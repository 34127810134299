export default [
    'abat',
    'abbé',
    'aboi',
    'abot',
    'abri',
    'abus',
    'accu',
    'aces',
    'acmé',
    'acné',
    'acon',
    'acre',
    'âcre',
    'acta',
    'acte',
    'adné',
    'ados',
    'aède',
    'aéra',
    'aéré',
    'aère',
    'afin',
    'afro',
    'âgée',
    'âges',
    'âgés',
    'agha',
    'agio',
    'agir',
    'agis',
    'agit',
    'agît',
    'agui',
    'ahan',
    'aida',
    'aide',
    'aidé',
    'aies',
    'aigu',
    'aile',
    'ailé',
    'ails',
    'aima',
    'aime',
    'aimé',
    'aine',
    'aîné',
    'aira',
    'aire',
    'airé',
    'airs',
    'aise',
    'aisé',
    'aisy',
    'aléa',
    'ales',
    'alfa',
    'alla',
    'allé',
    'allô',
    'aloi',
    'alpe',
    'alto',
    'alun',
    'amas',
    'amen',
    'amer',
    'âmes',
    'amie',
    'amis',
    'amuï',
    'anal',
    'ânes',
    'ange',
    'anis',
    'ânon',
    'anse',
    'ansé',
    'ante',
    'anus',
    'août',
    'apex',
    'apis',
    'âpre',
    'apte',
    'aras',
    'arcs',
    'ardu',
    'arec',
    'ares',
    'aria',
    'arma',
    'arme',
    'armé',
    'arts',
    'arum',
    'aspe',
    'asse',
    'asti',
    'âtre',
    'aube',
    'auge',
    'aune',
    'aura',
    'auto',
    'aval',
    'avec',
    'aven',
    'aveu',
    'avez',
    'avis',
    'axai',
    'axas',
    'axât',
    'axée',
    'axer',
    'axes',
    'axés',
    'axez',
    'axis',
    'ayez',
    'azur',
    'baba',
    'baby',
    'bacs',
    'baie',
    'bail',
    'bain',
    'bais',
    'bals',
    'banc',
    'bang',
    'bans',
    'bard',
    'barn',
    'bars',
    'basa',
    'base',
    'basé',
    'bâta',
    'bâte',
    'bâté',
    'bath',
    'bâti',
    'bats',
    'baud',
    'baux',
    'bava',
    'bave',
    'bavé',
    'baya',
    'baye',
    'bayé',
    'béai',
    'béas',
    'béat',
    'béât',
    'beau',
    'bébé',
    'becs',
    'béer',
    'bées',
    'béez',
    'bégu',
    'béké',
    'bêla',
    'bêle',
    'bêlé',
    'béni',
    'bers',
    'bêta',
    'bête',
    'beur',
    'beys',
    'bibi',
    'bics',
    'bide',
    'bief',
    'bien',
    'bila',
    'bile',
    'bilé',
    'bill',
    'bina',
    'bine',
    'biné',
    'bisa',
    'bise',
    'bisé',
    'bita',
    'bite',
    'bité',
    'bits',
    'bled',
    'blés',
    'blet',
    'bleu',
    'bloc',
    'boas',
    'bobo',
    'bobs',
    'bock',
    'bois',
    'boit',
    'bols',
    'bôme',
    'bond',
    'boni',
    'bons',
    'boom',
    'bora',
    'bord',
    'bore',
    'bort',
    'boss',
    'bote',
    'bots',
    'bouc',
    'boue',
    'boum',
    'bous',
    'bout',
    'boxa',
    'boxe',
    'boxé',
    'boys',
    'brai',
    'bran',
    'bras',
    'bref',
    'bric',
    'brie',
    'brin',
    'brio',
    'bris',
    'broc',
    'brou',
    'brrr',
    'brui',
    'brun',
    'brus',
    'brut',
    'buée',
    'bues',
    'buis',
    'buna',
    'bure',
    'busc',
    'buse',
    'bush',
    'buta',
    'bute',
    'buté',
    'buts',
    'cabs',
    'caca',
    'cade',
    'cadi',
    'café',
    'cage',
    'caïd',
    'cake',
    'cala',
    'cale',
    'calé',
    'calf',
    'calo',
    'cals',
    'came',
    'camp',
    'cana',
    'cane',
    'cané',
    'cape',
    'caps',
    'cari',
    'cars',
    'casa',
    'case',
    'casé',
    'cash',
    'cati',
    'cava',
    'cave',
    'cavé',
    'ceci',
    'céda',
    'cédé',
    'cède',
    'cela',
    'celé',
    'cèle',
    'cène',
    'cens',
    'cent',
    'cèpe',
    'ceps',
    'cerf',
    'ceux',
    'chah',
    'chai',
    'char',
    'chas',
    'chat',
    'chef',
    'cher',
    'chez',
    'chia',
    'chic',
    'chie',
    'chié',
    'choc',
    'chou',
    'chue',
    'chus',
    'chut',
    'chût',
    'ciao',
    'ciel',
    'cils',
    'cime',
    'ciné',
    'cinq',
    'cira',
    'cire',
    'ciré',
    'cita',
    'cite',
    'cité',
    'clam',
    'clan',
    'clef',
    'clés',
    'clic',
    'clin',
    'clip',
    'clos',
    'clôt',
    'clou',
    'club',
    'coca',
    'coco',
    'cocu',
    'coda',
    'code',
    'codé',
    'coin',
    'cois',
    'coït',
    'coke',
    'cola',
    'cols',
    'colt',
    'coma',
    'cône',
    'côni',
    'cons',
    'cool',
    'coqs',
    'cors',
    'cosy',
    'cota',
    'cote',
    'coté',
    'côte',
    'côté',
    'coti',
    'coud',
    'coup',
    'cour',
    'cous',
    'coût',
    'crac',
    'cran',
    'créa',
    'crée',
    'créé',
    'cria',
    'cric',
    'crie',
    'crié',
    'crin',
    'cris',
    'croc',
    'crue',
    'crus',
    'crûs',
    'crut',
    'crût',
    'cuba',
    'cube',
    'cubé',
    'cuir',
    'cuis',
    'cuit',
    'cula',
    'cule',
    'culé',
    'culs',
    'cura',
    'cure',
    'curé',
    'cuva',
    'cuve',
    'cuvé',
    'cyan',
    'czar',
    'dada',
    'daim',
    'dais',
    'dama',
    'dame',
    'damé',
    'dans',
    'dard',
    'data',
    'date',
    'daté',
    'deçà',
    'déca',
    'déci',
    'déçu',
    'défi',
    'déjà',
    'delà',
    'dème',
    'demi',
    'déni',
    'dent',
    'deux',
    'deys',
    'dico',
    'dieu',
    'dîme',
    'dîna',
    'dîne',
    'dîné',
    'ding',
    'dira',
    'dire',
    'dise',
    'dite',
    'dito',
    'dits',
    'diva',
    'dock',
    'dodo',
    'dodu',
    'doge',
    'dois',
    'doit',
    'dola',
    'dole',
    'dolé',
    'dols',
    'dôme',
    'donc',
    'dons',
    'dont',
    'dopa',
    'dope',
    'dopé',
    'dora',
    'dore',
    'doré',
    'dors',
    'dort',
    'dosa',
    'dose',
    'dosé',
    'dota',
    'dote',
    'doté',
    'dots',
    'doua',
    'doue',
    'doué',
    'doum',
    'doux',
    'drap',
    'drop',
    'drue',
    'drus',
    'dual',
    'duce',
    'ducs',
    'duel',
    'dues',
    'duit',
    'dune',
    'duos',
    'dupa',
    'dupe',
    'dupé',
    'dura',
    'dure',
    'duré',
    'durs',
    'eaux',
    'ébat',
    'èche',
    'écho',
    'échu',
    'écot',
    'écru',
    'écus',
    'édam',
    'éden',
    'édit',
    'égal',
    'élan',
    'elfe',
    'élis',
    'élit',
    'elle',
    'élue',
    'élus',
    'élut',
    'élût',
    'embu',
    'émet',
    'émeu',
    'émia',
    'émie',
    'émié',
    'émir',
    'émis',
    'émit',
    'émît',
    'émoi',
    'émou',
    'émue',
    'émus',
    'émut',
    'émût',
    'enta',
    'ente',
    'enté',
    'éons',
    'épar',
    'épée',
    'épia',
    'épie',
    'épié',
    'épis',
    'ères',
    'ergs',
    'éros',
    'erra',
    'erre',
    'erré',
    'esse',
    'étai',
    'étal',
    'état',
    'étau',
    'étés',
    'êtes',
    'étoc',
    'être',
    'étui',
    'eues',
    'euro',
    'exil',
    'exit',
    'face',
    'fada',
    'fade',
    'fadé',
    'fado',
    'faim',
    'fais',
    'fait',
    'famé',
    'fana',
    'fane',
    'fané',
    'faon',
    'fard',
    'faro',
    'fars',
    'fart',
    'fats',
    'faut',
    'faux',
    'faxa',
    'faxe',
    'faxé',
    'féal',
    'fées',
    'fêla',
    'fêle',
    'fêlé',
    'fend',
    'fera',
    'féra',
    'fers',
    'féru',
    'fêta',
    'fête',
    'fêté',
    'fétu',
    'feue',
    'feus',
    'feux',
    'fève',
    'fiai',
    'fias',
    'fiat',
    'fiât',
    'fics',
    'fiée',
    'fief',
    'fiel',
    'fier',
    'fies',
    'fiés',
    'fieu',
    'fiez',
    'fige',
    'figé',
    'fila',
    'file',
    'filé',
    'film',
    'fils',
    'fine',
    'fini',
    'fins',
    'fion',
    'fisc',
    'fixa',
    'fixe',
    'fixé',
    'flac',
    'flan',
    'flic',
    'floc',
    'flop',
    'flot',
    'flou',
    'flua',
    'flue',
    'flué',
    'flux',
    'focs',
    'foie',
    'foin',
    'fois',
    'folk',
    'fols',
    'fond',
    'font',
    'foot',
    'fora',
    'fore',
    'foré',
    'fors',
    'fort',
    'foui',
    'four',
    'fous',
    'fout',
    'foxé',
    'frac',
    'frai',
    'fret',
    'fric',
    'fris',
    'frit',
    'frît',
    'froc',
    'fuel',
    'fuie',
    'fuir',
    'fuis',
    'fuit',
    'fuît',
    'full',
    'fuma',
    'fume',
    'fumé',
    'fusa',
    'fuse',
    'fusé',
    'futé',
    'fûts',
    'gade',
    'gaga',
    'gage',
    'gagé',
    'gags',
    'gaie',
    'gain',
    'gais',
    'gala',
    'gale',
    'gals',
    'gang',
    'gant',
    'gaps',
    'gara',
    'gare',
    'garé',
    'gars',
    'gâta',
    'gâte',
    'gâté',
    'gaur',
    'gava',
    'gave',
    'gavé',
    'gays',
    'gaza',
    'gaze',
    'gazé',
    'geai',
    'gela',
    'gelé',
    'gèle',
    'gels',
    'gémi',
    'gêna',
    'gène',
    'gêne',
    'gêné',
    'gens',
    'gent',
    'géra',
    'géré',
    'gère',
    'gins',
    'girl',
    'gîta',
    'gîte',
    'gîté',
    'glas',
    'glui',
    'glus',
    'gnon',
    'gnou',
    'goal',
    'goba',
    'gobe',
    'gobé',
    'goda',
    'gode',
    'godé',
    'gogo',
    'goïm',
    'golf',
    'gond',
    'gong',
    'gord',
    'goum',
    'gour',
    'goût',
    'gras',
    'grau',
    'gréa',
    'grec',
    'grée',
    'gréé',
    'grès',
    'gril',
    'gris',
    'grog',
    'gros',
    'grue',
    'guai',
    'guéa',
    'guée',
    'guéé',
    'gués',
    'guet',
    'guis',
    'guru',
    'guss',
    'gyms',
    'haie',
    'haïe',
    'haïk',
    'haïr',
    'hais',
    'haïs',
    'hait',
    'haït',
    'hala',
    'hâla',
    'hale',
    'halé',
    'hâle',
    'hâlé',
    'hall',
    'halo',
    'haro',
    'hart',
    'hase',
    'hast',
    'hâta',
    'hâte',
    'hâté',
    'haut',
    'hava',
    'have',
    'havé',
    'hâve',
    'havi',
    'hein',
    'héla',
    'hélé',
    'hèle',
    'hère',
    'heur',
    'hier',
    'hies',
    'hoir',
    'holà',
    'home',
    'homo',
    'hors',
    'hôte',
    'houa',
    'houe',
    'houé',
    'houp',
    'houx',
    'huai',
    'huas',
    'huât',
    'huée',
    'huer',
    'hues',
    'hués',
    'huez',
    'huis',
    'huit',
    'huma',
    'hume',
    'humé',
    'hune',
    'hure',
    'ibis',
    'idée',
    'idem',
    'ides',
    'igné',
    'igue',
    'îles',
    'îlot',
    'imam',
    'imbu',
    'inca',
    'inde',
    'indu',
    'inné',
    'inox',
    'insu',
    'inti',
    'ioda',
    'iode',
    'iodé',
    'ions',
    'iota',
    'irai',
    'iras',
    'ires',
    'irez',
    'iris',
    'isba',
    'issu',
    'item',
    'itou',
    'iule',
    'ives',
    'ivre',
    'ixia',
    'jack',
    'jade',
    'jaïn',
    'jais',
    'jale',
    'jans',
    'janv',
    'jars',
    'jasa',
    'jase',
    'jasé',
    'java',
    'jazz',
    'jean',
    'jeep',
    'jerk',
    'jeta',
    'jeté',
    'jets',
    'jeun',
    'jeux',
    'jobs',
    'joie',
    'joli',
    'jonc',
    'jota',
    'joua',
    'joue',
    'joué',
    'joug',
    'joui',
    'jour',
    'jubé',
    'judo',
    'juge',
    'jugé',
    'juif',
    'juin',
    'jupe',
    'jura',
    'jure',
    'juré',
    'jury',
    'juta',
    'jute',
    'juté',
    'kaki',
    'kali',
    'kami',
    'kans',
    'kart',
    'kava',
    'kawa',
    'képi',
    'khan',
    'khat',
    'khôl',
    'kick',
    'kief',
    'kifs',
    'kiki',
    'kilo',
    'kils',
    'kilt',
    'kirs',
    'kits',
    'kiwi',
    'kola',
    'korê',
    'krak',
    'ksar',
    'kvas',
    'kwas',
    'laça',
    'lace',
    'lacé',
    'lacs',
    'lads',
    'lady',
    'laïc',
    'laid',
    'laie',
    'lais',
    'lait',
    'lama',
    'lame',
    'lamé',
    'Land',
    'lapa',
    'lape',
    'lapé',
    'laps',
    'lard',
    'lava',
    'lave',
    'lavé',
    'laya',
    'laye',
    'layé',
    'lège',
    'legs',
    'lent',
    'lésa',
    'lésé',
    'lèse',
    'lest',
    'leur',
    'leva',
    'levé',
    'lève',
    'liai',
    'lias',
    'liât',
    'lice',
    'lido',
    'lied',
    'liée',
    'lien',
    'lier',
    'lies',
    'liés',
    'lieu',
    'liez',
    'lift',
    'lige',
    'lima',
    'lime',
    'limé',
    'lino',
    'lion',
    'lira',
    'lire',
    'lise',
    'lita',
    'lite',
    'lité',
    'lits',
    'live',
    'loba',
    'lobe',
    'lobé',
    'lobs',
    'loch',
    'lods',
    'lofa',
    'lofe',
    'lofé',
    'lofs',
    'loft',
    'loge',
    'logé',
    'logo',
    'loin',
    'loir',
    'lois',
    'lolo',
    'long',
    'look',
    'lord',
    'lori',
    'lors',
    'lote',
    'loti',
    'loto',
    'lots',
    'loua',
    'loue',
    'loué',
    'loup',
    'lova',
    'love',
    'lové',
    'lues',
    'luge',
    'lugé',
    'luis',
    'luit',
    'lulu',
    'lump',
    'lune',
    'luné',
    'luta',
    'lute',
    'luté',
    'luth',
    'luxa',
    'luxe',
    'luxé',
    'lynx',
    'lyre',
    'lysa',
    'lyse',
    'lysé',
    'Mach',
    'macs',
    'mage',
    'maïa',
    'maie',
    'mail',
    'main',
    'mais',
    'maïs',
    'maki',
    'mâle',
    'mali',
    'malt',
    'mana',
    'mans',
    'marc',
    'mare',
    'mari',
    'mark',
    'mars',
    'mata',
    'mâta',
    'mate',
    'maté',
    'mâte',
    'mâté',
    'math',
    'mati',
    'mats',
    'mâts',
    'maux',
    'maya',
    'maye',
    'méat',
    'mecs',
    'mède',
    'mégi',
    'mêla',
    'mêle',
    'mêlé',
    'mélo',
    'mémé',
    'même',
    'mena',
    'mené',
    'mène',
    'mens',
    'ment',
    'menu',
    'mère',
    'mers',
    'mesa',
    'mess',
    'méta',
    'mets',
    'meus',
    'meut',
    'mica',
    'midi',
    'miel',
    'mien',
    'mies',
    'mile',
    'mils',
    'mima',
    'mime',
    'mimé',
    'mimi',
    'mina',
    'mine',
    'miné',
    'mini',
    'mira',
    'mire',
    'miré',
    'miro',
    'mirs',
    'misa',
    'mise',
    'misé',
    'miss',
    'mita',
    'mite',
    'mité',
    'mixa',
    'mixe',
    'mixé',
    'moco',
    'mode',
    'mois',
    'moka',
    'môle',
    'mols',
    'moly',
    'môme',
    'mono',
    'mont',
    'mord',
    'more',
    'mort',
    'moto',
    'mots',
    'moud',
    'moue',
    'mous',
    'moût',
    'moxa',
    'moyé',
    'muai',
    'muas',
    'muât',
    'muée',
    'muer',
    'mues',
    'mués',
    'muet',
    'muez',
    'muge',
    'mugi',
    'muid',
    'mule',
    'muni',
    'mura',
    'mure',
    'muré',
    'mûre',
    'mûri',
    'murs',
    'mûrs',
    'musa',
    'musc',
    'muse',
    'musé',
    'must',
    'muta',
    'mute',
    'muté',
    'nafé',
    'nage',
    'nagé',
    'naïf',
    'nain',
    'nais',
    'naît',
    'naja',
    'nana',
    'naos',
    'nard',
    'nase',
    'nazi',
    'neck',
    'nées',
    'nefs',
    'néné',
    'néon',
    'nerf',
    'nets',
    'neuf',
    'névé',
    'niai',
    'nias',
    'niât',
    'nids',
    'niée',
    'nier',
    'nies',
    'niés',
    'niez',
    'nife',
    'nifs',
    'nixe',
    'noce',
    'Noël',
    'noie',
    'noir',
    'noix',
    'nome',
    'noms',
    'none',
    'nopa',
    'nope',
    'nopé',
    'nord',
    'nota',
    'note',
    'noté',
    'noua',
    'noue',
    'noué',
    'nous',
    'nova',
    'nove',
    'nové',
    'noya',
    'noyé',
    'nuai',
    'nuas',
    'nuât',
    'nuée',
    'nuer',
    'nues',
    'nués',
    'nuez',
    'nuis',
    'nuit',
    'nuls',
    'obéi',
    'obel',
    'obit',
    'obus',
    'ocra',
    'ocre',
    'ocré',
    'odes',
    'oeil',
    'oeuf',
    'ogre',
    'ohms',
    'oies',
    'oing',
    'oint',
    'olim',
    'ollé',
    'omet',
    'omis',
    'omit',
    'omît',
    'once',
    'onde',
    'ondé',
    'onyx',
    'onze',
    'open',
    'opes',
    'opta',
    'opte',
    'opté',
    'opus',
    'oral',
    'orbe',
    'ordo',
    'orée',
    'ores',
    'orge',
    'orin',
    'orle',
    'orme',
    'orna',
    'orne',
    'orné',
    'osai',
    'osas',
    'osât',
    'osée',
    'oser',
    'oses',
    'osés',
    'osez',
    'ossu',
    'ôtai',
    'ôtas',
    'ôtât',
    'ôtée',
    'ôter',
    'ôtes',
    'ôtés',
    'ôtez',
    'oued',
    'ouïe',
    'ouïr',
    'ouïs',
    'ouït',
    'ours',
    'oust',
    'oves',
    'ovin',
    'ovni',
    'oyat',
    'pack',
    'page',
    'pagé',
    'paie',
    'pain',
    'pair',
    'pais',
    'paît',
    'paix',
    'pale',
    'pâle',
    'pâli',
    'pals',
    'pâma',
    'pâme',
    'pâmé',
    'pana',
    'pane',
    'pané',
    'pans',
    'paon',
    'papa',
    'pape',
    'papi',
    'papy',
    'para',
    'parc',
    'pare',
    'paré',
    'pari',
    'pars',
    'part',
    'paru',
    'pâta',
    'pâte',
    'pâté',
    'pâti',
    'pava',
    'pave',
    'pavé',
    'paya',
    'paye',
    'payé',
    'pays',
    'péan',
    'peau',
    'pela',
    'pelé',
    'pèle',
    'pend',
    'pêne',
    'péon',
    'pépé',
    'perd',
    'père',
    'péri',
    'pers',
    'pesa',
    'pesé',
    'pèse',
    'peso',
    'péta',
    'pété',
    'pète',
    'peuh',
    'peul',
    'peur',
    'peut',
    'peux',
    'pèze',
    'pfft',
    'piaf',
    'pian',
    'pica',
    'pics',
    'pied',
    'pies',
    'pieu',
    'pifa',
    'pife',
    'pifé',
    'pifs',
    'pige',
    'pigé',
    'pila',
    'pile',
    'pilé',
    'pins',
    'pion',
    'pipa',
    'pipe',
    'pipé',
    'pipi',
    'pire',
    'pisé',
    'pite',
    'pive',
    'plan',
    'plat',
    'plia',
    'plie',
    'plié',
    'plis',
    'ploc',
    'plot',
    'plus',
    'plut',
    'plût',
    'pneu',
    'poil',
    'pois',
    'poix',
    'pôle',
    'poli',
    'polo',
    'pond',
    'pont',
    'pool',
    'pope',
    'porc',
    'pore',
    'port',
    'posa',
    'pose',
    'posé',
    'pote',
    'pots',
    'pouf',
    'pour',
    'poux',
    'prés',
    'près',
    'prêt',
    'pria',
    'prie',
    'prié',
    'pris',
    'prit',
    'prît',
    'prix',
    'prof',
    'pros',
    'prou',
    'puai',
    'puas',
    'puât',
    'pubs',
    'puce',
    'puée',
    'puer',
    'pues',
    'pués',
    'puez',
    'puis',
    'pull',
    'puma',
    'puna',
    'puni',
    'punk',
    'pupe',
    'pure',
    'purs',
    'pute',
    'puys',
    'quai',
    'quel',
    'quoi',
    'race',
    'racé',
    'rada',
    'rade',
    'radé',
    'rage',
    'ragé',
    'raïa',
    'raid',
    'raie',
    'rail',
    'rais',
    'raja',
    'raki',
    'râla',
    'râle',
    'râlé',
    'rama',
    'rame',
    'ramé',
    'rami',
    'rang',
    'rani',
    'ranz',
    'râpa',
    'râpe',
    'râpé',
    'rapt',
    'rare',
    'rasa',
    'rase',
    'rasé',
    'rash',
    'rata',
    'rate',
    'raté',
    'rats',
    'rave',
    'ravi',
    'raya',
    'raye',
    'rayé',
    'réac',
    'réai',
    'réal',
    'réas',
    'réât',
    'reçu',
    'redû',
    'réel',
    'réer',
    'rées',
    'réez',
    'régi',
    'regs',
    'rein',
    'reis',
    'relu',
    'rems',
    'rend',
    'rené',
    'rêne',
    'reps',
    'repu',
    'rets',
    'rêva',
    'rêve',
    'rêvé',
    'revu',
    'rhés',
    'rhum',
    'rial',
    'rias',
    'rida',
    'ride',
    'ridé',
    'riel',
    'rien',
    'ries',
    'riez',
    'rift',
    'rima',
    'rime',
    'rimé',
    'ring',
    'ripa',
    'ripe',
    'ripé',
    'rira',
    'rire',
    'rite',
    'riva',
    'rive',
    'rivé',
    'rixe',
    'roba',
    'robe',
    'robé',
    'robs',
    'rock',
    'rocs',
    'roda',
    'rôda',
    'rode',
    'rodé',
    'rôde',
    'rôdé',
    'rois',
    'rôle',
    'rond',
    'rosa',
    'rose',
    'rosé',
    'rosi',
    'rota',
    'rote',
    'roté',
    'rôti',
    'rots',
    'rôts',
    'roua',
    'roue',
    'roué',
    'rouf',
    'roui',
    'roux',
    'ruai',
    'ruas',
    'ruât',
    'rude',
    'ruée',
    'ruer',
    'rues',
    'rués',
    'ruez',
    'rugi',
    'rusa',
    'ruse',
    'rusé',
    'rush',
    'ruts',
    'sacs',
    'saga',
    'sage',
    'sain',
    'sais',
    'saïs',
    'sait',
    'saké',
    'saki',
    'sala',
    'sale',
    'salé',
    'sali',
    'sana',
    'sang',
    'sans',
    'sapa',
    'sape',
    'sapé',
    'sari',
    'satî',
    'sauf',
    'saur',
    'saut',
    'saxe',
    'saxo',
    'scat',
    'scia',
    'scie',
    'scié',
    'seau',
    'secs',
    'sein',
    'self',
    'sels',
    'sema',
    'semé',
    'sème',
    'séné',
    'sens',
    'sent',
    'seps',
    'sept',
    'sera',
    'serf',
    'sers',
    'sert',
    'sets',
    'seul',
    'sève',
    'sévi',
    'sexe',
    'sexy',
    'shah',
    'show',
    'sial',
    'sied',
    'siée',
    'sien',
    'silo',
    'sils',
    'sima',
    'sire',
    'sirs',
    'sise',
    'site',
    'sium',
    'Skaï',
    'skia',
    'skie',
    'skié',
    'skif',
    'skis',
    'slip',
    'slow',
    'smog',
    'snob',
    'socs',
    'soda',
    'sodé',
    'sofa',
    'soie',
    'soif',
    'soin',
    'soir',
    'sois',
    'soit',
    'soja',
    'sole',
    'solo',
    'sols',
    'soma',
    'sono',
    'sons',
    'sont',
    'sore',
    'sors',
    'sort',
    'sots',
    'soue',
    'souk',
    'soûl',
    'sous',
    'soya',
    'spot',
    'star',
    'stem',
    'stop',
    'stuc',
    'suai',
    'suas',
    'suât',
    'subi',
    'suça',
    'suce',
    'sucé',
    'sucs',
    'suée',
    'suer',
    'sues',
    'sués',
    'suez',
    'suie',
    'suif',
    'suis',
    'suit',
    'sure',
    'sûre',
    'surf',
    'suri',
    'surs',
    'sûrs',
    'swap',
    'tacs',
    'tact',
    'taie',
    'tain',
    'tais',
    'tait',
    'tala',
    'talc',
    'tale',
    'talé',
    'tank',
    'tans',
    'tant',
    'taon',
    'tapa',
    'tape',
    'tapé',
    'tapi',
    'tara',
    'tard',
    'tare',
    'taré',
    'tari',
    'tata',
    'tâta',
    'tâte',
    'tâté',
    'taux',
    'taxa',
    'taxe',
    'taxé',
    'taxi',
    'teck',
    'tees',
    'teks',
    'télé',
    'tell',
    'tels',
    'tend',
    'tenu',
    'ténu',
    'test',
    'téta',
    'tété',
    'tète',
    'tête',
    'têtu',
    'thés',
    'thon',
    'thym',
    'tian',
    'tics',
    'tien',
    'tifs',
    'tige',
    'tilt',
    'tins',
    'tint',
    'tînt',
    'tira',
    'tire',
    'tiré',
    'tirs',
    'tisa',
    'tise',
    'tisé',
    'titi',
    'tocs',
    'toge',
    'toit',
    'tôle',
    'tolu',
    'toma',
    'tome',
    'tomé',
    'tond',
    'tons',
    'topa',
    'tope',
    'topé',
    'topo',
    'tops',
    'tord',
    'tors',
    'tort',
    'tory',
    'toto',
    'toua',
    'toue',
    'toué',
    'tour',
    'tous',
    'tout',
    'toux',
    'trac',
    'tram',
    'très',
    'tria',
    'trie',
    'trié',
    'trin',
    'trio',
    'trip',
    'tris',
    'troc',
    'trop',
    'trot',
    'trou',
    'truc',
    'tsar',
    'tuai',
    'tuas',
    'tuât',
    'tuba',
    'tube',
    'tubé',
    'tubs',
    'tuée',
    'tuer',
    'tues',
    'tués',
    'tuez',
    'tufs',
    'tune',
    'tupi',
    'turc',
    'turf',
    'tutu',
    'typa',
    'type',
    'typé',
    'typo',
    'tzar',
    'ubac',
    'ulve',
    'unau',
    'unes',
    'unie',
    'unir',
    'unis',
    'unit',
    'unît',
    'upas',
    'urée',
    'ures',
    'urge',
    'urne',
    'urus',
    'usai',
    'usas',
    'usât',
    'usée',
    'user',
    'uses',
    'usés',
    'usez',
    'uval',
    'uvée',
    'vagi',
    'vain',
    'vair',
    'vais',
    'vals',
    'valu',
    'vamp',
    'vans',
    'vape',
    'vars',
    'vase',
    'vaut',
    'vaux',
    'veau',
    'vécu',
    'Véda',
    'vêla',
    'veld',
    'vêle',
    'vêlé',
    'vélo',
    'velu',
    'vend',
    'vent',
    'venu',
    'vers',
    'vert',
    'vête',
    'veto',
    'vêts',
    'vêtu',
    'veuf',
    'veut',
    'veux',
    'vexa',
    'vexe',
    'vexé',
    'vice',
    'vida',
    'vide',
    'vidé',
    'vies',
    'vifs',
    'vile',
    'vils',
    'vina',
    'vine',
    'viné',
    'vins',
    'vint',
    'vînt',
    'vioc',
    'viol',
    'vira',
    'vire',
    'viré',
    'visa',
    'vise',
    'visé',
    'vite',
    'vive',
    'vlan',
    'voeu',
    'voie',
    'voir',
    'vois',
    'voit',
    'voix',
    'vola',
    'vole',
    'volé',
    'vols',
    'volt',
    'vomi',
    'vont',
    'vota',
    'vote',
    'voté',
    'voua',
    'voue',
    'voué',
    'vous',
    'vrac',
    'vrai',
    'vues',
    'watt',
    'whig',
    'yack',
    'yaks',
    'yang',
    'yard',
    'yens',
    'yeux',
    'yoga',
    'yogi',
    'yole',
    'zain',
    'zani',
    'zébu',
    'zées',
    'zélé',
    'zèle',
    'zend',
    'zéro',
    'zêta',
    'ziba',
    'zibe',
    'zibé',
    'zigs',
    'zinc',
    'zizi',
    'zona',
    'zone',
    'zoné',
    'zoom',
    'zoos',
    'zozo'
];
