export default [
    'abaissa',
    'abaisse',
    'abaissé',
    'abajoue',
    'abandon',
    'abaques',
    'abatage',
    'abatées',
    'abattée',
    'abattes',
    'abattez',
    'abattis',
    'abattit',
    'abattît',
    'abattra',
    'abattre',
    'abattue',
    'abattus',
    'abbayes',
    'abbesse',
    'abdiqua',
    'abdique',
    'abdiqué',
    'abdomen',
    'abeille',
    'aberrai',
    'aberras',
    'aberrât',
    'aberrer',
    'aberres',
    'aberrez',
    'abêties',
    'abêtira',
    'abhorra',
    'abhorre',
    'abhorré',
    'abîmais',
    'abîmait',
    'abîmant',
    'abîmées',
    'abîment',
    'abîmera',
    'abîmiez',
    'abîmons',
    'abjecte',
    'abjects',
    'abjurai',
    'abjuras',
    'abjurât',
    'abjurée',
    'abjurer',
    'abjures',
    'abjurés',
    'abjurez',
    'ablatif',
    'ablégat',
    'ablette',
    'abloqua',
    'abloque',
    'abloqué',
    'aboient',
    'aboiera',
    'abolies',
    'abolira',
    'abomina',
    'abomine',
    'abominé',
    'abondai',
    'abondas',
    'abondât',
    'abonder',
    'abondes',
    'abondez',
    'abonnai',
    'abonnas',
    'abonnât',
    'abonnée',
    'abonner',
    'abonnes',
    'abonnés',
    'abonnez',
    'abonnie',
    'abonnir',
    'abonnis',
    'abonnit',
    'abonnît',
    'abordai',
    'abordas',
    'abordât',
    'abordée',
    'aborder',
    'abordes',
    'abordés',
    'abordez',
    'abortif',
    'aboucha',
    'abouche',
    'abouché',
    'aboulai',
    'aboulas',
    'aboulât',
    'aboulée',
    'abouler',
    'aboules',
    'aboulés',
    'aboulez',
    'aboulie',
    'aboutai',
    'aboutas',
    'aboutât',
    'aboutée',
    'abouter',
    'aboutes',
    'aboutés',
    'aboutez',
    'aboutir',
    'aboutis',
    'aboutit',
    'aboutît',
    'aboyais',
    'aboyait',
    'aboyant',
    'aboyeur',
    'aboyiez',
    'aboyons',
    'abrasai',
    'abrasas',
    'abrasât',
    'abrasée',
    'abraser',
    'abrases',
    'abrasés',
    'abrasez',
    'abrasif',
    'abrégea',
    'abrégée',
    'abréger',
    'abrégés',
    'abrèges',
    'abrégez',
    'abreuva',
    'abreuve',
    'abreuvé',
    'abricot',
    'abritai',
    'abritas',
    'abritât',
    'abritée',
    'abriter',
    'abrites',
    'abrités',
    'abritez',
    'abrogea',
    'abrogée',
    'abroger',
    'abroges',
    'abrogés',
    'abrogez',
    'abrupte',
    'abrupts',
    'abrutie',
    'abrutir',
    'abrutis',
    'abrutit',
    'abrutît',
    'abscons',
    'absence',
    'absente',
    'absenté',
    'absents',
    'absides',
    'absolue',
    'absolus',
    'absolve',
    'absorba',
    'absorbe',
    'absorbé',
    'absoute',
    'abstème',
    'abstenu',
    'abstrus',
    'absurde',
    'abusais',
    'abusait',
    'abusant',
    'abusées',
    'abusent',
    'abusera',
    'abusiez',
    'abusifs',
    'abusive',
    'abusons',
    'abyssal',
    'abysses',
    'abyssin',
    'acabits',
    'acacias',
    'acadien',
    'acajous',
    'acanthe',
    'acarien',
    'acaules',
    'accabla',
    'accable',
    'accablé',
    'accédai',
    'accédas',
    'accédât',
    'accéder',
    'accèdes',
    'accédez',
    'accents',
    'accepta',
    'accepte',
    'accepté',
    'accises',
    'acclama',
    'acclame',
    'acclamé',
    'accolai',
    'accolas',
    'accolât',
    'accolée',
    'accoler',
    'accoles',
    'accolés',
    'accolez',
    'accorai',
    'accoras',
    'accorât',
    'accorda',
    'accorde',
    'accordé',
    'accords',
    'accorée',
    'accorer',
    'accores',
    'accorés',
    'accorez',
    'accorte',
    'accorts',
    'accosta',
    'accoste',
    'accosté',
    'accotai',
    'accotas',
    'accotât',
    'accotée',
    'accoter',
    'accotes',
    'accotés',
    'accotez',
    'accouai',
    'accouas',
    'accouât',
    'accoudé',
    'accouée',
    'accouer',
    'accoues',
    'accoués',
    'accouez',
    'accoure',
    'accours',
    'accourt',
    'accouru',
    'accrocs',
    'accrois',
    'accroît',
    'accrues',
    'accueil',
    'acculai',
    'acculas',
    'acculât',
    'acculée',
    'acculer',
    'accules',
    'acculés',
    'acculez',
    'accusai',
    'accusas',
    'accusât',
    'accusée',
    'accuser',
    'accuses',
    'accusés',
    'accusez',
    'acérais',
    'acérait',
    'acérant',
    'acerbes',
    'acérées',
    'acèrent',
    'acérera',
    'acériez',
    'acérons',
    'acétate',
    'acétone',
    'acétyla',
    'acétyle',
    'acétylé',
    'achards',
    'acharna',
    'acharne',
    'acharné',
    'achéens',
    'achetai',
    'achetas',
    'achetât',
    'achetée',
    'acheter',
    'achetés',
    'achètes',
    'achetez',
    'achevai',
    'achevas',
    'achevât',
    'achevée',
    'achever',
    'achevés',
    'achèves',
    'achevez',
    'achigan',
    'acholie',
    'achoppé',
    'achrome',
    'achylie',
    'acidité',
    'acidose',
    'acidula',
    'acidule',
    'acidulé',
    'aciérai',
    'aciéras',
    'aciérât',
    'aciérée',
    'aciérer',
    'aciérés',
    'acières',
    'aciérez',
    'aciérie',
    'acisela',
    'aciselé',
    'acisèle',
    'acolyte',
    'acompte',
    'aconage',
    'aconier',
    'aconits',
    'à-côtés',
    'à-coups',
    'acquêts',
    'acquise',
    'acquits',
    'âcretés',
    'actâmes',
    'actants',
    'actasse',
    'actâtes',
    'acterai',
    'acteras',
    'acterez',
    'acteurs',
    'actinie',
    'actions',
    'activai',
    'activas',
    'activât',
    'activée',
    'activer',
    'actives',
    'activés',
    'activez',
    'actrice',
    'actuels',
    'acuités',
    'acuminé',
    'adagios',
    'adaptai',
    'adaptas',
    'adaptât',
    'adaptée',
    'adapter',
    'adaptes',
    'adaptés',
    'adaptez',
    'addenda',
    'additif',
    'adénite',
    'adénome',
    'adeptes',
    'adéquat',
    'adextré',
    'adhérai',
    'adhéras',
    'adhérât',
    'adhérer',
    'adhères',
    'adhérez',
    'adhésif',
    'adipeux',
    'adipose',
    'adipsie',
    'adjoins',
    'adjoint',
    'adjugea',
    'adjugée',
    'adjuger',
    'adjuges',
    'adjugés',
    'adjugez',
    'adjurai',
    'adjuras',
    'adjurât',
    'adjurée',
    'adjurer',
    'adjures',
    'adjurés',
    'adjurez',
    'adjuvat',
    'admette',
    'admîmes',
    'admirai',
    'admiras',
    'admirât',
    'admirée',
    'admirer',
    'admires',
    'admirés',
    'admirez',
    'admises',
    'admisse',
    'admîtes',
    'adonisé',
    'adonnée',
    'adonner',
    'adonnés',
    'adonnez',
    'adoptai',
    'adoptas',
    'adoptât',
    'adoptée',
    'adopter',
    'adoptes',
    'adoptés',
    'adoptez',
    'adoptif',
    'adorais',
    'adorait',
    'adorant',
    'adorées',
    'adorent',
    'adorera',
    'adoriez',
    'adorons',
    'adossai',
    'adossas',
    'adossât',
    'adossée',
    'adosser',
    'adosses',
    'adossés',
    'adossez',
    'adoubai',
    'adoubas',
    'adoubât',
    'adoubée',
    'adouber',
    'adoubes',
    'adoubés',
    'adoubez',
    'adoucie',
    'adoucir',
    'adoucis',
    'adoucit',
    'adoucît',
    'adressa',
    'adresse',
    'adressé',
    'adroite',
    'adroits',
    'adstrat',
    'adulais',
    'adulait',
    'adulant',
    'adulées',
    'adulent',
    'adulera',
    'aduliez',
    'adulons',
    'adultes',
    'advenir',
    'advenue',
    'advenus',
    'adverbe',
    'adverse',
    'advient',
    'aérâmes',
    'aérasse',
    'aérâtes',
    'aérerai',
    'aéreras',
    'aérerez',
    'aériens',
    'aérions',
    'aérobie',
    'aéronef',
    'aérosol',
    'aeschne',
    'affable',
    'affadie',
    'affadir',
    'affadis',
    'affadit',
    'affadît',
    'affaire',
    'affairé',
    'affalai',
    'affalas',
    'affalât',
    'affalée',
    'affaler',
    'affales',
    'affalés',
    'affalez',
    'affamai',
    'affamas',
    'affamât',
    'affamée',
    'affamer',
    'affames',
    'affamés',
    'affamez',
    'afféage',
    'afféagé',
    'affecta',
    'affecte',
    'affecté',
    'affects',
    'afférai',
    'afféras',
    'afférât',
    'afférer',
    'affères',
    'afférez',
    'afferma',
    'afferme',
    'affermé',
    'affermi',
    'affétée',
    'affétés',
    'afficha',
    'affiche',
    'affiché',
    'affidée',
    'affidés',
    'affilai',
    'affilas',
    'affilât',
    'affilée',
    'affiler',
    'affiles',
    'affilés',
    'affilez',
    'affilia',
    'affilie',
    'affilié',
    'affinai',
    'affinas',
    'affinât',
    'affinée',
    'affiner',
    'affines',
    'affinés',
    'affinez',
    'affirma',
    'affirme',
    'affirmé',
    'affixal',
    'affixes',
    'afflige',
    'affligé',
    'affloua',
    'affloue',
    'affloué',
    'affluai',
    'affluas',
    'affluât',
    'affluer',
    'afflues',
    'affluez',
    'affolai',
    'affolas',
    'affolât',
    'affolée',
    'affoler',
    'affoles',
    'affolés',
    'affolez',
    'affréta',
    'affrété',
    'affrète',
    'affreux',
    'affrita',
    'affrite',
    'affrité',
    'affront',
    'affubla',
    'affuble',
    'affublé',
    'affurai',
    'affuras',
    'affurât',
    'affurée',
    'affurer',
    'affures',
    'affurés',
    'affurez',
    'affûtai',
    'affûtas',
    'affûtât',
    'affûtée',
    'affûter',
    'affûtes',
    'affûtés',
    'affûtez',
    'afghane',
    'afghans',
    'afocale',
    'afocaux',
    'agaçais',
    'agaçait',
    'agaçant',
    'agacées',
    'agacent',
    'agacera',
    'agaciez',
    'agaçons',
    'agarics',
    'agatisé',
    'agençai',
    'agenças',
    'agençât',
    'agencée',
    'agencer',
    'agences',
    'agencés',
    'agencez',
    'agendas',
    'agérate',
    'aggrava',
    'aggrave',
    'aggravé',
    'agilité',
    'agiotai',
    'agiotas',
    'agiotât',
    'agioter',
    'agiotes',
    'agiotez',
    'agirais',
    'agirait',
    'agirent',
    'agiriez',
    'agirons',
    'agiront',
    'agisses',
    'agissez',
    'agitais',
    'agitait',
    'agitant',
    'agitato',
    'agitées',
    'agitent',
    'agitera',
    'agitiez',
    'agitons',
    'aglyphe',
    'agnathe',
    'agneaux',
    'agnelai',
    'agnelas',
    'agnelât',
    'agneler',
    'agnelet',
    'agnelez',
    'agnelin',
    'agnelle',
    'agnosie',
    'agonies',
    'agonira',
    'agonisa',
    'agonise',
    'agonisé',
    'agoutis',
    'agrafai',
    'agrafas',
    'agrafât',
    'agrafée',
    'agrafer',
    'agrafes',
    'agrafés',
    'agrafez',
    'agraire',
    'agrandi',
    'agréais',
    'agréait',
    'agréant',
    'agréées',
    'agréent',
    'agréera',
    'agrégat',
    'agrégea',
    'agrégée',
    'agréger',
    'agrégés',
    'agrèges',
    'agrégez',
    'agréiez',
    'agréons',
    'agressa',
    'agresse',
    'agressé',
    'agreste',
    'agricha',
    'agriche',
    'agriché',
    'agriffé',
    'agriles',
    'agrions',
    'agriote',
    'agrippa',
    'agrippe',
    'agrippé',
    'agrotis',
    'agrumes',
    'aguerri',
    'aguicha',
    'aguiche',
    'aguiché',
    'ahanais',
    'ahanait',
    'ahanant',
    'ahanent',
    'ahanera',
    'ahaniez',
    'ahanons',
    'aheurté',
    'ahuries',
    'ahurira',
    'aidâmes',
    'aidasse',
    'aidâtes',
    'aiderai',
    'aideras',
    'aiderez',
    'aidions',
    'aïeules',
    'aiglons',
    'aigreur',
    'aigries',
    'aigrira',
    'aiguisa',
    'aiguise',
    'aiguisé',
    'aïkidos',
    'ailante',
    'aileron',
    'ailette',
    'ailiers',
    'aillais',
    'aillait',
    'aillant',
    'aillées',
    'aillent',
    'aillera',
    'ailliez',
    'ailloli',
    'aillons',
    'aimable',
    'aimâmes',
    'aimanta',
    'aimante',
    'aimanté',
    'aimants',
    'aimasse',
    'aimâtes',
    'aimerai',
    'aimeras',
    'aimerez',
    'aimions',
    'aînesse',
    'airains',
    'airâmes',
    'airasse',
    'airâtes',
    'airelle',
    'airerai',
    'aireras',
    'airerez',
    'airions',
    'aisance',
    'ajointa',
    'ajointe',
    'ajointé',
    'ajourai',
    'ajouras',
    'ajourât',
    'ajourée',
    'ajourer',
    'ajoures',
    'ajourés',
    'ajourez',
    'ajourna',
    'ajourne',
    'ajourné',
    'ajoutai',
    'ajoutas',
    'ajoutât',
    'ajoutée',
    'ajouter',
    'ajoutes',
    'ajoutés',
    'ajoutez',
    'ajustai',
    'ajustas',
    'ajustât',
    'ajustée',
    'ajuster',
    'ajustes',
    'ajustés',
    'ajustez',
    'akvavit',
    'alaires',
    'alaises',
    'alambic',
    'alangui',
    'alanine',
    'alarmai',
    'alarmas',
    'alarmât',
    'alarmée',
    'alarmer',
    'alarmes',
    'alarmés',
    'alarmez',
    'albâtre',
    'albédos',
    'alberge',
    'albinos',
    'albites',
    'albugos',
    'albumen',
    'alcades',
    'alcalin',
    'alcalis',
    'alcènes',
    'alcoolé',
    'alcools',
    'alcôves',
    'alcoyle',
    'alcynes',
    'alcyons',
    'aldines',
    'alentie',
    'alentir',
    'alentis',
    'alentit',
    'alentît',
    'alépine',
    'alérion',
    'alertai',
    'alertas',
    'alertât',
    'alertée',
    'alerter',
    'alertes',
    'alertés',
    'alertez',
    'alésage',
    'alésais',
    'alésait',
    'alésant',
    'alésées',
    'alèsent',
    'alésera',
    'aléseur',
    'alésiez',
    'alésoir',
    'alésons',
    'alevina',
    'alevine',
    'aleviné',
    'alevins',
    'alezans',
    'algèbre',
    'algides',
    'algines',
    'algique',
    'alicant',
    'alidade',
    'aliénai',
    'aliénas',
    'aliénât',
    'aliénée',
    'aliéner',
    'aliénés',
    'aliènes',
    'aliénez',
    'alifère',
    'alignai',
    'alignas',
    'alignât',
    'alignée',
    'aligner',
    'alignes',
    'alignés',
    'alignez',
    'aligoté',
    'aliment',
    'alinéas',
    'alisier',
    'alismes',
    'alitais',
    'alitait',
    'alitant',
    'alitées',
    'alitent',
    'alitera',
    'alitiez',
    'alitons',
    'allaita',
    'allaite',
    'allaité',
    'allâmes',
    'allante',
    'allants',
    'allasse',
    'allâtes',
    'allécha',
    'alléché',
    'allèche',
    'allégea',
    'allégée',
    'alléger',
    'allégés',
    'allèges',
    'allégez',
    'allégie',
    'allégir',
    'allégis',
    'allégit',
    'allégît',
    'allègre',
    'allegro',
    'allégua',
    'allégué',
    'allègue',
    'allènes',
    'alliacé',
    'alliage',
    'alliais',
    'alliait',
    'alliant',
    'alliées',
    'allient',
    'alliera',
    'alliiez',
    'allions',
    'allonge',
    'allongé',
    'allouai',
    'allouas',
    'allouât',
    'allouée',
    'allouer',
    'alloues',
    'alloués',
    'allouez',
    'allumai',
    'allumas',
    'allumât',
    'allumée',
    'allumer',
    'allumes',
    'allumés',
    'allumez',
    'allurée',
    'allures',
    'allurés',
    'allusif',
    'allyles',
    'alogies',
    'alouate',
    'alourdi',
    'aloyaux',
    'alpagas',
    'alpages',
    'alpagua',
    'alpague',
    'alpines',
    'alpiste',
    'altérai',
    'altéras',
    'altérât',
    'altérée',
    'altérer',
    'altérés',
    'altères',
    'altérez',
    'alterna',
    'alterne',
    'alterné',
    'altesse',
    'altière',
    'altiers',
    'altises',
    'altiste',
    'alucite',
    'alumina',
    'alumine',
    'aluminé',
    'alunais',
    'alunait',
    'alunant',
    'alunées',
    'alunent',
    'alunera',
    'aluniez',
    'alunira',
    'alunite',
    'alunons',
    'alvéole',
    'alvéolé',
    'alvines',
    'amadoua',
    'amadoue',
    'amadoué',
    'amadous',
    'amaigri',
    'amandes',
    'amanite',
    'amantes',
    'amarile',
    'amarils',
    'amarina',
    'amarine',
    'amariné',
    'amarrai',
    'amarras',
    'amarrât',
    'amarrée',
    'amarrer',
    'amarres',
    'amarrés',
    'amarrez',
    'amassai',
    'amassas',
    'amassât',
    'amassée',
    'amasser',
    'amasses',
    'amassés',
    'amassez',
    'amateur',
    'amaties',
    'amatira',
    'amazone',
    'ambages',
    'ambiant',
    'ambiguë',
    'ambigus',
    'amblais',
    'amblait',
    'amblant',
    'amblent',
    'amblera',
    'ambleur',
    'ambliez',
    'amblons',
    'ambrais',
    'ambrait',
    'ambrant',
    'ambrées',
    'ambrent',
    'ambrera',
    'ambriez',
    'ambrons',
    'aménage',
    'aménagé',
    'amenais',
    'amenait',
    'amenant',
    'amendai',
    'amendas',
    'amendât',
    'amendée',
    'amender',
    'amendes',
    'amendés',
    'amendez',
    'amenées',
    'amènent',
    'amènera',
    'ameniez',
    'aménité',
    'amenons',
    'amerrir',
    'amerris',
    'amerrit',
    'amerrît',
    'ameubli',
    'ameutai',
    'ameutas',
    'ameutât',
    'ameutée',
    'ameuter',
    'ameutes',
    'ameutés',
    'ameutez',
    'amiable',
    'amiante',
    'amibien',
    'amicale',
    'amicaux',
    'amidons',
    'amincie',
    'amincir',
    'amincis',
    'amincit',
    'amincît',
    'aminées',
    'amirale',
    'amiraux',
    'amitiés',
    'amitose',
    'amnésie',
    'amochai',
    'amochas',
    'amochât',
    'amochée',
    'amocher',
    'amoches',
    'amochés',
    'amochez',
    'amodiai',
    'amodias',
    'amodiât',
    'amodiée',
    'amodier',
    'amodies',
    'amodiés',
    'amodiez',
    'amollie',
    'amollir',
    'amollis',
    'amollit',
    'amollît',
    'amorale',
    'amoraux',
    'amorçai',
    'amorças',
    'amorçât',
    'amorcée',
    'amorcer',
    'amorces',
    'amorcés',
    'amorcez',
    'amoroso',
    'amorphe',
    'amortie',
    'amortir',
    'amortis',
    'amortit',
    'amortît',
    'ampères',
    'amphore',
    'ampleur',
    'ampoule',
    'ampoulé',
    'amputai',
    'amputas',
    'amputât',
    'amputée',
    'amputer',
    'amputes',
    'amputés',
    'amputez',
    'amuïmes',
    'amuïrez',
    'amuïtes',
    'amurais',
    'amurait',
    'amurant',
    'amurées',
    'amurent',
    'amurera',
    'amuriez',
    'amurons',
    'amusais',
    'amusait',
    'amusant',
    'amusées',
    'amusent',
    'amusera',
    'amuseur',
    'amusies',
    'amusiez',
    'amusons',
    'amylacé',
    'amylase',
    'amylène',
    'analité',
    'analysa',
    'analyse',
    'analysé',
    'anatife',
    'ancêtre',
    'anchois',
    'anciens',
    'ancolie',
    'ancrage',
    'ancrais',
    'ancrait',
    'ancrant',
    'ancrées',
    'ancrent',
    'ancrera',
    'ancriez',
    'ancrons',
    'andains',
    'andalou',
    'andante',
    'andines',
    'andrène',
    'anéanti',
    'anémiai',
    'anémias',
    'anémiât',
    'anémiée',
    'anémier',
    'anémies',
    'anémiés',
    'anémiez',
    'anémone',
    'âneries',
    'ânesses',
    'angarie',
    'angéite',
    'angelot',
    'angélus',
    'angevin',
    'angines',
    'angiome',
    'anglais',
    'anglets',
    'angoras',
    'anhélai',
    'anhélas',
    'anhélât',
    'anhéler',
    'anhèles',
    'anhélez',
    'anhydre',
    'ânières',
    'aniline',
    'animais',
    'animait',
    'animale',
    'animant',
    'animaux',
    'animées',
    'animent',
    'animera',
    'animiez',
    'animons',
    'anisais',
    'anisait',
    'anisant',
    'anisées',
    'anisent',
    'anisera',
    'anisiez',
    'anisons',
    'annales',
    'anneaux',
    'annelai',
    'annelas',
    'annelât',
    'annelée',
    'anneler',
    'annelés',
    'annelez',
    'annelle',
    'annexai',
    'annexas',
    'annexât',
    'annexée',
    'annexer',
    'annexes',
    'annexés',
    'annexez',
    'annonça',
    'annonce',
    'annoncé',
    'annones',
    'annotai',
    'annotas',
    'annotât',
    'annotée',
    'annoter',
    'annotes',
    'annotés',
    'annotez',
    'annuels',
    'annuité',
    'annulai',
    'annulas',
    'annulât',
    'annulée',
    'annuler',
    'annules',
    'annulés',
    'annulez',
    'anoblie',
    'anoblir',
    'anoblis',
    'anoblit',
    'anoblît',
    'anodine',
    'anodins',
    'anodisa',
    'anodise',
    'anodisé',
    'anomale',
    'anomaux',
    'anomies',
    'ânonnai',
    'ânonnas',
    'ânonnât',
    'ânonnée',
    'ânonner',
    'ânonnes',
    'ânonnés',
    'ânonnez',
    'anonyme',
    'anoraks',
    'anordir',
    'anordis',
    'anordit',
    'anordît',
    'anormal',
    'anosmie',
    'anoures',
    'antenne',
    'anthère',
    'anthrax',
    'antigel',
    'antique',
    'antivol',
    'anuries',
    'anxiété',
    'anxieux',
    'aoriste',
    'aortite',
    'aoûtais',
    'aoûtait',
    'aoûtant',
    'aoûtats',
    'aoûtées',
    'aoûtent',
    'aoûtera',
    'aoûtien',
    'aoûtiez',
    'aoûtons',
    'apaches',
    'apaisai',
    'apaisas',
    'apaisât',
    'apaisée',
    'apaiser',
    'apaises',
    'apaisés',
    'apaisez',
    'apanage',
    'apanagé',
    'apartés',
    'apathie',
    'apepsie',
    'aperçue',
    'aperçus',
    'aperçut',
    'aperçût',
    'apétale',
    'apeurai',
    'apeuras',
    'apeurât',
    'apeurée',
    'apeurer',
    'apeures',
    'apeurés',
    'apeurez',
    'aphasie',
    'aphélie',
    'aphones',
    'aphonie',
    'aphteux',
    'aphylle',
    'apicale',
    'apicaux',
    'apiquai',
    'apiquas',
    'apiquât',
    'apiquée',
    'apiquer',
    'apiques',
    'apiqués',
    'apiquez',
    'apitoie',
    'apitoya',
    'apitoyé',
    'aplanie',
    'aplanir',
    'aplanis',
    'aplanit',
    'aplanît',
    'aplasie',
    'aplatie',
    'aplatir',
    'aplatis',
    'aplatit',
    'aplatît',
    'aplombs',
    'apocope',
    'apogées',
    'apollon',
    'apories',
    'apostai',
    'apostas',
    'apostat',
    'apostât',
    'apostée',
    'aposter',
    'apostes',
    'apostés',
    'apostez',
    'apôtres',
    'appaira',
    'appaire',
    'appairé',
    'apparat',
    'apparia',
    'apparie',
    'apparié',
    'apparue',
    'apparus',
    'apparut',
    'apparût',
    'appâtai',
    'appâtas',
    'appâtât',
    'appâtée',
    'appâter',
    'appâtes',
    'appâtés',
    'appâtez',
    'appeaux',
    'appelai',
    'appelas',
    'appelât',
    'appelée',
    'appeler',
    'appelés',
    'appelez',
    'appelle',
    'appétai',
    'appétas',
    'appétât',
    'appétée',
    'appéter',
    'appétés',
    'appètes',
    'appétez',
    'appétit',
    'appoint',
    'apponta',
    'apponte',
    'apponté',
    'apporta',
    'apporte',
    'apporté',
    'apports',
    'apposai',
    'apposas',
    'apposât',
    'apposée',
    'apposer',
    'apposes',
    'apposés',
    'apposez',
    'apprend',
    'apprêta',
    'apprête',
    'apprêté',
    'apprêts',
    'apprise',
    'appuies',
    'appuyai',
    'appuyas',
    'appuyât',
    'appuyée',
    'appuyer',
    'appuyés',
    'appuyez',
    'apraxie',
    'âpretés',
    'apsides',
    'aptères',
    'aptéryx',
    'apurais',
    'apurait',
    'apurant',
    'apurées',
    'apurent',
    'apurera',
    'apuriez',
    'apurons',
    'aqueduc',
    'aqueuse',
    'aquilin',
    'aquilon',
    'arabica',
    'arabisa',
    'arabise',
    'arabisé',
    'arables',
    'araires',
    'araméen',
    'aramons',
    'arasais',
    'arasait',
    'arasant',
    'arasées',
    'arasent',
    'arasera',
    'arasiez',
    'arasons',
    'arbitra',
    'arbitre',
    'arbitré',
    'arborai',
    'arboras',
    'arborât',
    'arborée',
    'arborer',
    'arbores',
    'arborés',
    'arborez',
    'arbouse',
    'arbuste',
    'arcades',
    'arcanes',
    'arcasse',
    'arceaux',
    'archéen',
    'archère',
    'archers',
    'archets',
    'archiva',
    'archive',
    'archivé',
    'arçonna',
    'arçonne',
    'arçonné',
    'arcures',
    'ardente',
    'ardents',
    'ardeurs',
    'ardoisa',
    'ardoise',
    'ardoisé',
    'aréique',
    'arénacé',
    'aréoles',
    'argenta',
    'argente',
    'argenté',
    'argents',
    'argiles',
    'arguais',
    'arguait',
    'arguant',
    'arguées',
    'arguent',
    'arguera',
    'arguiez',
    'arguons',
    'argutie',
    'aridité',
    'arienne',
    'ariette',
    'arilles',
    'ariosos',
    'arisais',
    'arisait',
    'arisant',
    'arisent',
    'arisera',
    'arisiez',
    'arisons',
    'armadas',
    'armâmes',
    'armasse',
    'armâtes',
    'armerai',
    'armeras',
    'armerez',
    'armeuse',
    'armions',
    'armoire',
    'armoise',
    'armoria',
    'armorie',
    'armorié',
    'armures',
    'arnaqua',
    'arnaque',
    'arnaqué',
    'arnicas',
    'aromate',
    'arondes',
    'arpégea',
    'arpégée',
    'arpéger',
    'arpégés',
    'arpèges',
    'arpégez',
    'arpenta',
    'arpente',
    'arpenté',
    'arpents',
    'arpètes',
    'arpette',
    'arpions',
    'arquais',
    'arquait',
    'arquant',
    'arquées',
    'arquent',
    'arquera',
    'arquiez',
    'arquons',
    'arracha',
    'arrache',
    'arraché',
    'arrange',
    'arrangé',
    'arrenta',
    'arrente',
    'arrenté',
    'arrêtai',
    'arrêtas',
    'arrêtât',
    'arrêtée',
    'arrêter',
    'arrêtes',
    'arrêtés',
    'arrêtez',
    'arriéra',
    'arriéré',
    'arrière',
    'arrimai',
    'arrimas',
    'arrimât',
    'arrimée',
    'arrimer',
    'arrimes',
    'arrimés',
    'arrimez',
    'arrisai',
    'arrisas',
    'arrisât',
    'arriser',
    'arrises',
    'arrisez',
    'arrivai',
    'arrivas',
    'arrivât',
    'arrivée',
    'arriver',
    'arrives',
    'arrivés',
    'arrivez',
    'arrobes',
    'arroche',
    'arrogée',
    'arroger',
    'arrogés',
    'arrogez',
    'arrondi',
    'arrosai',
    'arrosas',
    'arrosât',
    'arrosée',
    'arroser',
    'arroses',
    'arrosés',
    'arrosez',
    'arroyos',
    'arsenal',
    'arsenic',
    'arsines',
    'artères',
    'article',
    'artimon',
    'artisan',
    'artiste',
    'aryenne',
    'asbeste',
    'ascèses',
    'ascètes',
    'ascidie',
    'ascites',
    'asepsie',
    'asexuée',
    'asexuel',
    'asexués',
    'ashrams',
    'asialie',
    'asinien',
    'asocial',
    'aspecta',
    'aspecte',
    'aspecté',
    'aspects',
    'asperge',
    'aspergé',
    'asperme',
    'aspirai',
    'aspiras',
    'aspirât',
    'aspirée',
    'aspirer',
    'aspires',
    'aspirés',
    'aspirez',
    'assagie',
    'assagir',
    'assagis',
    'assagit',
    'assagît',
    'assaini',
    'assauts',
    'assécha',
    'asséché',
    'assèche',
    'assenai',
    'assénai',
    'assenas',
    'assénas',
    'assenât',
    'assénât',
    'assenée',
    'assénée',
    'assener',
    'asséner',
    'assenés',
    'assénés',
    'assènes',
    'assenez',
    'assénez',
    'asseoir',
    'asservi',
    'asseyes',
    'asseyez',
    'assidue',
    'assidus',
    'assieds',
    'assiégé',
    'assiège',
    'assiéra',
    'assigna',
    'assigne',
    'assigné',
    'assîmes',
    'assises',
    'assisse',
    'assista',
    'assiste',
    'assisté',
    'assîtes',
    'associa',
    'associe',
    'associé',
    'assoies',
    'assoira',
    'assolai',
    'assolas',
    'assolât',
    'assolée',
    'assoler',
    'assoles',
    'assolés',
    'assolez',
    'assomma',
    'assomme',
    'assommé',
    'assonai',
    'assonas',
    'assonât',
    'assoner',
    'assones',
    'assonez',
    'assorti',
    'assoupi',
    'assouvi',
    'assoyez',
    'assumai',
    'assumas',
    'assumât',
    'assumée',
    'assumer',
    'assumes',
    'assumés',
    'assumez',
    'assurai',
    'assuras',
    'assurât',
    'assurée',
    'assurer',
    'assures',
    'assurés',
    'assurez',
    'astasie',
    'astates',
    'astérie',
    'asthmes',
    'asticot',
    'astiqua',
    'astique',
    'astiqué',
    'astrale',
    'astraux',
    'astuces',
    'ataxies',
    'atelier',
    'athanor',
    'athénée',
    'athlète',
    'athymie',
    'atlante',
    'atomisa',
    'atomise',
    'atomisé',
    'atonale',
    'atonals',
    'atonaux',
    'atonies',
    'atriums',
    'atroces',
    'attabla',
    'attable',
    'attablé',
    'attacha',
    'attache',
    'attaché',
    'attaqua',
    'attaque',
    'attaqué',
    'attarda',
    'attarde',
    'attardé',
    'atteins',
    'atteint',
    'attelai',
    'attelas',
    'attelât',
    'attelée',
    'atteler',
    'attelés',
    'attelez',
    'attelle',
    'attende',
    'attends',
    'attendu',
    'attenta',
    'attente',
    'attenté',
    'atténua',
    'atténue',
    'atténué',
    'atterra',
    'atterre',
    'atterré',
    'atterri',
    'attesta',
    'atteste',
    'attesté',
    'attiédi',
    'attifai',
    'attifas',
    'attifât',
    'attifée',
    'attifer',
    'attifes',
    'attifés',
    'attifez',
    'attigea',
    'attiger',
    'attiges',
    'attigez',
    'attique',
    'attirai',
    'attiras',
    'attirât',
    'attirée',
    'attirer',
    'attires',
    'attirés',
    'attirez',
    'attisai',
    'attisas',
    'attisât',
    'attisée',
    'attiser',
    'attises',
    'attisés',
    'attisez',
    'attitra',
    'attitre',
    'attitré',
    'attrait',
    'attrapa',
    'attrape',
    'attrapé',
    'aubades',
    'aubaine',
    'aubères',
    'auberge',
    'aubette',
    'aubiers',
    'aucubas',
    'audaces',
    'au-delà',
    'audible',
    'auditif',
    'augment',
    'augurai',
    'augural',
    'auguras',
    'augurât',
    'augurée',
    'augurer',
    'augures',
    'augurés',
    'augurez',
    'auguste',
    'aumônes',
    'auréola',
    'auréole',
    'auréolé',
    'aurifia',
    'aurifie',
    'aurifié',
    'auriges',
    'aurions',
    'aurochs',
    'aurores',
    'austère',
    'austral',
    'auteurs',
    'autisme',
    'autiste',
    'autobus',
    'autocar',
    'automne',
    'auvents',
    'auxines',
    'avachie',
    'avachir',
    'avachis',
    'avachit',
    'avachît',
    'avaient',
    'avalais',
    'avalait',
    'avalant',
    'avalées',
    'avalent',
    'avalera',
    'avaleur',
    'avaliez',
    'avalisa',
    'avalise',
    'avalisé',
    'avalons',
    'avançai',
    'avanças',
    'avançât',
    'avancée',
    'avancer',
    'avances',
    'avancés',
    'avancez',
    'avanies',
    'avariai',
    'avarias',
    'avariât',
    'avarice',
    'avariée',
    'avarier',
    'avaries',
    'avariés',
    'avariez',
    'avatars',
    'aveline',
    'avenant',
    'avenirs',
    'avenues',
    'avérais',
    'avérait',
    'avérant',
    'avérées',
    'avèrent',
    'avérera',
    'avériez',
    'avérons',
    'averses',
    'avertie',
    'avertir',
    'avertis',
    'avertit',
    'avertît',
    'aveugla',
    'aveugle',
    'aveuglé',
    'aveulie',
    'aveulir',
    'aveulis',
    'aveulit',
    'aveulît',
    'aviaire',
    'avicole',
    'avidité',
    'avilies',
    'avilira',
    'avinais',
    'avinait',
    'avinant',
    'avinées',
    'avinent',
    'avinera',
    'aviniez',
    'avinons',
    'avirons',
    'avisais',
    'avisait',
    'avisant',
    'avisées',
    'avisent',
    'avisera',
    'avisiez',
    'avisons',
    'avivage',
    'avivais',
    'avivait',
    'avivant',
    'avivées',
    'avivent',
    'avivera',
    'aviviez',
    'avivons',
    'avocate',
    'avocats',
    'avoines',
    'avortai',
    'avortas',
    'avortât',
    'avortée',
    'avorter',
    'avortes',
    'avortés',
    'avortez',
    'avorton',
    'avouais',
    'avouait',
    'avouant',
    'avouées',
    'avouent',
    'avouera',
    'avouiez',
    'avouons',
    'axaient',
    'axasses',
    'axerais',
    'axerait',
    'axèrent',
    'axeriez',
    'axerons',
    'axeront',
    'axiomes',
    'axolotl',
    'axonges',
    'azalées',
    'azerole',
    'azimuté',
    'azimuts',
    'azoïque',
    'azotate',
    'azotées',
    'azoteux',
    'azotite',
    'aztèque',
    'azulejo',
    'azurage',
    'azurais',
    'azurait',
    'azurant',
    'azurées',
    'azurent',
    'azurera',
    'azuriez',
    'azurite',
    'azurons',
    'babilla',
    'babille',
    'babillé',
    'babines',
    'babiole',
    'babouin',
    'baccara',
    'bâchage',
    'bâchais',
    'bâchait',
    'bâchant',
    'bâchées',
    'bâchent',
    'bâchera',
    'bâchiez',
    'bâchons',
    'bachota',
    'bachote',
    'bachoté',
    'bachots',
    'bacille',
    'bâclage',
    'bâclais',
    'bâclait',
    'bâclant',
    'bâclées',
    'bâclent',
    'bâclera',
    'bâcliez',
    'bâclons',
    'badaude',
    'badauds',
    'baderne',
    'badiane',
    'badinai',
    'badinas',
    'badinât',
    'badiner',
    'badines',
    'badinez',
    'baffais',
    'baffait',
    'baffant',
    'baffées',
    'baffent',
    'baffera',
    'baffiez',
    'baffles',
    'baffons',
    'bafouai',
    'bafouas',
    'bafouât',
    'bafouée',
    'bafouer',
    'bafoues',
    'bafoués',
    'bafouez',
    'bâfrais',
    'bâfrait',
    'bâfrant',
    'bâfrées',
    'bâfrent',
    'bâfrera',
    'bâfreur',
    'bâfriez',
    'bâfrons',
    'bagages',
    'bagarra',
    'bagarre',
    'bagarré',
    'bagasse',
    'bagnard',
    'bagnole',
    'baguage',
    'baguais',
    'baguait',
    'baguant',
    'baguées',
    'baguent',
    'baguera',
    'baguier',
    'baguiez',
    'baguons',
    'baierai',
    'baieras',
    'baierez',
    'baignai',
    'baignas',
    'baignât',
    'baignée',
    'baigner',
    'baignes',
    'baignés',
    'baignez',
    'baillai',
    'bâillai',
    'baillas',
    'bâillas',
    'baillât',
    'bâillât',
    'baillée',
    'bailler',
    'bâiller',
    'bailles',
    'baillés',
    'bâilles',
    'baillez',
    'bâillez',
    'baillis',
    'bâillon',
    'baisais',
    'baisait',
    'baisant',
    'baisées',
    'baisent',
    'baisera',
    'baisers',
    'baiseur',
    'baisiez',
    'baisons',
    'baisota',
    'baisote',
    'baisoté',
    'baissai',
    'baissas',
    'baissât',
    'baissée',
    'baisser',
    'baisses',
    'baissés',
    'baissez',
    'bajoues',
    'bajoyer',
    'baklava',
    'baladai',
    'baladas',
    'baladât',
    'baladée',
    'balader',
    'balades',
    'baladés',
    'baladez',
    'baladin',
    'balafon',
    'balafra',
    'balafre',
    'balafré',
    'balaies',
    'balança',
    'balance',
    'balancé',
    'balanes',
    'balatas',
    'balayai',
    'balayas',
    'balayât',
    'balayée',
    'balayer',
    'balayes',
    'balayés',
    'balayez',
    'balcons',
    'baleina',
    'baleine',
    'baleiné',
    'balèvre',
    'balisai',
    'balisas',
    'balisât',
    'balisée',
    'baliser',
    'balises',
    'balisés',
    'balisez',
    'baliste',
    'ballade',
    'ballais',
    'ballait',
    'ballant',
    'ballast',
    'ballent',
    'ballera',
    'ballets',
    'balliez',
    'ballons',
    'ballote',
    'ballots',
    'balourd',
    'balzane',
    'balzans',
    'bambins',
    'bambous',
    'banales',
    'bananes',
    'bancale',
    'bancals',
    'banches',
    'bandage',
    'bandais',
    'bandait',
    'bandant',
    'bandeau',
    'bandées',
    'bandent',
    'bandera',
    'bandiez',
    'bandits',
    'bandons',
    'banians',
    'banlons',
    'bannais',
    'bannait',
    'bannant',
    'bannées',
    'bannent',
    'bannera',
    'bannies',
    'banniez',
    'bannira',
    'bannons',
    'banquai',
    'banquas',
    'banquât',
    'banquée',
    'banquer',
    'banques',
    'banqués',
    'banquet',
    'banquez',
    'bantoue',
    'bantous',
    'baobabs',
    'baptême',
    'baptisa',
    'baptise',
    'baptisé',
    'baqueta',
    'baqueté',
    'baquets',
    'barakas',
    'baraqua',
    'baraque',
    'baraqué',
    'baratin',
    'baratta',
    'baratte',
    'baratté',
    'barbais',
    'barbait',
    'barbant',
    'barbare',
    'barbeau',
    'barbées',
    'barbelé',
    'barbent',
    'barbera',
    'barbets',
    'barbier',
    'barbiez',
    'barbons',
    'barbota',
    'barbote',
    'barboté',
    'barbues',
    'barbule',
    'bardage',
    'bardais',
    'bardait',
    'bardane',
    'bardant',
    'bardeau',
    'bardées',
    'bardent',
    'bardera',
    'bardiez',
    'bardons',
    'bardots',
    'barèmes',
    'barétai',
    'barétas',
    'barétât',
    'baréter',
    'barètes',
    'barétez',
    'bariola',
    'bariole',
    'bariolé',
    'barlong',
    'barmaid',
    'barmans',
    'baronna',
    'baronne',
    'baronné',
    'baroque',
    'barouda',
    'baroude',
    'baroudé',
    'barouds',
    'baroufs',
    'barques',
    'barrage',
    'barrais',
    'barrait',
    'barrant',
    'barreau',
    'barrées',
    'barrent',
    'barrera',
    'barreur',
    'barriez',
    'barrira',
    'barrons',
    'barrots',
    'baryons',
    'barytes',
    'baryton',
    'baryums',
    'barzoïs',
    'basales',
    'basalte',
    'basâmes',
    'basanai',
    'basanas',
    'basanât',
    'basanée',
    'basaner',
    'basanes',
    'basanés',
    'basanez',
    'basasse',
    'basâtes',
    'bascula',
    'bascule',
    'basculé',
    'baselle',
    'baserai',
    'baseras',
    'baserez',
    'basides',
    'basilic',
    'basions',
    'basique',
    'baskets',
    'bas-mât',
    'basoche',
    'basques',
    'bassets',
    'bassina',
    'bassine',
    'bassiné',
    'bassins',
    'bassons',
    'bastide',
    'basting',
    'bastion',
    'bâtâmes',
    'bâtarde',
    'bâtards',
    'bâtasse',
    'bâtâtes',
    'batavia',
    'bateaux',
    'batelai',
    'batelas',
    'batelât',
    'batelée',
    'bateler',
    'batelés',
    'batelet',
    'batelez',
    'batelle',
    'bâterai',
    'bâteras',
    'bâterez',
    'bathyal',
    'bâtîmes',
    'bâtions',
    'bâtirai',
    'bâtiras',
    'bâtirez',
    'bâtisse',
    'batiste',
    'bâtîtes',
    'bâtonna',
    'bâtonne',
    'bâtonné',
    'batoude',
    'battage',
    'battais',
    'battait',
    'battant',
    'battées',
    'battent',
    'batteur',
    'battiez',
    'battoir',
    'battons',
    'battrai',
    'battras',
    'battrez',
    'battues',
    'batture',
    'baudets',
    'baugeai',
    'baugeas',
    'baugeât',
    'baugées',
    'baugent',
    'baugera',
    'baugiez',
    'baumier',
    'bauxite',
    'bavâmes',
    'bavarda',
    'bavarde',
    'bavardé',
    'bavards',
    'bavassa',
    'bavasse',
    'bavassé',
    'bavâtes',
    'baverai',
    'baveras',
    'baverez',
    'bavette',
    'baveuse',
    'bavions',
    'bavocha',
    'bavoche',
    'bavoché',
    'bavoirs',
    'bavolet',
    'bavures',
    'bayâmes',
    'bayasse',
    'bayâtes',
    'bayerai',
    'bayeras',
    'bayerez',
    'bayions',
    'bazarda',
    'bazarde',
    'bazardé',
    'bazooka',
    'beagles',
    'béaient',
    'béances',
    'béantes',
    'béasses',
    'beatnik',
    'beaupré',
    'beautés',
    'bécanes',
    'bécarre',
    'bécasse',
    'bêchage',
    'bêchais',
    'bêchait',
    'bêchant',
    'bêchées',
    'bêchent',
    'bêchera',
    'bêcheur',
    'bêchiez',
    'bêchons',
    'bécotai',
    'bécotas',
    'bécotât',
    'bécotée',
    'bécoter',
    'bécotes',
    'bécotés',
    'bécotez',
    'becquai',
    'becquas',
    'becquât',
    'becquée',
    'becquer',
    'becques',
    'becqués',
    'becquez',
    'bectais',
    'bectait',
    'bectant',
    'bectées',
    'bectent',
    'bectera',
    'bectiez',
    'bectons',
    'bedaine',
    'bédanes',
    'bedeaux',
    'bédégar',
    'bedonna',
    'bedonne',
    'bedonné',
    'bédouin',
    'béerais',
    'béerait',
    'béèrent',
    'béeriez',
    'béerons',
    'béeront',
    'beffroi',
    'bégaies',
    'bégayai',
    'bégayas',
    'bégayât',
    'bégayée',
    'bégayer',
    'bégayes',
    'bégayés',
    'bégayez',
    'bégonia',
    'bégueta',
    'bégueté',
    'béguète',
    'béguine',
    'béguins',
    'beignes',
    'beignet',
    'béjaune',
    'bêlâmes',
    'bêlante',
    'bêlants',
    'bêlasse',
    'bêlâtes',
    'bêlerai',
    'bêleras',
    'bêlerez',
    'belette',
    'bélière',
    'béliers',
    'bêlions',
    'bélître',
    'belotes',
    'belouga',
    'bélouga',
    'bélugas',
    'bénarde',
    'bengali',
    'bénigne',
    'bénîmes',
    'bénirai',
    'béniras',
    'bénirez',
    'bénisse',
    'bénites',
    'bénîtes',
    'benjoin',
    'benoîte',
    'benoîts',
    'benthos',
    'benzène',
    'benzine',
    'benzols',
    'béotien',
    'béquais',
    'béquait',
    'béquant',
    'béquées',
    'bèquent',
    'béquera',
    'béqueta',
    'béqueté',
    'béquiez',
    'béquons',
    'berbère',
    'bercail',
    'berçais',
    'berçait',
    'berçant',
    'berceau',
    'bercées',
    'bercent',
    'bercera',
    'berceur',
    'berciez',
    'berçons',
    'bergère',
    'bergers',
    'berline',
    'bermuda',
    'bernais',
    'bernait',
    'bernant',
    'bernées',
    'bernent',
    'bernera',
    'berniez',
    'bernons',
    'berthon',
    'besaces',
    'besants',
    'bésigue',
    'besogna',
    'besogne',
    'besogné',
    'besoins',
    'bestial',
    'bétails',
    'bêtasse',
    'bêtifia',
    'bêtifie',
    'bêtifié',
    'bêtisai',
    'bêtisas',
    'bêtisât',
    'bêtiser',
    'bêtises',
    'bêtisez',
    'bétoine',
    'bétoire',
    'bétonna',
    'bétonne',
    'bétonné',
    'bétyles',
    'beuglai',
    'beuglas',
    'beuglât',
    'beuglée',
    'beugler',
    'beugles',
    'beuglés',
    'beuglez',
    'beurrai',
    'beurras',
    'beurrât',
    'beurrée',
    'beurrer',
    'beurres',
    'beurrés',
    'beurrez',
    'bézoard',
    'biacide',
    'biaisai',
    'biaisas',
    'biaisât',
    'biaisée',
    'biaiser',
    'biaises',
    'biaisés',
    'biaisez',
    'bibelot',
    'biberon',
    'bibines',
    'bicarré',
    'bichais',
    'bichait',
    'bichant',
    'bichent',
    'bichera',
    'bichiez',
    'bichons',
    'bichota',
    'bichote',
    'bichoté',
    'bicoque',
    'bicorne',
    'bicycle',
    'bidasse',
    'bidents',
    'bidoche',
    'bidonna',
    'bidonne',
    'bidonné',
    'bidules',
    'bielles',
    'biennal',
    'bientôt',
    'bifaces',
    'biffage',
    'biffais',
    'biffait',
    'biffant',
    'biffées',
    'biffent',
    'biffera',
    'biffiez',
    'biffins',
    'biffons',
    'biffure',
    'bifides',
    'bifocal',
    'bifteck',
    'bigames',
    'bigamie',
    'bigarra',
    'bigarre',
    'bigarré',
    'biglais',
    'biglait',
    'biglant',
    'biglées',
    'biglent',
    'biglera',
    'bigleux',
    'bigliez',
    'biglons',
    'bigorna',
    'bigorne',
    'bigorné',
    'bigotes',
    'bigoudi',
    'biguine',
    'bikinis',
    'bilabié',
    'bilames',
    'bilâmes',
    'bilasse',
    'bilâtes',
    'bilerai',
    'bileras',
    'bilerez',
    'bileuse',
    'bilieux',
    'bilions',
    'billard',
    'billeté',
    'billets',
    'billion',
    'billons',
    'billots',
    'bilobée',
    'bilobés',
    'biloqua',
    'biloque',
    'biloqué',
    'bimanes',
    'binages',
    'binaire',
    'binâmes',
    'binards',
    'binasse',
    'binâtes',
    'binerai',
    'bineras',
    'binerez',
    'binette',
    'bineuse',
    'binions',
    'binious',
    'binocle',
    'biocide',
    'biopsie',
    'biotite',
    'biotope',
    'biotype',
    'bioxyde',
    'bipales',
    'biparti',
    'bipèdes',
    'bipenné',
    'biphasé',
    'bipieds',
    'biplace',
    'biplans',
    'biquets',
    'birèmes',
    'biroute',
    'bisâmes',
    'bisasse',
    'bisâtes',
    'biscôme',
    'biscuit',
    'biseaux',
    'biserai',
    'biseras',
    'biserez',
    'bisexué',
    'bisions',
    'bismuth',
    'bisquai',
    'bisquas',
    'bisquât',
    'bisquée',
    'bisquer',
    'bisques',
    'bisqués',
    'bisquez',
    'bissais',
    'bissait',
    'bissant',
    'bissées',
    'bissent',
    'bissera',
    'bissiez',
    'bissons',
    'bistrai',
    'bistras',
    'bistrât',
    'bistrée',
    'bistrer',
    'bistres',
    'bistrés',
    'bistrez',
    'bistros',
    'bistrot',
    'bitâmes',
    'bitasse',
    'bitâtes',
    'biterai',
    'biteras',
    'biterez',
    'bitions',
    'bitonal',
    'bitords',
    'bittais',
    'bittait',
    'bittant',
    'bittées',
    'bittent',
    'bittera',
    'bittiez',
    'bittons',
    'bittura',
    'bitture',
    'bitturé',
    'bitumai',
    'bitumas',
    'bitumât',
    'bitumée',
    'bitumer',
    'bitumes',
    'bitumés',
    'bitumez',
    'biturai',
    'bituras',
    'biturât',
    'biturée',
    'biturer',
    'bitures',
    'biturés',
    'biturez',
    'bivalve',
    'biveaux',
    'bivouac',
    'bizarre',
    'bizutai',
    'bizutas',
    'bizutât',
    'bizutée',
    'bizuter',
    'bizutes',
    'bizutés',
    'bizutez',
    'blablas',
    'blafard',
    'blaguai',
    'blaguas',
    'blaguât',
    'blaguée',
    'blaguer',
    'blagues',
    'blagués',
    'blaguez',
    'blairai',
    'blairas',
    'blairât',
    'blairée',
    'blairer',
    'blaires',
    'blairés',
    'blairez',
    'blâmais',
    'blâmait',
    'blâmant',
    'blâmées',
    'blâment',
    'blâmera',
    'blâmiez',
    'blâmons',
    'blanche',
    'blanchi',
    'blasais',
    'blasait',
    'blasant',
    'blasées',
    'blasent',
    'blasera',
    'blasiez',
    'blasons',
    'blatéra',
    'blatéré',
    'blatère',
    'blattes',
    'blazers',
    'blèches',
    'blêmais',
    'blêmait',
    'blêmant',
    'blêmera',
    'blêmies',
    'blêmiez',
    'blêmons',
    'blendes',
    'blennie',
    'blésais',
    'blésait',
    'blésant',
    'blésées',
    'blèsent',
    'blésera',
    'blésiez',
    'blésité',
    'blésons',
    'blessai',
    'blessas',
    'blessât',
    'blessée',
    'blesser',
    'blesses',
    'blessés',
    'blessez',
    'blettes',
    'blettir',
    'blettis',
    'blettit',
    'blettît',
    'bleuets',
    'bleuies',
    'bleuira',
    'bleutai',
    'bleutas',
    'bleutât',
    'bleutée',
    'bleuter',
    'bleutes',
    'bleutés',
    'bleutez',
    'blindai',
    'blindas',
    'blindât',
    'blindée',
    'blinder',
    'blindes',
    'blindés',
    'blindez',
    'blister',
    'blocage',
    'blondel',
    'blondes',
    'blondie',
    'blondin',
    'blondir',
    'blondis',
    'blondit',
    'blondît',
    'bloomer',
    'bloquai',
    'bloquas',
    'bloquât',
    'bloquée',
    'bloquer',
    'bloques',
    'bloqués',
    'bloquez',
    'blottie',
    'blottir',
    'blottis',
    'blottit',
    'blottît',
    'blousai',
    'blousas',
    'blousât',
    'blousée',
    'blouser',
    'blouses',
    'blousés',
    'blousez',
    'blouson',
    'bluette',
    'bluffai',
    'bluffas',
    'bluffât',
    'bluffée',
    'bluffer',
    'bluffes',
    'bluffés',
    'bluffez',
    'blutage',
    'blutais',
    'blutait',
    'blutant',
    'blutées',
    'blutent',
    'blutera',
    'blutiez',
    'blutoir',
    'blutons',
    'bobards',
    'bobèche',
    'bobinai',
    'bobinas',
    'bobinât',
    'bobinée',
    'bobiner',
    'bobines',
    'bobinés',
    'bobinez',
    'bobonne',
    'bocager',
    'bocages',
    'bocarda',
    'bocarde',
    'bocardé',
    'bocards',
    'boësses',
    'boettai',
    'boettas',
    'boettât',
    'boettée',
    'boetter',
    'boettes',
    'boettés',
    'boettez',
    'bogheis',
    'bohèmes',
    'boilles',
    'boirais',
    'boirait',
    'boiriez',
    'boirons',
    'boiront',
    'boisage',
    'boisais',
    'boisait',
    'boisant',
    'boisées',
    'boisent',
    'boisera',
    'boiseur',
    'boisiez',
    'boisons',
    'boisson',
    'boitais',
    'boitait',
    'boitant',
    'boitent',
    'boitera',
    'boiteux',
    'boîtier',
    'boitiez',
    'boitons',
    'boivent',
    'bolducs',
    'boléros',
    'bolides',
    'boliers',
    'bolivar',
    'bollard',
    'bombage',
    'bombais',
    'bombait',
    'bombant',
    'bombées',
    'bombent',
    'bombera',
    'bombiez',
    'bombons',
    'bonaces',
    'bonasse',
    'bonbons',
    'bondais',
    'bondait',
    'bondant',
    'bondées',
    'bondent',
    'bondera',
    'bondiez',
    'bondira',
    'bondons',
    'bondrée',
    'bonheur',
    'boniche',
    'bonifia',
    'bonifie',
    'bonifié',
    'bonites',
    'bonjour',
    'bonnets',
    'bonsaïs',
    'bonsoir',
    'booléen',
    'booster',
    'borates',
    'bordage',
    'bordais',
    'bordait',
    'bordant',
    'bordées',
    'bordels',
    'bordent',
    'bordera',
    'bordier',
    'bordiez',
    'bordons',
    'bordure',
    'boréale',
    'boréaux',
    'borgnes',
    'boriqué',
    'bornage',
    'bornais',
    'bornait',
    'bornant',
    'bornées',
    'bornent',
    'bornera',
    'borniez',
    'bornoie',
    'bornons',
    'bornoya',
    'bornoyé',
    'bortsch',
    'boscots',
    'bosquet',
    'bossage',
    'bossais',
    'bossait',
    'bossant',
    'bossées',
    'bossela',
    'bosselé',
    'bossent',
    'bossera',
    'bosseur',
    'bossiez',
    'bossoir',
    'bossons',
    'bossuai',
    'bossuas',
    'bossuât',
    'bossuée',
    'bossuer',
    'bossues',
    'bossués',
    'bossuez',
    'bostons',
    'bottais',
    'bottait',
    'bottant',
    'bottées',
    'bottela',
    'bottelé',
    'bottent',
    'bottera',
    'bottier',
    'bottiez',
    'bottine',
    'bottins',
    'bottons',
    'boubous',
    'boucana',
    'boucane',
    'boucané',
    'boucans',
    'boucaus',
    'bouchai',
    'bouchas',
    'bouchât',
    'bouchée',
    'boucher',
    'bouches',
    'bouchés',
    'bouchez',
    'bouchon',
    'bouchot',
    'bouclai',
    'bouclas',
    'bouclât',
    'bouclée',
    'boucler',
    'boucles',
    'bouclés',
    'bouclez',
    'boudais',
    'boudait',
    'boudant',
    'bouddha',
    'boudées',
    'boudent',
    'boudera',
    'boudeur',
    'boudiez',
    'boudina',
    'boudine',
    'boudiné',
    'boudins',
    'boudoir',
    'boudons',
    'boueurs',
    'boueuse',
    'bouffai',
    'bouffas',
    'bouffât',
    'bouffée',
    'bouffer',
    'bouffes',
    'bouffés',
    'bouffez',
    'bouffie',
    'bouffir',
    'bouffis',
    'bouffit',
    'bouffît',
    'bouffon',
    'bougeai',
    'bougeas',
    'bougeât',
    'bougées',
    'bougent',
    'bougera',
    'bougies',
    'bougiez',
    'bougnat',
    'bougons',
    'bougran',
    'bougres',
    'bouille',
    'bouilli',
    'boulaie',
    'boulais',
    'boulait',
    'boulant',
    'bouleau',
    'boulées',
    'boulent',
    'boulera',
    'bouleté',
    'boulets',
    'boulier',
    'bouliez',
    'bouline',
    'boulins',
    'bouloir',
    'boulons',
    'boulots',
    'boument',
    'boumera',
    'bouquet',
    'bouquin',
    'bourbes',
    'bourbon',
    'bourdai',
    'bourdas',
    'bourdât',
    'bourder',
    'bourdes',
    'bourdez',
    'bourdon',
    'bourrai',
    'bourras',
    'bourrât',
    'bourrée',
    'bourrer',
    'bourres',
    'bourrés',
    'bourrez',
    'bourrin',
    'bourrue',
    'bourrus',
    'bourses',
    'bouseux',
    'bousier',
    'bousins',
    'boutade',
    'boutais',
    'boutait',
    'boutant',
    'boutées',
    'boutent',
    'boutera',
    'bouteur',
    'boutiez',
    'boutoir',
    'boutons',
    'boutres',
    'boutura',
    'bouture',
    'bouturé',
    'bouvets',
    'bouvier',
    'bouvril',
    'bovidés',
    'bowling',
    'boxâmes',
    'boxasse',
    'boxâtes',
    'boxerai',
    'boxeras',
    'boxerez',
    'boxeurs',
    'boxions',
    'boyards',
    'boycott',
    'brabant',
    'bractée',
    'bradage',
    'bradais',
    'bradait',
    'bradant',
    'bradées',
    'bradent',
    'bradera',
    'bradeur',
    'bradiez',
    'bradons',
    'bradype',
    'braient',
    'braiera',
    'brailla',
    'braille',
    'braillé',
    'braisai',
    'braisas',
    'braisât',
    'braisée',
    'braiser',
    'braises',
    'braisés',
    'braisez',
    'bramais',
    'bramait',
    'bramant',
    'bramées',
    'brament',
    'bramera',
    'bramiez',
    'bramons',
    'brancha',
    'branche',
    'branché',
    'branchu',
    'brandes',
    'brandie',
    'brandir',
    'brandis',
    'brandit',
    'brandît',
    'brandon',
    'branlai',
    'branlas',
    'branlât',
    'branlée',
    'branler',
    'branles',
    'branlés',
    'branlez',
    'brantes',
    'braquai',
    'braquas',
    'braquât',
    'braquée',
    'braquer',
    'braques',
    'braqués',
    'braquet',
    'braquez',
    'brasage',
    'brasais',
    'brasait',
    'brasant',
    'brasées',
    'brasent',
    'brasera',
    'brasero',
    'brasier',
    'brasiez',
    'brasons',
    'brassai',
    'brassas',
    'brassât',
    'brassée',
    'brasser',
    'brasses',
    'brassés',
    'brassez',
    'brassin',
    'brasure',
    'bravade',
    'bravais',
    'bravait',
    'bravant',
    'bravées',
    'bravent',
    'bravera',
    'braviez',
    'bravons',
    'brayais',
    'brayait',
    'brayant',
    'brayées',
    'brayent',
    'brayera',
    'brayiez',
    'brayons',
    'brèches',
    'bréchet',
    'brélais',
    'brêlais',
    'brélait',
    'brêlait',
    'brelans',
    'brélant',
    'brêlant',
    'brélées',
    'brêlées',
    'brèlent',
    'brêlent',
    'brélera',
    'brêlera',
    'bréliez',
    'brêliez',
    'brellai',
    'brellas',
    'brellât',
    'brellée',
    'breller',
    'brelles',
    'brellés',
    'brellez',
    'brélons',
    'brêlons',
    'bretons',
    'brettai',
    'brettas',
    'brettât',
    'brettée',
    'bretter',
    'brettes',
    'brettés',
    'brettez',
    'bretzel',
    'breveta',
    'breveté',
    'brevets',
    'brévité',
    'briards',
    'bricola',
    'bricole',
    'bricolé',
    'bridais',
    'bridait',
    'bridant',
    'bridées',
    'brident',
    'bridera',
    'bridgea',
    'bridger',
    'bridges',
    'bridgez',
    'bridiez',
    'bridons',
    'brifais',
    'brifait',
    'brifant',
    'brifées',
    'brifent',
    'brifera',
    'briffai',
    'briffas',
    'briffât',
    'briffée',
    'briffer',
    'briffes',
    'briffés',
    'briffez',
    'brifiez',
    'brifons',
    'brigade',
    'brigand',
    'briguai',
    'briguas',
    'briguât',
    'briguée',
    'briguer',
    'brigues',
    'brigués',
    'briguez',
    'brillai',
    'brillas',
    'brillât',
    'briller',
    'brilles',
    'brillez',
    'brimade',
    'brimais',
    'brimait',
    'brimant',
    'brimées',
    'briment',
    'brimera',
    'brimiez',
    'brimons',
    'bringue',
    'brioche',
    'brioché',
    'briquai',
    'briquas',
    'briquât',
    'briquée',
    'briquer',
    'briques',
    'briqués',
    'briquet',
    'briquez',
    'brisais',
    'brisait',
    'brisant',
    'brisées',
    'brisent',
    'brisera',
    'briseur',
    'brisiez',
    'briskas',
    'brisons',
    'brisque',
    'bristol',
    'brisure',
    'brocard',
    'brochai',
    'brochas',
    'brochât',
    'brochée',
    'brocher',
    'broches',
    'brochés',
    'brochet',
    'brochez',
    'brocoli',
    'brodais',
    'brodait',
    'brodant',
    'brodées',
    'brodent',
    'brodera',
    'brodeur',
    'brodiez',
    'brodons',
    'broient',
    'broiera',
    'bromate',
    'bromure',
    'broncha',
    'bronche',
    'bronché',
    'broncho',
    'bronzai',
    'bronzas',
    'bronzât',
    'bronzée',
    'bronzer',
    'bronzes',
    'bronzés',
    'bronzez',
    'brossai',
    'brossas',
    'brossât',
    'brossée',
    'brosser',
    'brosses',
    'brossés',
    'brossez',
    'brouets',
    'brousse',
    'broutai',
    'broutas',
    'broutât',
    'broutée',
    'brouter',
    'broutes',
    'broutés',
    'broutez',
    'broyage',
    'broyais',
    'broyait',
    'broyant',
    'broyées',
    'broyeur',
    'broyiez',
    'broyons',
    'bruants',
    'bruches',
    'brucine',
    'brugnon',
    'bruîmes',
    'bruinât',
    'bruiner',
    'bruines',
    'bruirai',
    'bruiras',
    'bruirez',
    'bruisse',
    'bruitai',
    'bruitas',
    'bruitât',
    'bruiter',
    'bruites',
    'bruîtes',
    'bruitez',
    'brûlage',
    'brûlais',
    'brûlait',
    'brûlant',
    'brûlées',
    'brûlent',
    'brûlera',
    'brûleur',
    'brûliez',
    'brûloir',
    'brûlons',
    'brûlots',
    'brûlure',
    'brumait',
    'brumera',
    'brumeux',
    'brunchs',
    'brunets',
    'brunies',
    'brunira',
    'brusqua',
    'brusque',
    'brusqué',
    'brutale',
    'brutaux',
    'bruyant',
    'bruyère',
    'bryones',
    'bubales',
    'buccale',
    'buccaux',
    'buccins',
    'bûchais',
    'bûchait',
    'bûchant',
    'bûchées',
    'bûchent',
    'bûchera',
    'bûchers',
    'bûcheur',
    'bûchiez',
    'bûchons',
    'bucrane',
    'budgets',
    'buffets',
    'buffles',
    'bugrane',
    'buisson',
    'bulbeux',
    'bulgare',
    'bulleux',
    'bunkers',
    'bureaux',
    'burelée',
    'burelés',
    'burelle',
    'burette',
    'burgaus',
    'burinai',
    'burinas',
    'burinât',
    'burinée',
    'buriner',
    'burines',
    'burinés',
    'burinez',
    'burnous',
    'busards',
    'bussent',
    'bussiez',
    'bustier',
    'butâmes',
    'butasse',
    'butâtes',
    'buterai',
    'buteras',
    'buterez',
    'buteurs',
    'butinai',
    'butinas',
    'butinât',
    'butinée',
    'butiner',
    'butines',
    'butinés',
    'butinez',
    'butions',
    'butoirs',
    'butomes',
    'buttage',
    'buttais',
    'buttait',
    'buttant',
    'buttées',
    'buttent',
    'buttera',
    'buttiez',
    'buttoir',
    'buttons',
    'buvable',
    'buvards',
    'buvette',
    'buveurs',
    'buveuse',
    'buvions',
    'buvotai',
    'buvotas',
    'buvotât',
    'buvoter',
    'buvotes',
    'buvotez',
    'bye-bye',
    'cabalai',
    'cabalas',
    'cabalât',
    'cabaler',
    'cabales',
    'cabalez',
    'cabanai',
    'cabanas',
    'cabanât',
    'cabanée',
    'cabaner',
    'cabanes',
    'cabanés',
    'cabanez',
    'cabanon',
    'cabaret',
    'cabiais',
    'cabines',
    'cabinet',
    'câblage',
    'câblais',
    'câblait',
    'câblant',
    'câblées',
    'câblent',
    'câblera',
    'câbleur',
    'câblier',
    'câbliez',
    'câblons',
    'câblots',
    'caboche',
    'cabossa',
    'cabosse',
    'cabossé',
    'cabotai',
    'cabotas',
    'cabotât',
    'caboter',
    'cabotes',
    'cabotez',
    'cabotin',
    'cabrais',
    'cabrait',
    'cabrant',
    'cabrées',
    'cabrent',
    'cabrera',
    'cabriez',
    'cabrons',
    'cacabai',
    'cacabas',
    'cacabât',
    'cacaber',
    'cacabes',
    'cacabez',
    'cacaoté',
    'cacarda',
    'cacarde',
    'cacardé',
    'cachais',
    'cachait',
    'cachant',
    'cachées',
    'cachent',
    'cachera',
    'cacheta',
    'cacheté',
    'cachets',
    'cachiez',
    'cachons',
    'cachots',
    'cachous',
    'cacique',
    'cacolet',
    'cadavre',
    'caddies',
    'cadeaux',
    'cadenas',
    'cadença',
    'cadence',
    'cadencé',
    'cadette',
    'cadmiai',
    'cadmias',
    'cadmiât',
    'cadmiée',
    'cadmier',
    'cadmies',
    'cadmiés',
    'cadmiez',
    'cadmium',
    'cadogan',
    'cadrage',
    'cadrais',
    'cadrait',
    'cadrans',
    'cadrant',
    'cadrats',
    'cadrées',
    'cadrent',
    'cadrera',
    'cadreur',
    'cadriez',
    'cadrons',
    'caducée',
    'caduque',
    'caecale',
    'caecaux',
    'caecums',
    'caesium',
    'caetera',
    'cafarda',
    'cafarde',
    'cafardé',
    'cafards',
    'caféier',
    'caféine',
    'cafetan',
    'caftais',
    'caftait',
    'caftans',
    'caftant',
    'caftées',
    'caftent',
    'caftera',
    'caftiez',
    'caftons',
    'cageots',
    'cagibis',
    'cagnais',
    'cagnait',
    'cagnant',
    'cagnard',
    'cagnent',
    'cagnera',
    'cagneux',
    'cagniez',
    'cagnons',
    'cagotes',
    'cagoule',
    'cahiers',
    'cahotai',
    'cahotas',
    'cahotât',
    'cahotée',
    'cahoter',
    'cahotes',
    'cahotés',
    'cahotez',
    'cahutes',
    'caillai',
    'caillas',
    'caillât',
    'caillée',
    'cailler',
    'cailles',
    'caillés',
    'caillez',
    'caillot',
    'caillou',
    'caïmans',
    'caïques',
    'cairote',
    'caisses',
    'caisson',
    'cajeput',
    'cajolai',
    'cajolas',
    'cajolât',
    'cajolée',
    'cajoler',
    'cajoles',
    'cajolés',
    'cajolez',
    'calages',
    'calamar',
    'calames',
    'calâmes',
    'calasse',
    'calâtes',
    'calcina',
    'calcine',
    'calciné',
    'calcins',
    'calcite',
    'calcium',
    'calcula',
    'calcule',
    'calculé',
    'calculs',
    'calèche',
    'caleçon',
    'calepin',
    'calerai',
    'caleras',
    'calerez',
    'caletai',
    'caletas',
    'caletât',
    'caletée',
    'caleter',
    'caletés',
    'caletez',
    'calette',
    'calfata',
    'calfate',
    'calfaté',
    'calfats',
    'calibra',
    'calibre',
    'calibré',
    'calices',
    'calicot',
    'caliers',
    'califat',
    'califes',
    'câlinai',
    'câlinas',
    'câlinât',
    'câlinée',
    'câliner',
    'câlines',
    'câlinés',
    'câlinez',
    'calions',
    'calleux',
    'calmais',
    'calmait',
    'calmant',
    'calmars',
    'calmées',
    'calment',
    'calmera',
    'calmiez',
    'calmira',
    'calmons',
    'calomel',
    'calorie',
    'calotin',
    'calotta',
    'calotte',
    'calotté',
    'caloyer',
    'calquai',
    'calquas',
    'calquât',
    'calquée',
    'calquer',
    'calques',
    'calqués',
    'calquez',
    'caltais',
    'caltait',
    'caltant',
    'caltées',
    'caltent',
    'caltera',
    'caltiez',
    'caltons',
    'calumet',
    'calypso',
    'camaïeu',
    'camails',
    'camarde',
    'camards',
    'cambial',
    'cambium',
    'cambrai',
    'cambras',
    'cambrât',
    'cambrée',
    'cambrer',
    'cambres',
    'cambrés',
    'cambrez',
    'cambuse',
    'cambuta',
    'cambute',
    'cambuté',
    'camélia',
    'camelle',
    'camelot',
    'caméras',
    'camions',
    'campais',
    'campait',
    'campane',
    'campant',
    'campées',
    'campent',
    'campera',
    'campeur',
    'camphre',
    'camphré',
    'campiez',
    'camping',
    'campons',
    'canâmes',
    'canapés',
    'canarda',
    'canarde',
    'canardé',
    'canards',
    'canaris',
    'canasse',
    'canasta',
    'canâtes',
    'cancale',
    'cancana',
    'cancane',
    'cancané',
    'cancans',
    'cancels',
    'cancers',
    'canches',
    'cancres',
    'candela',
    'candeur',
    'candide',
    'candies',
    'candira',
    'canerai',
    'caneras',
    'canerez',
    'caneton',
    'canette',
    'canevas',
    'cangues',
    'caniche',
    'canines',
    'canions',
    'canisse',
    'canitie',
    'cannage',
    'cannais',
    'cannait',
    'cannant',
    'cannées',
    'cannela',
    'cannelé',
    'cannent',
    'cannera',
    'canneur',
    'canniez',
    'cannons',
    'canonna',
    'canonne',
    'canonné',
    'canopes',
    'canotai',
    'canotas',
    'canotât',
    'canoter',
    'canotes',
    'canotez',
    'cantals',
    'cantate',
    'canters',
    'cantine',
    'cantons',
    'cantres',
    'canulai',
    'canular',
    'canulas',
    'canulât',
    'canulée',
    'canuler',
    'canules',
    'canulés',
    'canulez',
    'canyons',
    'canzone',
    'capable',
    'capéais',
    'capéait',
    'capéant',
    'capéent',
    'capéera',
    'capéiez',
    'capelai',
    'capelan',
    'capelas',
    'capelât',
    'capelée',
    'capeler',
    'capelés',
    'capelet',
    'capelez',
    'capelle',
    'capéons',
    'capeyai',
    'capeyas',
    'capeyât',
    'capeyer',
    'capeyes',
    'capeyez',
    'capital',
    'capitée',
    'capités',
    'capiton',
    'caponna',
    'caponne',
    'caponné',
    'caporal',
    'capotai',
    'capotas',
    'capotât',
    'capotée',
    'capoter',
    'capotes',
    'capotés',
    'capotez',
    'caprice',
    'câprier',
    'caprine',
    'caprins',
    'caprons',
    'capside',
    'capsula',
    'capsule',
    'capsulé',
    'captage',
    'captais',
    'captait',
    'captals',
    'captant',
    'captées',
    'captent',
    'captera',
    'capteur',
    'captiez',
    'captifs',
    'captiva',
    'captive',
    'captivé',
    'captons',
    'captura',
    'capture',
    'capturé',
    'capuces',
    'capuche',
    'capucin',
    'capulet',
    'caquais',
    'caquait',
    'caquant',
    'caquées',
    'caquent',
    'caquera',
    'caqueta',
    'caqueté',
    'caquets',
    'caquiez',
    'caquons',
    'carabes',
    'carabin',
    'caracal',
    'caracos',
    'caracul',
    'carafes',
    'carafon',
    'caraïbe',
    'caramel',
    'carbone',
    'carboné',
    'carbura',
    'carbure',
    'carburé',
    'carcans',
    'carcels',
    'cardage',
    'cardais',
    'cardait',
    'cardans',
    'cardant',
    'cardées',
    'cardent',
    'cardera',
    'cardère',
    'cardeur',
    'cardial',
    'cardias',
    'cardiez',
    'cardite',
    'cardons',
    'carêmes',
    'carénai',
    'carénas',
    'carénât',
    'carença',
    'carence',
    'carencé',
    'carénée',
    'caréner',
    'carénés',
    'carènes',
    'carénez',
    'caressa',
    'caresse',
    'caressé',
    'carguai',
    'carguas',
    'carguât',
    'carguée',
    'carguer',
    'cargues',
    'cargués',
    'carguez',
    'cariais',
    'cariait',
    'cariant',
    'caribou',
    'cariées',
    'carient',
    'cariera',
    'carieux',
    'cariiez',
    'carions',
    'cariste',
    'carline',
    'carlins',
    'carmais',
    'carmait',
    'carmant',
    'carmées',
    'carment',
    'carmera',
    'carmiez',
    'carmina',
    'carmine',
    'carminé',
    'carmins',
    'carmons',
    'carnage',
    'carneau',
    'carnées',
    'carnèle',
    'carnets',
    'carnier',
    'carolus',
    'carotta',
    'carotte',
    'carotté',
    'caroube',
    'carpeau',
    'carpien',
    'carrais',
    'carrait',
    'carrant',
    'carrare',
    'carreau',
    'carrées',
    'carrela',
    'carrelé',
    'carrent',
    'carrera',
    'carrick',
    'carrier',
    'carriez',
    'carroie',
    'carrons',
    'carroya',
    'carroyé',
    'carrure',
    'cartels',
    'carters',
    'cartier',
    'cartons',
    'cartoon',
    'casâmes',
    'casaque',
    'casasse',
    'casâtes',
    'casbahs',
    'cascada',
    'cascade',
    'cascadé',
    'caséfia',
    'caséfie',
    'caséfié',
    'caséine',
    'caserai',
    'caseras',
    'caserez',
    'caserna',
    'caserne',
    'caserné',
    'casiers',
    'casimir',
    'casinos',
    'casions',
    'casoars',
    'casquai',
    'casquas',
    'casquât',
    'casquée',
    'casquer',
    'casques',
    'casqués',
    'casquez',
    'cassage',
    'cassais',
    'cassait',
    'cassant',
    'cassate',
    'casseau',
    'cassées',
    'cassent',
    'cassera',
    'casseur',
    'cassier',
    'cassiez',
    'cassine',
    'cassons',
    'cassure',
    'castels',
    'castine',
    'castors',
    'castrai',
    'castras',
    'castrat',
    'castrât',
    'castrée',
    'castrer',
    'castres',
    'castrés',
    'castrez',
    'casuels',
    'cataire',
    'catalan',
    'catalpa',
    'catchai',
    'catchas',
    'catchât',
    'catcher',
    'catches',
    'catchez',
    'cathare',
    'cathode',
    'cations',
    'catogan',
    'caudale',
    'caudaux',
    'causais',
    'causait',
    'causale',
    'causals',
    'causant',
    'causées',
    'causent',
    'causera',
    'causeur',
    'causiez',
    'causons',
    'causses',
    'cautèle',
    'cautère',
    'caution',
    'cavalai',
    'cavalas',
    'cavalât',
    'cavalée',
    'cavaler',
    'cavales',
    'cavalés',
    'cavalez',
    'cavâmes',
    'cavasse',
    'cavâtes',
    'caveaux',
    'caverai',
    'caveras',
    'caverez',
    'caverne',
    'caviars',
    'cavions',
    'caviste',
    'cavités',
    'cécidie',
    'cédâmes',
    'cédante',
    'cédants',
    'cédasse',
    'cédâtes',
    'céderai',
    'céderas',
    'céderez',
    'cédille',
    'cédions',
    'cédraie',
    'cédrats',
    'cédules',
    'ceignes',
    'ceignez',
    'ceignis',
    'ceignit',
    'ceignît',
    'ceindra',
    'ceindre',
    'ceintes',
    'céladon',
    'celâmes',
    'celasse',
    'celâtes',
    'célébra',
    'célébré',
    'célèbre',
    'cèlerai',
    'cèleras',
    'cèlerez',
    'céleris',
    'célesta',
    'céleste',
    'célibat',
    'celions',
    'cellier',
    'cellule',
    'cémenta',
    'cémente',
    'cémenté',
    'céments',
    'cénacle',
    'cendrai',
    'cendras',
    'cendrât',
    'cendrée',
    'cendrer',
    'cendres',
    'cendrés',
    'cendrez',
    'cenelle',
    'censées',
    'censeur',
    'censier',
    'censura',
    'censure',
    'censuré',
    'centavo',
    'centime',
    'centrai',
    'central',
    'centras',
    'centrât',
    'centrée',
    'centrer',
    'centres',
    'centrés',
    'centrez',
    'cénures',
    'cépages',
    'cérames',
    'céraste',
    'cerbère',
    'cerceau',
    'cerclai',
    'cerclas',
    'cerclât',
    'cerclée',
    'cercler',
    'cercles',
    'cerclés',
    'cerclez',
    'céréale',
    'cerises',
    'cérites',
    'cérithe',
    'cériums',
    'cernais',
    'cernait',
    'cernant',
    'cerneau',
    'cernées',
    'cernent',
    'cernera',
    'cerniez',
    'cernons',
    'certain',
    'cérumen',
    'céruses',
    'cerveau',
    'cervier',
    'césiums',
    'cessais',
    'cessait',
    'cessant',
    'cessées',
    'cessent',
    'cessera',
    'cessiez',
    'cession',
    'cessons',
    'césures',
    'cétacés',
    'cétanes',
    'cétérac',
    'cétoine',
    'cétones',
    'ceux-ci',
    'ceux-là',
    'chablai',
    'chablas',
    'chablât',
    'chablée',
    'chabler',
    'chables',
    'chablés',
    'chablez',
    'chablis',
    'chabots',
    'chabrol',
    'chabrot',
    'chacals',
    'chacone',
    'chacune',
    'chadouf',
    'chagrin',
    'chahuta',
    'chahute',
    'chahuté',
    'chahuts',
    'chaînai',
    'chaînas',
    'chaînât',
    'chaînée',
    'chaîner',
    'chaînes',
    'chaînés',
    'chaînez',
    'chaînon',
    'chaires',
    'chaises',
    'chaland',
    'chalaze',
    'chalets',
    'chaleur',
    'châlits',
    'chaloir',
    'chaluts',
    'chamade',
    'chambra',
    'chambre',
    'chambré',
    'chameau',
    'chamois',
    'champis',
    'chancel',
    'chances',
    'chancir',
    'chancis',
    'chancit',
    'chancît',
    'chancre',
    'changea',
    'changée',
    'changer',
    'changes',
    'changés',
    'changez',
    'chanson',
    'chantai',
    'chantas',
    'chantât',
    'chantée',
    'chanter',
    'chantes',
    'chantés',
    'chantez',
    'chantre',
    'chanvre',
    'chaouch',
    'chapeau',
    'chapées',
    'chapela',
    'chapelé',
    'chapons',
    'chapska',
    'charade',
    'charbon',
    'chardon',
    'chargea',
    'chargée',
    'charger',
    'charges',
    'chargés',
    'chargez',
    'chariot',
    'charité',
    'charmai',
    'charmas',
    'charmât',
    'charmée',
    'charmer',
    'charmes',
    'charmés',
    'charmez',
    'charnel',
    'charnue',
    'charnus',
    'charpie',
    'charria',
    'charrie',
    'charrié',
    'charroi',
    'charron',
    'charrue',
    'charter',
    'chartes',
    'chassai',
    'chassas',
    'chassât',
    'chassée',
    'chasser',
    'chasses',
    'chassés',
    'châsses',
    'chassez',
    'chassie',
    'châssis',
    'chastes',
    'châtain',
    'château',
    'châtiai',
    'châtias',
    'châtiât',
    'châtiée',
    'châtier',
    'châties',
    'châtiés',
    'châtiez',
    'chatoie',
    'chatons',
    'chatoya',
    'chatoyé',
    'châtrai',
    'châtras',
    'châtrât',
    'châtrée',
    'châtrer',
    'châtres',
    'châtrés',
    'châtrez',
    'chattes',
    'chaudes',
    'chauffa',
    'chauffe',
    'chauffé',
    'chaulai',
    'chaulas',
    'chaulât',
    'chaulée',
    'chauler',
    'chaules',
    'chaulés',
    'chaulez',
    'chaumai',
    'chaumas',
    'chaumât',
    'chaumée',
    'chaumer',
    'chaumes',
    'chaumés',
    'chaumez',
    'chaussa',
    'chausse',
    'chaussé',
    'chauves',
    'chauvin',
    'chauvir',
    'chauvis',
    'chauvit',
    'chauvît',
    'chavira',
    'chavire',
    'chaviré',
    'chébecs',
    'chèches',
    'chéchia',
    'cheffes',
    'cheikhs',
    'cheires',
    'chelems',
    'chemina',
    'chemine',
    'cheminé',
    'chemins',
    'chemisa',
    'chemise',
    'chemisé',
    'chênaie',
    'chenaux',
    'chenets',
    'chenils',
    'chenues',
    'cheptel',
    'chèques',
    'chérais',
    'chérait',
    'chérant',
    'chercha',
    'cherche',
    'cherché',
    'chérent',
    'chérera',
    'chergui',
    'chéries',
    'chériez',
    'chérifs',
    'chérira',
    'chérons',
    'chérots',
    'cherrai',
    'cherras',
    'cherrât',
    'cherrer',
    'cherres',
    'cherrez',
    'chertés',
    'chervis',
    'chester',
    'chétifs',
    'chétive',
    'chevala',
    'chevale',
    'chevalé',
    'chevaux',
    'chevelu',
    'chevets',
    'cheveux',
    'chèvres',
    'chevron',
    'chiadai',
    'chiadas',
    'chiadât',
    'chiadée',
    'chiader',
    'chiades',
    'chiadés',
    'chiadez',
    'chialai',
    'chialas',
    'chialât',
    'chialer',
    'chiales',
    'chialez',
    'chiâmes',
    'chiante',
    'chianti',
    'chiants',
    'chiasma',
    'chiasme',
    'chiasse',
    'chiâtes',
    'chicana',
    'chicane',
    'chicané',
    'chiches',
    'chichis',
    'chicons',
    'chicota',
    'chicote',
    'chicoté',
    'chicots',
    'chienna',
    'chienne',
    'chienné',
    'chierai',
    'chieras',
    'chierez',
    'chierie',
    'chiffes',
    'chiffon',
    'chiffra',
    'chiffre',
    'chiffré',
    'chignon',
    'chiions',
    'chiites',
    'chilien',
    'chimère',
    'chimies',
    'chinage',
    'chinais',
    'chinait',
    'chinant',
    'chinées',
    'chinent',
    'chinera',
    'chineur',
    'chiniez',
    'chinois',
    'chinons',
    'chinook',
    'chinure',
    'chipais',
    'chipait',
    'chipant',
    'chipées',
    'chipent',
    'chipera',
    'chipies',
    'chipiez',
    'chipons',
    'chipota',
    'chipote',
    'chipoté',
    'chiquai',
    'chiquas',
    'chiquât',
    'chiquée',
    'chiquer',
    'chiques',
    'chiqués',
    'chiquez',
    'chitine',
    'chitons',
    'chiures',
    'chleuhe',
    'chlorai',
    'chloral',
    'chloras',
    'chlorât',
    'chlorée',
    'chlorer',
    'chlores',
    'chlorés',
    'chlorez',
    'choeurs',
    'choient',
    'choiera',
    'choirai',
    'choisie',
    'choisir',
    'choisis',
    'choisit',
    'choisît',
    'choléra',
    'choline',
    'chômage',
    'chômais',
    'chômait',
    'chômant',
    'chômées',
    'chôment',
    'chômera',
    'chômeur',
    'chômiez',
    'chômons',
    'chopais',
    'chopait',
    'chopant',
    'chopées',
    'chopent',
    'chopera',
    'chopiez',
    'chopina',
    'chopine',
    'chopiné',
    'chopons',
    'choppai',
    'choppas',
    'choppât',
    'chopper',
    'choppes',
    'choppez',
    'choquai',
    'choquas',
    'choquât',
    'choquée',
    'choquer',
    'choques',
    'choqués',
    'choquez',
    'chorale',
    'chorals',
    'chorées',
    'chorège',
    'chorion',
    'chorizo',
    'chouans',
    'choucas',
    'choyais',
    'choyait',
    'choyant',
    'choyées',
    'choyiez',
    'choyons',
    'chrisme',
    'christs',
    'chromai',
    'chromas',
    'chromât',
    'chromée',
    'chromer',
    'chromes',
    'chromés',
    'chromez',
    'chromos',
    'chronos',
    'chrouma',
    'chroume',
    'chroumé',
    'chuinta',
    'chuinte',
    'chuinté',
    'chutais',
    'chutait',
    'chutant',
    'chutées',
    'chutent',
    'chutera',
    'chutiez',
    'chutons',
    'cibiche',
    'cibiste',
    'ciboire',
    'ciboule',
    'cicéros',
    'cierges',
    'cigales',
    'cigares',
    'cigogne',
    'ci-haut',
    'cilices',
    'ciliées',
    'cillais',
    'cillait',
    'cillant',
    'cillées',
    'cillent',
    'cillera',
    'cilliez',
    'cillons',
    'cimaise',
    'cimenta',
    'cimente',
    'cimenté',
    'ciments',
    'cimiers',
    'cinabre',
    'cincles',
    'cinémas',
    'cinétir',
    'cinglai',
    'cinglas',
    'cinglât',
    'cinglée',
    'cingler',
    'cingles',
    'cinglés',
    'cinglez',
    'cinoche',
    'cinoque',
    'cintrai',
    'cintras',
    'cintrât',
    'cintrée',
    'cintrer',
    'cintres',
    'cintrés',
    'cintrez',
    'cipayes',
    'cipolin',
    'cirages',
    'cirâmes',
    'cirasse',
    'cirâtes',
    'circuit',
    'circula',
    'circule',
    'circulé',
    'cirerai',
    'cireras',
    'cirerez',
    'cireurs',
    'cireuse',
    'cirière',
    'ciriers',
    'cirions',
    'cirques',
    'ciseaux',
    'ciselai',
    'ciselas',
    'ciselât',
    'ciselée',
    'ciseler',
    'ciselés',
    'cisèles',
    'ciselet',
    'ciselez',
    'cistres',
    'cistron',
    'cistude',
    'citadin',
    'citâmes',
    'citasse',
    'citâtes',
    'citerai',
    'citeras',
    'citerez',
    'citerne',
    'cithare',
    'citions',
    'citoyen',
    'citrate',
    'citrine',
    'citrins',
    'citrons',
    'civelle',
    'civette',
    'civière',
    'civiles',
    'civique',
    'civisme',
    'clabaud',
    'clabota',
    'clabote',
    'claboté',
    'clabots',
    'claires',
    'clairet',
    'clairon',
    'clamais',
    'clamait',
    'clamant',
    'clamées',
    'clament',
    'clamera',
    'clameur',
    'clamiez',
    'clamons',
    'clampai',
    'clampas',
    'clampât',
    'clampée',
    'clamper',
    'clampes',
    'clampés',
    'clampez',
    'clampsa',
    'clampse',
    'clampsé',
    'clamsai',
    'clamsas',
    'clamsât',
    'clamser',
    'clamses',
    'clamsez',
    'clandés',
    'clapais',
    'clapait',
    'clapant',
    'clapent',
    'clapera',
    'clapets',
    'clapier',
    'clapiez',
    'clapira',
    'clapons',
    'clapota',
    'clapote',
    'clapoté',
    'clappai',
    'clappas',
    'clappât',
    'clapper',
    'clappes',
    'clappez',
    'clapsai',
    'clapsas',
    'clapsât',
    'clapser',
    'clapses',
    'clapsez',
    'claquai',
    'claquas',
    'claquât',
    'claquée',
    'claquer',
    'claques',
    'claqués',
    'claquet',
    'claquez',
    'clarine',
    'clartés',
    'classai',
    'classas',
    'classât',
    'classée',
    'classer',
    'classes',
    'classés',
    'classez',
    'clauses',
    'clausus',
    'clavais',
    'clavait',
    'clavant',
    'claveau',
    'clavées',
    'clavelé',
    'clavent',
    'clavera',
    'claveta',
    'claveté',
    'clavier',
    'claviez',
    'clavons',
    'clayère',
    'clayons',
    'clément',
    'clenche',
    'clephte',
    'clergés',
    'clergie',
    'clichai',
    'clichas',
    'clichât',
    'clichée',
    'clicher',
    'cliches',
    'clichés',
    'clichez',
    'cliente',
    'clients',
    'clignai',
    'clignas',
    'clignât',
    'clignée',
    'cligner',
    'clignes',
    'clignés',
    'clignez',
    'climats',
    'clinfoc',
    'cliques',
    'cliquet',
    'clissai',
    'clissas',
    'clissât',
    'clissée',
    'clisser',
    'clisses',
    'clissés',
    'clissez',
    'clivage',
    'clivais',
    'clivait',
    'clivant',
    'clivées',
    'clivent',
    'clivera',
    'cliviez',
    'clivons',
    'cloaque',
    'clochai',
    'clochas',
    'clochât',
    'clochée',
    'clocher',
    'cloches',
    'clochés',
    'clochez',
    'cloison',
    'cloîtra',
    'cloître',
    'cloîtré',
    'clopina',
    'clopine',
    'clopiné',
    'cloquai',
    'cloquas',
    'cloquât',
    'cloquer',
    'cloques',
    'cloquez',
    'clorais',
    'clorait',
    'cloriez',
    'clorons',
    'cloront',
    'closent',
    'clôtura',
    'clôture',
    'clôturé',
    'clouais',
    'clouait',
    'clouant',
    'clouées',
    'clouent',
    'clouera',
    'clouiez',
    'clouons',
    'cloutai',
    'cloutas',
    'cloutât',
    'cloutée',
    'clouter',
    'cloutes',
    'cloutés',
    'cloutez',
    'cloyère',
    'cnémide',
    'coaches',
    'coagula',
    'coagule',
    'coagulé',
    'coalisa',
    'coalise',
    'coalisé',
    'coaltar',
    'coassai',
    'coassas',
    'coassât',
    'coasser',
    'coasses',
    'coassez',
    'coaxial',
    'cobayes',
    'cocagne',
    'cocaïne',
    'cocarde',
    'cocasse',
    'cochais',
    'côchais',
    'cochait',
    'côchait',
    'cochant',
    'côchant',
    'cochées',
    'côchées',
    'cochent',
    'côchent',
    'cochera',
    'côchera',
    'cochère',
    'cochers',
    'cochiez',
    'côchiez',
    'cochons',
    'côchons',
    'cockers',
    'cockney',
    'cockpit',
    'cocotte',
    'coction',
    'cocuage',
    'cocufia',
    'cocufie',
    'cocufié',
    'codages',
    'codâmes',
    'codasse',
    'codâtes',
    'codéine',
    'coderai',
    'coderas',
    'coderez',
    'codeurs',
    'codeuse',
    'codifia',
    'codifie',
    'codifié',
    'codions',
    'coffins',
    'coffrai',
    'coffras',
    'coffrât',
    'coffrée',
    'coffrer',
    'coffres',
    'coffrés',
    'coffret',
    'coffrez',
    'cogitai',
    'cogitas',
    'cogitât',
    'cogitée',
    'cogiter',
    'cogites',
    'cogités',
    'cogitez',
    'cognacs',
    'cognais',
    'cognait',
    'cognant',
    'cognats',
    'cognées',
    'cognent',
    'cognera',
    'cogniez',
    'cognons',
    'cohésif',
    'cohorte',
    'coiffai',
    'coiffas',
    'coiffât',
    'coiffée',
    'coiffer',
    'coiffes',
    'coiffés',
    'coiffez',
    'coinçai',
    'coinças',
    'coinçât',
    'coincée',
    'coincer',
    'coinces',
    'coincés',
    'coincez',
    'coïtais',
    'coïtait',
    'coïtant',
    'coïtent',
    'coïtera',
    'coïtiez',
    'coïtons',
    'cokéfia',
    'cokéfie',
    'cokéfié',
    'cokerie',
    'colback',
    'colères',
    'colibri',
    'colinot',
    'colique',
    'colites',
    'collage',
    'collais',
    'collait',
    'collant',
    'collées',
    'collège',
    'collent',
    'collera',
    'colleta',
    'colleté',
    'collets',
    'colleur',
    'colleys',
    'collier',
    'colliez',
    'collige',
    'colligé',
    'colline',
    'collons',
    'collyre',
    'colmata',
    'colmate',
    'colmaté',
    'colombe',
    'colonat',
    'colonel',
    'colonie',
    'colonne',
    'colorai',
    'coloras',
    'colorât',
    'colorée',
    'colorer',
    'colores',
    'colorés',
    'colorez',
    'coloria',
    'colorie',
    'colorié',
    'coloris',
    'colosse',
    'coltina',
    'coltine',
    'coltiné',
    'colvert',
    'combats',
    'combien',
    'combina',
    'combine',
    'combiné',
    'comblai',
    'comblas',
    'comblât',
    'comblée',
    'combler',
    'combles',
    'comblés',
    'comblez',
    'comédie',
    'comédon',
    'comètes',
    'comices',
    'comique',
    'comités',
    'comment',
    'comméra',
    'comméré',
    'commère',
    'commets',
    'commise',
    'commode',
    'commuai',
    'commuas',
    'commuât',
    'commuée',
    'commuer',
    'commues',
    'commués',
    'commuez',
    'commune',
    'communs',
    'commuta',
    'commute',
    'commuté',
    'compact',
    'compara',
    'compare',
    'comparé',
    'comparu',
    'compati',
    'compère',
    'compéta',
    'compété',
    'compète',
    'compila',
    'compile',
    'compilé',
    'complet',
    'complot',
    'complus',
    'complut',
    'complût',
    'componé',
    'composa',
    'compose',
    'composé',
    'compost',
    'compote',
    'compris',
    'comprit',
    'comprît',
    'comptai',
    'comptas',
    'comptât',
    'comptée',
    'compter',
    'comptes',
    'comptés',
    'comptez',
    'computa',
    'compute',
    'computé',
    'computs',
    'comtale',
    'comtaux',
    'conarde',
    'conards',
    'conasse',
    'conatif',
    'concave',
    'concéda',
    'concédé',
    'concède',
    'concept',
    'concert',
    'concile',
    'concise',
    'conclue',
    'conclus',
    'conclut',
    'conclût',
    'conçois',
    'conçoit',
    'concret',
    'conçues',
    'condoms',
    'condors',
    'conduis',
    'conduit',
    'condyle',
    'conféra',
    'conféré',
    'confère',
    'confiai',
    'confias',
    'confiât',
    'confiée',
    'confier',
    'confies',
    'confiés',
    'confiez',
    'confina',
    'confine',
    'confiné',
    'confins',
    'confira',
    'confire',
    'confise',
    'confite',
    'confits',
    'conflit',
    'conflua',
    'conflue',
    'conflué',
    'confond',
    'confort',
    'confuse',
    'congaïs',
    'congaye',
    'congela',
    'congelé',
    'congèle',
    'congère',
    'congréa',
    'congrée',
    'congréé',
    'congres',
    'congrès',
    'congrue',
    'congrus',
    'conidie',
    'cônîmes',
    'conique',
    'cônirai',
    'côniras',
    'cônirez',
    'cônisse',
    'cônîtes',
    'conjura',
    'conjure',
    'conjuré',
    'connais',
    'connaît',
    'connard',
    'connexe',
    'connota',
    'connote',
    'connoté',
    'connues',
    'conobra',
    'conobre',
    'conobré',
    'conoïde',
    'conopée',
    'conques',
    'conquêt',
    'conquis',
    'conquit',
    'conquît',
    'conseil',
    'consens',
    'consent',
    'consola',
    'console',
    'consolé',
    'consona',
    'consone',
    'consoné',
    'consort',
    'conspua',
    'conspue',
    'conspué',
    'constat',
    'consuls',
    'consuma',
    'consume',
    'consumé',
    'contact',
    'contage',
    'contais',
    'contait',
    'contant',
    'contées',
    'content',
    'contenu',
    'contera',
    'conteur',
    'contiez',
    'contigu',
    'contins',
    'contint',
    'contînt',
    'continu',
    'contons',
    'contour',
    'contrai',
    'contras',
    'contrat',
    'contrât',
    'contrée',
    'contrer',
    'contres',
    'contrés',
    'contrez',
    'contrit',
    'convenu',
    'convers',
    'convexe',
    'conviai',
    'convias',
    'conviât',
    'conviée',
    'convier',
    'convies',
    'conviés',
    'conviez',
    'convins',
    'convint',
    'convînt',
    'convive',
    'convoie',
    'convois',
    'convola',
    'convole',
    'convolé',
    'convoya',
    'convoyé',
    'coolies',
    'coopéra',
    'coopéré',
    'coopère',
    'cooptai',
    'cooptas',
    'cooptât',
    'cooptée',
    'coopter',
    'cooptes',
    'cooptés',
    'cooptez',
    'copaïer',
    'copains',
    'copeaux',
    'copiage',
    'copiais',
    'copiait',
    'copiant',
    'copiées',
    'copient',
    'copiera',
    'copieur',
    'copieux',
    'copiiez',
    'copinai',
    'copinas',
    'copinât',
    'copiner',
    'copines',
    'copinez',
    'copions',
    'copiste',
    'coprahs',
    'copulai',
    'copulas',
    'copulât',
    'copuler',
    'copules',
    'copulez',
    'coquais',
    'coquait',
    'coquant',
    'coquard',
    'coquart',
    'coquées',
    'coquent',
    'coquera',
    'coqueta',
    'coqueté',
    'coquets',
    'coquiez',
    'coquine',
    'coquins',
    'coquons',
    'corbeau',
    'corbins',
    'cordage',
    'cordais',
    'cordait',
    'cordant',
    'cordeau',
    'cordées',
    'cordela',
    'cordelé',
    'cordent',
    'cordera',
    'cordial',
    'cordier',
    'cordiez',
    'cordons',
    'coréens',
    'coriace',
    'cormier',
    'cornacs',
    'cornais',
    'cornait',
    'cornant',
    'cornard',
    'cornéen',
    'cornées',
    'cornent',
    'cornera',
    'cornets',
    'cornier',
    'corniez',
    'cornons',
    'cornues',
    'corolle',
    'coroner',
    'corozos',
    'corrals',
    'correct',
    'corréla',
    'corrélé',
    'corrèle',
    'corrida',
    'corrige',
    'corrigé',
    'corroda',
    'corrode',
    'corrodé',
    'corroie',
    'corroya',
    'corroyé',
    'corsage',
    'corsais',
    'corsait',
    'corsant',
    'corsées',
    'corsent',
    'corsera',
    'corseta',
    'corseté',
    'corsète',
    'corsets',
    'corsiez',
    'corsons',
    'cortège',
    'corvées',
    'coryzas',
    'cosaque',
    'cosinus',
    'cossais',
    'cossait',
    'cossant',
    'cossard',
    'cossent',
    'cossera',
    'cossiez',
    'cossons',
    'cossues',
    'costale',
    'costard',
    'costaud',
    'costaux',
    'costuma',
    'costume',
    'costumé',
    'cotâmes',
    'cotasse',
    'cotâtes',
    'coteaux',
    'côtelée',
    'côtelés',
    'coterai',
    'coteras',
    'coterez',
    'coterie',
    'côtière',
    'côtiers',
    'cotîmes',
    'cotions',
    'cotirai',
    'cotiras',
    'cotirez',
    'cotisai',
    'cotisas',
    'cotisât',
    'cotisée',
    'cotiser',
    'cotises',
    'cotisés',
    'cotisez',
    'cotisse',
    'cotîtes',
    'côtoies',
    'cotonna',
    'cotonne',
    'cotonné',
    'côtoyai',
    'côtoyas',
    'côtoyât',
    'côtoyée',
    'côtoyer',
    'côtoyés',
    'côtoyez',
    'cottage',
    'couarde',
    'couards',
    'couchai',
    'couchas',
    'couchât',
    'couchée',
    'coucher',
    'couches',
    'couchés',
    'couchez',
    'coucous',
    'coudais',
    'coudait',
    'coudant',
    'coudées',
    'coudent',
    'coudera',
    'coudiez',
    'coudoie',
    'coudons',
    'coudoya',
    'coudoyé',
    'coudrai',
    'coudras',
    'coudrez',
    'couenne',
    'couette',
    'couffin',
    'couguar',
    'couille',
    'couinai',
    'couinas',
    'couinât',
    'couiner',
    'couines',
    'couinez',
    'coulage',
    'coulais',
    'coulait',
    'coulant',
    'coulées',
    'coulent',
    'coulera',
    'couleur',
    'couliez',
    'couloir',
    'coulomb',
    'coulons',
    'coulure',
    'country',
    'coupage',
    'coupais',
    'coupait',
    'coupant',
    'coupées',
    'coupent',
    'coupera',
    'coupiez',
    'couplai',
    'couplas',
    'couplât',
    'couplée',
    'coupler',
    'couples',
    'couplés',
    'couplet',
    'couplez',
    'coupole',
    'coupons',
    'coupure',
    'courage',
    'courais',
    'courait',
    'courant',
    'courbai',
    'courbas',
    'courbât',
    'courbée',
    'courber',
    'courbes',
    'courbés',
    'courbez',
    'courent',
    'coureur',
    'courges',
    'couriez',
    'courlis',
    'courons',
    'courrai',
    'courras',
    'courrez',
    'coursai',
    'coursas',
    'coursât',
    'coursée',
    'courser',
    'courses',
    'coursés',
    'coursez',
    'courson',
    'courtes',
    'courues',
    'cousais',
    'cousait',
    'cousant',
    'cousent',
    'couseur',
    'cousiez',
    'cousina',
    'cousine',
    'cousiné',
    'cousins',
    'cousons',
    'coussin',
    'cousues',
    'coûtais',
    'coûtait',
    'coûtant',
    'couteau',
    'coûtées',
    'coûtent',
    'coûtera',
    'coûteux',
    'coûtiez',
    'coutils',
    'coûtons',
    'coutres',
    'coutume',
    'coutura',
    'couture',
    'couturé',
    'couvain',
    'couvais',
    'couvait',
    'couvant',
    'couvées',
    'couvent',
    'couvera',
    'couvert',
    'couviez',
    'couvons',
    'couvres',
    'couvrez',
    'couvrir',
    'couvris',
    'couvrit',
    'couvrît',
    'couvrue',
    'couvrus',
    'coxales',
    'coyotes',
    'crabier',
    'crabots',
    'crachai',
    'crachas',
    'crachat',
    'crachât',
    'crachée',
    'cracher',
    'craches',
    'crachés',
    'crachez',
    'crachin',
    'cracker',
    'craigne',
    'crailla',
    'craille',
    'craillé',
    'crainte',
    'craints',
    'cramais',
    'cramait',
    'cramant',
    'crambés',
    'cramées',
    'crament',
    'cramera',
    'cramiez',
    'cramons',
    'crampes',
    'crampon',
    'crampsa',
    'crampse',
    'crampsé',
    'cramsai',
    'cramsas',
    'cramsât',
    'cramser',
    'cramses',
    'cramsez',
    'cranais',
    'crânais',
    'cranait',
    'crânait',
    'cranant',
    'crânant',
    'cranées',
    'cranent',
    'crânent',
    'cranera',
    'crânera',
    'crâneur',
    'crânien',
    'craniez',
    'crâniez',
    'cranons',
    'crânons',
    'crantai',
    'crantas',
    'crantât',
    'crantée',
    'cranter',
    'crantes',
    'crantés',
    'crantez',
    'crapaud',
    'crapula',
    'crapule',
    'crapulé',
    'craquai',
    'craquas',
    'craquât',
    'craquée',
    'craquer',
    'craques',
    'craqués',
    'craquez',
    'crashes',
    'crassai',
    'crassas',
    'crassât',
    'crassée',
    'crasser',
    'crasses',
    'crassés',
    'crassez',
    'cratère',
    'cravata',
    'cravate',
    'cravaté',
    'crawlai',
    'crawlas',
    'crawlât',
    'crawlée',
    'crawler',
    'crawles',
    'crawlés',
    'crawlez',
    'crayeux',
    'crayons',
    'créâmes',
    'créance',
    'créasse',
    'créâtes',
    'créatif',
    'créchai',
    'créchas',
    'créchât',
    'crécher',
    'crèches',
    'créchez',
    'crédita',
    'crédite',
    'crédité',
    'crédits',
    'crédule',
    'créerai',
    'créeras',
    'créerez',
    'créions',
    'crémais',
    'crémait',
    'crémant',
    'crèment',
    'crémera',
    'crémeux',
    'crémier',
    'crémiez',
    'crémons',
    'crénais',
    'crénait',
    'crénant',
    'créneau',
    'crénées',
    'crénela',
    'crénelé',
    'crènent',
    'crénera',
    'créniez',
    'crénons',
    'créoles',
    'crêpage',
    'crêpais',
    'crêpait',
    'crêpant',
    'crêpées',
    'crêpent',
    'crêpera',
    'crépies',
    'crêpiez',
    'crépine',
    'crépira',
    'crépita',
    'crépite',
    'crépité',
    'crépons',
    'crêpons',
    'crépues',
    'cresson',
    'crétacé',
    'crétine',
    'crétins',
    'crétois',
    'creusai',
    'creusas',
    'creusât',
    'creusée',
    'creuser',
    'creuses',
    'creusés',
    'creuset',
    'creusez',
    'crevais',
    'crevait',
    'crevant',
    'crevées',
    'crèvent',
    'crèvera',
    'creviez',
    'crevons',
    'criâmes',
    'criante',
    'criants',
    'criarde',
    'criards',
    'criasse',
    'criâtes',
    'criblai',
    'criblas',
    'criblât',
    'criblée',
    'cribler',
    'cribles',
    'criblés',
    'criblez',
    'cricket',
    'cricris',
    'crierai',
    'crieras',
    'crierez',
    'crieurs',
    'crieuse',
    'criions',
    'criques',
    'criquet',
    'crispai',
    'crispas',
    'crispât',
    'crispée',
    'crisper',
    'crispes',
    'crispés',
    'crispez',
    'crissai',
    'crissas',
    'crissât',
    'crisser',
    'crisses',
    'crissez',
    'cristal',
    'critère',
    'croassa',
    'croasse',
    'croassé',
    'croates',
    'crochai',
    'crochas',
    'crochât',
    'crochée',
    'crocher',
    'croches',
    'crochés',
    'crochet',
    'crochez',
    'crochue',
    'crochus',
    'croient',
    'croirai',
    'croiras',
    'croirez',
    'croisai',
    'croisas',
    'croisât',
    'croisée',
    'croiser',
    'croises',
    'croisés',
    'croisez',
    'croisse',
    'croîtra',
    'croître',
    'croquai',
    'croquas',
    'croquât',
    'croquée',
    'croquer',
    'croques',
    'croqués',
    'croquet',
    'croquez',
    'croquis',
    'crossai',
    'crossas',
    'crossât',
    'crossée',
    'crosser',
    'crosses',
    'crossés',
    'crossez',
    'crotale',
    'crottai',
    'crottas',
    'crottât',
    'crottée',
    'crotter',
    'crottes',
    'crottés',
    'crottez',
    'crottin',
    'croulai',
    'croulas',
    'croulât',
    'crouler',
    'croules',
    'croulez',
    'croupes',
    'croupir',
    'croupis',
    'croupit',
    'croupît',
    'croûtai',
    'croûtas',
    'croûtât',
    'croûtée',
    'croûter',
    'croûtes',
    'croûtés',
    'croûtez',
    'croûton',
    'croyais',
    'croyait',
    'croyant',
    'croyiez',
    'croyons',
    'cruauté',
    'cruches',
    'cruchon',
    'crucial',
    'crudité',
    'cruelle',
    'crûment',
    'crurent',
    'crûrent',
    'crusses',
    'crûsses',
    'cryptai',
    'cryptas',
    'cryptât',
    'cryptée',
    'crypter',
    'cryptes',
    'cryptés',
    'cryptez',
    'cubages',
    'cubaine',
    'cubains',
    'cubâmes',
    'cubasse',
    'cubâtes',
    'cuberai',
    'cuberas',
    'cuberez',
    'cubions',
    'cubique',
    'cubisme',
    'cubiste',
    'cubitus',
    'cueille',
    'cueilli',
    'cuestas',
    'cuiller',
    'cuirais',
    'cuirait',
    'cuiriez',
    'cuirons',
    'cuiront',
    'cuisais',
    'cuisait',
    'cuisant',
    'cuisent',
    'cuisiez',
    'cuisina',
    'cuisine',
    'cuisiné',
    'cuisons',
    'cuisses',
    'cuisson',
    'cuissot',
    'cuistre',
    'cuitais',
    'cuitait',
    'cuitant',
    'cuitent',
    'cuitera',
    'cuitiez',
    'cuitons',
    'cuivrai',
    'cuivras',
    'cuivrât',
    'cuivrée',
    'cuivrer',
    'cuivres',
    'cuivrés',
    'cuivrez',
    'culâmes',
    'culasse',
    'culâtes',
    'culbuta',
    'culbute',
    'culbuté',
    'culerai',
    'culeras',
    'culerez',
    'culeron',
    'culions',
    'culmina',
    'culmine',
    'culminé',
    'culotta',
    'culotte',
    'culotté',
    'cultiva',
    'cultive',
    'cultivé',
    'cultuel',
    'culture',
    'cumulai',
    'cumulas',
    'cumulât',
    'cumulée',
    'cumuler',
    'cumules',
    'cumulés',
    'cumulez',
    'cumulus',
    'cupides',
    'cuprite',
    'cupules',
    'curable',
    'curaçao',
    'curages',
    'curâmes',
    'curares',
    'curasse',
    'curâtes',
    'curatif',
    'curcuma',
    'curerai',
    'cureras',
    'curerez',
    'curetai',
    'curetas',
    'curetât',
    'curetée',
    'cureter',
    'curetés',
    'curetez',
    'cureton',
    'curette',
    'curiale',
    'curiaux',
    'curieux',
    'curions',
    'curiste',
    'curiums',
    'curling',
    'curseur',
    'cursifs',
    'cursive',
    'curules',
    'cuscute',
    'custode',
    'cutanée',
    'cutanés',
    'cutines',
    'cutters',
    'cuvages',
    'cuvâmes',
    'cuvasse',
    'cuvâtes',
    'cuveaux',
    'cuvelai',
    'cuvelas',
    'cuvelât',
    'cuvelée',
    'cuveler',
    'cuvelés',
    'cuvelez',
    'cuvelle',
    'cuverai',
    'cuveras',
    'cuverez',
    'cuvette',
    'cuviers',
    'cuvions',
    'cyanosa',
    'cyanose',
    'cyanosé',
    'cyanura',
    'cyanure',
    'cyanuré',
    'cyclane',
    'cyclisa',
    'cyclise',
    'cyclisé',
    'cyclone',
    'cyclope',
    'cymaise',
    'cymbale',
    'cynique',
    'cynisme',
    'cyphose',
    'cyprins',
    'cystine',
    'cystite',
    'cytises',
    'dactyle',
    'dactylo',
    'daguais',
    'daguait',
    'daguant',
    'daguées',
    'daguent',
    'daguera',
    'daguets',
    'daguiez',
    'daguons',
    'dahlias',
    'daignai',
    'daignas',
    'daignât',
    'daignée',
    'daigner',
    'daignes',
    'daignés',
    'daignez',
    'daïmios',
    'daleaus',
    'dallage',
    'dallais',
    'dallait',
    'dallant',
    'dallées',
    'dallent',
    'dallera',
    'dalleur',
    'dalliez',
    'dallons',
    'dalmate',
    'daltons',
    'damages',
    'damâmes',
    'damassa',
    'damasse',
    'damassé',
    'damâtes',
    'damerai',
    'dameras',
    'damerez',
    'damiers',
    'damions',
    'damnais',
    'damnait',
    'damnant',
    'damnées',
    'damnent',
    'damnera',
    'damniez',
    'damnons',
    'danaïde',
    'dancing',
    'dandina',
    'dandine',
    'dandiné',
    'dandins',
    'dangers',
    'daniens',
    'danoise',
    'dansais',
    'dansait',
    'dansant',
    'dansées',
    'dansent',
    'dansera',
    'danseur',
    'dansiez',
    'dansons',
    'daphnés',
    'daphnie',
    'daraise',
    'dardais',
    'dardait',
    'dardant',
    'dardées',
    'dardent',
    'dardera',
    'dardiez',
    'dardons',
    'dariole',
    'darique',
    'dartois',
    'dartres',
    'dasyure',
    'datable',
    'datages',
    'dataire',
    'datâmes',
    'datasse',
    'datâtes',
    'datchas',
    'daterai',
    'dateras',
    'daterez',
    'daterie',
    'dateurs',
    'dateuse',
    'dations',
    'datives',
    'dattier',
    'daturas',
    'daubais',
    'daubait',
    'daubant',
    'daubées',
    'daubent',
    'daubera',
    'daubeur',
    'daubiez',
    'daubons',
    'dauphin',
    'daurade',
    'daviers',
    'dazibao',
    'dealers',
    'débâcha',
    'débâche',
    'débâché',
    'débâcla',
    'débâcle',
    'débâclé',
    'déballa',
    'déballe',
    'déballé',
    'débanda',
    'débande',
    'débandé',
    'débarda',
    'débarde',
    'débardé',
    'débarra',
    'débarre',
    'débarré',
    'débâtai',
    'débâtas',
    'débâtât',
    'débâtée',
    'débâter',
    'débâtes',
    'débâtés',
    'débâtez',
    'débâtie',
    'débâtir',
    'débâtis',
    'débâtit',
    'débâtît',
    'débatte',
    'débattu',
    'débecta',
    'débecte',
    'débecté',
    'débiles',
    'débinai',
    'débinas',
    'débinât',
    'débinée',
    'débiner',
    'débines',
    'débinés',
    'débinez',
    'débitai',
    'débitas',
    'débitât',
    'débitée',
    'débiter',
    'débites',
    'débités',
    'débitez',
    'déblaie',
    'déblais',
    'déblaya',
    'déblaye',
    'déblayé',
    'débleui',
    'débogua',
    'débogue',
    'débogué',
    'déboire',
    'déboisa',
    'déboise',
    'déboisé',
    'déboîta',
    'déboîte',
    'déboîté',
    'débonda',
    'débonde',
    'débondé',
    'déborda',
    'déborde',
    'débordé',
    'débords',
    'débotta',
    'débotte',
    'débotté',
    'débouda',
    'déboude',
    'déboudé',
    'déboula',
    'déboule',
    'déboulé',
    'débours',
    'débouta',
    'déboute',
    'débouté',
    'débraie',
    'débraya',
    'débraye',
    'débrayé',
    'débrida',
    'débride',
    'débridé',
    'débucha',
    'débuche',
    'débuché',
    'débulla',
    'débulle',
    'débullé',
    'débutai',
    'débutas',
    'débutât',
    'débutée',
    'débuter',
    'débutes',
    'débutés',
    'débutez',
    'décades',
    'décadis',
    'décalai',
    'décalas',
    'décalât',
    'décalée',
    'décaler',
    'décales',
    'décalés',
    'décalez',
    'décampa',
    'décampe',
    'décampé',
    'décanal',
    'décanat',
    'décanta',
    'décante',
    'décanté',
    'décapai',
    'décapas',
    'décapât',
    'décapée',
    'décaper',
    'décapes',
    'décapés',
    'décapez',
    'décarra',
    'décarre',
    'décarré',
    'décatie',
    'décatir',
    'décatis',
    'décatit',
    'décatît',
    'décausa',
    'décause',
    'décausé',
    'décavée',
    'décavés',
    'décédai',
    'décédas',
    'décédât',
    'décédée',
    'décéder',
    'décédés',
    'décèdes',
    'décédez',
    'décelai',
    'décelas',
    'décelât',
    'décelée',
    'déceler',
    'décelés',
    'décèles',
    'décelez',
    'décence',
    'décente',
    'décents',
    'décerna',
    'décerne',
    'décerné',
    'décessa',
    'décesse',
    'décessé',
    'décevez',
    'décevra',
    'déchapa',
    'déchape',
    'déchapé',
    'déchets',
    'déchira',
    'déchire',
    'déchiré',
    'déchoie',
    'déchoir',
    'déchois',
    'déchoit',
    'déchues',
    'décibel',
    'décidai',
    'décidas',
    'décidât',
    'décidée',
    'décider',
    'décides',
    'décidés',
    'décidez',
    'déciles',
    'décimai',
    'décimal',
    'décimas',
    'décimât',
    'décimée',
    'décimer',
    'décimes',
    'décimés',
    'décimez',
    'décisif',
    'déclama',
    'déclame',
    'déclamé',
    'déclara',
    'déclare',
    'déclaré',
    'déclics',
    'déclina',
    'décline',
    'décliné',
    'déclins',
    'déclive',
    'déclora',
    'déclore',
    'déclose',
    'décloua',
    'décloue',
    'décloué',
    'décocha',
    'décoche',
    'décoché',
    'décodai',
    'décodas',
    'décodât',
    'décodée',
    'décoder',
    'décodes',
    'décodés',
    'décodez',
    'déçoive',
    'décolla',
    'décolle',
    'décollé',
    'déconna',
    'déconne',
    'déconné',
    'décorai',
    'décoras',
    'décorât',
    'décorda',
    'décorde',
    'décordé',
    'décorée',
    'décorer',
    'décores',
    'décorés',
    'décorez',
    'décorna',
    'décorne',
    'décorné',
    'décorum',
    'décotes',
    'découds',
    'découla',
    'découle',
    'découlé',
    'découpa',
    'découpe',
    'découpé',
    'décours',
    'découse',
    'décousu',
    'décrêpa',
    'décrêpe',
    'décrêpé',
    'décrépi',
    'décréta',
    'décrété',
    'décrète',
    'décrets',
    'décriai',
    'décrias',
    'décriât',
    'décriée',
    'décrier',
    'décries',
    'décriés',
    'décriez',
    'décrira',
    'décrire',
    'décrite',
    'décrits',
    'décrive',
    'décrois',
    'décroît',
    'décruai',
    'décruas',
    'décruât',
    'décruée',
    'décruer',
    'décrues',
    'décrués',
    'décruez',
    'décrusa',
    'décruse',
    'décrusé',
    'déçûmes',
    'décupla',
    'décuple',
    'décuplé',
    'décussé',
    'déçusse',
    'déçûtes',
    'décuvai',
    'décuvas',
    'décuvât',
    'décuvée',
    'décuver',
    'décuves',
    'décuvés',
    'décuvez',
    'dédains',
    'dédales',
    'dédiais',
    'dédiait',
    'dédiant',
    'dédiées',
    'dédient',
    'dédiera',
    'dédiiez',
    'dédîmes',
    'dédions',
    'dédirai',
    'dédiras',
    'dédirez',
    'dédises',
    'dédisse',
    'dédites',
    'dédîtes',
    'dédorai',
    'dédoras',
    'dédorât',
    'dédorée',
    'dédorer',
    'dédores',
    'dédorés',
    'dédorez',
    'déduira',
    'déduire',
    'déduise',
    'déduite',
    'déduits',
    'déesses',
    'défaire',
    'défaite',
    'défaits',
    'défarda',
    'défarde',
    'défardé',
    'défasse',
    'défauts',
    'défende',
    'défends',
    'défendu',
    'défense',
    'déféqua',
    'déféqué',
    'défèque',
    'déferai',
    'déférai',
    'déferas',
    'déféras',
    'déférât',
    'déférée',
    'déférer',
    'déférés',
    'défères',
    'déferez',
    'déférez',
    'déferla',
    'déferle',
    'déferlé',
    'déferra',
    'déferre',
    'déferré',
    'défiais',
    'défiait',
    'défiant',
    'défibra',
    'défibre',
    'défibré',
    'déficha',
    'défiche',
    'défiché',
    'déficit',
    'défiées',
    'défient',
    'défiera',
    'défigea',
    'défigée',
    'défiger',
    'défiges',
    'défigés',
    'défigez',
    'défiiez',
    'défilai',
    'défilas',
    'défilât',
    'défilée',
    'défiler',
    'défiles',
    'défilés',
    'défilez',
    'défîmes',
    'définie',
    'définir',
    'définis',
    'définit',
    'définît',
    'défions',
    'défisse',
    'défîtes',
    'déflora',
    'déflore',
    'défloré',
    'défolia',
    'défolie',
    'défolié',
    'défonça',
    'défonce',
    'défoncé',
    'déforça',
    'déforce',
    'déforcé',
    'déforma',
    'déforme',
    'déformé',
    'défoula',
    'défoule',
    'défoulé',
    'défraie',
    'défraya',
    'défraye',
    'défrayé',
    'défripa',
    'défripe',
    'défripé',
    'défrisa',
    'défrise',
    'défrisé',
    'défunte',
    'défunts',
    'dégagea',
    'dégagée',
    'dégager',
    'dégages',
    'dégagés',
    'dégagez',
    'dégaina',
    'dégaine',
    'dégainé',
    'déganta',
    'dégante',
    'déganté',
    'dégarni',
    'dégazai',
    'dégazas',
    'dégazât',
    'dégazée',
    'dégazer',
    'dégazes',
    'dégazés',
    'dégazez',
    'dégelai',
    'dégelas',
    'dégelât',
    'dégelée',
    'dégeler',
    'dégelés',
    'dégèles',
    'dégelez',
    'dégerma',
    'dégerme',
    'dégermé',
    'dégîtai',
    'dégîtas',
    'dégîtât',
    'dégîtée',
    'dégîter',
    'dégîtes',
    'dégîtés',
    'dégîtez',
    'dégivra',
    'dégivre',
    'dégivré',
    'déglaça',
    'déglace',
    'déglacé',
    'dégluai',
    'dégluas',
    'dégluât',
    'dégluée',
    'dégluer',
    'déglues',
    'déglués',
    'dégluez',
    'dégluti',
    'dégoisa',
    'dégoise',
    'dégoisé',
    'dégomma',
    'dégomme',
    'dégommé',
    'dégonda',
    'dégonde',
    'dégondé',
    'dégorge',
    'dégorgé',
    'dégotai',
    'dégotas',
    'dégotât',
    'dégotée',
    'dégoter',
    'dégotes',
    'dégotés',
    'dégotez',
    'dégotta',
    'dégotte',
    'dégotté',
    'dégoûta',
    'dégoûte',
    'dégoûté',
    'dégoûts',
    'dégrada',
    'dégrade',
    'dégradé',
    'dégrafa',
    'dégrafe',
    'dégrafé',
    'dégréai',
    'dégréas',
    'dégréât',
    'dégréée',
    'dégréer',
    'dégrées',
    'dégréés',
    'dégréez',
    'dégreva',
    'dégrevé',
    'dégrève',
    'dégrisa',
    'dégrise',
    'dégrisé',
    'déguisa',
    'déguise',
    'déguisé',
    'dégusta',
    'déguste',
    'dégusté',
    'déhalai',
    'déhalas',
    'déhalât',
    'déhalée',
    'déhaler',
    'déhales',
    'déhalés',
    'déhalez',
    'déharda',
    'déharde',
    'déhardé',
    'déhotta',
    'déhotte',
    'déhotté',
    'déicide',
    'déifiai',
    'déifias',
    'déifiât',
    'déifiée',
    'déifier',
    'déifies',
    'déifiés',
    'déifiez',
    'déistes',
    'déjanta',
    'déjante',
    'déjanté',
    'déjauge',
    'déjaugé',
    'déjetai',
    'déjetas',
    'déjetât',
    'déjetée',
    'déjeter',
    'déjetés',
    'déjetez',
    'déjette',
    'déjeuna',
    'déjeune',
    'déjeuné',
    'déjouai',
    'déjouas',
    'déjouât',
    'déjouée',
    'déjouer',
    'déjoues',
    'déjoués',
    'déjouez',
    'déjucha',
    'déjuche',
    'déjuché',
    'déjugea',
    'déjugée',
    'déjuger',
    'déjuges',
    'déjugés',
    'déjugez',
    'délabra',
    'délabre',
    'délabré',
    'délaçai',
    'délaças',
    'délaçât',
    'délacée',
    'délacer',
    'délaces',
    'délacés',
    'délacez',
    'délaies',
    'délaina',
    'délaine',
    'délainé',
    'délaita',
    'délaite',
    'délaité',
    'délarda',
    'délarde',
    'délardé',
    'délassa',
    'délasse',
    'délassé',
    'délatta',
    'délatte',
    'délatté',
    'délavai',
    'délavas',
    'délavât',
    'délavée',
    'délaver',
    'délaves',
    'délavés',
    'délavez',
    'délayai',
    'délayas',
    'délayât',
    'délayée',
    'délayer',
    'délayes',
    'délayés',
    'délayez',
    'délecta',
    'délecte',
    'délecté',
    'délégua',
    'délégué',
    'délègue',
    'délesta',
    'déleste',
    'délesté',
    'déliais',
    'déliait',
    'déliant',
    'délicat',
    'délices',
    'déliées',
    'délient',
    'déliera',
    'déliiez',
    'délinéa',
    'délinée',
    'délinéé',
    'délions',
    'délirai',
    'déliras',
    'délirât',
    'délirer',
    'délires',
    'délirez',
    'délissa',
    'délisse',
    'délissé',
    'délitai',
    'délitas',
    'délitât',
    'délitée',
    'déliter',
    'délites',
    'délités',
    'délitez',
    'délivra',
    'délivre',
    'délivré',
    'délogea',
    'délogée',
    'déloger',
    'déloges',
    'délogés',
    'délogez',
    'déloqua',
    'déloque',
    'déloqué',
    'délovai',
    'délovas',
    'délovât',
    'délovée',
    'délover',
    'déloves',
    'délovés',
    'délovez',
    'déloyal',
    'déluges',
    'délurai',
    'déluras',
    'délurât',
    'délurée',
    'délurer',
    'délures',
    'délurés',
    'délurez',
    'délutai',
    'délutas',
    'délutât',
    'délutée',
    'déluter',
    'délutes',
    'délutés',
    'délutez',
    'demains',
    'demanda',
    'demande',
    'demandé',
    'démange',
    'démangé',
    'démaria',
    'démarie',
    'démarié',
    'démarra',
    'démarre',
    'démarré',
    'démâtai',
    'démâtas',
    'démâtât',
    'démâtée',
    'démâter',
    'démâtes',
    'démâtés',
    'démâtez',
    'démêlai',
    'démêlas',
    'démêlât',
    'démêlée',
    'démêler',
    'démêles',
    'démêlés',
    'démêlez',
    'démenai',
    'démenas',
    'démenât',
    'démence',
    'démenée',
    'démener',
    'démenés',
    'démènes',
    'démenez',
    'démente',
    'démenti',
    'déments',
    'démerda',
    'démerde',
    'démerdé',
    'démette',
    'demeura',
    'demeure',
    'demeuré',
    'demiard',
    'démîmes',
    'déminai',
    'déminas',
    'déminât',
    'déminée',
    'déminer',
    'démines',
    'déminés',
    'déminez',
    'démises',
    'démisse',
    'démîtes',
    'démodai',
    'démodas',
    'démodât',
    'démodée',
    'démoder',
    'démodes',
    'démodés',
    'demodex',
    'démodez',
    'démolie',
    'démolir',
    'démolis',
    'démolit',
    'démolît',
    'démonta',
    'démonte',
    'démonté',
    'démorde',
    'démords',
    'démordu',
    'démoula',
    'démoule',
    'démoulé',
    'démunie',
    'démunir',
    'démunis',
    'démunit',
    'démunît',
    'démurai',
    'démuras',
    'démurât',
    'démurée',
    'démurer',
    'démures',
    'démurés',
    'démurez',
    'démurge',
    'démurgé',
    'dénanti',
    'dénatta',
    'dénatte',
    'dénatté',
    'déneige',
    'déneigé',
    'dénerva',
    'dénerve',
    'dénervé',
    'dengues',
    'déniais',
    'déniait',
    'déniant',
    'dénicha',
    'déniche',
    'déniché',
    'déniées',
    'dénient',
    'déniera',
    'deniers',
    'dénigra',
    'dénigre',
    'dénigré',
    'déniiez',
    'dénions',
    'dénitra',
    'dénitre',
    'dénitré',
    'dénoies',
    'dénomma',
    'dénomme',
    'dénommé',
    'dénonça',
    'dénonce',
    'dénoncé',
    'dénotai',
    'dénotas',
    'dénotât',
    'dénotée',
    'dénoter',
    'dénotes',
    'dénotés',
    'dénotez',
    'dénouai',
    'dénouas',
    'dénouât',
    'dénouée',
    'dénouer',
    'dénoues',
    'dénoués',
    'dénouez',
    'dénoyai',
    'dénoyas',
    'dénoyât',
    'dénoyée',
    'dénoyer',
    'dénoyés',
    'dénoyez',
    'denrées',
    'densité',
    'dentale',
    'dentaux',
    'dentées',
    'dentela',
    'dentelé',
    'dentier',
    'dentine',
    'denture',
    'dénuais',
    'dénuait',
    'dénuant',
    'dénudai',
    'dénudas',
    'dénudât',
    'dénudée',
    'dénuder',
    'dénudes',
    'dénudés',
    'dénudez',
    'dénuées',
    'dénuent',
    'dénuera',
    'dénuiez',
    'dénuons',
    'dépanna',
    'dépanne',
    'dépanné',
    'déparai',
    'déparas',
    'déparât',
    'déparée',
    'déparer',
    'dépares',
    'déparés',
    'déparez',
    'déparia',
    'déparie',
    'déparié',
    'déparla',
    'déparle',
    'déparlé',
    'départe',
    'départi',
    'départs',
    'dépassa',
    'dépasse',
    'dépassé',
    'dépavai',
    'dépavas',
    'dépavât',
    'dépavée',
    'dépaver',
    'dépaves',
    'dépavés',
    'dépavez',
    'dépaysa',
    'dépayse',
    'dépaysé',
    'dépeçai',
    'dépeças',
    'dépeçât',
    'dépecée',
    'dépecer',
    'dépecés',
    'dépèces',
    'dépecez',
    'dépêcha',
    'dépêche',
    'dépêché',
    'dépeins',
    'dépeint',
    'dépende',
    'dépends',
    'dépendu',
    'dépensa',
    'dépense',
    'dépensé',
    'dépérir',
    'dépéris',
    'dépérit',
    'dépérît',
    'dépêtra',
    'dépêtre',
    'dépêtré',
    'déphasa',
    'déphase',
    'déphasé',
    'dépilai',
    'dépilas',
    'dépilât',
    'dépilée',
    'dépiler',
    'dépiles',
    'dépilés',
    'dépilez',
    'dépiqua',
    'dépique',
    'dépiqué',
    'dépista',
    'dépiste',
    'dépisté',
    'dépitai',
    'dépitas',
    'dépitât',
    'dépitée',
    'dépiter',
    'dépites',
    'dépités',
    'dépitez',
    'déplaça',
    'déplace',
    'déplacé',
    'déplais',
    'déplaît',
    'dépliai',
    'déplias',
    'dépliât',
    'dépliée',
    'déplier',
    'déplies',
    'dépliés',
    'dépliez',
    'déploie',
    'déplora',
    'déplore',
    'déploré',
    'déploya',
    'déployé',
    'dépluma',
    'déplume',
    'déplumé',
    'dépolie',
    'dépolir',
    'dépolis',
    'dépolit',
    'dépolît',
    'déporta',
    'déporte',
    'déporté',
    'déports',
    'déposai',
    'déposas',
    'déposât',
    'déposée',
    'déposer',
    'déposes',
    'déposés',
    'déposez',
    'dépotai',
    'dépotas',
    'dépotât',
    'dépotée',
    'dépoter',
    'dépotes',
    'dépotés',
    'dépotez',
    'déprava',
    'déprave',
    'dépravé',
    'déprend',
    'déprima',
    'déprime',
    'déprimé',
    'déprisa',
    'déprise',
    'déprisé',
    'dépulpa',
    'dépulpe',
    'dépulpé',
    'dépurai',
    'dépuras',
    'dépurât',
    'dépurée',
    'dépurer',
    'dépures',
    'dépurés',
    'dépurez',
    'députai',
    'députas',
    'députât',
    'députée',
    'députer',
    'députes',
    'députés',
    'députez',
    'déradai',
    'déradas',
    'déradât',
    'dérader',
    'dérades',
    'déradez',
    'déragea',
    'dérager',
    'dérages',
    'déragez',
    'déraidi',
    'déraies',
    'dérange',
    'dérangé',
    'dérapai',
    'dérapas',
    'dérapât',
    'déraper',
    'dérapes',
    'dérapez',
    'dérasai',
    'dérasas',
    'dérasât',
    'dérasée',
    'déraser',
    'dérases',
    'dérasés',
    'dérasez',
    'dératai',
    'dératas',
    'dératât',
    'dératée',
    'dérater',
    'dérates',
    'dératés',
    'dératez',
    'dérayai',
    'dérayas',
    'dérayât',
    'dérayée',
    'dérayer',
    'dérayes',
    'dérayés',
    'dérayez',
    'dérégla',
    'déréglé',
    'dérègle',
    'déridai',
    'déridas',
    'déridât',
    'déridée',
    'dérider',
    'dérides',
    'déridés',
    'déridez',
    'dérivai',
    'dérivas',
    'dérivât',
    'dérivée',
    'dériver',
    'dérives',
    'dérivés',
    'dérivez',
    'dermite',
    'dernier',
    'dérobai',
    'dérobas',
    'dérobât',
    'dérobée',
    'dérober',
    'dérobes',
    'dérobés',
    'dérobez',
    'dérocha',
    'déroche',
    'déroché',
    'dérodai',
    'dérodas',
    'dérodât',
    'dérodée',
    'déroder',
    'dérodes',
    'dérodés',
    'dérodez',
    'dérogea',
    'déroger',
    'déroges',
    'dérogez',
    'déroula',
    'déroule',
    'déroulé',
    'dérouta',
    'déroute',
    'dérouté',
    'derrick',
    'désarma',
    'désarme',
    'désarmé',
    'désaveu',
    'désaxai',
    'désaxas',
    'désaxât',
    'désaxée',
    'désaxer',
    'désaxes',
    'désaxés',
    'désaxez',
    'descend',
    'déserta',
    'déserte',
    'déserté',
    'déserts',
    'désigna',
    'désigne',
    'désigné',
    'designs',
    'désirai',
    'désiras',
    'désirât',
    'désirée',
    'désirer',
    'désires',
    'désirés',
    'désirez',
    'désista',
    'désiste',
    'désisté',
    'desmans',
    'désobéi',
    'désolai',
    'désolas',
    'désolât',
    'désolée',
    'désoler',
    'désoles',
    'désolés',
    'désolez',
    'désorba',
    'désorbe',
    'désorbé',
    'désossa',
    'désosse',
    'désossé',
    'despote',
    'dessala',
    'dessale',
    'dessalé',
    'dessein',
    'dessers',
    'dessert',
    'dessina',
    'dessine',
    'dessiné',
    'dessins',
    'dessola',
    'dessole',
    'dessolé',
    'dessous',
    'destina',
    'destine',
    'destiné',
    'destins',
    'désuète',
    'désuets',
    'désunie',
    'désunir',
    'désunis',
    'désunit',
    'désunît',
    'détacha',
    'détache',
    'détaché',
    'détails',
    'détalai',
    'détalas',
    'détalât',
    'détaler',
    'détales',
    'détalez',
    'détalla',
    'détalle',
    'détallé',
    'détaxai',
    'détaxas',
    'détaxât',
    'détaxée',
    'détaxer',
    'détaxes',
    'détaxés',
    'détaxez',
    'détecta',
    'détecte',
    'détecté',
    'déteins',
    'déteint',
    'dételai',
    'dételas',
    'dételât',
    'dételée',
    'dételer',
    'dételés',
    'dételez',
    'dételle',
    'détende',
    'détends',
    'détendu',
    'détenez',
    'détenir',
    'détente',
    'détenue',
    'détenus',
    'déterge',
    'détergé',
    'déterra',
    'déterre',
    'déterré',
    'détesta',
    'déteste',
    'détesté',
    'détiens',
    'détient',
    'détirai',
    'détiras',
    'détirât',
    'détirée',
    'détirer',
    'détires',
    'détirés',
    'détirez',
    'détissa',
    'détisse',
    'détissé',
    'détonai',
    'détonas',
    'détonât',
    'détoner',
    'détones',
    'détonez',
    'détonna',
    'détonne',
    'détonné',
    'détorde',
    'détords',
    'détordu',
    'détoura',
    'détoure',
    'détouré',
    'détours',
    'détroit',
    'détrôna',
    'détrône',
    'détrôné',
    'détruis',
    'détruit',
    'dévalai',
    'dévalas',
    'dévalât',
    'dévalée',
    'dévaler',
    'dévales',
    'dévalés',
    'dévalez',
    'dévalua',
    'dévalue',
    'dévalué',
    'devança',
    'devance',
    'devancé',
    'devants',
    'dévasai',
    'dévasas',
    'dévasât',
    'dévasée',
    'dévaser',
    'dévases',
    'dévasés',
    'dévasez',
    'dévasta',
    'dévaste',
    'dévasté',
    'déveine',
    'devenez',
    'devenir',
    'déventa',
    'dévente',
    'déventé',
    'devenue',
    'devenus',
    'déverdi',
    'déverni',
    'déversa',
    'déverse',
    'déversé',
    'dévêtes',
    'dévêtez',
    'dévêtir',
    'dévêtis',
    'dévêtit',
    'dévêtît',
    'dévêtue',
    'dévêtus',
    'déviais',
    'déviait',
    'déviant',
    'dévidai',
    'dévidas',
    'dévidât',
    'dévidée',
    'dévider',
    'dévides',
    'dévidés',
    'dévidez',
    'déviées',
    'deviens',
    'devient',
    'dévient',
    'déviera',
    'déviiez',
    'devinai',
    'devinas',
    'devinât',
    'devinée',
    'deviner',
    'devines',
    'devinés',
    'devinez',
    'devions',
    'dévions',
    'devisai',
    'devisas',
    'devisât',
    'deviser',
    'devises',
    'devisez',
    'dévissa',
    'dévisse',
    'dévissé',
    'dévoies',
    'dévoila',
    'dévoile',
    'dévoilé',
    'devoirs',
    'dévolta',
    'dévolte',
    'dévolté',
    'dévolue',
    'dévolus',
    'dévorai',
    'dévoras',
    'dévorât',
    'dévorée',
    'dévorer',
    'dévores',
    'dévorés',
    'dévorez',
    'dévotes',
    'dévouai',
    'dévouas',
    'dévouât',
    'dévouée',
    'dévouer',
    'dévoues',
    'dévoués',
    'dévouez',
    'dévoyai',
    'dévoyas',
    'dévoyât',
    'dévoyée',
    'dévoyer',
    'dévoyés',
    'dévoyez',
    'devrais',
    'devrait',
    'devriez',
    'devrons',
    'devront',
    'dextres',
    'diabète',
    'diables',
    'diabolo',
    'diacide',
    'diacode',
    'diacres',
    'diadème',
    'dialysa',
    'dialyse',
    'dialysé',
    'diamant',
    'diamide',
    'diantre',
    'diaprai',
    'diapras',
    'diaprât',
    'diaprée',
    'diaprer',
    'diapres',
    'diaprés',
    'diaprez',
    'diaules',
    'dicline',
    'dicrote',
    'dictais',
    'dictait',
    'dictame',
    'dictant',
    'dictées',
    'dictent',
    'dictera',
    'dictiez',
    'diction',
    'dictons',
    'didymes',
    'diérèse',
    'diergol',
    'diesels',
    'diffama',
    'diffame',
    'diffamé',
    'différa',
    'différé',
    'diffère',
    'diffusa',
    'diffuse',
    'diffusé',
    'digérai',
    'digéras',
    'digérât',
    'digérée',
    'digérer',
    'digérés',
    'digères',
    'digérez',
    'digeste',
    'digests',
    'digital',
    'digitée',
    'digités',
    'dignité',
    'diktats',
    'dilatai',
    'dilatas',
    'dilatât',
    'dilatée',
    'dilater',
    'dilates',
    'dilatés',
    'dilatez',
    'dilemme',
    'diluais',
    'diluait',
    'diluant',
    'diluées',
    'diluent',
    'diluera',
    'diluiez',
    'diluons',
    'diminua',
    'diminue',
    'diminué',
    'dînâmes',
    'dînasse',
    'dînâtes',
    'dindons',
    'dînerai',
    'dîneras',
    'dînerez',
    'dînette',
    'dîneurs',
    'dîneuse',
    'dinghie',
    'dinghys',
    'dinguai',
    'dinguas',
    'dinguât',
    'dinguée',
    'dinguer',
    'dingues',
    'dingués',
    'dinguez',
    'dînions',
    'diocèse',
    'dionées',
    'dioptre',
    'diorama',
    'diorite',
    'dioxine',
    'dioxyde',
    'diphasé',
    'diplôma',
    'diplôme',
    'diplômé',
    'dipodes',
    'dipôles',
    'diptère',
    'directe',
    'directs',
    'dirhams',
    'dirhems',
    'dirigea',
    'dirigée',
    'diriger',
    'diriges',
    'dirigés',
    'dirigez',
    'dirions',
    'discale',
    'discaux',
    'discret',
    'discuta',
    'discute',
    'discuté',
    'diserte',
    'diserts',
    'disette',
    'diseurs',
    'diseuse',
    'disions',
    'disparu',
    'disposa',
    'dispose',
    'disposé',
    'disputa',
    'dispute',
    'disputé',
    'disques',
    'dissent',
    'dissiez',
    'dissipa',
    'dissipe',
    'dissipé',
    'dissolu',
    'dissona',
    'dissone',
    'dissoné',
    'dissous',
    'dissout',
    'distale',
    'distant',
    'distaux',
    'distend',
    'distord',
    'distyle',
    'diurèse',
    'diurnal',
    'diurnes',
    'divagua',
    'divague',
    'divagué',
    'diverge',
    'divergé',
    'diverse',
    'diverti',
    'divette',
    'divines',
    'divisai',
    'divisas',
    'divisât',
    'divisée',
    'diviser',
    'divises',
    'divisés',
    'divisez',
    'divorça',
    'divorce',
    'divorcé',
    'dixième',
    'dizaine',
    'dizains',
    'dociles',
    'dockers',
    'docteur',
    'dodinai',
    'dodinas',
    'dodinât',
    'dodinée',
    'dodiner',
    'dodines',
    'dodinés',
    'dodinez',
    'doggers',
    'doigtai',
    'doigtas',
    'doigtât',
    'doigtée',
    'doigter',
    'doigtes',
    'doigtés',
    'doigtez',
    'doivent',
    'dolâmes',
    'dolasse',
    'dolâtes',
    'doleaux',
    'dolente',
    'dolents',
    'dolerai',
    'doleras',
    'dolerez',
    'dolines',
    'dolions',
    'dollars',
    'dolmans',
    'dolmens',
    'doloire',
    'dolomie',
    'dolosif',
    'domaine',
    'dominai',
    'dominas',
    'dominât',
    'dominée',
    'dominer',
    'domines',
    'dominés',
    'dominez',
    'dominos',
    'domisme',
    'dommage',
    'domptai',
    'domptas',
    'domptât',
    'domptée',
    'dompter',
    'domptes',
    'domptés',
    'domptez',
    'donacie',
    'dondons',
    'donjons',
    'donnais',
    'donnait',
    'donnant',
    'données',
    'donnent',
    'donnera',
    'donneur',
    'donniez',
    'donnons',
    'dopages',
    'dopâmes',
    'dopante',
    'dopants',
    'dopasse',
    'dopâtes',
    'doperai',
    'doperas',
    'doperez',
    'dopings',
    'dopions',
    'dorades',
    'dorages',
    'dorâmes',
    'dorasse',
    'dorâtes',
    'dorerai',
    'doreras',
    'dorerez',
    'doreurs',
    'doreuse',
    'doriens',
    'dorions',
    'dorique',
    'dorlota',
    'dorlote',
    'dorloté',
    'dormais',
    'dormait',
    'dormant',
    'dorment',
    'dormeur',
    'dormiez',
    'dormira',
    'dormons',
    'dorsale',
    'dorsaux',
    'dortoir',
    'dorures',
    'dosable',
    'dosages',
    'dosâmes',
    'dosasse',
    'dosâtes',
    'doserai',
    'doseras',
    'doserez',
    'doseurs',
    'dosions',
    'dossard',
    'dossier',
    'dotales',
    'dotâmes',
    'dotasse',
    'dotâtes',
    'doterai',
    'doteras',
    'doterez',
    'dotions',
    'douaire',
    'douâmes',
    'douanes',
    'douasse',
    'douâtes',
    'doublai',
    'doublas',
    'doublât',
    'doublée',
    'doubler',
    'doubles',
    'doublés',
    'doublet',
    'doublez',
    'doublon',
    'doucets',
    'douceur',
    'douchai',
    'douchas',
    'douchât',
    'douchée',
    'doucher',
    'douches',
    'douchés',
    'douchez',
    'doucies',
    'doucine',
    'doucins',
    'doucira',
    'douelle',
    'douerai',
    'doueras',
    'douerez',
    'douille',
    'douions',
    'douleur',
    'dourine',
    'doutais',
    'doutait',
    'doutant',
    'doutées',
    'doutent',
    'doutera',
    'douteur',
    'douteux',
    'doutiez',
    'doutons',
    'douvain',
    'douzain',
    'doyenne',
    'doyenné',
    'dracéna',
    'drachme',
    'dragage',
    'dragées',
    'drageon',
    'dragons',
    'draguai',
    'draguas',
    'draguât',
    'draguée',
    'draguer',
    'dragues',
    'dragués',
    'draguez',
    'draient',
    'draiera',
    'draille',
    'drainai',
    'drainas',
    'drainât',
    'drainée',
    'drainer',
    'draines',
    'drainés',
    'drainez',
    'drakkar',
    'drapais',
    'drapait',
    'drapant',
    'drapeau',
    'drapées',
    'drapent',
    'drapera',
    'drapier',
    'drapiez',
    'drapons',
    'draveur',
    'drayais',
    'drayait',
    'drayant',
    'drayées',
    'drayent',
    'drayera',
    'drayiez',
    'drayoir',
    'drayons',
    'drennes',
    'dressai',
    'dressas',
    'dressât',
    'dressée',
    'dresser',
    'dresses',
    'dressés',
    'dressez',
    'dribbla',
    'dribble',
    'dribblé',
    'drillai',
    'drillas',
    'drillât',
    'drillée',
    'driller',
    'drilles',
    'drillés',
    'drillez',
    'drisses',
    'drivais',
    'drivait',
    'drivant',
    'drivées',
    'drivent',
    'drivera',
    'drivers',
    'driviez',
    'drivons',
    'drogman',
    'droguai',
    'droguas',
    'droguât',
    'droguée',
    'droguer',
    'drogues',
    'drogués',
    'droguet',
    'droguez',
    'droites',
    'drontes',
    'dropais',
    'dropait',
    'dropant',
    'dropées',
    'dropent',
    'dropera',
    'dropiez',
    'dropons',
    'droppai',
    'droppas',
    'droppât',
    'droppée',
    'dropper',
    'droppes',
    'droppés',
    'droppez',
    'drossai',
    'drossas',
    'drossât',
    'drossée',
    'drosser',
    'drosses',
    'drossés',
    'drossez',
    'druides',
    'drumlin',
    'drummer',
    'dryades',
    'dualité',
    'ducales',
    'ducasse',
    'ductile',
    'duègnes',
    'duettos',
    'dugongs',
    'dulcite',
    'dumping',
    'dundees',
    'dunette',
    'duopole',
    'dupâmes',
    'dupasse',
    'dupâtes',
    'duperai',
    'duperas',
    'duperez',
    'duperie',
    'dupeurs',
    'dupeuse',
    'dupions',
    'duplexa',
    'duplexe',
    'duplexé',
    'durable',
    'duramen',
    'durâmes',
    'durasse',
    'durâtes',
    'duratif',
    'durcies',
    'durcira',
    'durerai',
    'dureras',
    'durerez',
    'duretés',
    'durions',
    'dussent',
    'dussiez',
    'duumvir',
    'duvetai',
    'duvetas',
    'duvetât',
    'duvetée',
    'duveter',
    'duvetés',
    'duvetez',
    'duvette',
    'dynamos',
    'dynaste',
    'dyspnée',
    'dysurie',
    'dytique',
    'ébahies',
    'ébahira',
    'ébarbai',
    'ébarbas',
    'ébarbât',
    'ébarbée',
    'ébarber',
    'ébarbes',
    'ébarbés',
    'ébarbez',
    'ébattez',
    'ébattre',
    'ébattue',
    'ébattus',
    'ébaubie',
    'ébaubir',
    'ébaubis',
    'ébaucha',
    'ébauche',
    'ébauché',
    'ébaudie',
    'ébaudir',
    'ébaudis',
    'ébavura',
    'ébavure',
    'ébavuré',
    'ébénier',
    'éberlua',
    'éberlue',
    'éberlué',
    'ébisela',
    'ébiselé',
    'ébisèle',
    'éblouie',
    'éblouir',
    'éblouis',
    'éblouit',
    'éblouît',
    'ébonite',
    'éborgna',
    'éborgne',
    'éborgné',
    'ébossai',
    'ébossas',
    'ébossât',
    'ébossée',
    'ébosser',
    'ébosses',
    'ébossés',
    'ébossez',
    'ébouais',
    'ébouait',
    'ébouant',
    'ébouées',
    'ébouent',
    'ébouera',
    'éboueur',
    'ébouiez',
    'éboulai',
    'éboulas',
    'éboulât',
    'éboulée',
    'ébouler',
    'éboules',
    'éboulés',
    'éboulez',
    'éboulis',
    'ébouons',
    'ébourra',
    'ébourre',
    'ébourré',
    'éboutai',
    'éboutas',
    'éboutât',
    'éboutée',
    'ébouter',
    'éboutes',
    'éboutés',
    'éboutez',
    'ébraisa',
    'ébraise',
    'ébraisé',
    'ébranla',
    'ébranle',
    'ébranlé',
    'ébrasai',
    'ébrasas',
    'ébrasât',
    'ébrasée',
    'ébraser',
    'ébrases',
    'ébrasés',
    'ébrasez',
    'ébrécha',
    'ébréché',
    'ébrèche',
    'ébriété',
    'ébrouée',
    'ébrouer',
    'ébroués',
    'ébrouez',
    'ébruita',
    'ébruite',
    'ébruité',
    'éburnée',
    'éburnés',
    'écachai',
    'écachas',
    'écachât',
    'écachée',
    'écacher',
    'écaches',
    'écachés',
    'écachez',
    'écaffai',
    'écaffas',
    'écaffât',
    'écaffée',
    'écaffer',
    'écaffes',
    'écaffés',
    'écaffez',
    'écailla',
    'écaille',
    'écaillé',
    'écalais',
    'écalait',
    'écalant',
    'écalées',
    'écalent',
    'écalera',
    'écaliez',
    'écalons',
    'écalure',
    'écangua',
    'écangue',
    'écangué',
    'écartai',
    'écartas',
    'écartât',
    'écartée',
    'écarter',
    'écartes',
    'écartés',
    'écartez',
    'écaties',
    'écatira',
    'eccéité',
    'échalas',
    'échampi',
    'échange',
    'échangé',
    'échappa',
    'échappe',
    'échappé',
    'écharde',
    'écharna',
    'écharne',
    'écharné',
    'écharpa',
    'écharpe',
    'écharpé',
    'échasse',
    'échauda',
    'échaude',
    'échaudé',
    'échaula',
    'échaule',
    'échaulé',
    'échauma',
    'échaume',
    'échaumé',
    'échéant',
    'échelle',
    'échelon',
    'échevin',
    'échidné',
    'échinai',
    'échinas',
    'échinât',
    'échinée',
    'échiner',
    'échines',
    'échinés',
    'échinez',
    'échoies',
    'échoira',
    'échoppa',
    'échoppe',
    'échoppé',
    'échouai',
    'échouas',
    'échouât',
    'échouée',
    'échouer',
    'échoues',
    'échoués',
    'échouez',
    'écimage',
    'écimais',
    'écimait',
    'écimant',
    'écimées',
    'éciment',
    'écimera',
    'écimiez',
    'écimons',
    'éclaira',
    'éclaire',
    'éclairé',
    'éclairs',
    'éclatai',
    'éclatas',
    'éclatât',
    'éclatée',
    'éclater',
    'éclates',
    'éclatés',
    'éclatez',
    'éclipsa',
    'éclipse',
    'éclipsé',
    'éclissa',
    'éclisse',
    'éclissé',
    'éclopai',
    'éclopas',
    'éclopât',
    'éclopée',
    'écloper',
    'éclopes',
    'éclopés',
    'éclopez',
    'éclorai',
    'écloras',
    'éclorez',
    'écloses',
    'éclusai',
    'éclusas',
    'éclusât',
    'éclusée',
    'écluser',
    'écluses',
    'éclusés',
    'éclusez',
    'écobuai',
    'écobuas',
    'écobuât',
    'écobuée',
    'écobuer',
    'écobues',
    'écobués',
    'écobuez',
    'écocide',
    'écoeura',
    'écoeure',
    'écoeuré',
    'écolage',
    'écolier',
    'économe',
    'écopais',
    'écopait',
    'écopant',
    'écopées',
    'écopent',
    'écopera',
    'écopiez',
    'écopons',
    'écorais',
    'écorait',
    'écorant',
    'écorçai',
    'écorças',
    'écorçât',
    'écorcée',
    'écorcer',
    'écorces',
    'écorcés',
    'écorcez',
    'écorcha',
    'écorche',
    'écorché',
    'écorées',
    'écorent',
    'écorera',
    'écoriez',
    'écornai',
    'écornas',
    'écornât',
    'écornée',
    'écorner',
    'écornes',
    'écornés',
    'écornez',
    'écorons',
    'écossai',
    'écossas',
    'écossât',
    'écossée',
    'écosser',
    'écosses',
    'écossés',
    'écossez',
    'écotées',
    'écotype',
    'écoulai',
    'écoulas',
    'écoulât',
    'écoulée',
    'écouler',
    'écoules',
    'écoulés',
    'écoulez',
    'écourta',
    'écourte',
    'écourté',
    'écoutai',
    'écoutas',
    'écoutât',
    'écoutée',
    'écouter',
    'écoutes',
    'écoutés',
    'écoutez',
    'écrasai',
    'écrasas',
    'écrasât',
    'écrasée',
    'écraser',
    'écrases',
    'écrasés',
    'écrasez',
    'écrémai',
    'écrémas',
    'écrémât',
    'écrémée',
    'écrémer',
    'écrémés',
    'écrèmes',
    'écrémez',
    'écrêtai',
    'écrêtas',
    'écrêtât',
    'écrêtée',
    'écrêter',
    'écrêtes',
    'écrêtés',
    'écrêtez',
    'écriées',
    'écriiez',
    'écrions',
    'écrirai',
    'écriras',
    'écrirez',
    'écrites',
    'écrives',
    'écrivez',
    'écrivis',
    'écrivit',
    'écrivît',
    'écrouai',
    'écrouas',
    'écrouât',
    'écrouée',
    'écrouer',
    'écroues',
    'écroués',
    'écrouez',
    'écrouie',
    'écrouir',
    'écrouis',
    'écrouit',
    'écrouît',
    'écroulé',
    'écroûta',
    'écroûte',
    'écroûté',
    'ectasie',
    'ecthyma',
    'ectopie',
    'écubier',
    'écueils',
    'écuelle',
    'écuissa',
    'écuisse',
    'écuissé',
    'éculais',
    'éculait',
    'éculant',
    'éculées',
    'éculent',
    'éculera',
    'éculiez',
    'éculons',
    'écumage',
    'écumais',
    'écumait',
    'écumant',
    'écumées',
    'écument',
    'écumera',
    'écumeur',
    'écumeux',
    'écumiez',
    'écumons',
    'écurais',
    'écurait',
    'écurant',
    'écurées',
    'écurent',
    'écurera',
    'écuries',
    'écuriez',
    'écurons',
    'écusson',
    'écuyère',
    'écuyers',
    'eczémas',
    'édentai',
    'édentas',
    'édentât',
    'édentée',
    'édenter',
    'édentes',
    'édentés',
    'édentez',
    'édictai',
    'édictas',
    'édictât',
    'édictée',
    'édicter',
    'édictes',
    'édictés',
    'édictez',
    'édicule',
    'édifiai',
    'édifias',
    'édifiât',
    'édifice',
    'édifiée',
    'édifier',
    'édifies',
    'édifiés',
    'édifiez',
    'édilité',
    'éditais',
    'éditait',
    'éditant',
    'éditées',
    'éditent',
    'éditera',
    'éditeur',
    'éditiez',
    'édition',
    'éditons',
    'édredon',
    'éduquai',
    'éduquas',
    'éduquât',
    'éduquée',
    'éduquer',
    'éduques',
    'éduqués',
    'éduquez',
    'éfendis',
    'effaçai',
    'effaças',
    'effaçât',
    'effacée',
    'effacer',
    'effaces',
    'effacés',
    'effacez',
    'effanai',
    'effanas',
    'effanât',
    'effanée',
    'effaner',
    'effanes',
    'effanés',
    'effanez',
    'effarai',
    'effaras',
    'effarât',
    'effarée',
    'effarer',
    'effares',
    'effarés',
    'effarez',
    'effendi',
    'effigie',
    'effilai',
    'effilas',
    'effilât',
    'effilée',
    'effiler',
    'effiles',
    'effilés',
    'effilez',
    'effluva',
    'effluve',
    'effluvé',
    'efforcé',
    'efforts',
    'effraie',
    'effraya',
    'effraye',
    'effrayé',
    'effréné',
    'effrita',
    'effrite',
    'effrité',
    'effrois',
    'égaient',
    'égaiera',
    'égaillé',
    'égalais',
    'égalait',
    'égalant',
    'égalées',
    'égalent',
    'égalera',
    'égaliez',
    'égalisa',
    'égalise',
    'égalisé',
    'égalité',
    'égalons',
    'égarais',
    'égarait',
    'égarant',
    'égarées',
    'égarent',
    'égarera',
    'égariez',
    'égarons',
    'égayais',
    'égayait',
    'égayant',
    'égayées',
    'égayent',
    'égayera',
    'égayiez',
    'égayons',
    'égéenne',
    'égéries',
    'égermai',
    'égermas',
    'égermât',
    'égermée',
    'égermer',
    'égermes',
    'égermés',
    'égermez',
    'églefin',
    'églises',
    'églogue',
    'égoïnes',
    'égoïsme',
    'égoïste',
    'égorgea',
    'égorgée',
    'égorger',
    'égorges',
    'égorgés',
    'égorgez',
    'égoutta',
    'égoutte',
    'égoutté',
    'égraina',
    'égraine',
    'égrainé',
    'égrappa',
    'égrappe',
    'égrappé',
    'égrenai',
    'égrenas',
    'égrenât',
    'égrenée',
    'égrener',
    'égrenés',
    'égrènes',
    'égrenez',
    'égrisai',
    'égrisas',
    'égrisât',
    'égrisée',
    'égriser',
    'égrises',
    'égrisés',
    'égrisez',
    'égrugea',
    'égrugée',
    'égruger',
    'égruges',
    'égrugés',
    'égrugez',
    'éhontée',
    'éhontés',
    'éjacula',
    'éjacule',
    'éjaculé',
    'éjectai',
    'éjectas',
    'éjectât',
    'éjectée',
    'éjecter',
    'éjectes',
    'éjectés',
    'éjectez',
    'éjointa',
    'éjointe',
    'éjointé',
    'élabora',
    'élabore',
    'élaboré',
    'élagage',
    'élaguai',
    'élaguas',
    'élaguât',
    'élaguée',
    'élaguer',
    'élagues',
    'élagués',
    'élaguez',
    'élançai',
    'élanças',
    'élançât',
    'élancée',
    'élancer',
    'élances',
    'élancés',
    'élancez',
    'élargie',
    'élargir',
    'élargis',
    'élargit',
    'élargît',
    'élavées',
    'elbeufs',
    'électif',
    'élégant',
    'élégies',
    'élégira',
    'élément',
    'élevage',
    'élevais',
    'élevait',
    'élevant',
    'élevées',
    'élèvent',
    'élèvera',
    'éleveur',
    'éleviez',
    'élevons',
    'élidais',
    'élidait',
    'élidant',
    'élidées',
    'élident',
    'élidera',
    'élidiez',
    'élidons',
    'élimais',
    'élimait',
    'élimant',
    'élimées',
    'éliment',
    'élimera',
    'élimiez',
    'élimina',
    'élimine',
    'éliminé',
    'élimons',
    'élingua',
    'élingue',
    'élingué',
    'élirais',
    'élirait',
    'éliriez',
    'élirons',
    'éliront',
    'élisais',
    'élisait',
    'élisant',
    'élisent',
    'élisiez',
    'élision',
    'élisons',
    'élixirs',
    'ellipse',
    'élodées',
    'éloigna',
    'éloigne',
    'éloigné',
    'élongea',
    'élongée',
    'élonger',
    'élonges',
    'élongés',
    'élongez',
    'élucida',
    'élucide',
    'élucidé',
    'éludais',
    'éludait',
    'éludant',
    'éludées',
    'éludent',
    'éludera',
    'éludiez',
    'éludons',
    'élurent',
    'élusifs',
    'élusive',
    'élusses',
    'éluvial',
    'éluvion',
    'élyséen',
    'élytres',
    'émaciée',
    'émacier',
    'émaciés',
    'émaciez',
    'émailla',
    'émaille',
    'émaillé',
    'émanais',
    'émanait',
    'émanant',
    'émanche',
    'émanent',
    'émanera',
    'émaniez',
    'émanons',
    'émargea',
    'émargée',
    'émarger',
    'émarges',
    'émargés',
    'émargez',
    'embâcle',
    'emballa',
    'emballe',
    'emballé',
    'embarda',
    'embarde',
    'embardé',
    'embargo',
    'embarra',
    'embarre',
    'embarré',
    'embases',
    'embatte',
    'embattu',
    'embauma',
    'embaume',
    'embaumé',
    'embelli',
    'embêtai',
    'embêtas',
    'embêtât',
    'embêtée',
    'embêter',
    'embêtes',
    'embêtés',
    'embêtez',
    'emblava',
    'emblave',
    'emblavé',
    'emblème',
    'emboire',
    'emboîta',
    'emboîte',
    'emboîté',
    'embolie',
    'embossa',
    'embosse',
    'embossé',
    'embouai',
    'embouas',
    'embouât',
    'embouée',
    'embouer',
    'emboues',
    'emboués',
    'embouez',
    'embouti',
    'embouts',
    'embraie',
    'embrasa',
    'embrase',
    'embrasé',
    'embraya',
    'embraye',
    'embrayé',
    'embrela',
    'embrelé',
    'embrèle',
    'embreva',
    'embrevé',
    'embrève',
    'embruma',
    'embrume',
    'embrumé',
    'embruni',
    'embruns',
    'embryon',
    'embuais',
    'embuait',
    'embuant',
    'embûche',
    'embuées',
    'embuent',
    'embuera',
    'embuiez',
    'embûmes',
    'embuons',
    'embûtes',
    'embuvez',
    'éméchai',
    'éméchas',
    'éméchât',
    'éméchée',
    'émécher',
    'éméchés',
    'émèches',
    'éméchez',
    'émergea',
    'émerger',
    'émerges',
    'émergez',
    'émerisa',
    'émerise',
    'émerisé',
    'émérite',
    'émétine',
    'émettes',
    'émettez',
    'émettra',
    'émettre',
    'émeutes',
    'émeuves',
    'émiâmes',
    'émiasse',
    'émiâtes',
    'émierai',
    'émieras',
    'émierez',
    'émietta',
    'émiette',
    'émietté',
    'émigrai',
    'émigras',
    'émigrât',
    'émigrée',
    'émigrer',
    'émigres',
    'émigrés',
    'émigrez',
    'émiions',
    'éminçai',
    'éminças',
    'éminçât',
    'émincée',
    'émincer',
    'éminces',
    'émincés',
    'émincez',
    'éminent',
    'émirats',
    'émirent',
    'émisses',
    'émissif',
    'emmarge',
    'emmargé',
    'emmêlai',
    'emmêlas',
    'emmêlât',
    'emmêlée',
    'emmêler',
    'emmêles',
    'emmêlés',
    'emmêlez',
    'emmenai',
    'emmenas',
    'emmenât',
    'emmenée',
    'emmener',
    'emmenés',
    'emmènes',
    'emmenez',
    'emmerda',
    'emmerde',
    'emmerdé',
    'emmétra',
    'emmétré',
    'emmètre',
    'emmotté',
    'emmurai',
    'emmuras',
    'emmurât',
    'emmurée',
    'emmurer',
    'emmures',
    'emmurés',
    'emmurez',
    'émondai',
    'émondas',
    'émondât',
    'émondée',
    'émonder',
    'émondes',
    'émondés',
    'émondez',
    'émotifs',
    'émotion',
    'émotive',
    'émottai',
    'émottas',
    'émottât',
    'émottée',
    'émotter',
    'émottes',
    'émottés',
    'émottez',
    'émoucha',
    'émouche',
    'émouché',
    'émoudra',
    'émoudre',
    'émoules',
    'émoulez',
    'émoulue',
    'émoulus',
    'émoulut',
    'émoulût',
    'émoussa',
    'émousse',
    'émoussé',
    'émouvez',
    'émouvra',
    'empalai',
    'empalas',
    'empalât',
    'empalée',
    'empaler',
    'empales',
    'empalés',
    'empalez',
    'empalma',
    'empalme',
    'empalmé',
    'empanna',
    'empanne',
    'empanné',
    'emparée',
    'emparer',
    'emparés',
    'emparez',
    'empâtai',
    'empâtas',
    'empâtât',
    'empâtée',
    'empâter',
    'empâtes',
    'empâtés',
    'empâtez',
    'empatta',
    'empatte',
    'empatté',
    'empauma',
    'empaume',
    'empaumé',
    'empêcha',
    'empêche',
    'empêché',
    'empênai',
    'empênas',
    'empênât',
    'empênée',
    'empêner',
    'empênes',
    'empênés',
    'empênez',
    'empenna',
    'empenne',
    'empenné',
    'emperla',
    'emperle',
    'emperlé',
    'empesai',
    'empesas',
    'empesât',
    'empesée',
    'empeser',
    'empesés',
    'empèses',
    'empesez',
    'empesta',
    'empeste',
    'empesté',
    'empêtra',
    'empêtre',
    'empêtré',
    'emphase',
    'empiéta',
    'empiété',
    'empiète',
    'empilai',
    'empilas',
    'empilât',
    'empilée',
    'empiler',
    'empiles',
    'empilés',
    'empilez',
    'empirai',
    'empiras',
    'empirât',
    'empirée',
    'empirer',
    'empires',
    'empirés',
    'empirez',
    'emplies',
    'emplira',
    'emploie',
    'emplois',
    'employa',
    'employé',
    'empluma',
    'emplume',
    'emplumé',
    'empocha',
    'empoche',
    'empoché',
    'emporia',
    'emporta',
    'emporte',
    'emporté',
    'emports',
    'empotai',
    'empotas',
    'empotât',
    'empotée',
    'empoter',
    'empotes',
    'empotés',
    'empotez',
    'emprise',
    'emprunt',
    'empuses',
    'empyème',
    'empyrée',
    'émulais',
    'émulait',
    'émulant',
    'émulées',
    'émulent',
    'émulera',
    'émuliez',
    'émulons',
    'émulsif',
    'émurent',
    'émusses',
    'énarque',
    'encadra',
    'encadre',
    'encadré',
    'encagea',
    'encagée',
    'encager',
    'encages',
    'encagés',
    'encagez',
    'encaqua',
    'encaque',
    'encaqué',
    'encarta',
    'encarte',
    'encarté',
    'encarts',
    'encavai',
    'encavas',
    'encavât',
    'encavée',
    'encaver',
    'encaves',
    'encavés',
    'encavez',
    'encensa',
    'encense',
    'encensé',
    'enchère',
    'enchéri',
    'encirai',
    'enciras',
    'encirât',
    'encirée',
    'encirer',
    'encires',
    'encirés',
    'encirez',
    'enclava',
    'enclave',
    'enclavé',
    'encline',
    'enclins',
    'enclora',
    'enclore',
    'enclose',
    'encloua',
    'encloue',
    'encloué',
    'enclume',
    'encocha',
    'encoche',
    'encoché',
    'encodai',
    'encodas',
    'encodât',
    'encodée',
    'encoder',
    'encodes',
    'encodés',
    'encodez',
    'encolla',
    'encolle',
    'encollé',
    'encorda',
    'encorde',
    'encordé',
    'encorna',
    'encorne',
    'encorné',
    'encoure',
    'encours',
    'encourt',
    'encouru',
    'encrage',
    'encrais',
    'encrait',
    'encrant',
    'encrées',
    'encrent',
    'encrêpa',
    'encrêpe',
    'encrêpé',
    'encrera',
    'encreur',
    'encrier',
    'encriez',
    'encrine',
    'encrons',
    'encroué',
    'encuvai',
    'encuvas',
    'encuvât',
    'encuvée',
    'encuver',
    'encuves',
    'encuvés',
    'encuvez',
    'endauba',
    'endaube',
    'endaubé',
    'endémie',
    'endenta',
    'endente',
    'endenté',
    'endetta',
    'endette',
    'endetté',
    'endigua',
    'endigue',
    'endigué',
    'endives',
    'endorme',
    'endormi',
    'endossa',
    'endosse',
    'endossé',
    'endroit',
    'enduira',
    'enduire',
    'enduise',
    'enduite',
    'enduits',
    'endurai',
    'enduras',
    'endurât',
    'endurci',
    'endurée',
    'endurer',
    'endures',
    'endurés',
    'endurez',
    'enduros',
    'énergie',
    'énervai',
    'énervas',
    'énervât',
    'énervée',
    'énerver',
    'énerves',
    'énervés',
    'énervez',
    'enfaîta',
    'enfaîte',
    'enfaîté',
    'enfance',
    'enfanta',
    'enfante',
    'enfanté',
    'enfants',
    'enferma',
    'enferme',
    'enfermé',
    'enferra',
    'enferre',
    'enferré',
    'enficha',
    'enfiche',
    'enfiché',
    'enfilai',
    'enfilas',
    'enfilât',
    'enfilée',
    'enfiler',
    'enfiles',
    'enfilés',
    'enfilez',
    'enflais',
    'enflait',
    'enflant',
    'enflées',
    'enflent',
    'enflera',
    'enfliez',
    'enflons',
    'enflure',
    'enfoiré',
    'enfonça',
    'enfonce',
    'enfoncé',
    'enforci',
    'enfouie',
    'enfouir',
    'enfouis',
    'enfouit',
    'enfouît',
    'enfuies',
    'enfumai',
    'enfumas',
    'enfumât',
    'enfumée',
    'enfumer',
    'enfumes',
    'enfumés',
    'enfumez',
    'enfûtai',
    'enfûtas',
    'enfûtât',
    'enfûtée',
    'enfûter',
    'enfûtes',
    'enfûtés',
    'enfûtez',
    'enfuyez',
    'engagea',
    'engagée',
    'engager',
    'engages',
    'engagés',
    'engagez',
    'engaina',
    'engaine',
    'engainé',
    'engamai',
    'engamas',
    'engamât',
    'engamée',
    'engamer',
    'engames',
    'engamés',
    'engamez',
    'engavai',
    'engavas',
    'engavât',
    'engavée',
    'engaver',
    'engaves',
    'engavés',
    'engavez',
    'engerba',
    'engerbe',
    'engerbé',
    'englaça',
    'englace',
    'englacé',
    'engloba',
    'englobe',
    'englobé',
    'engluai',
    'engluas',
    'engluât',
    'engluée',
    'engluer',
    'englues',
    'englués',
    'engluez',
    'engobai',
    'engobas',
    'engobât',
    'engobée',
    'engober',
    'engobes',
    'engobés',
    'engobez',
    'engomma',
    'engomme',
    'engommé',
    'engonça',
    'engonce',
    'engoncé',
    'engorge',
    'engorgé',
    'engouée',
    'engouer',
    'engoués',
    'engouez',
    'engoula',
    'engoule',
    'engoulé',
    'engrais',
    'engrava',
    'engrave',
    'engravé',
    'engrena',
    'engrené',
    'engrène',
    'engrois',
    'enhardi',
    'enherba',
    'enherbe',
    'enherbé',
    'éniella',
    'énielle',
    'éniellé',
    'énièmes',
    'énigmes',
    'enivrai',
    'enivras',
    'enivrât',
    'enivrée',
    'enivrer',
    'enivres',
    'enivrés',
    'enivrez',
    'enjamba',
    'enjambe',
    'enjambé',
    'enjoins',
    'enjoint',
    'enjôlai',
    'enjôlas',
    'enjôlât',
    'enjôlée',
    'enjôler',
    'enjôles',
    'enjôlés',
    'enjôlez',
    'enjonça',
    'enjonce',
    'enjoncé',
    'enjouai',
    'enjouas',
    'enjouât',
    'enjouée',
    'enjouer',
    'enjoues',
    'enjoués',
    'enjouez',
    'enjugua',
    'enjugue',
    'enjugué',
    'enjuiva',
    'enjuive',
    'enjuivé',
    'enkysté',
    'enlaçai',
    'enlaças',
    'enlaçât',
    'enlacée',
    'enlacer',
    'enlaces',
    'enlacés',
    'enlacez',
    'enlaidi',
    'enlevai',
    'enlevas',
    'enlevât',
    'enlevée',
    'enlever',
    'enlevés',
    'enlèves',
    'enlevez',
    'enliais',
    'enliait',
    'enliant',
    'enliées',
    'enlient',
    'enliera',
    'enligna',
    'enligne',
    'enligné',
    'enliiez',
    'enlions',
    'enlisai',
    'enlisas',
    'enlisât',
    'enlisée',
    'enliser',
    'enlises',
    'enlisés',
    'enlisez',
    'ennéade',
    'enneige',
    'enneigé',
    'ennemie',
    'ennemis',
    'ennobli',
    'ennuage',
    'ennuagé',
    'ennuies',
    'ennuyai',
    'ennuyas',
    'ennuyât',
    'ennuyée',
    'ennuyer',
    'ennuyés',
    'ennuyez',
    'énonçai',
    'énonças',
    'énonçât',
    'énoncée',
    'énoncer',
    'énonces',
    'énoncés',
    'énoncez',
    'énormes',
    'énouais',
    'énouait',
    'énouant',
    'énouées',
    'énouent',
    'énouera',
    'énouiez',
    'énouons',
    'enquêta',
    'enquête',
    'enquêté',
    'enquise',
    'enragea',
    'enragée',
    'enrager',
    'enrages',
    'enragés',
    'enragez',
    'enraies',
    'enraqua',
    'enrayai',
    'enrayas',
    'enrayât',
    'enrayée',
    'enrayer',
    'enrayes',
    'enrayés',
    'enrayez',
    'enrênai',
    'enrênas',
    'enrênât',
    'enrênée',
    'enrêner',
    'enrênes',
    'enrênés',
    'enrênez',
    'enrhuma',
    'enrhume',
    'enrhumé',
    'enrichi',
    'enrobai',
    'enrobas',
    'enrobât',
    'enrobée',
    'enrober',
    'enrobes',
    'enrobés',
    'enrobez',
    'enrocha',
    'enroche',
    'enroché',
    'enrôlai',
    'enrôlas',
    'enrôlât',
    'enrôlée',
    'enrôler',
    'enrôles',
    'enrôlés',
    'enrôlez',
    'enrouai',
    'enrouas',
    'enrouât',
    'enrouée',
    'enrouer',
    'enroues',
    'enroués',
    'enrouez',
    'enroula',
    'enroule',
    'enroulé',
    'ensabla',
    'ensable',
    'ensablé',
    'ensacha',
    'ensache',
    'ensaché',
    'ensauvé',
    'enserra',
    'enserre',
    'enserré',
    'ensilai',
    'ensilas',
    'ensilât',
    'ensilée',
    'ensiler',
    'ensiles',
    'ensilés',
    'ensilez',
    'enstéra',
    'enstéré',
    'enstère',
    'ensuite',
    'ensuivi',
    'entabla',
    'entable',
    'entablé',
    'entacha',
    'entache',
    'entaché',
    'entamai',
    'entamas',
    'entamât',
    'entamée',
    'entamer',
    'entames',
    'entamés',
    'entâmes',
    'entamez',
    'entaque',
    'entaqué',
    'entassa',
    'entasse',
    'entassé',
    'entâtes',
    'entende',
    'entends',
    'entendu',
    'entente',
    'enterai',
    'enteras',
    'enterez',
    'enterra',
    'enterre',
    'enterré',
    'entêtai',
    'entêtas',
    'entêtât',
    'en-tête',
    'entêtée',
    'entêter',
    'entêtes',
    'entêtés',
    'entêtez',
    'entiché',
    'entière',
    'entiers',
    'entions',
    'entités',
    'entoila',
    'entoile',
    'entoilé',
    'entoirs',
    'entôlai',
    'entôlas',
    'entôlât',
    'entôlée',
    'entôler',
    'entôles',
    'entôlés',
    'entôlez',
    'entonna',
    'entonne',
    'entonné',
    'entorse',
    'entoura',
    'entoure',
    'entouré',
    'entours',
    'entrain',
    'entrais',
    'entrait',
    'entrant',
    'entrava',
    'entrave',
    'entravé',
    'entrées',
    'entrent',
    'entrera',
    'entrevu',
    'entriez',
    'entrons',
    'entubai',
    'entubas',
    'entubât',
    'entubée',
    'entuber',
    'entubes',
    'entubés',
    'entubez',
    'entures',
    'énucléa',
    'énuclée',
    'énucléé',
    'énuméra',
    'énuméré',
    'énumère',
    'envahie',
    'envahir',
    'envahis',
    'envahit',
    'envahît',
    'envasai',
    'envasas',
    'envasât',
    'envasée',
    'envaser',
    'envases',
    'envasés',
    'envasez',
    'enverge',
    'envergé',
    'enverra',
    'enviais',
    'enviait',
    'enviant',
    'envidai',
    'envidas',
    'envidât',
    'envidée',
    'envider',
    'envides',
    'envidés',
    'envidez',
    'enviées',
    'envient',
    'enviera',
    'envieux',
    'enviiez',
    'envinée',
    'envinés',
    'envions',
    'environ',
    'envoies',
    'envoilé',
    'envolée',
    'envoler',
    'envolés',
    'envolez',
    'envoûta',
    'envoûte',
    'envoûté',
    'envoyai',
    'envoyas',
    'envoyât',
    'envoyée',
    'envoyer',
    'envoyés',
    'envoyez',
    'enzymes',
    'éocènes',
    'éoliens',
    'éolithe',
    'éosines',
    'épactes',
    'épaisse',
    'épaissi',
    'épalais',
    'épalait',
    'épalant',
    'épalées',
    'épalent',
    'épalera',
    'épaliez',
    'épalons',
    'épampra',
    'épampre',
    'épampré',
    'épancha',
    'épanche',
    'épanché',
    'épandes',
    'épandez',
    'épandis',
    'épandit',
    'épandît',
    'épandra',
    'épandre',
    'épandue',
    'épandus',
    'épannai',
    'épannas',
    'épannât',
    'épannée',
    'épanner',
    'épannes',
    'épannés',
    'épannez',
    'épanoui',
    'épargna',
    'épargne',
    'épargné',
    'éparque',
    'éparses',
    'épatais',
    'épatait',
    'épatant',
    'épatées',
    'épatent',
    'épatera',
    'épateur',
    'épatiez',
    'épatons',
    'épaufra',
    'épaufre',
    'épaufré',
    'épaulai',
    'épaulas',
    'épaulât',
    'épaulée',
    'épauler',
    'épaules',
    'épaulés',
    'épaulez',
    'épeiche',
    'épeires',
    'épéisme',
    'épéiste',
    'épelais',
    'épelait',
    'épelant',
    'épelées',
    'épeliez',
    'épelles',
    'épelons',
    'épépina',
    'épépine',
    'épépiné',
    'éperdez',
    'éperdre',
    'éperdue',
    'éperdus',
    'éperlan',
    'éperons',
    'épervin',
    'épeulai',
    'épeulas',
    'épeulât',
    'épeulée',
    'épeuler',
    'épeules',
    'épeulés',
    'épeulez',
    'épeurai',
    'épeuras',
    'épeurât',
    'épeurée',
    'épeurer',
    'épeures',
    'épeurés',
    'épeurez',
    'éphèbes',
    'éphores',
    'épiages',
    'épiaire',
    'épiâmes',
    'épiasse',
    'épiâtes',
    'épiçais',
    'épiçait',
    'épiçant',
    'épicéas',
    'épicées',
    'épicène',
    'épicent',
    'épicera',
    'épicier',
    'épiciez',
    'épiçons',
    'épierai',
    'épieras',
    'épierez',
    'épierra',
    'épierre',
    'épierré',
    'épieurs',
    'épieuse',
    'épigées',
    'épigone',
    'épigyne',
    'épiions',
    'épilais',
    'épilait',
    'épilant',
    'épilées',
    'épilent',
    'épilera',
    'épiliez',
    'épillet',
    'épilobe',
    'épilons',
    'épinais',
    'épinait',
    'épinant',
    'épinard',
    'épinçai',
    'épinças',
    'épinçât',
    'épincée',
    'épincer',
    'épinces',
    'épincés',
    'épincez',
    'épinées',
    'épinent',
    'épinera',
    'épineux',
    'épingla',
    'épingle',
    'épinglé',
    'épinier',
    'épiniez',
    'épinons',
    'épiques',
    'épisode',
    'épissai',
    'épissas',
    'épissât',
    'épissée',
    'épisser',
    'épisses',
    'épissés',
    'épissez',
    'épitoge',
    'épitomé',
    'épîtres',
    'éploies',
    'éplorée',
    'éplorés',
    'éployai',
    'éployas',
    'éployât',
    'éployée',
    'éployer',
    'éployés',
    'éployez',
    'éplucha',
    'épluche',
    'épluché',
    'épointa',
    'épointe',
    'épointé',
    'épongea',
    'épongée',
    'éponger',
    'éponges',
    'épongés',
    'épongez',
    'épontes',
    'éponyme',
    'épopées',
    'époques',
    'épousai',
    'épousas',
    'épousât',
    'épousée',
    'épouser',
    'épouses',
    'épousés',
    'épousez',
    'époutia',
    'époutie',
    'époutié',
    'époutir',
    'époutis',
    'époutit',
    'époutît',
    'épreins',
    'épreint',
    'éprenez',
    'épreuve',
    'éprîmes',
    'éprises',
    'éprîtes',
    'éprouva',
    'éprouve',
    'éprouvé',
    'epsilon',
    'épuçais',
    'épuçait',
    'épuçant',
    'épucées',
    'épucent',
    'épucera',
    'épuciez',
    'épuçons',
    'épuisai',
    'épuisas',
    'épuisât',
    'épuisée',
    'épuiser',
    'épuises',
    'épuisés',
    'épuisez',
    'épulide',
    'épulies',
    'épulons',
    'épurais',
    'épurait',
    'épurant',
    'épurées',
    'épurent',
    'épurera',
    'épurges',
    'épuriez',
    'épurons',
    'équarri',
    'équerra',
    'équerre',
    'équerré',
    'équeuta',
    'équeute',
    'équeuté',
    'équidés',
    'équille',
    'équines',
    'équipai',
    'équipas',
    'équipât',
    'équipée',
    'équiper',
    'équipes',
    'équipés',
    'équipez',
    'équités',
    'érables',
    'éraflai',
    'éraflas',
    'éraflât',
    'éraflée',
    'érafler',
    'érafles',
    'éraflés',
    'éraflez',
    'éraient',
    'éraiera',
    'érailla',
    'éraille',
    'éraillé',
    'érayais',
    'érayait',
    'érayant',
    'érayées',
    'érayent',
    'érayera',
    'érayiez',
    'érayons',
    'erbines',
    'erbiums',
    'éreinta',
    'éreinte',
    'éreinté',
    'ergotai',
    'ergotas',
    'ergotât',
    'ergoter',
    'ergotes',
    'ergotez',
    'érigeai',
    'érigeas',
    'érigeât',
    'érigées',
    'érigent',
    'érigera',
    'érigiez',
    'érignes',
    'ermites',
    'érodais',
    'érodait',
    'érodant',
    'érodées',
    'érodent',
    'érodera',
    'érodiez',
    'érodons',
    'érogène',
    'érosifs',
    'érosion',
    'érosive',
    'érotisa',
    'érotise',
    'érotisé',
    'errâmes',
    'errance',
    'errante',
    'errants',
    'errasse',
    'errâtes',
    'erratum',
    'errerai',
    'erreras',
    'errerez',
    'erreurs',
    'errions',
    'erronée',
    'erronés',
    'erseaux',
    'éructai',
    'éructas',
    'éructât',
    'éructée',
    'éructer',
    'éructes',
    'éructés',
    'éructez',
    'érudite',
    'érudits',
    'éruptif',
    'esbaudi',
    'esbigné',
    'escadre',
    'escales',
    'escapes',
    'escarpé',
    'escarre',
    'eschais',
    'eschait',
    'eschant',
    'eschées',
    'eschent',
    'eschera',
    'eschiez',
    'eschons',
    'escient',
    'esclave',
    'escobar',
    'escorta',
    'escorte',
    'escorté',
    'escrime',
    'escrimé',
    'escrocs',
    'escudos',
    'ésérine',
    'espaçai',
    'espaças',
    'espaçât',
    'espacée',
    'espacer',
    'espaces',
    'espacés',
    'espacez',
    'espadas',
    'espadon',
    'espèces',
    'espérai',
    'espéras',
    'espérât',
    'espérée',
    'espérer',
    'espérés',
    'espères',
    'espérez',
    'espions',
    'espoirs',
    'esprits',
    'esquifs',
    'esquire',
    'esquiva',
    'esquive',
    'esquivé',
    'essaies',
    'essaima',
    'essaime',
    'essaimé',
    'essaims',
    'essange',
    'essangé',
    'essarta',
    'essarte',
    'essarté',
    'essarts',
    'essayai',
    'essayas',
    'essayât',
    'essayée',
    'essayer',
    'essayes',
    'essayés',
    'essayez',
    'essence',
    'esseulé',
    'essieux',
    'essorai',
    'essoras',
    'essorât',
    'essorée',
    'essorer',
    'essores',
    'essorés',
    'essorez',
    'essuies',
    'essuyai',
    'essuyas',
    'essuyât',
    'essuyée',
    'essuyer',
    'essuyés',
    'essuyez',
    'estampa',
    'estampe',
    'estampé',
    'estarie',
    'esthète',
    'estimai',
    'estimas',
    'estimât',
    'estimée',
    'estimer',
    'estimes',
    'estimés',
    'estimez',
    'estivai',
    'estival',
    'estivas',
    'estivât',
    'estivée',
    'estiver',
    'estives',
    'estivés',
    'estivez',
    'estomac',
    'estompa',
    'estompe',
    'estompé',
    'estoqua',
    'estoque',
    'estoqué',
    'estrade',
    'estrans',
    'estrope',
    'établai',
    'établas',
    'établât',
    'établée',
    'établer',
    'étables',
    'établés',
    'établez',
    'établie',
    'établir',
    'établis',
    'établit',
    'établît',
    'étageai',
    'étageas',
    'étageât',
    'étagées',
    'étagent',
    'étagera',
    'étagère',
    'étagiez',
    'étaient',
    'étaiera',
    'étalage',
    'étalagé',
    'étalais',
    'étalait',
    'étalant',
    'étalées',
    'étalent',
    'étalera',
    'étalier',
    'étaliez',
    'étalisa',
    'étalise',
    'étalisé',
    'étalons',
    'étamage',
    'étamais',
    'étamait',
    'étamant',
    'étambot',
    'étamées',
    'étament',
    'étamera',
    'étameur',
    'étamiez',
    'étamine',
    'étamons',
    'étampai',
    'étampas',
    'étampât',
    'étampée',
    'étamper',
    'étampes',
    'étampés',
    'étampez',
    'étamure',
    'étancha',
    'étanche',
    'étanché',
    'étançon',
    'étarqua',
    'étarque',
    'étarqué',
    'étayage',
    'étayais',
    'étayait',
    'étayant',
    'étayées',
    'étayent',
    'étayera',
    'étayiez',
    'étayons',
    'éteigne',
    'éteinte',
    'éteints',
    'étendes',
    'étendez',
    'étendis',
    'étendit',
    'étendît',
    'étendra',
    'étendre',
    'étendue',
    'étendus',
    'éternel',
    'éternua',
    'éternue',
    'éternué',
    'étésien',
    'étêtage',
    'étêtais',
    'étêtait',
    'étêtant',
    'étêtées',
    'étêtent',
    'étêtera',
    'étêtiez',
    'étêtons',
    'éteules',
    'éthanes',
    'éthérée',
    'éthérés',
    'éthique',
    'ethnies',
    'éthuses',
    'éthyles',
    'étiages',
    'étiolai',
    'étiolas',
    'étiolât',
    'étiolée',
    'étioler',
    'étioles',
    'étiolés',
    'étiolez',
    'étiques',
    'étirage',
    'étirais',
    'étirait',
    'étirant',
    'étirées',
    'étirent',
    'étirera',
    'étireur',
    'étiriez',
    'étirons',
    'étisies',
    'étoffai',
    'étoffas',
    'étoffât',
    'étoffée',
    'étoffer',
    'étoffes',
    'étoffés',
    'étoffez',
    'étoilai',
    'étoilas',
    'étoilât',
    'étoilée',
    'étoiler',
    'étoiles',
    'étoilés',
    'étoilez',
    'étonnai',
    'étonnas',
    'étonnât',
    'étonnée',
    'étonner',
    'étonnes',
    'étonnés',
    'étonnez',
    'étouffa',
    'étouffe',
    'étouffé',
    'étoupai',
    'étoupas',
    'étoupât',
    'étoupée',
    'étouper',
    'étoupes',
    'étoupés',
    'étoupez',
    'étourdi',
    'étrange',
    'étraves',
    'étrécie',
    'étrécir',
    'étrécis',
    'étrécit',
    'étrécît',
    'étreins',
    'étreint',
    'étrenna',
    'étrenne',
    'étrenné',
    'étriers',
    'étrilla',
    'étrille',
    'étrillé',
    'étripai',
    'étripas',
    'étripât',
    'étripée',
    'étriper',
    'étripes',
    'étripés',
    'étripez',
    'étriqua',
    'étrique',
    'étriqué',
    'étrives',
    'étroite',
    'étroits',
    'étudiai',
    'étudias',
    'étudiât',
    'étudiée',
    'étudier',
    'étudies',
    'étudiés',
    'étudiez',
    'étuvage',
    'étuvais',
    'étuvait',
    'étuvant',
    'étuvées',
    'étuvent',
    'étuvera',
    'étuveur',
    'étuviez',
    'étuvons',
    'étymons',
    'eudémis',
    'eudiste',
    'euglène',
    'eunecte',
    'eunuque',
    'eussent',
    'eussiez',
    'eutexie',
    'évacuai',
    'évacuas',
    'évacuât',
    'évacuée',
    'évacuer',
    'évacues',
    'évacués',
    'évacuez',
    'évadées',
    'évadiez',
    'évadons',
    'évaluai',
    'évaluas',
    'évaluât',
    'évaluée',
    'évaluer',
    'évalues',
    'évalués',
    'évaluez',
    'évanoui',
    'évapora',
    'évapore',
    'évaporé',
    'évasais',
    'évasait',
    'évasant',
    'évasées',
    'évasent',
    'évasera',
    'évasiez',
    'évasifs',
    'évasion',
    'évasive',
    'évasons',
    'évasure',
    'évêchés',
    'éveilla',
    'éveille',
    'éveillé',
    'éventai',
    'éventas',
    'éventât',
    'éventée',
    'éventer',
    'éventes',
    'éventés',
    'éventez',
    'éventra',
    'éventre',
    'éventré',
    'évêques',
    'évidage',
    'évidais',
    'évidait',
    'évidant',
    'évidées',
    'évident',
    'évidera',
    'évidiez',
    'évidons',
    'évinçai',
    'évinças',
    'évinçât',
    'évincée',
    'évincer',
    'évinces',
    'évincés',
    'évincez',
    'évitage',
    'évitais',
    'évitait',
    'évitant',
    'évitées',
    'évitent',
    'évitera',
    'évitiez',
    'évitons',
    'évoluai',
    'évoluas',
    'évoluât',
    'évoluée',
    'évoluer',
    'évolues',
    'évolués',
    'évoluez',
    'évoquai',
    'évoquas',
    'évoquât',
    'évoquée',
    'évoquer',
    'évoques',
    'évoqués',
    'évoquez',
    'evzones',
    'exactes',
    'exagéra',
    'exagéré',
    'exagère',
    'exaltai',
    'exaltas',
    'exaltât',
    'exaltée',
    'exalter',
    'exaltes',
    'exaltés',
    'exaltez',
    'examens',
    'examina',
    'examine',
    'examiné',
    'exarque',
    'exauçai',
    'exauças',
    'exauçât',
    'exaucée',
    'exaucer',
    'exauces',
    'exaucés',
    'exaucez',
    'excavai',
    'excavas',
    'excavât',
    'excavée',
    'excaver',
    'excaves',
    'excavés',
    'excavez',
    'excédai',
    'excédas',
    'excédât',
    'excédée',
    'excéder',
    'excédés',
    'excèdes',
    'excédez',
    'excella',
    'excelle',
    'excellé',
    'excepta',
    'excepte',
    'excepté',
    'excipai',
    'excipas',
    'excipât',
    'excipée',
    'exciper',
    'excipes',
    'excipés',
    'excipez',
    'excisai',
    'excisas',
    'excisât',
    'excisée',
    'exciser',
    'excises',
    'excisés',
    'excisez',
    'excitai',
    'excitas',
    'excitât',
    'excitée',
    'exciter',
    'excites',
    'excités',
    'excitez',
    'exclamé',
    'exclues',
    'excluez',
    'exclura',
    'exclure',
    'excoria',
    'excorie',
    'excorié',
    'excréta',
    'excrété',
    'excrète',
    'excusai',
    'excusas',
    'excusât',
    'excusée',
    'excuser',
    'excuses',
    'excusés',
    'excusez',
    'exécrai',
    'exécras',
    'exécrât',
    'exécrée',
    'exécrer',
    'exécrés',
    'exècres',
    'exécrez',
    'exécuta',
    'exécute',
    'exécuté',
    'exèdres',
    'exégèse',
    'exégète',
    'exemple',
    'exempta',
    'exempte',
    'exempté',
    'exempts',
    'exerçai',
    'exerças',
    'exerçât',
    'exercée',
    'exercer',
    'exerces',
    'exercés',
    'exercez',
    'exergue',
    'exfolia',
    'exfolie',
    'exfolié',
    'exhalai',
    'exhalas',
    'exhalât',
    'exhalée',
    'exhaler',
    'exhales',
    'exhalés',
    'exhalez',
    'exhaure',
    'exhibai',
    'exhibas',
    'exhibât',
    'exhibée',
    'exhiber',
    'exhibes',
    'exhibés',
    'exhibez',
    'exhorta',
    'exhorte',
    'exhorté',
    'exhumai',
    'exhumas',
    'exhumât',
    'exhumée',
    'exhumer',
    'exhumes',
    'exhumés',
    'exhumez',
    'exigeai',
    'exigeas',
    'exigeât',
    'exigées',
    'exigent',
    'exigera',
    'exigiez',
    'exiguës',
    'exilais',
    'exilait',
    'exilant',
    'exilées',
    'exilent',
    'exilera',
    'exiliez',
    'exilons',
    'existai',
    'existas',
    'existât',
    'exister',
    'existes',
    'existez',
    'exocets',
    'exogame',
    'exogène',
    'exondée',
    'exonder',
    'exondés',
    'exondez',
    'exonéra',
    'exonéré',
    'exonère',
    'exordes',
    'expansé',
    'expédia',
    'expédie',
    'expédié',
    'experte',
    'experts',
    'expiais',
    'expiait',
    'expiant',
    'expiées',
    'expient',
    'expiera',
    'expiiez',
    'expions',
    'expirai',
    'expiras',
    'expirât',
    'expirée',
    'expirer',
    'expires',
    'expirés',
    'expirez',
    'exploit',
    'explora',
    'explore',
    'exploré',
    'explosa',
    'explose',
    'explosé',
    'exporta',
    'exporte',
    'exporté',
    'exposai',
    'exposas',
    'exposât',
    'exposée',
    'exposer',
    'exposes',
    'exposés',
    'exposez',
    'express',
    'exprima',
    'exprime',
    'exprimé',
    'expulsa',
    'expulse',
    'expulsé',
    'expurge',
    'expurgé',
    'exquise',
    'exsudai',
    'exsudas',
    'exsudat',
    'exsudât',
    'exsudée',
    'exsuder',
    'exsudes',
    'exsudés',
    'exsudez',
    'extases',
    'extasié',
    'exténua',
    'exténue',
    'exténué',
    'externe',
    'extirpa',
    'extirpe',
    'extirpé',
    'extrada',
    'extrade',
    'extradé',
    'extraie',
    'extrais',
    'extrait',
    'extrême',
    'extruda',
    'extrude',
    'extrudé',
    'exultai',
    'exultas',
    'exultât',
    'exulter',
    'exultes',
    'exultez',
    'ex-voto',
    'fabliau',
    'fablier',
    'fabulai',
    'fabulas',
    'fabulât',
    'fabuler',
    'fabules',
    'fabulez',
    'façades',
    'facétie',
    'facetta',
    'facette',
    'facetté',
    'fâchais',
    'fâchait',
    'fâchant',
    'fâchées',
    'fâchent',
    'fâchera',
    'fâcheux',
    'fâchiez',
    'fâchons',
    'faciale',
    'faciaux',
    'faciles',
    'faconde',
    'façonna',
    'façonne',
    'façonné',
    'factage',
    'facteur',
    'factice',
    'faction',
    'factuel',
    'factums',
    'factura',
    'facture',
    'facturé',
    'faculté',
    'fadaise',
    'fadâmes',
    'fadasse',
    'fadâtes',
    'faderai',
    'faderas',
    'faderez',
    'fadeurs',
    'fadings',
    'fadions',
    'fafiots',
    'fagotai',
    'fagotas',
    'fagotât',
    'fagotée',
    'fagoter',
    'fagotes',
    'fagotés',
    'fagotez',
    'fagotin',
    'faibles',
    'faiblir',
    'faiblis',
    'faiblit',
    'faiblît',
    'faïence',
    'faïencé',
    'faillai',
    'faillas',
    'faillât',
    'faillée',
    'failler',
    'failles',
    'faillés',
    'faillez',
    'faillir',
    'faillis',
    'faillit',
    'faillît',
    'faisais',
    'faisait',
    'faisans',
    'faisant',
    'faiseur',
    'faisiez',
    'faisons',
    'faîtage',
    'faîteau',
    'faitout',
    'falaise',
    'falbala',
    'falerne',
    'fallait',
    'falloir',
    'falotes',
    'faluche',
    'falzars',
    'fameuse',
    'famille',
    'famines',
    'fanages',
    'fanâmes',
    'fanasse',
    'fanâtes',
    'fanerai',
    'faneras',
    'fanerez',
    'faneurs',
    'faneuse',
    'fanfare',
    'fangeux',
    'fanions',
    'fantôme',
    'fanzine',
    'faquins',
    'faraday',
    'faraude',
    'farauds',
    'farceur',
    'farcies',
    'farcins',
    'farcira',
    'fardage',
    'fardais',
    'fardait',
    'fardant',
    'fardeau',
    'fardées',
    'fardent',
    'fardera',
    'fardier',
    'fardiez',
    'fardons',
    'farfelu',
    'fargues',
    'farinai',
    'farinas',
    'farinât',
    'farinée',
    'fariner',
    'farines',
    'farinés',
    'farinez',
    'farrago',
    'fartage',
    'fartais',
    'fartait',
    'fartant',
    'fartées',
    'fartent',
    'fartera',
    'fartiez',
    'fartons',
    'fascées',
    'fascias',
    'fasciée',
    'fasciés',
    'fascina',
    'fascine',
    'fasciné',
    'fascisa',
    'fascise',
    'fascisé',
    'fassent',
    'fassiez',
    'fatales',
    'fatigua',
    'fatigue',
    'fatigué',
    'fatuité',
    'faubers',
    'faubert',
    'faucard',
    'fauchai',
    'fauchas',
    'fauchât',
    'fauchée',
    'faucher',
    'fauches',
    'fauchés',
    'fauchet',
    'fauchez',
    'fauchon',
    'faucons',
    'faucres',
    'faudrai',
    'faudras',
    'faudrez',
    'faufila',
    'faufile',
    'faufilé',
    'faufils',
    'faussai',
    'faussas',
    'faussât',
    'faussée',
    'fausser',
    'fausses',
    'faussés',
    'fausset',
    'faussez',
    'fautais',
    'fautait',
    'fautant',
    'fautent',
    'fautera',
    'fauteur',
    'fautiez',
    'fautifs',
    'fautive',
    'fautons',
    'favelas',
    'faveurs',
    'favoris',
    'faxâmes',
    'faxasse',
    'faxâtes',
    'faxerai',
    'faxeras',
    'faxerez',
    'faxions',
    'fayards',
    'fayotai',
    'fayotas',
    'fayotât',
    'fayoter',
    'fayotes',
    'fayotez',
    'fayotta',
    'fayotte',
    'fayotté',
    'fazenda',
    'fébrile',
    'fécales',
    'féciaux',
    'féconda',
    'féconde',
    'fécondé',
    'féconds',
    'féculai',
    'féculas',
    'féculât',
    'féculée',
    'féculer',
    'fécules',
    'féculés',
    'féculez',
    'fedayin',
    'fédérai',
    'fédéral',
    'fédéras',
    'fédérât',
    'fédérée',
    'fédérer',
    'fédérés',
    'fédères',
    'fédérez',
    'féeries',
    'feignes',
    'feignez',
    'feignis',
    'feignit',
    'feignît',
    'feindra',
    'feindre',
    'feintai',
    'feintas',
    'feintât',
    'feintée',
    'feinter',
    'feintes',
    'feintés',
    'feintez',
    'fêlâmes',
    'fêlasse',
    'fêlâtes',
    'fêlerai',
    'fêleras',
    'fêlerez',
    'félibre',
    'félidés',
    'félines',
    'fêlions',
    'fellaga',
    'fellahs',
    'félonie',
    'félonne',
    'fêlures',
    'femelle',
    'féminin',
    'fémoral',
    'fendage',
    'fendais',
    'fendait',
    'fendant',
    'fendard',
    'fendart',
    'fendent',
    'fendeur',
    'fendiez',
    'fendoir',
    'fendons',
    'fendrai',
    'fendras',
    'fendrez',
    'fendues',
    'fenêtra',
    'fenêtre',
    'fenêtré',
    'fennecs',
    'fenouil',
    'fentons',
    'féodale',
    'féodaux',
    'fériale',
    'fériaux',
    'fériées',
    'ferions',
    'ferlais',
    'ferlait',
    'ferlant',
    'ferlées',
    'ferlent',
    'ferlera',
    'ferliez',
    'ferlons',
    'fermage',
    'fermail',
    'fermais',
    'fermait',
    'fermant',
    'fermaux',
    'fermées',
    'ferment',
    'fermera',
    'fermeté',
    'fermier',
    'fermiez',
    'fermium',
    'fermoir',
    'fermons',
    'féroces',
    'ferrade',
    'ferrage',
    'ferrais',
    'ferrait',
    'ferrant',
    'ferrate',
    'ferrées',
    'ferrent',
    'ferrera',
    'ferrets',
    'ferreur',
    'ferreux',
    'ferries',
    'ferriez',
    'ferrite',
    'ferrons',
    'ferrure',
    'fertile',
    'férules',
    'fervent',
    'ferveur',
    'fessais',
    'fessait',
    'fessant',
    'fessées',
    'fessent',
    'fessera',
    'fessier',
    'fessiez',
    'fessons',
    'fessues',
    'festifs',
    'festins',
    'festive',
    'festoie',
    'festons',
    'festoya',
    'festoyé',
    'fêtâmes',
    'fêtards',
    'fêtasse',
    'fêtâtes',
    'fêterai',
    'fêteras',
    'fêterez',
    'fétiche',
    'fétides',
    'fêtions',
    'fétuque',
    'feuilla',
    'feuille',
    'feuillé',
    'feuillu',
    'feulais',
    'feulait',
    'feulant',
    'feulent',
    'feulera',
    'feuliez',
    'feulons',
    'feutrai',
    'feutras',
    'feutrât',
    'feutrée',
    'feutrer',
    'feutres',
    'feutrés',
    'feutrez',
    'féviers',
    'février',
    'fiables',
    'fiacres',
    'fiaient',
    'fiançai',
    'fianças',
    'fiançât',
    'fiancée',
    'fiancer',
    'fiances',
    'fiancés',
    'fiancez',
    'fiascos',
    'fiasque',
    'fiasses',
    'fibreux',
    'fibrine',
    'fibrome',
    'fibrose',
    'fibules',
    'ficaire',
    'ficelai',
    'ficelas',
    'ficelât',
    'ficelée',
    'ficeler',
    'ficelés',
    'ficelez',
    'ficelle',
    'fichage',
    'fichais',
    'fichait',
    'fichant',
    'fichées',
    'fichent',
    'fichera',
    'fichets',
    'fichier',
    'fichiez',
    'fichoir',
    'fichons',
    'fichtre',
    'fichues',
    'fictifs',
    'fiction',
    'fictive',
    'fidèles',
    'fieffai',
    'fieffas',
    'fieffât',
    'fieffée',
    'fieffer',
    'fieffes',
    'fieffés',
    'fieffez',
    'fientai',
    'fientas',
    'fientât',
    'fienter',
    'fientes',
    'fientez',
    'fierais',
    'fierait',
    'fièrent',
    'fieriez',
    'fierons',
    'fieront',
    'fiérote',
    'fiérots',
    'fiertés',
    'fiestas',
    'fièvres',
    'fifille',
    'figaros',
    'figeais',
    'figeait',
    'figeant',
    'figeons',
    'figerai',
    'figeras',
    'figerez',
    'figions',
    'fignola',
    'fignole',
    'fignolé',
    'figuier',
    'figurai',
    'figuras',
    'figurât',
    'figurée',
    'figurer',
    'figures',
    'figurés',
    'figurez',
    'filable',
    'filages',
    'filaire',
    'filâmes',
    'filante',
    'filants',
    'filasse',
    'filâtes',
    'filerai',
    'fileras',
    'filerez',
    'filetai',
    'filetas',
    'filetât',
    'filetée',
    'fileter',
    'filetés',
    'filètes',
    'filetez',
    'fileurs',
    'fileuse',
    'filiale',
    'filiaux',
    'filière',
    'filions',
    'filleul',
    'filmage',
    'filmais',
    'filmait',
    'filmant',
    'filmées',
    'filment',
    'filmera',
    'filmiez',
    'filmons',
    'filocha',
    'filoche',
    'filoché',
    'filouta',
    'filoute',
    'filouté',
    'filtrai',
    'filtras',
    'filtrat',
    'filtrât',
    'filtrée',
    'filtrer',
    'filtres',
    'filtrés',
    'filtrez',
    'finages',
    'finales',
    'finança',
    'finance',
    'financé',
    'finassa',
    'finasse',
    'finassé',
    'finaude',
    'finauds',
    'finerie',
    'finesse',
    'finette',
    'finîmes',
    'finirai',
    'finiras',
    'finirez',
    'finisse',
    'finîtes',
    'finnois',
    'firmans',
    'fiscale',
    'fiscaux',
    'fissent',
    'fissiez',
    'fissile',
    'fission',
    'fissura',
    'fissure',
    'fissuré',
    'fistons',
    'fistots',
    'fistule',
    'fixages',
    'fixâmes',
    'fixante',
    'fixants',
    'fixasse',
    'fixâtes',
    'fixatif',
    'fixerai',
    'fixeras',
    'fixerez',
    'fixings',
    'fixions',
    'fixisme',
    'fixiste',
    'fixités',
    'flaches',
    'flacons',
    'fla-fla',
    'flagada',
    'flairai',
    'flairas',
    'flairât',
    'flairée',
    'flairer',
    'flaires',
    'flairés',
    'flairez',
    'flamand',
    'flamant',
    'flambai',
    'flambas',
    'flambât',
    'flambée',
    'flamber',
    'flambes',
    'flambés',
    'flambez',
    'flamine',
    'flammée',
    'flammes',
    'flammés',
    'flânais',
    'flânait',
    'flânant',
    'flancha',
    'flanche',
    'flanché',
    'flânent',
    'flânera',
    'flâneur',
    'flâniez',
    'flânons',
    'flanqua',
    'flanque',
    'flanqué',
    'flapies',
    'flaquai',
    'flaquas',
    'flaquât',
    'flaquer',
    'flaques',
    'flaquez',
    'flashai',
    'flashas',
    'flashât',
    'flasher',
    'flashes',
    'flashez',
    'flasque',
    'flattai',
    'flattas',
    'flattât',
    'flattée',
    'flatter',
    'flattes',
    'flattés',
    'flattez',
    'flaupai',
    'flaupas',
    'flaupât',
    'flaupée',
    'flauper',
    'flaupes',
    'flaupés',
    'flaupez',
    'flavine',
    'fléchai',
    'fléchas',
    'fléchât',
    'fléchée',
    'flécher',
    'fléchés',
    'flèches',
    'fléchez',
    'fléchie',
    'fléchir',
    'fléchis',
    'fléchit',
    'fléchît',
    'flegmes',
    'flegmon',
    'flémard',
    'flemmes',
    'fléoles',
    'flétans',
    'flétrie',
    'flétrir',
    'flétris',
    'flétrit',
    'flétrît',
    'flettes',
    'fleurai',
    'fleuras',
    'fleurât',
    'fleurée',
    'fleurer',
    'fleures',
    'fleurés',
    'fleuret',
    'fleurez',
    'fleurie',
    'fleurir',
    'fleuris',
    'fleurit',
    'fleurît',
    'fleuron',
    'fleuves',
    'flexion',
    'flexure',
    'flingot',
    'flingua',
    'flingue',
    'flingué',
    'flipots',
    'flippai',
    'flippas',
    'flippât',
    'flipper',
    'flippes',
    'flippez',
    'flirtai',
    'flirtas',
    'flirtât',
    'flirter',
    'flirtes',
    'flirtez',
    'flocage',
    'floches',
    'flocons',
    'flocula',
    'flocule',
    'floculé',
    'flopées',
    'florale',
    'floraux',
    'floréal',
    'florins',
    'flottai',
    'flottas',
    'flottât',
    'flottée',
    'flotter',
    'flottes',
    'flottés',
    'flottez',
    'flouais',
    'flouait',
    'flouant',
    'flouées',
    'flouent',
    'flouera',
    'flouiez',
    'flouons',
    'flousai',
    'flousas',
    'flousât',
    'flouser',
    'flouses',
    'flousez',
    'flouves',
    'flouzes',
    'fluages',
    'fluâmes',
    'fluasse',
    'fluates',
    'fluâtes',
    'fluctua',
    'fluctue',
    'fluctué',
    'fluerai',
    'flueras',
    'fluerez',
    'fluette',
    'fluides',
    'fluions',
    'flushes',
    'flûtais',
    'flûtait',
    'flûtant',
    'flûteau',
    'flûtées',
    'flûtent',
    'flûtera',
    'flûtiez',
    'flûtons',
    'fluvial',
    'fluxais',
    'fluxait',
    'fluxant',
    'fluxées',
    'fluxent',
    'fluxera',
    'fluxiez',
    'fluxion',
    'fluxons',
    'focales',
    'foetale',
    'foetaux',
    'foirade',
    'foirail',
    'foirais',
    'foirait',
    'foirant',
    'foirent',
    'foirera',
    'foireux',
    'foiriez',
    'foirons',
    'folasse',
    'folâtra',
    'folâtre',
    'folâtré',
    'foliacé',
    'foliées',
    'foliole',
    'foliota',
    'foliote',
    'folioté',
    'foliots',
    'follets',
    'fomenta',
    'fomente',
    'fomenté',
    'fonçais',
    'fonçait',
    'fonçant',
    'foncées',
    'foncent',
    'foncera',
    'fonceur',
    'foncier',
    'fonciez',
    'fonçons',
    'fondais',
    'fondait',
    'fondant',
    'fondées',
    'fondent',
    'fondera',
    'fondeur',
    'fondiez',
    'fondoir',
    'fondons',
    'fondouk',
    'fondrai',
    'fondras',
    'fondrez',
    'fondues',
    'footing',
    'forages',
    'foraine',
    'forains',
    'forâmes',
    'forasse',
    'forâtes',
    'forbans',
    'forçage',
    'forçais',
    'forçait',
    'forçant',
    'forçats',
    'forcées',
    'forcené',
    'forcent',
    'forceps',
    'forcera',
    'forciez',
    'forcing',
    'forcira',
    'forclos',
    'forçons',
    'forerai',
    'foreras',
    'forerez',
    'foreurs',
    'foreuse',
    'forfais',
    'forfait',
    'forfera',
    'forfont',
    'forgeai',
    'forgeas',
    'forgeât',
    'forgées',
    'forgent',
    'forgera',
    'forgeur',
    'forgiez',
    'forints',
    'forions',
    'forjeta',
    'forjeté',
    'forlane',
    'formage',
    'formais',
    'formait',
    'formant',
    'formats',
    'formées',
    'formels',
    'formène',
    'forment',
    'formera',
    'formiez',
    'formola',
    'formole',
    'formolé',
    'formols',
    'formons',
    'formula',
    'formule',
    'formulé',
    'fortins',
    'fortran',
    'fortuit',
    'fortune',
    'fortuné',
    'forures',
    'fossile',
    'fossoie',
    'fossoir',
    'fossoya',
    'fossoyé',
    'fouaces',
    'fouages',
    'foucade',
    'foudres',
    'fouetta',
    'fouette',
    'fouetté',
    'foufous',
    'fougeai',
    'fougeas',
    'fougeât',
    'fougent',
    'fougera',
    'fougère',
    'fougiez',
    'fougues',
    'fouilla',
    'fouille',
    'fouillé',
    'fouîmes',
    'fouinai',
    'fouinas',
    'fouinât',
    'fouiner',
    'fouines',
    'fouinez',
    'fouirai',
    'fouiras',
    'fouirez',
    'fouisse',
    'fouîtes',
    'foulage',
    'foulais',
    'foulait',
    'foulant',
    'foulard',
    'foulées',
    'foulent',
    'foulera',
    'fouleur',
    'fouliez',
    'fouloir',
    'foulons',
    'foulque',
    'foulure',
    'fourbai',
    'fourbas',
    'fourbât',
    'fourbée',
    'fourber',
    'fourbes',
    'fourbés',
    'fourbez',
    'fourbie',
    'fourbir',
    'fourbis',
    'fourbit',
    'fourbît',
    'fourbue',
    'fourbus',
    'fourcha',
    'fourche',
    'fourché',
    'fourchu',
    'fourgon',
    'fourgua',
    'fourgue',
    'fourgué',
    'fourmes',
    'fourmis',
    'fournée',
    'fournie',
    'fournil',
    'fournir',
    'fournis',
    'fournit',
    'fournît',
    'fourrai',
    'fourras',
    'fourrât',
    'fourrée',
    'fourrer',
    'fourres',
    'fourrés',
    'fourrez',
    'foutais',
    'foutait',
    'foutant',
    'foutent',
    'foutiez',
    'foutoir',
    'foutons',
    'foutrai',
    'foutral',
    'foutras',
    'foutrez',
    'foutues',
    'fragile',
    'fragons',
    'fraîche',
    'fraîchi',
    'fraient',
    'fraiera',
    'frairie',
    'fraisai',
    'fraisas',
    'fraisât',
    'fraisée',
    'fraiser',
    'fraises',
    'fraisés',
    'fraisez',
    'fraisil',
    'framées',
    'franche',
    'franchi',
    'frangea',
    'frangée',
    'franger',
    'franges',
    'frangés',
    'frangez',
    'frangin',
    'frappai',
    'frappas',
    'frappât',
    'frappée',
    'frapper',
    'frappes',
    'frappés',
    'frappez',
    'frasils',
    'frasque',
    'fraters',
    'fratrie',
    'fraudai',
    'fraudas',
    'fraudât',
    'fraudée',
    'frauder',
    'fraudes',
    'fraudés',
    'fraudez',
    'frayage',
    'frayais',
    'frayait',
    'frayant',
    'frayées',
    'frayent',
    'frayera',
    'frayère',
    'frayeur',
    'frayiez',
    'frayons',
    'freesia',
    'freezer',
    'frégata',
    'frégate',
    'frégaté',
    'freinai',
    'freinas',
    'freinât',
    'freinée',
    'freiner',
    'freines',
    'freinés',
    'freinez',
    'freinte',
    'frelata',
    'frelate',
    'frelaté',
    'frelons',
    'frémira',
    'frênaie',
    'frérots',
    'fresque',
    'frétais',
    'frétait',
    'frétant',
    'frétées',
    'frètent',
    'frétera',
    'fréteur',
    'frétiez',
    'fretins',
    'frétons',
    'frettai',
    'frettas',
    'frettât',
    'frettée',
    'fretter',
    'frettes',
    'frettés',
    'frettez',
    'friable',
    'friande',
    'friands',
    'friches',
    'frichti',
    'fricota',
    'fricote',
    'fricoté',
    'fricots',
    'frigide',
    'frileux',
    'frimais',
    'frimait',
    'frimant',
    'frimées',
    'friment',
    'frimera',
    'frimeur',
    'frimiez',
    'frimons',
    'fringua',
    'fringue',
    'fringué',
    'fripais',
    'fripait',
    'fripant',
    'fripées',
    'fripent',
    'fripera',
    'fripier',
    'fripiez',
    'fripons',
    'friquée',
    'friqués',
    'friquet',
    'frirais',
    'frirait',
    'frirent',
    'fririez',
    'frirons',
    'friront',
    'frisage',
    'frisais',
    'frisait',
    'frisant',
    'frisées',
    'frisent',
    'frisera',
    'frisiez',
    'frisons',
    'frisses',
    'frisson',
    'frisure',
    'frittai',
    'frittas',
    'frittât',
    'frittée',
    'fritter',
    'frittes',
    'frittés',
    'frittez',
    'friture',
    'frivole',
    'frocard',
    'froides',
    'froidir',
    'froidis',
    'froidit',
    'froidît',
    'froissa',
    'froisse',
    'froissé',
    'frôlais',
    'frôlait',
    'frôlant',
    'frôlées',
    'frôlent',
    'frôlera',
    'frôleur',
    'frôliez',
    'frôlons',
    'fromage',
    'froment',
    'fronçai',
    'fronças',
    'fronçât',
    'froncée',
    'froncer',
    'fronces',
    'froncés',
    'froncez',
    'froncis',
    'frondai',
    'frondas',
    'frondât',
    'frondée',
    'fronder',
    'frondes',
    'frondés',
    'frondez',
    'frontal',
    'fronton',
    'frottai',
    'frottas',
    'frottât',
    'frottée',
    'frotter',
    'frottes',
    'frottés',
    'frottez',
    'frottis',
    'frouais',
    'frouait',
    'frouant',
    'frouent',
    'frouera',
    'frouiez',
    'frouons',
    'frousse',
    'frugale',
    'frugaux',
    'fruitée',
    'fruités',
    'frusqua',
    'frusque',
    'frusqué',
    'frustes',
    'frustra',
    'frustre',
    'frustré',
    'fuchsia',
    'fuégien',
    'fugaces',
    'fugitif',
    'fuguais',
    'fuguait',
    'fuguant',
    'fuguent',
    'fuguera',
    'fugueur',
    'fuguiez',
    'fuguons',
    'führers',
    'fuirais',
    'fuirait',
    'fuirent',
    'fuiriez',
    'fuirons',
    'fuiront',
    'fuisses',
    'fulgura',
    'fulgure',
    'fulguré',
    'fulmina',
    'fulmine',
    'fulminé',
    'fumable',
    'fumages',
    'fumâmes',
    'fumante',
    'fumants',
    'fumasse',
    'fumâtes',
    'fumerai',
    'fumeras',
    'fumerez',
    'fumerie',
    'fumeron',
    'fumeurs',
    'fumeuse',
    'fumiers',
    'fumigea',
    'fumigée',
    'fumiger',
    'fumiges',
    'fumigés',
    'fumigez',
    'fumions',
    'fumiste',
    'fumoirs',
    'fumures',
    'funèbre',
    'funeste',
    'furetai',
    'furetas',
    'furetât',
    'fureter',
    'furètes',
    'furetez',
    'fureurs',
    'furieux',
    'furioso',
    'furoles',
    'furtifs',
    'furtive',
    'fusains',
    'fusâmes',
    'fusasse',
    'fusâtes',
    'fuscine',
    'fuseaux',
    'fuselai',
    'fuselas',
    'fuselât',
    'fuselée',
    'fuseler',
    'fuselés',
    'fuselez',
    'fuselle',
    'fuserai',
    'fuseras',
    'fuserez',
    'fusette',
    'fusible',
    'fusilla',
    'fusille',
    'fusillé',
    'fusions',
    'fussent',
    'fussiez',
    'fustige',
    'fustigé',
    'futaies',
    'futaine',
    'futiles',
    'futures',
    'fuyante',
    'fuyants',
    'fuyarde',
    'fuyards',
    'fuyions',
    'gabares',
    'gabarit',
    'gabarre',
    'gabbros',
    'gabegie',
    'gabelle',
    'gabelou',
    'gabiers',
    'gabions',
    'gâchage',
    'gâchais',
    'gâchait',
    'gâchant',
    'gâchées',
    'gâchent',
    'gâchera',
    'gâcheur',
    'gâchiez',
    'gâchons',
    'gadgets',
    'gadoues',
    'gaffais',
    'gaffait',
    'gaffant',
    'gaffées',
    'gaffent',
    'gaffera',
    'gaffeur',
    'gaffiez',
    'gaffons',
    'gageais',
    'gageait',
    'gageant',
    'gageons',
    'gagerai',
    'gageras',
    'gagerez',
    'gageure',
    'gagions',
    'gagiste',
    'gagnage',
    'gagnais',
    'gagnait',
    'gagnant',
    'gagnées',
    'gagnent',
    'gagnera',
    'gagneur',
    'gagniez',
    'gagnons',
    'gaïacol',
    'gaietés',
    'gaillet',
    'gaîment',
    'gainage',
    'gainais',
    'gainait',
    'gainant',
    'gainées',
    'gainent',
    'gainera',
    'gainier',
    'gainiez',
    'gainons',
    'galante',
    'galants',
    'galaxie',
    'galbais',
    'galbait',
    'galbant',
    'galbées',
    'galbent',
    'galbera',
    'galbiez',
    'galbons',
    'galéace',
    'galéjai',
    'galéjas',
    'galéjât',
    'galéjer',
    'galèjes',
    'galéjez',
    'galènes',
    'galères',
    'galerie',
    'galerne',
    'galetas',
    'galette',
    'galeuse',
    'galgals',
    'galibot',
    'galions',
    'galiote',
    'galipot',
    'gallecs',
    'gallium',
    'gallois',
    'gallons',
    'gallups',
    'galoche',
    'galonna',
    'galonne',
    'galonné',
    'galopai',
    'galopas',
    'galopât',
    'galopée',
    'galoper',
    'galopes',
    'galopés',
    'galopez',
    'galopin',
    'galures',
    'galurin',
    'galvano',
    'gambada',
    'gambade',
    'gambadé',
    'gambits',
    'gamelle',
    'gamètes',
    'gaminai',
    'gaminas',
    'gaminât',
    'gaminer',
    'gamines',
    'gaminez',
    'gammare',
    'gammées',
    'ganache',
    'gandins',
    'gangues',
    'ganoïde',
    'gansais',
    'gansait',
    'gansant',
    'gansées',
    'gansent',
    'gansera',
    'gansiez',
    'gansons',
    'gantais',
    'gantait',
    'gantant',
    'gantées',
    'gantelé',
    'gantent',
    'gantera',
    'gantier',
    'gantiez',
    'gantons',
    'garages',
    'garâmes',
    'garança',
    'garance',
    'garancé',
    'garante',
    'garanti',
    'garants',
    'garasse',
    'garâtes',
    'garbure',
    'garçons',
    'gardais',
    'gardait',
    'gardant',
    'gardées',
    'gardent',
    'gardera',
    'gardeur',
    'gardian',
    'gardien',
    'gardiez',
    'gardons',
    'garenne',
    'garerai',
    'gareras',
    'garerez',
    'gargota',
    'gargote',
    'gargoté',
    'garions',
    'garnies',
    'garnira',
    'garrots',
    'gascons',
    'gas-oil',
    'gasoils',
    'gâtâmes',
    'gâtasse',
    'gâtâtes',
    'gâteaux',
    'gâterai',
    'gâteras',
    'gâterez',
    'gâterie',
    'gâteuse',
    'gâtifia',
    'gâtifie',
    'gâtifié',
    'gâtines',
    'gâtions',
    'gâtisme',
    'gaucher',
    'gauches',
    'gauchie',
    'gauchir',
    'gauchis',
    'gauchit',
    'gauchît',
    'gauchos',
    'gaufrai',
    'gaufras',
    'gaufrât',
    'gaufrée',
    'gaufrer',
    'gaufres',
    'gaufrés',
    'gaufrez',
    'gaulage',
    'gaulais',
    'gaulait',
    'gaulant',
    'gaulées',
    'gaulent',
    'gaulera',
    'gauliez',
    'gaulois',
    'gaulons',
    'gaussai',
    'gaussas',
    'gaussât',
    'gaussée',
    'gausser',
    'gausses',
    'gaussés',
    'gaussez',
    'gavache',
    'gavages',
    'gavâmes',
    'gavasse',
    'gavâtes',
    'gaverai',
    'gaveras',
    'gaverez',
    'gaveurs',
    'gaveuse',
    'gavials',
    'gavions',
    'gavotte',
    'gazages',
    'gazâmes',
    'gazante',
    'gazants',
    'gazasse',
    'gazâtes',
    'gazelle',
    'gazerai',
    'gazeras',
    'gazerez',
    'gazette',
    'gazeuse',
    'gazière',
    'gaziers',
    'gazions',
    'gazoduc',
    'gazoles',
    'gazonna',
    'gazonne',
    'gazonné',
    'géantes',
    'géhenne',
    'geignes',
    'geignez',
    'geignis',
    'geignit',
    'geignît',
    'geindra',
    'geindre',
    'geintes',
    'geishas',
    'gelâmes',
    'gelasse',
    'gelâtes',
    'gèlerai',
    'gèleras',
    'gèlerez',
    'gélifia',
    'gélifie',
    'gélifié',
    'gelions',
    'gélives',
    'géloses',
    'gélules',
    'gelures',
    'gémeaux',
    'gémelle',
    'gémîmes',
    'géminai',
    'géminas',
    'géminât',
    'géminée',
    'géminer',
    'gémines',
    'géminés',
    'géminez',
    'gémirai',
    'gémiras',
    'gémirez',
    'gémisse',
    'gémîtes',
    'gemmage',
    'gemmail',
    'gemmais',
    'gemmait',
    'gemmant',
    'gemmaux',
    'gemmées',
    'gemment',
    'gemmera',
    'gemmeur',
    'gemmiez',
    'gemmons',
    'gemmule',
    'gênâmes',
    'gênante',
    'gênants',
    'gênasse',
    'gênâtes',
    'gencive',
    'gendres',
    'genépis',
    'génépis',
    'générai',
    'gênerai',
    'général',
    'généras',
    'gêneras',
    'générât',
    'générée',
    'générer',
    'générés',
    'génères',
    'générez',
    'gênerez',
    'genèses',
    'genette',
    'gêneurs',
    'gêneuse',
    'géniale',
    'géniaux',
    'gênions',
    'génisse',
    'génital',
    'génitif',
    'génoise',
    'génomes',
    'gentils',
    'géoïdes',
    'geôlier',
    'gérâmes',
    'gérance',
    'gérante',
    'gérants',
    'gérasse',
    'gérâtes',
    'gerbage',
    'gerbais',
    'gerbait',
    'gerbant',
    'gerbées',
    'gerbent',
    'gerbera',
    'gerbeur',
    'gerbier',
    'gerbiez',
    'gerbons',
    'gerçais',
    'gerçait',
    'gerçant',
    'gercées',
    'gercent',
    'gercera',
    'gerciez',
    'gerçons',
    'gerçure',
    'gérerai',
    'géreras',
    'gérerez',
    'gerfaut',
    'gérions',
    'germain',
    'germais',
    'germait',
    'germant',
    'germées',
    'germens',
    'germent',
    'germera',
    'germiez',
    'germoir',
    'germons',
    'géromés',
    'géronte',
    'gerseau',
    'gésiers',
    'gésines',
    'gestion',
    'gestuel',
    'geysers',
    'ghettos',
    'ghildes',
    'giaours',
    'gibbeux',
    'gibbons',
    'gibelin',
    'giberne',
    'gibiers',
    'giboies',
    'giboyai',
    'giboyas',
    'giboyât',
    'giboyée',
    'giboyer',
    'giboyés',
    'giboyez',
    'giclais',
    'giclait',
    'giclant',
    'giclées',
    'giclent',
    'giclera',
    'gicleur',
    'gicliez',
    'giclons',
    'giflais',
    'giflait',
    'giflant',
    'giflées',
    'giflent',
    'giflera',
    'gifliez',
    'giflons',
    'gigogne',
    'gigolos',
    'gigotai',
    'gigotas',
    'gigotât',
    'gigoter',
    'gigotes',
    'gigotez',
    'gindres',
    'ginguet',
    'ginkgos',
    'ginseng',
    'girafes',
    'girafon',
    'girasol',
    'girelle',
    'girofle',
    'girolle',
    'gironde',
    'gironds',
    'gironna',
    'gironne',
    'gironné',
    'gisions',
    'gîtâmes',
    'gitanes',
    'gîtasse',
    'gîtâtes',
    'gîterai',
    'gîteras',
    'gîterez',
    'gîtions',
    'givrage',
    'givrais',
    'givrait',
    'givrant',
    'givrées',
    'givrent',
    'givrera',
    'givreux',
    'givriez',
    'givrons',
    'givrure',
    'glabres',
    'glaçage',
    'glaçais',
    'glaçait',
    'glaçant',
    'glacées',
    'glacent',
    'glacera',
    'glaceur',
    'glaceux',
    'glacial',
    'glacier',
    'glaciez',
    'glaçons',
    'glaçure',
    'glaïeul',
    'glairai',
    'glairas',
    'glairât',
    'glairée',
    'glairer',
    'glaires',
    'glairés',
    'glairez',
    'glaisai',
    'glaisas',
    'glaisât',
    'glaisée',
    'glaiser',
    'glaises',
    'glaisés',
    'glaisez',
    'glaives',
    'glanage',
    'glanais',
    'glanait',
    'glanant',
    'glandai',
    'glandas',
    'glandât',
    'glandée',
    'glander',
    'glandes',
    'glandés',
    'glandez',
    'glanées',
    'glanent',
    'glanera',
    'glaneur',
    'glaniez',
    'glanons',
    'glanure',
    'glapies',
    'glapira',
    'glatira',
    'glauque',
    'glaviot',
    'glécome',
    'gliomes',
    'glissai',
    'glissas',
    'glissât',
    'glissée',
    'glisser',
    'glisses',
    'glissés',
    'glissez',
    'globale',
    'globaux',
    'globine',
    'globule',
    'gloires',
    'glosais',
    'glosait',
    'glosant',
    'glosées',
    'glosent',
    'glosera',
    'glosiez',
    'glosons',
    'glottal',
    'glottes',
    'gloussa',
    'glousse',
    'gloussé',
    'glouton',
    'gluante',
    'gluants',
    'glucide',
    'glucose',
    'glutens',
    'glycine',
    'glycols',
    'glyphes',
    'gnocchi',
    'gnomons',
    'gnosies',
    'gobâmes',
    'gobasse',
    'gobâtes',
    'gobelet',
    'gobelin',
    'goberai',
    'goberas',
    'goberez',
    'goberge',
    'gobergé',
    'gobetai',
    'gobetas',
    'gobetât',
    'gobetée',
    'gobeter',
    'gobetés',
    'gobetez',
    'gobette',
    'gobeurs',
    'gobeuse',
    'gobions',
    'godages',
    'godâmes',
    'godasse',
    'godâtes',
    'goderai',
    'goderas',
    'goderez',
    'godiche',
    'godilla',
    'godille',
    'godillé',
    'godions',
    'godrons',
    'goéland',
    'goémons',
    'goinfra',
    'goinfre',
    'goinfré',
    'goitres',
    'goldens',
    'golfeur',
    'goménol',
    'gominai',
    'gominas',
    'gominât',
    'gominée',
    'gominer',
    'gomines',
    'gominés',
    'gominez',
    'gommage',
    'gommais',
    'gommait',
    'gommant',
    'gommées',
    'gomment',
    'gommera',
    'gommeux',
    'gommier',
    'gommiez',
    'gommons',
    'gonades',
    'gondais',
    'gondait',
    'gondant',
    'gondées',
    'gondent',
    'gondera',
    'gondiez',
    'gondola',
    'gondole',
    'gondolé',
    'gondons',
    'gonelle',
    'gonflai',
    'gonflas',
    'gonflât',
    'gonflée',
    'gonfler',
    'gonfles',
    'gonflés',
    'gonflez',
    'gordien',
    'gorfous',
    'gorgeai',
    'gorgeas',
    'gorgeât',
    'gorgées',
    'gorgent',
    'gorgera',
    'gorgets',
    'gorgiez',
    'gorgone',
    'gorille',
    'gosette',
    'gosiers',
    'gospels',
    'gotique',
    'gouacha',
    'gouache',
    'gouaché',
    'gouapes',
    'goudron',
    'gouffre',
    'gougère',
    'gouines',
    'goujats',
    'goujons',
    'goulags',
    'goulées',
    'goulets',
    'goulots',
    'goulues',
    'goupils',
    'gourais',
    'gourait',
    'gourant',
    'gourbis',
    'gourdes',
    'gourdin',
    'gourées',
    'gourent',
    'gourera',
    'gouriez',
    'gourmée',
    'gourmes',
    'gourmés',
    'gourmet',
    'gourons',
    'gourous',
    'gousses',
    'gousset',
    'goûtais',
    'goûtait',
    'goûtant',
    'goûtées',
    'goûtent',
    'goûtera',
    'goûters',
    'goûteur',
    'goûteux',
    'goûtiez',
    'goûtons',
    'gouttai',
    'gouttas',
    'gouttât',
    'goutter',
    'gouttes',
    'gouttez',
    'goyaves',
    'grabats',
    'grabens',
    'grabuge',
    'graciai',
    'gracias',
    'graciât',
    'graciée',
    'gracier',
    'gracies',
    'graciés',
    'graciez',
    'gracile',
    'gradées',
    'graders',
    'gradins',
    'graduai',
    'graduas',
    'graduât',
    'graduée',
    'graduel',
    'graduer',
    'gradues',
    'gradués',
    'graduez',
    'grailla',
    'graille',
    'graillé',
    'grainai',
    'grainas',
    'grainât',
    'grainée',
    'grainer',
    'graines',
    'grainés',
    'grainez',
    'graissa',
    'graisse',
    'graissé',
    'gramens',
    'grammes',
    'grandes',
    'grandet',
    'grandie',
    'grandir',
    'grandis',
    'grandit',
    'grandît',
    'granges',
    'granita',
    'granite',
    'granité',
    'granits',
    'granula',
    'granule',
    'granulé',
    'graphes',
    'graphie',
    'grappes',
    'grappin',
    'grasses',
    'grasset',
    'gratina',
    'gratine',
    'gratiné',
    'gratins',
    'grattai',
    'grattas',
    'grattât',
    'grattée',
    'gratter',
    'grattes',
    'grattés',
    'grattez',
    'gratuit',
    'gravais',
    'gravait',
    'gravant',
    'gravats',
    'gravées',
    'gravent',
    'gravera',
    'graveur',
    'gravide',
    'gravier',
    'gravies',
    'graviez',
    'gravira',
    'gravita',
    'gravite',
    'gravité',
    'gravois',
    'gravons',
    'gravure',
    'gréâmes',
    'gréasse',
    'gréâtes',
    'grébige',
    'grécisa',
    'grécise',
    'grécisé',
    'grécité',
    'grecqua',
    'grecque',
    'grecqué',
    'gredine',
    'gredins',
    'gréerai',
    'greffai',
    'greffas',
    'greffât',
    'greffée',
    'greffer',
    'greffes',
    'greffés',
    'greffez',
    'greffon',
    'grègues',
    'gréions',
    'grêlait',
    'grêlera',
    'grelins',
    'grêlons',
    'grelots',
    'grenade',
    'grenage',
    'grenais',
    'grenait',
    'grenant',
    'grenats',
    'grenées',
    'grenela',
    'grenelé',
    'grènent',
    'grènera',
    'greneur',
    'grenier',
    'greniez',
    'grenons',
    'grenues',
    'grenure',
    'grésage',
    'grésais',
    'grésait',
    'grésant',
    'grésées',
    'grèsent',
    'grésera',
    'gréseux',
    'grésiez',
    'grésils',
    'grésoir',
    'grésons',
    'gressin',
    'grevais',
    'grevait',
    'grevant',
    'grevées',
    'grèvent',
    'grèvera',
    'greviez',
    'grevons',
    'grièche',
    'griffai',
    'griffas',
    'griffât',
    'griffée',
    'griffer',
    'griffes',
    'griffés',
    'griffez',
    'griffon',
    'griffue',
    'griffus',
    'grignai',
    'grignas',
    'grignât',
    'grigner',
    'grignes',
    'grignez',
    'grignon',
    'grigous',
    'gri-gri',
    'grigris',
    'grillai',
    'grillas',
    'grillât',
    'grillée',
    'griller',
    'grilles',
    'grillés',
    'grillez',
    'grillon',
    'grimaça',
    'grimace',
    'grimacé',
    'grimage',
    'grimais',
    'grimait',
    'grimant',
    'grimaud',
    'grimées',
    'griment',
    'grimera',
    'grimiez',
    'grimons',
    'grimpai',
    'grimpas',
    'grimpât',
    'grimpée',
    'grimper',
    'grimpes',
    'grimpés',
    'grimpez',
    'grinçai',
    'grinças',
    'grinçât',
    'grincer',
    'grinces',
    'grincez',
    'grincha',
    'grinche',
    'grinché',
    'gringes',
    'gringos',
    'gringua',
    'gringue',
    'gringué',
    'griotte',
    'grippai',
    'grippal',
    'grippas',
    'grippât',
    'grippée',
    'gripper',
    'grippes',
    'grippés',
    'grippez',
    'grisais',
    'grisait',
    'grisant',
    'grisard',
    'grisbis',
    'grisées',
    'grisent',
    'grisera',
    'grisets',
    'grisiez',
    'grisola',
    'grisole',
    'grisolé',
    'grisons',
    'grisous',
    'grivela',
    'grivelé',
    'grivois',
    'grizzli',
    'grizzly',
    'grognai',
    'grognas',
    'grognât',
    'grogner',
    'grognes',
    'grognez',
    'grognon',
    'groisil',
    'grolles',
    'grondai',
    'grondas',
    'grondât',
    'grondée',
    'gronder',
    'grondes',
    'grondés',
    'grondez',
    'grondin',
    'grosses',
    'grossie',
    'grossir',
    'grossis',
    'grossit',
    'grossît',
    'grottes',
    'groulla',
    'groupai',
    'groupas',
    'groupât',
    'groupée',
    'grouper',
    'groupes',
    'groupés',
    'groupez',
    'groupie',
    'grouses',
    'grugeai',
    'grugeas',
    'grugeât',
    'grugées',
    'grugent',
    'grugera',
    'grugiez',
    'grumeau',
    'grumela',
    'grumelé',
    'grutier',
    'gruyère',
    'gryphée',
    'guanaco',
    'guarani',
    'guéable',
    'guéâmes',
    'guéasse',
    'guéâtes',
    'guèbres',
    'guéerai',
    'guéeras',
    'guéerez',
    'guéions',
    'guelfes',
    'gueltes',
    'guenons',
    'guépard',
    'guêpier',
    'guérets',
    'guéries',
    'guérira',
    'guérite',
    'guerres',
    'guêtrai',
    'guêtras',
    'guêtrât',
    'guêtrée',
    'guêtrer',
    'guêtres',
    'guêtrés',
    'guêtrez',
    'guêtron',
    'guettai',
    'guettas',
    'guettât',
    'guettée',
    'guetter',
    'guettes',
    'guettés',
    'guettez',
    'gueulai',
    'gueulas',
    'gueulât',
    'gueulée',
    'gueuler',
    'gueules',
    'gueulés',
    'gueulez',
    'gueusai',
    'gueusas',
    'gueusât',
    'gueusée',
    'gueuser',
    'gueuses',
    'gueusés',
    'gueusez',
    'guibole',
    'guibres',
    'guiches',
    'guichet',
    'guidage',
    'guidais',
    'guidait',
    'guidant',
    'guideau',
    'guidées',
    'guident',
    'guidera',
    'guidiez',
    'guidons',
    'guignai',
    'guignas',
    'guignât',
    'guignée',
    'guigner',
    'guignes',
    'guignés',
    'guignez',
    'guignol',
    'guignon',
    'guildes',
    'guimpes',
    'guincha',
    'guinche',
    'guinché',
    'guindai',
    'guindas',
    'guindât',
    'guindée',
    'guinder',
    'guindes',
    'guindés',
    'guindez',
    'guinées',
    'guipage',
    'guipais',
    'guipait',
    'guipant',
    'guipées',
    'guipent',
    'guipera',
    'guipiez',
    'guipoir',
    'guipons',
    'guipure',
    'guitare',
    'guivrée',
    'guivrés',
    'guldens',
    'gunites',
    'gymnase',
    'gymnote',
    'gypaète',
    'gypseux',
    'habiles',
    'habilla',
    'habille',
    'habillé',
    'habitai',
    'habitas',
    'habitat',
    'habitât',
    'habitée',
    'habiter',
    'habites',
    'habités',
    'habitez',
    'habitua',
    'habitue',
    'habitué',
    'habitus',
    'hablais',
    'hablait',
    'hablant',
    'hablées',
    'hablent',
    'hablera',
    'hâbleur',
    'habliez',
    'hablons',
    'hachage',
    'hachais',
    'hachait',
    'hachant',
    'hachées',
    'hachent',
    'hachera',
    'hacheur',
    'hachiez',
    'hachoir',
    'hachons',
    'hachura',
    'hachure',
    'hachuré',
    'haddock',
    'hadiths',
    'hafnium',
    'hagarde',
    'hagards',
    'haïkaïs',
    'haillon',
    'haineux',
    'haïrais',
    'haïrait',
    'haïrent',
    'haïriez',
    'haïrons',
    'haïront',
    'haïsses',
    'haïssez',
    'haïtien',
    'halages',
    'halâmes',
    'hâlâmes',
    'halasse',
    'hâlasse',
    'halâtes',
    'hâlâtes',
    'halbran',
    'haleine',
    'halerai',
    'hâlerai',
    'haleras',
    'hâleras',
    'halerez',
    'hâlerez',
    'haletai',
    'haletas',
    'haletât',
    'haletée',
    'haleter',
    'haletés',
    'halètes',
    'haletez',
    'haleurs',
    'haleuse',
    'halicte',
    'halions',
    'hâlions',
    'haliple',
    'hallali',
    'hallier',
    'hâloirs',
    'haltère',
    'hamadas',
    'hameaux',
    'hameçon',
    'hammams',
    'hamster',
    'hanchai',
    'hanchas',
    'hanchât',
    'hanchée',
    'hancher',
    'hanches',
    'hanchés',
    'hanchez',
    'hangars',
    'hansart',
    'hantais',
    'hantait',
    'hantant',
    'hantées',
    'hantent',
    'hantera',
    'hantiez',
    'hantise',
    'hantons',
    'happais',
    'happait',
    'happant',
    'happées',
    'happent',
    'happera',
    'happiez',
    'happons',
    'haquets',
    'harassa',
    'harasse',
    'harassé',
    'harcela',
    'harcelé',
    'harcèle',
    'hardais',
    'hardait',
    'hardant',
    'hardées',
    'hardent',
    'hardera',
    'hardies',
    'hardiez',
    'hardons',
    'harengs',
    'harfang',
    'hargnes',
    'haricot',
    'harissa',
    'harnais',
    'harnois',
    'harpail',
    'harpais',
    'harpait',
    'harpant',
    'harpées',
    'harpent',
    'harpera',
    'harpies',
    'harpiez',
    'harpons',
    'hasarda',
    'hasarde',
    'hasardé',
    'hasards',
    'hastées',
    'hâtâmes',
    'hâtasse',
    'hâtâtes',
    'hâtelet',
    'hâterai',
    'hâteras',
    'hâterez',
    'hâtiers',
    'hâtions',
    'hâtives',
    'haubana',
    'haubane',
    'haubané',
    'haubans',
    'haubert',
    'haussai',
    'haussas',
    'haussât',
    'haussée',
    'hausser',
    'hausses',
    'haussés',
    'haussez',
    'hautain',
    'hauteur',
    'hautins',
    'havages',
    'havâmes',
    'havanes',
    'havasse',
    'havâtes',
    'havenet',
    'haverai',
    'haveras',
    'haverez',
    'haveurs',
    'haveuse',
    'havîmes',
    'havions',
    'havirai',
    'haviras',
    'havirez',
    'havisse',
    'havîtes',
    'hawaïen',
    'heaumes',
    'héberge',
    'hébergé',
    'hébétai',
    'hébétas',
    'hébétât',
    'hébétée',
    'hébéter',
    'hébétés',
    'hébètes',
    'hébétez',
    'hébreux',
    'hectare',
    'hégires',
    'hélâmes',
    'hélasse',
    'hélâtes',
    'hélerai',
    'héleras',
    'hélerez',
    'hélices',
    'hélicon',
    'hélions',
    'héliums',
    'hellène',
    'hélodée',
    'helvète',
    'hématie',
    'hémines',
    'hémione',
    'hennies',
    'hennins',
    'hennira',
    'hérauts',
    'herbacé',
    'herbage',
    'herbagé',
    'herbais',
    'herbait',
    'herbant',
    'herbées',
    'herbent',
    'herbera',
    'herbeux',
    'herbier',
    'herbiez',
    'herbons',
    'herbues',
    'hercule',
    'hérésie',
    'hérissa',
    'hérisse',
    'hérissé',
    'héritai',
    'héritas',
    'héritât',
    'héritée',
    'hériter',
    'hérites',
    'hérités',
    'héritez',
    'hermine',
    'herniée',
    'hernies',
    'herniés',
    'héroïne',
    'hersage',
    'hersais',
    'hersait',
    'hersant',
    'hersées',
    'hersent',
    'hersera',
    'herseur',
    'hersiez',
    'hersons',
    'hésitai',
    'hésitas',
    'hésitât',
    'hésiter',
    'hésites',
    'hésitez',
    'hétaïre',
    'hetmans',
    'hêtraie',
    'heureux',
    'heurtai',
    'heurtas',
    'heurtât',
    'heurtée',
    'heurter',
    'heurtes',
    'heurtés',
    'heurtez',
    'hexoses',
    'hiatale',
    'hiataux',
    'hiberna',
    'hiberne',
    'hiberné',
    'hickory',
    'hidalgo',
    'hideurs',
    'hideuse',
    'hièbles',
    'hiémale',
    'hiémaux',
    'hilaire',
    'hilares',
    'hindoue',
    'hindous',
    'hippies',
    'hircine',
    'hircins',
    'hirsute',
    'hispide',
    'hissais',
    'hissait',
    'hissant',
    'hissées',
    'hissent',
    'hissera',
    'hissiez',
    'hissons',
    'hittite',
    'hiverna',
    'hiverne',
    'hiverné',
    'hobbies',
    'hochais',
    'hochait',
    'hochant',
    'hochées',
    'hochent',
    'hochera',
    'hochets',
    'hochiez',
    'hochons',
    'hockeys',
    'hoiries',
    'holding',
    'hold-up',
    'holmium',
    'homards',
    'homélie',
    'hommage',
    'hongrai',
    'hongras',
    'hongrât',
    'hongrée',
    'hongrer',
    'hongres',
    'hongrés',
    'hongrez',
    'honnête',
    'honneur',
    'honnies',
    'honnira',
    'honorai',
    'honoras',
    'honorât',
    'honorée',
    'honorer',
    'honores',
    'honorés',
    'honorez',
    'honteux',
    'hôpital',
    'hoplite',
    'hoqueta',
    'hoqueté',
    'hoquets',
    'horaire',
    'horions',
    'horizon',
    'horloge',
    'hormona',
    'hormone',
    'hormoné',
    'horreur',
    'hosanna',
    'hospice',
    'hosties',
    'hostile',
    'hot-dog',
    'hôtesse',
    'hottées',
    'houache',
    'houâmes',
    'houasse',
    'houâtes',
    'houblon',
    'houdans',
    'houerai',
    'houeras',
    'houerez',
    'houille',
    'houions',
    'houleux',
    'houlque',
    'houppai',
    'houppas',
    'houppât',
    'houppée',
    'houpper',
    'houppes',
    'houppés',
    'houppez',
    'houques',
    'hourdai',
    'hourdas',
    'hourdât',
    'hourdée',
    'hourder',
    'hourdes',
    'hourdés',
    'hourdez',
    'hourdie',
    'hourdir',
    'hourdis',
    'hourdit',
    'hourdît',
    'hourque',
    'hourras',
    'houseau',
    'houssai',
    'houssas',
    'houssât',
    'houssée',
    'housser',
    'housses',
    'houssés',
    'houssez',
    'huaient',
    'huasses',
    'hublots',
    'huchais',
    'huchait',
    'huchant',
    'huchées',
    'huchent',
    'huchera',
    'huchets',
    'huchiez',
    'huchons',
    'huerais',
    'huerait',
    'huèrent',
    'hueriez',
    'huerons',
    'hueront',
    'huertas',
    'huilage',
    'huilais',
    'huilait',
    'huilant',
    'huilées',
    'huilent',
    'huilera',
    'huileux',
    'huilier',
    'huiliez',
    'huilons',
    'huitain',
    'huîtres',
    'hulotte',
    'hululai',
    'hululas',
    'hululât',
    'hululée',
    'hululer',
    'hulules',
    'hululés',
    'hululez',
    'humages',
    'humaine',
    'humains',
    'humâmes',
    'humasse',
    'humâtes',
    'humbles',
    'humecta',
    'humecte',
    'humecté',
    'humerai',
    'huméral',
    'humeras',
    'humerez',
    'humérus',
    'humeurs',
    'humides',
    'humilia',
    'humilie',
    'humilié',
    'humions',
    'humoral',
    'humours',
    'huniers',
    'huppées',
    'hurdler',
    'hurlais',
    'hurlait',
    'hurlant',
    'hurlées',
    'hurlent',
    'hurlera',
    'hurleur',
    'hurliez',
    'hurlons',
    'huronne',
    'hurrahs',
    'hussard',
    'hussite',
    'hutinet',
    'hyaline',
    'hyalins',
    'hyalite',
    'hybrida',
    'hybride',
    'hybridé',
    'hydrata',
    'hydrate',
    'hydraté',
    'hydries',
    'hydrure',
    'hygiène',
    'hygroma',
    'hyménée',
    'hyoïdes',
    'hypéron',
    'hypnose',
    'hypogée',
    'hypogés',
    'hysopes',
    'ibéride',
    'icaques',
    'icarien',
    'iceberg',
    'ichtyol',
    'ici-bas',
    'icoglan',
    'ictères',
    'idéales',
    'idéelle',
    'idiomes',
    'idiotes',
    'idiotie',
    'idoines',
    'idylles',
    'ignames',
    'ignares',
    'ignoble',
    'ignorai',
    'ignoras',
    'ignorât',
    'ignorée',
    'ignorer',
    'ignores',
    'ignorés',
    'ignorez',
    'iguanes',
    'iléales',
    'iléites',
    'iliaque',
    'îlienne',
    'illégal',
    'îlotage',
    'îlotier',
    'imageai',
    'imageas',
    'imageât',
    'imagées',
    'imagent',
    'imagera',
    'imagier',
    'imagiez',
    'imagina',
    'imagine',
    'imaginé',
    'imamats',
    'imanats',
    'imberbe',
    'imbibai',
    'imbibas',
    'imbibât',
    'imbibée',
    'imbiber',
    'imbibes',
    'imbibés',
    'imbibez',
    'imitais',
    'imitait',
    'imitant',
    'imitées',
    'imitent',
    'imitera',
    'imitiez',
    'imitons',
    'immense',
    'immerge',
    'immergé',
    'immigra',
    'immigre',
    'immigré',
    'immiscé',
    'immolai',
    'immolas',
    'immolât',
    'immolée',
    'immoler',
    'immoles',
    'immolés',
    'immolez',
    'immonde',
    'immoral',
    'immunes',
    'impacta',
    'impacte',
    'impacté',
    'impacts',
    'impaire',
    'impairs',
    'impalas',
    'imparti',
    'impasse',
    'impayée',
    'impayés',
    'impétra',
    'impétré',
    'impètre',
    'impiété',
    'implant',
    'implexe',
    'implora',
    'implore',
    'imploré',
    'implosa',
    'implose',
    'implosé',
    'impolie',
    'impolis',
    'importa',
    'importe',
    'importé',
    'imposai',
    'imposas',
    'imposât',
    'imposée',
    'imposer',
    'imposes',
    'imposés',
    'imposez',
    'imposte',
    'imprévu',
    'imprima',
    'imprime',
    'imprimé',
    'impulsa',
    'impulse',
    'impulsé',
    'impunie',
    'impunis',
    'impures',
    'imputai',
    'imputas',
    'imputât',
    'imputée',
    'imputer',
    'imputes',
    'imputés',
    'imputez',
    'inactif',
    'inanimé',
    'inanité',
    'inaptes',
    'inavoué',
    'incarna',
    'incarne',
    'incarné',
    'inceste',
    'incipit',
    'incisai',
    'incisas',
    'incisât',
    'incisée',
    'inciser',
    'incises',
    'incisés',
    'incisez',
    'incisif',
    'incitai',
    'incitas',
    'incitât',
    'incitée',
    'inciter',
    'incites',
    'incités',
    'incitez',
    'incivil',
    'inclina',
    'incline',
    'incliné',
    'inclues',
    'incluez',
    'inclura',
    'inclure',
    'incluse',
    'incomba',
    'incombe',
    'incombé',
    'inconnu',
    'incréée',
    'incréés',
    'incubai',
    'incubas',
    'incubât',
    'incubée',
    'incuber',
    'incubes',
    'incubés',
    'incubez',
    'incuits',
    'inculpa',
    'inculpe',
    'inculpé',
    'inculte',
    'incurie',
    'incurva',
    'incurve',
    'incurvé',
    'incuses',
    'indécis',
    'indemne',
    'indexai',
    'indexas',
    'indexât',
    'indexée',
    'indexer',
    'indexes',
    'indexés',
    'indexez',
    'indiçai',
    'indican',
    'indiças',
    'indiçât',
    'indicée',
    'indicer',
    'indices',
    'indicés',
    'indicez',
    'indiens',
    'indigna',
    'indigne',
    'indigné',
    'indigos',
    'indiqua',
    'indique',
    'indiqué',
    'indiums',
    'indivis',
    'indoles',
    'induira',
    'induire',
    'induise',
    'induite',
    'induits',
    'indults',
    'indurai',
    'induras',
    'indurât',
    'indurée',
    'indurer',
    'indures',
    'indurés',
    'indurez',
    'indusie',
    'induvie',
    'inédite',
    'inédits',
    'inégale',
    'inégalé',
    'inégaux',
    'ineptes',
    'ineptie',
    'inermes',
    'inertes',
    'inertie',
    'inexact',
    'inexpié',
    'infâmes',
    'infamie',
    'infante',
    'infants',
    'infatua',
    'infatue',
    'infatué',
    'infecta',
    'infecte',
    'infecté',
    'infects',
    'inféoda',
    'inféode',
    'inféodé',
    'inférai',
    'inféras',
    'inférât',
    'inférée',
    'inférer',
    'inférés',
    'infères',
    'inférez',
    'infesta',
    'infeste',
    'infesté',
    'infimes',
    'infinie',
    'infinis',
    'infirma',
    'infirme',
    'infirmé',
    'infixes',
    'inflige',
    'infligé',
    'influai',
    'influas',
    'influât',
    'influer',
    'influes',
    'influez',
    'infondé',
    'informa',
    'informe',
    'informé',
    'infules',
    'infusai',
    'infusas',
    'infusât',
    'infusée',
    'infuser',
    'infuses',
    'infusés',
    'infusez',
    'ingambe',
    'ingénié',
    'ingénue',
    'ingénus',
    'ingérai',
    'ingéras',
    'ingérât',
    'ingérée',
    'ingérer',
    'ingérés',
    'ingères',
    'ingérez',
    'ingrate',
    'ingrats',
    'inhalai',
    'inhalas',
    'inhalât',
    'inhalée',
    'inhaler',
    'inhales',
    'inhalés',
    'inhalez',
    'inhibai',
    'inhibas',
    'inhibât',
    'inhibée',
    'inhiber',
    'inhibes',
    'inhibés',
    'inhibez',
    'inhumai',
    'inhumas',
    'inhumât',
    'inhumée',
    'inhumer',
    'inhumes',
    'inhumés',
    'inhumez',
    'inimité',
    'iniques',
    'initiai',
    'initial',
    'initias',
    'initiât',
    'initiée',
    'initier',
    'inities',
    'initiés',
    'initiez',
    'injecta',
    'injecte',
    'injecté',
    'injures',
    'injuria',
    'injurie',
    'injurié',
    'injuste',
    'innéité',
    'innerva',
    'innerve',
    'innervé',
    'innomée',
    'innomés',
    'innommé',
    'innovai',
    'innovas',
    'innovât',
    'innovée',
    'innover',
    'innoves',
    'innovés',
    'innovez',
    'inocula',
    'inocule',
    'inoculé',
    'inodore',
    'inondai',
    'inondas',
    'inondât',
    'inondée',
    'inonder',
    'inondes',
    'inondés',
    'inondez',
    'inopiné',
    'inouïes',
    'inquart',
    'inquiet',
    'insanes',
    'inscris',
    'inscrit',
    'insecte',
    'insensé',
    'insérai',
    'inséras',
    'insérât',
    'insérée',
    'insérer',
    'insérés',
    'insères',
    'insérez',
    'insigne',
    'insinua',
    'insinue',
    'insinué',
    'insista',
    'insiste',
    'insisté',
    'insolai',
    'insolas',
    'insolât',
    'insolée',
    'insoler',
    'insoles',
    'insolés',
    'insolez',
    'inspira',
    'inspire',
    'inspiré',
    'instant',
    'insulta',
    'insulte',
    'insulté',
    'insurgé',
    'intacte',
    'intacts',
    'intégra',
    'intégré',
    'intègre',
    'intense',
    'intenta',
    'intente',
    'intenté',
    'intérêt',
    'intérim',
    'interna',
    'interne',
    'interné',
    'intimai',
    'intimas',
    'intimât',
    'intimée',
    'intimer',
    'intimes',
    'intimés',
    'intimez',
    'introït',
    'intruse',
    'intubai',
    'intubas',
    'intubât',
    'intubée',
    'intuber',
    'intubes',
    'intubés',
    'intubez',
    'inuline',
    'inusité',
    'inusuel',
    'inutile',
    'invendu',
    'inventa',
    'invente',
    'inventé',
    'inversa',
    'inverse',
    'inversé',
    'inverti',
    'investi',
    'inviolé',
    'invitai',
    'invitas',
    'invitât',
    'invitée',
    'inviter',
    'invites',
    'invités',
    'invitez',
    'invoqua',
    'invoque',
    'invoqué',
    'iodâmes',
    'iodasse',
    'iodates',
    'iodâtes',
    'ioderai',
    'ioderas',
    'ioderez',
    'iodions',
    'iodique',
    'iodisme',
    'iodlais',
    'iodlait',
    'iodlant',
    'iodlées',
    'iodlent',
    'iodlera',
    'iodliez',
    'iodlons',
    'iodurée',
    'iodures',
    'iodurés',
    'ioniens',
    'ionique',
    'ionisai',
    'ionisas',
    'ionisât',
    'ionisée',
    'ioniser',
    'ionises',
    'ionisés',
    'ionisez',
    'ionones',
    'ioulais',
    'ioulait',
    'ioulant',
    'ioulées',
    'ioulent',
    'ioulera',
    'iouliez',
    'ioulons',
    'iourtes',
    'ipomées',
    'iraient',
    'irakien',
    'iranien',
    'iraqien',
    'iridiée',
    'iridien',
    'iridiés',
    'iridium',
    'irisais',
    'irisait',
    'irisant',
    'irisées',
    'irisent',
    'irisera',
    'irisiez',
    'irisons',
    'ironies',
    'ironisa',
    'ironise',
    'ironisé',
    'irradia',
    'irradie',
    'irradié',
    'irréels',
    'irrigua',
    'irrigue',
    'irrigué',
    'irritai',
    'irritas',
    'irritât',
    'irritée',
    'irriter',
    'irrites',
    'irrités',
    'irritez',
    'ischion',
    'isiaque',
    'isobare',
    'isocèle',
    'isogame',
    'isogone',
    'isolais',
    'isolait',
    'isolant',
    'isolats',
    'isolées',
    'isolent',
    'isolera',
    'isoliez',
    'isoloir',
    'isolons',
    'isomère',
    'isopets',
    'isopode',
    'isotope',
    'issante',
    'issants',
    'isthmes',
    'italien',
    'itérais',
    'itérait',
    'itérant',
    'itérées',
    'itèrent',
    'itérera',
    'itériez',
    'itérons',
    'ivettes',
    'ivoires',
    'ivoirin',
    'ivraies',
    'ivresse',
    'ivrogne',
    'jabirus',
    'jablais',
    'jablait',
    'jablant',
    'jablées',
    'jablent',
    'jablera',
    'jabliez',
    'jabloir',
    'jablons',
    'jabotai',
    'jabotas',
    'jabotât',
    'jabotée',
    'jaboter',
    'jabotes',
    'jabotés',
    'jabotez',
    'jacassa',
    'jacasse',
    'jacassé',
    'jachère',
    'jackets',
    'jacobée',
    'jacobin',
    'jacobus',
    'jaconas',
    'jacquée',
    'jacquer',
    'jacqués',
    'jacquet',
    'jacquot',
    'jactais',
    'jactait',
    'jactant',
    'jactent',
    'jactera',
    'jactiez',
    'jactons',
    'jadéite',
    'jaguars',
    'jaillir',
    'jaillis',
    'jaillit',
    'jaillît',
    'jalonna',
    'jalonne',
    'jalonné',
    'jalousa',
    'jalouse',
    'jalousé',
    'jambage',
    'jambées',
    'jambier',
    'jambons',
    'jambose',
    'jangada',
    'janvier',
    'japonna',
    'japonne',
    'japonné',
    'jappais',
    'jappait',
    'jappant',
    'jappent',
    'jappera',
    'jappeur',
    'jappiez',
    'jappons',
    'jaquier',
    'jardina',
    'jardine',
    'jardiné',
    'jardins',
    'jardons',
    'jargons',
    'jarosse',
    'jarreta',
    'jarreté',
    'jarrets',
    'jasâmes',
    'jasasse',
    'jasâtes',
    'jaserai',
    'jaseran',
    'jaseras',
    'jaserez',
    'jaseron',
    'jaseurs',
    'jaseuse',
    'jasions',
    'jasmins',
    'jaspais',
    'jaspait',
    'jaspant',
    'jaspées',
    'jaspent',
    'jaspera',
    'jaspiez',
    'jaspina',
    'jaspine',
    'jaspiné',
    'jaspons',
    'jaspure',
    'jattées',
    'jaugeai',
    'jaugeas',
    'jaugeât',
    'jaugées',
    'jaugent',
    'jaugera',
    'jaugeur',
    'jaugiez',
    'jaunets',
    'jaunies',
    'jaunira',
    'javarts',
    'javeaux',
    'javelai',
    'javelas',
    'javelât',
    'javelée',
    'javeler',
    'javelés',
    'javelez',
    'javelle',
    'javelot',
    'jazzman',
    'jazzmen',
    'jéjunum',
    'jerkais',
    'jerkait',
    'jerkant',
    'jerkent',
    'jerkera',
    'jerkiez',
    'jerkons',
    'jerseys',
    'jésuite',
    'jetable',
    'jetages',
    'jetâmes',
    'jetasse',
    'jetâtes',
    'jeteurs',
    'jeteuse',
    'jetions',
    'jettent',
    'jettera',
    'jeûnais',
    'jeûnait',
    'jeûnant',
    'jeûnent',
    'jeûnera',
    'jeunets',
    'jeûneur',
    'jeûniez',
    'jeûnons',
    'jeunots',
    'jiggers',
    'jobarda',
    'jobarde',
    'jobardé',
    'jobards',
    'jobelin',
    'jocasse',
    'jociste',
    'jockeys',
    'jodlais',
    'jodlait',
    'jodlant',
    'jodlées',
    'jodlent',
    'jodlera',
    'jodliez',
    'jodlons',
    'jogging',
    'joignes',
    'joignez',
    'joignis',
    'joignit',
    'joignît',
    'joindra',
    'joindre',
    'jointes',
    'jointif',
    'jonçais',
    'jonçait',
    'jonçant',
    'joncées',
    'joncent',
    'joncera',
    'jonchai',
    'jonchas',
    'jonchât',
    'jonchée',
    'joncher',
    'jonches',
    'jonchés',
    'jonchet',
    'jonchez',
    'jonciez',
    'jonçons',
    'jonglai',
    'jonglas',
    'jonglât',
    'jonglée',
    'jongler',
    'jongles',
    'jonglés',
    'jonglez',
    'jonques',
    'josephs',
    'jouable',
    'jouâmes',
    'jouasse',
    'jouâtes',
    'jouerai',
    'joueras',
    'jouerez',
    'joueurs',
    'joueuse',
    'joufflu',
    'jouîmes',
    'jouions',
    'jouirai',
    'jouiras',
    'jouirez',
    'jouisse',
    'jouîtes',
    'joujoux',
    'journal',
    'journée',
    'joutais',
    'joutait',
    'joutant',
    'joutent',
    'joutera',
    'jouteur',
    'joutiez',
    'joutons',
    'jouxtai',
    'jouxtas',
    'jouxtât',
    'jouxtée',
    'jouxter',
    'jouxtes',
    'jouxtés',
    'jouxtez',
    'joviale',
    'joviaux',
    'joviens',
    'joyeuse',
    'jubarte',
    'jubilai',
    'jubilas',
    'jubilât',
    'jubiler',
    'jubiles',
    'jubilez',
    'juchais',
    'juchait',
    'juchant',
    'juchées',
    'juchent',
    'juchera',
    'juchiez',
    'juchoir',
    'juchons',
    'judaïté',
    'judéité',
    'judelle',
    'judokas',
    'jugales',
    'jugeais',
    'jugeait',
    'jugeant',
    'jugeons',
    'jugeote',
    'jugerai',
    'jugeras',
    'jugerez',
    'jugeurs',
    'jugeuse',
    'jugions',
    'jugulai',
    'jugulas',
    'jugulât',
    'jugulée',
    'juguler',
    'jugules',
    'jugulés',
    'jugulez',
    'juillet',
    'jujubes',
    'juliens',
    'jumeaux',
    'jumelai',
    'jumelas',
    'jumelât',
    'jumelée',
    'jumeler',
    'jumelés',
    'jumelez',
    'jumelle',
    'juments',
    'jumping',
    'jungles',
    'juniors',
    'junkers',
    'jupette',
    'juponna',
    'juponne',
    'juponné',
    'jurâmes',
    'jurande',
    'jurasse',
    'jurâtes',
    'jurerai',
    'jureras',
    'jurerez',
    'jureurs',
    'jurions',
    'juriste',
    'jusants',
    'jusques',
    'jussiée',
    'jussion',
    'justice',
    'jutâmes',
    'jutasse',
    'jutâtes',
    'juterai',
    'juteras',
    'juterez',
    'juteuse',
    'jutions',
    'juvénat',
    'kabbale',
    'kabyles',
    'kaïnite',
    'kaisers',
    'kaliums',
    'kamalas',
    'kamichi',
    'kanakes',
    'kandjar',
    'kantien',
    'kaolins',
    'karakul',
    'karatés',
    'karbaus',
    'karités',
    'karmans',
    'karting',
    'kascher',
    'keffieh',
    'kelvins',
    'kentias',
    'képhirs',
    'kérabau',
    'ketchup',
    'ketmies',
    'khâgnes',
    'khalife',
    'khamsin',
    'khanats',
    'khédive',
    'khmères',
    'kif-kif',
    'kimonos',
    'kinases',
    'kiosque',
    'kippers',
    'kirschs',
    'klaxons',
    'kleenex',
    'klephte',
    'kobolds',
    'kolkhoz',
    'konzern',
    'kopecks',
    'koubbas',
    'kouglof',
    'koulaks',
    'krakens',
    'kroumir',
    'krypton',
    'kufique',
    'kummels',
    'kumquat',
    'labarum',
    'labelle',
    'labeurs',
    'labiale',
    'labiaux',
    'labiées',
    'labiles',
    'labiums',
    'laboura',
    'laboure',
    'labouré',
    'labours',
    'laçages',
    'laçâmes',
    'laçasse',
    'laçâtes',
    'laccase',
    'lacerai',
    'lacérai',
    'laceras',
    'lacéras',
    'lacérât',
    'lacérée',
    'lacérer',
    'lacérés',
    'lacères',
    'lacerez',
    'lacérez',
    'lacerie',
    'laceurs',
    'laceuse',
    'lâchage',
    'lâchais',
    'lâchait',
    'lâchant',
    'lâchées',
    'lâchent',
    'lâchera',
    'lâcheté',
    'lâcheur',
    'lâchiez',
    'lâchons',
    'lacinié',
    'lacions',
    'lactase',
    'lactate',
    'lactées',
    'lactose',
    'lacunes',
    'ladanum',
    'lagunes',
    'là-haut',
    'laïcats',
    'laîches',
    'laïcisa',
    'laïcise',
    'laïcisé',
    'laïcité',
    'laideur',
    'laierai',
    'laieras',
    'laierez',
    'lainage',
    'lainais',
    'lainait',
    'lainant',
    'lainées',
    'lainent',
    'lainera',
    'laineur',
    'laineux',
    'lainier',
    'lainiez',
    'lainons',
    'laïques',
    'laissai',
    'laissas',
    'laissât',
    'laissée',
    'laisser',
    'laisses',
    'laissés',
    'laissez',
    'laitage',
    'laitées',
    'laiteux',
    'laitier',
    'laitons',
    'laitues',
    'lakiste',
    'lamâmes',
    'lamasse',
    'lamâtes',
    'lambeau',
    'lambels',
    'lambics',
    'lambina',
    'lambine',
    'lambiné',
    'lambins',
    'lambris',
    'lamelle',
    'lamellé',
    'lamenta',
    'lamente',
    'lamenté',
    'lamento',
    'lamerai',
    'lameras',
    'lamerez',
    'lamiers',
    'lamifié',
    'laminai',
    'laminas',
    'laminât',
    'laminée',
    'laminer',
    'lamines',
    'laminés',
    'laminez',
    'lamions',
    'lampais',
    'lampait',
    'lampant',
    'lamparo',
    'lampées',
    'lampent',
    'lampera',
    'lampiez',
    'lampion',
    'lampons',
    'lampyre',
    'lançage',
    'lançais',
    'lançait',
    'lançant',
    'lancées',
    'lancent',
    'lancera',
    'lanceur',
    'lancier',
    'lanciez',
    'lancina',
    'lancine',
    'lanciné',
    'lançons',
    'landais',
    'landaus',
    'landier',
    'langage',
    'langeai',
    'langeas',
    'langeât',
    'langées',
    'langent',
    'langera',
    'langiez',
    'langues',
    'languie',
    'languir',
    'languis',
    'languit',
    'languît',
    'lanices',
    'lanière',
    'laniers',
    'laotien',
    'lapâmes',
    'lapasse',
    'lapâtes',
    'laperai',
    'laperas',
    'laperez',
    'lapidai',
    'lapidas',
    'lapidât',
    'lapidée',
    'lapider',
    'lapides',
    'lapidés',
    'lapidez',
    'lapilli',
    'lapines',
    'lapions',
    'lapones',
    'laptots',
    'laquage',
    'laquais',
    'laquait',
    'laquant',
    'laquées',
    'laquent',
    'laquera',
    'laqueur',
    'laqueux',
    'laquiez',
    'laquons',
    'laraire',
    'larbins',
    'larcins',
    'lardais',
    'lardait',
    'lardant',
    'lardées',
    'lardent',
    'lardera',
    'lardiez',
    'lardons',
    'largage',
    'largeur',
    'larguai',
    'larguas',
    'larguât',
    'larguée',
    'larguer',
    'largues',
    'largués',
    'larguez',
    'larigot',
    'larmier',
    'larmoie',
    'larmoya',
    'larmoyé',
    'larrons',
    'larvées',
    'laryngé',
    'lasagne',
    'lascars',
    'lascifs',
    'lascive',
    'lassais',
    'lassait',
    'lassant',
    'lassées',
    'lassent',
    'lassera',
    'lassiez',
    'lassons',
    'lasting',
    'latence',
    'latente',
    'latents',
    'latéral',
    'latines',
    'latries',
    'lattage',
    'lattais',
    'lattait',
    'lattant',
    'lattées',
    'lattent',
    'lattera',
    'lattiez',
    'lattons',
    'lauréat',
    'laurées',
    'laurier',
    'lavable',
    'lavabos',
    'lavages',
    'lavâmes',
    'lavande',
    'lavaret',
    'lavasse',
    'lavâtes',
    'laverai',
    'laveras',
    'laverez',
    'laverie',
    'lavette',
    'laveurs',
    'laveuse',
    'lavions',
    'lavoirs',
    'lavures',
    'laxatif',
    'laxisme',
    'laxiste',
    'laxités',
    'layâmes',
    'layasse',
    'layâtes',
    'layerai',
    'layeras',
    'layerez',
    'layette',
    'layions',
    'lazaret',
    'leaders',
    'leasing',
    'léchage',
    'léchais',
    'léchait',
    'léchant',
    'léchées',
    'lèchent',
    'léchera',
    'lécheur',
    'léchiez',
    'léchons',
    'lecteur',
    'lecture',
    'légales',
    'légende',
    'légères',
    'leggins',
    'leghorn',
    'légions',
    'légiste',
    'léguais',
    'léguait',
    'léguant',
    'léguées',
    'lèguent',
    'léguera',
    'léguiez',
    'légumes',
    'léguons',
    'lemming',
    'lémures',
    'lendits',
    'lénifia',
    'lénifie',
    'lénifié',
    'lénitif',
    'lenteur',
    'lentigo',
    'léonard',
    'léonine',
    'léonins',
    'léonure',
    'léopard',
    'lépiote',
    'lépisme',
    'lépreux',
    'lepture',
    'lésâmes',
    'lésante',
    'lésants',
    'lésasse',
    'lésâtes',
    'lesbien',
    'lesdits',
    'léserai',
    'léseras',
    'léserez',
    'lésinai',
    'lésinas',
    'lésinât',
    'lésiner',
    'lésines',
    'lésinez',
    'lésions',
    'lessiva',
    'lessive',
    'lessivé',
    'lestage',
    'lestais',
    'lestait',
    'lestant',
    'lestées',
    'lestent',
    'lestera',
    'lestiez',
    'lestons',
    'létales',
    'letchis',
    'lettone',
    'lettrée',
    'lettres',
    'lettrés',
    'leucine',
    'leucite',
    'leucome',
    'leucose',
    'leurrai',
    'leurras',
    'leurrât',
    'leurrée',
    'leurrer',
    'leurres',
    'leurrés',
    'leurrez',
    'levages',
    'levains',
    'levâmes',
    'levasse',
    'levâtes',
    'lèverai',
    'lèveras',
    'lèverez',
    'leviers',
    'lévigea',
    'lévigée',
    'léviger',
    'léviges',
    'lévigés',
    'lévigez',
    'levions',
    'lévirat',
    'lévites',
    'levraut',
    'lévrier',
    'levrons',
    'levures',
    'lexèmes',
    'lexical',
    'lexique',
    'lézarda',
    'lézarde',
    'lézardé',
    'lézards',
    'liaient',
    'liaison',
    'liantes',
    'liardai',
    'liardas',
    'liardât',
    'liarder',
    'liardes',
    'liardez',
    'liasses',
    'libages',
    'libella',
    'libelle',
    'libellé',
    'libérai',
    'libéral',
    'libéras',
    'libérât',
    'libérée',
    'libérer',
    'libérés',
    'libères',
    'libérez',
    'liberté',
    'liberty',
    'libidos',
    'libyens',
    'licence',
    'lichais',
    'lichait',
    'lichant',
    'lichées',
    'lichens',
    'lichent',
    'lichera',
    'lichiez',
    'lichons',
    'liciers',
    'licitai',
    'licitas',
    'licitât',
    'licitée',
    'liciter',
    'licites',
    'licités',
    'licitez',
    'licorne',
    'licteur',
    'liégeai',
    'liégeas',
    'liégeât',
    'liégées',
    'liègent',
    'liégera',
    'liégiez',
    'lierais',
    'lierait',
    'lièrent',
    'lieriez',
    'liernes',
    'lierons',
    'lieront',
    'lierres',
    'liesses',
    'lieudit',
    'lieuses',
    'lièvres',
    'liftais',
    'liftait',
    'liftant',
    'liftées',
    'liftent',
    'liftera',
    'liftier',
    'liftiez',
    'lifting',
    'liftons',
    'ligands',
    'lignage',
    'lignais',
    'lignait',
    'lignant',
    'lignard',
    'lignées',
    'lignent',
    'lignera',
    'ligneul',
    'ligneux',
    'ligniez',
    'lignine',
    'lignite',
    'lignons',
    'ligotai',
    'ligotas',
    'ligotât',
    'ligotée',
    'ligoter',
    'ligotes',
    'ligotés',
    'ligotez',
    'liguais',
    'liguait',
    'liguant',
    'liguées',
    'liguent',
    'liguera',
    'ligueur',
    'liguiez',
    'ligulée',
    'ligules',
    'ligulés',
    'liguons',
    'ligures',
    'liliacé',
    'liliale',
    'liliaux',
    'limaces',
    'limaçon',
    'limages',
    'limâmes',
    'limanda',
    'limande',
    'limandé',
    'limasse',
    'limâtes',
    'limerai',
    'limeras',
    'limerez',
    'limette',
    'limeurs',
    'limeuse',
    'limiers',
    'liminal',
    'limions',
    'limitai',
    'limitas',
    'limitât',
    'limitée',
    'limiter',
    'limites',
    'limités',
    'limitez',
    'limnées',
    'limogea',
    'limogée',
    'limoger',
    'limoges',
    'limogés',
    'limogez',
    'limonai',
    'limonas',
    'limonât',
    'limonée',
    'limoner',
    'limones',
    'limonés',
    'limonez',
    'limpide',
    'limules',
    'linaire',
    'linceul',
    'linéale',
    'linéaux',
    'linette',
    'lingams',
    'lingeai',
    'lingeas',
    'lingeât',
    'lingées',
    'lingent',
    'lingera',
    'lingère',
    'lingiez',
    'lingots',
    'lingual',
    'linière',
    'liniers',
    'linkage',
    'linnéen',
    'linotte',
    'linsang',
    'linsoir',
    'linteau',
    'linters',
    'lionnes',
    'liparis',
    'lipases',
    'lipémie',
    'lipides',
    'lipoïde',
    'lipomes',
    'lippées',
    'lippues',
    'liqueur',
    'liquida',
    'liquide',
    'liquidé',
    'lirions',
    'lisages',
    'liserai',
    'lisérai',
    'liseras',
    'liséras',
    'liserât',
    'lisérât',
    'liserée',
    'lisérée',
    'liserer',
    'lisérer',
    'liserés',
    'lisérés',
    'lisères',
    'liserez',
    'lisérez',
    'liseron',
    'liseurs',
    'liseuse',
    'lisible',
    'lisière',
    'lisiers',
    'lisions',
    'lissage',
    'lissais',
    'lissait',
    'lissant',
    'lissées',
    'lissent',
    'lissera',
    'lisseur',
    'lissier',
    'lissiez',
    'lissoir',
    'lissons',
    'listage',
    'listais',
    'listait',
    'listant',
    'listeau',
    'listées',
    'listels',
    'listent',
    'listera',
    'listiez',
    'listing',
    'listons',
    'litâmes',
    'litanie',
    'litasse',
    'litâtes',
    'litchis',
    'liteaux',
    'literai',
    'literas',
    'literez',
    'literie',
    'lithams',
    'lithiné',
    'lithium',
    'litière',
    'litiges',
    'litions',
    'litorne',
    'litotes',
    'litrons',
    'litsams',
    'livarot',
    'livèche',
    'livides',
    'livings',
    'livrais',
    'livrait',
    'livrant',
    'livrées',
    'livrent',
    'livrera',
    'livrets',
    'livreur',
    'livriez',
    'livrons',
    'loaders',
    'lobaire',
    'lobâmes',
    'lobasse',
    'lobâtes',
    'lobbies',
    'lobélie',
    'loberai',
    'loberas',
    'loberez',
    'lobions',
    'lobulée',
    'lobulés',
    'locales',
    'locatif',
    'lochais',
    'lochait',
    'lochant',
    'lochées',
    'lochent',
    'lochera',
    'lochies',
    'lochiez',
    'lochons',
    'loculée',
    'loculés',
    'locuste',
    'lofâmes',
    'lofasse',
    'lofâtes',
    'loferai',
    'loferas',
    'loferez',
    'lofions',
    'logeais',
    'logeait',
    'logeant',
    'logeons',
    'logerai',
    'logeras',
    'logerez',
    'logette',
    'logeurs',
    'logeuse',
    'loggias',
    'logions',
    'logique',
    'logiste',
    'loisirs',
    'lokoums',
    'lombago',
    'lombard',
    'lombric',
    'londrès',
    'longane',
    'longeai',
    'longeas',
    'longeât',
    'longées',
    'longent',
    'longera',
    'longiez',
    'longues',
    'longuet',
    'looping',
    'lopette',
    'loquace',
    'loquais',
    'loquait',
    'loquant',
    'loquées',
    'loquent',
    'loquera',
    'loquets',
    'loquiez',
    'loquons',
    'lordose',
    'lorette',
    'lorgnai',
    'lorgnas',
    'lorgnât',
    'lorgnée',
    'lorgner',
    'lorgnes',
    'lorgnés',
    'lorgnez',
    'lorgnon',
    'loriots',
    'lorrain',
    'lorsque',
    'losange',
    'losangé',
    'loterie',
    'lotiers',
    'lotîmes',
    'lotions',
    'lotirai',
    'lotiras',
    'lotirez',
    'lotisse',
    'lotîtes',
    'louable',
    'louages',
    'louâmes',
    'louange',
    'louangé',
    'louasse',
    'louâtes',
    'loubard',
    'loubars',
    'louchai',
    'louchas',
    'louchât',
    'loucher',
    'louches',
    'louchet',
    'louchez',
    'louchir',
    'louchis',
    'louchit',
    'louchît',
    'louchon',
    'louerai',
    'loueras',
    'louerez',
    'loueurs',
    'loueuse',
    'loufais',
    'loufait',
    'loufant',
    'loufent',
    'loufera',
    'loufiat',
    'loufiez',
    'loufons',
    'lougres',
    'louions',
    'loukoum',
    'loulous',
    'loupage',
    'loupais',
    'loupait',
    'loupant',
    'loupées',
    'loupent',
    'loupera',
    'loupiez',
    'loupiot',
    'loupons',
    'lourais',
    'lourait',
    'lourant',
    'lourdai',
    'lourdas',
    'lourdât',
    'lourdée',
    'lourder',
    'lourdes',
    'lourdés',
    'lourdez',
    'lourées',
    'lourent',
    'lourera',
    'louriez',
    'lourons',
    'loustic',
    'loutres',
    'louvais',
    'louvait',
    'louvant',
    'louvées',
    'louvent',
    'louvera',
    'louveta',
    'louveté',
    'louvets',
    'louviez',
    'louvoie',
    'louvons',
    'louvoya',
    'louvoyé',
    'lovâmes',
    'lovasse',
    'lovâtes',
    'loverai',
    'loveras',
    'loverez',
    'lovions',
    'loyales',
    'loyauté',
    'lucanes',
    'lucarne',
    'lucides',
    'lucilie',
    'luciole',
    'ludions',
    'ludique',
    'ludisme',
    'luétine',
    'luettes',
    'lugeais',
    'lugeait',
    'lugeant',
    'lugeons',
    'lugerai',
    'lugeras',
    'lugerez',
    'lugeurs',
    'lugeuse',
    'lugions',
    'lugubre',
    'luirais',
    'luirait',
    'luiriez',
    'luirons',
    'luiront',
    'luisais',
    'luisait',
    'luisant',
    'luisent',
    'luisiez',
    'luisons',
    'lumbago',
    'lumière',
    'lunaire',
    'lunchai',
    'lunchas',
    'lunchât',
    'luncher',
    'lunches',
    'lunchez',
    'lunette',
    'lunules',
    'lunures',
    'lupanar',
    'lupulin',
    'lurette',
    'luronne',
    'lussent',
    'lussiez',
    'lustrai',
    'lustral',
    'lustras',
    'lustrât',
    'lustrée',
    'lustrer',
    'lustres',
    'lustrés',
    'lustrez',
    'lutâmes',
    'lutasse',
    'lutâtes',
    'lutéine',
    'luterai',
    'luteras',
    'luterez',
    'luthier',
    'lutinai',
    'lutinas',
    'lutinât',
    'lutinée',
    'lutiner',
    'lutines',
    'lutinés',
    'lutinez',
    'lutions',
    'lutrins',
    'luttais',
    'luttait',
    'luttant',
    'luttent',
    'luttera',
    'lutteur',
    'luttiez',
    'luttons',
    'luxâmes',
    'luxasse',
    'luxâtes',
    'luxerai',
    'luxeras',
    'luxerez',
    'luxions',
    'luxueux',
    'luxures',
    'luzerne',
    'luzules',
    'lycaons',
    'lycéens',
    'lycènes',
    'lychees',
    'lychnis',
    'lycopes',
    'lycoses',
    'lyddite',
    'lydiens',
    'lymphes',
    'lynchai',
    'lynchas',
    'lynchât',
    'lynchée',
    'lyncher',
    'lynches',
    'lynchés',
    'lynchez',
    'lyrique',
    'lyrisme',
    'lysâmes',
    'lysasse',
    'lysâtes',
    'lyserai',
    'lyseras',
    'lyserez',
    'lysines',
    'lysions',
    'lytique',
    'maboule',
    'mabouls',
    'macabre',
    'macache',
    'macadam',
    'macaque',
    'macaron',
    'macérai',
    'macéras',
    'macérât',
    'macérée',
    'macérer',
    'macérés',
    'macères',
    'macérez',
    'maceron',
    'mâchais',
    'mâchait',
    'mâchant',
    'machaon',
    'mâchées',
    'mâchent',
    'mâchera',
    'mâcheur',
    'mâchiez',
    'machina',
    'machine',
    'machiné',
    'machins',
    'mâchons',
    'mâchura',
    'mâchure',
    'mâchuré',
    'maclais',
    'maclait',
    'maclant',
    'maclées',
    'maclent',
    'maclera',
    'macliez',
    'maclons',
    'maçonna',
    'maçonne',
    'maçonné',
    'macquai',
    'macquas',
    'macquât',
    'macquée',
    'macquer',
    'macques',
    'macqués',
    'macquez',
    'macramé',
    'maculai',
    'maculas',
    'maculât',
    'maculée',
    'maculer',
    'macules',
    'maculés',
    'maculez',
    'madéfia',
    'madéfie',
    'madéfié',
    'madères',
    'madones',
    'madrées',
    'madrier',
    'maestro',
    'maffias',
    'mafflue',
    'mafflus',
    'mafiosi',
    'mafioso',
    'magasin',
    'magenta',
    'maghzen',
    'magique',
    'magnais',
    'magnait',
    'magnant',
    'magnats',
    'magnées',
    'magnent',
    'magnera',
    'magnéto',
    'magniez',
    'magnons',
    'magnums',
    'magrets',
    'magyare',
    'mahatma',
    'mahonia',
    'mahonne',
    'maïeurs',
    'maigres',
    'maigrie',
    'maigrir',
    'maigris',
    'maigrit',
    'maigrît',
    'mailing',
    'maillai',
    'maillas',
    'maillât',
    'maillée',
    'mailler',
    'mailles',
    'maillés',
    'maillet',
    'maillez',
    'maillon',
    'maillot',
    'mainate',
    'maintes',
    'mairies',
    'maisons',
    'maîtres',
    'maïzena',
    'majesté',
    'majeure',
    'majeurs',
    'ma-jong',
    'majorai',
    'majoral',
    'majoras',
    'majorat',
    'majorât',
    'majorée',
    'majorer',
    'majores',
    'majorés',
    'majorez',
    'malabar',
    'malades',
    'maladie',
    'maladif',
    'malaire',
    'malaise',
    'malaisé',
    'malards',
    'malaria',
    'malarts',
    'malaxai',
    'malaxas',
    'malaxât',
    'malaxée',
    'malaxer',
    'malaxes',
    'malaxés',
    'malaxez',
    'malfais',
    'malfait',
    'malfamé',
    'malfera',
    'malfont',
    'malfrat',
    'malheur',
    'malices',
    'maliens',
    'maligne',
    'malique',
    'malmena',
    'malmené',
    'malmène',
    'malotru',
    'malpoli',
    'malsain',
    'maltage',
    'maltais',
    'maltait',
    'maltant',
    'maltase',
    'maltées',
    'maltent',
    'maltera',
    'malteur',
    'maltiez',
    'maltons',
    'maltose',
    'maltôte',
    'malvenu',
    'mamelle',
    'mamelon',
    'mamelue',
    'mamelus',
    'mammite',
    'mamours',
    'manades',
    'managea',
    'managée',
    'manager',
    'manages',
    'managés',
    'managez',
    'manants',
    'manches',
    'manchon',
    'manchot',
    'mancies',
    'mandais',
    'mandait',
    'mandale',
    'mandant',
    'mandata',
    'mandate',
    'mandaté',
    'mandats',
    'mandées',
    'mandent',
    'mandera',
    'mandiez',
    'mandons',
    'mandore',
    'mandrin',
    'manégea',
    'manégée',
    'manéger',
    'manégés',
    'manèges',
    'manégez',
    'maneton',
    'manette',
    'mangeai',
    'mangeas',
    'mangeât',
    'mangées',
    'mangent',
    'mangera',
    'mangeur',
    'mangiez',
    'mangues',
    'maniais',
    'maniait',
    'maniant',
    'manicle',
    'maniées',
    'manient',
    'maniera',
    'maniéra',
    'maniéré',
    'manière',
    'manieur',
    'maniiez',
    'manille',
    'maniocs',
    'manions',
    'manique',
    'manitou',
    'mannite',
    'mannose',
    'manoirs',
    'manoqua',
    'manoque',
    'manoqué',
    'manquai',
    'manquas',
    'manquât',
    'manquée',
    'manquer',
    'manques',
    'manqués',
    'manquez',
    'mansion',
    'manteau',
    'mantelé',
    'manuels',
    'maoïsme',
    'maoïste',
    'maories',
    'maousse',
    'maquais',
    'maquait',
    'maquant',
    'maquées',
    'maquent',
    'maquera',
    'maquiez',
    'maquons',
    'maranta',
    'marante',
    'marasme',
    'marâtre',
    'marauda',
    'maraude',
    'maraudé',
    'marauds',
    'marbrai',
    'marbras',
    'marbrât',
    'marbrée',
    'marbrer',
    'marbres',
    'marbrés',
    'marbrez',
    'marchai',
    'marchas',
    'marchât',
    'marcher',
    'marches',
    'marchés',
    'marchez',
    'marelle',
    'maremme',
    'marengo',
    'marfils',
    'margays',
    'margeai',
    'margeas',
    'margeât',
    'margées',
    'margent',
    'margera',
    'margeur',
    'margiez',
    'margina',
    'margine',
    'marginé',
    'margota',
    'margote',
    'margoté',
    'mariage',
    'mariais',
    'mariait',
    'mariale',
    'marials',
    'mariant',
    'mariaux',
    'mariées',
    'marient',
    'mariera',
    'marieur',
    'marigot',
    'mariiez',
    'marinai',
    'marinas',
    'marinât',
    'marinée',
    'mariner',
    'marines',
    'marinés',
    'marinez',
    'mariole',
    'marions',
    'mariste',
    'marital',
    'marlous',
    'marmita',
    'marmite',
    'marmité',
    'marmots',
    'marnage',
    'marnais',
    'marnait',
    'marnant',
    'marnées',
    'marnent',
    'marnera',
    'marneur',
    'marneux',
    'marniez',
    'marnons',
    'maronna',
    'maronne',
    'maronné',
    'marotte',
    'maroute',
    'marquai',
    'marquas',
    'marquât',
    'marquée',
    'marquer',
    'marques',
    'marqués',
    'marquez',
    'marquis',
    'marrais',
    'marrait',
    'marrant',
    'marrées',
    'marrent',
    'marrera',
    'marries',
    'marriez',
    'marrons',
    'marrube',
    'marteau',
    'martela',
    'martelé',
    'martèle',
    'martial',
    'martien',
    'Martini',
    'martres',
    'martyre',
    'martyrs',
    'marxien',
    'marxisa',
    'marxise',
    'marxisé',
    'mascara',
    'masquai',
    'masquas',
    'masquât',
    'masquée',
    'masquer',
    'masques',
    'masqués',
    'masquez',
    'massage',
    'massais',
    'massait',
    'massant',
    'massées',
    'massent',
    'massera',
    'masseur',
    'massier',
    'massiez',
    'massifs',
    'massive',
    'massons',
    'massues',
    'mastaba',
    'mastics',
    'mastiff',
    'mastite',
    'masures',
    'matador',
    'matages',
    'matâmes',
    'mâtâmes',
    'matasse',
    'mâtasse',
    'matâtes',
    'mâtâtes',
    'matchai',
    'matchas',
    'matchât',
    'matchée',
    'matcher',
    'matches',
    'matchés',
    'matchez',
    'matelas',
    'matelot',
    'materai',
    'mâterai',
    'materas',
    'mâteras',
    'materez',
    'mâterez',
    'materna',
    'materne',
    'materné',
    'matheux',
    'matière',
    'matîmes',
    'mâtinai',
    'matinal',
    'mâtinas',
    'mâtinât',
    'matinée',
    'mâtinée',
    'mâtiner',
    'matines',
    'mâtinés',
    'mâtinez',
    'mations',
    'mâtions',
    'matirai',
    'matiras',
    'matirez',
    'matisse',
    'matités',
    'matîtes',
    'matoirs',
    'matoise',
    'matonne',
    'matriça',
    'matrice',
    'matricé',
    'matrone',
    'maturai',
    'maturas',
    'maturât',
    'maturée',
    'maturer',
    'matures',
    'maturés',
    'mâtures',
    'maturez',
    'maudira',
    'maudire',
    'maudite',
    'maudits',
    'maugréa',
    'maugrée',
    'maugréé',
    'mausers',
    'mauvais',
    'maxille',
    'maximal',
    'maximes',
    'maximum',
    'maxwell',
    'mazette',
    'mazouta',
    'mazoute',
    'mazouté',
    'mazouts',
    'mazurka',
    'méandre',
    'mécanos',
    'meccano',
    'mécénat',
    'mécènes',
    'méchage',
    'méchais',
    'méchait',
    'méchant',
    'méchées',
    'mèchent',
    'méchera',
    'mécheux',
    'méchiez',
    'méchons',
    'méchoui',
    'mechtas',
    'méconnu',
    'médecin',
    'medersa',
    'médersa',
    'médiale',
    'médiane',
    'médians',
    'médiats',
    'médiaux',
    'médical',
    'médîmes',
    'médinas',
    'médique',
    'médirai',
    'médiras',
    'médirez',
    'médisai',
    'médisas',
    'médisât',
    'médiser',
    'médises',
    'médisez',
    'médisse',
    'méditai',
    'méditas',
    'méditât',
    'méditée',
    'méditer',
    'médites',
    'médités',
    'médîtes',
    'méditez',
    'médiums',
    'médusai',
    'médusas',
    'médusât',
    'médusée',
    'méduser',
    'méduses',
    'médusés',
    'médusez',
    'meeting',
    'méfaire',
    'méfaite',
    'méfaits',
    'méfasse',
    'méferai',
    'méferas',
    'méferez',
    'méfiais',
    'méfiait',
    'méfiant',
    'méfiées',
    'méfient',
    'méfiera',
    'méfiiez',
    'méfîmes',
    'méfions',
    'méfisse',
    'méfîtes',
    'méforme',
    'mégarde',
    'mégères',
    'mégîmes',
    'mégirai',
    'mégiras',
    'mégirez',
    'mégissa',
    'mégisse',
    'mégissé',
    'mégîtes',
    'mégohms',
    'mégotai',
    'mégotas',
    'mégotât',
    'mégotée',
    'mégoter',
    'mégotes',
    'mégotés',
    'mégotez',
    'méharée',
    'méharis',
    'méioses',
    'meistre',
    'méjugea',
    'méjugée',
    'méjuger',
    'méjuges',
    'méjugés',
    'méjugez',
    'mélaena',
    'mêlâmes',
    'mélange',
    'mélangé',
    'mélasse',
    'mêlasse',
    'mêlâtes',
    'mélénas',
    'mêlerai',
    'mêleras',
    'mêlerez',
    'mélèzes',
    'mélilot',
    'mêlions',
    'mélique',
    'mélisse',
    'mélitte',
    'mellâhs',
    'mellite',
    'mélodie',
    'melonné',
    'mélopée',
    'membrée',
    'membres',
    'membrés',
    'membron',
    'membrue',
    'membrus',
    'mémento',
    'mémères',
    'mémoire',
    'menaçai',
    'menaças',
    'menaçât',
    'menacée',
    'menacer',
    'menaces',
    'menacés',
    'menacez',
    'ménades',
    'ménagea',
    'ménagée',
    'ménager',
    'ménages',
    'ménagés',
    'ménagez',
    'menâmes',
    'menasse',
    'menâtes',
    'mendiai',
    'mendias',
    'mendiât',
    'mendiée',
    'mendier',
    'mendies',
    'mendiés',
    'mendiez',
    'meneaux',
    'mènerai',
    'mèneras',
    'mènerez',
    'meneurs',
    'meneuse',
    'menhirs',
    'menines',
    'méninge',
    'méningé',
    'menions',
    'menotte',
    'mensuel',
    'mensura',
    'mensure',
    'mensuré',
    'mentais',
    'mentait',
    'mentale',
    'mentant',
    'mentaux',
    'mentent',
    'menteur',
    'menthes',
    'menthol',
    'menties',
    'mentiez',
    'mention',
    'mentira',
    'mentons',
    'mentors',
    'menuets',
    'menuisa',
    'menuise',
    'menuisé',
    'ménures',
    'méplate',
    'méplats',
    'méprend',
    'méprisa',
    'méprise',
    'méprisé',
    'mercier',
    'mercure',
    'merdeux',
    'merdier',
    'merdoie',
    'merdoya',
    'merdoyé',
    'merguez',
    'mérinos',
    'merises',
    'méritai',
    'méritas',
    'méritât',
    'méritée',
    'mériter',
    'mérites',
    'mérités',
    'méritez',
    'merlans',
    'merlins',
    'merlons',
    'merrain',
    'mésange',
    'mesclun',
    'mesquin',
    'message',
    'messers',
    'messied',
    'messiée',
    'messier',
    'messies',
    'messire',
    'mestres',
    'mesurai',
    'mesuras',
    'mesurât',
    'mesurée',
    'mesurer',
    'mesures',
    'mesurés',
    'mesurez',
    'mésusai',
    'mésusas',
    'mésusât',
    'mésuser',
    'mésuses',
    'mésusez',
    'métallo',
    'métayer',
    'méteils',
    'météore',
    'métèque',
    'méthane',
    'méthode',
    'méthyle',
    'métiers',
    'métissa',
    'métisse',
    'métissé',
    'métopes',
    'métrage',
    'métrais',
    'métrait',
    'métrant',
    'métrées',
    'mètrent',
    'métrera',
    'métreur',
    'métriez',
    'métrite',
    'métrons',
    'mettais',
    'mettait',
    'mettant',
    'mettent',
    'metteur',
    'mettiez',
    'mettons',
    'mettrai',
    'mettras',
    'mettrez',
    'meublai',
    'meublas',
    'meublât',
    'meublée',
    'meubler',
    'meubles',
    'meublés',
    'meublez',
    'meuglai',
    'meuglas',
    'meuglât',
    'meugler',
    'meugles',
    'meuglez',
    'meulage',
    'meulais',
    'meulait',
    'meulant',
    'meulées',
    'meulent',
    'meulera',
    'meulier',
    'meuliez',
    'meulons',
    'meunier',
    'meurent',
    'meurtre',
    'meurtri',
    'meuvent',
    'mévende',
    'mévends',
    'mévendu',
    'mévente',
    'mézails',
    'mézigue',
    'mi-août',
    'miasmes',
    'miaulai',
    'miaulas',
    'miaulât',
    'miauler',
    'miaules',
    'miaulez',
    'micacée',
    'micacés',
    'micelle',
    'mi-clos',
    'micmacs',
    'mi-côte',
    'microbe',
    'microns',
    'miction',
    'midship',
    'miellée',
    'miellés',
    'miennes',
    'miettes',
    'mièvres',
    'mi-fins',
    'mignard',
    'mignons',
    'mignota',
    'mignote',
    'mignoté',
    'migrais',
    'migrait',
    'migrant',
    'migrent',
    'migrera',
    'migriez',
    'migrons',
    'mijotai',
    'mijotas',
    'mijotât',
    'mijotée',
    'mijoter',
    'mijotes',
    'mijotés',
    'mijotez',
    'mi-juin',
    'mikados',
    'mildiou',
    'milices',
    'milieux',
    'militai',
    'militas',
    'militât',
    'militer',
    'milites',
    'militez',
    'millage',
    'millets',
    'millier',
    'million',
    'milords',
    'milouin',
    'mimâmes',
    'mi-mars',
    'mimasse',
    'mimâtes',
    'mimerai',
    'mimeras',
    'mimerez',
    'mimions',
    'mimique',
    'mimosas',
    'minable',
    'minages',
    'minâmes',
    'minante',
    'minants',
    'minaret',
    'minasse',
    'minâtes',
    'minauda',
    'minaude',
    'minaudé',
    'minbars',
    'minceur',
    'mincira',
    'minerai',
    'minéral',
    'mineras',
    'minerez',
    'minerve',
    'minette',
    'mineure',
    'mineurs',
    'minibus',
    'minicar',
    'minière',
    'miniers',
    'minimal',
    'minimes',
    'minimum',
    'minions',
    'Minitel',
    'minoens',
    'minorai',
    'minoras',
    'minorât',
    'minorée',
    'minorer',
    'minores',
    'minorés',
    'minorez',
    'minuits',
    'minutai',
    'minutas',
    'minutât',
    'minutée',
    'minuter',
    'minutes',
    'minutés',
    'minutez',
    'minutie',
    'miocène',
    'mioches',
    'miracle',
    'mirador',
    'mirages',
    'mirâmes',
    'mirasse',
    'mirâtes',
    'mirbane',
    'mirerai',
    'mireras',
    'mirerez',
    'mireurs',
    'mireuse',
    'mirions',
    'miroirs',
    'miroita',
    'miroite',
    'miroité',
    'miroton',
    'misaine',
    'misâmes',
    'misasse',
    'misâtes',
    'miserai',
    'miseras',
    'misères',
    'miserez',
    'misions',
    'missels',
    'missent',
    'missiez',
    'missile',
    'mission',
    'missive',
    'mistons',
    'mistral',
    'mitages',
    'mitaine',
    'mitâmes',
    'mitasse',
    'mitâtes',
    'miterai',
    'miteras',
    'miterez',
    'miteuse',
    'mitigea',
    'mitigée',
    'mitiger',
    'mitiges',
    'mitigés',
    'mitigez',
    'mitions',
    'mitonna',
    'mitonne',
    'mitonné',
    'mitoses',
    'mitoyen',
    'mitrale',
    'mitraux',
    'mitrées',
    'mitrons',
    'mi-voix',
    'mixages',
    'mixâmes',
    'mixasse',
    'mixâtes',
    'mixerai',
    'mixeras',
    'mixerez',
    'mixeurs',
    'mixions',
    'mixités',
    'mixtion',
    'mixture',
    'mobiles',
    'moblots',
    'mochard',
    'mocheté',
    'modales',
    'modelai',
    'modelas',
    'modelât',
    'modelée',
    'modeler',
    'modelés',
    'modèles',
    'modelez',
    'modérai',
    'modéras',
    'modérât',
    'modérée',
    'modérer',
    'modérés',
    'modères',
    'modérez',
    'moderne',
    'modeste',
    'modifia',
    'modifie',
    'modifié',
    'modique',
    'modiste',
    'modulai',
    'modulas',
    'modulât',
    'modulée',
    'moduler',
    'modules',
    'modulés',
    'modulez',
    'modulor',
    'moelles',
    'moellon',
    'mofette',
    'mohairs',
    'moignon',
    'moindre',
    'moineau',
    'moirage',
    'moirais',
    'moirait',
    'moirant',
    'moirées',
    'moirent',
    'moirera',
    'moiriez',
    'moirons',
    'moirure',
    'moisais',
    'moisait',
    'moisant',
    'moisées',
    'moisent',
    'moisera',
    'moisiez',
    'moisira',
    'moisons',
    'moisson',
    'moitais',
    'moitait',
    'moitant',
    'moitent',
    'moitera',
    'moiteur',
    'moities',
    'moitiés',
    'moitiez',
    'moitira',
    'moitons',
    'molaire',
    'molasse',
    'molènes',
    'molesta',
    'moleste',
    'molesté',
    'moletai',
    'moletas',
    'moletât',
    'moletée',
    'moleter',
    'moletés',
    'moletez',
    'molette',
    'mollahs',
    'mollard',
    'mollets',
    'mollies',
    'mollira',
    'molochs',
    'molosse',
    'moments',
    'momerie',
    'momifia',
    'momifie',
    'momifié',
    'monacal',
    'monades',
    'monceau',
    'mondain',
    'mondais',
    'mondait',
    'mondant',
    'mondées',
    'mondent',
    'mondera',
    'mondial',
    'mondiez',
    'mondons',
    'monèmes',
    'mongole',
    'mongols',
    'moniale',
    'moniaux',
    'monisme',
    'moniste',
    'monitor',
    'monnaie',
    'monnaya',
    'monnaye',
    'monnayé',
    'monocle',
    'monodie',
    'monoski',
    'monstre',
    'montage',
    'montais',
    'montait',
    'montant',
    'montées',
    'montent',
    'montera',
    'monteur',
    'montiez',
    'montoir',
    'montons',
    'montrai',
    'montras',
    'montrât',
    'montrée',
    'montrer',
    'montres',
    'montrés',
    'montrez',
    'monture',
    'moquais',
    'moquait',
    'moquant',
    'moquées',
    'moquent',
    'moquera',
    'moqueur',
    'moquiez',
    'moquons',
    'moraine',
    'morales',
    'morasse',
    'morbide',
    'morbleu',
    'morceau',
    'morcela',
    'morcelé',
    'mordais',
    'mordait',
    'mordant',
    'mordent',
    'mordiez',
    'mordons',
    'mordora',
    'mordore',
    'mordoré',
    'mordrai',
    'mordras',
    'mordrez',
    'mordues',
    'moreaux',
    'morelle',
    'morfale',
    'morfaux',
    'morfila',
    'morfile',
    'morfilé',
    'morfils',
    'morflai',
    'morflas',
    'morflât',
    'morflée',
    'morfler',
    'morfles',
    'morflés',
    'morflez',
    'morfond',
    'morguai',
    'morguas',
    'morguât',
    'morguée',
    'morguer',
    'morgues',
    'morgués',
    'morguez',
    'morille',
    'morions',
    'mormone',
    'mornées',
    'moroses',
    'morpion',
    'morsure',
    'mortels',
    'mortier',
    'mort-né',
    'morulas',
    'morveux',
    'mosanes',
    'mosette',
    'mosquée',
    'motarde',
    'motards',
    'mot-clé',
    'moteurs',
    'motions',
    'motivai',
    'motivas',
    'motivât',
    'motivée',
    'motiver',
    'motives',
    'motivés',
    'motivez',
    'motrice',
    'mottais',
    'mottait',
    'mottant',
    'mottées',
    'mottent',
    'mottera',
    'motteux',
    'mottiez',
    'mottons',
    'mouchai',
    'mouchas',
    'mouchât',
    'mouchée',
    'moucher',
    'mouches',
    'mouchés',
    'mouchez',
    'moudrai',
    'moudras',
    'moudrez',
    'mouetta',
    'mouette',
    'mouetté',
    'moufles',
    'mouflet',
    'mouflon',
    'mouftai',
    'mouftas',
    'mouftât',
    'moufter',
    'mouftes',
    'mouftez',
    'mouilla',
    'mouille',
    'mouillé',
    'mouises',
    'moujiks',
    'moukère',
    'moulage',
    'moulais',
    'moulait',
    'moulant',
    'moulées',
    'moulent',
    'moulera',
    'mouleur',
    'mouliez',
    'moulina',
    'mouline',
    'mouliné',
    'moulins',
    'moulons',
    'moulues',
    'moulura',
    'moulure',
    'mouluré',
    'mourais',
    'mourait',
    'mourant',
    'mouriez',
    'mouroir',
    'mourons',
    'mourrai',
    'mourras',
    'mourres',
    'mourrez',
    'mousmés',
    'moussai',
    'moussas',
    'moussât',
    'mousser',
    'mousses',
    'moussez',
    'mousson',
    'moussue',
    'moussus',
    'moutard',
    'moutier',
    'moutons',
    'mouture',
    'mouvais',
    'mouvait',
    'mouvant',
    'mouvées',
    'mouvent',
    'mouvera',
    'mouviez',
    'mouvoir',
    'mouvons',
    'mouvrai',
    'mouvras',
    'mouvrez',
    'Moviola',
    'moyenna',
    'moyenne',
    'moyenné',
    'moyette',
    'mozette',
    'muaient',
    'muances',
    'muasses',
    'muchais',
    'muchait',
    'muchant',
    'muchées',
    'muchent',
    'muchera',
    'muchiez',
    'muchons',
    'mucrons',
    'muerais',
    'muerait',
    'muèrent',
    'mueriez',
    'muerons',
    'mueront',
    'muettes',
    'muezzin',
    'muffins',
    'muflier',
    'mugîmes',
    'mugirai',
    'mugiras',
    'mugirez',
    'mugisse',
    'mugîtes',
    'mugueta',
    'mugueté',
    'muguets',
    'mularde',
    'mulards',
    'mulâtre',
    'muletas',
    'mulette',
    'mulotai',
    'mulotas',
    'mulotât',
    'muloter',
    'mulotes',
    'mulotez',
    'mulsion',
    'munîmes',
    'munirai',
    'muniras',
    'munirez',
    'munisse',
    'munîtes',
    'munster',
    'muphtis',
    'muqueux',
    'murages',
    'murales',
    'murâmes',
    'murasse',
    'murâtes',
    'murènes',
    'murerai',
    'mureras',
    'murerez',
    'muretin',
    'murette',
    'muriate',
    'mûriers',
    'mûrîmes',
    'murions',
    'mûrirai',
    'mûriras',
    'mûrirez',
    'mûrisse',
    'mûrîtes',
    'murmels',
    'murmura',
    'murmure',
    'murmuré',
    'murrhin',
    'musâmes',
    'musarda',
    'musarde',
    'musardé',
    'musards',
    'musasse',
    'musâtes',
    'muscade',
    'muscari',
    'muscats',
    'musclai',
    'musclas',
    'musclât',
    'musclée',
    'muscler',
    'muscles',
    'musclés',
    'musclez',
    'museaux',
    'muselai',
    'muselas',
    'muselât',
    'muselée',
    'museler',
    'muselés',
    'muselet',
    'muselez',
    'muselle',
    'muserai',
    'museras',
    'muserez',
    'musette',
    'muséums',
    'musical',
    'musions',
    'musiqua',
    'musique',
    'musiqué',
    'musoirs',
    'musquai',
    'musquas',
    'musquât',
    'musquée',
    'musquer',
    'musques',
    'musqués',
    'musquez',
    'mussais',
    'mussait',
    'mussant',
    'mussées',
    'mussent',
    'mussera',
    'mussiez',
    'mussifs',
    'mussive',
    'mussons',
    'mustang',
    'mutable',
    'mutages',
    'mutâmes',
    'mutante',
    'mutants',
    'mutasse',
    'mutâtes',
    'muterai',
    'muteras',
    'muterez',
    'mutilai',
    'mutilas',
    'mutilât',
    'mutilée',
    'mutiler',
    'mutiles',
    'mutilés',
    'mutilez',
    'mutinai',
    'mutinas',
    'mutinât',
    'mutinée',
    'mutiner',
    'mutines',
    'mutinés',
    'mutinez',
    'mutions',
    'mutique',
    'mutisme',
    'mutités',
    'mutuels',
    'mutules',
    'myalgie',
    'mycoses',
    'myéline',
    'myélite',
    'myélome',
    'mygales',
    'myiases',
    'myopies',
    'myosite',
    'myriade',
    'myrrhes',
    'mystère',
    'nabotes',
    'nacarat',
    'nacelle',
    'nacrais',
    'nacrait',
    'nacrant',
    'nacrées',
    'nacrent',
    'nacrera',
    'nacriez',
    'nacrons',
    'nagaïka',
    'nageais',
    'nageait',
    'nageant',
    'nageons',
    'nagerai',
    'nageras',
    'nagerez',
    'nageurs',
    'nageuse',
    'nagions',
    'naguère',
    'nahaïka',
    'naïades',
    'naisses',
    'naissez',
    'naîtrai',
    'naîtras',
    'naîtrez',
    'naïveté',
    'nandous',
    'nanisme',
    'nankins',
    'nansouk',
    'nanties',
    'nantira',
    'nanzouk',
    'napalms',
    'naphtes',
    'naphtol',
    'nappage',
    'nappais',
    'nappait',
    'nappant',
    'nappées',
    'nappent',
    'nappera',
    'nappiez',
    'nappons',
    'narcose',
    'narguai',
    'narguas',
    'narguât',
    'narguée',
    'narguer',
    'nargues',
    'nargués',
    'narguez',
    'narines',
    'narrais',
    'narrait',
    'narrant',
    'narrées',
    'narrent',
    'narrera',
    'narriez',
    'narrons',
    'narthex',
    'narvals',
    'nasales',
    'nasarde',
    'nasards',
    'naseaux',
    'nasilla',
    'nasille',
    'nasillé',
    'nasique',
    'natales',
    'natices',
    'nations',
    'natives',
    'natrons',
    'natrums',
    'nattage',
    'nattais',
    'nattait',
    'nattant',
    'nattées',
    'nattent',
    'nattera',
    'nattier',
    'nattiez',
    'nattons',
    'naturel',
    'natures',
    'naucore',
    'nausées',
    'nautile',
    'navajas',
    'navales',
    'navarin',
    'navette',
    'navigua',
    'navigue',
    'navigué',
    'navires',
    'navrais',
    'navrait',
    'navrant',
    'navrées',
    'navrent',
    'navrera',
    'navriez',
    'navrons',
    'nazisme',
    'nécrosa',
    'nécrose',
    'nécrosé',
    'nectars',
    'néfaste',
    'néflier',
    'négatif',
    'négaton',
    'néglige',
    'négligé',
    'négoces',
    'négocia',
    'négocie',
    'négocié',
    'négondo',
    'négrier',
    'neigeât',
    'neigera',
    'neigeux',
    'nélombo',
    'némales',
    'néodyme',
    'néogène',
    'néottie',
    'népètes',
    'néphron',
    'néréide',
    'nérolis',
    'nerprun',
    'nerveux',
    'nervins',
    'nervura',
    'nervure',
    'nervuré',
    'nescafé',
    'netteté',
    'nettoie',
    'nettoya',
    'nettoyé',
    'neurale',
    'neuraux',
    'neurone',
    'neurula',
    'neutres',
    'neutron',
    'névraxe',
    'névrite',
    'névrose',
    'névrosé',
    'niables',
    'niaient',
    'niaisai',
    'niaisas',
    'niaisât',
    'niaiser',
    'niaises',
    'niaisez',
    'niaouli',
    'niasses',
    'nichais',
    'nichait',
    'nichant',
    'nichées',
    'nichent',
    'nichera',
    'nichets',
    'nichiez',
    'nichoir',
    'nichons',
    'nickela',
    'nickelé',
    'nickels',
    'nidifia',
    'nidifie',
    'nidifié',
    'niellai',
    'niellas',
    'niellât',
    'niellée',
    'nieller',
    'nielles',
    'niellés',
    'niellez',
    'nierais',
    'nierait',
    'nièrent',
    'nieriez',
    'nierons',
    'nieront',
    'nigaude',
    'nigauds',
    'nigelle',
    'nilgaut',
    'nimbais',
    'nimbait',
    'nimbant',
    'nimbées',
    'nimbent',
    'nimbera',
    'nimbiez',
    'nimbons',
    'niobium',
    'nippais',
    'nippait',
    'nippant',
    'nippées',
    'nippent',
    'nippera',
    'nippiez',
    'nippone',
    'nippons',
    'nirvana',
    'nitrais',
    'nitrait',
    'nitrant',
    'nitrata',
    'nitrate',
    'nitraté',
    'nitrées',
    'nitrent',
    'nitrera',
    'nitreux',
    'nitriez',
    'nitrile',
    'nitrite',
    'nitrons',
    'nitrura',
    'nitrure',
    'nitruré',
    'nivales',
    'nivéale',
    'niveaux',
    'nivéaux',
    'nivelai',
    'nivelas',
    'nivelât',
    'nivelée',
    'niveler',
    'nivelés',
    'nivelez',
    'nivelle',
    'nivéole',
    'nizerés',
    'nobliau',
    'noceurs',
    'noceuse',
    'nochers',
    'nocives',
    'noctule',
    'nocuité',
    'nodules',
    'noierai',
    'noieras',
    'noierez',
    'noiraud',
    'noircie',
    'noircir',
    'noircis',
    'noircit',
    'noircît',
    'nolisai',
    'nolisas',
    'nolisât',
    'nolisée',
    'noliser',
    'nolises',
    'nolisés',
    'nolisez',
    'nomades',
    'nombrai',
    'nombras',
    'nombrât',
    'nombrée',
    'nombrer',
    'nombres',
    'nombrés',
    'nombrez',
    'nombril',
    'nominal',
    'nommais',
    'nommait',
    'nommant',
    'nommées',
    'nomment',
    'nommera',
    'nommiez',
    'nommons',
    'nonante',
    'nonidis',
    'non-moi',
    'nopâmes',
    'nopasse',
    'nopâtes',
    'noperai',
    'noperas',
    'noperez',
    'nopions',
    'nordira',
    'normale',
    'normand',
    'normaux',
    'normées',
    'noroîts',
    'norrois',
    'nostocs',
    'notable',
    'notaire',
    'notâmes',
    'notarié',
    'notasse',
    'notâtes',
    'noterai',
    'noteras',
    'noterez',
    'notices',
    'notifia',
    'notifie',
    'notifié',
    'notions',
    'notoire',
    'notules',
    'nouâmes',
    'nouasse',
    'nouâtes',
    'nouerai',
    'noueras',
    'nouerez',
    'noueuse',
    'nougats',
    'nouille',
    'nouions',
    'noulets',
    'noumène',
    'nounous',
    'nourrie',
    'nourrir',
    'nourris',
    'nourrit',
    'nourrît',
    'nouures',
    'nouveau',
    'novâmes',
    'novasse',
    'novâtes',
    'noverai',
    'noveras',
    'noverez',
    'novices',
    'novions',
    'noyades',
    'noyâmes',
    'noyasse',
    'noyâtes',
    'noyauta',
    'noyaute',
    'noyauté',
    'noyions',
    'nuageux',
    'nuaient',
    'nuançai',
    'nuanças',
    'nuançât',
    'nuancée',
    'nuancer',
    'nuances',
    'nuancés',
    'nuancez',
    'nuasses',
    'nubiens',
    'nubiles',
    'nucelle',
    'nucléai',
    'nucléas',
    'nucléât',
    'nucléée',
    'nucléer',
    'nuclées',
    'nucléés',
    'nucléez',
    'nucléon',
    'nucléus',
    'nudisme',
    'nudiste',
    'nudités',
    'nuerais',
    'nuerait',
    'nuèrent',
    'nueriez',
    'nuerons',
    'nueront',
    'nuirais',
    'nuirait',
    'nuiriez',
    'nuirons',
    'nuiront',
    'nuisais',
    'nuisait',
    'nuisant',
    'nuisent',
    'nuisiez',
    'nuisons',
    'nullard',
    'nullité',
    'numéral',
    'numéros',
    'numerus',
    'numides',
    'nunatak',
    'nuptial',
    'nuraghe',
    'nuraghi',
    'nursery',
    'nymphal',
    'nymphéa',
    'nymphée',
    'nymphes',
    'oasiens',
    'obéîmes',
    'obéirai',
    'obéiras',
    'obéirez',
    'obéisse',
    'obéîtes',
    'obérais',
    'obérait',
    'obérant',
    'obérées',
    'obèrent',
    'obérera',
    'obériez',
    'obérons',
    'obésité',
    'objecta',
    'objecte',
    'objecté',
    'oblates',
    'oblatif',
    'obligea',
    'obligée',
    'obliger',
    'obliges',
    'obligés',
    'obligez',
    'obliqua',
    'oblique',
    'obliqué',
    'oblongs',
    'obombra',
    'obombre',
    'obombré',
    'obscène',
    'obscure',
    'obscurs',
    'obsédai',
    'obsédas',
    'obsédât',
    'obsédée',
    'obséder',
    'obsédés',
    'obsèdes',
    'obsédez',
    'observa',
    'observe',
    'observé',
    'obstiné',
    'obstrua',
    'obstrue',
    'obstrué',
    'obtenez',
    'obtenir',
    'obtenue',
    'obtenus',
    'obtiens',
    'obtient',
    'obturai',
    'obturas',
    'obturât',
    'obturée',
    'obturer',
    'obtures',
    'obturés',
    'obturez',
    'obtuses',
    'obusier',
    'obvenez',
    'obvenir',
    'obviais',
    'obviait',
    'obviant',
    'obviées',
    'obviens',
    'obvient',
    'obviera',
    'obviiez',
    'obvions',
    'ocarina',
    'occases',
    'occiput',
    'occitan',
    'occlues',
    'occluez',
    'occlura',
    'occlure',
    'occulta',
    'occulte',
    'occulté',
    'occupai',
    'occupas',
    'occupât',
    'occupée',
    'occuper',
    'occupes',
    'occupés',
    'occupez',
    'ocellée',
    'ocelles',
    'ocellés',
    'ocelots',
    'ocrâmes',
    'ocrasse',
    'ocrâtes',
    'ocrerai',
    'ocreras',
    'ocrerez',
    'ocreuse',
    'ocrions',
    'octanes',
    'octante',
    'octants',
    'octaves',
    'octavia',
    'octavie',
    'octavié',
    'octavin',
    'octidis',
    'octobre',
    'octroie',
    'octrois',
    'octroya',
    'octroyé',
    'octuors',
    'octupla',
    'octuple',
    'octuplé',
    'odieuse',
    'odorant',
    'odorats',
    'odyssée',
    'oedèmes',
    'oedipes',
    'oeillet',
    'oersted',
    'oestral',
    'oestres',
    'oestrus',
    'oeuvées',
    'oeuvrai',
    'oeuvras',
    'oeuvrât',
    'oeuvrer',
    'oeuvres',
    'oeuvrez',
    'offensa',
    'offense',
    'offensé',
    'offerte',
    'offerts',
    'offices',
    'officia',
    'officie',
    'officié',
    'offrais',
    'offrait',
    'offrant',
    'offrent',
    'offreur',
    'offriez',
    'offrira',
    'offrons',
    'ogivale',
    'ogivaux',
    'ogresse',
    'ohmique',
    'oïdiums',
    'oignons',
    'oiseaux',
    'oiselai',
    'oiselas',
    'oiselât',
    'oiselée',
    'oiseler',
    'oiselés',
    'oiselet',
    'oiselez',
    'oiselle',
    'oiseuse',
    'oisives',
    'okoumés',
    'oléates',
    'oléfine',
    'oléines',
    'oléique',
    'oléoduc',
    'oléolat',
    'olifant',
    'olivaie',
    'olivier',
    'olivine',
    'ollaire',
    'olympes',
    'ombelle',
    'ombellé',
    'ombilic',
    'ombrage',
    'ombragé',
    'ombrais',
    'ombrait',
    'ombrant',
    'ombrées',
    'ombrent',
    'ombrera',
    'ombreux',
    'ombrien',
    'ombriez',
    'ombrine',
    'ombrons',
    'omettes',
    'omettez',
    'omettra',
    'omettre',
    'omicron',
    'omirent',
    'omisses',
    'omnibus',
    'omniums',
    'onagres',
    'onciale',
    'onciaux',
    'onction',
    'ondatra',
    'ondines',
    'ondoies',
    'ondoyai',
    'ondoyas',
    'ondoyât',
    'ondoyée',
    'ondoyer',
    'ondoyés',
    'ondoyez',
    'ondulai',
    'ondulas',
    'ondulât',
    'ondulée',
    'onduler',
    'ondules',
    'ondulés',
    'ondulez',
    'onéreux',
    'onglées',
    'onglets',
    'onglier',
    'onglons',
    'onguent',
    'ongulée',
    'ongulés',
    'ont-ils',
    'onusien',
    'onzains',
    'onzième',
    'oocytes',
    'oogones',
    'oolithe',
    'oospore',
    'opacité',
    'opaline',
    'opalins',
    'opalisa',
    'opalise',
    'opalisé',
    'opaques',
    'opérais',
    'opérait',
    'opérant',
    'opérées',
    'opèrent',
    'opérera',
    'opériez',
    'opérons',
    'ophites',
    'ophiure',
    'opiaçai',
    'opiaças',
    'opiaçât',
    'opiacée',
    'opiacer',
    'opiaces',
    'opiacés',
    'opiacez',
    'opinais',
    'opinait',
    'opinant',
    'opinent',
    'opinera',
    'opiniez',
    'opinion',
    'opinons',
    'opossum',
    'oppidum',
    'opposai',
    'opposas',
    'opposât',
    'opposée',
    'opposer',
    'opposes',
    'opposés',
    'opposez',
    'opprima',
    'opprime',
    'opprimé',
    'optâmes',
    'optasse',
    'optâtes',
    'optatif',
    'opterai',
    'opteras',
    'opterez',
    'optimal',
    'optimum',
    'options',
    'optique',
    'opulent',
    'opuntia',
    'oracles',
    'orageux',
    'oraison',
    'orangea',
    'orangée',
    'oranger',
    'oranges',
    'orangés',
    'orangez',
    'orantes',
    'orateur',
    'orbitai',
    'orbital',
    'orbitas',
    'orbitât',
    'orbiter',
    'orbites',
    'orbitez',
    'orchite',
    'ordalie',
    'ordinal',
    'ordonna',
    'ordonne',
    'ordonné',
    'ordures',
    'oréades',
    'oreille',
    'orfèvre',
    'orfraie',
    'orfrois',
    'organdi',
    'organes',
    'orgasme',
    'orgeats',
    'orgelet',
    'orgueil',
    'orienta',
    'oriente',
    'orienté',
    'orients',
    'orifice',
    'origans',
    'origine',
    'orignal',
    'orillon',
    'oringua',
    'oringue',
    'oringué',
    'oripeau',
    'ormaies',
    'ormeaux',
    'ormille',
    'ormoies',
    'ornâmes',
    'ornasse',
    'ornâtes',
    'ornerai',
    'orneras',
    'ornerez',
    'ornière',
    'ornions',
    'oronges',
    'orphéon',
    'orphies',
    'orteils',
    'orthose',
    'ortolan',
    'orvales',
    'osaient',
    'osasses',
    'oscilla',
    'oscille',
    'oscillé',
    'oscules',
    'oseille',
    'oseraie',
    'oserais',
    'oserait',
    'osèrent',
    'oseriez',
    'oserons',
    'oseront',
    'osmique',
    'osmiums',
    'osmonde',
    'osmoses',
    'osséine',
    'osselet',
    'osseuse',
    'ossifia',
    'ossifie',
    'ossifié',
    'ostéite',
    'ostiaks',
    'ostiole',
    'ôtaient',
    'otalgie',
    'otaries',
    'ôtasses',
    'ôterais',
    'ôterait',
    'ôtèrent',
    'ôteriez',
    'ôterons',
    'ôteront',
    'otiques',
    'otocyon',
    'ottoman',
    'ouatais',
    'ouatait',
    'ouatant',
    'ouatées',
    'ouatent',
    'ouatera',
    'ouatiez',
    'ouatina',
    'ouatine',
    'ouatiné',
    'ouatons',
    'oubliai',
    'oublias',
    'oubliât',
    'oubliée',
    'oublier',
    'oublies',
    'oubliés',
    'oubliez',
    'ougrien',
    'ouïgour',
    'ouillai',
    'ouillas',
    'ouillât',
    'ouillée',
    'ouiller',
    'ouilles',
    'ouillés',
    'ouillez',
    'ouïrais',
    'ouïrait',
    'ouïrent',
    'ouïriez',
    'ouïrons',
    'ouïront',
    'ouïsses',
    'oukases',
    'oulémas',
    'ouragan',
    'ourdies',
    'ourdira',
    'ourlais',
    'ourlait',
    'ourlant',
    'ourlées',
    'ourlent',
    'ourlera',
    'ourlets',
    'ourlien',
    'ourliez',
    'ourlons',
    'oursins',
    'oursons',
    'outarde',
    'outilla',
    'outille',
    'outillé',
    'outlaws',
    'outputs',
    'outrage',
    'outragé',
    'outrais',
    'outrait',
    'outrant',
    'outrées',
    'outrent',
    'outrera',
    'outriez',
    'outrons',
    'ouverte',
    'ouverts',
    'ouvrage',
    'ouvragé',
    'ouvrais',
    'ouvrait',
    'ouvrant',
    'ouvreau',
    'ouvrées',
    'ouvrent',
    'ouvrera',
    'ouvreur',
    'ouvrier',
    'ouvriez',
    'ouvrira',
    'ouvroir',
    'ouvrons',
    'ovaires',
    'ovalisa',
    'ovalise',
    'ovalisé',
    'ovarien',
    'ovarite',
    'ovation',
    'ovipare',
    'ovocyte',
    'ovoïdal',
    'ovoïdes',
    'ovulais',
    'ovulait',
    'ovulant',
    'ovulent',
    'ovulera',
    'ovuliez',
    'ovulons',
    'oxacide',
    'oxalate',
    'oxfords',
    'oxydais',
    'oxydait',
    'oxydant',
    'oxydase',
    'oxydées',
    'oxydent',
    'oxydera',
    'oxydiez',
    'oxydons',
    'oxygéna',
    'oxygéné',
    'oxygène',
    'oxymels',
    'oxytons',
    'oxyures',
    'ozonées',
    'ozonisa',
    'ozonise',
    'ozonisé',
    'pacagea',
    'pacagée',
    'pacager',
    'pacages',
    'pacagés',
    'pacagez',
    'pacfung',
    'pacifia',
    'pacifie',
    'pacifié',
    'pacquai',
    'pacquas',
    'pacquât',
    'pacquée',
    'pacquer',
    'pacques',
    'pacqués',
    'pacquez',
    'pacsons',
    'pactisa',
    'pactise',
    'pactisé',
    'pactole',
    'paddock',
    'padoues',
    'paellas',
    'pagaies',
    'pagaïes',
    'pagayai',
    'pagayas',
    'pagayât',
    'pagayer',
    'pagayes',
    'pagayez',
    'pageais',
    'pageait',
    'pageant',
    'pageons',
    'pageota',
    'pageote',
    'pageoté',
    'pageots',
    'pagerai',
    'pageras',
    'pagerez',
    'paginai',
    'paginas',
    'paginât',
    'paginée',
    'paginer',
    'pagines',
    'paginés',
    'paginez',
    'pagions',
    'pagnota',
    'pagnote',
    'pagnoté',
    'pagodes',
    'pagodon',
    'pagures',
    'païenne',
    'paierai',
    'paieras',
    'paierez',
    'paierie',
    'paillai',
    'paillas',
    'paillât',
    'paillée',
    'pailler',
    'pailles',
    'paillés',
    'paillet',
    'paillez',
    'paillis',
    'paillon',
    'paillot',
    'pairies',
    'pairles',
    'paisses',
    'paissez',
    'paîtrai',
    'paîtras',
    'paîtrez',
    'pajotai',
    'pajotas',
    'pajotât',
    'pajotée',
    'pajoter',
    'pajotes',
    'pajotés',
    'pajotez',
    'palabra',
    'palabre',
    'palabré',
    'palaces',
    'paladin',
    'palatal',
    'palatin',
    'palémon',
    'paleron',
    'paletot',
    'paletta',
    'palette',
    'paletté',
    'pâleurs',
    'palière',
    'paliers',
    'pâlîmes',
    'pâlirai',
    'pâliras',
    'pâlirez',
    'palissa',
    'palisse',
    'palissé',
    'pâlisse',
    'pâlîtes',
    'paliure',
    'palléal',
    'palliai',
    'pallias',
    'palliât',
    'palliée',
    'pallier',
    'pallies',
    'palliés',
    'palliez',
    'pallium',
    'palmais',
    'palmait',
    'palmant',
    'palmées',
    'palment',
    'palmera',
    'palmier',
    'palmiez',
    'palmite',
    'palmons',
    'palmure',
    'palombe',
    'palotai',
    'palotas',
    'palotât',
    'palotée',
    'paloter',
    'palotes',
    'palotés',
    'palotez',
    'pâlotte',
    'palpais',
    'palpait',
    'palpant',
    'palpées',
    'palpent',
    'palpera',
    'palpeur',
    'palpiez',
    'palpita',
    'palpite',
    'palpité',
    'palpons',
    'paluche',
    'paludes',
    'pâmâmes',
    'pâmasse',
    'pâmâtes',
    'pâmerai',
    'pâmeras',
    'pâmerez',
    'pâmions',
    'pampéro',
    'pampres',
    'panacée',
    'panacha',
    'panache',
    'panaché',
    'panades',
    'panamas',
    'panâmes',
    'panarde',
    'panards',
    'panaris',
    'panasse',
    'panâtes',
    'pandits',
    'pandore',
    'panerai',
    'paneras',
    'panerée',
    'panerez',
    'paneton',
    'panière',
    'paniers',
    'panifia',
    'panifie',
    'panifié',
    'panions',
    'paniqua',
    'panique',
    'paniqué',
    'pannais',
    'pannait',
    'pannant',
    'panneau',
    'pannées',
    'pannent',
    'pannera',
    'panniez',
    'pannons',
    'panorpe',
    'panosse',
    'pansage',
    'pansais',
    'pansait',
    'pansant',
    'pansées',
    'pansent',
    'pansera',
    'pansiez',
    'pansons',
    'pansues',
    'pantela',
    'pantelé',
    'pantène',
    'pantins',
    'pantois',
    'pantoum',
    'panures',
    'panzers',
    'paonnes',
    'papable',
    'papaïne',
    'papales',
    'papauté',
    'papaver',
    'papayer',
    'papayes',
    'papegai',
    'papesse',
    'papiers',
    'papille',
    'papions',
    'papisme',
    'papiste',
    'papotai',
    'papotas',
    'papotât',
    'papoter',
    'papotes',
    'papotez',
    'paprika',
    'papules',
    'papyrus',
    'paquets',
    'paradai',
    'paradas',
    'paradât',
    'parader',
    'parades',
    'paradez',
    'paradis',
    'parados',
    'parafai',
    'parafas',
    'parafât',
    'parafée',
    'parafer',
    'parafes',
    'parafés',
    'parafez',
    'parages',
    'parâmes',
    'parapet',
    'parapha',
    'paraphe',
    'paraphé',
    'parasol',
    'parasse',
    'parâtes',
    'parâtre',
    'parbleu',
    'parcage',
    'pardieu',
    'pardons',
    'paréage',
    'pareils',
    'parélie',
    'parente',
    'parenté',
    'parents',
    'parerai',
    'pareras',
    'parerât',
    'parères',
    'parésie',
    'paressa',
    'paresse',
    'paressé',
    'pareurs',
    'pareuse',
    'parfais',
    'parfait',
    'parfera',
    'parfila',
    'parfile',
    'parfilé',
    'parfois',
    'parfond',
    'parfont',
    'parfuma',
    'parfume',
    'parfumé',
    'parfums',
    'pariade',
    'pariage',
    'pariais',
    'pariait',
    'parians',
    'pariant',
    'pariées',
    'parient',
    'pariera',
    'parieur',
    'parigot',
    'pariiez',
    'parions',
    'parisis',
    'parités',
    'parjura',
    'parjure',
    'parjuré',
    'parking',
    'parlais',
    'parlait',
    'parlant',
    'parlées',
    'parlent',
    'parlera',
    'parleur',
    'parliez',
    'parloir',
    'parlons',
    'parlota',
    'parlote',
    'parloté',
    'parodia',
    'parodie',
    'parodié',
    'paroles',
    'parquai',
    'parquas',
    'parquât',
    'parquée',
    'parquer',
    'parques',
    'parqués',
    'parquet',
    'parquez',
    'parrain',
    'parsecs',
    'parsema',
    'parsemé',
    'parsème',
    'parsies',
    'partage',
    'partagé',
    'partais',
    'partait',
    'partant',
    'partent',
    'partial',
    'partiel',
    'parties',
    'partiez',
    'partira',
    'partons',
    'partout',
    'parulie',
    'parûmes',
    'parures',
    'parusse',
    'parûtes',
    'parvenu',
    'parvins',
    'parvint',
    'parvînt',
    'pascale',
    'pascaux',
    'pasquin',
    'passade',
    'passage',
    'passais',
    'passait',
    'passant',
    'passées',
    'passent',
    'passera',
    'passeur',
    'passiez',
    'passifs',
    'passion',
    'passive',
    'passons',
    'pastels',
    'pasteur',
    'patache',
    'pâtâmes',
    'patards',
    'pâtasse',
    'patates',
    'pâtâtes',
    'pataude',
    'patauds',
    'patauge',
    'pataugé',
    'patelin',
    'patelle',
    'patènes',
    'patenta',
    'patente',
    'patenté',
    'patents',
    'pâterai',
    'pâteras',
    'patères',
    'pâterez',
    'paterne',
    'pâteuse',
    'patient',
    'pâtîmes',
    'patinai',
    'patinas',
    'patinât',
    'patinée',
    'patiner',
    'patines',
    'patinés',
    'patinez',
    'pâtions',
    'pâtirai',
    'pâtiras',
    'pâtirez',
    'pâtissa',
    'pâtisse',
    'pâtissé',
    'pâtîtes',
    'patoche',
    'patoisa',
    'patoise',
    'patoisé',
    'patrice',
    'patries',
    'patrons',
    'pattais',
    'pattait',
    'pattant',
    'pattées',
    'pattent',
    'pattera',
    'pattern',
    'pattiez',
    'pattons',
    'pattues',
    'pâturai',
    'pâturas',
    'pâturât',
    'pâturée',
    'pâturer',
    'pâtures',
    'pâturés',
    'pâturez',
    'pâturin',
    'paturon',
    'pâturon',
    'paulien',
    'paumais',
    'paumait',
    'paumant',
    'paumées',
    'paument',
    'paumera',
    'paumier',
    'paumiez',
    'paumoie',
    'paumons',
    'paumoya',
    'paumoyé',
    'paumure',
    'pausais',
    'pausait',
    'pausant',
    'pausent',
    'pausera',
    'pausiez',
    'pausons',
    'pauvres',
    'pauvret',
    'pavages',
    'pavâmes',
    'pavanai',
    'pavanas',
    'pavanât',
    'pavanée',
    'pavaner',
    'pavanes',
    'pavanés',
    'pavanez',
    'pavasse',
    'pavâtes',
    'paverai',
    'paveras',
    'paverez',
    'paveurs',
    'pavions',
    'pavoisa',
    'pavoise',
    'pavoisé',
    'payable',
    'payâmes',
    'payante',
    'payants',
    'payasse',
    'payâtes',
    'payerai',
    'payeras',
    'payerez',
    'payeurs',
    'payeuse',
    'payions',
    'paysage',
    'paysans',
    'péageer',
    'peaussa',
    'peausse',
    'peaussé',
    'pébrine',
    'pébrocs',
    'pécaïre',
    'pécaris',
    'péchais',
    'pêchais',
    'péchait',
    'pêchait',
    'péchant',
    'pêchant',
    'pêchées',
    'pèchent',
    'pêchent',
    'péchera',
    'pêchera',
    'pêchers',
    'pécheur',
    'pêcheur',
    'pêcheux',
    'péchiez',
    'pêchiez',
    'péchons',
    'pêchons',
    'péclota',
    'péclote',
    'pécloté',
    'pecnots',
    'pécores',
    'pectens',
    'pectine',
    'pectiné',
    'péculat',
    'pécules',
    'pédalai',
    'pédalas',
    'pédalât',
    'pédaler',
    'pédales',
    'pédalez',
    'Pédalos',
    'pédante',
    'pédants',
    'pédieux',
    'peeling',
    'pégases',
    'peignai',
    'peignas',
    'peignât',
    'peignée',
    'peigner',
    'peignes',
    'peignés',
    'peignez',
    'peignis',
    'peignit',
    'peignît',
    'peilles',
    'peinais',
    'peinait',
    'peinant',
    'peinard',
    'peindra',
    'peindre',
    'peinées',
    'peinent',
    'peinera',
    'peiniez',
    'peinons',
    'peintes',
    'peintre',
    'pékinée',
    'pékinés',
    'pelades',
    'pelages',
    'pelagos',
    'pelâmes',
    'pelards',
    'pelasse',
    'pelâtes',
    'peléens',
    'péléens',
    'pèlerai',
    'pèleras',
    'pèlerez',
    'pèlerin',
    'péliade',
    'pélican',
    'pelions',
    'pelisse',
    'pelleta',
    'pelleté',
    'pellets',
    'pelotai',
    'pelotas',
    'pelotât',
    'pelotée',
    'peloter',
    'pelotes',
    'pelotés',
    'pelotez',
    'peloton',
    'pelouse',
    'peltées',
    'pelucha',
    'peluche',
    'peluché',
    'pelures',
    'pelvien',
    'pénales',
    'penalty',
    'pénarde',
    'pénards',
    'pénates',
    'penaude',
    'penauds',
    'penchai',
    'penchas',
    'penchât',
    'penchée',
    'pencher',
    'penches',
    'penchés',
    'penchez',
    'pendage',
    'pendais',
    'pendait',
    'pendant',
    'pendard',
    'pendent',
    'pendiez',
    'pendoir',
    'pendons',
    'pendrai',
    'pendras',
    'pendrez',
    'pendues',
    'pendula',
    'pendule',
    'pendulé',
    'pénétra',
    'pénétré',
    'pénètre',
    'pénible',
    'péniche',
    'péniens',
    'pennage',
    'pennées',
    'pennies',
    'pennons',
    'pensais',
    'pensait',
    'pensant',
    'pensées',
    'pensent',
    'pensera',
    'penseur',
    'pensiez',
    'pensifs',
    'pension',
    'pensive',
    'pensons',
    'pensums',
    'pentane',
    'pentode',
    'pentose',
    'pentues',
    'penture',
    'pénurie',
    'péottes',
    'pépères',
    'pépètes',
    'pépiais',
    'pépiait',
    'pépiant',
    'pépient',
    'pépiera',
    'pépiiez',
    'pépions',
    'pépites',
    'péplums',
    'pepsine',
    'peptide',
    'peptone',
    'péquins',
    'perçage',
    'perçais',
    'perçait',
    'percale',
    'perçant',
    'percées',
    'percent',
    'percept',
    'percera',
    'perceur',
    'perchai',
    'perchas',
    'perchât',
    'perchée',
    'percher',
    'perches',
    'perchés',
    'perchez',
    'perchis',
    'perciez',
    'perclus',
    'perçois',
    'perçoit',
    'perçons',
    'perçues',
    'percuta',
    'percute',
    'percuté',
    'perdais',
    'perdait',
    'perdant',
    'perdent',
    'perdiez',
    'perdons',
    'perdrai',
    'perdras',
    'perdrez',
    'perdrix',
    'perdues',
    'perdura',
    'perdure',
    'perduré',
    'pérenne',
    'perfide',
    'perfora',
    'perfore',
    'perforé',
    'perfusa',
    'perfuse',
    'perfusé',
    'pergola',
    'péridot',
    'périgée',
    'périmai',
    'périmas',
    'périmât',
    'périmée',
    'périmer',
    'périmes',
    'périmés',
    'pérîmes',
    'périmez',
    'périnée',
    'période',
    'périple',
    'périrai',
    'périras',
    'périrez',
    'périsse',
    'pérîtes',
    'perlais',
    'perlait',
    'perlant',
    'perlées',
    'perlent',
    'perlera',
    'perlier',
    'perliez',
    'perlons',
    'perlots',
    'permets',
    'permien',
    'permise',
    'permuta',
    'permute',
    'permuté',
    'péronés',
    'pérorai',
    'péroras',
    'pérorât',
    'pérorer',
    'pérores',
    'pérorez',
    'perrons',
    'persane',
    'persans',
    'persels',
    'persils',
    'pertuis',
    'pervers',
    'pesades',
    'pesages',
    'pesâmes',
    'pesante',
    'pesants',
    'pesasse',
    'pesâtes',
    'pèserai',
    'pèseras',
    'pèserez',
    'pesetas',
    'pesette',
    'peseurs',
    'peseuse',
    'pesions',
    'pestais',
    'pestait',
    'pestant',
    'pestent',
    'pestera',
    'pesteux',
    'pestiez',
    'pestons',
    'pétales',
    'pétâmes',
    'pétante',
    'pétants',
    'pétarda',
    'pétarde',
    'pétardé',
    'pétards',
    'pétases',
    'pétasse',
    'pétâtes',
    'péterai',
    'péteras',
    'péterez',
    'péteurs',
    'péteuse',
    'pétilla',
    'pétille',
    'pétillé',
    'pétiole',
    'pétiolé',
    'pétions',
    'petiote',
    'petiots',
    'petites',
    'pétoche',
    'pétoire',
    'pétrées',
    'pétrels',
    'pétreux',
    'pétries',
    'pétrins',
    'pétrira',
    'pétrole',
    'pétunai',
    'pétunas',
    'pétunât',
    'pétuner',
    'pétunes',
    'pétunez',
    'pétunia',
    'peulven',
    'peuplai',
    'peuplas',
    'peuplât',
    'peuplée',
    'peupler',
    'peuples',
    'peuplés',
    'peuplez',
    'peureux',
    'peut-il',
    'peuvent',
    'peyotls',
    'pézizes',
    'pfennig',
    'phalène',
    'phalère',
    'phallus',
    'phanère',
    'phanies',
    'pharaon',
    'pharynx',
    'phasmes',
    'phénate',
    'phénols',
    'philtre',
    'phobies',
    'phocéen',
    'phoenix',
    'pholade',
    'phonème',
    'phonies',
    'phoques',
    'photons',
    'phrasai',
    'phrasas',
    'phrasât',
    'phrasée',
    'phraser',
    'phrases',
    'phrasés',
    'phrasez',
    'phtisie',
    'phyllie',
    'phylums',
    'piaffai',
    'piaffas',
    'piaffât',
    'piaffer',
    'piaffes',
    'piaffez',
    'piailla',
    'piaille',
    'piaillé',
    'pianota',
    'pianote',
    'pianoté',
    'piastre',
    'piaulai',
    'piaulas',
    'piaulât',
    'piauler',
    'piaules',
    'piaulez',
    'pibales',
    'pibrock',
    'picador',
    'picages',
    'picarde',
    'picards',
    'picarel',
    'piccolo',
    'pichets',
    'pickles',
    'pick-up',
    'picolai',
    'picolas',
    'picolât',
    'picolée',
    'picoler',
    'picoles',
    'picolés',
    'picolez',
    'picolos',
    'picorai',
    'picoras',
    'picorât',
    'picorée',
    'picorer',
    'picores',
    'picorés',
    'picorez',
    'picotai',
    'picotas',
    'picotât',
    'picotée',
    'picoter',
    'picotes',
    'picotés',
    'picotez',
    'picotin',
    'picpoul',
    'picrate',
    'piéfort',
    'piégeai',
    'piégeas',
    'piégeât',
    'piégées',
    'piègent',
    'piégera',
    'piégeur',
    'piégiez',
    'piémont',
    'piéride',
    'pierrai',
    'pierras',
    'pierrât',
    'pierrée',
    'pierrer',
    'pierres',
    'pierrés',
    'pierrez',
    'pierrot',
    'piétais',
    'piétait',
    'piétant',
    'piétées',
    'piètent',
    'piétera',
    'piétiez',
    'piétina',
    'piétine',
    'piétiné',
    'piétins',
    'piétons',
    'piètres',
    'pieuses',
    'pieutai',
    'pieutas',
    'pieutât',
    'pieutée',
    'pieuter',
    'pieutes',
    'pieutés',
    'pieutez',
    'pieuvre',
    'pifâmes',
    'pifasse',
    'pifâtes',
    'piferai',
    'piferas',
    'piferez',
    'piffais',
    'piffait',
    'piffant',
    'piffées',
    'piffent',
    'piffera',
    'piffiez',
    'piffons',
    'pifions',
    'pigeais',
    'pigeait',
    'pigeant',
    'pigeons',
    'pigerai',
    'pigeras',
    'pigerez',
    'pigions',
    'pigiste',
    'pigment',
    'pignada',
    'pignade',
    'pignons',
    'pignouf',
    'pilages',
    'pilaire',
    'pilâmes',
    'pilasse',
    'pilâtes',
    'pilerai',
    'pileras',
    'pilerez',
    'pileurs',
    'pileuse',
    'piliers',
    'pilions',
    'pillage',
    'pillais',
    'pillait',
    'pillant',
    'pillard',
    'pillées',
    'pillent',
    'pillera',
    'pilleur',
    'pilleux',
    'pilliez',
    'pillons',
    'pilonna',
    'pilonne',
    'pilonné',
    'piloris',
    'pilotai',
    'pilotas',
    'pilotât',
    'pilotée',
    'piloter',
    'pilotes',
    'pilotés',
    'pilotez',
    'pilotin',
    'pilotis',
    'pilules',
    'pimenta',
    'pimente',
    'pimenté',
    'piments',
    'pimpant',
    'pinacle',
    'pinards',
    'pinasse',
    'pinçais',
    'pinçait',
    'pinçant',
    'pinceau',
    'pincées',
    'pincent',
    'pincera',
    'pinciez',
    'pinçons',
    'pinéale',
    'pineaux',
    'pinéaux',
    'pinèdes',
    'pingres',
    'pinière',
    'pinnule',
    'pin-pon',
    'pinsons',
    'pintade',
    'pintais',
    'pintait',
    'pintant',
    'pintées',
    'pintent',
    'pintera',
    'pintiez',
    'pintons',
    'piochai',
    'piochas',
    'piochât',
    'piochée',
    'piocher',
    'pioches',
    'piochés',
    'piochez',
    'piolets',
    'pionçai',
    'pionças',
    'pionçât',
    'pioncer',
    'pionces',
    'pioncez',
    'pionnai',
    'pionnas',
    'pionnât',
    'pionner',
    'pionnes',
    'pionnez',
    'pipâmes',
    'pipasse',
    'pipâtes',
    'pipeaux',
    'pipelet',
    'piperai',
    'piperas',
    'piperez',
    'piperie',
    'pipette',
    'pipeurs',
    'pipeuse',
    'pipière',
    'pipiers',
    'pipions',
    'piquage',
    'piquais',
    'piquait',
    'piquant',
    'piquées',
    'piquent',
    'piquera',
    'piqueta',
    'piqueté',
    'piquets',
    'piqueur',
    'piqueux',
    'piquier',
    'piquiez',
    'piquons',
    'piqûres',
    'piranha',
    'piratai',
    'piratas',
    'piratât',
    'pirater',
    'pirates',
    'piratez',
    'pirayas',
    'pirogue',
    'pirojki',
    'piroles',
    'piscine',
    'pissais',
    'pissait',
    'pissant',
    'pissats',
    'pissées',
    'pissent',
    'pissera',
    'pisseur',
    'pisseux',
    'pissiez',
    'pissoir',
    'pissons',
    'pistage',
    'pistais',
    'pistait',
    'pistant',
    'pistard',
    'pistées',
    'pistent',
    'pistera',
    'pisteur',
    'pistiez',
    'pistils',
    'pistole',
    'pistons',
    'pistous',
    'pitance',
    'piteuse',
    'pitonna',
    'pitonne',
    'pitonné',
    'pituite',
    'piverts',
    'pivoine',
    'pivotai',
    'pivotas',
    'pivotât',
    'pivotée',
    'pivoter',
    'pivotes',
    'pivotés',
    'pivotez',
    'placage',
    'plaçais',
    'plaçait',
    'plaçant',
    'placard',
    'placebo',
    'placées',
    'placent',
    'placera',
    'placets',
    'placeur',
    'placide',
    'placier',
    'placiez',
    'plaçons',
    'plafond',
    'plagale',
    'plagaux',
    'plagiai',
    'plagias',
    'plagiat',
    'plagiât',
    'plagiée',
    'plagier',
    'plagies',
    'plagiés',
    'plagiez',
    'plaidai',
    'plaidas',
    'plaidât',
    'plaidée',
    'plaider',
    'plaides',
    'plaidés',
    'plaidez',
    'plaigne',
    'plainai',
    'plainas',
    'plainât',
    'plainée',
    'plainer',
    'plaines',
    'plainés',
    'plainez',
    'plainte',
    'plaints',
    'plairai',
    'plairas',
    'plairez',
    'plaises',
    'plaisez',
    'plaisir',
    'planage',
    'planais',
    'planait',
    'planant',
    'plancha',
    'planche',
    'planché',
    'planées',
    'planent',
    'planera',
    'planète',
    'planeur',
    'planèze',
    'planiez',
    'planoir',
    'planons',
    'planqua',
    'planque',
    'planqué',
    'plantai',
    'plantas',
    'plantât',
    'plantée',
    'planter',
    'plantes',
    'plantés',
    'plantez',
    'planton',
    'plaquai',
    'plaquas',
    'plaquât',
    'plaquée',
    'plaquer',
    'plaques',
    'plaqués',
    'plaquez',
    'plasmas',
    'plastes',
    'plastic',
    'plastie',
    'platane',
    'plateau',
    'platées',
    'platina',
    'platine',
    'platiné',
    'plâtrai',
    'plâtras',
    'plâtrât',
    'plâtrée',
    'plâtrer',
    'plâtres',
    'plâtrés',
    'plâtrez',
    'playons',
    'plébans',
    'plectre',
    'pléiade',
    'pleines',
    'plénier',
    'pleurai',
    'pleural',
    'pleuras',
    'pleurât',
    'pleurée',
    'pleurer',
    'pleures',
    'pleurés',
    'pleurez',
    'pleutre',
    'pleuvra',
    'plèvres',
    'pleyons',
    'pliable',
    'pliages',
    'pliâmes',
    'pliante',
    'pliants',
    'pliasse',
    'pliâtes',
    'plierai',
    'plieras',
    'plierez',
    'plieurs',
    'plieuse',
    'pliions',
    'plinthe',
    'plioirs',
    'pliques',
    'plissai',
    'plissas',
    'plissât',
    'plissée',
    'plisser',
    'plisses',
    'plissés',
    'plissez',
    'pliures',
    'ploient',
    'ploiera',
    'plombai',
    'plombas',
    'plombât',
    'plombée',
    'plomber',
    'plombes',
    'plombés',
    'plombez',
    'plommée',
    'plongea',
    'plongée',
    'plonger',
    'plonges',
    'plongés',
    'plongez',
    'ploquai',
    'ploquas',
    'ploquât',
    'ploquée',
    'ploquer',
    'ploques',
    'ploqués',
    'ploquez',
    'ployais',
    'ployait',
    'ployant',
    'ployées',
    'ployiez',
    'ployons',
    'pluchai',
    'pluchas',
    'pluchât',
    'plucher',
    'pluches',
    'pluchez',
    'plumage',
    'plumais',
    'plumait',
    'plumant',
    'plumard',
    'plumeau',
    'plumées',
    'plument',
    'plumera',
    'plumeté',
    'plumets',
    'plumeur',
    'plumeux',
    'plumier',
    'plumiez',
    'plumons',
    'plumule',
    'plupart',
    'plurale',
    'pluraux',
    'plurent',
    'pluriel',
    'plusses',
    'plutons',
    'pluvial',
    'pluvian',
    'pluvier',
    'pluvina',
    'pluvine',
    'pluviné',
    'pneumos',
    'pochade',
    'pochais',
    'pochait',
    'pochant',
    'pochard',
    'pochées',
    'pochent',
    'pochera',
    'pochiez',
    'pochoir',
    'pochons',
    'podagre',
    'podaire',
    'podiums',
    'podzols',
    'poecile',
    'poêlais',
    'poêlait',
    'poêlant',
    'poêlées',
    'poêlent',
    'poêlera',
    'poêliez',
    'poêlons',
    'poésies',
    'poétisa',
    'poétise',
    'poétisé',
    'pognons',
    'pogrome',
    'pogroms',
    'poignée',
    'poignes',
    'poignet',
    'poignez',
    'poignis',
    'poignit',
    'poignît',
    'poilais',
    'poilait',
    'poilant',
    'poilées',
    'poilent',
    'poilera',
    'poiliez',
    'poilons',
    'poilues',
    'poinçon',
    'poindra',
    'poindre',
    'pointai',
    'pointal',
    'pointas',
    'pointât',
    'pointée',
    'pointer',
    'pointes',
    'pointés',
    'pointez',
    'pointue',
    'pointus',
    'poireau',
    'poirier',
    'poirota',
    'poirote',
    'poiroté',
    'poisons',
    'poissai',
    'poissas',
    'poissât',
    'poissée',
    'poisser',
    'poisses',
    'poissés',
    'poissez',
    'poisson',
    'poivrai',
    'poivras',
    'poivrât',
    'poivrée',
    'poivrer',
    'poivres',
    'poivrés',
    'poivrez',
    'poivron',
    'poivrot',
    'polacre',
    'polaire',
    'polaque',
    'polders',
    'polenta',
    'poliçai',
    'poliças',
    'poliçât',
    'policée',
    'policer',
    'polices',
    'policés',
    'policez',
    'polîmes',
    'polirai',
    'poliras',
    'polirez',
    'polisse',
    'polîtes',
    'pollens',
    'polluai',
    'polluas',
    'polluât',
    'polluée',
    'polluer',
    'pollues',
    'pollués',
    'polluez',
    'poltron',
    'polypes',
    'polysoc',
    'pomélos',
    'pommada',
    'pommade',
    'pommadé',
    'pommais',
    'pommait',
    'pommant',
    'pommard',
    'pommeau',
    'pommela',
    'pommelé',
    'pomment',
    'pommera',
    'pommeté',
    'pommier',
    'pommiez',
    'pommons',
    'pompage',
    'pompais',
    'pompait',
    'pompant',
    'pompées',
    'pompent',
    'pompera',
    'pompeur',
    'pompeux',
    'pompier',
    'pompiez',
    'pompile',
    'pompons',
    'ponants',
    'ponçage',
    'ponçais',
    'ponçait',
    'ponçant',
    'ponceau',
    'poncées',
    'poncent',
    'poncera',
    'ponceur',
    'ponceux',
    'ponchos',
    'ponciez',
    'poncifs',
    'ponçons',
    'ponctua',
    'ponctue',
    'ponctué',
    'pondais',
    'pondait',
    'pondant',
    'pondent',
    'pondéra',
    'pondéré',
    'pondère',
    'pondeur',
    'pondiez',
    'pondoir',
    'pondons',
    'pondrai',
    'pondras',
    'pondrez',
    'pondues',
    'pongées',
    'pontage',
    'pontais',
    'pontait',
    'pontant',
    'pontées',
    'pontent',
    'pontera',
    'pontets',
    'pontier',
    'pontiez',
    'pontife',
    'pontils',
    'pontons',
    'poplité',
    'popotes',
    'popotin',
    'populos',
    'poquais',
    'poquait',
    'poquant',
    'poquent',
    'poquera',
    'poquets',
    'poquiez',
    'poquons',
    'porcher',
    'porches',
    'porcine',
    'porcins',
    'poreuse',
    'porions',
    'porques',
    'porreau',
    'portage',
    'portail',
    'portais',
    'portait',
    'portant',
    'portées',
    'portent',
    'portera',
    'porteur',
    'portier',
    'portiez',
    'portion',
    'portons',
    'portors',
    'portune',
    'posadas',
    'posâmes',
    'posasse',
    'posâtes',
    'poserai',
    'poseras',
    'poserez',
    'poseurs',
    'poseuse',
    'posions',
    'positif',
    'positon',
    'posséda',
    'possédé',
    'possède',
    'postage',
    'postais',
    'postait',
    'postale',
    'postant',
    'postaux',
    'postées',
    'postent',
    'postera',
    'posters',
    'postier',
    'postiez',
    'postons',
    'postula',
    'postule',
    'postulé',
    'posture',
    'potable',
    'potache',
    'potager',
    'potages',
    'potamot',
    'potards',
    'potassa',
    'potasse',
    'potassé',
    'poteaux',
    'potelée',
    'potelés',
    'potence',
    'potencé',
    'poterie',
    'poterne',
    'potiche',
    'potière',
    'potiers',
    'potinai',
    'potinas',
    'potinât',
    'potiner',
    'potines',
    'potinez',
    'potions',
    'potiron',
    'pouacre',
    'poucier',
    'pouding',
    'poudrai',
    'poudras',
    'poudrât',
    'poudrée',
    'poudrer',
    'poudres',
    'poudrés',
    'poudrez',
    'pouffai',
    'pouffas',
    'pouffât',
    'pouffer',
    'pouffes',
    'pouffez',
    'pouillé',
    'poulain',
    'poulbot',
    'poulets',
    'poulies',
    'poulina',
    'pouline',
    'pouliné',
    'pouliot',
    'poulote',
    'poulots',
    'poulpes',
    'poumons',
    'poupées',
    'poupine',
    'poupins',
    'poupons',
    'pourpra',
    'pourpre',
    'pourpré',
    'pourrai',
    'pourras',
    'pourrez',
    'pourrie',
    'pourrir',
    'pourris',
    'pourrit',
    'pourrît',
    'pourvoi',
    'pourvue',
    'pourvus',
    'pourvut',
    'pourvût',
    'poussah',
    'poussai',
    'poussas',
    'poussât',
    'poussée',
    'pousser',
    'pousses',
    'poussés',
    'poussez',
    'poussif',
    'poussin',
    'poutres',
    'poutsai',
    'poutsas',
    'poutsât',
    'poutsée',
    'poutser',
    'poutses',
    'poutsés',
    'poutsez',
    'pouture',
    'pouvais',
    'pouvait',
    'pouvant',
    'pouviez',
    'pouvoir',
    'pouvons',
    'praires',
    'prairie',
    'pralina',
    'praline',
    'praliné',
    'pralins',
    'préavis',
    'précéda',
    'précédé',
    'précède',
    'prêchai',
    'prêchas',
    'prêchât',
    'prêchée',
    'prêcher',
    'prêches',
    'prêchés',
    'prêchez',
    'précisa',
    'précise',
    'précisé',
    'précité',
    'précoce',
    'précuit',
    'prédira',
    'prédire',
    'prédise',
    'prédite',
    'prédits',
    'préfaça',
    'préface',
    'préfacé',
    'préféra',
    'préféré',
    'préfère',
    'préfets',
    'préfixa',
    'préfixe',
    'préfixé',
    'préjuge',
    'préjugé',
    'prélart',
    'prélats',
    'prélegs',
    'préleva',
    'prélevé',
    'prélève',
    'préluda',
    'prélude',
    'préludé',
    'premier',
    'prémuni',
    'prenais',
    'prenait',
    'prenant',
    'prendra',
    'prendre',
    'preneur',
    'preniez',
    'prennes',
    'prénoms',
    'prenons',
    'préoral',
    'prépaie',
    'prépara',
    'prépare',
    'préparé',
    'prépaya',
    'prépaye',
    'prépayé',
    'préposa',
    'prépose',
    'préposé',
    'prépuce',
    'présage',
    'présagé',
    'présent',
    'présida',
    'préside',
    'présidé',
    'presque',
    'pressai',
    'pressas',
    'pressât',
    'pressée',
    'presser',
    'presses',
    'pressés',
    'pressez',
    'prestes',
    'présuma',
    'présume',
    'présumé',
    'présura',
    'présure',
    'présuré',
    'prêtais',
    'prêtait',
    'prêtant',
    'prêtées',
    'prétend',
    'prêtent',
    'prêtera',
    'préteur',
    'prêteur',
    'prêtiez',
    'prêtons',
    'prêtres',
    'preuves',
    'prévale',
    'prévalu',
    'prévaut',
    'prévaux',
    'prévenu',
    'prévins',
    'prévint',
    'prévînt',
    'prévoie',
    'prévoir',
    'prévois',
    'prévoit',
    'prévôté',
    'prévôts',
    'prévues',
    'priâmes',
    'priante',
    'priants',
    'priapée',
    'priasse',
    'priâtes',
    'prierai',
    'prieras',
    'prières',
    'prierez',
    'prieure',
    'prieuré',
    'prieurs',
    'priions',
    'primage',
    'primais',
    'primait',
    'primant',
    'primate',
    'primats',
    'primées',
    'priment',
    'primera',
    'primeur',
    'primidi',
    'primiez',
    'primons',
    'princes',
    'priorat',
    'prirent',
    'prisais',
    'prisait',
    'prisant',
    'prisées',
    'prisent',
    'prisera',
    'priseur',
    'prisiez',
    'prismes',
    'prisons',
    'prisses',
    'privais',
    'privait',
    'privant',
    'privées',
    'privent',
    'privera',
    'priviez',
    'privons',
    'probant',
    'probité',
    'procéda',
    'procédé',
    'procède',
    'proches',
    'procréa',
    'procrée',
    'procréé',
    'procura',
    'procure',
    'procuré',
    'prodige',
    'produis',
    'produit',
    'profana',
    'profane',
    'profané',
    'proféra',
    'proféré',
    'profère',
    'profila',
    'profile',
    'profilé',
    'profils',
    'profita',
    'profite',
    'profité',
    'profits',
    'profond',
    'profuse',
    'progrès',
    'prohiba',
    'prohibe',
    'prohibé',
    'projeta',
    'projeté',
    'projets',
    'prolans',
    'prolixe',
    'promena',
    'promené',
    'promène',
    'promets',
    'promeus',
    'promeut',
    'promise',
    'prompte',
    'prompts',
    'prônais',
    'prônait',
    'prônant',
    'pronaos',
    'prônées',
    'prônent',
    'prônera',
    'prôneur',
    'prôniez',
    'pronoms',
    'prônons',
    'propage',
    'propagé',
    'propane',
    'propène',
    'propice',
    'proposa',
    'propose',
    'proposé',
    'propres',
    'propret',
    'proprio',
    'prorata',
    'proroge',
    'prorogé',
    'prostré',
    'protase',
    'protées',
    'protégé',
    'protège',
    'protèle',
    'protêts',
    'protide',
    'protons',
    'prouvai',
    'prouvas',
    'prouvât',
    'prouvée',
    'prouver',
    'prouves',
    'prouvés',
    'prouvez',
    'provenu',
    'provins',
    'provint',
    'provînt',
    'proyers',
    'prudent',
    'pruines',
    'pruneau',
    'prunier',
    'prurigo',
    'prurits',
    'prytane',
    'psaumes',
    'pschent',
    'psoques',
    'psychés',
    'psychès',
    'puaient',
    'puantes',
    'puasses',
    'pubères',
    'puberté',
    'pubiens',
    'publiai',
    'publias',
    'publiât',
    'publics',
    'publiée',
    'publier',
    'publies',
    'publiés',
    'publiez',
    'puceaux',
    'puceron',
    'pucheux',
    'puciers',
    'pudding',
    'pudeurs',
    'pudique',
    'puerais',
    'puerait',
    'puèrent',
    'pueriez',
    'puérile',
    'puérils',
    'puerons',
    'pueront',
    'puffins',
    'pugilat',
    'pugnace',
    'puînées',
    'puisage',
    'puisais',
    'puisait',
    'puisant',
    'puisard',
    'puisées',
    'puisent',
    'puisera',
    'puisiez',
    'puisons',
    'puisque',
    'puisses',
    'pullman',
    'pullula',
    'pullule',
    'pullulé',
    'pulpeux',
    'pulques',
    'pulsais',
    'pulsait',
    'pulsant',
    'pulsars',
    'pulsées',
    'pulsent',
    'pulsera',
    'pulsiez',
    'pulsion',
    'pulsons',
    'pultacé',
    'punaisa',
    'punaise',
    'punaisé',
    'punîmes',
    'punique',
    'punirai',
    'puniras',
    'punirez',
    'punisse',
    'punîtes',
    'punitif',
    'pupazzi',
    'pupazzo',
    'pupille',
    'pupitre',
    'pureaux',
    'puretés',
    'purgeai',
    'purgeas',
    'purgeât',
    'purgées',
    'purgent',
    'purgera',
    'purgeur',
    'purgiez',
    'purifia',
    'purifie',
    'purifié',
    'purines',
    'purisme',
    'puriste',
    'purotin',
    'purpura',
    'pussent',
    'pussiez',
    'pustule',
    'putains',
    'putatif',
    'putiers',
    'putiets',
    'putride',
    'putschs',
    'puzzles',
    'pycnose',
    'pyélite',
    'pygmées',
    'pyjamas',
    'pylônes',
    'pylores',
    'pyogène',
    'pyrales',
    'pyrènes',
    'pyrexie',
    'pyrites',
    'pyrrole',
    'pyrrols',
    'pythien',
    'pythies',
    'pythons',
    'pyuries',
    'pyxides',
    'quakers',
    'qualité',
    'quantum',
    'quarrai',
    'quarras',
    'quarrât',
    'quarrée',
    'quarrer',
    'quarres',
    'quarrés',
    'quarrez',
    'quartai',
    'quartas',
    'quartât',
    'quartée',
    'quarter',
    'quartes',
    'quartés',
    'quartez',
    'quasars',
    'quassia',
    'quatuor',
    'quelles',
    'quelque',
    'quêtais',
    'quêtait',
    'quêtant',
    'quêtées',
    'quêtent',
    'quêtera',
    'quêteur',
    'quêtiez',
    'quêtons',
    'queusot',
    'queutai',
    'queutas',
    'queutât',
    'queuter',
    'queutes',
    'queutez',
    'quiches',
    'quichua',
    'quidams',
    'quiètes',
    'quignon',
    'quilles',
    'quillon',
    'quinaud',
    'quinées',
    'quinine',
    'quinoas',
    'quintal',
    'quintes',
    'quipous',
    'quirite',
    'quittai',
    'quittas',
    'quittât',
    'quittée',
    'quitter',
    'quittes',
    'quittés',
    'quittez',
    'quoique',
    'quorums',
    'quotité',
    'quottai',
    'quottas',
    'quottât',
    'quotter',
    'quottes',
    'quottez',
    'rabâcha',
    'rabâche',
    'rabâché',
    'rabanes',
    'rabanta',
    'rabante',
    'rabanté',
    'rabatte',
    'rabattu',
    'rabbins',
    'rabiota',
    'rabiote',
    'rabioté',
    'rabiots',
    'rabique',
    'râblais',
    'râblait',
    'râblant',
    'râblées',
    'râblent',
    'râblera',
    'râbliez',
    'râblons',
    'râblure',
    'rabonni',
    'rabotai',
    'rabotas',
    'rabotât',
    'rabotée',
    'raboter',
    'rabotes',
    'rabotés',
    'rabotez',
    'rabouta',
    'raboute',
    'rabouté',
    'rabroua',
    'rabroue',
    'rabroué',
    'raccord',
    'raccroc',
    'rachats',
    'racheta',
    'racheté',
    'rachète',
    'raciale',
    'raciaux',
    'racinai',
    'racinal',
    'racinas',
    'racinât',
    'racinée',
    'raciner',
    'racines',
    'racinés',
    'racinez',
    'racisme',
    'raciste',
    'rackets',
    'raclage',
    'raclais',
    'raclait',
    'raclant',
    'raclées',
    'raclent',
    'raclera',
    'racleur',
    'racliez',
    'racloir',
    'raclons',
    'raclure',
    'racolai',
    'racolas',
    'racolât',
    'racolée',
    'racoler',
    'racoles',
    'racolés',
    'racolez',
    'raconta',
    'raconte',
    'raconté',
    'racorni',
    'radâmes',
    'radasse',
    'radâtes',
    'radeaux',
    'raderai',
    'raderas',
    'raderez',
    'radeuse',
    'radiais',
    'radiait',
    'radiale',
    'radians',
    'radiant',
    'radiaux',
    'radical',
    'radiées',
    'radient',
    'radiera',
    'radieux',
    'radiiez',
    'radinai',
    'radinas',
    'radinât',
    'radinée',
    'radiner',
    'radines',
    'radinés',
    'radinez',
    'radions',
    'radiums',
    'radjahs',
    'radômes',
    'radotai',
    'radotas',
    'radotât',
    'radoter',
    'radotes',
    'radotez',
    'radouba',
    'radoube',
    'radoubé',
    'radoubs',
    'radouci',
    'rafales',
    'raffina',
    'raffine',
    'raffiné',
    'raffola',
    'raffole',
    'raffolé',
    'raffûta',
    'raffûte',
    'raffûté',
    'raffuts',
    'rafiots',
    'raflais',
    'raflait',
    'raflant',
    'raflées',
    'raflent',
    'raflera',
    'rafliez',
    'raflons',
    'rageais',
    'rageait',
    'rageant',
    'rageons',
    'ragerai',
    'rageras',
    'ragerez',
    'rageurs',
    'rageuse',
    'ragions',
    'raglans',
    'ragotai',
    'ragotas',
    'ragotât',
    'ragoter',
    'ragotes',
    'ragotez',
    'ragoûta',
    'ragoûte',
    'ragoûté',
    'ragoûts',
    'ragrafa',
    'ragrafe',
    'ragrafé',
    'ragréai',
    'ragréas',
    'ragréât',
    'ragréée',
    'ragréer',
    'ragrées',
    'ragréés',
    'ragréez',
    'raguais',
    'raguait',
    'raguant',
    'raguées',
    'raguent',
    'raguera',
    'raguiez',
    'raguons',
    'raiders',
    'raideur',
    'raidies',
    'raidira',
    'raierai',
    'raieras',
    'raierez',
    'raifort',
    'raillai',
    'raillas',
    'raillât',
    'raillée',
    'railler',
    'railles',
    'raillés',
    'raillez',
    'rainais',
    'rainait',
    'rainant',
    'rainées',
    'rainent',
    'rainera',
    'raineta',
    'raineté',
    'rainiez',
    'rainons',
    'rainura',
    'rainure',
    'rainuré',
    'raisiné',
    'raisins',
    'raisons',
    'rajeuni',
    'rajouta',
    'rajoute',
    'rajouté',
    'rajouts',
    'rajusta',
    'rajuste',
    'rajusté',
    'râlâmes',
    'râlante',
    'râlants',
    'râlasse',
    'râlâtes',
    'ralenti',
    'râlerai',
    'râleras',
    'râlerez',
    'râleurs',
    'râleuse',
    'râlions',
    'rallégé',
    'rallège',
    'ralliai',
    'rallias',
    'ralliât',
    'ralliée',
    'rallier',
    'rallies',
    'ralliés',
    'ralliez',
    'ralluma',
    'rallume',
    'rallumé',
    'rallyes',
    'ramadan',
    'ramagea',
    'ramagée',
    'ramager',
    'ramages',
    'ramagés',
    'ramagez',
    'ramâmes',
    'ramanda',
    'ramande',
    'ramandé',
    'ramarra',
    'ramarre',
    'ramarré',
    'ramassa',
    'ramasse',
    'ramassé',
    'ramâtes',
    'rambour',
    'ramdams',
    'rameaux',
    'ramenai',
    'ramenas',
    'ramenât',
    'ramenda',
    'ramende',
    'ramendé',
    'ramenée',
    'ramener',
    'ramenés',
    'ramènes',
    'ramenez',
    'ramerai',
    'rameras',
    'ramerez',
    'ramerot',
    'ramette',
    'rameurs',
    'rameuse',
    'rameuta',
    'rameute',
    'rameuté',
    'ramiers',
    'ramifia',
    'ramifie',
    'ramifié',
    'ramille',
    'ramions',
    'ramolli',
    'ramollo',
    'ramonai',
    'ramonas',
    'ramonât',
    'ramonée',
    'ramoner',
    'ramones',
    'ramonés',
    'ramonez',
    'rampais',
    'rampait',
    'rampant',
    'rampeau',
    'rampent',
    'rampera',
    'rampiez',
    'rampons',
    'ramures',
    'ranatre',
    'rancard',
    'rancart',
    'rancher',
    'ranches',
    'rancies',
    'rancios',
    'rancira',
    'rançons',
    'rancune',
    'rangeai',
    'rangeas',
    'rangeât',
    'rangées',
    'rangent',
    'rangera',
    'rangiez',
    'ranimai',
    'ranimas',
    'ranimât',
    'ranimée',
    'ranimer',
    'ranimes',
    'ranimés',
    'ranimez',
    'rapaces',
    'râpages',
    'râpâmes',
    'râpasse',
    'râpâtes',
    'râperai',
    'râperas',
    'râperez',
    'râperie',
    'râpeuse',
    'raphias',
    'rapiate',
    'rapiats',
    'rapides',
    'rapiéça',
    'rapiécé',
    'rapièce',
    'rapière',
    'rapinai',
    'rapinas',
    'rapinât',
    'rapinée',
    'rapiner',
    'rapines',
    'rapinés',
    'rapinez',
    'râpions',
    'raplati',
    'rappela',
    'rappelé',
    'rappels',
    'rapport',
    'rappris',
    'rapprit',
    'rapprît',
    'rapsode',
    'râpures',
    'raquais',
    'raquait',
    'raquant',
    'raquées',
    'raquent',
    'raquera',
    'raquiez',
    'raquons',
    'raréfia',
    'raréfie',
    'raréfié',
    'raretés',
    'rasades',
    'rasages',
    'rasâmes',
    'rasance',
    'rasante',
    'rasants',
    'rasasse',
    'rasâtes',
    'raserai',
    'raseras',
    'raserez',
    'rasette',
    'raseurs',
    'raseuse',
    'rasibus',
    'rasions',
    'rasoirs',
    'rasseye',
    'rassied',
    'rassise',
    'rassoie',
    'rassois',
    'rassoit',
    'rassura',
    'rassure',
    'rassuré',
    'ratafia',
    'ratages',
    'ratâmes',
    'ratasse',
    'ratâtes',
    'râteaux',
    'râtelai',
    'râtelas',
    'râtelât',
    'râtelée',
    'râteler',
    'râtelés',
    'râtelez',
    'râtelle',
    'raterai',
    'rateras',
    'raterez',
    'ratière',
    'ratiers',
    'ratifia',
    'ratifie',
    'ratifié',
    'ratinai',
    'ratinas',
    'ratinât',
    'ratinée',
    'ratiner',
    'ratines',
    'ratinés',
    'ratinez',
    'rations',
    'ratissa',
    'ratisse',
    'ratissé',
    'raturai',
    'raturas',
    'raturât',
    'raturée',
    'raturer',
    'ratures',
    'raturés',
    'raturez',
    'raucité',
    'rauques',
    'ravagea',
    'ravagée',
    'ravager',
    'ravages',
    'ravagés',
    'ravagez',
    'ravalai',
    'ravalas',
    'ravalât',
    'ravalée',
    'ravaler',
    'ravales',
    'ravalés',
    'ravalez',
    'ravauda',
    'ravaude',
    'ravaudé',
    'ravelin',
    'ravière',
    'raviers',
    'ravilie',
    'ravilir',
    'ravilis',
    'ravilit',
    'ravilît',
    'ravîmes',
    'ravinai',
    'ravinas',
    'ravinât',
    'ravinée',
    'raviner',
    'ravines',
    'ravinés',
    'ravinez',
    'ravioli',
    'ravirai',
    'raviras',
    'ravirez',
    'ravisai',
    'ravisas',
    'ravisât',
    'ravisée',
    'raviser',
    'ravises',
    'ravisés',
    'ravisez',
    'ravisse',
    'ravîtes',
    'ravivai',
    'ravivas',
    'ravivât',
    'ravivée',
    'raviver',
    'ravives',
    'ravivés',
    'ravivez',
    'rayages',
    'rayâmes',
    'rayasse',
    'rayâtes',
    'rayerai',
    'rayeras',
    'rayères',
    'rayerez',
    'rayions',
    'rayonna',
    'rayonne',
    'rayonné',
    'rayures',
    'razziai',
    'razzias',
    'razziât',
    'razziée',
    'razzier',
    'razzies',
    'razziés',
    'razziez',
    'réactif',
    'réadmet',
    'réadmis',
    'réadmit',
    'réadmît',
    'réagira',
    'réaient',
    'réalésa',
    'réalésé',
    'réalèse',
    'réalgar',
    'réalisa',
    'réalise',
    'réalisé',
    'réalité',
    'réanima',
    'réanime',
    'réanimé',
    'réarmai',
    'réarmas',
    'réarmât',
    'réarmée',
    'réarmer',
    'réarmes',
    'réarmés',
    'réarmez',
    'réasses',
    'rebanda',
    'rebande',
    'rebandé',
    'rebâtie',
    'rebâtir',
    'rebâtis',
    'rebâtit',
    'rebâtît',
    'rebatte',
    'rebattu',
    'rebella',
    'rebelle',
    'rebellé',
    'rebiffa',
    'rebiffe',
    'rebiffé',
    'rebiqua',
    'rebique',
    'rebiqué',
    'reboisa',
    'reboise',
    'reboisé',
    'rebondi',
    'rebonds',
    'reborda',
    'reborde',
    'rebordé',
    'rebords',
    'rebours',
    'rebouta',
    'reboute',
    'rebouté',
    'rebroda',
    'rebrode',
    'rebrodé',
    'rebrûla',
    'rebrûle',
    'rebrûlé',
    'rebutai',
    'rebutas',
    'rebutât',
    'rebutée',
    'rebuter',
    'rebutes',
    'rebutés',
    'rebutez',
    'recalai',
    'recalas',
    'recalât',
    'recalée',
    'recaler',
    'recales',
    'recalés',
    'recalez',
    'recarda',
    'recarde',
    'recardé',
    'recasai',
    'recasas',
    'recasât',
    'recasée',
    'recaser',
    'recases',
    'recasés',
    'recasez',
    'recausa',
    'recause',
    'recausé',
    'recédai',
    'recédas',
    'recédât',
    'recédée',
    'recéder',
    'recédés',
    'recèdes',
    'recédez',
    'recelai',
    'recélai',
    'recelas',
    'recélas',
    'recelât',
    'recélât',
    'recelée',
    'recélée',
    'receler',
    'recéler',
    'recelés',
    'recélés',
    'recèles',
    'recelez',
    'recélez',
    'recensa',
    'recense',
    'recensé',
    'récente',
    'récents',
    'recepai',
    'recépai',
    'recepas',
    'recépas',
    'recepât',
    'recépât',
    'recepée',
    'recépée',
    'receper',
    'recéper',
    'recepés',
    'recépés',
    'recèpes',
    'recepez',
    'recépez',
    'recette',
    'recevez',
    'recevra',
    'rechapa',
    'rechape',
    'rechapé',
    'réchaud',
    'rechuta',
    'rechute',
    'rechuté',
    'récifal',
    'récitai',
    'récital',
    'récitas',
    'récitât',
    'récitée',
    'réciter',
    'récites',
    'récités',
    'récitez',
    'réclama',
    'réclame',
    'réclamé',
    'réclina',
    'récline',
    'récliné',
    'recloua',
    'recloue',
    'recloué',
    'reclure',
    'recluse',
    'recoins',
    'reçoive',
    'recolai',
    'recolas',
    'recolât',
    'recolée',
    'recoler',
    'recoles',
    'recolés',
    'recolez',
    'recolla',
    'recolle',
    'recollé',
    'récolta',
    'récolte',
    'récolté',
    'reconnu',
    'recopia',
    'recopie',
    'recopié',
    'recorda',
    'recorde',
    'recordé',
    'records',
    'recouds',
    'recoupa',
    'recoupe',
    'recoupé',
    'recoure',
    'recours',
    'recourt',
    'recouru',
    'recouse',
    'recousu',
    'recréai',
    'récréai',
    'recréas',
    'récréas',
    'recréât',
    'récréât',
    'recréée',
    'récréée',
    'recréer',
    'récréer',
    'recrées',
    'recréés',
    'récrées',
    'récréés',
    'recréez',
    'récréez',
    'recrépi',
    'récriai',
    'récrias',
    'récriât',
    'récriée',
    'récrier',
    'récries',
    'récriés',
    'récriez',
    'récrira',
    'récrire',
    'récrite',
    'récrits',
    'récrive',
    'recrois',
    'recroît',
    'recrues',
    'recruta',
    'recrute',
    'recruté',
    'rectale',
    'rectaux',
    'recteur',
    'rection',
    'rectite',
    'rectums',
    'recueil',
    'recuira',
    'recuire',
    'recuise',
    'recuite',
    'recuits',
    'reculai',
    'reculas',
    'reculât',
    'reculée',
    'reculer',
    'recules',
    'reculés',
    'reculez',
    'reçûmes',
    'récurai',
    'récuras',
    'récurât',
    'récurée',
    'récurer',
    'récures',
    'récurés',
    'récurez',
    'récusai',
    'récusas',
    'récusât',
    'récusée',
    'récuser',
    'récuses',
    'récusés',
    'récusez',
    'reçusse',
    'reçûtes',
    'recycla',
    'recycle',
    'recyclé',
    'redéfis',
    'redéfit',
    'redéfît',
    'redenté',
    'redents',
    'redevez',
    'redevra',
    'rédigea',
    'rédigée',
    'rédiger',
    'rédiges',
    'rédigés',
    'rédigez',
    'rédimai',
    'rédimas',
    'rédimât',
    'rédimée',
    'rédimer',
    'redîmes',
    'rédimes',
    'rédimés',
    'rédimez',
    'redirai',
    'rediras',
    'redirez',
    'redises',
    'redisse',
    'redites',
    'redîtes',
    'redoive',
    'redonda',
    'redonde',
    'redondé',
    'redonna',
    'redonne',
    'redonné',
    'redorai',
    'redoras',
    'redorât',
    'redorée',
    'redorer',
    'redores',
    'redorés',
    'redorez',
    'redouta',
    'redoute',
    'redouté',
    'réduira',
    'réduire',
    'réduise',
    'réduite',
    'réduits',
    'redûmes',
    'redusse',
    'redûtes',
    'réduves',
    'réédita',
    'réédite',
    'réédité',
    'réélira',
    'réélire',
    'réélise',
    'réelles',
    'réélues',
    'réerais',
    'réerait',
    'réèrent',
    'réeriez',
    'réerons',
    'réeront',
    'refaire',
    'refaite',
    'refaits',
    'refasse',
    'refende',
    'refends',
    'refendu',
    'referai',
    'référai',
    'referas',
    'référas',
    'référât',
    'référée',
    'référer',
    'référés',
    'réfères',
    'referez',
    'référez',
    'referma',
    'referme',
    'refermé',
    'refilai',
    'refilas',
    'refilât',
    'refilée',
    'refiler',
    'refiles',
    'refilés',
    'refilez',
    'refîmes',
    'refisse',
    'refîtes',
    'refléta',
    'reflété',
    'reflète',
    'reflets',
    'réflexe',
    'refluai',
    'refluas',
    'refluât',
    'refluer',
    'reflues',
    'refluez',
    'refonde',
    'refonds',
    'refondu',
    'refonte',
    'reforge',
    'reforgé',
    'reforma',
    'réforma',
    'reforme',
    'reformé',
    'réforme',
    'réformé',
    'refoula',
    'refoule',
    'refoulé',
    'refrain',
    'refréna',
    'réfréna',
    'refréné',
    'refrène',
    'réfréné',
    'réfrène',
    'refuges',
    'réfugia',
    'réfugie',
    'réfugié',
    'refusai',
    'refusas',
    'refusât',
    'refusée',
    'refuser',
    'refuses',
    'refusés',
    'refusez',
    'réfutai',
    'réfutas',
    'réfutât',
    'réfutée',
    'réfuter',
    'réfutes',
    'réfutés',
    'réfutez',
    'regagna',
    'regagne',
    'regagné',
    'regains',
    'régalai',
    'régalas',
    'régalât',
    'régalée',
    'régaler',
    'régales',
    'régalés',
    'régalez',
    'regarda',
    'regarde',
    'regardé',
    'regards',
    'regarni',
    'régatai',
    'régatas',
    'régatât',
    'régater',
    'régates',
    'régatez',
    'régence',
    'régenta',
    'régente',
    'régenté',
    'régents',
    'reggaes',
    'regimba',
    'regimbe',
    'regimbé',
    'régimes',
    'régîmes',
    'régions',
    'régirai',
    'régiras',
    'régirez',
    'régisse',
    'régîtes',
    'réglage',
    'réglais',
    'réglait',
    'réglant',
    'réglées',
    'règlent',
    'réglera',
    'réglets',
    'régleur',
    'régliez',
    'régloir',
    'réglons',
    'réglure',
    'régnais',
    'régnait',
    'régnant',
    'règnent',
    'régnera',
    'régniez',
    'régnons',
    'regorge',
    'regorgé',
    'regrats',
    'regréai',
    'regréas',
    'regréât',
    'regréée',
    'regréer',
    'regrées',
    'regréés',
    'regréez',
    'regrets',
    'régules',
    'rehauts',
    'réifiai',
    'réifias',
    'réifiât',
    'réifiée',
    'réifier',
    'réifies',
    'réifiés',
    'réifiez',
    'réitéra',
    'réitéré',
    'réitère',
    'reîtres',
    'rejetai',
    'rejetas',
    'rejetât',
    'rejetée',
    'rejeter',
    'rejetés',
    'rejetez',
    'rejeton',
    'rejette',
    'rejoins',
    'rejoint',
    'rejouai',
    'rejouas',
    'rejouât',
    'rejouée',
    'rejouer',
    'rejoues',
    'rejoués',
    'rejouez',
    'réjouie',
    'réjouir',
    'réjouis',
    'réjouit',
    'réjouît',
    'rejugea',
    'rejugée',
    'rejuger',
    'rejuges',
    'rejugés',
    'rejugez',
    'relâcha',
    'relâche',
    'relâché',
    'relaies',
    'relança',
    'relance',
    'relancé',
    'relapse',
    'rélargi',
    'relatai',
    'relatas',
    'relatât',
    'relatée',
    'relater',
    'relates',
    'relatés',
    'relatez',
    'relatif',
    'relavai',
    'relavas',
    'relavât',
    'relavée',
    'relaver',
    'relaves',
    'relavés',
    'relavez',
    'relaxai',
    'relaxas',
    'relaxât',
    'relaxée',
    'relaxer',
    'relaxes',
    'relaxés',
    'relaxez',
    'relayai',
    'relayas',
    'relayât',
    'relayée',
    'relayer',
    'relayes',
    'relayés',
    'relayez',
    'relégua',
    'relégué',
    'relègue',
    'relents',
    'relevai',
    'relevas',
    'relevât',
    'relevée',
    'relever',
    'relevés',
    'relèves',
    'relevez',
    'reliais',
    'reliait',
    'reliant',
    'reliées',
    'reliefs',
    'relient',
    'reliera',
    'relieur',
    'reliiez',
    'relions',
    'relique',
    'relirai',
    'reliras',
    'relirez',
    'relises',
    'relisez',
    'reliure',
    'relogea',
    'relogée',
    'reloger',
    'reloges',
    'relogés',
    'relogez',
    'relouai',
    'relouas',
    'relouât',
    'relouée',
    'relouer',
    'reloues',
    'reloués',
    'relouez',
    'reluira',
    'reluire',
    'reluise',
    'reluite',
    'reluits',
    'relûmes',
    'reluqua',
    'reluque',
    'reluqué',
    'relusse',
    'relûtes',
    'remâcha',
    'remâche',
    'remâché',
    'remakes',
    'remange',
    'remangé',
    'remania',
    'remanie',
    'remanié',
    'remaria',
    'remarie',
    'remarié',
    'rembina',
    'rembine',
    'rembiné',
    'remblai',
    'remèdes',
    'remédia',
    'remédie',
    'remédié',
    'rémérés',
    'remette',
    'rémiges',
    'remîmes',
    'remisai',
    'remisas',
    'remisât',
    'remisée',
    'remiser',
    'remises',
    'remisés',
    'remisez',
    'remisse',
    'remîtes',
    'remmena',
    'remmené',
    'remmène',
    'remonta',
    'remonte',
    'remonté',
    'rémoras',
    'remorde',
    'remords',
    'remordu',
    'remouds',
    'remoule',
    'remoulu',
    'rempara',
    'rempare',
    'remparé',
    'rempart',
    'rempila',
    'rempile',
    'rempilé',
    'remplia',
    'remplie',
    'remplié',
    'remplir',
    'remplis',
    'remplit',
    'remplît',
    'remploi',
    'rempota',
    'rempote',
    'rempoté',
    'remuais',
    'remuait',
    'remuant',
    'remuées',
    'remuent',
    'remuera',
    'remugle',
    'remuiez',
    'remuons',
    'renacla',
    'renâcle',
    'renâclé',
    'rénales',
    'renarda',
    'renarde',
    'renardé',
    'renards',
    'renauda',
    'renaude',
    'renaudé',
    'rencard',
    'rencart',
    'rendais',
    'rendait',
    'rendant',
    'rendent',
    'rendiez',
    'rendons',
    'rendors',
    'rendort',
    'rendrai',
    'rendras',
    'rendrez',
    'rendues',
    'renégat',
    'reneige',
    'rénette',
    'renfila',
    'renfile',
    'renfilé',
    'renflai',
    'renflas',
    'renflât',
    'renflée',
    'renfler',
    'renfles',
    'renflés',
    'renflez',
    'renfort',
    'rengage',
    'rengagé',
    'reniais',
    'reniait',
    'reniant',
    'reniées',
    'renient',
    'reniera',
    'renifla',
    'renifle',
    'reniflé',
    'reniiez',
    'renions',
    'renomma',
    'renomme',
    'renommé',
    'renonça',
    'renonce',
    'renoncé',
    'renouai',
    'renouas',
    'renouât',
    'renouée',
    'renouer',
    'renoues',
    'renoués',
    'renouez',
    'rénovai',
    'rénovas',
    'rénovât',
    'rénovée',
    'rénover',
    'rénoves',
    'rénovés',
    'rénovez',
    'rentais',
    'rentait',
    'rentama',
    'rentame',
    'rentamé',
    'rentant',
    'rentées',
    'rentent',
    'rentera',
    'rentier',
    'rentiez',
    'rentons',
    'rentrai',
    'rentras',
    'rentrât',
    'rentrée',
    'rentrer',
    'rentres',
    'rentrés',
    'rentrez',
    'renviai',
    'renvias',
    'renviât',
    'renvida',
    'renvide',
    'renvidé',
    'renviée',
    'renvier',
    'renvies',
    'renviés',
    'renviez',
    'renvoie',
    'renvois',
    'renvoya',
    'renvoyé',
    'réopéra',
    'réopéré',
    'réopère',
    'repaies',
    'repaira',
    'repaire',
    'repairé',
    'répande',
    'répands',
    'répandu',
    'réparai',
    'réparas',
    'réparât',
    'réparée',
    'réparer',
    'répares',
    'réparés',
    'réparez',
    'reparla',
    'reparle',
    'reparlé',
    'reparte',
    'reparti',
    'réparti',
    'reparue',
    'reparus',
    'reparut',
    'reparût',
    'repassa',
    'repasse',
    'repassé',
    'repavai',
    'repavas',
    'repavât',
    'repavée',
    'repaver',
    'repaves',
    'repavés',
    'repavez',
    'repayai',
    'repayas',
    'repayât',
    'repayée',
    'repayer',
    'repayes',
    'repayés',
    'repayez',
    'repêcha',
    'repêche',
    'repêché',
    'repeins',
    'repeint',
    'repende',
    'repends',
    'rependu',
    'repensa',
    'repense',
    'repensé',
    'repente',
    'repenti',
    'repérai',
    'repéras',
    'repérât',
    'reperça',
    'reperce',
    'repercé',
    'reperde',
    'reperds',
    'reperdu',
    'repérée',
    'repérer',
    'repérés',
    'repères',
    'repérez',
    'répétai',
    'répétas',
    'répétât',
    'répétée',
    'répéter',
    'répétés',
    'répètes',
    'répétez',
    'repinça',
    'repince',
    'repincé',
    'repiqua',
    'repique',
    'repiqué',
    'replaça',
    'replace',
    'replacé',
    'replète',
    'replets',
    'repleut',
    'repliai',
    'replias',
    'repliât',
    'repliée',
    'replier',
    'replies',
    'repliés',
    'repliez',
    'reploie',
    'reploya',
    'reployé',
    'repolie',
    'repolir',
    'repolis',
    'repolit',
    'repolît',
    'réponde',
    'réponds',
    'répondu',
    'réponse',
    'reporta',
    'reporte',
    'reporté',
    'reports',
    'reposai',
    'reposas',
    'reposât',
    'reposée',
    'reposer',
    'reposes',
    'reposés',
    'reposez',
    'reprend',
    'réprima',
    'réprime',
    'réprimé',
    'reprisa',
    'reprise',
    'reprisé',
    'reptile',
    'répudia',
    'répudie',
    'répudié',
    'répugna',
    'répugne',
    'répugné',
    'repûmes',
    'repusse',
    'réputai',
    'réputas',
    'réputât',
    'réputée',
    'réputer',
    'repûtes',
    'réputes',
    'réputés',
    'réputez',
    'requéru',
    'requête',
    'requiem',
    'requins',
    'requise',
    'reroisa',
    'resalai',
    'resalas',
    'resalât',
    'resalée',
    'resaler',
    'resales',
    'resalés',
    'resalez',
    'resalie',
    'resalir',
    'resalis',
    'resalua',
    'resalue',
    'resalué',
    'rescapé',
    'rescrit',
    'réseaux',
    'résédas',
    'reséqua',
    'reséqué',
    'resèque',
    'réserva',
    'réserve',
    'réservé',
    'résidai',
    'résidas',
    'résidât',
    'résider',
    'résides',
    'résidez',
    'résidus',
    'résigna',
    'résigne',
    'résigné',
    'résilia',
    'résilie',
    'résilié',
    'résille',
    'résinai',
    'résinas',
    'résinât',
    'résinée',
    'résiner',
    'résines',
    'résinés',
    'résinez',
    'résista',
    'résiste',
    'résisté',
    'résolue',
    'résolus',
    'résolut',
    'résolût',
    'résolve',
    'résonna',
    'résonne',
    'résonné',
    'résorba',
    'résorbe',
    'résorbé',
    'résoute',
    'respect',
    'respira',
    'respire',
    'respiré',
    'ressacs',
    'ressaie',
    'ressaut',
    'ressaya',
    'ressaye',
    'ressayé',
    'ressema',
    'ressemé',
    'ressème',
    'ressens',
    'ressent',
    'ressers',
    'ressert',
    'ressors',
    'ressort',
    'ressuai',
    'ressuas',
    'ressuât',
    'ressuer',
    'ressues',
    'ressuez',
    'ressuie',
    'ressuis',
    'ressuya',
    'ressuyé',
    'restais',
    'restait',
    'restant',
    'restées',
    'restent',
    'restera',
    'restiez',
    'restons',
    'resucée',
    'résulta',
    'résulte',
    'résumai',
    'résumas',
    'résumât',
    'résumée',
    'résumer',
    'résumes',
    'résumés',
    'résumez',
    'resurgi',
    'retable',
    'rétabli',
    'rétamai',
    'rétamas',
    'rétamât',
    'rétamée',
    'rétamer',
    'rétames',
    'rétamés',
    'rétamez',
    'retapai',
    'retapas',
    'retapât',
    'retapée',
    'retaper',
    'retapes',
    'retapés',
    'retapez',
    'retarda',
    'retarde',
    'retardé',
    'retards',
    'retâtai',
    'retâtas',
    'retâtât',
    'retâtée',
    'retâter',
    'retâtes',
    'retâtés',
    'retâtez',
    'reteins',
    'reteint',
    'retende',
    'retends',
    'retendu',
    'retenez',
    'retenir',
    'retenta',
    'retente',
    'retenté',
    'retenti',
    'retenue',
    'retenus',
    'reterça',
    'reterce',
    'retercé',
    'retersa',
    'reterse',
    'retersé',
    'retiens',
    'retient',
    'rétines',
    'retirai',
    'retiras',
    'retirât',
    'retirée',
    'retirer',
    'retires',
    'retirés',
    'retirez',
    'retissa',
    'retisse',
    'retissé',
    'rétives',
    'retomba',
    'retombe',
    'retombé',
    'retonde',
    'retonds',
    'retondu',
    'retorde',
    'retords',
    'retordu',
    'retours',
    'retraça',
    'retrace',
    'retracé',
    'retraie',
    'retrais',
    'retrait',
    'rétréci',
    'réunies',
    'réunion',
    'réunira',
    'réussie',
    'réussir',
    'réussis',
    'réussit',
    'réussît',
    'revalez',
    'revalue',
    'revalus',
    'revalut',
    'revalût',
    'rêvâmes',
    'rêvassa',
    'rêvasse',
    'rêvassé',
    'rêvâtes',
    'revêche',
    'revécus',
    'revécut',
    'revécût',
    'réveils',
    'révélai',
    'révélas',
    'révélât',
    'révélée',
    'révéler',
    'révélés',
    'révèles',
    'révélez',
    'revende',
    'revends',
    'revendu',
    'revenez',
    'revenir',
    'revente',
    'revenue',
    'revenus',
    'révérai',
    'rêverai',
    'révéras',
    'rêveras',
    'révérât',
    'reverdi',
    'révérée',
    'révérer',
    'révérés',
    'révères',
    'révérez',
    'rêverez',
    'rêverie',
    'reverni',
    'reverra',
    'reversa',
    'reverse',
    'reversé',
    'revêtes',
    'revêtez',
    'revêtir',
    'revêtis',
    'revêtit',
    'revêtît',
    'revêtue',
    'revêtus',
    'rêveurs',
    'rêveuse',
    'reviens',
    'revient',
    'revîmes',
    'rêvions',
    'revirai',
    'reviras',
    'revirât',
    'revirer',
    'revires',
    'revirez',
    'révisai',
    'révisas',
    'révisât',
    'révisée',
    'réviser',
    'révises',
    'révisés',
    'révisez',
    'revissa',
    'revisse',
    'revissé',
    'revîtes',
    'revives',
    'revivez',
    'revivra',
    'revivre',
    'revoies',
    'revolai',
    'revolas',
    'revolât',
    'revoler',
    'revoles',
    'revolez',
    'révolta',
    'révolte',
    'révolté',
    'révolue',
    'révolus',
    'révoqua',
    'révoque',
    'révoqué',
    'revotai',
    'revotas',
    'revotât',
    'revotée',
    'revoter',
    'revotes',
    'revotés',
    'revotez',
    'revoulu',
    'revoyez',
    'révulsa',
    'révulse',
    'révulsé',
    'rhénane',
    'rhénans',
    'rhénium',
    'rhéteur',
    'rhétien',
    'rhinite',
    'rhizome',
    'rhodiée',
    'rhodiés',
    'rhodite',
    'rhodium',
    'rhodoïd',
    'rhombes',
    'rhumais',
    'rhumait',
    'rhumant',
    'rhumées',
    'rhument',
    'rhumera',
    'rhumiez',
    'rhumons',
    'riaient',
    'riantes',
    'ribaude',
    'ribauds',
    'riblage',
    'riblais',
    'riblait',
    'riblant',
    'riblées',
    'riblent',
    'riblera',
    'ribliez',
    'riblons',
    'riboses',
    'ribotes',
    'ribouis',
    'riboula',
    'riboule',
    'riboulé',
    'ricaine',
    'ricains',
    'ricanai',
    'ricanas',
    'ricanât',
    'ricaner',
    'ricanes',
    'ricanez',
    'riccies',
    'richard',
    'ricinée',
    'ricinés',
    'ricocha',
    'ricoche',
    'ricoché',
    'ric-rac',
    'ridages',
    'ridâmes',
    'ridasse',
    'ridâtes',
    'rideaux',
    'ridelle',
    'riderai',
    'rideras',
    'riderez',
    'ridions',
    'ridoirs',
    'ridules',
    'rieuses',
    'riffles',
    'rififis',
    'riflais',
    'riflait',
    'riflant',
    'riflard',
    'riflées',
    'riflent',
    'riflera',
    'rifliez',
    'rifloir',
    'riflons',
    'rigides',
    'rigodon',
    'rigolai',
    'rigolas',
    'rigolât',
    'rigoler',
    'rigoles',
    'rigolez',
    'rigolos',
    'rigotte',
    'rigueur',
    'rillons',
    'rimâmes',
    'rimasse',
    'rimâtes',
    'rimayes',
    'rimerai',
    'rimeras',
    'rimerez',
    'rimeurs',
    'rimeuse',
    'rimions',
    'Rimmels',
    'rinçage',
    'rinçais',
    'rinçait',
    'rinçant',
    'rinceau',
    'rincées',
    'rincent',
    'rincera',
    'rinceur',
    'rinciez',
    'rinçons',
    'rinçure',
    'ringard',
    'ripages',
    'ripâmes',
    'ripasse',
    'ripâtes',
    'ripaton',
    'riperai',
    'riperas',
    'riperez',
    'ripieno',
    'ripions',
    'Ripolin',
    'riposta',
    'riposte',
    'riposté',
    'rippers',
    'ririons',
    'risbans',
    'risette',
    'risible',
    'risotto',
    'risquai',
    'risquas',
    'risquât',
    'risquée',
    'risquer',
    'risques',
    'risqués',
    'risquez',
    'rissent',
    'rissiez',
    'rissola',
    'rissole',
    'rissolé',
    'rituels',
    'rivages',
    'rivales',
    'rivâmes',
    'rivasse',
    'rivâtes',
    'riverai',
    'riveras',
    'riverez',
    'rivetai',
    'rivetas',
    'rivetât',
    'rivetée',
    'riveter',
    'rivetés',
    'rivetez',
    'rivette',
    'riveurs',
    'riveuse',
    'rivière',
    'rivions',
    'rivoirs',
    'rivures',
    'rixdale',
    'rizerie',
    'rizière',
    'robages',
    'robâmes',
    'robasse',
    'robâtes',
    'roberai',
    'roberas',
    'roberez',
    'roberts',
    'robinet',
    'robions',
    'robuste',
    'rocades',
    'rochage',
    'rochers',
    'rochets',
    'rocheux',
    'rochier',
    'rockers',
    'rockets',
    'rococos',
    'rocouai',
    'rocouas',
    'rocouât',
    'rocouée',
    'rocouer',
    'rocoues',
    'rocoués',
    'rocouez',
    'rocquai',
    'rocquas',
    'rocquât',
    'rocquer',
    'rocques',
    'rocquez',
    'rodages',
    'rodâmes',
    'rôdâmes',
    'rodasse',
    'rôdasse',
    'rodâtes',
    'rôdâtes',
    'roderai',
    'rôderai',
    'roderas',
    'rôderas',
    'roderez',
    'rôderez',
    'rôdeurs',
    'rôdeuse',
    'rodions',
    'rôdions',
    'rodoirs',
    'rogaton',
    'rognage',
    'rognais',
    'rognait',
    'rognant',
    'rognées',
    'rognent',
    'rognera',
    'rogneur',
    'rogniez',
    'rognons',
    'rognure',
    'rogomme',
    'roguées',
    'roharts',
    'roideur',
    'roidies',
    'roidira',
    'rollier',
    'romaine',
    'romains',
    'romança',
    'romance',
    'romancé',
    'romande',
    'romands',
    'romanes',
    'romanis',
    'romanos',
    'romarin',
    'rompais',
    'rompait',
    'rompant',
    'rompent',
    'rompiez',
    'rompons',
    'romprai',
    'rompras',
    'romprez',
    'rompues',
    'ronceux',
    'ronchon',
    'roncier',
    'rondeau',
    'rondeur',
    'rondier',
    'rondies',
    'rondins',
    'rondira',
    'ronflai',
    'ronflas',
    'ronflât',
    'ronfler',
    'ronfles',
    'ronflez',
    'rongeai',
    'rongeas',
    'rongeât',
    'rongées',
    'rongent',
    'rongera',
    'rongeur',
    'rongiez',
    'ronrons',
    'roquais',
    'roquait',
    'roquant',
    'roquent',
    'roquera',
    'roquets',
    'roquiez',
    'roquons',
    'rorqual',
    'rosacée',
    'rosaces',
    'rosacés',
    'rosages',
    'rosaire',
    'rosâmes',
    'rosasse',
    'rosâtes',
    'rosâtre',
    'rosbifs',
    'roseaux',
    'roselet',
    'roséole',
    'roserai',
    'roseras',
    'roserez',
    'rosette',
    'rosière',
    'rosiers',
    'rosîmes',
    'rosions',
    'rosirai',
    'rosiras',
    'rosirez',
    'rosisse',
    'rosîtes',
    'rossais',
    'rossait',
    'rossant',
    'rossard',
    'rossées',
    'rossent',
    'rossera',
    'rossiez',
    'rossons',
    'rostral',
    'rostres',
    'rotacée',
    'rotacés',
    'rotâmes',
    'rotangs',
    'rotarys',
    'rotasse',
    'rotâtes',
    'rotatif',
    'roterai',
    'roteras',
    'roterez',
    'rôtîmes',
    'rotions',
    'rôtirai',
    'rôtiras',
    'rôtirez',
    'rôtisse',
    'rôtîtes',
    'rotonde',
    'rotules',
    'rotures',
    'rouages',
    'rouâmes',
    'rouanne',
    'rouasse',
    'rouâtes',
    'roubles',
    'rouelle',
    'rouerai',
    'roueras',
    'rouerez',
    'rouerie',
    'rouette',
    'rougets',
    'rougeur',
    'rougies',
    'rougira',
    'rouilla',
    'rouille',
    'rouillé',
    'rouîmes',
    'rouions',
    'rouirai',
    'rouiras',
    'rouirez',
    'rouisse',
    'rouîtes',
    'roulade',
    'roulage',
    'roulais',
    'roulait',
    'roulant',
    'rouleau',
    'roulées',
    'roulent',
    'roulera',
    'rouleur',
    'roulier',
    'rouliez',
    'rouloir',
    'roulons',
    'roulure',
    'roumain',
    'roupies',
    'rouquin',
    'rousses',
    'roussie',
    'roussin',
    'roussir',
    'roussis',
    'roussit',
    'roussît',
    'roustie',
    'roustir',
    'roustis',
    'roustit',
    'roustît',
    'routage',
    'routais',
    'routait',
    'routant',
    'routard',
    'routées',
    'routent',
    'routera',
    'routier',
    'routiez',
    'routine',
    'routons',
    'rouvert',
    'rouvres',
    'rouvrez',
    'rouvrir',
    'rouvris',
    'rouvrit',
    'rouvrît',
    'rouvrue',
    'rouvrus',
    'rowings',
    'royales',
    'royaume',
    'royauté',
    'ruaient',
    'ruasses',
    'rubanai',
    'rubanas',
    'rubanât',
    'rubanée',
    'rubaner',
    'rubanes',
    'rubanés',
    'rubanez',
    'rubéfia',
    'rubéfie',
    'rubéfié',
    'rubéole',
    'rubiacé',
    'rubican',
    'ruchais',
    'ruchait',
    'ruchant',
    'ruchées',
    'ruchent',
    'ruchera',
    'ruchers',
    'ruchiez',
    'ruchons',
    'rudenta',
    'rudente',
    'rudenté',
    'rudéral',
    'rudesse',
    'rudoies',
    'rudoyai',
    'rudoyas',
    'rudoyât',
    'rudoyée',
    'rudoyer',
    'rudoyés',
    'rudoyez',
    'ruelles',
    'ruerais',
    'ruerait',
    'ruèrent',
    'rueriez',
    'ruerons',
    'rueront',
    'ruffian',
    'rufians',
    'rugîmes',
    'rugirai',
    'rugiras',
    'rugirez',
    'rugisse',
    'rugîtes',
    'rugueux',
    'ruilais',
    'ruilait',
    'ruilant',
    'ruilées',
    'ruilent',
    'ruilera',
    'ruiliez',
    'ruilons',
    'ruinais',
    'ruinait',
    'ruinant',
    'ruinées',
    'ruinent',
    'ruinera',
    'ruineux',
    'ruiniez',
    'ruinons',
    'ruinure',
    'rumeurs',
    'ruminai',
    'ruminas',
    'ruminât',
    'ruminée',
    'ruminer',
    'rumines',
    'ruminés',
    'ruminez',
    'rupinai',
    'rupinas',
    'rupinât',
    'rupiner',
    'rupines',
    'rupinez',
    'rupteur',
    'rupture',
    'rurales',
    'rusâmes',
    'rusasse',
    'rusâtes',
    'ruserai',
    'ruseras',
    'ruserez',
    'rusions',
    'russule',
    'rustaud',
    'rustine',
    'rustres',
    'rutilai',
    'rutilas',
    'rutilât',
    'rutiler',
    'rutiles',
    'rutilez',
    'rythmai',
    'rythmas',
    'rythmât',
    'rythmée',
    'rythmer',
    'rythmes',
    'rythmés',
    'rythmez',
    'sabayon',
    'sabbats',
    'sabéens',
    'sabelle',
    'sabines',
    'sablage',
    'sablais',
    'sablait',
    'sablant',
    'sablées',
    'sablent',
    'sablera',
    'sableur',
    'sableux',
    'sablier',
    'sabliez',
    'sablons',
    'saborda',
    'saborde',
    'sabordé',
    'sabords',
    'sabotai',
    'sabotas',
    'sabotât',
    'sabotée',
    'saboter',
    'sabotes',
    'sabotés',
    'sabotez',
    'saboula',
    'saboule',
    'saboulé',
    'sabrage',
    'sabrais',
    'sabrait',
    'sabraît',
    'sabrant',
    'sabrées',
    'sabrent',
    'sabrera',
    'sabreur',
    'sabriez',
    'sabrons',
    'saburre',
    'sacagna',
    'sacagne',
    'sacagné',
    'saccada',
    'saccade',
    'saccadé',
    'saccage',
    'saccagé',
    'saccule',
    'sachant',
    'sachems',
    'sachent',
    'sachets',
    'sachiez',
    'sachons',
    'sacoche',
    'sacomes',
    'sacquai',
    'sacquas',
    'sacquât',
    'sacquée',
    'sacquer',
    'sacques',
    'sacqués',
    'sacquez',
    'sacrais',
    'sacrait',
    'sacrale',
    'sacrant',
    'sacraux',
    'sacrées',
    'sacrent',
    'sacrera',
    'sacrets',
    'sacriez',
    'sacrons',
    'sacrums',
    'sadique',
    'sadisme',
    'safaris',
    'safrana',
    'safrane',
    'safrané',
    'safrans',
    'sagaces',
    'sagaies',
    'sagesse',
    'sagette',
    'sagitté',
    'sagouin',
    'saignai',
    'saignas',
    'saignât',
    'saignée',
    'saigner',
    'saignes',
    'saignés',
    'saignez',
    'sailles',
    'saillez',
    'saillie',
    'saillir',
    'saillis',
    'saillit',
    'saillît',
    'saïmiri',
    'saintes',
    'saisies',
    'saisine',
    'saisira',
    'saisons',
    'salaces',
    'salades',
    'salages',
    'salaire',
    'salâmes',
    'salamis',
    'salaria',
    'salarie',
    'salarié',
    'salasse',
    'salâtes',
    'salauds',
    'salerai',
    'saleras',
    'salerez',
    'saleron',
    'saletés',
    'saleurs',
    'saleuse',
    'saliens',
    'salière',
    'salifia',
    'salifie',
    'salifié',
    'salîmes',
    'salines',
    'salions',
    'salique',
    'salirai',
    'saliras',
    'salirez',
    'salisse',
    'salîtes',
    'salivai',
    'salivas',
    'salivât',
    'saliver',
    'salives',
    'salivez',
    'saloirs',
    'saloons',
    'salopai',
    'salopas',
    'salopât',
    'salopée',
    'saloper',
    'salopes',
    'salopés',
    'salopez',
    'saluais',
    'saluait',
    'saluant',
    'salubre',
    'saluées',
    'saluent',
    'saluera',
    'saluiez',
    'saluons',
    'salures',
    'samares',
    'samedis',
    'samovar',
    'sampang',
    'sampans',
    'sampots',
    'samurai',
    'sanctus',
    'sandale',
    'sandjak',
    'Sandows',
    'sandres',
    'sanglai',
    'sanglas',
    'sanglât',
    'sanglée',
    'sangler',
    'sangles',
    'sanglés',
    'sanglez',
    'sanglot',
    'sangria',
    'sangsue',
    'sanguin',
    'sanicle',
    'sanieux',
    'santals',
    'santons',
    'saoulai',
    'saoulas',
    'saoulât',
    'saoulée',
    'saouler',
    'saoules',
    'saoulés',
    'saoulez',
    'sapajou',
    'sapâmes',
    'sapasse',
    'sapâtes',
    'sapèque',
    'saperai',
    'saperas',
    'saperde',
    'saperez',
    'sapeurs',
    'saphène',
    'saphirs',
    'sapides',
    'sapines',
    'sapions',
    'saquais',
    'saquait',
    'saquant',
    'saquées',
    'saquent',
    'saquera',
    'saquiez',
    'saquons',
    'sarclai',
    'sarclas',
    'sarclât',
    'sarclée',
    'sarcler',
    'sarcles',
    'sarclés',
    'sarclez',
    'sarcome',
    'sardane',
    'sardine',
    'sarigue',
    'sarisse',
    'sarment',
    'sarongs',
    'sarraus',
    'sassais',
    'sassait',
    'sassant',
    'sassées',
    'sassent',
    'sassera',
    'sasseur',
    'sassiez',
    'sassons',
    'satanai',
    'satanas',
    'satanât',
    'satanée',
    'sataner',
    'satanes',
    'satanés',
    'satanez',
    'satiété',
    'satinai',
    'satinas',
    'satinât',
    'satinée',
    'satiner',
    'satines',
    'satinés',
    'satinez',
    'satires',
    'satonna',
    'satonne',
    'satonné',
    'satrape',
    'saturai',
    'saturas',
    'saturât',
    'saturée',
    'saturer',
    'satures',
    'saturés',
    'saturez',
    'saturne',
    'satyres',
    'sauçais',
    'sauçait',
    'sauçant',
    'saucées',
    'saucent',
    'saucera',
    'saucier',
    'sauciez',
    'sauçons',
    'saulaie',
    'saulées',
    'saumoné',
    'saumons',
    'saumura',
    'saumure',
    'saumuré',
    'saunage',
    'saunais',
    'saunait',
    'saunant',
    'saunent',
    'saunera',
    'saunier',
    'sauniez',
    'saunons',
    'saurais',
    'saurait',
    'saurant',
    'saurées',
    'saurent',
    'saurera',
    'saurien',
    'sauries',
    'sauriez',
    'saurins',
    'saurira',
    'saurons',
    'sauront',
    'sautais',
    'sautait',
    'sautant',
    'sautées',
    'sautent',
    'sautera',
    'sauteur',
    'sautiez',
    'sautoir',
    'sautons',
    'sauvage',
    'sauvais',
    'sauvait',
    'sauvant',
    'sauvées',
    'sauvent',
    'sauvera',
    'sauveté',
    'sauveur',
    'sauviez',
    'sauvons',
    'savanes',
    'savante',
    'savants',
    'savarin',
    'savarts',
    'savates',
    'saveurs',
    'savions',
    'savonna',
    'savonne',
    'savonné',
    'savoura',
    'savoure',
    'savouré',
    'saxhorn',
    'saxonne',
    'saynète',
    'scaldes',
    'scalène',
    'scalpai',
    'scalpas',
    'scalpât',
    'scalpée',
    'scalpel',
    'scalper',
    'scalpes',
    'scalpés',
    'scalpez',
    'scandai',
    'scandas',
    'scandât',
    'scandée',
    'scander',
    'scandes',
    'scandés',
    'scandez',
    'scanner',
    'scarole',
    'scellai',
    'scellas',
    'scellât',
    'scellée',
    'sceller',
    'scelles',
    'scellés',
    'scellez',
    'sceptre',
    'schakos',
    'schappe',
    'scheida',
    'scheide',
    'scheidé',
    'scheiks',
    'schelem',
    'schémas',
    'schéols',
    'scherzo',
    'schisme',
    'schiste',
    'schlamm',
    'schlass',
    'schleus',
    'schlich',
    'schnaps',
    'schnock',
    'schofar',
    'schorre',
    'schupos',
    'sciable',
    'sciages',
    'sciâmes',
    'sciasse',
    'sciâtes',
    'science',
    'sciènes',
    'scierai',
    'scieras',
    'scierez',
    'scierie',
    'scieurs',
    'scieuse',
    'sciions',
    'scilles',
    'scindai',
    'scindas',
    'scindât',
    'scindée',
    'scinder',
    'scindes',
    'scindés',
    'scindez',
    'sciotta',
    'sciotte',
    'sciotté',
    'scirpes',
    'sciures',
    'scolies',
    'scolyte',
    'sconses',
    'scooter',
    'scopies',
    'scorbut',
    'scories',
    'scotcha',
    'scotche',
    'scotché',
    'Scotchs',
    'scoties',
    'scoured',
    'scoutes',
    'scraper',
    'scratch',
    'scriban',
    'scribes',
    'scripte',
    'scripts',
    'scrutai',
    'scrutas',
    'scrutât',
    'scrutée',
    'scruter',
    'scrutes',
    'scrutés',
    'scrutez',
    'scrutin',
    'sculpta',
    'sculpte',
    'sculpté',
    'scutums',
    'scythes',
    'séances',
    'séantes',
    'sébacée',
    'sébacés',
    'sébaste',
    'sébiles',
    'sebkhas',
    'sécable',
    'sécante',
    'sécants',
    'séchage',
    'séchais',
    'séchait',
    'séchant',
    'séchées',
    'sèchent',
    'séchera',
    'sécheur',
    'séchiez',
    'séchoir',
    'séchons',
    'seconda',
    'seconde',
    'secondé',
    'seconds',
    'secouai',
    'secouas',
    'secouât',
    'secouée',
    'secouer',
    'secoues',
    'secoués',
    'secouez',
    'secoure',
    'secours',
    'secourt',
    'secouru',
    'sécréta',
    'secrète',
    'sécrété',
    'sécrète',
    'secrets',
    'secteur',
    'section',
    'secundo',
    'sédatif',
    'séduira',
    'séduire',
    'séduise',
    'séduite',
    'séduits',
    'ségalas',
    'segment',
    'ségrais',
    'seguias',
    'seiches',
    'seigles',
    'seilles',
    'seillon',
    'séismes',
    'séjours',
    'sélects',
    'sellais',
    'sellait',
    'sellant',
    'sellées',
    'sellent',
    'sellera',
    'sellier',
    'selliez',
    'sellons',
    'semaine',
    'semâmes',
    'semasse',
    'semâtes',
    'semblai',
    'semblas',
    'semblât',
    'sembler',
    'sembles',
    'semblez',
    'semelle',
    'semence',
    'sèmerai',
    'sèmeras',
    'sèmerez',
    'semeurs',
    'semeuse',
    'séminal',
    'semions',
    'sémites',
    'semoirs',
    'semonça',
    'semonce',
    'semoncé',
    'semoule',
    'semples',
    'sénevés',
    'séniles',
    'seniors',
    'sensass',
    'sensées',
    'senseur',
    'sensuel',
    'sentais',
    'sentait',
    'sentant',
    'sentent',
    'senteur',
    'sentier',
    'senties',
    'sentiez',
    'sentine',
    'sentira',
    'sentons',
    'sépales',
    'séparai',
    'séparas',
    'séparât',
    'séparée',
    'séparer',
    'sépares',
    'séparés',
    'séparez',
    'septain',
    'septidi',
    'septime',
    'septimo',
    'septuor',
    'sequins',
    'séquoia',
    'sera-ce',
    'sérails',
    'sérança',
    'sérance',
    'sérancé',
    'serdeau',
    'sereine',
    'sereins',
    'séreuse',
    'serfoui',
    'sergent',
    'sériais',
    'sériait',
    'sériant',
    'sériées',
    'sériels',
    'sérient',
    'sériera',
    'sérieux',
    'sériiez',
    'serinai',
    'serinas',
    'serinât',
    'serinée',
    'seriner',
    'serines',
    'serinés',
    'sérines',
    'serinez',
    'seringa',
    'serions',
    'sérions',
    'sérique',
    'serment',
    'sermons',
    'serpent',
    'serpule',
    'serrage',
    'serrais',
    'serrait',
    'serrans',
    'serrant',
    'serrate',
    'serrées',
    'serrent',
    'serrera',
    'serriez',
    'serrons',
    'serrure',
    'serties',
    'sertira',
    'servage',
    'servais',
    'servait',
    'servals',
    'servant',
    'servent',
    'serveur',
    'service',
    'servies',
    'serviez',
    'servile',
    'servira',
    'servons',
    'sésames',
    'sessile',
    'session',
    'sétacée',
    'sétacés',
    'setiers',
    'setters',
    'seulets',
    'sévères',
    'sévices',
    'sévîmes',
    'sévirai',
    'séviras',
    'sévirez',
    'sévisse',
    'sévîtes',
    'sevrage',
    'sevrais',
    'sevrait',
    'sevrant',
    'sevrées',
    'sevrent',
    'sevrera',
    'sevriez',
    'sevrons',
    'sexisme',
    'sexiste',
    'sextant',
    'sextidi',
    'sextine',
    'sexuées',
    'sexuels',
    'seyante',
    'seyants',
    'sézigue',
    'sfumato',
    'shakers',
    'shérifs',
    'sherrys',
    'shimmys',
    'shintôs',
    'shogoun',
    'shoguns',
    'shootai',
    'shootas',
    'shootât',
    'shootée',
    'shooter',
    'shootes',
    'shootés',
    'shootez',
    'shoping',
    'shuntai',
    'shuntas',
    'shuntât',
    'shuntée',
    'shunter',
    'shuntes',
    'shuntés',
    'shuntez',
    'siamois',
    'sibylle',
    'sicaire',
    'siccité',
    'sidérai',
    'sidéral',
    'sidéras',
    'sidérât',
    'sidérée',
    'sidérer',
    'sidérés',
    'sidères',
    'sidérez',
    'siècles',
    'siégeai',
    'siégeas',
    'siégeât',
    'siègent',
    'siégera',
    'siégiez',
    'siemens',
    'siennes',
    'siérait',
    'siéront',
    'sierras',
    'siestes',
    'sifflai',
    'sifflas',
    'sifflât',
    'sifflée',
    'siffler',
    'siffles',
    'sifflés',
    'sifflet',
    'sifflez',
    'sifilet',
    'sigillé',
    'signais',
    'signait',
    'signala',
    'signale',
    'signalé',
    'signant',
    'signaux',
    'signées',
    'signent',
    'signera',
    'signets',
    'signiez',
    'signons',
    'silence',
    'silènes',
    'silique',
    'sillage',
    'sillets',
    'sillons',
    'silphes',
    'silures',
    'simarre',
    'simiens',
    'similis',
    'similor',
    'simonie',
    'simouns',
    'simples',
    'simplet',
    'simulai',
    'simulas',
    'simulât',
    'simulée',
    'simuler',
    'simules',
    'simulés',
    'simulez',
    'sincère',
    'singeai',
    'singeas',
    'singeât',
    'singées',
    'singent',
    'singera',
    'singiez',
    'singles',
    'sinisai',
    'sinisas',
    'sinisât',
    'sinisée',
    'siniser',
    'sinises',
    'sinisés',
    'sinisez',
    'sinités',
    'sinueux',
    'siphons',
    'sirdars',
    'sirènes',
    'sirocco',
    'sirocos',
    'sirotai',
    'sirotas',
    'sirotât',
    'sirotée',
    'siroter',
    'sirotes',
    'sirotés',
    'sirotez',
    'sismale',
    'sismaux',
    'sistres',
    'situais',
    'situait',
    'situant',
    'situées',
    'situent',
    'situera',
    'situiez',
    'situons',
    'sixains',
    'sixième',
    'sizains',
    'sizerin',
    'skating',
    'sketchs',
    'skiable',
    'skiâmes',
    'skiasse',
    'skiâtes',
    'skierai',
    'skieras',
    'skierez',
    'skieurs',
    'skieuse',
    'skiions',
    'skipper',
    'slaloma',
    'slalome',
    'slalomé',
    'slaloms',
    'slavisa',
    'slavise',
    'slavisé',
    'slogans',
    'sloughi',
    'slovène',
    'smashai',
    'smashas',
    'smashât',
    'smashée',
    'smasher',
    'smashes',
    'smashés',
    'smashez',
    'smicard',
    'smillai',
    'smillas',
    'smillât',
    'smillée',
    'smiller',
    'smilles',
    'smillés',
    'smillez',
    'smoking',
    'sniffai',
    'sniffas',
    'sniffât',
    'sniffée',
    'sniffer',
    'sniffes',
    'sniffés',
    'sniffez',
    'snobais',
    'snobait',
    'snobant',
    'snobées',
    'snobent',
    'snobera',
    'snobiez',
    'snobons',
    'sociale',
    'sociaux',
    'société',
    'socques',
    'sodique',
    'sodokus',
    'sodomie',
    'soffite',
    'soierie',
    'soignai',
    'soignas',
    'soignât',
    'soignée',
    'soigner',
    'soignes',
    'soignés',
    'soignez',
    'soirées',
    'solaire',
    'soldais',
    'soldait',
    'soldant',
    'soldate',
    'soldats',
    'soldées',
    'soldent',
    'soldera',
    'soldeur',
    'soldiez',
    'soldons',
    'soleils',
    'soleret',
    'solfège',
    'solfiai',
    'solfias',
    'solfiât',
    'solfiée',
    'solfier',
    'solfies',
    'solfiés',
    'solfiez',
    'solides',
    'soliste',
    'solives',
    'solmisa',
    'solmise',
    'solmisé',
    'soluble',
    'solutés',
    'solvant',
    'sombrai',
    'sombras',
    'sombrât',
    'sombrer',
    'sombres',
    'sombrez',
    'somites',
    'sommais',
    'sommait',
    'sommant',
    'sommées',
    'sommeil',
    'somment',
    'sommera',
    'sommets',
    'sommier',
    'sommiez',
    'sommité',
    'sommons',
    'somnola',
    'somnole',
    'somnolé',
    'sonates',
    'sondage',
    'sondais',
    'sondait',
    'sondant',
    'sondées',
    'sondent',
    'sondera',
    'sondeur',
    'sondiez',
    'sondons',
    'songeai',
    'songeas',
    'songeât',
    'songent',
    'songera',
    'songeur',
    'songiez',
    'sonique',
    'sonnais',
    'sonnait',
    'sonnant',
    'sonnées',
    'sonnent',
    'sonnera',
    'sonnets',
    'sonneur',
    'sonniez',
    'sonnons',
    'sonores',
    'sont-ce',
    'sophora',
    'soprani',
    'soprano',
    'sorbets',
    'sorbier',
    'sorcier',
    'sordide',
    'sorghos',
    'sorguai',
    'sorguas',
    'sorguât',
    'sorguer',
    'sorgues',
    'sorguez',
    'sororal',
    'sortais',
    'sortait',
    'sortant',
    'sortent',
    'sorties',
    'sortiez',
    'sortira',
    'sortons',
    'sottise',
    'souches',
    'souchet',
    'souciai',
    'soucias',
    'souciât',
    'souciée',
    'soucier',
    'soucies',
    'souciés',
    'souciez',
    'soudage',
    'soudain',
    'soudais',
    'soudait',
    'soudans',
    'soudant',
    'soudard',
    'soudées',
    'soudent',
    'soudera',
    'soudeur',
    'soudier',
    'soudiez',
    'soudoie',
    'soudons',
    'soudoya',
    'soudoyé',
    'soudure',
    'souffla',
    'souffle',
    'soufflé',
    'souffre',
    'soufrai',
    'soufras',
    'soufrât',
    'soufrée',
    'soufrer',
    'soufres',
    'soufrés',
    'soufrez',
    'souhait',
    'souilla',
    'souille',
    'souillé',
    'soulage',
    'soulagé',
    'soûlais',
    'soûlait',
    'soulane',
    'soûlant',
    'soûlard',
    'soûlaud',
    'soûlées',
    'soûlent',
    'soûlera',
    'souleva',
    'soulevé',
    'soulève',
    'soulier',
    'soûliez',
    'soûlons',
    'soûlote',
    'soûlots',
    'soultes',
    'soumets',
    'soumise',
    'soupais',
    'soupait',
    'soupant',
    'soupape',
    'soupçon',
    'soupent',
    'soupera',
    'soupesa',
    'soupesé',
    'soupèse',
    'soupeur',
    'soupiez',
    'soupira',
    'soupire',
    'soupiré',
    'soupirs',
    'souples',
    'soupons',
    'souquai',
    'souquas',
    'souquât',
    'souquée',
    'souquer',
    'souques',
    'souqués',
    'souquez',
    'sourate',
    'sources',
    'sourcil',
    'sourdes',
    'souries',
    'souriez',
    'sourira',
    'sourire',
    'soutane',
    'soutenu',
    'soutien',
    'soutier',
    'soutins',
    'soutint',
    'soutînt',
    'soutira',
    'soutire',
    'soutiré',
    'souvent',
    'souvenu',
    'souvins',
    'souvint',
    'souvînt',
    'soviets',
    'soyeuse',
    'spadice',
    'spalter',
    'spartes',
    'spasmes',
    'spathes',
    'spatial',
    'spatule',
    'speaker',
    'spécial',
    'spectre',
    'spécula',
    'spécule',
    'spéculé',
    'speechs',
    'spencer',
    'spermes',
    'sphères',
    'spiders',
    'spinale',
    'spinaux',
    'spirale',
    'spirant',
    'spiraux',
    'spirées',
    'spirite',
    'spitant',
    'spittai',
    'spittas',
    'spittât',
    'spittée',
    'spitter',
    'spittes',
    'spittés',
    'spittez',
    'spleens',
    'splitta',
    'splitte',
    'splitté',
    'spoliai',
    'spolias',
    'spoliât',
    'spoliée',
    'spolier',
    'spolies',
    'spoliés',
    'spoliez',
    'spondée',
    'sponsor',
    'sportif',
    'sporula',
    'sporule',
    'sporulé',
    'sprinta',
    'sprinte',
    'sprinté',
    'sprints',
    'spumeux',
    'squales',
    'squames',
    'squares',
    'squashs',
    'squatta',
    'squatte',
    'squatté',
    'squille',
    'squirre',
    'stables',
    'stadias',
    'staffai',
    'staffas',
    'staffât',
    'staffée',
    'staffer',
    'staffes',
    'staffés',
    'staffez',
    'stagnai',
    'stagnas',
    'stagnât',
    'stagner',
    'stagnes',
    'stagnez',
    'stalags',
    'stalles',
    'staminé',
    'stances',
    'starets',
    'staries',
    'starisa',
    'starise',
    'starisé',
    'starter',
    'statère',
    'statice',
    'statifs',
    'station',
    'stative',
    'stators',
    'statuai',
    'statuas',
    'statuât',
    'statuée',
    'statuer',
    'statues',
    'statués',
    'statuez',
    'stature',
    'statuts',
    'stawugs',
    'stayers',
    'steamer',
    'steeple',
    'stencil',
    'sténopé',
    'sténose',
    'stentor',
    'stepper',
    'steppes',
    'stérais',
    'stérait',
    'stérant',
    'stérées',
    'stèrent',
    'stéréos',
    'stérera',
    'stéride',
    'stériez',
    'stérile',
    'sterlet',
    'sternal',
    'sternes',
    'sternum',
    'stérols',
    'stérons',
    'steward',
    'sthènes',
    'stibiée',
    'stibiés',
    'stibine',
    'stimula',
    'stimule',
    'stimulé',
    'stipité',
    'stiples',
    'stipula',
    'stipule',
    'stipulé',
    'stockai',
    'stockas',
    'stockât',
    'stockée',
    'stocker',
    'stockes',
    'stockés',
    'stockez',
    'stoïque',
    'stokers',
    'stolons',
    'stomate',
    'stomoxe',
    'stoppai',
    'stoppas',
    'stoppât',
    'stoppée',
    'stopper',
    'stoppes',
    'stoppés',
    'stoppez',
    'strates',
    'stratum',
    'stratus',
    'stressa',
    'stresse',
    'stressé',
    'stretch',
    'strette',
    'striais',
    'striait',
    'striant',
    'stricte',
    'stricts',
    'stridor',
    'striées',
    'strient',
    'striera',
    'striges',
    'striiez',
    'strings',
    'strions',
    'strippa',
    'strippe',
    'strippé',
    'striqua',
    'strique',
    'striqué',
    'striure',
    'stromas',
    'strombe',
    'strophe',
    'strumes',
    'stryges',
    'stucage',
    'studios',
    'stupeur',
    'stupide',
    'stupres',
    'stuquai',
    'stuquas',
    'stuquât',
    'stuquée',
    'stuquer',
    'stuques',
    'stuqués',
    'stuquez',
    'stylais',
    'stylait',
    'stylant',
    'stylées',
    'stylent',
    'stylera',
    'stylets',
    'styliez',
    'stylisa',
    'stylise',
    'stylisé',
    'stylite',
    'stylons',
    'styrène',
    'suaient',
    'suaires',
    'suantes',
    'suasses',
    'suavité',
    'subaigu',
    'subîmes',
    'subirai',
    'subiras',
    'subirez',
    'subisse',
    'subites',
    'subîtes',
    'sublima',
    'sublime',
    'sublimé',
    'suborna',
    'suborne',
    'suborné',
    'subroge',
    'subrogé',
    'subside',
    'subtile',
    'subtils',
    'subulée',
    'subulés',
    'subvenu',
    'subvins',
    'subvint',
    'subvînt',
    'suçâmes',
    'suçasse',
    'suçâtes',
    'succéda',
    'succédé',
    'succède',
    'succins',
    'succion',
    'succube',
    'sucerai',
    'suceras',
    'sucerez',
    'sucette',
    'suceurs',
    'suceuse',
    'sucions',
    'suçoirs',
    'suçotai',
    'suçotas',
    'suçotât',
    'suçotée',
    'suçoter',
    'suçotes',
    'suçotés',
    'suçotez',
    'sucrage',
    'sucrais',
    'sucrait',
    'sucrant',
    'sucrase',
    'sucrate',
    'sucrées',
    'sucrent',
    'sucrera',
    'sucrier',
    'sucriez',
    'sucrins',
    'sucrons',
    'sud-est',
    'sudiste',
    'sudoral',
    'suédées',
    'suédine',
    'suédois',
    'suerais',
    'suerait',
    'suèrent',
    'sueriez',
    'suerons',
    'sueront',
    'suettes',
    'suffète',
    'suffira',
    'suffire',
    'suffise',
    'suffite',
    'suffits',
    'suffixa',
    'suffixe',
    'suffixé',
    'suggéra',
    'suggéré',
    'suggère',
    'suicida',
    'suicide',
    'suicidé',
    'suifais',
    'suifait',
    'suifant',
    'suifées',
    'suifent',
    'suifera',
    'suiffai',
    'suiffas',
    'suiffât',
    'suiffée',
    'suiffer',
    'suiffes',
    'suiffés',
    'suiffez',
    'suifiez',
    'suifons',
    'suintai',
    'suintas',
    'suintât',
    'suintée',
    'suinter',
    'suintes',
    'suintés',
    'suintez',
    'suis-je',
    'suisses',
    'suitées',
    'suivais',
    'suivait',
    'suivant',
    'suivent',
    'suiveur',
    'suivies',
    'suiviez',
    'suivons',
    'suivrai',
    'suivras',
    'suivrez',
    'sujette',
    'sulfata',
    'sulfate',
    'sulfaté',
    'sulfita',
    'sulfite',
    'sulfité',
    'sulfona',
    'sulfone',
    'sulfoné',
    'sulfura',
    'sulfure',
    'sulfuré',
    'sultane',
    'sultans',
    'summums',
    'sunnite',
    'superbe',
    'supères',
    'suppléa',
    'supplée',
    'suppléé',
    'supplia',
    'supplie',
    'supplié',
    'support',
    'supposa',
    'suppose',
    'supposé',
    'suppôts',
    'suppura',
    'suppure',
    'suppuré',
    'supputa',
    'suppute',
    'supputé',
    'suprême',
    'suraigu',
    'suranné',
    'surates',
    'surboum',
    'surdent',
    'surdité',
    'surdora',
    'surdore',
    'surdoré',
    'surdoué',
    'sureaux',
    'sûretés',
    'surette',
    'surfaça',
    'surface',
    'surfacé',
    'surfais',
    'surfait',
    'surfant',
    'surfent',
    'surfera',
    'surfiez',
    'surfila',
    'surfile',
    'surfilé',
    'surfils',
    'surfine',
    'surfins',
    'surfons',
    'surfont',
    'surgela',
    'surgelé',
    'surgèle',
    'surgeon',
    'surgira',
    'surîmes',
    'surinai',
    'surinas',
    'surinât',
    'surinée',
    'suriner',
    'surines',
    'surinés',
    'surinez',
    'surirai',
    'suriras',
    'surirez',
    'surisse',
    'surîtes',
    'surjala',
    'surjale',
    'surjalé',
    'surjeta',
    'surjeté',
    'surjets',
    'surliai',
    'surlias',
    'surliât',
    'surliée',
    'surlier',
    'surlies',
    'surliés',
    'surliez',
    'surloua',
    'surloue',
    'surloué',
    'surmena',
    'surmené',
    'surmène',
    'surnage',
    'surnagé',
    'surnoms',
    'suroîts',
    'surpaie',
    'surpaya',
    'surpaye',
    'surpayé',
    'surplis',
    'surplus',
    'surpris',
    'surprit',
    'surprît',
    'sursaut',
    'sursema',
    'sursemé',
    'sursème',
    'sursise',
    'sursoie',
    'sursois',
    'sursoit',
    'surtaux',
    'surtaxa',
    'surtaxe',
    'surtaxé',
    'surtout',
    'survécu',
    'survenu',
    'survies',
    'survins',
    'survint',
    'survînt',
    'survira',
    'survire',
    'surviré',
    'survive',
    'survola',
    'survole',
    'survolé',
    'survols',
    'suscita',
    'suscite',
    'suscité',
    'susdite',
    'susdits',
    'suspect',
    'suspend',
    'suspens',
    'sussent',
    'sussiez',
    'susurra',
    'susurre',
    'susurré',
    'susvisé',
    'suturai',
    'sutural',
    'suturas',
    'suturât',
    'suturée',
    'suturer',
    'sutures',
    'suturés',
    'suturez',
    'sveltes',
    'swahéli',
    'swahili',
    'sweater',
    'swingua',
    'swingue',
    'swingué',
    'sycosis',
    'syénite',
    'syllaba',
    'syllabe',
    'syllabé',
    'sylphes',
    'sylvain',
    'symbole',
    'synapse',
    'syncopa',
    'syncope',
    'syncopé',
    'syndics',
    'synodal',
    'synodes',
    'synopse',
    'synovie',
    'syntaxe',
    'syntone',
    'syriens',
    'syringe',
    'syrphes',
    'système',
    'systyle',
    'tabagie',
    'tabards',
    'tabassa',
    'tabasse',
    'tabassé',
    'tablais',
    'tablait',
    'tablant',
    'tablars',
    'tableau',
    'tablées',
    'tablent',
    'tablera',
    'tableur',
    'tablier',
    'tabliez',
    'tabloïd',
    'tablons',
    'taboues',
    'taboués',
    'tabulai',
    'tabulas',
    'tabulât',
    'tabuler',
    'tabules',
    'tabulez',
    'tachais',
    'tâchais',
    'tachait',
    'tâchait',
    'tachant',
    'tâchant',
    'tachées',
    'tâchées',
    'tachent',
    'tâchent',
    'tachera',
    'tâchera',
    'tacheta',
    'tacheté',
    'tachiez',
    'tâchiez',
    'tachine',
    'tachons',
    'tâchons',
    'tachyon',
    'tacites',
    'tactile',
    'tadorne',
    'taenias',
    'tagètes',
    'tagette',
    'taillai',
    'taillas',
    'taillât',
    'taillée',
    'tailler',
    'tailles',
    'taillés',
    'taillez',
    'taillis',
    'tairais',
    'tairait',
    'tairiez',
    'tairons',
    'tairont',
    'taisais',
    'taisait',
    'taisant',
    'taisent',
    'taiseux',
    'taisiez',
    'taisons',
    'tajines',
    'talâmes',
    'talasse',
    'talâtes',
    'talents',
    'talerai',
    'taleras',
    'talerez',
    'taleths',
    'talions',
    'talitre',
    'tallage',
    'tallais',
    'tallait',
    'tallant',
    'tallent',
    'tallera',
    'talleth',
    'talliez',
    'tallons',
    'talmuds',
    'talocha',
    'taloche',
    'taloché',
    'talonna',
    'talonne',
    'talonné',
    'talpack',
    'talquai',
    'talquas',
    'talquât',
    'talquée',
    'talquer',
    'talques',
    'talqués',
    'talquez',
    'talures',
    'talwegs',
    'tamarin',
    'tamaris',
    'tamarix',
    'tambour',
    'tamiers',
    'tamisai',
    'tamisas',
    'tamisât',
    'tamisée',
    'tamiser',
    'tamises',
    'tamisés',
    'tamisez',
    'tamoule',
    'tamouls',
    'tampico',
    'tampons',
    'tam-tam',
    'tanagra',
    'tançais',
    'tançait',
    'tançant',
    'tancées',
    'tancent',
    'tancera',
    'tanches',
    'tanciez',
    'tançons',
    'tandems',
    'tangage',
    'tangara',
    'tangent',
    'tangons',
    'tanguai',
    'tanguas',
    'tanguât',
    'tanguer',
    'tangues',
    'tanguez',
    'tanière',
    'tanisai',
    'tanisas',
    'tanisât',
    'tanisée',
    'taniser',
    'tanises',
    'tanisés',
    'tanisez',
    'tankers',
    'tannage',
    'tannais',
    'tannait',
    'tannant',
    'tannées',
    'tannent',
    'tannera',
    'tanneur',
    'tanniez',
    'tannins',
    'tannisa',
    'tannise',
    'tannisé',
    'tannons',
    'tanrecs',
    'tan-sad',
    'tantale',
    'tantine',
    'taoïsme',
    'taôisme',
    'taoïste',
    'taôiste',
    'tapagea',
    'tapager',
    'tapages',
    'tapagez',
    'tapâmes',
    'tapante',
    'tapants',
    'tapasse',
    'tapâtes',
    'tapecul',
    'taperai',
    'taperas',
    'taperez',
    'tapette',
    'tapeurs',
    'tapeuse',
    'tapîmes',
    'tapinai',
    'tapinas',
    'tapinât',
    'tapiner',
    'tapines',
    'tapinez',
    'tapioca',
    'tapions',
    'tapirai',
    'tapiras',
    'tapirez',
    'tapissa',
    'tapisse',
    'tapissé',
    'tapîtes',
    'taponna',
    'taponne',
    'taponné',
    'tapotai',
    'tapotas',
    'tapotât',
    'tapotée',
    'tapoter',
    'tapotes',
    'tapotés',
    'tapotez',
    'taquais',
    'taquait',
    'taquant',
    'taquées',
    'taquent',
    'taquera',
    'taquets',
    'taquiez',
    'taquina',
    'taquine',
    'taquiné',
    'taquins',
    'taquoir',
    'taquons',
    'tarages',
    'taramas',
    'tarâmes',
    'tarares',
    'tarasse',
    'tarâtes',
    'tarauda',
    'taraude',
    'taraudé',
    'tarauds',
    'tardais',
    'tardait',
    'tardant',
    'tardent',
    'tardera',
    'tardiez',
    'tardifs',
    'tardive',
    'tardons',
    'tarente',
    'tarerai',
    'tareras',
    'tarerez',
    'targuai',
    'targuas',
    'targuât',
    'targuée',
    'targuer',
    'targues',
    'targués',
    'targuez',
    'targuie',
    'tarière',
    'tarifai',
    'tarifas',
    'tarifât',
    'tarifée',
    'tarifer',
    'tarifes',
    'tarifés',
    'tarifez',
    'tarifia',
    'tarifie',
    'tarifié',
    'tarîmes',
    'tarions',
    'tarirai',
    'tariras',
    'tarirez',
    'tarisse',
    'tarîtes',
    'tarotée',
    'tarotés',
    'tarpans',
    'tarpons',
    'tarsale',
    'tarsaux',
    'tarsien',
    'tarsier',
    'tartane',
    'tartans',
    'tartare',
    'tartina',
    'tartine',
    'tartiné',
    'tartira',
    'tartres',
    'tartufe',
    'tarzans',
    'tassage',
    'tassais',
    'tassait',
    'tassant',
    'tasseau',
    'tassées',
    'tassent',
    'tassera',
    'tassiez',
    'tassili',
    'tassons',
    'tâtâmes',
    'tatamis',
    'tatanes',
    'tatares',
    'tâtasse',
    'tâtâtes',
    'tâterai',
    'tâteras',
    'tâterez',
    'tâteurs',
    'tâtions',
    'tâtonna',
    'tâtonne',
    'tâtonné',
    'tatouai',
    'tatouas',
    'tatouât',
    'tatouée',
    'tatouer',
    'tatoues',
    'tatoués',
    'tatouez',
    'taulard',
    'taulier',
    'taupées',
    'taupier',
    'taupins',
    'taureau',
    'taurine',
    'taurins',
    'tauzins',
    'tavelai',
    'tavelas',
    'tavelât',
    'tavelée',
    'taveler',
    'tavelés',
    'tavelez',
    'tavella',
    'tavelle',
    'tavellé',
    'taverne',
    'taxable',
    'taxâmes',
    'taxasse',
    'taxâtes',
    'taxatif',
    'taxerai',
    'taxeras',
    'taxerez',
    'taxions',
    'taxiway',
    'tchador',
    'tchèque',
    'teckels',
    'Téflons',
    'teignes',
    'teignez',
    'teignis',
    'teignit',
    'teignît',
    'teillai',
    'teillas',
    'teillât',
    'teillée',
    'teiller',
    'teilles',
    'teillés',
    'teillez',
    'teindra',
    'teindre',
    'teintai',
    'teintas',
    'teintât',
    'teintée',
    'teinter',
    'teintes',
    'teintés',
    'teintez',
    'télamon',
    'télégas',
    'téléski',
    'télexai',
    'télexas',
    'télexât',
    'télexée',
    'télexer',
    'télexes',
    'télexés',
    'télexez',
    'tellure',
    'telsons',
    'témoins',
    'tempéra',
    'tempéré',
    'tempère',
    'tempêta',
    'tempête',
    'tempêté',
    'temples',
    'tenable',
    'tenaces',
    'tenante',
    'tenants',
    'tendais',
    'tendait',
    'tendant',
    'tendent',
    'tenders',
    'tendeur',
    'tendiez',
    'tendoir',
    'tendons',
    'tendrai',
    'tendras',
    'tendres',
    'tendrez',
    'tendron',
    'tendues',
    'ténesme',
    'teneurs',
    'teneuse',
    'tenions',
    'tenonna',
    'tenonne',
    'tenonné',
    'tenrecs',
    'tension',
    'tensons',
    'tentais',
    'tentait',
    'tentant',
    'tentées',
    'tentent',
    'tentera',
    'tentiez',
    'tentons',
    'tenture',
    'ténuité',
    'tenures',
    'téorbes',
    'tequila',
    'terbine',
    'terbium',
    'terçais',
    'terçait',
    'terçant',
    'tercées',
    'tercent',
    'tercera',
    'tercets',
    'terciez',
    'terçons',
    'Tergals',
    'termina',
    'termine',
    'terminé',
    'termite',
    'ternies',
    'ternira',
    'terpène',
    'terpine',
    'terrage',
    'terrain',
    'terrais',
    'terrait',
    'terrant',
    'terreau',
    'terrées',
    'terrent',
    'terrera',
    'terreur',
    'terreux',
    'terrien',
    'terrier',
    'terriez',
    'terrils',
    'terrine',
    'terrira',
    'terroir',
    'terrons',
    'tersais',
    'tersait',
    'tersant',
    'tersées',
    'tersent',
    'tersera',
    'tersiez',
    'tersons',
    'tertres',
    'tessons',
    'testacé',
    'testage',
    'testais',
    'testait',
    'testant',
    'testées',
    'testent',
    'testera',
    'testeur',
    'testiez',
    'testons',
    'tétâmes',
    'tétanie',
    'tétanos',
    'têtards',
    'tétasse',
    'tétâtes',
    'têteaux',
    'téterai',
    'téteras',
    'téterez',
    'têtière',
    'tétines',
    'tétions',
    'tétrade',
    'teutons',
    'texanes',
    'textile',
    'textuel',
    'textura',
    'texture',
    'texturé',
    'thalers',
    'thalles',
    'thalweg',
    'théatin',
    'théâtre',
    'thébain',
    'théière',
    'théiers',
    'théines',
    'théisme',
    'théiste',
    'thénars',
    'théorbe',
    'théorie',
    'thèques',
    'thermal',
    'thermes',
    'thermie',
    'thermos',
    'thésard',
    'thonier',
    'thorine',
    'thorite',
    'thorium',
    'thorons',
    'thrènes',
    'thulium',
    'thymies',
    'thymine',
    'thymols',
    'thyrses',
    'tibiale',
    'tibiaux',
    'tickets',
    'tic-tac',
    'tiédeur',
    'tiédies',
    'tiédira',
    'tiendra',
    'tiennes',
    'tierçai',
    'tierças',
    'tierçât',
    'tiercée',
    'tiercer',
    'tierces',
    'tiercés',
    'tiercez',
    'tigelle',
    'tigette',
    'tiglons',
    'tigrais',
    'tigrait',
    'tigrant',
    'tigrées',
    'tigrent',
    'tigrera',
    'tigriez',
    'tigrons',
    'tilbury',
    'tillacs',
    'tillage',
    'tillais',
    'tillait',
    'tillant',
    'tillées',
    'tillent',
    'tillera',
    'tilleul',
    'tilliez',
    'tillons',
    'timbale',
    'timbrai',
    'timbras',
    'timbrât',
    'timbrée',
    'timbrer',
    'timbres',
    'timbrés',
    'timbrez',
    'timides',
    'timings',
    'timorée',
    'timorés',
    'tinamou',
    'tincals',
    'tinette',
    'tinrent',
    'tinsses',
    'tintais',
    'tintait',
    'tintant',
    'tintées',
    'tintent',
    'tintera',
    'tintiez',
    'tintins',
    'tintons',
    'tipules',
    'tiquais',
    'tiquait',
    'tiquant',
    'tiquent',
    'tiquera',
    'tiqueté',
    'tiqueur',
    'tiquiez',
    'tiquons',
    'tirades',
    'tirages',
    'tirâmes',
    'tirasse',
    'tirâtes',
    'tirerai',
    'tireras',
    'tirerez',
    'tirette',
    'tireurs',
    'tireuse',
    'tirions',
    'tiroirs',
    'tisâmes',
    'tisanes',
    'tisasse',
    'tisâtes',
    'tiserai',
    'tiseras',
    'tiserez',
    'tisions',
    'tisonna',
    'tisonne',
    'tisonné',
    'tissage',
    'tissais',
    'tissait',
    'tissant',
    'tissées',
    'tissent',
    'tissera',
    'tisseur',
    'tissiez',
    'tissons',
    'tissure',
    'titanes',
    'titilla',
    'titille',
    'titillé',
    'titrage',
    'titrais',
    'titrait',
    'titrant',
    'titrées',
    'titrent',
    'titrera',
    'titriez',
    'titrons',
    'titubai',
    'titubas',
    'titubât',
    'tituber',
    'titubes',
    'titubez',
    'toastai',
    'toastas',
    'toastât',
    'toastée',
    'toaster',
    'toastes',
    'toastés',
    'toastez',
    'tocades',
    'tocante',
    'tocarde',
    'tocards',
    'toccata',
    'tocsins',
    'toilais',
    'toilait',
    'toilant',
    'toilées',
    'toilent',
    'toilera',
    'toiliez',
    'toilons',
    'toisais',
    'toisait',
    'toisant',
    'toisées',
    'toisent',
    'toisera',
    'toisiez',
    'toisons',
    'toiture',
    'tolérai',
    'toléras',
    'tolérât',
    'tolérée',
    'tolérer',
    'tolérés',
    'tolères',
    'tolérez',
    'tôlerie',
    'tôlière',
    'tôliers',
    'tolites',
    'toluène',
    'toluols',
    'tomâmes',
    'tomasse',
    'tomates',
    'tomâtes',
    'tombacs',
    'tombais',
    'tombait',
    'tombale',
    'tombant',
    'tombaux',
    'tombeau',
    'tombées',
    'tombent',
    'tombera',
    'tombeur',
    'tombiez',
    'tombola',
    'tombons',
    'tomerai',
    'tomeras',
    'tomerez',
    'tomette',
    'tomions',
    'tommies',
    'tonales',
    'tondage',
    'tondais',
    'tondait',
    'tondant',
    'tondent',
    'tondeur',
    'tondiez',
    'tondons',
    'tondrai',
    'tondras',
    'tondrez',
    'tondues',
    'tonifia',
    'tonifie',
    'tonifié',
    'tonique',
    'tonnage',
    'tonnais',
    'tonnait',
    'tonnant',
    'tonneau',
    'tonnent',
    'tonnera',
    'tonniez',
    'tonnons',
    'tonsura',
    'tonsure',
    'tonsuré',
    'tontina',
    'tontine',
    'tontiné',
    'tontons',
    'tonture',
    'topâmes',
    'topasse',
    'topâtes',
    'topazes',
    'toperai',
    'toperas',
    'toperez',
    'topette',
    'topions',
    'topique',
    'toquade',
    'toquais',
    'toquait',
    'toquant',
    'toquard',
    'toquées',
    'toquent',
    'toquera',
    'toquets',
    'toquiez',
    'toquons',
    'torchai',
    'torchas',
    'torchât',
    'torchée',
    'torcher',
    'torches',
    'torchés',
    'torchez',
    'torchis',
    'torchon',
    'torcols',
    'tordage',
    'tordais',
    'tordait',
    'tordant',
    'tordent',
    'tordeur',
    'tordiez',
    'tordoir',
    'tordons',
    'tordrai',
    'tordras',
    'tordrez',
    'tordues',
    'toréais',
    'toréait',
    'toréant',
    'toréent',
    'toréera',
    'toréiez',
    'toréons',
    'toreros',
    'tornade',
    'toronna',
    'toronne',
    'toronné',
    'torpédo',
    'torpeur',
    'torpide',
    'torques',
    'torrent',
    'torride',
    'torsada',
    'torsade',
    'torsadé',
    'torseur',
    'torsion',
    'tortils',
    'tortora',
    'tortore',
    'tortoré',
    'tortues',
    'tortura',
    'torture',
    'torturé',
    'torysme',
    'toscane',
    'toscans',
    'totales',
    'touages',
    'touâmes',
    'touareg',
    'touasse',
    'touâtes',
    'toubibs',
    'toucans',
    'touchai',
    'touchas',
    'touchât',
    'touchau',
    'touchée',
    'toucher',
    'touches',
    'touchés',
    'touchez',
    'touerai',
    'toueras',
    'touerez',
    'toueurs',
    'touffes',
    'touffue',
    'touffus',
    'touilla',
    'touille',
    'touillé',
    'touions',
    'toundra',
    'toupets',
    'toupies',
    'touques',
    'tourbai',
    'tourbas',
    'tourbât',
    'tourber',
    'tourbes',
    'tourbez',
    'tourets',
    'tourier',
    'touries',
    'tournai',
    'tournas',
    'tournât',
    'tournée',
    'tourner',
    'tournes',
    'tournés',
    'tournez',
    'tournis',
    'tournoi',
    'tourons',
    'tourtes',
    'toussai',
    'toussas',
    'toussât',
    'tousser',
    'tousses',
    'toussez',
    'toutime',
    'toutims',
    'toutous',
    'toxémie',
    'toxines',
    'toxique',
    'trabées',
    'traçage',
    'traçais',
    'traçait',
    'tracana',
    'tracane',
    'tracané',
    'traçant',
    'tracées',
    'tracent',
    'tracera',
    'traceur',
    'trachée',
    'traciez',
    'traçons',
    'tractai',
    'tractas',
    'tractât',
    'tractée',
    'tracter',
    'tractes',
    'tractés',
    'tractez',
    'tractif',
    'tractus',
    'traduis',
    'traduit',
    'trafics',
    'trahies',
    'trahira',
    'traient',
    'traille',
    'traînai',
    'traînas',
    'traînât',
    'traînée',
    'traîner',
    'traînes',
    'traînés',
    'traînez',
    'trairai',
    'trairas',
    'trairez',
    'traitai',
    'traitas',
    'traitât',
    'traitée',
    'traiter',
    'traites',
    'traités',
    'traitez',
    'traître',
    'trajets',
    'tralala',
    'tramail',
    'tramais',
    'tramait',
    'tramant',
    'tramées',
    'trament',
    'tramera',
    'tramiez',
    'tramons',
    'tramway',
    'trancha',
    'tranche',
    'tranché',
    'transat',
    'transes',
    'transfo',
    'transie',
    'transir',
    'transis',
    'transit',
    'transît',
    'trapèze',
    'trappes',
    'trapues',
    'traquai',
    'traquas',
    'traquât',
    'traquée',
    'traquer',
    'traques',
    'traqués',
    'traquet',
    'traquez',
    'traumas',
    'travail',
    'travaux',
    'travées',
    'travelo',
    'travers',
    'trayais',
    'trayait',
    'trayant',
    'trayeur',
    'trayiez',
    'trayons',
    'tréfila',
    'tréfile',
    'tréfilé',
    'tréflée',
    'tréflés',
    'trèfles',
    'treille',
    'trémail',
    'trémata',
    'trémate',
    'trématé',
    'trembla',
    'tremble',
    'tremblé',
    'trémies',
    'trémolo',
    'trempai',
    'trempas',
    'trempât',
    'trempée',
    'tremper',
    'trempes',
    'trempés',
    'trempez',
    'trémula',
    'trémule',
    'trémulé',
    'trépana',
    'trépane',
    'trépané',
    'trépang',
    'trépans',
    'trépida',
    'trépide',
    'trépidé',
    'trépied',
    'trésors',
    'tressai',
    'tressas',
    'tressât',
    'tressée',
    'tresser',
    'tresses',
    'tressés',
    'tressez',
    'tréteau',
    'treuils',
    'trévira',
    'trévire',
    'tréviré',
    'triades',
    'triages',
    'triaire',
    'triâmes',
    'triasse',
    'triâtes',
    'tribade',
    'tribale',
    'tribart',
    'tribaux',
    'tribord',
    'tribune',
    'tribuns',
    'tributs',
    'triceps',
    'trichai',
    'trichas',
    'trichât',
    'tricher',
    'triches',
    'trichez',
    'tricota',
    'tricote',
    'tricoté',
    'tricots',
    'trident',
    'trierai',
    'trieras',
    'trières',
    'trierez',
    'trieurs',
    'trieuse',
    'trifide',
    'trigles',
    'trigone',
    'triions',
    'trillai',
    'trillas',
    'trillât',
    'triller',
    'trilles',
    'trillez',
    'trilobé',
    'trimais',
    'trimait',
    'trimant',
    'trimard',
    'trimées',
    'triment',
    'trimera',
    'trimère',
    'trimiez',
    'trimons',
    'tringla',
    'tringle',
    'tringlé',
    'trinité',
    'trinqua',
    'trinque',
    'trinqué',
    'triodes',
    'triolet',
    'trionyx',
    'tripale',
    'tripang',
    'tripier',
    'triplai',
    'triplas',
    'triplât',
    'triplée',
    'tripler',
    'triples',
    'triplés',
    'Triplex',
    'triplez',
    'tripoli',
    'tripota',
    'tripote',
    'tripoté',
    'tripots',
    'tripous',
    'tripoux',
    'triquai',
    'triquas',
    'triquât',
    'triquée',
    'triquer',
    'triques',
    'triqués',
    'triquet',
    'triquez',
    'trirème',
    'trismes',
    'trismus',
    'trisocs',
    'trissai',
    'trissas',
    'trissât',
    'trissée',
    'trisser',
    'trisses',
    'trissés',
    'trissez',
    'tristes',
    'tritium',
    'tritons',
    'tritura',
    'triture',
    'trituré',
    'trivial',
    'trocart',
    'trochée',
    'troches',
    'trochet',
    'trochin',
    'troènes',
    'trognes',
    'trognon',
    'troïkas',
    'trolley',
    'trombes',
    'trommel',
    'trompai',
    'trompas',
    'trompât',
    'trompée',
    'tromper',
    'trompes',
    'trompés',
    'trompez',
    'trônais',
    'trônait',
    'trônant',
    'tronche',
    'tronçon',
    'trônent',
    'trônera',
    'trôniez',
    'trônons',
    'tronqua',
    'tronque',
    'tronqué',
    'trophée',
    'troquai',
    'troquas',
    'troquât',
    'troquée',
    'troquer',
    'troques',
    'troqués',
    'troquet',
    'troquez',
    'trottai',
    'trottas',
    'trottât',
    'trottée',
    'trotter',
    'trottes',
    'trottés',
    'trottez',
    'trottin',
    'trouais',
    'trouait',
    'trouant',
    'troubla',
    'trouble',
    'troublé',
    'trouées',
    'trouent',
    'trouera',
    'trouiez',
    'trouons',
    'troupes',
    'troussa',
    'trousse',
    'troussé',
    'trouvai',
    'trouvas',
    'trouvât',
    'trouvée',
    'trouver',
    'trouves',
    'trouvés',
    'trouvez',
    'troyens',
    'truanda',
    'truande',
    'truandé',
    'truands',
    'trubles',
    'trucage',
    'trucida',
    'trucide',
    'trucidé',
    'truelle',
    'truffai',
    'truffas',
    'truffât',
    'truffée',
    'truffer',
    'truffes',
    'truffés',
    'truffez',
    'truisme',
    'truitée',
    'truites',
    'truités',
    'trumeau',
    'truquai',
    'truquas',
    'truquât',
    'truquée',
    'truquer',
    'truques',
    'truqués',
    'truquez',
    'trustai',
    'trustas',
    'trustât',
    'trustée',
    'truster',
    'trustes',
    'trustés',
    'trustez',
    'tsarine',
    'tsé-tsé',
    't-shirt',
    'tsigane',
    'tss-tss',
    'tsunami',
    'tuables',
    'tuaient',
    'tuantes',
    'tuasses',
    'tubages',
    'tubaire',
    'tubâmes',
    'tubarde',
    'tubards',
    'tubasse',
    'tubâtes',
    'tuberai',
    'tuberas',
    'tuberez',
    'tubifex',
    'tubions',
    'tubiste',
    'tubulée',
    'tubulés',
    'tuerais',
    'tuerait',
    'tuèrent',
    'tueries',
    'tueriez',
    'tuerons',
    'tueront',
    'tueuses',
    'tufeaux',
    'tuffeau',
    'tufière',
    'tufiers',
    'tuilais',
    'tuilait',
    'tuilant',
    'tuileau',
    'tuilées',
    'tuilent',
    'tuilera',
    'tuilier',
    'tuiliez',
    'tuilons',
    'tulipes',
    'tullier',
    'tuméfia',
    'tuméfie',
    'tuméfié',
    'tumeurs',
    'tumoral',
    'tumulte',
    'tumulus',
    'tungars',
    'tunique',
    'tuniqué',
    'tunnels',
    'tupajas',
    'turbans',
    'turbehs',
    'turbide',
    'turbina',
    'turbine',
    'turbiné',
    'turbins',
    'turbith',
    'turbots',
    'turgide',
    'turions',
    'turneps',
    'turpide',
    'turques',
    'turquin',
    'tussahs',
    'tussent',
    'tussiez',
    'tussore',
    'tussors',
    'tutelle',
    'tuteura',
    'tuteure',
    'tuteuré',
    'tuteurs',
    'tuthies',
    'tutoies',
    'tutoyai',
    'tutoyas',
    'tutoyât',
    'tutoyée',
    'tutoyer',
    'tutoyés',
    'tutoyez',
    'tutrice',
    'tuyauta',
    'tuyaute',
    'tuyauté',
    'tuyères',
    'twistai',
    'twistas',
    'twistât',
    'twister',
    'twistes',
    'twistez',
    'tympans',
    'typâmes',
    'typasse',
    'typâtes',
    'typerai',
    'typeras',
    'typerez',
    'typhons',
    'typhose',
    'typions',
    'typique',
    'typisai',
    'typisas',
    'typisât',
    'typisée',
    'typiser',
    'typises',
    'typisés',
    'typisez',
    'tzarine',
    'tzigane',
    'ulcérai',
    'ulcéras',
    'ulcérât',
    'ulcérée',
    'ulcérer',
    'ulcérés',
    'ulcères',
    'ulcérez',
    'ulmaire',
    'ulnaire',
    'ultimes',
    'ululais',
    'ululait',
    'ululant',
    'ululent',
    'ululera',
    'ululiez',
    'ululons',
    'unanime',
    'uncinée',
    'uncinés',
    'unguéal',
    'uniates',
    'uniaxes',
    'unicité',
    'unièmes',
    'unifiai',
    'unifias',
    'unifiât',
    'unifiée',
    'unifier',
    'unifies',
    'unifiés',
    'unifiez',
    'unilobé',
    'uniment',
    'unipare',
    'uniques',
    'unirais',
    'unirait',
    'unirent',
    'uniriez',
    'unirons',
    'uniront',
    'unisexe',
    'unisses',
    'unissez',
    'unisson',
    'unitifs',
    'unitive',
    'univers',
    'upsilon',
    'uracile',
    'uranate',
    'uranies',
    'uranium',
    'uranyle',
    'urbaine',
    'urbains',
    'urcéolé',
    'uréides',
    'urémies',
    'uretère',
    'urétral',
    'urètres',
    'urgeait',
    'urgence',
    'urgente',
    'urgents',
    'urinais',
    'urinait',
    'urinant',
    'urinaux',
    'urinent',
    'urinera',
    'urineux',
    'uriniez',
    'urinoir',
    'urinons',
    'uriques',
    'uropode',
    'usagées',
    'usagers',
    'usaient',
    'usantes',
    'usasses',
    'userais',
    'userait',
    'usèrent',
    'useriez',
    'userons',
    'useront',
    'usinage',
    'usinais',
    'usinait',
    'usinant',
    'usinées',
    'usinent',
    'usinera',
    'usinier',
    'usiniez',
    'usinons',
    'usitées',
    'usuelle',
    'usurier',
    'usurpai',
    'usurpas',
    'usurpât',
    'usurpée',
    'usurper',
    'usurpes',
    'usurpés',
    'usurpez',
    'utérine',
    'utérins',
    'utilisa',
    'utilise',
    'utilisé',
    'utilité',
    'utopies',
    'uvéites',
    'vacance',
    'vacante',
    'vacants',
    'vacarme',
    'vaccina',
    'vaccine',
    'vacciné',
    'vaccins',
    'vachard',
    'vachère',
    'vachers',
    'vacilla',
    'vacille',
    'vacillé',
    'vacives',
    'vacuité',
    'vacuole',
    'vacuome',
    'vaginal',
    'vagirai',
    'vagiras',
    'vagirez',
    'vagisse',
    'vaguais',
    'vaguait',
    'vaguant',
    'vaguent',
    'vaguera',
    'vaguiez',
    'vaguons',
    'vahinés',
    'vaigres',
    'vailles',
    'vaincra',
    'vaincre',
    'vaincue',
    'vaincus',
    'vainque',
    'vairées',
    'vairons',
    'valable',
    'valence',
    'valetai',
    'valetas',
    'valetât',
    'valeter',
    'valetez',
    'valette',
    'valeurs',
    'validai',
    'validas',
    'validât',
    'validée',
    'valider',
    'valides',
    'validés',
    'validez',
    'valines',
    'valions',
    'valisai',
    'valisas',
    'valisât',
    'valisée',
    'valiser',
    'valises',
    'valisés',
    'valisez',
    'vallées',
    'vallons',
    'valoche',
    'valousa',
    'valouse',
    'valousé',
    'valsais',
    'valsait',
    'valsant',
    'valsées',
    'valsent',
    'valsera',
    'valseur',
    'valsiez',
    'valsons',
    'valûmes',
    'valusse',
    'valûtes',
    'valvées',
    'valvule',
    'vampais',
    'vampait',
    'vampant',
    'vampées',
    'vampent',
    'vampera',
    'vampiez',
    'vampire',
    'vampons',
    'vandale',
    'vanesse',
    'vanille',
    'vanillé',
    'vanités',
    'vannage',
    'vannais',
    'vannait',
    'vannant',
    'vanneau',
    'vannées',
    'vannent',
    'vannera',
    'vanneur',
    'vannier',
    'vanniez',
    'vannons',
    'vannure',
    'vantail',
    'vantais',
    'vantait',
    'vantant',
    'vantard',
    'vantaux',
    'vantées',
    'vantent',
    'vantera',
    'vantiez',
    'vantons',
    'vapeurs',
    'vaquais',
    'vaquait',
    'vaquant',
    'vaquent',
    'vaquera',
    'vaquiez',
    'vaquons',
    'varappa',
    'varappe',
    'varappé',
    'varechs',
    'vareuse',
    'variais',
    'variait',
    'variant',
    'varices',
    'variées',
    'varient',
    'variera',
    'variété',
    'variiez',
    'variole',
    'variolé',
    'varions',
    'varlets',
    'varlopa',
    'varlope',
    'varlopé',
    'varrons',
    'vasarde',
    'vasards',
    'vaseuse',
    'vasière',
    'vasques',
    'vassale',
    'vassaux',
    'va-tout',
    'vaudois',
    'vaudoue',
    'vaudous',
    'vaudrai',
    'vaudras',
    'vaudrez',
    'vaurien',
    'vautour',
    'vautrai',
    'vautras',
    'vautrât',
    'vautrée',
    'vautrer',
    'vautres',
    'vautrés',
    'vautrez',
    'va-vite',
    'vecteur',
    'vécûmes',
    'vécusse',
    'vécûtes',
    'vedette',
    'védique',
    'védisme',
    'végétai',
    'végétal',
    'végétas',
    'végétât',
    'végéter',
    'végètes',
    'végétez',
    'veillai',
    'veillas',
    'veillât',
    'veillée',
    'veiller',
    'veilles',
    'veillés',
    'veillez',
    'veinais',
    'veinait',
    'veinant',
    'veinard',
    'veinées',
    'veinent',
    'veinera',
    'veineux',
    'veiniez',
    'veinons',
    'veinule',
    'veinure',
    'vêlages',
    'vélaire',
    'vêlâmes',
    'vêlasse',
    'vêlâtes',
    'velches',
    'vêlerai',
    'vêleras',
    'vêlerez',
    'vêlions',
    'vélique',
    'vélites',
    'véloces',
    'véloski',
    'velours',
    'velouta',
    'veloute',
    'velouté',
    'vénales',
    'vendais',
    'vendait',
    'vendant',
    'vendéen',
    'vendent',
    'vendeur',
    'vendiez',
    'vendons',
    'vendrai',
    'vendras',
    'vendrez',
    'vendues',
    'venelle',
    'vénérai',
    'vénéras',
    'vénérât',
    'vénérée',
    'vénérer',
    'vénérés',
    'vénères',
    'vénérez',
    'vénerie',
    'venette',
    'veneurs',
    'vengeai',
    'vengeas',
    'vengeât',
    'vengées',
    'vengent',
    'vengera',
    'vengeur',
    'vengiez',
    'véniels',
    'venions',
    'ventage',
    'ventail',
    'ventait',
    'ventera',
    'venteux',
    'ventila',
    'ventile',
    'ventilé',
    'ventôse',
    'ventral',
    'ventrée',
    'ventres',
    'ventrue',
    'ventrus',
    'venturi',
    'vénusté',
    'véranda',
    'vératre',
    'verbale',
    'verbaux',
    'verbeux',
    'verdage',
    'verdets',
    'verdeur',
    'verdict',
    'verdier',
    'verdies',
    'verdira',
    'verdoie',
    'verdoya',
    'verdoyé',
    'verdure',
    'véreuse',
    'vergées',
    'vergers',
    'vergeté',
    'verglas',
    'vergnes',
    'vergues',
    'vérifia',
    'vérifie',
    'vérifié',
    'vérines',
    'vérisme',
    'vériste',
    'vérités',
    'verjuta',
    'verjute',
    'verjuté',
    'verlans',
    'vermées',
    'vermeil',
    'vermets',
    'vermine',
    'vermout',
    'vernale',
    'vernaux',
    'vernier',
    'vernies',
    'vernira',
    'vérolée',
    'véroles',
    'vérolés',
    'véronal',
    'verrais',
    'verrait',
    'verrats',
    'verrées',
    'verrier',
    'verriez',
    'verrine',
    'verrons',
    'verront',
    'verrous',
    'verrues',
    'versais',
    'versait',
    'versant',
    'verseau',
    'versées',
    'versent',
    'versera',
    'versets',
    'verseur',
    'versiez',
    'version',
    'versoir',
    'versons',
    'verstes',
    'vertige',
    'vertigo',
    'verveux',
    'vésanie',
    'vésical',
    'vessais',
    'vessait',
    'vessant',
    'vessent',
    'vessera',
    'vessies',
    'vessiez',
    'vessons',
    'vestale',
    'vestige',
    'vestons',
    'vétéran',
    'vétilla',
    'vétille',
    'vétillé',
    'vêtîmes',
    'vêtions',
    'vêtirai',
    'vêtiras',
    'vêtirez',
    'vêtisse',
    'vêtîtes',
    'vétiver',
    'vêtures',
    'vétuste',
    'vétusté',
    'veuille',
    'veulent',
    'veuvage',
    'vexâmes',
    'vexante',
    'vexants',
    'vexasse',
    'vexâtes',
    'vexerai',
    'vexeras',
    'vexerez',
    'vexille',
    'vexions',
    'viables',
    'viaducs',
    'viagère',
    'viagers',
    'viandai',
    'viandas',
    'viandât',
    'viandée',
    'viander',
    'viandes',
    'viandés',
    'viandez',
    'vibords',
    'vibrage',
    'vibrais',
    'vibrait',
    'vibrant',
    'vibrato',
    'vibrées',
    'vibrent',
    'vibrera',
    'vibreur',
    'vibriez',
    'vibrion',
    'vibrons',
    'vicaire',
    'viciais',
    'viciait',
    'viciant',
    'viciées',
    'vicient',
    'viciera',
    'vicieux',
    'viciiez',
    'vicinal',
    'vicions',
    'vicomte',
    'victime',
    'vidages',
    'vidames',
    'vidâmes',
    'vidamie',
    'vidange',
    'vidangé',
    'vidasse',
    'vidâtes',
    'videlle',
    'viderai',
    'videras',
    'viderez',
    'videurs',
    'videuse',
    'vidimai',
    'vidimas',
    'vidimât',
    'vidimée',
    'vidimer',
    'vidimes',
    'vidimés',
    'vidimez',
    'vidimus',
    'vidions',
    'vidoirs',
    'viduité',
    'vidures',
    'vieille',
    'vieilli',
    'viellai',
    'viellas',
    'viellât',
    'vieller',
    'vielles',
    'viellez',
    'viendra',
    'viennes',
    'vierges',
    'vigiles',
    'vigneau',
    'vignots',
    'vigogne',
    'vigueur',
    'viguier',
    'vikings',
    'vilaine',
    'vilains',
    'vilenie',
    'village',
    'vinages',
    'vinaire',
    'vinâmes',
    'vinasse',
    'vinâtes',
    'vinerai',
    'vineras',
    'vinerez',
    'vineuse',
    'vinifia',
    'vinifie',
    'vinifié',
    'vinions',
    'vinique',
    'vinrent',
    'vinsses',
    'vinyles',
    'violaça',
    'violace',
    'violacé',
    'violais',
    'violait',
    'violant',
    'violats',
    'violées',
    'violent',
    'violera',
    'violets',
    'violeur',
    'violier',
    'violiez',
    'violine',
    'violona',
    'violone',
    'violoné',
    'violons',
    'vioques',
    'vioquir',
    'vioquis',
    'vioquit',
    'vioquît',
    'viornes',
    'vipères',
    'vipérin',
    'virages',
    'viragos',
    'virales',
    'virâmes',
    'virasse',
    'virâtes',
    'virelai',
    'virerai',
    'vireras',
    'virerez',
    'vireton',
    'vireurs',
    'vireuse',
    'virgula',
    'virgule',
    'virgulé',
    'viriles',
    'virions',
    'virolai',
    'virolas',
    'virolât',
    'virolée',
    'viroler',
    'viroles',
    'virolés',
    'virolez',
    'viroses',
    'virtuel',
    'virures',
    'visages',
    'visâmes',
    'visasse',
    'visâtes',
    'viscère',
    'viscose',
    'viserai',
    'viseras',
    'viserez',
    'viseurs',
    'viseuse',
    'visible',
    'visière',
    'visions',
    'visitai',
    'visitas',
    'visitât',
    'visitée',
    'visiter',
    'visites',
    'visités',
    'visitez',
    'visnage',
    'vissage',
    'vissais',
    'vissait',
    'vissant',
    'vissées',
    'vissent',
    'vissera',
    'vissiez',
    'vissons',
    'visuels',
    'vitales',
    'vitesse',
    'vitrage',
    'vitrail',
    'vitrais',
    'vitrait',
    'vitrant',
    'vitraux',
    'vitrées',
    'vitrent',
    'vitrera',
    'vitreux',
    'vitrier',
    'vitriez',
    'vitrine',
    'vitriol',
    'vitrons',
    'vivable',
    'vivaces',
    'vivante',
    'vivants',
    'vivendi',
    'viveurs',
    'viveuse',
    'viviers',
    'vivifia',
    'vivifie',
    'vivifié',
    'vivions',
    'vivotai',
    'vivotas',
    'vivotât',
    'vivoter',
    'vivotes',
    'vivotez',
    'vivrais',
    'vivrait',
    'vivrées',
    'vivrier',
    'vivriez',
    'vivrons',
    'vivront',
    'vizirat',
    'vocable',
    'vocales',
    'vocatif',
    'vogoule',
    'voguais',
    'voguait',
    'voguant',
    'voguent',
    'voguera',
    'voguiez',
    'voguons',
    'voilage',
    'voilais',
    'voilait',
    'voilant',
    'voilées',
    'voilent',
    'voilera',
    'voilier',
    'voiliez',
    'voilons',
    'voilure',
    'voiries',
    'voisina',
    'voisine',
    'voisiné',
    'voisins',
    'voitura',
    'voiture',
    'voituré',
    'voïvode',
    'volable',
    'volages',
    'volâmes',
    'volante',
    'volants',
    'volapük',
    'volasse',
    'volâtes',
    'volatil',
    'volcans',
    'volerai',
    'voleras',
    'volerez',
    'volerie',
    'voletai',
    'voletas',
    'voletât',
    'voleter',
    'voletez',
    'volette',
    'voleurs',
    'voleuse',
    'volière',
    'voligea',
    'voligée',
    'voliger',
    'voliges',
    'voligés',
    'voligez',
    'volions',
    'volitif',
    'volleys',
    'volonté',
    'voltage',
    'voltais',
    'voltait',
    'voltant',
    'voltent',
    'voltera',
    'voltiez',
    'voltige',
    'voltigé',
    'voltons',
    'volumes',
    'volupté',
    'volutes',
    'vomîmes',
    'vomique',
    'vomirai',
    'vomiras',
    'vomirez',
    'vomisse',
    'vomîtes',
    'vomitif',
    'voraces',
    'votâmes',
    'votante',
    'votants',
    'votasse',
    'votâtes',
    'voterai',
    'voteras',
    'voterez',
    'votions',
    'votives',
    'vouâmes',
    'vouasse',
    'vouâtes',
    'voudrai',
    'voudras',
    'voudrez',
    'vouerai',
    'voueras',
    'vouerez',
    'vouions',
    'vouivre',
    'voulais',
    'voulait',
    'voulant',
    'vouliez',
    'vouloir',
    'voulons',
    'voulues',
    'vousoie',
    'vousoya',
    'vousoyé',
    'voûtais',
    'voûtait',
    'voûtant',
    'voûtées',
    'voûtent',
    'voûtera',
    'voûtiez',
    'voûtons',
    'vouvoie',
    'vouvoya',
    'vouvoyé',
    'voyagea',
    'voyager',
    'voyages',
    'voyagez',
    'voyance',
    'voyante',
    'voyants',
    'voyelle',
    'voyeurs',
    'voyeuse',
    'voyions',
    'vreneli',
    'vrillai',
    'vrillas',
    'vrillât',
    'vrillée',
    'vriller',
    'vrilles',
    'vrillés',
    'vrillez',
    'vrombir',
    'vrombis',
    'vrombit',
    'vrombît',
    'vulcain',
    'vulgate',
    'vulpins',
    'wadings',
    'wagages',
    'Walkman',
    'wallaby',
    'wallons',
    'wapitis',
    'wargame',
    'warrant',
    'wattman',
    'wattmen',
    'welches',
    'welters',
    'wergeld',
    'western',
    'whiskys',
    'wigwams',
    'wilayas',
    'winches',
    'witloof',
    'wolfram',
    'wombats',
    'woofers',
    'wormien',
    'wurmien',
    'xanthie',
    'ximénie',
    'xylènes',
    'yankees',
    'yaourts',
    'yatagan',
    'yiddish',
    'yogourt',
    'youpine',
    'youpins',
    'yourtes',
    'youyous',
    'ypérite',
    'ypréaux',
    'ysopets',
    'yttrias',
    'yttrium',
    'zaïrois',
    'zambien',
    'zancles',
    'zaouïas',
    'zébrais',
    'zébrait',
    'zébrant',
    'zébrées',
    'zèbrent',
    'zébrera',
    'zébriez',
    'zébrons',
    'zébrure',
    'zellige',
    'zélotes',
    'zenanas',
    'zéniths',
    'zéolite',
    'zéphyrs',
    'zestais',
    'zestait',
    'zestant',
    'zestées',
    'zestent',
    'zestera',
    'zestiez',
    'zestons',
    'zétètes',
    'zeugmas',
    'zeugmes',
    'zeuzère',
    'zézaies',
    'zézayai',
    'zézayas',
    'zézayât',
    'zézayée',
    'zézayer',
    'zézayes',
    'zézayés',
    'zézayez',
    'zibâmes',
    'zibasse',
    'zibâtes',
    'ziberai',
    'ziberas',
    'ziberez',
    'zibions',
    'zieutai',
    'zieutas',
    'zieutât',
    'zieutée',
    'zieuter',
    'zieutes',
    'zieutés',
    'zieutez',
    'zigotos',
    'ziguais',
    'ziguait',
    'ziguant',
    'ziguées',
    'ziguent',
    'ziguera',
    'ziguiez',
    'ziguons',
    'zigzags',
    'zincage',
    'zingage',
    'zingari',
    'zingaro',
    'zinguai',
    'zinguas',
    'zinguât',
    'zinguée',
    'zinguer',
    'zingues',
    'zingués',
    'zinguez',
    'zinnias',
    'zinzins',
    'zippais',
    'zippait',
    'zippant',
    'zippées',
    'zippent',
    'zippera',
    'zippiez',
    'zippons',
    'zircone',
    'zircons',
    'zizanie',
    'zombies',
    'zonages',
    'zonales',
    'zonâmes',
    'zonarde',
    'zonards',
    'zonasse',
    'zonâtes',
    'zonerai',
    'zoneras',
    'zonerez',
    'zoniers',
    'zonions',
    'zonures',
    'zooglée',
    'zooïdes',
    'zoolite',
    'zoonose',
    'zoopsie',
    'zorille',
    'zostère',
    'zouaves',
    'zozotai',
    'zozotas',
    'zozotât',
    'zozoter',
    'zozotes',
    'zozotez',
    'zutique',
    'zutiste',
    'zwanzes',
    'zygènes',
    'zygomas',
    'zygotes',
    'zymases',
    'zythons',
    'zythums'
];
