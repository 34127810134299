export default [
    'abaissais',
    'abaissait',
    'abaissant',
    'abaissées',
    'abaissent',
    'abaissera',
    'abaisseur',
    'abaissiez',
    'abaissons',
    'abandonna',
    'abandonne',
    'abandonné',
    'abasourdi',
    'abâtardie',
    'abâtardir',
    'abâtardis',
    'abâtardit',
    'abâtardît',
    'abat-jour',
    'abattages',
    'abattante',
    'abattants',
    'abatteurs',
    'abattîmes',
    'abattions',
    'abattisse',
    'abattîtes',
    'abattoirs',
    'abattrais',
    'abattrait',
    'abattriez',
    'abattrons',
    'abattront',
    'abat-vent',
    'abat-voix',
    'abbatiale',
    'abbatiaux',
    'abdiquais',
    'abdiquait',
    'abdiquant',
    'abdiquées',
    'abdiquent',
    'abdiquera',
    'abdiquiez',
    'abdiquons',
    'abdominal',
    'abducteur',
    'abduction',
    'aberrâmes',
    'aberrance',
    'aberrante',
    'aberrants',
    'aberrasse',
    'aberrâtes',
    'aberrerai',
    'aberreras',
    'aberrerez',
    'aberrions',
    'abêtirais',
    'abêtirait',
    'abêtirent',
    'abêtiriez',
    'abêtirons',
    'abêtiront',
    'abêtisses',
    'abêtissez',
    'abhorrais',
    'abhorrait',
    'abhorrant',
    'abhorrées',
    'abhorrent',
    'abhorrera',
    'abhorriez',
    'abhorrons',
    'abîmaient',
    'abîmasses',
    'abîmerais',
    'abîmerait',
    'abîmèrent',
    'abîmeriez',
    'abîmerons',
    'abîmeront',
    'abiotique',
    'abjection',
    'abjurâmes',
    'abjurasse',
    'abjurâtes',
    'abjurerai',
    'abjureras',
    'abjurerez',
    'abjurions',
    'ablations',
    'ablatives',
    'abloquais',
    'abloquait',
    'abloquant',
    'abloquées',
    'abloquent',
    'abloquera',
    'abloquiez',
    'abloquons',
    'ablutions',
    'aboiement',
    'aboierais',
    'aboierait',
    'aboieriez',
    'aboierons',
    'aboieront',
    'abolirais',
    'abolirait',
    'abolirent',
    'aboliriez',
    'abolirons',
    'aboliront',
    'abolisses',
    'abolissez',
    'abolition',
    'abominais',
    'abominait',
    'abominant',
    'abominées',
    'abominent',
    'abominera',
    'abominiez',
    'abominons',
    'abondâmes',
    'abondance',
    'abondante',
    'abondants',
    'abondasse',
    'abondâtes',
    'abonderai',
    'abonderas',
    'abonderez',
    'abondions',
    'abonnâmes',
    'abonnasse',
    'abonnâtes',
    'abonnerai',
    'abonneras',
    'abonnerez',
    'abonnîmes',
    'abonnions',
    'abonnirai',
    'abonniras',
    'abonnirez',
    'abonnisse',
    'abonnîtes',
    'abordable',
    'abordages',
    'abordâmes',
    'abordasse',
    'abordâtes',
    'aborderai',
    'aborderas',
    'aborderez',
    'abordions',
    'aborigène',
    'abortives',
    'abouchais',
    'abouchait',
    'abouchant',
    'abouchées',
    'abouchent',
    'abouchera',
    'abouchiez',
    'abouchons',
    'aboulâmes',
    'aboulasse',
    'aboulâtes',
    'aboulerai',
    'abouleras',
    'aboulerez',
    'aboulions',
    'aboulique',
    'aboutâmes',
    'aboutasse',
    'aboutâtes',
    'abouterai',
    'abouteras',
    'abouterez',
    'aboutîmes',
    'aboutions',
    'aboutirai',
    'aboutiras',
    'aboutirez',
    'aboutisse',
    'aboutîtes',
    'aboyaient',
    'aboyasses',
    'aboyèrent',
    'aboyeuses',
    'abrasâmes',
    'abrasasse',
    'abrasâtes',
    'abraserai',
    'abraseras',
    'abraserez',
    'abrasions',
    'abrasives',
    'abrégeais',
    'abrégeait',
    'abrégeant',
    'abrégeons',
    'abrégerai',
    'abrégeras',
    'abrégerez',
    'abrégions',
    'abreuvais',
    'abreuvait',
    'abreuvant',
    'abreuvées',
    'abreuvent',
    'abreuvera',
    'abreuviez',
    'abreuvoir',
    'abreuvons',
    'abricotai',
    'abricotas',
    'abricotât',
    'abricotée',
    'abricoter',
    'abricotes',
    'abricotés',
    'abricotez',
    'abritâmes',
    'abritasse',
    'abritâtes',
    'abriterai',
    'abriteras',
    'abriterez',
    'abritions',
    'abrivents',
    'abrogatif',
    'abrogeais',
    'abrogeait',
    'abrogeant',
    'abrogeons',
    'abrogerai',
    'abrogeras',
    'abrogerez',
    'abrogions',
    'abrutîmes',
    'abrutirai',
    'abrutiras',
    'abrutirez',
    'abrutisse',
    'abrutîtes',
    'abscisses',
    'absconses',
    'absentées',
    'absentiez',
    'absentons',
    'absinthes',
    'absolvais',
    'absolvait',
    'absolvant',
    'absolvent',
    'absolviez',
    'absolvons',
    'absorbais',
    'absorbait',
    'absorbant',
    'absorbées',
    'absorbent',
    'absorbera',
    'absorbeur',
    'absorbiez',
    'absorbons',
    'absoudrai',
    'absoudras',
    'absoudrez',
    'absteniez',
    'abstenons',
    'abstenues',
    'abstinent',
    'abstînmes',
    'abstîntes',
    'abstraies',
    'abstraira',
    'abstraire',
    'abstraite',
    'abstraits',
    'abstrayez',
    'abstruses',
    'absurdité',
    'abusaient',
    'abusasses',
    'abuserais',
    'abuserait',
    'abusèrent',
    'abuseriez',
    'abuserons',
    'abuseront',
    'abyssales',
    'abyssines',
    'académies',
    'acadienne',
    'acagnardé',
    'acalèphes',
    'acariâtre',
    'accablais',
    'accablait',
    'accablant',
    'accablées',
    'accablent',
    'accablera',
    'accabliez',
    'accablons',
    'accalmies',
    'accaparai',
    'accaparas',
    'accaparât',
    'accaparée',
    'accaparer',
    'accapares',
    'accaparés',
    'accaparez',
    'accédâmes',
    'accédasse',
    'accédâtes',
    'accéderai',
    'accéderas',
    'accéderez',
    'accédions',
    'accélérai',
    'accéléras',
    'accélérât',
    'accélérée',
    'accélérer',
    'accélérés',
    'accélères',
    'accélérez',
    'accentuai',
    'accentuas',
    'accentuât',
    'accentuée',
    'accentuel',
    'accentuer',
    'accentues',
    'accentués',
    'accentuez',
    'acceptais',
    'acceptait',
    'acceptant',
    'acceptées',
    'acceptent',
    'acceptera',
    'accepteur',
    'acceptiez',
    'acception',
    'acceptons',
    'accession',
    'accessits',
    'accidenta',
    'accidente',
    'accidenté',
    'accidents',
    'acclamais',
    'acclamait',
    'acclamant',
    'acclamées',
    'acclament',
    'acclamera',
    'acclamiez',
    'acclamons',
    'acclimata',
    'acclimate',
    'acclimaté',
    'accointée',
    'accointer',
    'accointés',
    'accointez',
    'accolades',
    'accolâmes',
    'accolasse',
    'accolâtes',
    'accolerai',
    'accoleras',
    'accolerez',
    'accolions',
    'accommoda',
    'accommode',
    'accommodé',
    'accomplie',
    'accomplir',
    'accomplis',
    'accomplit',
    'accomplît',
    'accorâmes',
    'accorasse',
    'accorâtes',
    'accordais',
    'accordait',
    'accordant',
    'accordées',
    'accordent',
    'accordéon',
    'accordera',
    'accordeur',
    'accordiez',
    'accordoir',
    'accordons',
    'accorerai',
    'accoreras',
    'accorerez',
    'accorions',
    'accostage',
    'accostais',
    'accostait',
    'accostant',
    'accostées',
    'accostent',
    'accostera',
    'accostiez',
    'accostons',
    'accotâmes',
    'accotasse',
    'accotâtes',
    'accoterai',
    'accoteras',
    'accoterez',
    'accotions',
    'accotoirs',
    'accouâmes',
    'accouasse',
    'accouâtes',
    'accouchai',
    'accouchas',
    'accouchât',
    'accouchée',
    'accoucher',
    'accouches',
    'accouchés',
    'accouchez',
    'accoudées',
    'accoudiez',
    'accoudoir',
    'accoudons',
    'accouerai',
    'accoueras',
    'accouerez',
    'accouions',
    'accouplai',
    'accouplas',
    'accouplât',
    'accouplée',
    'accoupler',
    'accouples',
    'accouplés',
    'accouplez',
    'accourais',
    'accourait',
    'accourant',
    'accourcir',
    'accourcis',
    'accourcit',
    'accourcît',
    'accourent',
    'accouriez',
    'accourons',
    'accourrai',
    'accourras',
    'accourrez',
    'accourues',
    'accoutrai',
    'accoutras',
    'accoutrât',
    'accoutrée',
    'accoutrer',
    'accoutres',
    'accoutrés',
    'accoutrez',
    'accoutuma',
    'accoutume',
    'accoutumé',
    'accouvage',
    'accrédita',
    'accrédite',
    'accrédité',
    'accrétion',
    'accrochai',
    'accrochas',
    'accrochât',
    'accrochée',
    'accrocher',
    'accroches',
    'accrochés',
    'accrochez',
    'accroisse',
    'accroîtra',
    'accroître',
    'accroupie',
    'accroupir',
    'accroupis',
    'accrurent',
    'accrusses',
    'accueille',
    'accueilli',
    'acculâmes',
    'acculasse',
    'acculâtes',
    'acculerai',
    'acculeras',
    'acculerez',
    'acculions',
    'accultura',
    'acculture',
    'acculturé',
    'accumulai',
    'accumulas',
    'accumulât',
    'accumulée',
    'accumuler',
    'accumules',
    'accumulés',
    'accumulez',
    'accusâmes',
    'accusasse',
    'accusâtes',
    'accusatif',
    'accuserai',
    'accuseras',
    'accuserez',
    'accusions',
    'acéphales',
    'acéraient',
    'acérasses',
    'acerbités',
    'acérerais',
    'acérerait',
    'acérèrent',
    'acéreriez',
    'acérerons',
    'acéreront',
    'acescence',
    'acescente',
    'acescents',
    'acétamide',
    'acétifiai',
    'acétifias',
    'acétifiât',
    'acétifiée',
    'acétifier',
    'acétifies',
    'acétifiés',
    'acétifiez',
    'acétiques',
    'acétylais',
    'acétylait',
    'acétylant',
    'acétylées',
    'acétylène',
    'acétylent',
    'acétylera',
    'acétyliez',
    'acétylons',
    'achalanda',
    'achalande',
    'achalandé',
    'acharnais',
    'acharnait',
    'acharnant',
    'acharnées',
    'acharnent',
    'acharnera',
    'acharniez',
    'acharnons',
    'achéennes',
    'acheminai',
    'acheminas',
    'acheminât',
    'acheminée',
    'acheminer',
    'achemines',
    'acheminés',
    'acheminez',
    'achetable',
    'achetâmes',
    'achetasse',
    'achetâtes',
    'achèterai',
    'achèteras',
    'achèterez',
    'acheteurs',
    'acheteuse',
    'achetions',
    'acheuléen',
    'achevâmes',
    'achevasse',
    'achevâtes',
    'achèverai',
    'achèveras',
    'achèverez',
    'achevions',
    'achillées',
    'achoppées',
    'achoppiez',
    'achoppons',
    'achromats',
    'achromies',
    'acidalies',
    'acidifiai',
    'acidifias',
    'acidifiât',
    'acidifiée',
    'acidifier',
    'acidifies',
    'acidifiés',
    'acidifiez',
    'acidulais',
    'acidulait',
    'acidulant',
    'acidulées',
    'acidulent',
    'acidulera',
    'aciduliez',
    'acidulons',
    'aciérages',
    'aciérâmes',
    'aciérasse',
    'aciérâtes',
    'aciérerai',
    'aciéreras',
    'aciérerez',
    'aciérions',
    'aciselais',
    'aciselait',
    'aciselant',
    'aciselées',
    'acisèlent',
    'acisèlera',
    'aciseliez',
    'aciselons',
    'aclinique',
    'acnéiques',
    'aconitine',
    'acoquinée',
    'acoquiner',
    'acoquinés',
    'acoquinez',
    'acouphène',
    'acquérais',
    'acquérait',
    'acquérant',
    'acquéreur',
    'acquériez',
    'acquérons',
    'acquerrai',
    'acquerras',
    'acquerrez',
    'acquières',
    'acquiesça',
    'acquiesce',
    'acquiescé',
    'acquirent',
    'acquisses',
    'acquittai',
    'acquittas',
    'acquittât',
    'acquittée',
    'acquitter',
    'acquittes',
    'acquittés',
    'acquittez',
    'acrimonie',
    'acrobates',
    'acrobatie',
    'acroléine',
    'acromions',
    'acronymes',
    'acropoles',
    'acrotères',
    'acrylique',
    'actassent',
    'actassiez',
    'acterions',
    'actinique',
    'actinisme',
    'actinites',
    'actiniums',
    'actinotes',
    'actionnai',
    'actionnas',
    'actionnât',
    'actionnée',
    'actionner',
    'actionnes',
    'actionnés',
    'actionnez',
    'activâmes',
    'activasse',
    'activâtes',
    'activerai',
    'activeras',
    'activerez',
    'activeurs',
    'activions',
    'activisme',
    'activiste',
    'activités',
    'actuaires',
    'actualisa',
    'actualise',
    'actualisé',
    'actualité',
    'actuariat',
    'actuariel',
    'actuelles',
    'acuminées',
    'acutangle',
    'adamantin',
    'adamismes',
    'adaptable',
    'adaptâmes',
    'adaptasse',
    'adaptâtes',
    'adaptatif',
    'adapterai',
    'adapteras',
    'adapterez',
    'adaptions',
    'additions',
    'additives',
    'adducteur',
    'adduction',
    'adénoïdes',
    'adénosine',
    'adéquates',
    'adextrées',
    'adhérâmes',
    'adhérante',
    'adhérants',
    'adhérasse',
    'adhérâtes',
    'adhérence',
    'adhérente',
    'adhérents',
    'adhérerai',
    'adhéreras',
    'adhérerez',
    'adhérions',
    'adhésions',
    'adhésives',
    'adipeuses',
    'adipiques',
    'adipolyse',
    'adiposité',
    'adjacence',
    'adjacente',
    'adjacents',
    'adjectifs',
    'adjectiva',
    'adjective',
    'adjectivé',
    'adjoignes',
    'adjoignez',
    'adjoignis',
    'adjoignit',
    'adjoignît',
    'adjoindra',
    'adjoindre',
    'adjointes',
    'adjudante',
    'adjudants',
    'adjugeais',
    'adjugeait',
    'adjugeant',
    'adjugeons',
    'adjugerai',
    'adjugeras',
    'adjugerez',
    'adjugions',
    'adjurâmes',
    'adjurasse',
    'adjurâtes',
    'adjurerai',
    'adjureras',
    'adjurerez',
    'adjurions',
    'adjuvante',
    'adjuvants',
    'admettais',
    'admettait',
    'admettant',
    'admettent',
    'admettiez',
    'admettons',
    'admettrai',
    'admettras',
    'admettrez',
    'admirable',
    'admirâmes',
    'admirasse',
    'admirâtes',
    'admiratif',
    'admirerai',
    'admireras',
    'admirerez',
    'admirions',
    'admissent',
    'admissiez',
    'admission',
    'admixtion',
    'admonesta',
    'admoneste',
    'admonesté',
    'adonisées',
    'adonisiez',
    'adonisons',
    'adonnâmes',
    'adonnâtes',
    'adonnerez',
    'adonnions',
    'adoptable',
    'adoptâmes',
    'adoptante',
    'adoptants',
    'adoptasse',
    'adoptâtes',
    'adopterai',
    'adopteras',
    'adopterez',
    'adoptions',
    'adoptives',
    'adorables',
    'adoraient',
    'adorasses',
    'adorateur',
    'adoration',
    'adorerais',
    'adorerait',
    'adorèrent',
    'adoreriez',
    'adorerons',
    'adoreront',
    'adossâmes',
    'adossasse',
    'adossâtes',
    'adosserai',
    'adosseras',
    'adosserez',
    'adossions',
    'adoubâmes',
    'adoubasse',
    'adoubâtes',
    'adouberai',
    'adouberas',
    'adouberez',
    'adoubions',
    'adoucîmes',
    'adoucirai',
    'adouciras',
    'adoucirez',
    'adoucisse',
    'adoucîtes',
    'adragante',
    'adressage',
    'adressais',
    'adressait',
    'adressant',
    'adressées',
    'adressent',
    'adressera',
    'adressiez',
    'adressons',
    'adsorbant',
    'adulaient',
    'adulasses',
    'adulateur',
    'adulation',
    'adulerais',
    'adulerait',
    'adulèrent',
    'aduleriez',
    'adulerons',
    'aduleront',
    'adultérai',
    'adultéras',
    'adultérât',
    'adultérée',
    'adultérer',
    'adultérés',
    'adultères',
    'adultérez',
    'adultérin',
    'adultisme',
    'advection',
    'adventifs',
    'adventive',
    'adverbial',
    'adversité',
    'adviendra',
    'adynamies',
    'aegosomes',
    'aérassent',
    'aérassiez',
    'aérateurs',
    'aérations',
    'aérerions',
    'aériennes',
    'aéro-club',
    'aérodrome',
    'aérogares',
    'aérolites',
    'aérolithe',
    'aérologie',
    'aéronaute',
    'aéronaval',
    'aéroplane',
    'aéroporté',
    'aéroports',
    'aérostats',
    'aérotrain',
    'affabulai',
    'affabulas',
    'affabulât',
    'affabulée',
    'affabuler',
    'affabules',
    'affabulés',
    'affabulez',
    'affadîmes',
    'affadirai',
    'affadiras',
    'affadirez',
    'affadisse',
    'affadîtes',
    'affaiblie',
    'affaiblir',
    'affaiblis',
    'affaiblit',
    'affaiblît',
    'affairées',
    'affairiez',
    'affairons',
    'affaissai',
    'affaissas',
    'affaissât',
    'affaissée',
    'affaisser',
    'affaisses',
    'affaissés',
    'affaissez',
    'affaitage',
    'affalâmes',
    'affalasse',
    'affalâtes',
    'affalerai',
    'affaleras',
    'affalerez',
    'affalions',
    'affamâmes',
    'affamasse',
    'affamâtes',
    'affamerai',
    'affameras',
    'affamerez',
    'affameurs',
    'affamions',
    'afféageai',
    'afféageas',
    'afféageât',
    'afféagées',
    'afféagent',
    'afféagera',
    'afféagiez',
    'affectais',
    'affectait',
    'affectant',
    'affectées',
    'affectent',
    'affectera',
    'affectiez',
    'affectifs',
    'affection',
    'affective',
    'affectons',
    'affenages',
    'afférâmes',
    'afférasse',
    'afférâtes',
    'afférente',
    'afférents',
    'afférerai',
    'afféreras',
    'afférerez',
    'afférions',
    'affermage',
    'affermais',
    'affermait',
    'affermant',
    'affermées',
    'afferment',
    'affermera',
    'affermies',
    'affermiez',
    'affermira',
    'affermons',
    'afféterie',
    'affichage',
    'affichais',
    'affichait',
    'affichant',
    'affichées',
    'affichent',
    'affichera',
    'afficheur',
    'affichiez',
    'affichons',
    'affidavit',
    'affilages',
    'affilâmes',
    'affilasse',
    'affilâtes',
    'affilerai',
    'affileras',
    'affilerez',
    'affiliais',
    'affiliait',
    'affiliant',
    'affiliées',
    'affilient',
    'affiliera',
    'affiliiez',
    'affilions',
    'affiloirs',
    'affinages',
    'affinâmes',
    'affinasse',
    'affinâtes',
    'affinerai',
    'affineras',
    'affinerez',
    'affineurs',
    'affineuse',
    'affinions',
    'affinités',
    'affiquets',
    'affirmais',
    'affirmait',
    'affirmant',
    'affirmées',
    'affirment',
    'affirmera',
    'affirmiez',
    'affirmons',
    'affixales',
    'affleurai',
    'affleuras',
    'affleurât',
    'affleurée',
    'affleurer',
    'affleures',
    'affleurés',
    'affleurez',
    'afflictif',
    'affligeai',
    'affligeas',
    'affligeât',
    'affligées',
    'affligent',
    'affligera',
    'affligiez',
    'afflouais',
    'afflouait',
    'afflouant',
    'afflouées',
    'afflouent',
    'afflouera',
    'afflouiez',
    'afflouons',
    'affluâmes',
    'affluasse',
    'affluâtes',
    'affluence',
    'affluente',
    'affluents',
    'affluerai',
    'afflueras',
    'affluerez',
    'affluions',
    'affolâmes',
    'affolante',
    'affolants',
    'affolasse',
    'affolâtes',
    'affolerai',
    'affoleras',
    'affolerez',
    'affolions',
    'affouagea',
    'affouagée',
    'affouager',
    'affouages',
    'affouagés',
    'affouagez',
    'affouilla',
    'affouille',
    'affouillé',
    'affourage',
    'affouragé',
    'affourcha',
    'affourche',
    'affourché',
    'affranchi',
    'affrétais',
    'affrétait',
    'affrétant',
    'affrétées',
    'affrètent',
    'affrétera',
    'affréteur',
    'affrétiez',
    'affrétons',
    'affreuses',
    'affrianda',
    'affriande',
    'affriandé',
    'affrichai',
    'affrichas',
    'affrichât',
    'affrichée',
    'affricher',
    'affriches',
    'affrichés',
    'affrichez',
    'affriolai',
    'affriolas',
    'affriolât',
    'affriolée',
    'affrioler',
    'affrioles',
    'affriolés',
    'affriolez',
    'affriquée',
    'affritais',
    'affritait',
    'affritant',
    'affritées',
    'affritent',
    'affritera',
    'affritiez',
    'affritons',
    'affrontai',
    'affrontas',
    'affrontât',
    'affrontée',
    'affronter',
    'affrontes',
    'affrontés',
    'affrontez',
    'affruitai',
    'affruitas',
    'affruitât',
    'affruiter',
    'affruites',
    'affruitez',
    'affublais',
    'affublait',
    'affublant',
    'affublées',
    'affublent',
    'affublera',
    'affubliez',
    'affublons',
    'affurâmes',
    'affurasse',
    'affurâtes',
    'affurerai',
    'affureras',
    'affurerez',
    'affurions',
    'affusions',
    'affûtages',
    'affûtâmes',
    'affûtasse',
    'affûtâtes',
    'affûterai',
    'affûteras',
    'affûterez',
    'affûteurs',
    'affûteuse',
    'affûtiaux',
    'affûtions',
    'africaine',
    'africains',
    'afrikaans',
    'afrikaner',
    'agaçaient',
    'agaçantes',
    'agaçasses',
    'agacement',
    'agacerais',
    'agacerait',
    'agacèrent',
    'agaceries',
    'agaceriez',
    'agacerons',
    'agaceront',
    'agalactie',
    'agatisées',
    'agençâmes',
    'agençasse',
    'agençâtes',
    'agencerai',
    'agenceras',
    'agencerez',
    'agencions',
    'agénésies',
    'aggloméra',
    'aggloméré',
    'agglomère',
    'agglutina',
    'agglutine',
    'agglutiné',
    'aggravais',
    'aggravait',
    'aggravant',
    'aggravées',
    'aggravent',
    'aggravera',
    'aggraviez',
    'aggravons',
    'agilement',
    'agiotages',
    'agiotâmes',
    'agiotasse',
    'agiotâtes',
    'agioterai',
    'agioteras',
    'agioterez',
    'agioteurs',
    'agiotions',
    'agiraient',
    'agissante',
    'agissions',
    'agitaient',
    'agitasses',
    'agitateur',
    'agitation',
    'agiterais',
    'agiterait',
    'agitèrent',
    'agiteriez',
    'agiterons',
    'agiteront',
    'agnelages',
    'agnelâmes',
    'agnelasse',
    'agnelâtes',
    'agnelines',
    'agnelions',
    'agnellent',
    'agnellera',
    'agonirais',
    'agonirait',
    'agonirent',
    'agoniriez',
    'agonirons',
    'agoniront',
    'agonisais',
    'agonisait',
    'agonisant',
    'agonisent',
    'agonisera',
    'agonisiez',
    'agonisons',
    'agonisses',
    'agonissez',
    'agrafages',
    'agrafâmes',
    'agrafasse',
    'agrafâtes',
    'agraferai',
    'agraferas',
    'agraferez',
    'agrafeuse',
    'agrafions',
    'agrandies',
    'agrandira',
    'agraphies',
    'agrariens',
    'agréables',
    'agréaient',
    'agréasses',
    'agréerais',
    'agréerait',
    'agréèrent',
    'agréeriez',
    'agréerons',
    'agréeront',
    'agrégatif',
    'agrégeais',
    'agrégeait',
    'agrégeant',
    'agrégeons',
    'agrégerai',
    'agrégeras',
    'agrégerez',
    'agrégions',
    'agrémenta',
    'agrémente',
    'agrémenté',
    'agréments',
    'agressais',
    'agressait',
    'agressant',
    'agressées',
    'agressent',
    'agressera',
    'agresseur',
    'agressiez',
    'agressifs',
    'agression',
    'agressive',
    'agressons',
    'agrichais',
    'agrichait',
    'agrichant',
    'agrichées',
    'agrichent',
    'agrichera',
    'agrichiez',
    'agrichons',
    'agricoles',
    'agriffées',
    'agriffiez',
    'agriffons',
    'agripaume',
    'agrippais',
    'agrippait',
    'agrippant',
    'agrippées',
    'agrippent',
    'agrippera',
    'agrippiez',
    'agrippons',
    'agrologie',
    'agronomes',
    'agronomie',
    'agrostide',
    'aguerries',
    'aguerrira',
    'agueusies',
    'aguichais',
    'aguichait',
    'aguichant',
    'aguichées',
    'aguichent',
    'aguichera',
    'aguicheur',
    'aguichiez',
    'aguichons',
    'ahanaient',
    'ahanasses',
    'ahanerais',
    'ahanerait',
    'ahanèrent',
    'ahaneriez',
    'ahanerons',
    'ahaneront',
    'aheurtées',
    'aheurtiez',
    'aheurtons',
    'ahurirais',
    'ahurirait',
    'ahurirent',
    'ahuririez',
    'ahurirons',
    'ahuriront',
    'ahurisses',
    'ahurissez',
    'aidassent',
    'aidassiez',
    'aiderions',
    'aiglefins',
    'aiglonnes',
    'aigrefins',
    'aigrelets',
    'aigrement',
    'aigrettée',
    'aigrettes',
    'aigrettés',
    'aigrirais',
    'aigrirait',
    'aigrirent',
    'aigririez',
    'aigrirons',
    'aigriront',
    'aigrisses',
    'aigrissez',
    'aiguières',
    'aiguillai',
    'aiguillas',
    'aiguillat',
    'aiguillât',
    'aiguillée',
    'aiguiller',
    'aiguilles',
    'aiguillez',
    'aiguillon',
    'aiguillot',
    'aiguisage',
    'aiguisais',
    'aiguisait',
    'aiguisant',
    'aiguisées',
    'aiguisent',
    'aiguisera',
    'aiguiseur',
    'aiguisiez',
    'aiguisoir',
    'aiguisons',
    'aillaient',
    'aillasses',
    'aillerais',
    'aillerait',
    'aillèrent',
    'ailleriez',
    'aillerons',
    'ailleront',
    'aimantais',
    'aimantait',
    'aimantant',
    'aimantées',
    'aimantent',
    'aimantera',
    'aimantiez',
    'aimantons',
    'aimassent',
    'aimassiez',
    'aimerions',
    'airassent',
    'airassiez',
    'airedales',
    'airerions',
    'aisselles',
    'ajointais',
    'ajointait',
    'ajointant',
    'ajointées',
    'ajointent',
    'ajointera',
    'ajointiez',
    'ajointons',
    'ajourâmes',
    'ajourasse',
    'ajourâtes',
    'ajourerai',
    'ajoureras',
    'ajourerez',
    'ajourions',
    'ajournais',
    'ajournait',
    'ajournant',
    'ajournées',
    'ajournent',
    'ajournera',
    'ajourniez',
    'ajournons',
    'ajoutâmes',
    'ajoutasse',
    'ajoutâtes',
    'ajouterai',
    'ajouteras',
    'ajouterez',
    'ajoutions',
    'ajustable',
    'ajustages',
    'ajustâmes',
    'ajustasse',
    'ajustâtes',
    'ajusterai',
    'ajusteras',
    'ajusterez',
    'ajusteurs',
    'ajusteuse',
    'ajustions',
    'akinésies',
    'akkadiens',
    'alambiqua',
    'alambique',
    'alambiqué',
    'alandiers',
    'alanguies',
    'alanguira',
    'alarmâmes',
    'alarmante',
    'alarmants',
    'alarmasse',
    'alarmâtes',
    'alarmerai',
    'alarmeras',
    'alarmerez',
    'alarmions',
    'alarmiste',
    'alastrims',
    'alaternes',
    'albanaise',
    'albergier',
    'albigeois',
    'albinisme',
    'albuginée',
    'albuginés',
    'albuminée',
    'albumines',
    'albuminés',
    'albumoses',
    'alcaïques',
    'alcalines',
    'alcalisai',
    'alcalisas',
    'alcalisât',
    'alcalisée',
    'alcaliser',
    'alcalises',
    'alcalisés',
    'alcalisez',
    'alcaloïde',
    'alcaloses',
    'alcarazas',
    'alchimies',
    'alcoolats',
    'alcoolisa',
    'alcoolise',
    'alcoolisé',
    'alcootest',
    'aldéhydes',
    'aléatoire',
    'alentîmes',
    'alentirai',
    'alentiras',
    'alentirez',
    'alentisse',
    'alentîtes',
    'alentours',
    'alertâmes',
    'alertasse',
    'alertâtes',
    'alerterai',
    'alerteras',
    'alerterez',
    'alertions',
    'alésaient',
    'alésasses',
    'aléserais',
    'aléserait',
    'alésèrent',
    'aléseriez',
    'aléserons',
    'aléseront',
    'aléseuses',
    'aleurites',
    'aleurodes',
    'aleurones',
    'alevinage',
    'alevinais',
    'alevinait',
    'alevinant',
    'alevinées',
    'alevinent',
    'alevinera',
    'alevinier',
    'aleviniez',
    'alevinons',
    'alexandra',
    'alfatière',
    'alfatiers',
    'alfénides',
    'algarades',
    'algazelle',
    'algériens',
    'algidités',
    'alginates',
    'algonquin',
    'alicantes',
    'aliénable',
    'aliénâmes',
    'aliénante',
    'aliénants',
    'aliénasse',
    'aliénâtes',
    'aliénerai',
    'aliéneras',
    'aliénerez',
    'aliénions',
    'aliéniste',
    'alignâmes',
    'alignasse',
    'alignâtes',
    'alignerai',
    'aligneras',
    'alignerez',
    'alignions',
    'alimentai',
    'alimentas',
    'alimentât',
    'alimentée',
    'alimenter',
    'alimentes',
    'alimentez',
    'aliquante',
    'aliquotes',
    'alitaient',
    'alitasses',
    'alitement',
    'aliterais',
    'aliterait',
    'alitèrent',
    'aliteriez',
    'aliterons',
    'aliteront',
    'alizarine',
    'alkékenge',
    'allaitais',
    'allaitait',
    'allaitant',
    'allaitées',
    'allaitent',
    'allaitera',
    'allaitiez',
    'allaitons',
    'allassent',
    'allassiez',
    'alléchais',
    'alléchait',
    'alléchant',
    'alléchées',
    'allèchent',
    'alléchera',
    'alléchiez',
    'alléchons',
    'allégeais',
    'allégeait',
    'allégeant',
    'allégeons',
    'allégerai',
    'allégeras',
    'allégerez',
    'allégîmes',
    'allégions',
    'allégirai',
    'allégiras',
    'allégirez',
    'allégisse',
    'allégîtes',
    'allégorie',
    'alléguais',
    'alléguait',
    'alléguant',
    'alléguées',
    'allèguent',
    'alléguera',
    'alléguiez',
    'alléguons',
    'allemande',
    'allemands',
    'allergène',
    'allergies',
    'alliacées',
    'alliaient',
    'alliances',
    'alliasses',
    'allierais',
    'allierait',
    'allièrent',
    'allieriez',
    'allierons',
    'allieront',
    'alligator',
    'allodiale',
    'allodiaux',
    'allogamie',
    'allogènes',
    'allongeai',
    'allongeas',
    'allongeât',
    'allongées',
    'allongent',
    'allongera',
    'allongiez',
    'allopathe',
    'allouâmes',
    'allouasse',
    'allouâtes',
    'allouerai',
    'alloueras',
    'allouerez',
    'allouions',
    'alluchons',
    'allumages',
    'allumâmes',
    'allumasse',
    'allumâtes',
    'allumerai',
    'allumeras',
    'allumerez',
    'allumette',
    'allumeurs',
    'allumeuse',
    'allumions',
    'allusions',
    'allusives',
    'alluviale',
    'alluviaux',
    'alluvions',
    'allylique',
    'almanachs',
    'almandins',
    'alogiques',
    'alopécies',
    'alouettes',
    'alourdies',
    'alourdira',
    'alpaguais',
    'alpaguait',
    'alpaguant',
    'alpaguent',
    'alpaguera',
    'alpaguiez',
    'alpaguons',
    'alpestres',
    'alphabets',
    'alpinisme',
    'alpiniste',
    'alsaciens',
    'altérable',
    'altérâmes',
    'altérante',
    'altérants',
    'altérasse',
    'altérâtes',
    'altérerai',
    'altéreras',
    'altérerez',
    'altérions',
    'altérités',
    'alternais',
    'alternait',
    'alternant',
    'alternats',
    'alternées',
    'alternent',
    'alternera',
    'alterniez',
    'alternons',
    'altimètre',
    'altiports',
    'altitudes',
    'altruisme',
    'altruiste',
    'aluminage',
    'aluminais',
    'aluminait',
    'aluminant',
    'aluminate',
    'aluminées',
    'aluminent',
    'aluminera',
    'alumineux',
    'aluminiez',
    'aluminium',
    'aluminons',
    'alunaient',
    'alunasses',
    'alunerais',
    'alunerait',
    'alunèrent',
    'aluneriez',
    'alunerons',
    'aluneront',
    'alunirais',
    'alunirait',
    'alunirent',
    'aluniriez',
    'alunirons',
    'aluniront',
    'alunisses',
    'alunissez',
    'alvéolées',
    'alvéolite',
    'amabilité',
    'amadouais',
    'amadouait',
    'amadouant',
    'amadouées',
    'amadouent',
    'amadouera',
    'amadouiez',
    'amadouons',
    'amaigries',
    'amaigrira',
    'amalgamai',
    'amalgamas',
    'amalgamât',
    'amalgamée',
    'amalgamer',
    'amalgames',
    'amalgamés',
    'amalgamez',
    'amandaies',
    'amandiers',
    'amarantes',
    'amareyeur',
    'amarinais',
    'amarinait',
    'amarinant',
    'amarinées',
    'amarinent',
    'amarinera',
    'amariniez',
    'amarinons',
    'amarrages',
    'amarrâmes',
    'amarrasse',
    'amarrâtes',
    'amarrerai',
    'amarreras',
    'amarrerez',
    'amarrions',
    'amaryllis',
    'amassâmes',
    'amassasse',
    'amassâtes',
    'amasserai',
    'amasseras',
    'amasserez',
    'amassions',
    'amatirais',
    'amatirait',
    'amatirent',
    'amatiriez',
    'amatirons',
    'amatiront',
    'amatisses',
    'amatissez',
    'amauroses',
    'ambassade',
    'ambiances',
    'ambiantes',
    'ambiguïté',
    'ambisexué',
    'ambitieux',
    'ambitions',
    'amblaient',
    'amblasses',
    'amblerais',
    'amblerait',
    'amblèrent',
    'ambleriez',
    'amblerons',
    'ambleront',
    'ambleuses',
    'amblyopes',
    'amblyopie',
    'ambraient',
    'ambrasses',
    'ambrerais',
    'ambrerait',
    'ambrèrent',
    'ambreriez',
    'ambrerons',
    'ambreront',
    'ambroisie',
    'ambrosien',
    'ambulacre',
    'ambulance',
    'ambulante',
    'ambulants',
    'améliorai',
    'amélioras',
    'améliorât',
    'améliorée',
    'améliorer',
    'améliores',
    'améliorés',
    'améliorez',
    'aménageai',
    'aménageas',
    'aménageât',
    'aménagées',
    'aménagent',
    'aménagera',
    'aménageur',
    'aménagiez',
    'amenaient',
    'amenasses',
    'amendable',
    'amendâmes',
    'amendasse',
    'amendâtes',
    'amenderai',
    'amenderas',
    'amenderez',
    'amendions',
    'amènerais',
    'amènerait',
    'amenèrent',
    'amèneriez',
    'amènerons',
    'amèneront',
    'amenuisai',
    'amenuisas',
    'amenuisât',
    'amenuisée',
    'amenuiser',
    'amenuises',
    'amenuisés',
    'amenuisez',
    'amèrement',
    'américain',
    'américium',
    'amerloque',
    'amerrîmes',
    'amerrirai',
    'amerriras',
    'amerrirez',
    'amerrisse',
    'amerrîtes',
    'amertumes',
    'améthyste',
    'amétropes',
    'amétropie',
    'ameublies',
    'ameublira',
    'ameutâmes',
    'ameutasse',
    'ameutâtes',
    'ameuterai',
    'ameuteras',
    'ameuterez',
    'ameutions',
    'amharique',
    'amibiases',
    'amibienne',
    'amiboïdes',
    'amidonnai',
    'amidonnas',
    'amidonnât',
    'amidonnée',
    'amidonner',
    'amidonnes',
    'amidonnés',
    'amidonnez',
    'amincîmes',
    'amincirai',
    'aminciras',
    'amincirez',
    'amincisse',
    'amincîtes',
    'amirautés',
    'ammodytes',
    'ammoniacs',
    'ammonites',
    'ammoniums',
    'ammophile',
    'amnésique',
    'amnistiai',
    'amnistias',
    'amnistiât',
    'amnistiée',
    'amnistier',
    'amnisties',
    'amnistiés',
    'amnistiez',
    'amochâmes',
    'amochasse',
    'amochâtes',
    'amocherai',
    'amocheras',
    'amocherez',
    'amochions',
    'amodiâmes',
    'amodiasse',
    'amodiâtes',
    'amodierai',
    'amodieras',
    'amodierez',
    'amodiions',
    'amoindrie',
    'amoindrir',
    'amoindris',
    'amoindrit',
    'amoindrît',
    'amollîmes',
    'amollirai',
    'amolliras',
    'amollirez',
    'amollisse',
    'amollîtes',
    'amoncelai',
    'amoncelas',
    'amoncelât',
    'amoncelée',
    'amonceler',
    'amoncelés',
    'amoncelez',
    'amoncelle',
    'amorçages',
    'amorçâmes',
    'amorçasse',
    'amorçâtes',
    'amorcerai',
    'amorceras',
    'amorcerez',
    'amorcions',
    'amordança',
    'amordance',
    'amordancé',
    'amortîmes',
    'amortirai',
    'amortiras',
    'amortirez',
    'amortisse',
    'amortîtes',
    'amotissez',
    'amouraché',
    'amourette',
    'amoureuse',
    'amovibles',
    'amphibies',
    'amphibole',
    'amphioxus',
    'ampholyte',
    'amphotère',
    'amplectif',
    'amplement',
    'ampliatif',
    'amplifiai',
    'amplifias',
    'amplifiât',
    'amplifiée',
    'amplifier',
    'amplifies',
    'amplifiés',
    'amplifiez',
    'amplitude',
    'ampoulées',
    'amputâmes',
    'amputasse',
    'amputâtes',
    'amputerai',
    'amputeras',
    'amputerez',
    'amputions',
    'amuïrions',
    'amuïssent',
    'amuïssiez',
    'amuïssons',
    'amulettes',
    'amuraient',
    'amurasses',
    'amurerais',
    'amurerait',
    'amurèrent',
    'amureriez',
    'amurerons',
    'amureront',
    'amusaient',
    'amusantes',
    'amusasses',
    'amusement',
    'amuserais',
    'amuserait',
    'amusèrent',
    'amuseriez',
    'amuserons',
    'amuseront',
    'amusettes',
    'amuseuses',
    'amygdales',
    'amylacées',
    'amyliques',
    'anabolite',
    'anacondas',
    'anacrouse',
    'anaérobie',
    'anaglyphe',
    'anagnoste',
    'anagogies',
    'anagramme',
    'analgésia',
    'analgésie',
    'analgésié',
    'analogies',
    'analogons',
    'analogues',
    'analysais',
    'analysait',
    'analysant',
    'analysées',
    'analysent',
    'analysera',
    'analyseur',
    'analysiez',
    'analysons',
    'analystes',
    'anamnèses',
    'anapestes',
    'anaphases',
    'anaphores',
    'anarchies',
    'anarthrie',
    'anasarque',
    'anathèmes',
    'anatomies',
    'anatomisa',
    'anatomise',
    'anatomisé',
    'anavenins',
    'ancestral',
    'anciennes',
    'ancraient',
    'ancrasses',
    'ancrerais',
    'ancrerait',
    'ancrèrent',
    'ancreriez',
    'ancrerons',
    'ancreront',
    'andalouse',
    'andantino',
    'andésites',
    'andouille',
    'androcées',
    'androgène',
    'androgyne',
    'androïdes',
    'anéanties',
    'anéantira',
    'anecdotes',
    'anémiâmes',
    'anémiasse',
    'anémiâtes',
    'anémierai',
    'anémieras',
    'anémierez',
    'anémiions',
    'anémiques',
    'anéroïdes',
    'anévrisme',
    'anévrysme',
    'angélique',
    'angélisme',
    'angevines',
    'angineuse',
    'anglaisai',
    'anglaisas',
    'anglaisât',
    'anglaisée',
    'anglaiser',
    'anglaises',
    'anglaisés',
    'anglaisez',
    'anglicane',
    'anglicans',
    'anglicisa',
    'anglicise',
    'anglicisé',
    'anglomane',
    'angoissai',
    'angoissas',
    'angoissât',
    'angoissée',
    'angoisser',
    'angoisses',
    'angoissés',
    'angoissez',
    'angstroem',
    'anguilles',
    'angulaire',
    'anguleuse',
    'angustura',
    'angusture',
    'anhélâmes',
    'anhélasse',
    'anhélâtes',
    'anhélerai',
    'anhéleras',
    'anhélerez',
    'anhélions',
    'anhidrose',
    'anhydride',
    'anhydrite',
    'anicroche',
    'anilismes',
    'animaient',
    'animalier',
    'animalisa',
    'animalise',
    'animalisé',
    'animalité',
    'animasses',
    'animateur',
    'animation',
    'animerais',
    'animerait',
    'animèrent',
    'animeriez',
    'animerons',
    'animeront',
    'animismes',
    'animistes',
    'animosité',
    'anisaient',
    'anisasses',
    'aniserais',
    'aniserait',
    'anisèrent',
    'aniseriez',
    'aniserons',
    'aniseront',
    'anisettes',
    'ankylosai',
    'ankylosas',
    'ankylosât',
    'ankylosée',
    'ankyloser',
    'ankyloses',
    'ankylosés',
    'ankylosez',
    'annaliste',
    'annalités',
    'annamites',
    'annelâmes',
    'annelasse',
    'annelâtes',
    'annelions',
    'annellent',
    'annellera',
    'annexâmes',
    'annexasse',
    'annexâtes',
    'annexerai',
    'annexeras',
    'annexerez',
    'annexions',
    'annihilai',
    'annihilas',
    'annihilât',
    'annihilée',
    'annihiler',
    'annihiles',
    'annihilés',
    'annihilez',
    'annonçais',
    'annonçait',
    'annonçant',
    'annoncées',
    'annoncent',
    'annoncera',
    'annonceur',
    'annoncier',
    'annonciez',
    'annonçons',
    'annotâmes',
    'annotasse',
    'annotâtes',
    'annoterai',
    'annoteras',
    'annoterez',
    'annotions',
    'annuaires',
    'annualité',
    'annuelles',
    'annulable',
    'annulaire',
    'annulâmes',
    'annulasse',
    'annulâtes',
    'annulerai',
    'annuleras',
    'annulerez',
    'annulions',
    'anoblîmes',
    'anoblirai',
    'anobliras',
    'anoblirez',
    'anoblisse',
    'anoblîtes',
    'anodisais',
    'anodisait',
    'anodisant',
    'anodisées',
    'anodisent',
    'anodisera',
    'anodisiez',
    'anodisons',
    'anodontes',
    'anomalies',
    'ânonnâmes',
    'ânonnasse',
    'ânonnâtes',
    'ânonnerai',
    'ânonneras',
    'ânonnerez',
    'ânonnions',
    'anonymats',
    'anophèles',
    'anordîmes',
    'anordirai',
    'anordiras',
    'anordirez',
    'anordisse',
    'anordîtes',
    'anorexies',
    'anormales',
    'anoxémies',
    'ansérines',
    'antéfixes',
    'antenaise',
    'antéposai',
    'antéposas',
    'antéposât',
    'antéposée',
    'antéposer',
    'antéposes',
    'antéposés',
    'antéposez',
    'antérieur',
    'anthonome',
    'anthrènes',
    'antiatome',
    'antibruit',
    'antichars',
    'anticipai',
    'anticipas',
    'anticipât',
    'anticipée',
    'anticiper',
    'anticipes',
    'anticipés',
    'anticipez',
    'anticorps',
    'antidatai',
    'antidatas',
    'antidatât',
    'antidatée',
    'antidater',
    'antidates',
    'antidatés',
    'antidatez',
    'antidotes',
    'antiennes',
    'antigènes',
    'antihéros',
    'antillais',
    'antilopes',
    'antimites',
    'antimoine',
    'antinazie',
    'antinazis',
    'antinomie',
    'antipapes',
    'antipodes',
    'antiquité',
    'antiradar',
    'antirides',
    'antithèse',
    'antitrust',
    'antonymes',
    'antonymie',
    'anxieuses',
    'anxiogène',
    'aortiques',
    'aoûtaient',
    'aoûtasses',
    'aoûtement',
    'aoûterais',
    'aoûterait',
    'aoûtèrent',
    'aoûteriez',
    'aoûterons',
    'aoûteront',
    'aoûtienne',
    'apagogies',
    'apaisâmes',
    'apaisante',
    'apaisants',
    'apaisasse',
    'apaisâtes',
    'apaiserai',
    'apaiseras',
    'apaiserez',
    'apaisions',
    'apanageai',
    'apanageas',
    'apanageât',
    'apanagées',
    'apanagent',
    'apanagera',
    'apanagiez',
    'apartheid',
    'apathique',
    'apatrides',
    'apercevez',
    'apercevra',
    'aperçoive',
    'aperçûmes',
    'aperçusse',
    'aperçûtes',
    'apériteur',
    'apéritifs',
    'apéritive',
    'apertures',
    'apeurâmes',
    'apeurasse',
    'apeurâtes',
    'apeurerai',
    'apeureras',
    'apeurerez',
    'apeurions',
    'aphasique',
    'aphérèses',
    'aphorisme',
    'aphteuses',
    'apiéceurs',
    'apiéceuse',
    'apiquages',
    'apiquâmes',
    'apiquasse',
    'apiquâtes',
    'apiquerai',
    'apiqueras',
    'apiquerez',
    'apiquions',
    'apitoient',
    'apitoiera',
    'apitoyais',
    'apitoyait',
    'apitoyant',
    'apitoyées',
    'apitoyiez',
    'apitoyons',
    'aplanîmes',
    'aplanirai',
    'aplaniras',
    'aplanirez',
    'aplanisse',
    'aplanîtes',
    'aplatîmes',
    'aplatirai',
    'aplatiras',
    'aplatirez',
    'aplatisse',
    'aplatîtes',
    'apoastres',
    'apocryphe',
    'apogamies',
    'apologies',
    'apologues',
    'apophonie',
    'apophyses',
    'apoplexie',
    'apostâmes',
    'apostasia',
    'apostasie',
    'apostasié',
    'apostasse',
    'apostates',
    'apostâtes',
    'aposterai',
    'aposteras',
    'aposterez',
    'apostilla',
    'apostille',
    'apostillé',
    'apostions',
    'apostolat',
    'apothèmes',
    'apothéose',
    'appairais',
    'appairait',
    'appairant',
    'appairées',
    'appairent',
    'appairera',
    'appairiez',
    'appairons',
    'appareils',
    'apparence',
    'apparente',
    'apparenté',
    'apparents',
    'appariais',
    'appariait',
    'appariant',
    'appariées',
    'apparient',
    'appariera',
    'appariiez',
    'apparions',
    'appartenu',
    'appartins',
    'appartint',
    'appartînt',
    'apparûmes',
    'apparusse',
    'apparûtes',
    'appâtâmes',
    'appâtasse',
    'appâtâtes',
    'appâterai',
    'appâteras',
    'appâterez',
    'appâtions',
    'appauvrie',
    'appauvrir',
    'appauvris',
    'appauvrit',
    'appauvrît',
    'appelâmes',
    'appelante',
    'appelants',
    'appelasse',
    'appelâtes',
    'appelions',
    'appellent',
    'appellera',
    'appendice',
    'appesanti',
    'appétâmes',
    'appétasse',
    'appétâtes',
    'appétence',
    'appéterai',
    'appéteras',
    'appéterez',
    'appétions',
    'applaudie',
    'applaudir',
    'applaudis',
    'applaudit',
    'applaudît',
    'applicage',
    'appliquai',
    'appliquas',
    'appliquât',
    'appliquée',
    'appliquer',
    'appliques',
    'appliqués',
    'appliquez',
    'appointai',
    'appointas',
    'appointât',
    'appointée',
    'appointer',
    'appointes',
    'appointés',
    'appointez',
    'appointie',
    'appointir',
    'appointis',
    'appointit',
    'appointît',
    'appontage',
    'appontais',
    'appontait',
    'appontant',
    'appontent',
    'appontera',
    'apponteur',
    'appontiez',
    'appontons',
    'apportais',
    'apportait',
    'apportant',
    'apportées',
    'apportent',
    'apportera',
    'apporteur',
    'apportiez',
    'apportons',
    'apposâmes',
    'apposasse',
    'apposâtes',
    'apposerai',
    'apposeras',
    'apposerez',
    'apposions',
    'appréciai',
    'apprécias',
    'appréciât',
    'appréciée',
    'apprécier',
    'apprécies',
    'appréciés',
    'appréciez',
    'apprenais',
    'apprenait',
    'apprenant',
    'apprendra',
    'apprendre',
    'appreniez',
    'apprennes',
    'apprenons',
    'apprentie',
    'apprentis',
    'apprêtage',
    'apprêtais',
    'apprêtait',
    'apprêtant',
    'apprêtées',
    'apprêtent',
    'apprêtera',
    'apprêteur',
    'apprêtiez',
    'apprêtons',
    'apprirent',
    'apprisses',
    'approchai',
    'approchas',
    'approchât',
    'approchée',
    'approcher',
    'approches',
    'approchés',
    'approchez',
    'appropria',
    'approprie',
    'approprié',
    'approuvai',
    'approuvas',
    'approuvât',
    'approuvée',
    'approuver',
    'approuves',
    'approuvés',
    'approuvez',
    'appuierai',
    'appuieras',
    'appuierez',
    'appuyâmes',
    'appuyasse',
    'appuyâtes',
    'appuyions',
    'après-ski',
    'aptitudes',
    'apuraient',
    'apurasses',
    'apurement',
    'apurerais',
    'apurerait',
    'apurèrent',
    'apureriez',
    'apurerons',
    'apureront',
    'aquaplane',
    'aquarelle',
    'aquariums',
    'aquatinte',
    'aquatique',
    'aquicoles',
    'arabesque',
    'arabiques',
    'arabisais',
    'arabisait',
    'arabisant',
    'arabisées',
    'arabisent',
    'arabisera',
    'arabisiez',
    'arabisons',
    'arachides',
    'arachnéen',
    'aragonite',
    'araignées',
    'araméenne',
    'aranéides',
    'arantèles',
    'arasaient',
    'arasasses',
    'arasement',
    'araserais',
    'araserait',
    'arasèrent',
    'araseriez',
    'araserons',
    'araseront',
    'aratoires',
    'araucaria',
    'arbalètes',
    'arbitrage',
    'arbitrais',
    'arbitrait',
    'arbitrale',
    'arbitrant',
    'arbitraux',
    'arbitrées',
    'arbitrent',
    'arbitrera',
    'arbitriez',
    'arbitrons',
    'arborâmes',
    'arborasse',
    'arborâtes',
    'arborerai',
    'arboreras',
    'arborerez',
    'arboretum',
    'arborions',
    'arborisai',
    'arborisas',
    'arborisât',
    'arboriser',
    'arborises',
    'arborisez',
    'arbousier',
    'arbustifs',
    'arbustive',
    'arcansons',
    'arcatures',
    'arc-bouta',
    'arc-boute',
    'arc-bouté',
    'archaïque',
    'archaïsai',
    'archaïsas',
    'archaïsât',
    'archaïser',
    'archaïses',
    'archaïsez',
    'archaïsme',
    'archanges',
    'archéenne',
    'archégone',
    'archelles',
    'archeries',
    'archétype',
    'archiatre',
    'archicube',
    'archiducs',
    'archipels',
    'archivage',
    'archivais',
    'archivait',
    'archivant',
    'archivées',
    'archivent',
    'archivera',
    'archiviez',
    'archivons',
    'archontes',
    'arçonnais',
    'arçonnait',
    'arçonnant',
    'arçonnées',
    'arçonnent',
    'arçonnera',
    'arçonniez',
    'arçonnons',
    'arctiques',
    'ardemment',
    'ardillons',
    'ardoisais',
    'ardoisait',
    'ardoisant',
    'ardoisées',
    'ardoisent',
    'ardoisera',
    'ardoisier',
    'ardoisiez',
    'ardoisons',
    'aréflexie',
    'arénacées',
    'arénicole',
    'aréolaire',
    'aréomètre',
    'aréopages',
    'aréostyle',
    'aréquiers',
    'arêtieres',
    'argentais',
    'argentait',
    'argentans',
    'argentant',
    'argentées',
    'argentent',
    'argentera',
    'argenteur',
    'argentier',
    'argentiez',
    'argentine',
    'argentins',
    'argentite',
    'argentons',
    'argenture',
    'argilacée',
    'argilacés',
    'argileuse',
    'argonaute',
    'argortisé',
    'argotique',
    'argotisai',
    'argotisas',
    'argotisât',
    'argotiser',
    'argotises',
    'argotisez',
    'argotisme',
    'argotiste',
    'argousier',
    'argousins',
    'arguaient',
    'arguasses',
    'arguerais',
    'arguerait',
    'arguèrent',
    'argueriez',
    'arguerons',
    'argueront',
    'argumenta',
    'argumente',
    'argumenté',
    'arguments',
    'argyrisme',
    'argyroses',
    'arianisme',
    'arisaient',
    'arisasses',
    'ariserais',
    'ariserait',
    'arisèrent',
    'ariseriez',
    'ariserons',
    'ariseront',
    'arlequins',
    'armagnacs',
    'armaillis',
    'armassent',
    'armassiez',
    'armateurs',
    'armatures',
    'armements',
    'arméniens',
    'armerions',
    'arminiens',
    'armistice',
    'armoiries',
    'armoriais',
    'armoriait',
    'armoriale',
    'armoriant',
    'armoriaux',
    'armoriées',
    'armorient',
    'armoriera',
    'armoriiez',
    'armorions',
    'armurerie',
    'armuriers',
    'arnaquais',
    'arnaquait',
    'arnaquant',
    'arnaquées',
    'arnaquent',
    'arnaquera',
    'arnaquiez',
    'arnaquons',
    'aromatisa',
    'aromatise',
    'aromatisé',
    'arpégeais',
    'arpégeait',
    'arpégeant',
    'arpégeons',
    'arpégerai',
    'arpégeras',
    'arpégerez',
    'arpégions',
    'arpentage',
    'arpentais',
    'arpentait',
    'arpentant',
    'arpentées',
    'arpentent',
    'arpentera',
    'arpenteur',
    'arpentiez',
    'arpentons',
    'arquaient',
    'arquasses',
    'arquebuse',
    'arquerais',
    'arquerait',
    'arquèrent',
    'arqueriez',
    'arquerons',
    'arqueront',
    'arrachage',
    'arrachais',
    'arrachait',
    'arrachant',
    'arrachées',
    'arrachent',
    'arrachera',
    'arracheur',
    'arrachiez',
    'arrachons',
    'arrangeai',
    'arrangeas',
    'arrangeât',
    'arrangées',
    'arrangent',
    'arrangera',
    'arrangeur',
    'arrangiez',
    'arrentais',
    'arrentait',
    'arrentant',
    'arrentées',
    'arrentent',
    'arrentera',
    'arrentiez',
    'arrentons',
    'arréragea',
    'arréragée',
    'arrérager',
    'arrérages',
    'arréragés',
    'arréragez',
    'arrêtâmes',
    'arrêtasse',
    'arrêtâtes',
    'arrêterai',
    'arrêteras',
    'arrêterez',
    'arrêtions',
    'arrêtiste',
    'arrêtoirs',
    'arriérais',
    'arriérait',
    'arriérant',
    'arriérées',
    'arrièrent',
    'arriérera',
    'arriériez',
    'arriérons',
    'arrimages',
    'arrimâmes',
    'arrimasse',
    'arrimâtes',
    'arrimerai',
    'arrimeras',
    'arrimerez',
    'arrimeurs',
    'arrimions',
    'arrisâmes',
    'arrisasse',
    'arrisâtes',
    'arriserai',
    'arriseras',
    'arriserez',
    'arrisions',
    'arrivages',
    'arrivâmes',
    'arrivasse',
    'arrivâtes',
    'arriverai',
    'arriveras',
    'arriverez',
    'arrivions',
    'arrivisme',
    'arriviste',
    'arrogance',
    'arrogante',
    'arrogants',
    'arrogeons',
    'arrogerez',
    'arrogions',
    'arrondies',
    'arrondira',
    'arrosable',
    'arrosages',
    'arrosâmes',
    'arrosasse',
    'arrosâtes',
    'arroserai',
    'arroseras',
    'arroserez',
    'arroseurs',
    'arroseuse',
    'arrosions',
    'arrosoirs',
    'arséniate',
    'arsenical',
    'arsénieux',
    'arsénites',
    'arséniure',
    'arsouille',
    'arsouillé',
    'artefacts',
    'artériels',
    'artériole',
    'artérites',
    'artésiens',
    'arthrites',
    'arthrodie',
    'arthroses',
    'artichaut',
    'articulai',
    'articulas',
    'articulât',
    'articulée',
    'articuler',
    'articules',
    'articulés',
    'articulet',
    'articulez',
    'artifices',
    'artilleur',
    'artisanal',
    'artisanat',
    'artocarpe',
    'aruspices',
    'arythmies',
    'asbestose',
    'ascarides',
    'ascendant',
    'ascenseur',
    'ascension',
    'ascétique',
    'ascétisme',
    'ascitique',
    'aseptique',
    'aseptisai',
    'aseptisas',
    'aseptisât',
    'aseptisée',
    'aseptiser',
    'aseptises',
    'aseptisés',
    'aseptisez',
    'asexuelle',
    'ashkénaze',
    'asiatique',
    'asilaires',
    'asinienne',
    'asociales',
    'asparagus',
    'aspectais',
    'aspectait',
    'aspectant',
    'aspectées',
    'aspectent',
    'aspectera',
    'aspectiez',
    'aspectons',
    'aspergeai',
    'aspergeas',
    'aspergeât',
    'aspergées',
    'aspergent',
    'aspergera',
    'aspergiez',
    'aspérités',
    'aspersion',
    'aspersoir',
    'asphaltai',
    'asphaltas',
    'asphaltât',
    'asphaltée',
    'asphalter',
    'asphaltes',
    'asphaltés',
    'asphaltez',
    'asphodèle',
    'asphyxiai',
    'asphyxias',
    'asphyxiât',
    'asphyxiée',
    'asphyxier',
    'asphyxies',
    'asphyxiés',
    'asphyxiez',
    'aspirâmes',
    'aspirante',
    'aspirants',
    'aspirasse',
    'aspirâtes',
    'aspirerai',
    'aspireras',
    'aspirerez',
    'aspirines',
    'aspirions',
    'assagîmes',
    'assagirai',
    'assagiras',
    'assagirez',
    'assagisse',
    'assagîtes',
    'assailles',
    'assaillez',
    'assaillie',
    'assaillir',
    'assaillis',
    'assaillit',
    'assaillît',
    'assainies',
    'assainira',
    'assassina',
    'assassine',
    'assassiné',
    'assassins',
    'asséchais',
    'asséchait',
    'asséchant',
    'asséchées',
    'assèchent',
    'asséchera',
    'asséchiez',
    'asséchons',
    'assemblai',
    'assemblas',
    'assemblât',
    'assemblée',
    'assembler',
    'assembles',
    'assemblés',
    'assemblez',
    'assenâmes',
    'assénâmes',
    'assenasse',
    'assénasse',
    'assenâtes',
    'assénâtes',
    'assénerai',
    'assènerai',
    'asséneras',
    'assèneras',
    'assénerez',
    'assènerez',
    'assenions',
    'assénions',
    'assertion',
    'asservies',
    'asservira',
    'assesseur',
    'asseyions',
    'assibilai',
    'assibilas',
    'assibilât',
    'assibilée',
    'assibiler',
    'assibiles',
    'assibilés',
    'assibilez',
    'assiduité',
    'assiégeai',
    'assiégeas',
    'assiégeât',
    'assiégées',
    'assiègent',
    'assiégera',
    'assiégiez',
    'assiérais',
    'assiérait',
    'assiériez',
    'assiérons',
    'assiéront',
    'assiettée',
    'assiettes',
    'assignais',
    'assignait',
    'assignant',
    'assignats',
    'assignées',
    'assignent',
    'assignera',
    'assigniez',
    'assignons',
    'assimilai',
    'assimilas',
    'assimilât',
    'assimilée',
    'assimiler',
    'assimiles',
    'assimilés',
    'assimilez',
    'assissent',
    'assissiez',
    'assistais',
    'assistait',
    'assistant',
    'assistées',
    'assistent',
    'assistera',
    'assistiez',
    'assistons',
    'associais',
    'associait',
    'associant',
    'associées',
    'associent',
    'associera',
    'associiez',
    'associons',
    'assoiffée',
    'assoiffés',
    'assoirais',
    'assoirait',
    'assoiriez',
    'assoirons',
    'assoiront',
    'assolâmes',
    'assolasse',
    'assolâtes',
    'assolerai',
    'assoleras',
    'assolerez',
    'assolions',
    'assombrie',
    'assombrir',
    'assombris',
    'assombrit',
    'assombrît',
    'assommais',
    'assommait',
    'assommant',
    'assommées',
    'assomment',
    'assommera',
    'assommeur',
    'assommiez',
    'assommoir',
    'assommons',
    'assonâmes',
    'assonance',
    'assonancé',
    'assonante',
    'assonants',
    'assonasse',
    'assonâtes',
    'assonerai',
    'assoneras',
    'assonerez',
    'assonions',
    'assorties',
    'assortira',
    'assoupies',
    'assoupira',
    'assouplie',
    'assouplir',
    'assouplis',
    'assouplit',
    'assouplît',
    'assourdie',
    'assourdir',
    'assourdis',
    'assourdit',
    'assourdît',
    'assouvies',
    'assouvira',
    'assoyions',
    'assuétude',
    'assujetti',
    'assumâmes',
    'assumasse',
    'assumâtes',
    'assumerai',
    'assumeras',
    'assumerez',
    'assumions',
    'assurable',
    'assurâmes',
    'assurance',
    'assurasse',
    'assurâtes',
    'assurerai',
    'assureras',
    'assurerez',
    'assureurs',
    'assurions',
    'assyriens',
    'astatique',
    'astéroïde',
    'asthénies',
    'asticotai',
    'asticotas',
    'asticotât',
    'asticotée',
    'asticoter',
    'asticotes',
    'asticotés',
    'asticotez',
    'astigmate',
    'astiquage',
    'astiquais',
    'astiquait',
    'astiquant',
    'astiquées',
    'astiquent',
    'astiquera',
    'astiquiez',
    'astiquons',
    'astragale',
    'astrakans',
    'astreigne',
    'astreinte',
    'astreints',
    'astrolabe',
    'astronefs',
    'astronome',
    'astucieux',
    'asymétrie',
    'asymptote',
    'asyndètes',
    'asynergie',
    'asystolie',
    'ataraxies',
    'ataviques',
    'atavismes',
    'ataxiques',
    'atemporel',
    'atermoies',
    'atermoyai',
    'atermoyas',
    'atermoyât',
    'atermoyer',
    'atermoyez',
    'athéismes',
    'athermane',
    'athéromes',
    'athétoses',
    'atomicité',
    'atomiques',
    'atomisais',
    'atomisait',
    'atomisant',
    'atomisées',
    'atomisent',
    'atomisera',
    'atomiseur',
    'atomisiez',
    'atomismes',
    'atomisons',
    'atomistes',
    'atonalité',
    'atoniques',
    'atoxiques',
    'atrabiles',
    'atrocités',
    'atrophiai',
    'atrophias',
    'atrophiât',
    'atrophiée',
    'atrophier',
    'atrophies',
    'atrophiés',
    'atrophiez',
    'atropines',
    'attablais',
    'attablait',
    'attablant',
    'attablées',
    'attablent',
    'attablera',
    'attabliez',
    'attablons',
    'attachais',
    'attachait',
    'attachant',
    'attachées',
    'attachent',
    'attachera',
    'attachiez',
    'attachons',
    'attagènes',
    'attaquais',
    'attaquait',
    'attaquant',
    'attaquées',
    'attaquent',
    'attaquera',
    'attaquiez',
    'attaquons',
    'attardais',
    'attardait',
    'attardant',
    'attardées',
    'attardent',
    'attardera',
    'attardiez',
    'attardons',
    'atteignes',
    'atteignez',
    'atteignis',
    'atteignit',
    'atteignît',
    'atteindra',
    'atteindre',
    'atteintes',
    'attelages',
    'attelâmes',
    'attelasse',
    'attelâtes',
    'attelions',
    'attellent',
    'attellera',
    'attenante',
    'attenants',
    'attendais',
    'attendait',
    'attendant',
    'attendent',
    'attendiez',
    'attendons',
    'attendrai',
    'attendras',
    'attendrez',
    'attendrie',
    'attendrir',
    'attendris',
    'attendrit',
    'attendrît',
    'attendues',
    'attentais',
    'attentait',
    'attentant',
    'attentats',
    'attentent',
    'attentera',
    'attentiez',
    'attentifs',
    'attention',
    'attentive',
    'attentons',
    'atténuais',
    'atténuait',
    'atténuant',
    'atténuées',
    'atténuent',
    'atténuera',
    'atténuiez',
    'atténuons',
    'atterrage',
    'atterrais',
    'atterrait',
    'atterrant',
    'atterrées',
    'atterrent',
    'atterrera',
    'atterriez',
    'atterrira',
    'atterrons',
    'attestais',
    'attestait',
    'attestant',
    'attestées',
    'attestent',
    'attestera',
    'attestiez',
    'attestons',
    'atticisme',
    'attiédies',
    'attiédira',
    'attifâmes',
    'attifasse',
    'attifâtes',
    'attiferai',
    'attiferas',
    'attiferez',
    'attifions',
    'attigeais',
    'attigeait',
    'attigeant',
    'attigeons',
    'attigerai',
    'attigeras',
    'attigerez',
    'attigions',
    'attirable',
    'attirails',
    'attirâmes',
    'attirance',
    'attirante',
    'attirants',
    'attirasse',
    'attirâtes',
    'attirerai',
    'attireras',
    'attirerez',
    'attirions',
    'attisâmes',
    'attisasse',
    'attisâtes',
    'attiserai',
    'attiseras',
    'attiserez',
    'attisions',
    'attitrais',
    'attitrait',
    'attitrant',
    'attitrées',
    'attitrent',
    'attitrera',
    'attitriez',
    'attitrons',
    'attitudes',
    'attorneys',
    'attractif',
    'attrapade',
    'attrapage',
    'attrapais',
    'attrapait',
    'attrapant',
    'attrapées',
    'attrapent',
    'attrapera',
    'attrapiez',
    'attrapons',
    'attrayant',
    'attribuai',
    'attribuas',
    'attribuât',
    'attribuée',
    'attribuer',
    'attribues',
    'attribués',
    'attribuez',
    'attributs',
    'attriquai',
    'attriquas',
    'attriquât',
    'attriquée',
    'attriquer',
    'attriques',
    'attriqués',
    'attriquez',
    'attristai',
    'attristas',
    'attristât',
    'attristée',
    'attrister',
    'attristes',
    'attristés',
    'attristez',
    'attrition',
    'attroupai',
    'attroupas',
    'attroupât',
    'attroupée',
    'attrouper',
    'attroupes',
    'attroupés',
    'attroupez',
    'atypiques',
    'aubépines',
    'aubergine',
    'audacieux',
    'au-dedans',
    'au-dehors',
    'au-dessus',
    'au-devant',
    'audiences',
    'auditeurs',
    'auditions',
    'auditives',
    'auditoire',
    'auditorat',
    'auditrice',
    'augmentai',
    'augmentas',
    'augmentât',
    'augmentée',
    'augmenter',
    'augmentes',
    'augmentés',
    'augmentez',
    'augurales',
    'augurâmes',
    'augurasse',
    'augurâtes',
    'augurerai',
    'augureras',
    'augurerez',
    'augurions',
    'aumônerie',
    'aumônière',
    'aumôniers',
    'aurais-je',
    'aurais-tu',
    'aurait-il',
    'aurait-on',
    'aura-t-il',
    'aura-t-on',
    'auréolais',
    'auréolait',
    'auréolant',
    'auréolées',
    'auréolent',
    'auréolera',
    'auréoliez',
    'auréolons',
    'auricules',
    'aurifères',
    'aurifiais',
    'aurifiait',
    'aurifiant',
    'aurifiées',
    'aurifient',
    'aurifiera',
    'aurifiiez',
    'aurifions',
    'auscultai',
    'auscultas',
    'auscultât',
    'auscultée',
    'ausculter',
    'auscultes',
    'auscultés',
    'auscultez',
    'austérité',
    'australes',
    'autarcies',
    'autoberge',
    'autoclave',
    'autocopie',
    'autocrate',
    'autodafés',
    'autodrome',
    'autofocus',
    'autogames',
    'autogamie',
    'autogènes',
    'autogérée',
    'autogérés',
    'autogires',
    'autoguidé',
    'automates',
    'automédon',
    'automnale',
    'automnaux',
    'autoneige',
    'autonomes',
    'autonomie',
    'autonymes',
    'autopompe',
    'autopsiai',
    'autopsias',
    'autopsiât',
    'autopsiée',
    'autopsier',
    'autopsies',
    'autopsiés',
    'autopsiez',
    'autoradio',
    'autorails',
    'autorisai',
    'autorisas',
    'autorisât',
    'autorisée',
    'autoriser',
    'autorises',
    'autorisés',
    'autorisez',
    'autorités',
    'autoroute',
    'auto-stop',
    'autotomie',
    'autrefois',
    'autrement',
    'autruches',
    'auvergnat',
    'avachîmes',
    'avachirai',
    'avachiras',
    'avachirez',
    'avachisse',
    'avachîtes',
    'avalaient',
    'avalanche',
    'avalasses',
    'avalerais',
    'avalerait',
    'avalèrent',
    'avaleriez',
    'avalerons',
    'avaleront',
    'avalisais',
    'avalisait',
    'avalisant',
    'avalisées',
    'avalisent',
    'avalisera',
    'avaliseur',
    'avalisiez',
    'avalisons',
    'avalistes',
    'avançâmes',
    'avançasse',
    'avançâtes',
    'avancerai',
    'avanceras',
    'avancerez',
    'avancions',
    'avantagea',
    'avantagée',
    'avantager',
    'avantages',
    'avantagés',
    'avantagez',
    'avariâmes',
    'avariasse',
    'avariâtes',
    'avarierai',
    'avarieras',
    'avarierez',
    'avariions',
    'avelinier',
    'avenantes',
    'avènement',
    'aventurai',
    'aventuras',
    'aventurât',
    'aventurée',
    'aventurer',
    'aventures',
    'aventurés',
    'aventurez',
    'avéraient',
    'avérasses',
    'avérerais',
    'avérerait',
    'avérèrent',
    'avéreriez',
    'avérerons',
    'avéreront',
    'aversions',
    'avertîmes',
    'avertirai',
    'avertiras',
    'avertirez',
    'avertisse',
    'avertîtes',
    'aveuglais',
    'aveuglait',
    'aveuglant',
    'aveuglées',
    'aveuglent',
    'aveuglera',
    'aveugliez',
    'aveuglons',
    'aveulîmes',
    'aveulirai',
    'aveuliras',
    'aveulirez',
    'aveulisse',
    'aveulîtes',
    'avez-vous',
    'aviateurs',
    'aviations',
    'aviatrice',
    'avidement',
    'avilirais',
    'avilirait',
    'avilirent',
    'aviliriez',
    'avilirons',
    'aviliront',
    'avilisses',
    'avilissez',
    'avinaient',
    'avinasses',
    'avinerais',
    'avinerait',
    'avinèrent',
    'avineriez',
    'avinerons',
    'avineront',
    'avionique',
    'avionneur',
    'avisaient',
    'avisasses',
    'aviserais',
    'aviserait',
    'avisèrent',
    'aviseriez',
    'aviserons',
    'aviseront',
    'avitailla',
    'avitaille',
    'avitaillé',
    'avivaient',
    'avivasses',
    'avivement',
    'aviverais',
    'aviverait',
    'avivèrent',
    'aviveriez',
    'aviverons',
    'aviveront',
    'avocatier',
    'avocettes',
    'avoisinai',
    'avoisinas',
    'avoisinât',
    'avoisinée',
    'avoisiner',
    'avoisines',
    'avoisinés',
    'avoisinez',
    'avortâmes',
    'avortasse',
    'avortâtes',
    'avorterai',
    'avorteras',
    'avorterez',
    'avorteurs',
    'avorteuse',
    'avortions',
    'avouables',
    'avouaient',
    'avouasses',
    'avouerais',
    'avouerait',
    'avouèrent',
    'avoueriez',
    'avouerons',
    'avoueront',
    'avulsions',
    'axassions',
    'axeraient',
    'axillaire',
    'axiologie',
    'ayatollah',
    'azerolier',
    'azimutale',
    'azimutaux',
    'azimutées',
    'azotémies',
    'azotiques',
    'azoturies',
    'azuraient',
    'azurasses',
    'azurerais',
    'azurerait',
    'azurèrent',
    'azureriez',
    'azurerons',
    'azureront',
    'babeurres',
    'babillage',
    'babillais',
    'babillait',
    'babillant',
    'babillard',
    'babillent',
    'babillera',
    'babilliez',
    'babillons',
    'bâbordais',
    'babouches',
    'baby-foot',
    'bacchante',
    'bâchaient',
    'bâchasses',
    'bachelier',
    'bâcherais',
    'bâcherait',
    'bâchèrent',
    'bâcheriez',
    'bâcherons',
    'bâcheront',
    'bachiques',
    'bachotage',
    'bachotais',
    'bachotait',
    'bachotant',
    'bachotées',
    'bachotent',
    'bachotera',
    'bachotiez',
    'bachotons',
    'bacillose',
    'bâclaient',
    'bâclasses',
    'bâclerais',
    'bâclerait',
    'bâclèrent',
    'bâcleriez',
    'bâclerons',
    'bâcleront',
    'bactérien',
    'bactéries',
    'badigeons',
    'badinages',
    'badinâmes',
    'badinasse',
    'badinâtes',
    'badinerai',
    'badineras',
    'badinerez',
    'badinerie',
    'badinions',
    'badminton',
    'baffaient',
    'baffasses',
    'bafferais',
    'bafferait',
    'baffèrent',
    'bafferiez',
    'bafferons',
    'bafferont',
    'bafouâmes',
    'bafouasse',
    'bafouâtes',
    'bafouerai',
    'bafoueras',
    'bafouerez',
    'bafouilla',
    'bafouille',
    'bafouillé',
    'bafouions',
    'bâfraient',
    'bâfrasses',
    'bâfrerais',
    'bâfrerait',
    'bâfrèrent',
    'bâfreriez',
    'bâfrerons',
    'bâfreront',
    'bâfreuses',
    'bagagiste',
    'bagarrais',
    'bagarrait',
    'bagarrant',
    'bagarrées',
    'bagarrent',
    'bagarrera',
    'bagarreur',
    'bagarriez',
    'bagarrons',
    'bagatelle',
    'baguaient',
    'baguasses',
    'baguerais',
    'baguerait',
    'baguèrent',
    'bagueriez',
    'baguerons',
    'bagueront',
    'baguettes',
    'baierions',
    'baignades',
    'baignâmes',
    'baignasse',
    'baignâtes',
    'baignerai',
    'baigneras',
    'baignerez',
    'baigneurs',
    'baigneuse',
    'baignions',
    'baignoire',
    'baillâmes',
    'bâillâmes',
    'baillasse',
    'bâillasse',
    'baillâtes',
    'bâillâtes',
    'baillerai',
    'bâillerai',
    'bailleras',
    'bâilleras',
    'baillerez',
    'bâillerez',
    'bailleurs',
    'bailliage',
    'baillions',
    'bâillions',
    'bâillonna',
    'bâillonne',
    'bâillonné',
    'baisaient',
    'baisasses',
    'baisemain',
    'baisement',
    'baiserais',
    'baiserait',
    'baisèrent',
    'baiseriez',
    'baiserons',
    'baiseront',
    'baiseuses',
    'baisotais',
    'baisotait',
    'baisotant',
    'baisotées',
    'baisotent',
    'baisotera',
    'baisotiez',
    'baisotons',
    'baissâmes',
    'baissasse',
    'baissâtes',
    'baisserai',
    'baisseras',
    'baisserez',
    'baissière',
    'baissiers',
    'baissions',
    'bakchichs',
    'baladâmes',
    'baladasse',
    'baladâtes',
    'baladerai',
    'baladeras',
    'baladerez',
    'baladeurs',
    'baladeuse',
    'baladions',
    'balafrais',
    'balafrait',
    'balafrant',
    'balafrées',
    'balafrent',
    'balafrera',
    'balafriez',
    'balafrons',
    'balaierai',
    'balaieras',
    'balaierez',
    'balalaïka',
    'balançais',
    'balançait',
    'balançant',
    'balancées',
    'balancent',
    'balancera',
    'balancier',
    'balanciez',
    'balancine',
    'balançons',
    'balanites',
    'balayages',
    'balayâmes',
    'balayasse',
    'balayâtes',
    'balayerai',
    'balayeras',
    'balayerez',
    'balayette',
    'balayeurs',
    'balayeuse',
    'balayions',
    'balbutiai',
    'balbutias',
    'balbutiât',
    'balbutiée',
    'balbutier',
    'balbuties',
    'balbutiés',
    'balbutiez',
    'balbuzard',
    'balconnet',
    'baldaquin',
    'baleinais',
    'baleinait',
    'baleinant',
    'baleineau',
    'baleinées',
    'baleinent',
    'baleinera',
    'baleinier',
    'baleiniez',
    'baleinons',
    'balisages',
    'balisâmes',
    'balisasse',
    'balisâtes',
    'baliserai',
    'baliseras',
    'baliserez',
    'baliseurs',
    'balisiers',
    'balisions',
    'balivages',
    'baliveaux',
    'baliverne',
    'balkanisa',
    'balkanise',
    'balkanisé',
    'ballaient',
    'ballantes',
    'ballasses',
    'ballastai',
    'ballastas',
    'ballastât',
    'ballastée',
    'ballaster',
    'ballastes',
    'ballastés',
    'ballastez',
    'ballerais',
    'ballerait',
    'ballèrent',
    'balleriez',
    'ballerine',
    'ballerons',
    'balleront',
    'ballonnai',
    'ballonnas',
    'ballonnât',
    'ballonnée',
    'ballonner',
    'ballonnes',
    'ballonnés',
    'ballonnet',
    'ballonnez',
    'ballottai',
    'ballottas',
    'ballottât',
    'ballottée',
    'ballotter',
    'ballottes',
    'ballottés',
    'ballottez',
    'ball-trap',
    'balluchon',
    'balnéaire',
    'balourdes',
    'balsamier',
    'balsamine',
    'balthazar',
    'baluchons',
    'balustres',
    'bambochai',
    'bambochas',
    'bambochât',
    'bambocher',
    'bamboches',
    'bambochez',
    'bamboulas',
    'banalisai',
    'banalisas',
    'banalisât',
    'banalisée',
    'banaliser',
    'banalises',
    'banalisés',
    'banalisez',
    'banalités',
    'bananiers',
    'bancables',
    'bancaires',
    'bancroche',
    'bandaient',
    'bandasses',
    'banderais',
    'banderait',
    'bandèrent',
    'banderiez',
    'banderole',
    'banderons',
    'banderont',
    'bandonéon',
    'banjoïste',
    'banlieues',
    'bannaient',
    'bannasses',
    'bannerais',
    'bannerait',
    'bannèrent',
    'bannerets',
    'banneriez',
    'bannerons',
    'banneront',
    'bannetons',
    'bannettes',
    'bannières',
    'bannirais',
    'bannirait',
    'bannirent',
    'banniriez',
    'bannirons',
    'banniront',
    'bannisses',
    'bannissez',
    'banquâmes',
    'banquasse',
    'banquâtes',
    'banquerai',
    'banqueras',
    'banquerez',
    'banquetai',
    'banquetas',
    'banquetât',
    'banqueter',
    'banquetez',
    'banquette',
    'banquière',
    'banquiers',
    'banquions',
    'banquises',
    'banquiste',
    'baptisais',
    'baptisait',
    'baptisant',
    'baptisées',
    'baptisent',
    'baptisera',
    'baptisiez',
    'baptismal',
    'baptismes',
    'baptisons',
    'baptistes',
    'baquetais',
    'baquetait',
    'baquetant',
    'baquetées',
    'baquetiez',
    'baquetons',
    'baquettes',
    'baragouin',
    'baraquais',
    'baraquait',
    'baraquant',
    'baraquent',
    'baraquera',
    'baraquiez',
    'baraquons',
    'baraterie',
    'baratinai',
    'baratinas',
    'baratinât',
    'baratinée',
    'baratiner',
    'baratines',
    'baratinés',
    'baratinez',
    'barattage',
    'barattais',
    'barattait',
    'barattant',
    'barattées',
    'barattent',
    'barattera',
    'barattiez',
    'barattons',
    'barbacane',
    'barbaient',
    'barbantes',
    'barbaques',
    'barbaries',
    'barbasses',
    'barbecues',
    'barbelées',
    'barbelure',
    'barberais',
    'barberait',
    'barbèrent',
    'barberiez',
    'barberons',
    'barberont',
    'barbettes',
    'barbiches',
    'barbifiai',
    'barbifias',
    'barbifiât',
    'barbifiée',
    'barbifier',
    'barbifies',
    'barbifiés',
    'barbifiez',
    'barbilles',
    'barbillon',
    'barbitals',
    'barbotage',
    'barbotais',
    'barbotait',
    'barbotant',
    'barbotées',
    'barbotent',
    'barbotera',
    'barboteur',
    'barbotiez',
    'barbotine',
    'barbotins',
    'barbotons',
    'barbouzes',
    'barcasses',
    'bardaient',
    'bardasses',
    'barderais',
    'barderait',
    'bardèrent',
    'barderiez',
    'barderons',
    'barderont',
    'barétâmes',
    'barétasse',
    'barétâtes',
    'barèterai',
    'barèteras',
    'barèterez',
    'barétions',
    'barguigna',
    'barguigne',
    'barguigné',
    'barillets',
    'bariolage',
    'bariolais',
    'bariolait',
    'bariolant',
    'bariolées',
    'bariolent',
    'bariolera',
    'barioliez',
    'bariolons',
    'bariolure',
    'barlongue',
    'barnabite',
    'baromètre',
    'baronnage',
    'baronnais',
    'baronnait',
    'baronnant',
    'baronnées',
    'baronnent',
    'baronnera',
    'baronnets',
    'baronnies',
    'baronniez',
    'baronnons',
    'baroscope',
    'baroudais',
    'baroudait',
    'baroudant',
    'baroudent',
    'baroudera',
    'baroudeur',
    'baroudiez',
    'baroudons',
    'baroufles',
    'barquette',
    'barracuda',
    'barraient',
    'barrasses',
    'barrement',
    'barrerais',
    'barrerait',
    'barrèrent',
    'barreriez',
    'barrerons',
    'barreront',
    'barrettes',
    'barreuses',
    'barricada',
    'barricade',
    'barricadé',
    'barrières',
    'barriques',
    'barrirais',
    'barrirait',
    'barrirent',
    'barririez',
    'barrirons',
    'barriront',
    'barrisses',
    'barrissez',
    'barytines',
    'basanâmes',
    'basanasse',
    'basanâtes',
    'basanerai',
    'basaneras',
    'basanerez',
    'basanions',
    'basassent',
    'basassiez',
    'bas-bleus',
    'bas-côtés',
    'basculais',
    'basculait',
    'basculant',
    'basculées',
    'basculent',
    'basculera',
    'basculeur',
    'basculiez',
    'basculons',
    'base-ball',
    'baserions',
    'bas-fonds',
    'basicités',
    'basilaire',
    'basilical',
    'basilique',
    'basophile',
    'basquaise',
    'basquines',
    'bassement',
    'bassesses',
    'bassinais',
    'bassinait',
    'bassinant',
    'bassinées',
    'bassinent',
    'bassinera',
    'bassinets',
    'bassiniez',
    'bassinons',
    'bassistes',
    'basternes',
    'bastidons',
    'bastillée',
    'bastilles',
    'bastillés',
    'bastionna',
    'bastionne',
    'bastionné',
    'bataclans',
    'bataillai',
    'bataillas',
    'bataillât',
    'batailler',
    'batailles',
    'bataillez',
    'bataillon',
    'batardeau',
    'bâtardise',
    'bâtassent',
    'bâtassiez',
    'batavique',
    'batayoles',
    'batelages',
    'batelâmes',
    'batelasse',
    'batelâtes',
    'bateleurs',
    'bateleuse',
    'batelière',
    'bateliers',
    'batelions',
    'batellent',
    'batellera',
    'bâterions',
    'bat-flanc',
    'bathyales',
    'batifolai',
    'batifolas',
    'batifolât',
    'batifolée',
    'batifoler',
    'batifoles',
    'batifolés',
    'batifolez',
    'bâtiments',
    'bâtirions',
    'bâtissais',
    'bâtissait',
    'bâtissant',
    'bâtissent',
    'bâtisseur',
    'bâtissiez',
    'bâtissons',
    'bâtonnais',
    'bâtonnait',
    'bâtonnant',
    'bâtonnats',
    'bâtonnées',
    'bâtonnent',
    'bâtonnera',
    'bâtonnets',
    'bâtonnier',
    'bâtonniez',
    'bâtonnons',
    'batracien',
    'battaient',
    'battantes',
    'battement',
    'batteries',
    'batteuses',
    'battirent',
    'battisses',
    'battrions',
    'baudriers',
    'baudroies',
    'baudruche',
    'baugeâmes',
    'baugeasse',
    'baugeâtes',
    'baugerais',
    'baugerait',
    'baugèrent',
    'baugeriez',
    'baugerons',
    'baugeront',
    'bauquière',
    'bavardage',
    'bavardais',
    'bavardait',
    'bavardant',
    'bavardent',
    'bavardera',
    'bavardiez',
    'bavardons',
    'bavaroise',
    'bavassais',
    'bavassait',
    'bavassant',
    'bavassent',
    'bavassera',
    'bavassiez',
    'bavassons',
    'baverions',
    'bavochais',
    'bavochait',
    'bavochant',
    'bavochent',
    'bavochera',
    'bavochiez',
    'bavochons',
    'bavochure',
    'bayadères',
    'bayassent',
    'bayassiez',
    'bayerions',
    'bazardais',
    'bazardait',
    'bazardant',
    'bazardées',
    'bazardent',
    'bazardera',
    'bazardiez',
    'bazardons',
    'béarnaise',
    'béassions',
    'béatement',
    'béatifiai',
    'béatifias',
    'béatifiât',
    'béatifiée',
    'béatifier',
    'béatifies',
    'béatifiés',
    'béatifiez',
    'béatitude',
    'beauceron',
    'beau-fils',
    'beau-père',
    'bécasseau',
    'bécassine',
    'becfigues',
    'bêchaient',
    'béchamels',
    'bêchasses',
    'bêcherais',
    'bêcherait',
    'bêchèrent',
    'bêcheriez',
    'bêcherons',
    'bêcheront',
    'bêcheuses',
    'béchiques',
    'bécotâmes',
    'bécotasse',
    'bécotâtes',
    'bécoterai',
    'bécoteras',
    'bécoterez',
    'bécotions',
    'becquâmes',
    'becquasse',
    'becquâtes',
    'becquerai',
    'becqueras',
    'becquerel',
    'becquerez',
    'becquetai',
    'becquetas',
    'becquetât',
    'becquetée',
    'becqueter',
    'becquetés',
    'becquetez',
    'becquette',
    'becquions',
    'bectaient',
    'bectasses',
    'becterais',
    'becterait',
    'bectèrent',
    'becteriez',
    'becterons',
    'becteront',
    'bedonnais',
    'bedonnait',
    'bedonnant',
    'bedonnent',
    'bedonnera',
    'bedonniez',
    'bedonnons',
    'bédouines',
    'beefsteak',
    'béeraient',
    'bégaierai',
    'bégaieras',
    'bégaierez',
    'bégayâmes',
    'bégayasse',
    'bégayâtes',
    'bégayerai',
    'bégayeras',
    'bégayerez',
    'bégayeurs',
    'bégayeuse',
    'bégayions',
    'béguetais',
    'béguetait',
    'béguetant',
    'béguètent',
    'béguètera',
    'béguetiez',
    'béguetons',
    'bégueules',
    'béguinage',
    'bélandres',
    'bêlassent',
    'bêlassiez',
    'bêlements',
    'bélemnite',
    'bêlerions',
    'belladone',
    'bellâtres',
    'bellement',
    'belluaire',
    'belvédère',
    'bémolisai',
    'bémolisas',
    'bémolisât',
    'bémolisée',
    'bémoliser',
    'bémolises',
    'bémolisés',
    'bémolisez',
    'bénéfices',
    'bénéficia',
    'bénéficie',
    'bénéficié',
    'bénéfique',
    'bénévolat',
    'bénévoles',
    'bénignité',
    'bénirions',
    'bénissais',
    'bénissait',
    'bénissant',
    'bénissent',
    'bénisseur',
    'bénissiez',
    'bénissons',
    'bénitiers',
    'benjamine',
    'benjamins',
    'benthique',
    'bentonite',
    'benzoates',
    'béotismes',
    'béquaient',
    'béquasses',
    'béquerais',
    'béquerait',
    'béquèrent',
    'béqueriez',
    'béquerons',
    'béqueront',
    'béquetais',
    'béquetait',
    'béquetant',
    'béquetées',
    'béquetiez',
    'béquetons',
    'béquettes',
    'béquillai',
    'béquillas',
    'béquillât',
    'béquillée',
    'béquiller',
    'béquilles',
    'béquillés',
    'béquillez',
    'berçaient',
    'berçantes',
    'berçasses',
    'bercement',
    'bercerais',
    'bercerait',
    'bercèrent',
    'berceriez',
    'bercerons',
    'berceront',
    'berceuses',
    'bergamote',
    'bergeries',
    'berkélium',
    'berlingot',
    'bernaches',
    'bernacles',
    'bernaient',
    'bernardin',
    'bernasses',
    'bernerais',
    'bernerait',
    'bernèrent',
    'berneriez',
    'bernerons',
    'berneront',
    'bernicles',
    'berniques',
    'berrichon',
    'béryllium',
    'besaiguës',
    'besognais',
    'besognait',
    'besognant',
    'besognent',
    'besognera',
    'besogneux',
    'besogniez',
    'besognons',
    'bessemers',
    'bestiaire',
    'bestiales',
    'bestioles',
    'bêtatrons',
    'bêtifiais',
    'bêtifiait',
    'bêtifiant',
    'bêtifient',
    'bêtifiera',
    'bêtifiiez',
    'bêtifions',
    'bêtisâmes',
    'bêtisasse',
    'bêtisâtes',
    'bêtiserai',
    'bêtiseras',
    'bêtiserez',
    'bêtisiers',
    'bêtisions',
    'bétonnage',
    'bétonnais',
    'bétonnait',
    'bétonnant',
    'bétonnées',
    'bétonnent',
    'bétonnera',
    'bétonniez',
    'bétonnons',
    'betterave',
    'beuglâmes',
    'beuglante',
    'beuglants',
    'beuglasse',
    'beuglâtes',
    'beuglerai',
    'beugleras',
    'beuglerez',
    'beuglions',
    'beurrâmes',
    'beurrasse',
    'beurrâtes',
    'beurrerai',
    'beurreras',
    'beurrerez',
    'beurrerie',
    'beurriers',
    'beurrions',
    'beuveries',
    'bévatrons',
    'beylicale',
    'beylicats',
    'beylicaux',
    'beylismes',
    'biacuminé',
    'biaisâmes',
    'biaisasse',
    'biaisâtes',
    'biaiserai',
    'biaiseras',
    'biaiserez',
    'biaisions',
    'bibelotai',
    'bibelotas',
    'bibelotât',
    'bibeloter',
    'bibelotes',
    'bibelotez',
    'biberonna',
    'biberonne',
    'biberonné',
    'bibliobus',
    'bibliques',
    'bicarrées',
    'bicéphale',
    'bichaient',
    'bichasses',
    'bicherais',
    'bicherait',
    'bichèrent',
    'bicheriez',
    'bicherons',
    'bicheront',
    'bichettes',
    'bichlamar',
    'bichonnai',
    'bichonnas',
    'bichonnât',
    'bichonnée',
    'bichonner',
    'bichonnes',
    'bichonnés',
    'bichonnez',
    'bichotais',
    'bichotait',
    'bichotant',
    'bichotent',
    'bichotera',
    'bichotiez',
    'bichotons',
    'bichromie',
    'bickfords',
    'bicolores',
    'biconcave',
    'biconvexe',
    'bidonnais',
    'bidonnait',
    'bidonnant',
    'bidonnées',
    'bidonnent',
    'bidonnera',
    'bidonniez',
    'bidonnons',
    'bidouilla',
    'bidouille',
    'bidouillé',
    'biellette',
    'bien-aimé',
    'bien-dire',
    'bien-être',
    'bienfaits',
    'bien-jugé',
    'biennales',
    'bienséant',
    'bienvenir',
    'bienvenue',
    'bienvenus',
    'biffaient',
    'biffasses',
    'bifferais',
    'bifferait',
    'biffèrent',
    'bifferiez',
    'bifferons',
    'bifferont',
    'bifilaire',
    'bifocales',
    'bifurquai',
    'bifurquas',
    'bifurquât',
    'bifurquer',
    'bifurques',
    'bifurquez',
    'bigarades',
    'bigarrais',
    'bigarrait',
    'bigarrant',
    'bigarreau',
    'bigarrées',
    'bigarrent',
    'bigarrera',
    'bigarriez',
    'bigarrons',
    'bigarrure',
    'biglaient',
    'biglasses',
    'biglerais',
    'biglerait',
    'biglèrent',
    'bigleriez',
    'biglerons',
    'bigleront',
    'bigleuses',
    'bignonias',
    'bigophone',
    'bigornais',
    'bigornait',
    'bigornant',
    'bigorneau',
    'bigornées',
    'bigornent',
    'bigornera',
    'bigorniez',
    'bigornons',
    'bigoterie',
    'bigotisme',
    'bigrement',
    'bihoreaus',
    'bijectifs',
    'bijection',
    'bijective',
    'bijoutier',
    'bilabiale',
    'bilabiées',
    'bilassent',
    'bilassiez',
    'bilatéral',
    'bilboquet',
    'bilerions',
    'bilharzie',
    'biliaires',
    'bilieuses',
    'bilingues',
    'billetées',
    'billettes',
    'billonnai',
    'billonnas',
    'billonnât',
    'billonnée',
    'billonner',
    'billonnes',
    'billonnés',
    'billonnez',
    'biloquais',
    'biloquait',
    'biloquant',
    'biloquées',
    'biloquent',
    'biloquera',
    'biloquiez',
    'biloquons',
    'bimensuel',
    'bimoteurs',
    'binassent',
    'binassiez',
    'binerions',
    'binoclard',
    'biocénose',
    'biochimie',
    'biogenèse',
    'biographe',
    'biologies',
    'biomasses',
    'bioniques',
    'biosphère',
    'biotiques',
    'biparties',
    'bipartite',
    'bipennées',
    'biphasées',
    'bipolaire',
    'bipoutres',
    'biquettes',
    'bisaïeule',
    'bisaïeuls',
    'bisannuel',
    'bisassent',
    'bisassiez',
    'bisbilles',
    'biscaïens',
    'biscornue',
    'biscornus',
    'biscottes',
    'biscuitai',
    'biscuitas',
    'biscuitât',
    'biscuitée',
    'biscuiter',
    'biscuites',
    'biscuités',
    'biscuitez',
    'biseautai',
    'biseautas',
    'biseautât',
    'biseautée',
    'biseauter',
    'biseautes',
    'biseautés',
    'biseautez',
    'biserions',
    'bisexuées',
    'bisexuels',
    'bisquâmes',
    'bisquasse',
    'bisquâtes',
    'bisquerai',
    'bisqueras',
    'bisquerez',
    'bisquions',
    'bissaient',
    'bissasses',
    'bisserais',
    'bisserait',
    'bissèrent',
    'bisseriez',
    'bisserons',
    'bisseront',
    'bissextes',
    'bissexuée',
    'bissexuel',
    'bissexués',
    'bistortes',
    'bistouris',
    'bistourna',
    'bistourne',
    'bistourné',
    'bistrâmes',
    'bistrasse',
    'bistrâtes',
    'bistrerai',
    'bistreras',
    'bistrerez',
    'bistrions',
    'bisulfate',
    'bisulfure',
    'bitassent',
    'bitassiez',
    'bitension',
    'biterions',
    'bitonales',
    'bittaient',
    'bittasses',
    'bitterais',
    'bitterait',
    'bittèrent',
    'bitteriez',
    'bitterons',
    'bitteront',
    'bitturais',
    'bitturait',
    'bitturant',
    'bitturées',
    'bitturent',
    'bitturera',
    'bitturiez',
    'bitturons',
    'bitumages',
    'bitumâmes',
    'bitumasse',
    'bitumâtes',
    'bitumerai',
    'bitumeras',
    'bitumerez',
    'bitumeuse',
    'bituminai',
    'bituminas',
    'bituminât',
    'bituminée',
    'bituminer',
    'bitumines',
    'bituminés',
    'bituminez',
    'bitumions',
    'biturâmes',
    'biturasse',
    'biturâtes',
    'biturbine',
    'biturerai',
    'bitureras',
    'biturerez',
    'biturions',
    'bivalence',
    'bivalente',
    'bivalents',
    'bivouaqua',
    'bivouaque',
    'bivouaqué',
    'bizutages',
    'bizutâmes',
    'bizutasse',
    'bizutâtes',
    'bizuterai',
    'bizuteras',
    'bizuterez',
    'bizutions',
    'blablabla',
    'blablatai',
    'blablatas',
    'blablatât',
    'blablater',
    'blablates',
    'blablatez',
    'black-out',
    'blafardes',
    'blaguâmes',
    'blaguasse',
    'blaguâtes',
    'blaguerai',
    'blagueras',
    'blaguerez',
    'blagueurs',
    'blagueuse',
    'blaguions',
    'blairâmes',
    'blairasse',
    'blairâtes',
    'blaireaux',
    'blairerai',
    'blaireras',
    'blairerez',
    'blairions',
    'blâmables',
    'blâmaient',
    'blâmasses',
    'blâmerais',
    'blâmerait',
    'blâmèrent',
    'blâmeriez',
    'blâmerons',
    'blâmeront',
    'blanc-bec',
    'blanchets',
    'blancheur',
    'blanchies',
    'blanchira',
    'blandices',
    'blasaient',
    'blasasses',
    'blasement',
    'blaserais',
    'blaserait',
    'blasèrent',
    'blaseriez',
    'blaserons',
    'blaseront',
    'blasonnai',
    'blasonnas',
    'blasonnât',
    'blasonnée',
    'blasonner',
    'blasonnes',
    'blasonnés',
    'blasonnez',
    'blasphéma',
    'blasphémé',
    'blasphème',
    'blastulas',
    'blatérais',
    'blatérait',
    'blatérant',
    'blatérées',
    'blatèrent',
    'blatérera',
    'blatériez',
    'blatérons',
    'blêmaient',
    'blêmerais',
    'blêmerait',
    'blêmeriez',
    'blêmerons',
    'blêmeront',
    'blêmirent',
    'blêmisses',
    'blésaient',
    'blésasses',
    'blèsement',
    'bléserais',
    'bléserait',
    'blésèrent',
    'bléseriez',
    'bléserons',
    'bléseront',
    'blessâmes',
    'blessante',
    'blessants',
    'blessasse',
    'blessâtes',
    'blesserai',
    'blesseras',
    'blesserez',
    'blessions',
    'blessures',
    'blettîmes',
    'blettirai',
    'blettiras',
    'blettirez',
    'blettisse',
    'blettîtes',
    'bleuâtres',
    'bleuirais',
    'bleuirait',
    'bleuirent',
    'bleuiriez',
    'bleuirons',
    'bleuiront',
    'bleuisses',
    'bleuissez',
    'bleutâmes',
    'bleutasse',
    'bleutâtes',
    'bleuterai',
    'bleuteras',
    'bleuterez',
    'bleutions',
    'blindages',
    'blindâmes',
    'blindasse',
    'blindâtes',
    'blinderai',
    'blinderas',
    'blinderez',
    'blindions',
    'blizzards',
    'blocaille',
    'blockhaus',
    'blondasse',
    'blondeurs',
    'blondîmes',
    'blondines',
    'blondinet',
    'blondisse',
    'blondîtes',
    'blondoies',
    'blondoyai',
    'blondoyas',
    'blondoyât',
    'blondoyer',
    'blondoyez',
    'bloquâmes',
    'bloquante',
    'bloquants',
    'bloquasse',
    'bloquâtes',
    'bloquerai',
    'bloqueras',
    'bloquerez',
    'bloquions',
    'blottîmes',
    'blottirai',
    'blottiras',
    'blottirez',
    'blottisse',
    'blottîtes',
    'blousâmes',
    'blousasse',
    'blousâtes',
    'blouserai',
    'blouseras',
    'blouserez',
    'blousions',
    'blue-jean',
    'bluffâmes',
    'bluffasse',
    'bluffâtes',
    'blufferai',
    'blufferas',
    'blufferez',
    'bluffeurs',
    'bluffeuse',
    'bluffions',
    'blutaient',
    'blutasses',
    'bluterais',
    'bluterait',
    'blutèrent',
    'bluteriez',
    'bluterons',
    'bluteront',
    'bobinages',
    'bobinâmes',
    'bobinasse',
    'bobinâtes',
    'bobinerai',
    'bobineras',
    'bobinerez',
    'bobinette',
    'bobineurs',
    'bobineuse',
    'bobiniers',
    'bobinions',
    'bobinoirs',
    'bobsleigh',
    'bocagères',
    'bocardage',
    'bocardais',
    'bocardait',
    'bocardant',
    'bocardées',
    'bocardent',
    'bocardera',
    'bocardiez',
    'bocardons',
    'boettâmes',
    'boettasse',
    'boettâtes',
    'boetterai',
    'boetteras',
    'boetterez',
    'boettions',
    'bohémiens',
    'boiraient',
    'boisaient',
    'boisasses',
    'boisement',
    'boiserais',
    'boiserait',
    'boisèrent',
    'boiseries',
    'boiseriez',
    'boiserons',
    'boiseront',
    'boisseaux',
    'boitaient',
    'boitasses',
    'boitement',
    'boiterais',
    'boiterait',
    'boitèrent',
    'boiteries',
    'boiteriez',
    'boiterons',
    'boiteront',
    'boiteuses',
    'boitillai',
    'boitillas',
    'boitillât',
    'boitiller',
    'boitilles',
    'boitillez',
    'bolchevik',
    'bolivares',
    'bolomètre',
    'bombaient',
    'bombances',
    'bombantes',
    'bombardai',
    'bombardas',
    'bombardât',
    'bombardée',
    'bombarder',
    'bombardes',
    'bombardés',
    'bombardez',
    'bombardon',
    'bombasses',
    'bombement',
    'bomberais',
    'bomberait',
    'bombèrent',
    'bomberiez',
    'bomberons',
    'bomberont',
    'bonbonnes',
    'bondaient',
    'bondasses',
    'bondelles',
    'bonderais',
    'bonderait',
    'bondèrent',
    'bonderiez',
    'bondérisa',
    'bondérise',
    'bondérisé',
    'bonderons',
    'bonderont',
    'bondirais',
    'bondirait',
    'bondirent',
    'bondiriez',
    'bondirons',
    'bondiront',
    'bondisses',
    'bondissez',
    'bondonnai',
    'bondonnas',
    'bondonnât',
    'bondonnée',
    'bondonner',
    'bondonnes',
    'bondonnés',
    'bondonnez',
    'bonhomies',
    'bonichons',
    'bonifiais',
    'bonifiait',
    'bonifiant',
    'bonifiées',
    'bonifient',
    'bonifiera',
    'bonifiiez',
    'bonifions',
    'bonimenta',
    'bonimente',
    'bonimenté',
    'boniments',
    'bonnement',
    'bonneteau',
    'bonneteur',
    'bonnetier',
    'bonnettes',
    'bonzeries',
    'bookmaker',
    'booléenne',
    'boomerang',
    'boqueteau',
    'bordaient',
    'bordasses',
    'bordelais',
    'borderais',
    'borderait',
    'bordereau',
    'bordèrent',
    'borderies',
    'borderiez',
    'borderons',
    'borderont',
    'bordières',
    'bordigues',
    'bornaient',
    'bornasses',
    'bornerais',
    'bornerait',
    'bornèrent',
    'borneriez',
    'bornerons',
    'borneront',
    'bornoient',
    'bornoiera',
    'bornoyais',
    'bornoyait',
    'bornoyant',
    'bornoyées',
    'bornoyiez',
    'bornoyons',
    'boscottes',
    'bossaient',
    'bossasses',
    'bosselage',
    'bosselais',
    'bosselait',
    'bosselant',
    'bosselées',
    'bosseliez',
    'bosselles',
    'bosselons',
    'bosselure',
    'bosserais',
    'bosserait',
    'bossèrent',
    'bosseriez',
    'bosserons',
    'bosseront',
    'bossettes',
    'bosseuses',
    'bossuâmes',
    'bossuasse',
    'bossuâtes',
    'bossuerai',
    'bossueras',
    'bossuerez',
    'bossuions',
    'bostryche',
    'botanique',
    'botanisai',
    'botanisas',
    'botanisât',
    'botaniser',
    'botanises',
    'botanisez',
    'botaniste',
    'bottaient',
    'bottasses',
    'bottelage',
    'bottelais',
    'bottelait',
    'bottelant',
    'bottelées',
    'botteleur',
    'botteliez',
    'bottelles',
    'bottelons',
    'botterais',
    'botterait',
    'bottèrent',
    'botteriez',
    'botterons',
    'botteront',
    'bottillon',
    'botulique',
    'botulisme',
    'boucanage',
    'boucanais',
    'boucanait',
    'boucanant',
    'boucanées',
    'boucanent',
    'boucanera',
    'boucanier',
    'boucaniez',
    'boucanons',
    'bouchages',
    'bouchâmes',
    'boucharda',
    'boucharde',
    'bouchardé',
    'bouchasse',
    'bouchâtes',
    'boucherai',
    'boucheras',
    'boucherez',
    'boucherie',
    'bouchions',
    'bouchonna',
    'bouchonne',
    'bouchonné',
    'bouclages',
    'bouclâmes',
    'bouclasse',
    'bouclâtes',
    'bouclerai',
    'boucleras',
    'bouclerez',
    'bouclette',
    'boucliers',
    'bouclions',
    'boudaient',
    'boudasses',
    'bouderais',
    'bouderait',
    'boudèrent',
    'bouderies',
    'bouderiez',
    'bouderons',
    'bouderont',
    'boudeuses',
    'boudinage',
    'boudinais',
    'boudinait',
    'boudinant',
    'boudinées',
    'boudinent',
    'boudinera',
    'boudiniez',
    'boudinons',
    'bouffâmes',
    'bouffante',
    'bouffarde',
    'bouffasse',
    'bouffâtes',
    'boufferai',
    'boufferas',
    'boufferez',
    'bouffette',
    'bouffîmes',
    'bouffions',
    'bouffirai',
    'bouffiras',
    'bouffirez',
    'bouffisse',
    'bouffîtes',
    'bouffonna',
    'bouffonne',
    'bouffonné',
    'bougeâmes',
    'bougeasse',
    'bougeâtes',
    'bougeoirs',
    'bougeotte',
    'bougerais',
    'bougerait',
    'bougèrent',
    'bougeriez',
    'bougerons',
    'bougeront',
    'bougonnai',
    'bougonnas',
    'bougonnât',
    'bougonner',
    'bougonnes',
    'bougonnez',
    'boui-boui',
    'bouillais',
    'bouillait',
    'bouillant',
    'bouillent',
    'bouilleur',
    'bouillies',
    'bouilliez',
    'bouillira',
    'bouillons',
    'boulaient',
    'boulangea',
    'boulangée',
    'boulanger',
    'boulanges',
    'boulangés',
    'boulangez',
    'boulasses',
    'boulbènes',
    'boulerais',
    'boulerait',
    'boulèrent',
    'bouleriez',
    'boulerons',
    'bouleront',
    'bouletées',
    'boulettes',
    'boulevard',
    'boulimies',
    'boulinier',
    'boulistes',
    'boulonnai',
    'boulonnas',
    'boulonnât',
    'boulonnée',
    'boulonner',
    'boulonnes',
    'boulonnés',
    'boulonnez',
    'boulottai',
    'boulottas',
    'boulottât',
    'boulottée',
    'boulotter',
    'boulottes',
    'boulottés',
    'boulottez',
    'boumerais',
    'boumerait',
    'boumeriez',
    'boumerons',
    'boumeront',
    'bouquetin',
    'bouquinai',
    'bouquinas',
    'bouquinât',
    'bouquinée',
    'bouquiner',
    'bouquines',
    'bouquinés',
    'bouquinez',
    'bourbeuse',
    'bourbiers',
    'bourdaine',
    'bourdâmes',
    'bourdasse',
    'bourdâtes',
    'bourderai',
    'bourderas',
    'bourderez',
    'bourdions',
    'bourdonna',
    'bourdonne',
    'bourdonné',
    'bourgades',
    'bourgeois',
    'bourgeons',
    'bourgeron',
    'bourgogne',
    'bourrache',
    'bourrades',
    'bourrages',
    'bourrâmes',
    'bourrasse',
    'bourrâtes',
    'bourratif',
    'bourreaux',
    'bourrelai',
    'bourrelas',
    'bourrelât',
    'bourrelée',
    'bourreler',
    'bourrelés',
    'bourrelet',
    'bourrelez',
    'bourrelle',
    'bourrerai',
    'bourreras',
    'bourrerez',
    'bourrette',
    'bourriche',
    'bourricot',
    'bourrions',
    'bourriqua',
    'bourrique',
    'bourriqué',
    'bourroirs',
    'boursière',
    'boursiers',
    'bouscueil',
    'bousculai',
    'bousculas',
    'bousculât',
    'bousculée',
    'bousculer',
    'bouscules',
    'bousculés',
    'bousculez',
    'bousillai',
    'bousillas',
    'bousillât',
    'bousillée',
    'bousiller',
    'bousilles',
    'bousillés',
    'bousillez',
    'boussoles',
    'boutaient',
    'boutasses',
    'boutefeux',
    'bouteille',
    'bouterais',
    'bouterait',
    'boutèrent',
    'bouteriez',
    'bouterons',
    'bouteront',
    'bouteroue',
    'boutiques',
    'boutisses',
    'boutonnai',
    'boutonnas',
    'boutonnât',
    'boutonnée',
    'boutonner',
    'boutonnes',
    'boutonnés',
    'boutonnez',
    'bouturage',
    'bouturais',
    'bouturait',
    'bouturant',
    'bouturent',
    'bouturera',
    'bouturiez',
    'bouturons',
    'bouveries',
    'bouvières',
    'bouvillon',
    'bouvreuil',
    'bovarysme',
    'boxassent',
    'boxassiez',
    'box-calfs',
    'boxerions',
    'boyaudier',
    'boycottai',
    'boycottas',
    'boycottât',
    'boycottée',
    'boycotter',
    'boycottes',
    'boycottés',
    'boycottez',
    'boy-scout',
    'brabançon',
    'bracelets',
    'brachiale',
    'brachiaux',
    'braconnai',
    'braconnas',
    'braconnât',
    'braconnée',
    'braconner',
    'braconnes',
    'braconnés',
    'braconnez',
    'bractéale',
    'bractéaux',
    'bradaient',
    'bradasses',
    'braderais',
    'braderait',
    'bradèrent',
    'braderies',
    'braderiez',
    'braderons',
    'braderont',
    'bradeuses',
    'braguette',
    'brahmanes',
    'braierais',
    'braierait',
    'braieriez',
    'braierons',
    'braieront',
    'braillais',
    'braillait',
    'braillant',
    'braillard',
    'braillées',
    'braillent',
    'braillera',
    'brailleur',
    'brailliez',
    'braillons',
    'braiments',
    'braisâmes',
    'braisasse',
    'braisâtes',
    'braiserai',
    'braiseras',
    'braiserez',
    'braisière',
    'braisions',
    'bramaient',
    'bramasses',
    'bramement',
    'bramerais',
    'bramerait',
    'bramèrent',
    'brameriez',
    'bramerons',
    'brameront',
    'brancards',
    'branchage',
    'branchais',
    'branchait',
    'branchant',
    'branchées',
    'branchent',
    'branchera',
    'branchial',
    'branchies',
    'branchiez',
    'branchons',
    'branchues',
    'brandades',
    'brandilla',
    'brandille',
    'brandillé',
    'brandîmes',
    'brandirai',
    'brandiras',
    'brandirez',
    'brandisse',
    'brandîtes',
    'branlâmes',
    'branlante',
    'branlants',
    'branlasse',
    'branlâtes',
    'branlerai',
    'branleras',
    'branlerez',
    'branlions',
    'braquages',
    'braquâmes',
    'braquasse',
    'braquâtes',
    'braquerai',
    'braqueras',
    'braquerez',
    'braquions',
    'brasaient',
    'brasasses',
    'braserais',
    'braserait',
    'brasèrent',
    'braseriez',
    'braserons',
    'braseront',
    'brasillai',
    'brasillas',
    'brasillât',
    'brasiller',
    'brasilles',
    'brasillez',
    'brassages',
    'brassâmes',
    'brassards',
    'brassasse',
    'brassâtes',
    'brasserai',
    'brasseras',
    'brasserez',
    'brasserie',
    'brasseurs',
    'brasseuse',
    'brassière',
    'brassions',
    'bravaches',
    'bravaient',
    'bravasses',
    'bravement',
    'braverais',
    'braverait',
    'bravèrent',
    'braveriez',
    'braverons',
    'braveront',
    'bravoures',
    'brayaient',
    'brayasses',
    'brayerais',
    'brayerait',
    'brayèrent',
    'brayeriez',
    'brayerons',
    'brayeront',
    'breakfast',
    'brélaient',
    'brêlaient',
    'brélasses',
    'brêlasses',
    'brélerais',
    'brêlerais',
    'brélerait',
    'brêlerait',
    'brélèrent',
    'brêlèrent',
    'bréleriez',
    'brêleriez',
    'brélerons',
    'brêlerons',
    'bréleront',
    'brêleront',
    'brellâmes',
    'brellasse',
    'brellâtes',
    'brellerai',
    'brelleras',
    'brellerez',
    'brellions',
    'breloques',
    'brésilien',
    'brésillai',
    'brésillas',
    'brésillât',
    'brésillée',
    'brésiller',
    'brésilles',
    'brésillés',
    'brésillez',
    'bretèches',
    'bretelles',
    'bretessée',
    'bretessés',
    'brettâmes',
    'brettasse',
    'brettâtes',
    'brettelai',
    'brettelas',
    'brettelât',
    'brettelée',
    'bretteler',
    'brettelés',
    'brettelez',
    'brettelle',
    'bretterai',
    'bretteras',
    'bretterez',
    'bretteurs',
    'brettions',
    'breuvages',
    'brevetais',
    'brevetait',
    'brevetant',
    'brevetées',
    'brevetiez',
    'brevetons',
    'brevettes',
    'bréviaire',
    'bricolage',
    'bricolais',
    'bricolait',
    'bricolant',
    'bricolées',
    'bricolent',
    'bricolera',
    'bricoleur',
    'bricoliez',
    'bricolons',
    'bridaient',
    'bridasses',
    'briderais',
    'briderait',
    'bridèrent',
    'brideriez',
    'briderons',
    'brideront',
    'bridgeais',
    'bridgeait',
    'bridgeant',
    'bridgeons',
    'bridgerai',
    'bridgeras',
    'bridgerez',
    'bridgeurs',
    'bridgeuse',
    'bridgions',
    'briefings',
    'brièvetés',
    'brifaient',
    'brifasses',
    'briferais',
    'briferait',
    'brifèrent',
    'briferiez',
    'briferons',
    'briferont',
    'briffâmes',
    'briffasse',
    'briffâtes',
    'brifferai',
    'brifferas',
    'brifferez',
    'briffions',
    'brigadier',
    'brigandai',
    'brigandas',
    'brigandât',
    'brigandée',
    'brigander',
    'brigandes',
    'brigandés',
    'brigandez',
    'brigantin',
    'briguâmes',
    'briguasse',
    'briguâtes',
    'briguerai',
    'brigueras',
    'briguerez',
    'briguions',
    'brillâmes',
    'brillance',
    'brillanta',
    'brillante',
    'brillanté',
    'brillants',
    'brillasse',
    'brillâtes',
    'brillerai',
    'brilleras',
    'brillerez',
    'brillions',
    'brimaient',
    'brimasses',
    'brimbalai',
    'brimbalas',
    'brimbalât',
    'brimbalée',
    'brimbaler',
    'brimbales',
    'brimbalés',
    'brimbalez',
    'brimerais',
    'brimerait',
    'brimèrent',
    'brimeriez',
    'brimerons',
    'brimeront',
    'brindille',
    'briochées',
    'briquâmes',
    'briquasse',
    'briquâtes',
    'briquerai',
    'briqueras',
    'briquerez',
    'briquetai',
    'briquetas',
    'briquetât',
    'briquetée',
    'briqueter',
    'briquetés',
    'briquetez',
    'briquette',
    'briquions',
    'brisaient',
    'brisasses',
    'briscards',
    'brise-fer',
    'brise-jet',
    'brisement',
    'briserais',
    'briserait',
    'brisèrent',
    'briseriez',
    'briserons',
    'briseront',
    'briseuses',
    'brocantai',
    'brocantas',
    'brocantât',
    'brocantée',
    'brocanter',
    'brocantes',
    'brocantés',
    'brocantez',
    'brocardai',
    'brocardas',
    'brocardât',
    'brocardée',
    'brocarder',
    'brocardes',
    'brocardés',
    'brocardez',
    'brochages',
    'brochâmes',
    'brochante',
    'brochants',
    'brochasse',
    'brochâtes',
    'brocherai',
    'brocheras',
    'brocherez',
    'brocheton',
    'brochette',
    'brocheurs',
    'brocheuse',
    'brochions',
    'brochures',
    'brodaient',
    'brodasses',
    'brodequin',
    'broderais',
    'broderait',
    'brodèrent',
    'broderies',
    'broderiez',
    'broderons',
    'broderont',
    'brodeuses',
    'broiement',
    'broierais',
    'broierait',
    'broieriez',
    'broierons',
    'broieront',
    'bromiques',
    'bromismes',
    'bronchais',
    'bronchait',
    'bronchant',
    'bronchent',
    'bronchera',
    'bronchiez',
    'bronchite',
    'bronchons',
    'bronzages',
    'bronzâmes',
    'bronzante',
    'bronzants',
    'bronzasse',
    'bronzâtes',
    'bronzerai',
    'bronzeras',
    'bronzerez',
    'bronzeurs',
    'bronziers',
    'bronzions',
    'broquette',
    'brossages',
    'brossâmes',
    'brossasse',
    'brossâtes',
    'brosserai',
    'brosseras',
    'brosserez',
    'brosserie',
    'brossière',
    'brossiers',
    'brossions',
    'brouettai',
    'brouettas',
    'brouettât',
    'brouettée',
    'brouetter',
    'brouettes',
    'brouettés',
    'brouettez',
    'brouhahas',
    'brouillai',
    'brouillas',
    'brouillât',
    'brouillée',
    'brouiller',
    'brouilles',
    'brouillés',
    'brouillez',
    'brouillon',
    'broussard',
    'broussins',
    'broutâmes',
    'broutards',
    'broutasse',
    'broutâtes',
    'brouterai',
    'brouteras',
    'brouterez',
    'broutille',
    'broutions',
    'brownings',
    'broyaient',
    'broyasses',
    'broyèrent',
    'broyeuses',
    'brucelles',
    'bruineuse',
    'bruirions',
    'bruissage',
    'bruissais',
    'bruissait',
    'bruissant',
    'bruissent',
    'bruissiez',
    'bruissons',
    'bruitages',
    'bruitâmes',
    'bruitasse',
    'bruitâtes',
    'bruiterai',
    'bruiteras',
    'bruiterez',
    'bruiteurs',
    'bruiteuse',
    'bruitions',
    'brûlaient',
    'brûlantes',
    'brûlasses',
    'brûlerais',
    'brûlerait',
    'brûlèrent',
    'brûleries',
    'brûleriez',
    'brûlerons',
    'brûleront',
    'brumassât',
    'brumasser',
    'brumerait',
    'brumeuses',
    'brunantes',
    'brunâtres',
    'brunettes',
    'brunirais',
    'brunirait',
    'brunirent',
    'bruniriez',
    'brunirons',
    'bruniront',
    'brunisses',
    'brunissez',
    'brushings',
    'brusquais',
    'brusquait',
    'brusquant',
    'brusquées',
    'brusquent',
    'brusquera',
    'brusquiez',
    'brusquons',
    'brutalisa',
    'brutalise',
    'brutalisé',
    'brutalité',
    'bruyantes',
    'bryologie',
    'buanderie',
    'buandière',
    'buandiers',
    'bubonique',
    'bûchaient',
    'bûchasses',
    'bûcherais',
    'bûcherait',
    'bûchèrent',
    'bûcheriez',
    'bûcherons',
    'bûcheront',
    'bûchettes',
    'bûcheuses',
    'bucolique',
    'budgétisa',
    'budgétise',
    'budgétisé',
    'buffetier',
    'buglosses',
    'buildings',
    'bulbaires',
    'bulbeuses',
    'bulbilles',
    'bullaires',
    'bulldozer',
    'bulletins',
    'bulleuses',
    'bungalows',
    'buprestes',
    'buraliste',
    'burinages',
    'burinâmes',
    'burinasse',
    'burinâtes',
    'burinerai',
    'burineras',
    'burinerez',
    'burineurs',
    'burinions',
    'burlesque',
    'burlingue',
    'busserole',
    'butadiène',
    'butaniers',
    'butassent',
    'butassiez',
    'buterions',
    'butinâmes',
    'butinasse',
    'butinâtes',
    'butinerai',
    'butineras',
    'butinerez',
    'butineurs',
    'butineuse',
    'butinions',
    'buttaient',
    'buttasses',
    'butterais',
    'butterait',
    'buttèrent',
    'butteriez',
    'butterons',
    'butteront',
    'butylènes',
    'butylique',
    'butyrates',
    'butyreuse',
    'butyrines',
    'butyrique',
    'buvetière',
    'buvetiers',
    'buvotâmes',
    'buvotasse',
    'buvotâtes',
    'buvoterai',
    'buvoteras',
    'buvoterez',
    'buvotions',
    'byssinose',
    'byzantine',
    'byzantins',
    'cabalâmes',
    'cabalasse',
    'cabalâtes',
    'cabalerai',
    'cabaleras',
    'cabalerez',
    'cabalions',
    'cabaliste',
    'cabanâmes',
    'cabanasse',
    'cabanâtes',
    'cabanerai',
    'cabaneras',
    'cabanerez',
    'cabanions',
    'cabassets',
    'cabernets',
    'cabestans',
    'cabillaud',
    'cabillots',
    'câblaient',
    'câblasses',
    'câblerais',
    'câblerait',
    'câblèrent',
    'câbleries',
    'câbleriez',
    'câblerons',
    'câbleront',
    'câbleuses',
    'câblistes',
    'cabochard',
    'cabochons',
    'cabossais',
    'cabossait',
    'cabossant',
    'cabossées',
    'cabossent',
    'cabossera',
    'cabossiez',
    'cabossons',
    'cabotages',
    'cabotâmes',
    'cabotasse',
    'cabotâtes',
    'caboterai',
    'caboteras',
    'caboterez',
    'caboteurs',
    'cabotinai',
    'cabotinas',
    'cabotinât',
    'cabotiner',
    'cabotines',
    'cabotinez',
    'cabotions',
    'caboulots',
    'cabraient',
    'cabrasses',
    'cabrerais',
    'cabrerait',
    'cabrèrent',
    'cabreriez',
    'cabrerons',
    'cabreront',
    'cabriolai',
    'cabriolas',
    'cabriolât',
    'cabrioler',
    'cabrioles',
    'cabriolet',
    'cabriolez',
    'cacabâmes',
    'cacabasse',
    'cacabâtes',
    'cacaberai',
    'cacaberas',
    'cacaberez',
    'cacabions',
    'cacahuète',
    'cacaotées',
    'cacaotier',
    'cacaoyère',
    'cacaoyers',
    'cacardais',
    'cacardait',
    'cacardant',
    'cacardent',
    'cacardera',
    'cacardiez',
    'cacardons',
    'cachaient',
    'cachalots',
    'cachasses',
    'cache-col',
    'cachemire',
    'cache-nez',
    'cache-pot',
    'cacherais',
    'cacherait',
    'cachèrent',
    'cacheriez',
    'cacherons',
    'cacheront',
    'cachetage',
    'cachetais',
    'cachetait',
    'cachetant',
    'cachetées',
    'cachetiez',
    'cachetons',
    'cachettes',
    'cachexies',
    'cachuchas',
    'cacochyme',
    'cacologie',
    'cadanchai',
    'cadanchas',
    'cadanchât',
    'cadancher',
    'cadanches',
    'cadanchez',
    'cadastrai',
    'cadastral',
    'cadastras',
    'cadastrât',
    'cadastrée',
    'cadastrer',
    'cadastres',
    'cadastrés',
    'cadastrez',
    'cadenassa',
    'cadenasse',
    'cadenassé',
    'cadençais',
    'cadençait',
    'cadençant',
    'cadencées',
    'cadencent',
    'cadencera',
    'cadenciez',
    'cadençons',
    'cadenette',
    'cadmiages',
    'cadmiâmes',
    'cadmiasse',
    'cadmiâtes',
    'cadmierai',
    'cadmieras',
    'cadmierez',
    'cadmiions',
    'cadraient',
    'cadrantes',
    'cadrasses',
    'cadrature',
    'cadrerais',
    'cadrerait',
    'cadrèrent',
    'cadreriez',
    'cadrerons',
    'cadreront',
    'cadreuses',
    'caducités',
    'cafardage',
    'cafardais',
    'cafardait',
    'cafardant',
    'cafardées',
    'cafardent',
    'cafardera',
    'cafardeur',
    'cafardeux',
    'cafardiez',
    'cafardons',
    'caféières',
    'caféismes',
    'cafétéria',
    'cafetière',
    'cafetiers',
    'cafouilla',
    'cafouille',
    'cafouillé',
    'caftaient',
    'caftasses',
    'cafterais',
    'cafterait',
    'caftèrent',
    'cafteriez',
    'cafterons',
    'cafteront',
    'cagerotte',
    'cagnaient',
    'cagnardai',
    'cagnardas',
    'cagnardât',
    'cagnarder',
    'cagnardes',
    'cagnardez',
    'cagnasses',
    'cagnerais',
    'cagnerait',
    'cagnèrent',
    'cagneriez',
    'cagnerons',
    'cagneront',
    'cagneuses',
    'cagnottes',
    'cagoterie',
    'cagoulard',
    'cahotâmes',
    'cahotante',
    'cahotants',
    'cahotasse',
    'cahotâtes',
    'cahoterai',
    'cahoteras',
    'cahoterez',
    'cahoteuse',
    'cahotions',
    'caillâmes',
    'caillasse',
    'caillâtes',
    'caillerai',
    'cailleras',
    'caillerez',
    'cailletai',
    'cailletas',
    'cailletât',
    'cailleter',
    'cailletez',
    'caillette',
    'caillions',
    'caillouta',
    'cailloute',
    'caillouté',
    'caisserie',
    'caissette',
    'caissière',
    'caissiers',
    'cajolâmes',
    'cajolante',
    'cajolants',
    'cajolasse',
    'cajolâtes',
    'cajolerai',
    'cajoleras',
    'cajolerez',
    'cajolerie',
    'cajoleurs',
    'cajoleuse',
    'cajolions',
    'calabrais',
    'caladiums',
    'calaisons',
    'calambacs',
    'calaminai',
    'calaminas',
    'calaminât',
    'calaminée',
    'calaminer',
    'calamines',
    'calaminés',
    'calaminez',
    'calamités',
    'calanchai',
    'calanchas',
    'calanchât',
    'calancher',
    'calanches',
    'calanchez',
    'calandrai',
    'calandras',
    'calandrât',
    'calandrée',
    'calandrer',
    'calandres',
    'calandrés',
    'calandrez',
    'calanques',
    'calassent',
    'calassiez',
    'calcaires',
    'calcanéum',
    'calcémies',
    'calcicole',
    'calcifiée',
    'calcifiés',
    'calcinais',
    'calcinait',
    'calcinant',
    'calcinées',
    'calcinent',
    'calcinera',
    'calciniez',
    'calcinons',
    'calciques',
    'calciurie',
    'calculais',
    'calculait',
    'calculant',
    'calculées',
    'calculent',
    'calculera',
    'calculeux',
    'calculiez',
    'calculons',
    'caldarium',
    'caldeiras',
    'calebasse',
    'calembour',
    'cale-pied',
    'calerions',
    'caletâmes',
    'caletasse',
    'caletâtes',
    'caletions',
    'calettent',
    'calettera',
    'calfatage',
    'calfatais',
    'calfatait',
    'calfatant',
    'calfatées',
    'calfatent',
    'calfatera',
    'calfatiez',
    'calfatons',
    'calfeutra',
    'calfeutre',
    'calfeutré',
    'calibrage',
    'calibrais',
    'calibrait',
    'calibrant',
    'calibrées',
    'calibrent',
    'calibrera',
    'calibreur',
    'calibriez',
    'calibrons',
    'calicules',
    'câlinâmes',
    'câlinante',
    'câlinants',
    'câlinasse',
    'câlinâtes',
    'câlinerai',
    'câlineras',
    'câlinerez',
    'câlinerie',
    'câlinions',
    'caliornes',
    'calissons',
    'calleuses',
    'call-girl',
    'callipyge',
    'callosité',
    'calmaient',
    'calmantes',
    'calmasses',
    'calmement',
    'calmerais',
    'calmerait',
    'calmèrent',
    'calmeriez',
    'calmerons',
    'calmeront',
    'calmirais',
    'calmirait',
    'calmirent',
    'calmiriez',
    'calmirons',
    'calmiront',
    'calmisses',
    'calmissez',
    'calomniai',
    'calomnias',
    'calomniât',
    'calomniée',
    'calomnier',
    'calomnies',
    'calomniés',
    'calomniez',
    'calorique',
    'calottais',
    'calottait',
    'calottant',
    'calottées',
    'calottent',
    'calottera',
    'calottiez',
    'calottons',
    'caloyères',
    'calquages',
    'calquâmes',
    'calquasse',
    'calquâtes',
    'calquerai',
    'calqueras',
    'calquerez',
    'calquions',
    'caltaient',
    'caltasses',
    'calterais',
    'calterait',
    'caltèrent',
    'calteriez',
    'calterons',
    'calteront',
    'calvaires',
    'calvilles',
    'calvities',
    'camaldule',
    'camarades',
    'camarilla',
    'cambiales',
    'cambistes',
    'cambrages',
    'cambrâmes',
    'cambrasse',
    'cambrâtes',
    'cambrerai',
    'cambreras',
    'cambrerez',
    'cambriens',
    'cambriola',
    'cambriole',
    'cambriolé',
    'cambrions',
    'cambrures',
    'cambusier',
    'cambutais',
    'cambutait',
    'cambutant',
    'cambutées',
    'cambutent',
    'cambutera',
    'cambutiez',
    'cambutons',
    'caméléons',
    'camelines',
    'camélines',
    'camelotai',
    'camelotas',
    'camelotât',
    'cameloter',
    'camelotes',
    'camelotez',
    'camembert',
    'cameraman',
    'cameramen',
    'camériers',
    'camériste',
    'camionnai',
    'camionnas',
    'camionnât',
    'camionnée',
    'camionner',
    'camionnes',
    'camionnés',
    'camionnez',
    'camisards',
    'camisoles',
    'camomille',
    'camouflai',
    'camouflas',
    'camouflât',
    'camouflée',
    'camoufler',
    'camoufles',
    'camouflés',
    'camouflet',
    'camouflez',
    'campagnes',
    'campagnol',
    'campaient',
    'campanile',
    'campanule',
    'campasses',
    'campêches',
    'campement',
    'camperais',
    'camperait',
    'campèrent',
    'camperiez',
    'camperons',
    'camperont',
    'campeuses',
    'camphrées',
    'camphrier',
    'canadiens',
    'canailles',
    'canalisai',
    'canalisas',
    'canalisât',
    'canalisée',
    'canaliser',
    'canalises',
    'canalisés',
    'canalisez',
    'cananéens',
    'canardais',
    'canardait',
    'canardant',
    'canardeau',
    'canardées',
    'canardent',
    'canardera',
    'canardiez',
    'canardons',
    'canassent',
    'canassiez',
    'canassons',
    'cancanais',
    'cancanait',
    'cancanant',
    'cancanent',
    'cancanera',
    'cancanier',
    'cancaniez',
    'cancanons',
    'cancéreux',
    'cancrelat',
    'candidate',
    'candidats',
    'candirais',
    'candirait',
    'candirent',
    'candiriez',
    'candirons',
    'candiront',
    'candisses',
    'candissez',
    'canéphore',
    'canerions',
    'canicules',
    'caniveaux',
    'cannaient',
    'cannasses',
    'cannelais',
    'cannelait',
    'cannelant',
    'cannelées',
    'cannelier',
    'canneliez',
    'cannelles',
    'cannelons',
    'cannelure',
    'cannerais',
    'cannerait',
    'cannèrent',
    'canneriez',
    'cannerons',
    'canneront',
    'canneuses',
    'cannibale',
    'cannisses',
    'canoéiste',
    'canoniale',
    'canoniaux',
    'canonicat',
    'canonique',
    'canonisai',
    'canonisas',
    'canonisât',
    'canonisée',
    'canoniser',
    'canonises',
    'canonisés',
    'canonisez',
    'canoniste',
    'canonnade',
    'canonnage',
    'canonnais',
    'canonnait',
    'canonnant',
    'canonnées',
    'canonnent',
    'canonnera',
    'canonnier',
    'canonniez',
    'canonnons',
    'canotages',
    'canotâmes',
    'canotasse',
    'canotâtes',
    'canoterai',
    'canoteras',
    'canoterez',
    'canoteurs',
    'canoteuse',
    'canotiers',
    'canotions',
    'cantabile',
    'cantaloup',
    'cantilène',
    'cantinier',
    'cantiques',
    'cantonade',
    'cantonale',
    'cantonaux',
    'cantonnai',
    'cantonnas',
    'cantonnât',
    'cantonnée',
    'cantonner',
    'cantonnes',
    'cantonnés',
    'cantonnez',
    'canulâmes',
    'canulasse',
    'canulâtes',
    'canulerai',
    'canuleras',
    'canulerez',
    'canulions',
    'canutuses',
    'capacités',
    'caparaçon',
    'capéaient',
    'capéasses',
    'capéerais',
    'capéerait',
    'capéèrent',
    'capéeriez',
    'capéerons',
    'capéeront',
    'capelages',
    'capelâmes',
    'capelasse',
    'capelâtes',
    'capelines',
    'capelions',
    'capellent',
    'capellera',
    'capésiens',
    'capétiens',
    'capeyâmes',
    'capeyasse',
    'capeyâtes',
    'capeyerai',
    'capeyeras',
    'capeyerez',
    'capeyions',
    'capistons',
    'capitaine',
    'capitales',
    'capiteuse',
    'capitoles',
    'capitolin',
    'capitonna',
    'capitonne',
    'capitonné',
    'capitouls',
    'capitulai',
    'capitulas',
    'capitulât',
    'capituler',
    'capitules',
    'capitulez',
    'caponnais',
    'caponnait',
    'caponnant',
    'caponnent',
    'caponnera',
    'caponniez',
    'caponnons',
    'capotages',
    'capotâmes',
    'capotasse',
    'capotâtes',
    'capoterai',
    'capoteras',
    'capoterez',
    'capotions',
    'capricant',
    'capriccio',
    'capselles',
    'capsulage',
    'capsulais',
    'capsulait',
    'capsulant',
    'capsulées',
    'capsulent',
    'capsulera',
    'capsuliez',
    'capsulons',
    'captaient',
    'captasses',
    'captateur',
    'captatifs',
    'captation',
    'captative',
    'capterais',
    'capterait',
    'captèrent',
    'capteriez',
    'capterons',
    'capteront',
    'captieuse',
    'captivais',
    'captivait',
    'captivant',
    'captivées',
    'captivent',
    'captivera',
    'captiviez',
    'captivité',
    'captivons',
    'capturais',
    'capturait',
    'capturant',
    'capturées',
    'capturent',
    'capturera',
    'capturiez',
    'capturons',
    'capuchons',
    'capucines',
    'caquaient',
    'caquasses',
    'caquelons',
    'caquerais',
    'caquerait',
    'caquèrent',
    'caqueriez',
    'caquerons',
    'caqueront',
    'caquetage',
    'caquetais',
    'caquetait',
    'caquetant',
    'caquetiez',
    'caquetons',
    'caquettes',
    'carabinée',
    'carabines',
    'carabinés',
    'caracolai',
    'caracolas',
    'caracolât',
    'caracoler',
    'caracoles',
    'caracolez',
    'caractère',
    'carambola',
    'carambole',
    'carambolé',
    'caramélée',
    'caramélés',
    'carapaces',
    'carapatai',
    'carapatas',
    'carapatât',
    'carapatée',
    'carapater',
    'carapates',
    'carapatés',
    'carapatez',
    'caravanes',
    'caravelle',
    'carbonari',
    'carbonaro',
    'carbonata',
    'carbonate',
    'carbonaté',
    'carbonées',
    'carbonisa',
    'carbonise',
    'carbonisé',
    'carbonyle',
    'carboxyle',
    'carburais',
    'carburait',
    'carburant',
    'carburées',
    'carburent',
    'carburera',
    'carburiez',
    'carburons',
    'carcailla',
    'carcaille',
    'carcaillé',
    'carcajous',
    'carcasses',
    'carcérale',
    'carcéraux',
    'carcinome',
    'cardaient',
    'cardamine',
    'cardamome',
    'cardasses',
    'carderais',
    'carderait',
    'cardèrent',
    'carderiez',
    'carderons',
    'carderont',
    'cardeuses',
    'cardiales',
    'cardiaque',
    'cardigans',
    'cardinale',
    'cardinaux',
    'cardioïde',
    'carénages',
    'carénâmes',
    'carénasse',
    'carénâtes',
    'carençais',
    'carençait',
    'carençant',
    'carencées',
    'carencent',
    'carencera',
    'carenciez',
    'carençons',
    'carénerai',
    'caréneras',
    'carénerez',
    'carénions',
    'carentiel',
    'caressais',
    'caressait',
    'caressant',
    'caressées',
    'caressent',
    'caressera',
    'caressiez',
    'caressons',
    'car-ferry',
    'cargaison',
    'carguâmes',
    'carguasse',
    'carguâtes',
    'carguerai',
    'cargueras',
    'carguerez',
    'carguions',
    'cariacous',
    'cariaient',
    'cariasses',
    'cariatide',
    'carierais',
    'carierait',
    'carièrent',
    'carieriez',
    'carierons',
    'carieront',
    'carieuses',
    'carillons',
    'caritatif',
    'carlingue',
    'carlismes',
    'carlistes',
    'carmaient',
    'carmasses',
    'carmeline',
    'carmélite',
    'carmerais',
    'carmerait',
    'carmèrent',
    'carmeriez',
    'carmerons',
    'carmeront',
    'carminais',
    'carminait',
    'carminant',
    'carminées',
    'carminent',
    'carminera',
    'carminiez',
    'carminons',
    'carnation',
    'carnavals',
    'carnifiai',
    'carnifias',
    'carnifiât',
    'carnifiée',
    'carnifier',
    'carnifies',
    'carnifiés',
    'carnifiez',
    'carnivore',
    'carnotset',
    'caronades',
    'caroncule',
    'carotènes',
    'carotides',
    'carottage',
    'carottais',
    'carottait',
    'carottant',
    'carottées',
    'carottent',
    'carottera',
    'carotteur',
    'carottier',
    'carottiez',
    'carottons',
    'caroubier',
    'carpelles',
    'carpettes',
    'carpienne',
    'carpillon',
    'carraient',
    'carrasses',
    'carrefour',
    'carrelage',
    'carrelais',
    'carrelait',
    'carrelant',
    'carrelées',
    'carrelets',
    'carreleur',
    'carreliez',
    'carrelles',
    'carrelons',
    'carrément',
    'carrerais',
    'carrerait',
    'carrèrent',
    'carreriez',
    'carrerons',
    'carreront',
    'carrières',
    'carrioles',
    'carroient',
    'carroiera',
    'carrossai',
    'carrossas',
    'carrossât',
    'carrossée',
    'carrosser',
    'carrosses',
    'carrossés',
    'carrossez',
    'carrousel',
    'carroyage',
    'carroyais',
    'carroyait',
    'carroyant',
    'carroyées',
    'carroyiez',
    'carroyons',
    'cartables',
    'cartésien',
    'cartilage',
    'cartonnai',
    'cartonnas',
    'cartonnât',
    'cartonnée',
    'cartonner',
    'cartonnes',
    'cartonnés',
    'cartonnez',
    'cartouche',
    'caryopses',
    'casanière',
    'casaniers',
    'casaquins',
    'casassent',
    'casassiez',
    'cascadais',
    'cascadait',
    'cascadant',
    'cascadent',
    'cascadera',
    'cascadeur',
    'cascadiez',
    'cascadons',
    'caséfiais',
    'caséfiait',
    'caséfiant',
    'caséfiées',
    'caséfient',
    'caséfiera',
    'caséfiiez',
    'caséfions',
    'casematai',
    'casematas',
    'casematât',
    'casematée',
    'casemater',
    'casemates',
    'casematés',
    'casematez',
    'caserions',
    'casernais',
    'casernait',
    'casernant',
    'casernées',
    'casernent',
    'casernera',
    'casernier',
    'caserniez',
    'casernons',
    'cash-flow',
    'cashmeres',
    'casquâmes',
    'casquasse',
    'casquâtes',
    'casquerai',
    'casqueras',
    'casquerez',
    'casquette',
    'casquions',
    'cassables',
    'cassaient',
    'cassantes',
    'cassasses',
    'cassation',
    'casse-cou',
    'casse-cul',
    'cassement',
    'casserais',
    'casserait',
    'cassèrent',
    'casseriez',
    'casserole',
    'casserons',
    'casseront',
    'cassetins',
    'cassettes',
    'casseuses',
    'cassonade',
    'cassoulet',
    'castagnai',
    'castagnas',
    'castagnât',
    'castagnée',
    'castagner',
    'castagnes',
    'castagnés',
    'castagnez',
    'castillan',
    'castoréum',
    'castrâmes',
    'castrasse',
    'castrâtes',
    'castrerai',
    'castreras',
    'castrerez',
    'castrions',
    'casuelles',
    'casuistes',
    'catalanes',
    'catalogne',
    'catalogua',
    'catalogue',
    'catalogué',
    'catalysai',
    'catalysas',
    'catalysât',
    'catalysée',
    'catalyser',
    'catalyses',
    'catalysés',
    'catalysez',
    'catamaran',
    'cataphote',
    'catapulta',
    'catapulte',
    'catapulté',
    'cataracte',
    'catarrhal',
    'catarrhes',
    'catchâmes',
    'catchasse',
    'catchâtes',
    'catcherai',
    'catcheras',
    'catcherez',
    'catcheurs',
    'catcheuse',
    'catchions',
    'catéchisa',
    'catéchise',
    'catéchisé',
    'catégorie',
    'caténaire',
    'cathéters',
    'catissage',
    'cattleyas',
    'caucasien',
    'cauchemar',
    'caudillos',
    'caudrette',
    'causaient',
    'causalité',
    'causantes',
    'causasses',
    'causatifs',
    'causative',
    'causerais',
    'causerait',
    'causèrent',
    'causeries',
    'causeriez',
    'causerons',
    'causeront',
    'causettes',
    'causeuses',
    'caustique',
    'cauteleux',
    'cautérisa',
    'cautérise',
    'cautérisé',
    'cautionna',
    'cautionne',
    'cautionné',
    'cavaillon',
    'cavalâmes',
    'cavalasse',
    'cavalâtes',
    'cavalcada',
    'cavalcade',
    'cavalcadé',
    'cavalerai',
    'cavaleras',
    'cavalerez',
    'cavalerie',
    'cavaleurs',
    'cavaleuse',
    'cavalière',
    'cavaliers',
    'cavalions',
    'cavassent',
    'cavassiez',
    'cavatines',
    'caverions',
    'caverneux',
    'caviardai',
    'caviardas',
    'caviardât',
    'caviardée',
    'caviarder',
    'caviardes',
    'caviardés',
    'caviardez',
    'cavicorne',
    'cédassent',
    'cédassiez',
    'céderions',
    'cédétiste',
    'cédratier',
    'cédulaire',
    'cégésimal',
    'cégétiste',
    'ceignîmes',
    'ceignions',
    'ceignisse',
    'ceignîtes',
    'ceindrais',
    'ceindrait',
    'ceindriez',
    'ceindrons',
    'ceindront',
    'ceinturai',
    'ceinturas',
    'ceinturât',
    'ceinturée',
    'ceinturer',
    'ceintures',
    'ceinturés',
    'ceinturez',
    'ceinturon',
    'celassent',
    'celassiez',
    'célébrais',
    'célébrait',
    'célébrant',
    'célébrées',
    'célèbrent',
    'célébrera',
    'celebrets',
    'célébriez',
    'célébrité',
    'célébrons',
    'cèlerions',
    'célestins',
    'cellérier',
    'celles-ci',
    'celles-là',
    'cellulars',
    'cellulite',
    'celluloïd',
    'cellulose',
    'celtiques',
    'cémentais',
    'cémentait',
    'cémentant',
    'cémentées',
    'cémentent',
    'cémentera',
    'cémentiez',
    'cémentons',
    'cendrâmes',
    'cendrasse',
    'cendrâtes',
    'cendrerai',
    'cendreras',
    'cendrerez',
    'cendreuse',
    'cendriers',
    'cendrions',
    'cénobites',
    'cénotaphe',
    'censément',
    'censières',
    'censorats',
    'censorial',
    'censurais',
    'censurait',
    'censurant',
    'censurées',
    'censurent',
    'censurera',
    'censuriez',
    'censurons',
    'centaines',
    'centaurée',
    'centaures',
    'centenier',
    'centennal',
    'centiares',
    'centibars',
    'centièmes',
    'centrages',
    'centrales',
    'centrâmes',
    'centrasse',
    'centrâtes',
    'centrerai',
    'centreras',
    'centrerez',
    'centreurs',
    'centrions',
    'centrisme',
    'centriste',
    'centuplai',
    'centuplas',
    'centuplât',
    'centuplée',
    'centupler',
    'centuples',
    'centuplés',
    'centuplez',
    'centuries',
    'centurion',
    'cependant',
    'céphalées',
    'céphéides',
    'céramique',
    'céramiste',
    'cerclages',
    'cerclâmes',
    'cerclasse',
    'cerclâtes',
    'cerclerai',
    'cercleras',
    'cerclerez',
    'cerclions',
    'cercueils',
    'céréalier',
    'cérébrale',
    'cérébraux',
    'cérémonie',
    'cerfeuils',
    'cerisaies',
    'cerisette',
    'cerisiers',
    'cernaient',
    'cernasses',
    'cernerais',
    'cernerait',
    'cernèrent',
    'cerneriez',
    'cernerons',
    'cerneront',
    'certaines',
    'certifiai',
    'certifias',
    'certifiât',
    'certifiée',
    'certifier',
    'certifies',
    'certifiés',
    'certifiez',
    'certitude',
    'céruléens',
    'cervelets',
    'cervelles',
    'cervicale',
    'cervicaux',
    'cervoises',
    'césariens',
    'césarisme',
    'cessaient',
    'cessantes',
    'cessasses',
    'cessation',
    'cesserais',
    'cesserait',
    'cessèrent',
    'cesseriez',
    'cesserons',
    'cesseront',
    'cessibles',
    'chabichou',
    'chablâmes',
    'chablasse',
    'chablâtes',
    'chablerai',
    'chableras',
    'chablerez',
    'chablions',
    'chabraque',
    'chaconnes',
    'chadburns',
    'chafouine',
    'chafouins',
    'chagrinai',
    'chagrinas',
    'chagrinât',
    'chagrinée',
    'chagriner',
    'chagrines',
    'chagrinés',
    'chagrinez',
    'chahutais',
    'chahutait',
    'chahutant',
    'chahutées',
    'chahutent',
    'chahutera',
    'chahuteur',
    'chahutiez',
    'chahutons',
    'chaînages',
    'chaînâmes',
    'chaînasse',
    'chaînâtes',
    'chaînerai',
    'chaîneras',
    'chaînerez',
    'chaînette',
    'chaîneurs',
    'chaîneuse',
    'chaîniers',
    'chaînions',
    'chaîniste',
    'chaisière',
    'chaisiers',
    'chalazion',
    'chaldéens',
    'challenge',
    'challengé',
    'chaloupai',
    'chaloupas',
    'chaloupât',
    'chaloupée',
    'chalouper',
    'chaloupes',
    'chaloupés',
    'chaloupez',
    'chalumeau',
    'chalutage',
    'chalutier',
    'chamailla',
    'chamaille',
    'chamaillé',
    'chamarrai',
    'chamarras',
    'chamarrât',
    'chamarrée',
    'chamarrer',
    'chamarres',
    'chamarrés',
    'chamarrez',
    'chambarda',
    'chambarde',
    'chambardé',
    'chambards',
    'chamboula',
    'chamboule',
    'chamboulé',
    'chambrais',
    'chambrait',
    'chambrant',
    'chambrées',
    'chambrent',
    'chambrera',
    'chambrier',
    'chambriez',
    'chambrons',
    'chamelier',
    'chamelles',
    'chamérops',
    'chamoisai',
    'chamoisas',
    'chamoisât',
    'chamoisée',
    'chamoiser',
    'chamoises',
    'chamoisés',
    'chamoisez',
    'champagne',
    'champarts',
    'champêtre',
    'champions',
    'champleva',
    'champlevé',
    'champlève',
    'chançarde',
    'chançards',
    'chancelai',
    'chancelas',
    'chancelât',
    'chanceler',
    'chancelez',
    'chancelle',
    'chanceuse',
    'chancîmes',
    'chancirai',
    'chanciras',
    'chancirez',
    'chancisse',
    'chancîtes',
    'chandails',
    'chandelle',
    'chanfrein',
    'changeais',
    'changeait',
    'changeant',
    'changeons',
    'changerai',
    'changeras',
    'changerez',
    'changeurs',
    'changions',
    'chanlatte',
    'chanoines',
    'chansonna',
    'chansonne',
    'chansonné',
    'chantages',
    'chantâmes',
    'chantante',
    'chantants',
    'chantasse',
    'chantâtes',
    'chanteaux',
    'chanterai',
    'chanteras',
    'chanterez',
    'chanteurs',
    'chanteuse',
    'chantiers',
    'chantilly',
    'chantions',
    'chantonna',
    'chantonne',
    'chantonné',
    'chantoung',
    'chanvrier',
    'chaotique',
    'chapardai',
    'chapardas',
    'chapardât',
    'chapardée',
    'chaparder',
    'chapardes',
    'chapardés',
    'chapardez',
    'chapeauta',
    'chapeaute',
    'chapeauté',
    'chapelain',
    'chapelais',
    'chapelait',
    'chapelant',
    'chapelées',
    'chapelets',
    'chapelier',
    'chapeliez',
    'chapelles',
    'chapelons',
    'chapelure',
    'chaperons',
    'chapiteau',
    'chapitrai',
    'chapitras',
    'chapitrât',
    'chapitrée',
    'chapitrer',
    'chapitres',
    'chapitrés',
    'chapitrez',
    'chaponnai',
    'chaponnas',
    'chaponnât',
    'chaponnée',
    'chaponner',
    'chaponnes',
    'chaponnés',
    'chaponnez',
    'charabias',
    'charançon',
    'charbonna',
    'charbonne',
    'charbonné',
    'charcutai',
    'charcutas',
    'charcutât',
    'charcutée',
    'charcuter',
    'charcutes',
    'charcutés',
    'charcutez',
    'chargeais',
    'chargeait',
    'chargeant',
    'chargeons',
    'chargerai',
    'chargeras',
    'chargerez',
    'chargeurs',
    'chargions',
    'charismes',
    'charivari',
    'charlatan',
    'charlotte',
    'charmâmes',
    'charmante',
    'charmants',
    'charmasse',
    'charmâtes',
    'charmerai',
    'charmeras',
    'charmerez',
    'charmeurs',
    'charmeuse',
    'charmille',
    'charmions',
    'charnelle',
    'charnière',
    'charniers',
    'charognes',
    'charpenta',
    'charpente',
    'charpenté',
    'charretée',
    'charretin',
    'charreton',
    'charrette',
    'charriage',
    'charriais',
    'charriait',
    'charriant',
    'charriées',
    'charrient',
    'charriera',
    'charriiez',
    'charrions',
    'charroies',
    'charroyai',
    'charroyas',
    'charroyât',
    'charroyée',
    'charroyer',
    'charroyés',
    'charroyez',
    'charruage',
    'chartisme',
    'chartiste',
    'chartreux',
    'chartrier',
    'chassâmes',
    'chassasse',
    'chassâtes',
    'chasselas',
    'chassepot',
    'chasserai',
    'chasseras',
    'chasserez',
    'chasseurs',
    'chasseuse',
    'chassieux',
    'chassions',
    'chastetés',
    'chasubles',
    'châtaigne',
    'châtelain',
    'châtiâmes',
    'châtiasse',
    'châtiâtes',
    'châtierai',
    'châtieras',
    'chatières',
    'châtierez',
    'châtiions',
    'châtiment',
    'chatoient',
    'chatoiera',
    'chatonnai',
    'chatonnas',
    'chatonnât',
    'chatonner',
    'chatonnes',
    'chatonnez',
    'chatoyais',
    'chatoyait',
    'chatoyant',
    'chatoyiez',
    'chatoyons',
    'châtrâmes',
    'châtrasse',
    'châtrâtes',
    'châtrerai',
    'châtreras',
    'châtrerez',
    'châtrions',
    'chatterie',
    'chaudeaux',
    'chaudière',
    'chaudrons',
    'chauffage',
    'chauffais',
    'chauffait',
    'chauffant',
    'chauffard',
    'chauffées',
    'chauffent',
    'chauffera',
    'chauffeur',
    'chauffiez',
    'chauffons',
    'chaufours',
    'chaulages',
    'chaulâmes',
    'chaulasse',
    'chaulâtes',
    'chaulerai',
    'chauleras',
    'chaulerez',
    'chauleuse',
    'chaulions',
    'chaumâmes',
    'chaumasse',
    'chaumâtes',
    'chaumerai',
    'chaumeras',
    'chaumerez',
    'chaumière',
    'chaumines',
    'chaumions',
    'chaussais',
    'chaussait',
    'chaussant',
    'chaussées',
    'chaussent',
    'chaussera',
    'chausseur',
    'chaussiez',
    'chaussons',
    'chaussure',
    'chauvîmes',
    'chauvines',
    'chauvirai',
    'chauviras',
    'chauvirez',
    'chauvisse',
    'chauvîtes',
    'chavirais',
    'chavirait',
    'chavirant',
    'chavirées',
    'chavirent',
    'chavirera',
    'chaviriez',
    'chavirons',
    'cheddites',
    'chefferie',
    'chef-lieu',
    'cheftaine',
    'chélicère',
    'chelingua',
    'chelingue',
    'chelingué',
    'chelléens',
    'cheminais',
    'cheminait',
    'cheminant',
    'cheminées',
    'cheminent',
    'cheminera',
    'cheminiez',
    'cheminons',
    'cheminots',
    'chemisage',
    'chemisais',
    'chemisait',
    'chemisant',
    'chemisées',
    'chemisent',
    'chemisera',
    'chemisier',
    'chemisiez',
    'chemisons',
    'chenapans',
    'chenillée',
    'chenilles',
    'chenillés',
    'chénopode',
    'chéquiers',
    'chéraient',
    'chérasses',
    'cherchais',
    'cherchait',
    'cherchant',
    'cherchées',
    'cherchent',
    'cherchera',
    'chercheur',
    'cherchiez',
    'cherchons',
    'chèrement',
    'chérerais',
    'chérerait',
    'chérèrent',
    'chéreriez',
    'chérerons',
    'chéreront',
    'chérifien',
    'chérirais',
    'chérirait',
    'chérirent',
    'chéririez',
    'chérirons',
    'chériront',
    'chérisses',
    'chérissez',
    'cherrâmes',
    'cherrasse',
    'cherrâtes',
    'cherrerai',
    'cherreras',
    'cherrerez',
    'cherrions',
    'chérubins',
    'chevalais',
    'chevalait',
    'chevalant',
    'chevalées',
    'chevalent',
    'chevalera',
    'chevalets',
    'chevalier',
    'chevaliez',
    'chevaline',
    'chevalins',
    'chevalons',
    'chevaucha',
    'chevauche',
    'chevauché',
    'chevêches',
    'chevelues',
    'chevelure',
    'chevêtres',
    'chevillai',
    'chevillas',
    'chevillât',
    'chevillée',
    'cheviller',
    'chevilles',
    'chevillés',
    'chevillez',
    'cheviotte',
    'chevreaux',
    'chevretai',
    'chevretas',
    'chevretât',
    'chevreter',
    'chevretez',
    'chevrette',
    'chevreuil',
    'chevrière',
    'chevriers',
    'chevronna',
    'chevronne',
    'chevronné',
    'chevrotai',
    'chevrotas',
    'chevrotât',
    'chevroter',
    'chevrotes',
    'chevrotez',
    'chevrotin',
    'chiadâmes',
    'chiadasse',
    'chiadâtes',
    'chiaderai',
    'chiaderas',
    'chiaderez',
    'chiadions',
    'chialâmes',
    'chialasse',
    'chialâtes',
    'chialerai',
    'chialeras',
    'chialerez',
    'chialions',
    'chiassent',
    'chiassiez',
    'chibouque',
    'chicanais',
    'chicanait',
    'chicanant',
    'chicanées',
    'chicanent',
    'chicanera',
    'chicaneur',
    'chicanier',
    'chicaniez',
    'chicanons',
    'chicorées',
    'chicotais',
    'chicotait',
    'chicotant',
    'chicotent',
    'chicotera',
    'chicotiez',
    'chicotins',
    'chicotons',
    'chiendent',
    'chienlits',
    'chiennais',
    'chiennait',
    'chiennant',
    'chiennent',
    'chiennera',
    'chienniez',
    'chiennons',
    'chierions',
    'chiffonna',
    'chiffonne',
    'chiffonné',
    'chiffrage',
    'chiffrais',
    'chiffrait',
    'chiffrant',
    'chiffrées',
    'chiffrent',
    'chiffrera',
    'chiffreur',
    'chiffrier',
    'chiffriez',
    'chiffrons',
    'chignoles',
    'chilienne',
    'chimiques',
    'chimismes',
    'chimistes',
    'chimpanzé',
    'chinaient',
    'chinasses',
    'chinerais',
    'chinerait',
    'chinèrent',
    'chineriez',
    'chinerons',
    'chineront',
    'chineuses',
    'chinoisai',
    'chinoisas',
    'chinoisât',
    'chinoiser',
    'chinoises',
    'chinoisez',
    'chiourmes',
    'chipaient',
    'chipasses',
    'chiperais',
    'chiperait',
    'chipèrent',
    'chiperiez',
    'chiperons',
    'chiperont',
    'chipolata',
    'chipotage',
    'chipotais',
    'chipotait',
    'chipotant',
    'chipotées',
    'chipotent',
    'chipotera',
    'chipoteur',
    'chipotiez',
    'chipotons',
    'chiquâmes',
    'chiquasse',
    'chiquâtes',
    'chiquerai',
    'chiqueras',
    'chiquerez',
    'chiqueurs',
    'chiqueuse',
    'chiquions',
    'chirurgie',
    'chisteras',
    'chitineux',
    'chlamydes',
    'chlinguai',
    'chlinguas',
    'chlinguât',
    'chlinguée',
    'chlinguer',
    'chlingues',
    'chlingués',
    'chlinguez',
    'chlorâmes',
    'chlorasse',
    'chlorates',
    'chlorâtes',
    'chlorerai',
    'chloreras',
    'chlorerez',
    'chlorions',
    'chlorique',
    'chloroses',
    'chlorurai',
    'chloruras',
    'chlorurât',
    'chlorurée',
    'chlorurer',
    'chlorures',
    'chlorurés',
    'chlorurez',
    'chochotte',
    'chocolaté',
    'chocolats',
    'chocottes',
    'choierais',
    'choierait',
    'choieriez',
    'choierons',
    'choieront',
    'choisîmes',
    'choisirai',
    'choisiras',
    'choisirez',
    'choisisse',
    'choisîtes',
    'cholémies',
    'cholérine',
    'choluries',
    'chômables',
    'chômaient',
    'chômasses',
    'chômerais',
    'chômerait',
    'chômèrent',
    'chômeriez',
    'chômerons',
    'chômeront',
    'chômeuses',
    'chopaient',
    'chopasses',
    'choperais',
    'choperait',
    'chopèrent',
    'choperiez',
    'choperons',
    'choperont',
    'chopinais',
    'chopinait',
    'chopinant',
    'chopinent',
    'chopinera',
    'chopiniez',
    'chopinons',
    'choppâmes',
    'choppasse',
    'choppâtes',
    'chopperai',
    'chopperas',
    'chopperez',
    'choppions',
    'choquâmes',
    'choquante',
    'choquants',
    'choquasse',
    'choquâtes',
    'choquerai',
    'choqueras',
    'choquerez',
    'choquions',
    'choréique',
    'choreutes',
    'choristes',
    'choroïdes',
    'chosifiai',
    'chosifias',
    'chosifiât',
    'chosifiée',
    'chosifier',
    'chosifies',
    'chosifiés',
    'chosifiez',
    'chouchous',
    'chouettes',
    'chouleurs',
    'chouravai',
    'chouravas',
    'chouravât',
    'chou-rave',
    'chouravée',
    'chouraver',
    'chouraves',
    'chouravés',
    'chouravez',
    'chourinai',
    'chourinas',
    'chourinât',
    'chourinée',
    'chouriner',
    'chourines',
    'chourinés',
    'chourinez',
    'chow-chow',
    'choyaient',
    'choyasses',
    'choyèrent',
    'chrémeaux',
    'chrétiens',
    'chromages',
    'chromâmes',
    'chromasse',
    'chromates',
    'chromâtes',
    'chromerai',
    'chromeras',
    'chromerez',
    'chromions',
    'chromique',
    'chromiste',
    'chronaxie',
    'chroniqua',
    'chronique',
    'chroniqué',
    'chroumais',
    'chroumait',
    'chroumant',
    'chroumées',
    'chroument',
    'chroumiez',
    'chroumons',
    'chthonien',
    'chuchotai',
    'chuchotas',
    'chuchotât',
    'chuchotée',
    'chuchoter',
    'chuchotes',
    'chuchotés',
    'chuchotez',
    'chuchotis',
    'chuintais',
    'chuintait',
    'chuintant',
    'chuintent',
    'chuintera',
    'chuintiez',
    'chuintons',
    'chutaient',
    'chutasses',
    'chuterais',
    'chuterait',
    'chutèrent',
    'chuteriez',
    'chuterons',
    'chuteront',
    'chylifère',
    'ciboriums',
    'ciboulots',
    'cicatrice',
    'cicatrisa',
    'cicatrise',
    'cicatrisé',
    'cicérones',
    'cicindèle',
    'ci-contre',
    'cicutines',
    'ci-dessus',
    'ci-devant',
    'cidreries',
    'cigarette',
    'cigarière',
    'cigarillo',
    'cigogneau',
    'ci-inclus',
    'ci-jointe',
    'ci-joints',
    'ciliaires',
    'cillaient',
    'cillasses',
    'cillement',
    'cillerais',
    'cillerait',
    'cillèrent',
    'cilleriez',
    'cillerons',
    'cilleront',
    'cimentais',
    'cimentait',
    'cimentant',
    'cimentées',
    'cimentent',
    'cimentera',
    'cimentier',
    'cimentiez',
    'cimentons',
    'cimeterre',
    'cimetière',
    'cinéastes',
    'ciné-club',
    'cinéphile',
    'cinéraire',
    'cinérites',
    'ciné-shop',
    'cinétique',
    'cingalais',
    'cinglâmes',
    'cinglante',
    'cinglants',
    'cinglasse',
    'cinglâtes',
    'cinglerai',
    'cingleras',
    'cinglerez',
    'cinglions',
    'cinnamome',
    'cinquante',
    'cinquième',
    'cintrages',
    'cintrâmes',
    'cintrasse',
    'cintrâtes',
    'cintrerai',
    'cintreras',
    'cintrerez',
    'cintrions',
    'cirassent',
    'cirassiez',
    'circadien',
    'circoncis',
    'circoncit',
    'circoncît',
    'circulais',
    'circulait',
    'circulant',
    'circulent',
    'circulera',
    'circuliez',
    'circulons',
    'cirerions',
    'cirrhoses',
    'cisaillai',
    'cisaillas',
    'cisaillât',
    'cisaillée',
    'cisailler',
    'cisailles',
    'cisaillés',
    'cisaillez',
    'cisalpins',
    'ciselâmes',
    'ciselasse',
    'ciselâtes',
    'cisélerai',
    'ciséleras',
    'cisélerez',
    'ciseleurs',
    'ciselions',
    'ciselures',
    'citadelle',
    'citadines',
    'citassent',
    'citassiez',
    'citations',
    'citatteur',
    'citerions',
    'citoyenne',
    'citriques',
    'citronnée',
    'citronnés',
    'civilisai',
    'civilisas',
    'civilisât',
    'civilisée',
    'civiliser',
    'civilises',
    'civilisés',
    'civilisez',
    'civiliste',
    'civilités',
    'clabaudai',
    'clabaudas',
    'clabaudât',
    'clabauder',
    'clabaudes',
    'clabaudez',
    'clabotage',
    'clabotais',
    'clabotait',
    'clabotant',
    'clabotées',
    'clabotent',
    'clabotera',
    'clabotiez',
    'clabotons',
    'clafoutis',
    'clairance',
    'clairette',
    'clairière',
    'claironna',
    'claironne',
    'claironné',
    'clairsemé',
    'clamaient',
    'clamasses',
    'clamerais',
    'clamerait',
    'clamèrent',
    'clameriez',
    'clamerons',
    'clameront',
    'clampâmes',
    'clampasse',
    'clampâtes',
    'clamperai',
    'clamperas',
    'clamperez',
    'clampions',
    'clampsais',
    'clampsait',
    'clampsant',
    'clampsées',
    'clampsent',
    'clampsera',
    'clampsiez',
    'clampsons',
    'clamsâmes',
    'clamsasse',
    'clamsâtes',
    'clamserai',
    'clamseras',
    'clamserez',
    'clamsions',
    'claniques',
    'clapaient',
    'clapasses',
    'claperais',
    'claperait',
    'clapèrent',
    'claperiez',
    'claperons',
    'claperont',
    'clapirais',
    'clapirait',
    'clapirent',
    'clapiriez',
    'clapirons',
    'clapiront',
    'clapisses',
    'clapissez',
    'clapotage',
    'clapotais',
    'clapotait',
    'clapotant',
    'clapotent',
    'clapotera',
    'clapotiez',
    'clapotons',
    'clappâmes',
    'clappasse',
    'clappâtes',
    'clapperai',
    'clapperas',
    'clapperez',
    'clappions',
    'clapsâmes',
    'clapsasse',
    'clapsâtes',
    'clapserai',
    'clapseras',
    'clapserez',
    'clapsions',
    'claquages',
    'claquâmes',
    'claquante',
    'claquants',
    'claquasse',
    'claquâtes',
    'claquerai',
    'claqueras',
    'claquerez',
    'claquetai',
    'claquetas',
    'claquetât',
    'claqueter',
    'claquetez',
    'claquette',
    'claquions',
    'claquoirs',
    'clarifiai',
    'clarifias',
    'clarifiât',
    'clarifiée',
    'clarifier',
    'clarifies',
    'clarifiés',
    'clarifiez',
    'clarisses',
    'classable',
    'classâmes',
    'classasse',
    'classâtes',
    'classerai',
    'classeras',
    'classerez',
    'classeurs',
    'classifia',
    'classifie',
    'classifié',
    'classions',
    'classique',
    'clastique',
    'claudiqua',
    'claudique',
    'claudiqué',
    'claustrai',
    'claustral',
    'claustras',
    'claustrât',
    'claustrée',
    'claustrer',
    'claustres',
    'claustrés',
    'claustrez',
    'clausules',
    'clavaient',
    'clavaires',
    'clavasses',
    'clavecins',
    'clavelées',
    'claverais',
    'claverait',
    'clavèrent',
    'claveriez',
    'claverons',
    'claveront',
    'clavetage',
    'clavetais',
    'clavetait',
    'clavetant',
    'clavetées',
    'clavetiez',
    'clavetons',
    'clavettai',
    'clavettas',
    'clavettât',
    'clavettée',
    'clavetter',
    'clavettes',
    'clavettés',
    'clavettez',
    'clavicule',
    'clavistes',
    'clayettes',
    'claymores',
    'clayonnai',
    'clayonnas',
    'clayonnât',
    'clayonnée',
    'clayonner',
    'clayonnes',
    'clayonnés',
    'clayonnez',
    'clearings',
    'clématite',
    'clémences',
    'clémentes',
    'clepsydre',
    'clergyman',
    'clergymen',
    'cléricale',
    'cléricaux',
    'clichages',
    'clichâmes',
    'clichasse',
    'clichâtes',
    'clicherai',
    'clicheras',
    'clicherez',
    'clicherie',
    'clicheurs',
    'clichions',
    'clientèle',
    'clignâmes',
    'clignasse',
    'clignâtes',
    'clignerai',
    'cligneras',
    'clignerez',
    'clignions',
    'clignotai',
    'clignotas',
    'clignotât',
    'clignoter',
    'clignotes',
    'clignotez',
    'climatère',
    'climatisa',
    'climatise',
    'climatisé',
    'clinicats',
    'clinicien',
    'cliniques',
    'clinquant',
    'cliquetai',
    'cliquetas',
    'cliquetât',
    'cliqueter',
    'cliquetez',
    'cliquetis',
    'cliquette',
    'clissâmes',
    'clissasse',
    'clissâtes',
    'clisserai',
    'clisseras',
    'clisserez',
    'clissions',
    'clivables',
    'clivaient',
    'clivasses',
    'cliverais',
    'cliverait',
    'clivèrent',
    'cliveriez',
    'cliverons',
    'cliveront',
    'clochâmes',
    'clocharde',
    'clochards',
    'clochasse',
    'clochâtes',
    'clocherai',
    'clocheras',
    'clocherez',
    'clocheton',
    'clochette',
    'clochions',
    'cloisonna',
    'cloisonne',
    'cloisonné',
    'cloîtrais',
    'cloîtrait',
    'cloîtrant',
    'cloîtrées',
    'cloîtrent',
    'cloîtrera',
    'cloîtriez',
    'cloîtrons',
    'clopinais',
    'clopinait',
    'clopinant',
    'clopinent',
    'clopinera',
    'clopiniez',
    'clopinons',
    'cloportes',
    'cloquâmes',
    'cloquasse',
    'cloquâtes',
    'cloquerai',
    'cloqueras',
    'cloquerez',
    'cloquions',
    'cloraient',
    'closeries',
    'clôturais',
    'clôturait',
    'clôturant',
    'clôturées',
    'clôturent',
    'clôturera',
    'clôturiez',
    'clôturons',
    'clouaient',
    'clouasses',
    'clouerais',
    'clouerait',
    'clouèrent',
    'cloueriez',
    'clouerons',
    'cloueront',
    'cloutages',
    'cloutâmes',
    'cloutards',
    'cloutasse',
    'cloutâtes',
    'clouterai',
    'clouteras',
    'clouterez',
    'clouterie',
    'cloutiers',
    'cloutions',
    'clovisses',
    'clownerie',
    'clubistes',
    'clunisien',
    'clystères',
    'coaccusée',
    'coaccusés',
    'coagulais',
    'coagulait',
    'coagulant',
    'coagulées',
    'coagulent',
    'coagulera',
    'coaguliez',
    'coagulons',
    'coagulums',
    'coalisais',
    'coalisait',
    'coalisant',
    'coalisées',
    'coalisent',
    'coalisera',
    'coalisiez',
    'coalisons',
    'coalition',
    'coassâmes',
    'coassasse',
    'coassâtes',
    'coasserai',
    'coasseras',
    'coasserez',
    'coassions',
    'coassocié',
    'coauteurs',
    'coaxiales',
    'cocardier',
    'coccidies',
    'coccygien',
    'cochaient',
    'côchaient',
    'cochasses',
    'côchasses',
    'cocherais',
    'côcherais',
    'cocherait',
    'côcherait',
    'cochèrent',
    'côchèrent',
    'cocheriez',
    'côcheriez',
    'cocherons',
    'côcherons',
    'cocheront',
    'côcheront',
    'cochonnai',
    'cochonnas',
    'cochonnât',
    'cochonnée',
    'cochonner',
    'cochonnes',
    'cochonnés',
    'cochonnet',
    'cochonnez',
    'cocktails',
    'cocoricos',
    'cocotiers',
    'cocufiais',
    'cocufiait',
    'cocufiant',
    'cocufiées',
    'cocufient',
    'cocufiera',
    'cocufiiez',
    'cocufions',
    'codassent',
    'codassiez',
    'coderions',
    'codétenue',
    'codétenus',
    'codicille',
    'codifiais',
    'codifiait',
    'codifiant',
    'codifiées',
    'codifient',
    'codifiera',
    'codifiiez',
    'codifions',
    'coéditeur',
    'coédition',
    'coeliaque',
    'coenzymes',
    'coercible',
    'coercitif',
    'coexistai',
    'coexistas',
    'coexistât',
    'coexister',
    'coexistes',
    'coexistez',
    'coffrages',
    'coffrâmes',
    'coffrasse',
    'coffrâtes',
    'coffrerai',
    'coffreras',
    'coffrerez',
    'coffrions',
    'cogérance',
    'cogérante',
    'cogérants',
    'cogestion',
    'cogitâmes',
    'cogitante',
    'cogitants',
    'cogitasse',
    'cogitâtes',
    'cogiterai',
    'cogiteras',
    'cogiterez',
    'cogitions',
    'cognaient',
    'cognasses',
    'cognation',
    'cognement',
    'cognerais',
    'cognerait',
    'cognèrent',
    'cogneriez',
    'cognerons',
    'cogneront',
    'cognitifs',
    'cognitive',
    'cohabitai',
    'cohabitas',
    'cohabitât',
    'cohabiter',
    'cohabites',
    'cohabitez',
    'cohérence',
    'cohérente',
    'cohérents',
    'cohéreurs',
    'cohéritai',
    'cohéritas',
    'cohéritât',
    'cohériter',
    'cohérites',
    'cohéritez',
    'cohésions',
    'cohésives',
    'coiffâmes',
    'coiffante',
    'coiffants',
    'coiffasse',
    'coiffâtes',
    'coifferai',
    'coifferas',
    'coifferez',
    'coiffeurs',
    'coiffeuse',
    'coiffions',
    'coiffures',
    'coinçâmes',
    'coinçasse',
    'coinçâtes',
    'coincerai',
    'coinceras',
    'coincerez',
    'coïncidai',
    'coïncidas',
    'coïncidât',
    'coïncider',
    'coïncides',
    'coïncidez',
    'coincions',
    'coin-coin',
    'coïnculpé',
    'coïtaient',
    'coïtasses',
    'coïterais',
    'coïterait',
    'coïtèrent',
    'coïteriez',
    'coïterons',
    'coïteront',
    'cokéfiais',
    'cokéfiait',
    'cokéfiant',
    'cokéfiées',
    'cokéfient',
    'cokéfiera',
    'cokéfiiez',
    'cokéfions',
    'colatures',
    'colchique',
    'colcotars',
    'coléreuse',
    'colérique',
    'colimaçon',
    'colineaux',
    'colistier',
    'collabora',
    'collabore',
    'collaboré',
    'collagène',
    'collaient',
    'collantes',
    'collapsus',
    'collargol',
    'collasses',
    'collateur',
    'collation',
    'collectai',
    'collectas',
    'collectât',
    'collectée',
    'collecter',
    'collectes',
    'collectés',
    'collectez',
    'collectif',
    'collégial',
    'collégien',
    'collègues',
    'collerais',
    'collerait',
    'collèrent',
    'colleriez',
    'collerons',
    'colleront',
    'colletais',
    'colletait',
    'colletant',
    'colletées',
    'colleteur',
    'colletiez',
    'colletons',
    'collettes',
    'colleuses',
    'colligeai',
    'colligeas',
    'colligeât',
    'colligées',
    'colligent',
    'colligera',
    'colligiez',
    'collision',
    'collodion',
    'colloïdal',
    'colloïdes',
    'colloquai',
    'colloquas',
    'colloquât',
    'colloquée',
    'colloquer',
    'colloques',
    'colloqués',
    'colloquez',
    'collusion',
    'colluvion',
    'colmatage',
    'colmatais',
    'colmatait',
    'colmatant',
    'colmatées',
    'colmatent',
    'colmatera',
    'colmatiez',
    'colmatons',
    'colocases',
    'colombage',
    'colombier',
    'colombine',
    'colombins',
    'colonages',
    'colonelle',
    'coloniale',
    'coloniaux',
    'colonisai',
    'colonisas',
    'colonisât',
    'colonisée',
    'coloniser',
    'colonises',
    'colonisés',
    'colonisez',
    'colonnade',
    'colophane',
    'colorâmes',
    'colorante',
    'colorants',
    'colorasse',
    'colorâtes',
    'colorerai',
    'coloreras',
    'colorerez',
    'coloriage',
    'coloriais',
    'coloriait',
    'coloriant',
    'coloriées',
    'colorient',
    'coloriera',
    'coloriiez',
    'colorions',
    'coloriste',
    'colossale',
    'colossaux',
    'colostrum',
    'colportai',
    'colportas',
    'colportât',
    'colportée',
    'colporter',
    'colportes',
    'colportés',
    'colportez',
    'coltinage',
    'coltinais',
    'coltinait',
    'coltinant',
    'coltinées',
    'coltinent',
    'coltinera',
    'coltiniez',
    'coltinons',
    'columelle',
    'colzatier',
    'comateuse',
    'combatifs',
    'combative',
    'combattes',
    'combattez',
    'combattis',
    'combattit',
    'combattît',
    'combattra',
    'combattre',
    'combattue',
    'combattus',
    'combinais',
    'combinait',
    'combinant',
    'combinard',
    'combinats',
    'combinées',
    'combinent',
    'combinera',
    'combiniez',
    'combinons',
    'comblâmes',
    'comblasse',
    'comblâtes',
    'comblerai',
    'combleras',
    'comblerez',
    'comblions',
    'comburant',
    'come-back',
    'comédiens',
    'cométaire',
    'commandai',
    'commandas',
    'commandât',
    'commandée',
    'commander',
    'commandes',
    'commandés',
    'commandez',
    'commandos',
    'commémora',
    'commémore',
    'commémoré',
    'commençai',
    'commenças',
    'commençât',
    'commencée',
    'commencer',
    'commences',
    'commencés',
    'commencez',
    'commensal',
    'commentai',
    'commentas',
    'commentât',
    'commentée',
    'commenter',
    'commentes',
    'commentés',
    'commentez',
    'commérage',
    'commérais',
    'commérait',
    'commérant',
    'commerçai',
    'commerças',
    'commerçât',
    'commercer',
    'commerces',
    'commercez',
    'commèrent',
    'commérera',
    'commériez',
    'commérons',
    'commettes',
    'commettez',
    'commettra',
    'commettre',
    'commirent',
    'commisses',
    'commodité',
    'commodore',
    'commotion',
    'commuable',
    'commuâmes',
    'commuasse',
    'commuâtes',
    'commuerai',
    'commueras',
    'commuerez',
    'commuions',
    'communale',
    'communard',
    'communaux',
    'communiai',
    'communias',
    'communiât',
    'communier',
    'communies',
    'communiez',
    'communion',
    'commutais',
    'commutait',
    'commutant',
    'commutées',
    'commutent',
    'commutera',
    'commutiez',
    'commutons',
    'compacité',
    'compactes',
    'compagnes',
    'compagnie',
    'compagnon',
    'comparais',
    'comparait',
    'comparaît',
    'comparant',
    'comparées',
    'comparent',
    'comparera',
    'compariez',
    'comparons',
    'comparses',
    'comparues',
    'compassai',
    'compassas',
    'compassât',
    'compassée',
    'compasser',
    'compasses',
    'compassés',
    'compassez',
    'compatira',
    'compensai',
    'compensas',
    'compensât',
    'compensée',
    'compenser',
    'compenses',
    'compensés',
    'compensez',
    'compérage',
    'compétais',
    'compétait',
    'compétant',
    'compétent',
    'compètent',
    'compétera',
    'compétiez',
    'compétons',
    'compilais',
    'compilait',
    'compilant',
    'compilées',
    'compilent',
    'compilera',
    'compiliez',
    'compilons',
    'compissai',
    'compissas',
    'compissât',
    'compissée',
    'compisser',
    'compisses',
    'compissés',
    'compissez',
    'complaira',
    'complaire',
    'complaise',
    'complétai',
    'complétas',
    'complétât',
    'complétée',
    'compléter',
    'complétés',
    'complètes',
    'complétez',
    'complétif',
    'complexai',
    'complexas',
    'complexât',
    'complexée',
    'complexer',
    'complexes',
    'complexés',
    'complexez',
    'complices',
    'compliqua',
    'complique',
    'compliqué',
    'complotai',
    'complotas',
    'complotât',
    'complotée',
    'comploter',
    'complotes',
    'complotés',
    'complotez',
    'complûmes',
    'complusse',
    'complûtes',
    'componées',
    'comportai',
    'comportas',
    'comportât',
    'comportée',
    'comporter',
    'comportes',
    'comportés',
    'comportez',
    'composais',
    'composait',
    'composant',
    'composées',
    'composent',
    'composera',
    'composeur',
    'composiez',
    'composite',
    'composons',
    'compostai',
    'compostas',
    'compostât',
    'compostée',
    'composter',
    'compostes',
    'compostés',
    'compostez',
    'compotier',
    'comprends',
    'comprenez',
    'comprenne',
    'compressa',
    'compresse',
    'compressé',
    'comprimai',
    'comprimas',
    'comprimât',
    'comprimée',
    'comprimer',
    'comprimes',
    'comprimés',
    'comprîmes',
    'comprimez',
    'comprises',
    'comprisse',
    'comprîtes',
    'compromet',
    'compromis',
    'compromit',
    'compromît',
    'comptable',
    'comptages',
    'comptâmes',
    'comptasse',
    'comptâtes',
    'compterai',
    'compteras',
    'compterez',
    'compteurs',
    'comptines',
    'comptions',
    'comptoirs',
    'compulsai',
    'compulsas',
    'compulsât',
    'compulsée',
    'compulser',
    'compulses',
    'compulsés',
    'compulsez',
    'compulsif',
    'computais',
    'computait',
    'computant',
    'computées',
    'computent',
    'computera',
    'computiez',
    'computons',
    'comtesses',
    'conations',
    'conatives',
    'concassai',
    'concassas',
    'concassât',
    'concassée',
    'concasser',
    'concasses',
    'concassés',
    'concassez',
    'concaténa',
    'concaténé',
    'concatène',
    'concavité',
    'concédais',
    'concédait',
    'concédant',
    'concédées',
    'concèdent',
    'concédera',
    'concédiez',
    'concédons',
    'concentra',
    'concentre',
    'concentré',
    'concernai',
    'concernas',
    'concernât',
    'concernée',
    'concerner',
    'concernes',
    'concernés',
    'concernez',
    'concertai',
    'concertas',
    'concertât',
    'concertée',
    'concerter',
    'concertes',
    'concertés',
    'concertez',
    'concertos',
    'concessif',
    'concevais',
    'concevait',
    'concevant',
    'conceviez',
    'concevoir',
    'concevons',
    'concevrai',
    'concevras',
    'concevrez',
    'conchoïde',
    'concierge',
    'conciliai',
    'concilias',
    'conciliât',
    'conciliée',
    'concilier',
    'concilies',
    'conciliés',
    'conciliez',
    'concision',
    'conclaves',
    'concluais',
    'concluait',
    'concluant',
    'concluent',
    'concluiez',
    'conclûmes',
    'concluons',
    'conclurai',
    'concluras',
    'conclurez',
    'conclusse',
    'conclûtes',
    'concoctai',
    'concoctas',
    'concoctât',
    'concoctée',
    'concocter',
    'concoctes',
    'concoctés',
    'concoctez',
    'conçoives',
    'concombre',
    'concordai',
    'concordas',
    'concordat',
    'concordât',
    'concorder',
    'concordes',
    'concordez',
    'concoures',
    'concourez',
    'concourir',
    'concourra',
    'concourue',
    'concourus',
    'concourut',
    'concourût',
    'concrétai',
    'concrétas',
    'concrétât',
    'concrétée',
    'concréter',
    'concrétés',
    'concrètes',
    'concrétez',
    'concubine',
    'concubins',
    'conçurent',
    'conçusses',
    'condamnai',
    'condamnas',
    'condamnât',
    'condamnée',
    'condamner',
    'condamnes',
    'condamnés',
    'condamnez',
    'condensai',
    'condensas',
    'condensât',
    'condensée',
    'condenser',
    'condenses',
    'condensés',
    'condensez',
    'condiment',
    'condition',
    'conduirai',
    'conduiras',
    'conduirez',
    'conduises',
    'conduisez',
    'conduisis',
    'conduisit',
    'conduisît',
    'conduites',
    'condylien',
    'condylome',
    'confédéra',
    'confédéré',
    'confédère',
    'conférais',
    'conférait',
    'conférant',
    'conférées',
    'confèrent',
    'conférera',
    'confériez',
    'conférons',
    'conferves',
    'confessai',
    'confessas',
    'confessât',
    'confessée',
    'confesser',
    'confesses',
    'confessés',
    'confessez',
    'confettis',
    'confiâmes',
    'confiance',
    'confiante',
    'confiants',
    'confiasse',
    'confiâtes',
    'confident',
    'confierai',
    'confieras',
    'confierez',
    'configura',
    'configure',
    'configuré',
    'confiions',
    'confinais',
    'confinait',
    'confinant',
    'confinées',
    'confinent',
    'confinera',
    'confiniez',
    'confinons',
    'confirais',
    'confirait',
    'confirent',
    'confiriez',
    'confirmai',
    'confirmas',
    'confirmât',
    'confirmée',
    'confirmer',
    'confirmes',
    'confirmés',
    'confirmez',
    'confirons',
    'confiront',
    'confisais',
    'confisait',
    'confisant',
    'confisent',
    'confiseur',
    'confisiez',
    'confisons',
    'confisqua',
    'confisque',
    'confisqué',
    'confisses',
    'confiteor',
    'confiture',
    'confluais',
    'confluait',
    'confluant',
    'confluent',
    'confluera',
    'confluiez',
    'confluons',
    'confondes',
    'confondez',
    'confondis',
    'confondit',
    'confondît',
    'confondra',
    'confondre',
    'confondue',
    'confondus',
    'conformai',
    'conformas',
    'conformât',
    'conformée',
    'conformer',
    'conformes',
    'conformés',
    'conformez',
    'confortai',
    'confortas',
    'confortât',
    'confortée',
    'conforter',
    'confortes',
    'confortés',
    'confortez',
    'confrères',
    'confrérie',
    'confronta',
    'confronte',
    'confronté',
    'confusion',
    'congédiai',
    'congédias',
    'congédiât',
    'congédiée',
    'congédier',
    'congédies',
    'congédiés',
    'congédiez',
    'congelais',
    'congelait',
    'congelant',
    'congelées',
    'congèlent',
    'congèlera',
    'congeliez',
    'congelons',
    'congénère',
    'congestif',
    'congolais',
    'congréais',
    'congréait',
    'congréant',
    'congréées',
    'congréent',
    'congréera',
    'congréiez',
    'congréons',
    'congruent',
    'conicines',
    'conicités',
    'conifères',
    'cônirions',
    'cônissais',
    'cônissait',
    'cônissant',
    'cônissent',
    'cônissiez',
    'cônissons',
    'conjoigne',
    'conjointe',
    'conjoints',
    'conjugale',
    'conjugaux',
    'conjuguai',
    'conjuguas',
    'conjuguât',
    'conjuguée',
    'conjuguer',
    'conjugues',
    'conjugués',
    'conjuguez',
    'conjurais',
    'conjurait',
    'conjurant',
    'conjurées',
    'conjurent',
    'conjurera',
    'conjuriez',
    'conjurons',
    'connaisse',
    'connaîtra',
    'connaître',
    'connardes',
    'connasses',
    'connectai',
    'connectas',
    'connectât',
    'connectée',
    'connecter',
    'connectes',
    'connectés',
    'connectez',
    'connectif',
    'conneries',
    'connexion',
    'connexité',
    'connivent',
    'connotais',
    'connotait',
    'connotant',
    'connotées',
    'connotent',
    'connotera',
    'connotiez',
    'connotons',
    'connurent',
    'connusses',
    'conobrais',
    'conobrait',
    'conobrant',
    'conobrées',
    'conobrent',
    'conobrera',
    'conobriez',
    'conobrons',
    'conpressa',
    'conpresse',
    'conpressé',
    'conquérez',
    'conquérir',
    'conquerra',
    'conquérue',
    'conquérus',
    'conquêtes',
    'conquière',
    'conquiers',
    'conquiert',
    'conquîmes',
    'conquises',
    'conquisse',
    'conquîtes',
    'consacrai',
    'consacras',
    'consacrât',
    'consacrée',
    'consacrer',
    'consacres',
    'consacrés',
    'consacrez',
    'conscient',
    'conscrits',
    'conseilla',
    'conseille',
    'conseillé',
    'consensus',
    'consentes',
    'consentez',
    'consentie',
    'consentir',
    'consentis',
    'consentit',
    'consentît',
    'conservai',
    'conservas',
    'conservât',
    'conservée',
    'conserver',
    'conserves',
    'conservés',
    'conservez',
    'considéra',
    'considéré',
    'considère',
    'consignai',
    'consignas',
    'consignât',
    'consignée',
    'consigner',
    'consignes',
    'consignés',
    'consignez',
    'consistai',
    'consistas',
    'consistât',
    'consister',
    'consistes',
    'consistez',
    'consoeurs',
    'consolais',
    'consolait',
    'consolant',
    'consolées',
    'consolent',
    'consolera',
    'consolida',
    'consolide',
    'consolidé',
    'consoliez',
    'consolons',
    'consommai',
    'consommas',
    'consommât',
    'consommée',
    'consommer',
    'consommes',
    'consommés',
    'consommez',
    'consonais',
    'consonait',
    'consonant',
    'consonent',
    'consonera',
    'consoniez',
    'consonnes',
    'consonons',
    'conspirai',
    'conspiras',
    'conspirât',
    'conspirée',
    'conspirer',
    'conspires',
    'conspirés',
    'conspirez',
    'conspuais',
    'conspuait',
    'conspuant',
    'conspuées',
    'conspuent',
    'conspuera',
    'conspuiez',
    'conspuons',
    'constable',
    'constance',
    'constante',
    'constants',
    'constatai',
    'constatas',
    'constatât',
    'constatée',
    'constater',
    'constates',
    'constatés',
    'constatez',
    'constella',
    'constelle',
    'constellé',
    'consterna',
    'consterne',
    'consterné',
    'constipai',
    'constipas',
    'constipât',
    'constipée',
    'constiper',
    'constipes',
    'constipés',
    'constipez',
    'constitua',
    'constitue',
    'constitué',
    'construis',
    'construit',
    'consulats',
    'consultai',
    'consultas',
    'consultât',
    'consultée',
    'consulter',
    'consultes',
    'consultés',
    'consultez',
    'consumais',
    'consumait',
    'consumant',
    'consumées',
    'consument',
    'consumera',
    'consumiez',
    'consumons',
    'contactai',
    'contactas',
    'contactât',
    'contactée',
    'contacter',
    'contactes',
    'contactés',
    'contactez',
    'contagion',
    'contaient',
    'container',
    'contamina',
    'contamine',
    'contaminé',
    'contasses',
    'contempla',
    'contemple',
    'contemplé',
    'contenais',
    'contenait',
    'contenant',
    'conteneur',
    'conteniez',
    'contenons',
    'contentai',
    'contentas',
    'contentât',
    'contentée',
    'contenter',
    'contentes',
    'contentés',
    'contentez',
    'contentif',
    'contenues',
    'conterais',
    'conterait',
    'contèrent',
    'conteriez',
    'conterons',
    'conteront',
    'contestai',
    'contestas',
    'contestât',
    'contestée',
    'contester',
    'contestes',
    'contestés',
    'contestez',
    'conteuses',
    'contextes',
    'contienne',
    'contiguës',
    'continent',
    'contînmes',
    'continsse',
    'contîntes',
    'continuai',
    'continuas',
    'continuât',
    'continuée',
    'continuel',
    'continuer',
    'continues',
    'continués',
    'continuez',
    'continuos',
    'continuum',
    'contourna',
    'contourne',
    'contourné',
    'contracta',
    'contracte',
    'contracté',
    'contrains',
    'contraint',
    'contraire',
    'contralto',
    'contrâmes',
    'contraria',
    'contrarie',
    'contrarié',
    'contrasse',
    'contrasta',
    'contraste',
    'contrasté',
    'contrâtes',
    'contrebas',
    'contrebat',
    'contredis',
    'contredit',
    'contredît',
    'contrefis',
    'contrefit',
    'contrefît',
    'contrerai',
    'contreras',
    'contrerez',
    'contre-ut',
    'contribua',
    'contribue',
    'contribué',
    'contrions',
    'contrista',
    'contriste',
    'contristé',
    'contrites',
    'contrôlai',
    'contrôlas',
    'contrôlât',
    'contrôlée',
    'contrôler',
    'contrôles',
    'contrôlés',
    'contrôlez',
    'controuva',
    'controuve',
    'controuvé',
    'contumace',
    'contusion',
    'convaincs',
    'convaincu',
    'convenais',
    'convenait',
    'convenant',
    'conveniez',
    'convenons',
    'convenues',
    'convergea',
    'converger',
    'converges',
    'convergez',
    'conversai',
    'conversas',
    'conversât',
    'converser',
    'converses',
    'conversez',
    'convertie',
    'convertir',
    'convertis',
    'convertit',
    'convertît',
    'convexité',
    'conviâmes',
    'conviasse',
    'conviâtes',
    'convienne',
    'convierai',
    'convieras',
    'convierez',
    'conviions',
    'convînmes',
    'convinsse',
    'convîntes',
    'convivial',
    'convoient',
    'convoiera',
    'convoitai',
    'convoitas',
    'convoitât',
    'convoitée',
    'convoiter',
    'convoites',
    'convoités',
    'convoitez',
    'convolais',
    'convolait',
    'convolant',
    'convolent',
    'convolera',
    'convoliez',
    'convolons',
    'convoquai',
    'convoquas',
    'convoquât',
    'convoquée',
    'convoquer',
    'convoques',
    'convoqués',
    'convoquez',
    'convoyais',
    'convoyait',
    'convoyant',
    'convoyées',
    'convoyeur',
    'convoyiez',
    'convoyons',
    'convulsai',
    'convulsas',
    'convulsât',
    'convulsée',
    'convulser',
    'convulses',
    'convulsés',
    'convulsez',
    'convulsif',
    'coopérais',
    'coopérait',
    'coopérant',
    'coopèrent',
    'coopérera',
    'coopériez',
    'coopérons',
    'cooptâmes',
    'cooptasse',
    'cooptâtes',
    'coopterai',
    'coopteras',
    'coopterez',
    'cooptions',
    'coordonna',
    'coordonne',
    'coordonné',
    'copartage',
    'copermuta',
    'copermute',
    'copermuté',
    'copiaient',
    'copiasses',
    'copierais',
    'copierait',
    'copièrent',
    'copieriez',
    'copierons',
    'copieront',
    'copieuses',
    'copilotes',
    'copinages',
    'copinâmes',
    'copinasse',
    'copinâtes',
    'copinerai',
    'copineras',
    'copinerez',
    'copinerie',
    'copinions',
    'copossédé',
    'copossède',
    'copposéda',
    'copulâmes',
    'copulasse',
    'copulâtes',
    'copulatif',
    'copulerai',
    'copuleras',
    'copulerez',
    'copulions',
    'copyright',
    'coquaient',
    'coquasses',
    'coquelets',
    'coqueleux',
    'coquerais',
    'coquerait',
    'coquèrent',
    'coquerico',
    'coqueriez',
    'coquerons',
    'coqueront',
    'coquetais',
    'coquetait',
    'coquetant',
    'coquetier',
    'coquetiez',
    'coquetons',
    'coquettes',
    'coquillai',
    'coquillas',
    'coquillât',
    'coquiller',
    'coquilles',
    'coquillez',
    'corallien',
    'coranique',
    'corbeille',
    'corbillat',
    'cordaient',
    'cordasses',
    'cordelais',
    'cordelait',
    'cordelant',
    'cordelées',
    'cordelier',
    'cordeliez',
    'cordelles',
    'cordelons',
    'corderais',
    'corderait',
    'cordèrent',
    'corderiez',
    'corderons',
    'corderont',
    'cordiales',
    'cordonnai',
    'cordonnas',
    'cordonnât',
    'cordonnée',
    'cordonner',
    'cordonnes',
    'cordonnés',
    'cordonnet',
    'cordonnez',
    'coréennes',
    'coriandre',
    'coricides',
    'corindons',
    'cormorans',
    'cornaient',
    'cornaline',
    'cornasses',
    'cornéenne',
    'corneille',
    'cornélien',
    'cornemuse',
    'cornerais',
    'cornerait',
    'cornèrent',
    'corneriez',
    'cornerons',
    'corneront',
    'cornettes',
    'corniauds',
    'corniches',
    'cornichon',
    'cornières',
    'cornistes',
    'coronaire',
    'coronille',
    'corporaux',
    'corporels',
    'corpulent',
    'correctes',
    'correctif',
    'corrélais',
    'corrélait',
    'corrélant',
    'corrélées',
    'corrèlent',
    'corrélera',
    'corréliez',
    'corrélons',
    'corridors',
    'corrigeai',
    'corrigeas',
    'corrigeât',
    'corrigées',
    'corrigent',
    'corrigera',
    'corrigiez',
    'corrobora',
    'corrobore',
    'corroboré',
    'corrodais',
    'corrodait',
    'corrodant',
    'corrodées',
    'corrodent',
    'corrodera',
    'corrodiez',
    'corrodons',
    'corroient',
    'corroiera',
    'corrompes',
    'corrompez',
    'corrompis',
    'corrompit',
    'corrompît',
    'corrompra',
    'corrompre',
    'corrompue',
    'corrompus',
    'corrosifs',
    'corrosion',
    'corrosive',
    'corroyage',
    'corroyais',
    'corroyait',
    'corroyant',
    'corroyées',
    'corroyeur',
    'corroyiez',
    'corroyons',
    'corsaient',
    'corsaires',
    'corsasses',
    'corselets',
    'corserais',
    'corserait',
    'corsèrent',
    'corseriez',
    'corserons',
    'corseront',
    'corsetais',
    'corsetait',
    'corsetant',
    'corsetées',
    'corsètent',
    'corsètera',
    'corsetier',
    'corsetiez',
    'corsetons',
    'corticale',
    'corticaux',
    'cortisone',
    'corvéable',
    'corvettes',
    'coryphées',
    'cosmiques',
    'cossaient',
    'cossardes',
    'cossasses',
    'cosserais',
    'cosserait',
    'cossèrent',
    'cosseriez',
    'cosserons',
    'cosseront',
    'costaudes',
    'costumais',
    'costumait',
    'costumant',
    'costumées',
    'costument',
    'costumera',
    'costumier',
    'costumiez',
    'costumons',
    'cotassent',
    'cotassiez',
    'cotations',
    'côtelette',
    'coterions',
    'cothurnes',
    'cotillons',
    'cotirions',
    'cotisâmes',
    'cotisante',
    'cotisants',
    'cotisasse',
    'cotisâtes',
    'cotiserai',
    'cotiseras',
    'cotiserez',
    'cotisions',
    'cotissais',
    'cotissait',
    'cotissant',
    'cotissent',
    'cotissiez',
    'cotissons',
    'côtoierai',
    'côtoieras',
    'côtoierez',
    'cotonnade',
    'cotonnais',
    'cotonnait',
    'cotonnant',
    'cotonnées',
    'cotonnent',
    'cotonnera',
    'cotonneux',
    'cotonnier',
    'cotonniez',
    'cotonnons',
    'côtoyâmes',
    'côtoyasse',
    'côtoyâtes',
    'côtoyions',
    'cotylédon',
    'couardise',
    'couchages',
    'couchâmes',
    'couchante',
    'couchants',
    'couchasse',
    'couchâtes',
    'coucherai',
    'coucheras',
    'coucherez',
    'couchette',
    'coucheurs',
    'coucheuse',
    'couchions',
    'coudaient',
    'coudasses',
    'couderais',
    'couderait',
    'coudèrent',
    'couderiez',
    'couderons',
    'couderont',
    'coudoient',
    'coudoiera',
    'coudoyais',
    'coudoyait',
    'coudoyant',
    'coudoyées',
    'coudoyiez',
    'coudoyons',
    'coudraies',
    'coudriers',
    'coudrions',
    'coufiques',
    'cougouars',
    'couillons',
    'couinâmes',
    'couinasse',
    'couinâtes',
    'couinerai',
    'couineras',
    'couinerez',
    'couinions',
    'coulaient',
    'coulantes',
    'coulasses',
    'coulerais',
    'coulerait',
    'coulèrent',
    'couleriez',
    'coulerons',
    'couleront',
    'couleuvre',
    'coulissai',
    'coulissas',
    'coulissât',
    'coulissée',
    'coulisser',
    'coulisses',
    'coulissés',
    'coulissez',
    'coupables',
    'coupaient',
    'coupailla',
    'coupaille',
    'coupaillé',
    'coupantes',
    'coupasses',
    'coupe-feu',
    'coupellai',
    'coupellas',
    'coupellât',
    'coupellée',
    'coupeller',
    'coupelles',
    'coupellés',
    'coupellez',
    'couperais',
    'couperait',
    'coupèrent',
    'couperets',
    'couperiez',
    'couperons',
    'couperont',
    'couperose',
    'couperosé',
    'couplages',
    'couplâmes',
    'couplasse',
    'couplâtes',
    'couplerai',
    'coupleras',
    'couplerez',
    'coupleurs',
    'couplions',
    'courageux',
    'couraient',
    'courailla',
    'couraille',
    'couraillé',
    'courantes',
    'courbâmes',
    'courbasse',
    'courbâtes',
    'courbatue',
    'courbatus',
    'courberai',
    'courberas',
    'courberez',
    'courbette',
    'courbions',
    'courbures',
    'courettes',
    'coureuses',
    'courgette',
    'courlieus',
    'couronnai',
    'couronnas',
    'couronnât',
    'couronnée',
    'couronner',
    'couronnes',
    'couronnés',
    'couronnez',
    'courriers',
    'courrions',
    'courroies',
    'courrouça',
    'courrouce',
    'courroucé',
    'coursâmes',
    'coursasse',
    'coursâtes',
    'courserai',
    'courseras',
    'courserez',
    'coursière',
    'coursiers',
    'coursions',
    'coursives',
    'courtages',
    'courtauda',
    'courtaude',
    'courtaudé',
    'courtauds',
    'courtière',
    'courtiers',
    'courtines',
    'courtisai',
    'courtisan',
    'courtisas',
    'courtisât',
    'courtisée',
    'courtiser',
    'courtises',
    'courtisés',
    'courtisez',
    'courtoise',
    'coururent',
    'courusses',
    'cousaient',
    'couseuses',
    'cousinais',
    'cousinait',
    'cousinant',
    'cousinées',
    'cousinent',
    'cousinera',
    'cousiniez',
    'cousinons',
    'cousirent',
    'cousisses',
    'coussinet',
    'coûtaient',
    'coûtantes',
    'coûtasses',
    'coutelier',
    'coûterais',
    'coûterait',
    'coûtèrent',
    'coûteriez',
    'coûterons',
    'coûteront',
    'coûteuses',
    'coutumier',
    'couturais',
    'couturait',
    'couturant',
    'couturées',
    'couturent',
    'couturera',
    'couturier',
    'couturiez',
    'couturons',
    'couvaient',
    'couvaison',
    'couvasses',
    'couverais',
    'couverait',
    'couvercle',
    'couvèrent',
    'couveriez',
    'couverons',
    'couveront',
    'couvertes',
    'couveuses',
    'couvrante',
    'couvrants',
    'couvreurs',
    'couvreuse',
    'couvrîmes',
    'couvrions',
    'couvrirai',
    'couvriras',
    'couvrirez',
    'couvrisse',
    'couvrîtes',
    'covendeur',
    'coxalgies',
    'crabotage',
    'crachâmes',
    'crachasse',
    'crachâtes',
    'cracherai',
    'cracheras',
    'cracherez',
    'cracheurs',
    'cracheuse',
    'crachinât',
    'crachiner',
    'crachions',
    'crachoirs',
    'crachotai',
    'crachotas',
    'crachotât',
    'crachoter',
    'crachotes',
    'crachotez',
    'craignais',
    'craignait',
    'craignant',
    'craignent',
    'craigniez',
    'craignons',
    'craillais',
    'craillait',
    'craillant',
    'craillent',
    'craillera',
    'crailliez',
    'craillons',
    'craindrai',
    'craindras',
    'craindrez',
    'craintifs',
    'craintive',
    'cramaient',
    'cramasses',
    'cramerais',
    'cramerait',
    'cramèrent',
    'crameriez',
    'cramerons',
    'crameront',
    'cramiques',
    'cramoisie',
    'cramoisis',
    'cramponna',
    'cramponne',
    'cramponné',
    'crampsais',
    'crampsait',
    'crampsant',
    'crampsent',
    'crampsera',
    'crampsiez',
    'crampsons',
    'cramsâmes',
    'cramsasse',
    'cramsâtes',
    'cramserai',
    'cramseras',
    'cramserez',
    'cramsions',
    'cranaient',
    'crânaient',
    'cranasses',
    'crânasses',
    'crânement',
    'cranerais',
    'crânerais',
    'cranerait',
    'crânerait',
    'cranèrent',
    'crânèrent',
    'crâneries',
    'craneriez',
    'crâneriez',
    'cranerons',
    'crânerons',
    'craneront',
    'crâneront',
    'crâneuses',
    'crânienne',
    'crantâmes',
    'crantasse',
    'crantâtes',
    'cranterai',
    'cranteras',
    'cranterez',
    'crantions',
    'crapahuta',
    'crapahute',
    'crapahuté',
    'crapaütai',
    'crapaütas',
    'crapaütât',
    'crapaütée',
    'crapaüter',
    'crapaütes',
    'crapaütés',
    'crapaütez',
    'crapulais',
    'crapulait',
    'crapulant',
    'crapulent',
    'crapulera',
    'crapuleux',
    'crapuliez',
    'crapulons',
    'craquages',
    'craquâmes',
    'craquasse',
    'craquâtes',
    'craquelai',
    'craquelas',
    'craquelât',
    'craquelée',
    'craqueler',
    'craquelés',
    'craquelez',
    'craquelle',
    'craquerai',
    'craqueras',
    'craquerez',
    'craquetai',
    'craquetas',
    'craquetât',
    'craqueter',
    'craquetez',
    'craquette',
    'craquions',
    'crassâmes',
    'crassanes',
    'crassasse',
    'crassâtes',
    'crasserai',
    'crasseras',
    'crasserez',
    'crasseuse',
    'crassiers',
    'crassions',
    'cravachai',
    'cravachas',
    'cravachât',
    'cravachée',
    'cravacher',
    'cravaches',
    'cravachés',
    'cravachez',
    'cravatais',
    'cravatait',
    'cravatant',
    'cravatées',
    'cravatent',
    'cravatera',
    'cravatiez',
    'cravatons',
    'crawlâmes',
    'crawlasse',
    'crawlâtes',
    'crawlerai',
    'crawleras',
    'crawlerez',
    'crawleurs',
    'crawleuse',
    'crawlions',
    'crayeuses',
    'crayonnai',
    'crayonnas',
    'crayonnât',
    'crayonnée',
    'crayonner',
    'crayonnes',
    'crayonnés',
    'crayonnez',
    'créancier',
    'créassent',
    'créassiez',
    'créateurs',
    'créatines',
    'créations',
    'créatives',
    'créatrice',
    'créatures',
    'crécelles',
    'créchâmes',
    'créchasse',
    'créchâtes',
    'crécherai',
    'crécheras',
    'crécherez',
    'créchions',
    'crédences',
    'crédibles',
    'créditais',
    'créditait',
    'créditant',
    'créditées',
    'créditent',
    'créditera',
    'créditeur',
    'créditiez',
    'créditons',
    'crédulité',
    'créerions',
    'crémaient',
    'crémantes',
    'crémasses',
    'crémation',
    'crémerais',
    'crémerait',
    'crémèrent',
    'crémeries',
    'crémeriez',
    'crémerons',
    'crémeront',
    'crémeuses',
    'crémières',
    'crénaient',
    'crénasses',
    'crénelais',
    'crénelait',
    'crénelant',
    'crénelées',
    'créneliez',
    'crénelles',
    'crénelons',
    'crénerais',
    'crénerait',
    'crénèrent',
    'créneriez',
    'crénerons',
    'créneront',
    'créolisai',
    'créolisas',
    'créolisât',
    'créolisée',
    'créoliser',
    'créolises',
    'créolisés',
    'créolisez',
    'créosotai',
    'créosotas',
    'créosotât',
    'créosotée',
    'créosoter',
    'créosotes',
    'créosotés',
    'créosotez',
    'crêpaient',
    'crêpasses',
    'crêperais',
    'crêperait',
    'crêpèrent',
    'crêperies',
    'crêperiez',
    'crêperons',
    'crêperont',
    'crépirais',
    'crépirait',
    'crépirent',
    'crépiriez',
    'crépirons',
    'crépiront',
    'crépisses',
    'crépissez',
    'crépitais',
    'crépitait',
    'crépitant',
    'crépitent',
    'crépitera',
    'crépitiez',
    'crépitons',
    'crescendo',
    'crétacées',
    'crétinisa',
    'crétinise',
    'crétinisé',
    'crétoises',
    'cretonnes',
    'creusages',
    'creusâmes',
    'creusasse',
    'creusâtes',
    'creuserai',
    'creuseras',
    'creuserez',
    'creusions',
    'crevaient',
    'crevaison',
    'crevassai',
    'crevassas',
    'crevassât',
    'crevassée',
    'crevasser',
    'crevasses',
    'crevassés',
    'crevassez',
    'crèverais',
    'crèverait',
    'crevèrent',
    'crèveriez',
    'crèverons',
    'crèveront',
    'crevettes',
    'criaillai',
    'criaillas',
    'criaillât',
    'criailler',
    'criailles',
    'criaillez',
    'criassent',
    'criassiez',
    'criblages',
    'criblâmes',
    'criblasse',
    'criblâtes',
    'criblerai',
    'cribleras',
    'criblerez',
    'criblions',
    'crierions',
    'criminels',
    'crinières',
    'crinoline',
    'crispâmes',
    'crispante',
    'crispants',
    'crispasse',
    'crispâtes',
    'crisperai',
    'crisperas',
    'crisperez',
    'crispions',
    'crissâmes',
    'crissasse',
    'crissâtes',
    'crisserai',
    'crisseras',
    'crisserez',
    'crissions',
    'critérium',
    'critiquai',
    'critiquas',
    'critiquât',
    'critiquée',
    'critiquer',
    'critiques',
    'critiqués',
    'critiquez',
    'croassais',
    'croassait',
    'croassant',
    'croassent',
    'croassera',
    'croassiez',
    'croassons',
    'crochâmes',
    'crochasse',
    'crochâtes',
    'crocherai',
    'crocheras',
    'crocherez',
    'crochetai',
    'crochetas',
    'crochetât',
    'crochetée',
    'crocheter',
    'crochetés',
    'crochètes',
    'crochetez',
    'crochions',
    'crocodile',
    'croirions',
    'croisades',
    'croisâmes',
    'croisasse',
    'croisâtes',
    'croiserai',
    'croiseras',
    'croiserez',
    'croiseurs',
    'croisière',
    'croisions',
    'croissais',
    'croissait',
    'croissant',
    'croissent',
    'croissiez',
    'croissons',
    'croîtrais',
    'croîtrait',
    'croîtriez',
    'croîtrons',
    'croîtront',
    'croquâmes',
    'croquante',
    'croquants',
    'croquasse',
    'croquâtes',
    'croquerai',
    'croqueras',
    'croquerez',
    'croquette',
    'croquions',
    'crossâmes',
    'crossasse',
    'crossâtes',
    'crosserai',
    'crosseras',
    'crosserez',
    'crossions',
    'crottâmes',
    'crottasse',
    'crottâtes',
    'crotterai',
    'crotteras',
    'crotterez',
    'crottions',
    'crouillat',
    'crouilles',
    'croulâmes',
    'croulante',
    'croulants',
    'croulasse',
    'croulâtes',
    'croulerai',
    'crouleras',
    'croulerez',
    'croulions',
    'croupière',
    'croupiers',
    'croupîmes',
    'croupions',
    'croupirai',
    'croupiras',
    'croupirez',
    'croupisse',
    'croupîtes',
    'croustade',
    'croûtâmes',
    'croûtasse',
    'croûtâtes',
    'croûterai',
    'croûteras',
    'croûterez',
    'croûtions',
    'croyables',
    'croyaient',
    'croyances',
    'croyantes',
    'cruciales',
    'crucifiai',
    'crucifias',
    'crucifiât',
    'crucifiée',
    'crucifier',
    'crucifies',
    'crucifiés',
    'crucifiez',
    'crussions',
    'crûssions',
    'crustacés',
    'cruzeiros',
    'cryptâmes',
    'cryptasse',
    'cryptâtes',
    'crypterai',
    'crypteras',
    'crypterez',
    'cryptions',
    'cryptique',
    'cubassent',
    'cubassiez',
    'cuberions',
    'cucurbite',
    'cueillais',
    'cueillait',
    'cueillant',
    'cueillent',
    'cueillera',
    'cueillies',
    'cueilliez',
    'cueillons',
    'cuillerée',
    'cuillères',
    'cuiraient',
    'cuirassai',
    'cuirassas',
    'cuirassât',
    'cuirassée',
    'cuirasser',
    'cuirasses',
    'cuirassés',
    'cuirassez',
    'cuisaient',
    'cuisantes',
    'cuisinais',
    'cuisinait',
    'cuisinant',
    'cuisinées',
    'cuisinent',
    'cuisinera',
    'cuisinier',
    'cuisiniez',
    'cuisinons',
    'cuisirent',
    'cuisisses',
    'cuissarde',
    'cuissards',
    'cuisseaux',
    'cuitaient',
    'cuitasses',
    'cuiterais',
    'cuiterait',
    'cuitèrent',
    'cuiteriez',
    'cuiterons',
    'cuiteront',
    'cuivrâmes',
    'cuivrasse',
    'cuivrâtes',
    'cuivrerai',
    'cuivreras',
    'cuivrerez',
    'cuivreuse',
    'cuivrions',
    'cuivrique',
    'culassent',
    'culassiez',
    'culbutage',
    'culbutais',
    'culbutait',
    'culbutant',
    'culbutées',
    'culbutent',
    'culbutera',
    'culbuteur',
    'culbutiez',
    'culbutons',
    'culerions',
    'culinaire',
    'culminais',
    'culminait',
    'culminant',
    'culminent',
    'culminera',
    'culminiez',
    'culminons',
    'culottage',
    'culottais',
    'culottait',
    'culottant',
    'culottées',
    'culottent',
    'culottera',
    'culottier',
    'culottiez',
    'culottons',
    'cultismes',
    'cultivais',
    'cultivait',
    'cultivant',
    'cultivées',
    'cultivent',
    'cultivera',
    'cultiviez',
    'cultivons',
    'cultuelle',
    'culturale',
    'culturaux',
    'culturels',
    'cumulable',
    'cumulâmes',
    'cumularde',
    'cumulards',
    'cumulasse',
    'cumulâtes',
    'cumulatif',
    'cumulerai',
    'cumuleras',
    'cumulerez',
    'cumulions',
    'cupidités',
    'cuprifère',
    'cupriques',
    'curaillon',
    'curassent',
    'curassiez',
    'curatelle',
    'curateurs',
    'curatives',
    'curatrice',
    'cure-dent',
    'cure-pipe',
    'curerions',
    'curetages',
    'curetâmes',
    'curetasse',
    'curetâtes',
    'curetions',
    'curettage',
    'curettent',
    'curettera',
    'curieuses',
    'curiosité',
    'cuticules',
    'cuvaisons',
    'cuvassent',
    'cuvassiez',
    'cuvelages',
    'cuvelâmes',
    'cuvelasse',
    'cuvelâtes',
    'cuvelions',
    'cuvellent',
    'cuvellera',
    'cuverions',
    'cyanamide',
    'cyanogène',
    'cyanosais',
    'cyanosait',
    'cyanosant',
    'cyanosées',
    'cyanosent',
    'cyanosera',
    'cyanosiez',
    'cyanosons',
    'cyanurais',
    'cyanurait',
    'cyanurant',
    'cyanurées',
    'cyanurent',
    'cyanurera',
    'cyanuriez',
    'cyanurons',
    'cyclables',
    'cyclamens',
    'cycliques',
    'cyclisais',
    'cyclisait',
    'cyclisant',
    'cyclisées',
    'cyclisent',
    'cyclisera',
    'cyclisiez',
    'cyclismes',
    'cyclisons',
    'cyclistes',
    'cycloïdal',
    'cycloïdes',
    'cyclonale',
    'cyclonaux',
    'cyclopéen',
    'cyclotron',
    'cylindrai',
    'cylindras',
    'cylindrât',
    'cylindrée',
    'cylindrer',
    'cylindres',
    'cylindrés',
    'cylindrez',
    'cymbalier',
    'cymbalums',
    'cynodrome',
    'cynophile',
    'cyprières',
    'cypriotes',
    'cystiques',
    'cytologie',
    'cytolyses',
    'czimbalum',
    'dadaïsmes',
    'daguaient',
    'daguasses',
    'daguerais',
    'daguerait',
    'daguèrent',
    'dagueriez',
    'daguerons',
    'dagueront',
    'dahoméens',
    'daignâmes',
    'daignasse',
    'daignâtes',
    'daignerai',
    'daigneras',
    'daignerez',
    'daignions',
    'dallaient',
    'dallasses',
    'dallerais',
    'dallerait',
    'dallèrent',
    'dalleriez',
    'dallerons',
    'dalleront',
    'dalmatien',
    'daltonien',
    'damassais',
    'damassait',
    'damassant',
    'damassées',
    'damassent',
    'damassera',
    'damassiez',
    'damassons',
    'damassure',
    'damerions',
    'damnables',
    'damnaient',
    'damnasses',
    'damnation',
    'damnerais',
    'damnerait',
    'damnèrent',
    'damneriez',
    'damnerons',
    'damneront',
    'damoiseau',
    'dandinais',
    'dandinait',
    'dandinant',
    'dandinées',
    'dandinent',
    'dandinera',
    'dandiniez',
    'dandinons',
    'dangereux',
    'daniennes',
    'dansables',
    'dansaient',
    'dansantes',
    'dansasses',
    'danserais',
    'danserait',
    'dansèrent',
    'danseriez',
    'danserons',
    'danseront',
    'danseuses',
    'dansottai',
    'dansottas',
    'dansottât',
    'dansotter',
    'dansottes',
    'dansottez',
    'dantesque',
    'darboukas',
    'dardaient',
    'dardasses',
    'darderais',
    'darderait',
    'dardèrent',
    'darderiez',
    'darderons',
    'darderont',
    'dare-dare',
    'dartreuse',
    'dartroses',
    'darwinien',
    'datassent',
    'datassiez',
    'datations',
    'daterions',
    'daubaient',
    'daubasses',
    'dauberais',
    'dauberait',
    'daubèrent',
    'dauberiez',
    'dauberons',
    'dauberont',
    'daubeuses',
    'daubières',
    'dauphines',
    'davantage',
    'déactivai',
    'déactivas',
    'déactivât',
    'déactivée',
    'déactiver',
    'déactives',
    'déactivés',
    'déactivez',
    'déambulai',
    'déambulas',
    'déambulât',
    'déambuler',
    'déambules',
    'déambulez',
    'débâchais',
    'débâchait',
    'débâchant',
    'débâchées',
    'débâchent',
    'débâchera',
    'débâchiez',
    'débâchons',
    'débâclais',
    'débâclait',
    'débâclant',
    'débâclent',
    'débâclera',
    'débâcliez',
    'débâclons',
    'débagoula',
    'débagoule',
    'débagoulé',
    'déballage',
    'déballais',
    'déballait',
    'déballant',
    'déballées',
    'déballent',
    'déballera',
    'déballiez',
    'déballons',
    'débandade',
    'débandais',
    'débandait',
    'débandant',
    'débandées',
    'débandent',
    'débandera',
    'débandiez',
    'débandons',
    'débaptisa',
    'débaptise',
    'débaptisé',
    'débardage',
    'débardais',
    'débardait',
    'débardant',
    'débardées',
    'débardent',
    'débardera',
    'débardeur',
    'débardiez',
    'débardons',
    'débarquai',
    'débarquas',
    'débarquât',
    'débarquée',
    'débarquer',
    'débarques',
    'débarqués',
    'débarquez',
    'débarrais',
    'débarrait',
    'débarrant',
    'débarrées',
    'débarrent',
    'débarrera',
    'débarriez',
    'débarrons',
    'débâtâmes',
    'débâtasse',
    'débâtâtes',
    'débâterai',
    'débâteras',
    'débâterez',
    'débâtîmes',
    'débâtions',
    'débâtirai',
    'débâtiras',
    'débâtirez',
    'débâtisse',
    'débâtîtes',
    'débattais',
    'débattait',
    'débattant',
    'débattent',
    'débatteur',
    'débattiez',
    'débattons',
    'débattrai',
    'débattras',
    'débattrez',
    'débattues',
    'débauchai',
    'débauchas',
    'débauchât',
    'débauchée',
    'débaucher',
    'débauches',
    'débauchés',
    'débauchez',
    'débectais',
    'débectait',
    'débectant',
    'débectées',
    'débectent',
    'débectera',
    'débectiez',
    'débectons',
    'débenzola',
    'débenzole',
    'débenzolé',
    'débilitai',
    'débilitas',
    'débilitât',
    'débilitée',
    'débiliter',
    'débilites',
    'débilités',
    'débilitez',
    'débinâmes',
    'débinasse',
    'débinâtes',
    'débinerai',
    'débineras',
    'débinerez',
    'débinions',
    'débitable',
    'débitages',
    'débitâmes',
    'débitante',
    'débitants',
    'débitasse',
    'débitâtes',
    'débiterai',
    'débiteras',
    'débiterez',
    'débiteurs',
    'débitions',
    'débitrice',
    'déblaient',
    'déblaiera',
    'déblatéra',
    'déblatéré',
    'déblatère',
    'déblayage',
    'déblayais',
    'déblayait',
    'déblayant',
    'déblayées',
    'déblayent',
    'déblayera',
    'déblayiez',
    'déblayons',
    'débleuies',
    'débleuira',
    'déblocage',
    'débloquai',
    'débloquas',
    'débloquât',
    'débloquée',
    'débloquer',
    'débloques',
    'débloqués',
    'débloquez',
    'débobinai',
    'débobinas',
    'débobinât',
    'débobinée',
    'débobiner',
    'débobines',
    'débobinés',
    'débobinez',
    'déboguais',
    'déboguait',
    'déboguant',
    'déboguées',
    'déboguent',
    'déboguera',
    'déboguiez',
    'déboguons',
    'déboisage',
    'déboisais',
    'déboisait',
    'déboisant',
    'déboisées',
    'déboisent',
    'déboisera',
    'déboisiez',
    'déboisons',
    'déboîtais',
    'déboîtait',
    'déboîtant',
    'déboîtées',
    'déboîtent',
    'déboîtera',
    'déboîtiez',
    'déboîtons',
    'débondais',
    'débondait',
    'débondant',
    'débondées',
    'débondent',
    'débondera',
    'débondiez',
    'débondons',
    'débordais',
    'débordait',
    'débordant',
    'débordées',
    'débordent',
    'débordera',
    'débordiez',
    'débordons',
    'débossela',
    'débosselé',
    'débottais',
    'débottait',
    'débottant',
    'débottées',
    'débottent',
    'débottera',
    'débottiez',
    'débottons',
    'débouchai',
    'débouchas',
    'débouchât',
    'débouchée',
    'déboucher',
    'débouches',
    'débouchés',
    'débouchez',
    'débouclai',
    'débouclas',
    'débouclât',
    'débouclée',
    'déboucler',
    'déboucles',
    'débouclés',
    'débouclez',
    'déboudais',
    'déboudait',
    'déboudant',
    'déboudées',
    'déboudent',
    'déboudera',
    'déboudiez',
    'déboudons',
    'débouille',
    'débouilli',
    'déboulais',
    'déboulait',
    'déboulant',
    'déboulées',
    'déboulent',
    'déboulera',
    'débouliez',
    'déboulons',
    'débouquai',
    'débouquas',
    'débouquât',
    'débouquer',
    'débouques',
    'débouquez',
    'débourbai',
    'débourbas',
    'débourbât',
    'débourbée',
    'débourber',
    'débourbes',
    'débourbés',
    'débourbez',
    'débourrai',
    'débourras',
    'débourrât',
    'débourrée',
    'débourrer',
    'débourres',
    'débourrés',
    'débourrez',
    'déboursai',
    'déboursas',
    'déboursât',
    'déboursée',
    'débourser',
    'débourses',
    'déboursés',
    'déboursez',
    'déboutais',
    'déboutait',
    'déboutant',
    'déboutées',
    'déboutent',
    'déboutera',
    'déboutiez',
    'déboutons',
    'débraient',
    'débraiera',
    'débrailla',
    'débraille',
    'débraillé',
    'débrancha',
    'débranche',
    'débranché',
    'débrayage',
    'débrayais',
    'débrayait',
    'débrayant',
    'débrayées',
    'débrayent',
    'débrayera',
    'débrayiez',
    'débrayons',
    'débridais',
    'débridait',
    'débridant',
    'débridées',
    'débrident',
    'débridera',
    'débridiez',
    'débridons',
    'débrochai',
    'débrochas',
    'débrochât',
    'débrochée',
    'débrocher',
    'débroches',
    'débrochés',
    'débrochez',
    'débuchais',
    'débuchait',
    'débuchant',
    'débuchées',
    'débuchent',
    'débuchera',
    'débuchiez',
    'débuchons',
    'débullais',
    'débullait',
    'débullant',
    'débullées',
    'débullent',
    'débullera',
    'débulliez',
    'débullons',
    'débusquai',
    'débusquas',
    'débusquât',
    'débusquée',
    'débusquer',
    'débusques',
    'débusqués',
    'débusquez',
    'débutâmes',
    'débutante',
    'débutants',
    'débutasse',
    'débutâtes',
    'débuterai',
    'débuteras',
    'débuterez',
    'débutions',
    'décacheta',
    'décacheté',
    'décadaire',
    'décadence',
    'décadente',
    'décadents',
    'décaèdres',
    'décaféina',
    'décaféine',
    'décaféiné',
    'décagonal',
    'décagones',
    'décaissai',
    'décaissas',
    'décaissât',
    'décaissée',
    'décaisser',
    'décaisses',
    'décaissés',
    'décaissez',
    'décalages',
    'décalâmes',
    'décalasse',
    'décalâtes',
    'décalerai',
    'décaleras',
    'décalerez',
    'décalions',
    'décalitre',
    'décalogue',
    'décalotta',
    'décalotte',
    'décalotté',
    'décalquai',
    'décalquas',
    'décalquât',
    'décalquée',
    'décalquer',
    'décalques',
    'décalqués',
    'décalquez',
    'décalvant',
    'décamètre',
    'décampais',
    'décampait',
    'décampant',
    'décampent',
    'décampera',
    'décampiez',
    'décampons',
    'décanales',
    'décanilla',
    'décanille',
    'décanillé',
    'décantais',
    'décantait',
    'décantant',
    'décantées',
    'décantent',
    'décantera',
    'décanteur',
    'décantiez',
    'décantons',
    'décapages',
    'décapâmes',
    'décapante',
    'décapants',
    'décapasse',
    'décapâtes',
    'décapelai',
    'décapelas',
    'décapelât',
    'décapelée',
    'décapeler',
    'décapelés',
    'décapelez',
    'décapelle',
    'décaperai',
    'décaperas',
    'décaperez',
    'décapeurs',
    'décapeuse',
    'décapions',
    'décapitai',
    'décapitas',
    'décapitât',
    'décapitée',
    'décapiter',
    'décapites',
    'décapités',
    'décapitez',
    'décapotai',
    'décapotas',
    'décapotât',
    'décapotée',
    'décapoter',
    'décapotes',
    'décapotés',
    'décapotez',
    'décapsula',
    'décapsule',
    'décapsulé',
    'décarbura',
    'décarbure',
    'décarburé',
    'décarrais',
    'décarrait',
    'décarrant',
    'décarrela',
    'décarrelé',
    'décarrent',
    'décarrera',
    'décarriez',
    'décarrons',
    'décathlon',
    'décatîmes',
    'décatirai',
    'décatiras',
    'décatirez',
    'décatisse',
    'décatîtes',
    'décausais',
    'décausait',
    'décausant',
    'décausées',
    'décausent',
    'décausera',
    'décausiez',
    'décausons',
    'décédâmes',
    'décédasse',
    'décédâtes',
    'décéderai',
    'décéderas',
    'décéderez',
    'décédions',
    'décelable',
    'décelâmes',
    'décelasse',
    'décelâtes',
    'décélérai',
    'décèlerai',
    'décéléras',
    'décèleras',
    'décélérât',
    'décélérer',
    'décélères',
    'décélérez',
    'décèlerez',
    'décelions',
    'décemment',
    'décemviri',
    'décemvirs',
    'décennale',
    'décennaux',
    'décennies',
    'décentrai',
    'décentras',
    'décentrât',
    'décentrée',
    'décentrer',
    'décentres',
    'décentrés',
    'décentrez',
    'déception',
    'décerclai',
    'décerclas',
    'décerclât',
    'décerclée',
    'décercler',
    'décercles',
    'décerclés',
    'décerclez',
    'décernais',
    'décernait',
    'décernant',
    'décernées',
    'décernent',
    'décernera',
    'décerniez',
    'décernons',
    'décessais',
    'décessait',
    'décessant',
    'décessées',
    'décessent',
    'décessera',
    'décessiez',
    'décessons',
    'décevante',
    'décevants',
    'décevions',
    'décevrais',
    'décevrait',
    'décevriez',
    'décevrons',
    'décevront',
    'déchaînai',
    'déchaînas',
    'déchaînât',
    'déchaînée',
    'déchaîner',
    'déchaînes',
    'déchaînés',
    'déchaînez',
    'déchantai',
    'déchantas',
    'déchantât',
    'déchanter',
    'déchantes',
    'déchantez',
    'déchapais',
    'déchapait',
    'déchapant',
    'déchapées',
    'déchapent',
    'déchapera',
    'déchapiez',
    'déchapons',
    'déchargea',
    'déchargée',
    'décharger',
    'décharges',
    'déchargés',
    'déchargez',
    'décharnai',
    'décharnas',
    'décharnât',
    'décharnée',
    'décharner',
    'décharnes',
    'décharnés',
    'décharnez',
    'déchaumai',
    'déchaumas',
    'déchaumât',
    'déchaumée',
    'déchaumer',
    'déchaumes',
    'déchaumés',
    'déchaumez',
    'déchaussa',
    'déchausse',
    'déchaussé',
    'déchéance',
    'déchiffra',
    'déchiffre',
    'déchiffré',
    'déchirais',
    'déchirait',
    'déchirant',
    'déchirées',
    'déchirent',
    'déchirera',
    'déchiriez',
    'déchirons',
    'déchirure',
    'déchoient',
    'déchoirai',
    'déchoiras',
    'déchoirez',
    'déchoyiez',
    'déchoyons',
    'déchromai',
    'déchromas',
    'déchromât',
    'déchromée',
    'déchromer',
    'déchromes',
    'déchromés',
    'déchromez',
    'déchurent',
    'déchusses',
    'décidable',
    'décidâmes',
    'décidasse',
    'décidâtes',
    'déciderai',
    'décideras',
    'déciderez',
    'décideurs',
    'décideuse',
    'décidions',
    'déciduale',
    'décigrade',
    'décilages',
    'décilitre',
    'décimales',
    'décimâmes',
    'décimasse',
    'décimâtes',
    'décimerai',
    'décimeras',
    'décimerez',
    'décimètre',
    'décimions',
    'décintrai',
    'décintras',
    'décintrât',
    'décintrée',
    'décintrer',
    'décintres',
    'décintrés',
    'décintrez',
    'décisions',
    'décisives',
    'décisoire',
    'déclamais',
    'déclamait',
    'déclamant',
    'déclamées',
    'déclament',
    'déclamera',
    'déclamiez',
    'déclamons',
    'déclarais',
    'déclarait',
    'déclarant',
    'déclarées',
    'déclarent',
    'déclarera',
    'déclariez',
    'déclarons',
    'déclassai',
    'déclassas',
    'déclassât',
    'déclassée',
    'déclasser',
    'déclasses',
    'déclassés',
    'déclassez',
    'déclaveta',
    'déclaveté',
    'déclencha',
    'déclenche',
    'déclenché',
    'déclinais',
    'déclinait',
    'déclinant',
    'déclinées',
    'déclinent',
    'déclinera',
    'décliniez',
    'déclinons',
    'déclivité',
    'déclorais',
    'déclorait',
    'décloriez',
    'déclorons',
    'décloront',
    'déclosent',
    'déclouais',
    'déclouait',
    'déclouant',
    'déclouées',
    'déclouent',
    'déclouera',
    'déclouiez',
    'déclouons',
    'décochage',
    'décochais',
    'décochait',
    'décochant',
    'décochées',
    'décochent',
    'décochera',
    'décochiez',
    'décochons',
    'décoction',
    'décodages',
    'décodâmes',
    'décodasse',
    'décodâtes',
    'décoderai',
    'décoderas',
    'décoderez',
    'décodeurs',
    'décodeuse',
    'décodions',
    'décoffrai',
    'décoffras',
    'décoffrât',
    'décoffrée',
    'décoffrer',
    'décoffres',
    'décoffrés',
    'décoffrez',
    'décoiffai',
    'décoiffas',
    'décoiffât',
    'décoiffée',
    'décoiffer',
    'décoiffes',
    'décoiffés',
    'décoiffez',
    'décoinçai',
    'décoinças',
    'décoinçât',
    'décoincée',
    'décoincer',
    'décoinces',
    'décoincés',
    'décoincez',
    'déçoivent',
    'décolérai',
    'décoléras',
    'décolérât',
    'décolérer',
    'décolères',
    'décolérez',
    'décollage',
    'décollais',
    'décollait',
    'décollant',
    'décollées',
    'décollent',
    'décollera',
    'décolleta',
    'décolleté',
    'décolliez',
    'décollons',
    'décolorai',
    'décoloras',
    'décolorât',
    'décolorée',
    'décolorer',
    'décolores',
    'décolorés',
    'décolorez',
    'décombres',
    'décomposa',
    'décompose',
    'décomposé',
    'décomptai',
    'décomptas',
    'décomptât',
    'décomptée',
    'décompter',
    'décomptes',
    'décomptés',
    'décomptez',
    'déconfira',
    'déconfire',
    'déconfise',
    'déconfite',
    'déconfits',
    'décongela',
    'décongelé',
    'décongèle',
    'déconnais',
    'déconnait',
    'déconnant',
    'déconnent',
    'déconnera',
    'déconniez',
    'déconnons',
    'décorâmes',
    'décorasse',
    'décorâtes',
    'décoratif',
    'décordais',
    'décordait',
    'décordant',
    'décordées',
    'décordent',
    'décordera',
    'décordiez',
    'décordons',
    'décorerai',
    'décoreras',
    'décorerez',
    'décorions',
    'décornais',
    'décornait',
    'décornant',
    'décornées',
    'décornent',
    'décornera',
    'décorniez',
    'décornons',
    'découchai',
    'découchas',
    'découchât',
    'découcher',
    'découches',
    'découchez',
    'découdrai',
    'découdras',
    'découdrez',
    'découlais',
    'découlait',
    'découlant',
    'découlent',
    'découlera',
    'découliez',
    'découlons',
    'découpage',
    'découpais',
    'découpait',
    'découpant',
    'découpées',
    'découpent',
    'découpera',
    'découpeur',
    'découpiez',
    'découplai',
    'découplas',
    'découplât',
    'découplée',
    'découpler',
    'découples',
    'découplés',
    'découplez',
    'découpoir',
    'découpons',
    'découpure',
    'décourage',
    'découragé',
    'décousais',
    'décousait',
    'décousant',
    'décousent',
    'décousiez',
    'décousons',
    'décousues',
    'décousure',
    'découvert',
    'découvres',
    'découvrez',
    'découvrir',
    'découvris',
    'découvrit',
    'découvrît',
    'découvrue',
    'découvrus',
    'décrassai',
    'décrassas',
    'décrassât',
    'décrassée',
    'décrasser',
    'décrasses',
    'décrassés',
    'décrassez',
    'décrédita',
    'décrédite',
    'décrédité',
    'décrément',
    'décrêpage',
    'décrêpais',
    'décrêpait',
    'décrêpant',
    'décrêpées',
    'décrêpent',
    'décrêpera',
    'décrépies',
    'décrêpiez',
    'décrépira',
    'décrépita',
    'décrépite',
    'décrépité',
    'décrêpons',
    'décrétais',
    'décrétait',
    'décrétant',
    'décrétées',
    'décrètent',
    'décrétera',
    'décrétiez',
    'décrétons',
    'décreusai',
    'décreusas',
    'décreusât',
    'décreusée',
    'décreuser',
    'décreuses',
    'décreusés',
    'décreusez',
    'décriâmes',
    'décriasse',
    'décriâtes',
    'décrierai',
    'décrieras',
    'décrierez',
    'décriions',
    'décrirais',
    'décrirait',
    'décririez',
    'décrirons',
    'décriront',
    'décrispai',
    'décrispas',
    'décrispât',
    'décrispée',
    'décrisper',
    'décrispes',
    'décrispés',
    'décrispez',
    'décrivais',
    'décrivait',
    'décrivant',
    'décrivent',
    'décriviez',
    'décrivons',
    'décrochai',
    'décrochas',
    'décrochât',
    'décrochée',
    'décrocher',
    'décroches',
    'décrochés',
    'décrochez',
    'décroisai',
    'décroisas',
    'décroisât',
    'décroisée',
    'décroiser',
    'décroises',
    'décroisés',
    'décroisez',
    'décroisse',
    'décroîtra',
    'décroître',
    'décrottai',
    'décrottas',
    'décrottât',
    'décrottée',
    'décrotter',
    'décrottes',
    'décrottés',
    'décrottez',
    'décroûtai',
    'décroûtas',
    'décroûtât',
    'décroûtée',
    'décroûter',
    'décroûtes',
    'décroûtés',
    'décroûtez',
    'décruages',
    'décruâmes',
    'décruasse',
    'décruâtes',
    'décruerai',
    'décrueras',
    'décruerez',
    'décruions',
    'décrurent',
    'décrusage',
    'décrusais',
    'décrusait',
    'décrusant',
    'décrusées',
    'décrusent',
    'décrusera',
    'décrusiez',
    'décrusons',
    'décrusses',
    'décryptai',
    'décryptas',
    'décryptât',
    'décryptée',
    'décrypter',
    'décryptes',
    'décryptés',
    'décryptez',
    'décubitus',
    'décuivrai',
    'décuivras',
    'décuivrât',
    'décuivrée',
    'décuivrer',
    'décuivres',
    'décuivrés',
    'décuivrez',
    'déculassa',
    'déculasse',
    'déculassé',
    'déculotta',
    'déculotte',
    'déculotté',
    'décuplais',
    'décuplait',
    'décuplant',
    'décuplées',
    'décuplent',
    'décuplera',
    'décupliez',
    'décuplons',
    'décurions',
    'décurrent',
    'décussées',
    'déçussent',
    'déçussiez',
    'décuvages',
    'décuvâmes',
    'décuvasse',
    'décuvâtes',
    'décuverai',
    'décuveras',
    'décuverez',
    'décuvions',
    'dédaignai',
    'dédaignas',
    'dédaignât',
    'dédaignée',
    'dédaigner',
    'dédaignes',
    'dédaignés',
    'dédaignez',
    'dédaléens',
    'dédiaient',
    'dédiasses',
    'dédicaçai',
    'dédicaças',
    'dédicaçât',
    'dédicacée',
    'dédicacer',
    'dédicaces',
    'dédicacés',
    'dédicacez',
    'dédierais',
    'dédierait',
    'dédièrent',
    'dédieriez',
    'dédierons',
    'dédieront',
    'dédirions',
    'dédisions',
    'dédissent',
    'dédissiez',
    'dédommage',
    'dédommagé',
    'dédorages',
    'dédorâmes',
    'dédorasse',
    'dédorâtes',
    'dédorerai',
    'dédoreras',
    'dédorerez',
    'dédorions',
    'dédouanai',
    'dédouanas',
    'dédouanât',
    'dédouanée',
    'dédouaner',
    'dédouanes',
    'dédouanés',
    'dédouanez',
    'dédoublai',
    'dédoublas',
    'dédoublât',
    'dédoublée',
    'dédoubler',
    'dédoubles',
    'dédoublés',
    'dédoublez',
    'déductifs',
    'déduction',
    'déductive',
    'déduirais',
    'déduirait',
    'déduiriez',
    'déduirons',
    'déduiront',
    'déduisais',
    'déduisait',
    'déduisant',
    'déduisent',
    'déduisiez',
    'déduisons',
    'défailles',
    'défaillez',
    'défaillie',
    'défaillir',
    'défaillis',
    'défaillit',
    'défaillît',
    'défaisais',
    'défaisait',
    'défaisant',
    'défaisiez',
    'défaisons',
    'défalquai',
    'défalquas',
    'défalquât',
    'défalquée',
    'défalquer',
    'défalques',
    'défalqués',
    'défalquez',
    'défardais',
    'défardait',
    'défardant',
    'défardées',
    'défardent',
    'défardera',
    'défardiez',
    'défardons',
    'défassent',
    'défassiez',
    'défatigua',
    'défatigue',
    'défatigué',
    'défaufila',
    'défaufile',
    'défaufilé',
    'défaussai',
    'défaussas',
    'défaussât',
    'défaussée',
    'défausser',
    'défausses',
    'défaussés',
    'défaussez',
    'défaveurs',
    'défectifs',
    'défection',
    'défective',
    'défendais',
    'défendait',
    'défendant',
    'défendent',
    'défendeur',
    'défendiez',
    'défendons',
    'défendrai',
    'défendras',
    'défendrez',
    'défendues',
    'défenseur',
    'défensifs',
    'défensive',
    'déféquais',
    'déféquait',
    'déféquant',
    'déféquées',
    'défèquent',
    'déféquera',
    'déféquiez',
    'déféquons',
    'déférâmes',
    'déférasse',
    'déférâtes',
    'déférence',
    'déférente',
    'déférents',
    'déférerai',
    'déféreras',
    'déférerez',
    'déferions',
    'déférions',
    'déferlais',
    'déferlait',
    'déferlant',
    'déferlées',
    'déferlent',
    'déferlera',
    'déferliez',
    'déferlons',
    'déferrage',
    'déferrais',
    'déferrait',
    'déferrant',
    'déferrées',
    'déferrent',
    'déferrera',
    'déferriez',
    'déferrisa',
    'déferrise',
    'déferrisé',
    'déferrons',
    'déferrure',
    'défeuilla',
    'défeuille',
    'défeuillé',
    'défeutrai',
    'défeutras',
    'défeutrât',
    'défeutrée',
    'défeutrer',
    'défeutres',
    'défeutrés',
    'défeutrez',
    'défiaient',
    'défiances',
    'défiantes',
    'défiasses',
    'défibrage',
    'défibrais',
    'défibrait',
    'défibrant',
    'défibrées',
    'défibrent',
    'défibrera',
    'défibreur',
    'défibriez',
    'défibrons',
    'déficelai',
    'déficelas',
    'déficelât',
    'déficelée',
    'déficeler',
    'déficelés',
    'déficelez',
    'déficelle',
    'défichais',
    'défichait',
    'défichant',
    'défichées',
    'défichent',
    'défichera',
    'défichiez',
    'défichons',
    'déficient',
    'défierais',
    'défierait',
    'défièrent',
    'défieriez',
    'défierons',
    'défieront',
    'défigeais',
    'défigeait',
    'défigeant',
    'défigeons',
    'défigerai',
    'défigeras',
    'défigerez',
    'défigions',
    'défigurai',
    'défiguras',
    'défigurât',
    'défigurée',
    'défigurer',
    'défigures',
    'défigurés',
    'défigurez',
    'défilades',
    'défilages',
    'défilâmes',
    'défilasse',
    'défilâtes',
    'défilerai',
    'défileras',
    'défilerez',
    'défileuse',
    'défilions',
    'définîmes',
    'définirai',
    'définiras',
    'définirez',
    'définisse',
    'définîtes',
    'définitif',
    'défissent',
    'défissiez',
    'déflagrai',
    'déflagras',
    'déflagrât',
    'déflagrer',
    'déflagres',
    'déflagrez',
    'déflaquai',
    'déflaquas',
    'déflaquât',
    'déflaquer',
    'déflaques',
    'déflaquez',
    'déflation',
    'défleurie',
    'défleurir',
    'défleuris',
    'défleurit',
    'défleurît',
    'déflorais',
    'déflorait',
    'déflorant',
    'déflorées',
    'déflorent',
    'déflorera',
    'défloriez',
    'déflorons',
    'défluents',
    'défoliais',
    'défoliait',
    'défoliant',
    'défoliées',
    'défolient',
    'défoliera',
    'défoliiez',
    'défolions',
    'défonçage',
    'défonçais',
    'défonçait',
    'défonçant',
    'défoncées',
    'défoncent',
    'défoncera',
    'défonciez',
    'défonçons',
    'déforçais',
    'déforçait',
    'déforçant',
    'déforcées',
    'déforcent',
    'déforcera',
    'déforciez',
    'déforçons',
    'déformais',
    'déformait',
    'déformant',
    'déformées',
    'déforment',
    'déformera',
    'déformiez',
    'déformons',
    'défoulais',
    'défoulait',
    'défoulant',
    'défoulées',
    'défoulent',
    'défoulera',
    'défouliez',
    'défoulons',
    'défournai',
    'défournas',
    'défournât',
    'défournée',
    'défourner',
    'défournes',
    'défournés',
    'défournez',
    'défourrai',
    'défourras',
    'défourrât',
    'défourrée',
    'défourrer',
    'défourres',
    'défourrés',
    'défourrez',
    'défraîchi',
    'défraient',
    'défraiera',
    'défranchi',
    'défrayais',
    'défrayait',
    'défrayant',
    'défrayées',
    'défrayent',
    'défrayera',
    'défrayiez',
    'défrayons',
    'défrettai',
    'défrettas',
    'défrettât',
    'défrettée',
    'défretter',
    'défrettes',
    'défrettés',
    'défrettez',
    'défrichai',
    'défrichas',
    'défrichât',
    'défrichée',
    'défricher',
    'défriches',
    'défrichés',
    'défrichez',
    'défringua',
    'défringue',
    'défringué',
    'défripais',
    'défripait',
    'défripant',
    'défripées',
    'défripent',
    'défripera',
    'défripiez',
    'défripons',
    'défrisais',
    'défrisait',
    'défrisant',
    'défrisées',
    'défrisent',
    'défrisera',
    'défrisiez',
    'défrisons',
    'défroissa',
    'défroisse',
    'défroissé',
    'défronçai',
    'défronças',
    'défronçât',
    'défroncée',
    'défroncer',
    'défronces',
    'défroncés',
    'défroncez',
    'défroquai',
    'défroquas',
    'défroquât',
    'défroquée',
    'défroquer',
    'défroques',
    'défroqués',
    'défroquez',
    'défruitai',
    'défruitas',
    'défruitât',
    'défruitée',
    'défruiter',
    'défruites',
    'défruités',
    'défruitez',
    'dégageais',
    'dégageait',
    'dégageant',
    'dégageons',
    'dégagerai',
    'dégageras',
    'dégagerez',
    'dégagions',
    'dégainais',
    'dégainait',
    'dégainant',
    'dégainées',
    'dégainent',
    'dégainera',
    'dégainiez',
    'dégainons',
    'dégalonna',
    'dégalonne',
    'dégalonné',
    'dégantais',
    'dégantait',
    'dégantant',
    'dégantées',
    'dégantent',
    'dégantera',
    'dégantiez',
    'dégantons',
    'dégarnies',
    'dégarnira',
    'dégauchie',
    'dégauchir',
    'dégauchis',
    'dégauchit',
    'dégauchît',
    'dégazages',
    'dégazâmes',
    'dégazasse',
    'dégazâtes',
    'dégazerai',
    'dégazeras',
    'dégazerez',
    'dégazions',
    'dégazonna',
    'dégazonne',
    'dégazonné',
    'dégelâmes',
    'dégelasse',
    'dégelâtes',
    'dégèlerai',
    'dégèleras',
    'dégèlerez',
    'dégelions',
    'dégénérai',
    'dégénéras',
    'dégénérât',
    'dégénérer',
    'dégénères',
    'dégénérez',
    'dégermais',
    'dégermait',
    'dégermant',
    'dégermées',
    'dégerment',
    'dégermera',
    'dégermiez',
    'dégermons',
    'dégîtâmes',
    'dégîtasse',
    'dégîtâtes',
    'dégîterai',
    'dégîteras',
    'dégîterez',
    'dégîtions',
    'dégivrage',
    'dégivrais',
    'dégivrait',
    'dégivrant',
    'dégivrées',
    'dégivrent',
    'dégivrera',
    'dégivreur',
    'dégivriez',
    'dégivrons',
    'déglaçais',
    'déglaçait',
    'déglaçant',
    'déglacées',
    'déglacent',
    'déglacera',
    'déglaciez',
    'déglaçons',
    'déglingua',
    'déglingue',
    'déglingué',
    'dégluâmes',
    'dégluasse',
    'dégluâtes',
    'dégluerai',
    'déglueras',
    'dégluerez',
    'dégluions',
    'dégluties',
    'déglutira',
    'dégobilla',
    'dégobille',
    'dégobillé',
    'dégoisais',
    'dégoisait',
    'dégoisant',
    'dégoisées',
    'dégoisent',
    'dégoisera',
    'dégoisiez',
    'dégoisons',
    'dégommage',
    'dégommais',
    'dégommait',
    'dégommant',
    'dégommées',
    'dégomment',
    'dégommera',
    'dégommiez',
    'dégommons',
    'dégondais',
    'dégondait',
    'dégondant',
    'dégondées',
    'dégondent',
    'dégondera',
    'dégondiez',
    'dégondons',
    'dégonflai',
    'dégonflas',
    'dégonflât',
    'dégonflée',
    'dégonfler',
    'dégonfles',
    'dégonflés',
    'dégonflez',
    'dégorgeai',
    'dégorgeas',
    'dégorgeât',
    'dégorgées',
    'dégorgent',
    'dégorgera',
    'dégorgiez',
    'dégotâmes',
    'dégotasse',
    'dégotâtes',
    'dégoterai',
    'dégoteras',
    'dégoterez',
    'dégotions',
    'dégottais',
    'dégottait',
    'dégottant',
    'dégottées',
    'dégottent',
    'dégottera',
    'dégottiez',
    'dégottons',
    'dégoulina',
    'dégouline',
    'dégouliné',
    'dégourdie',
    'dégourdir',
    'dégourdis',
    'dégourdit',
    'dégourdît',
    'dégoûtais',
    'dégoûtait',
    'dégoûtant',
    'dégoûtées',
    'dégoûtent',
    'dégoûtera',
    'dégoûtiez',
    'dégoûtons',
    'dégouttai',
    'dégouttas',
    'dégouttât',
    'dégouttée',
    'dégoutter',
    'dégouttes',
    'dégouttés',
    'dégouttez',
    'dégradais',
    'dégradait',
    'dégradant',
    'dégradées',
    'dégradent',
    'dégradera',
    'dégradiez',
    'dégradons',
    'dégrafais',
    'dégrafait',
    'dégrafant',
    'dégrafées',
    'dégrafent',
    'dégrafera',
    'dégrafiez',
    'dégrafons',
    'dégraissa',
    'dégraisse',
    'dégraissé',
    'dégravoie',
    'dégravoya',
    'dégravoyé',
    'dégréâmes',
    'dégréasse',
    'dégréâtes',
    'dégréerai',
    'dégréeras',
    'dégréerez',
    'dégréions',
    'dégressif',
    'dégrevais',
    'dégrevait',
    'dégrevant',
    'dégrevées',
    'dégrèvent',
    'dégrèvera',
    'dégreviez',
    'dégrevons',
    'dégriffée',
    'dégriffés',
    'dégrippai',
    'dégrippas',
    'dégrippât',
    'dégrippée',
    'dégripper',
    'dégrippes',
    'dégrippés',
    'dégrippez',
    'dégrisais',
    'dégrisait',
    'dégrisant',
    'dégrisées',
    'dégrisent',
    'dégrisera',
    'dégrisiez',
    'dégrisons',
    'dégrossai',
    'dégrossas',
    'dégrossât',
    'dégrossée',
    'dégrosser',
    'dégrosses',
    'dégrossés',
    'dégrossez',
    'dégrossie',
    'dégrossir',
    'dégrossis',
    'dégrossit',
    'dégrossît',
    'déguerpie',
    'déguerpir',
    'déguerpis',
    'déguerpit',
    'déguerpît',
    'dégueulai',
    'dégueulas',
    'dégueulât',
    'dégueulée',
    'dégueuler',
    'dégueules',
    'dégueulés',
    'dégueulez',
    'déguisais',
    'déguisait',
    'déguisant',
    'déguisées',
    'déguisent',
    'déguisera',
    'déguisiez',
    'déguisons',
    'dégurgita',
    'dégurgite',
    'dégurgité',
    'dégustais',
    'dégustait',
    'dégustant',
    'dégustées',
    'dégustent',
    'dégustera',
    'dégustiez',
    'dégustons',
    'déhalâmes',
    'déhalasse',
    'déhalâtes',
    'déhalerai',
    'déhaleras',
    'déhalerez',
    'déhalions',
    'déhanchai',
    'déhanchas',
    'déhanchât',
    'déhanchée',
    'déhancher',
    'déhanches',
    'déhanchés',
    'déhanchez',
    'déhardais',
    'déhardait',
    'déhardant',
    'déhardées',
    'déhardent',
    'déhardera',
    'déhardiez',
    'déhardons',
    'déhiscent',
    'déhottais',
    'déhottait',
    'déhottant',
    'déhottées',
    'déhottent',
    'déhottera',
    'déhottiez',
    'déhottons',
    'déhouilla',
    'déhouille',
    'déhouillé',
    'déictique',
    'déifiâmes',
    'déifiasse',
    'déifiâtes',
    'déifierai',
    'déifieras',
    'déifierez',
    'déifiions',
    'déjantais',
    'déjantait',
    'déjantant',
    'déjantées',
    'déjantent',
    'déjantera',
    'déjantiez',
    'déjantons',
    'déjaugeai',
    'déjaugeas',
    'déjaugeât',
    'déjaugent',
    'déjaugera',
    'déjaugiez',
    'déjaunira',
    'déjection',
    'déjetâmes',
    'déjetasse',
    'déjetâtes',
    'déjetions',
    'déjettent',
    'déjettera',
    'déjeunais',
    'déjeunait',
    'déjeunant',
    'déjeunent',
    'déjeunera',
    'déjeuners',
    'déjeuniez',
    'déjeunons',
    'déjouâmes',
    'déjouasse',
    'déjouâtes',
    'déjouerai',
    'déjoueras',
    'déjouerez',
    'déjouions',
    'déjuchais',
    'déjuchait',
    'déjuchant',
    'déjuchées',
    'déjuchent',
    'déjuchera',
    'déjuchiez',
    'déjuchons',
    'déjugeais',
    'déjugeait',
    'déjugeant',
    'déjugeons',
    'déjugerai',
    'déjugeras',
    'déjugerez',
    'déjugions',
    'délabrais',
    'délabrait',
    'délabrant',
    'délabrées',
    'délabrent',
    'délabrera',
    'délabriez',
    'délabrons',
    'délaçâmes',
    'délaçasse',
    'délaçâtes',
    'délacerai',
    'délaceras',
    'délacerez',
    'délacions',
    'délaierai',
    'délaieras',
    'délaierez',
    'délainage',
    'délainais',
    'délainait',
    'délainant',
    'délainées',
    'délainent',
    'délainera',
    'délainiez',
    'délainons',
    'délaissai',
    'délaissas',
    'délaissât',
    'délaissée',
    'délaisser',
    'délaisses',
    'délaissés',
    'délaissez',
    'délaitais',
    'délaitait',
    'délaitant',
    'délaitées',
    'délaitent',
    'délaitera',
    'délaitiez',
    'délaitons',
    'délardais',
    'délardait',
    'délardant',
    'délardées',
    'délardent',
    'délardera',
    'délardiez',
    'délardons',
    'délassais',
    'délassait',
    'délassant',
    'délassées',
    'délassent',
    'délassera',
    'délassiez',
    'délassons',
    'délateurs',
    'délations',
    'délatrice',
    'délattais',
    'délattait',
    'délattant',
    'délattées',
    'délattent',
    'délattera',
    'délattiez',
    'délattons',
    'délavages',
    'délavâmes',
    'délavasse',
    'délavâtes',
    'délaverai',
    'délaveras',
    'délaverez',
    'délavions',
    'délayages',
    'délayâmes',
    'délayasse',
    'délayâtes',
    'délayerai',
    'délayeras',
    'délayerez',
    'délayions',
    'délébiles',
    'délectais',
    'délectait',
    'délectant',
    'délectées',
    'délectent',
    'délectera',
    'délectiez',
    'délectons',
    'délégants',
    'déléguais',
    'déléguait',
    'déléguant',
    'déléguées',
    'délèguent',
    'déléguera',
    'déléguiez',
    'déléguons',
    'délestage',
    'délestais',
    'délestait',
    'délestant',
    'délestées',
    'délestent',
    'délestera',
    'délestiez',
    'délestons',
    'délétères',
    'délétions',
    'déliaient',
    'déliasses',
    'délibérai',
    'délibéras',
    'délibérât',
    'délibérée',
    'délibérer',
    'délibérés',
    'délibères',
    'délibérez',
    'délicates',
    'délicieux',
    'déliement',
    'délierais',
    'délierait',
    'délièrent',
    'délieriez',
    'délierons',
    'délieront',
    'délimitai',
    'délimitas',
    'délimitât',
    'délimitée',
    'délimiter',
    'délimites',
    'délimités',
    'délimitez',
    'délinéais',
    'délinéait',
    'délinéant',
    'délinéées',
    'délinéent',
    'délinéera',
    'délinéiez',
    'délinéons',
    'délirâmes',
    'délirante',
    'délirants',
    'délirasse',
    'délirâtes',
    'délirerai',
    'délireras',
    'délirerez',
    'délirions',
    'délissage',
    'délissais',
    'délissait',
    'délissant',
    'délissées',
    'délissent',
    'délissera',
    'délissiez',
    'délissons',
    'délitages',
    'délitâmes',
    'délitasse',
    'délitâtes',
    'déliterai',
    'déliteras',
    'déliterez',
    'délitions',
    'délivrais',
    'délivrait',
    'délivrant',
    'délivrées',
    'délivrent',
    'délivrera',
    'délivreur',
    'délivriez',
    'délivrons',
    'délogeais',
    'délogeait',
    'délogeant',
    'délogeons',
    'délogerai',
    'délogeras',
    'délogerez',
    'délogions',
    'déloquais',
    'déloquait',
    'déloquant',
    'déloquées',
    'déloquent',
    'déloquera',
    'déloquiez',
    'déloquons',
    'délovâmes',
    'délovasse',
    'délovâtes',
    'déloverai',
    'déloveras',
    'déloverez',
    'délovions',
    'déloyales',
    'déloyauté',
    'deltaïque',
    'deltoïdes',
    'délurâmes',
    'délurasse',
    'délurâtes',
    'délurerai',
    'délureras',
    'délurerez',
    'délurions',
    'délustrai',
    'délustras',
    'délustrât',
    'délustrée',
    'délustrer',
    'délustres',
    'délustrés',
    'délustrez',
    'délutages',
    'délutâmes',
    'délutasse',
    'délutâtes',
    'déluterai',
    'déluteras',
    'déluterez',
    'délutions',
    'démaçonna',
    'démaçonne',
    'démaçonné',
    'démagogie',
    'démagogue',
    'démaigrie',
    'démaigrir',
    'démaigris',
    'démaigrit',
    'démaigrît',
    'démaillai',
    'démaillas',
    'démaillât',
    'démaillée',
    'démailler',
    'démailles',
    'démaillés',
    'démaillez',
    'démanchai',
    'démanchas',
    'démanchât',
    'démanchée',
    'démancher',
    'démanches',
    'démanchés',
    'démanchez',
    'demandais',
    'demandait',
    'demandant',
    'demandées',
    'demandent',
    'demandera',
    'demandeur',
    'demandiez',
    'demandons',
    'démangeai',
    'démangeas',
    'démangeât',
    'démangées',
    'démangent',
    'démangera',
    'démangiez',
    'démantela',
    'démantelé',
    'démantèle',
    'démarchai',
    'démarchas',
    'démarchât',
    'démarchée',
    'démarcher',
    'démarches',
    'démarchés',
    'démarchez',
    'démariais',
    'démariait',
    'démariant',
    'démariées',
    'démarient',
    'démariera',
    'démariiez',
    'démarions',
    'démarquai',
    'démarquas',
    'démarquât',
    'démarquée',
    'démarquer',
    'démarques',
    'démarqués',
    'démarquez',
    'démarrage',
    'démarrais',
    'démarrait',
    'démarrant',
    'démarrées',
    'démarrent',
    'démarrera',
    'démarreur',
    'démarriez',
    'démarrons',
    'démasclai',
    'démasclas',
    'démasclât',
    'démasclée',
    'démascler',
    'démascles',
    'démasclés',
    'démasclez',
    'démasquai',
    'démasquas',
    'démasquât',
    'démasquée',
    'démasquer',
    'démasques',
    'démasqués',
    'démasquez',
    'démâtages',
    'démâtâmes',
    'démâtasse',
    'démâtâtes',
    'démâterai',
    'démâteras',
    'démâterez',
    'démâtions',
    'démazouta',
    'démazoute',
    'démazouté',
    'déméchage',
    'démêlages',
    'démêlâmes',
    'démêlasse',
    'démêlâtes',
    'démêlerai',
    'démêleras',
    'démêlerez',
    'démêlions',
    'démêloirs',
    'démêlures',
    'démembrai',
    'démembras',
    'démembrât',
    'démembrée',
    'démembrer',
    'démembres',
    'démembrés',
    'démembrez',
    'déménagea',
    'déménagée',
    'déménager',
    'déménages',
    'déménagés',
    'déménagez',
    'démenâmes',
    'démenasse',
    'démenâtes',
    'démènerai',
    'démèneras',
    'démènerez',
    'démenions',
    'démentais',
    'démentait',
    'démentant',
    'démentent',
    'démentiel',
    'démenties',
    'démentiez',
    'démentira',
    'démentons',
    'démerdais',
    'démerdait',
    'démerdant',
    'démerdées',
    'démerdent',
    'démerdera',
    'démerdiez',
    'démerdons',
    'déméritai',
    'déméritas',
    'déméritât',
    'démériter',
    'démérites',
    'déméritez',
    'démesurée',
    'démesures',
    'démesurés',
    'démettais',
    'démettait',
    'démettant',
    'démettent',
    'démettiez',
    'démettons',
    'démettrai',
    'démettras',
    'démettrez',
    'démeublai',
    'démeublas',
    'démeublât',
    'démeublée',
    'démeubler',
    'démeubles',
    'démeublés',
    'démeublez',
    'demeurais',
    'demeurait',
    'demeurant',
    'demeurées',
    'demeurent',
    'demeurera',
    'demeuriez',
    'demeurons',
    'demi-clef',
    'demi-dieu',
    'démiellai',
    'démiellas',
    'démiellât',
    'démiellée',
    'démieller',
    'démielles',
    'démiellés',
    'démiellez',
    'demi-fine',
    'demi-fins',
    'demi-fond',
    'demi-gros',
    'demi-jour',
    'demi-lune',
    'demi-maux',
    'demi-mort',
    'déminages',
    'déminâmes',
    'déminasse',
    'déminâtes',
    'déminerai',
    'démineras',
    'déminerez',
    'démineurs',
    'déminions',
    'demi-plan',
    'demi-sang',
    'démissent',
    'démissiez',
    'démission',
    'demi-tige',
    'demi-tons',
    'demi-tour',
    'démiurges',
    'démocrate',
    'démodâmes',
    'démodasse',
    'démodâtes',
    'démoderai',
    'démoderas',
    'démoderez',
    'démodions',
    'démodulai',
    'démodulas',
    'démodulât',
    'démodulée',
    'démoduler',
    'démodules',
    'démodulés',
    'démodulez',
    'démolîmes',
    'démolirai',
    'démoliras',
    'démolirez',
    'démolisse',
    'démolîtes',
    'démonisme',
    'démontage',
    'démontais',
    'démontait',
    'démontant',
    'démontées',
    'démontent',
    'démontera',
    'démontiez',
    'démontons',
    'démontrai',
    'démontras',
    'démontrât',
    'démontrée',
    'démontrer',
    'démontres',
    'démontrés',
    'démontrez',
    'démordais',
    'démordait',
    'démordant',
    'démordent',
    'démordiez',
    'démordons',
    'démordrai',
    'démordras',
    'démordrez',
    'démordues',
    'démotique',
    'démotivai',
    'démotivas',
    'démotivât',
    'démotivée',
    'démotiver',
    'démotives',
    'démotivés',
    'démotivez',
    'démoulage',
    'démoulais',
    'démoulait',
    'démoulant',
    'démoulées',
    'démoulent',
    'démoulera',
    'démouliez',
    'démoulons',
    'démunîmes',
    'démunirai',
    'démuniras',
    'démunirez',
    'démunisse',
    'démunîtes',
    'démurâmes',
    'démurasse',
    'démurâtes',
    'démurerai',
    'démureras',
    'démurerez',
    'démurgeai',
    'démurgeas',
    'démurgeât',
    'démurgées',
    'démurgent',
    'démurgera',
    'démurgiez',
    'démurions',
    'démuselai',
    'démuselas',
    'démuselât',
    'démuselée',
    'démuseler',
    'démuselés',
    'démuselez',
    'démuselle',
    'dénanties',
    'dénantira',
    'dénattais',
    'dénattait',
    'dénattant',
    'dénattées',
    'dénattent',
    'dénattera',
    'dénattiez',
    'dénattons',
    'dénaturai',
    'dénaturas',
    'dénaturât',
    'dénaturée',
    'dénaturer',
    'dénatures',
    'dénaturés',
    'dénaturez',
    'dendrites',
    'déneigeai',
    'déneigeas',
    'déneigeât',
    'déneigées',
    'déneigent',
    'déneigera',
    'déneigiez',
    'dénervais',
    'dénervait',
    'dénervant',
    'dénervées',
    'dénervent',
    'dénervera',
    'dénerviez',
    'dénervons',
    'déniaient',
    'déniaisai',
    'déniaisas',
    'déniaisât',
    'déniaisée',
    'déniaiser',
    'déniaises',
    'déniaisés',
    'déniaisez',
    'déniasses',
    'dénichais',
    'dénichait',
    'dénichant',
    'dénichées',
    'dénichent',
    'dénichera',
    'dénicheur',
    'dénichiez',
    'dénichons',
    'dénickela',
    'dénickele',
    'dénickelé',
    'dénierais',
    'dénierait',
    'dénièrent',
    'dénieriez',
    'dénierons',
    'dénieront',
    'dénigrais',
    'dénigrait',
    'dénigrant',
    'dénigrées',
    'dénigrent',
    'dénigrera',
    'dénigreur',
    'dénigriez',
    'dénigrons',
    'dénitrais',
    'dénitrait',
    'dénitrant',
    'dénitrées',
    'dénitrent',
    'dénitrera',
    'dénitriez',
    'dénitrons',
    'dénivelai',
    'dénivelas',
    'dénivelât',
    'dénivelée',
    'déniveler',
    'dénivelés',
    'dénivelez',
    'dénivelle',
    'dénoierai',
    'dénoieras',
    'dénoierez',
    'dénombrai',
    'dénombras',
    'dénombrât',
    'dénombrée',
    'dénombrer',
    'dénombres',
    'dénombrés',
    'dénombrez',
    'dénommais',
    'dénommait',
    'dénommant',
    'dénommées',
    'dénomment',
    'dénommera',
    'dénommiez',
    'dénommons',
    'dénonçais',
    'dénonçait',
    'dénonçant',
    'dénoncées',
    'dénoncent',
    'dénoncera',
    'dénonciez',
    'dénonçons',
    'dénotâmes',
    'dénotasse',
    'dénotâtes',
    'dénoterai',
    'dénoteras',
    'dénoterez',
    'dénotions',
    'dénouâmes',
    'dénouasse',
    'dénouâtes',
    'dénouerai',
    'dénoueras',
    'dénouerez',
    'dénouions',
    'dénoyâmes',
    'dénoyasse',
    'dénoyâtes',
    'dénoyauta',
    'dénoyaute',
    'dénoyauté',
    'dénoyions',
    'dentaires',
    'dentelais',
    'dentelait',
    'dentelant',
    'dentelées',
    'denteliez',
    'dentelles',
    'dentelons',
    'dentelure',
    'denticule',
    'dentistes',
    'dentition',
    'dénuaient',
    'dénuasses',
    'dénudâmes',
    'dénudasse',
    'dénudâtes',
    'dénuderai',
    'dénuderas',
    'dénuderez',
    'dénudions',
    'dénuement',
    'dénuerais',
    'dénuerait',
    'dénuèrent',
    'dénueriez',
    'dénuerons',
    'dénueront',
    'déodorant',
    'dépaillai',
    'dépaillas',
    'dépaillât',
    'dépaillée',
    'dépailler',
    'dépailles',
    'dépaillés',
    'dépaillez',
    'dépalissa',
    'dépalisse',
    'dépalissé',
    'dépannage',
    'dépannais',
    'dépannait',
    'dépannant',
    'dépannées',
    'dépannent',
    'dépannera',
    'dépanneur',
    'dépanniez',
    'dépannons',
    'dépaqueta',
    'dépaqueté',
    'déparâmes',
    'déparasse',
    'déparâtes',
    'déparerai',
    'dépareras',
    'déparerez',
    'dépariais',
    'dépariait',
    'dépariant',
    'dépariées',
    'déparient',
    'dépariera',
    'dépariiez',
    'déparions',
    'déparlais',
    'déparlait',
    'déparlant',
    'déparlent',
    'déparlera',
    'déparliez',
    'déparlons',
    'départage',
    'départagé',
    'départais',
    'départait',
    'départant',
    'départent',
    'départies',
    'départiez',
    'départira',
    'départons',
    'dépassais',
    'dépassait',
    'dépassant',
    'dépassées',
    'dépassent',
    'dépassera',
    'dépassiez',
    'dépassons',
    'dépavages',
    'dépavâmes',
    'dépavasse',
    'dépavâtes',
    'dépaverai',
    'dépaveras',
    'dépaverez',
    'dépavions',
    'dépaysais',
    'dépaysait',
    'dépaysant',
    'dépaysées',
    'dépaysent',
    'dépaysera',
    'dépaysiez',
    'dépaysons',
    'dépeçages',
    'dépeçâmes',
    'dépeçasse',
    'dépeçâtes',
    'dépècerai',
    'dépèceras',
    'dépècerez',
    'dépeceurs',
    'dépeceuse',
    'dépêchais',
    'dépêchait',
    'dépêchant',
    'dépêchées',
    'dépêchent',
    'dépêchera',
    'dépêchiez',
    'dépêchons',
    'dépecions',
    'dépeignai',
    'dépeignas',
    'dépeignât',
    'dépeignée',
    'dépeigner',
    'dépeignes',
    'dépeignés',
    'dépeignez',
    'dépeignis',
    'dépeignit',
    'dépeignît',
    'dépeindra',
    'dépeindre',
    'dépeintes',
    'dépendais',
    'dépendait',
    'dépendant',
    'dépendent',
    'dépendeur',
    'dépendiez',
    'dépendons',
    'dépendrai',
    'dépendras',
    'dépendrez',
    'dépendues',
    'dépensais',
    'dépensait',
    'dépensant',
    'dépensées',
    'dépensent',
    'dépensera',
    'dépensier',
    'dépensiez',
    'dépensons',
    'dépérîmes',
    'dépérirai',
    'dépériras',
    'dépérirez',
    'dépérisse',
    'dépérîtes',
    'dépêtrais',
    'dépêtrait',
    'dépêtrant',
    'dépêtrées',
    'dépêtrent',
    'dépêtrera',
    'dépêtriez',
    'dépêtrons',
    'dépeuplai',
    'dépeuplas',
    'dépeuplât',
    'dépeuplée',
    'dépeupler',
    'dépeuples',
    'dépeuplés',
    'dépeuplez',
    'déphasage',
    'déphasais',
    'déphasait',
    'déphasant',
    'déphasées',
    'déphasent',
    'déphasera',
    'déphasiez',
    'déphasons',
    'dépiautai',
    'dépiautas',
    'dépiautât',
    'dépiautée',
    'dépiauter',
    'dépiautes',
    'dépiautés',
    'dépiautez',
    'dépicages',
    'dépilages',
    'dépilâmes',
    'dépilasse',
    'dépilâtes',
    'dépilerai',
    'dépileras',
    'dépilerez',
    'dépilions',
    'dépinglai',
    'dépinglas',
    'dépinglât',
    'dépinglée',
    'dépingler',
    'dépingles',
    'dépinglés',
    'dépinglez',
    'dépiquage',
    'dépiquais',
    'dépiquait',
    'dépiquant',
    'dépiquées',
    'dépiquent',
    'dépiquera',
    'dépiquiez',
    'dépiquons',
    'dépistage',
    'dépistais',
    'dépistait',
    'dépistant',
    'dépistées',
    'dépistent',
    'dépistera',
    'dépistiez',
    'dépistons',
    'dépitâmes',
    'dépitasse',
    'dépitâtes',
    'dépiterai',
    'dépiteras',
    'dépiterez',
    'dépitions',
    'déplaçais',
    'déplaçait',
    'déplaçant',
    'déplacées',
    'déplacent',
    'déplacera',
    'déplaciez',
    'déplaçons',
    'déplairai',
    'déplairas',
    'déplairez',
    'déplaises',
    'déplaisez',
    'déplaisir',
    'déplanqua',
    'déplanque',
    'déplanqué',
    'déplantai',
    'déplantas',
    'déplantât',
    'déplantée',
    'déplanter',
    'déplantes',
    'déplantés',
    'déplantez',
    'déplâtrai',
    'déplâtras',
    'déplâtrât',
    'déplâtrée',
    'déplâtrer',
    'déplâtres',
    'déplâtrés',
    'déplâtrez',
    'dépliages',
    'dépliâmes',
    'dépliante',
    'dépliants',
    'dépliasse',
    'dépliâtes',
    'déplierai',
    'déplieras',
    'déplierez',
    'dépliions',
    'déplissai',
    'déplissas',
    'déplissât',
    'déplissée',
    'déplisser',
    'déplisses',
    'déplissés',
    'déplissez',
    'déploient',
    'déploiera',
    'déplombai',
    'déplombas',
    'déplombât',
    'déplombée',
    'déplomber',
    'déplombes',
    'déplombés',
    'déplombez',
    'déplorais',
    'déplorait',
    'déplorant',
    'déplorées',
    'déplorent',
    'déplorera',
    'déploriez',
    'déplorons',
    'déployais',
    'déployait',
    'déployant',
    'déployées',
    'déployiez',
    'déployons',
    'déplumais',
    'déplumait',
    'déplumant',
    'déplumées',
    'déplument',
    'déplumera',
    'déplumiez',
    'déplumons',
    'déplurent',
    'déplusses',
    'dépoétisa',
    'dépoétise',
    'dépoétisé',
    'dépointai',
    'dépointas',
    'dépointât',
    'dépointée',
    'dépointer',
    'dépointes',
    'dépointés',
    'dépointez',
    'dépolîmes',
    'dépolirai',
    'dépoliras',
    'dépolirez',
    'dépolisse',
    'dépolîtes',
    'dépolluai',
    'dépolluas',
    'dépolluât',
    'dépolluée',
    'dépolluer',
    'dépollues',
    'dépollués',
    'dépolluez',
    'déponente',
    'déponents',
    'déportais',
    'déportait',
    'déportant',
    'déportées',
    'déportent',
    'déportera',
    'déportiez',
    'déportons',
    'déposâmes',
    'déposante',
    'déposants',
    'déposasse',
    'déposâtes',
    'déposerai',
    'déposeras',
    'déposerez',
    'déposions',
    'déposséda',
    'dépossédé',
    'dépossède',
    'dépotages',
    'dépotâmes',
    'dépotasse',
    'dépotâtes',
    'dépoterai',
    'dépoteras',
    'dépoterez',
    'dépotions',
    'dépotoirs',
    'dépoudrai',
    'dépoudras',
    'dépoudrât',
    'dépoudrée',
    'dépoudrer',
    'dépoudres',
    'dépoudrés',
    'dépoudrez',
    'dépouilla',
    'dépouille',
    'dépouillé',
    'dépourvue',
    'dépourvus',
    'dépourvut',
    'dépravais',
    'dépravait',
    'dépravant',
    'dépravées',
    'dépravent',
    'dépravera',
    'dépraviez',
    'dépravons',
    'dépréciai',
    'déprécias',
    'dépréciât',
    'dépréciée',
    'déprécier',
    'déprécies',
    'dépréciés',
    'dépréciez',
    'déprenais',
    'déprenait',
    'déprenant',
    'déprendra',
    'déprendre',
    'dépreniez',
    'déprennes',
    'déprenons',
    'dépressif',
    'déprimais',
    'déprimait',
    'déprimant',
    'déprimées',
    'dépriment',
    'déprimera',
    'déprimiez',
    'déprimons',
    'déprirent',
    'déprisais',
    'déprisait',
    'déprisant',
    'déprisées',
    'déprisent',
    'déprisera',
    'déprisiez',
    'déprisons',
    'déprisses',
    'dépucelai',
    'dépucelas',
    'dépucelât',
    'dépucelée',
    'dépuceler',
    'dépucelés',
    'dépucelez',
    'dépucelle',
    'dépulpais',
    'dépulpait',
    'dépulpant',
    'dépulpées',
    'dépulpent',
    'dépulpera',
    'dépulpiez',
    'dépulpons',
    'dépurâmes',
    'dépurasse',
    'dépurâtes',
    'dépuratif',
    'dépurerai',
    'dépureras',
    'dépurerez',
    'dépurions',
    'députâmes',
    'députasse',
    'députâtes',
    'députerai',
    'députeras',
    'députerez',
    'députions',
    'déquillai',
    'déquillas',
    'déquillât',
    'déquillée',
    'déquiller',
    'déquilles',
    'déquillés',
    'déquillez',
    'déracinai',
    'déracinas',
    'déracinât',
    'déracinée',
    'déraciner',
    'déracines',
    'déracinés',
    'déracinez',
    'déradâmes',
    'déradasse',
    'déradâtes',
    'déraderai',
    'déraderas',
    'déraderez',
    'déradions',
    'dérageais',
    'dérageait',
    'dérageant',
    'dérageons',
    'déragerai',
    'dérageras',
    'déragerez',
    'déragions',
    'déraidies',
    'déraidira',
    'déraierai',
    'déraieras',
    'déraierez',
    'déraillai',
    'déraillas',
    'déraillât',
    'dérailler',
    'dérailles',
    'déraillez',
    'déraisons',
    'dérangeai',
    'dérangeas',
    'dérangeât',
    'dérangées',
    'dérangent',
    'dérangera',
    'dérangiez',
    'dérapages',
    'dérapâmes',
    'dérapasse',
    'dérapâtes',
    'déraperai',
    'déraperas',
    'déraperez',
    'dérapions',
    'dérasâmes',
    'dérasasse',
    'dérasâtes',
    'déraserai',
    'déraseras',
    'déraserez',
    'dérasions',
    'dératâmes',
    'dératasse',
    'dératâtes',
    'dératerai',
    'dérateras',
    'dératerez',
    'dérations',
    'dératisai',
    'dératisas',
    'dératisât',
    'dératisée',
    'dératiser',
    'dératises',
    'dératisés',
    'dératisez',
    'dérayâmes',
    'dérayasse',
    'dérayâtes',
    'dérayerai',
    'dérayeras',
    'dérayerez',
    'dérayions',
    'dérayures',
    'déréalisa',
    'déréalise',
    'déréalisé',
    'déréglais',
    'déréglait',
    'déréglant',
    'déréglées',
    'dérèglent',
    'déréglera',
    'dérégliez',
    'déréglons',
    'dérégulai',
    'dérégulas',
    'dérégulât',
    'dérégulée',
    'déréguler',
    'dérégules',
    'dérégulés',
    'dérégulez',
    'déridâmes',
    'déridasse',
    'déridâtes',
    'dériderai',
    'dérideras',
    'dériderez',
    'déridions',
    'dérisions',
    'dérisoire',
    'dérivâmes',
    'dérivasse',
    'dérivâtes',
    'dérivatif',
    'dériverai',
    'dériveras',
    'dériverez',
    'dériveurs',
    'dérivions',
    'dermatite',
    'dermatose',
    'dermestes',
    'dermiques',
    'dernières',
    'dérobades',
    'dérobâmes',
    'dérobasse',
    'dérobâtes',
    'déroberai',
    'déroberas',
    'déroberez',
    'dérobions',
    'dérochage',
    'dérochais',
    'dérochait',
    'dérochant',
    'dérochées',
    'dérochent',
    'dérochera',
    'dérochiez',
    'dérochons',
    'dérodâmes',
    'dérodasse',
    'dérodâtes',
    'déroderai',
    'déroderas',
    'déroderez',
    'dérodions',
    'dérogeais',
    'dérogeait',
    'dérogeant',
    'dérogeons',
    'dérogerai',
    'dérogeras',
    'dérogerez',
    'dérogions',
    'dérouilla',
    'dérouille',
    'dérouillé',
    'déroulage',
    'déroulais',
    'déroulait',
    'déroulant',
    'déroulées',
    'déroulent',
    'déroulera',
    'dérouleur',
    'dérouliez',
    'déroulons',
    'déroutage',
    'déroutais',
    'déroutait',
    'déroutant',
    'déroutées',
    'déroutent',
    'déroutera',
    'déroutiez',
    'déroutons',
    'derrières',
    'derviches',
    'désabonna',
    'désabonne',
    'désabonné',
    'désabusai',
    'désabusas',
    'désabusât',
    'désabusée',
    'désabuser',
    'désabuses',
    'désabusés',
    'désabusez',
    'désaccord',
    'désactiva',
    'désactive',
    'désactivé',
    'désadapta',
    'désadapte',
    'désadapté',
    'désagença',
    'désagence',
    'désagencé',
    'désagrafa',
    'désagrafe',
    'désagrafé',
    'désagrégé',
    'désagrège',
    'désajusta',
    'désajuste',
    'désajusté',
    'désaliéna',
    'désaliéné',
    'désaliène',
    'désaligna',
    'désaligne',
    'désaligné',
    'désaltéra',
    'désaltéré',
    'désaltère',
    'désamarra',
    'désamarre',
    'désamarré',
    'désamorça',
    'désamorce',
    'désamorcé',
    'désannexa',
    'désannexe',
    'désannexé',
    'désappris',
    'désapprit',
    'désapprît',
    'désarmais',
    'désarmait',
    'désarmant',
    'désarmées',
    'désarment',
    'désarmera',
    'désarmiez',
    'désarmons',
    'désarrima',
    'désarrime',
    'désarrimé',
    'désarrois',
    'désastres',
    'désavouai',
    'désavouas',
    'désavouât',
    'désavouée',
    'désavouer',
    'désavoues',
    'désavoués',
    'désavouez',
    'désaxâmes',
    'désaxasse',
    'désaxâtes',
    'désaxerai',
    'désaxeras',
    'désaxerez',
    'désaxions',
    'descellai',
    'descellas',
    'descellât',
    'descellée',
    'desceller',
    'descelles',
    'descellés',
    'descellez',
    'descendes',
    'descendez',
    'descendis',
    'descendit',
    'descendît',
    'descendra',
    'descendre',
    'descendue',
    'descendus',
    'descentes',
    'déséchoua',
    'déséchoue',
    'déséchoué',
    'désembuai',
    'désembuas',
    'désembuât',
    'désembuée',
    'désembuer',
    'désembues',
    'désembués',
    'désembuez',
    'désempara',
    'désempare',
    'désemparé',
    'désempesa',
    'désempesé',
    'désempèse',
    'désemplie',
    'désemplir',
    'désemplis',
    'désénerva',
    'désénerve',
    'désénervé',
    'désenfila',
    'désenfile',
    'désenfilé',
    'désenflai',
    'désenflas',
    'désenflât',
    'désenflée',
    'désenfler',
    'désenfles',
    'désenflés',
    'désenflez',
    'désenfuma',
    'désenfume',
    'désenfumé',
    'désengage',
    'désengagé',
    'désenivra',
    'désenivre',
    'désenivré',
    'désenlaça',
    'désenlace',
    'désenlacé',
    'désennuie',
    'désennuya',
    'désennuyé',
    'désenraie',
    'désenraya',
    'désenraye',
    'désenrayé',
    'désenroua',
    'désenroue',
    'désenroué',
    'désenvasa',
    'désenvase',
    'désenvasé',
    'déséquipa',
    'déséquipe',
    'déséquipé',
    'désertais',
    'désertait',
    'désertant',
    'désertées',
    'désertent',
    'désertera',
    'déserteur',
    'désertiez',
    'désertion',
    'désertons',
    'désespéra',
    'désespéré',
    'désespère',
    'désespoir',
    'désétabli',
    'désétamai',
    'désétamas',
    'désétamât',
    'désétamée',
    'désétamer',
    'désétames',
    'désétamés',
    'désétamez',
    'désherbai',
    'désherbas',
    'désherbât',
    'désherbée',
    'désherber',
    'désherbes',
    'désherbés',
    'désherbez',
    'déshérita',
    'déshérite',
    'déshérité',
    'déshonora',
    'déshonore',
    'déshonoré',
    'déshuilai',
    'déshuilas',
    'déshuilât',
    'déshuilée',
    'déshuiler',
    'déshuiles',
    'déshuilés',
    'déshuilez',
    'désignais',
    'désignait',
    'désignant',
    'désignées',
    'désignent',
    'désignera',
    'désigniez',
    'désignons',
    'désindexa',
    'désindexe',
    'désindexé',
    'désinence',
    'désinvita',
    'désinvite',
    'désinvité',
    'désirable',
    'désirâmes',
    'désirasse',
    'désirâtes',
    'désirerai',
    'désireras',
    'désirerez',
    'désireuse',
    'désirions',
    'désistais',
    'désistait',
    'désistant',
    'désistées',
    'désistent',
    'désistera',
    'désistiez',
    'désistons',
    'désobéies',
    'désobéira',
    'désoblige',
    'désobligé',
    'désoccupa',
    'désoccupe',
    'désoccupé',
    'désoeuvré',
    'désolâmes',
    'désolante',
    'désolants',
    'désolasse',
    'désolâtes',
    'désolerai',
    'désoleras',
    'désolerez',
    'désolions',
    'désopilai',
    'désopilas',
    'désopilât',
    'désopilée',
    'désopiler',
    'désopiles',
    'désopilés',
    'désopilez',
    'désorbais',
    'désorbait',
    'désorbant',
    'désorbées',
    'désorbent',
    'désorbera',
    'désorbiez',
    'désorbita',
    'désorbite',
    'désorbité',
    'désorbons',
    'désordres',
    'désormais',
    'désossais',
    'désossait',
    'désossant',
    'désossées',
    'désossent',
    'désossera',
    'désossiez',
    'désossons',
    'désoxydai',
    'désoxydas',
    'désoxydât',
    'désoxydée',
    'désoxyder',
    'désoxydes',
    'désoxydés',
    'désoxydez',
    'desperado',
    'desquamai',
    'desquamas',
    'desquamât',
    'desquamée',
    'desquamer',
    'desquames',
    'desquamés',
    'desquamez',
    'dessablai',
    'dessablas',
    'dessablât',
    'dessablée',
    'dessabler',
    'dessables',
    'dessablés',
    'dessablez',
    'dessaisie',
    'dessaisir',
    'dessaisis',
    'dessaisit',
    'dessaisît',
    'dessalage',
    'dessalais',
    'dessalait',
    'dessalant',
    'dessalées',
    'dessalent',
    'dessalera',
    'dessaliez',
    'dessalons',
    'dessangla',
    'dessangle',
    'dessanglé',
    'dessaoula',
    'dessaoule',
    'dessaoulé',
    'desséchai',
    'desséchas',
    'desséchât',
    'desséchée',
    'dessécher',
    'desséchés',
    'dessèches',
    'desséchez',
    'dessellai',
    'dessellas',
    'dessellât',
    'dessellée',
    'desseller',
    'desselles',
    'dessellés',
    'dessellez',
    'desserrai',
    'desserras',
    'desserrât',
    'desserrée',
    'desserrer',
    'desserres',
    'desserrés',
    'desserrez',
    'dessertes',
    'dessertie',
    'dessertir',
    'dessertis',
    'dessertit',
    'dessertît',
    'desserves',
    'desservez',
    'desservie',
    'desservir',
    'desservis',
    'desservit',
    'desservît',
    'dessillai',
    'dessillas',
    'dessillât',
    'dessillée',
    'dessiller',
    'dessilles',
    'dessillés',
    'dessillez',
    'dessinais',
    'dessinait',
    'dessinant',
    'dessinées',
    'dessinent',
    'dessinera',
    'dessiniez',
    'dessinons',
    'dessolais',
    'dessolait',
    'dessolant',
    'dessolées',
    'dessolent',
    'dessolera',
    'dessoliez',
    'dessolons',
    'dessoudai',
    'dessoudas',
    'dessoudât',
    'dessoudée',
    'dessouder',
    'dessoudes',
    'dessoudés',
    'dessoudez',
    'dessoulai',
    'dessoûlai',
    'dessoulas',
    'dessoûlas',
    'dessoulât',
    'dessoûlât',
    'dessoulée',
    'dessoûlée',
    'dessouler',
    'dessoûler',
    'dessoules',
    'dessoulés',
    'dessoûles',
    'dessoûlés',
    'dessoulez',
    'dessoûlez',
    'dessuitai',
    'dessuitas',
    'dessuitât',
    'dessuitée',
    'dessuiter',
    'dessuites',
    'dessuités',
    'dessuitez',
    'destinais',
    'destinait',
    'destinant',
    'destinées',
    'destinent',
    'destinera',
    'destiniez',
    'destinons',
    'destituai',
    'destituée',
    'destituer',
    'destitues',
    'destitués',
    'destituez',
    'déstockai',
    'déstockas',
    'déstockât',
    'déstockée',
    'déstocker',
    'déstockes',
    'déstockés',
    'déstockez',
    'destriers',
    'destroyer',
    'désuétude',
    'désulfita',
    'désulfite',
    'désulfité',
    'désulfura',
    'désulfure',
    'désulfuré',
    'désunîmes',
    'désunions',
    'désunirai',
    'désuniras',
    'désunirez',
    'désunisse',
    'désunîtes',
    'détachage',
    'détachais',
    'détachait',
    'détachant',
    'détachées',
    'détachent',
    'détachera',
    'détacheur',
    'détachiez',
    'détachons',
    'détaillai',
    'détaillas',
    'détaillât',
    'détaillée',
    'détailler',
    'détailles',
    'détaillés',
    'détaillez',
    'détalâmes',
    'détalasse',
    'détalâtes',
    'détalerai',
    'détaleras',
    'détalerez',
    'détalions',
    'détallais',
    'détallait',
    'détallant',
    'détallées',
    'détallent',
    'détallera',
    'détalliez',
    'détallons',
    'détapissa',
    'détapisse',
    'détapissé',
    'détartrai',
    'détartras',
    'détartrât',
    'détartrée',
    'détartrer',
    'détartres',
    'détartrés',
    'détartrez',
    'détaxâmes',
    'détaxasse',
    'détaxâtes',
    'détaxerai',
    'détaxeras',
    'détaxerez',
    'détaxions',
    'détectais',
    'détectait',
    'détectant',
    'détectées',
    'détectent',
    'détectera',
    'détecteur',
    'détectiez',
    'détection',
    'détective',
    'détectons',
    'déteignes',
    'déteignez',
    'déteignis',
    'déteignit',
    'déteignît',
    'déteindra',
    'déteindre',
    'déteintes',
    'dételages',
    'dételâmes',
    'dételasse',
    'dételâtes',
    'dételions',
    'détellent',
    'détellera',
    'détenante',
    'détenants',
    'détendais',
    'détendait',
    'détendant',
    'détendent',
    'détendeur',
    'détendiez',
    'détendons',
    'détendrai',
    'détendras',
    'détendrez',
    'détendues',
    'détenions',
    'détenteur',
    'détention',
    'détergeai',
    'détergeas',
    'détergeât',
    'détergées',
    'détergent',
    'détergera',
    'détergiez',
    'détériora',
    'détériore',
    'détérioré',
    'détermina',
    'détermine',
    'déterminé',
    'déterrage',
    'déterrais',
    'déterrait',
    'déterrant',
    'déterrées',
    'déterrent',
    'déterrera',
    'déterreur',
    'déterriez',
    'déterrons',
    'détersifs',
    'détersion',
    'détersive',
    'détestais',
    'détestait',
    'détestant',
    'détestées',
    'détestent',
    'détestera',
    'détestiez',
    'détestons',
    'détiendra',
    'détiennes',
    'détinrent',
    'détinsses',
    'détirâmes',
    'détirasse',
    'détirâtes',
    'détirerai',
    'détireras',
    'détirerez',
    'détireuse',
    'détirions',
    'détissais',
    'détissait',
    'détissant',
    'détissées',
    'détissent',
    'détissera',
    'détissiez',
    'détissons',
    'détonâmes',
    'détonante',
    'détonants',
    'détonasse',
    'détonâtes',
    'détonerai',
    'détoneras',
    'détonerez',
    'détonions',
    'détonnais',
    'détonnait',
    'détonnant',
    'détonnela',
    'détonnelé',
    'détonnent',
    'détonnera',
    'détonniez',
    'détonnons',
    'détordais',
    'détordait',
    'détordant',
    'détordent',
    'détordiez',
    'détordons',
    'détordrai',
    'détordras',
    'détordrez',
    'détordues',
    'détourais',
    'détourait',
    'détourant',
    'détourées',
    'détourent',
    'détourera',
    'détouriez',
    'détournai',
    'détournas',
    'détournât',
    'détournée',
    'détourner',
    'détournes',
    'détournés',
    'détournez',
    'détourons',
    'détractai',
    'détractas',
    'détractât',
    'détractée',
    'détracter',
    'détractes',
    'détractés',
    'détractez',
    'détrancha',
    'détranche',
    'détranché',
    'détraquai',
    'détraquas',
    'détraquât',
    'détraquée',
    'détraquer',
    'détraques',
    'détraqués',
    'détraquez',
    'détrempai',
    'détrempas',
    'détrempât',
    'détrempée',
    'détremper',
    'détrempes',
    'détrempés',
    'détrempez',
    'détressai',
    'détressas',
    'détressât',
    'détressée',
    'détresser',
    'détresses',
    'détressés',
    'détressez',
    'détricota',
    'détricote',
    'détricoté',
    'détriment',
    'détrompai',
    'détrompas',
    'détrompât',
    'détrompée',
    'détromper',
    'détrompes',
    'détrompés',
    'détrompez',
    'détrônais',
    'détrônait',
    'détrônant',
    'détroncha',
    'détronche',
    'détronché',
    'détrônées',
    'détrônent',
    'détrônera',
    'détrôniez',
    'détrônons',
    'détroquai',
    'détroquas',
    'détroquât',
    'détroquée',
    'détroquer',
    'détroques',
    'détroqués',
    'détroquez',
    'détroussa',
    'détrousse',
    'détroussé',
    'détruirai',
    'détruiras',
    'détruirez',
    'détruises',
    'détruisez',
    'détruisis',
    'détruisit',
    'détruisît',
    'détruites',
    'deutérium',
    'deutérons',
    'deux-deux',
    'deuxièmes',
    'deux-mâts',
    'dévalâmes',
    'dévalasse',
    'dévalâtes',
    'dévalerai',
    'dévaleras',
    'dévalerez',
    'dévalions',
    'dévalisai',
    'dévalisas',
    'dévalisât',
    'dévalisée',
    'dévaliser',
    'dévalises',
    'dévalisés',
    'dévalisez',
    'dévaluais',
    'dévaluait',
    'dévaluant',
    'dévaluées',
    'dévaluent',
    'dévaluera',
    'dévaluiez',
    'dévaluons',
    'devançais',
    'devançait',
    'devançant',
    'devancées',
    'devancent',
    'devancera',
    'devancier',
    'devanciez',
    'devançons',
    'devanture',
    'dévasâmes',
    'dévasasse',
    'dévasâtes',
    'dévaserai',
    'dévaseras',
    'dévaserez',
    'dévasions',
    'dévastais',
    'dévastait',
    'dévastant',
    'dévastées',
    'dévastent',
    'dévastera',
    'dévastiez',
    'dévastons',
    'développa',
    'développe',
    'développé',
    'devenions',
    'déventais',
    'déventait',
    'déventant',
    'déventées',
    'déventent',
    'déventera',
    'déventiez',
    'déventons',
    'déverbale',
    'déverbaux',
    'déverdira',
    'déverguai',
    'déverguas',
    'déverguât',
    'déverguée',
    'déverguer',
    'dévergues',
    'dévergués',
    'déverguez',
    'dévernies',
    'dévernira',
    'déversais',
    'déversait',
    'déversant',
    'déversées',
    'déversent',
    'déversera',
    'déversiez',
    'déversoir',
    'déversons',
    'dévêtîmes',
    'dévêtions',
    'dévêtirai',
    'dévêtiras',
    'dévêtirez',
    'dévêtisse',
    'dévêtîtes',
    'déviaient',
    'déviantes',
    'déviasses',
    'déviateur',
    'déviation',
    'dévidages',
    'dévidâmes',
    'dévidasse',
    'dévidâtes',
    'déviderai',
    'dévideras',
    'déviderez',
    'dévideurs',
    'dévideuse',
    'dévidions',
    'dévidoirs',
    'deviendra',
    'deviennes',
    'dévierais',
    'dévierait',
    'dévièrent',
    'dévieriez',
    'dévierons',
    'dévieront',
    'devinable',
    'devinâmes',
    'devinasse',
    'devinâtes',
    'devinerai',
    'devineras',
    'devinerez',
    'devinette',
    'devinions',
    'devinrent',
    'devinsses',
    'dévirolai',
    'dévirolas',
    'dévirolât',
    'dévirolée',
    'déviroler',
    'déviroles',
    'dévirolés',
    'dévirolez',
    'dévisagea',
    'dévisagée',
    'dévisager',
    'dévisages',
    'dévisagés',
    'dévisagez',
    'devisâmes',
    'devisasse',
    'devisâtes',
    'deviserai',
    'deviseras',
    'deviserez',
    'devisions',
    'dévissage',
    'dévissais',
    'dévissait',
    'dévissant',
    'dévissées',
    'dévissent',
    'dévissera',
    'dévissiez',
    'dévissons',
    'dévoierai',
    'dévoieras',
    'dévoierez',
    'dévoilais',
    'dévoilait',
    'dévoilant',
    'dévoilées',
    'dévoilent',
    'dévoilera',
    'dévoiliez',
    'dévoilons',
    'dévoltage',
    'dévoltais',
    'dévoltait',
    'dévoltant',
    'dévoltées',
    'dévoltent',
    'dévoltera',
    'dévoltiez',
    'dévoltons',
    'dévolutif',
    'dévoniens',
    'dévorâmes',
    'dévorante',
    'dévorants',
    'dévorasse',
    'dévorâtes',
    'dévorerai',
    'dévoreras',
    'dévorerez',
    'dévoreurs',
    'dévoreuse',
    'dévorions',
    'dévotions',
    'dévouâmes',
    'dévouasse',
    'dévouâtes',
    'dévouerai',
    'dévoueras',
    'dévouerez',
    'dévouions',
    'dévoyâmes',
    'dévoyasse',
    'dévoyâtes',
    'dévoyions',
    'devraient',
    'dextérité',
    'dextrines',
    'dextroses',
    'diablerie',
    'diablesse',
    'diablotin',
    'diachylon',
    'diachylum',
    'diaclases',
    'diaconale',
    'diaconats',
    'diaconaux',
    'diadoques',
    'diagnoses',
    'diagonale',
    'diagonaux',
    'diagramme',
    'dialectal',
    'dialectes',
    'dialoguai',
    'dialoguas',
    'dialoguât',
    'dialoguer',
    'dialogues',
    'dialoguez',
    'dialysais',
    'dialysait',
    'dialysant',
    'dialysées',
    'dialysent',
    'dialysera',
    'dialyseur',
    'dialysiez',
    'dialysons',
    'diamantai',
    'diamantas',
    'diamantât',
    'diamantée',
    'diamanter',
    'diamantes',
    'diamantés',
    'diamantez',
    'diamantin',
    'diamétral',
    'diamètres',
    'diapasons',
    'diapédèse',
    'diaphanes',
    'diaphonie',
    'diaphyses',
    'diaprâmes',
    'diaprasse',
    'diaprâtes',
    'diaprerai',
    'diapreras',
    'diaprerez',
    'diaprions',
    'diaprures',
    'diarrhées',
    'diascopes',
    'diascopie',
    'diastases',
    'diastoles',
    'diathèses',
    'diatomées',
    'diatomite',
    'diatribes',
    'diazoïque',
    'dibasique',
    'dictaient',
    'dictasses',
    'dictateur',
    'dictature',
    'dicterais',
    'dicterait',
    'dictèrent',
    'dicteriez',
    'dicterons',
    'dicteront',
    'didactyle',
    'diduction',
    'diffamais',
    'diffamait',
    'diffamant',
    'diffamées',
    'diffament',
    'diffamera',
    'diffamiez',
    'diffamons',
    'différais',
    'différait',
    'différant',
    'différées',
    'différend',
    'différent',
    'diffèrent',
    'différera',
    'différiez',
    'différons',
    'difficile',
    'difformai',
    'difformas',
    'difformât',
    'difformée',
    'difformer',
    'difformes',
    'difformés',
    'difformez',
    'diffracta',
    'diffracte',
    'diffracté',
    'diffusais',
    'diffusait',
    'diffusant',
    'diffusées',
    'diffusent',
    'diffusera',
    'diffuseur',
    'diffusiez',
    'diffusion',
    'diffusons',
    'digérâmes',
    'digérasse',
    'digérâtes',
    'digérerai',
    'digéreras',
    'digérerez',
    'digérions',
    'digesteur',
    'digestifs',
    'digestion',
    'digestive',
    'digitales',
    'dignement',
    'digrammes',
    'digraphie',
    'dilacérai',
    'dilacéras',
    'dilacérât',
    'dilacérée',
    'dilacérer',
    'dilacérés',
    'dilacères',
    'dilacérez',
    'dilapidai',
    'dilapidas',
    'dilapidât',
    'dilapidée',
    'dilapider',
    'dilapidés',
    'dilapidez',
    'dilatable',
    'dilatâmes',
    'dilatante',
    'dilatants',
    'dilatasse',
    'dilatâtes',
    'dilaterai',
    'dilateras',
    'dilaterez',
    'dilations',
    'dilatoire',
    'dilection',
    'diligence',
    'diligente',
    'diligents',
    'diluaient',
    'diluantes',
    'diluasses',
    'diluerais',
    'diluerait',
    'diluèrent',
    'dilueriez',
    'diluerons',
    'dilueront',
    'dilutions',
    'diluviale',
    'diluviaux',
    'diluviens',
    'diluviums',
    'dimanches',
    'dimension',
    'diminuais',
    'diminuait',
    'diminuant',
    'diminuées',
    'diminuent',
    'diminuera',
    'diminuiez',
    'diminuons',
    'diminutif',
    'dimorphes',
    'dinandier',
    'dînassent',
    'dînassiez',
    'dînatoire',
    'dindonnai',
    'dindonnas',
    'dindonnât',
    'dindonnée',
    'dindonner',
    'dindonnes',
    'dindonnés',
    'dindonnez',
    'dînerions',
    'dinguâmes',
    'dinguasse',
    'dinguâtes',
    'dinguerai',
    'dingueras',
    'dinguerez',
    'dinguerie',
    'dinguions',
    'dinosaure',
    'diocésain',
    'dioptries',
    'dipalides',
    'dipétales',
    'diphasées',
    'diphtérie',
    'diploïdes',
    'diplômais',
    'diplômait',
    'diplômant',
    'diplomate',
    'diplômées',
    'diplôment',
    'diplômera',
    'diplômiez',
    'diplômons',
    'diplopies',
    'dipolaire',
    'dipsomane',
    'diptyques',
    'directeur',
    'directifs',
    'direction',
    'directive',
    'dirigeais',
    'dirigeait',
    'dirigeant',
    'dirigeons',
    'dirigerai',
    'dirigeras',
    'dirigerez',
    'dirigions',
    'dirigisme',
    'dirigiste',
    'dirimante',
    'dirimants',
    'discernai',
    'discernas',
    'discernât',
    'discernée',
    'discerner',
    'discernes',
    'discernés',
    'discernez',
    'disciples',
    'discobole',
    'discoïdal',
    'discoïdes',
    'discordai',
    'discordas',
    'discordât',
    'discorder',
    'discordes',
    'discordez',
    'discounté',
    'discounts',
    'discoures',
    'discourez',
    'discourir',
    'discourra',
    'discourue',
    'discourus',
    'discourut',
    'discourût',
    'discrédit',
    'discrètes',
    'disculpai',
    'disculpas',
    'disculpât',
    'disculpée',
    'disculper',
    'disculpes',
    'disculpés',
    'disculpez',
    'discursif',
    'discutais',
    'discutait',
    'discutant',
    'discutées',
    'discutent',
    'discutera',
    'discuteur',
    'discutiez',
    'discutons',
    'disetteux',
    'disgrâces',
    'disgracia',
    'disgracie',
    'disgracié',
    'disjoigne',
    'disjointe',
    'disjoints',
    'disjoncta',
    'disjoncte',
    'disjoncté',
    'disloquai',
    'disloquas',
    'disloquât',
    'disloquée',
    'disloquer',
    'disloques',
    'disloqués',
    'disloquez',
    'disparais',
    'disparaît',
    'disparate',
    'disparité',
    'disparues',
    'dispensai',
    'dispensas',
    'dispensât',
    'dispensée',
    'dispenser',
    'dispenses',
    'dispensés',
    'dispensez',
    'dispersai',
    'dispersal',
    'dispersas',
    'dispersât',
    'dispersée',
    'disperser',
    'disperses',
    'dispersés',
    'dispersez',
    'dispersif',
    'disposais',
    'disposait',
    'disposant',
    'disposées',
    'disposent',
    'disposera',
    'disposiez',
    'disposons',
    'disputais',
    'disputait',
    'disputant',
    'disputées',
    'disputent',
    'disputera',
    'disputiez',
    'disputons',
    'disquaire',
    'disquette',
    'disruptif',
    'dissémina',
    'dissémine',
    'disséminé',
    'disséquai',
    'disséquas',
    'disséquât',
    'disséquée',
    'disséquer',
    'disséqués',
    'dissèques',
    'disséquez',
    'dissertai',
    'dissertas',
    'dissertât',
    'disserter',
    'dissertes',
    'dissertez',
    'dissident',
    'dissimula',
    'dissimule',
    'dissimulé',
    'dissipais',
    'dissipait',
    'dissipant',
    'dissipées',
    'dissipent',
    'dissipera',
    'dissipiez',
    'dissipons',
    'dissociai',
    'dissocias',
    'dissociât',
    'dissociée',
    'dissocier',
    'dissocies',
    'dissociés',
    'dissociez',
    'dissolues',
    'dissolves',
    'dissolvez',
    'dissonais',
    'dissonait',
    'dissonant',
    'dissonent',
    'dissonera',
    'dissoniez',
    'dissonons',
    'dissoudra',
    'dissoudre',
    'dissoutes',
    'dissuadai',
    'dissuadas',
    'dissuadât',
    'dissuadée',
    'dissuader',
    'dissuades',
    'dissuadés',
    'dissuadez',
    'dissuasif',
    'distançai',
    'distanças',
    'distançât',
    'distancée',
    'distancer',
    'distances',
    'distancés',
    'distancez',
    'distancia',
    'distancie',
    'distancié',
    'distantes',
    'distendes',
    'distendez',
    'distendis',
    'distendit',
    'distendît',
    'distendra',
    'distendre',
    'distendue',
    'distendus',
    'disthènes',
    'distillai',
    'distillas',
    'distillat',
    'distillât',
    'distillée',
    'distiller',
    'distilles',
    'distillés',
    'distillez',
    'distincte',
    'distincts',
    'distingua',
    'distingue',
    'distingué',
    'distinguo',
    'distiques',
    'distordes',
    'distordez',
    'distordis',
    'distordit',
    'distordît',
    'distordra',
    'distordre',
    'distordue',
    'distordus',
    'distraies',
    'distraira',
    'distraire',
    'distraite',
    'distraits',
    'distrayez',
    'distribua',
    'distribue',
    'distribué',
    'districts',
    'divaguais',
    'divaguait',
    'divaguant',
    'divaguent',
    'divaguera',
    'divaguiez',
    'divaguons',
    'divergeai',
    'divergeas',
    'divergeât',
    'divergées',
    'divergent',
    'divergera',
    'divergiez',
    'diversion',
    'diversité',
    'diverties',
    'divertira',
    'dividende',
    'divinisai',
    'divinisas',
    'divinisât',
    'divinisée',
    'diviniser',
    'divinises',
    'divinisés',
    'divinisez',
    'divinités',
    'divisâmes',
    'divisasse',
    'divisâtes',
    'diviserai',
    'diviseras',
    'diviserez',
    'diviseurs',
    'divisible',
    'divisions',
    'divorçais',
    'divorçait',
    'divorçant',
    'divorcées',
    'divorcent',
    'divorcera',
    'divorciez',
    'divorçons',
    'divulguai',
    'divulguas',
    'divulguât',
    'divulguée',
    'divulguer',
    'divulgues',
    'divulgués',
    'divulguez',
    'dizygotes',
    'djaïnisme',
    'djellabas',
    'dobermans',
    'docilités',
    'docimasie',
    'doctement',
    'doctorale',
    'doctorant',
    'doctorats',
    'doctoraux',
    'doctrinal',
    'doctrines',
    'documenta',
    'documente',
    'documenté',
    'documents',
    'dodelinai',
    'dodelinas',
    'dodelinât',
    'dodelinée',
    'dodeliner',
    'dodelines',
    'dodelinés',
    'dodelinez',
    'dodinâmes',
    'dodinasse',
    'dodinâtes',
    'dodinerai',
    'dodineras',
    'dodinerez',
    'dodinions',
    'dogmatisa',
    'dogmatise',
    'dogmatisé',
    'doigtâmes',
    'doigtasse',
    'doigtâtes',
    'doigterai',
    'doigteras',
    'doigterez',
    'doigtiers',
    'doigtions',
    'dolassent',
    'dolassiez',
    'doléances',
    'dolerions',
    'dolomites',
    'dolorisme',
    'dolosives',
    'domaniale',
    'domaniaux',
    'domiciles',
    'domicilia',
    'domicilie',
    'domicilié',
    'dominâmes',
    'dominance',
    'dominante',
    'dominants',
    'dominasse',
    'dominâtes',
    'dominerai',
    'domineras',
    'dominerez',
    'dominical',
    'dominions',
    'domptable',
    'domptages',
    'domptâmes',
    'domptasse',
    'domptâtes',
    'dompterai',
    'dompteras',
    'dompterez',
    'dompteurs',
    'dompteuse',
    'domptions',
    'donataire',
    'donateurs',
    'donations',
    'donatisme',
    'donatiste',
    'donatrice',
    'dondaines',
    'donnaient',
    'donnasses',
    'donnerais',
    'donnerait',
    'donnèrent',
    'donneriez',
    'donnerons',
    'donneront',
    'donneuses',
    'donzelles',
    'dopamines',
    'dopassent',
    'dopassiez',
    'doperions',
    'dorassent',
    'dorassiez',
    'dorerions',
    'doriennes',
    'dorlotais',
    'dorlotait',
    'dorlotant',
    'dorlotées',
    'dorlotent',
    'dorlotera',
    'dorlotiez',
    'dorlotons',
    'dormaient',
    'dormantes',
    'dormeuses',
    'dormirais',
    'dormirait',
    'dormirent',
    'dormiriez',
    'dormirons',
    'dormiront',
    'dormisses',
    'dormitifs',
    'dormition',
    'dormitive',
    'dorsalgie',
    'doryphore',
    'dosassent',
    'dosassiez',
    'doserions',
    'dosimètre',
    'dosserets',
    'dossières',
    'dotalités',
    'dotassent',
    'dotassiez',
    'dotations',
    'doterions',
    'douanière',
    'douaniers',
    'douassent',
    'douassiez',
    'doublages',
    'doublâmes',
    'doublasse',
    'doublâtes',
    'doubleaux',
    'doublerai',
    'doubleras',
    'doublerez',
    'doubleurs',
    'doubleuse',
    'doubliers',
    'doublions',
    'doublures',
    'douceâtre',
    'doucement',
    'doucereux',
    'doucettes',
    'douchâmes',
    'douchasse',
    'douchâtes',
    'doucherai',
    'doucheras',
    'doucherez',
    'doucheurs',
    'doucheuse',
    'douchions',
    'doucirais',
    'doucirait',
    'doucirent',
    'douciriez',
    'doucirons',
    'douciront',
    'doucisses',
    'doucissez',
    'douerions',
    'douillets',
    'doutaient',
    'doutasses',
    'douterais',
    'douterait',
    'doutèrent',
    'douteriez',
    'douterons',
    'douteront',
    'douteuses',
    'douvelles',
    'douzaines',
    'douzièmes',
    'doxologie',
    'doyenneté',
    'draconien',
    'dragéifia',
    'dragéifie',
    'dragéifié',
    'drageoirs',
    'drageonna',
    'drageonne',
    'drageonné',
    'draglines',
    'dragonnes',
    'draguâmes',
    'draguasse',
    'draguâtes',
    'draguerai',
    'dragueras',
    'draguerez',
    'dragueurs',
    'dragueuse',
    'draguions',
    'draierais',
    'draierait',
    'draieriez',
    'draierons',
    'draieront',
    'drainages',
    'drainâmes',
    'drainasse',
    'drainâtes',
    'drainerai',
    'draineras',
    'drainerez',
    'draineurs',
    'draineuse',
    'drainions',
    'draisines',
    'dramatisa',
    'dramatise',
    'dramatisé',
    'drapaient',
    'drapasses',
    'drapement',
    'draperais',
    'draperait',
    'drapèrent',
    'draperies',
    'draperiez',
    'draperons',
    'draperont',
    'drapières',
    'drastique',
    'dravidien',
    'drawbacks',
    'drayaient',
    'drayasses',
    'drayerais',
    'drayerait',
    'drayèrent',
    'drayeriez',
    'drayerons',
    'drayeront',
    'drayoires',
    'dressages',
    'dressâmes',
    'dressante',
    'dressants',
    'dressasse',
    'dressâtes',
    'dresserai',
    'dresseras',
    'dresserez',
    'dresseurs',
    'dresseuse',
    'dressions',
    'dressoirs',
    'dribblais',
    'dribblait',
    'dribblant',
    'dribblées',
    'dribblent',
    'dribblera',
    'dribbleur',
    'dribbliez',
    'dribblons',
    'drillâmes',
    'drillasse',
    'drillâtes',
    'drillerai',
    'drilleras',
    'drillerez',
    'drillions',
    'drivaient',
    'drivasses',
    'driverais',
    'driverait',
    'drivèrent',
    'driveriez',
    'driverons',
    'driveront',
    'droguâmes',
    'droguasse',
    'droguâtes',
    'droguerai',
    'drogueras',
    'droguerez',
    'droguerie',
    'droguions',
    'droguiste',
    'droit-fil',
    'droitière',
    'droitiers',
    'droitures',
    'drôlement',
    'drôleries',
    'drôlesses',
    'dropaient',
    'dropasses',
    'droperais',
    'droperait',
    'dropèrent',
    'droperiez',
    'droperons',
    'droperont',
    'drop-goal',
    'droppages',
    'droppâmes',
    'droppasse',
    'droppâtes',
    'dropperai',
    'dropperas',
    'dropperez',
    'droppions',
    'drossâmes',
    'drossasse',
    'drossâtes',
    'drosserai',
    'drosseras',
    'drosserez',
    'drossions',
    'drugstore',
    'druidesse',
    'druidique',
    'druidisme',
    'dualismes',
    'dualistes',
    'dubitatif',
    'duchesses',
    'ducroires',
    'ductilité',
    'duelliste',
    'duettiste',
    'dulcifiai',
    'dulcifias',
    'dulcifiât',
    'dulcifiée',
    'dulcifier',
    'dulcifies',
    'dulcifiés',
    'dulcifiez',
    'dulcinées',
    'duodénale',
    'duodénaux',
    'duodénite',
    'duodénums',
    'dupassent',
    'dupassiez',
    'duperions',
    'duplexais',
    'duplexait',
    'duplexant',
    'duplexées',
    'duplexent',
    'duplexera',
    'duplexiez',
    'duplexons',
    'duplicata',
    'duplicité',
    'dupliquai',
    'dupliquas',
    'dupliquât',
    'dupliquée',
    'dupliquer',
    'dupliques',
    'dupliqués',
    'dupliquez',
    'duralumin',
    'durassent',
    'durassiez',
    'duratives',
    'durcirais',
    'durcirait',
    'durcirent',
    'durciriez',
    'durcirons',
    'durciront',
    'durcisses',
    'durcissez',
    'dure-mère',
    'durerions',
    'durillons',
    'duumvirat',
    'duvetâmes',
    'duvetasse',
    'duvetâtes',
    'duveteuse',
    'duvetions',
    'duvettent',
    'duvettera',
    'dynamique',
    'dynamisai',
    'dynamisas',
    'dynamisât',
    'dynamisée',
    'dynamiser',
    'dynamises',
    'dynamisés',
    'dynamisez',
    'dynamisme',
    'dynamiste',
    'dynamitai',
    'dynamitas',
    'dynamitât',
    'dynamitée',
    'dynamiter',
    'dynamites',
    'dynamités',
    'dynamitez',
    'dynasties',
    'dyscrasie',
    'dysidrose',
    'dyslexies',
    'dyspepsie',
    'dysphasie',
    'dysphonie',
    'dysphorie',
    'dysplasie',
    'dyspraxie',
    'dystasies',
    'dystocies',
    'dystomies',
    'dystonies',
    'eau-forte',
    'ébahirais',
    'ébahirait',
    'ébahirent',
    'ébahiriez',
    'ébahirons',
    'ébahiront',
    'ébahisses',
    'ébahissez',
    'ébarbages',
    'ébarbâmes',
    'ébarbasse',
    'ébarbâtes',
    'ébarberai',
    'ébarberas',
    'ébarberez',
    'ébarbeurs',
    'ébarbeuse',
    'ébarbions',
    'ébarboirs',
    'ébarbures',
    'ébattîmes',
    'ébattions',
    'ébattîtes',
    'ébattriez',
    'ébattrons',
    'ébaubîmes',
    'ébaubirez',
    'ébaubîtes',
    'ébauchage',
    'ébauchais',
    'ébauchait',
    'ébauchant',
    'ébauchées',
    'ébauchent',
    'ébauchera',
    'ébaucheur',
    'ébauchiez',
    'ébauchoir',
    'ébauchons',
    'ébaudîmes',
    'ébaudirez',
    'ébaudîtes',
    'ébavurais',
    'ébavurait',
    'ébavurant',
    'ébavurées',
    'ébavurent',
    'ébavurera',
    'ébavuriez',
    'ébavurons',
    'ébénistes',
    'éberluais',
    'éberluait',
    'éberluant',
    'éberluées',
    'éberluent',
    'éberluera',
    'éberluiez',
    'éberluons',
    'ébiselais',
    'ébiselait',
    'ébiselant',
    'ébiselées',
    'ébisèlent',
    'ébiselera',
    'ébiseliez',
    'ébiselons',
    'éblouîmes',
    'éblouirai',
    'éblouiras',
    'éblouirez',
    'éblouisse',
    'éblouîtes',
    'éborgnage',
    'éborgnais',
    'éborgnait',
    'éborgnant',
    'éborgnées',
    'éborgnent',
    'éborgnera',
    'éborgniez',
    'éborgnons',
    'ébossâmes',
    'ébossasse',
    'ébossâtes',
    'ébosserai',
    'ébosseras',
    'ébosserez',
    'ébossions',
    'ébouaient',
    'ébouasses',
    'ébouerais',
    'ébouerait',
    'ébouèrent',
    'éboueriez',
    'ébouerons',
    'éboueront',
    'éboulâmes',
    'éboulasse',
    'éboulâtes',
    'éboulerai',
    'ébouleras',
    'éboulerez',
    'éboulions',
    'ébouriffa',
    'ébouriffe',
    'ébouriffé',
    'ébourrais',
    'ébourrait',
    'ébourrant',
    'ébourrées',
    'ébourrent',
    'ébourrera',
    'ébourriez',
    'ébourrons',
    'éboutâmes',
    'éboutasse',
    'éboutâtes',
    'ébouterai',
    'ébouteras',
    'ébouterez',
    'éboutions',
    'ébraisais',
    'ébraisait',
    'ébraisant',
    'ébraisées',
    'ébraisent',
    'ébraisera',
    'ébraisiez',
    'ébraisons',
    'ébranchai',
    'ébranchas',
    'ébranchât',
    'ébranchée',
    'ébrancher',
    'ébranches',
    'ébranchés',
    'ébranchez',
    'ébranlais',
    'ébranlait',
    'ébranlant',
    'ébranlées',
    'ébranlent',
    'ébranlera',
    'ébranliez',
    'ébranlons',
    'ébrasâmes',
    'ébrasasse',
    'ébrasâtes',
    'ébraserai',
    'ébraseras',
    'ébraserez',
    'ébrasions',
    'ébréchais',
    'ébréchait',
    'ébréchant',
    'ébréchées',
    'ébrèchent',
    'ébréchera',
    'ébréchiez',
    'ébréchons',
    'ébréchure',
    'ébrouâmes',
    'ébrouâtes',
    'ébrouerez',
    'ébrouions',
    'ébruitais',
    'ébruitait',
    'ébruitant',
    'ébruitées',
    'ébruitent',
    'ébruitera',
    'ébruitiez',
    'ébruitons',
    'éburnéens',
    'écachâmes',
    'écachasse',
    'écachâtes',
    'écacherai',
    'écacheras',
    'écacherez',
    'écachions',
    'écaffâmes',
    'écaffasse',
    'écaffâtes',
    'écafferai',
    'écafferas',
    'écafferez',
    'écaffions',
    'écaillage',
    'écaillais',
    'écaillait',
    'écaillant',
    'écaillées',
    'écaillent',
    'écaillera',
    'écailleur',
    'écailleux',
    'écailliez',
    'écaillons',
    'écaillure',
    'écalaient',
    'écalasses',
    'écalerais',
    'écalerait',
    'écalèrent',
    'écaleriez',
    'écalerons',
    'écaleront',
    'écanguais',
    'écanguait',
    'écanguant',
    'écanguées',
    'écanguent',
    'écanguera',
    'écanguiez',
    'écanguons',
    'écarlates',
    'écartâmes',
    'écartasse',
    'écartâtes',
    'écartelai',
    'écartelas',
    'écartelât',
    'écartelée',
    'écarteler',
    'écartelés',
    'écartèles',
    'écartelez',
    'écarterai',
    'écarteras',
    'écarterez',
    'écarteurs',
    'écartions',
    'écatirais',
    'écatirait',
    'écatirent',
    'écatiriez',
    'écatirons',
    'écatiront',
    'écatisses',
    'écatissez',
    'ecballium',
    'ecchymose',
    'ecclésial',
    'écervelée',
    'écervelés',
    'échafauda',
    'échafaude',
    'échafaudé',
    'échafauds',
    'échalassa',
    'échalasse',
    'échalassé',
    'échaliers',
    'échalotes',
    'échampies',
    'échampira',
    'échancrai',
    'échancras',
    'échancrât',
    'échancrée',
    'échancrer',
    'échancres',
    'échancrés',
    'échancrez',
    'échangeai',
    'échangeas',
    'échangeât',
    'échangées',
    'échangent',
    'échangera',
    'échangeur',
    'échangiez',
    'échansons',
    'échappais',
    'échappait',
    'échappant',
    'échappées',
    'échappent',
    'échappera',
    'échappiez',
    'échappons',
    'écharnage',
    'écharnais',
    'écharnait',
    'écharnant',
    'écharnées',
    'écharnent',
    'écharnera',
    'écharniez',
    'écharnoir',
    'écharnons',
    'écharpais',
    'écharpait',
    'écharpant',
    'écharpées',
    'écharpent',
    'écharpera',
    'écharpiez',
    'écharpons',
    'échassier',
    'échaudage',
    'échaudais',
    'échaudait',
    'échaudant',
    'échaudées',
    'échaudent',
    'échaudera',
    'échaudiez',
    'échaudons',
    'échauffai',
    'échauffas',
    'échauffât',
    'échauffée',
    'échauffer',
    'échauffes',
    'échauffés',
    'échauffez',
    'échaulais',
    'échaulait',
    'échaulant',
    'échaulées',
    'échaulent',
    'échaulera',
    'échauliez',
    'échaulons',
    'échaumais',
    'échaumait',
    'échaumant',
    'échaumées',
    'échaument',
    'échaumera',
    'échaumiez',
    'échaumons',
    'échéances',
    'échelette',
    'écheliers',
    'échelonna',
    'échelonne',
    'échelonné',
    'échenilla',
    'échenille',
    'échenillé',
    'écheveaux',
    'échevelai',
    'échevelas',
    'échevelât',
    'échevelée',
    'écheveler',
    'échevelés',
    'échevelez',
    'échevelle',
    'échevette',
    'échevinal',
    'échinâmes',
    'échinasse',
    'échinâtes',
    'échinerai',
    'échineras',
    'échinerez',
    'échinions',
    'échiqueté',
    'échiquier',
    'échoirait',
    'échoiront',
    'écholalie',
    'échoppais',
    'échoppait',
    'échoppant',
    'échoppées',
    'échoppent',
    'échoppera',
    'échoppiez',
    'échoppons',
    'échotière',
    'échotiers',
    'échouages',
    'échouâmes',
    'échouasse',
    'échouâtes',
    'échouerai',
    'échoueras',
    'échouerez',
    'échouions',
    'écimaient',
    'écimasses',
    'écimerais',
    'écimerait',
    'écimèrent',
    'écimeriez',
    'écimerons',
    'écimeront',
    'éclairage',
    'éclairais',
    'éclairait',
    'éclairant',
    'éclaircie',
    'éclaircir',
    'éclaircis',
    'éclaircit',
    'éclaircît',
    'éclairées',
    'éclairent',
    'éclairera',
    'éclaireur',
    'éclairiez',
    'éclairons',
    'éclatâmes',
    'éclatante',
    'éclatants',
    'éclatasse',
    'éclatâtes',
    'éclaterai',
    'éclateras',
    'éclaterez',
    'éclateurs',
    'éclations',
    'éclimètre',
    'éclipsais',
    'éclipsait',
    'éclipsant',
    'éclipsées',
    'éclipsent',
    'éclipsera',
    'éclipsiez',
    'éclipsons',
    'éclissais',
    'éclissait',
    'éclissant',
    'éclissées',
    'éclissent',
    'éclissera',
    'éclissiez',
    'éclissons',
    'éclopâmes',
    'éclopasse',
    'éclopâtes',
    'écloperai',
    'écloperas',
    'écloperez',
    'éclopions',
    'éclorions',
    'éclosions',
    'éclusages',
    'éclusâmes',
    'éclusasse',
    'éclusâtes',
    'écluserai',
    'écluseras',
    'écluserez',
    'éclusière',
    'éclusiers',
    'éclusions',
    'écobuages',
    'écobuâmes',
    'écobuasse',
    'écobuâtes',
    'écobuerai',
    'écobueras',
    'écobuerez',
    'écobuions',
    'écoeurais',
    'écoeurait',
    'écoeurant',
    'écoeurées',
    'écoeurent',
    'écoeurera',
    'écoeuriez',
    'écoeurons',
    'écolâtres',
    'écologies',
    'écomusées',
    'éconduira',
    'éconduire',
    'éconduise',
    'éconduite',
    'éconduits',
    'économats',
    'économies',
    'économisa',
    'économise',
    'économisé',
    'écopaient',
    'écopasses',
    'écoperais',
    'écoperait',
    'écoperche',
    'écopèrent',
    'écoperiez',
    'écoperons',
    'écoperont',
    'écoraient',
    'écorasses',
    'écorçâmes',
    'écorçasse',
    'écorçâtes',
    'écorcerai',
    'écorceras',
    'écorcerez',
    'écorceurs',
    'écorchais',
    'écorchait',
    'écorchant',
    'écorchées',
    'écorchent',
    'écorchera',
    'écorcheur',
    'écorchiez',
    'écorchons',
    'écorchure',
    'écorcions',
    'écorerais',
    'écorerait',
    'écorèrent',
    'écoreriez',
    'écorerons',
    'écoreront',
    'écornâmes',
    'écornasse',
    'écornâtes',
    'écornerai',
    'écorneras',
    'écornerez',
    'écornifla',
    'écornifle',
    'écorniflé',
    'écornions',
    'écornures',
    'écossaise',
    'écossâmes',
    'écossasse',
    'écossâtes',
    'écosserai',
    'écosseras',
    'écosserez',
    'écossions',
    'écoulâmes',
    'écoulasse',
    'écoulâtes',
    'écoulerai',
    'écouleras',
    'écoulerez',
    'écoulions',
    'écoumènes',
    'écourtais',
    'écourtait',
    'écourtant',
    'écourtées',
    'écourtent',
    'écourtera',
    'écourtiez',
    'écourtons',
    'écoutâmes',
    'écoutasse',
    'écoutâtes',
    'écouterai',
    'écouteras',
    'écouterez',
    'écouteurs',
    'écoutille',
    'écoutions',
    'écrasâmes',
    'écrasante',
    'écrasants',
    'écrasasse',
    'écrasâtes',
    'écraserai',
    'écraseras',
    'écraserez',
    'écraseurs',
    'écraseuse',
    'écrasions',
    'écrémages',
    'écrémâmes',
    'écrémasse',
    'écrémâtes',
    'écrémerai',
    'écrémeras',
    'écrémerez',
    'écrémeuse',
    'écrémions',
    'écrêtâmes',
    'écrêtasse',
    'écrêtâtes',
    'écrêterai',
    'écrêteras',
    'écrêterez',
    'écrêtions',
    'écrevisse',
    'écrieriez',
    'écrierons',
    'écririons',
    'écriteaux',
    'écritoire',
    'écritures',
    'écrivains',
    'écrivassa',
    'écrivasse',
    'écrivassé',
    'écrivîmes',
    'écrivions',
    'écrivisse',
    'écrivîtes',
    'écrouâmes',
    'écrouasse',
    'écrouâtes',
    'écrouerai',
    'écroueras',
    'écrouerez',
    'écrouîmes',
    'écrouions',
    'écrouirai',
    'écrouiras',
    'écrouirez',
    'écrouisse',
    'écrouîtes',
    'écroulées',
    'écrouliez',
    'écroulons',
    'écroûtais',
    'écroûtait',
    'écroûtant',
    'écroûtées',
    'écroûtent',
    'écroûtera',
    'écroûtiez',
    'écroûtons',
    'ectoderme',
    'ectopique',
    'ectropion',
    'écuissais',
    'écuissait',
    'écuissant',
    'écuissées',
    'écuissent',
    'écuissera',
    'écuissiez',
    'écuissons',
    'éculaient',
    'éculasses',
    'éculerais',
    'éculerait',
    'éculèrent',
    'éculeriez',
    'éculerons',
    'éculeront',
    'écumaient',
    'écumantes',
    'écumasses',
    'écumerais',
    'écumerait',
    'écumèrent',
    'écumeriez',
    'écumerons',
    'écumeront',
    'écumeuses',
    'écumoires',
    'écuraient',
    'écurasses',
    'écurerais',
    'écurerait',
    'écurèrent',
    'écureriez',
    'écurerons',
    'écureront',
    'écureuils',
    'écussonna',
    'écussonne',
    'écussonné',
    'edelweiss',
    'édéniques',
    'édentâmes',
    'édentasse',
    'édentâtes',
    'édenterai',
    'édenteras',
    'édenterez',
    'édentions',
    'édictâmes',
    'édictasse',
    'édictâtes',
    'édicterai',
    'édicteras',
    'édicterez',
    'édictions',
    'édifiâmes',
    'édifiante',
    'édifiants',
    'édifiasse',
    'édifiâtes',
    'édifierai',
    'édifieras',
    'édifierez',
    'édifiions',
    'éditables',
    'éditaient',
    'éditasses',
    'éditerais',
    'éditerait',
    'éditèrent',
    'éditeriez',
    'éditerons',
    'éditeront',
    'éditionna',
    'éditionne',
    'éditionné',
    'éditorial',
    'éditrices',
    'éducables',
    'éducateur',
    'éducatifs',
    'éducation',
    'éducative',
    'édulcorai',
    'édulcoras',
    'édulcorât',
    'édulcorée',
    'édulcorer',
    'édulcores',
    'édulcorés',
    'édulcorez',
    'éduquâmes',
    'éduquasse',
    'éduquâtes',
    'éduquerai',
    'éduqueras',
    'éduquerez',
    'éduquions',
    'éfaufilai',
    'éfaufilas',
    'éfaufilât',
    'éfaufilée',
    'éfaufiler',
    'éfaufiles',
    'éfaufilés',
    'éfaufilez',
    'effaçable',
    'effaçâmes',
    'effaçasse',
    'effaçâtes',
    'effacerai',
    'effaceras',
    'effacerez',
    'effacions',
    'effanâmes',
    'effanasse',
    'effanâtes',
    'effanerai',
    'effaneras',
    'effanerez',
    'effanions',
    'effanures',
    'effarâmes',
    'effarante',
    'effarants',
    'effarasse',
    'effarâtes',
    'effarerai',
    'effareras',
    'effarerez',
    'effarions',
    'effecteur',
    'effectifs',
    'effective',
    'effectuai',
    'effectuas',
    'effectuât',
    'effectuée',
    'effectuer',
    'effectues',
    'effectués',
    'effectuez',
    'efféminai',
    'efféminas',
    'efféminât',
    'efféminée',
    'efféminer',
    'effémines',
    'efféminés',
    'efféminez',
    'efférente',
    'efférents',
    'effeuilla',
    'effeuille',
    'effeuillé',
    'efficaces',
    'efficient',
    'effilages',
    'effilâmes',
    'effilasse',
    'effilâtes',
    'effilerai',
    'effileras',
    'effilerez',
    'effilions',
    'effilocha',
    'effiloche',
    'effiloché',
    'effilures',
    'efflanqua',
    'efflanque',
    'efflanqué',
    'effleurai',
    'effleuras',
    'effleurât',
    'effleurée',
    'effleurer',
    'effleures',
    'effleurés',
    'effleurez',
    'effleurir',
    'effleuris',
    'effleurit',
    'effleurît',
    'effluence',
    'effluente',
    'effluents',
    'effluvais',
    'effluvait',
    'effluvant',
    'effluvent',
    'effluvera',
    'effluviez',
    'effluvons',
    'effondrai',
    'effondras',
    'effondrât',
    'effondrée',
    'effondrer',
    'effondres',
    'effondrés',
    'effondrez',
    'efforcées',
    'efforciez',
    'efforçons',
    'effraient',
    'effraiera',
    'effrangea',
    'effrangée',
    'effranger',
    'effranges',
    'effrangés',
    'effrangez',
    'effrayais',
    'effrayait',
    'effrayant',
    'effrayées',
    'effrayent',
    'effrayera',
    'effrayiez',
    'effrayons',
    'effrénées',
    'effritais',
    'effritait',
    'effritant',
    'effritées',
    'effritent',
    'effritera',
    'effritiez',
    'effritons',
    'effrontée',
    'effrontés',
    'effusions',
    'éfourceau',
    'égaiement',
    'égaierais',
    'égaierait',
    'égaieriez',
    'égaierons',
    'égaieront',
    'égaillées',
    'égailliez',
    'égaillons',
    'égalables',
    'égalaient',
    'égalasses',
    'également',
    'égalerais',
    'égalerait',
    'égalèrent',
    'égaleriez',
    'égalerons',
    'égaleront',
    'égalisais',
    'égalisait',
    'égalisant',
    'égalisées',
    'égalisent',
    'égalisera',
    'égaliseur',
    'égalisiez',
    'égalisons',
    'égaraient',
    'égarasses',
    'égarement',
    'égarerais',
    'égarerait',
    'égarèrent',
    'égareriez',
    'égarerons',
    'égareront',
    'égayaient',
    'égayantes',
    'égayasses',
    'égayement',
    'égayerais',
    'égayerait',
    'égayèrent',
    'égayeriez',
    'égayerons',
    'égayeront',
    'égermages',
    'égermâmes',
    'égermasse',
    'égermâtes',
    'égermerai',
    'égermeras',
    'égermerez',
    'égermions',
    'églantier',
    'églantine',
    'égorgeais',
    'égorgeait',
    'égorgeant',
    'égorgeons',
    'égorgerai',
    'égorgeras',
    'égorgerez',
    'égorgeurs',
    'égorgeuse',
    'égorgions',
    'égosillée',
    'égosiller',
    'égosillés',
    'égosillez',
    'égotismes',
    'égotistes',
    'égoutiers',
    'égouttage',
    'égouttais',
    'égouttait',
    'égouttant',
    'égouttées',
    'égouttent',
    'égouttera',
    'égouttiez',
    'égouttoir',
    'égouttons',
    'égoutture',
    'égrainage',
    'égrainais',
    'égrainait',
    'égrainant',
    'égrainées',
    'égrainent',
    'égrainera',
    'égrainiez',
    'égrainons',
    'égrappage',
    'égrappais',
    'égrappait',
    'égrappant',
    'égrappées',
    'égrappent',
    'égrappera',
    'égrappiez',
    'égrappoir',
    'égrappons',
    'égratigna',
    'égratigne',
    'égratigné',
    'égrenages',
    'égrenâmes',
    'égrenasse',
    'égrenâtes',
    'égrènerai',
    'égrèneras',
    'égrènerez',
    'égreneuse',
    'égrenions',
    'égrillard',
    'égrisages',
    'égrisâmes',
    'égrisasse',
    'égrisâtes',
    'égriserai',
    'égriseras',
    'égriserez',
    'égrisions',
    'égrotante',
    'égrotants',
    'égrugeage',
    'égrugeais',
    'égrugeait',
    'égrugeant',
    'égrugeoir',
    'égrugeons',
    'égrugerai',
    'égrugeras',
    'égrugerez',
    'égrugions',
    'égyptiens',
    'eidétique',
    'éjaculais',
    'éjaculait',
    'éjaculant',
    'éjaculées',
    'éjaculent',
    'éjaculera',
    'éjaculiez',
    'éjaculons',
    'éjectable',
    'éjectâmes',
    'éjectasse',
    'éjectâtes',
    'éjecterai',
    'éjecteras',
    'éjecterez',
    'éjecteurs',
    'éjections',
    'éjointais',
    'éjointait',
    'éjointant',
    'éjointées',
    'éjointent',
    'éjointera',
    'éjointiez',
    'éjointons',
    'élaborais',
    'élaborait',
    'élaborant',
    'élaborées',
    'élaborent',
    'élaborera',
    'élaboriez',
    'élaborons',
    'élaguâmes',
    'élaguasse',
    'élaguâtes',
    'élaguerai',
    'élagueras',
    'élaguerez',
    'élagueurs',
    'élaguions',
    'élançâmes',
    'élançasse',
    'élançâtes',
    'élancerai',
    'élanceras',
    'élancerez',
    'élancions',
    'élargîmes',
    'élargirai',
    'élargiras',
    'élargirez',
    'élargisse',
    'élargîtes',
    'élastique',
    'eldorados',
    'éléatique',
    'électeurs',
    'élections',
    'électives',
    'électoral',
    'électorat',
    'électrice',
    'électrisa',
    'électrise',
    'électrisé',
    'électrode',
    'électrons',
    'électrums',
    'élégances',
    'élégantes',
    'élégiaque',
    'élégirais',
    'élégirait',
    'élégiriez',
    'élégirons',
    'élégiront',
    'élégissez',
    'éléphants',
    'élevaient',
    'élevasses',
    'élévateur',
    'élévation',
    'élèverais',
    'élèverait',
    'élevèrent',
    'élèveriez',
    'élèverons',
    'élèveront',
    'éleveuses',
    'élidaient',
    'élidasses',
    'éliderais',
    'éliderait',
    'élidèrent',
    'élideriez',
    'éliderons',
    'élideront',
    'éligibles',
    'élimaient',
    'élimasses',
    'élimerais',
    'élimerait',
    'élimèrent',
    'élimeriez',
    'élimerons',
    'élimeront',
    'éliminais',
    'éliminait',
    'éliminant',
    'éliminées',
    'éliminent',
    'éliminera',
    'éliminiez',
    'éliminons',
    'élinguais',
    'élinguait',
    'élinguant',
    'élinguées',
    'élinguent',
    'élinguera',
    'élinguiez',
    'élinguons',
    'éliraient',
    'élisaient',
    'élitaires',
    'élitismes',
    'élitistes',
    'ellébores',
    'elle-même',
    'élocution',
    'élogieuse',
    'éloignais',
    'éloignait',
    'éloignant',
    'éloignées',
    'éloignent',
    'éloignera',
    'éloigniez',
    'éloignons',
    'élongeais',
    'élongeait',
    'élongeant',
    'élongeons',
    'élongerai',
    'élongeras',
    'élongerez',
    'élongions',
    'éloquence',
    'éloquente',
    'éloquents',
    'élucidais',
    'élucidait',
    'élucidant',
    'élucidées',
    'élucident',
    'élucidera',
    'élucidiez',
    'élucidons',
    'élucubrai',
    'élucubras',
    'élucubrât',
    'élucubrée',
    'élucubrer',
    'élucubres',
    'élucubrés',
    'élucubrez',
    'éludaient',
    'éludasses',
    'éluderais',
    'éluderait',
    'éludèrent',
    'éluderiez',
    'éluderons',
    'éluderont',
    'élussions',
    'éluviales',
    'élyséenne',
    'émaciâmes',
    'émaciâtes',
    'émacierez',
    'émaciions',
    'émaillage',
    'émaillais',
    'émaillait',
    'émaillant',
    'émaillées',
    'émaillent',
    'émaillera',
    'émailleur',
    'émailliez',
    'émaillons',
    'émaillure',
    'émanaient',
    'émanasses',
    'émanation',
    'émancipai',
    'émancipas',
    'émancipât',
    'émancipée',
    'émanciper',
    'émancipes',
    'émancipés',
    'émancipez',
    'émanerais',
    'émanerait',
    'émanèrent',
    'émaneriez',
    'émanerons',
    'émaneront',
    'émargeais',
    'émargeait',
    'émargeant',
    'émargeons',
    'émargerai',
    'émargeras',
    'émargerez',
    'émargions',
    'émasculai',
    'émasculas',
    'émasculât',
    'émasculée',
    'émasculer',
    'émascules',
    'émasculés',
    'émasculez',
    'emballage',
    'emballais',
    'emballait',
    'emballant',
    'emballées',
    'emballent',
    'emballera',
    'emballeur',
    'emballiez',
    'emballons',
    'embardais',
    'embardait',
    'embardant',
    'embardées',
    'embardent',
    'embardera',
    'embardiez',
    'embardons',
    'embarquai',
    'embarquas',
    'embarquât',
    'embarquée',
    'embarquer',
    'embarques',
    'embarqués',
    'embarquez',
    'embarrais',
    'embarrait',
    'embarrant',
    'embarrées',
    'embarrent',
    'embarrera',
    'embarriez',
    'embarrons',
    'embattage',
    'embattais',
    'embattait',
    'embattant',
    'embattent',
    'embattiez',
    'embattons',
    'embattrai',
    'embattras',
    'embattrez',
    'embattues',
    'embauchai',
    'embauchas',
    'embauchât',
    'embauchée',
    'embaucher',
    'embauches',
    'embauchés',
    'embauchez',
    'embaumais',
    'embaumait',
    'embaumant',
    'embaumées',
    'embaument',
    'embaumera',
    'embaumeur',
    'embaumiez',
    'embaumons',
    'embecquai',
    'embecquas',
    'embecquât',
    'embecquée',
    'embecquer',
    'embecques',
    'embecqués',
    'embecquez',
    'émbéguiné',
    'embellies',
    'embellira',
    'embêtâmes',
    'embêtante',
    'embêtants',
    'embêtasse',
    'embêtâtes',
    'embêterai',
    'embêteras',
    'embêterez',
    'embêtions',
    'embidonna',
    'embidonne',
    'embidonné',
    'emblavage',
    'emblavais',
    'emblavait',
    'emblavant',
    'emblavées',
    'emblavent',
    'emblavera',
    'emblaviez',
    'emblavons',
    'emblavure',
    'embobinai',
    'embobinas',
    'embobinât',
    'embobinée',
    'embobiner',
    'embobines',
    'embobinés',
    'embobinez',
    'emboirons',
    'emboîtage',
    'emboîtais',
    'emboîtait',
    'emboîtant',
    'emboîtées',
    'emboîtent',
    'emboîtera',
    'emboîtiez',
    'emboîtons',
    'emboîture',
    'embolisme',
    'embossage',
    'embossais',
    'embossait',
    'embossant',
    'embossées',
    'embossent',
    'embossera',
    'embossiez',
    'embossons',
    'embottela',
    'embottelé',
    'embouâmes',
    'embouasse',
    'embouâtes',
    'embouchai',
    'embouchas',
    'embouchât',
    'embouchée',
    'emboucher',
    'embouches',
    'embouchés',
    'embouchez',
    'embouerai',
    'emboueras',
    'embouerez',
    'embouions',
    'embouquai',
    'embouquas',
    'embouquât',
    'embouquer',
    'embouques',
    'embouquez',
    'embourbai',
    'embourbas',
    'embourbât',
    'embourbée',
    'embourber',
    'embourbes',
    'embourbés',
    'embourbez',
    'embourrai',
    'embourras',
    'embourrât',
    'embourrée',
    'embourrer',
    'embourres',
    'embourrés',
    'embourrez',
    'embouties',
    'emboutira',
    'embraient',
    'embraiera',
    'embrancha',
    'embranche',
    'embranché',
    'embraquai',
    'embraquas',
    'embraquât',
    'embraquée',
    'embraquer',
    'embraques',
    'embraqués',
    'embraquez',
    'embrasais',
    'embrasait',
    'embrasant',
    'embrasées',
    'embrasent',
    'embrasera',
    'embrasiez',
    'embrasons',
    'embrassai',
    'embrassas',
    'embrassât',
    'embrassée',
    'embrasser',
    'embrasses',
    'embrassés',
    'embrassez',
    'embrasure',
    'embrayage',
    'embrayais',
    'embrayait',
    'embrayant',
    'embrayées',
    'embrayent',
    'embrayera',
    'embrayiez',
    'embrayons',
    'embrelais',
    'embrelait',
    'embrelant',
    'embrelées',
    'embrèlent',
    'embrèlera',
    'embreliez',
    'embrelons',
    'embrevais',
    'embrevait',
    'embrevant',
    'embrevées',
    'embrèvent',
    'embrèvera',
    'embreviez',
    'embrevons',
    'embrigada',
    'embrigade',
    'embrigadé',
    'embringua',
    'embringue',
    'embringué',
    'embrochai',
    'embrochas',
    'embrochât',
    'embrochée',
    'embrocher',
    'embroches',
    'embrochés',
    'embrochez',
    'embroncha',
    'embronche',
    'embronché',
    'embrumais',
    'embrumait',
    'embrumant',
    'embrumées',
    'embrument',
    'embrumera',
    'embrumiez',
    'embrumons',
    'embrunies',
    'embrunira',
    'embuaient',
    'embuasses',
    'embuerais',
    'embuerait',
    'embuèrent',
    'embueriez',
    'embuerons',
    'embueront',
    'embuscade',
    'embusquai',
    'embusquas',
    'embusquât',
    'embusquée',
    'embusquer',
    'embusques',
    'embusqués',
    'embusquez',
    'embussiez',
    'embuvions',
    'éméchâmes',
    'éméchasse',
    'éméchâtes',
    'émécherai',
    'émécheras',
    'émécherez',
    'éméchions',
    'émeraudes',
    'émergeais',
    'émergeait',
    'émergeant',
    'émergence',
    'émergeons',
    'émergerai',
    'émergeras',
    'émergerez',
    'émergions',
    'émerillon',
    'émerisais',
    'émerisait',
    'émerisant',
    'émerisées',
    'émerisent',
    'émerisera',
    'émerisiez',
    'émerisons',
    'émersions',
    'émétiques',
    'émetteurs',
    'émettions',
    'émettrais',
    'émettrait',
    'émettrice',
    'émettriez',
    'émettrons',
    'émettront',
    'émeutière',
    'émeutiers',
    'émiassent',
    'émiassiez',
    'émierions',
    'émiettais',
    'émiettait',
    'émiettant',
    'émiettées',
    'émiettent',
    'émiettera',
    'émiettiez',
    'émiettons',
    'émigrâmes',
    'émigrante',
    'émigrants',
    'émigrasse',
    'émigrâtes',
    'émigrerai',
    'émigreras',
    'émigrerez',
    'émigrions',
    'éminçâmes',
    'éminçasse',
    'éminçâtes',
    'émincerai',
    'éminceras',
    'émincerez',
    'émincions',
    'éminences',
    'éminentes',
    'émissaire',
    'émissions',
    'émissives',
    'émissoles',
    'emmanchai',
    'emmanchas',
    'emmanchât',
    'emmanchée',
    'emmancher',
    'emmanches',
    'emmanchés',
    'emmanchez',
    'emmargeai',
    'emmargeas',
    'emmargeât',
    'emmargées',
    'emmargent',
    'emmargera',
    'emmargiez',
    'emmêlâmes',
    'emmêlasse',
    'emmêlâtes',
    'emmêlerai',
    'emmêleras',
    'emmêlerez',
    'emmêlions',
    'emménagea',
    'emménagée',
    'emménager',
    'emménages',
    'emménagés',
    'emménagez',
    'emmenâmes',
    'emmenasse',
    'emmenâtes',
    'emmènerai',
    'emmèneras',
    'emmènerez',
    'emmenions',
    'emmentals',
    'emmenthal',
    'emmerdais',
    'emmerdait',
    'emmerdant',
    'emmerdées',
    'emmerdent',
    'emmerdera',
    'emmerdeur',
    'emmerdiez',
    'emmerdons',
    'emmétrais',
    'emmétrait',
    'emmétrant',
    'emmétrées',
    'emmètrent',
    'emmétrera',
    'emmétriez',
    'emmétrons',
    'emmétrope',
    'emmiellai',
    'emmiellas',
    'emmiellât',
    'emmiellée',
    'emmieller',
    'emmielles',
    'emmiellés',
    'emmiellez',
    'emmitonna',
    'emmitonne',
    'emmitonné',
    'emmottées',
    'emmurâmes',
    'emmurasse',
    'emmurâtes',
    'emmurerai',
    'emmureras',
    'emmurerez',
    'emmurions',
    'émollient',
    'émolument',
    'émondages',
    'émondâmes',
    'émondasse',
    'émondâtes',
    'émonderai',
    'émonderas',
    'émonderez',
    'émondeurs',
    'émondions',
    'émondoirs',
    'émorfilai',
    'émorfilas',
    'émorfilât',
    'émorfilée',
    'émorfiler',
    'émorfiles',
    'émorfilés',
    'émorfilez',
    'émotionna',
    'émotionne',
    'émotionné',
    'émotivité',
    'émottages',
    'émottâmes',
    'émottasse',
    'émottâtes',
    'émotterai',
    'émotteras',
    'émotterez',
    'émotteurs',
    'émotteuse',
    'émottions',
    'émouchais',
    'émouchait',
    'émouchant',
    'émouchées',
    'émouchent',
    'émouchera',
    'émoucheta',
    'émoucheté',
    'émouchète',
    'émouchets',
    'émouchiez',
    'émouchoir',
    'émouchons',
    'émoudrais',
    'émoudrait',
    'émoudriez',
    'émoudrons',
    'émoudront',
    'émoulages',
    'émouleurs',
    'émoulions',
    'émoulûmes',
    'émoulusse',
    'émoulûtes',
    'émoussais',
    'émoussait',
    'émoussant',
    'émoussées',
    'émoussent',
    'émoussera',
    'émoussiez',
    'émoussons',
    'émouvante',
    'émouvants',
    'émouvions',
    'émouvrais',
    'émouvrait',
    'émouvriez',
    'émouvrons',
    'émouvront',
    'empaillai',
    'empaillas',
    'empaillât',
    'empaillée',
    'empailler',
    'empailles',
    'empaillés',
    'empaillez',
    'empalâmes',
    'empalasse',
    'empalâtes',
    'empalerai',
    'empaleras',
    'empalerez',
    'empalions',
    'empalmais',
    'empalmait',
    'empalmant',
    'empalmées',
    'empalment',
    'empalmera',
    'empalmiez',
    'empalmons',
    'empanacha',
    'empanache',
    'empanaché',
    'empannage',
    'empannais',
    'empannait',
    'empannant',
    'empannées',
    'empannent',
    'empannera',
    'empanniez',
    'empannons',
    'empaqueta',
    'empaqueté',
    'emparâmes',
    'emparâtes',
    'emparerez',
    'emparions',
    'emparquai',
    'emparquas',
    'emparquât',
    'emparquée',
    'emparquer',
    'emparques',
    'emparqués',
    'emparquez',
    'empâtâmes',
    'empâtasse',
    'empâtâtes',
    'empâterai',
    'empâteras',
    'empâterez',
    'empathies',
    'empâtions',
    'empâtrera',
    'empattais',
    'empattait',
    'empattant',
    'empattées',
    'empattent',
    'empattera',
    'empattiez',
    'empattons',
    'empaumais',
    'empaumait',
    'empaumant',
    'empaumées',
    'empaument',
    'empaumera',
    'empaumiez',
    'empaumons',
    'empaumure',
    'empêchais',
    'empêchait',
    'empêchant',
    'empêchées',
    'empêchent',
    'empêchera',
    'empêcheur',
    'empêchiez',
    'empêchons',
    'empeignai',
    'empeignas',
    'empeignât',
    'empeignée',
    'empeigner',
    'empeignes',
    'empeignés',
    'empeignez',
    'empênâmes',
    'empênasse',
    'empênâtes',
    'empênerai',
    'empêneras',
    'empênerez',
    'empênions',
    'empennage',
    'empennais',
    'empennait',
    'empennant',
    'empennées',
    'empennent',
    'empennera',
    'empenniez',
    'empennons',
    'emperchai',
    'emperchas',
    'emperchât',
    'emperchée',
    'empercher',
    'emperches',
    'emperchés',
    'emperchez',
    'empereurs',
    'emperlais',
    'emperlait',
    'emperlant',
    'emperlées',
    'emperlent',
    'emperlera',
    'emperliez',
    'emperlons',
    'empesages',
    'empesâmes',
    'empesasse',
    'empesâtes',
    'empèserai',
    'empèseras',
    'empèserez',
    'empesions',
    'empestais',
    'empestait',
    'empestant',
    'empestées',
    'empestent',
    'empestera',
    'empestiez',
    'empestons',
    'empêtrais',
    'empêtrait',
    'empêtrant',
    'empêtrées',
    'empêtrent',
    'empêtrera',
    'empêtriez',
    'empêtrons',
    'emphysème',
    'empierrai',
    'empierras',
    'empierrât',
    'empierrée',
    'empierrer',
    'empierres',
    'empierrés',
    'empierrez',
    'empiétais',
    'empiétait',
    'empiétant',
    'empiètent',
    'empiétera',
    'empiétiez',
    'empiétons',
    'empiffrée',
    'empiffrer',
    'empiffrés',
    'empiffrez',
    'empilages',
    'empilâmes',
    'empilasse',
    'empilâtes',
    'empilerai',
    'empileras',
    'empilerez',
    'empileurs',
    'empileuse',
    'empilions',
    'empirâmes',
    'empirasse',
    'empirâtes',
    'empirerai',
    'empireras',
    'empirerez',
    'empirions',
    'empirique',
    'empirisme',
    'empiriste',
    'emplâtrai',
    'emplâtras',
    'emplâtrât',
    'emplâtrée',
    'emplâtrer',
    'emplâtres',
    'emplâtrés',
    'emplâtrez',
    'emplettes',
    'emplirais',
    'emplirait',
    'emplirent',
    'empliriez',
    'emplirons',
    'empliront',
    'emplisses',
    'emplissez',
    'emploient',
    'emploiera',
    'employais',
    'employait',
    'employant',
    'employées',
    'employeur',
    'employiez',
    'employons',
    'emplumais',
    'emplumait',
    'emplumant',
    'emplumées',
    'emplument',
    'emplumera',
    'emplumiez',
    'emplumons',
    'empochais',
    'empochait',
    'empochant',
    'empochées',
    'empochent',
    'empochera',
    'empochiez',
    'empochons',
    'empoignai',
    'empoignas',
    'empoignât',
    'empoignée',
    'empoigner',
    'empoignes',
    'empoignés',
    'empoignez',
    'empoissai',
    'empoissas',
    'empoissât',
    'empoissée',
    'empoisser',
    'empoisses',
    'empoissés',
    'empoissez',
    'emportais',
    'emportait',
    'emportant',
    'emportées',
    'emportent',
    'emportera',
    'emportiez',
    'emportons',
    'empotâmes',
    'empotasse',
    'empotâtes',
    'empoterai',
    'empoteras',
    'empoterez',
    'empotions',
    'empourpra',
    'empourpre',
    'empourpré',
    'empreigne',
    'empreinte',
    'empreints',
    'empressée',
    'empresser',
    'empressés',
    'empressez',
    'emprésura',
    'emprésure',
    'emprésuré',
    'empruntai',
    'empruntas',
    'empruntât',
    'empruntée',
    'emprunter',
    'empruntes',
    'empruntés',
    'empruntez',
    'empuantie',
    'empuantir',
    'empuantis',
    'empuantit',
    'empuantît',
    'empyreume',
    'émulaient',
    'émulasses',
    'émulation',
    'émulerais',
    'émulerait',
    'émulèrent',
    'émuleriez',
    'émulerons',
    'émuleront',
    'émulseurs',
    'émulsifia',
    'émulsifie',
    'émulsifié',
    'émulsines',
    'émulsions',
    'émulsives',
    'émussions',
    'enamourée',
    'énamourée',
    'enamourer',
    'énamourer',
    'enamourés',
    'énamourés',
    'enamourez',
    'énamourez',
    'énanthème',
    'encabanai',
    'encabanas',
    'encabanât',
    'encabanée',
    'encabaner',
    'encabanes',
    'encabanés',
    'encabanez',
    'encablure',
    'encadrais',
    'encadrait',
    'encadrant',
    'encadrées',
    'encadrent',
    'encadrera',
    'encadreur',
    'encadriez',
    'encadrons',
    'encageais',
    'encageait',
    'encageant',
    'encageons',
    'encagerai',
    'encageras',
    'encagerez',
    'encagions',
    'encaissai',
    'encaissas',
    'encaissât',
    'encaissée',
    'encaisser',
    'encaisses',
    'encaissés',
    'encaissez',
    'encalminé',
    'encaquais',
    'encaquait',
    'encaquant',
    'encaquées',
    'encaquent',
    'encaquera',
    'encaquiez',
    'encaquons',
    'encartage',
    'encartais',
    'encartait',
    'encartant',
    'encartées',
    'encartent',
    'encartera',
    'encartiez',
    'encartons',
    'encaserna',
    'encaserne',
    'encaserné',
    'encastelé',
    'encastrai',
    'encastras',
    'encastrât',
    'encastrée',
    'encastrer',
    'encastres',
    'encastrés',
    'encastrez',
    'encavages',
    'encavâmes',
    'encavasse',
    'encavâtes',
    'encaverai',
    'encaveras',
    'encaverez',
    'encavions',
    'enceintes',
    'encensais',
    'encensait',
    'encensant',
    'encensées',
    'encensent',
    'encensera',
    'encenseur',
    'encensiez',
    'encensoir',
    'encensons',
    'encéphale',
    'encerclai',
    'encerclas',
    'encerclât',
    'encerclée',
    'encercler',
    'encercles',
    'encerclés',
    'encerclez',
    'enchaînai',
    'enchaînas',
    'enchaînât',
    'enchaînée',
    'enchaîner',
    'enchaînes',
    'enchaînés',
    'enchaînez',
    'enchantai',
    'enchantas',
    'enchantât',
    'enchantée',
    'enchanter',
    'enchantes',
    'enchantés',
    'enchantez',
    'encharnai',
    'encharnas',
    'encharnât',
    'encharnée',
    'encharner',
    'encharnes',
    'encharnés',
    'encharnez',
    'enchâssai',
    'enchâssas',
    'enchâssât',
    'enchâssée',
    'enchâsser',
    'enchâsses',
    'enchâssés',
    'enchâssez',
    'enchaussa',
    'enchausse',
    'enchaussé',
    'enchemisa',
    'enchemise',
    'enchemisé',
    'enchéries',
    'enchérira',
    'encirâmes',
    'encirasse',
    'encirâtes',
    'encirerai',
    'encireras',
    'encirerez',
    'encirions',
    'enclavais',
    'enclavait',
    'enclavant',
    'enclavées',
    'enclavent',
    'enclavera',
    'enclaviez',
    'enclavons',
    'enclencha',
    'enclenche',
    'enclenché',
    'encloîtra',
    'encloître',
    'encloîtré',
    'enclorais',
    'enclorait',
    'encloriez',
    'enclorons',
    'encloront',
    'enclosent',
    'enclosons',
    'enclouais',
    'enclouait',
    'enclouant',
    'enclouées',
    'enclouent',
    'enclouera',
    'enclouiez',
    'enclouons',
    'enclouure',
    'encochais',
    'encochait',
    'encochant',
    'encochées',
    'encochent',
    'encochera',
    'encochiez',
    'encochons',
    'encodages',
    'encodâmes',
    'encodasse',
    'encodâtes',
    'encoderai',
    'encoderas',
    'encoderez',
    'encodeurs',
    'encodions',
    'encoffrai',
    'encoffras',
    'encoffrât',
    'encoffrée',
    'encoffrer',
    'encoffres',
    'encoffrés',
    'encoffrez',
    'encollage',
    'encollais',
    'encollait',
    'encollant',
    'encollées',
    'encollent',
    'encollera',
    'encolleur',
    'encolliez',
    'encollons',
    'encolures',
    'encombrai',
    'encombras',
    'encombrât',
    'encombrée',
    'encombrer',
    'encombres',
    'encombrés',
    'encombrez',
    'encordais',
    'encordait',
    'encordant',
    'encordées',
    'encordent',
    'encordera',
    'encordiez',
    'encordons',
    'encornais',
    'encornait',
    'encornant',
    'encornées',
    'encornent',
    'encornera',
    'encornets',
    'encorniez',
    'encornons',
    'encourage',
    'encouragé',
    'encourais',
    'encourait',
    'encourant',
    'encourent',
    'encouriez',
    'encourons',
    'encourrai',
    'encourras',
    'encourrez',
    'encourues',
    'encraient',
    'encrassai',
    'encrassas',
    'encrassât',
    'encrassée',
    'encrasser',
    'encrasses',
    'encrassés',
    'encrassez',
    'encrêpais',
    'encrêpait',
    'encrêpant',
    'encrêpées',
    'encrêpent',
    'encrêpera',
    'encrêpiez',
    'encrêpons',
    'encrerais',
    'encrerait',
    'encrèrent',
    'encreriez',
    'encrerons',
    'encreront',
    'encrouées',
    'encroûtai',
    'encroûtas',
    'encroûtât',
    'encroûtée',
    'encroûter',
    'encroûtes',
    'encroûtés',
    'encroûtez',
    'encuvages',
    'encuvâmes',
    'encuvasse',
    'encuvâtes',
    'encuverai',
    'encuveras',
    'encuverez',
    'encuvions',
    'endaubais',
    'endaubait',
    'endaubant',
    'endaubées',
    'endaubent',
    'endaubera',
    'endaubiez',
    'endaubons',
    'endémique',
    'endentais',
    'endentait',
    'endentant',
    'endentées',
    'endentent',
    'endentera',
    'endentiez',
    'endentons',
    'endettais',
    'endettait',
    'endettant',
    'endettées',
    'endettent',
    'endettera',
    'endettiez',
    'endettons',
    'endeuilla',
    'endeuille',
    'endeuillé',
    'endiablai',
    'endiablas',
    'endiablât',
    'endiablée',
    'endiabler',
    'endiables',
    'endiablés',
    'endiablez',
    'endigages',
    'endiguais',
    'endiguait',
    'endiguant',
    'endiguées',
    'endiguent',
    'endiguera',
    'endiguiez',
    'endiguons',
    'endocarde',
    'endocarpe',
    'endocrine',
    'endoderme',
    'endogames',
    'endogamie',
    'endogènes',
    'endolorie',
    'endolorir',
    'endoloris',
    'endolorit',
    'endolorît',
    'endomètre',
    'endommage',
    'endommagé',
    'endormais',
    'endormait',
    'endormant',
    'endorment',
    'endormeur',
    'endormies',
    'endormiez',
    'endormira',
    'endormons',
    'endoscope',
    'endosmose',
    'endossais',
    'endossait',
    'endossant',
    'endossées',
    'endossent',
    'endossera',
    'endosseur',
    'endossiez',
    'endossons',
    'enduirais',
    'enduirait',
    'enduiriez',
    'enduirons',
    'enduiront',
    'enduisais',
    'enduisait',
    'enduisant',
    'enduisent',
    'enduisiez',
    'enduisons',
    'endurable',
    'endurâmes',
    'endurance',
    'endurante',
    'endurants',
    'endurasse',
    'endurâtes',
    'endurcies',
    'endurcira',
    'endurerai',
    'endureras',
    'endurerez',
    'endurions',
    'endymions',
    'énergique',
    'énervâmes',
    'énervante',
    'énervants',
    'énervasse',
    'énervâtes',
    'énerverai',
    'énerveras',
    'énerverez',
    'énervions',
    'enfaîtais',
    'enfaîtait',
    'enfaîtant',
    'enfaîteau',
    'enfaîtées',
    'enfaîtent',
    'enfaîtera',
    'enfaîtiez',
    'enfaîtons',
    'enfantais',
    'enfantait',
    'enfantant',
    'enfantées',
    'enfantent',
    'enfantera',
    'enfantiez',
    'enfantine',
    'enfantins',
    'enfantons',
    'enfarinai',
    'enfarinas',
    'enfarinât',
    'enfarinée',
    'enfariner',
    'enfarines',
    'enfarinés',
    'enfarinez',
    'enfermais',
    'enfermait',
    'enfermant',
    'enfermées',
    'enferment',
    'enfermera',
    'enfermiez',
    'enfermons',
    'enferrais',
    'enferrait',
    'enferrant',
    'enferrées',
    'enferrent',
    'enferrera',
    'enferriez',
    'enferrons',
    'enfichais',
    'enfichait',
    'enfichant',
    'enfichées',
    'enfichent',
    'enfichera',
    'enfichiez',
    'enfichons',
    'enfiellai',
    'enfiellas',
    'enfiellât',
    'enfiellée',
    'enfieller',
    'enfielles',
    'enfiellés',
    'enfiellez',
    'enfiévrai',
    'enfiévras',
    'enfiévrât',
    'enfiévrée',
    'enfiévrer',
    'enfiévrés',
    'enfièvres',
    'enfiévrez',
    'enfilades',
    'enfilages',
    'enfilâmes',
    'enfilasse',
    'enfilâtes',
    'enfilerai',
    'enfileras',
    'enfilerez',
    'enfileurs',
    'enfileuse',
    'enfilions',
    'enflaient',
    'enflammai',
    'enflammas',
    'enflammât',
    'enflammée',
    'enflammer',
    'enflammes',
    'enflammés',
    'enflammez',
    'enflasses',
    'enfléchai',
    'enfléchas',
    'enfléchât',
    'enfléchée',
    'enflécher',
    'enfléchés',
    'enflèches',
    'enfléchez',
    'enflerais',
    'enflerait',
    'enflèrent',
    'enfleriez',
    'enflerons',
    'enfleront',
    'enfleurai',
    'enfleuras',
    'enfleurât',
    'enfleurée',
    'enfleurer',
    'enfleures',
    'enfleurés',
    'enfleurez',
    'enfoirées',
    'enfonçais',
    'enfonçait',
    'enfonçant',
    'enfoncées',
    'enfoncent',
    'enfoncera',
    'enfonceur',
    'enfonciez',
    'enfonçons',
    'enfonçure',
    'enforcira',
    'enfouîmes',
    'enfouirai',
    'enfouiras',
    'enfouirez',
    'enfouisse',
    'enfouîtes',
    'enfourcha',
    'enfourche',
    'enfourché',
    'enfournai',
    'enfournas',
    'enfournât',
    'enfournée',
    'enfourner',
    'enfournes',
    'enfournés',
    'enfournez',
    'enfreigne',
    'enfreinte',
    'enfreints',
    'enfuiriez',
    'enfuirons',
    'enfumages',
    'enfumâmes',
    'enfumasse',
    'enfumâtes',
    'enfumerai',
    'enfumeras',
    'enfumerez',
    'enfumions',
    'enfûtages',
    'enfûtâmes',
    'enfûtasse',
    'enfûtâtes',
    'enfûterai',
    'enfûteras',
    'enfûterez',
    'enfûtions',
    'enfuyions',
    'engageais',
    'engageait',
    'engageant',
    'engageons',
    'engagerai',
    'engageras',
    'engagerez',
    'engagions',
    'engainais',
    'engainait',
    'engainant',
    'engainées',
    'engainent',
    'engainera',
    'engainiez',
    'engainons',
    'engamâmes',
    'engamasse',
    'engamâtes',
    'engamerai',
    'engameras',
    'engamerez',
    'engamions',
    'engavâmes',
    'engavasse',
    'engavâtes',
    'engaverai',
    'engaveras',
    'engaverez',
    'engavions',
    'engazonna',
    'engazonne',
    'engazonné',
    'engeances',
    'engelures',
    'engendrai',
    'engendras',
    'engendrât',
    'engendrée',
    'engendrer',
    'engendres',
    'engendrés',
    'engendrez',
    'engerbais',
    'engerbait',
    'engerbant',
    'engerbées',
    'engerbent',
    'engerbera',
    'engerbiez',
    'engerbons',
    'englaçais',
    'englaçait',
    'englaçant',
    'englacées',
    'englacent',
    'englacera',
    'englaciez',
    'englaçons',
    'englobais',
    'englobait',
    'englobant',
    'englobées',
    'englobent',
    'englobera',
    'englobiez',
    'englobons',
    'engloutie',
    'engloutir',
    'engloutis',
    'engloutit',
    'engloutît',
    'engluages',
    'engluâmes',
    'engluasse',
    'engluâtes',
    'engluerai',
    'englueras',
    'engluerez',
    'engluions',
    'engobages',
    'engobâmes',
    'engobasse',
    'engobâtes',
    'engoberai',
    'engoberas',
    'engoberez',
    'engobions',
    'engommage',
    'engommais',
    'engommait',
    'engommant',
    'engommées',
    'engomment',
    'engommera',
    'engommiez',
    'engommons',
    'engonçais',
    'engonçait',
    'engonçant',
    'engoncées',
    'engoncent',
    'engoncera',
    'engonciez',
    'engonçons',
    'engorgeai',
    'engorgeas',
    'engorgeât',
    'engorgées',
    'engorgent',
    'engorgera',
    'engorgiez',
    'engouâmes',
    'engouâtes',
    'engouerez',
    'engouffra',
    'engouffre',
    'engouffré',
    'engouions',
    'engoulais',
    'engoulait',
    'engoulant',
    'engoulées',
    'engoulent',
    'engoulera',
    'engouliez',
    'engoulons',
    'engourdie',
    'engourdir',
    'engourdis',
    'engourdit',
    'engourdît',
    'engraissa',
    'engraisse',
    'engraissé',
    'engrammes',
    'engrangea',
    'engrangée',
    'engranger',
    'engranges',
    'engrangés',
    'engrangez',
    'engravais',
    'engravait',
    'engravant',
    'engravées',
    'engravent',
    'engravera',
    'engraviez',
    'engravons',
    'engrenage',
    'engrenais',
    'engrenait',
    'engrenant',
    'engrenées',
    'engrènent',
    'engrènera',
    'engreneur',
    'engreniez',
    'engrenons',
    'engrossai',
    'engrossas',
    'engrossât',
    'engrossée',
    'engrosser',
    'engrosses',
    'engrossés',
    'engrossez',
    'engrumela',
    'engrumelé',
    'engueulai',
    'engueulas',
    'engueulât',
    'engueulée',
    'engueuler',
    'engueules',
    'engueulés',
    'engueulez',
    'enhardies',
    'enhardira',
    'enherbais',
    'enherbait',
    'enherbant',
    'enherbées',
    'enherbent',
    'enherbera',
    'enherbiez',
    'enherbons',
    'éniellais',
    'éniellait',
    'éniellant',
    'éniellées',
    'éniellent',
    'éniellera',
    'énielliez',
    'éniellons',
    'enivrâmes',
    'enivrante',
    'enivrants',
    'enivrasse',
    'enivrâtes',
    'enivrerai',
    'enivreras',
    'enivrerez',
    'enivrions',
    'enjambais',
    'enjambait',
    'enjambant',
    'enjambées',
    'enjambent',
    'enjambera',
    'enjambiez',
    'enjambons',
    'enjavelai',
    'enjavelas',
    'enjavelât',
    'enjavelée',
    'enjaveler',
    'enjavelés',
    'enjavelez',
    'enjavelle',
    'enjoignes',
    'enjoignez',
    'enjoignis',
    'enjoignit',
    'enjoignît',
    'enjoindra',
    'enjoindre',
    'enjointes',
    'enjôlâmes',
    'enjôlasse',
    'enjôlâtes',
    'enjôlerai',
    'enjôleras',
    'enjôlerez',
    'enjôleurs',
    'enjôleuse',
    'enjôlions',
    'enjolivai',
    'enjolivas',
    'enjolivât',
    'enjolivée',
    'enjoliver',
    'enjolives',
    'enjolivés',
    'enjolivez',
    'enjonçais',
    'enjonçait',
    'enjonçant',
    'enjoncées',
    'enjoncent',
    'enjoncera',
    'enjonciez',
    'enjonçons',
    'enjouâmes',
    'enjouasse',
    'enjouâtes',
    'enjouerai',
    'enjoueras',
    'enjouerez',
    'enjouions',
    'enjuguais',
    'enjuguait',
    'enjuguant',
    'enjuguées',
    'enjuguent',
    'enjuguera',
    'enjuguiez',
    'enjuguons',
    'enjuivais',
    'enjuivait',
    'enjuivant',
    'enjuivées',
    'enjuivent',
    'enjuivera',
    'enjuiviez',
    'enjuivons',
    'enjuponna',
    'enjuponne',
    'enjuponné',
    'enkystées',
    'enkystiez',
    'enkystons',
    'enlaçâmes',
    'enlaçasse',
    'enlaçâtes',
    'enlacerai',
    'enlaceras',
    'enlacerez',
    'enlacions',
    'enlaidies',
    'enlaidira',
    'enlevages',
    'enlevâmes',
    'enlevasse',
    'enlevâtes',
    'enlèverai',
    'enlèveras',
    'enlèverez',
    'enlevions',
    'enlevures',
    'enliaient',
    'enliassai',
    'enliassas',
    'enliassât',
    'enliassée',
    'enliasser',
    'enliasses',
    'enliassés',
    'enliassez',
    'enlierais',
    'enlierait',
    'enlièrent',
    'enlieriez',
    'enlierons',
    'enlieront',
    'enlignais',
    'enlignait',
    'enlignant',
    'enlignées',
    'enlignent',
    'enlignera',
    'enligniez',
    'enlignons',
    'enlisâmes',
    'enlisasse',
    'enlisâtes',
    'enliserai',
    'enliseras',
    'enliserez',
    'enlisions',
    'enluminai',
    'enluminas',
    'enluminât',
    'enluminée',
    'enluminer',
    'enlumines',
    'enluminés',
    'enluminez',
    'ennéagone',
    'enneigeai',
    'enneigeas',
    'enneigeât',
    'enneigées',
    'enneigent',
    'enneigera',
    'enneigiez',
    'ennoblies',
    'ennoblira',
    'ennuageai',
    'ennuageas',
    'ennuageât',
    'ennuagées',
    'ennuagent',
    'ennuagera',
    'ennuagiez',
    'ennuierai',
    'ennuieras',
    'ennuierez',
    'ennuyâmes',
    'ennuyasse',
    'ennuyâtes',
    'ennuyeuse',
    'ennuyions',
    'énonçâmes',
    'énonçasse',
    'énonçâtes',
    'énoncerai',
    'énonceras',
    'énoncerez',
    'énoncions',
    'énormités',
    'énostoses',
    'énouaient',
    'énouasses',
    'énouerais',
    'énouerait',
    'énouèrent',
    'énoueriez',
    'énouerons',
    'énoueront',
    'enquériez',
    'enquérons',
    'enquerrez',
    'enquêtais',
    'enquêtait',
    'enquêtant',
    'enquêtent',
    'enquêtera',
    'enquêteur',
    'enquêtiez',
    'enquêtons',
    'enracinai',
    'enracinas',
    'enracinât',
    'enracinée',
    'enraciner',
    'enracines',
    'enracinés',
    'enracinez',
    'enrageais',
    'enrageait',
    'enrageant',
    'enrageons',
    'enragerai',
    'enrageras',
    'enragerez',
    'enragions',
    'enraierai',
    'enraieras',
    'enraierez',
    'enraillai',
    'enraillas',
    'enraillât',
    'enraillée',
    'enrailler',
    'enrailles',
    'enraillés',
    'enraillez',
    'enrayages',
    'enrayâmes',
    'enrayasse',
    'enrayâtes',
    'enrayerai',
    'enrayeras',
    'enrayerez',
    'enrayions',
    'enrayoirs',
    'enrayures',
    'enrênâmes',
    'enrênasse',
    'enrênâtes',
    'enrênerai',
    'enrêneras',
    'enrênerez',
    'enrênions',
    'enrhumais',
    'enrhumait',
    'enrhumant',
    'enrhumées',
    'enrhument',
    'enrhumera',
    'enrhumiez',
    'enrhumons',
    'enrichies',
    'enrichira',
    'enrobages',
    'enrobâmes',
    'enrobasse',
    'enrobâtes',
    'enroberai',
    'enroberas',
    'enroberez',
    'enrobeuse',
    'enrobions',
    'enrochais',
    'enrochait',
    'enrochant',
    'enrochées',
    'enrochent',
    'enrochera',
    'enrochiez',
    'enrochons',
    'enrôlâmes',
    'enrôlasse',
    'enrôlâtes',
    'enrôlerai',
    'enrôleras',
    'enrôlerez',
    'enrôleurs',
    'enrôlions',
    'enrouâmes',
    'enrouasse',
    'enrouâtes',
    'enrouerai',
    'enroueras',
    'enrouerez',
    'enrouilla',
    'enrouille',
    'enrouillé',
    'enrouions',
    'enroulais',
    'enroulait',
    'enroulant',
    'enroulées',
    'enroulent',
    'enroulera',
    'enrouleur',
    'enrouliez',
    'enroulons',
    'enrubanna',
    'enrubanne',
    'enrubanné',
    'ensablais',
    'ensablait',
    'ensablant',
    'ensablées',
    'ensablent',
    'ensablera',
    'ensabliez',
    'ensablons',
    'ensabotai',
    'ensabotas',
    'ensabotât',
    'ensabotée',
    'ensaboter',
    'ensabotes',
    'ensabotés',
    'ensabotez',
    'ensachage',
    'ensachais',
    'ensachait',
    'ensachant',
    'ensachées',
    'ensachent',
    'ensachera',
    'ensacheur',
    'ensachiez',
    'ensachons',
    'ensaisina',
    'ensaisine',
    'ensaisiné',
    'ensauvées',
    'ensauviez',
    'ensauvons',
    'enseignai',
    'enseignas',
    'enseignât',
    'enseignée',
    'enseigner',
    'enseignes',
    'enseignés',
    'enseignez',
    'ensembles',
    'ensemença',
    'ensemence',
    'ensemencé',
    'enserrais',
    'enserrait',
    'enserrant',
    'enserrées',
    'enserrent',
    'enserrera',
    'enserriez',
    'enserrons',
    'ensevelie',
    'ensevelir',
    'ensevelis',
    'ensevelit',
    'ensevelît',
    'ensiforme',
    'ensilages',
    'ensilâmes',
    'ensilasse',
    'ensilâtes',
    'ensilerai',
    'ensileras',
    'ensilerez',
    'ensilions',
    'ensorcela',
    'ensorcelé',
    'ensoufrai',
    'ensoufras',
    'ensoufrât',
    'ensoufrée',
    'ensoufrer',
    'ensoufres',
    'ensoufrés',
    'ensoufrez',
    'ensouples',
    'enstérais',
    'enstérait',
    'enstérant',
    'enstérées',
    'enstèrent',
    'enstérera',
    'enstériez',
    'enstérons',
    'ensuivies',
    'entablais',
    'entablait',
    'entablant',
    'entablées',
    'entablent',
    'entablera',
    'entabliez',
    'entablons',
    'entachais',
    'entachait',
    'entachant',
    'entachées',
    'entachent',
    'entachera',
    'entachiez',
    'entachons',
    'entaillai',
    'entaillas',
    'entaillât',
    'entaillée',
    'entailler',
    'entailles',
    'entaillés',
    'entaillez',
    'entamâmes',
    'entamasse',
    'entamâtes',
    'entamerai',
    'entameras',
    'entamerez',
    'entamions',
    'entaquais',
    'entaquait',
    'entaquant',
    'entaquées',
    'entaquent',
    'entaquera',
    'entaquiez',
    'entaquons',
    'entartrai',
    'entartras',
    'entartrât',
    'entartrée',
    'entartrer',
    'entartres',
    'entartrés',
    'entartrez',
    'entassais',
    'entassait',
    'entassant',
    'entassées',
    'entassent',
    'entassera',
    'entassiez',
    'entassons',
    'entendais',
    'entendait',
    'entendant',
    'entendent',
    'entendeur',
    'entendiez',
    'entendons',
    'entendrai',
    'entendras',
    'entendrez',
    'entendues',
    'enténébra',
    'enténébré',
    'enténèbre',
    'entérinai',
    'entérinas',
    'entérinât',
    'entérinée',
    'entériner',
    'entérines',
    'entérinés',
    'entérinez',
    'enterions',
    'entérique',
    'entérites',
    'enterrage',
    'enterrais',
    'enterrait',
    'enterrant',
    'enterrées',
    'enterrent',
    'enterrera',
    'enterriez',
    'enterrons',
    'entêtâmes',
    'entêtante',
    'entêtants',
    'entêtasse',
    'entêtâtes',
    'entêterai',
    'entêteras',
    'entêterez',
    'entêtions',
    'enthalpie',
    'enthymème',
    'entichées',
    'entichiez',
    'entichons',
    'entièreté',
    'entoilage',
    'entoilais',
    'entoilait',
    'entoilant',
    'entoilées',
    'entoilent',
    'entoilera',
    'entoiliez',
    'entoilons',
    'entôlages',
    'entôlâmes',
    'entôlasse',
    'entôlâtes',
    'entôlerai',
    'entôleras',
    'entôlerez',
    'entôlions',
    'entolomes',
    'entonnage',
    'entonnais',
    'entonnait',
    'entonnant',
    'entonnées',
    'entonnent',
    'entonnera',
    'entonniez',
    'entonnoir',
    'entonnons',
    'entourage',
    'entourais',
    'entourait',
    'entourant',
    'entourées',
    'entourent',
    'entourera',
    'entouriez',
    'entourons',
    'entractes',
    'entraidée',
    'entraider',
    'entraides',
    'entraidés',
    'entraidez',
    'entraient',
    'entraînai',
    'entraînas',
    'entraînât',
    'entraînée',
    'entraîner',
    'entraînes',
    'entraînés',
    'entraînez',
    'entrantes',
    'entrasses',
    'entravais',
    'entravait',
    'entravant',
    'entravées',
    'entravent',
    'entravera',
    'entraviez',
    'entravons',
    'entrechat',
    'entrecôte',
    'entre-eux',
    'entrefers',
    'entregent',
    'entre-haï',
    'entrelaça',
    'entrelace',
    'entrelacé',
    'entrelacs',
    'entremêla',
    'entremêle',
    'entremêlé',
    'entremets',
    'entremise',
    'entre-nui',
    'entrepont',
    'entreposa',
    'entrepose',
    'entreposé',
    'entrepôts',
    'entrepris',
    'entreprit',
    'entreprît',
    'entrerais',
    'entrerait',
    'entrèrent',
    'entreriez',
    'entrerons',
    'entreront',
    'entresols',
    'entretenu',
    'entretien',
    'entretins',
    'entretint',
    'entretînt',
    'entre-tué',
    'entrevoie',
    'entrevoir',
    'entrevois',
    'entrevoit',
    'entrevous',
    'entrevues',
    'entrismes',
    'entropies',
    'entropion',
    'entroques',
    'entrouvre',
    'entubâmes',
    'entubasse',
    'entubâtes',
    'entuberai',
    'entuberas',
    'entuberez',
    'entubions',
    'énucléais',
    'énucléait',
    'énucléant',
    'énucléées',
    'énucléent',
    'énucléera',
    'énucléiez',
    'énucléons',
    'énumérais',
    'énumérait',
    'énumérant',
    'énumérées',
    'énumèrent',
    'énumérera',
    'énumériez',
    'énumérons',
    'énurésies',
    'envahîmes',
    'envahirai',
    'envahiras',
    'envahirez',
    'envahisse',
    'envahîtes',
    'envasâmes',
    'envasasse',
    'envasâtes',
    'envaserai',
    'envaseras',
    'envaserez',
    'envasions',
    'enveloppa',
    'enveloppe',
    'enveloppé',
    'envenimai',
    'envenimas',
    'envenimât',
    'envenimée',
    'envenimer',
    'envenimes',
    'envenimés',
    'envenimez',
    'envergeai',
    'envergeas',
    'envergeât',
    'envergées',
    'envergent',
    'envergera',
    'envergiez',
    'enverguai',
    'enverguas',
    'enverguât',
    'enverguée',
    'enverguer',
    'envergues',
    'envergués',
    'enverguez',
    'envergure',
    'enverrais',
    'enverrait',
    'enverriez',
    'enverrons',
    'enverront',
    'enviables',
    'enviaient',
    'enviasses',
    'envidâmes',
    'envidasse',
    'envidâtes',
    'enviderai',
    'envideras',
    'enviderez',
    'envidions',
    'envieilli',
    'envierais',
    'envierait',
    'envièrent',
    'envieriez',
    'envierons',
    'envieront',
    'envieuses',
    'environna',
    'environne',
    'environné',
    'envisagea',
    'envisagée',
    'envisager',
    'envisages',
    'envisagés',
    'envisagez',
    'envoilées',
    'envoiliez',
    'envoilons',
    'envolâmes',
    'envolâtes',
    'envolerez',
    'envolions',
    'envoûtais',
    'envoûtait',
    'envoûtant',
    'envoûtées',
    'envoûtent',
    'envoûtera',
    'envoûteur',
    'envoûtiez',
    'envoûtons',
    'envoyâmes',
    'envoyasse',
    'envoyâtes',
    'envoyeurs',
    'envoyeuse',
    'envoyions',
    'enzooties',
    'éoliennes',
    'éolipiles',
    'épagneuls',
    'épaisseur',
    'épaissies',
    'épaissira',
    'épalaient',
    'épalasses',
    'épalerais',
    'épalerait',
    'épalèrent',
    'épaleriez',
    'épalerons',
    'épaleront',
    'épamprage',
    'épamprais',
    'épamprait',
    'épamprant',
    'épamprées',
    'épamprent',
    'épamprera',
    'épampriez',
    'épamprons',
    'épanchais',
    'épanchait',
    'épanchant',
    'épanchées',
    'épanchent',
    'épanchera',
    'épanchiez',
    'épanchons',
    'épandages',
    'épandeurs',
    'épandeuse',
    'épandîmes',
    'épandions',
    'épandisse',
    'épandîtes',
    'épandrais',
    'épandrait',
    'épandriez',
    'épandrons',
    'épandront',
    'épannâmes',
    'épannasse',
    'épannâtes',
    'épannelai',
    'épannelas',
    'épannelât',
    'épannelée',
    'épanneler',
    'épannelés',
    'épannelez',
    'épannelle',
    'épannerai',
    'épanneras',
    'épannerez',
    'épannions',
    'épanouies',
    'épanouira',
    'éparchies',
    'épargnais',
    'épargnait',
    'épargnant',
    'épargnées',
    'épargnent',
    'épargnera',
    'épargniez',
    'épargnons',
    'éparpilla',
    'éparpille',
    'éparpillé',
    'épataient',
    'épatantes',
    'épatasses',
    'épatement',
    'épaterais',
    'épaterait',
    'épatèrent',
    'épateriez',
    'épaterons',
    'épateront',
    'épateuses',
    'épaufrais',
    'épaufrait',
    'épaufrant',
    'épaufrées',
    'épaufrent',
    'épaufrera',
    'épaufriez',
    'épaufrons',
    'épaufrure',
    'épaulâmes',
    'épaulards',
    'épaulasse',
    'épaulâtes',
    'épaulerai',
    'épauleras',
    'épaulerez',
    'épaulette',
    'épaulière',
    'épaulions',
    'épeautres',
    'épelaient',
    'épelasses',
    'épelèrent',
    'épellerai',
    'épelleras',
    'épellerez',
    'épendymes',
    'épenthèse',
    'épépinais',
    'épépinait',
    'épépinant',
    'épépinées',
    'épépinent',
    'épépinera',
    'épépiniez',
    'épépinons',
    'éperdîmes',
    'éperdions',
    'éperdîtes',
    'éperdriez',
    'éperdrons',
    'éperonnai',
    'éperonnas',
    'éperonnât',
    'éperonnée',
    'éperonner',
    'éperonnes',
    'éperonnés',
    'éperonnez',
    'épervière',
    'éperviers',
    'épeulâmes',
    'épeulasse',
    'épeulâtes',
    'épeulerai',
    'épeuleras',
    'épeulerez',
    'épeulions',
    'épeurâmes',
    'épeurasse',
    'épeurâtes',
    'épeurerai',
    'épeureras',
    'épeurerez',
    'épeurions',
    'éphémères',
    'épiaisons',
    'épiassent',
    'épiassiez',
    'épiçaient',
    'épicarpes',
    'épiçasses',
    'épicentre',
    'épicerais',
    'épicerait',
    'épicèrent',
    'épiceries',
    'épiceriez',
    'épicerons',
    'épiceront',
    'épicières',
    'épicurien',
    'épicycles',
    'épidémies',
    'épidermes',
    'épididyme',
    'épierions',
    'épierrage',
    'épierrais',
    'épierrait',
    'épierrant',
    'épierrées',
    'épierrent',
    'épierrera',
    'épierriez',
    'épierrons',
    'épigastre',
    'épiglotte',
    'épigramme',
    'épigraphe',
    'épilaient',
    'épilasses',
    'épilation',
    'épilepsie',
    'épilerais',
    'épilerait',
    'épilèrent',
    'épileriez',
    'épilerons',
    'épileront',
    'épiloguai',
    'épiloguas',
    'épiloguât',
    'épiloguée',
    'épiloguer',
    'épilogues',
    'épilogués',
    'épiloguez',
    'épinaient',
    'épinasses',
    'épinçâmes',
    'épinçasse',
    'épinçâtes',
    'épincelai',
    'épincelas',
    'épincelât',
    'épincelée',
    'épinceler',
    'épincelés',
    'épincèles',
    'épincelez',
    'épincerai',
    'épinceras',
    'épincerez',
    'épincetai',
    'épincetas',
    'épincetât',
    'épincetée',
    'épinceter',
    'épincetés',
    'épincètes',
    'épincetez',
    'épincions',
    'épinerais',
    'épinerait',
    'épinèrent',
    'épineriez',
    'épinerons',
    'épineront',
    'épinettes',
    'épineuses',
    'épinglage',
    'épinglais',
    'épinglait',
    'épinglant',
    'épinglées',
    'épinglent',
    'épinglera',
    'épinglier',
    'épingliez',
    'épinglons',
    'épinières',
    'épinoches',
    'épiphanes',
    'épiphanie',
    'épiphyses',
    'épiphytes',
    'épiphytie',
    'épiploons',
    'épiscopal',
    'épiscopat',
    'épiscopes',
    'épissâmes',
    'épissasse',
    'épissâtes',
    'épisserai',
    'épisseras',
    'épisserez',
    'épissions',
    'épissoirs',
    'épissures',
    'épistasie',
    'épistaxis',
    'épistyles',
    'épitaphes',
    'épithèmes',
    'épithètes',
    'épizootie',
    'éploierai',
    'éploieras',
    'éploierez',
    'éployâmes',
    'éployasse',
    'éployâtes',
    'éployions',
    'épluchage',
    'épluchais',
    'épluchait',
    'épluchant',
    'épluchées',
    'épluchent',
    'épluchera',
    'éplucheur',
    'épluchiez',
    'épluchons',
    'épluchure',
    'épointage',
    'épointais',
    'épointait',
    'épointant',
    'épointées',
    'épointent',
    'épointera',
    'épointiez',
    'épointons',
    'épongeage',
    'épongeais',
    'épongeait',
    'épongeant',
    'épongeons',
    'épongerai',
    'épongeras',
    'épongerez',
    'épongions',
    'épontilla',
    'épontille',
    'épontillé',
    'éponymies',
    'épouillai',
    'épouillas',
    'épouillât',
    'épouillée',
    'épouiller',
    'épouilles',
    'épouillés',
    'épouillez',
    'époumonai',
    'époumonas',
    'époumonât',
    'époumonée',
    'époumoner',
    'époumones',
    'époumonés',
    'époumonez',
    'épousâmes',
    'épousasse',
    'épousâtes',
    'épouserai',
    'épouseras',
    'épouserez',
    'épouseurs',
    'épousions',
    'épousseta',
    'épousseté',
    'époutiais',
    'époutiait',
    'époutiant',
    'époutiées',
    'époutient',
    'époutiera',
    'époutiiez',
    'époutîmes',
    'époutions',
    'époutirai',
    'époutiras',
    'époutirez',
    'époutisse',
    'époutîtes',
    'épouvanta',
    'épouvante',
    'épouvanté',
    'épreignes',
    'épreignez',
    'épreindra',
    'épreindre',
    'épreintes',
    'éprendrez',
    'éprenions',
    'éprissiez',
    'éprouvais',
    'éprouvait',
    'éprouvant',
    'éprouvées',
    'éprouvent',
    'éprouvera',
    'éprouviez',
    'éprouvons',
    'epsomites',
    'épuçaient',
    'épuçasses',
    'épucerais',
    'épucerait',
    'épucèrent',
    'épuceriez',
    'épucerons',
    'épuceront',
    'épuisable',
    'épuisâmes',
    'épuisante',
    'épuisants',
    'épuisasse',
    'épuisâtes',
    'épuiserai',
    'épuiseras',
    'épuiserez',
    'épuisette',
    'épuisions',
    'épulpeurs',
    'épuraient',
    'épurasses',
    'épurateur',
    'épuratifs',
    'épuration',
    'épurative',
    'épurement',
    'épurerais',
    'épurerait',
    'épurèrent',
    'épureriez',
    'épurerons',
    'épureront',
    'équarries',
    'équarrira',
    'équateurs',
    'équations',
    'équerrage',
    'équerrais',
    'équerrait',
    'équerrant',
    'équerrées',
    'équerrent',
    'équerrera',
    'équerriez',
    'équerrons',
    'équestres',
    'équeutage',
    'équeutais',
    'équeutait',
    'équeutant',
    'équeutées',
    'équeutent',
    'équeutera',
    'équeutiez',
    'équeutons',
    'équiangle',
    'équilibra',
    'équilibre',
    'équilibré',
    'équinisme',
    'équinoxes',
    'équipages',
    'équipâmes',
    'équipasse',
    'équipâtes',
    'équiperai',
    'équiperas',
    'équiperez',
    'équipière',
    'équipiers',
    'équipions',
    'équipolés',
    'équipollé',
    'équitable',
    'équitante',
    'équitants',
    'équivalez',
    'équivalus',
    'équivalut',
    'équivalût',
    'équivoqua',
    'équivoque',
    'équivoqué',
    'érablière',
    'éradiquai',
    'éradiquas',
    'éradiquât',
    'éradiquée',
    'éradiquer',
    'éradiques',
    'éradiqués',
    'éradiquez',
    'éraflâmes',
    'éraflasse',
    'éraflâtes',
    'éraflerai',
    'érafleras',
    'éraflerez',
    'éraflions',
    'éraflures',
    'éraierais',
    'éraierait',
    'éraieriez',
    'éraierons',
    'éraieront',
    'éraillais',
    'éraillait',
    'éraillant',
    'éraillées',
    'éraillent',
    'éraillera',
    'érailliez',
    'éraillons',
    'éraillure',
    'érayaient',
    'érayasses',
    'érayerais',
    'érayerait',
    'érayèrent',
    'érayeriez',
    'érayerons',
    'érayeront',
    'érecteurs',
    'érectiles',
    'érections',
    'érectrice',
    'éreintage',
    'éreintais',
    'éreintait',
    'éreintant',
    'éreintées',
    'éreintent',
    'éreintera',
    'éreinteur',
    'éreintiez',
    'éreintons',
    'érepsines',
    'érésipèle',
    'éréthisme',
    'ergastule',
    'ergonomes',
    'ergonomie',
    'ergotages',
    'ergotâmes',
    'ergotasse',
    'ergotâtes',
    'ergoterai',
    'ergoteras',
    'ergoterez',
    'ergoteurs',
    'ergoteuse',
    'ergotines',
    'ergotions',
    'ergotisme',
    'érigeâmes',
    'érigeasse',
    'érigeâtes',
    'érigerais',
    'érigerait',
    'érigèrent',
    'érigeriez',
    'érigerons',
    'érigérons',
    'érigeront',
    'éristales',
    'éristique',
    'ermitages',
    'érodaient',
    'érodasses',
    'éroderais',
    'éroderait',
    'érodèrent',
    'éroderiez',
    'éroderons',
    'éroderont',
    'érotiques',
    'érotisais',
    'érotisait',
    'érotisant',
    'érotisées',
    'érotisent',
    'érotisera',
    'érotisiez',
    'érotismes',
    'érotisons',
    'érotomane',
    'errassent',
    'errassiez',
    'erratique',
    'errements',
    'errerions',
    'éructâmes',
    'éructasse',
    'éructâtes',
    'éructerai',
    'éructeras',
    'éructerez',
    'éructions',
    'érudition',
    'érugineux',
    'éruptions',
    'éruptives',
    'érysipèle',
    'érythèmes',
    'érythrine',
    'esbaudies',
    'esbignées',
    'esbigniez',
    'esbignons',
    'esbroufai',
    'esbroufas',
    'esbroufât',
    'esbroufée',
    'esbroufer',
    'esbroufes',
    'esbroufés',
    'esbroufez',
    'escabeaux',
    'escabelle',
    'escadrons',
    'escaladai',
    'escaladas',
    'escaladât',
    'escaladée',
    'escalader',
    'escalades',
    'escaladés',
    'escaladez',
    'Escalator',
    'escaliers',
    'escalopes',
    'escamotai',
    'escamotas',
    'escamotât',
    'escamotée',
    'escamoter',
    'escamotes',
    'escamotés',
    'escamotez',
    'escapades',
    'escarbots',
    'escargots',
    'escaroles',
    'escarpées',
    'escarpins',
    'eschaient',
    'eschasses',
    'escherais',
    'escherait',
    'eschèrent',
    'escheriez',
    'escherons',
    'escheront',
    'esclaffée',
    'esclaffer',
    'esclaffés',
    'esclaffez',
    'esclandre',
    'esclavage',
    'esclavons',
    'escomptai',
    'escomptas',
    'escomptât',
    'escomptée',
    'escompter',
    'escomptes',
    'escomptés',
    'escomptez',
    'escopette',
    'escortais',
    'escortait',
    'escortant',
    'escortées',
    'escortent',
    'escortera',
    'escorteur',
    'escortiez',
    'escortons',
    'escouades',
    'escrimées',
    'escrimeur',
    'escrimiez',
    'escrimons',
    'escroquai',
    'escroquas',
    'escroquât',
    'escroquée',
    'escroquer',
    'escroques',
    'escroqués',
    'escroquez',
    'esculapes',
    'esculines',
    'esgourdes',
    'espaçâmes',
    'espaçasse',
    'espaçâtes',
    'espacerai',
    'espaceras',
    'espacerez',
    'espacions',
    'espagnole',
    'espagnols',
    'espaliers',
    'esparcets',
    'espérâmes',
    'espérance',
    'espéranto',
    'espérasse',
    'espérâtes',
    'espérerai',
    'espéreras',
    'espérerez',
    'espérions',
    'espiègles',
    'espingole',
    'espionite',
    'espionnai',
    'espionnas',
    'espionnât',
    'espionnée',
    'espionner',
    'espionnes',
    'espionnés',
    'espionnez',
    'esplanade',
    'espolinai',
    'espolinas',
    'espolinât',
    'espolinée',
    'espoliner',
    'espolines',
    'espolinés',
    'espolinez',
    'espontons',
    'espoulina',
    'espouline',
    'espouliné',
    'esquarres',
    'esquilles',
    'esquimaud',
    'Esquimaux',
    'esquintai',
    'esquintas',
    'esquintât',
    'esquintée',
    'esquinter',
    'esquintes',
    'esquintés',
    'esquintez',
    'esquissai',
    'esquissas',
    'esquissât',
    'esquissée',
    'esquisser',
    'esquisses',
    'esquissés',
    'esquissez',
    'esquivais',
    'esquivait',
    'esquivant',
    'esquivées',
    'esquivent',
    'esquivera',
    'esquiviez',
    'esquivons',
    'essaierai',
    'essaieras',
    'essaierez',
    'essaimage',
    'essaimais',
    'essaimait',
    'essaimant',
    'essaiment',
    'essaimera',
    'essaimiez',
    'essaimons',
    'essangeai',
    'essangeas',
    'essangeât',
    'essangées',
    'essangent',
    'essangera',
    'essangiez',
    'essanvage',
    'essartage',
    'essartais',
    'essartait',
    'essartant',
    'essartées',
    'essartent',
    'essartera',
    'essartiez',
    'essartons',
    'essayages',
    'essayâmes',
    'essayasse',
    'essayâtes',
    'essayerai',
    'essayeras',
    'essayerez',
    'essayeurs',
    'essayeuse',
    'essayions',
    'essayiste',
    'essentiel',
    'esseulées',
    'essorages',
    'essorâmes',
    'essorasse',
    'essorâtes',
    'essorerai',
    'essoreras',
    'essorerez',
    'essoreuse',
    'essorilla',
    'essorille',
    'essorillé',
    'essorions',
    'essouchai',
    'essouchas',
    'essouchât',
    'essouchée',
    'essoucher',
    'essouches',
    'essouchés',
    'essouchez',
    'essouffla',
    'essouffle',
    'essoufflé',
    'essuierai',
    'essuieras',
    'essuierez',
    'essuyages',
    'essuyâmes',
    'essuyasse',
    'essuyâtes',
    'essuyeurs',
    'essuyeuse',
    'essuyions',
    'estacades',
    'estafette',
    'estafiers',
    'estagnons',
    'estaminet',
    'estampage',
    'estampais',
    'estampait',
    'estampant',
    'estampées',
    'estampent',
    'estampera',
    'estampeur',
    'estampiez',
    'estampons',
    'estancias',
    'estérifia',
    'estérifie',
    'estérifié',
    'esterlins',
    'esthésies',
    'esthétisa',
    'esthétise',
    'esthétisé',
    'estimable',
    'estimâmes',
    'estimasse',
    'estimâtes',
    'estimatif',
    'estimerai',
    'estimeras',
    'estimerez',
    'estimions',
    'estivages',
    'estivales',
    'estivâmes',
    'estivante',
    'estivants',
    'estivasse',
    'estivâtes',
    'estiverai',
    'estiveras',
    'estiverez',
    'estivions',
    'estocades',
    'estomaqua',
    'estomaque',
    'estomaqué',
    'estompage',
    'estompais',
    'estompait',
    'estompant',
    'estompées',
    'estompent',
    'estompera',
    'estompiez',
    'estompons',
    'estoniens',
    'estoquais',
    'estoquait',
    'estoquant',
    'estoquées',
    'estoquent',
    'estoquera',
    'estoquiez',
    'estoquons',
    'estourbie',
    'estourbir',
    'estourbis',
    'estourbit',
    'estourbît',
    'estradiot',
    'estragons',
    'estrapada',
    'estrapade',
    'estrapadé',
    'estropiai',
    'estropias',
    'estropiât',
    'estropiée',
    'estropier',
    'estropies',
    'estropiés',
    'estropiez',
    'estuaires',
    'estuarien',
    'esturgeon',
    'établâmes',
    'établasse',
    'établâtes',
    'établerai',
    'étableras',
    'établerez',
    'établîmes',
    'établions',
    'établirai',
    'établiras',
    'établirez',
    'établisse',
    'établîtes',
    'étageâmes',
    'étageasse',
    'étageâtes',
    'étagement',
    'étagerais',
    'étagerait',
    'étagèrent',
    'étageriez',
    'étagerons',
    'étageront',
    'étaiement',
    'étaierais',
    'étaierait',
    'étaieriez',
    'étaierons',
    'étaieront',
    'étalageai',
    'étalageas',
    'étalageât',
    'étalagées',
    'étalagent',
    'étalagera',
    'étalagiez',
    'étalaient',
    'étalasses',
    'étalement',
    'étalerais',
    'étalerait',
    'étalèrent',
    'étaleriez',
    'étalerons',
    'étaleront',
    'étaleuses',
    'étalières',
    'étalingua',
    'étalingue',
    'étalingué',
    'étalisais',
    'étalisait',
    'étalisant',
    'étalisent',
    'étalisera',
    'étalisiez',
    'étalisons',
    'étalonnai',
    'étalonnas',
    'étalonnât',
    'étalonnée',
    'étalonner',
    'étalonnes',
    'étalonnés',
    'étalonnez',
    'étamaient',
    'étamasses',
    'étambrais',
    'étamerais',
    'étamerait',
    'étamèrent',
    'étameriez',
    'étamerons',
    'étameront',
    'étampages',
    'étampâmes',
    'étampasse',
    'étampâtes',
    'étamperai',
    'étamperas',
    'étamperez',
    'étampeurs',
    'étampions',
    'étampures',
    'étanchais',
    'étanchait',
    'étanchant',
    'étanchées',
    'étanchent',
    'étanchera',
    'étanchiez',
    'étanchons',
    'étançonna',
    'étançonne',
    'étançonné',
    'étarquais',
    'étarquait',
    'étarquant',
    'étarquées',
    'étarquent',
    'étarquera',
    'étarquiez',
    'étarquons',
    'étasunien',
    'étatiques',
    'étatismes',
    'étatistes',
    'étayaient',
    'étayasses',
    'étayement',
    'étayerais',
    'étayerait',
    'étayèrent',
    'étayeriez',
    'étayerons',
    'étayeront',
    'éteignais',
    'éteignait',
    'éteignant',
    'éteignent',
    'éteigniez',
    'éteignoir',
    'éteignons',
    'éteindrai',
    'éteindras',
    'éteindrez',
    'étendages',
    'étendards',
    'étendîmes',
    'étendions',
    'étendisse',
    'étendîtes',
    'étendoirs',
    'étendrais',
    'étendrait',
    'étendriez',
    'étendrons',
    'étendront',
    'éternelle',
    'éternisai',
    'éternisas',
    'éternisât',
    'éternisée',
    'éterniser',
    'éternises',
    'éternisés',
    'éternisez',
    'éternités',
    'éternuais',
    'éternuait',
    'éternuant',
    'éternuent',
    'éternuera',
    'éternuiez',
    'éternuons',
    'êtes-vous',
    'étêtaient',
    'étêtasses',
    'étêtement',
    'étêterais',
    'étêterait',
    'étêtèrent',
    'étêteriez',
    'étêterons',
    'étêteront',
    'éthérifia',
    'éthérifie',
    'éthérifié',
    'éthérisai',
    'éthérisas',
    'éthérisât',
    'éthérisée',
    'éthériser',
    'éthérises',
    'éthérisés',
    'éthérisez',
    'éthérisme',
    'éthiopien',
    'ethniques',
    'ethnocide',
    'éthologie',
    'éthylènes',
    'éthylique',
    'éthylisme',
    'étincelai',
    'étincelas',
    'étincelât',
    'étinceler',
    'étincelez',
    'étincelle',
    'étiolâmes',
    'étiolasse',
    'étiolâtes',
    'étiolerai',
    'étioleras',
    'étiolerez',
    'étiolions',
    'étiologie',
    'étiquetai',
    'étiquetas',
    'étiquetât',
    'étiquetée',
    'étiqueter',
    'étiquetés',
    'étiquetez',
    'étiquette',
    'étirables',
    'étiraient',
    'étirasses',
    'étirement',
    'étirerais',
    'étirerait',
    'étirèrent',
    'étireriez',
    'étirerons',
    'étireront',
    'étireuses',
    'étoffâmes',
    'étoffasse',
    'étoffâtes',
    'étofferai',
    'étofferas',
    'étofferez',
    'étoffions',
    'étoilâmes',
    'étoilasse',
    'étoilâtes',
    'étoilerai',
    'étoileras',
    'étoilerez',
    'étoilions',
    'étonnâmes',
    'étonnante',
    'étonnants',
    'étonnasse',
    'étonnâtes',
    'étonnerai',
    'étonneras',
    'étonnerez',
    'étonnions',
    'étouffade',
    'étouffage',
    'étouffais',
    'étouffait',
    'étouffant',
    'étouffées',
    'étouffent',
    'étouffera',
    'étouffeur',
    'étouffiez',
    'étouffoir',
    'étouffons',
    'étoupâmes',
    'étoupasse',
    'étoupâtes',
    'étouperai',
    'étouperas',
    'étouperez',
    'étoupilla',
    'étoupille',
    'étoupillé',
    'étoupions',
    'étourdies',
    'étourdira',
    'étourneau',
    'étrangère',
    'étrangers',
    'étrangeté',
    'étranglai',
    'étranglas',
    'étranglât',
    'étranglée',
    'étrangler',
    'étrangles',
    'étranglés',
    'étranglez',
    'étrécîmes',
    'étrécirai',
    'étréciras',
    'étrécirez',
    'étrécisse',
    'étrécîtes',
    'étreignes',
    'étreignez',
    'étreignis',
    'étreignit',
    'étreignît',
    'étreindra',
    'étreindre',
    'étreintes',
    'étrennais',
    'étrennait',
    'étrennant',
    'étrennées',
    'étrennent',
    'étrennera',
    'étrenniez',
    'étrennons',
    'étrillais',
    'étrillait',
    'étrillant',
    'étrillées',
    'étrillent',
    'étrillera',
    'étrilliez',
    'étrillons',
    'étripages',
    'étripâmes',
    'étripasse',
    'étripâtes',
    'étriperai',
    'étriperas',
    'étriperez',
    'étripions',
    'étriquais',
    'étriquait',
    'étriquant',
    'étriquées',
    'étriquent',
    'étriquera',
    'étriquiez',
    'étriquons',
    'étrivière',
    'étrusques',
    'étudiâmes',
    'étudiante',
    'étudiants',
    'étudiasse',
    'étudiâtes',
    'étudierai',
    'étudieras',
    'étudierez',
    'étudiions',
    'étuvaient',
    'étuvasses',
    'étuverais',
    'étuverait',
    'étuvèrent',
    'étuveriez',
    'étuverons',
    'étuveront',
    'étuveuses',
    'euclidien',
    'eucologes',
    'eugénates',
    'eugénique',
    'eugénisme',
    'eugéniste',
    'eupatoire',
    'euphonies',
    'euphorbes',
    'euphories',
    'euphorisa',
    'euphorise',
    'euphorisé',
    'euphuisme',
    'eurasiens',
    'eurocrate',
    'européens',
    'europiums',
    'eurythmie',
    'euscarien',
    'euskarien',
    'eustaches',
    'eux-mêmes',
    'évacuâmes',
    'évacuante',
    'évacuants',
    'évacuasse',
    'évacuâtes',
    'évacuerai',
    'évacueras',
    'évacuerez',
    'évacuions',
    'évaderiez',
    'évaderons',
    'évaluable',
    'évaluâmes',
    'évaluasse',
    'évaluâtes',
    'évaluerai',
    'évalueras',
    'évaluerez',
    'évaluions',
    'évangiles',
    'évanouies',
    'évaporais',
    'évaporait',
    'évaporant',
    'évaporées',
    'évaporent',
    'évaporera',
    'évaporiez',
    'évaporons',
    'évasaient',
    'évasasses',
    'évasement',
    'évaserais',
    'évaserait',
    'évasèrent',
    'évaseriez',
    'évaserons',
    'évaseront',
    'évections',
    'éveillais',
    'éveillait',
    'éveillant',
    'éveillées',
    'éveillent',
    'éveillera',
    'éveilleur',
    'éveilliez',
    'éveillons',
    'événement',
    'évènement',
    'éventails',
    'éventaire',
    'éventâmes',
    'éventasse',
    'éventâtes',
    'éventerai',
    'éventeras',
    'éventerez',
    'éventions',
    'éventrais',
    'éventrait',
    'éventrant',
    'éventrées',
    'éventrent',
    'éventrera',
    'éventreur',
    'éventriez',
    'éventrons',
    'éventuels',
    'évertuiez',
    'évertuons',
    'évictions',
    'évidaient',
    'évidasses',
    'évidement',
    'évidences',
    'évidentes',
    'éviderais',
    'éviderait',
    'évidèrent',
    'évideriez',
    'éviderons',
    'évideront',
    'évinçâmes',
    'évinçasse',
    'évinçâtes',
    'évincerai',
    'évinceras',
    'évincerez',
    'évincions',
    'éviscérai',
    'éviscéras',
    'éviscérât',
    'éviscérée',
    'éviscérer',
    'éviscérés',
    'éviscères',
    'éviscérez',
    'évitables',
    'évitaient',
    'évitasses',
    'évitement',
    'éviterais',
    'éviterait',
    'évitèrent',
    'éviteriez',
    'éviterons',
    'éviteront',
    'évocables',
    'évocateur',
    'évocation',
    'évoluâmes',
    'évoluasse',
    'évoluâtes',
    'évoluerai',
    'évolueras',
    'évoluerez',
    'évoluions',
    'évolutifs',
    'évolution',
    'évolutive',
    'évoquâmes',
    'évoquasse',
    'évoquâtes',
    'évoquerai',
    'évoqueras',
    'évoquerez',
    'évoquions',
    'exacerbai',
    'exacerbas',
    'exacerbât',
    'exacerbée',
    'exacerber',
    'exacerbes',
    'exacerbés',
    'exacerbez',
    'exactions',
    'exagérais',
    'exagérait',
    'exagérant',
    'exagérées',
    'exagèrent',
    'exagérera',
    'exagériez',
    'exagérons',
    'exaltâmes',
    'exaltante',
    'exaltants',
    'exaltasse',
    'exaltâtes',
    'exalterai',
    'exalteras',
    'exalterez',
    'exaltions',
    'examinais',
    'examinait',
    'examinant',
    'examinées',
    'examinent',
    'examinera',
    'examiniez',
    'examinons',
    'exanthème',
    'exarchats',
    'exaspérai',
    'exaspéras',
    'exaspérât',
    'exaspérée',
    'exaspérer',
    'exaspérés',
    'exaspères',
    'exaspérez',
    'exauçâmes',
    'exauçasse',
    'exauçâtes',
    'exaucerai',
    'exauceras',
    'exaucerez',
    'exaucions',
    'excavâmes',
    'excavasse',
    'excavâtes',
    'excaverai',
    'excaveras',
    'excaverez',
    'excavions',
    'excédâmes',
    'excédante',
    'excédants',
    'excédasse',
    'excédâtes',
    'excédents',
    'excéderai',
    'excéderas',
    'excéderez',
    'excédions',
    'excellais',
    'excellait',
    'excellant',
    'excellent',
    'excellera',
    'excelliez',
    'excellons',
    'excentrai',
    'excentras',
    'excentrât',
    'excentrée',
    'excentrer',
    'excentres',
    'excentrés',
    'excentrez',
    'exceptais',
    'exceptait',
    'exceptant',
    'exceptées',
    'exceptent',
    'exceptera',
    'exceptiez',
    'exception',
    'exceptons',
    'excessifs',
    'excessive',
    'excipâmes',
    'excipasse',
    'excipâtes',
    'exciperai',
    'exciperas',
    'exciperez',
    'excipient',
    'excipions',
    'excisâmes',
    'excisasse',
    'excisâtes',
    'exciserai',
    'exciseras',
    'exciserez',
    'excisions',
    'excitable',
    'excitâmes',
    'excitante',
    'excitants',
    'excitasse',
    'excitâtes',
    'exciterai',
    'exciteras',
    'exciterez',
    'excitions',
    'exclamées',
    'exclamiez',
    'exclamons',
    'excluions',
    'exclurais',
    'exclurait',
    'exclurent',
    'excluriez',
    'exclurons',
    'excluront',
    'exclusifs',
    'exclusion',
    'exclusive',
    'exclusses',
    'excoriais',
    'excoriait',
    'excoriant',
    'excoriées',
    'excorient',
    'excoriera',
    'excoriiez',
    'excorions',
    'excrément',
    'excrétais',
    'excrétait',
    'excrétant',
    'excrétées',
    'excrètent',
    'excrétera',
    'excréteur',
    'excrétiez',
    'excrétion',
    'excrétons',
    'excursion',
    'excusable',
    'excusâmes',
    'excusasse',
    'excusâtes',
    'excuserai',
    'excuseras',
    'excuserez',
    'excusions',
    'exécrable',
    'exécrâmes',
    'exécrasse',
    'exécrâtes',
    'exécrerai',
    'exécreras',
    'exécrerez',
    'exécrions',
    'exécutais',
    'exécutait',
    'exécutant',
    'exécutées',
    'exécutent',
    'exécutera',
    'exécuteur',
    'exécutiez',
    'exécutifs',
    'exécution',
    'exécutive',
    'exécutons',
    'exemptais',
    'exemptait',
    'exemptant',
    'exemptées',
    'exemptent',
    'exemptera',
    'exemptiez',
    'exemption',
    'exemptons',
    'exequatur',
    'exerçâmes',
    'exerçante',
    'exerçants',
    'exerçasse',
    'exerçâtes',
    'exercerai',
    'exerceras',
    'exercerez',
    'exercices',
    'exercions',
    'exfoliais',
    'exfoliait',
    'exfoliant',
    'exfoliées',
    'exfolient',
    'exfoliera',
    'exfoliiez',
    'exfolions',
    'exhalâmes',
    'exhalasse',
    'exhalâtes',
    'exhalerai',
    'exhaleras',
    'exhalerez',
    'exhalions',
    'exhaussai',
    'exhaussas',
    'exhaussât',
    'exhaussée',
    'exhausser',
    'exhausses',
    'exhaussés',
    'exhaussez',
    'exhaustif',
    'exhérédai',
    'exhérédas',
    'exhérédât',
    'exhérédée',
    'exhéréder',
    'exhérédés',
    'exhérèdes',
    'exhérédez',
    'exhibâmes',
    'exhibasse',
    'exhibâtes',
    'exhiberai',
    'exhiberas',
    'exhiberez',
    'exhibions',
    'exhortais',
    'exhortait',
    'exhortant',
    'exhortées',
    'exhortent',
    'exhortera',
    'exhortiez',
    'exhortons',
    'exhumâmes',
    'exhumasse',
    'exhumâtes',
    'exhumerai',
    'exhumeras',
    'exhumerez',
    'exhumions',
    'exigeâmes',
    'exigeante',
    'exigeants',
    'exigeasse',
    'exigeâtes',
    'exigences',
    'exigerais',
    'exigerait',
    'exigèrent',
    'exigeriez',
    'exigerons',
    'exigeront',
    'exigibles',
    'exiguïtés',
    'exilaient',
    'exilasses',
    'exilerais',
    'exilerait',
    'exilèrent',
    'exileriez',
    'exilerons',
    'exileront',
    'existâmes',
    'existante',
    'existants',
    'existasse',
    'existâtes',
    'existence',
    'existerai',
    'existeras',
    'existerez',
    'existions',
    'ex-libris',
    'exocrines',
    'exogamies',
    'exondâmes',
    'exondâtes',
    'exonderez',
    'exondions',
    'exonérais',
    'exonérait',
    'exonérant',
    'exonérées',
    'exonèrent',
    'exonérera',
    'exonériez',
    'exonérons',
    'exorbitée',
    'exorbités',
    'exorcisai',
    'exorcisas',
    'exorcisât',
    'exorcisée',
    'exorciser',
    'exorcises',
    'exorcisés',
    'exorcisez',
    'exorcisme',
    'exorciste',
    'exoréique',
    'exoréisme',
    'exosmoses',
    'exosphère',
    'exostoses',
    'exotiques',
    'exotismes',
    'exotoxine',
    'expansées',
    'expansifs',
    'expansion',
    'expansive',
    'expatriai',
    'expatrias',
    'expatriât',
    'expatriée',
    'expatrier',
    'expatries',
    'expatriés',
    'expatriez',
    'expectora',
    'expectore',
    'expectoré',
    'expédiais',
    'expédiait',
    'expédiant',
    'expédiées',
    'expédient',
    'expédiera',
    'expédiiez',
    'expédions',
    'expéditif',
    'expertisa',
    'expertise',
    'expertisé',
    'expiables',
    'expiaient',
    'expiasses',
    'expiation',
    'expierais',
    'expierait',
    'expièrent',
    'expieriez',
    'expierons',
    'expieront',
    'expirâmes',
    'expirante',
    'expirants',
    'expirasse',
    'expirâtes',
    'expirerai',
    'expireras',
    'expirerez',
    'expirions',
    'explétifs',
    'explétive',
    'explicita',
    'explicite',
    'explicité',
    'expliquai',
    'expliquas',
    'expliquât',
    'expliquée',
    'expliquer',
    'expliques',
    'expliqués',
    'expliquez',
    'exploitai',
    'exploitas',
    'exploitât',
    'exploitée',
    'exploiter',
    'exploites',
    'exploités',
    'exploitez',
    'explorais',
    'explorait',
    'explorant',
    'explorées',
    'explorent',
    'explorera',
    'exploriez',
    'explorons',
    'explosais',
    'explosait',
    'explosant',
    'explosent',
    'explosera',
    'exploseur',
    'explosiez',
    'explosifs',
    'explosion',
    'explosive',
    'explosons',
    'exportais',
    'exportait',
    'exportant',
    'exportées',
    'exportent',
    'exportera',
    'exportiez',
    'exportons',
    'exposâmes',
    'exposante',
    'exposants',
    'exposasse',
    'exposâtes',
    'exposerai',
    'exposeras',
    'exposerez',
    'exposions',
    'expressif',
    'exprimais',
    'exprimait',
    'exprimant',
    'exprimées',
    'expriment',
    'exprimera',
    'exprimiez',
    'exprimons',
    'expropria',
    'exproprie',
    'exproprié',
    'expulsais',
    'expulsait',
    'expulsant',
    'expulsées',
    'expulsent',
    'expulsera',
    'expulsiez',
    'expulsion',
    'expulsons',
    'expurgeai',
    'expurgeas',
    'expurgeât',
    'expurgées',
    'expurgent',
    'expurgera',
    'expurgiez',
    'exsangues',
    'exsudâmes',
    'exsudasse',
    'exsudâtes',
    'exsuderai',
    'exsuderas',
    'exsuderez',
    'exsudions',
    'extasiées',
    'extasiiez',
    'extasions',
    'extatique',
    'extenseur',
    'extensifs',
    'extension',
    'extensive',
    'exténuais',
    'exténuait',
    'exténuant',
    'exténuées',
    'exténuent',
    'exténuera',
    'exténuiez',
    'exténuons',
    'extérieur',
    'extermina',
    'extermine',
    'exterminé',
    'externats',
    'extirpais',
    'extirpait',
    'extirpant',
    'extirpées',
    'extirpent',
    'extirpera',
    'extirpiez',
    'extirpons',
    'extorquai',
    'extorquas',
    'extorquât',
    'extorquée',
    'extorquer',
    'extorques',
    'extorqués',
    'extorquez',
    'extorsion',
    'extractif',
    'extradais',
    'extradait',
    'extradant',
    'extradées',
    'extradent',
    'extradera',
    'extradiez',
    'extradons',
    'extra-fin',
    'extraient',
    'extrairai',
    'extrairas',
    'extrairez',
    'extraites',
    'extrapola',
    'extrapole',
    'extrapolé',
    'extravasa',
    'extravase',
    'extravasé',
    'extrayais',
    'extrayait',
    'extrayant',
    'extrayiez',
    'extrayons',
    'extrémité',
    'extremums',
    'extrorses',
    'extrudais',
    'extrudait',
    'extrudant',
    'extrudées',
    'extrudent',
    'extrudera',
    'extrudiez',
    'extrudons',
    'extrusion',
    'exubérant',
    'exulcérai',
    'exulcéras',
    'exulcérât',
    'exulcérée',
    'exulcérer',
    'exulcérés',
    'exulcères',
    'exulcérez',
    'exultâmes',
    'exultasse',
    'exultâtes',
    'exulterai',
    'exulteras',
    'exulterez',
    'exultions',
    'exutoires',
    'eye-liner',
    'fabricant',
    'fabricien',
    'fabriquai',
    'fabriquas',
    'fabriquât',
    'fabriquée',
    'fabriquer',
    'fabriques',
    'fabriqués',
    'fabriquez',
    'fabulâmes',
    'fabulasse',
    'fabulâtes',
    'fabulerai',
    'fabuleras',
    'fabulerez',
    'fabuleuse',
    'fabulions',
    'fabuliste',
    'facétieux',
    'facettais',
    'facettait',
    'facettant',
    'facettées',
    'facettent',
    'facettera',
    'facettiez',
    'facettons',
    'fâchaient',
    'fâchasses',
    'fâcherais',
    'fâcherait',
    'fâchèrent',
    'fâcheries',
    'fâcheriez',
    'fâcherons',
    'fâcheront',
    'fâcheuses',
    'facilitai',
    'facilitas',
    'facilitât',
    'facilitée',
    'faciliter',
    'facilites',
    'facilités',
    'facilitez',
    'façonnage',
    'façonnais',
    'façonnait',
    'façonnant',
    'façonnées',
    'façonnent',
    'façonnera',
    'façonnier',
    'façonniez',
    'façonnons',
    'facticité',
    'factieuse',
    'factitifs',
    'factitive',
    'factorage',
    'factoriel',
    'factoring',
    'factorisa',
    'factorise',
    'factorisé',
    'factotums',
    'factrices',
    'factuelle',
    'facturais',
    'facturait',
    'facturant',
    'facturées',
    'facturent',
    'facturera',
    'facturier',
    'facturiez',
    'facturons',
    'fadassent',
    'fadassiez',
    'faderions',
    'fagotages',
    'fagotâmes',
    'fagotasse',
    'fagotâtes',
    'fagoterai',
    'fagoteras',
    'fagoterez',
    'fagotiers',
    'fagotions',
    'faiblarde',
    'faiblards',
    'faiblesse',
    'faiblîmes',
    'faiblirai',
    'faibliras',
    'faiblirez',
    'faiblisse',
    'faiblîtes',
    'faïencées',
    'faïencier',
    'faignanta',
    'faignante',
    'faignanté',
    'faignants',
    'faillâmes',
    'faillasse',
    'faillâtes',
    'faillerai',
    'failleras',
    'faillerez',
    'faillible',
    'faillîmes',
    'faillions',
    'faillirai',
    'failliras',
    'faillirez',
    'faillisse',
    'faillites',
    'faillîtes',
    'fainéanta',
    'fainéante',
    'fainéanté',
    'fainéants',
    'fair-play',
    'faisables',
    'faisaient',
    'faisandai',
    'faisandas',
    'faisandât',
    'faisandée',
    'faisander',
    'faisandes',
    'faisandés',
    'faisandez',
    'faisceaux',
    'faiseuses',
    'faisselle',
    'faîtières',
    'fait-tout',
    'fakirisme',
    'falarique',
    'falourdes',
    'falsifiai',
    'falsifias',
    'falsifiât',
    'falsifiée',
    'falsifier',
    'falsifies',
    'falsifiés',
    'falsifiez',
    'falunière',
    'famélique',
    'familiale',
    'familiaux',
    'familière',
    'familiers',
    'fanassent',
    'fanassiez',
    'fanatique',
    'fanatisai',
    'fanatisas',
    'fanatisât',
    'fanatisée',
    'fanatiser',
    'fanatises',
    'fanatisés',
    'fanatisez',
    'fanatisme',
    'fandangos',
    'fanerions',
    'fanfarons',
    'fangeuses',
    'fantaisie',
    'fantasias',
    'fantasmai',
    'fantasmas',
    'fantasmât',
    'fantasmer',
    'fantasmes',
    'fantasmez',
    'fantasque',
    'fantassin',
    'fantoches',
    'faradique',
    'farandole',
    'farceuses',
    'farcirais',
    'farcirait',
    'farcirent',
    'farciriez',
    'farcirons',
    'farciront',
    'farcisses',
    'farcissez',
    'fardaient',
    'fardasses',
    'farderais',
    'farderait',
    'fardèrent',
    'farderiez',
    'farderons',
    'farderont',
    'farfadets',
    'farfelues',
    'fariboles',
    'farigoule',
    'farinacée',
    'farinacés',
    'farinâmes',
    'farinasse',
    'farinâtes',
    'farinerai',
    'farineras',
    'farinerez',
    'farineuse',
    'farinions',
    'farlouses',
    'farniente',
    'farouches',
    'fartaient',
    'fartasses',
    'farterais',
    'farterait',
    'fartèrent',
    'farteriez',
    'farterons',
    'farteront',
    'fascicule',
    'fasciculé',
    'fascinais',
    'fascinait',
    'fascinant',
    'fascinées',
    'fascinent',
    'fascinera',
    'fasciniez',
    'fascinons',
    'fascisais',
    'fascisait',
    'fascisant',
    'fascisées',
    'fascisent',
    'fascisera',
    'fascisiez',
    'fascismes',
    'fascisons',
    'fascistes',
    'faseillai',
    'faseillas',
    'faseillât',
    'faseiller',
    'faseilles',
    'faseillez',
    'fast-food',
    'fastigiée',
    'fastigiés',
    'fastueuse',
    'fatalisme',
    'fataliste',
    'fatalités',
    'fatidique',
    'fatigable',
    'fatigante',
    'fatigants',
    'fatiguais',
    'fatiguait',
    'fatiguant',
    'fatiguées',
    'fatiguent',
    'fatiguera',
    'fatiguiez',
    'fatiguons',
    'fatrasies',
    'faubourgs',
    'faucardai',
    'faucardas',
    'faucardât',
    'faucardée',
    'faucarder',
    'faucardes',
    'faucardés',
    'faucardez',
    'fauchages',
    'fauchâmes',
    'fauchards',
    'fauchasse',
    'fauchâtes',
    'faucherai',
    'faucheras',
    'faucherez',
    'fauchette',
    'faucheurs',
    'faucheuse',
    'fauchions',
    'faucilles',
    'faucillon',
    'fauconnai',
    'fauconnas',
    'fauconnât',
    'fauconner',
    'fauconnes',
    'fauconnez',
    'faudrions',
    'faufilage',
    'faufilais',
    'faufilait',
    'faufilant',
    'faufilées',
    'faufilent',
    'faufilera',
    'faufiliez',
    'faufilons',
    'faufilure',
    'faunesque',
    'fauniques',
    'faussaire',
    'faussâmes',
    'faussasse',
    'faussâtes',
    'fausserai',
    'fausseras',
    'fausserez',
    'faussetés',
    'faussions',
    'fautaient',
    'fautasses',
    'fauterais',
    'fauterait',
    'fautèrent',
    'fauteriez',
    'fauterons',
    'fauteront',
    'fauteuils',
    'fautrices',
    'fauveries',
    'fauvettes',
    'fauvismes',
    'faux-sens',
    'faveroles',
    'favorable',
    'favorisai',
    'favorisas',
    'favorisât',
    'favorisée',
    'favoriser',
    'favorises',
    'favorisés',
    'favorisez',
    'favorites',
    'faxassent',
    'faxassiez',
    'faxerions',
    'fayotâmes',
    'fayotasse',
    'fayotâtes',
    'fayoterai',
    'fayoteras',
    'fayoterez',
    'fayotions',
    'fayottais',
    'fayottait',
    'fayottant',
    'fayottent',
    'fayottera',
    'fayottiez',
    'fayottons',
    'fébricule',
    'fébrifuge',
    'fébrilité',
    'fécondais',
    'fécondait',
    'fécondant',
    'fécondées',
    'fécondent',
    'fécondera',
    'fécondiez',
    'fécondité',
    'fécondons',
    'féculâmes',
    'féculasse',
    'féculâtes',
    'féculence',
    'féculente',
    'féculents',
    'féculerai',
    'féculeras',
    'féculerez',
    'féculerie',
    'féculions',
    'fédérales',
    'fédérâmes',
    'fédérasse',
    'fédérâtes',
    'fédératif',
    'fédérerai',
    'fédéreras',
    'fédérerez',
    'fédérions',
    'féeriques',
    'fégaterai',
    'fégateras',
    'fégaterez',
    'feignanta',
    'feignante',
    'feignanté',
    'feignants',
    'feignîmes',
    'feignions',
    'feignisse',
    'feignîtes',
    'feindrais',
    'feindrait',
    'feindriez',
    'feindrons',
    'feindront',
    'feintâmes',
    'feintasse',
    'feintâtes',
    'feinterai',
    'feinteras',
    'feinterez',
    'feinteurs',
    'feintions',
    'feintises',
    'fêlassent',
    'fêlassiez',
    'feldspath',
    'feldwebel',
    'fêlerions',
    'félibrige',
    'félicitai',
    'félicitas',
    'félicitât',
    'félicitée',
    'féliciter',
    'félicites',
    'félicités',
    'félicitez',
    'félinités',
    'fellaghas',
    'fellation',
    'felouques',
    'féminines',
    'féminisai',
    'féminisas',
    'féminisât',
    'féminisée',
    'féminiser',
    'féminises',
    'féminisés',
    'féminisez',
    'féminisme',
    'féministe',
    'féminités',
    'fémorales',
    'fenaisons',
    'fendaient',
    'fendantes',
    'fendillai',
    'fendillas',
    'fendillât',
    'fendillée',
    'fendiller',
    'fendilles',
    'fendillés',
    'fendillez',
    'fendirent',
    'fendisses',
    'fendrions',
    'fenestrai',
    'fenestras',
    'fenestrât',
    'fenestrée',
    'fenestrer',
    'fenestres',
    'fenestrés',
    'fenestrez',
    'fenêtrage',
    'fenêtrais',
    'fenêtrait',
    'fenêtrant',
    'fenêtrées',
    'fenêtrent',
    'fenêtrera',
    'fenêtriez',
    'fenêtrons',
    'fenugrecs',
    'féodalité',
    'fer-blanc',
    'ferlaient',
    'ferlasses',
    'ferlerais',
    'ferlerait',
    'ferlèrent',
    'ferleriez',
    'ferlerons',
    'ferleront',
    'fermaient',
    'fermantes',
    'fermasses',
    'fermement',
    'fermentai',
    'fermentas',
    'fermentât',
    'fermentée',
    'fermenter',
    'fermentes',
    'fermentés',
    'fermentez',
    'fermerais',
    'fermerait',
    'fermèrent',
    'fermeriez',
    'fermerons',
    'fermeront',
    'fermettes',
    'fermeture',
    'fermières',
    'férocités',
    'ferraient',
    'ferrailla',
    'ferraille',
    'ferraillé',
    'ferrasses',
    'ferratier',
    'ferrement',
    'ferrerais',
    'ferrerait',
    'ferrèrent',
    'ferreriez',
    'ferrerons',
    'ferreront',
    'ferriques',
    'fertilisa',
    'fertilise',
    'fertilisé',
    'fertilité',
    'ferventes',
    'fessaient',
    'fessasses',
    'fesserais',
    'fesserait',
    'fessèrent',
    'fesseriez',
    'fesserons',
    'fesseront',
    'fessières',
    'festivals',
    'festivité',
    'festoient',
    'festoiera',
    'festonnai',
    'festonnas',
    'festonnât',
    'festonnée',
    'festonner',
    'festonnes',
    'festonnés',
    'festonnez',
    'festoyais',
    'festoyait',
    'festoyant',
    'festoyées',
    'festoyiez',
    'festoyons',
    'fêtassent',
    'fêtassiez',
    'fêterions',
    'féticheur',
    'fétichisa',
    'fétichise',
    'fétichisé',
    'fétidités',
    'feudistes',
    'feuillage',
    'feuillais',
    'feuillait',
    'feuillant',
    'feuillard',
    'feuillées',
    'feuillent',
    'feuillera',
    'feuilleta',
    'feuilleté',
    'feuillets',
    'feuilliez',
    'feuillons',
    'feuillues',
    'feuillure',
    'feulaient',
    'feulasses',
    'feulement',
    'feulerais',
    'feulerait',
    'feulèrent',
    'feuleriez',
    'feulerons',
    'feuleront',
    'feutrages',
    'feutrâmes',
    'feutrante',
    'feutrants',
    'feutrasse',
    'feutrâtes',
    'feutrerai',
    'feutreras',
    'feutrerez',
    'feutrines',
    'feutrions',
    'féveroles',
    'fiabilité',
    'fiançâmes',
    'fiançasse',
    'fiançâtes',
    'fiancerai',
    'fianceras',
    'fiancerez',
    'fiancions',
    'fiassions',
    'fibrannes',
    'fibreuses',
    'fibrineux',
    'fibroïnes',
    'ficelages',
    'ficelâmes',
    'ficelasse',
    'ficelâtes',
    'ficelions',
    'ficellent',
    'ficellera',
    'fichaient',
    'fichasses',
    'ficherais',
    'ficherait',
    'fichèrent',
    'ficheriez',
    'ficherons',
    'ficheront',
    'fichistes',
    'fidéismes',
    'fidéistes',
    'fidélités',
    'fieffâmes',
    'fieffasse',
    'fieffâtes',
    'fiefferai',
    'fiefferas',
    'fiefferez',
    'fieffions',
    'fielleuse',
    'fientâmes',
    'fientasse',
    'fientâtes',
    'fienterai',
    'fienteras',
    'fienterez',
    'fientions',
    'fieraient',
    'fièrement',
    'fiévreuse',
    'fifrelins',
    'figeaient',
    'figeasses',
    'figements',
    'figerions',
    'fignolage',
    'fignolais',
    'fignolait',
    'fignolant',
    'fignolées',
    'fignolent',
    'fignolera',
    'fignoleur',
    'fignoliez',
    'fignolons',
    'figueries',
    'figulines',
    'figurâmes',
    'figurante',
    'figurants',
    'figurasse',
    'figurâtes',
    'figuratif',
    'figurerai',
    'figureras',
    'figurerez',
    'figurines',
    'figurions',
    'figurisme',
    'figuriste',
    'filaments',
    'filandres',
    'filanzane',
    'filariose',
    'filassent',
    'filassiez',
    'filateurs',
    'filatures',
    'filerions',
    'filetages',
    'filetâmes',
    'filetasse',
    'filetâtes',
    'filèterai',
    'filèteras',
    'filèterez',
    'filetions',
    'filiation',
    'filiforme',
    'filigrana',
    'filigrane',
    'filigrané',
    'fillasses',
    'fillettes',
    'filleules',
    'filmaient',
    'filmasses',
    'filmerais',
    'filmerait',
    'filmèrent',
    'filmeriez',
    'filmerons',
    'filmeront',
    'filmiques',
    'filochais',
    'filochait',
    'filochant',
    'filochées',
    'filochent',
    'filochera',
    'filochiez',
    'filochons',
    'filoniens',
    'filoselle',
    'filoutais',
    'filoutait',
    'filoutant',
    'filoutées',
    'filoutent',
    'filoutera',
    'filoutiez',
    'filoutons',
    'filtrable',
    'filtrages',
    'filtrâmes',
    'filtrante',
    'filtrants',
    'filtrasse',
    'filtrâtes',
    'filtrerai',
    'filtreras',
    'filtrerez',
    'filtrions',
    'finalisme',
    'finaliste',
    'finalités',
    'finançais',
    'finançait',
    'finançant',
    'financées',
    'financent',
    'financera',
    'financier',
    'financiez',
    'finançons',
    'finassais',
    'finassait',
    'finassant',
    'finassées',
    'finassent',
    'finassera',
    'finasseur',
    'finassier',
    'finassiez',
    'finassons',
    'finirions',
    'finissage',
    'finissais',
    'finissait',
    'finissant',
    'finissent',
    'finisseur',
    'finissiez',
    'finissons',
    'finitions',
    'finnoises',
    'fioriture',
    'firmament',
    'fiscalisa',
    'fiscalise',
    'fiscalisé',
    'fiscalité',
    'fissibles',
    'fissionna',
    'fissionne',
    'fissionné',
    'fissurais',
    'fissurait',
    'fissurant',
    'fissurées',
    'fissurent',
    'fissurera',
    'fissuriez',
    'fissurons',
    'fistuleux',
    'fistuline',
    'fixassent',
    'fixassiez',
    'fixateurs',
    'fixations',
    'fixatives',
    'fixatrice',
    'fixerions',
    'flacherie',
    'flagellai',
    'flagellas',
    'flagellât',
    'flagellée',
    'flageller',
    'flagelles',
    'flagellés',
    'flagellez',
    'flagellum',
    'flageolai',
    'flageolas',
    'flageolât',
    'flageoler',
    'flageoles',
    'flageolet',
    'flageolez',
    'flagornai',
    'flagornas',
    'flagornât',
    'flagornée',
    'flagorner',
    'flagornes',
    'flagornés',
    'flagornez',
    'flagrance',
    'flagrante',
    'flagrants',
    'flairâmes',
    'flairasse',
    'flairâtes',
    'flairerai',
    'flaireras',
    'flairerez',
    'flaireurs',
    'flaireuse',
    'flairions',
    'flamandes',
    'flambages',
    'flambâmes',
    'flambards',
    'flambarts',
    'flambasse',
    'flambâtes',
    'flambeaux',
    'flamberai',
    'flamberas',
    'flamberez',
    'flamberge',
    'flambeurs',
    'flambeuse',
    'flambions',
    'flamboies',
    'flamboyai',
    'flamboyas',
    'flamboyât',
    'flamboyer',
    'flamboyez',
    'flamencos',
    'flammèche',
    'flânaient',
    'flânasses',
    'flanchais',
    'flanchait',
    'flanchant',
    'flanchées',
    'flanchent',
    'flanchera',
    'flanchets',
    'flanchiez',
    'flanchons',
    'flandrins',
    'flanelles',
    'flânerais',
    'flânerait',
    'flânèrent',
    'flâneries',
    'flâneriez',
    'flânerons',
    'flâneront',
    'flâneuses',
    'flanquais',
    'flanquait',
    'flanquant',
    'flanquées',
    'flanquent',
    'flanquera',
    'flanquiez',
    'flanquons',
    'flaquâmes',
    'flaquasse',
    'flaquâtes',
    'flaquerai',
    'flaqueras',
    'flaquerez',
    'flaquions',
    'flashâmes',
    'flashasse',
    'flashâtes',
    'flasherai',
    'flasheras',
    'flasherez',
    'flashions',
    'flattâmes',
    'flattasse',
    'flattâtes',
    'flatterai',
    'flatteras',
    'flatterez',
    'flatterie',
    'flatteurs',
    'flatteuse',
    'flattions',
    'flatulent',
    'flaupâmes',
    'flaupasse',
    'flaupâtes',
    'flauperai',
    'flauperas',
    'flauperez',
    'flaupions',
    'fléchages',
    'fléchâmes',
    'fléchasse',
    'fléchâtes',
    'flécherai',
    'flécheras',
    'flécherez',
    'fléchette',
    'fléchîmes',
    'fléchions',
    'fléchirai',
    'fléchiras',
    'fléchirez',
    'fléchisse',
    'fléchîtes',
    'flémardes',
    'flemmarda',
    'flemmarde',
    'flemmardé',
    'flemmards',
    'flétrîmes',
    'flétrirai',
    'flétriras',
    'flétrirez',
    'flétrisse',
    'flétrîtes',
    'fleurages',
    'fleurâmes',
    'fleurasse',
    'fleurâtes',
    'fleurerai',
    'fleureras',
    'fleurerez',
    'fleurette',
    'fleurîmes',
    'fleurions',
    'fleurirai',
    'fleuriras',
    'fleurirez',
    'fleurisse',
    'fleuriste',
    'fleurîtes',
    'fleuronné',
    'flexibles',
    'flibustai',
    'flibustas',
    'flibustât',
    'flibustée',
    'flibuster',
    'flibustes',
    'flibustés',
    'flibustez',
    'flinguais',
    'flinguait',
    'flinguant',
    'flinguées',
    'flinguent',
    'flinguera',
    'flinguiez',
    'flinguons',
    'flippâmes',
    'flippasse',
    'flippâtes',
    'flipperai',
    'flipperas',
    'flipperez',
    'flippions',
    'flirtâmes',
    'flirtasse',
    'flirtâtes',
    'flirterai',
    'flirteras',
    'flirterez',
    'flirteurs',
    'flirteuse',
    'flirtions',
    'floconnai',
    'floconnas',
    'floconnât',
    'floconner',
    'floconnes',
    'floconnez',
    'floculais',
    'floculait',
    'floculant',
    'floculera',
    'floculiez',
    'floculons',
    'flonflons',
    'floraison',
    'floralies',
    'florences',
    'florentin',
    'floricole',
    'florifère',
    'florilège',
    'flottable',
    'flottages',
    'flottâmes',
    'flottante',
    'flottants',
    'flottards',
    'flottasse',
    'flottâtes',
    'flotterai',
    'flotteras',
    'flotterez',
    'flotteurs',
    'flottille',
    'flottions',
    'flouaient',
    'flouasses',
    'flouerais',
    'flouerait',
    'flouèrent',
    'floueriez',
    'flouerons',
    'floueront',
    'flousâmes',
    'flousasse',
    'flousâtes',
    'flouserai',
    'flouseras',
    'flouserez',
    'flousions',
    'fluassent',
    'fluassiez',
    'fluctuais',
    'fluctuait',
    'fluctuant',
    'fluctuent',
    'fluctuera',
    'fluctuiez',
    'fluctuons',
    'fluerions',
    'fluidifia',
    'fluidifie',
    'fluidifié',
    'fluidique',
    'fluidisai',
    'fluidisas',
    'fluidisât',
    'fluidisée',
    'fluidiser',
    'fluidises',
    'fluidisés',
    'fluidisez',
    'fluidités',
    'fluorines',
    'fluorisai',
    'fluorisas',
    'fluorisât',
    'fluorisée',
    'fluoriser',
    'fluorises',
    'fluorisés',
    'fluorisez',
    'fluoroses',
    'fluorures',
    'flûtaient',
    'flûtasses',
    'flûterais',
    'flûterait',
    'flûtèrent',
    'flûteriez',
    'flûterons',
    'flûteront',
    'flûtistes',
    'fluviales',
    'fluxaient',
    'fluxasses',
    'fluxerais',
    'fluxerait',
    'fluxèrent',
    'fluxeriez',
    'fluxerons',
    'fluxeront',
    'fluxmètre',
    'focalisai',
    'focalisas',
    'focalisât',
    'focalisée',
    'focaliser',
    'focalises',
    'focalisés',
    'focalisez',
    'foiraient',
    'foirantes',
    'foirasses',
    'foirerais',
    'foirerait',
    'foirèrent',
    'foireriez',
    'foirerons',
    'foireront',
    'foireuses',
    'foisonnai',
    'foisonnas',
    'foisonnât',
    'foisonner',
    'foisonnes',
    'foisonnez',
    'folâtrais',
    'folâtrait',
    'folâtrant',
    'folâtrent',
    'folâtrera',
    'folâtriez',
    'folâtrons',
    'foliacées',
    'foliaires',
    'foliation',
    'folichons',
    'foliotais',
    'foliotait',
    'foliotant',
    'foliotées',
    'foliotent',
    'foliotera',
    'foliotiez',
    'foliotons',
    'folklores',
    'folksongs',
    'follement',
    'follettes',
    'follicule',
    'fomentais',
    'fomentait',
    'fomentant',
    'fomentées',
    'fomentent',
    'fomentera',
    'fomentiez',
    'fomentons',
    'fonçaient',
    'fonçasses',
    'foncerais',
    'foncerait',
    'foncèrent',
    'fonceriez',
    'foncerons',
    'fonceront',
    'fonceuses',
    'foncières',
    'fonctions',
    'fondaient',
    'fondantes',
    'fondasses',
    'fondateur',
    'fondation',
    'fondement',
    'fonderais',
    'fonderait',
    'fondèrent',
    'fonderies',
    'fonderiez',
    'fonderons',
    'fonderont',
    'fondeuses',
    'fondirent',
    'fondisses',
    'fondrière',
    'fondrions',
    'fongibles',
    'fongicide',
    'fongiques',
    'fongosité',
    'fongueuse',
    'fontaines',
    'fontanges',
    'fontanili',
    'footballs',
    'foraminée',
    'foraminés',
    'forassent',
    'forassiez',
    'forçaient',
    'forçasses',
    'forcement',
    'forcément',
    'forcenées',
    'forcerais',
    'forcerait',
    'forcèrent',
    'forceries',
    'forceriez',
    'forcerons',
    'forceront',
    'forcirais',
    'forcirait',
    'forcirent',
    'forciriez',
    'forcirons',
    'forciront',
    'forcisses',
    'forcissez',
    'forcloses',
    'forerions',
    'forestier',
    'forfaites',
    'forfasses',
    'forferais',
    'forferait',
    'forferiez',
    'forferons',
    'forferont',
    'forficule',
    'forfirent',
    'forfisses',
    'forgeable',
    'forgeages',
    'forgeâmes',
    'forgeasse',
    'forgeâtes',
    'forgerais',
    'forgerait',
    'forgèrent',
    'forgeriez',
    'forgerons',
    'forgeront',
    'forgeuses',
    'forjetais',
    'forjetait',
    'forjetant',
    'forjetées',
    'forjetiez',
    'forjetons',
    'forjettes',
    'forlançai',
    'forlanças',
    'forlançât',
    'forlancée',
    'forlancer',
    'forlances',
    'forlancés',
    'forlancez',
    'forlignai',
    'forlignas',
    'forlignât',
    'forligner',
    'forlignes',
    'forlignez',
    'formaient',
    'formalisa',
    'formalise',
    'formalisé',
    'formalité',
    'formasses',
    'formateur',
    'formatifs',
    'formation',
    'formative',
    'formelles',
    'formerais',
    'formerait',
    'formèrent',
    'formerets',
    'formeriez',
    'formerons',
    'formeront',
    'formiates',
    'formicant',
    'formiques',
    'formolais',
    'formolait',
    'formolant',
    'formolées',
    'formolent',
    'formolera',
    'formoliez',
    'formolons',
    'formulais',
    'formulait',
    'formulant',
    'formulées',
    'formulent',
    'formulera',
    'formuliez',
    'formulons',
    'forniquai',
    'forniquas',
    'forniquât',
    'forniquer',
    'forniques',
    'forniquez',
    'forsythia',
    'fortement',
    'fortiches',
    'fortifiai',
    'fortifias',
    'fortifiât',
    'fortifiée',
    'fortifier',
    'fortifies',
    'fortifiés',
    'fortifiez',
    'fortraite',
    'fortraits',
    'fortuites',
    'fortunées',
    'fossettes',
    'fossilisa',
    'fossilise',
    'fossilisé',
    'fossoient',
    'fossoiera',
    'fossoyais',
    'fossoyait',
    'fossoyant',
    'fossoyées',
    'fossoyeur',
    'fossoyiez',
    'fossoyons',
    'fouaciers',
    'fouaillai',
    'fouaillas',
    'fouaillât',
    'fouaillée',
    'fouailler',
    'fouailles',
    'fouaillés',
    'fouaillez',
    'foudroies',
    'foudroyai',
    'foudroyas',
    'foudroyât',
    'foudroyée',
    'foudroyer',
    'foudroyés',
    'foudroyez',
    'fouettais',
    'fouettait',
    'fouettant',
    'fouettard',
    'fouettées',
    'fouettent',
    'fouettera',
    'fouettiez',
    'fouettons',
    'fougasses',
    'fougeâmes',
    'fougeasse',
    'fougeâtes',
    'fougerais',
    'fougerait',
    'fougèrent',
    'fougeriez',
    'fougerole',
    'fougerons',
    'fougeront',
    'fougueuse',
    'fouillais',
    'fouillait',
    'fouillant',
    'fouillées',
    'fouillent',
    'fouillera',
    'fouilleur',
    'fouilliez',
    'fouillons',
    'fouinâmes',
    'fouinarde',
    'fouinards',
    'fouinasse',
    'fouinâtes',
    'fouinerai',
    'fouineras',
    'fouinerez',
    'fouineurs',
    'fouineuse',
    'fouinions',
    'fouirions',
    'fouissais',
    'fouissait',
    'fouissant',
    'fouissent',
    'fouisseur',
    'fouissiez',
    'fouissons',
    'foulaient',
    'foulasses',
    'foulerais',
    'foulerait',
    'foulèrent',
    'fouleries',
    'fouleriez',
    'foulerons',
    'fouleront',
    'fouleuses',
    'fourailla',
    'fouraille',
    'fouraillé',
    'fourbâmes',
    'fourbasse',
    'fourbâtes',
    'fourberai',
    'fourberas',
    'fourberez',
    'fourberie',
    'fourbîmes',
    'fourbions',
    'fourbirai',
    'fourbiras',
    'fourbirez',
    'fourbisse',
    'fourbîtes',
    'fourbures',
    'fourchais',
    'fourchait',
    'fourchant',
    'fourchées',
    'fourchent',
    'fourchera',
    'fourchets',
    'fourchiez',
    'fourchons',
    'fourchues',
    'fourgonna',
    'fourgonne',
    'fourgonné',
    'fourguais',
    'fourguait',
    'fourguant',
    'fourguées',
    'fourguent',
    'fourguera',
    'fourguiez',
    'fourguons',
    'fourmilla',
    'fourmille',
    'fourmillé',
    'fournaise',
    'fourneaux',
    'fournière',
    'fourniers',
    'fournîmes',
    'fournirai',
    'fourniras',
    'fournirez',
    'fournisse',
    'fournîtes',
    'fourragea',
    'fourragée',
    'fourrager',
    'fourrages',
    'fourragés',
    'fourragez',
    'fourrâmes',
    'fourrasse',
    'fourrâtes',
    'fourreaux',
    'fourrerai',
    'fourreras',
    'fourrerez',
    'fourreurs',
    'fourreuse',
    'fourrière',
    'fourriers',
    'fourrions',
    'fourrures',
    'fourvoies',
    'fourvoyai',
    'fourvoyas',
    'fourvoyât',
    'fourvoyée',
    'fourvoyer',
    'fourvoyés',
    'fourvoyez',
    'foutaient',
    'foutaises',
    'foutrales',
    'foutraque',
    'foutrions',
    'fracassai',
    'fracassas',
    'fracassât',
    'fracassée',
    'fracasser',
    'fracasses',
    'fracassés',
    'fracassez',
    'fractions',
    'fracturai',
    'fracturas',
    'fracturât',
    'fracturée',
    'fracturer',
    'fractures',
    'fracturés',
    'fracturez',
    'fragilisa',
    'fragilise',
    'fragilisé',
    'fragilité',
    'fragmenta',
    'fragmente',
    'fragmenté',
    'fragments',
    'fraîcheur',
    'fraîchins',
    'fraîchira',
    'fraierais',
    'fraierait',
    'fraieriez',
    'fraierons',
    'fraieront',
    'fraisages',
    'fraisâmes',
    'fraisasse',
    'fraisâtes',
    'fraiserai',
    'fraiseras',
    'fraiserez',
    'fraiseurs',
    'fraiseuse',
    'fraisière',
    'fraisiers',
    'fraisions',
    'fraisures',
    'framboisa',
    'framboise',
    'framboisé',
    'française',
    'franchies',
    'franchira',
    'franchise',
    'franciens',
    'francique',
    'francisai',
    'francisas',
    'francisât',
    'francisée',
    'franciser',
    'francises',
    'francisés',
    'francisez',
    'franciste',
    'francités',
    'franciums',
    'franc-jeu',
    'francolin',
    'frangeais',
    'frangeait',
    'frangeant',
    'frangeons',
    'frangerai',
    'frangeras',
    'frangerez',
    'frangines',
    'frangions',
    'franglais',
    'frappages',
    'frappâmes',
    'frappante',
    'frappants',
    'frappasse',
    'frappâtes',
    'frapperai',
    'frapperas',
    'frapperez',
    'frappeurs',
    'frappeuse',
    'frappions',
    'fraternel',
    'fraudâmes',
    'fraudasse',
    'fraudâtes',
    'frauderai',
    'frauderas',
    'frauderez',
    'fraudeurs',
    'fraudeuse',
    'fraudions',
    'frayaient',
    'frayasses',
    'frayement',
    'frayerais',
    'frayerait',
    'frayèrent',
    'frayeriez',
    'frayerons',
    'frayeront',
    'fredaines',
    'fredonnai',
    'fredonnas',
    'fredonnât',
    'fredonnée',
    'fredonner',
    'fredonnes',
    'fredonnés',
    'fredonnez',
    'free-jazz',
    'frégatais',
    'frégatait',
    'frégatant',
    'frégatées',
    'frégatent',
    'frégatiez',
    'frégatons',
    'freinages',
    'freinâmes',
    'freinante',
    'freinants',
    'freinasse',
    'freinâtes',
    'freinerai',
    'freineras',
    'freinerez',
    'freinions',
    'frelatage',
    'frelatais',
    'frelatait',
    'frelatant',
    'frelatées',
    'frelatent',
    'frelatera',
    'frelatiez',
    'frelatons',
    'freluquet',
    'frémirais',
    'frémirait',
    'frémirent',
    'frémiriez',
    'frémirons',
    'frémiront',
    'frémisses',
    'frémissez',
    'frénésies',
    'fréquence',
    'fréquenta',
    'fréquente',
    'fréquenté',
    'fréquents',
    'fressures',
    'frétaient',
    'frétasses',
    'fréterais',
    'fréterait',
    'frétèrent',
    'fréteriez',
    'fréterons',
    'fréteront',
    'frétillai',
    'frétillas',
    'frétillât',
    'frétiller',
    'frétilles',
    'frétillez',
    'frettages',
    'frettâmes',
    'frettasse',
    'frettâtes',
    'fretterai',
    'fretteras',
    'fretterez',
    'frettions',
    'freudiens',
    'freudisme',
    'friandise',
    'fricassai',
    'fricassas',
    'fricassât',
    'fricassée',
    'fricasser',
    'fricasses',
    'fricassés',
    'fricassez',
    'fricatifs',
    'fricative',
    'fric-frac',
    'fricotage',
    'fricotais',
    'fricotait',
    'fricotant',
    'fricotées',
    'fricotent',
    'fricotera',
    'fricoteur',
    'fricotiez',
    'fricotons',
    'frictions',
    'fridolins',
    'frigidité',
    'frigories',
    'frileuses',
    'frimaient',
    'frimasses',
    'frimerais',
    'frimerait',
    'frimèrent',
    'frimeriez',
    'frimerons',
    'frimeront',
    'frimeuses',
    'frimousse',
    'fringales',
    'fringante',
    'fringants',
    'fringuais',
    'fringuait',
    'fringuant',
    'fringuées',
    'fringuent',
    'fringuera',
    'fringuiez',
    'fringuons',
    'fripaient',
    'fripasses',
    'friperais',
    'friperait',
    'fripèrent',
    'friperies',
    'friperiez',
    'friperons',
    'friperont',
    'fripières',
    'friponnai',
    'friponnas',
    'friponnât',
    'friponnée',
    'friponner',
    'friponnes',
    'friponnés',
    'friponnez',
    'friraient',
    'frisaient',
    'frisantes',
    'frisasses',
    'friserais',
    'friserait',
    'frisèrent',
    'friseriez',
    'friserons',
    'friseront',
    'frisettes',
    'frisolées',
    'frisonnes',
    'frisottai',
    'frisottas',
    'frisottât',
    'frisottée',
    'frisotter',
    'frisottes',
    'frisottés',
    'frisottez',
    'frisottis',
    'frisquets',
    'frissions',
    'frissonna',
    'frissonne',
    'frissonné',
    'friteries',
    'friteuses',
    'frittages',
    'frittâmes',
    'frittasse',
    'frittâtes',
    'fritterai',
    'fritteras',
    'fritterez',
    'frittions',
    'frivolité',
    'froideurs',
    'froidîmes',
    'froidirai',
    'froidiras',
    'froidirez',
    'froidisse',
    'froidîtes',
    'froidures',
    'froissais',
    'froissait',
    'froissant',
    'froissées',
    'froissent',
    'froissera',
    'froissiez',
    'froissons',
    'froissure',
    'frôlaient',
    'frôlasses',
    'frôlement',
    'frôlerais',
    'frôlerait',
    'frôlèrent',
    'frôleriez',
    'frôlerons',
    'frôleront',
    'frôleuses',
    'fromagère',
    'fromagers',
    'fromental',
    'fronçâmes',
    'fronçasse',
    'fronçâtes',
    'froncerai',
    'fronceras',
    'froncerez',
    'froncions',
    'frondâmes',
    'frondasse',
    'frondâtes',
    'fronderai',
    'fronderas',
    'fronderez',
    'frondeurs',
    'frondeuse',
    'frondions',
    'frontails',
    'frontales',
    'fronteaux',
    'frontière',
    'frottages',
    'frottâmes',
    'frottante',
    'frottants',
    'frottasse',
    'frottâtes',
    'frotterai',
    'frotteras',
    'frotterez',
    'frotteurs',
    'frotteuse',
    'frottions',
    'frottoirs',
    'frouaient',
    'frouasses',
    'frouerais',
    'frouerait',
    'frouèrent',
    'froueriez',
    'frouerons',
    'froueront',
    'frou-frou',
    'froufrous',
    'froussard',
    'fructidor',
    'fructifia',
    'fructifie',
    'fructifié',
    'fructoses',
    'fructueux',
    'frugalité',
    'frugivore',
    'fruiterie',
    'fruitière',
    'fruitiers',
    'frusquais',
    'frusquait',
    'frusquant',
    'frusquées',
    'frusquent',
    'frusquera',
    'frusquiez',
    'frusquons',
    'frustrais',
    'frustrait',
    'frustrant',
    'frustrées',
    'frustrent',
    'frustrera',
    'frustriez',
    'frustrons',
    'fuchsines',
    'fuégienne',
    'fugacités',
    'fugitives',
    'fuguaient',
    'fuguasses',
    'fuguerais',
    'fuguerait',
    'fuguèrent',
    'fugueriez',
    'fuguerons',
    'fugueront',
    'fugueuses',
    'fuiraient',
    'fuissions',
    'fulgurais',
    'fulgurait',
    'fulgurant',
    'fulgurées',
    'fulgurent',
    'fulgurera',
    'fulguriez',
    'fulgurons',
    'fulminais',
    'fulminait',
    'fulminant',
    'fulminate',
    'fulminées',
    'fulminent',
    'fulminera',
    'fulminiez',
    'fulminons',
    'fumagines',
    'fumaisons',
    'fumassent',
    'fumassiez',
    'fumerions',
    'fumerolle',
    'fumeterre',
    'fumigeais',
    'fumigeait',
    'fumigeant',
    'fumigènes',
    'fumigeons',
    'fumigerai',
    'fumigeras',
    'fumigerez',
    'fumigions',
    'fumivores',
    'funambule',
    'funéraire',
    'funicules',
    'furent-ce',
    'furetages',
    'furetâmes',
    'furetasse',
    'furetâtes',
    'furèterai',
    'furèteras',
    'furèterez',
    'fureteurs',
    'fureteuse',
    'furetions',
    'furfuracé',
    'furfurals',
    'furibarde',
    'furibards',
    'furibonde',
    'furibonds',
    'furieuses',
    'furoncles',
    'fusassent',
    'fusassiez',
    'fuselages',
    'fuselâmes',
    'fuselasse',
    'fuselâtes',
    'fuselions',
    'fusellent',
    'fusellera',
    'fuserions',
    'fusiforme',
    'fusiliers',
    'fusillade',
    'fusillais',
    'fusillait',
    'fusillant',
    'fusillées',
    'fusillent',
    'fusillera',
    'fusilleur',
    'fusilliez',
    'fusillons',
    'fusiniste',
    'fusionnai',
    'fusionnas',
    'fusionnât',
    'fusionnée',
    'fusionner',
    'fusionnes',
    'fusionnés',
    'fusionnez',
    'fustigeai',
    'fustigeas',
    'fustigeât',
    'fustigées',
    'fustigent',
    'fustigera',
    'fustigiez',
    'futailles',
    'futilités',
    'futurible',
    'futurisme',
    'futuriste',
    'gabardine',
    'gabariage',
    'gabariers',
    'gabarrier',
    'gabionnai',
    'gabionnas',
    'gabionnât',
    'gabionnée',
    'gabionner',
    'gabionnes',
    'gabionnés',
    'gabionnez',
    'gâchaient',
    'gâchasses',
    'gâcherais',
    'gâcherait',
    'gâchèrent',
    'gâcheriez',
    'gâcherons',
    'gâcheront',
    'gâchettes',
    'gâcheuses',
    'gadgétisa',
    'gadgétise',
    'gadgétisé',
    'gaéliques',
    'gaffaient',
    'gaffasses',
    'gafferais',
    'gafferait',
    'gaffèrent',
    'gafferiez',
    'gafferons',
    'gafferont',
    'gaffeuses',
    'gageaient',
    'gageasses',
    'gagerions',
    'gagnables',
    'gagnaient',
    'gagnantes',
    'gagnasses',
    'gagnerais',
    'gagnerait',
    'gagnèrent',
    'gagneriez',
    'gagnerons',
    'gagneront',
    'gagneuses',
    'gaillarde',
    'gaillards',
    'gailletin',
    'gainaient',
    'gainasses',
    'gainerais',
    'gainerait',
    'gainèrent',
    'gaineries',
    'gaineriez',
    'gainerons',
    'gaineront',
    'galactose',
    'galalithe',
    'galamment',
    'galandage',
    'galantine',
    'galantins',
    'galapiats',
    'galbaient',
    'galbantes',
    'galbasses',
    'galberais',
    'galberait',
    'galbèrent',
    'galberiez',
    'galberons',
    'galberont',
    'galéasses',
    'galéjades',
    'galéjâmes',
    'galéjasse',
    'galéjâtes',
    'galéjerai',
    'galéjeras',
    'galéjerez',
    'galéjions',
    'galénique',
    'galénisme',
    'galériens',
    'galetteux',
    'galhauban',
    'galiléens',
    'galipette',
    'galipotai',
    'galipotas',
    'galipotât',
    'galipotée',
    'galipoter',
    'galipotes',
    'galipotés',
    'galipotez',
    'galléries',
    'gallicane',
    'gallicans',
    'gallicole',
    'gallinacé',
    'galliques',
    'galloises',
    'galonnais',
    'galonnait',
    'galonnant',
    'galonnées',
    'galonnent',
    'galonnera',
    'galonnier',
    'galonniez',
    'galonnons',
    'galopades',
    'galopâmes',
    'galopante',
    'galopants',
    'galopasse',
    'galopâtes',
    'galoperai',
    'galoperas',
    'galoperez',
    'galopeurs',
    'galopeuse',
    'galopions',
    'galoubets',
    'galuchats',
    'galvanisa',
    'galvanise',
    'galvanisé',
    'galvaudai',
    'galvaudas',
    'galvaudât',
    'galvaudée',
    'galvauder',
    'galvaudes',
    'galvaudés',
    'galvaudez',
    'gambadais',
    'gambadait',
    'gambadant',
    'gambadent',
    'gambadera',
    'gambadiez',
    'gambadons',
    'gambergea',
    'gambergée',
    'gamberger',
    'gamberges',
    'gambergés',
    'gambergez',
    'gambettes',
    'gambillai',
    'gambillas',
    'gambillât',
    'gambiller',
    'gambilles',
    'gambillez',
    'gaminâmes',
    'gaminasse',
    'gaminâtes',
    'gaminerai',
    'gamineras',
    'gaminerez',
    'gaminerie',
    'gaminions',
    'ganaderia',
    'gandouras',
    'ganglions',
    'gangrenai',
    'gangrenas',
    'gangrenât',
    'gangrenée',
    'gangrener',
    'gangrenés',
    'gangrènes',
    'gangrenez',
    'gangsters',
    'gansaient',
    'gansasses',
    'ganserais',
    'ganserait',
    'gansèrent',
    'ganseriez',
    'ganserons',
    'ganseront',
    'gansettes',
    'gantaient',
    'gantasses',
    'gantelées',
    'gantelets',
    'ganteline',
    'ganterais',
    'ganterait',
    'gantèrent',
    'ganteries',
    'ganteriez',
    'ganterons',
    'ganteront',
    'gantières',
    'garagiste',
    'garançais',
    'garançait',
    'garançant',
    'garancées',
    'garancent',
    'garancera',
    'garanceur',
    'garanciez',
    'garançons',
    'garanties',
    'garantira',
    'garassent',
    'garassiez',
    'garcettes',
    'garçonnes',
    'garçonnet',
    'gardaient',
    'gardasses',
    'garde-feu',
    'garde-fou',
    'gardénals',
    'gardénias',
    'garderais',
    'garderait',
    'gardèrent',
    'garderies',
    'garderiez',
    'garderons',
    'garderont',
    'gardeuses',
    'garde-vue',
    'gardienne',
    'garerions',
    'gargantua',
    'gargarisa',
    'gargarise',
    'gargarisé',
    'gargotais',
    'gargotait',
    'gargotant',
    'gargotent',
    'gargotera',
    'gargotier',
    'gargotiez',
    'gargotons',
    'gargousse',
    'garnement',
    'garnirais',
    'garnirait',
    'garnirent',
    'garniriez',
    'garnirons',
    'garniront',
    'garnisons',
    'garnisses',
    'garnissez',
    'garniture',
    'garrigues',
    'garrottai',
    'garrottas',
    'garrottât',
    'garrottée',
    'garrotter',
    'garrottes',
    'garrottés',
    'garrottez',
    'gasconnai',
    'gasconnas',
    'gasconnât',
    'gasconner',
    'gasconnes',
    'gasconnez',
    'gaspachos',
    'gaspillai',
    'gaspillas',
    'gaspillât',
    'gaspillée',
    'gaspiller',
    'gaspilles',
    'gaspillés',
    'gaspillez',
    'gastrique',
    'gastrites',
    'gastrulas',
    'gâtassent',
    'gâtassiez',
    'gâterions',
    'gâtifiais',
    'gâtifiait',
    'gâtifiant',
    'gâtifient',
    'gâtifiera',
    'gâtifiiez',
    'gâtifions',
    'gattilier',
    'gauchères',
    'gaucherie',
    'gauchîmes',
    'gauchirai',
    'gauchiras',
    'gauchirez',
    'gauchisme',
    'gauchisse',
    'gauchiste',
    'gauchîtes',
    'gaudriole',
    'gaufrages',
    'gaufrâmes',
    'gaufrasse',
    'gaufrâtes',
    'gaufrerai',
    'gaufreras',
    'gaufrerez',
    'gaufrette',
    'gaufreurs',
    'gaufreuse',
    'gaufriers',
    'gaufrions',
    'gaufroirs',
    'gaufrures',
    'gaulaient',
    'gaulasses',
    'gaulerais',
    'gaulerait',
    'gaulèrent',
    'gauleriez',
    'gaulerons',
    'gauleront',
    'gaulliens',
    'gaullisme',
    'gaulliste',
    'gauloises',
    'gaussâmes',
    'gaussasse',
    'gaussâtes',
    'gausserai',
    'gausseras',
    'gausserez',
    'gaussions',
    'gavassent',
    'gavassiez',
    'gaverions',
    'gavroches',
    'gazassent',
    'gazassiez',
    'gazéifiai',
    'gazéifias',
    'gazéifiât',
    'gazéifiée',
    'gazéifier',
    'gazéifies',
    'gazéifiés',
    'gazéifiez',
    'gazerions',
    'gazetière',
    'gazetiers',
    'gazogènes',
    'gazolines',
    'gazomètre',
    'gazonnage',
    'gazonnais',
    'gazonnait',
    'gazonnant',
    'gazonnées',
    'gazonnent',
    'gazonnera',
    'gazonniez',
    'gazonnons',
    'gazouilla',
    'gazouille',
    'gazouillé',
    'geignarde',
    'geignards',
    'geignîmes',
    'geignions',
    'geignisse',
    'geignîtes',
    'geindrais',
    'geindrait',
    'geindriez',
    'geindrons',
    'geindront',
    'gelassent',
    'gelassiez',
    'gélatinai',
    'gélatinas',
    'gélatinât',
    'gélatinée',
    'gélatiner',
    'gélatines',
    'gélatinés',
    'gélatinez',
    'gèlerions',
    'gélifiais',
    'gélifiait',
    'gélifiant',
    'gélifiées',
    'gélifient',
    'gélifiera',
    'gélifiiez',
    'gélifions',
    'gelinotte',
    'gélinotte',
    'gélivures',
    'gémellité',
    'géminâmes',
    'géminasse',
    'géminâtes',
    'géminerai',
    'gémineras',
    'géminerez',
    'géminions',
    'gémirions',
    'gémissais',
    'gémissait',
    'gémissant',
    'gémissent',
    'gémissiez',
    'gémissons',
    'gemmaient',
    'gemmasses',
    'gemmation',
    'gemmerais',
    'gemmerait',
    'gemmèrent',
    'gemmeriez',
    'gemmerons',
    'gemmeront',
    'gemmeuses',
    'gemmifère',
    'gênassent',
    'gênassiez',
    'gendarmai',
    'gendarmas',
    'gendarmât',
    'gendarmée',
    'gendarmer',
    'gendarmes',
    'gendarmés',
    'gendarmez',
    'généralat',
    'générales',
    'générâmes',
    'générasse',
    'générâtes',
    'génératif',
    'générerai',
    'généreras',
    'générerez',
    'généreuse',
    'générions',
    'gênerions',
    'générique',
    'génésique',
    'genêtière',
    'génétique',
    'génétisme',
    'genévrier',
    'génialité',
    'genièvres',
    'génitales',
    'géniteurs',
    'génitrice',
    'génocides',
    'génomique',
    'génotypes',
    'gentianes',
    'gentilité',
    'gentilles',
    'gentillet',
    'gentiment',
    'gentleman',
    'gentlemen',
    'géochimie',
    'géodésies',
    'géographe',
    'geôlières',
    'géologies',
    'géologues',
    'géomancie',
    'géométral',
    'géomètres',
    'géométrie',
    'géophages',
    'géophiles',
    'géorgiens',
    'géotrupes',
    'géraniums',
    'gérassent',
    'gérassiez',
    'gerbaient',
    'gerbantes',
    'gerbasses',
    'gerberais',
    'gerberait',
    'gerbèrent',
    'gerberiez',
    'gerberons',
    'gerberont',
    'gerbeuses',
    'gerbières',
    'gerbilles',
    'gerboises',
    'gerçaient',
    'gerçasses',
    'gercerais',
    'gercerait',
    'gercèrent',
    'gerceriez',
    'gercerons',
    'gerceront',
    'gérerions',
    'gériatres',
    'gériatrie',
    'germaient',
    'germaines',
    'germanisa',
    'germanise',
    'germanisé',
    'germanium',
    'germasses',
    'germerais',
    'germerait',
    'germèrent',
    'germeriez',
    'germerons',
    'germeront',
    'germicide',
    'gérondifs',
    'gestation',
    'gesticula',
    'gesticule',
    'gesticulé',
    'gestuelle',
    'gibbeuses',
    'gibbosité',
    'gibecière',
    'gibelines',
    'gibelotte',
    'giboierai',
    'giboieras',
    'giboierez',
    'giboulées',
    'giboyâmes',
    'giboyasse',
    'giboyâtes',
    'giboyeuse',
    'giboyions',
    'giclaient',
    'giclasses',
    'giclement',
    'giclerais',
    'giclerait',
    'giclèrent',
    'gicleriez',
    'giclerons',
    'gicleront',
    'giflaient',
    'giflasses',
    'giflerais',
    'giflerait',
    'giflèrent',
    'gifleriez',
    'giflerons',
    'gifleront',
    'gigotâmes',
    'gigotante',
    'gigotants',
    'gigotasse',
    'gigotâtes',
    'gigoterai',
    'gigoteras',
    'gigoterez',
    'gigotions',
    'giletière',
    'giletiers',
    'gimblette',
    'gingembre',
    'gingivale',
    'gingivaux',
    'gingivite',
    'girafeaux',
    'girandole',
    'girations',
    'giratoire',
    'giraumons',
    'giraumont',
    'giravions',
    'girodynes',
    'giroflées',
    'giroflier',
    'girondine',
    'girondins',
    'gironnais',
    'gironnait',
    'gironnant',
    'gironnées',
    'gironnent',
    'gironnera',
    'gironniez',
    'gironnons',
    'girouetta',
    'girouette',
    'girouetté',
    'gisements',
    'gîtassent',
    'gîtassiez',
    'gîterions',
    'givraient',
    'givrantes',
    'givrasses',
    'givrerais',
    'givrerait',
    'givrèrent',
    'givreriez',
    'givrerons',
    'givreront',
    'givreuses',
    'glabelles',
    'glaçaient',
    'glaçantes',
    'glaçasses',
    'glacerais',
    'glacerait',
    'glacèrent',
    'glaceries',
    'glaceriez',
    'glacerons',
    'glaceront',
    'glaceuses',
    'glaciaire',
    'glaciales',
    'glacières',
    'glairâmes',
    'glairasse',
    'glairâtes',
    'glairerai',
    'glaireras',
    'glairerez',
    'glaireuse',
    'glairions',
    'glairures',
    'glaisâmes',
    'glaisasse',
    'glaisâtes',
    'glaiserai',
    'glaiseras',
    'glaiserez',
    'glaiseuse',
    'glaisière',
    'glaisions',
    'glanaient',
    'glanasses',
    'glandages',
    'glandâmes',
    'glandasse',
    'glandâtes',
    'glanderai',
    'glanderas',
    'glanderez',
    'glandeurs',
    'glandeuse',
    'glandions',
    'glanerais',
    'glanerait',
    'glanèrent',
    'glaneriez',
    'glanerons',
    'glaneront',
    'glaneuses',
    'glapirais',
    'glapirait',
    'glapirent',
    'glapiriez',
    'glapirons',
    'glapiront',
    'glapisses',
    'glapissez',
    'glaréoles',
    'glatirais',
    'glatirait',
    'glatirent',
    'glatiriez',
    'glatirons',
    'glatiront',
    'glatisses',
    'glatissez',
    'glaucomes',
    'glaviotai',
    'glaviotas',
    'glaviotât',
    'glavioter',
    'glaviotes',
    'glaviotez',
    'glaviotta',
    'glaviotte',
    'glaviotté',
    'gléchomes',
    'glénoïdes',
    'glissades',
    'glissages',
    'glissâmes',
    'glissante',
    'glissants',
    'glissasse',
    'glissâtes',
    'glisserai',
    'glisseras',
    'glisserez',
    'glisseurs',
    'glisseuse',
    'glissière',
    'glissions',
    'glissoire',
    'glissoirs',
    'globalisa',
    'globalise',
    'globalisé',
    'globalité',
    'globuleux',
    'globuline',
    'glomérule',
    'gloriette',
    'glorieuse',
    'glorifiai',
    'glorifias',
    'glorifiât',
    'glorifiée',
    'glorifier',
    'glorifies',
    'glorifiés',
    'glorifiez',
    'glorioles',
    'glosaient',
    'glosasses',
    'gloserais',
    'gloserait',
    'glosèrent',
    'gloseriez',
    'gloserons',
    'gloseront',
    'glossaire',
    'glossines',
    'glossites',
    'glottales',
    'glottique',
    'glouglous',
    'gloussais',
    'gloussait',
    'gloussant',
    'gloussent',
    'gloussera',
    'gloussiez',
    'gloussons',
    'glouteron',
    'gloutonne',
    'glucinium',
    'glucoside',
    'glumelles',
    'glutamate',
    'glutineux',
    'glycémies',
    'glycéride',
    'glycéries',
    'glycérina',
    'glycérine',
    'glycériné',
    'glycogène',
    'glycolyse',
    'glyptique',
    'glyptodon',
    'gnognotes',
    'gnognotte',
    'gnomiques',
    'gnostique',
    'gobassent',
    'gobassiez',
    'gobergeai',
    'gobergeas',
    'gobergeât',
    'gobergées',
    'gobergent',
    'gobergera',
    'gobergiez',
    'goberions',
    'gobetâmes',
    'gobetasse',
    'gobetâtes',
    'gobetions',
    'gobettent',
    'gobettera',
    'godaillai',
    'godaillas',
    'godaillât',
    'godailler',
    'godailles',
    'godaillez',
    'godassent',
    'godassiez',
    'godemiché',
    'goderions',
    'godillais',
    'godillait',
    'godillant',
    'godillent',
    'godillera',
    'godilleur',
    'godilliez',
    'godillons',
    'godillots',
    'godiveaux',
    'godronnai',
    'godronnas',
    'godronnât',
    'godronnée',
    'godronner',
    'godronnes',
    'godronnés',
    'godronnez',
    'goélettes',
    'goguenard',
    'goguettes',
    'goinfrais',
    'goinfrait',
    'goinfrant',
    'goinfrées',
    'goinfrent',
    'goinfrera',
    'goinfriez',
    'goinfrons',
    'goitreuse',
    'golfeuses',
    'golmottes',
    'goménolée',
    'goménolés',
    'gominâmes',
    'gominasse',
    'gominâtes',
    'gominerai',
    'gomineras',
    'gominerez',
    'gominions',
    'gommaient',
    'gommasses',
    'gommerais',
    'gommerait',
    'gommèrent',
    'gommeriez',
    'gommerons',
    'gommeront',
    'gommeuses',
    'gonadique',
    'gondaient',
    'gondasses',
    'gonderais',
    'gonderait',
    'gondèrent',
    'gonderiez',
    'gonderons',
    'gonderont',
    'gondolage',
    'gondolais',
    'gondolait',
    'gondolant',
    'gondolent',
    'gondolera',
    'gondolier',
    'gondoliez',
    'gondolons',
    'gonfalons',
    'gonfanons',
    'gonflable',
    'gonflages',
    'gonflâmes',
    'gonflante',
    'gonflants',
    'gonflasse',
    'gonflâtes',
    'gonflerai',
    'gonfleras',
    'gonflerez',
    'gonfleurs',
    'gonflions',
    'gonnelles',
    'gonocoque',
    'gonophore',
    'gonozoïde',
    'gonzesses',
    'gorgeâmes',
    'gorgeasse',
    'gorgeâtes',
    'gorgerais',
    'gorgerait',
    'gorgèrent',
    'gorgeriez',
    'gorgerins',
    'gorgerons',
    'gorgeront',
    'gothiques',
    'gouachais',
    'gouachait',
    'gouachant',
    'gouachées',
    'gouachent',
    'gouachera',
    'gouachiez',
    'gouachons',
    'gouaillai',
    'gouaillas',
    'gouaillât',
    'gouailler',
    'gouailles',
    'gouaillez',
    'goualante',
    'goudronna',
    'goudronne',
    'goudronné',
    'goujonnai',
    'goujonnas',
    'goujonnât',
    'goujonnée',
    'goujonner',
    'goujonnes',
    'goujonnés',
    'goujonnez',
    'goulaches',
    'goulafres',
    'goulaschs',
    'gouleyant',
    'goulottes',
    'goulûment',
    'goupillai',
    'goupillas',
    'goupillât',
    'goupillée',
    'goupiller',
    'goupilles',
    'goupillés',
    'goupillez',
    'goupillon',
    'gouraient',
    'gourances',
    'gourasses',
    'gourerais',
    'gourerait',
    'gourèrent',
    'goureriez',
    'gourerons',
    'goureront',
    'gourmanda',
    'gourmande',
    'gourmandé',
    'gourmands',
    'gourmette',
    'goûtaient',
    'goûtasses',
    'goûterais',
    'goûterait',
    'goûtèrent',
    'goûteriez',
    'goûterons',
    'goûteront',
    'goûteuses',
    'gouttâmes',
    'gouttasse',
    'gouttâtes',
    'goutterai',
    'goutteras',
    'goutterez',
    'goutteuse',
    'gouttière',
    'gouttions',
    'gouvernai',
    'gouvernas',
    'gouvernât',
    'gouvernée',
    'gouverner',
    'gouvernes',
    'gouvernés',
    'gouvernez',
    'goyaviers',
    'graciâmes',
    'graciasse',
    'graciâtes',
    'gracierai',
    'gracieras',
    'gracierez',
    'gracieuse',
    'graciions',
    'gracilité',
    'gradation',
    'gradients',
    'graduâmes',
    'graduasse',
    'graduâtes',
    'graduelle',
    'graduerai',
    'gradueras',
    'graduerez',
    'graduions',
    'graffitis',
    'graillais',
    'graillait',
    'graillant',
    'graillées',
    'graillent',
    'graillera',
    'grailliez',
    'graillons',
    'grainages',
    'grainâmes',
    'grainasse',
    'grainâtes',
    'grainerai',
    'graineras',
    'grainerez',
    'grainière',
    'grainiers',
    'grainions',
    'graissage',
    'graissais',
    'graissait',
    'graissant',
    'graissées',
    'graissent',
    'graissera',
    'graisseur',
    'graisseux',
    'graissiez',
    'graissons',
    'graminées',
    'grammaire',
    'grand-duc',
    'grandelet',
    'grandesse',
    'grandette',
    'grandeurs',
    'grandîmes',
    'grandiose',
    'grandirai',
    'grandiras',
    'grandirez',
    'grandisse',
    'grandîtes',
    'granitais',
    'granitait',
    'granitant',
    'granitées',
    'granitent',
    'granitera',
    'graniteux',
    'granitiez',
    'granitons',
    'granivore',
    'granulais',
    'granulait',
    'granulant',
    'granulats',
    'granulées',
    'granulent',
    'granulera',
    'granuleux',
    'granulies',
    'granuliez',
    'granulite',
    'granulome',
    'granulons',
    'graphèmes',
    'graphique',
    'graphisme',
    'graphiste',
    'graphitai',
    'graphitas',
    'graphitât',
    'graphitée',
    'graphiter',
    'graphites',
    'graphités',
    'graphitez',
    'grappilla',
    'grappille',
    'grappillé',
    'grasserie',
    'grasseyai',
    'grasseyas',
    'grasseyât',
    'grasseyer',
    'grasseyes',
    'grasseyez',
    'graterons',
    'graticula',
    'graticule',
    'graticulé',
    'gratifiai',
    'gratifias',
    'gratifiât',
    'gratifiée',
    'gratifier',
    'gratifies',
    'gratifiés',
    'gratifiez',
    'gratinais',
    'gratinait',
    'gratinant',
    'gratinées',
    'gratinent',
    'gratinera',
    'gratiniez',
    'gratinons',
    'gratioles',
    'gratitude',
    'grattages',
    'grattâmes',
    'grattasse',
    'grattâtes',
    'grattelle',
    'gratterai',
    'gratteras',
    'gratterez',
    'gratteron',
    'gratteurs',
    'gratteuse',
    'grattions',
    'grattoirs',
    'grattures',
    'gratuites',
    'gratuités',
    'gravaient',
    'gravasses',
    'gravatier',
    'gravelées',
    'graveleux',
    'gravelles',
    'gravelure',
    'gravement',
    'graverais',
    'graverait',
    'gravèrent',
    'graveriez',
    'graverons',
    'graveront',
    'graveuses',
    'gravidité',
    'gravillon',
    'gravirais',
    'gravirait',
    'gravirent',
    'graviriez',
    'gravirons',
    'graviront',
    'gravisses',
    'gravissez',
    'gravitais',
    'gravitait',
    'gravitant',
    'gravitent',
    'gravitera',
    'gravitiez',
    'gravitons',
    'gréassent',
    'gréassiez',
    'grécisais',
    'grécisait',
    'grécisant',
    'grécisées',
    'grécisent',
    'grécisera',
    'grécisiez',
    'grécisons',
    'grecquais',
    'grecquait',
    'grecquant',
    'grecquées',
    'grecquent',
    'grecquera',
    'grecquiez',
    'grecquons',
    'gréements',
    'greffages',
    'greffâmes',
    'greffasse',
    'greffâtes',
    'grefferai',
    'grefferas',
    'grefferez',
    'greffeurs',
    'greffeuse',
    'greffière',
    'greffiers',
    'greffions',
    'greffoirs',
    'grégaires',
    'grégorien',
    'grêlerait',
    'grelottai',
    'grelottas',
    'grelottât',
    'grelotter',
    'grelottes',
    'grelottez',
    'greluchon',
    'grenaches',
    'grenadage',
    'grenadeur',
    'grenadier',
    'grenadine',
    'grenadins',
    'grenaient',
    'grenailla',
    'grenaille',
    'grenaillé',
    'grenaison',
    'grenasses',
    'grenelais',
    'grenelait',
    'grenelant',
    'grenelées',
    'greneliez',
    'grenelles',
    'grenelons',
    'grènerais',
    'grènerait',
    'grenèrent',
    'grèneriez',
    'grènerons',
    'grèneront',
    'grésaient',
    'grésasses',
    'gréserais',
    'gréserait',
    'grésèrent',
    'gréseriez',
    'gréserons',
    'gréseront',
    'grevaient',
    'grevantes',
    'grevasses',
    'grèverais',
    'grèverait',
    'grevèrent',
    'grèveriez',
    'grèverons',
    'grèveront',
    'grévistes',
    'gribiches',
    'griffades',
    'griffâmes',
    'griffasse',
    'griffâtes',
    'grifferai',
    'grifferas',
    'grifferez',
    'griffeurs',
    'griffeuse',
    'griffions',
    'griffonna',
    'griffonne',
    'griffonné',
    'griffures',
    'grignâmes',
    'grignasse',
    'grignâtes',
    'grignerai',
    'grigneras',
    'grignerez',
    'grignions',
    'grignotai',
    'grignotas',
    'grignotât',
    'grignotée',
    'grignoter',
    'grignotes',
    'grignotés',
    'grignotez',
    'grignotis',
    'grillades',
    'grillagea',
    'grillagée',
    'grillager',
    'grillages',
    'grillagés',
    'grillagez',
    'grillâmes',
    'grillasse',
    'grillâtes',
    'grillerai',
    'grilleras',
    'grillerez',
    'grillions',
    'grilloirs',
    'grimaçais',
    'grimaçait',
    'grimaçant',
    'grimacées',
    'grimacent',
    'grimacera',
    'grimacier',
    'grimaciez',
    'grimaçons',
    'grimaient',
    'grimasses',
    'grimerais',
    'grimerait',
    'grimèrent',
    'grimeriez',
    'grimerons',
    'grimeront',
    'grimoires',
    'grimpâmes',
    'grimpante',
    'grimpants',
    'grimpasse',
    'grimpâtes',
    'grimperai',
    'grimperas',
    'grimperez',
    'grimpette',
    'grimpeurs',
    'grimpeuse',
    'grimpions',
    'grinçâmes',
    'grinçante',
    'grinçants',
    'grinçasse',
    'grinçâtes',
    'grincerai',
    'grinceras',
    'grincerez',
    'grinchais',
    'grinchait',
    'grinchant',
    'grinchées',
    'grinchent',
    'grinchera',
    'grincheux',
    'grinchiez',
    'grinchons',
    'grincions',
    'gringalet',
    'gringuais',
    'gringuait',
    'gringuant',
    'gringuent',
    'gringuera',
    'gringuiez',
    'gringuons',
    'grippages',
    'grippales',
    'grippâmes',
    'grippasse',
    'grippâtes',
    'gripperai',
    'gripperas',
    'gripperez',
    'grippions',
    'grisaient',
    'grisailla',
    'grisaille',
    'grisaillé',
    'grisantes',
    'grisasses',
    'grisâtres',
    'griserais',
    'griserait',
    'grisèrent',
    'griseries',
    'griseriez',
    'griserons',
    'griseront',
    'grisettes',
    'gris-gris',
    'grisolais',
    'grisolait',
    'grisolant',
    'grisolent',
    'grisolera',
    'grisoliez',
    'grisollai',
    'grisollas',
    'grisollât',
    'grisoller',
    'grisolles',
    'grisollez',
    'grisolons',
    'grisonnai',
    'grisonnas',
    'grisonnât',
    'grisonner',
    'grisonnes',
    'grisonnez',
    'grivelais',
    'grivelait',
    'grivelant',
    'grivelées',
    'griveliez',
    'grivelles',
    'grivelons',
    'grivelure',
    'grivetons',
    'grivoises',
    'grognâmes',
    'grognarde',
    'grognards',
    'grognassa',
    'grognasse',
    'grognassé',
    'grognâtes',
    'grognerai',
    'grogneras',
    'grognerez',
    'grognerie',
    'grogneurs',
    'grogneuse',
    'grognions',
    'grognonne',
    'grommelai',
    'grommelas',
    'grommelât',
    'grommelée',
    'grommeler',
    'grommelés',
    'grommelez',
    'grommelle',
    'grondâmes',
    'grondasse',
    'grondâtes',
    'gronderai',
    'gronderas',
    'gronderez',
    'gronderie',
    'grondions',
    'groseille',
    'grosserie',
    'grossesse',
    'grosseurs',
    'grossière',
    'grossiers',
    'grossîmes',
    'grossirai',
    'grossiras',
    'grossirez',
    'grossisse',
    'grossiste',
    'grossîtes',
    'grossoies',
    'grossoyai',
    'grossoyas',
    'grossoyât',
    'grossoyée',
    'grossoyer',
    'grossoyés',
    'grossoyez',
    'grotesque',
    'grouillée',
    'grouiller',
    'grouilles',
    'grouillés',
    'grouillez',
    'grouillot',
    'groupages',
    'groupâmes',
    'groupasse',
    'groupâtes',
    'grouperai',
    'grouperas',
    'grouperez',
    'groupions',
    'grugeâmes',
    'grugeasse',
    'grugeâtes',
    'grugeoirs',
    'grugerais',
    'grugerait',
    'grugèrent',
    'grugeriez',
    'grugerons',
    'grugeront',
    'grumelais',
    'grumelait',
    'grumelant',
    'grumelées',
    'grumeleux',
    'grumeliez',
    'grumelles',
    'grumelons',
    'grumelure',
    'gruppetti',
    'gruppetto',
    'guéassent',
    'guéassiez',
    'guéerions',
    'guenilles',
    'guêpières',
    'guéridons',
    'guérillas',
    'guérirais',
    'guérirait',
    'guérirent',
    'guéririez',
    'guérirons',
    'guériront',
    'guérisons',
    'guérisses',
    'guérissez',
    'guerrière',
    'guerriers',
    'guerroies',
    'guerroyai',
    'guerroyas',
    'guerroyât',
    'guerroyer',
    'guerroyez',
    'guêtrâmes',
    'guêtrasse',
    'guêtrâtes',
    'guêtrerai',
    'guêtreras',
    'guêtrerez',
    'guêtrions',
    'guettâmes',
    'guettasse',
    'guettâtes',
    'guetterai',
    'guetteras',
    'guetterez',
    'guetteurs',
    'guetteuse',
    'guettions',
    'gueulâmes',
    'gueulante',
    'gueulants',
    'gueularde',
    'gueulards',
    'gueulasse',
    'gueulâtes',
    'gueulerai',
    'gueuleras',
    'gueulerez',
    'gueuleton',
    'gueulions',
    'gueusâmes',
    'gueusasse',
    'gueusâtes',
    'gueuserai',
    'gueuseras',
    'gueuserez',
    'gueuserie',
    'gueusions',
    'guibolles',
    'guidaient',
    'guidasses',
    'guide-âne',
    'guiderais',
    'guiderait',
    'guidèrent',
    'guideriez',
    'guiderons',
    'guideront',
    'guiderope',
    'guignâmes',
    'guignarde',
    'guignards',
    'guignasse',
    'guignâtes',
    'guignerai',
    'guigneras',
    'guignerez',
    'guignette',
    'guigniers',
    'guignions',
    'guignolet',
    'guillaume',
    'guilledou',
    'guillemet',
    'guillemot',
    'guilleret',
    'guillocha',
    'guilloche',
    'guilloché',
    'guimauves',
    'guimbarde',
    'guinchais',
    'guinchait',
    'guinchant',
    'guinchent',
    'guinchera',
    'guinchiez',
    'guinchons',
    'guindâmes',
    'guindasse',
    'guindâtes',
    'guindeaux',
    'guinderai',
    'guinderas',
    'guinderez',
    'guindions',
    'guipaient',
    'guipasses',
    'guiperais',
    'guiperait',
    'guipèrent',
    'guiperiez',
    'guiperons',
    'guiperont',
    'guirlande',
    'guisarmes',
    'guitounes',
    'gummifère',
    'gustatifs',
    'gustation',
    'gustative',
    'GUTenberg',
    'gutturale',
    'gutturaux',
    'gymkhanas',
    'gymnastes',
    'gymniques',
    'gypseuses',
    'gyromètre',
    'gyrophare',
    'gyroscope',
    'gyrostats',
    'habaneras',
    'habiletés',
    'habilitai',
    'habilitas',
    'habilitât',
    'habilitée',
    'habiliter',
    'habilites',
    'habilités',
    'habilitez',
    'habillage',
    'habillais',
    'habillait',
    'habillant',
    'habillées',
    'habillent',
    'habillera',
    'habilleur',
    'habilliez',
    'habillons',
    'habitable',
    'habitacle',
    'habitâmes',
    'habitante',
    'habitants',
    'habitasse',
    'habitâtes',
    'habiterai',
    'habiteras',
    'habiterez',
    'habitions',
    'habituais',
    'habituait',
    'habituant',
    'habitudes',
    'habituées',
    'habituels',
    'habituent',
    'habituera',
    'habituiez',
    'habituons',
    'hablaient',
    'hablasses',
    'hablerais',
    'hablerait',
    'hablèrent',
    'hâbleries',
    'hableriez',
    'hablerons',
    'hableront',
    'hâbleuses',
    'hachaient',
    'hachasses',
    'hachement',
    'hacherais',
    'hacherait',
    'hachereau',
    'hachèrent',
    'hacheriez',
    'hacherons',
    'hacheront',
    'hachettes',
    'hachischs',
    'hachurais',
    'hachurait',
    'hachurant',
    'hachurées',
    'hachurent',
    'hachurera',
    'hachuriez',
    'hachurons',
    'haciendas',
    'haineuses',
    'haïraient',
    'haïssable',
    'haïssions',
    'haïtienne',
    'halassent',
    'hâlassent',
    'halassiez',
    'hâlassiez',
    'halecrets',
    'halerions',
    'hâlerions',
    'haletâmes',
    'haletante',
    'haletants',
    'haletasse',
    'haletâtes',
    'halèterai',
    'halèteras',
    'halèterez',
    'haletions',
    'haliotide',
    'halitueux',
    'halloween',
    'hallucina',
    'hallucine',
    'halluciné',
    'halogènes',
    'halophile',
    'halophyte',
    'hamadryas',
    'hamamélis',
    'hamburger',
    'hameçonna',
    'hameçonne',
    'hameçonné',
    'hanchâmes',
    'hanchasse',
    'hanchâtes',
    'hancherai',
    'hancheras',
    'hancherez',
    'hanchions',
    'handballs',
    'handicapa',
    'handicape',
    'handicapé',
    'handicaps',
    'hannetons',
    'hantaient',
    'hantasses',
    'hanterais',
    'hanterait',
    'hantèrent',
    'hanteriez',
    'hanterons',
    'hanteront',
    'haploïdes',
    'happaient',
    'happasses',
    'happement',
    'happening',
    'happerais',
    'happerait',
    'happèrent',
    'happeriez',
    'happerons',
    'happeront',
    'haquebute',
    'haquenées',
    'hara-kiri',
    'haranguai',
    'haranguas',
    'haranguât',
    'haranguée',
    'haranguer',
    'harangues',
    'harangués',
    'haranguez',
    'harassais',
    'harassait',
    'harassant',
    'harassées',
    'harassent',
    'harassera',
    'harassiez',
    'harassons',
    'harcelais',
    'harcelait',
    'harcelant',
    'harcelées',
    'harcèlent',
    'harcèlera',
    'harceliez',
    'harcelles',
    'harcelons',
    'hardaient',
    'hardasses',
    'harderais',
    'harderait',
    'hardèrent',
    'harderiez',
    'harderons',
    'harderont',
    'hardiesse',
    'hardiment',
    'harengère',
    'harenguet',
    'hargneuse',
    'haridelle',
    'harmattan',
    'harmonica',
    'harmonies',
    'harmonisa',
    'harmonise',
    'harmonisé',
    'harmonium',
    'harnachai',
    'harnachas',
    'harnachât',
    'harnachée',
    'harnacher',
    'harnaches',
    'harnachés',
    'harnachez',
    'harpagons',
    'harpaient',
    'harpailla',
    'harpaille',
    'harpaillé',
    'harpasses',
    'harperais',
    'harperait',
    'harpèrent',
    'harperiez',
    'harperons',
    'harperont',
    'harpistes',
    'harponnai',
    'harponnas',
    'harponnât',
    'harponnée',
    'harponner',
    'harponnes',
    'harponnés',
    'harponnez',
    'haruspice',
    'hasardais',
    'hasardait',
    'hasardant',
    'hasardées',
    'hasardent',
    'hasardera',
    'hasardeux',
    'hasardiez',
    'hasardons',
    'haschichs',
    'haschisch',
    'hâtassent',
    'hâtassiez',
    'hâtelette',
    'hâtereaux',
    'hâterions',
    'hâtiveaux',
    'haubanage',
    'haubanais',
    'haubanait',
    'haubanant',
    'haubanées',
    'haubanent',
    'haubanera',
    'haubaniez',
    'haubanons',
    'haussâmes',
    'haussasse',
    'haussâtes',
    'hausserai',
    'hausseras',
    'hausserez',
    'haussière',
    'haussiers',
    'haussions',
    'hautaines',
    'hautement',
    'haut-fond',
    'hauturier',
    'havanaise',
    'havassent',
    'havassiez',
    'haveneaux',
    'haverions',
    'havirions',
    'havissais',
    'havissait',
    'havissant',
    'havissent',
    'havissiez',
    'havissons',
    'havresacs',
    'hawaïenne',
    'heaumiers',
    'hébergeai',
    'hébergeas',
    'hébergeât',
    'hébergées',
    'hébergent',
    'hébergera',
    'hébergiez',
    'hébétâmes',
    'hébétasse',
    'hébétâtes',
    'hébéterai',
    'hébéteras',
    'hébéterez',
    'hébétions',
    'hébétudes',
    'hébraïque',
    'hébraïsai',
    'hébraïsas',
    'hébraïsât',
    'hébraïser',
    'hébraïses',
    'hébraïsez',
    'hébraïsme',
    'hécatombe',
    'hectiques',
    'hectowatt',
    'hédonisme',
    'hédoniste',
    'hégéliens',
    'hégémonie',
    'heiduques',
    'heimatlos',
    'hélassent',
    'hélassiez',
    'hélépoles',
    'hélerions',
    'hélianthe',
    'héliaques',
    'héliastes',
    'hélicoïde',
    'héligares',
    'héliostat',
    'héliporté',
    'héliports',
    'hellébore',
    'hellénisa',
    'hellénise',
    'hellénisé',
    'helminthe',
    'helvelles',
    'hématines',
    'hématique',
    'hématites',
    'hématomes',
    'hématoses',
    'hématurie',
    'hémialgie',
    'hémicycle',
    'hémièdres',
    'hémiédrie',
    'hémogénie',
    'hémolyses',
    'hémophile',
    'hémostase',
    'hennirais',
    'hennirait',
    'hennirent',
    'henniriez',
    'hennirons',
    'henniront',
    'hennisses',
    'hennissez',
    'héparines',
    'hépatique',
    'hépatisme',
    'hépatites',
    'heptaèdre',
    'heptagone',
    'herbacées',
    'herbageai',
    'herbageas',
    'herbageât',
    'herbagées',
    'herbagent',
    'herbagera',
    'herbagiez',
    'herbaient',
    'herbasses',
    'herberais',
    'herberait',
    'herbèrent',
    'herberies',
    'herberiez',
    'herberons',
    'herberont',
    'herbettes',
    'herbeuses',
    'herbicide',
    'herbivore',
    'herborisa',
    'herborise',
    'herborisé',
    'herculéen',
    'hercynien',
    'herd-book',
    'hérédités',
    'hérétique',
    'hérissais',
    'hérissait',
    'hérissant',
    'hérissées',
    'hérissent',
    'hérissera',
    'hérissiez',
    'hérissons',
    'héritages',
    'héritâmes',
    'héritasse',
    'héritâtes',
    'hériterai',
    'hériteras',
    'hériterez',
    'héritière',
    'héritiers',
    'héritions',
    'hermandad',
    'hermesses',
    'herniaire',
    'hernieuse',
    'héroïques',
    'héroïsmes',
    'héronneau',
    'hersaient',
    'hersasses',
    'herschage',
    'herscheur',
    'herserais',
    'herserait',
    'hersèrent',
    'herseriez',
    'herserons',
    'herseront',
    'herseuses',
    'hésitâmes',
    'hésitante',
    'hésitants',
    'hésitasse',
    'hésitâtes',
    'hésiterai',
    'hésiteras',
    'hésiterez',
    'hésitions',
    'hétairies',
    'heureuses',
    'heurtâmes',
    'heurtasse',
    'heurtâtes',
    'heurterai',
    'heurteras',
    'heurterez',
    'heurtions',
    'heurtoirs',
    'hexacorde',
    'hexaèdres',
    'hexagonal',
    'hexagones',
    'hexamètre',
    'hexapodes',
    'hibernais',
    'hibernait',
    'hibernale',
    'hibernant',
    'hibernaux',
    'hibernées',
    'hibernent',
    'hibernera',
    'hiberniez',
    'hibernons',
    'hilarante',
    'hilarants',
    'hilarités',
    'himalayen',
    'himations',
    'hipparion',
    'hipparque',
    'hippiatre',
    'hippiques',
    'hippismes',
    'hirondeau',
    'hissaient',
    'hissasses',
    'hisserais',
    'hisserait',
    'hissèrent',
    'hisseriez',
    'hisserons',
    'hisseront',
    'histamine',
    'histidine',
    'histoires',
    'histolyse',
    'historiai',
    'historias',
    'historiât',
    'historiée',
    'historien',
    'historier',
    'histories',
    'historiés',
    'historiez',
    'histrions',
    'hitlérien',
    'hivernage',
    'hivernais',
    'hivernait',
    'hivernale',
    'hivernant',
    'hivernaux',
    'hivernées',
    'hivernent',
    'hivernera',
    'hiverniez',
    'hivernons',
    'hobereaux',
    'hochaient',
    'hochasses',
    'hochement',
    'hocherais',
    'hocherait',
    'hochèrent',
    'hocheriez',
    'hocherons',
    'hocheront',
    'hockeyeur',
    'hollandes',
    'holocènes',
    'holosides',
    'homéostat',
    'homérique',
    'homespuns',
    'homicides',
    'hominisée',
    'hominisés',
    'hommasses',
    'homodonte',
    'homofocal',
    'homogènes',
    'homologie',
    'homologua',
    'homologue',
    'homologué',
    'homonymes',
    'homonymie',
    'homophone',
    'homuncule',
    'hongrâmes',
    'hongrasse',
    'hongrâtes',
    'hongrerai',
    'hongreras',
    'hongrerez',
    'hongrions',
    'hongroies',
    'hongroise',
    'hongroyai',
    'hongroyas',
    'hongroyât',
    'hongroyée',
    'hongroyer',
    'hongroyés',
    'hongroyez',
    'honnêteté',
    'honnirais',
    'honnirait',
    'honnirent',
    'honniriez',
    'honnirons',
    'honniront',
    'honnisses',
    'honnissez',
    'honorable',
    'honoraire',
    'honorâmes',
    'honorasse',
    'honorâtes',
    'honorerai',
    'honoreras',
    'honorerez',
    'honorions',
    'honteuses',
    'hooligans',
    'hoquetais',
    'hoquetait',
    'hoquetant',
    'hoquetées',
    'hoquetiez',
    'hoquetons',
    'hoquettes',
    'hordéines',
    'horlogère',
    'horlogers',
    'hormonais',
    'hormonait',
    'hormonale',
    'hormonant',
    'hormonaux',
    'hormonées',
    'hormonent',
    'hormonera',
    'hormoniez',
    'hormonons',
    'horodatée',
    'horodatés',
    'horoscope',
    'horribles',
    'horrifiai',
    'horrifias',
    'horrifiât',
    'horrifiée',
    'horrifier',
    'horrifies',
    'horrifiés',
    'horrifiez',
    'horripila',
    'horripile',
    'horripilé',
    'hors-bord',
    'hors-cote',
    'horse-pox',
    'hortensia',
    'horticole',
    'hospodars',
    'hostilité',
    'hôtelière',
    'hôteliers',
    'hottentot',
    'hottereau',
    'hotterets',
    'houaiches',
    'houassent',
    'houassiez',
    'houblonna',
    'houblonne',
    'houblonné',
    'houerions',
    'houillère',
    'houillers',
    'houlettes',
    'houleuses',
    'houppâmes',
    'houppasse',
    'houppâtes',
    'houpperai',
    'houpperas',
    'houpperez',
    'houppette',
    'houppiers',
    'houppions',
    'hourdages',
    'hourdâmes',
    'hourdasse',
    'hourdâtes',
    'hourderai',
    'hourderas',
    'hourderez',
    'hourdîmes',
    'hourdions',
    'hourdirai',
    'hourdiras',
    'hourdirez',
    'hourdisse',
    'hourdîtes',
    'hourvaris',
    'houspilla',
    'houspille',
    'houspillé',
    'houssaies',
    'houssâmes',
    'houssasse',
    'houssâtes',
    'housserai',
    'housseras',
    'housserez',
    'houssines',
    'houssions',
    'houssoirs',
    'huassions',
    'huchaient',
    'huchasses',
    'hucherais',
    'hucherait',
    'huchèrent',
    'hucheriez',
    'hucherons',
    'hucheront',
    'hueraient',
    'huguenote',
    'huguenots',
    'huilaient',
    'huilasses',
    'huilerais',
    'huilerait',
    'huilèrent',
    'huileries',
    'huileriez',
    'huilerons',
    'huileront',
    'huileuses',
    'huisserie',
    'huissiers',
    'huitaines',
    'huitièmes',
    'huîtrière',
    'huîtriers',
    'hululâmes',
    'hululasse',
    'hululâtes',
    'hululerai',
    'hululeras',
    'hululerez',
    'hululions',
    'humanisai',
    'humanisas',
    'humanisât',
    'humanisée',
    'humaniser',
    'humanises',
    'humanisés',
    'humanisez',
    'humanisme',
    'humaniste',
    'humanités',
    'humanoïde',
    'humassent',
    'humassiez',
    'humectage',
    'humectais',
    'humectait',
    'humectant',
    'humectées',
    'humectent',
    'humectera',
    'humectiez',
    'humectons',
    'humérales',
    'humerions',
    'humidifia',
    'humidifie',
    'humidifié',
    'humidités',
    'humiliais',
    'humiliait',
    'humiliant',
    'humiliées',
    'humilient',
    'humiliera',
    'humiliiez',
    'humilions',
    'humilités',
    'humorales',
    'humorisme',
    'humoriste',
    'hurlaient',
    'hurlantes',
    'hurlasses',
    'hurlement',
    'hurlerais',
    'hurlerait',
    'hurlèrent',
    'hurleriez',
    'hurlerons',
    'hurleront',
    'hurleuses',
    'huroniens',
    'hurricane',
    'hussardes',
    'hyacinthe',
    'hyaloïdes',
    'hybridais',
    'hybridait',
    'hybridant',
    'hybridées',
    'hybrident',
    'hybridera',
    'hybridiez',
    'hybridité',
    'hybridons',
    'hydatides',
    'hydracide',
    'hydratais',
    'hydratait',
    'hydratant',
    'hydratées',
    'hydratent',
    'hydratera',
    'hydratiez',
    'hydratons',
    'hydravion',
    'hydrazine',
    'hydrémies',
    'hydriques',
    'hydrocèle',
    'hydrofoil',
    'hydrofuge',
    'hydrofugé',
    'hydrogéna',
    'hydrogéné',
    'hydrogène',
    'hydrolase',
    'hydrolats',
    'hydrolysa',
    'hydrolyse',
    'hydrolysé',
    'hydromels',
    'hydroxyde',
    'hyoïdiens',
    'hypallage',
    'hyperbate',
    'hyperbole',
    'hypnotisa',
    'hypnotise',
    'hypnotisé',
    'hypocrite',
    'hypoderme',
    'hypogynes',
    'hypophyse',
    'hypostase',
    'hypostyle',
    'hypotendu',
    'hypothèse',
    'hypotonie',
    'hystéries',
    'iatrogène',
    'ibériques',
    'icaquiers',
    'icarienne',
    'icefields',
    'ichneumon',
    'ichtyoses',
    'iconiques',
    'iconogène',
    'ictérique',
    'idéalisai',
    'idéalisas',
    'idéalisât',
    'idéalisée',
    'idéaliser',
    'idéalises',
    'idéalisés',
    'idéalisez',
    'idéalisme',
    'idéaliste',
    'idéalités',
    'idéations',
    'identifia',
    'identifie',
    'identifié',
    'identique',
    'identités',
    'idéologie',
    'idéologue',
    'idiolecte',
    'idiotifia',
    'idiotifie',
    'idiotifié',
    'idiotisai',
    'idiotisas',
    'idiotisât',
    'idiotisée',
    'idiotiser',
    'idiotises',
    'idiotisés',
    'idiotisez',
    'idiotisme',
    'idolâtrai',
    'idolâtras',
    'idolâtrât',
    'idolâtrée',
    'idolâtrer',
    'idolâtres',
    'idolâtrés',
    'idolâtrez',
    'idolâtrie',
    'idyllique',
    'ignifugea',
    'ignifugée',
    'ignifuger',
    'ignifuges',
    'ignifugés',
    'ignifugez',
    'ignitions',
    'ignivomes',
    'ignominie',
    'ignorâmes',
    'ignorance',
    'ignorante',
    'ignorants',
    'ignorasse',
    'ignorâtes',
    'ignorerai',
    'ignoreras',
    'ignorerez',
    'ignorions',
    'iguanodon',
    'illégales',
    'illettrée',
    'illettrés',
    'illicites',
    'illimitée',
    'illimités',
    'illisible',
    'illogique',
    'illogisme',
    'illuminai',
    'illuminas',
    'illuminât',
    'illuminée',
    'illuminer',
    'illumines',
    'illuminés',
    'illuminez',
    'illusions',
    'illusoire',
    'illustrai',
    'illustras',
    'illustrât',
    'illustrée',
    'illustrer',
    'illustres',
    'illustrés',
    'illustrez',
    'imageâmes',
    'imageasse',
    'imageâtes',
    'imagerais',
    'imagerait',
    'imagèrent',
    'imageries',
    'imageriez',
    'imagerons',
    'imageront',
    'imagières',
    'imaginais',
    'imaginait',
    'imaginant',
    'imaginées',
    'imaginent',
    'imaginera',
    'imaginiez',
    'imaginons',
    'imbéciles',
    'imbibâmes',
    'imbibasse',
    'imbibâtes',
    'imbiberai',
    'imbiberas',
    'imbiberez',
    'imbibions',
    'imbriquai',
    'imbriquas',
    'imbriquât',
    'imbriquée',
    'imbriquer',
    'imbriques',
    'imbriqués',
    'imbriquez',
    'imbroglio',
    'imbuvable',
    'imitables',
    'imitaient',
    'imitasses',
    'imitateur',
    'imitatifs',
    'imitation',
    'imitative',
    'imiterais',
    'imiterait',
    'imitèrent',
    'imiteriez',
    'imiterons',
    'imiteront',
    'immaculée',
    'immaculés',
    'immanence',
    'immanente',
    'immanents',
    'immatures',
    'immédiate',
    'immédiats',
    'immensité',
    'immergeai',
    'immergeas',
    'immergeât',
    'immergées',
    'immergent',
    'immergera',
    'immergiez',
    'imméritée',
    'immérités',
    'immersifs',
    'immersion',
    'immersive',
    'immeubles',
    'immigrais',
    'immigrait',
    'immigrant',
    'immigrées',
    'immigrent',
    'immigrera',
    'immigriez',
    'immigrons',
    'imminence',
    'imminente',
    'imminents',
    'immiscées',
    'immisciez',
    'immisçons',
    'immixtion',
    'immobiles',
    'immodérée',
    'immodérés',
    'immodeste',
    'immolâmes',
    'immolasse',
    'immolâtes',
    'immolerai',
    'immoleras',
    'immolerez',
    'immolions',
    'immondice',
    'immorales',
    'immortels',
    'immotivée',
    'immotivés',
    'immuables',
    'immunisai',
    'immunisas',
    'immunisât',
    'immunisée',
    'immuniser',
    'immunises',
    'immunisés',
    'immunisez',
    'immunités',
    'impactais',
    'impactait',
    'impactant',
    'impactées',
    'impactent',
    'impactera',
    'impactiez',
    'impactons',
    'impaludée',
    'impaludés',
    'imparable',
    'imparfait',
    'impartial',
    'imparties',
    'impartira',
    'impatient',
    'impavides',
    'impayable',
    'impédance',
    'impératif',
    'impériale',
    'impériaux',
    'impérieux',
    'impéritie',
    'impétigos',
    'impétrais',
    'impétrait',
    'impétrant',
    'impétrées',
    'impètrent',
    'impétrera',
    'impétriez',
    'impétrons',
    'impétueux',
    'implantai',
    'implantas',
    'implantât',
    'implantée',
    'implanter',
    'implantes',
    'implantés',
    'implantez',
    'implicite',
    'impliquai',
    'impliquas',
    'impliquât',
    'impliquée',
    'impliquer',
    'impliques',
    'impliqués',
    'impliquez',
    'implorais',
    'implorait',
    'implorant',
    'implorées',
    'implorent',
    'implorera',
    'imploriez',
    'implorons',
    'implosais',
    'implosait',
    'implosant',
    'implosent',
    'implosera',
    'implosiez',
    'implosifs',
    'implosion',
    'implosive',
    'implosons',
    'impluvium',
    'importais',
    'importait',
    'important',
    'importées',
    'importent',
    'importera',
    'importiez',
    'importons',
    'importuna',
    'importune',
    'importuné',
    'importuns',
    'imposable',
    'imposâmes',
    'imposante',
    'imposants',
    'imposasse',
    'imposâtes',
    'imposerai',
    'imposeras',
    'imposerez',
    'imposeurs',
    'imposions',
    'imposteur',
    'imposture',
    'impotence',
    'impotente',
    'impotents',
    'imprécise',
    'imprégnai',
    'imprégnas',
    'imprégnât',
    'imprégnée',
    'imprégner',
    'imprégnés',
    'imprègnes',
    'imprégnez',
    'imprévues',
    'imprimais',
    'imprimait',
    'imprimant',
    'imprimées',
    'impriment',
    'imprimera',
    'imprimeur',
    'imprimiez',
    'imprimons',
    'improbité',
    'impromptu',
    'impropres',
    'improuvai',
    'improuvas',
    'improuvât',
    'improuvée',
    'improuver',
    'improuves',
    'improuvés',
    'improuvez',
    'improvisa',
    'improvise',
    'improvisé',
    'imprudent',
    'impubères',
    'impuberté',
    'impudence',
    'impudente',
    'impudents',
    'impudeurs',
    'impudique',
    'impulsais',
    'impulsait',
    'impulsant',
    'impulsées',
    'impulsent',
    'impulsera',
    'impulsiez',
    'impulsifs',
    'impulsion',
    'impulsive',
    'impulsons',
    'impunités',
    'impuretés',
    'imputable',
    'imputâmes',
    'imputasse',
    'imputâtes',
    'imputerai',
    'imputeras',
    'imputerez',
    'imputions',
    'inabritée',
    'inabrités',
    'inachevée',
    'inachevés',
    'inactions',
    'inactivai',
    'inactivas',
    'inactivât',
    'inactivée',
    'inactiver',
    'inactives',
    'inactivez',
    'inactuels',
    'inadaptée',
    'inadaptés',
    'inadéquat',
    'inaltérée',
    'inaltérés',
    'inamicale',
    'inamicaux',
    'inanimées',
    'inanitiés',
    'inanition',
    'inapaisée',
    'inapaisés',
    'inaperçue',
    'inaperçus',
    'inassouvi',
    'inattendu',
    'inaudible',
    'inaugurai',
    'inaugural',
    'inauguras',
    'inaugurât',
    'inaugurée',
    'inaugurer',
    'inaugures',
    'inaugurés',
    'inaugurez',
    'inavouées',
    'incapable',
    'incarcéra',
    'incarcéré',
    'incarcère',
    'incarnais',
    'incarnait',
    'incarnant',
    'incarnate',
    'incarnats',
    'incarnées',
    'incarnent',
    'incarnera',
    'incarniez',
    'incarnons',
    'incartade',
    'incasique',
    'incendiai',
    'incendias',
    'incendiât',
    'incendiée',
    'incendier',
    'incendies',
    'incendiés',
    'incendiez',
    'incertain',
    'incessant',
    'inchangée',
    'inchangés',
    'inchoatif',
    'incidence',
    'incidente',
    'incidents',
    'incinérai',
    'incinéras',
    'incinérât',
    'incinérée',
    'incinérer',
    'incinérés',
    'incinères',
    'incinérez',
    'incisâmes',
    'incisasse',
    'incisâtes',
    'inciserai',
    'inciseras',
    'inciserez',
    'incisions',
    'incisives',
    'incisures',
    'incitâmes',
    'incitante',
    'incitants',
    'incitasse',
    'incitâtes',
    'incitatif',
    'inciterai',
    'inciteras',
    'inciterez',
    'incitions',
    'inciviles',
    'incivique',
    'incivisme',
    'inclément',
    'inclinais',
    'inclinait',
    'inclinant',
    'inclinées',
    'inclinent',
    'inclinera',
    'incliniez',
    'inclinons',
    'incluions',
    'inclurais',
    'inclurait',
    'inclurent',
    'incluriez',
    'inclurons',
    'incluront',
    'inclusifs',
    'inclusion',
    'inclusive',
    'inclusses',
    'incognito',
    'incolores',
    'incombais',
    'incombait',
    'incombant',
    'incombent',
    'incombera',
    'incombiez',
    'incombons',
    'incommoda',
    'incommode',
    'incommodé',
    'incomplet',
    'incompris',
    'inconfort',
    'incongrue',
    'incongrus',
    'inconnues',
    'inconsolé',
    'incorpora',
    'incorpore',
    'incorporé',
    'incorrect',
    'incrédule',
    'incrément',
    'incrimina',
    'incrimine',
    'incriminé',
    'incroyant',
    'incrustai',
    'incrustas',
    'incrustât',
    'incrustée',
    'incruster',
    'incrustes',
    'incrustés',
    'incrustez',
    'incubâmes',
    'incubasse',
    'incubâtes',
    'incuberai',
    'incuberas',
    'incuberez',
    'incubions',
    'inculpais',
    'inculpait',
    'inculpant',
    'inculpées',
    'inculpent',
    'inculpera',
    'inculpiez',
    'inculpons',
    'inculquai',
    'inculquas',
    'inculquât',
    'inculquée',
    'inculquer',
    'inculques',
    'inculqués',
    'inculquez',
    'incultivé',
    'inculture',
    'incunable',
    'incurable',
    'incurieux',
    'incursion',
    'incurvais',
    'incurvait',
    'incurvant',
    'incurvées',
    'incurvent',
    'incurvera',
    'incurviez',
    'incurvons',
    'indatable',
    'indécence',
    'indécente',
    'indécents',
    'indécises',
    'indéfinie',
    'indéfinis',
    'indélicat',
    'indemnisa',
    'indemnise',
    'indemnisé',
    'indemnité',
    'indexages',
    'indexâmes',
    'indexasse',
    'indexâtes',
    'indexerai',
    'indexeras',
    'indexerez',
    'indexions',
    'indiçâmes',
    'indiçasse',
    'indiçâtes',
    'indicatif',
    'indicerai',
    'indiceras',
    'indicerez',
    'indicible',
    'indiciels',
    'indicions',
    'indiction',
    'indiennes',
    'indifféra',
    'indifféré',
    'indiffère',
    'indigence',
    'indigènes',
    'indigente',
    'indigents',
    'indigeste',
    'indigètes',
    'indignais',
    'indignait',
    'indignant',
    'indignées',
    'indignent',
    'indignera',
    'indigniez',
    'indignité',
    'indignons',
    'indiquais',
    'indiquait',
    'indiquant',
    'indiquées',
    'indiquent',
    'indiquera',
    'indiquiez',
    'indiquons',
    'indirecte',
    'indirects',
    'indiscret',
    'indiscuté',
    'indisposa',
    'indispose',
    'indisposé',
    'individus',
    'indivises',
    'indociles',
    'indolence',
    'indolente',
    'indolents',
    'indolores',
    'indomptée',
    'indomptés',
    'inducteur',
    'inductifs',
    'induction',
    'inductive',
    'induirais',
    'induirait',
    'induiriez',
    'induirons',
    'induiront',
    'induisais',
    'induisait',
    'induisant',
    'induisent',
    'induisiez',
    'induisons',
    'indulgent',
    'indulines',
    'indurâmes',
    'indurasse',
    'indurâtes',
    'indurerai',
    'indureras',
    'indurerez',
    'indurions',
    'industrie',
    'inécoutée',
    'inécoutés',
    'ineffable',
    'inégalées',
    'inégalité',
    'inélégant',
    'inemployé',
    'inéprouvé',
    'inépuisée',
    'inépuisés',
    'inertiels',
    'inespérée',
    'inespérés',
    'inétendue',
    'inétendus',
    'inexactes',
    'inexécuté',
    'inexercée',
    'inexercés',
    'inexperte',
    'inexperts',
    'inexpiées',
    'inexploré',
    'inexprimé',
    'infamante',
    'infamants',
    'infantile',
    'infarctus',
    'infatuais',
    'infatuait',
    'infatuant',
    'infatuées',
    'infatuent',
    'infatuera',
    'infatuiez',
    'infatuons',
    'inféconde',
    'inféconds',
    'infectais',
    'infectait',
    'infectant',
    'infectées',
    'infectent',
    'infectera',
    'infectiez',
    'infection',
    'infectons',
    'inféodais',
    'inféodait',
    'inféodant',
    'inféodées',
    'inféodent',
    'inféodera',
    'inféodiez',
    'inféodons',
    'inférâmes',
    'inférasse',
    'inférâtes',
    'inférence',
    'inférerai',
    'inféreras',
    'inférerez',
    'inférieur',
    'inférions',
    'infernale',
    'infernaux',
    'infertile',
    'infestais',
    'infestait',
    'infestant',
    'infestées',
    'infestent',
    'infestera',
    'infestiez',
    'infestons',
    'infidèles',
    'infiltrai',
    'infiltras',
    'infiltrât',
    'infiltrée',
    'infiltrer',
    'infiltres',
    'infiltrés',
    'infiltrez',
    'infinités',
    'infinitif',
    'infirmais',
    'infirmait',
    'infirmant',
    'infirmées',
    'infirment',
    'infirmera',
    'infirmier',
    'infirmiez',
    'infirmité',
    'infirmons',
    'inflation',
    'infléchie',
    'infléchir',
    'infléchis',
    'infléchit',
    'infléchît',
    'inflexion',
    'infligeai',
    'infligeas',
    'infligeât',
    'infligées',
    'infligent',
    'infligera',
    'infligiez',
    'influâmes',
    'influasse',
    'influâtes',
    'influença',
    'influence',
    'influencé',
    'influente',
    'influents',
    'influenza',
    'influerai',
    'influeras',
    'influerez',
    'influions',
    'infondées',
    'informais',
    'informait',
    'informant',
    'informées',
    'informels',
    'informent',
    'informera',
    'informiez',
    'informons',
    'informulé',
    'infortune',
    'infortuné',
    'infrasons',
    'infumable',
    'infusâmes',
    'infusasse',
    'infusâtes',
    'infuserai',
    'infuseras',
    'infuserez',
    'infusible',
    'infusions',
    'infusoire',
    'ingéniées',
    'ingénieur',
    'ingénieux',
    'ingéniiez',
    'ingénions',
    'ingénuité',
    'ingérâmes',
    'ingérasse',
    'ingérâtes',
    'ingérence',
    'ingérerai',
    'ingéreras',
    'ingérerez',
    'ingérions',
    'ingestion',
    'inguinale',
    'inguinaux',
    'ingurgita',
    'ingurgite',
    'ingurgité',
    'inhabiles',
    'inhabitée',
    'inhabités',
    'inhalâmes',
    'inhalasse',
    'inhalâtes',
    'inhalerai',
    'inhaleras',
    'inhalerez',
    'inhalions',
    'inhérence',
    'inhérente',
    'inhérents',
    'inhibâmes',
    'inhibasse',
    'inhibâtes',
    'inhiberai',
    'inhiberas',
    'inhiberez',
    'inhibions',
    'inhibitif',
    'inhumaine',
    'inhumains',
    'inhumâmes',
    'inhumasse',
    'inhumâtes',
    'inhumerai',
    'inhumeras',
    'inhumerez',
    'inhumions',
    'inimitées',
    'inimitiés',
    'iniquités',
    'initiales',
    'initiâmes',
    'initiasse',
    'initiâtes',
    'initierai',
    'initieras',
    'initierez',
    'initiions',
    'injectais',
    'injectait',
    'injectant',
    'injectées',
    'injectent',
    'injectera',
    'injecteur',
    'injectiez',
    'injection',
    'injectons',
    'injonctif',
    'injouable',
    'injuriais',
    'injuriait',
    'injuriant',
    'injuriées',
    'injurient',
    'injuriera',
    'injurieux',
    'injuriiez',
    'injurions',
    'injustice',
    'inlandsis',
    'innéismes',
    'innéistes',
    'innervais',
    'innervait',
    'innervant',
    'innervées',
    'innervent',
    'innervera',
    'innerviez',
    'innervons',
    'innocence',
    'innocenta',
    'innocente',
    'innocenté',
    'innocents',
    'innocuité',
    'innominée',
    'innominés',
    'innommées',
    'innovâmes',
    'innovasse',
    'innovâtes',
    'innoverai',
    'innoveras',
    'innoverez',
    'innovions',
    'inobservé',
    'inoccupée',
    'inoccupés',
    'in-octavo',
    'inoculais',
    'inoculait',
    'inoculant',
    'inoculées',
    'inoculent',
    'inoculera',
    'inoculiez',
    'inoculons',
    'inondable',
    'inondâmes',
    'inondasse',
    'inondâtes',
    'inonderai',
    'inonderas',
    'inonderez',
    'inondions',
    'inopérant',
    'inopinées',
    'in-quarto',
    'inquiétai',
    'inquiétas',
    'inquiétât',
    'inquiétée',
    'inquiéter',
    'inquiétés',
    'inquiètes',
    'inquiétez',
    'insalubre',
    'insanités',
    'inscrirai',
    'inscriras',
    'inscrirez',
    'inscrites',
    'inscrives',
    'inscrivez',
    'inscrivis',
    'inscrivit',
    'inscrivît',
    'insculpai',
    'insculpas',
    'insculpât',
    'insculpée',
    'insculper',
    'insculpes',
    'insculpés',
    'insculpez',
    'insécable',
    'inselberg',
    'inséminai',
    'inséminas',
    'inséminât',
    'inséminée',
    'inséminer',
    'insémines',
    'inséminés',
    'inséminez',
    'insensées',
    'insérable',
    'insérâmes',
    'insérasse',
    'insérâtes',
    'insérerai',
    'inséreras',
    'insérerez',
    'insérions',
    'insertion',
    'insidieux',
    'insincère',
    'insinuais',
    'insinuait',
    'insinuant',
    'insinuées',
    'insinuent',
    'insinuera',
    'insinuiez',
    'insinuons',
    'insipides',
    'insistais',
    'insistait',
    'insistant',
    'insistent',
    'insistera',
    'insistiez',
    'insistons',
    'insolâmes',
    'insolasse',
    'insolâtes',
    'insolence',
    'insolente',
    'insolents',
    'insolerai',
    'insoleras',
    'insolerez',
    'insolions',
    'insolites',
    'insoluble',
    'insomnies',
    'insonores',
    'insoumise',
    'inspectai',
    'inspectas',
    'inspectât',
    'inspectée',
    'inspecter',
    'inspectes',
    'inspectés',
    'inspectez',
    'inspirais',
    'inspirait',
    'inspirant',
    'inspirées',
    'inspirent',
    'inspirera',
    'inspiriez',
    'inspirons',
    'instables',
    'installai',
    'installas',
    'installât',
    'installée',
    'installer',
    'installes',
    'installés',
    'installez',
    'instances',
    'instantes',
    'instaurai',
    'instauras',
    'instaurât',
    'instaurée',
    'instaurer',
    'instaures',
    'instaurés',
    'instaurez',
    'instiguai',
    'instiguas',
    'instiguât',
    'instiguée',
    'instiguer',
    'instigues',
    'instigués',
    'instiguez',
    'instillai',
    'instillas',
    'instillât',
    'instillée',
    'instiller',
    'instilles',
    'instillés',
    'instillez',
    'instincts',
    'instituai',
    'instituas',
    'instituât',
    'instituée',
    'instituer',
    'institues',
    'institués',
    'instituez',
    'instituts',
    'instruira',
    'instruire',
    'instruise',
    'instruite',
    'instruits',
    'insufflai',
    'insufflas',
    'insufflât',
    'insufflée',
    'insuffler',
    'insuffles',
    'insufflés',
    'insufflez',
    'insulaire',
    'insulines',
    'insultais',
    'insultait',
    'insultant',
    'insultées',
    'insultent',
    'insultera',
    'insulteur',
    'insultiez',
    'insultons',
    'insurgées',
    'insurgiez',
    'intactile',
    'intaillai',
    'intaillas',
    'intaillât',
    'intailler',
    'intailles',
    'intaillez',
    'intégrais',
    'intégrait',
    'intégrale',
    'intégrant',
    'intégraux',
    'intégrées',
    'intègrent',
    'intégrera',
    'intégriez',
    'intégrité',
    'intégrons',
    'intellect',
    'intenable',
    'intendant',
    'intensifs',
    'intensité',
    'intensive',
    'intentais',
    'intentait',
    'intentant',
    'intentées',
    'intentent',
    'intentera',
    'intentiez',
    'intention',
    'intentons',
    'intercala',
    'intercale',
    'intercalé',
    'intercéda',
    'intercédé',
    'intercède',
    'interdira',
    'interdire',
    'interdise',
    'interdite',
    'interdits',
    'intéressa',
    'intéresse',
    'intéressé',
    'interface',
    'interféra',
    'interféré',
    'interfère',
    'intérieur',
    'interjeta',
    'interjeté',
    'interlock',
    'interlope',
    'interlude',
    'intermède',
    'internais',
    'internait',
    'internant',
    'internats',
    'internées',
    'internent',
    'internera',
    'interniez',
    'internons',
    'interpola',
    'interpole',
    'interpolé',
    'interposa',
    'interpose',
    'interposé',
    'interroge',
    'interrogé',
    'intervenu',
    'interview',
    'intervins',
    'intervint',
    'intervînt',
    'interzone',
    'intestats',
    'intestine',
    'intestins',
    'intimâmes',
    'intimasse',
    'intimâtes',
    'intimerai',
    'intimeras',
    'intimerez',
    'intimidai',
    'intimidas',
    'intimidât',
    'intimidée',
    'intimider',
    'intimides',
    'intimidés',
    'intimidez',
    'intimions',
    'intimisme',
    'intimiste',
    'intimités',
    'intitulai',
    'intitulas',
    'intitulât',
    'intitulée',
    'intituler',
    'intitules',
    'intitulés',
    'intitulez',
    'intoxiqua',
    'intoxique',
    'intoxiqué',
    'intrépide',
    'intrigant',
    'intriguai',
    'intriguas',
    'intriguât',
    'intriguée',
    'intriguer',
    'intrigues',
    'intrigués',
    'intriguez',
    'intriquai',
    'intriquas',
    'intriquât',
    'intriquée',
    'intriquer',
    'intriques',
    'intriqués',
    'intriquez',
    'introduis',
    'introduit',
    'intronisa',
    'intronise',
    'intronisé',
    'intrusion',
    'intubâmes',
    'intubasse',
    'intubâtes',
    'intuberai',
    'intuberas',
    'intuberez',
    'intubions',
    'intuitifs',
    'intuition',
    'intuitive',
    'inusables',
    'inusitées',
    'inusuelle',
    'inutilisé',
    'inutilité',
    'invaginai',
    'invaginas',
    'invaginât',
    'invaginée',
    'invaginer',
    'invagines',
    'invaginés',
    'invaginez',
    'invaincue',
    'invaincus',
    'invalidai',
    'invalidas',
    'invalidât',
    'invalidée',
    'invalider',
    'invalides',
    'invalidés',
    'invalidez',
    'invariant',
    'invasions',
    'invectiva',
    'invective',
    'invectivé',
    'invendues',
    'inventais',
    'inventait',
    'inventant',
    'inventées',
    'inventent',
    'inventera',
    'inventeur',
    'inventiez',
    'inventifs',
    'invention',
    'inventive',
    'inventons',
    'inversais',
    'inversait',
    'inversant',
    'inversées',
    'inversent',
    'inversera',
    'inverseur',
    'inversiez',
    'inversifs',
    'inversion',
    'inversive',
    'inversons',
    'invertase',
    'inverties',
    'invertine',
    'invertira',
    'investies',
    'investira',
    'invétérée',
    'invétérer',
    'invétérés',
    'invétérez',
    'inviolées',
    'invisible',
    'invitâmes',
    'invitante',
    'invitants',
    'invitasse',
    'invitâtes',
    'inviterai',
    'inviteras',
    'inviterez',
    'invitions',
    'invivable',
    'involucré',
    'involutée',
    'involutés',
    'invoquais',
    'invoquait',
    'invoquant',
    'invoquées',
    'invoquent',
    'invoquera',
    'invoquiez',
    'invoquons',
    'iodassent',
    'iodassiez',
    'ioderions',
    'iodlaient',
    'iodlasses',
    'iodlerais',
    'iodlerait',
    'iodlèrent',
    'iodleriez',
    'iodlerons',
    'iodleront',
    'iodoforme',
    'ioniennes',
    'ionisâmes',
    'ionisante',
    'ionisants',
    'ionisasse',
    'ionisâtes',
    'ioniserai',
    'ioniseras',
    'ioniserez',
    'ionisions',
    'iotacisme',
    'ioulaient',
    'ioulasses',
    'ioulerais',
    'ioulerait',
    'ioulèrent',
    'iouleriez',
    'ioulerons',
    'iouleront',
    'irakienne',
    'iranienne',
    'iraqienne',
    'irascible',
    'iréniques',
    'irénismes',
    'iridienne',
    'irisables',
    'irisaient',
    'irisasses',
    'irisation',
    'iriserais',
    'iriserait',
    'irisèrent',
    'iriseriez',
    'iriserons',
    'iriseront',
    'irlandais',
    'ironiques',
    'ironisais',
    'ironisait',
    'ironisant',
    'ironisent',
    'ironisera',
    'ironisiez',
    'ironisons',
    'ironistes',
    'iroquoise',
    'irradiais',
    'irradiait',
    'irradiant',
    'irradiées',
    'irradient',
    'irradiera',
    'irradiiez',
    'irradions',
    'irréalisé',
    'irréalité',
    'irréelles',
    'irréfutée',
    'irréfutés',
    'irrésolue',
    'irrésolus',
    'irrespect',
    'irrigable',
    'irriguais',
    'irriguait',
    'irriguant',
    'irriguées',
    'irriguent',
    'irriguera',
    'irriguiez',
    'irriguons',
    'irritable',
    'irritâmes',
    'irritante',
    'irritants',
    'irritasse',
    'irritâtes',
    'irritatif',
    'irriterai',
    'irriteras',
    'irriterez',
    'irritions',
    'irruption',
    'ischémies',
    'islamique',
    'islamisai',
    'islamisas',
    'islamisât',
    'islamisée',
    'islamiser',
    'islamises',
    'islamisés',
    'islamisez',
    'islamisme',
    'islamiste',
    'islandais',
    'isobathes',
    'isocardes',
    'isochores',
    'isochrone',
    'isoclinal',
    'isoclines',
    'isogamies',
    'isoglosse',
    'isohyètes',
    'isohypses',
    'isolables',
    'isolaient',
    'isolantes',
    'isolasses',
    'isolateur',
    'isolation',
    'isolement',
    'isolément',
    'isolerais',
    'isolerait',
    'isolèrent',
    'isoleriez',
    'isolerons',
    'isoleront',
    'isologues',
    'isoméries',
    'isonomies',
    'isoséiste',
    'isosistes',
    'isostasie',
    'isotherme',
    'isotonies',
    'israélien',
    'israélite',
    'isthmique',
    'italienne',
    'italiques',
    'itéraient',
    'itérasses',
    'itératifs',
    'itération',
    'itérative',
    'itérerais',
    'itérerait',
    'itérèrent',
    'itéreriez',
    'itérerons',
    'itéreront',
    'itinérant',
    'ivoirerie',
    'ivoiriens',
    'ivoirière',
    'ivoiriers',
    'ivoirines',
    'jablaient',
    'jablasses',
    'jablerais',
    'jablerait',
    'jablèrent',
    'jableriez',
    'jablerons',
    'jableront',
    'jablières',
    'jabloires',
    'jaborandi',
    'jabotâmes',
    'jabotasse',
    'jabotâtes',
    'jaboterai',
    'jaboteras',
    'jaboterez',
    'jabotions',
    'jacaranda',
    'jacassais',
    'jacassait',
    'jacassant',
    'jacassent',
    'jacassera',
    'jacasseur',
    'jacassiez',
    'jacassons',
    'jacinthes',
    'jacobines',
    'jacquards',
    'jacquerie',
    'jacquiers',
    'jactaient',
    'jactances',
    'jactasses',
    'jacterais',
    'jacterait',
    'jactèrent',
    'jacteriez',
    'jacterons',
    'jacteront',
    'jaillîmes',
    'jaillirai',
    'jailliras',
    'jaillirez',
    'jaillisse',
    'jaillîtes',
    'jaïnismes',
    'jalonnais',
    'jalonnait',
    'jalonnant',
    'jalonnées',
    'jalonnent',
    'jalonnera',
    'jalonneur',
    'jalonniez',
    'jalonnons',
    'jalousais',
    'jalousait',
    'jalousant',
    'jalousées',
    'jalousent',
    'jalousera',
    'jalousies',
    'jalousiez',
    'jalousons',
    'jambettes',
    'jambières',
    'jamborees',
    'jambosier',
    'janotisme',
    'japonaise',
    'japonerie',
    'japonisme',
    'japoniste',
    'japonnais',
    'japonnait',
    'japonnant',
    'japonnées',
    'japonnent',
    'japonnera',
    'japonniez',
    'japonnons',
    'jappaient',
    'jappasses',
    'jappement',
    'japperais',
    'japperait',
    'jappèrent',
    'japperiez',
    'japperons',
    'japperont',
    'jappeuses',
    'jaquemart',
    'jaquettes',
    'jardinage',
    'jardinais',
    'jardinait',
    'jardinant',
    'jardinées',
    'jardinent',
    'jardinera',
    'jardinets',
    'jardineux',
    'jardinier',
    'jardiniez',
    'jardinons',
    'jarettera',
    'jargonnai',
    'jargonnas',
    'jargonnât',
    'jargonner',
    'jargonnes',
    'jargonnez',
    'jarretais',
    'jarretait',
    'jarretant',
    'jarretées',
    'jarretiez',
    'jarretons',
    'jarrettes',
    'jasassent',
    'jasassiez',
    'jaserions',
    'jaspaient',
    'jaspasses',
    'jasperais',
    'jasperait',
    'jaspèrent',
    'jasperiez',
    'jasperons',
    'jasperont',
    'jaspinais',
    'jaspinait',
    'jaspinant',
    'jaspinées',
    'jaspinent',
    'jaspinera',
    'jaspiniez',
    'jaspinons',
    'jaugeages',
    'jaugeâmes',
    'jaugeasse',
    'jaugeâtes',
    'jaugerais',
    'jaugerait',
    'jaugèrent',
    'jaugeriez',
    'jaugerons',
    'jaugeront',
    'jaumières',
    'jaunâtres',
    'jaunettes',
    'jaunirais',
    'jaunirait',
    'jaunirent',
    'jauniriez',
    'jaunirons',
    'jauniront',
    'jaunisses',
    'jaunissez',
    'javanaise',
    'javelages',
    'javelâmes',
    'javelasse',
    'javelâtes',
    'javeleurs',
    'javeleuse',
    'javelines',
    'javelions',
    'javellent',
    'javellera',
    'javellisa',
    'javellise',
    'javellisé',
    'jeannette',
    'jectisses',
    'jennérien',
    'jérémiade',
    'jerkaient',
    'jerkasses',
    'jerkerais',
    'jerkerait',
    'jerkèrent',
    'jerkeriez',
    'jerkerons',
    'jerkeront',
    'jéroboams',
    'jerricane',
    'jerricans',
    'jerrycans',
    'jetassent',
    'jetassiez',
    'jettatura',
    'jetterais',
    'jetterait',
    'jetteriez',
    'jetterons',
    'jetteront',
    'jeûnaient',
    'jeûnasses',
    'jeunement',
    'jeûnerais',
    'jeûnerait',
    'jeûnèrent',
    'jeûneriez',
    'jeûnerons',
    'jeûneront',
    'jeunesses',
    'jeunettes',
    'jeûneuses',
    'jeunottes',
    'jiu-jitsu',
    'joaillier',
    'jobardais',
    'jobardait',
    'jobardant',
    'jobardées',
    'jobardent',
    'jobardera',
    'jobardiez',
    'jobardise',
    'jobardons',
    'jocrisses',
    'jodlaient',
    'jodlasses',
    'jodlerais',
    'jodlerait',
    'jodlèrent',
    'jodleriez',
    'jodlerons',
    'jodleront',
    'johannite',
    'joignîmes',
    'joignions',
    'joignisse',
    'joignîtes',
    'joindrais',
    'joindrait',
    'joindriez',
    'joindrons',
    'joindront',
    'jointives',
    'jointoies',
    'jointoyai',
    'jointoyas',
    'jointoyât',
    'jointoyée',
    'jointoyer',
    'jointoyés',
    'jointures',
    'joliesses',
    'jonçaient',
    'jonçasses',
    'joncerais',
    'joncerait',
    'joncèrent',
    'jonceriez',
    'joncerons',
    'jonceront',
    'jonchâmes',
    'jonchante',
    'jonchants',
    'jonchasse',
    'jonchâtes',
    'joncherai',
    'joncheras',
    'jonchères',
    'joncherez',
    'jonchions',
    'jonctions',
    'jonglâmes',
    'jonglasse',
    'jonglâtes',
    'jonglerai',
    'jongleras',
    'jonglerez',
    'jonglerie',
    'jongleurs',
    'jongleuse',
    'jonglions',
    'jonkheers',
    'jonquille',
    'jontoyais',
    'jontoyait',
    'jontoyant',
    'jontoyiez',
    'jontoyons',
    'jordanien',
    'jottereau',
    'jouaillai',
    'jouaillas',
    'jouaillât',
    'jouailler',
    'jouailles',
    'jouaillez',
    'jouassent',
    'jouassiez',
    'joubarbes',
    'jouerions',
    'joufflues',
    'jouirions',
    'jouissais',
    'jouissait',
    'jouissant',
    'jouissent',
    'jouisseur',
    'jouissiez',
    'jouissifs',
    'jouissive',
    'jouissons',
    'joutaient',
    'joutasses',
    'jouterais',
    'jouterait',
    'joutèrent',
    'jouteriez',
    'jouterons',
    'jouteront',
    'jouteuses',
    'jouvences',
    'jouxtâmes',
    'jouxtasse',
    'jouxtâtes',
    'jouxterai',
    'jouxteras',
    'jouxterez',
    'jouxtions',
    'jovialité',
    'joviennes',
    'joyeuseté',
    'jubilaire',
    'jubilâmes',
    'jubilasse',
    'jubilâtes',
    'jubilerai',
    'jubileras',
    'jubilerez',
    'jubilions',
    'juchaient',
    'juchasses',
    'jucherais',
    'jucherait',
    'juchèrent',
    'jucheriez',
    'jucherons',
    'jucheront',
    'judaïcité',
    'judaïques',
    'judaïsmes',
    'judicieux',
    'jugeables',
    'jugeaient',
    'jugeasses',
    'jugements',
    'jugerions',
    'jugulaire',
    'jugulâmes',
    'jugulante',
    'jugulants',
    'jugulasse',
    'jugulâtes',
    'jugulerai',
    'juguleras',
    'jugulerez',
    'jugulions',
    'juiveries',
    'jujubiers',
    'juliennes',
    'jumelages',
    'jumelâmes',
    'jumelasse',
    'jumelâtes',
    'jumelions',
    'jumellent',
    'jumellera',
    'junoniens',
    'juponnais',
    'juponnait',
    'juponnant',
    'juponnées',
    'juponnent',
    'juponnera',
    'juponniez',
    'juponnons',
    'jurassent',
    'jurassien',
    'jurassiez',
    'juratoire',
    'jurements',
    'jurerions',
    'juridique',
    'juridisme',
    'jusque-là',
    'jusquiame',
    'justement',
    'justesses',
    'justicier',
    'justifiai',
    'justifias',
    'justifiât',
    'justifiée',
    'justifier',
    'justifies',
    'justifiés',
    'justifiez',
    'jutassent',
    'jutassiez',
    'juterions',
    'juvéniles',
    'juxtaposa',
    'juxtapose',
    'juxtaposé',
    'kafkaïens',
    'kakémonos',
    'kalicytie',
    'kaliémies',
    'kalmoukes',
    'kamikazes',
    'kangourou',
    'kantienne',
    'kantismes',
    'kaoliangs',
    'kapokiers',
    'karatékas',
    'karstique',
    'keepsakes',
    'kénotrons',
    'kératines',
    'kératites',
    'kératoses',
    'kermesses',
    'kérogènes',
    'kérosènes',
    'keynésien',
    'khâgneuse',
    'khalifats',
    'khédivale',
    'khédivats',
    'khédivaux',
    'khédivial',
    'khédiviat',
    'kidnappai',
    'kidnappas',
    'kidnappât',
    'kidnappée',
    'kidnapper',
    'kidnappes',
    'kidnappés',
    'kidnappez',
    'kiesérite',
    'kilocycle',
    'kilohertz',
    'kilométra',
    'kilométré',
    'kilomètre',
    'kilotonne',
    'kilovolts',
    'kilowatts',
    'kinescope',
    'kinkajous',
    'klaxonnai',
    'klaxonnas',
    'klaxonnât',
    'klaxonnée',
    'klaxonner',
    'klaxonnes',
    'klaxonnés',
    'klaxonnez',
    'klystrons',
    'knock-out',
    'kolatiers',
    'kolinskis',
    'kolkhozes',
    'korrigane',
    'korrigans',
    'kreutzers',
    'kronprinz',
    'kymriques',
    'kyrielles',
    'kystiques',
    'labdanums',
    'labialisa',
    'labialise',
    'labialisé',
    'laborieux',
    'labourage',
    'labourais',
    'labourait',
    'labourant',
    'labourées',
    'labourent',
    'labourera',
    'laboureur',
    'labouriez',
    'labourons',
    'labradors',
    'laçassent',
    'laçassiez',
    'laccolite',
    'lacements',
    'lacérâmes',
    'lacérasse',
    'lacérâtes',
    'lacérerai',
    'lacéreras',
    'lacérerez',
    'lacerions',
    'lacérions',
    'lâchaient',
    'lâchasses',
    'lâchement',
    'lâcherais',
    'lâcherait',
    'lâchèrent',
    'lâcheriez',
    'lâcherons',
    'lâcheront',
    'lâcheuses',
    'laciniées',
    'laconique',
    'laconisme',
    'lacrymale',
    'lacrymaux',
    'lactaires',
    'lactation',
    'lactifère',
    'lactiques',
    'lacunaire',
    'lacuneuse',
    'lacustres',
    'là-dessus',
    'ladreries',
    'lagopèdes',
    'lagothrix',
    'lagunaire',
    'laïcisais',
    'laïcisait',
    'laïcisant',
    'laïcisées',
    'laïcisent',
    'laïcisera',
    'laïcisiez',
    'laïcismes',
    'laïcisons',
    'laidement',
    'laiderons',
    'laierions',
    'lainaient',
    'lainasses',
    'lainerais',
    'lainerait',
    'lainèrent',
    'laineries',
    'laineriez',
    'lainerons',
    'laineront',
    'laineuses',
    'lainières',
    'laissâmes',
    'laissasse',
    'laissâtes',
    'laisserai',
    'laisseras',
    'laisserez',
    'laissions',
    'laitances',
    'laiteries',
    'laiterons',
    'laiteuses',
    'laitières',
    'laitonnai',
    'laitonnas',
    'laitonnât',
    'laitonnée',
    'laitonner',
    'laitonnes',
    'laitonnés',
    'laitonnez',
    'laïusseur',
    'lallation',
    'lamaïsmes',
    'lamaïstes',
    'lamanages',
    'lamaneurs',
    'lamantins',
    'lamaserie',
    'lamassent',
    'lamassiez',
    'lambinais',
    'lambinait',
    'lambinant',
    'lambinent',
    'lambinera',
    'lambiniez',
    'lambinons',
    'lambourde',
    'lambrissa',
    'lambrisse',
    'lambrissé',
    'lambruche',
    'lambswool',
    'lamellées',
    'lamelleux',
    'lamentais',
    'lamentait',
    'lamentant',
    'lamentées',
    'lamentent',
    'lamentera',
    'lamentiez',
    'lamentons',
    'lamerions',
    'lamifiées',
    'laminages',
    'laminaire',
    'laminâmes',
    'laminasse',
    'laminâtes',
    'laminerai',
    'lamineras',
    'laminerez',
    'lamineurs',
    'lamineuse',
    'laminions',
    'laminoirs',
    'lampaient',
    'lampassée',
    'lampasses',
    'lampassés',
    'lamperais',
    'lamperait',
    'lampèrent',
    'lamperiez',
    'lamperons',
    'lamperont',
    'lampistes',
    'lampourde',
    'lamproies',
    'lançaient',
    'lançasses',
    'lancement',
    'lancéolée',
    'lancéolés',
    'lancerais',
    'lancerait',
    'lancèrent',
    'lanceriez',
    'lancerons',
    'lanceront',
    'lancettes',
    'lanceuses',
    'lancinais',
    'lancinait',
    'lancinant',
    'lancinées',
    'lancinent',
    'lancinera',
    'lanciniez',
    'lancinons',
    'landaises',
    'landaulet',
    'landgrave',
    'landsturm',
    'langagier',
    'langeâmes',
    'langeasse',
    'langeâtes',
    'langerais',
    'langerait',
    'langèrent',
    'langeriez',
    'langerons',
    'langeront',
    'langouste',
    'languette',
    'langueurs',
    'langueyai',
    'langueyas',
    'langueyât',
    'langueyée',
    'langueyer',
    'langueyes',
    'langueyés',
    'langueyez',
    'languides',
    'languiers',
    'languîmes',
    'languirai',
    'languiras',
    'languirez',
    'languisse',
    'languîtes',
    'lanifères',
    'lanigères',
    'lanolines',
    'lantanier',
    'lanternai',
    'lanternas',
    'lanternât',
    'lanternée',
    'lanterner',
    'lanternes',
    'lanternés',
    'lanternez',
    'lanternon',
    'lanthanes',
    'laotienne',
    'lapassent',
    'lapassiez',
    'lapements',
    'lapereaux',
    'laperions',
    'lapicides',
    'lapidaire',
    'lapidâmes',
    'lapidasse',
    'lapidâtes',
    'lapiderai',
    'lapideras',
    'lapiderez',
    'lapidions',
    'lapinière',
    'lapinisme',
    'laquaient',
    'laquantes',
    'laquasses',
    'laquerais',
    'laquerait',
    'laquèrent',
    'laqueriez',
    'laquerons',
    'laqueront',
    'laqueuses',
    'lardaient',
    'lardasses',
    'larderais',
    'larderait',
    'lardèrent',
    'larderiez',
    'larderons',
    'larderont',
    'lardoires',
    'lardonnai',
    'lardonnas',
    'lardonnât',
    'lardonnée',
    'lardonner',
    'lardonnes',
    'lardonnés',
    'lardonnez',
    'largables',
    'largement',
    'largesses',
    'larghetto',
    'larguâmes',
    'larguasse',
    'larguâtes',
    'larguerai',
    'largueras',
    'larguerez',
    'larguions',
    'larmoient',
    'larmoiera',
    'larmoyais',
    'larmoyait',
    'larmoyant',
    'larmoyées',
    'larmoyiez',
    'larmoyons',
    'larvaires',
    'larvicide',
    'laryngées',
    'laryngien',
    'laryngite',
    'lasciveté',
    'lascivité',
    'lassaient',
    'lassantes',
    'lassasses',
    'lasserais',
    'lasserait',
    'lassèrent',
    'lasseriez',
    'lasserons',
    'lasseront',
    'lassitude',
    'lataniers',
    'latérales',
    'latérites',
    'laticlave',
    'latifolié',
    'latinisai',
    'latinisas',
    'latinisât',
    'latinisée',
    'latiniser',
    'latinises',
    'latinisés',
    'latinisez',
    'latinisme',
    'latiniste',
    'latinités',
    'latitudes',
    'lattaient',
    'lattasses',
    'latterais',
    'latterait',
    'lattèrent',
    'latteriez',
    'latterons',
    'latteront',
    'laudanums',
    'laudateur',
    'laudatifs',
    'laudative',
    'lauréates',
    'lavandins',
    'lavassent',
    'lavassiez',
    'lavements',
    'laverions',
    'laxatives',
    'layassent',
    'layassiez',
    'layerions',
    'layetiers',
    'lazariste',
    'lazulites',
    'lazurites',
    'lazzarone',
    'lazzaroni',
    'léchaient',
    'léchasses',
    'lèche-cul',
    'lèchement',
    'lécherais',
    'lécherait',
    'léchèrent',
    'lécheriez',
    'lécherons',
    'lécheront',
    'lécheuses',
    'lécithine',
    'lectorats',
    'lectrices',
    'légalisai',
    'légalisas',
    'légalisât',
    'légalisée',
    'légaliser',
    'légalises',
    'légalisés',
    'légalisez',
    'légalisme',
    'légaliste',
    'légalités',
    'légataire',
    'légations',
    'légèretés',
    'légiférai',
    'légiféras',
    'légiférât',
    'légiférer',
    'légifères',
    'légiférez',
    'légitimai',
    'légitimas',
    'légitimât',
    'légitimée',
    'légitimer',
    'légitimes',
    'légitimés',
    'légitimez',
    'léguaient',
    'léguasses',
    'léguerais',
    'léguerait',
    'léguèrent',
    'légueriez',
    'léguerons',
    'légueront',
    'légumière',
    'légumiers',
    'légumines',
    'leitmotiv',
    'lendemain',
    'lénifiais',
    'lénifiait',
    'lénifiant',
    'lénifiées',
    'lénifient',
    'lénifiera',
    'lénifiiez',
    'lénifions',
    'léninisme',
    'lénitives',
    'lentement',
    'lentigine',
    'lentilles',
    'lentillon',
    'lentisque',
    'léonardes',
    'léopardée',
    'léopardés',
    'lépreuses',
    'lésassent',
    'lésassiez',
    'lesbienne',
    'léserions',
    'lésinâmes',
    'lésinante',
    'lésinants',
    'lésinasse',
    'lésinâtes',
    'lésinerai',
    'lésineras',
    'lésinerez',
    'lésinerie',
    'lésineurs',
    'lésineuse',
    'lésinions',
    'lésionnel',
    'lessivage',
    'lessivais',
    'lessivait',
    'lessivant',
    'lessivées',
    'lessivent',
    'lessivera',
    'lessiviel',
    'lessiviez',
    'lessivons',
    'lestaient',
    'lestasses',
    'lestement',
    'lesterais',
    'lesterait',
    'lestèrent',
    'lesteriez',
    'lesterons',
    'lesteront',
    'létalités',
    'léthargie',
    'lettonnes',
    'lettrages',
    'lettrines',
    'lettrisme',
    'leucanies',
    'leucémies',
    'leucocyte',
    'leurrâmes',
    'leurrasse',
    'leurrâtes',
    'leurrerai',
    'leurreras',
    'leurrerez',
    'leurrions',
    'levantine',
    'levantins',
    'levassent',
    'levassiez',
    'lèverions',
    'lévigeais',
    'lévigeait',
    'lévigeant',
    'lévigeons',
    'lévigerai',
    'lévigeras',
    'lévigerez',
    'lévigions',
    'levrettai',
    'levrettas',
    'levrettât',
    'levrettée',
    'levretter',
    'levrettes',
    'levrettés',
    'levrettez',
    'levronnes',
    'lévuloses',
    'lexicales',
    'lézardais',
    'lézardait',
    'lézardant',
    'lézardées',
    'lézardent',
    'lézardera',
    'lézardiez',
    'lézardons',
    'liaisonna',
    'liaisonne',
    'liaisonné',
    'liardâmes',
    'liardasse',
    'liardâtes',
    'liarderai',
    'liarderas',
    'liarderez',
    'liardions',
    'liasiques',
    'liassions',
    'libanaise',
    'libations',
    'libellais',
    'libellait',
    'libellant',
    'libellées',
    'libellent',
    'libellera',
    'libelliez',
    'libellons',
    'libellule',
    'libérable',
    'libérales',
    'libérâmes',
    'libérasse',
    'libérâtes',
    'libérerai',
    'libéreras',
    'libérerez',
    'libériens',
    'libérions',
    'libertine',
    'libertins',
    'libidinal',
    'libraires',
    'librairie',
    'libration',
    'librement',
    'librettos',
    'libyennes',
    'licenciai',
    'licencias',
    'licenciât',
    'licenciée',
    'licencier',
    'licencies',
    'licenciés',
    'licenciez',
    'lichaient',
    'lichasses',
    'licherais',
    'licherait',
    'lichèrent',
    'licheriez',
    'licherons',
    'licheront',
    'lichettes',
    'licitâmes',
    'licitasse',
    'licitâtes',
    'liciterai',
    'liciteras',
    'liciterez',
    'licitions',
    'liégeâmes',
    'liégeasse',
    'liégeâtes',
    'liégeoise',
    'liégerais',
    'liégerait',
    'liégèrent',
    'liégeriez',
    'liégerons',
    'liégeront',
    'lieraient',
    'liftaient',
    'liftasses',
    'lifterais',
    'lifterait',
    'liftèrent',
    'lifteriez',
    'lifterons',
    'lifteront',
    'liftières',
    'ligaments',
    'ligaturai',
    'ligaturas',
    'ligaturât',
    'ligaturée',
    'ligaturer',
    'ligatures',
    'ligaturés',
    'ligaturez',
    'lignaient',
    'lignasses',
    'lignerais',
    'lignerait',
    'lignèrent',
    'ligneriez',
    'lignerons',
    'ligneront',
    'ligneuses',
    'lignicole',
    'lignifiai',
    'lignifias',
    'lignifiât',
    'lignifiée',
    'lignifier',
    'lignifies',
    'lignifiés',
    'lignifiez',
    'ligotages',
    'ligotâmes',
    'ligotasse',
    'ligotâtes',
    'ligoterai',
    'ligoteras',
    'ligoterez',
    'ligotions',
    'liguaient',
    'liguasses',
    'liguerais',
    'liguerait',
    'liguèrent',
    'ligueriez',
    'liguerons',
    'ligueront',
    'ligueuses',
    'liliacées',
    'limailles',
    'limandais',
    'limandait',
    'limandant',
    'limandées',
    'limandent',
    'limandera',
    'limandiez',
    'limandons',
    'limassent',
    'limassiez',
    'limbaires',
    'limericks',
    'limerions',
    'limettier',
    'limicoles',
    'liminaire',
    'liminales',
    'limitable',
    'limitâmes',
    'limitasse',
    'limitâtes',
    'limitatif',
    'limiterai',
    'limiteras',
    'limiterez',
    'limiteurs',
    'limitions',
    'limogeage',
    'limogeais',
    'limogeait',
    'limogeant',
    'limogeons',
    'limogerai',
    'limogeras',
    'limogerez',
    'limogions',
    'limonades',
    'limonages',
    'limonaire',
    'limonâmes',
    'limonasse',
    'limonâtes',
    'limonènes',
    'limonerai',
    'limoneras',
    'limonerez',
    'limoneuse',
    'limonière',
    'limoniers',
    'limonions',
    'limonites',
    'limoselle',
    'limousina',
    'limousine',
    'limousiné',
    'limousins',
    'limpidité',
    'linéaires',
    'linéament',
    'linéarité',
    'lingeâmes',
    'lingeasse',
    'lingeâtes',
    'lingerais',
    'lingerait',
    'lingèrent',
    'lingeries',
    'lingeriez',
    'lingerons',
    'lingeront',
    'linguales',
    'linguette',
    'linguiste',
    'liniments',
    'linnéenne',
    'linoléums',
    'linotypes',
    'linotypie',
    'lionceaux',
    'lipolyses',
    'lipophile',
    'lipotrope',
    'liquation',
    'liquéfiai',
    'liquéfias',
    'liquéfiât',
    'liquéfiée',
    'liquéfier',
    'liquéfies',
    'liquéfiés',
    'liquéfiez',
    'liquettes',
    'liquidais',
    'liquidait',
    'liquidant',
    'liquidées',
    'liquident',
    'liquidera',
    'liquidien',
    'liquidiez',
    'liquidité',
    'liquidons',
    'liquoreux',
    'lisérages',
    'liserâmes',
    'lisérâmes',
    'liserasse',
    'lisérasse',
    'liserâtes',
    'lisérâtes',
    'lisérerai',
    'lisèrerai',
    'liséreras',
    'lisèreras',
    'lisérerez',
    'lisèrerez',
    'liserions',
    'lisérions',
    'lissaient',
    'lissantes',
    'lissasses',
    'lisserais',
    'lisserait',
    'lissèrent',
    'lisseriez',
    'lisserons',
    'lisseront',
    'lisseuses',
    'listaient',
    'listasses',
    'listerais',
    'listerait',
    'listèrent',
    'listeriez',
    'listerons',
    'listeront',
    'litassent',
    'litassiez',
    'literions',
    'litharges',
    'lithiases',
    'lithinées',
    'lithiques',
    'lithodome',
    'lithogène',
    'litigieux',
    'littérale',
    'littéraux',
    'littorale',
    'littoraux',
    'littorine',
    'lituanien',
    'liturgies',
    'lividités',
    'livrables',
    'livraient',
    'livraison',
    'livrasses',
    'livrerais',
    'livrerait',
    'livrèrent',
    'livreriez',
    'livrerons',
    'livreront',
    'livresque',
    'livreuses',
    'lobassent',
    'lobassiez',
    'loberions',
    'lobotomie',
    'lobulaire',
    'lobuleuse',
    'localisai',
    'localisas',
    'localisât',
    'localisée',
    'localiser',
    'localises',
    'localisés',
    'localisez',
    'localités',
    'locataire',
    'locations',
    'locatives',
    'lochaient',
    'lochasses',
    'locherais',
    'locherait',
    'lochèrent',
    'locheriez',
    'locherons',
    'locheront',
    'lock-outa',
    'lock-oute',
    'lock-outé',
    'locomotif',
    'loculaire',
    'loculeuse',
    'locuteurs',
    'locutions',
    'locutrice',
    'lofassent',
    'lofassiez',
    'loferions',
    'logeables',
    'logeaient',
    'logeasses',
    'logements',
    'logerions',
    'logiciels',
    'logiciens',
    'logicisme',
    'logopédie',
    'logorrhée',
    'loi-cadre',
    'lointaine',
    'lointains',
    'loisibles',
    'lombaires',
    'lombalgie',
    'lombardes',
    'londonien',
    'longeâmes',
    'longeasse',
    'longeâtes',
    'longerais',
    'longerait',
    'longèrent',
    'longeriez',
    'longerons',
    'longeront',
    'longévité',
    'longitude',
    'longottes',
    'longrines',
    'longtemps',
    'longuette',
    'longueurs',
    'loquacité',
    'loquaient',
    'loquasses',
    'loquerais',
    'loquerait',
    'loquèrent',
    'loqueriez',
    'loquerons',
    'loqueront',
    'loqueteau',
    'loqueteux',
    'lorgnâmes',
    'lorgnasse',
    'lorgnâtes',
    'lorgnerai',
    'lorgneras',
    'lorgnerez',
    'lorgnette',
    'lorgnions',
    'loricaire',
    'lorraines',
    'losangées',
    'lotionnai',
    'lotionnas',
    'lotionnât',
    'lotionnée',
    'lotionner',
    'lotionnes',
    'lotionnés',
    'lotionnez',
    'lotirions',
    'lotissais',
    'lotissait',
    'lotissant',
    'lotissent',
    'lotisseur',
    'lotissiez',
    'lotissons',
    'louangeai',
    'louangeas',
    'louangeât',
    'louangées',
    'louangent',
    'louangera',
    'louangeur',
    'louangiez',
    'louassent',
    'louassiez',
    'louchâmes',
    'louchasse',
    'louchâtes',
    'loucherai',
    'loucheras',
    'loucherez',
    'loucherie',
    'loucheurs',
    'loucheuse',
    'louchîmes',
    'louchions',
    'louchirai',
    'louchiras',
    'louchirez',
    'louchisse',
    'louchîtes',
    'louerions',
    'loufaient',
    'loufasses',
    'louferais',
    'louferait',
    'loufèrent',
    'louferiez',
    'louferons',
    'louferont',
    'loufoques',
    'loupaient',
    'loupasses',
    'louperais',
    'louperait',
    'loupèrent',
    'louperiez',
    'louperons',
    'louperont',
    'loupiotes',
    'louraient',
    'lourasses',
    'lourdâmes',
    'lourdasse',
    'lourdâtes',
    'lourdaude',
    'lourdauds',
    'lourderai',
    'lourderas',
    'lourderez',
    'lourdeurs',
    'lourdions',
    'lourerais',
    'lourerait',
    'lourèrent',
    'loureriez',
    'lourerons',
    'loureront',
    'louvaient',
    'louvasses',
    'louverais',
    'louverait',
    'louvèrent',
    'louveriez',
    'louverons',
    'louveront',
    'louvetais',
    'louvetait',
    'louvetant',
    'louveteau',
    'louvetées',
    'louvetier',
    'louvetiez',
    'louvetons',
    'louvettes',
    'louvoient',
    'louvoiera',
    'louvoyais',
    'louvoyait',
    'louvoyant',
    'louvoyiez',
    'louvoyons',
    'lovassent',
    'lovassiez',
    'lovelaces',
    'loverions',
    'loyalisme',
    'loyaliste',
    'lubricité',
    'lubrifiai',
    'lubrifias',
    'lubrifiât',
    'lubrifiée',
    'lubrifier',
    'lubrifies',
    'lubrifiés',
    'lubrifiez',
    'lubriques',
    'lucidités',
    'lucifuges',
    'lucimètre',
    'lucratifs',
    'lucrative',
    'ludiciels',
    'lugeaient',
    'lugeasses',
    'lugerions',
    'luiraient',
    'luisaient',
    'luisances',
    'luisantes',
    'luisirent',
    'luisisses',
    'lumignons',
    'luminaire',
    'lumineuse',
    'luministe',
    'lumitypes',
    'lunaisons',
    'lunatique',
    'lunchâmes',
    'lunchasse',
    'lunchâtes',
    'luncherai',
    'luncheras',
    'luncherez',
    'lunchions',
    'lunetière',
    'lunetiers',
    'lupulines',
    'lustrages',
    'lustrales',
    'lustrâmes',
    'lustrante',
    'lustrants',
    'lustrasse',
    'lustrâtes',
    'lustrerai',
    'lustreras',
    'lustrerez',
    'lustrerie',
    'lustrines',
    'lustrions',
    'lutassent',
    'lutassiez',
    'lutéciens',
    'lutéciums',
    'luterions',
    'luthérien',
    'lutheries',
    'luthistes',
    'lutinâmes',
    'lutinasse',
    'lutinâtes',
    'lutinerai',
    'lutineras',
    'lutinerez',
    'lutinions',
    'luttaient',
    'luttasses',
    'lutterais',
    'lutterait',
    'luttèrent',
    'lutteriez',
    'lutterons',
    'lutteront',
    'lutteuses',
    'luxassent',
    'luxassiez',
    'luxations',
    'luxerions',
    'luxmètres',
    'luxueuses',
    'luxuriant',
    'luxurieux',
    'lycéennes',
    'lycopodes',
    'lydiennes',
    'lymphoïde',
    'lynchages',
    'lynchâmes',
    'lynchasse',
    'lynchâtes',
    'lyncherai',
    'lyncheras',
    'lyncherez',
    'lyncheurs',
    'lyncheuse',
    'lynchions',
    'lyonnaise',
    'lyophiles',
    'lysassent',
    'lysassiez',
    'lysergide',
    'lyserions',
    'lysimaque',
    'lysozymes',
    'macaronis',
    'macassars',
    'macchabée',
    'macédoine',
    'macérâmes',
    'macérasse',
    'macérâtes',
    'macérerai',
    'macéreras',
    'macérerez',
    'macérions',
    'mâchaient',
    'mâchasses',
    'mâchefers',
    'mâchement',
    'mâcherais',
    'mâcherait',
    'mâchèrent',
    'mâcheriez',
    'mâcherons',
    'mâcheront',
    'machettes',
    'mâcheuses',
    'machiavel',
    'machicota',
    'machicote',
    'machicoté',
    'machinais',
    'machinait',
    'machinaît',
    'machinale',
    'machinaux',
    'machinées',
    'machinent',
    'machinera',
    'machismes',
    'machmètre',
    'mâchoires',
    'mâchonnai',
    'mâchonnas',
    'mâchonnât',
    'mâchonnée',
    'mâchonner',
    'mâchonnes',
    'mâchonnés',
    'mâchonnez',
    'mâchurais',
    'mâchurait',
    'mâchurant',
    'mâchurées',
    'mâchurent',
    'mâchurera',
    'mâchuriez',
    'mâchurons',
    'maclaient',
    'maclasses',
    'maclerais',
    'maclerait',
    'maclèrent',
    'macleriez',
    'maclerons',
    'macleront',
    'maçonnais',
    'maçonnait',
    'maçonnant',
    'maçonnées',
    'maçonnent',
    'maçonnera',
    'maçonniez',
    'maçonnons',
    'macquâmes',
    'macquasse',
    'macquâtes',
    'macquerai',
    'macqueras',
    'macquerez',
    'macquions',
    'macreuses',
    'macrocyte',
    'macropode',
    'macroures',
    'maculages',
    'maculâmes',
    'maculasse',
    'maculâtes',
    'maculerai',
    'maculeras',
    'maculerez',
    'maculions',
    'madapolam',
    'madécasse',
    'madéfiais',
    'madéfiait',
    'madéfiant',
    'madéfiées',
    'madéfient',
    'madéfiera',
    'madéfiiez',
    'madéfions',
    'madeleine',
    'madérisai',
    'madérisas',
    'madérisât',
    'madérisée',
    'madériser',
    'madérises',
    'madérisés',
    'madérisez',
    'madragues',
    'madrépore',
    'madrigaux',
    'madrilène',
    'maelström',
    'magasinai',
    'magasinas',
    'magasinât',
    'magasinée',
    'magasiner',
    'magasines',
    'magasinés',
    'magasinez',
    'magazines',
    'maghrébin',
    'magiciens',
    'magistère',
    'magisters',
    'magistral',
    'magistrat',
    'magnaient',
    'magnanier',
    'magnanime',
    'magnasses',
    'magnerais',
    'magnerait',
    'magnèrent',
    'magneriez',
    'magnerons',
    'magneront',
    'magnésien',
    'magnésies',
    'magnésite',
    'magnésium',
    'magnétisa',
    'magnétise',
    'magnétisé',
    'magnétite',
    'magnétron',
    'magnifiai',
    'magnifias',
    'magnifiât',
    'magnifiée',
    'magnifier',
    'magnifies',
    'magnifiés',
    'magnifiez',
    'magnitude',
    'magnolias',
    'magouille',
    'magouillé',
    'maharajah',
    'maharanis',
    'mahdistes',
    'mahométan',
    'mahousses',
    'mahrattes',
    'maigrelet',
    'maigreurs',
    'maigrîmes',
    'maigriots',
    'maigrirai',
    'maigriras',
    'maigrirez',
    'maigrisse',
    'maigrîtes',
    'maillâmes',
    'maillasse',
    'maillâtes',
    'maillerai',
    'mailleras',
    'maillerez',
    'mailleton',
    'maillions',
    'mailloche',
    'maillotin',
    'maillures',
    'mainlevée',
    'mainmises',
    'mainmorte',
    'maintenez',
    'maintenir',
    'maintenue',
    'maintenus',
    'maintiens',
    'maintient',
    'maïseries',
    'maisonnée',
    'maîtresse',
    'maîtrisai',
    'maîtrisas',
    'maîtrisât',
    'maîtrisée',
    'maîtriser',
    'maîtrises',
    'maîtrisés',
    'maîtrisez',
    'majolique',
    'majorâmes',
    'majorante',
    'majorants',
    'majorasse',
    'majorâtes',
    'majordome',
    'majorerai',
    'majoreras',
    'majorerez',
    'majorette',
    'majorions',
    'majorités',
    'majuscule',
    'makimonos',
    'malachite',
    'maladives',
    'maladroit',
    'malaisées',
    'malandres',
    'malandrin',
    'malappris',
    'malavisée',
    'malavisés',
    'malaxages',
    'malaxâmes',
    'malaxasse',
    'malaxâtes',
    'malaxerai',
    'malaxeras',
    'malaxerez',
    'malaxeurs',
    'malaxions',
    'malchance',
    'maldonnes',
    'maléfices',
    'maléfique',
    'malfaçons',
    'malfaites',
    'malfamées',
    'malfasses',
    'malferais',
    'malferait',
    'malferiez',
    'malferons',
    'malferont',
    'malfirent',
    'malfisses',
    'malgaches',
    'malhabile',
    'malicieux',
    'maliennes',
    'malignité',
    'malingres',
    'mal-jugés',
    'malléable',
    'malléoles',
    'mallettes',
    'malmenais',
    'malmenait',
    'malmenant',
    'malmenées',
    'malmènent',
    'malmènera',
    'malmeniez',
    'malmenons',
    'malotrues',
    'malpighie',
    'malpolies',
    'malpropre',
    'malsaines',
    'malséante',
    'malséants',
    'maltaient',
    'maltasses',
    'malterais',
    'malterait',
    'maltèrent',
    'malteries',
    'malteriez',
    'malterons',
    'malteront',
    'maltraita',
    'maltraite',
    'maltraité',
    'malvenues',
    'malvoyant',
    'mamelonna',
    'mamelonne',
    'mamelonné',
    'mamelouke',
    'mamelukes',
    'mammaires',
    'mammalien',
    'mammifère',
    'mammouths',
    'manageais',
    'manageait',
    'manageant',
    'manageons',
    'managerai',
    'manageras',
    'managerez',
    'managions',
    'mancelles',
    'mancheron',
    'manchette',
    'manchonna',
    'manchonne',
    'manchonné',
    'manchotes',
    'mandaient',
    'mandantes',
    'mandarine',
    'mandarins',
    'mandasses',
    'mandatais',
    'mandatait',
    'mandatant',
    'mandatées',
    'mandatent',
    'mandatera',
    'mandatiez',
    'mandatons',
    'mandchoue',
    'mandchous',
    'mandement',
    'manderais',
    'manderait',
    'mandèrent',
    'manderiez',
    'manderons',
    'manderont',
    'mandibule',
    'mandoline',
    'mandorles',
    'mandrills',
    'manégeais',
    'manégeait',
    'manégeant',
    'manégeons',
    'manégerai',
    'manégeras',
    'manégerez',
    'manégions',
    'manganate',
    'manganèse',
    'manganeux',
    'manganite',
    'mangeable',
    'mangeâmes',
    'mangeasse',
    'mangeâtes',
    'mangeoire',
    'mangeotta',
    'mangeotte',
    'mangeotté',
    'mangerais',
    'mangerait',
    'mangèrent',
    'mangeriez',
    'mangerons',
    'mangeront',
    'mangeures',
    'mangeuses',
    'mangliers',
    'mangouste',
    'mangroves',
    'manguiers',
    'maniables',
    'maniaient',
    'maniaques',
    'maniasses',
    'manichéen',
    'manicorde',
    'maniement',
    'manierais',
    'maniérais',
    'manierait',
    'maniérait',
    'maniérant',
    'maniérées',
    'manièrent',
    'maniérera',
    'manieriez',
    'maniériez',
    'manierons',
    'maniérons',
    'manieront',
    'manieuses',
    'manifesta',
    'manifeste',
    'manifesté',
    'manifolds',
    'manigança',
    'manigance',
    'manigancé',
    'manillons',
    'manipulai',
    'manipulas',
    'manipulât',
    'manipulée',
    'manipuler',
    'manipules',
    'manipulés',
    'manipulez',
    'manivelle',
    'mannequin',
    'manoeuvra',
    'manoeuvre',
    'manoeuvré',
    'manomètre',
    'manoquais',
    'manoquait',
    'manoquant',
    'manoquées',
    'manoquent',
    'manoquera',
    'manoquiez',
    'manoquons',
    'manouches',
    'manquâmes',
    'manquante',
    'manquants',
    'manquasse',
    'manquâtes',
    'manquerai',
    'manqueras',
    'manquerez',
    'manquions',
    'mansardai',
    'mansardas',
    'mansardât',
    'mansardée',
    'mansarder',
    'mansardes',
    'mansardés',
    'mansardez',
    'mantelées',
    'mantelets',
    'mantelure',
    'mantilles',
    'mantiques',
    'mantisses',
    'manubrium',
    'manucurai',
    'manucuras',
    'manucurât',
    'manucurée',
    'manucurer',
    'manucures',
    'manucurés',
    'manucurez',
    'manuéline',
    'manuélins',
    'manuelles',
    'manuscrit',
    'manuterge',
    'maquaient',
    'maquasses',
    'maquerais',
    'maquerait',
    'maquereau',
    'maquèrent',
    'maqueriez',
    'maquerons',
    'maqueront',
    'maquettes',
    'maquignon',
    'maquillai',
    'maquillas',
    'maquillât',
    'maquillée',
    'maquiller',
    'maquilles',
    'maquillés',
    'maquillez',
    'maquisard',
    'marabouts',
    'maraîcher',
    'maraîchin',
    'marasques',
    'marasquin',
    'marathons',
    'maraudage',
    'maraudais',
    'maraudait',
    'maraudant',
    'maraudent',
    'maraudera',
    'maraudeur',
    'maraudiez',
    'maraudons',
    'maravédis',
    'marbrâmes',
    'marbrasse',
    'marbrâtes',
    'marbrerai',
    'marbreras',
    'marbrerez',
    'marbrerie',
    'marbreurs',
    'marbreuse',
    'marbrière',
    'marbriers',
    'marbrions',
    'marbrures',
    'marcassin',
    'marchâmes',
    'marchanda',
    'marchande',
    'marchandé',
    'marchands',
    'marchante',
    'marchants',
    'marchasse',
    'marchâtes',
    'marchéage',
    'marcherai',
    'marcheras',
    'marcherez',
    'marcheurs',
    'marcheuse',
    'marchions',
    'marcottai',
    'marcottas',
    'marcottât',
    'marcottée',
    'marcotter',
    'marcottes',
    'marcottés',
    'marcottez',
    'marécages',
    'maréchale',
    'maréchaux',
    'mareyages',
    'mareyeurs',
    'mareyeuse',
    'margaille',
    'margarine',
    'margeâmes',
    'margeasse',
    'margeâtes',
    'margelles',
    'margerais',
    'margerait',
    'margèrent',
    'margeriez',
    'margerons',
    'margeront',
    'margeuses',
    'marginais',
    'marginait',
    'marginale',
    'marginant',
    'marginaux',
    'marginées',
    'marginent',
    'marginera',
    'marginiez',
    'marginons',
    'margotais',
    'margotait',
    'margotant',
    'margotent',
    'margotera',
    'margotiez',
    'margotins',
    'margotons',
    'margottai',
    'margottas',
    'margottât',
    'margotter',
    'margottes',
    'margottez',
    'margoulin',
    'margraves',
    'mariables',
    'mariaient',
    'mariantes',
    'mariasses',
    'marierais',
    'marierait',
    'marièrent',
    'marieriez',
    'marierons',
    'marieront',
    'marieuses',
    'marihuana',
    'marijuana',
    'marinades',
    'marinages',
    'marinâmes',
    'marinasse',
    'marinâtes',
    'marinerai',
    'marineras',
    'marinerez',
    'marinière',
    'mariniers',
    'marinions',
    'marinisme',
    'mariolles',
    'maritales',
    'maritimes',
    'maritorne',
    'marivauda',
    'marivaude',
    'marivaudé',
    'marketing',
    'marmaille',
    'marmelade',
    'marmitage',
    'marmitais',
    'marmitait',
    'marmitant',
    'marmitées',
    'marmitent',
    'marmitera',
    'marmitiez',
    'marmitons',
    'marmonnai',
    'marmonnas',
    'marmonnât',
    'marmonnée',
    'marmonner',
    'marmonnes',
    'marmonnés',
    'marmonnez',
    'marmoréen',
    'marmorisa',
    'marmorise',
    'marmorisé',
    'marmottai',
    'marmottas',
    'marmottât',
    'marmottée',
    'marmotter',
    'marmottes',
    'marmottés',
    'marmottez',
    'marmouset',
    'marnaient',
    'marnasses',
    'marnerais',
    'marnerait',
    'marnèrent',
    'marneriez',
    'marnerons',
    'marneront',
    'marneuses',
    'marnières',
    'marocaine',
    'marocains',
    'maroilles',
    'maronites',
    'maronnais',
    'maronnait',
    'maronnant',
    'maronnent',
    'maronnera',
    'maronniez',
    'maronnons',
    'maroquina',
    'maroquine',
    'maroquiné',
    'maroquins',
    'marotique',
    'marouette',
    'marouflai',
    'marouflas',
    'marouflât',
    'marouflée',
    'maroufler',
    'maroufles',
    'marouflés',
    'marouflez',
    'marquages',
    'marquâmes',
    'marquante',
    'marquants',
    'marquasse',
    'marquâtes',
    'marquerai',
    'marqueras',
    'marquerez',
    'marquetai',
    'marquetas',
    'marquetât',
    'marquetée',
    'marqueter',
    'marquetés',
    'marquetez',
    'marquette',
    'marqueurs',
    'marqueuse',
    'marquions',
    'marquisat',
    'marquises',
    'marquoirs',
    'marraient',
    'marraines',
    'marrantes',
    'marrasses',
    'marrerais',
    'marrerait',
    'marrèrent',
    'marreriez',
    'marrerons',
    'marreront',
    'marronnai',
    'marronnas',
    'marronnât',
    'marronner',
    'marronnes',
    'marronnez',
    'marsaults',
    'marsouina',
    'marsouine',
    'marsouiné',
    'marsouins',
    'marsupial',
    'martagons',
    'martelage',
    'martelais',
    'martelait',
    'martelant',
    'martelées',
    'martèlent',
    'martèlera',
    'marteleur',
    'marteliez',
    'martelons',
    'martiales',
    'martienne',
    'martinets',
    'martyrisa',
    'martyrise',
    'martyrisé',
    'martyrium',
    'marxienne',
    'marxisais',
    'marxisait',
    'marxisant',
    'marxisées',
    'marxisent',
    'marxisera',
    'marxisiez',
    'marxismes',
    'marxisons',
    'marxistes',
    'marylands',
    'mascarade',
    'mascarets',
    'mascarons',
    'mascottes',
    'masculine',
    'masculins',
    'masquages',
    'masquâmes',
    'masquante',
    'masquants',
    'masquasse',
    'masquâtes',
    'masquerai',
    'masqueras',
    'masquerez',
    'masquions',
    'massacrai',
    'massacras',
    'massacrât',
    'massacrée',
    'massacrer',
    'massacres',
    'massacrés',
    'massacrez',
    'massaient',
    'massasses',
    'massepain',
    'masserais',
    'masserait',
    'massèrent',
    'masseriez',
    'masserons',
    'masseront',
    'masséters',
    'massettes',
    'masseuses',
    'massicota',
    'massicote',
    'massicoté',
    'massicots',
    'massières',
    'massifiai',
    'massifias',
    'massifiât',
    'massifiée',
    'massifier',
    'massifies',
    'massifiés',
    'massifiez',
    'massiques',
    'massorahs',
    'masticage',
    'mastiquai',
    'mastiquas',
    'mastiquât',
    'mastiquée',
    'mastiquer',
    'mastiques',
    'mastiqués',
    'mastiquez',
    'mastoïdes',
    'masturbai',
    'masturbas',
    'masturbât',
    'masturbée',
    'masturber',
    'masturbes',
    'masturbés',
    'masturbez',
    'masuriums',
    'matamores',
    'matassent',
    'mâtassent',
    'matassiez',
    'mâtassiez',
    'matchâmes',
    'matchasse',
    'matchâtes',
    'matcherai',
    'matcheras',
    'matcherez',
    'matchions',
    'matefaims',
    'matelassa',
    'matelasse',
    'matelassé',
    'matelotes',
    'mâtereaux',
    'matériaux',
    'matériels',
    'materions',
    'mâterions',
    'maternage',
    'maternais',
    'maternait',
    'maternant',
    'maternées',
    'maternels',
    'maternent',
    'maternera',
    'materniez',
    'maternisa',
    'maternise',
    'maternisé',
    'maternité',
    'maternons',
    'matheuses',
    'matinales',
    'mâtinâmes',
    'mâtinasse',
    'mâtinâtes',
    'mâtinerai',
    'mâtineras',
    'mâtinerez',
    'matineuse',
    'matinière',
    'matiniers',
    'mâtinions',
    'matirions',
    'matissais',
    'matissait',
    'matissant',
    'matissent',
    'matissiez',
    'matissons',
    'matraquai',
    'matraquas',
    'matraquât',
    'matraquée',
    'matraquer',
    'matraques',
    'matraqués',
    'matraquez',
    'matriçais',
    'matriçait',
    'matriçant',
    'matricées',
    'matricent',
    'matricera',
    'matricide',
    'matriciez',
    'matriçons',
    'matricula',
    'matricule',
    'matriculé',
    'matthiole',
    'maturâmes',
    'maturasse',
    'maturâtes',
    'maturerai',
    'matureras',
    'maturerez',
    'maturions',
    'maturités',
    'matutinal',
    'maubèches',
    'maudirais',
    'maudirait',
    'maudirent',
    'maudiriez',
    'maudirons',
    'maudiront',
    'maudisses',
    'maudissez',
    'maugréais',
    'maugréait',
    'maugréant',
    'maugréées',
    'maugréent',
    'maugréera',
    'maugréiez',
    'maugréons',
    'maurandie',
    'maurelles',
    'mauresque',
    'mausolées',
    'maussades',
    'mauvaises',
    'mauvéines',
    'mauviette',
    'maximales',
    'maximisai',
    'maximisas',
    'maximisât',
    'maximisée',
    'maximiser',
    'maximises',
    'maximisés',
    'maximisez',
    'mazagrans',
    'mazdéisme',
    'mazoutais',
    'mazoutait',
    'mazoutant',
    'mazoutées',
    'mazoutent',
    'mazoutera',
    'mazoutiez',
    'mazoutons',
    'mea-culpa',
    'méandrine',
    'mécanique',
    'mécanisai',
    'mécanisas',
    'mécanisât',
    'mécanisée',
    'mécaniser',
    'mécanises',
    'mécanisés',
    'mécanisez',
    'mécanisme',
    'mécaniste',
    'méchaient',
    'méchantes',
    'méchasses',
    'mécherais',
    'mécherait',
    'méchèrent',
    'mécheriez',
    'mécherons',
    'mécheront',
    'mécheuses',
    'mécomptai',
    'mécomptas',
    'mécomptât',
    'mécomptée',
    'mécompter',
    'mécomptes',
    'mécomptés',
    'mécomptez',
    'méconiums',
    'méconnais',
    'méconnaît',
    'méconnues',
    'mécontent',
    'mécréante',
    'mécréants',
    'médaillai',
    'médaillas',
    'médaillât',
    'médaillée',
    'médailler',
    'médailles',
    'médaillés',
    'médaillez',
    'médaillon',
    'médecines',
    'médiantes',
    'médiastin',
    'médiateur',
    'médiation',
    'médiatisa',
    'médiatise',
    'médiatisé',
    'médiators',
    'médicales',
    'médicinal',
    'médiévale',
    'médiévaux',
    'médiocres',
    'médirions',
    'médisâmes',
    'médisance',
    'médisante',
    'médisants',
    'médisasse',
    'médisâtes',
    'médiserai',
    'médiseras',
    'médiserez',
    'médisions',
    'médissent',
    'médissiez',
    'méditâmes',
    'méditante',
    'méditants',
    'méditasse',
    'méditâtes',
    'méditatif',
    'méditerai',
    'méditeras',
    'méditerez',
    'méditions',
    'médulleux',
    'médusâmes',
    'médusante',
    'médusants',
    'médusasse',
    'médusâtes',
    'méduserai',
    'méduseras',
    'méduserez',
    'médusions',
    'méfaisais',
    'méfaisait',
    'méfaisant',
    'méfaisiez',
    'méfaisons',
    'méfassent',
    'méfassiez',
    'méferions',
    'méfiaient',
    'méfiances',
    'méfiantes',
    'méfiasses',
    'méfierais',
    'méfierait',
    'méfièrent',
    'méfieriez',
    'méfierons',
    'méfieront',
    'méfissent',
    'méfissiez',
    'mégacéros',
    'mégacôlon',
    'mégacycle',
    'mégahertz',
    'mégalithe',
    'mégaphone',
    'mégaptère',
    'mégatonne',
    'mégirions',
    'mégissais',
    'mégissait',
    'mégissant',
    'mégissées',
    'mégissent',
    'mégissera',
    'mégissier',
    'mégissiez',
    'mégissons',
    'mégotâmes',
    'mégotasse',
    'mégotâtes',
    'mégoterai',
    'mégoteras',
    'mégoterez',
    'mégotions',
    'méhariste',
    'meilleure',
    'meilleurs',
    'méiotique',
    'méjanages',
    'méjugeais',
    'méjugeait',
    'méjugeant',
    'méjugeons',
    'méjugerai',
    'méjugeras',
    'méjugerez',
    'méjugions',
    'mélampyre',
    'mélangeai',
    'mélangeas',
    'mélangeât',
    'mélangées',
    'mélangent',
    'mélangera',
    'mélangeur',
    'mélangiez',
    'mélanines',
    'mélanique',
    'mélanisme',
    'mélanomes',
    'mélanoses',
    'mêlassent',
    'mêlassiez',
    'melchiors',
    'melchites',
    'mêlé-cass',
    'mêlerions',
    'méli-mélo',
    'mélinites',
    'mellifère',
    'melliflue',
    'mélodieux',
    'mélodique',
    'mélodiste',
    'mélodrame',
    'mélomanes',
    'mélongine',
    'melonnées',
    'mélophage',
    'mélusines',
    'membranes',
    'membrures',
    'mémorable',
    'mémoriaux',
    'mémoriels',
    'mémorisai',
    'mémorisas',
    'mémorisât',
    'mémorisée',
    'mémoriser',
    'mémorises',
    'mémorisés',
    'mémorisez',
    'menaçâmes',
    'menaçante',
    'menaçants',
    'menaçasse',
    'menaçâtes',
    'menacerai',
    'menaceras',
    'menacerez',
    'menacions',
    'ménageais',
    'ménageait',
    'ménageant',
    'ménageons',
    'ménagerai',
    'ménageras',
    'ménagères',
    'ménagerez',
    'ménagerie',
    'ménagions',
    'menassent',
    'menassiez',
    'menchevik',
    'mendélien',
    'mendiâmes',
    'mendiante',
    'mendiants',
    'mendiasse',
    'mendiâtes',
    'mendicité',
    'mendierai',
    'mendieras',
    'mendierez',
    'mendigota',
    'mendigote',
    'mendigoté',
    'mendigots',
    'mendiions',
    'mènerions',
    'ménestrel',
    'ménétrier',
    'méningées',
    'méningite',
    'ménisques',
    'mennonite',
    'ménologes',
    'ménopause',
    'mensonger',
    'mensonges',
    'menstruel',
    'menstrues',
    'mensuelle',
    'mensurais',
    'mensurait',
    'mensurant',
    'mensurées',
    'mensurent',
    'mensurera',
    'mensuriez',
    'mensurons',
    'mentaient',
    'mentalité',
    'menteries',
    'menteuses',
    'mentholée',
    'mentholés',
    'mentionna',
    'mentionne',
    'mentionné',
    'mentirais',
    'mentirait',
    'mentirent',
    'mentiriez',
    'mentirons',
    'mentiront',
    'mentismes',
    'mentisses',
    'mentonnet',
    'menuisais',
    'menuisait',
    'menuisant',
    'menuisées',
    'menuisent',
    'menuisera',
    'menuisier',
    'menuisiez',
    'menuisons',
    'ményanthe',
    'méprenais',
    'méprenait',
    'méprenant',
    'méprendra',
    'méprendre',
    'mépreniez',
    'méprennes',
    'méprenons',
    'méprirent',
    'méprisais',
    'méprisait',
    'méprisant',
    'méprisées',
    'méprisent',
    'méprisera',
    'méprisiez',
    'méprisons',
    'méprisses',
    'mercantis',
    'mercaptan',
    'merceries',
    'mercerisa',
    'mercerise',
    'mercerisé',
    'mercières',
    'mercredis',
    'mercureux',
    'mercuriel',
    'merdeuses',
    'merdiques',
    'merdoient',
    'merdoiera',
    'merdoyais',
    'merdoyait',
    'merdoyant',
    'merdoyiez',
    'merdoyons',
    'méridiens',
    'meringuai',
    'meringuas',
    'meringuât',
    'meringuée',
    'meringuer',
    'meringues',
    'meringués',
    'meringuez',
    'merisiers',
    'méristème',
    'méritâmes',
    'méritante',
    'méritants',
    'méritasse',
    'méritâtes',
    'mériterai',
    'mériteras',
    'mériterez',
    'méritions',
    'méritoire',
    'merlettes',
    'merluches',
    'merveille',
    'mérycisme',
    'mésalliai',
    'mésallias',
    'mésalliât',
    'mésalliée',
    'mésallier',
    'mésallies',
    'mésalliés',
    'mésalliez',
    'mescaline',
    'mésentère',
    'mésestima',
    'mésestime',
    'mésestimé',
    'mésocarpe',
    'mésoderme',
    'mésopause',
    'mesquines',
    'messagère',
    'messagers',
    'messeyait',
    'messiéent',
    'messieurs',
    'mestrance',
    'mesurable',
    'mesurages',
    'mesurâmes',
    'mesurasse',
    'mesurâtes',
    'mesurerai',
    'mesureras',
    'mesurerez',
    'mesureurs',
    'mesurions',
    'mésusâmes',
    'mésusasse',
    'mésusâtes',
    'mésuserai',
    'mésuseras',
    'mésuserez',
    'mésusions',
    'métacarpe',
    'métairies',
    'métallisa',
    'métallise',
    'métallisé',
    'métaphase',
    'métaphore',
    'métaphyse',
    'métastase',
    'métatarse',
    'métayages',
    'métayères',
    'météorisa',
    'météorise',
    'météorisé',
    'météorite',
    'méthanier',
    'méthylène',
    'métissage',
    'métissais',
    'métissait',
    'métissant',
    'métissées',
    'métissent',
    'métissera',
    'métissiez',
    'métissons',
    'métonymie',
    'métraient',
    'métrasses',
    'métrerais',
    'métrerait',
    'métrèrent',
    'métreriez',
    'métrerons',
    'métreront',
    'métreuses',
    'métricien',
    'métriques',
    'métronome',
    'métropole',
    'mettables',
    'mettaient',
    'metteuses',
    'mettrions',
    'meublâmes',
    'meublasse',
    'meublâtes',
    'meublerai',
    'meubleras',
    'meublerez',
    'meublions',
    'meuglâmes',
    'meuglante',
    'meuglants',
    'meuglasse',
    'meuglâtes',
    'meuglerai',
    'meugleras',
    'meuglerez',
    'meuglions',
    'meulaient',
    'meulasses',
    'meulerais',
    'meulerait',
    'meulèrent',
    'meuleriez',
    'meulerons',
    'meuleront',
    'meulettes',
    'meulières',
    'meuneries',
    'meunières',
    'meurettes',
    'meurtrier',
    'meurtries',
    'meurtrira',
    'mévendais',
    'mévendait',
    'mévendant',
    'mévendent',
    'mévendiez',
    'mévendons',
    'mévendrai',
    'mévendras',
    'mévendrez',
    'mévendues',
    'mexicaine',
    'mexicains',
    'mezzanine',
    'miam-miam',
    'miaulâmes',
    'miaulante',
    'miaulants',
    'miaulasse',
    'miaulâtes',
    'miaulerai',
    'miauleras',
    'miaulerez',
    'miauleurs',
    'miauleuse',
    'miaulions',
    'mi-carême',
    'micheline',
    'mi-chemin',
    'michetons',
    'mi-closes',
    'microbien',
    'microfilm',
    'midinette',
    'mielleuse',
    'mièvrerie',
    'migmatite',
    'mignardai',
    'mignardas',
    'mignardât',
    'mignardée',
    'mignarder',
    'mignardes',
    'mignardés',
    'mignardez',
    'mignonnes',
    'mignonnet',
    'mignotais',
    'mignotait',
    'mignotant',
    'mignotées',
    'mignotent',
    'mignotera',
    'mignotiez',
    'mignotons',
    'migraient',
    'migraines',
    'migrasses',
    'migrateur',
    'migration',
    'migrerais',
    'migrerait',
    'migrèrent',
    'migreriez',
    'migrerons',
    'migreront',
    'mijaurées',
    'mijotâmes',
    'mijotante',
    'mijotants',
    'mijotasse',
    'mijotâtes',
    'mijoterai',
    'mijoteras',
    'mijoterez',
    'mijotions',
    'milanaise',
    'mildiousé',
    'miliaires',
    'miliciens',
    'militaire',
    'militâmes',
    'militante',
    'militants',
    'militasse',
    'militâtes',
    'militerai',
    'militeras',
    'militerez',
    'militions',
    'milk-bars',
    'millasses',
    'millénium',
    'millépore',
    'millésima',
    'millésime',
    'millésimé',
    'milliaire',
    'milliards',
    'milliasse',
    'millibars',
    'millièmes',
    'millivolt',
    'mimassent',
    'mimassiez',
    'mimerions',
    'mimétique',
    'mimétisme',
    'mimodrame',
    'mimolette',
    'mimologie',
    'minahouet',
    'minassent',
    'minassiez',
    'minaudais',
    'minaudait',
    'minaudant',
    'minaudées',
    'minaudent',
    'minaudera',
    'minaudier',
    'minaudiez',
    'minaudons',
    'mincirais',
    'mincirait',
    'mincirent',
    'minciriez',
    'mincirons',
    'minciront',
    'mincisses',
    'mincissez',
    'minérales',
    'minerions',
    'minervals',
    'miniatura',
    'miniature',
    'miniaturé',
    'mini-jupe',
    'minimales',
    'minimisai',
    'minimisas',
    'minimisât',
    'minimisée',
    'minimiser',
    'minimises',
    'minimisés',
    'minimisez',
    'ministère',
    'ministres',
    'minoennes',
    'minorâmes',
    'minorasse',
    'minorâtes',
    'minoratif',
    'minorerai',
    'minoreras',
    'minorerez',
    'minorions',
    'minorités',
    'minoterie',
    'minotière',
    'minotiers',
    'minuscule',
    'minutages',
    'minutaire',
    'minutâmes',
    'minutasse',
    'minutâtes',
    'minuterai',
    'minuteras',
    'minuterez',
    'minuterie',
    'minuteurs',
    'minutiers',
    'minutieux',
    'minutions',
    'mi-partie',
    'mi-partis',
    'mirabelle',
    'mirabilis',
    'miraculée',
    'miraculés',
    'mirassent',
    'mirassiez',
    'mirerions',
    'mirifique',
    'mirliflor',
    'mirlitons',
    'mirmidons',
    'mirmillon',
    'miroitais',
    'miroitait',
    'miroitant',
    'miroitées',
    'miroitent',
    'miroitera',
    'miroitier',
    'miroitiez',
    'miroitons',
    'mirontons',
    'misassent',
    'misassiez',
    'miscibles',
    'misérable',
    'misérérés',
    'miséreuse',
    'miserions',
    'misogynes',
    'misogynie',
    'mispickel',
    'missilier',
    'mistelles',
    'mistigris',
    'mistonnes',
    'mistoufle',
    'mitassent',
    'mitassiez',
    'miterions',
    'mitigeais',
    'mitigeait',
    'mitigeant',
    'mitigeons',
    'mitigerai',
    'mitigeras',
    'mitigerez',
    'mitigeurs',
    'mitigions',
    'mitonnais',
    'mitonnait',
    'mitonnant',
    'mitonnées',
    'mitonnent',
    'mitonnera',
    'mitonniez',
    'mitonnons',
    'mitoyenne',
    'mitrailla',
    'mitraille',
    'mitraillé',
    'mixassent',
    'mixassiez',
    'mixerions',
    'mixtionna',
    'mixtionne',
    'mixtionné',
    'mobilière',
    'mobiliers',
    'mobilisai',
    'mobilisas',
    'mobilisât',
    'mobilisée',
    'mobiliser',
    'mobilises',
    'mobilisés',
    'mobilisez',
    'mobilités',
    'mobylette',
    'mocassins',
    'mochardes',
    'modalités',
    'modelages',
    'modelâmes',
    'modelante',
    'modelants',
    'modelasse',
    'modelâtes',
    'modèlerai',
    'modèleras',
    'modèlerez',
    'modeleurs',
    'modeleuse',
    'modelions',
    'modélisai',
    'modélisas',
    'modélisée',
    'modéliser',
    'modélises',
    'modélisés',
    'modélisez',
    'modéliste',
    'modérâmes',
    'modérasse',
    'modérâtes',
    'modérerai',
    'modéreras',
    'modérerez',
    'modérions',
    'modernisa',
    'modernise',
    'modernisé',
    'modernité',
    'modesties',
    'modicités',
    'modifiais',
    'modifiait',
    'modifiant',
    'modifiées',
    'modifient',
    'modifiera',
    'modifiiez',
    'modifions',
    'modillons',
    'modulaire',
    'modulâmes',
    'modulante',
    'modulants',
    'modulasse',
    'modulâtes',
    'modulerai',
    'moduleras',
    'modulerez',
    'modulions',
    'moelleuse',
    'moineries',
    'moinillon',
    'moiraient',
    'moirasses',
    'moirerais',
    'moirerait',
    'moirèrent',
    'moireriez',
    'moirerons',
    'moireront',
    'moisaient',
    'moisasses',
    'moiserais',
    'moiserait',
    'moisèrent',
    'moiseriez',
    'moiserons',
    'moiseront',
    'moisirais',
    'moisirait',
    'moisirent',
    'moisiriez',
    'moisirons',
    'moisiront',
    'moisisses',
    'moisissez',
    'moissines',
    'moissonna',
    'moissonne',
    'moissonné',
    'moitaient',
    'moitasses',
    'moiterais',
    'moiterait',
    'moitèrent',
    'moiteriez',
    'moiterons',
    'moiteront',
    'moitirais',
    'moitirait',
    'moitirent',
    'moitiriez',
    'moitirons',
    'moitiront',
    'moitisses',
    'moitissez',
    'molécules',
    'moleskine',
    'molestais',
    'molestait',
    'molestant',
    'molestées',
    'molestent',
    'molestera',
    'molestiez',
    'molestons',
    'moletages',
    'moletâmes',
    'moletasse',
    'moletâtes',
    'moletions',
    'molettent',
    'molettera',
    'moliniste',
    'mollardai',
    'mollardas',
    'mollardât',
    'mollardée',
    'mollarder',
    'mollardes',
    'mollardés',
    'mollardez',
    'mollasses',
    'mollasson',
    'mollement',
    'mollesses',
    'molletons',
    'mollirais',
    'mollirait',
    'mollirent',
    'molliriez',
    'mollirons',
    'molliront',
    'mollisses',
    'mollissez',
    'molluscum',
    'mollusque',
    'molybdène',
    'momentané',
    'momifiais',
    'momifiait',
    'momifiant',
    'momifiées',
    'momifient',
    'momifiera',
    'momifiiez',
    'momifions',
    'monacales',
    'monarchie',
    'monarques',
    'monastère',
    'monazites',
    'mondaient',
    'mondaines',
    'mondanité',
    'mondasses',
    'monderais',
    'monderait',
    'mondèrent',
    'monderiez',
    'monderons',
    'monderont',
    'mondiales',
    'monétaire',
    'monétique',
    'monétisai',
    'monétisas',
    'monétisât',
    'monétisée',
    'monétiser',
    'monétises',
    'monétisés',
    'monétisez',
    'mongolien',
    'moniteurs',
    'monitions',
    'monitoire',
    'monitorat',
    'monitrice',
    'monnaient',
    'monnaiera',
    'monnayage',
    'monnayais',
    'monnayait',
    'monnayant',
    'monnayées',
    'monnayent',
    'monnayera',
    'monnayeur',
    'monnayeux',
    'monnayiez',
    'monnayons',
    'monoacide',
    'monoblocs',
    'monocoque',
    'monocorde',
    'monocytes',
    'monoecies',
    'monogames',
    'monogamie',
    'monoïques',
    'monolithe',
    'monologua',
    'monologue',
    'monologué',
    'monomanie',
    'monomères',
    'monophasé',
    'monoplace',
    'monoplans',
    'monopoles',
    'monopsone',
    'monoptère',
    'monorails',
    'monorimes',
    'monostyle',
    'monotones',
    'monotonie',
    'monotrace',
    'monotrème',
    'Monotypes',
    'monoxyles',
    'monsignor',
    'montagnes',
    'montaient',
    'montaison',
    'montantes',
    'montasses',
    'monterais',
    'monterait',
    'montèrent',
    'monteriez',
    'monterons',
    'monteront',
    'monte-sac',
    'monteuses',
    'monticole',
    'monticule',
    'mont-joie',
    'montrable',
    'montrâmes',
    'montrasse',
    'montrâtes',
    'montrerai',
    'montreras',
    'montrerez',
    'montreurs',
    'montreuse',
    'montrions',
    'montueuse',
    'monuments',
    'moquaient',
    'moquasses',
    'moquerais',
    'moquerait',
    'moquèrent',
    'moqueries',
    'moqueriez',
    'moquerons',
    'moqueront',
    'moquettai',
    'moquettas',
    'moquettât',
    'moquettée',
    'moquetter',
    'moquettes',
    'moquettés',
    'moquettez',
    'moqueuses',
    'moraillon',
    'moralisai',
    'moralisas',
    'moralisât',
    'moralisée',
    'moraliser',
    'moralises',
    'moralisés',
    'moralisez',
    'moralisme',
    'moraliste',
    'moralités',
    'moratoire',
    'morbidité',
    'morcelais',
    'morcelait',
    'morcelant',
    'morcelées',
    'morceliez',
    'morcelles',
    'morcelons',
    'mordaches',
    'mordacité',
    'mordaient',
    'mordançai',
    'mordanças',
    'mordançât',
    'mordancée',
    'mordancer',
    'mordances',
    'mordancés',
    'mordancez',
    'mordantes',
    'mordicant',
    'mordillai',
    'mordillas',
    'mordillât',
    'mordillée',
    'mordiller',
    'mordilles',
    'mordillés',
    'mordillez',
    'mordirent',
    'mordisses',
    'mordorais',
    'mordorait',
    'mordorant',
    'mordorées',
    'mordorent',
    'mordorera',
    'mordoriez',
    'mordorons',
    'mordorure',
    'mordrions',
    'moresques',
    'morfilais',
    'morfilait',
    'morfilant',
    'morfilées',
    'morfilent',
    'morfilera',
    'morfiliez',
    'morfilons',
    'morflâmes',
    'morflasse',
    'morflâtes',
    'morflerai',
    'morfleras',
    'morflerez',
    'morflions',
    'morfondes',
    'morfondez',
    'morfondis',
    'morfondit',
    'morfondît',
    'morfondra',
    'morfondre',
    'morfondue',
    'morfondus',
    'morgeline',
    'morguâmes',
    'morguasse',
    'morguâtes',
    'morguenne',
    'morguerai',
    'morgueras',
    'morguerez',
    'morguions',
    'moribonde',
    'moribonds',
    'moricaude',
    'moricauds',
    'morigénai',
    'morigénas',
    'morigénât',
    'morigénée',
    'morigéner',
    'morigénés',
    'morigènes',
    'morigénez',
    'morillons',
    'mornifles',
    'morosités',
    'morphèmes',
    'morphines',
    'mortaisai',
    'mortaisas',
    'mortaisât',
    'mortaisée',
    'mortaiser',
    'mortaises',
    'mortaisés',
    'mortaisez',
    'mortalité',
    'morte-eau',
    'mortelles',
    'mortifiai',
    'mortifias',
    'mortifiât',
    'mortifiée',
    'mortifier',
    'mortifies',
    'mortifiés',
    'mortifiez',
    'mort-nées',
    'mortuaire',
    'morutière',
    'morutiers',
    'morveuses',
    'mosaïquée',
    'mosaïques',
    'mosaïqués',
    'mosaïsmes',
    'mosaïstes',
    'mosellane',
    'mosellans',
    'motilités',
    'motionnai',
    'motionnas',
    'motionnât',
    'motionnée',
    'motionner',
    'motionnes',
    'motionnés',
    'motionnez',
    'motivâmes',
    'motivante',
    'motivants',
    'motivasse',
    'motivâtes',
    'motiverai',
    'motiveras',
    'motiverez',
    'motivions',
    'motociste',
    'motocross',
    'motocycle',
    'motoneige',
    'motopompe',
    'motorisai',
    'motorisas',
    'motorisât',
    'motorisée',
    'motoriser',
    'motorises',
    'motorisés',
    'motorisez',
    'motoriste',
    'motorship',
    'motricité',
    'mots-clés',
    'mottaient',
    'mottasses',
    'motterais',
    'motterait',
    'mottèrent',
    'motteriez',
    'motterons',
    'motteront',
    'mouchages',
    'mouchâmes',
    'moucharda',
    'moucharde',
    'mouchardé',
    'mouchards',
    'mouchasse',
    'mouchâtes',
    'moucherai',
    'moucheras',
    'moucherez',
    'moucheron',
    'mouchetai',
    'mouchetas',
    'mouchetât',
    'mouchetée',
    'moucheter',
    'mouchetés',
    'mouchetez',
    'mouchetis',
    'mouchette',
    'mouchions',
    'mouchoirs',
    'mouchures',
    'moudrions',
    'mouettais',
    'mouettait',
    'mouettant',
    'mouettent',
    'mouettera',
    'mouettiez',
    'mouettons',
    'moufettes',
    'mouffette',
    'mouflette',
    'mouftâmes',
    'mouftasse',
    'mouftâtes',
    'moufterai',
    'moufteras',
    'moufterez',
    'mouftions',
    'mouillage',
    'mouillais',
    'mouillait',
    'mouillant',
    'mouillées',
    'mouillent',
    'mouillera',
    'mouillère',
    'mouilleur',
    'mouilliez',
    'mouilloir',
    'mouillons',
    'mouillure',
    'moujingue',
    'moulaient',
    'moulantes',
    'moulasses',
    'moulerais',
    'moulerait',
    'moulèrent',
    'mouleriez',
    'moulerons',
    'mouleront',
    'moulières',
    'moulinage',
    'moulinais',
    'moulinait',
    'moulinant',
    'moulinées',
    'moulinent',
    'moulinera',
    'moulinets',
    'moulineur',
    'moulinier',
    'mouliniez',
    'moulinons',
    'moulurais',
    'moulurait',
    'moulurant',
    'moulurées',
    'moulurent',
    'moulurera',
    'mouluriez',
    'moulurons',
    'moulusses',
    'moumoutes',
    'mouquères',
    'mouraient',
    'mourantes',
    'mouronnai',
    'mouronnas',
    'mouronnât',
    'mouronnée',
    'mouronner',
    'mouronnes',
    'mouronnés',
    'mouronnez',
    'mourrions',
    'moururent',
    'mourusses',
    'mousquets',
    'moussâmes',
    'moussante',
    'moussants',
    'moussasse',
    'moussâtes',
    'mousserai',
    'mousseras',
    'mousserez',
    'mousseron',
    'mousseuse',
    'moussions',
    'moussoirs',
    'moustache',
    'moustachu',
    'moustique',
    'moutardes',
    'moutonnai',
    'moutonnas',
    'moutonnât',
    'moutonnée',
    'moutonner',
    'moutonnes',
    'moutonnés',
    'moutonnez',
    'mouvaient',
    'mouvances',
    'mouvantes',
    'mouvasses',
    'mouvement',
    'mouverais',
    'mouverait',
    'mouvèrent',
    'mouveriez',
    'mouverons',
    'mouveront',
    'mouvrions',
    'moyen-âge',
    'moyennais',
    'moyennait',
    'moyennant',
    'moyennées',
    'moyennent',
    'moyennera',
    'moyenniez',
    'moyennons',
    'mozabites',
    'mozarabes',
    'muassions',
    'muchaient',
    'muchasses',
    'mucherais',
    'mucherait',
    'muchèrent',
    'mucheriez',
    'mucherons',
    'mucheront',
    'mucilages',
    'mucosités',
    'mudéjares',
    'mueraient',
    'mufleries',
    'mugirions',
    'mugissais',
    'mugissait',
    'mugissant',
    'mugissent',
    'mugissiez',
    'mugissons',
    'muguetais',
    'muguetait',
    'muguetant',
    'muguetées',
    'muguetiez',
    'muguetons',
    'muguettes',
    'mulassier',
    'muletière',
    'muletiers',
    'mulotâmes',
    'mulotasse',
    'mulotâtes',
    'muloterai',
    'muloteras',
    'muloterez',
    'mulotions',
    'multipare',
    'multiples',
    'multiplex',
    'multiplia',
    'multiplie',
    'multiplié',
    'multitube',
    'multitude',
    'municipal',
    'municipes',
    'munirions',
    'munissais',
    'munissait',
    'munissant',
    'munissent',
    'munissiez',
    'munissons',
    'munitions',
    'muqueuses',
    'muraillai',
    'muraillas',
    'muraillât',
    'muraillée',
    'murailler',
    'murailles',
    'muraillés',
    'muraillez',
    'murassent',
    'murassiez',
    'murerions',
    'mûririons',
    'mûrissage',
    'mûrissais',
    'mûrissait',
    'mûrissant',
    'mûrissent',
    'mûrissiez',
    'mûrissons',
    'murmurais',
    'murmurait',
    'murmurant',
    'murmurées',
    'murmurent',
    'murmurera',
    'murmuriez',
    'murmurons',
    'murrhines',
    'musagètes',
    'musardais',
    'musardait',
    'musardant',
    'musardent',
    'musardera',
    'musardiez',
    'musardise',
    'musardons',
    'musassent',
    'musassiez',
    'muscadets',
    'muscadier',
    'muscadins',
    'muscardin',
    'muscarine',
    'musclâmes',
    'musclasse',
    'musclâtes',
    'musclerai',
    'muscleras',
    'musclerez',
    'musclions',
    'musculeux',
    'muselâmes',
    'muselasse',
    'muselâtes',
    'muselière',
    'muselions',
    'musellent',
    'musellera',
    'muserions',
    'muserolle',
    'musicales',
    'musiciens',
    'musiquais',
    'musiquait',
    'musiquant',
    'musiquées',
    'musiquent',
    'musiquera',
    'musiquiez',
    'musiquons',
    'musquâmes',
    'musquasse',
    'musquâtes',
    'musquerai',
    'musqueras',
    'musquerez',
    'musquions',
    'mussaient',
    'mussasses',
    'musserais',
    'musserait',
    'mussèrent',
    'musseriez',
    'musserons',
    'musseront',
    'musulmane',
    'musulmans',
    'mutagènes',
    'mutassent',
    'mutassiez',
    'mutations',
    'muterions',
    'mutilâmes',
    'mutilante',
    'mutilants',
    'mutilasse',
    'mutilâtes',
    'mutilerai',
    'mutileras',
    'mutilerez',
    'mutilions',
    'mutinâmes',
    'mutinasse',
    'mutinâtes',
    'mutinerai',
    'mutineras',
    'mutinerez',
    'mutinerie',
    'mutinions',
    'mutualité',
    'mutuelles',
    'mycéliens',
    'mycéliums',
    'mycéniens',
    'mycoderme',
    'mycologie',
    'mycologue',
    'mycorhize',
    'mydriases',
    'myélocyte',
    'myocardes',
    'myographe',
    'myologies',
    'myopathie',
    'myopotame',
    'myrmidons',
    'myrobolan',
    'myrosines',
    'myroxyles',
    'myroxylon',
    'myrtilles',
    'mysticité',
    'mystifiai',
    'mystifias',
    'mystifiât',
    'mystifiée',
    'mystifier',
    'mystifies',
    'mystifiés',
    'mystifiez',
    'mystiques',
    'mythiques',
    'mythomane',
    'myxoedème',
    'nacraient',
    'nacrasses',
    'nacrerais',
    'nacrerait',
    'nacrèrent',
    'nacreriez',
    'nacrerons',
    'nacreront',
    'nageaient',
    'nageasses',
    'nageoires',
    'nagerions',
    'naissains',
    'naissance',
    'naissante',
    'naissants',
    'naissions',
    'naîtrions',
    'naïvement',
    'nantirais',
    'nantirait',
    'nantirent',
    'nantiriez',
    'nantirons',
    'nantiront',
    'nantisses',
    'nantissez',
    'napoléons',
    'nappaient',
    'nappasses',
    'napperais',
    'napperait',
    'nappèrent',
    'napperiez',
    'napperons',
    'napperont',
    'naquirent',
    'naquisses',
    'narcéines',
    'narcisses',
    'narcotine',
    'narghileh',
    'narghilés',
    'narguâmes',
    'narguasse',
    'narguâtes',
    'narguerai',
    'nargueras',
    'narguerez',
    'narguilés',
    'narguions',
    'narquoise',
    'narraient',
    'narrasses',
    'narrateur',
    'narratifs',
    'narration',
    'narrative',
    'narrerais',
    'narrerait',
    'narrèrent',
    'narreriez',
    'narrerons',
    'narreront',
    'nasalisai',
    'nasalisas',
    'nasalisât',
    'nasalisée',
    'nasaliser',
    'nasalises',
    'nasalisés',
    'nasalisez',
    'nasalités',
    'nasillais',
    'nasillait',
    'nasillant',
    'nasillard',
    'nasillées',
    'nasillent',
    'nasillera',
    'nasilleur',
    'nasilliez',
    'nasillons',
    'nasitorts',
    'nataliste',
    'natalités',
    'natations',
    'natatoire',
    'nationale',
    'nationaux',
    'nativisme',
    'nativiste',
    'nativités',
    'nattaient',
    'nattasses',
    'natterais',
    'natterait',
    'nattèrent',
    'natteriez',
    'natterons',
    'natteront',
    'nattières',
    'naturelle',
    'naturisme',
    'naturiste',
    'naufragea',
    'naufragée',
    'naufrager',
    'naufrages',
    'naufragés',
    'naufragez',
    'naumachie',
    'naupathie',
    'nauséeuse',
    'nautiques',
    'nautismes',
    'nautonier',
    'navarques',
    'navetteur',
    'navicules',
    'navigable',
    'navigante',
    'navigants',
    'naviguais',
    'naviguait',
    'naviguant',
    'naviguent',
    'naviguera',
    'naviguiez',
    'naviguons',
    'naviplane',
    'navraient',
    'navrantes',
    'navrasses',
    'navrement',
    'navrerais',
    'navrerait',
    'navrèrent',
    'navreriez',
    'navrerons',
    'navreront',
    'nazaréens',
    'néanmoins',
    'néantisai',
    'néantisas',
    'néantisât',
    'néantisée',
    'néantiser',
    'néantises',
    'néantisés',
    'néantisez',
    'nébuleuse',
    'nécessita',
    'nécessite',
    'nécessité',
    'nécrobies',
    'nécrologe',
    'nécromant',
    'nécropole',
    'nécropsie',
    'nécrosais',
    'nécrosait',
    'nécrosant',
    'nécrosées',
    'nécrosent',
    'nécrosera',
    'nécrosiez',
    'nécrosons',
    'nectaires',
    'nectarine',
    'négateurs',
    'négations',
    'négatives',
    'négatrice',
    'négligeai',
    'négligeas',
    'négligeât',
    'négligées',
    'négligent',
    'négligera',
    'négligiez',
    'négociais',
    'négociait',
    'négociant',
    'négociées',
    'négocient',
    'négociera',
    'négociiez',
    'négocions',
    'négresses',
    'négrilles',
    'négrillon',
    'négritude',
    'négroïdes',
    'neigerait',
    'neigeuses',
    'némalions',
    'nénuphars',
    'néoblaste',
    'néocomien',
    'néoformée',
    'néoformés',
    'néo-grecs',
    'néologies',
    'néoménies',
    'néomycine',
    'néonatale',
    'néonatals',
    'néophytes',
    'néoplasme',
    'néozoïque',
    'népenthès',
    'népériens',
    'néphélion',
    'néphridie',
    'néphrites',
    'néphroses',
    'népotisme',
    'neptunium',
    'néritique',
    'néroniens',
    'nervation',
    'nerveuses',
    'nervosité',
    'nervurais',
    'nervurait',
    'nervurant',
    'nervurées',
    'nervurent',
    'nervurera',
    'nervuriez',
    'nervurons',
    'nestorien',
    'nettement',
    'nettoient',
    'nettoiera',
    'nettoyage',
    'nettoyais',
    'nettoyait',
    'nettoyant',
    'nettoyées',
    'nettoyeur',
    'nettoyiez',
    'nettoyons',
    'neuronale',
    'neuronaux',
    'neutrinos',
    'neuvaines',
    'neuvièmes',
    'névralgie',
    'névrilème',
    'névroglie',
    'névrosées',
    'niaisâmes',
    'niaisasse',
    'niaisâtes',
    'niaiserai',
    'niaiseras',
    'niaiserez',
    'niaiserie',
    'niaisions',
    'niassions',
    'nichaient',
    'nichasses',
    'nicherais',
    'nicherait',
    'nichèrent',
    'nicheriez',
    'nicherons',
    'nicheront',
    'nichromes',
    'nickelage',
    'nickelais',
    'nickelait',
    'nickelant',
    'nickelées',
    'nickeliez',
    'nickelles',
    'nickelons',
    'nicodèmes',
    'nicotines',
    'nictation',
    'nictitant',
    'nidations',
    'nidifiais',
    'nidifiait',
    'nidifiant',
    'nidifient',
    'nidifiera',
    'nidifiiez',
    'nidifions',
    'niellages',
    'niellâmes',
    'niellasse',
    'niellâtes',
    'niellerai',
    'nielleras',
    'niellerez',
    'nielleurs',
    'niellions',
    'niellures',
    'nieraient',
    'nihilisme',
    'nihiliste',
    'nilotique',
    'nimbaient',
    'nimbasses',
    'nimberais',
    'nimberait',
    'nimbèrent',
    'nimberiez',
    'nimberons',
    'nimberont',
    'nippaient',
    'nippasses',
    'nipperais',
    'nipperait',
    'nippèrent',
    'nipperiez',
    'nipperons',
    'nipperont',
    'nipponnes',
    'nitouches',
    'nitraient',
    'nitrasses',
    'nitratais',
    'nitratait',
    'nitratant',
    'nitratées',
    'nitratent',
    'nitratera',
    'nitratiez',
    'nitration',
    'nitratons',
    'nitrerais',
    'nitrerait',
    'nitrèrent',
    'nitreriez',
    'nitrerons',
    'nitreront',
    'nitreuses',
    'nitrières',
    'nitrifiai',
    'nitrifias',
    'nitrifiât',
    'nitrifiée',
    'nitrifier',
    'nitrifies',
    'nitrifiés',
    'nitrifiez',
    'nitriques',
    'nitrurais',
    'nitrurait',
    'nitrurant',
    'nitrurées',
    'nitrurent',
    'nitrurera',
    'nitruriez',
    'nitrurons',
    'nivelages',
    'nivelâmes',
    'nivelasse',
    'nivelâtes',
    'nivelette',
    'niveleurs',
    'niveleuse',
    'nivelions',
    'nivellent',
    'nivellera',
    'nivernais',
    'noblement',
    'noblesses',
    'nocivités',
    'noctuelle',
    'nocturnes',
    'nodosités',
    'nodulaire',
    'noduleuse',
    'noétiques',
    'noierions',
    'noirâtres',
    'noiraudes',
    'noirceurs',
    'noircîmes',
    'noircirai',
    'noirciras',
    'noircirez',
    'noircisse',
    'noircîtes',
    'noiseraie',
    'noisetier',
    'noisettes',
    'nolisâmes',
    'nolisasse',
    'nolisâtes',
    'noliserai',
    'noliseras',
    'noliserez',
    'nolisions',
    'nomadisai',
    'nomadisas',
    'nomadisât',
    'nomadisée',
    'nomadiser',
    'nomadises',
    'nomadisés',
    'nomadisez',
    'nomadisme',
    'nombrable',
    'nombrâmes',
    'nombrasse',
    'nombrâtes',
    'nombrerai',
    'nombreuse',
    'nombrions',
    'nominales',
    'nominatif',
    'nommaient',
    'nommasses',
    'nommément',
    'nommerais',
    'nommerait',
    'nommèrent',
    'nommeriez',
    'nommerons',
    'nommeront',
    'non-lieux',
    'nonnettes',
    'nonpareil',
    'non-tissé',
    'non-usage',
    'nopassent',
    'nopassiez',
    'noperions',
    'nordiques',
    'nordirais',
    'nordirait',
    'nordirent',
    'nordiriez',
    'nordirons',
    'nordiront',
    'nordisses',
    'nordissez',
    'nordistes',
    'normalien',
    'normalisa',
    'normalise',
    'normalisé',
    'normandes',
    'normatifs',
    'normative',
    'norvégien',
    'nosologie',
    'nostalgie',
    'notamment',
    'notariale',
    'notariats',
    'notariaux',
    'notariées',
    'notassent',
    'notassiez',
    'notations',
    'noterions',
    'notifiais',
    'notifiait',
    'notifiant',
    'notifiées',
    'notifient',
    'notifiera',
    'notifiiez',
    'notifions',
    'notionnel',
    'notonecte',
    'notoriété',
    'nouaisons',
    'nouassent',
    'nouassiez',
    'nouerions',
    'nougatine',
    'nourrains',
    'nourrices',
    'nourrîmes',
    'nourrisse',
    'nourrîtes',
    'nouveauté',
    'nouvelles',
    'novassent',
    'novassiez',
    'novateurs',
    'novations',
    'novatoire',
    'novatrice',
    'novembres',
    'noverions',
    'noviciats',
    'novocaïne',
    'noyassent',
    'noyassiez',
    'noyautage',
    'noyautais',
    'noyautait',
    'noyautant',
    'noyautées',
    'noyautent',
    'noyautera',
    'noyautiez',
    'noyautons',
    'nuageuses',
    'nuançâmes',
    'nuançasse',
    'nuançâtes',
    'nuancerai',
    'nuanceras',
    'nuancerez',
    'nuancions',
    'nuassions',
    'nubiennes',
    'nubilités',
    'nucléaire',
    'nucléâmes',
    'nucléasse',
    'nucléâtes',
    'nucléerai',
    'nucléeras',
    'nucléerez',
    'nucléines',
    'nucléions',
    'nucléique',
    'nucléoles',
    'nueraient',
    'nuiraient',
    'nuisaient',
    'nuisances',
    'nuisibles',
    'nuisirent',
    'nuisisses',
    'nullardes',
    'nullement',
    'nullipare',
    'numéraire',
    'numérales',
    'numérique',
    'numérisai',
    'numérisas',
    'numérisât',
    'numérisée',
    'numériser',
    'numérises',
    'numérisés',
    'numérisez',
    'numérotai',
    'numérotas',
    'numérotât',
    'numérotée',
    'numéroter',
    'numérotes',
    'numérotés',
    'numérotez',
    'numismate',
    'nummulite',
    'nuptiales',
    'nurseries',
    'nutations',
    'nutriment',
    'nutritifs',
    'nutrition',
    'nutritive',
    'nyctalope',
    'nycturies',
    'nymphales',
    'nymphette',
    'nymphoses',
    'nystagmus',
    'oasiennes',
    'obédience',
    'obéirions',
    'obéissais',
    'obéissait',
    'obéissant',
    'obéissent',
    'obéissiez',
    'obéissons',
    'obélisque',
    'obéraient',
    'obérasses',
    'obérerais',
    'obérerait',
    'obérèrent',
    'obéreriez',
    'obérerons',
    'obéreront',
    'obituaire',
    'objectais',
    'objectait',
    'objectale',
    'objectant',
    'objectaux',
    'objectées',
    'objectent',
    'objectera',
    'objecteur',
    'objectiez',
    'objectifs',
    'objection',
    'objectiva',
    'objective',
    'objectivé',
    'objectons',
    'objurguai',
    'objurguas',
    'objurguât',
    'objurguer',
    'objurgues',
    'objurguez',
    'oblations',
    'oblatives',
    'obligeais',
    'obligeait',
    'obligeant',
    'obligeons',
    'obligerai',
    'obligeras',
    'obligerez',
    'obligions',
    'obliquais',
    'obliquait',
    'obliquant',
    'obliquent',
    'obliquera',
    'obliquiez',
    'obliquité',
    'obliquons',
    'oblitérai',
    'oblitéras',
    'oblitérât',
    'oblitérée',
    'oblitérer',
    'oblitérés',
    'oblitères',
    'oblitérez',
    'oblongues',
    'obnubilai',
    'obnubilas',
    'obnubilât',
    'obnubilée',
    'obnubiler',
    'obnubiles',
    'obnubilés',
    'obnubilez',
    'obombrais',
    'obombrait',
    'obombrant',
    'obombrées',
    'obombrent',
    'obombrera',
    'obombriez',
    'obombrons',
    'obscénité',
    'obscurcie',
    'obscurcir',
    'obscurcis',
    'obscurcit',
    'obscurcît',
    'obscurité',
    'obsédâmes',
    'obsédante',
    'obsédasse',
    'obsédâtes',
    'obséderai',
    'obséderas',
    'obséderez',
    'obsédions',
    'observais',
    'observait',
    'observant',
    'observées',
    'observent',
    'observera',
    'observiez',
    'observons',
    'obsession',
    'obsolètes',
    'obstacles',
    'obstinées',
    'obstiniez',
    'obstinons',
    'obstruais',
    'obstruait',
    'obstruant',
    'obstruées',
    'obstruent',
    'obstruera',
    'obstruiez',
    'obstruons',
    'obtempéra',
    'obtempéré',
    'obtempère',
    'obtenions',
    'obtention',
    'obtiendra',
    'obtiennes',
    'obtinrent',
    'obtinsses',
    'obturâmes',
    'obturasse',
    'obturâtes',
    'obturerai',
    'obtureras',
    'obturerez',
    'obturions',
    'obvenions',
    'obviaient',
    'obviasses',
    'obviendra',
    'obviennes',
    'obvierais',
    'obvierait',
    'obvièrent',
    'obvieriez',
    'obvierons',
    'obvieront',
    'obvinrent',
    'obvinsses',
    'occasions',
    'occidents',
    'occipital',
    'occitanes',
    'occluions',
    'occlurais',
    'occlurait',
    'occlurent',
    'occluriez',
    'occlurons',
    'occluront',
    'occlusifs',
    'occlusion',
    'occlusive',
    'occlusses',
    'occultais',
    'occultait',
    'occultant',
    'occultées',
    'occultent',
    'occultera',
    'occultiez',
    'occultons',
    'occupâmes',
    'occupante',
    'occupants',
    'occupasse',
    'occupâtes',
    'occuperai',
    'occuperas',
    'occuperez',
    'occupions',
    'occurrent',
    'océanaute',
    'océaniens',
    'océanique',
    'ocrassent',
    'ocrassiez',
    'ocrerions',
    'octaviais',
    'octaviait',
    'octaviant',
    'octaviées',
    'octavient',
    'octaviera',
    'octaviiez',
    'octavions',
    'octogonal',
    'octogones',
    'octopodes',
    'octostyle',
    'octroient',
    'octroiera',
    'octroyais',
    'octroyait',
    'octroyant',
    'octroyées',
    'octroyiez',
    'octroyons',
    'octuplais',
    'octuplait',
    'octuplant',
    'octuplées',
    'octuplent',
    'octuplera',
    'octupliez',
    'octuplons',
    'oculaires',
    'oculistes',
    'ocytocine',
    'odalisque',
    'odelettes',
    'odomètres',
    'odontoïde',
    'odorantes',
    'oedicnème',
    'oedipiens',
    'oeillades',
    'oeillards',
    'oeillères',
    'oeilleton',
    'oeillette',
    'oekoumène',
    'oenanthes',
    'oenilisme',
    'oenolique',
    'oenolisme',
    'oenologie',
    'oenologue',
    'oenothera',
    'oenothère',
    'oerstites',
    'oesophage',
    'oestrales',
    'oeufriers',
    'oeuvrâmes',
    'oeuvrasse',
    'oeuvrâtes',
    'oeuvrerai',
    'oeuvreras',
    'oeuvrerez',
    'oeuvrions',
    'offensais',
    'offensait',
    'offensant',
    'offensées',
    'offensent',
    'offensera',
    'offenseur',
    'offensiez',
    'offensifs',
    'offensive',
    'offensons',
    'officiais',
    'officiait',
    'officiant',
    'officiaux',
    'officiels',
    'officient',
    'officiera',
    'officiers',
    'officieux',
    'officiiez',
    'officinal',
    'officines',
    'officions',
    'offraient',
    'offrandes',
    'offrantes',
    'offreuses',
    'offrirais',
    'offrirait',
    'offrirent',
    'offririez',
    'offrirons',
    'offriront',
    'offrisses',
    'offusquai',
    'offusquas',
    'offusquât',
    'offusquée',
    'offusquer',
    'offusques',
    'offusqués',
    'offusquez',
    'oghamique',
    'ohmmètres',
    'oignirent',
    'oignonade',
    'oiselâmes',
    'oiselasse',
    'oiselâtes',
    'oiseleurs',
    'oiselière',
    'oiseliers',
    'oiselions',
    'oisellent',
    'oisellera',
    'oisillons',
    'oisivetés',
    'oléfiante',
    'oléfiants',
    'oléicoles',
    'oléifères',
    'oléiforme',
    'oléomètre',
    'olfactifs',
    'olfaction',
    'olfactive',
    'oligarque',
    'oligistes',
    'oligocène',
    'oligopole',
    'oliguries',
    'oliphants',
    'olivaison',
    'olivâtres',
    'oliveraie',
    'olivétain',
    'olivettes',
    'olographe',
    'olympiade',
    'olympiens',
    'olympique',
    'olympisme',
    'ombellées',
    'ombellule',
    'ombilical',
    'ombiliqué',
    'ombrageai',
    'ombrageas',
    'ombrageât',
    'ombragées',
    'ombragent',
    'ombragera',
    'ombrageux',
    'ombragiez',
    'ombraient',
    'ombrasses',
    'ombrelles',
    'ombrerais',
    'ombrerait',
    'ombrèrent',
    'ombreriez',
    'ombrerons',
    'ombreront',
    'ombrettes',
    'ombreuses',
    'ombrienne',
    'ombudsman',
    'ombudsmen',
    'omelettes',
    'omettions',
    'omettrais',
    'omettrait',
    'omettriez',
    'omettrons',
    'omettront',
    'omissions',
    'omnivores',
    'omoplates',
    'onanismes',
    'oncogènes',
    'oncologie',
    'oncotique',
    'onctueuse',
    'ondemètre',
    'ondoierai',
    'ondoieras',
    'ondoierez',
    'ondoyâmes',
    'ondoyante',
    'ondoyants',
    'ondoyasse',
    'ondoyâtes',
    'ondoyions',
    'ondulâmes',
    'ondulante',
    'ondulants',
    'ondulasse',
    'ondulâtes',
    'ondulerai',
    'onduleras',
    'ondulerez',
    'onduleuse',
    'ondulions',
    'onéreuses',
    'one-steps',
    'onglettes',
    'onguiculé',
    'oniriques',
    'onirismes',
    'onkotique',
    'ont-elles',
    'ontogénie',
    'ontologie',
    'onusienne',
    'oosphères',
    'oothèques',
    'opacifiai',
    'opacifias',
    'opacifiât',
    'opacifiée',
    'opacifier',
    'opacifies',
    'opacifiés',
    'opacifiez',
    'opalisais',
    'opalisait',
    'opalisant',
    'opalisées',
    'opalisent',
    'opalisera',
    'opalisiez',
    'opalisons',
    'opérables',
    'opéraient',
    'opérandes',
    'opérantes',
    'opérasses',
    'opérateur',
    'opération',
    'operculée',
    'opercules',
    'operculés',
    'opérerais',
    'opérerait',
    'opérèrent',
    'opéreriez',
    'opérerons',
    'opéreront',
    'opérettes',
    'ophidiens',
    'ophtalmie',
    'opiaçâmes',
    'opiaçasse',
    'opiaçâtes',
    'opiacerai',
    'opiaceras',
    'opiacerez',
    'opiacions',
    'opinaient',
    'opinasses',
    'opinerais',
    'opinerait',
    'opinèrent',
    'opineriez',
    'opinerons',
    'opineront',
    'opiniâtre',
    'opiniâtré',
    'opiomanes',
    'opiomanie',
    'opportune',
    'opportuns',
    'opposable',
    'opposâmes',
    'opposante',
    'opposasse',
    'opposâtes',
    'opposerai',
    'opposeras',
    'opposerez',
    'opposions',
    'oppressai',
    'oppressas',
    'oppressât',
    'oppressée',
    'oppresser',
    'oppresses',
    'oppressés',
    'oppressez',
    'oppressif',
    'opprimais',
    'opprimait',
    'opprimant',
    'opprimées',
    'oppriment',
    'opprimera',
    'opprimiez',
    'opprimons',
    'opprobres',
    'optassent',
    'optassiez',
    'optatives',
    'opterions',
    'opticiens',
    'optimales',
    'optimisai',
    'optimisas',
    'optimisât',
    'optimisée',
    'optimiser',
    'optimises',
    'optimisés',
    'optimisez',
    'optimisme',
    'optimiste',
    'optionnel',
    'opulences',
    'opulentes',
    'opuscules',
    'orageuses',
    'oralement',
    'orangeade',
    'orangeais',
    'orangeait',
    'orangeant',
    'orangeons',
    'orangerai',
    'orangeras',
    'orangerez',
    'orangerie',
    'orangions',
    'orangiste',
    'oratoires',
    'oratorien',
    'oratorios',
    'oratrices',
    'orbicoles',
    'orbitaire',
    'orbitales',
    'orbitâmes',
    'orbitasse',
    'orbitâtes',
    'orbiterai',
    'orbiteras',
    'orbiterez',
    'orbitions',
    'orcanette',
    'orchestra',
    'orchestre',
    'orchestré',
    'orchidées',
    'ordinaire',
    'ordinales',
    'ordinands',
    'ordonnais',
    'ordonnait',
    'ordonnant',
    'ordonnées',
    'ordonnent',
    'ordonnera',
    'ordonniez',
    'ordonnons',
    'ordurière',
    'orduriers',
    'oreillard',
    'oreillers',
    'oreillons',
    'organeaux',
    'organique',
    'organisai',
    'organisas',
    'organisât',
    'organisée',
    'organiser',
    'organises',
    'organisés',
    'organisez',
    'organisme',
    'organiste',
    'organites',
    'organsina',
    'organsine',
    'organsiné',
    'organsins',
    'orgiaques',
    'orientais',
    'orientait',
    'orientale',
    'orientant',
    'orientaux',
    'orientées',
    'orientent',
    'orientera',
    'orienteur',
    'orientiez',
    'orientons',
    'oriflamme',
    'originale',
    'originaux',
    'originels',
    'oringuais',
    'oringuait',
    'oringuant',
    'oringuées',
    'oringuent',
    'oringuera',
    'oringuiez',
    'oringuons',
    'ornassent',
    'ornassiez',
    'ornementa',
    'ornemente',
    'ornementé',
    'ornements',
    'ornerions',
    'orobanche',
    'orogenèse',
    'orogénies',
    'orpheline',
    'orphelins',
    'orphiques',
    'orphismes',
    'orpiments',
    'orseilles',
    'orthodoxe',
    'orviétans',
    'osassions',
    'oscabrion',
    'oscillais',
    'oscillait',
    'oscillant',
    'oscillent',
    'oscillera',
    'oscilliez',
    'oscillons',
    'oseraient',
    'osmomètre',
    'osmotique',
    'ossatures',
    'ossements',
    'ossifiais',
    'ossifiait',
    'ossifiant',
    'ossifiées',
    'ossifient',
    'ossifiera',
    'ossifiiez',
    'ossifions',
    'ossuaires',
    'ostéalgie',
    'ostensoir',
    'ostracisa',
    'ostracise',
    'ostracisé',
    'ostrogote',
    'ostrogots',
    'ôtassions',
    'ôteraient',
    'otocystes',
    'otolithes',
    'otologies',
    'oto-rhino',
    'otorragie',
    'otorrhées',
    'otoscopes',
    'ottomanes',
    'ouabaïnes',
    'ouaouaron',
    'ouataient',
    'ouatasses',
    'ouaterais',
    'ouaterait',
    'ouatèrent',
    'ouateriez',
    'ouaterons',
    'ouateront',
    'ouatinais',
    'ouatinait',
    'ouatinant',
    'ouatinées',
    'ouatinent',
    'ouatinera',
    'ouatiniez',
    'ouatinons',
    'oubliâmes',
    'oubliasse',
    'oubliâtes',
    'oublierai',
    'oublieras',
    'oublierez',
    'oubliette',
    'oublieuse',
    'oubliions',
    'ougrienne',
    'ouïghours',
    'ouillâmes',
    'ouillasse',
    'ouillâtes',
    'ouillerai',
    'ouilleras',
    'ouillères',
    'ouillerez',
    'ouillions',
    'ouïraient',
    'ouïssions',
    'ouistitis',
    'ouraliens',
    'ourdirais',
    'ourdirait',
    'ourdirent',
    'ourdiriez',
    'ourdirons',
    'ourdiront',
    'ourdisses',
    'ourdissez',
    'ourlaient',
    'ourlasses',
    'ourlerais',
    'ourlerait',
    'ourlèrent',
    'ourleriez',
    'ourlerons',
    'ourleront',
    'ourlienne',
    'outardeau',
    'outillage',
    'outillais',
    'outillait',
    'outillant',
    'outillées',
    'outillent',
    'outillera',
    'outilleur',
    'outilliez',
    'outillons',
    'outrageai',
    'outrageas',
    'outrageât',
    'outragées',
    'outragent',
    'outragera',
    'outrageux',
    'outragiez',
    'outraient',
    'outrances',
    'outrasses',
    'outre-mer',
    'outrerais',
    'outrerait',
    'outrèrent',
    'outreriez',
    'outrerons',
    'outreront',
    'outsiders',
    'ouverture',
    'ouvrables',
    'ouvrageai',
    'ouvrageas',
    'ouvrageât',
    'ouvragées',
    'ouvragent',
    'ouvragera',
    'ouvragiez',
    'ouvraient',
    'ouvraison',
    'ouvrantes',
    'ouvrasses',
    'ouvrerais',
    'ouvrerait',
    'ouvrèrent',
    'ouvreriez',
    'ouvrerons',
    'ouvreront',
    'ouvreuses',
    'ouvrières',
    'ouvrirais',
    'ouvrirait',
    'ouvrirent',
    'ouvririez',
    'ouvrirons',
    'ouvriront',
    'ouvrisses',
    'ovalisais',
    'ovalisait',
    'ovalisant',
    'ovalisées',
    'ovalisent',
    'ovalisera',
    'ovalisiez',
    'ovalisons',
    'ovarienne',
    'ovationna',
    'ovationne',
    'ovationné',
    'overdoses',
    'oviductes',
    'oviparité',
    'oviscapte',
    'ovogenèse',
    'ovoïdales',
    'ovulaient',
    'ovulaires',
    'ovulasses',
    'ovulation',
    'ovulerais',
    'ovulerait',
    'ovulèrent',
    'ovuleriez',
    'ovulerons',
    'ovuleront',
    'oxaliques',
    'oxydables',
    'oxydaient',
    'oxydasses',
    'oxydation',
    'oxyderais',
    'oxyderait',
    'oxydèrent',
    'oxyderiez',
    'oxyderons',
    'oxyderont',
    'oxygénais',
    'oxygénait',
    'oxygénant',
    'oxygénées',
    'oxygènent',
    'oxygénera',
    'oxygéniez',
    'oxygénons',
    'oxylithes',
    'oxyuroses',
    'ozocérite',
    'ozokérite',
    'ozonisais',
    'ozonisait',
    'ozonisant',
    'ozonisées',
    'ozonisent',
    'ozonisera',
    'ozoniseur',
    'ozonisiez',
    'ozonisons',
    'pacageais',
    'pacageait',
    'pacageant',
    'pacageons',
    'pacagerai',
    'pacageras',
    'pacagerez',
    'pacagions',
    'pacemaker',
    'pachaliks',
    'pacifiais',
    'pacifiait',
    'pacifiant',
    'pacifiées',
    'pacifient',
    'pacifiera',
    'pacifiiez',
    'pacifions',
    'pacifique',
    'pacifisme',
    'pacifiste',
    'packfungs',
    'pacotille',
    'pacquages',
    'pacquâmes',
    'pacquasse',
    'pacquâtes',
    'pacquerai',
    'pacqueras',
    'pacquerez',
    'pacquions',
    'pactisais',
    'pactisait',
    'pactisant',
    'pactisent',
    'pactisera',
    'pactisiez',
    'pactisons',
    'padichahs',
    'padischah',
    'pagaierai',
    'pagaieras',
    'pagaierez',
    'pagailles',
    'paganisai',
    'paganisas',
    'paganisât',
    'paganisée',
    'paganiser',
    'paganises',
    'paganisés',
    'paganisez',
    'paganisme',
    'pagayâmes',
    'pagayasse',
    'pagayâtes',
    'pagayerai',
    'pagayeras',
    'pagayerez',
    'pagayeurs',
    'pagayeuse',
    'pagayions',
    'pageaient',
    'pageasses',
    'pageotais',
    'pageotait',
    'pageotant',
    'pageotées',
    'pageotent',
    'pageotera',
    'pageotiez',
    'pageotons',
    'pagerions',
    'paginâmes',
    'paginasse',
    'paginâtes',
    'paginerai',
    'pagineras',
    'paginerez',
    'paginions',
    'pagnotais',
    'pagnotait',
    'pagnotant',
    'pagnotent',
    'pagnotera',
    'pagnotiez',
    'pagnotons',
    'paiements',
    'paierions',
    'paillages',
    'paillâmes',
    'paillarda',
    'paillarde',
    'paillardé',
    'paillards',
    'paillasse',
    'paillâtes',
    'paillerai',
    'pailleras',
    'paillerez',
    'pailletai',
    'pailletas',
    'pailletât',
    'pailletée',
    'pailleter',
    'pailletés',
    'pailletez',
    'paillette',
    'pailleuse',
    'paillions',
    'paillonna',
    'paillonne',
    'paillonné',
    'paillotes',
    'pairesses',
    'paisibles',
    'paissance',
    'paisseaux',
    'paisselai',
    'paisselas',
    'paisselât',
    'paisselée',
    'paisseler',
    'paisselés',
    'paisselez',
    'paisselle',
    'paissions',
    'paîtrions',
    'pajotâmes',
    'pajotasse',
    'pajotâtes',
    'pajoterai',
    'pajoteras',
    'pajoterez',
    'pajotions',
    'palabrais',
    'palabrait',
    'palabrant',
    'palabrent',
    'palabrera',
    'palabriez',
    'palabrons',
    'palanches',
    'palancrai',
    'palancras',
    'palancrât',
    'palancrée',
    'palancrer',
    'palancres',
    'palancrés',
    'palancrez',
    'palangrai',
    'palangras',
    'palangrât',
    'palangrée',
    'palangrer',
    'palangres',
    'palangrés',
    'palangrez',
    'palanguai',
    'palanguas',
    'palanguât',
    'palanguer',
    'palangues',
    'palanguez',
    'palanquai',
    'palanquas',
    'palanquât',
    'palanquée',
    'palanquer',
    'palanques',
    'palanqués',
    'palanquez',
    'palanquin',
    'palastres',
    'palatales',
    'palatinat',
    'palatines',
    'palefrois',
    'paléosols',
    'palestres',
    'palettais',
    'palettait',
    'palettant',
    'palettées',
    'palettent',
    'palettera',
    'palettiez',
    'palettisa',
    'palettise',
    'palettisé',
    'palettons',
    'palicares',
    'pâlichons',
    'palikares',
    'palilalie',
    'palinodie',
    'pâlirions',
    'palissada',
    'palissade',
    'palissadé',
    'palissage',
    'palissais',
    'pâlissais',
    'palissait',
    'pâlissait',
    'palissant',
    'pâlissant',
    'palissées',
    'palissent',
    'pâlissent',
    'palissera',
    'palissiez',
    'pâlissiez',
    'palissons',
    'pâlissons',
    'palladium',
    'palléales',
    'palliâmes',
    'palliasse',
    'palliâtes',
    'palliatif',
    'pallidums',
    'pallierai',
    'pallieras',
    'pallierez',
    'palliions',
    'palmaient',
    'palmaires',
    'palmasses',
    'palmature',
    'palmeraie',
    'palmerais',
    'palmerait',
    'palmèrent',
    'palmeriez',
    'palmerons',
    'palmeront',
    'palmettes',
    'palmifide',
    'palmilobé',
    'palmipède',
    'palmistes',
    'palmitine',
    'palonnier',
    'palotâmes',
    'palotasse',
    'palotâtes',
    'paloterai',
    'paloteras',
    'paloterez',
    'palotions',
    'palourdes',
    'palpables',
    'palpaient',
    'palpasses',
    'palpation',
    'palpébral',
    'palperais',
    'palperait',
    'palpèrent',
    'palperiez',
    'palperons',
    'palperont',
    'palpitais',
    'palpitait',
    'palpitant',
    'palpitent',
    'palpitera',
    'palpitiez',
    'palpitons',
    'paltoquet',
    'paludéens',
    'paludière',
    'paludiers',
    'paludines',
    'paludisme',
    'palustres',
    'pâmassent',
    'pâmassiez',
    'pâmerions',
    'pâmoisons',
    'pamphlets',
    'pampilles',
    'panachage',
    'panachais',
    'panachait',
    'panachant',
    'panachées',
    'panachent',
    'panachera',
    'panachiez',
    'panachons',
    'panachure',
    'panassent',
    'panassiez',
    'panatelas',
    'panatella',
    'pancartes',
    'pancraces',
    'pandectes',
    'pandémies',
    'panerions',
    'paneterie',
    'panetière',
    'panetiers',
    'pangolins',
    'panicauts',
    'paniculée',
    'paniculés',
    'panifiais',
    'panifiait',
    'panifiant',
    'panifiées',
    'panifient',
    'panifiera',
    'panifiiez',
    'panifions',
    'paniquais',
    'paniquait',
    'paniquant',
    'paniquard',
    'paniquées',
    'paniquent',
    'paniquera',
    'paniquiez',
    'paniquons',
    'panmixies',
    'pannaient',
    'pannasses',
    'panneauta',
    'panneaute',
    'panneauté',
    'pannerais',
    'pannerait',
    'pannèrent',
    'panneriez',
    'pannerons',
    'panneront',
    'pannetons',
    'pannicule',
    'panonceau',
    'panoplies',
    'panoramas',
    'panoufles',
    'pansaient',
    'pansasses',
    'pansement',
    'panserais',
    'panserait',
    'pansèrent',
    'panseriez',
    'panserons',
    'panseront',
    'pantalons',
    'pantelais',
    'pantelait',
    'pantelant',
    'panteliez',
    'pantelles',
    'pantelons',
    'pantennes',
    'panthéons',
    'panthères',
    'pantières',
    'pantoires',
    'pantoises',
    'pantomime',
    'pantoufla',
    'pantoufle',
    'pantouflé',
    'papelarde',
    'papelards',
    'paperasse',
    'papeterie',
    'papetière',
    'papetiers',
    'papilleux',
    'papillome',
    'papillons',
    'papillota',
    'papillote',
    'papilloté',
    'papotages',
    'papotâmes',
    'papotasse',
    'papotâtes',
    'papoterai',
    'papoteras',
    'papoterez',
    'papotions',
    'papouilla',
    'papouille',
    'papouillé',
    'papuleuse',
    'paquebots',
    'paquetage',
    'paqueteur',
    'parabases',
    'parabiose',
    'paraboles',
    'paracheva',
    'parachevé',
    'parachève',
    'parachuta',
    'parachute',
    'parachuté',
    'paraclets',
    'paradâmes',
    'paradasse',
    'paradâtes',
    'paraderai',
    'paraderas',
    'paraderez',
    'paradeurs',
    'paradigme',
    'paradions',
    'paradoxal',
    'paradoxes',
    'parafâmes',
    'parafasse',
    'parafâtes',
    'paraferai',
    'paraferas',
    'paraferez',
    'paraffina',
    'paraffine',
    'paraffiné',
    'parafions',
    'paragrêle',
    'paraisses',
    'paraissez',
    'paraîtrai',
    'paraîtras',
    'paraîtrez',
    'parallaxe',
    'parallèle',
    'paralysai',
    'paralysas',
    'paralysât',
    'paralysée',
    'paralyser',
    'paralyses',
    'paralysés',
    'paralysez',
    'paralysie',
    'paramécie',
    'paramétra',
    'paramétré',
    'paramètre',
    'parangons',
    'paranoïas',
    'paranoïde',
    'paraphais',
    'paraphait',
    'paraphant',
    'paraphées',
    'paraphent',
    'paraphera',
    'paraphiez',
    'paraphons',
    'paraphyse',
    'parapluie',
    'parascève',
    'parasitai',
    'parasitas',
    'parasitât',
    'parasitée',
    'parasiter',
    'parasites',
    'parasités',
    'parasitez',
    'parassent',
    'parassiez',
    'parataxes',
    'paravents',
    'parcellai',
    'parcellas',
    'parcellât',
    'parcellée',
    'parceller',
    'parcelles',
    'parcellés',
    'parcellez',
    'parchemin',
    'parcmètre',
    'parcoures',
    'parcourez',
    'parcourir',
    'parcourra',
    'parcourue',
    'parcourus',
    'parcourut',
    'parcourût',
    'pardessus',
    'pardonnai',
    'pardonnas',
    'pardonnât',
    'pardonnée',
    'pardonner',
    'pardonnes',
    'pardonnés',
    'pardonnez',
    'pare-boue',
    'pareilles',
    'parementa',
    'paremente',
    'parementé',
    'parements',
    'parentale',
    'parentaux',
    'parerâmes',
    'parerasse',
    'parerâtes',
    'paressais',
    'paressait',
    'paressant',
    'paressent',
    'paressera',
    'paresseux',
    'paressiez',
    'paressons',
    'parfaites',
    'parfasses',
    'parferais',
    'parferait',
    'parferiez',
    'parferons',
    'parferont',
    'parfilage',
    'parfilais',
    'parfilait',
    'parfilant',
    'parfilées',
    'parfilent',
    'parfilera',
    'parfiliez',
    'parfilons',
    'parfirent',
    'parfisses',
    'parfondes',
    'parfondez',
    'parfondis',
    'parfondit',
    'parfondît',
    'parfondra',
    'parfondre',
    'parfondue',
    'parfondus',
    'parfumais',
    'parfumait',
    'parfumant',
    'parfumées',
    'parfument',
    'parfumera',
    'parfumeur',
    'parfumiez',
    'parfumons',
    'parhélies',
    'pariaient',
    'pariasses',
    'parierais',
    'parierait',
    'parièrent',
    'parieriez',
    'parierons',
    'parieront',
    'pariétale',
    'pariétaux',
    'parieuses',
    'parigotes',
    'paripenné',
    'parisette',
    'parisiens',
    'paritaire',
    'parjurais',
    'parjurait',
    'parjurant',
    'parjurées',
    'parjurent',
    'parjurera',
    'parjuriez',
    'parjurons',
    'parkérisa',
    'parkérise',
    'parkérisé',
    'parkinson',
    'parlaient',
    'parlantes',
    'parlasses',
    'parlement',
    'parlerais',
    'parlerait',
    'parlèrent',
    'parleriez',
    'parlerons',
    'parleront',
    'parleuses',
    'parlez-en',
    'parlotais',
    'parlotait',
    'parlotant',
    'parlotent',
    'parlotera',
    'parlotiez',
    'parlotons',
    'parlottes',
    'parmélies',
    'parmesans',
    'parnasses',
    'parodiais',
    'parodiait',
    'parodiant',
    'parodiées',
    'parodient',
    'parodiera',
    'parodiiez',
    'parodions',
    'parodique',
    'parodiste',
    'paroisses',
    'parolière',
    'paroliers',
    'paronymes',
    'paronymie',
    'parotides',
    'parousies',
    'paroxysme',
    'paroxyton',
    'parpaings',
    'parquâmes',
    'parquasse',
    'parquâtes',
    'parquerai',
    'parqueras',
    'parquerez',
    'parquetai',
    'parquetas',
    'parquetât',
    'parquetée',
    'parqueter',
    'parquetés',
    'parquetez',
    'parquette',
    'parqueurs',
    'parqueuse',
    'parquions',
    'parrainai',
    'parrainas',
    'parrainât',
    'parrainée',
    'parrainer',
    'parraines',
    'parrainés',
    'parrainez',
    'parricide',
    'parsemais',
    'parsemait',
    'parsemant',
    'parsemées',
    'parsèment',
    'parsèmera',
    'parsemiez',
    'parsemons',
    'parsismes',
    'partageai',
    'partageas',
    'partageât',
    'partagées',
    'partagent',
    'partagera',
    'partageur',
    'partageux',
    'partagiez',
    'partaient',
    'partances',
    'partantes',
    'parterres',
    'partiaire',
    'partiales',
    'participa',
    'participe',
    'participé',
    'particule',
    'partielle',
    'partirais',
    'partirait',
    'partirent',
    'partiriez',
    'partirons',
    'partiront',
    'partisane',
    'partisans',
    'partisses',
    'partiteur',
    'partitifs',
    'partition',
    'partitive',
    'partouses',
    'partouzes',
    'parurerie',
    'parurière',
    'paruriers',
    'parussent',
    'parussiez',
    'parutions',
    'parvenais',
    'parvenait',
    'parvenant',
    'parveniez',
    'parvenons',
    'parvenues',
    'parvienne',
    'parvînmes',
    'parvinsse',
    'parvîntes',
    'passables',
    'passagère',
    'passagers',
    'passaient',
    'passantes',
    'passasses',
    'passation',
    'passavant',
    'passéisme',
    'passéiste',
    'passement',
    'passepoil',
    'passeport',
    'passerage',
    'passerais',
    'passerait',
    'passereau',
    'passèrent',
    'passeriez',
    'passerine',
    'passerons',
    'passeront',
    'passe-thé',
    'passeuses',
    'passibles',
    'passionna',
    'passionne',
    'passionné',
    'passivité',
    'passoires',
    'pastellai',
    'pastellas',
    'pastellât',
    'pastellée',
    'pasteller',
    'pastelles',
    'pastellés',
    'pastellez',
    'pastèques',
    'pastichai',
    'pastichas',
    'pastichât',
    'pastichée',
    'pasticher',
    'pastiches',
    'pastichés',
    'pastichez',
    'pastillai',
    'pastillas',
    'pastillât',
    'pastillée',
    'pastiller',
    'pastilles',
    'pastillés',
    'pastillez',
    'pastorale',
    'pastoraux',
    'patachons',
    'patafiola',
    'patafiole',
    'patafiolé',
    'patapoufs',
    'patarasse',
    'pâtassent',
    'pâtassiez',
    'pataugeai',
    'pataugeas',
    'pataugeât',
    'pataugent',
    'pataugera',
    'pataugeur',
    'pataugiez',
    'patchouli',
    'patchwork',
    'patelinai',
    'patelinas',
    'patelinât',
    'patelinée',
    'pateliner',
    'patelines',
    'patelinés',
    'patelinez',
    'patenôtre',
    'patentage',
    'patentais',
    'patentait',
    'patentant',
    'patentées',
    'patentent',
    'patentera',
    'patentiez',
    'patentons',
    'pâterions',
    'paternels',
    'paternité',
    'pathogène',
    'patiences',
    'patientai',
    'patientas',
    'patientât',
    'patienter',
    'patientes',
    'patientez',
    'patinages',
    'patinâmes',
    'patinasse',
    'patinâtes',
    'patinerai',
    'patineras',
    'patinerez',
    'patinette',
    'patineurs',
    'patineuse',
    'patinions',
    'patinoire',
    'pâtirions',
    'pâtissais',
    'pâtissait',
    'pâtissant',
    'pâtissées',
    'pâtissent',
    'pâtissera',
    'pâtissier',
    'pâtissiez',
    'pâtissons',
    'patoisais',
    'patoisait',
    'patoisant',
    'patoisent',
    'patoisera',
    'patoisiez',
    'patoisons',
    'patouilla',
    'patouille',
    'patouillé',
    'patraques',
    'patriciat',
    'patricien',
    'patriclan',
    'patriotes',
    'patronage',
    'patronale',
    'patronats',
    'patronaux',
    'patronnai',
    'patronnas',
    'patronnât',
    'patronnée',
    'patronner',
    'patronnes',
    'patronnés',
    'patronnez',
    'patronyme',
    'pattaient',
    'pattasses',
    'patterais',
    'patterait',
    'pattèrent',
    'patteriez',
    'patterons',
    'patteront',
    'pâturages',
    'pâturâmes',
    'pâturasse',
    'pâturâtes',
    'pâturerai',
    'pâtureras',
    'pâturerez',
    'pâturions',
    'pauchouse',
    'paulettes',
    'paulienne',
    'paulinien',
    'paulistes',
    'paulownia',
    'paumaient',
    'paumasses',
    'paumelles',
    'paumerais',
    'paumerait',
    'paumèrent',
    'paumeriez',
    'paumerons',
    'paumeront',
    'paumoient',
    'paumoiera',
    'paumoyais',
    'paumoyait',
    'paumoyant',
    'paumoyées',
    'paumoyiez',
    'paumoyons',
    'paupérisa',
    'paupérise',
    'paupérisé',
    'paupières',
    'paupiette',
    'pausaient',
    'pausasses',
    'pauserais',
    'pauserait',
    'pausèrent',
    'pauseriez',
    'pauserons',
    'pauseront',
    'pauvresse',
    'pauvretés',
    'pauvrette',
    'pavanâmes',
    'pavanasse',
    'pavanâtes',
    'pavanerai',
    'pavaneras',
    'pavanerez',
    'pavanions',
    'pavassent',
    'pavassiez',
    'pavements',
    'paverions',
    'pavillons',
    'pavoisais',
    'pavoisait',
    'pavoisant',
    'pavoisées',
    'pavoisent',
    'pavoisera',
    'pavoisiez',
    'pavoisons',
    'payassent',
    'payassiez',
    'payements',
    'payerions',
    'paysagère',
    'paysagers',
    'paysannat',
    'paysannes',
    'péageères',
    'péagistes',
    'peauciers',
    'peaufinai',
    'peaufinas',
    'peaufinât',
    'peaufinée',
    'peaufiner',
    'peaufines',
    'peaufinés',
    'peaufinez',
    'peaussais',
    'peaussait',
    'peaussant',
    'peaussent',
    'peaussera',
    'peaussier',
    'peaussiez',
    'peaussons',
    'pébroques',
    'peccables',
    'péchaient',
    'pêchaient',
    'péchasses',
    'pêchasses',
    'pécherais',
    'pêcherais',
    'pécherait',
    'pêcherait',
    'péchèrent',
    'pêchèrent',
    'pêcheries',
    'pécheriez',
    'pêcheriez',
    'pécherons',
    'pêcherons',
    'pécheront',
    'pêcheront',
    'pêchettes',
    'pêcheuses',
    'péclotais',
    'péclotait',
    'péclotant',
    'péclotent',
    'péclotera',
    'péclotiez',
    'péclotons',
    'pectinées',
    'pectiques',
    'pectorale',
    'pectoraux',
    'pédagogie',
    'pédagogue',
    'pédalâmes',
    'pédalasse',
    'pédalâtes',
    'pédalerai',
    'pédaleras',
    'pédalerez',
    'pédaleurs',
    'pédaleuse',
    'pédaliers',
    'pédalions',
    'pédéraste',
    'pédestres',
    'pédiatres',
    'pédiatrie',
    'pédicelle',
    'pédiculée',
    'pédiculés',
    'pédicures',
    'pédicurie',
    'pédieuses',
    'pedigrees',
    'pédimanes',
    'pédiments',
    'pédologie',
    'pédologue',
    'pédoncule',
    'pédonculé',
    'pédophile',
    'pegmatite',
    'peignages',
    'peignâmes',
    'peignasse',
    'peignâtes',
    'peignerai',
    'peigneras',
    'peignerez',
    'peigneurs',
    'peigneuse',
    'peigniers',
    'peignîmes',
    'peignions',
    'peignisse',
    'peignîtes',
    'peignoire',
    'peignoirs',
    'peignures',
    'peinaient',
    'peinardes',
    'peinasses',
    'peindrais',
    'peindrait',
    'peindriez',
    'peindrons',
    'peindront',
    'peinerais',
    'peinerait',
    'peinèrent',
    'peineriez',
    'peinerons',
    'peineront',
    'peinturai',
    'peinturas',
    'peinturât',
    'peinturée',
    'peinturer',
    'peintures',
    'peinturés',
    'peinturez',
    'péjoratif',
    'pékinoise',
    'pélagiens',
    'pélagique',
    'pélamides',
    'pélamydes',
    'pélasgien',
    'pelassent',
    'pelassiez',
    'peléennes',
    'péléennes',
    'pêle-mêle',
    'pèlerines',
    'pèlerions',
    'pellagres',
    'pelletage',
    'pelletais',
    'pelletait',
    'pelletant',
    'pelletées',
    'pelleteur',
    'pelletier',
    'pelletiez',
    'pelletons',
    'pellettes',
    'pellicule',
    'pelliculé',
    'pellucide',
    'pélobates',
    'pélodytes',
    'pelotages',
    'pelotâmes',
    'pelotaris',
    'pelotasse',
    'pelotâtes',
    'peloterai',
    'peloteras',
    'peloterez',
    'peloteurs',
    'peloteuse',
    'pelotions',
    'pelotonna',
    'pelotonne',
    'pelotonné',
    'peltastes',
    'peluchais',
    'peluchait',
    'peluchant',
    'peluchées',
    'peluchent',
    'peluchera',
    'pelucheux',
    'peluchiez',
    'peluchons',
    'pelvienne',
    'pemmicans',
    'pénalisai',
    'pénalisas',
    'pénalisât',
    'pénalisée',
    'pénaliser',
    'pénalises',
    'pénalisés',
    'pénalisez',
    'pénalités',
    'penalties',
    'penchâmes',
    'penchante',
    'penchants',
    'penchasse',
    'penchâtes',
    'pencherai',
    'pencheras',
    'pencherez',
    'penchions',
    'pendables',
    'pendaient',
    'pendaison',
    'pendantes',
    'pendentif',
    'penderies',
    'pendillai',
    'pendillas',
    'pendillât',
    'pendiller',
    'pendilles',
    'pendillez',
    'pendillon',
    'pendirent',
    'pendisses',
    'pendrions',
    'pendulais',
    'pendulait',
    'pendulant',
    'pendulent',
    'pendulera',
    'penduliez',
    'pendulons',
    'pénétrais',
    'pénétrait',
    'pénétrant',
    'pénétrées',
    'pénètrent',
    'pénétrera',
    'pénétriez',
    'pénétrons',
    'pénicillé',
    'péniennes',
    'péninsule',
    'pénitence',
    'pénitente',
    'pénitents',
    'pénombres',
    'pensables',
    'pensaient',
    'pensantes',
    'pensasses',
    'penserais',
    'penserait',
    'pensèrent',
    'penseriez',
    'penserons',
    'penseront',
    'penseuses',
    'pensionna',
    'pensionne',
    'pensionné',
    'pentacles',
    'pentaèdre',
    'pentagone',
    'pentamère',
    'pentapols',
    'pentatome',
    'pentecôte',
    'penthodes',
    'penthotal',
    'pépiaient',
    'pépiasses',
    'pépiement',
    'pépierais',
    'pépierait',
    'pépièrent',
    'pépieriez',
    'pépierons',
    'pépieront',
    'pépinière',
    'péponides',
    'peptiques',
    'péquenaud',
    'péquenots',
    'péquistes',
    'péramèles',
    'perborate',
    'perçaient',
    'percaline',
    'perçantes',
    'perçasses',
    'percement',
    'perceptif',
    'percerais',
    'percerait',
    'percèrent',
    'perceriez',
    'percerons',
    'perceront',
    'perceuses',
    'percevais',
    'percevait',
    'percevant',
    'perceviez',
    'percevoir',
    'percevons',
    'percevrai',
    'percevras',
    'percevrez',
    'perchâmes',
    'perchasse',
    'perchâtes',
    'percherai',
    'percheras',
    'percherez',
    'percheron',
    'percheurs',
    'percheuse',
    'perchions',
    'perchiste',
    'perchoirs',
    'percluses',
    'perçoives',
    'perçurent',
    'perçusses',
    'percutais',
    'percutait',
    'percutant',
    'percutées',
    'percutent',
    'percutera',
    'percuteur',
    'percutiez',
    'percutons',
    'perdables',
    'perdaient',
    'perdantes',
    'perdirent',
    'perdisses',
    'perdition',
    'perdreaux',
    'perdrions',
    'perdurais',
    'perdurait',
    'perdurant',
    'perdurent',
    'perdurera',
    'perduriez',
    'perdurons',
    'pérégrina',
    'pérégrine',
    'pérégriné',
    'pérennisa',
    'pérennise',
    'pérennisé',
    'pérennité',
    'perfectif',
    'perfidies',
    'perfoliée',
    'perfoliés',
    'perforage',
    'perforais',
    'perforait',
    'perforant',
    'perforées',
    'perforent',
    'perforera',
    'perforiez',
    'perforons',
    'perfusais',
    'perfusait',
    'perfusant',
    'perfusées',
    'perfusent',
    'perfusera',
    'perfusiez',
    'perfusion',
    'perfusons',
    'périanthe',
    'péricarde',
    'péricarpe',
    'périclita',
    'périclite',
    'périclité',
    'péricycle',
    'péridural',
    'périgueux',
    'périhélie',
    'périlleux',
    'périmâmes',
    'périmasse',
    'périmâtes',
    'périmerai',
    'périmeras',
    'périmerez',
    'périmètre',
    'périmions',
    'périnatal',
    'périnéale',
    'périnéaux',
    'périostes',
    'péripétie',
    'périptère',
    'péririons',
    'périscope',
    'périssais',
    'périssait',
    'périssant',
    'périssent',
    'périssiez',
    'périssons',
    'péristome',
    'péristyle',
    'périthèce',
    'péritoine',
    'perlaient',
    'perlasses',
    'perlèches',
    'perlerais',
    'perlerait',
    'perlèrent',
    'perleriez',
    'perlerons',
    'perleront',
    'perlières',
    'perlouses',
    'perlouzes',
    'permalloy',
    'permanent',
    'perméable',
    'permettes',
    'permettez',
    'permettra',
    'permettre',
    'permienne',
    'permirent',
    'permisses',
    'permissif',
    'permutais',
    'permutait',
    'permutant',
    'permutées',
    'permutent',
    'permutera',
    'permutiez',
    'permutons',
    'péronière',
    'péroniers',
    'pérorâmes',
    'pérorasse',
    'pérorâtes',
    'pérorerai',
    'péroreras',
    'pérorerez',
    'péroreurs',
    'péroreuse',
    'pérorions',
    'peroxydai',
    'péroxydai',
    'peroxydas',
    'péroxydas',
    'peroxydât',
    'péroxydât',
    'peroxydée',
    'péroxydée',
    'peroxyder',
    'péroxyder',
    'peroxydes',
    'peroxydés',
    'péroxydes',
    'péroxydés',
    'peroxydez',
    'péroxydez',
    'perpétrai',
    'perpétras',
    'perpétrât',
    'perpétrée',
    'perpétrer',
    'perpétrés',
    'perpètres',
    'perpétrez',
    'perpétuai',
    'perpétuas',
    'perpétuât',
    'perpétuée',
    'perpétuel',
    'perpétuer',
    'perpétues',
    'perpétués',
    'perpétuez',
    'perplexes',
    'perroquet',
    'perruches',
    'perruques',
    'persécuta',
    'persécute',
    'persécuté',
    'persévéra',
    'persévéré',
    'persévère',
    'persicots',
    'persienne',
    'persiflai',
    'persiflas',
    'persiflât',
    'persiflée',
    'persifler',
    'persifles',
    'persiflés',
    'persiflez',
    'persillai',
    'persillas',
    'persillât',
    'persillée',
    'persiller',
    'persilles',
    'persillés',
    'persillez',
    'persistai',
    'persistas',
    'persistât',
    'persister',
    'persistes',
    'persistez',
    'personnel',
    'personnes',
    'persuadai',
    'persuadas',
    'persuadât',
    'persuadée',
    'persuader',
    'persuades',
    'persuadés',
    'persuadez',
    'persuasif',
    'pertinent',
    'perturbai',
    'perturbas',
    'perturbât',
    'perturbée',
    'perturber',
    'perturbes',
    'perturbés',
    'perturbez',
    'péruviens',
    'pervenche',
    'perverses',
    'pervertie',
    'pervertir',
    'pervertis',
    'pervertit',
    'pervertît',
    'pervibrai',
    'pervibras',
    'pervibrât',
    'pervibrée',
    'pervibrer',
    'pervibres',
    'pervibrés',
    'pervibrez',
    'pesamment',
    'pesanteur',
    'pesassent',
    'pesassiez',
    'pèse-bébé',
    'pèse-lait',
    'pèse-moût',
    'pèserions',
    'pèse-sels',
    'pessaires',
    'pestaient',
    'pestasses',
    'pesterais',
    'pesterait',
    'pestèrent',
    'pesteriez',
    'pesterons',
    'pesteront',
    'pesteuses',
    'pesticide',
    'pestiféra',
    'pestiféré',
    'pestifère',
    'pétalisme',
    'pétaloïde',
    'pétanques',
    'pétaradai',
    'pétaradas',
    'pétaradât',
    'pétarader',
    'pétarades',
    'pétaradez',
    'pétardais',
    'pétardait',
    'pétardant',
    'pétardées',
    'pétardent',
    'pétardera',
    'pétardiez',
    'pétardons',
    'pétassent',
    'pétassiez',
    'pétéchial',
    'pétéchies',
    'péterions',
    'pétillais',
    'pétillait',
    'pétillant',
    'pétillent',
    'pétillera',
    'pétilliez',
    'pétillons',
    'pétiolées',
    'petitesse',
    'pétitions',
    'pétitoire',
    'pétoncles',
    'pétouilla',
    'pétouille',
    'pétouillé',
    'pétreuses',
    'pétrifiai',
    'pétrifias',
    'pétrifiât',
    'pétrifiée',
    'pétrifier',
    'pétrifies',
    'pétrifiés',
    'pétrifiez',
    'pétrirais',
    'pétrirait',
    'pétrirent',
    'pétririez',
    'pétrirons',
    'pétriront',
    'pétrisses',
    'pétrissez',
    'pétrogale',
    'pétrolier',
    'pétulance',
    'pétulante',
    'pétulants',
    'pétunâmes',
    'pétunasse',
    'pétunâtes',
    'pétunerai',
    'pétuneras',
    'pétunerez',
    'pétunions',
    'peucédans',
    'peuplades',
    'peuplâmes',
    'peuplasse',
    'peuplâtes',
    'peuplerai',
    'peupleras',
    'peuplerez',
    'peupliers',
    'peuplions',
    'peureuses',
    'peut-elle',
    'peut-être',
    'phagocyta',
    'phagocyte',
    'phagocyté',
    'phalanger',
    'phalanges',
    'phallines',
    'phallique',
    'phalloïde',
    'phantasme',
    'pharillon',
    'pharisien',
    'pharmacie',
    'pharyngal',
    'pharyngée',
    'pharyngés',
    'phénicien',
    'phéniquée',
    'phéniques',
    'phéniqués',
    'phénomène',
    'phénotype',
    'philippin',
    'philistin',
    'phlébites',
    'phlegmons',
    'phlyctène',
    'phobiques',
    'phocéenne',
    'pholiotes',
    'phonateur',
    'phonation',
    'phoniques',
    'phonolite',
    'phormiums',
    'phosgènes',
    'phosphata',
    'phosphate',
    'phosphaté',
    'phosphène',
    'phosphite',
    'phosphora',
    'phosphore',
    'phosphoré',
    'photogène',
    'photolyse',
    'photopile',
    'photostat',
    'phototype',
    'phragmite',
    'phrasâmes',
    'phrasasse',
    'phrasâtes',
    'phraserai',
    'phraseras',
    'phraserez',
    'phraseurs',
    'phraseuse',
    'phrasions',
    'phratries',
    'phrénique',
    'phryganes',
    'phrygiens',
    'phtaléine',
    'phtalique',
    'phtiriase',
    'phtisique',
    'phylarque',
    'phyllades',
    'physalies',
    'physicien',
    'physiques',
    'phytotron',
    'piaffâmes',
    'piaffante',
    'piaffants',
    'piaffasse',
    'piaffâtes',
    'piafferai',
    'piafferas',
    'piafferez',
    'piaffeurs',
    'piaffeuse',
    'piaffions',
    'piaillais',
    'piaillait',
    'piaillant',
    'piaillard',
    'piaillent',
    'piaillera',
    'piailleur',
    'piailliez',
    'piaillons',
    'pianistes',
    'pianotage',
    'pianotais',
    'pianotait',
    'pianotant',
    'pianotées',
    'pianotent',
    'pianotera',
    'pianotiez',
    'pianotons',
    'piassavas',
    'piaulâmes',
    'piaulasse',
    'piaulâtes',
    'piaulerai',
    'piauleras',
    'piaulerez',
    'piaulions',
    'picardans',
    'picholine',
    'picolâmes',
    'picolasse',
    'picolâtes',
    'picolerai',
    'picoleras',
    'picolerez',
    'picoleurs',
    'picoleuse',
    'picolions',
    'picorâmes',
    'picorasse',
    'picorâtes',
    'picorerai',
    'picoreras',
    'picorerez',
    'picorions',
    'picotâmes',
    'picotasse',
    'picotâtes',
    'picoterai',
    'picoteras',
    'picoterez',
    'picotions',
    'picridium',
    'picriques',
    'picturale',
    'picturaux',
    'piécettes',
    'piédestal',
    'pied-fort',
    'pied-noir',
    'piédouche',
    'pied-plat',
    'piédroits',
    'piégeages',
    'piégeâmes',
    'piégeasse',
    'piégeâtes',
    'piégerais',
    'piégerait',
    'piégèrent',
    'piégeriez',
    'piégerons',
    'piégeront',
    'pierrâmes',
    'pierrasse',
    'pierrâtes',
    'pierrerai',
    'pierreras',
    'pierrerez',
    'pierreuse',
    'pierriers',
    'pierrions',
    'piétaient',
    'piétaille',
    'piétasses',
    'piètement',
    'piéterais',
    'piéterait',
    'piétèrent',
    'piéteriez',
    'piéterons',
    'piéteront',
    'piétinais',
    'piétinait',
    'piétinant',
    'piétinées',
    'piétinent',
    'piétinera',
    'piétiniez',
    'piétinons',
    'piétismes',
    'piétistes',
    'piétonnes',
    'pieutâmes',
    'pieutasse',
    'pieutâtes',
    'pieuterai',
    'pieuteras',
    'pieuterez',
    'pieutions',
    'pifassent',
    'pifassiez',
    'piferions',
    'piffaient',
    'piffasses',
    'pifferais',
    'pifferait',
    'piffèrent',
    'pifferiez',
    'pifferons',
    'pifferont',
    'pifomètre',
    'pigeaient',
    'pigeasses',
    'pigeonnai',
    'pigeonnas',
    'pigeonnât',
    'pigeonnée',
    'pigeonner',
    'pigeonnes',
    'pigeonnés',
    'pigeonnez',
    'pigerions',
    'pigmentai',
    'pigmentas',
    'pigmentât',
    'pigmentée',
    'pigmenter',
    'pigmentes',
    'pigmentés',
    'pigmentez',
    'pignochai',
    'pignochas',
    'pignochât',
    'pignochée',
    'pignocher',
    'pignoches',
    'pignochés',
    'pignochez',
    'pilassent',
    'pilassiez',
    'pilastres',
    'pilchards',
    'pilerions',
    'pilifères',
    'pillaient',
    'pillardes',
    'pillasses',
    'pillerais',
    'pillerait',
    'pillèrent',
    'pilleriez',
    'pillerons',
    'pilleront',
    'pilleuses',
    'pilocarpe',
    'pilonnage',
    'pilonnais',
    'pilonnait',
    'pilonnant',
    'pilonnées',
    'pilonnent',
    'pilonnera',
    'pilonniez',
    'pilonnons',
    'piloselle',
    'pilosisme',
    'pilosités',
    'pilotages',
    'pilotâmes',
    'pilotasse',
    'pilotâtes',
    'piloterai',
    'piloteras',
    'piloterez',
    'pilotions',
    'pilulaire',
    'piluliers',
    'pimbêches',
    'pimentais',
    'pimentait',
    'pimentant',
    'pimentées',
    'pimentent',
    'pimentera',
    'pimentiez',
    'pimentons',
    'pimpantes',
    'pinaillai',
    'pinaillas',
    'pinaillât',
    'pinailler',
    'pinailles',
    'pinaillez',
    'pinardier',
    'pinastres',
    'pinçaient',
    'pinçasses',
    'pincelier',
    'pincement',
    'pince-nez',
    'pincerais',
    'pincerait',
    'pincèrent',
    'pinceriez',
    'pincerons',
    'pinceront',
    'pincettes',
    'pincharde',
    'pinchards',
    'pindarisa',
    'pindarise',
    'pindarisé',
    'pineraies',
    'pingouins',
    'ping-pong',
    'pingrerie',
    'pintadeau',
    'pintadine',
    'pintaient',
    'pintasses',
    'pinterais',
    'pinterait',
    'pintèrent',
    'pinteriez',
    'pinterons',
    'pinteront',
    'piochages',
    'piochâmes',
    'piochasse',
    'piochâtes',
    'piocherai',
    'piocheras',
    'piocherez',
    'piocheurs',
    'piocheuse',
    'piochions',
    'pionçâmes',
    'pionçasse',
    'pionçâtes',
    'pioncerai',
    'pionceras',
    'pioncerez',
    'pioncions',
    'pionnâmes',
    'pionnasse',
    'pionnâtes',
    'pionnerai',
    'pionneras',
    'pionnerez',
    'pionnière',
    'pionniers',
    'pionnions',
    'pioupious',
    'pipassent',
    'pipassiez',
    'pipelette',
    'pipe-line',
    'pipelines',
    'piperades',
    'pipérines',
    'piperions',
    'pipéronal',
    'piquaient',
    'piquantes',
    'piquasses',
    'pique-feu',
    'piquerais',
    'piquerait',
    'piquèrent',
    'piqueriez',
    'piquerons',
    'piqueront',
    'piquetage',
    'piquetais',
    'piquetait',
    'piquetant',
    'piquetées',
    'piquetiez',
    'piquetons',
    'piquettes',
    'piqueuses',
    'piratages',
    'piratâmes',
    'piratasse',
    'piratâtes',
    'piraterai',
    'pirateras',
    'piraterez',
    'piraterie',
    'pirations',
    'piriforme',
    'piroguier',
    'pirouetta',
    'pirouette',
    'pirouetté',
    'pis-aller',
    'piscicole',
    'piscivore',
    'pissaient',
    'pissasses',
    'pissenlit',
    'pisserais',
    'pisserait',
    'pissèrent',
    'pisseriez',
    'pisserons',
    'pisseront',
    'pissettes',
    'pisseuses',
    'pistachai',
    'pistachas',
    'pistachât',
    'pistachée',
    'pistacher',
    'pistaches',
    'pistachés',
    'pistachez',
    'pistaient',
    'pistasses',
    'pisterais',
    'pisterait',
    'pistèrent',
    'pisteriez',
    'pisterons',
    'pisteront',
    'pistolets',
    'pistonnai',
    'pistonnas',
    'pistonnât',
    'pistonnée',
    'pistonner',
    'pistonnes',
    'pistonnés',
    'pistonnez',
    'pitchpins',
    'pitonnage',
    'pitonnais',
    'pitonnait',
    'pitonnant',
    'pitonnent',
    'pitonnera',
    'pitonniez',
    'pitonnons',
    'pitoyable',
    'pitreries',
    'pivotâmes',
    'pivotante',
    'pivotants',
    'pivotasse',
    'pivotâtes',
    'pivoterai',
    'pivoteras',
    'pivoterez',
    'pivotions',
    'pizzerias',
    'pizzicati',
    'pizzicato',
    'plaçaient',
    'placardai',
    'placardas',
    'placardât',
    'placardée',
    'placarder',
    'placardes',
    'placardés',
    'placardez',
    'plaçasses',
    'placement',
    'placentas',
    'placerais',
    'placerait',
    'placèrent',
    'placeriez',
    'placerons',
    'placeront',
    'placettes',
    'placeuses',
    'placidité',
    'plafonnai',
    'plafonnas',
    'plafonnât',
    'plafonnée',
    'plafonner',
    'plafonnes',
    'plafonnés',
    'plafonnez',
    'plagiaire',
    'plagiâmes',
    'plagiasse',
    'plagiâtes',
    'plagierai',
    'plagieras',
    'plagierez',
    'plagiions',
    'plagistes',
    'plaidâmes',
    'plaidante',
    'plaidants',
    'plaidasse',
    'plaidâtes',
    'plaiderai',
    'plaideras',
    'plaiderez',
    'plaideurs',
    'plaideuse',
    'plaidions',
    'plaidoyer',
    'plaignais',
    'plaignait',
    'plaignant',
    'plaignent',
    'plaigniez',
    'plaignons',
    'plainâmes',
    'plainasse',
    'plainâtes',
    'plaindrai',
    'plaindras',
    'plaindrez',
    'plainerai',
    'plaineras',
    'plainerez',
    'plainions',
    'plaintifs',
    'plaintive',
    'plairions',
    'plaisance',
    'plaisanta',
    'plaisante',
    'plaisanté',
    'plaisants',
    'plaisions',
    'planaient',
    'planaires',
    'planasses',
    'planchais',
    'planchait',
    'planchant',
    'planchéia',
    'planchéie',
    'planchéié',
    'planchent',
    'planchera',
    'planchers',
    'planchiez',
    'planchons',
    'planctons',
    'planéités',
    'planerais',
    'planerait',
    'planèrent',
    'planeriez',
    'planerons',
    'planeront',
    'planeuses',
    'planifiai',
    'planifias',
    'planifiât',
    'planifiée',
    'planifier',
    'planifies',
    'planifiés',
    'planifiez',
    'planismes',
    'plannings',
    'planorbes',
    'planquais',
    'planquait',
    'planquant',
    'planquées',
    'planquent',
    'planquera',
    'planquiez',
    'planquons',
    'plantages',
    'plantains',
    'plantaire',
    'plantâmes',
    'plantasse',
    'plantâtes',
    'planterai',
    'planteras',
    'planterez',
    'planteurs',
    'planteuse',
    'plantions',
    'plantoirs',
    'plantules',
    'plaquages',
    'plaquâmes',
    'plaquasse',
    'plaquâtes',
    'plaquerai',
    'plaqueras',
    'plaquerez',
    'plaquette',
    'plaqueurs',
    'plaqueuse',
    'plaquions',
    'plasmifia',
    'plasmifie',
    'plasmifié',
    'plasmodes',
    'plastifia',
    'plastifie',
    'plastifié',
    'plastigel',
    'plastiqua',
    'plastique',
    'plastiqué',
    'plastisol',
    'plastrons',
    'platelage',
    'platement',
    'platières',
    'platinage',
    'platinais',
    'platinait',
    'platinant',
    'platinées',
    'platinent',
    'platinera',
    'platiniez',
    'platinisa',
    'platinise',
    'platinisé',
    'platinons',
    'platitude',
    'plâtrages',
    'plâtrâmes',
    'plâtrasse',
    'plâtrâtes',
    'plâtrerai',
    'plâtreras',
    'plâtrerez',
    'plâtrerie',
    'plâtreuse',
    'plâtrière',
    'plâtriers',
    'plâtrions',
    'plausible',
    'play-back',
    'play-boys',
    'plébéiens',
    'plénières',
    'plénitude',
    'pléonasme',
    'pléthores',
    'pleurages',
    'pleurales',
    'pleurâmes',
    'pleurante',
    'pleurants',
    'pleurarde',
    'pleurards',
    'pleurasse',
    'pleurâtes',
    'pleurerai',
    'pleureras',
    'pleurerez',
    'pleurésie',
    'pleureurs',
    'pleureuse',
    'pleurions',
    'pleurites',
    'pleurotes',
    'pleuvassa',
    'pleuvasse',
    'pleuvassé',
    'pleuvinât',
    'pleuviner',
    'pleuvotât',
    'pleuvoter',
    'pleuvrait',
    'pleuvront',
    'plexiglas',
    'pliassent',
    'pliassiez',
    'pliements',
    'plierions',
    'pliocènes',
    'plissages',
    'plissâmes',
    'plissasse',
    'plissâtes',
    'plisserai',
    'plisseras',
    'plisserez',
    'plisseurs',
    'plisseuse',
    'plissions',
    'plissures',
    'ploiement',
    'ploierais',
    'ploierait',
    'ploieriez',
    'ploierons',
    'ploieront',
    'plombages',
    'plombâmes',
    'plombasse',
    'plombâtes',
    'plomberai',
    'plomberas',
    'plomberez',
    'plomberie',
    'plombeurs',
    'plombiers',
    'plombions',
    'plomboirs',
    'plombures',
    'plongeais',
    'plongeait',
    'plongeant',
    'plongeoir',
    'plongeons',
    'plongerai',
    'plongeras',
    'plongerez',
    'plongeurs',
    'plongeuse',
    'plongions',
    'ploquâmes',
    'ploquasse',
    'ploquâtes',
    'ploquerai',
    'ploqueras',
    'ploquerez',
    'ploquions',
    'ployables',
    'ployaient',
    'ployasses',
    'ployèrent',
    'pluchâmes',
    'pluchasse',
    'pluchâtes',
    'plucherai',
    'plucheras',
    'plucherez',
    'plucheuse',
    'pluchions',
    'plumaient',
    'plumaison',
    'plumasses',
    'plumerais',
    'plumerait',
    'plumèrent',
    'plumeriez',
    'plumerons',
    'plumeront',
    'plumetées',
    'plumeuses',
    'plumitifs',
    'pluralité',
    'plurielle',
    'plusieurs',
    'plussions',
    'plutonien',
    'plutonium',
    'pluviales',
    'pluvieuse',
    'pluvinait',
    'pluvinera',
    'pneumonie',
    'pochaient',
    'pochardes',
    'pochasses',
    'pocherais',
    'pocherait',
    'pochèrent',
    'pocheriez',
    'pocherons',
    'pocheront',
    'pochetées',
    'pochettes',
    'pocheuses',
    'pochouses',
    'podestats',
    'podologie',
    'podologue',
    'poêlaient',
    'poêlasses',
    'poêlerais',
    'poêlerait',
    'poêlèrent',
    'poêleriez',
    'poêlerons',
    'poêleront',
    'poétereau',
    'poétesses',
    'poétiques',
    'poétisais',
    'poétisait',
    'poétisant',
    'poétisées',
    'poétisent',
    'poétisera',
    'poétisiez',
    'poétisons',
    'poignante',
    'poignants',
    'poignarda',
    'poignarde',
    'poignardé',
    'poignards',
    'poignîmes',
    'poignions',
    'poignisse',
    'poignîtes',
    'poilaient',
    'poilantes',
    'poilasses',
    'poilerais',
    'poilerait',
    'poilèrent',
    'poileriez',
    'poilerons',
    'poileront',
    'poinçonna',
    'poinçonne',
    'poinçonné',
    'poindrais',
    'poindrait',
    'poindriez',
    'poindrons',
    'poindront',
    'pointages',
    'pointâmes',
    'pointasse',
    'pointâtes',
    'pointeaux',
    'pointerai',
    'pointeras',
    'pointerez',
    'pointeurs',
    'pointeuse',
    'pointilla',
    'pointille',
    'pointillé',
    'pointions',
    'pointures',
    'poireauta',
    'poireaute',
    'poireauté',
    'poirotais',
    'poirotait',
    'poirotant',
    'poirotent',
    'poirotera',
    'poirotiez',
    'poirotons',
    'poissâmes',
    'poissarde',
    'poissards',
    'poissasse',
    'poissâtes',
    'poisserai',
    'poisseras',
    'poisserez',
    'poisseuse',
    'poissions',
    'poitevine',
    'poitevins',
    'poitrails',
    'poitrines',
    'poivrades',
    'poivrâmes',
    'poivrasse',
    'poivrâtes',
    'poivrerai',
    'poivreras',
    'poivrerez',
    'poivrière',
    'poivriers',
    'poivrions',
    'poivrotes',
    'polarisai',
    'polarisas',
    'polarisât',
    'polarisée',
    'polariser',
    'polarises',
    'polarisés',
    'polarisez',
    'polarités',
    'Polaroïds',
    'polémiqua',
    'polémique',
    'polémiqué',
    'polémiste',
    'poliçâmes',
    'poliçasse',
    'poliçâtes',
    'policeman',
    'policemen',
    'policerai',
    'policeras',
    'policerez',
    'policière',
    'policiers',
    'policions',
    'polirions',
    'polissage',
    'polissais',
    'polissait',
    'polissant',
    'polissent',
    'polisseur',
    'polissiez',
    'polissoir',
    'polissons',
    'politesse',
    'politiqua',
    'politique',
    'politiqué',
    'politisai',
    'politisas',
    'politisât',
    'politisée',
    'politiser',
    'politises',
    'politisés',
    'politisez',
    'polluâmes',
    'polluante',
    'polluants',
    'polluasse',
    'polluâtes',
    'polluerai',
    'pollueras',
    'polluerez',
    'pollueurs',
    'pollueuse',
    'polluions',
    'pollution',
    'polochons',
    'polonaise',
    'poloniums',
    'poltronne',
    'polyacide',
    'polyakène',
    'polyamide',
    'polyamine',
    'polyandre',
    'polycopia',
    'polycopie',
    'polycopié',
    'polyèdres',
    'polyester',
    'polygalas',
    'polygales',
    'polygames',
    'polygamie',
    'polygonal',
    'polygones',
    'polynômes',
    'polyoside',
    'polypeuse',
    'polyphasé',
    'polypiers',
    'polypodes',
    'polypores',
    'polyptère',
    'polysémie',
    'polystyle',
    'polythène',
    'polytonal',
    'polytrics',
    'polyuries',
    'pommadais',
    'pommadait',
    'pommadant',
    'pommadées',
    'pommadent',
    'pommadera',
    'pommadiez',
    'pommadons',
    'pommaient',
    'pommasses',
    'pommelais',
    'pommelait',
    'pommelant',
    'pommelées',
    'pommeliez',
    'pommelles',
    'pommelons',
    'pommèrent',
    'pommetées',
    'pommettes',
    'pomoerium',
    'pomologue',
    'pompaient',
    'pompasses',
    'pompéiens',
    'pomperais',
    'pomperait',
    'pompèrent',
    'pomperiez',
    'pomperons',
    'pomperont',
    'pompettes',
    'pompeuses',
    'pompières',
    'pompistes',
    'pomponnai',
    'pomponnas',
    'pomponnât',
    'pomponnée',
    'pomponner',
    'pomponnes',
    'pomponnés',
    'pomponnez',
    'ponantais',
    'ponçaient',
    'ponçasses',
    'poncelets',
    'poncerais',
    'poncerait',
    'poncèrent',
    'ponceriez',
    'poncerons',
    'ponceront',
    'ponceuses',
    'ponctions',
    'ponctuais',
    'ponctuait',
    'ponctuant',
    'ponctuées',
    'ponctuels',
    'ponctuent',
    'ponctuera',
    'ponctuiez',
    'ponctuons',
    'pondaient',
    'pondaison',
    'pondérais',
    'pondérait',
    'pondérale',
    'pondérant',
    'pondéraux',
    'pondérées',
    'pondèrent',
    'pondérera',
    'pondéreux',
    'pondériez',
    'pondérons',
    'pondeuses',
    'pondirent',
    'pondisses',
    'pondrions',
    'pongistes',
    'pontaient',
    'pontasses',
    'ponterais',
    'ponterait',
    'pontèrent',
    'ponteriez',
    'ponterons',
    'ponteront',
    'pontifiai',
    'pontifias',
    'pontifiât',
    'pontifier',
    'pontifies',
    'pontifiez',
    'pontillai',
    'pontillas',
    'pontillât',
    'pontillée',
    'pontiller',
    'pontilles',
    'pontillés',
    'pontillez',
    'pontuseau',
    'popelines',
    'poplitées',
    'populaces',
    'populages',
    'populaire',
    'populéums',
    'populeuse',
    'populisme',
    'populiste',
    'poquaient',
    'poquasses',
    'poquerais',
    'poquerait',
    'poquèrent',
    'poqueriez',
    'poquerons',
    'poqueront',
    'porcelets',
    'porc-épic',
    'porchères',
    'porcherie',
    'porosités',
    'porphyres',
    'porridges',
    'portables',
    'portaient',
    'portances',
    'portantes',
    'portasses',
    'portatifs',
    'portative',
    'portefaix',
    'portement',
    'portemine',
    'porterais',
    'porterait',
    'portèrent',
    'porteries',
    'porteriez',
    'porterons',
    'porteront',
    'porteuses',
    'portières',
    'portillon',
    'portiques',
    'portlands',
    'portraits',
    'portuaire',
    'portugais',
    'portulans',
    'posassent',
    'posassiez',
    'posemètre',
    'poserions',
    'positions',
    'positives',
    'positrons',
    'posologie',
    'possédais',
    'possédait',
    'possédant',
    'possédées',
    'possèdent',
    'possédera',
    'possédiez',
    'possédons',
    'possessif',
    'possibles',
    'postaient',
    'postasses',
    'postcures',
    'postdatai',
    'postdatas',
    'postdatât',
    'postdatée',
    'postdater',
    'postdates',
    'postdatés',
    'postdatez',
    'posterais',
    'posterait',
    'postèrent',
    'posteriez',
    'postérité',
    'posterons',
    'posteront',
    'postfaces',
    'posthites',
    'posthumes',
    'postichai',
    'postichas',
    'postichât',
    'posticher',
    'postiches',
    'postichez',
    'postières',
    'postillon',
    'postnatal',
    'postposai',
    'postposas',
    'postposât',
    'postposée',
    'postposer',
    'postposes',
    'postposés',
    'postposez',
    'postulais',
    'postulait',
    'postulant',
    'postulats',
    'postulées',
    'postulent',
    'postulera',
    'postuliez',
    'postulons',
    'posturale',
    'posturaux',
    'potagères',
    'potassais',
    'potassait',
    'potassant',
    'potassées',
    'potassent',
    'potassera',
    'potasseur',
    'potassiez',
    'potassium',
    'potassons',
    'potencées',
    'potentats',
    'potentiel',
    'potinâmes',
    'potinasse',
    'potinâtes',
    'potinerai',
    'potineras',
    'potinerez',
    'potinière',
    'potiniers',
    'potinions',
    'potomètre',
    'poubelles',
    'poucettes',
    'poudingue',
    'poudrages',
    'poudrâmes',
    'poudrasse',
    'poudrâtes',
    'poudrerai',
    'poudreras',
    'poudrerez',
    'poudrerie',
    'poudrette',
    'poudreuse',
    'poudrière',
    'poudriers',
    'poudrions',
    'poudroies',
    'poudroyai',
    'poudroyas',
    'poudroyât',
    'poudroyer',
    'poudroyez',
    'pouffâmes',
    'pouffasse',
    'pouffâtes',
    'poufferai',
    'poufferas',
    'poufferez',
    'pouffions',
    'pouillard',
    'pouilleux',
    'pouillots',
    'poulaines',
    'poulardes',
    'poulettes',
    'pouliches',
    'poulinais',
    'poulinait',
    'poulinant',
    'poulinent',
    'poulinera',
    'pouliniez',
    'poulinons',
    'pouponnai',
    'pouponnas',
    'pouponnât',
    'pouponner',
    'pouponnes',
    'pouponnez',
    'pourboire',
    'pourceaux',
    'pour-cent',
    'pourfende',
    'pourfends',
    'pourfendu',
    'pourlécha',
    'pourléché',
    'pourlèche',
    'pourpiers',
    'pourpoint',
    'pourprais',
    'pourprait',
    'pourprant',
    'pourprées',
    'pourprent',
    'pourprera',
    'pourpriez',
    'pourprons',
    'pourridié',
    'pourrîmes',
    'pourrions',
    'pourrirai',
    'pourriras',
    'pourrirez',
    'pourrisse',
    'pourrîtes',
    'poursuite',
    'poursuive',
    'poursuivi',
    'pourtours',
    'pourvoies',
    'pourvoira',
    'pourvoyez',
    'pourvûmes',
    'pourvusse',
    'pourvûtes',
    'poussages',
    'poussâmes',
    'poussasse',
    'poussâtes',
    'pousserai',
    'pousseras',
    'pousserez',
    'poussette',
    'pousseurs',
    'poussière',
    'poussiers',
    'poussines',
    'poussions',
    'poussives',
    'poussoirs',
    'poutargue',
    'poutrelle',
    'poutsâmes',
    'poutsasse',
    'poutsâtes',
    'poutserai',
    'poutseras',
    'poutserez',
    'poutsions',
    'pouvaient',
    'prairials',
    'pralinage',
    'pralinais',
    'pralinait',
    'pralinant',
    'pralinées',
    'pralinent',
    'pralinera',
    'praliniez',
    'pralinons',
    'praticien',
    'pratiquai',
    'pratiquas',
    'pratiquât',
    'pratiquée',
    'pratiquer',
    'pratiques',
    'pratiqués',
    'pratiquez',
    'préacheta',
    'préacheté',
    'préachète',
    'préalable',
    'préalpine',
    'préalpins',
    'préambule',
    'préavisai',
    'préavisas',
    'préavisât',
    'préavisée',
    'préaviser',
    'préavises',
    'préavisés',
    'préavisez',
    'prébendes',
    'prébendés',
    'précaires',
    'précarisa',
    'précarise',
    'précarisé',
    'précarité',
    'précédais',
    'précédait',
    'précédant',
    'précédées',
    'précédent',
    'précèdent',
    'précédera',
    'précédiez',
    'précédons',
    'préceinte',
    'préceptes',
    'prêchâmes',
    'prêchasse',
    'prêchâtes',
    'prêcherai',
    'prêcheras',
    'prêcherez',
    'prêcheurs',
    'prêcheuse',
    'prêchions',
    'précieuse',
    'précipice',
    'précipita',
    'précipite',
    'précipité',
    'préciputs',
    'précisais',
    'précisait',
    'précisant',
    'précisées',
    'précisent',
    'précisera',
    'précisiez',
    'précision',
    'précisons',
    'précitées',
    'précocité',
    'précompta',
    'précompte',
    'précompté',
    'préconçue',
    'préconçus',
    'préconisa',
    'préconise',
    'préconisé',
    'précuites',
    'prédateur',
    'prédation',
    'prédéfini',
    'prédicant',
    'prédicats',
    'prédigéré',
    'prédiquai',
    'prédiquas',
    'prédiquât',
    'prédiquée',
    'prédiquer',
    'prédiques',
    'prédiqués',
    'prédiquez',
    'prédirais',
    'prédirait',
    'prédirent',
    'prédiriez',
    'prédirons',
    'prédiront',
    'prédisais',
    'prédisait',
    'prédisant',
    'prédisent',
    'prédisiez',
    'prédisons',
    'prédisses',
    'prédomina',
    'prédomine',
    'prédominé',
    'préembalé',
    'préemptai',
    'préemptas',
    'préemptât',
    'préemptée',
    'préempter',
    'préemptes',
    'préemptés',
    'préemptez',
    'préétabli',
    'préexista',
    'préexiste',
    'préexisté',
    'préfaçais',
    'préfaçait',
    'préfaçant',
    'préfacées',
    'préfacent',
    'préfacera',
    'préfacier',
    'préfaciez',
    'préfaçons',
    'préférais',
    'préférait',
    'préférant',
    'préférées',
    'préfèrent',
    'préférera',
    'préfériez',
    'préférons',
    'préfigura',
    'préfigure',
    'préfiguré',
    'préfixais',
    'préfixait',
    'préfixant',
    'préfixées',
    'préfixent',
    'préfixera',
    'préfixiez',
    'préfixion',
    'préfixons',
    'préformai',
    'préformas',
    'préformât',
    'préformée',
    'préformer',
    'préformes',
    'préformés',
    'préformez',
    'prégnance',
    'prégnante',
    'prégnants',
    'préjudice',
    'préjugeai',
    'préjugeas',
    'préjugeât',
    'préjugées',
    'préjugent',
    'préjugera',
    'préjugiez',
    'prélassai',
    'prélassas',
    'prélassât',
    'prélassée',
    'prélasser',
    'prélasses',
    'prélassés',
    'prélassez',
    'prélatine',
    'prélatins',
    'prélature',
    'prélavage',
    'prélevais',
    'prélevait',
    'prélevant',
    'prélevées',
    'prélèvent',
    'prélèvera',
    'préleviez',
    'prélevons',
    'préludais',
    'préludait',
    'préludant',
    'préludent',
    'préludera',
    'préludiez',
    'préludons',
    'prématuré',
    'prémédita',
    'prémédite',
    'prémédité',
    'premières',
    'prémisses',
    'prémontré',
    'prémunies',
    'prémunira',
    'prenables',
    'prenaient',
    'prenantes',
    'prénatale',
    'prénataux',
    'prendrais',
    'prendrait',
    'prendriez',
    'prendrons',
    'prendront',
    'prénommai',
    'prénommas',
    'prénommât',
    'prénommée',
    'prénommer',
    'prénommes',
    'prénommés',
    'prénommez',
    'préoccupa',
    'préoccupe',
    'préoccupé',
    'préorales',
    'prépaient',
    'prépaiera',
    'préparais',
    'préparait',
    'préparant',
    'préparées',
    'préparent',
    'préparera',
    'prépariez',
    'préparons',
    'prépayais',
    'prépayait',
    'prépayant',
    'prépayées',
    'prépayent',
    'prépayera',
    'prépayiez',
    'prépayons',
    'préposais',
    'préposait',
    'préposant',
    'préposées',
    'préposent',
    'préposera',
    'préposiez',
    'préposons',
    'préréglai',
    'préréglas',
    'préréglât',
    'préréglée',
    'prérégler',
    'préréglés',
    'prérègles',
    'préréglez',
    'présageai',
    'présageas',
    'présageât',
    'présagées',
    'présagent',
    'présagera',
    'présagiez',
    'presbytes',
    'presbytie',
    'prescient',
    'prescrira',
    'prescrire',
    'prescrite',
    'prescrits',
    'prescrive',
    'préséance',
    'présences',
    'présentai',
    'présentas',
    'présentât',
    'présentée',
    'présenter',
    'présentes',
    'présentés',
    'présentez',
    'préséries',
    'préservai',
    'préservas',
    'préservât',
    'préservée',
    'préserver',
    'préserves',
    'préservés',
    'préservez',
    'présidais',
    'présidait',
    'présidant',
    'présidées',
    'président',
    'présidera',
    'présidial',
    'présidiez',
    'présidium',
    'présidons',
    'pressages',
    'pressâmes',
    'pressante',
    'pressants',
    'pressasse',
    'pressâtes',
    'pressente',
    'pressenti',
    'presserai',
    'presseras',
    'presserez',
    'presseurs',
    'presseuse',
    'pressiers',
    'pressings',
    'pressions',
    'pressoirs',
    'pressurai',
    'pressuras',
    'pressurât',
    'pressurée',
    'pressurer',
    'pressures',
    'pressurés',
    'pressurez',
    'prestance',
    'prestants',
    'prestesse',
    'prestiges',
    'présumais',
    'présumait',
    'présumant',
    'présumées',
    'présument',
    'présumera',
    'présumiez',
    'présumons',
    'présurais',
    'présurait',
    'présurant',
    'présurées',
    'présurent',
    'présurera',
    'présuriez',
    'présurons',
    'prêtaient',
    'prêtasses',
    'prêt-bail',
    'prétendes',
    'prétendez',
    'prétendis',
    'prétendit',
    'prétendît',
    'prétendra',
    'prétendre',
    'prétendue',
    'prétendus',
    'prête-nom',
    'prêterais',
    'prêterait',
    'prêtèrent',
    'prêteriez',
    'prétérits',
    'prêterons',
    'prêteront',
    'préteuses',
    'prêteuses',
    'prétextai',
    'prétextas',
    'prétextât',
    'prétextée',
    'prétexter',
    'prétextes',
    'prétextés',
    'prétextez',
    'prétoires',
    'prétorial',
    'prétorien',
    'prêtresse',
    'prêtrises',
    'prévalais',
    'prévalait',
    'prévalant',
    'prévalent',
    'prévaliez',
    'prévaloir',
    'prévalons',
    'prévalues',
    'prévaudra',
    'prévenais',
    'prévenait',
    'prévenant',
    'préveniez',
    'prévenons',
    'préventif',
    'prévenues',
    'préverbes',
    'prévienne',
    'prévînmes',
    'prévinsse',
    'prévîntes',
    'prévirent',
    'prévision',
    'prévisses',
    'prévoient',
    'prévoirai',
    'prévoiras',
    'prévoirez',
    'prévôtale',
    'prévôtaux',
    'prévoyais',
    'prévoyait',
    'prévoyant',
    'prévoyiez',
    'prévoyons',
    'priapisme',
    'priassent',
    'priassiez',
    'prie-Dieu',
    'prierions',
    'primaient',
    'primaires',
    'primarisa',
    'primarise',
    'primarisé',
    'primarité',
    'primasses',
    'primatial',
    'primaties',
    'primautés',
    'primerais',
    'primerait',
    'primèrent',
    'primeriez',
    'primerons',
    'primeront',
    'primerose',
    'primevère',
    'primipare',
    'primipile',
    'primitifs',
    'primitive',
    'princesse',
    'princière',
    'princiers',
    'principal',
    'principat',
    'principes',
    'printemps',
    'priodonte',
    'priorités',
    'prisaient',
    'prisasses',
    'priserais',
    'priserait',
    'prisèrent',
    'priseriez',
    'priserons',
    'priseront',
    'priseuses',
    'prissions',
    'privaient',
    'privasses',
    'privatifs',
    'privation',
    'privatisa',
    'privatise',
    'privatisé',
    'privative',
    'privautés',
    'priverais',
    'priverait',
    'privèrent',
    'priveriez',
    'priverons',
    'priveront',
    'privilège',
    'probables',
    'probantes',
    'probation',
    'problèmes',
    'procédais',
    'procédait',
    'procédant',
    'procèdent',
    'procédera',
    'procédiez',
    'procédons',
    'procédure',
    'processif',
    'processus',
    'prochaine',
    'prochains',
    'proclamai',
    'proclamas',
    'proclamât',
    'proclamée',
    'proclamer',
    'proclames',
    'proclamés',
    'proclamez',
    'proconsul',
    'procréais',
    'procréait',
    'procréant',
    'procréées',
    'procréent',
    'procréera',
    'procréiez',
    'procréons',
    'proctites',
    'procurais',
    'procurait',
    'procurant',
    'procurées',
    'procurent',
    'procurera',
    'procureur',
    'procuriez',
    'procurons',
    'prodiguai',
    'prodiguas',
    'prodiguât',
    'prodiguée',
    'prodiguer',
    'prodigues',
    'prodigués',
    'prodiguez',
    'prodromes',
    'productif',
    'produirai',
    'produiras',
    'produirez',
    'produises',
    'produisez',
    'produisis',
    'produisit',
    'produisît',
    'produites',
    'profanais',
    'profanait',
    'profanant',
    'profanées',
    'profanent',
    'profanera',
    'profaniez',
    'profanons',
    'proférais',
    'proférait',
    'proférant',
    'proférées',
    'profèrent',
    'proférera',
    'profériez',
    'proférons',
    'professai',
    'professas',
    'professât',
    'professée',
    'professer',
    'professes',
    'professés',
    'professez',
    'profilage',
    'profilais',
    'profilait',
    'profilant',
    'profilées',
    'profilent',
    'profilera',
    'profiliez',
    'profilons',
    'profitais',
    'profitait',
    'profitant',
    'profitent',
    'profitera',
    'profiteur',
    'profitiez',
    'profitons',
    'profondes',
    'profusion',
    'progiciel',
    'prognathe',
    'programma',
    'programme',
    'programmé',
    'progressa',
    'progresse',
    'progressé',
    'prohibais',
    'prohibait',
    'prohibant',
    'prohibées',
    'prohibent',
    'prohibera',
    'prohibiez',
    'prohibons',
    'projetais',
    'projetait',
    'projetant',
    'projetées',
    'projeteur',
    'projetiez',
    'projetons',
    'projettes',
    'prolamine',
    'prolapsus',
    'prolepses',
    'proliféra',
    'proliféré',
    'prolifère',
    'prolixité',
    'prologues',
    'prolongea',
    'prolongée',
    'prolonger',
    'prolonges',
    'prolongés',
    'prolongez',
    'promenade',
    'promenais',
    'promenait',
    'promenant',
    'promenées',
    'promènent',
    'promènera',
    'promeneur',
    'promeniez',
    'promenoir',
    'promenons',
    'promesses',
    'promettes',
    'promettez',
    'promettra',
    'promettre',
    'promeuves',
    'promirent',
    'promisses',
    'promoteur',
    'promotion',
    'promouvez',
    'promouvra',
    'prompteur',
    'promulgua',
    'promulgue',
    'promulgué',
    'promurent',
    'promusses',
    'prônaient',
    'prônasses',
    'pronateur',
    'pronation',
    'prônerais',
    'prônerait',
    'prônèrent',
    'prôneriez',
    'prônerons',
    'prôneront',
    'prôneuses',
    'prononçai',
    'prononças',
    'prononçât',
    'prononcée',
    'prononcer',
    'prononces',
    'prononcés',
    'prononcez',
    'pronostic',
    'propageai',
    'propageas',
    'propageât',
    'propagées',
    'propagent',
    'propagera',
    'propagiez',
    'propagule',
    'propanier',
    'propergol',
    'prophases',
    'prophètes',
    'prophétie',
    'proposais',
    'proposait',
    'proposant',
    'proposées',
    'proposent',
    'proposera',
    'proposiez',
    'proposons',
    'propretés',
    'proprette',
    'propriété',
    'propulsai',
    'propulsas',
    'propulsât',
    'propulsée',
    'propulser',
    'propulses',
    'propulsés',
    'propulsez',
    'propulsif',
    'propylées',
    'propylène',
    'prorogeai',
    'prorogeas',
    'prorogeât',
    'prorogées',
    'prorogent',
    'prorogera',
    'prorogiez',
    'prosaïque',
    'prosaïsme',
    'prosateur',
    'proscrira',
    'proscrire',
    'proscrite',
    'proscrits',
    'proscrive',
    'prosélyte',
    'prosocial',
    'prosodiai',
    'prosodias',
    'prosodiât',
    'prosodiée',
    'prosodier',
    'prosodies',
    'prosodiés',
    'prosodiez',
    'prospecta',
    'prospecte',
    'prospecté',
    'prospects',
    'prospérai',
    'prospéras',
    'prospérât',
    'prospérer',
    'prospères',
    'prospérez',
    'prostates',
    'prosterna',
    'prosterne',
    'prosterné',
    'prosthèse',
    'prostitua',
    'prostitue',
    'prostitué',
    'prostrées',
    'prostyles',
    'protéases',
    'protégeai',
    'protégeas',
    'protégeât',
    'protégées',
    'protègent',
    'protégera',
    'protégiez',
    'protéides',
    'protéines',
    'protéique',
    'protestai',
    'protestas',
    'protestât',
    'protester',
    'protestes',
    'protestez',
    'prothalle',
    'prothèses',
    'prothorax',
    'protocole',
    'protogine',
    'prototype',
    'protoxyde',
    'protuteur',
    'prouesses',
    'prouvable',
    'prouvâmes',
    'prouvasse',
    'prouvâtes',
    'prouverai',
    'prouveras',
    'prouverez',
    'prouvions',
    'provenais',
    'provenait',
    'provenant',
    'provençal',
    'provendes',
    'proveniez',
    'provenons',
    'provenues',
    'proverbes',
    'provienne',
    'provignai',
    'provignas',
    'provignât',
    'provignée',
    'provigner',
    'provignes',
    'provignés',
    'provignez',
    'provinces',
    'provînmes',
    'provinsse',
    'provîntes',
    'proviseur',
    'provision',
    'provocant',
    'provoquai',
    'provoquas',
    'provoquât',
    'provoquée',
    'provoquer',
    'provoques',
    'provoqués',
    'provoquez',
    'proxénète',
    'proximale',
    'proximaux',
    'proximité',
    'prudences',
    'prudentes',
    'pruderies',
    'prunelaie',
    'prunelées',
    'prunelles',
    'prussiate',
    'prussiens',
    'prussique',
    'prytanées',
    'psallette',
    'psalliote',
    'psalmiste',
    'psalmodia',
    'psalmodie',
    'psalmodié',
    'psautiers',
    'psoriasis',
    'psychique',
    'psychisme',
    'psychoses',
    'ptomaïnes',
    'ptyalines',
    'ptyalisme',
    'puanteurs',
    'puassions',
    'pubescent',
    'pubiennes',
    'publiable',
    'publiâmes',
    'publiasse',
    'publiâtes',
    'publicain',
    'publicité',
    'publierai',
    'publieras',
    'publierez',
    'publiions',
    'publiques',
    'puccinias',
    'puccinies',
    'pucelages',
    'puddlages',
    'puddleurs',
    'pudibonde',
    'pudibonds',
    'pudicités',
    'pueraient',
    'puérilité',
    'puerpéral',
    'pugiliste',
    'pugnacité',
    'puisaient',
    'puisasses',
    'puisatier',
    'puisement',
    'puiserais',
    'puiserait',
    'puisèrent',
    'puiseriez',
    'puiserons',
    'puiseront',
    'puisettes',
    'puissance',
    'puissante',
    'puissants',
    'puissions',
    'pulicaire',
    'pullorose',
    'pull-over',
    'pullulais',
    'pullulait',
    'pullulant',
    'pullulent',
    'pullulera',
    'pulluliez',
    'pullulons',
    'pulpaires',
    'pulpeuses',
    'pulsaient',
    'pulsasses',
    'pulsation',
    'pulserais',
    'pulserait',
    'pulsèrent',
    'pulseriez',
    'pulserons',
    'pulseront',
    'pultacées',
    'pulvérins',
    'pulvérisa',
    'pulvérise',
    'pulvérisé',
    'punaisais',
    'punaisait',
    'punaisant',
    'punaisées',
    'punaisent',
    'punaisera',
    'punaisiez',
    'punaisons',
    'puncheurs',
    'punirions',
    'punissais',
    'punissait',
    'punissant',
    'punissent',
    'punissiez',
    'punissons',
    'punitions',
    'punitives',
    'pupitreur',
    'purgatifs',
    'purgation',
    'purgative',
    'purgeâmes',
    'purgeasse',
    'purgeâtes',
    'purgeoirs',
    'purgerais',
    'purgerait',
    'purgèrent',
    'purgeriez',
    'purgerons',
    'purgeront',
    'purifiais',
    'purifiait',
    'purifiant',
    'purifiées',
    'purifient',
    'purifiera',
    'purifiiez',
    'purifions',
    'puritaine',
    'puritains',
    'purpurine',
    'purpurins',
    'purulence',
    'purulente',
    'purulents',
    'puseyisme',
    'pustuleux',
    'putatives',
    'putréfiai',
    'putréfias',
    'putréfiât',
    'putréfiée',
    'putréfier',
    'putréfies',
    'putréfiés',
    'putréfiez',
    'putridité',
    'pygargues',
    'pylorique',
    'pyorrhées',
    'pyramidai',
    'pyramidal',
    'pyramidas',
    'pyramidât',
    'pyramidée',
    'pyramider',
    'pyramides',
    'pyramidés',
    'pyramidez',
    'pyrénéens',
    'pyrénéite',
    'pyrèthres',
    'pyrogènes',
    'pyrograva',
    'pyrograve',
    'pyrogravé',
    'pyrolyses',
    'pyromanes',
    'pyromanie',
    'pyromètre',
    'pyrophore',
    'pyroxylée',
    'pyroxylés',
    'pyrrhique',
    'pythienne',
    'pythiques',
    'quadrants',
    'quadrette',
    'quadriges',
    'quadrilla',
    'quadrille',
    'quadrillé',
    'quadrupla',
    'quadruple',
    'quadruplé',
    'qualifiai',
    'qualifias',
    'qualifiât',
    'qualifiée',
    'qualifier',
    'qualifies',
    'qualifiés',
    'qualifiez',
    'quantième',
    'quantifia',
    'quantifie',
    'quantifié',
    'quantités',
    'quarrâmes',
    'quarrasse',
    'quarrâtes',
    'quarrerai',
    'quarreras',
    'quarrerez',
    'quarrions',
    'quartagea',
    'quartagée',
    'quartager',
    'quartages',
    'quartagés',
    'quartagez',
    'quartâmes',
    'quartasse',
    'quartâtes',
    'quartauts',
    'quarterai',
    'quarteras',
    'quarterez',
    'quarteron',
    'quartette',
    'quartidis',
    'quartiers',
    'quartiles',
    'quartions',
    'quartzeux',
    'quartzite',
    'quasiment',
    'Quasimodo',
    'quassiers',
    'quassines',
    'quaternes',
    'quatrains',
    'quatrième',
    'québécois',
    'quebracho',
    'quémandai',
    'quémandas',
    'quémandât',
    'quémandée',
    'quémander',
    'quémandes',
    'quémandés',
    'quémandez',
    'quenelles',
    'quenottes',
    'quéquette',
    'quérables',
    'querellai',
    'querellas',
    'querellât',
    'querellée',
    'quereller',
    'querelles',
    'querellés',
    'querellez',
    'quérulent',
    'questeurs',
    'questions',
    'questures',
    'quêtaient',
    'quêtasses',
    'quêterais',
    'quêterait',
    'quêtèrent',
    'quêteriez',
    'quêterons',
    'quêteront',
    'quêteuses',
    'quetsches',
    'queutâmes',
    'queutasse',
    'queutâtes',
    'queuterai',
    'queuteras',
    'queuterez',
    'queutions',
    'quiconque',
    'quiddités',
    'quiétisme',
    'quiétiste',
    'quiétudes',
    'quilleurs',
    'quilleuse',
    'quilliers',
    'quinaires',
    'quinaudes',
    'quinconce',
    'quinquets',
    'quinquina',
    'quintaine',
    'quintette',
    'quinteuse',
    'quintidis',
    'quintupla',
    'quintuple',
    'quintuplé',
    'quinzaine',
    'quinzième',
    'quiproquo',
    'quiscales',
    'quittâmes',
    'quittança',
    'quittance',
    'quittancé',
    'quittasse',
    'quittâtes',
    'quitterai',
    'quitteras',
    'quitterez',
    'quittions',
    'quolibets',
    'quotidien',
    'quotients',
    'quottâmes',
    'quottasse',
    'quottâtes',
    'quotterai',
    'quotteras',
    'quotterez',
    'quottions',
    'rabâchage',
    'rabâchais',
    'rabâchait',
    'rabâchant',
    'rabâchées',
    'rabâchent',
    'rabâchera',
    'rabâcheur',
    'rabâchiez',
    'rabâchons',
    'rabaissai',
    'rabaissas',
    'rabaissât',
    'rabaissée',
    'rabaisser',
    'rabaisses',
    'rabaissés',
    'rabaissez',
    'rabantais',
    'rabantait',
    'rabantant',
    'rabantées',
    'rabantent',
    'rabantera',
    'rabantiez',
    'rabantons',
    'rabattage',
    'rabattais',
    'rabattait',
    'rabattant',
    'rabattent',
    'rabatteur',
    'rabattiez',
    'rabattons',
    'rabattrai',
    'rabattras',
    'rabattrez',
    'rabattues',
    'rabbinats',
    'rabibocha',
    'rabiboche',
    'rabiboché',
    'rabiotais',
    'rabiotait',
    'rabiotant',
    'rabiotées',
    'rabiotent',
    'rabiotera',
    'rabiotiez',
    'rabiotons',
    'râblaient',
    'râblasses',
    'râblerais',
    'râblerait',
    'râblèrent',
    'râbleriez',
    'râblerons',
    'râbleront',
    'rabonnies',
    'rabonnira',
    'rabotages',
    'rabotâmes',
    'rabotasse',
    'rabotâtes',
    'raboterai',
    'raboteras',
    'raboterez',
    'raboteurs',
    'raboteuse',
    'rabotions',
    'rabougrie',
    'rabougrir',
    'rabougris',
    'rabougrit',
    'rabougrît',
    'raboutais',
    'raboutait',
    'raboutant',
    'raboutées',
    'raboutent',
    'raboutera',
    'raboutiez',
    'raboutons',
    'rabrouais',
    'rabrouait',
    'rabrouant',
    'rabrouées',
    'rabrouent',
    'rabrouera',
    'rabrouiez',
    'rabrouons',
    'racailles',
    'raccordai',
    'raccordas',
    'raccordât',
    'raccordée',
    'raccorder',
    'raccordes',
    'raccordés',
    'raccordez',
    'raccourci',
    'raccoutra',
    'raccoutre',
    'raccoutré',
    'raccrocha',
    'raccroche',
    'raccroché',
    'racémique',
    'rachetais',
    'rachetait',
    'rachetant',
    'rachetées',
    'rachètent',
    'rachètera',
    'rachetiez',
    'rachetons',
    'rachidien',
    'racinages',
    'racinâmes',
    'racinasse',
    'racinâtes',
    'racinerai',
    'racineras',
    'racinerez',
    'raciniens',
    'racinions',
    'racketter',
    'raclaient',
    'raclasses',
    'raclement',
    'raclerais',
    'raclerait',
    'raclèrent',
    'racleriez',
    'raclerons',
    'racleront',
    'raclettes',
    'racleuses',
    'racolages',
    'racolâmes',
    'racolasse',
    'racolâtes',
    'racolerai',
    'racoleras',
    'racolerez',
    'racoleurs',
    'racoleuse',
    'racolions',
    'racontais',
    'racontait',
    'racontant',
    'racontars',
    'racontées',
    'racontent',
    'racontera',
    'raconteur',
    'racontiez',
    'racontons',
    'racornies',
    'racornira',
    'radariste',
    'radassent',
    'radassiez',
    'raderions',
    'radiaient',
    'radiaires',
    'radiances',
    'radiasses',
    'radiateur',
    'radiatifs',
    'radiation',
    'radiative',
    'radicales',
    'radicante',
    'radicants',
    'radicelle',
    'radierais',
    'radierait',
    'radièrent',
    'radieriez',
    'radierons',
    'radieront',
    'radieuses',
    'radinâmes',
    'radinasse',
    'radinâtes',
    'radinerai',
    'radineras',
    'radinerez',
    'radinerie',
    'radinions',
    'radiolyse',
    'radotages',
    'radotâmes',
    'radotasse',
    'radotâtes',
    'radoterai',
    'radoteras',
    'radoterez',
    'radoteurs',
    'radoteuse',
    'radotions',
    'radoubais',
    'radoubait',
    'radoubant',
    'radoubées',
    'radoubent',
    'radoubera',
    'radoubiez',
    'radoubons',
    'radoucies',
    'radoucira',
    'raffermie',
    'raffermir',
    'raffermis',
    'raffermit',
    'raffermît',
    'raffinage',
    'raffinais',
    'raffinait',
    'raffinant',
    'raffinées',
    'raffinent',
    'raffinera',
    'raffineur',
    'raffiniez',
    'raffinons',
    'rafflesia',
    'rafflésie',
    'raffolais',
    'raffolait',
    'raffolant',
    'raffolées',
    'raffolent',
    'raffolera',
    'raffoliez',
    'raffolons',
    'raffûtais',
    'raffûtait',
    'raffûtant',
    'raffûtées',
    'raffûtent',
    'raffûtera',
    'raffûtiez',
    'raffûtons',
    'rafistola',
    'rafistole',
    'rafistolé',
    'raflaient',
    'raflasses',
    'raflerais',
    'raflerait',
    'raflèrent',
    'rafleriez',
    'raflerons',
    'rafleront',
    'rafraîchi',
    'rageaient',
    'rageantes',
    'rageasses',
    'ragerions',
    'ragondins',
    'ragotâmes',
    'ragotasse',
    'ragotâtes',
    'ragoterai',
    'ragoteras',
    'ragoterez',
    'ragotions',
    'ragoûtais',
    'ragoûtait',
    'ragoûtant',
    'ragoûtées',
    'ragoûtent',
    'ragoûtera',
    'ragoûtiez',
    'ragoûtons',
    'ragrafais',
    'ragrafait',
    'ragrafant',
    'ragrafées',
    'ragrafent',
    'ragrafera',
    'ragrafiez',
    'ragrafons',
    'ragréâmes',
    'ragréasse',
    'ragréâtes',
    'ragréerai',
    'ragréeras',
    'ragréerez',
    'ragréions',
    'raguaient',
    'raguasses',
    'raguerais',
    'raguerait',
    'raguèrent',
    'ragueriez',
    'raguerons',
    'ragueront',
    'raidillon',
    'raidirent',
    'raidisses',
    'raidissez',
    'raierions',
    'raillâmes',
    'raillasse',
    'raillâtes',
    'raillerai',
    'railleras',
    'raillerez',
    'raillerie',
    'railleurs',
    'railleuse',
    'raillions',
    'rainaient',
    'rainasses',
    'rainerais',
    'rainerait',
    'rainèrent',
    'raineriez',
    'rainerons',
    'raineront',
    'rainetais',
    'rainetait',
    'rainetant',
    'rainetées',
    'rainetiez',
    'rainetons',
    'rainettes',
    'rainurage',
    'rainurais',
    'rainurait',
    'rainurant',
    'rainurées',
    'rainurent',
    'rainurera',
    'rainuriez',
    'rainurons',
    'raisonnai',
    'raisonnas',
    'raisonnât',
    'raisonnée',
    'raisonner',
    'raisonnes',
    'raisonnés',
    'raisonnez',
    'rajeunies',
    'rajeunira',
    'rajoutais',
    'rajoutait',
    'rajoutant',
    'rajoutées',
    'rajoutent',
    'rajoutera',
    'rajoutiez',
    'rajoutons',
    'rajustais',
    'rajustait',
    'rajustant',
    'rajustées',
    'rajustent',
    'rajustera',
    'rajustiez',
    'rajustons',
    'râlassent',
    'râlassiez',
    'râlements',
    'ralenties',
    'ralentira',
    'râlerions',
    'ralinguai',
    'ralinguas',
    'ralinguât',
    'ralinguée',
    'ralinguer',
    'ralingues',
    'ralingués',
    'ralinguez',
    'rallégeai',
    'rallégeas',
    'rallégeât',
    'rallègent',
    'rallégera',
    'rallégiez',
    'ralliâmes',
    'ralliasse',
    'ralliâtes',
    'rallierai',
    'rallieras',
    'rallierez',
    'ralliions',
    'rallongea',
    'rallongée',
    'rallonger',
    'rallonges',
    'rallongés',
    'rallongez',
    'rallumais',
    'rallumait',
    'rallumant',
    'rallumées',
    'rallument',
    'rallumera',
    'rallumiez',
    'rallumons',
    'ramageais',
    'ramageait',
    'ramageant',
    'ramageons',
    'ramagerai',
    'ramageras',
    'ramagerez',
    'ramagions',
    'ramaillai',
    'ramaillas',
    'ramaillât',
    'ramailler',
    'ramailles',
    'ramaillez',
    'ramandais',
    'ramandait',
    'ramandant',
    'ramandées',
    'ramandent',
    'ramandera',
    'ramandiez',
    'ramandons',
    'ramarrais',
    'ramarrait',
    'ramarrant',
    'ramarrées',
    'ramarrent',
    'ramarrera',
    'ramarriez',
    'ramarrons',
    'ramassage',
    'ramassais',
    'ramassait',
    'ramassant',
    'ramassées',
    'ramassent',
    'ramassera',
    'ramasseur',
    'ramassiez',
    'ramassons',
    'rambardes',
    'ramenâmes',
    'ramenasse',
    'ramenâtes',
    'ramendais',
    'ramendait',
    'ramendant',
    'ramendées',
    'ramendent',
    'ramendera',
    'ramendeur',
    'ramendiez',
    'ramendons',
    'ramènerai',
    'ramèneras',
    'ramènerez',
    'ramenions',
    'ramequins',
    'ramereaux',
    'ramerions',
    'rameutais',
    'rameutait',
    'rameutant',
    'rameutées',
    'rameutent',
    'rameutera',
    'rameutiez',
    'rameutons',
    'ramifiais',
    'ramifiait',
    'ramifiant',
    'ramifiées',
    'ramifient',
    'ramifiera',
    'ramifiiez',
    'ramifions',
    'ramingues',
    'ramollies',
    'ramollira',
    'ramonages',
    'ramonâmes',
    'ramonasse',
    'ramonâtes',
    'ramonerai',
    'ramoneras',
    'ramonerez',
    'ramoneurs',
    'ramonions',
    'rampaient',
    'rampantes',
    'rampasses',
    'rampement',
    'ramperais',
    'ramperait',
    'rampèrent',
    'ramperiez',
    'ramperons',
    'ramperont',
    'ramponeau',
    'rancardai',
    'rancardas',
    'rancardât',
    'rancardée',
    'rancarder',
    'rancardes',
    'rancardés',
    'rancardez',
    'rancirais',
    'rancirait',
    'rancirent',
    'ranciriez',
    'rancirons',
    'ranciront',
    'rancisses',
    'rancissez',
    'rancoeurs',
    'rançonnai',
    'rançonnas',
    'rançonnât',
    'rançonnée',
    'rançonner',
    'rançonnes',
    'rançonnés',
    'rançonnez',
    'rancunier',
    'randomisa',
    'randomise',
    'randomisé',
    'randonnai',
    'randonnas',
    'randonnât',
    'randonnée',
    'randonner',
    'randonnes',
    'randonnés',
    'randonnez',
    'rangeâmes',
    'rangeasse',
    'rangeâtes',
    'rangement',
    'rangerais',
    'rangerait',
    'rangèrent',
    'rangeriez',
    'rangerons',
    'rangeront',
    'ranimâmes',
    'ranimasse',
    'ranimâtes',
    'ranimerai',
    'ranimeras',
    'ranimerez',
    'ranimions',
    'rapacités',
    'râpassent',
    'râpassiez',
    'rapatriai',
    'rapatrias',
    'rapatriât',
    'rapatriée',
    'rapatrier',
    'rapatries',
    'rapatriés',
    'rapatriez',
    'râperions',
    'rapetassa',
    'rapetasse',
    'rapetassé',
    'rapetissa',
    'rapetisse',
    'rapetissé',
    'rapidités',
    'rapiéçage',
    'rapiéçais',
    'rapiéçait',
    'rapiéçant',
    'rapiécées',
    'rapiècent',
    'rapiécera',
    'rapiéceta',
    'rapiéceté',
    'rapiécète',
    'rapiéciez',
    'rapiéçons',
    'rapinâmes',
    'rapinasse',
    'rapinâtes',
    'rapinerai',
    'rapineras',
    'rapinerez',
    'rapinerie',
    'rapinions',
    'raplaties',
    'raplatira',
    'rapointie',
    'rapointir',
    'rapointis',
    'rapointit',
    'rapointît',
    'rappariai',
    'rapparias',
    'rappariât',
    'rappariée',
    'rapparier',
    'rapparies',
    'rappariés',
    'rappariez',
    'rappelais',
    'rappelait',
    'rappelant',
    'rappelées',
    'rappeliez',
    'rappelles',
    'rappelons',
    'rappliqua',
    'rapplique',
    'rappliqué',
    'rapportai',
    'rapportas',
    'rapportât',
    'rapportée',
    'rapporter',
    'rapportes',
    'rapportés',
    'rapportez',
    'rapprende',
    'rapprends',
    'rapprenez',
    'rapprêtai',
    'rapprêtas',
    'rapprêtât',
    'rapprêtée',
    'rapprêter',
    'rapprêtes',
    'rapprêtés',
    'rapprêtez',
    'rapprîmes',
    'rapprises',
    'rapprisse',
    'rapprîtes',
    'rapprocha',
    'rapproche',
    'rapproché',
    'rapsodies',
    'raquaient',
    'raquasses',
    'raquerais',
    'raquerait',
    'raquèrent',
    'raqueriez',
    'raquerons',
    'raqueront',
    'raquettes',
    'raréfiais',
    'raréfiait',
    'raréfiant',
    'raréfiées',
    'raréfient',
    'raréfiera',
    'raréfiiez',
    'raréfions',
    'rarescent',
    'rarissime',
    'rasassent',
    'rasassiez',
    'rascasses',
    'raserions',
    'rassasiai',
    'rassasias',
    'rassasiât',
    'rassasiée',
    'rassasier',
    'rassasies',
    'rassasiés',
    'rassasiez',
    'rassembla',
    'rassemble',
    'rassemblé',
    'rasséréna',
    'rasséréné',
    'rassérène',
    'rasseyais',
    'rasseyait',
    'rasseyant',
    'rasseyent',
    'rasseyiez',
    'rasseyons',
    'rassiérai',
    'rassiéras',
    'rassiérez',
    'rassirent',
    'rassisses',
    'rassoient',
    'rassortie',
    'rassortir',
    'rassortis',
    'rassortit',
    'rassortît',
    'rassoyais',
    'rassoyait',
    'rassoyant',
    'rassoyiez',
    'rassoyons',
    'rassurais',
    'rassurait',
    'rassurant',
    'rassurées',
    'rassurent',
    'rassurera',
    'rassuriez',
    'rassurons',
    'ratassent',
    'ratassiez',
    'ratatinai',
    'ratatinas',
    'ratatinât',
    'ratatinée',
    'ratatiner',
    'ratatines',
    'ratatinés',
    'ratatinez',
    'râtelages',
    'râtelâmes',
    'râtelasse',
    'râtelâtes',
    'râteleurs',
    'râteleuse',
    'râteliers',
    'râtelions',
    'râtellent',
    'râtellera',
    'râtelures',
    'raterions',
    'ratiboisa',
    'ratiboise',
    'ratiboisé',
    'ratichons',
    'ratifiais',
    'ratifiait',
    'ratifiant',
    'ratifiées',
    'ratifient',
    'ratifiera',
    'ratifiiez',
    'ratifions',
    'ratinages',
    'ratinâmes',
    'ratinasse',
    'ratinâtes',
    'ratinerai',
    'ratineras',
    'ratinerez',
    'ratinions',
    'ratiocina',
    'ratiocine',
    'ratiociné',
    'rationaux',
    'rationnai',
    'rationnas',
    'rationnât',
    'rationnée',
    'rationnel',
    'rationner',
    'rationnes',
    'rationnés',
    'rationnez',
    'ratissage',
    'ratissais',
    'ratissait',
    'ratissant',
    'ratissées',
    'ratissent',
    'ratissera',
    'ratissiez',
    'ratissons',
    'rattachai',
    'rattachas',
    'rattachât',
    'rattachée',
    'rattacher',
    'rattaches',
    'rattachés',
    'rattachez',
    'rattrapai',
    'rattrapas',
    'rattrapât',
    'rattrapée',
    'rattraper',
    'rattrapes',
    'rattrapés',
    'rattrapez',
    'raturages',
    'raturâmes',
    'raturasse',
    'raturâtes',
    'raturerai',
    'ratureras',
    'raturerez',
    'raturions',
    'raucheurs',
    'raugmenta',
    'raugmente',
    'raugmenté',
    'rauwolfia',
    'ravageais',
    'ravageait',
    'ravageant',
    'ravageons',
    'ravagerai',
    'ravageras',
    'ravagerez',
    'ravageurs',
    'ravageuse',
    'ravagions',
    'ravalâmes',
    'ravalasse',
    'ravalâtes',
    'ravalerai',
    'ravaleras',
    'ravalerez',
    'ravaleurs',
    'ravalions',
    'ravaudage',
    'ravaudais',
    'ravaudait',
    'ravaudant',
    'ravaudées',
    'ravaudent',
    'ravaudera',
    'ravaudeur',
    'ravaudiez',
    'ravaudons',
    'ravenalas',
    'ravenelle',
    'ravigotai',
    'ravigotas',
    'ravigotât',
    'ravigotée',
    'ravigoter',
    'ravigotes',
    'ravigotés',
    'ravigotez',
    'ravilîmes',
    'ravilirai',
    'raviliras',
    'ravilirez',
    'ravilisse',
    'ravilîtes',
    'ravinâmes',
    'ravinasse',
    'ravinâtes',
    'ravinerai',
    'ravineras',
    'ravinerez',
    'ravinions',
    'ravirions',
    'ravisâmes',
    'ravisasse',
    'ravisâtes',
    'raviserai',
    'raviseras',
    'raviserez',
    'ravisions',
    'ravissais',
    'ravissait',
    'ravissant',
    'ravissent',
    'ravisseur',
    'ravissiez',
    'ravissons',
    'ravivages',
    'ravivâmes',
    'ravivasse',
    'ravivâtes',
    'raviverai',
    'raviveras',
    'raviverât',
    'ravivions',
    'rayassent',
    'rayassiez',
    'rayerions',
    'ray-grass',
    'rayonnage',
    'rayonnais',
    'rayonnait',
    'rayonnant',
    'rayonnées',
    'rayonnent',
    'rayonnera',
    'rayonniez',
    'rayonnons',
    'razziâmes',
    'razziasse',
    'razziâtes',
    'razzierai',
    'razzieras',
    'razzierât',
    'razziions',
    'réabonnai',
    'réabonnas',
    'réabonnât',
    'réabonnée',
    'réabonner',
    'réabonnes',
    'réabonnés',
    'réabonnez',
    'réabsorba',
    'réabsorbe',
    'réabsorbé',
    'réacteurs',
    'réactions',
    'réactivai',
    'réactivas',
    'réactivât',
    'réactivée',
    'réactiver',
    'réactives',
    'réactivés',
    'réactivez',
    'réactrice',
    'réadaptai',
    'réadaptas',
    'réadaptât',
    'réadaptée',
    'réadapter',
    'réadaptes',
    'réadaptés',
    'réadaptez',
    'réadmette',
    'réadmîmes',
    'réadmises',
    'réadmisse',
    'réadmîtes',
    'réaffecta',
    'réaffecte',
    'réaffecté',
    'réaffirma',
    'réaffirme',
    'réaffirmé',
    'réaffûtai',
    'réaffûtas',
    'réaffûtât',
    'réaffûtée',
    'réaffûter',
    'réaffûtes',
    'réaffûtés',
    'réaffûtez',
    'réagirais',
    'réagirait',
    'réagirent',
    'réagiriez',
    'réagirons',
    'réagiront',
    'réagisses',
    'réagissez',
    'réajustai',
    'réajustas',
    'réajustât',
    'réajustée',
    'réajuster',
    'réajustes',
    'réajustés',
    'réajustez',
    'réalésais',
    'réalésait',
    'réalésant',
    'réalésées',
    'réalèsent',
    'réalésera',
    'réalésiez',
    'réalésons',
    'réalignai',
    'réalignas',
    'réalignât',
    'réalignée',
    'réaligner',
    'réalignes',
    'réalignés',
    'réalignez',
    'réalisais',
    'réalisait',
    'réalisant',
    'réalisées',
    'réalisent',
    'réalisera',
    'réalisiez',
    'réalismes',
    'réalisons',
    'réalistes',
    'réaménage',
    'réaménagé',
    'réamorçai',
    'réamorças',
    'réamorçât',
    'réamorcée',
    'réamorcer',
    'réamorces',
    'réamorcés',
    'réamorcez',
    'réanimais',
    'réanimait',
    'réanimant',
    'réanimées',
    'réaniment',
    'réanimera',
    'réanimiez',
    'réanimons',
    'réapparue',
    'réapparus',
    'réapparut',
    'réapparût',
    'réapprend',
    'réapprise',
    'réargenta',
    'réargente',
    'réargenté',
    'réarmâmes',
    'réarmasse',
    'réarmâtes',
    'réarmerai',
    'réarmeras',
    'réarmerez',
    'réarmions',
    'réarrange',
    'réarrangé',
    'réassigna',
    'réassigne',
    'réassigné',
    'réassions',
    'réassorti',
    'réassurai',
    'réassuras',
    'réassurât',
    'réassurée',
    'réassurer',
    'réassures',
    'réassurés',
    'réassurez',
    'rebaissai',
    'rebaissas',
    'rebaissât',
    'rebaisser',
    'rebaisses',
    'rebaissez',
    'rebandais',
    'rebandait',
    'rebandant',
    'rebandées',
    'rebandent',
    'rebandera',
    'rebandiez',
    'rebandons',
    'rebaptisa',
    'rebaptise',
    'rebaptisé',
    'rebâtîmes',
    'rebâtirai',
    'rebâtiras',
    'rebâtirez',
    'rebâtisse',
    'rebâtîtes',
    'rebattais',
    'rebattait',
    'rebattant',
    'rebattent',
    'rebattiez',
    'rebattons',
    'rebattrai',
    'rebattras',
    'rebattrez',
    'rebattues',
    'rebellais',
    'rebellait',
    'rebellant',
    'rebellées',
    'rebellent',
    'rebellera',
    'rebelliez',
    'rébellion',
    'rebellons',
    'rebiffais',
    'rebiffait',
    'rebiffant',
    'rebiffées',
    'rebiffent',
    'rebiffera',
    'rebiffiez',
    'rebiffons',
    'rebiquais',
    'rebiquait',
    'rebiquant',
    'rebiquées',
    'rebiquent',
    'rebiquera',
    'rebiquiez',
    'rebiquons',
    'reblanchi',
    'reblochon',
    'reboisais',
    'reboisait',
    'reboisant',
    'reboisées',
    'reboisent',
    'reboisera',
    'reboisiez',
    'reboisons',
    'rebondira',
    'rebordais',
    'rebordait',
    'rebordant',
    'rebordées',
    'rebordent',
    'rebordera',
    'rebordiez',
    'rebordons',
    'rebouchai',
    'rebouchas',
    'rebouchât',
    'rebouchée',
    'reboucher',
    'rebouches',
    'rebouchés',
    'rebouchez',
    'reboutais',
    'reboutait',
    'reboutant',
    'reboutées',
    'reboutent',
    'reboutera',
    'rebouteur',
    'rebouteux',
    'reboutiez',
    'reboutons',
    'rebrodais',
    'rebrodait',
    'rebrodant',
    'rebrodées',
    'rebrodent',
    'rebrodera',
    'rebrodiez',
    'rebrodons',
    'rebroussa',
    'rebrousse',
    'rebroussé',
    'rebrûlais',
    'rebrûlait',
    'rebrûlant',
    'rebrûlées',
    'rebrûlent',
    'rebrûlera',
    'rebrûliez',
    'rebrûlons',
    'rebuffade',
    'rebutâmes',
    'rebutante',
    'rebutants',
    'rebutasse',
    'rebutâtes',
    'rebuterai',
    'rebuteras',
    'rebuterez',
    'rebutions',
    'recacheta',
    'recacheté',
    'recalâmes',
    'recalasse',
    'recalâtes',
    'recalcula',
    'recalcule',
    'recalculé',
    'recalerai',
    'recaleras',
    'recalerez',
    'recalions',
    'recardais',
    'recardait',
    'recardant',
    'recardées',
    'recardent',
    'recardera',
    'recardiez',
    'recardons',
    'recarrela',
    'recarrelé',
    'recasâmes',
    'recasasse',
    'recasâtes',
    'recaserai',
    'recaseras',
    'recaserez',
    'recasions',
    'recausais',
    'recausait',
    'recausant',
    'recausent',
    'recausera',
    'recausiez',
    'recausons',
    'recédâmes',
    'recédasse',
    'recédâtes',
    'recéderai',
    'recéderas',
    'recéderez',
    'recédions',
    'recelâmes',
    'recélâmes',
    'recelasse',
    'recélasse',
    'recelâtes',
    'recélâtes',
    'recélerai',
    'recèlerai',
    'recéleras',
    'recèleras',
    'recélerez',
    'recèlerez',
    'receleurs',
    'receleuse',
    'recelions',
    'recélions',
    'récemment',
    'recensais',
    'recensait',
    'recensant',
    'recensées',
    'recensent',
    'recensera',
    'recenseur',
    'recensiez',
    'recension',
    'recensons',
    'recentrai',
    'recentras',
    'recentrât',
    'recentrée',
    'recentrer',
    'recentres',
    'recentrés',
    'recentrez',
    'recépages',
    'recepâmes',
    'recépâmes',
    'recepasse',
    'recépasse',
    'recepâtes',
    'recépâtes',
    'recéperai',
    'recèperai',
    'recéperas',
    'recèperas',
    'recéperez',
    'recèperez',
    'recepions',
    'recépions',
    'récépissé',
    'récepteur',
    'réceptifs',
    'réception',
    'réceptive',
    'recerclai',
    'recerclas',
    'recerclât',
    'recerclée',
    'recercler',
    'recercles',
    'recerclés',
    'recerclez',
    'récessifs',
    'récession',
    'récessive',
    'recevable',
    'receveurs',
    'receveuse',
    'recevions',
    'recevrais',
    'recevrait',
    'recevriez',
    'recevrons',
    'recevront',
    'rechampie',
    'réchampie',
    'rechampir',
    'réchampir',
    'rechampis',
    'réchampis',
    'rechampit',
    'rechampît',
    'réchampit',
    'réchampît',
    'rechangea',
    'rechangée',
    'rechanger',
    'rechanges',
    'rechangés',
    'rechangez',
    'rechantai',
    'rechantas',
    'rechantât',
    'rechantée',
    'rechanter',
    'rechantes',
    'rechantés',
    'rechantez',
    'rechapage',
    'rechapais',
    'rechapait',
    'rechapant',
    'rechapées',
    'rechapent',
    'rechapera',
    'rechapiez',
    'rechapons',
    'réchappai',
    'réchappas',
    'réchappât',
    'réchapper',
    'réchappes',
    'réchappez',
    'rechargea',
    'rechargée',
    'recharger',
    'recharges',
    'rechargés',
    'rechargez',
    'rechassai',
    'rechassas',
    'rechassât',
    'rechassée',
    'rechasser',
    'rechasses',
    'rechassés',
    'rechassez',
    'réchauffa',
    'réchauffe',
    'réchauffé',
    'rechaussa',
    'rechausse',
    'rechaussé',
    'rechercha',
    'recherche',
    'recherché',
    'rechignai',
    'rechignas',
    'rechignât',
    'rechigner',
    'rechignes',
    'rechignez',
    'rechutais',
    'rechutait',
    'rechutant',
    'rechutent',
    'rechutera',
    'rechutiez',
    'rechutons',
    'récidivai',
    'récidivas',
    'récidivât',
    'récidiver',
    'récidives',
    'récidivez',
    'récifales',
    'récipient',
    'récitâmes',
    'récitante',
    'récitants',
    'récitasse',
    'récitâtes',
    'récitatif',
    'réciterai',
    'réciteras',
    'réciterez',
    'récitions',
    'réclamais',
    'réclamait',
    'réclamant',
    'réclamées',
    'réclament',
    'réclamera',
    'réclamiez',
    'réclamons',
    'reclassai',
    'reclassas',
    'reclassât',
    'reclassée',
    'reclasser',
    'reclasses',
    'reclassés',
    'reclassez',
    'réclinais',
    'réclinait',
    'réclinant',
    'réclinent',
    'réclinera',
    'récliniez',
    'réclinons',
    'reclouais',
    'reclouait',
    'reclouant',
    'reclouées',
    'reclouent',
    'reclouera',
    'reclouiez',
    'reclouons',
    'réclusion',
    'recoiffai',
    'recoiffas',
    'recoiffât',
    'recoiffée',
    'recoiffer',
    'recoiffes',
    'recoiffés',
    'recoiffez',
    'reçoivent',
    'recolâmes',
    'recolasse',
    'recolâtes',
    'recolerai',
    'recoleras',
    'recolerez',
    'recolions',
    'recollage',
    'recollais',
    'recollait',
    'recollant',
    'recollées',
    'recollent',
    'recollera',
    'récollets',
    'recolliez',
    'recollons',
    'recolorai',
    'recoloras',
    'recolorât',
    'recolorée',
    'recolorer',
    'recolores',
    'recolorés',
    'recolorez',
    'récoltais',
    'récoltait',
    'récoltant',
    'récoltées',
    'récoltent',
    'récoltera',
    'récolteur',
    'récoltiez',
    'récoltons',
    'recombina',
    'recombine',
    'recombiné',
    'recomparu',
    'recompila',
    'recompile',
    'recompilé',
    'recomposa',
    'recompose',
    'recomposé',
    'recomptai',
    'recomptas',
    'recomptât',
    'recomptée',
    'recompter',
    'recomptes',
    'recomptés',
    'recomptez',
    'reconduis',
    'reconduit',
    'réconfort',
    'recongela',
    'recongelé',
    'recongèle',
    'reconnais',
    'reconnaît',
    'reconnues',
    'reconquis',
    'reconquit',
    'reconquît',
    'recopiais',
    'recopiait',
    'recopiant',
    'recopiées',
    'recopient',
    'recopiera',
    'recopiiez',
    'recopions',
    'recordage',
    'recordais',
    'recordait',
    'recordant',
    'recordées',
    'recordent',
    'recordera',
    'recordiez',
    'recordman',
    'recordmen',
    'recordons',
    'recorrige',
    'recorrigé',
    'recouchai',
    'recouchas',
    'recouchât',
    'recouchée',
    'recoucher',
    'recouches',
    'recouchés',
    'recouchez',
    'recoudrai',
    'recoudras',
    'recoudrez',
    'recoupais',
    'recoupait',
    'recoupant',
    'recoupées',
    'recoupent',
    'recoupera',
    'recoupiez',
    'recoupons',
    'recourais',
    'recourait',
    'recourant',
    'recourbai',
    'recourbas',
    'recourbât',
    'recourbée',
    'recourber',
    'recourbes',
    'recourbés',
    'recourbez',
    'recourent',
    'recouriez',
    'recourons',
    'recourrai',
    'recourras',
    'recourrez',
    'recourues',
    'recousais',
    'recousait',
    'recousant',
    'recousent',
    'recousiez',
    'recousons',
    'recousues',
    'recouvert',
    'recouvrai',
    'recouvras',
    'recouvrât',
    'recouvrée',
    'recouvrer',
    'recouvres',
    'recouvrés',
    'recouvrez',
    'recouvrir',
    'recouvris',
    'recouvrit',
    'recouvrît',
    'recouvrue',
    'recouvrus',
    'recrachai',
    'recrachas',
    'recrachât',
    'recrachée',
    'recracher',
    'recraches',
    'recrachés',
    'recrachez',
    'recréâmes',
    'récréâmes',
    'recréasse',
    'récréasse',
    'recréâtes',
    'récréâtes',
    'récréatif',
    'recréerai',
    'récréerai',
    'recréeras',
    'récréeras',
    'recréerez',
    'récréerez',
    'recréions',
    'récréions',
    'recrépies',
    'recrépira',
    'recreusai',
    'recreusas',
    'recreusât',
    'recreusée',
    'recreuser',
    'recreuses',
    'recreusés',
    'recreusez',
    'récriâmes',
    'récriasse',
    'récriâtes',
    'récrierai',
    'récrieras',
    'récrierez',
    'récriions',
    'récrimina',
    'récrimine',
    'récriminé',
    'récrirais',
    'récrirait',
    'récririez',
    'récrirons',
    'récriront',
    'récrivais',
    'récrivait',
    'récrivant',
    'récrivent',
    'récriviez',
    'récrivons',
    'recroisée',
    'recroiser',
    'recroises',
    'recroisés',
    'recroisez',
    'recroisse',
    'recroîtra',
    'recroître',
    'recrurent',
    'recrusses',
    'recrutais',
    'recrutait',
    'recrutant',
    'recrutées',
    'recrutent',
    'recrutera',
    'recruteur',
    'recrutiez',
    'recrutons',
    'rectangle',
    'rectifiai',
    'rectifias',
    'rectifiât',
    'rectifiée',
    'rectifier',
    'rectifies',
    'rectifiés',
    'rectifiez',
    'rectitude',
    'rectorale',
    'rectorats',
    'rectoraux',
    'rectrices',
    'recueille',
    'recueilli',
    'recuirais',
    'recuirait',
    'recuiriez',
    'recuirons',
    'recuiront',
    'recuisais',
    'recuisait',
    'recuisant',
    'recuisent',
    'recuisiez',
    'recuisons',
    'reculades',
    'reculâmes',
    'reculasse',
    'reculâtes',
    'reculerai',
    'reculeras',
    'reculerez',
    'reculions',
    'reculotta',
    'reculotte',
    'reculotté',
    'récupérai',
    'récupéras',
    'récupérât',
    'récupérée',
    'récupérer',
    'récupérés',
    'récupères',
    'récupérez',
    'récurages',
    'récurâmes',
    'récurasse',
    'récurâtes',
    'récurerai',
    'récureras',
    'récurerez',
    'récurions',
    'récurrent',
    'récursifs',
    'récursion',
    'récursive',
    'récusable',
    'récusâmes',
    'récusasse',
    'récusâtes',
    'récuserai',
    'récuseras',
    'récuserez',
    'récusions',
    'reçussent',
    'reçussiez',
    'recyclage',
    'recyclais',
    'recyclait',
    'recyclant',
    'recyclées',
    'recyclent',
    'recyclera',
    'recycliez',
    'recyclons',
    'rédacteur',
    'rédaction',
    'reddition',
    'redéfaire',
    'redéfaite',
    'redéfaits',
    'redéfasse',
    'redéferai',
    'redéferas',
    'redéferez',
    'redéfîmes',
    'redéfinie',
    'redéfinir',
    'redéfinis',
    'redéfinit',
    'redéfinît',
    'redéfisse',
    'redéfîtes',
    'redemanda',
    'redemande',
    'redemandé',
    'redémarra',
    'redémarre',
    'redémarré',
    'redémolie',
    'redémolir',
    'redémolis',
    'redémolit',
    'redémolît',
    'redentées',
    'redéploie',
    'redéploya',
    'redéployé',
    'redescend',
    'redevable',
    'redevance',
    'redevenez',
    'redevenir',
    'redevenue',
    'redevenus',
    'redeviens',
    'redevient',
    'redevions',
    'redevrais',
    'redevrait',
    'redevriez',
    'redevrons',
    'redevront',
    'rédigeais',
    'rédigeait',
    'rédigeant',
    'rédigeons',
    'rédigerai',
    'rédigeras',
    'rédigerez',
    'rédigions',
    'rédimâmes',
    'rédimasse',
    'rédimâtes',
    'rédimerai',
    'rédimeras',
    'rédimerez',
    'rédimions',
    'redingote',
    'redirions',
    'rediscuta',
    'rediscute',
    'rediscuté',
    'redisions',
    'redissent',
    'redissiez',
    'redoivent',
    'redondais',
    'redondait',
    'redondant',
    'redondent',
    'redondera',
    'redondiez',
    'redondons',
    'redonnais',
    'redonnait',
    'redonnant',
    'redonnées',
    'redonnent',
    'redonnera',
    'redonniez',
    'redonnons',
    'redorâmes',
    'redorasse',
    'redorâtes',
    'redorerai',
    'redoreras',
    'redorerez',
    'redorions',
    'redoublai',
    'redoublas',
    'redoublât',
    'redoublée',
    'redoubler',
    'redoubles',
    'redoublés',
    'redoublez',
    'redoutais',
    'redoutait',
    'redoutant',
    'redoutées',
    'redoutent',
    'redoutera',
    'redoutiez',
    'redoutons',
    'redressai',
    'redressas',
    'redressât',
    'redressée',
    'redresser',
    'redresses',
    'redressés',
    'redressez',
    'réductase',
    'réducteur',
    'réduction',
    'réduirais',
    'réduirait',
    'réduiriez',
    'réduirons',
    'réduiront',
    'réduisais',
    'réduisait',
    'réduisant',
    'réduisent',
    'réduisiez',
    'réduisons',
    'redussent',
    'redussiez',
    'réécoutai',
    'réécoutas',
    'réécoutât',
    'réécoutée',
    'réécouter',
    'réécoutes',
    'réécoutés',
    'réécoutez',
    'réédifiai',
    'réédifias',
    'réédifiât',
    'réédifiée',
    'réédifier',
    'réédifies',
    'réédifiés',
    'réédifiez',
    'rééditais',
    'rééditait',
    'rééditant',
    'rééditées',
    'rééditent',
    'rééditera',
    'rééditiez',
    'réédition',
    'rééditons',
    'rééduquai',
    'rééduquas',
    'rééduquât',
    'rééduquée',
    'rééduquer',
    'rééduques',
    'rééduqués',
    'rééduquez',
    'réélirais',
    'réélirait',
    'rééliriez',
    'réélirons',
    'rééliront',
    'réélisais',
    'réélisait',
    'réélisant',
    'réélisent',
    'réélisiez',
    'réélisons',
    'réélurent',
    'réélusses',
    'réemploie',
    'réemplois',
    'réemploya',
    'réemployé',
    'réengagea',
    'réengagée',
    'réengager',
    'réengages',
    'réengagés',
    'réengagez',
    'réeraient',
    'réessaies',
    'réessayai',
    'réessayas',
    'réessayât',
    'réessayée',
    'réessayer',
    'réessayes',
    'réessayés',
    'réessayez',
    'réévaluai',
    'réévaluas',
    'réévaluât',
    'réévaluée',
    'réévaluer',
    'réévalues',
    'réévalués',
    'réévaluez',
    'réexamens',
    'réexamina',
    'réexamine',
    'réexaminé',
    'réexpédia',
    'réexpédie',
    'réexpédié',
    'réexporta',
    'réexporte',
    'réexporté',
    'refaçonna',
    'refaçonne',
    'refaçonné',
    'réfaction',
    'refaisais',
    'refaisait',
    'refaisant',
    'refaisiez',
    'refaisons',
    'refassent',
    'refassiez',
    'réfection',
    'refendais',
    'refendait',
    'refendant',
    'refendent',
    'refendiez',
    'refendons',
    'refendrai',
    'refendras',
    'refendrez',
    'refendues',
    'référâmes',
    'référasse',
    'référâtes',
    'référença',
    'référence',
    'référencé',
    'référents',
    'référerai',
    'référeras',
    'référerez',
    'referions',
    'référions',
    'refermais',
    'refermait',
    'refermant',
    'refermées',
    'referment',
    'refermera',
    'refermiez',
    'refermons',
    'refilâmes',
    'refilasse',
    'refilâtes',
    'refilerai',
    'refileras',
    'refilerez',
    'refilions',
    'refissent',
    'refissiez',
    'réfléchie',
    'réfléchir',
    'réfléchis',
    'réfléchit',
    'réfléchît',
    'réflectif',
    'reflétais',
    'reflétait',
    'reflétant',
    'reflétées',
    'reflètent',
    'reflétera',
    'reflétiez',
    'reflétons',
    'refleurie',
    'refleurir',
    'refleuris',
    'refleurit',
    'refleurît',
    'réflexifs',
    'réflexion',
    'réflexive',
    'refluâmes',
    'refluasse',
    'refluâtes',
    'refluerai',
    'reflueras',
    'refluerez',
    'refluions',
    'refondais',
    'refondait',
    'refondant',
    'refondent',
    'refondiez',
    'refondons',
    'refondrai',
    'refondras',
    'refondrez',
    'refondues',
    'reforgeai',
    'reforgeas',
    'reforgeât',
    'reforgées',
    'reforgent',
    'reforgera',
    'reforgiez',
    'reformais',
    'réformais',
    'réformait',
    'réformant',
    'reformées',
    'réformées',
    'reforment',
    'réforment',
    'reformera',
    'réformera',
    'réformiez',
    'réformons',
    'reformula',
    'reformule',
    'reformulé',
    'refouilla',
    'refouille',
    'refouillé',
    'refoulais',
    'refoulait',
    'refoulant',
    'refoulées',
    'refoulent',
    'refoulera',
    'refouliez',
    'refoulons',
    'refourrai',
    'refourras',
    'refourrât',
    'refourrée',
    'refourrer',
    'refourres',
    'refourrés',
    'refourrez',
    'réfractai',
    'réfractas',
    'réfractât',
    'réfractée',
    'réfracter',
    'réfractes',
    'réfractés',
    'réfractez',
    'refrénais',
    'réfrénais',
    'refrénait',
    'réfrénait',
    'refrénant',
    'réfrénant',
    'refrénées',
    'réfrénées',
    'refrènent',
    'réfrènent',
    'refrénera',
    'réfrénera',
    'refréniez',
    'réfréniez',
    'refrénons',
    'réfrénons',
    'réfrigéra',
    'réfrigéré',
    'réfrigère',
    'refroidie',
    'refroidir',
    'refroidis',
    'refroidit',
    'refroidît',
    'réfugiais',
    'réfugiait',
    'réfugiant',
    'réfugiées',
    'réfugient',
    'réfugiera',
    'réfugiiez',
    'réfugions',
    'refusable',
    'refusâmes',
    'refusasse',
    'refusâtes',
    'refuserai',
    'refuseras',
    'refuserez',
    'refusions',
    'réfutable',
    'réfutâmes',
    'réfutasse',
    'réfutâtes',
    'réfuterai',
    'réfuteras',
    'réfuterez',
    'réfutions',
    'refuzniks',
    'regagnais',
    'regagnait',
    'regagnant',
    'regagnées',
    'regagnent',
    'regagnera',
    'regagniez',
    'regagnons',
    'régalades',
    'régalages',
    'régalâmes',
    'régalasse',
    'régalâtes',
    'régalerai',
    'régaleras',
    'régalerez',
    'régaliens',
    'régalions',
    'regardais',
    'regardait',
    'regardant',
    'regardées',
    'regardent',
    'regardera',
    'regardiez',
    'regardons',
    'regarnies',
    'regarnira',
    'régatâmes',
    'régatasse',
    'régatâtes',
    'régaterai',
    'régateras',
    'régaterez',
    'régatière',
    'régatiers',
    'régations',
    'regazonna',
    'regazonne',
    'regazonné',
    'régénérai',
    'régénéras',
    'régénérât',
    'régénérée',
    'régénérer',
    'régénérés',
    'régénères',
    'régénérez',
    'régentais',
    'régentait',
    'régentant',
    'régentées',
    'régentent',
    'régentera',
    'régentiez',
    'régentons',
    'régicides',
    'regimbais',
    'regimbait',
    'regimbant',
    'regimbées',
    'regimbent',
    'regimbera',
    'regimbeur',
    'regimbiez',
    'regimbons',
    'régiments',
    'régionale',
    'régionaux',
    'régirions',
    'régissais',
    'régissait',
    'régissant',
    'régissent',
    'régisseur',
    'régissiez',
    'régissons',
    'registrai',
    'registras',
    'registrât',
    'registrée',
    'registrer',
    'registres',
    'registrés',
    'registrez',
    'réglables',
    'réglaient',
    'réglasses',
    'règlement',
    'réglerais',
    'réglerait',
    'réglèrent',
    'régleriez',
    'réglerons',
    'régleront',
    'réglettes',
    'régleuses',
    'réglisses',
    'régnaient',
    'régnantes',
    'régnasses',
    'régnerais',
    'régnerait',
    'régnèrent',
    'régneriez',
    'régnerons',
    'régneront',
    'regonflai',
    'regonflas',
    'regonflât',
    'regonflée',
    'regonfler',
    'regonfles',
    'regonflés',
    'regonflez',
    'regorgeai',
    'regorgeas',
    'regorgeât',
    'regorgent',
    'regorgera',
    'regorgiez',
    'regrattai',
    'regrattas',
    'regrattât',
    'regrattée',
    'regratter',
    'regrattes',
    'regrattés',
    'regrattez',
    'regréâmes',
    'regréasse',
    'regréâtes',
    'regréerai',
    'regréeras',
    'regréerez',
    'regreffai',
    'regreffas',
    'regreffât',
    'regreffée',
    'regreffer',
    'regreffes',
    'regreffés',
    'regreffez',
    'regréions',
    'régressai',
    'régressas',
    'régressât',
    'régresser',
    'régresses',
    'régressez',
    'régressif',
    'regrettai',
    'regrettas',
    'regrettât',
    'regrettée',
    'regretter',
    'regrettes',
    'regrettés',
    'regrettez',
    'regrimpai',
    'regrimpas',
    'regrimpât',
    'regrimpée',
    'regrimper',
    'regrimpes',
    'regrimpés',
    'regrimpez',
    'regrossir',
    'regrossis',
    'regrossit',
    'regrossît',
    'regroupai',
    'regroupas',
    'regroupât',
    'regroupée',
    'regrouper',
    'regroupes',
    'regroupés',
    'regroupez',
    'régulière',
    'réguliers',
    'régurgita',
    'régurgite',
    'régurgité',
    'réhabitua',
    'réhabitue',
    'réhabitué',
    'rehaussai',
    'rehaussas',
    'rehaussât',
    'rehaussée',
    'rehausser',
    'rehausses',
    'rehaussés',
    'rehaussez',
    'réhydrata',
    'réhydrate',
    'réhydraté',
    'reichstag',
    'réifiâmes',
    'réifiasse',
    'réifiâtes',
    'réifierai',
    'réifieras',
    'réifierez',
    'réifiions',
    'réimporta',
    'réimporte',
    'réimporté',
    'réimposai',
    'réimposas',
    'réimposât',
    'réimposée',
    'réimposer',
    'réimposes',
    'réimposés',
    'réimposez',
    'réimprima',
    'réimprime',
    'réimprimé',
    'réincarna',
    'réincarne',
    'réincarné',
    'reinettes',
    'réinfecta',
    'réinfecte',
    'réinfecté',
    'réinjecta',
    'réinjecte',
    'réinjecté',
    'réinscris',
    'réinscrit',
    'réinsérai',
    'réinséras',
    'réinsérât',
    'réinsérée',
    'réinsérer',
    'réinsérés',
    'réinsères',
    'réinsérez',
    'réintégra',
    'réintégré',
    'réintègre',
    'réinventa',
    'réinvente',
    'réinventé',
    'réinvesti',
    'réinvitai',
    'réinvitas',
    'réinvitât',
    'réinvitée',
    'réinviter',
    'réinvites',
    'réinvités',
    'réinvitez',
    'réitérais',
    'réitérait',
    'réitérant',
    'réitérées',
    'réitèrent',
    'réitérera',
    'réitériez',
    'réitérons',
    'rejaillir',
    'rejaillis',
    'rejaillit',
    'rejaillît',
    'rejetable',
    'rejetâmes',
    'rejetasse',
    'rejetâtes',
    'rejetions',
    'rejettent',
    'rejettera',
    'rejoignes',
    'rejoignez',
    'rejoignis',
    'rejoignit',
    'rejoignît',
    'rejoindra',
    'rejoindre',
    'rejointes',
    'rejouâmes',
    'rejouasse',
    'rejouâtes',
    'rejouerai',
    'rejoueras',
    'rejouerez',
    'réjouîmes',
    'rejouions',
    'réjouirai',
    'réjouiras',
    'réjouirez',
    'réjouisse',
    'réjouîtes',
    'rejugeais',
    'rejugeait',
    'rejugeant',
    'rejugeons',
    'rejugerai',
    'rejugeras',
    'rejugerez',
    'rejugions',
    'relâchais',
    'relâchait',
    'relâchant',
    'relâchées',
    'relâchent',
    'relâchera',
    'relâchiez',
    'relâchons',
    'relaierai',
    'relaieras',
    'relaierez',
    'relaissai',
    'relaissas',
    'relaissât',
    'relaissée',
    'relaisser',
    'relaisses',
    'relaissés',
    'relaissez',
    'relançais',
    'relançait',
    'relançant',
    'relancées',
    'relancent',
    'relancera',
    'relanciez',
    'relançons',
    'rélargies',
    'rélargira',
    'relatâmes',
    'relatasse',
    'relatâtes',
    'relaterai',
    'relateras',
    'relaterez',
    'relations',
    'relatives',
    'relavâmes',
    'relavasse',
    'relavâtes',
    'relaverai',
    'relaveras',
    'relaverez',
    'relavions',
    'relaxâmes',
    'relaxasse',
    'relaxâtes',
    'relaxerai',
    'relaxeras',
    'relaxerez',
    'relaxions',
    'relayâmes',
    'relayasse',
    'relayâtes',
    'relayerai',
    'relayeras',
    'relayerez',
    'relayeurs',
    'relayeuse',
    'relayions',
    'relecture',
    'reléguais',
    'reléguait',
    'reléguant',
    'reléguées',
    'relèguent',
    'reléguera',
    'reléguiez',
    'reléguons',
    'relevable',
    'relevâmes',
    'relevasse',
    'relevâtes',
    'relèverai',
    'relèveras',
    'relèverez',
    'releveurs',
    'releveuse',
    'relevions',
    'reliaient',
    'reliasses',
    'relierais',
    'relierait',
    'relièrent',
    'relieriez',
    'relierons',
    'relieront',
    'relieuses',
    'religieux',
    'religions',
    'reliquats',
    'relirions',
    'relisions',
    'relogeais',
    'relogeait',
    'relogeant',
    'relogeons',
    'relogerai',
    'relogeras',
    'relogerez',
    'relogions',
    'relouâmes',
    'relouasse',
    'relouâtes',
    'relouerai',
    'reloueras',
    'relouerez',
    'relouions',
    'reluirais',
    'reluirait',
    'reluiriez',
    'reluirons',
    'reluiront',
    'reluisais',
    'reluisait',
    'reluisant',
    'reluisent',
    'reluisiez',
    'reluisons',
    'reluquais',
    'reluquait',
    'reluquant',
    'reluquées',
    'reluquent',
    'reluquera',
    'reluquiez',
    'reluquons',
    'relussent',
    'relussiez',
    'remâchais',
    'remâchait',
    'remâchant',
    'remâchées',
    'remâchent',
    'remâchera',
    'remâchiez',
    'remâchons',
    'remaillai',
    'remaillas',
    'remaillât',
    'remaillée',
    'remailler',
    'remailles',
    'remaillés',
    'remaillez',
    'rémanence',
    'rémanentE',
    'rémanents',
    'remangeai',
    'remangeas',
    'remangeât',
    'remangées',
    'remangent',
    'remangera',
    'remangiez',
    'remaniais',
    'remaniait',
    'remaniant',
    'remaniées',
    'remanient',
    'remaniera',
    'remaniiez',
    'remanions',
    'remarchai',
    'remarchas',
    'remarchât',
    'remarcher',
    'remarches',
    'remarchez',
    'remariage',
    'remariais',
    'remariait',
    'remariant',
    'remariées',
    'remarient',
    'remariera',
    'remariiez',
    'remarions',
    'remarquai',
    'remarquas',
    'remarquât',
    'remarquée',
    'remarquer',
    'remarques',
    'remarqués',
    'remarquez',
    'remballai',
    'remballas',
    'remballât',
    'remballée',
    'remballer',
    'remballes',
    'remballés',
    'remballez',
    'rembarqua',
    'rembarque',
    'rembarqué',
    'rembarrai',
    'rembarras',
    'rembarrât',
    'rembarrée',
    'rembarrer',
    'rembarres',
    'rembarrés',
    'rembarrez',
    'rembinais',
    'rembinait',
    'rembinant',
    'rembinées',
    'rembinent',
    'rembinera',
    'rembiniez',
    'rembinons',
    'remblaies',
    'remblavai',
    'remblavas',
    'remblavât',
    'remblavée',
    'remblaver',
    'remblaves',
    'remblavés',
    'remblavez',
    'remblayai',
    'remblayas',
    'remblayât',
    'remblayée',
    'remblayer',
    'remblayes',
    'remblayés',
    'remblayez',
    'remboîtai',
    'remboîtas',
    'remboîtât',
    'remboîtée',
    'remboîter',
    'remboîtes',
    'remboîtés',
    'remboîtez',
    'rembougea',
    'rembougée',
    'rembouger',
    'rembouges',
    'rembougés',
    'rembougez',
    'rembourra',
    'rembourre',
    'rembourré',
    'remboursa',
    'rembourse',
    'remboursé',
    'rembrunie',
    'rembrunir',
    'rembrunis',
    'rembrunit',
    'rembrunît',
    'rembuchai',
    'rembuchas',
    'rembuchât',
    'rembuchée',
    'rembucher',
    'rembuches',
    'rembuchés',
    'rembuchez',
    'remédiais',
    'remédiait',
    'remédiant',
    'remédient',
    'remédiera',
    'remédiiez',
    'remédions',
    'remembrai',
    'remembras',
    'remembrât',
    'remembrée',
    'remembrer',
    'remembres',
    'remembrés',
    'remembrez',
    'remémorai',
    'remémoras',
    'remémorât',
    'remémorée',
    'remémorer',
    'remémores',
    'remémorés',
    'remémorez',
    'remerciai',
    'remercias',
    'remerciât',
    'remerciée',
    'remercier',
    'remercies',
    'remerciés',
    'remerciez',
    'remettais',
    'remettait',
    'remettant',
    'remettent',
    'remettiez',
    'remettons',
    'remettrai',
    'remettras',
    'remettrez',
    'remeublai',
    'remeublas',
    'remeublât',
    'remeublée',
    'remeubler',
    'remeubles',
    'remeublés',
    'remeublez',
    'remisages',
    'remisâmes',
    'remisasse',
    'remisâtes',
    'remiserai',
    'remiseras',
    'remiserez',
    'remisiers',
    'remisions',
    'remissent',
    'remissiez',
    'rémission',
    'rémittent',
    'remmailla',
    'remmaille',
    'remmaillé',
    'remmancha',
    'remmanche',
    'remmanché',
    'remmenais',
    'remmenait',
    'remmenant',
    'remmenées',
    'remmènent',
    'remmènera',
    'remmeniez',
    'remmenons',
    'remodelai',
    'remodelas',
    'remodelât',
    'remodelée',
    'remodeler',
    'remodelés',
    'remodèles',
    'remodelez',
    'remontage',
    'remontais',
    'remontait',
    'remontant',
    'remontées',
    'remontent',
    'remontera',
    'remonteur',
    'remontiez',
    'remontoir',
    'remontons',
    'remontrai',
    'remontras',
    'remontrât',
    'remontrée',
    'remontrer',
    'remontres',
    'remontrés',
    'remontrez',
    'remordais',
    'remordait',
    'remordant',
    'remordent',
    'remordiez',
    'remordons',
    'remordrai',
    'remordras',
    'remordrez',
    'remordues',
    'remorquai',
    'remorquas',
    'remorquât',
    'remorquée',
    'remorquer',
    'remorques',
    'remorqués',
    'remorquez',
    'remouchai',
    'remouchas',
    'remouchât',
    'remouchée',
    'remoucher',
    'remouches',
    'remouchés',
    'remouchez',
    'remoudrai',
    'remoudras',
    'remoudrez',
    'remouilla',
    'remouille',
    'remouillé',
    'rémoulade',
    'remoulais',
    'remoulait',
    'remoulant',
    'remoulent',
    'rémouleur',
    'remouliez',
    'remoulons',
    'remoulues',
    'rempailla',
    'rempaille',
    'rempaillé',
    'remparais',
    'remparait',
    'remparant',
    'remparées',
    'remparent',
    'remparera',
    'rempariez',
    'remparons',
    'rempiétai',
    'rempiétas',
    'rempiétât',
    'rempiétée',
    'rempiéter',
    'rempiétés',
    'rempiètes',
    'rempiétez',
    'rempilais',
    'rempilait',
    'rempilant',
    'rempilées',
    'rempilent',
    'rempilera',
    'rempiliez',
    'rempilons',
    'remplaçai',
    'remplaças',
    'remplaçât',
    'remplacée',
    'remplacer',
    'remplaces',
    'remplacés',
    'remplacez',
    'remplages',
    'rempliais',
    'rempliait',
    'rempliant',
    'rempliées',
    'remplient',
    'rempliera',
    'rempliiez',
    'remplîmes',
    'remplions',
    'remplirai',
    'rempliras',
    'remplirez',
    'remplisse',
    'remplîtes',
    'remploies',
    'remployai',
    'remployas',
    'remployât',
    'remployée',
    'remployer',
    'remployés',
    'remployez',
    'remplumai',
    'remplumas',
    'remplumât',
    'remplumée',
    'remplumer',
    'remplumes',
    'remplumés',
    'remplumez',
    'rempochai',
    'rempochas',
    'rempochât',
    'rempochée',
    'rempocher',
    'rempoches',
    'rempochés',
    'rempochez',
    'remportai',
    'remportas',
    'remportât',
    'remportée',
    'remporter',
    'remportes',
    'remportés',
    'remportez',
    'rempotais',
    'rempotait',
    'rempotant',
    'rempotées',
    'rempotent',
    'rempotera',
    'rempotiez',
    'rempotons',
    'remprunta',
    'remprunte',
    'remprunté',
    'remuaient',
    'remuantes',
    'remuasses',
    'remuement',
    'remuerais',
    'remuerait',
    'remuèrent',
    'remueriez',
    'remuerons',
    'remueront',
    'rémunérai',
    'rémunéras',
    'rémunérât',
    'rémunérée',
    'rémunérer',
    'rémunérés',
    'rémunères',
    'rémunérez',
    'renâclais',
    'renâclait',
    'renâclant',
    'renâclent',
    'renâclera',
    'renâcliez',
    'renâclons',
    'renaisses',
    'renaissez',
    'renaîtrai',
    'renaîtras',
    'renaîtrez',
    'renardais',
    'renardait',
    'renardant',
    'renardeau',
    'renardent',
    'renardera',
    'renardiez',
    'renardons',
    'renaudais',
    'renaudait',
    'renaudant',
    'renaudent',
    'renaudera',
    'renaudiez',
    'renaudons',
    'rencaissa',
    'rencaisse',
    'rencaissé',
    'rencardai',
    'rencardas',
    'rencardât',
    'rencardée',
    'rencarder',
    'rencardes',
    'rencardés',
    'renchaîna',
    'renchaîne',
    'renchaîné',
    'renchérir',
    'renchéris',
    'renchérit',
    'renchérît',
    'rencognai',
    'rencognas',
    'rencognât',
    'rencognée',
    'rencogner',
    'rencognes',
    'rencognés',
    'rencognez',
    'rencontra',
    'rencontre',
    'rencontré',
    'rendaient',
    'rendement',
    'rendirent',
    'rendisses',
    'rendormes',
    'rendormez',
    'rendormie',
    'rendormir',
    'rendormis',
    'rendormit',
    'rendormît',
    'rendossai',
    'rendossas',
    'rendossât',
    'rendossée',
    'rendosser',
    'rendosses',
    'rendossés',
    'rendossez',
    'rendrions',
    'renégates',
    'renégocia',
    'renégocie',
    'renégocié',
    'reneigeât',
    'reneigera',
    'renfaîtai',
    'renfaîtas',
    'renfaîtât',
    'renfaîtée',
    'renfaîter',
    'renfaîtes',
    'renfaîtés',
    'renfaîtez',
    'renfermai',
    'renfermas',
    'renfermât',
    'renfermée',
    'renfermer',
    'renfermes',
    'renfermés',
    'renfermez',
    'renfilais',
    'renfilait',
    'renfilant',
    'renfilées',
    'renfilent',
    'renfilera',
    'renfiliez',
    'renfilons',
    'renflâmes',
    'renflamma',
    'renflamme',
    'renflammé',
    'renflasse',
    'renflâtes',
    'renflerai',
    'renfleras',
    'renflerez',
    'renflions',
    'renflouai',
    'renflouas',
    'renflouât',
    'renflouée',
    'renflouer',
    'renfloues',
    'renfloués',
    'renflouez',
    'renfonçai',
    'renfonças',
    'renfonçât',
    'renfoncée',
    'renfoncer',
    'renfonces',
    'renfoncés',
    'renfoncez',
    'renforçai',
    'renforças',
    'renforçât',
    'renforcée',
    'renforcer',
    'renforces',
    'renforcés',
    'renforcez',
    'renformie',
    'renformir',
    'renformis',
    'renfrogna',
    'renfrogne',
    'renfrogné',
    'rengageai',
    'rengageas',
    'rengageât',
    'rengagées',
    'rengagent',
    'rengagera',
    'rengagiez',
    'rengainai',
    'rengainas',
    'rengainât',
    'rengainée',
    'rengainer',
    'rengaines',
    'rengainés',
    'rengainez',
    'rengorgea',
    'rengorgée',
    'rengorger',
    'rengorges',
    'rengorgés',
    'rengorgez',
    'rengracia',
    'rengracie',
    'rengracié',
    'rengrenai',
    'rengrénai',
    'rengrenas',
    'rengrénas',
    'rengrenât',
    'rengrénât',
    'rengrenée',
    'rengrénée',
    'rengrener',
    'rengréner',
    'rengrenés',
    'rengrénés',
    'rengrènes',
    'rengrenez',
    'rengrénez',
    'reniaient',
    'reniasses',
    'reniement',
    'renierais',
    'renierait',
    'renièrent',
    'renieriez',
    'renierons',
    'renieront',
    'reniflais',
    'reniflait',
    'reniflant',
    'reniflard',
    'reniflées',
    'reniflent',
    'reniflera',
    'renifleur',
    'renifliez',
    'reniflons',
    'réniforme',
    'rénitence',
    'renommais',
    'renommait',
    'renommant',
    'renommées',
    'renomment',
    'renommera',
    'renommiez',
    'renommons',
    'renonçais',
    'renonçait',
    'renonçant',
    'renoncées',
    'renoncent',
    'renoncera',
    'renonciez',
    'renonçons',
    'renoncule',
    'renouâmes',
    'renouasse',
    'renouâtes',
    'renouerai',
    'renoueras',
    'renouerez',
    'renouions',
    'renouveau',
    'renouvela',
    'renouvelé',
    'rénovâmes',
    'rénovasse',
    'rénovâtes',
    'rénoverai',
    'rénoveras',
    'rénoverez',
    'rénovions',
    'renquilla',
    'renquille',
    'renquillé',
    'renseigna',
    'renseigne',
    'renseigné',
    'rentables',
    'rentaient',
    'rentamais',
    'rentamait',
    'rentamant',
    'rentamées',
    'rentament',
    'rentamera',
    'rentamiez',
    'rentamons',
    'rentasses',
    'renterais',
    'renterait',
    'rentèrent',
    'renteriez',
    'renterons',
    'renteront',
    'rentières',
    'rentoilai',
    'rentoilas',
    'rentoilât',
    'rentoilée',
    'rentoiler',
    'rentoiles',
    'rentoilés',
    'rentoilez',
    'rentrâmes',
    'rentrante',
    'rentrants',
    'rentrasse',
    'rentrâtes',
    'rentrerai',
    'rentreras',
    'rentrerez',
    'rentrions',
    'renvenima',
    'renvenime',
    'renvenimé',
    'renvergea',
    'renvergée',
    'renverger',
    'renverges',
    'renvergés',
    'renvergez',
    'renverrai',
    'renverras',
    'renverrez',
    'renversai',
    'renversas',
    'renversât',
    'renversée',
    'renverser',
    'renverses',
    'renversés',
    'renversez',
    'renviâmes',
    'renviasse',
    'renviâtes',
    'renvidage',
    'renvidais',
    'renvidait',
    'renvidant',
    'renvidées',
    'renvident',
    'renvidera',
    'renvideur',
    'renvidiez',
    'renvidons',
    'renvierai',
    'renvieras',
    'renvierez',
    'renviions',
    'renvoient',
    'renvoyais',
    'renvoyait',
    'renvoyant',
    'renvoyées',
    'renvoyiez',
    'renvoyons',
    'réoccupai',
    'réoccupas',
    'réoccupât',
    'réoccupée',
    'réoccuper',
    'réoccupes',
    'réoccupés',
    'réoccupez',
    'réopérais',
    'réopérait',
    'réopérant',
    'réopérées',
    'réopèrent',
    'réopérera',
    'réopériez',
    'réopérons',
    'réordonna',
    'réordonne',
    'réordonné',
    'réorienta',
    'réoriente',
    'réorienté',
    'repaierai',
    'repaieras',
    'repaierez',
    'repairais',
    'repairait',
    'repairant',
    'repairent',
    'repairera',
    'repairiez',
    'repairons',
    'repaisses',
    'repaissez',
    'repaîtrai',
    'repaîtras',
    'repaîtrez',
    'répandais',
    'répandait',
    'répandant',
    'répandent',
    'répandiez',
    'répandons',
    'répandrai',
    'répandras',
    'répandrez',
    'répandues',
    'réparable',
    'réparâmes',
    'réparasse',
    'réparâtes',
    'réparerai',
    'répareras',
    'réparerez',
    'réparions',
    'reparlais',
    'reparlait',
    'reparlant',
    'reparlent',
    'reparlera',
    'reparliez',
    'reparlons',
    'repartage',
    'repartagé',
    'repartais',
    'repartait',
    'repartant',
    'repartent',
    'reparties',
    'réparties',
    'repartiez',
    'repartira',
    'répartira',
    'repartons',
    'reparûmes',
    'reparusse',
    'reparûtes',
    'repassage',
    'repassais',
    'repassait',
    'repassant',
    'repassées',
    'repassent',
    'repassera',
    'repasseur',
    'repassiez',
    'repassons',
    'repatinai',
    'repatinas',
    'repatinât',
    'repatinée',
    'repatiner',
    'repatines',
    'repatinés',
    'repatinez',
    'repavâmes',
    'repavasse',
    'repavâtes',
    'repaverai',
    'repaveras',
    'repaverez',
    'repavions',
    'repayâmes',
    'repayasse',
    'repayâtes',
    'repayerai',
    'repayeras',
    'repayerez',
    'repayions',
    'repêchage',
    'repêchais',
    'repêchait',
    'repêchant',
    'repêchées',
    'repêchent',
    'repêchera',
    'repêchiez',
    'repêchons',
    'repeignai',
    'repeignas',
    'repeignât',
    'repeignée',
    'repeigner',
    'repeignes',
    'repeignés',
    'repeignez',
    'repeignis',
    'repeignit',
    'repeignît',
    'repeindra',
    'repeindre',
    'repeintes',
    'rependais',
    'rependait',
    'rependant',
    'rependent',
    'rependiez',
    'rependons',
    'rependrai',
    'rependras',
    'rependrez',
    'rependues',
    'repensais',
    'repensait',
    'repensant',
    'repensées',
    'repensent',
    'repensera',
    'repensiez',
    'repensons',
    'repentais',
    'repentait',
    'repentant',
    'repentent',
    'repenties',
    'repentiez',
    'repentira',
    'repentons',
    'repérable',
    'repérages',
    'repérâmes',
    'repérasse',
    'repérâtes',
    'reperçais',
    'reperçait',
    'reperçant',
    'repercées',
    'repercent',
    'repercera',
    'reperciez',
    'reperçons',
    'répercuta',
    'répercute',
    'répercuté',
    'reperdais',
    'reperdait',
    'reperdant',
    'reperdent',
    'reperdiez',
    'reperdons',
    'reperdrai',
    'reperdras',
    'reperdrez',
    'reperdues',
    'repérerai',
    'repéreras',
    'repérerez',
    'repérions',
    'répétable',
    'répétâmes',
    'répétasse',
    'répétâtes',
    'répéterai',
    'répéteras',
    'répéterez',
    'répéteurs',
    'répétions',
    'répétitif',
    'repeuplai',
    'repeuplas',
    'repeuplât',
    'repeuplée',
    'repeupler',
    'repeuples',
    'repeuplés',
    'repeuplez',
    'repinçais',
    'repinçait',
    'repinçant',
    'repincées',
    'repincent',
    'repincera',
    'repinciez',
    'repinçons',
    'repiquage',
    'repiquais',
    'repiquait',
    'repiquant',
    'repiquées',
    'repiquent',
    'repiquera',
    'repiquiez',
    'repiquons',
    'replaçais',
    'replaçait',
    'replaçant',
    'replacées',
    'replacent',
    'replacera',
    'replaciez',
    'replaçons',
    'replantai',
    'replantas',
    'replantât',
    'replantée',
    'replanter',
    'replantes',
    'replantés',
    'replantez',
    'replâtrai',
    'replâtras',
    'replâtrât',
    'replâtrée',
    'replâtrer',
    'replâtres',
    'replâtrés',
    'replâtrez',
    'réplétifs',
    'réplétion',
    'réplétive',
    'repleuvra',
    'repliable',
    'repliâmes',
    'repliasse',
    'repliâtes',
    'replierai',
    'replieras',
    'replierez',
    'repliions',
    'répliquai',
    'répliquas',
    'répliquât',
    'répliquée',
    'répliquer',
    'répliques',
    'répliqués',
    'répliquez',
    'replissai',
    'replissas',
    'replissât',
    'replissée',
    'replisser',
    'replisses',
    'replissés',
    'replissez',
    'reploient',
    'reploiera',
    'replongea',
    'replongée',
    'replonger',
    'replonges',
    'replongés',
    'replongez',
    'reployais',
    'reployait',
    'reployant',
    'reployées',
    'reployiez',
    'reployons',
    'replurent',
    'repolîmes',
    'repolirai',
    'repoliras',
    'repolirez',
    'repolisse',
    'repolîtes',
    'répondais',
    'répondait',
    'répondant',
    'répondent',
    'répondeur',
    'répondiez',
    'répondons',
    'répondrai',
    'répondras',
    'répondrez',
    'répondues',
    'reportage',
    'reportais',
    'reportait',
    'reportant',
    'reportées',
    'reportent',
    'reportera',
    'reporteur',
    'reportiez',
    'reportons',
    'reposâmes',
    'reposante',
    'reposants',
    'reposasse',
    'reposâtes',
    'reposerai',
    'reposeras',
    'reposerez',
    'reposions',
    'reposoirs',
    'repoussai',
    'repoussas',
    'repoussât',
    'repoussée',
    'repousser',
    'repousses',
    'repoussés',
    'repoussez',
    'reprenais',
    'reprenait',
    'reprenant',
    'reprendra',
    'reprendre',
    'repreniez',
    'reprennes',
    'reprenons',
    'répressif',
    'réprimais',
    'réprimait',
    'réprimant',
    'réprimées',
    'répriment',
    'réprimera',
    'réprimiez',
    'réprimons',
    'reprirent',
    'reprisage',
    'reprisais',
    'reprisait',
    'reprisant',
    'reprisées',
    'reprisent',
    'reprisera',
    'reprisiez',
    'reprisons',
    'reprisses',
    'reprochai',
    'reprochas',
    'reprochât',
    'reprochée',
    'reprocher',
    'reproches',
    'reprochés',
    'reprochez',
    'reproduis',
    'reproduit',
    'reprouvai',
    'réprouvai',
    'reprouvas',
    'réprouvas',
    'reprouvât',
    'réprouvât',
    'reprouvée',
    'réprouvée',
    'reprouver',
    'réprouver',
    'reprouves',
    'reprouvés',
    'réprouves',
    'réprouvés',
    'reprouvez',
    'réprouvez',
    'reptation',
    'répudiais',
    'répudiait',
    'répudiant',
    'répudiées',
    'répudient',
    'répudiera',
    'répudiiez',
    'répudions',
    'répugnais',
    'répugnait',
    'répugnant',
    'répugnent',
    'répugnera',
    'répugniez',
    'répugnons',
    'répulsion',
    'repussent',
    'repussiez',
    'réputâmes',
    'réputasse',
    'réputâtes',
    'réputerai',
    'réputeras',
    'réputerez',
    'réputions',
    'requérais',
    'requérait',
    'requérant',
    'requériez',
    'requérons',
    'requerrai',
    'requerras',
    'requerrez',
    'requérues',
    'requières',
    'requinqua',
    'requinque',
    'requinqué',
    'requirent',
    'réquisits',
    'requisses',
    'resalâmes',
    'resalasse',
    'resalâtes',
    'resalerai',
    'resaleras',
    'resalerez',
    'resalions',
    'resalirai',
    'resaliras',
    'resalirez',
    'resaluais',
    'resaluait',
    'resaluant',
    'resaluées',
    'resaluent',
    'resaluera',
    'resaluiez',
    'resaluons',
    'rescapées',
    'rescindai',
    'rescindas',
    'rescindât',
    'rescinder',
    'rescindes',
    'rescindez',
    'rescision',
    'rescousse',
    'résection',
    'reséquais',
    'reséquait',
    'reséquant',
    'reséquées',
    'resèquent',
    'reséquera',
    'reséquiez',
    'reséquons',
    'réserpine',
    'réservais',
    'réservait',
    'réservant',
    'réservées',
    'réservent',
    'réservera',
    'réserviez',
    'réservoir',
    'réservons',
    'résidâmes',
    'résidante',
    'résidants',
    'résidasse',
    'résidâtes',
    'résidence',
    'résidente',
    'résidents',
    'résiderai',
    'résideras',
    'résiderez',
    'résidions',
    'résiduels',
    'résignais',
    'résignait',
    'résignant',
    'résignées',
    'résignent',
    'résignera',
    'résigniez',
    'résignons',
    'résiliais',
    'résiliait',
    'résiliant',
    'résiliées',
    'résilient',
    'résiliera',
    'résiliiez',
    'résilions',
    'résinâmes',
    'résinasse',
    'résinâtes',
    'résinerai',
    'résineras',
    'résinerez',
    'résineuse',
    'résinière',
    'résiniers',
    'résinifia',
    'résinifie',
    'résinifié',
    'résinions',
    'résistais',
    'résistait',
    'résistant',
    'résistent',
    'résistera',
    'résistiez',
    'résistons',
    'résolûmes',
    'résolusse',
    'résolûtes',
    'résolutif',
    'résolvais',
    'résolvait',
    'résolvant',
    'résolvent',
    'résolviez',
    'résolvons',
    'résonance',
    'résonante',
    'résonants',
    'résonnais',
    'résonnait',
    'résonnant',
    'résonnent',
    'résonnera',
    'résonniez',
    'résonnons',
    'résorbais',
    'résorbait',
    'résorbant',
    'résorbées',
    'résorbent',
    'résorbera',
    'résorbiez',
    'résorbons',
    'résorcine',
    'résoudrai',
    'résoudras',
    'résoudrez',
    'respectai',
    'respectas',
    'respectât',
    'respectée',
    'respecter',
    'respectes',
    'respectés',
    'respectez',
    'respectif',
    'respirais',
    'respirait',
    'respirant',
    'respirées',
    'respirent',
    'respirera',
    'respiriez',
    'respirons',
    'resplendi',
    'resquilla',
    'resquille',
    'resquillé',
    'ressaient',
    'ressaiera',
    'ressaigna',
    'ressaigne',
    'ressaigné',
    'ressaisie',
    'ressaisir',
    'ressaisis',
    'ressaisit',
    'ressaisît',
    'ressassai',
    'ressassas',
    'ressassât',
    'ressassée',
    'ressasser',
    'ressasses',
    'ressassés',
    'ressassez',
    'ressautai',
    'ressautas',
    'ressautât',
    'ressautée',
    'ressauter',
    'ressautes',
    'ressautés',
    'ressautez',
    'ressayage',
    'ressayais',
    'ressayait',
    'ressayant',
    'ressayées',
    'ressayent',
    'ressayera',
    'ressayiez',
    'ressayons',
    'ressemais',
    'ressemait',
    'ressemant',
    'ressembla',
    'ressemble',
    'ressemblé',
    'ressemées',
    'ressemela',
    'ressemelé',
    'ressèment',
    'ressèmera',
    'ressemiez',
    'ressemons',
    'ressentes',
    'ressentez',
    'ressentie',
    'ressentir',
    'ressentis',
    'ressentit',
    'ressentît',
    'resserrai',
    'resserras',
    'resserrât',
    'resserrée',
    'resserrer',
    'resserres',
    'resserrés',
    'resserrez',
    'resserves',
    'resservez',
    'resservie',
    'resservir',
    'resservis',
    'resservit',
    'resservît',
    'ressortes',
    'ressortez',
    'ressortie',
    'ressortir',
    'ressortis',
    'ressortit',
    'ressortît',
    'ressoudai',
    'ressoudas',
    'ressoudât',
    'ressoudée',
    'ressouder',
    'ressoudes',
    'ressoudés',
    'ressoudez',
    'ressourça',
    'ressource',
    'ressourcé',
    'ressuâmes',
    'ressuasse',
    'ressuâtes',
    'ressuerai',
    'ressueras',
    'ressuerez',
    'ressuient',
    'ressuiera',
    'ressuions',
    'ressurgir',
    'ressurgis',
    'ressurgit',
    'ressurgît',
    'ressuyage',
    'ressuyais',
    'ressuyait',
    'ressuyant',
    'ressuyées',
    'ressuyiez',
    'ressuyons',
    'restaient',
    'restantes',
    'restasses',
    'restaurai',
    'restauras',
    'restaurât',
    'restaurée',
    'restaurer',
    'restaures',
    'restaurés',
    'restaurez',
    'resterais',
    'resterait',
    'restèrent',
    'resteriez',
    'resterons',
    'resteront',
    'restituai',
    'restituas',
    'restituât',
    'restituée',
    'restituer',
    'restitues',
    'restitués',
    'restituez',
    'restreins',
    'restreint',
    'résultait',
    'résultant',
    'résultats',
    'résultera',
    'résumâmes',
    'résumasse',
    'résumâtes',
    'résumerai',
    'résumeras',
    'résumerez',
    'résumions',
    'resurgira',
    'rétablies',
    'rétablira',
    'retaillai',
    'retaillas',
    'retaillât',
    'retaillée',
    'retailler',
    'retailles',
    'retaillés',
    'retaillez',
    'rétamages',
    'rétamâmes',
    'rétamasse',
    'rétamâtes',
    'rétamerai',
    'rétameras',
    'rétamerez',
    'rétameurs',
    'rétamions',
    'retapâmes',
    'retapasse',
    'retapâtes',
    'retaperai',
    'retaperas',
    'retaperez',
    'retapions',
    'retapissa',
    'retapisse',
    'retapissé',
    'retardais',
    'retardait',
    'retardant',
    'retardées',
    'retardent',
    'retardera',
    'retardiez',
    'retardons',
    'retâtâmes',
    'retâtasse',
    'retâtâtes',
    'retâterai',
    'retâteras',
    'retâterez',
    'retâtions',
    'reteignes',
    'reteignez',
    'reteignis',
    'reteignit',
    'reteignît',
    'reteindra',
    'reteindre',
    'reteintes',
    'retenante',
    'retenants',
    'retendais',
    'retendait',
    'retendant',
    'retendent',
    'retendiez',
    'retendons',
    'retendrai',
    'retendras',
    'retendrez',
    'retendues',
    'retenions',
    'retentais',
    'retentait',
    'retentant',
    'retentées',
    'retentent',
    'retentera',
    'retentiez',
    'rétention',
    'retentira',
    'retentons',
    'reterçais',
    'reterçait',
    'reterçant',
    'retercées',
    'retercent',
    'retercera',
    'reterciez',
    'reterçons',
    'retersais',
    'retersait',
    'retersant',
    'retersées',
    'retersent',
    'retersera',
    'retersiez',
    'retersons',
    'rétiaires',
    'réticence',
    'réticente',
    'réticents',
    'réticulai',
    'réticulas',
    'réticulât',
    'réticulée',
    'réticuler',
    'réticules',
    'réticulés',
    'réticulez',
    'réticulum',
    'retiendra',
    'retiennes',
    'rétiniens',
    'rétinites',
    'retinrent',
    'retinsses',
    'retirages',
    'retirâmes',
    'retirasse',
    'retirâtes',
    'retirerai',
    'retireras',
    'retirerez',
    'retirions',
    'retissais',
    'retissait',
    'retissant',
    'retissées',
    'retissent',
    'retissera',
    'retissiez',
    'retissons',
    'rétivités',
    'retombais',
    'retombait',
    'retombant',
    'retombées',
    'retombent',
    'retombera',
    'retombiez',
    'retombons',
    'retondais',
    'retondait',
    'retondant',
    'retondent',
    'retondiez',
    'retondons',
    'retondrai',
    'retondras',
    'retondrez',
    'retondues',
    'retordage',
    'retordais',
    'retordait',
    'retordant',
    'retordent',
    'retordeur',
    'retordiez',
    'retordons',
    'retordrai',
    'retordras',
    'retordrez',
    'retordues',
    'rétorquai',
    'rétorquas',
    'rétorquât',
    'rétorquée',
    'rétorquer',
    'rétorques',
    'rétorqués',
    'rétorquez',
    'rétorsion',
    'retouchai',
    'retouchas',
    'retouchât',
    'retouchée',
    'retoucher',
    'retouches',
    'retouchés',
    'retouchez',
    'retournai',
    'retournas',
    'retournât',
    'retournée',
    'retourner',
    'retournes',
    'retournés',
    'retournez',
    'retraçais',
    'retraçait',
    'retraçant',
    'retracées',
    'retracent',
    'retracera',
    'retraciez',
    'retraçons',
    'rétractai',
    'rétractas',
    'rétractât',
    'rétractée',
    'rétracter',
    'rétractes',
    'rétractés',
    'rétractez',
    'retraduis',
    'retraduit',
    'retraient',
    'retrairai',
    'retrairas',
    'retrairez',
    'retraitai',
    'retraitas',
    'retraitât',
    'retraitée',
    'retraiter',
    'retraites',
    'retraités',
    'retraitez',
    'retrancha',
    'retranche',
    'retranché',
    'retrayais',
    'retrayait',
    'retrayant',
    'retrayiez',
    'retrayons',
    'rétrécies',
    'rétrécira',
    'retrempai',
    'retrempas',
    'retrempât',
    'retrempée',
    'retremper',
    'retrempes',
    'retrempés',
    'retrempez',
    'rétribuai',
    'rétribuas',
    'rétribuât',
    'rétribuée',
    'rétribuer',
    'rétribues',
    'rétribués',
    'rétribuez',
    'retriever',
    'rétroacte',
    'rétroagir',
    'rétroagis',
    'rétroagit',
    'rétroagît',
    'rétrocéda',
    'rétrocédé',
    'rétrocède',
    'retroussa',
    'retrousse',
    'retroussé',
    'retrouvai',
    'retrouvas',
    'retrouvât',
    'retrouvée',
    'retrouver',
    'retrouves',
    'retrouvés',
    'retrouvez',
    'réunifiai',
    'réunifias',
    'réunifiât',
    'réunifiée',
    'réunifier',
    'réunifies',
    'réunifiés',
    'réunifiez',
    'réunirais',
    'réunirait',
    'réunirent',
    'réuniriez',
    'réunirons',
    'réuniront',
    'réunisses',
    'réunissez',
    'réussîmes',
    'réussirai',
    'réussiras',
    'réussirez',
    'réussisse',
    'réussites',
    'réussîtes',
    'réutilisa',
    'réutilise',
    'réutilisé',
    'revaccina',
    'revaccine',
    'revacciné',
    'revailles',
    'revalions',
    'revalûmes',
    'revalusse',
    'revalûtes',
    'revanchai',
    'revanchas',
    'revanchât',
    'revanchée',
    'revancher',
    'revanches',
    'revanchés',
    'revanchez',
    'rêvassais',
    'rêvassait',
    'rêvassant',
    'rêvassent',
    'rêvassera',
    'rêvasseur',
    'rêvassiez',
    'rêvassons',
    'revaudrai',
    'revaudras',
    'revaudrez',
    'revécûmes',
    'revécusse',
    'revécûtes',
    'réveillai',
    'réveillas',
    'réveillât',
    'réveillée',
    'réveiller',
    'réveilles',
    'réveillés',
    'réveillez',
    'réveillon',
    'révélâmes',
    'révélasse',
    'révélâtes',
    'révélerai',
    'révéleras',
    'révélerez',
    'révélions',
    'revenante',
    'revenants',
    'revendais',
    'revendait',
    'revendant',
    'revendent',
    'revendeur',
    'revendiez',
    'revendons',
    'revendrai',
    'revendras',
    'revendrez',
    'revendues',
    'revenez-y',
    'revenions',
    'révérâmes',
    'révérasse',
    'révérâtes',
    'réverbéra',
    'réverbéré',
    'réverbère',
    'reverchai',
    'reverchas',
    'reverchât',
    'reverchée',
    'revercher',
    'reverches',
    'reverchés',
    'reverchez',
    'reverdies',
    'reverdira',
    'révérence',
    'révérende',
    'révérends',
    'révérerai',
    'révéreras',
    'révérerez',
    'révérions',
    'rêverions',
    'revernies',
    'revernira',
    'reverrais',
    'reverrait',
    'reverriez',
    'reverrons',
    'reverront',
    'reversais',
    'reversait',
    'reversant',
    'reversées',
    'reversent',
    'reversera',
    'reversiez',
    'réversion',
    'reversons',
    'revêtîmes',
    'revêtions',
    'revêtirai',
    'revêtiras',
    'revêtirez',
    'revêtisse',
    'revêtîtes',
    'reveuille',
    'reveulent',
    'reviendra',
    'reviennes',
    'revigorai',
    'revigoras',
    'revigorât',
    'revigorée',
    'revigorer',
    'revigores',
    'revigorés',
    'revigorez',
    'revinrent',
    'revinsses',
    'revirâmes',
    'revirasse',
    'revirâtes',
    'revirerai',
    'revireras',
    'revirerez',
    'revirions',
    'révisable',
    'révisâmes',
    'révisasse',
    'révisâtes',
    'réviserai',
    'réviseras',
    'réviserez',
    'réviseurs',
    'révisions',
    'revissais',
    'revissait',
    'revissant',
    'revissées',
    'revissent',
    'revissera',
    'revissiez',
    'revissons',
    'revivifia',
    'revivifie',
    'revivifié',
    'revivions',
    'revivrais',
    'revivrait',
    'revivriez',
    'revivrons',
    'revivront',
    'révocable',
    'revolâmes',
    'revolasse',
    'revolâtes',
    'revolerai',
    'revoleras',
    'revolerez',
    'revolions',
    'révoltais',
    'révoltait',
    'révoltant',
    'révoltées',
    'révoltent',
    'révoltera',
    'révoltiez',
    'révoltons',
    'revolvers',
    'révoquais',
    'révoquait',
    'révoquant',
    'révoquées',
    'révoquent',
    'révoquera',
    'révoquiez',
    'révoquons',
    'revotâmes',
    'revotasse',
    'revotâtes',
    'revoterai',
    'revoteras',
    'revoterez',
    'revotions',
    'revoudrai',
    'revoudras',
    'revoudrez',
    'revoulais',
    'revoulait',
    'revoulant',
    'revouliez',
    'revouloir',
    'revoulons',
    'revoulues',
    'revoyions',
    'revoyures',
    'révulsais',
    'révulsait',
    'révulsant',
    'révulsées',
    'révulsent',
    'révulsera',
    'révulsiez',
    'révulsifs',
    'révulsion',
    'révulsive',
    'révulsons',
    'rewriters',
    'rewriting',
    'rhabillai',
    'rhabillas',
    'rhabillât',
    'rhabillée',
    'rhabiller',
    'rhabilles',
    'rhabillés',
    'rhabillez',
    'rhapsodes',
    'rhapsodie',
    'rhéobases',
    'rhéologie',
    'rhéologue',
    'rhéomètre',
    'rhéophile',
    'rhéostats',
    'rhétienne',
    'rhétiques',
    'rhinanthe',
    'rhingrave',
    'rhizotome',
    'rhodamine',
    'rhodanien',
    'rhodinols',
    'rhombique',
    'rhomboïde',
    'rhubarbes',
    'rhumaient',
    'rhumasses',
    'rhumerais',
    'rhumerait',
    'rhumèrent',
    'rhumeries',
    'rhumeriez',
    'rhumerons',
    'rhumeront',
    'rhynchite',
    'rhyolites',
    'rhyolithe',
    'rhytidome',
    'riblaient',
    'riblasses',
    'riblerais',
    'riblerait',
    'riblèrent',
    'ribleriez',
    'riblerons',
    'ribleront',
    'ribosomes',
    'riboulais',
    'riboulait',
    'riboulant',
    'riboulent',
    'riboulera',
    'ribouliez',
    'riboulons',
    'ricanâmes',
    'ricanasse',
    'ricanâtes',
    'ricanerai',
    'ricaneras',
    'ricanerez',
    'ricaneurs',
    'ricaneuse',
    'ricanions',
    'ricercare',
    'ricercari',
    'richardes',
    'richelieu',
    'richement',
    'richesses',
    'ricochais',
    'ricochait',
    'ricochant',
    'ricochent',
    'ricochera',
    'ricochets',
    'ricochiez',
    'ricochons',
    'ridassent',
    'ridassiez',
    'ridements',
    'riderions',
    'ridicules',
    'rieslings',
    'riffaudai',
    'riffaudas',
    'riffaudât',
    'riffaudée',
    'riffauder',
    'riffaudes',
    'riffaudés',
    'riffaudez',
    'riflaient',
    'riflasses',
    'riflerais',
    'riflerait',
    'riflèrent',
    'rifleriez',
    'riflerons',
    'rifleront',
    'riflettes',
    'rigaudons',
    'rigidifia',
    'rigidifie',
    'rigidifié',
    'rigidités',
    'rigolades',
    'rigolages',
    'rigolâmes',
    'rigolarde',
    'rigolards',
    'rigolasse',
    'rigolâtes',
    'rigolerai',
    'rigoleras',
    'rigolerez',
    'rigoleurs',
    'rigoleuse',
    'rigolions',
    'rigolotes',
    'rigorisme',
    'rigoriste',
    'rigoureux',
    'rillettes',
    'rimaillai',
    'rimaillas',
    'rimaillât',
    'rimailler',
    'rimailles',
    'rimaillez',
    'rimassent',
    'rimassiez',
    'rimerions',
    'rinçaient',
    'rinçasses',
    'rincerais',
    'rincerait',
    'rincèrent',
    'rinceriez',
    'rincerons',
    'rinceront',
    'rincettes',
    'rinceuses',
    'ringardai',
    'ringardas',
    'ringardât',
    'ringardée',
    'ringarder',
    'ringardes',
    'ringardés',
    'ringardez',
    'ripaillai',
    'ripaillas',
    'ripaillât',
    'ripailler',
    'ripailles',
    'ripaillez',
    'ripassent',
    'ripassiez',
    'riperions',
    'ripolinai',
    'ripolinas',
    'ripolinât',
    'ripolinée',
    'ripoliner',
    'ripolines',
    'ripolinés',
    'ripolinez',
    'ripostais',
    'ripostait',
    'ripostant',
    'ripostent',
    'ripostera',
    'ripostiez',
    'ripostons',
    'ripuaires',
    'risbermes',
    'risquâmes',
    'risquasse',
    'risquâtes',
    'risquerai',
    'risqueras',
    'risquerez',
    'risquions',
    'rissolais',
    'rissolait',
    'rissolant',
    'rissolées',
    'rissolent',
    'rissolera',
    'rissoliez',
    'rissolons',
    'ristourna',
    'ristourne',
    'ristourné',
    'ritualisa',
    'ritualise',
    'ritualisé',
    'rituelles',
    'rivalisai',
    'rivalisas',
    'rivalisât',
    'rivaliser',
    'rivalises',
    'rivalisez',
    'rivalités',
    'rivassent',
    'rivassiez',
    'rivelaine',
    'riveraine',
    'riverains',
    'riverions',
    'rivetages',
    'rivetâmes',
    'rivetasse',
    'rivetâtes',
    'riveteuse',
    'rivetions',
    'rivettent',
    'rivettera',
    'rivulairs',
    'roadsters',
    'robassent',
    'robassiez',
    'robelages',
    'roberions',
    'robiniers',
    'roboratif',
    'robotique',
    'robotisai',
    'robotisas',
    'robotisât',
    'robotisée',
    'robotiser',
    'robotises',
    'robotisés',
    'robotisez',
    'rocailles',
    'rocheuses',
    'rockeuses',
    'rocouâmes',
    'rocouasse',
    'rocouâtes',
    'rocouerai',
    'rocoueras',
    'rocouerez',
    'rocouions',
    'rocouyers',
    'rocquâmes',
    'rocquasse',
    'rocquâtes',
    'rocquerai',
    'rocqueras',
    'rocquerez',
    'rocquions',
    'rôdaillai',
    'rôdaillas',
    'rôdaillât',
    'rôdailler',
    'rôdailles',
    'rôdaillez',
    'rodassent',
    'rôdassent',
    'rodassiez',
    'rôdassiez',
    'roderions',
    'rôderions',
    'rodomonts',
    'roentgens',
    'rogations',
    'rogatoire',
    'rognaient',
    'rognasses',
    'rognerais',
    'rognerait',
    'rognèrent',
    'rogneriez',
    'rognerons',
    'rogneront',
    'rogneuses',
    'rognonnai',
    'rognonnas',
    'rognonnât',
    'rognonnée',
    'rognonner',
    'rognonnes',
    'rognonnés',
    'rognonnez',
    'roidirais',
    'roidirait',
    'roidirent',
    'roidiriez',
    'roidirons',
    'roidiront',
    'roidisses',
    'roidissez',
    'roitelets',
    'romançais',
    'romançait',
    'romançant',
    'romancées',
    'romancent',
    'romancera',
    'romancero',
    'romanches',
    'romancier',
    'romanciez',
    'romançons',
    'romanisai',
    'romanisas',
    'romanisât',
    'romanisée',
    'romaniser',
    'romanises',
    'romanisés',
    'romanisez',
    'romaniste',
    'romanités',
    'rombières',
    'rompaient',
    'rompirent',
    'rompisses',
    'romprions',
    'romstecks',
    'ronceraie',
    'ronceuses',
    'ronchonna',
    'ronchonne',
    'ronchonné',
    'roncières',
    'rondaches',
    'rondelets',
    'rondelles',
    'rondement',
    'rondirais',
    'rondirait',
    'rondirent',
    'rondiriez',
    'rondirons',
    'rondiront',
    'rondisses',
    'rondissez',
    'ronéotypa',
    'ronéotype',
    'ronéotypé',
    'ronflagua',
    'ronflague',
    'ronflagué',
    'ronflâmes',
    'ronflante',
    'ronflants',
    'ronflasse',
    'ronflâtes',
    'ronflerai',
    'ronfleras',
    'ronflerez',
    'ronfleurs',
    'ronfleuse',
    'ronflions',
    'rongeâmes',
    'rongeasse',
    'rongeâtes',
    'rongement',
    'rongerais',
    'rongerait',
    'rongèrent',
    'rongeriez',
    'rongerons',
    'rongeront',
    'rongeuses',
    'ronronnai',
    'ronronnas',
    'ronronnât',
    'ronronner',
    'ronronnes',
    'ronronnez',
    'rookeries',
    'roquaient',
    'roquasses',
    'roquefort',
    'roquentin',
    'roquerais',
    'roquerait',
    'roquèrent',
    'roqueries',
    'roqueriez',
    'roquerons',
    'roqueront',
    'roquettes',
    'rosalbins',
    'rosassent',
    'rosassiez',
    'roselière',
    'roseliers',
    'roseraies',
    'roserions',
    'rosirions',
    'rosissais',
    'rosissait',
    'rosissant',
    'rosissent',
    'rosissiez',
    'rosissons',
    'rossaient',
    'rossasses',
    'rosserais',
    'rosserait',
    'rossèrent',
    'rosseries',
    'rosseriez',
    'rosserons',
    'rosseront',
    'rossignol',
    'rostrales',
    'rotariens',
    'rotassent',
    'rotassiez',
    'rotateurs',
    'rotations',
    'rotatives',
    'rotatoire',
    'rotatrice',
    'rotengles',
    'roténones',
    'roterions',
    'rotifères',
    'rôtirions',
    'rôtissage',
    'rôtissais',
    'rôtissait',
    'rôtissant',
    'rôtissent',
    'rôtisseur',
    'rôtissiez',
    'rôtissons',
    'rotondité',
    'rotruenge',
    'rotuliens',
    'roturière',
    'roturiers',
    'rouassent',
    'rouassiez',
    'roublarde',
    'roublards',
    'roucoulai',
    'roucoulas',
    'roucoulât',
    'roucoulée',
    'roucouler',
    'roucoules',
    'roucoulés',
    'roucoulez',
    'roudoudou',
    'rouerions',
    'rougeâtre',
    'rougeaude',
    'rougeauds',
    'rougeoies',
    'rougeoles',
    'rougeoyai',
    'rougeoyas',
    'rougeoyât',
    'rougeoyer',
    'rougeoyez',
    'rougirais',
    'rougirait',
    'rougirent',
    'rougiriez',
    'rougirons',
    'rougiront',
    'rougisses',
    'rougissez',
    'rouillais',
    'rouillait',
    'rouillant',
    'rouillées',
    'rouillent',
    'rouillera',
    'rouilliez',
    'rouillons',
    'rouillure',
    'rouirions',
    'rouissage',
    'rouissais',
    'rouissait',
    'rouissant',
    'rouissent',
    'rouissiez',
    'rouissoir',
    'rouissons',
    'roulaient',
    'roulantes',
    'roulasses',
    'rouleauté',
    'roulement',
    'roulerais',
    'roulerait',
    'roulèrent',
    'rouleriez',
    'roulerons',
    'rouleront',
    'roulettes',
    'roulottai',
    'roulottas',
    'roulottât',
    'roulottée',
    'roulotter',
    'roulottes',
    'roulottés',
    'roulottez',
    'roumaines',
    'roupettes',
    'roupillai',
    'roupillas',
    'roupillât',
    'roupiller',
    'roupilles',
    'roupillez',
    'roupillon',
    'rouquines',
    'rouspétai',
    'rouspétas',
    'rouspétât',
    'rouspéter',
    'rouspètes',
    'rouspétez',
    'roussâtre',
    'rousseaux',
    'rousselet',
    'roussette',
    'rousseurs',
    'roussîmes',
    'roussirai',
    'roussiras',
    'roussirez',
    'roussisse',
    'roussîtes',
    'roustîmes',
    'roustirai',
    'roustiras',
    'roustirez',
    'roustisse',
    'roustîtes',
    'routaient',
    'routardes',
    'routasses',
    'routerais',
    'routerait',
    'routèrent',
    'routeriez',
    'routerons',
    'routeront',
    'routières',
    'routinier',
    'rouverain',
    'rouverins',
    'rouvertes',
    'rouvraies',
    'rouvrîmes',
    'rouvrions',
    'rouvrirai',
    'rouvriras',
    'rouvrirez',
    'rouvrisse',
    'rouvrîtes',
    'royalisme',
    'royaliste',
    'royalties',
    'ruassions',
    'rubanâmes',
    'rubanasse',
    'rubanâtes',
    'rubanerai',
    'rubaneras',
    'rubanerez',
    'rubanerie',
    'rubanière',
    'rubaniers',
    'rubanions',
    'rubéfiais',
    'rubéfiait',
    'rubéfiant',
    'rubéfiées',
    'rubéfient',
    'rubéfiera',
    'rubéfiiez',
    'rubéfions',
    'rubellite',
    'rubéoleux',
    'rubescent',
    'rubiacées',
    'rubicelle',
    'rubiconde',
    'rubiconds',
    'rubidiums',
    'rubiettes',
    'rubriques',
    'ruchaient',
    'ruchasses',
    'rucherais',
    'rucherait',
    'ruchèrent',
    'rucheriez',
    'rucherons',
    'rucheront',
    'rudbeckie',
    'rudentais',
    'rudentait',
    'rudentant',
    'rudentées',
    'rudentent',
    'rudentera',
    'rudentiez',
    'rudentons',
    'rudenture',
    'rudérales',
    'rudiments',
    'rudoierai',
    'rudoieras',
    'rudoierez',
    'rudoyâmes',
    'rudoyasse',
    'rudoyâtes',
    'rudoyions',
    'rueraient',
    'rugirions',
    'rugissais',
    'rugissait',
    'rugissant',
    'rugissent',
    'rugissiez',
    'rugissons',
    'rugosités',
    'rugueuses',
    'ruilaient',
    'ruilasses',
    'ruilerais',
    'ruilerait',
    'ruilèrent',
    'ruileriez',
    'ruilerons',
    'ruileront',
    'ruinaient',
    'ruinasses',
    'ruinerais',
    'ruinerait',
    'ruinèrent',
    'ruineriez',
    'ruinerons',
    'ruineront',
    'ruineuses',
    'ruinistes',
    'ruisseaux',
    'ruisselai',
    'ruisselas',
    'ruisselât',
    'ruisseler',
    'ruisselet',
    'ruisselez',
    'ruisselle',
    'ruminâmes',
    'ruminante',
    'ruminants',
    'ruminasse',
    'ruminâtes',
    'ruminerai',
    'rumineras',
    'ruminerez',
    'ruminions',
    'rumstecks',
    'runabouts',
    'rupestres',
    'rupicoles',
    'rupinâmes',
    'rupinasse',
    'rupinâtes',
    'rupinerai',
    'rupineras',
    'rupinerez',
    'rupinions',
    'rusassent',
    'rusassiez',
    'ruserions',
    'russifiai',
    'russifias',
    'russifiât',
    'russifiée',
    'russifier',
    'russifies',
    'russifiés',
    'russifiez',
    'rustaudes',
    'rusticage',
    'rusticité',
    'rustiquai',
    'rustiquas',
    'rustiquât',
    'rustiquée',
    'rustiquer',
    'rustiques',
    'rustiqués',
    'rustiquez',
    'rutabagas',
    'ruthénium',
    'rutilâmes',
    'rutilante',
    'rutilants',
    'rutilasse',
    'rutilâtes',
    'rutilerai',
    'rutileras',
    'rutilerez',
    'rutilions',
    'rythmâmes',
    'rythmasse',
    'rythmâtes',
    'rythmerai',
    'rythmeras',
    'rythmerez',
    'rythmions',
    'rythmique',
    'sabéennes',
    'sabéismes',
    'sablaient',
    'sablasses',
    'sablerais',
    'sablerait',
    'sablèrent',
    'sableries',
    'sableriez',
    'sablerons',
    'sableront',
    'sableuses',
    'sablières',
    'sablonnai',
    'sablonnas',
    'sablonnât',
    'sablonnée',
    'sablonner',
    'sablonnes',
    'sablonnés',
    'sablonnez',
    'sabordage',
    'sabordais',
    'sabordait',
    'sabordant',
    'sabordées',
    'sabordent',
    'sabordera',
    'sabordiez',
    'sabordons',
    'sabotages',
    'sabotâmes',
    'sabotasse',
    'sabotâtes',
    'saboterai',
    'saboteras',
    'saboterez',
    'saboterie',
    'saboteurs',
    'saboteuse',
    'sabotière',
    'sabotiers',
    'sabotions',
    'saboulais',
    'saboulait',
    'saboulant',
    'saboulées',
    'saboulent',
    'saboulera',
    'sabouliez',
    'saboulons',
    'sabraient',
    'sabraîmes',
    'sabraisse',
    'sabraîtes',
    'sabrerais',
    'sabrerait',
    'sabreriez',
    'sabrerons',
    'sabreront',
    'sabreuses',
    'saburrale',
    'saburraux',
    'sacagnais',
    'sacagnait',
    'sacagnant',
    'sacagnées',
    'sacagnent',
    'sacagnera',
    'sacagniez',
    'sacagnons',
    'saccadais',
    'saccadait',
    'saccadant',
    'saccadées',
    'saccadent',
    'saccadera',
    'saccadiez',
    'saccadons',
    'saccageai',
    'saccageas',
    'saccageât',
    'saccagées',
    'saccagent',
    'saccagera',
    'saccageur',
    'saccagiez',
    'saccharin',
    'sacculine',
    'sacerdoce',
    'sacolévas',
    'sacolèves',
    'sacquâmes',
    'sacquasse',
    'sacquâtes',
    'sacquerai',
    'sacqueras',
    'sacquerez',
    'sacquions',
    'sacraient',
    'sacralisa',
    'sacralise',
    'sacralisé',
    'sacrasses',
    'sacrebleu',
    'sacredieu',
    'sacrement',
    'sacrément',
    'sacrerais',
    'sacrerait',
    'sacrèrent',
    'sacreriez',
    'sacrerons',
    'sacreront',
    'sacrifiai',
    'sacrifias',
    'sacrifiât',
    'sacrifice',
    'sacrifiée',
    'sacrifier',
    'sacrifies',
    'sacrifiés',
    'sacrifiez',
    'sacrilège',
    'sacripant',
    'sacristie',
    'saducéens',
    'safranais',
    'safranait',
    'safranant',
    'safranées',
    'safranent',
    'safranera',
    'safraniez',
    'safranons',
    'sagacités',
    'sagittale',
    'sagittaux',
    'sagittées',
    'sagoutier',
    'sahariens',
    'sahéliens',
    'saignâmes',
    'saignante',
    'saignants',
    'saignasse',
    'saignâtes',
    'saignerai',
    'saigneras',
    'saignerez',
    'saigneurs',
    'saigneuse',
    'saignions',
    'saignoirs',
    'saillante',
    'saillants',
    'saillîmes',
    'saillions',
    'saillirai',
    'sailliras',
    'saillirez',
    'saillisse',
    'saillîtes',
    'sainement',
    'sainfoins',
    'saintetés',
    'saisirais',
    'saisirait',
    'saisirent',
    'saisiriez',
    'saisirons',
    'saisiront',
    'saisisses',
    'saisissez',
    'saisonnai',
    'saisonnas',
    'saisonnât',
    'saisonner',
    'saisonnes',
    'saisonnez',
    'salacités',
    'saladeros',
    'saladiers',
    'salaisons',
    'salamalec',
    'salangane',
    'salariais',
    'salariait',
    'salariale',
    'salariant',
    'salariats',
    'salariaux',
    'salariées',
    'salarient',
    'salariera',
    'salariiez',
    'salarions',
    'salassent',
    'salassiez',
    'salerions',
    'salésiens',
    'salicaire',
    'salicines',
    'salicoles',
    'salicoque',
    'salicorne',
    'saliennes',
    'salifères',
    'salifiais',
    'salifiait',
    'salifiant',
    'salifiées',
    'salifient',
    'salifiera',
    'salifiiez',
    'salifions',
    'saligaude',
    'saligauds',
    'salignons',
    'salinages',
    'salinière',
    'salinités',
    'salirions',
    'salissais',
    'salissait',
    'salissant',
    'salissent',
    'salissiez',
    'salissons',
    'salissure',
    'salivaire',
    'salivâmes',
    'salivasse',
    'salivâtes',
    'saliverai',
    'saliveras',
    'saliverez',
    'salivions',
    'salonarde',
    'salonards',
    'salonnard',
    'salonnier',
    'salopâmes',
    'salopards',
    'salopasse',
    'salopâtes',
    'saloperai',
    'saloperas',
    'saloperez',
    'saloperie',
    'salopette',
    'salopiaud',
    'salopions',
    'salopiots',
    'salpêtrai',
    'salpêtras',
    'salpêtrât',
    'salpêtrée',
    'salpêtrer',
    'salpêtres',
    'salpêtrés',
    'salpêtrez',
    'salpicons',
    'saltation',
    'saluaient',
    'saluasses',
    'salubrité',
    'saluerais',
    'saluerait',
    'saluèrent',
    'salueriez',
    'saluerons',
    'salueront',
    'salutaire',
    'salutiste',
    'salvateur',
    'samariums',
    'samouraïs',
    'samoyèdes',
    'sancerres',
    'sanctifia',
    'sanctifie',
    'sanctifié',
    'sanctions',
    'sandwicha',
    'sandwiche',
    'sandwiché',
    'sandwichs',
    'sanglâmes',
    'sanglante',
    'sanglants',
    'sanglasse',
    'sanglâtes',
    'sanglerai',
    'sangleras',
    'sanglerez',
    'sangliers',
    'sanglions',
    'sanglotai',
    'sanglotas',
    'sanglotât',
    'sangloter',
    'sanglotes',
    'sanglotez',
    'sang-mêlé',
    'sanguines',
    'sanhédrin',
    'sanieuses',
    'sanitaire',
    'sans-abri',
    'sanscrite',
    'sanscrits',
    'sans-fils',
    'sans-gêne',
    'sanskrits',
    'sansonnet',
    'santonnai',
    'santonnas',
    'santonnât',
    'santonnée',
    'santonner',
    'santonnes',
    'santonnés',
    'santonnez',
    'saoudiens',
    'saoudites',
    'saoulâmes',
    'saoulasse',
    'saoulâtes',
    'saoulerai',
    'saouleras',
    'saoulerez',
    'saoulions',
    'sapassent',
    'sapassiez',
    'sapements',
    'saperions',
    'saphiques',
    'saphismes',
    'sapidités',
    'sapiences',
    'sapinette',
    'sapinière',
    'saponacée',
    'saponacés',
    'saponaire',
    'saponifia',
    'saponifie',
    'saponifié',
    'saponines',
    'sapotiers',
    'saquaient',
    'saquasses',
    'saquerais',
    'saquerait',
    'saquèrent',
    'saqueriez',
    'saquerons',
    'saqueront',
    'sarabande',
    'sarbacane',
    'sarcasmes',
    'sarcelles',
    'sarclages',
    'sarclâmes',
    'sarclasse',
    'sarclâtes',
    'sarclerai',
    'sarcleras',
    'sarclerez',
    'sarcleurs',
    'sarcleuse',
    'sarclions',
    'sarcloirs',
    'sarcocèle',
    'sarcoïdes',
    'sarcoptes',
    'sardinier',
    'sardoines',
    'sargasses',
    'sarmentai',
    'sarmentas',
    'sarmentât',
    'sarmenter',
    'sarmentes',
    'sarmentez',
    'sarrasine',
    'sarrasins',
    'sarrettes',
    'sarriette',
    'sassafras',
    'sassaient',
    'sassanide',
    'sassasses',
    'sassement',
    'sassenage',
    'sasserais',
    'sasserait',
    'sassèrent',
    'sasseriez',
    'sasserons',
    'sasseront',
    'sasseuses',
    'satanâmes',
    'satanasse',
    'satanâtes',
    'satanerai',
    'sataneras',
    'satanerez',
    'satanions',
    'satanique',
    'satanisme',
    'satellisa',
    'satellise',
    'satellisé',
    'satellite',
    'satinages',
    'satinâmes',
    'satinasse',
    'satinâtes',
    'satinerai',
    'satineras',
    'satinerez',
    'satinette',
    'satineurs',
    'satineuse',
    'satinions',
    'satirique',
    'satirisai',
    'satirisas',
    'satirisât',
    'satirisée',
    'satiriser',
    'satirises',
    'satirisés',
    'satirisez',
    'satisfais',
    'satisfait',
    'satisfera',
    'satisfont',
    'satonnais',
    'satonnait',
    'satonnant',
    'satonnées',
    'satonnent',
    'satonnera',
    'satonniez',
    'satonnons',
    'satrapies',
    'saturable',
    'saturâmes',
    'saturante',
    'saturants',
    'saturasse',
    'saturâtes',
    'saturerai',
    'satureras',
    'saturerez',
    'saturions',
    'saturnien',
    'saturnies',
    'saturnine',
    'saturnins',
    'satyrique',
    'satyrisme',
    'sauçaient',
    'sauçasses',
    'saucerais',
    'saucerait',
    'saucèrent',
    'sauceriez',
    'saucerons',
    'sauceront',
    'saucières',
    'saucisses',
    'saucisson',
    'saugrenue',
    'saugrenus',
    'saumâtres',
    'saumoneau',
    'saumonées',
    'saumurage',
    'saumurais',
    'saumurait',
    'saumurant',
    'saumurées',
    'saumurent',
    'saumurera',
    'saumuriez',
    'saumurons',
    'saunaient',
    'saunasses',
    'saunerais',
    'saunerait',
    'saunèrent',
    'sauneriez',
    'saunerons',
    'sauneront',
    'saunières',
    'saupiquet',
    'saupoudra',
    'saupoudre',
    'saupoudré',
    'sauraient',
    'saurasses',
    'saurerais',
    'saurerait',
    'saurèrent',
    'saureriez',
    'saurerons',
    'saureront',
    'saurirais',
    'saurirait',
    'saurirent',
    'sauririez',
    'saurirons',
    'sauriront',
    'saurisses',
    'saurissez',
    'saussaies',
    'sautaient',
    'sautasses',
    'sautelles',
    'sauterais',
    'sauterait',
    'sautereau',
    'sautèrent',
    'sauteries',
    'sauteriez',
    'sauternes',
    'sauterons',
    'sauteront',
    'sauteuses',
    'sautillai',
    'sautillas',
    'sautillât',
    'sautiller',
    'sautilles',
    'sautillez',
    'sauvageon',
    'sauvagine',
    'sauvagins',
    'sauvaient',
    'sauvasses',
    'sauverais',
    'sauverait',
    'sauvèrent',
    'sauveriez',
    'sauverons',
    'sauveront',
    'sauvetage',
    'sauveteur',
    'savamment',
    'savetiers',
    'savonnage',
    'savonnais',
    'savonnait',
    'savonnant',
    'savonnées',
    'savonnent',
    'savonnera',
    'savonneux',
    'savonnier',
    'savonniez',
    'savonnons',
    'savourais',
    'savourait',
    'savourant',
    'savourées',
    'savourent',
    'savourera',
    'savoureux',
    'savouriez',
    'savourons',
    'savoyarde',
    'savoyards',
    'saxatiles',
    'saxicoles',
    'saxifrage',
    'saxophone',
    'scabieuse',
    'scabreuse',
    'scalaires',
    'scalpâmes',
    'scalpasse',
    'scalpâtes',
    'scalperai',
    'scalperas',
    'scalperez',
    'scalpions',
    'scandales',
    'scandâmes',
    'scandasse',
    'scandâtes',
    'scanderai',
    'scanderas',
    'scanderez',
    'scandions',
    'scandiums',
    'scansions',
    'scaphites',
    'scaphoïde',
    'scarabées',
    'scarieuse',
    'scélérate',
    'scélérats',
    'scellages',
    'scellâmes',
    'scellasse',
    'scellâtes',
    'scellerai',
    'scelleras',
    'scellerez',
    'scellions',
    'scénarios',
    'scéniques',
    'sceptique',
    'schapskas',
    'scheidais',
    'scheidait',
    'scheidant',
    'scheidées',
    'scheident',
    'scheidera',
    'scheidiez',
    'scheidons',
    'scherries',
    'schisteux',
    'schizoïde',
    'schlagues',
    'schlittai',
    'schlittas',
    'schlittât',
    'schlittée',
    'schlitter',
    'schlittes',
    'schlittés',
    'schlittez',
    'schnauzer',
    'schnoques',
    'schnorkel',
    'schnouffs',
    'schooners',
    'sciassent',
    'sciassiez',
    'sciatique',
    'sciemment',
    'scierions',
    'scindâmes',
    'scindasse',
    'scindâtes',
    'scinderai',
    'scinderas',
    'scinderez',
    'scindions',
    'scintilla',
    'scintille',
    'scintillé',
    'sciottais',
    'sciottait',
    'sciottant',
    'sciottées',
    'sciottent',
    'sciottera',
    'sciottiez',
    'sciottons',
    'scissiles',
    'scissions',
    'scissures',
    'scléreuse',
    'sclérosai',
    'sclérosas',
    'sclérosât',
    'sclérosée',
    'scléroser',
    'scléroses',
    'sclérosés',
    'sclérosez',
    'scolaires',
    'scolarisa',
    'scolarise',
    'scolarisé',
    'scolarité',
    'scoliaste',
    'scolioses',
    'scoriacée',
    'scoriacés',
    'scorpions',
    'scotchais',
    'scotchait',
    'scotchant',
    'scotchées',
    'scotchent',
    'scotchera',
    'scotchiez',
    'scotchons',
    'scotismes',
    'scotistes',
    'scotomisa',
    'scotomise',
    'scotomisé',
    'scottishs',
    'scoutisme',
    'Scrabbles',
    'scratchai',
    'scratchas',
    'scratchât',
    'scratchée',
    'scratcher',
    'scratches',
    'scratchés',
    'scratchez',
    'scripteur',
    'scrofules',
    'scrubbers',
    'scrupules',
    'scrutâmes',
    'scrutasse',
    'scrutâtes',
    'scruterai',
    'scruteras',
    'scruterez',
    'scrutions',
    'sculptais',
    'sculptait',
    'sculptant',
    'sculptées',
    'sculptent',
    'sculptera',
    'sculpteur',
    'sculptiez',
    'sculptons',
    'sculpture',
    'scythique',
    'séborrhée',
    'sécateurs',
    'sécession',
    'séchaient',
    'séchasses',
    'sèchement',
    'sécherais',
    'sécherait',
    'séchèrent',
    'sécheries',
    'sécheriez',
    'sécherons',
    'sécheront',
    'sécheuses',
    'secondais',
    'secondait',
    'secondant',
    'secondées',
    'secondent',
    'secondera',
    'secondiez',
    'secondons',
    'secouâmes',
    'secouasse',
    'secouâtes',
    'secouerai',
    'secoueras',
    'secouerez',
    'secoueurs',
    'secouions',
    'secourais',
    'secourait',
    'secourant',
    'secourent',
    'secoureur',
    'secouriez',
    'secourons',
    'secourrai',
    'secourras',
    'secourrez',
    'secourues',
    'secousses',
    'secrétage',
    'sécrétais',
    'sécrétait',
    'sécrétant',
    'sécrétées',
    'sécrètent',
    'sécrétera',
    'sécréteur',
    'sécrétiez',
    'sécrétine',
    'sécrétion',
    'sécrétons',
    'sectaires',
    'sectateur',
    'sectionna',
    'sectionne',
    'sectionné',
    'sectoriel',
    'sectorisa',
    'sectorise',
    'sectorisé',
    'séculaire',
    'séculière',
    'séculiers',
    'sécurisai',
    'sécurisas',
    'sécurisât',
    'sécurisée',
    'sécuriser',
    'sécurises',
    'sécurisés',
    'sécurisez',
    'sécurités',
    'sédatives',
    'sédimenta',
    'sédimente',
    'sédimenté',
    'sédiments',
    'séditieux',
    'séditions',
    'séducteur',
    'séduction',
    'séduirais',
    'séduirait',
    'séduiriez',
    'séduirons',
    'séduiront',
    'séduisais',
    'séduisait',
    'séduisant',
    'séduisent',
    'séduisiez',
    'séduisons',
    'séfarades',
    'segmentai',
    'segmental',
    'segmentas',
    'segmentât',
    'segmentée',
    'segmenter',
    'segmentes',
    'segmentés',
    'segmentez',
    'ségrairie',
    'seigneurs',
    'séismique',
    'seizièmes',
    'séjournai',
    'séjournas',
    'séjournât',
    'séjourner',
    'séjournes',
    'séjournez',
    'sélaciens',
    'sélecteur',
    'sélectifs',
    'sélection',
    'sélective',
    'séléniate',
    'sélénieux',
    'sélénique',
    'sélénites',
    'séléniums',
    'séléniure',
    'sellaient',
    'sellasses',
    'sellerais',
    'sellerait',
    'sellèrent',
    'selleries',
    'selleriez',
    'sellerons',
    'selleront',
    'sellettes',
    'semailles',
    'semainier',
    'sémantème',
    'sémaphore',
    'semassent',
    'semassiez',
    'semblable',
    'semblâmes',
    'semblante',
    'semblants',
    'semblasse',
    'semblâtes',
    'semblerai',
    'sembleras',
    'semblerez',
    'semblions',
    'sèmerions',
    'semestres',
    'semi-coke',
    'semi-fini',
    'sémillant',
    'sémillons',
    'séminaire',
    'séminales',
    'sémitique',
    'semonçais',
    'semonçait',
    'semonçant',
    'semoncées',
    'semoncent',
    'semoncera',
    'semonciez',
    'semonçons',
    'sénateurs',
    'sénéchaux',
    'sénescent',
    'sénestres',
    'sénilités',
    'sensation',
    'sensément',
    'sensibles',
    'sensitifs',
    'sensitive',
    'sensoriel',
    'sensuelle',
    'sentaient',
    'sentences',
    'sentiment',
    'sentirais',
    'sentirait',
    'sentirent',
    'sentiriez',
    'sentirons',
    'sentiront',
    'sentisses',
    'sépaloïde',
    'séparable',
    'séparâmes',
    'séparante',
    'séparants',
    'séparasse',
    'séparâtes',
    'séparerai',
    'sépareras',
    'séparerez',
    'séparions',
    'septembre',
    'septemvir',
    'septennal',
    'septennat',
    'septicité',
    'septièmes',
    'septiques',
    'septuplai',
    'septuplas',
    'septuplât',
    'septuplée',
    'septupler',
    'septuples',
    'septuplés',
    'septuplez',
    'sépulcral',
    'sépulcres',
    'sépulture',
    'séquelles',
    'séquences',
    'séquestra',
    'séquestre',
    'séquestré',
    'serais-je',
    'serais-tu',
    'serait-ce',
    'serait-il',
    'serait-on',
    'sérançais',
    'sérançait',
    'sérançant',
    'sérancées',
    'sérancent',
    'sérancera',
    'séranciez',
    'sérançons',
    'séraphins',
    'sera-t-il',
    'sera-t-on',
    'sérénades',
    'sérénités',
    'serfouies',
    'serfouira',
    'sergettes',
    'sériaient',
    'sérialisa',
    'sérialise',
    'sérialisé',
    'sériasses',
    'sériation',
    'sérielles',
    'sérierais',
    'sérierait',
    'sérièrent',
    'sérieriez',
    'sérierons',
    'sérieront',
    'sérieuses',
    'serinâmes',
    'serinasse',
    'serinâtes',
    'serinerai',
    'serineras',
    'serinerez',
    'seringats',
    'seringuai',
    'seringuas',
    'seringuât',
    'seringuée',
    'seringuer',
    'seringues',
    'seringués',
    'seringuez',
    'serinions',
    'sermonnai',
    'sermonnas',
    'sermonnât',
    'sermonnée',
    'sermonner',
    'sermonnes',
    'sermonnés',
    'sermonnez',
    'sérologie',
    'seront-ce',
    'sérosités',
    'serpentai',
    'serpentas',
    'serpentât',
    'serpentée',
    'serpenter',
    'serpentes',
    'serpentés',
    'serpentez',
    'serpentin',
    'serpettes',
    'serpolets',
    'serraient',
    'serrasses',
    'serratule',
    'serrement',
    'serre-nez',
    'serrerais',
    'serrerait',
    'serrèrent',
    'serreriez',
    'serrerons',
    'serreront',
    'serrurier',
    'sertirais',
    'sertirait',
    'sertirent',
    'sertiriez',
    'sertirons',
    'sertiront',
    'sertisses',
    'sertissez',
    'servaient',
    'servantes',
    'serveuses',
    'serviable',
    'serviette',
    'servilité',
    'servirais',
    'servirait',
    'servirent',
    'serviriez',
    'servirons',
    'serviront',
    'servisses',
    'serviteur',
    'servitude',
    'sésamoïde',
    'sesbanias',
    'sesbanies',
    'sesterces',
    'seulement',
    'seulettes',
    'sévérités',
    'sévirions',
    'sévissais',
    'sévissait',
    'sévissant',
    'sévissent',
    'sévissiez',
    'sévissons',
    'sevraient',
    'sevrasses',
    'sevrerais',
    'sevrerait',
    'sevrèrent',
    'sevreriez',
    'sevrerons',
    'sevreront',
    'sexologie',
    'sexologue',
    'sexonomie',
    'sex-shops',
    'sextolets',
    'sextuplai',
    'sextuplas',
    'sextuplât',
    'sextuplée',
    'sextupler',
    'sextuples',
    'sextuplés',
    'sextuplez',
    'sexualisa',
    'sexualise',
    'sexualisé',
    'sexualité',
    'sexuelles',
    'sforzando',
    'sgraffite',
    'shampoing',
    'shantoung',
    'shantungs',
    'shetlands',
    'shillings',
    'shootâmes',
    'shootasse',
    'shootâtes',
    'shooterai',
    'shooteras',
    'shooterez',
    'shootions',
    'shoppings',
    'shrapnell',
    'shrapnels',
    'shuntâmes',
    'shuntasse',
    'shuntâtes',
    'shunterai',
    'shunteras',
    'shunterez',
    'shuntions',
    'siamoises',
    'sibériens',
    'sibilante',
    'sibilants',
    'sibylline',
    'sibyllins',
    'siccatifs',
    'siccative',
    'siciliens',
    'side-cars',
    'sidérales',
    'sidérâmes',
    'sidérante',
    'sidérants',
    'sidérasse',
    'sidérâtes',
    'sidérerai',
    'sidéreras',
    'sidérerez',
    'sidérions',
    'sidérites',
    'sidéroses',
    'siégeâmes',
    'siégeasse',
    'siégeâtes',
    'siégerais',
    'siégerait',
    'siégèrent',
    'siégeriez',
    'siégerons',
    'siégeront',
    'siéraient',
    'sifflages',
    'sifflâmes',
    'sifflante',
    'sifflants',
    'sifflasse',
    'sifflâtes',
    'sifflerai',
    'siffleras',
    'sifflerez',
    'siffleurs',
    'siffleuse',
    'sifflions',
    'sifflotai',
    'sifflotas',
    'sifflotât',
    'sifflotée',
    'siffloter',
    'sifflotes',
    'sifflotés',
    'sifflotez',
    'sigillées',
    'sigisbées',
    'siglaison',
    'sigmoïdes',
    'signaient',
    'signalais',
    'signalait',
    'signalant',
    'signalées',
    'signalent',
    'signalera',
    'signaleur',
    'signaliez',
    'signalisa',
    'signalise',
    'signalisé',
    'signalons',
    'signasses',
    'signature',
    'signerais',
    'signerait',
    'signèrent',
    'signeriez',
    'signerons',
    'signeront',
    'signifiai',
    'signifias',
    'signifiât',
    'signifiée',
    'signifier',
    'signifies',
    'signifiés',
    'signifiez',
    'silicates',
    'siliceuse',
    'silicique',
    'siliciure',
    'siliconai',
    'siliconas',
    'siliconât',
    'siliconée',
    'siliconer',
    'silicones',
    'siliconés',
    'siliconez',
    'silicoses',
    'silicules',
    'silionnes',
    'sillonnai',
    'sillonnas',
    'sillonnât',
    'sillonnée',
    'sillonner',
    'sillonnes',
    'sillonnés',
    'sillonnez',
    'silotages',
    'siluriens',
    'simagrées',
    'simarubas',
    'simbleaux',
    'simiennes',
    'simiesque',
    'similaire',
    'similisai',
    'similisas',
    'similisât',
    'similisée',
    'similiser',
    'similises',
    'similisés',
    'similisez',
    'similiste',
    'simplette',
    'simplexes',
    'simplifia',
    'simplifie',
    'simplifié',
    'simplisme',
    'simpliste',
    'simulacre',
    'simulâmes',
    'simulasse',
    'simulâtes',
    'simulerai',
    'simuleras',
    'simulerez',
    'simulions',
    'simultané',
    'sinapisée',
    'sinapisés',
    'sinapisme',
    'sincérité',
    'sinciputs',
    'sinécures',
    'singeâmes',
    'singeasse',
    'singeâtes',
    'singerais',
    'singerait',
    'singèrent',
    'singeries',
    'singeriez',
    'singerons',
    'singeront',
    'singulier',
    'sinisâmes',
    'sinisante',
    'sinisants',
    'sinisasse',
    'sinisâtes',
    'siniserai',
    'siniseras',
    'siniserez',
    'sinisions',
    'sinistrée',
    'sinistres',
    'sinistrés',
    'sinologie',
    'sinologue',
    'sintérisa',
    'sintérise',
    'sintérisé',
    'sinueuses',
    'sinuosité',
    'sinusites',
    'sinusoïde',
    'sionismes',
    'sionistes',
    'siphoïdes',
    'siphonnai',
    'siphonnas',
    'siphonnât',
    'siphonnée',
    'siphonner',
    'siphonnes',
    'siphonnés',
    'siphonnez',
    'sirotâmes',
    'sirotasse',
    'sirotâtes',
    'siroterai',
    'siroteras',
    'siroterez',
    'sirotions',
    'sirupeuse',
    'sirventes',
    'sirventès',
    'sismicité',
    'sismiques',
    'sisymbres',
    'sittelles',
    'situaient',
    'situasses',
    'situation',
    'situerais',
    'situerait',
    'situèrent',
    'situeriez',
    'situerons',
    'situeront',
    'skiassent',
    'skiassiez',
    'skierions',
    'slalomais',
    'slalomait',
    'slalomant',
    'slaloment',
    'slalomera',
    'slalomeur',
    'slalomiez',
    'slalomons',
    'slavisais',
    'slavisait',
    'slavisant',
    'slavisées',
    'slavisent',
    'slavisera',
    'slavisiez',
    'slavisons',
    'slavistes',
    'sleepings',
    'slovaques',
    'smaltines',
    'smaragdin',
    'smashâmes',
    'smashasse',
    'smashâtes',
    'smasherai',
    'smasheras',
    'smasherez',
    'smashions',
    'smectique',
    'smicardes',
    'smillages',
    'smillâmes',
    'smillasse',
    'smillâtes',
    'smillerai',
    'smilleras',
    'smillerez',
    'smillions',
    'smorzando',
    'snack-bar',
    'sniffâmes',
    'sniffasse',
    'sniffâtes',
    'snifferai',
    'snifferas',
    'snifferez',
    'sniffions',
    'snobaient',
    'snobasses',
    'snoberais',
    'snoberait',
    'snobèrent',
    'snoberiez',
    'snoberons',
    'snoberont',
    'snobinard',
    'snobismes',
    'snow-boot',
    'sobrement',
    'sobriétés',
    'sobriquet',
    'sociables',
    'socialisa',
    'socialise',
    'socialisé',
    'sociatrie',
    'socquette',
    'socratisa',
    'socratise',
    'socratisé',
    'sodomisai',
    'sodomisas',
    'sodomisât',
    'sodomisée',
    'sodomiser',
    'sodomises',
    'sodomisés',
    'sodomisez',
    'sodomites',
    'soeurette',
    'softwares',
    'soiffarde',
    'soiffards',
    'soignâmes',
    'soignante',
    'soignants',
    'soignasse',
    'soignâtes',
    'soignerai',
    'soigneras',
    'soignerez',
    'soigneurs',
    'soigneuse',
    'soignions',
    'solarisai',
    'solarisas',
    'solarisât',
    'solarisée',
    'solariser',
    'solarises',
    'solarisés',
    'solarisez',
    'solariums',
    'soldaient',
    'soldasses',
    'solderais',
    'solderait',
    'soldèrent',
    'solderiez',
    'solderons',
    'solderont',
    'soldeuses',
    'soléaires',
    'solécisme',
    'solennels',
    'solennisa',
    'solennise',
    'solennisé',
    'solennité',
    'solfatare',
    'solfiâmes',
    'solfiasse',
    'solfiâtes',
    'solfierai',
    'solfieras',
    'solfierez',
    'solfiions',
    'solidages',
    'solidaire',
    'solidifia',
    'solidifie',
    'solidifié',
    'solidités',
    'solifluai',
    'solifluas',
    'solifluât',
    'solifluer',
    'soliflues',
    'solifluez',
    'soliloqua',
    'soliloque',
    'soliloqué',
    'solipèdes',
    'solitaire',
    'solitudes',
    'soliveaux',
    'sollicita',
    'sollicite',
    'sollicité',
    'solmisais',
    'solmisait',
    'solmisant',
    'solmisées',
    'solmisent',
    'solmisera',
    'solmisiez',
    'solmisons',
    'solstices',
    'solutions',
    'solutréen',
    'solvables',
    'somations',
    'somatique',
    'somatisai',
    'somatisas',
    'somatisât',
    'somatisée',
    'somatiser',
    'somatises',
    'somatisés',
    'somatisez',
    'sombrâmes',
    'sombrasse',
    'sombrâtes',
    'sombrerai',
    'sombreras',
    'sombrerez',
    'sombreros',
    'sombrions',
    'sommaient',
    'sommaires',
    'sommasses',
    'sommation',
    'sommeilla',
    'sommeille',
    'sommeillé',
    'sommelier',
    'sommerais',
    'sommerait',
    'sommèrent',
    'sommeriez',
    'sommerons',
    'sommeront',
    'sommitale',
    'sommitaux',
    'somnifère',
    'somnolais',
    'somnolait',
    'somnolant',
    'somnolent',
    'somnolera',
    'somnoliez',
    'somnolons',
    'somptueux',
    'sonatines',
    'sondaient',
    'sondasses',
    'sonderais',
    'sonderait',
    'sondèrent',
    'sonderiez',
    'sonderons',
    'sonderont',
    'sondeuses',
    'songeâmes',
    'songeasse',
    'songeâtes',
    'songerais',
    'songerait',
    'songèrent',
    'songeries',
    'songeriez',
    'songerons',
    'songeront',
    'songeuses',
    'sonnaient',
    'sonnailla',
    'sonnaille',
    'sonnaillé',
    'sonnantes',
    'sonnasses',
    'sonnerais',
    'sonnerait',
    'sonnèrent',
    'sonneries',
    'sonneriez',
    'sonnerons',
    'sonneront',
    'sonnettes',
    'sonomètre',
    'sonorisai',
    'sonorisas',
    'sonorisât',
    'sonorisée',
    'sonoriser',
    'sonorises',
    'sonorisés',
    'sonorisez',
    'sonorités',
    'sophismes',
    'sophistes',
    'sorbitols',
    'sorcières',
    'sordidité',
    'sorguâmes',
    'sorguasse',
    'sorguâtes',
    'sorguerai',
    'sorgueras',
    'sorguerez',
    'sorguions',
    'sornettes',
    'sororales',
    'sortables',
    'sortaient',
    'sortantes',
    'sortilège',
    'sortirais',
    'sortirait',
    'sortirent',
    'sortiriez',
    'sortirons',
    'sortiront',
    'sortisses',
    'sostenuto',
    'sottement',
    'sottisier',
    'soubrette',
    'souchetai',
    'souchetas',
    'souchetât',
    'souchetée',
    'soucheter',
    'souchetés',
    'souchetez',
    'souchette',
    'souchevai',
    'souchevas',
    'souchevât',
    'souchevée',
    'souchever',
    'souchevés',
    'souchèves',
    'souchevez',
    'sou-chong',
    'souciâmes',
    'souciasse',
    'souciâtes',
    'soucierai',
    'soucieras',
    'soucierez',
    'soucieuse',
    'souciions',
    'soucoupes',
    'soudables',
    'soudaient',
    'soudaines',
    'soudanais',
    'soudantes',
    'soudasses',
    'souderais',
    'souderait',
    'soudèrent',
    'souderiez',
    'souderons',
    'souderont',
    'soudeuses',
    'soudières',
    'soudoient',
    'soudoiera',
    'soudoyais',
    'soudoyait',
    'soudoyant',
    'soudoyées',
    'soudoyiez',
    'soudoyons',
    'soufferte',
    'soufferts',
    'soufflage',
    'soufflais',
    'soufflait',
    'soufflant',
    'soufflard',
    'soufflées',
    'soufflent',
    'soufflera',
    'souffleta',
    'souffleté',
    'soufflets',
    'souffleur',
    'souffliez',
    'soufflons',
    'soufflure',
    'souffrais',
    'souffrait',
    'souffrant',
    'souffrent',
    'souffriez',
    'souffrira',
    'souffrons',
    'soufismes',
    'soufrages',
    'soufrâmes',
    'soufrasse',
    'soufrâtes',
    'soufrerai',
    'soufreras',
    'soufrerez',
    'soufreurs',
    'soufreuse',
    'soufrière',
    'soufrions',
    'soufroirs',
    'souhaitai',
    'souhaitas',
    'souhaitât',
    'souhaitée',
    'souhaiter',
    'souhaites',
    'souhaités',
    'souhaitez',
    'souillais',
    'souillait',
    'souillant',
    'souillard',
    'souillées',
    'souillent',
    'souillera',
    'souilliez',
    'souillons',
    'souillure',
    'soulageai',
    'soulageas',
    'soulageât',
    'soulagées',
    'soulagent',
    'soulagera',
    'soulagiez',
    'soûlaient',
    'soûlardes',
    'soûlasses',
    'soûlaudes',
    'soûlerais',
    'soûlerait',
    'soûlèrent',
    'soûleries',
    'soûleriez',
    'soûlerons',
    'soûleront',
    'soulevais',
    'soulevait',
    'soulevant',
    'soulevées',
    'soulèvent',
    'soulèvera',
    'souleviez',
    'soulevons',
    'soulignai',
    'soulignas',
    'soulignât',
    'soulignée',
    'souligner',
    'soulignes',
    'soulignés',
    'soulignez',
    'soumettes',
    'soumettez',
    'soumettra',
    'soumettre',
    'soumirent',
    'soumisses',
    'soupaient',
    'soupasses',
    'soupçonna',
    'soupçonne',
    'soupçonné',
    'soupentes',
    'souperais',
    'souperait',
    'soupèrent',
    'souperiez',
    'souperons',
    'souperont',
    'soupesais',
    'soupesait',
    'soupesant',
    'soupesées',
    'soupèsent',
    'soupèsera',
    'soupesiez',
    'soupesons',
    'soupeuses',
    'soupières',
    'soupirail',
    'soupirais',
    'soupirait',
    'soupirant',
    'soupiraux',
    'soupirent',
    'soupirera',
    'soupiriez',
    'soupirons',
    'souplesse',
    'souquâmes',
    'souquasse',
    'souquâtes',
    'souquerai',
    'souqueras',
    'souquerez',
    'souquions',
    'sourcière',
    'sourciers',
    'sourcilla',
    'sourcille',
    'sourcillé',
    'sourdinai',
    'sourdinas',
    'sourdinât',
    'sourdinée',
    'sourdiner',
    'sourdines',
    'sourdinés',
    'sourdinez',
    'souriante',
    'souriants',
    'souriceau',
    'souriions',
    'sourirais',
    'sourirait',
    'sourirent',
    'souririez',
    'sourirons',
    'souriront',
    'sourisses',
    'sournoise',
    'sous-aide',
    'sous-bois',
    'sous-chef',
    'souscrira',
    'souscrire',
    'souscrite',
    'souscrits',
    'souscrive',
    'sous-loua',
    'sous-loue',
    'sous-loué',
    'sous-main',
    'sous-offs',
    'sous-paie',
    'sous-paya',
    'sous-paye',
    'sous-payé',
    'sous-pied',
    'sous-pull',
    'soussigné',
    'sous-sols',
    'sous-tend',
    'soustraie',
    'soustrais',
    'soustrait',
    'sous-vira',
    'sous-vire',
    'sous-viré',
    'soutachai',
    'soutachas',
    'soutachât',
    'soutachée',
    'soutacher',
    'soutaches',
    'soutachés',
    'soutachez',
    'soutenais',
    'soutenait',
    'soutenant',
    'souteneur',
    'souteniez',
    'soutenons',
    'soutenues',
    'soutienne',
    'soutînmes',
    'soutinsse',
    'soutîntes',
    'soutirage',
    'soutirais',
    'soutirait',
    'soutirant',
    'soutirées',
    'soutirent',
    'soutirera',
    'soutiriez',
    'soutirons',
    'souvenais',
    'souvenait',
    'souvenant',
    'souveniez',
    'souvenirs',
    'souvenons',
    'souvenues',
    'souverain',
    'souvienne',
    'souvînmes',
    'souvinsse',
    'souvîntes',
    'soviétisa',
    'soviétise',
    'soviétisé',
    'sovkhozes',
    'spacieuse',
    'spadassin',
    'spaghetti',
    'sparadrap',
    'spardecks',
    'spartéine',
    'sparterie',
    'spartiate',
    'spatangue',
    'spathifia',
    'spathifie',
    'spathifié',
    'spathique',
    'spatiales',
    'spéciales',
    'spécieuse',
    'spécifiai',
    'spécifias',
    'spécifiât',
    'spécifiée',
    'spécifier',
    'spécifies',
    'spécifiés',
    'spécifiez',
    'spécimens',
    'spectacle',
    'spectrale',
    'spectraux',
    'spéculais',
    'spéculait',
    'spéculant',
    'spéculent',
    'spéculera',
    'spéculiez',
    'spéculons',
    'spéculums',
    'spergules',
    'spermatie',
    'sphacélai',
    'sphacélas',
    'sphacélât',
    'sphacélée',
    'sphacéler',
    'sphacélés',
    'sphacèles',
    'sphacélez',
    'sphaignes',
    'sphénoïde',
    'sphérique',
    'sphéroïde',
    'sphincter',
    'spinelles',
    'spinnaker',
    'spiracles',
    'spirantes',
    'spirifers',
    'spirilles',
    'spiritain',
    'spiritual',
    'spirituel',
    'spiroïdal',
    'spirorbes',
    'spitantes',
    'spittâmes',
    'spittasse',
    'spittâtes',
    'spitterai',
    'spitteras',
    'spitterez',
    'spittions',
    'splendeur',
    'splendide',
    'splittais',
    'splittait',
    'splittant',
    'splittées',
    'splittent',
    'splittera',
    'splittiez',
    'splittons',
    'spoliâmes',
    'spoliasse',
    'spoliâtes',
    'spolierai',
    'spolieras',
    'spolierez',
    'spoliions',
    'spongieux',
    'spongille',
    'spontanée',
    'spontanés',
    'sporanges',
    'sporogone',
    'sportives',
    'sportsman',
    'sportsmen',
    'sportules',
    'sporulais',
    'sporulait',
    'sporulant',
    'sporulent',
    'sporulera',
    'sporuliez',
    'sporulons',
    'spoutniks',
    'sprintais',
    'sprintait',
    'sprintant',
    'sprintent',
    'sprintera',
    'sprintiez',
    'sprintons',
    'spumeuses',
    'spumosité',
    'squameuse',
    'squamules',
    'squatines',
    'squattais',
    'squattait',
    'squattant',
    'squattées',
    'squattent',
    'squattera',
    'squatters',
    'squattiez',
    'squattons',
    'squelette',
    'squirreux',
    'squirrhes',
    'stabilisa',
    'stabilise',
    'stabilisé',
    'stabilité',
    'staccatos',
    'staffâmes',
    'staffasse',
    'staffâtes',
    'stafferai',
    'stafferas',
    'stafferez',
    'staffeurs',
    'staffions',
    'stagiaire',
    'stagnâmes',
    'stagnante',
    'stagnants',
    'stagnasse',
    'stagnâtes',
    'stagnerai',
    'stagneras',
    'stagnerez',
    'stagnions',
    'staknings',
    'stalinien',
    'stalinisa',
    'stalinise',
    'stalinisé',
    'staminale',
    'staminaux',
    'staminées',
    'standards',
    'standings',
    'staphylin',
    'starifiai',
    'starifias',
    'starifiât',
    'starifiée',
    'starifier',
    'starifies',
    'starifiés',
    'starifiez',
    'starisais',
    'starisait',
    'starisant',
    'starisées',
    'starisent',
    'starisera',
    'starisiez',
    'starisons',
    'starlette',
    'starostes',
    'stationna',
    'stationne',
    'stationné',
    'statiques',
    'statuaire',
    'statuâmes',
    'statuasse',
    'statuâtes',
    'statuerai',
    'statueras',
    'statuerez',
    'statuette',
    'statufiai',
    'statufias',
    'statufiât',
    'statufiée',
    'statufier',
    'statufies',
    'statufiés',
    'statufiez',
    'statuions',
    'stéarates',
    'stéarines',
    'stéarique',
    'stéatites',
    'stéatoses',
    'stégomyie',
    'steinbock',
    'stellages',
    'stellaire',
    'sténosage',
    'sténotypa',
    'sténotype',
    'sténotypé',
    'steppages',
    'steppeurs',
    'steppique',
    'stéradian',
    'stéraient',
    'stérasses',
    'stercoral',
    'stéréoduc',
    'stérerais',
    'stérerait',
    'stérèrent',
    'stéreriez',
    'stérerons',
    'stéreront',
    'stérilets',
    'stérilisa',
    'stérilise',
    'stérilisé',
    'stérilité',
    'stériques',
    'sternales',
    'stéroïdes',
    'stigmates',
    'stimugène',
    'stimulais',
    'stimulait',
    'stimulant',
    'stimulées',
    'stimulent',
    'stimulera',
    'stimuliez',
    'stimuline',
    'stimulons',
    'stipendia',
    'stipendie',
    'stipendié',
    'stipitées',
    'stipulais',
    'stipulait',
    'stipulant',
    'stipulées',
    'stipulent',
    'stipulera',
    'stipuliez',
    'stipulons',
    'stockages',
    'stockâmes',
    'stockasse',
    'stockâtes',
    'stock-car',
    'stockerai',
    'stockeras',
    'stockerez',
    'stockions',
    'stockiste',
    'stoïciens',
    'stoïcisme',
    'stomacale',
    'stomacaux',
    'stomatite',
    'stoppages',
    'stoppâmes',
    'stoppasse',
    'stoppâtes',
    'stopperai',
    'stopperas',
    'stopperez',
    'stoppeurs',
    'stoppeuse',
    'stoppions',
    'storistes',
    'strabique',
    'strabisme',
    'stradiots',
    'stramoine',
    'strangula',
    'strangule',
    'strangulé',
    'stratèges',
    'stratégie',
    'stratifia',
    'stratifie',
    'stratifié',
    'stratiome',
    'stressais',
    'stressait',
    'stressant',
    'stressées',
    'stressent',
    'stressera',
    'stressiez',
    'stressons',
    'striaient',
    'striasses',
    'striation',
    'striction',
    'stridence',
    'stridente',
    'stridents',
    'stridulai',
    'stridulas',
    'stridulât',
    'stridulée',
    'striduler',
    'stridules',
    'stridulés',
    'stridulez',
    'strierais',
    'strierait',
    'strièrent',
    'strieriez',
    'strierons',
    'strieront',
    'strigiles',
    'stripages',
    'strippais',
    'strippait',
    'strippant',
    'strippées',
    'strippent',
    'strippera',
    'strippiez',
    'stripping',
    'strippons',
    'striquais',
    'striquait',
    'striquant',
    'striquées',
    'striquent',
    'striquera',
    'striquiez',
    'striquons',
    'strobiles',
    'strongles',
    'strongyle',
    'strontium',
    'structura',
    'structure',
    'structuré',
    'stucateur',
    'studettes',
    'studieuse',
    'stupéfait',
    'stupéfiai',
    'stupéfias',
    'stupéfiât',
    'stupéfiée',
    'stupéfier',
    'stupéfies',
    'stupéfiés',
    'stupéfiez',
    'stupidité',
    'stuquâmes',
    'stuquasse',
    'stuquâtes',
    'stuquerai',
    'stuqueras',
    'stuquerez',
    'stuquions',
    'stylaient',
    'stylasses',
    'stylerais',
    'stylerait',
    'stylèrent',
    'styleriez',
    'stylerons',
    'styleront',
    'stylisais',
    'stylisait',
    'stylisant',
    'stylisées',
    'stylisent',
    'stylisera',
    'stylisiez',
    'stylismes',
    'stylisons',
    'stylistes',
    'stylobate',
    'styloïdes',
    'stylomine',
    'styptique',
    'styrolène',
    'suassions',
    'suavement',
    'subaigues',
    'subalpine',
    'subalpins',
    'subdivisa',
    'subdivise',
    'subdivisé',
    'subéreuse',
    'subérines',
    'subirions',
    'subissais',
    'subissait',
    'subissant',
    'subissent',
    'subissiez',
    'subissons',
    'subjacent',
    'subjectif',
    'subjuguai',
    'subjuguas',
    'subjuguât',
    'subjuguée',
    'subjuguer',
    'subjugues',
    'subjugués',
    'subjuguez',
    'sublimais',
    'sublimait',
    'sublimant',
    'sublimées',
    'subliment',
    'sublimera',
    'sublimiez',
    'sublimité',
    'sublimons',
    'submergea',
    'submergée',
    'submerger',
    'submerges',
    'submergés',
    'submergez',
    'subodorai',
    'subodoras',
    'subodorât',
    'subodorée',
    'subodorer',
    'subodores',
    'subodorés',
    'subodorez',
    'subornais',
    'subornait',
    'subornant',
    'subornées',
    'subornent',
    'subornera',
    'suborneur',
    'suborniez',
    'subornons',
    'subrogeai',
    'subrogeas',
    'subrogeât',
    'subrogées',
    'subrogent',
    'subrogera',
    'subrogiez',
    'subsistai',
    'subsistas',
    'subsistât',
    'subsister',
    'subsistes',
    'subsistez',
    'substance',
    'substitua',
    'substitue',
    'substitué',
    'substitut',
    'substrats',
    'subtilisa',
    'subtilise',
    'subtilisé',
    'subtilité',
    'suburbain',
    'subvenais',
    'subvenait',
    'subvenant',
    'subveniez',
    'subvenons',
    'subvenues',
    'subversif',
    'subvertie',
    'subvertir',
    'subvertis',
    'subvertit',
    'subvertît',
    'subvienne',
    'subvînmes',
    'subvinsse',
    'subvîntes',
    'suçassent',
    'suçassiez',
    'succédais',
    'succédait',
    'succédané',
    'succédant',
    'succédées',
    'succèdent',
    'succédera',
    'succédiez',
    'succédons',
    'successif',
    'succincte',
    'succincts',
    'succombai',
    'succombas',
    'succombât',
    'succomber',
    'succombes',
    'succombez',
    'succulent',
    'sucements',
    'sucerions',
    'suçotâmes',
    'suçotasse',
    'suçotâtes',
    'suçoterai',
    'suçoteras',
    'suçoterez',
    'suçotions',
    'sucraient',
    'sucrantes',
    'sucrasses',
    'sucrerais',
    'sucrerait',
    'sucrèrent',
    'sucreries',
    'sucreriez',
    'sucrerons',
    'sucreront',
    'sucrières',
    'sudations',
    'sudatoire',
    'sudorales',
    'sud-ouest',
    'suédoises',
    'sueraient',
    'suffirais',
    'suffirait',
    'suffirent',
    'suffiriez',
    'suffirons',
    'suffiront',
    'suffisais',
    'suffisait',
    'suffisant',
    'suffisent',
    'suffisiez',
    'suffisons',
    'suffisses',
    'suffixais',
    'suffixait',
    'suffixale',
    'suffixant',
    'suffixaux',
    'suffixées',
    'suffixent',
    'suffixera',
    'suffixiez',
    'suffixons',
    'suffocant',
    'suffoquai',
    'suffoquas',
    'suffoquât',
    'suffoquée',
    'suffoquer',
    'suffoques',
    'suffoqués',
    'suffoquez',
    'suffrages',
    'suffusion',
    'suggérais',
    'suggérait',
    'suggérant',
    'suggérées',
    'suggèrent',
    'suggérera',
    'suggériez',
    'suggérons',
    'suggestif',
    'suicidais',
    'suicidait',
    'suicidant',
    'suicidées',
    'suicident',
    'suicidera',
    'suicidiez',
    'suicidons',
    'suifaient',
    'suifasses',
    'suiferais',
    'suiferait',
    'suifèrent',
    'suiferiez',
    'suiferons',
    'suiferont',
    'suiffâmes',
    'suiffasse',
    'suiffâtes',
    'suifferai',
    'suifferas',
    'suifferez',
    'suiffeuse',
    'suiffions',
    'suintâmes',
    'suintante',
    'suintants',
    'suintasse',
    'suintâtes',
    'suinterai',
    'suinteras',
    'suinterez',
    'suintions',
    'suissesse',
    'suivaient',
    'suivantes',
    'suiveuses',
    'suivirent',
    'suivismes',
    'suivisses',
    'suivistes',
    'suivrions',
    'sujétions',
    'sulcature',
    'sulfamide',
    'sulfatage',
    'sulfatais',
    'sulfatait',
    'sulfatant',
    'sulfatées',
    'sulfatent',
    'sulfatera',
    'sulfateur',
    'sulfatiez',
    'sulfatons',
    'sulfitage',
    'sulfitais',
    'sulfitait',
    'sulfitant',
    'sulfitées',
    'sulfitent',
    'sulfitera',
    'sulfitiez',
    'sulfitons',
    'sulfonais',
    'sulfonait',
    'sulfonant',
    'sulfonées',
    'sulfonent',
    'sulfonera',
    'sulfoniez',
    'sulfonons',
    'sulfosels',
    'sulfurage',
    'sulfurais',
    'sulfurait',
    'sulfurant',
    'sulfurées',
    'sulfurent',
    'sulfurera',
    'sulfureux',
    'sulfuriez',
    'sulfurisé',
    'sulfurons',
    'sulpicien',
    'sultanats',
    'sumériens',
    'sunlights',
    'supérette',
    'superfine',
    'superfini',
    'superfins',
    'superflue',
    'superflus',
    'supérieur',
    'supernova',
    'superposa',
    'superpose',
    'superposé',
    'superstar',
    'supervisa',
    'supervise',
    'supervisé',
    'supplanta',
    'supplante',
    'supplanté',
    'suppléais',
    'suppléait',
    'suppléant',
    'suppléées',
    'suppléent',
    'suppléera',
    'suppléiez',
    'suppléons',
    'supplétif',
    'suppliais',
    'suppliait',
    'suppliant',
    'supplices',
    'supplicia',
    'supplicie',
    'supplicié',
    'suppliées',
    'supplient',
    'suppliera',
    'suppliiez',
    'supplions',
    'supplique',
    'supportai',
    'supportas',
    'supportât',
    'supportée',
    'supporter',
    'supportes',
    'supportés',
    'supportez',
    'supposais',
    'supposait',
    'supposant',
    'supposées',
    'supposent',
    'supposera',
    'supposiez',
    'supposons',
    'supprimai',
    'supprimas',
    'supprimât',
    'supprimée',
    'supprimer',
    'supprimes',
    'supprimés',
    'supprimez',
    'suppurais',
    'suppurait',
    'suppurant',
    'suppurent',
    'suppurera',
    'suppuriez',
    'suppurons',
    'supputais',
    'supputait',
    'supputant',
    'supputées',
    'supputent',
    'supputera',
    'supputiez',
    'supputons',
    'surabonda',
    'surabonde',
    'surabondé',
    'suractivé',
    'suraiguës',
    'surajouta',
    'surajoute',
    'surajouté',
    'surannées',
    'surbaissa',
    'surbaisse',
    'surbaissé',
    'surcharge',
    'surchargé',
    'surclassa',
    'surclasse',
    'surclassé',
    'surcontra',
    'surcontre',
    'surcontré',
    'surcoupai',
    'surcoupas',
    'surcoupât',
    'surcoupée',
    'surcouper',
    'surcoupes',
    'surcoupés',
    'surcoupez',
    'surcroîts',
    'surdorais',
    'surdorait',
    'surdorant',
    'surdorées',
    'surdorent',
    'surdorera',
    'surdoriez',
    'surdorons',
    'surdosage',
    'surdouées',
    'surédifia',
    'surédifie',
    'surédifié',
    'surélevai',
    'surélevas',
    'surélevât',
    'surélevée',
    'surélever',
    'surélevés',
    'surélèves',
    'surélevez',
    'suremploi',
    'suréquipa',
    'suréquipe',
    'suréquipé',
    'surestima',
    'surestime',
    'surestimé',
    'surévalua',
    'surévalue',
    'surévalué',
    'surexcita',
    'surexcite',
    'surexcité',
    'surexposa',
    'surexpose',
    'surexposé',
    'surfaçais',
    'surfaçait',
    'surfaçant',
    'surfacées',
    'surfacent',
    'surfacera',
    'surfaciez',
    'surfaçons',
    'surfaient',
    'surfaites',
    'surfasses',
    'surferais',
    'surferait',
    'surfèrent',
    'surferiez',
    'surferons',
    'surferont',
    'surfilage',
    'surfilais',
    'surfilait',
    'surfilant',
    'surfilées',
    'surfilent',
    'surfilera',
    'surfiliez',
    'surfilons',
    'surfirent',
    'surfisses',
    'surfondue',
    'surfondus',
    'surfusion',
    'surgelais',
    'surgelait',
    'surgelant',
    'surgelées',
    'surgèlent',
    'surgèlera',
    'surgeliez',
    'surgelons',
    'surgeonna',
    'surgeonne',
    'surgeonné',
    'surgirais',
    'surgirait',
    'surgirent',
    'surgiriez',
    'surgirons',
    'surgiront',
    'surgisses',
    'surgissez',
    'surglaçai',
    'surglaças',
    'surglaçât',
    'surglacée',
    'surglacer',
    'surglaces',
    'surglacés',
    'surglacez',
    'surhaussa',
    'surhausse',
    'surhaussé',
    'surhommes',
    'surhumain',
    'suricates',
    'surikates',
    'surimposa',
    'surimpose',
    'surimposé',
    'surinâmes',
    'surinasse',
    'surinâtes',
    'surinerai',
    'surineras',
    'surinerez',
    'surinions',
    'suririons',
    'surissais',
    'surissait',
    'surissant',
    'surissent',
    'surissiez',
    'surissons',
    'surjalais',
    'surjalait',
    'surjalant',
    'surjalent',
    'surjalera',
    'surjaliez',
    'surjalons',
    'surjetais',
    'surjetait',
    'surjetant',
    'surjetées',
    'surjetiez',
    'surjetons',
    'surjettes',
    'surliâmes',
    'surliasse',
    'surliâtes',
    'surlierai',
    'surlieras',
    'surlierez',
    'surliions',
    'surlonges',
    'surlouais',
    'surlouait',
    'surlouant',
    'surlouées',
    'surlouent',
    'surlouera',
    'surlouiez',
    'surlouons',
    'surloyers',
    'surmenage',
    'surmenais',
    'surmenait',
    'surmenant',
    'surmenées',
    'surmènent',
    'surmènera',
    'surmeniez',
    'surmenons',
    'surmontai',
    'surmontas',
    'surmontât',
    'surmontée',
    'surmonter',
    'surmontes',
    'surmontés',
    'surmontez',
    'surmoulai',
    'surmoulas',
    'surmoulât',
    'surmoulée',
    'surmouler',
    'surmoules',
    'surmoulés',
    'surmoulez',
    'surmulets',
    'surmulots',
    'surnageai',
    'surnageas',
    'surnageât',
    'surnagent',
    'surnagera',
    'surnagiez',
    'surnombre',
    'surnommai',
    'surnommas',
    'surnommât',
    'surnommée',
    'surnommer',
    'surnommes',
    'surnommés',
    'surnommez',
    'suroffres',
    'suroxydai',
    'suroxydas',
    'suroxydât',
    'suroxydée',
    'suroxyder',
    'suroxydes',
    'suroxydés',
    'suroxydez',
    'surpaient',
    'surpaiera',
    'surpassai',
    'surpassas',
    'surpassât',
    'surpassée',
    'surpasser',
    'surpasses',
    'surpassés',
    'surpassez',
    'surpayais',
    'surpayait',
    'surpayant',
    'surpayées',
    'surpayent',
    'surpayera',
    'surpayiez',
    'surpayons',
    'surpeuplé',
    'surpiquai',
    'surpiquas',
    'surpiquât',
    'surpiquée',
    'surpiquer',
    'surpiques',
    'surpiqués',
    'surpiquez',
    'surpiqûre',
    'surplaces',
    'surplomba',
    'surplombe',
    'surplombé',
    'surplombs',
    'surprends',
    'surprenez',
    'surprenne',
    'surprimes',
    'surprîmes',
    'surprises',
    'surprisse',
    'surprîtes',
    'surrénale',
    'sursatura',
    'sursature',
    'sursaturé',
    'sursautai',
    'sursautas',
    'sursautât',
    'sursauter',
    'sursautes',
    'sursautez',
    'sursemais',
    'sursemait',
    'sursemant',
    'sursemées',
    'sursèment',
    'sursèmera',
    'sursemiez',
    'sursemons',
    'surseoira',
    'sursirent',
    'sursisses',
    'sursoient',
    'sursoyais',
    'sursoyait',
    'sursoyant',
    'sursoyiez',
    'sursoyons',
    'surtaxais',
    'surtaxait',
    'surtaxant',
    'surtaxées',
    'surtaxent',
    'surtaxera',
    'surtaxiez',
    'surtaxons',
    'surtontes',
    'survécues',
    'surveilla',
    'surveille',
    'surveillé',
    'survenais',
    'survenait',
    'survenant',
    'surveniez',
    'survenons',
    'surventes',
    'survenues',
    'survienne',
    'survînmes',
    'survinsse',
    'survîntes',
    'survirage',
    'survirais',
    'survirait',
    'survirant',
    'survirent',
    'survirera',
    'survireur',
    'surviriez',
    'survirons',
    'survivais',
    'survivait',
    'survivant',
    'survivent',
    'surviviez',
    'survivons',
    'survivrai',
    'survivras',
    'survivrez',
    'survolais',
    'survolait',
    'survolant',
    'survolées',
    'survolent',
    'survolera',
    'survoliez',
    'survolons',
    'survoltai',
    'survoltas',
    'survoltât',
    'survoltée',
    'survolter',
    'survoltes',
    'survoltés',
    'survoltez',
    'suscitais',
    'suscitait',
    'suscitant',
    'suscitées',
    'suscitent',
    'suscitera',
    'suscitiez',
    'suscitons',
    'susnommée',
    'susnommés',
    'suspectai',
    'suspectas',
    'suspectât',
    'suspectée',
    'suspecter',
    'suspectes',
    'suspectés',
    'suspectez',
    'suspendes',
    'suspendez',
    'suspendis',
    'suspendit',
    'suspendît',
    'suspendra',
    'suspendre',
    'suspendue',
    'suspendus',
    'suspenses',
    'suspensif',
    'suspentes',
    'suspicion',
    'sustentai',
    'sustentas',
    'sustentât',
    'sustentée',
    'sustenter',
    'sustentes',
    'sustentés',
    'sustentez',
    'susurrais',
    'susurrait',
    'susurrant',
    'susurrées',
    'susurrent',
    'susurrera',
    'susurriez',
    'susurrons',
    'susvisées',
    'suturales',
    'suturâmes',
    'suturasse',
    'suturâtes',
    'suturerai',
    'sutureras',
    'suturerez',
    'suturions',
    'suzeraine',
    'suzerains',
    'svastikas',
    'sveltesse',
    'swastikas',
    'swinguais',
    'swinguait',
    'swinguant',
    'swinguent',
    'swinguera',
    'swinguiez',
    'swinguons',
    'sybarites',
    'sycomores',
    'syllabais',
    'syllabait',
    'syllabant',
    'syllabées',
    'syllabent',
    'syllabera',
    'syllabiez',
    'syllabons',
    'syllepses',
    'sylphides',
    'sylvestre',
    'sylvicole',
    'sylvinite',
    'symbioses',
    'symbiotes',
    'symbolisa',
    'symbolise',
    'symbolisé',
    'symétries',
    'symétrisa',
    'symétrise',
    'symétrisé',
    'sympathie',
    'symphonie',
    'symphyses',
    'symposion',
    'symposium',
    'symptômes',
    'synagogue',
    'synalèphe',
    'synaptase',
    'synarchie',
    'synchrone',
    'synclinal',
    'syncopais',
    'syncopait',
    'syncopale',
    'syncopant',
    'syncopaux',
    'syncopées',
    'syncopent',
    'syncopera',
    'syncopiez',
    'syncopons',
    'syndicale',
    'syndicats',
    'syndicaux',
    'syndiquai',
    'syndiquas',
    'syndiquât',
    'syndiquée',
    'syndiquer',
    'syndiques',
    'syndiqués',
    'syndiquez',
    'syndromes',
    'synéchies',
    'synérèses',
    'synergies',
    'syngnathe',
    'synodales',
    'synodique',
    'synonymes',
    'synonymie',
    'synopsies',
    'synostose',
    'syntagmes',
    'synthèses',
    'syntonies',
    'syntonisa',
    'syntonise',
    'syntonisé',
    'syphilide',
    'syriaques',
    'syriennes',
    'tabagique',
    'tabagisme',
    'tabassais',
    'tabassait',
    'tabassant',
    'tabassées',
    'tabassent',
    'tabassera',
    'tabassiez',
    'tabassons',
    'tabatière',
    'tabellion',
    'tabétique',
    'tablaient',
    'tablasses',
    'tablature',
    'tablerais',
    'tablerait',
    'tablèrent',
    'tableriez',
    'tablerons',
    'tableront',
    'tabletier',
    'tablettes',
    'tabourets',
    'tabulaire',
    'tabulâmes',
    'tabulasse',
    'tabulâtes',
    'tabulerai',
    'tabuleras',
    'tabulerez',
    'tabulions',
    'tachaient',
    'tâchaient',
    'tâchantes',
    'tachasses',
    'tâchasses',
    'tacherais',
    'tâcherais',
    'tacherait',
    'tâcherait',
    'tachèrent',
    'tâchèrent',
    'tacheriez',
    'tâcheriez',
    'tacherons',
    'tâcherons',
    'tacheront',
    'tâcheront',
    'tachetais',
    'tachetait',
    'tachetant',
    'tachetées',
    'tachetiez',
    'tachetons',
    'tachettes',
    'tacheture',
    'tachismes',
    'tachistes',
    'taciturne',
    'tacticien',
    'tactiques',
    'tactismes',
    'tahitiens',
    'taillable',
    'tailladai',
    'tailladas',
    'tailladât',
    'tailladée',
    'taillader',
    'taillades',
    'tailladés',
    'tailladez',
    'taillages',
    'taillâmes',
    'taillasse',
    'taillâtes',
    'taillerai',
    'tailleras',
    'taillerez',
    'taillerie',
    'tailleurs',
    'tailleuse',
    'taillions',
    'tailloirs',
    'tailloles',
    'tairaient',
    'taisaient',
    'taiseuses',
    'talassent',
    'talassiez',
    'talerions',
    'talismans',
    'tallaient',
    'tallasses',
    'tallerais',
    'tallerait',
    'tallèrent',
    'talleriez',
    'tallerons',
    'talleront',
    'tallipots',
    'talmouses',
    'talochais',
    'talochait',
    'talochant',
    'talochées',
    'talochent',
    'talochera',
    'talochiez',
    'talochons',
    'talonnage',
    'talonnais',
    'talonnait',
    'talonnant',
    'talonnées',
    'talonnent',
    'talonnera',
    'talonneur',
    'talonniez',
    'talonnons',
    'talquâmes',
    'talquasse',
    'talquâtes',
    'talquerai',
    'talqueras',
    'talquerez',
    'talqueuse',
    'talquions',
    'tamanduas',
    'tamanoirs',
    'tambourin',
    'tamisages',
    'tamisâmes',
    'tamisante',
    'tamisants',
    'tamisasse',
    'tamisâtes',
    'tamiserai',
    'tamiseras',
    'tamiserez',
    'tamiserie',
    'tamiseurs',
    'tamiseuse',
    'tamisière',
    'tamisiers',
    'tamisions',
    'tamponnai',
    'tamponnas',
    'tamponnât',
    'tamponnée',
    'tamponner',
    'tamponnes',
    'tamponnés',
    'tamponnez',
    'tanaisies',
    'tançaient',
    'tançasses',
    'tancerais',
    'tancerait',
    'tancèrent',
    'tanceriez',
    'tancerons',
    'tanceront',
    'tangences',
    'tangentes',
    'tangibles',
    'tanguâmes',
    'tanguasse',
    'tanguâtes',
    'tanguerai',
    'tangueras',
    'tanguerez',
    'tanguière',
    'tanguions',
    'tanisages',
    'tanisâmes',
    'tanisasse',
    'tanisâtes',
    'taniserai',
    'taniseras',
    'taniserez',
    'tanisions',
    'tankistes',
    'tannaient',
    'tannasses',
    'tannerais',
    'tannerait',
    'tannèrent',
    'tanneries',
    'tanneriez',
    'tannerons',
    'tanneront',
    'tanneuses',
    'tanniques',
    'tannisais',
    'tannisait',
    'tannisant',
    'tannisées',
    'tannisent',
    'tannisera',
    'tannisiez',
    'tannisons',
    'tantièmes',
    'tantinets',
    'tantrisme',
    'tapageais',
    'tapageait',
    'tapageant',
    'tapageons',
    'tapagerai',
    'tapageras',
    'tapagerez',
    'tapageurs',
    'tapageuse',
    'tapagions',
    'tapassent',
    'tapassiez',
    'tape-culs',
    'tapements',
    'tapenades',
    'taperions',
    'tapinâmes',
    'tapinasse',
    'tapinâtes',
    'tapinerai',
    'tapineras',
    'tapinerez',
    'tapinions',
    'tapirions',
    'tapissais',
    'tapissait',
    'tapissant',
    'tapissées',
    'tapissent',
    'tapissera',
    'tapissier',
    'tapissiez',
    'tapissons',
    'taponnais',
    'taponnait',
    'taponnant',
    'taponnées',
    'taponnent',
    'taponnera',
    'taponniez',
    'taponnons',
    'tapotages',
    'tapotâmes',
    'tapotante',
    'tapotants',
    'tapotasse',
    'tapotâtes',
    'tapoterai',
    'tapoteras',
    'tapoterez',
    'tapotions',
    'taquaient',
    'taquasses',
    'taquerais',
    'taquerait',
    'taquèrent',
    'taqueriez',
    'taquerons',
    'taqueront',
    'taquinais',
    'taquinait',
    'taquinant',
    'taquinées',
    'taquinent',
    'taquinera',
    'taquiniez',
    'taquinons',
    'tarabusta',
    'tarabuste',
    'tarabusté',
    'tararages',
    'tarasques',
    'tarassent',
    'tarassiez',
    'taraudage',
    'taraudais',
    'taraudait',
    'taraudant',
    'taraudées',
    'taraudent',
    'taraudera',
    'taraudeur',
    'taraudiez',
    'taraudons',
    'taravelle',
    'tarbouche',
    'tarbouchs',
    'tardaient',
    'tardasses',
    'tarderais',
    'tarderait',
    'tardèrent',
    'tarderiez',
    'tarderons',
    'tarderont',
    'tardillon',
    'tardiveté',
    'tarentule',
    'tarerions',
    'targettes',
    'targuâmes',
    'targuasse',
    'targuâtes',
    'targuerai',
    'targueras',
    'targuerez',
    'targuions',
    'tarifaire',
    'tarifâmes',
    'tarifante',
    'tarifants',
    'tarifasse',
    'tarifâtes',
    'tariferai',
    'tariferas',
    'tariferez',
    'tarifiais',
    'tarifiait',
    'tarifiant',
    'tarifiées',
    'tarifient',
    'tarifiera',
    'tarifiiez',
    'tarifions',
    'taririons',
    'tarissais',
    'tarissait',
    'tarissant',
    'tarissent',
    'tarissiez',
    'tarissons',
    'tarlatane',
    'tarsienne',
    'tartarins',
    'tartinais',
    'tartinait',
    'tartinant',
    'tartinées',
    'tartinent',
    'tartinera',
    'tartiniez',
    'tartinons',
    'tartirais',
    'tartirait',
    'tartirent',
    'tartiriez',
    'tartirons',
    'tartiront',
    'tartisses',
    'tartissez',
    'tartrates',
    'tartreuse',
    'tartrique',
    'tartuffes',
    'tassaient',
    'tassasses',
    'tassement',
    'tasserais',
    'tasserait',
    'tassèrent',
    'tasseriez',
    'tasserons',
    'tasseront',
    'tassettes',
    'taste-vin',
    'tâtassent',
    'tâtassiez',
    'tâterions',
    'tatillons',
    'tâtonnais',
    'tâtonnait',
    'tâtonnant',
    'tâtonnent',
    'tâtonnera',
    'tâtonniez',
    'tâtonnons',
    'tatouages',
    'tatouâmes',
    'tatouasse',
    'tatouâtes',
    'tatouerai',
    'tatoueras',
    'tatouerez',
    'tatoueurs',
    'tatouions',
    'taulardes',
    'taulières',
    'taupières',
    'taurillon',
    'taurobole',
    'tautomère',
    'tavaïolle',
    'tavelâmes',
    'tavelasse',
    'tavelâtes',
    'tavelions',
    'tavellais',
    'tavellait',
    'tavellant',
    'tavellées',
    'tavellent',
    'tavellera',
    'tavelliez',
    'tavellons',
    'tavelures',
    'tavernier',
    'tavillons',
    'taxassent',
    'taxassiez',
    'taxateurs',
    'taxations',
    'taxatives',
    'taxerions',
    'taximètre',
    'taxinomie',
    'Taxiphone',
    'taxodiums',
    'taxonomie',
    'taylorisa',
    'taylorise',
    'taylorisé',
    'tcharchaf',
    'tchitolas',
    'technique',
    'technisai',
    'technisas',
    'technisât',
    'technisée',
    'techniser',
    'technises',
    'technisés',
    'technisez',
    'tectrices',
    'teen-ager',
    'tee-shirt',
    'tégénaire',
    'téguments',
    'teigneuse',
    'teignîmes',
    'teignions',
    'teignisse',
    'teignîtes',
    'teillages',
    'teillâmes',
    'teillasse',
    'teillâtes',
    'teillerai',
    'teilleras',
    'teillerez',
    'teilleurs',
    'teilleuse',
    'teillions',
    'teindrais',
    'teindrait',
    'teindriez',
    'teindrons',
    'teindront',
    'teintâmes',
    'teintasse',
    'teintâtes',
    'teinterai',
    'teinteras',
    'teinterez',
    'teintions',
    'teintures',
    'télébenne',
    'télécopia',
    'télécopie',
    'télécopié',
    'téléfilms',
    'téléguida',
    'téléguide',
    'téléguidé',
    'télémarks',
    'télémétra',
    'télémétré',
    'télémètre',
    'télépathe',
    'téléphona',
    'téléphone',
    'téléphoné',
    'téléradio',
    'télescopa',
    'télescope',
    'télescopé',
    'télésiège',
    'télétexte',
    'télétypes',
    'télévisai',
    'télévisas',
    'télévisât',
    'télévisée',
    'téléviser',
    'télévises',
    'télévisés',
    'télévisez',
    'télexâmes',
    'télexasse',
    'télexâtes',
    'télexerai',
    'télexeras',
    'télexerez',
    'télexions',
    'télexiste',
    'tellement',
    'tellières',
    'tellurate',
    'tellureux',
    'tellurien',
    'tellurure',
    'télophase',
    'téméraire',
    'témérités',
    'témoignai',
    'témoignas',
    'témoignât',
    'témoignée',
    'témoigner',
    'témoignes',
    'témoignés',
    'témoignez',
    'tempérais',
    'tempérait',
    'tempérant',
    'tempérées',
    'tempèrent',
    'tempérera',
    'tempériez',
    'tempérons',
    'tempêtais',
    'tempêtait',
    'tempêtant',
    'tempêtent',
    'tempêtera',
    'tempêtiez',
    'tempêtons',
    'templiers',
    'temporale',
    'temporaux',
    'temporels',
    'temporisa',
    'temporise',
    'temporisé',
    'ténacités',
    'tenaillai',
    'tenaillas',
    'tenaillât',
    'tenaillée',
    'tenailler',
    'tenailles',
    'tenaillés',
    'tenaillez',
    'tenancier',
    'tendaient',
    'tendances',
    'tendelles',
    'tenderies',
    'tendeuses',
    'tendineux',
    'tendinite',
    'tendirent',
    'tendisses',
    'tendresse',
    'tendretés',
    'tendrions',
    'ténébreux',
    'ténébrion',
    'tènements',
    'ténifuges',
    'tennisman',
    'tennismen',
    'tenonnais',
    'tenonnait',
    'tenonnant',
    'tenonnées',
    'tenonnent',
    'tenonnera',
    'tenonniez',
    'tenonnons',
    'ténorinos',
    'ténorisai',
    'ténorisas',
    'ténorisât',
    'ténoriser',
    'ténorises',
    'ténorisez',
    'ténorites',
    'ténotomie',
    'tentacule',
    'tentaient',
    'tentantes',
    'tentasses',
    'tentateur',
    'tentation',
    'tentative',
    'tenterais',
    'tenterait',
    'tentèrent',
    'tenteriez',
    'tenterons',
    'tenteront',
    'tenthrède',
    'terçaient',
    'terçasses',
    'tercerais',
    'tercerait',
    'tercèrent',
    'terceriez',
    'tercerons',
    'terceront',
    'térébelle',
    'térébique',
    'térébrant',
    'terminais',
    'terminait',
    'terminale',
    'terminant',
    'terminaux',
    'terminées',
    'terminent',
    'terminera',
    'terminiez',
    'terminons',
    'ternaires',
    'ternirais',
    'ternirait',
    'ternirent',
    'terniriez',
    'ternirons',
    'terniront',
    'ternisses',
    'ternissez',
    'terpinéol',
    'terpinols',
    'terraient',
    'terramare',
    'terraquée',
    'terraqués',
    'terrarium',
    'terrassai',
    'terrassas',
    'terrassât',
    'terrassée',
    'terrasser',
    'terrasses',
    'terrassés',
    'terrassez',
    'terreauta',
    'terreaute',
    'terreauté',
    'terrerais',
    'terrerait',
    'terrèrent',
    'terreriez',
    'terrerons',
    'terreront',
    'terrestre',
    'terreuses',
    'terribles',
    'terricole',
    'terrienne',
    'terrifiai',
    'terrifias',
    'terrifiât',
    'terrifiée',
    'terrifier',
    'terrifies',
    'terrifiés',
    'terrifiez',
    'terrigène',
    'terrirais',
    'terrirait',
    'terrirent',
    'terririez',
    'terrirons',
    'terriront',
    'terrisses',
    'terrissez',
    'terrorisa',
    'terrorise',
    'terrorisé',
    'tersaient',
    'tersasses',
    'terserais',
    'terserait',
    'tersèrent',
    'terseriez',
    'terserons',
    'terseront',
    'tertiaire',
    'terzettos',
    'tessiture',
    'testables',
    'testacées',
    'testaient',
    'testament',
    'testasses',
    'testateur',
    'testerais',
    'testerait',
    'testèrent',
    'testeriez',
    'testerons',
    'testeront',
    'testicule',
    'tétanique',
    'tétanisai',
    'tétanisas',
    'tétanisât',
    'tétanisée',
    'tétaniser',
    'tétanises',
    'tétanisés',
    'tétanisez',
    'tétanisme',
    'tétassent',
    'tétassiez',
    'téterelle',
    'téterions',
    'tétragone',
    'tétramère',
    'tétrapode',
    'tétrarque',
    'tétrodons',
    'teuf-teuf',
    'teutonnes',
    'textuelle',
    'texturais',
    'texturait',
    'texturant',
    'texturées',
    'texturent',
    'texturera',
    'texturiez',
    'texturisa',
    'texturise',
    'texturisé',
    'texturons',
    'thalliums',
    'théâtrale',
    'théâtraux',
    'thébaïdes',
    'thébaines',
    'thébaïnes',
    'thébaïque',
    'thébaïsme',
    'thématisa',
    'thématise',
    'thématisé',
    'théodicée',
    'théogonie',
    'théologal',
    'théologie',
    'théorèmes',
    'théorique',
    'théorisai',
    'théorisas',
    'théorisât',
    'théorisée',
    'théoriser',
    'théorises',
    'théorisés',
    'théorisez',
    'théosophe',
    'thérapies',
    'thériaque',
    'théridion',
    'thermales',
    'thermidor',
    'thermique',
    'thermites',
    'thésardes',
    'thésaurus',
    'thétiques',
    'théurgies',
    'thiamines',
    'thibaudes',
    'thomistes',
    'thonaires',
    'thréonine',
    'thridaces',
    'thrillers',
    'thrombine',
    'thrombose',
    'thylacine',
    'thymiques',
    'thyratron',
    'thyristor',
    'thyroïdes',
    'thyroxine',
    'tibétaine',
    'tibétains',
    'tictaquai',
    'tictaquas',
    'tictaquât',
    'tictaquer',
    'tictaques',
    'tictaquez',
    'tiédasses',
    'tièdement',
    'tiédirais',
    'tiédirait',
    'tiédirent',
    'tiédiriez',
    'tiédirons',
    'tiédiront',
    'tiédisses',
    'tiédissez',
    'tiendrais',
    'tiendrait',
    'tiendriez',
    'tiendrons',
    'tiendront',
    'tierçâmes',
    'tierçasse',
    'tierçâtes',
    'tiercelet',
    'tiercerai',
    'tierceras',
    'tiercerez',
    'tierceron',
    'tiercions',
    'tignasses',
    'tigraient',
    'tigrasses',
    'tigrerais',
    'tigrerait',
    'tigrèrent',
    'tigreriez',
    'tigrerons',
    'tigreront',
    'tigresses',
    'tigridies',
    'tillaient',
    'tillasses',
    'tillerais',
    'tillerait',
    'tillèrent',
    'tilleriez',
    'tillerons',
    'tilleront',
    'timbalier',
    'timbrages',
    'timbrâmes',
    'timbrasse',
    'timbrâtes',
    'timbrerai',
    'timbreras',
    'timbrerez',
    'timbrions',
    'timidités',
    'timonerie',
    'timonière',
    'timoniers',
    'tinssions',
    'tintaient',
    'tintantes',
    'tintasses',
    'tintement',
    'tinterais',
    'tinterait',
    'tintèrent',
    'tinteriez',
    'tinterons',
    'tinteront',
    'tintouins',
    'tiquaient',
    'tiquasses',
    'tiquerais',
    'tiquerait',
    'tiquèrent',
    'tiqueriez',
    'tiquerons',
    'tiqueront',
    'tiquetées',
    'tiqueture',
    'tiqueuses',
    'tiraillai',
    'tiraillas',
    'tiraillât',
    'tiraillée',
    'tirailler',
    'tirailles',
    'tiraillés',
    'tiraillez',
    'tirassent',
    'tirassiez',
    'tire-clou',
    'tire-fond',
    'tire-lait',
    'tirelires',
    'tire-pied',
    'tirerions',
    'tire-sous',
    'tiretaine',
    'tisassent',
    'tisassiez',
    'tiserions',
    'tisonnais',
    'tisonnait',
    'tisonnant',
    'tisonnées',
    'tisonnent',
    'tisonnera',
    'tisonnier',
    'tisonniez',
    'tisonnons',
    'tissaient',
    'tissasses',
    'tisserais',
    'tisserait',
    'tisserand',
    'tissèrent',
    'tisseriez',
    'tisserins',
    'tisserons',
    'tisseront',
    'tisseuses',
    'titanique',
    'titillais',
    'titillait',
    'titillant',
    'titillées',
    'titillent',
    'titillera',
    'titilliez',
    'titillons',
    'titraient',
    'titrasses',
    'titrerais',
    'titrerait',
    'titrèrent',
    'titreriez',
    'titrerons',
    'titreront',
    'titubâmes',
    'titubante',
    'titubants',
    'titubasse',
    'titubâtes',
    'tituberai',
    'tituberas',
    'tituberez',
    'titubions',
    'titulaire',
    'toastâmes',
    'toastasse',
    'toastâtes',
    'toasterai',
    'toasteras',
    'toasterez',
    'toasteurs',
    'toastions',
    'toboggans',
    'togolaiss',
    'tohu-bohu',
    'toilaient',
    'toilasses',
    'toilerais',
    'toilerait',
    'toilèrent',
    'toileries',
    'toileriez',
    'toilerons',
    'toileront',
    'toilettai',
    'toilettas',
    'toilettât',
    'toilettée',
    'toiletter',
    'toilettes',
    'toilettés',
    'toilettez',
    'toisaient',
    'toisasses',
    'toiserais',
    'toiserait',
    'toisèrent',
    'toiseriez',
    'toiserons',
    'toiseront',
    'tokharien',
    'tolérable',
    'tolérâmes',
    'tolérance',
    'tolérante',
    'tolérants',
    'tolérasse',
    'tolérâtes',
    'tolérerai',
    'toléreras',
    'tolérerez',
    'tolérions',
    'toluidine',
    'tomahawks',
    'tomaisons',
    'tomassent',
    'tomassiez',
    'tombaient',
    'tombantes',
    'tombasses',
    'tombelles',
    'tomberais',
    'tomberait',
    'tombereau',
    'tombèrent',
    'tomberiez',
    'tomberons',
    'tomberont',
    'tomenteux',
    'tomerions',
    'tommettes',
    'tom-pouce',
    'tonalités',
    'tondaient',
    'tondaison',
    'tondeuses',
    'tondirent',
    'tondisses',
    'tondrions',
    'tonicités',
    'tonifiais',
    'tonifiait',
    'tonifiant',
    'tonifiées',
    'tonifient',
    'tonifiera',
    'tonifiiez',
    'tonifions',
    'tonitruai',
    'tonitruas',
    'tonitruât',
    'tonitruer',
    'tonitrues',
    'tonitruez',
    'tonnaient',
    'tonnelage',
    'tonnelets',
    'tonnelier',
    'tonnelles',
    'tonnerais',
    'tonnerait',
    'tonneriez',
    'tonnerons',
    'tonneront',
    'tonnerres',
    'tonsurais',
    'tonsurait',
    'tonsurant',
    'tonsurées',
    'tonsurent',
    'tonsurera',
    'tonsuriez',
    'tonsurons',
    'tontinais',
    'tontinait',
    'tontinant',
    'tontinées',
    'tontinent',
    'tontinera',
    'tontiniez',
    'tontinons',
    'tontisses',
    'topassent',
    'topassiez',
    'toperions',
    'topologie',
    'toponymes',
    'toponymie',
    'toquaient',
    'toquantes',
    'toquasses',
    'toquerais',
    'toquerait',
    'toquèrent',
    'toqueriez',
    'toquerons',
    'toqueront',
    'torchâmes',
    'torchasse',
    'torchâtes',
    'torcherai',
    'torcheras',
    'torchères',
    'torcherez',
    'torchions',
    'torchonna',
    'torchonne',
    'torchonné',
    'tordaient',
    'tordantes',
    'tordeuses',
    'tordirent',
    'tordisses',
    'tordrions',
    'toréadors',
    'toréaient',
    'toréasses',
    'toréerais',
    'toréerait',
    'toréèrent',
    'toréeriez',
    'toréerons',
    'toréeront',
    'torgnoles',
    'toronnais',
    'toronnait',
    'toronnant',
    'toronnent',
    'toronnera',
    'toronniez',
    'toronnons',
    'torpillai',
    'torpillas',
    'torpillât',
    'torpillée',
    'torpiller',
    'torpilles',
    'torpillés',
    'torpillez',
    'torréfiai',
    'torréfias',
    'torréfiât',
    'torréfiée',
    'torréfier',
    'torréfies',
    'torréfiés',
    'torréfiez',
    'torsadais',
    'torsadait',
    'torsadant',
    'torsadées',
    'torsadent',
    'torsadera',
    'torsadiez',
    'torsadons',
    'tortillai',
    'tortillas',
    'tortillât',
    'tortillée',
    'tortiller',
    'tortilles',
    'tortillés',
    'tortillez',
    'tortillon',
    'tortorais',
    'tortorait',
    'tortorant',
    'tortorées',
    'tortorent',
    'tortorera',
    'tortoriez',
    'tortorons',
    'tortueuse',
    'torturais',
    'torturait',
    'torturant',
    'torturées',
    'torturent',
    'torturera',
    'torturiez',
    'torturons',
    'totalisai',
    'totalisas',
    'totalisât',
    'totalisée',
    'totaliser',
    'totalises',
    'totalisés',
    'totalisez',
    'totalités',
    'totémique',
    'totémisme',
    'tôt-faits',
    'touailles',
    'touarègue',
    'touassent',
    'touassiez',
    'touchâmes',
    'touchante',
    'touchants',
    'touchasse',
    'touchâtes',
    'toucherai',
    'toucheras',
    'toucherez',
    'touchette',
    'toucheurs',
    'touchions',
    'touerions',
    'touffeurs',
    'touillage',
    'touillais',
    'touillait',
    'touillant',
    'touillées',
    'touillent',
    'touillera',
    'touilliez',
    'touillons',
    'touloupes',
    'toungouze',
    'toupillai',
    'toupillas',
    'toupillât',
    'toupillée',
    'toupiller',
    'toupilles',
    'toupillés',
    'toupillez',
    'toupillon',
    'touraille',
    'touranien',
    'tourbâmes',
    'tourbasse',
    'tourbâtes',
    'tourberai',
    'tourberas',
    'tourberez',
    'tourbeuse',
    'tourbière',
    'tourbiers',
    'tourbions',
    'tourdille',
    'tourelles',
    'tourières',
    'tourillon',
    'tourismes',
    'touristes',
    'tourmenta',
    'tourmente',
    'tourmenté',
    'tourments',
    'tournages',
    'tournâmes',
    'tournante',
    'tournants',
    'tournassa',
    'tournasse',
    'tournassé',
    'tournâtes',
    'tournedos',
    'tournerai',
    'tourneras',
    'tournerez',
    'tournesol',
    'tournette',
    'tourneurs',
    'tourneuse',
    'tournevis',
    'tournilla',
    'tournille',
    'tournillé',
    'tourniole',
    'tournions',
    'tourniqua',
    'tournique',
    'tourniqué',
    'tournisse',
    'tournoies',
    'tournoyai',
    'tournoyas',
    'tournoyât',
    'tournoyer',
    'tournoyez',
    'tournures',
    'tourteaux',
    'tourtière',
    'touselles',
    'Toussaint',
    'toussâmes',
    'toussasse',
    'toussâtes',
    'tousserai',
    'tousseras',
    'tousserez',
    'tousserie',
    'tousseurs',
    'tousseuse',
    'toussions',
    'toussotai',
    'toussotas',
    'toussotât',
    'toussoter',
    'toussotes',
    'toussotez',
    'toutefois',
    'tout-fait',
    'tout-fous',
    'toxicités',
    'toxicoses',
    'traboulai',
    'traboulas',
    'traboulât',
    'trabouler',
    'traboules',
    'traboulez',
    'traçaient',
    'tracanais',
    'tracanait',
    'tracanant',
    'tracanées',
    'tracanent',
    'tracanera',
    'tracaniez',
    'tracanons',
    'tracassai',
    'tracassas',
    'tracassât',
    'tracassée',
    'tracasser',
    'tracasses',
    'tracassés',
    'traçasses',
    'tracassez',
    'tracassin',
    'tracement',
    'tracerais',
    'tracerait',
    'tracèrent',
    'tracerets',
    'traceriez',
    'tracerons',
    'traceront',
    'traceuses',
    'trachéale',
    'trachéaux',
    'trachéens',
    'trachéide',
    'trachéite',
    'trachomes',
    'trachytes',
    'tractable',
    'tractâmes',
    'tractasse',
    'tractâtes',
    'tracterai',
    'tracteras',
    'tracterez',
    'tracteurs',
    'tractions',
    'tractives',
    'tractrice',
    'traditeur',
    'tradition',
    'traduirai',
    'traduiras',
    'traduirez',
    'traduises',
    'traduisez',
    'traduisis',
    'traduisit',
    'traduisît',
    'traduites',
    'traficota',
    'traficote',
    'traficoté',
    'trafiquai',
    'trafiquas',
    'trafiquée',
    'trafiquer',
    'trafiques',
    'trafiqués',
    'trafiquez',
    'tragédien',
    'tragédies',
    'tragiques',
    'trahirais',
    'trahirait',
    'trahirent',
    'trahiriez',
    'trahirons',
    'trahiront',
    'trahisons',
    'trahisses',
    'trahissez',
    'traînages',
    'traînâmes',
    'traînante',
    'traînants',
    'traînarde',
    'traînards',
    'traînassa',
    'traînasse',
    'traînassé',
    'traînâtes',
    'traîneaux',
    'traînerai',
    'traîneras',
    'traînerez',
    'traîneurs',
    'traîneuse',
    'trainglot',
    'trainings',
    'traînions',
    'trairions',
    'traitable',
    'traitâmes',
    'traitante',
    'traitants',
    'traitasse',
    'traitâtes',
    'traiterai',
    'traiteras',
    'traiterez',
    'traiteurs',
    'traitions',
    'traîtrise',
    'tramaient',
    'tramasses',
    'tramerais',
    'tramerait',
    'tramèrent',
    'trameriez',
    'tramerons',
    'trameront',
    'traminots',
    'tranchage',
    'tranchais',
    'tranchait',
    'tranchant',
    'tranchées',
    'tranchent',
    'tranchera',
    'tranchets',
    'trancheur',
    'tranchiez',
    'tranchoir',
    'tranchons',
    'transcoda',
    'transcode',
    'transcodé',
    'transcris',
    'transcrit',
    'transepts',
    'transféra',
    'transféré',
    'transfère',
    'transfert',
    'transfila',
    'transfile',
    'transfilé',
    'transfini',
    'transfuge',
    'transfusa',
    'transfuse',
    'transfusé',
    'transhuma',
    'transhume',
    'transhumé',
    'transigea',
    'transiger',
    'transiges',
    'transigez',
    'transîmes',
    'transirai',
    'transiras',
    'transirez',
    'transisse',
    'transitai',
    'transitas',
    'transitât',
    'transitée',
    'transiter',
    'transites',
    'transités',
    'transîtes',
    'transitez',
    'transitif',
    'translata',
    'translate',
    'translaté',
    'transmets',
    'transmise',
    'transmuai',
    'transmuas',
    'transmuât',
    'transmuée',
    'transmuer',
    'transmues',
    'transmués',
    'transmuez',
    'transmuta',
    'transmute',
    'transmuté',
    'transparu',
    'transpira',
    'transpire',
    'transpiré',
    'transport',
    'transposa',
    'transpose',
    'transposé',
    'transsuda',
    'transsude',
    'transsudé',
    'transvasa',
    'transvase',
    'transvasé',
    'transvida',
    'transvide',
    'transvidé',
    'trantrans',
    'trappeurs',
    'trappiste',
    'traquâmes',
    'traquasse',
    'traquâtes',
    'traquerai',
    'traqueras',
    'traquerez',
    'traqueurs',
    'traqueuse',
    'traquions',
    'trattoria',
    'travailla',
    'travaille',
    'travaillé',
    'travelage',
    'traveling',
    'traversai',
    'traversas',
    'traversât',
    'traversée',
    'traverser',
    'traverses',
    'traversés',
    'traversez',
    'traversin',
    'travestie',
    'travestir',
    'travestis',
    'travestit',
    'travestît',
    'trayaient',
    'trayeuses',
    'trébuchai',
    'trébuchas',
    'trébuchât',
    'trébuchée',
    'trébucher',
    'trébuches',
    'trébuchés',
    'trébuchet',
    'trébuchez',
    'tréfilage',
    'tréfilais',
    'tréfilait',
    'tréfilant',
    'tréfilées',
    'tréfilent',
    'tréfilera',
    'tréfileur',
    'tréfiliez',
    'tréfilons',
    'tréflière',
    'tréhalose',
    'treillage',
    'treillagé',
    'treizième',
    'treiziste',
    'trekkings',
    'trématage',
    'trématais',
    'trématait',
    'trématant',
    'trématent',
    'trématera',
    'trématiez',
    'trématons',
    'tremblais',
    'tremblait',
    'tremblant',
    'tremblées',
    'tremblent',
    'tremblera',
    'trembleur',
    'trembliez',
    'tremblons',
    'tremblota',
    'tremblote',
    'trembloté',
    'trémelles',
    'trémières',
    'trémoussa',
    'trémousse',
    'trémoussé',
    'trempages',
    'trempâmes',
    'trempasse',
    'trempâtes',
    'tremperai',
    'tremperas',
    'tremperez',
    'trempette',
    'trempions',
    'tremplins',
    'trémulais',
    'trémulait',
    'trémulant',
    'trémulées',
    'trémulent',
    'trémulera',
    'trémuliez',
    'trémulons',
    'trentaine',
    'trentains',
    'trentième',
    'trépanais',
    'trépanait',
    'trépanant',
    'trépanées',
    'trépanent',
    'trépanera',
    'trépaniez',
    'trépanons',
    'trépassai',
    'trépassas',
    'trépassée',
    'trépasser',
    'trépasses',
    'trépassés',
    'trépassez',
    'tréphones',
    'trépidais',
    'trépidait',
    'trépidant',
    'trépidées',
    'trépident',
    'trépidera',
    'trépidiez',
    'trépidons',
    'trépignai',
    'trépignas',
    'trépignât',
    'trépignée',
    'trépigner',
    'trépignes',
    'trépignés',
    'trépignez',
    'trépointe',
    'tréponème',
    'trésaille',
    'trescheur',
    'trésorier',
    'tressages',
    'tressâmes',
    'tressasse',
    'tressâtes',
    'tressauta',
    'tressaute',
    'tressauté',
    'tresserai',
    'tresseras',
    'tresserez',
    'tressions',
    'treuillai',
    'treuillas',
    'treuillât',
    'treuillée',
    'treuiller',
    'treuilles',
    'treuillés',
    'treuillez',
    'trévirais',
    'trévirait',
    'trévirant',
    'trévirées',
    'trévirent',
    'trévirera',
    'tréviriez',
    'trévirons',
    'triacides',
    'trialcool',
    'triandrie',
    'triangles',
    'triangula',
    'triangule',
    'triangulé',
    'triasique',
    'triassent',
    'triassiez',
    'triathlon',
    'triballai',
    'triballas',
    'triballât',
    'triballée',
    'triballer',
    'triballes',
    'triballés',
    'triballez',
    'triboulet',
    'tribunats',
    'tribunaux',
    'tricennal',
    'trichâmes',
    'trichasse',
    'trichâtes',
    'tricherai',
    'tricheras',
    'tricherez',
    'tricherie',
    'tricheurs',
    'tricheuse',
    'trichinée',
    'trichinés',
    'trichions',
    'trichites',
    'trichomas',
    'trichomes',
    'trichrome',
    'tricoises',
    'tricolore',
    'tricornes',
    'tricotage',
    'tricotais',
    'tricotait',
    'tricotant',
    'tricotées',
    'tricotent',
    'tricotera',
    'tricotets',
    'tricoteur',
    'tricotiez',
    'tricotons',
    'tricounis',
    'trictracs',
    'tricycles',
    'tridacnes',
    'tridentée',
    'tridentés',
    'triennale',
    'triennaux',
    'trierions',
    'triforium',
    'trigéminé',
    'triglyphs',
    'trijumeau',
    'trilingue',
    'trilitère',
    'trillâmes',
    'trillasse',
    'trillâtes',
    'trillerai',
    'trilleras',
    'trillerez',
    'trillions',
    'trilobées',
    'trilogies',
    'trimaient',
    'trimarans',
    'trimardai',
    'trimardas',
    'trimardât',
    'trimarder',
    'trimardes',
    'trimardez',
    'trimasses',
    'trimbalai',
    'trimbalas',
    'trimbalât',
    'trimbalée',
    'trimbaler',
    'trimbales',
    'trimbalés',
    'trimbalez',
    'trimballa',
    'trimballe',
    'trimballé',
    'trimerais',
    'trimerait',
    'trimèrent',
    'trimeriez',
    'trimerons',
    'trimeront',
    'trimestre',
    'trimètres',
    'trimoteur',
    'trinervée',
    'trinervés',
    'tringlais',
    'tringlait',
    'tringlant',
    'tringlées',
    'tringlent',
    'tringlera',
    'tringliez',
    'tringlons',
    'tringlots',
    'trinquais',
    'trinquait',
    'trinquant',
    'trinquées',
    'trinquent',
    'trinquera',
    'trinqueur',
    'trinquiez',
    'trinquons',
    'triomphai',
    'triomphal',
    'triomphas',
    'triomphât',
    'triompher',
    'triomphes',
    'triomphez',
    'tripaille',
    'tripartie',
    'tripartis',
    'triperies',
    'tripettes',
    'triphasée',
    'triphasés',
    'tripières',
    'triplaces',
    'triplâmes',
    'triplasse',
    'triplâtes',
    'triplerai',
    'tripleras',
    'triplerez',
    'triplette',
    'triplions',
    'triploïde',
    'triplures',
    'tripodies',
    'tripotage',
    'tripotais',
    'tripotait',
    'tripotant',
    'tripotées',
    'tripotent',
    'tripotera',
    'tripoteur',
    'tripotiez',
    'tripotons',
    'triptyque',
    'triquâmes',
    'triquasse',
    'triquâtes',
    'triquerai',
    'triqueras',
    'triquerez',
    'triquions',
    'trirègnes',
    'triséquai',
    'triséquas',
    'triséquât',
    'triséquée',
    'triséquer',
    'triséqués',
    'trisèques',
    'triséquez',
    'trisomies',
    'trissâmes',
    'trissasse',
    'trissâtes',
    'trisserai',
    'trisseras',
    'trisserez',
    'trissions',
    'tristesse',
    'triticale',
    'triturais',
    'triturait',
    'triturant',
    'triturées',
    'triturent',
    'triturera',
    'trituriez',
    'triturons',
    'triumvirs',
    'trivalent',
    'trivalves',
    'triviales',
    'trochiles',
    'trochiter',
    'trochlées',
    'trochures',
    'troglobie',
    'troisième',
    'trois-six',
    'trombines',
    'tromblons',
    'trombones',
    'trompâmes',
    'trompasse',
    'trompâtes',
    'tromperai',
    'tromperas',
    'tromperez',
    'tromperie',
    'trompetai',
    'trompetas',
    'trompetât',
    'trompetée',
    'trompeter',
    'trompetés',
    'trompetez',
    'trompette',
    'trompeurs',
    'trompeuse',
    'trompions',
    'trônaient',
    'trônasses',
    'tronchets',
    'tronçonna',
    'tronçonne',
    'tronçonné',
    'trônerais',
    'trônerait',
    'trônèrent',
    'trôneriez',
    'trônerons',
    'trôneront',
    'tronquais',
    'tronquait',
    'tronquant',
    'tronquées',
    'tronquent',
    'tronquera',
    'tronquiez',
    'tronquons',
    'trophique',
    'tropicale',
    'tropicaux',
    'tropiques',
    'tropismes',
    'troquâmes',
    'troquasse',
    'troquâtes',
    'troquerai',
    'troqueras',
    'troquerez',
    'troqueurs',
    'troqueuse',
    'troquions',
    'trottâmes',
    'trottante',
    'trottants',
    'trottasse',
    'trottâtes',
    'trotterai',
    'trotteras',
    'trotterez',
    'trotteurs',
    'trotteuse',
    'trottinai',
    'trottinas',
    'trottinât',
    'trottiner',
    'trottines',
    'trottinez',
    'trottions',
    'trottoirs',
    'trouaient',
    'trouasses',
    'troubades',
    'troublais',
    'troublait',
    'troublant',
    'troubleau',
    'troublées',
    'troublent',
    'troublera',
    'troubliez',
    'troublons',
    'trouerais',
    'trouerait',
    'trouèrent',
    'troueriez',
    'trouerons',
    'troueront',
    'troufions',
    'trouilles',
    'troupeaux',
    'troupiale',
    'troupiers',
    'troussage',
    'troussais',
    'troussait',
    'troussant',
    'trousseau',
    'troussées',
    'troussent',
    'troussera',
    'trousseur',
    'troussiez',
    'troussons',
    'trou-trou',
    'trouvable',
    'trouvâmes',
    'trouvasse',
    'trouvâtes',
    'trouverai',
    'trouveras',
    'trouvères',
    'trouverez',
    'trouveurs',
    'trouveuse',
    'trouvions',
    'troyennes',
    'truandais',
    'truandait',
    'truandant',
    'truandées',
    'truandent',
    'truandera',
    'truandiez',
    'truandons',
    'trublions',
    'trucidais',
    'trucidait',
    'trucidant',
    'trucidées',
    'trucident',
    'trucidera',
    'trucidiez',
    'trucidons',
    'trucmuche',
    'truculent',
    'trudgeons',
    'truellées',
    'truffâmes',
    'truffasse',
    'truffâtes',
    'trufferai',
    'trufferas',
    'trufferez',
    'truffière',
    'truffiers',
    'truffions',
    'truquages',
    'truquâmes',
    'truquasse',
    'truquâtes',
    'truquerai',
    'truqueras',
    'truquerez',
    'truqueurs',
    'truqueuse',
    'truquions',
    'truquiste',
    'trusquina',
    'trusquine',
    'trusquiné',
    'trusquins',
    'trustâmes',
    'trustasse',
    'trustâtes',
    'trusterai',
    'trusteras',
    'trusterez',
    'trusteurs',
    'trustions',
    'trypsines',
    'tsarismes',
    'tsaristes',
    'tuassions',
    'tubassent',
    'tubassiez',
    'tubéracée',
    'tubéracés',
    'tubercule',
    'tubéreuse',
    'tuberions',
    'tubérisée',
    'tubérisés',
    'tubicoles',
    'tubipores',
    'tubulaire',
    'tubuleuse',
    'tubulures',
    'tudesques',
    'tue-chien',
    'tueraient',
    'tuilaient',
    'tuilasses',
    'tuilerais',
    'tuilerait',
    'tuilèrent',
    'tuileries',
    'tuileriez',
    'tuilerons',
    'tuileront',
    'tuilettes',
    'tuilières',
    'tularémie',
    'tulipiers',
    'tulleries',
    'tullières',
    'tullistes',
    'tuméfiais',
    'tuméfiait',
    'tuméfiant',
    'tuméfiées',
    'tuméfient',
    'tuméfiera',
    'tuméfiiez',
    'tuméfions',
    'tumescent',
    'tumorales',
    'tumulaire',
    'tungstate',
    'tungstène',
    'tunicelle',
    'tuniquées',
    'tunisiens',
    'tunnelier',
    'turbidité',
    'turbinage',
    'turbinais',
    'turbinait',
    'turbinant',
    'turbinées',
    'turbinent',
    'turbinera',
    'turbiniez',
    'turbinons',
    'turbotins',
    'turbulent',
    'turciques',
    'turfistes',
    'turlupina',
    'turlupine',
    'turlupiné',
    'turluttes',
    'turlututu',
    'turoniens',
    'turpitude',
    'turquerie',
    'turquette',
    'turquoise',
    'turriculé',
    'tussilage',
    'tutélaire',
    'tuteurage',
    'tuteurais',
    'tuteurait',
    'tuteurant',
    'tuteurées',
    'tuteurent',
    'tuteurera',
    'tuteuriez',
    'tuteurons',
    'tutoierai',
    'tutoieras',
    'tutoierez',
    'tutoyâmes',
    'tutoyasse',
    'tutoyâtes',
    'tutoyeurs',
    'tutoyeuse',
    'tutoyions',
    'tuyautage',
    'tuyautais',
    'tuyautait',
    'tuyautant',
    'tuyautées',
    'tuyautent',
    'tuyautera',
    'tuyauteur',
    'tuyautiez',
    'tuyautons',
    'twin-sets',
    'twistâmes',
    'twistasse',
    'twistâtes',
    'twisterai',
    'twisteras',
    'twisterez',
    'twistions',
    'tylenchus',
    'tympanaux',
    'tympanisa',
    'tympanise',
    'tympanisé',
    'tympanons',
    'typassent',
    'typassiez',
    'typerions',
    'typhiques',
    'typhlites',
    'typhoïdes',
    'typisâmes',
    'typisasse',
    'typisâtes',
    'typiserai',
    'typiseras',
    'typiserez',
    'typisions',
    'typologie',
    'typomètre',
    'tyranneau',
    'tyrannies',
    'tyrannisa',
    'tyrannise',
    'tyrannisé',
    'tyroliens',
    'tyrosines',
    'ubicuités',
    'ubiquités',
    'ubuesques',
    'ukrainien',
    'ulcérâmes',
    'ulcérasse',
    'ulcérâtes',
    'ulcératif',
    'ulcérerai',
    'ulcéreras',
    'ulcérerez',
    'ulcéreuse',
    'ulcérions',
    'ulcéroïde',
    'ultérieur',
    'ultimatum',
    'ultra-son',
    'ultrasons',
    'ululaient',
    'ululasses',
    'ululement',
    'ululerais',
    'ululerait',
    'ululèrent',
    'ululeriez',
    'ululerons',
    'ululeront',
    'unanimité',
    'unciforme',
    'unguéales',
    'unguifère',
    'unicaules',
    'unicolore',
    'unicornes',
    'unifiâmes',
    'unifiasse',
    'unifiâtes',
    'unifierai',
    'unifieras',
    'unifierez',
    'unifiions',
    'uniflores',
    'unifoliée',
    'unifoliés',
    'uniformes',
    'unilingue',
    'unilobées',
    'uniraient',
    'unisexuée',
    'unisexués',
    'unissions',
    'unitaires',
    'unitarien',
    'univalves',
    'universel',
    'univocité',
    'univoques',
    'uppercuts',
    'uranifère',
    'uraninite',
    'uraniques',
    'uranismes',
    'urbanisai',
    'urbanisas',
    'urbanisât',
    'urbanisée',
    'urbaniser',
    'urbanises',
    'urbanisés',
    'urbanisez',
    'urbanisme',
    'urbaniste',
    'urbanités',
    'urcéolées',
    'urémiques',
    'urétérale',
    'urétéraux',
    'urétérite',
    'uréthanne',
    'urétrales',
    'urétrites',
    'uricémies',
    'urinaient',
    'urinaires',
    'urinasses',
    'urinerais',
    'urinerait',
    'urinèrent',
    'urineriez',
    'urinerons',
    'urineront',
    'urineuses',
    'urinifère',
    'urobiline',
    'urochrome',
    'urolagnie',
    'urologies',
    'urologues',
    'uromètres',
    'uropygial',
    'uropygien',
    'ursulines',
    'urticaire',
    'urticante',
    'urticants',
    'uruguayen',
    'usassions',
    'useraient',
    'usinaient',
    'usinasses',
    'usinerais',
    'usinerait',
    'usinèrent',
    'usineriez',
    'usinerons',
    'usineront',
    'usinières',
    'ustensile',
    'usucapion',
    'usufruits',
    'usuraires',
    'usurières',
    'usurpâmes',
    'usurpasse',
    'usurpâtes',
    'usurperai',
    'usurperas',
    'usurperez',
    'usurpions',
    'utilement',
    'utilisais',
    'utilisait',
    'utilisant',
    'utilisées',
    'utilisent',
    'utilisera',
    'utilisiez',
    'utilisons',
    'utopiques',
    'utopistes',
    'utricules',
    'uvulaires',
    'vacancier',
    'vacataire',
    'vacations',
    'vaccaires',
    'vaccinais',
    'vaccinait',
    'vaccinale',
    'vaccinant',
    'vaccinaux',
    'vaccinées',
    'vaccinent',
    'vaccinera',
    'vaccinide',
    'vaccinier',
    'vacciniez',
    'vaccinons',
    'vachardes',
    'vachement',
    'vacheries',
    'vacherins',
    'vachettes',
    'vacillais',
    'vacillait',
    'vacillant',
    'vacillent',
    'vacillera',
    'vacilliez',
    'vacillons',
    'vacuolisa',
    'vacuolise',
    'vacuolisé',
    'vagabonda',
    'vagabonde',
    'vagabondé',
    'vagabonds',
    'vaginales',
    'vaginisme',
    'vaginites',
    'vagirions',
    'vagissais',
    'vagissait',
    'vagissant',
    'vagissent',
    'vagissiez',
    'vagissons',
    'vagotonie',
    'vaguaient',
    'vaguasses',
    'vaguement',
    'vaguerais',
    'vaguerait',
    'vaguèrent',
    'vagueriez',
    'vaguerons',
    'vagueront',
    'vaigrages',
    'vaillance',
    'vaillante',
    'vaillants',
    'vaincrais',
    'vaincrait',
    'vaincriez',
    'vaincrons',
    'vaincront',
    'vainement',
    'vainquais',
    'vainquait',
    'vainquant',
    'vainquent',
    'vainqueur',
    'vainquiez',
    'vainquons',
    'vaironnai',
    'vaironnas',
    'vaironnât',
    'vaironner',
    'vaironnes',
    'vaironnez',
    'vaisseaux',
    'vaisselle',
    'valdingua',
    'valdingue',
    'valdingué',
    'valériane',
    'valetâmes',
    'valetasse',
    'valetâtes',
    'valeterai',
    'valeteras',
    'valeterez',
    'valetions',
    'valettent',
    'valeureux',
    'validâmes',
    'validasse',
    'validâtes',
    'validerai',
    'valideras',
    'validerez',
    'validions',
    'validités',
    'valisâmes',
    'valisasse',
    'valisâtes',
    'valiserai',
    'valiseras',
    'valiserez',
    'valisions',
    'valkyries',
    'valleuses',
    'vallonnai',
    'vallonnas',
    'vallonnât',
    'vallonnée',
    'vallonner',
    'vallonnes',
    'vallonnés',
    'vallonnez',
    'valorisai',
    'valorisas',
    'valorisât',
    'valorisée',
    'valoriser',
    'valorises',
    'valorisés',
    'valorisez',
    'valousais',
    'valousait',
    'valousant',
    'valousées',
    'valousent',
    'valousera',
    'valousiez',
    'valousons',
    'valsaient',
    'valsasses',
    'valserais',
    'valserait',
    'valsèrent',
    'valseriez',
    'valserons',
    'valseront',
    'valseuses',
    'valussent',
    'valussiez',
    'valvaires',
    'vampaient',
    'vampasses',
    'vamperais',
    'vamperait',
    'vampèrent',
    'vamperiez',
    'vamperons',
    'vamperont',
    'vampirisa',
    'vampirise',
    'vampirisé',
    'vanadique',
    'vanadiums',
    'vandalisa',
    'vandalise',
    'vandalisé',
    'vandoises',
    'vanillées',
    'vanillier',
    'vanilline',
    'vanillons',
    'vaniteuse',
    'vannaient',
    'vannasses',
    'vannelles',
    'vannerais',
    'vannerait',
    'vannèrent',
    'vanneries',
    'vanneriez',
    'vannerons',
    'vanneront',
    'vanneuses',
    'vannières',
    'vantaient',
    'vantardes',
    'vantasses',
    'vanterais',
    'vanterait',
    'vantèrent',
    'vanteries',
    'vanteriez',
    'vanterons',
    'vanteront',
    'vaporeuse',
    'vaporisai',
    'vaporisas',
    'vaporisât',
    'vaporisée',
    'vaporiser',
    'vaporises',
    'vaporisés',
    'vaporisez',
    'vaquaient',
    'vaquasses',
    'vaquerais',
    'vaquerait',
    'vaquèrent',
    'vaqueriez',
    'vaquerons',
    'vaqueront',
    'varaignes',
    'varangues',
    'varappais',
    'varappait',
    'varappant',
    'varappent',
    'varappera',
    'varappeur',
    'varappiez',
    'varappons',
    'varheures',
    'variables',
    'variaient',
    'variances',
    'variantes',
    'variasses',
    'variateur',
    'variation',
    'varicelle',
    'varierais',
    'varierait',
    'varièrent',
    'varieriez',
    'varierons',
    'varieront',
    'variétale',
    'variétaux',
    'variolées',
    'varioleux',
    'variqueux',
    'varlopais',
    'varlopait',
    'varlopant',
    'varlopées',
    'varlopent',
    'varlopera',
    'varlopiez',
    'varlopons',
    'vaselinai',
    'vaselinas',
    'vaselinât',
    'vaselinée',
    'vaseliner',
    'vaselines',
    'vaselinés',
    'vaselinez',
    'vasouilla',
    'vasouille',
    'vasouillé',
    'vassalisa',
    'vassalise',
    'vassalisé',
    'vassalité',
    'vassiveau',
    'vastement',
    'vaticanes',
    'vaticinai',
    'vaticinas',
    'vaticinât',
    'vaticiner',
    'vaticines',
    'vaticinez',
    'vauchérie',
    'vaudoises',
    'vaudrions',
    'vaurienne',
    'vautrâmes',
    'vautrasse',
    'vautrâtes',
    'vautrerai',
    'vautreras',
    'vautrerez',
    'vautrions',
    'vavasseur',
    'vectoriel',
    'vécussent',
    'vécussiez',
    'végétales',
    'végétâmes',
    'végétasse',
    'végétâtes',
    'végétatif',
    'végéterai',
    'végéteras',
    'végéterez',
    'végétions',
    'véhémence',
    'véhémente',
    'véhéments',
    'véhiculai',
    'véhiculas',
    'véhiculât',
    'véhiculée',
    'véhiculer',
    'véhicules',
    'véhiculés',
    'véhiculez',
    'veillâmes',
    'veillasse',
    'veillâtes',
    'veillerai',
    'veilleras',
    'veillerez',
    'veilleurs',
    'veilleuse',
    'veillions',
    'veinaient',
    'veinardes',
    'veinasses',
    'veinerais',
    'veinerait',
    'veinèrent',
    'veineriez',
    'veinerons',
    'veineront',
    'veinettes',
    'veineuses',
    'vélarisai',
    'vélarisas',
    'vélarisât',
    'vélarisée',
    'vélariser',
    'vélarises',
    'vélarisés',
    'vélarisez',
    'vêlassent',
    'vêlassiez',
    'vêlements',
    'vêlerions',
    'vélivoles',
    'velléités',
    'vélocités',
    'vélodrome',
    'veloutais',
    'veloutait',
    'veloutant',
    'veloutées',
    'veloutent',
    'veloutera',
    'velouteux',
    'veloutier',
    'veloutiez',
    'veloutine',
    'veloutons',
    'venaisons',
    'vénalités',
    'vendables',
    'vendaient',
    'vendangea',
    'vendangée',
    'vendanger',
    'vendanges',
    'vendangés',
    'vendangez',
    'vendéenne',
    'vendettas',
    'vendeuses',
    'vendirent',
    'vendisses',
    'vendredis',
    'vendrions',
    'vénéneuse',
    'vénérable',
    'vénérâmes',
    'vénérasse',
    'vénérâtes',
    'vénérerai',
    'vénéreras',
    'vénérerez',
    'vénériens',
    'vénérions',
    'vengeâmes',
    'vengeance',
    'vengeante',
    'vengeants',
    'vengeasse',
    'vengeâtes',
    'vengerais',
    'vengerait',
    'vengèrent',
    'vengeriez',
    'vengerons',
    'vengeront',
    'vénielles',
    'venimeuse',
    'vénitiens',
    'venterait',
    'venteuses',
    'ventilais',
    'ventilait',
    'ventilant',
    'ventilées',
    'ventilent',
    'ventilera',
    'ventiliez',
    'ventilons',
    'ventousai',
    'ventousas',
    'ventousât',
    'ventousée',
    'ventouser',
    'ventouses',
    'ventousés',
    'ventousez',
    'ventrales',
    'ventrière',
    'vénusiens',
    'véracités',
    'véraisons',
    'vératrine',
    'verbalisa',
    'verbalise',
    'verbalisé',
    'verbeuses',
    'verbiagea',
    'verbiager',
    'verbiages',
    'verbiagez',
    'verboquet',
    'verbosité',
    'verdâtres',
    'verdelets',
    'verdirais',
    'verdirait',
    'verdirent',
    'verdiriez',
    'verdirons',
    'verdiront',
    'verdisses',
    'verdissez',
    'verdoient',
    'verdoiera',
    'verdoyais',
    'verdoyait',
    'verdoyant',
    'verdoyiez',
    'verdoyons',
    'verdunisa',
    'verdunise',
    'verdunisé',
    'vérétille',
    'vergences',
    'vergeoise',
    'vergettes',
    'vergeture',
    'vergeures',
    'verglaçât',
    'verglacer',
    'vergobret',
    'vergognes',
    'véridique',
    'vérifiais',
    'vérifiait',
    'vérifiant',
    'vérifiées',
    'vérifient',
    'vérifiera',
    'vérifieur',
    'vérifiiez',
    'vérifions',
    'véritable',
    'verjutais',
    'verjutait',
    'verjutant',
    'verjutées',
    'verjutent',
    'verjutera',
    'verjutiez',
    'verjutons',
    'vermeille',
    'vermicide',
    'vermicula',
    'vermicule',
    'vermiculé',
    'vermifuge',
    'vermillai',
    'vermillas',
    'vermillât',
    'vermiller',
    'vermilles',
    'vermillez',
    'vermillon',
    'vermineux',
    'vermoulai',
    'vermoulas',
    'vermoulât',
    'vermoulée',
    'vermouler',
    'vermoules',
    'vermoulés',
    'vermoulez',
    'vermoulue',
    'vermoulus',
    'vermouths',
    'vernation',
    'vernirais',
    'vernirait',
    'vernirent',
    'verniriez',
    'vernirons',
    'verniront',
    'vernissai',
    'vernissas',
    'vernissât',
    'vernissée',
    'vernisser',
    'vernisses',
    'vernissés',
    'vernissez',
    'véronique',
    'verraient',
    'verrannes',
    'verreries',
    'verrières',
    'versaient',
    'versasses',
    'versatile',
    'versement',
    'verserais',
    'verserait',
    'versèrent',
    'verseriez',
    'verserons',
    'verseront',
    'verseuses',
    'versifiai',
    'versifias',
    'versifiât',
    'versifiée',
    'versifier',
    'versifies',
    'versifiés',
    'versifiez',
    'vertébral',
    'vertébrée',
    'vertébrés',
    'vertèbres',
    'vertement',
    'verticale',
    'verticaux',
    'vertubleu',
    'vertuchou',
    'vertueuse',
    'verveines',
    'vervelles',
    'verveuses',
    'vésicales',
    'vésicante',
    'vésicants',
    'vésicules',
    'vespérale',
    'vespéraux',
    'vessaient',
    'vessasses',
    'vesserais',
    'vesserait',
    'vessèrent',
    'vesseriez',
    'vesserons',
    'vesseront',
    'vessigons',
    'vestiaire',
    'vestibule',
    'vêtements',
    'vétillais',
    'vétillait',
    'vétillant',
    'vétillard',
    'vétillent',
    'vétillera',
    'vétilleux',
    'vétilliez',
    'vétillons',
    'vêtirions',
    'vêtissent',
    'vêtissiez',
    'veuglaire',
    'veuillent',
    'veuleries',
    'veveysans',
    'vexassent',
    'vexassiez',
    'vexateurs',
    'vexations',
    'vexatoire',
    'vexatrice',
    'vexerions',
    'viabilisa',
    'viabilise',
    'viabilisé',
    'viabilité',
    'viandâmes',
    'viandasse',
    'viandâtes',
    'vianderai',
    'vianderas',
    'vianderez',
    'viandions',
    'viatiques',
    'vibraient',
    'vibrantes',
    'vibrasses',
    'vibrateur',
    'vibratile',
    'vibration',
    'vibrerais',
    'vibrerait',
    'vibrèrent',
    'vibreriez',
    'vibrerons',
    'vibreront',
    'vibrionna',
    'vibrionne',
    'vibrionné',
    'vibrisses',
    'vicariale',
    'vicariant',
    'vicariats',
    'vicariaux',
    'vicennale',
    'vicennaux',
    'vice-rois',
    'vicésimal',
    'viciables',
    'viciaient',
    'viciasses',
    'viciateur',
    'viciation',
    'vicierais',
    'vicierait',
    'vicièrent',
    'vicieriez',
    'vicierons',
    'vicieront',
    'vicieuses',
    'vicinales',
    'vicomtale',
    'vicomtaux',
    'victoires',
    'victorias',
    'victorien',
    'vidangeai',
    'vidangeas',
    'vidangeât',
    'vidangées',
    'vidangent',
    'vidangera',
    'vidangeur',
    'vidangiez',
    'vidassent',
    'vidassiez',
    'vide-cave',
    'vidéoclip',
    'viderions',
    'vidimâmes',
    'vidimasse',
    'vidimâtes',
    'vidimerai',
    'vidimeras',
    'vidimerez',
    'vidimions',
    'vieillard',
    'vieillies',
    'vieillira',
    'vieillots',
    'viellâmes',
    'viellasse',
    'viellâtes',
    'viellerai',
    'vielleras',
    'viellerez',
    'vielleurs',
    'viellions',
    'viendrais',
    'viendrait',
    'viendriez',
    'viendrons',
    'viendront',
    'vigilance',
    'vigilante',
    'vigilants',
    'vignerons',
    'vignettes',
    'vigneture',
    'vignobles',
    'vigoureux',
    'vigueries',
    'vilipenda',
    'vilipende',
    'vilipendé',
    'villosité',
    'vinaigrai',
    'vinaigras',
    'vinaigrât',
    'vinaigrée',
    'vinaigrer',
    'vinaigres',
    'vinaigrés',
    'vinaigrez',
    'vinassent',
    'vinassiez',
    'vindictes',
    'vinerions',
    'vingtaine',
    'vingtième',
    'vingt-six',
    'vinicoles',
    'vinifères',
    'vinifiais',
    'vinifiait',
    'vinifiant',
    'vinifiées',
    'vinifient',
    'vinifiera',
    'vinifiiez',
    'vinifions',
    'vinosités',
    'vinssions',
    'vinylique',
    'vinylites',
    'violaçais',
    'violaçait',
    'violaçant',
    'violacées',
    'violacent',
    'violacera',
    'violaciez',
    'violaçons',
    'violaient',
    'violasses',
    'violateur',
    'violation',
    'violâtres',
    'violences',
    'violentai',
    'violentas',
    'violentât',
    'violentée',
    'violenter',
    'violentes',
    'violentés',
    'violentez',
    'violerais',
    'violerait',
    'violèrent',
    'violeriez',
    'violerons',
    'violeront',
    'violettes',
    'violeuses',
    'violistes',
    'violonais',
    'violonait',
    'violonant',
    'violonent',
    'violonera',
    'violoneux',
    'violoniez',
    'violonons',
    'vioquîmes',
    'vioquirai',
    'vioquiras',
    'vioquirez',
    'vioquisse',
    'vioquîtes',
    'vipéreaux',
    'vipérines',
    'virassent',
    'virassiez',
    'virements',
    'virerions',
    'virevolta',
    'virevolte',
    'virevolté',
    'virginale',
    'virginaux',
    'virginies',
    'virginité',
    'virgulais',
    'virgulait',
    'virgulant',
    'virgulées',
    'virgulent',
    'virgulera',
    'virguliez',
    'virgulons',
    'virilisai',
    'virilisas',
    'virilisât',
    'virilisée',
    'viriliser',
    'virilises',
    'virilisés',
    'virilisez',
    'virilisme',
    'virilités',
    'virilocal',
    'virolages',
    'virolâmes',
    'virolasse',
    'virolâtes',
    'virolerai',
    'viroleras',
    'virolerez',
    'viroliers',
    'virolions',
    'virologie',
    'virologue',
    'virtuelle',
    'virtuoses',
    'virulence',
    'virulente',
    'virulents',
    'visagiste',
    'visassent',
    'visassiez',
    'vis-à-vis',
    'viscaches',
    'viscérale',
    'viscéraux',
    'viscosité',
    'viserions',
    'visionnai',
    'visionnas',
    'visionnât',
    'visionnée',
    'visionner',
    'visionnes',
    'visionnés',
    'visionnez',
    'visitâmes',
    'visitante',
    'visitants',
    'visitasse',
    'visitâtes',
    'visiterai',
    'visiteras',
    'visiterez',
    'visiteurs',
    'visiteuse',
    'visitions',
    'visqueuse',
    'vissaient',
    'vissasses',
    'visserais',
    'visserait',
    'vissèrent',
    'visseries',
    'visseriez',
    'visserons',
    'visseront',
    'visseuses',
    'visualisa',
    'visualise',
    'visualisé',
    'visuelles',
    'vitalisme',
    'vitaliste',
    'vitalités',
    'vitaminée',
    'vitamines',
    'vitaminés',
    'vitelline',
    'vitellins',
    'vitelotte',
    'viticoles',
    'vitiligos',
    'vitraient',
    'vitrasses',
    'vitrerais',
    'vitrerait',
    'vitrèrent',
    'vitreries',
    'vitreriez',
    'vitrerons',
    'vitreront',
    'vitreuses',
    'vitrières',
    'vitrifiai',
    'vitrifias',
    'vitrifiât',
    'vitrifiée',
    'vitrifier',
    'vitrifies',
    'vitrifiés',
    'vitrifiez',
    'vitriolai',
    'vitriolas',
    'vitriolât',
    'vitriolée',
    'vitrioler',
    'vitrioles',
    'vitriolés',
    'vitriolez',
    'vitulaire',
    'vitupérai',
    'vitupéras',
    'vitupérât',
    'vitupérée',
    'vitupérer',
    'vitupérés',
    'vitupères',
    'vitupérez',
    'vivacités',
    'vivandier',
    'vivariums',
    'vivifiais',
    'vivifiait',
    'vivifiant',
    'vivifiées',
    'vivifient',
    'vivifiera',
    'vivifiiez',
    'vivifions',
    'vivipares',
    'vivotâmes',
    'vivotasse',
    'vivotâtes',
    'vivoterai',
    'vivoteras',
    'vivoterez',
    'vivotions',
    'vivraient',
    'vivrières',
    'vocalique',
    'vocalisai',
    'vocalisas',
    'vocalisât',
    'vocalisée',
    'vocaliser',
    'vocalises',
    'vocalisés',
    'vocalisez',
    'vocalisme',
    'vocations',
    'vociférai',
    'vociféras',
    'vociférât',
    'vociférée',
    'vociférer',
    'vociférés',
    'vocifères',
    'vociférez',
    'voguaient',
    'voguasses',
    'voguerais',
    'voguerait',
    'voguèrent',
    'vogueriez',
    'voguerons',
    'vogueront',
    'voilaient',
    'voilasses',
    'voilement',
    'voilerais',
    'voilerait',
    'voilèrent',
    'voileries',
    'voileriez',
    'voilerons',
    'voileront',
    'voilettes',
    'voisinage',
    'voisinais',
    'voisinait',
    'voisinant',
    'voisinent',
    'voisinera',
    'voisiniez',
    'voisinons',
    'voiturage',
    'voiturais',
    'voiturait',
    'voiturant',
    'voiturées',
    'voiturent',
    'voiturera',
    'voiturier',
    'voituriez',
    'voiturins',
    'voiturons',
    'voïvodats',
    'voïvodies',
    'volailler',
    'volailles',
    'volassent',
    'volassiez',
    'volatiles',
    'volatisée',
    'volatiser',
    'volatisés',
    'volcanisa',
    'volcanise',
    'volcanisé',
    'volerions',
    'voletâmes',
    'voletasse',
    'voletâtes',
    'voletions',
    'volettent',
    'volettera',
    'voligeage',
    'voligeais',
    'voligeait',
    'voligeant',
    'voligeons',
    'voligerai',
    'voligeras',
    'voligerez',
    'voligions',
    'volitions',
    'volitives',
    'volleyeur',
    'voltaient',
    'voltaïque',
    'voltaires',
    'voltasses',
    'volterais',
    'volterait',
    'voltèrent',
    'volteriez',
    'volterons',
    'volteront',
    'voltigeai',
    'voltigeas',
    'voltigeât',
    'voltigent',
    'voltigera',
    'voltigeur',
    'voltigiez',
    'voltmètre',
    'volubiles',
    'volubilis',
    'volucelle',
    'volvaires',
    'vomériens',
    'vomiquier',
    'vomirions',
    'vomissais',
    'vomissait',
    'vomissant',
    'vomissent',
    'vomissiez',
    'vomissons',
    'vomissure',
    'vomitives',
    'vomitoire',
    'voracités',
    'votassent',
    'votassiez',
    'votations',
    'voterions',
    'vouassent',
    'vouassiez',
    'voudrions',
    'vouerions',
    'voulaient',
    'voulurent',
    'voulusses',
    'vous-même',
    'vousoient',
    'vousoiera',
    'vousoyais',
    'vousoyait',
    'vousoyant',
    'vousoyées',
    'vousoyiez',
    'vousoyons',
    'vousseaux',
    'voussoies',
    'voussoirs',
    'voussoyai',
    'voussoyas',
    'voussoyât',
    'voussoyée',
    'voussoyer',
    'voussoyés',
    'voussoyez',
    'voussures',
    'voûtaient',
    'voûtasses',
    'voûterais',
    'voûterait',
    'voûtèrent',
    'voûteriez',
    'voûterons',
    'voûteront',
    'vouvoient',
    'vouvoiera',
    'vouvoyais',
    'vouvoyait',
    'vouvoyant',
    'vouvoyées',
    'vouvoyiez',
    'vouvoyons',
    'voyageais',
    'voyageait',
    'voyageant',
    'voyageons',
    'voyagerai',
    'voyageras',
    'voyagerez',
    'voyageurs',
    'voyageuse',
    'voyagions',
    'vraquiers',
    'vrillages',
    'vrillâmes',
    'vrillasse',
    'vrillâtes',
    'vrillerai',
    'vrilleras',
    'vrillerez',
    'vrillette',
    'vrillions',
    'vrombîmes',
    'vrombirai',
    'vrombiras',
    'vrombirez',
    'vrombisse',
    'vrombîtes',
    'vulcanien',
    'vulcanisa',
    'vulcanise',
    'vulcanisé',
    'vulgaires',
    'vulgarisa',
    'vulgarise',
    'vulgarisé',
    'vulgarité',
    'vulnérant',
    'vulvaires',
    'wagnérien',
    'wagon-bar',
    'wagon-lit',
    'wagonnées',
    'wagonnets',
    'wagonnier',
    'walk-over',
    'walkyries',
    'wallabies',
    'wallonnes',
    'warrantai',
    'warrantas',
    'warrantât',
    'warrantée',
    'warranter',
    'warrantes',
    'warrantés',
    'warrantez',
    'wassingue',
    'watergang',
    'wattheure',
    'wattmètre',
    'week-ends',
    'whipcords',
    'wishbones',
    'wisigothe',
    'wurmienne',
    'wyandotte',
    'xanthines',
    'xanthomes',
    'xénélasie',
    'xénophile',
    'xénophobe',
    'xérophile',
    'xiphoïdes',
    'xylidines',
    'xylocopes',
    'xylophage',
    'xylophone',
    'yachtings',
    'yachtsman',
    'yachtsmen',
    'yearlings',
    'yoghourts',
    'yohimbehe',
    'yohimbine',
    'ytterbine',
    'ytterbium',
    'yttrifère',
    'yttriques',
    'zaïroises',
    'zambienne',
    'zanzibars',
    'zarzuelas',
    'zébraient',
    'zébrasses',
    'zébrerais',
    'zébrerait',
    'zébrèrent',
    'zébreriez',
    'zébrerons',
    'zébreront',
    'zélateurs',
    'zélatrice',
    'zénithale',
    'zénithaux',
    'zéolithes',
    'zéphyrien',
    'zéphyrine',
    'zeppelins',
    'zérotages',
    'zérumbets',
    'zestaient',
    'zestasses',
    'zesterais',
    'zesterait',
    'zestèrent',
    'zesteriez',
    'zesterons',
    'zesteront',
    'zététique',
    'zézaierai',
    'zézaieras',
    'zézaierez',
    'zézayâmes',
    'zézayasse',
    'zézayâtes',
    'zézayerai',
    'zézayeras',
    'zézayerez',
    'zézayions',
    'zibassent',
    'zibassiez',
    'zibelines',
    'ziberions',
    'zieutâmes',
    'zieutasse',
    'zieutâtes',
    'zieuterai',
    'zieuteras',
    'zieuterez',
    'zieutions',
    'ziggourat',
    'zigoteaux',
    'zigouilla',
    'zigouille',
    'zigouillé',
    'ziguaient',
    'ziguasses',
    'ziguerais',
    'ziguerait',
    'ziguèrent',
    'zigueriez',
    'ziguerons',
    'zigueront',
    'zigzaguai',
    'zigzaguas',
    'zigzaguât',
    'zigzaguer',
    'zigzagues',
    'zigzaguez',
    'zincifère',
    'zinguâmes',
    'zinguasse',
    'zinguâtes',
    'zinguerai',
    'zingueras',
    'zinguerez',
    'zingueurs',
    'zinguions',
    'zinzinula',
    'zinzinule',
    'zinzinulé',
    'zippaient',
    'zippasses',
    'zipperais',
    'zipperait',
    'zippèrent',
    'zipperiez',
    'zipperons',
    'zipperont',
    'zirconite',
    'zirconium',
    'zodiacale',
    'zodiacaux',
    'zodiaques',
    'zonassent',
    'zonassiez',
    'zonerions',
    'zoogamète',
    'zoolâtres',
    'zoolâtrie',
    'zoolithes',
    'zoologies',
    'zoomorphe',
    'zoophiles',
    'zoophilie',
    'zoophobie',
    'zoophores',
    'zoophytes',
    'zoospores',
    'zootaxies',
    'zostérien',
    'zozotâmes',
    'zozotasse',
    'zozotâtes',
    'zozoterai',
    'zozoteras',
    'zozoterez',
    'zozotions',
    'zucchette',
    'zuchettes',
    'zymotique'
];
