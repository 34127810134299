export default [
    'accessoirisassions',
    'accessoiriseraient',
    'acétylsalicyliques',
    'administrativement',
    'anathématisassions',
    'anathématiseraient',
    'anatomopathologies',
    'anesthésiologistes',
    'angiocardiographie',
    'anthropocentriques',
    'anthropocentrismes',
    'anthropomorphiques',
    'anthropomorphismes',
    'anti-américanismes',
    'anticonceptionnels',
    'anticonjoncturelle',
    'anticryptogamiques',
    'antigouvernemental',
    'antigravitationnel',
    'anti-impérialismes',
    'anti-impérialistes',
    'anti-inflammatoire',
    'antiparlementaires',
    'antipéristaltiques',
    'antiréglementaires',
    'anti-scientifiques',
    'approfondissements',
    'approvisionnassent',
    'approvisionnassiez',
    'approvisionnements',
    'approvisionnerions',
    'archipresbytérales',
    'architecturassions',
    'architectureraient',
    'aristocratiquement',
    'arrière-grand-mère',
    'arrière-grand-père',
    'arrière-petit-fils',
    'assurances-crédits',
    'autodéterminassiez',
    'autodéterminations',
    'autodéterminerions',
    'auto-équilibrantes',
    'auto-intoxications',
    'autosuggestionnées',
    'autosuggestionniez',
    'autosuggestionnons',
    'boustifaillassions',
    'boustifailleraient',
    'bringueballassions',
    'bringueballeraient',
    'brinqueballassions',
    'brinqueballeraient',
    'broncho-pneumonies',
    'bureaucratisassent',
    'bureaucratisassiez',
    'bureaucratisations',
    'bureaucratiserions',
    'calligraphiassions',
    'calligraphieraient',
    'cardiorespiratoire',
    'cardio-vasculaires',
    'céphalo-rachidiens',
    'chèques-restaurant',
    'chirographiassions',
    'chirographieraient',
    'christianisassions',
    'christianiseraient',
    'chromolithographie',
    'chromotypographies',
    'chronophotographie',
    'cinématographiâmes',
    'cinématographiasse',
    'cinématographiâtes',
    'cinématographierai',
    'cinématographieras',
    'cinématographierez',
    'cinématographiions',
    'cinématographiques',
    'circonscrivissions',
    'circonstanciassent',
    'circonstanciassiez',
    'circonstancierions',
    'climatopathologies',
    'coadministratrices',
    'collaborationniste',
    'collectionnassions',
    'collectionneraient',
    'collectivisassions',
    'collectiviseraient',
    'commercialisassent',
    'commercialisassiez',
    'commercialisations',
    'commercialiserions',
    'commissionnassions',
    'commissionneraient',
    'compartimentassent',
    'compartimentassiez',
    'compartimentations',
    'compartimenterions',
    'compréhensibilités',
    'comptabilisassions',
    'comptabiliseraient',
    'concentrationnaire',
    'conceptualisassent',
    'conceptualisassiez',
    'conceptualisations',
    'conceptualiserions',
    'conditionnellement',
    'confectionnassions',
    'confectionneraient',
    'confidentiellement',
    'congestionnassions',
    'congestionneraient',
    'consciencieusement',
    'constitutionnalisa',
    'constitutionnalise',
    'constitutionnalisé',
    'constitutionnalité',
    'constitutionnelles',
    'consubstantialités',
    'consubstantiations',
    'containerisassions',
    'containeriseraient',
    'conteneurisassions',
    'conteneuriseraient',
    'contorsionnassions',
    'contorsionneraient',
    'contractualisaient',
    'contractualisasses',
    'contractualiserais',
    'contractualiserait',
    'contractualisèrent',
    'contractualiseriez',
    'contractualiserons',
    'contractualiseront',
    'contradictoirement',
    'contre-attaquaient',
    'contre-attaquasses',
    'contre-attaquerais',
    'contre-attaquerait',
    'contre-attaquèrent',
    'contre-attaqueriez',
    'contre-attaquerons',
    'contre-attaqueront',
    'contrebalançassent',
    'contrebalançassiez',
    'contrebalancerions',
    'contre-espionnages',
    'contre-indications',
    'contre-indiquaient',
    'contre-indiquasses',
    'contre-indiquerais',
    'contre-indiquerait',
    'contre-indiquèrent',
    'contre-indiqueriez',
    'contre-indiquerons',
    'contre-indiqueront',
    'contre-manifestais',
    'contre-manifestait',
    'contre-manifestant',
    'contre-manifestent',
    'contre-manifestera',
    'contre-manifestiez',
    'contre-manifestons',
    'contremarquassions',
    'contremarqueraient',
    'contre-passassions',
    'contre-passeraient',
    'contre-performance',
    'contre-plaquassent',
    'contre-plaquassiez',
    'contre-plaquerions',
    'contre-préparation',
    'contre-prestations',
    'contre-propagandes',
    'contre-proposition',
    'contre-révolutions',
    'contre-scellassent',
    'contre-scellassiez',
    'contre-scellerions',
    'contre-terrorismes',
    'contre-terroristes',
    'contre-torpilleurs',
    'conventionnassions',
    'conventionneraient',
    'conversationnelles',
    'convulsionnassions',
    'convulsionneraient',
    'correctionnalisais',
    'correctionnalisait',
    'correctionnalisant',
    'correctionnalisées',
    'correctionnalisera',
    'correctionnalisiez',
    'correctionnalisons',
    'correspondancières',
    'court-circuitaient',
    'court-circuitasses',
    'court-circuiterais',
    'court-circuiterait',
    'court-circuitèrent',
    'court-circuiteriez',
    'court-circuiterons',
    'court-circuiteront',
    'dactylographiaient',
    'dactylographiasses',
    'dactylographierais',
    'dactylographierait',
    'dactylographièrent',
    'dactylographieriez',
    'dactylographierons',
    'dactylographieront',
    'débarbouillassions',
    'débarbouilleraient',
    'débroussaillassent',
    'débroussaillassiez',
    'débroussaillements',
    'débroussaillerions',
    'débureaucratisâmes',
    'débureaucratisasse',
    'débureaucratisâtes',
    'débureaucratiserai',
    'débureaucratiseras',
    'débureaucratiserez',
    'débureaucratisions',
    'décapitalisassions',
    'décapitaliseraient',
    'décapuchonnassions',
    'décapuchonneraient',
    'décentralisassions',
    'décentralisatrices',
    'décentraliseraient',
    'déchaperonnassions',
    'déchaperonneraient',
    'déchristianisaient',
    'déchristianisasses',
    'déchristianisation',
    'déchristianiserais',
    'déchristianiserait',
    'déchristianisèrent',
    'déchristianiseriez',
    'déchristianiserons',
    'déchristianiseront',
    'décléricalisassent',
    'décléricalisassiez',
    'décléricaliserions',
    'déconditionnassent',
    'déconditionnassiez',
    'déconditionnements',
    'déconditionnerions',
    'décongestionnaient',
    'décongestionnasses',
    'décongestionnerais',
    'décongestionnerait',
    'décongestionnèrent',
    'décongestionneriez',
    'décongestionnerons',
    'décongestionneront',
    'décontenançassions',
    'décontenanceraient',
    'décriminalisassent',
    'décriminalisassiez',
    'décriminaliserions',
    'déculpabilisassent',
    'déculpabilisassiez',
    'déculpabiliserions',
    'dédifférenciassent',
    'dédifférenciassiez',
    'dédifférencierions',
    'dématérialisassent',
    'dématérialisassiez',
    'dématérialisations',
    'dématérialiserions',
    'démilitarisassions',
    'démilitariseraient',
    'déminéralisassions',
    'déminéraliseraient',
    'demi-pensionnaires',
    'démocrate-chrétien',
    'démouscaillassions',
    'démouscailleraient',
    'démultiplicatrices',
    'dénationalisassent',
    'dénationalisassiez',
    'dénationalisations',
    'dénationaliserions',
    'dénaturalisassions',
    'dénaturaliseraient',
    'dénicotinisassions',
    'dénicotiniseraient',
    'dénucléarisassions',
    'dénucléariseraient',
    'départementalierai',
    'départementalieras',
    'départementalierez',
    'départementalisais',
    'départementalisait',
    'départementalisant',
    'départementalisées',
    'départementalisent',
    'départementalisiez',
    'départementalisons',
    'dépersonnalisaient',
    'dépersonnalisasses',
    'dépersonnalisation',
    'dépersonnaliserais',
    'dépersonnaliserait',
    'dépersonnalisèrent',
    'dépersonnaliseriez',
    'dépersonnaliserons',
    'dépersonnaliseront',
    'dépolymérisassions',
    'dépolymériseraient',
    'dépressurisassions',
    'dépressuriseraient',
    'déprolétarisassent',
    'déprolétarisassiez',
    'déprolétariserions',
    'désacclimatassions',
    'désacclimateraient',
    'désaccoutumassions',
    'désaccoutumeraient',
    'désaffectionnaient',
    'désaffectionnasses',
    'désaffectionnerais',
    'désaffectionnerait',
    'désaffectionnèrent',
    'désaffectionneriez',
    'désaffectionnerons',
    'désaffectionneront',
    'désambiguïsassions',
    'désambiguïseraient',
    'désapprovisionnais',
    'désapprovisionnait',
    'désapprovisionnant',
    'désapprovisionnées',
    'désapprovisionnent',
    'désapprovisionnera',
    'désapprovisionniez',
    'désapprovisionnons',
    'désavantageassions',
    'désavantageusement',
    'désembourgeoisâmes',
    'désembourgeoisasse',
    'désembourgeoisâtes',
    'désembourgeoiserai',
    'désembourgeoiseras',
    'désembourgeoiserez',
    'désembourgeoisions',
    'désembouteillaient',
    'désembouteillasses',
    'désembouteillerais',
    'désembouteillerait',
    'désembouteillèrent',
    'désembouteilleriez',
    'désembouteillerons',
    'désembouteilleront',
    'désemprisonnassent',
    'désemprisonnassiez',
    'désemprisonnerions',
    'désengourdissaient',
    'désensibilisassent',
    'désensibilisassiez',
    'désensibilisateurs',
    'désensibilisations',
    'désensibiliserions',
    'désensorcelassions',
    'désensorcellerions',
    'désentortillassent',
    'désentortillassiez',
    'désentortillerions',
    'désenveloppassions',
    'désenvelopperaient',
    'déséquilibrassions',
    'déséquilibreraient',
    'déshumidifiassions',
    'déshumidifieraient',
    'déshydrogénassions',
    'déshydrogéneraient',
    'déshypothéquassent',
    'déshypothéquassiez',
    'déshypothéquerions',
    'désillusionnassent',
    'désillusionnassiez',
    'désillusionnerions',
    'désincorporassions',
    'désincorporeraient',
    'désingularisassent',
    'désingularisassiez',
    'désingulariserions',
    'désinsectisassions',
    'désinsectiseraient',
    'désintéressassions',
    'désintéresseraient',
    'désintoxiquassions',
    'désintoxiqueraient',
    'désinvestissements',
    'désolidarisassions',
    'désolidariseraient',
    'désubjectivisaient',
    'désubjectivisasses',
    'désubjectiviserais',
    'désubjectiviserait',
    'désubjectivisèrent',
    'désubjectiviseriez',
    'désubjectiviserons',
    'désubjectiviseront',
    'désynchronisassent',
    'désynchronisassiez',
    'désynchronisations',
    'désynchroniserions',
    'déverrouillassions',
    'déverrouilleraient',
    'diagnostiquassions',
    'diagnostiqueraient',
    'dialectalisassions',
    'dialectaliseraient',
    'disproportionnâmes',
    'disproportionnasse',
    'disproportionnâtes',
    'disproportionnerai',
    'disproportionneras',
    'disproportionnerez',
    'disproportionnions',
    'dysfonctionnements',
    'échantillonnassent',
    'échantillonnassiez',
    'échantillonnerions',
    'écouvillonnassions',
    'écouvillonneraient',
    'égravillonnassions',
    'égravillonneraient',
    'électro-acoustique',
    'électrocoagulation',
    'électrodiagnostics',
    'électrodynamomètre',
    'électromagnétiques',
    'électrophysiologie',
    'électroradiologies',
    'embarbouillassions',
    'embarbouilleraient',
    'emberlificotassent',
    'emberlificotassiez',
    'emberlificoterions',
    'embourgeoisassions',
    'embourgeoiseraient',
    'embroussaillassent',
    'embroussaillassiez',
    'embroussaillerions',
    'emmouscaillassions',
    'emmouscailleraient',
    'empiriocriticismes',
    'encapuchonnassions',
    'encapuchonneraient',
    'enchaperonnassions',
    'enchaperonneraient',
    'endivisionnassions',
    'endivisionneraient',
    'enorgueillissaient',
    'enthousiasmassions',
    'enthousiasmeraient',
    'entre-déchirassiez',
    'entre-déchirerions',
    'entre-deux-guerres',
    'entre-dévorassions',
    'entre-frappassions',
    'entre-heurtassions',
    'entre-mangeassions',
    'entre-regardassiez',
    'entre-regarderions',
    'étrésillonnassions',
    'étrésillonneraient',
    'exceptionnellement',
    'experts-comptables',
    'extraordinairement',
    'extra-sensorielles',
    'extrême-orientales',
    'fonctionnarisaient',
    'fonctionnarisasses',
    'fonctionnarisation',
    'fonctionnariserais',
    'fonctionnariserait',
    'fonctionnarisèrent',
    'fonctionnariseriez',
    'fonctionnariserons',
    'fonctionnariseront',
    'Francais-GUTenberg',
    'frigorifugeassions',
    'gastro-intestinale',
    'gastro-intestinaux',
    'gouvernementalisme',
    'grammaticalisaient',
    'grammaticalisation',
    'grammaticaliserais',
    'grammaticaliserait',
    'grammaticaliseriez',
    'grammaticaliserons',
    'grammaticaliseront',
    'grand-guignolesque',
    'gynandromorphismes',
    'hauts-commissaires',
    'hispano-américaine',
    'hispano-américains',
    'histocompatibilité',
    'hommes-grenouilles',
    'homogénéifiassions',
    'homogénéifieraient',
    'hydro-électricités',
    'hypersustentateurs',
    'hypersustentations',
    'hypersustentatrice',
    'hypertrophiassions',
    'immatérialisassent',
    'immatérialisassiez',
    'immatérialiserions',
    'immunosuppresseurs',
    'imperméabilisaient',
    'imperméabilisasses',
    'imperméabilisation',
    'imperméabiliserais',
    'imperméabiliserait',
    'imperméabilisèrent',
    'imperméabiliseriez',
    'imperméabiliserons',
    'imperméabiliseront',
    'imprescriptibilité',
    'impressionnabilité',
    'impressionnassions',
    'impressionneraient',
    'inaccomplissements',
    'incommunicabilités',
    'inconditionnalités',
    'inconstitutionnels',
    'indestructibilités',
    'indestructiblement',
    'indifférenciations',
    'individualisassent',
    'individualisassiez',
    'individualisations',
    'individualiserions',
    'industrialisassent',
    'industrialisassiez',
    'industrialisations',
    'industrialiserions',
    'inintelligibilités',
    'inintelligiblement',
    'insensibilisassent',
    'insensibilisassiez',
    'insensibilisations',
    'insensibiliserions',
    'insolubilisassions',
    'insolubiliseraient',
    'institutionnalisai',
    'institutionnalisas',
    'institutionnalisât',
    'institutionnalisée',
    'institutionnaliser',
    'institutionnalises',
    'institutionnalisés',
    'institutionnalisez',
    'institutionnalisme',
    'insurrectionnelles',
    'intellectualisâmes',
    'intellectualisasse',
    'intellectualisâtes',
    'intellectualiserai',
    'intellectualiseras',
    'intellectualiserez',
    'intellectualisions',
    'intellectuellement',
    'intentionnellement',
    'interchangeabilité',
    'intercommunication',
    'intercontinentales',
    'interdépartemental',
    'interdisciplinaire',
    'interindividuelles',
    'interministérielle',
    'internationalisais',
    'internationalisait',
    'internationalisant',
    'internationalisées',
    'internationalisent',
    'internationalisera',
    'internationalisiez',
    'internationalismes',
    'internationalisons',
    'internationalistes',
    'interparlementaire',
    'interpénétrassions',
    'interprofessionnel',
    'interventionnismes',
    'interventionnistes',
    'irrespectueusement',
    'jurisprudentielles',
    'laissé-pour-compte',
    'latino-américaines',
    'lieutenant-colonel',
    'lithographiassions',
    'lithographieraient',
    'locataires-gérants',
    'macrophotographies',
    'magnéto-électrique',
    'magnétoscopassions',
    'magnétoscoperaient',
    'malencontreusement',
    'malléabilisassions',
    'malléabiliseraient',
    'manutentionnassent',
    'manutentionnassiez',
    'manutentionnerions',
    'maréchaux-ferrants',
    'marxisme-léninisme',
    'marxiste-léniniste',
    'médico-pédagogique',
    'mercantilisassions',
    'mercantiliseraient',
    'métamorphisassions',
    'métamorphiseraient',
    'métamorphosassions',
    'métamorphoseraient',
    'micro-informatique',
    'micromanipulations',
    'microphotographies',
    'mithridatisassions',
    'mithridatiseraient',
    'morphopsychologies',
    'multidimensionnels',
    'multidisciplinaire',
    'multimilliardaires',
    'multimillionnaires',
    'multiprogrammation',
    'municipalisassions',
    'municipaliseraient',
    'néo-platoniciennes',
    'neurochirurgicales',
    'neurochirurgiennes',
    'neurolinguistiques',
    'neurotransmetteurs',
    'non-contradictions',
    'non-participations',
    'non-proliférations',
    'non-remboursements',
    'non-responsabilité',
    'nord-vietnamiennes',
    'nues-propriétaires',
    'occidentalisassent',
    'occidentalisassiez',
    'occidentalisations',
    'occidentaliserions',
    'odontostomatologie',
    'omnidirectionnelle',
    'organisationnelles',
    'orthographiassions',
    'orthographieraient',
    'orthographiquement',
    'paillassonnassions',
    'paillassonneraient',
    'parcellarisassions',
    'parcellariseraient',
    'parthénogénétiques',
    'particularisassent',
    'particularisassiez',
    'particularisations',
    'particulariserions',
    'perfectionnassions',
    'perfectionneraient',
    'perméabilisassions',
    'perméabiliseraient',
    'perquisitionnaient',
    'perquisitionnasses',
    'perquisitionnerais',
    'perquisitionnerait',
    'perquisitionnèrent',
    'perquisitionneriez',
    'perquisitionnerons',
    'perquisitionneront',
    'personnalisassions',
    'personnaliseraient',
    'petites-maîtresses',
    'pharmacodépendance',
    'pharmacodynamiques',
    'pharmacogénétiques',
    'pharmacovigilances',
    'photoconducttrices',
    'photographiassions',
    'photographieraient',
    'photographiquement',
    'photolithographies',
    'photoluminescences',
    'photomacrographies',
    'photomicrographies',
    'piézo-électricités',
    'pinces-monseigneur',
    'pluridisciplinaire',
    'plus-que-parfaites',
    'portraiturerassent',
    'portraiturerassiez',
    'postsynchronisâmes',
    'postsynchronisasse',
    'postsynchronisâtes',
    'postsynchroniserai',
    'postsynchroniseras',
    'postsynchroniserez',
    'postsynchronisions',
    'potentialisassions',
    'potentialiseraient',
    'précautionnassions',
    'précautionneraient',
    'prédéterminassions',
    'prédétermineraient',
    'préférentiellement',
    'prestidigitatrices',
    'processionnassions',
    'processionneraient',
    'professionnalismes',
    'proportionnassions',
    'proportionneraient',
    'proverbialisassent',
    'proverbialisassiez',
    'proverbialiserions',
    'providentiellement',
    'pseudo-fécondation',
    'pseudomembraneuses',
    'pseudo-névroptères',
    'psychanalysassions',
    'psychanalyseraient',
    'psychiatrisassions',
    'psychiatriseraient',
    'psycho-analeptique',
    'psychoanaleptiques',
    'psychodysleptiques',
    'psycholinguistique',
    'psychophysiologies',
    'psychosensorielles',
    'psychotechnicienne',
    'quatre-vingt-douze',
    'quatre-vingt-seize',
    'quatre-vingt-trois',
    'quintessenciassent',
    'quintessenciassiez',
    'quintessencierions',
    'radical-socialisme',
    'radical-socialiste',
    'radiocommunication',
    'radiodiffusassions',
    'radiodiffuseraient',
    'radiographiassions',
    'radiographieraient',
    'radiophotographies',
    'radiotélégraphiais',
    'radiotélégraphiait',
    'radiotélégraphiant',
    'radiotélégraphiées',
    'radiotélégraphient',
    'radiotélégraphiera',
    'radiotélégraphiiez',
    'radiotélégraphions',
    'radiotélégraphique',
    'radiotélégraphiste',
    'radiotéléphonistes',
    'ragaillardissaient',
    'rapprovisionnaient',
    'rapprovisionnasses',
    'rapprovisionnerais',
    'rapprovisionnerait',
    'rapprovisionnèrent',
    'rapprovisionneriez',
    'rapprovisionnerons',
    'rapprovisionneront',
    'réapprovisionnâmes',
    'réapprovisionnasse',
    'réapprovisionnâtes',
    'réapprovisionnerai',
    'réapprovisionneras',
    'réapprovisionnerez',
    'réapprovisionnions',
    'rechristianisaient',
    'rechristianisasses',
    'rechristianiserais',
    'rechristianiserait',
    'rechristianisèrent',
    'rechristianiseriez',
    'rechristianiserons',
    'rechristianiseront',
    'reconstruisissions',
    'reconventionnelles',
    'recristallisassent',
    'recristallisassiez',
    'recristalliserions',
    'recroquevillassent',
    'recroquevillassiez',
    'recroquevillerions',
    'réenregistrassions',
    'réenregistreraient',
    'rééquilibrerassent',
    'rééquilibrerassiez',
    'réimperméabilisais',
    'réimperméabilisait',
    'réimperméabilisant',
    'réimperméabilisées',
    'réimperméabilisent',
    'réimperméabilisera',
    'réimperméabilisiez',
    'réimperméabilisons',
    'reines-marguerites',
    'réinitialisassions',
    'réinitialiseraient',
    'réinterprétassions',
    'réinterpréteraient',
    'réintroduisissions',
    'remilitarisassions',
    'remilitariseraient',
    'rempoissonnassions',
    'rempoissonneraient',
    'réordonnançassions',
    'réordonnanceraient',
    'reporter-cameraman',
    'repositionnassions',
    'repositionneraient',
    'reprographiassions',
    'reprographieraient',
    'républicanisassent',
    'républicanisassiez',
    'républicaniserions',
    'réquisitionnassent',
    'réquisitionnassiez',
    'réquisitionnerions',
    'responsabilisaient',
    'responsabilisasses',
    'responsabiliserais',
    'responsabiliserait',
    'responsabilisèrent',
    'responsabiliseriez',
    'responsabiliserons',
    'responsabiliseront',
    'resurchauffassions',
    'resurchaufferaient',
    'résurrectionnelles',
    'retranscrivissions',
    'revascularisations',
    'révolutionnarismes',
    'révolutionnaristes',
    'révolutionnassions',
    'révolutionneraient',
    'rhinopharyngiennes',
    'romans-feuilletons',
    'saisies-exécutions',
    'semi-manufacturées',
    'serviettes-éponges',
    'shampooingnassions',
    'shampooingneraient',
    'social-démocraties',
    'sociaux-démocrates',
    'sociolinguistiques',
    'socioprofessionnel',
    'soumissionnassions',
    'soumissionneraient',
    'sous-alimentassent',
    'sous-alimentassiez',
    'sous-alimentations',
    'sous-alimenterions',
    'sous-consommations',
    'sous-développement',
    'sous-embranchement',
    'sous-entendissions',
    'sous-exploitassent',
    'sous-exploitassiez',
    'sous-exploitations',
    'sous-exploiterions',
    'sous-utilisassions',
    'sous-utiliseraient',
    'spatio-temporelles',
    'spiritualisassions',
    'spiritualiseraient',
    'sténodactylographe',
    'sténographiassions',
    'sténographieraient',
    'sténographiquement',
    'stéréocomparateurs',
    'strobophotographie',
    'subventionnassions',
    'subventionneraient',
    'suggestionnassions',
    'suggestionneraient',
    'superstitieusement',
    'supplémentairement',
    'supraconductivités',
    'surcapitalisations',
    'surenchérissements',
    'surmédicalisassent',
    'surmédicalisassiez',
    'surmédicaliserions',
    'surmultiplications',
    'syncristallisaient',
    'syncristallisasses',
    'syncristalliserais',
    'syncristalliserait',
    'syncristallisèrent',
    'syncristalliseriez',
    'syncristalliserons',
    'syncristalliseront',
    'technocratisassent',
    'technocratisassiez',
    'technocratisations',
    'technocratiserions',
    'télécommandassions',
    'télécommanderaient',
    'télécommunications',
    'télé-enseignements',
    'télésignalisations',
    'thalassothérapeute',
    'théophilanthropies',
    'thermodurcissables',
    'thermodynamicienne',
    'thermoélectricités',
    'thermogravimétries',
    'thermoluminescence',
    'thermorégulatrices',
    'timbres-quittances',
    'tintinnabulassions',
    'tintinnabuleraient',
    'tire-bouchonnaient',
    'tirebouchonnassent',
    'tire-bouchonnasses',
    'tirebouchonnassiez',
    'tire-bouchonnerais',
    'tire-bouchonnerait',
    'tire-bouchonnèrent',
    'tire-bouchonneriez',
    'tirebouchonnerions',
    'tire-bouchonnerons',
    'tire-bouchonneront',
    'tourbillonnassions',
    'tourbillonneraient',
    'trachéo-bronchites',
    'traditionnellement',
    'tranquillisassions',
    'tranquilliseraient',
    'transcontinentales',
    'transformationnels',
    'transistorisassent',
    'transistorisassiez',
    'transistorisations',
    'transistoriserions',
    'translittérassions',
    'translittéreraient',
    'transsubstantiâmes',
    'transsubstantiasse',
    'transsubstantiâtes',
    'transsubstantierai',
    'transsubstantieras',
    'transsubstantierez',
    'transsubstantiions',
    'trente-et-quarante',
    'triboluminescences',
    'tridimensionnelles',
    'tripatouillassions',
    'tripatouilleraient',
    'troussequinassions',
    'troussequineraient',
    'tuberculinisassent',
    'tuberculinisassiez',
    'tuberculiniserions',
    'turbo-alternateurs',
    'ultramicroscopique',
    'unidimensionnelles',
    'unidirectionnelles',
    'universalisassions',
    'universaliseraient',
    'valses-hésitations',
    'vasoconstrictrices',
    'villégiaturassions',
    'villégiatureraient',
    'villes-champignons',
    'wagons-restaurants'
];
