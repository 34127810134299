export default [
    'abaissassions',
    'abaisseraient',
    'abandonnaient',
    'abandonnasses',
    'abandonnerais',
    'abandonnerait',
    'abandonnèrent',
    'abandonneriez',
    'abandonnerons',
    'abandonneront',
    'abasourdirais',
    'abasourdirait',
    'abasourdirent',
    'abasourdiriez',
    'abasourdirons',
    'abasourdiront',
    'abasourdisses',
    'abasourdissez',
    'abâtardirions',
    'abâtardissais',
    'abâtardissait',
    'abâtardissant',
    'abâtardissent',
    'abâtardissiez',
    'abâtardissons',
    'abbevillienne',
    'abdiquassions',
    'abdiqueraient',
    'abêtissements',
    'abhorrassions',
    'abhorreraient',
    'abloquassions',
    'abloqueraient',
    'abominassions',
    'abomineraient',
    'abondancistes',
    'abonnissaient',
    'abonnissement',
    'abouchassions',
    'aboucheraient',
    'aboutissaient',
    'aboutissement',
    'abracadabrant',
    'abrégeassions',
    'abreuvassions',
    'abreuveraient',
    'abricotassent',
    'abricotassiez',
    'abricoterions',
    'abrogeassions',
    'abrutissaient',
    'abrutissantes',
    'abrutissement',
    'absentassions',
    'absorbassions',
    'absorberaient',
    'absorptivités',
    'abstiendrions',
    'abstrairaient',
    'abstraitement',
    'abyssiniennes',
    'académicienne',
    'acagnarderiez',
    'acagnarderons',
    'accablassions',
    'accableraient',
    'accaparassent',
    'accaparassiez',
    'accaparements',
    'accaparerions',
    'accastillâmes',
    'accastillasse',
    'accastillâtes',
    'accastillerai',
    'accastilleras',
    'accastillerez',
    'accastillions',
    'accélérassent',
    'accélérassiez',
    'accélérateurs',
    'accélérations',
    'accélératrice',
    'accélérerions',
    'accéléromètre',
    'accentuassent',
    'accentuassiez',
    'accentuations',
    'accentuerions',
    'acceptabilité',
    'acceptassions',
    'accepteraient',
    'accessibilité',
    'accessoirisai',
    'accessoirisas',
    'accessoirisât',
    'accessoirisée',
    'accessoiriser',
    'accessoirises',
    'accessoirisés',
    'accessoirisez',
    'accessoiriste',
    'accidentaient',
    'accidentasses',
    'accidentelles',
    'accidenterais',
    'accidenterait',
    'accidentèrent',
    'accidenteriez',
    'accidenterons',
    'accidenteront',
    'acclamassions',
    'acclameraient',
    'acclimatables',
    'acclimataient',
    'acclimatasses',
    'acclimatation',
    'acclimatement',
    'acclimaterais',
    'acclimaterait',
    'acclimatèrent',
    'acclimateriez',
    'acclimaterons',
    'acclimateront',
    'accointassiez',
    'accointerions',
    'accommodaient',
    'accommodantes',
    'accommodasses',
    'accommodateur',
    'accommodation',
    'accommodement',
    'accommoderais',
    'accommoderait',
    'accommodèrent',
    'accommoderiez',
    'accommoderons',
    'accommoderont',
    'accompagnâmes',
    'accompagnasse',
    'accompagnâtes',
    'accompagnerai',
    'accompagneras',
    'accompagnerez',
    'accompagnions',
    'accomplirions',
    'accomplissais',
    'accomplissait',
    'accomplissant',
    'accomplissent',
    'accomplissiez',
    'accomplissons',
    'accordassions',
    'accordéoniste',
    'accorderaient',
    'accostassions',
    'accosteraient',
    'accouchassent',
    'accouchassiez',
    'accouchements',
    'accoucherions',
    'accoudassions',
    'accouplassent',
    'accouplassiez',
    'accouplements',
    'accouplerions',
    'accourcirions',
    'accourcissais',
    'accourcissait',
    'accourcissant',
    'accourcissent',
    'accourcissiez',
    'accourcissons',
    'accourussions',
    'accoutrassent',
    'accoutrassiez',
    'accoutrements',
    'accoutrerions',
    'accoutumaient',
    'accoutumances',
    'accoutumasses',
    'accoutumerais',
    'accoutumerait',
    'accoutumèrent',
    'accoutumeriez',
    'accoutumerons',
    'accoutumeront',
    'accréditaient',
    'accréditasses',
    'accréditation',
    'accréditerais',
    'accréditerait',
    'accréditèrent',
    'accréditeriez',
    'accréditerons',
    'accréditeront',
    'accréditrices',
    'accrochassent',
    'accrochassiez',
    'accrocherions',
    'accroissaient',
    'accroissement',
    'accroîtraient',
    'accroupirions',
    'accroupissons',
    'accueillaient',
    'accueillantes',
    'accueillerais',
    'accueillerait',
    'accueilleriez',
    'accueillerons',
    'accueilleront',
    'accueillirent',
    'accueillisses',
    'acculturaient',
    'acculturasses',
    'acculturation',
    'acculturerais',
    'acculturerait',
    'acculturèrent',
    'accultureriez',
    'acculturerons',
    'accultureront',
    'accumulassent',
    'accumulassiez',
    'accumulateurs',
    'accumulations',
    'accumulerions',
    'acétifiassent',
    'acétifiassiez',
    'acétifierions',
    'acétylassions',
    'acétyléniques',
    'acétyleraient',
    'achalandaient',
    'achalandasses',
    'achalanderais',
    'achalanderait',
    'achalandèrent',
    'achalanderiez',
    'achalanderons',
    'achalanderont',
    'acharnassions',
    'acharneraient',
    'acheminassent',
    'acheminassiez',
    'acheminements',
    'acheminerions',
    'achoppassions',
    'achromatiques',
    'achromatismes',
    'achromatopsie',
    'acidifiassent',
    'acidifiassiez',
    'acidification',
    'acidifierions',
    'acidulassions',
    'aciduleraient',
    'aciselassions',
    'acisèleraient',
    'acoquinassiez',
    'acoquinements',
    'acoquinerions',
    'acousticienne',
    'acquiesçaient',
    'acquiesçasses',
    'acquiescement',
    'acquiescerais',
    'acquiescerait',
    'acquiescèrent',
    'acquiesceriez',
    'acquiescerons',
    'acquiesceront',
    'acquittassent',
    'acquittassiez',
    'acquittements',
    'acquitterions',
    'acrimonieuses',
    'acrocéphalies',
    'actinométries',
    'actinomycètes',
    'actinomycoses',
    'actionnariats',
    'actionnassent',
    'actionnassiez',
    'actionnements',
    'actionnerions',
    'actualisaient',
    'actualisasses',
    'actualisation',
    'actualiserais',
    'actualiserait',
    'actualisèrent',
    'actualiseriez',
    'actualiserons',
    'actualiseront',
    'acuponctrices',
    'acupunctrices',
    'adaptabilités',
    'additionnâmes',
    'additionnasse',
    'additionnâtes',
    'additionnelle',
    'additionnerai',
    'additionneras',
    'additionnerez',
    'additionnions',
    'adjectivaient',
    'adjectivasses',
    'adjectivement',
    'adjectiverais',
    'adjectiverait',
    'adjectivèrent',
    'adjectiveriez',
    'adjectiverons',
    'adjectiveront',
    'adjectivisais',
    'adjectivisait',
    'adjectivisant',
    'adjectivisées',
    'adjectivisent',
    'adjectivisera',
    'adjectivisiez',
    'adjectivisons',
    'adjoignissent',
    'adjoignissiez',
    'adjoindraient',
    'adjudant-chef',
    'adjudicataire',
    'adjudicateurs',
    'adjudications',
    'adjudicatives',
    'adjudicatrice',
    'adjugeassions',
    'administrable',
    'administrâmes',
    'administrasse',
    'administrâtes',
    'administratif',
    'administrerai',
    'administreras',
    'administrerez',
    'administrions',
    'admirablement',
    'admissibilité',
    'admonestaient',
    'admonestasses',
    'admonestation',
    'admonesterais',
    'admonesterait',
    'admonestèrent',
    'admonesteriez',
    'admonesterons',
    'admonesteront',
    'adonisassions',
    'adoucissaient',
    'adoucissantes',
    'adoucissement',
    'adrénergiques',
    'adressassions',
    'adresseraient',
    'adultérassent',
    'adultérassiez',
    'adultérations',
    'adultérerions',
    'aérodynamique',
    'aéroglisseurs',
    'aéronautiques',
    'aérospatiales',
    'affabulassent',
    'affabulassiez',
    'affabulations',
    'affabulerions',
    'affadissaient',
    'affadissantes',
    'affadissement',
    'affaiblirions',
    'affaiblissais',
    'affaiblissait',
    'affaiblissant',
    'affaiblissent',
    'affaiblissiez',
    'affaiblissons',
    'affairassions',
    'affaissassent',
    'affaissassiez',
    'affaissements',
    'affaisserions',
    'afféageassent',
    'afféageassiez',
    'afféageraient',
    'affectassions',
    'affecteraient',
    'affectionnais',
    'affectionnait',
    'affectionnant',
    'affectionnées',
    'affectionnent',
    'affectionnera',
    'affectionniez',
    'affectionnons',
    'affermassions',
    'affermeraient',
    'affermiraient',
    'affermissions',
    'affichassions',
    'afficheraient',
    'affiliassions',
    'affilieraient',
    'affirmassions',
    'affirmeraient',
    'affleurassent',
    'affleurassiez',
    'affleurements',
    'affleurerions',
    'affligeassent',
    'affligeassiez',
    'affligeraient',
    'afflouassions',
    'affloueraient',
    'affouageaient',
    'affouageasses',
    'affouagerions',
    'affouillaient',
    'affouillasses',
    'affouillement',
    'affouillerais',
    'affouillerait',
    'affouillèrent',
    'affouilleriez',
    'affouillerons',
    'affouilleront',
    'affourageâmes',
    'affourageasse',
    'affourageâtes',
    'affouragement',
    'affouragerais',
    'affouragerait',
    'affouragèrent',
    'affourageriez',
    'affouragerons',
    'affourageront',
    'affourchaient',
    'affourchasses',
    'affourcherais',
    'affourcherait',
    'affourchèrent',
    'affourcheriez',
    'affourcherons',
    'affourcheront',
    'affourrageais',
    'affourrageait',
    'affourrageant',
    'affourrageons',
    'affourragerai',
    'affourrageras',
    'affourragerez',
    'affourragions',
    'affranchirais',
    'affranchirait',
    'affranchirent',
    'affranchiriez',
    'affranchirons',
    'affranchiront',
    'affranchisses',
    'affranchissez',
    'affrétassions',
    'affréteraient',
    'affriandaient',
    'affriandasses',
    'affrianderais',
    'affrianderait',
    'affriandèrent',
    'affrianderiez',
    'affrianderons',
    'affrianderont',
    'affrichassent',
    'affrichassiez',
    'affricherions',
    'affriolassent',
    'affriolassiez',
    'affriolerions',
    'affritassions',
    'affriteraient',
    'affrontassent',
    'affrontassiez',
    'affrontements',
    'affronterions',
    'affruitassent',
    'affruitassiez',
    'affruiterions',
    'affublassions',
    'affubleraient',
    'africanisâmes',
    'africanisasse',
    'africanisâtes',
    'africaniserai',
    'africaniseras',
    'africaniserez',
    'africanisions',
    'afro-cubaines',
    'agenouillâmes',
    'agenouillâtes',
    'agenouillerez',
    'agenouillions',
    'agenouilloirs',
    'aggiornamento',
    'aggloméraient',
    'agglomérasses',
    'agglomération',
    'agglomérerais',
    'agglomérerait',
    'agglomérèrent',
    'aggloméreriez',
    'agglomérerons',
    'aggloméreront',
    'agglutinaient',
    'agglutinantes',
    'agglutinasses',
    'agglutination',
    'agglutinerais',
    'agglutinerait',
    'agglutinèrent',
    'agglutineriez',
    'agglutinerons',
    'agglutineront',
    'aggravassions',
    'aggraveraient',
    'agnelleraient',
    'agnosticismes',
    'agonisassions',
    'agoniseraient',
    'agrammaticale',
    'agrammaticaux',
    'agrammatismes',
    'agrandiraient',
    'agrandisseurs',
    'agrandissions',
    'agrégeassions',
    'agrémentaient',
    'agrémentasses',
    'agrémenterais',
    'agrémenterait',
    'agrémentèrent',
    'agrémenteriez',
    'agrémenterons',
    'agrémenteront',
    'agressassions',
    'agresseraient',
    'agressivement',
    'agrichassions',
    'agricheraient',
    'agricultrices',
    'agriffassions',
    'agrippassions',
    'agripperaient',
    'aguerriraient',
    'aguerrissions',
    'aguichassions',
    'aguicheraient',
    'aheurtassions',
    'ahurissements',
    'aigres-douces',
    'aiguillassent',
    'aiguillassiez',
    'aiguillerions',
    'aiguilletâmes',
    'aiguilletasse',
    'aiguilletâtes',
    'aiguilletions',
    'aiguillettent',
    'aiguillettera',
    'aiguillonnais',
    'aiguillonnait',
    'aiguillonnant',
    'aiguillonnées',
    'aiguillonnent',
    'aiguillonnera',
    'aiguillonniez',
    'aiguillonnons',
    'aiguisassions',
    'aiguiseraient',
    'aimantassions',
    'aimanteraient',
    'ajointassions',
    'ajointeraient',
    'ajournassions',
    'ajourneraient',
    'alambiquaient',
    'alambiquasses',
    'alambiquerais',
    'alambiquerait',
    'alambiquèrent',
    'alambiqueriez',
    'alambiquerons',
    'alambiqueront',
    'alanguiraient',
    'alanguissions',
    'albuminurique',
    'alcalescences',
    'alcalescentes',
    'alcalifiantes',
    'alcalimétries',
    'alcalinisâmes',
    'alcalinisasse',
    'alcalinisâtes',
    'alcaliniserai',
    'alcaliniseras',
    'alcaliniserez',
    'alcalinisions',
    'alcalisassent',
    'alcalisassiez',
    'alcaliserions',
    'alcoolisables',
    'alcoolisaient',
    'alcoolisasses',
    'alcoolisation',
    'alcooliserais',
    'alcooliserait',
    'alcoolisèrent',
    'alcooliseriez',
    'alcooliserons',
    'alcooliseront',
    'aléatoirement',
    'alentissaient',
    'alevinassions',
    'alevineraient',
    'algorithmique',
    'aliénabilités',
    'alimentassent',
    'alimentassiez',
    'alimentations',
    'alimenterions',
    'allaitassions',
    'allaiteraient',
    'alléchassions',
    'allécheraient',
    'allégeassions',
    'allégissaient',
    'allégorisâmes',
    'allégorisasse',
    'allégorisâtes',
    'allégoriserai',
    'allégoriseras',
    'allégoriserez',
    'allégorisions',
    'alléguassions',
    'allégueraient',
    'allélomorphes',
    'allergisantes',
    'allergologies',
    'allergologues',
    'aller-retours',
    'allitérations',
    'allongeassent',
    'allongeassiez',
    'allongeraient',
    'allopathiques',
    'allotropiques',
    'allume-cigare',
    'allumettières',
    'alluvionnaire',
    'alluvionnâmes',
    'alluvionnasse',
    'alluvionnâtes',
    'alluvionnerai',
    'alluvionneras',
    'alluvionnerez',
    'alluvionnions',
    'alourdiraient',
    'alourdissions',
    'alpaguassions',
    'alpagueraient',
    'alphabétiques',
    'alphabétisais',
    'alphabétisait',
    'alphabétisant',
    'alphabétisées',
    'alphabétisent',
    'alphabétisera',
    'alphabétisiez',
    'alphabétismes',
    'alphabétisons',
    'altérabilités',
    'alternassions',
    'alterneraient',
    'aluminassions',
    'alumineraient',
    'amadouassions',
    'amadoueraient',
    'amaigriraient',
    'amaigrissante',
    'amaigrissants',
    'amaigrissions',
    'amalgamassent',
    'amalgamassiez',
    'amalgamations',
    'amalgamerions',
    'amarinassions',
    'amarineraient',
    'amaryllidacée',
    'ambassadrices',
    'ambitionnâmes',
    'ambitionnasse',
    'ambitionnâtes',
    'ambitionnerai',
    'ambitionneras',
    'ambitionnerez',
    'ambitionnions',
    'ambulancières',
    'améliorassent',
    'améliorassiez',
    'améliorations',
    'améliorerions',
    'aménageassent',
    'aménageassiez',
    'aménageraient',
    'amenuisassent',
    'amenuisassiez',
    'amenuisements',
    'amenuiserions',
    'américanisais',
    'américanisait',
    'américanisant',
    'américanisées',
    'américanisent',
    'américanisera',
    'américanisiez',
    'américanismes',
    'américanisons',
    'américanistes',
    'amérindiennes',
    'amerrissaient',
    'ameubliraient',
    'ameublissions',
    'amidonnassent',
    'amidonnassiez',
    'amidonnerions',
    'amincissaient',
    'amincissantes',
    'amincissement',
    'ammonisations',
    'amniocentèses',
    'amnistiassent',
    'amnistiassiez',
    'amnistierions',
    'amoindririons',
    'amoindrissais',
    'amoindrissait',
    'amoindrissant',
    'amoindrissent',
    'amoindrissiez',
    'amoindrissons',
    'amollissaient',
    'amollissantes',
    'amollissement',
    'amoncelassent',
    'amoncelassiez',
    'amoncellement',
    'amoncellerais',
    'amoncellerait',
    'amoncelleriez',
    'amoncellerons',
    'amoncelleront',
    'amordançaient',
    'amordançasses',
    'amordancerais',
    'amordancerait',
    'amordancèrent',
    'amordanceriez',
    'amordancerons',
    'amordanceront',
    'amortissables',
    'amortissement',
    'amouracheriez',
    'amouracherons',
    'amoureusement',
    'ampélographie',
    'amphiarthrose',
    'amphibologies',
    'amphictyonies',
    'amphigourique',
    'amphithéâtres',
    'amplifiassent',
    'amplifiassiez',
    'amplificateur',
    'amplification',
    'amplifierions',
    'amplis-tuners',
    'amuse-gueules',
    'anabolisantes',
    'anachorétique',
    'anachroniques',
    'anachronismes',
    'anacréontique',
    'anaglyptiques',
    'analgésiaient',
    'analgésiasses',
    'analgésierais',
    'analgésierait',
    'analgésièrent',
    'analgésieriez',
    'analgésierons',
    'analgésieront',
    'anallergiques',
    'analysassions',
    'analyseraient',
    'anaphrodisies',
    'anarchisantes',
    'anastomosâmes',
    'anastomosâtes',
    'anastomoserez',
    'anastomosions',
    'anathématisai',
    'anathématisas',
    'anathématisât',
    'anathématisée',
    'anathématiser',
    'anathématises',
    'anathématisés',
    'anathématisez',
    'anatomisaient',
    'anatomisasses',
    'anatomiserais',
    'anatomiserait',
    'anatomisèrent',
    'anatomiseriez',
    'anatomiserons',
    'anatomiseront',
    'andouillettes',
    'androcéphales',
    'androstérones',
    'anéantiraient',
    'anéantissions',
    'anesthésiâmes',
    'anesthésiante',
    'anesthésiants',
    'anesthésiasse',
    'anesthésiâtes',
    'anesthésierai',
    'anesthésieras',
    'anesthésierez',
    'anesthésiions',
    'anesthésiques',
    'anesthésistes',
    'anfractuosité',
    'angiocholites',
    'angiographies',
    'anglaisassent',
    'anglaisassiez',
    'anglaiserions',
    'anglicanismes',
    'anglicisaient',
    'anglicisasses',
    'angliciserais',
    'angliciserait',
    'anglicisèrent',
    'angliciseriez',
    'angliciserons',
    'angliciseront',
    'anglo-normand',
    'anglo-saxonne',
    'angoissassent',
    'angoissassiez',
    'angoisserions',
    'angusticlaves',
    'anharmoniques',
    'animadversion',
    'animalisaient',
    'animalisasses',
    'animaliserais',
    'animaliserait',
    'animalisèrent',
    'animaliseriez',
    'animaliserons',
    'animaliseront',
    'ankylosassent',
    'ankylosassiez',
    'ankyloserions',
    'année-lumière',
    'annelleraient',
    'annexionnisme',
    'annexionniste',
    'annihilassent',
    'annihilassiez',
    'annihilations',
    'annihilerions',
    'anniversaires',
    'annonçassions',
    'annonceraient',
    'annonciateurs',
    'annonciations',
    'annonciatrice',
    'anoblissaient',
    'anoblissement',
    'anodisassions',
    'anodiseraient',
    'anordissaient',
    'antédiluviens',
    'antéposassent',
    'antéposassiez',
    'antéposerions',
    'anthérozoïdes',
    'anthraquinone',
    'anthropologie',
    'anthropologue',
    'anthropophage',
    'antiaériennes',
    'antiatomiques',
    'antibiotiques',
    'antibrouillée',
    'antibrouillés',
    'anticancéreux',
    'anticipassent',
    'anticipassiez',
    'anticipations',
    'anticiperions',
    'anticléricale',
    'anticléricaux',
    'anticoagulant',
    'anticycliques',
    'anticyclonale',
    'anticyclonaux',
    'antidatassent',
    'antidatassiez',
    'antidaterions',
    'antidérapante',
    'antidérapants',
    'antidétonante',
    'antidétonants',
    'antifascistes',
    'antilithiques',
    'antimitotique',
    'antinationale',
    'antinationaux',
    'antinucléaire',
    'antipaludéens',
    'antipaludique',
    'antiparallèle',
    'antiparasites',
    'antiparticule',
    'antipathiques',
    'antipersonnel',
    'antiphonaires',
    'antipollution',
    'antipyrétique',
    'antiradiation',
    'antireligieux',
    'antisatellite',
    'antisémitisme',
    'antiseptiques',
    'antisportives',
    'antitétanique',
    'antithétiques',
    'antivenimeuse',
    'apanageassent',
    'apanageassiez',
    'apanageraient',
    'apercevraient',
    'aphrodisiaque',
    'apitoieraient',
    'apitoyassions',
    'aplanissaient',
    'aplanissement',
    'aplatissaient',
    'aplatissement',
    'aplatissoires',
    'apocalyptique',
    'apolliniennes',
    'apologétiques',
    'aponévrotique',
    'apoplectiques',
    'apostasiaient',
    'apostasiasses',
    'apostasierais',
    'apostasierait',
    'apostasièrent',
    'apostasieriez',
    'apostasierons',
    'apostasieront',
    'apostillaient',
    'apostillasses',
    'apostillerais',
    'apostillerait',
    'apostillèrent',
    'apostilleriez',
    'apostillerons',
    'apostilleront',
    'apostrophâmes',
    'apostrophasse',
    'apostrophâtes',
    'apostropherai',
    'apostropheras',
    'apostropherez',
    'apostrophions',
    'appairassions',
    'appaireraient',
    'appalachienne',
    'apparaissions',
    'apparaîtrions',
    'appareillades',
    'appareillages',
    'appareillâmes',
    'appareillasse',
    'appareillâtes',
    'appareillerai',
    'appareilleras',
    'appareillerez',
    'appareilleurs',
    'appareillions',
    'apparentement',
    'apparenteriez',
    'apparenterons',
    'appariassions',
    'apparieraient',
    'appartenaient',
    'appartenances',
    'appartiendrai',
    'appartiendras',
    'appartiendrez',
    'appartiennent',
    'appartinssent',
    'appartinssiez',
    'appauvririons',
    'appauvrissais',
    'appauvrissait',
    'appauvrissant',
    'appauvrissent',
    'appauvrissiez',
    'appauvrissons',
    'appelleraient',
    'appesantirais',
    'appesantirait',
    'appesantirent',
    'appesantiriez',
    'appesantirons',
    'appesantiront',
    'appesantisses',
    'appesantissez',
    'appétissantes',
    'applaudimètre',
    'applaudirions',
    'applaudissais',
    'applaudissait',
    'applaudissant',
    'applaudissent',
    'applaudissiez',
    'applaudissons',
    'applicabilité',
    'applicatrices',
    'appliquassent',
    'appliquassiez',
    'appliquerions',
    'appoggiatures',
    'appointassent',
    'appointassiez',
    'appointements',
    'appointerions',
    'appointirions',
    'appointissais',
    'appointissait',
    'appointissant',
    'appointissent',
    'appointissiez',
    'appointissons',
    'appontassions',
    'apponteraient',
    'apportassions',
    'apporteraient',
    'appréciassent',
    'appréciassiez',
    'appréciateurs',
    'appréciations',
    'appréciatives',
    'appréciatrice',
    'apprécierions',
    'appréhendâmes',
    'appréhendasse',
    'appréhendâtes',
    'appréhenderai',
    'appréhenderas',
    'appréhenderez',
    'appréhendions',
    'appréhensions',
    'appréhensives',
    'apprendraient',
    'apprentissage',
    'apprêtassions',
    'apprêteraient',
    'apprivoisable',
    'apprivoisâmes',
    'apprivoisasse',
    'apprivoisâtes',
    'apprivoiserai',
    'apprivoiseras',
    'apprivoiserez',
    'apprivoiseurs',
    'apprivoiseuse',
    'apprivoisions',
    'approbativité',
    'approbatrices',
    'approchassent',
    'approchassiez',
    'approcherions',
    'approfondîmes',
    'approfondirai',
    'approfondiras',
    'approfondirez',
    'approfondisse',
    'approfondîtes',
    'appropriaient',
    'appropriasses',
    'appropriation',
    'approprierais',
    'approprierait',
    'approprièrent',
    'approprieriez',
    'approprierons',
    'approprieront',
    'approuvassent',
    'approuvassiez',
    'approuverions',
    'approvisionna',
    'approvisionne',
    'approvisionné',
    'approximatifs',
    'approximation',
    'approximative',
    'apragmatismes',
    'après-guerres',
    'après-rasages',
    'aquafortistes',
    'aquarellistes',
    'arabisassions',
    'arabiseraient',
    'arbalétrières',
    'arbitragistes',
    'arbitrassions',
    'arbitreraient',
    'arborescences',
    'arborescentes',
    'arboriculteur',
    'arboriculture',
    'arborisassent',
    'arborisassiez',
    'arborisations',
    'arboriserions',
    'arc-boutaient',
    'arc-boutasses',
    'arc-bouterais',
    'arc-bouterait',
    'arc-boutèrent',
    'arc-bouteriez',
    'arc-bouterons',
    'arc-bouteront',
    'archaïsassent',
    'archaïsassiez',
    'archaïserions',
    'archangélique',
    'archéologique',
    'archiduchesse',
    'archimandrite',
    'archiphonèmes',
    'architectonie',
    'architecturai',
    'architectural',
    'architecturas',
    'architecturât',
    'architecturée',
    'architecturer',
    'architectures',
    'architecturés',
    'architecturez',
    'archivassions',
    'archiveraient',
    'archivistique',
    'arçonnassions',
    'arçonneraient',
    'arcs-boutants',
    'ardoisassions',
    'ardoiseraient',
    'argentassions',
    'argenteraient',
    'argotisassent',
    'argotisassiez',
    'argotiserions',
    'argumentaient',
    'argumentaires',
    'argumentasses',
    'argumentateur',
    'argumentation',
    'argumenterais',
    'argumenterait',
    'argumentèrent',
    'argumenteriez',
    'argumenterons',
    'argumenteront',
    'aristocraties',
    'aristotélique',
    'aristotélisme',
    'arithmétiques',
    'armoriassions',
    'armorieraient',
    'arnaquassions',
    'arnaqueraient',
    'aromatisaient',
    'aromatisasses',
    'aromatiserais',
    'aromatiserait',
    'aromatisèrent',
    'aromatiseriez',
    'aromatiserons',
    'aromatiseront',
    'arpégeassions',
    'arpentassions',
    'arpenteraient',
    'arrachassions',
    'arracheraient',
    'arraisonnâmes',
    'arraisonnasse',
    'arraisonnâtes',
    'arraisonnerai',
    'arraisonneras',
    'arraisonnerez',
    'arraisonnions',
    'arrangeassent',
    'arrangeassiez',
    'arrangeraient',
    'arrentassions',
    'arrenteraient',
    'arrérageaient',
    'arrérageasses',
    'arréragerions',
    'arriérassions',
    'arrière-corps',
    'arrière-cours',
    'arrière-fonds',
    'arrière-garde',
    'arrière-gorge',
    'arrière-goûts',
    'arrière-mains',
    'arrière-plans',
    'arriéreraient',
    'arrière-train',
    'arrogeassions',
    'arrondiraient',
    'arrondissages',
    'arrondissions',
    'arsouilleriez',
    'arsouillerons',
    'artériectomie',
    'artériotomies',
    'arthrographie',
    'arthropathies',
    'artichautière',
    'articulassent',
    'articulassiez',
    'articulations',
    'articulatoire',
    'articulerions',
    'artificielles',
    'artificieuses',
    'ascensionnais',
    'ascensionnait',
    'ascensionnant',
    'ascensionnées',
    'ascensionnels',
    'ascensionnent',
    'ascensionnera',
    'ascensionniez',
    'ascensionnons',
    'aseptisassent',
    'aseptisassiez',
    'aseptisations',
    'aseptiserions',
    'asociabilités',
    'aspectassions',
    'aspecteraient',
    'aspergeassent',
    'aspergeassiez',
    'aspergeraient',
    'aspergilloses',
    'aspermatismes',
    'asphaltassent',
    'asphaltassiez',
    'asphalterions',
    'asphyxiassent',
    'asphyxiassiez',
    'asphyxierions',
    'assagissaient',
    'assagissement',
    'assaillirions',
    'assaillissent',
    'assaillissiez',
    'assainiraient',
    'assainisseurs',
    'assainissions',
    'assaisonnâmes',
    'assaisonnasse',
    'assaisonnâtes',
    'assaisonnerai',
    'assaisonneras',
    'assaisonnerez',
    'assaisonnions',
    'assarmentâmes',
    'assarmentasse',
    'assarmentâtes',
    'assarmenterai',
    'assarmenteras',
    'assarmenterez',
    'assarmentions',
    'assassinaient',
    'assassinasses',
    'assassinerais',
    'assassinerait',
    'assassinèrent',
    'assassineriez',
    'assassinerons',
    'assassineront',
    'asséchassions',
    'assécheraient',
    'assemblassent',
    'assemblassiez',
    'assemblerions',
    'assermentâmes',
    'assermentasse',
    'assermentâtes',
    'assermenterai',
    'assermenteras',
    'assermenterez',
    'assermentions',
    'asserviraient',
    'asservissante',
    'asservissants',
    'asservisseurs',
    'asservissions',
    'assibilassent',
    'assibilassiez',
    'assibilations',
    'assibilerions',
    'assiégeassent',
    'assiégeassiez',
    'assiégeraient',
    'assignassions',
    'assigneraient',
    'assimilassent',
    'assimilassiez',
    'assimilateurs',
    'assimilations',
    'assimilatrice',
    'assimilerions',
    'assistassions',
    'assisteraient',
    'associassions',
    'associeraient',
    'assombririons',
    'assombrissais',
    'assombrissait',
    'assombrissant',
    'assombrissent',
    'assombrissiez',
    'assombrissons',
    'assommassions',
    'assommeraient',
    'assortiraient',
    'assortissions',
    'assoupiraient',
    'assoupissante',
    'assoupissants',
    'assoupissions',
    'assouplirions',
    'assouplissais',
    'assouplissait',
    'assouplissant',
    'assouplissent',
    'assouplissiez',
    'assouplissons',
    'assourdirions',
    'assourdissais',
    'assourdissait',
    'assourdissant',
    'assourdissent',
    'assourdissiez',
    'assourdissons',
    'assouviraient',
    'assouvissions',
    'assujettirais',
    'assujettirait',
    'assujettirent',
    'assujettiriez',
    'assujettirons',
    'assujettiront',
    'assujettisses',
    'assujettissez',
    'assyriologies',
    'assyriologues',
    'astéréognosie',
    'asthénosphère',
    'asticotassent',
    'asticotassiez',
    'asticoterions',
    'astigmatismes',
    'astiquassions',
    'astiqueraient',
    'astreignaient',
    'astreignantes',
    'astreignirent',
    'astreignisses',
    'astreindrions',
    'astrologiques',
    'astronautique',
    'astronomiques',
    'asymptotiques',
    'atermoiements',
    'atermoierions',
    'atermoyassent',
    'atermoyassiez',
    'atmosphérique',
    'atomisassions',
    'atomiseraient',
    'atrophiassent',
    'atrophiassiez',
    'atrophierions',
    'attablassions',
    'attableraient',
    'attachassions',
    'attacheraient',
    'attaquassions',
    'attaqueraient',
    'attardassions',
    'attarderaient',
    'atteignissent',
    'atteignissiez',
    'atteindraient',
    'attelleraient',
    'attendissions',
    'attendririons',
    'attendrissais',
    'attendrissait',
    'attendrissant',
    'attendrissent',
    'attendrisseur',
    'attendrissiez',
    'attendrissons',
    'attentassions',
    'attentatoires',
    'attenteraient',
    'attentionnées',
    'attentivement',
    'atténuassions',
    'atténueraient',
    'atterrassions',
    'atterreraient',
    'atterriraient',
    'atterrissages',
    'atterrissions',
    'attestassions',
    'attesteraient',
    'attiédiraient',
    'attiédissions',
    'attigeassions',
    'attitrassions',
    'attitreraient',
    'attouchements',
    'attrapassions',
    'attraperaient',
    'attribuassent',
    'attribuassiez',
    'attribuerions',
    'attributaires',
    'attriquassent',
    'attriquassiez',
    'attriquerions',
    'attristassent',
    'attristassiez',
    'attristerions',
    'attroupassent',
    'attroupassiez',
    'attroupements',
    'attrouperions',
    'audiovisuelle',
    'auditionnâmes',
    'auditionnasse',
    'auditionnâtes',
    'auditionnerai',
    'auditionneras',
    'auditionnerez',
    'auditionnions',
    'augmentassent',
    'augmentassiez',
    'augmentations',
    'augmentatives',
    'augmenterions',
    'augustinienne',
    'auréolassions',
    'auréoleraient',
    'aurifiassions',
    'aurifications',
    'aurifieraient',
    'aurignacienne',
    'auscultassent',
    'auscultassiez',
    'auscultations',
    'auscultatoire',
    'ausculterions',
    'australiennes',
    'authenticités',
    'authentifiais',
    'authentifiait',
    'authentifiant',
    'authentifiées',
    'authentifient',
    'authentifiera',
    'authentifiiez',
    'authentifions',
    'authentiquais',
    'authentiquait',
    'authentiquant',
    'authentiquées',
    'authentiquent',
    'authentiquera',
    'authentiquiez',
    'authentiquons',
    'auto-adhésifs',
    'auto-adhésive',
    'auto-allumage',
    'auto-amorçage',
    'autocassables',
    'autochenilles',
    'autocinétique',
    'autocollantes',
    'autocratiques',
    'autocritiques',
    'autodéterminé',
    'autofinancées',
    'autofinanciez',
    'autofinançons',
    'autographiais',
    'autographiait',
    'autographiant',
    'autographiées',
    'autographient',
    'autographiera',
    'autographiiez',
    'autographions',
    'autoguideriez',
    'autoguiderons',
    'automatisâmes',
    'automatisasse',
    'automatisâtes',
    'automatiserai',
    'automatiseras',
    'automatiserez',
    'automatisions',
    'automobilisme',
    'automobiliste',
    'autonettoyant',
    'autoportantes',
    'autoporteuses',
    'autoportraits',
    'autopropulsée',
    'autopropulsés',
    'autopsiassent',
    'autopsiassiez',
    'autopsierions',
    'autopunitions',
    'autorisassent',
    'autorisassiez',
    'autorisations',
    'autoriserions',
    'autoritarisme',
    'autoroutières',
    'auto-stoppeur',
    'autrichiennes',
    'avachissaient',
    'avachissement',
    'avaient-elles',
    'avalisassions',
    'avaliseraient',
    'avantageaient',
    'avantageasses',
    'avantagerions',
    'avant-coureur',
    'avant-creuset',
    'avant-dernier',
    'avant-guerres',
    'avant-projets',
    'avant-veilles',
    'aventurassent',
    'aventurassiez',
    'aventurerions',
    'avertissaient',
    'avertissement',
    'avertisseuses',
    'aveuglassions',
    'aveugleraient',
    'aveulissaient',
    'aveulissement',
    'avilissements',
    'avions-cargos',
    'avitaillaient',
    'avitaillasses',
    'avitaillement',
    'avitaillerais',
    'avitaillerait',
    'avitaillèrent',
    'avitailleriez',
    'avitaillerons',
    'avitailleront',
    'avoisinassent',
    'avoisinassiez',
    'avoisinerions',
    'axiomatisâmes',
    'axiomatisasse',
    'axiomatisâtes',
    'axiomatiserai',
    'axiomatiseras',
    'axiomatiserez',
    'axiomatisions',
    'axonométrique',
    'babillassions',
    'babilleraient',
    'baccalauréats',
    'bachi-bouzouk',
    'bachotassions',
    'bachoteraient',
    'bacilliformes',
    'bactériologie',
    'bactériophage',
    'badigeonnages',
    'badigeonnâmes',
    'badigeonnasse',
    'badigeonnâtes',
    'badigeonnerai',
    'badigeonneras',
    'badigeonnerez',
    'badigeonneurs',
    'badigeonnions',
    'bafouillaient',
    'bafouillasses',
    'bafouillerais',
    'bafouillerait',
    'bafouillèrent',
    'bafouilleriez',
    'bafouillerons',
    'bafouilleront',
    'bafouilleuses',
    'bagarrassions',
    'bagarreraient',
    'baguenaudâmes',
    'baguenaudasse',
    'baguenaudâtes',
    'baguenauderai',
    'baguenauderas',
    'baguenauderez',
    'baguenaudiers',
    'baguenaudions',
    'bâillonnaient',
    'bâillonnasses',
    'bâillonnement',
    'bâillonnerais',
    'bâillonnerait',
    'bâillonnèrent',
    'bâillonneriez',
    'bâillonnerons',
    'bâillonneront',
    'baisotassions',
    'baisoteraient',
    'balafrassions',
    'balafreraient',
    'balançassions',
    'balanceraient',
    'balbutiassent',
    'balbutiassiez',
    'balbutiements',
    'balbutierions',
    'baleinassions',
    'baleineraient',
    'balkanisaient',
    'balkanisasses',
    'balkanisation',
    'balkaniserais',
    'balkaniserait',
    'balkanisèrent',
    'balkaniseriez',
    'balkaniserons',
    'balkaniseront',
    'ballastassent',
    'ballastassiez',
    'ballasterions',
    'ballonnassent',
    'ballonnassiez',
    'ballonnements',
    'ballonnerions',
    'ballottassent',
    'ballottassiez',
    'ballottements',
    'ballotterions',
    'bambochassent',
    'bambochassiez',
    'bambocherions',
    'banalisassent',
    'banalisassiez',
    'banalisations',
    'banaliserions',
    'banderilleros',
    'banlieusardes',
    'bannissements',
    'banqueroutier',
    'banquetassent',
    'banquetassiez',
    'banquetterais',
    'banquetterait',
    'banquetteriez',
    'banquetterons',
    'banquetteront',
    'baptisassions',
    'baptiseraient',
    'baquetassions',
    'baquetterions',
    'baragouinages',
    'baragouinâmes',
    'baragouinasse',
    'baragouinâtes',
    'baragouinerai',
    'baragouineras',
    'baragouinerez',
    'baragouineurs',
    'baragouineuse',
    'baragouinions',
    'baraquassions',
    'baraqueraient',
    'baratinassent',
    'baratinassiez',
    'baratinerions',
    'barattassions',
    'baratteraient',
    'barbifiassent',
    'barbifiassiez',
    'barbifierions',
    'barbituriques',
    'barbiturismes',
    'barbotassions',
    'barboteraient',
    'barbouillages',
    'barbouillâmes',
    'barbouillasse',
    'barbouillâtes',
    'barbouillerai',
    'barbouilleras',
    'barbouillerez',
    'barbouilleurs',
    'barbouilleuse',
    'barbouillions',
    'barguignaient',
    'barguignasses',
    'barguignerais',
    'barguignerait',
    'barguignèrent',
    'barguigneriez',
    'barguignerons',
    'barguigneront',
    'bariolassions',
    'barioleraient',
    'barométriques',
    'baronnassions',
    'baronneraient',
    'baroudassions',
    'barouderaient',
    'barricadaient',
    'barricadasses',
    'barricaderais',
    'barricaderait',
    'barricadèrent',
    'barricaderiez',
    'barricaderons',
    'barricaderont',
    'barrissements',
    'barycentrique',
    'basculassions',
    'basculeraient',
    'bassinassions',
    'bassineraient',
    'bastillonnais',
    'bastillonnait',
    'bastillonnant',
    'bastillonnées',
    'bastillonnent',
    'bastillonnera',
    'bastillonniez',
    'bastillonnons',
    'bastionnaient',
    'bastionnasses',
    'bastionnerais',
    'bastionnerait',
    'bastionnèrent',
    'bastionneriez',
    'bastionnerons',
    'bastionneront',
    'bataillassent',
    'bataillassiez',
    'bataillerions',
    'bateau-mouche',
    'bateau-pilote',
    'batelleraient',
    'bathymétrique',
    'batifolassent',
    'batifolassiez',
    'batifolerions',
    'bâtonnassions',
    'bâtonneraient',
    'battellements',
    'bavardassions',
    'bavarderaient',
    'bavassassions',
    'bavasseraient',
    'bavochassions',
    'bavocheraient',
    'bazardassions',
    'bazarderaient',
    'béatifiassent',
    'béatifiassiez',
    'béatification',
    'béatifierions',
    'beaux-parents',
    'bec-de-lièvre',
    'becquetassent',
    'becquetassiez',
    'becquetterais',
    'becquetterait',
    'becquetteriez',
    'becquetterons',
    'becquetteront',
    'bedonnassions',
    'bedonneraient',
    'béguetassions',
    'béguèteraient',
    'behaviorismes',
    'béhaviorismes',
    'béhavioristes',
    'bélinographes',
    'belle-de-jour',
    'belle-de-nuit',
    'belle-famille',
    'belles-doches',
    'belles-filles',
    'belles-soeurs',
    'belligérances',
    'belligérantes',
    'bémolisassent',
    'bémolisassiez',
    'bémoliserions',
    'bénéficiaient',
    'bénéficiaires',
    'bénéficiasses',
    'bénéficierais',
    'bénéficierait',
    'bénéficièrent',
    'bénéficieriez',
    'bénéficierons',
    'bénéficieront',
    'béquetassions',
    'béquetterions',
    'béquillassent',
    'béquillassiez',
    'béquillerions',
    'bercelonnette',
    'bergeronnette',
    'berginisation',
    'bertillonnage',
    'besognassions',
    'besogneraient',
    'bêtathérapies',
    'bêtifiassions',
    'bêtifieraient',
    'bétonnassions',
    'bétonneraient',
    'betteravières',
    'bibelotassent',
    'bibelotassiez',
    'bibeloterions',
    'biberonnaient',
    'biberonnasses',
    'biberonnerais',
    'biberonnerait',
    'biberonnèrent',
    'biberonneriez',
    'biberonnerons',
    'biberonneront',
    'bibliographes',
    'bibliographie',
    'bibliophilies',
    'bibliothèques',
    'bicaméralisme',
    'bicentenaires',
    'bichonnassent',
    'bichonnassiez',
    'bichonnerions',
    'bichotassions',
    'bichoteraient',
    'biculturelles',
    'bidonnassions',
    'bidonneraient',
    'bidouillaient',
    'bidouillasses',
    'bidouillerais',
    'bidouillerait',
    'bidouillèrent',
    'bidouilleriez',
    'bidouillerons',
    'bidouilleront',
    'bienfaisances',
    'bienfaisantes',
    'bienfaitrices',
    'bienheureuses',
    'bien-pensante',
    'bien-pensants',
    'bienveillance',
    'bienveillante',
    'bienveillants',
    'bifurquassent',
    'bifurquassiez',
    'bifurquerions',
    'bigarrassions',
    'bigarreraient',
    'bigornassions',
    'bigorneraient',
    'billebaudâmes',
    'billebaudasse',
    'billebaudâtes',
    'billebauderai',
    'billebauderas',
    'billebauderez',
    'billebaudions',
    'billonnassent',
    'billonnassiez',
    'billonnerions',
    'biloquassions',
    'biloqueraient',
    'bimbeloteries',
    'bimestrielles',
    'bimétalliques',
    'bimétallismes',
    'bimétallistes',
    'bimillénaires',
    'bioacoustique',
    'bioclimatique',
    'biodégradable',
    'biogéographie',
    'biographiques',
    'biomécaniques',
    'biotypologies',
    'biquotidienne',
    'biréfringence',
    'biréfringente',
    'biréfringents',
    'biscuitassent',
    'biscuitassiez',
    'biscuiterions',
    'biseautassent',
    'biseautassiez',
    'biseauterions',
    'bisegmentâmes',
    'bisegmentasse',
    'bisegmentâtes',
    'bisegmenterai',
    'bisegmenteras',
    'bisegmenterez',
    'bisegmentions',
    'bissexualités',
    'bistournaient',
    'bistournasses',
    'bistournerais',
    'bistournerait',
    'bistournèrent',
    'bistourneriez',
    'bistournerons',
    'bistourneront',
    'bitturassions',
    'bittureraient',
    'bituminassent',
    'bituminassiez',
    'bituminerions',
    'bivouaquaient',
    'bivouaquasses',
    'bivouaquerais',
    'bivouaquerait',
    'bivouaquèrent',
    'bivouaqueriez',
    'bivouaquerons',
    'bivouaqueront',
    'blablatassent',
    'blablatassiez',
    'blablaterions',
    'blackboulages',
    'blackboulâmes',
    'blackboulasse',
    'blackboulâtes',
    'blackboulerai',
    'blackbouleras',
    'blackboulerez',
    'blackboulions',
    'blanchiraient',
    'blanchissages',
    'blanchissante',
    'blanchissants',
    'blanchisserie',
    'blanchisseurs',
    'blanchisseuse',
    'blanchissions',
    'blancs-estocs',
    'blancs-seings',
    'blasonnassent',
    'blasonnassiez',
    'blasonnerions',
    'blasphémaient',
    'blasphémasses',
    'blasphémateur',
    'blasphémerais',
    'blasphémerait',
    'blasphémèrent',
    'blasphémeriez',
    'blasphémerons',
    'blasphémeront',
    'blastogenèses',
    'blastomycoses',
    'blatérassions',
    'blatéreraient',
    'blêmissements',
    'blennorragies',
    'blettissaient',
    'blettissement',
    'bleuissements',
    'blocs-moteurs',
    'blondissaient',
    'blondoierions',
    'blondoyassent',
    'blondoyassiez',
    'blottissaient',
    'bocardassions',
    'bocarderaient',
    'boisselleries',
    'boitillassent',
    'boitillassiez',
    'boitillements',
    'boitillerions',
    'bolchévisâmes',
    'bolchévisasse',
    'bolchévisâtes',
    'bolchéviserai',
    'bolchéviseras',
    'bolchéviserez',
    'bolchévisions',
    'bombardassent',
    'bombardassiez',
    'bombardements',
    'bombarderions',
    'bonapartismes',
    'bonapartistes',
    'bondérisaient',
    'bondérisasses',
    'bondérisation',
    'bondériserais',
    'bondériserait',
    'bondérisèrent',
    'bondériseriez',
    'bondériserons',
    'bondériseront',
    'bondieuseries',
    'bondissements',
    'bondonnassent',
    'bondonnassiez',
    'bondonnerions',
    'bonifiassions',
    'bonifications',
    'bonifieraient',
    'bonimentaient',
    'bonimentasses',
    'bonimenterais',
    'bonimenterait',
    'bonimentèrent',
    'bonimenteriez',
    'bonimenterons',
    'bonimenteront',
    'bonimenteuses',
    'bonnes-mamans',
    'bornoieraient',
    'bornoyassions',
    'bosselassions',
    'bossellements',
    'bossellerions',
    'botanisassent',
    'botanisassiez',
    'botaniserions',
    'bottelassions',
    'bottellerions',
    'boucanassions',
    'boucaneraient',
    'bouchardaient',
    'bouchardasses',
    'boucharderais',
    'boucharderait',
    'bouchardèrent',
    'boucharderiez',
    'boucharderons',
    'boucharderont',
    'bouchonnaient',
    'bouchonnasses',
    'bouchonnerais',
    'bouchonnerait',
    'bouchonnèrent',
    'bouchonneriez',
    'bouchonnerons',
    'bouchonneront',
    'boudinassions',
    'boudineraient',
    'bouffissaient',
    'bouffonnaient',
    'bouffonnasses',
    'bouffonnerais',
    'bouffonnerait',
    'bouffonnèrent',
    'bouffonneries',
    'bouffonneriez',
    'bouffonnerons',
    'bouffonneront',
    'bougainvillée',
    'bougonnassent',
    'bougonnassiez',
    'bougonnements',
    'bougonnerions',
    'bouillabaisse',
    'bouilliraient',
    'bouillissions',
    'bouillonnâmes',
    'bouillonnante',
    'bouillonnants',
    'bouillonnasse',
    'bouillonnâtes',
    'bouillonnerai',
    'bouillonneras',
    'bouillonnerez',
    'bouillonnions',
    'bouillottâmes',
    'bouillottasse',
    'bouillottâtes',
    'bouillotterai',
    'bouillotteras',
    'bouillotterez',
    'bouillottions',
    'boulangeaient',
    'boulangeasses',
    'boulangerions',
    'boulevardière',
    'boulevardiers',
    'bouleversâmes',
    'bouleversante',
    'bouleversants',
    'bouleversasse',
    'bouleversâtes',
    'bouleverserai',
    'bouleverseras',
    'bouleverserez',
    'bouleversions',
    'boulonnassent',
    'boulonnassiez',
    'boulonnerions',
    'boulottassent',
    'boulottassiez',
    'boulotterions',
    'bouquinassent',
    'bouquinassiez',
    'bouquinerions',
    'bourboniennes',
    'bourdonnaient',
    'bourdonnantes',
    'bourdonnasses',
    'bourdonnement',
    'bourdonnerais',
    'bourdonnerait',
    'bourdonnèrent',
    'bourdonneriez',
    'bourdonnerons',
    'bourdonneront',
    'bourgeoisiale',
    'bourgeoisiaux',
    'bourgeonnâmes',
    'bourgeonnante',
    'bourgeonnants',
    'bourgeonnasse',
    'bourgeonnâtes',
    'bourgeonnerai',
    'bourgeonneras',
    'bourgeonnerez',
    'bourgeonnions',
    'bourguignonne',
    'bourlinguâmes',
    'bourlinguasse',
    'bourlinguâtes',
    'bourlinguerai',
    'bourlingueras',
    'bourlinguerez',
    'bourlingueurs',
    'bourlingueuse',
    'bourlinguions',
    'bourrelassent',
    'bourrelassiez',
    'bourrèlements',
    'bourrellerais',
    'bourrellerait',
    'bourrelleries',
    'bourrelleriez',
    'bourrellerons',
    'bourrelleront',
    'bourriquaient',
    'bourriquasses',
    'bourriquerais',
    'bourriquerait',
    'bourriquèrent',
    'bourriqueriez',
    'bourriquerons',
    'bourriqueront',
    'boursicotages',
    'boursicotâmes',
    'boursicotasse',
    'boursicotâtes',
    'boursicoterai',
    'boursicoteras',
    'boursicoterez',
    'boursicoteurs',
    'boursicoteuse',
    'boursicotière',
    'boursicotiers',
    'boursicotions',
    'boursouflages',
    'boursouflâmes',
    'boursouflasse',
    'boursouflâtes',
    'boursouflerai',
    'boursoufleras',
    'boursouflerez',
    'boursouflions',
    'boursouflures',
    'bousculassent',
    'bousculassiez',
    'bousculerions',
    'bousillassent',
    'bousillassiez',
    'bousillerions',
    'boustifaillai',
    'boustifaillas',
    'boustifaillât',
    'boustifailler',
    'boustifailles',
    'boustifaillez',
    'boustrophédon',
    'boutonnassent',
    'boutonnassiez',
    'boutonnerions',
    'bouts-dehiors',
    'bouturassions',
    'boutureraient',
    'boycottassent',
    'boycottassiez',
    'boycotterions',
    'brachycéphale',
    'braconnassent',
    'braconnassiez',
    'braconnerions',
    'braillassions',
    'brailleraient',
    'brainstorming',
    'brancardières',
    'branchassions',
    'brancheraient',
    'brandillaient',
    'brandillasses',
    'brandillerais',
    'brandillerait',
    'brandillèrent',
    'brandilleriez',
    'brandillerons',
    'brandilleront',
    'brandissaient',
    'brasillassent',
    'brasillassiez',
    'brasillerions',
    'bras-le-corps',
    'bredouillages',
    'bredouillâmes',
    'bredouillasse',
    'bredouillâtes',
    'bredouillerai',
    'bredouilleras',
    'bredouillerez',
    'bredouilleurs',
    'bredouilleuse',
    'bredouillions',
    'brésillassent',
    'brésillassiez',
    'brésillerions',
    'brettelassent',
    'brettelassiez',
    'brettellerais',
    'brettellerait',
    'brettelleriez',
    'brettellerons',
    'brettelleront',
    'brevetassions',
    'brevetterions',
    'bricolassions',
    'bricoleraient',
    'bridgeassions',
    'brigandassent',
    'brigandassiez',
    'briganderions',
    'brillantaient',
    'brillantantes',
    'brillantasses',
    'brillanterais',
    'brillanterait',
    'brillantèrent',
    'brillanteriez',
    'brillanterons',
    'brillanteront',
    'brillantinais',
    'brillantinait',
    'brillantinant',
    'brillantinées',
    'brillantinent',
    'brillantinera',
    'brillantiniez',
    'brillantinons',
    'brimbalassent',
    'brimbalassiez',
    'brimbalements',
    'brimbalerions',
    'brindezingues',
    'bringueballai',
    'bringueballas',
    'bringueballât',
    'bringueballée',
    'bringueballer',
    'bringueballes',
    'bringueballés',
    'bringueballez',
    'brinquebalais',
    'brinquebalait',
    'brinquebalant',
    'brinquebalées',
    'brinquebalent',
    'brinquebalera',
    'brinquebaliez',
    'brinqueballai',
    'brinqueballas',
    'brinqueballât',
    'brinqueballée',
    'brinqueballer',
    'brinqueballes',
    'brinqueballés',
    'brinqueballez',
    'brinquebalons',
    'briquetassent',
    'briquetassiez',
    'briquetterais',
    'briquetterait',
    'briquetteriez',
    'briquetterons',
    'briquetteront',
    'brocantassent',
    'brocantassiez',
    'brocanterions',
    'brocardassent',
    'brocardassiez',
    'brocarderions',
    'bromhydriques',
    'bronchassions',
    'bronchectasie',
    'broncheraient',
    'bronchiteuses',
    'bronchitiques',
    'bronchoscopie',
    'brouettassent',
    'brouettassiez',
    'brouetterions',
    'brouillaminis',
    'brouillassait',
    'brouillassent',
    'brouillassera',
    'brouillassiez',
    'brouillerions',
    'brouillonnais',
    'brouillonnait',
    'brouillonnant',
    'brouillonnées',
    'brouillonnent',
    'brouillonnera',
    'brouillonniez',
    'brouillonnons',
    'broussailleux',
    'brûle-parfums',
    'brunissements',
    'brusquassions',
    'brusqueraient',
    'brutalisaient',
    'brutalisasses',
    'brutaliserais',
    'brutaliserait',
    'brutalisèrent',
    'brutaliseriez',
    'brutaliserons',
    'brutaliseront',
    'bucco-génital',
    'budgétisaient',
    'budgétisasses',
    'budgétisation',
    'budgétiserais',
    'budgétiserait',
    'budgétisèrent',
    'budgétiseriez',
    'budgétiserons',
    'budgétiseront',
    'buissonneuses',
    'buissonnières',
    'bull-terriers',
    'bureaucraties',
    'bureaucratisa',
    'bureaucratise',
    'bureaucratisé',
    'burlesquement',
    'byzantinismes',
    'byzantinistes',
    'cabalistiques',
    'cabossassions',
    'cabosseraient',
    'cabotinassent',
    'cabotinassiez',
    'cabotinerions',
    'cabriolassent',
    'cabriolassiez',
    'cabriolerions',
    'cacardassions',
    'cacarderaient',
    'cachetassions',
    'cachetterions',
    'cacophoniques',
    'cadanchassent',
    'cadanchassiez',
    'cadancherions',
    'cadastrassent',
    'cadastrassiez',
    'cadastrerions',
    'cadenassaient',
    'cadenassasses',
    'cadenasserais',
    'cadenasserait',
    'cadenassèrent',
    'cadenasseriez',
    'cadenasserons',
    'cadenasseront',
    'cadençassions',
    'cadenceraient',
    'cafardassions',
    'cafarderaient',
    'cafouillaient',
    'cafouillasses',
    'cafouillerais',
    'cafouillerait',
    'cafouillèrent',
    'cafouilleriez',
    'cafouillerons',
    'cafouilleront',
    'cagnardassent',
    'cagnardassiez',
    'cagnarderions',
    'caillebottais',
    'caillebottait',
    'caillebottant',
    'caillebottées',
    'caillebottent',
    'caillebottera',
    'caillebottiez',
    'caillebottons',
    'cailletassent',
    'cailletassiez',
    'cailletterais',
    'cailletterait',
    'cailletteriez',
    'cailletterons',
    'cailletteront',
    'cailloutaient',
    'cailloutantes',
    'cailloutasses',
    'caillouterais',
    'caillouterait',
    'cailloutèrent',
    'caillouteriez',
    'caillouterons',
    'caillouteront',
    'caillouteuses',
    'calaminassent',
    'calaminassiez',
    'calaminerions',
    'calamistrâmes',
    'calamistrasse',
    'calamistrâtes',
    'calamistrerai',
    'calamistreras',
    'calamistrerez',
    'calamistrions',
    'calanchassent',
    'calanchassiez',
    'calancherions',
    'calandrassent',
    'calandrassiez',
    'calandrerions',
    'calcification',
    'calcinassions',
    'calcineraient',
    'calculassions',
    'calculatrices',
    'calculeraient',
    'calédoniennes',
    'caléidoscopes',
    'calembredaine',
    'caletteraient',
    'calfatassions',
    'calfateraient',
    'calfeutraient',
    'calfeutrasses',
    'calfeutrement',
    'calfeutrerais',
    'calfeutrerait',
    'calfeutrèrent',
    'calfeutreriez',
    'calfeutrerons',
    'calfeutreront',
    'calibrassions',
    'calibreraient',
    'calligraphiai',
    'calligraphias',
    'calligraphiât',
    'calligraphiée',
    'calligraphier',
    'calligraphies',
    'calligraphiés',
    'calligraphiez',
    'calomniassent',
    'calomniassiez',
    'calomniateurs',
    'calomniatrice',
    'calomnierions',
    'calorifugeais',
    'calorifugeait',
    'calorifugeant',
    'calorifugeons',
    'calorifugerai',
    'calorifugeras',
    'calorifugerez',
    'calorifugions',
    'calorimétries',
    'calorisations',
    'calottassions',
    'calotteraient',
    'cambodgiennes',
    'cambriolaient',
    'cambriolasses',
    'cambriolerais',
    'cambriolerait',
    'cambriolèrent',
    'cambrioleriez',
    'cambriolerons',
    'cambrioleront',
    'cambrioleuses',
    'cambutassions',
    'cambuteraient',
    'camelotassent',
    'camelotassiez',
    'cameloterions',
    'camionnassent',
    'camionnassiez',
    'camionnerions',
    'camouflassent',
    'camouflassiez',
    'camouflerions',
    'canadianismes',
    'canalisassent',
    'canalisassiez',
    'canalisations',
    'canaliserions',
    'canardassions',
    'canarderaient',
    'cancanassions',
    'cancaneraient',
    'cancérisation',
    'cancérologues',
    'cannelassions',
    'cannellerions',
    'cannibalismes',
    'canonisassent',
    'canonisassiez',
    'canonisations',
    'canoniserions',
    'canonnassions',
    'canonneraient',
    'cantharidines',
    'cantonnassent',
    'cantonnassiez',
    'cantonnements',
    'cantonnerions',
    'caoutchoutage',
    'caoutchoutais',
    'caoutchoutait',
    'caoutchoutant',
    'caoutchoutées',
    'caoutchoutent',
    'caoutchoutera',
    'caoutchouteux',
    'caoutchoutiez',
    'caoutchoutons',
    'caparaçonnais',
    'caparaçonnait',
    'caparaçonnant',
    'caparaçonnées',
    'caparaçonnent',
    'caparaçonnera',
    'caparaçonniez',
    'caparaçonnons',
    'capelleraient',
    'capitaineries',
    'capitalisable',
    'capitalisâmes',
    'capitalisante',
    'capitalisants',
    'capitalisasse',
    'capitalisâtes',
    'capitaliserai',
    'capitaliseras',
    'capitaliserez',
    'capitalisions',
    'capitonnaient',
    'capitonnasses',
    'capitonnerais',
    'capitonnerait',
    'capitonnèrent',
    'capitonneriez',
    'capitonnerons',
    'capitonneront',
    'capitulassent',
    'capitulassiez',
    'capitulations',
    'capitulerions',
    'caponnassions',
    'caponneraient',
    'caporalisâmes',
    'caporalisasse',
    'caporalisâtes',
    'caporaliserai',
    'caporaliseras',
    'caporaliserez',
    'caporalisions',
    'capsulassions',
    'capsuleraient',
    'captieusement',
    'captivassions',
    'captiveraient',
    'capturassions',
    'captureraient',
    'capuchonnâmes',
    'capuchonnasse',
    'capuchonnâtes',
    'capuchonnerai',
    'capuchonneras',
    'capuchonnerez',
    'capuchonnions',
    'caquetassions',
    'caquetterions',
    'caracolassent',
    'caracolassiez',
    'caracolerions',
    'caractérielle',
    'caractérisais',
    'caractérisait',
    'caractérisant',
    'caractérisées',
    'caractérisent',
    'caractérisera',
    'caractérisiez',
    'caractérisons',
    'carambolaient',
    'carambolasses',
    'carambolerais',
    'carambolerait',
    'carambolèrent',
    'caramboleriez',
    'carambolerons',
    'caramboleront',
    'caramélisâmes',
    'caramélisasse',
    'caramélisâtes',
    'caraméliserai',
    'caraméliseras',
    'caraméliserez',
    'caramélisions',
    'carapatassent',
    'carapatassiez',
    'carapaterions',
    'caravansérail',
    'carbonataient',
    'carbonatasses',
    'carbonatation',
    'carbonaterais',
    'carbonaterait',
    'carbonatèrent',
    'carbonateriez',
    'carbonaterons',
    'carbonateront',
    'carbonisaient',
    'carbonisasses',
    'carbonisation',
    'carboniserais',
    'carboniserait',
    'carbonisèrent',
    'carboniseriez',
    'carboniserons',
    'carboniseront',
    'carburassions',
    'carburéacteur',
    'carbureraient',
    'carcaillaient',
    'carcaillasses',
    'carcaillerais',
    'carcaillerait',
    'carcaillèrent',
    'carcailleriez',
    'carcaillerons',
    'carcailleront',
    'carcinologies',
    'cardiogrammes',
    'cardiographes',
    'cardiographie',
    'cardiopathies',
    'cardiotonique',
    'carençassions',
    'carenceraient',
    'caressassions',
    'caresseraient',
    'caricaturales',
    'caricaturâmes',
    'caricaturasse',
    'caricaturâtes',
    'caricaturerai',
    'caricatureras',
    'caricaturerez',
    'caricaturions',
    'caricaturiste',
    'carillonnâmes',
    'carillonnasse',
    'carillonnâtes',
    'carillonnerai',
    'carillonneras',
    'carillonnerez',
    'carillonneurs',
    'carillonnions',
    'carminassions',
    'carmineraient',
    'carnavalesque',
    'carnifiassent',
    'carnifiassiez',
    'carnification',
    'carnifierions',
    'carolingienne',
    'carottassions',
    'carotteraient',
    'carrelassions',
    'carrellerions',
    'carroieraient',
    'carrossassent',
    'carrossassiez',
    'carrosserions',
    'carroyassions',
    'carte-réponse',
    'cartésianisme',
    'carthaginoise',
    'cartilagineux',
    'cartographies',
    'cartomanciens',
    'cartonnassent',
    'cartonnassiez',
    'cartonnerions',
    'cartons-pâtes',
    'cartoucheries',
    'cartouchières',
    'cascadassions',
    'cascaderaient',
    'caséfiassions',
    'caséfieraient',
    'casematassent',
    'casematassiez',
    'casematerions',
    'casernassions',
    'caserneraient',
    'casse-pierres',
    'castagnassent',
    'castagnassiez',
    'castagnerions',
    'catalectiques',
    'cataleptiques',
    'cataloguaient',
    'cataloguasses',
    'cataloguerais',
    'cataloguerait',
    'cataloguèrent',
    'catalogueriez',
    'cataloguerons',
    'catalogueront',
    'catalysassent',
    'catalysassiez',
    'catalyserions',
    'catapultaient',
    'catapultasses',
    'catapulterais',
    'catapulterait',
    'catapultèrent',
    'catapulteriez',
    'catapulterons',
    'catapulteront',
    'catastrophais',
    'catastrophait',
    'catastrophant',
    'catastrophées',
    'catastrophent',
    'catastrophera',
    'catastrophiez',
    'catastrophons',
    'catéchisaient',
    'catéchisasses',
    'catéchiserais',
    'catéchiserait',
    'catéchisèrent',
    'catéchiseriez',
    'catéchiserons',
    'catéchiseront',
    'catéchistique',
    'catéchuménats',
    'catégorielles',
    'catherinettes',
    'cathétérismes',
    'catholicismes',
    'cauchemardais',
    'cauchemardait',
    'cauchemardant',
    'cauchemardent',
    'cauchemardera',
    'cauchemardeux',
    'cauchemardiez',
    'cauchemardons',
    'cautérisaient',
    'cautérisantes',
    'cautérisasses',
    'cautérisation',
    'cautériserais',
    'cautériserait',
    'cautérisèrent',
    'cautériseriez',
    'cautériserons',
    'cautériseront',
    'cautionnaient',
    'cautionnasses',
    'cautionnement',
    'cautionnerais',
    'cautionnerait',
    'cautionnèrent',
    'cautionneriez',
    'cautionnerons',
    'cautionneront',
    'cavalcadaient',
    'cavalcadasses',
    'cavalcaderais',
    'cavalcaderait',
    'cavalcadèrent',
    'cavalcaderiez',
    'cavalcaderons',
    'cavalcaderont',
    'cavalièrement',
    'caviardassent',
    'caviardassiez',
    'caviarderions',
    'ceinturassent',
    'ceinturassiez',
    'ceinturerions',
    'célébrassions',
    'célébreraient',
    'cellulosiques',
    'cémentassions',
    'cémenteraient',
    'cénesthésique',
    'censurassions',
    'censureraient',
    'centralisâmes',
    'centralisasse',
    'centralisâtes',
    'centraliserai',
    'centraliseras',
    'centraliserez',
    'centralisions',
    'centrifugeais',
    'centrifugeait',
    'centrifugeant',
    'centrifugeons',
    'centrifugerai',
    'centrifugeras',
    'centrifugerez',
    'centrifugeurs',
    'centrifugeuse',
    'centrifugions',
    'centuplassent',
    'centuplassiez',
    'centuplerions',
    'céphalothorax',
    'cercopithèque',
    'cérébelleuses',
    'cérémonielles',
    'cérémonieuses',
    'cerfs-volants',
    'certifiassent',
    'certifiassiez',
    'certificateur',
    'certification',
    'certifierions',
    'cessez-le-feu',
    'cessionnaires',
    'chagrinassent',
    'chagrinassiez',
    'chagrinerions',
    'chahutassions',
    'chahuteraient',
    'chalcographie',
    'chalcopyrites',
    'challengeâmes',
    'challengeasse',
    'challengeâtes',
    'challengerais',
    'challengerait',
    'challengèrent',
    'challengeriez',
    'challengerons',
    'challengeront',
    'chaloupassent',
    'chaloupassiez',
    'chalouperions',
    'chamaillaient',
    'chamaillasses',
    'chamaillerais',
    'chamaillerait',
    'chamaillèrent',
    'chamailleries',
    'chamailleriez',
    'chamaillerons',
    'chamailleront',
    'chamailleuses',
    'chamarrassent',
    'chamarrassiez',
    'chamarrerions',
    'chambardaient',
    'chambardasses',
    'chambardement',
    'chambarderais',
    'chambarderait',
    'chambardèrent',
    'chambarderiez',
    'chambarderons',
    'chambarderont',
    'chamboulaient',
    'chamboulasses',
    'chamboulement',
    'chamboulerais',
    'chamboulerait',
    'chamboulèrent',
    'chambouleriez',
    'chamboulerons',
    'chambouleront',
    'chambrassions',
    'chambreraient',
    'chamoisassent',
    'chamoisassiez',
    'chamoiserions',
    'champagnisais',
    'champagnisait',
    'champagnisant',
    'champagnisées',
    'champagnisent',
    'champagnisera',
    'champagnisiez',
    'champagnisons',
    'champlevaient',
    'champlevasses',
    'champlèverais',
    'champlèverait',
    'champlevèrent',
    'champlèveriez',
    'champlèverons',
    'champlèveront',
    'chancelassent',
    'chancelassiez',
    'chancellerais',
    'chancellerait',
    'chancelleries',
    'chancelleriez',
    'chancellerons',
    'chancelleront',
    'chancissaient',
    'chanfreinâmes',
    'chanfreinasse',
    'chanfreinâtes',
    'chanfreinerai',
    'chanfreineras',
    'chanfreinerez',
    'chanfreinions',
    'changeassions',
    'chansonnaient',
    'chansonnasses',
    'chansonnerais',
    'chansonnerait',
    'chansonnèrent',
    'chansonneriez',
    'chansonnerons',
    'chansonneront',
    'chansonnettes',
    'chansonnières',
    'chanstiquâmes',
    'chanstiquasse',
    'chanstiquâtes',
    'chanstiquerai',
    'chanstiqueras',
    'chanstiquerez',
    'chanstiquions',
    'chantepleures',
    'chantonnaient',
    'chantonnasses',
    'chantonnement',
    'chantonnerais',
    'chantonnerait',
    'chantonnèrent',
    'chantonneriez',
    'chantonnerons',
    'chantonneront',
    'chantournâmes',
    'chantournasse',
    'chantournâtes',
    'chantournerai',
    'chantourneras',
    'chantournerez',
    'chantournions',
    'chapardassent',
    'chapardassiez',
    'chaparderions',
    'chapeautaient',
    'chapeautasses',
    'chapeauterais',
    'chapeauterait',
    'chapeautèrent',
    'chapeauteriez',
    'chapeauterons',
    'chapeauteront',
    'chapelassions',
    'chapellerions',
    'chaperonnâmes',
    'chaperonnasse',
    'chaperonnâtes',
    'chaperonnerai',
    'chaperonneras',
    'chaperonnerez',
    'chaperonnions',
    'chapitrassent',
    'chapitrassiez',
    'chapitrerions',
    'chaplinesques',
    'chaponnassent',
    'chaponnassiez',
    'chaponnerions',
    'chaptalisâmes',
    'chaptalisasse',
    'chaptalisâtes',
    'chaptaliserai',
    'chaptaliseras',
    'chaptaliserez',
    'chaptalisions',
    'charançonnées',
    'charbonnaient',
    'charbonnasses',
    'charbonnerais',
    'charbonnerait',
    'charbonnèrent',
    'charbonneriez',
    'charbonnerons',
    'charbonneront',
    'charbonneuses',
    'charbonnières',
    'charcutassent',
    'charcutassiez',
    'charcuterions',
    'chardonnerets',
    'chargeassions',
    'charismatique',
    'charlatanerie',
    'charlatanisme',
    'charnellement',
    'charpentaient',
    'charpentasses',
    'charpenterais',
    'charpenterait',
    'charpentèrent',
    'charpenteries',
    'charpenteriez',
    'charpenterons',
    'charpenteront',
    'charriassions',
    'charrieraient',
    'charroierions',
    'charroyassent',
    'charroyassiez',
    'chassé-croisé',
    'châtaigneraie',
    'chateaubriand',
    'chateaubriant',
    'châteaubriant',
    'chatoieraient',
    'chatonnassent',
    'chatonnassiez',
    'chatonnerions',
    'chatouillâmes',
    'chatouillasse',
    'chatouillâtes',
    'chatouillerai',
    'chatouilleras',
    'chatouillerez',
    'chatouilleuse',
    'chatouillions',
    'chatoyassions',
    'chaudronnerie',
    'chaudronnière',
    'chaudronniers',
    'chauds-froids',
    'chauffagistes',
    'chauffassions',
    'chauffe-bains',
    'chauffe-pieds',
    'chauffe-plats',
    'chaufferaient',
    'chaufferettes',
    'chaufourniers',
    'chaussassions',
    'chausse-pieds',
    'chausseraient',
    'chausse-trape',
    'chauve-souris',
    'chauvissaient',
    'chavirassions',
    'chavireraient',
    'chelinguaient',
    'chelinguasses',
    'chelinguerais',
    'chelinguerait',
    'chelinguèrent',
    'chelingueriez',
    'chelinguerons',
    'chelingueront',
    'cheminassions',
    'chemineraient',
    'chemisassions',
    'chemiseraient',
    'chênes-lièges',
    'cherchassions',
    'chercheraient',
    'chevalassions',
    'chevaleraient',
    'chevaleresque',
    'cheval-vapeur',
    'chevauchaient',
    'chevauchasses',
    'chevauchement',
    'chevaucherais',
    'chevaucherait',
    'chevauchèrent',
    'chevaucheriez',
    'chevaucherons',
    'chevaucheront',
    'chevillassent',
    'chevillassiez',
    'chevillerions',
    'chèvrefeuille',
    'chevretassent',
    'chevretassiez',
    'chevretterais',
    'chevretterait',
    'chevretteriez',
    'chevretterons',
    'chevretteront',
    'chevronnaient',
    'chevronnasses',
    'chevronnerais',
    'chevronnerait',
    'chevronnèrent',
    'chevronneriez',
    'chevronnerons',
    'chevronneront',
    'chevrotassent',
    'chevrotassiez',
    'chevrotements',
    'chevroterions',
    'chicanassions',
    'chicaneraient',
    'chicotassions',
    'chicoteraient',
    'chiennassions',
    'chienneraient',
    'chiffonnaient',
    'chiffonnasses',
    'chiffonnement',
    'chiffonnerais',
    'chiffonnerait',
    'chiffonnèrent',
    'chiffonneriez',
    'chiffonnerons',
    'chiffonneront',
    'chiffonnières',
    'chiffrassions',
    'chiffreraient',
    'chinoisassent',
    'chinoisassiez',
    'chinoiserions',
    'chipotassions',
    'chipoteraient',
    'chirographiai',
    'chirographias',
    'chirographiât',
    'chirographiée',
    'chirographier',
    'chirographies',
    'chirographiés',
    'chirographiez',
    'chiromanciens',
    'chiropracteur',
    'chiropracties',
    'chirurgicales',
    'chirurgiennes',
    'chlinguassent',
    'chlinguassiez',
    'chlinguerions',
    'chlorhydrates',
    'chlorhydrique',
    'chloroformais',
    'chloroformait',
    'chloroformant',
    'chloroformées',
    'chloroforment',
    'chloroformera',
    'chloroformiez',
    'chloroformons',
    'chlorophylles',
    'chloropicrine',
    'chlorurassent',
    'chlorurassiez',
    'chlorurerions',
    'chocolateries',
    'chocolatières',
    'choisissaient',
    'cholécystites',
    'cholériformes',
    'chondriosomes',
    'chondroblaste',
    'chopinassions',
    'chopineraient',
    'chorégraphies',
    'choroïdiennes',
    'chosifiassent',
    'chosifiassiez',
    'chosification',
    'chosifierions',
    'chouchoutâmes',
    'chouchoutasse',
    'chouchoutâtes',
    'chouchouterai',
    'chouchouteras',
    'chouchouterez',
    'chouchoutions',
    'chouravassent',
    'chouravassiez',
    'chouraverions',
    'chourinassent',
    'chourinassiez',
    'chourinerions',
    'chrestomathie',
    'christianisai',
    'christianisas',
    'christianisât',
    'christianisée',
    'christianiser',
    'christianises',
    'christianisés',
    'christianisez',
    'christianisme',
    'christologies',
    'chromatopsies',
    'chromosomique',
    'chromosphères',
    'chroniquaient',
    'chroniquasses',
    'chroniquement',
    'chroniquerais',
    'chroniquerait',
    'chroniquèrent',
    'chroniqueriez',
    'chroniquerons',
    'chroniqueront',
    'chroniqueuses',
    'chronologique',
    'chronométrage',
    'chronométrais',
    'chronométrait',
    'chronométrant',
    'chronométrées',
    'chronomètrent',
    'chronométrera',
    'chronométreur',
    'chronométries',
    'chronométriez',
    'chronométrons',
    'chroumassions',
    'chrysanthèmes',
    'chuchotassent',
    'chuchotassiez',
    'chuchotements',
    'chuchoterions',
    'chuintassions',
    'chuinteraient',
    'cicatricielle',
    'cicatrisables',
    'cicatrisaient',
    'cicatrisantes',
    'cicatrisasses',
    'cicatrisation',
    'cicatriserais',
    'cicatriserait',
    'cicatrisèrent',
    'cicatriseriez',
    'cicatriserons',
    'cicatriseront',
    'cicéroniennes',
    'cimentassions',
    'cimenteraient',
    'cinémathèques',
    'cinquantaines',
    'cinquante-six',
    'cinquantièmes',
    'cinquièmement',
    'circoncirions',
    'circoncisions',
    'circoncissent',
    'circoncissiez',
    'circonférence',
    'circonscrirai',
    'circonscriras',
    'circonscrirez',
    'circonscrites',
    'circonscrives',
    'circonscrivez',
    'circonscrivis',
    'circonscrivit',
    'circonscrivît',
    'circonspectes',
    'circonstances',
    'circonstancia',
    'circonstancie',
    'circonstancié',
    'circonvenions',
    'circonviendra',
    'circonviennes',
    'circonvinrent',
    'circonvinsses',
    'circulassions',
    'circulatoires',
    'circuleraient',
    'circumduction',
    'circumpolaire',
    'cisaillassent',
    'cisaillassiez',
    'cisaillements',
    'cisaillerions',
    'cisterciennes',
    'cités-jardins',
    'civilisassent',
    'civilisassiez',
    'civilisateurs',
    'civilisations',
    'civilisatrice',
    'civiliserions',
    'clabaudassent',
    'clabaudassiez',
    'clabauderions',
    'clabotassions',
    'claboteraient',
    'claires-voies',
    'claironnaient',
    'claironnantes',
    'claironnasses',
    'claironnerais',
    'claironnerait',
    'claironnèrent',
    'claironneriez',
    'claironnerons',
    'claironneront',
    'clairvoyances',
    'clairvoyantes',
    'clampsassions',
    'clampseraient',
    'clandestinité',
    'clapotassions',
    'clapoteraient',
    'claquemurâmes',
    'claquemurasse',
    'claquemurâtes',
    'claquemurerai',
    'claquemureras',
    'claquemurerez',
    'claquemurions',
    'claquetassent',
    'claquetassiez',
    'claquetterais',
    'claquetterait',
    'claquetteriez',
    'claquetterons',
    'claquetteront',
    'clarifiassent',
    'clarifiassiez',
    'clarification',
    'clarifierions',
    'clarinettiste',
    'classifiaient',
    'classifiasses',
    'classifierais',
    'classifierait',
    'classifièrent',
    'classifieriez',
    'classifierons',
    'classifieront',
    'classiquement',
    'claudications',
    'claudiquaient',
    'claudiquasses',
    'claudiquerais',
    'claudiquerait',
    'claudiquèrent',
    'claudiqueriez',
    'claudiquerons',
    'claudiqueront',
    'claustrassent',
    'claustrassiez',
    'claustrations',
    'claustrerions',
    'claustrophobe',
    'clavecinistes',
    'clavetassions',
    'clavettassent',
    'clavettassiez',
    'clavetterions',
    'clayonnassent',
    'clayonnassiez',
    'clayonnerions',
    'clémentiniers',
    'cléricalismes',
    'clignotassent',
    'clignotassiez',
    'clignotements',
    'clignoterions',
    'climatériques',
    'climatisaient',
    'climatisantes',
    'climatisasses',
    'climatisation',
    'climatiserais',
    'climatiserait',
    'climatisèrent',
    'climatiseriez',
    'climatiserons',
    'climatiseront',
    'climatologies',
    'climatologues',
    'cliquetassent',
    'cliquetassiez',
    'cliquètements',
    'cliquettement',
    'cliquetterais',
    'cliquetterait',
    'cliquetteriez',
    'cliquetterons',
    'cliquetteront',
    'clitoridienne',
    'clochardisais',
    'clochardisait',
    'clochardisant',
    'clochardisées',
    'clochardisent',
    'clochardisera',
    'clochardisiez',
    'clochardisons',
    'cloisonnaient',
    'cloisonnantes',
    'cloisonnasses',
    'cloisonnement',
    'cloisonnerais',
    'cloisonnerait',
    'cloisonnèrent',
    'cloisonneriez',
    'cloisonnerons',
    'cloisonneront',
    'cloîtrassions',
    'cloîtreraient',
    'clopinassions',
    'clopineraient',
    'clôturassions',
    'clôtureraient',
    'coagulabilité',
    'coagulassions',
    'coagulatrices',
    'coaguleraient',
    'coalisassions',
    'coaliseraient',
    'cobelligérant',
    'cocaïnisation',
    'cocaïnomanies',
    'cochonnailles',
    'cochonnassent',
    'cochonnassiez',
    'cochonnerions',
    'cocontractant',
    'cocréancières',
    'cocufiassions',
    'cocufieraient',
    'codétentrices',
    'codifiassions',
    'codificateurs',
    'codifications',
    'codifieraient',
    'codirectrices',
    'coelioscopies',
    'coercibilités',
    'coexistassent',
    'coexistassiez',
    'coexisterions',
    'coffres-forts',
    'cohabitassent',
    'cohabitassiez',
    'cohabitations',
    'cohabiterions',
    'cohéritassent',
    'cohéritassiez',
    'cohériterions',
    'coïncidassent',
    'coïncidassiez',
    'coïnciderions',
    'cokéfiassions',
    'cokéfieraient',
    'colibacillose',
    'colin-tampons',
    'collaboraient',
    'collaborasses',
    'collaborateur',
    'collaboration',
    'collaborerais',
    'collaborerait',
    'collaborèrent',
    'collaboreriez',
    'collaborerons',
    'collaboreront',
    'collationnais',
    'collationnait',
    'collationnant',
    'collationnées',
    'collationnent',
    'collationnera',
    'collationniez',
    'collationnons',
    'collectassent',
    'collectassiez',
    'collecterions',
    'collectionnai',
    'collectionnas',
    'collectionnât',
    'collectionnée',
    'collectionner',
    'collectionnes',
    'collectionnés',
    'collectionnez',
    'collectivisai',
    'collectivisas',
    'collectivisât',
    'collectivisée',
    'collectiviser',
    'collectivises',
    'collectivisés',
    'collectivisez',
    'collectivisme',
    'collectiviste',
    'collectivités',
    'collégialités',
    'colletassions',
    'colletterions',
    'colligeassent',
    'colligeassiez',
    'colligeraient',
    'colloquassent',
    'colloquassiez',
    'colloquerions',
    'colmatassions',
    'colmateraient',
    'cologarithmes',
    'colombophiles',
    'colombophilie',
    'colonialismes',
    'colonialistes',
    'colonisassent',
    'colonisassiez',
    'colonisateurs',
    'colonisations',
    'colonisatrice',
    'coloniserions',
    'coloriassions',
    'colorieraient',
    'colorimétries',
    'colorisations',
    'colossalement',
    'colportassent',
    'colportassiez',
    'colporterions',
    'cols-de-cygne',
    'coltinassions',
    'coltineraient',
    'combattissent',
    'combattissiez',
    'combattraient',
    'combientièmes',
    'combinassions',
    'combinatoires',
    'combineraient',
    'comblanchiens',
    'comestibilité',
    'commandassent',
    'commandassiez',
    'commandements',
    'commanderions',
    'commanditaire',
    'commanditâmes',
    'commanditasse',
    'commanditâtes',
    'commanditerai',
    'commanditeras',
    'commanditerez',
    'commanditions',
    'commémoraient',
    'commémoraison',
    'commémorasses',
    'commémoratifs',
    'commémoration',
    'commémorative',
    'commémorerais',
    'commémorerait',
    'commémorèrent',
    'commémoreriez',
    'commémorerons',
    'commémoreront',
    'commençassent',
    'commençassiez',
    'commencements',
    'commencerions',
    'commendataire',
    'commensalisme',
    'commentassent',
    'commentassiez',
    'commentateurs',
    'commentatrice',
    'commenterions',
    'commérassions',
    'commerçassent',
    'commerçassiez',
    'commercerions',
    'commercialisa',
    'commercialise',
    'commercialisé',
    'comméreraient',
    'commettraient',
    'comminatoires',
    'commisération',
    'commissariats',
    'commissionnai',
    'commissionnas',
    'commissionnât',
    'commissionnée',
    'commissionner',
    'commissionnes',
    'commissionnés',
    'commissionnez',
    'commissurales',
    'commotionnais',
    'commotionnait',
    'commotionnant',
    'commotionnées',
    'commotionnent',
    'commotionnera',
    'commotionniez',
    'commotionnons',
    'communalisais',
    'communalisait',
    'communalisant',
    'communalisées',
    'communalisent',
    'communalisera',
    'communalisiez',
    'communalisons',
    'communautaire',
    'communiassent',
    'communiassiez',
    'communicables',
    'communicantes',
    'communicateur',
    'communicatifs',
    'communication',
    'communicative',
    'communierions',
    'communiquâmes',
    'communiquante',
    'communiquants',
    'communiquasse',
    'communiquâtes',
    'communiquerai',
    'communiqueras',
    'communiquerez',
    'communiquions',
    'communisantes',
    'commutassions',
    'commutativité',
    'commuteraient',
    'compagnonnage',
    'comparabilité',
    'comparaissais',
    'comparaissait',
    'comparaissant',
    'comparaissent',
    'comparaissiez',
    'comparaissons',
    'comparaîtrais',
    'comparaîtrait',
    'comparaîtriez',
    'comparaîtrons',
    'comparaîtront',
    'comparassions',
    'comparatismes',
    'comparatistes',
    'comparatrices',
    'compareraient',
    'compartimenta',
    'compartimente',
    'compartimenté',
    'compartiments',
    'comparussions',
    'compassassent',
    'compassassiez',
    'compasserions',
    'compatibilité',
    'compatiraient',
    'compatissante',
    'compatissants',
    'compatissions',
    'compendieuses',
    'compensassent',
    'compensassiez',
    'compensateurs',
    'compensations',
    'compensatoire',
    'compensatrice',
    'compenserions',
    'compétassions',
    'compéteraient',
    'compétitivité',
    'compétitrices',
    'compilassions',
    'compilatrices',
    'compileraient',
    'compissassent',
    'compissassiez',
    'compisserions',
    'complairaient',
    'complaisaient',
    'complaisances',
    'complaisantes',
    'complétassent',
    'complétassiez',
    'compléterions',
    'complexassent',
    'complexassiez',
    'complexerions',
    'complexifiais',
    'complexifiait',
    'complexifiant',
    'complexifiées',
    'complexifient',
    'complexifiera',
    'complexifiiez',
    'complexifions',
    'complications',
    'complimentais',
    'complimentait',
    'complimentant',
    'complimentées',
    'complimentent',
    'complimentera',
    'complimenteur',
    'complimentiez',
    'complimentons',
    'compliquaient',
    'compliquasses',
    'compliquerais',
    'compliquerait',
    'compliquèrent',
    'compliqueriez',
    'compliquerons',
    'compliqueront',
    'complotassent',
    'complotassiez',
    'comportassent',
    'comportassiez',
    'comportements',
    'comporterions',
    'composassions',
    'composerasses',
    'composerèrent',
    'compositrices',
    'compostassent',
    'compostassiez',
    'composterions',
    'compréhensifs',
    'compréhension',
    'compréhensive',
    'comprendrions',
    'compressaient',
    'compressasses',
    'compresserais',
    'compresserait',
    'compressèrent',
    'compresseriez',
    'compresserons',
    'compresseront',
    'compressibles',
    'comprimassent',
    'comprimassiez',
    'comprimerions',
    'compromettais',
    'compromettait',
    'compromettant',
    'compromettent',
    'compromettiez',
    'compromettons',
    'compromettrai',
    'compromettras',
    'compromettrez',
    'compromissent',
    'compromissiez',
    'compromission',
    'comptabilisai',
    'comptabilisas',
    'comptabilisât',
    'comptabilisée',
    'comptabiliser',
    'comptabilises',
    'comptabilisés',
    'comptabilisez',
    'comptabilités',
    'compulsassent',
    'compulsassiez',
    'compulserions',
    'computassions',
    'computeraient',
    'concassassent',
    'concassassiez',
    'concasserions',
    'concaténaient',
    'concaténasses',
    'concaténation',
    'concaténerais',
    'concaténerait',
    'concaténèrent',
    'concaténeriez',
    'concaténerons',
    'concaténeront',
    'concédassions',
    'concéderaient',
    'concélébrâmes',
    'concélébrasse',
    'concélébrâtes',
    'concélébrerai',
    'concélébreras',
    'concélébrerez',
    'concélébrions',
    'concentraient',
    'concentrasses',
    'concentration',
    'concentrerais',
    'concentrerait',
    'concentrèrent',
    'concentreriez',
    'concentrerons',
    'concentreront',
    'concentriques',
    'conceptualisa',
    'conceptualise',
    'conceptualisé',
    'conceptuelles',
    'concernassent',
    'concernassiez',
    'concernerions',
    'concertassent',
    'concertassiez',
    'concertations',
    'concerterions',
    'conchyliennes',
    'conciergeries',
    'conciliabules',
    'conciliassent',
    'conciliassiez',
    'conciliateurs',
    'conciliations',
    'conciliatoire',
    'conciliatrice',
    'concilierions',
    'concitoyennes',
    'concoctassent',
    'concoctassiez',
    'concocterions',
    'concomitances',
    'concomitantes',
    'concordassent',
    'concordassiez',
    'concordataire',
    'concorderions',
    'concourraient',
    'concourussent',
    'concourussiez',
    'concrétassent',
    'concrétassiez',
    'concréterions',
    'concrétisâmes',
    'concrétisasse',
    'concrétisâtes',
    'concrétiserai',
    'concrétiseras',
    'concrétiserez',
    'concrétisions',
    'concupiscence',
    'concupiscente',
    'concupiscents',
    'concurremment',
    'concurrençais',
    'concurrençait',
    'concurrençant',
    'concurrencées',
    'concurrencent',
    'concurrencera',
    'concurrenciez',
    'concurrençons',
    'concurrentiel',
    'condamnassent',
    'condamnassiez',
    'condamnations',
    'condamnatoire',
    'condamnerions',
    'condensassent',
    'condensassiez',
    'condensateurs',
    'condensations',
    'condenserions',
    'condescendais',
    'condescendait',
    'condescendant',
    'condescendent',
    'condescendiez',
    'condescendons',
    'condescendrai',
    'condescendras',
    'condescendrez',
    'condescendues',
    'conditionnais',
    'conditionnait',
    'conditionnant',
    'conditionnées',
    'conditionnels',
    'conditionnent',
    'conditionnera',
    'conditionneur',
    'conditionniez',
    'conditionnons',
    'conductivités',
    'conduisissent',
    'conduisissiez',
    'confectionnai',
    'confectionnas',
    'confectionnât',
    'confectionnée',
    'confectionner',
    'confectionnes',
    'confectionnés',
    'confectionnez',
    'confédéraient',
    'confédérasses',
    'confédération',
    'confédérerais',
    'confédérerait',
    'confédérèrent',
    'confédéreriez',
    'confédérerons',
    'confédéreront',
    'conférassions',
    'conférencière',
    'conférenciers',
    'conféreraient',
    'confessassent',
    'confessassiez',
    'confesserions',
    'confessionnal',
    'confessionnel',
    'confidentiels',
    'configuraient',
    'configurasses',
    'configuration',
    'configurerais',
    'configurerait',
    'configurèrent',
    'configureriez',
    'configurerons',
    'configureront',
    'confinassions',
    'confineraient',
    'confirmassent',
    'confirmassiez',
    'confirmations',
    'confirmatives',
    'confirmerions',
    'confiscations',
    'confisquaient',
    'confisquasses',
    'confisquerais',
    'confisquerait',
    'confisquèrent',
    'confisqueriez',
    'confisquerons',
    'confisqueront',
    'confitureries',
    'confiturières',
    'conflagration',
    'conflictuelle',
    'confluassions',
    'conflueraient',
    'confondissent',
    'confondissiez',
    'confondraient',
    'conformassent',
    'conformassiez',
    'conformateurs',
    'conformations',
    'conformerions',
    'confortassent',
    'confortassiez',
    'conforterions',
    'confraternels',
    'confraternité',
    'confrontaient',
    'confrontasses',
    'confrontation',
    'confronterais',
    'confronterait',
    'confrontèrent',
    'confronteriez',
    'confronterons',
    'confronteront',
    'confucianisme',
    'confucianiste',
    'confusionnels',
    'congédiassent',
    'congédiassiez',
    'congédiements',
    'congédierions',
    'congelassions',
    'congèleraient',
    'congestionnai',
    'congestionnas',
    'congestionnât',
    'congestionnée',
    'congestionner',
    'congestionnes',
    'congestionnés',
    'congestionnez',
    'conglomérâmes',
    'conglomérasse',
    'conglomérâtes',
    'conglomérerai',
    'congloméreras',
    'conglomérerez',
    'conglomérions',
    'conglutinâmes',
    'conglutinasse',
    'conglutinâtes',
    'conglutinerai',
    'conglutineras',
    'conglutinerez',
    'conglutinions',
    'congratulâmes',
    'congratulasse',
    'congratulâtes',
    'congratulerai',
    'congratuleras',
    'congratulerez',
    'congratulions',
    'congréassions',
    'congréeraient',
    'congréganiste',
    'congrégations',
    'congressistes',
    'conjecturales',
    'conjecturâmes',
    'conjecturasse',
    'conjecturâtes',
    'conjecturerai',
    'conjectureras',
    'conjecturerez',
    'conjecturions',
    'conjoignaient',
    'conjoignirent',
    'conjoignisses',
    'conjoindrions',
    'conjointement',
    'conjonctivale',
    'conjonctivaux',
    'conjonctivite',
    'conjoncturels',
    'conjugalement',
    'conjuguassent',
    'conjuguassiez',
    'conjuguerions',
    'conjurassions',
    'conjuratrices',
    'conjureraient',
    'connaissables',
    'connaissaient',
    'connaissances',
    'connaissement',
    'connaisseuses',
    'connaîtraient',
    'connectassent',
    'connectassiez',
    'connecterions',
    'connotassions',
    'connoteraient',
    'conobrassions',
    'conobreraient',
    'conpressaient',
    'conpressantes',
    'conpressasses',
    'conpresserais',
    'conpresserait',
    'conpressèrent',
    'conpresseriez',
    'conpresserons',
    'conpresseront',
    'conquerraient',
    'consacrassent',
    'consacrassiez',
    'consacrerions',
    'consanguinité',
    'consciencieux',
    'conscriptions',
    'consécrations',
    'conseillaient',
    'conseillasses',
    'conseillerais',
    'conseillerait',
    'conseillèrent',
    'conseilleriez',
    'conseillerons',
    'conseilleront',
    'conseilleuses',
    'consentements',
    'consentirions',
    'consentissent',
    'consentissiez',
    'conséquemment',
    'conservassent',
    'conservassiez',
    'conservateurs',
    'conservations',
    'conservatisme',
    'conservatoire',
    'conservatrice',
    'conserverions',
    'considérables',
    'considéraient',
    'considérantes',
    'considérasses',
    'considération',
    'considérerais',
    'considérerait',
    'considérèrent',
    'considéreriez',
    'considérerons',
    'considéreront',
    'consignassent',
    'consignassiez',
    'consignataire',
    'consignations',
    'consignerions',
    'consistassent',
    'consistassiez',
    'consisterions',
    'consistoriale',
    'consistoriaux',
    'consolassions',
    'consolatrices',
    'consoleraient',
    'consolidaient',
    'consolidasses',
    'consolidation',
    'consoliderais',
    'consoliderait',
    'consolidèrent',
    'consolideriez',
    'consoliderons',
    'consolideront',
    'consommassent',
    'consommassiez',
    'consommateurs',
    'consommations',
    'consommatrice',
    'consommerions',
    'consomptibles',
    'consonantique',
    'consonantisme',
    'consonassions',
    'consoneraient',
    'conspirassent',
    'conspirassiez',
    'conspirateurs',
    'conspirations',
    'conspiratrice',
    'conspirerions',
    'conspuassions',
    'conspueraient',
    'constatassent',
    'constatassiez',
    'constatations',
    'constaterions',
    'constellaient',
    'constellasses',
    'constellation',
    'constellerais',
    'constellerait',
    'constellèrent',
    'constelleriez',
    'constellerons',
    'constelleront',
    'consternaient',
    'consternantes',
    'consternasses',
    'consternation',
    'consternerais',
    'consternerait',
    'consternèrent',
    'consterneriez',
    'consternerons',
    'consterneront',
    'constipassent',
    'constipassiez',
    'constipations',
    'constiperions',
    'constituaient',
    'constituantes',
    'constituasses',
    'constituerais',
    'constituerait',
    'constituèrent',
    'constitueriez',
    'constituerons',
    'constitueront',
    'constitutions',
    'constitutives',
    'constricteurs',
    'constructeurs',
    'constructions',
    'constructives',
    'constructrice',
    'construirions',
    'construisîmes',
    'construisions',
    'construisisse',
    'construisîtes',
    'consultassent',
    'consultassiez',
    'consultations',
    'consultatives',
    'consulterions',
    'consumassions',
    'consumeraient',
    'consumérismes',
    'consuméristes',
    'contactassent',
    'contactassiez',
    'contacterions',
    'contagionnais',
    'contagionnait',
    'contagionnant',
    'contagionnées',
    'contagionnent',
    'contagionnera',
    'contagionniez',
    'contagionnons',
    'contagiosités',
    'containerisai',
    'containerisas',
    'containerisât',
    'containerisée',
    'containeriser',
    'containerises',
    'containerisés',
    'containerisez',
    'contaminaient',
    'contaminasses',
    'contamination',
    'contaminerais',
    'contaminerait',
    'contaminèrent',
    'contamineriez',
    'contaminerons',
    'contamineront',
    'contemplaient',
    'contemplasses',
    'contemplateur',
    'contemplatifs',
    'contemplation',
    'contemplative',
    'contemplerais',
    'contemplerait',
    'contemplèrent',
    'contempleriez',
    'contemplerons',
    'contempleront',
    'contemporaine',
    'contemporains',
    'contemptrices',
    'conteneurisai',
    'conteneurisas',
    'conteneurisât',
    'conteneurisée',
    'conteneuriser',
    'conteneurises',
    'conteneurisés',
    'conteneurisez',
    'contentassent',
    'contentassiez',
    'contentements',
    'contenterions',
    'contentieuses',
    'contestassent',
    'contestassiez',
    'contestataire',
    'contestations',
    'contesterions',
    'contextuelles',
    'contiendrions',
    'continentales',
    'contingentais',
    'contingentait',
    'contingentant',
    'contingentées',
    'contingentent',
    'contingentera',
    'contingentiez',
    'contingentons',
    'continuassent',
    'continuassiez',
    'continuateurs',
    'continuations',
    'continuatrice',
    'continuerions',
    'contorsionnai',
    'contorsionnas',
    'contorsionnât',
    'contorsionnée',
    'contorsionner',
    'contorsionnes',
    'contorsionnés',
    'contorsionnez',
    'contournaient',
    'contournasses',
    'contournement',
    'contournerais',
    'contournerait',
    'contournèrent',
    'contourneriez',
    'contournerons',
    'contourneront',
    'contraceptifs',
    'contraception',
    'contraceptive',
    'contractaient',
    'contractantes',
    'contractasses',
    'contracterais',
    'contracterait',
    'contractèrent',
    'contracteriez',
    'contracterons',
    'contracteront',
    'contractilité',
    'contractuelle',
    'contracturais',
    'contracturait',
    'contracturant',
    'contracturées',
    'contracturent',
    'contracturera',
    'contracturiez',
    'contracturons',
    'contradicteur',
    'contradiction',
    'contraignable',
    'contraignante',
    'contraignants',
    'contraignîmes',
    'contraignions',
    'contraignisse',
    'contraignîtes',
    'contraindrais',
    'contraindrait',
    'contraindriez',
    'contraindrons',
    'contraindront',
    'contrairement',
    'contraposâmes',
    'contraposasse',
    'contraposâtes',
    'contraposerai',
    'contraposeras',
    'contraposerez',
    'contraposions',
    'contrariaient',
    'contrariantes',
    'contrariasses',
    'contrarierais',
    'contrarierait',
    'contrarièrent',
    'contrarieriez',
    'contrarierons',
    'contrarieront',
    'contrarotatif',
    'contrastaient',
    'contrastasses',
    'contrasterais',
    'contrasterait',
    'contrastèrent',
    'contrasteriez',
    'contrasterons',
    'contrasteront',
    'contravention',
    'contre-alizés',
    'contre-allées',
    'contre-amiral',
    'contrebalança',
    'contrebalance',
    'contrebalancé',
    'contrebandier',
    'contrebassons',
    'contrebattais',
    'contrebattait',
    'contrebattant',
    'contrebattent',
    'contrebattiez',
    'contrebattons',
    'contrebattrai',
    'contrebattras',
    'contrebattrez',
    'contrebattues',
    'contreboutais',
    'contreboutait',
    'contreboutant',
    'contreboutées',
    'contreboutent',
    'contreboutera',
    'contreboutiez',
    'contreboutons',
    'contre-butais',
    'contre-butait',
    'contrebutâmes',
    'contre-butant',
    'contrebutasse',
    'contrebutâtes',
    'contre-butées',
    'contre-butent',
    'contre-butera',
    'contrebuterai',
    'contrebuteras',
    'contrebuterez',
    'contre-butiez',
    'contrebutions',
    'contre-butons',
    'contrecarrais',
    'contrecarrait',
    'contrecarrant',
    'contrecarrées',
    'contrecarrent',
    'contrecarrera',
    'contrecarriez',
    'contrecarrons',
    'contre-chants',
    'contre-digues',
    'contredirions',
    'contredisions',
    'contredissent',
    'contredissiez',
    'contre-écrous',
    'contre-essais',
    'contrefacteur',
    'contrefaisais',
    'contrefaisait',
    'contrefaisant',
    'contrefaisiez',
    'contrefaisons',
    'contrefassent',
    'contrefassiez',
    'contreferions',
    'contrefichais',
    'contrefichait',
    'contrefichant',
    'contrefichées',
    'contrefichent',
    'contrefichera',
    'contrefichiez',
    'contrefichons',
    'contre-filets',
    'contrefissent',
    'contrefissiez',
    'contrefoutais',
    'contrefoutait',
    'contrefoutant',
    'contrefoutent',
    'contrefoutiez',
    'contrefoutons',
    'contrefoutrai',
    'contrefoutras',
    'contrefoutrez',
    'contrefoutues',
    'contre-fugues',
    'contre-lettre',
    'contremaîtres',
    'contremandais',
    'contremandait',
    'contremandant',
    'contremandées',
    'contremandent',
    'contremandera',
    'contremandiez',
    'contremandons',
    'contremarches',
    'contremarquai',
    'contremarquas',
    'contremarquât',
    'contremarquée',
    'contremarquer',
    'contremarques',
    'contremarqués',
    'contremarquez',
    'contre-mesure',
    'contre-minais',
    'contre-minait',
    'contre-minant',
    'contre-minées',
    'contre-minent',
    'contre-minera',
    'contre-miniez',
    'contre-minons',
    'contre-murais',
    'contre-murait',
    'contre-murant',
    'contre-murées',
    'contre-murera',
    'contre-muriez',
    'contre-murons',
    'contre-ordres',
    'contreparties',
    'contre-passai',
    'contre-passas',
    'contre-passât',
    'contre-passée',
    'contre-passer',
    'contre-passes',
    'contre-passés',
    'contre-passez',
    'contre-pentes',
    'contrepèterie',
    'contre-plaqua',
    'contre-plaque',
    'contre-plaqué',
    'contre-pointe',
    'contrepoisons',
    'contre-portes',
    'contre-projet',
    'contrescarpes',
    'contre-scella',
    'contre-scelle',
    'contre-scellé',
    'contresignais',
    'contresignait',
    'contresignant',
    'contresignées',
    'contresignent',
    'contresignera',
    'contresigniez',
    'contresignons',
    'contre-sujets',
    'contre-taille',
    'contre-timbre',
    'contre-tirais',
    'contre-tirait',
    'contre-tirant',
    'contre-tirées',
    'contre-tirent',
    'contre-tirera',
    'contre-tiriez',
    'contre-tirons',
    'contre-valeur',
    'contrevenante',
    'contrevenants',
    'contrevenions',
    'contre-vérité',
    'contrevérités',
    'contreviendra',
    'contreviennes',
    'contrevinrent',
    'contrevinsses',
    'contre-visite',
    'contribuables',
    'contribuaient',
    'contribuasses',
    'contribuerais',
    'contribuerait',
    'contribuèrent',
    'contribueriez',
    'contribuerons',
    'contribueront',
    'contributions',
    'contributives',
    'contristaient',
    'contristasses',
    'contristerais',
    'contristerait',
    'contristèrent',
    'contristeriez',
    'contristerons',
    'contristeront',
    'contrôlassent',
    'contrôlassiez',
    'controlatéral',
    'contrôlerions',
    'controuvaient',
    'controuvasses',
    'controuverais',
    'controuverait',
    'controuvèrent',
    'controuveriez',
    'controuverons',
    'controuveront',
    'controversais',
    'controversait',
    'controversant',
    'controversées',
    'controversent',
    'controversera',
    'controversiez',
    'controversons',
    'contusionnais',
    'contusionnait',
    'contusionnant',
    'contusionnées',
    'contusionnent',
    'contusionnera',
    'contusionniez',
    'contusionnons',
    'convaincantes',
    'convaincrions',
    'convainquîmes',
    'convainquions',
    'convainquisse',
    'convainquîtes',
    'convalescence',
    'convalescente',
    'convalescents',
    'conventionnai',
    'conventionnas',
    'conventionnât',
    'conventionnée',
    'conventionnel',
    'conventionner',
    'conventionnes',
    'conventionnés',
    'conventionnez',
    'conventuelles',
    'convergeaient',
    'convergeantes',
    'convergeasses',
    'convergerions',
    'conversassent',
    'conversassiez',
    'conversations',
    'converserions',
    'convertirions',
    'convertissais',
    'convertissait',
    'convertissant',
    'convertissent',
    'convertisseur',
    'convertissiez',
    'convertissons',
    'conviendrions',
    'convivialités',
    'convoieraient',
    'convoitassent',
    'convoitassiez',
    'convoiterions',
    'convolassions',
    'convoleraient',
    'convoquassent',
    'convoquassiez',
    'convoquerions',
    'convoyassions',
    'convulsassent',
    'convulsassiez',
    'convulserions',
    'convulsionnai',
    'convulsionnas',
    'convulsionnât',
    'convulsionnée',
    'convulsionner',
    'convulsionnes',
    'convulsionnés',
    'convulsionnez',
    'coopérassions',
    'coopératismes',
    'coopératrices',
    'coopéreraient',
    'coordinateurs',
    'coordinations',
    'coordinatrice',
    'coordonnaient',
    'coordonnasses',
    'coordonnateur',
    'coordonnerais',
    'coordonnerait',
    'coordonnèrent',
    'coordonneriez',
    'coordonnerons',
    'coordonneront',
    'copartageante',
    'copartageants',
    'copermutaient',
    'copermutasses',
    'copermuterais',
    'copermuterait',
    'copermutèrent',
    'copermuteriez',
    'copermuterons',
    'copermuteront',
    'copernicienne',
    'copossesseurs',
    'copposédaient',
    'copposédasses',
    'copposéderais',
    'copposéderait',
    'copposédèrent',
    'copposéderiez',
    'copposéderons',
    'copposéderont',
    'coprésidences',
    'coprésidentes',
    'coproductions',
    'coquelucheuse',
    'coquetassions',
    'coquetterions',
    'coquillassent',
    'coquillassiez',
    'coquillerions',
    'cordelassions',
    'cordellerions',
    'cordonnassent',
    'cordonnassiez',
    'cordonnerions',
    'cordons-bleus',
    'corinthiennes',
    'coronariennes',
    'corporatismes',
    'corporatistes',
    'corpusculaire',
    'correctionnel',
    'corrélassions',
    'corréleraient',
    'correspondais',
    'correspondait',
    'correspondant',
    'correspondent',
    'correspondiez',
    'correspondons',
    'correspondrai',
    'correspondras',
    'correspondrez',
    'correspondues',
    'corrigeassent',
    'corrigeassiez',
    'corrigeraient',
    'corroboraient',
    'corroborasses',
    'corroboration',
    'corroborerais',
    'corroborerait',
    'corroborèrent',
    'corroboreriez',
    'corroborerons',
    'corroboreront',
    'corrodassions',
    'corroderaient',
    'corroieraient',
    'corrompissent',
    'corrompissiez',
    'corrompraient',
    'corroyassions',
    'corsetassions',
    'corsèteraient',
    'cosignataires',
    'cosmétiquâmes',
    'cosmétiquasse',
    'cosmétiquâtes',
    'cosmétiquerai',
    'cosmétiqueras',
    'cosmétiquerez',
    'cosmétiquions',
    'cosmétologies',
    'cosmétologues',
    'cosmogoniques',
    'cosmographies',
    'costumassions',
    'costumeraient',
    'cotonnassions',
    'cotonneraient',
    'couchaillâmes',
    'couchaillasse',
    'couchaillâtes',
    'couchaillerai',
    'couchailleras',
    'couchaillerez',
    'coudoieraient',
    'coudoyassions',
    'couillonnâmes',
    'couillonnasse',
    'couillonnâtes',
    'couillonnerai',
    'couillonneras',
    'couillonnerez',
    'couillonnions',
    'coulissassent',
    'coulissassiez',
    'coulisserions',
    'coupaillaient',
    'coupaillasses',
    'coupaillerais',
    'coupaillerait',
    'coupaillèrent',
    'coupailleriez',
    'coupaillerons',
    'coupailleront',
    'coupe-circuit',
    'coupellassent',
    'coupellassiez',
    'coupellerions',
    'couraillaient',
    'couraillasses',
    'couraillerais',
    'couraillerait',
    'couraillèrent',
    'courailleriez',
    'couraillerons',
    'courailleront',
    'courbaturâmes',
    'courbaturasse',
    'courbaturâtes',
    'courbaturerai',
    'courbatureras',
    'courbaturerez',
    'courbaturions',
    'couronnassent',
    'couronnassiez',
    'couronnerions',
    'courrouçaient',
    'courrouçasses',
    'courroucerais',
    'courroucerait',
    'courroucèrent',
    'courrouceriez',
    'courroucerons',
    'courrouceront',
    'courtaudaient',
    'courtaudasses',
    'courtauderais',
    'courtauderait',
    'courtaudèrent',
    'courtauderiez',
    'courtauderons',
    'courtauderont',
    'court-circuit',
    'courtepointes',
    'courtisanerie',
    'courtisassent',
    'courtisassiez',
    'courtiserions',
    'courtoisement',
    'cousinassions',
    'cousineraient',
    'couturassions',
    'coutureraient',
    'crachotassent',
    'crachotassiez',
    'crachotements',
    'crachoterions',
    'crachouillais',
    'crachouillait',
    'crachouillant',
    'crachouillent',
    'crachouillera',
    'crachouilliez',
    'crachouillons',
    'craignissions',
    'craillassions',
    'crailleraient',
    'craintivement',
    'cramponnaient',
    'cramponnasses',
    'cramponnement',
    'cramponnerais',
    'cramponnerait',
    'cramponnèrent',
    'cramponneriez',
    'cramponnerons',
    'cramponneront',
    'crampsassions',
    'crampseraient',
    'crapahutaient',
    'crapahutasses',
    'crapahuterais',
    'crapahuterait',
    'crapahutèrent',
    'crapahuteriez',
    'crapahuterons',
    'crapahuteront',
    'crapaütassent',
    'crapaütassiez',
    'crapaüterions',
    'crapulassions',
    'crapuleraient',
    'craquelassent',
    'craquelassiez',
    'craquèlements',
    'craquellement',
    'craquellerais',
    'craquellerait',
    'craquelleriez',
    'craquellerons',
    'craquelleront',
    'craquetassent',
    'craquetassiez',
    'craquètements',
    'craquettement',
    'craquetterais',
    'craquetterait',
    'craquetteriez',
    'craquetterons',
    'craquetteront',
    'cratériformes',
    'cravachassent',
    'cravachassiez',
    'cravacherions',
    'cravatassions',
    'cravateraient',
    'crayonnassent',
    'crayonnassiez',
    'crayonnerions',
    'créditassions',
    'créditeraient',
    'crédits-bails',
    'crénelassions',
    'crénellerions',
    'créolisassent',
    'créolisassiez',
    'créoliserions',
    'créosotassent',
    'créosotassiez',
    'créosoterions',
    'crépitassions',
    'crépiteraient',
    'crépusculaire',
    'cressonnières',
    'crétinisaient',
    'crétinisasses',
    'crétiniserais',
    'crétiniserait',
    'crétinisèrent',
    'crétiniseriez',
    'crétiniserons',
    'crétiniseront',
    'crevassassent',
    'crevassassiez',
    'crevasserions',
    'criaillassent',
    'criaillassiez',
    'criaillements',
    'criaillerions',
    'criminalisais',
    'criminalisait',
    'criminalisant',
    'criminalisées',
    'criminalisent',
    'criminalisera',
    'criminalisiez',
    'criminalisons',
    'criminologies',
    'criminologues',
    'cristalleries',
    'cristallisais',
    'cristallisait',
    'cristallisant',
    'cristallisées',
    'cristallisent',
    'cristallisera',
    'cristallisiez',
    'cristallisons',
    'criticaillais',
    'criticaillait',
    'criticaillant',
    'criticaillées',
    'criticaillent',
    'criticaillera',
    'criticailliez',
    'criticaillons',
    'critiquassent',
    'critiquassiez',
    'critiquerions',
    'croassassions',
    'croasseraient',
    'croc-en-jambe',
    'crochetassent',
    'crochetassiez',
    'crochèterions',
    'croque-madame',
    'croquemitaine',
    'cross-country',
    'croupissaient',
    'croupissantes',
    'croupissement',
    'croustillâmes',
    'croustillante',
    'croustillants',
    'croustillasse',
    'croustillâtes',
    'croustillerai',
    'croustilleras',
    'croustillerez',
    'croustillions',
    'crucifiassent',
    'crucifiassiez',
    'crucifiements',
    'crucifierions',
    'cruciverbiste',
    'cryptogrammes',
    'cryptographie',
    'cueilleraient',
    'cueillissions',
    'cuirassassent',
    'cuirassassiez',
    'cuirasserions',
    'cuisinassions',
    'cuisineraient',
    'culbutassions',
    'culbuteraient',
    'culminassions',
    'culmineraient',
    'culottassions',
    'culotteraient',
    'culpabilisais',
    'culpabilisait',
    'culpabilisant',
    'culpabilisées',
    'culpabilisent',
    'culpabilisera',
    'culpabilisiez',
    'culpabilisons',
    'culs-de-jatte',
    'cultivassions',
    'cultivatrices',
    'cultiveraient',
    'culturalismes',
    'curarisations',
    'cure-oreilles',
    'curetteraient',
    'curiethérapie',
    'cuvelleraient',
    'cyanhydriques',
    'cyanosassions',
    'cyanoseraient',
    'cyanurassions',
    'cyanureraient',
    'cybernéticien',
    'cybernétiques',
    'cyclisassions',
    'cycliseraient',
    'cyclothymique',
    'cyclotourisme',
    'cylindrassent',
    'cylindrassiez',
    'cylindrerions',
    'cystographies',
    'cytogénétique',
    'cytoplasmique',
    'dactylographe',
    'dactylologies',
    'dactyloscopie',
    'daguerréotype',
    'damasquinages',
    'damasquinâmes',
    'damasquinasse',
    'damasquinâtes',
    'damasquinerai',
    'damasquineras',
    'damasquinerez',
    'damasquineurs',
    'damasquinions',
    'damassassions',
    'damasseraient',
    'dames-jeannes',
    'dandinassions',
    'dandineraient',
    'dansottassent',
    'dansottassiez',
    'dansotterions',
    'déactivassent',
    'déactivassiez',
    'déactiverions',
    'déambulassent',
    'déambulassiez',
    'déambulations',
    'déambulatoire',
    'déambulerions',
    'débâchassions',
    'débâcheraient',
    'débâclassions',
    'débâcleraient',
    'débagoulaient',
    'débagoulasses',
    'débagoulerais',
    'débagoulerait',
    'débagoulèrent',
    'débagouleriez',
    'débagoulerons',
    'débagouleront',
    'débâillonnais',
    'débâillonnait',
    'débâillonnant',
    'débâillonnées',
    'débâillonnent',
    'débâillonnera',
    'débâillonniez',
    'débâillonnons',
    'déballassions',
    'déballastages',
    'déballeraient',
    'déballonnâmes',
    'déballonnasse',
    'déballonnâtes',
    'déballonnerai',
    'déballonneras',
    'déballonnerez',
    'déballonnions',
    'débalourdâmes',
    'débalourdasse',
    'débalourdâtes',
    'débalourderai',
    'débalourderas',
    'débalourderez',
    'débalourdions',
    'débanalisâmes',
    'débanalisasse',
    'débanalisâtes',
    'débanaliserai',
    'débanaliseras',
    'débanaliserez',
    'débanalisions',
    'débandassions',
    'débanderaient',
    'débaptisaient',
    'débaptisasses',
    'débaptiserais',
    'débaptiserait',
    'débaptisèrent',
    'débaptiseriez',
    'débaptiserons',
    'débaptiseront',
    'débarbouillai',
    'débarbouillas',
    'débarbouillât',
    'débarbouillée',
    'débarbouiller',
    'débarbouilles',
    'débarbouillés',
    'débarbouillez',
    'débardassions',
    'débarderaient',
    'débarquassent',
    'débarquassiez',
    'débarquements',
    'débarquerions',
    'débarrassâmes',
    'débarrassasse',
    'débarrassâtes',
    'débarrasserai',
    'débarrasseras',
    'débarrasserez',
    'débarrassions',
    'débarreraient',
    'débâtissaient',
    'débattissions',
    'débauchassent',
    'débauchassiez',
    'débaucherions',
    'débecquetâmes',
    'débecquetasse',
    'débecquetâtes',
    'débecquetions',
    'débecquettent',
    'débecquettera',
    'débectassions',
    'débecteraient',
    'débenzolaient',
    'débenzolasses',
    'débenzolerais',
    'débenzolerait',
    'débenzolèrent',
    'débenzoleriez',
    'débenzolerons',
    'débenzoleront',
    'débilitassent',
    'débilitassiez',
    'débiliterions',
    'débillardâmes',
    'débillardasse',
    'débillardâtes',
    'débillarderai',
    'débillarderas',
    'débillarderez',
    'débillardions',
    'déblaieraient',
    'déblatéraient',
    'déblatérasses',
    'déblatérerais',
    'déblatérerait',
    'déblatérèrent',
    'déblatéreriez',
    'déblatérerons',
    'déblatéreront',
    'déblayassions',
    'déblayeraient',
    'débleuiraient',
    'débleuissions',
    'débloquassent',
    'débloquassiez',
    'débloquerions',
    'débobinassent',
    'débobinassiez',
    'débobinerions',
    'déboguassions',
    'débogueraient',
    'déboisassions',
    'déboiseraient',
    'déboîtassions',
    'déboîteraient',
    'débondassions',
    'débonderaient',
    'débonnairetés',
    'débordassions',
    'déborderaient',
    'débosselaient',
    'débosselasses',
    'débosselèrent',
    'débossellerai',
    'débosselleras',
    'débossellerez',
    'débottassions',
    'débotteraient',
    'débouchassent',
    'débouchassiez',
    'débouchements',
    'déboucherions',
    'débouclassent',
    'débouclassiez',
    'débouclerions',
    'déboudassions',
    'débouderaient',
    'débouillaient',
    'débouillirais',
    'débouillirait',
    'débouillirent',
    'débouilliriez',
    'débouillirons',
    'débouilliront',
    'débouillisses',
    'déboulassions',
    'débouleraient',
    'déboulonnages',
    'déboulonnâmes',
    'déboulonnasse',
    'déboulonnâtes',
    'déboulonnerai',
    'déboulonneras',
    'déboulonnerez',
    'déboulonnions',
    'débouquassent',
    'débouquassiez',
    'débouquements',
    'débouquerions',
    'débourbassent',
    'débourbassiez',
    'débourberions',
    'débourgeoisée',
    'débourgeoisés',
    'débourrassent',
    'débourrassiez',
    'débourrements',
    'débourrerions',
    'déboursassent',
    'déboursassiez',
    'déboursements',
    'débourserions',
    'déboussolâmes',
    'déboussolasse',
    'déboussolâtes',
    'déboussolerai',
    'déboussoleras',
    'déboussolerez',
    'déboussolions',
    'déboutassions',
    'débouteraient',
    'déboutonnages',
    'déboutonnâmes',
    'déboutonnasse',
    'déboutonnâtes',
    'déboutonnerai',
    'déboutonneras',
    'déboutonnerez',
    'déboutonnions',
    'débraguettais',
    'débraguettait',
    'débraguettant',
    'débraguettées',
    'débraguettent',
    'débraguettera',
    'débraguettiez',
    'débraguettons',
    'débraieraient',
    'débraillaient',
    'débraillasses',
    'débraillerais',
    'débraillerait',
    'débraillèrent',
    'débrailleriez',
    'débraillerons',
    'débrailleront',
    'débranchaient',
    'débranchasses',
    'débranchement',
    'débrancherais',
    'débrancherait',
    'débranchèrent',
    'débrancheriez',
    'débrancherons',
    'débrancheront',
    'débrayassions',
    'débrayeraient',
    'débridassions',
    'débrideraient',
    'débrochassent',
    'débrochassiez',
    'débrocherions',
    'débrouillages',
    'débrouillâmes',
    'débrouillarde',
    'débrouillards',
    'débrouillasse',
    'débrouillâtes',
    'débrouillerai',
    'débrouilleras',
    'débrouillerez',
    'débrouillions',
    'débroussailla',
    'débroussaille',
    'débroussaillé',
    'débuchassions',
    'débucheraient',
    'débudgétisais',
    'débudgétisait',
    'débudgétisant',
    'débudgétisées',
    'débudgétisent',
    'débudgétisera',
    'débudgétisiez',
    'débudgétisons',
    'débullassions',
    'débulleraient',
    'débusquassent',
    'débusquassiez',
    'débusquements',
    'débusquerions',
    'décachetaient',
    'décachetasses',
    'décachetèrent',
    'décachetterai',
    'décachetteras',
    'décachetterez',
    'décadenassais',
    'décadenassait',
    'décadenassant',
    'décadenassées',
    'décadenassent',
    'décadenassera',
    'décadenassiez',
    'décadenassons',
    'décaféinaient',
    'décaféinasses',
    'décaféinerais',
    'décaféinerait',
    'décaféinèrent',
    'décaféineriez',
    'décaféinerons',
    'décaféineront',
    'décaissassent',
    'décaissassiez',
    'décaissements',
    'décaisserions',
    'décalaminages',
    'décalaminâmes',
    'décalaminasse',
    'décalaminâtes',
    'décalaminerai',
    'décalamineras',
    'décalaminerez',
    'décalaminions',
    'décalcifiâmes',
    'décalcifiasse',
    'décalcifiâtes',
    'décalcifierai',
    'décalcifieras',
    'décalcifierez',
    'décalcifiions',
    'décalcomanies',
    'décalottaient',
    'décalottasses',
    'décalotterais',
    'décalotterait',
    'décalottèrent',
    'décalotteriez',
    'décalotterons',
    'décalotteront',
    'décalquassent',
    'décalquassiez',
    'décalquerions',
    'décampassions',
    'décamperaient',
    'décanillaient',
    'décanillasses',
    'décanillerais',
    'décanillerait',
    'décanillèrent',
    'décanilleriez',
    'décanillerons',
    'décanilleront',
    'décantassions',
    'décanteraient',
    'décapelassent',
    'décapelassiez',
    'décapellerais',
    'décapellerait',
    'décapelleriez',
    'décapellerons',
    'décapelleront',
    'décapitalisai',
    'décapitalisas',
    'décapitalisât',
    'décapitalisée',
    'décapitaliser',
    'décapitalises',
    'décapitalisés',
    'décapitalisez',
    'décapitassent',
    'décapitassiez',
    'décapitations',
    'décapiterions',
    'décapotassent',
    'décapotassiez',
    'décapoterions',
    'décapsulaient',
    'décapsulasses',
    'décapsulation',
    'décapsulerais',
    'décapsulerait',
    'décapsulèrent',
    'décapsuleriez',
    'décapsulerons',
    'décapsuleront',
    'décapuchonnai',
    'décapuchonnas',
    'décapuchonnât',
    'décapuchonnée',
    'décapuchonner',
    'décapuchonnes',
    'décapuchonnés',
    'décapuchonnez',
    'décarbonatais',
    'décarbonatait',
    'décarbonatant',
    'décarbonatées',
    'décarbonatent',
    'décarbonatera',
    'décarbonatiez',
    'décarbonatons',
    'décarburaient',
    'décarburasses',
    'décarburation',
    'décarburerais',
    'décarburerait',
    'décarburèrent',
    'décarbureriez',
    'décarburerons',
    'décarbureront',
    'décarcassâmes',
    'décarcassasse',
    'décarcassâtes',
    'décarcasserai',
    'décarcasseras',
    'décarcasserez',
    'décarcassions',
    'décarrassions',
    'décarrelaient',
    'décarrelasses',
    'décarrelèrent',
    'décarrellerai',
    'décarrelleras',
    'décarrellerez',
    'décarreraient',
    'décartonnâmes',
    'décartonnasse',
    'décartonnâtes',
    'décartonnerai',
    'décartonneras',
    'décartonnerez',
    'décartonnions',
    'décathloniens',
    'décatissaient',
    'décausassions',
    'décauseraient',
    'décélérassent',
    'décélérassiez',
    'décélérations',
    'décélérerions',
    'décentralisai',
    'décentralisas',
    'décentralisât',
    'décentralisée',
    'décentraliser',
    'décentralises',
    'décentralisés',
    'décentralisez',
    'décentrassent',
    'décentrassiez',
    'décentrerions',
    'décerclassent',
    'décerclassiez',
    'décerclerions',
    'décernassions',
    'décerneraient',
    'décessassions',
    'décesseraient',
    'déchagrinâmes',
    'déchagrinasse',
    'déchagrinâtes',
    'déchagrinerai',
    'déchagrineras',
    'déchagrinerez',
    'déchagrinions',
    'déchaînassent',
    'déchaînassiez',
    'déchaînements',
    'déchaînerions',
    'déchantassent',
    'déchantassiez',
    'déchanterions',
    'déchapassions',
    'déchaperaient',
    'déchaperonnai',
    'déchaperonnas',
    'déchaperonnât',
    'déchaperonnée',
    'déchaperonner',
    'déchaperonnes',
    'déchaperonnés',
    'déchaperonnez',
    'déchargeaient',
    'déchargeasses',
    'déchargements',
    'déchargerions',
    'décharnassent',
    'décharnassiez',
    'décharnerions',
    'déchaumassent',
    'déchaumassiez',
    'déchaumerions',
    'déchaussaient',
    'déchaussasses',
    'déchaussement',
    'déchausserais',
    'déchausserait',
    'déchaussèrent',
    'déchausseriez',
    'déchausserons',
    'déchausseront',
    'déchevêtrâmes',
    'déchevêtrasse',
    'déchevêtrâtes',
    'déchevêtrerai',
    'déchevêtreras',
    'déchevêtrerez',
    'déchevêtrions',
    'déchevillâmes',
    'déchevillasse',
    'déchevillâtes',
    'déchevillerai',
    'déchevilleras',
    'déchevillerez',
    'déchevillions',
    'déchiffonnais',
    'déchiffonnait',
    'déchiffonnant',
    'déchiffonnées',
    'déchiffonnent',
    'déchiffonnera',
    'déchiffonniez',
    'déchiffonnons',
    'déchiffrables',
    'déchiffraient',
    'déchiffrasses',
    'déchiffrement',
    'déchiffrerais',
    'déchiffrerait',
    'déchiffrèrent',
    'déchiffreriez',
    'déchiffrerons',
    'déchiffreront',
    'déchiquetages',
    'déchiquetâmes',
    'déchiquetasse',
    'déchiquetâtes',
    'déchiqueteurs',
    'déchiqueteuse',
    'déchiquetions',
    'déchiquettent',
    'déchiquettera',
    'déchiquetures',
    'déchirassions',
    'déchireraient',
    'déchlorurâmes',
    'déchlorurasse',
    'déchlorurâtes',
    'déchlorurerai',
    'déchlorureras',
    'déchlorurerez',
    'déchlorurions',
    'déchromassent',
    'déchromassiez',
    'déchromerions',
    'déchronologie',
    'décidabilités',
    'décimalisâmes',
    'décimalisasse',
    'décimalisâtes',
    'décimaliserai',
    'décimaliseras',
    'décimaliserez',
    'décimalisions',
    'décimétriques',
    'décintrassent',
    'décintrassiez',
    'décintrements',
    'décintrerâmes',
    'décintrerasse',
    'décintrerâtes',
    'décisionnaire',
    'décisionnelle',
    'déclamassions',
    'déclamatoires',
    'déclamatrices',
    'déclameraient',
    'déclarassions',
    'déclaratoires',
    'déclareraient',
    'déclassassent',
    'déclassassiez',
    'déclassements',
    'déclasserions',
    'déclavetaient',
    'déclavetasses',
    'déclavetèrent',
    'déclavetterai',
    'déclavetteras',
    'déclavetterez',
    'déclenchables',
    'déclenchaient',
    'déclenchasses',
    'déclenchement',
    'déclencherais',
    'déclencherait',
    'déclenchèrent',
    'déclencheriez',
    'déclencherons',
    'déclencheront',
    'décléricalisa',
    'décléricalise',
    'décléricalisé',
    'déclinassions',
    'déclineraient',
    'décliquetages',
    'décliquetâmes',
    'décliquetasse',
    'décliquetâtes',
    'décliquetions',
    'décliquettent',
    'décliquettera',
    'décloisonnais',
    'décloisonnait',
    'décloisonnant',
    'décloisonnées',
    'décloisonnent',
    'décloisonnera',
    'décloisonniez',
    'décloisonnons',
    'déclouassions',
    'décloueraient',
    'décochassions',
    'décocheraient',
    'décoffrassent',
    'décoffrassiez',
    'décoffrerions',
    'décoiffassent',
    'décoiffassiez',
    'décoiffements',
    'décoifferions',
    'décoinçassent',
    'décoinçassiez',
    'décoincerions',
    'décolérassent',
    'décolérassiez',
    'décolérerions',
    'décollassions',
    'décolleraient',
    'décolletaient',
    'décolletasses',
    'décolletèrent',
    'décolleteuses',
    'décolletterai',
    'décolletteras',
    'décolletterez',
    'décolonisâmes',
    'décolonisasse',
    'décolonisâtes',
    'décoloniserai',
    'décoloniseras',
    'décoloniserez',
    'décolonisions',
    'décolorassent',
    'décolorassiez',
    'décolorations',
    'décolorerions',
    'décommandâmes',
    'décommandasse',
    'décommandâtes',
    'décommanderai',
    'décommanderas',
    'décommanderez',
    'décommandions',
    'décomplexâmes',
    'décomplexasse',
    'décomplexâtes',
    'décomplexerai',
    'décomplexeras',
    'décomplexerez',
    'décomplexions',
    'décomposables',
    'décomposaient',
    'décomposasses',
    'décomposerais',
    'décomposerait',
    'décomposèrent',
    'décomposeriez',
    'décomposerons',
    'décomposeront',
    'décomposition',
    'décompressais',
    'décompressait',
    'décompressant',
    'décompressées',
    'décompressent',
    'décompressera',
    'décompressiez',
    'décompression',
    'décompressons',
    'décomprimâmes',
    'décomprimasse',
    'décomprimâtes',
    'décomprimerai',
    'décomprimeras',
    'décomprimerez',
    'décomprimions',
    'décomptassent',
    'décomptassiez',
    'décompterions',
    'déconcentrais',
    'déconcentrait',
    'déconcentrant',
    'déconcentrées',
    'déconcentrent',
    'déconcentrera',
    'déconcentriez',
    'déconcentrons',
    'déconcertâmes',
    'déconcertante',
    'déconcertants',
    'déconcertasse',
    'déconcertâtes',
    'déconcerterai',
    'déconcerteras',
    'déconcerterez',
    'déconcertions',
    'déconditionna',
    'déconditionne',
    'déconditionné',
    'déconfiraient',
    'déconfisaient',
    'déconfissions',
    'décongelaient',
    'décongelasses',
    'décongélation',
    'décongèlerais',
    'décongèlerait',
    'décongelèrent',
    'décongèleriez',
    'décongèlerons',
    'décongèleront',
    'décongestions',
    'décongestives',
    'déconnassions',
    'déconnectâmes',
    'déconnectasse',
    'déconnectâtes',
    'déconnecterai',
    'déconnecteras',
    'déconnecterez',
    'déconnections',
    'déconneraient',
    'déconseillais',
    'déconseillait',
    'déconseillant',
    'déconseillées',
    'déconseillent',
    'déconseillera',
    'déconseilliez',
    'déconseillons',
    'déconsidérais',
    'déconsidérait',
    'déconsidérant',
    'déconsidérées',
    'déconsidèrent',
    'déconsidérera',
    'déconsidériez',
    'déconsidérons',
    'déconsignâmes',
    'déconsignasse',
    'déconsignâtes',
    'déconsignerai',
    'déconsigneras',
    'déconsignerez',
    'déconsignions',
    'déconstipâmes',
    'déconstipasse',
    'déconstipâtes',
    'déconstiperai',
    'déconstiperas',
    'déconstiperez',
    'déconstipions',
    'décontaminais',
    'décontaminait',
    'décontaminant',
    'décontaminées',
    'décontaminent',
    'décontaminera',
    'décontaminiez',
    'décontaminons',
    'décontenançai',
    'décontenanças',
    'décontenançât',
    'décontenancée',
    'décontenancer',
    'décontenances',
    'décontenancés',
    'décontenancez',
    'décontractais',
    'décontractait',
    'décontractant',
    'décontractées',
    'décontractent',
    'décontractera',
    'décontractiez',
    'décontraction',
    'décontractons',
    'décordassions',
    'décorderaient',
    'décornassions',
    'décorneraient',
    'décortication',
    'décortiquâmes',
    'décortiquasse',
    'décortiquâtes',
    'décortiquerai',
    'décortiqueras',
    'décortiquerez',
    'décortiquions',
    'découchassent',
    'découchassiez',
    'découcherions',
    'découlassions',
    'découleraient',
    'découpassions',
    'découperaient',
    'découplassent',
    'découplassiez',
    'découplerions',
    'décourageâmes',
    'décourageante',
    'décourageants',
    'décourageasse',
    'décourageâtes',
    'découragement',
    'découragerais',
    'découragerait',
    'découragèrent',
    'décourageriez',
    'découragerons',
    'décourageront',
    'découronnâmes',
    'découronnasse',
    'découronnâtes',
    'découronnerai',
    'découronneras',
    'découronnerez',
    'découronnions',
    'décousissions',
    'découvrements',
    'découvririons',
    'découvrissent',
    'découvrissiez',
    'décrassassent',
    'décrassassiez',
    'décrassements',
    'décrasserions',
    'décréditaient',
    'décréditasses',
    'décréditerais',
    'décréditerait',
    'décréditèrent',
    'décréditeriez',
    'décréditerons',
    'décréditeront',
    'décrêpassions',
    'décrêperaient',
    'décrépiraient',
    'décrépissages',
    'décrépissions',
    'décrépitaient',
    'décrépitasses',
    'décrépiterais',
    'décrépiterait',
    'décrépitèrent',
    'décrépiteriez',
    'décrépiterons',
    'décrépiteront',
    'décrétassions',
    'décréteraient',
    'décreusassent',
    'décreusassiez',
    'décreuserions',
    'décriminalisa',
    'décriminalise',
    'décriminalisé',
    'décrispassent',
    'décrispassiez',
    'décrispations',
    'décrisperions',
    'décrivissions',
    'décrochassent',
    'décrochassiez',
    'décrochements',
    'décrocherions',
    'décroisassent',
    'décroisassiez',
    'décroiserions',
    'décroissaient',
    'décroissances',
    'décroissantes',
    'décroissement',
    'décroîtraient',
    'décrottassent',
    'décrottassiez',
    'décrotterions',
    'décroûtassent',
    'décroûtassiez',
    'décroûterions',
    'décrusassions',
    'décruseraient',
    'décryptassent',
    'décryptassiez',
    'décrypterions',
    'décuivrassent',
    'décuivrassiez',
    'décuivrerions',
    'déculassaient',
    'déculassasses',
    'déculasserais',
    'déculasserait',
    'déculassèrent',
    'déculasseriez',
    'déculasserons',
    'déculasseront',
    'déculottaient',
    'déculottasses',
    'déculotterais',
    'déculotterait',
    'déculottèrent',
    'déculotteriez',
    'déculotterons',
    'déculotteront',
    'déculpabilisa',
    'déculpabilise',
    'déculpabilisé',
    'décuplassions',
    'décupleraient',
    'décuscuteuses',
    'dédaignassent',
    'dédaignassiez',
    'dédaignerions',
    'dédicaçassent',
    'dédicaçassiez',
    'dédicacerions',
    'dédifférencia',
    'dédifférencie',
    'dédifférencié',
    'dédommageâmes',
    'dédommageasse',
    'dédommageâtes',
    'dédommagement',
    'dédommagerais',
    'dédommagerait',
    'dédommagèrent',
    'dédommageriez',
    'dédommagerons',
    'dédommageront',
    'dédouanassent',
    'dédouanassiez',
    'dédouanements',
    'dédouanerions',
    'dédoublassent',
    'dédoublassiez',
    'dédoublements',
    'dédoublerions',
    'dédramatisais',
    'dédramatisait',
    'dédramatisant',
    'dédramatisées',
    'dédramatisent',
    'dédramatisera',
    'dédramatisiez',
    'dédramatisons',
    'déduisissions',
    'défaillirions',
    'défaillissent',
    'défaillissiez',
    'défalquassent',
    'défalquassiez',
    'défalquerions',
    'défardassions',
    'défarderaient',
    'défatiguaient',
    'défatiguasses',
    'défatiguerais',
    'défatiguerait',
    'défatiguèrent',
    'défatigueriez',
    'défatiguerons',
    'défatigueront',
    'défaufilaient',
    'défaufilasses',
    'défaufilerais',
    'défaufilerait',
    'défaufilèrent',
    'défaufileriez',
    'défaufilerons',
    'défaufileront',
    'défaussassent',
    'défaussassiez',
    'défausserions',
    'défavorisâmes',
    'défavorisasse',
    'défavorisâtes',
    'défavoriserai',
    'défavoriseras',
    'défavoriserez',
    'défavorisions',
    'défectuosités',
    'défenderesses',
    'défendissions',
    'défensivement',
    'déféquassions',
    'déféqueraient',
    'déferlassions',
    'déferleraient',
    'déferrassions',
    'déferreraient',
    'déferrisaient',
    'déferrisasses',
    'déferriserais',
    'déferriserait',
    'déferrisèrent',
    'déferriseriez',
    'déferriserons',
    'déferriseront',
    'défervescence',
    'défeuillaient',
    'défeuillaison',
    'défeuillasses',
    'défeuillerais',
    'défeuillerait',
    'défeuillèrent',
    'défeuilleriez',
    'défeuillerons',
    'défeuilleront',
    'défeutrassent',
    'défeutrassiez',
    'défeutrerions',
    'défibrassions',
    'défibreraient',
    'déficelassent',
    'déficelassiez',
    'déficellerais',
    'déficellerait',
    'déficelleriez',
    'déficellerons',
    'déficelleront',
    'défichassions',
    'déficheraient',
    'défigeassions',
    'défigurassent',
    'défigurassiez',
    'défigurations',
    'défigurements',
    'défigurerions',
    'définissables',
    'définissaient',
    'définissantes',
    'définitionnel',
    'déflagrassent',
    'déflagrassiez',
    'déflagrateurs',
    'déflagrations',
    'déflagrerions',
    'déflaquassent',
    'déflaquassiez',
    'déflaquerions',
    'défleuraisons',
    'défleuririons',
    'défleurissais',
    'défleurissait',
    'défleurissant',
    'défleurissent',
    'défleurissiez',
    'défleurissons',
    'déflorassions',
    'défloreraient',
    'défluviations',
    'défoliassions',
    'défolieraient',
    'défonçassions',
    'défonceraient',
    'déforçassions',
    'déforceraient',
    'déforestation',
    'déformassions',
    'déformatrices',
    'déformeraient',
    'défoulassions',
    'défouleraient',
    'défouraillais',
    'défouraillait',
    'défouraillant',
    'défouraillées',
    'défouraillent',
    'défouraillera',
    'défourailliez',
    'défouraillons',
    'défournassent',
    'défournassiez',
    'défournements',
    'défournerions',
    'défourrassent',
    'défourrassiez',
    'défourrerions',
    'défraîchirais',
    'défraîchirait',
    'défraîchirent',
    'défraîchiriez',
    'défraîchirons',
    'défraîchiront',
    'défraîchisses',
    'défraîchissez',
    'défraieraient',
    'défrancisâmes',
    'défrancisasse',
    'défrancisâtes',
    'défranciserai',
    'défranciseras',
    'défranciserez',
    'défrancisions',
    'défrayassions',
    'défrayeraient',
    'défrettassent',
    'défrettassiez',
    'défretterions',
    'défrichassent',
    'défrichassiez',
    'défrichements',
    'défricherions',
    'défringuaient',
    'défringuasses',
    'défringuerais',
    'défringuerait',
    'défringuèrent',
    'défringueriez',
    'défringuerons',
    'défringueront',
    'défripassions',
    'défriperaient',
    'défrisassions',
    'défriseraient',
    'défroissables',
    'défroissaient',
    'défroissasses',
    'défroisserais',
    'défroisserait',
    'défroissèrent',
    'défroisseriez',
    'défroisserons',
    'défroisseront',
    'défronçassent',
    'défronçassiez',
    'défroncerions',
    'défroquassent',
    'défroquassiez',
    'défroquerions',
    'défruitassent',
    'défruitassiez',
    'défruiterions',
    'dégageassions',
    'dégainassions',
    'dégaineraient',
    'dégalonnaient',
    'dégalonnasses',
    'dégalonnerais',
    'dégalonnerait',
    'dégalonnèrent',
    'dégalonneriez',
    'dégalonnerons',
    'dégalonneront',
    'dégantassions',
    'déganteraient',
    'dégarniraient',
    'dégarnissions',
    'dégasolinages',
    'dégasolinâmes',
    'dégasolinasse',
    'dégasolinâtes',
    'dégasolinerai',
    'dégasolineras',
    'dégasolinerez',
    'dégasolinions',
    'dégauchirions',
    'dégauchissage',
    'dégauchissais',
    'dégauchissait',
    'dégauchissant',
    'dégauchissent',
    'dégauchissiez',
    'dégauchissons',
    'dégazolinages',
    'dégazolinâmes',
    'dégazolinasse',
    'dégazolinâtes',
    'dégazolinerai',
    'dégazolineras',
    'dégazolinerez',
    'dégazolinions',
    'dégazonnaient',
    'dégazonnasses',
    'dégazonnement',
    'dégazonnerais',
    'dégazonnerait',
    'dégazonnèrent',
    'dégazonneriez',
    'dégazonnerons',
    'dégazonneront',
    'dégénérassent',
    'dégénérassiez',
    'dégénératives',
    'dégénérerions',
    'dégermassions',
    'dégermeraient',
    'dégingandâmes',
    'dégingandasse',
    'dégingandâtes',
    'déginganderai',
    'déginganderas',
    'déginganderez',
    'dégingandions',
    'dégivrassions',
    'dégivreraient',
    'déglaçassions',
    'déglaceraient',
    'déglaciations',
    'déglinguaient',
    'déglinguasses',
    'déglinguerais',
    'déglinguerait',
    'déglinguèrent',
    'déglingueriez',
    'déglinguerons',
    'déglingueront',
    'déglutination',
    'déglutiraient',
    'déglutissions',
    'dégobillaient',
    'dégobillasses',
    'dégobillerais',
    'dégobillerait',
    'dégobillèrent',
    'dégobilleriez',
    'dégobillerons',
    'dégobilleront',
    'dégoisassions',
    'dégoiseraient',
    'dégommassions',
    'dégommeraient',
    'dégondassions',
    'dégonderaient',
    'dégonflassent',
    'dégonflassiez',
    'dégonflements',
    'dégonflerions',
    'dégorgeassent',
    'dégorgeassiez',
    'dégorgeraient',
    'dégottassions',
    'dégotteraient',
    'dégoudronnais',
    'dégoudronnait',
    'dégoudronnant',
    'dégoudronnées',
    'dégoudronnent',
    'dégoudronnera',
    'dégoudronniez',
    'dégoudronnons',
    'dégoulinaient',
    'dégoulinasses',
    'dégoulinement',
    'dégoulinerais',
    'dégoulinerait',
    'dégoulinèrent',
    'dégoulineriez',
    'dégoulinerons',
    'dégoulineront',
    'dégoupillâmes',
    'dégoupillasse',
    'dégoupillâtes',
    'dégoupillerai',
    'dégoupilleras',
    'dégoupillerez',
    'dégoupillions',
    'dégourdirions',
    'dégourdissais',
    'dégourdissait',
    'dégourdissant',
    'dégourdissent',
    'dégourdissiez',
    'dégourdissons',
    'dégoûtassions',
    'dégoûteraient',
    'dégouttassent',
    'dégouttassiez',
    'dégoutterions',
    'dégradassions',
    'dégraderaient',
    'dégrafassions',
    'dégraferaient',
    'dégraissaient',
    'dégraissantes',
    'dégraissasses',
    'dégraisserais',
    'dégraisserait',
    'dégraissèrent',
    'dégraisseriez',
    'dégraisserons',
    'dégraisseront',
    'dégravoierais',
    'dégravoierait',
    'dégravoieriez',
    'dégravoierons',
    'dégravoieront',
    'dégravoyaient',
    'dégravoyasses',
    'dégravoyèrent',
    'dégressivités',
    'dégrevassions',
    'dégrèveraient',
    'dégringolades',
    'dégringolâmes',
    'dégringolasse',
    'dégringolâtes',
    'dégringolerai',
    'dégringoleras',
    'dégringolerez',
    'dégringolions',
    'dégrippassent',
    'dégrippassiez',
    'dégripperions',
    'dégrisassions',
    'dégriseraient',
    'dégrossassent',
    'dégrossassiez',
    'dégrosserions',
    'dégrossirions',
    'dégrossissage',
    'dégrossissais',
    'dégrossissait',
    'dégrossissant',
    'dégrossissent',
    'dégrossissiez',
    'dégrossissons',
    'dégrouillâmes',
    'dégrouillasse',
    'dégrouillâtes',
    'dégrouillerai',
    'dégrouilleras',
    'dégrouillerez',
    'dégrouillions',
    'déguerpirions',
    'déguerpissais',
    'déguerpissait',
    'déguerpissant',
    'déguerpissent',
    'déguerpissiez',
    'déguerpissons',
    'dégueulassent',
    'dégueulassiez',
    'dégueulerions',
    'déguisassions',
    'déguiseraient',
    'dégurgitaient',
    'dégurgitasses',
    'dégurgiterais',
    'dégurgiterait',
    'dégurgitèrent',
    'dégurgiteriez',
    'dégurgiterons',
    'dégurgiteront',
    'dégustassions',
    'dégustatrices',
    'dégusteraient',
    'déhanchassent',
    'déhanchassiez',
    'déhanchements',
    'déhancherions',
    'déhardassions',
    'déharderaient',
    'déharnachâmes',
    'déharnachasse',
    'déharnachâtes',
    'déharnacherai',
    'déharnacheras',
    'déharnacherez',
    'déharnachions',
    'déhottassions',
    'déhotteraient',
    'déhouillaient',
    'déhouillasses',
    'déhouillerais',
    'déhouillerait',
    'déhouillèrent',
    'déhouilleriez',
    'déhouillerons',
    'déhouilleront',
    'déjantassions',
    'déjanteraient',
    'déjaugeassent',
    'déjaugeassiez',
    'déjaugeraient',
    'déjauniraient',
    'déjaunissions',
    'déjetteraient',
    'déjeunassions',
    'déjeuneraient',
    'déjuchassions',
    'déjucheraient',
    'déjugeassions',
    'délabialisais',
    'délabialisait',
    'délabialisant',
    'délabialisées',
    'délabialisent',
    'délabialisera',
    'délabialisiez',
    'délabialisons',
    'délabrassions',
    'délabreraient',
    'délainassions',
    'délaineraient',
    'délaissassent',
    'délaissassiez',
    'délaissements',
    'délaisserions',
    'délaitassions',
    'délaiteraient',
    'délardassions',
    'délarderaient',
    'délassassions',
    'délasseraient',
    'délattassions',
    'délatteraient',
    'délectassions',
    'délecteraient',
    'déléguassions',
    'délégueraient',
    'délestassions',
    'délesteraient',
    'délibérassent',
    'délibérassiez',
    'délibérations',
    'délibératives',
    'délibératoire',
    'délibérerions',
    'délignifiâmes',
    'délignifiasse',
    'délignifiâtes',
    'délignifierai',
    'délignifieras',
    'délignifierez',
    'délignifiions',
    'délimitassent',
    'délimitassiez',
    'délimitations',
    'délimiterions',
    'délinéassions',
    'délinéeraient',
    'déliquescence',
    'déliquescente',
    'déliquescents',
    'délissassions',
    'délisseraient',
    'délitescences',
    'délitescentes',
    'délivrassions',
    'délivreraient',
    'délogeassions',
    'déloquassions',
    'déloqueraient',
    'deltoïdiennes',
    'délustrassent',
    'délustrassiez',
    'délustrerions',
    'démaçonnaient',
    'démaçonnasses',
    'démaçonnerais',
    'démaçonnerait',
    'démaçonnèrent',
    'démaçonneriez',
    'démaçonnerons',
    'démaçonneront',
    'démagnétisais',
    'démagnétisait',
    'démagnétisant',
    'démagnétisées',
    'démagnétisent',
    'démagnétisera',
    'démagnétisiez',
    'démagnétisons',
    'démaigririons',
    'démaigrissais',
    'démaigrissait',
    'démaigrissant',
    'démaigrissent',
    'démaigrissiez',
    'démaigrissons',
    'démaillassent',
    'démaillassiez',
    'démaillerions',
    'démaillotâmes',
    'démaillotasse',
    'démaillotâtes',
    'démailloterai',
    'démailloteras',
    'démailloterez',
    'démaillotions',
    'démanchassent',
    'démanchassiez',
    'démancherions',
    'demandassions',
    'demanderaient',
    'démangeaisons',
    'démangeassent',
    'démangeassiez',
    'démangeraient',
    'démantelaient',
    'démantelasses',
    'démantèlement',
    'démantèlerais',
    'démantèlerait',
    'démantelèrent',
    'démantèleriez',
    'démantèlerons',
    'démantèleront',
    'démantibulais',
    'démantibulait',
    'démantibulant',
    'démantibulées',
    'démantibulent',
    'démantibulera',
    'démantibuliez',
    'démantibulons',
    'démaquillâmes',
    'démaquillante',
    'démaquillants',
    'démaquillasse',
    'démaquillâtes',
    'démaquillerai',
    'démaquilleras',
    'démaquillerez',
    'démaquillions',
    'démarchassent',
    'démarchassiez',
    'démarcherions',
    'démariassions',
    'démarieraient',
    'démarquassent',
    'démarquassiez',
    'démarquerions',
    'démarrassions',
    'démarreraient',
    'démasclassent',
    'démasclassiez',
    'démasclerions',
    'démasquassent',
    'démasquassiez',
    'démasquerions',
    'démastiquâmes',
    'démastiquasse',
    'démastiquâtes',
    'démastiquerai',
    'démastiqueras',
    'démastiquerez',
    'démastiquions',
    'dématérialisa',
    'dématérialise',
    'dématérialisé',
    'démazoutaient',
    'démazoutasses',
    'démazouterais',
    'démazouterait',
    'démazoutèrent',
    'démazouteriez',
    'démazouterons',
    'démazouteront',
    'démembrassent',
    'démembrassiez',
    'démembrements',
    'démembrerions',
    'déménageaient',
    'déménageasses',
    'déménagements',
    'déménagerions',
    'démentiraient',
    'démentissions',
    'démerdassions',
    'démerderaient',
    'déméritassent',
    'déméritassiez',
    'démériterions',
    'déméthanisais',
    'déméthanisait',
    'déméthanisant',
    'déméthanisées',
    'déméthanisent',
    'déméthanisera',
    'déméthanisiez',
    'déméthanisons',
    'démeublassent',
    'démeublassiez',
    'démeublerions',
    'demeurassions',
    'demeureraient',
    'demi-brigades',
    'demi-colonnes',
    'demi-douzaine',
    'démiellassent',
    'démiellassiez',
    'démiellerions',
    'demi-journées',
    'démilitarisai',
    'démilitarisas',
    'démilitarisât',
    'démilitarisée',
    'démilitariser',
    'démilitarises',
    'démilitarisés',
    'démilitarisez',
    'demi-longueur',
    'demi-mondaine',
    'déminéralisai',
    'déminéralisas',
    'déminéralisât',
    'déminéralisée',
    'déminéraliser',
    'déminéralises',
    'déminéralisés',
    'déminéralisez',
    'demi-pensions',
    'demi-portions',
    'demi-produits',
    'demi-reliures',
    'demi-sommeils',
    'démissionnais',
    'démissionnait',
    'démissionnant',
    'démissionnées',
    'démissionnent',
    'démissionnera',
    'démissionniez',
    'démissionnons',
    'démobilisable',
    'démobilisâmes',
    'démobilisasse',
    'démobilisâtes',
    'démobiliserai',
    'démobiliseras',
    'démobiliserez',
    'démobilisions',
    'démocratiques',
    'démocratisais',
    'démocratisait',
    'démocratisant',
    'démocratisées',
    'démocratisent',
    'démocratisera',
    'démocratisiez',
    'démocratisons',
    'démodulassent',
    'démodulassiez',
    'démodulations',
    'démodulerions',
    'démographique',
    'démolissaient',
    'démolisseuses',
    'démonétisâmes',
    'démonétisasse',
    'démonétisâtes',
    'démonétiserai',
    'démonétiseras',
    'démonétiserez',
    'démonétisions',
    'démonstrateur',
    'démonstratifs',
    'démonstration',
    'démonstrative',
    'démontassions',
    'démonte-pneus',
    'démonteraient',
    'démontrassent',
    'démontrassiez',
    'démontrerions',
    'démoralisâmes',
    'démoralisante',
    'démoralisants',
    'démoralisasse',
    'démoralisâtes',
    'démoraliserai',
    'démoraliseras',
    'démoraliserez',
    'démoralisions',
    'démordissions',
    'démotivassent',
    'démotivassiez',
    'démotiverions',
    'démouchetâmes',
    'démouchetasse',
    'démouchetâtes',
    'démouchetions',
    'démouchettent',
    'démouchettera',
    'démoulassions',
    'démouleraient',
    'démouscaillai',
    'démouscaillas',
    'démouscaillât',
    'démouscaillée',
    'démouscailler',
    'démouscailles',
    'démouscaillés',
    'démouscaillez',
    'démoustiquais',
    'démoustiquait',
    'démoustiquant',
    'démoustiquées',
    'démoustiquent',
    'démoustiquera',
    'démoustiquiez',
    'démoustiquons',
    'démultipliais',
    'démultipliait',
    'démultipliant',
    'démultipliées',
    'démultiplient',
    'démultipliera',
    'démultipliiez',
    'démultiplions',
    'démunissaient',
    'démurgeassent',
    'démurgeassiez',
    'démurgeraient',
    'démuselassent',
    'démuselassiez',
    'démusellerais',
    'démusellerait',
    'démuselleriez',
    'démusellerons',
    'démuselleront',
    'démystifiâmes',
    'démystifiasse',
    'démystifiâtes',
    'démystifierai',
    'démystifieras',
    'démystifierez',
    'démystifiions',
    'démythifiâmes',
    'démythifiasse',
    'démythifiâtes',
    'démythifierai',
    'démythifieras',
    'démythifierez',
    'démythifiions',
    'dénantiraient',
    'dénantissions',
    'dénasalisâmes',
    'dénasalisasse',
    'dénasalisâtes',
    'dénasaliserai',
    'dénasaliseras',
    'dénasaliserez',
    'dénasalisions',
    'dénationalisa',
    'dénationalise',
    'dénationalisé',
    'dénattassions',
    'dénatteraient',
    'dénaturalisai',
    'dénaturalisas',
    'dénaturalisât',
    'dénaturalisée',
    'dénaturaliser',
    'dénaturalises',
    'dénaturalisés',
    'dénaturalisez',
    'dénaturassent',
    'dénaturassiez',
    'dénaturations',
    'dénaturerions',
    'dénébulisâmes',
    'dénébulisasse',
    'dénébulisâtes',
    'dénébuliserai',
    'dénébuliseras',
    'dénébuliserez',
    'déneigeassent',
    'déneigeassiez',
    'déneigeraient',
    'dénervassions',
    'dénerveraient',
    'déniaisassent',
    'déniaisassiez',
    'déniaiserions',
    'dénichassions',
    'dénicheraient',
    'dénickelaient',
    'dénickelasses',
    'dénickelerais',
    'dénickelerait',
    'dénickelèrent',
    'dénickeleriez',
    'dénickelerons',
    'dénickeleront',
    'dénicotinisai',
    'dénicotinisas',
    'dénicotinisât',
    'dénicotinisée',
    'dénicotiniser',
    'dénicotinises',
    'dénicotinisés',
    'dénicotinisez',
    'dénigrassions',
    'dénigreraient',
    'dénitrassions',
    'dénitreraient',
    'dénitrifiâmes',
    'dénitrifiasse',
    'dénitrifiâtes',
    'dénitrifierai',
    'dénitrifieras',
    'dénitrifierez',
    'dénitrifiions',
    'dénivelassent',
    'dénivelassiez',
    'dénivellation',
    'dénivellement',
    'dénivellerais',
    'dénivellerait',
    'dénivelleriez',
    'dénivellerons',
    'dénivelleront',
    'dénombrassent',
    'dénombrassiez',
    'dénombrements',
    'dénombrerions',
    'dénominateurs',
    'dénominations',
    'dénominatives',
    'dénommassions',
    'dénommeraient',
    'dénonçassions',
    'dénonceraient',
    'dénonciateurs',
    'dénonciations',
    'dénonciatrice',
    'dénotationnel',
    'dénoyautaient',
    'dénoyautasses',
    'dénoyauterais',
    'dénoyauterait',
    'dénoyautèrent',
    'dénoyauteriez',
    'dénoyauterons',
    'dénoyauteront',
    'dentelassions',
    'dentellerions',
    'dénucléarisai',
    'dénucléarisas',
    'dénucléarisât',
    'dénucléarisée',
    'dénucléariser',
    'dénucléarises',
    'dénucléarisés',
    'dénucléarisez',
    'déontologique',
    'dépaillassent',
    'dépaillassiez',
    'dépaillerions',
    'dépaisselâmes',
    'dépaisselasse',
    'dépaisselâtes',
    'dépaisselions',
    'dépaissellent',
    'dépaissellera',
    'dépalissaient',
    'dépalissasses',
    'dépalisserais',
    'dépalisserait',
    'dépalissèrent',
    'dépalisseriez',
    'dépalisserons',
    'dépalisseront',
    'dépannassions',
    'dépanneraient',
    'dépaquetaient',
    'dépaquetasses',
    'dépaquetèrent',
    'dépaquetterai',
    'dépaquetteras',
    'dépaquetterez',
    'déparaffinage',
    'déparaffinais',
    'déparaffinait',
    'déparaffinant',
    'déparaffinées',
    'déparaffinent',
    'déparaffinera',
    'déparaffiniez',
    'déparaffinons',
    'déparasitâmes',
    'déparasitasse',
    'déparasitâtes',
    'déparasiterai',
    'déparasiteras',
    'déparasiterez',
    'déparasitions',
    'dépareillâmes',
    'dépareillasse',
    'dépareillâtes',
    'dépareillerai',
    'dépareilleras',
    'dépareillerez',
    'dépareillions',
    'dépariassions',
    'déparieraient',
    'déparlassions',
    'déparleraient',
    'départageâmes',
    'départageasse',
    'départageâtes',
    'départagerais',
    'départagerait',
    'départagèrent',
    'départageriez',
    'départagerons',
    'départageront',
    'départemental',
    'départiraient',
    'départissions',
    'dépassassions',
    'dépasseraient',
    'dépassionnais',
    'dépassionnait',
    'dépassionnant',
    'dépassionnées',
    'dépassionnent',
    'dépassionnera',
    'dépassionniez',
    'dépassionnons',
    'dépatouillais',
    'dépatouillait',
    'dépatouillant',
    'dépatouillées',
    'dépatouillent',
    'dépatouillera',
    'dépatouilliez',
    'dépatouillons',
    'dépaysassions',
    'dépayseraient',
    'dépêchassions',
    'dépêcheraient',
    'dépeignassent',
    'dépeignassiez',
    'dépeignerions',
    'dépeignissent',
    'dépeignissiez',
    'dépeindraient',
    'dépelotonnais',
    'dépelotonnait',
    'dépelotonnant',
    'dépelotonnées',
    'dépelotonnent',
    'dépelotonnera',
    'dépelotonniez',
    'dépelotonnons',
    'dépendissions',
    'dépensassions',
    'dépenseraient',
    'dépérissaient',
    'dépérissement',
    'dépêtrassions',
    'dépêtreraient',
    'dépeuplassent',
    'dépeuplassiez',
    'dépeuplements',
    'dépeuplerions',
    'déphasassions',
    'déphaseraient',
    'dépiautassent',
    'dépiautassiez',
    'dépiauterions',
    'dépinglassent',
    'dépinglassiez',
    'dépinglerions',
    'dépiquassions',
    'dépiqueraient',
    'dépistassions',
    'dépisteraient',
    'déplaçassions',
    'déplaceraient',
    'déplafonnâmes',
    'déplafonnasse',
    'déplafonnâtes',
    'déplafonnerai',
    'déplafonneras',
    'déplafonnerez',
    'déplafonnions',
    'déplanquaient',
    'déplanquasses',
    'déplanquerais',
    'déplanquerait',
    'déplanquèrent',
    'déplanqueriez',
    'déplanquerons',
    'déplanqueront',
    'déplantassent',
    'déplantassiez',
    'déplantations',
    'déplanterions',
    'déplâtrassent',
    'déplâtrassiez',
    'déplâtrerions',
    'déplissassent',
    'déplissassiez',
    'déplisserions',
    'déploieraient',
    'déplombassent',
    'déplombassiez',
    'déplomberions',
    'déplorassions',
    'déploreraient',
    'déployassions',
    'déplumassions',
    'déplumeraient',
    'dépoétisaient',
    'dépoétisasses',
    'dépoétiserais',
    'dépoétiserait',
    'dépoétisèrent',
    'dépoétiseriez',
    'dépoétiserons',
    'dépoétiseront',
    'dépointassent',
    'dépointassiez',
    'dépointerions',
    'dépoitraillée',
    'dépoitraillés',
    'dépolarisâmes',
    'dépolarisasse',
    'dépolarisâtes',
    'dépolariserai',
    'dépolariseras',
    'dépolariserez',
    'dépolarisions',
    'dépolissaient',
    'dépolitisâmes',
    'dépolitisasse',
    'dépolitisâtes',
    'dépolitiserai',
    'dépolitiseras',
    'dépolitiserez',
    'dépolitisions',
    'dépolluassent',
    'dépolluassiez',
    'dépolluerions',
    'dépolymérisai',
    'dépolymérisas',
    'dépolymérisât',
    'dépolymérisée',
    'dépolymériser',
    'dépolymérises',
    'dépolymérisés',
    'dépolymérisez',
    'dépontillâmes',
    'dépontillasse',
    'dépontillâtes',
    'dépontillerai',
    'dépontilleras',
    'dépontillerez',
    'dépontillions',
    'dépopulations',
    'déportassions',
    'déporteraient',
    'dépossédaient',
    'dépossédasses',
    'déposséderais',
    'déposséderait',
    'dépossédèrent',
    'déposséderiez',
    'déposséderons',
    'déposséderont',
    'dépossessions',
    'dépoudrassent',
    'dépoudrassiez',
    'dépoudrerions',
    'dépouillaient',
    'dépouillasses',
    'dépouillement',
    'dépouillerais',
    'dépouillerait',
    'dépouillèrent',
    'dépouilleriez',
    'dépouillerons',
    'dépouilleront',
    'dépoussiérage',
    'dépoussiérais',
    'dépoussiérait',
    'dépoussiérant',
    'dépoussiérées',
    'dépoussièrent',
    'dépoussiérera',
    'dépoussiéreur',
    'dépoussiériez',
    'dépoussiérons',
    'dépravassions',
    'dépraveraient',
    'dépréciassent',
    'dépréciassiez',
    'dépréciateurs',
    'dépréciations',
    'dépréciatives',
    'dépréciatrice',
    'déprécierions',
    'déprédatrices',
    'déprendraient',
    'dépressurisai',
    'dépressurisas',
    'dépressurisât',
    'dépressurisée',
    'dépressuriser',
    'dépressurises',
    'dépressurisés',
    'dépressurisez',
    'déprimassions',
    'déprimeraient',
    'déprisassions',
    'dépriseraient',
    'déprogrammais',
    'déprogrammait',
    'déprogrammant',
    'déprogrammées',
    'déprogramment',
    'déprogrammera',
    'déprogrammiez',
    'déprogrammons',
    'déprolétarisa',
    'déprolétarise',
    'déprolétarisé',
    'dépropanisais',
    'dépropanisait',
    'dépropanisant',
    'dépropanisées',
    'dépropanisent',
    'dépropanisera',
    'dépropanisiez',
    'dépropanisons',
    'dépucelassent',
    'dépucelassiez',
    'dépucellerais',
    'dépucellerait',
    'dépucelleriez',
    'dépucellerons',
    'dépucelleront',
    'dépulpassions',
    'dépulperaient',
    'déquillassent',
    'déquillassiez',
    'déquillerions',
    'déracinassent',
    'déracinassiez',
    'déracinements',
    'déracinerions',
    'dérageassions',
    'déraidiraient',
    'déraidissions',
    'déraillassent',
    'déraillassiez',
    'déraillements',
    'déraillerions',
    'déraisonnable',
    'déraisonnâmes',
    'déraisonnasse',
    'déraisonnâtes',
    'déraisonnerai',
    'déraisonneras',
    'déraisonnerez',
    'déraisonnions',
    'dérangeassent',
    'dérangeassiez',
    'dérangeraient',
    'dératisassent',
    'dératisassiez',
    'dératisations',
    'dératiserions',
    'déréalisaient',
    'déréalisasses',
    'déréaliserais',
    'déréaliserait',
    'déréalisèrent',
    'déréaliseriez',
    'déréaliserons',
    'déréaliseront',
    'déréglassions',
    'dérégleraient',
    'dérégulassent',
    'dérégulassiez',
    'dérégulerions',
    'dérisoirement',
    'dermatologies',
    'dermatologues',
    'dermographies',
    'dérochassions',
    'dérocheraient',
    'dérogeassions',
    'dérouillaient',
    'dérouillasses',
    'dérouillerais',
    'dérouillerait',
    'dérouillèrent',
    'dérouilleriez',
    'dérouillerons',
    'dérouilleront',
    'déroulassions',
    'dérouleraient',
    'déroutassions',
    'dérouteraient',
    'désabonnaient',
    'désabonnasses',
    'désabonnement',
    'désabonnerais',
    'désabonnerait',
    'désabonnèrent',
    'désabonneriez',
    'désabonnerons',
    'désabonneront',
    'désabusassent',
    'désabusassiez',
    'désabusements',
    'désabuserions',
    'désacclimatai',
    'désacclimatas',
    'désacclimatât',
    'désacclimatée',
    'désacclimater',
    'désacclimates',
    'désacclimatés',
    'désacclimatez',
    'désaccordâmes',
    'désaccordasse',
    'désaccordâtes',
    'désaccorderai',
    'désaccorderas',
    'désaccorderez',
    'désaccordions',
    'désaccouplais',
    'désaccouplait',
    'désaccouplant',
    'désaccouplées',
    'désaccouplent',
    'désaccouplera',
    'désaccoupliez',
    'désaccouplons',
    'désaccoutumai',
    'désaccoutumas',
    'désaccoutumât',
    'désaccoutumée',
    'désaccoutumer',
    'désaccoutumes',
    'désaccoutumés',
    'désaccoutumez',
    'désacralisais',
    'désacralisait',
    'désacralisant',
    'désacralisées',
    'désacralisent',
    'désacralisera',
    'désacralisiez',
    'désacralisons',
    'désactivaient',
    'désactivasses',
    'désactiverais',
    'désactiverait',
    'désactivèrent',
    'désactiveriez',
    'désactiverons',
    'désactiveront',
    'désadaptaient',
    'désadaptasses',
    'désadapterais',
    'désadapterait',
    'désadaptèrent',
    'désadapteriez',
    'désadapterons',
    'désadapteront',
    'désaffectâmes',
    'désaffectasse',
    'désaffectâtes',
    'désaffecterai',
    'désaffecteras',
    'désaffecterez',
    'désaffections',
    'désaffiliâmes',
    'désaffiliasse',
    'désaffiliâtes',
    'désaffilierai',
    'désaffilieras',
    'désaffilierez',
    'désaffiliions',
    'désagençaient',
    'désagençasses',
    'désagencerais',
    'désagencerait',
    'désagencèrent',
    'désagenceriez',
    'désagencerons',
    'désagenceront',
    'désagrafaient',
    'désagrafasses',
    'désagraferais',
    'désagraferait',
    'désagrafèrent',
    'désagraferiez',
    'désagraferons',
    'désagraferont',
    'désagrégation',
    'désagrégeâmes',
    'désagrégeasse',
    'désagrégeâtes',
    'désagrégerais',
    'désagrégerait',
    'désagrégèrent',
    'désagrégeriez',
    'désagrégerons',
    'désagrégeront',
    'désaimantâmes',
    'désaimantasse',
    'désaimantâtes',
    'désaimanterai',
    'désaimanteras',
    'désaimanterez',
    'désaimantions',
    'désajustaient',
    'désajustasses',
    'désajusterais',
    'désajusterait',
    'désajustèrent',
    'désajusteriez',
    'désajusterons',
    'désajusteront',
    'désaliénaient',
    'désaliénasses',
    'désaliénerais',
    'désaliénerait',
    'désaliénèrent',
    'désaliéneriez',
    'désaliénerons',
    'désaliéneront',
    'désalignaient',
    'désalignasses',
    'désalignerais',
    'désalignerait',
    'désalignèrent',
    'désaligneriez',
    'désalignerons',
    'désaligneront',
    'désaltéraient',
    'désaltérasses',
    'désaltérerais',
    'désaltérerait',
    'désaltérèrent',
    'désaltéreriez',
    'désaltérerons',
    'désaltéreront',
    'désamarraient',
    'désamarrasses',
    'désamarrerais',
    'désamarrerait',
    'désamarrèrent',
    'désamarreriez',
    'désamarrerons',
    'désamarreront',
    'désambiguïsai',
    'désambiguïsas',
    'désambiguïsât',
    'désambiguïsée',
    'désambiguïser',
    'désambiguïses',
    'désambiguïsés',
    'désambiguïsez',
    'désamidonnais',
    'désamidonnait',
    'désamidonnant',
    'désamidonnées',
    'désamidonnent',
    'désamidonnera',
    'désamidonniez',
    'désamidonnons',
    'désamorçaient',
    'désamorçasses',
    'désamorcerais',
    'désamorcerait',
    'désamorcèrent',
    'désamorceriez',
    'désamorcerons',
    'désamorceront',
    'désannexaient',
    'désannexasses',
    'désannexerais',
    'désannexerait',
    'désannexèrent',
    'désannexeriez',
    'désannexerons',
    'désannexeront',
    'désappariâmes',
    'désappariasse',
    'désappariâtes',
    'désapparierai',
    'désapparieras',
    'désapparierez',
    'désappariions',
    'désappointais',
    'désappointait',
    'désappointant',
    'désappointées',
    'désappointent',
    'désappointera',
    'désappointiez',
    'désappointons',
    'désapprendrai',
    'désapprendras',
    'désapprendrez',
    'désapprenions',
    'désapprennent',
    'désapprissent',
    'désapprissiez',
    'désapprouvais',
    'désapprouvait',
    'désapprouvant',
    'désapprouvées',
    'désapprouvent',
    'désapprouvera',
    'désapprouviez',
    'désapprouvons',
    'désarçonnâmes',
    'désarçonnasse',
    'désarçonnâtes',
    'désarçonnerai',
    'désarçonneras',
    'désarçonnerez',
    'désarçonnions',
    'désargentâmes',
    'désargentasse',
    'désargentâtes',
    'désargenterai',
    'désargenteras',
    'désargenterez',
    'désargentions',
    'désarmassions',
    'désarmeraient',
    'désarrimaient',
    'désarrimasses',
    'désarrimerais',
    'désarrimerait',
    'désarrimèrent',
    'désarrimeriez',
    'désarrimerons',
    'désarrimeront',
    'désarticulais',
    'désarticulait',
    'désarticulant',
    'désarticulées',
    'désarticulent',
    'désarticulera',
    'désarticuliez',
    'désarticulons',
    'désassemblage',
    'désassemblais',
    'désassemblait',
    'désassemblant',
    'désassemblées',
    'désassemblent',
    'désassemblera',
    'désassembliez',
    'désassemblons',
    'désassimilais',
    'désassimilait',
    'désassimilant',
    'désassimilées',
    'désassimilent',
    'désassimilera',
    'désassimiliez',
    'désassimilons',
    'désassortîmes',
    'désassortirai',
    'désassortiras',
    'désassortirez',
    'désassortisse',
    'désassortîtes',
    'désatomisâmes',
    'désatomisasse',
    'désatomisâtes',
    'désatomiserai',
    'désatomiseras',
    'désatomiserez',
    'désatomisions',
    'désavantageai',
    'désavantageas',
    'désavantageât',
    'désavantagées',
    'désavantagent',
    'désavantagera',
    'désavantageux',
    'désavantagiez',
    'désaveuglâmes',
    'désaveuglasse',
    'désaveuglâtes',
    'désaveuglerai',
    'désaveugleras',
    'désaveuglerez',
    'désaveuglions',
    'désavouassent',
    'désavouassiez',
    'désavouerions',
    'descellassent',
    'descellassiez',
    'descellements',
    'descellerions',
    'descendissent',
    'descendissiez',
    'descendraient',
    'descriptibles',
    'déséchouaient',
    'déséchouasses',
    'déséchouerais',
    'déséchouerait',
    'déséchouèrent',
    'déséchoueriez',
    'déséchouerons',
    'déséchoueront',
    'déségrégation',
    'désembourbais',
    'désembourbait',
    'désembourbant',
    'désembourbées',
    'désembourbent',
    'désembourbera',
    'désembourbiez',
    'désembourbons',
    'désembraierai',
    'désembraieras',
    'désembraierez',
    'désembrayâmes',
    'désembrayasse',
    'désembrayâtes',
    'désembrayerai',
    'désembrayeras',
    'désembrayerez',
    'désembrayions',
    'désembuassent',
    'désembuassiez',
    'désembuerions',
    'désemmanchais',
    'désemmanchait',
    'désemmanchant',
    'désemmanchées',
    'désemmanchent',
    'désemmanchera',
    'désemmanchiez',
    'désemmanchons',
    'désemparaient',
    'désemparasses',
    'désemparerais',
    'désemparerait',
    'désemparèrent',
    'désempareriez',
    'désemparerons',
    'désempareront',
    'désempesaient',
    'désempesasses',
    'désempèserais',
    'désempèserait',
    'désempesèrent',
    'désempèseriez',
    'désempèserons',
    'désempèseront',
    'désemplirions',
    'désemplissais',
    'désemplissait',
    'désemplissant',
    'désemplissiez',
    'désemplissons',
    'désemprisonna',
    'désemprisonne',
    'désemprisonné',
    'désencadrâmes',
    'désencadrasse',
    'désencadrâtes',
    'désencadrerai',
    'désencadreras',
    'désencadrerez',
    'désencadrions',
    'désencartâmes',
    'désencartasse',
    'désencartâtes',
    'désencarterai',
    'désencarteras',
    'désencarterez',
    'désencartions',
    'désenchaînais',
    'désenchaînait',
    'désenchaînant',
    'désenchaînées',
    'désenchaînent',
    'désenchaînera',
    'désenchaîniez',
    'désenchaînons',
    'désenchantais',
    'désenchantait',
    'désenchantant',
    'désenchantées',
    'désenchantent',
    'désenchantera',
    'désenchantiez',
    'désenchantons',
    'désenclavâmes',
    'désenclavasse',
    'désenclavâtes',
    'désenclaverai',
    'désenclaveras',
    'désenclaverez',
    'désenclavions',
    'désencollâmes',
    'désencollasse',
    'désencollâtes',
    'désencollerai',
    'désencolleras',
    'désencollerez',
    'désencollions',
    'désencombrais',
    'désencombrait',
    'désencombrant',
    'désencombrées',
    'désencombrent',
    'désencombrera',
    'désencombriez',
    'désencombrons',
    'désencrassais',
    'désencrassait',
    'désencrassant',
    'désencrassées',
    'désencrassent',
    'désencrassera',
    'désencrassiez',
    'désencrassons',
    'désendettâmes',
    'désendettasse',
    'désendettâtes',
    'désendetterai',
    'désendetteras',
    'désendetterez',
    'désendettions',
    'désénervaient',
    'désénervasses',
    'désénerverais',
    'désénerverait',
    'désénervèrent',
    'désénerveriez',
    'désénerverons',
    'désénerveront',
    'désenfilaient',
    'désenfilasses',
    'désenfilerais',
    'désenfilerait',
    'désenfilèrent',
    'désenfileriez',
    'désenfilerons',
    'désenfileront',
    'désenflammais',
    'désenflammait',
    'désenflammant',
    'désenflammées',
    'désenflamment',
    'désenflammera',
    'désenflammiez',
    'désenflammons',
    'désenflassent',
    'désenflassiez',
    'désenflerions',
    'désenfumaient',
    'désenfumasses',
    'désenfumerais',
    'désenfumerait',
    'désenfumèrent',
    'désenfumeriez',
    'désenfumerons',
    'désenfumeront',
    'désengageâmes',
    'désengageasse',
    'désengageâtes',
    'désengagement',
    'désengagerais',
    'désengagerait',
    'désengagèrent',
    'désengageriez',
    'désengagerons',
    'désengageront',
    'désengorgeais',
    'désengorgeait',
    'désengorgeant',
    'désengorgeons',
    'désengorgerai',
    'désengorgeras',
    'désengorgerez',
    'désengorgions',
    'désengourdies',
    'désengourdira',
    'désenivraient',
    'désenivrasses',
    'désenivrerais',
    'désenivrerait',
    'désenivrèrent',
    'désenivreriez',
    'désenivrerons',
    'désenivreront',
    'désenlaçaient',
    'désenlaçasses',
    'désenlacerais',
    'désenlacerait',
    'désenlacèrent',
    'désenlaceriez',
    'désenlacerons',
    'désenlaceront',
    'désenlaidîmes',
    'désenlaidirai',
    'désenlaidiras',
    'désenlaidirez',
    'désenlaidisse',
    'désenlaidîtes',
    'désennuierais',
    'désennuierait',
    'désennuieriez',
    'désennuierons',
    'désennuieront',
    'désennuyaient',
    'désennuyasses',
    'désennuyèrent',
    'désenraierais',
    'désenraierait',
    'désenraieriez',
    'désenraierons',
    'désenraieront',
    'désenrayaient',
    'désenrayasses',
    'désenrayerais',
    'désenrayerait',
    'désenrayèrent',
    'désenrayeriez',
    'désenrayerons',
    'désenrayeront',
    'désenrhumâmes',
    'désenrhumasse',
    'désenrhumâtes',
    'désenrhumerai',
    'désenrhumeras',
    'désenrhumerez',
    'désenrhumions',
    'désenrouaient',
    'désenrouasses',
    'désenrouerais',
    'désenrouerait',
    'désenrouèrent',
    'désenroueriez',
    'désenrouerons',
    'désenroueront',
    'désensablâmes',
    'désensablasse',
    'désensablâtes',
    'désensablerai',
    'désensableras',
    'désensablerez',
    'désensablions',
    'désensibilisa',
    'désensibilise',
    'désensibilisé',
    'désensorcelai',
    'désensorcelas',
    'désensorcelât',
    'désensorcelée',
    'désensorceler',
    'désensorcelés',
    'désensorcelez',
    'désensorcelle',
    'désentoilages',
    'désentoilâmes',
    'désentoilasse',
    'désentoilâtes',
    'désentoilerai',
    'désentoileras',
    'désentoilerez',
    'désentoilions',
    'désentortilla',
    'désentortille',
    'désentortillé',
    'désentravâmes',
    'désentravasse',
    'désentravâtes',
    'désentraverai',
    'désentraveras',
    'désentraverez',
    'désentravions',
    'désenvasaient',
    'désenvasasses',
    'désenvaserais',
    'désenvaserait',
    'désenvasèrent',
    'désenvaseriez',
    'désenvaserons',
    'désenvaseront',
    'désenveloppai',
    'désenveloppas',
    'désenveloppât',
    'désenveloppée',
    'désenvelopper',
    'désenveloppes',
    'désenveloppés',
    'désenveloppez',
    'désenvenimais',
    'désenvenimait',
    'désenvenimant',
    'désenvenimées',
    'désenveniment',
    'désenvenimera',
    'désenvenimiez',
    'désenvenimons',
    'désenverguais',
    'désenverguait',
    'désenverguant',
    'désenverguées',
    'désenverguent',
    'désenverguera',
    'désenverguiez',
    'désenverguons',
    'désépaissîmes',
    'désépaissirai',
    'désépaissiras',
    'désépaissirez',
    'désépaississe',
    'désépaissîtes',
    'déséquilibrai',
    'déséquilibras',
    'déséquilibrât',
    'déséquilibrée',
    'déséquilibrer',
    'déséquilibres',
    'déséquilibrés',
    'déséquilibrez',
    'déséquipaient',
    'déséquipasses',
    'déséquiperais',
    'déséquiperait',
    'déséquipèrent',
    'déséquiperiez',
    'déséquiperons',
    'déséquiperont',
    'désertassions',
    'déserteraient',
    'désespéraient',
    'désespérances',
    'désespérantes',
    'désespérasses',
    'désespérément',
    'désespérerais',
    'désespérerait',
    'désespérèrent',
    'désespéreriez',
    'désespérerons',
    'désespéreront',
    'désétablirais',
    'désétablirait',
    'désétablirent',
    'désétabliriez',
    'désétablirons',
    'désétabliront',
    'désétablisses',
    'désétablissez',
    'désétamassent',
    'désétamassiez',
    'désétamerions',
    'désétatisâmes',
    'désétatisasse',
    'désétatisâtes',
    'désétatiserai',
    'désétatiseras',
    'désétatiserez',
    'désétatisions',
    'déshabillages',
    'déshabillâmes',
    'déshabillasse',
    'déshabillâtes',
    'déshabillerai',
    'déshabilleras',
    'déshabillerez',
    'déshabillions',
    'déshabituâmes',
    'déshabituasse',
    'déshabituâtes',
    'déshabituerai',
    'déshabitueras',
    'déshabituerez',
    'déshabituions',
    'désherbassent',
    'désherbassiez',
    'désherberions',
    'déshéritaient',
    'déshéritasses',
    'déshériterais',
    'déshériterait',
    'déshéritèrent',
    'déshériteriez',
    'déshériterons',
    'déshériteront',
    'déshonnêtetés',
    'déshonoraient',
    'déshonorantes',
    'déshonorasses',
    'déshonorerais',
    'déshonorerait',
    'déshonorèrent',
    'déshonoreriez',
    'déshonorerons',
    'déshonoreront',
    'déshuilassent',
    'déshuilassiez',
    'déshuilerions',
    'déshumanisais',
    'déshumanisait',
    'déshumanisant',
    'déshumanisées',
    'déshumanisent',
    'déshumanisera',
    'déshumanisiez',
    'déshumanisons',
    'déshumidifiai',
    'déshumidifias',
    'déshumidifiât',
    'déshumidifiée',
    'déshumidifier',
    'déshumidifies',
    'déshumidifiés',
    'déshumidifiez',
    'déshydratâmes',
    'déshydratante',
    'déshydratants',
    'déshydratasse',
    'déshydratâtes',
    'déshydraterai',
    'déshydrateras',
    'déshydraterez',
    'déshydrations',
    'déshydrogénai',
    'déshydrogénas',
    'déshydrogénât',
    'déshydrogénée',
    'déshydrogéner',
    'déshydrogénés',
    'déshydrogènes',
    'déshydrogénez',
    'déshypothéqua',
    'déshypothéqué',
    'déshypothèque',
    'désignassions',
    'désigneraient',
    'désillusionna',
    'désillusionne',
    'désillusionné',
    'désincarnâmes',
    'désincarnasse',
    'désincarnâtes',
    'désincarnerai',
    'désincarneras',
    'désincarnerez',
    'désincarnions',
    'désincorporai',
    'désincorporas',
    'désincorporât',
    'désincorporée',
    'désincorporer',
    'désincorpores',
    'désincorporés',
    'désincorporez',
    'désincrustais',
    'désincrustait',
    'désincrustant',
    'désincrustées',
    'désincrustent',
    'désincrustera',
    'désincrustiez',
    'désincrustons',
    'désinculpâmes',
    'désinculpasse',
    'désinculpâtes',
    'désinculperai',
    'désinculperas',
    'désinculperez',
    'désinculpions',
    'désindexaient',
    'désindexasses',
    'désindexerais',
    'désindexerait',
    'désindexèrent',
    'désindexeriez',
    'désindexerons',
    'désindexeront',
    'désinentielle',
    'désinfectâmes',
    'désinfectante',
    'désinfectants',
    'désinfectasse',
    'désinfectâtes',
    'désinfecterai',
    'désinfecteras',
    'désinfecterez',
    'désinfections',
    'désinflations',
    'désingularisa',
    'désingularise',
    'désingularisé',
    'désinsectisai',
    'désinsectisas',
    'désinsectisât',
    'désinsectisée',
    'désinsectiser',
    'désinsectises',
    'désinsectisés',
    'désinsectisez',
    'désinsertions',
    'désintégrâmes',
    'désintégrasse',
    'désintégrâtes',
    'désintégrerai',
    'désintégreras',
    'désintégrerez',
    'désintégrions',
    'désintéressai',
    'désintéressas',
    'désintéressât',
    'désintéressée',
    'désintéresser',
    'désintéresses',
    'désintéressés',
    'désintéressez',
    'désintoxiquai',
    'désintoxiquas',
    'désintoxiquât',
    'désintoxiquée',
    'désintoxiquer',
    'désintoxiques',
    'désintoxiqués',
    'désintoxiquez',
    'désinvestîmes',
    'désinvestirai',
    'désinvestiras',
    'désinvestirez',
    'désinvestisse',
    'désinvestîtes',
    'désinvitaient',
    'désinvitasses',
    'désinviterais',
    'désinviterait',
    'désinvitèrent',
    'désinviteriez',
    'désinviterons',
    'désinviteront',
    'désinvoltures',
    'désistassions',
    'désisteraient',
    'désobéiraient',
    'désobéissance',
    'désobéissante',
    'désobéissants',
    'désobéissions',
    'désobligeâmes',
    'désobligeance',
    'désobligeante',
    'désobligeants',
    'désobligeasse',
    'désobligeâtes',
    'désobligerais',
    'désobligerait',
    'désobligèrent',
    'désobligeriez',
    'désobligerons',
    'désobligeront',
    'désobstruâmes',
    'désobstruasse',
    'désobstruâtes',
    'désobstruerai',
    'désobstrueras',
    'désobstruerez',
    'désoccupaient',
    'désoccupasses',
    'désoccuperais',
    'désoccuperait',
    'désoccupèrent',
    'désoccuperiez',
    'désoccuperons',
    'désoccuperont',
    'désodorisâmes',
    'désodorisante',
    'désodorisants',
    'désodorisasse',
    'désodorisâtes',
    'désodoriserai',
    'désodoriseras',
    'désodoriserez',
    'désodorisions',
    'désoeuvrement',
    'désolidarisai',
    'désolidarisas',
    'désolidarisât',
    'désolidarisée',
    'désolidariser',
    'désolidarises',
    'désolidarisés',
    'désolidarisez',
    'désopilassent',
    'désopilassiez',
    'désopilerions',
    'désorbassions',
    'désorberaient',
    'désorbitaient',
    'désorbitasses',
    'désorbiterais',
    'désorbiterait',
    'désorbitèrent',
    'désorbiteriez',
    'désorbiterons',
    'désorbiteront',
    'désordonnâmes',
    'désordonnasse',
    'désordonnâtes',
    'désordonnerai',
    'désordonneras',
    'désordonnerez',
    'désordonnions',
    'désorganisais',
    'désorganisait',
    'désorganisant',
    'désorganisées',
    'désorganisent',
    'désorganisera',
    'désorganisiez',
    'désorganisons',
    'désorientâmes',
    'désorientasse',
    'désorientâtes',
    'désorienterai',
    'désorienteras',
    'désorienterez',
    'désorientions',
    'désossassions',
    'désosseraient',
    'désoxydassent',
    'désoxydassiez',
    'désoxydations',
    'désoxyderions',
    'désoxygénâmes',
    'désoxygénasse',
    'désoxygénâtes',
    'désoxygénerai',
    'désoxygéneras',
    'désoxygénerez',
    'désoxygénions',
    'desquamassent',
    'desquamassiez',
    'desquamations',
    'desquamerions',
    'dessablassent',
    'dessablassiez',
    'dessablerions',
    'dessaisirions',
    'dessaisissais',
    'dessaisissait',
    'dessaisissant',
    'dessaisissent',
    'dessaisissiez',
    'dessaisissons',
    'dessalassions',
    'dessaleraient',
    'dessanglaient',
    'dessanglasses',
    'dessanglerais',
    'dessanglerait',
    'dessanglèrent',
    'dessangleriez',
    'dessanglerons',
    'dessangleront',
    'dessaoulaient',
    'dessaoulasses',
    'dessaoulerais',
    'dessaoulerait',
    'dessaoulèrent',
    'dessaouleriez',
    'dessaoulerons',
    'dessaouleront',
    'desséchassent',
    'desséchassiez',
    'dessèchements',
    'dessécherions',
    'dessellassent',
    'dessellassiez',
    'dessellerions',
    'desserrassent',
    'desserrassiez',
    'desserrerions',
    'dessertirions',
    'dessertissais',
    'dessertissait',
    'dessertissant',
    'dessertissent',
    'dessertissiez',
    'dessertissons',
    'desservirions',
    'desservissent',
    'desservissiez',
    'dessiccations',
    'dessiccatives',
    'dessillassent',
    'dessillassiez',
    'dessillerions',
    'dessinassions',
    'dessinatrices',
    'dessineraient',
    'dessolassions',
    'dessoleraient',
    'dessoudassent',
    'dessoudassiez',
    'dessouderions',
    'dessoulassent',
    'dessoûlassent',
    'dessoulassiez',
    'dessoûlassiez',
    'dessoulerions',
    'dessoûlerions',
    'dessuitassent',
    'dessuitassiez',
    'dessuiterions',
    'dessus-de-lit',
    'déstabilisais',
    'déstabilisait',
    'déstabilisant',
    'déstabilisées',
    'déstabilisent',
    'déstabilisera',
    'déstabilisiez',
    'déstabilisons',
    'destinassions',
    'destinataires',
    'destineraient',
    'destituerions',
    'déstockassent',
    'déstockassiez',
    'déstockerions',
    'destructibles',
    'destructrices',
    'destructurais',
    'destructurait',
    'destructurant',
    'destructurées',
    'destructurent',
    'destructurera',
    'destructuriez',
    'destructurons',
    'désulfitaient',
    'désulfitasses',
    'désulfiterais',
    'désulfiterait',
    'désulfitèrent',
    'désulfiteriez',
    'désulfiterons',
    'désulfiteront',
    'désulfuraient',
    'désulfurasses',
    'désulfuration',
    'désulfurerais',
    'désulfurerait',
    'désulfurèrent',
    'désulfureriez',
    'désulfurerons',
    'désulfureront',
    'désunissaient',
    'désynchronisa',
    'désynchronise',
    'désynchronisé',
    'détachassions',
    'détacheraient',
    'détaillassent',
    'détaillassiez',
    'détaillerions',
    'détallassions',
    'détalleraient',
    'détapissaient',
    'détapissasses',
    'détapisserais',
    'détapisserait',
    'détapissèrent',
    'détapisseriez',
    'détapisserons',
    'détapisseront',
    'détartrassent',
    'détartrassiez',
    'détartrerions',
    'détectassions',
    'détecteraient',
    'déteignissent',
    'déteignissiez',
    'déteindraient',
    'dételleraient',
    'détendissions',
    'détergeassent',
    'détergeassiez',
    'détergeraient',
    'détérioraient',
    'détériorasses',
    'détérioration',
    'détériorerais',
    'détériorerait',
    'détériorèrent',
    'détérioreriez',
    'détériorerons',
    'détérioreront',
    'déterminables',
    'déterminaient',
    'déterminantes',
    'déterminasses',
    'déterminatifs',
    'détermination',
    'déterminative',
    'déterminerais',
    'déterminerait',
    'déterminèrent',
    'détermineriez',
    'déterminerons',
    'détermineront',
    'déterminismes',
    'déterministes',
    'déterrassions',
    'déterreraient',
    'détestassions',
    'détesteraient',
    'détiendraient',
    'détissassions',
    'détisseraient',
    'détonnassions',
    'détonnelaient',
    'détonnelasses',
    'détonnelèrent',
    'détonnellerai',
    'détonnelleras',
    'détonnellerez',
    'détonneraient',
    'détordissions',
    'détortillâmes',
    'détortillasse',
    'détortillâtes',
    'détortillerai',
    'détortilleras',
    'détortillerez',
    'détortillions',
    'détourassions',
    'détoureraient',
    'détournassent',
    'détournassiez',
    'détournements',
    'détournerions',
    'détoxications',
    'détractassent',
    'détractassiez',
    'détracterions',
    'détranchaient',
    'détranchasses',
    'détrancherais',
    'détrancherait',
    'détranchèrent',
    'détrancheriez',
    'détrancherons',
    'détrancheront',
    'détransposais',
    'détransposait',
    'détransposant',
    'détransposées',
    'détransposent',
    'détransposera',
    'détransposiez',
    'détransposons',
    'détraquassent',
    'détraquassiez',
    'détraquements',
    'détraquerions',
    'détrempassent',
    'détrempassiez',
    'détremperions',
    'détressassent',
    'détressassiez',
    'détresserions',
    'détricotaient',
    'détricotasses',
    'détricoterais',
    'détricoterait',
    'détricotèrent',
    'détricoteriez',
    'détricoterons',
    'détricoteront',
    'détrompassent',
    'détrompassiez',
    'détromperions',
    'détrônassions',
    'détronchaient',
    'détronchasses',
    'détroncherais',
    'détroncherait',
    'détronchèrent',
    'détroncheriez',
    'détroncherons',
    'détroncheront',
    'détrôneraient',
    'détroquassent',
    'détroquassiez',
    'détroquerions',
    'détroussaient',
    'détroussasses',
    'détrousserais',
    'détrousserait',
    'détroussèrent',
    'détrousseriez',
    'détrousserons',
    'détrousseront',
    'détrousseuses',
    'détruisissent',
    'détruisissiez',
    'détumescences',
    'dévalisassent',
    'dévalisassiez',
    'dévaliserions',
    'dévalorisâmes',
    'dévalorisante',
    'dévalorisants',
    'dévalorisasse',
    'dévalorisâtes',
    'dévaloriserai',
    'dévaloriseras',
    'dévaloriserez',
    'dévalorisions',
    'dévaluassions',
    'dévaluerasses',
    'dévaluerèrent',
    'devançassions',
    'devanceraient',
    'dévastassions',
    'dévastatrices',
    'dévasteraient',
    'développables',
    'développaient',
    'développantes',
    'développasses',
    'développement',
    'développerais',
    'développerait',
    'développèrent',
    'développeriez',
    'développerons',
    'développeront',
    'déventassions',
    'déventeraient',
    'déverdiraient',
    'déverdissions',
    'dévergondages',
    'dévergondâmes',
    'dévergondasse',
    'dévergondâtes',
    'dévergonderai',
    'dévergonderas',
    'dévergonderez',
    'dévergondions',
    'déverguassent',
    'déverguassiez',
    'déverguerions',
    'déverniraient',
    'dévernissions',
    'déverrouillai',
    'déverrouillas',
    'déverrouillât',
    'déverrouillée',
    'déverrouiller',
    'déverrouilles',
    'déverrouillés',
    'déverrouillez',
    'déversassions',
    'déverseraient',
    'deviendraient',
    'dévirilisâmes',
    'dévirilisasse',
    'dévirilisâtes',
    'déviriliserai',
    'déviriliseras',
    'déviriliserez',
    'dévirilisions',
    'dévirolassent',
    'dévirolassiez',
    'dévirolerions',
    'dévisageaient',
    'dévisageasses',
    'dévisagerions',
    'dévissassions',
    'dévisserasses',
    'dévisserèrent',
    'dévitalisâmes',
    'dévitalisasse',
    'dévitalisâtes',
    'dévitaliserai',
    'dévitaliseras',
    'dévitaliserez',
    'dévitalisions',
    'dévitrifiâmes',
    'dévitrifiasse',
    'dévitrifiâtes',
    'dévitrifierai',
    'dévitrifieras',
    'dévitrifierez',
    'dévitrifiions',
    'dévoilassions',
    'dévoilerasses',
    'dévoilerèrent',
    'dévoltassions',
    'dévolteraient',
    'dextrocardies',
    'diachroniques',
    'diacoustiques',
    'diagnostiquai',
    'diagnostiquas',
    'diagnostiquât',
    'diagnostiquée',
    'diagnostiquer',
    'diagnostiques',
    'diagnostiqués',
    'diagnostiquez',
    'dialectalisai',
    'dialectalisas',
    'dialectalisât',
    'dialectalisée',
    'dialectaliser',
    'dialectalises',
    'dialectalisés',
    'dialectalisez',
    'dialecticiens',
    'dialectisâmes',
    'dialectisasse',
    'dialectisâtes',
    'dialectiserai',
    'dialectiseras',
    'dialectiserez',
    'dialectisions',
    'dialectologie',
    'dialectologue',
    'dialoguassent',
    'dialoguassiez',
    'dialoguerions',
    'dialysassions',
    'dialyseraient',
    'diamagnétisme',
    'diamantassent',
    'diamantassiez',
    'diamanterions',
    'diamantifères',
    'diamidophénol',
    'diaphorétique',
    'diaphragmâmes',
    'diaphragmasse',
    'diaphragmâtes',
    'diaphragmerai',
    'diaphragmeras',
    'diaphragmerez',
    'diaphragmions',
    'dichotomiques',
    'dichromatique',
    'dicotylédones',
    'dictatoriales',
    'dictionnaires',
    'diélectriques',
    'diététicienne',
    'diffamassions',
    'diffamatoires',
    'diffamatrices',
    'diffameraient',
    'différassions',
    'différenciais',
    'différenciait',
    'différenciant',
    'différenciées',
    'différencient',
    'différenciera',
    'différenciiez',
    'différencions',
    'différentiels',
    'différeraient',
    'difficilement',
    'difformassent',
    'difformassiez',
    'difformerions',
    'diffractaient',
    'diffractasses',
    'diffracterais',
    'diffracterait',
    'diffractèrent',
    'diffracteriez',
    'diffracterons',
    'diffracteront',
    'diffusassions',
    'diffuseraient',
    'digestibilité',
    'digitalisâmes',
    'digitalisasse',
    'digitalisâtes',
    'digitalisions',
    'digtaliserais',
    'digtaliserait',
    'digtaliseriez',
    'digtaliserons',
    'digtaliseront',
    'dilacérassent',
    'dilacérassiez',
    'dilacérations',
    'dilacérerions',
    'dilapidassent',
    'dilapidassiez',
    'dilapidateurs',
    'dilapidations',
    'dilapidatrice',
    'dilapiderions',
    'dilatabilités',
    'dilettantisme',
    'dimensionnais',
    'dimensionnait',
    'dimensionnant',
    'dimensionnées',
    'dimensionnels',
    'dimensionnent',
    'dimensionnera',
    'dimensionniez',
    'dimensionnons',
    'diminuassions',
    'diminueraient',
    'dindonnassent',
    'dindonnassiez',
    'dindonnerions',
    'diphtongaison',
    'diphtonguâmes',
    'diphtonguasse',
    'diphtonguâtes',
    'diphtonguerai',
    'diphtongueras',
    'diphtonguerez',
    'diphtonguions',
    'diplômassions',
    'diplomatiques',
    'diplômeraient',
    'directivismes',
    'directoriales',
    'dirigeassions',
    'disaccharides',
    'discarthroses',
    'discernassent',
    'discernassiez',
    'discernements',
    'discernerions',
    'disciplinable',
    'disciplinaire',
    'disciplinâmes',
    'disciplinasse',
    'disciplinâtes',
    'disciplinerai',
    'disciplineras',
    'disciplinerez',
    'disciplinions',
    'discographies',
    'discontacteur',
    'discontinuais',
    'discontinuait',
    'discontinuant',
    'discontinuent',
    'discontinuera',
    'discontinuiez',
    'discontinuité',
    'discontinuons',
    'disconvenions',
    'disconviendra',
    'disconviennes',
    'disconvinrent',
    'disconvinsses',
    'discordassent',
    'discordassiez',
    'discorderions',
    'discothécaire',
    'discourraient',
    'discourtoises',
    'discourtoisie',
    'discourussent',
    'discourussiez',
    'discréditâmes',
    'discréditasse',
    'discréditâtes',
    'discréditerai',
    'discréditeras',
    'discréditerez',
    'discréditions',
    'discriminâmes',
    'discriminante',
    'discriminants',
    'discriminasse',
    'discriminâtes',
    'discriminerai',
    'discrimineras',
    'discriminerez',
    'discriminions',
    'disculpassent',
    'disculpassiez',
    'disculpations',
    'disculperions',
    'discutaillais',
    'discutaillait',
    'discutaillant',
    'discutaillées',
    'discutaillent',
    'discutaillera',
    'discutailliez',
    'discutaillons',
    'discutassions',
    'discuteraient',
    'disgraciaient',
    'disgraciasses',
    'disgracierais',
    'disgracierait',
    'disgracièrent',
    'disgracieriez',
    'disgracierons',
    'disgracieront',
    'disgracieuses',
    'disjoignaient',
    'disjoignirent',
    'disjoignisses',
    'disjoindrions',
    'disjonctaient',
    'disjonctasses',
    'disjoncterais',
    'disjoncterait',
    'disjonctèrent',
    'disjoncteriez',
    'disjoncterons',
    'disjoncteront',
    'disloquassent',
    'disloquassiez',
    'disloquerions',
    'disparaissais',
    'disparaissait',
    'disparaissant',
    'disparaissent',
    'disparaissiez',
    'disparaissons',
    'disparaîtrais',
    'disparaîtrait',
    'disparaîtriez',
    'disparaîtrons',
    'disparaîtront',
    'disparussions',
    'dispendieuses',
    'dispensassent',
    'dispensassiez',
    'dispensateurs',
    'dispensatrice',
    'dispenserions',
    'dispersassent',
    'dispersassiez',
    'disperserions',
    'disponibilité',
    'disposassions',
    'disposeraient',
    'disproportion',
    'disputaillais',
    'disputaillait',
    'disputaillant',
    'disputaillent',
    'disputaillera',
    'disputailliez',
    'disputaillons',
    'disputassions',
    'disputeraient',
    'disqualifiais',
    'disqualifiait',
    'disqualifiant',
    'disqualifiées',
    'disqualifient',
    'disqualifiera',
    'disqualifiiez',
    'disqualifions',
    'dissemblables',
    'dissemblances',
    'disséminaient',
    'disséminasses',
    'dissémination',
    'disséminerais',
    'disséminerait',
    'disséminèrent',
    'dissémineriez',
    'disséminerons',
    'dissémineront',
    'dissentiments',
    'disséquassent',
    'disséquassiez',
    'disséquerions',
    'dissertassent',
    'dissertassiez',
    'dissertations',
    'disserterions',
    'dissimilation',
    'dissimilitude',
    'dissimulaient',
    'dissimulasses',
    'dissimulateur',
    'dissimulation',
    'dissimulerais',
    'dissimulerait',
    'dissimulèrent',
    'dissimuleriez',
    'dissimulerons',
    'dissimuleront',
    'dissipassions',
    'dissipatrices',
    'dissiperaient',
    'dissociassent',
    'dissociassiez',
    'dissociations',
    'dissocierions',
    'dissolubilité',
    'dissonassions',
    'dissoneraient',
    'dissoudraient',
    'dissuadassent',
    'dissuadassiez',
    'dissuaderions',
    'dissyllabique',
    'dissymétrique',
    'distançassent',
    'distançassiez',
    'distancerions',
    'distanciaient',
    'distanciasses',
    'distanciation',
    'distancierais',
    'distancierait',
    'distancièrent',
    'distancieriez',
    'distancierons',
    'distancieront',
    'distendissent',
    'distendissiez',
    'distendraient',
    'distillassent',
    'distillassiez',
    'distillateurs',
    'distillations',
    'distillatrice',
    'distillerions',
    'distinctement',
    'distinguables',
    'distinguaient',
    'distinguasses',
    'distinguerais',
    'distinguerait',
    'distinguèrent',
    'distingueriez',
    'distinguerons',
    'distingueront',
    'distordissent',
    'distordissiez',
    'distordraient',
    'distractivité',
    'distrairaient',
    'distraitement',
    'distribuables',
    'distribuaient',
    'distribuasses',
    'distribuerais',
    'distribuerait',
    'distribuèrent',
    'distribueriez',
    'distribuerons',
    'distribueront',
    'distributeurs',
    'distributions',
    'distributives',
    'distributrice',
    'dithyrambique',
    'divaguassions',
    'divagueraient',
    'divergeassent',
    'divergeassiez',
    'divergeraient',
    'diversifiâmes',
    'diversifiasse',
    'diversifiâtes',
    'diversifierai',
    'diversifieras',
    'diversifierez',
    'diversifiions',
    'diversiformes',
    'divertimentos',
    'divertiraient',
    'divertissante',
    'divertissants',
    'divertissions',
    'divinisassent',
    'divinisassiez',
    'divinisations',
    'diviniserions',
    'divisibilités',
    'divisionnaire',
    'divisionnisme',
    'divisionniste',
    'divorçassions',
    'divorceraient',
    'divulgatrices',
    'divulguassent',
    'divulguassiez',
    'divulguerions',
    'dix-huitièmes',
    'dix-neuvièmes',
    'dix-septièmes',
    'doctoralement',
    'documentaient',
    'documentaires',
    'documentasses',
    'documentation',
    'documenterais',
    'documenterait',
    'documentèrent',
    'documenteriez',
    'documenterons',
    'documenteront',
    'dodécagonales',
    'dodécasyllabe',
    'dodelinassent',
    'dodelinassiez',
    'dodelinements',
    'dodelinerions',
    'dogmatisaient',
    'dogmatisasses',
    'dogmatiserais',
    'dogmatiserait',
    'dogmatisèrent',
    'dogmatiseriez',
    'dogmatiserons',
    'dogmatiseront',
    'domestication',
    'domestiquâmes',
    'domestiquasse',
    'domestiquâtes',
    'domestiquerai',
    'domestiqueras',
    'domestiquerez',
    'domestiquions',
    'domiciliaient',
    'domiciliaires',
    'domiciliasses',
    'domiciliation',
    'domicilierais',
    'domicilierait',
    'domicilièrent',
    'domicilieriez',
    'domicilierons',
    'domicilieront',
    'donjuanesques',
    'dorlotassions',
    'dorloteraient',
    'douces-amères',
    'douilletterie',
    'dragéifiaient',
    'dragéifiasses',
    'dragéifierais',
    'dragéifierait',
    'dragéifièrent',
    'dragéifieriez',
    'dragéifierons',
    'dragéifieront',
    'drageonnaient',
    'drageonnasses',
    'drageonnement',
    'drageonnerais',
    'drageonnerait',
    'drageonnèrent',
    'drageonneriez',
    'drageonnerons',
    'drageonneront',
    'dramatisaient',
    'dramatisasses',
    'dramatisation',
    'dramatiserais',
    'dramatiserait',
    'dramatisèrent',
    'dramatiseriez',
    'dramatiserons',
    'dramatiseront',
    'draps-housses',
    'dressing-room',
    'dribblassions',
    'dribbleraient',
    'dulcifiassent',
    'dulcifiassiez',
    'dulcification',
    'dulcifierions',
    'duplexassions',
    'duplexeraient',
    'dupliquassent',
    'dupliquassiez',
    'dupliquerions',
    'durcissements',
    'duvetteraient',
    'dynamiquement',
    'dynamisassent',
    'dynamisassiez',
    'dynamisations',
    'dynamiserions',
    'dynamitassent',
    'dynamitassiez',
    'dynamiterions',
    'dynamogénique',
    'dynamographes',
    'dysentériques',
    'dysménorrhées',
    'ébahissements',
    'ébauchassions',
    'ébaucheraient',
    'ébavurassions',
    'ébavureraient',
    'éberluassions',
    'éberlueraient',
    'ébiselassions',
    'ébiseleraient',
    'éblouissaient',
    'éblouissantes',
    'éblouissement',
    'éborgnassions',
    'éborgneraient',
    'ébouillantage',
    'ébouillantais',
    'ébouillantait',
    'ébouillantant',
    'ébouillantées',
    'ébouillantent',
    'ébouillantera',
    'ébouillantiez',
    'ébouillantons',
    'ébourgeonnage',
    'ébourgeonnais',
    'ébourgeonnait',
    'ébourgeonnant',
    'ébourgeonnées',
    'ébourgeonnent',
    'ébourgeonnera',
    'ébourgeonniez',
    'ébourgeonnons',
    'ébouriffaient',
    'ébouriffantes',
    'ébouriffasses',
    'ébourifferais',
    'ébourifferait',
    'ébouriffèrent',
    'ébourifferiez',
    'ébourifferons',
    'ébourifferont',
    'ébourrassions',
    'ébourreraient',
    'ébraisassions',
    'ébraiseraient',
    'ébranchassent',
    'ébranchassiez',
    'ébrancherions',
    'ébranlassions',
    'ébranleraient',
    'ébréchassions',
    'ébrécheraient',
    'ébruitassions',
    'ébruiteraient',
    'ébulliomètres',
    'ébulliométrie',
    'ébullioscopes',
    'ébullioscopie',
    'écaillassions',
    'écailleraient',
    'écanguassions',
    'écangueraient',
    'écarquillâmes',
    'écarquillasse',
    'écarquillâtes',
    'écarquillerai',
    'écarquilleras',
    'écarquillerez',
    'écarquillions',
    'écartelassent',
    'écartelassiez',
    'écartèlements',
    'écartèlerions',
    'échafaudaient',
    'échafaudasses',
    'échafauderais',
    'échafauderait',
    'échafaudèrent',
    'échafauderiez',
    'échafauderons',
    'échafauderont',
    'échalassaient',
    'échalassasses',
    'échalasserais',
    'échalasserait',
    'échalassèrent',
    'échalasseriez',
    'échalasserons',
    'échalasseront',
    'échampiraient',
    'échampissions',
    'échancrassent',
    'échancrassiez',
    'échancrerions',
    'échanfreinais',
    'échanfreinait',
    'échanfreinant',
    'échanfreinées',
    'échanfreinent',
    'échanfreinera',
    'échanfreiniez',
    'échanfreinons',
    'échangeassent',
    'échangeassiez',
    'échangeraient',
    'échantillonna',
    'échantillonne',
    'échantillonné',
    'échappassions',
    'échappatoires',
    'échapperaient',
    'échardonnages',
    'échardonnâmes',
    'échardonnasse',
    'échardonnâtes',
    'échardonnerai',
    'échardonneras',
    'échardonnerez',
    'échardonnions',
    'écharnassions',
    'écharneraient',
    'écharpassions',
    'écharperaient',
    'échauboulures',
    'échaudassions',
    'échauderaient',
    'échauffassent',
    'échauffassiez',
    'échauffements',
    'échaufferions',
    'échauffourées',
    'échaulassions',
    'échauleraient',
    'échaumassions',
    'échaumeraient',
    'échelonnaient',
    'échelonnasses',
    'échelonnement',
    'échelonnerais',
    'échelonnerait',
    'échelonnèrent',
    'échelonneriez',
    'échelonnerons',
    'échelonneront',
    'échenillaient',
    'échenillasses',
    'échenillerais',
    'échenillerait',
    'échenillèrent',
    'échenilleriez',
    'échenillerons',
    'échenilleront',
    'échevelassent',
    'échevelassiez',
    'échevellerais',
    'échevellerait',
    'échevelleriez',
    'échevellerons',
    'échevelleront',
    'échoppassions',
    'échopperaient',
    'éclaboussâmes',
    'éclaboussasse',
    'éclaboussâtes',
    'éclabousserai',
    'éclabousseras',
    'éclabousserez',
    'éclaboussions',
    'éclaboussures',
    'éclairagistes',
    'éclairassions',
    'éclaircirions',
    'éclaircissage',
    'éclaircissais',
    'éclaircissait',
    'éclaircissant',
    'éclaircissent',
    'éclaircissiez',
    'éclaircissons',
    'éclaireraient',
    'éclipsassions',
    'éclipseraient',
    'éclissassions',
    'éclisseraient',
    'écoeurassions',
    'écoeureraient',
    'éconduiraient',
    'éconduisaient',
    'éconduisirent',
    'éconduisisses',
    'économétrique',
    'économisaient',
    'économisasses',
    'économiserais',
    'économiserait',
    'économisèrent',
    'économiseriez',
    'économiserons',
    'économiseront',
    'écorchassions',
    'écorcheraient',
    'écorniflaient',
    'écorniflasses',
    'écorniflerais',
    'écorniflerait',
    'écorniflèrent',
    'écornifleriez',
    'écorniflerons',
    'écornifleront',
    'écornifleuses',
    'écourtassions',
    'écourteraient',
    'écouvillonnai',
    'écouvillonnas',
    'écouvillonnât',
    'écouvillonnée',
    'écouvillonner',
    'écouvillonnes',
    'écouvillonnés',
    'écouvillonnez',
    'écrabouillage',
    'écrabouillais',
    'écrabouillait',
    'écrabouillant',
    'écrabouillées',
    'écrabouillent',
    'écrabouillera',
    'écrabouilliez',
    'écrabouillons',
    'écrivaillâmes',
    'écrivaillasse',
    'écrivaillâtes',
    'écrivaillerai',
    'écrivailleras',
    'écrivaillerez',
    'écrivailleurs',
    'écrivailleuse',
    'écrivaillions',
    'écrivassaient',
    'écrivassasses',
    'écrivasserais',
    'écrivasserait',
    'écrivassèrent',
    'écrivasseriez',
    'écrivasserons',
    'écrivasseront',
    'écrivassières',
    'écrouissaient',
    'écroulassions',
    'écroûtassions',
    'écroûteraient',
    'ectodermiques',
    'ectoparasites',
    'écuissassions',
    'écuisseraient',
    'écussonnaient',
    'écussonnasses',
    'écussonnerais',
    'écussonnerait',
    'écussonnèrent',
    'écussonneriez',
    'écussonnerons',
    'écussonneront',
    'édificatrices',
    'éditionnaient',
    'éditionnasses',
    'éditionnerais',
    'éditionnerait',
    'éditionnèrent',
    'éditionneriez',
    'éditionnerons',
    'éditionneront',
    'éditorialiste',
    'éducationnels',
    'édulcorassent',
    'édulcorassiez',
    'édulcorations',
    'édulcorerions',
    'éfaufilassent',
    'éfaufilassiez',
    'éfaufilerions',
    'effarouchâmes',
    'effarouchasse',
    'effarouchâtes',
    'effaroucherai',
    'effaroucheras',
    'effaroucherez',
    'effarouchions',
    'effectivement',
    'effectuassent',
    'effectuassiez',
    'effectuerions',
    'efféminassent',
    'efféminassiez',
    'efféminements',
    'efféminerions',
    'effervescence',
    'effervescente',
    'effervescents',
    'effeuillaient',
    'effeuillaison',
    'effeuillasses',
    'effeuillement',
    'effeuillerais',
    'effeuillerait',
    'effeuillèrent',
    'effeuilleriez',
    'effeuillerons',
    'effeuilleront',
    'effeuilleuses',
    'effilochaient',
    'effilochasses',
    'effilocherais',
    'effilocherait',
    'effilochèrent',
    'effilocheriez',
    'effilocherons',
    'effilocheront',
    'effilocheuses',
    'efflanquaient',
    'efflanquasses',
    'efflanquerais',
    'efflanquerait',
    'efflanquèrent',
    'efflanqueriez',
    'efflanquerons',
    'efflanqueront',
    'effleurassent',
    'effleurassiez',
    'effleurements',
    'effleurerions',
    'effleuririons',
    'effleurissais',
    'effleurissait',
    'effleurissant',
    'effleurissent',
    'effleurissiez',
    'effleurissons',
    'efflorescence',
    'efflorescente',
    'efflorescents',
    'effluvassions',
    'effluveraient',
    'effondrassent',
    'effondrassiez',
    'effondrements',
    'effondrerions',
    'efforçassions',
    'effraieraient',
    'effrangeaient',
    'effrangeasses',
    'effrangerions',
    'effrayassions',
    'effrayeraient',
    'effritassions',
    'effriteraient',
    'égaillassions',
    'égalisassions',
    'égalisatrices',
    'égaliseraient',
    'égalitarismes',
    'égalitaristes',
    'égocentriques',
    'égocentrismes',
    'égorgeassions',
    'égosillassiez',
    'égosillerions',
    'égouttassions',
    'égoutteraient',
    'égrainassions',
    'égraineraient',
    'égrappassions',
    'égrapperaient',
    'égratignaient',
    'égratignasses',
    'égratignerais',
    'égratignerait',
    'égratignèrent',
    'égratigneriez',
    'égratignerons',
    'égratigneront',
    'égravillonnai',
    'égravillonnas',
    'égravillonnât',
    'égravillonnée',
    'égravillonner',
    'égravillonnes',
    'égravillonnés',
    'égravillonnez',
    'égrugeassions',
    'éjaculassions',
    'éjaculeraient',
    'éjointassions',
    'éjointeraient',
    'élaborassions',
    'élaboreraient',
    'élargissaient',
    'élargissement',
    'électoralisme',
    'électoraliste',
    'électricienne',
    'électrifiâmes',
    'électrifiasse',
    'électrifiâtes',
    'électrifierai',
    'électrifieras',
    'électrifierez',
    'électrifiions',
    'électrisables',
    'électrisaient',
    'électrisantes',
    'électrisasses',
    'électrisation',
    'électriserais',
    'électriserait',
    'électrisèrent',
    'électriseriez',
    'électriserons',
    'électriseront',
    'électrochimie',
    'électrocutais',
    'électrocutait',
    'électrocutant',
    'électrocutées',
    'électrocutent',
    'électrocutera',
    'électrocutiez',
    'électrocution',
    'électrocutons',
    'électrologies',
    'électrolysais',
    'électrolysait',
    'électrolysant',
    'électrolysées',
    'électrolysent',
    'électrolysera',
    'électrolysiez',
    'électrolysons',
    'électromoteur',
    'électronicien',
    'électroniques',
    'électronisais',
    'électronisait',
    'électronisant',
    'électronisées',
    'électronisent',
    'électronisera',
    'électronisiez',
    'électronisons',
    'électrophones',
    'électroscopes',
    'éléphantesque',
    'éléphantiasis',
    'éliminassions',
    'éliminatoires',
    'éliminatrices',
    'élimineraient',
    'élinguassions',
    'élingueraient',
    'élisabéthaine',
    'élisabéthains',
    'ellipsoïdales',
    'élogieusement',
    'éloignassions',
    'éloigneraient',
    'élongeassions',
    'élucidassions',
    'élucideraient',
    'élucubrassent',
    'élucubrassiez',
    'élucubrations',
    'élucubrerions',
    'elzéviriennes',
    'émaillassions',
    'émailleraient',
    'émancipassent',
    'émancipassiez',
    'émancipateurs',
    'émancipations',
    'émancipatrice',
    'émanciperions',
    'émargeassions',
    'émasculassent',
    'émasculassiez',
    'émasculations',
    'émasculerions',
    'emballassions',
    'emballeraient',
    'emballottâmes',
    'emballottasse',
    'emballottâtes',
    'emballotterai',
    'emballotteras',
    'emballotterez',
    'emballottions',
    'embarbouillai',
    'embarbouillas',
    'embarbouillât',
    'embarbouillée',
    'embarbouiller',
    'embarbouilles',
    'embarbouillés',
    'embarbouillez',
    'embardassions',
    'embarderaient',
    'embarquassent',
    'embarquassiez',
    'embarquements',
    'embarquerions',
    'embarrassâmes',
    'embarrassante',
    'embarrassants',
    'embarrassasse',
    'embarrassâtes',
    'embarrasserai',
    'embarrasseras',
    'embarrasserez',
    'embarrassions',
    'embarreraient',
    'embastillâmes',
    'embastillasse',
    'embastillâtes',
    'embastillerai',
    'embastilleras',
    'embastillerez',
    'embastillions',
    'embastionnais',
    'embastionnait',
    'embastionnant',
    'embastionnées',
    'embastionnent',
    'embastionnera',
    'embastionniez',
    'embastionnons',
    'embattissions',
    'embauchassent',
    'embauchassiez',
    'embaucherions',
    'embaumassions',
    'embaumeraient',
    'embecquassent',
    'embecquassiez',
    'embecquerions',
    'émbéguineriez',
    'émbéguinerons',
    'embelliraient',
    'embellissâmes',
    'embellissasse',
    'embellissâtes',
    'embellissions',
    'emberlificota',
    'emberlificote',
    'emberlificoté',
    'embidonnaient',
    'embidonnasses',
    'embidonnerais',
    'embidonnerait',
    'embidonnèrent',
    'embidonneriez',
    'embidonnerons',
    'embidonneront',
    'emblavassions',
    'emblaveraient',
    'emblématiques',
    'embobelinâmes',
    'embobelinasse',
    'embobelinâtes',
    'embobelinerai',
    'embobelineras',
    'embobelinerez',
    'embobelinions',
    'embobinassent',
    'embobinassiez',
    'embobinerions',
    'emboîtassions',
    'emboîteraient',
    'embossassions',
    'embosseraient',
    'embottelaient',
    'embottelasses',
    'embottelèrent',
    'embottellerai',
    'embottelleras',
    'embottellerez',
    'embouchassent',
    'embouchassiez',
    'emboucherions',
    'embouquassent',
    'embouquassiez',
    'embouquements',
    'embouquerions',
    'embourbassent',
    'embourbassiez',
    'embourberions',
    'embourgeoisai',
    'embourgeoisas',
    'embourgeoisât',
    'embourgeoisée',
    'embourgeoiser',
    'embourgeoises',
    'embourgeoisés',
    'embourgeoisez',
    'embourrassent',
    'embourrassiez',
    'embourrerions',
    'embouteillage',
    'embouteillais',
    'embouteillait',
    'embouteillant',
    'embouteillées',
    'embouteillent',
    'embouteillera',
    'embouteilliez',
    'embouteillons',
    'emboutiraient',
    'emboutissages',
    'emboutissions',
    'emboutissoirs',
    'embraieraient',
    'embranchaient',
    'embranchasses',
    'embranchement',
    'embrancherais',
    'embrancherait',
    'embranchèrent',
    'embrancheriez',
    'embrancherons',
    'embrancheront',
    'embraquassent',
    'embraquassiez',
    'embraquerions',
    'embrasassions',
    'embraseraient',
    'embrassassent',
    'embrassassiez',
    'embrassements',
    'embrasserions',
    'embrayassions',
    'embrayeraient',
    'embrelassions',
    'embrèleraient',
    'embrevassions',
    'embrèveraient',
    'embrigadaient',
    'embrigadasses',
    'embrigadement',
    'embrigaderais',
    'embrigaderait',
    'embrigadèrent',
    'embrigaderiez',
    'embrigaderons',
    'embrigaderont',
    'embringuaient',
    'embringuasses',
    'embringuerais',
    'embringuerait',
    'embringuèrent',
    'embringueriez',
    'embringuerons',
    'embringueront',
    'embrochassent',
    'embrochassiez',
    'embrochements',
    'embrocherions',
    'embronchaient',
    'embronchasses',
    'embronchement',
    'embroncherais',
    'embroncherait',
    'embronchèrent',
    'embroncheriez',
    'embroncherons',
    'embroncheront',
    'embrouillages',
    'embrouillâmes',
    'embrouillasse',
    'embrouillâtes',
    'embrouillerai',
    'embrouilleras',
    'embrouillerez',
    'embrouillions',
    'embroussailla',
    'embroussaille',
    'embroussaillé',
    'embrumassions',
    'embrumeraient',
    'embruniraient',
    'embrunissions',
    'embryogénique',
    'embryologique',
    'embryologiste',
    'embryonnaires',
    'embryopathies',
    'embusquassent',
    'embusquassiez',
    'embusquerions',
    'émergeassions',
    'émerillonnais',
    'émerillonnait',
    'émerillonnant',
    'émerillonnées',
    'émerillonnent',
    'émerillonnera',
    'émerillonniez',
    'émerillonnons',
    'émerisassions',
    'émeriseraient',
    'émerveillâmes',
    'émerveillasse',
    'émerveillâtes',
    'émerveillerai',
    'émerveilleras',
    'émerveillerez',
    'émerveillions',
    'émiettassions',
    'émietteraient',
    'éminentissime',
    'emmagasinages',
    'emmagasinâmes',
    'emmagasinasse',
    'emmagasinâtes',
    'emmagasinerai',
    'emmagasineras',
    'emmagasinerez',
    'emmagasinions',
    'emmaillotâmes',
    'emmaillotasse',
    'emmaillotâtes',
    'emmailloterai',
    'emmailloteras',
    'emmailloterez',
    'emmaillotions',
    'emmanchassent',
    'emmanchassiez',
    'emmanchements',
    'emmancherions',
    'emmargeassent',
    'emmargeassiez',
    'emmargeraient',
    'emménageaient',
    'emménageasses',
    'emménagements',
    'emménagerions',
    'emmerdassions',
    'emmerderaient',
    'emmétrassions',
    'emmétreraient',
    'emmiellassent',
    'emmiellassiez',
    'emmiellerions',
    'emmitonnaient',
    'emmitonnasses',
    'emmitonnerais',
    'emmitonnerait',
    'emmitonnèrent',
    'emmitonneriez',
    'emmitonnerons',
    'emmitonneront',
    'emmitouflâmes',
    'emmitouflasse',
    'emmitouflâtes',
    'emmitouflerai',
    'emmitoufleras',
    'emmitouflerez',
    'emmitouflions',
    'emmortaisâmes',
    'emmortaisasse',
    'emmortaisâtes',
    'emmortaiserai',
    'emmortaiseras',
    'emmortaiserez',
    'emmortaisions',
    'emmouscaillai',
    'emmouscaillas',
    'emmouscaillât',
    'emmouscaillée',
    'emmouscailler',
    'emmouscailles',
    'emmouscaillés',
    'emmouscaillez',
    'émorfilassent',
    'émorfilassiez',
    'émorfilerions',
    'émotionnables',
    'émotionnaient',
    'émotionnasses',
    'émotionnelles',
    'émotionnerais',
    'émotionnerait',
    'émotionnèrent',
    'émotionneriez',
    'émotionnerons',
    'émotionneront',
    'émouchassions',
    'émoucheraient',
    'émouchetaient',
    'émouchetasses',
    'émouchèterais',
    'émouchèterait',
    'émouchetèrent',
    'émouchèteriez',
    'émouchèterons',
    'émouchèteront',
    'émoussassions',
    'émousseraient',
    'émoustillâmes',
    'émoustillante',
    'émoustillants',
    'émoustillasse',
    'émoustillâtes',
    'émoustillerai',
    'émoustilleras',
    'émoustillerez',
    'émoustillions',
    'empaillassent',
    'empaillassiez',
    'empaillements',
    'empalmassions',
    'empalmeraient',
    'empanachaient',
    'empanachasses',
    'empanacherais',
    'empanacherait',
    'empanachèrent',
    'empanacheriez',
    'empanacherons',
    'empanacheront',
    'empannassions',
    'empanneraient',
    'empapillotais',
    'empapillotait',
    'empapillotant',
    'empapillotées',
    'empapillotent',
    'empapillotera',
    'empapillotiez',
    'empapillotons',
    'empaquetaient',
    'empaquetasses',
    'empaquetèrent',
    'empaqueteuses',
    'empaquetterai',
    'empaquetteras',
    'empaquetterez',
    'emparquassent',
    'emparquassiez',
    'emparquerions',
    'empâtreraient',
    'empattassions',
    'empatteraient',
    'empaumassions',
    'empaumeraient',
    'empêchassions',
    'empêcheraient',
    'empeignassent',
    'empeignassiez',
    'empeignerions',
    'empennassions',
    'empenneraient',
    'emperchassent',
    'emperchassiez',
    'empercherions',
    'emperlassions',
    'emperleraient',
    'empestassions',
    'empesteraient',
    'empêtrassions',
    'empêtreraient',
    'emphysémateux',
    'emphytéotique',
    'empierrassent',
    'empierrassiez',
    'empierrements',
    'empierrerions',
    'empiétassions',
    'empiéteraient',
    'empiffrassiez',
    'empiffrerions',
    'empiriquement',
    'emplaillerais',
    'emplaillerait',
    'emplailleriez',
    'emplaillerons',
    'emplailleront',
    'emplâtrassent',
    'emplâtrassiez',
    'emploieraient',
    'employassions',
    'emplumassions',
    'emplumeraient',
    'empochassions',
    'empocheraient',
    'empoignassent',
    'empoignassiez',
    'empoignerions',
    'empoisonnâmes',
    'empoisonnante',
    'empoisonnants',
    'empoisonnasse',
    'empoisonnâtes',
    'empoisonnerai',
    'empoisonneras',
    'empoisonnerez',
    'empoisonneurs',
    'empoisonneuse',
    'empoisonnions',
    'empoissassent',
    'empoissassiez',
    'empoisserions',
    'empoissonnais',
    'empoissonnait',
    'empoissonnant',
    'empoissonnées',
    'empoissonnent',
    'empoissonnera',
    'empoissonniez',
    'empoissonnons',
    'emportassions',
    'emporte-pièce',
    'emporteraient',
    'empourpraient',
    'empourprasses',
    'empourprerais',
    'empourprerait',
    'empourprèrent',
    'empourpreriez',
    'empourprerons',
    'empourpreront',
    'empoussiérais',
    'empoussiérait',
    'empoussiérant',
    'empoussiérées',
    'empoussièrent',
    'empoussiérera',
    'empoussiériez',
    'empoussiérons',
    'empreignaient',
    'empreignirent',
    'empreignisses',
    'empreindrions',
    'empressassiez',
    'empressements',
    'empresserions',
    'emprésuraient',
    'emprésurasses',
    'emprésurerais',
    'emprésurerait',
    'emprésurèrent',
    'emprésureriez',
    'emprésurerons',
    'emprésureront',
    'emprisonnâmes',
    'emprisonnasse',
    'emprisonnâtes',
    'emprisonnerai',
    'emprisonneras',
    'emprisonnerez',
    'emprisonnions',
    'empruntassent',
    'empruntassiez',
    'emprunterions',
    'empuantirions',
    'empuantissais',
    'empuantissait',
    'empuantissant',
    'empuantissent',
    'empuantissiez',
    'empuantissons',
    'émulsifiables',
    'émulsifiaient',
    'émulsifiasses',
    'émulsifierais',
    'émulsifierait',
    'émulsifièrent',
    'émulsifieriez',
    'émulsifierons',
    'émulsifieront',
    'émulsionnâmes',
    'émulsionnasse',
    'émulsionnâtes',
    'émulsionnerai',
    'émulsionneras',
    'émulsionnerez',
    'émulsionnions',
    'enamourassiez',
    'énamourassiez',
    'enamourerions',
    'énamourerions',
    'énantiomorphe',
    'énantiotropes',
    'encabanassent',
    'encabanassiez',
    'encabanerions',
    'encadrassions',
    'encadreraient',
    'encageassions',
    'encaissassent',
    'encaissassiez',
    'encaissements',
    'encaisserions',
    'encanaillâmes',
    'encanaillasse',
    'encanaillâtes',
    'encanaillerai',
    'encanailleras',
    'encanaillerez',
    'encanaillions',
    'encapuchonnai',
    'encapuchonnas',
    'encapuchonnât',
    'encapuchonnée',
    'encapuchonner',
    'encapuchonnes',
    'encapuchonnés',
    'encapuchonnez',
    'encaquassions',
    'encaqueraient',
    'encartassions',
    'encarteraient',
    'encartonnâmes',
    'encartonnasse',
    'encartonnâtes',
    'encartonnerai',
    'encartonneras',
    'encartonnerez',
    'encartonnions',
    'encartouchais',
    'encartouchait',
    'encartouchant',
    'encartouchées',
    'encartouchent',
    'encartouchera',
    'encartouchiez',
    'encartouchons',
    'encasernaient',
    'encasernasses',
    'encasernerais',
    'encasernerait',
    'encasernèrent',
    'encaserneriez',
    'encasernerons',
    'encaserneront',
    'encastèleriez',
    'encastèlerons',
    'encastrassent',
    'encastrassiez',
    'encastrements',
    'encastrerions',
    'encaustiquage',
    'encaustiquais',
    'encaustiquait',
    'encaustiquant',
    'encaustiquées',
    'encaustiquent',
    'encaustiquera',
    'encaustiquiez',
    'encaustiquons',
    'encensassions',
    'encenseraient',
    'encerclassent',
    'encerclassiez',
    'encerclements',
    'encerclerions',
    'enchaînassent',
    'enchaînassiez',
    'enchaînements',
    'enchaînerions',
    'enchantassent',
    'enchantassiez',
    'enchantements',
    'enchanteresse',
    'enchanterions',
    'enchaperonnai',
    'enchaperonnas',
    'enchaperonnât',
    'enchaperonnée',
    'enchaperonner',
    'enchaperonnes',
    'enchaperonnés',
    'enchaperonnez',
    'encharnassent',
    'encharnassiez',
    'encharnerions',
    'enchâssassent',
    'enchâssassiez',
    'enchâssements',
    'enchâsserions',
    'enchatonnâmes',
    'enchatonnasse',
    'enchatonnâtes',
    'enchatonnerai',
    'enchatonneras',
    'enchatonnerez',
    'enchatonnions',
    'enchaussaient',
    'enchaussasses',
    'enchausserais',
    'enchausserait',
    'enchaussèrent',
    'enchausseriez',
    'enchausserons',
    'enchausseront',
    'enchemisaient',
    'enchemisasses',
    'enchemiserais',
    'enchemiserait',
    'enchemisèrent',
    'enchemiseriez',
    'enchemiserons',
    'enchemiseront',
    'enchériraient',
    'enchérisseurs',
    'enchérisseuse',
    'enchérissions',
    'enchevalement',
    'enchevauchais',
    'enchevauchait',
    'enchevauchant',
    'enchevauchées',
    'enchevauchent',
    'enchevauchera',
    'enchevauchiez',
    'enchevauchons',
    'enchevauchure',
    'enchevêtrâmes',
    'enchevêtrasse',
    'enchevêtrâtes',
    'enchevêtrerai',
    'enchevêtreras',
    'enchevêtrerez',
    'enchevêtrions',
    'enchevêtrures',
    'enchifrenâmes',
    'enchifrenasse',
    'enchifrenâtes',
    'enchifrènerai',
    'enchifrèneras',
    'enchifrènerez',
    'enchifrenions',
    'enclavassions',
    'enclaveraient',
    'enclenchaient',
    'enclenchasses',
    'enclenchement',
    'enclencherais',
    'enclencherait',
    'enclenchèrent',
    'enclencheriez',
    'enclencherons',
    'enclencheront',
    'encliquetages',
    'encliquetâmes',
    'encliquetasse',
    'encliquetâtes',
    'encliquetions',
    'encliquettent',
    'encliquettera',
    'encloîtraient',
    'encloîtrasses',
    'encloîtrerais',
    'encloîtrerait',
    'encloîtrèrent',
    'encloîtreriez',
    'encloîtrerons',
    'encloîtreront',
    'enclouassions',
    'encloueraient',
    'encochassions',
    'encocheraient',
    'encoffrassent',
    'encoffrassiez',
    'encoffrerions',
    'encollassions',
    'encolleraient',
    'encombrassent',
    'encombrassiez',
    'encombrements',
    'encombrerions',
    'encordassions',
    'encorderaient',
    'encornassions',
    'encorneraient',
    'encourageâmes',
    'encourageante',
    'encourageants',
    'encourageasse',
    'encourageâtes',
    'encouragement',
    'encouragerais',
    'encouragerait',
    'encouragèrent',
    'encourageriez',
    'encouragerons',
    'encourageront',
    'encourussions',
    'encrassassent',
    'encrassassiez',
    'encrassements',
    'encrasserions',
    'encrêpassions',
    'encrêperaient',
    'encroûtassent',
    'encroûtassiez',
    'encroûtements',
    'encroûterions',
    'encyclopédies',
    'endaubassions',
    'endauberaient',
    'endentassions',
    'endenteraient',
    'endettassions',
    'endetteraient',
    'endeuillaient',
    'endeuillasses',
    'endeuillerais',
    'endeuillerait',
    'endeuillèrent',
    'endeuilleriez',
    'endeuillerons',
    'endeuilleront',
    'endiablassent',
    'endiablassiez',
    'endiablerions',
    'endiguassions',
    'endigueraient',
    'endimanchâmes',
    'endimanchasse',
    'endimanchâtes',
    'endimancherai',
    'endimancheras',
    'endimancherez',
    'endimanchions',
    'endivisionnai',
    'endivisionnas',
    'endivisionnât',
    'endivisionnée',
    'endivisionner',
    'endivisionnes',
    'endivisionnés',
    'endivisionnez',
    'endocrinienne',
    'endoctrinâmes',
    'endoctrinasse',
    'endoctrinâtes',
    'endoctrinerai',
    'endoctrineras',
    'endoctrinerez',
    'endoctrinions',
    'endoloririons',
    'endolorissais',
    'endolorissait',
    'endolorissant',
    'endolorissent',
    'endolorissiez',
    'endolorissons',
    'endommageâmes',
    'endommageasse',
    'endommageâtes',
    'endommagement',
    'endommagerais',
    'endommagerait',
    'endommagèrent',
    'endommageriez',
    'endommagerons',
    'endommageront',
    'endormiraient',
    'endormissions',
    'endoscopiques',
    'endosmomètres',
    'endossassions',
    'endossataires',
    'endosseraient',
    'endothéliales',
    'endothermique',
    'enduisissions',
    'endurciraient',
    'endurcissions',
    'énergiquement',
    'enfaîtassions',
    'enfaîteraient',
    'enfantassions',
    'enfanteraient',
    'enfantillages',
    'enfarinassent',
    'enfarinassiez',
    'enfarinerions',
    'enfermassions',
    'enfermeraient',
    'enferrassions',
    'enferreraient',
    'enfichassions',
    'enficheraient',
    'enfiellassent',
    'enfiellassiez',
    'enfiellerions',
    'enfiévrassent',
    'enfiévrassiez',
    'enfiévrerions',
    'enflammassent',
    'enflammassiez',
    'enflammerions',
    'enfléchassent',
    'enfléchassiez',
    'enflécherions',
    'enfleurassent',
    'enfleurassiez',
    'enfleurerions',
    'enfonçassions',
    'enfonceraient',
    'enforciraient',
    'enforcissions',
    'enfouissaient',
    'enfouissement',
    'enfourchaient',
    'enfourchasses',
    'enfourchement',
    'enfourcherais',
    'enfourcherait',
    'enfourchèrent',
    'enfourcheriez',
    'enfourcherons',
    'enfourcheront',
    'enfournassent',
    'enfournassiez',
    'enfournements',
    'enfournerions',
    'enfreignaient',
    'enfreignirent',
    'enfreignisses',
    'enfreindrions',
    'enfutaillâmes',
    'enfutaillasse',
    'enfutaillâtes',
    'enfutaillerai',
    'enfutailleras',
    'enfutaillerez',
    'enfutaillions',
    'engageassions',
    'engainassions',
    'engaineraient',
    'engargoussais',
    'engargoussait',
    'engargoussant',
    'engargoussées',
    'engargoussent',
    'engargoussera',
    'engargoussiez',
    'engargoussons',
    'engazonnaient',
    'engazonnasses',
    'engazonnement',
    'engazonnerais',
    'engazonnerait',
    'engazonnèrent',
    'engazonneriez',
    'engazonnerons',
    'engazonneront',
    'engendrassent',
    'engendrassiez',
    'engendrements',
    'engendrerions',
    'engerbassions',
    'engerberaient',
    'englaçassions',
    'englaceraient',
    'englobassions',
    'engloberaient',
    'engloutirions',
    'engloutissais',
    'engloutissait',
    'engloutissant',
    'engloutissent',
    'engloutissiez',
    'engloutissons',
    'engommassions',
    'engommeraient',
    'engonçassions',
    'engonceraient',
    'engorgeassent',
    'engorgeassiez',
    'engorgeraient',
    'engouffraient',
    'engouffrasses',
    'engouffrement',
    'engouffrerais',
    'engouffrerait',
    'engouffrèrent',
    'engouffreriez',
    'engouffrerons',
    'engouffreront',
    'engoulassions',
    'engouleraient',
    'engourdirions',
    'engourdissais',
    'engourdissait',
    'engourdissant',
    'engourdissent',
    'engourdissiez',
    'engourdissons',
    'engraissaient',
    'engraissasses',
    'engraissement',
    'engraisserais',
    'engraisserait',
    'engraissèrent',
    'engraisseriez',
    'engraisserons',
    'engraisseront',
    'engrangeaient',
    'engrangeasses',
    'engrangements',
    'engrangerions',
    'engravassions',
    'engraveraient',
    'engrenassions',
    'engrèneraient',
    'engrossassent',
    'engrossassiez',
    'engrosserions',
    'engrumelaient',
    'engrumelasses',
    'engrumelèrent',
    'engrumellerai',
    'engrumelleras',
    'engrumellerez',
    'engueulassent',
    'engueulassiez',
    'engueulerions',
    'enguirlandais',
    'enguirlandait',
    'enguirlandant',
    'enguirlandées',
    'enguirlandent',
    'enguirlandera',
    'enguirlandiez',
    'enguirlandons',
    'enhardiraient',
    'enhardissions',
    'enharmoniques',
    'enharnachâmes',
    'enharnachasse',
    'enharnachâtes',
    'enharnacherai',
    'enharnacheras',
    'enharnacherez',
    'enharnachions',
    'enherbassions',
    'enherberaient',
    'éniellassions',
    'énielleraient',
    'enjambassions',
    'enjamberaient',
    'enjavelassent',
    'enjavelassiez',
    'enjavellerais',
    'enjavellerait',
    'enjavelleriez',
    'enjavellerons',
    'enjavelleront',
    'enjoignissent',
    'enjoignissiez',
    'enjoindraient',
    'enjolivassent',
    'enjolivassiez',
    'enjolivements',
    'enjoliverions',
    'enjonçassions',
    'enjonceraient',
    'enjuguassions',
    'enjugueraient',
    'enjuivassions',
    'enjuiveraient',
    'enjuponnaient',
    'enjuponnasses',
    'enjuponnerais',
    'enjuponnerait',
    'enjuponnèrent',
    'enjuponneriez',
    'enjuponnerons',
    'enjuponneront',
    'enkystassions',
    'enlaidiraient',
    'enlaidissions',
    'enliassassent',
    'enliassassiez',
    'enliasserions',
    'enlignassions',
    'enligneraient',
    'enluminassent',
    'enluminassiez',
    'enluminerions',
    'enneigeassent',
    'enneigeassiez',
    'enneigeraient',
    'ennobliraient',
    'ennoblissions',
    'ennuageassent',
    'ennuageassiez',
    'ennuageraient',
    'ennuyeusement',
    'enorgueillies',
    'enorgueillira',
    'enquêtassions',
    'enquêteraient',
    'enquiquinâmes',
    'enquiquinante',
    'enquiquinants',
    'enquiquinasse',
    'enquiquinâtes',
    'enquiquinerai',
    'enquiquineras',
    'enquiquinerez',
    'enquiquineurs',
    'enquiquineuse',
    'enquiquinions',
    'enracinassent',
    'enracinassiez',
    'enracinements',
    'enracinerions',
    'enrageassions',
    'enraillassent',
    'enraillassiez',
    'enraillerions',
    'enraquassions',
    'enrégimentais',
    'enrégimentait',
    'enrégimentant',
    'enrégimentées',
    'enrégimentent',
    'enrégimentera',
    'enrégimentiez',
    'enrégimentons',
    'enregistrable',
    'enregistrâmes',
    'enregistrasse',
    'enregistrâtes',
    'enregistrerai',
    'enregistreras',
    'enregistrerez',
    'enregistreurs',
    'enregistreuse',
    'enregistrions',
    'enrhumassions',
    'enrhumeraient',
    'enrichiraient',
    'enrichissante',
    'enrichissants',
    'enrichissions',
    'enrochassions',
    'enrocheraient',
    'enrouillaient',
    'enrouillasses',
    'enrouillerais',
    'enrouillerait',
    'enrouillèrent',
    'enrouilleriez',
    'enrouillerons',
    'enrouilleront',
    'enroulassions',
    'enrouleraient',
    'enrubannaient',
    'enrubannasses',
    'enrubannerais',
    'enrubannerait',
    'enrubannèrent',
    'enrubanneriez',
    'enrubannerons',
    'enrubanneront',
    'ensablassions',
    'ensableraient',
    'ensabotassent',
    'ensabotassiez',
    'ensaboterions',
    'ensachassions',
    'ensacheraient',
    'ensaisinaient',
    'ensaisinasses',
    'ensaisinerais',
    'ensaisinerait',
    'ensaisinèrent',
    'ensaisineriez',
    'ensaisinerons',
    'ensaisineront',
    'ensanglantais',
    'ensanglantait',
    'ensanglantant',
    'ensanglantées',
    'ensanglantent',
    'ensanglantera',
    'ensanglantiez',
    'ensanglantons',
    'ensauvassions',
    'enseignassent',
    'enseignassiez',
    'enseignements',
    'enseignerions',
    'ensemençaient',
    'ensemençasses',
    'ensemencement',
    'ensemencerais',
    'ensemencerait',
    'ensemencèrent',
    'ensemenceriez',
    'ensemencerons',
    'ensemenceront',
    'enserrassions',
    'enserreraient',
    'ensevelirions',
    'ensevelissais',
    'ensevelissait',
    'ensevelissant',
    'ensevelissent',
    'ensevelissiez',
    'ensevelissons',
    'ensoleillâmes',
    'ensoleillasse',
    'ensoleillâtes',
    'ensoleillerai',
    'ensoleilleras',
    'ensoleillerez',
    'ensoleillions',
    'ensommeillées',
    'ensorcelaient',
    'ensorcelantes',
    'ensorcelasses',
    'ensorcelèrent',
    'ensorceleuses',
    'ensorcellerai',
    'ensorcelleras',
    'ensorcellerez',
    'ensoufrassent',
    'ensoufrassiez',
    'ensoufrerions',
    'enstérassions',
    'enstéreraient',
    'entablassions',
    'entableraient',
    'entachassions',
    'entacheraient',
    'entaillassent',
    'entaillassiez',
    'entaillerions',
    'entaqueraient',
    'entartrassent',
    'entartrassiez',
    'entartrerions',
    'entassassions',
    'entasseraient',
    'entendissions',
    'enténébraient',
    'enténébrasses',
    'enténébrerais',
    'enténébrerait',
    'enténébrèrent',
    'enténébreriez',
    'enténébrerons',
    'enténébreront',
    'entérinassent',
    'entérinassiez',
    'entérinements',
    'entérinerions',
    'entérocolites',
    'entérokinases',
    'entéro-rénale',
    'entéro-rénaux',
    'entérovaccins',
    'enterrassions',
    'enterreraient',
    'enthousiasmai',
    'enthousiasmas',
    'enthousiasmât',
    'enthousiasmée',
    'enthousiasmer',
    'enthousiasmes',
    'enthousiasmés',
    'enthousiasmez',
    'enthousiastes',
    'entichassions',
    'entoilassions',
    'entoileraient',
    'entomologique',
    'entomologiste',
    'entonnassions',
    'entonneraient',
    'entortillages',
    'entortillâmes',
    'entortillasse',
    'entortillâtes',
    'entortillerai',
    'entortilleras',
    'entortillerez',
    'entortillions',
    'entourassions',
    'entoureraient',
    'entraccordées',
    'entraccordiez',
    'entraccordons',
    'entraccusâmes',
    'entraccusâtes',
    'entraccuserez',
    'entraccusions',
    'entradmirâmes',
    'entradmirâtes',
    'entradmirerez',
    'entradmirions',
    'entraidassiez',
    'entraiderions',
    'entraînassent',
    'entraînassiez',
    'entraînements',
    'entraînerions',
    'entravassions',
    'entraveraient',
    'entrebâillais',
    'entrebâillait',
    'entrebâillant',
    'entrebâillées',
    'entrebâillent',
    'entrebâillera',
    'entrebâilleur',
    'entrebâilliez',
    'entrebâillons',
    'entrebattîmes',
    'entrebattions',
    'entrebattîtes',
    'entrebattriez',
    'entrebattrons',
    'entrechoquais',
    'entrechoquait',
    'entrechoquant',
    'entrechoquées',
    'entrechoquent',
    'entrechoquera',
    'entrechoquiez',
    'entrechoquons',
    'entrecoupâmes',
    'entrecoupasse',
    'entrecoupâtes',
    'entrecouperai',
    'entrecouperas',
    'entrecouperez',
    'entrecoupions',
    'entrecroisais',
    'entrecroisait',
    'entrecroisant',
    'entrecroisées',
    'entrecroisent',
    'entrecroisera',
    'entrecroisiez',
    'entrecroisons',
    'entre-déchiré',
    'entre-détruit',
    'entre-dévorée',
    'entre-dévorer',
    'entre-dévorés',
    'entre-dévorez',
    'entre-égorgée',
    'entre-égorgés',
    'entre-frappée',
    'entre-frapper',
    'entre-frappés',
    'entre-frappez',
    'entre-haïriez',
    'entre-haïrons',
    'entre-haïssez',
    'entre-heurtée',
    'entre-heurter',
    'entre-heurtés',
    'entre-heurtez',
    'entrelaçaient',
    'entrelaçasses',
    'entrelacement',
    'entrelacerais',
    'entrelacerait',
    'entrelacèrent',
    'entrelaceriez',
    'entrelacerons',
    'entrelaceront',
    'entrelardâmes',
    'entrelardasse',
    'entrelardâtes',
    'entrelarderai',
    'entrelarderas',
    'entrelarderez',
    'entrelardions',
    'entre-louâmes',
    'entre-louâtes',
    'entre-louerez',
    'entre-louions',
    'entre-mangées',
    'entre-mangiez',
    'entremêlaient',
    'entremêlasses',
    'entremêlement',
    'entremêlerais',
    'entremêlerait',
    'entremêlèrent',
    'entremêleriez',
    'entremêlerons',
    'entremêleront',
    'entremetteurs',
    'entremetteuse',
    'entremettions',
    'entremettrais',
    'entremettrait',
    'entremettriez',
    'entremettrons',
    'entremettront',
    'entremissions',
    'entre-nuiriez',
    'entre-nuirons',
    'entre-nuisiez',
    'entre-nuisons',
    'entreposaient',
    'entreposasses',
    'entreposerais',
    'entreposerait',
    'entreposèrent',
    'entreposeriez',
    'entreposerons',
    'entreposeront',
    'entreprenante',
    'entreprenants',
    'entreprendrai',
    'entreprendras',
    'entreprendrez',
    'entrepreneurs',
    'entrepreneuse',
    'entreprenions',
    'entreprennent',
    'entreprissent',
    'entreprissiez',
    'entre-regardé',
    'entretaillées',
    'entretailliez',
    'entretaillons',
    'entretenaient',
    'entretiendrai',
    'entretiendras',
    'entretiendrez',
    'entretiennent',
    'entretinssent',
    'entretinssiez',
    'entretoisâmes',
    'entretoisasse',
    'entretoisâtes',
    'entretoiserai',
    'entretoiseras',
    'entretoiserez',
    'entretoisions',
    'entre-tueriez',
    'entre-tuerons',
    'entreverrions',
    'entrevissions',
    'entrevoûtâmes',
    'entrevoûtasse',
    'entrevoûtâtes',
    'entrevouterai',
    'entrevouteras',
    'entrevouterez',
    'entrevoûtions',
    'entrevoyaient',
    'entrouvraient',
    'entrouvrirais',
    'entrouvrirait',
    'entrouvrirent',
    'entrouvririez',
    'entrouvrirons',
    'entrouvriront',
    'entrouvrisses',
    'énucléassions',
    'énucléeraient',
    'énumérassions',
    'énuméreraient',
    'envahissaient',
    'envahissantes',
    'envahissement',
    'enveloppaient',
    'enveloppantes',
    'enveloppasses',
    'enveloppement',
    'envelopperais',
    'envelopperait',
    'enveloppèrent',
    'envelopperiez',
    'envelopperons',
    'envelopperont',
    'envenimassent',
    'envenimassiez',
    'envenimations',
    'envenimements',
    'envenimerions',
    'envergeassent',
    'envergeassiez',
    'envergeraient',
    'enverguassent',
    'enverguassiez',
    'enverguerions',
    'envieillirais',
    'envieillirait',
    'envieillirent',
    'envieilliriez',
    'envieillirons',
    'envieilliront',
    'envieillisses',
    'envieillissez',
    'environnaient',
    'environnantes',
    'environnasses',
    'environnement',
    'environnerais',
    'environnerait',
    'environnèrent',
    'environneriez',
    'environnerons',
    'environneront',
    'envisageables',
    'envisageaient',
    'envisageasses',
    'envisagerions',
    'envoilassions',
    'envoûtassions',
    'envoûteraient',
    'enzymopathies',
    'éosinophilies',
    'épaissiraient',
    'épaississante',
    'épaississants',
    'épaississeurs',
    'épaississions',
    'épamprassions',
    'épampreraient',
    'épanchassions',
    'épancheraient',
    'épannelassent',
    'épannelassiez',
    'épannellerais',
    'épannellerait',
    'épannelleriez',
    'épannellerons',
    'épannelleront',
    'épanouiraient',
    'épanouissions',
    'épargnassions',
    'épargneraient',
    'éparpillaient',
    'éparpillasses',
    'éparpillement',
    'éparpillerais',
    'éparpillerait',
    'éparpillèrent',
    'éparpilleriez',
    'éparpillerons',
    'éparpilleront',
    'épaufrassions',
    'épaufreraient',
    'épaulés-jetés',
    'épeirogénique',
    'épenthétiques',
    'épépinassions',
    'épépineraient',
    'éperonnassent',
    'éperonnassiez',
    'éperonnerions',
    'épicrâniennes',
    'épicycloïdale',
    'épicycloïdaux',
    'épidémiologie',
    'épierrassions',
    'épierreraient',
    'épigastriques',
    'épigraphiques',
    'épigraphistes',
    'épileptiforme',
    'épiloguassent',
    'épiloguassiez',
    'épiloguerâmes',
    'épiloguerasse',
    'épiloguerâtes',
    'épiloguerions',
    'épincelassent',
    'épincelassiez',
    'épincèlerions',
    'épincetassent',
    'épincetassiez',
    'épinceterions',
    'épine-vinette',
    'épinglassions',
    'épingleraient',
    'épiphénomènes',
    'épirogéniques',
    'épistémologie',
    'épluchassions',
    'éplucheraient',
    'épointassions',
    'épointeraient',
    'épongeassions',
    'épontillaient',
    'épontillasses',
    'épontillerais',
    'épontillerait',
    'épontillèrent',
    'épontilleriez',
    'épontillerons',
    'épontilleront',
    'épouillassent',
    'épouillassiez',
    'épouillerions',
    'époumonassent',
    'époumonassiez',
    'époumonerions',
    'époussetaient',
    'époussetasses',
    'époussetèrent',
    'époussetterai',
    'époussetteras',
    'époussetterez',
    'époustouflais',
    'époustouflait',
    'époustouflant',
    'époustouflées',
    'époustouflent',
    'époustouflera',
    'époustoufliez',
    'époustouflons',
    'époutiassions',
    'époutieraient',
    'époutissaient',
    'épouvantables',
    'épouvantaient',
    'épouvantasses',
    'épouvantement',
    'épouvanterais',
    'épouvanterait',
    'épouvantèrent',
    'épouvanteriez',
    'épouvanterons',
    'épouvanteront',
    'épreindraient',
    'éprouvassions',
    'éprouveraient',
    'équarriraient',
    'équarrissages',
    'équarrisseurs',
    'équarrissions',
    'équarrissoirs',
    'équerrassions',
    'équerreraient',
    'équeutassions',
    'équeuteraient',
    'équidistances',
    'équidistantes',
    'équilatérales',
    'équilibraient',
    'équilibrantes',
    'équilibrasses',
    'équilibration',
    'équilibrerais',
    'équilibrerait',
    'équilibrèrent',
    'équilibreriez',
    'équilibrerons',
    'équilibreront',
    'équilibristes',
    'équimultiples',
    'équipartition',
    'équipollences',
    'équipollentes',
    'équipotentiel',
    'équiprobables',
    'équitablement',
    'équivalussent',
    'équivalussiez',
    'équivaudrions',
    'équivoquaient',
    'équivoquasses',
    'équivoquerais',
    'équivoquerait',
    'équivoquèrent',
    'équivoqueriez',
    'équivoquerons',
    'équivoqueront',
    'éradiquassent',
    'éradiquassiez',
    'éradiquerions',
    'éraillassions',
    'érailleraient',
    'éreintassions',
    'éreinteraient',
    'ergothérapies',
    'érotisassions',
    'érotiseraient',
    'érotomaniaque',
    'érysipélateux',
    'érythémateuse',
    'érythroblaste',
    'érythromycine',
    'esbaudissions',
    'esbignassions',
    'esbroufassent',
    'esbroufassiez',
    'esbrouferions',
    'escaladassent',
    'escaladassiez',
    'escaladerions',
    'escamotassent',
    'escamotassiez',
    'escamoterions',
    'escargotières',
    'escarmouchais',
    'escarmouchait',
    'escarmouchant',
    'escarmouchent',
    'escarmouchera',
    'escarmouchiez',
    'escarmouchons',
    'escarpolettes',
    'escarrifiâmes',
    'escarrifiasse',
    'escarrifiâtes',
    'escarrifierai',
    'escarrifieras',
    'escarrifierez',
    'escarrifiions',
    'eschatologies',
    'esclaffassiez',
    'esclafferions',
    'esclavageâmes',
    'esclavageasse',
    'esclavageâtes',
    'esclavagerais',
    'esclavagerait',
    'esclavagèrent',
    'esclavageriez',
    'esclavagerons',
    'esclavageront',
    'esclavagismes',
    'esclavagistes',
    'escobarderies',
    'escomptassent',
    'escomptassiez',
    'escompterions',
    'escortassions',
    'escorteraient',
    'escrimassions',
    'escroquassent',
    'escroquassiez',
    'escroquerions',
    'espaces-temps',
    'espagnolettes',
    'espagnolismes',
    'espérantistes',
    'espionnassent',
    'espionnassiez',
    'espionnerions',
    'espolinassent',
    'espolinassiez',
    'espolinerions',
    'espoulinaient',
    'espoulinasses',
    'espoulinerais',
    'espoulinerait',
    'espoulinèrent',
    'espoulineriez',
    'espoulinerons',
    'espoulineront',
    'esprit-de-sel',
    'esprit-de-vin',
    'esquimautages',
    'esquintassent',
    'esquintassiez',
    'esquinterions',
    'esquissassent',
    'esquissassiez',
    'esquisserions',
    'esquivassions',
    'esquiveraient',
    'essaimassions',
    'essaimeraient',
    'essangeassent',
    'essangeassiez',
    'essangeraient',
    'essartassions',
    'essarteraient',
    'essentialisme',
    'essorillaient',
    'essorillasses',
    'essorillerais',
    'essorillerait',
    'essorillèrent',
    'essorilleriez',
    'essorillerons',
    'essorilleront',
    'essouchassent',
    'essouchassiez',
    'essouchements',
    'essoucherions',
    'essoufflaient',
    'essoufflasses',
    'essoufflement',
    'essoufflerais',
    'essoufflerait',
    'essoufflèrent',
    'essouffleriez',
    'essoufflerons',
    'essouffleront',
    'essuie-glaces',
    'essuie-plumes',
    'essuie-verres',
    'establishment',
    'est-allemande',
    'est-allemands',
    'estampassions',
    'estamperaient',
    'estampillages',
    'estampillâmes',
    'estampillasse',
    'estampillâtes',
    'estampillerai',
    'estampilleras',
    'estampillerez',
    'estampillions',
    'estérifiaient',
    'estérifiasses',
    'estérifierais',
    'estérifierait',
    'estérifièrent',
    'estérifieriez',
    'estérifierons',
    'estérifieront',
    'esthésiogènes',
    'esthéticienne',
    'esthétisaient',
    'esthétisantes',
    'esthétisasses',
    'esthétiserais',
    'esthétiserait',
    'esthétisèrent',
    'esthétiseriez',
    'esthétiserons',
    'esthétiseront',
    'estomaquaient',
    'estomaquasses',
    'estomaquerais',
    'estomaquerait',
    'estomaquèrent',
    'estomaqueriez',
    'estomaquerons',
    'estomaqueront',
    'estompassions',
    'estomperaient',
    'estoquassions',
    'estoqueraient',
    'estourbirions',
    'estourbissais',
    'estourbissait',
    'estourbissant',
    'estourbissent',
    'estourbissiez',
    'estourbissons',
    'estrapadaient',
    'estrapadasses',
    'estrapaderais',
    'estrapaderait',
    'estrapadèrent',
    'estrapaderiez',
    'estrapaderons',
    'estrapaderont',
    'estrapassâmes',
    'estrapassasse',
    'estrapassâtes',
    'estrapasserai',
    'estrapasseras',
    'estrapasserez',
    'estrapassions',
    'estropiassent',
    'estropiassiez',
    'estropierions',
    'estudiantines',
    'établissaient',
    'établissement',
    'étaient-elles',
    'étalageassent',
    'étalageassiez',
    'étalageraient',
    'étalinguaient',
    'étalinguasses',
    'étalinguerais',
    'étalinguerait',
    'étalinguèrent',
    'étalingueriez',
    'étalinguerons',
    'étalingueront',
    'étalisassions',
    'étaliseraient',
    'étalonnassent',
    'étalonnassiez',
    'étalonnements',
    'étalonnerions',
    'étanchassions',
    'étancheraient',
    'étançonnaient',
    'étançonnasses',
    'étançonnerais',
    'étançonnerait',
    'étançonnèrent',
    'étançonneriez',
    'étançonnerons',
    'étançonneront',
    'étarquassions',
    'étarqueraient',
    'éteignissions',
    'éternellement',
    'éternisassent',
    'éternisassiez',
    'éterniserions',
    'éternuassions',
    'éternueraient',
    'éthérifiaient',
    'éthérifiasses',
    'éthérifierais',
    'éthérifierait',
    'éthérifièrent',
    'éthérifieriez',
    'éthérifierons',
    'éthérifieront',
    'éthérisassent',
    'éthérisassiez',
    'éthérisations',
    'éthériserions',
    'ethnographies',
    'ethnologiques',
    'étincelassent',
    'étincelassiez',
    'étincellement',
    'étincellerais',
    'étincellerait',
    'étincelleriez',
    'étincellerons',
    'étincelleront',
    'étiquetassent',
    'étiquetassiez',
    'étiquetterais',
    'étiquetterait',
    'étiquetteriez',
    'étiquetterons',
    'étiquetteront',
    'étouffassions',
    'étoufferaient',
    'étoupillaient',
    'étoupillasses',
    'étoupillerais',
    'étoupillerait',
    'étoupillèrent',
    'étoupilleriez',
    'étoupillerons',
    'étoupilleront',
    'étourdiraient',
    'étourdissante',
    'étourdissants',
    'étourdissions',
    'étranglassent',
    'étranglassiez',
    'étranglements',
    'étranglerions',
    'étrécissaient',
    'étreignissent',
    'étreignissiez',
    'étreindraient',
    'étrennassions',
    'étrenneraient',
    'étrésillonnai',
    'étrésillonnas',
    'étrésillonnât',
    'étrésillonnée',
    'étrésillonner',
    'étrésillonnes',
    'étrésillonnés',
    'étrésillonnez',
    'étrillassions',
    'étrilleraient',
    'étriquassions',
    'étriqueraient',
    'étronçonnâmes',
    'étronçonnasse',
    'étronçonnâtes',
    'étronçonnerai',
    'étronçonneras',
    'étronçonnerez',
    'étronçonnions',
    'étymologiques',
    'étymologistes',
    'eucharistique',
    'eudiométrique',
    'euphorisaient',
    'euphorisantes',
    'euphorisasses',
    'euphoriserais',
    'euphoriserait',
    'euphorisèrent',
    'euphoriseriez',
    'euphoriserons',
    'euphoriseront',
    'eurafricaines',
    'eurasiatiques',
    'européanisais',
    'européanisait',
    'européanisant',
    'européanisées',
    'européanisent',
    'européanisera',
    'européanisiez',
    'européanismes',
    'européanisons',
    'eussent-elles',
    'eussions-nous',
    'évangéliaires',
    'évangélisâmes',
    'évangélisasse',
    'évangélisâtes',
    'évangéliserai',
    'évangéliseras',
    'évangéliserez',
    'évangélisions',
    'évanouissions',
    'évaporassions',
    'évaporatoires',
    'évaporeraient',
    'éveillassions',
    'éveilleraient',
    'événementiels',
    'éventrassions',
    'éventreraient',
    'évertuassions',
    'éviscérassent',
    'éviscérassiez',
    'éviscérerions',
    'exacerbassent',
    'exacerbassiez',
    'exacerbations',
    'exacerberions',
    'exagérassions',
    'exagératrices',
    'exagéreraient',
    'examinassions',
    'examinatrices',
    'examineraient',
    'exaspérassent',
    'exaspérassiez',
    'exaspérations',
    'exaspérerions',
    'excédentaires',
    'excellassions',
    'excelleraient',
    'excentrassent',
    'excentrassiez',
    'excentrations',
    'excentrerions',
    'excentricités',
    'exceptassions',
    'excepteraient',
    'exceptionnels',
    'excessivement',
    'excitabilités',
    'exclamassions',
    'exclusivement',
    'exclusivismes',
    'excommuniâmes',
    'excommuniasse',
    'excommuniâtes',
    'excommunierai',
    'excommunieras',
    'excommunierez',
    'excommuniions',
    'excoriassions',
    'excorieraient',
    'excrémentiels',
    'excrétassions',
    'excréteraient',
    'excroissances',
    'excursionnais',
    'excursionnait',
    'excursionnant',
    'excursionnent',
    'excursionnera',
    'excursionniez',
    'excursionnons',
    'exécrablement',
    'exécutassions',
    'exécuteraient',
    'exemplifiâmes',
    'exemplifiasse',
    'exemplifiâtes',
    'exemplifierai',
    'exemplifieras',
    'exemplifierez',
    'exemplifiions',
    'exemptassions',
    'exempteraient',
    'exfoliassions',
    'exfolieraient',
    'exhaussassent',
    'exhaussassiez',
    'exhaussements',
    'exhausserions',
    'exhaustivités',
    'exhérédassent',
    'exhérédassiez',
    'exhérédations',
    'exhéréderions',
    'exhortassions',
    'exhorteraient',
    'existentielle',
    'exonérassions',
    'exonéreraient',
    'exophtalmique',
    'exorcisassent',
    'exorcisassiez',
    'exorcisations',
    'exorciserions',
    'exothermiques',
    'expansibilité',
    'expatriassent',
    'expatriassiez',
    'expatriations',
    'expatrierions',
    'expectoraient',
    'expectorantes',
    'expectorasses',
    'expectoration',
    'expectorerais',
    'expectorerait',
    'expectorèrent',
    'expectoreriez',
    'expectorerons',
    'expectoreront',
    'expédiassions',
    'expédieraient',
    'expérimentais',
    'expérimentait',
    'expérimentale',
    'expérimentant',
    'expérimentaux',
    'expérimentées',
    'expérimentent',
    'expérimentera',
    'expérimentiez',
    'expérimentons',
    'expertisaient',
    'expertisasses',
    'expertiserais',
    'expertiserait',
    'expertisèrent',
    'expertiseriez',
    'expertiserons',
    'expertiseront',
    'explicitaient',
    'explicitasses',
    'explicitation',
    'explicitement',
    'expliciterais',
    'expliciterait',
    'explicitèrent',
    'expliciteriez',
    'expliciterons',
    'expliciteront',
    'expliquassent',
    'expliquassiez',
    'expliquerions',
    'exploitassent',
    'exploitassiez',
    'exploitations',
    'exploiterions',
    'explorassions',
    'exploratoires',
    'exploratrices',
    'exploreraient',
    'explosassions',
    'exploseraient',
    'exponentielle',
    'exportassions',
    'exportatrices',
    'exporteraient',
    'expressivités',
    'exprimassions',
    'exprimeraient',
    'expromissions',
    'expropriaient',
    'expropriantes',
    'expropriasses',
    'expropriation',
    'exproprierais',
    'exproprierait',
    'exproprièrent',
    'exproprieriez',
    'exproprierons',
    'exproprieront',
    'expulsassions',
    'expulseraient',
    'expurgatoires',
    'expurgeassent',
    'expurgeassiez',
    'expurgeraient',
    'extasiassions',
    'extemporanées',
    'extensibilité',
    'extensomètres',
    'exténuassions',
    'exténueraient',
    'extériorisais',
    'extériorisait',
    'extériorisant',
    'extériorisées',
    'extériorisent',
    'extériorisera',
    'extériorisiez',
    'extériorisons',
    'exterminaient',
    'exterminasses',
    'exterminateur',
    'extermination',
    'exterminerais',
    'exterminerait',
    'exterminèrent',
    'extermineriez',
    'exterminerons',
    'extermineront',
    'extéroceptifs',
    'extéroceptive',
    'extirpassions',
    'extirperaient',
    'extorquassent',
    'extorquassiez',
    'extorquerions',
    'extradassions',
    'extraderaient',
    'extra-légales',
    'extra-lucides',
    'extrapolaient',
    'extrapolasses',
    'extrapolation',
    'extrapolerais',
    'extrapolerait',
    'extrapolèrent',
    'extrapoleriez',
    'extrapolerons',
    'extrapoleront',
    'extrasystoles',
    'extravagances',
    'extravagantes',
    'extravaguâmes',
    'extravaguasse',
    'extravaguâtes',
    'extravaguerai',
    'extravagueras',
    'extravaguerez',
    'extravaguions',
    'extravasaient',
    'extravasasses',
    'extravasation',
    'extravaserais',
    'extravaserait',
    'extravasèrent',
    'extravaseriez',
    'extravaserons',
    'extravaseront',
    'extraversions',
    'extrudassions',
    'extruderaient',
    'exulcérassent',
    'exulcérassiez',
    'exulcérations',
    'exulcérerions',
    'fabricatrices',
    'fabriquassent',
    'fabriquassiez',
    'fabriquerions',
    'fabuleusement',
    'facettassions',
    'facetteraient',
    'facilitassent',
    'facilitassiez',
    'facilitations',
    'faciliterions',
    'façonnassions',
    'façonneraient',
    'factionnaires',
    'factorisaient',
    'factorisasses',
    'factorisation',
    'factoriserais',
    'factoriserait',
    'factorisèrent',
    'factoriseriez',
    'factoriserons',
    'factoriseront',
    'facturassions',
    'factureraient',
    'faiblissaient',
    'faiblissantes',
    'faignantaient',
    'faignantasses',
    'faignanterais',
    'faignanterait',
    'faignantèrent',
    'faignanteriez',
    'faignanterons',
    'faignanteront',
    'faillibilités',
    'fainéantaient',
    'fainéantasses',
    'fainéanterais',
    'fainéanterait',
    'fainéantèrent',
    'fainéanteriez',
    'fainéanterons',
    'fainéanteront',
    'faisandassent',
    'faisandassiez',
    'faisanderions',
    'falsifiassent',
    'falsifiassiez',
    'falsificateur',
    'falsification',
    'falsifierions',
    'familiarisais',
    'familiarisait',
    'familiarisant',
    'familiarisées',
    'familiarisent',
    'familiarisera',
    'familiarisiez',
    'familiarisons',
    'familièrement',
    'fanatiquement',
    'fanatisassent',
    'fanatisassiez',
    'fanatiserions',
    'fanfaronnades',
    'fanfaronnâmes',
    'fanfaronnasse',
    'fanfaronnâtes',
    'fanfaronnerai',
    'fanfaronneras',
    'fanfaronnerez',
    'fanfaronnions',
    'fanfreluchais',
    'fanfreluchait',
    'fanfreluchant',
    'fanfreluchées',
    'fanfreluchent',
    'fanfreluchera',
    'fanfreluchiez',
    'fanfreluchons',
    'fangothérapie',
    'fantasmagorie',
    'fantasmassent',
    'fantasmassiez',
    'fantasmatique',
    'fantasmerions',
    'fantomatiques',
    'farfouillâmes',
    'farfouillasse',
    'farfouillâtes',
    'farfouillerai',
    'farfouilleras',
    'farfouillerez',
    'farfouillions',
    'fascinassions',
    'fascinatrices',
    'fascineraient',
    'fascisassions',
    'fasciseraient',
    'faseillassent',
    'faseillassiez',
    'faseillerions',
    'fastueusement',
    'fatigabilités',
    'fatiguassions',
    'fatigueraient',
    'faubouriennes',
    'faucardassent',
    'faucardassiez',
    'faucarderions',
    'fauconnassent',
    'fauconnassiez',
    'fauconnerions',
    'faufilassions',
    'faufileraient',
    'faux-bourdons',
    'faux-négatifs',
    'faux-positifs',
    'faux-semblant',
    'favorablement',
    'favorisassent',
    'favorisassiez',
    'favoriserions',
    'fayottassions',
    'fayotteraient',
    'fécondabilité',
    'fécondassions',
    'fécondatrices',
    'féconderaient',
    'fédéralisâmes',
    'fédéralisasse',
    'fédéralisâtes',
    'fédéraliserai',
    'fédéraliseras',
    'fédéraliserez',
    'fédéralisions',
    'feignantaient',
    'feignantasses',
    'feignanterais',
    'feignanterait',
    'feignantèrent',
    'feignanteriez',
    'feignanterons',
    'feignanteront',
    'feldspathique',
    'félicitassent',
    'félicitassiez',
    'félicitations',
    'féliciterions',
    'féminisassent',
    'féminisassiez',
    'féminisations',
    'féminiserions',
    'fendillassent',
    'fendillassiez',
    'fendillements',
    'fendillerions',
    'fenestrassent',
    'fenestrassiez',
    'fenestrations',
    'fenestrerions',
    'fenêtrassions',
    'fenêtreraient',
    'fenouillettes',
    'ferblanteries',
    'fermentassent',
    'fermentassiez',
    'fermentations',
    'fermentatives',
    'fermenterions',
    'ferraillaient',
    'ferraillasses',
    'ferraillement',
    'ferraillerais',
    'ferraillerait',
    'ferraillèrent',
    'ferrailleriez',
    'ferraillerons',
    'ferrailleront',
    'ferrailleuses',
    'ferricyanures',
    'ferrugineuses',
    'fertilisables',
    'fertilisaient',
    'fertilisantes',
    'fertilisasses',
    'fertilisation',
    'fertiliserais',
    'fertiliserait',
    'fertilisèrent',
    'fertiliseriez',
    'fertiliserons',
    'fertiliseront',
    'festivalières',
    'festoieraient',
    'festonnassent',
    'festonnassiez',
    'festonnerions',
    'festoyassions',
    'fétichisaient',
    'fétichisasses',
    'fétichiserais',
    'fétichiserait',
    'fétichisèrent',
    'fétichiseriez',
    'fétichiserons',
    'fétichiseront',
    'feuiletisâmes',
    'feuiletisasse',
    'feuiletisâtes',
    'feuillagistes',
    'feuillantines',
    'feuillassions',
    'feuille-morte',
    'feuilleraient',
    'feuilletaient',
    'feuilletasses',
    'feuilletèrent',
    'feuilletisais',
    'feuilletisait',
    'feuilletisant',
    'feuilletisées',
    'feuilletisent',
    'feuilletisera',
    'feuilletisiez',
    'feuilletisons',
    'feuilletterai',
    'feuilletteras',
    'feuilletterez',
    'fibrillations',
    'fibromateuses',
    'ficelleraient',
    'fidéjussoires',
    'fiévreusement',
    'fignolassions',
    'fignoleraient',
    'filamenteuses',
    'filigranaient',
    'filigranasses',
    'filigranerais',
    'filigranerait',
    'filigranèrent',
    'filigraneriez',
    'filigranerons',
    'filigraneront',
    'filmographies',
    'filochassions',
    'filocheraient',
    'filoutassions',
    'filouteraient',
    'finalisations',
    'finançassions',
    'financeraient',
    'finassassions',
    'finasseraient',
    'finlandisâmes',
    'finlandisasse',
    'finlandisâtes',
    'finlandiserai',
    'finlandiseras',
    'finlandiserez',
    'finlandisions',
    'finno-ougrien',
    'fiscalisaient',
    'fiscalisasses',
    'fiscalisation',
    'fiscaliserais',
    'fiscaliserait',
    'fiscalisèrent',
    'fiscaliseriez',
    'fiscaliserons',
    'fiscaliseront',
    'fissionnaient',
    'fissionnasses',
    'fissionnerais',
    'fissionnerait',
    'fissionnèrent',
    'fissionneriez',
    'fissionnerons',
    'fissionneront',
    'fissurassions',
    'fissureraient',
    'flagellassent',
    'flagellassiez',
    'flagellateurs',
    'flagellations',
    'flagellatrice',
    'flagellerions',
    'flageolassent',
    'flageolassiez',
    'flageolements',
    'flageolerions',
    'flagornassent',
    'flagornassiez',
    'flagornerions',
    'flamboiements',
    'flamboierions',
    'flamboyassent',
    'flamboyassiez',
    'flanchassions',
    'flancheraient',
    'flandricismes',
    'flanquassions',
    'flanqueraient',
    'flatteusement',
    'fléchissaient',
    'fléchissement',
    'flemmardaient',
    'flemmardasses',
    'flemmarderais',
    'flemmarderait',
    'flemmardèrent',
    'flemmarderiez',
    'flemmarderons',
    'flemmarderont',
    'flétrissaient',
    'flétrissantes',
    'flétrissement',
    'fleurdelisées',
    'fleurettistes',
    'fleurissaient',
    'fleurissantes',
    'flexionnelles',
    'flibustassent',
    'flibustassiez',
    'flibusterions',
    'flinguassions',
    'flingueraient',
    'floconnassent',
    'floconnassiez',
    'floconnerions',
    'floculassions',
    'floculeraient',
    'floricultures',
    'flottabilités',
    'fluctuassions',
    'fluctueraient',
    'fluidifiaient',
    'fluidifiantes',
    'fluidifiasses',
    'fluidifierais',
    'fluidifierait',
    'fluidifièrent',
    'fluidifieriez',
    'fluidifierons',
    'fluidifieront',
    'fluidisassent',
    'fluidisassiez',
    'fluidiserions',
    'fluorescéines',
    'fluorescences',
    'fluorescentes',
    'fluorhydrique',
    'fluorisassent',
    'fluorisassiez',
    'fluoriserions',
    'fluorurations',
    'fluotournages',
    'focalisassent',
    'focalisassiez',
    'focalisations',
    'focaliserions',
    'foisonnassent',
    'foisonnassiez',
    'foisonnements',
    'foisonnerions',
    'folâtrassions',
    'folâtreraient',
    'folichonnâmes',
    'folichonnasse',
    'folichonnâtes',
    'folichonnerai',
    'folichonneras',
    'folichonnerez',
    'folichonnions',
    'foliotassions',
    'folioteraient',
    'folliculaires',
    'fomentassions',
    'fomentatrices',
    'fomenteraient',
    'fonctionnaire',
    'fonctionnâmes',
    'fonctionnasse',
    'fonctionnâtes',
    'fonctionnelle',
    'fonctionnerai',
    'fonctionneras',
    'fonctionnerez',
    'fonctionnions',
    'fondamentales',
    'fongistatique',
    'fontainebleau',
    'footballeuses',
    'forcipressure',
    'forjetassions',
    'forjetterions',
    'forlançassent',
    'forlançassiez',
    'forlancerions',
    'forlignassent',
    'forlignassiez',
    'forlignerions',
    'formaldéhydes',
    'formalisaient',
    'formalisantes',
    'formalisasses',
    'formalisation',
    'formaliserais',
    'formaliserait',
    'formalisèrent',
    'formaliseriez',
    'formaliserons',
    'formaliseront',
    'formolassions',
    'formoleraient',
    'formulassions',
    'formuleraient',
    'fornicatrices',
    'forniquassent',
    'forniquassiez',
    'forniquerions',
    'fortifiassent',
    'fortifiassiez',
    'fortification',
    'fortifierions',
    'fossilisaient',
    'fossilisasses',
    'fossilisation',
    'fossiliserais',
    'fossiliserait',
    'fossilisèrent',
    'fossiliseriez',
    'fossiliserons',
    'fossiliseront',
    'fossoieraient',
    'fossoyassions',
    'fouaillassent',
    'fouaillassiez',
    'fouaillerions',
    'foudroiements',
    'foudroierions',
    'foudroyassent',
    'foudroyassiez',
    'fouettassions',
    'fouetteraient',
    'fougueusement',
    'fouillassions',
    'fouilleraient',
    'fouraillaient',
    'fouraillasses',
    'fouraillerais',
    'fouraillerait',
    'fouraillèrent',
    'fourailleriez',
    'fouraillerons',
    'fourailleront',
    'fourbissaient',
    'fourchassions',
    'fourcherasses',
    'fourcherèrent',
    'fourgonnaient',
    'fourgonnasses',
    'fourgonnerais',
    'fourgonnerait',
    'fourgonnèrent',
    'fourgonneriez',
    'fourgonnerons',
    'fourgonneront',
    'fourgonnettes',
    'fourguassions',
    'fourgueraient',
    'fourmillaient',
    'fourmillantes',
    'fourmillasses',
    'fourmillement',
    'fourmillerais',
    'fourmillerait',
    'fourmillèrent',
    'fourmilleriez',
    'fourmillerons',
    'fourmilleront',
    'fourmis-lions',
    'fournissaient',
    'fournisseuses',
    'fourrageaient',
    'fourrageasses',
    'fourragerions',
    'fourvoiements',
    'fourvoierions',
    'fourvoyassent',
    'fourvoyassiez',
    'fracassassent',
    'fracassassiez',
    'fracasserions',
    'fractionnâmes',
    'fractionnante',
    'fractionnants',
    'fractionnasse',
    'fractionnâtes',
    'fractionnelle',
    'fractionnerai',
    'fractionneras',
    'fractionnerez',
    'fractionnions',
    'fractionnisme',
    'fracturassent',
    'fracturassiez',
    'fracturerions',
    'fragilisaient',
    'fragilisantes',
    'fragilisasses',
    'fragiliserais',
    'fragiliserait',
    'fragilisèrent',
    'fragiliseriez',
    'fragiliserons',
    'fragiliseront',
    'fragmentaient',
    'fragmentaires',
    'fragmentasses',
    'fragmentation',
    'fragmenterais',
    'fragmenterait',
    'fragmentèrent',
    'fragmenteriez',
    'fragmenterons',
    'fragmenteront',
    'fraîchiraient',
    'fraîchissante',
    'fraîchissants',
    'fraîchissions',
    'framboisaient',
    'framboisasses',
    'framboiserais',
    'framboiserait',
    'framboisèrent',
    'framboiseriez',
    'framboiserons',
    'framboiseront',
    'franchiraient',
    'franchissable',
    'franchissions',
    'francisassent',
    'francisassiez',
    'francisations',
    'franciscaines',
    'franciserions',
    'franc-maçonne',
    'francophilies',
    'francophobies',
    'francophonies',
    'francs-alleux',
    'francs-maçons',
    'frangeassions',
    'frangipaniers',
    'fraternisâmes',
    'fraternisante',
    'fraternisants',
    'fraternisasse',
    'fraternisâtes',
    'fraterniserai',
    'fraterniseras',
    'fraterniserez',
    'fraternisions',
    'fredonnassent',
    'fredonnassiez',
    'fredonnements',
    'fredonnerions',
    'frégatassions',
    'frelatassions',
    'frelateraient',
    'frémissements',
    'fréquentables',
    'fréquentaient',
    'fréquentasses',
    'fréquentatifs',
    'fréquentation',
    'fréquentative',
    'fréquenterais',
    'fréquenterait',
    'fréquentèrent',
    'fréquenteriez',
    'fréquenterons',
    'fréquenteront',
    'fréquentielle',
    'frétillassent',
    'frétillassiez',
    'frétillements',
    'frétillerions',
    'fricassassent',
    'fricassassiez',
    'fricasserions',
    'fricotassions',
    'fricoteraient',
    'frictionnâmes',
    'frictionnasse',
    'frictionnâtes',
    'frictionnelle',
    'frictionnerai',
    'frictionneras',
    'frictionnerez',
    'frictionnions',
    'frigorifiâmes',
    'frigorifiante',
    'frigorifiants',
    'frigorifiasse',
    'frigorifiâtes',
    'frigorifierai',
    'frigorifieras',
    'frigorifierez',
    'frigorifiions',
    'frigorifiques',
    'frigorifugeai',
    'frigorifugeas',
    'frigorifugeât',
    'frigorifugées',
    'frigorifugent',
    'frigorifugera',
    'frigorifugiez',
    'fringuassions',
    'fringueraient',
    'friponnassent',
    'friponnassiez',
    'friponnerions',
    'fripouillerie',
    'frisottassent',
    'frisottassiez',
    'frisotterions',
    'frissonnaient',
    'frissonnantes',
    'frissonnasses',
    'frissonnement',
    'frissonnerais',
    'frissonnerait',
    'frissonnèrent',
    'frissonneriez',
    'frissonnerons',
    'frissonneront',
    'froidissaient',
    'froissassions',
    'froisseraient',
    'froufroutâmes',
    'froufroutante',
    'froufroutants',
    'froufroutasse',
    'froufroutâtes',
    'froufrouterai',
    'froufrouteras',
    'froufrouterez',
    'froufroutions',
    'fructifiaient',
    'fructifiantes',
    'fructifiasses',
    'fructifierais',
    'fructifierait',
    'fructifièrent',
    'fructifieriez',
    'fructifierons',
    'fructifieront',
    'fruiticulteur',
    'frusquassions',
    'frusqueraient',
    'frustrassions',
    'frustratoires',
    'frustreraient',
    'fulgurassions',
    'fulgureraient',
    'fulminassions',
    'fulminatoires',
    'fulmineraient',
    'fumigeassions',
    'funambulesque',
    'furonculeuses',
    'fuselleraient',
    'fusillassions',
    'fusilleraient',
    'fusionnassent',
    'fusionnassiez',
    'fusionnements',
    'fusionnerions',
    'fussent-elles',
    'fustigeassent',
    'fustigeassiez',
    'fustigeraient',
    'gabionnassent',
    'gabionnassiez',
    'gabionnerions',
    'gadgétisaient',
    'gadgétisasses',
    'gadgétiserais',
    'gadgétiserait',
    'gadgétisèrent',
    'gadgétiseriez',
    'gadgétiserons',
    'gadgétiseront',
    'gaillardement',
    'galactagogues',
    'galactomètres',
    'galactophores',
    'galéopithèque',
    'galipotassent',
    'galipotassiez',
    'galipoterions',
    'gallicanismes',
    'gallo-romaine',
    'gallo-romains',
    'galonnassions',
    'galonneraient',
    'galvanisaient',
    'galvanisantes',
    'galvanisasses',
    'galvanisation',
    'galvaniserais',
    'galvaniserait',
    'galvanisèrent',
    'galvaniseriez',
    'galvaniserons',
    'galvaniseront',
    'galvanomètres',
    'galvanotypies',
    'galvaudassent',
    'galvaudassiez',
    'galvauderions',
    'gambadassions',
    'gambaderaient',
    'gambergeaient',
    'gambergeasses',
    'gambergerions',
    'gambillassent',
    'gambillassiez',
    'gambillerions',
    'ganglionnaire',
    'gangrenassent',
    'gangrenassiez',
    'gangrènerions',
    'gangstérismes',
    'garançassions',
    'garanceraient',
    'garantiraient',
    'garantissions',
    'garde-magasin',
    'garde-meubles',
    'gardes-boeufs',
    'gardes-chasse',
    'gardes-freins',
    'gardes-malade',
    'gargantuesque',
    'gargarisaient',
    'gargarisantes',
    'gargarisasses',
    'gargariserais',
    'gargariserait',
    'gargarisèrent',
    'gargariseriez',
    'gargariserons',
    'gargariseront',
    'gargotassions',
    'gargoteraient',
    'gargouillâmes',
    'gargouillasse',
    'gargouillâtes',
    'gargouillerai',
    'gargouilleras',
    'gargouillerez',
    'gargouillions',
    'garibaldienne',
    'garrottassent',
    'garrottassiez',
    'garrotterions',
    'gasconnassent',
    'gasconnassiez',
    'gasconnerions',
    'gaspillassent',
    'gaspillassiez',
    'gaspillerions',
    'gastralgiques',
    'gastrectomies',
    'gastronomique',
    'gastroscopies',
    'gâtifiassions',
    'gâtifieraient',
    'gauchissaient',
    'gauchissement',
    'gazéifiassent',
    'gazéifiassiez',
    'gazéification',
    'gazéifierions',
    'gazonnassions',
    'gazonneraient',
    'gazouillaient',
    'gazouillasses',
    'gazouillement',
    'gazouillerais',
    'gazouillerait',
    'gazouillèrent',
    'gazouilleriez',
    'gazouillerons',
    'gazouilleront',
    'gazouilleuses',
    'gélatinassent',
    'gélatinassiez',
    'gélatinerions',
    'gélatinisâmes',
    'gélatinisasse',
    'gélatinisâtes',
    'gélatiniserai',
    'gélatiniseras',
    'gélatiniserez',
    'gélatinisions',
    'gélifiassions',
    'gélifications',
    'gélifieraient',
    'gemmologistes',
    'gendarmassent',
    'gendarmassiez',
    'gendarmerions',
    'généalogiques',
    'généalogistes',
    'généralisable',
    'généralisâmes',
    'généralisante',
    'généralisants',
    'généralisasse',
    'généralisâtes',
    'généraliserai',
    'généraliseras',
    'généraliserez',
    'généralisions',
    'généralissime',
    'généreusement',
    'généthliaques',
    'généticiennes',
    'génétiquement',
    'gentilshommes',
    'géocentriques',
    'géodynamiques',
    'géographiques',
    'géomagnétique',
    'géomagnétisme',
    'géométrisâmes',
    'géométrisasse',
    'géométrisâtes',
    'géométriserai',
    'géométriseras',
    'géométriserez',
    'géométrisions',
    'géophysiciens',
    'géopolitiques',
    'géosynclinaux',
    'géotechniques',
    'géothermiques',
    'germanisaient',
    'germanisasses',
    'germanisation',
    'germaniserais',
    'germaniserait',
    'germanisèrent',
    'germaniseriez',
    'germaniserons',
    'germaniseront',
    'germanophiles',
    'germanophobes',
    'germinatrices',
    'gérontocratie',
    'gérontologies',
    'gérontologues',
    'gérontophiles',
    'gérontophilie',
    'gesticulaient',
    'gesticulantes',
    'gesticulasses',
    'gesticulation',
    'gesticulerais',
    'gesticulerait',
    'gesticulèrent',
    'gesticuleriez',
    'gesticulerons',
    'gesticuleront',
    'gestionnaires',
    'gigantomachie',
    'gironnassions',
    'gironneraient',
    'girouettaient',
    'girouettasses',
    'girouetterais',
    'girouetterait',
    'girouettèrent',
    'girouetteriez',
    'girouetterons',
    'girouetteront',
    'glaciologique',
    'glagolitiques',
    'glandouillais',
    'glandouillait',
    'glandouillant',
    'glandouillent',
    'glandouillera',
    'glandouilliez',
    'glandouillons',
    'glapissements',
    'glaviotassent',
    'glaviotassiez',
    'glavioterions',
    'glaviottaient',
    'glaviottasses',
    'glaviotterais',
    'glaviotterait',
    'glaviottèrent',
    'glaviotteriez',
    'glaviotterons',
    'glaviotteront',
    'globalisaient',
    'globalisantes',
    'globalisasses',
    'globaliserais',
    'globaliserait',
    'globalisèrent',
    'globaliseriez',
    'globaliserons',
    'globaliseront',
    'globe-trotter',
    'glockenspiels',
    'glorieusement',
    'glorifiassent',
    'glorifiassiez',
    'glorificateur',
    'glorification',
    'glorifierions',
    'glougloutâmes',
    'glougloutante',
    'glougloutants',
    'glougloutasse',
    'glougloutâtes',
    'glouglouterai',
    'glouglouteras',
    'glouglouterez',
    'glougloutions',
    'gloussassions',
    'glousseraient',
    'gloutonnement',
    'gloutonneries',
    'glycérinaient',
    'glycérinasses',
    'glycérinerais',
    'glycérinerait',
    'glycérinèrent',
    'glycérineriez',
    'glycérinerons',
    'glycérineront',
    'glycogéniques',
    'glycoprotéine',
    'glycosuriques',
    'glyptographie',
    'glyptothèques',
    'gobergeassent',
    'gobergeassiez',
    'gobergeraient',
    'gobetteraient',
    'godaillassent',
    'godaillassiez',
    'godaillerions',
    'godillassions',
    'godilleraient',
    'godronnassent',
    'godronnassiez',
    'godronnerions',
    'goguenardâmes',
    'goguenardasse',
    'goguenardâtes',
    'goguenarderai',
    'goguenarderas',
    'goguenarderez',
    'goguenardions',
    'goguenardises',
    'goinfrassions',
    'goinfreraient',
    'gommes-guttes',
    'gomorrhéennes',
    'gondolassions',
    'gondoleraient',
    'gonochorismes',
    'gouachassions',
    'gouacheraient',
    'gouaillassent',
    'gouaillassiez',
    'gouaillerions',
    'goudronnaient',
    'goudronnasses',
    'goudronnerais',
    'goudronnerait',
    'goudronnèrent',
    'goudronneriez',
    'goudronnerons',
    'goudronneront',
    'goudronneuses',
    'goujonnassent',
    'goujonnassiez',
    'goujonnerions',
    'goupillassent',
    'goupillassiez',
    'goupillerions',
    'goupillonnais',
    'goupillonnait',
    'goupillonnant',
    'goupillonnées',
    'goupillonnent',
    'goupillonnera',
    'goupillonniez',
    'goupillonnons',
    'gourmandaient',
    'gourmandasses',
    'gourmanderais',
    'gourmanderait',
    'gourmandèrent',
    'gourmanderiez',
    'gourmanderons',
    'gourmanderont',
    'gouvernassent',
    'gouvernassiez',
    'gouvernements',
    'gouvernerions',
    'gracieusement',
    'graduellement',
    'graillassions',
    'grailleraient',
    'graillonnâmes',
    'graillonnasse',
    'graillonnâtes',
    'graillonnerai',
    'graillonneras',
    'graillonnerez',
    'graillonnions',
    'graissassions',
    'graisseraient',
    'grammairienne',
    'grammaticales',
    'grand-ducales',
    'grandiloquent',
    'grandissaient',
    'grandissantes',
    'grands-angles',
    'grands-duchés',
    'grands-mamans',
    'grands-messes',
    'grands-oncles',
    'grands-tantes',
    'grands-voiles',
    'granitassions',
    'graniteraient',
    'granulassions',
    'granuleraient',
    'granulométrie',
    'graphiquement',
    'graphitassent',
    'graphitassiez',
    'graphiterions',
    'graphologique',
    'grappillaient',
    'grappillasses',
    'grappillerais',
    'grappillerait',
    'grappillèrent',
    'grappilleriez',
    'grappillerons',
    'grappilleront',
    'grappilleuses',
    'grasseyassent',
    'grasseyassiez',
    'grasseyements',
    'grasseyerions',
    'grassouillets',
    'graticulaient',
    'graticulasses',
    'graticulerais',
    'graticulerait',
    'graticulèrent',
    'graticuleriez',
    'graticulerons',
    'graticuleront',
    'gratifiassent',
    'gratifiassiez',
    'gratification',
    'gratifierions',
    'gratinassions',
    'gratineraient',
    'gratte-papier',
    'gravillonnage',
    'gravitassions',
    'graviteraient',
    'grécisassions',
    'gréciseraient',
    'grecquassions',
    'grecqueraient',
    'grelottassent',
    'grelottassiez',
    'grelottements',
    'grelotterions',
    'grenaillaient',
    'grenaillasses',
    'grenaillerais',
    'grenaillerait',
    'grenaillèrent',
    'grenailleriez',
    'grenaillerons',
    'grenailleront',
    'grenelassions',
    'grenellerions',
    'grenouillages',
    'grenouillâmes',
    'grenouillasse',
    'grenouillâtes',
    'grenouillerai',
    'grenouilleras',
    'grenouillères',
    'grenouillerez',
    'grenouillette',
    'grenouillions',
    'grésillements',
    'gribouillages',
    'gribouillâmes',
    'gribouillasse',
    'gribouillâtes',
    'gribouillerai',
    'gribouilleras',
    'gribouillerez',
    'gribouilleurs',
    'gribouilleuse',
    'gribouillions',
    'griffonnaient',
    'griffonnasses',
    'griffonnement',
    'griffonnerais',
    'griffonnerait',
    'griffonnèrent',
    'griffonneriez',
    'griffonnerons',
    'griffonneront',
    'griffonneuses',
    'grignotassent',
    'grignotassiez',
    'grignotements',
    'grignoterions',
    'grillageaient',
    'grillageasses',
    'grillagerions',
    'grimaçassions',
    'grimaceraient',
    'grinchassions',
    'grincheraient',
    'gringuassions',
    'gringueraient',
    'grisaillaient',
    'grisaillasses',
    'grisaillerais',
    'grisaillerait',
    'grisaillèrent',
    'grisailleriez',
    'grisaillerons',
    'grisailleront',
    'grisolassions',
    'grisoleraient',
    'grisollassent',
    'grisollassiez',
    'grisollerions',
    'grisonnassent',
    'grisonnassiez',
    'grisonnements',
    'grisonnerions',
    'grivelassions',
    'grivellerions',
    'grognassaient',
    'grognassasses',
    'grognasserais',
    'grognasserait',
    'grognassèrent',
    'grognasseriez',
    'grognasserons',
    'grognasseront',
    'grommelassent',
    'grommelassiez',
    'grommellement',
    'grommellerais',
    'grommellerait',
    'grommelleriez',
    'grommellerons',
    'grommelleront',
    'gros-porteurs',
    'grossièrement',
    'grossissaient',
    'grossissantes',
    'grossissement',
    'grossoierions',
    'grossoyassent',
    'grossoyassiez',
    'grotesquement',
    'grouillements',
    'grouillerions',
    'groullassions',
    'grumelassions',
    'grumellerions',
    'guerroierions',
    'guerroyassent',
    'guerroyassiez',
    'gueuletonnais',
    'gueuletonnait',
    'gueuletonnant',
    'gueuletonnent',
    'gueuletonnera',
    'gueuletonniez',
    'gueuletonnons',
    'guillemetâmes',
    'guillemetasse',
    'guillemetâtes',
    'guillemetions',
    'guillemettent',
    'guillemettera',
    'guillochaient',
    'guillochasses',
    'guillocherais',
    'guillocherait',
    'guillochèrent',
    'guillocheriez',
    'guillocherons',
    'guillocheront',
    'guillotinâmes',
    'guillotinasse',
    'guillotinâtes',
    'guillotinerai',
    'guillotineras',
    'guillotinerez',
    'guillotinions',
    'guinchassions',
    'guincheraient',
    'gymnasiarques',
    'gymnosophiste',
    'gynécologique',
    'gynécomasties',
    'gyroscopiques',
    'habilitassent',
    'habilitassiez',
    'habilitations',
    'habiliterions',
    'habillassions',
    'habilleraient',
    'habitabilités',
    'habituassions',
    'habitueraient',
    'hache-légumes',
    'hachurassions',
    'hachureraient',
    'hagiographies',
    'haillonneuses',
    'hallebardiers',
    'hallstattiens',
    'hallucinaient',
    'hallucinantes',
    'hallucinasses',
    'hallucination',
    'hallucinerais',
    'hallucinerait',
    'hallucinèrent',
    'hallucineriez',
    'hallucinerons',
    'hallucineront',
    'hallucinogène',
    'halogénations',
    'haltérophiles',
    'haltérophilie',
    'hameçonnaient',
    'hameçonnasses',
    'hameçonnerais',
    'hameçonnerait',
    'hameçonnèrent',
    'hameçonneriez',
    'hameçonnerons',
    'hameçonneront',
    'handballeuses',
    'handicapaient',
    'handicapasses',
    'handicaperais',
    'handicaperait',
    'handicapèrent',
    'handicaperiez',
    'handicaperons',
    'handicaperont',
    'hannetonnages',
    'hannetonnâmes',
    'hannetonnasse',
    'hannetonnâtes',
    'hannetonnerai',
    'hannetonneras',
    'hannetonnerez',
    'hannetonnions',
    'haranguassent',
    'haranguassiez',
    'haranguerions',
    'harassassions',
    'harasseraient',
    'harcelassions',
    'harcèleraient',
    'harcellements',
    'harcellerions',
    'hargneusement',
    'harmonicistes',
    'harmonicordes',
    'harmonisaient',
    'harmonisasses',
    'harmonisation',
    'harmoniserais',
    'harmoniserait',
    'harmonisèrent',
    'harmoniseriez',
    'harmoniserons',
    'harmoniseront',
    'harnachassent',
    'harnachassiez',
    'harnachements',
    'harnacherions',
    'harpaillaient',
    'harpaillasses',
    'harpaillerais',
    'harpaillerait',
    'harpaillèrent',
    'harpailleriez',
    'harpaillerons',
    'harpailleront',
    'harponnassent',
    'harponnassiez',
    'harponnements',
    'harponnerions',
    'hasardassions',
    'hasarderaient',
    'haubanassions',
    'haubaneraient',
    'haut-de-forme',
    'hautes-contre',
    'haut-le-coeur',
    'haut-le-corps',
    'haut-parleurs',
    'hauts-reliefs',
    'hebdomadaires',
    'hebdomadières',
    'hébéphrénique',
    'hébergeassent',
    'hébergeassiez',
    'hébergeraient',
    'hébraïsassent',
    'hébraïsassiez',
    'hébraïserions',
    'hectométrique',
    'hégélianismes',
    'héliciculteur',
    'héliciculture',
    'héliochromies',
    'héliographies',
    'héliograveurs',
    'héliogravures',
    'héliothérapie',
    'héliotropines',
    'héliotropisme',
    'hellénisaient',
    'hellénisantes',
    'hellénisasses',
    'hellénisation',
    'helléniserais',
    'helléniserait',
    'hellénisèrent',
    'helléniseriez',
    'helléniserons',
    'helléniseront',
    'hellénistique',
    'helminthiases',
    'helminthiques',
    'hématologique',
    'hématologiste',
    'hématopoïèses',
    'hématozoaires',
    'hémiplégiques',
    'hémisphérique',
    'hémodynamique',
    'hémoptysiques',
    'hémorragiques',
    'hémorroïdaire',
    'hémorroïdales',
    'hémostatiques',
    'hennissements',
    'hépatisations',
    'hépatomégalie',
    'herbageassent',
    'herbageassiez',
    'herbageraient',
    'herborisaient',
    'herborisasses',
    'herborisateur',
    'herborisation',
    'herboriserais',
    'herboriserait',
    'herborisèrent',
    'herboriseriez',
    'herboriserons',
    'herboriseront',
    'herboristerie',
    'hérissassions',
    'hérisseraient',
    'hérissonnâmes',
    'hérissonnasse',
    'hérissonnâtes',
    'hérissonnerai',
    'hérissonneras',
    'hérissonnerez',
    'hérissonnions',
    'hermaphrodite',
    'herméneutique',
    'héroï-comique',
    'herpétologies',
    'hétérocerques',
    'hétérogénéité',
    'hétérogreffes',
    'hétéromorphes',
    'hétérosexuels',
    'hétérotrophes',
    'hétérozygotes',
    'heure-machine',
    'hexadécimales',
    'hibernassions',
    'hiberneraient',
    'hidrosadénite',
    'hiérarchiques',
    'hiérarchisais',
    'hiérarchisait',
    'hiérarchisant',
    'hiérarchisées',
    'hiérarchisent',
    'hiérarchisera',
    'hiérarchisiez',
    'hiérarchisons',
    'hiérogrammate',
    'hippiatriques',
    'hippocratique',
    'hippocratisme',
    'hippologiques',
    'hippophagique',
    'hispanisantes',
    'hispano-arabe',
    'histaminiques',
    'histologiques',
    'histoplasmose',
    'historiassent',
    'historiassiez',
    'historierions',
    'hivernassions',
    'hiverneraient',
    'hollywoodiens',
    'holoprotéines',
    'homéopathique',
    'hominisations',
    'homocentrique',
    'homocinétique',
    'homocycliques',
    'homogénéifiai',
    'homogénéifias',
    'homogénéifiât',
    'homogénéifiée',
    'homogénéifier',
    'homogénéifies',
    'homogénéifiés',
    'homogénéifiez',
    'homogénéisais',
    'homogénéisait',
    'homogénéisant',
    'homogénéisées',
    'homogénéisent',
    'homogénéisera',
    'homogénéisiez',
    'homogénéisons',
    'homologations',
    'homologuaient',
    'homologuasses',
    'homologuerais',
    'homologuerait',
    'homologuèrent',
    'homologueriez',
    'homologuerons',
    'homologueront',
    'homosexualité',
    'homosexuelles',
    'homothétiques',
    'hongroierions',
    'hongroyassent',
    'hongroyassiez',
    'honorabilités',
    'honorablement',
    'hoquetassions',
    'hoquetterions',
    'horizontalité',
    'hormonassions',
    'hormoneraient',
    'horrifiassent',
    'horrifiassiez',
    'horrifierions',
    'horripilaient',
    'horripilantes',
    'horripilasses',
    'horripilation',
    'horripilerais',
    'horripilerait',
    'horripilèrent',
    'horripileriez',
    'horripilerons',
    'horripileront',
    'hors-concours',
    'horticulteurs',
    'horticultrice',
    'horticultures',
    'hortillonnage',
    'hospitalières',
    'hospitalisais',
    'hospitalisait',
    'hospitalisant',
    'hospitalisées',
    'hospitalisent',
    'hospitalisera',
    'hospitalisiez',
    'hospitalismes',
    'hospitalisons',
    'houblonnaient',
    'houblonnasses',
    'houblonnerais',
    'houblonnerait',
    'houblonnèrent',
    'houblonneriez',
    'houblonnerons',
    'houblonneront',
    'houblonnières',
    'hourdissaient',
    'houspillaient',
    'houspillasses',
    'houspillerais',
    'houspillerait',
    'houspillèrent',
    'houspilleriez',
    'houspillerons',
    'houspilleront',
    'houspilleuses',
    'humanisassent',
    'humanisassiez',
    'humanisations',
    'humaniserions',
    'humanitarisme',
    'humanitariste',
    'humectassions',
    'humecteraient',
    'humidifiaient',
    'humidifiasses',
    'humidifierais',
    'humidifierait',
    'humidifièrent',
    'humidifieriez',
    'humidifierons',
    'humidifieront',
    'humifications',
    'humiliassions',
    'humilieraient',
    'humoristiques',
    'hybridassions',
    'hybrideraient',
    'hydrargyrisme',
    'hydratassions',
    'hydrateraient',
    'hydrauliciens',
    'hydrocarbonée',
    'hydrocarbonés',
    'hydrocarbures',
    'hydrocéphales',
    'hydrocéphalie',
    'hydrofugeâmes',
    'hydrofugeasse',
    'hydrofugeâtes',
    'hydrofugerais',
    'hydrofugerait',
    'hydrofugèrent',
    'hydrofugeriez',
    'hydrofugerons',
    'hydrofugeront',
    'hydrogénaient',
    'hydrogénasses',
    'hydrogénation',
    'hydrogénerais',
    'hydrogénerait',
    'hydrogénèrent',
    'hydrogéneriez',
    'hydrogénerons',
    'hydrogéneront',
    'hydrogéologie',
    'hydroglisseur',
    'hydrographies',
    'hydrologiques',
    'hydrologistes',
    'hydrolysables',
    'hydrolysaient',
    'hydrolysasses',
    'hydrolyserais',
    'hydrolyserait',
    'hydrolysèrent',
    'hydrolyseriez',
    'hydrolyserons',
    'hydrolyseront',
    'hydrométéores',
    'hydrominérale',
    'hydrominéraux',
    'hydronéphrose',
    'hydroquinones',
    'hydrosilicate',
    'hydrosolubles',
    'hydrostatique',
    'hydrothérapie',
    'hygrométrique',
    'hygroscopique',
    'hyperacidités',
    'hyperacousies',
    'hyperalgésies',
    'hyperboliques',
    'hyperboloïdes',
    'hyperboréenne',
    'hypercalcémie',
    'hyperchromies',
    'hypercorrecte',
    'hypercorrects',
    'hyperesthésie',
    'hyperglycémie',
    'hyperlipémies',
    'hypermétropes',
    'hypermétropie',
    'hypernerveuse',
    'hyperréalisme',
    'hypersensible',
    'hypersoniques',
    'hyperstatique',
    'hypertensions',
    'hyperthermies',
    'hypertrophiée',
    'hypertrophier',
    'hypertrophies',
    'hypertrophiés',
    'hypertrophiez',
    'hypnotisaient',
    'hypnotisasses',
    'hypnotiserais',
    'hypnotiserait',
    'hypnotisèrent',
    'hypnotiseriez',
    'hypnotiserons',
    'hypnotiseront',
    'hypnotiseuses',
    'hypocalorique',
    'hypochlorites',
    'hypocritement',
    'hypodermiques',
    'hypogastrique',
    'hypoglycémies',
    'hypophysaires',
    'hyposécrétion',
    'hypostasiâmes',
    'hypostasiasse',
    'hypostasiâtes',
    'hypostasierai',
    'hypostasieras',
    'hypostasierez',
    'hypostasiions',
    'hypostatiques',
    'hyposulfureux',
    'hypothécables',
    'hypothécaires',
    'hypothéquâmes',
    'hypothéquasse',
    'hypothéquâtes',
    'hypothéquerai',
    'hypothéqueras',
    'hypothéquerez',
    'hypothéquions',
    'hypothétiques',
    'hypothyroïdie',
    'hystérectomie',
    'ichtyologique',
    'ichtyologiste',
    'iconoclasties',
    'iconographies',
    'idéalisassent',
    'idéalisassiez',
    'idéalisateurs',
    'idéalisations',
    'idéalisatrice',
    'idéaliserions',
    'identifiables',
    'identifiaient',
    'identifiasses',
    'identifierais',
    'identifierait',
    'identifièrent',
    'identifieriez',
    'identifierons',
    'identifieront',
    'identiquement',
    'idéographique',
    'idéologisâmes',
    'idéologisasse',
    'idéologisâtes',
    'idéologiserai',
    'idéologiseras',
    'idéologiserez',
    'idéologisions',
    'idiopathiques',
    'idiosyncrasie',
    'idiotifiaient',
    'idiotifiasses',
    'idiotifierais',
    'idiotifierait',
    'idiotifièrent',
    'idiotifieriez',
    'idiotifierons',
    'idiotifieront',
    'idiotisassent',
    'idiotisassiez',
    'idiotiserions',
    'idolâtrassent',
    'idolâtrassiez',
    'idolâtrerions',
    'ignifugations',
    'ignifugeaient',
    'ignifugeasses',
    'ignifugerions',
    'ignipunctures',
    'ignominieuses',
    'ilangs-ilangs',
    'iléo-caecales',
    'illégitimités',
    'illisibilités',
    'illisiblement',
    'illogiquement',
    'illuminassent',
    'illuminassiez',
    'illuminations',
    'illuminerions',
    'illusionnâmes',
    'illusionnasse',
    'illusionnâtes',
    'illusionnerai',
    'illusionneras',
    'illusionnerez',
    'illusionnions',
    'illusionnisme',
    'illusionniste',
    'illusoirement',
    'illustrassent',
    'illustrassiez',
    'illustrateurs',
    'illustrations',
    'illustratrice',
    'illustrerions',
    'imaginassions',
    'imagineraient',
    'imbriquassent',
    'imbriquassiez',
    'imbriquerions',
    'immanentismes',
    'immarcescible',
    'immatérialisa',
    'immatérialise',
    'immatérialisé',
    'immatérialité',
    'immatérielles',
    'immatriculais',
    'immatriculait',
    'immatriculant',
    'immatriculées',
    'immatriculent',
    'immatriculera',
    'immatriculiez',
    'immatriculons',
    'immédiatement',
    'immensurables',
    'immergeassent',
    'immergeassiez',
    'immergeraient',
    'immigrassions',
    'immigreraient',
    'immisçassions',
    'immobilisâmes',
    'immobilisasse',
    'immobilisâtes',
    'immobiliserai',
    'immobiliseras',
    'immobiliserez',
    'immobilisions',
    'immortalisais',
    'immortalisait',
    'immortalisant',
    'immortalisées',
    'immortalisent',
    'immortalisera',
    'immortalisiez',
    'immortalisons',
    'immunisassent',
    'immunisassiez',
    'immunisations',
    'immuniserions',
    'immunochimies',
    'immutabilités',
    'impactassions',
    'impacteraient',
    'impaludations',
    'impardonnable',
    'imparidigités',
    'imparipennées',
    'impartageable',
    'impartialités',
    'impartiraient',
    'impartissions',
    'impassibilité',
    'impatientâmes',
    'impatientante',
    'impatientants',
    'impatientasse',
    'impatientâtes',
    'impatienterai',
    'impatienteras',
    'impatienterez',
    'impatientions',
    'impatronisais',
    'impatronisait',
    'impatronisant',
    'impatronisées',
    'impatronisent',
    'impatronisera',
    'impatronisiez',
    'impatronisons',
    'impeccabilité',
    'impécunieuses',
    'impécuniosité',
    'impénétrables',
    'imperceptible',
    'imperfectible',
    'imperfections',
    'imperfectives',
    'impérialement',
    'impérialismes',
    'impérialistes',
    'impérissables',
    'impersonnelle',
    'impertinences',
    'impertinentes',
    'imperturbable',
    'impétigineuse',
    'impétrassions',
    'impétreraient',
    'implacabilité',
    'implantassent',
    'implantassiez',
    'implantations',
    'implanterions',
    'implémentâmes',
    'implémentasse',
    'implémentâtes',
    'implémenterai',
    'implémenteras',
    'implémenterez',
    'implémentions',
    'implicitement',
    'impliquassent',
    'impliquassiez',
    'impliquerions',
    'implorassions',
    'imploreraient',
    'implosassions',
    'imploseraient',
    'impondérables',
    'impopularités',
    'importassions',
    'importatrices',
    'importeraient',
    'import-export',
    'importunaient',
    'importunasses',
    'importunerais',
    'importunerait',
    'importunèrent',
    'importuneriez',
    'importunerons',
    'importuneront',
    'impossibilité',
    'impraticables',
    'imprécatoires',
    'imprégnassent',
    'imprégnassiez',
    'imprégnations',
    'imprégnerions',
    'impréparation',
    'impressionnai',
    'impressionnas',
    'impressionnât',
    'impressionnée',
    'impressionner',
    'impressionnes',
    'impressionnés',
    'impressionnez',
    'imprévisibles',
    'imprévoyances',
    'imprévoyantes',
    'imprimassions',
    'imprimeraient',
    'improbabilité',
    'improductives',
    'imprononçable',
    'improuvassent',
    'improuvassiez',
    'improuverions',
    'improvisaient',
    'improvisasses',
    'improvisateur',
    'improvisation',
    'improviserais',
    'improviserait',
    'improvisèrent',
    'improviseriez',
    'improviserons',
    'improviseront',
    'impudiquement',
    'impulsassions',
    'impulseraient',
    'impulsivement',
    'imputrescible',
    'inacceptables',
    'inacceptation',
    'inaccessibles',
    'inaccordables',
    'inaccoutumées',
    'inachèvements',
    'inactivassent',
    'inactivassiez',
    'inactivations',
    'inactiverions',
    'inadaptations',
    'inadéquations',
    'inadmissibles',
    'inadvertances',
    'inaliénations',
    'inamovibilité',
    'inanalysables',
    'inapplicables',
    'inapplication',
    'inappréciable',
    'inapprivoisée',
    'inapprivoisés',
    'inappropriées',
    'inassimilable',
    'inattaquables',
    'inaugurassent',
    'inaugurassiez',
    'inaugurations',
    'inaugurerions',
    'inauthentique',
    'incalculables',
    'incandescence',
    'incandescente',
    'incandescents',
    'incantatoires',
    'incapacitante',
    'incapacitants',
    'incarcéraient',
    'incarcérasses',
    'incarcération',
    'incarcérerais',
    'incarcérerait',
    'incarcérèrent',
    'incarcéreriez',
    'incarcérerons',
    'incarcéreront',
    'incarnassions',
    'incarneraient',
    'incendiassent',
    'incendiassiez',
    'incendierions',
    'incessibilité',
    'inchauffables',
    'inchavirables',
    'incinérassent',
    'incinérassiez',
    'incinérateurs',
    'incinérations',
    'incinérerions',
    'inclinassions',
    'inclineraient',
    'inclusivement',
    'incoagulables',
    'incombassions',
    'incomberaient',
    'incombustible',
    'incomestibles',
    'incommodaient',
    'incommodantes',
    'incommodasses',
    'incommodément',
    'incommoderais',
    'incommoderait',
    'incommodèrent',
    'incommoderiez',
    'incommoderons',
    'incommoderont',
    'incomparables',
    'incompatibles',
    'incompétences',
    'incompétentes',
    'inconcevables',
    'inconciliable',
    'inconditionné',
    'inconfortable',
    'incongelables',
    'inconsciences',
    'inconscientes',
    'inconséquence',
    'inconséquente',
    'inconséquents',
    'inconsidérées',
    'inconsistance',
    'inconsistante',
    'inconsistants',
    'inconsolables',
    'inconsommable',
    'inconstatable',
    'incontestable',
    'incontinences',
    'incontinentes',
    'incontrôlable',
    'inconvenances',
    'inconvenantes',
    'inconvénients',
    'inconvertible',
    'incorporables',
    'incorporaient',
    'incorporasses',
    'incorporation',
    'incorporelles',
    'incorporerais',
    'incorporerait',
    'incorporèrent',
    'incorporeriez',
    'incorporerons',
    'incorporeront',
    'incorrections',
    'incorrigibles',
    'incorruptible',
    'incrémentâmes',
    'incrémentasse',
    'incrémentâtes',
    'incrémenterai',
    'incrémenteras',
    'incrémenterez',
    'incrémentions',
    'incriminables',
    'incriminaient',
    'incriminasses',
    'incrimination',
    'incriminerais',
    'incriminerait',
    'incriminèrent',
    'incrimineriez',
    'incriminerons',
    'incrimineront',
    'incrochetable',
    'incrustassent',
    'incrustassiez',
    'incrustations',
    'incrusterions',
    'inculpassions',
    'inculperaient',
    'inculquassent',
    'inculquassiez',
    'inculquerions',
    'incultivables',
    'incurablement',
    'incurvassions',
    'incurveraient',
    'indéchirables',
    'indéclinables',
    'indécollables',
    'indécrochable',
    'indécrottable',
    'indéfectibles',
    'indéfendables',
    'indéformables',
    'indéfrichable',
    'indéfrisables',
    'indélébilités',
    'indélicatesse',
    'indémaillable',
    'indemnisables',
    'indemnisaient',
    'indemnisasses',
    'indemnisation',
    'indemniserais',
    'indemniserait',
    'indemnisèrent',
    'indemniseriez',
    'indemniserons',
    'indemniseront',
    'indemnitaires',
    'indémontables',
    'indémontrable',
    'indénombrable',
    'indépassables',
    'indépendances',
    'indépendantes',
    'indéracinable',
    'indéréglables',
    'indéterminées',
    'indiciblement',
    'indifféraient',
    'indifférasses',
    'indifférences',
    'indifférencié',
    'indifférentes',
    'indifférerais',
    'indifférerait',
    'indifférèrent',
    'indifféreriez',
    'indifférerons',
    'indifféreront',
    'indignassions',
    'indigneraient',
    'indiquassions',
    'indiqueraient',
    'indirectement',
    'indiscernable',
    'indisciplinée',
    'indisciplines',
    'indisciplinés',
    'indiscrétions',
    'indiscutables',
    'indispensable',
    'indisponibles',
    'indisposaient',
    'indisposasses',
    'indisposerais',
    'indisposerait',
    'indisposèrent',
    'indisposeriez',
    'indisposerons',
    'indisposeront',
    'indisposition',
    'indissociable',
    'indissolubles',
    'individualisa',
    'individualise',
    'individualisé',
    'individualité',
    'individuation',
    'individuelles',
    'indochinoises',
    'indo-européen',
    'indonésiennes',
    'induisissions',
    'indulgenciais',
    'indulgenciait',
    'indulgenciant',
    'indulgenciées',
    'indulgencient',
    'indulgenciera',
    'indulgenciiez',
    'indulgencions',
    'industrialisa',
    'industrialise',
    'industrialisé',
    'industrielles',
    'industrieuses',
    'inébranlables',
    'inéchangeable',
    'ineffablement',
    'inefficacités',
    'inégalitaires',
    'inélasticités',
    'inéligibilité',
    'inemployables',
    'inescomptable',
    'inesthétiques',
    'inexactitudes',
    'inexécutables',
    'inexpériences',
    'inexpérimenté',
    'inexplicables',
    'inexploitable',
    'inexplorables',
    'inexplosibles',
    'inexpressives',
    'inexprimables',
    'inexpugnables',
    'inextensibles',
    'inextinguible',
    'inextirpables',
    'inextricables',
    'infalsifiable',
    'infantilisais',
    'infantilisait',
    'infantilisant',
    'infantilisées',
    'infantilisent',
    'infantilisera',
    'infantilisiez',
    'infantilismes',
    'infantilisons',
    'infatuassions',
    'infatueraient',
    'infectassions',
    'infecteraient',
    'inféodassions',
    'inféoderaient',
    'infériorisais',
    'infériorisait',
    'infériorisant',
    'infériorisées',
    'infériorisent',
    'infériorisera',
    'infériorisiez',
    'infériorisons',
    'infestassions',
    'infesteraient',
    'infibulations',
    'infiltrassent',
    'infiltrassiez',
    'infiltrations',
    'infiltrerions',
    'infinitésimal',
    'infirmassions',
    'infirmeraient',
    'inflammations',
    'inflammatoire',
    'infléchirions',
    'infléchissais',
    'infléchissait',
    'infléchissant',
    'infléchissent',
    'infléchissiez',
    'infléchissons',
    'inflexibilité',
    'infligeassent',
    'infligeassiez',
    'infligeraient',
    'inflorescence',
    'influençables',
    'influençaient',
    'influençasses',
    'influencerais',
    'influencerait',
    'influencèrent',
    'influenceriez',
    'influencerons',
    'influenceront',
    'informassions',
    'informaticien',
    'informatiques',
    'informatisais',
    'informatisait',
    'informatisant',
    'informatisées',
    'informatisent',
    'informatisera',
    'informatisiez',
    'informatisons',
    'informatrices',
    'informeraient',
    'infroissables',
    'infructueuses',
    'infundibulums',
    'ingéniassions',
    'ingouvernable',
    'inguérissable',
    'ingurgitaient',
    'ingurgitasses',
    'ingurgitation',
    'ingurgiterais',
    'ingurgiterait',
    'ingurgitèrent',
    'ingurgiteriez',
    'ingurgiterons',
    'ingurgiteront',
    'inhabituelles',
    'inharmonieuse',
    'inhospitalier',
    'inhumainement',
    'inimaginables',
    'ininflammable',
    'inintelligent',
    'inintéressant',
    'ininterrompue',
    'ininterrompus',
    'initialisâmes',
    'initialisasse',
    'initialisâtes',
    'initialiserai',
    'initialiseras',
    'initialiserez',
    'initialisions',
    'injectassions',
    'injecteraient',
    'injuriassions',
    'injurieraient',
    'injustifiable',
    'innervassions',
    'innerveraient',
    'innocentaient',
    'innocentasses',
    'innocenterais',
    'innocenterait',
    'innocentèrent',
    'innocenteriez',
    'innocenterons',
    'innocenteront',
    'inobservables',
    'inobservances',
    'inobservation',
    'inoccupations',
    'inoculassions',
    'inoculeraient',
    'inopportunité',
    'inqualifiable',
    'inquiétassent',
    'inquiétassiez',
    'inquiéterions',
    'inquisitorial',
    'inquisitrices',
    'inracontables',
    'insaisissable',
    'insalissables',
    'insalivations',
    'insatiabilité',
    'insatisfaites',
    'inscriptibles',
    'inscrivissent',
    'inscrivissiez',
    'insculpassent',
    'insculpassiez',
    'insculperions',
    'inséminassent',
    'inséminassiez',
    'inséminations',
    'inséminerions',
    'insensibilisa',
    'insensibilise',
    'insensibilisé',
    'insensibilité',
    'insignifiance',
    'insignifiante',
    'insignifiants',
    'insinuassions',
    'insinueraient',
    'insistassions',
    'insisteraient',
    'insolubilisai',
    'insolubilisas',
    'insolubilisât',
    'insolubilisée',
    'insolubiliser',
    'insolubilises',
    'insolubilisés',
    'insolubilisez',
    'insolvabilité',
    'insonorisâmes',
    'insonorisasse',
    'insonorisâtes',
    'insonoriserai',
    'insonoriseras',
    'insonoriserez',
    'insonorisions',
    'insoumissions',
    'insoupçonnées',
    'insoutenables',
    'inspectassent',
    'inspectassiez',
    'inspecterions',
    'inspirassions',
    'inspiratrices',
    'inspireraient',
    'installassent',
    'installassiez',
    'installateurs',
    'installations',
    'installatrice',
    'installerions',
    'instantanéité',
    'instaurassent',
    'instaurassiez',
    'instaurations',
    'instaurerions',
    'instigatrices',
    'instiguassent',
    'instiguassiez',
    'instiguerions',
    'instillassent',
    'instillassiez',
    'instillations',
    'instillerions',
    'instinctuelle',
    'instituassent',
    'instituassiez',
    'instituerions',
    'institutrices',
    'instructrices',
    'instruiraient',
    'instruisaient',
    'instruisirent',
    'instruisisses',
    'instrumentais',
    'instrumentait',
    'instrumentant',
    'instrumentées',
    'instrumentent',
    'instrumentera',
    'instrumentiez',
    'instrumentons',
    'insubmersible',
    'insubordonnée',
    'insubordonnés',
    'insuffisances',
    'insuffisantes',
    'insufflassent',
    'insufflassiez',
    'insufflateurs',
    'insufflations',
    'insufflerions',
    'insultassions',
    'insulteraient',
    'insupportable',
    'insurgeassiez',
    'insurmontable',
    'insurpassable',
    'insurrections',
    'intaillassent',
    'intaillassiez',
    'intaillerions',
    'intangibilité',
    'intarissables',
    'intégralement',
    'intégrassions',
    'intégreraient',
    'intellectuels',
    'intelligences',
    'intelligentes',
    'intelligibles',
    'intempérances',
    'intempérantes',
    'intempestives',
    'intemporalité',
    'intemporelles',
    'intensifiâmes',
    'intensifiasse',
    'intensifiâtes',
    'intensifierai',
    'intensifieras',
    'intensifierez',
    'intensifiions',
    'intensivement',
    'intentassions',
    'intenteraient',
    'intentionnées',
    'intentionnels',
    'interastrales',
    'interbancaire',
    'intercalaient',
    'intercalaires',
    'intercalasses',
    'intercalation',
    'intercalerais',
    'intercalerait',
    'intercalèrent',
    'intercaleriez',
    'intercalerons',
    'intercaleront',
    'intercédaient',
    'intercédasses',
    'intercéderais',
    'intercéderait',
    'intercédèrent',
    'intercéderiez',
    'intercéderons',
    'intercéderont',
    'interceptâmes',
    'interceptasse',
    'interceptâtes',
    'intercepterai',
    'intercepteras',
    'intercepterez',
    'intercepteurs',
    'interceptions',
    'intercesseurs',
    'intercessions',
    'interclassais',
    'interclassait',
    'interclassant',
    'interclassées',
    'interclassent',
    'interclassera',
    'interclassiez',
    'interclassons',
    'intercommunal',
    'intercostales',
    'intercotidale',
    'intercotidaux',
    'intercurrente',
    'intercurrents',
    'interdictions',
    'interdigitale',
    'interdigitaux',
    'interdiraient',
    'interdisaient',
    'interdissions',
    'intéressaient',
    'intéressantes',
    'intéressasses',
    'intéressement',
    'intéresserais',
    'intéresserait',
    'intéressèrent',
    'intéresseriez',
    'intéresserons',
    'intéresseront',
    'interféraient',
    'interférasses',
    'interférences',
    'interférerais',
    'interférerait',
    'interférèrent',
    'interféreriez',
    'interférerons',
    'interféreront',
    'interfoliages',
    'interfoliâmes',
    'interfoliasse',
    'interfoliâtes',
    'interfolierai',
    'interfolieras',
    'interfolierez',
    'interfoliions',
    'intériorisais',
    'intériorisait',
    'intériorisant',
    'intériorisées',
    'intériorisent',
    'intériorisera',
    'intériorisiez',
    'intériorisons',
    'interjections',
    'interjectives',
    'interjetaient',
    'interjetasses',
    'interjetèrent',
    'interjetterai',
    'interjetteras',
    'interjetterez',
    'interlignâmes',
    'interlignasse',
    'interlignâtes',
    'interlignerai',
    'interligneras',
    'interlignerez',
    'interlignions',
    'interlinéaire',
    'interlocuteur',
    'interloquâmes',
    'interloquasse',
    'interloquâtes',
    'interloquerai',
    'interloqueras',
    'interloquerez',
    'interloquions',
    'intermédiaire',
    'interminables',
    'intermittence',
    'intermittente',
    'intermittents',
    'internassions',
    'international',
    'interneraient',
    'intéroceptifs',
    'intéroceptive',
    'interoculaire',
    'interosseuses',
    'interpariétal',
    'interpellâmes',
    'interpellasse',
    'interpellâtes',
    'interpellerai',
    'interpelleras',
    'interpellerez',
    'interpellions',
    'interpénétrée',
    'interpénétrer',
    'interpénétrés',
    'interpénétrez',
    'interpolaient',
    'interpolasses',
    'interpolateur',
    'interpolation',
    'interpolerais',
    'interpolerait',
    'interpolèrent',
    'interpoleriez',
    'interpolerons',
    'interpoleront',
    'interposaient',
    'interposasses',
    'interposerais',
    'interposerait',
    'interposèrent',
    'interposeriez',
    'interposerons',
    'interposeront',
    'interposition',
    'interprétâmes',
    'interprétante',
    'interprétants',
    'interprétasse',
    'interprétâtes',
    'interprétatif',
    'interpréterai',
    'interpréteras',
    'interpréterez',
    'interpréteurs',
    'interprétions',
    'interrelation',
    'interrogateur',
    'interrogatifs',
    'interrogation',
    'interrogative',
    'interrogeâmes',
    'interrogeasse',
    'interrogeâtes',
    'interrogerais',
    'interrogerait',
    'interrogèrent',
    'interrogeriez',
    'interrogerons',
    'interrogeront',
    'interrompîmes',
    'interrompions',
    'interrompisse',
    'interrompîtes',
    'interromprais',
    'interromprait',
    'interrompriez',
    'interromprons',
    'interrompront',
    'interrupteurs',
    'interruptions',
    'interruptives',
    'interruptrice',
    'intersections',
    'intersessions',
    'intersexuelle',
    'intersidérale',
    'intersidéraux',
    'intersyndical',
    'intertropical',
    'interurbaines',
    'intervallaire',
    'intervenaient',
    'intervenantes',
    'interventions',
    'interversions',
    'intervertîmes',
    'intervertirai',
    'intervertiras',
    'intervertirez',
    'intervertisse',
    'intervertîtes',
    'interviendrai',
    'interviendras',
    'interviendrez',
    'interviennent',
    'interviewâmes',
    'interviewasse',
    'interviewâtes',
    'interviewerai',
    'intervieweras',
    'interviewerez',
    'intervieweurs',
    'intervieweuse',
    'interviewions',
    'intervinssent',
    'intervinssiez',
    'intimidassent',
    'intimidassiez',
    'intimidateurs',
    'intimidations',
    'intimidatrice',
    'intimiderions',
    'intitulassent',
    'intitulassiez',
    'intitulerions',
    'intoxications',
    'intoxiquaient',
    'intoxiquasses',
    'intoxiquerais',
    'intoxiquerait',
    'intoxiquèrent',
    'intoxiqueriez',
    'intoxiquerons',
    'intoxiqueront',
    'intracrâniens',
    'intradermique',
    'intraduisible',
    'intransigeant',
    'intransitives',
    'intraveineuse',
    'intrépidement',
    'intriguassent',
    'intriguassiez',
    'intriguerions',
    'intriquassent',
    'intriquassiez',
    'intriquerions',
    'introducteurs',
    'introductions',
    'introductives',
    'introductrice',
    'introduirions',
    'introduisîmes',
    'introduisions',
    'introduisisse',
    'introduisîtes',
    'introjections',
    'intromissions',
    'intronisaient',
    'intronisasses',
    'intronisation',
    'introniserais',
    'introniserait',
    'intronisèrent',
    'introniseriez',
    'introniserons',
    'introniseront',
    'introspectifs',
    'introspection',
    'introspective',
    'introversions',
    'intuitivement',
    'inutilisables',
    'invaginassent',
    'invaginassiez',
    'invaginations',
    'invaginerions',
    'invalidassent',
    'invalidassiez',
    'invalidations',
    'invaliderions',
    'invariabilité',
    'invectivaient',
    'invectivasses',
    'invectiverais',
    'invectiverait',
    'invectivèrent',
    'invectiveriez',
    'invectiverons',
    'invectiveront',
    'inventassions',
    'inventeraient',
    'inventoriages',
    'inventoriâmes',
    'inventoriasse',
    'inventoriâtes',
    'inventorierai',
    'inventorieras',
    'inventorierez',
    'inventoriions',
    'invérifiables',
    'inversassions',
    'inverseraient',
    'invertiraient',
    'invertissions',
    'investigateur',
    'investigation',
    'investiraient',
    'investisseurs',
    'investisseuse',
    'investissions',
    'invétérassiez',
    'invétérerions',
    'invincibilité',
    'inviolabilité',
    'invisibilités',
    'invisiblement',
    'involontaires',
    'invoquassions',
    'invoqueraient',
    'invulnérables',
    'ionosphérique',
    'irascibilités',
    'ironisassions',
    'ironiseraient',
    'irrachetables',
    'irradiassions',
    'irradieraient',
    'irrationalité',
    'irrationnelle',
    'irrattrapable',
    'irréalisables',
    'irrécouvrable',
    'irrécupérable',
    'irrédentistes',
    'irréductibles',
    'irréformables',
    'irréfragables',
    'irréfrénables',
    'irrégularités',
    'irréligieuses',
    'irrémédiables',
    'irrémissibles',
    'irremplaçable',
    'irrépressible',
    'irréprochable',
    'irrésistibles',
    'irrésolutions',
    'irrespectueux',
    'irrespirables',
    'irresponsable',
    'irréversibles',
    'irriguassions',
    'irrigueraient',
    'irritabilités',
    'islamisassent',
    'islamisassiez',
    'islamisations',
    'islamiserions',
    'isochronismes',
    'isodynamiques',
    'isomérisation',
    'italianisâmes',
    'italianisante',
    'italianisants',
    'italianisasse',
    'italianisâtes',
    'italianiserai',
    'italianiseras',
    'italianiserez',
    'italianisions',
    'ithyphallique',
    'jacassassions',
    'jacasseraient',
    'jaillissaient',
    'jaillissantes',
    'jaillissement',
    'jalonnassions',
    'jalonneraient',
    'jalousassions',
    'jalouseraient',
    'japonaiseries',
    'japonnassions',
    'japonneraient',
    'jardinassions',
    'jardineraient',
    'jaretteraient',
    'jargonaphasie',
    'jargonnassent',
    'jargonnassiez',
    'jargonnerions',
    'jarovisations',
    'jarretassions',
    'jaspinassions',
    'jaspineraient',
    'jaunissements',
    'javelleraient',
    'javellisaient',
    'javellisasses',
    'javellisation',
    'javelliserais',
    'javelliserait',
    'javellisèrent',
    'javelliseriez',
    'javelliserons',
    'javelliseront',
    'jobardassions',
    'jobarderaient',
    'jointoierions',
    'jointoyassent',
    'jointoyassiez',
    'jouaillassent',
    'jouaillassiez',
    'jouaillerions',
    'journellement',
    'jumelleraient',
    'jupitériennes',
    'juponnassions',
    'juponneraient',
    'juridiquement',
    'jurisconsulte',
    'jurisprudence',
    'juste-milieux',
    'justifiassent',
    'justifiassiez',
    'justificateur',
    'justificatifs',
    'justification',
    'justificative',
    'justifierions',
    'juxtalinéaire',
    'juxtaposables',
    'juxtaposaient',
    'juxtaposantes',
    'juxtaposasses',
    'juxtaposerais',
    'juxtaposerait',
    'juxtaposèrent',
    'juxtaposeriez',
    'juxtaposerons',
    'juxtaposeront',
    'juxtaposition',
    'kabbalistique',
    'kaléidoscopes',
    'kaolinisation',
    'kentrophylles',
    'kidnappassent',
    'kidnappassiez',
    'kidnapperions',
    'kilogrammètre',
    'kilométraient',
    'kilométrasses',
    'kilométrerais',
    'kilométrerait',
    'kilométrèrent',
    'kilométreriez',
    'kilométrerons',
    'kilométreront',
    'kilométriques',
    'kilowattheure',
    'kinesthésique',
    'klaxonnassent',
    'klaxonnassiez',
    'klaxonnerions',
    'kolkhoziennes',
    'kommandanturs',
    'kremlinologie',
    'labialisaient',
    'labialisasses',
    'labialisation',
    'labialiserais',
    'labialiserait',
    'labialisèrent',
    'labialiseriez',
    'labialiserons',
    'labialiseront',
    'labiodentales',
    'labourassions',
    'laboureraient',
    'labyrinthique',
    'labyrinthodon',
    'lacédémoniens',
    'laconiquement',
    'lactalbumines',
    'lactoflavines',
    'laïcisassions',
    'laïciseraient',
    'laisser-aller',
    'laitonnassent',
    'laitonnassiez',
    'laitonnerions',
    'lambinassions',
    'lambineraient',
    'lambrissaient',
    'lambrissasses',
    'lambrisserais',
    'lambrisserait',
    'lambrissèrent',
    'lambrisseriez',
    'lambrisserons',
    'lambrisseront',
    'lamelliformes',
    'lamentassions',
    'lamenteraient',
    'laminectomies',
    'lampadophores',
    'lance-flammes',
    'lance-pierres',
    'lancinassions',
    'lancineraient',
    'langoustières',
    'langueyassent',
    'langueyassiez',
    'langueyerions',
    'languissaient',
    'languissantes',
    'lanternassent',
    'lanternassiez',
    'lanternerions',
    'lardonnassent',
    'lardonnassiez',
    'lardonnerions',
    'larmoieraient',
    'larmoyassions',
    'laryngectomie',
    'laryngologies',
    'laryngologues',
    'laryngoscopes',
    'laryngoscopie',
    'latifundiaire',
    'latinisassent',
    'latinisassiez',
    'latinisations',
    'latiniserions',
    'latitudinaire',
    'légalisassent',
    'légalisassiez',
    'légalisations',
    'légaliserions',
    'légiférassent',
    'légiférassiez',
    'légiférerions',
    'législatrices',
    'légitimassent',
    'légitimassiez',
    'légitimations',
    'légitimerions',
    'leishmanioses',
    'lénifiassions',
    'lénifieraient',
    'lenticulaires',
    'lépidodendron',
    'lépidosirènes',
    'léprologistes',
    'leptocéphales',
    'leptolithique',
    'leptospiroses',
    'lessivassions',
    'lessiveraient',
    'leucocytaires',
    'lévigeassions',
    'levrettassent',
    'levrettassiez',
    'levretterions',
    'lexicalisâmes',
    'lexicalisasse',
    'lexicalisâtes',
    'lexicaliserai',
    'lexicaliseras',
    'lexicaliserez',
    'lexicalisions',
    'lexicographes',
    'lexicographie',
    'lexicologique',
    'lézardassions',
    'lézarderaient',
    'liaisonnaient',
    'liaisonnasses',
    'liaisonnerais',
    'liaisonnerait',
    'liaisonnèrent',
    'liaisonneriez',
    'liaisonnerons',
    'liaisonneront',
    'libellassions',
    'libelleraient',
    'libéralisâmes',
    'libéralisante',
    'libéralisants',
    'libéralisasse',
    'libéralisâtes',
    'libéraliserai',
    'libéraliseras',
    'libéraliserez',
    'libéralisions',
    'libre-échange',
    'libre-penseur',
    'libre-service',
    'licenciassent',
    'licenciassiez',
    'licenciements',
    'licencierions',
    'ligamentaires',
    'ligamenteuses',
    'ligaturassent',
    'ligaturassiez',
    'ligaturerions',
    'lignifiassent',
    'lignifiassiez',
    'lignification',
    'lignifierions',
    'lilliputienne',
    'limandassions',
    'limanderaient',
    'limnicultures',
    'limogeassions',
    'limousinaient',
    'limousinasses',
    'limousinerais',
    'limousinerait',
    'limousinèrent',
    'limousineriez',
    'limousinerons',
    'limousineront',
    'linguistiques',
    'lipoprotéines',
    'liquéfacteurs',
    'liquéfactions',
    'liquéfiassent',
    'liquéfiassiez',
    'liquéfierions',
    'liquidassions',
    'liquidatrices',
    'liquideraient',
    'lithographiai',
    'lithographias',
    'lithographiât',
    'lithographiée',
    'lithographier',
    'lithographies',
    'lithographiés',
    'lithographiez',
    'lithologiques',
    'lithothamnium',
    'lithotriteurs',
    'litispendance',
    'littéralement',
    'littératrices',
    'localisassent',
    'localisassiez',
    'localisateurs',
    'localisations',
    'localisatrice',
    'localiserions',
    'lock-outaient',
    'lock-outasses',
    'lock-outerais',
    'lock-outerait',
    'lock-outèrent',
    'lock-outeriez',
    'lock-outerons',
    'lock-outeront',
    'locotracteurs',
    'logisticienne',
    'logomachiques',
    'loi-programme',
    'lombarthroses',
    'long-courrier',
    'longitudinale',
    'longitudinaux',
    'lotionnassent',
    'lotionnassiez',
    'lotionnerions',
    'louangeassent',
    'louangeassiez',
    'louangeraient',
    'louchissaient',
    'louvetassions',
    'louvetterions',
    'louvoieraient',
    'louvoyassions',
    'loxodromiques',
    'lubrifiassent',
    'lubrifiassiez',
    'lubrification',
    'lubrifierions',
    'lucifériennes',
    'lucrativement',
    'luminescences',
    'luminescentes',
    'lumineusement',
    'lusitaniennes',
    'lutéinisation',
    'luthéranismes',
    'lycanthropies',
    'lymphocytaire',
    'lymphocytoses',
    'lymphographie',
    'lyophilisâmes',
    'lyophilisante',
    'lyophilisants',
    'lyophilisasse',
    'lyophilisâtes',
    'lyophiliserai',
    'lyophiliseras',
    'lyophiliserez',
    'lyophilisions',
    'macadamisâmes',
    'macadamisasse',
    'macadamisâtes',
    'macadamiserai',
    'macadamiseras',
    'macadamiserez',
    'macadamisions',
    'macédoniennes',
    'machiavélique',
    'machiavélisme',
    'machicotaient',
    'machicotasses',
    'machicoterais',
    'machicoterait',
    'machicotèrent',
    'machicoteriez',
    'machicoterons',
    'machicoteront',
    'machinaissent',
    'machinaissiez',
    'machinalement',
    'machinassions',
    'machine-outil',
    'machineraient',
    'mâchonnassent',
    'mâchonnassiez',
    'mâchonnements',
    'mâchonnerions',
    'mâchouillâmes',
    'mâchouillante',
    'mâchouillants',
    'mâchouillasse',
    'mâchouillâtes',
    'mâchouillerai',
    'mâchouilleras',
    'mâchouillerez',
    'mâchouillions',
    'mâchurassions',
    'mâchureraient',
    'maçonnassions',
    'maçonneraient',
    'macrobiotique',
    'macrocéphales',
    'macrocéphalie',
    'macrocosmique',
    'macrodécision',
    'macrographies',
    'macroscélides',
    'macroscopique',
    'macrosporange',
    'madéfiassions',
    'madéfieraient',
    'madérisassent',
    'madérisassiez',
    'madériserions',
    'madréporiques',
    'madrigalisais',
    'madrigalisait',
    'madrigalisant',
    'madrigalisent',
    'madrigalisera',
    'madrigalisiez',
    'madrigalisons',
    'madrigalistes',
    'magasinassent',
    'magasinassiez',
    'magasinerions',
    'magdalénienne',
    'magistratures',
    'magnanarelles',
    'magnanimement',
    'magnétisaient',
    'magnétisantes',
    'magnétisasses',
    'magnétisation',
    'magnétiserais',
    'magnétiserait',
    'magnétisèrent',
    'magnétiseriez',
    'magnétiserons',
    'magnétiseront',
    'magnétiseuses',
    'magnétomètres',
    'magnétométrie',
    'magnétomoteur',
    'magnétophones',
    'magnétoscopai',
    'magnétoscopas',
    'magnétoscopât',
    'magnétoscopée',
    'magnétoscoper',
    'magnétoscopes',
    'magnétoscopés',
    'magnétoscopez',
    'magnétosphère',
    'magnifiassent',
    'magnifiassiez',
    'magnificences',
    'magnifierions',
    'magouillaient',
    'magouillantes',
    'magouillerais',
    'magouillerait',
    'magouilleriez',
    'magouillerons',
    'magouilleront',
    'magouilleuses',
    'maigrichonnes',
    'maigrissaient',
    'mainmortables',
    'maintiendrais',
    'maintiendrait',
    'maintiendriez',
    'maintiendrons',
    'maintiendront',
    'maintinssions',
    'maîtrisassent',
    'maîtrisassiez',
    'maîtriserions',
    'malchanceuses',
    'malencontreux',
    'malentendante',
    'malentendants',
    'malformations',
    'malgracieuses',
    'malhabilement',
    'malhonnêtetés',
    'malléabilisai',
    'malléabilisas',
    'malléabilisât',
    'malléabilisée',
    'malléabiliser',
    'malléabilises',
    'malléabilisés',
    'malléabilisez',
    'malléabilités',
    'malmenassions',
    'malmèneraient',
    'malnutritions',
    'malocclusions',
    'malproprement',
    'malthusiennes',
    'maltraitaient',
    'maltraitantes',
    'maltraitasses',
    'maltraiterais',
    'maltraiterait',
    'maltraitèrent',
    'maltraiteriez',
    'maltraiterons',
    'maltraiteront',
    'malveillances',
    'malveillantes',
    'malversations',
    'mamelonnaient',
    'mamelonnasses',
    'mamelonnerais',
    'mamelonnerait',
    'mamelonnèrent',
    'mamelonneriez',
    'mamelonnerons',
    'mamelonneront',
    'mammographies',
    'manageassions',
    'mancenilliers',
    'manchonnaient',
    'manchonnasses',
    'manchonnerais',
    'manchonnerait',
    'manchonnèrent',
    'manchonneriez',
    'manchonnerons',
    'manchonneront',
    'mandatassions',
    'mandateraient',
    'mandat-lettre',
    'mandibulaires',
    'mandolinistes',
    'manécanteries',
    'manégeassions',
    'mangeottaient',
    'mangeottasses',
    'mangeotterais',
    'mangeotterait',
    'mangeottèrent',
    'mangeotteriez',
    'mangeotterons',
    'mangeotteront',
    'manichordions',
    'maniérassions',
    'maniéreraient',
    'manifestaient',
    'manifestantes',
    'manifestasses',
    'manifestation',
    'manifestement',
    'manifesterais',
    'manifesterait',
    'manifestèrent',
    'manifesteriez',
    'manifesterons',
    'manifesteront',
    'manigançaient',
    'manigançasses',
    'manigancerais',
    'manigancerait',
    'manigancèrent',
    'maniganceriez',
    'manigancerons',
    'maniganceront',
    'manipulassent',
    'manipulassiez',
    'manipulateurs',
    'manipulations',
    'manipulatrice',
    'manipulerions',
    'mannequinâmes',
    'mannequinasse',
    'mannequinâtes',
    'mannequinerai',
    'mannequineras',
    'mannequinerez',
    'mannequinions',
    'manodétendeur',
    'manoeuvrables',
    'manoeuvraient',
    'manoeuvrasses',
    'manoeuvrerais',
    'manoeuvrerait',
    'manoeuvrèrent',
    'manoeuvreriez',
    'manoeuvrerons',
    'manoeuvreront',
    'manoeuvrières',
    'manométriques',
    'manoquassions',
    'manoqueraient',
    'mansardassent',
    'mansardassiez',
    'mansarderions',
    'manucurassent',
    'manucurassiez',
    'manucurerions',
    'manufacturais',
    'manufacturait',
    'manufacturant',
    'manufacturées',
    'manufacturent',
    'manufacturera',
    'manufacturier',
    'manufacturiez',
    'manufacturons',
    'manutentionna',
    'manutentionne',
    'manutentionné',
    'maquereautais',
    'maquereautait',
    'maquereautant',
    'maquereautées',
    'maquereautent',
    'maquereautera',
    'maquereautiez',
    'maquereautons',
    'maquignonnage',
    'maquignonnais',
    'maquignonnait',
    'maquignonnant',
    'maquignonnées',
    'maquignonnent',
    'maquignonnera',
    'maquignonniez',
    'maquignonnons',
    'maquillassent',
    'maquillassiez',
    'maquillerions',
    'marathonienne',
    'maraudassions',
    'marauderaient',
    'marchandaient',
    'marchandasses',
    'marchanderais',
    'marchanderait',
    'marchandèrent',
    'marchanderiez',
    'marchanderons',
    'marchanderont',
    'marcottassent',
    'marcottassiez',
    'marcotterions',
    'maréchaleries',
    'maréchaussées',
    'maremmatiques',
    'marginalement',
    'marginalisais',
    'marginalisait',
    'marginalisant',
    'marginalisées',
    'marginalisent',
    'marginalisera',
    'marginalisiez',
    'marginalismes',
    'marginalisons',
    'marginassions',
    'margineraient',
    'margotassions',
    'margoteraient',
    'margottassent',
    'margottassiez',
    'margotterions',
    'marivaudaient',
    'marivaudasses',
    'marivauderais',
    'marivauderait',
    'marivaudèrent',
    'marivauderiez',
    'marivauderons',
    'marivauderont',
    'marmitassions',
    'marmiteraient',
    'marmonnassent',
    'marmonnassiez',
    'marmonnements',
    'marmonnerions',
    'marmorisaient',
    'marmorisasses',
    'marmoriserais',
    'marmoriserait',
    'marmorisèrent',
    'marmoriseriez',
    'marmoriserons',
    'marmoriseront',
    'marmottassent',
    'marmottassiez',
    'marmottements',
    'marmotterions',
    'maronnassions',
    'maronneraient',
    'maroquinaient',
    'maroquinasses',
    'maroquinerais',
    'maroquinerait',
    'maroquinèrent',
    'maroquineries',
    'maroquineriez',
    'maroquinerons',
    'maroquineront',
    'maroquinières',
    'marouflassent',
    'marouflassiez',
    'marouflerions',
    'marquetassent',
    'marquetassiez',
    'marquetterais',
    'marquetterait',
    'marquetteriez',
    'marquetterons',
    'marquetteront',
    'marronnassent',
    'marronnassiez',
    'marronnerions',
    'marseillaises',
    'marsouinaient',
    'marsouinasses',
    'marsouinerais',
    'marsouinerait',
    'marsouinèrent',
    'marsouineriez',
    'marsouinerons',
    'marsouineront',
    'marteau-pilon',
    'martelassions',
    'martèleraient',
    'martellements',
    'martyrisaient',
    'martyrisasses',
    'martyriserais',
    'martyriserait',
    'martyrisèrent',
    'martyriseriez',
    'martyriserons',
    'martyriseront',
    'marxisassions',
    'marxiseraient',
    'masculinisais',
    'masculinisait',
    'masculinisant',
    'masculinisées',
    'masculinisent',
    'masculinisera',
    'masculinisiez',
    'masculinisons',
    'massacrassent',
    'massacrassiez',
    'massacrerions',
    'massicotaient',
    'massicotasses',
    'massicoterais',
    'massicoterait',
    'massicotèrent',
    'massicoteriez',
    'massicoterons',
    'massicoteront',
    'massifiassent',
    'massifiassiez',
    'massification',
    'massifierions',
    'masticatoires',
    'masticatrices',
    'mastiquassent',
    'mastiquassiez',
    'mastiquerions',
    'mastoïdiennes',
    'masturbassent',
    'masturbassiez',
    'masturbations',
    'masturberions',
    'matelassaient',
    'matelassasses',
    'matelasserais',
    'matelasserait',
    'matelassèrent',
    'matelasseriez',
    'matelasserons',
    'matelasseront',
    'matelassières',
    'matérialisais',
    'matérialisait',
    'matérialisant',
    'matérialisées',
    'matérialisent',
    'matérialisera',
    'matérialisiez',
    'matérialismes',
    'matérialisons',
    'matérialistes',
    'maternassions',
    'materneraient',
    'maternisaient',
    'maternisasses',
    'materniserais',
    'materniserait',
    'maternisèrent',
    'materniseriez',
    'materniserons',
    'materniseront',
    'mathématicien',
    'mathématiques',
    'mathématisais',
    'mathématisait',
    'mathématisant',
    'mathématisées',
    'mathématisent',
    'mathématisera',
    'mathématisiez',
    'mathématisons',
    'matraquassent',
    'matraquassiez',
    'matraquerions',
    'matriçassions',
    'matriceraient',
    'matriculaient',
    'matriculasses',
    'matriculerais',
    'matriculerait',
    'matriculèrent',
    'matriculeriez',
    'matriculerons',
    'matriculeront',
    'matrilinéaire',
    'matrimoniales',
    'maugréassions',
    'maugréeraient',
    'maximalisâmes',
    'maximalisasse',
    'maximalisâtes',
    'maximaliserai',
    'maximaliseras',
    'maximaliserez',
    'maximalisions',
    'maximisassent',
    'maximisassiez',
    'maximisations',
    'maximiserions',
    'mazoutassions',
    'mazouteraient',
    'mécaniciennes',
    'mécaniquement',
    'mécanisassent',
    'mécanisassiez',
    'mécanisations',
    'mécaniserions',
    'mécanographes',
    'mécanographie',
    'mécomptassent',
    'mécomptassiez',
    'mécompterions',
    'méconnaissais',
    'méconnaissait',
    'méconnaissant',
    'méconnaissent',
    'méconnaissiez',
    'méconnaissons',
    'méconnaîtrais',
    'méconnaîtrait',
    'méconnaîtriez',
    'méconnaîtrons',
    'méconnaîtront',
    'méconnussions',
    'mécontentâmes',
    'mécontentasse',
    'mécontentâtes',
    'mécontenterai',
    'mécontenteras',
    'mécontenterez',
    'mécontentions',
    'médaillassent',
    'médaillassiez',
    'médaillerions',
    'médecine-ball',
    'médiatisaient',
    'médiatisantes',
    'médiatisasses',
    'médiatisation',
    'médiatiserais',
    'médiatiserait',
    'médiatisèrent',
    'médiatiseriez',
    'médiatiserons',
    'médiatiseront',
    'médicalisâmes',
    'médicalisante',
    'médicalisants',
    'médicalisasse',
    'médicalisâtes',
    'médicaliserai',
    'médicaliseras',
    'médicaliserez',
    'médicalisions',
    'médicamentais',
    'médicamentait',
    'médicamentant',
    'médicamentées',
    'médicamentent',
    'médicamentera',
    'médicamenteux',
    'médicamentiez',
    'médicamentons',
    'medicine-ball',
    'médico-légale',
    'médico-légaux',
    'médico-social',
    'méditerranéen',
    'méditerranées',
    'mégalithiques',
    'mégissassions',
    'mégisseraient',
    'méjugeassions',
    'mélancoliques',
    'mélangeassent',
    'mélangeassiez',
    'mélangeraient',
    'mélanodermies',
    'mellification',
    'mémorialistes',
    'mémorisassent',
    'mémorisassiez',
    'mémorisations',
    'mémoriserions',
    'ménageassions',
    'mendigotaient',
    'mendigotasses',
    'mendigoterais',
    'mendigoterait',
    'mendigotèrent',
    'mendigoteriez',
    'mendigoterons',
    'mendigoteront',
    'méningitiques',
    'méningocoques',
    'ménopausiques',
    'menstruations',
    'mensualisâmes',
    'mensualisasse',
    'mensualisâtes',
    'mensualiserai',
    'mensualiseras',
    'mensualiserez',
    'mensualisions',
    'mensuellement',
    'mensurassions',
    'mensureraient',
    'mentionnaient',
    'mentionnasses',
    'mentionnerais',
    'mentionnerait',
    'mentionnèrent',
    'mentionneriez',
    'mentionnerons',
    'mentionneront',
    'menuisassions',
    'menuiseraient',
    'méprendraient',
    'méprisassions',
    'mépriseraient',
    'mercantilisai',
    'mercantilisas',
    'mercantilisât',
    'mercantilisée',
    'mercantiliser',
    'mercantilises',
    'mercantilisés',
    'mercantilisez',
    'mercantilisme',
    'mercantiliste',
    'mercaticienne',
    'mercerisaient',
    'mercerisasses',
    'merceriserais',
    'merceriserait',
    'mercerisèrent',
    'merceriseriez',
    'merceriserons',
    'merceriseront',
    'merchandising',
    'mercurochrome',
    'merdoieraient',
    'merdoyassions',
    'meringuassent',
    'meringuassiez',
    'meringuerions',
    'méritocraties',
    'mérovingienne',
    'merveilleuses',
    'mésalliassent',
    'mésalliassiez',
    'mésallierions',
    'mésencéphales',
    'mésentériques',
    'mésestimaient',
    'mésestimasses',
    'mésestimation',
    'mésestimerais',
    'mésestimerait',
    'mésestimèrent',
    'mésestimeriez',
    'mésestimerons',
    'mésestimeront',
    'mésolithiques',
    'métallisaient',
    'métallisantes',
    'métallisasses',
    'métallisation',
    'métalliserais',
    'métalliserait',
    'métallisèrent',
    'métalliseriez',
    'métalliserons',
    'métalliseront',
    'métallurgique',
    'métallurgiste',
    'métamorphique',
    'métamorphisai',
    'métamorphisas',
    'métamorphisât',
    'métamorphisée',
    'métamorphiser',
    'métamorphises',
    'métamorphisés',
    'métamorphisez',
    'métamorphisme',
    'métamorphosai',
    'métamorphosas',
    'métamorphosât',
    'métamorphosée',
    'métamorphoser',
    'métamorphoses',
    'métamorphosés',
    'métamorphosez',
    'métaphoriques',
    'métaphysicien',
    'métaphysiques',
    'métapsychique',
    'métatarsienne',
    'métempsychose',
    'métempsycoses',
    'métencéphales',
    'météorisaient',
    'météorisasses',
    'météoriserais',
    'météoriserait',
    'météorisèrent',
    'météoriseriez',
    'météoriserons',
    'météoriseront',
    'météorologies',
    'météorologues',
    'méthacrylique',
    'méthodologies',
    'méticulosités',
    'métissassions',
    'métisseraient',
    'métrologiques',
    'métropolitain',
    'meurtriraient',
    'meurtrissante',
    'meurtrissants',
    'meurtrissions',
    'meurtrissures',
    'mévendissions',
    'mezzo-soprano',
    'microbalances',
    'microbiologie',
    'microcéphales',
    'microcircuits',
    'microcosmique',
    'microcristaux',
    'microéconomie',
    'microfilmâmes',
    'microfilmasse',
    'microfilmâtes',
    'microfilmerai',
    'microfilmeras',
    'microfilmerez',
    'microfilmions',
    'micrographies',
    'microlithique',
    'micrométrique',
    'microphonique',
    'microscopique',
    'microsecondes',
    'mielleusement',
    'mignardassent',
    'mignardassiez',
    'mignarderions',
    'mignotassions',
    'mignoteraient',
    'militairement',
    'militantismes',
    'militarisâmes',
    'militarisasse',
    'militarisâtes',
    'militariserai',
    'militariseras',
    'militariserez',
    'militarisions',
    'mille-feuille',
    'millefeuilles',
    'millénarismes',
    'mille-pertuis',
    'millerandages',
    'millésimaient',
    'millésimasses',
    'millésimerais',
    'millésimerait',
    'millésimèrent',
    'millésimeriez',
    'millésimerons',
    'millésimeront',
    'milliardaires',
    'milliardièmes',
    'millimétrique',
    'millionnaires',
    'millithermies',
    'minaudassions',
    'minauderaient',
    'minéralisâmes',
    'minéralisante',
    'minéralisants',
    'minéralisasse',
    'minéralisâtes',
    'minéraliserai',
    'minéraliseras',
    'minéraliserez',
    'minéralisions',
    'minéralogique',
    'minéralogiste',
    'miniaturaient',
    'miniaturasses',
    'miniaturerais',
    'miniaturerait',
    'miniaturèrent',
    'miniatureriez',
    'miniaturerons',
    'miniatureront',
    'miniaturisais',
    'miniaturisait',
    'miniaturisant',
    'miniaturisées',
    'miniaturisent',
    'miniaturisera',
    'miniaturisiez',
    'miniaturisons',
    'miniaturistes',
    'Mini-cassette',
    'minicassettes',
    'minimisassent',
    'minimisassiez',
    'minimisations',
    'minimiserions',
    'ministérielle',
    'miroitassions',
    'miroiteraient',
    'misanthropies',
    'misérabilisme',
    'misérabiliste',
    'misérablement',
    'missionnaires',
    'mithriacismes',
    'mithridatâmes',
    'mithridatasse',
    'mithridatâtes',
    'mithridaterai',
    'mithridateras',
    'mithridaterez',
    'mithridations',
    'mithridatisai',
    'mithridatisas',
    'mithridatisât',
    'mithridatisée',
    'mithridatiser',
    'mithridatises',
    'mithridatisés',
    'mithridatisez',
    'mithridatisme',
    'mitigeassions',
    'mitochondries',
    'mitonnassions',
    'mitonneraient',
    'mitraillaient',
    'mitraillantes',
    'mitraillasses',
    'mitraillerais',
    'mitraillerait',
    'mitraillèrent',
    'mitrailleriez',
    'mitraillerons',
    'mitrailleront',
    'mitraillettes',
    'mitrailleuses',
    'mixtionnaient',
    'mixtionnasses',
    'mixtionnerais',
    'mixtionnerait',
    'mixtionnèrent',
    'mixtionneriez',
    'mixtionnerons',
    'mixtionneront',
    'mnémotechnies',
    'mobilisassent',
    'mobilisassiez',
    'mobilisateurs',
    'mobilisations',
    'mobilisatrice',
    'mobiliserions',
    'modélisations',
    'modéliserions',
    'modérantismes',
    'modernisaient',
    'modernisasses',
    'modernisation',
    'moderniserais',
    'moderniserait',
    'modernisèrent',
    'moderniseriez',
    'moderniserons',
    'moderniseront',
    'modifiassions',
    'modificateurs',
    'modifications',
    'modificatives',
    'modificatrice',
    'modifieraient',
    'moelleusement',
    'moissonnaient',
    'moissonnasses',
    'moissonnerais',
    'moissonnerait',
    'moissonnèrent',
    'moissonneriez',
    'moissonnerons',
    'moissonneront',
    'moissonneuses',
    'molestassions',
    'molesteraient',
    'moletteraient',
    'mollardassent',
    'mollardassiez',
    'mollarderions',
    'molletonnâmes',
    'molletonnasse',
    'molletonnâtes',
    'molletonnerai',
    'molletonneras',
    'molletonnerez',
    'molletonneuse',
    'molletonnions',
    'momentanément',
    'momifiassions',
    'momifications',
    'momifieraient',
    'mondialisâmes',
    'mondialisasse',
    'mondialisâtes',
    'mondialiserai',
    'mondialiseras',
    'mondialiserez',
    'mondialisions',
    'monétisassent',
    'monétisassiez',
    'monétiserions',
    'monnaieraient',
    'monnayassions',
    'monnayeraient',
    'monoatomiques',
    'monocliniques',
    'monographique',
    'monolinguisme',
    'monolithiques',
    'monolithismes',
    'monologuaient',
    'monologuasses',
    'monologuerais',
    'monologuerait',
    'monologuèrent',
    'monologueriez',
    'monologuerons',
    'monologueront',
    'mononucléaire',
    'mononucléoses',
    'monophoniques',
    'monopolisâmes',
    'monopolisante',
    'monopolisants',
    'monopolisasse',
    'monopolisâtes',
    'monopoliserai',
    'monopoliseras',
    'monopoliserez',
    'monopolisions',
    'monstruosités',
    'mont-de-piété',
    'montgolfières',
    'monumentalité',
    'moquettassent',
    'moquettassiez',
    'moquetterions',
    'moralisassent',
    'moralisassiez',
    'moralisateurs',
    'moralisations',
    'moralisatrice',
    'moraliserions',
    'morcelassions',
    'morcellements',
    'morcellerions',
    'mordançassent',
    'mordançassiez',
    'mordancerions',
    'mordillassent',
    'mordillassiez',
    'mordillements',
    'mordillerions',
    'mordorassions',
    'mordoreraient',
    'morfilassions',
    'morfileraient',
    'morfondissent',
    'morfondissiez',
    'morfondraient',
    'morganatiques',
    'morigénassent',
    'morigénassiez',
    'morigénerions',
    'morphinomanes',
    'morphinomanie',
    'morphologique',
    'mortaisassent',
    'mortaisassiez',
    'mortaiserions',
    'mort-aux-rats',
    'mortifiassent',
    'mortifiassiez',
    'mortification',
    'mortifierions',
    'mortinatalité',
    'motionnassent',
    'motionnassiez',
    'motionnerions',
    'motocyclettes',
    'motocyclismes',
    'motocyclistes',
    'motonautiques',
    'motonautismes',
    'motoneigistes',
    'motorisassent',
    'motorisassiez',
    'motorisations',
    'motoriserions',
    'mototracteurs',
    'moucharabiehs',
    'mouchardaient',
    'mouchardasses',
    'moucharderais',
    'moucharderait',
    'mouchardèrent',
    'moucharderiez',
    'moucharderons',
    'moucharderont',
    'moucheronnais',
    'moucheronnait',
    'moucheronnant',
    'moucheronnent',
    'moucheronnera',
    'moucheronniez',
    'moucheronnons',
    'mouchetassent',
    'mouchetassiez',
    'mouchetterais',
    'mouchetterait',
    'mouchetteriez',
    'mouchetterons',
    'mouchetteront',
    'mouettassions',
    'mouetteraient',
    'mouillassions',
    'mouilleraient',
    'moulinassions',
    'moulineraient',
    'moulurassions',
    'moulureraient',
    'mouronnassent',
    'mouronnassiez',
    'mouronnerions',
    'mousquetaires',
    'mousqueteries',
    'moustériennes',
    'moustiquaires',
    'moutonnassent',
    'moutonnassiez',
    'moutonnements',
    'moutonnerions',
    'mouvementâmes',
    'mouvementasse',
    'mouvementâtes',
    'mouvementerai',
    'mouvementeras',
    'mouvementerez',
    'mouvementions',
    'moyennassions',
    'moyenneraient',
    'mucilagineuse',
    'muguetassions',
    'muguetterions',
    'multifilaires',
    'multilatérale',
    'multilatéraux',
    'multinational',
    'multiplexâmes',
    'multiplexasse',
    'multiplexâtes',
    'multiplexerai',
    'multiplexeras',
    'multiplexerez',
    'multiplexeurs',
    'multiplexions',
    'multipliables',
    'multipliaient',
    'multipliasses',
    'multiplicande',
    'multiplicités',
    'multiplierais',
    'multiplierait',
    'multiplièrent',
    'multiplieriez',
    'multiplierons',
    'multiplieront',
    'multiraciales',
    'multistandard',
    'municipalisai',
    'municipalisas',
    'municipalisât',
    'municipalisée',
    'municipaliser',
    'municipalises',
    'municipalisés',
    'municipalisez',
    'municipalités',
    'munitionnaire',
    'munitionnâmes',
    'munitionnasse',
    'munitionnâtes',
    'munitionnerai',
    'munitionneras',
    'munitionnerez',
    'munitionnions',
    'muraillassent',
    'muraillassiez',
    'muraillerions',
    'murmurassions',
    'murmureraient',
    'musardassions',
    'musarderaient',
    'muselleraient',
    'muséographies',
    'musicographes',
    'musicographie',
    'musiquassions',
    'musiqueraient',
    'mutationnisme',
    'mutationniste',
    'mycodermiques',
    'myélographies',
    'myrmécophiles',
    'mystifiassent',
    'mystifiassiez',
    'mystificateur',
    'mystification',
    'mystifierions',
    'mythologiques',
    'mytiliculteur',
    'mytiliculture',
    'mytilotoxines',
    'myxoedémateux',
    'nantissements',
    'napoléonienne',
    'narcothérapie',
    'narquoisement',
    'nasalisassent',
    'nasalisassiez',
    'nasalisations',
    'nasaliserions',
    'nasillassions',
    'nasilleraient',
    'nationalisais',
    'nationalisait',
    'nationalisant',
    'nationalisées',
    'nationalisent',
    'nationalisera',
    'nationalisiez',
    'nationalismes',
    'nationalisons',
    'nationalistes',
    'naturalisâmes',
    'naturalisasse',
    'naturalisâtes',
    'naturaliserai',
    'naturaliseras',
    'naturaliserez',
    'naturalisions',
    'naturellement',
    'naufrageaient',
    'naufrageasses',
    'naufragerions',
    'navalisations',
    'navigabilités',
    'naviguassions',
    'navigueraient',
    'néantisassent',
    'néantisassiez',
    'néantisations',
    'néantiserions',
    'nébulisations',
    'nécessitaient',
    'nécessitantes',
    'nécessitasses',
    'nécessiterais',
    'nécessiterait',
    'nécessitèrent',
    'nécessiteriez',
    'nécessiterons',
    'nécessiteront',
    'nécessiteuses',
    'nécrologiques',
    'nécromanciens',
    'nécrosassions',
    'nécroseraient',
    'néerlandaises',
    'négligeassent',
    'négligeassiez',
    'négligeraient',
    'négociabilité',
    'négociassions',
    'négociatrices',
    'négocieraient',
    'néguentropies',
    'néo-celtiques',
    'néoformations',
    'néo-gothiques',
    'néo-hébridais',
    'néo-kantismes',
    'néonatalogies',
    'néo-réalismes',
    'néo-réalistes',
    'néo-thomismes',
    'néo-zélandais',
    'néphrectomies',
    'néphrographie',
    'néphropathies',
    'nervurassions',
    'nervureraient',
    'nettoieraient',
    'nettoyassions',
    'neurasthénies',
    'neurobiologie',
    'neuroleptique',
    'neurologiques',
    'neurologistes',
    'neuroplégique',
    'neurosciences',
    'neutralisâmes',
    'neutralisante',
    'neutralisants',
    'neutralisasse',
    'neutralisâtes',
    'neutraliserai',
    'neutraliseras',
    'neutraliserez',
    'neutralisions',
    'neutrographie',
    'névrodermites',
    'nickelassions',
    'nickellerions',
    'nicotinisâmes',
    'nicotinisasse',
    'nicotinisâtes',
    'nicotiniserai',
    'nicotiniseras',
    'nicotiniserez',
    'nicotinisions',
    'nidifiassions',
    'nidifications',
    'nidifieraient',
    'nids-de-poule',
    'nietzschéenne',
    'niquedouilles',
    'nitratassions',
    'nitrateraient',
    'nitrifiassent',
    'nitrifiassiez',
    'nitrification',
    'nitrifierions',
    'nitrobenzènes',
    'nitrobenzines',
    'nitrotoluènes',
    'nitrurassions',
    'nitrureraient',
    'nivelleraient',
    'nivo-pluviale',
    'nivo-pluviaux',
    'noctambulisme',
    'noircissaient',
    'noircissantes',
    'noircissement',
    'nomadisassent',
    'nomadisassiez',
    'nomadiserions',
    'nomenclateurs',
    'nomenclatrice',
    'nomenclatures',
    'nominalisâmes',
    'nominalisasse',
    'nominalisâtes',
    'nominaliserai',
    'nominaliseras',
    'nominaliserez',
    'nominalisions',
    'non-agression',
    'nonchalamment',
    'non-comparant',
    'non-croyantes',
    'non-directifs',
    'non-directive',
    'non-euclidien',
    'non-exécution',
    'non-existence',
    'non-figuratif',
    'non-ingérence',
    'non-inscrites',
    'non-paiements',
    'non-résidente',
    'non-résidents',
    'non-révisable',
    'non-violences',
    'non-violentes',
    'noradrénaline',
    'nord-africain',
    'nord-coréenne',
    'normalisaient',
    'normalisantes',
    'normalisasses',
    'normalisation',
    'normaliserais',
    'normaliserait',
    'normalisèrent',
    'normaliseriez',
    'normaliserons',
    'normaliseront',
    'notifiassions',
    'notifications',
    'notifieraient',
    'nourrissaient',
    'nourrissantes',
    'noyautassions',
    'noyauteraient',
    'nue-propriété',
    'numériquement',
    'numérisassent',
    'numérisassiez',
    'numériserions',
    'numérotassent',
    'numérotassiez',
    'numérotations',
    'numéroterions',
    'numismatiques',
    'nummulitiques',
    'nutritionnels',
    'obédientielle',
    'objectassions',
    'objecteraient',
    'objectivaient',
    'objectivasses',
    'objectivation',
    'objectivement',
    'objectiverais',
    'objectiverait',
    'objectivèrent',
    'objectiveriez',
    'objectiverons',
    'objectiveront',
    'objectivismes',
    'objurguassent',
    'objurguassiez',
    'objurguerions',
    'obligeassions',
    'obliquassions',
    'obliqueraient',
    'oblitérassent',
    'oblitérassiez',
    'oblitérateurs',
    'oblitérations',
    'oblitératrice',
    'oblitérerions',
    'obnubilassent',
    'obnubilassiez',
    'obnubilations',
    'obnubilerions',
    'obombrassions',
    'obombreraient',
    'obscurantisme',
    'obscurantiste',
    'obscurcirions',
    'obscurcissais',
    'obscurcissait',
    'obscurcissant',
    'obscurcissent',
    'obscurcissiez',
    'obscurcissons',
    'obséquiosités',
    'observassions',
    'observatoires',
    'observatrices',
    'observeraient',
    'obsessionnels',
    'obsolescences',
    'obsolescentes',
    'obstétricales',
    'obstétriciens',
    'obstinassions',
    'obstruassions',
    'obstrueraient',
    'obtempéraient',
    'obtempérasses',
    'obtempérerais',
    'obtempérerait',
    'obtempérèrent',
    'obtempéreriez',
    'obtempérerons',
    'obtempéreront',
    'obtiendraient',
    'obviendraient',
    'occasionnâmes',
    'occasionnasse',
    'occasionnâtes',
    'occasionnelle',
    'occasionnerai',
    'occasionneras',
    'occasionnerez',
    'occasionnions',
    'occidentalisa',
    'occidentalise',
    'occidentalisé',
    'occultassions',
    'occulteraient',
    'océanographes',
    'océanographie',
    'océanologique',
    'octaviassions',
    'octavieraient',
    'octroieraient',
    'octroyassions',
    'octuplassions',
    'octupleraient',
    'odoriférantes',
    'oecuménicités',
    'oeil-de-boeuf',
    'oeilletonnage',
    'oeilletonnais',
    'oeilletonnait',
    'oeilletonnant',
    'oeilletonnées',
    'oeilletonnent',
    'oeilletonnera',
    'oeilletonniez',
    'oeilletonnons',
    'oeils-de-chat',
    'oenométriques',
    'oesophagienne',
    'offensassions',
    'offenseraient',
    'offensivement',
    'officialisais',
    'officialisait',
    'officialisant',
    'officialisées',
    'officialisent',
    'officialisera',
    'officialisiez',
    'officialisons',
    'officiassions',
    'officieraient',
    'offusquassent',
    'offusquassiez',
    'offusquerions',
    'oiseau-mouche',
    'oiseaux-lyres',
    'oiselleraient',
    'oléicultrices',
    'oligarchiques',
    'oligo-élément',
    'oligophrénies',
    'ombelliformes',
    'ombrageassent',
    'ombrageassiez',
    'ombrageraient',
    'omnipraticien',
    'omniprésences',
    'omniprésentes',
    'onctueusement',
    'oniromanciens',
    'onomasiologie',
    'onomatopéique',
    'onychophagies',
    'opacifiassent',
    'opacifiassiez',
    'opacification',
    'opacifierions',
    'opalisassions',
    'opaliseraient',
    'opéra-comique',
    'opérationnels',
    'ophiographies',
    'ophtalmologie',
    'ophtalmologue',
    'ophtalmomètre',
    'ophtalmoscope',
    'opiniâtrement',
    'opiniâtrèrent',
    'opiniâtreriez',
    'opiniâtrerons',
    'opportunément',
    'opportunismes',
    'opportunistes',
    'opposabilités',
    'oppositionnel',
    'oppressassent',
    'oppressassiez',
    'oppresserions',
    'opprimassions',
    'opprimeraient',
    'optimalisâmes',
    'optimalisasse',
    'optimalisâtes',
    'optimaliserai',
    'optimaliseras',
    'optimaliserez',
    'optimalisions',
    'optimisassent',
    'optimisassiez',
    'optimisations',
    'optimiserions',
    'orangeassions',
    'orangs-outans',
    'orchestraient',
    'orchestrasses',
    'orchestrateur',
    'orchestration',
    'orchestrerais',
    'orchestrerait',
    'orchestrèrent',
    'orchestreriez',
    'orchestrerons',
    'orchestreront',
    'ordinairement',
    'ordonnançâmes',
    'ordonnançasse',
    'ordonnançâtes',
    'ordonnancerai',
    'ordonnanceras',
    'ordonnancerez',
    'ordonnancions',
    'ordonnassions',
    'ordonnatrices',
    'ordonneraient',
    'organigrammes',
    'organiquement',
    'organisassent',
    'organisassiez',
    'organisateurs',
    'organisations',
    'organisatrice',
    'organiserions',
    'organsinaient',
    'organsinasses',
    'organsinèrent',
    'orgueilleuses',
    'orientalismes',
    'orientalistes',
    'orientassions',
    'orientatrices',
    'orienteraient',
    'originalement',
    'oringuassions',
    'oringueraient',
    'ornementaient',
    'ornementasses',
    'ornementation',
    'ornementerais',
    'ornementerait',
    'ornementèrent',
    'ornementeriez',
    'ornementerons',
    'ornementeront',
    'ornithologies',
    'ornithologues',
    'ornithomancie',
    'ornithorynque',
    'orographiques',
    'orthographiai',
    'orthographias',
    'orthographiât',
    'orthographiée',
    'orthographier',
    'orthographies',
    'orthographiés',
    'orthographiez',
    'orthopédiques',
    'orthopédistes',
    'orthophoniste',
    'orthoscopique',
    'orthostatique',
    'oscillassions',
    'oscillatoires',
    'oscilleraient',
    'oscillogramme',
    'oscillographe',
    'oscillomètres',
    'osiériculture',
    'ossifiassions',
    'ossifications',
    'ossifieraient',
    'ostentatoires',
    'ostéomalacies',
    'ostéomyélites',
    'ostéoplasties',
    'ostéosarcomes',
    'ostéosynthèse',
    'ostracisaient',
    'ostracisasses',
    'ostraciserais',
    'ostraciserait',
    'ostracisèrent',
    'ostraciseriez',
    'ostraciserons',
    'ostraciseront',
    'ostréiculteur',
    'ostréiculture',
    'ouatinassions',
    'ouatineraient',
    'outillassions',
    'outilleraient',
    'outrageassent',
    'outrageassiez',
    'outrageraient',
    'outrecuidance',
    'outrecuidante',
    'outrecuidants',
    'outrepassâmes',
    'outrepassasse',
    'outrepassâtes',
    'outrepasserai',
    'outrepasseras',
    'outrepasserez',
    'outrepassions',
    'ouvrageassent',
    'ouvrageassiez',
    'ouvrageraient',
    'ouvre-huîtres',
    'ovalisassions',
    'ovaliseraient',
    'ovariectomies',
    'ovationnaient',
    'ovationnasses',
    'ovationnerais',
    'ovationnerait',
    'ovationnèrent',
    'ovationneriez',
    'ovationnerons',
    'ovationneront',
    'ovoviviparité',
    'oxygénassions',
    'oxygéneraient',
    'ozonisassions',
    'ozoniseraient',
    'pacageassions',
    'pacifiassions',
    'pacificateurs',
    'pacifications',
    'pacificatrice',
    'pacifieraient',
    'pacifiquement',
    'pactisassions',
    'pactiseraient',
    'paganisassent',
    'paganisassiez',
    'paganiserions',
    'pageotassions',
    'pageoteraient',
    'pagnotassions',
    'pagnoteraient',
    'paillardaient',
    'paillardasses',
    'paillarderais',
    'paillarderait',
    'paillardèrent',
    'paillarderiez',
    'paillarderons',
    'paillarderont',
    'paillassonnai',
    'paillassonnas',
    'paillassonnât',
    'paillassonnée',
    'paillassonner',
    'paillassonnes',
    'paillassonnés',
    'paillassonnez',
    'pailletassent',
    'pailletassiez',
    'pailletterais',
    'pailletterait',
    'pailletteriez',
    'pailletterons',
    'pailletteront',
    'paillonnaient',
    'paillonnasses',
    'paillonnerais',
    'paillonnerait',
    'paillonnèrent',
    'paillonneriez',
    'paillonnerons',
    'paillonneront',
    'paisselassent',
    'paisselassiez',
    'paissellerais',
    'paissellerait',
    'paisselleriez',
    'paissellerons',
    'paisselleront',
    'pakistanaises',
    'palabrassions',
    'palabrerasses',
    'palabrerèrent',
    'palancrassent',
    'palancrassiez',
    'palancrerions',
    'palangrassent',
    'palangrassiez',
    'palangrerions',
    'palanguassent',
    'palanguassiez',
    'palanguerions',
    'palanquassent',
    'palanquassiez',
    'palanquerions',
    'palefrenières',
    'paléochrétien',
    'paléographies',
    'paléolithique',
    'paléontologie',
    'paléontologue',
    'paléothériums',
    'palestinienne',
    'palettassions',
    'paletteraient',
    'palettisaient',
    'palettisasses',
    'palettiserais',
    'palettiserait',
    'palettisèrent',
    'palettiseriez',
    'palettiserons',
    'palettiseront',
    'palingénésies',
    'palissadaient',
    'palissadasses',
    'palissaderais',
    'palissaderait',
    'palissadèrent',
    'palissaderiez',
    'palissaderons',
    'palissaderont',
    'palissassions',
    'palisseraient',
    'palissonnâmes',
    'palissonnasse',
    'palissonnâtes',
    'palissonnerai',
    'palissonneras',
    'palissonnerez',
    'palissonneurs',
    'palissonnions',
    'pallidectomie',
    'palma-christi',
    'palmipartites',
    'palpitassions',
    'palpiteraient',
    'pamphlétaires',
    'pamplemousses',
    'panachassions',
    'panacheraient',
    'panafricaines',
    'panaméricaine',
    'panaméricains',
    'pancréatiques',
    'pandiculation',
    'pangermanisme',
    'pangermaniste',
    'panhellénique',
    'panhellénisme',
    'paniers-repas',
    'panifiassions',
    'panifications',
    'panifieraient',
    'paniquassions',
    'paniqueraient',
    'panislamiques',
    'panislamismes',
    'panneautaient',
    'panneautasses',
    'panneauterais',
    'panneauterait',
    'panneautèrent',
    'panneauteriez',
    'panneauterons',
    'panneauteront',
    'panophtalmies',
    'panoramiquais',
    'panoramiquait',
    'panoramiquant',
    'panoramiquent',
    'panoramiquera',
    'panoramiquiez',
    'panoramiquons',
    'pantalonnades',
    'pantelassions',
    'pantellerions',
    'pantouflaient',
    'pantouflardes',
    'pantouflasses',
    'pantouflerais',
    'pantouflerait',
    'pantouflèrent',
    'pantoufleriez',
    'pantouflerons',
    'pantoufleront',
    'pantouflières',
    'paperasseries',
    'paperassières',
    'papilionacées',
    'papillonnages',
    'papillonnâmes',
    'papillonnante',
    'papillonnants',
    'papillonnasse',
    'papillonnâtes',
    'papillonnerai',
    'papillonneras',
    'papillonnerez',
    'papillonnions',
    'papillotaient',
    'papillotantes',
    'papillotasses',
    'papillotement',
    'papilloterais',
    'papilloterait',
    'papillotèrent',
    'papilloteriez',
    'papilloterons',
    'papilloteront',
    'papouillaient',
    'papouillasses',
    'papouillerais',
    'papouillerait',
    'papouillèrent',
    'papouilleriez',
    'papouillerons',
    'papouilleront',
    'parachevaient',
    'parachevasses',
    'parachèvement',
    'parachèverais',
    'parachèverait',
    'parachevèrent',
    'parachèveriez',
    'parachèverons',
    'parachèveront',
    'parachronisme',
    'parachutaient',
    'parachutasses',
    'parachuterais',
    'parachuterait',
    'parachutèrent',
    'parachuteriez',
    'parachuterons',
    'parachuteront',
    'parachutismes',
    'parachutistes',
    'paradisiaques',
    'paraffinaient',
    'paraffinasses',
    'paraffinerais',
    'paraffinerait',
    'paraffinèrent',
    'paraffineriez',
    'paraffinerons',
    'paraffineront',
    'parafiscalité',
    'paraisonnâmes',
    'paraisonnasse',
    'paraisonnâtes',
    'paraisonnerai',
    'paraisonneras',
    'paraisonnerez',
    'paraisonnions',
    'parallactique',
    'parallèlement',
    'parallélisais',
    'parallélisait',
    'parallélisant',
    'parallélisées',
    'parallélisent',
    'parallélisera',
    'parallélisiez',
    'parallélismes',
    'parallélisons',
    'paralysassent',
    'paralysassiez',
    'paralyserions',
    'paramédicales',
    'paramétrables',
    'paramétraient',
    'paramétrasses',
    'paramétrerais',
    'paramétrerait',
    'paramétrèrent',
    'paramétreriez',
    'paramétrerons',
    'paramétreront',
    'paramétriques',
    'paramilitaire',
    'parangonnâmes',
    'parangonnasse',
    'parangonnâtes',
    'parangonnerai',
    'parangonneras',
    'parangonnerez',
    'parangonnions',
    'paraphassions',
    'parapheraient',
    'paraphernales',
    'paraphrasâmes',
    'paraphrasasse',
    'paraphrasâtes',
    'paraphraserai',
    'paraphraseras',
    'paraphraserez',
    'paraphraseurs',
    'paraphraseuse',
    'paraphrasions',
    'paraplégiques',
    'parapsychique',
    'parascolaires',
    'parasexualité',
    'parasitassent',
    'parasitassiez',
    'parasiterions',
    'parathormones',
    'paratonnerres',
    'paratyphiques',
    'paratyphoïdes',
    'parcellarisai',
    'parcellarisas',
    'parcellarisât',
    'parcellarisée',
    'parcellariser',
    'parcellarises',
    'parcellarisés',
    'parcellarisez',
    'parcellassent',
    'parcellassiez',
    'parcellerions',
    'parcellisâmes',
    'parcellisasse',
    'parcellisâtes',
    'parcelliserai',
    'parcelliseras',
    'parcelliserez',
    'parcellisions',
    'parcheminâmes',
    'parcheminasse',
    'parcheminâtes',
    'parcheminerai',
    'parchemineras',
    'parcheminerez',
    'parcheminière',
    'parcheminiers',
    'parcheminions',
    'parcimonieuse',
    'parcourraient',
    'parcourussent',
    'parcourussiez',
    'pardonnassent',
    'pardonnassiez',
    'pardonnerions',
    'parementaient',
    'parementasses',
    'parementerais',
    'parementerait',
    'parementèrent',
    'parementeriez',
    'parementerons',
    'parementeront',
    'parémiologies',
    'paressassions',
    'paresseraient',
    'parfilassions',
    'parfileraient',
    'parfondissent',
    'parfondissiez',
    'parfondraient',
    'parfumassions',
    'parfumeraient',
    'paridigitidée',
    'paridigitidés',
    'parisianismes',
    'parjurassions',
    'parjureraient',
    'parkérisaient',
    'parkérisasses',
    'parkérisation',
    'parkériserais',
    'parkériserait',
    'parkérisèrent',
    'parkériseriez',
    'parkériserons',
    'parkériseront',
    'parkinsoniens',
    'parlementaire',
    'parlementâmes',
    'parlementasse',
    'parlementâtes',
    'parlementerai',
    'parlementeras',
    'parlementerez',
    'parlementions',
    'parlotassions',
    'parloteraient',
    'parnassiennes',
    'parodiassions',
    'parodieraient',
    'paroissiennes',
    'parotidiennes',
    'paroxysmiques',
    'paroxystiques',
    'parquetassent',
    'parquetassiez',
    'parquetterais',
    'parquetterait',
    'parquetteriez',
    'parquetterons',
    'parquetteront',
    'parrainassent',
    'parrainassiez',
    'parrainerions',
    'parsemassions',
    'parsèmeraient',
    'partageassent',
    'partageassiez',
    'partageraient',
    'participaient',
    'participantes',
    'participasses',
    'participatifs',
    'participation',
    'participative',
    'participerais',
    'participerait',
    'participèrent',
    'participeriez',
    'participerons',
    'participeront',
    'participiales',
    'particularisa',
    'particularise',
    'particularisé',
    'particularité',
    'particulières',
    'partiellement',
    'partitionnais',
    'partitionnait',
    'partitionnant',
    'partitionnées',
    'partitionnent',
    'partitionnera',
    'partitionniez',
    'partitionnons',
    'parviendrions',
    'passagèrement',
    'passementâmes',
    'passementasse',
    'passementâtes',
    'passementerai',
    'passementeras',
    'passementerez',
    'passementerie',
    'passementière',
    'passementiers',
    'passementions',
    'passe-partout',
    'passepoilâmes',
    'passepoilasse',
    'passepoilâtes',
    'passepoilerai',
    'passepoileras',
    'passepoilerez',
    'passepoilions',
    'passerinettes',
    'passe-velours',
    'passe-volants',
    'passing-shots',
    'passionnaient',
    'passionnantes',
    'passionnasses',
    'passionnelles',
    'passionnément',
    'passionnerais',
    'passionnerait',
    'passionnèrent',
    'passionneriez',
    'passionnerons',
    'passionneront',
    'passionnettes',
    'pastellassent',
    'pastellassiez',
    'pastellerions',
    'pasteuriennes',
    'pasteurisâmes',
    'pasteurisasse',
    'pasteurisâtes',
    'pasteuriserai',
    'pasteuriseras',
    'pasteuriserez',
    'pasteurisions',
    'pastichassent',
    'pastichassiez',
    'pasticherions',
    'pastillassent',
    'pastillassiez',
    'pastillerions',
    'patafiolaient',
    'patafiolasses',
    'patafiolerais',
    'patafiolerait',
    'patafiolèrent',
    'patafioleriez',
    'patafiolerons',
    'patafioleront',
    'pataphysiques',
    'pataugeairent',
    'pataugeaisses',
    'pataugeassent',
    'pataugeassiez',
    'pataugeraient',
    'patelinassent',
    'patelinassiez',
    'patelinerions',
    'patentassions',
    'patenteraient',
    'paternalismes',
    'paternalistes',
    'pathologiques',
    'pathologistes',
    'patientassent',
    'patientassiez',
    'patienterions',
    'pâtissassions',
    'pâtisseraient',
    'patoisassions',
    'patoiseraient',
    'patouillaient',
    'patouillasses',
    'patouillerais',
    'patouillerait',
    'patouillèrent',
    'patouilleriez',
    'patouillerons',
    'patouilleront',
    'patrilinéaire',
    'patrimoniales',
    'patronnassent',
    'patronnassiez',
    'patronnerions',
    'patronymiques',
    'patrouillâmes',
    'patrouillasse',
    'patrouillâtes',
    'patrouillerai',
    'patrouilleras',
    'patrouillerez',
    'patrouilleurs',
    'patrouillions',
    'pattemouilles',
    'pattinsonages',
    'paumoieraient',
    'paumoyassions',
    'paupérisaient',
    'paupérisasses',
    'paupérisation',
    'paupériserais',
    'paupériserait',
    'paupérisèrent',
    'paupériseriez',
    'paupériserons',
    'paupériseront',
    'pavillonnaire',
    'pavillonnerie',
    'pavimenteuses',
    'pavoisassions',
    'pavoiseraient',
    'peaufinassent',
    'peaufinassiez',
    'peaufinerions',
    'peaussassions',
    'peausseraient',
    'péclotassions',
    'pécloteraient',
    'pédérastiques',
    'pédicellaires',
    'pédonculaires',
    'peinturassent',
    'peinturassiez',
    'peinturerions',
    'peinturlurais',
    'peinturlurait',
    'peinturlurant',
    'peinturlurées',
    'peinturlurent',
    'peinturlurera',
    'peinturluriez',
    'peinturlurons',
    'pélagianismes',
    'pelletassions',
    'pelletiérines',
    'pelletterions',
    'pelliculaires',
    'pelliculeuses',
    'pelotonnaient',
    'pelotonnasses',
    'pelotonnement',
    'pelotonnerais',
    'pelotonnerait',
    'pelotonnèrent',
    'pelotonneriez',
    'pelotonnerons',
    'pelotonneront',
    'peluchassions',
    'pelucheraient',
    'pelvigraphies',
    'pénalisassent',
    'pénalisassiez',
    'pénalisations',
    'pénaliserions',
    'pendillassent',
    'pendillassiez',
    'pendillerions',
    'pendouillâmes',
    'pendouillasse',
    'pendouillâtes',
    'pendouillerai',
    'pendouilleras',
    'pendouillerez',
    'pendouillions',
    'pendulassions',
    'penduleraient',
    'pénétrabilité',
    'pénétrassions',
    'pénétreraient',
    'pénétromètres',
    'péninsulaires',
    'pénitenceries',
    'pénitentiaire',
    'pénitentiales',
    'pénitentielle',
    'pensionnaient',
    'pensionnaires',
    'pensionnasses',
    'pensionnerais',
    'pensionnerait',
    'pensionnèrent',
    'pensionneriez',
    'pensionnerons',
    'pensionneront',
    'pentadactyles',
    'pentadécagone',
    'pentatoniques',
    'pentédécagone',
    'pépiniéristes',
    'perce-oreille',
    'perce-pierres',
    'perchloriques',
    'percutassions',
    'percuteraient',
    'perdurassions',
    'perdureraient',
    'pérégrinaient',
    'pérégrinasses',
    'pérégrination',
    'pérégrinerais',
    'pérégrinerait',
    'pérégrinèrent',
    'pérégrineriez',
    'pérégrinerons',
    'pérégrineront',
    'pérennisaient',
    'pérennisasses',
    'pérennisation',
    'pérenniserais',
    'pérenniserait',
    'pérennisèrent',
    'pérenniseriez',
    'pérenniserons',
    'pérenniseront',
    'perfectionnai',
    'perfectionnas',
    'perfectionnât',
    'perfectionnée',
    'perfectionner',
    'perfectionnes',
    'perfectionnés',
    'perfectionnez',
    'perforassions',
    'perforatrices',
    'perforeraient',
    'performatives',
    'perfusassions',
    'perfuseraient',
    'périclitaient',
    'périclitasses',
    'péricliterais',
    'péricliterait',
    'périclitèrent',
    'péricliteriez',
    'péricliterons',
    'péricliteront',
    'périglaciaire',
    'péripatétisme',
    'périphériques',
    'périphlébites',
    'périphrasâmes',
    'périphrasasse',
    'périphrasâtes',
    'périphraserai',
    'périphraseras',
    'périphraserez',
    'périphrasions',
    'périscolaires',
    'périscopiques',
    'périssologies',
    'péristaltique',
    'péristaltisme',
    'pérityphlites',
    'permanentâmes',
    'permanentasse',
    'permanentâtes',
    'permanenterai',
    'permanenteras',
    'permanenterez',
    'permanentions',
    'permanganates',
    'permanganique',
    'perméabilisai',
    'perméabilisas',
    'perméabilisât',
    'perméabilisée',
    'perméabiliser',
    'perméabilises',
    'perméabilisés',
    'perméabilisez',
    'perméabilités',
    'permettraient',
    'permissivités',
    'permittivités',
    'permutabilité',
    'permutassions',
    'permuteraient',
    'peroxydassent',
    'péroxydassent',
    'peroxydassiez',
    'péroxydassiez',
    'peroxyderions',
    'péroxyderions',
    'perpétrassent',
    'perpétrassiez',
    'perpétrations',
    'perpétrerions',
    'perpétuassent',
    'perpétuassiez',
    'perpétuations',
    'perpétuerions',
    'perquisiteurs',
    'perquisitions',
    'persécutaient',
    'persécutasses',
    'persécuterais',
    'persécuterait',
    'persécutèrent',
    'persécuteriez',
    'persécuterons',
    'persécuteront',
    'persécutrices',
    'persévéraient',
    'persévérances',
    'persévérantes',
    'persévérasses',
    'persévération',
    'persévérerais',
    'persévérerait',
    'persévérèrent',
    'persévéreriez',
    'persévérerons',
    'persévéreront',
    'persiflassent',
    'persiflassiez',
    'persiflerions',
    'persillassent',
    'persillassiez',
    'persillerions',
    'persistassent',
    'persistassiez',
    'persisterions',
    'personnalisai',
    'personnalisas',
    'personnalisât',
    'personnalisée',
    'personnaliser',
    'personnalises',
    'personnalisés',
    'personnalisez',
    'personnalisme',
    'personnaliste',
    'personnalités',
    'personnifiais',
    'personnifiait',
    'personnifiant',
    'personnifiées',
    'personnifient',
    'personnifiera',
    'personnifiiez',
    'personnifions',
    'perspicacités',
    'perspirations',
    'persuadassent',
    'persuadassiez',
    'persuaderions',
    'pertuisaniers',
    'perturbassent',
    'perturbassiez',
    'perturbateurs',
    'perturbations',
    'perturbatrice',
    'perturberions',
    'pervertirions',
    'pervertissais',
    'pervertissait',
    'pervertissant',
    'pervertissent',
    'pervertisseur',
    'pervertissiez',
    'pervertissons',
    'pervibrassent',
    'pervibrassiez',
    'pervibrerions',
    'pèse-liqueurs',
    'pèse-personne',
    'pestiféraient',
    'pestiférasses',
    'pestiférerais',
    'pestiférerait',
    'pestiférèrent',
    'pestiféreriez',
    'pestiférerons',
    'pestiféreront',
    'pestilentiels',
    'pétaradassent',
    'pétaradassiez',
    'pétaraderions',
    'pétardassions',
    'pétarderaient',
    'pétillassions',
    'pétilleraient',
    'pétitionnaire',
    'pétitionnâmes',
    'pétitionnasse',
    'pétitionnâtes',
    'pétitionnerai',
    'pétitionneras',
    'pétitionnerez',
    'pétitionnions',
    'petits-beurre',
    'petits-neveux',
    'pétouillaient',
    'pétouillasses',
    'pétouillerais',
    'pétouillerait',
    'pétouillèrent',
    'pétouilleriez',
    'pétouillerons',
    'pétouilleront',
    'pétrarquisais',
    'pétrarquisait',
    'pétrarquisant',
    'pétrarquisent',
    'pétrarquisera',
    'pétrarquisiez',
    'pétrarquismes',
    'pétrarquisons',
    'pétrarquistes',
    'pétrifiassent',
    'pétrifiassiez',
    'pétrification',
    'pétrifierions',
    'pétrochimique',
    'pétrochimiste',
    'pétrographies',
    'pétrolochimie',
    'pets-de-nonne',
    'phagédéniques',
    'phagédénismes',
    'phagocytaient',
    'phagocytaires',
    'phagocytasses',
    'phagocyterais',
    'phagocyterait',
    'phagocytèrent',
    'phagocyteriez',
    'phagocyterons',
    'phagocyteront',
    'phalangiennes',
    'phalanstérien',
    'phallocraties',
    'pharamineuses',
    'pharaoniennes',
    'pharmaciennes',
    'pharmacologie',
    'pharmacomanie',
    'pharyngiennes',
    'phénanthrènes',
    'phénobarbital',
    'phénotypiques',
    'philanthropes',
    'philanthropie',
    'philatéliques',
    'philatélistes',
    'philharmonies',
    'philistinisme',
    'philodendrons',
    'philologiques',
    'philosophales',
    'philosophâmes',
    'philosophasse',
    'philosophâtes',
    'philosopherai',
    'philosopheras',
    'philosopherez',
    'philosophions',
    'philosophique',
    'philosophisme',
    'phlegmoneuses',
    'phlogistiques',
    'phonématiques',
    'phonéticienne',
    'phonogéniques',
    'phonolitiques',
    'phonologiques',
    'phosphataient',
    'phosphatasses',
    'phosphaterais',
    'phosphaterait',
    'phosphatèrent',
    'phosphateriez',
    'phosphaterons',
    'phosphateront',
    'phosphaturies',
    'phospholipide',
    'phosphoraient',
    'phosphorasses',
    'phosphorerais',
    'phosphorerait',
    'phosphorèrent',
    'phosphoreriez',
    'phosphorerons',
    'phosphoreront',
    'phosphoriques',
    'photocellules',
    'photochimique',
    'photocopiâmes',
    'photocopiasse',
    'photocopiâtes',
    'photocopierai',
    'photocopieras',
    'photocopierez',
    'photocopieurs',
    'photocopieuse',
    'photocopiions',
    'photogéniques',
    'photographiai',
    'photographias',
    'photographiât',
    'photographiée',
    'photographier',
    'photographies',
    'photographiés',
    'photographiez',
    'photograveurs',
    'photograveuse',
    'photogravures',
    'photomontages',
    'photosensible',
    'photos-finish',
    'photosynthèse',
    'photothérapie',
    'phototropisme',
    'phraséologies',
    'phrénologique',
    'phtisiologies',
    'phtisiologues',
    'phylloxériens',
    'phylloxérique',
    'phylogéniques',
    'physicalismes',
    'physiocraties',
    'physiologique',
    'physiologiste',
    'physionomique',
    'physionomiste',
    'physisorption',
    'piaillassions',
    'piailleraient',
    'pianotassions',
    'pianoteraient',
    'pictographies',
    'pied-de-biche',
    'pied-de-poule',
    'pieds-de-loup',
    'pieds-de-veau',
    'pies-grièches',
    'piétinassions',
    'piétineraient',
    'pigeonnassent',
    'pigeonnassiez',
    'pigeonnerions',
    'pigmentassent',
    'pigmentassiez',
    'pigmentations',
    'pigmenterions',
    'pignochassent',
    'pignochassiez',
    'pignocherions',
    'pilonnassions',
    'pilonneraient',
    'pilo-sébacées',
    'pimentassions',
    'pimenteraient',
    'pinacothèques',
    'pinaillassent',
    'pinaillassiez',
    'pinaillerions',
    'pindarisaient',
    'pindarisasses',
    'pindariserais',
    'pindariserait',
    'pindarisèrent',
    'pindariseriez',
    'pindariserons',
    'pindariseront',
    'pique-niquais',
    'pique-niquait',
    'pique-niquant',
    'pique-niquent',
    'pique-niquera',
    'pique-niqueur',
    'pique-niquiez',
    'pique-niquons',
    'piquetassions',
    'piquetterions',
    'pirouettaient',
    'pirouettasses',
    'pirouettement',
    'pirouetterais',
    'pirouetterait',
    'pirouettèrent',
    'pirouetteriez',
    'pirouetterons',
    'pirouetteront',
    'pisciculteurs',
    'piscicultrice',
    'piscicultures',
    'pisolithiques',
    'pissaladières',
    'pistachassent',
    'pistachassiez',
    'pistacherions',
    'pistonnassent',
    'pistonnassiez',
    'pistonnerions',
    'pitonnassions',
    'pitonneraient',
    'pitoyablement',
    'placardassent',
    'placardassiez',
    'placarderions',
    'placentations',
    'plafonnassent',
    'plafonnassiez',
    'plafonnements',
    'plafonnerions',
    'plaignissions',
    'plains-chants',
    'plaintivement',
    'plaisantaient',
    'plaisantasses',
    'plaisanterais',
    'plaisanterait',
    'plaisantèrent',
    'plaisanteries',
    'plaisanteriez',
    'plaisanterons',
    'plaisanteront',
    'planchassions',
    'planchéiaîmes',
    'planchéiaisse',
    'planchéiaîtes',
    'planchéiasses',
    'planchéierais',
    'planchéierait',
    'planchéièrent',
    'planchéieriez',
    'planchéierons',
    'planchéieront',
    'plancheraient',
    'plan-concaves',
    'plan-convexes',
    'planctoniques',
    'planifiassent',
    'planifiassiez',
    'planificateur',
    'planification',
    'planifierions',
    'planimétrique',
    'planquassions',
    'planqueraient',
    'plaqueminiers',
    'plasmifiaient',
    'plasmifiasses',
    'plasmifierais',
    'plasmifierait',
    'plasmifièrent',
    'plasmifieriez',
    'plasmifierons',
    'plasmifieront',
    'plasticiennes',
    'plastifiaient',
    'plastifiantes',
    'plastifiasses',
    'plastifierais',
    'plastifierait',
    'plastifièrent',
    'plastifieriez',
    'plastifierons',
    'plastifieront',
    'plastiquaient',
    'plastiquasses',
    'plastiquement',
    'plastiquerais',
    'plastiquerait',
    'plastiquèrent',
    'plastiqueriez',
    'plastiquerons',
    'plastiqueront',
    'plastronnâmes',
    'plastronnasse',
    'plastronnâtes',
    'plastronnerai',
    'plastronneras',
    'plastronnerez',
    'plastronnions',
    'plates-bandes',
    'plates-formes',
    'platinassions',
    'platineraient',
    'platinisaient',
    'platinisasses',
    'platiniserais',
    'platiniserait',
    'platinisèrent',
    'platiniseriez',
    'platiniserons',
    'platiniseront',
    'platonicienne',
    'plausibilités',
    'plausiblement',
    'plébiscitaire',
    'plébiscitâmes',
    'plébiscitasse',
    'plébiscitâtes',
    'plébisciterai',
    'plébisciteras',
    'plébisciterez',
    'plébiscitions',
    'pléonastiques',
    'pleurnichâmes',
    'pleurnichasse',
    'pleurnichâtes',
    'pleurnicherai',
    'pleurnicheras',
    'pleurnicherez',
    'pleurnicherie',
    'pleurnicheurs',
    'pleurnicheuse',
    'pleurnichions',
    'pleurobranche',
    'pleuvasserait',
    'plongeassions',
    'ploutocraties',
    'pluriannuelle',
    'plurilatérale',
    'plurilatéraux',
    'plurinational',
    'pluripartisme',
    'plurivalentes',
    'pluviométries',
    'pneumatologie',
    'pneumatophore',
    'pneumectomies',
    'pneumoconiose',
    'pneumographie',
    'poecilotherme',
    'poétisassions',
    'poétiseraient',
    'poignardaient',
    'poignardasses',
    'poignarderais',
    'poignarderait',
    'poignardèrent',
    'poignarderiez',
    'poignarderons',
    'poignarderont',
    'poïkilotherme',
    'poinçonnaient',
    'poinçonnasses',
    'poinçonnement',
    'poinçonnerais',
    'poinçonnerait',
    'poinçonnèrent',
    'poinçonneriez',
    'poinçonnerons',
    'poinçonneront',
    'poinçonneuses',
    'pointillaient',
    'pointillasses',
    'pointillerais',
    'pointillerait',
    'pointillèrent',
    'pointilleriez',
    'pointillerons',
    'pointilleront',
    'pointilleuses',
    'pointillismes',
    'pointillistes',
    'point-virgule',
    'poireautaient',
    'poireautasses',
    'poireauterais',
    'poireauterait',
    'poireautèrent',
    'poireauteriez',
    'poireauterons',
    'poireauteront',
    'poirotassions',
    'poiroteraient',
    'poissonneries',
    'poissonneuses',
    'poissonnières',
    'polarisassent',
    'polarisassiez',
    'polarisations',
    'polariserions',
    'polémiquaient',
    'polémiquasses',
    'polémiquerais',
    'polémiquerait',
    'polémiquèrent',
    'polémiqueriez',
    'polémiquerons',
    'polémiqueront',
    'polichinelles',
    'policliniques',
    'poliomyélites',
    'poliorcétique',
    'polissonnâmes',
    'polissonnasse',
    'polissonnâtes',
    'polissonnerai',
    'polissonneras',
    'polissonnerez',
    'polissonnerie',
    'polissonnions',
    'politiciennes',
    'politiquaient',
    'politiquasses',
    'politiquement',
    'politiquerais',
    'politiquerait',
    'politiquèrent',
    'politiqueriez',
    'politiquerons',
    'politiqueront',
    'politisassent',
    'politisassiez',
    'politisations',
    'politiserions',
    'poltronneries',
    'polyarthrites',
    'polycentrique',
    'polycentrisme',
    'polychroïsmes',
    'polycliniques',
    'polycondensat',
    'polycopiaient',
    'polycopiasses',
    'polycopierais',
    'polycopierait',
    'polycopièrent',
    'polycopieriez',
    'polycopierons',
    'polycopieront',
    'polycycliques',
    'polydactylies',
    'polyembryonie',
    'polyéthylènes',
    'polyglobulies',
    'polygonations',
    'polymérisable',
    'polymérisâmes',
    'polymérisasse',
    'polymérisâtes',
    'polymériserai',
    'polymériseras',
    'polymériserez',
    'polymérisions',
    'polymorphisme',
    'polynésiennes',
    'polynucléaire',
    'polyphoniques',
    'polytechnique',
    'pommadassions',
    'pommaderaient',
    'pommelassions',
    'pommellerions',
    'pommerassions',
    'pomponnassent',
    'pomponnassiez',
    'pomponnerions',
    'ponctionnâmes',
    'ponctionnasse',
    'ponctionnâtes',
    'ponctionnerai',
    'ponctionneras',
    'ponctionnerez',
    'ponctionnions',
    'ponctuassions',
    'ponctueraient',
    'pondérassions',
    'pondératrices',
    'pondéreraient',
    'pontifiassent',
    'pontifiassiez',
    'pontifierions',
    'pontillassent',
    'pontillassiez',
    'pontillerions',
    'populairement',
    'popularisâmes',
    'popularisasse',
    'popularisâtes',
    'populariserai',
    'populariseras',
    'populariserez',
    'popularisions',
    'porcelainière',
    'porcelainiers',
    'pornographies',
    'porphyrisâmes',
    'porphyrisasse',
    'porphyrisâtes',
    'porphyriserai',
    'porphyriseras',
    'porphyriserez',
    'porphyrisions',
    'porte-à-porte',
    'porte-bagages',
    'porte-billets',
    'porte-bonheur',
    'porte-bouquet',
    'porte-cigares',
    'porte-couteau',
    'porte-crayons',
    'porte-crosses',
    'porte-drapeau',
    'porte-étriers',
    'porte-fanions',
    'porte-fenêtre',
    'portefeuilles',
    'porte-greffes',
    'porte-haubans',
    'porte-malheur',
    'portemanteaux',
    'porte-monnaie',
    'porte-montres',
    'porte-musique',
    'portionnaires',
    'portraitistes',
    'portraiturais',
    'portraiturait',
    'portraiturant',
    'portraiturées',
    'portraiturent',
    'portraiturera',
    'portraituriez',
    'portraiturons',
    'positionnâmes',
    'positionnasse',
    'positionnâtes',
    'positionnerai',
    'positionneras',
    'positionnerez',
    'positionnions',
    'possédassions',
    'posséderaient',
    'possessionnel',
    'possessivités',
    'postclassique',
    'postcommunion',
    'postdatassent',
    'postdatassiez',
    'postdaterions',
    'postériorités',
    'postglaciaire',
    'posthypophyse',
    'postichassent',
    'postichassiez',
    'posticherions',
    'postillonnais',
    'postillonnait',
    'postillonnant',
    'postillonnent',
    'postillonnera',
    'postillonniez',
    'postillonnons',
    'postposassent',
    'postposassiez',
    'postposerions',
    'postpositions',
    'postscolaires',
    'post-scriptum',
    'postulassions',
    'postuleraient',
    'potassassions',
    'potasseraient',
    'potentialisai',
    'potentialisas',
    'potentialisât',
    'potentialisée',
    'potentialiser',
    'potentialises',
    'potentialisés',
    'potentialisez',
    'potentialités',
    'potentiomètre',
    'poudroiements',
    'poudroierions',
    'poudroyassent',
    'poudroyassiez',
    'poulinassions',
    'poulineraient',
    'poult-de-soie',
    'pouponnassent',
    'pouponnassiez',
    'pouponnerions',
    'pourchassâmes',
    'pourchassasse',
    'pourchassâtes',
    'pourchasserai',
    'pourchasseras',
    'pourchasserez',
    'pourchassions',
    'pourfendaient',
    'pourfendirent',
    'pourfendisses',
    'pourfendrions',
    'pourléchaient',
    'pourléchasses',
    'pourlécherais',
    'pourlécherait',
    'pourléchèrent',
    'pourlécheriez',
    'pourlécherons',
    'pourlécheront',
    'pourprassions',
    'pourpreraient',
    'pourrissaient',
    'pourrissantes',
    'pourrissement',
    'poursuivaient',
    'poursuivantes',
    'poursuivirent',
    'poursuivisses',
    'poursuivrions',
    'pourvoiraient',
    'pousse-pousse',
    'poussiéreuses',
    'pouts-de-soie',
    'pralinassions',
    'pralineraient',
    'praticabilité',
    'pratiquassent',
    'pratiquassiez',
    'pratiquerions',
    'préachetaient',
    'préachetasses',
    'préachèterais',
    'préachèterait',
    'préachetèrent',
    'préachèteriez',
    'préachèterons',
    'préachèteront',
    'préalablement',
    'préavisassent',
    'préavisassiez',
    'préaviserions',
    'précambrienne',
    'précarisaient',
    'précarisasses',
    'précariserais',
    'précariserait',
    'précarisèrent',
    'précariseriez',
    'précariserons',
    'précariseront',
    'précautionnai',
    'précautionnas',
    'précautionnât',
    'précautionnée',
    'précautionner',
    'précautionnes',
    'précautionnés',
    'précautionnez',
    'précédassions',
    'précéderaient',
    'préchauffages',
    'préchauffâmes',
    'préchauffasse',
    'préchauffâtes',
    'préchaufferai',
    'préchaufferas',
    'préchaufferez',
    'préchauffions',
    'prêchi-prêcha',
    'précieusement',
    'précipitaient',
    'précipitasses',
    'précipitation',
    'précipiterais',
    'précipiterait',
    'précipitèrent',
    'précipiteriez',
    'précipiterons',
    'précipiteront',
    'préciputaires',
    'précisassions',
    'préciseraient',
    'précolombiens',
    'précombustion',
    'précomptaient',
    'précomptasses',
    'précompterais',
    'précompterait',
    'précomptèrent',
    'précompteriez',
    'précompterons',
    'précompteront',
    'préconception',
    'préconisaient',
    'préconisasses',
    'préconisateur',
    'préconisation',
    'préconiserais',
    'préconiserait',
    'préconisèrent',
    'préconiseriez',
    'préconiserons',
    'préconiseront',
    'précontrainte',
    'précontraints',
    'prédécesseurs',
    'prédéfinirais',
    'prédéfinirait',
    'prédéfinirent',
    'prédéfiniriez',
    'prédéfinirons',
    'prédéfiniront',
    'prédéfinisses',
    'prédéfinissez',
    'prédestinâmes',
    'prédestinasse',
    'prédestinâtes',
    'prédestinerai',
    'prédestineras',
    'prédestinerez',
    'prédestinions',
    'prédéterminai',
    'prédéterminas',
    'prédéterminât',
    'prédéterminée',
    'prédéterminer',
    'prédétermines',
    'prédéterminés',
    'prédéterminez',
    'prédicatrices',
    'prédilections',
    'prédiquassent',
    'prédiquassiez',
    'prédiquerions',
    'prédisposâmes',
    'prédisposasse',
    'prédisposâtes',
    'prédisposerai',
    'prédisposeras',
    'prédisposerez',
    'prédisposions',
    'prédominaient',
    'prédominances',
    'prédominantes',
    'prédominasses',
    'prédominerais',
    'prédominerait',
    'prédominèrent',
    'prédomineriez',
    'prédominerons',
    'prédomineront',
    'préélectorale',
    'préélectoraux',
    'préemptassent',
    'préemptassiez',
    'préempterions',
    'préétablirais',
    'préétablirait',
    'préétablirent',
    'préétabliriez',
    'préétablirons',
    'préétabliront',
    'préétablisses',
    'préétablissez',
    'préexistaient',
    'préexistantes',
    'préexistasses',
    'préexisterais',
    'préexisterait',
    'préexistèrent',
    'préexisteriez',
    'préexisterons',
    'préexisteront',
    'préfabriquées',
    'préfaçassions',
    'préfaceraient',
    'préfectorales',
    'préférassions',
    'préférentiels',
    'préféreraient',
    'préfiguraient',
    'préfigurasses',
    'préfiguration',
    'préfigurerais',
    'préfigurerait',
    'préfigurèrent',
    'préfigureriez',
    'préfigurerons',
    'préfigureront',
    'préfixassions',
    'préfixeraient',
    'préfloraisons',
    'préfoliations',
    'préformassent',
    'préformassiez',
    'préformations',
    'préformerions',
    'préglaciaires',
    'préhellénique',
    'préhistoriens',
    'préhistorique',
    'préjudiciable',
    'préjudiciâmes',
    'préjudiciasse',
    'préjudiciâtes',
    'préjudicielle',
    'préjudicierai',
    'préjudicieras',
    'préjudicierez',
    'préjudiciions',
    'préjugeassent',
    'préjugeassiez',
    'préjugeraient',
    'prélassassent',
    'prélassassiez',
    'prélasserions',
    'prélevassions',
    'prélèveraient',
    'préliminaires',
    'préludassions',
    'préluderaient',
    'prématurément',
    'prémédication',
    'préméditaient',
    'préméditasses',
    'préméditation',
    'préméditerais',
    'préméditerait',
    'préméditèrent',
    'préméditeriez',
    'préméditerons',
    'préméditeront',
    'prémenstruels',
    'prémilitaires',
    'prémonitoires',
    'prémuniraient',
    'prémunissions',
    'prénommassent',
    'prénommassiez',
    'prénommerions',
    'préoccupaient',
    'préoccupantes',
    'préoccupasses',
    'préoccupation',
    'préoccuperais',
    'préoccuperait',
    'préoccupèrent',
    'préoccuperiez',
    'préoccuperons',
    'préoccuperont',
    'préopératoire',
    'prépaieraient',
    'préparassions',
    'préparatoires',
    'préparatrices',
    'prépareraient',
    'prépayassions',
    'prépayeraient',
    'prépondérance',
    'prépondérante',
    'prépondérants',
    'préposassions',
    'préposeraient',
    'préprogrammée',
    'préprogrammés',
    'préraphaélite',
    'préréglassent',
    'préréglassiez',
    'préréglerions',
    'préromantique',
    'préromantisme',
    'présageassent',
    'présageassiez',
    'présageraient',
    'présanctifiée',
    'présanctifiés',
    'presbytérales',
    'presbytériens',
    'prescripteurs',
    'prescriptible',
    'prescriptions',
    'prescriptrice',
    'prescriraient',
    'prescrivaient',
    'prescrivirent',
    'prescrivisses',
    'présélecteurs',
    'présélections',
    'présentassent',
    'présentassiez',
    'présentateurs',
    'présentations',
    'présentatrice',
    'présenterions',
    'préservassent',
    'préservassiez',
    'préservateurs',
    'préservations',
    'préservatrice',
    'préserverions',
    'présidassions',
    'présidentiels',
    'présideraient',
    'présomptueuse',
    'presse-bouton',
    'presse-citron',
    'presse-étoupe',
    'presse-fruits',
    'pressentaient',
    'pressentiment',
    'pressentirais',
    'pressentirait',
    'pressentirent',
    'pressentiriez',
    'pressentirons',
    'pressentiront',
    'pressentisses',
    'pressurassent',
    'pressurassiez',
    'pressurerions',
    'pressurisâmes',
    'pressurisasse',
    'pressurisâtes',
    'pressuriserai',
    'pressuriseras',
    'pressuriserez',
    'pressurisions',
    'prestigieuses',
    'présumassions',
    'présumeraient',
    'présupposâmes',
    'présupposasse',
    'présupposâtes',
    'présupposerai',
    'présupposeras',
    'présupposerez',
    'présupposions',
    'présurassions',
    'présureraient',
    'prêt-à-porter',
    'prétendissent',
    'prétendissiez',
    'prétendraient',
    'prétentiardes',
    'prétentieuses',
    'prétextassent',
    'prétextassiez',
    'prétexterions',
    'prévalussions',
    'prévaricateur',
    'prévarication',
    'prévariquâmes',
    'prévariquasse',
    'prévariquâtes',
    'prévariquerai',
    'prévariqueras',
    'prévariquerez',
    'prévariquions',
    'prévaudraient',
    'préventoriums',
    'préviendrions',
    'prévisibilité',
    'prévisionnels',
    'primarisaient',
    'primarisasses',
    'primariserais',
    'primariserait',
    'primarisèrent',
    'primariseriez',
    'primariserons',
    'primariseront',
    'primesautière',
    'primesautiers',
    'primipilaires',
    'primitivement',
    'primitivismes',
    'primogéniture',
    'princièrement',
    'privatdocents',
    'privatdozents',
    'privatisaient',
    'privatisasses',
    'privatisation',
    'privatiserais',
    'privatiserait',
    'privatisèrent',
    'privatiseriez',
    'privatiserons',
    'privatiseront',
    'privilégiâmes',
    'privilégiasse',
    'privilégiâtes',
    'privilégierai',
    'privilégieras',
    'privilégierez',
    'privilégiions',
    'probabilismes',
    'probabilistes',
    'problématique',
    'procédassions',
    'procéderaient',
    'procédurières',
    'processionnai',
    'processionnas',
    'processionnât',
    'processionnel',
    'processionner',
    'processionnes',
    'processionnez',
    'procès-verbal',
    'prochainement',
    'proclamassent',
    'proclamassiez',
    'proclamations',
    'proclamerions',
    'proconsulaire',
    'procréassions',
    'procréatrices',
    'procréeraient',
    'procurassions',
    'procurerasses',
    'procurerèrent',
    'prodiguassent',
    'prodiguassiez',
    'prodiguerions',
    'productivités',
    'produisissent',
    'produisissiez',
    'profanassions',
    'profanatrices',
    'profaneraient',
    'proférassions',
    'proféreraient',
    'professassent',
    'professassiez',
    'professerions',
    'professionnel',
    'professorales',
    'profilassions',
    'profileraient',
    'profitassions',
    'profiteraient',
    'progestatives',
    'progestérones',
    'prognathismes',
    'programmables',
    'programmaient',
    'programmasses',
    'programmateur',
    'programmation',
    'programmerais',
    'programmerait',
    'programmèrent',
    'programmeriez',
    'programmerons',
    'programmeront',
    'programmeuses',
    'progressaient',
    'progressasses',
    'progresserais',
    'progresserait',
    'progressèrent',
    'progresseriez',
    'progresserons',
    'progresseront',
    'progressismes',
    'progressistes',
    'progressivité',
    'prohibassions',
    'prohiberaient',
    'projetassions',
    'projetterions',
    'prolétarienne',
    'prolétarisais',
    'prolétarisait',
    'prolétarisant',
    'prolétarisées',
    'prolétarisent',
    'prolétarisera',
    'prolétarisiez',
    'prolétarisons',
    'proliféraient',
    'proliférasses',
    'prolifération',
    'proliférerais',
    'proliférerait',
    'proliférèrent',
    'proliféreriez',
    'proliférerons',
    'proliféreront',
    'prolongateurs',
    'prolongations',
    'prolongeaient',
    'prolongeasses',
    'prolongements',
    'prolongerions',
    'promenassions',
    'promèneraient',
    'prométhéennes',
    'promettraient',
    'promotionnels',
    'promouvraient',
    'promulgations',
    'promulguaient',
    'promulguasses',
    'promulguerais',
    'promulguerait',
    'promulguèrent',
    'promulgueriez',
    'promulguerons',
    'promulgueront',
    'prononçassent',
    'prononçassiez',
    'prononcerions',
    'prononciation',
    'pronostiquais',
    'pronostiquait',
    'pronostiquant',
    'pronostiquées',
    'pronostiquent',
    'pronostiquera',
    'pronostiqueur',
    'pronostiquiez',
    'pronostiquons',
    'propagandiste',
    'propagatrices',
    'propageassent',
    'propageassiez',
    'propageraient',
    'proparoxytons',
    'propédeutique',
    'propharmacien',
    'prophétisâmes',
    'prophétisasse',
    'prophétisâtes',
    'prophétiserai',
    'prophétiseras',
    'prophétiserez',
    'prophétisions',
    'propitiations',
    'propitiatoire',
    'proportionnai',
    'proportionnas',
    'proportionnât',
    'proportionnée',
    'proportionnel',
    'proportionner',
    'proportionnes',
    'proportionnés',
    'proportionnez',
    'proposassions',
    'proposeraient',
    'propre-à-rien',
    'propriétaires',
    'proprioceptif',
    'propulsassent',
    'propulsassiez',
    'propulserions',
    'prorogeassent',
    'prorogeassiez',
    'prorogeraient',
    'prosaïquement',
    'proscripteurs',
    'proscriptions',
    'proscriraient',
    'proscrivaient',
    'proscrivirent',
    'proscrivisses',
    'prosélytismes',
    'prosodiassent',
    'prosodiassiez',
    'prosodierions',
    'prospectaient',
    'prospectasses',
    'prospecterais',
    'prospecterait',
    'prospectèrent',
    'prospecteriez',
    'prospecterons',
    'prospecteront',
    'prospectrices',
    'prospérassent',
    'prospérassiez',
    'prospérerions',
    'prosternaient',
    'prosternasses',
    'prosternation',
    'prosternement',
    'prosternerais',
    'prosternerait',
    'prosternèrent',
    'prosterneriez',
    'prosternerons',
    'prosterneront',
    'prosthétiques',
    'prostituaient',
    'prostituasses',
    'prostituerais',
    'prostituerait',
    'prostituèrent',
    'prostitueriez',
    'prostituerons',
    'prostitueront',
    'prostitutions',
    'protactiniums',
    'protagonistes',
    'protégeassent',
    'protégeassiez',
    'protège-dents',
    'protégeraient',
    'protège-tibia',
    'protéolytique',
    'protérandries',
    'protestassent',
    'protestassiez',
    'protestataire',
    'protestations',
    'protesterions',
    'prothrombines',
    'protocolaires',
    'protohistoire',
    'protubérances',
    'protubérantes',
    'proudhonienne',
    'proverbialisa',
    'proverbialise',
    'proverbialisé',
    'providentiels',
    'proviendrions',
    'provignassent',
    'provignassiez',
    'provignements',
    'provignerions',
    'provincialats',
    'provisionnels',
    'provocatrices',
    'provoquassent',
    'provoquassiez',
    'provoquerions',
    'proxénétismes',
    'prudhommeries',
    'prudhommesque',
    'prurigineuses',
    'psalmodiaient',
    'psalmodiasses',
    'psalmodierais',
    'psalmodierait',
    'psalmodièrent',
    'psalmodieriez',
    'psalmodierons',
    'psalmodieront',
    'pseudarthrose',
    'psychanalysai',
    'psychanalysas',
    'psychanalysât',
    'psychanalysée',
    'psychanalyser',
    'psychanalyses',
    'psychanalysés',
    'psychanalysez',
    'psychanalyste',
    'psychédélique',
    'psychiatrique',
    'psychiatrisai',
    'psychiatrisas',
    'psychiatrisât',
    'psychiatrisée',
    'psychiatriser',
    'psychiatrises',
    'psychiatrisés',
    'psychiatrisez',
    'psychologique',
    'psychologisme',
    'psychométries',
    'psychomoteurs',
    'psychomotrice',
    'psychonévrose',
    'psychopathies',
    'psychorigides',
    'psychosociale',
    'psychosociaux',
    'ptérodactyles',
    'publicitaires',
    'publipostages',
    'pudibonderies',
    'puéricultrice',
    'puéricultures',
    'pugilistiques',
    'pullulassions',
    'pulluleraient',
    'pulsionnelles',
    'pulsoréacteur',
    'pulvérisables',
    'pulvérisaient',
    'pulvérisasses',
    'pulvérisateur',
    'pulvérisation',
    'pulvériserais',
    'pulvériserait',
    'pulvérisèrent',
    'pulvériseriez',
    'pulvériserons',
    'pulvériseront',
    'pulvérulences',
    'pulvérulentes',
    'punaisassions',
    'punaiseraient',
    'punching-ball',
    'pupinisations',
    'purifiassions',
    'purificateurs',
    'purifications',
    'purificatoire',
    'purificatrice',
    'purifieraient',
    'pusillanimité',
    'putassassière',
    'putassassiers',
    'putréfactions',
    'putréfiassent',
    'putréfiassiez',
    'putréfierions',
    'pyramidassent',
    'pyramidassiez',
    'pyramiderions',
    'pyrogénations',
    'pyrogravaient',
    'pyrogravasses',
    'pyrograverais',
    'pyrograverait',
    'pyrogravèrent',
    'pyrograveriez',
    'pyrograverons',
    'pyrograveront',
    'pyrograveuses',
    'pyrométriques',
    'pyrotechnique',
    'pyrrhoniennes',
    'pythagoricien',
    'pythagoriques',
    'pythagorismes',
    'quadragénaire',
    'quadragésimal',
    'quadragésimes',
    'quadrichromie',
    'quadriennales',
    'quadrilatères',
    'quadrillaient',
    'quadrillasses',
    'quadrillerais',
    'quadrillerait',
    'quadrillèrent',
    'quadrilleriez',
    'quadrillerons',
    'quadrilleront',
    'quadrimoteurs',
    'quadriparties',
    'quadripartite',
    'quadriphonies',
    'quadrisyllabe',
    'quadruplaient',
    'quadruplasses',
    'quadruplement',
    'quadruplerais',
    'quadruplerait',
    'quadruplèrent',
    'quadrupleriez',
    'quadruplerons',
    'quadrupleront',
    'qualifiassent',
    'qualifiassiez',
    'qualificatifs',
    'qualification',
    'qualificative',
    'qualifierions',
    'quantifiables',
    'quantifiaient',
    'quantifiasses',
    'quantifierais',
    'quantifierait',
    'quantifièrent',
    'quantifieriez',
    'quantifierons',
    'quantifieront',
    'quantitatives',
    'quarante-cinq',
    'quarante-deux',
    'quarante-huit',
    'quarante-neuf',
    'quarante-sept',
    'quarderonnais',
    'quarderonnait',
    'quarderonnant',
    'quarderonnées',
    'quarderonnent',
    'quarderonnera',
    'quarderonniez',
    'quarderonnons',
    'quartageaient',
    'quartageasses',
    'quartagerions',
    'quatre-épices',
    'quatre-quarts',
    'quatre-vingts',
    'quatrièmement',
    'quelques-unes',
    'quémandassent',
    'quémandassiez',
    'quémanderions',
    'querellassent',
    'querellassiez',
    'querellerions',
    'questionnaire',
    'questionnâmes',
    'questionnasse',
    'questionnâtes',
    'questionnerai',
    'questionneras',
    'questionnerez',
    'questionneurs',
    'questionneuse',
    'questionnions',
    'queues-de-pie',
    'queues-de-rat',
    'quincaillerie',
    'quincaillière',
    'quincailliers',
    'quinquagésime',
    'quinquennales',
    'quintefeuille',
    'quintessences',
    'quintessencia',
    'quintessencie',
    'quintessencié',
    'quintuplaient',
    'quintuplasses',
    'quintuplerais',
    'quintuplerait',
    'quintuplèrent',
    'quintupleriez',
    'quintuplerons',
    'quintupleront',
    'quinzièmement',
    'quittançaient',
    'quittançasses',
    'quittancerais',
    'quittancerait',
    'quittancèrent',
    'quittanceriez',
    'quittancerons',
    'quittanceront',
    'quotidienneté',
    'rabâchassions',
    'rabâcheraient',
    'rabaissassent',
    'rabaissassiez',
    'rabaissements',
    'rabaisserions',
    'rabantassions',
    'rabanteraient',
    'rabattissions',
    'rabelaisienne',
    'rabibochaient',
    'rabibochasses',
    'rabibocherais',
    'rabibocherait',
    'rabibochèrent',
    'rabibocheriez',
    'rabibocherons',
    'rabibocheront',
    'rabiotassions',
    'rabioteraient',
    'rabonniraient',
    'rabonnissions',
    'rabougririons',
    'rabougrissais',
    'rabougrissait',
    'rabougrissant',
    'rabougrissent',
    'rabougrissiez',
    'rabougrissons',
    'rabouilleuses',
    'raboutassions',
    'rabouteraient',
    'rabrouassions',
    'rabroueraient',
    'raccommodable',
    'raccommodages',
    'raccommodâmes',
    'raccommodasse',
    'raccommodâtes',
    'raccommoderai',
    'raccommoderas',
    'raccommoderez',
    'raccommodeurs',
    'raccommodeuse',
    'raccommodions',
    'raccompagnais',
    'raccompagnait',
    'raccompagnant',
    'raccompagnées',
    'raccompagnent',
    'raccompagnera',
    'raccompagniez',
    'raccompagnons',
    'raccordassent',
    'raccordassiez',
    'raccordements',
    'raccorderions',
    'raccourcirais',
    'raccourcirait',
    'raccourcirent',
    'raccourciriez',
    'raccourcirons',
    'raccourciront',
    'raccourcisses',
    'raccourcissez',
    'raccoutraient',
    'raccoutrasses',
    'raccoutrerais',
    'raccoutrerait',
    'raccoutrèrent',
    'raccoutreriez',
    'raccoutrerons',
    'raccoutreront',
    'raccoutumâmes',
    'raccoutumasse',
    'raccoutumâtes',
    'raccoutumerai',
    'raccoutumeras',
    'raccoutumerez',
    'raccoutumions',
    'raccrochaient',
    'raccrochasses',
    'raccrocherais',
    'raccrocherait',
    'raccrochèrent',
    'raccrocheriez',
    'raccrocherons',
    'raccrocheront',
    'raccrocheuses',
    'rachetassions',
    'rachèteraient',
    'racontassions',
    'raconteraient',
    'racorniraient',
    'racornissions',
    'radicalisâmes',
    'radicalisasse',
    'radicalisâtes',
    'radicaliserai',
    'radicaliseras',
    'radicaliserez',
    'radicalisions',
    'radiesthésies',
    'radioactivité',
    'radioamateurs',
    'radioamatrice',
    'radiobalisage',
    'radiobalisais',
    'radiobalisait',
    'radiobalisant',
    'radiobalisées',
    'radiobalisent',
    'radiobalisera',
    'radiobalisiez',
    'radiobalisons',
    'radiocarbones',
    'radiocassette',
    'radiodiffusai',
    'radiodiffusas',
    'radiodiffusât',
    'radiodiffusée',
    'radiodiffuser',
    'radiodiffuses',
    'radiodiffusés',
    'radiodiffusez',
    'radiographiai',
    'radiographias',
    'radiographiât',
    'radiographiée',
    'radiographier',
    'radiographies',
    'radiographiés',
    'radiographiez',
    'radioguidages',
    'radioguidâmes',
    'radioguidasse',
    'radioguidâtes',
    'radioguiderai',
    'radioguideras',
    'radioguiderez',
    'radioguidions',
    'radiologiques',
    'radiologistes',
    'radionavigant',
    'radionécroses',
    'radiophonique',
    'radioreporter',
    'radioscopâmes',
    'radioscopasse',
    'radioscopâtes',
    'radioscoperai',
    'radioscoperas',
    'radioscoperez',
    'radioscopions',
    'radiosondages',
    'radiotélévisé',
    'radiothérapie',
    'radoubassions',
    'radouberaient',
    'radouciraient',
    'radoucissions',
    'raffermirions',
    'raffermissais',
    'raffermissait',
    'raffermissant',
    'raffermissent',
    'raffermissiez',
    'raffermissons',
    'raffinassions',
    'raffineraient',
    'raffolassions',
    'raffoleraient',
    'raffûtassions',
    'raffûteraient',
    'rafistolaient',
    'rafistolasses',
    'rafistolerais',
    'rafistolerait',
    'rafistolèrent',
    'rafistoleriez',
    'rafistolerons',
    'rafistoleront',
    'rafraîchirais',
    'rafraîchirait',
    'rafraîchirent',
    'rafraîchiriez',
    'rafraîchirons',
    'rafraîchiront',
    'rafraîchisses',
    'rafraîchissez',
    'ragaillardies',
    'ragaillardira',
    'ragoûtassions',
    'ragoûteraient',
    'ragrafassions',
    'ragraferaient',
    'rahat-lokoums',
    'rahat-loukoum',
    'raidirassions',
    'raidissements',
    'railleusement',
    'rainetassions',
    'rainetterions',
    'rainurassions',
    'rainureraient',
    'rais-de-coeur',
    'raisonnassent',
    'raisonnassiez',
    'raisonnements',
    'raisonnerions',
    'rajeuniraient',
    'rajeunissante',
    'rajeunissants',
    'rajeunissions',
    'rajoutassions',
    'rajouteraient',
    'rajustassions',
    'rajusteraient',
    'ralentiraient',
    'ralentisseurs',
    'ralentissions',
    'ralinguassent',
    'ralinguassiez',
    'ralinguerions',
    'rallégeassent',
    'rallégeassiez',
    'rallégeraient',
    'rallongeaient',
    'rallongeasses',
    'rallongements',
    'rallongerions',
    'rallumassions',
    'rallumeraient',
    'ramageassions',
    'ramaillassent',
    'ramaillassiez',
    'ramaillerions',
    'ramandassions',
    'ramanderaient',
    'ramarrassions',
    'ramarreraient',
    'ramassassions',
    'ramasseraient',
    'ramastiquâmes',
    'ramastiquasse',
    'ramastiquâtes',
    'ramastiquions',
    'ramatisquerai',
    'ramatisqueras',
    'ramatisquerez',
    'ramendassions',
    'ramenderaient',
    'rameutassions',
    'rameuteraient',
    'ramifiassions',
    'ramifications',
    'ramifieraient',
    'ramolliraient',
    'ramollissante',
    'ramollissants',
    'ramollissions',
    'rancardassent',
    'rancardassiez',
    'rancarderions',
    'rancissements',
    'rançonnassent',
    'rançonnassiez',
    'rançonnements',
    'rançonnerions',
    'randomisaient',
    'randomisasses',
    'randomisation',
    'randomiserais',
    'randomiserait',
    'randomisèrent',
    'randomiseriez',
    'randomiserons',
    'randomiseront',
    'randonnassent',
    'randonnassiez',
    'randonnerions',
    'rapapillotais',
    'rapapillotait',
    'rapapillotant',
    'rapapillotées',
    'rapapillotent',
    'rapapillotera',
    'rapapillotiez',
    'rapapillotons',
    'rapatriassent',
    'rapatriassiez',
    'rapatriements',
    'rapatrierions',
    'rapatronnages',
    'rapetassaient',
    'rapetassasses',
    'rapetasserais',
    'rapetasserait',
    'rapetassèrent',
    'rapetasseriez',
    'rapetasserons',
    'rapetasseront',
    'rapetissaient',
    'rapetissasses',
    'rapetissement',
    'rapetisserais',
    'rapetisserait',
    'rapetissèrent',
    'rapetisseriez',
    'rapetisserons',
    'rapetisseront',
    'rapiéçassions',
    'rapiéceraient',
    'rapiécetaient',
    'rapiécetasses',
    'rapiécéterais',
    'rapiécéterait',
    'rapiécetèrent',
    'rapiécéteriez',
    'rapiécéterons',
    'rapiécéteront',
    'raplatiraient',
    'raplatissions',
    'rapointirions',
    'rapointissais',
    'rapointissait',
    'rapointissant',
    'rapointissent',
    'rapointissiez',
    'rapointissons',
    'rappareillais',
    'rappareillait',
    'rappareillant',
    'rappareillées',
    'rappareillent',
    'rappareillera',
    'rappareilliez',
    'rappareillons',
    'rappariassent',
    'rappariassiez',
    'rappariements',
    'rapparierions',
    'rappelassions',
    'rappellerions',
    'rappliquaient',
    'rappliquasses',
    'rappliquerais',
    'rappliquerait',
    'rappliquèrent',
    'rappliqueriez',
    'rappliquerons',
    'rappliqueront',
    'rapportassent',
    'rapportassiez',
    'rapporterions',
    'rapprendrions',
    'rapprêtassent',
    'rapprêtassiez',
    'rapprêterions',
    'rapprochaient',
    'rapprochantes',
    'rapprochasses',
    'rapprochement',
    'rapprocherais',
    'rapprocherait',
    'rapprochèrent',
    'rapprocheriez',
    'rapprocherons',
    'rapprocheront',
    'rappropriâmes',
    'rappropriasse',
    'rappropriâtes',
    'rapproprierai',
    'rapproprieras',
    'rapproprierez',
    'rappropriions',
    'raréfiassions',
    'raréfieraient',
    'rassasiassent',
    'rassasiassiez',
    'rassasiements',
    'rassasierions',
    'rassemblaient',
    'rassemblasses',
    'rassemblement',
    'rassemblerais',
    'rassemblerait',
    'rassemblèrent',
    'rassembleriez',
    'rassemblerons',
    'rassembleront',
    'rassembleuses',
    'rassérénaient',
    'rassérénasses',
    'rassérénerais',
    'rassérénerait',
    'rassérénèrent',
    'rasséréneriez',
    'rassérénerons',
    'rasséréneront',
    'rassortiments',
    'rassortirions',
    'rassortissais',
    'rassortissait',
    'rassortissant',
    'rassortissent',
    'rassortissiez',
    'rassortissons',
    'rassurassions',
    'rassureraient',
    'rastaquouères',
    'ratatinassent',
    'ratatinassiez',
    'ratatinerions',
    'râtelleraient',
    'ratiboisaient',
    'ratiboisasses',
    'ratiboiserais',
    'ratiboiserait',
    'ratiboisèrent',
    'ratiboiseriez',
    'ratiboiserons',
    'ratiboiseront',
    'ratifications',
    'ratifieraient',
    'ratiocinaient',
    'ratiocinasses',
    'ratiocination',
    'ratiocinerais',
    'ratiocinerait',
    'ratiocinèrent',
    'ratiocineriez',
    'ratiocinerons',
    'ratiocineront',
    'rationalisais',
    'rationalisait',
    'rationalisant',
    'rationalisées',
    'rationalisent',
    'rationalisera',
    'rationalisiez',
    'rationalismes',
    'rationalisons',
    'rationalistes',
    'rationnassent',
    'rationnassiez',
    'rationnements',
    'rationnerions',
    'ratissassions',
    'ratisseraient',
    'rattachassent',
    'rattachassiez',
    'rattachements',
    'rattacherions',
    'rattrapassent',
    'rattrapassiez',
    'rattraperions',
    'raugmentaient',
    'raugmentasses',
    'raugmenterais',
    'raugmenterait',
    'raugmentèrent',
    'raugmenteriez',
    'raugmenterons',
    'raugmenteront',
    'ravageassions',
    'ravaudassions',
    'ravauderaient',
    'ravigotassent',
    'ravigotassiez',
    'ravigoterions',
    'ravilissaient',
    'ravitaillâmes',
    'ravitaillasse',
    'ravitaillâtes',
    'ravitaillerai',
    'ravitailleras',
    'ravitaillerez',
    'ravitailleurs',
    'ravitaillions',
    'raviverassent',
    'raviverassiez',
    'rayonnassions',
    'rayonneraient',
    'razzierassent',
    'razzierassiez',
    'réabonnassent',
    'réabonnassiez',
    'réabonnements',
    'réabonnerâmes',
    'réabonnerasse',
    'réabonnerâtes',
    'réabsorbaient',
    'réabsorbasses',
    'réabsorberais',
    'réabsorberait',
    'réabsorbèrent',
    'réabsorberiez',
    'réabsorberons',
    'réabsorberont',
    'réabsorptions',
    'réaccoutumais',
    'réaccoutumait',
    'réaccoutumant',
    'réaccoutumées',
    'réaccoutument',
    'réaccoutumera',
    'réaccoutumiez',
    'réaccoutumons',
    'réactionnaire',
    'réactionnelle',
    'réactivassent',
    'réactivassiez',
    'réactivations',
    'réactiverions',
    'réactualisais',
    'réactualisait',
    'réactualisant',
    'réactualisées',
    'réactualisent',
    'réactualisera',
    'réactualisiez',
    'réactualisons',
    'réadaptassent',
    'réadaptassiez',
    'réadaptations',
    'réadapterions',
    'réadmettaient',
    'réadmettrions',
    'réaffectaient',
    'réaffectasses',
    'réaffectation',
    'réaffecterais',
    'réaffecterait',
    'réaffectèrent',
    'réaffecteriez',
    'réaffecterons',
    'réaffecteront',
    'réaffirmaient',
    'réaffirmasses',
    'réaffirmerais',
    'réaffirmerait',
    'réaffirmèrent',
    'réaffirmeriez',
    'réaffirmerons',
    'réaffirmeront',
    'réaffûtassent',
    'réaffûtassiez',
    'réaffûterions',
    'réajustassent',
    'réajustassiez',
    'réajustements',
    'réajusterions',
    'réalésassions',
    'réaléseraient',
    'réalignassent',
    'réalignassiez',
    'réalignerions',
    'réalisassions',
    'réalisatrices',
    'réaliseraient',
    'réaménageâmes',
    'réaménageasse',
    'réaménageâtes',
    'réaménagerais',
    'réaménagerait',
    'réaménagèrent',
    'réaménageriez',
    'réaménagerons',
    'réaménageront',
    'réamorçassent',
    'réamorçassiez',
    'réamorcerions',
    'réanimassions',
    'réanimeraient',
    'réapparaisses',
    'réapparaissez',
    'réapparaîtrai',
    'réapparaîtras',
    'réapparaîtrez',
    'réapparitions',
    'réapparussent',
    'réapparussiez',
    'réapprenaient',
    'réapprendrais',
    'réapprendrait',
    'réapprendriez',
    'réapprendrons',
    'réapprendront',
    'réapprissions',
    'réargentaient',
    'réargentasses',
    'réargenterais',
    'réargenterait',
    'réargentèrent',
    'réargenteriez',
    'réargenterons',
    'réargenteront',
    'réarrangeâmes',
    'réarrangeasse',
    'réarrangeâtes',
    'réarrangerais',
    'réarrangerait',
    'réarrangèrent',
    'réarrangeriez',
    'réarrangerons',
    'réarrangeront',
    'réassignaient',
    'réassignasses',
    'réassignerais',
    'réassignerait',
    'réassignèrent',
    'réassigneriez',
    'réassignerons',
    'réassigneront',
    'réassortiment',
    'réassortirais',
    'réassortirait',
    'réassortirent',
    'réassortiriez',
    'réassortirons',
    'réassortiront',
    'réassortisses',
    'réassortissez',
    'réassurassent',
    'réassurassiez',
    'réassurerions',
    'rebaissassent',
    'rebaissassiez',
    'rebaisserions',
    'rebandassions',
    'rebanderaient',
    'rebaptisaient',
    'rebaptisasses',
    'rebaptiserais',
    'rebaptiserait',
    'rebaptisèrent',
    'rebaptiseriez',
    'rebaptiserons',
    'rebaptiseront',
    'rebâtissaient',
    'rebattissions',
    'rebellassions',
    'rebelleraient',
    'rebiffassions',
    'rebifferaient',
    'rebiquassions',
    'rebiqueraient',
    'reblanchirais',
    'reblanchirait',
    'reblanchirent',
    'reblanchiriez',
    'reblanchirons',
    'reblanchiront',
    'reblanchisses',
    'reblanchissez',
    'reboisassions',
    'reboiseraient',
    'rebondiraient',
    'rebondissante',
    'rebondissants',
    'rebondissions',
    'rebordassions',
    'reborderaient',
    'rebouchassent',
    'rebouchassiez',
    'reboucherions',
    'reboutassions',
    'rebouteraient',
    'reboutonnâmes',
    'reboutonnasse',
    'reboutonnâtes',
    'reboutonnerai',
    'reboutonneras',
    'reboutonnerez',
    'reboutonnions',
    'rebrodassions',
    'rebroderaient',
    'rebroussaient',
    'rebroussasses',
    'rebroussement',
    'rebrousserais',
    'rebrousserait',
    'rebroussèrent',
    'rebrousseriez',
    'rebrousserons',
    'rebrousseront',
    'rebrûlassions',
    'rebrûleraient',
    'recachetaient',
    'recachetasses',
    'recachetèrent',
    'recachetterai',
    'recachetteras',
    'recachetterez',
    'recalcifiâmes',
    'recalcifiasse',
    'recalcifiâtes',
    'recalcifierai',
    'recalcifieras',
    'recalcifierez',
    'recalcifiions',
    'récalcitrante',
    'récalcitrants',
    'recalculaient',
    'recalculasses',
    'recalculerais',
    'recalculerait',
    'recalculèrent',
    'recalculeriez',
    'recalculerons',
    'recalculeront',
    'récapitulâmes',
    'récapitulasse',
    'récapitulâtes',
    'récapitulatif',
    'récapitulerai',
    'récapituleras',
    'récapitulerez',
    'récapitulions',
    'recardassions',
    'recarderaient',
    'recarrelaient',
    'recarrelasses',
    'recarrelèrent',
    'recarrellerai',
    'recarrelleras',
    'recarrellerez',
    'recausassions',
    'recauseraient',
    'recensassions',
    'recenseraient',
    'recentrassent',
    'recentrassiez',
    'recentrerions',
    'réceptionnais',
    'réceptionnait',
    'réceptionnant',
    'réceptionnées',
    'réceptionnent',
    'réceptionnera',
    'réceptionniez',
    'réceptionnons',
    'recerclassent',
    'recerclassiez',
    'recerclerions',
    'recevabilités',
    'rechampirions',
    'réchampirions',
    'rechampissage',
    'réchampissage',
    'rechampissais',
    'réchampissais',
    'rechampissait',
    'réchampissait',
    'rechampissant',
    'réchampissant',
    'rechampissent',
    'réchampissent',
    'rechampissiez',
    'réchampissiez',
    'rechampissons',
    'réchampissons',
    'rechangeaient',
    'rechangeasses',
    'rechangerions',
    'rechantassent',
    'rechantassiez',
    'rechanterions',
    'rechapassions',
    'rechaperaient',
    'réchappassent',
    'réchappassiez',
    'réchapperions',
    'rechargeables',
    'rechargeaient',
    'rechargeasses',
    'rechargerions',
    'rechassassent',
    'rechassassiez',
    'rechasserions',
    'réchauffaient',
    'réchauffantes',
    'réchauffasses',
    'réchauffement',
    'réchaufferais',
    'réchaufferait',
    'réchauffèrent',
    'réchaufferiez',
    'réchaufferons',
    'réchaufferont',
    'rechaussaient',
    'rechaussasses',
    'rechaussement',
    'rechausserais',
    'rechausserait',
    'rechaussèrent',
    'rechausseriez',
    'rechausserons',
    'rechausseront',
    'recherchaient',
    'recherchasses',
    'rechercherais',
    'rechercherait',
    'recherchèrent',
    'rechercheriez',
    'rechercherons',
    'rechercheront',
    'rechignassent',
    'rechignassiez',
    'rechignerions',
    'rechutassions',
    'rechuteraient',
    'récidivassent',
    'récidivassiez',
    'récidiverions',
    'récipiendaire',
    'réciproquâmes',
    'réciproquasse',
    'réciproquâtes',
    'réciproquerai',
    'réciproqueras',
    'réciproquerez',
    'réciproquions',
    'réclamassions',
    'réclameraient',
    'reclassassent',
    'reclassassiez',
    'reclassements',
    'reclasserions',
    'réclinassions',
    'réclineraient',
    'reclouassions',
    'recloueraient',
    'recoiffassent',
    'recoiffassiez',
    'recoifferions',
    'recollassions',
    'récollections',
    'recolleraient',
    'recolorassent',
    'recolorassiez',
    'recolorerions',
    'récoltassions',
    'récolteraient',
    'recombinaient',
    'recombinasses',
    'recombinerais',
    'recombinerait',
    'recombinèrent',
    'recombineriez',
    'recombinerons',
    'recombineront',
    'recommandable',
    'recommandâmes',
    'recommandasse',
    'recommandâtes',
    'recommanderai',
    'recommanderas',
    'recommanderez',
    'recommandions',
    'recommençâmes',
    'recommençasse',
    'recommençâtes',
    'recommencerai',
    'recommenceras',
    'recommencerez',
    'recommencions',
    'recomparaisse',
    'recomparaîtra',
    'recomparaître',
    'recomparurent',
    'recomparusses',
    'récompensâmes',
    'récompensasse',
    'récompensâtes',
    'récompenserai',
    'récompenseras',
    'récompenserez',
    'récompensions',
    'recompilaient',
    'recompilasses',
    'recompilerais',
    'recompilerait',
    'recompilèrent',
    'recompileriez',
    'recompilerons',
    'recompileront',
    'recomposables',
    'recomposaient',
    'recomposasses',
    'recomposerais',
    'recomposerait',
    'recomposèrent',
    'recomposeriez',
    'recomposerons',
    'recomposeront',
    'recomptassent',
    'recomptassiez',
    'recompterions',
    'réconciliâmes',
    'réconciliasse',
    'réconciliâtes',
    'réconcilierai',
    'réconcilieras',
    'réconcilierez',
    'réconciliions',
    'recondamnâmes',
    'recondamnasse',
    'recondamnâtes',
    'recondamnerai',
    'recondamneras',
    'recondamnerez',
    'recondamnions',
    'reconductible',
    'reconductions',
    'reconduirions',
    'reconduisîmes',
    'reconduisions',
    'reconduisisse',
    'reconduisîtes',
    'réconfortâmes',
    'réconfortante',
    'réconfortants',
    'réconfortasse',
    'réconfortâtes',
    'réconforterai',
    'réconforteras',
    'réconforterez',
    'réconfortions',
    'recongelaient',
    'recongelasses',
    'recongèlerais',
    'recongèlerait',
    'recongelèrent',
    'recongèleriez',
    'recongèlerons',
    'recongèleront',
    'reconnaissais',
    'reconnaissait',
    'reconnaissant',
    'reconnaissent',
    'reconnaissiez',
    'reconnaissons',
    'reconnaîtrais',
    'reconnaîtrait',
    'reconnaîtriez',
    'reconnaîtrons',
    'reconnaîtront',
    'reconnectâmes',
    'reconnectasse',
    'reconnectâtes',
    'reconnecterai',
    'reconnecteras',
    'reconnecterez',
    'reconnections',
    'reconnussions',
    'reconquérante',
    'reconquérants',
    'reconquérions',
    'reconquerrais',
    'reconquerrait',
    'reconquerriez',
    'reconquerrons',
    'reconquerront',
    'reconquièrent',
    'reconquissent',
    'reconquissiez',
    'reconsidérais',
    'reconsidérait',
    'reconsidérant',
    'reconsidérées',
    'reconsidèrent',
    'reconsidérera',
    'reconsidériez',
    'reconsidérons',
    'reconsolidais',
    'reconsolidait',
    'reconsolidant',
    'reconsolidées',
    'reconsolident',
    'reconsolidera',
    'reconsolidiez',
    'reconsolidons',
    'reconstituais',
    'reconstituait',
    'reconstituant',
    'reconstituées',
    'reconstituent',
    'reconstituera',
    'reconstituiez',
    'reconstituons',
    'reconstruirai',
    'reconstruiras',
    'reconstruirez',
    'reconstruises',
    'reconstruisez',
    'reconstruisis',
    'reconstruisit',
    'reconstruisît',
    'reconstruites',
    'reconversions',
    'reconvertîmes',
    'reconvertirai',
    'reconvertiras',
    'reconvertirez',
    'reconvertisse',
    'reconvertîtes',
    'recopiassions',
    'recopieraient',
    'recoquillâmes',
    'recoquillasse',
    'recoquillâtes',
    'recoquillerai',
    'recoquilleras',
    'recoquillerez',
    'recoquillions',
    'recordassions',
    'recorderaient',
    'recorrigeâmes',
    'recorrigeasse',
    'recorrigeâtes',
    'recorrigerais',
    'recorrigerait',
    'recorrigèrent',
    'recorrigeriez',
    'recorrigerons',
    'recorrigeront',
    'recouchassent',
    'recouchassiez',
    'recoucherâmes',
    'recoucherasse',
    'recoucherâtes',
    'recoupassions',
    'recouperaient',
    'recourbassent',
    'recourbassiez',
    'recourberions',
    'recourussions',
    'recousissions',
    'recouvrassent',
    'recouvrassiez',
    'recouvrements',
    'recouvrerions',
    'recouvririons',
    'recouvrissent',
    'recouvrissiez',
    'recrachassent',
    'recrachassiez',
    'recracherions',
    'recrépiraient',
    'recrépissions',
    'recreusassent',
    'recreusassiez',
    'recreuserions',
    'récriminaient',
    'récriminasses',
    'récriminateur',
    'récrimination',
    'récriminerais',
    'récriminerait',
    'récriminèrent',
    'récrimineriez',
    'récriminerons',
    'récrimineront',
    'recristallisa',
    'recristallise',
    'recristallisé',
    'récrivissions',
    'recroiserions',
    'recroissaient',
    'recroissantes',
    'recroîtraient',
    'recroquevilla',
    'recroqueville',
    'recroquevillé',
    'recrudescence',
    'recrudescente',
    'recrudescents',
    'recrutassions',
    'recruteraient',
    'rectangulaire',
    'rectifiassent',
    'rectifiassiez',
    'rectificateur',
    'rectificatifs',
    'rectification',
    'rectificative',
    'rectifierions',
    'rectilinéaire',
    'recueillaient',
    'recueillement',
    'recueillerais',
    'recueillerait',
    'recueilleriez',
    'recueillerons',
    'recueilleront',
    'recueillirent',
    'recueillisses',
    'recuisissions',
    'reculottaient',
    'reculottasses',
    'reculotterais',
    'reculotterait',
    'reculottèrent',
    'reculotteriez',
    'reculotterons',
    'reculotteront',
    'récupérassent',
    'récupérassiez',
    'récupérateurs',
    'récupérations',
    'récupératrice',
    'récupérerions',
    'récursivement',
    'recyclassions',
    'recycleraient',
    'rédactionnels',
    'redéfaisaient',
    'redéfinirions',
    'redéfinissais',
    'redéfinissait',
    'redéfinissant',
    'redéfinissent',
    'redéfinissiez',
    'redéfinissons',
    'redéfinitions',
    'redemandaient',
    'redemandasses',
    'redemanderais',
    'redemanderait',
    'redemandèrent',
    'redemanderiez',
    'redemanderons',
    'redemanderont',
    'redémarraient',
    'redémarrasses',
    'redémarrerais',
    'redémarrerait',
    'redémarrèrent',
    'redémarreriez',
    'redémarrerons',
    'redémarreront',
    'redémolirions',
    'redémolissais',
    'redémolissait',
    'redémolissant',
    'redémolissent',
    'redémolissiez',
    'redémolissons',
    'redémontrâmes',
    'redémontrasse',
    'redémontrâtes',
    'redémontrerai',
    'redémontreras',
    'redémontrerez',
    'redémontrions',
    'rédemptoriste',
    'redéploierais',
    'redéploierait',
    'redéploieriez',
    'redéploierons',
    'redéploieront',
    'redéployaient',
    'redéployasses',
    'redéployèrent',
    'redescendîmes',
    'redescendions',
    'redescendisse',
    'redescendîtes',
    'redescendrais',
    'redescendrait',
    'redescendriez',
    'redescendrons',
    'redescendront',
    'redeviendrais',
    'redeviendrait',
    'redeviendriez',
    'redeviendrons',
    'redeviendront',
    'redevinssions',
    'rédhibitoires',
    'rédigeassions',
    'rediscutaient',
    'rediscutasses',
    'rediscuterais',
    'rediscuterait',
    'rediscutèrent',
    'rediscuteriez',
    'rediscuterons',
    'rediscuteront',
    'redistribuais',
    'redistribuait',
    'redistribuant',
    'redistribuées',
    'redistribuent',
    'redistribuera',
    'redistribuiez',
    'redistribuons',
    'redondassions',
    'redonderaient',
    'redonnassions',
    'redonneraient',
    'redoublassent',
    'redoublassiez',
    'redoublements',
    'redoublerions',
    'redoutassions',
    'redouteraient',
    'redressassent',
    'redressassiez',
    'redressements',
    'redresserions',
    'réductibilité',
    'réduisissions',
    'réduplicatifs',
    'réduplication',
    'réduplicative',
    'réécoutassent',
    'réécoutassiez',
    'réécouterions',
    'réédifiassent',
    'réédifiassiez',
    'réédification',
    'réédifierions',
    'rééditassions',
    'rééditeraient',
    'rééduquassent',
    'rééduquassiez',
    'rééduquerions',
    'réembauchâmes',
    'réembauchasse',
    'réembauchâtes',
    'réembaucherai',
    'réembaucheras',
    'réembaucherez',
    'réembauchions',
    'réemploierais',
    'réemploierait',
    'réemploieriez',
    'réemploierons',
    'réemploieront',
    'réemployaient',
    'réemployasses',
    'réemployèrent',
    'réempruntâmes',
    'réempruntasse',
    'réempruntâtes',
    'réemprunterai',
    'réemprunteras',
    'réemprunterez',
    'réempruntions',
    'réengageaient',
    'réengageasses',
    'réengagements',
    'réengagerions',
    'réenregistrai',
    'réenregistras',
    'réenregistrât',
    'réenregistrée',
    'réenregistrer',
    'réenregistres',
    'réenregistrés',
    'réenregistrez',
    'réensemençais',
    'réensemençait',
    'réensemençant',
    'réensemencées',
    'réensemencent',
    'réensemencera',
    'réensemenciez',
    'réensemençons',
    'rééquilibrage',
    'rééquilibrais',
    'rééquilibrait',
    'rééquilibrant',
    'rééquilibrées',
    'rééquilibrent',
    'rééquilibrera',
    'rééquilibriez',
    'rééquilibrons',
    'rééquipements',
    'réescomptâmes',
    'réescomptasse',
    'réescomptâtes',
    'réescompterai',
    'réescompteras',
    'réescompterez',
    'réescomptions',
    'réessaierions',
    'réessayassent',
    'réessayassiez',
    'réessayerions',
    'réévaluassent',
    'réévaluassiez',
    'réévaluations',
    'réévaluerions',
    'réexaminaient',
    'réexaminasses',
    'réexaminerais',
    'réexaminerait',
    'réexaminèrent',
    'réexamineriez',
    'réexaminerons',
    'réexamineront',
    'réexpédiaient',
    'réexpédiasses',
    'réexpédierais',
    'réexpédierait',
    'réexpédièrent',
    'réexpédieriez',
    'réexpédierons',
    'réexpédieront',
    'réexpéditions',
    'réexportaient',
    'réexportasses',
    'réexporterais',
    'réexporterait',
    'réexportèrent',
    'réexporteriez',
    'réexporterons',
    'réexporteront',
    'refaçonnaient',
    'refaçonnasses',
    'refaçonnerais',
    'refaçonnerait',
    'refaçonnèrent',
    'refaçonneriez',
    'refaçonnerons',
    'refaçonneront',
    'refendissions',
    'référençaient',
    'référençasses',
    'référencerais',
    'référencerait',
    'référencèrent',
    'référenceriez',
    'référencerons',
    'référenceront',
    'référendaires',
    'référentielle',
    'refermassions',
    'refermeraient',
    'réfléchirions',
    'réfléchissais',
    'réfléchissait',
    'réfléchissant',
    'réfléchissent',
    'réfléchissiez',
    'réfléchissons',
    'réflectivités',
    'reflétassions',
    'refléteraient',
    'refleuririons',
    'refleurissais',
    'refleurissait',
    'refleurissant',
    'refleurissent',
    'refleurissiez',
    'refleurissons',
    'réflexivement',
    'réflexogramme',
    'réflexologies',
    'refondissions',
    'reforgeassent',
    'reforgeassiez',
    'reforgeraient',
    'reformassions',
    'réformassions',
    'réformatrices',
    'reformeraient',
    'réformeraient',
    'reformulaient',
    'reformulasses',
    'reformulerais',
    'reformulerait',
    'reformulèrent',
    'reformuleriez',
    'reformulerons',
    'reformuleront',
    'refouillaient',
    'refouillasses',
    'refouillement',
    'refouillerais',
    'refouillerait',
    'refouillèrent',
    'refouilleriez',
    'refouillerons',
    'refouilleront',
    'refoulassions',
    'refouleraient',
    'refourrassent',
    'refourrassiez',
    'refourrerions',
    'réfractassent',
    'réfractassiez',
    'réfracterions',
    'refrénassions',
    'réfrénassions',
    'refréneraient',
    'réfréneraient',
    'réfrigéraient',
    'réfrigérantes',
    'réfrigérasses',
    'réfrigérateur',
    'réfrigération',
    'réfrigérerais',
    'réfrigérerait',
    'réfrigérèrent',
    'réfrigéreriez',
    'réfrigérerons',
    'réfrigéreront',
    'refroidirions',
    'refroidissais',
    'refroidissait',
    'refroidissant',
    'refroidissent',
    'refroidisseur',
    'refroidissiez',
    'refroidissons',
    'réfugiassions',
    'réfugieraient',
    'regagnassions',
    'regagneraient',
    'regardassions',
    'regarderaient',
    'regarniraient',
    'regarnissions',
    'regazonnaient',
    'regazonnasses',
    'regazonnerais',
    'regazonnerait',
    'regazonnèrent',
    'regazonneriez',
    'regazonnerons',
    'regazonneront',
    'régénérassent',
    'régénérassiez',
    'régénérateurs',
    'régénérations',
    'régénératrice',
    'régénérerions',
    'régentassions',
    'régenteraient',
    'regimbassions',
    'regimberaient',
    'régimentaires',
    'régionalisais',
    'régionalisait',
    'régionalisant',
    'régionalisées',
    'régionalisent',
    'régionalisera',
    'régionalisiez',
    'régionalismes',
    'régionalisons',
    'régionalistes',
    'registrassent',
    'registrassiez',
    'registrerions',
    'réglementaire',
    'réglementâmes',
    'réglementasse',
    'réglementâtes',
    'réglementerai',
    'réglementeras',
    'réglementerez',
    'réglementions',
    'regonflassent',
    'regonflassiez',
    'regonflerions',
    'regorgeassent',
    'regorgeassiez',
    'regorgeraient',
    'regrattassent',
    'regrattassiez',
    'regratterions',
    'regreffassent',
    'regreffassiez',
    'regrefferions',
    'régressassent',
    'régressassiez',
    'régresserions',
    'regrettassent',
    'regrettassiez',
    'regretterions',
    'regrimpassent',
    'regrimpassiez',
    'regrimperions',
    'regrossirions',
    'regrossissais',
    'regrossissait',
    'regrossissant',
    'regrossissent',
    'regrossissiez',
    'regrossissons',
    'regroupassent',
    'regroupassiez',
    'regroupements',
    'regrouperions',
    'régularisâmes',
    'régularisante',
    'régularisants',
    'régularisasse',
    'régularisâtes',
    'régulariserai',
    'régulariseras',
    'régulariserez',
    'régularisions',
    'régulièrement',
    'régurgitaient',
    'régurgitasses',
    'régurgitation',
    'régurgiterais',
    'régurgiterait',
    'régurgitèrent',
    'régurgiteriez',
    'régurgiterons',
    'régurgiteront',
    'réhabilitable',
    'réhabilitâmes',
    'réhabilitasse',
    'réhabilitâtes',
    'réhabiliterai',
    'réhabiliteras',
    'réhabiliterez',
    'réhabilitions',
    'réhabituaient',
    'réhabituasses',
    'réhabituerais',
    'réhabituerait',
    'réhabituèrent',
    'réhabitueriez',
    'réhabituerons',
    'réhabitueront',
    'rehaussassent',
    'rehaussassiez',
    'rehaussements',
    'rehausserions',
    'réhydrataient',
    'réhydratasses',
    'réhydraterais',
    'réhydraterait',
    'réhydratèrent',
    'réhydrateriez',
    'réhydraterons',
    'réhydrateront',
    'réimplantâmes',
    'réimplantasse',
    'réimplantâtes',
    'réimplanterai',
    'réimplanteras',
    'réimplanterez',
    'réimplantions',
    'réimportaient',
    'réimportasses',
    'réimporterais',
    'réimporterait',
    'réimportèrent',
    'réimporteriez',
    'réimporterons',
    'réimporteront',
    'réimposassent',
    'réimposassiez',
    'réimposerions',
    'réimpositions',
    'réimpressions',
    'réimprimaient',
    'réimprimasses',
    'réimprimerais',
    'réimprimerait',
    'réimprimèrent',
    'réimprimeriez',
    'réimprimerons',
    'réimprimeront',
    'réincarcérais',
    'réincarcérait',
    'réincarcérant',
    'réincarcérées',
    'réincarcèrent',
    'réincarcérera',
    'réincarcériez',
    'réincarcérons',
    'réincarnaient',
    'réincarnasses',
    'réincarnation',
    'réincarnerais',
    'réincarnerait',
    'réincarnèrent',
    'réincarneriez',
    'réincarnerons',
    'réincarneront',
    'réincorporais',
    'réincorporait',
    'réincorporant',
    'réincorporées',
    'réincorporent',
    'réincorporera',
    'réincorporiez',
    'réincorporons',
    'réinfectaient',
    'réinfectasses',
    'réinfecterais',
    'réinfecterait',
    'réinfectèrent',
    'réinfecteriez',
    'réinfecterons',
    'réinfecteront',
    'réinitialisai',
    'réinitialisas',
    'réinitialisât',
    'réinitialisée',
    'réinitialiser',
    'réinitialises',
    'réinitialisés',
    'réinitialisez',
    'réinjectaient',
    'réinjectasses',
    'réinjecterais',
    'réinjecterait',
    'réinjectèrent',
    'réinjecteriez',
    'réinjecterons',
    'réinjecteront',
    'réinscririons',
    'réinscrivîmes',
    'réinscrivions',
    'réinscrivisse',
    'réinscrivîtes',
    'réinsérassent',
    'réinsérassiez',
    'réinsérerions',
    'réinstallâmes',
    'réinstallasse',
    'réinstallâtes',
    'réinstallerai',
    'réinstalleras',
    'réinstallerez',
    'réinstallions',
    'réintégrables',
    'réintégraient',
    'réintégrasses',
    'réintégration',
    'réintégrerais',
    'réintégrerait',
    'réintégrèrent',
    'réintégreriez',
    'réintégrerons',
    'réintégreront',
    'réinterprétai',
    'réinterprétas',
    'réinterprétât',
    'réinterprétée',
    'réinterpréter',
    'réinterprétés',
    'réinterprètes',
    'réinterprétez',
    'réintroduirai',
    'réintroduiras',
    'réintroduirez',
    'réintroduises',
    'réintroduisez',
    'réintroduisis',
    'réintroduisit',
    'réintroduisît',
    'réintroduites',
    'réinventaient',
    'réinventasses',
    'réinventerais',
    'réinventerait',
    'réinventèrent',
    'réinventeriez',
    'réinventerons',
    'réinventeront',
    'réinvestirais',
    'réinvestirait',
    'réinvestirent',
    'réinvestiriez',
    'réinvestirons',
    'réinvestiront',
    'réinvestisses',
    'réinvestissez',
    'réinvitassent',
    'réinvitassiez',
    'réinviterions',
    'réitérassions',
    'réitéreraient',
    'rejaillirions',
    'rejaillissais',
    'rejaillissait',
    'rejaillissant',
    'rejaillissent',
    'rejaillissiez',
    'rejaillissons',
    'rejetteraient',
    'rejoignissent',
    'rejoignissiez',
    'rejoindraient',
    'rejointoierai',
    'rejointoieras',
    'rejointoierez',
    'rejointoyâmes',
    'rejointoyasse',
    'rejointoyâtes',
    'rejointoyions',
    'réjouissaient',
    'réjouissances',
    'réjouissantes',
    'rejugeassions',
    'relâchassions',
    'relâcheraient',
    'relaissassent',
    'relaissassiez',
    'relaisserions',
    'relançassions',
    'relanceraient',
    'rélargiraient',
    'rélargissions',
    'relationnelle',
    'relativisâmes',
    'relativisante',
    'relativisants',
    'relativisasse',
    'relativisâtes',
    'relativiserai',
    'relativiseras',
    'relativiserez',
    'relativisions',
    'reléguassions',
    'relégueraient',
    'religionnaire',
    'relogeassions',
    'reluisissions',
    'reluquassions',
    'reluqueraient',
    'remâchassions',
    'remâcheraient',
    'remaillassent',
    'remaillassiez',
    'remaillerions',
    'remangeassent',
    'remangeassiez',
    'remangeraient',
    'remaniassions',
    'remanieraient',
    'remaquillâmes',
    'remaquillasse',
    'remaquillâtes',
    'remaquillerai',
    'remaquilleras',
    'remaquillerez',
    'remaquillions',
    'remarchassent',
    'remarchassiez',
    'remarcherions',
    'remariassions',
    'remarieraient',
    'remarquassent',
    'remarquassiez',
    'remarquerions',
    'remastiquâmes',
    'remastiquasse',
    'remastiquâtes',
    'remastiquerai',
    'remastiqueras',
    'remastiquerez',
    'remastiquions',
    'remballassent',
    'remballassiez',
    'remballerions',
    'rembarquaient',
    'rembarquasses',
    'rembarquement',
    'rembarquerais',
    'rembarquerait',
    'rembarquèrent',
    'rembarqueriez',
    'rembarquerons',
    'rembarqueront',
    'rembarrassent',
    'rembarrassiez',
    'rembarrerions',
    'rembinassions',
    'rembineraient',
    'remblaiements',
    'remblaierions',
    'remblavassent',
    'remblavassiez',
    'remblaverions',
    'remblayassent',
    'remblayassiez',
    'remblayerions',
    'remboîtassent',
    'remboîtassiez',
    'remboîtements',
    'remboîterions',
    'rembougeaient',
    'rembougeasses',
    'rembougerions',
    'rembourraient',
    'rembourrasses',
    'rembourrerais',
    'rembourrerait',
    'rembourrèrent',
    'rembourreriez',
    'rembourrerons',
    'rembourreront',
    'remboursables',
    'remboursaient',
    'remboursasses',
    'remboursement',
    'rembourserais',
    'rembourserait',
    'remboursèrent',
    'rembourseriez',
    'rembourserons',
    'rembourseront',
    'rembrunirions',
    'rembrunissais',
    'rembrunissait',
    'rembrunissant',
    'rembrunissent',
    'rembrunissiez',
    'rembrunissons',
    'rembuchassent',
    'rembuchassiez',
    'rembucherions',
    'remédiassions',
    'remédieraient',
    'remembrassent',
    'remembrassiez',
    'remembrements',
    'remembrerions',
    'remémorassent',
    'remémorassiez',
    'remémorations',
    'remémorerions',
    'remerciassent',
    'remerciassiez',
    'remerciements',
    'remercierions',
    'remeublassent',
    'remeublassiez',
    'remeublerions',
    'remilitarisai',
    'remilitarisas',
    'remilitarisât',
    'remilitarisée',
    'remilitariser',
    'remilitarises',
    'remilitarisés',
    'remilitarisez',
    'réminiscences',
    'remmaillaient',
    'remmaillasses',
    'remmaillerais',
    'remmaillerait',
    'remmaillèrent',
    'remmailleriez',
    'remmaillerons',
    'remmailleront',
    'remmailleuses',
    'remmaillotais',
    'remmaillotait',
    'remmaillotant',
    'remmaillotées',
    'remmaillotent',
    'remmaillotera',
    'remmaillotiez',
    'remmaillotons',
    'remmanchaient',
    'remmanchasses',
    'remmancherais',
    'remmancherait',
    'remmanchèrent',
    'remmancheriez',
    'remmancherons',
    'remmancheront',
    'remmenassions',
    'remmèneraient',
    'remodelassent',
    'remodelassiez',
    'remodèlerions',
    'remontassions',
    'remonte-pente',
    'remonteraient',
    'remontrassent',
    'remontrassiez',
    'remontrerions',
    'remordissions',
    'remorquassent',
    'remorquassiez',
    'remorquerions',
    'remouchassent',
    'remouchassiez',
    'remoucherions',
    'remouillaient',
    'remouillasses',
    'remouillerais',
    'remouillerait',
    'remouillèrent',
    'remouilleriez',
    'remouillerons',
    'remouilleront',
    'remoulussions',
    'rempaillaient',
    'rempaillasses',
    'rempaillèrent',
    'rempailleuses',
    'rempaquetâmes',
    'rempaquetasse',
    'rempaquetâtes',
    'rempaquetions',
    'rempaquettent',
    'rempaquettera',
    'remparassions',
    'rempareraient',
    'rempiétassent',
    'rempiétassiez',
    'rempiéterions',
    'rempilassions',
    'rempileraient',
    'remplaçassent',
    'remplaçassiez',
    'remplacements',
    'remplacerions',
    'rempliassions',
    'remplieraient',
    'remplissaient',
    'remplisseuses',
    'remploierions',
    'remployassent',
    'remployassiez',
    'remplumassent',
    'remplumassiez',
    'remplumerions',
    'rempochassent',
    'rempochassiez',
    'rempocherions',
    'rempoissonnai',
    'rempoissonnas',
    'rempoissonnât',
    'rempoissonnée',
    'rempoissonner',
    'rempoissonnes',
    'rempoissonnés',
    'rempoissonnez',
    'remportassent',
    'remportassiez',
    'remporterions',
    'rempotassions',
    'rempoteraient',
    'rempruntaient',
    'rempruntasses',
    'remprunterais',
    'remprunterait',
    'rempruntèrent',
    'remprunteriez',
    'remprunterons',
    'remprunteront',
    'rémunérassent',
    'rémunérassiez',
    'rémunérateurs',
    'rémunérations',
    'rémunératoire',
    'rémunératrice',
    'rémunérerions',
    'renaclassions',
    'renâcleraient',
    'renaquissions',
    'renardassions',
    'renarderaient',
    'renaudassions',
    'renauderaient',
    'rencaissaient',
    'rencaissasses',
    'rencaissement',
    'rencaisserais',
    'rencaisserait',
    'rencaissèrent',
    'rencaisseriez',
    'rencaisserons',
    'rencaisseront',
    'rencardassent',
    'rencardassiez',
    'rencarderions',
    'renchaînaient',
    'renchaînasses',
    'renchaînerais',
    'renchaînerait',
    'renchaînèrent',
    'renchaîneriez',
    'renchaînerons',
    'renchaîneront',
    'renchéririons',
    'renchérissais',
    'renchérissait',
    'renchérissant',
    'renchérissent',
    'renchérisseur',
    'renchérissiez',
    'renchérissons',
    'rencognassent',
    'rencognassiez',
    'rencognerions',
    'rencontraient',
    'rencontrasses',
    'rencontrerais',
    'rencontrerait',
    'rencontrèrent',
    'rencontreriez',
    'rencontrerons',
    'rencontreront',
    'rendormirions',
    'rendormissent',
    'rendormissiez',
    'rendossassent',
    'rendossassiez',
    'rendosserions',
    'renégociaient',
    'renégociasses',
    'renégocierais',
    'renégocierait',
    'renégocièrent',
    'renégocieriez',
    'renégocierons',
    'renégocieront',
    'renfaîtassent',
    'renfaîtassiez',
    'renfaîterions',
    'renfermassent',
    'renfermassiez',
    'renfermements',
    'renfermerions',
    'renfilassions',
    'renfileraient',
    'renflammaient',
    'renflammasses',
    'renflammerais',
    'renflammerait',
    'renflammèrent',
    'renflammeriez',
    'renflammerons',
    'renflammeront',
    'renflouassent',
    'renflouassiez',
    'renflouements',
    'renflouerions',
    'renfonçassent',
    'renfonçassiez',
    'renfoncements',
    'renfoncerions',
    'renforçassent',
    'renforçassiez',
    'renforcements',
    'renforcerions',
    'renformirâmes',
    'renformirasse',
    'renformirâtes',
    'renformissais',
    'renformissait',
    'renformissant',
    'renformissiez',
    'renformissons',
    'renfrognaient',
    'renfrognasses',
    'renfrognement',
    'renfrognerais',
    'renfrognerait',
    'renfrognèrent',
    'renfrogneriez',
    'renfrognerons',
    'renfrogneront',
    'rengageassent',
    'rengageassiez',
    'rengageraient',
    'rengainassent',
    'rengainassiez',
    'rengainerions',
    'rengorgeaient',
    'rengorgeasses',
    'rengorgements',
    'rengorgerions',
    'rengraciaient',
    'rengraciasses',
    'rengracierais',
    'rengracierait',
    'rengracièrent',
    'rengracieriez',
    'rengracierons',
    'rengracieront',
    'rengraissâmes',
    'rengraissasse',
    'rengraissâtes',
    'rengraisserai',
    'rengraisseras',
    'rengraisserez',
    'rengraissions',
    'rengrenassent',
    'rengrénassent',
    'rengrenassiez',
    'rengrénassiez',
    'rengrénerions',
    'rengrènerions',
    'reniflassions',
    'renifleraient',
    'renommassions',
    'renommeraient',
    'renonçassions',
    'renonceraient',
    'renonciataire',
    'renonciateurs',
    'renonciations',
    'renonciatrice',
    'renouvelables',
    'renouvelaient',
    'renouvelantes',
    'renouvelasses',
    'renouvelèrent',
    'renouvellerai',
    'renouvelleras',
    'renouvellerez',
    'renquillaient',
    'renquillasses',
    'renquillerais',
    'renquillerait',
    'renquillèrent',
    'renquilleriez',
    'renquillerons',
    'renquilleront',
    'renseignaient',
    'renseignasses',
    'renseignement',
    'renseignerais',
    'renseignerait',
    'renseignèrent',
    'renseigneriez',
    'renseignerons',
    'renseigneront',
    'rentabilisais',
    'rentabilisait',
    'rentabilisant',
    'rentabilisées',
    'rentabilisent',
    'rentabilisera',
    'rentabilisiez',
    'rentabilisons',
    'rentamassions',
    'rentameraient',
    'rentoilassent',
    'rentoilassiez',
    'rentoilerions',
    'rentrouvertes',
    'rentrouvrîmes',
    'rentrouvrions',
    'rentrouvrirai',
    'rentrouvriras',
    'rentrouvrirez',
    'rentrouvrisse',
    'rentrouvrîtes',
    'renveloppâmes',
    'renveloppasse',
    'renveloppâtes',
    'renvelopperai',
    'renvelopperas',
    'renvelopperez',
    'renveloppions',
    'renvenimaient',
    'renvenimasses',
    'renvenimerais',
    'renvenimerait',
    'renvenimèrent',
    'renvenimeriez',
    'renvenimerons',
    'renvenimeront',
    'renvergeaient',
    'renvergeasses',
    'renvergerions',
    'renversassent',
    'renversassiez',
    'renversements',
    'renverserions',
    'renvidassions',
    'renvideraient',
    'renvoyassions',
    'réoccupassent',
    'réoccupassiez',
    'réoccupations',
    'réoccuperions',
    'réopérassions',
    'réopéreraient',
    'réorchestrais',
    'réorchestrait',
    'réorchestrant',
    'réorchestrées',
    'réorchestrent',
    'réorchestrera',
    'réorchestriez',
    'réorchestrons',
    'réordonnaient',
    'réordonnançai',
    'réordonnanças',
    'réordonnançât',
    'réordonnancée',
    'réordonnancer',
    'réordonnances',
    'réordonnancés',
    'réordonnancez',
    'réordonnasses',
    'réordonnerais',
    'réordonnerait',
    'réordonnèrent',
    'réordonneriez',
    'réordonnerons',
    'réordonneront',
    'réorganisâmes',
    'réorganisasse',
    'réorganisâtes',
    'réorganiserai',
    'réorganiseras',
    'réorganiserez',
    'réorganisions',
    'réorientaient',
    'réorientasses',
    'réorientation',
    'réorienterais',
    'réorienterait',
    'réorientèrent',
    'réorienteriez',
    'réorienterons',
    'réorienteront',
    'repairassions',
    'repaireraient',
    'répandissions',
    'reparaissions',
    'reparaîtrions',
    'reparlassions',
    'reparleraient',
    'repartageâmes',
    'repartageasse',
    'repartageâtes',
    'repartagerais',
    'repartagerait',
    'repartagèrent',
    'repartageriez',
    'repartagerons',
    'repartageront',
    'repartiraient',
    'répartiraient',
    'répartissable',
    'repartissions',
    'répartissions',
    'repassassions',
    'repasseraient',
    'repatinassent',
    'repatinassiez',
    'repatinerions',
    'repêchassions',
    'repêcheraient',
    'repeignassent',
    'repeignassiez',
    'repeignerions',
    'repeignissent',
    'repeignissiez',
    'repeindraient',
    'rependissions',
    'repensassions',
    'repenseraient',
    'repentiraient',
    'repentissions',
    'reperçassions',
    'reperceraient',
    'répercussions',
    'répercutaient',
    'répercutasses',
    'répercuterais',
    'répercuterait',
    'répercutèrent',
    'répercuteriez',
    'répercuterons',
    'répercuteront',
    'reperdissions',
    'répertoriâmes',
    'répertoriasse',
    'répertoriâtes',
    'répertorierai',
    'répertorieras',
    'répertorierez',
    'répertoriions',
    'répétabilités',
    'repeuplassent',
    'repeuplassiez',
    'repeuplements',
    'repeuplerions',
    'repinçassions',
    'repinceraient',
    'repiquassions',
    'repiqueraient',
    'replaçassions',
    'replaceraient',
    'replantassent',
    'replantassiez',
    'replanterions',
    'replâtrassent',
    'replâtrassiez',
    'replâtrerions',
    'repleuvraient',
    'répliquassent',
    'répliquassiez',
    'répliquerions',
    'replissassent',
    'replissassiez',
    'replisserions',
    'reploieraient',
    'replongeaient',
    'replongeasses',
    'replongerions',
    'reployassions',
    'repolissaient',
    'répondissions',
    'reportassions',
    'reporteraient',
    'repositionnai',
    'repositionnas',
    'repositionnât',
    'repositionnée',
    'repositionner',
    'repositionnes',
    'repositionnés',
    'repositionnez',
    'repoussassent',
    'repoussassiez',
    'repoussements',
    'repousserions',
    'répréhensible',
    'répréhensions',
    'reprendraient',
    'représentable',
    'représentâmes',
    'représentante',
    'représentants',
    'représentasse',
    'représentâtes',
    'représentatif',
    'représenterai',
    'représenteras',
    'représenterez',
    'représentions',
    'réprimandâmes',
    'réprimandasse',
    'réprimandâtes',
    'réprimanderai',
    'réprimanderas',
    'réprimanderez',
    'réprimandions',
    'réprimassions',
    'réprimeraient',
    'reprisassions',
    'repriseraient',
    'réprobatrices',
    'reprochassent',
    'reprochassiez',
    'reprocherions',
    'reproducteurs',
    'reproductions',
    'reproductives',
    'reproductrice',
    'reproduirions',
    'reproduisîmes',
    'reproduisions',
    'reproduisisse',
    'reproduisîtes',
    'reprogrammais',
    'reprogrammait',
    'reprogrammant',
    'reprogrammées',
    'reprogramment',
    'reprogrammera',
    'reprogrammiez',
    'reprogrammons',
    'reprographiai',
    'reprographias',
    'reprographiât',
    'reprographiée',
    'reprographier',
    'reprographies',
    'reprographiés',
    'reprographiez',
    'reprouvassent',
    'réprouvassent',
    'reprouvassiez',
    'réprouvassiez',
    'reprouverions',
    'réprouverions',
    'républicaines',
    'républicanisa',
    'républicanise',
    'républicanisé',
    'répudiassions',
    'répudieraient',
    'répugnassions',
    'répugneraient',
    'requinquaient',
    'requinquasses',
    'requinquerais',
    'requinquerait',
    'requinquèrent',
    'requinqueriez',
    'requinquerons',
    'requinqueront',
    'réquisitionna',
    'réquisitionne',
    'réquisitionné',
    'réquisitoires',
    'réquisitorial',
    'reroisassions',
    'resalissaient',
    'resaluassions',
    'resalueraient',
    'rescindassent',
    'rescindassiez',
    'rescinderions',
    'reséquassions',
    'reséqueraient',
    'réservassions',
    'réservataires',
    'réserveraient',
    'résidentielle',
    'résignassions',
    'résignataires',
    'résigneraient',
    'résiliassions',
    'résilieraient',
    'résinifiaient',
    'résinifiasses',
    'résinifierais',
    'résinifierait',
    'résinifièrent',
    'résinifieriez',
    'résinifierons',
    'résinifieront',
    'résipiscences',
    'résistassions',
    'résisteraient',
    'resocialisais',
    'resocialisait',
    'resocialisant',
    'resocialisées',
    'resocialisent',
    'resocialisera',
    'resocialisiez',
    'resocialisons',
    'résonnassions',
    'résonneraient',
    'résorbassions',
    'résorberaient',
    'respectassent',
    'respectassiez',
    'respecterions',
    'respectueuses',
    'respirassions',
    'respiratoires',
    'respireraient',
    'resplendirais',
    'resplendirait',
    'resplendirent',
    'resplendiriez',
    'resplendirons',
    'resplendiront',
    'resplendisses',
    'resplendissez',
    'resquillaient',
    'resquillasses',
    'resquillerais',
    'resquillerait',
    'resquillèrent',
    'resquilleriez',
    'resquillerons',
    'resquilleront',
    'resquilleuses',
    'ressaieraient',
    'ressaignaient',
    'ressaignasses',
    'ressaignerais',
    'ressaignerait',
    'ressaignèrent',
    'ressaigneriez',
    'ressaignerons',
    'ressaigneront',
    'ressaisirions',
    'ressaisissais',
    'ressaisissait',
    'ressaisissant',
    'ressaisissent',
    'ressaisissiez',
    'ressaisissons',
    'ressassassent',
    'ressassassiez',
    'ressasserions',
    'ressautassent',
    'ressautassiez',
    'ressauterions',
    'ressayassions',
    'ressayeraient',
    'ressemassions',
    'ressemblaient',
    'ressemblances',
    'ressemblantes',
    'ressemblasses',
    'ressemblerais',
    'ressemblerait',
    'ressemblèrent',
    'ressembleriez',
    'ressemblerons',
    'ressembleront',
    'ressemelaient',
    'ressemelasses',
    'ressemelèrent',
    'ressemellerai',
    'ressemelleras',
    'ressemellerez',
    'ressèmeraient',
    'ressentiments',
    'ressentirions',
    'ressentissent',
    'ressentissiez',
    'resserrassent',
    'resserrassiez',
    'resserrements',
    'resserrerions',
    'resservirions',
    'resservissent',
    'resservissiez',
    'ressortirions',
    'ressortissais',
    'ressortissait',
    'ressortissant',
    'ressortissent',
    'ressortissiez',
    'ressortissons',
    'ressoudassent',
    'ressoudassiez',
    'ressouderions',
    'ressourçaient',
    'ressourçasses',
    'ressourcement',
    'ressourcerais',
    'ressourcerait',
    'ressourcèrent',
    'ressourceriez',
    'ressourcerons',
    'ressourceront',
    'ressouvenions',
    'ressouviendra',
    'ressouviennes',
    'ressouvinrent',
    'ressouvinsses',
    'ressuieraient',
    'ressurgirions',
    'ressurgissais',
    'ressurgissait',
    'ressurgissant',
    'ressurgissent',
    'ressurgissiez',
    'ressurgissons',
    'ressuscitâmes',
    'ressuscitasse',
    'ressuscitâtes',
    'ressusciterai',
    'ressusciteras',
    'ressusciterez',
    'ressuscitions',
    'ressuyassions',
    'restaurassent',
    'restaurassiez',
    'restaurateurs',
    'restaurations',
    'restauratrice',
    'restaurerions',
    'restituassent',
    'restituassiez',
    'restituerions',
    'restreignîmes',
    'restreignions',
    'restreignisse',
    'restreignîtes',
    'restreindrais',
    'restreindrait',
    'restreindriez',
    'restreindrons',
    'restreindront',
    'restringentes',
    'restructurais',
    'restructurait',
    'restructurant',
    'restructurées',
    'restructurent',
    'restructurera',
    'restructuriez',
    'restructurons',
    'resurchauffai',
    'resurchauffas',
    'resurchauffât',
    'resurchauffée',
    'resurchauffer',
    'resurchauffes',
    'resurchauffés',
    'resurchauffez',
    'resurgiraient',
    'resurgissions',
    'résurrections',
    'rétabliraient',
    'rétablissions',
    'retaillassent',
    'retaillassiez',
    'retaillerions',
    'retapissaient',
    'retapissasses',
    'retapisserais',
    'retapisserait',
    'retapissèrent',
    'retapisseriez',
    'retapisserons',
    'retapisseront',
    'retardassions',
    'retardataires',
    'retardatrices',
    'retarderaient',
    'reteignissent',
    'reteignissiez',
    'reteindraient',
    'retendissions',
    'retentassions',
    'retenteraient',
    'retentiraient',
    'retentissante',
    'retentissants',
    'retentissions',
    'reterçassions',
    'reterceraient',
    'retersassions',
    'reterseraient',
    'réticulassent',
    'réticulassiez',
    'réticulations',
    'réticulerions',
    'retiendraient',
    'retissassions',
    'retisseraient',
    'retombassions',
    'retomberaient',
    'retondissions',
    'retordissions',
    'rétorquassent',
    'rétorquassiez',
    'rétorquerions',
    'retouchassent',
    'retouchassiez',
    'retoucherions',
    'retournassent',
    'retournassiez',
    'retournements',
    'retournerions',
    'retraçassions',
    'retraceraient',
    'rétractassent',
    'rétractassiez',
    'rétractations',
    'rétracterions',
    'rétractilités',
    'retraductions',
    'retraduirions',
    'retraduisîmes',
    'retraduisions',
    'retraduisisse',
    'retraduisîtes',
    'retraitassent',
    'retraitassiez',
    'retraitements',
    'retraiterions',
    'retranchaient',
    'retranchasses',
    'retranchement',
    'retrancherais',
    'retrancherait',
    'retranchèrent',
    'retrancheriez',
    'retrancherons',
    'retrancheront',
    'retranscrirai',
    'retranscriras',
    'retranscrirez',
    'retranscrites',
    'retranscrives',
    'retranscrivez',
    'retranscrivis',
    'retranscrivit',
    'retranscrivît',
    'retransmettes',
    'retransmettez',
    'retransmettra',
    'retransmettre',
    'retransmirent',
    'retransmisses',
    'retravaillais',
    'retravaillait',
    'retravaillant',
    'retravaillées',
    'retravaillent',
    'retravaillera',
    'retravailliez',
    'retravaillons',
    'retraversâmes',
    'retraversasse',
    'retraversâtes',
    'retraverserai',
    'retraverseras',
    'retraverserez',
    'retraversions',
    'rétréciraient',
    'rétrécissante',
    'rétrécissants',
    'rétrécissions',
    'retrempassent',
    'retrempassiez',
    'retremperions',
    'rétribuassent',
    'rétribuassiez',
    'rétribuerions',
    'rétroactivité',
    'rétroagirions',
    'rétroagissais',
    'rétroagissait',
    'rétroagissant',
    'rétroagissent',
    'rétroagissiez',
    'rétroagissons',
    'rétrocédaient',
    'rétrocédasses',
    'rétrocéderais',
    'rétrocéderait',
    'rétrocédèrent',
    'rétrocéderiez',
    'rétrocéderons',
    'rétrocéderont',
    'rétrocessions',
    'rétrogradâmes',
    'rétrogradasse',
    'rétrogradâtes',
    'rétrograderai',
    'rétrograderas',
    'rétrograderez',
    'rétrogradions',
    'rétrogression',
    'rétropédalage',
    'rétroposition',
    'rétrospectifs',
    'rétrospection',
    'rétrospective',
    'retroussaient',
    'retroussasses',
    'retroussement',
    'retrousserais',
    'retrousserait',
    'retroussèrent',
    'retrousseriez',
    'retrousserons',
    'retrousseront',
    'retrouvassent',
    'retrouvassiez',
    'retrouverions',
    'rétroversions',
    'réunifiassent',
    'réunifiassiez',
    'réunification',
    'réunifierions',
    'réussissaient',
    'réutilisables',
    'réutilisaient',
    'réutilisasses',
    'réutilisation',
    'réutiliserais',
    'réutiliserait',
    'réutilisèrent',
    'réutiliseriez',
    'réutiliserons',
    'réutiliseront',
    'revaccinaient',
    'revaccinasses',
    'revaccinerais',
    'revaccinerait',
    'revaccinèrent',
    'revaccineriez',
    'revaccinerons',
    'revaccineront',
    'revalorisâmes',
    'revalorisasse',
    'revalorisâtes',
    'revaloriserai',
    'revaloriseras',
    'revaloriserez',
    'revalorisions',
    'revanchassent',
    'revanchassiez',
    'revancherions',
    'rêvassassions',
    'rêvasseraient',
    'réveillassent',
    'réveillassiez',
    'réveillerions',
    'réveillonnais',
    'réveillonnait',
    'réveillonnant',
    'réveillonnent',
    'réveillonnera',
    'réveillonneur',
    'réveillonniez',
    'réveillonnons',
    'revendicateur',
    'revendicatifs',
    'revendication',
    'revendicative',
    'revendiquâmes',
    'revendiquasse',
    'revendiquâtes',
    'revendiquerai',
    'revendiqueras',
    'revendiquerez',
    'revendiquions',
    'revendissions',
    'réverbéraient',
    'réverbérantes',
    'réverbérasses',
    'réverbération',
    'réverbérerais',
    'réverbérerait',
    'réverbérèrent',
    'réverbéreriez',
    'réverbérerons',
    'réverbéreront',
    'reverchassent',
    'reverchassiez',
    'revercherions',
    'reverdiraient',
    'reverdissions',
    'révérencielle',
    'révérencieuse',
    'reverniraient',
    'revernissions',
    'reversassions',
    'reverseraient',
    'réversibilité',
    'reviendraient',
    'revigorassent',
    'revigorassiez',
    'revigorations',
    'revigorerions',
    'révisionnisme',
    'révisionniste',
    'revissassions',
    'revisseraient',
    'revitalisâmes',
    'revitalisasse',
    'revitalisâtes',
    'revitaliserai',
    'revitaliseras',
    'revitaliserez',
    'revitalisions',
    'revivifiaient',
    'revivifiantes',
    'revivifiasses',
    'revivifierais',
    'revivifierait',
    'revivifièrent',
    'revivifieriez',
    'revivifierons',
    'revivifieront',
    'reviviscences',
    'révoltassions',
    'révolteraient',
    'révolutionnai',
    'révolutionnas',
    'révolutionnât',
    'révolutionnée',
    'révolutionner',
    'révolutionnes',
    'révolutionnés',
    'révolutionnez',
    'révolvérisais',
    'révolvérisait',
    'révolvérisant',
    'révolvérisées',
    'révolvérisent',
    'révolvérisera',
    'révolvérisiez',
    'révolvérisons',
    'révoquassions',
    'révoqueraient',
    'revoulussions',
    'révulsassions',
    'révulseraient',
    'rez-de-jardin',
    'rhabdomancien',
    'rhabdomancies',
    'rhabillassent',
    'rhabillassiez',
    'rhabillements',
    'rhabillerions',
    'rhéostatiques',
    'rhétoricienne',
    'rhétoriqueurs',
    'rhinencéphale',
    'rhinopharyngé',
    'rhinoplasties',
    'rhododendrons',
    'rhumatisantes',
    'rhumatismales',
    'rhumatologies',
    'rhumatologues',
    'rhynchonelles',
    'ribonucléases',
    'ribonucléique',
    'riboulassions',
    'ribouldingues',
    'ribouleraient',
    'rickettsioses',
    'ricochassions',
    'ricocheraient',
    'ridiculisâmes',
    'ridiculisasse',
    'ridiculisâtes',
    'ridiculiserai',
    'ridiculiseras',
    'ridiculiserez',
    'ridiculisions',
    'riffaudassent',
    'riffaudassiez',
    'riffauderions',
    'rigidifiaient',
    'rigidifiasses',
    'rigidifierais',
    'rigidifierait',
    'rigidifièrent',
    'rigidifieriez',
    'rigidifierons',
    'rigidifieront',
    'rimaillassent',
    'rimaillassiez',
    'rimaillerions',
    'ringardassent',
    'ringardassiez',
    'ringarderions',
    'ripaillassent',
    'ripaillassiez',
    'ripaillerions',
    'ripolinassent',
    'ripolinassiez',
    'ripolinerions',
    'ripostassions',
    'riposteraient',
    'rissolassions',
    'rissoleraient',
    'ristournaient',
    'ristournasses',
    'ristournerais',
    'ristournerait',
    'ristournèrent',
    'ristourneriez',
    'ristournerons',
    'ristourneront',
    'ritualisaient',
    'ritualisasses',
    'ritualiserais',
    'ritualiserait',
    'ritualisèrent',
    'ritualiseriez',
    'ritualiserons',
    'ritualiseront',
    'rivalisassent',
    'rivalisassiez',
    'rivaliserions',
    'rivetteraient',
    'rizicultrices',
    'robinetteries',
    'robotisassent',
    'robotisassiez',
    'robotisations',
    'robotiserions',
    'rocambolesque',
    'rocking-chair',
    'rôdaillassent',
    'rôdaillassiez',
    'rôdaillerions',
    'rogatoirement',
    'rognonnassent',
    'rognonnassiez',
    'rognonnerions',
    'romançassions',
    'romanceraient',
    'romanichelles',
    'romanisassent',
    'romanisassiez',
    'romanisations',
    'romaniserions',
    'romans-photos',
    'ronchonnaient',
    'ronchonnasses',
    'ronchonnement',
    'ronchonnerais',
    'ronchonnerait',
    'ronchonnèrent',
    'ronchonneriez',
    'ronchonnerons',
    'ronchonneront',
    'ronchonneuses',
    'rondes-bosses',
    'rondouillarde',
    'rondouillards',
    'ronds-de-cuir',
    'ronéotypaient',
    'ronéotypasses',
    'ronéotyperais',
    'ronéotyperait',
    'ronéotypèrent',
    'ronéotyperiez',
    'ronéotyperons',
    'ronéotyperont',
    'ronflaguaient',
    'ronflaguasses',
    'ronflaguerais',
    'ronflaguerait',
    'ronflaguèrent',
    'ronflagueriez',
    'ronflaguerons',
    'ronflagueront',
    'ronronnassent',
    'ronronnassiez',
    'ronronnements',
    'ronronnerions',
    'ronsardisâmes',
    'ronsardisasse',
    'ronsardisâtes',
    'ronsardiserai',
    'ronsardiseras',
    'ronsardiserez',
    'ronsardisions',
    'rosicrucienne',
    'roucoulassent',
    'roucoulassiez',
    'roucoulements',
    'roucoulerions',
    'rouflaquettes',
    'rougeoiements',
    'rougeoierions',
    'rougeoyassent',
    'rougeoyassiez',
    'rouges-gorges',
    'rouges-queues',
    'rougissements',
    'rouillassions',
    'rouilleraient',
    'roulés-boulés',
    'roulottassent',
    'roulottassiez',
    'roulotterions',
    'roupillassent',
    'roupillassiez',
    'roupillerions',
    'rouscaillâmes',
    'rouscaillasse',
    'rouscaillâtes',
    'rouscaillerai',
    'rouscailleras',
    'rouscaillerez',
    'rouscaillions',
    'rouspétassent',
    'rouspétassiez',
    'rouspéterions',
    'roussissaient',
    'roustissaient',
    'rubéfiassions',
    'rubéfieraient',
    'rudentassions',
    'rudenteraient',
    'rudimentaires',
    'ruisselassent',
    'ruisselassiez',
    'ruissellement',
    'ruissellerais',
    'ruissellerait',
    'ruisselleriez',
    'ruissellerons',
    'ruisselleront',
    'russifiassent',
    'russifiassiez',
    'russification',
    'russifierions',
    'rustiquassent',
    'rustiquassiez',
    'rustiquerions',
    'rythmiquement',
    'sabbathiennes',
    'sabellianisme',
    'sablonnassent',
    'sablonnassiez',
    'sablonnerions',
    'sabordassions',
    'saborderaient',
    'saboulassions',
    'sabouleraient',
    'sacagnassions',
    'sacagneraient',
    'saccadassions',
    'saccaderaient',
    'saccageassent',
    'saccageassiez',
    'saccageraient',
    'saccharifères',
    'saccharifiais',
    'saccharifiait',
    'saccharifiant',
    'saccharifiées',
    'saccharifient',
    'saccharifiera',
    'saccharifiiez',
    'saccharifions',
    'saccharomyces',
    'sacculiformes',
    'sacralisaient',
    'sacralisasses',
    'sacralisation',
    'sacraliserais',
    'sacraliserait',
    'sacralisèrent',
    'sacraliseriez',
    'sacraliserons',
    'sacraliseront',
    'sacramentaire',
    'sacramentelle',
    'sacrifiassent',
    'sacrifiassiez',
    'sacrificateur',
    'sacrificielle',
    'sacrifierions',
    'sacro-saintes',
    'safranassions',
    'safraneraient',
    'saint-bernard',
    'saint-cyriens',
    'saisissements',
    'saisonnassent',
    'saisonnassiez',
    'saisonnerions',
    'salariassions',
    'salarieraient',
    'salifiassions',
    'salifications',
    'salifieraient',
    'salmonelloses',
    'salpêtrassent',
    'salpêtrassiez',
    'salpêtrerions',
    'salsepareille',
    'saltimbanques',
    'salutairement',
    'sanctifiaient',
    'sanctifiasses',
    'sanctifierais',
    'sanctifierait',
    'sanctifièrent',
    'sanctifieriez',
    'sanctifierons',
    'sanctifieront',
    'sanctionnâmes',
    'sanctionnasse',
    'sanctionnâtes',
    'sanctionnerai',
    'sanctionneras',
    'sanctionnerez',
    'sanctionnions',
    'sanctuarisais',
    'sanctuarisait',
    'sanctuarisant',
    'sanctuarisées',
    'sanctuarisent',
    'sanctuarisera',
    'sanctuarisiez',
    'sanctuarisons',
    'sandwichaient',
    'sandwichasses',
    'sandwicherais',
    'sandwicherait',
    'sandwichèrent',
    'sandwicheriez',
    'sandwicherons',
    'sandwicheront',
    'sanglotassent',
    'sanglotassiez',
    'sanglotements',
    'sangloterions',
    'sanguinolente',
    'sanguinolents',
    'sanscritistes',
    'sans-culottes',
    'sans-filistes',
    'sanskritismes',
    'sanskritistes',
    'santonnassent',
    'santonnassiez',
    'santonnerions',
    'saponifiables',
    'saponifiaient',
    'saponifiasses',
    'saponifierais',
    'saponifierait',
    'saponifièrent',
    'saponifieriez',
    'saponifierons',
    'saponifieront',
    'sarcomateuses',
    'sarmentassent',
    'sarmentassiez',
    'sarmenterions',
    'satellisaient',
    'satellisasses',
    'satellisation',
    'satelliserais',
    'satelliserait',
    'satellisèrent',
    'satelliseriez',
    'satelliserons',
    'satelliseront',
    'satiriquement',
    'satirisassent',
    'satirisassiez',
    'satiriserions',
    'satisfactions',
    'satisfaisante',
    'satisfaisants',
    'satisfaisions',
    'satisfassions',
    'satisferaient',
    'satisfissions',
    'satonnassions',
    'satonneraient',
    'saturabilités',
    'saucissonnais',
    'saucissonnait',
    'saucissonnant',
    'saucissonnées',
    'saucissonnent',
    'saucissonnera',
    'saucissonniez',
    'saucissonnons',
    'sauf-conduits',
    'saumurassions',
    'saumureraient',
    'saupoudraient',
    'saupoudrasses',
    'saupoudrerais',
    'saupoudrerait',
    'saupoudrèrent',
    'saupoudreriez',
    'saupoudrerons',
    'saupoudreront',
    'saupoudreuses',
    'sautillassent',
    'sautillassiez',
    'sautillements',
    'sautillerions',
    'sauts-de-loup',
    'sauvegardâmes',
    'sauvegardasse',
    'sauvegardâtes',
    'sauvegarderai',
    'sauvegarderas',
    'sauvegarderez',
    'sauvegardions',
    'savonnassions',
    'savonneraient',
    'savourassions',
    'savoureraient',
    'saxophonistes',
    'scandalisâmes',
    'scandalisasse',
    'scandalisâtes',
    'scandaliserai',
    'scandaliseras',
    'scandaliserez',
    'scandalisions',
    'scaphandrière',
    'scaphandriers',
    'scarificateur',
    'scarification',
    'scatologiques',
    'scélératesses',
    'scénographies',
    'sceptiquement',
    'scheidassions',
    'scheideraient',
    'schématisâmes',
    'schématisasse',
    'schématisâtes',
    'schématiserai',
    'schématiseras',
    'schématiserez',
    'schématisions',
    'schismatiques',
    'schizogenèses',
    'schizonévrose',
    'schizophrènes',
    'schizophrénie',
    'schizothymies',
    'schlittassent',
    'schlittassiez',
    'schlitterions',
    'scientificité',
    'scientifiques',
    'scientologies',
    'scientologues',
    'scintigraphie',
    'scintillaient',
    'scintillantes',
    'scintillasses',
    'scintillation',
    'scintillement',
    'scintillèrent',
    'sciottassions',
    'sciotteraient',
    'scissionnâmes',
    'scissionnasse',
    'scissionnâtes',
    'scissionnerai',
    'scissionneras',
    'scissionnerez',
    'scissionnions',
    'scissionniste',
    'scissiparités',
    'sclérosassent',
    'sclérosassiez',
    'scléroserions',
    'scolarisaient',
    'scolarisasses',
    'scolarisation',
    'scolariserais',
    'scolariserait',
    'scolarisèrent',
    'scolariseriez',
    'scolariserons',
    'scolariseront',
    'scotchassions',
    'scotcheraient',
    'scotomisaient',
    'scotomisasses',
    'scotomisation',
    'scotomiserais',
    'scotomiserait',
    'scotomisèrent',
    'scotomiseriez',
    'scotomiserons',
    'scotomiseront',
    'scratchassent',
    'scratchassiez',
    'scratcherions',
    'scribouillais',
    'scribouillait',
    'scribouillant',
    'scribouillard',
    'scribouillées',
    'scribouillent',
    'scribouillera',
    'scribouilleur',
    'scribouilliez',
    'scribouillons',
    'scripturaires',
    'scrogneugneux',
    'sculptassions',
    'sculpteraient',
    'sèche-cheveux',
    'secondassions',
    'seconderaient',
    'secourussions',
    'secrétairerie',
    'sécrétassions',
    'sécréteraient',
    'sectionnaient',
    'sectionnasses',
    'sectionnement',
    'sectionnerais',
    'sectionnerait',
    'sectionnèrent',
    'sectionneriez',
    'sectionnerons',
    'sectionneront',
    'sectorisaient',
    'sectorisasses',
    'sectorisation',
    'sectoriserais',
    'sectoriserait',
    'sectorisèrent',
    'sectoriseriez',
    'sectoriserons',
    'sectoriseront',
    'séculairement',
    'sécularisâmes',
    'sécularisasse',
    'sécularisâtes',
    'séculariserai',
    'séculariseras',
    'séculariserez',
    'sécularisions',
    'séculièrement',
    'sécurisassent',
    'sécurisassiez',
    'sécurisations',
    'sécuriserions',
    'sédentarisais',
    'sédentarisait',
    'sédentarisant',
    'sédentarisées',
    'sédentarisent',
    'sédentarisera',
    'sédentarisiez',
    'sédentarisons',
    'sédimentaient',
    'sédimentaires',
    'sédimentasses',
    'sédimentation',
    'sédimenterais',
    'sédimenterait',
    'sédimentèrent',
    'sédimenteriez',
    'sédimenterons',
    'sédimenteront',
    'séduisissions',
    'segmentassent',
    'segmentassiez',
    'segmentations',
    'segmenterions',
    'seigneuriages',
    'seigneuriales',
    'séismographes',
    'séjournassent',
    'séjournassiez',
    'séjournerions',
    'sélectionnais',
    'sélectionnait',
    'sélectionnant',
    'sélectionnées',
    'sélectionnent',
    'sélectionnera',
    'sélectionneur',
    'sélectionniez',
    'sélectionnons',
    'sélectivement',
    'sélénographie',
    'self-made-man',
    'self-made-men',
    'self-services',
    'sémanticienne',
    'sémaphoriques',
    'sémasiologies',
    'semblablement',
    'semestrielles',
    'semi-chenillé',
    'semi-consonne',
    'semi-lunaires',
    'sémiologiques',
    'sémioticienne',
    'semi-produits',
    'semi-publique',
    'semi-remorque',
    'semi-voyelles',
    'semnopithèque',
    'semonçassions',
    'semonceraient',
    'sempiternelle',
    'sénéchaussées',
    'senestrochère',
    'sensationnels',
    'sensibilisais',
    'sensibilisait',
    'sensibilisant',
    'sensibilisées',
    'sensibilisent',
    'sensibilisera',
    'sensibilisiez',
    'sensibilisons',
    'sensitométrie',
    'sensorimétrie',
    'sensorimoteur',
    'sentencieuses',
    'sentimentales',
    'septembriseur',
    'septennalités',
    'septentrional',
    'septicémiques',
    'septuagénaire',
    'septuagésimes',
    'septuplassent',
    'septuplassiez',
    'septuplerions',
    'séquentielles',
    'séquestraient',
    'séquestrasses',
    'séquestration',
    'séquestrerais',
    'séquestrerait',
    'séquestrèrent',
    'séquestreriez',
    'séquestrerons',
    'séquestreront',
    'sérançassions',
    'séranceraient',
    'serfouiraient',
    'serfouissages',
    'serfouissions',
    'sergent-major',
    'sérialisaient',
    'sérialisasses',
    'sérialiserais',
    'sérialiserait',
    'sérialisèrent',
    'sérialiseriez',
    'sérialiserons',
    'sérialiseront',
    'sériciculteur',
    'sériciculture',
    'séricigraphie',
    'seringuassent',
    'seringuassiez',
    'seringuerions',
    'sermonnassent',
    'sermonnassiez',
    'sermonnerions',
    'sérothérapies',
    'serpentassent',
    'serpentassiez',
    'serpentements',
    'serpenterions',
    'serpigineuses',
    'serre-papiers',
    'serviabilités',
    'servocommande',
    'sesquialtères',
    'sexagésimales',
    'sextuplassent',
    'sextuplassiez',
    'sextuplerions',
    'sexualisaient',
    'sexualisasses',
    'sexualisation',
    'sexualiserais',
    'sexualiserait',
    'sexualisèrent',
    'sexualiseriez',
    'sexualiserons',
    'sexualiseront',
    'shakespearien',
    'shampooineurs',
    'shampooineuse',
    'shampooingnai',
    'shampooingnas',
    'shampooingnât',
    'shampooingnée',
    'shampooingner',
    'shampooingnes',
    'shampooingnés',
    'shampooingnez',
    'shampouinâmes',
    'shampouinasse',
    'shampouinâtes',
    'shampouinerai',
    'shampouineras',
    'shampouinerez',
    'shampouineurs',
    'shampouineuse',
    'shampouinions',
    'shipchandlers',
    'show-business',
    'sidérurgiques',
    'sidérurgistes',
    'sifflotassent',
    'sifflotassiez',
    'sifflotements',
    'siffloterions',
    'signalassions',
    'signaleraient',
    'signalétiques',
    'signalisaient',
    'signalisasses',
    'signalisation',
    'signaliserais',
    'signaliserait',
    'signalisèrent',
    'signaliseriez',
    'signaliserons',
    'signaliseront',
    'signifiassent',
    'signifiassiez',
    'significatifs',
    'signification',
    'significative',
    'signifierions',
    'silhouettâmes',
    'silhouettasse',
    'silhouettâtes',
    'silhouetterai',
    'silhouetteras',
    'silhouetterez',
    'silhouettions',
    'silicatisâmes',
    'silicatisasse',
    'silicatisâtes',
    'silicatiserai',
    'silicatiseras',
    'silicatiserez',
    'silicatisions',
    'siliconassent',
    'siliconassiez',
    'siliconerions',
    'sillonnassent',
    'sillonnassiez',
    'sillonnerions',
    'similigravure',
    'similisassent',
    'similisassiez',
    'similiserions',
    'simplifiables',
    'simplifiaient',
    'simplifiasses',
    'simplifierais',
    'simplifierait',
    'simplifièrent',
    'simplifieriez',
    'simplifierons',
    'simplifieront',
    'simultanéisme',
    'simultanéités',
    'simultanément',
    'singularisais',
    'singularisait',
    'singularisant',
    'singularisées',
    'singularisent',
    'singularisera',
    'singularisiez',
    'singularisons',
    'sintérisaient',
    'sintérisasses',
    'sintériserais',
    'sintériserait',
    'sintérisèrent',
    'sintériseriez',
    'sintériserons',
    'sintériseront',
    'siphonnassent',
    'siphonnassiez',
    'siphonnerions',
    'sismothérapie',
    'slalomassions',
    'slalomeraient',
    'slavisassions',
    'slaviseraient',
    'socialisaient',
    'socialisantes',
    'socialisasses',
    'socialisation',
    'socialiserais',
    'socialiserait',
    'socialisèrent',
    'socialiseriez',
    'socialiserons',
    'socialiseront',
    'socinianismes',
    'socioaffectif',
    'socioculturel',
    'sociologiques',
    'sociologismes',
    'sociométrique',
    'sociothérapie',
    'socratisaient',
    'socratisasses',
    'socratiserais',
    'socratiserait',
    'socratisèrent',
    'socratiseriez',
    'socratiserons',
    'socratiseront',
    'sodomisassent',
    'sodomisassiez',
    'sodomiserions',
    'soigneusement',
    'soixante-cinq',
    'soixante-deux',
    'soixante-huit',
    'soixante-neuf',
    'soixante-sept',
    'solarigraphes',
    'solarisassent',
    'solarisassiez',
    'solariserions',
    'solennisaient',
    'solennisasses',
    'solenniserais',
    'solenniserait',
    'solennisèrent',
    'solenniseriez',
    'solenniserons',
    'solenniseront',
    'solidairement',
    'solidarisâmes',
    'solidarisasse',
    'solidarisâtes',
    'solidariserai',
    'solidariseras',
    'solidariserez',
    'solidarisions',
    'solidifiaient',
    'solidifiasses',
    'solidifierais',
    'solidifierait',
    'solidifièrent',
    'solidifieriez',
    'solidifierons',
    'solidifieront',
    'solifluassent',
    'solifluassiez',
    'solifluerions',
    'soliloquaient',
    'soliloquasses',
    'soliloquerais',
    'soliloquerait',
    'soliloquèrent',
    'soliloqueriez',
    'soliloquerons',
    'soliloqueront',
    'solitairement',
    'sollicitaient',
    'sollicitasses',
    'sollicitation',
    'solliciterais',
    'solliciterait',
    'sollicitèrent',
    'solliciteriez',
    'solliciterons',
    'solliciteront',
    'solliciteuses',
    'solmisassions',
    'solmiseraient',
    'solubilisâmes',
    'solubilisasse',
    'solubilisâtes',
    'solubiliserai',
    'solubiliseras',
    'solubiliserez',
    'solubilisions',
    'solutionnâmes',
    'solutionnasse',
    'solutionnâtes',
    'solutionnerai',
    'solutionneras',
    'solutionnerez',
    'solutionnions',
    'somatisassent',
    'somatisassiez',
    'somatisations',
    'somatiserions',
    'sommeillaient',
    'sommeillasses',
    'sommeillerais',
    'sommeillerait',
    'sommeillèrent',
    'sommeilleriez',
    'sommeillerons',
    'sommeilleront',
    'sommeilleuses',
    'somnambulique',
    'somnambulisme',
    'somnolassions',
    'somnoleraient',
    'sonnaillaient',
    'sonnaillasses',
    'sonnaillerais',
    'sonnaillerait',
    'sonnaillèrent',
    'sonnailleriez',
    'sonnaillerons',
    'sonnailleront',
    'sonorisassent',
    'sonorisassiez',
    'sonorisations',
    'sonoriserions',
    'sophistiquais',
    'sophistiquait',
    'sophistiquant',
    'sophistiquées',
    'sophistiquent',
    'sophistiquera',
    'sophistiquiez',
    'sophistiquons',
    'soubassements',
    'soubresautais',
    'soubresautait',
    'soubresautant',
    'soubresautent',
    'soubresautera',
    'soubresautiez',
    'soubresautons',
    'souchetassent',
    'souchetassiez',
    'souchetterais',
    'souchetterait',
    'souchetteriez',
    'souchetterons',
    'souchetteront',
    'souchevassent',
    'souchevassiez',
    'souchèverions',
    'soucieusement',
    'soudoieraient',
    'soudoyassions',
    'soufflassions',
    'souffleraient',
    'souffletaient',
    'souffletasses',
    'souffletèrent',
    'souffletterai',
    'souffletteras',
    'souffletterez',
    'souffreteuses',
    'souffriraient',
    'souffrissions',
    'souhaitassent',
    'souhaitassiez',
    'souhaiterions',
    'souillassions',
    'souilleraient',
    'soulageassent',
    'soulageassiez',
    'soulageraient',
    'soulevassions',
    'soulèveraient',
    'soulignassent',
    'soulignassiez',
    'soulignements',
    'soulignerions',
    'soûlographies',
    'soumettraient',
    'soumissionnai',
    'soumissionnas',
    'soumissionnât',
    'soumissionnée',
    'soumissionner',
    'soumissionnes',
    'soumissionnés',
    'soumissionnez',
    'soupçonnables',
    'soupçonnaient',
    'soupçonnasses',
    'soupçonnerais',
    'soupçonnerait',
    'soupçonnèrent',
    'soupçonneriez',
    'soupçonnerons',
    'soupçonneront',
    'soupçonneuses',
    'soupesassions',
    'soupèseraient',
    'soupirassions',
    'soupireraient',
    'sourcillaient',
    'sourcillasses',
    'sourcillerais',
    'sourcillerait',
    'sourcillèrent',
    'sourcilleriez',
    'sourcillerons',
    'sourcilleront',
    'sourcilleuses',
    'sourde-muette',
    'sourdinassent',
    'sourdinassiez',
    'sourdinerions',
    'sournoisement',
    'sournoiseries',
    'sous-activité',
    'sous-alimenta',
    'sous-alimente',
    'sous-alimenté',
    'sous-bailleur',
    'sous-calibrée',
    'sous-calibrés',
    'sous-clavière',
    'sous-claviers',
    'sous-comptoir',
    'souscripteurs',
    'souscriptions',
    'souscriptrice',
    'souscriraient',
    'souscrivaient',
    'souscrivirent',
    'souscrivisses',
    'sous-cutanées',
    'sous-diaconat',
    'sous-économes',
    'sous-ensemble',
    'sous-entendes',
    'sous-entendez',
    'sous-entendis',
    'sous-entendit',
    'sous-entendît',
    'sous-entendra',
    'sous-entendre',
    'sous-entendue',
    'sous-entendus',
    'sous-équipées',
    'sous-estimais',
    'sous-estimait',
    'sous-estimant',
    'sous-estimées',
    'sous-estiment',
    'sous-estimera',
    'sous-estimiez',
    'sous-estimons',
    'sous-évaluais',
    'sous-évaluait',
    'sous-évaluant',
    'sous-évaluées',
    'sous-évaluent',
    'sous-évaluera',
    'sous-évaluiez',
    'sous-évaluons',
    'sous-exploita',
    'sous-exploite',
    'sous-exploité',
    'sous-exposais',
    'sous-exposait',
    'sous-exposant',
    'sous-exposées',
    'sous-exposent',
    'sous-exposera',
    'sous-exposiez',
    'sous-exposons',
    'sous-filiales',
    'sous-jacentes',
    'sous-location',
    'sous-louaient',
    'sous-louasses',
    'sous-louerais',
    'sous-louerait',
    'sous-louèrent',
    'sous-loueriez',
    'sous-louerons',
    'sous-loueront',
    'sous-marinier',
    'sous-multiple',
    'sous-normales',
    'sous-officier',
    'sous-paierais',
    'sous-paierait',
    'sous-paieriez',
    'sous-paierons',
    'sous-paieront',
    'sous-payaient',
    'sous-payasses',
    'sous-payerais',
    'sous-payerait',
    'sous-payèrent',
    'sous-payeriez',
    'sous-payerons',
    'sous-payeront',
    'sous-peuplées',
    'sous-préfètes',
    'sous-produits',
    'sous-pubienne',
    'sous-routines',
    'sous-sections',
    'sous-stations',
    'sous-systèmes',
    'sous-tangente',
    'sous-tendîmes',
    'sous-tendions',
    'sous-tendisse',
    'sous-tendîtes',
    'sous-tendrais',
    'sous-tendrait',
    'sous-tendriez',
    'sous-tendrons',
    'sous-tendront',
    'sous-tensions',
    'sous-titrages',
    'sous-titrâmes',
    'sous-titrasse',
    'sous-titrâtes',
    'sous-titrerai',
    'sous-titreras',
    'sous-titrerez',
    'sous-titrions',
    'soustractions',
    'soustractives',
    'soustrairions',
    'sous-traitais',
    'sous-traitait',
    'sous-traitant',
    'sous-traitées',
    'sous-traitent',
    'sous-traitera',
    'sous-traitiez',
    'sous-traitons',
    'soustrayaient',
    'sous-utilisai',
    'sous-utilisas',
    'sous-utilisât',
    'sous-utilisée',
    'sous-utiliser',
    'sous-utilises',
    'sous-utilisés',
    'sous-utilisez',
    'sous-vêtement',
    'sous-viraient',
    'sous-virasses',
    'sous-virerais',
    'sous-virerait',
    'sous-virèrent',
    'sous-vireriez',
    'sous-virerons',
    'sous-vireront',
    'soutachassent',
    'soutachassiez',
    'soutacherions',
    'soutiendrions',
    'soutien-gorge',
    'soutirassions',
    'soutireraient',
    'souverainetés',
    'souviendrions',
    'soviétisaient',
    'soviétisasses',
    'soviétiserais',
    'soviétiserait',
    'soviétisèrent',
    'soviétiseriez',
    'soviétiserons',
    'soviétiseront',
    'spacieusement',
    'spasmolytique',
    'spathifiaient',
    'spathifiasses',
    'spathifierais',
    'spathifierait',
    'spathifièrent',
    'spathifieriez',
    'spathifierons',
    'spathifieront',
    'spatialisâmes',
    'spatialisasse',
    'spatialisâtes',
    'spatialiserai',
    'spatialiseras',
    'spatialiserez',
    'spatialisions',
    'spécialisâmes',
    'spécialisasse',
    'spécialisâtes',
    'spécialiserai',
    'spécialiseras',
    'spécialiserez',
    'spécialisions',
    'spécieusement',
    'spécifiassent',
    'spécifiassiez',
    'spécification',
    'spécifierions',
    'spectaculaire',
    'spectroscopes',
    'spéculassions',
    'spéculatrices',
    'spéculeraient',
    'spéléologique',
    'spermatocytes',
    'spermatogonie',
    'spermatozoïde',
    'spermogrammes',
    'sphacélassent',
    'sphacélassiez',
    'sphacélerions',
    'sphinctériens',
    'sphygmogramme',
    'sphygmographe',
    'spina-ventosa',
    'spiritualisai',
    'spiritualisas',
    'spiritualisât',
    'spiritualisée',
    'spiritualiser',
    'spiritualises',
    'spiritualisés',
    'spiritualisez',
    'spiritualisme',
    'spiritualiste',
    'spiritualités',
    'spirochétoses',
    'splanchniques',
    'spleenétiques',
    'splendidement',
    'splénomégalie',
    'splittassions',
    'splitteraient',
    'spongiculture',
    'sponsorisâmes',
    'sponsorisasse',
    'sponsorisâtes',
    'sponsoriserai',
    'sponsoriseras',
    'sponsoriserez',
    'sponsorisions',
    'spontanéismes',
    'sporotrichose',
    'sporulassions',
    'sporuleraient',
    'sprintassions',
    'sprinteraient',
    'squattassions',
    'squatteraient',
    'squattérisais',
    'squattérisait',
    'squattérisant',
    'squattérisées',
    'squattérisent',
    'squattérisera',
    'squattérisiez',
    'squattérisons',
    'squelettiques',
    'stabilimètres',
    'stabilisaient',
    'stabilisantes',
    'stabilisasses',
    'stabilisateur',
    'stabilisation',
    'stabiliserais',
    'stabiliserait',
    'stabilisèrent',
    'stabiliseriez',
    'stabiliserons',
    'stabiliseront',
    'stakhanovisme',
    'stakhanoviste',
    'stalagmomètre',
    'stalinisaient',
    'stalinisasses',
    'staliniserais',
    'staliniserait',
    'stalinisèrent',
    'staliniseriez',
    'staliniserons',
    'staliniseront',
    'standardisais',
    'standardisait',
    'standardisant',
    'standardisées',
    'standardisent',
    'standardisera',
    'standardisiez',
    'standardisons',
    'standardistes',
    'staphylocoque',
    'starifiassent',
    'starifiassiez',
    'starifierions',
    'starisassions',
    'stariseraient',
    'starting-gate',
    'stathoudérats',
    'stationnaient',
    'stationnaires',
    'stationnarité',
    'stationnasses',
    'stationnement',
    'stationnerais',
    'stationnerait',
    'stationnèrent',
    'stationneriez',
    'stationnerons',
    'stationneront',
    'statisticiens',
    'statoréacteur',
    'statufiassent',
    'statufiassiez',
    'statufierions',
    'steeple-chase',
    'stendhalienne',
    'sténodactylos',
    'sténographiai',
    'sténographias',
    'sténographiât',
    'sténographiée',
    'sténographier',
    'sténographies',
    'sténographiés',
    'sténographiez',
    'sténotypaient',
    'sténotypasses',
    'sténotyperais',
    'sténotyperait',
    'sténotypèrent',
    'sténotyperiez',
    'sténotyperons',
    'sténotyperont',
    'sténotypistes',
    'stéréochimies',
    'stéréochromie',
    'stéréognosies',
    'stéréogrammes',
    'stéréographie',
    'stéréométries',
    'stéréophonies',
    'stéréoscopies',
    'stéréotomique',
    'stéréotypâmes',
    'stéréotypasse',
    'stéréotypâtes',
    'stéréotyperai',
    'stéréotyperas',
    'stéréotyperez',
    'stéréotypions',
    'stérilisaient',
    'stérilisantes',
    'stérilisasses',
    'stérilisateur',
    'stérilisation',
    'stériliserais',
    'stériliserait',
    'stérilisèrent',
    'stériliseriez',
    'stériliserons',
    'stériliseront',
    'sternutations',
    'sternutatoire',
    'stichomythies',
    'stigmatisâmes',
    'stigmatisasse',
    'stigmatisâtes',
    'stigmatiserai',
    'stigmatiseras',
    'stigmatiserez',
    'stigmatisions',
    'stilligouttes',
    'stimulassions',
    'stimulatrices',
    'stimuleraient',
    'stipendiaient',
    'stipendiaires',
    'stipendiasses',
    'stipendierais',
    'stipendierait',
    'stipendièrent',
    'stipendieriez',
    'stipendierons',
    'stipendieront',
    'stipulassions',
    'stipuleraient',
    'stochastiques',
    'stomatologies',
    'stomatologues',
    'stomatorragie',
    'stomatoscopes',
    'strangulaient',
    'strangulasses',
    'strangulation',
    'strangulerais',
    'strangulerait',
    'strangulèrent',
    'stranguleriez',
    'strangulerons',
    'stranguleront',
    'stratifiaient',
    'stratifiasses',
    'stratifierais',
    'stratifierait',
    'stratifièrent',
    'stratifieriez',
    'stratifierons',
    'stratifieront',
    'stratigraphie',
    'stratosphères',
    'stratovisions',
    'streptococcie',
    'streptocoques',
    'streptomycète',
    'streptomycine',
    'stressassions',
    'stresseraient',
    'stridulassent',
    'stridulassiez',
    'stridulations',
    'stridulerions',
    'strioscopique',
    'strippassions',
    'stripperaient',
    'striquassions',
    'striqueraient',
    'stroboscopies',
    'strombolienne',
    'strophantines',
    'structurables',
    'structuraient',
    'structurantes',
    'structurasses',
    'structuration',
    'structurelles',
    'structurerais',
    'structurerait',
    'structurèrent',
    'structureriez',
    'structurerons',
    'structureront',
    'studieusement',
    'stupéfactions',
    'stupéfiassent',
    'stupéfiassiez',
    'stupéfierions',
    'stylisassions',
    'styliseraient',
    'stylisticiens',
    'subaquatiques',
    'subcarpatique',
    'subconscience',
    'subconsciente',
    'subconscients',
    'subdélégation',
    'subdéléguâmes',
    'subdéléguasse',
    'subdéléguâtes',
    'subdéléguerai',
    'subdélégueras',
    'subdéléguerez',
    'subdéléguions',
    'subdésertique',
    'subdivisaient',
    'subdivisasses',
    'subdiviserais',
    'subdiviserait',
    'subdivisèrent',
    'subdiviseriez',
    'subdiviserons',
    'subdiviseront',
    'subéquatorial',
    'subjectivisme',
    'subjectiviste',
    'subjectivités',
    'subjuguassent',
    'subjuguassiez',
    'subjuguerions',
    'sublimassions',
    'sublimeraient',
    'subliminaires',
    'submergeaient',
    'submergeasses',
    'submergerions',
    'subodorassent',
    'subodorassiez',
    'subodorerions',
    'subordination',
    'subordonnâmes',
    'subordonnante',
    'subordonnants',
    'subordonnasse',
    'subordonnâtes',
    'subordonnerai',
    'subordonneras',
    'subordonnerez',
    'subordonnions',
    'subornassions',
    'suborneraient',
    'subrogatoires',
    'subrogeassent',
    'subrogeassiez',
    'subrogeraient',
    'subséquemment',
    'subsistassent',
    'subsistassiez',
    'subsisterions',
    'substantielle',
    'substantivais',
    'substantivait',
    'substantivant',
    'substantivées',
    'substantivent',
    'substantivera',
    'substantiviez',
    'substantivons',
    'substituaient',
    'substituasses',
    'substituerais',
    'substituerait',
    'substituèrent',
    'substitueriez',
    'substituerons',
    'substitueront',
    'substitutions',
    'substitutives',
    'subtilisaient',
    'subtilisasses',
    'subtilisation',
    'subtiliserais',
    'subtiliserait',
    'subtilisèrent',
    'subtiliseriez',
    'subtiliserons',
    'subtiliseront',
    'subtropicales',
    'suburbicaires',
    'subventionnai',
    'subventionnas',
    'subventionnât',
    'subventionnée',
    'subventionnel',
    'subventionner',
    'subventionnes',
    'subventionnés',
    'subventionnez',
    'subvertirions',
    'subvertissais',
    'subvertissait',
    'subvertissant',
    'subvertissent',
    'subvertissiez',
    'subvertissons',
    'subviendrions',
    'succédassions',
    'succéderaient',
    'succenturiées',
    'successorales',
    'succinctement',
    'succombassent',
    'succombassiez',
    'succomberions',
    'sud-africaine',
    'sud-africains',
    'sud-américain',
    'sud-coréennes',
    'suffixassions',
    'suffixeraient',
    'suffoquassent',
    'suffoquassiez',
    'suffoquerions',
    'suggérassions',
    'suggéreraient',
    'suggestionnai',
    'suggestionnas',
    'suggestionnât',
    'suggestionnée',
    'suggestionner',
    'suggestionnes',
    'suggestionnés',
    'suggestionnez',
    'suicidassions',
    'suicideraient',
    'sulfatassions',
    'sulfateraient',
    'sulfhydriqueS',
    'sulfinisation',
    'sulfitassions',
    'sulfiteraient',
    'sulfonassions',
    'sulfoneraient',
    'sulfoviniques',
    'sulfurassions',
    'sulfureraient',
    'superbénéfice',
    'superchampion',
    'superfétation',
    'superficielle',
    'superfinirais',
    'superfinirait',
    'superfinirent',
    'superfiniriez',
    'superfinirons',
    'superfiniront',
    'superfinisses',
    'superfinissez',
    'superfinition',
    'superovariées',
    'superposables',
    'superposaient',
    'superposasses',
    'superposerais',
    'superposerait',
    'superposèrent',
    'superposeriez',
    'superposerons',
    'superposeront',
    'superposition',
    'superréaction',
    'supersoniques',
    'superstitieux',
    'superstitions',
    'supervisaient',
    'supervisasses',
    'superviserais',
    'superviserait',
    'supervisèrent',
    'superviseriez',
    'superviserons',
    'superviseront',
    'superviseuses',
    'supplantaient',
    'supplantasses',
    'supplanterais',
    'supplanterait',
    'supplantèrent',
    'supplanteriez',
    'supplanterons',
    'supplanteront',
    'suppléassions',
    'suppléeraient',
    'supplémentais',
    'supplémentait',
    'supplémentant',
    'supplémentées',
    'supplémentent',
    'supplémentera',
    'supplémentiez',
    'supplémentons',
    'suppliassions',
    'supplications',
    'suppliciaient',
    'suppliciasses',
    'supplicierais',
    'supplicierait',
    'supplicièrent',
    'supplicieriez',
    'supplicierons',
    'supplicieront',
    'supplieraient',
    'supportassent',
    'supportassiez',
    'supporterions',
    'supposassions',
    'supposeraient',
    'suppositoires',
    'supprimassent',
    'supprimassiez',
    'supprimerions',
    'suppurassions',
    'suppureraient',
    'supputassions',
    'supputeraient',
    'supranational',
    'suprasensible',
    'surabondaient',
    'surabondances',
    'surabondantes',
    'surabondasses',
    'surabonderais',
    'surabonderait',
    'surabondèrent',
    'surabonderiez',
    'surabonderons',
    'surabonderont',
    'surajoutaient',
    'surajoutasses',
    'surajouterais',
    'surajouterait',
    'surajoutèrent',
    'surajouteriez',
    'surajouterons',
    'surajouteront',
    'suralimentais',
    'suralimentait',
    'suralimentant',
    'suralimentées',
    'suralimentent',
    'suralimentera',
    'suralimentiez',
    'suralimentons',
    'surbaissaient',
    'surbaissasses',
    'surbaissement',
    'surbaisserais',
    'surbaisserait',
    'surbaissèrent',
    'surbaisseriez',
    'surbaisserons',
    'surbaisseront',
    'surchargeâmes',
    'surchargeasse',
    'surchargeâtes',
    'surchargerais',
    'surchargerait',
    'surchargèrent',
    'surchargeriez',
    'surchargerons',
    'surchargeront',
    'surchauffâmes',
    'surchauffasse',
    'surchauffâtes',
    'surchaufferai',
    'surchaufferas',
    'surchaufferez',
    'surchauffeurs',
    'surchauffions',
    'surclassaient',
    'surclassasses',
    'surclasserais',
    'surclasserait',
    'surclassèrent',
    'surclasseriez',
    'surclasserons',
    'surclasseront',
    'surcomprimais',
    'surcomprimait',
    'surcomprimant',
    'surcomprimées',
    'surcompriment',
    'surcomprimera',
    'surcomprimiez',
    'surcomprimons',
    'surcontraient',
    'surcontrasses',
    'surcontrerais',
    'surcontrerait',
    'surcontrèrent',
    'surcontreriez',
    'surcontrerons',
    'surcontreront',
    'surcoupassent',
    'surcoupassiez',
    'surcouperions',
    'surcreusement',
    'surdéterminée',
    'surdéterminés',
    'surdéveloppée',
    'surdéveloppés',
    'surdi-mutités',
    'surdorassions',
    'surdoreraient',
    'surédifiaient',
    'surédifiasses',
    'surédifierais',
    'surédifierait',
    'surédifièrent',
    'surédifieriez',
    'surédifierons',
    'surédifieront',
    'surélevassent',
    'surélevassiez',
    'surélévations',
    'surélèverions',
    'surenchérîmes',
    'surenchérirai',
    'surenchériras',
    'surenchérirez',
    'surenchérisse',
    'surenchérîtes',
    'surencombrées',
    'surentraînais',
    'surentraînait',
    'surentraînant',
    'surentraînées',
    'surentraînent',
    'surentraînera',
    'surentraîniez',
    'surentraînons',
    'suréquipaient',
    'suréquipasses',
    'suréquipement',
    'suréquiperais',
    'suréquiperait',
    'suréquipèrent',
    'suréquiperiez',
    'suréquiperons',
    'suréquiperont',
    'surérogations',
    'surérogatoire',
    'surestimaient',
    'surestimasses',
    'surestimation',
    'surestimerais',
    'surestimerait',
    'surestimèrent',
    'surestimeriez',
    'surestimerons',
    'surestimeront',
    'surévaluaient',
    'surévaluasses',
    'surévaluation',
    'surévaluerais',
    'surévaluerait',
    'surévaluèrent',
    'surévalueriez',
    'surévaluerons',
    'surévalueront',
    'surexcitaient',
    'surexcitantes',
    'surexcitasses',
    'surexcitation',
    'surexciterais',
    'surexciterait',
    'surexcitèrent',
    'surexciteriez',
    'surexciterons',
    'surexciteront',
    'surexploitais',
    'surexploitait',
    'surexploitant',
    'surexploitées',
    'surexploitent',
    'surexploitera',
    'surexploitiez',
    'surexploitons',
    'surexposaient',
    'surexposasses',
    'surexposerais',
    'surexposerait',
    'surexposèrent',
    'surexposeriez',
    'surexposerons',
    'surexposeront',
    'surexposition',
    'surfaçassions',
    'surfaceraient',
    'surfilassions',
    'surfileraient',
    'surgelassions',
    'surgèleraient',
    'surgénérateur',
    'surgeonnaient',
    'surgeonnasses',
    'surgeonnerais',
    'surgeonnerait',
    'surgeonnèrent',
    'surgeonneriez',
    'surgeonnerons',
    'surgeonneront',
    'surgissements',
    'surglaçassent',
    'surglaçassiez',
    'surglacerions',
    'surhaussaient',
    'surhaussasses',
    'surhaussement',
    'surhausserais',
    'surhausserait',
    'surhaussèrent',
    'surhausseriez',
    'surhausserons',
    'surhausseront',
    'surimposaient',
    'surimposasses',
    'surimposerais',
    'surimposerait',
    'surimposèrent',
    'surimposeriez',
    'surimposerons',
    'surimposeront',
    'surimposition',
    'surimpression',
    'surinfections',
    'surintendance',
    'surintendante',
    'surintendants',
    'surintensités',
    'surjalassions',
    'surjaleraient',
    'surjetassions',
    'surjetterions',
    'surlendemains',
    'surlouassions',
    'surloueraient',
    'surmédicalisa',
    'surmédicalise',
    'surmédicalisé',
    'surmenassions',
    'surmèneraient',
    'surmontassent',
    'surmontassiez',
    'surmonterions',
    'surmortalités',
    'surmoulassent',
    'surmoulassiez',
    'surmoulerions',
    'surmultipliée',
    'surmultipliés',
    'surnageassent',
    'surnageassiez',
    'surnageraient',
    'surnaturelles',
    'surnommassent',
    'surnommassiez',
    'surnommerions',
    'surnuméraires',
    'suroxydassent',
    'suroxydassiez',
    'suroxyderions',
    'surpaieraient',
    'surpassassent',
    'surpassassiez',
    'surpassements',
    'surpasserions',
    'surpayassions',
    'surpayeraient',
    'surpeuplement',
    'surpiquassent',
    'surpiquassiez',
    'surpiquerions',
    'surplombaient',
    'surplombantes',
    'surplombasses',
    'surplombement',
    'surplomberais',
    'surplomberait',
    'surplombèrent',
    'surplomberiez',
    'surplomberons',
    'surplomberont',
    'surpopulation',
    'surprendrions',
    'surproducteur',
    'surproduction',
    'surprotégeais',
    'surprotégeait',
    'surprotégeant',
    'surprotégeons',
    'surprotégerai',
    'surprotégeras',
    'surprotégerez',
    'surprotégions',
    'surpuissantes',
    'sursaturaient',
    'sursaturasses',
    'sursaturation',
    'sursaturerais',
    'sursaturerait',
    'sursaturèrent',
    'sursatureriez',
    'sursaturerons',
    'sursatureront',
    'sursautassent',
    'sursautassiez',
    'sursauterions',
    'sursemassions',
    'sursèmeraient',
    'surseoiraient',
    'surtaxassions',
    'surtaxeraient',
    'survécussions',
    'surveillaient',
    'surveillances',
    'surveillantes',
    'surveillasses',
    'surveillerais',
    'surveillerait',
    'surveillèrent',
    'surveilleriez',
    'surveillerons',
    'surveilleront',
    'surviendrions',
    'survirassions',
    'survireraient',
    'survolassions',
    'survoleraient',
    'survoltassent',
    'survoltassiez',
    'survolterions',
    'suscitassions',
    'susciteraient',
    'sus-dénommées',
    'sus-dominante',
    'sus-hépatique',
    'susmentionnée',
    'susmentionnés',
    'suspectassent',
    'suspectassiez',
    'suspecterions',
    'suspendissent',
    'suspendissiez',
    'suspendraient',
    'sustentassent',
    'sustentassiez',
    'sustentateurs',
    'sustentations',
    'sustentatrice',
    'sustenterions',
    'susurrassions',
    'susurreraient',
    'swinguassions',
    'swingueraient',
    'syllabassions',
    'syllaberaient',
    'syllogistique',
    'sylviculteurs',
    'sylvicultrice',
    'sylvicultures',
    'symbolisaient',
    'symbolisasses',
    'symbolisation',
    'symboliserais',
    'symboliserait',
    'symbolisèrent',
    'symboliseriez',
    'symboliserons',
    'symboliseront',
    'symétrisaient',
    'symétrisasses',
    'symétriserais',
    'symétriserait',
    'symétrisèrent',
    'symétriseriez',
    'symétriserons',
    'symétriseront',
    'sympathisâmes',
    'sympathisante',
    'sympathisants',
    'sympathisasse',
    'sympathisâtes',
    'sympathiserai',
    'sympathiseras',
    'sympathiserez',
    'sympathisions',
    'symptomatique',
    'synchondroses',
    'synchroniques',
    'synchronisais',
    'synchronisait',
    'synchronisant',
    'synchronisées',
    'synchronisent',
    'synchronisera',
    'synchroniseur',
    'synchronisiez',
    'synchronismes',
    'synchronisons',
    'syncopassions',
    'syncoperaient',
    'syndicalisais',
    'syndicalisait',
    'syndicalisant',
    'syndicalisées',
    'syndicalisent',
    'syndicalisera',
    'syndicalisiez',
    'syndicalismes',
    'syndicalisons',
    'syndicalistes',
    'syndicataires',
    'syndiquassent',
    'syndiquassiez',
    'syndiquerions',
    'syntacticiens',
    'syntagmatique',
    'synthétisâmes',
    'synthétisasse',
    'synthétisâtes',
    'synthétiserai',
    'synthétiseras',
    'synthétiserez',
    'synthétiseurs',
    'synthétisions',
    'syntonisaient',
    'syntonisasses',
    'syntonisation',
    'syntoniserais',
    'syntoniserait',
    'syntonisèrent',
    'syntoniseriez',
    'syntoniserons',
    'syntoniseront',
    'syphiligraphe',
    'syphilitiques',
    'syphilographe',
    'syringomyélie',
    'systématiques',
    'systématisais',
    'systématisait',
    'systématisant',
    'systématisées',
    'systématisent',
    'systématisera',
    'systématisiez',
    'systématisons',
    'tabacultrices',
    'tabassassions',
    'tabasseraient',
    'tachéographes',
    'tachéométries',
    'tachetassions',
    'tachetterions',
    'tachistoscope',
    'tachyarythmie',
    'tachyphylaxie',
    'tailladassent',
    'tailladassiez',
    'tailladerions',
    'taillanderies',
    'taille-crayon',
    'talismaniques',
    'talkie-walkie',
    'talochassions',
    'talocheraient',
    'talonnassions',
    'talonneraient',
    'tambourinages',
    'tambourinaire',
    'tambourinâmes',
    'tambourinasse',
    'tambourinâtes',
    'tambourinerai',
    'tambourineras',
    'tambourinerez',
    'tambourineurs',
    'tambourineuse',
    'tambourinions',
    'tambour-major',
    'tamponnassent',
    'tamponnassiez',
    'tamponnements',
    'tamponnerions',
    'tannisassions',
    'tanniseraient',
    'tapageassions',
    'tapageusement',
    'tapis-brosses',
    'tapissassions',
    'tapisseraient',
    'taponnassions',
    'taponneraient',
    'taquinassions',
    'taquineraient',
    'tarabiscotage',
    'tarabiscotais',
    'tarabiscotait',
    'tarabiscotant',
    'tarabiscotées',
    'tarabiscotent',
    'tarabiscotera',
    'tarabiscotiez',
    'tarabiscotons',
    'tarabustaient',
    'tarabustasses',
    'tarabusterais',
    'tarabusterait',
    'tarabustèrent',
    'tarabusteriez',
    'tarabusterons',
    'tarabusteront',
    'taraudassions',
    'tarauderaient',
    'tarifiassions',
    'tarifications',
    'tarifieraient',
    'tartinassions',
    'tartineraient',
    'tatillonnâmes',
    'tatillonnasse',
    'tatillonnâtes',
    'tatillonnerai',
    'tatillonneras',
    'tatillonnerez',
    'tatillonnions',
    'tâtonnassions',
    'tâtonneraient',
    'taupe-grillon',
    'tauromachique',
    'tavellassions',
    'tavelleraient',
    'taxidermistes',
    'taylorisaient',
    'taylorisasses',
    'taylorisation',
    'tayloriserais',
    'tayloriserait',
    'taylorisèrent',
    'tayloriseriez',
    'tayloriserons',
    'tayloriseront',
    'techniciennes',
    'technicisâmes',
    'technicisasse',
    'technicisâtes',
    'techniciserai',
    'techniciseras',
    'techniciserez',
    'technicisions',
    'techniquement',
    'technisassent',
    'technisassiez',
    'techniserions',
    'technocraties',
    'technocratisa',
    'technocratise',
    'technocratisé',
    'technologique',
    'technologiste',
    'tégumentaires',
    'télécommandai',
    'télécommandas',
    'télécommandât',
    'télécommandée',
    'télécommander',
    'télécommandes',
    'télécommandés',
    'télécommandez',
    'télécopiaient',
    'télécopiasses',
    'télécopierais',
    'télécopierait',
    'télécopièrent',
    'télécopieriez',
    'télécopierons',
    'télécopieront',
    'télédétection',
    'télédiffusais',
    'télédiffusait',
    'télédiffusant',
    'télédiffusées',
    'télédiffusent',
    'télédiffusera',
    'télédiffusiez',
    'télédiffusion',
    'télédiffusons',
    'télégraphiais',
    'télégraphiait',
    'télégraphiant',
    'télégraphiées',
    'télégraphient',
    'télégraphiera',
    'télégraphiiez',
    'télégraphions',
    'télégraphique',
    'télégraphiste',
    'téléguidaient',
    'téléguidasses',
    'téléguiderais',
    'téléguiderait',
    'téléguidèrent',
    'téléguideriez',
    'téléguiderons',
    'téléguideront',
    'téléimprimeur',
    'télémécanique',
    'télémétraient',
    'télémétrasses',
    'télémétrerais',
    'télémétrerait',
    'télémétrèrent',
    'télémétreriez',
    'télémétrerons',
    'télémétreront',
    'télémétriques',
    'télencéphales',
    'téléobjectifs',
    'téléologiques',
    'téléopérateur',
    'téléopération',
    'télépathiques',
    'téléphériques',
    'téléphonaient',
    'téléphonasses',
    'téléphonerais',
    'téléphonerait',
    'téléphonèrent',
    'téléphoneriez',
    'téléphonerons',
    'téléphoneront',
    'téléphoniques',
    'téléphonistes',
    'télépointages',
    'téléreportage',
    'télérobotique',
    'télescopaient',
    'télescopasses',
    'télescoperais',
    'télescoperait',
    'télescopèrent',
    'télescoperiez',
    'télescoperons',
    'télescoperont',
    'télescopiques',
    'téléscripteur',
    'télévisassent',
    'télévisassiez',
    'téléviserions',
    'télévisuelles',
    'témérairement',
    'témoignassent',
    'témoignassiez',
    'témoignerions',
    'tempérassions',
    'tempéreraient',
    'tempêtassions',
    'tempêteraient',
    'temporisaient',
    'temporisantes',
    'temporisasses',
    'temporisateur',
    'temporisation',
    'temporiserais',
    'temporiserait',
    'temporisèrent',
    'temporiseriez',
    'temporiserons',
    'temporiseront',
    'tenaillassent',
    'tenaillassiez',
    'tenaillements',
    'tenaillerions',
    'tendancielles',
    'tendancieuses',
    'tenonnassions',
    'tenonneraient',
    'ténorisassent',
    'ténorisassiez',
    'ténoriserions',
    'tensioactives',
    'tentaculaires',
    'tératogenèses',
    'tératologique',
    'tératologiste',
    'térébenthines',
    'tergiversâmes',
    'tergiversasse',
    'tergiversâtes',
    'tergiverserai',
    'tergiverseras',
    'tergiverserez',
    'tergiversions',
    'terminassions',
    'termineraient',
    'terminologies',
    'terminologues',
    'ternissements',
    'terrafungines',
    'terrassassent',
    'terrassassiez',
    'terrassements',
    'terrasserions',
    'terreautaient',
    'terreautasses',
    'terreauterais',
    'terreauterait',
    'terreautèrent',
    'terreauteriez',
    'terreauterons',
    'terreauteront',
    'terre-neuvien',
    'terre-neuvier',
    'terrifiassent',
    'terrifiassiez',
    'terrifierions',
    'territoriales',
    'terrorisaient',
    'terrorisantes',
    'terrorisasses',
    'terroriserais',
    'terroriserait',
    'terrorisèrent',
    'terroriseriez',
    'terroriserons',
    'terroriseront',
    'testamentaire',
    'testiculaires',
    'testimoniales',
    'testostérones',
    'tétanisassent',
    'tétanisassiez',
    'tétanisations',
    'tétaniserions',
    'tête-de-Maure',
    'tête-de-nègre',
    'têtes-de-mort',
    'tétrachlorure',
    'tétracyclines',
    'tétradactyles',
    'tétraplégique',
    'tétraploïdies',
    'tétrasyllabes',
    'tétratomicité',
    'tétratomiques',
    'textuellement',
    'texturassions',
    'textureraient',
    'texturisaient',
    'texturisasses',
    'texturisation',
    'texturiserais',
    'texturiserait',
    'texturisèrent',
    'texturiseriez',
    'texturiserons',
    'texturiseront',
    'thaïlandaises',
    'thalassotoque',
    'thanatologies',
    'théâtralement',
    'théâtralisais',
    'théâtralisait',
    'théâtralisant',
    'théâtralisées',
    'théâtralisent',
    'théâtralisera',
    'théâtralisiez',
    'théâtralismes',
    'théâtralisons',
    'thématisaient',
    'thématisasses',
    'thématiserais',
    'thématiserait',
    'thématisèrent',
    'thématiseriez',
    'thématiserons',
    'thématiseront',
    'théocratiques',
    'théologiennes',
    'théophyllines',
    'théorématique',
    'théoriciennes',
    'théoriquement',
    'théorisassent',
    'théorisassiez',
    'théorisations',
    'théoriserions',
    'thérapeutique',
    'thermocautère',
    'thermochimies',
    'thermocouples',
    'thermoformage',
    'thermographie',
    'thermolabiles',
    'thermométries',
    'thermosiphons',
    'thermosphères',
    'thermostables',
    'thésaurisâmes',
    'thésaurisasse',
    'thésaurisâtes',
    'thésauriserai',
    'thésauriseras',
    'thésauriserez',
    'thésauriseurs',
    'thésauriseuse',
    'thésaurisions',
    'thesmophories',
    'thoracentèses',
    'thuriféraires',
    'thyroïdiennes',
    'thyrotrophine',
    'tictaquassent',
    'tictaquassiez',
    'tictaquerions',
    'tiédissements',
    'tiercefeuille',
    'timbres-poste',
    'tintinnabulai',
    'tintinnabulas',
    'tintinnabulât',
    'tintinnabuler',
    'tintinnabules',
    'tintinnabulez',
    'tiraillassent',
    'tiraillassiez',
    'tiraillements',
    'tiraillerions',
    'tire-au-flanc',
    'tirebouchonna',
    'tirebouchonne',
    'tirebouchonné',
    'tire-bouchons',
    'tiroir-caisse',
    'tisonnassions',
    'tisonneraient',
    'titillassions',
    'titilleraient',
    'titularisâmes',
    'titularisante',
    'titularisants',
    'titularisasse',
    'titularisâtes',
    'titulariserai',
    'titulariseras',
    'titulariserez',
    'titularisions',
    'toilettassent',
    'toilettassiez',
    'toiletterions',
    'tolérantismes',
    'tonicardiaque',
    'tonifiassions',
    'tonifieraient',
    'tonitruassent',
    'tonitruassiez',
    'tonitruerions',
    'tonsurassions',
    'tonsureraient',
    'tontinassions',
    'tontineraient',
    'topicalisâmes',
    'topicalisasse',
    'topicalisâtes',
    'topicaliserai',
    'topicaliseras',
    'topicaliserez',
    'topicalisions',
    'topographique',
    'torchonnaient',
    'torchonnasses',
    'torchonnerais',
    'torchonnerait',
    'torchonnèrent',
    'torchonneriez',
    'torchonnerons',
    'torchonneront',
    'toronnassions',
    'toronneraient',
    'torpillassent',
    'torpillassiez',
    'torpillerions',
    'torréfacteurs',
    'torréfactions',
    'torréfiassent',
    'torréfiassiez',
    'torréfierions',
    'torrentielles',
    'torrentueuses',
    'torsadassions',
    'torsaderaient',
    'tortillassent',
    'tortillassiez',
    'tortillements',
    'tortillerions',
    'tortionnaires',
    'tortorassions',
    'tortoreraient',
    'tortueusement',
    'torturassions',
    'tortureraient',
    'totalisassent',
    'totalisassiez',
    'totalisateurs',
    'totalisations',
    'totalisatrice',
    'totaliserions',
    'totalitarisme',
    'touche-à-tout',
    'touillassions',
    'touilleraient',
    'toupillassent',
    'toupillassiez',
    'toupillerions',
    'tourbillonnai',
    'tourbillonnas',
    'tourbillonnât',
    'tourbillonner',
    'tourbillonnes',
    'tourbillonnez',
    'tourillonnais',
    'tourillonnait',
    'tourillonnant',
    'tourillonnent',
    'tourillonnera',
    'tourillonniez',
    'tourillonnons',
    'tourmentaient',
    'tourmentasses',
    'tourmenterais',
    'tourmenterait',
    'tourmentèrent',
    'tourmenteriez',
    'tourmenterons',
    'tourmenteront',
    'tourmenteuses',
    'tournaillâmes',
    'tournaillasse',
    'tournaillâtes',
    'tournaillerai',
    'tournailleras',
    'tournaillerez',
    'tournaillions',
    'tournassaient',
    'tournassasses',
    'tournasserais',
    'tournasserait',
    'tournassèrent',
    'tournasseriez',
    'tournasserons',
    'tournasseront',
    'tourneboulais',
    'tourneboulait',
    'tourneboulant',
    'tourneboulées',
    'tourneboulent',
    'tourneboulera',
    'tournebouliez',
    'tourneboulons',
    'tournebroches',
    'tourne-disque',
    'tourne-pierre',
    'tournicotâmes',
    'tournicotasse',
    'tournicotâtes',
    'tournicoterai',
    'tournicoteras',
    'tournicoterez',
    'tournicotions',
    'tournillaient',
    'tournillasses',
    'tournillerais',
    'tournillerait',
    'tournillèrent',
    'tournilleriez',
    'tournillerons',
    'tournilleront',
    'tourniquaient',
    'tourniquasses',
    'tourniquerais',
    'tourniquerait',
    'tourniquèrent',
    'tourniqueriez',
    'tourniquerons',
    'tourniqueront',
    'tournoiements',
    'tournoierions',
    'tournoyassent',
    'tournoyassiez',
    'toussaillâmes',
    'toussaillasse',
    'toussaillâtes',
    'toussaillerai',
    'toussailleras',
    'toussaillerez',
    'toussaillions',
    'toussotassent',
    'toussotassiez',
    'toussotements',
    'toussoterions',
    'toutes-bonnes',
    'toutes-épices',
    'tout-puissant',
    'toxicodermies',
    'toxicologique',
    'toxoplasmoses',
    'traboulassent',
    'traboulassiez',
    'traboulerions',
    'tracanassions',
    'tracaneraient',
    'tracassassent',
    'tracassassiez',
    'tracasserions',
    'trachéostomie',
    'trachéotomies',
    'tractionnaire',
    'tradescantias',
    'traditionnels',
    'traduisissent',
    'traduisissiez',
    'traficotaient',
    'traficotasses',
    'traficoterais',
    'traficoterait',
    'traficotèrent',
    'traficoteriez',
    'traficoterons',
    'traficoteront',
    'tragi-comédie',
    'tragi-comique',
    'traînaillâmes',
    'traînaillasse',
    'traînaillâtes',
    'traînaillerai',
    'traînailleras',
    'traînaillerez',
    'traînaillions',
    'traînassaient',
    'traînassasses',
    'traînasserais',
    'traînasserait',
    'traînassèrent',
    'traînasseriez',
    'traînasserons',
    'traînasseront',
    'traîne-bûches',
    'traîne-misère',
    'tranchassions',
    'tranchée-abri',
    'tranchefilais',
    'tranchefilait',
    'tranchefilant',
    'tranchefilées',
    'tranchefilent',
    'tranchefilera',
    'tranchefiliez',
    'tranchefilons',
    'trancheraient',
    'tranquillisai',
    'tranquillisas',
    'tranquillisât',
    'tranquillisée',
    'tranquilliser',
    'tranquillises',
    'tranquillisés',
    'tranquillisez',
    'tranquillités',
    'transafricain',
    'transbahutais',
    'transbahutait',
    'transbahutant',
    'transbahutées',
    'transbahutent',
    'transbahutera',
    'transbahutiez',
    'transbahutons',
    'transbordâmes',
    'transbordasse',
    'transbordâtes',
    'transborderai',
    'transborderas',
    'transborderez',
    'transbordeurs',
    'transbordions',
    'transcanadien',
    'transcaspiens',
    'transcendâmes',
    'transcendance',
    'transcendante',
    'transcendants',
    'transcendasse',
    'transcendâtes',
    'transcenderai',
    'transcenderas',
    'transcenderez',
    'transcendions',
    'transcodaient',
    'transcodasses',
    'transcoderais',
    'transcoderait',
    'transcodèrent',
    'transcoderiez',
    'transcoderons',
    'transcoderont',
    'transcripteur',
    'transcription',
    'transcririons',
    'transcrivîmes',
    'transcrivions',
    'transcrivisse',
    'transcrivîtes',
    'transducteurs',
    'transductions',
    'transférables',
    'transféraient',
    'transférasses',
    'transfèrement',
    'transférerais',
    'transférerait',
    'transférèrent',
    'transféreriez',
    'transférerons',
    'transféreront',
    'transfigurais',
    'transfigurait',
    'transfigurant',
    'transfigurées',
    'transfigurent',
    'transfigurera',
    'transfiguriez',
    'transfigurons',
    'transfilaient',
    'transfilasses',
    'transfilerais',
    'transfilerait',
    'transfilèrent',
    'transfileriez',
    'transfilerons',
    'transfileront',
    'transformable',
    'transformâmes',
    'transformante',
    'transformants',
    'transformasse',
    'transformâtes',
    'transformerai',
    'transformeras',
    'transformerez',
    'transformions',
    'transformisme',
    'transformiste',
    'transfusaient',
    'transfusasses',
    'transfuserais',
    'transfuserait',
    'transfusèrent',
    'transfuseriez',
    'transfuserons',
    'transfuseront',
    'transgressais',
    'transgressait',
    'transgressant',
    'transgressées',
    'transgressent',
    'transgressera',
    'transgresseur',
    'transgressiez',
    'transgression',
    'transgressons',
    'transhumaient',
    'transhumances',
    'transhumantes',
    'transhumasses',
    'transhumerais',
    'transhumerait',
    'transhumèrent',
    'transhumeriez',
    'transhumerons',
    'transhumeront',
    'transigeaient',
    'transigeantes',
    'transigeasses',
    'transigerions',
    'transissaient',
    'transistorisa',
    'transistorise',
    'transistorisé',
    'transitassent',
    'transitassiez',
    'transiterions',
    'transitionnel',
    'transitivités',
    'translataient',
    'translatasses',
    'translaterais',
    'translaterait',
    'translatèrent',
    'translateriez',
    'translaterons',
    'translateront',
    'translitérais',
    'translitérait',
    'translitérant',
    'translitérées',
    'translitèrent',
    'translitérera',
    'translitériez',
    'translitérons',
    'translittérai',
    'translittéras',
    'translittérât',
    'translittérée',
    'translittérer',
    'translittérés',
    'translittères',
    'translittérez',
    'translocation',
    'translucidité',
    'transmetteurs',
    'transmettions',
    'transmettrais',
    'transmettrait',
    'transmettriez',
    'transmettrons',
    'transmettront',
    'transmigrâmes',
    'transmigrasse',
    'transmigrâtes',
    'transmigrerai',
    'transmigreras',
    'transmigrerez',
    'transmigrions',
    'transmissible',
    'transmissions',
    'transmuassent',
    'transmuassiez',
    'transmuerions',
    'transmutables',
    'transmutaient',
    'transmutasses',
    'transmutation',
    'transmuterais',
    'transmuterait',
    'transmutèrent',
    'transmuteriez',
    'transmuterons',
    'transmuteront',
    'transnational',
    'transocéanien',
    'transparaisse',
    'transparaîtra',
    'transparaître',
    'transparences',
    'transparentes',
    'transparurent',
    'transparusses',
    'transperçâmes',
    'transperçante',
    'transperçants',
    'transperçasse',
    'transperçâtes',
    'transpercerai',
    'transperceras',
    'transpercerez',
    'transpercions',
    'transpiraient',
    'transpirantes',
    'transpirasses',
    'transpiration',
    'transpirerais',
    'transpirerait',
    'transpirèrent',
    'transpireriez',
    'transpirerons',
    'transpireront',
    'transplantais',
    'transplantait',
    'transplantant',
    'transplantées',
    'transplantent',
    'transplantera',
    'transplantiez',
    'transplantoir',
    'transplantons',
    'transpolaires',
    'transportable',
    'transportâmes',
    'transportasse',
    'transportâtes',
    'transporterai',
    'transporteras',
    'transporterez',
    'transporteurs',
    'transporteuse',
    'transportions',
    'transposables',
    'transposaient',
    'transposasses',
    'transposerais',
    'transposerait',
    'transposèrent',
    'transposeriez',
    'transposerons',
    'transposeront',
    'transpositeur',
    'transposition',
    'transpyrénéen',
    'transsaharien',
    'transsexuelle',
    'transsibérien',
    'transsoniques',
    'transsudaient',
    'transsudasses',
    'transsudation',
    'transsuderais',
    'transsuderait',
    'transsudèrent',
    'transsuderiez',
    'transsuderons',
    'transsuderont',
    'transvasaient',
    'transvasasses',
    'transvasement',
    'transvaserais',
    'transvaserait',
    'transvasèrent',
    'transvaseriez',
    'transvaserons',
    'transvaseront',
    'transversales',
    'transvestisme',
    'transvidaient',
    'transvidasses',
    'transviderais',
    'transviderait',
    'transvidèrent',
    'transvideriez',
    'transviderons',
    'transvideront',
    'transylvaines',
    'trapézoïdales',
    'traumatisâmes',
    'traumatisante',
    'traumatisants',
    'traumatisasse',
    'traumatisâtes',
    'traumatiserai',
    'traumatiseras',
    'traumatiserez',
    'traumatisions',
    'traumatologie',
    'traumatologue',
    'travaillaient',
    'travaillasses',
    'travaillerais',
    'travaillerait',
    'travaillèrent',
    'travailleriez',
    'travaillerons',
    'travailleront',
    'travailleuses',
    'travaillismes',
    'travaillistes',
    'traversassent',
    'traversassiez',
    'traverserions',
    'travestirions',
    'travestissais',
    'travestissait',
    'travestissant',
    'travestissent',
    'travestissiez',
    'travestissons',
    'trébuchassent',
    'trébuchassiez',
    'trébuchements',
    'trébucherions',
    'tréfilassions',
    'tréfileraient',
    'treillageâmes',
    'treillageasse',
    'treillageâtes',
    'treillagerais',
    'treillagerait',
    'treillagèrent',
    'treillageriez',
    'treillagerons',
    'treillageront',
    'treillagistes',
    'treillissâmes',
    'treillissasse',
    'treillissâtes',
    'treillisserai',
    'treillisseras',
    'treillisserez',
    'treillissions',
    'treizièmement',
    'trématassions',
    'trémateraient',
    'tremblassions',
    'trembleraient',
    'tremblotaient',
    'tremblotantes',
    'tremblotasses',
    'tremblotement',
    'trembloterais',
    'trembloterait',
    'tremblotèrent',
    'trembloteriez',
    'trembloterons',
    'trembloteront',
    'trémoussaient',
    'trémoussasses',
    'trémoussement',
    'trémousserais',
    'trémousserait',
    'trémoussèrent',
    'trémousseriez',
    'trémousserons',
    'trémousseront',
    'trémulassions',
    'trémuleraient',
    'trente-quatre',
    'trépanassions',
    'trépaneraient',
    'trépasserions',
    'trépidassions',
    'trépideraient',
    'trépignassent',
    'trépignassiez',
    'trépignements',
    'trépignerions',
    'tressaillîmes',
    'tressaillions',
    'tressaillirai',
    'tressailliras',
    'tressaillirez',
    'tressaillisse',
    'tressaillîtes',
    'tressautaient',
    'tressautasses',
    'tressautement',
    'tressauterais',
    'tressauterait',
    'tressautèrent',
    'tressauteriez',
    'tressauterons',
    'tressauteront',
    'treuillassent',
    'treuillassiez',
    'treuillerions',
    'trévirassions',
    'trévireraient',
    'triangulaient',
    'triangulaires',
    'triangulasses',
    'triangulation',
    'triangulerais',
    'triangulerait',
    'triangulèrent',
    'trianguleriez',
    'triangulerons',
    'trianguleront',
    'triballassent',
    'triballassiez',
    'triballerions',
    'tribunitienne',
    'tricentenaire',
    'trichocéphale',
    'trichophytons',
    'tricotassions',
    'tricoteraient',
    'trifouillâmes',
    'trifouillasse',
    'trifouillâtes',
    'trifouillerai',
    'trifouilleras',
    'trifouillerez',
    'trifouillions',
    'trigémellaire',
    'trigonométrie',
    'triloculaires',
    'trimardassent',
    'trimardassiez',
    'trimarderions',
    'trimbalassent',
    'trimbalassiez',
    'trimbalements',
    'trimbalerions',
    'trimballaient',
    'trimballasses',
    'trimballement',
    'trimballerais',
    'trimballerait',
    'trimballèrent',
    'trimballeriez',
    'trimballerons',
    'trimballeront',
    'trimestrielle',
    'tringlassions',
    'tringleraient',
    'trinquassions',
    'trinqueraient',
    'triomphalisme',
    'triomphaliste',
    'triomphassent',
    'triomphassiez',
    'triomphateurs',
    'triomphatrice',
    'triompherions',
    'tripartitions',
    'tripatouillai',
    'tripatouillas',
    'tripatouillât',
    'tripatouillée',
    'tripatouiller',
    'tripatouilles',
    'tripatouillés',
    'tripatouillez',
    'tripotassions',
    'tripoteraient',
    'trirectangles',
    'trisannuelles',
    'triséquassent',
    'triséquassiez',
    'triséquerions',
    'tristounettes',
    'trisyllabique',
    'triturassions',
    'tritureraient',
    'troglodytique',
    'trois-étoiles',
    'troisièmement',
    'trompetassent',
    'trompetassiez',
    'trompetterais',
    'trompetterait',
    'trompetteriez',
    'trompetterons',
    'trompetteront',
    'trompettistes',
    'trompeusement',
    'tronçonnaient',
    'tronçonnasses',
    'tronçonnement',
    'tronçonnerais',
    'tronçonnerait',
    'tronçonnèrent',
    'tronçonneriez',
    'tronçonnerons',
    'tronçonneront',
    'tronçonneuses',
    'tronquassions',
    'tronqueraient',
    'trophoblastes',
    'tropicalisais',
    'tropicalisait',
    'tropicalisant',
    'tropicalisées',
    'tropicalisent',
    'tropicalisera',
    'tropicalisiez',
    'tropicalisons',
    'trottinassent',
    'trottinassiez',
    'trottinements',
    'trottinerions',
    'troublassions',
    'troubleraient',
    'trouillomètre',
    'troussassions',
    'trousse-pieds',
    'trousse-queue',
    'troussequinai',
    'troussequinas',
    'troussequinât',
    'troussequinée',
    'troussequiner',
    'troussequines',
    'troussequinés',
    'troussequinez',
    'trousseraient',
    'truandassions',
    'truanderaient',
    'trucidassions',
    'trucideraient',
    'trufficulture',
    'truiticulture',
    'trusquinaient',
    'trusquinasses',
    'trusquinerais',
    'trusquinerait',
    'trusquinèrent',
    'trusquineriez',
    'trusquinerons',
    'trusquineront',
    'trypsinogènes',
    'tsouin-tsouin',
    'tuberculeuses',
    'tuberculinais',
    'tuberculinait',
    'tuberculinant',
    'tuberculinées',
    'tuberculinent',
    'tuberculinera',
    'tuberculiniez',
    'tuberculinisa',
    'tuberculinise',
    'tuberculinisé',
    'tuberculinons',
    'tuberculisais',
    'tuberculisait',
    'tuberculisant',
    'tuberculisées',
    'tuberculisent',
    'tuberculisera',
    'tuberculisiez',
    'tuberculisons',
    'tubérisations',
    'tuméfiassions',
    'tuméfieraient',
    'turbinassions',
    'turbineraient',
    'turbomachines',
    'turboréacteur',
    'turco-mongole',
    'turco-mongols',
    'turlupinaient',
    'turlupinasses',
    'turlupinerais',
    'turlupinerait',
    'turlupinèrent',
    'turlupineriez',
    'turlupinerons',
    'turlupineront',
    'tuteurassions',
    'tuteureraient',
    'tuyautassions',
    'tuyauteraient',
    'tympanisaient',
    'tympanisasses',
    'tympaniserais',
    'tympaniserait',
    'tympanisèrent',
    'tympaniseriez',
    'tympaniserons',
    'tympaniseront',
    'typographique',
    'tyrannisaient',
    'tyrannisantes',
    'tyrannisasses',
    'tyranniserais',
    'tyranniserait',
    'tyrannisèrent',
    'tyranniseriez',
    'tyranniserons',
    'tyranniseront',
    'tyrannosaures',
    'ultra-courtes',
    'ultramodernes',
    'ultramontaine',
    'ultramontains',
    'ultrasensible',
    'ultra-sonique',
    'ultrasoniques',
    'ultra-violets',
    'ultraviolette',
    'unicellulaire',
    'unificatrices',
    'uniformisâmes',
    'uniformisasse',
    'uniformisâtes',
    'uniformiserai',
    'uniformiseras',
    'uniformiserez',
    'uniformisions',
    'uniloculaires',
    'unisexualités',
    'universalisai',
    'universalisas',
    'universalisât',
    'universalisée',
    'universaliser',
    'universalises',
    'universalisés',
    'universalisez',
    'universalisme',
    'universaliste',
    'universalités',
    'universitaire',
    'univitellines',
    'uranographies',
    'uranoplasties',
    'urbanisassent',
    'urbanisassiez',
    'urbanisations',
    'urbaniserions',
    'urbanistiques',
    'urobilinogène',
    'urobilinuries',
    'uro-génitales',
    'usufructuaire',
    'usufruitières',
    'utilisassions',
    'utilisatrices',
    'utiliseraient',
    'utilitarismes',
    'utilitaristes',
    'vaccinassions',
    'vaccinatrices',
    'vaccineraient',
    'vaccinostyles',
    'vacillassions',
    'vacilleraient',
    'vacuolisaient',
    'vacuolisasses',
    'vacuolisation',
    'vacuoliserais',
    'vacuoliserait',
    'vacuolisèrent',
    'vacuoliseriez',
    'vacuoliserons',
    'vacuoliseront',
    'vadrouillâmes',
    'vadrouillasse',
    'vadrouillâtes',
    'vadrouillerai',
    'vadrouilleras',
    'vadrouillerez',
    'vadrouillions',
    'vagabondaient',
    'vagabondasses',
    'vagabonderais',
    'vagabonderait',
    'vagabondèrent',
    'vagabonderiez',
    'vagabonderons',
    'vagabonderont',
    'vainquissions',
    'vaironnassent',
    'vaironnassiez',
    'vaironnerions',
    'valdinguaient',
    'valdinguasses',
    'valdinguerais',
    'valdinguerait',
    'valdinguèrent',
    'valdingueriez',
    'valdinguerons',
    'valdingueront',
    'valérianelles',
    'valérianiques',
    'valétudinaire',
    'vallonnassent',
    'vallonnassiez',
    'vallonnements',
    'vallonnerions',
    'valorisassent',
    'valorisassiez',
    'valorisations',
    'valoriserions',
    'valousassions',
    'valouseraient',
    'vampirisaient',
    'vampirisasses',
    'vampiriserais',
    'vampiriserait',
    'vampirisèrent',
    'vampiriseriez',
    'vampiriserons',
    'vampiriseront',
    'vandalisaient',
    'vandalisasses',
    'vandaliserais',
    'vandaliserait',
    'vandalisèrent',
    'vandaliseriez',
    'vandaliserons',
    'vandaliseront',
    'vaniteusement',
    'vaporeusement',
    'vaporisassent',
    'vaporisassiez',
    'vaporisateurs',
    'vaporisations',
    'vaporiserions',
    'varappassions',
    'varapperaient',
    'variolisation',
    'varlopassions',
    'varloperaient',
    'vascularisées',
    'vaselinassent',
    'vaselinassiez',
    'vaselinerions',
    'vasouillaient',
    'vasouillardes',
    'vasouillasses',
    'vasouillerais',
    'vasouillerait',
    'vasouillèrent',
    'vasouilleriez',
    'vasouillerons',
    'vasouilleront',
    'vassalisaient',
    'vassalisasses',
    'vassaliserais',
    'vassaliserait',
    'vassalisèrent',
    'vassaliseriez',
    'vassaliserons',
    'vassaliseront',
    'vaticinassent',
    'vaticinassiez',
    'vaticinateurs',
    'vaticinations',
    'vaticinatrice',
    'vaticinerions',
    'vauclusiennes',
    'vaudevilliste',
    'végétariennes',
    'véhémentement',
    'véhiculassent',
    'véhiculassiez',
    'véhiculerions',
    'vélarisassent',
    'vélarisassiez',
    'vélariserions',
    'vélocipédique',
    'veloutassions',
    'velouteraient',
    'vendangeaient',
    'vendangeasses',
    'vendangerions',
    'vénérablement',
    'vénéréologies',
    'ventilassions',
    'ventileraient',
    'ventousassent',
    'ventousassiez',
    'ventouserions',
    'ventriculaire',
    'ventriloquies',
    'ventripotente',
    'ventripotents',
    'verbalisaient',
    'verbalisasses',
    'verbalisation',
    'verbaliserais',
    'verbaliserait',
    'verbalisèrent',
    'verbaliseriez',
    'verbaliserons',
    'verbaliseront',
    'verbiageaient',
    'verbiageasses',
    'verbiagerions',
    'verbigération',
    'verdissements',
    'verdoieraient',
    'verdoyassions',
    'verdunisaient',
    'verdunisasses',
    'verdunisation',
    'verduniserais',
    'verduniserait',
    'verdunisèrent',
    'verduniseriez',
    'verduniserons',
    'verduniseront',
    'véridiquement',
    'vérifiassions',
    'vérificateurs',
    'vérifications',
    'vérificatives',
    'vérificatrice',
    'vérifieraient',
    'véritablement',
    'verjutassions',
    'verjuteraient',
    'vermiculaient',
    'vermiculaires',
    'vermiculasses',
    'vermiculerais',
    'vermiculerait',
    'vermiculèrent',
    'vermiculeriez',
    'vermiculerons',
    'vermiculeront',
    'vermillassent',
    'vermillassiez',
    'vermillerions',
    'vermillonnais',
    'vermillonnait',
    'vermillonnant',
    'vermillonnées',
    'vermillonnent',
    'vermillonnera',
    'vermillonniez',
    'vermillonnons',
    'vermoulassent',
    'vermoulassiez',
    'vermoulerions',
    'vernaculaires',
    'vernalisation',
    'vernissassent',
    'vernissassiez',
    'vernisserions',
    'verrouillages',
    'verrouillâmes',
    'verrouillasse',
    'verrouillâtes',
    'verrouillerai',
    'verrouilleras',
    'verrouillerez',
    'verrouillions',
    'versaillaises',
    'versifiassent',
    'versifiassiez',
    'versificateur',
    'versification',
    'versifierions',
    'vers-librisme',
    'vers-libriste',
    'vert-de-grisé',
    'verticalement',
    'vertigineuses',
    'vertueusement',
    'vespertilions',
    'vesse-de-loup',
    'vestibulaires',
    'vestimentaire',
    'vétillassions',
    'vétilleraient',
    'viabilisaient',
    'viabilisasses',
    'viabiliserais',
    'viabiliserait',
    'viabilisèrent',
    'viabiliseriez',
    'viabiliserons',
    'viabiliseront',
    'vibraphoniste',
    'vibrionnaient',
    'vibrionnasses',
    'vibrionnerais',
    'vibrionnerait',
    'vibrionnèrent',
    'vibrionneriez',
    'vibrionnerons',
    'vibrionneront',
    'vibromasseurs',
    'vice-consulat',
    'vice-légation',
    'vice-recteurs',
    'vice-royautés',
    'vidangeassent',
    'vidangeassiez',
    'vidangeraient',
    'vidéocassette',
    'vieilliraient',
    'vieillissante',
    'vieillissants',
    'vieillissions',
    'vietnamiennes',
    'vilipendaient',
    'vilipendasses',
    'vilipenderais',
    'vilipenderait',
    'vilipendèrent',
    'vilipenderiez',
    'vilipenderons',
    'vilipenderont',
    'ville-dortoir',
    'villégiateurs',
    'villégiaturai',
    'villégiaturas',
    'villégiaturât',
    'villégiaturer',
    'villégiatures',
    'villégiaturez',
    'vinaigrassent',
    'vinaigrassiez',
    'vinaigrerions',
    'vingtièmement',
    'vinifiassions',
    'vinifications',
    'vinifieraient',
    'violaçassions',
    'violaceraient',
    'violentassent',
    'violentassiez',
    'violenterions',
    'violonassions',
    'violoneraient',
    'vioquissaient',
    'virevoltaient',
    'virevoltasses',
    'virevolterais',
    'virevolterait',
    'virevoltèrent',
    'virevolteriez',
    'virevolterons',
    'virevolteront',
    'virgulassions',
    'virguleraient',
    'virilisassent',
    'virilisassiez',
    'virilisations',
    'viriliserions',
    'virostatiques',
    'virtuellement',
    'viscéralement',
    'viscosimètres',
    'visionnassent',
    'visionnassiez',
    'visionnements',
    'visionnerions',
    'visiophonique',
    'visualisaient',
    'visualisasses',
    'visualisation',
    'visualiserais',
    'visualiserait',
    'visualisèrent',
    'visualiseriez',
    'visualiserons',
    'visualiseront',
    'viticultrices',
    'vitrifiassent',
    'vitrifiassiez',
    'vitrification',
    'vitrifierions',
    'vitriolassent',
    'vitriolassiez',
    'vitriolerions',
    'vitupérassent',
    'vitupérassiez',
    'vitupérateurs',
    'vitupérations',
    'vitupératrice',
    'vitupérerions',
    'vivifiassions',
    'vivificateurs',
    'vivifications',
    'vivificatrice',
    'vivifieraient',
    'vocalisassent',
    'vocalisassiez',
    'vocalisateurs',
    'vocalisations',
    'vocalisatrice',
    'vocaliserions',
    'vociférassent',
    'vociférassiez',
    'vociférateurs',
    'vociférations',
    'vocifératrice',
    'vociférerions',
    'voisinassions',
    'voisineraient',
    'voiturassions',
    'voitureraient',
    'volatilisable',
    'volatilisâmes',
    'volatilisasse',
    'volatilisâtes',
    'volatiliserai',
    'volatiliseras',
    'volatiliserez',
    'volatilisions',
    'volcanisaient',
    'volcanisasses',
    'volcaniserais',
    'volcaniserait',
    'volcanisèrent',
    'volcaniseriez',
    'volcaniserons',
    'volcaniseront',
    'volcanologies',
    'volcanologues',
    'voletteraient',
    'voligeassions',
    'volontarismes',
    'volontaristes',
    'voltairiennes',
    'voltaïsations',
    'voltigeassent',
    'voltigeassiez',
    'voltigeraient',
    'Volucompteurs',
    'volumétriques',
    'vousoieraient',
    'vousoyassions',
    'voussoiements',
    'voussoierions',
    'voussoyassent',
    'voussoyassiez',
    'vouvoieraient',
    'vouvoyassions',
    'voyageassions',
    'vraisemblable',
    'vraisemblance',
    'vrombissaient',
    'vrombissement',
    'vulcanisaient',
    'vulcanisasses',
    'vulcanisation',
    'vulcaniserais',
    'vulcaniserait',
    'vulcanisèrent',
    'vulcaniseriez',
    'vulcaniserons',
    'vulcaniseront',
    'vulcanologies',
    'vulcanologues',
    'vulgarisaient',
    'vulgarisasses',
    'vulgarisateur',
    'vulgarisation',
    'vulgariserais',
    'vulgariserait',
    'vulgarisèrent',
    'vulgariseriez',
    'vulgariserons',
    'vulgariseront',
    'vulnérabilité',
    'wagon-citerne',
    'wagons-salons',
    'wagons-vannes',
    'walkie-talkie',
    'warrantassent',
    'warrantassiez',
    'warranterions',
    'washingtonias',
    'water-ballast',
    'water-closets',
    'wellingtonias',
    'white-spirits',
    'wisigothiques',
    'xanthophylles',
    'xérophtalmies',
    'xiphoïdiennes',
    'xylographique',
    'zigouillaient',
    'zigouillasses',
    'zigouillerais',
    'zigouillerait',
    'zigouillèrent',
    'zigouilleriez',
    'zigouillerons',
    'zigouilleront',
    'zigzaguassent',
    'zigzaguassiez',
    'zigzaguerions',
    'zincographies',
    'zinjanthropes',
    'zinzinulaient',
    'zinzinulasses',
    'zinzinulerais',
    'zinzinulerait',
    'zinzinulèrent',
    'zinzinuleriez',
    'zinzinulerons',
    'zinzinuleront',
    'zoogéographie',
    'zoomorphismes',
    'zoosémiotique',
    'zootechnicien',
    'zootechniques',
    'zoroastrienne',
    'zoroastrismes',
    'zwinglianisme'
];
