export default {
    name: "Drider de l'enfer",
    boostOneShot: {
        lv1: 1,
        lv2: 1,
        lv3: 5,
        lv4: NaN,
        lv5: NaN
    },
    oneShot: {
        lv1: 1,
        lv2: 3,
        lv3: 12,
        lv4: NaN,
        lv5: NaN
    },
    boostOneByOne: {
        lv1: NaN,
        lv2: NaN,
        lv3: NaN,
        lv4: NaN,
        lv5: NaN
    },
    oneByOne: {
        lv1: NaN,
        lv2: NaN,
        lv3: NaN,
        lv4: NaN,
        lv5: NaN
    }
};
