export const T_2 = {
    type: 'tramways',
    line: '2',
    lineRef: 'STIF:Line::C01390:',
    nextStopRef: 'STIF:StopArea:SP:25641:',
    departureStation: 'Pont de Bezons',
    direction: 'R',
    directionName: 'Porte de Versailles',
    style: {
        bgcolor: 'rgb(207, 0, 158)',
        color: 'white'
    },
    avatar: 'T2'
};
export const RER_A = {
    type: 'rers',
    line: 'A',
    lineRef: 'STIF:Line::C01742:',
    nextStopRef: 'STIF:StopArea:SP:470549:',
    departureStation: 'La Défense',
    direction: 'R',
    directionName: 'Nation',
    style: {
        bgcolor: 'rgb(235, 33, 50)',
        color: 'white'
    },
    avatar: 'A'
};
export const METRO_1 = {
    type: 'metros',
    line: '1',
    lineRef: 'STIF:Line::C01371:',
    nextStopRef: 'STIF:StopArea:SP:22101:',
    departureStation: 'La Défense',
    direction: 'R',
    directionName: 'Chateau de Vincennes',
    style: {
        bgcolor: 'rgb(255, 205, 0)',
        color: 'black'
    },
    avatar: '1'
};
export const ALL = [T_2, RER_A, METRO_1];
export const API_URL = 'https://prim.iledefrance-mobilites.fr';
