import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { create, fetchConf } from '../spending.actions';
import { getDefaultExpenses, getSpendingIsLoading } from '../spending.selectors';
import {
    TextField,
    Typography,
    FormControlLabel,
    Switch,
    Button,
    CircularProgress
} from '@mui/material';
import { payers } from '../spending.const';
import { useNavigate } from 'react-router-dom';
import PageTitle from '../../common/Components/PageTitle';

export default function CreateSpending() {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [useDefaultExpenses, setUseDefaultExpenses] = useState(true);
    const isLoading = useSelector(getSpendingIsLoading);
    const defaultExpenses = useSelector(getDefaultExpenses);

    const dispatch = useDispatch();

    useEffect(() => {
        document.title = 'Home - Dépenses - création';
        if (!isLoading && defaultExpenses.length === 0) {
            dispatch(fetchConf());
        }
    }, []);

    async function handleSave() {
        if (name) {
            const spending = {
                name,
                expenses: useDefaultExpenses ? defaultExpenses : []
            };
            await dispatch(create(spending));
            navigate('/spending');
        }
    }

    return (
        <React.Fragment>
            <PageTitle title="Création de mois" />
            <div className="row mt-s">
                <TextField
                    label="Nom du mois"
                    placeholder="Janvier 2021"
                    value={name}
                    fullWidth
                    type="text"
                    onChange={(e) => setName(e.target.value)}
                />
            </div>
            <div className="row mt-s">
                <FormControlLabel
                    control={
                        <Switch
                            checked={useDefaultExpenses}
                            onChange={(e) => setUseDefaultExpenses(e.target.checked)}
                        />
                    }
                    label="Utiliser les dépenses par défaut"
                />
            </div>

            {useDefaultExpenses ? (
                <React.Fragment>
                    <div className="row mt-s">
                        <Typography variant="h6">Dépenses par défaut</Typography>
                    </div>
                    {isLoading ? (
                        <div className="row justify-center">
                            <CircularProgress />
                        </div>
                    ) : (
                        <div className="row">
                            <ul className="mt-xs mb-s">
                                {defaultExpenses.map((expense, i) => (
                                    <li key={i}>
                                        <b>{expense.name}</b> : {expense.price}€ de {expense.payer}{' '}
                                        pour{' '}
                                        {expense.both
                                            ? 'tous les deux'
                                            : expense.payer === payers.Audrey
                                            ? payers.Morgan
                                            : payers.Audrey}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </React.Fragment>
            ) : null}

            <div className="row space-between mt-s">
                <Button
                    variant="outlined"
                    color="error"
                    onClick={() => navigate('/spending')}
                    disabled={isLoading}
                >
                    Annuler
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    disabled={isLoading}
                >
                    Créer
                </Button>
            </div>
        </React.Fragment>
    );
}
