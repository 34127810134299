import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import KeyIcon from '@mui/icons-material/VpnKey';
import ExitIcon from '@mui/icons-material/ExitToApp';
import InfoIcon from '@mui/icons-material/Info';
import Transport from '@mui/icons-material/DirectionsSubway';
import ClearIcon from '@mui/icons-material/Clear';
import HomeIcon from '@mui/icons-material/Home';
import { getAuthIsLoggedIn } from '../../Auth/auth.selectors';
import { logOut } from '../../Auth/auth.actions';
import MenuIcon from '@mui/icons-material/Menu';
import InformationModal from './InformationModal';
import { AccountBalanceWallet, AdminPanelSettings, VideogameAsset } from '@mui/icons-material';

export default function Header() {
    const [isInformationModalOpen, setIsInformationModalOpen] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isLoggedIn = useSelector(getAuthIsLoggedIn);

    function navigateTo(path) {
        navigate(path);
        setIsDrawerOpen(false);
    }

    function handleLogOut() {
        dispatch(logOut());
        setIsDrawerOpen(false);
    }

    return (
        <React.Fragment>
            <AppBar position="sticky">
                <Toolbar>
                    {isLoggedIn && (
                        <IconButton
                            aria-label="menu"
                            onClick={() => setIsDrawerOpen(true)}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    <Typography style={{ width: '1em' }} />
                    <Typography style={{ flexGrow: 1 }} />
                    {!isLoggedIn && (
                        <React.Fragment>
                            <IconButton
                                aria-label="login"
                                onClick={() => navigateTo('/sign-in')}
                                color="inherit"
                            >
                                <KeyIcon />
                            </IconButton>
                        </React.Fragment>
                    )}
                    {isLoggedIn && (
                        <React.Fragment>
                            <IconButton aria-label="login" onClick={handleLogOut} color="inherit">
                                <ExitIcon />
                            </IconButton>
                        </React.Fragment>
                    )}
                    <IconButton
                        aria-label="application informations"
                        onClick={() => setIsInformationModalOpen(true)}
                        color="inherit"
                    >
                        <InfoIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer anchor="left" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
                <List>
                    <ListItem onClick={() => setIsDrawerOpen(false)}>
                        <ListItemIcon>
                            <ClearIcon />
                        </ListItemIcon>
                        <ListItemText primary="Home" primaryTypographyProps={{ variant: 'h6' }} />
                    </ListItem>
                    <ListItem onClick={() => navigateTo('/')}>
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Accueil" />
                    </ListItem>
                    <ListItem onClick={() => navigateTo('/transport')}>
                        <ListItemIcon>
                            <Transport />
                        </ListItemIcon>
                        <ListItemText primary="Transports" />
                    </ListItem>
                    <ListItem onClick={() => navigateTo('/admin')}>
                        <ListItemIcon>
                            <AdminPanelSettings />
                        </ListItemIcon>
                        <ListItemText primary="Administration" />
                    </ListItem>
                    <ListItem onClick={() => navigateTo('/spending')}>
                        <ListItemIcon>
                            <AccountBalanceWallet />
                        </ListItemIcon>
                        <ListItemText primary="Dépenses" />
                    </ListItem>
                    <ListItem onClick={() => navigateTo('/cheats')}>
                        <ListItemIcon>
                            <VideogameAsset />
                        </ListItemIcon>
                        <ListItemText primary="Triches" />
                    </ListItem>
                    <ListItem onClick={() => navigateTo('/kid-games')}>
                        <ListItemIcon>
                            <VideogameAsset />
                        </ListItemIcon>
                        <ListItemText primary="Jeux pour enfants" />
                    </ListItem>
                </List>
            </Drawer>
            <InformationModal
                open={isInformationModalOpen}
                onClose={() => setIsInformationModalOpen(false)}
            />
        </React.Fragment>
    );
}
