import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    IconButton,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Switch,
    FormControlLabel,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { payers } from '../spending.const';

export default function ExpenseModal(props) {
    const [name, setName] = useState('');
    const [price, setPrice] = useState(0);
    const [payer, setPayer] = useState(payers.Morgan);
    const [consider, setConsider] = useState(true);
    const [both, setBoth] = useState(true);
    const [comment, setComment] = useState('');
    const { open, onClose, showConsider } = props;

    function clean() {
        setName('');
        setPrice(0);
        setPayer(payers.Morgan);
        setBoth(true);
        setComment('');
    }

    function handleCancel() {
        clean();
        onClose();
    }

    function handleSave() {
        const expense = {
            name,
            price,
            payer,
            consider,
            both,
            comment
        };
        onClose(expense);
        clean();
    }

    function handlePriceChange(e) {
        const parsed = parseFloat(e.target.value);
        if (!isNaN(parsed)) {
            setPrice(parsed);
        }
    }

    return (
        <React.Fragment>
            <Dialog open={open} onClose={() => onClose()} aria-label="modal informative">
                <DialogTitle className="closable-modal__title">
                    <div className="row space-between">
                        <div className="col justify-center">Création d&apos;une dépense</div>
                        <div className="col justify-center">
                            <IconButton aria-label="close" onClick={() => onClose()}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className="row mt-s">
                        <TextField
                            required
                            fullWidth
                            id="name"
                            label="Nom"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className="row mt-s">
                        <TextField
                            required
                            fullWidth
                            id="price"
                            label="Prix"
                            type="number"
                            value={price}
                            onChange={handlePriceChange}
                        />
                    </div>
                    <div className="row mt-s">
                        <FormControl fullWidth>
                            <InputLabel id="payer">Acheteur</InputLabel>
                            <Select
                                labelId="payer-label"
                                id="payer-select"
                                value={payer}
                                label="Acheteur"
                                onChange={(e) => setPayer(e.target.value)}
                            >
                                <MenuItem value={payers.Audrey}>{payers.Audrey}</MenuItem>
                                <MenuItem value={payers.Morgan}>{payers.Morgan}</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <div className="row mt-s">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={both}
                                    onChange={(e) => setBoth(e.target.checked)}
                                />
                            }
                            label="Dépense commune"
                        />
                    </div>

                    {showConsider ? (
                        <div className="row mt-s">
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={consider}
                                        onChange={(e) => setConsider(e.target.checked)}
                                    />
                                }
                                label="Dépense à compter"
                            />
                        </div>
                    ) : null}

                    <div className="row mt-s">
                        <TextField
                            id="comment"
                            fullWidth
                            label="Commentaire"
                            multiline
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className="row space-between">
                        <Button onClick={handleCancel} color="error">
                            Annuler
                        </Button>
                        <Button onClick={handleSave} variant="contained">
                            Ajouter
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

ExpenseModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    showConsider: PropTypes.bool
};
