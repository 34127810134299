export const getSpendingState = (store) => store.spending;
export const getSpendingIsLoading = (store) =>
    getSpendingState(store) ? getSpendingState(store).isLoading : false;
export const getAllSpendings = (store) =>
    getSpendingState(store) ? getSpendingState(store).spendings : [];
export const getSpending = (store, id) => {
    const state = getSpendingState(store);
    if (state.spendings.length > 0) {
        return state.spendings.filter((s) => s.docId === id)[0];
    }
    return null;
};
export const getDefaultExpenses = (store) =>
    getSpendingState(store) ? getSpendingState(store).defaultExpenses : [];
