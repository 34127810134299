import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Alert } from '@mui/material';
import { getAuthIsLoggedIn, getAuthHasError, getAuthLoading } from './auth.selectors';
import { signIn } from './auth.actions';

export default function Auth() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isFormValid, setIsValidForm] = useState(false);
    const isLoggedIn = useSelector(getAuthIsLoggedIn);
    const isLoading = useSelector(getAuthLoading);
    const hasError = useSelector(getAuthHasError);
    const dispatch = useDispatch();

    useEffect(() => {
        document.title = 'Home - connexion';
    });

    useEffect(() => {
        checkForm();
    }, [email, password]);

    useEffect(() => {
        if (isLoggedIn === true) {
            navigate('/home');
        }
    }, [isLoggedIn]);

    function handleSubmit() {
        if (isFormValid) {
            dispatch(signIn(email, password));
        }
    }

    function checkForm() {
        const status = !!(email && password);
        setIsValidForm(status);
    }

    function handleKeyUp(event) {
        if (event.keyCode === 13) {
            handleSubmit();
        }
    }

    return (
        <div className="row justify-center mt-l">
            <div className="col full" style={{ maxWidth: '500px' }}>
                <div className="row mt-m justify-center">
                    <h1>Connexion</h1>
                </div>
                <div className="row mt-xs justify-center">
                    <TextField
                        label="Email"
                        placeholder="Email"
                        value={email}
                        fullWidth
                        required
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyUp={(event) => handleKeyUp(event)}
                    />
                </div>
                <div className="row mt-m justify-center">
                    <TextField
                        label="Mot de passe"
                        placeholder="Mot de passe"
                        value={password}
                        fullWidth
                        required
                        type="password"
                        autoComplete="off"
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyUp={(event) => handleKeyUp(event)}
                    />
                </div>
                {hasError ? (
                    <div className="row mt-m">
                        <Alert severity="error" className="full">
                            Adresse email ou mot de passe invalide
                        </Alert>
                    </div>
                ) : null}
                <div className="row justify-center mt-l mb-l">
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={isLoggedIn || isLoading}
                        onClick={handleSubmit}
                    >
                        Se connecter
                    </Button>
                </div>
            </div>
        </div>
    );
}
