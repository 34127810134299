export default [
    'anticonstitutionnelles',
    'arrière-grands-parents',
    'arrière-petites-filles',
    'arrière-petites-nièces',
    'arrière-petits-enfants',
    'autosuggestionnassions',
    'constitutionnalisaient',
    'constitutionnalisasses',
    'constitutionnaliserais',
    'constitutionnaliserait',
    'constitutionnalisèrent',
    'constitutionnaliseriez',
    'constitutionnaliserons',
    'constitutionnaliseront',
    'contre-électromotrices',
    'contre-interrogatoires',
    'contre-manifestassions',
    'contre-manifesteraient',
    'contre-révolutionnaire',
    'correctionnalisassions',
    'correctionnaliseraient',
    'démocrates-chrétiennes',
    'départementalisassions',
    'désapprovisionnassions',
    'désapprovisionneraient',
    'électroencéphalogramme',
    'institutionnalisassent',
    'institutionnalisassiez',
    'institutionnalisations',
    'institutionnaliserions',
    'internationalisassions',
    'internationaliseraient',
    'médico-professionnelle',
    'oto-rhino-laryngologie',
    'pistolets-mitrailleurs',
    'radiocristallographies',
    'radiotélégraphiassions',
    'radiotélégraphieraient',
    'réimperméabilisassions',
    'réimperméabiliseraient',
    'socio-professionnelles',
    'survolteurs-dévolteurs'
];
