import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
    IconButton,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getAppVersion } from '../app.selectors';

function InformationModal(props) {
    const version = useSelector(getAppVersion);
    const { open, onClose } = props;

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={() => onClose()}
                aria-label="modal informative"
                maxWidth="md"
            >
                <DialogTitle className="closable-modal__title">
                    <div className="row space-between">
                        <div className="col justify-center">À propos</div>
                        <div className="col justify-center">
                            <IconButton aria-label="close" onClick={() => onClose()}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        Créé par&nbsp;
                        <a href="mailto:morgan.denis.60@gmail.com">morgan.denis.60@gmail.com</a>
                    </Typography>
                    <Typography>Réalisé avec React</Typography>
                    <Typography>Version {version}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onClose()}>Fermer</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

InformationModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default InformationModal;
