import ailesDeGivre from './ailes-de-givre';
import ailesNoires from './ailes-noires';
import beteDesNeiges from './bete-des-neiges';
import chamanVaudou from './chaman-vaudou';
import driderDeLEnfer from './drider-de-lenfer';
import epinator from './epinator';
import faucheuse from './faucheuse';
import gargantua from './gargantua';
import golemAntique from './golem-antique';
import griffon from './griffon';
import mechaTroyen from './mecha-troyen';
import megaLarve from './mega-larve';
import morphalange from './morphalange';
import noceros from './noceros';
import reineAbeille from './reine-abeille';
import sabercroc from './sabercroc';
import serrulule from './serrulule';
import titanDesMarrees from './titan-des-marrees';
import wyrmDeJade from './wyrm-de-jade';

export default [
    ailesDeGivre,
    ailesNoires,
    beteDesNeiges,
    chamanVaudou,
    driderDeLEnfer,
    epinator,
    faucheuse,
    gargantua,
    golemAntique,
    griffon,
    mechaTroyen,
    megaLarve,
    morphalange,
    noceros,
    reineAbeille,
    sabercroc,
    serrulule,
    titanDesMarrees,
    wyrmDeJade
];
