export default {
    name: 'Mega larve',
    boostOneShot: {
        lv1: 1,
        lv2: 2,
        lv3: NaN,
        lv4: NaN,
        lv5: NaN
    },
    oneShot: {
        lv1: 2,
        lv2: 5,
        lv3: NaN,
        lv4: NaN,
        lv5: NaN
    },
    boostOneByOne: {
        lv1: NaN,
        lv2: NaN,
        lv3: NaN,
        lv4: NaN,
        lv5: NaN
    },
    oneByOne: {
        lv1: NaN,
        lv2: NaN,
        lv3: NaN,
        lv4: NaN,
        lv5: NaN
    }
};
