export default [
    'abats',
    'abbés',
    'abcès',
    'abêti',
    'abîma',
    'abîme',
    'abîmé',
    'aboie',
    'abois',
    'aboli',
    'abord',
    'abots',
    'about',
    'aboya',
    'aboyé',
    'abris',
    'abusa',
    'abuse',
    'abusé',
    'accès',
    'accot',
    'accru',
    'accus',
    'acéra',
    'acéré',
    'acère',
    'achat',
    'acide',
    'acier',
    'acini',
    'acmés',
    'acnés',
    'acons',
    'acore',
    'acres',
    'âcres',
    'actai',
    'actas',
    'actât',
    'actée',
    'acter',
    'actes',
    'actez',
    'actif',
    'adage',
    'adent',
    'adieu',
    'admet',
    'admis',
    'admit',
    'admît',
    'adnée',
    'adnés',
    'adora',
    'adore',
    'adoré',
    'adret',
    'adula',
    'adule',
    'adulé',
    'aèdes',
    'aequo',
    'aérai',
    'aéras',
    'aérât',
    'aérée',
    'aérer',
    'aérés',
    'aères',
    'aérez',
    'affin',
    'affût',
    'agaça',
    'agace',
    'agacé',
    'agami',
    'agape',
    'agate',
    'agave',
    'agavé',
    'âgées',
    'agent',
    'aghas',
    'agile',
    'agios',
    'agira',
    'agita',
    'agite',
    'agité',
    'agnat',
    'agora',
    'agréa',
    'agrée',
    'agréé',
    'agrès',
    'aguis',
    'ahana',
    'ahane',
    'ahané',
    'ahans',
    'ahuri',
    'aiche',
    'aidai',
    'aidas',
    'aidât',
    'aidée',
    'aider',
    'aides',
    'aidés',
    'aidez',
    'aient',
    'aïeul',
    'aïeux',
    'aigle',
    'aigre',
    'aigri',
    'aiguë',
    'aigus',
    'ai-je',
    'ailée',
    'ailes',
    'ailés',
    'ailla',
    'aille',
    'aillé',
    'aimai',
    'aimas',
    'aimât',
    'aimée',
    'aimer',
    'aimes',
    'aimés',
    'aimez',
    'aînée',
    'aines',
    'aînés',
    'ainsi',
    'aïoli',
    'airai',
    'airas',
    'airât',
    'airer',
    'aires',
    'airez',
    'aisée',
    'aises',
    'aisés',
    'aisys',
    'ajonc',
    'ajour',
    'ajout',
    'album',
    'aldin',
    'aldol',
    'aléas',
    'alêne',
    'aleph',
    'alésa',
    'alésé',
    'alèse',
    'alfas',
    'algie',
    'algol',
    'algue',
    'alias',
    'alibi',
    'alios',
    'alise',
    'alita',
    'alite',
    'alité',
    'alizé',
    'allai',
    'allas',
    'allât',
    'allée',
    'aller',
    'allés',
    'allez',
    'allia',
    'allie',
    'allié',
    'almée',
    'aloès',
    'alors',
    'alose',
    'alpax',
    'alpes',
    'alpha',
    'alpin',
    'altos',
    'aluna',
    'alune',
    'aluné',
    'aluni',
    'aluns',
    'alvin',
    'alyte',
    'amant',
    'amati',
    'ambla',
    'amble',
    'amblé',
    'ambon',
    'ambra',
    'ambre',
    'ambré',
    'amena',
    'amené',
    'amène',
    'amère',
    'amers',
    'amibe',
    'amict',
    'amide',
    'amies',
    'aminé',
    'amont',
    'amour',
    'amphi',
    'ample',
    'ampli',
    'amuïe',
    'amuïs',
    'amura',
    'amure',
    'amuré',
    'amusa',
    'amuse',
    'amusé',
    'amyle',
    'anale',
    'anaux',
    'anche',
    'ancra',
    'ancre',
    'ancré',
    'andin',
    'aneth',
    'anges',
    'angle',
    'angon',
    'angor',
    'ânier',
    'anima',
    'anime',
    'animé',
    'anion',
    'anisa',
    'anise',
    'anisé',
    'annal',
    'année',
    'anode',
    'ânons',
    'ansée',
    'anses',
    'ansés',
    'antan',
    'antes',
    'antre',
    'aorte',
    'aoûta',
    'aoûte',
    'aoûté',
    'aphte',
    'à-pic',
    'apiol',
    'apion',
    'aplat',
    'apnée',
    'apode',
    'appas',
    'appât',
    'appel',
    'appui',
    'après',
    'âpres',
    'aptes',
    'apura',
    'apure',
    'apuré',
    'arabe',
    'arasa',
    'arase',
    'arasé',
    'arbre',
    'arche',
    'arçon',
    'ardue',
    'ardus',
    'arecs',
    'arène',
    'arête',
    'argas',
    'argon',
    'argot',
    'argua',
    'argue',
    'argué',
    'argus',
    'arias',
    'aride',
    'arien',
    'arisa',
    'arise',
    'arisé',
    'armai',
    'armas',
    'armât',
    'armée',
    'armer',
    'armes',
    'armés',
    'armet',
    'armez',
    'armon',
    'arôme',
    'arqua',
    'arque',
    'arqué',
    'arrêt',
    'arsin',
    'artel',
    'arums',
    'aryen',
    'aryle',
    'asile',
    'aspes',
    'aspic',
    'asque',
    'assai',
    'asses',
    'assez',
    'assis',
    'assit',
    'assît',
    'aster',
    'astis',
    'astre',
    'as-tu',
    'atèle',
    'athée',
    'atlas',
    'atoll',
    'atome',
    'atone',
    'atour',
    'atout',
    'aubes',
    'aubin',
    'aucun',
    'audio',
    'audit',
    'auges',
    'auget',
    'aulne',
    'aunes',
    'aurai',
    'auras',
    'aurez',
    'aussi',
    'autel',
    'autos',
    'autre',
    'avais',
    'avait',
    'avala',
    'avale',
    'avalé',
    'avals',
    'avant',
    'avare',
    'avens',
    'avenu',
    'avéra',
    'avéré',
    'avère',
    'avers',
    'aveux',
    'avide',
    'aviez',
    'avili',
    'avina',
    'avine',
    'aviné',
    'avion',
    'avisa',
    'avise',
    'avisé',
    'aviso',
    'aviva',
    'avive',
    'avivé',
    'avoir',
    'avons',
    'avoua',
    'avoue',
    'avoué',
    'avril',
    'axais',
    'axait',
    'axant',
    'axées',
    'axent',
    'axera',
    'axiez',
    'axile',
    'axone',
    'axons',
    'ayant',
    'ayons',
    'azote',
    'azoté',
    'azura',
    'azure',
    'azuré',
    'azurs',
    'azyme',
    'babas',
    'babil',
    'babys',
    'bâcha',
    'bâche',
    'bâché',
    'bâcla',
    'bâcle',
    'bâclé',
    'bacon',
    'bacul',
    'badge',
    'badin',
    'baffa',
    'baffe',
    'baffé',
    'bâfra',
    'bâfre',
    'bâfré',
    'bagad',
    'bagne',
    'bagou',
    'bagua',
    'bague',
    'bagué',
    'bahut',
    'baies',
    'bains',
    'baisa',
    'baise',
    'baisé',
    'balai',
    'balès',
    'balla',
    'balle',
    'ballé',
    'balsa',
    'balte',
    'banal',
    'banco',
    'bancs',
    'banda',
    'bande',
    'bandé',
    'bangs',
    'banjo',
    'banna',
    'banne',
    'banné',
    'banni',
    'barba',
    'barbe',
    'barbé',
    'barbu',
    'barda',
    'barde',
    'bardé',
    'bards',
    'barge',
    'baril',
    'barns',
    'baron',
    'barra',
    'barre',
    'barré',
    'barri',
    'barye',
    'basai',
    'basal',
    'basas',
    'basât',
    'basée',
    'baser',
    'bases',
    'basés',
    'basez',
    'basic',
    'basin',
    'basse',
    'baste',
    'bâtai',
    'bâtas',
    'bâtât',
    'bâtée',
    'bâter',
    'bâtes',
    'bâtés',
    'bâtez',
    'bâtie',
    'batik',
    'bâtir',
    'bâtis',
    'bâtit',
    'bâtît',
    'bâton',
    'batte',
    'battu',
    'bauds',
    'bauge',
    'baugé',
    'baume',
    'bavai',
    'bavas',
    'bavât',
    'baver',
    'baves',
    'bavez',
    'bayai',
    'bayas',
    'bayât',
    'bayer',
    'bayes',
    'bayez',
    'bayou',
    'bazar',
    'béais',
    'béait',
    'béant',
    'béate',
    'béats',
    'beauf',
    'beaux',
    'bébés',
    'bêcha',
    'bêche',
    'bêché',
    'bécot',
    'becta',
    'becte',
    'becté',
    'bedon',
    'béent',
    'béera',
    'béguë',
    'bègue',
    'bégum',
    'béiez',
    'beige',
    'békés',
    'bêlai',
    'bêlas',
    'bêlât',
    'bêlée',
    'bêler',
    'bêles',
    'bêlés',
    'bêlez',
    'belge',
    'belle',
    'belon',
    'bémol',
    'bénef',
    'benêt',
    'bénie',
    'bénin',
    'bénir',
    'bénis',
    'bénit',
    'bénît',
    'benne',
    'béons',
    'béqua',
    'béqué',
    'bèque',
    'berça',
    'berce',
    'bercé',
    'béret',
    'berge',
    'berme',
    'berna',
    'berne',
    'berné',
    'béryl',
    'bésef',
    'bêtas',
    'bétel',
    'bêtes',
    'béton',
    'bette',
    'beurs',
    'bévue',
    'biais',
    'bibis',
    'bible',
    'bicha',
    'biche',
    'biché',
    'bicot',
    'bides',
    'bidet',
    'bidon',
    'biefs',
    'biens',
    'bière',
    'biffa',
    'biffe',
    'biffé',
    'bigla',
    'bigle',
    'biglé',
    'bigot',
    'bigre',
    'bigue',
    'bijou',
    'bilai',
    'bilan',
    'bilas',
    'bilât',
    'bilée',
    'biler',
    'biles',
    'bilés',
    'bilez',
    'bille',
    'bills',
    'binai',
    'binas',
    'binât',
    'binée',
    'biner',
    'bines',
    'binés',
    'binez',
    'bingo',
    'bique',
    'birbe',
    'bisai',
    'bisas',
    'bisât',
    'bisée',
    'biser',
    'bises',
    'bisés',
    'biset',
    'bisez',
    'bison',
    'bisou',
    'bissa',
    'bisse',
    'bissé',
    'bitai',
    'bitas',
    'bitât',
    'bitée',
    'biter',
    'bites',
    'bités',
    'bitez',
    'bitos',
    'bitta',
    'bitte',
    'bitté',
    'bizut',
    'black',
    'blair',
    'blâma',
    'blâme',
    'blâmé',
    'blanc',
    'blaps',
    'blasa',
    'blase',
    'blasé',
    'bleds',
    'blême',
    'blêmi',
    'blésa',
    'blésé',
    'blèse',
    'blets',
    'bleue',
    'bleui',
    'bleus',
    'blocs',
    'blond',
    'blues',
    'bluet',
    'bluff',
    'bluta',
    'blute',
    'bluté',
    'bobos',
    'bocal',
    'boche',
    'bocks',
    'boëte',
    'boeuf',
    'bogie',
    'bogue',
    'boira',
    'boire',
    'boisa',
    'boise',
    'boisé',
    'boita',
    'boite',
    'boité',
    'boîte',
    'boive',
    'boldo',
    'bolée',
    'bolet',
    'bomba',
    'bombe',
    'bombé',
    'bômes',
    'bonda',
    'bonde',
    'bondé',
    'bondi',
    'bonds',
    'bonis',
    'bonne',
    'bonté',
    'bonus',
    'bonze',
    'booms',
    'boots',
    'boras',
    'borax',
    'borda',
    'borde',
    'bordé',
    'bords',
    'bores',
    'borna',
    'borne',
    'borné',
    'borts',
    'bosco',
    'bossa',
    'bosse',
    'bossé',
    'bossu',
    'botes',
    'botta',
    'botte',
    'botté',
    'boucs',
    'bouda',
    'boude',
    'boudé',
    'bouée',
    'boues',
    'bouge',
    'bougé',
    'bouif',
    'boula',
    'boule',
    'boulé',
    'boume',
    'boumé',
    'bourg',
    'bouse',
    'bouta',
    'boute',
    'bouté',
    'bouts',
    'bovin',
    'boxai',
    'boxas',
    'boxât',
    'boxée',
    'boxer',
    'boxes',
    'boxés',
    'boxez',
    'boyau',
    'brada',
    'brade',
    'bradé',
    'braie',
    'brais',
    'brait',
    'brama',
    'brame',
    'bramé',
    'brans',
    'brasa',
    'brase',
    'brasé',
    'brava',
    'brave',
    'bravé',
    'bravo',
    'braya',
    'braye',
    'brayé',
    'break',
    'brefs',
    'bréla',
    'brêla',
    'brélé',
    'brèle',
    'brêle',
    'brêlé',
    'brème',
    'brève',
    'brick',
    'brida',
    'bride',
    'bridé',
    'bries',
    'brifa',
    'brife',
    'brifé',
    'brima',
    'brime',
    'brimé',
    'brins',
    'brios',
    'brisa',
    'brise',
    'brisé',
    'brocs',
    'broda',
    'brode',
    'brodé',
    'broie',
    'brome',
    'brook',
    'broum',
    'brous',
    'brout',
    'broya',
    'broyé',
    'bruie',
    'bruir',
    'bruis',
    'bruit',
    'bruît',
    'brûla',
    'brûle',
    'brûlé',
    'bruma',
    'brume',
    'brumé',
    'brune',
    'bruni',
    'bruns',
    'brute',
    'bruts',
    'bubon',
    'bûcha',
    'bûche',
    'bûché',
    'buées',
    'buggy',
    'bugle',
    'buire',
    'bulbe',
    'bulle',
    'bûmes',
    'bures',
    'burin',
    'buron',
    'buscs',
    'buses',
    'busse',
    'buste',
    'butai',
    'butas',
    'butât',
    'butée',
    'buter',
    'butes',
    'butés',
    'bûtes',
    'butez',
    'butin',
    'butor',
    'butta',
    'butte',
    'butté',
    'buvée',
    'buvez',
    'caban',
    'cabas',
    'câbla',
    'câble',
    'câblé',
    'cabot',
    'cabra',
    'cabre',
    'cabré',
    'cabri',
    'cabus',
    'cacao',
    'cacas',
    'cacha',
    'cache',
    'caché',
    'caddy',
    'cades',
    'cadet',
    'cadis',
    'cadra',
    'cadre',
    'cadré',
    'caduc',
    'cafés',
    'cafre',
    'cafta',
    'cafte',
    'cafté',
    'cages',
    'caget',
    'cagna',
    'cagne',
    'cagné',
    'cagot',
    'cahot',
    'caïds',
    'caïeu',
    'cairn',
    'cajou',
    'cajun',
    'cakes',
    'calai',
    'calao',
    'calas',
    'calât',
    'calée',
    'caler',
    'cales',
    'calés',
    'calez',
    'calfs',
    'câlin',
    'calma',
    'calme',
    'calmé',
    'calmi',
    'calos',
    'calot',
    'calta',
    'calte',
    'calté',
    'calva',
    'camée',
    'cames',
    'campa',
    'campe',
    'campé',
    'camps',
    'camus',
    'canai',
    'canal',
    'canas',
    'canât',
    'candi',
    'caner',
    'canes',
    'canez',
    'canif',
    'canin',
    'canna',
    'canne',
    'canné',
    'canoë',
    'canon',
    'canot',
    'canut',
    'caoua',
    'capéa',
    'capée',
    'capéé',
    'capes',
    'capon',
    'capot',
    'cappa',
    'câpre',
    'capta',
    'capte',
    'capté',
    'caqua',
    'caque',
    'caqué',
    'carat',
    'carda',
    'carde',
    'cardé',
    'carex',
    'cargo',
    'caria',
    'carie',
    'carié',
    'caris',
    'carma',
    'carme',
    'carmé',
    'carne',
    'carné',
    'carpe',
    'carra',
    'carre',
    'carré',
    'carry',
    'carte',
    'casai',
    'casas',
    'casât',
    'casée',
    'caser',
    'cases',
    'casés',
    'casez',
    'cassa',
    'casse',
    'cassé',
    'caste',
    'catch',
    'catin',
    'catis',
    'cauri',
    'causa',
    'cause',
    'causé',
    'cavai',
    'cavas',
    'cavât',
    'cavée',
    'caver',
    'caves',
    'cavés',
    'cavet',
    'cavez',
    'céans',
    'cédai',
    'cédas',
    'cédât',
    'cédée',
    'céder',
    'cédés',
    'cèdes',
    'cedex',
    'cédez',
    'cèdre',
    'ceins',
    'ceint',
    'celai',
    'celas',
    'celât',
    'celée',
    'celer',
    'celés',
    'cèles',
    'celez',
    'cella',
    'celle',
    'celte',
    'celui',
    'cènes',
    'censé',
    'cents',
    'cèpes',
    'cérat',
    'cerce',
    'cerfs',
    'cerna',
    'cerne',
    'cerné',
    'césar',
    'cessa',
    'cesse',
    'cessé',
    'ceste',
    'cette',
    'chahs',
    'chair',
    'chais',
    'châle',
    'champ',
    'chant',
    'chaos',
    'chape',
    'chapé',
    'chars',
    'chats',
    'chaud',
    'chaut',
    'chaux',
    'chefs',
    'cheik',
    'chêne',
    'chenu',
    'chéra',
    'chére',
    'chéré',
    'chère',
    'chéri',
    'chers',
    'chiai',
    'chias',
    'chiât',
    'chics',
    'chiée',
    'chien',
    'chier',
    'chies',
    'chiés',
    'chiez',
    'china',
    'chine',
    'chiné',
    'chiot',
    'chipa',
    'chipe',
    'chipé',
    'chips',
    'chocs',
    'choie',
    'choir',
    'chois',
    'choit',
    'choix',
    'chôma',
    'chôme',
    'chômé',
    'chopa',
    'chope',
    'chopé',
    'chose',
    'chott',
    'choux',
    'choya',
    'choyé',
    'chues',
    'chuta',
    'chute',
    'chuté',
    'chyle',
    'chyme',
    'cible',
    'cidre',
    'ciels',
    'cieux',
    'ciguë',
    'cilié',
    'cilla',
    'cille',
    'cillé',
    'cimes',
    'cinés',
    'cippe',
    'cirai',
    'ciras',
    'cirât',
    'cirée',
    'cirer',
    'cires',
    'cirés',
    'cirez',
    'ciron',
    'cirre',
    'ciste',
    'citai',
    'citas',
    'citât',
    'citée',
    'citer',
    'cites',
    'cités',
    'citez',
    'civet',
    'civil',
    'clade',
    'claie',
    'clair',
    'clama',
    'clame',
    'clamé',
    'clamp',
    'clams',
    'clans',
    'clapa',
    'clape',
    'clapé',
    'clapi',
    'clava',
    'clave',
    'clavé',
    'clebs',
    'clefs',
    'clerc',
    'clics',
    'clins',
    'clips',
    'cliva',
    'clive',
    'clivé',
    'clodo',
    'clone',
    'clope',
    'clora',
    'clore',
    'close',
    'cloua',
    'cloue',
    'cloué',
    'clous',
    'clown',
    'clubs',
    'cluse',
    'coach',
    'coati',
    'cobéa',
    'cobol',
    'cobra',
    'cocas',
    'cocha',
    'côcha',
    'coche',
    'coché',
    'côche',
    'côché',
    'cocon',
    'cocos',
    'cocus',
    'codai',
    'codas',
    'codât',
    'codée',
    'coder',
    'codes',
    'codés',
    'codex',
    'codez',
    'codon',
    'coeur',
    'cogna',
    'cogne',
    'cogné',
    'cohue',
    'coing',
    'coins',
    'coïta',
    'coite',
    'coïte',
    'coïté',
    'coïts',
    'colin',
    'colis',
    'colla',
    'colle',
    'collé',
    'colon',
    'côlon',
    'colts',
    'colza',
    'comas',
    'combe',
    'comma',
    'comme',
    'comte',
    'comté',
    'conçu',
    'condé',
    'cônes',
    'conga',
    'congé',
    'cônie',
    'cônir',
    'cônis',
    'cônit',
    'cônît',
    'connu',
    'conta',
    'conte',
    'conté',
    'copal',
    'copia',
    'copie',
    'copié',
    'copra',
    'copte',
    'coqua',
    'coque',
    'coqué',
    'coran',
    'corda',
    'corde',
    'cordé',
    'corna',
    'corne',
    'corné',
    'cornu',
    'coron',
    'corps',
    'corsa',
    'corse',
    'corsé',
    'cossa',
    'cosse',
    'cossé',
    'cossu',
    'cosys',
    'cotai',
    'cotas',
    'cotât',
    'cotée',
    'coter',
    'cotes',
    'cotés',
    'côtes',
    'côtés',
    'cotez',
    'cotie',
    'cotir',
    'cotis',
    'cotit',
    'cotît',
    'coton',
    'cotte',
    'couac',
    'couda',
    'coude',
    'coudé',
    'couds',
    'couic',
    'coula',
    'coule',
    'coulé',
    'coupa',
    'coupe',
    'coupé',
    'coups',
    'coure',
    'cours',
    'court',
    'couru',
    'couse',
    'cousu',
    'coûta',
    'coûte',
    'coûté',
    'coûts',
    'couva',
    'couve',
    'couvé',
    'coxal',
    'coyau',
    'crabe',
    'crack',
    'crado',
    'craie',
    'crama',
    'crame',
    'cramé',
    'crana',
    'crâna',
    'crane',
    'crané',
    'crâne',
    'crâné',
    'crans',
    'crase',
    'crash',
    'crave',
    'crawl',
    'créai',
    'créas',
    'créât',
    'credo',
    'créée',
    'créer',
    'crées',
    'créés',
    'créez',
    'créma',
    'crémé',
    'crème',
    'créna',
    'créné',
    'crène',
    'crêpa',
    'crêpe',
    'crêpé',
    'crépi',
    'crépu',
    'crête',
    'creux',
    'creva',
    'crevé',
    'crève',
    'criai',
    'crias',
    'criât',
    'crics',
    'criée',
    'crier',
    'cries',
    'criés',
    'criez',
    'crime',
    'crins',
    'crise',
    'crocs',
    'croie',
    'crois',
    'croîs',
    'croit',
    'croît',
    'croix',
    'cross',
    'cruel',
    'crues',
    'cubai',
    'cubas',
    'cubât',
    'cubée',
    'cuber',
    'cubes',
    'cubés',
    'cubez',
    'cuira',
    'cuire',
    'cuirs',
    'cuise',
    'cuita',
    'cuite',
    'cuité',
    'cuits',
    'culai',
    'culas',
    'culât',
    'culée',
    'culer',
    'cules',
    'culés',
    'culex',
    'culez',
    'culot',
    'culte',
    'cumin',
    'cumul',
    'curai',
    'curas',
    'curât',
    'curée',
    'curer',
    'cures',
    'curés',
    'curez',
    'curry',
    'cuvai',
    'cuvas',
    'cuvât',
    'cuvée',
    'cuver',
    'cuves',
    'cuvés',
    'cuvez',
    'cyans',
    'cycas',
    'cycle',
    'cygne',
    'czars',
    'dadas',
    'dagua',
    'dague',
    'dagué',
    'dahir',
    'daims',
    'daine',
    'dalla',
    'dalle',
    'dallé',
    'dalot',
    'damai',
    'daman',
    'damas',
    'damât',
    'damée',
    'damer',
    'dames',
    'damés',
    'damez',
    'damna',
    'damne',
    'damné',
    'dandy',
    'dansa',
    'danse',
    'dansé',
    'darce',
    'darda',
    'darde',
    'dardé',
    'dards',
    'darne',
    'darse',
    'datai',
    'datas',
    'datât',
    'datée',
    'dater',
    'dates',
    'datés',
    'datez',
    'datif',
    'datte',
    'dauba',
    'daube',
    'daubé',
    'débat',
    'débet',
    'débit',
    'début',
    'décan',
    'décas',
    'décès',
    'dèche',
    'déchu',
    'décis',
    'décor',
    'décri',
    'décru',
    'déçue',
    'déçus',
    'déçut',
    'déçût',
    'dédia',
    'dédie',
    'dédié',
    'dédis',
    'dédit',
    'dédît',
    'défia',
    'défie',
    'défié',
    'défis',
    'défit',
    'défît',
    'dégât',
    'dégel',
    'degré',
    'déité',
    'délai',
    'delco',
    'délia',
    'délie',
    'délié',
    'délit',
    'délot',
    'delta',
    'dèmes',
    'démet',
    'demie',
    'demis',
    'démis',
    'démit',
    'démît',
    'démon',
    'dénia',
    'dénie',
    'dénié',
    'dénis',
    'dense',
    'denté',
    'dents',
    'dénua',
    'dénue',
    'dénué',
    'dépit',
    'déplu',
    'dépôt',
    'derby',
    'derme',
    'derny',
    'désir',
    'dette',
    'deuil',
    'dévêt',
    'devez',
    'dévia',
    'dévie',
    'dévié',
    'devin',
    'devis',
    'dévot',
    'devra',
    'diane',
    'diapo',
    'dicos',
    'dicta',
    'dicte',
    'dicté',
    'dièse',
    'diète',
    'dieux',
    'diffa',
    'digit',
    'digne',
    'digon',
    'digue',
    'dilua',
    'dilue',
    'dilué',
    'dîmes',
    'dînai',
    'dinar',
    'dînas',
    'dînât',
    'dinde',
    'dîner',
    'dînes',
    'dînez',
    'dingo',
    'diode',
    'dirai',
    'diras',
    'direz',
    'disco',
    'dises',
    'disse',
    'dites',
    'dîtes',
    'divan',
    'divas',
    'divin',
    'divis',
    'djaïn',
    'djinn',
    'docks',
    'docte',
    'dodos',
    'dodue',
    'dodus',
    'doges',
    'dogme',
    'dogue',
    'doigt',
    'doive',
    'dolai',
    'dolas',
    'dolât',
    'dolce',
    'dolée',
    'doler',
    'doles',
    'dolés',
    'dolez',
    'dolic',
    'dômes',
    'donna',
    'donne',
    'donné',
    'dopai',
    'dopas',
    'dopât',
    'dopée',
    'doper',
    'dopes',
    'dopés',
    'dopez',
    'dorai',
    'doras',
    'dorât',
    'dorée',
    'dorer',
    'dores',
    'dorés',
    'dorez',
    'doris',
    'dorme',
    'dormi',
    'dosai',
    'dosas',
    'dosât',
    'dosée',
    'doser',
    'doses',
    'dosés',
    'dosez',
    'dosse',
    'dotai',
    'dotal',
    'dotas',
    'dotât',
    'dotée',
    'doter',
    'dotes',
    'dotés',
    'dotez',
    'douai',
    'douar',
    'douas',
    'douât',
    'douce',
    'douci',
    'douée',
    'douer',
    'doués',
    'douez',
    'doums',
    'douro',
    'douta',
    'doute',
    'douté',
    'douve',
    'douze',
    'doyen',
    'draie',
    'drain',
    'drame',
    'drapa',
    'drape',
    'drapé',
    'draps',
    'drave',
    'draya',
    'draye',
    'drayé',
    'drège',
    'drill',
    'dring',
    'drink',
    'driva',
    'drive',
    'drivé',
    'droit',
    'drôle',
    'dropa',
    'drope',
    'dropé',
    'drops',
    'drues',
    'drupe',
    'duale',
    'duaux',
    'ducal',
    'ducat',
    'duces',
    'duché',
    'duels',
    'duite',
    'duits',
    'dulie',
    'dûmes',
    'dunes',
    'duodi',
    'dupai',
    'dupas',
    'dupât',
    'dupée',
    'duper',
    'dupes',
    'dupés',
    'dupez',
    'durai',
    'duras',
    'durât',
    'durci',
    'durée',
    'durer',
    'dures',
    'durez',
    'durit',
    'dusse',
    'dûtes',
    'duvet',
    'dyade',
    'dzêta',
    'ébahi',
    'ébats',
    'ébène',
    'éboua',
    'éboue',
    'éboué',
    'écala',
    'écale',
    'écalé',
    'écang',
    'écart',
    'écati',
    'échec',
    'èches',
    'échos',
    'échue',
    'échus',
    'échut',
    'écima',
    'écime',
    'écimé',
    'éclat',
    'éclos',
    'éclot',
    'école',
    'écopa',
    'écope',
    'écopé',
    'écora',
    'écore',
    'écoré',
    'écoté',
    'écots',
    'écran',
    'écrié',
    'écrin',
    'écris',
    'écrit',
    'écrou',
    'écrue',
    'écrus',
    'écula',
    'écule',
    'éculé',
    'écuma',
    'écume',
    'écumé',
    'écura',
    'écure',
    'écuré',
    'édams',
    'édens',
    'édile',
    'édita',
    'édite',
    'édité',
    'édits',
    'effet',
    'égaie',
    'égala',
    'égale',
    'égalé',
    'égara',
    'égard',
    'égare',
    'égaré',
    'égaux',
    'égaya',
    'égaye',
    'égayé',
    'égéen',
    'égide',
    'égout',
    'eider',
    'élans',
    'élavé',
    'elbot',
    'élégi',
    'éléis',
    'éleva',
    'élevé',
    'élève',
    'elfes',
    'élida',
    'élide',
    'élidé',
    'élima',
    'élime',
    'élimé',
    'élira',
    'élire',
    'élise',
    'élite',
    'elles',
    'éloge',
    'éluda',
    'élude',
    'éludé',
    'élues',
    'émail',
    'émana',
    'émane',
    'émané',
    'émaux',
    'embat',
    'embua',
    'embue',
    'embué',
    'embus',
    'émeri',
    'émets',
    'émeus',
    'émeut',
    'émiai',
    'émias',
    'émiât',
    'émiée',
    'émier',
    'émies',
    'émiés',
    'émiez',
    'émirs',
    'émise',
    'émois',
    'émoud',
    'émous',
    'empan',
    'empli',
    'émues',
    'émula',
    'émule',
    'émulé',
    'encan',
    'encas',
    'encra',
    'encre',
    'encré',
    'endos',
    'enfer',
    'enfeu',
    'enfin',
    'enfla',
    'enfle',
    'enflé',
    'enfui',
    'engin',
    'enjeu',
    'enlia',
    'enlie',
    'enlié',
    'ennui',
    'énoua',
    'énoue',
    'énoué',
    'entai',
    'entas',
    'entât',
    'entée',
    'enter',
    'entes',
    'entés',
    'entez',
    'entra',
    'entre',
    'entré',
    'envia',
    'envie',
    'envié',
    'envoi',
    'envol',
    'épair',
    'épais',
    'épala',
    'épale',
    'épalé',
    'épand',
    'épars',
    'épata',
    'épate',
    'épaté',
    'épave',
    'épées',
    'épela',
    'épelé',
    'éphod',
    'épiai',
    'épias',
    'épiât',
    'épiça',
    'épice',
    'épicé',
    'épiée',
    'épier',
    'épies',
    'épiés',
    'épieu',
    'épiez',
    'épigé',
    'épila',
    'épile',
    'épilé',
    'épina',
    'épine',
    'épiné',
    'épite',
    'épode',
    'époux',
    'épris',
    'épuça',
    'épuce',
    'épucé',
    'épura',
    'épure',
    'épuré',
    'équin',
    'éraie',
    'éraya',
    'éraye',
    'érayé',
    'ergot',
    'érige',
    'érigé',
    'érine',
    'éroda',
    'érode',
    'érodé',
    'errai',
    'erras',
    'errât',
    'errer',
    'erres',
    'errez',
    'escha',
    'esche',
    'esché',
    'escot',
    'espar',
    'essai',
    'esses',
    'essor',
    'ester',
    'estoc',
    'es-tu',
    'étage',
    'étagé',
    'étaie',
    'étain',
    'étais',
    'était',
    'étala',
    'étale',
    'étalé',
    'étals',
    'étama',
    'étame',
    'étamé',
    'étang',
    'étant',
    'étape',
    'états',
    'étaux',
    'étaya',
    'étaye',
    'étayé',
    'étend',
    'étêta',
    'étête',
    'étêté',
    'éteuf',
    'éther',
    'étier',
    'étiez',
    'étira',
    'étire',
    'étiré',
    'étocs',
    'étole',
    'êtres',
    'étron',
    'étude',
    'étuis',
    'étuva',
    'étuve',
    'étuvé',
    'eûmes',
    'euros',
    'eusse',
    'eûtes',
    'évadé',
    'évasa',
    'évase',
    'évasé',
    'éveil',
    'évent',
    'évida',
    'évide',
    'évidé',
    'évier',
    'évita',
    'évite',
    'évité',
    'évohé',
    'exact',
    'excès',
    'exclu',
    'exige',
    'exigé',
    'exigu',
    'exila',
    'exile',
    'exilé',
    'exils',
    'exode',
    'expia',
    'expie',
    'expié',
    'extra',
    'fable',
    'faces',
    'fâcha',
    'fâche',
    'fâché',
    'facho',
    'façon',
    'facto',
    'fadai',
    'fadas',
    'fadât',
    'fadée',
    'fader',
    'fades',
    'fadés',
    'fadez',
    'fados',
    'fagne',
    'fagot',
    'faims',
    'faîne',
    'faire',
    'faite',
    'faîte',
    'faits',
    'fakir',
    'fallu',
    'falot',
    'falun',
    'famée',
    'famés',
    'fanai',
    'fanal',
    'fanas',
    'fanât',
    'fanée',
    'faner',
    'fanes',
    'fanés',
    'fanez',
    'fange',
    'fanon',
    'faons',
    'farad',
    'farce',
    'farci',
    'farda',
    'farde',
    'fardé',
    'fards',
    'faros',
    'farta',
    'farte',
    'farté',
    'farts',
    'fascé',
    'fasse',
    'faste',
    'fatal',
    'fatma',
    'fatum',
    'faune',
    'fauta',
    'faute',
    'fauté',
    'fauve',
    'favus',
    'faxai',
    'faxas',
    'faxât',
    'faxée',
    'faxer',
    'faxes',
    'faxés',
    'faxez',
    'fayot',
    'féale',
    'féaux',
    'fécal',
    'fèces',
    'feins',
    'feint',
    'fêlai',
    'fêlas',
    'fêlât',
    'fêlée',
    'fêler',
    'fêles',
    'fêlés',
    'fêlez',
    'félin',
    'félon',
    'femme',
    'fémur',
    'fende',
    'fends',
    'fendu',
    'fenil',
    'fente',
    'ferai',
    'feras',
    'féras',
    'ferez',
    'férié',
    'ferla',
    'ferle',
    'ferlé',
    'ferma',
    'ferme',
    'fermé',
    'ferra',
    'ferre',
    'ferré',
    'ferry',
    'férue',
    'férus',
    'fessa',
    'fesse',
    'fessé',
    'fessu',
    'fêtai',
    'fêtas',
    'fêtât',
    'fêtée',
    'fêter',
    'fêtes',
    'fêtés',
    'fêtez',
    'fétus',
    'feues',
    'feuil',
    'feula',
    'feule',
    'feulé',
    'fèves',
    'fiais',
    'fiait',
    'fiant',
    'fibre',
    'ficha',
    'fiche',
    'fiché',
    'fichu',
    'ficus',
    'fiées',
    'fiefs',
    'fiels',
    'fient',
    'fiera',
    'fière',
    'fiers',
    'fieux',
    'fifre',
    'figea',
    'figée',
    'figer',
    'figes',
    'figés',
    'figez',
    'figue',
    'fiiez',
    'filai',
    'filas',
    'filât',
    'filée',
    'filer',
    'files',
    'filés',
    'filet',
    'filez',
    'filin',
    'fille',
    'filma',
    'filme',
    'filmé',
    'films',
    'filon',
    'filou',
    'fîmes',
    'final',
    'fines',
    'finie',
    'finir',
    'finis',
    'finit',
    'finît',
    'fiole',
    'fions',
    'firme',
    'fiscs',
    'fisse',
    'fîtes',
    'fixai',
    'fixas',
    'fixât',
    'fixée',
    'fixer',
    'fixes',
    'fixés',
    'fixez',
    'fjeld',
    'fjord',
    'flair',
    'flâna',
    'flanc',
    'flâne',
    'flâné',
    'flans',
    'flapi',
    'flash',
    'fléau',
    'flein',
    'fleur',
    'flics',
    'flint',
    'flirt',
    'flood',
    'flops',
    'flore',
    'flots',
    'floua',
    'floue',
    'floué',
    'flous',
    'fluai',
    'fluas',
    'fluât',
    'fluer',
    'flues',
    'fluet',
    'fluez',
    'fluor',
    'flush',
    'flûta',
    'flûte',
    'flûté',
    'fluxa',
    'fluxe',
    'fluxé',
    'focal',
    'foehn',
    'foies',
    'foins',
    'foira',
    'foire',
    'foiré',
    'folie',
    'folié',
    'folio',
    'folks',
    'folle',
    'fonça',
    'fonce',
    'foncé',
    'fonda',
    'fonde',
    'fondé',
    'fonds',
    'fondu',
    'fonte',
    'foots',
    'forai',
    'foras',
    'forât',
    'força',
    'force',
    'forcé',
    'forci',
    'forée',
    'forer',
    'fores',
    'forés',
    'foret',
    'forêt',
    'forez',
    'forge',
    'forgé',
    'forma',
    'forme',
    'formé',
    'forte',
    'forts',
    'forum',
    'fosse',
    'fossé',
    'fouée',
    'fouet',
    'fouge',
    'fougé',
    'fouie',
    'fouir',
    'fouis',
    'fouit',
    'fouît',
    'foula',
    'foule',
    'foulé',
    'fours',
    'foute',
    'foutu',
    'fovéa',
    'foxée',
    'foxés',
    'foyer',
    'fracs',
    'fraie',
    'frais',
    'franc',
    'frape',
    'fraya',
    'fraye',
    'frayé',
    'frein',
    'frêle',
    'frémi',
    'frêne',
    'fréon',
    'frère',
    'fréta',
    'frété',
    'frète',
    'frets',
    'freux',
    'frics',
    'frigo',
    'frima',
    'frime',
    'frimé',
    'fripa',
    'fripe',
    'fripé',
    'frira',
    'frire',
    'frisa',
    'frise',
    'frisé',
    'frite',
    'fritz',
    'frocs',
    'froid',
    'frôla',
    'frôle',
    'frôlé',
    'front',
    'froua',
    'froue',
    'froué',
    'fruit',
    'fucus',
    'fuels',
    'fugua',
    'fugue',
    'fugué',
    'fuies',
    'fuira',
    'fuite',
    'fulls',
    'fumai',
    'fumas',
    'fumât',
    'fumée',
    'fumer',
    'fumes',
    'fumés',
    'fûmes',
    'fumet',
    'fumez',
    'funin',
    'funky',
    'furax',
    'furet',
    'furia',
    'furie',
    'fusai',
    'fusas',
    'fusât',
    'fusée',
    'fusel',
    'fuser',
    'fuses',
    'fusés',
    'fusez',
    'fusil',
    'fusse',
    'futée',
    'futés',
    'fûtes',
    'futur',
    'fuyez',
    'gâble',
    'gâcha',
    'gâche',
    'gâché',
    'gades',
    'gadin',
    'gaffa',
    'gaffe',
    'gaffé',
    'gagas',
    'gagea',
    'gagée',
    'gager',
    'gages',
    'gagés',
    'gagez',
    'gagna',
    'gagne',
    'gagné',
    'gaïac',
    'gaies',
    'gaina',
    'gaine',
    'gainé',
    'gains',
    'galas',
    'galba',
    'galbe',
    'galbé',
    'gales',
    'galet',
    'gallo',
    'galon',
    'galop',
    'gamba',
    'gambe',
    'gamin',
    'gamme',
    'ganga',
    'gangs',
    'gansa',
    'ganse',
    'gansé',
    'ganta',
    'gante',
    'ganté',
    'gants',
    'garai',
    'garas',
    'garât',
    'garce',
    'garda',
    'garde',
    'gardé',
    'garée',
    'garer',
    'gares',
    'garés',
    'garez',
    'garni',
    'garou',
    'gâtai',
    'gâtas',
    'gâtât',
    'gâtée',
    'gâter',
    'gâtes',
    'gâtés',
    'gâtez',
    'gatte',
    'gaude',
    'gaula',
    'gaule',
    'gaulé',
    'gaupe',
    'gaurs',
    'gauss',
    'gavai',
    'gavas',
    'gavât',
    'gavée',
    'gaver',
    'gaves',
    'gavés',
    'gavez',
    'gayal',
    'gazai',
    'gazas',
    'gazât',
    'gazée',
    'gazer',
    'gazes',
    'gazés',
    'gazez',
    'gazon',
    'geais',
    'géant',
    'gecko',
    'geins',
    'geint',
    'gelai',
    'gelas',
    'gelât',
    'gelée',
    'geler',
    'gelés',
    'gèles',
    'gelez',
    'gélif',
    'gémie',
    'gémir',
    'gémis',
    'gémit',
    'gémît',
    'gemma',
    'gemme',
    'gemmé',
    'gênai',
    'gênas',
    'gênât',
    'gênée',
    'gêner',
    'gènes',
    'gênes',
    'gênés',
    'genet',
    'genêt',
    'gênez',
    'génie',
    'genou',
    'genre',
    'géode',
    'geôle',
    'gérai',
    'géras',
    'gérât',
    'gerba',
    'gerbe',
    'gerbé',
    'gerça',
    'gerce',
    'gercé',
    'gérée',
    'gérer',
    'gérés',
    'gères',
    'gérez',
    'germa',
    'germe',
    'germé',
    'gésir',
    'gesse',
    'geste',
    'gibet',
    'gibus',
    'gicla',
    'gicle',
    'giclé',
    'gifla',
    'gifle',
    'giflé',
    'gigot',
    'gigue',
    'gilde',
    'gilet',
    'gille',
    'girie',
    'girls',
    'giron',
    'gisez',
    'gîtai',
    'gitan',
    'gîtas',
    'gîtât',
    'gîter',
    'gîtes',
    'gîtez',
    'giton',
    'givra',
    'givre',
    'givré',
    'glaça',
    'glace',
    'glacé',
    'glana',
    'gland',
    'glane',
    'glané',
    'glapi',
    'glass',
    'glati',
    'glèbe',
    'glène',
    'globe',
    'glome',
    'glosa',
    'glose',
    'glosé',
    'gluau',
    'gluis',
    'glume',
    'gnôle',
    'gnome',
    'gnons',
    'gnose',
    'gnous',
    'goals',
    'gobai',
    'gobas',
    'gobât',
    'gobée',
    'gober',
    'gobes',
    'gobés',
    'gobez',
    'gobie',
    'godai',
    'godas',
    'godât',
    'goder',
    'godes',
    'godet',
    'godez',
    'goglu',
    'gogos',
    'golfe',
    'golfs',
    'gombo',
    'gomma',
    'gomme',
    'gommé',
    'gonda',
    'gonde',
    'gondé',
    'gonds',
    'gongs',
    'gonze',
    'gords',
    'goret',
    'gorge',
    'gorgé',
    'gosse',
    'goton',
    'gouda',
    'gouet',
    'gouge',
    'goule',
    'goulu',
    'goums',
    'goura',
    'gourd',
    'goure',
    'gouré',
    'goûta',
    'goûte',
    'goûté',
    'goûts',
    'goyim',
    'grâce',
    'grade',
    'gradé',
    'grain',
    'grand',
    'graux',
    'grava',
    'grave',
    'gravé',
    'gravi',
    'gréai',
    'gréas',
    'gréât',
    'grèbe',
    'grecs',
    'gréée',
    'green',
    'gréer',
    'grées',
    'gréés',
    'gréez',
    'grège',
    'grêla',
    'grêle',
    'grêlé',
    'grena',
    'grené',
    'grène',
    'grenu',
    'grésa',
    'grésé',
    'grèse',
    'greva',
    'grevé',
    'grève',
    'grief',
    'grill',
    'grils',
    'grima',
    'grime',
    'grimé',
    'griot',
    'grisa',
    'grise',
    'grisé',
    'grive',
    'grogs',
    'groin',
    'grole',
    'groom',
    'group',
    'gruau',
    'grues',
    'gruge',
    'grugé',
    'grume',
    'guais',
    'guano',
    'guéai',
    'guéas',
    'guéât',
    'guède',
    'guéée',
    'guéer',
    'guées',
    'guéés',
    'guéez',
    'guêpe',
    'guère',
    'guéri',
    'guète',
    'guets',
    'gueux',
    'guida',
    'guide',
    'guidé',
    'guipa',
    'guipe',
    'guipé',
    'guise',
    'guppy',
    'gurus',
    'guzla',
    'gypse',
    'gyrin',
    'habit',
    'habla',
    'hable',
    'hablé',
    'hacha',
    'hache',
    'haché',
    'hadji',
    'haies',
    'haïes',
    'haïks',
    'haïku',
    'haine',
    'haïra',
    'haire',
    'halai',
    'hâlai',
    'halas',
    'hâlas',
    'halât',
    'hâlât',
    'halbi',
    'halée',
    'hâlée',
    'haler',
    'hâler',
    'hales',
    'halés',
    'hâles',
    'hâlés',
    'halez',
    'hâlez',
    'halle',
    'halls',
    'halos',
    'halte',
    'halva',
    'hamac',
    'hampe',
    'hanap',
    'hanse',
    'hanta',
    'hante',
    'hanté',
    'hapax',
    'happa',
    'happe',
    'happé',
    'haras',
    'harda',
    'harde',
    'hardé',
    'hardi',
    'harem',
    'harki',
    'harle',
    'haros',
    'harpa',
    'harpe',
    'harpé',
    'harts',
    'hasch',
    'hases',
    'hasté',
    'hasts',
    'hâtai',
    'hâtas',
    'hâtât',
    'hâtée',
    'hâter',
    'hâtes',
    'hâtés',
    'hâtez',
    'hâtif',
    'haute',
    'hauts',
    'havai',
    'havas',
    'havât',
    'havée',
    'haver',
    'haves',
    'havés',
    'hâves',
    'havez',
    'havie',
    'havir',
    'havis',
    'havit',
    'havît',
    'havre',
    'hayon',
    'hecto',
    'hélai',
    'hélas',
    'hélât',
    'hélée',
    'héler',
    'hélés',
    'hèles',
    'hélez',
    'hélix',
    'hello',
    'henné',
    'henni',
    'henry',
    'herba',
    'herbe',
    'herbé',
    'herbu',
    'hères',
    'héron',
    'héros',
    'herpe',
    'hersa',
    'herse',
    'hersé',
    'hertz',
    'hêtre',
    'heure',
    'heurs',
    'heurt',
    'hévéa',
    'hibou',
    'hi-fi',
    'hindi',
    'hippy',
    'hissa',
    'hisse',
    'hissé',
    'hiver',
    'hobby',
    'hocco',
    'hocha',
    'hoche',
    'hoché',
    'hoirs',
    'homes',
    'homme',
    'homos',
    'honni',
    'honte',
    'horde',
    'horst',
    'hosto',
    'hôtel',
    'hôtes',
    'hotte',
    'houai',
    'houas',
    'houât',
    'houée',
    'houer',
    'houes',
    'houés',
    'houez',
    'houka',
    'houle',
    'hourd',
    'houri',
    'hoyau',
    'huais',
    'huait',
    'huant',
    'huard',
    'huart',
    'hucha',
    'huche',
    'huché',
    'huées',
    'huent',
    'huera',
    'huiez',
    'huila',
    'huile',
    'huilé',
    'humai',
    'humas',
    'humât',
    'humée',
    'humer',
    'humes',
    'humés',
    'humez',
    'humus',
    'hunes',
    'huons',
    'huppe',
    'huppé',
    'hures',
    'hurla',
    'hurle',
    'hurlé',
    'huron',
    'hutte',
    'hydne',
    'hydre',
    'hyène',
    'hymen',
    'hymne',
    'iambe',
    'ibère',
    'ichor',
    'icône',
    'ictus',
    'idéal',
    'idéel',
    'idées',
    'idiot',
    'idole',
    'igloo',
    'ignée',
    'ignés',
    'igues',
    'iléal',
    'iléon',
    'iléus',
    'îlien',
    'ilion',
    'ilote',
    'îlots',
    'image',
    'imagé',
    'imago',
    'imams',
    'imbue',
    'imbus',
    'imide',
    'imita',
    'imite',
    'imité',
    'immun',
    'imper',
    'impie',
    'impôt',
    'impur',
    'incas',
    'index',
    'indue',
    'indus',
    'infra',
    'infus',
    'inlay',
    'innée',
    'innés',
    'inouï',
    'input',
    'inter',
    'intis',
    'inuit',
    'inule',
    'iodai',
    'iodas',
    'iodât',
    'iodée',
    'ioder',
    'iodes',
    'iodés',
    'iodez',
    'iodla',
    'iodle',
    'iodlé',
    'ioula',
    'ioule',
    'ioulé',
    'ipéca',
    'irais',
    'irait',
    'iriez',
    'irisa',
    'irise',
    'irisé',
    'irone',
    'irons',
    'iront',
    'isard',
    'isbas',
    'islam',
    'isola',
    'isole',
    'isolé',
    'issue',
    'issus',
    'items',
    'itéra',
    'itéré',
    'itère',
    'iules',
    'ivres',
    'ixias',
    'ixode',
    'jabla',
    'jable',
    'jablé',
    'jabot',
    'jacée',
    'jacks',
    'jacot',
    'jacta',
    'jacte',
    'jades',
    'jadis',
    'jaïna',
    'jaïns',
    'jalap',
    'jales',
    'jalon',
    'jambe',
    'jambé',
    'jante',
    'japon',
    'jappa',
    'jappe',
    'jappé',
    'jarde',
    'jarre',
    'jasai',
    'jasas',
    'jasât',
    'jaser',
    'jases',
    'jasez',
    'jaspa',
    'jaspe',
    'jaspé',
    'jatte',
    'jauge',
    'jaugé',
    'jaune',
    'jauni',
    'javas',
    'javel',
    'jeans',
    'jeeps',
    'jenny',
    'jerez',
    'jerka',
    'jerke',
    'jerké',
    'jerks',
    'jésus',
    'jetai',
    'jetas',
    'jetât',
    'jetée',
    'jeter',
    'jetés',
    'jetez',
    'jeton',
    'jette',
    'jeudi',
    'jeûna',
    'jeune',
    'jeûne',
    'jeûné',
    'jodla',
    'jodle',
    'jodlé',
    'joies',
    'joins',
    'joint',
    'joker',
    'jolie',
    'jolis',
    'jonça',
    'jonce',
    'joncé',
    'joncs',
    'jotas',
    'jouai',
    'joual',
    'jouas',
    'jouât',
    'jouée',
    'jouer',
    'joues',
    'joués',
    'jouet',
    'jouez',
    'jougs',
    'jouir',
    'jouis',
    'jouit',
    'jouît',
    'joule',
    'jours',
    'jouta',
    'joute',
    'jouté',
    'joyau',
    'jubés',
    'jucha',
    'juche',
    'juché',
    'judas',
    'judos',
    'jugal',
    'jugea',
    'jugée',
    'juger',
    'juges',
    'jugés',
    'jugez',
    'juifs',
    'juill',
    'juive',
    'julep',
    'jules',
    'jumbo',
    'jumel',
    'junte',
    'jupes',
    'jupon',
    'jurai',
    'juras',
    'jurat',
    'jurât',
    'jurée',
    'jurer',
    'jures',
    'jurés',
    'jurez',
    'juron',
    'jurys',
    'jusée',
    'jusqu',
    'juste',
    'jutai',
    'jutas',
    'jutât',
    'jutée',
    'juter',
    'jutes',
    'jutés',
    'jutez',
    'kacha',
    'kache',
    'kakis',
    'kalis',
    'kamis',
    'kapok',
    'kappa',
    'karma',
    'karts',
    'kavas',
    'kawas',
    'kayac',
    'kayak',
    'kéfir',
    'kendo',
    'képis',
    'ketch',
    'khans',
    'khats',
    'khmer',
    'khôls',
    'kicks',
    'kiefs',
    'kikis',
    'kilos',
    'kilts',
    'kiwis',
    'knout',
    'koala',
    'koinè',
    'kolas',
    'korês',
    'kraal',
    'krach',
    'kraft',
    'kraks',
    'kriss',
    'ksour',
    'kurde',
    'kyrie',
    'kyste',
    'label',
    'labié',
    'labre',
    'laçai',
    'laças',
    'laçât',
    'lacée',
    'lacer',
    'laces',
    'lacés',
    'lacet',
    'lacez',
    'lâcha',
    'lâche',
    'lâché',
    'lacis',
    'lacté',
    'ladin',
    'ladre',
    'lagon',
    'laïcs',
    'laide',
    'laids',
    'laies',
    'laina',
    'laine',
    'lainé',
    'laird',
    'laité',
    'laits',
    'laïus',
    'laize',
    'lamai',
    'lamas',
    'lamât',
    'lamée',
    'lamer',
    'lames',
    'lamés',
    'lamez',
    'lamie',
    'lampa',
    'lampe',
    'lampé',
    'lança',
    'lance',
    'lancé',
    'lande',
    'lange',
    'langé',
    'lapai',
    'lapas',
    'lapât',
    'lapée',
    'laper',
    'lapes',
    'lapés',
    'lapez',
    'lapin',
    'lapis',
    'laqua',
    'laque',
    'laqué',
    'larda',
    'larde',
    'lardé',
    'lards',
    'large',
    'largo',
    'larme',
    'larve',
    'larvé',
    'laser',
    'lassa',
    'lasse',
    'lassé',
    'lasso',
    'latex',
    'latin',
    'latta',
    'latte',
    'latté',
    'lauré',
    'lavai',
    'lavas',
    'lavât',
    'lavée',
    'laver',
    'laves',
    'lavés',
    'lavez',
    'lavis',
    'layai',
    'layas',
    'layât',
    'layée',
    'layer',
    'layes',
    'layés',
    'layez',
    'layon',
    'lazzi',
    'lebel',
    'lécha',
    'léché',
    'lèche',
    'leçon',
    'ledit',
    'légal',
    'légat',
    'léger',
    'lèges',
    'légua',
    'légué',
    'lègue',
    'lemme',
    'lente',
    'lento',
    'lents',
    'lèpre',
    'lérot',
    'lésai',
    'lésas',
    'lésât',
    'lésée',
    'léser',
    'lésés',
    'lèses',
    'lésez',
    'lesta',
    'leste',
    'lesté',
    'lests',
    'létal',
    'leude',
    'leurs',
    'levai',
    'levas',
    'levât',
    'levée',
    'lever',
    'levés',
    'lèves',
    'levez',
    'lèvre',
    'lexie',
    'lexis',
    'liage',
    'liais',
    'liait',
    'liane',
    'liant',
    'liard',
    'liber',
    'libre',
    'lices',
    'licha',
    'liche',
    'liché',
    'licol',
    'licou',
    'lidos',
    'lieds',
    'liées',
    'liégé',
    'liège',
    'liens',
    'lient',
    'liera',
    'lieue',
    'lieur',
    'lieus',
    'lieux',
    'lifta',
    'lifte',
    'lifté',
    'lifts',
    'liges',
    'ligie',
    'ligna',
    'ligne',
    'ligné',
    'ligot',
    'ligua',
    'ligue',
    'ligué',
    'liiez',
    'lilas',
    'limai',
    'liman',
    'limas',
    'limât',
    'limbe',
    'limée',
    'limer',
    'limes',
    'limés',
    'limez',
    'limon',
    'liner',
    'linga',
    'linge',
    'lingé',
    'links',
    'linon',
    'linos',
    'lions',
    'lippe',
    'lippu',
    'lirai',
    'liras',
    'lirez',
    'liron',
    'lises',
    'lisez',
    'lissa',
    'lisse',
    'lissé',
    'lista',
    'liste',
    'listé',
    'litai',
    'litas',
    'litât',
    'litée',
    'liter',
    'lites',
    'lités',
    'litez',
    'litho',
    'litre',
    'liure',
    'lives',
    'livet',
    'livra',
    'livre',
    'livré',
    'lobai',
    'lobas',
    'lobât',
    'lobby',
    'lobée',
    'lober',
    'lobes',
    'lobés',
    'lobez',
    'local',
    'locha',
    'loche',
    'loché',
    'lochs',
    'loden',
    'loess',
    'lofai',
    'lofas',
    'lofât',
    'lofer',
    'lofes',
    'lofez',
    'lofts',
    'logea',
    'logée',
    'loger',
    'loges',
    'logés',
    'logez',
    'logis',
    'logos',
    'loirs',
    'lolos',
    'longe',
    'longé',
    'longs',
    'looch',
    'loofa',
    'looks',
    'lopin',
    'loqua',
    'loque',
    'loqué',
    'loran',
    'lords',
    'loris',
    'lotes',
    'lotie',
    'lotir',
    'lotis',
    'lotit',
    'lotît',
    'lotos',
    'lotte',
    'lotus',
    'louai',
    'louas',
    'louât',
    'louée',
    'louer',
    'loues',
    'loués',
    'louez',
    'loufa',
    'loufe',
    'loufé',
    'louis',
    'loupa',
    'loupe',
    'loupé',
    'loups',
    'loura',
    'lourd',
    'loure',
    'louré',
    'louva',
    'louve',
    'louvé',
    'lovai',
    'lovas',
    'lovât',
    'lovée',
    'lover',
    'loves',
    'lovés',
    'lovez',
    'loyal',
    'loyer',
    'lubie',
    'lucre',
    'lueur',
    'luffa',
    'lugea',
    'luger',
    'luges',
    'lugez',
    'luira',
    'luire',
    'luise',
    'luite',
    'luits',
    'lulus',
    'lumen',
    'lûmes',
    'lumps',
    'lunch',
    'lundi',
    'lunée',
    'lunes',
    'lunés',
    'lupin',
    'lupus',
    'luron',
    'lusin',
    'lusse',
    'lutai',
    'lutas',
    'lutât',
    'lutée',
    'luter',
    'lutes',
    'lutés',
    'lûtes',
    'lutez',
    'luths',
    'lutin',
    'lutta',
    'lutte',
    'lutté',
    'luxai',
    'luxas',
    'luxât',
    'luxée',
    'luxer',
    'luxes',
    'luxés',
    'luxez',
    'lycée',
    'lycra',
    'lyres',
    'lyric',
    'lysai',
    'lysas',
    'lysat',
    'lysât',
    'lysée',
    'lyser',
    'lyses',
    'lysés',
    'lysez',
    'mâcha',
    'mâche',
    'mâché',
    'macho',
    'macis',
    'macla',
    'macle',
    'maclé',
    'maçon',
    'mâcon',
    'macre',
    'madré',
    'mafia',
    'mages',
    'magie',
    'magma',
    'magna',
    'magne',
    'magné',
    'magot',
    'maïas',
    'maies',
    'mails',
    'mains',
    'maint',
    'maire',
    'major',
    'makis',
    'mâles',
    'malin',
    'malis',
    'malle',
    'malta',
    'malte',
    'malté',
    'malts',
    'malus',
    'maman',
    'mambo',
    'mamie',
    'mammy',
    'manas',
    'manda',
    'mande',
    'mandé',
    'mânes',
    'mange',
    'mangé',
    'mania',
    'manie',
    'manié',
    'manne',
    'manse',
    'mante',
    'maori',
    'maoux',
    'maqua',
    'maque',
    'maqué',
    'marcs',
    'mardi',
    'marée',
    'mares',
    'marge',
    'margé',
    'maria',
    'marie',
    'marié',
    'marin',
    'maris',
    'marks',
    'marli',
    'marna',
    'marne',
    'marné',
    'marra',
    'marre',
    'marré',
    'marri',
    'marte',
    'maser',
    'massa',
    'masse',
    'massé',
    'mataf',
    'matai',
    'mâtai',
    'matas',
    'mâtas',
    'matât',
    'mâtât',
    'match',
    'matée',
    'mâtée',
    'mater',
    'mâter',
    'mates',
    'matés',
    'mâtes',
    'mâtés',
    'matez',
    'mâtez',
    'maths',
    'matie',
    'matin',
    'matir',
    'matis',
    'matit',
    'matît',
    'maton',
    'matou',
    'matte',
    'maure',
    'mauve',
    'mayas',
    'mayen',
    'mayes',
    'méats',
    'mécha',
    'méché',
    'mèche',
    'mèdes',
    'média',
    'médis',
    'médit',
    'médît',
    'médoc',
    'méfia',
    'méfie',
    'méfié',
    'méfis',
    'méfit',
    'méfît',
    'mégie',
    'mégir',
    'mégis',
    'mégit',
    'mégît',
    'mégot',
    'meïji',
    'mêlai',
    'mêlas',
    'mêlât',
    'Melba',
    'mêlée',
    'mêler',
    'mêles',
    'mêlés',
    'mêlez',
    'mélia',
    'melon',
    'mélos',
    'mémés',
    'mêmes',
    'menai',
    'menas',
    'menât',
    'menée',
    'mener',
    'menés',
    'mènes',
    'menez',
    'menin',
    'mense',
    'mente',
    'menti',
    'menue',
    'menus',
    'merci',
    'merde',
    'mères',
    'merle',
    'merlu',
    'mérou',
    'mesas',
    'messe',
    'métal',
    'métas',
    'météo',
    'métis',
    'métra',
    'métré',
    'mètre',
    'métro',
    'mette',
    'meula',
    'meule',
    'meulé',
    'meure',
    'meurs',
    'meurt',
    'meute',
    'meuve',
    'mezzo',
    'miaou',
    'micas',
    'miche',
    'micro',
    'midis',
    'miels',
    'miens',
    'mieux',
    'migra',
    'migre',
    'migré',
    'milan',
    'miles',
    'mille',
    'mimai',
    'mimas',
    'mimât',
    'mimée',
    'mimer',
    'mimes',
    'mimés',
    'mîmes',
    'mimez',
    'mimis',
    'minai',
    'minas',
    'minât',
    'mince',
    'minci',
    'minée',
    'miner',
    'mines',
    'minés',
    'minet',
    'minez',
    'minis',
    'minot',
    'minou',
    'minus',
    'mirai',
    'miras',
    'mirât',
    'mirée',
    'mirer',
    'mires',
    'mirés',
    'mirez',
    'miros',
    'misai',
    'misas',
    'misât',
    'misée',
    'miser',
    'mises',
    'misés',
    'misez',
    'misse',
    'mitai',
    'mitan',
    'mitas',
    'mitât',
    'mitée',
    'miter',
    'mites',
    'mités',
    'mîtes',
    'mitez',
    'miton',
    'mitre',
    'mitré',
    'mixai',
    'mixas',
    'mixât',
    'mixée',
    'mixer',
    'mixes',
    'mixés',
    'mixez',
    'mixte',
    'moche',
    'mocos',
    'modal',
    'modem',
    'modes',
    'modus',
    'moere',
    'moine',
    'moins',
    'moira',
    'moire',
    'moiré',
    'moisa',
    'moise',
    'moisé',
    'moïse',
    'moisi',
    'moita',
    'moite',
    'moité',
    'moiti',
    'mokas',
    'môles',
    'molle',
    'molli',
    'mollo',
    'molys',
    'mômes',
    'momie',
    'monda',
    'monde',
    'mondé',
    'monel',
    'monos',
    'monta',
    'monte',
    'monté',
    'monts',
    'moqua',
    'moque',
    'moqué',
    'moral',
    'morde',
    'mords',
    'mordu',
    'mores',
    'morio',
    'morne',
    'morné',
    'morse',
    'morte',
    'morts',
    'morue',
    'morve',
    'mosan',
    'motel',
    'motet',
    'motif',
    'motos',
    'motta',
    'motte',
    'motté',
    'motus',
    'mouds',
    'moues',
    'moula',
    'moule',
    'moulé',
    'moult',
    'moulu',
    'moûts',
    'mouva',
    'mouve',
    'mouvé',
    'moxas',
    'moyée',
    'moyen',
    'moyés',
    'moyeu',
    'muais',
    'muait',
    'muant',
    'mucha',
    'muche',
    'muché',
    'mucor',
    'mucus',
    'muées',
    'muent',
    'muera',
    'muets',
    'mufle',
    'mufti',
    'muges',
    'mugir',
    'mugis',
    'mugit',
    'mugît',
    'muids',
    'muiez',
    'mules',
    'mulet',
    'mulon',
    'mulot',
    'mûmes',
    'munie',
    'munir',
    'munis',
    'munit',
    'munît',
    'muons',
    'murai',
    'mural',
    'muras',
    'murât',
    'murée',
    'murer',
    'mures',
    'murés',
    'mûres',
    'muret',
    'murex',
    'murez',
    'mûrie',
    'mûrir',
    'mûris',
    'mûrit',
    'mûrît',
    'musai',
    'musas',
    'musât',
    'muscs',
    'musée',
    'muser',
    'muses',
    'musez',
    'mussa',
    'musse',
    'mussé',
    'musts',
    'mutai',
    'mutas',
    'mutât',
    'mutée',
    'muter',
    'mutes',
    'mutés',
    'mûtes',
    'mutez',
    'mutin',
    'myome',
    'myope',
    'myrte',
    'mythe',
    'nabab',
    'nabot',
    'nacra',
    'nacre',
    'nacré',
    'nadir',
    'nafés',
    'nagea',
    'nagée',
    'nager',
    'nages',
    'nagés',
    'nagez',
    'naïfs',
    'naine',
    'nains',
    'naïve',
    'najas',
    'nanan',
    'nanar',
    'nanas',
    'nanti',
    'napée',
    'napel',
    'nappa',
    'nappe',
    'nappé',
    'nards',
    'narra',
    'narre',
    'narré',
    'nasal',
    'nases',
    'nasse',
    'natal',
    'natif',
    'natta',
    'natte',
    'natté',
    'naval',
    'navet',
    'navra',
    'navre',
    'navré',
    'nazie',
    'nazis',
    'néant',
    'nebka',
    'necks',
    'nèfle',
    'nègre',
    'négus',
    'neige',
    'neigé',
    'nénés',
    'nenni',
    'néons',
    'nerfs',
    'nervi',
    'nette',
    'neufs',
    'neume',
    'neuve',
    'névés',
    'neveu',
    'niais',
    'niait',
    'niant',
    'nicha',
    'niche',
    'niché',
    'nicol',
    'nièce',
    'niées',
    'nième',
    'nient',
    'niera',
    'nifes',
    'niiez',
    'nille',
    'nimba',
    'nimbe',
    'nimbé',
    'ninas',
    'niôle',
    'nions',
    'nippa',
    'nippe',
    'nippé',
    'nique',
    'nitra',
    'nitre',
    'nitré',
    'nival',
    'nixes',
    'noble',
    'noces',
    'nocif',
    'Noëls',
    'noeud',
    'noies',
    'noire',
    'noirs',
    'noise',
    'nolis',
    'nomes',
    'nomma',
    'nomme',
    'nommé',
    'nonce',
    'nones',
    'nonne',
    'nopai',
    'nopal',
    'nopas',
    'nopât',
    'nopée',
    'noper',
    'nopes',
    'nopés',
    'nopez',
    'nordi',
    'noria',
    'norme',
    'normé',
    'notai',
    'notas',
    'notât',
    'notée',
    'noter',
    'notes',
    'notés',
    'notez',
    'notre',
    'nôtre',
    'nouai',
    'nouas',
    'nouât',
    'nouba',
    'nouée',
    'nouer',
    'noues',
    'noués',
    'nouez',
    'novai',
    'novas',
    'novât',
    'novée',
    'nover',
    'noves',
    'novés',
    'novez',
    'noyai',
    'noyas',
    'noyât',
    'noyau',
    'noyée',
    'noyer',
    'noyés',
    'noyez',
    'nuage',
    'nuais',
    'nuait',
    'nuant',
    'nuées',
    'nuent',
    'nuera',
    'nuiez',
    'nuira',
    'nuire',
    'nuise',
    'nuite',
    'nuits',
    'nulle',
    'nuons',
    'nuque',
    'nurse',
    'nylon',
    'oasis',
    'obéir',
    'obéis',
    'obéit',
    'obéît',
    'obèle',
    'obels',
    'obéra',
    'obéré',
    'obère',
    'obèse',
    'obier',
    'obits',
    'objet',
    'oblat',
    'obole',
    'obtus',
    'obvia',
    'obvie',
    'obvié',
    'occlu',
    'océan',
    'ocrai',
    'ocras',
    'ocrât',
    'ocrée',
    'ocrer',
    'ocres',
    'ocrés',
    'ocrez',
    'octet',
    'odéon',
    'odeur',
    'oeufs',
    'oeuvé',
    'offre',
    'oflag',
    'ogive',
    'ogres',
    'oille',
    'oings',
    'ointe',
    'oints',
    'oisif',
    'oison',
    'okapi',
    'oléum',
    'olive',
    'omble',
    'ombra',
    'ombre',
    'ombré',
    'oméga',
    'omets',
    'omise',
    'onces',
    'oncle',
    'ondée',
    'ondes',
    'ondés',
    'ondin',
    'ongle',
    'onglé',
    'opale',
    'opens',
    'opéra',
    'opéré',
    'opère',
    'opiat',
    'opina',
    'opine',
    'opiné',
    'opium',
    'optai',
    'optas',
    'optât',
    'opter',
    'optes',
    'optez',
    'orage',
    'orale',
    'orant',
    'oraux',
    'orbes',
    'ordre',
    'orées',
    'orges',
    'orgie',
    'orgue',
    'oriel',
    'orins',
    'orles',
    'Orlon',
    'ormes',
    'ornai',
    'ornas',
    'ornât',
    'ornée',
    'orner',
    'ornes',
    'ornés',
    'ornez',
    'orobe',
    'orpin',
    'orque',
    'ortie',
    'orvet',
    'osais',
    'osait',
    'osant',
    'oscar',
    'osées',
    'osent',
    'osera',
    'oside',
    'osier',
    'osiez',
    'osons',
    'osque',
    'ossue',
    'ossus',
    'otage',
    'ôtais',
    'ôtait',
    'ôtant',
    'ôtées',
    'ôtent',
    'ôtera',
    'ôtiez',
    'otite',
    'ôtons',
    'ouais',
    'ouata',
    'ouate',
    'ouaté',
    'oubli',
    'ouche',
    'oueds',
    'ouest',
    'ouïes',
    'ouïra',
    'ourdi',
    'ourla',
    'ourle',
    'ourlé',
    'ourse',
    'ouste',
    'outil',
    'outra',
    'outre',
    'outré',
    'ouvra',
    'ouvre',
    'ouvré',
    'ovale',
    'ovate',
    'ovine',
    'oviné',
    'ovins',
    'ovnis',
    'ovula',
    'ovule',
    'ovulé',
    'oxyda',
    'oxyde',
    'oxydé',
    'oyais',
    'oyats',
    'ozène',
    'ozone',
    'ozoné',
    'pacha',
    'packs',
    'pacte',
    'paddy',
    'padou',
    'pagea',
    'pagée',
    'pagel',
    'pager',
    'pages',
    'pagés',
    'pagez',
    'pagne',
    'pagre',
    'pagus',
    'païen',
    'paies',
    'pains',
    'paire',
    'pairs',
    'palan',
    'palée',
    'pales',
    'pâles',
    'palet',
    'pâlie',
    'pâlir',
    'palis',
    'pâlis',
    'pâlit',
    'pâlît',
    'palma',
    'palme',
    'palmé',
    'palot',
    'pâlot',
    'palpa',
    'palpe',
    'palpé',
    'palud',
    'palus',
    'pâmai',
    'pâmas',
    'pâmât',
    'pâmée',
    'pâmer',
    'pâmes',
    'pâmés',
    'pâmez',
    'pampa',
    'panai',
    'panas',
    'panât',
    'panax',
    'panca',
    'panda',
    'panée',
    'panel',
    'paner',
    'panes',
    'panés',
    'panez',
    'panic',
    'panka',
    'panna',
    'panne',
    'panné',
    'pansa',
    'panse',
    'pansé',
    'pansu',
    'paons',
    'papal',
    'papas',
    'papes',
    'papis',
    'papys',
    'pâque',
    'parai',
    'paras',
    'parât',
    'parce',
    'parcs',
    'pardi',
    'parée',
    'paréo',
    'parer',
    'pares',
    'parés',
    'parez',
    'paria',
    'parie',
    'parié',
    'paris',
    'parka',
    'parla',
    'parle',
    'parlé',
    'parme',
    'parmi',
    'paroi',
    'paros',
    'parsi',
    'parte',
    'parti',
    'parts',
    'parue',
    'parus',
    'parut',
    'parût',
    'passa',
    'passe',
    'passé',
    'pâtai',
    'pâtas',
    'pâtât',
    'pâtée',
    'pater',
    'pâter',
    'pâtes',
    'pâtés',
    'pâtez',
    'patio',
    'pâtir',
    'pâtis',
    'pâtit',
    'pâtît',
    'pâton',
    'pâtre',
    'patta',
    'patte',
    'patté',
    'pattu',
    'pauma',
    'paume',
    'paumé',
    'pausa',
    'pause',
    'pausé',
    'pavai',
    'pavas',
    'pavât',
    'pavée',
    'paver',
    'paves',
    'pavés',
    'pavez',
    'pavie',
    'pavot',
    'payai',
    'payas',
    'payât',
    'payée',
    'payer',
    'payes',
    'payés',
    'payez',
    'payse',
    'péage',
    'péans',
    'peaux',
    'pécha',
    'pêcha',
    'péché',
    'pèche',
    'pêche',
    'pêché',
    'pedum',
    'pègre',
    'peina',
    'peine',
    'peiné',
    'peins',
    'peint',
    'pékan',
    'pékin',
    'pelai',
    'pelas',
    'pelât',
    'pelée',
    'peler',
    'pelés',
    'pèles',
    'pelez',
    'pelle',
    'pelta',
    'pelté',
    'pénal',
    'pende',
    'pends',
    'pendu',
    'pênes',
    'pénil',
    'pénis',
    'penne',
    'penné',
    'penny',
    'penon',
    'pensa',
    'pense',
    'pensé',
    'pente',
    'pentu',
    'péons',
    'pépés',
    'pépia',
    'pépie',
    'pépié',
    'pépin',
    'pépon',
    'perça',
    'perce',
    'percé',
    'perçu',
    'perde',
    'perds',
    'perdu',
    'pères',
    'péril',
    'périr',
    'péris',
    'périt',
    'pérît',
    'perla',
    'perle',
    'perlé',
    'pérot',
    'perse',
    'perte',
    'pesai',
    'pesas',
    'pesât',
    'pesée',
    'peser',
    'pesés',
    'pèses',
    'pesez',
    'peson',
    'pesos',
    'pesse',
    'pesta',
    'peste',
    'pesté',
    'pétai',
    'pétas',
    'pétât',
    'pétée',
    'péter',
    'pétés',
    'pètes',
    'pétez',
    'petit',
    'peton',
    'pétré',
    'pétri',
    'pétun',
    'peuls',
    'peurs',
    'pèzes',
    'phage',
    'phare',
    'phase',
    'philo',
    'phlox',
    'phone',
    'phono',
    'photo',
    'piafs',
    'piano',
    'pians',
    'picas',
    'picot',
    'pièce',
    'pieds',
    'piégé',
    'piège',
    'pietà',
    'piéta',
    'piété',
    'piète',
    'pieux',
    'pièze',
    'pifai',
    'pifas',
    'pifât',
    'pifée',
    'pifer',
    'pifes',
    'pifés',
    'pifez',
    'piffa',
    'piffe',
    'piffé',
    'pigea',
    'pigée',
    'piger',
    'piges',
    'pigés',
    'pigez',
    'pigne',
    'pilaf',
    'pilai',
    'pilas',
    'pilât',
    'pilée',
    'piler',
    'piles',
    'pilés',
    'pilet',
    'pilez',
    'pilla',
    'pille',
    'pillé',
    'pilon',
    'pilou',
    'pilum',
    'pinça',
    'pince',
    'pincé',
    'pinne',
    'pinot',
    'pinta',
    'pinte',
    'pinté',
    'pions',
    'pipai',
    'pipas',
    'pipât',
    'pipée',
    'piper',
    'pipes',
    'pipés',
    'pipez',
    'pipis',
    'pipit',
    'piqua',
    'pique',
    'piqué',
    'pires',
    'pisés',
    'pissa',
    'pisse',
    'pissé',
    'pista',
    'piste',
    'pisté',
    'pites',
    'pitié',
    'piton',
    'pitre',
    'pives',
    'pivot',
    'pizza',
    'plaça',
    'place',
    'placé',
    'plage',
    'plaid',
    'plaie',
    'plais',
    'plaît',
    'plana',
    'plane',
    'plané',
    'plans',
    'plant',
    'plate',
    'plats',
    'plèbe',
    'plein',
    'pleur',
    'pleut',
    'pliai',
    'plias',
    'pliât',
    'pliée',
    'plier',
    'plies',
    'pliés',
    'pliez',
    'ploie',
    'plomb',
    'plots',
    'plouc',
    'plouf',
    'plouk',
    'ploya',
    'ployé',
    'pluie',
    'pluma',
    'plume',
    'plumé',
    'pneus',
    'pocha',
    'poche',
    'poché',
    'poêla',
    'poêle',
    'poêlé',
    'poële',
    'poème',
    'poète',
    'pogne',
    'poids',
    'poila',
    'poile',
    'poilé',
    'poils',
    'poilu',
    'poing',
    'poins',
    'point',
    'poire',
    'poiré',
    'poise',
    'poker',
    'polar',
    'pôles',
    'polie',
    'polio',
    'polir',
    'polis',
    'polit',
    'polît',
    'polka',
    'polos',
    'pomma',
    'pomme',
    'pommé',
    'pompa',
    'pompe',
    'pompé',
    'ponça',
    'ponce',
    'poncé',
    'ponde',
    'ponds',
    'pondu',
    'poney',
    'pongé',
    'ponta',
    'ponte',
    'ponté',
    'ponts',
    'pools',
    'popes',
    'poqua',
    'poque',
    'poqué',
    'porcs',
    'pores',
    'porno',
    'porta',
    'porte',
    'porté',
    'porto',
    'ports',
    'posai',
    'posas',
    'posât',
    'posée',
    'poser',
    'poses',
    'posés',
    'posez',
    'posta',
    'poste',
    'posté',
    'potée',
    'potes',
    'potin',
    'pouah',
    'pouce',
    'poufs',
    'poule',
    'pouls',
    'poupe',
    'prame',
    'préau',
    'prèle',
    'prêle',
    'prend',
    'prêta',
    'prête',
    'prêté',
    'prêts',
    'preux',
    'prévu',
    'priai',
    'prias',
    'priât',
    'priée',
    'prier',
    'pries',
    'priés',
    'priez',
    'prima',
    'prime',
    'primé',
    'primo',
    'prisa',
    'prise',
    'prisé',
    'priva',
    'prive',
    'privé',
    'probe',
    'profs',
    'proie',
    'prolo',
    'promo',
    'promu',
    'prôna',
    'prône',
    'prôné',
    'prose',
    'prote',
    'proue',
    'provo',
    'prude',
    'prune',
    'psitt',
    'psoas',
    'ptôse',
    'puais',
    'puait',
    'puant',
    'pubis',
    'puces',
    'puche',
    'puées',
    'puent',
    'puera',
    'puiez',
    'puîné',
    'puisa',
    'puise',
    'puisé',
    'puits',
    'pulls',
    'pulpe',
    'pulsa',
    'pulse',
    'pulsé',
    'pumas',
    'pûmes',
    'punas',
    'punch',
    'punie',
    'punir',
    'punis',
    'punit',
    'punît',
    'punks',
    'puons',
    'pupes',
    'purée',
    'pures',
    'purge',
    'purgé',
    'purin',
    'purot',
    'pusse',
    'putes',
    'pûtes',
    'putti',
    'putto',
    'pyrex',
    'quais',
    'quand',
    'quant',
    'quark',
    'quart',
    'quasi',
    'quels',
    'quêta',
    'quête',
    'quêté',
    'queue',
    'queux',
    'quiet',
    'quine',
    'quiné',
    'quint',
    'quipo',
    'quipu',
    'quota',
    'raban',
    'rabat',
    'rabbi',
    'râbla',
    'râble',
    'râblé',
    'rabot',
    'racée',
    'racer',
    'races',
    'racés',
    'racla',
    'racle',
    'raclé',
    'radai',
    'radar',
    'radas',
    'radât',
    'radée',
    'rader',
    'rades',
    'radés',
    'radez',
    'radia',
    'radie',
    'radié',
    'radin',
    'radio',
    'radis',
    'rafla',
    'rafle',
    'raflé',
    'ragea',
    'rager',
    'rages',
    'ragez',
    'ragot',
    'ragua',
    'rague',
    'ragué',
    'raïas',
    'raide',
    'raidi',
    'raids',
    'raies',
    'rails',
    'raina',
    'raine',
    'rainé',
    'rajah',
    'rajas',
    'rakis',
    'râlai',
    'râlas',
    'râlât',
    'râler',
    'râles',
    'râlez',
    'ramai',
    'ramas',
    'ramât',
    'ramée',
    'ramer',
    'rames',
    'ramés',
    'ramez',
    'ramie',
    'ramis',
    'rampa',
    'rampe',
    'rampé',
    'rance',
    'ranch',
    'ranci',
    'range',
    'rangé',
    'rangs',
    'ranis',
    'raout',
    'râpai',
    'râpas',
    'râpât',
    'râpée',
    'râper',
    'râpes',
    'râpés',
    'râpez',
    'rapin',
    'rapts',
    'raqua',
    'raque',
    'raqué',
    'rares',
    'rasai',
    'rasas',
    'rasât',
    'rasée',
    'raser',
    'rases',
    'rasés',
    'rasez',
    'rashs',
    'rasta',
    'ratai',
    'ratas',
    'ratât',
    'ratée',
    'ratel',
    'rater',
    'rates',
    'ratés',
    'ratez',
    'ratio',
    'raton',
    'raves',
    'ravie',
    'ravin',
    'ravir',
    'ravis',
    'ravit',
    'ravît',
    'rayai',
    'rayas',
    'rayât',
    'rayée',
    'rayer',
    'rayes',
    'rayés',
    'rayez',
    'rayon',
    'réacs',
    'réagi',
    'réais',
    'réait',
    'réale',
    'réant',
    'réaux',
    'rebab',
    'rebat',
    'rebec',
    'rébus',
    'rebut',
    'recel',
    'recès',
    'recez',
    'rêche',
    'récif',
    'récit',
    'recru',
    'recrû',
    'recta',
    'recto',
    'reçue',
    'recul',
    'reçus',
    'reçut',
    'reçût',
    'redan',
    'rédie',
    'redis',
    'redit',
    'redît',
    'redue',
    'redus',
    'redut',
    'redût',
    'réels',
    'réélu',
    'réent',
    'réera',
    'refis',
    'refit',
    'refît',
    'refus',
    'régal',
    'régie',
    'régir',
    'régis',
    'régit',
    'régît',
    'régla',
    'réglé',
    'règle',
    'réglo',
    'régna',
    'régné',
    'règne',
    'réiez',
    'reine',
    'reins',
    'rejet',
    'relax',
    'relia',
    'relie',
    'relié',
    'relis',
    'relit',
    'relue',
    'relus',
    'relut',
    'relût',
    'remet',
    'remis',
    'remit',
    'remît',
    'rémiz',
    'remua',
    'remue',
    'remué',
    'rénal',
    'rende',
    'rends',
    'rendu',
    'renée',
    'renés',
    'rênes',
    'renia',
    'renie',
    'renié',
    'renne',
    'renom',
    'renta',
    'rente',
    'renté',
    'réons',
    'repas',
    'repic',
    'répit',
    'repli',
    'replu',
    'repos',
    'repue',
    'repus',
    'reput',
    'repût',
    'resta',
    'reste',
    'resté',
    'rétif',
    'rétro',
    'réuni',
    'rêvai',
    'rêvas',
    'rêvât',
    'rêvée',
    'rêver',
    'rêves',
    'rêvés',
    'revêt',
    'rêvez',
    'revis',
    'revit',
    'revît',
    'revue',
    'revus',
    'rhuma',
    'rhumb',
    'rhume',
    'rhumé',
    'rhums',
    'riais',
    'riait',
    'rials',
    'riant',
    'ribla',
    'rible',
    'riblé',
    'riche',
    'ricin',
    'ridai',
    'ridas',
    'ridât',
    'ridée',
    'rider',
    'rides',
    'ridés',
    'ridez',
    'riels',
    'riens',
    'rient',
    'rieur',
    'rifla',
    'rifle',
    'riflé',
    'rifts',
    'riiez',
    'rimai',
    'rimas',
    'rimât',
    'rimée',
    'rimer',
    'rimes',
    'rimés',
    'rîmes',
    'rimez',
    'rinça',
    'rince',
    'rincé',
    'rings',
    'rions',
    'ripai',
    'ripas',
    'ripât',
    'ripée',
    'riper',
    'ripes',
    'ripés',
    'ripez',
    'rirai',
    'riras',
    'rires',
    'rirez',
    'risée',
    'risse',
    'rital',
    'rites',
    'rîtes',
    'rivai',
    'rival',
    'rivas',
    'rivât',
    'rivée',
    'river',
    'rives',
    'rivés',
    'rivet',
    'rivez',
    'rixes',
    'robai',
    'robas',
    'robât',
    'robée',
    'rober',
    'robes',
    'robés',
    'robez',
    'robin',
    'robot',
    'roche',
    'rocks',
    'rocou',
    'rodai',
    'rôdai',
    'rodas',
    'rôdas',
    'rodât',
    'rôdât',
    'rodée',
    'rodéo',
    'roder',
    'rôder',
    'rodes',
    'rodés',
    'rôdes',
    'rodez',
    'rôdez',
    'rogna',
    'rogne',
    'rogné',
    'rogue',
    'rogué',
    'roide',
    'roidi',
    'rôles',
    'roman',
    'rompe',
    'romps',
    'rompt',
    'rompu',
    'ronce',
    'ronde',
    'rondi',
    'rondo',
    'ronds',
    'Ronéo',
    'ronge',
    'rongé',
    'roqua',
    'roque',
    'roqué',
    'rosai',
    'rosas',
    'rosat',
    'rosât',
    'rosée',
    'roser',
    'roses',
    'rosés',
    'rosez',
    'rosie',
    'rosir',
    'rosis',
    'rosit',
    'rosît',
    'rossa',
    'rosse',
    'rossé',
    'rotai',
    'rotas',
    'rotât',
    'roter',
    'rotes',
    'rotez',
    'rôtie',
    'rotin',
    'rôtir',
    'rôtis',
    'rôtit',
    'rôtît',
    'rotor',
    'rouai',
    'rouan',
    'rouas',
    'rouât',
    'rouée',
    'rouer',
    'roues',
    'roués',
    'rouet',
    'rouez',
    'roufs',
    'rouge',
    'rougi',
    'rouie',
    'rouir',
    'rouis',
    'rouit',
    'rouît',
    'roula',
    'roule',
    'roulé',
    'roumi',
    'round',
    'routa',
    'route',
    'routé',
    'royal',
    'ruade',
    'ruais',
    'ruait',
    'ruant',
    'ruban',
    'rubis',
    'rucha',
    'ruche',
    'ruché',
    'rudes',
    'ruées',
    'ruent',
    'ruera',
    'rugby',
    'rugir',
    'rugis',
    'rugit',
    'rugît',
    'ruiez',
    'ruila',
    'ruile',
    'ruilé',
    'ruina',
    'ruine',
    'ruiné',
    'rumba',
    'rumen',
    'rumex',
    'ruolz',
    'ruons',
    'rupin',
    'rural',
    'rusai',
    'rusas',
    'rusât',
    'rusée',
    'ruser',
    'ruses',
    'rusés',
    'rusez',
    'rushs',
    'russe',
    'sabir',
    'sabla',
    'sable',
    'sablé',
    'sabot',
    'sabra',
    'sabre',
    'sabré',
    'sache',
    'sacra',
    'sacre',
    'sacré',
    'safre',
    'sagas',
    'sages',
    'sagou',
    'sagum',
    'sahel',
    'saïga',
    'saine',
    'sains',
    'saint',
    'saisi',
    'saïte',
    'sajou',
    'sakés',
    'sakis',
    'salai',
    'salas',
    'salât',
    'salée',
    'salep',
    'saler',
    'sales',
    'salés',
    'salez',
    'salie',
    'salin',
    'salir',
    'salis',
    'salit',
    'salît',
    'salle',
    'salol',
    'salon',
    'salop',
    'salsa',
    'salse',
    'salua',
    'salue',
    'salué',
    'salut',
    'salve',
    'samba',
    'samit',
    'sampi',
    'sanas',
    'sangs',
    'sanie',
    'santé',
    'sanve',
    'sanza',
    'saoul',
    'sapai',
    'sapas',
    'sapât',
    'sapée',
    'saper',
    'sapes',
    'sapés',
    'sapez',
    'sapin',
    'saqua',
    'saque',
    'saqué',
    'sarde',
    'saris',
    'saros',
    'sassa',
    'sasse',
    'sassé',
    'satin',
    'satîs',
    'sauça',
    'sauce',
    'saucé',
    'sauge',
    'saule',
    'sauna',
    'saune',
    'sauné',
    'saura',
    'saure',
    'sauré',
    'sauri',
    'saurs',
    'sauta',
    'saute',
    'sauté',
    'sauts',
    'sauva',
    'sauve',
    'sauvé',
    'savez',
    'savon',
    'saxes',
    'saxon',
    'saxos',
    'sayon',
    'sbire',
    'scalp',
    'scare',
    'scats',
    'sceau',
    'scène',
    'schah',
    'sciai',
    'scias',
    'sciât',
    'sciée',
    'scier',
    'scies',
    'sciés',
    'sciez',
    'scion',
    'scoop',
    'score',
    'scout',
    'scull',
    'scuta',
    'séant',
    'seaux',
    'sebka',
    'sébum',
    'sécha',
    'séché',
    'sèche',
    'secte',
    'séide',
    'seime',
    'seine',
    'seing',
    'seins',
    'seize',
    'selfs',
    'sella',
    'selle',
    'sellé',
    'selon',
    'seltz',
    'selve',
    'semai',
    'semas',
    'semât',
    'semée',
    'semer',
    'semés',
    'sèmes',
    'semez',
    'semis',
    'sénat',
    'senau',
    'sénés',
    'senne',
    'sensé',
    'sente',
    'senti',
    'seoir',
    'sépia',
    'sérac',
    'serai',
    'seras',
    'serbe',
    'serez',
    'serfs',
    'serge',
    'sergé',
    'séria',
    'série',
    'sérié',
    'serin',
    'serpe',
    'serra',
    'serre',
    'serré',
    'serte',
    'serti',
    'sérum',
    'serve',
    'servi',
    'séton',
    'seuil',
    'seule',
    'seuls',
    'sèves',
    'sévir',
    'sévis',
    'sévit',
    'sévît',
    'sevra',
    'sevre',
    'sevré',
    'sexes',
    'sexte',
    'sexto',
    'sexué',
    'shahs',
    'shako',
    'shoot',
    'short',
    'shows',
    'shunt',
    'sicle',
    'siégé',
    'siège',
    'siens',
    'siéra',
    'sieur',
    'sigle',
    'sigma',
    'signa',
    'signe',
    'signé',
    'silex',
    'silos',
    'simas',
    'singe',
    'singé',
    'sinon',
    'sinus',
    'sioux',
    'sires',
    'sirex',
    'sirli',
    'sirop',
    'sisal',
    'sises',
    'sitar',
    'sites',
    'sitôt',
    'situa',
    'situe',
    'situé',
    'siums',
    'sixte',
    'Skaïs',
    'skate',
    'skiai',
    'skias',
    'skiât',
    'skier',
    'skies',
    'skiez',
    'skiff',
    'skifs',
    'slang',
    'slave',
    'slips',
    'sloop',
    'slows',
    'smala',
    'smalt',
    'smart',
    'smash',
    'smogs',
    'smolt',
    'smurf',
    'snack',
    'sniff',
    'snoba',
    'snobe',
    'snobé',
    'snobs',
    'sobre',
    'socle',
    'sodas',
    'sodée',
    'sodés',
    'soeur',
    'sofas',
    'soies',
    'soifs',
    'soins',
    'soirs',
    'sojas',
    'solda',
    'solde',
    'soldé',
    'solen',
    'soles',
    'solex',
    'solin',
    'solos',
    'somas',
    'somma',
    'somme',
    'sommé',
    'sonar',
    'sonda',
    'sonde',
    'sondé',
    'songe',
    'songé',
    'sonna',
    'sonne',
    'sonné',
    'sonos',
    'sorbe',
    'sores',
    'sorte',
    'sorti',
    'sorts',
    'sosie',
    'sotch',
    'sotie',
    'sotte',
    'souci',
    'souda',
    'soude',
    'soudé',
    'soues',
    'souks',
    'soûla',
    'soûle',
    'soûlé',
    'soûls',
    'soupa',
    'soupe',
    'soupé',
    'sourd',
    'souri',
    'soute',
    'soyas',
    'soyer',
    'soyez',
    'spahi',
    'spart',
    'spath',
    'spéos',
    'sphex',
    'spire',
    'spore',
    'sport',
    'spots',
    'sprat',
    'spray',
    'sprue',
    'squat',
    'squaw',
    'stade',
    'staff',
    'stage',
    'stand',
    'stars',
    'stase',
    'steak',
    'stèle',
    'stemm',
    'stems',
    'sténo',
    'stéra',
    'stéré',
    'stère',
    'stick',
    'stipe',
    'stock',
    'stops',
    'store',
    'stout',
    'stras',
    'stria',
    'strie',
    'strié',
    'strix',
    'stucs',
    'styla',
    'style',
    'stylé',
    'stylo',
    'suage',
    'suais',
    'suait',
    'suant',
    'suave',
    'suber',
    'subie',
    'subir',
    'subis',
    'subit',
    'subît',
    'suçai',
    'suças',
    'suçât',
    'sucée',
    'sucer',
    'suces',
    'sucés',
    'sucez',
    'suçon',
    'sucra',
    'sucre',
    'sucré',
    'suédé',
    'suède',
    'suées',
    'suent',
    'suera',
    'sueur',
    'suies',
    'suiez',
    'suifa',
    'suife',
    'suifé',
    'suifs',
    'suint',
    'suite',
    'suive',
    'suivi',
    'sujet',
    'sulky',
    'sumac',
    'sûmes',
    'sunna',
    'suons',
    'super',
    'supin',
    'supra',
    'surah',
    'sures',
    'sûres',
    'suret',
    'surfa',
    'surfe',
    'surfé',
    'surfs',
    'surgi',
    'surin',
    'surir',
    'suris',
    'surit',
    'surît',
    'suros',
    'sushi',
    'susse',
    'sûtes',
    'sutra',
    'swaps',
    'swing',
    'sylve',
    'sympa',
    'tabac',
    'tabar',
    'tabès',
    'tabla',
    'table',
    'tablé',
    'tabor',
    'tabou',
    'tacca',
    'tacet',
    'tacha',
    'tâcha',
    'tache',
    'taché',
    'tâche',
    'tâché',
    'tacon',
    'tacot',
    'tacts',
    'tafia',
    'tagal',
    'taies',
    'taïga',
    'tains',
    'taira',
    'taire',
    'taise',
    'talai',
    'talas',
    'talât',
    'talcs',
    'talée',
    'taler',
    'tales',
    'talés',
    'talez',
    'talla',
    'talle',
    'tallé',
    'talon',
    'talus',
    'tamia',
    'tamil',
    'tamis',
    'tança',
    'tance',
    'tancé',
    'tango',
    'tanin',
    'tanks',
    'tanna',
    'tanne',
    'tanné',
    'tante',
    'taons',
    'tapai',
    'tapas',
    'tapât',
    'tapée',
    'taper',
    'tapes',
    'tapés',
    'tapez',
    'tapie',
    'tapin',
    'tapir',
    'tapis',
    'tapit',
    'tapît',
    'tapon',
    'taqua',
    'taque',
    'taqué',
    'tarai',
    'taras',
    'tarât',
    'tarda',
    'tarde',
    'tardé',
    'tarée',
    'tarer',
    'tares',
    'tarés',
    'taret',
    'tarez',
    'targe',
    'tarie',
    'tarif',
    'tarin',
    'tarir',
    'taris',
    'tarit',
    'tarît',
    'tarot',
    'tarse',
    'tarte',
    'tarti',
    'tassa',
    'tasse',
    'tassé',
    'tâtai',
    'tatas',
    'tâtas',
    'tâtât',
    'tâtée',
    'tâter',
    'tâtes',
    'tâtés',
    'tâtez',
    'tatou',
    'taule',
    'taupe',
    'taupé',
    'taure',
    'taxai',
    'taxas',
    'taxât',
    'taxée',
    'taxer',
    'taxes',
    'taxés',
    'taxez',
    'taxie',
    'taxis',
    'taxon',
    'tchao',
    'tecks',
    'teins',
    'teint',
    'télés',
    'télex',
    'telle',
    'tells',
    'tempe',
    'tempo',
    'temps',
    'tende',
    'tends',
    'tendu',
    'tenez',
    'ténia',
    'tenir',
    'tenon',
    'ténor',
    'tenta',
    'tente',
    'tenté',
    'tenue',
    'ténue',
    'tenus',
    'ténus',
    'terça',
    'terce',
    'tercé',
    'terme',
    'terne',
    'terni',
    'terra',
    'terre',
    'terré',
    'terri',
    'tersa',
    'terse',
    'tersé',
    'testa',
    'teste',
    'testé',
    'tests',
    'tétai',
    'tétas',
    'tétât',
    'tétée',
    'téter',
    'tétés',
    'tètes',
    'têtes',
    'tétez',
    'tétin',
    'téton',
    'tétra',
    'tette',
    'têtue',
    'têtus',
    'texan',
    'texte',
    'thaïe',
    'thème',
    'thèse',
    'thêta',
    'thons',
    'thora',
    'thune',
    'thuya',
    'thyms',
    'tians',
    'tiare',
    'tibia',
    'tiède',
    'tiédi',
    'tiens',
    'tient',
    'tiers',
    'tiffe',
    'tiges',
    'tigra',
    'tigre',
    'tigré',
    'tilde',
    'tilla',
    'tille',
    'tillé',
    'tilts',
    'timon',
    'tinta',
    'tinte',
    'tinté',
    'tiqua',
    'tique',
    'tiqué',
    'tirai',
    'tiras',
    'tirât',
    'tirée',
    'tirer',
    'tires',
    'tirés',
    'tiret',
    'tirez',
    'tisai',
    'tisas',
    'tisât',
    'tisée',
    'tiser',
    'tises',
    'tisés',
    'tisez',
    'tison',
    'tissa',
    'tisse',
    'tissé',
    'tissu',
    'titan',
    'titis',
    'titra',
    'titre',
    'titré',
    'tmèse',
    'toast',
    'toges',
    'toila',
    'toile',
    'toilé',
    'toisa',
    'toise',
    'toisé',
    'toits',
    'tokai',
    'tokay',
    'tôlée',
    'tôles',
    'tolet',
    'tollé',
    'tolus',
    'tomai',
    'tomas',
    'tomât',
    'tomba',
    'tombe',
    'tombé',
    'tomée',
    'tomer',
    'tomes',
    'tomés',
    'tomez',
    'tomme',
    'tommy',
    'tonal',
    'tonde',
    'tonds',
    'tondu',
    'tonie',
    'tonka',
    'tonna',
    'tonne',
    'tonné',
    'tonte',
    'tonus',
    'topai',
    'topas',
    'topât',
    'toper',
    'topes',
    'topez',
    'topos',
    'toqua',
    'toque',
    'toqué',
    'torah',
    'torde',
    'tords',
    'tordu',
    'toréa',
    'torée',
    'toréé',
    'torii',
    'toril',
    'toron',
    'torse',
    'torts',
    'torve',
    'total',
    'totem',
    'toton',
    'totos',
    'touai',
    'touas',
    'touât',
    'touée',
    'touer',
    'toues',
    'toués',
    'touez',
    'tourd',
    'tours',
    'toute',
    'touts',
    'trabe',
    'traça',
    'trace',
    'tracé',
    'tracs',
    'tract',
    'trahi',
    'traie',
    'train',
    'trais',
    'trait',
    'trama',
    'trame',
    'tramé',
    'trams',
    'trapu',
    'tréma',
    'trêve',
    'triai',
    'trial',
    'trias',
    'triât',
    'tribu',
    'tridi',
    'triée',
    'trier',
    'tries',
    'triés',
    'triez',
    'trima',
    'trime',
    'trimé',
    'trine',
    'trins',
    'triol',
    'trios',
    'tripe',
    'trips',
    'trocs',
    'trois',
    'troll',
    'trôna',
    'tronc',
    'trône',
    'trôné',
    'trope',
    'trots',
    'troua',
    'troue',
    'troué',
    'trous',
    'trucs',
    'truie',
    'trust',
    'tsars',
    'tuage',
    'tuais',
    'tuait',
    'tuant',
    'tubai',
    'tubas',
    'tubât',
    'tubée',
    'tuber',
    'tubes',
    'tubés',
    'tubez',
    'tuées',
    'tuent',
    'tuera',
    'tueur',
    'tuiez',
    'tuila',
    'tuile',
    'tuilé',
    'tulle',
    'tûmes',
    'tuner',
    'tunes',
    'tuons',
    'tuque',
    'turbe',
    'turbé',
    'turco',
    'turcs',
    'turfs',
    'turne',
    'tusse',
    'tûtes',
    'tutie',
    'tutti',
    'tutus',
    'tuyau',
    'tweed',
    'twist',
    'typai',
    'typas',
    'typât',
    'typée',
    'typer',
    'types',
    'typés',
    'typez',
    'typha',
    'typon',
    'typos',
    'tyran',
    'tzars',
    'ubacs',
    'ukase',
    'uléma',
    'ultra',
    'ulula',
    'ulule',
    'ululé',
    'ulves',
    'unaus',
    'unies',
    'union',
    'unira',
    'unité',
    'urane',
    'urate',
    'urées',
    'urgea',
    'urger',
    'urina',
    'urine',
    'uriné',
    'urnes',
    'urubu',
    'usage',
    'usagé',
    'usais',
    'usait',
    'usant',
    'usées',
    'usent',
    'usera',
    'usiez',
    'usina',
    'usine',
    'usiné',
    'usité',
    'usnée',
    'usons',
    'usuel',
    'usure',
    'utile',
    'uvale',
    'uvaux',
    'uvées',
    'uvula',
    'uvule',
    'vache',
    'vagin',
    'vagir',
    'vagis',
    'vagit',
    'vagua',
    'vague',
    'vagué',
    'vainc',
    'vaine',
    'vains',
    'vairé',
    'vairs',
    'valet',
    'valez',
    'valsa',
    'valse',
    'valsé',
    'value',
    'valus',
    'valut',
    'valût',
    'valve',
    'valvé',
    'vampa',
    'vampe',
    'vampé',
    'vamps',
    'vanda',
    'vanna',
    'vanne',
    'vanné',
    'vanta',
    'vante',
    'vanté',
    'vapes',
    'vaqua',
    'vaque',
    'vaqué',
    'varan',
    'varia',
    'varie',
    'varié',
    'varus',
    'varve',
    'vases',
    'vaste',
    'veaux',
    'vécés',
    'vécue',
    'vécus',
    'vécut',
    'vécût',
    'Védas',
    'veina',
    'veine',
    'veiné',
    'vêlai',
    'vélar',
    'vêlas',
    'vêlât',
    'velds',
    'vêler',
    'vêles',
    'vêlez',
    'vélie',
    'vélin',
    'vélos',
    'velot',
    'velte',
    'velue',
    'vélum',
    'velus',
    'vénal',
    'vende',
    'vends',
    'vendu',
    'venet',
    'venez',
    'venge',
    'vengé',
    'venin',
    'venir',
    'venta',
    'vente',
    'venté',
    'vents',
    'venue',
    'venus',
    'vénus',
    'verbe',
    'verdi',
    'verge',
    'vergé',
    'vérin',
    'verni',
    'verra',
    'verre',
    'verré',
    'versa',
    'verse',
    'versé',
    'verso',
    'verte',
    'verts',
    'vertu',
    'verve',
    'vesce',
    'Vespa',
    'vessa',
    'vesse',
    'vessé',
    'veste',
    'vêtes',
    'vêtez',
    'vêtir',
    'vêtis',
    'vêtit',
    'vêtît',
    'vêtue',
    'vêtus',
    'veufs',
    'veule',
    'veuve',
    'vexai',
    'vexas',
    'vexât',
    'vexée',
    'vexer',
    'vexes',
    'vexés',
    'vexez',
    'vibra',
    'vibre',
    'vibré',
    'vices',
    'vichy',
    'vicia',
    'vicie',
    'vicié',
    'vidai',
    'vidas',
    'vidât',
    'vidée',
    'vidéo',
    'vider',
    'vides',
    'vidés',
    'videz',
    'vieil',
    'viens',
    'vient',
    'vieux',
    'vigie',
    'vigne',
    'viles',
    'villa',
    'ville',
    'vîmes',
    'vinai',
    'vinas',
    'vinât',
    'vinée',
    'viner',
    'vines',
    'vinés',
    'vinez',
    'vingt',
    'viocs',
    'viola',
    'viole',
    'violé',
    'viols',
    'virai',
    'viral',
    'viras',
    'virât',
    'virée',
    'virer',
    'vires',
    'virés',
    'virez',
    'viril',
    'virus',
    'visai',
    'visas',
    'visât',
    'visée',
    'viser',
    'vises',
    'visés',
    'visez',
    'vison',
    'vissa',
    'visse',
    'vissé',
    'vitae',
    'vital',
    'vîtes',
    'vitra',
    'vitre',
    'vitré',
    'vivat',
    'vives',
    'vivez',
    'vivra',
    'vivre',
    'vivré',
    'vizir',
    'vocal',
    'vodka',
    'voeux',
    'vogua',
    'vogue',
    'vogué',
    'voici',
    'voies',
    'voila',
    'voilà',
    'voile',
    'voilé',
    'voire',
    'volai',
    'volas',
    'volât',
    'volée',
    'voler',
    'voles',
    'volés',
    'volet',
    'volez',
    'volis',
    'volta',
    'volte',
    'volté',
    'volts',
    'volve',
    'vomer',
    'vomie',
    'vomir',
    'vomis',
    'vomit',
    'vomît',
    'votai',
    'votas',
    'votât',
    'votée',
    'voter',
    'votes',
    'votés',
    'votez',
    'votif',
    'votre',
    'vôtre',
    'vouai',
    'vouas',
    'vouât',
    'vouée',
    'vouer',
    'voues',
    'voués',
    'vouez',
    'vouge',
    'voulu',
    'voûta',
    'voûte',
    'voûté',
    'voyer',
    'voyez',
    'voyou',
    'vraie',
    'vrais',
    'vulgo',
    'vulve',
    'wagon',
    'watts',
    'weber',
    'wharf',
    'whigs',
    'whist',
    'winch',
    'xénon',
    'xérès',
    'xérus',
    'xipho',
    'xyste',
    'yacht',
    'yacks',
    'yards',
    'yèble',
    'yeuse',
    'yé-yé',
    'yogas',
    'yogis',
    'yoles',
    'yucca',
    'zabre',
    'zains',
    'zanis',
    'zanni',
    'zanzi',
    'zazou',
    'zébra',
    'zébré',
    'zèbre',
    'zébus',
    'zélée',
    'zélés',
    'zèles',
    'zends',
    'zéros',
    'zesta',
    'zeste',
    'zesté',
    'zibai',
    'zibas',
    'zibât',
    'zibée',
    'ziber',
    'zibes',
    'zibés',
    'zibez',
    'zigua',
    'zigue',
    'zigué',
    'zincs',
    'zippa',
    'zippe',
    'zippé',
    'zizis',
    'zloty',
    'zoïle',
    'zombi',
    'zonai',
    'zonal',
    'zonas',
    'zonât',
    'zonée',
    'zoner',
    'zones',
    'zonés',
    'zonez',
    'zooms',
    'zozos'
];
