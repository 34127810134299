import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow } from '@mui/material';

function NumberDisplay(props) {
    return <React.Fragment>{isNaN(props.value) ? '/' : props.value}</React.Fragment>;
}

NumberDisplay.propTypes = {
    value: PropTypes.any.isRequired
};

export default function HuntTableRow(props) {
    const { values, title } = props;
    return (
        <React.Fragment>
            <TableRow>
                <TableCell component="th" scope="row">
                    {title}
                </TableCell>
                <TableCell align="right">
                    <NumberDisplay value={values.lv1} />
                </TableCell>
                <TableCell align="right">
                    <NumberDisplay value={values.lv2} />
                </TableCell>
                <TableCell align="right">
                    <NumberDisplay value={values.lv3} />
                </TableCell>
                <TableCell align="right">
                    <NumberDisplay value={values.lv4} />
                </TableCell>
                <TableCell align="right">
                    <NumberDisplay value={values.lv5} />
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

HuntTableRow.propTypes = {
    title: PropTypes.string.isRequired,
    values: PropTypes.object.isRequired
};
