import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Typography,
    Avatar,
    IconButton
} from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { getLineStatus, getNextDepartures } from '../../common/ratp.api';

export default function TransportLine(props) {
    const { type, line, departureStation, style, avatar, directionName, lineRef, nextStopRef } =
        props.transport;

    const [isPending, setIsPending] = useState(false);
    const [departureAvailability, setDepartureAvailability] = useState(false);
    const [nextDepartures, setNextDepartures] = useState([]);
    const [isStatusAvailable] = useState(false);
    const [status, setStatus] = useState('');
    const [statusMessage, setStatusMessage] = useState('');

    useEffect(() => {
        load();
    }, []);

    async function load() {
        setIsPending(true);
        await Promise.all([departures(), lineStatus()]);
        setIsPending(false);
    }

    async function departures() {
        const data = await getNextDepartures(nextStopRef);
        console.log(data);
        setDepartureAvailability(true);
        setNextDepartures(data);
    }

    async function lineStatus() {
        const data = await getLineStatus(lineRef);
        if (data.length > 0) {
            setStatus(data[0].title);
            setStatusMessage(data[0].message);
        }
    }

    function getTypeDisplay() {
        switch (type) {
            case 'tramways':
                return `T${line}`;
            case 'rers':
                return `RER ${line}`;
            case 'metros':
                return `Metro ${line}`;
            case 'buses':
                return `Bus ${line}`;
            case 'noctiliens':
                return `Noctilien ${line}`;
        }
    }

    return (
        <React.Fragment>
            <Card className="mt-m">
                <CardHeader
                    avatar={<Avatar sx={{ ...style }}>{avatar}</Avatar>}
                    title={`${getTypeDisplay()} au départ de ${departureStation}`}
                    subheader={`Direction : ${directionName}`}
                    action={
                        <IconButton onClick={load}>
                            <Refresh />
                        </IconButton>
                    }
                />
                <CardContent style={{ paddingTop: '5px', paddingBottom: '10px' }}>
                    {isPending ? (
                        <div className="row mt-l mb-m justify-center">
                            <CircularProgress />
                        </div>
                    ) : (
                        <React.Fragment>
                            <div className="row mb-s">
                                <Typography variant="h6">Prochains passages</Typography>
                            </div>
                            {departureAvailability ? (
                                nextDepartures.map(({ direction, timeToWait }, index) => (
                                    <div key={index} className="row mb-xs">
                                        <Typography>
                                            - {direction} dans {timeToWait} m
                                        </Typography>
                                    </div>
                                ))
                            ) : (
                                <div className="row">
                                    <Typography>
                                        Les données ne sont pas disponibles pour le moment
                                    </Typography>
                                </div>
                            )}
                            <div className="row mt-s">
                                <Typography variant="h6">Info traffic</Typography>
                            </div>
                            <div className="row">
                                <Typography>
                                    {isStatusAvailable ? (
                                        <span>
                                            <b>{status}</b> : {statusMessage}
                                        </span>
                                    ) : (
                                        'Aucun problème sur la ligne'
                                    )}
                                </Typography>
                            </div>
                        </React.Fragment>
                    )}
                </CardContent>
            </Card>
        </React.Fragment>
    );
}

TransportLine.propTypes = {
    transport: PropTypes.object.isRequired
};
