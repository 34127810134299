import PropTypes from 'prop-types';
import { payers } from '../spending.const';
import { Stack, Typography } from '@mui/material';

export default function SpendingResume(props) {
    const { expenses } = props;

    const total = expenses.reduce(
        (acc, expense) => {
            if (expense.consider !== false) {
                const price = expense.both ? expense.price : expense.price * 2;
                if (expense.payer === payers.Audrey) {
                    acc[payers.Audrey].count += 1;
                    acc[payers.Audrey].sum += price;
                } else {
                    acc[payers.Morgan].count += 1;
                    acc[payers.Morgan].sum += price;
                }
            }
            return acc;
        },
        {
            [payers.Audrey]: {
                count: 0,
                sum: 0
            },
            [payers.Morgan]: {
                count: 0,
                sum: 0
            }
        }
    );
    const sum = total[payers.Audrey].sum + total[payers.Morgan].sum;
    total.global = {
        sum,
        count: total[payers.Audrey].count + total[payers.Morgan].count,
        perPerson: Math.round(sum / 2)
    };
    total[payers.Audrey].due = total.global.perPerson - total[payers.Audrey].sum;
    total[payers.Morgan].due = total.global.perPerson - total[payers.Morgan].sum;

    function getTotalText(user) {
        const { sum, count, due } = total[user];
        let baseText = `<b>${user}</b>&nbsp;à payé ${count} fois pour un total de <i>${sum.toFixed(
            2
        )} €</i>`;
        if (due > 0) {
            baseText += `&nbsp;et doit ${Math.round(due).toFixed(2)} €`;
        }
        return baseText + '.';
    }

    function getTotalGlobalText() {
        const { sum, count, perPerson } = total.global;
        return `Ce mois ci, ${count} dépenses on été faite pour un montant total de <i>${sum.toFixed(
            2
        )} €</i>&nbsp; soit (${perPerson.toFixed(2)} € / personnes)`;
    }

    if (!total) {
        return null;
    }

    return (
        <Stack className="mt-s">
            <Typography dangerouslySetInnerHTML={{ __html: getTotalGlobalText() }} />
            <Typography dangerouslySetInnerHTML={{ __html: getTotalText(payers.Audrey) }} />
            <Typography dangerouslySetInnerHTML={{ __html: getTotalText(payers.Morgan) }} />
        </Stack>
    );
}
SpendingResume.propTypes = {
    expenses: PropTypes.array.isRequired
};
