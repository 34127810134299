export default [
    'abaissables',
    'abaissaient',
    'abaissantes',
    'abaissasses',
    'abaissement',
    'abaisserais',
    'abaisserait',
    'abaissèrent',
    'abaisseriez',
    'abaisserons',
    'abaisseront',
    'abandonnais',
    'abandonnait',
    'abandonnant',
    'abandonnées',
    'abandonnent',
    'abandonnera',
    'abandonniez',
    'abandonnons',
    'abasourdies',
    'abasourdira',
    'abâtardîmes',
    'abâtardirai',
    'abâtardiras',
    'abâtardirez',
    'abâtardisse',
    'abâtardîtes',
    'abattements',
    'abattissent',
    'abattissiez',
    'abattraient',
    'abbevillien',
    'abdicataire',
    'abdications',
    'abdiquaient',
    'abdiquasses',
    'abdiquerais',
    'abdiquerait',
    'abdiquèrent',
    'abdiqueriez',
    'abdiquerons',
    'abdiqueront',
    'abdominales',
    'abécédaires',
    'aberrassent',
    'aberrassiez',
    'aberrations',
    'aberrerions',
    'abêtiraient',
    'abêtissante',
    'abêtissants',
    'abêtissions',
    'abhorraient',
    'abhorrasses',
    'abhorrerais',
    'abhorrerait',
    'abhorrèrent',
    'abhorreriez',
    'abhorrerons',
    'abhorreront',
    'abîmassions',
    'abîmeraient',
    'abjectement',
    'abjurassent',
    'abjurassiez',
    'abjurations',
    'abjurerions',
    'abloquaient',
    'abloquasses',
    'abloquerais',
    'abloquerait',
    'abloquèrent',
    'abloqueriez',
    'abloquerons',
    'abloqueront',
    'abnégations',
    'aboieraient',
    'aboliraient',
    'abolissions',
    'abominables',
    'abominaient',
    'abominasses',
    'abomination',
    'abominerais',
    'abominerait',
    'abominèrent',
    'abomineriez',
    'abominerons',
    'abomineront',
    'abondamment',
    'abondassent',
    'abondassiez',
    'abonderions',
    'abonnassent',
    'abonnassiez',
    'abonnements',
    'abonnerions',
    'abonnirions',
    'abonnissais',
    'abonnissait',
    'abonnissant',
    'abonnissent',
    'abonnissiez',
    'abonnissons',
    'abordassent',
    'abordassiez',
    'aborderions',
    'abouchaient',
    'abouchasses',
    'abouchement',
    'aboucherais',
    'aboucherait',
    'abouchèrent',
    'aboucheriez',
    'aboucherons',
    'aboucheront',
    'aboulassent',
    'aboulassiez',
    'aboulerions',
    'aboutassent',
    'aboutassiez',
    'aboutements',
    'abouterions',
    'aboutirions',
    'aboutissais',
    'aboutissait',
    'aboutissant',
    'aboutissent',
    'aboutissiez',
    'aboutissons',
    'aboyassions',
    'abrasassent',
    'abrasassiez',
    'abraserions',
    'abréactions',
    'abrégeaient',
    'abrégeasses',
    'abrégements',
    'abrègements',
    'abrégerions',
    'abreuvaient',
    'abreuvasses',
    'abreuverais',
    'abreuverait',
    'abreuvèrent',
    'abreuveriez',
    'abreuverons',
    'abreuveront',
    'abréviatifs',
    'abréviation',
    'abréviative',
    'abricotâmes',
    'abricotasse',
    'abricotâtes',
    'abricoterai',
    'abricoteras',
    'abricoterez',
    'abricotiers',
    'abricotions',
    'abritassent',
    'abritassiez',
    'abriterions',
    'abrogations',
    'abrogatives',
    'abrogatoire',
    'abrogeables',
    'abrogeaient',
    'abrogeasses',
    'abrogerions',
    'abruptement',
    'abrutirions',
    'abrutissais',
    'abrutissait',
    'abrutissant',
    'abrutissent',
    'abrutissiez',
    'abrutissons',
    'absentéisme',
    'absentéiste',
    'absenteriez',
    'absenterons',
    'absinthisme',
    'absolutions',
    'absolutisme',
    'absolutiste',
    'absolutoire',
    'absolvaient',
    'absorbaient',
    'absorbantes',
    'absorbasses',
    'absorberais',
    'absorberait',
    'absorbèrent',
    'absorberiez',
    'absorberons',
    'absorberont',
    'absorptions',
    'absoudrions',
    'abstentions',
    'abstiendrez',
    'abstinences',
    'abstinentes',
    'abstinssiez',
    'abstracteur',
    'abstraction',
    'abstrairais',
    'abstrairait',
    'abstrairiez',
    'abstrairons',
    'abstrairont',
    'abstrayions',
    'abusassions',
    'abuseraient',
    'abusivement',
    'abyssiniens',
    'académicien',
    'académiques',
    'académismes',
    'acagnardées',
    'acagnardiez',
    'acagnardons',
    'acanthacées',
    'acariâtreté',
    'accablaient',
    'accablantes',
    'accablasses',
    'accablement',
    'accablerais',
    'accablerait',
    'accablèrent',
    'accableriez',
    'accablerons',
    'accableront',
    'accaparâmes',
    'accaparasse',
    'accaparâtes',
    'accaparerai',
    'accapareras',
    'accaparerez',
    'accapareurs',
    'accapareuse',
    'accaparions',
    'accastillai',
    'accastillas',
    'accastillât',
    'accastillée',
    'accastiller',
    'accastilles',
    'accastillés',
    'accastillez',
    'accédassent',
    'accédassiez',
    'accéderions',
    'accélérâmes',
    'accelerando',
    'accélérasse',
    'accélérâtes',
    'accélérerai',
    'accéléreras',
    'accélérerez',
    'accélérions',
    'accentuâmes',
    'accentuasse',
    'accentuâtes',
    'accentuelle',
    'accentuerai',
    'accentueras',
    'accentuerez',
    'accentuions',
    'acceptables',
    'acceptaient',
    'acceptantes',
    'acceptasses',
    'acceptation',
    'accepterais',
    'accepterait',
    'acceptèrent',
    'accepteriez',
    'accepterons',
    'accepteront',
    'accessibles',
    'accessoires',
    'accidentais',
    'accidentait',
    'accidentant',
    'accidentées',
    'accidentels',
    'accidentent',
    'accidentera',
    'accidentiez',
    'accidentons',
    'acclamaient',
    'acclamasses',
    'acclamation',
    'acclamerais',
    'acclamerait',
    'acclamèrent',
    'acclameriez',
    'acclamerons',
    'acclameront',
    'acclimatais',
    'acclimatait',
    'acclimatant',
    'acclimatées',
    'acclimatent',
    'acclimatera',
    'acclimatiez',
    'acclimatons',
    'accointâmes',
    'accointâtes',
    'accointerez',
    'accointions',
    'accolassent',
    'accolassiez',
    'accolerions',
    'accommodais',
    'accommodait',
    'accommodant',
    'accommodats',
    'accommodées',
    'accommodent',
    'accommodera',
    'accommodiez',
    'accommodons',
    'accompagnai',
    'accompagnas',
    'accompagnât',
    'accompagnée',
    'accompagner',
    'accompagnes',
    'accompagnés',
    'accompagnez',
    'accomplîmes',
    'accomplirai',
    'accompliras',
    'accomplirez',
    'accomplisse',
    'accomplîtes',
    'accorassent',
    'accorassiez',
    'accordables',
    'accordaient',
    'accordasses',
    'accordement',
    'accorderais',
    'accorderait',
    'accordèrent',
    'accorderiez',
    'accorderons',
    'accorderont',
    'accorerions',
    'accostables',
    'accostaient',
    'accostasses',
    'accosterais',
    'accosterait',
    'accostèrent',
    'accosteriez',
    'accosterons',
    'accosteront',
    'accotassent',
    'accotassiez',
    'accotements',
    'accoterions',
    'accouassent',
    'accouassiez',
    'accouchâmes',
    'accouchasse',
    'accouchâtes',
    'accoucherai',
    'accoucheras',
    'accoucherez',
    'accoucheurs',
    'accoucheuse',
    'accouchions',
    'accoudement',
    'accouderiez',
    'accouderons',
    'accouerions',
    'accouplâmes',
    'accouplasse',
    'accouplâtes',
    'accouplerai',
    'accoupleras',
    'accouplerez',
    'accouplions',
    'accouraient',
    'accourcîmes',
    'accourcirai',
    'accourciras',
    'accourcirez',
    'accourcisse',
    'accourcîtes',
    'accourrions',
    'accoururent',
    'accourusses',
    'accoutrâmes',
    'accoutrasse',
    'accoutrâtes',
    'accoutrerai',
    'accoutreras',
    'accoutrerez',
    'accoutrions',
    'accoutumais',
    'accoutumait',
    'accoutumant',
    'accoutumées',
    'accoutument',
    'accoutumera',
    'accoutumiez',
    'accoutumons',
    'accréditais',
    'accréditait',
    'accréditant',
    'accréditées',
    'accréditent',
    'accréditera',
    'accréditeur',
    'accréditiez',
    'accréditifs',
    'accréditive',
    'accréditons',
    'accrescente',
    'accrescents',
    'accrochages',
    'accrochâmes',
    'accrochasse',
    'accrochâtes',
    'accrocherai',
    'accrocheras',
    'accrocherez',
    'accrocheurs',
    'accrocheuse',
    'accrochions',
    'accroissais',
    'accroissait',
    'accroissant',
    'accroissent',
    'accroissiez',
    'accroissons',
    'accroîtrais',
    'accroîtrait',
    'accroîtriez',
    'accroîtrons',
    'accroîtront',
    'accroupîmes',
    'accroupirez',
    'accroupîtes',
    'accrussions',
    'accueillais',
    'accueillait',
    'accueillant',
    'accueillent',
    'accueillera',
    'accueillies',
    'accueilliez',
    'accueillons',
    'acculassent',
    'acculassiez',
    'acculerions',
    'acculturais',
    'acculturait',
    'acculturant',
    'acculturées',
    'acculturent',
    'acculturera',
    'acculturiez',
    'acculturons',
    'accumulâmes',
    'accumulasse',
    'accumulâtes',
    'accumulerai',
    'accumuleras',
    'accumulerez',
    'accumulions',
    'accusassent',
    'accusassiez',
    'accusateurs',
    'accusations',
    'accusatives',
    'accusatoire',
    'accusatrice',
    'accuserions',
    'acérassions',
    'acéreraient',
    'acétifiâmes',
    'acétifiasse',
    'acétifiâtes',
    'acétifierai',
    'acétifieras',
    'acétifierez',
    'acétifiions',
    'acétobacter',
    'acétonémies',
    'acétonuries',
    'acétylaient',
    'acétylasses',
    'acétylerais',
    'acétylerait',
    'acétylèrent',
    'acétyleriez',
    'acétylerons',
    'acétyleront',
    'achalandage',
    'achalandais',
    'achalandait',
    'achalandant',
    'achalandées',
    'achalandent',
    'achalandera',
    'achalandiez',
    'achalandons',
    'acharnaient',
    'acharnasses',
    'acharnement',
    'acharnerais',
    'acharnerait',
    'acharnèrent',
    'acharneriez',
    'acharnerons',
    'acharneront',
    'acheminâmes',
    'acheminasse',
    'acheminâtes',
    'acheminerai',
    'achemineras',
    'acheminerez',
    'acheminions',
    'achetassent',
    'achetassiez',
    'achèterions',
    'acheuléenne',
    'achevassent',
    'achevassiez',
    'achèvements',
    'achèverions',
    'achoppement',
    'achopperiez',
    'achopperons',
    'aciculaires',
    'acidifiable',
    'acidifiâmes',
    'acidifiante',
    'acidifiants',
    'acidifiasse',
    'acidifiâtes',
    'acidifierai',
    'acidifieras',
    'acidifierez',
    'acidifiions',
    'acidimètres',
    'acidimétrie',
    'acidophiles',
    'acidulaient',
    'acidulasses',
    'acidulerais',
    'acidulerait',
    'acidulèrent',
    'aciduleriez',
    'acidulerons',
    'aciduleront',
    'aciérassent',
    'aciérassiez',
    'aciérations',
    'aciérerions',
    'aciselaient',
    'aciselasses',
    'acisèlerais',
    'acisèlerait',
    'aciselèrent',
    'acisèleriez',
    'acisèlerons',
    'acisèleront',
    'acoquinâmes',
    'acoquinâtes',
    'acoquinerez',
    'acoquinions',
    'acousticien',
    'acoustiques',
    'acquéraient',
    'acquéresses',
    'acquerrions',
    'acquiesçais',
    'acquiesçait',
    'acquiesçant',
    'acquiescent',
    'acquiescera',
    'acquiesciez',
    'acquiesçons',
    'acquisitifs',
    'acquisition',
    'acquisitive',
    'acquissions',
    'acquittable',
    'acquittâmes',
    'acquittasse',
    'acquittâtes',
    'acquitterai',
    'acquitteras',
    'acquitterez',
    'acquittions',
    'acrimonieux',
    'acrobatique',
    'acrocéphale',
    'acrocyanose',
    'acromégalie',
    'acrophobies',
    'acrostiches',
    'actinologie',
    'actinomètre',
    'actionnable',
    'actionnaire',
    'actionnâmes',
    'actionnasse',
    'actionnâtes',
    'actionnerai',
    'actionneras',
    'actionnerez',
    'actionneurs',
    'actionnions',
    'activassent',
    'activassiez',
    'activateurs',
    'activations',
    'activerions',
    'actualisais',
    'actualisait',
    'actualisant',
    'actualisées',
    'actualisent',
    'actualisera',
    'actualisiez',
    'actualisons',
    'actuarielle',
    'acuponcteur',
    'acuponcture',
    'acupuncteur',
    'acupuncture',
    'adamantines',
    'adaptassent',
    'adaptassiez',
    'adaptateurs',
    'adaptations',
    'adaptatives',
    'adaptatrice',
    'adapterions',
    'additionnai',
    'additionnas',
    'additionnât',
    'additionnée',
    'additionnel',
    'additionner',
    'additionnes',
    'additionnés',
    'additionnez',
    'adénopathie',
    'adéquations',
    'adhérassent',
    'adhérassiez',
    'adhérerions',
    'adhésivités',
    'adiaphorèse',
    'adipopexies',
    'adjectivais',
    'adjectivait',
    'adjectivale',
    'adjectivant',
    'adjectivaux',
    'adjectivées',
    'adjectivent',
    'adjectivera',
    'adjectiviez',
    'adjectivisa',
    'adjectivise',
    'adjectivisé',
    'adjectivons',
    'adjoignîmes',
    'adjoignions',
    'adjoignisse',
    'adjoignîtes',
    'adjoindrais',
    'adjoindrait',
    'adjoindriez',
    'adjoindrons',
    'adjoindront',
    'adjonctions',
    'adjudicatif',
    'adjugeaient',
    'adjugeasses',
    'adjugerions',
    'adjurassent',
    'adjurassiez',
    'adjurations',
    'adjurerions',
    'admettaient',
    'admettrions',
    'adminicules',
    'administrai',
    'administras',
    'administrât',
    'administrée',
    'administrer',
    'administres',
    'administrés',
    'administrez',
    'admirassent',
    'admirassiez',
    'admirateurs',
    'admirations',
    'admiratives',
    'admiratrice',
    'admirerions',
    'admissibles',
    'admittances',
    'admonestais',
    'admonestait',
    'admonestant',
    'admonestées',
    'admonestent',
    'admonestera',
    'admonestiez',
    'admonestons',
    'admonitions',
    'adolescence',
    'adolescente',
    'adolescents',
    'adoniseriez',
    'adoniserons',
    'adonnassiez',
    'adonnerions',
    'adoptassent',
    'adoptassiez',
    'adopterions',
    'adorassions',
    'adoratrices',
    'adoreraient',
    'adossassent',
    'adossassiez',
    'adossements',
    'adosserions',
    'adoubassent',
    'adoubassiez',
    'adouberions',
    'adoucirions',
    'adoucissage',
    'adoucissais',
    'adoucissait',
    'adoucissant',
    'adoucissent',
    'adoucisseur',
    'adoucissiez',
    'adoucissons',
    'adrénalines',
    'adressaient',
    'adressasses',
    'adresserais',
    'adresserait',
    'adressèrent',
    'adresseriez',
    'adresserons',
    'adresseront',
    'adroitement',
    'adsorbantes',
    'adsorptions',
    'adulassions',
    'adulatrices',
    'aduleraient',
    'adultérâmes',
    'adultérasse',
    'adultérâtes',
    'adultérerai',
    'adultéreras',
    'adultérerez',
    'adultérines',
    'adultérions',
    'adventistes',
    'adverbiales',
    'adversaires',
    'adversatifs',
    'adversative',
    'adviendrait',
    'aéronavales',
    'aérophagies',
    'aéroportées',
    'aérospatial',
    'affabilités',
    'affablement',
    'affabulâmes',
    'affabulasse',
    'affabulâtes',
    'affabulerai',
    'affabuleras',
    'affabulerez',
    'affabulions',
    'affacturage',
    'affadirions',
    'affadissais',
    'affadissait',
    'affadissant',
    'affadissent',
    'affadissiez',
    'affadissons',
    'affaiblîmes',
    'affaiblirai',
    'affaibliras',
    'affaiblirez',
    'affaiblisse',
    'affaiblîtes',
    'affairement',
    'affaireriez',
    'affairerons',
    'affairismes',
    'affairistes',
    'affaissâmes',
    'affaissasse',
    'affaissâtes',
    'affaisserai',
    'affaisseras',
    'affaisserez',
    'affaissions',
    'affaitement',
    'affalassent',
    'affalassiez',
    'affalements',
    'affalerions',
    'affamassent',
    'affamassiez',
    'affamerions',
    'afféageâmes',
    'afféageasse',
    'afféageâtes',
    'afféagerais',
    'afféagerait',
    'afféagèrent',
    'afféageriez',
    'afféagerons',
    'afféageront',
    'affectaient',
    'affectasses',
    'affectation',
    'affecterais',
    'affecterait',
    'affectèrent',
    'affecteriez',
    'affecterons',
    'affecteront',
    'affectionna',
    'affectionne',
    'affectionné',
    'affectivité',
    'affectueuse',
    'afférassent',
    'afférassiez',
    'afférerions',
    'affermaient',
    'affermasses',
    'affermerais',
    'affermerait',
    'affermèrent',
    'affermeriez',
    'affermerons',
    'affermeront',
    'affermirais',
    'affermirait',
    'affermirent',
    'affermiriez',
    'affermirons',
    'affermiront',
    'affermisses',
    'affermissez',
    'affichables',
    'affichaient',
    'affichasses',
    'afficherais',
    'afficherait',
    'affichèrent',
    'afficheriez',
    'afficherons',
    'afficheront',
    'affichettes',
    'affichistes',
    'affilassent',
    'affilassiez',
    'affilerions',
    'affiliaient',
    'affiliasses',
    'affiliation',
    'affilierais',
    'affilierait',
    'affilièrent',
    'affilieriez',
    'affilierons',
    'affilieront',
    'affinassent',
    'affinassiez',
    'affinements',
    'affinerions',
    'affirmaient',
    'affirmasses',
    'affirmatifs',
    'affirmation',
    'affirmative',
    'affirmerais',
    'affirmerait',
    'affirmèrent',
    'affirmeriez',
    'affirmerons',
    'affirmeront',
    'affixations',
    'affleurâmes',
    'affleurasse',
    'affleurâtes',
    'affleurerai',
    'affleureras',
    'affleurerez',
    'affleurions',
    'afflictions',
    'afflictives',
    'affligeâmes',
    'affligeante',
    'affligeants',
    'affligeasse',
    'affligeâtes',
    'affligerais',
    'affligerait',
    'affligèrent',
    'affligeriez',
    'affligerons',
    'affligeront',
    'afflouaient',
    'afflouasses',
    'afflouerais',
    'afflouerait',
    'afflouèrent',
    'affloueriez',
    'afflouerons',
    'affloueront',
    'affluassent',
    'affluassiez',
    'affluerions',
    'affolassent',
    'affolassiez',
    'affolements',
    'affolerions',
    'affouageais',
    'affouageait',
    'affouageant',
    'affouageons',
    'affouagerai',
    'affouageras',
    'affouagerez',
    'affouagions',
    'affouillais',
    'affouillait',
    'affouillant',
    'affouillées',
    'affouillent',
    'affouillera',
    'affouilliez',
    'affouillons',
    'affourageai',
    'affourageas',
    'affourageât',
    'affouragées',
    'affouragent',
    'affouragera',
    'affouragiez',
    'affourchais',
    'affourchait',
    'affourchant',
    'affourchées',
    'affourchent',
    'affourchera',
    'affourchiez',
    'affourchons',
    'affourragea',
    'affourragée',
    'affourrager',
    'affourrages',
    'affourragés',
    'affourragez',
    'affranchies',
    'affranchira',
    'affrétaient',
    'affrétasses',
    'affrètement',
    'affréterais',
    'affréterait',
    'affrétèrent',
    'affréteriez',
    'affréterons',
    'affréteront',
    'affriandais',
    'affriandait',
    'affriandant',
    'affriandées',
    'affriandent',
    'affriandera',
    'affriandiez',
    'affriandons',
    'affrichâmes',
    'affrichasse',
    'affrichâtes',
    'affricherai',
    'affricheras',
    'affricherez',
    'affrichions',
    'affriolâmes',
    'affriolante',
    'affriolants',
    'affriolasse',
    'affriolâtes',
    'affriolerai',
    'affrioleras',
    'affriolerez',
    'affriolions',
    'affritaient',
    'affritasses',
    'affriterais',
    'affriterait',
    'affritèrent',
    'affriteriez',
    'affriterons',
    'affriteront',
    'affrontâmes',
    'affrontasse',
    'affrontâtes',
    'affronterai',
    'affronteras',
    'affronterez',
    'affrontions',
    'affruitâmes',
    'affruitasse',
    'affruitâtes',
    'affruiterai',
    'affruiteras',
    'affruiterez',
    'affruitions',
    'affublaient',
    'affublasses',
    'affublement',
    'affublerais',
    'affublerait',
    'affublèrent',
    'affubleriez',
    'affublerons',
    'affubleront',
    'affurassent',
    'affurassiez',
    'affurerions',
    'affûtassent',
    'affûtassiez',
    'affûterions',
    'aficionados',
    'africanisai',
    'africanisas',
    'africanisât',
    'africanisée',
    'africaniser',
    'africanises',
    'africanisés',
    'africanisez',
    'africaniste',
    'afro-cubain',
    'agaçassions',
    'agaceraient',
    'agençassent',
    'agençassiez',
    'agencements',
    'agencerions',
    'agenouillée',
    'agenouiller',
    'agenouillés',
    'agenouillez',
    'agglomérais',
    'agglomérait',
    'agglomérant',
    'agglomérats',
    'agglomérées',
    'agglomèrent',
    'agglomérera',
    'agglomériez',
    'agglomérons',
    'agglutinais',
    'agglutinait',
    'agglutinant',
    'agglutinées',
    'agglutinent',
    'agglutinera',
    'agglutiniez',
    'agglutinons',
    'aggravaient',
    'aggravantes',
    'aggravasses',
    'aggravation',
    'aggraverais',
    'aggraverait',
    'aggravèrent',
    'aggraveriez',
    'aggraverons',
    'aggraveront',
    'agiotassent',
    'agiotassiez',
    'agioterions',
    'agissements',
    'agitassions',
    'agitatrices',
    'agiteraient',
    'agnelassent',
    'agnelassiez',
    'agnellerais',
    'agnellerait',
    'agnelleriez',
    'agnellerons',
    'agnelleront',
    'agnostiques',
    'agoniraient',
    'agonisaient',
    'agonisantes',
    'agonisasses',
    'agoniserais',
    'agoniserait',
    'agonisèrent',
    'agoniseriez',
    'agoniserons',
    'agoniseront',
    'agonissions',
    'agoraphobie',
    'agrafassent',
    'agrafassiez',
    'agraferions',
    'agrandirais',
    'agrandirait',
    'agrandirent',
    'agrandiriez',
    'agrandirons',
    'agrandiront',
    'agrandisses',
    'agrandissez',
    'agrariennes',
    'agréassions',
    'agréeraient',
    'agrégations',
    'agrégatives',
    'agrégeaient',
    'agrégeasses',
    'agrégerions',
    'agrémentais',
    'agrémentait',
    'agrémentant',
    'agrémentées',
    'agrémentent',
    'agrémentera',
    'agrémentiez',
    'agrémentons',
    'agressaient',
    'agressasses',
    'agresserais',
    'agresserait',
    'agressèrent',
    'agresseriez',
    'agresserons',
    'agresseront',
    'agressivité',
    'agrichaient',
    'agrichasses',
    'agricherais',
    'agricherait',
    'agrichèrent',
    'agricheriez',
    'agricherons',
    'agricheront',
    'agriculteur',
    'agriculture',
    'agrifferiez',
    'agrifferons',
    'agrippaient',
    'agrippasses',
    'agripperais',
    'agripperait',
    'agrippèrent',
    'agripperiez',
    'agripperons',
    'agripperont',
    'agrochimies',
    'agronomique',
    'aguardiente',
    'aguerrirais',
    'aguerrirait',
    'aguerrirent',
    'aguerririez',
    'aguerrirons',
    'aguerriront',
    'aguerrisses',
    'aguerrissez',
    'aguichaient',
    'aguichantes',
    'aguichasses',
    'aguicherais',
    'aguicherait',
    'aguichèrent',
    'aguicheriez',
    'aguicherons',
    'aguicheront',
    'aguicheuses',
    'ahanassions',
    'ahaneraient',
    'aheurteriez',
    'aheurterons',
    'ahuriraient',
    'ahurissante',
    'ahurissants',
    'ahurissions',
    'aigre-douce',
    'aigrelettes',
    'aigremoines',
    'aigres-doux',
    'aigriraient',
    'aigrissions',
    'aiguillages',
    'aiguillâmes',
    'aiguillasse',
    'aiguillâtes',
    'aiguillerai',
    'aiguilleras',
    'aiguillerez',
    'aiguilletai',
    'aiguilletas',
    'aiguilletât',
    'aiguilletée',
    'aiguilleter',
    'aiguilletés',
    'aiguilletez',
    'aiguillette',
    'aiguilleurs',
    'aiguilleuse',
    'aiguillions',
    'aiguillonna',
    'aiguillonne',
    'aiguillonné',
    'aiguisaient',
    'aiguisasses',
    'aiguiserais',
    'aiguiserait',
    'aiguisèrent',
    'aiguiseriez',
    'aiguiserons',
    'aiguiseront',
    'aiguiseuses',
    'aillassions',
    'ailleraient',
    'aimablement',
    'aimantaient',
    'aimantasses',
    'aimantation',
    'aimanterais',
    'aimanterait',
    'aimantèrent',
    'aimanteriez',
    'aimanterons',
    'aimanteront',
    'ajointaient',
    'ajointasses',
    'ajointerais',
    'ajointerait',
    'ajointèrent',
    'ajointeriez',
    'ajointerons',
    'ajointeront',
    'ajourassent',
    'ajourassiez',
    'ajourerions',
    'ajournaient',
    'ajournasses',
    'ajournement',
    'ajournerais',
    'ajournerait',
    'ajournèrent',
    'ajourneriez',
    'ajournerons',
    'ajourneront',
    'ajoutassent',
    'ajoutassiez',
    'ajouterions',
    'ajustassent',
    'ajustassiez',
    'ajustements',
    'ajusterions',
    'akkadiennes',
    'alabandines',
    'alabastrite',
    'alambiquais',
    'alambiquait',
    'alambiquant',
    'alambiquées',
    'alambiquent',
    'alambiquera',
    'alambiquiez',
    'alambiquons',
    'alanguirais',
    'alanguirait',
    'alanguirent',
    'alanguiriez',
    'alanguirons',
    'alanguiront',
    'alanguisses',
    'alanguissez',
    'alarmassent',
    'alarmassiez',
    'alarmerions',
    'albigeoises',
    'albumineuse',
    'albuminoïde',
    'albuminurie',
    'alcalescent',
    'alcalifiant',
    'alcalimètre',
    'alcalinisai',
    'alcalinisas',
    'alcalinisât',
    'alcalinisée',
    'alcaliniser',
    'alcalinises',
    'alcalinisés',
    'alcalinisez',
    'alcalinités',
    'alcalisâmes',
    'alcalisasse',
    'alcalisâtes',
    'alcaliserai',
    'alcaliseras',
    'alcaliserez',
    'alcalisions',
    'alchémilles',
    'alchimiques',
    'alchimistes',
    'alcoolature',
    'alcoolémies',
    'alcooliques',
    'alcoolisais',
    'alcoolisait',
    'alcoolisant',
    'alcoolisées',
    'alcoolisent',
    'alcoolisera',
    'alcoolisiez',
    'alcoolismes',
    'alcoolisons',
    'alcoomètres',
    'aldéhydique',
    'aldostérone',
    'alémaniques',
    'alentirions',
    'alentissais',
    'alentissait',
    'alentissant',
    'alentissent',
    'alentissiez',
    'alentissons',
    'alertassent',
    'alertassiez',
    'alerterions',
    'alésassions',
    'aléseraient',
    'alevinaient',
    'alevinasses',
    'alevinerais',
    'alevinerait',
    'alevinèrent',
    'alevineriez',
    'alevinerons',
    'alevineront',
    'alevinières',
    'alexandrine',
    'alexandrins',
    'algébriques',
    'algériennes',
    'algorithmes',
    'aliénassent',
    'aliénassiez',
    'aliénataire',
    'aliénateurs',
    'aliénations',
    'aliénatrice',
    'aliénerions',
    'alignassent',
    'alignassiez',
    'alignements',
    'alignerions',
    'alimentaire',
    'alimentâmes',
    'alimentasse',
    'alimentâtes',
    'alimenterai',
    'alimenteras',
    'alimenterez',
    'alimentions',
    'aliphatique',
    'alitassions',
    'aliteraient',
    'allaitaient',
    'allaitasses',
    'allaitement',
    'allaiterais',
    'allaiterait',
    'allaitèrent',
    'allaiteriez',
    'allaiterons',
    'allaiteront',
    'allantoïdes',
    'alléchaient',
    'alléchantes',
    'alléchasses',
    'allèchement',
    'allécherais',
    'allécherait',
    'alléchèrent',
    'allécheriez',
    'allécherons',
    'allécheront',
    'allégations',
    'allégeaient',
    'allégeances',
    'allégeasses',
    'allégements',
    'allègements',
    'allégerions',
    'allégirions',
    'allégissais',
    'allégissait',
    'allégissant',
    'allégissent',
    'allégissiez',
    'allégissons',
    'allégorique',
    'allégorisai',
    'allégorisas',
    'allégorisât',
    'allégorisée',
    'allégoriser',
    'allégorises',
    'allégorisés',
    'allégorisez',
    'allégrement',
    'allègrement',
    'allégresses',
    'allegrettos',
    'alléguaient',
    'alléguasses',
    'alléguerais',
    'alléguerait',
    'alléguèrent',
    'allégueriez',
    'alléguerons',
    'allégueront',
    'allergiques',
    'allergisant',
    'alliassions',
    'allieraient',
    'allocataire',
    'allocations',
    'allocutaire',
    'allocutions',
    'allongeâmes',
    'allongeasse',
    'allongeâtes',
    'allongement',
    'allongerais',
    'allongerait',
    'allongèrent',
    'allongeriez',
    'allongerons',
    'allongeront',
    'allopathies',
    'allotropies',
    'allouassent',
    'allouassiez',
    'allouerions',
    'allumassent',
    'allumassiez',
    'allumerions',
    'allumettier',
    'alluvionnai',
    'alluvionnas',
    'alluvionnât',
    'alluvionner',
    'alluvionnes',
    'alluvionnez',
    'almasiliums',
    'alourdirais',
    'alourdirait',
    'alourdirent',
    'alourdiriez',
    'alourdirons',
    'alourdiront',
    'alourdisses',
    'alourdissez',
    'alpaguaient',
    'alpaguasses',
    'alpaguerais',
    'alpaguerait',
    'alpaguèrent',
    'alpagueriez',
    'alpaguerons',
    'alpagueront',
    'alphabétisa',
    'alphabétise',
    'alphabétisé',
    'alsaciennes',
    'altéragènes',
    'altérassent',
    'altérassiez',
    'altérations',
    'altercation',
    'altérerions',
    'alternaient',
    'alternances',
    'alternantes',
    'alternasses',
    'alternateur',
    'alternatifs',
    'alternative',
    'alternerais',
    'alternerait',
    'alternèrent',
    'alterneriez',
    'alternerons',
    'alterneront',
    'altimétries',
    'altocumulus',
    'aluminaient',
    'aluminasses',
    'aluminerais',
    'aluminerait',
    'aluminèrent',
    'alumineriez',
    'aluminerons',
    'alumineront',
    'alumineuses',
    'aluminiages',
    'alunassions',
    'aluneraient',
    'aluniraient',
    'alunissages',
    'alunissions',
    'alvéolaires',
    'amadouaient',
    'amadouasses',
    'amadouerais',
    'amadouerait',
    'amadouèrent',
    'amadoueriez',
    'amadouerons',
    'amadoueront',
    'amadouviers',
    'amaigrirais',
    'amaigrirait',
    'amaigrirent',
    'amaigririez',
    'amaigrirons',
    'amaigriront',
    'amaigrisses',
    'amaigrissez',
    'amalgamâmes',
    'amalgamasse',
    'amalgamâtes',
    'amalgamerai',
    'amalgameras',
    'amalgamerez',
    'amalgamions',
    'amareyeuses',
    'amarinaient',
    'amarinasses',
    'amarinerais',
    'amarinerait',
    'amarinèrent',
    'amarineriez',
    'amarinerons',
    'amarineront',
    'amarrassent',
    'amarrassiez',
    'amarrerions',
    'amassassent',
    'amassassiez',
    'amasserions',
    'amateurisme',
    'amatiraient',
    'amatissions',
    'ambassadeur',
    'ambidextres',
    'ambisexuées',
    'ambitieuses',
    'ambitionnai',
    'ambitionnas',
    'ambitionnât',
    'ambitionnée',
    'ambitionner',
    'ambitionnes',
    'ambitionnés',
    'ambitionnez',
    'ambivalence',
    'ambivalente',
    'ambivalents',
    'amblassions',
    'ambleraient',
    'amblyoscope',
    'amblystomes',
    'ambrassions',
    'ambreraient',
    'ambrosienne',
    'ambulancier',
    'ambulatoire',
    'améliorable',
    'améliorâmes',
    'améliorante',
    'améliorants',
    'améliorasse',
    'améliorâtes',
    'améliorerai',
    'amélioreras',
    'améliorerez',
    'améliorions',
    'aménageable',
    'aménageâmes',
    'aménageasse',
    'aménageâtes',
    'aménagement',
    'aménagerais',
    'aménagerait',
    'aménagèrent',
    'aménageriez',
    'aménagerons',
    'aménageront',
    'aménageuses',
    'amenassions',
    'amendassent',
    'amendassiez',
    'amendements',
    'amenderions',
    'amèneraient',
    'aménorrhées',
    'amentifères',
    'amenuisâmes',
    'amenuisasse',
    'amenuisâtes',
    'amenuiserai',
    'amenuiseras',
    'amenuiserez',
    'amenuisions',
    'américaines',
    'américanisa',
    'américanise',
    'américanisé',
    'amérindiens',
    'amerririons',
    'amerrissage',
    'amerrissais',
    'amerrissait',
    'amerrissant',
    'amerrissent',
    'amerrissiez',
    'amerrissons',
    'ameublement',
    'ameublirais',
    'ameublirait',
    'ameublirent',
    'ameubliriez',
    'ameublirons',
    'ameubliront',
    'ameublisses',
    'ameublissez',
    'ameutassent',
    'ameutassiez',
    'ameuterions',
    'amicalement',
    'amidonnages',
    'amidonnâmes',
    'amidonnasse',
    'amidonnâtes',
    'amidonnerai',
    'amidonneras',
    'amidonnerez',
    'amidonnerie',
    'amidonnière',
    'amidonniers',
    'amidonnions',
    'amidopyrine',
    'amincirions',
    'amincissais',
    'amincissait',
    'amincissant',
    'amincissent',
    'amincissiez',
    'amincissons',
    'aminoplaste',
    'ammoniacale',
    'ammoniacaux',
    'ammoniaques',
    'amnioscopie',
    'amniotiques',
    'amnistiable',
    'amnistiâmes',
    'amnistiante',
    'amnistiants',
    'amnistiasse',
    'amnistiâtes',
    'amnistierai',
    'amnistieras',
    'amnistierez',
    'amnistiions',
    'amochassent',
    'amochassiez',
    'amocherions',
    'amodiassent',
    'amodiassiez',
    'amodiations',
    'amodierions',
    'amoindrîmes',
    'amoindrirai',
    'amoindriras',
    'amoindrirez',
    'amoindrisse',
    'amoindrîtes',
    'amollirions',
    'amollissais',
    'amollissait',
    'amollissant',
    'amollissent',
    'amollissiez',
    'amollissons',
    'amoncelâmes',
    'amoncelasse',
    'amoncelâtes',
    'amoncelions',
    'amoncellent',
    'amoncellera',
    'amonibilité',
    'amoralismes',
    'amorçassent',
    'amorçassiez',
    'amorcerions',
    'amordançais',
    'amordançait',
    'amordançant',
    'amordancées',
    'amordancent',
    'amordancera',
    'amordanciez',
    'amordançons',
    'amortirions',
    'amortissent',
    'amortisseur',
    'amortissiez',
    'amotissions',
    'amourachées',
    'amourachiez',
    'amourachons',
    'ampèremètre',
    'amphétamine',
    'amphictyons',
    'amphigouris',
    'amphimixies',
    'amphisbènes',
    'amphitryons',
    'amplectives',
    'ampliations',
    'ampliatives',
    'amplifiâmes',
    'amplifiante',
    'amplifiasse',
    'amplifiâtes',
    'amplifierai',
    'amplifieras',
    'amplifierez',
    'amplifiions',
    'ampli-tuner',
    'amputassent',
    'amputassiez',
    'amputations',
    'amputerions',
    'amuïssement',
    'amurassions',
    'amureraient',
    'amusassions',
    'amuseraient',
    'amygdalites',
    'amylobacter',
    'amyotrophie',
    'anabaptisme',
    'anabaptiste',
    'anabolisant',
    'anabolismes',
    'anacardiers',
    'anachorètes',
    'anacoluthes',
    'anagogiques',
    'analeptique',
    'analgésiais',
    'analgésiait',
    'analgésiant',
    'analgésiées',
    'analgésient',
    'analgésiera',
    'analgésiiez',
    'analgésions',
    'analgésique',
    'analogiques',
    'analphabète',
    'analysables',
    'analysaient',
    'analysantes',
    'analysasses',
    'analyserais',
    'analyserait',
    'analysèrent',
    'analyseriez',
    'analyserons',
    'analyseront',
    'analytiques',
    'anamorphose',
    'anaphorèses',
    'anaphorique',
    'anaphylaxie',
    'anaplasties',
    'anarchiques',
    'anarchisant',
    'anarchismes',
    'anarchistes',
    'anastigmats',
    'anastomosée',
    'anastomoser',
    'anastomoses',
    'anastomosés',
    'anastomosez',
    'anastrophes',
    'anatocismes',
    'anatomiques',
    'anatomisais',
    'anatomisait',
    'anatomisant',
    'anatomisées',
    'anatomisent',
    'anatomisera',
    'anatomisiez',
    'anatomisons',
    'anatomistes',
    'ancestrales',
    'anciennetés',
    'ancillaires',
    'ancrassions',
    'ancreraient',
    'andouillers',
    'andrinoples',
    'androgenèse',
    'androgénies',
    'andrologies',
    'andrologues',
    'andropauses',
    'anéantirais',
    'anéantirait',
    'anéantirent',
    'anéantiriez',
    'anéantirons',
    'anéantiront',
    'anéantisses',
    'anéantissez',
    'anecdotière',
    'anecdotiers',
    'anecdotique',
    'anémiassent',
    'anémiassiez',
    'anémierions',
    'anémographe',
    'anémomètres',
    'anémophiles',
    'anesthésiai',
    'anesthésias',
    'anesthésiât',
    'anesthésiée',
    'anesthésier',
    'anesthésies',
    'anesthésiés',
    'anesthésiez',
    'anévrismale',
    'anévrismaux',
    'angiectasie',
    'angiologies',
    'angiosperme',
    'anglaisâmes',
    'anglaisasse',
    'anglaisâtes',
    'anglaiserai',
    'anglaiseras',
    'anglaiserez',
    'anglaisions',
    'angledozers',
    'anglicisais',
    'anglicisait',
    'anglicisant',
    'anglicisées',
    'anglicisent',
    'anglicisera',
    'anglicisiez',
    'anglicismes',
    'anglicisons',
    'anglicistes',
    'anglo-arabe',
    'anglomanies',
    'anglophiles',
    'anglophilie',
    'anglophobes',
    'anglophobie',
    'anglophones',
    'anglo-saxon',
    'angoissâmes',
    'angoissante',
    'angoissants',
    'angoissasse',
    'angoissâtes',
    'angoisserai',
    'angoisseras',
    'angoisserez',
    'angoissions',
    'anguiformes',
    'anguillères',
    'anguillules',
    'anhélassent',
    'anhélassiez',
    'anhélations',
    'anhélerions',
    'animalcules',
    'animaleries',
    'animalières',
    'animalisais',
    'animalisait',
    'animalisant',
    'animalisées',
    'animalisent',
    'animalisera',
    'animalisiez',
    'animalisons',
    'animassions',
    'animatrices',
    'animeraient',
    'anisassions',
    'aniseraient',
    'anisotropes',
    'anisotropie',
    'ankylosâmes',
    'ankylosasse',
    'ankylosâtes',
    'ankyloserai',
    'ankyloseras',
    'ankyloserez',
    'ankylosions',
    'ankylostome',
    'annelassent',
    'annelassiez',
    'annellerais',
    'annellerait',
    'annelleriez',
    'annellerons',
    'annelleront',
    'annexassent',
    'annexassiez',
    'annexerions',
    'annihilâmes',
    'annihilasse',
    'annihilâtes',
    'annihilerai',
    'annihileras',
    'annihilerez',
    'annihilions',
    'annonçaient',
    'annonçasses',
    'annoncerais',
    'annoncerait',
    'annoncèrent',
    'annonceriez',
    'annoncerons',
    'annonceront',
    'annonceuses',
    'annoncières',
    'annotassent',
    'annotassiez',
    'annotateurs',
    'annotations',
    'annotatrice',
    'annoterions',
    'annulassent',
    'annulassiez',
    'annulations',
    'annulerions',
    'anoblirions',
    'anoblissais',
    'anoblissait',
    'anoblissant',
    'anoblissent',
    'anoblissiez',
    'anoblissons',
    'anodisaient',
    'anodisasses',
    'anodiserais',
    'anodiserait',
    'anodisèrent',
    'anodiseriez',
    'anodiserons',
    'anodiseront',
    'ânonnassent',
    'ânonnassiez',
    'ânonnements',
    'ânonnerions',
    'anonymement',
    'anordirions',
    'anordissais',
    'anordissait',
    'anordissant',
    'anordissent',
    'anordissiez',
    'anordissons',
    'anorexiques',
    'anormalités',
    'antagonique',
    'antagonisme',
    'antagoniste',
    'antalgiques',
    'antarctique',
    'antécédence',
    'antécédente',
    'antécédents',
    'Antéchrists',
    'antéposâmes',
    'antéposasse',
    'antéposâtes',
    'antéposerai',
    'antéposeras',
    'antéposerez',
    'antéposions',
    'antérieures',
    'antériorité',
    'antérograde',
    'anthologies',
    'anthracènes',
    'anthracites',
    'anthracnose',
    'anthracoses',
    'anthropoïde',
    'antiaériens',
    'antiamarile',
    'antiamarils',
    'anticasseur',
    'anticathode',
    'antichambre',
    'antichrèses',
    'anticipâmes',
    'anticipasse',
    'anticipâtes',
    'anticiperai',
    'anticiperas',
    'anticiperez',
    'anticipions',
    'anticlinale',
    'anticlinaux',
    'anticyclone',
    'antidatâmes',
    'antidatasse',
    'antidatâtes',
    'antidaterai',
    'antidateras',
    'antidaterez',
    'antidations',
    'antidopages',
    'antidopings',
    'antiglisses',
    'antihausses',
    'antillaises',
    'antimatière',
    'antimissile',
    'antimoniure',
    'antinomique',
    'antipathies',
    'antiphrases',
    'antipoisons',
    'antiquaille',
    'antiquaires',
    'antirabique',
    'antiracisme',
    'antiraciste',
    'antirouille',
    'antisémites',
    'antisepsies',
    'antisociale',
    'antisociaux',
    'antisportif',
    'antistrophe',
    'antitoxines',
    'antitoxique',
    'aoûtassions',
    'aoûteraient',
    'apaisassent',
    'apaisassiez',
    'apaisements',
    'apaiserions',
    'apanageâmes',
    'apanageasse',
    'apanageâtes',
    'apanagerais',
    'apanagerait',
    'apanagèrent',
    'apanageriez',
    'apanagerons',
    'apanageront',
    'aperception',
    'apercevions',
    'apercevrais',
    'apercevrait',
    'apercevriez',
    'apercevrons',
    'apercevront',
    'aperçoivent',
    'aperçussent',
    'aperçussiez',
    'apesanteurs',
    'apeurassent',
    'apeurassiez',
    'apeurerions',
    'apiculteurs',
    'apicultrice',
    'apicultures',
    'apiquassent',
    'apiquassiez',
    'apiquerions',
    'apitoiement',
    'apitoierais',
    'apitoierait',
    'apitoieriez',
    'apitoierons',
    'apitoieront',
    'apitoyaient',
    'apitoyasses',
    'apitoyèrent',
    'aplanétique',
    'aplanirions',
    'aplanissais',
    'aplanissait',
    'aplanissant',
    'aplanissent',
    'aplanissiez',
    'aplanissons',
    'aplatirions',
    'aplatissage',
    'aplatissais',
    'aplatissait',
    'aplatissant',
    'aplatissent',
    'aplatisseur',
    'aplatissiez',
    'aplatissoir',
    'aplatissons',
    'apocalypses',
    'apodictique',
    'apolitiques',
    'apolitismes',
    'apolliniens',
    'apologistes',
    'apomorphine',
    'aponévroses',
    'apophtegmes',
    'apophysaire',
    'aporétiques',
    'aposiopèses',
    'apostasiais',
    'apostasiait',
    'apostasiant',
    'apostasient',
    'apostasiera',
    'apostasiiez',
    'apostasions',
    'apostassent',
    'apostassiez',
    'aposterions',
    'apostillais',
    'apostillait',
    'apostillant',
    'apostillées',
    'apostillent',
    'apostillera',
    'apostilliez',
    'apostillons',
    'apostolique',
    'apostrophai',
    'apostrophas',
    'apostrophât',
    'apostrophée',
    'apostropher',
    'apostrophes',
    'apostrophés',
    'apostrophez',
    'apothicaire',
    'appairaient',
    'appairasses',
    'appairerais',
    'appairerait',
    'appairèrent',
    'appaireriez',
    'appairerons',
    'appaireront',
    'appalachien',
    'apparaisses',
    'apparaissez',
    'apparaîtrai',
    'apparaîtras',
    'apparaîtrez',
    'appareillai',
    'appareillas',
    'appareillât',
    'appareillée',
    'appareiller',
    'appareilles',
    'appareillés',
    'appareillez',
    'apparemment',
    'apparentées',
    'apparentiez',
    'apparentons',
    'appariaient',
    'appariasses',
    'appariement',
    'apparierais',
    'apparierait',
    'apparièrent',
    'apparieriez',
    'apparierons',
    'apparieront',
    'appariteurs',
    'apparitions',
    'appartement',
    'appartenais',
    'appartenait',
    'appartenant',
    'apparteniez',
    'appartenons',
    'appartienne',
    'appartînmes',
    'appartinsse',
    'appartîntes',
    'apparussent',
    'apparussiez',
    'appâtassent',
    'appâtassiez',
    'appâterions',
    'appauvrîmes',
    'appauvrirai',
    'appauvriras',
    'appauvrirez',
    'appauvrisse',
    'appauvrîtes',
    'appelassent',
    'appelassiez',
    'appellatifs',
    'appellation',
    'appellative',
    'appellerais',
    'appellerait',
    'appelleriez',
    'appellerons',
    'appelleront',
    'appendicite',
    'appesanties',
    'appesantira',
    'appétassent',
    'appétassiez',
    'appéterions',
    'appétissant',
    'applaudîmes',
    'applaudirai',
    'applaudiras',
    'applaudirez',
    'applaudisse',
    'applaudîtes',
    'applicables',
    'applicateur',
    'applicatifs',
    'application',
    'appliquâmes',
    'appliquasse',
    'appliquâtes',
    'appliquerai',
    'appliqueras',
    'appliquerez',
    'appliquions',
    'appointages',
    'appointâmes',
    'appointasse',
    'appointâtes',
    'appointerai',
    'appointeras',
    'appointerez',
    'appointîmes',
    'appointions',
    'appointirai',
    'appointiras',
    'appointirez',
    'appointisse',
    'appointîtes',
    'appontaient',
    'appontasses',
    'appontement',
    'apponterais',
    'apponterait',
    'appontèrent',
    'apponteriez',
    'apponterons',
    'apponteront',
    'apportaient',
    'apportasses',
    'apporterais',
    'apporterait',
    'apportèrent',
    'apporteriez',
    'apporterons',
    'apporteront',
    'apposassent',
    'apposassiez',
    'apposerions',
    'appositions',
    'appréciable',
    'appréciâmes',
    'appréciasse',
    'appréciâtes',
    'appréciatif',
    'apprécierai',
    'apprécieras',
    'apprécierez',
    'appréciions',
    'appréhendai',
    'appréhendas',
    'appréhendât',
    'appréhendée',
    'appréhender',
    'appréhendes',
    'appréhendés',
    'appréhendez',
    'appréhensif',
    'apprenaient',
    'apprenantes',
    'apprendrais',
    'apprendrait',
    'apprendriez',
    'apprendrons',
    'apprendront',
    'apprêtaient',
    'apprêtasses',
    'apprêterais',
    'apprêterait',
    'apprêtèrent',
    'apprêteriez',
    'apprêterons',
    'apprêteront',
    'apprêteuses',
    'apprissions',
    'apprivoisai',
    'apprivoisas',
    'apprivoisât',
    'apprivoisée',
    'apprivoiser',
    'apprivoises',
    'apprivoisés',
    'apprivoisez',
    'approbateur',
    'approbatifs',
    'approbation',
    'approbative',
    'approchable',
    'approchâmes',
    'approchante',
    'approchants',
    'approchasse',
    'approchâtes',
    'approcherai',
    'approcheras',
    'approcherez',
    'approchions',
    'approfondie',
    'approfondir',
    'approfondis',
    'approfondit',
    'approfondît',
    'appropriais',
    'appropriait',
    'appropriant',
    'appropriées',
    'approprient',
    'appropriera',
    'appropriiez',
    'approprions',
    'approuvable',
    'approuvâmes',
    'approuvasse',
    'approuvâtes',
    'approuverai',
    'approuveras',
    'approuverez',
    'approuvions',
    'appuie-bras',
    'appuie-main',
    'appuierions',
    'appuie-tête',
    'appuis-bras',
    'appuis-main',
    'appuis-tête',
    'appuyassent',
    'appuyassiez',
    'après-dîner',
    'après-vente',
    'aprioriques',
    'apriorismes',
    'aprioristes',
    'apurassions',
    'apureraient',
    'aquaculture',
    'aquiculteur',
    'aquiculture',
    'arabisaient',
    'arabisantes',
    'arabisasses',
    'arabisation',
    'arabiserais',
    'arabiserait',
    'arabisèrent',
    'arabiseriez',
    'arabiserons',
    'arabiseront',
    'arabophones',
    'arachnéenne',
    'arachnoïdes',
    'arasassions',
    'araseraient',
    'arbalétrier',
    'arbitraient',
    'arbitraires',
    'arbitrasses',
    'arbitrerais',
    'arbitrerait',
    'arbitrèrent',
    'arbitreriez',
    'arbitrerons',
    'arbitreront',
    'arborassent',
    'arborassiez',
    'arborerions',
    'arborescent',
    'arboricoles',
    'arborisâmes',
    'arborisasse',
    'arborisâtes',
    'arboriserai',
    'arboriseras',
    'arboriserez',
    'arborisions',
    'arbrisseaux',
    'arc-boutais',
    'arc-boutait',
    'arc-boutant',
    'arc-boutées',
    'arc-boutent',
    'arc-boutera',
    'arc-boutiez',
    'arc-boutons',
    'arc-en-ciel',
    'archaïsâmes',
    'archaïsante',
    'archaïsants',
    'archaïsasse',
    'archaïsâtes',
    'archaïserai',
    'archaïseras',
    'archaïserez',
    'archaïsions',
    'archéologie',
    'archéologue',
    'archevêchés',
    'archevêques',
    'archidiacre',
    'archiprêtre',
    'architectes',
    'architraves',
    'archivaient',
    'archivasses',
    'archiverais',
    'archiverait',
    'archivèrent',
    'archiveriez',
    'archiverons',
    'archiveront',
    'archivistes',
    'archivoltes',
    'arçonnaient',
    'arçonnasses',
    'arçonnerais',
    'arçonnerait',
    'arçonnèrent',
    'arçonneriez',
    'arçonnerons',
    'arçonneront',
    'ardoisaient',
    'ardoisasses',
    'ardoiserais',
    'ardoiserait',
    'ardoisèrent',
    'ardoiseriez',
    'ardoiserons',
    'ardoiseront',
    'ardoisières',
    'areligieuse',
    'aréométries',
    'argentaient',
    'argentasses',
    'argenterais',
    'argenterait',
    'argentèrent',
    'argenteries',
    'argenteriez',
    'argenterons',
    'argenteront',
    'argentifère',
    'argentiques',
    'argotisâmes',
    'argotisasse',
    'argotisâtes',
    'argotiserai',
    'argotiseras',
    'argotiserez',
    'argotisions',
    'arguassions',
    'argueraient',
    'argumentais',
    'argumentait',
    'argumentant',
    'argumentent',
    'argumentera',
    'argumentiez',
    'argumentons',
    'argyronètes',
    'arisassions',
    'ariseraient',
    'aristocrate',
    'aristoloche',
    'arlequinade',
    'arméniennes',
    'armillaires',
    'arminiennes',
    'armoriaient',
    'armoriasses',
    'armorierais',
    'armorierait',
    'armorièrent',
    'armorieriez',
    'armorierons',
    'armorieront',
    'arnaquaient',
    'arnaquasses',
    'arnaquerais',
    'arnaquerait',
    'arnaquèrent',
    'arnaqueriez',
    'arnaquerons',
    'arnaqueront',
    'aromatiques',
    'aromatisais',
    'aromatisait',
    'aromatisant',
    'aromatisées',
    'aromatisent',
    'aromatisera',
    'aromatisiez',
    'aromatisons',
    'arpégeaient',
    'arpégeasses',
    'arpégerions',
    'arpentaient',
    'arpentasses',
    'arpenterais',
    'arpenterait',
    'arpentèrent',
    'arpenteriez',
    'arpenterons',
    'arpenteront',
    'arpenteuses',
    'arquassions',
    'arquebusade',
    'arquebusier',
    'arqueraient',
    'arrachaient',
    'arrachasses',
    'arrachement',
    'arracherais',
    'arracherait',
    'arrachèrent',
    'arracheriez',
    'arracherons',
    'arracheront',
    'arracheuses',
    'arraisonnai',
    'arraisonnas',
    'arraisonnât',
    'arraisonnée',
    'arraisonner',
    'arraisonnes',
    'arraisonnés',
    'arraisonnez',
    'arrangeable',
    'arrangeâmes',
    'arrangeante',
    'arrangeants',
    'arrangeasse',
    'arrangeâtes',
    'arrangement',
    'arrangerais',
    'arrangerait',
    'arrangèrent',
    'arrangeriez',
    'arrangerons',
    'arrangeront',
    'arrentaient',
    'arrentasses',
    'arrenterais',
    'arrenterait',
    'arrentèrent',
    'arrenteriez',
    'arrenterons',
    'arrenteront',
    'arrérageais',
    'arrérageait',
    'arrérageant',
    'arrérageons',
    'arréragerai',
    'arrérageras',
    'arréragerez',
    'arréragions',
    'arrestation',
    'arrêtassent',
    'arrêtassiez',
    'arrêterions',
    'arriéraient',
    'arriérasses',
    'arriération',
    'arrière-ban',
    'arrière-bec',
    'arriérerais',
    'arriérerait',
    'arriérèrent',
    'arriéreriez',
    'arriérerons',
    'arriéreront',
    'arrimassent',
    'arrimassiez',
    'arrimerions',
    'arrisassent',
    'arrisassiez',
    'arriserions',
    'arrivassent',
    'arrivassiez',
    'arriverions',
    'arrogamment',
    'arrogerions',
    'arrondirais',
    'arrondirait',
    'arrondirent',
    'arrondiriez',
    'arrondirons',
    'arrondiront',
    'arrondisses',
    'arrondissez',
    'arrosassent',
    'arrosassiez',
    'arrosements',
    'arroserions',
    'arsenicales',
    'arsouillées',
    'arsouilliez',
    'arsouillons',
    'artérielles',
    'artéritique',
    'artésiennes',
    'arthralgies',
    'arthritique',
    'arthritisme',
    'articulaire',
    'articulâmes',
    'articulasse',
    'articulâtes',
    'articulerai',
    'articuleras',
    'articulerez',
    'articulions',
    'artificiels',
    'artificiers',
    'artificieux',
    'artilleries',
    'artisanales',
    'artistement',
    'artistiques',
    'aryténoïdes',
    'ascaridiase',
    'ascaridiose',
    'ascendances',
    'ascendantes',
    'ascensionna',
    'ascensionne',
    'ascensionné',
    'asclépiades',
    'aseptisâmes',
    'aseptisasse',
    'aseptisâtes',
    'aseptiserai',
    'aseptiseras',
    'aseptiserez',
    'aseptisions',
    'asexualités',
    'asparagines',
    'aspectaient',
    'aspectasses',
    'aspecterais',
    'aspecterait',
    'aspectèrent',
    'aspecteriez',
    'aspecterons',
    'aspecteront',
    'aspergeâmes',
    'aspergeasse',
    'aspergeâtes',
    'aspergerais',
    'aspergerait',
    'aspergèrent',
    'aspergeriez',
    'aspergerons',
    'aspergeront',
    'aspergilles',
    'asphaltages',
    'asphaltâmes',
    'asphaltasse',
    'asphaltâtes',
    'asphalterai',
    'asphalteras',
    'asphalterez',
    'asphaltions',
    'asphyxiâmes',
    'asphyxiante',
    'asphyxiants',
    'asphyxiasse',
    'asphyxiâtes',
    'asphyxierai',
    'asphyxieras',
    'asphyxierez',
    'asphyxiions',
    'aspidistras',
    'aspirassent',
    'aspirassiez',
    'aspirateurs',
    'aspirations',
    'aspiratoire',
    'aspirerions',
    'assagirions',
    'assagissais',
    'assagissait',
    'assagissant',
    'assagissent',
    'assagissiez',
    'assagissons',
    'assaillante',
    'assaillants',
    'assaillîmes',
    'assaillions',
    'assaillirai',
    'assailliras',
    'assaillirez',
    'assaillisse',
    'assaillîtes',
    'assainirais',
    'assainirait',
    'assainirent',
    'assainiriez',
    'assainirons',
    'assainiront',
    'assainisses',
    'assainissez',
    'assaisonnai',
    'assaisonnas',
    'assaisonnât',
    'assaisonnée',
    'assaisonner',
    'assaisonnes',
    'assaisonnés',
    'assaisonnez',
    'assarmentai',
    'assarmentas',
    'assarmentât',
    'assarmentée',
    'assarmenter',
    'assarmentes',
    'assarmentés',
    'assarmentez',
    'assassinais',
    'assassinait',
    'assassinant',
    'assassinats',
    'assassinées',
    'assassinent',
    'assassinera',
    'assassiniez',
    'assassinons',
    'asséchaient',
    'asséchasses',
    'assèchement',
    'assécherais',
    'assécherait',
    'asséchèrent',
    'assécheriez',
    'assécherons',
    'assécheront',
    'assemblages',
    'assemblâmes',
    'assemblasse',
    'assemblâtes',
    'assemblerai',
    'assembleras',
    'assemblerez',
    'assembleurs',
    'assembleuse',
    'assemblions',
    'assenassent',
    'assénassent',
    'assenassiez',
    'assénassiez',
    'assénerions',
    'assènerions',
    'assentiment',
    'assermentai',
    'assermentas',
    'assermentât',
    'assermentée',
    'assermenter',
    'assermentes',
    'assermentés',
    'assermentez',
    'asservirais',
    'asservirait',
    'asservirent',
    'asserviriez',
    'asservirons',
    'asserviront',
    'asservisses',
    'asservissez',
    'assibilâmes',
    'assibilasse',
    'assibilâtes',
    'assibilerai',
    'assibileras',
    'assibilerez',
    'assibilions',
    'assiégeâmes',
    'assiégeante',
    'assiégeants',
    'assiégeasse',
    'assiégeâtes',
    'assiégerais',
    'assiégerait',
    'assiégèrent',
    'assiégeriez',
    'assiégerons',
    'assiégeront',
    'assiéraient',
    'assignables',
    'assignaient',
    'assignasses',
    'assignation',
    'assignerais',
    'assignerait',
    'assignèrent',
    'assigneriez',
    'assignerons',
    'assigneront',
    'assimilable',
    'assimilâmes',
    'assimilasse',
    'assimilâtes',
    'assimilerai',
    'assimileras',
    'assimilerez',
    'assimilions',
    'assistaient',
    'assistanats',
    'assistances',
    'assistantes',
    'assistasses',
    'assisterais',
    'assisterait',
    'assistèrent',
    'assisteriez',
    'assisterons',
    'assisteront',
    'associaient',
    'associasses',
    'associatifs',
    'association',
    'associative',
    'associerais',
    'associerait',
    'associèrent',
    'associeriez',
    'associerons',
    'associeront',
    'assoiraient',
    'assolassent',
    'assolassiez',
    'assolements',
    'assolerions',
    'assombrîmes',
    'assombrirai',
    'assombriras',
    'assombrirez',
    'assombrisse',
    'assombrîtes',
    'assommaient',
    'assommantes',
    'assommasses',
    'assommerais',
    'assommerait',
    'assommèrent',
    'assommeriez',
    'assommerons',
    'assommeront',
    'assomptions',
    'assonancées',
    'assonassent',
    'assonassiez',
    'assonerions',
    'assortiment',
    'assortirais',
    'assortirait',
    'assortirent',
    'assortiriez',
    'assortirons',
    'assortiront',
    'assortisses',
    'assortissez',
    'assoupirais',
    'assoupirait',
    'assoupirent',
    'assoupiriez',
    'assoupirons',
    'assoupiront',
    'assoupisses',
    'assoupissez',
    'assouplîmes',
    'assouplirai',
    'assoupliras',
    'assouplirez',
    'assouplisse',
    'assouplîtes',
    'assourdîmes',
    'assourdirai',
    'assourdiras',
    'assourdirez',
    'assourdisse',
    'assourdîtes',
    'assouvirais',
    'assouvirait',
    'assouvirent',
    'assouviriez',
    'assouvirons',
    'assouviront',
    'assouvisses',
    'assouvissez',
    'assujetties',
    'assujettira',
    'assumassent',
    'assumassiez',
    'assumerions',
    'assurassent',
    'assurassiez',
    'assurerions',
    'assyriennes',
    'astérisques',
    'asthéniques',
    'asthmatique',
    'asticotâmes',
    'asticotasse',
    'asticotâtes',
    'asticoterai',
    'asticoteras',
    'asticoterez',
    'asticotions',
    'astiquaient',
    'astiquasses',
    'astiquerais',
    'astiquerait',
    'astiquèrent',
    'astiqueriez',
    'astiquerons',
    'astiqueront',
    'astreignais',
    'astreignait',
    'astreignant',
    'astreignent',
    'astreigniez',
    'astreignons',
    'astreindrai',
    'astreindras',
    'astreindrez',
    'astringence',
    'astringente',
    'astringents',
    'astrolâtrie',
    'astrologies',
    'astrologues',
    'astrométrie',
    'astronautes',
    'astronomies',
    'astucieuses',
    'asymétrique',
    'asynchrones',
    'ataraxiques',
    'atemporelle',
    'atermoierai',
    'atermoieras',
    'atermoierez',
    'atermoyâmes',
    'atermoyasse',
    'atermoyâtes',
    'atermoyions',
    'athématique',
    'athermiques',
    'athlétiques',
    'athlétismes',
    'atlantiques',
    'atlantismes',
    'atmosphères',
    'atomisaient',
    'atomisasses',
    'atomisation',
    'atomiserais',
    'atomiserait',
    'atomisèrent',
    'atomiseriez',
    'atomiserons',
    'atomiseront',
    'atomistique',
    'atrabilaire',
    'atrophiâmes',
    'atrophiasse',
    'atrophiâtes',
    'atrophierai',
    'atrophieras',
    'atrophierez',
    'atrophiions',
    'attablaient',
    'attablasses',
    'attablerais',
    'attablerait',
    'attablèrent',
    'attableriez',
    'attablerons',
    'attableront',
    'attachaient',
    'attachantes',
    'attachasses',
    'attachement',
    'attacherais',
    'attacherait',
    'attachèrent',
    'attacheriez',
    'attacherons',
    'attacheront',
    'attaquables',
    'attaquaient',
    'attaquantes',
    'attaquasses',
    'attaquerais',
    'attaquerait',
    'attaquèrent',
    'attaqueriez',
    'attaquerons',
    'attaqueront',
    'attardaient',
    'attardasses',
    'attarderais',
    'attarderait',
    'attardèrent',
    'attarderiez',
    'attarderons',
    'attarderont',
    'atteignable',
    'atteignîmes',
    'atteignions',
    'atteignisse',
    'atteignîtes',
    'atteindrais',
    'atteindrait',
    'atteindriez',
    'atteindrons',
    'atteindront',
    'attelassent',
    'attelassiez',
    'attellerais',
    'attellerait',
    'attelleriez',
    'attellerons',
    'attelleront',
    'attendaient',
    'attendirent',
    'attendisses',
    'attendrîmes',
    'attendrions',
    'attendrirai',
    'attendriras',
    'attendrirez',
    'attendrisse',
    'attendrîtes',
    'attentaient',
    'attentasses',
    'attenterais',
    'attenterait',
    'attentèrent',
    'attenteriez',
    'attenterons',
    'attenteront',
    'attentionné',
    'attentismes',
    'attentistes',
    'atténuaient',
    'atténuantes',
    'atténuasses',
    'atténuateur',
    'atténuation',
    'atténuerais',
    'atténuerait',
    'atténuèrent',
    'atténueriez',
    'atténuerons',
    'atténueront',
    'atterraient',
    'atterrantes',
    'atterrasses',
    'atterrerais',
    'atterrerait',
    'atterrèrent',
    'atterreriez',
    'atterrerons',
    'atterreront',
    'atterrirais',
    'atterrirait',
    'atterrirent',
    'atterririez',
    'atterrirons',
    'atterriront',
    'atterrisses',
    'atterrissez',
    'attestaient',
    'attestasses',
    'attestation',
    'attesterais',
    'attesterait',
    'attestèrent',
    'attesteriez',
    'attesterons',
    'attesteront',
    'attiédirais',
    'attiédirait',
    'attiédirent',
    'attiédiriez',
    'attiédirons',
    'attiédiront',
    'attiédisses',
    'attiédissez',
    'attifassent',
    'attifassiez',
    'attifements',
    'attiferions',
    'attigeaient',
    'attigeasses',
    'attigerions',
    'attirassent',
    'attirassiez',
    'attirerions',
    'attisassent',
    'attisassiez',
    'attisements',
    'attiserions',
    'attitraient',
    'attitrasses',
    'attitrerais',
    'attitrerait',
    'attitrèrent',
    'attitreriez',
    'attitrerons',
    'attitreront',
    'attractions',
    'attractives',
    'attrapaient',
    'attrapasses',
    'attraperais',
    'attraperait',
    'attrapèrent',
    'attraperiez',
    'attraperons',
    'attraperont',
    'attrayantes',
    'attribuable',
    'attribuâmes',
    'attribuasse',
    'attribuâtes',
    'attribuerai',
    'attribueras',
    'attribuerez',
    'attribuions',
    'attributifs',
    'attribution',
    'attributive',
    'attriquâmes',
    'attriquasse',
    'attriquâtes',
    'attriquerai',
    'attriqueras',
    'attriquerez',
    'attriquions',
    'attristâmes',
    'attristante',
    'attristants',
    'attristasse',
    'attristâtes',
    'attristerai',
    'attristeras',
    'attristerez',
    'attristions',
    'attroupâmes',
    'attroupasse',
    'attroupâtes',
    'attrouperai',
    'attrouperas',
    'attrouperez',
    'attroupions',
    'aubergistes',
    'audacieuses',
    'audibilités',
    'audi-mutité',
    'audiogramme',
    'audiomètres',
    'audiophones',
    'audiovisuel',
    'auditionnai',
    'auditionnas',
    'auditionnât',
    'auditionnée',
    'auditionner',
    'auditionnes',
    'auditionnés',
    'auditionnez',
    'auditoriums',
    'augmentable',
    'augmentâmes',
    'augmentasse',
    'augmentâtes',
    'augmentatif',
    'augmenterai',
    'augmenteras',
    'augmenterez',
    'augmentions',
    'augurassent',
    'augurassiez',
    'augurerions',
    'augustinien',
    'aurait-elle',
    'aura-t-elle',
    'auréolaient',
    'auréolasses',
    'auréolerais',
    'auréolerait',
    'auréolèrent',
    'auréoleriez',
    'auréolerons',
    'auréoleront',
    'auréomycine',
    'auriculaire',
    'auriez-vous',
    'aurifiaient',
    'aurifiasses',
    'aurifierais',
    'aurifierait',
    'aurifièrent',
    'aurifieriez',
    'aurifierons',
    'aurifieront',
    'aurignacien',
    'aurons-nous',
    'auscultâmes',
    'auscultasse',
    'auscultâtes',
    'ausculterai',
    'ausculteras',
    'ausculterez',
    'auscultions',
    'austèrement',
    'australiens',
    'autarciques',
    'authentifia',
    'authentifie',
    'authentifié',
    'authentiqua',
    'authentique',
    'authentiqué',
    'autistiques',
    'autocensure',
    'autochtones',
    'autocollant',
    'autocraties',
    'autocuiseur',
    'autodéfense',
    'autodidacte',
    'auto-écoles',
    'autofinancé',
    'autogestion',
    'autographes',
    'autographia',
    'autographie',
    'autographié',
    'autoguidage',
    'autoguidées',
    'autoguidiez',
    'autoguidons',
    'automations',
    'automatique',
    'automatisai',
    'automatisas',
    'automatisât',
    'automatisée',
    'automatiser',
    'automatises',
    'automatisés',
    'automatisez',
    'automatisme',
    'automobiles',
    'automoteurs',
    'automotrice',
    'autonomiste',
    'autoplastie',
    'autoportant',
    'autoporteur',
    'autopsiâmes',
    'autopsiasse',
    'autopsiâtes',
    'autopsierai',
    'autopsieras',
    'autopsierez',
    'autopsiions',
    'autoréglage',
    'autorisâmes',
    'autorisasse',
    'autorisâtes',
    'autoriserai',
    'autoriseras',
    'autoriserez',
    'autorisions',
    'autoritaire',
    'autoroutier',
    'autoscopies',
    'autotrophes',
    'autovaccins',
    'autrichiens',
    'auvergnates',
    'auxiliaires',
    'avachirions',
    'avachissais',
    'avachissait',
    'avachissant',
    'avachissent',
    'avachissiez',
    'avachissons',
    'avaient-ils',
    'avalassions',
    'avaleraient',
    'avalisaient',
    'avalisasses',
    'avaliserais',
    'avaliserait',
    'avalisèrent',
    'avaliseriez',
    'avaliserons',
    'avaliseront',
    'avançassent',
    'avançassiez',
    'avancements',
    'avancerions',
    'avantageais',
    'avantageait',
    'avantageant',
    'avantageons',
    'avantagerai',
    'avantageras',
    'avantagerez',
    'avantageuse',
    'avantagions',
    'avant-corps',
    'avant-garde',
    'avant-goûts',
    'avant-mains',
    'avant-ports',
    'avant-poste',
    'avant-scène',
    'avant-toits',
    'avant-train',
    'avariassent',
    'avariassiez',
    'avaricieuse',
    'avarierions',
    'aventurâmes',
    'aventurasse',
    'aventurâtes',
    'aventurerai',
    'aventureras',
    'aventurerez',
    'aventureuse',
    'aventurière',
    'aventuriers',
    'aventurines',
    'aventurions',
    'aventurisme',
    'avérassions',
    'avéreraient',
    'avertirions',
    'avertissais',
    'avertissait',
    'avertissant',
    'avertissent',
    'avertisseur',
    'avertissiez',
    'avertissons',
    'aveuglaient',
    'aveuglantes',
    'aveuglasses',
    'aveuglement',
    'aveuglément',
    'aveuglerais',
    'aveuglerait',
    'aveuglèrent',
    'aveugleriez',
    'aveuglerons',
    'aveugleront',
    'aveuglettes',
    'aveulirions',
    'aveulissais',
    'aveulissait',
    'aveulissant',
    'aveulissent',
    'aveulissiez',
    'aveulissons',
    'aviculteurs',
    'avicultrice',
    'avicultures',
    'aviliraient',
    'avilissante',
    'avilissants',
    'avilissions',
    'avinassions',
    'avineraient',
    'avion-cargo',
    'avionnettes',
    'avions-nous',
    'avisassions',
    'aviseraient',
    'avitaillais',
    'avitaillait',
    'avitaillant',
    'avitaillées',
    'avitaillent',
    'avitaillera',
    'avitailliez',
    'avitaillons',
    'avitaminose',
    'avivassions',
    'aviveraient',
    'avocaillons',
    'avocasserie',
    'avocassière',
    'avocassiers',
    'avoisinâmes',
    'avoisinante',
    'avoisinants',
    'avoisinasse',
    'avoisinâtes',
    'avoisinerai',
    'avoisineras',
    'avoisinerez',
    'avoisinions',
    'avortassent',
    'avortassiez',
    'avortements',
    'avorterions',
    'avouassions',
    'avoueraient',
    'avunculaire',
    'axiologique',
    'axiomatisai',
    'axiomatisas',
    'axiomatisât',
    'axiomatisée',
    'axiomatiser',
    'axiomatises',
    'axiomatisés',
    'axiomatisez',
    'axonométrie',
    'azoospermie',
    'azurassions',
    'azureraient',
    'babillaient',
    'babillardes',
    'babillasses',
    'babillerais',
    'babillerait',
    'babillèrent',
    'babilleriez',
    'babillerons',
    'babilleront',
    'babiroussas',
    'babouvismes',
    'baby-sitter',
    'bacchanales',
    'bacciformes',
    'bâchassions',
    'bachelières',
    'bâcheraient',
    'bachotaient',
    'bachotasses',
    'bachoterais',
    'bachoterait',
    'bachotèrent',
    'bachoteriez',
    'bachoterons',
    'bachoteront',
    'bacillaires',
    'bâclassions',
    'bâcleraient',
    'bactéricide',
    'bactéridies',
    'bactérienne',
    'badauderies',
    'badigeonnai',
    'badigeonnas',
    'badigeonnât',
    'badigeonnée',
    'badigeonner',
    'badigeonnes',
    'badigeonnés',
    'badigeonnez',
    'badinassent',
    'badinassiez',
    'badinerions',
    'baffassions',
    'bafferaient',
    'bafouassent',
    'bafouassiez',
    'bafouerions',
    'bafouillage',
    'bafouillais',
    'bafouillait',
    'bafouillant',
    'bafouillées',
    'bafouillent',
    'bafouillera',
    'bafouilleur',
    'bafouilliez',
    'bafouillons',
    'bâfrassions',
    'bâfreraient',
    'bagarraient',
    'bagarrasses',
    'bagarrerais',
    'bagarrerait',
    'bagarrèrent',
    'bagarreriez',
    'bagarrerons',
    'bagarreront',
    'bagarreuses',
    'baguassions',
    'baguenaudai',
    'baguenaudas',
    'baguenaudât',
    'baguenaudée',
    'baguenauder',
    'baguenaudes',
    'baguenaudés',
    'baguenaudez',
    'bagueraient',
    'baignassent',
    'baignassiez',
    'baignerions',
    'baillassent',
    'bâillassent',
    'baillassiez',
    'bâillassiez',
    'bâillements',
    'bailleresse',
    'baillerions',
    'bâillerions',
    'bâillonnais',
    'bâillonnait',
    'bâillonnant',
    'bâillonnées',
    'bâillonnent',
    'bâillonnera',
    'bâillonniez',
    'bâillonnons',
    'bains-marie',
    'baïonnettes',
    'baisassions',
    'baiseraient',
    'baisotaient',
    'baisotasses',
    'baisoterais',
    'baisoterait',
    'baisotèrent',
    'baisoteriez',
    'baisoterons',
    'baisoteront',
    'baissassent',
    'baissassiez',
    'baisserions',
    'baladassent',
    'baladassiez',
    'baladerions',
    'balafraient',
    'balafrasses',
    'balafrerais',
    'balafrerait',
    'balafrèrent',
    'balafreriez',
    'balafrerons',
    'balafreront',
    'balaierions',
    'balançaient',
    'balançasses',
    'balancelles',
    'balancement',
    'balancerais',
    'balancerait',
    'balancèrent',
    'balanceriez',
    'balancerons',
    'balanceront',
    'balançoires',
    'balayassent',
    'balayassiez',
    'balayerions',
    'balbutiâmes',
    'balbutiante',
    'balbutiants',
    'balbutiasse',
    'balbutiâtes',
    'balbutierai',
    'balbutieras',
    'balbutierez',
    'balbutiions',
    'baleinaient',
    'baleinasses',
    'baleinerais',
    'baleinerait',
    'baleinèrent',
    'baleineriez',
    'baleinerons',
    'baleineront',
    'baleinières',
    'balisassent',
    'balisassiez',
    'baliserions',
    'balistiques',
    'balkaniques',
    'balkanisais',
    'balkanisait',
    'balkanisant',
    'balkanisées',
    'balkanisent',
    'balkanisera',
    'balkanisiez',
    'balkanisons',
    'ballassions',
    'ballastages',
    'ballastâmes',
    'ballastasse',
    'ballastâtes',
    'ballasterai',
    'ballasteras',
    'ballasterez',
    'ballastière',
    'ballastions',
    'balleraient',
    'balletomane',
    'ballonnâmes',
    'ballonnasse',
    'ballonnâtes',
    'ballonnerai',
    'ballonneras',
    'ballonnerez',
    'ballonnions',
    'ballottages',
    'ballottâmes',
    'ballottasse',
    'ballottâtes',
    'ballotterai',
    'ballotteras',
    'ballotterez',
    'ballottines',
    'ballottions',
    'balnéations',
    'balourdises',
    'balsamiques',
    'balustrades',
    'bambochâmes',
    'bambochasse',
    'bambochâtes',
    'bambocherai',
    'bambocheras',
    'bambocherez',
    'bambocheurs',
    'bambocheuse',
    'bambochions',
    'banalisâmes',
    'banalisasse',
    'banalisâtes',
    'banaliserai',
    'banaliseras',
    'banaliserez',
    'banalisions',
    'bananeraies',
    'bandagistes',
    'bandassions',
    'bandelettes',
    'banderaient',
    'banderilles',
    'banditismes',
    'bandoulière',
    'banlieusard',
    'bannassions',
    'banneraient',
    'banniraient',
    'bannissions',
    'banquassent',
    'banquassiez',
    'banquerions',
    'banqueroute',
    'banquetâmes',
    'banquetasse',
    'banquetâtes',
    'banqueteurs',
    'banquetions',
    'banquettent',
    'banquettera',
    'baptisaient',
    'baptisasses',
    'baptiserais',
    'baptiserait',
    'baptisèrent',
    'baptiseriez',
    'baptiserons',
    'baptiseront',
    'baptismales',
    'baptistaire',
    'baquetaient',
    'baquetasses',
    'baquetèrent',
    'baquetterai',
    'baquetteras',
    'baquetterez',
    'baragouinai',
    'baragouinas',
    'baragouinât',
    'baragouinée',
    'baragouiner',
    'baragouines',
    'baragouinés',
    'baragouinez',
    'baraquaient',
    'baraquasses',
    'baraquement',
    'baraquerais',
    'baraquerait',
    'baraquèrent',
    'baraqueriez',
    'baraquerons',
    'baraqueront',
    'baratinâmes',
    'baratinasse',
    'baratinâtes',
    'baratinerai',
    'baratineras',
    'baratinerez',
    'baratineurs',
    'baratineuse',
    'baratinions',
    'barattaient',
    'barattasses',
    'baratterais',
    'baratterait',
    'barattèrent',
    'baratteriez',
    'baratterons',
    'baratteront',
    'barbaresque',
    'barbarismes',
    'barbassions',
    'barberaient',
    'barbichette',
    'barbifiâmes',
    'barbifiasse',
    'barbifiâtes',
    'barbifierai',
    'barbifieras',
    'barbifierez',
    'barbifiions',
    'barbotaient',
    'barbotasses',
    'barboterais',
    'barboterait',
    'barbotèrent',
    'barboteriez',
    'barboterons',
    'barboteront',
    'barboteuses',
    'barbotières',
    'barbouillai',
    'barbouillas',
    'barbouillât',
    'barbouillée',
    'barbouiller',
    'barbouilles',
    'barbouillés',
    'barbouillez',
    'barcarolles',
    'bardassions',
    'barderaient',
    'barétassent',
    'barétassiez',
    'barèterions',
    'barguignais',
    'barguignait',
    'barguignant',
    'barguignent',
    'barguignera',
    'barguigniez',
    'barguignons',
    'bariolaient',
    'bariolasses',
    'bariolerais',
    'bariolerait',
    'bariolèrent',
    'barioleriez',
    'bariolerons',
    'barioleront',
    'barlotières',
    'barographes',
    'baronnaient',
    'baronnasses',
    'baronnerais',
    'baronnerait',
    'baronnèrent',
    'baronneriez',
    'baronnerons',
    'baronneront',
    'baronnettes',
    'baroquismes',
    'baroudaient',
    'baroudasses',
    'barouderais',
    'barouderait',
    'baroudèrent',
    'barouderiez',
    'barouderons',
    'barouderont',
    'barrassions',
    'barreraient',
    'barricadais',
    'barricadait',
    'barricadant',
    'barricadées',
    'barricadent',
    'barricadera',
    'barricadiez',
    'barricadons',
    'barriraient',
    'barrissions',
    'bartavelles',
    'barycentres',
    'barymétries',
    'barysphères',
    'basaltiques',
    'basanassent',
    'basanassiez',
    'basanerions',
    'basculaient',
    'basculantes',
    'basculasses',
    'basculement',
    'basculerais',
    'basculerait',
    'basculèrent',
    'basculeriez',
    'basculerons',
    'basculeront',
    'basilicales',
    'bas-jointée',
    'bas-jointés',
    'basket-ball',
    'basketteurs',
    'basketteuse',
    'bas-reliefs',
    'bassinaient',
    'bassinantes',
    'bassinasses',
    'bassinerais',
    'bassinerait',
    'bassinèrent',
    'bassineriez',
    'bassinerons',
    'bassineront',
    'bassinoires',
    'bastillonna',
    'bastillonne',
    'bastillonné',
    'bastingages',
    'bastionnais',
    'bastionnait',
    'bastionnant',
    'bastionnées',
    'bastionnent',
    'bastionnera',
    'bastionniez',
    'bastionnons',
    'bastonnades',
    'bastringues',
    'bas-ventres',
    'bataillâmes',
    'bataillasse',
    'bataillâtes',
    'bataillerai',
    'batailleras',
    'bataillerez',
    'batailleurs',
    'batailleuse',
    'bataillions',
    'batelassent',
    'batelassiez',
    'batellerais',
    'batellerait',
    'batelleries',
    'batelleriez',
    'batellerons',
    'batelleront',
    'bathymétrie',
    'bathyscaphe',
    'bathysphère',
    'batifolages',
    'batifolâmes',
    'batifolante',
    'batifolants',
    'batifolasse',
    'batifolâtes',
    'batifolerai',
    'batifoleras',
    'batifolerez',
    'batifolions',
    'bâtissaient',
    'bâtisseuses',
    'bâtonnaient',
    'bâtonnasses',
    'bâtonnerais',
    'bâtonnerait',
    'bâtonnèrent',
    'bâtonneriez',
    'bâtonnerons',
    'bâtonneront',
    'battissions',
    'baugeassent',
    'baugeassiez',
    'baugeraient',
    'bavardaient',
    'bavardasses',
    'bavarderais',
    'bavarderait',
    'bavardèrent',
    'bavarderiez',
    'bavarderons',
    'bavarderont',
    'bavassaient',
    'bavassasses',
    'bavasserais',
    'bavasserait',
    'bavassèrent',
    'bavasseriez',
    'bavasserons',
    'bavasseront',
    'bavochaient',
    'bavochasses',
    'bavocherais',
    'bavocherait',
    'bavochèrent',
    'bavocheriez',
    'bavocherons',
    'bavocheront',
    'bazardaient',
    'bazardasses',
    'bazarderais',
    'bazarderait',
    'bazardèrent',
    'bazarderiez',
    'bazarderons',
    'bazarderont',
    'béatifiâmes',
    'béatifiante',
    'béatifiants',
    'béatifiasse',
    'béatifiâtes',
    'béatifierai',
    'béatifieras',
    'béatifierez',
    'béatifiions',
    'béatifiques',
    'beauceronne',
    'beaux-pères',
    'bec-de-cane',
    'bêchassions',
    'bêcheraient',
    'bécotassent',
    'bécotassiez',
    'bécoterions',
    'becquassent',
    'becquassiez',
    'becquerions',
    'becquetâmes',
    'becquetance',
    'becquetasse',
    'becquetâtes',
    'becquetions',
    'becquettent',
    'becquettera',
    'bectassions',
    'becteraient',
    'bedonnaient',
    'bedonnantes',
    'bedonnasses',
    'bedonnerais',
    'bedonnerait',
    'bedonnèrent',
    'bedonneriez',
    'bedonnerons',
    'bedonneront',
    'bégaiements',
    'bégaierions',
    'bégayassent',
    'bégayassiez',
    'bégayerions',
    'béguetaient',
    'béguetasses',
    'béguètement',
    'béguèterais',
    'béguèterait',
    'béguetèrent',
    'béguèteriez',
    'béguèterons',
    'béguèteront',
    'bégueulerie',
    'belgicismes',
    'belle-doche',
    'belle-fille',
    'belle-soeur',
    'bellicismes',
    'bellicistes',
    'belligérant',
    'belliqueuse',
    'bémolisâmes',
    'bémolisasse',
    'bémolisâtes',
    'bémoliserai',
    'bémoliseras',
    'bémoliserez',
    'bémolisions',
    'bénédicités',
    'bénédictine',
    'bénédictins',
    'bénédiction',
    'bénéficiais',
    'bénéficiait',
    'bénéficiant',
    'bénéficient',
    'bénéficiera',
    'bénéficiiez',
    'bénéficions',
    'bénignement',
    'bénissaient',
    'bénisseuses',
    'benoîtement',
    'benzéniques',
    'benzolismes',
    'béquassions',
    'béqueraient',
    'béquetaient',
    'béquetasses',
    'béquetèrent',
    'béquetterai',
    'béquetteras',
    'béquetterez',
    'béquillâmes',
    'béquillasse',
    'béquillâtes',
    'béquillerai',
    'béquilleras',
    'béquillerez',
    'béquillions',
    'berçassions',
    'berceraient',
    'bergamasque',
    'bergamotier',
    'bernardines',
    'bernassions',
    'berneraient',
    'berrichonne',
    'bersagliers',
    'besognaient',
    'besognasses',
    'besognerais',
    'besognerait',
    'besognèrent',
    'besogneriez',
    'besognerons',
    'besogneront',
    'besogneuses',
    'bestialités',
    'best-seller',
    'bétaillères',
    'bêtifiaient',
    'bêtifiasses',
    'bêtifierais',
    'bêtifierait',
    'bêtifièrent',
    'bêtifieriez',
    'bêtifierons',
    'bêtifieront',
    'bêtisassent',
    'bêtisassiez',
    'bêtiserions',
    'bétonnaient',
    'bétonnasses',
    'bétonnerais',
    'bétonnerait',
    'bétonnèrent',
    'bétonneriez',
    'bétonnerons',
    'bétonneront',
    'bétonneuses',
    'bétonnières',
    'betteravier',
    'beuglassent',
    'beuglassiez',
    'beuglements',
    'beuglerions',
    'beurrassent',
    'beurrassiez',
    'beurrerions',
    'biacuminées',
    'biaisassent',
    'biaisassiez',
    'biaiserions',
    'bibelotâmes',
    'bibelotasse',
    'bibelotâtes',
    'bibeloterai',
    'bibeloteras',
    'bibeloterez',
    'bibelotions',
    'biberonnais',
    'biberonnait',
    'biberonnant',
    'biberonnent',
    'biberonnera',
    'biberonniez',
    'biberonnons',
    'bibliomanie',
    'bibliophile',
    'bicamérisme',
    'bicarbonate',
    'bichassions',
    'bicheraient',
    'bichonnâmes',
    'bichonnasse',
    'bichonnâtes',
    'bichonnerai',
    'bichonneras',
    'bichonnerez',
    'bichonnions',
    'bichotaient',
    'bichotasses',
    'bichoterais',
    'bichoterait',
    'bichotèrent',
    'bichoteriez',
    'bichoterons',
    'bichoteront',
    'bichromates',
    'biculturels',
    'bicyclettes',
    'bidonnaient',
    'bidonnantes',
    'bidonnasses',
    'bidonnerais',
    'bidonnerait',
    'bidonnèrent',
    'bidonneriez',
    'bidonnerons',
    'bidonneront',
    'bidonvilles',
    'bidouillage',
    'bidouillais',
    'bidouillait',
    'bidouillant',
    'bidouillées',
    'bidouillent',
    'bidouillera',
    'bidouilliez',
    'bidouillons',
    'bien-aimées',
    'bienfaisant',
    'bienfaiteur',
    'bien-fondés',
    'bienheureux',
    'bienséances',
    'bienséantes',
    'biens-fonds',
    'biffassions',
    'bifferaient',
    'bifurcation',
    'bifurquâmes',
    'bifurquasse',
    'bifurquâtes',
    'bifurquerai',
    'bifurqueras',
    'bifurquerez',
    'bifurquions',
    'bigaradiers',
    'bigarraient',
    'bigarrasses',
    'bigarrerais',
    'bigarrerait',
    'bigarrèrent',
    'bigarreriez',
    'bigarrerons',
    'bigarreront',
    'biglassions',
    'bigleraient',
    'bigornaient',
    'bigornasses',
    'bigornerais',
    'bigornerait',
    'bigornèrent',
    'bigorneriez',
    'bigornerons',
    'bigorneront',
    'bijouteries',
    'bijoutières',
    'bilatérales',
    'bilharziose',
    'bilinguisme',
    'bilirubines',
    'billebaudai',
    'billebaudas',
    'billebaudât',
    'billebauder',
    'billebaudes',
    'billebaudez',
    'billetterie',
    'billevesées',
    'billonnages',
    'billonnâmes',
    'billonnasse',
    'billonnâtes',
    'billonnerai',
    'billonneras',
    'billonnerez',
    'billonnions',
    'biloculaire',
    'biloquaient',
    'biloquasses',
    'biloquerais',
    'biloquerait',
    'biloquèrent',
    'biloqueriez',
    'biloquerons',
    'biloqueront',
    'bimensuelle',
    'bimestriels',
    'binationale',
    'binationaux',
    'binoclardes',
    'binoculaire',
    'biochimiste',
    'bioénergies',
    'biographies',
    'biologiques',
    'biologistes',
    'biomédicale',
    'biomédicaux',
    'biophysique',
    'biosynthèse',
    'biothérapie',
    'bipartismes',
    'bipartition',
    'bipolarités',
    'biquotidien',
    'biréacteurs',
    'bisannuelle',
    'biscaïennes',
    'biscotterie',
    'biscuitâmes',
    'biscuitasse',
    'biscuitâtes',
    'biscuiterai',
    'biscuiteras',
    'biscuiterez',
    'biscuiterie',
    'biscuitions',
    'biseautages',
    'biseautâmes',
    'biseautasse',
    'biseautâtes',
    'biseauterai',
    'biseauteras',
    'biseauterez',
    'biseautions',
    'bisegmentai',
    'bisegmentas',
    'bisegmentât',
    'bisegmentée',
    'bisegmenter',
    'bisegmentes',
    'bisegmentés',
    'bisegmentez',
    'bisexualité',
    'bisexuelles',
    'bismuthines',
    'bisquassent',
    'bisquassiez',
    'bisquerions',
    'bissassions',
    'bissecteurs',
    'bissections',
    'bissectrice',
    'bisseraient',
    'bissextiles',
    'bissexuelle',
    'bistouilles',
    'bistournage',
    'bistournais',
    'bistournait',
    'bistournant',
    'bistournées',
    'bistournent',
    'bistournera',
    'bistourniez',
    'bistournons',
    'bistrassent',
    'bistrassiez',
    'bistrerions',
    'bittassions',
    'bitteraient',
    'bitturaient',
    'bitturasses',
    'bitturerais',
    'bitturerait',
    'bitturèrent',
    'bittureriez',
    'bitturerons',
    'bittureront',
    'bitumassent',
    'bitumassiez',
    'bitumerions',
    'bituminâmes',
    'bituminasse',
    'bituminâtes',
    'bituminerai',
    'bitumineras',
    'bituminerez',
    'bitumineuse',
    'bituminions',
    'biturassent',
    'biturassiez',
    'biturerions',
    'bivouaquais',
    'bivouaquait',
    'bivouaquant',
    'bivouaquent',
    'bivouaquera',
    'bivouaquiez',
    'bivouaquons',
    'bizarrement',
    'bizarreries',
    'bizarroïdes',
    'bizutassent',
    'bizutassiez',
    'bizuterions',
    'blablatâmes',
    'blablatasse',
    'blablatâtes',
    'blablaterai',
    'blablateras',
    'blablaterez',
    'blablations',
    'blackboulai',
    'blackboulas',
    'blackboulât',
    'blackboulée',
    'blackbouler',
    'blackboules',
    'blackboulés',
    'blackboulez',
    'blaguassent',
    'blaguassiez',
    'blaguerions',
    'blairassent',
    'blairassiez',
    'blairerions',
    'blâmassions',
    'blâmeraient',
    'blanc-estoc',
    'blanchaille',
    'blanchâtres',
    'blanchiment',
    'blanchirais',
    'blanchirait',
    'blanchirent',
    'blanchiriez',
    'blanchirons',
    'blanchiront',
    'blanchisses',
    'blanchissez',
    'blancs-becs',
    'blanc-seing',
    'blanquettes',
    'blasassions',
    'blaseraient',
    'blasonnâmes',
    'blasonnasse',
    'blasonnâtes',
    'blasonnerai',
    'blasonneras',
    'blasonnerez',
    'blasonnions',
    'blasphémais',
    'blasphémait',
    'blasphémant',
    'blasphémées',
    'blasphèment',
    'blasphémera',
    'blasphémiez',
    'blasphémons',
    'blastoderme',
    'blastopores',
    'blatéraient',
    'blatérasses',
    'blatérerais',
    'blatérerait',
    'blatérèrent',
    'blatéreriez',
    'blatérerons',
    'blatéreront',
    'blêmeraient',
    'blêmissions',
    'blennorrhée',
    'blépharites',
    'blésassions',
    'bléseraient',
    'blessassent',
    'blessassiez',
    'blesserions',
    'blettirions',
    'blettissais',
    'blettissait',
    'blettissant',
    'blettissent',
    'blettissiez',
    'blettissons',
    'blettissure',
    'bleuetières',
    'bleuiraient',
    'bleuissions',
    'bleusailles',
    'bleutassent',
    'bleutassiez',
    'bleuterions',
    'blindassent',
    'blindassiez',
    'blinderions',
    'bloc-moteur',
    'blocs-notes',
    'blondinette',
    'blondissais',
    'blondissait',
    'blondissant',
    'blondissent',
    'blondissiez',
    'blondissons',
    'blondoierai',
    'blondoieras',
    'blondoierez',
    'blondoyâmes',
    'blondoyasse',
    'blondoyâtes',
    'blondoyions',
    'bloquassent',
    'bloquassiez',
    'bloquerions',
    'blottirions',
    'blottissais',
    'blottissait',
    'blottissant',
    'blottissent',
    'blottissiez',
    'blottissons',
    'blousassent',
    'blousassiez',
    'blouserions',
    'bluffassent',
    'bluffassiez',
    'blufferions',
    'blutassions',
    'bluteraient',
    'bobinassent',
    'bobinassiez',
    'bobinerions',
    'bocardaient',
    'bocardasses',
    'bocarderais',
    'bocarderait',
    'bocardèrent',
    'bocarderiez',
    'bocarderons',
    'bocarderont',
    'bodhisattva',
    'boettassent',
    'boettassiez',
    'boetterions',
    'bohémiennes',
    'boisassions',
    'boiseraient',
    'boisseliers',
    'boitassions',
    'boiteraient',
    'boitillâmes',
    'boitillante',
    'boitillants',
    'boitillasse',
    'boitillâtes',
    'boitillerai',
    'boitilleras',
    'boitillerez',
    'boitillions',
    'bolchévisai',
    'bolchévisas',
    'bolchévisât',
    'bolchévisée',
    'bolchéviser',
    'bolchévises',
    'bolchévisés',
    'bolchévisez',
    'bolchevisme',
    'bolcheviste',
    'bombagistes',
    'bombardâmes',
    'bombardasse',
    'bombardâtes',
    'bombarderai',
    'bombarderas',
    'bombarderez',
    'bombardiers',
    'bombardions',
    'bombassions',
    'bomberaient',
    'bonasseries',
    'bonbonnière',
    'bondassions',
    'bonderaient',
    'bondérisais',
    'bondérisait',
    'bondérisant',
    'bondérisées',
    'bondérisent',
    'bondérisera',
    'bondérisiez',
    'bondérisons',
    'bondiraient',
    'bondissante',
    'bondissants',
    'bondissions',
    'bondonnâmes',
    'bondonnasse',
    'bondonnâtes',
    'bondonnerai',
    'bondonneras',
    'bondonnerez',
    'bondonnions',
    'bonifiaient',
    'bonifiantes',
    'bonifiasses',
    'bonifierais',
    'bonifierait',
    'bonifièrent',
    'bonifieriez',
    'bonifierons',
    'bonifieront',
    'bonimentais',
    'bonimentait',
    'bonimentant',
    'bonimentent',
    'bonimentera',
    'bonimenteur',
    'bonimentiez',
    'bonimentons',
    'bonne-maman',
    'bonneteries',
    'bonnetières',
    'bootleggers',
    'borborygmes',
    'bordassions',
    'bordelaises',
    'bordéliques',
    'borderaient',
    'bornassions',
    'borneraient',
    'bornoierais',
    'bornoierait',
    'bornoieriez',
    'bornoierons',
    'bornoieront',
    'bornoyaient',
    'bornoyasses',
    'bornoyèrent',
    'bossassions',
    'bosselaient',
    'bosselasses',
    'bosselèrent',
    'bossellerai',
    'bosselleras',
    'bossellerez',
    'bosseraient',
    'bossuassent',
    'bossuassiez',
    'bossuerions',
    'botanisâmes',
    'botanisasse',
    'botanisâtes',
    'botaniserai',
    'botaniseras',
    'botaniserez',
    'botanisions',
    'bottassions',
    'bottelaient',
    'bottelasses',
    'bottelèrent',
    'botteleuses',
    'bottellerai',
    'bottelleras',
    'bottellerez',
    'botteraient',
    'boucanaient',
    'boucanasses',
    'boucanerais',
    'boucanerait',
    'boucanèrent',
    'boucaneriez',
    'boucanerons',
    'boucaneront',
    'bouchardais',
    'bouchardait',
    'bouchardant',
    'bouchardées',
    'bouchardent',
    'bouchardera',
    'bouchardiez',
    'bouchardons',
    'bouchassent',
    'bouchassiez',
    'boucherions',
    'bouche-trou',
    'bouchonnais',
    'bouchonnait',
    'bouchonnant',
    'bouchonnées',
    'bouchonnent',
    'bouchonnera',
    'bouchonnier',
    'bouchonniez',
    'bouchonnons',
    'bouchoteurs',
    'bouclassent',
    'bouclassiez',
    'bouclerions',
    'boudassions',
    'bouddhiques',
    'bouddhistes',
    'bouderaient',
    'boudinaient',
    'boudinasses',
    'boudinerais',
    'boudinerait',
    'boudinèrent',
    'boudineriez',
    'boudinerons',
    'boudineront',
    'boudineuses',
    'bouffassent',
    'bouffassiez',
    'boufferions',
    'bouffetance',
    'bouffirions',
    'bouffissais',
    'bouffissait',
    'bouffissant',
    'bouffissent',
    'bouffissiez',
    'bouffissons',
    'bouffissure',
    'bouffonnais',
    'bouffonnait',
    'bouffonnant',
    'bouffonnent',
    'bouffonnera',
    'bouffonniez',
    'bouffonnons',
    'bougeassent',
    'bougeassiez',
    'bougeraient',
    'bougonnâmes',
    'bougonnasse',
    'bougonnâtes',
    'bougonnerai',
    'bougonneras',
    'bougonnerez',
    'bougonnions',
    'bouillaient',
    'bouillantes',
    'bouillirais',
    'bouillirait',
    'bouillirent',
    'bouilliriez',
    'bouillirons',
    'bouilliront',
    'bouillisses',
    'bouilloires',
    'bouillonnai',
    'bouillonnas',
    'bouillonnât',
    'bouillonner',
    'bouillonnes',
    'bouillonnez',
    'bouillottai',
    'bouillottas',
    'bouillottât',
    'bouillotter',
    'bouillottes',
    'bouillottez',
    'bouis-bouis',
    'boulangeais',
    'boulangeait',
    'boulangeant',
    'boulangeons',
    'boulangerai',
    'boulangeras',
    'boulangères',
    'boulangerez',
    'boulangerie',
    'boulangions',
    'boulangisme',
    'boulangiste',
    'boulassions',
    'bouldozeurs',
    'bouledogues',
    'bouleraient',
    'bouleversai',
    'bouleversas',
    'bouleversât',
    'bouleversée',
    'bouleverser',
    'bouleverses',
    'bouleversés',
    'bouleversez',
    'boulimiques',
    'boulingrins',
    'boulinières',
    'boulodromes',
    'boulonnages',
    'boulonnâmes',
    'boulonnasse',
    'boulonnâtes',
    'boulonnerai',
    'boulonneras',
    'boulonnerez',
    'boulonnerie',
    'boulonnions',
    'boulottâmes',
    'boulottasse',
    'boulottâtes',
    'boulotterai',
    'boulotteras',
    'boulotterez',
    'boulottions',
    'boumeraient',
    'bouquetière',
    'bouquetiers',
    'bouquinâmes',
    'bouquinasse',
    'bouquinâtes',
    'bouquinerai',
    'bouquineras',
    'bouquinerez',
    'bouquinerie',
    'bouquineurs',
    'bouquineuse',
    'bouquinions',
    'bouquiniste',
    'bourbillons',
    'bourboniens',
    'bourdassent',
    'bourdassiez',
    'bourderions',
    'bourdonnais',
    'bourdonnait',
    'bourdonnant',
    'bourdonnent',
    'bourdonnera',
    'bourdonniez',
    'bourdonnons',
    'bourgeoises',
    'bourgeoisie',
    'bourgeonnai',
    'bourgeonnas',
    'bourgeonnât',
    'bourgeonner',
    'bourgeonnes',
    'bourgeonnez',
    'bourgmestre',
    'bourguignon',
    'bourlinguai',
    'bourlinguas',
    'bourlinguât',
    'bourlinguer',
    'bourlingues',
    'bourlinguez',
    'bourrasques',
    'bourrassent',
    'bourrassiez',
    'bourratives',
    'bourrelâmes',
    'bourrelasse',
    'bourrelâtes',
    'bourreliers',
    'bourrelions',
    'bourrellent',
    'bourrellera',
    'bourrerions',
    'bourrichons',
    'bourriquais',
    'bourriquait',
    'bourriquant',
    'bourriquent',
    'bourriquera',
    'bourriquets',
    'bourriquiez',
    'bourriquons',
    'boursicotai',
    'boursicotas',
    'boursicotât',
    'boursicoter',
    'boursicotes',
    'boursicotez',
    'boursouflai',
    'boursouflas',
    'boursouflât',
    'boursouflée',
    'boursoufler',
    'boursoufles',
    'boursouflés',
    'boursouflez',
    'bousculades',
    'bousculâmes',
    'bousculasse',
    'bousculâtes',
    'bousculerai',
    'bousculeras',
    'bousculerez',
    'bousculions',
    'bousillages',
    'bousillâmes',
    'bousillasse',
    'bousillâtes',
    'bousillerai',
    'bousilleras',
    'bousillerez',
    'bousilleurs',
    'bousilleuse',
    'bousillions',
    'boutassions',
    'bout-dehors',
    'bouteillers',
    'bouteillons',
    'bouteraient',
    'bouterolles',
    'boutiquière',
    'boutiquiers',
    'boutonnages',
    'boutonnâmes',
    'boutonnasse',
    'boutonnâtes',
    'boutonnerai',
    'boutonneras',
    'boutonnerez',
    'boutonneuse',
    'boutonnière',
    'boutonniers',
    'boutonnions',
    'bouts-rimés',
    'bouturaient',
    'bouturasses',
    'bouturerais',
    'bouturerait',
    'bouturèrent',
    'boutureriez',
    'bouturerons',
    'boutureront',
    'bouveteuses',
    'bow-windows',
    'box-offices',
    'boyauderies',
    'boyaudières',
    'boycottages',
    'boycottâmes',
    'boycottasse',
    'boycottâtes',
    'boycotterai',
    'boycotteras',
    'boycotterez',
    'boycotteurs',
    'boycotteuse',
    'boycottions',
    'brabançonne',
    'braconnages',
    'braconnâmes',
    'braconnasse',
    'braconnâtes',
    'braconnerai',
    'braconneras',
    'braconnerez',
    'braconnière',
    'braconniers',
    'braconnions',
    'bradassions',
    'braderaient',
    'bradycardie',
    'brahmanique',
    'brahmanisme',
    'braieraient',
    'braillaient',
    'braillardes',
    'braillasses',
    'braillement',
    'braillerais',
    'braillerait',
    'braillèrent',
    'brailleriez',
    'braillerons',
    'brailleront',
    'brailleuses',
    'brain-trust',
    'braisassent',
    'braisassiez',
    'braiserions',
    'bramassions',
    'brameraient',
    'brancardier',
    'branchaient',
    'branchasses',
    'branchement',
    'brancherais',
    'brancherait',
    'branchèrent',
    'brancheriez',
    'brancherons',
    'brancheront',
    'branchettes',
    'branchiales',
    'brandebourg',
    'brandillais',
    'brandillait',
    'brandillant',
    'brandillées',
    'brandillent',
    'brandillera',
    'brandilliez',
    'brandillons',
    'brandirions',
    'brandissais',
    'brandissait',
    'brandissant',
    'brandissent',
    'brandissiez',
    'brandissons',
    'branlassent',
    'branlassiez',
    'branlements',
    'branlerions',
    'braquassent',
    'braquassiez',
    'braquemarts',
    'braquerions',
    'brasassions',
    'braseraient',
    'brasillâmes',
    'brasillasse',
    'brasillâtes',
    'brasillerai',
    'brasilleras',
    'brasillerez',
    'brasillions',
    'brassassent',
    'brassassiez',
    'brasserions',
    'bravassions',
    'braveraient',
    'brayassions',
    'brayeraient',
    'bredouillai',
    'bredouillas',
    'bredouillât',
    'bredouillée',
    'bredouiller',
    'bredouilles',
    'bredouillés',
    'bredouillez',
    'brélassions',
    'brêlassions',
    'bréleraient',
    'brêleraient',
    'brellassent',
    'brellassiez',
    'brellerions',
    'brésilienne',
    'brésillâmes',
    'brésillasse',
    'brésillâtes',
    'brésillerai',
    'brésilleras',
    'brésillerez',
    'brésillions',
    'bretonnante',
    'bretonnants',
    'brettassent',
    'brettassiez',
    'brettelâmes',
    'brettelasse',
    'brettelâtes',
    'brettelions',
    'brettellent',
    'brettellera',
    'bretterions',
    'brevetables',
    'brevetaient',
    'brevetasses',
    'brevetèrent',
    'brevetterai',
    'brevetteras',
    'brevetterez',
    'brévilignes',
    'bric-à-brac',
    'bricolaient',
    'bricolasses',
    'bricolerais',
    'bricolerait',
    'bricolèrent',
    'bricoleriez',
    'bricolerons',
    'bricoleront',
    'bricoleuses',
    'bridassions',
    'brideraient',
    'bridgeaient',
    'bridgeasses',
    'bridgerions',
    'brifassions',
    'briferaient',
    'briffassent',
    'briffassiez',
    'brifferions',
    'brigandages',
    'brigandâmes',
    'brigandasse',
    'brigandâtes',
    'briganderai',
    'briganderas',
    'briganderez',
    'brigandines',
    'brigandions',
    'brigantines',
    'brightismes',
    'briguassent',
    'briguassiez',
    'briguerions',
    'brillamment',
    'brillantage',
    'brillantais',
    'brillantait',
    'brillantant',
    'brillantées',
    'brillantent',
    'brillantera',
    'brillantiez',
    'brillantina',
    'brillantine',
    'brillantiné',
    'brillantons',
    'brillassent',
    'brillassiez',
    'brillerions',
    'brimassions',
    'brimbalâmes',
    'brimbalasse',
    'brimbalâtes',
    'brimbalerai',
    'brimbaleras',
    'brimbalerez',
    'brimbalions',
    'brimborions',
    'brimeraient',
    'brinquebala',
    'brinquebale',
    'brinquebalé',
    'briquassent',
    'briquassiez',
    'briquerions',
    'briquetages',
    'briquetâmes',
    'briquetasse',
    'briquetâtes',
    'briqueterie',
    'briquetiers',
    'briquetions',
    'briquettent',
    'briquettera',
    'brisassions',
    'brise-glace',
    'brise-lames',
    'briseraient',
    'britannique',
    'brocantages',
    'brocantâmes',
    'brocantasse',
    'brocantâtes',
    'brocanterai',
    'brocanteras',
    'brocanterez',
    'brocanteurs',
    'brocanteuse',
    'brocantions',
    'brocardâmes',
    'brocardasse',
    'brocardâtes',
    'brocarderai',
    'brocarderas',
    'brocarderez',
    'brocardions',
    'brocatelles',
    'brochassent',
    'brochassiez',
    'brocherions',
    'brodassions',
    'broderaient',
    'broieraient',
    'bronchaient',
    'bronchasses',
    'broncherais',
    'broncherait',
    'bronchèrent',
    'broncheriez',
    'broncherons',
    'broncheront',
    'bronchioles',
    'bronchiques',
    'bronchiteux',
    'brontosaure',
    'bronzassent',
    'bronzassiez',
    'bronzerions',
    'brossassent',
    'brossassiez',
    'brosserions',
    'brouettâmes',
    'brouettasse',
    'brouettâtes',
    'brouetterai',
    'brouetteras',
    'brouetterez',
    'brouettions',
    'brouillages',
    'brouillâmes',
    'brouillards',
    'brouillassa',
    'brouillasse',
    'brouillassé',
    'brouillâtes',
    'brouillerai',
    'brouilleras',
    'brouillerez',
    'brouillerie',
    'brouilleurs',
    'brouillions',
    'brouillonna',
    'brouillonne',
    'brouillonné',
    'broussaille',
    'broutassent',
    'broutassiez',
    'broutements',
    'brouterions',
    'broyassions',
    'brucelloses',
    'brugnoniers',
    'bruissaient',
    'bruissement',
    'bruitassent',
    'bruitassiez',
    'bruiterions',
    'brûlassions',
    'brûleraient',
    'brumisateur',
    'bruniraient',
    'brunissages',
    'brunissante',
    'brunissants',
    'brunisseurs',
    'brunisseuse',
    'brunissions',
    'brunissoirs',
    'brunissures',
    'brusquaient',
    'brusquasses',
    'brusquement',
    'brusquerais',
    'brusquerait',
    'brusquèrent',
    'brusqueries',
    'brusqueriez',
    'brusquerons',
    'brusqueront',
    'brutalement',
    'brutalisais',
    'brutalisait',
    'brutalisant',
    'brutalisées',
    'brutalisent',
    'brutalisera',
    'brutalisiez',
    'brutalisons',
    'buccinateur',
    'bûchassions',
    'bûcheraient',
    'bûcheronnes',
    'budgétaires',
    'budgétisais',
    'budgétisait',
    'budgétisant',
    'budgétisées',
    'budgétisent',
    'budgétisera',
    'budgétisiez',
    'budgétisons',
    'budgétivore',
    'buffetières',
    'buffleterie',
    'buissonneux',
    'buissonnier',
    'bureaucrate',
    'bureautique',
    'burinassent',
    'burinassiez',
    'burinerions',
    'businessman',
    'businessmen',
    'butinassent',
    'butinassiez',
    'butinerions',
    'buttassions',
    'butteraient',
    'butyromètre',
    'buvotassent',
    'buvotassiez',
    'buvoterions',
    'cabalassent',
    'cabalassiez',
    'cabalerions',
    'cabanassent',
    'cabanassiez',
    'cabanerions',
    'cabaretière',
    'cabaretiers',
    'câblassions',
    'câbleraient',
    'câblogramme',
    'cabochardes',
    'cabossaient',
    'cabossasses',
    'cabosserais',
    'cabosserait',
    'cabossèrent',
    'cabosseriez',
    'cabosserons',
    'cabosseront',
    'cabotassent',
    'cabotassiez',
    'caboterions',
    'cabotinages',
    'cabotinâmes',
    'cabotinasse',
    'cabotinâtes',
    'cabotinerai',
    'cabotineras',
    'cabotinerez',
    'cabotinions',
    'cabrassions',
    'cabreraient',
    'cabriolâmes',
    'cabriolasse',
    'cabriolâtes',
    'cabriolerai',
    'cabrioleras',
    'cabriolerez',
    'cabriolions',
    'cacabassent',
    'cacabassiez',
    'cacaberions',
    'cacahouètes',
    'cacaotières',
    'cacardaient',
    'cacardasses',
    'cacarderais',
    'cacarderait',
    'cacardèrent',
    'cacarderiez',
    'cacarderons',
    'cacarderont',
    'cachassions',
    'cache-cache',
    'cachectique',
    'cacheraient',
    'cachetaient',
    'cachetasses',
    'cachetèrent',
    'cachetterai',
    'cachetteras',
    'cachetterez',
    'cachotterie',
    'cachottière',
    'cachottiers',
    'cacodylates',
    'cacographes',
    'cacographie',
    'cacophonies',
    'cadanchâmes',
    'cadanchasse',
    'cadanchâtes',
    'cadancherai',
    'cadancheras',
    'cadancherez',
    'cadanchions',
    'cadastrales',
    'cadastrâmes',
    'cadastrasse',
    'cadastrâtes',
    'cadastrerai',
    'cadastreras',
    'cadastrerez',
    'cadastrions',
    'cadavéreuse',
    'cadavérique',
    'cadenassais',
    'cadenassait',
    'cadenassant',
    'cadenassées',
    'cadenassent',
    'cadenassera',
    'cadenassiez',
    'cadenassons',
    'cadençaient',
    'cadençasses',
    'cadencerais',
    'cadencerait',
    'cadencèrent',
    'cadenceriez',
    'cadencerons',
    'cadenceront',
    'cadmiassent',
    'cadmiassiez',
    'cadmierions',
    'cadrassions',
    'cadreraient',
    'cafardaient',
    'cafardasses',
    'cafarderais',
    'cafarderait',
    'cafardèrent',
    'cafarderiez',
    'cafarderons',
    'cafarderont',
    'cafardeuses',
    'cafouillage',
    'cafouillais',
    'cafouillait',
    'cafouillant',
    'cafouillent',
    'cafouillera',
    'cafouilliez',
    'cafouillons',
    'caftassions',
    'cafteraient',
    'cagnardâmes',
    'cagnardasse',
    'cagnardâtes',
    'cagnarderai',
    'cagnarderas',
    'cagnarderez',
    'cagnardions',
    'cagnassions',
    'cagneraient',
    'cagoulardes',
    'cahotassent',
    'cahotassiez',
    'cahotements',
    'cahoterions',
    'caillassent',
    'caillassiez',
    'caillebotis',
    'caillebotte',
    'caillebotté',
    'caillements',
    'caillerions',
    'cailletâmes',
    'cailletasse',
    'cailletâtes',
    'cailletions',
    'caillettent',
    'caillettera',
    'cailloutage',
    'cailloutais',
    'cailloutait',
    'cailloutant',
    'cailloutées',
    'cailloutent',
    'cailloutera',
    'caillouteux',
    'cailloutiez',
    'cailloutons',
    'cajeputiers',
    'cajolassent',
    'cajolassiez',
    'cajolerions',
    'calabraises',
    'calaminages',
    'calaminâmes',
    'calaminasse',
    'calaminâtes',
    'calaminerai',
    'calamineras',
    'calaminerez',
    'calaminions',
    'calamistrai',
    'calamistras',
    'calamistrât',
    'calamistrée',
    'calamistrer',
    'calamistres',
    'calamistrés',
    'calamistrez',
    'calamiteuse',
    'calanchâmes',
    'calanchasse',
    'calanchâtes',
    'calancherai',
    'calancheras',
    'calancherez',
    'calanchions',
    'calandrages',
    'calandrâmes',
    'calandrasse',
    'calandrâtes',
    'calandrerai',
    'calandreras',
    'calandrerez',
    'calandreurs',
    'calandreuse',
    'calandrions',
    'calcédoines',
    'calcéolaire',
    'calcinaient',
    'calcinasses',
    'calcination',
    'calcinerais',
    'calcinerait',
    'calcinèrent',
    'calcineriez',
    'calcinerons',
    'calcineront',
    'calculables',
    'calculaient',
    'calculasses',
    'calculateur',
    'calculerais',
    'calculerait',
    'calculèrent',
    'calculeriez',
    'calculerons',
    'calculeront',
    'calculettes',
    'calculeuses',
    'calebassier',
    'calédoniens',
    'calendriers',
    'caletassent',
    'caletassiez',
    'caletterais',
    'caletterait',
    'caletteriez',
    'caletterons',
    'caletteront',
    'calfataient',
    'calfatasses',
    'calfaterais',
    'calfaterait',
    'calfatèrent',
    'calfateriez',
    'calfaterons',
    'calfateront',
    'calfeutrage',
    'calfeutrais',
    'calfeutrait',
    'calfeutrant',
    'calfeutrées',
    'calfeutrent',
    'calfeutrera',
    'calfeutriez',
    'calfeutrons',
    'calibraient',
    'calibrasses',
    'calibration',
    'calibrerais',
    'calibrerait',
    'calibrèrent',
    'calibreriez',
    'calibrerons',
    'calibreront',
    'calibreuses',
    'californium',
    'câlinassent',
    'câlinassiez',
    'câlinerions',
    'calligramme',
    'calligraphe',
    'calmassions',
    'calmeraient',
    'calmiraient',
    'calmissions',
    'calomniâmes',
    'calomniasse',
    'calomniâtes',
    'calomnierai',
    'calomnieras',
    'calomnierez',
    'calomnieuse',
    'calomniions',
    'calorifères',
    'calorifique',
    'calorifugea',
    'calorifugée',
    'calorifuger',
    'calorifuges',
    'calorifugés',
    'calorifugez',
    'calorimètre',
    'calottaient',
    'calottasses',
    'calotterais',
    'calotterait',
    'calottèrent',
    'calotteriez',
    'calotterons',
    'calotteront',
    'calquassent',
    'calquassiez',
    'calquerions',
    'caltassions',
    'calteraient',
    'camaraderie',
    'cambodgiens',
    'cambrassent',
    'cambrassiez',
    'cambrements',
    'cambrerions',
    'cambriennes',
    'cambriolage',
    'cambriolais',
    'cambriolait',
    'cambriolant',
    'cambriolées',
    'cambriolent',
    'cambriolera',
    'cambrioleur',
    'cambrioliez',
    'cambriolons',
    'cambrousard',
    'cambrousses',
    'cambutaient',
    'cambutasses',
    'cambuterais',
    'cambuterait',
    'cambutèrent',
    'cambuteriez',
    'cambuterons',
    'cambuteront',
    'camelotâmes',
    'camelotasse',
    'camelotâtes',
    'cameloterai',
    'cameloteras',
    'cameloterez',
    'camelotions',
    'camerlingue',
    'camionnages',
    'camionnâmes',
    'camionnasse',
    'camionnâtes',
    'camionnerai',
    'camionneras',
    'camionnerez',
    'camionnette',
    'camionneurs',
    'camionnions',
    'camouflages',
    'camouflâmes',
    'camouflante',
    'camouflants',
    'camouflasse',
    'camouflâtes',
    'camouflerai',
    'camoufleras',
    'camouflerez',
    'camouflions',
    'campagnarde',
    'campagnards',
    'campassions',
    'camperaient',
    'camping-car',
    'camping-gaz',
    'canadiennes',
    'canaillerie',
    'canalicules',
    'canalisable',
    'canalisâmes',
    'canalisasse',
    'canalisâtes',
    'canaliserai',
    'canaliseras',
    'canaliserez',
    'canalisions',
    'cananéennes',
    'canardaient',
    'canardasses',
    'canarderais',
    'canarderait',
    'canardèrent',
    'canarderiez',
    'canarderons',
    'canarderont',
    'canardières',
    'cancanaient',
    'cancanasses',
    'cancanerais',
    'cancanerait',
    'cancanèrent',
    'cancaneriez',
    'cancanerons',
    'cancaneront',
    'cancanières',
    'cancéreuses',
    'cancérigène',
    'candélabres',
    'candidature',
    'candidement',
    'candiraient',
    'candissions',
    'canepetière',
    'caniculaire',
    'cannassions',
    'cannelaient',
    'cannelasses',
    'cannelèrent',
    'cannellerai',
    'cannelleras',
    'cannellerez',
    'cannellonis',
    'canneraient',
    'cannetilles',
    'canonisable',
    'canonisâmes',
    'canonisasse',
    'canonisâtes',
    'canoniserai',
    'canoniseras',
    'canoniserez',
    'canonisions',
    'canonnaient',
    'canonnasses',
    'canonnerais',
    'canonnerait',
    'canonnèrent',
    'canonneriez',
    'canonnerons',
    'canonneront',
    'canonnières',
    'canotassent',
    'canotassiez',
    'canoterions',
    'cantatrices',
    'cantharides',
    'cantilevers',
    'cantinières',
    'cantonnâmes',
    'cantonnasse',
    'cantonnâtes',
    'cantonnerai',
    'cantonneras',
    'cantonnerez',
    'cantonnière',
    'cantonniers',
    'cantonnions',
    'canulassent',
    'canulassiez',
    'canulerions',
    'caoutchoucs',
    'caoutchouta',
    'caoutchoute',
    'caoutchouté',
    'caparaçonna',
    'caparaçonne',
    'caparaçonné',
    'capéassions',
    'capéeraient',
    'capelassent',
    'capelassiez',
    'capellerais',
    'capellerait',
    'capelleriez',
    'capellerons',
    'capelleront',
    'capésiennes',
    'capétiennes',
    'capeyassent',
    'capeyassiez',
    'capeyerions',
    'capharnaüms',
    'capillaires',
    'capillarite',
    'capillarité',
    'capilotades',
    'capitalisai',
    'capitalisas',
    'capitalisât',
    'capitalisée',
    'capitaliser',
    'capitalises',
    'capitalisés',
    'capitalisez',
    'capitalisme',
    'capitaliste',
    'capitolines',
    'capitonnage',
    'capitonnais',
    'capitonnait',
    'capitonnant',
    'capitonnées',
    'capitonnent',
    'capitonnera',
    'capitonniez',
    'capitonnons',
    'capitulaire',
    'capitulâmes',
    'capitularde',
    'capitulards',
    'capitulasse',
    'capitulâtes',
    'capitulerai',
    'capituleras',
    'capitulerez',
    'capitulions',
    'caponnaient',
    'caponnasses',
    'caponnerais',
    'caponnerait',
    'caponnèrent',
    'caponneriez',
    'caponnerons',
    'caponneront',
    'caponnières',
    'caporalisai',
    'caporalisas',
    'caporalisât',
    'caporalisée',
    'caporaliser',
    'caporalises',
    'caporalisés',
    'caporalisez',
    'caporalisme',
    'capotassent',
    'capotassiez',
    'capoterions',
    'capricantes',
    'capricieuse',
    'capricornes',
    'capryliques',
    'capsulaient',
    'capsulaires',
    'capsulasses',
    'capsulerais',
    'capsulerait',
    'capsulèrent',
    'capsuleriez',
    'capsulerons',
    'capsuleront',
    'captassions',
    'captativité',
    'captatoires',
    'captatrices',
    'capteraient',
    'captivaient',
    'captivantes',
    'captivasses',
    'captiverais',
    'captiverait',
    'captivèrent',
    'captiveriez',
    'captiverons',
    'captiveront',
    'capturaient',
    'capturasses',
    'capturerais',
    'capturerait',
    'capturèrent',
    'captureriez',
    'capturerons',
    'captureront',
    'capuchonnai',
    'capuchonnas',
    'capuchonnât',
    'capuchonnée',
    'capuchonner',
    'capuchonnes',
    'capuchonnés',
    'capuchonnez',
    'capucinades',
    'caquassions',
    'caqueraient',
    'caquetaient',
    'caquetantes',
    'caquetasses',
    'caquètement',
    'caquetèrent',
    'caquetterai',
    'caquetteras',
    'caquetterez',
    'carabiniers',
    'caracolâmes',
    'caracolasse',
    'caracolâtes',
    'caracolerai',
    'caracoleras',
    'caracolerez',
    'caracolions',
    'caractériel',
    'caractérisa',
    'caractérise',
    'caractérisé',
    'carambolage',
    'carambolais',
    'carambolait',
    'carambolant',
    'carambolées',
    'carambolent',
    'carambolera',
    'caramboliez',
    'carambolons',
    'caramélisai',
    'caramélisas',
    'caramélisât',
    'caramélisée',
    'caraméliser',
    'caramélises',
    'caramélisés',
    'caramélisez',
    'carapatâmes',
    'carapatasse',
    'carapatâtes',
    'carapaterai',
    'carapateras',
    'carapaterez',
    'carapations',
    'caravanages',
    'caravanière',
    'caravaniers',
    'carbonatais',
    'carbonatait',
    'carbonatant',
    'carbonatées',
    'carbonatent',
    'carbonatera',
    'carbonatiez',
    'carbonatons',
    'carbonifère',
    'carboniques',
    'carbonisais',
    'carbonisait',
    'carbonisant',
    'carbonisées',
    'carbonisent',
    'carbonisera',
    'carbonisiez',
    'carbonisons',
    'carbonnades',
    'carborundum',
    'carboxylase',
    'carburaient',
    'carburasses',
    'carburateur',
    'carburation',
    'carburerais',
    'carburerait',
    'carburèrent',
    'carbureriez',
    'carburerons',
    'carbureront',
    'carcaillais',
    'carcaillait',
    'carcaillant',
    'carcaillent',
    'carcaillera',
    'carcailliez',
    'carcaillons',
    'carcinogène',
    'cardassions',
    'carderaient',
    'cardialgies',
    'cardinalats',
    'cardinalice',
    'cardiologie',
    'cardiologue',
    'cardiotomie',
    'carénassent',
    'carénassiez',
    'carençaient',
    'carençasses',
    'carencerais',
    'carencerait',
    'carencèrent',
    'carenceriez',
    'carencerons',
    'carenceront',
    'carénerions',
    'carentielle',
    'caressaient',
    'caressantes',
    'caressasses',
    'caresserais',
    'caresserait',
    'caressèrent',
    'caresseriez',
    'caresserons',
    'caresseront',
    'car-ferries',
    'carguassent',
    'carguassiez',
    'carguerions',
    'cariassions',
    'caricaturai',
    'caricatural',
    'caricaturas',
    'caricaturât',
    'caricaturée',
    'caricaturer',
    'caricatures',
    'caricaturés',
    'caricaturez',
    'carieraient',
    'carillonnai',
    'carillonnas',
    'carillonnât',
    'carillonnée',
    'carillonner',
    'carillonnes',
    'carillonnés',
    'carillonnez',
    'caritatives',
    'carlinguier',
    'carmagnoles',
    'carmassions',
    'carmeraient',
    'carminaient',
    'carminasses',
    'carminatifs',
    'carminative',
    'carminerais',
    'carminerait',
    'carminèrent',
    'carmineriez',
    'carminerons',
    'carmineront',
    'carnassière',
    'carnassiers',
    'carnifiâmes',
    'carnifiasse',
    'carnifiâtes',
    'carnifierai',
    'carnifieras',
    'carnifierez',
    'carnifiions',
    'carolingien',
    'carottaient',
    'carottasses',
    'carotterais',
    'carotterait',
    'carottèrent',
    'carotteriez',
    'carotterons',
    'carotteront',
    'carotteuses',
    'carottières',
    'carpatiques',
    'carpocapses',
    'carrassions',
    'carrelaient',
    'carrelasses',
    'carrelèrent',
    'carrellerai',
    'carrelleras',
    'carrellerez',
    'carreraient',
    'carriérisme',
    'carriériste',
    'carroierais',
    'carroierait',
    'carroieriez',
    'carroierons',
    'carroieront',
    'carrossable',
    'carrossages',
    'carrossâmes',
    'carrossasse',
    'carrossâtes',
    'carrosserai',
    'carrosseras',
    'carrosserez',
    'carrosserie',
    'carrossiers',
    'carrossions',
    'carroyaient',
    'carroyasses',
    'carroyèrent',
    'cartésienne',
    'cartographe',
    'cartomancie',
    'cartonnages',
    'cartonnâmes',
    'cartonnasse',
    'cartonnâtes',
    'cartonnerai',
    'cartonneras',
    'cartonnerez',
    'cartonnerie',
    'cartonneuse',
    'cartonnière',
    'cartonniers',
    'cartonnions',
    'carton-pâte',
    'cartothèque',
    'cascadaient',
    'cascadasses',
    'cascaderais',
    'cascaderait',
    'cascadèrent',
    'cascaderiez',
    'cascaderons',
    'cascaderont',
    'cascadeuses',
    'cascatelles',
    'caséfiaient',
    'caséfiasses',
    'caséfierais',
    'caséfierait',
    'caséfièrent',
    'caséfieriez',
    'caséfierons',
    'caséfieront',
    'casematâmes',
    'casematasse',
    'casematâtes',
    'casematerai',
    'casemateras',
    'casematerez',
    'casemations',
    'casernaient',
    'casernasses',
    'casernement',
    'casernerais',
    'casernerait',
    'casernèrent',
    'caserneriez',
    'casernerons',
    'caserneront',
    'casquassent',
    'casquassiez',
    'casquerions',
    'cassassions',
    'casse-pieds',
    'casse-pipes',
    'casseraient',
    'cassitérite',
    'cassolettes',
    'castagnâmes',
    'castagnasse',
    'castagnâtes',
    'castagnerai',
    'castagneras',
    'castagnerez',
    'castagnions',
    'castillanes',
    'castrassent',
    'castrassiez',
    'castrateurs',
    'castrations',
    'castratrice',
    'castrerions',
    'casuistique',
    'catabolisme',
    'catachrèses',
    'cataclysmes',
    'catadioptre',
    'catafalques',
    'catalepsies',
    'cataloguais',
    'cataloguait',
    'cataloguant',
    'cataloguées',
    'cataloguent',
    'cataloguera',
    'cataloguiez',
    'cataloguons',
    'catalysâmes',
    'catalysasse',
    'catalysâtes',
    'catalyserai',
    'catalyseras',
    'catalyserez',
    'catalyseurs',
    'catalysions',
    'catalytique',
    'cataplasmes',
    'catapultage',
    'catapultais',
    'catapultait',
    'catapultant',
    'catapultées',
    'catapultent',
    'catapultera',
    'catapultiez',
    'catapultons',
    'catarrhales',
    'catarrheuse',
    'catastropha',
    'catastrophe',
    'catastrophé',
    'catchassent',
    'catchassiez',
    'catcherions',
    'catéchisais',
    'catéchisait',
    'catéchisant',
    'catéchisées',
    'catéchisent',
    'catéchisera',
    'catéchisiez',
    'catéchismes',
    'catéchisons',
    'catéchistes',
    'catéchumène',
    'catégorèmes',
    'catégoriels',
    'catégorique',
    'catharsique',
    'cathédrales',
    'cathodiques',
    'catholicité',
    'catholiques',
    'catilinaire',
    'catoptrique',
    'caucasienne',
    'cauchemarda',
    'cauchemarde',
    'cauchemardé',
    'caudataires',
    'caulescente',
    'caulescents',
    'causalismes',
    'causassions',
    'causeraient',
    'causticités',
    'cauteleuses',
    'cautérisais',
    'cautérisait',
    'cautérisant',
    'cautérisées',
    'cautérisent',
    'cautérisera',
    'cautérisiez',
    'cautérisons',
    'cautionnais',
    'cautionnait',
    'cautionnant',
    'cautionnées',
    'cautionnent',
    'cautionnera',
    'cautionniez',
    'cautionnons',
    'cavalassent',
    'cavalassiez',
    'cavalcadais',
    'cavalcadait',
    'cavalcadant',
    'cavalcadent',
    'cavalcadera',
    'cavalcadiez',
    'cavalcadons',
    'cavalerions',
    'caverneuses',
    'cavernicole',
    'caviardâmes',
    'caviardasse',
    'caviardâtes',
    'caviarderai',
    'caviarderas',
    'caviarderez',
    'caviardions',
    'cavitations',
    'cégésimales',
    'ceignissent',
    'ceignissiez',
    'ceindraient',
    'ceinturages',
    'ceinturâmes',
    'ceinturasse',
    'ceinturâtes',
    'ceinturerai',
    'ceintureras',
    'ceinturerez',
    'ceinturions',
    'célébraient',
    'célébrasses',
    'célébration',
    'célébrerais',
    'célébrerait',
    'célébrèrent',
    'célébreriez',
    'célébrerons',
    'célébreront',
    'célérifères',
    'célibataire',
    'cellérières',
    'cellophanes',
    'cellulaires',
    'cémentaient',
    'cémentasses',
    'cémentation',
    'cémenterais',
    'cémenterait',
    'cémentèrent',
    'cémenteriez',
    'cémenterons',
    'cémenteront',
    'cendrassent',
    'cendrassiez',
    'cendrerions',
    'cendrillons',
    'cénesthésie',
    'cénobitique',
    'cénobitisme',
    'censitaires',
    'censoriales',
    'censuraient',
    'censurasses',
    'censurerais',
    'censurerait',
    'censurèrent',
    'censureriez',
    'censurerons',
    'censureront',
    'centenaires',
    'centennales',
    'centésimale',
    'centésimaux',
    'centigrades',
    'centigramme',
    'centilitres',
    'centimètres',
    'centralisai',
    'centralisas',
    'centralisât',
    'centralisée',
    'centraliser',
    'centralises',
    'centralisés',
    'centralisez',
    'centralisme',
    'centrassent',
    'centrassiez',
    'centrerions',
    'centrifugea',
    'centrifugée',
    'centrifuger',
    'centrifuges',
    'centrifugés',
    'centrifugez',
    'centripètes',
    'centrosomes',
    'centuplâmes',
    'centuplasse',
    'centuplâtes',
    'centuplerai',
    'centupleras',
    'centuplerez',
    'centuplions',
    'céphalalgie',
    'céphaliques',
    'cerclassent',
    'cerclassiez',
    'cerclerions',
    'cérébelleux',
    'cérébralité',
    'cérémonials',
    'cérémoniels',
    'cérémonieux',
    'cerf-volant',
    'cernassions',
    'cerneraient',
    'certifiâmes',
    'certifiasse',
    'certifiâtes',
    'certificats',
    'certifierai',
    'certifieras',
    'certifierez',
    'certifiions',
    'céruléennes',
    'cérumineuse',
    'césariennes',
    'cessassions',
    'cesseraient',
    'cessibilité',
    'chablassent',
    'chablassiez',
    'chablerions',
    'chagrinâmes',
    'chagrinante',
    'chagrinants',
    'chagrinasse',
    'chagrinâtes',
    'chagrinerai',
    'chagrineras',
    'chagrinerez',
    'chagrinions',
    'chahutaient',
    'chahutantes',
    'chahutasses',
    'chahuterais',
    'chahuterait',
    'chahutèrent',
    'chahuteriez',
    'chahuterons',
    'chahuteront',
    'chahuteuses',
    'chaînassent',
    'chaînassiez',
    'chaînerions',
    'chalcosines',
    'chaldéennes',
    'chaleureuse',
    'challengeai',
    'challengeas',
    'challengeât',
    'challengées',
    'challengent',
    'challengera',
    'challengers',
    'challengeur',
    'challengiez',
    'chaloupâmes',
    'chaloupasse',
    'chaloupâtes',
    'chalouperai',
    'chalouperas',
    'chalouperez',
    'chaloupions',
    'chamaillais',
    'chamaillait',
    'chamaillant',
    'chamaillées',
    'chamaillent',
    'chamaillera',
    'chamailleur',
    'chamailliez',
    'chamaillons',
    'chamanismes',
    'chamarrâmes',
    'chamarrasse',
    'chamarrâtes',
    'chamarrerai',
    'chamarreras',
    'chamarrerez',
    'chamarrions',
    'chamarrures',
    'chambardais',
    'chambardait',
    'chambardant',
    'chambardées',
    'chambardent',
    'chambardera',
    'chambardiez',
    'chambardons',
    'chambellans',
    'chambertins',
    'chamboulais',
    'chamboulait',
    'chamboulant',
    'chamboulées',
    'chamboulent',
    'chamboulera',
    'chambouliez',
    'chamboulons',
    'chambraient',
    'chambranles',
    'chambrasses',
    'chambrerais',
    'chambrerait',
    'chambrèrent',
    'chambreriez',
    'chambrerons',
    'chambreront',
    'chambrettes',
    'chambrières',
    'chamoisages',
    'chamoisâmes',
    'chamoisasse',
    'chamoisâtes',
    'chamoiserai',
    'chamoiseras',
    'chamoiserez',
    'chamoiserie',
    'chamoisions',
    'champagnisa',
    'champagnise',
    'champagnisé',
    'champenoise',
    'champignons',
    'championnat',
    'championnes',
    'champlevais',
    'champlevait',
    'champlevant',
    'champlevées',
    'champlèvent',
    'champlèvera',
    'champleviez',
    'champlevons',
    'chancelâmes',
    'chancelante',
    'chancelants',
    'chancelasse',
    'chancelâtes',
    'chancelière',
    'chanceliers',
    'chancelions',
    'chancellent',
    'chancellera',
    'chancirions',
    'chancissais',
    'chancissait',
    'chancissant',
    'chancissent',
    'chancissiez',
    'chancissons',
    'chandeleurs',
    'chandeliers',
    'chanfreinai',
    'chanfreinas',
    'chanfreinât',
    'chanfreinée',
    'chanfreiner',
    'chanfreines',
    'chanfreinés',
    'chanfreinez',
    'changeables',
    'changeaient',
    'changeantes',
    'changeasses',
    'changements',
    'changerions',
    'chanoinesse',
    'chansonnais',
    'chansonnait',
    'chansonnant',
    'chansonnées',
    'chansonnent',
    'chansonnera',
    'chansonnier',
    'chansonniez',
    'chansonnons',
    'chanstiquai',
    'chanstiquas',
    'chanstiquât',
    'chanstiquée',
    'chanstiquer',
    'chanstiques',
    'chanstiqués',
    'chanstiquez',
    'chantassent',
    'chantassiez',
    'chantefable',
    'chanterelle',
    'chanterions',
    'chantignole',
    'chantonnais',
    'chantonnait',
    'chantonnant',
    'chantonnées',
    'chantonnent',
    'chantonnera',
    'chantonniez',
    'chantonnons',
    'chantournai',
    'chantournas',
    'chantournât',
    'chantournée',
    'chantourner',
    'chantournes',
    'chantournés',
    'chantournez',
    'chanvrières',
    'chapardages',
    'chapardâmes',
    'chapardasse',
    'chapardâtes',
    'chaparderai',
    'chaparderas',
    'chaparderez',
    'chapardeurs',
    'chapardeuse',
    'chapardions',
    'chapeautais',
    'chapeautait',
    'chapeautant',
    'chapeautées',
    'chapeautent',
    'chapeautera',
    'chapeautiez',
    'chapeautons',
    'chapelaient',
    'chapelasses',
    'chapelèrent',
    'chapelières',
    'chapellenie',
    'chapellerai',
    'chapelleras',
    'chapellerez',
    'chapellerie',
    'chaperonnai',
    'chaperonnas',
    'chaperonnât',
    'chaperonnée',
    'chaperonner',
    'chaperonnes',
    'chaperonnés',
    'chaperonnez',
    'chapitrâmes',
    'chapitrasse',
    'chapitrâtes',
    'chapitrerai',
    'chapitreras',
    'chapitrerez',
    'chapitrions',
    'chaponnâmes',
    'chaponnasse',
    'chaponnâtes',
    'chaponnerai',
    'chaponneras',
    'chaponnerez',
    'chaponnions',
    'chaptalisai',
    'chaptalisas',
    'chaptalisât',
    'chaptalisée',
    'chaptaliser',
    'chaptalises',
    'chaptalisés',
    'chaptalisez',
    'charançonné',
    'charbonnage',
    'charbonnais',
    'charbonnait',
    'charbonnant',
    'charbonnées',
    'charbonnent',
    'charbonnera',
    'charbonneux',
    'charbonnier',
    'charbonniez',
    'charbonnons',
    'charcutâmes',
    'charcutasse',
    'charcutâtes',
    'charcuterai',
    'charcuteras',
    'charcuterez',
    'charcuterie',
    'charcutière',
    'charcutiers',
    'charcutions',
    'chargeaient',
    'chargeasses',
    'chargements',
    'chargerions',
    'chariotages',
    'charitables',
    'charlestons',
    'charmassent',
    'charmassiez',
    'charmerions',
    'charognards',
    'charpentage',
    'charpentais',
    'charpentait',
    'charpentant',
    'charpentées',
    'charpentent',
    'charpentera',
    'charpentier',
    'charpentiez',
    'charpentons',
    'charretière',
    'charretiers',
    'charriaient',
    'charriasses',
    'charrierais',
    'charrierait',
    'charrièrent',
    'charrieriez',
    'charrierons',
    'charrieront',
    'charroierai',
    'charroieras',
    'charroierez',
    'charronnage',
    'charroyâmes',
    'charroyasse',
    'charroyâtes',
    'charroyions',
    'chartreuses',
    'chassassent',
    'chassassiez',
    'chasse-clou',
    'chasseresse',
    'chasserions',
    'chasse-roue',
    'chassieuses',
    'chasublerie',
    'châtaigners',
    'châtaignier',
    'châtelaines',
    'châtellenie',
    'châtiassent',
    'châtiassiez',
    'châtierions',
    'chatoiement',
    'chatoierais',
    'chatoierait',
    'chatoieriez',
    'chatoierons',
    'chatoieront',
    'chatonnâmes',
    'chatonnasse',
    'chatonnâtes',
    'chatonnerai',
    'chatonneras',
    'chatonnerez',
    'chatonnions',
    'chatouillai',
    'chatouillas',
    'chatouillât',
    'chatouillée',
    'chatouiller',
    'chatouilles',
    'chatouillés',
    'chatouillez',
    'chatouillis',
    'chatoyaient',
    'chatoyasses',
    'chatoyèrent',
    'châtrassent',
    'châtrassiez',
    'châtrerions',
    'chattemites',
    'chattertons',
    'chaud-froid',
    'chauffaient',
    'chauffantes',
    'chauffasses',
    'chauffe-eau',
    'chaufferais',
    'chaufferait',
    'chauffèrent',
    'chaufferies',
    'chaufferiez',
    'chaufferons',
    'chaufferont',
    'chauffeuses',
    'chaulassent',
    'chaulassiez',
    'chaulerions',
    'chaumassent',
    'chaumassiez',
    'chaumerions',
    'chaussaient',
    'chaussantes',
    'chaussasses',
    'chausserais',
    'chausserait',
    'chaussèrent',
    'chausseriez',
    'chausserons',
    'chausseront',
    'chaussettes',
    'chauvinisme',
    'chauvirions',
    'chauvissais',
    'chauvissait',
    'chauvissant',
    'chauvissent',
    'chauvissiez',
    'chauvissons',
    'chaviraient',
    'chavirasses',
    'chavirement',
    'chavirerais',
    'chavirerait',
    'chavirèrent',
    'chavireriez',
    'chavirerons',
    'chavireront',
    'check-lists',
    'chefs-lieux',
    'chélidoines',
    'chelinguais',
    'chelinguait',
    'chelinguant',
    'chelinguées',
    'chelinguent',
    'chelinguera',
    'chelinguiez',
    'chelinguons',
    'chelléennes',
    'cheminaient',
    'cheminasses',
    'cheminement',
    'cheminerais',
    'cheminerait',
    'cheminèrent',
    'chemineriez',
    'cheminerons',
    'chemineront',
    'chemisaient',
    'chemisasses',
    'chemiserais',
    'chemiserait',
    'chemisèrent',
    'chemiseries',
    'chemiseriez',
    'chemiserons',
    'chemiseront',
    'chemisettes',
    'chemisières',
    'chêne-liège',
    'chènevières',
    'chenillette',
    'chérassions',
    'cherchaient',
    'cherchasses',
    'chercherais',
    'chercherait',
    'cherchèrent',
    'chercheriez',
    'chercherons',
    'chercheront',
    'chercheuses',
    'chéreraient',
    'chérifienne',
    'chériraient',
    'chérissions',
    'cherrassent',
    'cherrassiez',
    'cherrerions',
    'chétivement',
    'chevalaient',
    'chevalasses',
    'chevalement',
    'chevalerais',
    'chevalerait',
    'chevalèrent',
    'chevaleries',
    'chevaleriez',
    'chevalerons',
    'chevaleront',
    'chevalières',
    'chevauchais',
    'chevauchait',
    'chevauchant',
    'chevauchées',
    'chevauchent',
    'chevauchera',
    'chevauchiez',
    'chevauchons',
    'chevillâmes',
    'chevillards',
    'chevillasse',
    'chevillâtes',
    'chevillerai',
    'chevilleras',
    'chevillerez',
    'chevillions',
    'chèvre-pied',
    'chevretâmes',
    'chevretasse',
    'chevretâtes',
    'chevretions',
    'chevrettent',
    'chevrettera',
    'chevronnais',
    'chevronnait',
    'chevronnant',
    'chevronnées',
    'chevronnent',
    'chevronnera',
    'chevronniez',
    'chevronnons',
    'chevrotâmes',
    'chevrotante',
    'chevrotants',
    'chevrotasse',
    'chevrotâtes',
    'chevroterai',
    'chevroteras',
    'chevroterez',
    'chevrotines',
    'chevrotions',
    'chewing-gum',
    'chiadassent',
    'chiadassiez',
    'chiaderions',
    'chialassent',
    'chialassiez',
    'chialerions',
    'chicanaient',
    'chicanasses',
    'chicanerais',
    'chicanerait',
    'chicanèrent',
    'chicaneries',
    'chicaneriez',
    'chicanerons',
    'chicaneront',
    'chicaneuses',
    'chicanières',
    'chichiteuse',
    'chicotaient',
    'chicotasses',
    'chicoterais',
    'chicoterait',
    'chicotèrent',
    'chicoteriez',
    'chicoterons',
    'chicoteront',
    'chiennaient',
    'chiennasses',
    'chiennerais',
    'chiennerait',
    'chiennèrent',
    'chienneries',
    'chienneriez',
    'chiennerons',
    'chienneront',
    'chiffonnade',
    'chiffonnage',
    'chiffonnais',
    'chiffonnait',
    'chiffonnant',
    'chiffonnées',
    'chiffonnent',
    'chiffonnera',
    'chiffonnier',
    'chiffonniez',
    'chiffonnons',
    'chiffrables',
    'chiffraient',
    'chiffrasses',
    'chiffrement',
    'chiffrerais',
    'chiffrerait',
    'chiffrèrent',
    'chiffreriez',
    'chiffrerons',
    'chiffreront',
    'chiffreuses',
    'chimériques',
    'chinassions',
    'chinchillas',
    'chineraient',
    'chinoisâmes',
    'chinoisasse',
    'chinoisâtes',
    'chinoiserai',
    'chinoiseras',
    'chinoiserez',
    'chinoiserie',
    'chinoisions',
    'chipassions',
    'chiperaient',
    'chipotaient',
    'chipotasses',
    'chipoterais',
    'chipoterait',
    'chipotèrent',
    'chipoteriez',
    'chipoterons',
    'chipoteront',
    'chipoteuses',
    'chiquassent',
    'chiquassiez',
    'chiquenaude',
    'chiquerions',
    'chiromancie',
    'chiropraxie',
    'chirurgical',
    'chirurgiens',
    'chitineuses',
    'chlinguâmes',
    'chlinguasse',
    'chlinguâtes',
    'chlinguerai',
    'chlingueras',
    'chlinguerez',
    'chlinguions',
    'chlorassent',
    'chlorassiez',
    'chlorerions',
    'chloroforma',
    'chloroforme',
    'chloroformé',
    'chlorotique',
    'chlorurâmes',
    'chlorurasse',
    'chlorurâtes',
    'chlorurerai',
    'chlorureras',
    'chlorurerez',
    'chlorurions',
    'chocolatées',
    'chocolatier',
    'choieraient',
    'choisirions',
    'choisissais',
    'choisissait',
    'choisissant',
    'choisissent',
    'choisissiez',
    'choisissons',
    'cholagogues',
    'cholédoques',
    'cholériques',
    'cholestérol',
    'chômassions',
    'chômeraient',
    'chondriomes',
    'chopassions',
    'choperaient',
    'chopinaient',
    'chopinasses',
    'chopinerais',
    'chopinerait',
    'chopinèrent',
    'chopineriez',
    'chopinerons',
    'chopineront',
    'choppassent',
    'choppassiez',
    'chopperions',
    'choquassent',
    'choquassiez',
    'choquerions',
    'chorégraphe',
    'choroïdiens',
    'chosifiâmes',
    'chosifiasse',
    'chosifiâtes',
    'chosifierai',
    'chosifieras',
    'chosifierez',
    'chosifiions',
    'chouannerie',
    'chouchoutai',
    'chouchoutas',
    'chouchoutât',
    'chouchoutée',
    'chouchouter',
    'chouchoutes',
    'chouchoutés',
    'chouchoutez',
    'choucroutes',
    'chouravâmes',
    'chouravasse',
    'chouravâtes',
    'chouraverai',
    'chouraveras',
    'chouraverez',
    'chouravions',
    'chourinâmes',
    'chourinasse',
    'chourinâtes',
    'chourinerai',
    'chourineras',
    'chourinerez',
    'chourinions',
    'choux-raves',
    'chows-chows',
    'choyassions',
    'chrétiennes',
    'chrétientés',
    'chris-craft',
    'christiania',
    'christiques',
    'chromassent',
    'chromassiez',
    'chromatines',
    'chromatique',
    'chromatisme',
    'chromerions',
    'chromogènes',
    'chromosomes',
    'chromotypie',
    'chronicités',
    'chroniquais',
    'chroniquait',
    'chroniquant',
    'chroniquent',
    'chroniquera',
    'chroniqueur',
    'chroniquiez',
    'chroniquons',
    'chronologie',
    'chronométra',
    'chronométré',
    'chronomètre',
    'chroumaient',
    'chroumasses',
    'chroumèrent',
    'chrysalides',
    'chrysobéryl',
    'chrysocales',
    'chrysolithe',
    'chrysomèles',
    'chrysoprase',
    'chthonienne',
    'chuchotâmes',
    'chuchotasse',
    'chuchotâtes',
    'chuchoterai',
    'chuchoteras',
    'chuchoterez',
    'chuchoterie',
    'chuchotions',
    'chuintaient',
    'chuintasses',
    'chuintement',
    'chuinterais',
    'chuinterait',
    'chuintèrent',
    'chuinteriez',
    'chuinterons',
    'chuinteront',
    'chutassions',
    'chuteraient',
    'ciboulettes',
    'cicatriciel',
    'cicatricule',
    'cicatrisais',
    'cicatrisait',
    'cicatrisant',
    'cicatrisées',
    'cicatrisent',
    'cicatrisera',
    'cicatrisiez',
    'cicatrisons',
    'cicéroniens',
    'ci-incluses',
    'cillassions',
    'cilleraient',
    'cimentaient',
    'cimentasses',
    'cimentation',
    'cimenterais',
    'cimenterait',
    'cimentèrent',
    'cimenteries',
    'cimenteriez',
    'cimenterons',
    'cimenteront',
    'cinchonines',
    'cinémascope',
    'cinématique',
    'cinémomètre',
    'ciné-romans',
    'cingalaises',
    'cinglassent',
    'cinglassiez',
    'cinglerions',
    'cintrassent',
    'cintrassiez',
    'cintrerions',
    'circadienne',
    'circoncîmes',
    'circoncirai',
    'circonciras',
    'circoncirez',
    'circoncisez',
    'circoncisse',
    'circoncîtes',
    'circonflexe',
    'circonscris',
    'circonscrit',
    'circonspect',
    'circonvenez',
    'circonvenir',
    'circonvenue',
    'circonvenus',
    'circonviens',
    'circonvient',
    'circulaient',
    'circulaires',
    'circularité',
    'circulasses',
    'circulation',
    'circulerais',
    'circulerait',
    'circulèrent',
    'circuleriez',
    'circulerons',
    'circuleront',
    'cisaillâmes',
    'cisaillasse',
    'cisaillâtes',
    'cisaillerai',
    'cisailleras',
    'cisaillerez',
    'cisaillions',
    'ciselassent',
    'ciselassiez',
    'cisèlements',
    'cisélerions',
    'cisellement',
    'cisterciens',
    'citattrices',
    'cité-jardin',
    'citharistes',
    'citoyenneté',
    'citronnades',
    'citronnelle',
    'citronniers',
    'citrouilles',
    'civilisable',
    'civilisâmes',
    'civilisasse',
    'civilisâtes',
    'civiliserai',
    'civiliseras',
    'civiliserez',
    'civilisions',
    'clabaudages',
    'clabaudâmes',
    'clabaudasse',
    'clabaudâtes',
    'clabauderai',
    'clabauderas',
    'clabauderez',
    'clabauderie',
    'clabaudeurs',
    'clabaudeuse',
    'clabaudions',
    'clabotaient',
    'clabotasses',
    'claboterais',
    'claboterait',
    'clabotèrent',
    'claboteriez',
    'claboterons',
    'claboteront',
    'claire-voie',
    'claironnais',
    'claironnait',
    'claironnant',
    'claironnées',
    'claironnent',
    'claironnera',
    'claironniez',
    'claironnons',
    'clairsemées',
    'clairvoyant',
    'clamassions',
    'clameraient',
    'clampassent',
    'clampassiez',
    'clamperions',
    'clampsaient',
    'clampsasses',
    'clampserais',
    'clampserait',
    'clampsèrent',
    'clampseriez',
    'clampserons',
    'clampseront',
    'clamsassent',
    'clamsassiez',
    'clamserions',
    'clandestine',
    'clandestins',
    'clapassions',
    'claperaient',
    'clapiraient',
    'clapissions',
    'clapotaient',
    'clapotantes',
    'clapotasses',
    'clapotement',
    'clapoterais',
    'clapoterait',
    'clapotèrent',
    'clapoteriez',
    'clapoterons',
    'clapoteront',
    'clappassent',
    'clappassiez',
    'clappements',
    'clapperions',
    'clapsassent',
    'clapsassiez',
    'clapserions',
    'claquassent',
    'claquassiez',
    'claquements',
    'claquemurai',
    'claquemuras',
    'claquemurât',
    'claquemurée',
    'claquemurer',
    'claquemures',
    'claquemurés',
    'claquemurez',
    'claquerions',
    'claquetâmes',
    'claquetasse',
    'claquetâtes',
    'claquetions',
    'claquettent',
    'claquettera',
    'clarifiâmes',
    'clarifiasse',
    'clarifiâtes',
    'clarifierai',
    'clarifieras',
    'clarifierez',
    'clarifiions',
    'clarinettes',
    'classassent',
    'classassiez',
    'classements',
    'classerions',
    'classicisme',
    'classifiais',
    'classifiait',
    'classifiant',
    'classifiées',
    'classifient',
    'classifiera',
    'classifiiez',
    'classifions',
    'claudicante',
    'claudicants',
    'claudiquais',
    'claudiquait',
    'claudiquant',
    'claudiquent',
    'claudiquera',
    'claudiquiez',
    'claudiquons',
    'claustrales',
    'claustrâmes',
    'claustrasse',
    'claustrâtes',
    'claustrerai',
    'claustreras',
    'claustrerez',
    'claustrions',
    'clavassions',
    'claveraient',
    'clavetaient',
    'clavetasses',
    'clavetèrent',
    'clavettâmes',
    'clavettasse',
    'clavettâtes',
    'clavetterai',
    'clavetteras',
    'clavetterez',
    'clavettions',
    'clavicordes',
    'clayonnages',
    'clayonnâmes',
    'clayonnasse',
    'clayonnâtes',
    'clayonnerai',
    'clayonneras',
    'clayonnerez',
    'clayonnions',
    'clémentines',
    'cleptomanes',
    'cleptomanie',
    'cléricature',
    'clichassent',
    'clichassiez',
    'clicherions',
    'clignassent',
    'clignassiez',
    'clignements',
    'clignerions',
    'clignotâmes',
    'clignotante',
    'clignotants',
    'clignotasse',
    'clignotâtes',
    'clignoterai',
    'clignoteras',
    'clignoterez',
    'clignotions',
    'climatiques',
    'climatisais',
    'climatisait',
    'climatisant',
    'climatisées',
    'climatisent',
    'climatisera',
    'climatiseur',
    'climatisiez',
    'climatismes',
    'climatisons',
    'clinicienne',
    'clinomètres',
    'clinquantes',
    'cliquetâmes',
    'cliquetasse',
    'cliquetâtes',
    'cliquetions',
    'cliquettent',
    'cliquettera',
    'clissassent',
    'clissassiez',
    'clisserions',
    'clitoridien',
    'clivassions',
    'cliveraient',
    'clochardisa',
    'clochardise',
    'clochardisé',
    'clochassent',
    'clochassiez',
    'cloche-pied',
    'clocherions',
    'cloisonnais',
    'cloisonnait',
    'cloisonnant',
    'cloisonnées',
    'cloisonnent',
    'cloisonnera',
    'cloisonniez',
    'cloisonnons',
    'cloîtraient',
    'cloîtrasses',
    'cloîtrerais',
    'cloîtrerait',
    'cloîtrèrent',
    'cloîtreriez',
    'cloîtrerons',
    'cloîtreront',
    'clopinaient',
    'clopinasses',
    'clopinerais',
    'clopinerait',
    'clopinèrent',
    'clopineriez',
    'clopinerons',
    'clopineront',
    'clopinettes',
    'cloquassent',
    'cloquassiez',
    'cloquerions',
    'clôturaient',
    'clôturasses',
    'clôturerais',
    'clôturerait',
    'clôturèrent',
    'clôtureriez',
    'clôturerons',
    'clôtureront',
    'clouassions',
    'cloueraient',
    'cloutassent',
    'cloutassiez',
    'clouterions',
    'clownesques',
    'clunisienne',
    'coacquéreur',
    'coadjuteurs',
    'coadjutrice',
    'coagulables',
    'coagulaient',
    'coagulantes',
    'coagulasses',
    'coagulateur',
    'coagulation',
    'coagulerais',
    'coagulerait',
    'coagulèrent',
    'coaguleriez',
    'coagulerons',
    'coaguleront',
    'coalescence',
    'coalisaient',
    'coalisasses',
    'coaliserais',
    'coaliserait',
    'coalisèrent',
    'coaliseriez',
    'coaliserons',
    'coaliseront',
    'coaptations',
    'coarctation',
    'coassassent',
    'coassassiez',
    'coassements',
    'coasserions',
    'coassociées',
    'coassurance',
    'cocaïnomane',
    'cocardières',
    'cocasseries',
    'coccidioses',
    'coccinelles',
    'coccygienne',
    'cochassions',
    'côchassions',
    'cochenilles',
    'cocheraient',
    'côcheraient',
    'cochléaires',
    'cochléarias',
    'cochoncetés',
    'cochonnâmes',
    'cochonnasse',
    'cochonnâtes',
    'cochonnerai',
    'cochonneras',
    'cochonnerez',
    'cochonnerie',
    'cochonnions',
    'cocréancier',
    'cocufiaient',
    'cocufiasses',
    'cocufierais',
    'cocufierait',
    'cocufièrent',
    'cocufieriez',
    'cocufierons',
    'cocufieront',
    'cocycliques',
    'codébiteurs',
    'codébitrice',
    'codemandeur',
    'codétenteur',
    'codifiaient',
    'codifiasses',
    'codifierais',
    'codifierait',
    'codifièrent',
    'codifieriez',
    'codifierons',
    'codifieront',
    'codirecteur',
    'codirection',
    'codominance',
    'coéditrices',
    'coefficient',
    'coelacanthe',
    'coéquipière',
    'coéquipiers',
    'coercitions',
    'coercitives',
    'coexistâmes',
    'coexistante',
    'coexistants',
    'coexistasse',
    'coexistâtes',
    'coexistence',
    'coexisterai',
    'coexisteras',
    'coexisterez',
    'coexistions',
    'coextensifs',
    'coextensive',
    'coffrassent',
    'coffrassiez',
    'coffre-fort',
    'coffrerions',
    'cogitassent',
    'cogitassiez',
    'cogitations',
    'cogiterions',
    'cognassiers',
    'cognassions',
    'cogneraient',
    'cohabitâmes',
    'cohabitante',
    'cohabitants',
    'cohabitasse',
    'cohabitâtes',
    'cohabiterai',
    'cohabiteras',
    'cohabiterez',
    'cohabitions',
    'cohéritâmes',
    'cohéritasse',
    'cohéritâtes',
    'cohériterai',
    'cohériteras',
    'cohériterez',
    'cohéritière',
    'cohéritiers',
    'cohéritions',
    'coiffassent',
    'coiffassiez',
    'coifferions',
    'coinçassent',
    'coinçassiez',
    'coincements',
    'coincerions',
    'coïncidâmes',
    'coïncidasse',
    'coïncidâtes',
    'coïncidence',
    'coïncidente',
    'coïncidents',
    'coïnciderai',
    'coïncideras',
    'coïnciderez',
    'coïncidions',
    'coïnculpées',
    'coïtassions',
    'coïteraient',
    'cokéfaction',
    'cokéfiaient',
    'cokéfiantes',
    'cokéfiasses',
    'cokéfierais',
    'cokéfierait',
    'cokéfièrent',
    'cokéfieriez',
    'cokéfierons',
    'cokéfieront',
    'colchicines',
    'cold-creams',
    'colégataire',
    'coléoptères',
    'colibacille',
    'colicitante',
    'colicitants',
    'colifichets',
    'colistières',
    'collaborais',
    'collaborait',
    'collaborant',
    'collaborent',
    'collaborera',
    'collaboriez',
    'collaborons',
    'collassions',
    'collatérale',
    'collatéraux',
    'collationna',
    'collationne',
    'collationné',
    'collectages',
    'collectâmes',
    'collectasse',
    'collectâtes',
    'collecterai',
    'collecteras',
    'collecterez',
    'collecteurs',
    'collections',
    'collectives',
    'collectrice',
    'collégiales',
    'collégienne',
    'colleraient',
    'collerettes',
    'colletaient',
    'colletasses',
    'colletèrent',
    'colletterai',
    'colletteras',
    'colletterez',
    'colligeâmes',
    'colligeasse',
    'colligeâtes',
    'colligerais',
    'colligerait',
    'colligèrent',
    'colligeriez',
    'colligerons',
    'colligeront',
    'collimateur',
    'collimation',
    'collocation',
    'colloïdales',
    'colloquâmes',
    'colloquasse',
    'colloquâtes',
    'colloquerai',
    'colloqueras',
    'colloquerez',
    'colloquions',
    'collusoires',
    'collutoires',
    'colmataient',
    'colmatasses',
    'colmaterais',
    'colmaterait',
    'colmatèrent',
    'colmateriez',
    'colmaterons',
    'colmateront',
    'colocataire',
    'colonisâmes',
    'colonisasse',
    'colonisâtes',
    'coloniserai',
    'coloniseras',
    'coloniserez',
    'colonisions',
    'colonnettes',
    'coloquintes',
    'colorassent',
    'colorassiez',
    'colorations',
    'colorerions',
    'coloriaient',
    'coloriasses',
    'colorierais',
    'colorierait',
    'colorièrent',
    'colorieriez',
    'colorierons',
    'colorieront',
    'colorimètre',
    'colportages',
    'colportâmes',
    'colportasse',
    'colportâtes',
    'colporterai',
    'colporteras',
    'colporterez',
    'colporteurs',
    'colporteuse',
    'colportions',
    'colposcopie',
    'coltinaient',
    'coltinasses',
    'coltinerais',
    'coltinerait',
    'coltinèrent',
    'coltineriez',
    'coltinerons',
    'coltineront',
    'columbarium',
    'combativité',
    'combattante',
    'combattants',
    'combattîmes',
    'combattions',
    'combattisse',
    'combattîtes',
    'combattrais',
    'combattrait',
    'combattriez',
    'combattrons',
    'combattront',
    'combinables',
    'combinaient',
    'combinaison',
    'combinardes',
    'combinasses',
    'combinateur',
    'combinerais',
    'combinerait',
    'combinèrent',
    'combineriez',
    'combinerons',
    'combineront',
    'comblassent',
    'comblassiez',
    'comblements',
    'comblerions',
    'comburantes',
    'combustible',
    'combustions',
    'comédiennes',
    'comestibles',
    'comiquement',
    'commandâmes',
    'commandante',
    'commandants',
    'commandasse',
    'commandâtes',
    'commanderai',
    'commanderas',
    'commanderez',
    'commanderie',
    'commandeurs',
    'commandeuse',
    'commandions',
    'commanditai',
    'commanditas',
    'commanditât',
    'commanditée',
    'commanditer',
    'commandites',
    'commandités',
    'commanditez',
    'commémorais',
    'commémorait',
    'commémorant',
    'commémorées',
    'commémorent',
    'commémorera',
    'commémoriez',
    'commémorons',
    'commençâmes',
    'commençante',
    'commençants',
    'commençasse',
    'commençâtes',
    'commencerai',
    'commenceras',
    'commencerez',
    'commencions',
    'commensales',
    'commentaire',
    'commentâmes',
    'commentasse',
    'commentâtes',
    'commenterai',
    'commenteras',
    'commenterez',
    'commentions',
    'comméraient',
    'commérasses',
    'commerçâmes',
    'commerçante',
    'commerçants',
    'commerçasse',
    'commerçâtes',
    'commercerai',
    'commerceras',
    'commercerez',
    'commerciale',
    'commerciaux',
    'commercions',
    'commérerais',
    'commérerait',
    'commérèrent',
    'comméreriez',
    'commérerons',
    'comméreront',
    'commettante',
    'commettants',
    'commettions',
    'commettrais',
    'commettrait',
    'commettriez',
    'commettrons',
    'commettront',
    'comminutifs',
    'comminutive',
    'commissaire',
    'commissions',
    'commissoire',
    'commissural',
    'commissures',
    'commodément',
    'commotionna',
    'commotionne',
    'commotionné',
    'commuassent',
    'commuassiez',
    'commuerions',
    'communalise',
    'communalisé',
    'communardes',
    'communautés',
    'communément',
    'communiâmes',
    'communiante',
    'communiants',
    'communiasse',
    'communiâtes',
    'communicant',
    'communierai',
    'communieras',
    'communierez',
    'communiions',
    'communiquai',
    'communiquas',
    'communiquât',
    'communiquée',
    'communiquer',
    'communiques',
    'communiqués',
    'communiquez',
    'communisant',
    'communismes',
    'communistes',
    'commutables',
    'commutaient',
    'commutasses',
    'commutateur',
    'commutatifs',
    'commutation',
    'commutative',
    'commuterais',
    'commuterait',
    'commutèrent',
    'commuteriez',
    'commuterons',
    'commuteront',
    'compactages',
    'comparables',
    'comparaient',
    'comparaison',
    'comparaisse',
    'comparaîtra',
    'comparaître',
    'comparasses',
    'comparateur',
    'comparatifs',
    'comparative',
    'comparerais',
    'comparerait',
    'comparèrent',
    'compareriez',
    'comparerons',
    'compareront',
    'comparurent',
    'comparusses',
    'comparution',
    'compassâmes',
    'compassasse',
    'compassâtes',
    'compasserai',
    'compasseras',
    'compasserez',
    'compassions',
    'compatibles',
    'compatirais',
    'compatirait',
    'compatirent',
    'compatiriez',
    'compatirons',
    'compatiront',
    'compatisses',
    'compatissez',
    'compatriote',
    'compendieux',
    'compendiums',
    'compensable',
    'compensâmes',
    'compensasse',
    'compensâtes',
    'compenserai',
    'compenseras',
    'compenserez',
    'compensions',
    'compétaient',
    'compétasses',
    'compétences',
    'compétentes',
    'compéterais',
    'compéterait',
    'compétèrent',
    'compéteriez',
    'compéterons',
    'compéteront',
    'compétiteur',
    'compétitifs',
    'compétition',
    'compétitive',
    'compilaient',
    'compilasses',
    'compilateur',
    'compilation',
    'compilerais',
    'compilerait',
    'compilèrent',
    'compileriez',
    'compilerons',
    'compileront',
    'compissâmes',
    'compissasse',
    'compissâtes',
    'compisserai',
    'compisseras',
    'compisserez',
    'compissions',
    'complaintes',
    'complairais',
    'complairait',
    'complairiez',
    'complairons',
    'complairont',
    'complaisais',
    'complaisait',
    'complaisant',
    'complaisent',
    'complaisiez',
    'complaisons',
    'compléments',
    'complétâmes',
    'complétasse',
    'complétâtes',
    'compléterai',
    'compléteras',
    'compléterez',
    'complétions',
    'complétives',
    'complexâmes',
    'complexasse',
    'complexâtes',
    'complexerai',
    'complexeras',
    'complexerez',
    'complexifia',
    'complexifie',
    'complexifié',
    'complexions',
    'complexités',
    'complicités',
    'complimenta',
    'complimente',
    'complimenté',
    'compliments',
    'compliquais',
    'compliquait',
    'compliquant',
    'compliquées',
    'compliquent',
    'compliquera',
    'compliquiez',
    'compliquons',
    'complotâmes',
    'complotasse',
    'complotâtes',
    'comploterai',
    'comploteurs',
    'comploteuse',
    'complotions',
    'complussent',
    'complussiez',
    'componction',
    'comportâmes',
    'comportasse',
    'comportâtes',
    'comporterai',
    'comporteras',
    'comporterez',
    'comportions',
    'composaient',
    'composantes',
    'composasses',
    'composèrent',
    'composeuses',
    'compositeur',
    'composition',
    'compostages',
    'compostâmes',
    'compostasse',
    'compostâtes',
    'composterai',
    'composteras',
    'composterez',
    'composteurs',
    'compostions',
    'comprendrai',
    'comprendras',
    'comprendrez',
    'comprenette',
    'comprenions',
    'comprennent',
    'compressais',
    'compressait',
    'compressant',
    'compressées',
    'compressent',
    'compressera',
    'compresseur',
    'compressiez',
    'compressifs',
    'compression',
    'compressive',
    'compressons',
    'comprimable',
    'comprimâmes',
    'comprimante',
    'comprimants',
    'comprimasse',
    'comprimâtes',
    'comprimerai',
    'comprimeras',
    'comprimerez',
    'comprimions',
    'comprissent',
    'comprissiez',
    'compromette',
    'compromîmes',
    'compromises',
    'compromisse',
    'compromîtes',
    'comptassent',
    'comptassiez',
    'compterions',
    'compte-tenu',
    'compulsâmes',
    'compulsasse',
    'compulsâtes',
    'compulserai',
    'compulseras',
    'compulserez',
    'compulsions',
    'compulsives',
    'computaient',
    'computasses',
    'computation',
    'computerais',
    'computerait',
    'computèrent',
    'computeriez',
    'computerons',
    'computeront',
    'concassages',
    'concassâmes',
    'concassasse',
    'concassâtes',
    'concasserai',
    'concasseras',
    'concasserez',
    'concasseurs',
    'concassions',
    'concaténais',
    'concaténait',
    'concaténant',
    'concaténées',
    'concatènent',
    'concaténera',
    'concaténiez',
    'concaténons',
    'concédaient',
    'concédasses',
    'concéderais',
    'concéderait',
    'concédèrent',
    'concéderiez',
    'concéderons',
    'concéderont',
    'concélébrai',
    'concélébras',
    'concélébrât',
    'concélébrée',
    'concélébrer',
    'concélébrés',
    'concélèbres',
    'concélébrez',
    'concentrais',
    'concentrait',
    'concentrant',
    'concentrées',
    'concentrent',
    'concentrera',
    'concentriez',
    'concentrons',
    'conceptacle',
    'concepteurs',
    'conceptions',
    'conceptisme',
    'conceptrice',
    'conceptuels',
    'concernâmes',
    'concernasse',
    'concernâtes',
    'concernerai',
    'concerneras',
    'concernerez',
    'concernions',
    'concertâmes',
    'concertante',
    'concertants',
    'concertasse',
    'concertâtes',
    'concerterai',
    'concerteras',
    'concerterez',
    'concertinos',
    'concertions',
    'concertiste',
    'concessions',
    'concessives',
    'concevables',
    'concevaient',
    'concevrions',
    'conchoïdale',
    'conchoïdaux',
    'conchyliens',
    'conciliable',
    'conciliaire',
    'conciliâmes',
    'conciliante',
    'conciliants',
    'conciliasse',
    'conciliâtes',
    'concilierai',
    'concilieras',
    'concilierez',
    'conciliions',
    'concitoyens',
    'conclaviste',
    'concluaient',
    'concluantes',
    'conclurions',
    'conclusions',
    'conclussent',
    'conclussiez',
    'concoctâmes',
    'concoctasse',
    'concoctâtes',
    'concocterai',
    'concocteras',
    'concocterez',
    'concoctions',
    'concomitant',
    'concordâmes',
    'concordance',
    'concordante',
    'concordants',
    'concordasse',
    'concordâtes',
    'concorderai',
    'concorderas',
    'concorderez',
    'concordions',
    'concourante',
    'concourants',
    'concourions',
    'concourrais',
    'concourrait',
    'concourriez',
    'concourrons',
    'concourront',
    'concourûmes',
    'concourusse',
    'concourûtes',
    'concrétâmes',
    'concrétasse',
    'concrétâtes',
    'concréterai',
    'concréteras',
    'concréterez',
    'concrétions',
    'concrétisai',
    'concrétisas',
    'concrétisât',
    'concrétisée',
    'concrétiser',
    'concrétises',
    'concrétisés',
    'concrétisez',
    'concubinage',
    'concurrença',
    'concurrence',
    'concurrencé',
    'concurrente',
    'concurrents',
    'concussions',
    'conçussions',
    'condamnable',
    'condamnâmes',
    'condamnasse',
    'condamnâtes',
    'condamnerai',
    'condamneras',
    'condamnerez',
    'condamnions',
    'condensable',
    'condensâmes',
    'condensasse',
    'condensâtes',
    'condenserai',
    'condenseras',
    'condenserez',
    'condenseurs',
    'condensions',
    'condescende',
    'condescends',
    'condescendu',
    'condisciple',
    'conditionna',
    'conditionne',
    'conditionné',
    'condominium',
    'condottiere',
    'conductance',
    'conducteurs',
    'conductible',
    'conductions',
    'conductrice',
    'conduirions',
    'conduisîmes',
    'conduisions',
    'conduisisse',
    'conduisîtes',
    'condylienne',
    'confections',
    'confédérais',
    'confédérait',
    'confédérale',
    'confédérant',
    'confédéraux',
    'confédérées',
    'confédèrent',
    'confédérera',
    'confédériez',
    'confédérons',
    'conféraient',
    'conférasses',
    'conférences',
    'conférerais',
    'conférerait',
    'conférèrent',
    'conféreriez',
    'conférerons',
    'conféreront',
    'confessâmes',
    'confessasse',
    'confessâtes',
    'confesserai',
    'confesseras',
    'confesserez',
    'confesseurs',
    'confessions',
    'confiassent',
    'confiassiez',
    'confidences',
    'confidentes',
    'confierions',
    'configurais',
    'configurait',
    'configurant',
    'configurées',
    'configurent',
    'configurera',
    'configuriez',
    'configurons',
    'confinaient',
    'confinasses',
    'confinement',
    'confinerais',
    'confinerait',
    'confinèrent',
    'confineriez',
    'confinerons',
    'confineront',
    'confiraient',
    'confirmâmes',
    'confirmande',
    'confirmands',
    'confirmasse',
    'confirmâtes',
    'confirmatif',
    'confirmerai',
    'confirmeras',
    'confirmerez',
    'confirmions',
    'confisaient',
    'confiscable',
    'confiseries',
    'confiseuses',
    'confisquais',
    'confisquait',
    'confisquant',
    'confisquées',
    'confisquent',
    'confisquera',
    'confisquiez',
    'confisquons',
    'confissions',
    'confiturier',
    'conflictuel',
    'confluaient',
    'confluasses',
    'confluences',
    'confluerais',
    'confluerait',
    'confluèrent',
    'conflueriez',
    'confluerons',
    'conflueront',
    'confondante',
    'confondants',
    'confondîmes',
    'confondions',
    'confondisse',
    'confondîtes',
    'confondrais',
    'confondrait',
    'confondriez',
    'confondrons',
    'confondront',
    'conformâmes',
    'conformasse',
    'conformâtes',
    'conformerai',
    'conformeras',
    'conformerez',
    'conformions',
    'conformisme',
    'conformiste',
    'conformités',
    'confortable',
    'confortâmes',
    'confortasse',
    'confortâtes',
    'conforterai',
    'conforteras',
    'conforterez',
    'confortions',
    'confrontais',
    'confrontait',
    'confrontant',
    'confrontées',
    'confrontent',
    'confrontera',
    'confrontiez',
    'confrontons',
    'confusément',
    'congédiable',
    'congédiâmes',
    'congédiasse',
    'congédiâtes',
    'congédierai',
    'congédieras',
    'congédierez',
    'congédiions',
    'congelables',
    'congelaient',
    'congelasses',
    'congélateur',
    'congélation',
    'congèlerais',
    'congèlerait',
    'congelèrent',
    'congèleriez',
    'congèlerons',
    'congèleront',
    'congénitale',
    'congénitaux',
    'congestives',
    'conglomérai',
    'congloméras',
    'conglomérat',
    'conglomérât',
    'conglomérée',
    'conglomérer',
    'conglomérés',
    'conglomères',
    'conglomérez',
    'conglutinai',
    'conglutinas',
    'conglutinât',
    'conglutinée',
    'conglutiner',
    'conglutines',
    'conglutinés',
    'conglutinez',
    'congolaises',
    'congratulai',
    'congratulas',
    'congratulât',
    'congratulée',
    'congratuler',
    'congratules',
    'congratulés',
    'congratulez',
    'congréaient',
    'congréasses',
    'congréerais',
    'congréerait',
    'congréèrent',
    'congréeriez',
    'congréerons',
    'congréeront',
    'congruentes',
    'conirostres',
    'cônissaient',
    'conjecturai',
    'conjectural',
    'conjecturas',
    'conjecturât',
    'conjecturée',
    'conjecturer',
    'conjectures',
    'conjecturés',
    'conjecturez',
    'conjoignais',
    'conjoignait',
    'conjoignant',
    'conjoignent',
    'conjoigniez',
    'conjoignons',
    'conjoindrai',
    'conjoindras',
    'conjoindrez',
    'conjoncteur',
    'conjonctifs',
    'conjonction',
    'conjonctive',
    'conjoncture',
    'conjugaison',
    'conjuguâmes',
    'conjuguasse',
    'conjuguâtes',
    'conjuguerai',
    'conjugueras',
    'conjuguerez',
    'conjuguions',
    'conjuraient',
    'conjurasses',
    'conjurateur',
    'conjuration',
    'conjurerais',
    'conjurerait',
    'conjurèrent',
    'conjureriez',
    'conjurerons',
    'conjureront',
    'connaissais',
    'connaissait',
    'connaissant',
    'connaissent',
    'connaisseur',
    'connaissiez',
    'connaissons',
    'connaîtrais',
    'connaîtrait',
    'connaîtriez',
    'connaîtrons',
    'connaîtront',
    'connectâmes',
    'connectasse',
    'connectâtes',
    'connecterai',
    'connecteras',
    'connecterez',
    'connecteurs',
    'connections',
    'connectives',
    'connétables',
    'connivences',
    'conniventes',
    'connotaient',
    'connotasses',
    'connotation',
    'connoterais',
    'connoterait',
    'connotèrent',
    'connoteriez',
    'connoterons',
    'connoteront',
    'connussions',
    'conobraient',
    'conobrasses',
    'conobrerais',
    'conobrerait',
    'conobrèrent',
    'conobreriez',
    'conobrerons',
    'conobreront',
    'conpressais',
    'conpressait',
    'conpressant',
    'conpressées',
    'conpressent',
    'conpressera',
    'conpressiez',
    'conpressons',
    'conquérante',
    'conquérants',
    'conquérions',
    'conquerrais',
    'conquerrait',
    'conquerriez',
    'conquerrons',
    'conquerront',
    'conquièrent',
    'conquissent',
    'conquissiez',
    'consacrâmes',
    'consacrasse',
    'consacrâtes',
    'consacrerai',
    'consacreras',
    'consacrerez',
    'consacrions',
    'consanguine',
    'consanguins',
    'consciences',
    'conscientes',
    'consécutifs',
    'consécution',
    'consécutive',
    'conseillais',
    'conseillait',
    'conseillant',
    'conseillées',
    'conseillent',
    'conseillera',
    'conseillère',
    'conseillers',
    'conseilleur',
    'conseilliez',
    'conseillons',
    'consentante',
    'consentants',
    'consentîmes',
    'consentions',
    'consentirai',
    'consentiras',
    'consentirez',
    'consentisse',
    'consentîtes',
    'conséquence',
    'conséquente',
    'conséquents',
    'conservâmes',
    'conservasse',
    'conservâtes',
    'conserverai',
    'conserveras',
    'conserverez',
    'conserverie',
    'conservions',
    'considérais',
    'considérait',
    'considérant',
    'considérées',
    'considèrent',
    'considérera',
    'considériez',
    'considérons',
    'consignâmes',
    'consignasse',
    'consignâtes',
    'consignerai',
    'consigneras',
    'consignerez',
    'consignions',
    'consistâmes',
    'consistance',
    'consistante',
    'consistants',
    'consistasse',
    'consistâtes',
    'consisterai',
    'consisteras',
    'consisterez',
    'consistions',
    'consistoire',
    'consolables',
    'consolaient',
    'consolantes',
    'consolasses',
    'consolateur',
    'consolation',
    'consolerais',
    'consolerait',
    'consolèrent',
    'consoleriez',
    'consolerons',
    'consoleront',
    'consolidais',
    'consolidait',
    'consolidant',
    'consolidées',
    'consolident',
    'consolidera',
    'consolidiez',
    'consolidons',
    'consommable',
    'consommâmes',
    'consommasse',
    'consommâtes',
    'consommerai',
    'consommeras',
    'consommerez',
    'consommions',
    'consomptifs',
    'consomption',
    'consomptive',
    'consonaient',
    'consonances',
    'consonasses',
    'consonerais',
    'consonerait',
    'consonèrent',
    'consoneriez',
    'consonerons',
    'consoneront',
    'consortiums',
    'conspirâmes',
    'conspirasse',
    'conspirâtes',
    'conspirerai',
    'conspireras',
    'conspirerez',
    'conspirions',
    'conspuaient',
    'conspuasses',
    'conspuerais',
    'conspuerait',
    'conspuèrent',
    'conspueriez',
    'conspuerons',
    'conspueront',
    'constamment',
    'constantans',
    'constatâmes',
    'constatasse',
    'constatâtes',
    'constaterai',
    'constateras',
    'constaterez',
    'constations',
    'constellais',
    'constellait',
    'constellant',
    'constellées',
    'constellent',
    'constellera',
    'constelliez',
    'constellons',
    'consternais',
    'consternait',
    'consternant',
    'consternées',
    'consternent',
    'consternera',
    'consterniez',
    'consternons',
    'constipâmes',
    'constipasse',
    'constipâtes',
    'constiperai',
    'constiperas',
    'constiperez',
    'constipions',
    'constituais',
    'constituait',
    'constituant',
    'constituées',
    'constituent',
    'constituera',
    'constituiez',
    'constituons',
    'constitutif',
    'constructif',
    'construirai',
    'construiras',
    'construirez',
    'construises',
    'construisez',
    'construisis',
    'construisit',
    'construisît',
    'construites',
    'consulaires',
    'consultable',
    'consultâmes',
    'consultante',
    'consultants',
    'consultasse',
    'consultâtes',
    'consultatif',
    'consulterai',
    'consulteras',
    'consulterez',
    'consultions',
    'consumaient',
    'consumasses',
    'consumerais',
    'consumerait',
    'consumèrent',
    'consumeriez',
    'consumerons',
    'consumeront',
    'contactâmes',
    'contactasse',
    'contactâtes',
    'contacterai',
    'contacteras',
    'contacterez',
    'contacteurs',
    'contactions',
    'contactrice',
    'contagieuse',
    'contagionna',
    'contagionne',
    'contagionné',
    'contaminais',
    'contaminait',
    'contaminant',
    'contaminées',
    'contaminent',
    'contaminera',
    'contaminiez',
    'contaminons',
    'contassions',
    'contemplais',
    'contemplait',
    'contemplant',
    'contemplées',
    'contemplent',
    'contemplera',
    'contempliez',
    'contemplons',
    'contempteur',
    'contenaient',
    'contenances',
    'contenantes',
    'contentâmes',
    'contentasse',
    'contentâtes',
    'contenterai',
    'contenteras',
    'contenterez',
    'contentieux',
    'contentions',
    'contentives',
    'conteraient',
    'contestable',
    'contestâmes',
    'contestasse',
    'contestâtes',
    'contesterai',
    'contesteras',
    'contesterez',
    'contestions',
    'contextuels',
    'contextures',
    'contiendrai',
    'contiendras',
    'contiendrez',
    'contiennent',
    'contiguïtés',
    'continences',
    'continental',
    'contingence',
    'contingenta',
    'contingente',
    'contingenté',
    'contingents',
    'continssent',
    'continssiez',
    'continuâmes',
    'continuasse',
    'continuâtes',
    'continuelle',
    'continuerai',
    'continueras',
    'continuerez',
    'continuions',
    'continuités',
    'continûment',
    'contondante',
    'contondants',
    'contorsions',
    'contournais',
    'contournait',
    'contournant',
    'contournées',
    'contournent',
    'contournera',
    'contourniez',
    'contournons',
    'contractais',
    'contractait',
    'contractant',
    'contractées',
    'contractent',
    'contractera',
    'contractiez',
    'contraction',
    'contractons',
    'contractuel',
    'contractura',
    'contracture',
    'contracturé',
    'contraignes',
    'contraignez',
    'contraignis',
    'contraignit',
    'contraignît',
    'contraindra',
    'contraindre',
    'contraintes',
    'contraposai',
    'contraposas',
    'contraposât',
    'contraposée',
    'contraposer',
    'contraposes',
    'contraposés',
    'contraposez',
    'contrariais',
    'contrariait',
    'contrariant',
    'contrariées',
    'contrarient',
    'contrariera',
    'contrariété',
    'contrariiez',
    'contrarions',
    'contrassent',
    'contrassiez',
    'contrastais',
    'contrastait',
    'contrastant',
    'contrastées',
    'contrastent',
    'contrastera',
    'contrastiez',
    'contrastons',
    'contrebande',
    'contrebasse',
    'contrebatte',
    'contrebattu',
    'contrebouta',
    'contreboute',
    'contrebouté',
    'contre-buta',
    'contrebutai',
    'contrebutas',
    'contrebutât',
    'contre-bute',
    'contre-buté',
    'contrebutée',
    'contrebuter',
    'contrebutes',
    'contrebutés',
    'contrebutez',
    'contrecarra',
    'contrecarre',
    'contrecarré',
    'contrechamp',
    'contrecoeur',
    'contrecoups',
    'contredanse',
    'contredîmes',
    'contredirai',
    'contrediras',
    'contredirez',
    'contredises',
    'contredisse',
    'contredites',
    'contredîtes',
    'contrefaçon',
    'contrefaire',
    'contrefaite',
    'contrefaits',
    'contrefasse',
    'contreferai',
    'contreferas',
    'contreferez',
    'contre-fers',
    'contre-feux',
    'contreficha',
    'contrefiche',
    'contrefiché',
    'contre-fils',
    'contrefîmes',
    'contrefisse',
    'contrefîtes',
    'contreforts',
    'contrefoute',
    'contrefoutu',
    'contre-haut',
    'contre-jour',
    'contremanda',
    'contremande',
    'contremandé',
    'contre-mina',
    'contre-mine',
    'contre-miné',
    'contre-mura',
    'contre-muré',
    'contre-pied',
    'contrepoids',
    'contrepoint',
    'contre-rail',
    'contrerions',
    'contreseing',
    'contresigna',
    'contresigne',
    'contresigné',
    'contretemps',
    'contre-tira',
    'contre-tire',
    'contre-tiré',
    'contretypes',
    'contre-vair',
    'contrevenez',
    'contrevenir',
    'contrevents',
    'contrevenue',
    'contrevenus',
    'contreviens',
    'contrevient',
    'contre-voie',
    'contribuais',
    'contribuait',
    'contribuant',
    'contribuent',
    'contribuera',
    'contribuiez',
    'contribuons',
    'contributif',
    'contristais',
    'contristait',
    'contristant',
    'contristées',
    'contristent',
    'contristera',
    'contristiez',
    'contristons',
    'contritions',
    'contrôlable',
    'contrôlâmes',
    'contrôlasse',
    'contrôlâtes',
    'contrôlerai',
    'contrôleras',
    'contrôlerez',
    'contrôleurs',
    'contrôleuse',
    'contrôlions',
    'contrordres',
    'controuvais',
    'controuvait',
    'controuvant',
    'controuvées',
    'controuvent',
    'controuvera',
    'controuviez',
    'controuvons',
    'controversa',
    'controverse',
    'controversé',
    'contusionna',
    'contusionne',
    'contusionné',
    'conurbation',
    'convaincant',
    'convaincrai',
    'convaincras',
    'convaincrez',
    'convaincues',
    'convainques',
    'convainquez',
    'convainquis',
    'convainquit',
    'convainquît',
    'convenables',
    'convenaient',
    'convenances',
    'convenantes',
    'conventions',
    'conventuels',
    'convergeais',
    'convergeait',
    'convergeant',
    'convergence',
    'convergente',
    'convergents',
    'convergeons',
    'convergerai',
    'convergeras',
    'convergerez',
    'convergions',
    'conversâmes',
    'conversasse',
    'conversâtes',
    'converserai',
    'converseras',
    'converserez',
    'conversions',
    'convertible',
    'convertîmes',
    'convertirai',
    'convertiras',
    'convertirez',
    'convertisse',
    'convertîtes',
    'conviassent',
    'conviassiez',
    'convictions',
    'conviendrai',
    'conviendras',
    'conviendrez',
    'conviennent',
    'convierions',
    'convinssent',
    'convinssiez',
    'conviviales',
    'convocation',
    'convoierais',
    'convoierait',
    'convoieriez',
    'convoierons',
    'convoieront',
    'convoitâmes',
    'convoitasse',
    'convoitâtes',
    'convoiterai',
    'convoiteras',
    'convoiterez',
    'convoitions',
    'convoitises',
    'convolaient',
    'convolasses',
    'convolerais',
    'convolerait',
    'convolèrent',
    'convoleriez',
    'convolerons',
    'convoleront',
    'convolution',
    'convoquâmes',
    'convoquasse',
    'convoquâtes',
    'convoquerai',
    'convoqueras',
    'convoquerez',
    'convoquions',
    'convoyaient',
    'convoyasses',
    'convoyèrent',
    'convoyeuses',
    'convulsâmes',
    'convulsasse',
    'convulsâtes',
    'convulserai',
    'convulseras',
    'convulserez',
    'convulsions',
    'convulsives',
    'coopéraient',
    'coopérantes',
    'coopérasses',
    'coopérateur',
    'coopératifs',
    'coopération',
    'coopérative',
    'coopérerais',
    'coopérerait',
    'coopérèrent',
    'coopéreriez',
    'coopérerons',
    'coopéreront',
    'cooptassent',
    'cooptassiez',
    'cooptations',
    'coopterions',
    'coordonnais',
    'coordonnait',
    'coordonnant',
    'coordonnées',
    'coordonnent',
    'coordonnera',
    'coordonniez',
    'coordonnons',
    'copermutais',
    'copermutait',
    'copermutant',
    'copermutées',
    'copermutent',
    'copermutera',
    'copermutiez',
    'copermutons',
    'copernicien',
    'copiassions',
    'copieraient',
    'copinassent',
    'copinassiez',
    'copinerions',
    'coplanaires',
    'copossédées',
    'copossèdent',
    'copposédais',
    'copposédait',
    'copposédant',
    'copposédera',
    'copposédiez',
    'copposédons',
    'coprésident',
    'coprolithes',
    'copropriété',
    'copulassent',
    'copulassiez',
    'copulations',
    'copulatives',
    'copulerions',
    'coquassions',
    'coquelicots',
    'coqueluches',
    'coqueraient',
    'coquetaient',
    'coquetasses',
    'coquetèrent',
    'coquetterai',
    'coquetteras',
    'coquetterez',
    'coquetterie',
    'coquillages',
    'coquillâmes',
    'coquillarts',
    'coquillasse',
    'coquillâtes',
    'coquillerai',
    'coquilleras',
    'coquillerez',
    'coquillette',
    'coquillière',
    'coquilliers',
    'coquillions',
    'coquineries',
    'corallienne',
    'corbillards',
    'cordassions',
    'cordelaient',
    'cordelasses',
    'cordelèrent',
    'cordelettes',
    'cordelières',
    'cordellerai',
    'cordelleras',
    'cordellerez',
    'corderaient',
    'cordialités',
    'cordillères',
    'cordon-bleu',
    'cordonnâmes',
    'cordonnasse',
    'cordonnâtes',
    'cordonnerai',
    'cordonneras',
    'cordonnerez',
    'cordonnerie',
    'cordonniers',
    'cordonnions',
    'corinthiens',
    'cornassions',
    'corned-beef',
    'cornélienne',
    'cornemuseur',
    'corneraient',
    'cornettiste',
    'corn-flakes',
    'corollaires',
    'coronariens',
    'coronarites',
    'corporatifs',
    'corporation',
    'corporative',
    'corporelles',
    'corpulences',
    'corpulentes',
    'corpuscules',
    'correcteurs',
    'corrections',
    'correctives',
    'correctrice',
    'corrélaient',
    'corrélasses',
    'corrélatifs',
    'corrélation',
    'corrélative',
    'corrélerais',
    'corrélerait',
    'corrélèrent',
    'corréleriez',
    'corrélerons',
    'corréleront',
    'corresponde',
    'corresponds',
    'correspondu',
    'corrigeâmes',
    'corrigeasse',
    'corrigeâtes',
    'corrigerais',
    'corrigerait',
    'corrigèrent',
    'corrigeriez',
    'corrigerons',
    'corrigeront',
    'corrigibles',
    'corroborais',
    'corroborait',
    'corroborant',
    'corroborées',
    'corroborent',
    'corroborera',
    'corroboriez',
    'corroborons',
    'corrodaient',
    'corrodasses',
    'corroderais',
    'corroderait',
    'corrodèrent',
    'corroderiez',
    'corroderons',
    'corroderont',
    'corroierais',
    'corroierait',
    'corroieriez',
    'corroierons',
    'corroieront',
    'corrompîmes',
    'corrompions',
    'corrompisse',
    'corrompîtes',
    'corromprais',
    'corromprait',
    'corrompriez',
    'corromprons',
    'corrompront',
    'corroyaient',
    'corroyasses',
    'corroyèrent',
    'corrupteurs',
    'corruptible',
    'corruptions',
    'corruptrice',
    'corsassions',
    'corseraient',
    'corsetaient',
    'corsetasses',
    'corsèterais',
    'corsèterait',
    'corsetèrent',
    'corsèteriez',
    'corsèterons',
    'corsèteront',
    'corsetières',
    'cosmétiquai',
    'cosmétiquas',
    'cosmétiquât',
    'cosmétiquée',
    'cosmétiquer',
    'cosmétiques',
    'cosmétiqués',
    'cosmétiquez',
    'cosmodromes',
    'cosmogonies',
    'cosmologies',
    'cosmonautes',
    'cosmopolite',
    'cossassions',
    'cosseraient',
    'costumaient',
    'costumasses',
    'costumerais',
    'costumerait',
    'costumèrent',
    'costumeriez',
    'costumerons',
    'costumeront',
    'costumières',
    'cotisassent',
    'cotisassiez',
    'cotisations',
    'cotiserions',
    'cotissaient',
    'côtoiements',
    'côtoierions',
    'cotonnaient',
    'cotonnasses',
    'cotonnerais',
    'cotonnerait',
    'cotonnèrent',
    'cotonneriez',
    'cotonnerons',
    'cotonneront',
    'cotonneuses',
    'cotonnières',
    'côtoyassent',
    'côtoyassiez',
    'couchaillai',
    'couchaillas',
    'couchaillât',
    'couchailler',
    'couchailles',
    'couchassent',
    'couchassiez',
    'coucherions',
    'couche-tard',
    'couci-couça',
    'coudassions',
    'cou-de-pied',
    'couderaient',
    'coudoiement',
    'coudoierais',
    'coudoierait',
    'coudoieriez',
    'coudoierons',
    'coudoieront',
    'coudoyaient',
    'coudoyasses',
    'coudoyèrent',
    'couillonnai',
    'couillonnas',
    'couillonnât',
    'couillonnée',
    'couillonner',
    'couillonnes',
    'couillonnés',
    'couillonnez',
    'couinassent',
    'couinassiez',
    'couinements',
    'couinerions',
    'coulassions',
    'couleraient',
    'coulissâmes',
    'coulissante',
    'coulissants',
    'coulissasse',
    'coulissâtes',
    'coulisserai',
    'coulisseras',
    'coulisserez',
    'coulissiers',
    'coulissions',
    'coulommiers',
    'coupaillais',
    'coupaillait',
    'coupaillant',
    'coupaillées',
    'coupaillent',
    'coupaillera',
    'coupailliez',
    'coupaillons',
    'coupassions',
    'coupe-choux',
    'coupe-coupe',
    'coupe-gorge',
    'coupellâmes',
    'coupellasse',
    'coupellâtes',
    'coupellerai',
    'coupelleras',
    'coupellerez',
    'coupellions',
    'couperaient',
    'couperosées',
    'couplassent',
    'couplassiez',
    'couplerions',
    'courageuses',
    'couraillais',
    'couraillait',
    'couraillant',
    'couraillent',
    'couraillera',
    'courailliez',
    'couraillons',
    'courbassent',
    'courbassiez',
    'courbaturai',
    'courbaturas',
    'courbaturât',
    'courbaturée',
    'courbaturer',
    'courbatures',
    'courbaturés',
    'courbaturez',
    'courbements',
    'courberions',
    'courcaillet',
    'couronnâmes',
    'couronnasse',
    'couronnâtes',
    'couronnerai',
    'couronneras',
    'couronnerez',
    'couronnions',
    'courrouçais',
    'courrouçait',
    'courrouçant',
    'courroucées',
    'courroucent',
    'courroucera',
    'courrouciez',
    'courrouçons',
    'coursassent',
    'coursassiez',
    'courserions',
    'courtaudais',
    'courtaudait',
    'courtaudant',
    'courtaudées',
    'courtaudent',
    'courtaudera',
    'courtaudiez',
    'courtaudons',
    'courtilière',
    'courtisâmes',
    'courtisanes',
    'courtisasse',
    'courtisâtes',
    'courtiserai',
    'courtiseras',
    'courtiserez',
    'courtisions',
    'courtoisies',
    'courussions',
    'cousinaient',
    'cousinasses',
    'cousinerais',
    'cousinerait',
    'cousinèrent',
    'cousineriez',
    'cousinerons',
    'cousineront',
    'cousissions',
    'coûtassions',
    'coutelières',
    'coutellerie',
    'coûteraient',
    'coutumières',
    'couturaient',
    'couturasses',
    'couturerais',
    'couturerait',
    'couturèrent',
    'coutureriez',
    'couturerons',
    'coutureront',
    'couturières',
    'couvassions',
    'couveraient',
    'couvertures',
    'couvre-chef',
    'couvre-feux',
    'couvre-lits',
    'couvre-pied',
    'couvririons',
    'couvrissent',
    'couvrissiez',
    'covariances',
    'covendeuses',
    'cover-girls',
    'coxalgiques',
    'coxarthrose',
    'crachassent',
    'crachassiez',
    'crachements',
    'cracherions',
    'crachotâmes',
    'crachotasse',
    'crachotâtes',
    'crachoterai',
    'crachoteras',
    'crachoterez',
    'crachotions',
    'crachouilla',
    'crachouille',
    'crachouillé',
    'craignaient',
    'craignirent',
    'craignisses',
    'craillaient',
    'craillasses',
    'craillerais',
    'craillerait',
    'craillèrent',
    'crailleriez',
    'craillerons',
    'crailleront',
    'craindrions',
    'cramassions',
    'crameraient',
    'crampillons',
    'cramponnais',
    'cramponnait',
    'cramponnant',
    'cramponnées',
    'cramponnent',
    'cramponnera',
    'cramponnets',
    'cramponniez',
    'cramponnons',
    'crampsaient',
    'crampsasses',
    'crampserais',
    'crampserait',
    'crampsèrent',
    'crampseriez',
    'crampserons',
    'crampseront',
    'cramsassent',
    'cramsassiez',
    'cramserions',
    'cranassions',
    'crânassions',
    'craneraient',
    'crâneraient',
    'crantassent',
    'crantassiez',
    'cranterions',
    'crapahutais',
    'crapahutait',
    'crapahutant',
    'crapahutées',
    'crapahutent',
    'crapahutera',
    'crapahutiez',
    'crapahutons',
    'crapaudines',
    'crapaütâmes',
    'crapaütasse',
    'crapaütâtes',
    'crapaüterai',
    'crapaüteras',
    'crapaüterez',
    'crapaütions',
    'crapouillot',
    'crapoussine',
    'crapoussins',
    'crapulaient',
    'crapulasses',
    'crapulerais',
    'crapulerait',
    'crapulèrent',
    'crapuleries',
    'crapuleriez',
    'crapulerons',
    'crapuleront',
    'crapuleuses',
    'craquassent',
    'craquassiez',
    'craquelages',
    'craquelâmes',
    'craquelasse',
    'craquelâtes',
    'craquelions',
    'craquellent',
    'craquellera',
    'craquelures',
    'craquements',
    'craquerions',
    'craquetâmes',
    'craquetasse',
    'craquetâtes',
    'craquetions',
    'craquettent',
    'craquettera',
    'crassassent',
    'crassassiez',
    'crasserions',
    'craterelles',
    'cravachâmes',
    'cravachasse',
    'cravachâtes',
    'cravacherai',
    'cravacheras',
    'cravacherez',
    'cravachions',
    'cravataient',
    'cravatasses',
    'cravaterais',
    'cravaterait',
    'cravatèrent',
    'cravateriez',
    'cravaterons',
    'cravateront',
    'crawlassent',
    'crawlassiez',
    'crawlerions',
    'crayonnages',
    'crayonnâmes',
    'crayonnasse',
    'crayonnâtes',
    'crayonnerai',
    'crayonneras',
    'crayonnerez',
    'crayonnions',
    'créancières',
    'créatinines',
    'créativités',
    'crécerelles',
    'créchassent',
    'créchassiez',
    'crécherions',
    'crédibilité',
    'créditaient',
    'créditasses',
    'crédit-bail',
    'créditerais',
    'créditerait',
    'créditèrent',
    'créditeriez',
    'créditerons',
    'créditeront',
    'créditistes',
    'créditrices',
    'crémaillère',
    'crémassions',
    'crématoires',
    'crémeraient',
    'crénassions',
    'crénelaient',
    'crénelasses',
    'crénelèrent',
    'crénellerai',
    'crénelleras',
    'crénellerez',
    'créneraient',
    'créolisâmes',
    'créolisasse',
    'créolisâtes',
    'créoliserai',
    'créoliseras',
    'créoliserez',
    'créolisions',
    'créosotâmes',
    'créosotasse',
    'créosotâtes',
    'créosoterai',
    'créosoteras',
    'créosoterez',
    'créosotions',
    'crêpassions',
    'crêperaient',
    'crépiraient',
    'crépissages',
    'crépissions',
    'crépitaient',
    'crépitasses',
    'crépitation',
    'crépitement',
    'crépiterais',
    'crépiterait',
    'crépitèrent',
    'crépiteriez',
    'crépiterons',
    'crépiteront',
    'crépuscules',
    'crétinisais',
    'crétinisait',
    'crétinisant',
    'crétinisées',
    'crétinisent',
    'crétinisera',
    'crétinisiez',
    'crétinismes',
    'crétinisons',
    'creusassent',
    'creusassiez',
    'creusements',
    'creuserions',
    'crevassâmes',
    'crevassasse',
    'crevassâtes',
    'crevasserai',
    'crevasseras',
    'crevasserez',
    'crevassions',
    'crève-coeur',
    'crèveraient',
    'criaillâmes',
    'criaillasse',
    'criaillâtes',
    'criaillerai',
    'criailleras',
    'criaillerez',
    'criaillerie',
    'criaillions',
    'criblassent',
    'criblassiez',
    'criblerions',
    'criminalisa',
    'criminalise',
    'criminalisé',
    'criminalité',
    'criminelles',
    'crispassent',
    'crispassiez',
    'crispations',
    'crisperions',
    'crissassent',
    'crissassiez',
    'crissements',
    'crisserions',
    'cristalline',
    'cristallins',
    'cristallisa',
    'cristallise',
    'cristallisé',
    'criticailla',
    'criticaille',
    'criticaillé',
    'critiquable',
    'critiquâmes',
    'critiquasse',
    'critiquâtes',
    'critiquerai',
    'critiqueras',
    'critiquerez',
    'critiquions',
    'croassaient',
    'croassasses',
    'croassement',
    'croasserais',
    'croasserait',
    'croassèrent',
    'croasseriez',
    'croasserons',
    'croasseront',
    'crochassent',
    'crochassiez',
    'croche-pied',
    'crocherions',
    'crochetages',
    'crochetâmes',
    'crochetasse',
    'crochetâtes',
    'crochèterai',
    'crochèteras',
    'crochèterez',
    'crocheteurs',
    'crochetions',
    'croisassent',
    'croisassiez',
    'croisements',
    'croiserions',
    'croisillons',
    'croissaient',
    'croissances',
    'croissantes',
    'croîtraient',
    'croquassent',
    'croquassiez',
    'croque-mort',
    'croquerions',
    'crossassent',
    'crossassiez',
    'crosserions',
    'crottassent',
    'crottassiez',
    'crotterions',
    'croulassent',
    'croulassiez',
    'croulerions',
    'croupirions',
    'croupissais',
    'croupissait',
    'croupissant',
    'croupissent',
    'croupissiez',
    'croupissons',
    'croustillai',
    'croustillas',
    'croustillât',
    'croustiller',
    'croustilles',
    'croustillez',
    'croûtassent',
    'croûtassiez',
    'croûterions',
    'crucifiâmes',
    'crucifiasse',
    'crucifiâtes',
    'crucifierai',
    'crucifieras',
    'crucifierez',
    'crucifiions',
    'crucifixion',
    'cruellement',
    'cryptassent',
    'cryptassiez',
    'crypterions',
    'cucurbitins',
    'cueillaient',
    'cueillerais',
    'cueillerait',
    'cueilleriez',
    'cueillerons',
    'cueilleront',
    'cueillettes',
    'cueillirent',
    'cueillisses',
    'cuirassâmes',
    'cuirassasse',
    'cuirassâtes',
    'cuirasserai',
    'cuirasseras',
    'cuirasserez',
    'cuirassiers',
    'cuirassions',
    'cuisinaient',
    'cuisinasses',
    'cuisinerais',
    'cuisinerait',
    'cuisinèrent',
    'cuisineriez',
    'cuisinerons',
    'cuisineront',
    'cuisinettes',
    'cuisinières',
    'cuisissions',
    'cuistreries',
    'cuitassions',
    'cuiteraient',
    'cuivrassent',
    'cuivrassiez',
    'cuivrerions',
    'culbutaient',
    'culbutasses',
    'culbuterais',
    'culbuterait',
    'culbutèrent',
    'culbuteriez',
    'culbuterons',
    'culbuteront',
    'culminaient',
    'culminantes',
    'culminasses',
    'culmination',
    'culminerais',
    'culminerait',
    'culminèrent',
    'culmineriez',
    'culminerons',
    'culmineront',
    'culottaient',
    'culottasses',
    'culotterais',
    'culotterait',
    'culottèrent',
    'culotteriez',
    'culotterons',
    'culotteront',
    'culottières',
    'culpabilisa',
    'culpabilise',
    'culpabilisé',
    'culpabilité',
    'culs-de-sac',
    'cultivables',
    'cultivaient',
    'cultivasses',
    'cultivateur',
    'cultiverais',
    'cultiverait',
    'cultivèrent',
    'cultiveriez',
    'cultiverons',
    'cultiveront',
    'culturelles',
    'culturismes',
    'cumulassent',
    'cumulassiez',
    'cumulateurs',
    'cumulatives',
    'cumulatrice',
    'cumulerions',
    'cunéiformes',
    'cuniculture',
    'curarisante',
    'curarisants',
    'cure-ongles',
    'curetassent',
    'curetassiez',
    'curetterais',
    'curetterait',
    'curetteriez',
    'curetterons',
    'curetteront',
    'curriculums',
    'curvilignes',
    'curvimètres',
    'cuvelassent',
    'cuvelassiez',
    'cuvellerais',
    'cuvellerait',
    'cuvelleriez',
    'cuvellerons',
    'cuvelleront',
    'cyanosaient',
    'cyanosasses',
    'cyanoserais',
    'cyanoserait',
    'cyanosèrent',
    'cyanoseriez',
    'cyanoserons',
    'cyanoseront',
    'cyanuraient',
    'cyanurasses',
    'cyanuration',
    'cyanurerais',
    'cyanurerait',
    'cyanurèrent',
    'cyanureriez',
    'cyanurerons',
    'cyanureront',
    'cyclisaient',
    'cyclisasses',
    'cycliserais',
    'cycliserait',
    'cyclisèrent',
    'cycliseriez',
    'cycliserons',
    'cycliseront',
    'cyclo-cross',
    'cycloïdales',
    'cyclomoteur',
    'cycloniques',
    'cyclopéenne',
    'cyclorameur',
    'cyclostomes',
    'cyclothymie',
    'cylindrages',
    'cylindrâmes',
    'cylindrasse',
    'cylindrâtes',
    'cylindraxes',
    'cylindrerai',
    'cylindreras',
    'cylindrerez',
    'cylindreurs',
    'cylindreuse',
    'cylindrions',
    'cylindrique',
    'cymbalaires',
    'cymbalières',
    'cymbalistes',
    'cynégétique',
    'cyniquement',
    'cynocéphale',
    'cynoglosses',
    'cynorhodons',
    'cyrilliques',
    'cystectomie',
    'cysticerque',
    'cystoscopes',
    'cystoscopie',
    'cystotomies',
    'cytologique',
    'cytologiste',
    'cytoplasmes',
    'dactyliques',
    'daguassions',
    'dagueraient',
    'dahoméennes',
    'daignassent',
    'daignassiez',
    'daignerions',
    'dalaï-lamas',
    'dallassions',
    'dalleraient',
    'dalmatiques',
    'daltonienne',
    'daltonismes',
    'damalisques',
    'damasquinai',
    'damasquinas',
    'damasquinât',
    'damasquinée',
    'damasquiner',
    'damasquines',
    'damasquinés',
    'damasquinez',
    'damassaient',
    'damassasses',
    'damasserais',
    'damasserait',
    'damassèrent',
    'damasseriez',
    'damasserons',
    'damasseront',
    'dame-jeanne',
    'damnassions',
    'damneraient',
    'dandinaient',
    'dandinasses',
    'dandinement',
    'dandinerais',
    'dandinerait',
    'dandinèrent',
    'dandineriez',
    'dandinerons',
    'dandineront',
    'dandinettes',
    'dangereuses',
    'dangerosité',
    'dansassions',
    'danseraient',
    'dansottâmes',
    'dansottasse',
    'dansottâtes',
    'dansotterai',
    'dansotteras',
    'dansotterez',
    'dansottions',
    'dardassions',
    'darderaient',
    'darwinienne',
    'darwinismes',
    'darwinistes',
    'daubassions',
    'dauberaient',
    'dauphinelle',
    'dauphinoise',
    'déactivâmes',
    'déactivasse',
    'déactivâtes',
    'déactiverai',
    'déactiveras',
    'déactiverez',
    'déactivions',
    'déambulâmes',
    'déambulasse',
    'déambulâtes',
    'déambulerai',
    'déambuleras',
    'déambulerez',
    'déambulions',
    'débâchaient',
    'débâchasses',
    'débâcherais',
    'débâcherait',
    'débâchèrent',
    'débâcheriez',
    'débâcherons',
    'débâcheront',
    'débâclaient',
    'débâclasses',
    'débâclerais',
    'débâclerait',
    'débâclèrent',
    'débâcleriez',
    'débâclerons',
    'débâcleront',
    'débagoulais',
    'débagoulait',
    'débagoulant',
    'débagoulées',
    'débagoulent',
    'débagoulera',
    'débagouliez',
    'débagoulons',
    'débâillonna',
    'débâillonne',
    'débâillonné',
    'déballaient',
    'déballasses',
    'déballerais',
    'déballerait',
    'déballèrent',
    'déballeriez',
    'déballerons',
    'déballeront',
    'déballonnai',
    'déballonnas',
    'déballonnât',
    'déballonnée',
    'déballonner',
    'déballonnes',
    'déballonnés',
    'déballonnez',
    'débalourdai',
    'débalourdas',
    'débalourdât',
    'débalourdée',
    'débalourder',
    'débalourdes',
    'débalourdés',
    'débalourdez',
    'débanalisai',
    'débanalisas',
    'débanalisât',
    'débanalisée',
    'débanaliser',
    'débanalises',
    'débanalisés',
    'débanalisez',
    'débandaient',
    'débandasses',
    'débanderais',
    'débanderait',
    'débandèrent',
    'débanderiez',
    'débanderons',
    'débanderont',
    'débaptisais',
    'débaptisait',
    'débaptisant',
    'débaptisées',
    'débaptisent',
    'débaptisera',
    'débaptisiez',
    'débaptisons',
    'débarcadère',
    'débardaient',
    'débardasses',
    'débarderais',
    'débarderait',
    'débardèrent',
    'débarderiez',
    'débarderons',
    'débarderont',
    'débardeuses',
    'débarquâmes',
    'débarquasse',
    'débarquâtes',
    'débarquerai',
    'débarqueras',
    'débarquerez',
    'débarquions',
    'débarraient',
    'débarrassai',
    'débarrassas',
    'débarrassât',
    'débarrassée',
    'débarrasser',
    'débarrasses',
    'débarrassés',
    'débarrassez',
    'débarrerais',
    'débarrerait',
    'débarrèrent',
    'débarreriez',
    'débarrerons',
    'débarreront',
    'débâtassent',
    'débâtassiez',
    'débâterions',
    'débâtirions',
    'débâtissais',
    'débâtissait',
    'débâtissant',
    'débâtissent',
    'débâtissiez',
    'débâtissons',
    'débattaient',
    'débattement',
    'débattirent',
    'débattisses',
    'débattrions',
    'débauchages',
    'débauchâmes',
    'débauchasse',
    'débauchâtes',
    'débaucherai',
    'débaucheras',
    'débaucherez',
    'débauchions',
    'débecquetai',
    'débecquetas',
    'débecquetât',
    'débecquetée',
    'débecqueter',
    'débecquetés',
    'débecquetez',
    'débecquette',
    'débectaient',
    'débectasses',
    'débecterais',
    'débecterait',
    'débectèrent',
    'débecteriez',
    'débecterons',
    'débecteront',
    'débenzolage',
    'débenzolais',
    'débenzolait',
    'débenzolant',
    'débenzolées',
    'débenzolent',
    'débenzolera',
    'débenzoliez',
    'débenzolons',
    'débilitâmes',
    'débilitante',
    'débilitants',
    'débilitasse',
    'débilitâtes',
    'débiliterai',
    'débiliteras',
    'débiliterez',
    'débilitions',
    'débillardai',
    'débillardas',
    'débillardât',
    'débillardée',
    'débillarder',
    'débillardes',
    'débillardés',
    'débillardez',
    'débinassent',
    'débinassiez',
    'débinerions',
    'débitassent',
    'débitassiez',
    'débiterions',
    'débitmètres',
    'déblaiement',
    'déblaierais',
    'déblaierait',
    'déblaieriez',
    'déblaierons',
    'déblaieront',
    'déblatérais',
    'déblatérait',
    'déblatérant',
    'déblatèrent',
    'déblatérera',
    'déblatériez',
    'déblatérons',
    'déblayaient',
    'déblayasses',
    'déblayerais',
    'déblayerait',
    'déblayèrent',
    'déblayeriez',
    'déblayerons',
    'déblayeront',
    'débleuirais',
    'débleuirait',
    'débleuirent',
    'débleuiriez',
    'débleuirons',
    'débleuiront',
    'débleuisses',
    'débleuissez',
    'débloquâmes',
    'débloquasse',
    'débloquâtes',
    'débloquerai',
    'débloqueras',
    'débloquerez',
    'débloquions',
    'débobinâmes',
    'débobinasse',
    'débobinâtes',
    'débobinerai',
    'débobineras',
    'débobinerez',
    'débobinions',
    'déboguaient',
    'déboguasses',
    'déboguerais',
    'déboguerait',
    'déboguèrent',
    'débogueriez',
    'déboguerons',
    'débogueront',
    'déboisaient',
    'déboisasses',
    'déboisement',
    'déboiserais',
    'déboiserait',
    'déboisèrent',
    'déboiseriez',
    'déboiserons',
    'déboiseront',
    'déboîtaient',
    'déboîtasses',
    'déboîtement',
    'déboîterais',
    'déboîterait',
    'déboîtèrent',
    'déboîteriez',
    'déboîterons',
    'déboîteront',
    'débondaient',
    'débondasses',
    'débonderais',
    'débonderait',
    'débondèrent',
    'débonderiez',
    'débonderons',
    'débonderont',
    'débonnaires',
    'débordaient',
    'débordantes',
    'débordasses',
    'débordement',
    'déborderais',
    'déborderait',
    'débordèrent',
    'déborderiez',
    'déborderons',
    'déborderont',
    'débosselais',
    'débosselait',
    'débosselant',
    'débosselées',
    'débosseliez',
    'débosselles',
    'débosselons',
    'débottaient',
    'débottasses',
    'débotterais',
    'débotterait',
    'débottèrent',
    'débotteriez',
    'débotterons',
    'débotteront',
    'débouchages',
    'débouchâmes',
    'débouchasse',
    'débouchâtes',
    'déboucherai',
    'déboucheras',
    'déboucherez',
    'déboucheurs',
    'débouchions',
    'débouchoirs',
    'débouclâmes',
    'débouclasse',
    'débouclâtes',
    'débouclerai',
    'déboucleras',
    'débouclerez',
    'débouclions',
    'déboudaient',
    'déboudasses',
    'débouderais',
    'débouderait',
    'déboudèrent',
    'débouderiez',
    'débouderons',
    'débouderont',
    'débouillais',
    'débouillait',
    'débouillant',
    'débouillent',
    'débouillies',
    'débouilliez',
    'débouillira',
    'débouillons',
    'déboulaient',
    'déboulasses',
    'déboulerais',
    'déboulerait',
    'déboulèrent',
    'débouleriez',
    'déboulerons',
    'débouleront',
    'déboulonnai',
    'déboulonnas',
    'déboulonnât',
    'déboulonnée',
    'déboulonner',
    'déboulonnes',
    'déboulonnés',
    'déboulonnez',
    'débouquâmes',
    'débouquasse',
    'débouquâtes',
    'débouquerai',
    'débouqueras',
    'débouquerez',
    'débouquions',
    'débourbages',
    'débourbâmes',
    'débourbasse',
    'débourbâtes',
    'débourberai',
    'débourberas',
    'débourberez',
    'débourbeurs',
    'débourbions',
    'débourrages',
    'débourrâmes',
    'débourrasse',
    'débourrâtes',
    'débourrerai',
    'débourreras',
    'débourrerez',
    'débourrions',
    'déboursâmes',
    'déboursasse',
    'déboursâtes',
    'débourserai',
    'débourseras',
    'débourserez',
    'déboursions',
    'déboussolai',
    'déboussolas',
    'déboussolât',
    'déboussolée',
    'déboussoler',
    'déboussoles',
    'déboussolés',
    'déboussolez',
    'déboutaient',
    'déboutasses',
    'déboutement',
    'débouterais',
    'débouterait',
    'déboutèrent',
    'débouteriez',
    'débouterons',
    'débouteront',
    'déboutonnai',
    'déboutonnas',
    'déboutonnât',
    'déboutonnée',
    'déboutonner',
    'déboutonnes',
    'déboutonnés',
    'déboutonnez',
    'débraguetta',
    'débraguette',
    'débraguetté',
    'débraierais',
    'débraierait',
    'débraieriez',
    'débraierons',
    'débraieront',
    'débraillais',
    'débraillait',
    'débraillant',
    'débraillées',
    'débraillent',
    'débraillera',
    'débrailliez',
    'débraillons',
    'débranchais',
    'débranchait',
    'débranchant',
    'débranchées',
    'débranchent',
    'débranchera',
    'débranchiez',
    'débranchons',
    'débrayaient',
    'débrayasses',
    'débrayerais',
    'débrayerait',
    'débrayèrent',
    'débrayeriez',
    'débrayerons',
    'débrayeront',
    'débridaient',
    'débridasses',
    'débridement',
    'débriderais',
    'débriderait',
    'débridèrent',
    'débrideriez',
    'débriderons',
    'débrideront',
    'débrochages',
    'débrochâmes',
    'débrochasse',
    'débrochâtes',
    'débrocherai',
    'débrocheras',
    'débrocherez',
    'débrochions',
    'débrouillai',
    'débrouillas',
    'débrouillât',
    'débrouillée',
    'débrouiller',
    'débrouilles',
    'débrouillés',
    'débrouillez',
    'débuchaient',
    'débuchasses',
    'débucherais',
    'débucherait',
    'débuchèrent',
    'débucheriez',
    'débucherons',
    'débucheront',
    'débudgétisa',
    'débudgétise',
    'débudgétisé',
    'débullaient',
    'débullasses',
    'débullerais',
    'débullerait',
    'débullèrent',
    'débulleriez',
    'débullerons',
    'débulleront',
    'débusquâmes',
    'débusquasse',
    'débusquâtes',
    'débusquerai',
    'débusqueras',
    'débusquerez',
    'débusquions',
    'débutassent',
    'débutassiez',
    'débuterions',
    'décachetais',
    'décachetait',
    'décachetant',
    'décachetées',
    'décachetiez',
    'décachetons',
    'décachettes',
    'décadenassa',
    'décadenasse',
    'décadenassé',
    'décaféinais',
    'décaféinait',
    'décaféinant',
    'décaféinées',
    'décaféinent',
    'décaféinera',
    'décaféiniez',
    'décaféinons',
    'décagonales',
    'décagrammes',
    'décaissâmes',
    'décaissasse',
    'décaissâtes',
    'décaisserai',
    'décaisseras',
    'décaisserez',
    'décaissions',
    'décalaminai',
    'décalaminas',
    'décalaminât',
    'décalaminée',
    'décalaminer',
    'décalamines',
    'décalaminés',
    'décalaminez',
    'décalassent',
    'décalassiez',
    'décalcifiai',
    'décalcifias',
    'décalcifiât',
    'décalcifiée',
    'décalcifier',
    'décalcifies',
    'décalcifiés',
    'décalcifiez',
    'décalerions',
    'décalottais',
    'décalottait',
    'décalottant',
    'décalottées',
    'décalottent',
    'décalottera',
    'décalottiez',
    'décalottons',
    'décalquages',
    'décalquâmes',
    'décalquasse',
    'décalquâtes',
    'décalquerai',
    'décalqueras',
    'décalquerez',
    'décalquions',
    'décalvantes',
    'décampaient',
    'décampasses',
    'décamperais',
    'décamperait',
    'décampèrent',
    'décamperiez',
    'décamperons',
    'décamperont',
    'décanillais',
    'décanillait',
    'décanillant',
    'décanillent',
    'décanillera',
    'décanilliez',
    'décanillons',
    'décantaient',
    'décantasses',
    'décantation',
    'décanterais',
    'décanterait',
    'décantèrent',
    'décanteriez',
    'décanterons',
    'décanteront',
    'décanteuses',
    'décapassent',
    'décapassiez',
    'décapelâmes',
    'décapelasse',
    'décapelâtes',
    'décapelions',
    'décapellent',
    'décapellera',
    'décaperions',
    'décapitâmes',
    'décapitasse',
    'décapitâtes',
    'décapiterai',
    'décapiteras',
    'décapiterez',
    'décapitions',
    'décapotable',
    'décapotâmes',
    'décapotasse',
    'décapotâtes',
    'décapoterai',
    'décapoteras',
    'décapoterez',
    'décapotions',
    'décapsulage',
    'décapsulais',
    'décapsulait',
    'décapsulant',
    'décapsulées',
    'décapsulent',
    'décapsulera',
    'décapsuleur',
    'décapsuliez',
    'décapsulons',
    'décarbonata',
    'décarbonate',
    'décarbonaté',
    'décarburais',
    'décarburait',
    'décarburant',
    'décarburées',
    'décarburent',
    'décarburera',
    'décarburiez',
    'décarburons',
    'décarcassai',
    'décarcassas',
    'décarcassât',
    'décarcassée',
    'décarcasser',
    'décarcasses',
    'décarcassés',
    'décarcassez',
    'décarraient',
    'décarrasses',
    'décarrelais',
    'décarrelait',
    'décarrelant',
    'décarrelées',
    'décarreliez',
    'décarrelles',
    'décarrelons',
    'décarrerais',
    'décarrerait',
    'décarrèrent',
    'décarreriez',
    'décarrerons',
    'décarreront',
    'décartonnai',
    'décartonnas',
    'décartonnât',
    'décartonnée',
    'décartonner',
    'décartonnes',
    'décartonnés',
    'décartonnez',
    'décasyllabe',
    'décatirions',
    'décatissais',
    'décatissait',
    'décatissant',
    'décatissent',
    'décatissiez',
    'décatissons',
    'décausaient',
    'décausasses',
    'décauserais',
    'décauserait',
    'décausèrent',
    'décauseriez',
    'décauserons',
    'décauseront',
    'decauvilles',
    'décédassent',
    'décédassiez',
    'décéderions',
    'décelassent',
    'décelassiez',
    'décèlements',
    'décélérâmes',
    'décélérasse',
    'décélérâtes',
    'décélérerai',
    'décéléreras',
    'décélérerez',
    'décélérions',
    'décèlerions',
    'décemvirale',
    'décemvirats',
    'décemviraux',
    'décentrâmes',
    'décentrasse',
    'décentrâtes',
    'décentrerai',
    'décentreras',
    'décentrerez',
    'décentrions',
    'décerclâmes',
    'décerclasse',
    'décerclâtes',
    'décerclerai',
    'décercleras',
    'décerclerez',
    'décerclions',
    'décernaient',
    'décernasses',
    'décernerais',
    'décernerait',
    'décernèrent',
    'décerneriez',
    'décernerons',
    'décerneront',
    'décervelage',
    'décessaient',
    'décessasses',
    'décesserais',
    'décesserait',
    'décessèrent',
    'décesseriez',
    'décesserons',
    'décesseront',
    'décevraient',
    'déchagrinai',
    'déchagrinas',
    'déchagrinât',
    'déchagrinée',
    'déchagriner',
    'déchagrines',
    'déchagrinés',
    'déchagrinez',
    'déchaînâmes',
    'déchaînasse',
    'déchaînâtes',
    'déchaînerai',
    'déchaîneras',
    'déchaînerez',
    'déchaînions',
    'déchantâmes',
    'déchantasse',
    'déchantâtes',
    'déchanterai',
    'déchanteras',
    'déchanterez',
    'déchantions',
    'déchapaient',
    'déchapasses',
    'déchaperais',
    'déchaperait',
    'déchapèrent',
    'déchaperiez',
    'déchaperons',
    'déchaperont',
    'déchargeais',
    'déchargeait',
    'déchargeant',
    'déchargeoir',
    'déchargeons',
    'déchargerai',
    'déchargeras',
    'déchargerez',
    'déchargions',
    'décharnâmes',
    'décharnasse',
    'décharnâtes',
    'décharnerai',
    'décharneras',
    'décharnerez',
    'décharnions',
    'déchaumages',
    'déchaumâmes',
    'déchaumasse',
    'déchaumâtes',
    'déchaumerai',
    'déchaumeras',
    'déchaumerez',
    'déchaumeuse',
    'déchaumions',
    'déchaussage',
    'déchaussais',
    'déchaussait',
    'déchaussant',
    'déchaussées',
    'déchaussent',
    'déchaussera',
    'déchaussiez',
    'déchaussons',
    'déchevêtrai',
    'déchevêtras',
    'déchevêtrât',
    'déchevêtrée',
    'déchevêtrer',
    'déchevêtres',
    'déchevêtrés',
    'déchevêtrez',
    'déchevillai',
    'déchevillas',
    'déchevillât',
    'déchevillée',
    'décheviller',
    'déchevilles',
    'déchevillés',
    'déchevillez',
    'déchiffonna',
    'déchiffonne',
    'déchiffonné',
    'déchiffrage',
    'déchiffrais',
    'déchiffrait',
    'déchiffrant',
    'déchiffrées',
    'déchiffrent',
    'déchiffrera',
    'déchiffriez',
    'déchiffrons',
    'déchiquetai',
    'déchiquetas',
    'déchiquetât',
    'déchiquetée',
    'déchiqueter',
    'déchiquetés',
    'déchiquetez',
    'déchiquette',
    'déchiraient',
    'déchirantes',
    'déchirasses',
    'déchirement',
    'déchirerais',
    'déchirerait',
    'déchirèrent',
    'déchireriez',
    'déchirerons',
    'déchireront',
    'déchlorurai',
    'déchloruras',
    'déchlorurât',
    'déchlorurée',
    'déchlorurer',
    'déchlorures',
    'déchlorurés',
    'déchlorurez',
    'déchoirions',
    'déchromâmes',
    'déchromasse',
    'déchromâtes',
    'déchromerai',
    'déchromeras',
    'déchromerez',
    'déchromions',
    'déchussions',
    'décidassent',
    'décidassiez',
    'déciderions',
    'décigrammes',
    'décimalisai',
    'décimalisas',
    'décimalisât',
    'décimalisée',
    'décimaliser',
    'décimalises',
    'décimalisés',
    'décimalisez',
    'décimalités',
    'décimassent',
    'décimassiez',
    'décimations',
    'décimerions',
    'décintrages',
    'décintrâmes',
    'décintrasse',
    'décintrâtes',
    'décintrerai',
    'décintreras',
    'décintrerât',
    'décintrions',
    'décisionnel',
    'déclamaient',
    'déclamasses',
    'déclamateur',
    'déclamation',
    'déclamerais',
    'déclamerait',
    'déclamèrent',
    'déclameriez',
    'déclamerons',
    'déclameront',
    'déclaraient',
    'déclarantes',
    'déclarasses',
    'déclaratifs',
    'déclaration',
    'déclarative',
    'déclarerais',
    'déclarerait',
    'déclarèrent',
    'déclareriez',
    'déclarerons',
    'déclareront',
    'déclassâmes',
    'déclassasse',
    'déclassâtes',
    'déclasserai',
    'déclasseras',
    'déclasserez',
    'déclassions',
    'déclavetais',
    'déclavetait',
    'déclavetant',
    'déclavetées',
    'déclavetiez',
    'déclavetons',
    'déclavettes',
    'déclenchais',
    'déclenchait',
    'déclenchant',
    'déclenchées',
    'déclenchent',
    'déclenchera',
    'déclencheur',
    'déclenchiez',
    'déclenchons',
    'déclinables',
    'déclinaient',
    'déclinaison',
    'déclinantes',
    'déclinasses',
    'déclinerais',
    'déclinerait',
    'déclinèrent',
    'déclineriez',
    'déclinerons',
    'déclineront',
    'décliquetai',
    'décliquetas',
    'décliquetât',
    'décliquetée',
    'décliqueter',
    'décliquetés',
    'décliquetez',
    'décliquette',
    'décloisonna',
    'décloisonne',
    'décloisonné',
    'décloraient',
    'déclouaient',
    'déclouasses',
    'déclouerais',
    'déclouerait',
    'déclouèrent',
    'décloueriez',
    'déclouerons',
    'décloueront',
    'décochaient',
    'décochasses',
    'décocherais',
    'décocherait',
    'décochèrent',
    'décocheriez',
    'décocherons',
    'décocheront',
    'décodassent',
    'décodassiez',
    'décoderions',
    'décoffrages',
    'décoffrâmes',
    'décoffrasse',
    'décoffrâtes',
    'décoffrerai',
    'décoffreras',
    'décoffrerez',
    'décoffrions',
    'décoiffages',
    'décoiffâmes',
    'décoiffasse',
    'décoiffâtes',
    'décoifferai',
    'décoifferas',
    'décoifferez',
    'décoiffions',
    'décoinçâmes',
    'décoinçasse',
    'décoinçâtes',
    'décoincerai',
    'décoinceras',
    'décoincerez',
    'décoincions',
    'décolérâmes',
    'décolérasse',
    'décolérâtes',
    'décolérerai',
    'décoléreras',
    'décolérerez',
    'décolérions',
    'décollaient',
    'décollasses',
    'décollation',
    'décollement',
    'décollerais',
    'décollerait',
    'décollèrent',
    'décolleriez',
    'décollerons',
    'décolleront',
    'décolletage',
    'décolletais',
    'décolletait',
    'décolletant',
    'décolletées',
    'décolleteur',
    'décolletiez',
    'décolletons',
    'décollettes',
    'décolonisai',
    'décolonisas',
    'décolonisât',
    'décolonisée',
    'décoloniser',
    'décolonises',
    'décolonisés',
    'décolonisez',
    'décolorâmes',
    'décolorasse',
    'décolorâtes',
    'décolorerai',
    'décoloreras',
    'décolorerez',
    'décolorions',
    'décommandai',
    'décommandas',
    'décommandât',
    'décommandée',
    'décommander',
    'décommandes',
    'décommandés',
    'décommandez',
    'décomplexai',
    'décomplexas',
    'décomplexât',
    'décomplexée',
    'décomplexer',
    'décomplexes',
    'décomplexés',
    'décomplexez',
    'décomposais',
    'décomposait',
    'décomposant',
    'décomposées',
    'décomposent',
    'décomposera',
    'décomposiez',
    'décomposons',
    'décompressa',
    'décompresse',
    'décompressé',
    'décomprimai',
    'décomprimas',
    'décomprimât',
    'décomprimée',
    'décomprimer',
    'décomprimes',
    'décomprimés',
    'décomprimez',
    'décomptâmes',
    'décomptasse',
    'décomptâtes',
    'décompterai',
    'décompteras',
    'décompterez',
    'décomptions',
    'déconcentra',
    'déconcentre',
    'déconcentré',
    'déconcertai',
    'déconcertas',
    'déconcertât',
    'déconcertée',
    'déconcerter',
    'déconcertes',
    'déconcertés',
    'déconcertez',
    'déconfirais',
    'déconfirait',
    'déconfirent',
    'déconfiriez',
    'déconfirons',
    'déconfiront',
    'déconfisais',
    'déconfisait',
    'déconfisant',
    'déconfisent',
    'déconfisiez',
    'déconfisons',
    'déconfisses',
    'déconfiture',
    'décongelais',
    'décongelait',
    'décongelant',
    'décongelées',
    'décongèlent',
    'décongèlera',
    'décongeliez',
    'décongelons',
    'décongestif',
    'déconnaient',
    'déconnasses',
    'déconnectai',
    'déconnectas',
    'déconnectât',
    'déconnectée',
    'déconnecter',
    'déconnectes',
    'déconnectés',
    'déconnectez',
    'déconnerais',
    'déconnerait',
    'déconnèrent',
    'déconneriez',
    'déconnerons',
    'déconneront',
    'déconnexion',
    'déconseilla',
    'déconseille',
    'déconseillé',
    'déconsidéra',
    'déconsidéré',
    'déconsidère',
    'déconsignai',
    'déconsignas',
    'déconsignât',
    'déconsignée',
    'déconsigner',
    'déconsignes',
    'déconsignés',
    'déconsignez',
    'déconstipai',
    'déconstipas',
    'déconstipât',
    'déconstipée',
    'déconstiper',
    'déconstipes',
    'déconstipés',
    'déconstipez',
    'décontamina',
    'décontamine',
    'décontaminé',
    'décontracta',
    'décontracte',
    'décontracté',
    'déconvenues',
    'décorassent',
    'décorassiez',
    'décorateurs',
    'décorations',
    'décoratives',
    'décoratrice',
    'décordaient',
    'décordasses',
    'décorderais',
    'décorderait',
    'décordèrent',
    'décorderiez',
    'décorderons',
    'décorderont',
    'décorerions',
    'décornaient',
    'décornasses',
    'décornerais',
    'décornerait',
    'décornèrent',
    'décorneriez',
    'décornerons',
    'décorneront',
    'décorticage',
    'décortiquai',
    'décortiquas',
    'décortiquât',
    'décortiquée',
    'décortiquer',
    'décortiques',
    'décortiqués',
    'décortiquez',
    'découchâmes',
    'découchasse',
    'découchâtes',
    'découcherai',
    'découcheras',
    'découcherez',
    'découchions',
    'découdrions',
    'découlaient',
    'découlasses',
    'découlerais',
    'découlerait',
    'découlèrent',
    'découleriez',
    'découlerons',
    'découleront',
    'découpaient',
    'découpasses',
    'découperais',
    'découperait',
    'découpèrent',
    'découperiez',
    'découperons',
    'découperont',
    'découpeuses',
    'découplages',
    'découplâmes',
    'découplasse',
    'découplâtes',
    'découplerai',
    'découpleras',
    'découplerez',
    'découplions',
    'décourageai',
    'décourageas',
    'décourageât',
    'découragées',
    'découragent',
    'découragera',
    'découragiez',
    'découronnai',
    'découronnas',
    'découronnât',
    'découronnée',
    'découronner',
    'découronnes',
    'découronnés',
    'découronnez',
    'décousaient',
    'décousirent',
    'décousisses',
    'découvertes',
    'découvreurs',
    'découvreuse',
    'découvrîmes',
    'découvrions',
    'découvrirai',
    'découvriras',
    'découvrirez',
    'découvrisse',
    'découvrîtes',
    'décrassages',
    'décrassâmes',
    'décrassasse',
    'décrassâtes',
    'décrasserai',
    'décrasseras',
    'décrasserez',
    'décrassions',
    'décréditais',
    'décréditait',
    'décréditant',
    'décréditées',
    'décréditent',
    'décréditera',
    'décréditiez',
    'décréditons',
    'décrêpaient',
    'décrêpasses',
    'décrêperais',
    'décrêperait',
    'décrêpèrent',
    'décrêperiez',
    'décrêperons',
    'décrêperont',
    'décrépirais',
    'décrépirait',
    'décrépirent',
    'décrépiriez',
    'décrépirons',
    'décrépiront',
    'décrépisses',
    'décrépissez',
    'décrépitais',
    'décrépitait',
    'décrépitant',
    'décrépitées',
    'décrépitent',
    'décrépitera',
    'décrépitiez',
    'décrépitons',
    'décrépitude',
    'décrétaient',
    'décrétasses',
    'décréterais',
    'décréterait',
    'décrétèrent',
    'décréteriez',
    'décréterons',
    'décréteront',
    'décreusages',
    'décreusâmes',
    'décreusasse',
    'décreusâtes',
    'décreuserai',
    'décreuseras',
    'décreuserez',
    'décreusions',
    'décriassent',
    'décriassiez',
    'décrierions',
    'décriraient',
    'décrispâmes',
    'décrispasse',
    'décrispâtes',
    'décrisperai',
    'décrisperas',
    'décrisperez',
    'décrispions',
    'décrivaient',
    'décrivirent',
    'décrivisses',
    'décrochages',
    'décrochâmes',
    'décrochasse',
    'décrochâtes',
    'décrocherai',
    'décrocheras',
    'décrocherez',
    'décrochions',
    'décroisâmes',
    'décroisasse',
    'décroisâtes',
    'décroiserai',
    'décroiseras',
    'décroiserez',
    'décroisions',
    'décroissais',
    'décroissait',
    'décroissant',
    'décroissent',
    'décroissiez',
    'décroissons',
    'décroîtrais',
    'décroîtrait',
    'décroîtriez',
    'décroîtrons',
    'décroîtront',
    'décrottages',
    'décrottâmes',
    'décrottasse',
    'décrottâtes',
    'décrotterai',
    'décrotteras',
    'décrotterez',
    'décrotteurs',
    'décrottions',
    'décrottoirs',
    'décroûtâmes',
    'décroûtasse',
    'décroûtâtes',
    'décroûterai',
    'décroûteras',
    'décroûterez',
    'décroûtions',
    'décruassent',
    'décruassiez',
    'décruerions',
    'décrusaient',
    'décrusasses',
    'décruserais',
    'décruserait',
    'décrusèrent',
    'décruseriez',
    'décruserons',
    'décruseront',
    'décrussions',
    'décryptages',
    'décryptâmes',
    'décryptasse',
    'décryptâtes',
    'décrypterai',
    'décrypteras',
    'décrypterez',
    'décryptions',
    'décuivrâmes',
    'décuivrasse',
    'décuivrâtes',
    'décuivrerai',
    'décuivreras',
    'décuivrerez',
    'décuivrions',
    'déculassais',
    'déculassait',
    'déculassant',
    'déculassées',
    'déculassent',
    'déculassera',
    'déculassiez',
    'déculassons',
    'déculottais',
    'déculottait',
    'déculottant',
    'déculottées',
    'déculottent',
    'déculottera',
    'déculottiez',
    'déculottons',
    'décuplaient',
    'décuplasses',
    'décuplement',
    'décuplerais',
    'décuplerait',
    'décuplèrent',
    'décupleriez',
    'décuplerons',
    'décupleront',
    'décurrentes',
    'décuvaisons',
    'décuvassent',
    'décuvassiez',
    'décuverions',
    'dédaignable',
    'dédaignâmes',
    'dédaignasse',
    'dédaignâtes',
    'dédaignerai',
    'dédaigneras',
    'dédaignerez',
    'dédaigneuse',
    'dédaignions',
    'dédaléennes',
    'dédiassions',
    'dédicaçâmes',
    'dédicaçasse',
    'dédicaçâtes',
    'dédicacerai',
    'dédicaceras',
    'dédicacerez',
    'dédicacions',
    'dédicataire',
    'dédicatoire',
    'dédieraient',
    'dédommageai',
    'dédommageas',
    'dédommageât',
    'dédommagées',
    'dédommagent',
    'dédommagera',
    'dédommagiez',
    'dédorassent',
    'dédorassiez',
    'dédorerions',
    'dédouanâmes',
    'dédouanasse',
    'dédouanâtes',
    'dédouanerai',
    'dédouaneras',
    'dédouanerez',
    'dédouanions',
    'dédoublages',
    'dédoublâmes',
    'dédoublasse',
    'dédoublâtes',
    'dédoublerai',
    'dédoubleras',
    'dédoublerez',
    'dédoublions',
    'dédramatisa',
    'dédramatise',
    'dédramatisé',
    'déductibles',
    'déduiraient',
    'déduisaient',
    'déduisirent',
    'déduisisses',
    'défaillance',
    'défaillante',
    'défaillants',
    'défaillîmes',
    'défaillions',
    'défaillirai',
    'défailliras',
    'défaillirez',
    'défaillisse',
    'défaillîtes',
    'défaisaient',
    'défaitismes',
    'défaitistes',
    'défalcation',
    'défalquâmes',
    'défalquasse',
    'défalquâtes',
    'défalquerai',
    'défalqueras',
    'défalquerez',
    'défalquions',
    'défardaient',
    'défardasses',
    'défarderais',
    'défarderait',
    'défardèrent',
    'défarderiez',
    'défarderons',
    'défarderont',
    'défatiguais',
    'défatiguait',
    'défatiguant',
    'défatiguées',
    'défatiguent',
    'défatiguera',
    'défatiguiez',
    'défatiguons',
    'défaufilais',
    'défaufilait',
    'défaufilant',
    'défaufilées',
    'défaufilent',
    'défaufilera',
    'défaufiliez',
    'défaufilons',
    'défaussâmes',
    'défaussasse',
    'défaussâtes',
    'défausserai',
    'défausseras',
    'défausserez',
    'défaussions',
    'défavorable',
    'défavorisai',
    'défavorisas',
    'défavorisât',
    'défavorisée',
    'défavoriser',
    'défavorises',
    'défavorisés',
    'défavorisez',
    'défécations',
    'défectueuse',
    'défendables',
    'défendaient',
    'défendirent',
    'défendisses',
    'défendrions',
    'déféquaient',
    'déféquasses',
    'déféquerais',
    'déféquerait',
    'déféquèrent',
    'déféqueriez',
    'déféquerons',
    'déféqueront',
    'déférassent',
    'déférassiez',
    'déférerions',
    'déferlaient',
    'déferlantes',
    'déferlasses',
    'déferlement',
    'déferlerais',
    'déferlerait',
    'déferlèrent',
    'déferleriez',
    'déferlerons',
    'déferleront',
    'déferraient',
    'déferrasses',
    'déferrement',
    'déferrerais',
    'déferrerait',
    'déferrèrent',
    'déferreriez',
    'déferrerons',
    'déferreront',
    'déferrisais',
    'déferrisait',
    'déferrisant',
    'déferrisées',
    'déferrisent',
    'déferrisera',
    'déferrisiez',
    'déferrisons',
    'défeuillais',
    'défeuillait',
    'défeuillant',
    'défeuillées',
    'défeuillent',
    'défeuillera',
    'défeuilliez',
    'défeuillons',
    'défeutrâmes',
    'défeutrasse',
    'défeutrâtes',
    'défeutrerai',
    'défeutreras',
    'défeutrerez',
    'défeutrions',
    'défiassions',
    'défibraient',
    'défibrasses',
    'défibrerais',
    'défibrerait',
    'défibrèrent',
    'défibreriez',
    'défibrerons',
    'défibreront',
    'défibreuses',
    'déficelâmes',
    'déficelasse',
    'déficelâtes',
    'déficelions',
    'déficellent',
    'déficellera',
    'défichaient',
    'défichasses',
    'déficherais',
    'déficherait',
    'défichèrent',
    'déficheriez',
    'déficherons',
    'déficheront',
    'déficiences',
    'déficientes',
    'déficitaire',
    'défieraient',
    'défigeaient',
    'défigeasses',
    'défigerions',
    'défigurâmes',
    'défigurasse',
    'défigurâtes',
    'défigurerai',
    'défigureras',
    'défigurerez',
    'défigurions',
    'défilassent',
    'défilassiez',
    'défilements',
    'défilerions',
    'définirions',
    'définissais',
    'définissait',
    'définissant',
    'définissent',
    'définissiez',
    'définissons',
    'définiteurs',
    'définitions',
    'définitives',
    'définitoire',
    'déflagrâmes',
    'déflagrante',
    'déflagrants',
    'déflagrasse',
    'déflagrâtes',
    'déflagrerai',
    'déflagreras',
    'déflagrerez',
    'déflagrions',
    'déflaquâmes',
    'déflaquasse',
    'déflaquâtes',
    'déflaquerai',
    'déflaqueras',
    'déflaquerez',
    'déflaquions',
    'déflecteurs',
    'défleurîmes',
    'défleurirai',
    'défleuriras',
    'défleurirez',
    'défleurisse',
    'défleurîtes',
    'défloraient',
    'déflorasses',
    'défloration',
    'déflorerais',
    'déflorerait',
    'déflorèrent',
    'défloreriez',
    'déflorerons',
    'défloreront',
    'défoliaient',
    'défoliantes',
    'défoliasses',
    'défoliation',
    'défolierais',
    'défolierait',
    'défolièrent',
    'défolieriez',
    'défolierons',
    'défolieront',
    'défonçaient',
    'défonçasses',
    'défoncement',
    'défoncerais',
    'défoncerait',
    'défoncèrent',
    'défonceriez',
    'défoncerons',
    'défonceront',
    'défonceuses',
    'déforçaient',
    'déforçasses',
    'déforcerais',
    'déforcerait',
    'déforcèrent',
    'déforceriez',
    'déforcerons',
    'déforceront',
    'déformables',
    'déformaient',
    'déformantes',
    'déformasses',
    'déformateur',
    'déformation',
    'déformerais',
    'déformerait',
    'déformèrent',
    'déformeriez',
    'déformerons',
    'déformeront',
    'défoulaient',
    'défoulasses',
    'défoulement',
    'défoulerais',
    'défoulerait',
    'défoulèrent',
    'défouleriez',
    'défoulerons',
    'défouleront',
    'défourailla',
    'défouraille',
    'défouraillé',
    'défournages',
    'défournâmes',
    'défournasse',
    'défournâtes',
    'défournerai',
    'défourneras',
    'défournerez',
    'défourneurs',
    'défourneuse',
    'défournions',
    'défourrâmes',
    'défourrasse',
    'défourrâtes',
    'défourrerai',
    'défourreras',
    'défourrerez',
    'défourrions',
    'défraîchies',
    'défraîchira',
    'défraierais',
    'défraierait',
    'défraieriez',
    'défraierons',
    'défraieront',
    'défranchies',
    'défrancisai',
    'défrancisas',
    'défrancisât',
    'défrancisée',
    'défranciser',
    'défrancises',
    'défrancisés',
    'défrancisez',
    'défrayaient',
    'défrayasses',
    'défrayerais',
    'défrayerait',
    'défrayèrent',
    'défrayeriez',
    'défrayerons',
    'défrayeront',
    'défrettâmes',
    'défrettasse',
    'défrettâtes',
    'défretterai',
    'défretteras',
    'défretterez',
    'défrettions',
    'défrichages',
    'défrichâmes',
    'défrichasse',
    'défrichâtes',
    'défricherai',
    'défricheras',
    'défricherez',
    'défricheurs',
    'défricheuse',
    'défrichions',
    'défringuais',
    'défringuait',
    'défringuant',
    'défringuées',
    'défringuent',
    'défringuera',
    'défringuiez',
    'défringuons',
    'défripaient',
    'défripasses',
    'défriperais',
    'défriperait',
    'défripèrent',
    'défriperiez',
    'défriperons',
    'défriperont',
    'défrisaient',
    'défrisasses',
    'défrisement',
    'défriserais',
    'défriserait',
    'défrisèrent',
    'défriseriez',
    'défriserons',
    'défriseront',
    'défroissais',
    'défroissait',
    'défroissant',
    'défroissées',
    'défroissent',
    'défroissera',
    'défroissiez',
    'défroissons',
    'défronçâmes',
    'défronçasse',
    'défronçâtes',
    'défroncerai',
    'défronceras',
    'défroncerez',
    'défroncions',
    'défroquâmes',
    'défroquasse',
    'défroquâtes',
    'défroquerai',
    'défroqueras',
    'défroquerez',
    'défroquions',
    'défruitâmes',
    'défruitasse',
    'défruitâtes',
    'défruiterai',
    'défruiteras',
    'défruiterez',
    'défruitions',
    'dégageaient',
    'dégageasses',
    'dégagements',
    'dégagerions',
    'dégainaient',
    'dégainasses',
    'dégainerais',
    'dégainerait',
    'dégainèrent',
    'dégaineriez',
    'dégainerons',
    'dégaineront',
    'dégalonnais',
    'dégalonnait',
    'dégalonnant',
    'dégalonnées',
    'dégalonnent',
    'dégalonnera',
    'dégalonniez',
    'dégalonnons',
    'dégantaient',
    'dégantasses',
    'déganterais',
    'déganterait',
    'dégantèrent',
    'déganteriez',
    'déganterons',
    'déganteront',
    'dégarnirais',
    'dégarnirait',
    'dégarnirent',
    'dégarniriez',
    'dégarnirons',
    'dégarniront',
    'dégarnisses',
    'dégarnissez',
    'dégasolinai',
    'dégasolinas',
    'dégasolinât',
    'dégasolinée',
    'dégasoliner',
    'dégasolines',
    'dégasolinés',
    'dégasolinez',
    'dégauchîmes',
    'dégauchirai',
    'dégauchiras',
    'dégauchirez',
    'dégauchisse',
    'dégauchîtes',
    'dégazassent',
    'dégazassiez',
    'dégazerions',
    'dégazolinai',
    'dégazolinas',
    'dégazolinât',
    'dégazolinée',
    'dégazoliner',
    'dégazolines',
    'dégazolinés',
    'dégazolinez',
    'dégazonnage',
    'dégazonnais',
    'dégazonnait',
    'dégazonnant',
    'dégazonnées',
    'dégazonnent',
    'dégazonnera',
    'dégazonniez',
    'dégazonnons',
    'dégelassent',
    'dégelassiez',
    'dégèlerions',
    'dégénérâmes',
    'dégénérasse',
    'dégénérâtes',
    'dégénératif',
    'dégénérerai',
    'dégénéreras',
    'dégénérerez',
    'dégénérions',
    'dégermaient',
    'dégermasses',
    'dégermerais',
    'dégermerait',
    'dégermèrent',
    'dégermeriez',
    'dégermerons',
    'dégermeront',
    'dégingandai',
    'dégingandas',
    'dégingandât',
    'dégingandée',
    'dégingander',
    'dégingandes',
    'dégingandés',
    'dégingandez',
    'dégîtassent',
    'dégîtassiez',
    'dégîterions',
    'dégivraient',
    'dégivrasses',
    'dégivrerais',
    'dégivrerait',
    'dégivrèrent',
    'dégivreriez',
    'dégivrerons',
    'dégivreront',
    'déglaçaient',
    'déglaçasses',
    'déglacement',
    'déglacerais',
    'déglacerait',
    'déglacèrent',
    'déglaceriez',
    'déglacerons',
    'déglaceront',
    'déglinguais',
    'déglinguait',
    'déglinguant',
    'déglinguées',
    'déglinguent',
    'déglinguera',
    'déglinguiez',
    'déglinguons',
    'dégluassent',
    'dégluassiez',
    'dégluerions',
    'déglutirais',
    'déglutirait',
    'déglutirent',
    'déglutiriez',
    'déglutirons',
    'déglutiront',
    'déglutisses',
    'déglutissez',
    'déglutition',
    'dégobillais',
    'dégobillait',
    'dégobillant',
    'dégobillées',
    'dégobillent',
    'dégobillera',
    'dégobilliez',
    'dégobillons',
    'dégoisaient',
    'dégoisasses',
    'dégoiserais',
    'dégoiserait',
    'dégoisèrent',
    'dégoiseriez',
    'dégoiserons',
    'dégoiseront',
    'dégommaient',
    'dégommasses',
    'dégommerais',
    'dégommerait',
    'dégommèrent',
    'dégommeriez',
    'dégommerons',
    'dégommeront',
    'dégondaient',
    'dégondasses',
    'dégonderais',
    'dégonderait',
    'dégondèrent',
    'dégonderiez',
    'dégonderons',
    'dégonderont',
    'dégonflages',
    'dégonflâmes',
    'dégonflasse',
    'dégonflâtes',
    'dégonflerai',
    'dégonfleras',
    'dégonflerez',
    'dégonflions',
    'dégorgeâmes',
    'dégorgeasse',
    'dégorgeâtes',
    'dégorgement',
    'dégorgeoirs',
    'dégorgerais',
    'dégorgerait',
    'dégorgèrent',
    'dégorgeriez',
    'dégorgerons',
    'dégorgeront',
    'dégotassent',
    'dégotassiez',
    'dégoterions',
    'dégottaient',
    'dégottasses',
    'dégotterais',
    'dégotterait',
    'dégottèrent',
    'dégotteriez',
    'dégotterons',
    'dégotteront',
    'dégoudronna',
    'dégoudronne',
    'dégoudronné',
    'dégoulinade',
    'dégoulinais',
    'dégoulinait',
    'dégoulinant',
    'dégoulinent',
    'dégoulinera',
    'dégouliniez',
    'dégoulinons',
    'dégoupillai',
    'dégoupillas',
    'dégoupillât',
    'dégoupillée',
    'dégoupiller',
    'dégoupilles',
    'dégoupillés',
    'dégoupillez',
    'dégourdîmes',
    'dégourdirai',
    'dégourdiras',
    'dégourdirez',
    'dégourdisse',
    'dégourdîtes',
    'dégoûtaient',
    'dégoûtantes',
    'dégoûtasses',
    'dégoûtation',
    'dégoûterais',
    'dégoûterait',
    'dégoûtèrent',
    'dégoûteriez',
    'dégoûterons',
    'dégoûteront',
    'dégouttâmes',
    'dégouttasse',
    'dégouttâtes',
    'dégoutterai',
    'dégoutteras',
    'dégoutterez',
    'dégouttions',
    'dégradaient',
    'dégradantes',
    'dégradasses',
    'dégradateur',
    'dégradation',
    'dégraderais',
    'dégraderait',
    'dégradèrent',
    'dégraderiez',
    'dégraderons',
    'dégraderont',
    'dégrafaient',
    'dégrafasses',
    'dégraferais',
    'dégraferait',
    'dégrafèrent',
    'dégraferiez',
    'dégraferons',
    'dégraferont',
    'dégraissage',
    'dégraissais',
    'dégraissait',
    'dégraissant',
    'dégraissées',
    'dégraissent',
    'dégraissera',
    'dégraissiez',
    'dégraissons',
    'dégravoient',
    'dégravoiera',
    'dégravoyais',
    'dégravoyait',
    'dégravoyant',
    'dégravoyées',
    'dégravoyiez',
    'dégravoyons',
    'dégréassent',
    'dégréassiez',
    'dégréerions',
    'dégressives',
    'dégrevaient',
    'dégrevasses',
    'dégrèvement',
    'dégrèverais',
    'dégrèverait',
    'dégrevèrent',
    'dégrèveriez',
    'dégrèverons',
    'dégrèveront',
    'dégringolai',
    'dégringolas',
    'dégringolât',
    'dégringolée',
    'dégringoler',
    'dégringoles',
    'dégringolés',
    'dégringolez',
    'dégrippâmes',
    'dégrippasse',
    'dégrippâtes',
    'dégripperai',
    'dégripperas',
    'dégripperez',
    'dégrippions',
    'dégrisaient',
    'dégrisasses',
    'dégrisement',
    'dégriserais',
    'dégriserait',
    'dégrisèrent',
    'dégriseriez',
    'dégriserons',
    'dégriseront',
    'dégrossâmes',
    'dégrossasse',
    'dégrossâtes',
    'dégrosserai',
    'dégrosseras',
    'dégrosserez',
    'dégrossîmes',
    'dégrossions',
    'dégrossirai',
    'dégrossiras',
    'dégrossirez',
    'dégrossisse',
    'dégrossîtes',
    'dégrouillai',
    'dégrouillas',
    'dégrouillât',
    'dégrouillée',
    'dégrouiller',
    'dégrouilles',
    'dégrouillés',
    'dégrouillez',
    'déguenillée',
    'déguenillés',
    'déguerpîmes',
    'déguerpirai',
    'déguerpiras',
    'déguerpirez',
    'déguerpisse',
    'déguerpîtes',
    'dégueulâmes',
    'dégueulasse',
    'dégueulâtes',
    'dégueulerai',
    'dégueuleras',
    'dégueulerez',
    'dégueulions',
    'déguisaient',
    'déguisasses',
    'déguisement',
    'déguiserais',
    'déguiserait',
    'déguisèrent',
    'déguiseriez',
    'déguiserons',
    'déguiseront',
    'dégurgitais',
    'dégurgitait',
    'dégurgitant',
    'dégurgitées',
    'dégurgitent',
    'dégurgitera',
    'dégurgitiez',
    'dégurgitons',
    'dégustaient',
    'dégustasses',
    'dégustateur',
    'dégustation',
    'dégusterais',
    'dégusterait',
    'dégustèrent',
    'dégusteriez',
    'dégusterons',
    'dégusteront',
    'déhalassent',
    'déhalassiez',
    'déhalerions',
    'déhanchâmes',
    'déhanchasse',
    'déhanchâtes',
    'déhancherai',
    'déhancheras',
    'déhancherez',
    'déhanchions',
    'déhardaient',
    'déhardasses',
    'déharderais',
    'déharderait',
    'déhardèrent',
    'déharderiez',
    'déharderons',
    'déharderont',
    'déharnachai',
    'déharnachas',
    'déharnachât',
    'déharnachée',
    'déharnacher',
    'déharnaches',
    'déharnachés',
    'déharnachez',
    'déhiscences',
    'déhiscentes',
    'déhottaient',
    'déhottasses',
    'déhotterais',
    'déhotterait',
    'déhottèrent',
    'déhotteriez',
    'déhotterons',
    'déhotteront',
    'déhouillais',
    'déhouillait',
    'déhouillant',
    'déhouillées',
    'déhouillent',
    'déhouillera',
    'déhouilliez',
    'déhouillons',
    'déifiassent',
    'déifiassiez',
    'déification',
    'déifierions',
    'déjantaient',
    'déjantasses',
    'déjanterais',
    'déjanterait',
    'déjantèrent',
    'déjanteriez',
    'déjanterons',
    'déjanteront',
    'déjaugeâmes',
    'déjaugeasse',
    'déjaugeâtes',
    'déjaugerais',
    'déjaugerait',
    'déjaugèrent',
    'déjaugeriez',
    'déjaugerons',
    'déjaugeront',
    'déjaunirais',
    'déjaunirait',
    'déjaunirent',
    'déjauniriez',
    'déjaunirons',
    'déjauniront',
    'déjaunisses',
    'déjaunissez',
    'déjetassent',
    'déjetassiez',
    'déjetterais',
    'déjetterait',
    'déjetteriez',
    'déjetterons',
    'déjetteront',
    'déjeunaient',
    'déjeunasses',
    'déjeunerais',
    'déjeunerait',
    'déjeunèrent',
    'déjeuneriez',
    'déjeunerons',
    'déjeuneront',
    'déjouassent',
    'déjouassiez',
    'déjouerions',
    'déjuchaient',
    'déjuchasses',
    'déjucherais',
    'déjucherait',
    'déjuchèrent',
    'déjucheriez',
    'déjucherons',
    'déjucheront',
    'déjugeaient',
    'déjugeasses',
    'déjugerions',
    'délabialisa',
    'délabialise',
    'délabialisé',
    'délabraient',
    'délabrasses',
    'délabrement',
    'délabrerais',
    'délabrerait',
    'délabrèrent',
    'délabreriez',
    'délabrerons',
    'délabreront',
    'délaçassent',
    'délaçassiez',
    'délacerions',
    'délaierions',
    'délainaient',
    'délainasses',
    'délainerais',
    'délainerait',
    'délainèrent',
    'délaineriez',
    'délainerons',
    'délaineront',
    'délaissâmes',
    'délaissasse',
    'délaissâtes',
    'délaisserai',
    'délaisseras',
    'délaisserez',
    'délaissions',
    'délaitaient',
    'délaitasses',
    'délaiterais',
    'délaiterait',
    'délaitèrent',
    'délaiteriez',
    'délaiterons',
    'délaiteront',
    'délaiteuses',
    'délardaient',
    'délardasses',
    'délarderais',
    'délarderait',
    'délardèrent',
    'délarderiez',
    'délarderons',
    'délarderont',
    'délassaient',
    'délassantes',
    'délassasses',
    'délassement',
    'délasserais',
    'délasserait',
    'délassèrent',
    'délasseriez',
    'délasserons',
    'délasseront',
    'délattaient',
    'délattasses',
    'délatterais',
    'délatterait',
    'délattèrent',
    'délatteriez',
    'délatterons',
    'délatteront',
    'délavassent',
    'délavassiez',
    'délaverions',
    'délayassent',
    'délayassiez',
    'délayerions',
    'délectables',
    'délectaient',
    'délectasses',
    'délectation',
    'délecterais',
    'délecterait',
    'délectèrent',
    'délecteriez',
    'délecterons',
    'délecteront',
    'délégataire',
    'délégations',
    'déléguaient',
    'déléguasses',
    'déléguerais',
    'déléguerait',
    'déléguèrent',
    'délégueriez',
    'déléguerons',
    'délégueront',
    'délestaient',
    'délestasses',
    'délesterais',
    'délesterait',
    'délestèrent',
    'délesteriez',
    'délesterons',
    'délesteront',
    'déliassions',
    'délibérâmes',
    'délibérasse',
    'délibérâtes',
    'délibératif',
    'délibérerai',
    'délibéreras',
    'délibérerez',
    'délibérions',
    'délicatesse',
    'délicieuses',
    'délictueuse',
    'délieraient',
    'délignifiai',
    'délignifias',
    'délignifiât',
    'délignifiée',
    'délignifier',
    'délignifies',
    'délignifiés',
    'délignifiez',
    'délimitâmes',
    'délimitasse',
    'délimitâtes',
    'délimiterai',
    'délimiteras',
    'délimiterez',
    'délimiteurs',
    'délimitions',
    'délinéaient',
    'délinéament',
    'délinéasses',
    'délinéerais',
    'délinéerait',
    'délinéèrent',
    'délinéeriez',
    'délinéerons',
    'délinéeront',
    'délinquance',
    'délinquante',
    'délinquants',
    'délirassent',
    'délirassiez',
    'délirerions',
    'délissaient',
    'délissasses',
    'délisserais',
    'délisserait',
    'délissèrent',
    'délisseriez',
    'délisserons',
    'délisseront',
    'délitassent',
    'délitassiez',
    'déliterions',
    'délitescent',
    'délivraient',
    'délivrances',
    'délivrasses',
    'délivrerais',
    'délivrerait',
    'délivrèrent',
    'délivreriez',
    'délivrerons',
    'délivreront',
    'délogeaient',
    'délogeasses',
    'délogements',
    'délogerions',
    'déloquaient',
    'déloquasses',
    'déloquerais',
    'déloquerait',
    'déloquèrent',
    'déloqueriez',
    'déloquerons',
    'déloqueront',
    'délovassent',
    'délovassiez',
    'déloverions',
    'delphiniums',
    'deltacismes',
    'deltaplanes',
    'deltoïdiens',
    'délurassent',
    'délurassiez',
    'délurerions',
    'délustrages',
    'délustrâmes',
    'délustrasse',
    'délustrâtes',
    'délustrerai',
    'délustreras',
    'délustrerez',
    'délustrions',
    'délutassent',
    'délutassiez',
    'déluterions',
    'démaçonnais',
    'démaçonnait',
    'démaçonnant',
    'démaçonnées',
    'démaçonnent',
    'démaçonnera',
    'démaçonniez',
    'démaçonnons',
    'démagnétisa',
    'démagnétise',
    'démagnétisé',
    'démagogique',
    'démaigrîmes',
    'démaigrirai',
    'démaigriras',
    'démaigrirez',
    'démaigrisse',
    'démaigrîtes',
    'démaillages',
    'démaillâmes',
    'démaillasse',
    'démaillâtes',
    'démaillerai',
    'démailleras',
    'démaillerez',
    'démaillions',
    'démaillotai',
    'démaillotas',
    'démaillotât',
    'démaillotée',
    'démailloter',
    'démaillotes',
    'démaillotés',
    'démaillotez',
    'démanchâmes',
    'démanchasse',
    'démanchâtes',
    'démancherai',
    'démancheras',
    'démancherez',
    'démanchions',
    'demandaient',
    'demandasses',
    'demanderais',
    'demanderait',
    'demandèrent',
    'demanderiez',
    'demanderons',
    'demanderont',
    'demandeuses',
    'démangeâmes',
    'démangeasse',
    'démangeâtes',
    'démangerais',
    'démangerait',
    'démangèrent',
    'démangeriez',
    'démangerons',
    'démangeront',
    'démantelais',
    'démantelait',
    'démantelant',
    'démantelées',
    'démantèlent',
    'démantèlera',
    'démanteliez',
    'démantelons',
    'démantibula',
    'démantibule',
    'démantibulé',
    'démaquillai',
    'démaquillas',
    'démaquillât',
    'démaquillée',
    'démaquiller',
    'démaquilles',
    'démaquillés',
    'démaquillez',
    'démarcation',
    'démarchages',
    'démarchâmes',
    'démarchasse',
    'démarchâtes',
    'démarcherai',
    'démarcheras',
    'démarcherez',
    'démarcheurs',
    'démarcheuse',
    'démarchions',
    'démariaient',
    'démariasses',
    'démarierais',
    'démarierait',
    'démarièrent',
    'démarieriez',
    'démarierons',
    'démarieront',
    'démarquages',
    'démarquâmes',
    'démarquasse',
    'démarquâtes',
    'démarquerai',
    'démarqueras',
    'démarquerez',
    'démarqueurs',
    'démarquions',
    'démarraient',
    'démarrasses',
    'démarrerais',
    'démarrerait',
    'démarrèrent',
    'démarreriez',
    'démarrerons',
    'démarreront',
    'démasclâmes',
    'démasclasse',
    'démasclâtes',
    'démasclerai',
    'démascleras',
    'démasclerez',
    'démasclions',
    'démasquâmes',
    'démasquasse',
    'démasquâtes',
    'démasquerai',
    'démasqueras',
    'démasquerez',
    'démasquions',
    'démastiquai',
    'démastiquas',
    'démastiquât',
    'démastiquée',
    'démastiquer',
    'démastiques',
    'démastiqués',
    'démastiquez',
    'démâtassent',
    'démâtassiez',
    'démâterions',
    'démazoutais',
    'démazoutait',
    'démazoutant',
    'démazoutent',
    'démazoutera',
    'démazoutiez',
    'démazoutons',
    'démêlassent',
    'démêlassiez',
    'démêlements',
    'démêlerions',
    'démembrâmes',
    'démembrasse',
    'démembrâtes',
    'démembrerai',
    'démembreras',
    'démembrerez',
    'démembrions',
    'déménageais',
    'déménageait',
    'déménageant',
    'déménageons',
    'déménagerai',
    'déménageras',
    'déménagerez',
    'déménageurs',
    'déménagions',
    'démenassent',
    'démenassiez',
    'démènerions',
    'démentaient',
    'démentielle',
    'démentirais',
    'démentirait',
    'démentirent',
    'démentiriez',
    'démentirons',
    'démentiront',
    'démentisses',
    'démerdaient',
    'démerdasses',
    'démerderais',
    'démerderait',
    'démerdèrent',
    'démerderiez',
    'démerderons',
    'démerderont',
    'déméritâmes',
    'déméritasse',
    'déméritâtes',
    'démériterai',
    'démériteras',
    'démériterez',
    'déméritions',
    'déméthanisa',
    'déméthanise',
    'déméthanisé',
    'démettaient',
    'démettrions',
    'démeublâmes',
    'démeublasse',
    'démeublâtes',
    'démeublerai',
    'démeubleras',
    'démeublerez',
    'démeublions',
    'demeuraient',
    'demeurasses',
    'demeurerais',
    'demeurerait',
    'demeurèrent',
    'demeureriez',
    'demeurerons',
    'demeureront',
    'demi-bottes',
    'demi-cercle',
    'demi-deuils',
    'demi-droite',
    'démiellâmes',
    'démiellasse',
    'démiellâtes',
    'démiellerai',
    'démielleras',
    'démiellerez',
    'démiellions',
    'demi-finale',
    'demi-frères',
    'demi-heures',
    'demi-litres',
    'demi-mesure',
    'demi-mondes',
    'demi-mortes',
    'déminassent',
    'déminassiez',
    'déminerions',
    'demi-pauses',
    'demi-places',
    'demi-quarts',
    'demi-queues',
    'demi-rondes',
    'demi-saison',
    'demi-soeurs',
    'demi-soldes',
    'demi-soupir',
    'démissionna',
    'démissionne',
    'démissionné',
    'demi-tarifs',
    'demi-teinte',
    'demi-volées',
    'démobilisai',
    'démobilisas',
    'démobilisât',
    'démobilisée',
    'démobiliser',
    'démobilises',
    'démobilisés',
    'démobilisez',
    'démocraties',
    'démocratisa',
    'démocratise',
    'démocratisé',
    'démodassent',
    'démodassiez',
    'démoderions',
    'démodulâmes',
    'démodulasse',
    'démodulâtes',
    'démodulerai',
    'démoduleras',
    'démodulerez',
    'démodulions',
    'démographes',
    'démographie',
    'demoiselles',
    'démolirions',
    'démolissage',
    'démolissais',
    'démolissait',
    'démolissant',
    'démolissent',
    'démolisseur',
    'démolissiez',
    'démolissons',
    'démolitions',
    'démonétisai',
    'démonétisas',
    'démonétisât',
    'démonétisée',
    'démonétiser',
    'démonétises',
    'démonétisés',
    'démonétisez',
    'démoniaques',
    'démonologie',
    'démonomanie',
    'démontables',
    'démontaient',
    'démontasses',
    'démonterais',
    'démonterait',
    'démontèrent',
    'démonteriez',
    'démonterons',
    'démonteront',
    'démontrable',
    'démontrâmes',
    'démontrasse',
    'démontrâtes',
    'démontrerai',
    'démontreras',
    'démontrerez',
    'démontrions',
    'démoralisai',
    'démoralisas',
    'démoralisât',
    'démoralisée',
    'démoraliser',
    'démoralises',
    'démoralisés',
    'démoralisez',
    'démordaient',
    'démordirent',
    'démordisses',
    'démordrions',
    'démotivâmes',
    'démotivante',
    'démotivants',
    'démotivasse',
    'démotivâtes',
    'démotiverai',
    'démotiveras',
    'démotiverez',
    'démotivions',
    'démouchetai',
    'démouchetas',
    'démouchetât',
    'démouchetée',
    'démoucheter',
    'démouchetés',
    'démouchetez',
    'démouchette',
    'démoulaient',
    'démoulasses',
    'démoulerais',
    'démoulerait',
    'démoulèrent',
    'démouleriez',
    'démoulerons',
    'démouleront',
    'démoustiqua',
    'démoustique',
    'démoustiqué',
    'démultiplia',
    'démultiplie',
    'démultiplié',
    'démunirions',
    'démunissais',
    'démunissait',
    'démunissant',
    'démunissent',
    'démunissiez',
    'démunissons',
    'démurassent',
    'démurassiez',
    'démurerions',
    'démurgeâmes',
    'démurgeasse',
    'démurgeâtes',
    'démurgerais',
    'démurgerait',
    'démurgèrent',
    'démurgeriez',
    'démurgerons',
    'démurgeront',
    'démuselâmes',
    'démuselasse',
    'démuselâtes',
    'démuselions',
    'démusellent',
    'démusellera',
    'démystifiai',
    'démystifias',
    'démystifiât',
    'démystifiée',
    'démystifier',
    'démystifies',
    'démystifiés',
    'démystifiez',
    'démythifiai',
    'démythifias',
    'démythifiât',
    'démythifiée',
    'démythifier',
    'démythifies',
    'démythifiés',
    'démythifiez',
    'dénantirais',
    'dénantirait',
    'dénantirent',
    'dénantiriez',
    'dénantirons',
    'dénantiront',
    'dénantisses',
    'dénantissez',
    'dénasalisai',
    'dénasalisas',
    'dénasalisât',
    'dénasalisée',
    'dénasaliser',
    'dénasalises',
    'dénasalisés',
    'dénasalisez',
    'dénatalités',
    'dénattaient',
    'dénattasses',
    'dénatterais',
    'dénatterait',
    'dénattèrent',
    'dénatteriez',
    'dénatterons',
    'dénatteront',
    'dénaturâmes',
    'dénaturasse',
    'dénaturâtes',
    'dénaturerai',
    'dénatureras',
    'dénaturerez',
    'dénaturions',
    'dénébulisai',
    'dénébulisas',
    'dénébulisât',
    'dénébulisée',
    'dénébuliser',
    'dénébulises',
    'dénébulisés',
    'dénégations',
    'dénégatoire',
    'déneigeâmes',
    'déneigeasse',
    'déneigeâtes',
    'déneigement',
    'déneigerais',
    'déneigerait',
    'déneigèrent',
    'déneigeriez',
    'déneigerons',
    'déneigeront',
    'dénervaient',
    'dénervasses',
    'dénerverais',
    'dénerverait',
    'dénervèrent',
    'dénerveriez',
    'dénerverons',
    'dénerveront',
    'déniaisâmes',
    'déniaisasse',
    'déniaisâtes',
    'déniaiserai',
    'déniaiseras',
    'déniaiserez',
    'déniaisions',
    'déniassions',
    'dénichaient',
    'dénichasses',
    'dénicherais',
    'dénicherait',
    'dénichèrent',
    'dénicheriez',
    'dénicherons',
    'dénicheront',
    'dénicheuses',
    'dénickelais',
    'dénickelait',
    'dénickelant',
    'dénickelées',
    'dénickelent',
    'dénickelera',
    'dénickeliez',
    'dénickelons',
    'dénieraient',
    'dénigraient',
    'dénigrasses',
    'dénigrement',
    'dénigrerais',
    'dénigrerait',
    'dénigrèrent',
    'dénigreriez',
    'dénigrerons',
    'dénigreront',
    'dénigreuses',
    'dénitraient',
    'dénitrasses',
    'dénitrerais',
    'dénitrerait',
    'dénitrèrent',
    'dénitreriez',
    'dénitrerons',
    'dénitreront',
    'dénitrifiai',
    'dénitrifias',
    'dénitrifiât',
    'dénitrifiée',
    'dénitrifier',
    'dénitrifies',
    'dénitrifiés',
    'dénitrifiez',
    'dénivelâmes',
    'dénivelasse',
    'dénivelâtes',
    'dénivelions',
    'dénivellent',
    'dénivellera',
    'dénoierions',
    'dénombrable',
    'dénombrâmes',
    'dénombrasse',
    'dénombrâtes',
    'dénombrerai',
    'dénombreras',
    'dénombrerez',
    'dénombrions',
    'dénominatif',
    'dénommaient',
    'dénommasses',
    'dénommerais',
    'dénommerait',
    'dénommèrent',
    'dénommeriez',
    'dénommerons',
    'dénommeront',
    'dénonçaient',
    'dénonçasses',
    'dénoncerais',
    'dénoncerait',
    'dénoncèrent',
    'dénonceriez',
    'dénoncerons',
    'dénonceront',
    'dénotassent',
    'dénotassiez',
    'dénotations',
    'dénoterions',
    'dénouassent',
    'dénouassiez',
    'dénouements',
    'dénouerions',
    'dénoyassent',
    'dénoyassiez',
    'dénoyautais',
    'dénoyautait',
    'dénoyautant',
    'dénoyautées',
    'dénoyautent',
    'dénoyautera',
    'dénoyautiez',
    'dénoyautons',
    'densimétrie',
    'dentelaient',
    'dentelaires',
    'dentelasses',
    'dentelèrent',
    'dentellerai',
    'dentelleras',
    'dentellerez',
    'dentellerie',
    'dentellière',
    'dentelliers',
    'dentifrices',
    'dentisterie',
    'dénuassions',
    'dénudassent',
    'dénudassiez',
    'dénudations',
    'dénuderions',
    'dénueraient',
    'dénutrition',
    'déodorantes',
    'déontologie',
    'dépaillages',
    'dépaillâmes',
    'dépaillasse',
    'dépaillâtes',
    'dépaillerai',
    'dépailleras',
    'dépaillerez',
    'dépaillions',
    'dépaisselai',
    'dépaisselas',
    'dépaisselât',
    'dépaisselée',
    'dépaisseler',
    'dépaisselés',
    'dépaisselez',
    'dépaisselle',
    'dépalissais',
    'dépalissait',
    'dépalissant',
    'dépalissées',
    'dépalissent',
    'dépalissera',
    'dépalissiez',
    'dépalissons',
    'dépannaient',
    'dépannasses',
    'dépannerais',
    'dépannerait',
    'dépannèrent',
    'dépanneriez',
    'dépannerons',
    'dépanneront',
    'dépanneuses',
    'dépaquetage',
    'dépaquetais',
    'dépaquetait',
    'dépaquetant',
    'dépaquetées',
    'dépaquetiez',
    'dépaquetons',
    'dépaquettes',
    'déparaffina',
    'déparaffine',
    'déparaffiné',
    'déparasitai',
    'déparasitas',
    'déparasitât',
    'déparasitée',
    'déparasiter',
    'déparasités',
    'déparasitez',
    'déparassent',
    'déparassiez',
    'dépareillai',
    'dépareillas',
    'dépareillât',
    'dépareillée',
    'dépareiller',
    'dépareilles',
    'dépareillés',
    'dépareillez',
    'déparerions',
    'dépariaient',
    'dépariasses',
    'déparierais',
    'déparierait',
    'déparièrent',
    'déparieriez',
    'déparierons',
    'déparieront',
    'déparlaient',
    'déparlasses',
    'déparlerais',
    'déparlerait',
    'déparlèrent',
    'déparleriez',
    'déparlerons',
    'déparleront',
    'départageai',
    'départageas',
    'départageât',
    'départagées',
    'départagent',
    'départagera',
    'départagiez',
    'départaient',
    'département',
    'départirais',
    'départirait',
    'départirent',
    'départiriez',
    'départirons',
    'départiront',
    'départisses',
    'dépassaient',
    'dépassantes',
    'dépassasses',
    'dépassement',
    'dépasserais',
    'dépasserait',
    'dépassèrent',
    'dépasseriez',
    'dépasserons',
    'dépasseront',
    'dépassionna',
    'dépassionne',
    'dépassionné',
    'dépatouilla',
    'dépatouille',
    'dépatouillé',
    'dépavassent',
    'dépavassiez',
    'dépaverions',
    'dépaysaient',
    'dépaysasses',
    'dépaysement',
    'dépayserais',
    'dépayserait',
    'dépaysèrent',
    'dépayseriez',
    'dépayserons',
    'dépayseront',
    'dépeçassent',
    'dépeçassiez',
    'dépècements',
    'dépècerions',
    'dépêchaient',
    'dépêchasses',
    'dépêcherais',
    'dépêcherait',
    'dépêchèrent',
    'dépêcheriez',
    'dépêcherons',
    'dépêcheront',
    'dépeignâmes',
    'dépeignasse',
    'dépeignâtes',
    'dépeignerai',
    'dépeigneras',
    'dépeignerez',
    'dépeignîmes',
    'dépeignions',
    'dépeignisse',
    'dépeignîtes',
    'dépeindrais',
    'dépeindrait',
    'dépeindriez',
    'dépeindrons',
    'dépeindront',
    'dépelotonna',
    'dépelotonne',
    'dépelotonné',
    'dépenaillée',
    'dépenaillés',
    'dépendaient',
    'dépendances',
    'dépendantes',
    'dépendeuses',
    'dépendirent',
    'dépendisses',
    'dépendrions',
    'dépensaient',
    'dépensasses',
    'dépenserais',
    'dépenserait',
    'dépensèrent',
    'dépenseriez',
    'dépenserons',
    'dépenseront',
    'dépensières',
    'déperdition',
    'dépéririons',
    'dépérissais',
    'dépérissait',
    'dépérissant',
    'dépérissent',
    'dépérissiez',
    'dépérissons',
    'dépêtraient',
    'dépêtrasses',
    'dépêtrerais',
    'dépêtrerait',
    'dépêtrèrent',
    'dépêtreriez',
    'dépêtrerons',
    'dépêtreront',
    'dépeuplâmes',
    'dépeuplasse',
    'dépeuplâtes',
    'dépeuplerai',
    'dépeupleras',
    'dépeuplerez',
    'dépeuplions',
    'déphasaient',
    'déphasasses',
    'déphaserais',
    'déphaserait',
    'déphasèrent',
    'déphaseriez',
    'déphaserons',
    'déphaseront',
    'dépiautâmes',
    'dépiautasse',
    'dépiautâtes',
    'dépiauterai',
    'dépiauteras',
    'dépiauterez',
    'dépiautions',
    'dépilassent',
    'dépilassiez',
    'dépilations',
    'dépilatoire',
    'dépilerions',
    'dépinglâmes',
    'dépinglasse',
    'dépinglâtes',
    'dépinglerai',
    'dépingleras',
    'dépinglerez',
    'dépinglions',
    'dépiquaient',
    'dépiquasses',
    'dépiquerais',
    'dépiquerait',
    'dépiquèrent',
    'dépiqueriez',
    'dépiquerons',
    'dépiqueront',
    'dépistaient',
    'dépistasses',
    'dépisterais',
    'dépisterait',
    'dépistèrent',
    'dépisteriez',
    'dépisterons',
    'dépisteront',
    'dépitassent',
    'dépitassiez',
    'dépiterions',
    'déplaçaient',
    'déplaçasses',
    'déplacement',
    'déplacerais',
    'déplacerait',
    'déplacèrent',
    'déplaceriez',
    'déplacerons',
    'déplaceront',
    'déplafonnai',
    'déplafonnas',
    'déplafonnât',
    'déplafonnée',
    'déplafonner',
    'déplafonnes',
    'déplafonnés',
    'déplafonnez',
    'déplairions',
    'déplaisante',
    'déplaisants',
    'déplaisions',
    'déplanquais',
    'déplanquait',
    'déplanquant',
    'déplanquées',
    'déplanquent',
    'déplanquera',
    'déplanquiez',
    'déplanquons',
    'déplantages',
    'déplantâmes',
    'déplantasse',
    'déplantâtes',
    'déplanterai',
    'déplanteras',
    'déplanterez',
    'déplantions',
    'déplantoirs',
    'déplâtrages',
    'déplâtrâmes',
    'déplâtrasse',
    'déplâtrâtes',
    'déplâtrerai',
    'déplâtreras',
    'déplâtrerez',
    'déplâtrions',
    'dépliassent',
    'dépliassiez',
    'déplierions',
    'déplissages',
    'déplissâmes',
    'déplissasse',
    'déplissâtes',
    'déplisserai',
    'déplisseras',
    'déplisserez',
    'déplissions',
    'déploiement',
    'déploierais',
    'déploierait',
    'déploieriez',
    'déploierons',
    'déploieront',
    'déplombages',
    'déplombâmes',
    'déplombasse',
    'déplombâtes',
    'déplomberai',
    'déplomberas',
    'déplomberez',
    'déplombions',
    'déplorables',
    'déploraient',
    'déplorasses',
    'déplorerais',
    'déplorerait',
    'déplorèrent',
    'déploreriez',
    'déplorerons',
    'déploreront',
    'déployaient',
    'déployasses',
    'déployèrent',
    'déplumaient',
    'déplumasses',
    'déplumerais',
    'déplumerait',
    'déplumèrent',
    'déplumeriez',
    'déplumerons',
    'déplumeront',
    'déplussions',
    'dépoétisais',
    'dépoétisait',
    'dépoétisant',
    'dépoétisées',
    'dépoétisent',
    'dépoétisera',
    'dépoétisiez',
    'dépoétisons',
    'dépointâmes',
    'dépointasse',
    'dépointâtes',
    'dépointerai',
    'dépointeras',
    'dépointerez',
    'dépointions',
    'dépolarisai',
    'dépolarisas',
    'dépolarisât',
    'dépolarisée',
    'dépolariser',
    'dépolarises',
    'dépolarisés',
    'dépolarisez',
    'dépolirions',
    'dépolissage',
    'dépolissais',
    'dépolissait',
    'dépolissant',
    'dépolissent',
    'dépolissiez',
    'dépolissons',
    'dépolitisai',
    'dépolitisas',
    'dépolitisât',
    'dépolitisée',
    'dépolitiser',
    'dépolitises',
    'dépolitisés',
    'dépolitisez',
    'dépolluâmes',
    'dépolluasse',
    'dépolluâtes',
    'dépolluerai',
    'dépollueras',
    'dépolluerez',
    'dépolluions',
    'dépontillai',
    'dépontillas',
    'dépontillât',
    'dépontiller',
    'dépontilles',
    'dépontillez',
    'déportaient',
    'déportasses',
    'déportation',
    'déportement',
    'déporterais',
    'déporterait',
    'déportèrent',
    'déporteriez',
    'déporterons',
    'déporteront',
    'déposassent',
    'déposassiez',
    'déposerions',
    'dépositaire',
    'dépositions',
    'dépossédais',
    'dépossédait',
    'dépossédant',
    'dépossédées',
    'dépossèdent',
    'dépossédera',
    'dépossédiez',
    'dépossédons',
    'dépotassent',
    'dépotassiez',
    'dépotements',
    'dépoterions',
    'dépoudrâmes',
    'dépoudrasse',
    'dépoudrâtes',
    'dépoudrerai',
    'dépoudreras',
    'dépoudrerez',
    'dépoudrions',
    'dépouillais',
    'dépouillait',
    'dépouillant',
    'dépouillées',
    'dépouillent',
    'dépouillera',
    'dépouilliez',
    'dépouillons',
    'dépourvûmes',
    'dépourvûtes',
    'dépoussiéra',
    'dépoussiéré',
    'dépoussière',
    'dépravaient',
    'dépravasses',
    'dépravation',
    'dépraverais',
    'dépraverait',
    'dépravèrent',
    'dépraveriez',
    'dépraverons',
    'dépraveront',
    'déprécation',
    'dépréciâmes',
    'dépréciasse',
    'dépréciâtes',
    'dépréciatif',
    'déprécierai',
    'déprécieras',
    'déprécierez',
    'dépréciions',
    'déprédateur',
    'déprédation',
    'déprenaient',
    'déprendrais',
    'déprendrait',
    'déprendriez',
    'déprendrons',
    'déprendront',
    'dépressions',
    'dépressives',
    'déprimaient',
    'déprimantes',
    'déprimasses',
    'déprimerais',
    'déprimerait',
    'déprimèrent',
    'déprimeriez',
    'déprimerons',
    'déprimeront',
    'déprisaient',
    'déprisasses',
    'dépriserais',
    'dépriserait',
    'déprisèrent',
    'dépriseriez',
    'dépriserons',
    'dépriseront',
    'déprissions',
    'déprogramma',
    'déprogramme',
    'déprogrammé',
    'dépropanisa',
    'dépropanise',
    'dépropanisé',
    'dépucelâmes',
    'dépucelasse',
    'dépucelâtes',
    'dépucelions',
    'dépucellent',
    'dépucellera',
    'dépulpaient',
    'dépulpasses',
    'dépulperais',
    'dépulperait',
    'dépulpèrent',
    'dépulperiez',
    'dépulperons',
    'dépulperont',
    'dépurassent',
    'dépurassiez',
    'dépurations',
    'dépuratives',
    'dépurerions',
    'députassent',
    'députassiez',
    'députations',
    'députerions',
    'déquillâmes',
    'déquillasse',
    'déquillâtes',
    'déquillerai',
    'déquilleras',
    'déquillerez',
    'déquillions',
    'déracinâmes',
    'déracinasse',
    'déracinâtes',
    'déracinerai',
    'déracineras',
    'déracinerez',
    'déracinions',
    'déradassent',
    'déradassiez',
    'déraderions',
    'dérageaient',
    'dérageasses',
    'déragerions',
    'déraidirais',
    'déraidirait',
    'déraidirent',
    'déraidiriez',
    'déraidirons',
    'déraidiront',
    'déraidisses',
    'déraidissez',
    'déraierions',
    'déraillâmes',
    'déraillasse',
    'déraillâtes',
    'déraillerai',
    'dérailleras',
    'déraillerez',
    'dérailleurs',
    'déraillions',
    'déraisonnai',
    'déraisonnas',
    'déraisonnât',
    'déraisonner',
    'déraisonnes',
    'déraisonnez',
    'dérangeâmes',
    'dérangeante',
    'dérangeants',
    'dérangeasse',
    'dérangeâtes',
    'dérangement',
    'dérangerais',
    'dérangerait',
    'dérangèrent',
    'dérangeriez',
    'dérangerons',
    'dérangeront',
    'dérapassent',
    'dérapassiez',
    'déraperions',
    'dérasassent',
    'dérasassiez',
    'déraserions',
    'dératassent',
    'dératassiez',
    'dératerions',
    'dératisâmes',
    'dératisasse',
    'dératisâtes',
    'dératiserai',
    'dératiseras',
    'dératiserez',
    'dératisions',
    'dérayassent',
    'dérayassiez',
    'dérayerions',
    'déréalisais',
    'déréalisait',
    'déréalisant',
    'déréalisées',
    'déréalisent',
    'déréalisera',
    'déréalisiez',
    'déréalisons',
    'déréglaient',
    'déréglasses',
    'dérèglement',
    'déréglerais',
    'déréglerait',
    'déréglèrent',
    'dérégleriez',
    'déréglerons',
    'dérégleront',
    'dérégulâmes',
    'dérégulasse',
    'dérégulâtes',
    'dérégulerai',
    'déréguleras',
    'dérégulerez',
    'dérégulions',
    'déréliction',
    'déridassent',
    'déridassiez',
    'dériderions',
    'dérivassent',
    'dérivassiez',
    'dérivations',
    'dérivatives',
    'dériverions',
    'dérobassent',
    'dérobassiez',
    'déroberions',
    'dérochaient',
    'dérochasses',
    'dérochement',
    'dérocherais',
    'dérocherait',
    'dérochèrent',
    'dérocheriez',
    'dérocherons',
    'dérocheront',
    'dérodassent',
    'dérodassiez',
    'déroderions',
    'dérogations',
    'dérogatoire',
    'dérogeaient',
    'dérogeasses',
    'dérogerions',
    'dérouillais',
    'dérouillait',
    'dérouillant',
    'dérouillées',
    'dérouillent',
    'dérouillera',
    'dérouilliez',
    'dérouillons',
    'déroulaient',
    'déroulasses',
    'déroulement',
    'déroulerais',
    'déroulerait',
    'déroulèrent',
    'dérouleriez',
    'déroulerons',
    'dérouleront',
    'dérouleuses',
    'déroutaient',
    'déroutasses',
    'déroutement',
    'dérouterais',
    'dérouterait',
    'déroutèrent',
    'dérouteriez',
    'dérouterons',
    'dérouteront',
    'désabonnais',
    'désabonnait',
    'désabonnant',
    'désabonnées',
    'désabonnent',
    'désabonnera',
    'désabonniez',
    'désabonnons',
    'désabusâmes',
    'désabusasse',
    'désabusâtes',
    'désabuserai',
    'désabuseras',
    'désabuserez',
    'désabusions',
    'désaccordai',
    'désaccordas',
    'désaccordât',
    'désaccordée',
    'désaccorder',
    'désaccordes',
    'désaccordés',
    'désaccordez',
    'désaccoupla',
    'désaccouple',
    'désaccouplé',
    'désacralisa',
    'désacralise',
    'désacralisé',
    'désactivais',
    'désactivait',
    'désactivant',
    'désactivées',
    'désactivent',
    'désactivera',
    'désactiviez',
    'désactivons',
    'désadaptais',
    'désadaptait',
    'désadaptant',
    'désadaptent',
    'désadaptera',
    'désadaptiez',
    'désadaptons',
    'désaffectai',
    'désaffectas',
    'désaffectât',
    'désaffectée',
    'désaffecter',
    'désaffectes',
    'désaffectés',
    'désaffectez',
    'désaffiliai',
    'désaffilias',
    'désaffiliât',
    'désaffiliée',
    'désaffilier',
    'désaffilies',
    'désaffiliés',
    'désaffiliez',
    'désagençais',
    'désagençait',
    'désagençant',
    'désagencées',
    'désagencent',
    'désagencera',
    'désagenciez',
    'désagençons',
    'désagrafais',
    'désagrafait',
    'désagrafant',
    'désagrafées',
    'désagrafent',
    'désagrafera',
    'désagrafiez',
    'désagrafons',
    'désagréable',
    'désagrégeai',
    'désagrégeas',
    'désagrégeât',
    'désagrégées',
    'désagrègent',
    'désagrégera',
    'désagrégiez',
    'désagrément',
    'désaimantai',
    'désaimantas',
    'désaimantât',
    'désaimantée',
    'désaimanter',
    'désaimantes',
    'désaimantés',
    'désaimantez',
    'désajustais',
    'désajustait',
    'désajustant',
    'désajustées',
    'désajustent',
    'désajustera',
    'désajustiez',
    'désajustons',
    'désaliénais',
    'désaliénait',
    'désaliénant',
    'désaliénées',
    'désaliènent',
    'désaliénera',
    'désaliéniez',
    'désaliénons',
    'désalignais',
    'désalignait',
    'désalignant',
    'désalignées',
    'désalignent',
    'désalignera',
    'désaligniez',
    'désalignons',
    'désaltérais',
    'désaltérait',
    'désaltérant',
    'désaltérées',
    'désaltèrent',
    'désaltérera',
    'désaltériez',
    'désaltérons',
    'désamarrais',
    'désamarrait',
    'désamarrant',
    'désamarrées',
    'désamarrent',
    'désamarrera',
    'désamarriez',
    'désamarrons',
    'désamidonna',
    'désamidonne',
    'désamidonné',
    'désamorçage',
    'désamorçais',
    'désamorçait',
    'désamorçant',
    'désamorcées',
    'désamorcent',
    'désamorcera',
    'désamorciez',
    'désamorçons',
    'désannexais',
    'désannexait',
    'désannexant',
    'désannexées',
    'désannexent',
    'désannexera',
    'désannexiez',
    'désannexons',
    'désappariai',
    'désapparias',
    'désappariât',
    'désappariée',
    'désapparier',
    'désapparies',
    'désappariés',
    'désappariez',
    'désappointa',
    'désappointe',
    'désappointé',
    'désapprends',
    'désapprenez',
    'désapprenne',
    'désapprîmes',
    'désapprises',
    'désapprisse',
    'désapprîtes',
    'désapprouva',
    'désapprouve',
    'désapprouvé',
    'désarçonnai',
    'désarçonnas',
    'désarçonnât',
    'désarçonnée',
    'désarçonner',
    'désarçonnes',
    'désarçonnés',
    'désarçonnez',
    'désargentai',
    'désargentas',
    'désargentât',
    'désargentée',
    'désargenter',
    'désargentes',
    'désargentés',
    'désargentez',
    'désarmaient',
    'désarmantes',
    'désarmasses',
    'désarmement',
    'désarmerais',
    'désarmerait',
    'désarmèrent',
    'désarmeriez',
    'désarmerons',
    'désarmeront',
    'désarrimage',
    'désarrimais',
    'désarrimait',
    'désarrimant',
    'désarrimées',
    'désarriment',
    'désarrimera',
    'désarrimiez',
    'désarrimons',
    'désarticula',
    'désarticule',
    'désarticulé',
    'désassembla',
    'désassemble',
    'désassemblé',
    'désassimila',
    'désassimile',
    'désassimilé',
    'désassortie',
    'désassortir',
    'désassortis',
    'désassortit',
    'désassortît',
    'désastreuse',
    'désatomisai',
    'désatomisas',
    'désatomisât',
    'désatomisée',
    'désatomiser',
    'désatomises',
    'désatomisés',
    'désatomisez',
    'désavantage',
    'désavantagé',
    'désaveuglai',
    'désaveuglas',
    'désaveuglât',
    'désaveuglée',
    'désaveugler',
    'désaveugles',
    'désaveuglés',
    'désaveuglez',
    'désavouâmes',
    'désavouasse',
    'désavouâtes',
    'désavouerai',
    'désavoueras',
    'désavouerez',
    'désavouions',
    'désaxassent',
    'désaxassiez',
    'désaxerions',
    'descellâmes',
    'descellasse',
    'descellâtes',
    'descellerai',
    'descelleras',
    'descellerez',
    'descellions',
    'descendance',
    'descendante',
    'descendants',
    'descenderie',
    'descendeurs',
    'descendeuse',
    'descendîmes',
    'descendions',
    'descendisse',
    'descendîtes',
    'descendrais',
    'descendrait',
    'descendriez',
    'descendrons',
    'descendront',
    'descenseurs',
    'descriptifs',
    'description',
    'descriptive',
    'déséchouais',
    'déséchouait',
    'déséchouant',
    'déséchouées',
    'déséchouent',
    'déséchouera',
    'déséchouiez',
    'déséchouons',
    'désembourba',
    'désembourbe',
    'désembourbé',
    'désembraies',
    'désembrayai',
    'désembrayas',
    'désembrayât',
    'désembrayée',
    'désembrayer',
    'désembrayes',
    'désembrayés',
    'désembrayez',
    'désembuâmes',
    'désembuasse',
    'désembuâtes',
    'désembuerai',
    'désembueras',
    'désembuerez',
    'désembuions',
    'désemmancha',
    'désemmanche',
    'désemmanché',
    'désemparais',
    'désemparait',
    'désemparant',
    'désemparées',
    'désemparent',
    'désemparera',
    'désempariez',
    'désemparons',
    'désempesais',
    'désempesait',
    'désempesant',
    'désempesées',
    'désempèsent',
    'désempèsera',
    'désempesiez',
    'désempesons',
    'désemplirai',
    'désempliras',
    'désemplirez',
    'désencadrai',
    'désencadras',
    'désencadrât',
    'désencadrée',
    'désencadrer',
    'désencadres',
    'désencadrés',
    'désencadrez',
    'désencartai',
    'désencartas',
    'désencartât',
    'désencartée',
    'désencarter',
    'désencartes',
    'désencartés',
    'désencartez',
    'désenchaîna',
    'désenchaîne',
    'désenchaîné',
    'désenchanta',
    'désenchante',
    'désenchanté',
    'désenclavai',
    'désenclavas',
    'désenclavât',
    'désenclavée',
    'désenclaver',
    'désenclaves',
    'désenclavés',
    'désenclavez',
    'désencollai',
    'désencollas',
    'désencollât',
    'désencollée',
    'désencoller',
    'désencolles',
    'désencollés',
    'désencollez',
    'désencombra',
    'désencombre',
    'désencombré',
    'désencrassa',
    'désencrasse',
    'désencrassé',
    'désendettai',
    'désendettas',
    'désendettât',
    'désendettée',
    'désendetter',
    'désendettes',
    'désendettés',
    'désendettez',
    'désénervais',
    'désénervait',
    'désénervant',
    'désénervées',
    'désénervent',
    'désénervera',
    'désénerviez',
    'désénervons',
    'désenfilais',
    'désenfilait',
    'désenfilant',
    'désenfilées',
    'désenfilent',
    'désenfilera',
    'désenfiliez',
    'désenfilons',
    'désenflâmes',
    'désenflamma',
    'désenflamme',
    'désenflammé',
    'désenflasse',
    'désenflâtes',
    'désenflerai',
    'désenfleras',
    'désenflerez',
    'désenflions',
    'désenfumais',
    'désenfumait',
    'désenfumant',
    'désenfumées',
    'désenfument',
    'désenfumera',
    'désenfumiez',
    'désenfumons',
    'désengageai',
    'désengageas',
    'désengageât',
    'désengagées',
    'désengagent',
    'désengagera',
    'désengagiez',
    'désengorgea',
    'désengorgée',
    'désengorger',
    'désengorges',
    'désengorgés',
    'désengorgez',
    'désengourdi',
    'désenivrais',
    'désenivrait',
    'désenivrant',
    'désenivrées',
    'désenivrent',
    'désenivrera',
    'désenivriez',
    'désenivrons',
    'désenlaçais',
    'désenlaçait',
    'désenlaçant',
    'désenlacées',
    'désenlacent',
    'désenlacera',
    'désenlaciez',
    'désenlaçons',
    'désenlaidie',
    'désenlaidir',
    'désenlaidis',
    'désenlaidit',
    'désenlaidît',
    'désennuient',
    'désennuiera',
    'désennuyais',
    'désennuyait',
    'désennuyant',
    'désennuyées',
    'désennuyiez',
    'désennuyons',
    'désenraient',
    'désenraiera',
    'désenrayais',
    'désenrayait',
    'désenrayant',
    'désenrayées',
    'désenrayent',
    'désenrayera',
    'désenrayiez',
    'désenrayons',
    'désenrhumai',
    'désenrhumas',
    'désenrhumât',
    'désenrhumée',
    'désenrhumer',
    'désenrhumes',
    'désenrhumés',
    'désenrhumez',
    'désenrouais',
    'désenrouait',
    'désenrouant',
    'désenrouées',
    'désenrouent',
    'désenrouera',
    'désenrouiez',
    'désenrouons',
    'désensablai',
    'désensablas',
    'désensablât',
    'désensablée',
    'désensabler',
    'désensables',
    'désensablés',
    'désensablez',
    'désentoilai',
    'désentoilas',
    'désentoilât',
    'désentoilée',
    'désentoiler',
    'désentoiles',
    'désentoilés',
    'désentoilez',
    'désentravai',
    'désentravas',
    'désentravât',
    'désentravée',
    'désentraver',
    'désentraves',
    'désentravés',
    'désentravez',
    'désenvasais',
    'désenvasait',
    'désenvasant',
    'désenvasées',
    'désenvasent',
    'désenvasera',
    'désenvasiez',
    'désenvasons',
    'désenvenima',
    'désenvenime',
    'désenvenimé',
    'désenvergua',
    'désenvergue',
    'désenvergué',
    'désépaissie',
    'désépaissir',
    'désépaissis',
    'désépaissit',
    'désépaissît',
    'déséquipais',
    'déséquipait',
    'déséquipant',
    'déséquipées',
    'déséquipent',
    'déséquipera',
    'déséquipiez',
    'déséquipons',
    'désertaient',
    'désertasses',
    'déserterais',
    'déserterait',
    'désertèrent',
    'déserteriez',
    'déserterons',
    'déserteront',
    'déserteuses',
    'désertiques',
    'désescalade',
    'désespérais',
    'désespérait',
    'désespérant',
    'désespérées',
    'désespèrent',
    'désespérera',
    'désespériez',
    'désespérons',
    'désétablies',
    'désétablira',
    'désétamâmes',
    'désétamasse',
    'désétamâtes',
    'désétamerai',
    'désétameras',
    'désétamerez',
    'désétamions',
    'désétatisai',
    'désétatisas',
    'désétatisât',
    'désétatisée',
    'désétatiser',
    'désétatises',
    'désétatisés',
    'désétatisez',
    'déshabillai',
    'déshabillas',
    'déshabillât',
    'déshabillée',
    'déshabiller',
    'déshabilles',
    'déshabillés',
    'déshabillez',
    'déshabituai',
    'déshabituas',
    'déshabituât',
    'déshabituée',
    'déshabituer',
    'déshabitues',
    'déshabitués',
    'déshabituez',
    'désherbages',
    'désherbâmes',
    'désherbante',
    'désherbants',
    'désherbasse',
    'désherbâtes',
    'désherberai',
    'désherberas',
    'désherberez',
    'désherbions',
    'déshérences',
    'déshéritais',
    'déshéritait',
    'déshéritant',
    'déshéritées',
    'déshéritent',
    'déshéritera',
    'déshéritiez',
    'déshéritons',
    'déshonnêtes',
    'déshonneurs',
    'déshonorais',
    'déshonorait',
    'déshonorant',
    'déshonorées',
    'déshonorent',
    'déshonorera',
    'déshonoriez',
    'déshonorons',
    'déshuilâmes',
    'déshuilasse',
    'déshuilâtes',
    'déshuilerai',
    'déshuileras',
    'déshuilerez',
    'déshuilions',
    'déshumanisa',
    'déshumanise',
    'déshumanisé',
    'déshydratai',
    'déshydratas',
    'déshydratât',
    'déshydratée',
    'déshydrater',
    'déshydrates',
    'déshydratés',
    'déshydratez',
    'désignaient',
    'désignasses',
    'désignatifs',
    'désignation',
    'désignative',
    'désignerais',
    'désignerait',
    'désignèrent',
    'désigneriez',
    'désignerons',
    'désigneront',
    'désiliciage',
    'désillusion',
    'désincarnai',
    'désincarnas',
    'désincarnât',
    'désincarnée',
    'désincarner',
    'désincarnes',
    'désincarnés',
    'désincarnez',
    'désincrusta',
    'désincruste',
    'désincrusté',
    'désinculpai',
    'désinculpas',
    'désinculpât',
    'désinculpée',
    'désinculper',
    'désinculpes',
    'désinculpés',
    'désinculpez',
    'désindexais',
    'désindexait',
    'désindexant',
    'désindexées',
    'désindexent',
    'désindexera',
    'désindexiez',
    'désindexons',
    'désinentiel',
    'désinfectai',
    'désinfectas',
    'désinfectât',
    'désinfectée',
    'désinfecter',
    'désinfectes',
    'désinfectés',
    'désinfectez',
    'désintégrai',
    'désintégras',
    'désintégrât',
    'désintégrée',
    'désintégrer',
    'désintégrés',
    'désintègres',
    'désintégrez',
    'désintérêts',
    'désinvestie',
    'désinvestir',
    'désinvestis',
    'désinvestit',
    'désinvestît',
    'désinvitais',
    'désinvitait',
    'désinvitant',
    'désinvitées',
    'désinvitent',
    'désinvitera',
    'désinvitiez',
    'désinvitons',
    'désinvoltes',
    'désirassent',
    'désirassiez',
    'désirerions',
    'désistaient',
    'désistasses',
    'désistement',
    'désisterais',
    'désisterait',
    'désistèrent',
    'désisteriez',
    'désisterons',
    'désisteront',
    'désobéirais',
    'désobéirait',
    'désobéirent',
    'désobéiriez',
    'désobéirons',
    'désobéiront',
    'désobéisses',
    'désobéissez',
    'désobligeai',
    'désobligeas',
    'désobligeât',
    'désobligées',
    'désobligent',
    'désobligera',
    'désobligiez',
    'désobstruai',
    'désobstruas',
    'désobstruât',
    'désobstruée',
    'désobstruer',
    'désobstrues',
    'désobstrués',
    'désoccupais',
    'désoccupait',
    'désoccupant',
    'désoccupées',
    'désoccupent',
    'désoccupera',
    'désoccupiez',
    'désoccupons',
    'désodorisai',
    'désodorisas',
    'désodorisât',
    'désodorisée',
    'désodoriser',
    'désodorises',
    'désodorisés',
    'désodorisez',
    'désoeuvrées',
    'désolassent',
    'désolassiez',
    'désolations',
    'désolerions',
    'désopilâmes',
    'désopilante',
    'désopilants',
    'désopilasse',
    'désopilâtes',
    'désopilerai',
    'désopileras',
    'désopilerez',
    'désopilions',
    'désorbaient',
    'désorbasses',
    'désorberais',
    'désorberait',
    'désorbèrent',
    'désorberiez',
    'désorberons',
    'désorberont',
    'désorbitais',
    'désorbitait',
    'désorbitant',
    'désorbitées',
    'désorbitent',
    'désorbitera',
    'désorbitiez',
    'désorbitons',
    'désordonnai',
    'désordonnas',
    'désordonnât',
    'désordonnée',
    'désordonner',
    'désordonnes',
    'désordonnés',
    'désordonnez',
    'désorganisa',
    'désorganise',
    'désorganisé',
    'désorientai',
    'désorientas',
    'désorientât',
    'désorientée',
    'désorienter',
    'désorientes',
    'désorientés',
    'désorientez',
    'désorptions',
    'désossaient',
    'désossasses',
    'désossement',
    'désosserais',
    'désosserait',
    'désossèrent',
    'désosseriez',
    'désosserons',
    'désosseront',
    'désoxydâmes',
    'désoxydante',
    'désoxydants',
    'désoxydasse',
    'désoxydâtes',
    'désoxyderai',
    'désoxyderas',
    'désoxyderez',
    'désoxydions',
    'désoxygénai',
    'désoxygénas',
    'désoxygénât',
    'désoxygénée',
    'désoxygéner',
    'désoxygénés',
    'désoxygènes',
    'désoxygénez',
    'despotiques',
    'despotismes',
    'desquamâmes',
    'desquamasse',
    'desquamâtes',
    'desquamerai',
    'desquameras',
    'desquamerez',
    'desquamions',
    'dessablâmes',
    'dessablasse',
    'dessablâtes',
    'dessablerai',
    'dessableras',
    'dessablerez',
    'dessablions',
    'dessaisîmes',
    'dessaisirai',
    'dessaisiras',
    'dessaisirez',
    'dessaisisse',
    'dessaisîtes',
    'dessalaient',
    'dessalasses',
    'dessalement',
    'dessalerais',
    'dessalerait',
    'dessalèrent',
    'dessaleriez',
    'dessalerons',
    'dessaleront',
    'dessanglais',
    'dessanglait',
    'dessanglant',
    'dessanglées',
    'dessanglent',
    'dessanglera',
    'dessangliez',
    'dessanglons',
    'dessaoulais',
    'dessaoulait',
    'dessaoulant',
    'dessaoulées',
    'dessaoulent',
    'dessaoulera',
    'dessaouliez',
    'dessaoulons',
    'desséchâmes',
    'desséchante',
    'desséchants',
    'desséchasse',
    'desséchâtes',
    'dessécherai',
    'dessécheras',
    'dessécherez',
    'desséchions',
    'dessellâmes',
    'dessellasse',
    'dessellâtes',
    'dessellerai',
    'desselleras',
    'dessellerez',
    'dessellions',
    'desserrages',
    'desserrâmes',
    'desserrasse',
    'desserrâtes',
    'desserrerai',
    'desserreras',
    'desserrerez',
    'desserrions',
    'dessertîmes',
    'dessertirai',
    'dessertiras',
    'dessertirez',
    'dessertisse',
    'dessertîtes',
    'desservîmes',
    'desservions',
    'desservirai',
    'desserviras',
    'desservirez',
    'desservisse',
    'desservîtes',
    'dessiccatif',
    'dessillâmes',
    'dessillasse',
    'dessillâtes',
    'dessillerai',
    'dessilleras',
    'dessillerez',
    'dessillions',
    'dessinaient',
    'dessinasses',
    'dessinateur',
    'dessinerais',
    'dessinerait',
    'dessinèrent',
    'dessineriez',
    'dessinerons',
    'dessineront',
    'dessolaient',
    'dessolasses',
    'dessolement',
    'dessolerais',
    'dessolerait',
    'dessolèrent',
    'dessoleriez',
    'dessolerons',
    'dessoleront',
    'dessoudâmes',
    'dessoudasse',
    'dessoudâtes',
    'dessouderai',
    'dessouderas',
    'dessouderez',
    'dessoudions',
    'dessoulâmes',
    'dessoûlâmes',
    'dessoulasse',
    'dessoûlasse',
    'dessoulâtes',
    'dessoûlâtes',
    'dessoulerai',
    'dessoûlerai',
    'dessouleras',
    'dessoûleras',
    'dessoulerez',
    'dessoûlerez',
    'dessoulions',
    'dessoûlions',
    'dessuintage',
    'dessuitâmes',
    'dessuitasse',
    'dessuitâtes',
    'dessuiterai',
    'dessuiteras',
    'dessuiterez',
    'dessuitions',
    'déstabilisa',
    'déstabilise',
    'déstabilisé',
    'destinaient',
    'destinasses',
    'destination',
    'destinerais',
    'destinerait',
    'destinèrent',
    'destineriez',
    'destinerons',
    'destineront',
    'destituerai',
    'destitueras',
    'destituerez',
    'destituions',
    'destitution',
    'déstockâmes',
    'déstockasse',
    'déstockâtes',
    'déstockerai',
    'déstockeras',
    'déstockerez',
    'déstockions',
    'destructeur',
    'destructifs',
    'destruction',
    'destructive',
    'destructura',
    'destructure',
    'destructuré',
    'désulfitais',
    'désulfitait',
    'désulfitant',
    'désulfitées',
    'désulfitent',
    'désulfitera',
    'désulfitiez',
    'désulfitons',
    'désulfurais',
    'désulfurait',
    'désulfurant',
    'désulfurées',
    'désulfurent',
    'désulfurera',
    'désulfuriez',
    'désulfurons',
    'désunirions',
    'désunissais',
    'désunissait',
    'désunissant',
    'désunissent',
    'désunissiez',
    'désunissons',
    'détachables',
    'détachaient',
    'détachantes',
    'détachasses',
    'détachement',
    'détacherais',
    'détacherait',
    'détachèrent',
    'détacheriez',
    'détacherons',
    'détacheront',
    'détacheuses',
    'détaillâmes',
    'détaillante',
    'détaillants',
    'détaillasse',
    'détaillâtes',
    'détaillerai',
    'détailleras',
    'détaillerez',
    'détaillions',
    'détalassent',
    'détalassiez',
    'détalerions',
    'détallaient',
    'détallasses',
    'détallerais',
    'détallerait',
    'détallèrent',
    'détalleriez',
    'détallerons',
    'détalleront',
    'détapissais',
    'détapissait',
    'détapissant',
    'détapissées',
    'détapissent',
    'détapissera',
    'détapissiez',
    'détapissons',
    'détartrages',
    'détartrâmes',
    'détartrante',
    'détartrants',
    'détartrasse',
    'détartrâtes',
    'détartrerai',
    'détartreras',
    'détartrerez',
    'détartreurs',
    'détartrions',
    'détaxassent',
    'détaxassiez',
    'détaxations',
    'détaxerions',
    'détectables',
    'détectaient',
    'détectasses',
    'détecterais',
    'détecterait',
    'détectèrent',
    'détecteriez',
    'détecterons',
    'détecteront',
    'déteignîmes',
    'déteignions',
    'déteignisse',
    'déteignîtes',
    'déteindrais',
    'déteindrait',
    'déteindriez',
    'déteindrons',
    'déteindront',
    'dételassent',
    'dételassiez',
    'détellerais',
    'détellerait',
    'dételleriez',
    'détellerons',
    'dételleront',
    'détendaient',
    'détendirent',
    'détendisses',
    'détendrions',
    'détentrices',
    'détergeâmes',
    'détergeasse',
    'détergeâtes',
    'détergentes',
    'détergerais',
    'détergerait',
    'détergèrent',
    'détergeriez',
    'détergerons',
    'détergeront',
    'détériorais',
    'détériorait',
    'détériorant',
    'détériorées',
    'détériorent',
    'détériorera',
    'détérioriez',
    'détériorons',
    'déterminais',
    'déterminait',
    'déterminant',
    'déterminées',
    'déterminent',
    'déterminera',
    'déterminiez',
    'déterminons',
    'déterraient',
    'déterrasses',
    'déterrement',
    'déterrerais',
    'déterrerait',
    'déterrèrent',
    'déterreriez',
    'déterrerons',
    'déterreront',
    'détestables',
    'détestaient',
    'détestasses',
    'détestation',
    'détesterais',
    'détesterait',
    'détestèrent',
    'détesteriez',
    'détesterons',
    'détesteront',
    'détiendrais',
    'détiendrait',
    'détiendriez',
    'détiendrons',
    'détiendront',
    'détinssions',
    'détirassent',
    'détirassiez',
    'détirerions',
    'détissaient',
    'détissasses',
    'détisserais',
    'détisserait',
    'détissèrent',
    'détisseriez',
    'détisserons',
    'détisseront',
    'détonassent',
    'détonassiez',
    'détonateurs',
    'détonations',
    'détonerions',
    'détonnaient',
    'détonnasses',
    'détonnelais',
    'détonnelait',
    'détonnelant',
    'détonnelées',
    'détonneliez',
    'détonnelles',
    'détonnelons',
    'détonnerais',
    'détonnerait',
    'détonnèrent',
    'détonneriez',
    'détonnerons',
    'détonneront',
    'détordaient',
    'détordirent',
    'détordisses',
    'détordrions',
    'détortillai',
    'détortillas',
    'détortillât',
    'détortillée',
    'détortiller',
    'détortilles',
    'détortillés',
    'détortillez',
    'détouraient',
    'détourasses',
    'détourerais',
    'détourerait',
    'détourèrent',
    'détoureriez',
    'détourerons',
    'détoureront',
    'détournâmes',
    'détournasse',
    'détournâtes',
    'détournerai',
    'détourneras',
    'détournerez',
    'détournions',
    'détractâmes',
    'détractasse',
    'détractâtes',
    'détracterai',
    'détracteras',
    'détracterez',
    'détracteurs',
    'détractions',
    'détractrice',
    'détranchais',
    'détranchait',
    'détranchant',
    'détranchent',
    'détranchera',
    'détranchiez',
    'détranchons',
    'détransposa',
    'détranspose',
    'détransposé',
    'détraquâmes',
    'détraquasse',
    'détraquâtes',
    'détraquerai',
    'détraqueras',
    'détraquerez',
    'détraquions',
    'détrempâmes',
    'détrempasse',
    'détrempâtes',
    'détremperai',
    'détremperas',
    'détremperez',
    'détrempions',
    'détressâmes',
    'détressasse',
    'détressâtes',
    'détresserai',
    'détresseras',
    'détresserez',
    'détressions',
    'détricotais',
    'détricotait',
    'détricotant',
    'détricotées',
    'détricotent',
    'détricotera',
    'détricotiez',
    'détricotons',
    'détritiques',
    'détrompâmes',
    'détrompasse',
    'détrompâtes',
    'détromperai',
    'détromperas',
    'détromperez',
    'détrompeurs',
    'détrompions',
    'détrônaient',
    'détrônasses',
    'détronchais',
    'détronchait',
    'détronchant',
    'détronchent',
    'détronchera',
    'détronchiez',
    'détronchons',
    'détrônerais',
    'détrônerait',
    'détrônèrent',
    'détrôneriez',
    'détrônerons',
    'détrôneront',
    'détroquâmes',
    'détroquasse',
    'détroquâtes',
    'détroquerai',
    'détroqueras',
    'détroquerez',
    'détroquions',
    'détroussais',
    'détroussait',
    'détroussant',
    'détroussées',
    'détroussent',
    'détroussera',
    'détrousseur',
    'détroussiez',
    'détroussons',
    'détruirions',
    'détruisîmes',
    'détruisions',
    'détruisisse',
    'détruisîtes',
    'deux-pièces',
    'deux-points',
    'deux-quatre',
    'dévalassent',
    'dévalassiez',
    'dévalerions',
    'dévalisâmes',
    'dévalisasse',
    'dévalisâtes',
    'dévaliserai',
    'dévaliseras',
    'dévaliserez',
    'dévalisions',
    'dévalorisai',
    'dévalorisas',
    'dévalorisât',
    'dévalorisée',
    'dévaloriser',
    'dévalorises',
    'dévalorisés',
    'dévalorisez',
    'dévaluaient',
    'dévaluasses',
    'dévaluation',
    'dévaluèrent',
    'devançaient',
    'devançasses',
    'devancement',
    'devancerais',
    'devancerait',
    'devancèrent',
    'devanceriez',
    'devancerons',
    'devanceront',
    'devancières',
    'dévasassent',
    'dévasassiez',
    'dévaserions',
    'dévastaient',
    'dévastasses',
    'dévastateur',
    'dévastation',
    'dévasterais',
    'dévasterait',
    'dévastèrent',
    'dévasteriez',
    'dévasterons',
    'dévasteront',
    'développais',
    'développait',
    'développant',
    'développées',
    'développent',
    'développera',
    'développeur',
    'développiez',
    'développons',
    'déventaient',
    'déventasses',
    'déventerais',
    'déventerait',
    'déventèrent',
    'déventeriez',
    'déventerons',
    'déventeront',
    'déverdirais',
    'déverdirait',
    'déverdirent',
    'déverdiriez',
    'déverdirons',
    'déverdiront',
    'déverdisses',
    'déverdissez',
    'dévergondai',
    'dévergondas',
    'dévergondât',
    'dévergondée',
    'dévergonder',
    'dévergondes',
    'dévergondés',
    'dévergondez',
    'déverguâmes',
    'déverguasse',
    'déverguâtes',
    'déverguerai',
    'dévergueras',
    'déverguerez',
    'déverguions',
    'dévernirais',
    'dévernirait',
    'dévernirent',
    'déverniriez',
    'dévernirons',
    'déverniront',
    'dévernisses',
    'dévernissez',
    'déversaient',
    'déversasses',
    'déversement',
    'déverserais',
    'déverserait',
    'déversèrent',
    'déverseriez',
    'déverserons',
    'déverseront',
    'dévêtirions',
    'dévêtissent',
    'dévêtissiez',
    'déviassions',
    'déviatrices',
    'dévidassent',
    'dévidassiez',
    'déviderions',
    'deviendrais',
    'deviendrait',
    'deviendriez',
    'deviendrons',
    'deviendront',
    'dévieraient',
    'devinassent',
    'devinassiez',
    'devineresse',
    'devinerions',
    'devinssions',
    'dévirilisai',
    'dévirilisas',
    'dévirilisât',
    'dévirilisée',
    'déviriliser',
    'dévirilises',
    'dévirilisés',
    'dévirilisez',
    'dévirolâmes',
    'dévirolasse',
    'dévirolâtes',
    'dévirolerai',
    'déviroleras',
    'dévirolerez',
    'dévirolions',
    'dévisageais',
    'dévisageait',
    'dévisageant',
    'dévisageons',
    'dévisagerai',
    'dévisageras',
    'dévisagerez',
    'dévisagions',
    'devisassent',
    'devisassiez',
    'deviserions',
    'dévissaient',
    'dévissasses',
    'dévissèrent',
    'dévitalisai',
    'dévitalisas',
    'dévitalisât',
    'dévitalisée',
    'dévitaliser',
    'dévitalises',
    'dévitalisés',
    'dévitalisez',
    'dévitrifiai',
    'dévitrifias',
    'dévitrifiât',
    'dévitrifiée',
    'dévitrifier',
    'dévitrifies',
    'dévitrifiés',
    'dévitrifiez',
    'dévoiements',
    'dévoierions',
    'dévoilaient',
    'dévoilasses',
    'dévoilement',
    'dévoilèrent',
    'dévoltaient',
    'dévoltasses',
    'dévolterais',
    'dévolterait',
    'dévoltèrent',
    'dévolteriez',
    'dévolterons',
    'dévolteront',
    'dévolutions',
    'dévolutives',
    'dévoniennes',
    'dévorassent',
    'dévorassiez',
    'dévorateurs',
    'dévoratrice',
    'dévorerions',
    'dévouassent',
    'dévouassiez',
    'dévouements',
    'dévouerions',
    'dévoyassent',
    'dévoyassiez',
    'dextralités',
    'dextrogyres',
    'diabétiques',
    'diaboliques',
    'diachronies',
    'diaconesses',
    'diacritique',
    'diagnostics',
    'dialectales',
    'dialectique',
    'dialectisai',
    'dialectisas',
    'dialectisât',
    'dialectisée',
    'dialectiser',
    'dialectises',
    'dialectisés',
    'dialectisez',
    'dialogiques',
    'dialoguâmes',
    'dialoguasse',
    'dialoguâtes',
    'dialoguerai',
    'dialogueras',
    'dialoguerez',
    'dialoguions',
    'dialoguiste',
    'dialypétale',
    'dialysaient',
    'dialysasses',
    'dialyserais',
    'dialyserait',
    'dialysèrent',
    'dialyseriez',
    'dialyserons',
    'dialyseront',
    'diamantaire',
    'diamantâmes',
    'diamantasse',
    'diamantâtes',
    'diamanterai',
    'diamanteras',
    'diamanterez',
    'diamantines',
    'diamantions',
    'diamétrales',
    'diantrement',
    'diaphanéité',
    'diaphorèses',
    'diaphragmai',
    'diaphragmas',
    'diaphragmât',
    'diaphragmée',
    'diaphragmer',
    'diaphragmes',
    'diaphragmés',
    'diaphragmez',
    'diapositive',
    'diaprassent',
    'diaprassiez',
    'diaprerions',
    'diarrhéique',
    'diarthroses',
    'diastasique',
    'diastolique',
    'diatomiques',
    'diatoniques',
    'dichotomies',
    'dichroïques',
    'dichroïsmes',
    'dictaphones',
    'dictassions',
    'dictatorial',
    'dictatrices',
    'dicteraient',
    'didacticiel',
    'didactiques',
    'didactismes',
    'didascalies',
    'diencéphale',
    'diésélistes',
    'diététicien',
    'diététiques',
    'diffamaient',
    'diffamantes',
    'diffamasses',
    'diffamateur',
    'diffamation',
    'diffamerais',
    'diffamerait',
    'diffamèrent',
    'diffameriez',
    'diffamerons',
    'diffameront',
    'différaient',
    'différasses',
    'différences',
    'différencia',
    'différencie',
    'différencié',
    'différentes',
    'différerais',
    'différerait',
    'différèrent',
    'différeriez',
    'différerons',
    'différeront',
    'difficultés',
    'difformâmes',
    'difformasse',
    'difformâtes',
    'difformerai',
    'difformeras',
    'difformerez',
    'difformions',
    'difformités',
    'diffractais',
    'diffractait',
    'diffractant',
    'diffractées',
    'diffractent',
    'diffractera',
    'diffractiez',
    'diffraction',
    'diffractons',
    'diffusaient',
    'diffusasses',
    'diffusément',
    'diffuserais',
    'diffuserait',
    'diffusèrent',
    'diffuseriez',
    'diffuserons',
    'diffuseront',
    'diffuseuses',
    'diffusibles',
    'digérassent',
    'digérassiez',
    'digérerions',
    'digestibles',
    'digitalines',
    'digitalisai',
    'digitalisas',
    'digitalisât',
    'digitalisée',
    'digitaliser',
    'digitalises',
    'digitalisés',
    'digitalisez',
    'digitiforme',
    'digitigrade',
    'dignitaires',
    'digressions',
    'digtalisera',
    'dilacérâmes',
    'dilacérasse',
    'dilacérâtes',
    'dilacérerai',
    'dilacéreras',
    'dilacérerez',
    'dilacérions',
    'dilapidâmes',
    'dilapidasse',
    'dilapidâtes',
    'dilapiderai',
    'dilapideras',
    'dilapiderez',
    'dilapidions',
    'dilatassent',
    'dilatassiez',
    'dilatateurs',
    'dilatations',
    'dilatatrice',
    'dilaterions',
    'dilatomètre',
    'dilettantes',
    'diligemment',
    'diluassions',
    'dilueraient',
    'diluviennes',
    'dimensionna',
    'dimensionne',
    'dimensionné',
    'diminuaient',
    'diminuasses',
    'diminuerais',
    'diminuerait',
    'diminuèrent',
    'diminueriez',
    'diminuerons',
    'diminueront',
    'diminutions',
    'diminutives',
    'dimissoires',
    'dimorphisme',
    'dinanderies',
    'dindonnâmes',
    'dindonnasse',
    'dindonnâtes',
    'dindonneaux',
    'dindonnerai',
    'dindonneras',
    'dindonnerez',
    'dindonnions',
    'dinguassent',
    'dinguassiez',
    'dinguerions',
    'dinothérium',
    'diocésaines',
    'dionysiaque',
    'dioptriques',
    'diphtérique',
    'diphtonguai',
    'diphtonguas',
    'diphtonguât',
    'diphtonguée',
    'diphtonguer',
    'diphtongues',
    'diphtongués',
    'diphtonguez',
    'diplocoques',
    'diplômaient',
    'diplômasses',
    'diplomaties',
    'diplômerais',
    'diplômerait',
    'diplômèrent',
    'diplômeriez',
    'diplômerons',
    'diplômeront',
    'dipneumonée',
    'dipneumonés',
    'dipsomanies',
    'directement',
    'directivité',
    'directoires',
    'directorats',
    'directorial',
    'directrices',
    'dirigeables',
    'dirigeaient',
    'dirigeantes',
    'dirigeasses',
    'dirigerions',
    'discernable',
    'discernâmes',
    'discernasse',
    'discernâtes',
    'discernerai',
    'discerneras',
    'discernerez',
    'discernions',
    'disciplinai',
    'disciplinas',
    'disciplinât',
    'disciplinée',
    'discipliner',
    'disciplines',
    'disciplinés',
    'disciplinez',
    'discoïdales',
    'discontinua',
    'discontinue',
    'discontinué',
    'discontinus',
    'disconvenez',
    'disconvenir',
    'disconvenue',
    'disconvenus',
    'disconviens',
    'disconvient',
    'discopathie',
    'discophiles',
    'discophilie',
    'discordâmes',
    'discordance',
    'discordante',
    'discordants',
    'discordasse',
    'discordâtes',
    'discorderai',
    'discorderas',
    'discorderez',
    'discordions',
    'discothèque',
    'discountées',
    'discounters',
    'discoureurs',
    'discoureuse',
    'discourions',
    'discourrais',
    'discourrait',
    'discourriez',
    'discourrons',
    'discourront',
    'discourtois',
    'discourûmes',
    'discourusse',
    'discourûtes',
    'discréditai',
    'discréditas',
    'discréditât',
    'discréditée',
    'discréditer',
    'discrédites',
    'discrédités',
    'discréditez',
    'discrétions',
    'discrétoire',
    'discriminai',
    'discriminas',
    'discriminât',
    'discriminée',
    'discriminer',
    'discrimines',
    'discriminés',
    'discriminez',
    'disculpâmes',
    'disculpasse',
    'disculpâtes',
    'disculperai',
    'disculperas',
    'disculperez',
    'disculpions',
    'discursives',
    'discussions',
    'discutables',
    'discutaient',
    'discutailla',
    'discutaille',
    'discutaillé',
    'discutasses',
    'discuterais',
    'discuterait',
    'discutèrent',
    'discuteriez',
    'discuterons',
    'discuteront',
    'discuteuses',
    'disertement',
    'disetteuses',
    'disgraciais',
    'disgraciait',
    'disgraciant',
    'disgraciées',
    'disgracient',
    'disgraciera',
    'disgracieux',
    'disgraciiez',
    'disgracions',
    'disharmonie',
    'disjoignais',
    'disjoignait',
    'disjoignant',
    'disjoignent',
    'disjoigniez',
    'disjoignons',
    'disjoindrai',
    'disjoindras',
    'disjoindrez',
    'disjonctais',
    'disjonctait',
    'disjonctant',
    'disjonctées',
    'disjonctent',
    'disjonctera',
    'disjoncteur',
    'disjonctiez',
    'disjonctifs',
    'disjonction',
    'disjonctive',
    'disjonctons',
    'dislocation',
    'disloquâmes',
    'disloquasse',
    'disloquâtes',
    'disloquerai',
    'disloqueras',
    'disloquerez',
    'disloquions',
    'disparaisse',
    'disparaîtra',
    'disparaître',
    'disparation',
    'disparition',
    'disparurent',
    'disparusses',
    'dispatchers',
    'dispatching',
    'dispendieux',
    'dispensable',
    'dispensaire',
    'dispensâmes',
    'dispensasse',
    'dispensâtes',
    'dispenserai',
    'dispenseras',
    'dispenserez',
    'dispensions',
    'dispersâmes',
    'dispersante',
    'dispersants',
    'dispersasse',
    'dispersâtes',
    'disperserai',
    'disperseras',
    'disperserez',
    'dispersions',
    'dispersives',
    'disponibles',
    'disposaient',
    'disposasses',
    'disposerais',
    'disposerait',
    'disposèrent',
    'disposeriez',
    'disposerons',
    'disposeront',
    'dispositifs',
    'disposition',
    'disputaient',
    'disputailla',
    'disputaille',
    'disputaillé',
    'disputasses',
    'disputerais',
    'disputerait',
    'disputèrent',
    'disputeriez',
    'disputerons',
    'disputeront',
    'disqualifia',
    'disqualifie',
    'disqualifié',
    'disruptives',
    'dissecteurs',
    'dissections',
    'disséminais',
    'disséminait',
    'disséminant',
    'disséminées',
    'disséminent',
    'disséminera',
    'disséminiez',
    'disséminons',
    'dissensions',
    'disséquâmes',
    'disséquasse',
    'disséquâtes',
    'disséquerai',
    'disséqueras',
    'disséquerez',
    'disséqueurs',
    'disséquions',
    'dissertâmes',
    'dissertasse',
    'dissertâtes',
    'disserterai',
    'disserteras',
    'disserterez',
    'dissertions',
    'dissidences',
    'dissidentes',
    'dissimulais',
    'dissimulait',
    'dissimulant',
    'dissimulées',
    'dissimulent',
    'dissimulera',
    'dissimuliez',
    'dissimulons',
    'dissipaient',
    'dissipasses',
    'dissipateur',
    'dissipation',
    'dissiperais',
    'dissiperait',
    'dissipèrent',
    'dissiperiez',
    'dissiperons',
    'dissiperont',
    'dissociable',
    'dissociâmes',
    'dissociasse',
    'dissociâtes',
    'dissocierai',
    'dissocieras',
    'dissocierez',
    'dissociions',
    'dissolubles',
    'dissolutifs',
    'dissolution',
    'dissolutive',
    'dissolvante',
    'dissolvants',
    'dissolvions',
    'dissonaient',
    'dissonances',
    'dissonasses',
    'dissonerais',
    'dissonerait',
    'dissonèrent',
    'dissoneriez',
    'dissonerons',
    'dissoneront',
    'dissoudrais',
    'dissoudrait',
    'dissoudriez',
    'dissoudrons',
    'dissoudront',
    'dissuadâmes',
    'dissuadasse',
    'dissuadâtes',
    'dissuaderai',
    'dissuaderas',
    'dissuaderez',
    'dissuadions',
    'dissuasions',
    'dissuasives',
    'dissyllabes',
    'dissymétrie',
    'distançâmes',
    'distançasse',
    'distançâtes',
    'distancerai',
    'distanceras',
    'distancerez',
    'distanciais',
    'distanciait',
    'distanciant',
    'distanciées',
    'distancient',
    'distanciera',
    'distanciiez',
    'distancions',
    'distendîmes',
    'distendions',
    'distendisse',
    'distendîtes',
    'distendrais',
    'distendrait',
    'distendriez',
    'distendrons',
    'distendront',
    'distensions',
    'distillâmes',
    'distillasse',
    'distillâtes',
    'distillerai',
    'distilleras',
    'distillerez',
    'distillerie',
    'distillions',
    'distinctifs',
    'distinction',
    'distinctive',
    'distinguais',
    'distinguait',
    'distinguant',
    'distinguées',
    'distinguent',
    'distinguera',
    'distinguiez',
    'distinguons',
    'distordîmes',
    'distordions',
    'distordisse',
    'distordîtes',
    'distordrais',
    'distordrait',
    'distordriez',
    'distordrons',
    'distordront',
    'distorsions',
    'distraction',
    'distrairais',
    'distrairait',
    'distrairiez',
    'distrairons',
    'distrairont',
    'distrayante',
    'distrayants',
    'distrayions',
    'distribuais',
    'distribuait',
    'distribuant',
    'distribuées',
    'distribuent',
    'distribuera',
    'distribuiez',
    'distribuons',
    'distributif',
    'dithyrambes',
    'diurétiques',
    'divagateurs',
    'divagations',
    'divagatrice',
    'divaguaient',
    'divaguasses',
    'divaguerais',
    'divaguerait',
    'divaguèrent',
    'divagueriez',
    'divaguerons',
    'divagueront',
    'divergeâmes',
    'divergeasse',
    'divergeâtes',
    'divergences',
    'divergentes',
    'divergerais',
    'divergerait',
    'divergèrent',
    'divergeriez',
    'divergerons',
    'divergeront',
    'diversement',
    'diversifiai',
    'diversifias',
    'diversifiât',
    'diversifiée',
    'diversifier',
    'diversifies',
    'diversifiés',
    'diversifiez',
    'diverticule',
    'divertirais',
    'divertirait',
    'divertirent',
    'divertiriez',
    'divertirons',
    'divertiront',
    'divertisses',
    'divertissez',
    'divinateurs',
    'divinations',
    'divinatoire',
    'divinatrice',
    'divinisâmes',
    'divinisasse',
    'divinisâtes',
    'diviniserai',
    'diviniseras',
    'diviniserez',
    'divinisions',
    'divisassent',
    'divisassiez',
    'diviserions',
    'divorçaient',
    'divorçasses',
    'divorcerais',
    'divorcerait',
    'divorcèrent',
    'divorceriez',
    'divorcerons',
    'divorceront',
    'divulgateur',
    'divulgation',
    'divulguâmes',
    'divulguasse',
    'divulguâtes',
    'divulguerai',
    'divulgueras',
    'divulguerez',
    'divulguions',
    'docimologie',
    'doctorantes',
    'doctoresses',
    'doctrinaire',
    'doctrinales',
    'documentais',
    'documentait',
    'documentant',
    'documentées',
    'documentent',
    'documentera',
    'documentiez',
    'documentons',
    'dodécagonal',
    'dodécagones',
    'dodécastyle',
    'dodelinâmes',
    'dodelinasse',
    'dodelinâtes',
    'dodelinerai',
    'dodelineras',
    'dodelinerez',
    'dodelinions',
    'dodinassent',
    'dodinassiez',
    'dodinerions',
    'dogmatiques',
    'dogmatisais',
    'dogmatisait',
    'dogmatisant',
    'dogmatisées',
    'dogmatisent',
    'dogmatisera',
    'dogmatiseur',
    'dogmatisiez',
    'dogmatismes',
    'dogmatisons',
    'dogmatistes',
    'doigtassent',
    'doigtassiez',
    'doigterions',
    'dolomitique',
    'domanialité',
    'domesticité',
    'domestiquai',
    'domestiquas',
    'domestiquât',
    'domestiquée',
    'domestiquer',
    'domestiques',
    'domestiqués',
    'domestiquez',
    'domiciliais',
    'domiciliait',
    'domiciliant',
    'domiciliées',
    'domicilient',
    'domiciliera',
    'domiciliiez',
    'domicilions',
    'dominassent',
    'dominassiez',
    'dominateurs',
    'dominations',
    'dominatrice',
    'dominerions',
    'dominicaine',
    'dominicains',
    'dominicales',
    'dominoterie',
    'dominotière',
    'dominotiers',
    'dommageable',
    'domptassent',
    'domptassiez',
    'dompterions',
    'donjuanisme',
    'donnassions',
    'donneraient',
    'dorlotaient',
    'dorlotasses',
    'dorlotement',
    'dorloterais',
    'dorloterait',
    'dorlotèrent',
    'dorloteriez',
    'dorloterons',
    'dorloteront',
    'dormiraient',
    'dormissions',
    'douairières',
    'doublassent',
    'doublassiez',
    'doublements',
    'doublerions',
    'douce-amère',
    'doucereuses',
    'douchassent',
    'douchassiez',
    'doucherions',
    'douciraient',
    'doucissages',
    'doucissions',
    'douillettes',
    'douloureuse',
    'doutassions',
    'douteraient',
    'draconienne',
    'dragéifiais',
    'dragéifiait',
    'dragéifiant',
    'dragéifiées',
    'dragéifient',
    'dragéifiera',
    'dragéifiiez',
    'dragéifions',
    'drageonnage',
    'drageonnais',
    'drageonnait',
    'drageonnant',
    'drageonnent',
    'drageonnera',
    'drageonniez',
    'drageonnons',
    'dragonnades',
    'dragonniers',
    'draguassent',
    'draguassiez',
    'draguerions',
    'draieraient',
    'drainassent',
    'drainassiez',
    'drainerions',
    'draisiennes',
    'dramatiques',
    'dramatisais',
    'dramatisait',
    'dramatisant',
    'dramatisées',
    'dramatisent',
    'dramatisera',
    'dramatisiez',
    'dramatisons',
    'dramaturges',
    'dramaturgie',
    'drapassions',
    'draperaient',
    'drap-housse',
    'dravidienne',
    'drayassions',
    'drayeraient',
    'dreadnought',
    'dressassent',
    'dressassiez',
    'dresserions',
    'dreyfusarde',
    'dreyfusards',
    'dribblaient',
    'dribblasses',
    'dribblerais',
    'dribblerait',
    'dribblèrent',
    'dribbleriez',
    'dribblerons',
    'dribbleront',
    'dribbleuses',
    'drillassent',
    'drillassiez',
    'drillerions',
    'drivassions',
    'driveraient',
    'droguassent',
    'droguassiez',
    'droguerions',
    'droits-fils',
    'drolatiques',
    'dromadaires',
    'dropassions',
    'droperaient',
    'droppassent',
    'droppassiez',
    'dropperions',
    'drosophiles',
    'drossassent',
    'drossassiez',
    'drosserions',
    'dubitatives',
    'duffel-coat',
    'duffle-coat',
    'dulcifiâmes',
    'dulcifiasse',
    'dulcifiâtes',
    'dulcifierai',
    'dulcifieras',
    'dulcifierez',
    'dulcifiions',
    'duodécimale',
    'duodécimaux',
    'duplexaient',
    'duplexasses',
    'duplexerais',
    'duplexerait',
    'duplexèrent',
    'duplexeriez',
    'duplexerons',
    'duplexeront',
    'duplicateur',
    'duplication',
    'dupliquâmes',
    'dupliquasse',
    'dupliquâtes',
    'dupliquerai',
    'dupliqueras',
    'dupliquerez',
    'dupliquions',
    'durabilités',
    'durablement',
    'durciraient',
    'durcisseurs',
    'durcissions',
    'dures-mères',
    'duvetassent',
    'duvetassiez',
    'duvetterais',
    'duvetterait',
    'duvetteriez',
    'duvetterons',
    'duvetteront',
    'dynamisâmes',
    'dynamisasse',
    'dynamisâtes',
    'dynamiserai',
    'dynamiseras',
    'dynamiserez',
    'dynamisions',
    'dynamitages',
    'dynamitâmes',
    'dynamitasse',
    'dynamitâtes',
    'dynamiterai',
    'dynamiteras',
    'dynamiterez',
    'dynamiteurs',
    'dynamitions',
    'dynamogènes',
    'dynamomètre',
    'dynastiques',
    'dysarthries',
    'dyscalculie',
    'dyschromies',
    'dysenteries',
    'dysfonction',
    'dysgraphies',
    'dyshidroses',
    'dyslexiques',
    'dyspepsique',
    'dyspeptique',
    'dysprosiums',
    'dystrophies',
    'eaux-de-vie',
    'eaux-fortes',
    'ébahiraient',
    'ébahissions',
    'ébarbassent',
    'ébarbassiez',
    'ébarberions',
    'ébattissiez',
    'ébaubirions',
    'ébaubissiez',
    'ébaubissons',
    'ébauchaient',
    'ébauchasses',
    'ébaucherais',
    'ébaucherait',
    'ébauchèrent',
    'ébaucheriez',
    'ébaucherons',
    'ébaucheront',
    'ébaudirions',
    'ébaudissiez',
    'ébaudissons',
    'ébavuraient',
    'ébavurasses',
    'ébavurerais',
    'ébavurerait',
    'ébavurèrent',
    'ébavureriez',
    'ébavurerons',
    'ébavureront',
    'ébénisterie',
    'éberluaient',
    'éberluasses',
    'éberluerais',
    'éberluerait',
    'éberluèrent',
    'éberlueriez',
    'éberluerons',
    'éberlueront',
    'ébiselaient',
    'ébiselasses',
    'ébiselerais',
    'ébiselerait',
    'ébiselèrent',
    'ébiseleriez',
    'ébiselerons',
    'ébiseleront',
    'éblouirions',
    'éblouissais',
    'éblouissait',
    'éblouissant',
    'éblouissent',
    'éblouissiez',
    'éblouissons',
    'éborgnaient',
    'éborgnasses',
    'éborgnement',
    'éborgnerais',
    'éborgnerait',
    'éborgnèrent',
    'éborgneriez',
    'éborgnerons',
    'éborgneront',
    'ébossassent',
    'ébossassiez',
    'ébosserions',
    'ébouassions',
    'éboueraient',
    'ébouillanta',
    'ébouillante',
    'ébouillanté',
    'éboulassent',
    'éboulassiez',
    'éboulements',
    'éboulerions',
    'ébourgeonna',
    'ébourgeonne',
    'ébourgeonné',
    'ébouriffais',
    'ébouriffait',
    'ébouriffant',
    'ébouriffées',
    'ébouriffent',
    'ébouriffera',
    'ébouriffiez',
    'ébouriffons',
    'ébourraient',
    'ébourrasses',
    'ébourrerais',
    'ébourrerait',
    'ébourrèrent',
    'ébourreriez',
    'ébourrerons',
    'ébourreront',
    'éboutassent',
    'éboutassiez',
    'ébouterions',
    'ébraisaient',
    'ébraisasses',
    'ébraiserais',
    'ébraiserait',
    'ébraisèrent',
    'ébraiseriez',
    'ébraiserons',
    'ébraiseront',
    'ébranchages',
    'ébranchâmes',
    'ébranchasse',
    'ébranchâtes',
    'ébrancherai',
    'ébrancheras',
    'ébrancherez',
    'ébranchions',
    'ébranchoirs',
    'ébranlaient',
    'ébranlasses',
    'ébranlement',
    'ébranlerais',
    'ébranlerait',
    'ébranlèrent',
    'ébranleriez',
    'ébranlerons',
    'ébranleront',
    'ébrasassent',
    'ébrasassiez',
    'ébrasements',
    'ébraserions',
    'ébréchaient',
    'ébréchasses',
    'ébrécherais',
    'ébrécherait',
    'ébréchèrent',
    'ébrécheriez',
    'ébrécherons',
    'ébrécheront',
    'ébrouassiez',
    'ébrouements',
    'ébrouerions',
    'ébruitaient',
    'ébruitasses',
    'ébruitement',
    'ébruiterais',
    'ébruiterait',
    'ébruitèrent',
    'ébruiteriez',
    'ébruiterons',
    'ébruiteront',
    'ébullitions',
    'éburnéennes',
    'écachassent',
    'écachassiez',
    'écacherions',
    'écaffassent',
    'écaffassiez',
    'écafferions',
    'écaillaient',
    'écaillasses',
    'écaillerais',
    'écaillerait',
    'écaillèrent',
    'écailleriez',
    'écaillerons',
    'écailleront',
    'écailleuses',
    'écalassions',
    'écaleraient',
    'écanguaient',
    'écanguasses',
    'écanguerais',
    'écanguerait',
    'écanguèrent',
    'écangueriez',
    'écanguerons',
    'écangueront',
    'écarquillai',
    'écarquillas',
    'écarquillât',
    'écarquillée',
    'écarquiller',
    'écarquilles',
    'écarquillés',
    'écarquillez',
    'écartassent',
    'écartassiez',
    'écartelâmes',
    'écartelasse',
    'écartelâtes',
    'écartèlerai',
    'écartèleras',
    'écartèlerez',
    'écartelions',
    'écartelures',
    'écartements',
    'écarterions',
    'écatiraient',
    'écatissions',
    'ecclésiales',
    'échafaudage',
    'échafaudais',
    'échafaudait',
    'échafaudant',
    'échafaudées',
    'échafaudent',
    'échafaudera',
    'échafaudiez',
    'échafaudons',
    'échalassais',
    'échalassait',
    'échalassant',
    'échalassées',
    'échalassent',
    'échalassera',
    'échalassiez',
    'échalassons',
    'échampirais',
    'échampirait',
    'échampirent',
    'échampiriez',
    'échampirons',
    'échampiront',
    'échampisses',
    'échampissez',
    'échancrâmes',
    'échancrasse',
    'échancrâtes',
    'échancrerai',
    'échancreras',
    'échancrerez',
    'échancrions',
    'échancrures',
    'échanfreina',
    'échanfreine',
    'échanfreiné',
    'échangeable',
    'échangeâmes',
    'échangeasse',
    'échangeâtes',
    'échangerais',
    'échangerait',
    'échangèrent',
    'échangeriez',
    'échangerons',
    'échangeront',
    'échangeuses',
    'échangistes',
    'échantillon',
    'échappaient',
    'échappasses',
    'échappement',
    'échapperais',
    'échapperait',
    'échappèrent',
    'échapperiez',
    'échapperons',
    'échapperont',
    'échardonnai',
    'échardonnas',
    'échardonnât',
    'échardonnée',
    'échardonner',
    'échardonnes',
    'échardonnés',
    'échardonnez',
    'écharnaient',
    'écharnasses',
    'écharnement',
    'écharnerais',
    'écharnerait',
    'écharnèrent',
    'écharneriez',
    'écharnerons',
    'écharneront',
    'écharneuses',
    'écharpaient',
    'écharpasses',
    'écharperais',
    'écharperait',
    'écharpèrent',
    'écharperiez',
    'écharperons',
    'écharperont',
    'échaudaient',
    'échaudasses',
    'échauderais',
    'échauderait',
    'échaudèrent',
    'échauderiez',
    'échauderons',
    'échauderont',
    'échauffâmes',
    'échauffante',
    'échauffants',
    'échauffasse',
    'échauffâtes',
    'échaufferai',
    'échaufferas',
    'échaufferez',
    'échauffions',
    'échauguette',
    'échaulaient',
    'échaulasses',
    'échaulerais',
    'échaulerait',
    'échaulèrent',
    'échauleriez',
    'échaulerons',
    'échauleront',
    'échaumaient',
    'échaumasses',
    'échaumerais',
    'échaumerait',
    'échaumèrent',
    'échaumeriez',
    'échaumerons',
    'échaumeront',
    'échéanciers',
    'échelonnais',
    'échelonnait',
    'échelonnant',
    'échelonnées',
    'échelonnent',
    'échelonnera',
    'échelonniez',
    'échelonnons',
    'échenillage',
    'échenillais',
    'échenillait',
    'échenillant',
    'échenillées',
    'échenillent',
    'échenillera',
    'échenilliez',
    'échenilloir',
    'échenillons',
    'échevelâmes',
    'échevelasse',
    'échevelâtes',
    'échevelions',
    'échevellent',
    'échevellera',
    'échevinages',
    'échevinales',
    'échinassent',
    'échinassiez',
    'échinerions',
    'échinocoque',
    'échiquetées',
    'échographie',
    'échoiraient',
    'échoppaient',
    'échoppasses',
    'échopperais',
    'échopperait',
    'échoppèrent',
    'échopperiez',
    'échopperons',
    'échopperont',
    'échouassent',
    'échouassiez',
    'échouements',
    'échouerions',
    'écimassions',
    'écimeraient',
    'éclaboussai',
    'éclaboussas',
    'éclaboussât',
    'éclaboussée',
    'éclabousser',
    'éclabousses',
    'éclaboussés',
    'éclaboussez',
    'éclairaient',
    'éclairantes',
    'éclairasses',
    'éclaircîmes',
    'éclaircirai',
    'éclairciras',
    'éclaircirez',
    'éclaircisse',
    'éclaircîtes',
    'éclairement',
    'éclairerais',
    'éclairerait',
    'éclairèrent',
    'éclaireriez',
    'éclairerons',
    'éclaireront',
    'éclaireuses',
    'éclatassent',
    'éclatassiez',
    'éclatements',
    'éclaterions',
    'éclectiques',
    'éclectismes',
    'éclipsaient',
    'éclipsasses',
    'éclipserais',
    'éclipserait',
    'éclipsèrent',
    'éclipseriez',
    'éclipserons',
    'éclipseront',
    'écliptiques',
    'éclissaient',
    'éclissasses',
    'éclisserais',
    'éclisserait',
    'éclissèrent',
    'éclisseriez',
    'éclisserons',
    'éclisseront',
    'éclopassent',
    'éclopassiez',
    'écloperions',
    'éclusassent',
    'éclusassiez',
    'écluserions',
    'écobuassent',
    'écobuassiez',
    'écobuerions',
    'écoeuraient',
    'écoeurantes',
    'écoeurasses',
    'écoeurement',
    'écoeurerais',
    'écoeurerait',
    'écoeurèrent',
    'écoeureriez',
    'écoeurerons',
    'écoeureront',
    'écologiques',
    'écologistes',
    'éconduirais',
    'éconduirait',
    'éconduiriez',
    'éconduirons',
    'éconduiront',
    'éconduisais',
    'éconduisait',
    'éconduisant',
    'éconduisent',
    'éconduisiez',
    'éconduisons',
    'économétrie',
    'économiques',
    'économisais',
    'économisait',
    'économisant',
    'économisées',
    'économisent',
    'économisera',
    'économiseur',
    'économisiez',
    'économismes',
    'économisons',
    'économistes',
    'écopassions',
    'écoperaient',
    'écorassions',
    'écorçassent',
    'écorçassiez',
    'écorcerions',
    'écorchaient',
    'écorchasses',
    'écorchement',
    'écorcherais',
    'écorcherait',
    'écorchèrent',
    'écorcheriez',
    'écorcherons',
    'écorcheront',
    'écoreraient',
    'écornassent',
    'écornassiez',
    'écornerions',
    'écorniflais',
    'écorniflait',
    'écorniflant',
    'écorniflées',
    'écorniflent',
    'écorniflera',
    'écornifleur',
    'écornifliez',
    'écorniflons',
    'écossassent',
    'écossassiez',
    'écosserions',
    'écosystèmes',
    'écoulassent',
    'écoulassiez',
    'écoulements',
    'écoulerions',
    'écourtaient',
    'écourtasses',
    'écourterais',
    'écourterait',
    'écourtèrent',
    'écourteriez',
    'écourterons',
    'écourteront',
    'écoutassent',
    'écoutassiez',
    'écouterions',
    'écouvillons',
    'écrabouilla',
    'écrabouille',
    'écrabouillé',
    'écrasassent',
    'écrasassiez',
    'écrasements',
    'écraserions',
    'écrémassent',
    'écrémassiez',
    'écrémerions',
    'écrêtassent',
    'écrêtassiez',
    'écrêtements',
    'écrêterions',
    'écriassions',
    'écrivaillai',
    'écrivaillas',
    'écrivaillât',
    'écrivaillée',
    'écrivailler',
    'écrivailles',
    'écrivaillés',
    'écrivaillez',
    'écrivaillon',
    'écrivassais',
    'écrivassait',
    'écrivassant',
    'écrivassent',
    'écrivassera',
    'écrivassier',
    'écrivassiez',
    'écrivassons',
    'écrivissent',
    'écrivissiez',
    'écrouassent',
    'écrouassiez',
    'écrouerions',
    'écrouirions',
    'écrouissage',
    'écrouissais',
    'écrouissait',
    'écrouissant',
    'écrouissent',
    'écrouissiez',
    'écrouissons',
    'écroulement',
    'écrouleriez',
    'écroulerons',
    'écroûtaient',
    'écroûtasses',
    'écroûterais',
    'écroûterait',
    'écroûtèrent',
    'écroûteriez',
    'écroûterons',
    'écroûteront',
    'écroûteuses',
    'ectoblastes',
    'ectoplasmes',
    'écuissaient',
    'écuissasses',
    'écuisserais',
    'écuisserait',
    'écuissèrent',
    'écuisseriez',
    'écuisserons',
    'écuisseront',
    'éculassions',
    'éculeraient',
    'écumassions',
    'écumeraient',
    'écurassions',
    'écureraient',
    'écussonnage',
    'écussonnais',
    'écussonnait',
    'écussonnant',
    'écussonnées',
    'écussonnent',
    'écussonnera',
    'écussonniez',
    'écussonnoir',
    'écussonnons',
    'eczémateuse',
    'édentassent',
    'édentassiez',
    'édenterions',
    'édictassent',
    'édictassiez',
    'édicterions',
    'édifiassent',
    'édifiassiez',
    'édificateur',
    'édification',
    'édifierions',
    'édilitaires',
    'éditassions',
    'éditeraient',
    'éditionnais',
    'éditionnait',
    'éditionnant',
    'éditionnées',
    'éditionnent',
    'éditionnera',
    'éditionniez',
    'éditionnons',
    'éditoriales',
    'éducabilité',
    'éducatrices',
    'édulcorâmes',
    'édulcorante',
    'édulcorants',
    'édulcorasse',
    'édulcorâtes',
    'édulcorerai',
    'édulcoreras',
    'édulcorerez',
    'édulcorions',
    'éduquassent',
    'éduquassiez',
    'éduquerions',
    'éfaufilâmes',
    'éfaufilasse',
    'éfaufilâtes',
    'éfaufilerai',
    'éfaufileras',
    'éfaufilerez',
    'éfaufilions',
    'effaçassent',
    'effaçassiez',
    'effacements',
    'effacerions',
    'effanassent',
    'effanassiez',
    'effanerions',
    'effarassent',
    'effarassiez',
    'effarements',
    'effarerions',
    'effarouchai',
    'effarouchas',
    'effarouchât',
    'effarouchée',
    'effaroucher',
    'effarouches',
    'effarouchés',
    'effarouchez',
    'effarvattes',
    'effectuâmes',
    'effectuasse',
    'effectuâtes',
    'effectuerai',
    'effectueras',
    'effectuerez',
    'effectuions',
    'efféminâmes',
    'efféminasse',
    'efféminâtes',
    'efféminerai',
    'effémineras',
    'efféminerez',
    'efféminions',
    'effeuillage',
    'effeuillais',
    'effeuillait',
    'effeuillant',
    'effeuillées',
    'effeuillent',
    'effeuillera',
    'effeuilliez',
    'effeuillons',
    'efficacités',
    'efficiences',
    'efficientes',
    'effilassent',
    'effilassiez',
    'effilements',
    'effilerions',
    'effilochage',
    'effilochais',
    'effilochait',
    'effilochant',
    'effilochées',
    'effilochent',
    'effilochera',
    'effilocheur',
    'effilochiez',
    'effilochons',
    'effilochure',
    'efflanquais',
    'efflanquait',
    'efflanquant',
    'efflanquées',
    'efflanquent',
    'efflanquera',
    'efflanquiez',
    'efflanquons',
    'effleurages',
    'effleurâmes',
    'effleurasse',
    'effleurâtes',
    'effleurerai',
    'effleureras',
    'effleurerez',
    'effleurîmes',
    'effleurions',
    'effleurirai',
    'effleuriras',
    'effleurirez',
    'effleurisse',
    'effleurîtes',
    'effloraison',
    'effluvaient',
    'effluvasses',
    'effluverais',
    'effluverait',
    'effluvèrent',
    'effluveriez',
    'effluverons',
    'effluveront',
    'effondrâmes',
    'effondrasse',
    'effondrâtes',
    'effondrerai',
    'effondreras',
    'effondrerez',
    'effondrions',
    'efforceriez',
    'efforcerons',
    'effractions',
    'effraierais',
    'effraierait',
    'effraieriez',
    'effraierons',
    'effraieront',
    'effrangeais',
    'effrangeait',
    'effrangeant',
    'effrangeons',
    'effrangerai',
    'effrangeras',
    'effrangerez',
    'effrangions',
    'effrayaient',
    'effrayantes',
    'effrayasses',
    'effrayerais',
    'effrayerait',
    'effrayèrent',
    'effrayeriez',
    'effrayerons',
    'effrayeront',
    'effritaient',
    'effritasses',
    'effritement',
    'effriterais',
    'effriterait',
    'effritèrent',
    'effriteriez',
    'effriterons',
    'effriteront',
    'effronterie',
    'effroyables',
    'égaieraient',
    'égaillèrent',
    'égailleriez',
    'égaillerons',
    'égalassions',
    'égaleraient',
    'égalisaient',
    'égalisasses',
    'égalisateur',
    'égalisation',
    'égaliserais',
    'égaliserait',
    'égalisèrent',
    'égaliseriez',
    'égaliserons',
    'égaliseront',
    'égalitaires',
    'égarassions',
    'égareraient',
    'égayassions',
    'égayeraient',
    'égermassent',
    'égermassiez',
    'égermerions',
    'égoïstement',
    'égorgeaient',
    'égorgeasses',
    'égorgements',
    'égorgerions',
    'égosillâmes',
    'égosillâtes',
    'égosillerez',
    'égosillions',
    'égouttaient',
    'égouttasses',
    'égouttement',
    'égoutterais',
    'égoutterait',
    'égouttèrent',
    'égoutteriez',
    'égoutterons',
    'égoutteront',
    'égrainaient',
    'égrainasses',
    'égrainerais',
    'égrainerait',
    'égrainèrent',
    'égraineriez',
    'égrainerons',
    'égraineront',
    'égrappaient',
    'égrappasses',
    'égrapperais',
    'égrapperait',
    'égrappèrent',
    'égrapperiez',
    'égrapperons',
    'égrapperont',
    'égratignais',
    'égratignait',
    'égratignant',
    'égratignées',
    'égratignent',
    'égratignera',
    'égratigniez',
    'égratignons',
    'égratignure',
    'égrenassent',
    'égrenassiez',
    'égrènements',
    'égrènerions',
    'égrisassent',
    'égrisassiez',
    'égriserions',
    'égrugeaient',
    'égrugeasses',
    'égrugerions',
    'égueulement',
    'égyptiennes',
    'égyptologie',
    'égyptologue',
    'éjaculaient',
    'éjaculasses',
    'éjaculation',
    'éjaculerais',
    'éjaculerait',
    'éjaculèrent',
    'éjaculeriez',
    'éjaculerons',
    'éjaculeront',
    'éjectassent',
    'éjectassiez',
    'éjecterions',
    'éjointaient',
    'éjointasses',
    'éjointerais',
    'éjointerait',
    'éjointèrent',
    'éjointeriez',
    'éjointerons',
    'éjointeront',
    'élaboraient',
    'élaborasses',
    'élaboration',
    'élaborerais',
    'élaborerait',
    'élaborèrent',
    'élaboreriez',
    'élaborerons',
    'élaboreront',
    'élaguassent',
    'élaguassiez',
    'élaguerions',
    'élançassent',
    'élançassiez',
    'élancements',
    'élancerions',
    'élargirions',
    'élargissais',
    'élargissait',
    'élargissant',
    'élargissent',
    'élargissiez',
    'élargissons',
    'élasticités',
    'élastomères',
    'électivités',
    'électorales',
    'électricien',
    'électricité',
    'électrifiai',
    'électrifias',
    'électrifiât',
    'électrifiée',
    'électrifier',
    'électrifies',
    'électrifiés',
    'électrifiez',
    'électriques',
    'électrisais',
    'électrisait',
    'électrisant',
    'électrisées',
    'électrisent',
    'électrisera',
    'électrisiez',
    'électrisons',
    'électrochoc',
    'électrocuta',
    'électrocute',
    'électrocuté',
    'électrogène',
    'électrolysa',
    'électrolyse',
    'électrolysé',
    'électrolyte',
    'électronisa',
    'électronise',
    'électronisé',
    'électuaires',
    'élégiraient',
    'élégissions',
    'élémentaire',
    'éléphanteau',
    'éléphantine',
    'éléphantins',
    'élevassions',
    'élévatoires',
    'élévatrices',
    'élèveraient',
    'élidassions',
    'élideraient',
    'éligibilité',
    'élimassions',
    'élimeraient',
    'éliminaient',
    'éliminasses',
    'éliminateur',
    'élimination',
    'éliminerais',
    'éliminerait',
    'éliminèrent',
    'élimineriez',
    'éliminerons',
    'élimineront',
    'élinguaient',
    'élinguasses',
    'élinguerais',
    'élinguerait',
    'élinguèrent',
    'élingueriez',
    'élinguerons',
    'élingueront',
    'elles-mêmes',
    'ellipsoïdal',
    'ellipsoïdes',
    'elliptiques',
    'éloignaient',
    'éloignasses',
    'éloignement',
    'éloignerais',
    'éloignerait',
    'éloignèrent',
    'éloigneriez',
    'éloignerons',
    'éloigneront',
    'élongations',
    'élongeaient',
    'élongeasses',
    'élongerions',
    'éloquemment',
    'élucidaient',
    'élucidasses',
    'élucidation',
    'éluciderais',
    'éluciderait',
    'élucidèrent',
    'élucideriez',
    'éluciderons',
    'élucideront',
    'élucubrâmes',
    'élucubrasse',
    'élucubrâtes',
    'élucubrerai',
    'élucubreras',
    'élucubrerez',
    'élucubrions',
    'éludassions',
    'éluderaient',
    'elzéviriens',
    'émaciassiez',
    'émaciations',
    'émacierions',
    'émaillaient',
    'émaillasses',
    'émaillerais',
    'émaillerait',
    'émaillèrent',
    'émailleries',
    'émailleriez',
    'émaillerons',
    'émailleront',
    'émailleuses',
    'émanassions',
    'émancipâmes',
    'émancipasse',
    'émancipâtes',
    'émanciperai',
    'émanciperas',
    'émanciperez',
    'émancipions',
    'émaneraient',
    'émargeaient',
    'émargeasses',
    'émargements',
    'émargerions',
    'émasculâmes',
    'émasculasse',
    'émasculâtes',
    'émasculerai',
    'émasculeras',
    'émasculerez',
    'émasculions',
    'emballaient',
    'emballasses',
    'emballement',
    'emballerais',
    'emballerait',
    'emballèrent',
    'emballeriez',
    'emballerons',
    'emballeront',
    'emballeuses',
    'emballottai',
    'emballottas',
    'emballottât',
    'emballottée',
    'emballotter',
    'emballottes',
    'emballottés',
    'emballottez',
    'embarcadère',
    'embarcation',
    'embardaient',
    'embardasses',
    'embarderais',
    'embarderait',
    'embardèrent',
    'embarderiez',
    'embarderons',
    'embarderont',
    'embarquâmes',
    'embarquasse',
    'embarquâtes',
    'embarquerai',
    'embarqueras',
    'embarquerez',
    'embarquions',
    'embarraient',
    'embarrassai',
    'embarrassas',
    'embarrassât',
    'embarrassée',
    'embarrasser',
    'embarrasses',
    'embarrassés',
    'embarrassez',
    'embarrerais',
    'embarrerait',
    'embarrèrent',
    'embarreriez',
    'embarrerons',
    'embarreront',
    'embasements',
    'embastillai',
    'embastillas',
    'embastillât',
    'embastillée',
    'embastiller',
    'embastilles',
    'embastillés',
    'embastillez',
    'embastionna',
    'embastionne',
    'embastionné',
    'embattaient',
    'embattirent',
    'embattisses',
    'embattrions',
    'embauchages',
    'embauchâmes',
    'embauchasse',
    'embauchâtes',
    'embaucherai',
    'embaucheras',
    'embaucherez',
    'embaucheurs',
    'embaucheuse',
    'embauchions',
    'embauchoirs',
    'embaumaient',
    'embaumasses',
    'embaumement',
    'embaumerais',
    'embaumerait',
    'embaumèrent',
    'embaumeriez',
    'embaumerons',
    'embaumeront',
    'embaumeuses',
    'embecquâmes',
    'embecquasse',
    'embecquâtes',
    'embecquerai',
    'embecqueras',
    'embecquerez',
    'embecquions',
    'émbéguinées',
    'émbéguiniez',
    'émbéguinons',
    'embellirais',
    'embellirait',
    'embellirent',
    'embelliriez',
    'embellirons',
    'embelliront',
    'embellissai',
    'embellissas',
    'embellissât',
    'embellisses',
    'embellissez',
    'embêtassent',
    'embêtassiez',
    'embêtements',
    'embêterions',
    'embidonnais',
    'embidonnait',
    'embidonnant',
    'embidonnées',
    'embidonnent',
    'embidonnera',
    'embidonniez',
    'embidonnons',
    'emblavaient',
    'emblavasses',
    'emblaverais',
    'emblaverait',
    'emblavèrent',
    'emblaveriez',
    'emblaverons',
    'emblaveront',
    'embobelinai',
    'embobelinas',
    'embobelinât',
    'embobelinée',
    'embobeliner',
    'embobelines',
    'embobelinés',
    'embobelinez',
    'embobinâmes',
    'embobinasse',
    'embobinâtes',
    'embobinerai',
    'embobineras',
    'embobinerez',
    'embobinions',
    'emboîtables',
    'emboîtaient',
    'emboîtasses',
    'emboîtement',
    'emboîterais',
    'emboîterait',
    'emboîtèrent',
    'emboîteriez',
    'emboîterons',
    'emboîteront',
    'embonpoints',
    'embossaient',
    'embossasses',
    'embosserais',
    'embosserait',
    'embossèrent',
    'embosseriez',
    'embosserons',
    'embosseront',
    'embottelais',
    'embottelait',
    'embottelant',
    'embottelées',
    'embotteliez',
    'embottelles',
    'embottelons',
    'embouassent',
    'embouassiez',
    'embouchâmes',
    'embouchasse',
    'embouchâtes',
    'emboucherai',
    'emboucheras',
    'emboucherez',
    'embouchions',
    'embouchoirs',
    'embouchures',
    'embouerions',
    'embouquâmes',
    'embouquasse',
    'embouquâtes',
    'embouquerai',
    'embouqueras',
    'embouquerez',
    'embouquions',
    'embourbâmes',
    'embourbasse',
    'embourbâtes',
    'embourberai',
    'embourberas',
    'embourberez',
    'embourbions',
    'embourrâmes',
    'embourrasse',
    'embourrâtes',
    'embourrerai',
    'embourreras',
    'embourrerez',
    'embourrions',
    'embourrures',
    'embouteilla',
    'embouteille',
    'embouteillé',
    'emboutirais',
    'emboutirait',
    'emboutirent',
    'emboutiriez',
    'emboutirons',
    'emboutiront',
    'emboutisses',
    'emboutissez',
    'embraierais',
    'embraierait',
    'embraieriez',
    'embraierons',
    'embraieront',
    'embranchais',
    'embranchait',
    'embranchant',
    'embranchées',
    'embranchent',
    'embranchera',
    'embranchiez',
    'embranchons',
    'embraquâmes',
    'embraquasse',
    'embraquâtes',
    'embraquerai',
    'embraqueras',
    'embraquerez',
    'embraquions',
    'embrasaient',
    'embrasasses',
    'embrasement',
    'embraserais',
    'embraserait',
    'embrasèrent',
    'embraseriez',
    'embraserons',
    'embraseront',
    'embrassades',
    'embrassâmes',
    'embrassasse',
    'embrassâtes',
    'embrasserai',
    'embrasseras',
    'embrasserez',
    'embrasseurs',
    'embrasseuse',
    'embrassions',
    'embrayaient',
    'embrayasses',
    'embrayerais',
    'embrayerait',
    'embrayèrent',
    'embrayeriez',
    'embrayerons',
    'embrayeront',
    'embrelaient',
    'embrelasses',
    'embrèlerais',
    'embrèlerait',
    'embrelèrent',
    'embrèleriez',
    'embrèlerons',
    'embrèleront',
    'embrevaient',
    'embrevasses',
    'embrèvement',
    'embrèverais',
    'embrèverait',
    'embrevèrent',
    'embrèveriez',
    'embrèverons',
    'embrèveront',
    'embrigadais',
    'embrigadait',
    'embrigadant',
    'embrigadées',
    'embrigadent',
    'embrigadera',
    'embrigadiez',
    'embrigadons',
    'embringuais',
    'embringuait',
    'embringuant',
    'embringuées',
    'embringuent',
    'embringuera',
    'embringuiez',
    'embringuons',
    'embrocation',
    'embrochâmes',
    'embrochasse',
    'embrochâtes',
    'embrocherai',
    'embrocheras',
    'embrocherez',
    'embrochions',
    'embronchais',
    'embronchait',
    'embronchant',
    'embronchées',
    'embronchent',
    'embronchera',
    'embronchiez',
    'embronchons',
    'embrouillai',
    'embrouillas',
    'embrouillât',
    'embrouillée',
    'embrouiller',
    'embrouilles',
    'embrouillés',
    'embrouillez',
    'embrumaient',
    'embrumasses',
    'embrumerais',
    'embrumerait',
    'embrumèrent',
    'embrumeriez',
    'embrumerons',
    'embrumeront',
    'embrunirais',
    'embrunirait',
    'embrunirent',
    'embruniriez',
    'embrunirons',
    'embruniront',
    'embrunisses',
    'embrunissez',
    'embryogénie',
    'embryologie',
    'embuassions',
    'embueraient',
    'embusquâmes',
    'embusquasse',
    'embusquâtes',
    'embusquerai',
    'embusqueras',
    'embusquerez',
    'embusquions',
    'éméchassent',
    'éméchassiez',
    'émécherions',
    'émergeaient',
    'émergeasses',
    'émergerions',
    'émerillonna',
    'émerillonne',
    'émerillonné',
    'émerisaient',
    'émerisasses',
    'émeriserais',
    'émeriserait',
    'émerisèrent',
    'émeriseriez',
    'émeriserons',
    'émeriseront',
    'émerveillai',
    'émerveillas',
    'émerveillât',
    'émerveillée',
    'émerveiller',
    'émerveilles',
    'émerveillés',
    'émerveillez',
    'émettraient',
    'émiettaient',
    'émiettasses',
    'émiettement',
    'émietterais',
    'émietterait',
    'émiettèrent',
    'émietteriez',
    'émietterons',
    'émietteront',
    'émigrassent',
    'émigrassiez',
    'émigrations',
    'émigrerions',
    'éminçassent',
    'éminçassiez',
    'émincerions',
    'emmagasinai',
    'emmagasinas',
    'emmagasinât',
    'emmagasinée',
    'emmagasiner',
    'emmagasines',
    'emmagasinés',
    'emmagasinez',
    'emmaillotai',
    'emmaillotas',
    'emmaillotât',
    'emmaillotée',
    'emmailloter',
    'emmaillotes',
    'emmaillotés',
    'emmaillotez',
    'emmanchâmes',
    'emmanchasse',
    'emmanchâtes',
    'emmancherai',
    'emmancheras',
    'emmancherez',
    'emmanchions',
    'emmanchures',
    'emmargeâmes',
    'emmargeasse',
    'emmargeâtes',
    'emmargerais',
    'emmargerait',
    'emmargèrent',
    'emmargeriez',
    'emmargerons',
    'emmargeront',
    'emmêlassent',
    'emmêlassiez',
    'emmêlements',
    'emmêlerions',
    'emménageais',
    'emménageait',
    'emménageant',
    'emménageons',
    'emménagerai',
    'emménageras',
    'emménagerez',
    'emménagions',
    'emménagogue',
    'emmenassent',
    'emmenassiez',
    'emmènerions',
    'emmerdaient',
    'emmerdantes',
    'emmerdasses',
    'emmerdement',
    'emmerderais',
    'emmerderait',
    'emmerdèrent',
    'emmerderiez',
    'emmerderons',
    'emmerderont',
    'emmerdeuses',
    'emmétraient',
    'emmétrasses',
    'emmétrerais',
    'emmétrerait',
    'emmétrèrent',
    'emmétreriez',
    'emmétrerons',
    'emmétreront',
    'emmétropies',
    'emmiellâmes',
    'emmiellasse',
    'emmiellâtes',
    'emmiellerai',
    'emmielleras',
    'emmiellerez',
    'emmiellions',
    'emmitonnais',
    'emmitonnait',
    'emmitonnant',
    'emmitonnées',
    'emmitonnent',
    'emmitonnera',
    'emmitonniez',
    'emmitonnons',
    'emmitouflai',
    'emmitouflas',
    'emmitouflât',
    'emmitouflée',
    'emmitoufler',
    'emmitoufles',
    'emmitouflés',
    'emmitouflez',
    'emmortaisai',
    'emmortaisas',
    'emmortaisât',
    'emmortaisée',
    'emmortaiser',
    'emmortaises',
    'emmortaisés',
    'emmortaisez',
    'emmurassent',
    'emmurassiez',
    'emmurerions',
    'émollientes',
    'émonctoires',
    'émondassent',
    'émondassiez',
    'émonderions',
    'émorfilages',
    'émorfilâmes',
    'émorfilasse',
    'émorfilâtes',
    'émorfilerai',
    'émorfileras',
    'émorfilerez',
    'émorfilions',
    'émotionnais',
    'émotionnait',
    'émotionnant',
    'émotionnées',
    'émotionnels',
    'émotionnent',
    'émotionnera',
    'émotionniez',
    'émotionnons',
    'émottassent',
    'émottassiez',
    'émotterions',
    'émouchaient',
    'émouchasses',
    'émoucherais',
    'émoucherait',
    'émouchèrent',
    'émoucheriez',
    'émoucherons',
    'émoucheront',
    'émouchetais',
    'émouchetait',
    'émouchetant',
    'émouchetées',
    'émouchètent',
    'émouchètera',
    'émouchetiez',
    'émouchetons',
    'émouchettes',
    'émoudraient',
    'émoulussent',
    'émoulussiez',
    'émoussaient',
    'émoussasses',
    'émousserais',
    'émousserait',
    'émoussèrent',
    'émousseriez',
    'émousserons',
    'émousseront',
    'émoustillai',
    'émoustillas',
    'émoustillât',
    'émoustillée',
    'émoustiller',
    'émoustilles',
    'émoustillés',
    'émoustillez',
    'émouvraient',
    'empaillages',
    'empaillâmes',
    'empaillasse',
    'empaillâtes',
    'empailleurs',
    'empailleuse',
    'empaillions',
    'empalassent',
    'empalassiez',
    'empalements',
    'empalerions',
    'empalmaient',
    'empalmasses',
    'empalmerais',
    'empalmerait',
    'empalmèrent',
    'empalmeriez',
    'empalmerons',
    'empalmeront',
    'empanachais',
    'empanachait',
    'empanachant',
    'empanachées',
    'empanachent',
    'empanachera',
    'empanachiez',
    'empanachons',
    'empannaient',
    'empannasses',
    'empannerais',
    'empannerait',
    'empannèrent',
    'empanneriez',
    'empannerons',
    'empanneront',
    'empapillota',
    'empapillote',
    'empapilloté',
    'empaquetage',
    'empaquetais',
    'empaquetait',
    'empaquetant',
    'empaquetées',
    'empaqueteur',
    'empaquetiez',
    'empaquetons',
    'empaquettes',
    'emparassiez',
    'emparerions',
    'emparquâmes',
    'emparquasse',
    'emparquâtes',
    'emparquerai',
    'emparqueras',
    'emparquerez',
    'emparquions',
    'empâtassent',
    'empâtassiez',
    'empâtements',
    'empâterions',
    'empâtrerais',
    'empâtrerait',
    'empâtreriez',
    'empâtrerons',
    'empâtreront',
    'empattaient',
    'empattasses',
    'empattement',
    'empatterais',
    'empatterait',
    'empattèrent',
    'empatteriez',
    'empatterons',
    'empatteront',
    'empaumaient',
    'empaumasses',
    'empaumerais',
    'empaumerait',
    'empaumèrent',
    'empaumeriez',
    'empaumerons',
    'empaumeront',
    'empêchaient',
    'empêchasses',
    'empêchement',
    'empêcherais',
    'empêcherait',
    'empêchèrent',
    'empêcheriez',
    'empêcherons',
    'empêcheront',
    'empêcheuses',
    'empeignâmes',
    'empeignasse',
    'empeignâtes',
    'empeignerai',
    'empeigneras',
    'empeignerez',
    'empeignions',
    'empênassent',
    'empênassiez',
    'empênerions',
    'empennaient',
    'empennasses',
    'empennerais',
    'empennerait',
    'empennèrent',
    'empenneriez',
    'empennerons',
    'empenneront',
    'emperchâmes',
    'emperchasse',
    'emperchâtes',
    'empercherai',
    'empercheras',
    'empercherez',
    'emperchions',
    'emperlaient',
    'emperlasses',
    'emperlerais',
    'emperlerait',
    'emperlèrent',
    'emperleriez',
    'emperlerons',
    'emperleront',
    'empesassent',
    'empesassiez',
    'empèserions',
    'empestaient',
    'empestasses',
    'empesterais',
    'empesterait',
    'empestèrent',
    'empesteriez',
    'empesterons',
    'empesteront',
    'empêtraient',
    'empêtrasses',
    'empêtrerais',
    'empêtrerait',
    'empêtrèrent',
    'empêtreriez',
    'empêtrerons',
    'empêtreront',
    'emphatiques',
    'emphytéoses',
    'emphytéotes',
    'empiècement',
    'empierrâmes',
    'empierrasse',
    'empierrâtes',
    'empierrerai',
    'empierreras',
    'empierrerez',
    'empierrions',
    'empiétaient',
    'empiétasses',
    'empiétement',
    'empiètement',
    'empiéterais',
    'empiéterait',
    'empiétèrent',
    'empiéteriez',
    'empiéterons',
    'empiéteront',
    'empiffrâmes',
    'empiffrâtes',
    'empiffrerez',
    'empiffrions',
    'empilassent',
    'empilassiez',
    'empilements',
    'empilerions',
    'empirassent',
    'empirassiez',
    'empirerions',
    'emplacement',
    'emplaillera',
    'emplantures',
    'emplâtrâmes',
    'emplâtrasse',
    'emplâtrâtes',
    'emplâtrions',
    'empliraient',
    'emplissages',
    'emplissions',
    'emploierais',
    'emploierait',
    'emploieriez',
    'emploierons',
    'emploieront',
    'employables',
    'employaient',
    'employasses',
    'employèrent',
    'employeuses',
    'emplumaient',
    'emplumasses',
    'emplumerais',
    'emplumerait',
    'emplumèrent',
    'emplumeriez',
    'emplumerons',
    'emplumeront',
    'empochaient',
    'empochasses',
    'empocherais',
    'empocherait',
    'empochèrent',
    'empocheriez',
    'empocherons',
    'empocheront',
    'empoignades',
    'empoignâmes',
    'empoignante',
    'empoignants',
    'empoignasse',
    'empoignâtes',
    'empoignerai',
    'empoigneras',
    'empoignerez',
    'empoignions',
    'empointures',
    'empoisonnai',
    'empoisonnas',
    'empoisonnât',
    'empoisonnée',
    'empoisonner',
    'empoisonnes',
    'empoisonnés',
    'empoisonnez',
    'empoissâmes',
    'empoissasse',
    'empoissâtes',
    'empoisserai',
    'empoisseras',
    'empoisserez',
    'empoissions',
    'empoissonna',
    'empoissonne',
    'empoissonné',
    'emportaient',
    'emportasses',
    'emportement',
    'emporterais',
    'emporterait',
    'emportèrent',
    'emporteriez',
    'emporterons',
    'emporteront',
    'empotassent',
    'empotassiez',
    'empoterions',
    'empourprais',
    'empourprait',
    'empourprant',
    'empourprées',
    'empourprent',
    'empourprera',
    'empourpriez',
    'empourprons',
    'empoussiéra',
    'empoussiéré',
    'empoussière',
    'empreignais',
    'empreignait',
    'empreignant',
    'empreignent',
    'empreigniez',
    'empreignons',
    'empreindrai',
    'empreindras',
    'empreindrez',
    'empressâmes',
    'empressâtes',
    'empresserez',
    'empressions',
    'emprésurais',
    'emprésurait',
    'emprésurant',
    'emprésurées',
    'emprésurent',
    'emprésurera',
    'emprésuriez',
    'emprésurons',
    'emprisonnai',
    'emprisonnas',
    'emprisonnât',
    'emprisonnée',
    'emprisonner',
    'emprisonnes',
    'emprisonnés',
    'emprisonnez',
    'empruntâmes',
    'empruntasse',
    'empruntâtes',
    'emprunterai',
    'emprunteras',
    'emprunterez',
    'emprunteurs',
    'emprunteuse',
    'empruntions',
    'empuantîmes',
    'empuantirai',
    'empuantiras',
    'empuantirez',
    'empuantisse',
    'empuantîtes',
    'émulassions',
    'émuleraient',
    'émulsifiais',
    'émulsifiait',
    'émulsifiant',
    'émulsifiées',
    'émulsifient',
    'émulsifiera',
    'émulsifiiez',
    'émulsifions',
    'émulsionnai',
    'émulsionnas',
    'émulsionnât',
    'émulsionnée',
    'émulsionner',
    'émulsionnes',
    'émulsionnés',
    'émulsionnez',
    'enamourâmes',
    'énamourâmes',
    'enamourâtes',
    'énamourâtes',
    'enamourerez',
    'énamourerez',
    'enamourions',
    'énamourions',
    'énarthroses',
    'encabanages',
    'encabanâmes',
    'encabanasse',
    'encabanâtes',
    'encabanerai',
    'encabaneras',
    'encabanerez',
    'encabanions',
    'encadraient',
    'encadrasses',
    'encadrement',
    'encadrerais',
    'encadrerait',
    'encadrèrent',
    'encadreriez',
    'encadrerons',
    'encadreront',
    'encadreuses',
    'encageaient',
    'encageasses',
    'encagements',
    'encagerions',
    'encaissable',
    'encaissages',
    'encaissâmes',
    'encaissasse',
    'encaissâtes',
    'encaisserai',
    'encaisseras',
    'encaisserez',
    'encaisseurs',
    'encaissions',
    'encalminées',
    'encanaillai',
    'encanaillas',
    'encanaillât',
    'encanaillée',
    'encanailler',
    'encanailles',
    'encanaillés',
    'encanaillez',
    'encaquaient',
    'encaquasses',
    'encaquement',
    'encaquerais',
    'encaquerait',
    'encaquèrent',
    'encaqueriez',
    'encaquerons',
    'encaqueront',
    'encartaient',
    'encartasses',
    'encarterais',
    'encarterait',
    'encartèrent',
    'encarteriez',
    'encarterons',
    'encarteront',
    'encarteuses',
    'encartonnai',
    'encartonnas',
    'encartonnât',
    'encartonnée',
    'encartonner',
    'encartonnes',
    'encartonnés',
    'encartonnez',
    'encartoucha',
    'encartouche',
    'encartouché',
    'encasernais',
    'encasernait',
    'encasernant',
    'encasernées',
    'encasernent',
    'encasernera',
    'encaserniez',
    'encasernons',
    'encastelées',
    'encasteliez',
    'encastelons',
    'encastelure',
    'encastrable',
    'encastrâmes',
    'encastrasse',
    'encastrâtes',
    'encastrerai',
    'encastreras',
    'encastrerez',
    'encastrions',
    'encaustiqua',
    'encaustique',
    'encaustiqué',
    'encavassent',
    'encavassiez',
    'encavements',
    'encaverions',
    'encensaient',
    'encensasses',
    'encensement',
    'encenserais',
    'encenserait',
    'encensèrent',
    'encenseriez',
    'encenserons',
    'encenseront',
    'encenseuses',
    'encéphalite',
    'encerclâmes',
    'encerclasse',
    'encerclâtes',
    'encerclerai',
    'encercleras',
    'encerclerez',
    'encerclions',
    'enchaînâmes',
    'enchaînasse',
    'enchaînâtes',
    'enchaînerai',
    'enchaîneras',
    'enchaînerez',
    'enchaînions',
    'enchantâmes',
    'enchantasse',
    'enchantâtes',
    'enchanterai',
    'enchanteras',
    'enchanterez',
    'enchanteurs',
    'enchantions',
    'encharnâmes',
    'encharnasse',
    'encharnâtes',
    'encharnerai',
    'encharneras',
    'encharnerez',
    'encharnions',
    'enchâssâmes',
    'enchâssasse',
    'enchâssâtes',
    'enchâsserai',
    'enchâsseras',
    'enchâsserez',
    'enchâssions',
    'enchâssures',
    'enchatonnai',
    'enchatonnas',
    'enchatonnât',
    'enchatonnée',
    'enchatonner',
    'enchatonnes',
    'enchatonnés',
    'enchatonnez',
    'enchaussais',
    'enchaussait',
    'enchaussant',
    'enchaussées',
    'enchaussent',
    'enchaussera',
    'enchaussiez',
    'enchaussons',
    'enchemisage',
    'enchemisais',
    'enchemisait',
    'enchemisant',
    'enchemisées',
    'enchemisent',
    'enchemisera',
    'enchemisiez',
    'enchemisons',
    'enchérirais',
    'enchérirait',
    'enchérirent',
    'enchéririez',
    'enchérirons',
    'enchériront',
    'enchérisses',
    'enchérissez',
    'enchevaucha',
    'enchevauche',
    'enchevauché',
    'enchevêtrai',
    'enchevêtras',
    'enchevêtrât',
    'enchevêtrée',
    'enchevêtrer',
    'enchevêtres',
    'enchevêtrés',
    'enchevêtrez',
    'enchifrenai',
    'enchifrenas',
    'enchifrenât',
    'enchifrenée',
    'enchifrener',
    'enchifrenés',
    'enchifrènes',
    'enchifrenez',
    'encirassent',
    'encirassiez',
    'encirerions',
    'enclavaient',
    'enclavasses',
    'enclavement',
    'enclaverais',
    'enclaverait',
    'enclavèrent',
    'enclaveriez',
    'enclaverons',
    'enclaveront',
    'enclenchais',
    'enclenchait',
    'enclenchant',
    'enclenchées',
    'enclenchent',
    'enclenchera',
    'enclenchiez',
    'enclenchons',
    'encliquetai',
    'encliquetas',
    'encliquetât',
    'encliquetée',
    'encliqueter',
    'encliquetés',
    'encliquetez',
    'encliquette',
    'enclitiques',
    'encloîtrais',
    'encloîtrait',
    'encloîtrant',
    'encloîtrées',
    'encloîtrent',
    'encloîtrera',
    'encloîtriez',
    'encloîtrons',
    'encloraient',
    'enclouaient',
    'enclouasses',
    'enclouerais',
    'enclouerait',
    'enclouèrent',
    'encloueriez',
    'enclouerons',
    'encloueront',
    'encochaient',
    'encochasses',
    'encocherais',
    'encocherait',
    'encochèrent',
    'encocheriez',
    'encocherons',
    'encocheront',
    'encodassent',
    'encodassiez',
    'encoderions',
    'encoffrâmes',
    'encoffrasse',
    'encoffrâtes',
    'encoffrerai',
    'encoffreras',
    'encoffrerez',
    'encoffrions',
    'encoignures',
    'encollaient',
    'encollasses',
    'encollerais',
    'encollerait',
    'encollèrent',
    'encolleriez',
    'encollerons',
    'encolleront',
    'encolleuses',
    'encombrâmes',
    'encombrante',
    'encombrants',
    'encombrasse',
    'encombrâtes',
    'encombrerai',
    'encombreras',
    'encombrerez',
    'encombrions',
    'encordaient',
    'encordasses',
    'encorderais',
    'encorderait',
    'encordèrent',
    'encorderiez',
    'encorderons',
    'encorderont',
    'encornaient',
    'encornasses',
    'encornerais',
    'encornerait',
    'encornèrent',
    'encorneriez',
    'encornerons',
    'encorneront',
    'encourageai',
    'encourageas',
    'encourageât',
    'encouragées',
    'encouragent',
    'encouragera',
    'encouragiez',
    'encouraient',
    'encourrions',
    'encoururent',
    'encourusses',
    'encrassâmes',
    'encrassasse',
    'encrassâtes',
    'encrasserai',
    'encrasseras',
    'encrasserez',
    'encrassions',
    'encrêpaient',
    'encrêpasses',
    'encrêperais',
    'encrêperait',
    'encrêpèrent',
    'encrêperiez',
    'encrêperons',
    'encrêperont',
    'encreraient',
    'encroûtâmes',
    'encroûtasse',
    'encroûtâtes',
    'encroûterai',
    'encroûteras',
    'encroûterez',
    'encroûtions',
    'encuvassent',
    'encuvassiez',
    'encuverions',
    'encycliques',
    'endaubaient',
    'endaubasses',
    'endauberais',
    'endauberait',
    'endaubèrent',
    'endauberiez',
    'endauberons',
    'endauberont',
    'endentaient',
    'endentasses',
    'endenterais',
    'endenterait',
    'endentèrent',
    'endenteriez',
    'endenterons',
    'endenteront',
    'endettaient',
    'endettasses',
    'endettement',
    'endetterais',
    'endetterait',
    'endettèrent',
    'endetteriez',
    'endetterons',
    'endetteront',
    'endeuillais',
    'endeuillait',
    'endeuillant',
    'endeuillées',
    'endeuillent',
    'endeuillera',
    'endeuilliez',
    'endeuillons',
    'endiablâmes',
    'endiablasse',
    'endiablâtes',
    'endiablerai',
    'endiableras',
    'endiablerez',
    'endiablions',
    'endiguaient',
    'endiguasses',
    'endiguement',
    'endiguerais',
    'endiguerait',
    'endiguèrent',
    'endigueriez',
    'endiguerons',
    'endigueront',
    'endimanchai',
    'endimanchas',
    'endimanchât',
    'endimanchée',
    'endimancher',
    'endimanches',
    'endimanchés',
    'endimanchez',
    'endoblastes',
    'endocardite',
    'endocrinien',
    'endoctrinai',
    'endoctrinas',
    'endoctrinât',
    'endoctrinée',
    'endoctriner',
    'endoctrines',
    'endoctrinés',
    'endoctrinez',
    'endolorîmes',
    'endolorirai',
    'endoloriras',
    'endolorirez',
    'endolorisse',
    'endolorîtes',
    'endométrite',
    'endommageai',
    'endommageas',
    'endommageât',
    'endommagées',
    'endommagent',
    'endommagera',
    'endommagiez',
    'endormaient',
    'endormantes',
    'endormeuses',
    'endormirais',
    'endormirait',
    'endormirent',
    'endormiriez',
    'endormirons',
    'endormiront',
    'endormisses',
    'endorphines',
    'endoscopies',
    'endossables',
    'endossaient',
    'endossasses',
    'endossement',
    'endosserais',
    'endosserait',
    'endossèrent',
    'endosseriez',
    'endosserons',
    'endosseront',
    'endothélial',
    'endothélium',
    'endotoxines',
    'enduiraient',
    'enduisaient',
    'enduisirent',
    'enduisisses',
    'endurassent',
    'endurassiez',
    'endurcirais',
    'endurcirait',
    'endurcirent',
    'endurciriez',
    'endurcirons',
    'endurciront',
    'endurcisses',
    'endurcissez',
    'endurerions',
    'énergétique',
    'énergisante',
    'énergisants',
    'énergumènes',
    'énervassent',
    'énervassiez',
    'énervations',
    'énervements',
    'énerverions',
    'enfaîtaient',
    'enfaîtasses',
    'enfaîterais',
    'enfaîterait',
    'enfaîtèrent',
    'enfaîteriez',
    'enfaîterons',
    'enfaîteront',
    'enfantaient',
    'enfantasses',
    'enfantement',
    'enfanterais',
    'enfanterait',
    'enfantèrent',
    'enfanteriez',
    'enfanterons',
    'enfanteront',
    'enfarinâmes',
    'enfarinasse',
    'enfarinâtes',
    'enfarinerai',
    'enfarineras',
    'enfarinerez',
    'enfarinions',
    'enfermaient',
    'enfermasses',
    'enfermerais',
    'enfermerait',
    'enfermèrent',
    'enfermeriez',
    'enfermerons',
    'enfermeront',
    'enferraient',
    'enferrasses',
    'enferrerais',
    'enferrerait',
    'enferrèrent',
    'enferreriez',
    'enferrerons',
    'enferreront',
    'enfichables',
    'enfichaient',
    'enfichasses',
    'enficherais',
    'enficherait',
    'enfichèrent',
    'enficheriez',
    'enficherons',
    'enficheront',
    'enfiellâmes',
    'enfiellasse',
    'enfiellâtes',
    'enfiellerai',
    'enfielleras',
    'enfiellerez',
    'enfiellions',
    'enfiévrâmes',
    'enfiévrasse',
    'enfiévrâtes',
    'enfiévrerai',
    'enfiévreras',
    'enfiévrerez',
    'enfiévrions',
    'enfilassent',
    'enfilassiez',
    'enfilerions',
    'enflammâmes',
    'enflammasse',
    'enflammâtes',
    'enflammerai',
    'enflammeras',
    'enflammerez',
    'enflammions',
    'enflassions',
    'enfléchâmes',
    'enfléchasse',
    'enfléchâtes',
    'enflécherai',
    'enflécheras',
    'enflécherez',
    'enfléchions',
    'enfléchures',
    'enfleraient',
    'enfleurages',
    'enfleurâmes',
    'enfleurasse',
    'enfleurâtes',
    'enfleurerai',
    'enfleureras',
    'enfleurerez',
    'enfleurions',
    'enfonçaient',
    'enfonçasses',
    'enfoncement',
    'enfoncerais',
    'enfoncerait',
    'enfoncèrent',
    'enfonceriez',
    'enfoncerons',
    'enfonceront',
    'enfonceuses',
    'enforcirais',
    'enforcirait',
    'enforcirent',
    'enforciriez',
    'enforcirons',
    'enforciront',
    'enforcisses',
    'enforcissez',
    'enfouirions',
    'enfouissais',
    'enfouissait',
    'enfouissant',
    'enfouissent',
    'enfouisseur',
    'enfouissiez',
    'enfouissons',
    'enfourchais',
    'enfourchait',
    'enfourchant',
    'enfourchées',
    'enfourchent',
    'enfourchera',
    'enfourchiez',
    'enfourchons',
    'enfourchure',
    'enfournages',
    'enfournâmes',
    'enfournasse',
    'enfournâtes',
    'enfournerai',
    'enfourneras',
    'enfournerez',
    'enfournions',
    'enfreignais',
    'enfreignait',
    'enfreignant',
    'enfreignent',
    'enfreigniez',
    'enfreignons',
    'enfreindrai',
    'enfreindras',
    'enfreindrez',
    'enfuissions',
    'enfumassent',
    'enfumassiez',
    'enfumerions',
    'enfutaillai',
    'enfutaillas',
    'enfutaillât',
    'enfutaillée',
    'enfutailler',
    'enfutailles',
    'enfutaillés',
    'enfutaillez',
    'enfûtassent',
    'enfûtassiez',
    'enfûterions',
    'engageaient',
    'engageantes',
    'engageasses',
    'engagements',
    'engagerions',
    'engainaient',
    'engainasses',
    'engainerais',
    'engainerait',
    'engainèrent',
    'engaineriez',
    'engainerons',
    'engaineront',
    'engamassent',
    'engamassiez',
    'engamerions',
    'engargoussa',
    'engargousse',
    'engargoussé',
    'engavassent',
    'engavassiez',
    'engaverions',
    'engazonnais',
    'engazonnait',
    'engazonnant',
    'engazonnées',
    'engazonnent',
    'engazonnera',
    'engazonniez',
    'engazonnons',
    'engendrâmes',
    'engendrasse',
    'engendrâtes',
    'engendrerai',
    'engendreras',
    'engendrerez',
    'engendrions',
    'engerbaient',
    'engerbasses',
    'engerberais',
    'engerberait',
    'engerbèrent',
    'engerberiez',
    'engerberons',
    'engerberont',
    'engineering',
    'englaçaient',
    'englaçasses',
    'englacerais',
    'englacerait',
    'englacèrent',
    'englaceriez',
    'englacerons',
    'englaceront',
    'englobaient',
    'englobantes',
    'englobasses',
    'engloberais',
    'engloberait',
    'englobèrent',
    'engloberiez',
    'engloberons',
    'engloberont',
    'engloutîmes',
    'engloutirai',
    'engloutiras',
    'engloutirez',
    'engloutisse',
    'engloutîtes',
    'engluassent',
    'engluassiez',
    'engluements',
    'engluerions',
    'engobassent',
    'engobassiez',
    'engoberions',
    'engommaient',
    'engommasses',
    'engommerais',
    'engommerait',
    'engommèrent',
    'engommeriez',
    'engommerons',
    'engommeront',
    'engonçaient',
    'engonçasses',
    'engoncerais',
    'engoncerait',
    'engoncèrent',
    'engonceriez',
    'engoncerons',
    'engonceront',
    'engorgeâmes',
    'engorgeasse',
    'engorgeâtes',
    'engorgement',
    'engorgerais',
    'engorgerait',
    'engorgèrent',
    'engorgeriez',
    'engorgerons',
    'engorgeront',
    'engouassiez',
    'engouements',
    'engouerions',
    'engouffrais',
    'engouffrait',
    'engouffrant',
    'engouffrées',
    'engouffrent',
    'engouffrera',
    'engouffriez',
    'engouffrons',
    'engoulaient',
    'engoulasses',
    'engoulerais',
    'engoulerait',
    'engoulèrent',
    'engouleriez',
    'engoulerons',
    'engouleront',
    'engoulevent',
    'engourdîmes',
    'engourdirai',
    'engourdiras',
    'engourdirez',
    'engourdisse',
    'engourdîtes',
    'engraissage',
    'engraissais',
    'engraissait',
    'engraissant',
    'engraissées',
    'engraissent',
    'engraissera',
    'engraissiez',
    'engraissons',
    'engrangeais',
    'engrangeait',
    'engrangeant',
    'engrangeons',
    'engrangerai',
    'engrangeras',
    'engrangerez',
    'engrangions',
    'engravaient',
    'engravasses',
    'engraverais',
    'engraverait',
    'engravèrent',
    'engraveriez',
    'engraverons',
    'engraveront',
    'engrenaient',
    'engrenasses',
    'engrènerais',
    'engrènerait',
    'engrenèrent',
    'engrèneriez',
    'engrènerons',
    'engrèneront',
    'engreneuses',
    'engrossâmes',
    'engrossasse',
    'engrossâtes',
    'engrosserai',
    'engrosseras',
    'engrosserez',
    'engrossions',
    'engrumelais',
    'engrumelait',
    'engrumelant',
    'engrumelées',
    'engrumeliez',
    'engrumelles',
    'engrumelons',
    'engueulades',
    'engueulâmes',
    'engueulasse',
    'engueulâtes',
    'engueulerai',
    'engueuleras',
    'engueulerez',
    'engueulions',
    'enguichures',
    'enguirlanda',
    'enguirlande',
    'enguirlandé',
    'enhardirais',
    'enhardirait',
    'enhardirent',
    'enhardiriez',
    'enhardirons',
    'enhardiront',
    'enhardisses',
    'enhardissez',
    'enharmonies',
    'enharnachai',
    'enharnachas',
    'enharnachât',
    'enharnachée',
    'enharnacher',
    'enharnaches',
    'enharnachés',
    'enharnachez',
    'enherbaient',
    'enherbasses',
    'enherberais',
    'enherberait',
    'enherbèrent',
    'enherberiez',
    'enherberons',
    'enherberont',
    'éniellaient',
    'éniellasses',
    'éniellerais',
    'éniellerait',
    'éniellèrent',
    'énielleriez',
    'éniellerons',
    'énielleront',
    'énigmatique',
    'enivrassent',
    'enivrassiez',
    'enivrements',
    'enivrerions',
    'enjambaient',
    'enjambasses',
    'enjambement',
    'enjamberais',
    'enjamberait',
    'enjambèrent',
    'enjamberiez',
    'enjamberons',
    'enjamberont',
    'enjavelâmes',
    'enjavelasse',
    'enjavelâtes',
    'enjavelions',
    'enjavellent',
    'enjavellera',
    'enjoignîmes',
    'enjoignions',
    'enjoignisse',
    'enjoignîtes',
    'enjoindrais',
    'enjoindrait',
    'enjoindriez',
    'enjoindrons',
    'enjoindront',
    'enjôlassent',
    'enjôlassiez',
    'enjôlements',
    'enjôlerions',
    'enjolivâmes',
    'enjolivasse',
    'enjolivâtes',
    'enjoliverai',
    'enjoliveras',
    'enjoliverez',
    'enjoliveurs',
    'enjolivions',
    'enjolivures',
    'enjonçaient',
    'enjonçasses',
    'enjoncerais',
    'enjoncerait',
    'enjoncèrent',
    'enjonceriez',
    'enjoncerons',
    'enjonceront',
    'enjouassent',
    'enjouassiez',
    'enjouements',
    'enjouerions',
    'enjuguaient',
    'enjuguasses',
    'enjuguerais',
    'enjuguerait',
    'enjuguèrent',
    'enjugueriez',
    'enjuguerons',
    'enjugueront',
    'enjuivaient',
    'enjuivasses',
    'enjuiverais',
    'enjuiverait',
    'enjuivèrent',
    'enjuiveriez',
    'enjuiverons',
    'enjuiveront',
    'enjuponnais',
    'enjuponnait',
    'enjuponnant',
    'enjuponnées',
    'enjuponnent',
    'enjuponnera',
    'enjuponniez',
    'enjuponnons',
    'enkystement',
    'enkysteriez',
    'enkysterons',
    'enlaçassent',
    'enlaçassiez',
    'enlacements',
    'enlacerions',
    'enlaidirais',
    'enlaidirait',
    'enlaidirent',
    'enlaidiriez',
    'enlaidirons',
    'enlaidiront',
    'enlaidisses',
    'enlaidissez',
    'enlevassent',
    'enlevassiez',
    'enlèvements',
    'enlèverions',
    'enliassâmes',
    'enliassasse',
    'enliassâtes',
    'enliasserai',
    'enliasseras',
    'enliasserez',
    'enliassions',
    'enlieraient',
    'enlignaient',
    'enlignasses',
    'enlignerais',
    'enlignerait',
    'enlignèrent',
    'enligneriez',
    'enlignerons',
    'enligneront',
    'enlisassent',
    'enlisassiez',
    'enlisements',
    'enliserions',
    'enluminâmes',
    'enluminasse',
    'enluminâtes',
    'enluminerai',
    'enlumineras',
    'enluminerez',
    'enlumineurs',
    'enlumineuse',
    'enluminions',
    'enluminures',
    'ennéagonale',
    'ennéagonaux',
    'enneigeâmes',
    'enneigeasse',
    'enneigeâtes',
    'enneigement',
    'enneigerais',
    'enneigerait',
    'enneigèrent',
    'enneigeriez',
    'enneigerons',
    'enneigeront',
    'ennoblirais',
    'ennoblirait',
    'ennoblirent',
    'ennobliriez',
    'ennoblirons',
    'ennobliront',
    'ennoblisses',
    'ennoblissez',
    'ennuageâmes',
    'ennuageasse',
    'ennuageâtes',
    'ennuagerais',
    'ennuagerait',
    'ennuagèrent',
    'ennuageriez',
    'ennuagerons',
    'ennuageront',
    'ennuierions',
    'ennuyassent',
    'ennuyassiez',
    'énonçassent',
    'énonçassiez',
    'énoncerions',
    'énonciatifs',
    'énonciation',
    'énonciative',
    'enorgueilli',
    'énouassions',
    'énoueraient',
    'enquerrions',
    'enquêtaient',
    'enquêtasses',
    'enquêterais',
    'enquêterait',
    'enquêtèrent',
    'enquêteriez',
    'enquêterons',
    'enquêteront',
    'enquêteuses',
    'enquêtrices',
    'enquiquinai',
    'enquiquinas',
    'enquiquinât',
    'enquiquinée',
    'enquiquiner',
    'enquiquines',
    'enquiquinés',
    'enquiquinez',
    'enquissions',
    'enracinâmes',
    'enracinasse',
    'enracinâtes',
    'enracinerai',
    'enracineras',
    'enracinerez',
    'enracinions',
    'enrageaient',
    'enrageantes',
    'enrageasses',
    'enragerions',
    'enraiements',
    'enraierions',
    'enraillâmes',
    'enraillasse',
    'enraillâtes',
    'enraillerai',
    'enrailleras',
    'enraillerez',
    'enraillions',
    'enraquasses',
    'enraquèrent',
    'enrayassent',
    'enrayassiez',
    'enrayements',
    'enrayerions',
    'enrégimenta',
    'enrégimente',
    'enrégimenté',
    'enregistrai',
    'enregistras',
    'enregistrât',
    'enregistrée',
    'enregistrer',
    'enregistres',
    'enregistrés',
    'enregistrez',
    'enrênassent',
    'enrênassiez',
    'enrênerions',
    'enrhumaient',
    'enrhumasses',
    'enrhumerais',
    'enrhumerait',
    'enrhumèrent',
    'enrhumeriez',
    'enrhumerons',
    'enrhumeront',
    'enrichirais',
    'enrichirait',
    'enrichirent',
    'enrichiriez',
    'enrichirons',
    'enrichiront',
    'enrichisses',
    'enrichissez',
    'enrobassent',
    'enrobassiez',
    'enrobements',
    'enroberions',
    'enrochaient',
    'enrochasses',
    'enrochement',
    'enrocherais',
    'enrocherait',
    'enrochèrent',
    'enrocheriez',
    'enrocherons',
    'enrocheront',
    'enrôlassent',
    'enrôlassiez',
    'enrôlements',
    'enrôlerions',
    'enrouassent',
    'enrouassiez',
    'enrouements',
    'enrouerions',
    'enrouillais',
    'enrouillait',
    'enrouillant',
    'enrouillent',
    'enrouillera',
    'enrouilliez',
    'enrouillons',
    'enroulaient',
    'enroulasses',
    'enroulement',
    'enroulerais',
    'enroulerait',
    'enroulèrent',
    'enrouleriez',
    'enroulerons',
    'enrouleront',
    'enrouleuses',
    'enrubannais',
    'enrubannait',
    'enrubannant',
    'enrubannées',
    'enrubannent',
    'enrubannera',
    'enrubanniez',
    'enrubannons',
    'ensablaient',
    'ensablasses',
    'ensablement',
    'ensablerais',
    'ensablerait',
    'ensablèrent',
    'ensableriez',
    'ensablerons',
    'ensableront',
    'ensabotâmes',
    'ensabotasse',
    'ensabotâtes',
    'ensaboterai',
    'ensaboteras',
    'ensaboterez',
    'ensabotions',
    'ensachaient',
    'ensachasses',
    'ensacherais',
    'ensacherait',
    'ensachèrent',
    'ensacheriez',
    'ensacherons',
    'ensacheront',
    'ensacheuses',
    'ensaisinais',
    'ensaisinait',
    'ensaisinant',
    'ensaisinées',
    'ensaisinent',
    'ensaisinera',
    'ensaisiniez',
    'ensaisinons',
    'ensanglanta',
    'ensanglante',
    'ensanglanté',
    'ensauveriez',
    'ensauverons',
    'enseignâmes',
    'enseignante',
    'enseignants',
    'enseignasse',
    'enseignâtes',
    'enseignerai',
    'enseigneras',
    'enseignerez',
    'enseignions',
    'ensembliers',
    'ensemençais',
    'ensemençait',
    'ensemençant',
    'ensemencées',
    'ensemencent',
    'ensemencera',
    'ensemenciez',
    'ensemençons',
    'enserraient',
    'enserrasses',
    'enserrerais',
    'enserrerait',
    'enserrèrent',
    'enserreriez',
    'enserrerons',
    'enserreront',
    'ensevelîmes',
    'ensevelirai',
    'enseveliras',
    'ensevelirez',
    'ensevelisse',
    'ensevelîtes',
    'ensilassent',
    'ensilassiez',
    'ensilerions',
    'ensoleillai',
    'ensoleillas',
    'ensoleillât',
    'ensoleillée',
    'ensoleiller',
    'ensoleilles',
    'ensoleillés',
    'ensoleillez',
    'ensommeillé',
    'ensorcelais',
    'ensorcelait',
    'ensorcelant',
    'ensorcelées',
    'ensorceleur',
    'ensorceliez',
    'ensorcelles',
    'ensorcelons',
    'ensoufrâmes',
    'ensoufrasse',
    'ensoufrâtes',
    'ensoufrerai',
    'ensoufreras',
    'ensoufrerez',
    'ensoufrions',
    'enstéraient',
    'enstérasses',
    'enstérerais',
    'enstérerait',
    'enstérèrent',
    'enstéreriez',
    'enstérerons',
    'enstéreront',
    'entablaient',
    'entablasses',
    'entablement',
    'entablerais',
    'entablerait',
    'entablèrent',
    'entableriez',
    'entablerons',
    'entableront',
    'entachaient',
    'entachasses',
    'entacherais',
    'entacherait',
    'entachèrent',
    'entacheriez',
    'entacherons',
    'entacheront',
    'entaillages',
    'entaillâmes',
    'entaillasse',
    'entaillâtes',
    'entaillerai',
    'entailleras',
    'entaillerez',
    'entaillions',
    'entamassent',
    'entamassiez',
    'entamerions',
    'entaquaient',
    'entaquerais',
    'entaquerait',
    'entaqueriez',
    'entaquerons',
    'entaqueront',
    'entartrages',
    'entartrâmes',
    'entartrasse',
    'entartrâtes',
    'entartrerai',
    'entartreras',
    'entartrerez',
    'entartrions',
    'entassaient',
    'entassasses',
    'entassement',
    'entasserais',
    'entasserait',
    'entassèrent',
    'entasseriez',
    'entasserons',
    'entasseront',
    'entéléchies',
    'entendaient',
    'entendement',
    'entendirent',
    'entendisses',
    'entendrions',
    'enténébrais',
    'enténébrait',
    'enténébrant',
    'enténébrées',
    'enténèbrent',
    'enténébrera',
    'enténébriez',
    'enténébrons',
    'entéralgies',
    'entérinâmes',
    'entérinasse',
    'entérinâtes',
    'entérinerai',
    'entérineras',
    'entérinerez',
    'entérinions',
    'entérocoque',
    'enterraient',
    'enterrasses',
    'enterrement',
    'enterrerais',
    'enterrerait',
    'enterrèrent',
    'enterreriez',
    'enterrerons',
    'enterreront',
    'entêtassent',
    'entêtassiez',
    'entêtements',
    'entêterions',
    'entichement',
    'enticheriez',
    'enticherons',
    'entièrement',
    'entoilaient',
    'entoilasses',
    'entoilerais',
    'entoilerait',
    'entoilèrent',
    'entoileriez',
    'entoilerons',
    'entoileront',
    'entôlassent',
    'entôlassiez',
    'entôlerions',
    'entomologie',
    'entomophage',
    'entomophile',
    'entonnaient',
    'entonnaison',
    'entonnasses',
    'entonnement',
    'entonnerais',
    'entonnerait',
    'entonnèrent',
    'entonneriez',
    'entonnerons',
    'entonneront',
    'entortillai',
    'entortillas',
    'entortillât',
    'entortillée',
    'entortiller',
    'entortilles',
    'entortillés',
    'entortillez',
    'entouraient',
    'entourasses',
    'entourerais',
    'entourerait',
    'entourèrent',
    'entoureriez',
    'entourerons',
    'entoureront',
    'entournures',
    'entraccordé',
    'entraccusée',
    'entraccuser',
    'entraccusés',
    'entraccusez',
    'entradmirée',
    'entradmirer',
    'entradmirés',
    'entradmirez',
    'entraidâmes',
    'entraidâtes',
    'entraiderez',
    'entraidions',
    'entraînable',
    'entraînâmes',
    'entraînante',
    'entraînants',
    'entraînasse',
    'entraînâtes',
    'entraînerai',
    'entraîneras',
    'entraînerez',
    'entraîneurs',
    'entraîneuse',
    'entraînions',
    'entrassions',
    'entravaient',
    'entravasses',
    'entraverais',
    'entraverait',
    'entravèrent',
    'entraveriez',
    'entraverons',
    'entraveront',
    'entrebâilla',
    'entrebâille',
    'entrebâillé',
    'entre-bande',
    'entrebattez',
    'entrebattre',
    'entrebattue',
    'entrebattus',
    'entrechoqua',
    'entrechoque',
    'entrechoqué',
    'entrecoupai',
    'entrecoupas',
    'entrecoupât',
    'entrecoupée',
    'entrecouper',
    'entrecoupes',
    'entrecoupés',
    'entrecoupez',
    'entrecroisa',
    'entrecroise',
    'entrecroisé',
    'entrecuisse',
    'entrefaites',
    'entrefilets',
    'entre-haïes',
    'entre-jambe',
    'entrejambes',
    'entrelaçais',
    'entrelaçait',
    'entrelaçant',
    'entrelacées',
    'entrelacent',
    'entrelacera',
    'entrelaciez',
    'entrelaçons',
    'entrelardai',
    'entrelardas',
    'entrelardât',
    'entrelardée',
    'entrelarder',
    'entrelardes',
    'entrelardés',
    'entrelardez',
    'entre-ligne',
    'entre-louée',
    'entre-louer',
    'entre-loués',
    'entre-louez',
    'entre-mangé',
    'entremêlais',
    'entremêlait',
    'entremêlant',
    'entremêlées',
    'entremêlent',
    'entremêlera',
    'entremêliez',
    'entremêlons',
    'entremettes',
    'entremettez',
    'entremettra',
    'entremettre',
    'entremirent',
    'entremisses',
    'entre-nerfs',
    'entre-noeud',
    'entre-nuire',
    'entreposage',
    'entreposais',
    'entreposait',
    'entreposant',
    'entreposées',
    'entreposent',
    'entreposera',
    'entreposeur',
    'entreposiez',
    'entreposons',
    'entreprends',
    'entreprenez',
    'entreprenne',
    'entreprîmes',
    'entreprises',
    'entreprisse',
    'entreprîtes',
    'entreraient',
    'entre-rails',
    'entretaille',
    'entretaillé',
    'entre-temps',
    'entretenais',
    'entretenait',
    'entretenant',
    'entreteniez',
    'entretenons',
    'entretenues',
    'entretienne',
    'entretînmes',
    'entretinsse',
    'entretîntes',
    'entretoisai',
    'entretoisas',
    'entretoisât',
    'entretoisée',
    'entretoiser',
    'entretoises',
    'entretoisés',
    'entretoisez',
    'entre-tuées',
    'entre-tuiez',
    'entre-tuons',
    'entreverrai',
    'entreverras',
    'entreverrez',
    'entrevirent',
    'entrevisses',
    'entrevoient',
    'entrevoûtai',
    'entrevoûtas',
    'entrevoûtât',
    'entrevoûtée',
    'entrevoûter',
    'entrevoûtes',
    'entrevoûtés',
    'entrevoûtez',
    'entrevoyais',
    'entrevoyait',
    'entrevoyant',
    'entrevoyiez',
    'entrevoyons',
    'entrouverte',
    'entrouverts',
    'entrouvrais',
    'entrouvrait',
    'entrouvrant',
    'entrouvrent',
    'entrouvriez',
    'entrouvrira',
    'entrouvrons',
    'entubassent',
    'entubassiez',
    'entuberions',
    'enturbannée',
    'enturbannés',
    'énucléaient',
    'énucléasses',
    'énucléation',
    'énucléerais',
    'énucléerait',
    'énucléèrent',
    'énucléeriez',
    'énucléerons',
    'énucléeront',
    'énuméraient',
    'énumérasses',
    'énumératifs',
    'énumération',
    'énumérative',
    'énumérerais',
    'énumérerait',
    'énumérèrent',
    'énuméreriez',
    'énumérerons',
    'énuméreront',
    'envahirions',
    'envahissais',
    'envahissait',
    'envahissant',
    'envahissent',
    'envahisseur',
    'envahissiez',
    'envahissons',
    'envasassent',
    'envasassiez',
    'envasements',
    'envaserions',
    'enveloppais',
    'enveloppait',
    'enveloppant',
    'enveloppées',
    'enveloppent',
    'enveloppera',
    'enveloppiez',
    'enveloppons',
    'envenimâmes',
    'envenimasse',
    'envenimâtes',
    'envenimerai',
    'envenimeras',
    'envenimerez',
    'envenimions',
    'envergeâmes',
    'envergeasse',
    'envergeâtes',
    'envergerais',
    'envergerait',
    'envergèrent',
    'envergeriez',
    'envergerons',
    'envergeront',
    'enverguâmes',
    'enverguasse',
    'enverguâtes',
    'enverguerai',
    'envergueras',
    'enverguerez',
    'enverguions',
    'enverraient',
    'enviassions',
    'envidassent',
    'envidassiez',
    'enviderions',
    'envieillies',
    'envieillira',
    'envieraient',
    'environnais',
    'environnait',
    'environnant',
    'environnées',
    'environnent',
    'environnera',
    'environniez',
    'environnons',
    'envisageais',
    'envisageait',
    'envisageant',
    'envisageons',
    'envisagerai',
    'envisageras',
    'envisagerez',
    'envisagions',
    'envoileriez',
    'envoilerons',
    'envolassiez',
    'envolerions',
    'envoûtaient',
    'envoûtantes',
    'envoûtasses',
    'envoûtement',
    'envoûterais',
    'envoûterait',
    'envoûtèrent',
    'envoûteriez',
    'envoûterons',
    'envoûteront',
    'envoûteuses',
    'envoyassent',
    'envoyassiez',
    'enzymatique',
    'enzymologie',
    'éosinophile',
    'épaissirais',
    'épaissirait',
    'épaissirent',
    'épaissiriez',
    'épaissirons',
    'épaissiront',
    'épaississes',
    'épaississez',
    'épalassions',
    'épaleraient',
    'épampraient',
    'épamprasses',
    'épamprement',
    'épamprerais',
    'épamprerait',
    'épamprèrent',
    'épampreriez',
    'épamprerons',
    'épampreront',
    'épanchaient',
    'épanchasses',
    'épanchement',
    'épancherais',
    'épancherait',
    'épanchèrent',
    'épancheriez',
    'épancherons',
    'épancheront',
    'épandissent',
    'épandissiez',
    'épandraient',
    'épannassent',
    'épannassiez',
    'épannelâmes',
    'épannelasse',
    'épannelâtes',
    'épannelions',
    'épannellent',
    'épannellera',
    'épannerions',
    'épanouirais',
    'épanouirait',
    'épanouirent',
    'épanouiriez',
    'épanouirons',
    'épanouiront',
    'épanouisses',
    'épanouissez',
    'épargnaient',
    'épargnantes',
    'épargnasses',
    'épargnerais',
    'épargnerait',
    'épargnèrent',
    'épargneriez',
    'épargnerons',
    'épargneront',
    'éparpillais',
    'éparpillait',
    'éparpillant',
    'éparpillées',
    'éparpillent',
    'éparpillera',
    'éparpilliez',
    'éparpillons',
    'épatassions',
    'épateraient',
    'épaufraient',
    'épaufrasses',
    'épaufrerais',
    'épaufrerait',
    'épaufrèrent',
    'épaufreriez',
    'épaufrerons',
    'épaufreront',
    'épaulassent',
    'épaulassiez',
    'épaulé-jeté',
    'épaulements',
    'épaulerions',
    'épeichettes',
    'épelassions',
    'épellations',
    'épellerions',
    'épépinaient',
    'épépinasses',
    'épépinerais',
    'épépinerait',
    'épépinèrent',
    'épépineriez',
    'épépinerons',
    'épépineront',
    'éperdissiez',
    'éperonnâmes',
    'éperonnasse',
    'éperonnâtes',
    'éperonnerai',
    'éperonneras',
    'éperonnerez',
    'éperonnions',
    'épeulassent',
    'épeulassiez',
    'épeulerions',
    'épeurassent',
    'épeurassiez',
    'épeurerions',
    'éphémérides',
    'épiçassions',
    'épiceraient',
    'épicondyles',
    'épicrâniens',
    'épicurienne',
    'épicurismes',
    'épicycloïde',
    'épidémicité',
    'épidémiques',
    'épidermique',
    'épierraient',
    'épierrasses',
    'épierrement',
    'épierrerais',
    'épierrerait',
    'épierrèrent',
    'épierreriez',
    'épierrerons',
    'épierreront',
    'épigraphies',
    'épilassions',
    'épileptique',
    'épileraient',
    'épiloguâmes',
    'épiloguasse',
    'épiloguâtes',
    'épiloguerai',
    'épilogueras',
    'épiloguerât',
    'épiloguerez',
    'épiloguions',
    'épinassions',
    'épinçassent',
    'épinçassiez',
    'épincelâmes',
    'épincelasse',
    'épincelâtes',
    'épincèlerai',
    'épincèleras',
    'épincèlerez',
    'épincelions',
    'épincerions',
    'épincetâmes',
    'épincetasse',
    'épincetâtes',
    'épinceterai',
    'épinceteras',
    'épinceterez',
    'épincetions',
    'épineraient',
    'épinglaient',
    'épinglasses',
    'épinglerais',
    'épinglerait',
    'épinglèrent',
    'épingleriez',
    'épinglerons',
    'épingleront',
    'épinglettes',
    'épinglières',
    'épinochette',
    'épiscopales',
    'épisodiques',
    'épissassent',
    'épissassiez',
    'épisserions',
    'épistolaire',
    'épistoliers',
    'épithalames',
    'épithéliale',
    'épithéliaux',
    'épithéliums',
    'épizootique',
    'éploierions',
    'éployassent',
    'éployassiez',
    'épluchaient',
    'épluchasses',
    'éplucherais',
    'éplucherait',
    'épluchèrent',
    'éplucheriez',
    'éplucherons',
    'éplucheront',
    'éplucheuses',
    'épointaient',
    'épointasses',
    'épointement',
    'épointerais',
    'épointerait',
    'épointèrent',
    'épointeriez',
    'épointerons',
    'épointeront',
    'épongeaient',
    'épongeasses',
    'épongements',
    'épongerions',
    'épontillais',
    'épontillait',
    'épontillant',
    'épontillées',
    'épontillent',
    'épontillera',
    'épontilliez',
    'épontillons',
    'épouillages',
    'épouillâmes',
    'épouillasse',
    'épouillâtes',
    'épouillerai',
    'épouilleras',
    'épouillerez',
    'épouillions',
    'époumonâmes',
    'époumonasse',
    'époumonâtes',
    'époumonerai',
    'époumoneras',
    'époumonerez',
    'époumonions',
    'épousailles',
    'épousassent',
    'épousassiez',
    'épouserions',
    'époussetage',
    'époussetais',
    'époussetait',
    'époussetant',
    'époussetées',
    'époussetiez',
    'époussetons',
    'époussettes',
    'époustoufla',
    'époustoufle',
    'époustouflé',
    'époutiaient',
    'époutiasses',
    'époutierais',
    'époutierait',
    'époutièrent',
    'époutieriez',
    'époutierons',
    'époutieront',
    'époutirions',
    'époutissais',
    'époutissait',
    'époutissant',
    'époutissent',
    'époutissiez',
    'époutissons',
    'épouvantail',
    'épouvantais',
    'épouvantait',
    'épouvantant',
    'épouvantées',
    'épouvantent',
    'épouvantera',
    'épouvantiez',
    'épouvantons',
    'épreignions',
    'épreindrais',
    'épreindrait',
    'épreindriez',
    'épreindrons',
    'épreindront',
    'éprendrions',
    'éprouvaient',
    'éprouvantes',
    'éprouvasses',
    'éprouverais',
    'éprouverait',
    'éprouvèrent',
    'éprouveriez',
    'éprouverons',
    'éprouveront',
    'éprouvettes',
    'épuçassions',
    'épuceraient',
    'épuisassent',
    'épuisassiez',
    'épuisements',
    'épuiserions',
    'épurassions',
    'épuratoires',
    'épureraient',
    'équanimités',
    'équarrirais',
    'équarrirait',
    'équarrirent',
    'équarririez',
    'équarrirons',
    'équarriront',
    'équarrisses',
    'équarrissez',
    'équatoriale',
    'équatoriaux',
    'équerraient',
    'équerrasses',
    'équerrerais',
    'équerrerait',
    'équerrèrent',
    'équerreriez',
    'équerrerons',
    'équerreront',
    'équeutaient',
    'équeutasses',
    'équeuterais',
    'équeuterait',
    'équeutèrent',
    'équeuteriez',
    'équeuterons',
    'équeuteront',
    'équidistant',
    'équilatéral',
    'équilatères',
    'équilibrage',
    'équilibrais',
    'équilibrait',
    'équilibrant',
    'équilibrées',
    'équilibrent',
    'équilibrera',
    'équilibriez',
    'équilibrons',
    'équinoxiale',
    'équinoxiaux',
    'équipassent',
    'équipassiez',
    'équipements',
    'équiperions',
    'équipollent',
    'équipotents',
    'équitations',
    'équivailles',
    'équivalence',
    'équivalente',
    'équivalents',
    'équivalions',
    'équivalûmes',
    'équivalusse',
    'équivalûtes',
    'équivaudrai',
    'équivaudras',
    'équivaudrez',
    'équivoquais',
    'équivoquait',
    'équivoquant',
    'équivoquent',
    'équivoquera',
    'équivoquiez',
    'équivoquons',
    'éradication',
    'éradiquâmes',
    'éradiquasse',
    'éradiquâtes',
    'éradiquerai',
    'éradiqueras',
    'éradiquerez',
    'éradiquions',
    'éraflassent',
    'éraflassiez',
    'éraflements',
    'éraflerions',
    'éraieraient',
    'éraillaient',
    'éraillasses',
    'éraillement',
    'éraillerais',
    'éraillerait',
    'éraillèrent',
    'érailleriez',
    'éraillerons',
    'érailleront',
    'érayassions',
    'érayeraient',
    'érectilités',
    'éreintaient',
    'éreintantes',
    'éreintasses',
    'éreintement',
    'éreinterais',
    'éreinterait',
    'éreintèrent',
    'éreinteriez',
    'éreinterons',
    'éreinteront',
    'éreinteuses',
    'érémitiques',
    'ergographes',
    'ergonomique',
    'ergonomiste',
    'ergostérols',
    'ergotassent',
    'ergotassiez',
    'ergoterions',
    'érigeassent',
    'érigeassiez',
    'érigeraient',
    'érodassions',
    'éroderaient',
    'érotisaient',
    'érotisasses',
    'érotiserais',
    'érotiserait',
    'érotisèrent',
    'érotiseriez',
    'érotiserons',
    'érotiseront',
    'érotomanies',
    'erpétologie',
    'érubescence',
    'érubescente',
    'érubescents',
    'éruciformes',
    'éructassent',
    'éructassiez',
    'éructations',
    'éructerions',
    'érugineuses',
    'érythrocyte',
    'érythrosine',
    'esbaudiriez',
    'esbaudirons',
    'esbaudissez',
    'esbigneriez',
    'esbignerons',
    'esbroufâmes',
    'esbroufasse',
    'esbroufâtes',
    'esbrouferai',
    'esbrouferas',
    'esbrouferez',
    'esbroufeurs',
    'esbroufeuse',
    'esbroufions',
    'escadrilles',
    'escaladâmes',
    'escaladasse',
    'escaladâtes',
    'escaladerai',
    'escaladeras',
    'escaladerez',
    'escaladions',
    'escamotable',
    'escamotages',
    'escamotâmes',
    'escamotasse',
    'escamotâtes',
    'escamoterai',
    'escamoteras',
    'escamoterez',
    'escamoteurs',
    'escamoteuse',
    'escamotions',
    'escampettes',
    'escarbilles',
    'escarboucle',
    'escarcelles',
    'escarmoucha',
    'escarmouche',
    'escarmouché',
    'escarpement',
    'escarrifiai',
    'escarrifias',
    'escarrifiât',
    'escarrifiée',
    'escarrifier',
    'escarrifies',
    'escarrifiés',
    'escarrifiez',
    'eschassions',
    'escheraient',
    'esclaffâmes',
    'esclaffâtes',
    'esclafferez',
    'esclaffions',
    'esclavageai',
    'esclavageas',
    'esclavageât',
    'esclavagent',
    'esclavagera',
    'esclavagiez',
    'esclavonnes',
    'escogriffes',
    'escomptable',
    'escomptâmes',
    'escomptasse',
    'escomptâtes',
    'escompterai',
    'escompteras',
    'escompterez',
    'escompteurs',
    'escomptions',
    'escortaient',
    'escortasses',
    'escorterais',
    'escorterait',
    'escortèrent',
    'escorteriez',
    'escorterons',
    'escorteront',
    'escourgeons',
    'escrimeriez',
    'escrimerons',
    'escrimeuses',
    'escroquâmes',
    'escroquasse',
    'escroquâtes',
    'escroquerai',
    'escroqueras',
    'escroquerez',
    'escroquerie',
    'escroquions',
    'ésotériques',
    'ésotérismes',
    'espaçassent',
    'espaçassiez',
    'espacements',
    'espacerions',
    'espadrilles',
    'esparcettes',
    'espérassent',
    'espérassiez',
    'espérerions',
    'espièglerie',
    'espionnages',
    'espionnâmes',
    'espionnasse',
    'espionnâtes',
    'espionnerai',
    'espionneras',
    'espionnerez',
    'espionnions',
    'espionnites',
    'espolinâmes',
    'espolinasse',
    'espolinâtes',
    'espolinerai',
    'espolineras',
    'espolinerez',
    'espolinions',
    'espoulinais',
    'espoulinait',
    'espoulinant',
    'espoulinées',
    'espoulinent',
    'espoulinera',
    'espouliniez',
    'espoulinons',
    'esquimaudes',
    'Esquimaudes',
    'esquintâmes',
    'esquintante',
    'esquintants',
    'esquintasse',
    'esquintâtes',
    'esquinterai',
    'esquinteras',
    'esquinterez',
    'esquintions',
    'esquissâmes',
    'esquissasse',
    'esquissâtes',
    'esquisserai',
    'esquisseras',
    'esquisserez',
    'esquissions',
    'esquivaient',
    'esquivasses',
    'esquiverais',
    'esquiverait',
    'esquivèrent',
    'esquiveriez',
    'esquiverons',
    'esquiveront',
    'essaierions',
    'essaimaient',
    'essaimasses',
    'essaimerais',
    'essaimerait',
    'essaimèrent',
    'essaimeriez',
    'essaimerons',
    'essaimeront',
    'essangeages',
    'essangeâmes',
    'essangeasse',
    'essangeâtes',
    'essangerais',
    'essangerait',
    'essangèrent',
    'essangeriez',
    'essangerons',
    'essangeront',
    'essartaient',
    'essartasses',
    'essartement',
    'essarterais',
    'essarterait',
    'essartèrent',
    'essarteriez',
    'essarterons',
    'essarteront',
    'essayassent',
    'essayassiez',
    'essayerions',
    'essentielle',
    'essorassent',
    'essorassiez',
    'essorerions',
    'essorillais',
    'essorillait',
    'essorillant',
    'essorillées',
    'essorillent',
    'essorillera',
    'essorilliez',
    'essorillons',
    'essouchâmes',
    'essouchasse',
    'essouchâtes',
    'essoucherai',
    'essoucheras',
    'essoucherez',
    'essouchions',
    'essoufflais',
    'essoufflait',
    'essoufflant',
    'essoufflées',
    'essoufflent',
    'essoufflera',
    'essouffliez',
    'essoufflons',
    'essuie-main',
    'essuierions',
    'essuyassent',
    'essuyassiez',
    'estafilades',
    'estampaient',
    'estampasses',
    'estamperais',
    'estamperait',
    'estampèrent',
    'estamperiez',
    'estamperons',
    'estamperont',
    'estampillai',
    'estampillas',
    'estampillât',
    'estampillée',
    'estampiller',
    'estampilles',
    'estampillés',
    'estampillez',
    'estérifiais',
    'estérifiait',
    'estérifiant',
    'estérifiées',
    'estérifient',
    'estérifiera',
    'estérifiiez',
    'estérifions',
    'esthéticien',
    'esthétiques',
    'esthétisais',
    'esthétisait',
    'esthétisant',
    'esthétisées',
    'esthétisent',
    'esthétisera',
    'esthétisiez',
    'esthétismes',
    'esthétisons',
    'estimassent',
    'estimassiez',
    'estimations',
    'estimatives',
    'estimatoire',
    'estimerions',
    'estivassent',
    'estivassiez',
    'estivations',
    'estiverions',
    'estomaquais',
    'estomaquait',
    'estomaquant',
    'estomaquées',
    'estomaquent',
    'estomaquera',
    'estomaquiez',
    'estomaquons',
    'estompaient',
    'estompasses',
    'estomperais',
    'estomperait',
    'estompèrent',
    'estomperiez',
    'estomperons',
    'estomperont',
    'estoniennes',
    'estoquaient',
    'estoquasses',
    'estoquerais',
    'estoquerait',
    'estoquèrent',
    'estoqueriez',
    'estoquerons',
    'estoqueront',
    'estouffades',
    'estourbîmes',
    'estourbirai',
    'estourbiras',
    'estourbirez',
    'estourbisse',
    'estourbîtes',
    'estrapadais',
    'estrapadait',
    'estrapadant',
    'estrapadent',
    'estrapadera',
    'estrapadiez',
    'estrapadons',
    'estrapassai',
    'estrapassas',
    'estrapassât',
    'estrapassée',
    'estrapasser',
    'estrapasses',
    'estrapassés',
    'estrapassez',
    'estropiâmes',
    'estropiasse',
    'estropiâtes',
    'estropierai',
    'estropieras',
    'estropierez',
    'estropiions',
    'estuarienne',
    'estudiantin',
    'établassent',
    'établassiez',
    'établerions',
    'établirions',
    'établissais',
    'établissait',
    'établissant',
    'établissent',
    'établissiez',
    'établissons',
    'étageassent',
    'étageassiez',
    'étageraient',
    'étaient-ils',
    'étaieraient',
    'étalageâmes',
    'étalageasse',
    'étalageâtes',
    'étalagerais',
    'étalagerait',
    'étalagèrent',
    'étalageriez',
    'étalagerons',
    'étalageront',
    'étalagistes',
    'étalassions',
    'étaleraient',
    'étalinguais',
    'étalinguait',
    'étalinguant',
    'étalinguées',
    'étalinguent',
    'étalinguera',
    'étalinguiez',
    'étalinguons',
    'étalingures',
    'étalisaient',
    'étalisasses',
    'étaliserais',
    'étaliserait',
    'étalisèrent',
    'étaliseriez',
    'étaliserons',
    'étaliseront',
    'étalonnages',
    'étalonnâmes',
    'étalonnasse',
    'étalonnâtes',
    'étalonnerai',
    'étalonneras',
    'étalonnerez',
    'étalonnions',
    'étamassions',
    'étameraient',
    'étampassent',
    'étampassiez',
    'étamperches',
    'étamperions',
    'étanchaient',
    'étanchasses',
    'étanchéités',
    'étanchement',
    'étancherais',
    'étancherait',
    'étanchèrent',
    'étancheriez',
    'étancherons',
    'étancheront',
    'étançonnais',
    'étançonnait',
    'étançonnant',
    'étançonnées',
    'étançonnent',
    'étançonnera',
    'étançonniez',
    'étançonnons',
    'étarquaient',
    'étarquasses',
    'étarquerais',
    'étarquerait',
    'étarquèrent',
    'étarqueriez',
    'étarquerons',
    'étarqueront',
    'étasunienne',
    'étatisation',
    'étayassions',
    'étayeraient',
    'éteignaient',
    'éteignirent',
    'éteignisses',
    'éteindrions',
    'étendissent',
    'étendissiez',
    'étendraient',
    'éternisâmes',
    'éternisasse',
    'éternisâtes',
    'éterniserai',
    'éterniseras',
    'éterniserez',
    'éternisions',
    'éternuaient',
    'éternuasses',
    'éternuement',
    'éternuerais',
    'éternuerait',
    'éternuèrent',
    'éternueriez',
    'éternuerons',
    'éternueront',
    'étêtassions',
    'étêteraient',
    'éthérifiais',
    'éthérifiait',
    'éthérifiant',
    'éthérifiées',
    'éthérifient',
    'éthérifiera',
    'éthérifiiez',
    'éthérifions',
    'éthérisâmes',
    'éthérisasse',
    'éthérisâtes',
    'éthériserai',
    'éthériseras',
    'éthériserez',
    'éthérisions',
    'éthéromanes',
    'éthéromanie',
    'éthiopienne',
    'ethnographe',
    'ethnologies',
    'ethnologues',
    'éthographie',
    'éthologique',
    'éthylénique',
    'étincelages',
    'étincelâmes',
    'étincelante',
    'étincelants',
    'étincelasse',
    'étincelâtes',
    'étincelions',
    'étincellent',
    'étincellera',
    'étiolassent',
    'étiolassiez',
    'étiolements',
    'étiolerions',
    'étiologique',
    'étions-nous',
    'étiquetages',
    'étiquetâmes',
    'étiquetasse',
    'étiquetâtes',
    'étiqueteurs',
    'étiqueteuse',
    'étiquetions',
    'étiquettent',
    'étiquettera',
    'étirassions',
    'étireraient',
    'étoffassent',
    'étoffassiez',
    'étofferions',
    'étoilassent',
    'étoilassiez',
    'étoilements',
    'étoilerions',
    'étonnamment',
    'étonnassent',
    'étonnassiez',
    'étonnements',
    'étonnerions',
    'étouffaient',
    'étouffantes',
    'étouffasses',
    'étouffement',
    'étoufferais',
    'étoufferait',
    'étouffèrent',
    'étoufferiez',
    'étoufferons',
    'étoufferont',
    'étoupassent',
    'étoupassiez',
    'étouperions',
    'étoupillais',
    'étoupillait',
    'étoupillant',
    'étoupillées',
    'étoupillent',
    'étoupillera',
    'étoupilliez',
    'étoupillons',
    'étourderies',
    'étourdiment',
    'étourdirais',
    'étourdirait',
    'étourdirent',
    'étourdiriez',
    'étourdirons',
    'étourdiront',
    'étourdisses',
    'étourdissez',
    'étrangement',
    'étranglâmes',
    'étranglasse',
    'étranglâtes',
    'étranglerai',
    'étrangleras',
    'étranglerez',
    'étrangleurs',
    'étrangleuse',
    'étranglions',
    'étrangloirs',
    'étrécirions',
    'étrécissais',
    'étrécissait',
    'étrécissant',
    'étrécissent',
    'étrécissiez',
    'étrécissons',
    'étreignîmes',
    'étreignions',
    'étreignisse',
    'étreignîtes',
    'étreindrais',
    'étreindrait',
    'étreindriez',
    'étreindrons',
    'étreindront',
    'étrennaient',
    'étrennasses',
    'étrennerais',
    'étrennerait',
    'étrennèrent',
    'étrenneriez',
    'étrennerons',
    'étrenneront',
    'étrésillons',
    'étrillaient',
    'étrillasses',
    'étrillerais',
    'étrillerait',
    'étrillèrent',
    'étrilleriez',
    'étrillerons',
    'étrilleront',
    'étripassent',
    'étripassiez',
    'étriperions',
    'étriquaient',
    'étriquasses',
    'étriquerais',
    'étriquerait',
    'étriquèrent',
    'étriqueriez',
    'étriquerons',
    'étriqueront',
    'étroitement',
    'étroitesses',
    'étronçonnai',
    'étronçonnas',
    'étronçonnât',
    'étronçonnée',
    'étronçonner',
    'étronçonnes',
    'étronçonnés',
    'étronçonnez',
    'étudiassent',
    'étudiassiez',
    'étudierions',
    'étuvassions',
    'étuveraient',
    'étymologies',
    'eucalyptols',
    'eucharistie',
    'euclidienne',
    'eudémonisme',
    'eudiomètres',
    'eudiométrie',
    'eupeptiques',
    'euphémiques',
    'euphémismes',
    'euphoniques',
    'euphoriques',
    'euphorisais',
    'euphorisait',
    'euphorisant',
    'euphorisées',
    'euphorisent',
    'euphorisera',
    'euphorisiez',
    'euphorisons',
    'eurafricain',
    'eurasiennes',
    'euristiques',
    'eurodevises',
    'eurodollars',
    'euromarchés',
    'euromissile',
    'européanisa',
    'européanise',
    'européanisé',
    'européennes',
    'européismes',
    'eurovisions',
    'eurythmique',
    'euscarienne',
    'euskarienne',
    'eussent-ils',
    'eustatiques',
    'eustatismes',
    'eutectiques',
    'euthanasies',
    'évacuassent',
    'évacuassiez',
    'évacuateurs',
    'évacuations',
    'évacuatrice',
    'évacuerions',
    'évadassions',
    'évagination',
    'évaluassent',
    'évaluassiez',
    'évaluations',
    'évaluerions',
    'évanescence',
    'évanescente',
    'évanescents',
    'évangélique',
    'évangélisai',
    'évangélisas',
    'évangélisât',
    'évangélisée',
    'évangéliser',
    'évangélises',
    'évangélisés',
    'évangélisez',
    'évangélisme',
    'évangéliste',
    'évanouiriez',
    'évanouirons',
    'évanouissez',
    'évaporables',
    'évaporaient',
    'évaporasses',
    'évaporateur',
    'évaporation',
    'évaporerais',
    'évaporerait',
    'évaporèrent',
    'évaporeriez',
    'évaporerons',
    'évaporeront',
    'évasassions',
    'évaseraient',
    'évasivement',
    'éveillaient',
    'éveillasses',
    'éveillerais',
    'éveillerait',
    'éveillèrent',
    'éveilleriez',
    'éveillerons',
    'éveilleront',
    'éventassent',
    'éventassiez',
    'éventerions',
    'éventraient',
    'éventrasses',
    'éventration',
    'éventrerais',
    'éventrerait',
    'éventrèrent',
    'éventreriez',
    'éventrerons',
    'éventreront',
    'éventualité',
    'éventuelles',
    'évertueriez',
    'évertuerons',
    'évidassions',
    'évideraient',
    'évinçassent',
    'évinçassiez',
    'évincements',
    'évincerions',
    'éviscérâmes',
    'éviscérasse',
    'éviscérâtes',
    'éviscérerai',
    'éviscéreras',
    'éviscérerez',
    'éviscérions',
    'évitassions',
    'éviteraient',
    'évocatoires',
    'évocatrices',
    'évoluassent',
    'évoluassiez',
    'évoluerions',
    'évoquassent',
    'évoquassiez',
    'évoquerions',
    'exacerbâmes',
    'exacerbasse',
    'exacerbâtes',
    'exacerberai',
    'exacerberas',
    'exacerberez',
    'exacerbions',
    'exactitudes',
    'exagéraient',
    'exagérasses',
    'exagérateur',
    'exagération',
    'exagérément',
    'exagérerais',
    'exagérerait',
    'exagérèrent',
    'exagéreriez',
    'exagérerons',
    'exagéreront',
    'exaltassent',
    'exaltassiez',
    'exaltations',
    'exalterions',
    'examinaient',
    'examinasses',
    'examinateur',
    'examinerais',
    'examinerait',
    'examinèrent',
    'examineriez',
    'examinerons',
    'examineront',
    'exaspérâmes',
    'exaspérante',
    'exaspérants',
    'exaspérasse',
    'exaspérâtes',
    'exaspérerai',
    'exaspéreras',
    'exaspérerez',
    'exaspérions',
    'exauçassent',
    'exauçassiez',
    'exaucements',
    'exaucerions',
    'excavassent',
    'excavassiez',
    'excavateurs',
    'excavations',
    'excavatrice',
    'excaverions',
    'excédassent',
    'excédassiez',
    'excéderions',
    'excellaient',
    'excellasses',
    'excellences',
    'excellentes',
    'excellerais',
    'excellerait',
    'excellèrent',
    'excelleriez',
    'excellerons',
    'excelleront',
    'excentrâmes',
    'excentrasse',
    'excentrâtes',
    'excentrerai',
    'excentreras',
    'excentrerez',
    'excentrions',
    'excentrique',
    'exceptaient',
    'exceptasses',
    'excepterais',
    'excepterait',
    'exceptèrent',
    'excepteriez',
    'excepterons',
    'excepteront',
    'excipassent',
    'excipassiez',
    'exciperions',
    'excisassent',
    'excisassiez',
    'exciserions',
    'excitassent',
    'excitassiez',
    'excitateurs',
    'excitations',
    'excitatrice',
    'exciterions',
    'exclamatifs',
    'exclamation',
    'exclamative',
    'exclameriez',
    'exclamerons',
    'excluraient',
    'exclusivité',
    'exclussions',
    'excommuniai',
    'excommunias',
    'excommuniât',
    'excommuniée',
    'excommunier',
    'excommunies',
    'excommuniés',
    'excommuniez',
    'excoriaient',
    'excoriasses',
    'excoriation',
    'excorierais',
    'excorierait',
    'excorièrent',
    'excorieriez',
    'excorierons',
    'excorieront',
    'excrétaient',
    'excrétasses',
    'excréterais',
    'excréterait',
    'excrétèrent',
    'excréteriez',
    'excréterons',
    'excréteront',
    'excrétoires',
    'excrétrices',
    'excursionna',
    'excursionne',
    'excursionné',
    'excusassent',
    'excusassiez',
    'excuserions',
    'exécrassent',
    'exécrassiez',
    'exécrations',
    'exécrerions',
    'exécutables',
    'exécutaient',
    'exécutantes',
    'exécutasses',
    'exécuterais',
    'exécuterait',
    'exécutèrent',
    'exécuteriez',
    'exécuterons',
    'exécuteront',
    'exécutoires',
    'exécutrices',
    'exégétiques',
    'exemplaires',
    'exemplarité',
    'exemplifiai',
    'exemplifias',
    'exemplifiât',
    'exemplifiée',
    'exemplifier',
    'exemplifies',
    'exemplifiés',
    'exemplifiez',
    'exemptaient',
    'exemptasses',
    'exempterais',
    'exempterait',
    'exemptèrent',
    'exempteriez',
    'exempterons',
    'exempteront',
    'exerçassent',
    'exerçassiez',
    'exercerions',
    'exerciseurs',
    'exfoliaient',
    'exfoliantes',
    'exfoliasses',
    'exfoliation',
    'exfolierais',
    'exfolierait',
    'exfolièrent',
    'exfolieriez',
    'exfolierons',
    'exfolieront',
    'exhalaisons',
    'exhalassent',
    'exhalassiez',
    'exhalations',
    'exhalerions',
    'exhaussâmes',
    'exhaussasse',
    'exhaussâtes',
    'exhausserai',
    'exhausseras',
    'exhausserez',
    'exhaussions',
    'exhausteurs',
    'exhaustives',
    'exhérédâmes',
    'exhérédasse',
    'exhérédâtes',
    'exhéréderai',
    'exhéréderas',
    'exhéréderez',
    'exhérédions',
    'exhibassent',
    'exhibassiez',
    'exhiberions',
    'exhibitions',
    'exhortaient',
    'exhortasses',
    'exhortation',
    'exhorterais',
    'exhorterait',
    'exhortèrent',
    'exhorteriez',
    'exhorterons',
    'exhorteront',
    'exhumassent',
    'exhumassiez',
    'exhumations',
    'exhumerions',
    'exigeassent',
    'exigeassiez',
    'exigeraient',
    'exigibilité',
    'exilassions',
    'exileraient',
    'existassent',
    'existassiez',
    'existentiel',
    'existerions',
    'exondassiez',
    'exonderions',
    'exonéraient',
    'exonérasses',
    'exonération',
    'exonérerais',
    'exonérerait',
    'exonérèrent',
    'exonéreriez',
    'exonérerons',
    'exonéreront',
    'exophtalmie',
    'exorbitante',
    'exorbitants',
    'exorcisâmes',
    'exorcisasse',
    'exorcisâtes',
    'exorciserai',
    'exorciseras',
    'exorciserez',
    'exorcisions',
    'exotériques',
    'expansibles',
    'expansivité',
    'expatriâmes',
    'expatriasse',
    'expatriâtes',
    'expatrierai',
    'expatrieras',
    'expatrierez',
    'expatriions',
    'expectative',
    'expectorais',
    'expectorait',
    'expectorant',
    'expectorées',
    'expectorent',
    'expectorera',
    'expectoriez',
    'expectorons',
    'expédiaient',
    'expédiasses',
    'expédierais',
    'expédierait',
    'expédièrent',
    'expédieriez',
    'expédierons',
    'expédieront',
    'expéditeurs',
    'expéditions',
    'expéditives',
    'expéditrice',
    'expériences',
    'expérimenta',
    'expérimente',
    'expérimenté',
    'expertement',
    'expertisais',
    'expertisait',
    'expertisant',
    'expertisées',
    'expertisent',
    'expertisera',
    'expertisiez',
    'expertisons',
    'expiassions',
    'expiatoires',
    'expieraient',
    'expirassent',
    'expirassiez',
    'expirations',
    'expirerions',
    'explicables',
    'explicatifs',
    'explication',
    'explicative',
    'explicitais',
    'explicitait',
    'explicitant',
    'explicitées',
    'explicitent',
    'explicitera',
    'explicitiez',
    'explicitons',
    'expliquâmes',
    'expliquasse',
    'expliquâtes',
    'expliquerai',
    'expliqueras',
    'expliquerez',
    'expliquions',
    'exploitable',
    'exploitâmes',
    'exploitante',
    'exploitants',
    'exploitasse',
    'exploitâtes',
    'exploiterai',
    'exploiteras',
    'exploiterez',
    'exploiteurs',
    'exploiteuse',
    'exploitions',
    'exploraient',
    'explorasses',
    'explorateur',
    'exploration',
    'explorerais',
    'explorerait',
    'explorèrent',
    'exploreriez',
    'explorerons',
    'exploreront',
    'explosaient',
    'explosasses',
    'exploserais',
    'exploserait',
    'explosèrent',
    'exploseriez',
    'exploserons',
    'exploseront',
    'explosibles',
    'exponentiel',
    'exportables',
    'exportaient',
    'exportasses',
    'exportateur',
    'exportation',
    'exporterais',
    'exporterait',
    'exportèrent',
    'exporteriez',
    'exporterons',
    'exporteront',
    'exposassent',
    'exposassiez',
    'exposerions',
    'expositions',
    'expressions',
    'expressives',
    'exprimables',
    'exprimaient',
    'exprimasses',
    'exprimerais',
    'exprimerait',
    'exprimèrent',
    'exprimeriez',
    'exprimerons',
    'exprimeront',
    'expropriais',
    'expropriait',
    'expropriant',
    'expropriées',
    'exproprient',
    'expropriera',
    'expropriiez',
    'exproprions',
    'expulsaient',
    'expulsasses',
    'expulserais',
    'expulserait',
    'expulsèrent',
    'expulseriez',
    'expulserons',
    'expulseront',
    'expurgation',
    'expurgeâmes',
    'expurgeasse',
    'expurgeâtes',
    'expurgerais',
    'expurgerait',
    'expurgèrent',
    'expurgeriez',
    'expurgerons',
    'expurgeront',
    'exquisément',
    'exstrophies',
    'exsudassent',
    'exsudassiez',
    'exsudations',
    'exsuderions',
    'extasieriez',
    'extasierons',
    'extemporané',
    'extensibles',
    'exténuaient',
    'exténuantes',
    'exténuasses',
    'exténuation',
    'exténuerais',
    'exténuerait',
    'exténuèrent',
    'exténueriez',
    'exténuerons',
    'exténueront',
    'extérieures',
    'extériorisa',
    'extériorise',
    'extériorisé',
    'extériorité',
    'exterminais',
    'exterminait',
    'exterminant',
    'exterminées',
    'exterminent',
    'exterminera',
    'exterminiez',
    'exterminons',
    'extincteurs',
    'extinctions',
    'extinctrice',
    'extinguible',
    'extirpables',
    'extirpaient',
    'extirpasses',
    'extirpateur',
    'extirpation',
    'extirperais',
    'extirperait',
    'extirpèrent',
    'extirperiez',
    'extirperons',
    'extirperont',
    'extorquâmes',
    'extorquasse',
    'extorquâtes',
    'extorquerai',
    'extorqueras',
    'extorquerez',
    'extorqueurs',
    'extorqueuse',
    'extorquions',
    'extracteurs',
    'extractible',
    'extractions',
    'extractives',
    'extradaient',
    'extradasses',
    'extraderais',
    'extraderait',
    'extradèrent',
    'extraderiez',
    'extraderons',
    'extraderont',
    'extradition',
    'extra-fines',
    'extra-forts',
    'extrairions',
    'extra-légal',
    'extralucide',
    'extra-muros',
    'extranéités',
    'extrapolais',
    'extrapolait',
    'extrapolant',
    'extrapolées',
    'extrapolent',
    'extrapolera',
    'extrapoliez',
    'extrapolons',
    'extravagant',
    'extravaguai',
    'extravaguas',
    'extravaguât',
    'extravaguer',
    'extravagues',
    'extravaguez',
    'extravasais',
    'extravasait',
    'extravasant',
    'extravasées',
    'extravasent',
    'extravasera',
    'extravasiez',
    'extravasons',
    'extravertie',
    'extravertis',
    'extrayaient',
    'extrêmement',
    'extrémismes',
    'extrémistes',
    'extrinsèque',
    'extrudaient',
    'extrudasses',
    'extruderais',
    'extruderait',
    'extrudèrent',
    'extruderiez',
    'extruderons',
    'extruderont',
    'exubérances',
    'exubérantes',
    'exulcérâmes',
    'exulcérasse',
    'exulcérâtes',
    'exulcérerai',
    'exulcéreras',
    'exulcérerez',
    'exulcérions',
    'exultassent',
    'exultassiez',
    'exultations',
    'exulterions',
    'fabricantes',
    'fabricateur',
    'fabrication',
    'fabriquâmes',
    'fabriquante',
    'fabriquants',
    'fabriquasse',
    'fabriquâtes',
    'fabriquerai',
    'fabriqueras',
    'fabriquerez',
    'fabriquions',
    'fabulassent',
    'fabulassiez',
    'fabulateurs',
    'fabulations',
    'fabulatrice',
    'fabulerions',
    'face-à-face',
    'face-à-main',
    'facétieuses',
    'facettaient',
    'facettasses',
    'facetterais',
    'facetterait',
    'facettèrent',
    'facetteriez',
    'facetterons',
    'facetteront',
    'fâchassions',
    'fâcheraient',
    'facilitâmes',
    'facilitasse',
    'facilitâtes',
    'faciliterai',
    'faciliteras',
    'faciliterez',
    'facilitions',
    'façonnaient',
    'façonnasses',
    'façonnement',
    'façonnerais',
    'façonnerait',
    'façonnèrent',
    'façonneriez',
    'façonnerons',
    'façonneront',
    'façonnières',
    'fac-similés',
    'facticement',
    'factoreries',
    'factorielle',
    'factorisais',
    'factorisait',
    'factorisant',
    'factorisées',
    'factorisent',
    'factorisera',
    'factorisiez',
    'factorisons',
    'facturaient',
    'facturasses',
    'facturation',
    'facturerais',
    'facturerait',
    'facturèrent',
    'factureriez',
    'facturerons',
    'factureront',
    'facturières',
    'facultatifs',
    'facultative',
    'fadasseries',
    'fagotassent',
    'fagotassiez',
    'fagoterions',
    'faiblirions',
    'faiblissais',
    'faiblissait',
    'faiblissant',
    'faiblissent',
    'faiblissiez',
    'faiblissons',
    'faïenceries',
    'faïencières',
    'faignantais',
    'faignantait',
    'faignantant',
    'faignantent',
    'faignantera',
    'faignantiez',
    'faignantons',
    'faillassent',
    'faillassiez',
    'faillerions',
    'faillirions',
    'faillissent',
    'faillissiez',
    'fainéantais',
    'fainéantait',
    'fainéantant',
    'fainéantent',
    'fainéantera',
    'fainéantiez',
    'fainéantise',
    'fainéantons',
    'faisabilité',
    'faisandages',
    'faisandâmes',
    'faisandasse',
    'faisandâtes',
    'faisandeaux',
    'faisanderai',
    'faisanderas',
    'faisanderez',
    'faisanderie',
    'faisandions',
    'faldistoire',
    'fallacieuse',
    'falsifiable',
    'falsifiâmes',
    'falsifiasse',
    'falsifiâtes',
    'falsifierai',
    'falsifieras',
    'falsifierez',
    'falsifiions',
    'fameusement',
    'familiarisa',
    'familiarise',
    'familiarisé',
    'familiarité',
    'familistère',
    'fanatisâmes',
    'fanatisante',
    'fanatisants',
    'fanatisasse',
    'fanatisâtes',
    'fanatiserai',
    'fanatiseras',
    'fanatiserez',
    'fanatisions',
    'fanfaronnai',
    'fanfaronnas',
    'fanfaronnât',
    'fanfaronner',
    'fanfaronnes',
    'fanfaronnez',
    'fanfrelucha',
    'fanfreluche',
    'fanfreluché',
    'fantaisiste',
    'fantasmâmes',
    'fantasmasse',
    'fantasmâtes',
    'fantasmerai',
    'fantasmeras',
    'fantasmerez',
    'fantasmions',
    'fantastique',
    'faramineuse',
    'farciraient',
    'farcissions',
    'fardassions',
    'farderaient',
    'farfouillai',
    'farfouillas',
    'farfouillât',
    'farfouillée',
    'farfouiller',
    'farfouilles',
    'farfouillés',
    'farfouillez',
    'farinassent',
    'farinassiez',
    'farinerions',
    'fartassions',
    'farteraient',
    'fasciations',
    'fasciculées',
    'fascinaient',
    'fascinantes',
    'fascinasses',
    'fascinateur',
    'fascination',
    'fascinerais',
    'fascinerait',
    'fascinèrent',
    'fascineriez',
    'fascinerons',
    'fascineront',
    'fascisaient',
    'fascisantes',
    'fascisasses',
    'fascisation',
    'fasciserais',
    'fasciserait',
    'fascisèrent',
    'fasciseriez',
    'fasciserons',
    'fasciseront',
    'faseillâmes',
    'faseillasse',
    'faseillâtes',
    'faseillerai',
    'faseilleras',
    'faseillerez',
    'faseillions',
    'fastidieuse',
    'fatiguaient',
    'fatiguantes',
    'fatiguasses',
    'fatiguerais',
    'fatiguerait',
    'fatiguèrent',
    'fatigueriez',
    'fatiguerons',
    'fatigueront',
    'faubouriens',
    'faucardâmes',
    'faucardasse',
    'faucardâtes',
    'faucarderai',
    'faucarderas',
    'faucarderez',
    'faucardeurs',
    'faucardions',
    'fauchaisons',
    'fauchassent',
    'fauchassiez',
    'faucherions',
    'fauconnâmes',
    'fauconnasse',
    'fauconnâtes',
    'fauconneaux',
    'fauconnerai',
    'fauconneras',
    'fauconnerez',
    'fauconnerie',
    'fauconniers',
    'fauconnions',
    'faufilaient',
    'faufilasses',
    'faufilerais',
    'faufilerait',
    'faufilèrent',
    'faufileriez',
    'faufilerons',
    'faufileront',
    'faussassent',
    'faussassiez',
    'fausserions',
    'fautassions',
    'fauteraient',
    'fautivement',
    'faux-filets',
    'faux-fuyant',
    'favorisâmes',
    'favorisante',
    'favorisants',
    'favorisasse',
    'favorisâtes',
    'favoriserai',
    'favoriseras',
    'favoriserez',
    'favorisions',
    'favoritisme',
    'fayotassent',
    'fayotassiez',
    'fayoterions',
    'fayottaient',
    'fayottasses',
    'fayotterais',
    'fayotterait',
    'fayottèrent',
    'fayotteriez',
    'fayotterons',
    'fayotteront',
    'fébrilement',
    'fécondables',
    'fécondaient',
    'fécondantes',
    'fécondasses',
    'fécondateur',
    'fécondation',
    'féconderais',
    'féconderait',
    'fécondèrent',
    'féconderiez',
    'féconderons',
    'féconderont',
    'féculassent',
    'féculassiez',
    'féculerions',
    'fédéralisai',
    'fédéralisas',
    'fédéralisât',
    'fédéralisée',
    'fédéraliser',
    'fédéralises',
    'fédéralisés',
    'fédéralisez',
    'fédéralisme',
    'fédéraliste',
    'fédérassent',
    'fédérassiez',
    'fédérateurs',
    'fédérations',
    'fédératives',
    'fédératrice',
    'fédérerions',
    'fégaterions',
    'feignantais',
    'feignantait',
    'feignantant',
    'feignantent',
    'feignantera',
    'feignantiez',
    'feignantons',
    'feignissent',
    'feignissiez',
    'feindraient',
    'feintassent',
    'feintassiez',
    'feinterions',
    'félicitâmes',
    'félicitasse',
    'félicitâtes',
    'féliciterai',
    'féliciteras',
    'féliciterez',
    'félicitions',
    'féminisâmes',
    'féminisasse',
    'féminisâtes',
    'féminiserai',
    'féminiseras',
    'féminiserez',
    'féminisions',
    'femmelettes',
    'fendillâmes',
    'fendillasse',
    'fendillâtes',
    'fendillerai',
    'fendilleras',
    'fendillerez',
    'fendillions',
    'fendissions',
    'fenestrages',
    'fenestrâmes',
    'fenestrasse',
    'fenestrâtes',
    'fenestrerai',
    'fenestreras',
    'fenestrerez',
    'fenestrions',
    'fenêtraient',
    'fenêtrasses',
    'fenêtrerais',
    'fenêtrerait',
    'fenêtrèrent',
    'fenêtreriez',
    'fenêtrerons',
    'fenêtreront',
    'fenouillets',
    'féodalement',
    'féodalismes',
    'ferblantier',
    'ferlassions',
    'ferleraient',
    'fermassions',
    'fermentable',
    'fermentâmes',
    'fermentasse',
    'fermentâtes',
    'fermentatif',
    'fermenterai',
    'fermenteras',
    'fermenterez',
    'fermentions',
    'fermeraient',
    'ferraillage',
    'ferraillais',
    'ferraillait',
    'ferraillant',
    'ferraillent',
    'ferraillera',
    'ferrailleur',
    'ferrailliez',
    'ferraillons',
    'ferrassions',
    'ferreraient',
    'ferronnerie',
    'ferronnière',
    'ferronniers',
    'ferrotypies',
    'ferroutages',
    'ferroviaire',
    'ferrugineux',
    'ferry-boats',
    'fers-blancs',
    'fertilement',
    'fertilisais',
    'fertilisait',
    'fertilisant',
    'fertilisées',
    'fertilisent',
    'fertilisera',
    'fertilisiez',
    'fertilisons',
    'fessassions',
    'fesseraient',
    'festivalier',
    'festoierais',
    'festoierait',
    'festoieriez',
    'festoierons',
    'festoieront',
    'festonnâmes',
    'festonnasse',
    'festonnâtes',
    'festonnerai',
    'festonneras',
    'festonnerez',
    'festonnions',
    'festoyaient',
    'festoyasses',
    'festoyèrent',
    'fétichisais',
    'fétichisait',
    'fétichisant',
    'fétichisées',
    'fétichisent',
    'fétichisera',
    'fétichisiez',
    'fétichismes',
    'fétichisons',
    'fétichistes',
    'feudataires',
    'feuiletisai',
    'feuiletisas',
    'feuiletisât',
    'feuillaient',
    'feuillaison',
    'feuillasses',
    'feuillerais',
    'feuillerait',
    'feuillèrent',
    'feuillerets',
    'feuilleriez',
    'feuillerons',
    'feuilleront',
    'feuilletais',
    'feuilletait',
    'feuilletant',
    'feuilletées',
    'feuilletiez',
    'feuilletise',
    'feuilletisé',
    'feuilletons',
    'feuillettes',
    'feulassions',
    'feuleraient',
    'feutrassent',
    'feutrassiez',
    'feutrerions',
    'fiançailles',
    'fiançassent',
    'fiançassiez',
    'fiancerions',
    'fibrillaire',
    'fibrineuses',
    'fibrinogène',
    'fibrociment',
    'fibromateux',
    'fibromatose',
    'fibromyomes',
    'ficelassent',
    'ficelassiez',
    'ficellerais',
    'ficellerait',
    'ficelleries',
    'ficelleriez',
    'ficellerons',
    'ficelleront',
    'fichassions',
    'ficheraient',
    'fichtrement',
    'fictivement',
    'fidéicommis',
    'fidéjusseur',
    'fidéjussion',
    'fiduciaires',
    'fieffassent',
    'fieffassiez',
    'fiefferions',
    'fientassent',
    'fientassiez',
    'fienterions',
    'fier-à-bras',
    'fifty-fifty',
    'figeassions',
    'fignolaient',
    'fignolasses',
    'fignolerais',
    'fignolerait',
    'fignolèrent',
    'fignoleriez',
    'fignolerons',
    'fignoleront',
    'fignoleuses',
    'figurassent',
    'figurassiez',
    'figurations',
    'figuratives',
    'figurerions',
    'filamenteux',
    'filandières',
    'filandreuse',
    'filetassent',
    'filetassiez',
    'filèterions',
    'filialement',
    'filigranais',
    'filigranait',
    'filigranant',
    'filigranées',
    'filigranent',
    'filigranera',
    'filigraniez',
    'filigranons',
    'filipendule',
    'filmassions',
    'filmeraient',
    'filmologies',
    'filmothèque',
    'filochaient',
    'filochasses',
    'filocherais',
    'filocherait',
    'filochèrent',
    'filocheriez',
    'filocherons',
    'filocheront',
    'filoniennes',
    'filoutaient',
    'filoutasses',
    'filouterais',
    'filouterait',
    'filoutèrent',
    'filouteries',
    'filouteriez',
    'filouterons',
    'filouteront',
    'filtrassent',
    'filtrassiez',
    'filtrations',
    'filtrerions',
    'finalitaire',
    'finançaient',
    'finançasses',
    'financement',
    'financerais',
    'financerait',
    'financèrent',
    'financeriez',
    'financerons',
    'financeront',
    'financières',
    'finassaient',
    'finassasses',
    'finasserais',
    'finasserait',
    'finassèrent',
    'finasseries',
    'finasseriez',
    'finasserons',
    'finasseront',
    'finasseuses',
    'finassières',
    'finauderies',
    'finissaient',
    'finisseuses',
    'finlandaise',
    'finlandisai',
    'finlandisas',
    'finlandisât',
    'finlandisée',
    'finlandiser',
    'finlandises',
    'finlandisés',
    'finlandisez',
    'fiscalement',
    'fiscalisais',
    'fiscalisait',
    'fiscalisant',
    'fiscalisées',
    'fiscalisent',
    'fiscalisera',
    'fiscalisiez',
    'fiscalisons',
    'fiscalistes',
    'fissionnais',
    'fissionnait',
    'fissionnant',
    'fissionnées',
    'fissionnent',
    'fissionnera',
    'fissionniez',
    'fissionnons',
    'fissuraient',
    'fissurasses',
    'fissuration',
    'fissurerais',
    'fissurerait',
    'fissurèrent',
    'fissureriez',
    'fissurerons',
    'fissureront',
    'fistulaires',
    'fistuleuses',
    'flaccidités',
    'flaconnages',
    'flaconniers',
    'flagellaire',
    'flagellâmes',
    'flagellasse',
    'flagellâtes',
    'flagellerai',
    'flagelleras',
    'flagellerez',
    'flagellions',
    'flageolâmes',
    'flageolante',
    'flageolants',
    'flageolasse',
    'flageolâtes',
    'flageolerai',
    'flageoleras',
    'flageolerez',
    'flageolions',
    'flagornâmes',
    'flagornasse',
    'flagornâtes',
    'flagornerai',
    'flagorneras',
    'flagornerez',
    'flagornerie',
    'flagorneurs',
    'flagorneuse',
    'flagornions',
    'flairassent',
    'flairassiez',
    'flairerions',
    'flambassent',
    'flambassiez',
    'flambements',
    'flamberions',
    'flamboierai',
    'flamboieras',
    'flamboierez',
    'flamboyâmes',
    'flamboyante',
    'flamboyants',
    'flamboyasse',
    'flamboyâtes',
    'flamboyions',
    'flamingante',
    'flamingants',
    'flammeroles',
    'flânassions',
    'flanchaient',
    'flanchasses',
    'flancherais',
    'flancherait',
    'flanchèrent',
    'flancheriez',
    'flancherons',
    'flancheront',
    'flanconades',
    'flâneraient',
    'flanquaient',
    'flanquasses',
    'flanquement',
    'flanquerais',
    'flanquerait',
    'flanquèrent',
    'flanqueriez',
    'flanquerons',
    'flanqueront',
    'flaquassent',
    'flaquassiez',
    'flaquerions',
    'flashassent',
    'flashassiez',
    'flasherions',
    'flattassent',
    'flattassiez',
    'flatterions',
    'flatulences',
    'flatulentes',
    'flatuosités',
    'flaupassent',
    'flaupassiez',
    'flauperions',
    'flavescente',
    'flavescents',
    'fléchassent',
    'fléchassiez',
    'flécherions',
    'fléchirions',
    'fléchissais',
    'fléchissait',
    'fléchissant',
    'fléchissent',
    'fléchisseur',
    'fléchissiez',
    'fléchissons',
    'flegmatique',
    'flemmardais',
    'flemmardait',
    'flemmardant',
    'flemmardent',
    'flemmardera',
    'flemmardiez',
    'flemmardise',
    'flemmardons',
    'flétririons',
    'flétrissais',
    'flétrissait',
    'flétrissant',
    'flétrissent',
    'flétrissiez',
    'flétrissons',
    'flétrissure',
    'fleuraisons',
    'fleurassent',
    'fleurassiez',
    'fleurdelisé',
    'fleurerions',
    'fleuririons',
    'fleurissais',
    'fleurissait',
    'fleurissant',
    'fleurissent',
    'fleurissiez',
    'fleurissons',
    'fleuronnées',
    'flexibilité',
    'flexionnels',
    'flibustâmes',
    'flibustasse',
    'flibustâtes',
    'flibusterai',
    'flibusteras',
    'flibusterez',
    'flibustiers',
    'flibustions',
    'flinguaient',
    'flinguasses',
    'flinguerais',
    'flinguerait',
    'flinguèrent',
    'flingueriez',
    'flinguerons',
    'flingueront',
    'flippassent',
    'flippassiez',
    'flipperions',
    'flirtassent',
    'flirtassiez',
    'flirterions',
    'floconnâmes',
    'floconnante',
    'floconnants',
    'floconnasse',
    'floconnâtes',
    'floconnerai',
    'floconneras',
    'floconnerez',
    'floconneuse',
    'floconnions',
    'floculaient',
    'floculasses',
    'floculation',
    'floculerais',
    'floculerait',
    'floculèrent',
    'floculeriez',
    'floculerons',
    'floculeront',
    'florentines',
    'florissante',
    'florissants',
    'flosculeuse',
    'flottaisons',
    'flottassent',
    'flottassiez',
    'flottations',
    'flottements',
    'flotterions',
    'flouassions',
    'floueraient',
    'flousassent',
    'flousassiez',
    'flouserions',
    'fluctuaient',
    'fluctuantes',
    'fluctuasses',
    'fluctuation',
    'fluctuerais',
    'fluctuerait',
    'fluctuèrent',
    'fluctueriez',
    'fluctuerons',
    'fluctueront',
    'fluidifiais',
    'fluidifiait',
    'fluidifiant',
    'fluidifiées',
    'fluidifient',
    'fluidifiera',
    'fluidifiiez',
    'fluidifions',
    'fluidisâmes',
    'fluidisasse',
    'fluidisâtes',
    'fluidiserai',
    'fluidiseras',
    'fluidiserez',
    'fluidisions',
    'fluorations',
    'fluorescent',
    'fluorisâmes',
    'fluorisante',
    'fluorisants',
    'fluorisasse',
    'fluorisâtes',
    'fluoriserai',
    'fluoriseras',
    'fluoriserez',
    'fluorisions',
    'flûtassions',
    'flûteraient',
    'fluviatiles',
    'fluviomètre',
    'fluxassions',
    'fluxeraient',
    'focalisâmes',
    'focalisante',
    'focalisants',
    'focalisasse',
    'focalisâtes',
    'focaliserai',
    'focaliseras',
    'focaliserez',
    'focalisions',
    'foirassions',
    'foireraient',
    'foisonnâmes',
    'foisonnante',
    'foisonnants',
    'foisonnasse',
    'foisonnâtes',
    'foisonnerai',
    'foisonneras',
    'foisonnerez',
    'foisonnions',
    'folâtraient',
    'folâtrasses',
    'folâtrerais',
    'folâtrerait',
    'folâtrèrent',
    'folâtreries',
    'folâtreriez',
    'folâtrerons',
    'folâtreront',
    'folichonnai',
    'folichonnas',
    'folichonnât',
    'folichonner',
    'folichonnes',
    'folichonnez',
    'foliotaient',
    'foliotasses',
    'folioterais',
    'folioterait',
    'foliotèrent',
    'folioteriez',
    'folioterons',
    'folioteront',
    'folklorique',
    'folkloriste',
    'folliculine',
    'folliculite',
    'fomentaient',
    'fomentasses',
    'fomentateur',
    'fomentation',
    'fomenterais',
    'fomenterait',
    'fomentèrent',
    'fomenteriez',
    'fomenterons',
    'fomenteront',
    'fonçassions',
    'fonceraient',
    'fonctionnai',
    'fonctionnas',
    'fonctionnât',
    'fonctionnel',
    'fonctionner',
    'fonctionnes',
    'fonctionnez',
    'fondamental',
    'fondassions',
    'fondatrices',
    'fonderaient',
    'fondissions',
    'fongibilité',
    'fongiformes',
    'fontainiers',
    'fontanelles',
    'footballers',
    'footballeur',
    'forçassions',
    'forceraient',
    'forciraient',
    'forcissions',
    'forclusions',
    'foresteries',
    'forestières',
    'forfaisions',
    'forfaitaire',
    'forfaitures',
    'forfanterie',
    'forfassions',
    'forferaient',
    'forfissions',
    'forgeassent',
    'forgeassiez',
    'forgeraient',
    'forjetaient',
    'forjetasses',
    'forjetèrent',
    'forjetterai',
    'forjetteras',
    'forjetterez',
    'forlançâmes',
    'forlançasse',
    'forlançâtes',
    'forlancerai',
    'forlanceras',
    'forlancerez',
    'forlancions',
    'forlignâmes',
    'forlignasse',
    'forlignâtes',
    'forlignerai',
    'forligneras',
    'forlignerez',
    'forlignions',
    'formalisais',
    'formalisait',
    'formalisant',
    'formalisées',
    'formalisent',
    'formalisera',
    'formalisiez',
    'formalismes',
    'formalisons',
    'formalistes',
    'formariages',
    'formassions',
    'formatrices',
    'formeraient',
    'formicantes',
    'formication',
    'formidables',
    'formolaient',
    'formolasses',
    'formolerais',
    'formolerait',
    'formolèrent',
    'formoleriez',
    'formolerons',
    'formoleront',
    'formulables',
    'formulaient',
    'formulaires',
    'formulasses',
    'formulation',
    'formulerais',
    'formulerait',
    'formulèrent',
    'formuleriez',
    'formulerons',
    'formuleront',
    'fornicateur',
    'fornication',
    'forniquâmes',
    'forniquasse',
    'forniquâtes',
    'forniquerai',
    'forniqueras',
    'forniquerez',
    'forniquions',
    'forte-piano',
    'forteresses',
    'fortifiâmes',
    'fortifiante',
    'fortifiants',
    'fortifiasse',
    'fortifiâtes',
    'fortifierai',
    'fortifieras',
    'fortifierez',
    'fortifiions',
    'fortraiture',
    'fossilifère',
    'fossilisais',
    'fossilisait',
    'fossilisant',
    'fossilisées',
    'fossilisent',
    'fossilisera',
    'fossilisiez',
    'fossilisons',
    'fossoierais',
    'fossoierait',
    'fossoieriez',
    'fossoierons',
    'fossoieront',
    'fossoyaient',
    'fossoyasses',
    'fossoyèrent',
    'fossoyeuses',
    'fouaillâmes',
    'fouaillasse',
    'fouaillâtes',
    'fouaillerai',
    'fouailleras',
    'fouaillerez',
    'fouaillions',
    'foudroierai',
    'foudroieras',
    'foudroierez',
    'foudroyâmes',
    'foudroyante',
    'foudroyants',
    'foudroyasse',
    'foudroyâtes',
    'foudroyions',
    'fouettaient',
    'fouettasses',
    'fouettement',
    'fouetterais',
    'fouetterait',
    'fouettèrent',
    'fouetteriez',
    'fouetterons',
    'fouetteront',
    'fougeassent',
    'fougeassiez',
    'fougeraient',
    'fouillaient',
    'fouillasses',
    'fouillerais',
    'fouillerait',
    'fouillèrent',
    'fouilleriez',
    'fouillerons',
    'fouilleront',
    'fouilleuses',
    'fouinassent',
    'fouinassiez',
    'fouinerions',
    'fouissaient',
    'fouisseuses',
    'foulassions',
    'fouleraient',
    'foultitudes',
    'fouraillais',
    'fouraillait',
    'fouraillant',
    'fouraillées',
    'fouraillent',
    'fouraillera',
    'fourailliez',
    'fouraillons',
    'fourbassent',
    'fourbassiez',
    'fourberions',
    'fourbirions',
    'fourbissage',
    'fourbissais',
    'fourbissait',
    'fourbissant',
    'fourbissent',
    'fourbisseur',
    'fourbissiez',
    'fourbissons',
    'fourchaient',
    'fourchasses',
    'fourchèrent',
    'fourchettes',
    'fourgonnais',
    'fourgonnait',
    'fourgonnant',
    'fourgonnées',
    'fourgonnent',
    'fourgonnera',
    'fourgonniez',
    'fourgonnons',
    'fourguaient',
    'fourguasses',
    'fourguerais',
    'fourguerait',
    'fourguèrent',
    'fourgueriez',
    'fourguerons',
    'fourgueront',
    'fouriérisme',
    'fouriériste',
    'fourmilière',
    'fourmiliers',
    'fourmi-lion',
    'fourmilions',
    'fourmillais',
    'fourmillait',
    'fourmillant',
    'fourmillent',
    'fourmillera',
    'fourmilliez',
    'fourmillons',
    'fourniments',
    'fournirions',
    'fournissais',
    'fournissait',
    'fournissant',
    'fournissent',
    'fournisseur',
    'fournissiez',
    'fournissons',
    'fournitures',
    'fourrageais',
    'fourrageait',
    'fourrageant',
    'fourrageons',
    'fourragerai',
    'fourrageras',
    'fourragères',
    'fourragerez',
    'fourrageurs',
    'fourragions',
    'fourrassent',
    'fourrassiez',
    'fourrerions',
    'fourre-tout',
    'fourvoierai',
    'fourvoieras',
    'fourvoierez',
    'fourvoyâmes',
    'fourvoyante',
    'fourvoyants',
    'fourvoyasse',
    'fourvoyâtes',
    'fourvoyions',
    'foutriquets',
    'fox-terrier',
    'fracassâmes',
    'fracassante',
    'fracassants',
    'fracassasse',
    'fracassâtes',
    'fracasserai',
    'fracasseras',
    'fracasserez',
    'fracassions',
    'fractionnai',
    'fractionnas',
    'fractionnât',
    'fractionnée',
    'fractionnel',
    'fractionner',
    'fractionnes',
    'fractionnés',
    'fractionnez',
    'fracturâmes',
    'fracturasse',
    'fracturâtes',
    'fracturerai',
    'fractureras',
    'fracturerez',
    'fracturions',
    'fragilisais',
    'fragilisait',
    'fragilisant',
    'fragilisées',
    'fragilisent',
    'fragilisera',
    'fragilisiez',
    'fragilisons',
    'fragmentais',
    'fragmentait',
    'fragmentant',
    'fragmentées',
    'fragmentent',
    'fragmentera',
    'fragmentiez',
    'fragmentons',
    'fraîchement',
    'fraîchirais',
    'fraîchirait',
    'fraîchirent',
    'fraîchiriez',
    'fraîchirons',
    'fraîchiront',
    'fraîchisses',
    'fraîchissez',
    'fraieraient',
    'fraisassent',
    'fraisassiez',
    'fraiserions',
    'framboisais',
    'framboisait',
    'framboisant',
    'framboisées',
    'framboisent',
    'framboisera',
    'framboisier',
    'framboisiez',
    'framboisons',
    'franc-alleu',
    'franchement',
    'franchirais',
    'franchirait',
    'franchirent',
    'franchiriez',
    'franchirons',
    'franchiront',
    'franchisage',
    'franchisses',
    'franchissez',
    'francisâmes',
    'francisasse',
    'francisâtes',
    'franciscain',
    'franciserai',
    'franciseras',
    'franciserez',
    'francisions',
    'francisques',
    'franc-maçon',
    'francophile',
    'francophobe',
    'francophone',
    'francs-jeux',
    'frangeaient',
    'frangeantes',
    'frangeasses',
    'frangerions',
    'frangipanes',
    'franquistes',
    'frappassent',
    'frappassiez',
    'frappements',
    'frapperions',
    'fraternelle',
    'fraternisai',
    'fraternisas',
    'fraternisât',
    'fraterniser',
    'fraternises',
    'fraternisez',
    'fraternités',
    'fratricides',
    'fraudassent',
    'fraudassiez',
    'frauderions',
    'frauduleuse',
    'fraxinelles',
    'frayassions',
    'frayeraient',
    'fredonnâmes',
    'fredonnasse',
    'fredonnâtes',
    'fredonnerai',
    'fredonneras',
    'fredonnerez',
    'fredonnions',
    'free-lances',
    'frégataient',
    'frégatasses',
    'frégatèrent',
    'freinassent',
    'freinassiez',
    'freinerions',
    'frelataient',
    'frelatasses',
    'frelaterais',
    'frelaterait',
    'frelatèrent',
    'frelateriez',
    'frelaterons',
    'frelateront',
    'frémiraient',
    'frémissante',
    'frémissants',
    'frémissions',
    'frénétiques',
    'fréquemment',
    'fréquentais',
    'fréquentait',
    'fréquentant',
    'fréquentées',
    'fréquentent',
    'fréquentera',
    'fréquentiel',
    'fréquentiez',
    'fréquentons',
    'fresquistes',
    'frétassions',
    'fréteraient',
    'frétillâmes',
    'frétillante',
    'frétillants',
    'frétillasse',
    'frétillâtes',
    'frétillerai',
    'frétilleras',
    'frétillerez',
    'frétillions',
    'frettassent',
    'frettassiez',
    'fretterions',
    'freudiennes',
    'friabilités',
    'fricandeaux',
    'fricassâmes',
    'fricassasse',
    'fricassâtes',
    'fricasserai',
    'fricasseras',
    'fricasserez',
    'fricassions',
    'fricotaient',
    'fricotasses',
    'fricoterais',
    'fricoterait',
    'fricotèrent',
    'fricoteriez',
    'fricoterons',
    'fricoteront',
    'fricoteuses',
    'frictionnai',
    'frictionnas',
    'frictionnât',
    'frictionnée',
    'frictionnel',
    'frictionner',
    'frictionnes',
    'frictionnés',
    'frictionnez',
    'frigidaires',
    'frigidarium',
    'frigorifiai',
    'frigorifias',
    'frigorifiât',
    'frigorifiée',
    'frigorifier',
    'frigorifies',
    'frigorifiés',
    'frigorifiez',
    'frigorifuge',
    'frigorifugé',
    'frimassions',
    'frimeraient',
    'fringuaient',
    'fringuantes',
    'fringuasses',
    'fringuerais',
    'fringuerait',
    'fringuèrent',
    'fringueriez',
    'fringuerons',
    'fringueront',
    'fripassions',
    'friperaient',
    'friponnâmes',
    'friponnasse',
    'friponnâtes',
    'friponnerai',
    'friponneras',
    'friponnerez',
    'friponnerie',
    'friponnions',
    'fripouilles',
    'frisassions',
    'friseraient',
    'frisottâmes',
    'frisottante',
    'frisottants',
    'frisottasse',
    'frisottâtes',
    'frisotterai',
    'frisotteras',
    'frisotterez',
    'frisottions',
    'frisquettes',
    'frissonnais',
    'frissonnait',
    'frissonnant',
    'frissonnent',
    'frissonnera',
    'frissonniez',
    'frissonnons',
    'fritillaire',
    'frittassent',
    'frittassiez',
    'fritterions',
    'frivolement',
    'froidirions',
    'froidissais',
    'froidissait',
    'froidissant',
    'froidissent',
    'froidissiez',
    'froidissons',
    'froissables',
    'froissaient',
    'froissantes',
    'froissasses',
    'froissement',
    'froisserais',
    'froisserait',
    'froissèrent',
    'froisseriez',
    'froisserons',
    'froisseront',
    'frôlassions',
    'frôleraient',
    'fromageries',
    'fronçassent',
    'fronçassiez',
    'froncements',
    'froncerions',
    'frondaisons',
    'frondassent',
    'frondassiez',
    'fronderions',
    'frontalière',
    'frontaliers',
    'frontalités',
    'frontignans',
    'frontispice',
    'frottassent',
    'frottassiez',
    'frottements',
    'frotterions',
    'frouassions',
    'froueraient',
    'froufroutai',
    'froufroutas',
    'froufroutât',
    'froufrouter',
    'froufroutes',
    'froufroutez',
    'frous-frous',
    'froussardes',
    'fructifères',
    'fructifiais',
    'fructifiait',
    'fructifiant',
    'fructifient',
    'fructifiera',
    'fructifiiez',
    'fructifions',
    'fructueuses',
    'frugalement',
    'frumentacée',
    'frumentacés',
    'frumentaire',
    'frusquaient',
    'frusquasses',
    'frusquerais',
    'frusquerait',
    'frusquèrent',
    'frusqueriez',
    'frusquerons',
    'frusqueront',
    'frustraient',
    'frustrantes',
    'frustrasses',
    'frustration',
    'frustrerais',
    'frustrerait',
    'frustrèrent',
    'frustreriez',
    'frustrerons',
    'frustreront',
    'frutescente',
    'frutescents',
    'fuguassions',
    'fugueraient',
    'fulguraient',
    'fulgurances',
    'fulgurantes',
    'fulgurasses',
    'fulguration',
    'fulgurerais',
    'fulgurerait',
    'fulgurèrent',
    'fulgureriez',
    'fulgurerons',
    'fulgureront',
    'fuligineuse',
    'fulminaient',
    'fulminantes',
    'fulminasses',
    'fulmination',
    'fulminerais',
    'fulminerait',
    'fulminèrent',
    'fulmineriez',
    'fulminerons',
    'fulmineront',
    'fulminiques',
    'fume-cigare',
    'fumigateurs',
    'fumigations',
    'fumigatoire',
    'fumigeaient',
    'fumigeasses',
    'fumigerions',
    'fumisteries',
    'funérailles',
    'funérariums',
    'funestement',
    'funiculaire',
    'furetassent',
    'furetassiez',
    'furèterions',
    'furfuracées',
    'furonculeux',
    'furonculose',
    'furtivement',
    'fusainistes',
    'fuselassent',
    'fuselassiez',
    'fusellerais',
    'fusellerait',
    'fuselleriez',
    'fusellerons',
    'fuselleront',
    'fusibilités',
    'fusillaient',
    'fusillasses',
    'fusillerais',
    'fusillerait',
    'fusillèrent',
    'fusilleriez',
    'fusillerons',
    'fusilleront',
    'fusionnâmes',
    'fusionnante',
    'fusionnants',
    'fusionnasse',
    'fusionnâtes',
    'fusionnerai',
    'fusionneras',
    'fusionnerez',
    'fusionnions',
    'fussent-ils',
    'fustanelles',
    'fustigation',
    'fustigeâmes',
    'fustigeasse',
    'fustigeâtes',
    'fustigerais',
    'fustigerait',
    'fustigèrent',
    'fustigeriez',
    'fustigerons',
    'fustigeront',
    'futurologie',
    'futurologue',
    'gabionnages',
    'gabionnâmes',
    'gabionnasse',
    'gabionnâtes',
    'gabionnerai',
    'gabionneras',
    'gabionnerez',
    'gabionnions',
    'gâchassions',
    'gâcheraient',
    'gadgétisais',
    'gadgétisait',
    'gadgétisant',
    'gadgétisées',
    'gadgétisent',
    'gadgétisera',
    'gadgétisiez',
    'gadgétisons',
    'gadoliniums',
    'gaffassions',
    'gafferaient',
    'gageassions',
    'gagnassions',
    'gagne-petit',
    'gagneraient',
    'gaillardies',
    'gaillardise',
    'gailleterie',
    'gainassions',
    'gaineraient',
    'galactiques',
    'galactogène',
    'galanteries',
    'galbassions',
    'galberaient',
    'galéjassent',
    'galéjassiez',
    'galéjerions',
    'galetteuses',
    'galiléennes',
    'galimafrées',
    'galipotâmes',
    'galipotasse',
    'galipotâtes',
    'galipoterai',
    'galipoteras',
    'galipoterez',
    'galipotions',
    'gallicismes',
    'gallomanies',
    'galonnaient',
    'galonnasses',
    'galonnerais',
    'galonnerait',
    'galonnèrent',
    'galonneriez',
    'galonnerons',
    'galonneront',
    'galonnières',
    'galopassent',
    'galopassiez',
    'galoperions',
    'galvaniques',
    'galvanisais',
    'galvanisait',
    'galvanisant',
    'galvanisées',
    'galvanisent',
    'galvanisera',
    'galvanisiez',
    'galvanismes',
    'galvanisons',
    'galvanotype',
    'galvaudages',
    'galvaudâmes',
    'galvaudasse',
    'galvaudâtes',
    'galvauderai',
    'galvauderas',
    'galvauderez',
    'galvaudeuse',
    'galvaudions',
    'gambadaient',
    'gambadantes',
    'gambadasses',
    'gambaderais',
    'gambaderait',
    'gambadèrent',
    'gambaderiez',
    'gambaderons',
    'gambaderont',
    'gambergeais',
    'gambergeait',
    'gambergeant',
    'gambergeons',
    'gambergerai',
    'gambergeras',
    'gambergerez',
    'gambergions',
    'gambillâmes',
    'gambillasse',
    'gambillâtes',
    'gambillerai',
    'gambilleras',
    'gambillerez',
    'gambillions',
    'gaminassent',
    'gaminassiez',
    'gaminerions',
    'gamopétales',
    'gamosépales',
    'ganacheries',
    'gangétiques',
    'gangrenâmes',
    'gangrenante',
    'gangrenants',
    'gangrenasse',
    'gangrenâtes',
    'gangrènerai',
    'gangrèneras',
    'gangrènerez',
    'gangreneuse',
    'gangréneuse',
    'gangrenions',
    'gansassions',
    'ganseraient',
    'gantassions',
    'ganteraient',
    'garançaient',
    'garançasses',
    'garancerais',
    'garancerait',
    'garancèrent',
    'garanceries',
    'garanceriez',
    'garancerons',
    'garanceront',
    'garancières',
    'garantirais',
    'garantirait',
    'garantirent',
    'garantiriez',
    'garantirons',
    'garantiront',
    'garantisses',
    'garantissez',
    'garçonnière',
    'garçonniers',
    'gardassions',
    'garde-boeuf',
    'garde-corps',
    'garde-frein',
    'garde-nappe',
    'garde-pêche',
    'garde-place',
    'garde-ports',
    'garderaient',
    'garde-robes',
    'gardes-voie',
    'gardiennage',
    'gargamelles',
    'gargarisais',
    'gargarisait',
    'gargarisant',
    'gargarisées',
    'gargarisent',
    'gargarisera',
    'gargarisiez',
    'gargarismes',
    'gargarisons',
    'gargotaient',
    'gargotantes',
    'gargotasses',
    'gargoterais',
    'gargoterait',
    'gargotèrent',
    'gargoteriez',
    'gargoterons',
    'gargoteront',
    'gargotières',
    'gargouillai',
    'gargouillas',
    'gargouillât',
    'gargouiller',
    'gargouilles',
    'gargouillez',
    'gargouillis',
    'gargoulette',
    'garibaldien',
    'garniraient',
    'garnissages',
    'garnisseurs',
    'garnisseuse',
    'garnissions',
    'garrottages',
    'garrottâmes',
    'garrottasse',
    'garrottâtes',
    'garrotterai',
    'garrotteras',
    'garrotterez',
    'garrottions',
    'gasconnades',
    'gasconnâmes',
    'gasconnasse',
    'gasconnâtes',
    'gasconnerai',
    'gasconneras',
    'gasconnerez',
    'gasconnions',
    'gasconnisme',
    'gaspillages',
    'gaspillâmes',
    'gaspillasse',
    'gaspillâtes',
    'gaspillerai',
    'gaspilleras',
    'gaspillerez',
    'gaspilleurs',
    'gaspilleuse',
    'gaspillions',
    'gastéropode',
    'gastralgies',
    'gastronomes',
    'gastronomie',
    'gastropodes',
    'gastrotomie',
    'gâtifiaient',
    'gâtifiasses',
    'gâtifierais',
    'gâtifierait',
    'gâtifièrent',
    'gâtifieriez',
    'gâtifierons',
    'gâtifieront',
    'gauchirions',
    'gauchisante',
    'gauchisants',
    'gauchissais',
    'gauchissait',
    'gauchissant',
    'gauchissent',
    'gauchissiez',
    'gauchissons',
    'gaufrassent',
    'gaufrassiez',
    'gaufrerions',
    'gaulassions',
    'gauleraient',
    'gaulliennes',
    'gauloiserie',
    'gaulthéries',
    'gaussassent',
    'gaussassiez',
    'gausserions',
    'gazéifiâmes',
    'gazéifiasse',
    'gazéifiâtes',
    'gazéifierai',
    'gazéifieras',
    'gazéifierez',
    'gazéifiions',
    'gazonnaient',
    'gazonnasses',
    'gazonnement',
    'gazonnerais',
    'gazonnerait',
    'gazonnèrent',
    'gazonneriez',
    'gazonnerons',
    'gazonneront',
    'gazouillais',
    'gazouillait',
    'gazouillant',
    'gazouillent',
    'gazouillera',
    'gazouilleur',
    'gazouilliez',
    'gazouillons',
    'geignements',
    'geignissent',
    'geignissiez',
    'geindraient',
    'gélatinâmes',
    'gélatinasse',
    'gélatinâtes',
    'gélatinerai',
    'gélatineras',
    'gélatinerez',
    'gélatineuse',
    'gélatinions',
    'gélatinisai',
    'gélatinisas',
    'gélatinisât',
    'gélatinisée',
    'gélatiniser',
    'gélatinises',
    'gélatinisés',
    'gélatinisez',
    'gélifiaient',
    'gélifiantes',
    'gélifiasses',
    'gélifierais',
    'gélifierait',
    'gélifièrent',
    'gélifieriez',
    'gélifierons',
    'gélifieront',
    'gémellaires',
    'gémellipare',
    'géminassent',
    'géminassiez',
    'géminations',
    'géminerions',
    'gémissaient',
    'gémissantes',
    'gémissement',
    'gemmassions',
    'gemmeraient',
    'gemmologies',
    'gendarmâmes',
    'gendarmasse',
    'gendarmâtes',
    'gendarmerai',
    'gendarmeras',
    'gendarmerez',
    'gendarmerie',
    'gendarmions',
    'généalogies',
    'généralisai',
    'généralisas',
    'généralisât',
    'généralisée',
    'généraliser',
    'généralises',
    'généralisés',
    'généralisez',
    'généraliste',
    'généralités',
    'générassent',
    'générassiez',
    'générateurs',
    'générations',
    'génératives',
    'génératrice',
    'générerions',
    'générosités',
    'génésiaques',
    'généticiens',
    'génialement',
    'genouillère',
    'gentilhomme',
    'gentillesse',
    'gentillette',
    'génuflexion',
    'géodésiques',
    'géographies',
    'géologiques',
    'géométrique',
    'géométrisai',
    'géométrisas',
    'géométrisât',
    'géométrisée',
    'géométriser',
    'géométrises',
    'géométrisés',
    'géométrisez',
    'géophysique',
    'géorgiennes',
    'géothermies',
    'géotropisme',
    'gerbassions',
    'gerberaient',
    'gerçassions',
    'gerceraient',
    'gériatrique',
    'germandrées',
    'germaniques',
    'germanisais',
    'germanisait',
    'germanisant',
    'germanisées',
    'germanisent',
    'germanisera',
    'germanisiez',
    'germanismes',
    'germanisons',
    'germanistes',
    'germassions',
    'germeraient',
    'germinateur',
    'germinatifs',
    'germination',
    'germinative',
    'gérontismes',
    'gestaltisme',
    'gestatoires',
    'gesticulais',
    'gesticulait',
    'gesticulant',
    'gesticulent',
    'gesticulera',
    'gesticuliez',
    'gesticulons',
    'giboierions',
    'giboyassent',
    'giboyassiez',
    'giclassions',
    'gicleraient',
    'giflassions',
    'gifleraient',
    'gigantesque',
    'gigantismes',
    'gigotassent',
    'gigotassiez',
    'gigoterions',
    'gironnaient',
    'gironnasses',
    'gironnerais',
    'gironnerait',
    'gironnèrent',
    'gironneriez',
    'gironnerons',
    'gironneront',
    'girouettais',
    'girouettait',
    'girouettant',
    'girouettent',
    'girouettera',
    'girouettiez',
    'girouettons',
    'givrassions',
    'givreraient',
    'glaçassions',
    'glaceraient',
    'glaciations',
    'glaciologue',
    'gladiateurs',
    'glairassent',
    'glairassiez',
    'glairerions',
    'glaisassent',
    'glaisassiez',
    'glaiserions',
    'glanassions',
    'glandassent',
    'glandassiez',
    'glanderions',
    'glandouilla',
    'glandouille',
    'glandouillé',
    'glandulaire',
    'glanduleuse',
    'glaneraient',
    'glapiraient',
    'glapissante',
    'glapissants',
    'glapissions',
    'glatiraient',
    'glatissions',
    'glaviotâmes',
    'glaviotasse',
    'glaviotâtes',
    'glavioterai',
    'glavioteras',
    'glavioterez',
    'glaviotions',
    'glaviottais',
    'glaviottait',
    'glaviottant',
    'glaviottent',
    'glaviottera',
    'glaviottiez',
    'glaviottons',
    'glissassent',
    'glissassiez',
    'glissements',
    'glisserions',
    'globalement',
    'globalisais',
    'globalisait',
    'globalisant',
    'globalisées',
    'globalisent',
    'globalisera',
    'globalisiez',
    'globalisons',
    'globigérine',
    'globulaires',
    'globuleuses',
    'glorifiâmes',
    'glorifiante',
    'glorifiants',
    'glorifiasse',
    'glorifiâtes',
    'glorifierai',
    'glorifieras',
    'glorifierez',
    'glorifiions',
    'glosassions',
    'gloseraient',
    'glossateurs',
    'glossolalie',
    'glossotomie',
    'glougloutai',
    'glougloutas',
    'glougloutât',
    'glouglouter',
    'glougloutes',
    'glougloutez',
    'gloussaient',
    'gloussasses',
    'gloussement',
    'glousserais',
    'glousserait',
    'gloussèrent',
    'glousseriez',
    'glousserons',
    'glousseront',
    'glucomètres',
    'glucoseries',
    'glutamiques',
    'glutineuses',
    'glycérinais',
    'glycérinait',
    'glycérinant',
    'glycérinées',
    'glycérinent',
    'glycérinera',
    'glycériniez',
    'glycérinons',
    'glycériques',
    'glycocolles',
    'glycolipide',
    'glycosuries',
    'glyptodonte',
    'gnian-gnian',
    'gnomoniques',
    'gnoséologie',
    'gnosticisme',
    'gobeleterie',
    'gobeletiers',
    'gobe-mouche',
    'gobergeâmes',
    'gobergeasse',
    'gobergeâtes',
    'gobergerais',
    'gobergerait',
    'gobergèrent',
    'gobergeriez',
    'gobergerons',
    'gobergeront',
    'gobetassent',
    'gobetassiez',
    'gobetterais',
    'gobetterait',
    'gobetteriez',
    'gobetterons',
    'gobetteront',
    'godaillâmes',
    'godaillasse',
    'godaillâtes',
    'godaillerai',
    'godailleras',
    'godaillerez',
    'godaillions',
    'godelureaux',
    'godillaient',
    'godillantes',
    'godillasses',
    'godillerais',
    'godillerait',
    'godillèrent',
    'godilleriez',
    'godillerons',
    'godilleront',
    'godronnages',
    'godronnâmes',
    'godronnasse',
    'godronnâtes',
    'godronnerai',
    'godronneras',
    'godronnerez',
    'godronnions',
    'goguenardai',
    'goguenardas',
    'goguenardât',
    'goguenarder',
    'goguenardes',
    'goguenardez',
    'goinfraient',
    'goinfrasses',
    'goinfrerais',
    'goinfrerait',
    'goinfrèrent',
    'goinfreries',
    'goinfreriez',
    'goinfrerons',
    'goinfreront',
    'gominassent',
    'gominassiez',
    'gominerions',
    'gommassions',
    'gomme-gutte',
    'gommeraient',
    'gomorrhéens',
    'gonadotrope',
    'gondassions',
    'gonderaient',
    'gondolaient',
    'gondolantes',
    'gondolasses',
    'gondolement',
    'gondolerais',
    'gondolerait',
    'gondolèrent',
    'gondoleriez',
    'gondolerons',
    'gondoleront',
    'gonfalonier',
    'gonfanonier',
    'gonflassent',
    'gonflassiez',
    'gonflements',
    'gonflerions',
    'gongorismes',
    'goniomètres',
    'goniométrie',
    'gonococcies',
    'gorgeassent',
    'gorgeassiez',
    'gorgeraient',
    'gorgerettes',
    'gorgonzolas',
    'gouachaient',
    'gouachasses',
    'gouacherais',
    'gouacherait',
    'gouachèrent',
    'gouacheriez',
    'gouacherons',
    'gouacheront',
    'gouaillâmes',
    'gouaillante',
    'gouaillants',
    'gouaillasse',
    'gouaillâtes',
    'gouaillerai',
    'gouailleras',
    'gouaillerez',
    'gouaillerie',
    'gouailleurs',
    'gouailleuse',
    'gouaillions',
    'goudronnage',
    'goudronnais',
    'goudronnait',
    'goudronnant',
    'goudronnées',
    'goudronnent',
    'goudronnera',
    'goudronneur',
    'goudronneux',
    'goudronniez',
    'goudronnons',
    'gougnafiers',
    'goujateries',
    'goujonnâmes',
    'goujonnasse',
    'goujonnâtes',
    'goujonnerai',
    'goujonneras',
    'goujonnerez',
    'goujonnière',
    'goujonnions',
    'gouleyantes',
    'goupillâmes',
    'goupillasse',
    'goupillâtes',
    'goupillerai',
    'goupilleras',
    'goupillerez',
    'goupillions',
    'goupillonna',
    'goupillonne',
    'goupillonné',
    'gourassions',
    'goureraient',
    'gourgandine',
    'gourmandais',
    'gourmandait',
    'gourmandant',
    'gourmandées',
    'gourmandent',
    'gourmandera',
    'gourmandiez',
    'gourmandise',
    'gourmandons',
    'goûtassions',
    'goûteraient',
    'gouttassent',
    'gouttassiez',
    'gouttelette',
    'goutterions',
    'gouvernable',
    'gouvernails',
    'gouvernâmes',
    'gouvernance',
    'gouvernante',
    'gouvernants',
    'gouvernasse',
    'gouvernâtes',
    'gouvernerai',
    'gouverneras',
    'gouvernerez',
    'gouverneurs',
    'gouvernions',
    'grabataires',
    'graciassent',
    'graciassiez',
    'gracierions',
    'gracieuseté',
    'graduassent',
    'graduassiez',
    'graduations',
    'graduerions',
    'graillaient',
    'graillasses',
    'graillement',
    'graillerais',
    'graillerait',
    'graillèrent',
    'grailleriez',
    'graillerons',
    'grailleront',
    'graillonnai',
    'graillonnas',
    'graillonnât',
    'graillonner',
    'graillonnes',
    'graillonnez',
    'grainassent',
    'grainassiez',
    'grainerions',
    'graineterie',
    'grainetière',
    'grainetiers',
    'graissaient',
    'graissantes',
    'graissasses',
    'graisserais',
    'graisserait',
    'graissèrent',
    'graisseriez',
    'graisserons',
    'graisseront',
    'graisseuses',
    'grammairien',
    'grammatical',
    'grammatiste',
    'gramophones',
    'grand-angle',
    'grand-chose',
    'grand-croix',
    'grand-ducal',
    'grand-duché',
    'grandelette',
    'grandirions',
    'grandissais',
    'grandissait',
    'grandissant',
    'grandissent',
    'grandissiez',
    'grandissons',
    'grand-maman',
    'grand-mères',
    'grand-messe',
    'grand-oncle',
    'grand-peine',
    'grands-ducs',
    'grand-tante',
    'grand-voile',
    'granitaient',
    'granitasses',
    'graniterais',
    'graniterait',
    'granitèrent',
    'graniteriez',
    'graniterons',
    'graniteront',
    'graniteuses',
    'granitiques',
    'granitoïdes',
    'granulaient',
    'granulaires',
    'granulasses',
    'granulation',
    'granulerais',
    'granulerait',
    'granulèrent',
    'granuleriez',
    'granulerons',
    'granuleront',
    'granuleuses',
    'granulocyte',
    'grape-fruit',
    'grapefruits',
    'graphitages',
    'graphitâmes',
    'graphitasse',
    'graphitâtes',
    'graphiterai',
    'graphiteras',
    'graphiterez',
    'graphiteuse',
    'graphitions',
    'graphologie',
    'graphologue',
    'graphomètre',
    'grappillage',
    'grappillais',
    'grappillait',
    'grappillant',
    'grappillées',
    'grappillent',
    'grappillera',
    'grappilleur',
    'grappilliez',
    'grappillons',
    'gras-double',
    'grasseyâmes',
    'grasseyasse',
    'grasseyâtes',
    'grasseyerai',
    'grasseyeras',
    'grasseyerez',
    'grasseyions',
    'graticulais',
    'graticulait',
    'graticulant',
    'graticulées',
    'graticulent',
    'graticulera',
    'graticuliez',
    'graticulons',
    'gratifiâmes',
    'gratifiante',
    'gratifiants',
    'gratifiasse',
    'gratifiâtes',
    'gratifierai',
    'gratifieras',
    'gratifierez',
    'gratifiions',
    'gratinaient',
    'gratinasses',
    'gratinerais',
    'gratinerait',
    'gratinèrent',
    'gratineriez',
    'gratinerons',
    'gratineront',
    'grattassent',
    'grattassiez',
    'gratte-ciel',
    'grattements',
    'gratterions',
    'gravassions',
    'graveleuses',
    'graveraient',
    'gravidiques',
    'gravimétrie',
    'graviraient',
    'gravissions',
    'gravitaient',
    'gravitasses',
    'gravitation',
    'graviterais',
    'graviterait',
    'gravitèrent',
    'graviteriez',
    'graviterons',
    'graviteront',
    'grécisaient',
    'grécisasses',
    'gréciserais',
    'gréciserait',
    'grécisèrent',
    'gréciseriez',
    'gréciserons',
    'gréciseront',
    'gréco-latin',
    'grecquaient',
    'grecquasses',
    'grecquerais',
    'grecquerait',
    'grecquèrent',
    'grecqueriez',
    'grecquerons',
    'grecqueront',
    'gredineries',
    'greffassent',
    'greffassiez',
    'grefferions',
    'grégarismes',
    'grégorienne',
    'grelottâmes',
    'grelottante',
    'grelottants',
    'grelottasse',
    'grelottâtes',
    'grelotterai',
    'grelotteras',
    'grelotterez',
    'grelottions',
    'grenadières',
    'grenadilles',
    'grenaillais',
    'grenaillait',
    'grenaillant',
    'grenaillées',
    'grenaillent',
    'grenaillera',
    'grenailliez',
    'grenaillons',
    'grenassions',
    'grenelaient',
    'grenelasses',
    'grenelèrent',
    'grenellerai',
    'grenelleras',
    'grenellerez',
    'grèneraient',
    'grenouillai',
    'grenouillas',
    'grenouillât',
    'grenouiller',
    'grenouilles',
    'grenouillez',
    'grésassions',
    'gréseraient',
    'grevassions',
    'grèveraient',
    'gribouillai',
    'gribouillas',
    'gribouillât',
    'gribouillée',
    'gribouiller',
    'gribouilles',
    'gribouillés',
    'gribouillez',
    'gribouillis',
    'griffassent',
    'griffassiez',
    'grifferions',
    'griffonnage',
    'griffonnais',
    'griffonnait',
    'griffonnant',
    'griffonnées',
    'griffonnent',
    'griffonnera',
    'griffonneur',
    'griffonniez',
    'griffonnons',
    'grignassent',
    'grignassiez',
    'grignerions',
    'grignotages',
    'grignotâmes',
    'grignotasse',
    'grignotâtes',
    'grignoterai',
    'grignoteras',
    'grignoterez',
    'grignoteurs',
    'grignoteuse',
    'grignotions',
    'grillageais',
    'grillageait',
    'grillageant',
    'grillageons',
    'grillagerai',
    'grillageras',
    'grillagerez',
    'grillageurs',
    'grillagions',
    'grillassent',
    'grillassiez',
    'grille-pain',
    'grillerions',
    'grill-rooms',
    'grimaçaient',
    'grimaçantes',
    'grimaçasses',
    'grimacerais',
    'grimacerait',
    'grimacèrent',
    'grimaceriez',
    'grimacerons',
    'grimaceront',
    'grimacières',
    'grimassions',
    'grimeraient',
    'grimpassent',
    'grimpassiez',
    'grimpereaux',
    'grimperions',
    'grinçassent',
    'grinçassiez',
    'grincements',
    'grincerions',
    'grinchaient',
    'grinchasses',
    'grincherais',
    'grincherait',
    'grinchèrent',
    'grincheriez',
    'grincherons',
    'grincheront',
    'grincheuses',
    'gringuaient',
    'gringuasses',
    'gringuerais',
    'gringuerait',
    'gringuèrent',
    'gringueriez',
    'gringuerons',
    'gringueront',
    'grippassent',
    'grippassiez',
    'gripperions',
    'grippe-sous',
    'grisaillais',
    'grisaillait',
    'grisaillant',
    'grisaillées',
    'grisaillent',
    'grisaillera',
    'grisailliez',
    'grisaillons',
    'grisassions',
    'griseraient',
    'grisolaient',
    'grisolasses',
    'grisolerais',
    'grisolerait',
    'grisolèrent',
    'grisoleriez',
    'grisolerons',
    'grisoleront',
    'grisollâmes',
    'grisollasse',
    'grisollâtes',
    'grisollerai',
    'grisolleras',
    'grisollerez',
    'grisollions',
    'grisonnâmes',
    'grisonnante',
    'grisonnants',
    'grisonnasse',
    'grisonnâtes',
    'grisonnerai',
    'grisonneras',
    'grisonnerez',
    'grisonnions',
    'grisoumètre',
    'grisouteuse',
    'grivelaient',
    'grivelasses',
    'grivelèrent',
    'grivèleries',
    'grivellerai',
    'grivelleras',
    'grivellerez',
    'grivoiserie',
    'groenendael',
    'grognassais',
    'grognassait',
    'grognassant',
    'grognassent',
    'grognassera',
    'grognassiez',
    'grognassons',
    'grognements',
    'grognerions',
    'grommelâmes',
    'grommelasse',
    'grommelâtes',
    'grommelions',
    'grommellent',
    'grommellera',
    'grondassent',
    'grondassiez',
    'grondements',
    'gronderions',
    'groseillier',
    'grossièreté',
    'grossirions',
    'grossissais',
    'grossissait',
    'grossissant',
    'grossissent',
    'grossissiez',
    'grossissons',
    'grossoierai',
    'grossoieras',
    'grossoierez',
    'grossoyâmes',
    'grossoyasse',
    'grossoyâtes',
    'grossoyions',
    'grouillante',
    'grouillants',
    'grouillerai',
    'grouilleras',
    'grouillerez',
    'grouillions',
    'groullasses',
    'groullèrent',
    'groupassent',
    'groupassiez',
    'groupements',
    'grouperions',
    'groupuscule',
    'grugeassent',
    'grugeassiez',
    'grugeraient',
    'grumelaient',
    'grumelasses',
    'grumelèrent',
    'grumeleuses',
    'grumellerai',
    'grumelleras',
    'grumellerez',
    'guérilleros',
    'guériraient',
    'guérissable',
    'guérissante',
    'guérissants',
    'guérisseurs',
    'guérisseuse',
    'guérissions',
    'guerroierai',
    'guerroieras',
    'guerroierez',
    'guerroyâmes',
    'guerroyasse',
    'guerroyâtes',
    'guerroyions',
    'guêtrassent',
    'guêtrassiez',
    'guêtrerions',
    'guets-apens',
    'guettassent',
    'guettassiez',
    'guetterions',
    'gueulassent',
    'gueulassiez',
    'gueulements',
    'gueulerions',
    'gueuletonna',
    'gueuletonne',
    'gueuletonné',
    'gueusassent',
    'gueusassiez',
    'gueuserions',
    'guichetière',
    'guichetiers',
    'guidassions',
    'guideraient',
    'guignassent',
    'guignassiez',
    'guignerions',
    'guillemetai',
    'guillemetas',
    'guillemetât',
    'guillemetée',
    'guillemeter',
    'guillemetés',
    'guillemetez',
    'guillemette',
    'guillerette',
    'guillochage',
    'guillochais',
    'guillochait',
    'guillochant',
    'guillochées',
    'guillochent',
    'guillochera',
    'guillocheur',
    'guillochiez',
    'guillochons',
    'guillochure',
    'guillotinai',
    'guillotinas',
    'guillotinât',
    'guillotinée',
    'guillotiner',
    'guillotines',
    'guillotinés',
    'guillotinez',
    'guinchaient',
    'guinchasses',
    'guincherais',
    'guincherait',
    'guinchèrent',
    'guincheriez',
    'guincherons',
    'guincheront',
    'guindassent',
    'guindassiez',
    'guinderesse',
    'guinderions',
    'guinguettes',
    'guipassions',
    'guiperaient',
    'guitaristes',
    'gustométrie',
    'gymnastique',
    'gymnocarpes',
    'gymnosperme',
    'gynécologie',
    'gynécologue',
    'gypsomètres',
    'gypsophiles',
    'gyropilotes',
    'habilitâmes',
    'habilitasse',
    'habilitâtes',
    'habiliterai',
    'habiliteras',
    'habiliterez',
    'habilitions',
    'habillables',
    'habillaient',
    'habillasses',
    'habillement',
    'habillerais',
    'habillerait',
    'habillèrent',
    'habilleriez',
    'habillerons',
    'habilleront',
    'habilleuses',
    'habitassent',
    'habitassiez',
    'habitations',
    'habiterions',
    'habituaient',
    'habituasses',
    'habituation',
    'habituelles',
    'habituerais',
    'habituerait',
    'habituèrent',
    'habitueriez',
    'habituerons',
    'habitueront',
    'hablassions',
    'hableraient',
    'hachassions',
    'hacheraient',
    'hachuraient',
    'hachurasses',
    'hachurerais',
    'hachurerait',
    'hachurèrent',
    'hachureriez',
    'hachurerons',
    'hachureront',
    'hagiographe',
    'haillonneux',
    'haletassent',
    'haletassiez',
    'halètements',
    'halèterions',
    'half-tracks',
    'halieutique',
    'halitueuses',
    'hallebardes',
    'hallucinais',
    'hallucinait',
    'hallucinant',
    'hallucinées',
    'hallucinent',
    'hallucinera',
    'halluciniez',
    'hallucinons',
    'hallucinose',
    'halogénures',
    'halographie',
    'hamadryades',
    'hameçonnais',
    'hameçonnait',
    'hameçonnant',
    'hameçonnées',
    'hameçonnent',
    'hameçonnera',
    'hameçonniez',
    'hameçonnons',
    'hanchassent',
    'hanchassiez',
    'hanchements',
    'hancherions',
    'handballeur',
    'handicapais',
    'handicapait',
    'handicapant',
    'handicapées',
    'handicapent',
    'handicapera',
    'handicapeur',
    'handicapiez',
    'handicapons',
    'handisports',
    'hannetonnai',
    'hannetonnas',
    'hannetonnât',
    'hannetonner',
    'hannetonnes',
    'hannetonnez',
    'hanséatique',
    'hantassions',
    'hanteraient',
    'haplologies',
    'happassions',
    'happeraient',
    'haranguâmes',
    'haranguasse',
    'haranguâtes',
    'haranguerai',
    'harangueras',
    'haranguerez',
    'harangueurs',
    'harangueuse',
    'haranguions',
    'harassaient',
    'harassantes',
    'harassasses',
    'harassement',
    'harasserais',
    'harasserait',
    'harassèrent',
    'harasseriez',
    'harasserons',
    'harasseront',
    'harcelaient',
    'harcelantes',
    'harcelasses',
    'harcèlement',
    'harcèlerais',
    'harcèlerait',
    'harcelèrent',
    'harcèleriez',
    'harcèlerons',
    'harcèleront',
    'harcellerai',
    'harcelleras',
    'harcellerez',
    'hardassions',
    'harderaient',
    'harengaison',
    'harenguiers',
    'harmonieuse',
    'harmoniques',
    'harmonisais',
    'harmonisait',
    'harmonisant',
    'harmonisées',
    'harmonisent',
    'harmonisera',
    'harmonisiez',
    'harmonisons',
    'harnachâmes',
    'harnachasse',
    'harnachâtes',
    'harnacherai',
    'harnacheras',
    'harnacherez',
    'harnacheurs',
    'harnachions',
    'harpaillais',
    'harpaillait',
    'harpaillant',
    'harpaillées',
    'harpaillent',
    'harpaillera',
    'harpailliez',
    'harpaillons',
    'harpassions',
    'harperaient',
    'harponnages',
    'harponnâmes',
    'harponnasse',
    'harponnâtes',
    'harponnerai',
    'harponneras',
    'harponnerez',
    'harponneurs',
    'harponnions',
    'hasardaient',
    'hasardasses',
    'hasarderais',
    'hasarderait',
    'hasardèrent',
    'hasarderiez',
    'hasarderons',
    'hasarderont',
    'hasardeuses',
    'haschischin',
    'hassidismes',
    'haubanaient',
    'haubanasses',
    'haubanerais',
    'haubanerait',
    'haubanèrent',
    'haubaneriez',
    'haubanerons',
    'haubaneront',
    'haussassent',
    'haussassiez',
    'hausse-cols',
    'haussements',
    'hausserions',
    'hautboïstes',
    'haut-relief',
    'hauts-fonds',
    'hauturières',
    'havissaient',
    'hebdomadier',
    'hébéphrénie',
    'hébergeâmes',
    'hébergeasse',
    'hébergeâtes',
    'hébergement',
    'hébergerais',
    'hébergerait',
    'hébergèrent',
    'hébergeriez',
    'hébergerons',
    'hébergeront',
    'hébertismes',
    'hébétassent',
    'hébétassiez',
    'hébétements',
    'hébéterions',
    'hébraïsâmes',
    'hébraïsante',
    'hébraïsants',
    'hébraïsasse',
    'hébraïsâtes',
    'hébraïserai',
    'hébraïseras',
    'hébraïserez',
    'hébraïsions',
    'hectogramme',
    'hectolitres',
    'hectomètres',
    'hégéliennes',
    'hégémonique',
    'hélianthème',
    'hélianthine',
    'hélicoïdale',
    'hélicoïdaux',
    'hélicoptère',
    'héliographe',
    'héliomarine',
    'héliomarins',
    'héliotropes',
    'héliportage',
    'héliportées',
    'helléniques',
    'hellénisais',
    'hellénisait',
    'hellénisant',
    'hellénisées',
    'hellénisent',
    'hellénisera',
    'hellénisiez',
    'hellénismes',
    'hellénisons',
    'hellénistes',
    'helvétiques',
    'helvétismes',
    'hémarthrose',
    'hématémèses',
    'hématidrose',
    'hématologie',
    'hématologue',
    'héméralopes',
    'héméralopie',
    'hémérocalle',
    'hémicrânies',
    'hémiédrique',
    'hémiparésie',
    'hémiplégies',
    'hémisphères',
    'hémistiches',
    'hémitropies',
    'hémoculture',
    'hémocyanine',
    'hémodialyse',
    'hémoglobine',
    'hémogrammes',
    'hémolysines',
    'hémolytique',
    'hémopathies',
    'hémophilies',
    'hémoptysies',
    'hémorragies',
    'hémorroïdal',
    'hémorroïdes',
    'hendécagone',
    'henniraient',
    'hennissante',
    'hennissants',
    'hennissions',
    'hépatalgies',
    'hépatocèles',
    'hépatologie',
    'heptagonale',
    'heptagonaux',
    'heptamètres',
    'héraldiques',
    'héraldistes',
    'herbageâmes',
    'herbageasse',
    'herbageâtes',
    'herbagerais',
    'herbagerait',
    'herbagèrent',
    'herbageriez',
    'herbagerons',
    'herbageront',
    'herbassions',
    'herberaient',
    'herborisais',
    'herborisait',
    'herborisant',
    'herborisées',
    'herborisent',
    'herborisera',
    'herborisiez',
    'herborisons',
    'herboristes',
    'herculéenne',
    'hercynienne',
    'héréditaire',
    'hérésiarque',
    'hérissaient',
    'hérissasses',
    'hérissement',
    'hérisserais',
    'hérisserait',
    'hérissèrent',
    'hérisseriez',
    'hérisserons',
    'hérisseront',
    'hérissonnai',
    'hérissonnas',
    'hérissonnât',
    'hérissonnée',
    'hérissonner',
    'hérissonnes',
    'hérissonnés',
    'hérissonnez',
    'héritassent',
    'héritassiez',
    'hériterions',
    'herméticité',
    'hermétiques',
    'hermétismes',
    'herminettes',
    'héroïnomane',
    'héronnières',
    'herpétiques',
    'hersassions',
    'herscheuses',
    'herseraient',
    'hésitassent',
    'hésitassiez',
    'hésitations',
    'hésiterions',
    'hétéroclite',
    'hétérodoxes',
    'hétérodoxie',
    'hétérodynes',
    'hétérogamie',
    'hétérogènes',
    'hétérogénie',
    'hétérologue',
    'heure-homme',
    'heuristique',
    'heurtassent',
    'heurtassiez',
    'heurterions',
    'hexadécimal',
    'hexagonales',
    'hibernaient',
    'hibernantes',
    'hibernasses',
    'hibernation',
    'hibernerais',
    'hibernerait',
    'hibernèrent',
    'hiberneriez',
    'hibernerons',
    'hiberneront',
    'hideusement',
    'hiérarchies',
    'hiérarchisa',
    'hiérarchise',
    'hiérarchisé',
    'hiératiques',
    'hiératismes',
    'hiéroglyphe',
    'himalayenne',
    'hindouismes',
    'hindouistes',
    'hindoustani',
    'hinterlands',
    'hipparchies',
    'hippiatries',
    'hippocampes',
    'hippodromes',
    'hippogriffe',
    'hippologies',
    'hippomobile',
    'hippopotame',
    'hippuriques',
    'hirondelles',
    'hirsutismes',
    'hispaniques',
    'hispanisant',
    'hispanismes',
    'hissassions',
    'hisseraient',
    'histogenèse',
    'histogramme',
    'histologies',
    'historiâmes',
    'historiasse',
    'historiâtes',
    'historicité',
    'historienne',
    'historierai',
    'historieras',
    'historierez',
    'historiette',
    'historiions',
    'historiques',
    'historismes',
    'hitlérienne',
    'hitlérismes',
    'hit-parades',
    'hivernaient',
    'hivernantes',
    'hivernasses',
    'hivernerais',
    'hivernerait',
    'hivernèrent',
    'hiverneriez',
    'hivernerons',
    'hiverneront',
    'hochassions',
    'hochequeues',
    'hocheraient',
    'hockeyeuses',
    'hollandaise',
    'holocaustes',
    'hologrammes',
    'holographes',
    'holographie',
    'holothuries',
    'homarderies',
    'homéopathes',
    'homéopathie',
    'homéostasie',
    'homéotherme',
    'homilétique',
    'homocerques',
    'homochromie',
    'homofocales',
    'homogénéisa',
    'homogénéise',
    'homogénéisé',
    'homogénéité',
    'homographes',
    'homogreffes',
    'homologuais',
    'homologuait',
    'homologuant',
    'homologuées',
    'homologuent',
    'homologuera',
    'homologuiez',
    'homologuons',
    'homonymique',
    'homophonies',
    'homosexuels',
    'hongrassent',
    'hongrassiez',
    'hongrerions',
    'hongroierai',
    'hongroieras',
    'hongroierez',
    'hongroierie',
    'hongroyages',
    'hongroyâmes',
    'hongroyasse',
    'hongroyâtes',
    'hongroyeurs',
    'hongroyions',
    'honnêtement',
    'honniraient',
    'honnissions',
    'honorariats',
    'honorassent',
    'honorassiez',
    'honorerions',
    'honorifique',
    'hoquetaient',
    'hoquetasses',
    'hoquetèrent',
    'hoquetterai',
    'hoquetteras',
    'hoquetterez',
    'horizontale',
    'horizontaux',
    'horlogeries',
    'hormonaient',
    'hormonasses',
    'hormonerais',
    'hormonerait',
    'hormonèrent',
    'hormoneriez',
    'hormonerons',
    'hormoneront',
    'hornblendes',
    'horodateurs',
    'horrifiâmes',
    'horrifiante',
    'horrifiants',
    'horrifiasse',
    'horrifiâtes',
    'horrifierai',
    'horrifieras',
    'horrifierez',
    'horrifiions',
    'horrifiques',
    'horripilais',
    'horripilait',
    'horripilant',
    'horripilées',
    'horripilent',
    'horripilera',
    'horripiliez',
    'horripilons',
    'horse-guard',
    'horse-power',
    'hors-la-loi',
    'hors-pistes',
    'hospitalier',
    'hospitalisa',
    'hospitalise',
    'hospitalisé',
    'hospitalité',
    'hostellerie',
    'hostilement',
    'hôtelleries',
    'hôtels-Dieu',
    'hottentotes',
    'houblonnage',
    'houblonnais',
    'houblonnait',
    'houblonnant',
    'houblonnées',
    'houblonnent',
    'houblonnera',
    'houblonnier',
    'houblonniez',
    'houblonnons',
    'houppassent',
    'houppassiez',
    'houppelande',
    'houpperions',
    'hourdassent',
    'hourdassiez',
    'hourderions',
    'hourdirions',
    'hourdissais',
    'hourdissait',
    'hourdissant',
    'hourdissent',
    'hourdissiez',
    'hourdissons',
    'houspillais',
    'houspillait',
    'houspillant',
    'houspillées',
    'houspillent',
    'houspillera',
    'houspilleur',
    'houspilliez',
    'houspillons',
    'houssassent',
    'houssassiez',
    'housserions',
    'hovercrafts',
    'huchassions',
    'hucheraient',
    'huilassions',
    'huileraient',
    'hululassent',
    'hululassiez',
    'hululements',
    'hululerions',
    'humainement',
    'humanisâmes',
    'humanisasse',
    'humanisâtes',
    'humaniserai',
    'humaniseras',
    'humaniserez',
    'humanisions',
    'humanitaire',
    'humectaient',
    'humectasses',
    'humecterais',
    'humecterait',
    'humectèrent',
    'humecteriez',
    'humecterons',
    'humecteront',
    'humidifiais',
    'humidifiait',
    'humidifiant',
    'humidifiées',
    'humidifient',
    'humidifiera',
    'humidifiiez',
    'humidifions',
    'humiliaient',
    'humiliantes',
    'humiliasses',
    'humiliation',
    'humilierais',
    'humilierait',
    'humilièrent',
    'humilieriez',
    'humilierons',
    'humilieront',
    'hurlassions',
    'hurleraient',
    'hurluberlue',
    'hurluberlus',
    'huroniennes',
    'hybridaient',
    'hybridasses',
    'hybridation',
    'hybriderais',
    'hybriderait',
    'hybridèrent',
    'hybrideriez',
    'hybriderons',
    'hybrideront',
    'hybridismes',
    'hydarthrose',
    'hydratables',
    'hydrataient',
    'hydratantes',
    'hydratasses',
    'hydratation',
    'hydraterais',
    'hydraterait',
    'hydratèrent',
    'hydrateriez',
    'hydraterons',
    'hydrateront',
    'hydraulique',
    'hydrocotyle',
    'hydrocution',
    'hydrofugeai',
    'hydrofugeas',
    'hydrofugeât',
    'hydrofugées',
    'hydrofugent',
    'hydrofugera',
    'hydrofugiez',
    'hydrogénais',
    'hydrogénait',
    'hydrogénant',
    'hydrogénées',
    'hydrogènent',
    'hydrogénera',
    'hydrogéniez',
    'hydrogénons',
    'hydrographe',
    'hydrolithes',
    'hydrologies',
    'hydrologues',
    'hydrolysais',
    'hydrolysait',
    'hydrolysant',
    'hydrolysées',
    'hydrolysent',
    'hydrolysera',
    'hydrolysiez',
    'hydrolysons',
    'hydromètres',
    'hydrométrie',
    'hydrophiles',
    'hydrophobes',
    'hydrophobie',
    'hydropiques',
    'hydropisies',
    'hydrosphère',
    'Hygiaphones',
    'hygiéniques',
    'hygiénistes',
    'hygromètres',
    'hygrométrie',
    'hygrophiles',
    'hygrophobes',
    'hygroscopes',
    'hygroscopie',
    'hylozoïsmes',
    'hyoïdiennes',
    'hyperactifs',
    'hyperactive',
    'hyperalgies',
    'hyperboréen',
    'hypercapnie',
    'hyperchrome',
    'hyperdulies',
    'hypergenèse',
    'hypermarché',
    'hypermètres',
    'hypermnésie',
    'hyperplasie',
    'hypertélies',
    'hypertendue',
    'hypertendus',
    'hypertonies',
    'hypnotiques',
    'hypnotisais',
    'hypnotisait',
    'hypnotisant',
    'hypnotisées',
    'hypnotisent',
    'hypnotisera',
    'hypnotiseur',
    'hypnotisiez',
    'hypnotismes',
    'hypnotisons',
    'hypoacousie',
    'hypocaustes',
    'hypocentres',
    'hypochromes',
    'hypocondres',
    'hypocondrie',
    'hypocrisies',
    'hypodermose',
    'hypogastres',
    'hypoglosses',
    'hypolipémie',
    'hypospadias',
    'hypostasiai',
    'hypostasias',
    'hypostasiât',
    'hypostasiée',
    'hypostasier',
    'hypostasies',
    'hypostasiés',
    'hypostasiez',
    'hyposulfite',
    'hypotendues',
    'hypotension',
    'hypoténuses',
    'hypothénars',
    'hypothéquai',
    'hypothéquas',
    'hypothéquât',
    'hypothéquée',
    'hypothéquer',
    'hypothéqués',
    'hypothèques',
    'hypothéquez',
    'hypothermie',
    'hypotonique',
    'hypotrophie',
    'hystériques',
    'ichtyocolle',
    'ichtyologie',
    'ichtyophage',
    'ichtyosaure',
    'iconoclasme',
    'iconoclaste',
    'iconographe',
    'iconolâtres',
    'iconolâtrie',
    'iconologies',
    'iconoscopes',
    'iconostases',
    'idéalisâmes',
    'idéalisasse',
    'idéalisâtes',
    'idéaliserai',
    'idéaliseras',
    'idéaliserez',
    'idéalisions',
    'identifiais',
    'identifiait',
    'identifiant',
    'identifiées',
    'identifient',
    'identifiera',
    'identifiiez',
    'identifions',
    'idéogrammes',
    'idéographie',
    'idéologique',
    'idéologisai',
    'idéologisas',
    'idéologisât',
    'idéologisée',
    'idéologiser',
    'idéologises',
    'idéologisés',
    'idéologisez',
    'idiomatique',
    'idiopathies',
    'idiotifiais',
    'idiotifiait',
    'idiotifiant',
    'idiotifiées',
    'idiotifient',
    'idiotifiera',
    'idiotifiiez',
    'idiotifions',
    'idiotisâmes',
    'idiotisasse',
    'idiotisâtes',
    'idiotiserai',
    'idiotiseras',
    'idiotiserez',
    'idiotisions',
    'idolâtrâmes',
    'idolâtrasse',
    'idolâtrâtes',
    'idolâtrerai',
    'idolâtreras',
    'idolâtrerez',
    'idolâtrions',
    'idolâtrique',
    'ignifugeais',
    'ignifugeait',
    'ignifugeant',
    'ignifugeons',
    'ignifugerai',
    'ignifugeras',
    'ignifugerez',
    'ignifugions',
    'ignoblement',
    'ignominieux',
    'ignorantins',
    'ignorassent',
    'ignorassiez',
    'ignorerions',
    'ilang-ilang',
    'iléo-caecal',
    'illégalités',
    'illégitimes',
    'illettrisme',
    'illuminâmes',
    'illuminasse',
    'illuminâtes',
    'illuminerai',
    'illumineras',
    'illuminerez',
    'illuminions',
    'illuminisme',
    'illusionnai',
    'illusionnas',
    'illusionnât',
    'illusionnée',
    'illusionner',
    'illusionnes',
    'illusionnés',
    'illusionnez',
    'illustrâmes',
    'illustrasse',
    'illustrâtes',
    'illustrerai',
    'illustreras',
    'illustrerez',
    'illustrions',
    'imageassent',
    'imageassiez',
    'imageraient',
    'imaginables',
    'imaginaient',
    'imaginaires',
    'imaginasses',
    'imaginatifs',
    'imagination',
    'imaginative',
    'imaginerais',
    'imaginerait',
    'imaginèrent',
    'imagineriez',
    'imaginerons',
    'imagineront',
    'imbattables',
    'imbécillité',
    'imbibassent',
    'imbibassiez',
    'imbiberions',
    'imbibitions',
    'imbrication',
    'imbriquâmes',
    'imbriquasse',
    'imbriquâtes',
    'imbriquerai',
    'imbriqueras',
    'imbriquerez',
    'imbriquions',
    'imitassions',
    'imitatrices',
    'imiteraient',
    'immangeable',
    'immanquable',
    'immariables',
    'immatériels',
    'immatricula',
    'immatricule',
    'immatriculé',
    'immaturités',
    'immédiateté',
    'immémoriale',
    'immémoriaux',
    'immensément',
    'immergeâmes',
    'immergeasse',
    'immergeâtes',
    'immergerais',
    'immergerait',
    'immergèrent',
    'immergeriez',
    'immergerons',
    'immergeront',
    'immettables',
    'immigraient',
    'immigrantes',
    'immigrasses',
    'immigration',
    'immigrerais',
    'immigrerait',
    'immigrèrent',
    'immigreriez',
    'immigrerons',
    'immigreront',
    'immisceriez',
    'immiscerons',
    'immobilière',
    'immobiliers',
    'immobilisai',
    'immobilisas',
    'immobilisât',
    'immobilisée',
    'immobiliser',
    'immobilises',
    'immobilisés',
    'immobilisez',
    'immobilisme',
    'immobiliste',
    'immobilités',
    'immodesties',
    'immolassent',
    'immolassiez',
    'immolations',
    'immolerions',
    'immoralisme',
    'immoraliste',
    'immoralités',
    'immortalisa',
    'immortalise',
    'immortalisé',
    'immortalité',
    'immortelles',
    'immuabilité',
    'immunisâmes',
    'immunisante',
    'immunisants',
    'immunisasse',
    'immunisâtes',
    'immuniserai',
    'immuniseras',
    'immuniserez',
    'immunisions',
    'immunitaire',
    'immunogènes',
    'immunologie',
    'impactaient',
    'impactasses',
    'impacterais',
    'impacterait',
    'impactèrent',
    'impacteriez',
    'impacterons',
    'impacteront',
    'impalpables',
    'impanations',
    'imparfaites',
    'imparipenné',
    'impartiales',
    'impartirais',
    'impartirait',
    'impartirent',
    'impartiriez',
    'impartirons',
    'impartiront',
    'impartisses',
    'impartissez',
    'impassibles',
    'impatiences',
    'impatientai',
    'impatientas',
    'impatientât',
    'impatientée',
    'impatienter',
    'impatientes',
    'impatientés',
    'impatientez',
    'impatronisa',
    'impatronise',
    'impatronisé',
    'impeachment',
    'impeccables',
    'impécunieux',
    'impedimenta',
    'impénitence',
    'impénitente',
    'impénitents',
    'impensables',
    'impératives',
    'impératrice',
    'imperdables',
    'imperfectif',
    'impérieuses',
    'imperméable',
    'impersonnel',
    'impertinent',
    'impétraient',
    'impétrantes',
    'impétrasses',
    'impétration',
    'impétrerais',
    'impétrerait',
    'impétrèrent',
    'impétreriez',
    'impétrerons',
    'impétreront',
    'impétueuses',
    'impétuosité',
    'impitoyable',
    'implacables',
    'implantâmes',
    'implantasse',
    'implantâtes',
    'implanterai',
    'implanteras',
    'implanterez',
    'implantions',
    'implémentai',
    'implémentas',
    'implémentât',
    'implémentée',
    'implémenter',
    'implémentes',
    'implémentés',
    'implémentez',
    'implication',
    'impliquâmes',
    'impliquasse',
    'impliquâtes',
    'impliquerai',
    'impliqueras',
    'impliquerez',
    'impliquions',
    'imploraient',
    'implorantes',
    'implorasses',
    'imploration',
    'implorerais',
    'implorerait',
    'implorèrent',
    'imploreriez',
    'implorerons',
    'imploreront',
    'implosaient',
    'implosasses',
    'imploserais',
    'imploserait',
    'implosèrent',
    'imploseriez',
    'imploserons',
    'imploseront',
    'impolitesse',
    'impolitique',
    'impopulaire',
    'importables',
    'importaient',
    'importances',
    'importantes',
    'importasses',
    'importateur',
    'importation',
    'importerais',
    'importerait',
    'importèrent',
    'importeriez',
    'importerons',
    'importeront',
    'importunais',
    'importunait',
    'importunant',
    'importunées',
    'importunent',
    'importunera',
    'importuniez',
    'importunité',
    'importunons',
    'imposassent',
    'imposassiez',
    'imposerions',
    'impositions',
    'impossibles',
    'imprécation',
    'imprécision',
    'imprégnâmes',
    'imprégnasse',
    'imprégnâtes',
    'imprégnerai',
    'imprégneras',
    'imprégnerez',
    'imprégnions',
    'imprenables',
    'impresarios',
    'imprésarios',
    'impressions',
    'imprévision',
    'imprévoyant',
    'imprimables',
    'imprimaient',
    'imprimantes',
    'imprimasses',
    'imprimerais',
    'imprimerait',
    'imprimèrent',
    'imprimeries',
    'imprimeriez',
    'imprimerons',
    'imprimeront',
    'improbables',
    'improbation',
    'improductif',
    'impromptues',
    'impropriété',
    'improuvable',
    'improuvâmes',
    'improuvasse',
    'improuvâtes',
    'improuverai',
    'improuveras',
    'improuverez',
    'improuvions',
    'improvisais',
    'improvisait',
    'improvisant',
    'improvisées',
    'improvisent',
    'improvisera',
    'improvisiez',
    'improvisons',
    'imprudences',
    'imprudentes',
    'impubliable',
    'impudemment',
    'impudicités',
    'impuissance',
    'impuissante',
    'impuissants',
    'impulsaient',
    'impulsasses',
    'impulserais',
    'impulserait',
    'impulsèrent',
    'impulseriez',
    'impulserons',
    'impulseront',
    'impulsivité',
    'imputassent',
    'imputassiez',
    'imputations',
    'imputerions',
    'inabordable',
    'inaccentuée',
    'inaccentués',
    'inaccomplie',
    'inaccomplis',
    'inaccoutumé',
    'inactivâmes',
    'inactivasse',
    'inactivâtes',
    'inactiverai',
    'inactiveras',
    'inactiverez',
    'inactivions',
    'inactivités',
    'inactuelles',
    'inadaptable',
    'inadéquates',
    'inaliénable',
    'inaltérable',
    'inamissible',
    'inamovibles',
    'inapaisable',
    'inappliquée',
    'inappliqués',
    'inappréciée',
    'inappréciés',
    'inapproprié',
    'inaptitudes',
    'inarticulée',
    'inarticulés',
    'inassouvies',
    'inattendues',
    'inattentifs',
    'inattention',
    'inattentive',
    'inaugurales',
    'inaugurâmes',
    'inaugurasse',
    'inaugurâtes',
    'inaugurerai',
    'inaugureras',
    'inaugurerez',
    'inaugurions',
    'inavouables',
    'incantation',
    'incapacités',
    'incarcérais',
    'incarcérait',
    'incarcérant',
    'incarcérées',
    'incarcèrent',
    'incarcérera',
    'incarcériez',
    'incarcérons',
    'incarnadine',
    'incarnadins',
    'incarnaient',
    'incarnasses',
    'incarnation',
    'incarnerais',
    'incarnerait',
    'incarnèrent',
    'incarneriez',
    'incarnerons',
    'incarneront',
    'incassables',
    'incendiaire',
    'incendiâmes',
    'incendiasse',
    'incendiâtes',
    'incendierai',
    'incendieras',
    'incendierez',
    'incendiions',
    'incertaines',
    'incertitude',
    'incessantes',
    'incessibles',
    'incestueuse',
    'inchantable',
    'inchoatives',
    'incidemment',
    'incinérâmes',
    'incinérasse',
    'incinérâtes',
    'incinérerai',
    'incinéreras',
    'incinérerez',
    'incinérions',
    'incisassent',
    'incisassiez',
    'inciserions',
    'incitassent',
    'incitassiez',
    'incitateurs',
    'incitations',
    'incitatives',
    'incitatrice',
    'inciterions',
    'incivilités',
    'inclassable',
    'inclémences',
    'inclémentes',
    'inclinables',
    'inclinaient',
    'inclinaison',
    'inclinasses',
    'inclination',
    'inclinerais',
    'inclinerait',
    'inclinèrent',
    'inclineriez',
    'inclinerons',
    'inclineront',
    'incluaisses',
    'incluraient',
    'inclussions',
    'incoercible',
    'incohérence',
    'incohérente',
    'incohérents',
    'incoiffable',
    'incollables',
    'incombaient',
    'incombasses',
    'incomberais',
    'incomberait',
    'incombèrent',
    'incomberiez',
    'incomberons',
    'incomberont',
    'incommodais',
    'incommodait',
    'incommodant',
    'incommodées',
    'incommodent',
    'incommodera',
    'incommodiez',
    'incommodité',
    'incommodons',
    'incompétent',
    'incomplètes',
    'incomprises',
    'inconduites',
    'incongruité',
    'inconscient',
    'inconsidéré',
    'inconsolées',
    'inconstance',
    'inconstante',
    'inconstants',
    'incontestée',
    'incontestés',
    'incontinent',
    'incontrôlée',
    'incontrôlés',
    'inconvenant',
    'incorporais',
    'incorporait',
    'incorporant',
    'incorporées',
    'incorporels',
    'incorporent',
    'incorporera',
    'incorporiez',
    'incorporons',
    'incorrectes',
    'incrédulité',
    'incrémentai',
    'incrémentas',
    'incrémentât',
    'incrémentée',
    'incrémenter',
    'incrémentes',
    'incrémentés',
    'incrémentez',
    'increvables',
    'incriminais',
    'incriminait',
    'incriminant',
    'incriminées',
    'incriminent',
    'incriminera',
    'incriminiez',
    'incriminons',
    'incroyables',
    'incroyances',
    'incroyantes',
    'incrustâmes',
    'incrustante',
    'incrustants',
    'incrustasse',
    'incrustâtes',
    'incrusterai',
    'incrusteras',
    'incrusterez',
    'incrusteurs',
    'incrusteuse',
    'incrustions',
    'incubassent',
    'incubassiez',
    'incubateurs',
    'incubations',
    'incubatrice',
    'incuberions',
    'inculcation',
    'inculpables',
    'inculpaient',
    'inculpasses',
    'inculpation',
    'inculperais',
    'inculperait',
    'inculpèrent',
    'inculperiez',
    'inculperons',
    'inculperont',
    'inculquâmes',
    'inculquasse',
    'inculquâtes',
    'inculquerai',
    'inculqueras',
    'inculquerez',
    'inculquions',
    'incultivées',
    'incurieuses',
    'incuriosité',
    'incurvaient',
    'incurvasses',
    'incurvation',
    'incurverais',
    'incurverait',
    'incurvèrent',
    'incurveriez',
    'incurverons',
    'incurveront',
    'indécelable',
    'indécemment',
    'indécidable',
    'indécisions',
    'indélébiles',
    'indélibérée',
    'indélibérés',
    'indélicates',
    'indemnisais',
    'indemnisait',
    'indemnisant',
    'indemnisées',
    'indemnisent',
    'indemnisera',
    'indemnisiez',
    'indemnisons',
    'indémodable',
    'indéniables',
    'indentation',
    'indépendant',
    'indésirable',
    'indéterminé',
    'indexassent',
    'indexassiez',
    'indexations',
    'indexerions',
    'indianismes',
    'indianistes',
    'indiçassent',
    'indiçassiez',
    'indicateurs',
    'indications',
    'indicatives',
    'indicatrice',
    'indicerions',
    'indiciaires',
    'indicielles',
    'indifférais',
    'indifférait',
    'indifférant',
    'indifférées',
    'indifférent',
    'indiffèrent',
    'indifférera',
    'indiffériez',
    'indifférons',
    'indigestion',
    'indignaient',
    'indignasses',
    'indignation',
    'indignement',
    'indignerais',
    'indignerait',
    'indignèrent',
    'indigneriez',
    'indignerons',
    'indigneront',
    'indigotiers',
    'indigotines',
    'indiquaient',
    'indiquasses',
    'indiquerais',
    'indiquerait',
    'indiquèrent',
    'indiqueriez',
    'indiquerons',
    'indiqueront',
    'indiscrètes',
    'indiscutées',
    'indisposais',
    'indisposait',
    'indisposant',
    'indisposées',
    'indisposent',
    'indisposera',
    'indisposiez',
    'indisposons',
    'indistincte',
    'indistincts',
    'individuels',
    'indivisaire',
    'indivisible',
    'indivisions',
    'in-dix-huit',
    'indochinois',
    'indocilités',
    'indolemment',
    'indomptable',
    'indonésiens',
    'indubitable',
    'inductrices',
    'induiraient',
    'induisaient',
    'induisirent',
    'induisisses',
    'indulgences',
    'indulgencia',
    'indulgencie',
    'indulgencié',
    'indulgentes',
    'indurassent',
    'indurassiez',
    'indurations',
    'indurerions',
    'industriels',
    'industrieux',
    'inécoutable',
    'ineffaçable',
    'inefficaces',
    'inégalables',
    'inégalement',
    'inélastique',
    'inélégances',
    'inélégantes',
    'inéligibles',
    'inéluctable',
    'inemployées',
    'inénarrable',
    'inéprouvées',
    'inépuisable',
    'inéquitable',
    'inertielles',
    'inestimable',
    'inévitables',
    'inexcusable',
    'inexécutées',
    'inexécution',
    'inexistante',
    'inexistants',
    'inexistence',
    'inexorables',
    'inexpiables',
    'inexpliquée',
    'inexpliqués',
    'inexploitée',
    'inexploités',
    'inexplorées',
    'inexpressif',
    'inexprimées',
    'infaillible',
    'infaisables',
    'infanteries',
    'infanticide',
    'infantilisa',
    'infantilise',
    'infantilisé',
    'infatigable',
    'infatuaient',
    'infatuasses',
    'infatuation',
    'infatuerais',
    'infatuerait',
    'infatuèrent',
    'infatueriez',
    'infatuerons',
    'infatueront',
    'infécondité',
    'infectaient',
    'infectantes',
    'infectasses',
    'infecterais',
    'infecterait',
    'infectèrent',
    'infecteriez',
    'infecterons',
    'infecteront',
    'infectieuse',
    'inféodaient',
    'inféodasses',
    'inféoderais',
    'inféoderait',
    'inféodèrent',
    'inféoderiez',
    'inféoderons',
    'inféoderont',
    'inférassent',
    'inférassiez',
    'inférerions',
    'inférieures',
    'infériorisa',
    'infériorise',
    'infériorisé',
    'infériorité',
    'infertilité',
    'infestaient',
    'infestasses',
    'infestation',
    'infesterais',
    'infesterait',
    'infestèrent',
    'infesteriez',
    'infesterons',
    'infesteront',
    'infeutrable',
    'infidélités',
    'infiltrâmes',
    'infiltrasse',
    'infiltrâtes',
    'infiltrerai',
    'infiltreras',
    'infiltrerez',
    'infiltrions',
    'infinitives',
    'infinitudes',
    'infirmaient',
    'infirmasses',
    'infirmatifs',
    'infirmation',
    'infirmative',
    'infirmerais',
    'infirmerait',
    'infirmèrent',
    'infirmeries',
    'infirmeriez',
    'infirmerons',
    'infirmeront',
    'infirmières',
    'inflammable',
    'infléchîmes',
    'infléchirai',
    'infléchiras',
    'infléchirez',
    'infléchisse',
    'infléchîtes',
    'inflexibles',
    'infligeâmes',
    'infligeasse',
    'infligeâtes',
    'infligerais',
    'infligerait',
    'infligèrent',
    'infligeriez',
    'infligerons',
    'infligeront',
    'influassent',
    'influassiez',
    'influençais',
    'influençait',
    'influençant',
    'influencées',
    'influencent',
    'influencera',
    'influenciez',
    'influençons',
    'influerions',
    'infographie',
    'informaient',
    'informasses',
    'informateur',
    'informatifs',
    'information',
    'informatisa',
    'informatise',
    'informatisé',
    'informative',
    'informelles',
    'informerais',
    'informerait',
    'informèrent',
    'informeriez',
    'informerons',
    'informeront',
    'informulées',
    'infortunées',
    'infractions',
    'infrarouges',
    'infructueux',
    'infusassent',
    'infusassiez',
    'infuserions',
    'ingagnables',
    'ingénieries',
    'ingénieriez',
    'ingénierons',
    'ingénieuses',
    'ingéniosité',
    'ingérassent',
    'ingérassiez',
    'ingérerions',
    'ingratement',
    'ingratitude',
    'ingrédients',
    'ingressions',
    'ingurgitais',
    'ingurgitait',
    'ingurgitant',
    'ingurgitées',
    'ingurgitent',
    'ingurgitera',
    'ingurgitiez',
    'ingurgitons',
    'inhabiletés',
    'inhabilités',
    'inhabitable',
    'inhabituels',
    'inhalassent',
    'inhalassiez',
    'inhalateurs',
    'inhalations',
    'inhalatrice',
    'inhalerions',
    'inhibassent',
    'inhibassiez',
    'inhiberions',
    'inhibiteurs',
    'inhibitions',
    'inhibitives',
    'inhibitrice',
    'inhumanités',
    'inhumassent',
    'inhumassiez',
    'inhumations',
    'inhumerions',
    'inimitables',
    'initialisai',
    'initialisas',
    'initialisât',
    'initialisée',
    'initialiser',
    'initialises',
    'initialisés',
    'initialisez',
    'initiassent',
    'initiassiez',
    'initiateurs',
    'initiations',
    'initiatique',
    'initiatives',
    'initiatrice',
    'initierions',
    'injectables',
    'injectaient',
    'injectasses',
    'injecterais',
    'injecterait',
    'injectèrent',
    'injecteriez',
    'injecterons',
    'injecteront',
    'injonctions',
    'injonctives',
    'injuriaient',
    'injuriasses',
    'injurierais',
    'injurierait',
    'injurièrent',
    'injurieriez',
    'injurierons',
    'injurieront',
    'injurieuses',
    'injustement',
    'injustifiée',
    'injustifiés',
    'inlassables',
    'innervaient',
    'innervasses',
    'innervation',
    'innerverais',
    'innerverait',
    'innervèrent',
    'innerveriez',
    'innerverons',
    'innerveront',
    'innocemment',
    'innocentais',
    'innocentait',
    'innocentant',
    'innocentées',
    'innocentent',
    'innocentera',
    'innocentiez',
    'innocentons',
    'innombrable',
    'innommables',
    'innovassent',
    'innovassiez',
    'innovateurs',
    'innovations',
    'innovatrice',
    'innoverions',
    'inobservées',
    'inoculables',
    'inoculaient',
    'inoculasses',
    'inoculation',
    'inoculerais',
    'inoculerait',
    'inoculèrent',
    'inoculeriez',
    'inoculerons',
    'inoculeront',
    'inoffensifs',
    'inoffensive',
    'inondassent',
    'inondassiez',
    'inondations',
    'inonderions',
    'inopérables',
    'inopérantes',
    'inopinément',
    'inopportune',
    'inopportuns',
    'inopposable',
    'inorganique',
    'inorganisée',
    'inorganisés',
    'inoubliable',
    'inoxydables',
    'inquiétâmes',
    'inquiétante',
    'inquiétants',
    'inquiétasse',
    'inquiétâtes',
    'inquiéterai',
    'inquiéteras',
    'inquiéterez',
    'inquiétions',
    'inquiétudes',
    'inquisiteur',
    'inquisition',
    'insalubrité',
    'insatiables',
    'insatisfait',
    'inscription',
    'inscririons',
    'inscrivante',
    'inscrivants',
    'inscrivîmes',
    'inscrivions',
    'inscrivisse',
    'inscrivîtes',
    'insculpâmes',
    'insculpasse',
    'insculpâtes',
    'insculperai',
    'insculperas',
    'insculperez',
    'insculpions',
    'insectarium',
    'insecticide',
    'insectifuge',
    'insectivore',
    'insécurités',
    'inséminâmes',
    'inséminasse',
    'inséminâtes',
    'inséminerai',
    'insémineras',
    'inséminerez',
    'inséminions',
    'insensibles',
    'inséparable',
    'insérassent',
    'insérassiez',
    'insérerions',
    'insermentés',
    'insidieuses',
    'insinuaient',
    'insinuantes',
    'insinuasses',
    'insinuation',
    'insinuerais',
    'insinuerait',
    'insinuèrent',
    'insinueriez',
    'insinuerons',
    'insinueront',
    'insipidités',
    'insistaient',
    'insistances',
    'insistantes',
    'insistasses',
    'insisterais',
    'insisterait',
    'insistèrent',
    'insisteriez',
    'insisterons',
    'insisteront',
    'insociables',
    'insolassent',
    'insolassiez',
    'insolations',
    'insolemment',
    'insolerions',
    'insolvables',
    'insomniaque',
    'insomnieuse',
    'insondables',
    'insonorisai',
    'insonorisas',
    'insonorisât',
    'insonorisée',
    'insonoriser',
    'insonorises',
    'insonorisés',
    'insonorisez',
    'insonorités',
    'insouciance',
    'insouciante',
    'insouciants',
    'insoucieuse',
    'insoupçonné',
    'inspectâmes',
    'inspectasse',
    'inspectâtes',
    'inspecterai',
    'inspecteras',
    'inspecterez',
    'inspecteurs',
    'inspections',
    'inspectorat',
    'inspectrice',
    'inspiraient',
    'inspirantes',
    'inspirasses',
    'inspirateur',
    'inspiration',
    'inspirerais',
    'inspirerait',
    'inspirèrent',
    'inspireriez',
    'inspirerons',
    'inspireront',
    'instabilité',
    'installâmes',
    'installasse',
    'installâtes',
    'installerai',
    'installeras',
    'installerez',
    'installions',
    'instantanée',
    'instantanés',
    'instaurâmes',
    'instaurasse',
    'instaurâtes',
    'instaurerai',
    'instaureras',
    'instaurerez',
    'instaurions',
    'instigateur',
    'instigation',
    'instiguâmes',
    'instiguasse',
    'instiguâtes',
    'instiguerai',
    'instigueras',
    'instiguerez',
    'instiguions',
    'instillâmes',
    'instillasse',
    'instillâtes',
    'instillerai',
    'instilleras',
    'instillerez',
    'instillions',
    'instinctifs',
    'instinctive',
    'instinctuel',
    'instituâmes',
    'instituasse',
    'instituâtes',
    'instituerai',
    'institueras',
    'instituerez',
    'instituions',
    'instituteur',
    'institution',
    'instructeur',
    'instructifs',
    'instruction',
    'instructive',
    'instruirais',
    'instruirait',
    'instruiriez',
    'instruirons',
    'instruiront',
    'instruisais',
    'instruisait',
    'instruisant',
    'instruisent',
    'instruisiez',
    'instruisons',
    'instrumenta',
    'instrumente',
    'instrumenté',
    'instruments',
    'insuffisant',
    'insufflâmes',
    'insufflasse',
    'insufflâtes',
    'insufflerai',
    'insuffleras',
    'insufflerez',
    'insufflions',
    'insularités',
    'insulinases',
    'insultaient',
    'insultantes',
    'insultasses',
    'insulterais',
    'insulterait',
    'insultèrent',
    'insulteriez',
    'insulterons',
    'insulteront',
    'insurgeâmes',
    'insurgeâtes',
    'insurgeriez',
    'insurgerons',
    'intaillâmes',
    'intaillasse',
    'intaillâtes',
    'intaillerai',
    'intailleras',
    'intaillerez',
    'intaillions',
    'intangibles',
    'intégraient',
    'intégralité',
    'intégrantes',
    'intégrasses',
    'intégrateur',
    'intégration',
    'intégrerais',
    'intégrerait',
    'intégrèrent',
    'intégreriez',
    'intégrerons',
    'intégreront',
    'intégrismes',
    'intégristes',
    'intelligent',
    'intempérant',
    'intempéries',
    'intempestif',
    'intemporels',
    'intendances',
    'intendantes',
    'intensément',
    'intensifiai',
    'intensifias',
    'intensifiât',
    'intensifiée',
    'intensifier',
    'intensifies',
    'intensifiés',
    'intensifiez',
    'intentaient',
    'intentasses',
    'intenterais',
    'intenterait',
    'intentèrent',
    'intenteriez',
    'intenterons',
    'intenteront',
    'intentionné',
    'interactifs',
    'interaction',
    'interactive',
    'interalliée',
    'interalliés',
    'interarmées',
    'interastral',
    'intercalais',
    'intercalait',
    'intercalant',
    'intercalées',
    'intercalent',
    'intercalera',
    'intercaliez',
    'intercalons',
    'intercédais',
    'intercédait',
    'intercédant',
    'intercèdent',
    'intercédera',
    'intercédiez',
    'intercédons',
    'interceptai',
    'interceptas',
    'interceptât',
    'interceptée',
    'intercepter',
    'interceptes',
    'interceptés',
    'interceptez',
    'interclassa',
    'interclasse',
    'interclassé',
    'intercostal',
    'intercourse',
    'interdirais',
    'interdirait',
    'interdirent',
    'interdiriez',
    'interdirons',
    'interdiront',
    'interdisais',
    'interdisait',
    'interdisant',
    'interdisent',
    'interdisiez',
    'interdisons',
    'interdisses',
    'intéressais',
    'intéressait',
    'intéressant',
    'intéressées',
    'intéressent',
    'intéressera',
    'intéressiez',
    'intéressons',
    'interférais',
    'interférait',
    'interférant',
    'interfèrent',
    'interférera',
    'interfériez',
    'interférons',
    'interfluves',
    'interfoliai',
    'interfolias',
    'interfoliât',
    'interfoliée',
    'interfolier',
    'interfolies',
    'interfoliés',
    'interfoliez',
    'intergroupe',
    'intérieures',
    'intérimaire',
    'intériorisa',
    'intériorise',
    'intériorisé',
    'intériorité',
    'interjectif',
    'interjetais',
    'interjetait',
    'interjetant',
    'interjetées',
    'interjetiez',
    'interjetons',
    'interjettes',
    'interlignai',
    'interlignas',
    'interlignât',
    'interlignée',
    'interligner',
    'interlignes',
    'interlignés',
    'interlignez',
    'interloquai',
    'interloquas',
    'interloquât',
    'interloquée',
    'interloquer',
    'interloques',
    'interloqués',
    'interloquez',
    'internaient',
    'internasses',
    'internement',
    'internerais',
    'internerait',
    'internèrent',
    'interneriez',
    'internerons',
    'interneront',
    'internonces',
    'interosseux',
    'interpellai',
    'interpellas',
    'interpellât',
    'interpellée',
    'interpeller',
    'interpelles',
    'interpellés',
    'interpellez',
    'interphones',
    'interpolais',
    'interpolait',
    'interpolant',
    'interpolées',
    'interpolent',
    'interpolera',
    'interpoliez',
    'interpolons',
    'interposais',
    'interposait',
    'interposant',
    'interposées',
    'interposent',
    'interposera',
    'interposiez',
    'interposons',
    'interprétai',
    'interprétas',
    'interprétât',
    'interprétée',
    'interpréter',
    'interprétés',
    'interprètes',
    'interprétez',
    'interrègnes',
    'interrogeai',
    'interrogeas',
    'interrogeât',
    'interrogées',
    'interrogent',
    'interrogera',
    'interrogiez',
    'interrompes',
    'interrompez',
    'interrompis',
    'interrompit',
    'interrompît',
    'interrompra',
    'interrompre',
    'interrompue',
    'interrompus',
    'interruptif',
    'intersexuée',
    'intersexuel',
    'intersexués',
    'intersignes',
    'interstices',
    'interurbain',
    'intervalles',
    'intervenais',
    'intervenait',
    'intervenant',
    'interveniez',
    'intervenons',
    'intervenues',
    'intervertie',
    'intervertir',
    'intervertis',
    'intervertit',
    'intervertît',
    'intervienne',
    'interviewai',
    'interviewas',
    'interviewât',
    'interviewée',
    'interviewer',
    'interviewes',
    'interviewés',
    'interviewez',
    'intervînmes',
    'intervinsse',
    'intervîntes',
    'interzonale',
    'interzonaux',
    'intestinale',
    'intestinaux',
    'intimassent',
    'intimassiez',
    'intimations',
    'intimerions',
    'intimidable',
    'intimidâmes',
    'intimidante',
    'intimidants',
    'intimidasse',
    'intimidâtes',
    'intimiderai',
    'intimideras',
    'intimiderez',
    'intimidions',
    'intitulâmes',
    'intitulasse',
    'intitulâtes',
    'intitulerai',
    'intituleras',
    'intitulerez',
    'intitulions',
    'intolérable',
    'intolérance',
    'intolérante',
    'intolérants',
    'intonations',
    'intouchable',
    'intoxicante',
    'intoxicants',
    'intoxiquais',
    'intoxiquait',
    'intoxiquant',
    'intoxiquées',
    'intoxiquent',
    'intoxiquera',
    'intoxiquiez',
    'intoxiquons',
    'intraitable',
    'intra-muros',
    'intransitif',
    'intrépidité',
    'intrication',
    'intrigantes',
    'intriguâmes',
    'intriguasse',
    'intriguâtes',
    'intriguerai',
    'intrigueras',
    'intriguerez',
    'intriguions',
    'intrinsèque',
    'intriquâmes',
    'intriquasse',
    'intriquâtes',
    'intriquerai',
    'intriqueras',
    'intriquerez',
    'intriquions',
    'introductif',
    'introduirai',
    'introduiras',
    'introduirez',
    'introduises',
    'introduisez',
    'introduisis',
    'introduisit',
    'introduisît',
    'introduites',
    'intronisais',
    'intronisait',
    'intronisant',
    'intronisées',
    'intronisent',
    'intronisera',
    'intronisiez',
    'intronisons',
    'introuvable',
    'introvertie',
    'introvertis',
    'intubassent',
    'intubassiez',
    'intubations',
    'intuberions',
    'inutilement',
    'inutilisées',
    'invaginâmes',
    'invaginasse',
    'invaginâtes',
    'invaginerai',
    'invagineras',
    'invaginerez',
    'invaginions',
    'invalidâmes',
    'invalidante',
    'invalidants',
    'invalidasse',
    'invalidâtes',
    'invaliderai',
    'invalideras',
    'invaliderez',
    'invalidions',
    'invalidités',
    'invariables',
    'invariantes',
    'invectivais',
    'invectivait',
    'invectivant',
    'invectivées',
    'invectivent',
    'invectivera',
    'invectiviez',
    'invectivons',
    'invendables',
    'inventaient',
    'inventaires',
    'inventasses',
    'inventerais',
    'inventerait',
    'inventèrent',
    'inventeriez',
    'inventerons',
    'inventeront',
    'inventivité',
    'inventoriai',
    'inventorias',
    'inventoriât',
    'inventoriée',
    'inventorier',
    'inventories',
    'inventoriés',
    'inventoriez',
    'inventrices',
    'inversables',
    'inversaient',
    'inversasses',
    'inversement',
    'inverserais',
    'inverserait',
    'inversèrent',
    'inverseriez',
    'inverserons',
    'inverseront',
    'inversibles',
    'invertébrée',
    'invertébrés',
    'invertirais',
    'invertirait',
    'invertirent',
    'invertiriez',
    'invertirons',
    'invertiront',
    'invertisses',
    'invertissez',
    'investirais',
    'investirait',
    'investirent',
    'investiriez',
    'investirons',
    'investiront',
    'investisses',
    'investissez',
    'investiture',
    'invétérâmes',
    'invétérâtes',
    'invétérerez',
    'invétérions',
    'invincibles',
    'inviolables',
    'invitassent',
    'invitassiez',
    'invitations',
    'invitatoire',
    'inviterions',
    'invocations',
    'invocatoire',
    'involucrées',
    'invoquaient',
    'invoquasses',
    'invoquerais',
    'invoquerait',
    'invoquèrent',
    'invoqueriez',
    'invoquerons',
    'invoqueront',
    'iodhydrique',
    'iodlassions',
    'iodleraient',
    'ionisassent',
    'ionisassiez',
    'ionisations',
    'ioniserions',
    'ionosphères',
    'ioulassions',
    'iouleraient',
    'iridectomie',
    'iridescente',
    'iridescents',
    'irisassions',
    'iriseraient',
    'irlandaises',
    'ironisaient',
    'ironisasses',
    'ironiserais',
    'ironiserait',
    'ironisèrent',
    'ironiseriez',
    'ironiserons',
    'ironiseront',
    'irradiaient',
    'irradiantes',
    'irradiasses',
    'irradiation',
    'irradierais',
    'irradierait',
    'irradièrent',
    'irradieriez',
    'irradierons',
    'irradieront',
    'irraisonnée',
    'irraisonnés',
    'irrationnel',
    'irréalisées',
    'irréalismes',
    'irréalistes',
    'irrecevable',
    'irrécusable',
    'irréfléchie',
    'irréfléchis',
    'irréflexion',
    'irréfutable',
    'irrégulière',
    'irréguliers',
    'irréligieux',
    'irréligions',
    'irréparable',
    'irrévérence',
    'irrévocable',
    'irrigations',
    'irriguaient',
    'irriguasses',
    'irriguerais',
    'irriguerait',
    'irriguèrent',
    'irrigueriez',
    'irriguerons',
    'irrigueront',
    'irritassent',
    'irritassiez',
    'irritations',
    'irritatives',
    'irriterions',
    'isallobares',
    'ischémiques',
    'ischiatique',
    'islamisâmes',
    'islamisasse',
    'islamisâtes',
    'islamiserai',
    'islamiseras',
    'islamiserez',
    'islamisions',
    'islandaises',
    'isoclinales',
    'isodynamies',
    'isolassions',
    'isoleraient',
    'isotoniques',
    'isotopiques',
    'israélienne',
    'italianisai',
    'italianisas',
    'italianisât',
    'italianisée',
    'italianiser',
    'italianises',
    'italianisés',
    'italianisez',
    'italianisme',
    'itérassions',
    'itéreraient',
    'itinéraires',
    'itinérantes',
    'ivoiriennes',
    'ivrogneries',
    'jablassions',
    'jableraient',
    'jabotassent',
    'jabotassiez',
    'jaboterions',
    'jacassaient',
    'jacassantes',
    'jacassasses',
    'jacassement',
    'jacasserais',
    'jacasserait',
    'jacassèrent',
    'jacasseries',
    'jacasseriez',
    'jacasserons',
    'jacasseront',
    'jacasseuses',
    'jacobinisme',
    'jactassions',
    'jacteraient',
    'jaculatoire',
    'jaillirions',
    'jaillissais',
    'jaillissait',
    'jaillissant',
    'jaillissent',
    'jaillissiez',
    'jaillissons',
    'jalonnaient',
    'jalonnasses',
    'jalonnement',
    'jalonnerais',
    'jalonnerait',
    'jalonnèrent',
    'jalonneriez',
    'jalonnerons',
    'jalonneront',
    'jalousaient',
    'jalousasses',
    'jalousement',
    'jalouserais',
    'jalouserait',
    'jalousèrent',
    'jalouseriez',
    'jalouserons',
    'jalouseront',
    'jamaïquaine',
    'jamaïquains',
    'jambonneaux',
    'jamerosiers',
    'jam-session',
    'janissaires',
    'jansénismes',
    'jansénistes',
    'japonisante',
    'japonisants',
    'japonnaient',
    'japonnasses',
    'japonnerais',
    'japonnerait',
    'japonnèrent',
    'japonneriez',
    'japonnerons',
    'japonneront',
    'jappassions',
    'japperaient',
    'jardinaient',
    'jardinasses',
    'jardinerais',
    'jardinerait',
    'jardinèrent',
    'jardineriez',
    'jardinerons',
    'jardineront',
    'jardineuses',
    'jardinières',
    'jardinistes',
    'jaretterais',
    'jaretterait',
    'jaretteriez',
    'jaretterons',
    'jaretteront',
    'jargonnâmes',
    'jargonnasse',
    'jargonnâtes',
    'jargonnerai',
    'jargonneras',
    'jargonnerez',
    'jargonnions',
    'jarretaient',
    'jarretasses',
    'jarretelles',
    'jarretèrent',
    'jarretières',
    'jaspassions',
    'jasperaient',
    'jaspinaient',
    'jaspinasses',
    'jaspinerais',
    'jaspinerait',
    'jaspinèrent',
    'jaspineriez',
    'jaspinerons',
    'jaspineront',
    'jaugeassent',
    'jaugeassiez',
    'jaugeraient',
    'jauniraient',
    'jaunissages',
    'jaunissante',
    'jaunissants',
    'jaunissions',
    'javelassent',
    'javelassiez',
    'javellerais',
    'javellerait',
    'javelleriez',
    'javellerons',
    'javelleront',
    'javellisais',
    'javellisait',
    'javellisant',
    'javellisées',
    'javellisent',
    'javellisera',
    'javellisiez',
    'javellisons',
    'jean-foutre',
    'jennérienne',
    'jerkassions',
    'jerkeraient',
    'jésuitiques',
    'jésuitismes',
    'jetteraient',
    'jeûnassions',
    'jeûneraient',
    'joailleries',
    'joaillières',
    'jobardaient',
    'jobardasses',
    'jobarderais',
    'jobarderait',
    'jobardèrent',
    'jobarderies',
    'jobarderiez',
    'jobarderons',
    'jobarderont',
    'jodlassions',
    'jodleraient',
    'johanniques',
    'joignissent',
    'joignissiez',
    'joindraient',
    'jointoierai',
    'jointoieras',
    'jointoierez',
    'jointoyâmes',
    'jointoyasse',
    'jointoyâtes',
    'jointoyeurs',
    'jonçassions',
    'jonceraient',
    'jonchassent',
    'jonchassiez',
    'joncherions',
    'jonglassent',
    'jonglassiez',
    'jonglerions',
    'jontoyaient',
    'jordanienne',
    'jouaillâmes',
    'jouaillasse',
    'jouaillâtes',
    'jouaillerai',
    'jouailleras',
    'jouaillerez',
    'jouaillions',
    'jouissaient',
    'jouissances',
    'jouissantes',
    'jouisseuses',
    'journalière',
    'journaliers',
    'journalisme',
    'journaliste',
    'joutassions',
    'jouteraient',
    'jouvenceaux',
    'jouvencelle',
    'jouxtassent',
    'jouxtassiez',
    'jouxterions',
    'jovialement',
    'joyeusement',
    'jubilassent',
    'jubilassiez',
    'jubilations',
    'jubilerions',
    'juchassions',
    'jucheraient',
    'judicatures',
    'judiciaires',
    'judicieuses',
    'jugeassions',
    'jugulassent',
    'jugulassiez',
    'jugulerions',
    'jumelassent',
    'jumelassiez',
    'jumellerais',
    'jumellerait',
    'jumelleriez',
    'jumellerons',
    'jumelleront',
    'junoniennes',
    'jupitériens',
    'juponnaient',
    'juponnasses',
    'juponnerais',
    'juponnerait',
    'juponnèrent',
    'juponneriez',
    'juponnerons',
    'juponneront',
    'jurassienne',
    'jurassiques',
    'juridiction',
    'justaucorps',
    'justiciable',
    'justicières',
    'justifiable',
    'justifiâmes',
    'justifiante',
    'justifiants',
    'justifiasse',
    'justifiâtes',
    'justifierai',
    'justifieras',
    'justifierez',
    'justifiions',
    'juvénilités',
    'juxtaposais',
    'juxtaposait',
    'juxtaposant',
    'juxtaposées',
    'juxtaposent',
    'juxtaposera',
    'juxtaposiez',
    'juxtaposons',
    'kafkaïennes',
    'karpatiques',
    'kératotomie',
    'keynésienne',
    'khédiviales',
    'kichenottes',
    'kidnappages',
    'kidnappâmes',
    'kidnappasse',
    'kidnappâtes',
    'kidnapperai',
    'kidnapperas',
    'kidnapperez',
    'kidnappeurs',
    'kidnappeuse',
    'kidnappings',
    'kidnappions',
    'kieselguhrs',
    'kilocalorie',
    'kilogrammes',
    'kilométrage',
    'kilométrais',
    'kilométrait',
    'kilométrant',
    'kilométrées',
    'kilomètrent',
    'kilométrera',
    'kilométriez',
    'kilométrons',
    'kinesthésie',
    'kitchenette',
    'klaxonnâmes',
    'klaxonnasse',
    'klaxonnâtes',
    'klaxonnerai',
    'klaxonneras',
    'klaxonnerez',
    'klaxonnions',
    'kleptomanes',
    'kleptomanie',
    'kolkhoziens',
    'kymographes',
    'kymographie',
    'labialisais',
    'labialisait',
    'labialisant',
    'labialisées',
    'labialisent',
    'labialisera',
    'labialisiez',
    'labialisons',
    'laborantine',
    'laborantins',
    'laboratoire',
    'laborieuses',
    'labourables',
    'labouraient',
    'labourasses',
    'labourerais',
    'labourerait',
    'labourèrent',
    'laboureriez',
    'labourerons',
    'laboureront',
    'labyrinthes',
    'laccolithes',
    'lacérassent',
    'lacérassiez',
    'lacérations',
    'lacérerions',
    'lâchassions',
    'lâcheraient',
    'lacrymogène',
    'lactescence',
    'lactescente',
    'lactescents',
    'lactomètres',
    'lactosérums',
    'lactucarium',
    'lagotriches',
    'laïcisaient',
    'laïcisasses',
    'laïcisation',
    'laïciserais',
    'laïciserait',
    'laïcisèrent',
    'laïciseriez',
    'laïciserons',
    'laïciseront',
    'lainassions',
    'laineraient',
    'laissassent',
    'laissassiez',
    'laisserions',
    'laitonnâmes',
    'laitonnasse',
    'laitonnâtes',
    'laitonnerai',
    'laitonneras',
    'laitonnerez',
    'laitonnions',
    'laïusseuses',
    'lamarckisme',
    'lambdacisme',
    'lambinaient',
    'lambinasses',
    'lambinerais',
    'lambinerait',
    'lambinèrent',
    'lambineriez',
    'lambinerons',
    'lambineront',
    'lambrequins',
    'lambrissage',
    'lambrissais',
    'lambrissait',
    'lambrissant',
    'lambrissées',
    'lambrissent',
    'lambrissera',
    'lambrissiez',
    'lambrissons',
    'lambrusques',
    'lamellaires',
    'lamelleuses',
    'lamentables',
    'lamentaient',
    'lamentasses',
    'lamentation',
    'lamenterais',
    'lamenterait',
    'lamentèrent',
    'lamenteriez',
    'lamenterons',
    'lamenteront',
    'laminassent',
    'laminassiez',
    'laminerions',
    'lampadaires',
    'lampassions',
    'lamperaient',
    'lampisterie',
    'lamprillons',
    'lançassions',
    'lanceraient',
    'lancinaient',
    'lancinantes',
    'lancinasses',
    'lancinerais',
    'lancinerait',
    'lancinèrent',
    'lancineriez',
    'lancinerons',
    'lancineront',
    'landgraviat',
    'langagières',
    'langeassent',
    'langeassiez',
    'langeraient',
    'langoureuse',
    'langoustier',
    'langoustine',
    'langueyages',
    'langueyâmes',
    'langueyasse',
    'langueyâtes',
    'langueyerai',
    'langueyeras',
    'langueyerez',
    'langueyions',
    'languirions',
    'languissais',
    'languissait',
    'languissant',
    'languissent',
    'languissiez',
    'languissons',
    'lansquenets',
    'lanternâmes',
    'lanternasse',
    'lanternâtes',
    'lanterneaux',
    'lanternerai',
    'lanterneras',
    'lanternerez',
    'lanternions',
    'lanugineuse',
    'lapalissade',
    'lapidassent',
    'lapidassiez',
    'lapidations',
    'lapiderions',
    'laquassions',
    'laqueraient',
    'lardassions',
    'larderaient',
    'lardonnâmes',
    'lardonnasse',
    'lardonnâtes',
    'lardonnerai',
    'lardonneras',
    'lardonnerez',
    'lardonnions',
    'larguassent',
    'larguassiez',
    'larguerions',
    'larmoiement',
    'larmoierais',
    'larmoierait',
    'larmoieriez',
    'larmoierons',
    'larmoieront',
    'larmoyaient',
    'larmoyantes',
    'larmoyasses',
    'larmoyèrent',
    'laryngienne',
    'lascivement',
    'lassassions',
    'lasseraient',
    'latéralités',
    'latéritique',
    'laticifères',
    'latifoliées',
    'latifundium',
    'latinisâmes',
    'latinisasse',
    'latinisâtes',
    'latiniserai',
    'latiniseras',
    'latiniserez',
    'latinisions',
    'lattassions',
    'latteraient',
    'laudatrices',
    'lavallières',
    'lavandières',
    'lave-glaces',
    'lawn-tennis',
    'lawrenciums',
    'leaderships',
    'léchassions',
    'lèchefrites',
    'lécheraient',
    'légalisâmes',
    'légalisante',
    'légalisants',
    'légalisasse',
    'légalisâtes',
    'légaliserai',
    'légaliseras',
    'légaliserez',
    'légalisions',
    'légendaires',
    'légiférâmes',
    'légiférasse',
    'légiférâtes',
    'légiférerai',
    'légiféreras',
    'légiférerez',
    'légiférions',
    'légionnaire',
    'législateur',
    'législatifs',
    'législation',
    'législative',
    'législature',
    'légitimâmes',
    'légitimasse',
    'légitimâtes',
    'légitimerai',
    'légitimeras',
    'légitimerez',
    'légitimions',
    'légitimiste',
    'légitimités',
    'léguassions',
    'légueraient',
    'légumineuse',
    'leishmanies',
    'lénifiaient',
    'lénifiantes',
    'lénifiasses',
    'lénifierais',
    'lénifierait',
    'lénifièrent',
    'lénifieriez',
    'lénifierons',
    'lénifieront',
    'lenticelles',
    'lentiformes',
    'lépidostées',
    'léprologies',
    'léprologues',
    'léproseries',
    'lesbianisme',
    'lésinassent',
    'lésinassiez',
    'lésinerions',
    'lésionnaire',
    'lésionnelle',
    'lessivables',
    'lessivaient',
    'lessivasses',
    'lessiverais',
    'lessiverait',
    'lessivèrent',
    'lessiveriez',
    'lessiverons',
    'lessiveront',
    'lessiveuses',
    'lessivielle',
    'lestassions',
    'lesteraient',
    'léthargique',
    'leucémiques',
    'leucocytose',
    'leucopénies',
    'leucoplasie',
    'leucorrhées',
    'leucotomies',
    'leurrassent',
    'leurrassiez',
    'leurrerions',
    'lève-glaces',
    'lévigations',
    'lévigeaient',
    'lévigeasses',
    'lévigerions',
    'lévitations',
    'levrettâmes',
    'levrettasse',
    'levrettâtes',
    'levretterai',
    'levretteras',
    'levretterez',
    'levrettions',
    'lexicalisai',
    'lexicalisas',
    'lexicalisât',
    'lexicalisée',
    'lexicaliser',
    'lexicalises',
    'lexicalisés',
    'lexicalisez',
    'lexicologie',
    'lexicologue',
    'lézardaient',
    'lézardasses',
    'lézarderais',
    'lézarderait',
    'lézardèrent',
    'lézarderiez',
    'lézarderons',
    'lézarderont',
    'liaisonnais',
    'liaisonnait',
    'liaisonnant',
    'liaisonnées',
    'liaisonnent',
    'liaisonnera',
    'liaisonniez',
    'liaisonnons',
    'liardassent',
    'liardassiez',
    'liarderions',
    'libellaient',
    'libellasses',
    'libellerais',
    'libellerait',
    'libellèrent',
    'libelleriez',
    'libellerons',
    'libelleront',
    'libellistes',
    'libéralisai',
    'libéralisas',
    'libéralisât',
    'libéralisée',
    'libéraliser',
    'libéralises',
    'libéralisés',
    'libéralisez',
    'libéralisme',
    'libéralités',
    'libérassent',
    'libérassiez',
    'libérateurs',
    'libérations',
    'libératoire',
    'libératrice',
    'libérerions',
    'libériennes',
    'libertaires',
    'liberticide',
    'libertinage',
    'libidinales',
    'libidineuse',
    'librettiste',
    'licenciâmes',
    'licenciasse',
    'licenciâtes',
    'licencierai',
    'licencieras',
    'licencierez',
    'licencieuse',
    'licenciions',
    'lichassions',
    'licheraient',
    'licitassent',
    'licitassiez',
    'licitations',
    'liciterions',
    'liégeassent',
    'liégeassiez',
    'liégeraient',
    'lieutenance',
    'lieutenants',
    'liftassions',
    'lifteraient',
    'ligamenteux',
    'ligaturâmes',
    'ligaturasse',
    'ligaturâtes',
    'ligaturerai',
    'ligatureras',
    'ligaturerez',
    'ligaturions',
    'lignassions',
    'ligneraient',
    'lignerolles',
    'lignifiâmes',
    'lignifiante',
    'lignifiants',
    'lignifiasse',
    'lignifiâtes',
    'lignifierai',
    'lignifieras',
    'lignifierez',
    'lignifiions',
    'lignomètres',
    'ligotassent',
    'ligotassiez',
    'ligoterions',
    'liguassions',
    'ligueraient',
    'liguliflore',
    'lilliputien',
    'limandaient',
    'limandasses',
    'limanderais',
    'limanderait',
    'limandèrent',
    'limanderiez',
    'limanderons',
    'limanderont',
    'limitassent',
    'limitassiez',
    'limitations',
    'limitatives',
    'limiterions',
    'limitrophes',
    'limnologies',
    'limogeaient',
    'limogeasses',
    'limogerions',
    'limonadière',
    'limonadiers',
    'limonassent',
    'limonassiez',
    'limonerions',
    'limousinage',
    'limousinais',
    'limousinait',
    'limousinant',
    'limousinées',
    'limousinent',
    'limousinera',
    'limousiniez',
    'limousinons',
    'linaigrette',
    'lingeassent',
    'lingeassiez',
    'lingeraient',
    'lingotières',
    'linguatules',
    'linguiforme',
    'linotypiste',
    'lipochromes',
    'liposarcome',
    'liposoluble',
    'lipothymies',
    'lipovaccins',
    'liquéfiable',
    'liquéfiâmes',
    'liquéfiante',
    'liquéfiants',
    'liquéfiasse',
    'liquéfiâtes',
    'liquéfierai',
    'liquéfieras',
    'liquéfierez',
    'liquéfiions',
    'liquidables',
    'liquidaient',
    'liquidambar',
    'liquidasses',
    'liquidateur',
    'liquidatifs',
    'liquidation',
    'liquidative',
    'liquiderais',
    'liquiderait',
    'liquidèrent',
    'liquideriez',
    'liquiderons',
    'liquideront',
    'liquidienne',
    'liquoreuses',
    'liquoristes',
    'liserassent',
    'lisérassent',
    'liserassiez',
    'lisérassiez',
    'lisérerions',
    'lisèrerions',
    'lisibilités',
    'lisiblement',
    'lissassions',
    'lisseraient',
    'listassions',
    'listeraient',
    'listérioses',
    'lithiasique',
    'lithinifère',
    'lithogenèse',
    'lithographe',
    'lithologies',
    'lithophages',
    'lithophanie',
    'lithosphère',
    'litigieuses',
    'littéraires',
    'littéralité',
    'littérarité',
    'littérateur',
    'littérature',
    'lituanienne',
    'liturgiques',
    'living-room',
    'livrassions',
    'livreraient',
    'lixiviation',
    'lobectomies',
    'localisable',
    'localisâmes',
    'localisante',
    'localisants',
    'localisasse',
    'localisâtes',
    'localiserai',
    'localiseras',
    'localiserez',
    'localisions',
    'lochassions',
    'locheraient',
    'lock-outais',
    'lock-outait',
    'lock-outant',
    'lock-outées',
    'lock-outent',
    'lock-outera',
    'lock-outiez',
    'lock-outons',
    'locomobiles',
    'locomoteurs',
    'locomotions',
    'locomotives',
    'locomotrice',
    'logarithmes',
    'logeassions',
    'logicielles',
    'logiciennes',
    'logiquement',
    'logisticien',
    'logistiques',
    'logographes',
    'logogriphes',
    'logomachies',
    'lois-cadres',
    'londonienne',
    'longanimité',
    'longeassent',
    'longeassiez',
    'longeraient',
    'longicornes',
    'longilignes',
    'lophophores',
    'loquassions',
    'loqueraient',
    'loqueteuses',
    'lorgnassent',
    'lorgnassiez',
    'lorgnerions',
    'lotionnâmes',
    'lotionnasse',
    'lotionnâtes',
    'lotionnerai',
    'lotionneras',
    'lotionnerez',
    'lotionnions',
    'lotissaient',
    'lotissement',
    'lotisseuses',
    'louangeâmes',
    'louangeasse',
    'louangeâtes',
    'louangerais',
    'louangerait',
    'louangèrent',
    'louangeriez',
    'louangerons',
    'louangeront',
    'louangeuses',
    'louchassent',
    'louchassiez',
    'loucherions',
    'louchirions',
    'louchissais',
    'louchissait',
    'louchissant',
    'louchissent',
    'louchissiez',
    'louchissons',
    'loufassions',
    'louferaient',
    'loufoquerie',
    'loupassions',
    'louperaient',
    'lourassions',
    'lourdassent',
    'lourdassiez',
    'lourderions',
    'loureraient',
    'louvassions',
    'louveraient',
    'louvetaient',
    'louvetasses',
    'louvetèrent',
    'louveteries',
    'louvetterai',
    'louvetteras',
    'louvetterez',
    'louvoiement',
    'louvoierais',
    'louvoierait',
    'louvoieriez',
    'louvoierons',
    'louvoieront',
    'louvoyaient',
    'louvoyantes',
    'louvoyasses',
    'louvoyèrent',
    'loxodromies',
    'lubrifiâmes',
    'lubrifiante',
    'lubrifiants',
    'lubrifiasse',
    'lubrifiâtes',
    'lubrifierai',
    'lubrifieras',
    'lubrifierez',
    'lubrifiions',
    'lucernaires',
    'lucifériens',
    'ludothèques',
    'lugeassions',
    'lugubrement',
    'luisissions',
    'lumachelles',
    'luminescent',
    'luminophore',
    'luminosités',
    'lunchassent',
    'lunchassiez',
    'luncherions',
    'lunetteries',
    'lusitaniens',
    'lustrassent',
    'lustrassiez',
    'lustrations',
    'lustrerions',
    'luthérienne',
    'lutinassent',
    'lutinassiez',
    'lutinerions',
    'luttassions',
    'lutteraient',
    'luxuriances',
    'luxuriantes',
    'luxurieuses',
    'luzernières',
    'lycanthrope',
    'lycoperdons',
    'lymphangite',
    'lymphatique',
    'lymphatisme',
    'lymphocytes',
    'lymphopénie',
    'lynchassent',
    'lynchassiez',
    'lyncherions',
    'lyophilisai',
    'lyophilisas',
    'lyophilisât',
    'lyophilisée',
    'lyophiliser',
    'lyophilises',
    'lyophilisés',
    'lyophilisez',
    'lyriquement',
    'lysergamide',
    'lysergiques',
    'macadamisai',
    'macadamisas',
    'macadamisât',
    'macadamisée',
    'macadamiser',
    'macadamises',
    'macadamisés',
    'macadamisez',
    'macaronique',
    'macédoniens',
    'macérassent',
    'macérassiez',
    'macérateurs',
    'macérations',
    'macérerions',
    'macfarlanes',
    'mâchassions',
    'mâcheraient',
    'machicotais',
    'machicotait',
    'machicotant',
    'machicotent',
    'machicotera',
    'machicotiez',
    'machicotons',
    'mâchicoulis',
    'machinaîmes',
    'machinaisse',
    'machinaîtes',
    'machinasses',
    'machination',
    'machinerais',
    'machinerait',
    'machinèrent',
    'machineries',
    'machineriez',
    'machinerons',
    'machineront',
    'machinismes',
    'machinistes',
    'mâchonnâmes',
    'mâchonnasse',
    'mâchonnâtes',
    'mâchonnerai',
    'mâchonneras',
    'mâchonnerez',
    'mâchonnions',
    'mâchouillai',
    'mâchouillas',
    'mâchouillât',
    'mâchouilles',
    'mâchouillez',
    'mâchuraient',
    'mâchurasses',
    'mâchurerais',
    'mâchurerait',
    'mâchurèrent',
    'mâchureriez',
    'mâchurerons',
    'mâchureront',
    'maclassions',
    'macleraient',
    'maçonnaient',
    'maçonnasses',
    'maçonnerais',
    'maçonnerait',
    'maçonnèrent',
    'maçonneries',
    'maçonneriez',
    'maçonnerons',
    'maçonneront',
    'maçonniques',
    'macquassent',
    'macquassiez',
    'macquerions',
    'macrocosmes',
    'macroséisme',
    'macrospores',
    'maculassent',
    'maculassiez',
    'maculatures',
    'maculerions',
    'madéfiaient',
    'madéfiasses',
    'madéfierais',
    'madéfierait',
    'madéfièrent',
    'madéfieriez',
    'madéfierons',
    'madéfieront',
    'madérisâmes',
    'madérisasse',
    'madérisâtes',
    'madériserai',
    'madériseras',
    'madériserez',
    'madérisions',
    'madrigalisa',
    'madrigalise',
    'madrigalisé',
    'magasinages',
    'magasinâmes',
    'magasinasse',
    'magasinâtes',
    'magasinerai',
    'magasineras',
    'magasinerez',
    'magasinière',
    'magasiniers',
    'magasinions',
    'magdalénien',
    'maghrébines',
    'magiciennes',
    'magiquement',
    'magistrales',
    'magmatiques',
    'magnaneries',
    'magnanières',
    'magnanimité',
    'magnassions',
    'magneraient',
    'magnésienne',
    'magnétiques',
    'magnétisais',
    'magnétisait',
    'magnétisant',
    'magnétisées',
    'magnétisent',
    'magnétisera',
    'magnétiseur',
    'magnétisiez',
    'magnétismes',
    'magnétisons',
    'magnifiâmes',
    'magnifiasse',
    'magnifiâtes',
    'magnificats',
    'magnifierai',
    'magnifieras',
    'magnifierez',
    'magnifiions',
    'magnifiques',
    'magouillais',
    'magouillait',
    'magouillant',
    'magouillées',
    'magouillent',
    'magouillera',
    'magouilleur',
    'magouilliez',
    'magouillons',
    'maharadjahs',
    'mahométanes',
    'mahométisme',
    'maïeutiques',
    'maigrelette',
    'maigrichons',
    'maigriottes',
    'maigririons',
    'maigrissais',
    'maigrissait',
    'maigrissant',
    'maigrissent',
    'maigrissiez',
    'maigrissons',
    'maillassent',
    'maillassiez',
    'maillechort',
    'maillerions',
    'maintenance',
    'mainteneurs',
    'maintenions',
    'maintiendra',
    'maintiennes',
    'maintinrent',
    'maintinsses',
    'maisonnette',
    'maîtrisable',
    'maîtrisâmes',
    'maîtrisasse',
    'maîtrisâtes',
    'maîtriserai',
    'maîtriseras',
    'maîtriserez',
    'maîtrisions',
    'majestueuse',
    'majorassent',
    'majorassiez',
    'majorations',
    'majorerions',
    'majoritaire',
    'malacologie',
    'maladreries',
    'maladresses',
    'maladroites',
    'malaisément',
    'malandreuse',
    'malapprises',
    'malaxassent',
    'malaxassiez',
    'malaxerions',
    'malchanceux',
    'malcommodes',
    'malédiction',
    'malentendus',
    'malfaisance',
    'malfaisante',
    'malfaisants',
    'malfaisions',
    'malfaiteurs',
    'malfassions',
    'malferaient',
    'malfissions',
    'malgracieux',
    'malheureuse',
    'malhonnêtes',
    'malicieuses',
    'malléolaire',
    'malmenaient',
    'malmenasses',
    'malmènerais',
    'malmènerait',
    'malmenèrent',
    'malmèneriez',
    'malmènerons',
    'malmèneront',
    'malodorante',
    'malodorants',
    'malposition',
    'malpropreté',
    'malsonnante',
    'malsonnants',
    'maltassions',
    'malteraient',
    'malthusiens',
    'maltraitais',
    'maltraitait',
    'maltraitant',
    'maltraitées',
    'maltraitent',
    'maltraitera',
    'maltraitiez',
    'maltraitons',
    'malveillant',
    'malvoyantes',
    'mamelonnais',
    'mamelonnait',
    'mamelonnant',
    'mamelonnées',
    'mamelonnent',
    'mamelonnera',
    'mamelonniez',
    'mamelonnons',
    'mamillaires',
    'mammalienne',
    'mammalogies',
    'manageaient',
    'manageasses',
    'managements',
    'managerions',
    'mancenilles',
    'manchonnais',
    'manchonnait',
    'manchonnant',
    'manchonnées',
    'manchonnent',
    'manchonnera',
    'manchonniez',
    'manchonnons',
    'mancipation',
    'mandarinale',
    'mandarinats',
    'mandarinaux',
    'mandarinier',
    'mandassions',
    'mandataient',
    'mandataires',
    'mandatasses',
    'mandatement',
    'mandaterais',
    'mandaterait',
    'mandatèrent',
    'mandateriez',
    'mandaterons',
    'mandateront',
    'manderaient',
    'mandragores',
    'manducation',
    'manégeaient',
    'manégeasses',
    'manégerions',
    'manganiques',
    'mangeailles',
    'mangeassent',
    'mangeassiez',
    'mangeottais',
    'mangeottait',
    'mangeottant',
    'mangeottées',
    'mangeottent',
    'mangeottera',
    'mangeottiez',
    'mangeottons',
    'mangeraient',
    'mangoustans',
    'maniabilité',
    'maniaquerie',
    'maniassions',
    'manichéenne',
    'manichéisme',
    'manieraient',
    'maniéraient',
    'maniérasses',
    'maniérerais',
    'maniérerait',
    'maniérèrent',
    'maniéreriez',
    'maniérerons',
    'maniéreront',
    'maniérismes',
    'maniéristes',
    'manifestais',
    'manifestait',
    'manifestant',
    'manifestées',
    'manifestent',
    'manifestera',
    'manifestiez',
    'manifestons',
    'manigançais',
    'manigançait',
    'manigançant',
    'manigancées',
    'manigancent',
    'manigancera',
    'maniganciez',
    'manigançons',
    'maniguettes',
    'manipulâmes',
    'manipulante',
    'manipulants',
    'manipulasse',
    'manipulâtes',
    'manipulerai',
    'manipuleras',
    'manipulerez',
    'manipulions',
    'mannequinai',
    'mannequinas',
    'mannequinât',
    'mannequinée',
    'mannequiner',
    'mannequines',
    'mannequinés',
    'mannequinez',
    'manoeuvrais',
    'manoeuvrait',
    'manoeuvrant',
    'manoeuvrées',
    'manoeuvrent',
    'manoeuvrera',
    'manoeuvrier',
    'manoeuvriez',
    'manoeuvrons',
    'manométries',
    'manoquaient',
    'manoquasses',
    'manoquerais',
    'manoquerait',
    'manoquèrent',
    'manoqueriez',
    'manoquerons',
    'manoqueront',
    'manouvrière',
    'manouvriers',
    'manquassent',
    'manquassiez',
    'manquements',
    'manquerions',
    'mansardâmes',
    'mansardasse',
    'mansardâtes',
    'mansarderai',
    'mansarderas',
    'mansarderez',
    'mansardions',
    'mansuétudes',
    'manucurâmes',
    'manucurasse',
    'manucurâtes',
    'manucurerai',
    'manucureras',
    'manucurerez',
    'manucurions',
    'manufactura',
    'manufacture',
    'manufacturé',
    'manumission',
    'manuscrites',
    'manutention',
    'mappemondes',
    'maquassions',
    'maqueraient',
    'maquereauta',
    'maquereaute',
    'maquereauté',
    'maquettiste',
    'maquignonna',
    'maquignonne',
    'maquignonné',
    'maquillages',
    'maquillâmes',
    'maquillante',
    'maquillants',
    'maquillasse',
    'maquillâtes',
    'maquillerai',
    'maquilleras',
    'maquillerez',
    'maquilleurs',
    'maquilleuse',
    'maquillions',
    'maquisardes',
    'maraîchages',
    'maraîchères',
    'maraîchines',
    'marathonien',
    'maraudaient',
    'maraudasses',
    'marauderais',
    'marauderait',
    'maraudèrent',
    'marauderiez',
    'marauderons',
    'marauderont',
    'maraudeuses',
    'marbrassent',
    'marbrassiez',
    'marbrerions',
    'marcassites',
    'marcescence',
    'marcescente',
    'marcescents',
    'marcescible',
    'marchandage',
    'marchandais',
    'marchandait',
    'marchandant',
    'marchandées',
    'marchandent',
    'marchandera',
    'marchandiez',
    'marchandise',
    'marchandons',
    'marchanties',
    'marchassent',
    'marchassiez',
    'marchepieds',
    'marcherions',
    'marcottages',
    'marcottâmes',
    'marcottasse',
    'marcottâtes',
    'marcotterai',
    'marcotteras',
    'marcotterez',
    'marcottions',
    'marécageuse',
    'marégraphes',
    'marémoteurs',
    'marémotrice',
    'maréomètres',
    'margeassent',
    'margeassiez',
    'margeraient',
    'marginaient',
    'marginalisa',
    'marginalise',
    'marginalisé',
    'marginalité',
    'marginasses',
    'marginerais',
    'marginerait',
    'marginèrent',
    'margineriez',
    'marginerons',
    'margineront',
    'margotaient',
    'margotasses',
    'margoterais',
    'margoterait',
    'margotèrent',
    'margoteriez',
    'margoterons',
    'margoteront',
    'margottâmes',
    'margottasse',
    'margottâtes',
    'margotterai',
    'margotteras',
    'margotterez',
    'margottions',
    'margouillis',
    'margoulette',
    'margraviats',
    'marguerites',
    'marguillier',
    'mariassions',
    'marieraient',
    'marinassent',
    'marinassiez',
    'marinerions',
    'maringouins',
    'marionnette',
    'marivaudage',
    'marivaudais',
    'marivaudait',
    'marivaudant',
    'marivaudent',
    'marivaudera',
    'marivaudiez',
    'marivaudons',
    'marjolaines',
    'marmenteaux',
    'marmitaient',
    'marmitasses',
    'marmiterais',
    'marmiterait',
    'marmitèrent',
    'marmiteriez',
    'marmiterons',
    'marmiteront',
    'marmonnâmes',
    'marmonnasse',
    'marmonnâtes',
    'marmonnerai',
    'marmonneras',
    'marmonnerez',
    'marmonnions',
    'marmoréenne',
    'marmorisais',
    'marmorisait',
    'marmorisant',
    'marmorisées',
    'marmorisent',
    'marmorisera',
    'marmorisiez',
    'marmorisons',
    'marmottâmes',
    'marmottasse',
    'marmottâtes',
    'marmotterai',
    'marmotteras',
    'marmotterez',
    'marmotteurs',
    'marmotteuse',
    'marmottions',
    'marnassions',
    'marneraient',
    'maronnaient',
    'maronnasses',
    'maronnerais',
    'maronnerait',
    'maronnèrent',
    'maronneriez',
    'maronnerons',
    'maronneront',
    'maroquinage',
    'maroquinais',
    'maroquinait',
    'maroquinant',
    'maroquinées',
    'maroquinent',
    'maroquinera',
    'maroquinier',
    'maroquiniez',
    'maroquinons',
    'marouflages',
    'marouflâmes',
    'marouflasse',
    'marouflâtes',
    'marouflerai',
    'maroufleras',
    'marouflerez',
    'marouflions',
    'marquassent',
    'marquassiez',
    'marquerions',
    'marquetâmes',
    'marquetasse',
    'marquetâtes',
    'marqueterie',
    'marqueteurs',
    'marquetions',
    'marquettent',
    'marquettera',
    'marrassions',
    'marreraient',
    'marronnâmes',
    'marronnasse',
    'marronnâtes',
    'marronnerai',
    'marronneras',
    'marronnerez',
    'marronniers',
    'marronnions',
    'marseillais',
    'marsouinais',
    'marsouinait',
    'marsouinant',
    'marsouinent',
    'marsouinera',
    'marsouiniez',
    'marsouinons',
    'martelaient',
    'martelasses',
    'martèlement',
    'martèlerais',
    'martèlerait',
    'martelèrent',
    'martèleriez',
    'martèlerons',
    'martèleront',
    'martensites',
    'martingales',
    'martyrisais',
    'martyrisait',
    'martyrisant',
    'martyrisées',
    'martyrisent',
    'martyrisera',
    'martyrisiez',
    'martyrisons',
    'martyrologe',
    'marxisaient',
    'marxisasses',
    'marxiserais',
    'marxiserait',
    'marxisèrent',
    'marxiseriez',
    'marxiserons',
    'marxiseront',
    'masculinisa',
    'masculinise',
    'masculinisé',
    'masculinité',
    'maskinongés',
    'masochismes',
    'masochistes',
    'masquassent',
    'masquassiez',
    'masquerions',
    'massacrâmes',
    'massacrante',
    'massacrants',
    'massacrasse',
    'massacrâtes',
    'massacrerai',
    'massacreras',
    'massacrerez',
    'massacreurs',
    'massacreuse',
    'massacrions',
    'massassions',
    'masselottes',
    'masseraient',
    'massicotais',
    'massicotait',
    'massicotant',
    'massicotées',
    'massicotent',
    'massicotera',
    'massicotiez',
    'massicotons',
    'massifiâmes',
    'massifiasse',
    'massifiâtes',
    'massifierai',
    'massifieras',
    'massifierez',
    'massifiions',
    'massivement',
    'mastectomie',
    'masticateur',
    'mastication',
    'mastiquâmes',
    'mastiquasse',
    'mastiquâtes',
    'mastiquerai',
    'mastiqueras',
    'mastiquerez',
    'mastiquions',
    'mastodontes',
    'mastoïdiens',
    'mastoïdites',
    'mastologies',
    'mastroquets',
    'masturbâmes',
    'masturbasse',
    'masturbâtes',
    'masturberai',
    'masturberas',
    'masturberez',
    'masturbions',
    'matchassent',
    'matchassiez',
    'matcherions',
    'matelassais',
    'matelassait',
    'matelassant',
    'matelassées',
    'matelassent',
    'matelassera',
    'matelassier',
    'matelassiez',
    'matelassons',
    'matérialisa',
    'matérialise',
    'matérialisé',
    'matérialité',
    'matérielles',
    'maternaient',
    'maternasses',
    'maternelles',
    'maternerais',
    'maternerait',
    'maternèrent',
    'materneriez',
    'maternerons',
    'materneront',
    'maternisais',
    'maternisait',
    'maternisant',
    'maternisées',
    'maternisent',
    'maternisera',
    'maternisiez',
    'maternisons',
    'mathématisa',
    'mathématise',
    'mathématisé',
    'mâtinassent',
    'mâtinassiez',
    'mâtinerions',
    'matissaient',
    'matraquages',
    'matraquâmes',
    'matraquasse',
    'matraquâtes',
    'matraquerai',
    'matraqueras',
    'matraquerez',
    'matraqueurs',
    'matraqueuse',
    'matraquions',
    'matriarcale',
    'matriarcats',
    'matriarcaux',
    'matriçaient',
    'matricaires',
    'matriçasses',
    'matricerais',
    'matricerait',
    'matricèrent',
    'matriceriez',
    'matricerons',
    'matriceront',
    'matriculais',
    'matriculait',
    'matriculant',
    'matriculées',
    'matriculent',
    'matriculera',
    'matriculiez',
    'matriculons',
    'matrilocale',
    'matrilocaux',
    'matrimonial',
    'maturassent',
    'maturassiez',
    'maturations',
    'maturerions',
    'matutinales',
    'maudiraient',
    'maudissante',
    'maudissants',
    'maudissions',
    'maugréaient',
    'maugréasses',
    'maugréerais',
    'maugréerait',
    'maugréèrent',
    'maugréeriez',
    'maugréerons',
    'maugréeront',
    'maussaderie',
    'mauvaisetés',
    'maxillaires',
    'maximalisai',
    'maximalisas',
    'maximalisât',
    'maximalisée',
    'maximaliser',
    'maximalises',
    'maximalisés',
    'maximalisez',
    'maximalisme',
    'maximaliste',
    'maximisâmes',
    'maximisasse',
    'maximisâtes',
    'maximiserai',
    'maximiseras',
    'maximiserez',
    'maximisions',
    'mayonnaises',
    'mazoutaient',
    'mazoutasses',
    'mazouterais',
    'mazouterait',
    'mazoutèrent',
    'mazouteriez',
    'mazouterons',
    'mazouteront',
    'mécaniciens',
    'mécanisâmes',
    'mécanisasse',
    'mécanisâtes',
    'mécaniserai',
    'mécaniseras',
    'mécaniserez',
    'mécanisions',
    'méchancetés',
    'méchassions',
    'mécheraient',
    'mécomptâmes',
    'mécomptasse',
    'mécomptâtes',
    'mécompterai',
    'mécompteras',
    'mécompterez',
    'mécomptions',
    'méconduites',
    'méconnaisse',
    'méconnaîtra',
    'méconnaître',
    'méconnurent',
    'méconnusses',
    'mécontentai',
    'mécontentas',
    'mécontentât',
    'mécontentée',
    'mécontenter',
    'mécontentes',
    'mécontentés',
    'mécontentez',
    'médaillâmes',
    'médaillasse',
    'médaillâtes',
    'médaillerai',
    'médailleras',
    'médaillerez',
    'médailleurs',
    'médailliers',
    'médaillions',
    'médailliste',
    'médianoches',
    'médiatiques',
    'médiatisais',
    'médiatisait',
    'médiatisant',
    'médiatisées',
    'médiatisent',
    'médiatisera',
    'médiatisiez',
    'médiatisons',
    'médiatrices',
    'médicalisai',
    'médicalisas',
    'médicalisât',
    'médicalisée',
    'médicaliser',
    'médicalises',
    'médicalisés',
    'médicalisez',
    'médicamenta',
    'médicamente',
    'médicamenté',
    'médicaments',
    'médicastres',
    'médications',
    'médicinales',
    'médiciniers',
    'médiévismes',
    'médiévistes',
    'médiocratie',
    'médiocrités',
    'médisassent',
    'médisassiez',
    'médiserions',
    'méditassent',
    'méditassiez',
    'méditations',
    'méditatives',
    'méditerions',
    'méditerrané',
    'médiumnique',
    'médiumnités',
    'médullaires',
    'médulleuses',
    'médusassent',
    'médusassiez',
    'méduserions',
    'méfaisaient',
    'méfiassions',
    'méfieraient',
    'mégalomanes',
    'mégalomanie',
    'mégalopoles',
    'mégathérium',
    'mégissaient',
    'mégissasses',
    'mégisserais',
    'mégisserait',
    'mégissèrent',
    'mégisseries',
    'mégisseriez',
    'mégisserons',
    'mégisseront',
    'mégohmmètre',
    'mégotassent',
    'mégotassiez',
    'mégoterions',
    'méjugeaient',
    'méjugeasses',
    'méjugerions',
    'mélancolies',
    'mélangeâmes',
    'mélangeante',
    'mélangeants',
    'mélangeasse',
    'mélangeâtes',
    'mélangerais',
    'mélangerait',
    'mélangèrent',
    'mélangeriez',
    'mélangerons',
    'mélangeront',
    'mélangeuses',
    'méléagrines',
    'mélioratifs',
    'méliorative',
    'mélis-mélos',
    'mélodieuses',
    'melonnières',
    'membraneuse',
    'membranules',
    'mémorandums',
    'mémorations',
    'mémorielles',
    'mémorisâmes',
    'mémorisante',
    'mémorisants',
    'mémorisasse',
    'mémorisâtes',
    'mémoriserai',
    'mémoriseras',
    'mémoriserez',
    'mémorisions',
    'menaçassent',
    'menaçassiez',
    'menacerions',
    'ménageaient',
    'ménageantes',
    'ménageasses',
    'ménagements',
    'ménagerions',
    'mendélévium',
    'mendélienne',
    'mendélismes',
    'mendiassent',
    'mendiassiez',
    'mendierions',
    'mendigotais',
    'mendigotait',
    'mendigotant',
    'mendigotées',
    'mendigotent',
    'mendigotera',
    'mendigotiez',
    'mendigotons',
    'méningiomes',
    'ménopausées',
    'ménorragies',
    'mensongères',
    'menstruelle',
    'mensualisai',
    'mensualisas',
    'mensualisât',
    'mensualisée',
    'mensualiser',
    'mensualises',
    'mensualisés',
    'mensualisez',
    'mensualités',
    'mensuraient',
    'mensurasses',
    'mensuration',
    'mensurerais',
    'mensurerait',
    'mensurèrent',
    'mensureriez',
    'mensurerons',
    'mensureront',
    'mentalement',
    'mentionnais',
    'mentionnait',
    'mentionnant',
    'mentionnées',
    'mentionnent',
    'mentionnera',
    'mentionniez',
    'mentionnons',
    'mentiraient',
    'mentissions',
    'mentonnière',
    'mentonniers',
    'menuisaient',
    'menuisasses',
    'menuiserais',
    'menuiserait',
    'menuisèrent',
    'menuiseries',
    'menuiseriez',
    'menuiserons',
    'menuiseront',
    'méphitiques',
    'méphitismes',
    'méprenaient',
    'méprendrais',
    'méprendrait',
    'méprendriez',
    'méprendrons',
    'méprendront',
    'méprisables',
    'méprisaient',
    'méprisantes',
    'méprisasses',
    'mépriserais',
    'mépriserait',
    'méprisèrent',
    'mépriseriez',
    'mépriserons',
    'mépriseront',
    'méprissions',
    'mercantiles',
    'mercaticien',
    'mercatiques',
    'mercenaires',
    'mercerisage',
    'mercerisais',
    'mercerisait',
    'mercerisant',
    'mercerisées',
    'mercerisent',
    'mercerisera',
    'mercerisiez',
    'mercerisons',
    'mercuriales',
    'mercurielle',
    'merdoierais',
    'merdoierait',
    'merdoieriez',
    'merdoierons',
    'merdoieront',
    'merdoyaient',
    'merdoyasses',
    'merdoyèrent',
    'mères-grand',
    'méridiennes',
    'méridionale',
    'méridionaux',
    'meringuâmes',
    'meringuasse',
    'meringuâtes',
    'meringuerai',
    'meringueras',
    'meringuerez',
    'meringuions',
    'méritassent',
    'méritassiez',
    'mériterions',
    'mérovingien',
    'merveilleux',
    'mésalliâmes',
    'mésalliance',
    'mésalliasse',
    'mésalliâtes',
    'mésallierai',
    'mésallieras',
    'mésallierez',
    'mésalliions',
    'mésangettes',
    'mésaventure',
    'mésenchymes',
    'mésententes',
    'mésestimais',
    'mésestimait',
    'mésestimant',
    'mésestimées',
    'mésestiment',
    'mésestimera',
    'mésestimiez',
    'mésestimons',
    'mesmérismes',
    'mésoblastes',
    'mésosphères',
    'mésozoïques',
    'mesquinerie',
    'messageries',
    'messeyaient',
    'messianique',
    'messianisme',
    'mesurassent',
    'mesurassiez',
    'mesurerions',
    'mésusassent',
    'mésusassiez',
    'mésuserions',
    'métabolique',
    'métabolisme',
    'métabolites',
    'métacarpien',
    'métacentres',
    'métalangage',
    'métalangues',
    'métaldéhyde',
    'métallifère',
    'métalliques',
    'métallisais',
    'métallisait',
    'métallisant',
    'métallisées',
    'métallisent',
    'métallisera',
    'métallisiez',
    'métallisons',
    'métalloïdes',
    'métallurgie',
    'métaplasies',
    'métatarsien',
    'métazoaires',
    'météoriques',
    'météorisais',
    'météorisait',
    'météorisant',
    'météorisées',
    'météorisent',
    'météorisera',
    'météorisiez',
    'météorisons',
    'méthionines',
    'méthodiques',
    'méthodismes',
    'méthodistes',
    'méthyliques',
    'méticuleuse',
    'métissaient',
    'métissasses',
    'métisserais',
    'métisserait',
    'métissèrent',
    'métisseriez',
    'métisserons',
    'métisseront',
    'métonymique',
    'métrassions',
    'métreraient',
    'métricienne',
    'métromanies',
    'métropolite',
    'métrorragie',
    'meublassent',
    'meublassiez',
    'meublerions',
    'meuglassent',
    'meuglassiez',
    'meuglements',
    'meuglerions',
    'meulassions',
    'meuleraient',
    'meurtrières',
    'meurtrirais',
    'meurtrirait',
    'meurtrirent',
    'meurtririez',
    'meurtrirons',
    'meurtriront',
    'meurtrisses',
    'meurtrissez',
    'mévendaient',
    'mévendirent',
    'mévendisses',
    'mévendrions',
    'mezzo-tinto',
    'miasmatique',
    'miaulassent',
    'miaulassiez',
    'miaulements',
    'miaulerions',
    'micaschiste',
    'micellaires',
    'micocoulier',
    'microbicide',
    'microbienne',
    'microcinéma',
    'microclimat',
    'microcoques',
    'microcosmes',
    'microfiches',
    'microfilmai',
    'microfilmas',
    'microfilmât',
    'microfilmée',
    'microfilmer',
    'microfilmes',
    'microfilmés',
    'microfilmez',
    'microgrenue',
    'microgrenus',
    'micromètres',
    'micrométrie',
    'microphones',
    'microscopes',
    'microsillon',
    'mi-décembre',
    'mignardâmes',
    'mignardasse',
    'mignardâtes',
    'mignarderai',
    'mignarderas',
    'mignarderez',
    'mignardions',
    'mignardises',
    'mignonnette',
    'mignotaient',
    'mignotasses',
    'mignoterais',
    'mignoterait',
    'mignotèrent',
    'mignoteriez',
    'mignoterons',
    'mignoteront',
    'migraineuse',
    'migrassions',
    'migratoires',
    'migratrices',
    'migreraient',
    'mijotassent',
    'mijotassiez',
    'mijoterions',
    'mildiousées',
    'miliciennes',
    'militarisai',
    'militarisas',
    'militarisât',
    'militarisée',
    'militariser',
    'militarises',
    'militarisés',
    'militarisez',
    'militarisme',
    'militariste',
    'militassent',
    'militassiez',
    'militerions',
    'millénaires',
    'mille-raies',
    'millerandée',
    'millerandés',
    'millésimais',
    'millésimait',
    'millésimant',
    'millésimées',
    'millésiment',
    'millésimera',
    'millésimiez',
    'millésimons',
    'milliampère',
    'milligramme',
    'millilitres',
    'millimétrée',
    'millimétrés',
    'millimètres',
    'millionième',
    'mimographes',
    'minablement',
    'minaudaient',
    'minaudasses',
    'minauderais',
    'minauderait',
    'minaudèrent',
    'minauderies',
    'minauderiez',
    'minauderons',
    'minauderont',
    'minaudières',
    'minciraient',
    'mincissante',
    'mincissants',
    'mincissions',
    'minéraliers',
    'minéralisai',
    'minéralisas',
    'minéralisât',
    'minéralisée',
    'minéraliser',
    'minéralises',
    'minéralisés',
    'minéralisez',
    'minéralogie',
    'minervistes',
    'minestrones',
    'miniaturais',
    'miniaturait',
    'miniaturant',
    'miniaturées',
    'miniaturent',
    'miniaturera',
    'miniaturiez',
    'miniaturisa',
    'miniaturise',
    'miniaturisé',
    'miniaturons',
    'mini-chaîne',
    'minimisâmes',
    'minimisante',
    'minimisants',
    'minimisasse',
    'minimisâtes',
    'minimiserai',
    'minimiseras',
    'minimiserez',
    'minimisions',
    'ministériel',
    'ministrable',
    'minnesinger',
    'minorassent',
    'minorassiez',
    'minorations',
    'minoratives',
    'minorerions',
    'minoritaire',
    'mi-novembre',
    'minutassent',
    'minutassiez',
    'minuterions',
    'minutieuses',
    'mirabellier',
    'miraculeuse',
    'mirliflores',
    'mirobolante',
    'mirobolants',
    'miroitaient',
    'miroitantes',
    'miroitasses',
    'miroitement',
    'miroiterais',
    'miroiterait',
    'miroitèrent',
    'miroiteries',
    'miroiteriez',
    'miroiterons',
    'miroiteront',
    'miroitières',
    'misanthrope',
    'miscibilité',
    'miséricorde',
    'misonéismes',
    'misonéistes',
    'mithracisme',
    'mithriaques',
    'mithridatai',
    'mithridatas',
    'mithridatât',
    'mithridatée',
    'mithridater',
    'mithridates',
    'mithridatés',
    'mithridatez',
    'mitigations',
    'mitigeaient',
    'mitigeantes',
    'mitigeasses',
    'mitigerions',
    'mitonnaient',
    'mitonnasses',
    'mitonnerais',
    'mitonnerait',
    'mitonnèrent',
    'mitonneriez',
    'mitonnerons',
    'mitonneront',
    'mitoyenneté',
    'mitraillade',
    'mitraillage',
    'mitraillais',
    'mitraillait',
    'mitraillant',
    'mitraillées',
    'mitraillent',
    'mitraillera',
    'mitrailleur',
    'mitrailliez',
    'mitraillons',
    'mixtionnais',
    'mixtionnait',
    'mixtionnant',
    'mixtionnées',
    'mixtionnent',
    'mixtionnera',
    'mixtionniez',
    'mixtionnons',
    'mnémoniques',
    'mobilisable',
    'mobilisâmes',
    'mobilisante',
    'mobilisants',
    'mobilisasse',
    'mobilisâtes',
    'mobiliserai',
    'mobiliseras',
    'mobiliserez',
    'mobilisions',
    'modelassent',
    'modelassiez',
    'modèlerions',
    'modéliserai',
    'modéliseras',
    'modéliserez',
    'modélisions',
    'modénatures',
    'modérassent',
    'modérassiez',
    'modérateurs',
    'modérations',
    'modératrice',
    'modérerions',
    'modernisais',
    'modernisait',
    'modernisant',
    'modernisées',
    'modernisent',
    'modernisera',
    'modernisiez',
    'modernismes',
    'modernisons',
    'modernistes',
    'modestement',
    'modifiables',
    'modifiaient',
    'modifiantes',
    'modifiasses',
    'modificatif',
    'modifierais',
    'modifierait',
    'modifièrent',
    'modifieriez',
    'modifierons',
    'modifieront',
    'modiquement',
    'modularités',
    'modulassent',
    'modulassiez',
    'modulateurs',
    'modulations',
    'modulatrice',
    'modulerions',
    'moindrement',
    'moins-perçu',
    'moins-value',
    'moirassions',
    'moireraient',
    'moisassions',
    'moiseraient',
    'moisiraient',
    'moisissante',
    'moisissants',
    'moisissions',
    'moisissures',
    'moissonnage',
    'moissonnais',
    'moissonnait',
    'moissonnant',
    'moissonnées',
    'moissonnent',
    'moissonnera',
    'moissonneur',
    'moissonniez',
    'moissonnons',
    'moitassions',
    'moiteraient',
    'moitiraient',
    'moitissions',
    'moléculaire',
    'molestaient',
    'molestasses',
    'molesterais',
    'molesterait',
    'molestèrent',
    'molesteriez',
    'molesterons',
    'molesteront',
    'moletassent',
    'moletassiez',
    'moletterais',
    'moletterait',
    'moletteriez',
    'moletterons',
    'moletteront',
    'moliéresque',
    'molinosiste',
    'mollardâmes',
    'mollardasse',
    'mollardâtes',
    'mollarderai',
    'mollarderas',
    'mollarderez',
    'mollardions',
    'mollasserie',
    'mollassonne',
    'molletières',
    'molletonnai',
    'molletonnas',
    'molletonnât',
    'molletonnée',
    'molletonner',
    'molletonnes',
    'molletonnés',
    'molletonnez',
    'molliraient',
    'mollissions',
    'momentanées',
    'momifiaient',
    'momifiasses',
    'momifierais',
    'momifierait',
    'momifièrent',
    'momifieriez',
    'momifierons',
    'momifieront',
    'momordiques',
    'monachismes',
    'monadelphes',
    'monarchique',
    'monarchisme',
    'monarchiste',
    'monastiques',
    'mondassions',
    'monderaient',
    'mondialisai',
    'mondialisas',
    'mondialisât',
    'mondialiser',
    'mondialises',
    'mondialisez',
    'mondialiste',
    'mondovision',
    'monétarisme',
    'monétariste',
    'monétisâmes',
    'monétisasse',
    'monétisâtes',
    'monétiserai',
    'monétiseras',
    'monétiserez',
    'monétisions',
    'mongolienne',
    'mongoliques',
    'mongolismes',
    'mongoloïdes',
    'monitorages',
    'monitorings',
    'monnaierais',
    'monnaierait',
    'monnaieriez',
    'monnaierons',
    'monnaieront',
    'monnayables',
    'monnayaient',
    'monnayantes',
    'monnayasses',
    'monnayerais',
    'monnayerait',
    'monnayèrent',
    'monnayeriez',
    'monnayerons',
    'monnayeront',
    'monnayeuses',
    'monochromes',
    'monochromie',
    'monoclinale',
    'monoclinaux',
    'monoculaire',
    'monoculture',
    'monogamique',
    'monogénisme',
    'monogrammes',
    'monographie',
    'monoïdéisme',
    'monolingues',
    'monologuais',
    'monologuait',
    'monologuant',
    'monologuées',
    'monologuent',
    'monologuera',
    'monologuiez',
    'monologuons',
    'monomoteurs',
    'monomotrice',
    'monophasées',
    'monopolisai',
    'monopolisas',
    'monopolisât',
    'monopolisée',
    'monopoliser',
    'monopolises',
    'monopolisés',
    'monopolisez',
    'monopoliste',
    'monosépales',
    'monospermes',
    'monosulfite',
    'monosyllabe',
    'monothéique',
    'monothéisme',
    'monothéiste',
    'monovalente',
    'monovalents',
    'monseigneur',
    'monsignores',
    'monstrueuse',
    'montagnarde',
    'montagnards',
    'montagnette',
    'montagneuse',
    'montanismes',
    'montanistes',
    'montassions',
    'monte-pente',
    'monte-plats',
    'monteraient',
    'montmorency',
    'montrassent',
    'montrassiez',
    'montrerions',
    'monumentale',
    'monumentaux',
    'moquassions',
    'moqueraient',
    'moquettâmes',
    'moquettasse',
    'moquettâtes',
    'moquetterai',
    'moquetteras',
    'moquetterez',
    'moquettions',
    'morainiques',
    'moralisâmes',
    'moralisante',
    'moralisants',
    'moralisasse',
    'moralisâtes',
    'moraliserai',
    'moraliseras',
    'moraliserez',
    'moralisions',
    'moratoriums',
    'morbilleuse',
    'morcelables',
    'morcelaient',
    'morcelasses',
    'morcelèrent',
    'morcellerai',
    'morcelleras',
    'morcellerez',
    'mordançâmes',
    'mordançasse',
    'mordançâtes',
    'mordancerai',
    'mordanceras',
    'mordancerez',
    'mordancions',
    'mordicantes',
    'mordillâmes',
    'mordillante',
    'mordillants',
    'mordillasse',
    'mordillâtes',
    'mordillerai',
    'mordilleras',
    'mordillerez',
    'mordillions',
    'mordissions',
    'mordoraient',
    'mordorasses',
    'mordorerais',
    'mordorerait',
    'mordorèrent',
    'mordoreriez',
    'mordorerons',
    'mordoreront',
    'morfilaient',
    'morfilasses',
    'morfilerais',
    'morfilerait',
    'morfilèrent',
    'morfileriez',
    'morfilerons',
    'morfileront',
    'morflassent',
    'morflassiez',
    'morflerions',
    'morfondîmes',
    'morfondions',
    'morfondisse',
    'morfondîtes',
    'morfondrais',
    'morfondrait',
    'morfondriez',
    'morfondrons',
    'morfondront',
    'morguassent',
    'morguassiez',
    'morguerions',
    'morigénâmes',
    'morigénasse',
    'morigénâtes',
    'morigénerai',
    'morigéneras',
    'morigénerez',
    'morigénions',
    'mormonismes',
    'morphinisme',
    'morphogènes',
    'morphologie',
    'mortadelles',
    'mortaisages',
    'mortaisâmes',
    'mortaisasse',
    'mortaisâtes',
    'mortaiserai',
    'mortaiseras',
    'mortaiserez',
    'mortaiseuse',
    'mortaisions',
    'mortes-eaux',
    'mortifiâmes',
    'mortifiante',
    'mortifiants',
    'mortifiasse',
    'mortifiâtes',
    'mortifierai',
    'mortifieras',
    'mortifierez',
    'mortifiions',
    'moscoutaire',
    'motionnâmes',
    'motionnasse',
    'motionnâtes',
    'motionnerai',
    'motionneras',
    'motionnerez',
    'motionnions',
    'motivassent',
    'motivassiez',
    'motivations',
    'motiverions',
    'motoculteur',
    'motoculture',
    'motogodille',
    'motorisâmes',
    'motorisasse',
    'motorisâtes',
    'motoriserai',
    'motoriseras',
    'motoriserez',
    'motorisions',
    'mottassions',
    'motteraient',
    'mouchardage',
    'mouchardais',
    'mouchardait',
    'mouchardant',
    'mouchardées',
    'mouchardent',
    'mouchardera',
    'mouchardiez',
    'mouchardons',
    'mouchassent',
    'mouchassiez',
    'moucherions',
    'moucheronna',
    'moucheronne',
    'moucheronné',
    'mouchetâmes',
    'mouchetasse',
    'mouchetâtes',
    'mouchetions',
    'mouchettent',
    'mouchettera',
    'mouchetures',
    'mouettaient',
    'mouettasses',
    'mouetterais',
    'mouetterait',
    'mouettèrent',
    'mouetteriez',
    'mouetterons',
    'mouetteront',
    'mouftassent',
    'mouftassiez',
    'moufterions',
    'mouillaient',
    'mouillantes',
    'mouillasses',
    'mouillement',
    'mouillerais',
    'mouillerait',
    'mouillèrent',
    'mouilleriez',
    'mouillerons',
    'mouilleront',
    'mouillettes',
    'moulassions',
    'mouleraient',
    'moulinaient',
    'moulinasses',
    'moulinerais',
    'moulinerait',
    'moulinèrent',
    'moulineriez',
    'moulinerons',
    'moulineront',
    'moulinettes',
    'moulineuses',
    'moulinières',
    'mouluraient',
    'moulurasses',
    'moulurerais',
    'moulurerait',
    'moulurèrent',
    'moulureriez',
    'moulurerons',
    'moulureront',
    'moulussions',
    'mouronnâmes',
    'mouronnasse',
    'mouronnâtes',
    'mouronnerai',
    'mouronneras',
    'mouronnerez',
    'mouronnions',
    'mourussions',
    'mouscailles',
    'mousquetade',
    'mousquetons',
    'moussaillon',
    'moussassent',
    'moussassiez',
    'mousselines',
    'mousserions',
    'moustachues',
    'moustériens',
    'moutardière',
    'moutardiers',
    'moutonnâmes',
    'moutonnante',
    'moutonnants',
    'moutonnasse',
    'moutonnâtes',
    'moutonnerai',
    'moutonneras',
    'moutonnerez',
    'moutonnerie',
    'moutonneuse',
    'moutonnière',
    'moutonniers',
    'moutonnions',
    'mouvassions',
    'mouvementai',
    'mouvementas',
    'mouvementât',
    'mouvementée',
    'mouvementer',
    'mouvementes',
    'mouvementés',
    'mouvementez',
    'mouveraient',
    'moyenâgeuse',
    'moyennaient',
    'moyennasses',
    'moyennement',
    'moyennerais',
    'moyennerait',
    'moyennèrent',
    'moyenneriez',
    'moyennerons',
    'moyenneront',
    'muchassions',
    'mucheraient',
    'mugissaient',
    'mugissantes',
    'mugissement',
    'muguetaient',
    'muguetasses',
    'muguetèrent',
    'muguetterai',
    'muguetteras',
    'muguetterez',
    'mulassières',
    'mulâtresses',
    'mule-jennys',
    'mulotassent',
    'mulotassiez',
    'muloterions',
    'multicolore',
    'multiflores',
    'multiformes',
    'multimédias',
    'multimodale',
    'multiplexai',
    'multiplexas',
    'multiplexât',
    'multiplexée',
    'multiplexer',
    'multiplexes',
    'multiplexés',
    'multiplexez',
    'multipliais',
    'multipliait',
    'multipliant',
    'multipliées',
    'multiplient',
    'multipliera',
    'multipliiez',
    'multiplions',
    'multiracial',
    'multirisque',
    'multisalles',
    'municipales',
    'munificence',
    'munificente',
    'munificents',
    'munissaient',
    'munitionnai',
    'munitionnas',
    'munitionnât',
    'munitionnée',
    'munitionner',
    'munitionnes',
    'munitionnés',
    'munitionnez',
    'muraillâmes',
    'muraillasse',
    'muraillâtes',
    'muraillerai',
    'murailleras',
    'muraillerez',
    'muraillions',
    'muriatiques',
    'mûrissaient',
    'mûrissantes',
    'mûrissement',
    'mûrisseries',
    'murmuraient',
    'murmurantes',
    'murmurasses',
    'murmurerais',
    'murmurerait',
    'murmurèrent',
    'murmureriez',
    'murmurerons',
    'murmureront',
    'musaraignes',
    'musardaient',
    'musardasses',
    'musarderais',
    'musarderait',
    'musardèrent',
    'musarderiez',
    'musarderons',
    'musarderont',
    'muscardines',
    'musclassent',
    'musclassiez',
    'musclerions',
    'musculaires',
    'musculation',
    'musculature',
    'musculeuses',
    'muselassent',
    'muselassiez',
    'musellement',
    'musellerais',
    'musellerait',
    'muselleriez',
    'musellerons',
    'muselleront',
    'muséologies',
    'musicalités',
    'music-halls',
    'musiciennes',
    'musicologie',
    'musicologue',
    'musiquaient',
    'musiquasses',
    'musiquerais',
    'musiquerait',
    'musiquèrent',
    'musiqueriez',
    'musiquerons',
    'musiqueront',
    'musiquettes',
    'musquassent',
    'musquassiez',
    'musquerions',
    'mussassions',
    'musseraient',
    'mussitation',
    'mutabilités',
    'mutilassent',
    'mutilassiez',
    'mutilateurs',
    'mutilations',
    'mutilatrice',
    'mutilerions',
    'mutinassent',
    'mutinassiez',
    'mutinerions',
    'mutualismes',
    'mutualistes',
    'myasthénies',
    'mycéliennes',
    'mycéniennes',
    'mycoplasmes',
    'mydriatique',
    'myéloblaste',
    'myocardites',
    'myriamètres',
    'myriophylle',
    'myrtiformes',
    'mystagogies',
    'mystagogues',
    'mystérieuse',
    'mysticismes',
    'mystifiable',
    'mystifiâmes',
    'mystifiante',
    'mystifiants',
    'mystifiasse',
    'mystifiâtes',
    'mystifierai',
    'mystifieras',
    'mystifierez',
    'mystifiions',
    'mythographe',
    'mythologies',
    'mythologues',
    'mythomanies',
    'myxomatoses',
    'nacrassions',
    'nacreraient',
    'nageassions',
    'nantiraient',
    'nantissable',
    'nantissions',
    'naphtalènes',
    'naphtalines',
    'napoléonien',
    'napolitaine',
    'napolitains',
    'nappassions',
    'napperaient',
    'naquissions',
    'narcissique',
    'narcissisme',
    'narcolepsie',
    'narcotiques',
    'narguassent',
    'narguassiez',
    'narguerions',
    'narrassions',
    'narratrices',
    'narreraient',
    'nasalisâmes',
    'nasalisasse',
    'nasalisâtes',
    'nasaliserai',
    'nasaliseras',
    'nasaliserez',
    'nasalisions',
    'nasillaient',
    'nasillantes',
    'nasillardes',
    'nasillasses',
    'nasillement',
    'nasillerais',
    'nasillerait',
    'nasillèrent',
    'nasilleriez',
    'nasillerons',
    'nasilleront',
    'nasilleuses',
    'nasonnement',
    'nationalisa',
    'nationalise',
    'nationalisé',
    'nationalité',
    'nattassions',
    'natteraient',
    'naturalisai',
    'naturalisas',
    'naturalisât',
    'naturalisée',
    'naturaliser',
    'naturalises',
    'naturalisés',
    'naturalisez',
    'naturalisme',
    'naturaliste',
    'naufrageais',
    'naufrageait',
    'naufrageant',
    'naufrageons',
    'naufragerai',
    'naufrageras',
    'naufragerez',
    'naufrageurs',
    'naufrageuse',
    'naufragions',
    'nauséabonde',
    'nauséabonds',
    'nautonières',
    'navetteuses',
    'naviculaire',
    'navigateurs',
    'navigations',
    'navigatrice',
    'naviguaient',
    'naviguantes',
    'naviguasses',
    'naviguerais',
    'naviguerait',
    'naviguèrent',
    'navigueriez',
    'naviguerons',
    'navigueront',
    'navisphères',
    'navrassions',
    'navreraient',
    'nazaréennes',
    'néantisâmes',
    'néantisasse',
    'néantisâtes',
    'néantiserai',
    'néantiseras',
    'néantiserez',
    'néantisions',
    'nébuliseurs',
    'nébulosités',
    'nécessaires',
    'nécessitais',
    'nécessitait',
    'nécessitant',
    'nécessitées',
    'nécessitent',
    'nécessitera',
    'nécessiteux',
    'nécessitiez',
    'nécessitons',
    'nécrologies',
    'nécrologues',
    'nécromancie',
    'nécrophages',
    'nécrophilie',
    'nécrophores',
    'nécrosaient',
    'nécrosasses',
    'nécroserais',
    'nécroserait',
    'nécrosèrent',
    'nécroseriez',
    'nécroserons',
    'nécroseront',
    'néerlandais',
    'négatoscope',
    'négligeable',
    'négligeâmes',
    'négligeante',
    'négligeants',
    'négligeasse',
    'négligeâtes',
    'négligences',
    'négligentes',
    'négligerais',
    'négligerait',
    'négligèrent',
    'négligeriez',
    'négligerons',
    'négligeront',
    'négociables',
    'négociaient',
    'négociantes',
    'négociasses',
    'négociateur',
    'négociation',
    'négocierais',
    'négocierait',
    'négocièrent',
    'négocieriez',
    'négocierons',
    'négocieront',
    'négrillonne',
    'nématocyste',
    'néocomienne',
    'néo-grecque',
    'néolithique',
    'néologiques',
    'néologismes',
    'néoplasique',
    'népériennes',
    'néphrétique',
    'néphrologie',
    'néphrologue',
    'néroniennes',
    'nervuraient',
    'nervurasses',
    'nervurerais',
    'nervurerait',
    'nervurèrent',
    'nervureriez',
    'nervurerons',
    'nervureront',
    'nestorienne',
    'nettoiement',
    'nettoierais',
    'nettoierait',
    'nettoieriez',
    'nettoierons',
    'nettoieront',
    'nettoyaient',
    'nettoyantes',
    'nettoyasses',
    'nettoyèrent',
    'nettoyeuses',
    'neuroblaste',
    'neurologies',
    'neurologues',
    'neuroniques',
    'neurotropes',
    'neutralisai',
    'neutralisas',
    'neutralisât',
    'neutralisée',
    'neutraliser',
    'neutralises',
    'neutralisés',
    'neutralisez',
    'neutralisme',
    'neutraliste',
    'neutralités',
    'neutronique',
    'neutrophile',
    'névralgique',
    'névritiques',
    'névropathes',
    'névropathie',
    'névrotiques',
    'niaisassent',
    'niaisassiez',
    'niaiserions',
    'nichassions',
    'nicheraient',
    'nickelaient',
    'nickelasses',
    'nickelèrent',
    'nickélifère',
    'nickellerai',
    'nickelleras',
    'nickellerez',
    'nicotinisai',
    'nicotinisas',
    'nicotinisât',
    'nicotinisée',
    'nicotiniser',
    'nicotinises',
    'nicotinisés',
    'nicotinisez',
    'nicotinisme',
    'nictitantes',
    'nictitation',
    'nidifiaient',
    'nidifiasses',
    'nidifierais',
    'nidifierait',
    'nidifièrent',
    'nidifieriez',
    'nidifierons',
    'nidifieront',
    'niellassent',
    'niellassiez',
    'niellerions',
    'nietzschéen',
    'nigauderies',
    'nimbassions',
    'nimberaient',
    'nippassions',
    'nipperaient',
    'nitescences',
    'nitrassions',
    'nitrataient',
    'nitratasses',
    'nitratation',
    'nitraterais',
    'nitraterait',
    'nitratèrent',
    'nitrateriez',
    'nitraterons',
    'nitrateront',
    'nitreraient',
    'nitrifiâmes',
    'nitrifiasse',
    'nitrifiâtes',
    'nitrifierai',
    'nitrifieras',
    'nitrifierez',
    'nitrifiions',
    'nitrophiles',
    'nitrosation',
    'nitruraient',
    'nitrurasses',
    'nitruration',
    'nitrurerais',
    'nitrurerait',
    'nitrurèrent',
    'nitrureriez',
    'nitrurerons',
    'nitrureront',
    'nivelassent',
    'nivelassiez',
    'nivellement',
    'nivellerais',
    'nivellerait',
    'nivelleriez',
    'nivellerons',
    'nivelleront',
    'nivernaises',
    'nobiliaires',
    'noblaillons',
    'noctambules',
    'noctiluques',
    'noircirions',
    'noircissais',
    'noircissait',
    'noircissant',
    'noircissent',
    'noircisseur',
    'noircissiez',
    'noircissons',
    'noircissure',
    'nolisassent',
    'nolisassiez',
    'noliserions',
    'nomadisâmes',
    'nomadisasse',
    'nomadisâtes',
    'nomadiserai',
    'nomadiseras',
    'nomadiserez',
    'nomadisions',
    'nombrassent',
    'nombrassiez',
    'nombrilisme',
    'nominalisai',
    'nominalisas',
    'nominalisât',
    'nominalisée',
    'nominaliser',
    'nominalises',
    'nominalisés',
    'nominalisez',
    'nominalisme',
    'nominaliste',
    'nominations',
    'nominatives',
    'nommassions',
    'nommeraient',
    'nomogrammes',
    'nomographie',
    'nonagénaire',
    'non-alignée',
    'non-alignés',
    'nonantièmes',
    'nonchalance',
    'nonchalante',
    'nonchalants',
    'nonchaloirs',
    'nonciatures',
    'non-croyant',
    'non-engagée',
    'non-engagés',
    'non-fumeurs',
    'non-fumeuse',
    'non-initiée',
    'non-initiés',
    'non-inscrit',
    'nonpareille',
    'non-respect',
    'non-valeurs',
    'non-viables',
    'non-violent',
    'noologiques',
    'nord-coréen',
    'nordiraient',
    'nordissions',
    'normalement',
    'normalienne',
    'normalisais',
    'normalisait',
    'normalisant',
    'normalisées',
    'normalisent',
    'normalisera',
    'normalisiez',
    'normalisons',
    'norvégienne',
    'nosographie',
    'nostalgique',
    'notabilités',
    'notablement',
    'notairesses',
    'notifiaient',
    'notifiasses',
    'notifierais',
    'notifierait',
    'notifièrent',
    'notifieriez',
    'notifierons',
    'notifieront',
    'notionnelle',
    'notoirement',
    'nourricerie',
    'nourricière',
    'nourriciers',
    'nourrissage',
    'nourrissais',
    'nourrissait',
    'nourrissant',
    'nourrissent',
    'nourrisseur',
    'nourrissiez',
    'nourrissons',
    'nouveau-nés',
    'nouvelliste',
    'noyautaient',
    'noyautasses',
    'noyauterais',
    'noyauterait',
    'noyautèrent',
    'noyauteriez',
    'noyauterons',
    'noyauteront',
    'nuançassent',
    'nuançassiez',
    'nuancerions',
    'nucléassent',
    'nucléassiez',
    'nucléerions',
    'nucléonique',
    'nucléotides',
    'nuisissions',
    'numérateurs',
    'numérations',
    'numérisâmes',
    'numérisasse',
    'numérisâtes',
    'numériserai',
    'numériseras',
    'numériserez',
    'numérisions',
    'numérotages',
    'numérotâmes',
    'numérotasse',
    'numérotâtes',
    'numéroterai',
    'numéroteras',
    'numéroterez',
    'numéroteurs',
    'numérotions',
    'nuncupation',
    'nuptialités',
    'nyctalopies',
    'nycthémères',
    'nymphalidés',
    'nymphomanes',
    'nymphomanie',
    'obédiencier',
    'obédientiel',
    'obéissaient',
    'obéissances',
    'obéissantes',
    'obérassions',
    'obéreraient',
    'objectaient',
    'objectasses',
    'objecterais',
    'objecterait',
    'objectèrent',
    'objecteriez',
    'objecterons',
    'objecteront',
    'objectivais',
    'objectivait',
    'objectivant',
    'objectivées',
    'objectivent',
    'objectivera',
    'objectiviez',
    'objectivité',
    'objectivons',
    'objurgation',
    'objurguâmes',
    'objurguasse',
    'objurguâtes',
    'objurguerai',
    'objurgueras',
    'objurguerez',
    'objurguions',
    'obligataire',
    'obligations',
    'obligatoire',
    'obligeaient',
    'obligeances',
    'obligeantes',
    'obligeasses',
    'obligerions',
    'obliquaient',
    'obliquasses',
    'obliquement',
    'obliquerais',
    'obliquerait',
    'obliquèrent',
    'obliqueriez',
    'obliquerons',
    'obliqueront',
    'oblitérâmes',
    'oblitérasse',
    'oblitérâtes',
    'oblitérerai',
    'oblitéreras',
    'oblitérerez',
    'oblitérions',
    'obnubilâmes',
    'obnubilasse',
    'obnubilâtes',
    'obnubilerai',
    'obnubileras',
    'obnubilerez',
    'obnubilions',
    'obombraient',
    'obombrasses',
    'obombrerais',
    'obombrerait',
    'obombrèrent',
    'obombreriez',
    'obombrerons',
    'obombreront',
    'obscurcîmes',
    'obscurcirai',
    'obscurciras',
    'obscurcirez',
    'obscurcisse',
    'obscurcîtes',
    'obscurément',
    'obsécration',
    'obsédassent',
    'obsédassiez',
    'obséderions',
    'obséquieuse',
    'observables',
    'observaient',
    'observances',
    'observasses',
    'observateur',
    'observation',
    'observerais',
    'observerait',
    'observèrent',
    'observeriez',
    'observerons',
    'observeront',
    'obsidiennes',
    'obsidionale',
    'obsidionaux',
    'obsolescent',
    'obstétrical',
    'obstétrique',
    'obstination',
    'obstinément',
    'obstinerons',
    'obstruaient',
    'obstruasses',
    'obstructifs',
    'obstruction',
    'obstructive',
    'obstruerais',
    'obstruerait',
    'obstruèrent',
    'obstrueriez',
    'obstruerons',
    'obstrueront',
    'obtempérais',
    'obtempérait',
    'obtempérant',
    'obtempèrent',
    'obtempérera',
    'obtempériez',
    'obtempérons',
    'obtiendrais',
    'obtiendrait',
    'obtiendriez',
    'obtiendrons',
    'obtiendront',
    'obtinssions',
    'obturassent',
    'obturassiez',
    'obturateurs',
    'obturations',
    'obturatrice',
    'obturerions',
    'obtusangles',
    'obviassions',
    'obviendrais',
    'obviendrait',
    'obviendriez',
    'obviendrons',
    'obviendront',
    'obvieraient',
    'obvinssions',
    'occasionnai',
    'occasionnas',
    'occasionnât',
    'occasionnée',
    'occasionnel',
    'occasionner',
    'occasionnes',
    'occasionnés',
    'occasionnez',
    'occidentale',
    'occidentaux',
    'occipitales',
    'occluraient',
    'occlussions',
    'occultaient',
    'occultasses',
    'occultation',
    'occulterais',
    'occulterait',
    'occultèrent',
    'occulteriez',
    'occulterons',
    'occulteront',
    'occultismes',
    'occultistes',
    'occupassent',
    'occupassiez',
    'occupations',
    'occuperions',
    'occurrences',
    'occurrentes',
    'océanideLMS',
    'océaniennes',
    'océanologie',
    'océanologue',
    'octaviaient',
    'octaviasses',
    'octavierais',
    'octavierait',
    'octavièrent',
    'octavieriez',
    'octavierons',
    'octavieront',
    'octogénaire',
    'octogonales',
    'octosyllabe',
    'octroierais',
    'octroierait',
    'octroieriez',
    'octroierons',
    'octroieront',
    'octroyaient',
    'octroyasses',
    'octroyèrent',
    'octuplaient',
    'octuplasses',
    'octuplerais',
    'octuplerait',
    'octuplèrent',
    'octupleriez',
    'octuplerons',
    'octupleront',
    'ocularistes',
    'odieusement',
    'odontalgies',
    'odontologie',
    'odoriférant',
    'oecuménique',
    'oecuménisme',
    'oedémateuse',
    'oedipiennes',
    'oeil-de-pie',
    'oeilletonna',
    'oeilletonne',
    'oeilletonné',
    'oenanthique',
    'oenologique',
    'oenométries',
    'oesophagien',
    'oesophagite',
    'oestrogènes',
    'oeuvrassent',
    'oeuvrassiez',
    'oeuvrerions',
    'offensaient',
    'offensantes',
    'offensasses',
    'offenserais',
    'offenserait',
    'offensèrent',
    'offenseriez',
    'offenserons',
    'offenseront',
    'offertoires',
    'officiaient',
    'officialisa',
    'officialise',
    'officialisé',
    'officialité',
    'officiantes',
    'officiasses',
    'officielles',
    'officierais',
    'officierait',
    'officièrent',
    'officieriez',
    'officierons',
    'officieront',
    'officieuses',
    'officinales',
    'offriraient',
    'offrissions',
    'offsettiste',
    'offusquâmes',
    'offusquasse',
    'offusquâtes',
    'offusquerai',
    'offusqueras',
    'offusquerez',
    'offusquions',
    'oignonières',
    'oiseau-lyre',
    'oiselassent',
    'oiselassiez',
    'oisellerais',
    'oisellerait',
    'oiselleries',
    'oiselleriez',
    'oisellerons',
    'oiselleront',
    'oléagineuse',
    'oléiculteur',
    'oléiculture',
    'oligarchies',
    'olympiennes',
    'ombellifère',
    'ombilicales',
    'ombiliquées',
    'ombrageâmes',
    'ombrageasse',
    'ombrageâtes',
    'ombragerais',
    'ombragerait',
    'ombragèrent',
    'ombrageriez',
    'ombragerons',
    'ombrageront',
    'ombrageuses',
    'ombrassions',
    'ombreraient',
    'omettraient',
    'omnicolores',
    'omnipotence',
    'omnipotente',
    'omnipotents',
    'omniprésent',
    'omniscience',
    'omnisciente',
    'omniscients',
    'onctuosités',
    'ondoiements',
    'ondoierions',
    'ondoyassent',
    'ondoyassiez',
    'ondulassent',
    'ondulassiez',
    'ondulations',
    'ondulatoire',
    'ondulerions',
    'onguiculées',
    'onguiformes',
    'onguligrade',
    'onirologies',
    'onirologues',
    'oniromancie',
    'onomastique',
    'onomatopées',
    'ontogenèses',
    'ontogénique',
    'ontologique',
    'ontologisme',
    'onzièmement',
    'oolithiques',
    'opacifiâmes',
    'opacifiasse',
    'opacifiâtes',
    'opacifierai',
    'opacifieras',
    'opacifierez',
    'opacifiions',
    'opacimétrie',
    'opalescence',
    'opalescente',
    'opalescents',
    'opalisaient',
    'opalisasses',
    'opaliserais',
    'opaliserait',
    'opalisèrent',
    'opaliseriez',
    'opaliserons',
    'opaliseront',
    'opérassions',
    'opératoires',
    'opératrices',
    'operculaire',
    'opéreraient',
    'ophicléides',
    'ophidiennes',
    'ophioglosse',
    'ophiolâtrie',
    'ophiologies',
    'ophtalmique',
    'opiaçassent',
    'opiaçassiez',
    'opiacerions',
    'opinassions',
    'opineraient',
    'opiniâtrées',
    'opiniâtreté',
    'opiniâtriez',
    'opiniâtrons',
    'opisthodome',
    'opothérapie',
    'opportunité',
    'opposassent',
    'opposassiez',
    'opposerions',
    'oppositions',
    'oppressâmes',
    'oppressante',
    'oppressants',
    'oppressasse',
    'oppressâtes',
    'oppresserai',
    'oppresseras',
    'oppresserez',
    'oppresseurs',
    'oppressions',
    'oppressives',
    'opprimaient',
    'opprimasses',
    'opprimerais',
    'opprimerait',
    'opprimèrent',
    'opprimeriez',
    'opprimerons',
    'opprimeront',
    'opticiennes',
    'optimalisai',
    'optimalisas',
    'optimalisât',
    'optimalisée',
    'optimaliser',
    'optimalises',
    'optimalisés',
    'optimalisez',
    'optimisâmes',
    'optimisasse',
    'optimisâtes',
    'optimiserai',
    'optimiseras',
    'optimiserez',
    'optimisions',
    'optionnelle',
    'optométries',
    'orangeaient',
    'orangeasses',
    'orangeraies',
    'orangerions',
    'orang-outan',
    'orbiculaire',
    'orbitassent',
    'orbitassiez',
    'orbiterions',
    'orchestique',
    'orchestrais',
    'orchestrait',
    'orchestrale',
    'orchestrant',
    'orchestraux',
    'orchestrées',
    'orchestrent',
    'orchestrera',
    'orchestriez',
    'orchestrons',
    'ordinateurs',
    'ordinations',
    'ordinatrice',
    'ordonnaient',
    'ordonnançai',
    'ordonnanças',
    'ordonnançât',
    'ordonnancée',
    'ordonnancer',
    'ordonnances',
    'ordonnancés',
    'ordonnancez',
    'ordonnasses',
    'ordonnateur',
    'ordonnerais',
    'ordonnerait',
    'ordonnèrent',
    'ordonneriez',
    'ordonnerons',
    'ordonneront',
    'oreillardes',
    'oreillettes',
    'orfèvreries',
    'organicisme',
    'organisable',
    'organisâmes',
    'organisasse',
    'organisâtes',
    'organiserai',
    'organiseras',
    'organiserez',
    'organisions',
    'organsinais',
    'organsinait',
    'organsinant',
    'organsinées',
    'organsinent',
    'organsiniez',
    'organsinons',
    'orgastiques',
    'orgueilleux',
    'orichalques',
    'orientables',
    'orientaient',
    'orientasses',
    'orientateur',
    'orientation',
    'orientement',
    'orienterais',
    'orienterait',
    'orientèrent',
    'orienteriez',
    'orienterons',
    'orienteront',
    'orienteuses',
    'originaires',
    'originalité',
    'originelles',
    'oringuaient',
    'oringuasses',
    'oringuerais',
    'oringuerait',
    'oringuèrent',
    'oringueriez',
    'oringuerons',
    'oringueront',
    'orléanistes',
    'ornemaniste',
    'ornementais',
    'ornementait',
    'ornementale',
    'ornementant',
    'ornementaux',
    'ornementées',
    'ornementent',
    'ornementera',
    'ornementiez',
    'ornementons',
    'ornithogale',
    'orogéniques',
    'orographies',
    'orpaillages',
    'orpailleurs',
    'orphelinats',
    'orphéoniste',
    'orthocentre',
    'orthodontie',
    'orthodoxies',
    'orthogenèse',
    'orthogénies',
    'orthogonale',
    'orthogonaux',
    'orthographe',
    'orthopédies',
    'orthophonie',
    'orthoptères',
    'oryctéropes',
    'oscillaient',
    'oscillantes',
    'oscillasses',
    'oscillation',
    'oscillerais',
    'oscillerait',
    'oscillèrent',
    'oscilleriez',
    'oscillerons',
    'oscilleront',
    'osculations',
    'ossianiques',
    'ossifiaient',
    'ossifiasses',
    'ossifierais',
    'ossifierait',
    'ossifièrent',
    'ossifieriez',
    'ossifierons',
    'ossifieront',
    'ostensibles',
    'ostentation',
    'ostéoblaste',
    'ostéoclasie',
    'ostéogenèse',
    'ostéologies',
    'ostéopathes',
    'ostéopathie',
    'ostéophytes',
    'ostéoporose',
    'ostéotomies',
    'ostracisais',
    'ostracisait',
    'ostracisant',
    'ostracisées',
    'ostracisent',
    'ostracisera',
    'ostracisiez',
    'ostracismes',
    'ostracisons',
    'ostréicoles',
    'ostrogothes',
    'ouananiches',
    'ouatassions',
    'ouateraient',
    'ouatinaient',
    'ouatinasses',
    'ouatinerais',
    'ouatinerait',
    'ouatinèrent',
    'ouatineriez',
    'ouatinerons',
    'ouatineront',
    'oubliassent',
    'oubliassiez',
    'oublierions',
    'ouillassent',
    'ouillassiez',
    'ouillerions',
    'ouraliennes',
    'ourdiraient',
    'ourdissages',
    'ourdisseurs',
    'ourdisseuse',
    'ourdissions',
    'ourdissoirs',
    'ourlassions',
    'ourleraient',
    'outillaient',
    'outillasses',
    'outillerais',
    'outillerait',
    'outillèrent',
    'outilleriez',
    'outillerons',
    'outilleront',
    'outrageâmes',
    'outrageante',
    'outrageants',
    'outrageasse',
    'outrageâtes',
    'outragerais',
    'outragerait',
    'outragèrent',
    'outrageriez',
    'outragerons',
    'outrageront',
    'outrageuses',
    'outrancière',
    'outranciers',
    'outrassions',
    'outrepassai',
    'outrepassas',
    'outrepassât',
    'outrepassée',
    'outrepasser',
    'outrepasses',
    'outrepassés',
    'outrepassez',
    'outreraient',
    'outre-tombe',
    'ouvertement',
    'ouvrageâmes',
    'ouvrageasse',
    'ouvrageâtes',
    'ouvragerais',
    'ouvragerait',
    'ouvragèrent',
    'ouvrageriez',
    'ouvragerons',
    'ouvrageront',
    'ouvrassions',
    'ouvre-gants',
    'ouvreraient',
    'ouvriérisme',
    'ouvriériste',
    'ouvriraient',
    'ouvrissions',
    'ovalbumines',
    'ovalisaient',
    'ovalisasses',
    'ovalisation',
    'ovaliserais',
    'ovaliserait',
    'ovalisèrent',
    'ovaliseriez',
    'ovaliserons',
    'ovaliseront',
    'ovationnais',
    'ovationnait',
    'ovationnant',
    'ovationnées',
    'ovationnent',
    'ovationnera',
    'ovationniez',
    'ovationnons',
    'ovipositeur',
    'ovovivipare',
    'ovulassions',
    'ovuleraient',
    'oxhydriques',
    'oxycarbonée',
    'oxycarbonés',
    'oxychlorure',
    'oxycoupages',
    'oxydassions',
    'oxyderaient',
    'oxygénaient',
    'oxygénasses',
    'oxygénation',
    'oxygénerais',
    'oxygénerait',
    'oxygénèrent',
    'oxygéneriez',
    'oxygénerons',
    'oxygéneront',
    'oxysulfures',
    'ozonisaient',
    'ozonisasses',
    'ozonisateur',
    'ozonisation',
    'ozoniserais',
    'ozoniserait',
    'ozonisèrent',
    'ozoniseriez',
    'ozoniserons',
    'ozoniseront',
    'pacageaient',
    'pacageasses',
    'pacagerions',
    'pachydermes',
    'pachydermie',
    'pacifiaient',
    'pacifiasses',
    'pacifierais',
    'pacifierait',
    'pacifièrent',
    'pacifieriez',
    'pacifierons',
    'pacifieront',
    'pacquassent',
    'pacquassiez',
    'pacquerions',
    'pactisaient',
    'pactisasses',
    'pactiserais',
    'pactiserait',
    'pactisèrent',
    'pactiseriez',
    'pactiserons',
    'pactiseront',
    'pagaierions',
    'paganisâmes',
    'paganisasse',
    'paganisâtes',
    'paganiserai',
    'paganiseras',
    'paganiserez',
    'paganisions',
    'pagayassent',
    'pagayassiez',
    'pagayerions',
    'pageassions',
    'pageotaient',
    'pageotasses',
    'pageoterais',
    'pageoterait',
    'pageotèrent',
    'pageoteriez',
    'pageoterons',
    'pageoteront',
    'paginassent',
    'paginassiez',
    'paginations',
    'paginerions',
    'pagnotaient',
    'pagnotasses',
    'pagnoterais',
    'pagnoterait',
    'pagnotèrent',
    'pagnoteriez',
    'pagnoterons',
    'pagnoteront',
    'paidologies',
    'paillardais',
    'paillardait',
    'paillardant',
    'paillardées',
    'paillardent',
    'paillardera',
    'paillardiez',
    'paillardise',
    'paillardons',
    'paillassent',
    'paillassiez',
    'paillassons',
    'paillerions',
    'pailletages',
    'pailletâmes',
    'pailletasse',
    'pailletâtes',
    'pailleteurs',
    'pailletions',
    'paillettent',
    'paillettera',
    'paillonnais',
    'paillonnait',
    'paillonnant',
    'paillonnées',
    'paillonnent',
    'paillonnera',
    'paillonniez',
    'paillonnons',
    'paisselâmes',
    'paisselasse',
    'paisselâtes',
    'paisselions',
    'paissellent',
    'paissellera',
    'pajotassent',
    'pajotassiez',
    'pajoterions',
    'pakistanais',
    'palabraient',
    'palabrasses',
    'palabrèrent',
    'palancrâmes',
    'palancrasse',
    'palancrâtes',
    'palancrerai',
    'palancreras',
    'palancrerez',
    'palancrions',
    'palangrâmes',
    'palangrasse',
    'palangrâtes',
    'palangrerai',
    'palangreras',
    'palangrerez',
    'palangrions',
    'palanguâmes',
    'palanguasse',
    'palanguâtes',
    'palanguerai',
    'palangueras',
    'palanguerez',
    'palanguions',
    'palanquâmes',
    'palanquasse',
    'palanquâtes',
    'palanquerai',
    'palanqueras',
    'palanquerez',
    'palanquions',
    'palatalisée',
    'palatalisés',
    'palefrenier',
    'paléoclimat',
    'paléographe',
    'paléozoïque',
    'palestinien',
    'palettaient',
    'palettasses',
    'paletterais',
    'paletterait',
    'palettèrent',
    'paletteriez',
    'paletterons',
    'paletteront',
    'palettisais',
    'palettisait',
    'palettisant',
    'palettisées',
    'palettisent',
    'palettisera',
    'palettisiez',
    'palettisons',
    'palétuviers',
    'pâlichonnes',
    'palimpseste',
    'palindromes',
    'palinodique',
    'palissadais',
    'palissadait',
    'palissadant',
    'palissadées',
    'palissadent',
    'palissadera',
    'palissadiez',
    'palissadons',
    'palissaient',
    'pâlissaient',
    'palissandre',
    'pâlissantes',
    'palissasses',
    'palisserais',
    'palisserait',
    'palissèrent',
    'palisseriez',
    'palisserons',
    'palisseront',
    'palissonnai',
    'palissonnas',
    'palissonnât',
    'palissonnée',
    'palissonner',
    'palissonnes',
    'palissonnés',
    'palissonnez',
    'palliassent',
    'palliassiez',
    'palliatives',
    'pallierions',
    'palmassions',
    'palmeraient',
    'palmilobées',
    'palmipartie',
    'palmipartis',
    'palmiséquée',
    'palmiséqués',
    'palmitiques',
    'palotassent',
    'palotassiez',
    'paloterions',
    'palpassions',
    'palpébrales',
    'palperaient',
    'palpitaient',
    'palpitantes',
    'palpitasses',
    'palpitation',
    'palpiterais',
    'palpiterait',
    'palpitèrent',
    'palpiteriez',
    'palpiterons',
    'palpiteront',
    'palplanches',
    'paludéennes',
    'paludologie',
    'palynologie',
    'panachaient',
    'panachasses',
    'panacherais',
    'panacherait',
    'panachèrent',
    'panacheriez',
    'panacherons',
    'panacheront',
    'panafricain',
    'panarabisme',
    'panathénées',
    'pancalismes',
    'panclastite',
    'pancosmisme',
    'pancréatine',
    'pancréatite',
    'pandémonium',
    'panégyrique',
    'panégyriste',
    'panifiables',
    'panifiaient',
    'panifiasses',
    'panifierais',
    'panifierait',
    'panifièrent',
    'panifieriez',
    'panifierons',
    'panifieront',
    'paniquaient',
    'paniquantes',
    'paniquardes',
    'paniquasses',
    'paniquerais',
    'paniquerait',
    'paniquèrent',
    'paniqueriez',
    'paniquerons',
    'paniqueront',
    'panlogismes',
    'pannassions',
    'panneautage',
    'panneautais',
    'panneautait',
    'panneautant',
    'panneautées',
    'panneautent',
    'panneautera',
    'panneautiez',
    'panneautons',
    'panneraient',
    'panoptiques',
    'panoramiqua',
    'panoramique',
    'panoramiqué',
    'pansassions',
    'panseraient',
    'panslavisme',
    'panspermies',
    'pantelaient',
    'pantelantes',
    'pantelasses',
    'pantelèrent',
    'pantellerai',
    'pantelleras',
    'pantellerez',
    'panthéismes',
    'panthéistes',
    'pantographe',
    'pantomètres',
    'pantouflais',
    'pantouflait',
    'pantouflant',
    'pantouflard',
    'pantouflent',
    'pantouflera',
    'pantouflier',
    'pantoufliez',
    'pantouflons',
    'papavérines',
    'papelardise',
    'paperassier',
    'papilionacé',
    'papillaires',
    'papilleuses',
    'papillifère',
    'papillonnai',
    'papillonnas',
    'papillonnât',
    'papillonner',
    'papillonnes',
    'papillonnez',
    'papillotage',
    'papillotais',
    'papillotait',
    'papillotant',
    'papillotées',
    'papillotent',
    'papillotera',
    'papillotiez',
    'papillotons',
    'papotassent',
    'papotassiez',
    'papoterions',
    'papouillais',
    'papouillait',
    'papouillant',
    'papouillées',
    'papouillent',
    'papouillera',
    'papouilliez',
    'papouillons',
    'papyrologie',
    'papyrologue',
    'pâquerettes',
    'paqueteuses',
    'parabellums',
    'paraboloïde',
    'paracentèse',
    'parachevais',
    'parachevait',
    'parachevant',
    'parachevées',
    'parachèvent',
    'parachèvera',
    'paracheviez',
    'parachevons',
    'parachutage',
    'parachutais',
    'parachutait',
    'parachutant',
    'parachutées',
    'parachutent',
    'parachutera',
    'parachutiez',
    'parachutons',
    'paradassent',
    'paradassiez',
    'paraderions',
    'paradisiers',
    'paradoxales',
    'parafassent',
    'parafassiez',
    'paraferions',
    'paraffinage',
    'paraffinais',
    'paraffinait',
    'paraffinant',
    'paraffinées',
    'paraffinent',
    'paraffinera',
    'paraffiniez',
    'paraffinons',
    'parafiscale',
    'parafiscaux',
    'parafoudres',
    'paragraphes',
    'paraisonnai',
    'paraisonnas',
    'paraisonnât',
    'paraisonnée',
    'paraisonner',
    'paraisonnes',
    'paraisonnés',
    'paraisonnez',
    'paraissions',
    'paraîtrions',
    'paralangage',
    'parallélisa',
    'parallélise',
    'parallélisé',
    'paralogisme',
    'paralysâmes',
    'paralysante',
    'paralysants',
    'paralysasse',
    'paralysâtes',
    'paralyserai',
    'paralyseras',
    'paralyserez',
    'paralysions',
    'paralytique',
    'paramédical',
    'paramétrage',
    'paramétrais',
    'paramétrait',
    'paramétrant',
    'paramétrées',
    'paramètrent',
    'paramétrera',
    'paramétriez',
    'paramétrons',
    'parangonnai',
    'parangonnas',
    'parangonnât',
    'parangonnée',
    'parangonner',
    'parangonnes',
    'parangonnés',
    'parangonnez',
    'paranoïaque',
    'paranormale',
    'paranormaux',
    'paraphaient',
    'paraphasies',
    'paraphasses',
    'parapherais',
    'parapherait',
    'paraphèrent',
    'parapheriez',
    'paraphernal',
    'parapherons',
    'parapheront',
    'paraphrasai',
    'paraphrasas',
    'paraphrasât',
    'paraphrasée',
    'paraphraser',
    'paraphrases',
    'paraphrasés',
    'paraphrasez',
    'paraphrénie',
    'paraplégies',
    'parasitaire',
    'parasitâmes',
    'parasitasse',
    'parasitâtes',
    'parasiterai',
    'parasiteras',
    'parasiterez',
    'parasitions',
    'parasitisme',
    'parasitoses',
    'parastatale',
    'parastataux',
    'parcellaire',
    'parcellâmes',
    'parcellasse',
    'parcellâtes',
    'parcellerai',
    'parcelleras',
    'parcellerez',
    'parcellions',
    'parcellisai',
    'parcellisas',
    'parcellisât',
    'parcellisée',
    'parcelliser',
    'parcellises',
    'parcellisés',
    'parcellisez',
    'parcheminai',
    'parcheminas',
    'parcheminât',
    'parcheminée',
    'parcheminer',
    'parchemines',
    'parcheminés',
    'parcheminez',
    'parcimonies',
    'parcomètres',
    'parcourions',
    'parcourrais',
    'parcourrait',
    'parcourriez',
    'parcourrons',
    'parcourront',
    'parcourûmes',
    'parcourusse',
    'parcourûtes',
    'par-dessous',
    'pardonnable',
    'pardonnâmes',
    'pardonnasse',
    'pardonnâtes',
    'pardonnerai',
    'pardonneras',
    'pardonnerez',
    'pardonnions',
    'pare-balles',
    'pare-éclats',
    'parégorique',
    'parementais',
    'parementait',
    'parementant',
    'parementées',
    'parementent',
    'parementera',
    'parementiez',
    'parementons',
    'parenchymes',
    'parentalies',
    'parentérale',
    'parentéraux',
    'parenthésée',
    'parenthésés',
    'parenthèses',
    'parerassent',
    'parerassiez',
    'pare-soleil',
    'paressaient',
    'paressasses',
    'paresserais',
    'paresserait',
    'paressèrent',
    'paresseriez',
    'paresserons',
    'paresseront',
    'paresseuses',
    'paresthésie',
    'parfaisions',
    'parfassions',
    'parferaient',
    'parfilaient',
    'parfilasses',
    'parfilerais',
    'parfilerait',
    'parfilèrent',
    'parfileriez',
    'parfilerons',
    'parfileront',
    'parfissions',
    'parfondîmes',
    'parfondions',
    'parfondisse',
    'parfondîtes',
    'parfondrais',
    'parfondrait',
    'parfondriez',
    'parfondrons',
    'parfondront',
    'parfumaient',
    'parfumasses',
    'parfumerais',
    'parfumerait',
    'parfumèrent',
    'parfumeries',
    'parfumeriez',
    'parfumerons',
    'parfumeront',
    'parfumeuses',
    'pariassions',
    'parieraient',
    'pariétaires',
    'paripennées',
    'parisiennes',
    'parjuraient',
    'parjurasses',
    'parjurerais',
    'parjurerait',
    'parjurèrent',
    'parjureriez',
    'parjurerons',
    'parjureront',
    'parkérisais',
    'parkérisait',
    'parkérisant',
    'parkérisées',
    'parkérisent',
    'parkérisera',
    'parkérisiez',
    'parkérisons',
    'parlassions',
    'parlementai',
    'parlementas',
    'parlementât',
    'parlementer',
    'parlementes',
    'parlementez',
    'parleraient',
    'parlotaient',
    'parlotasses',
    'parloterais',
    'parloterait',
    'parlotèrent',
    'parloteriez',
    'parloterons',
    'parloteront',
    'parmentures',
    'parnassiens',
    'parodiaient',
    'parodiasses',
    'parodierais',
    'parodierait',
    'parodièrent',
    'parodieriez',
    'parodierons',
    'parodieront',
    'paroissiale',
    'paroissiaux',
    'paroissiens',
    'paronomases',
    'paronymique',
    'parotidiens',
    'parotidites',
    'paroxysmale',
    'paroxysmaux',
    'parpaillote',
    'parpaillots',
    'parquassent',
    'parquassiez',
    'parquerions',
    'parquetages',
    'parquetâmes',
    'parquetasse',
    'parquetâtes',
    'parqueterie',
    'parqueteurs',
    'parquetions',
    'parquettent',
    'parquettera',
    'parrainages',
    'parrainâmes',
    'parrainasse',
    'parrainâtes',
    'parrainerai',
    'parraineras',
    'parrainerez',
    'parrainions',
    'parsemaient',
    'parsemasses',
    'parsèmerais',
    'parsèmerait',
    'parsemèrent',
    'parsèmeriez',
    'parsèmerons',
    'parsèmeront',
    'partageable',
    'partageâmes',
    'partageasse',
    'partageâtes',
    'partagerais',
    'partagerait',
    'partagèrent',
    'partageriez',
    'partagerons',
    'partageront',
    'partageuses',
    'partenaires',
    'partenariat',
    'partialités',
    'participais',
    'participait',
    'participant',
    'participent',
    'participera',
    'participial',
    'participiez',
    'participons',
    'particulier',
    'partiraient',
    'partissions',
    'partitionna',
    'partitionne',
    'partitionné',
    'parturiente',
    'parturition',
    'parvenaient',
    'parviendrai',
    'parviendras',
    'parviendrez',
    'parviennent',
    'parvinssent',
    'parvinssiez',
    'passacaille',
    'passassions',
    'passe-droit',
    'passe-lacet',
    'passementai',
    'passementas',
    'passementât',
    'passementée',
    'passementer',
    'passementes',
    'passementés',
    'passementez',
    'passe-passe',
    'passe-pieds',
    'passe-plats',
    'passepoilai',
    'passepoilas',
    'passepoilât',
    'passepoilée',
    'passepoiler',
    'passepoiles',
    'passepoilés',
    'passepoilez',
    'passeraient',
    'passerelles',
    'passe-roses',
    'passe-temps',
    'passiflores',
    'passionnais',
    'passionnait',
    'passionnant',
    'passionnées',
    'passionnels',
    'passionnent',
    'passionnera',
    'passionniez',
    'passionnons',
    'passivation',
    'passivement',
    'pastellâmes',
    'pastellasse',
    'pastellâtes',
    'pastellerai',
    'pastelleras',
    'pastellerez',
    'pastellions',
    'pastelliste',
    'pastenagues',
    'pasteuriens',
    'pasteurisai',
    'pasteurisas',
    'pasteurisât',
    'pasteurisée',
    'pasteuriser',
    'pasteurises',
    'pasteurisés',
    'pasteurisez',
    'pastichâmes',
    'pastichasse',
    'pastichâtes',
    'pasticherai',
    'pasticheras',
    'pasticherez',
    'pasticheurs',
    'pasticheuse',
    'pastichions',
    'pastillâmes',
    'pastillasse',
    'pastillâtes',
    'pastillerai',
    'pastilleras',
    'pastillerez',
    'pastillions',
    'pastoureaux',
    'pastourelle',
    'patafiolais',
    'patafiolait',
    'patafiolant',
    'patafiolées',
    'patafiolent',
    'patafiolera',
    'patafioliez',
    'patafiolons',
    'pataugeages',
    'pataugeâmes',
    'pataugeasse',
    'pataugeâtes',
    'pataugeoire',
    'pataugerais',
    'pataugerait',
    'pataugèrent',
    'pataugeriez',
    'pataugerons',
    'pataugeront',
    'pataugeuses',
    'patelinages',
    'patelinâmes',
    'patelinasse',
    'patelinâtes',
    'patelinerai',
    'patelineras',
    'patelinerez',
    'patelinerie',
    'patelinions',
    'patentables',
    'patentaient',
    'patentasses',
    'patenterais',
    'patenterait',
    'patentèrent',
    'patenteriez',
    'patenterons',
    'patenteront',
    'paternelles',
    'pathétiques',
    'pathétismes',
    'pathogénies',
    'pathologies',
    'patibulaire',
    'patientâmes',
    'patientasse',
    'patientâtes',
    'patienterai',
    'patienteras',
    'patienterez',
    'patientions',
    'patinassent',
    'patinassiez',
    'patinerions',
    'pâtissaient',
    'pâtissasses',
    'pâtisserais',
    'pâtisserait',
    'pâtissèrent',
    'pâtisseries',
    'pâtisseriez',
    'pâtisserons',
    'pâtisseront',
    'pâtissières',
    'patoisaient',
    'patoisantes',
    'patoisasses',
    'patoiserais',
    'patoiserait',
    'patoisèrent',
    'patoiseriez',
    'patoiserons',
    'patoiseront',
    'patouillais',
    'patouillait',
    'patouillant',
    'patouillées',
    'patouillent',
    'patouillera',
    'patouilliez',
    'patouillons',
    'patriarcale',
    'patriarcats',
    'patriarcaux',
    'patriarches',
    'patricienne',
    'patrilocale',
    'patrilocaux',
    'patrimoines',
    'patrimonial',
    'patriotarde',
    'patriotards',
    'patriotique',
    'patriotisme',
    'patristique',
    'patrologies',
    'patronnâmes',
    'patronnasse',
    'patronnâtes',
    'patronnerai',
    'patronneras',
    'patronnerez',
    'patronnesse',
    'patronnions',
    'patrouillai',
    'patrouillas',
    'patrouillât',
    'patrouiller',
    'patrouilles',
    'patrouillez',
    'pattassions',
    'patteraient',
    'pâturassent',
    'pâturassiez',
    'pâturerions',
    'paulinienne',
    'paulinismes',
    'paumassions',
    'paumeraient',
    'paumoierais',
    'paumoierait',
    'paumoieriez',
    'paumoierons',
    'paumoieront',
    'paumoyaient',
    'paumoyasses',
    'paumoyèrent',
    'paupérisais',
    'paupérisait',
    'paupérisant',
    'paupérisées',
    'paupérisent',
    'paupérisera',
    'paupérisiez',
    'paupérismes',
    'paupérisons',
    'pausassions',
    'pauseraient',
    'pauses-café',
    'pavanassent',
    'pavanassiez',
    'pavanerions',
    'pavimenteux',
    'pavoisaient',
    'pavoisasses',
    'pavoisement',
    'pavoiserais',
    'pavoiserait',
    'pavoisèrent',
    'pavoiseriez',
    'pavoiserons',
    'pavoiseront',
    'paysagistes',
    'paysannerie',
    'peaufinâmes',
    'peaufinasse',
    'peaufinâtes',
    'peaufinerai',
    'peaufineras',
    'peaufinerez',
    'peaufinions',
    'peaussaient',
    'peaussasses',
    'peausserais',
    'peausserait',
    'peaussèrent',
    'peausseries',
    'peausseriez',
    'peausserons',
    'peausseront',
    'peccadilles',
    'péchassions',
    'pêchassions',
    'pechblendes',
    'pécheraient',
    'pêcheraient',
    'pécheresses',
    'péclotaient',
    'péclotasses',
    'pécloterais',
    'pécloterait',
    'péclotèrent',
    'pécloteriez',
    'pécloterons',
    'pécloteront',
    'pécuniaires',
    'pédagogique',
    'pédalassent',
    'pédalassiez',
    'pédalerions',
    'pédanteries',
    'pédantesque',
    'pédantismes',
    'pédérasties',
    'pédiculaire',
    'pédiculoses',
    'pédiplaines',
    'pédogenèses',
    'pédonculées',
    'pédophilies',
    'pedzouilles',
    'peignassent',
    'peignassiez',
    'peignerions',
    'peignissent',
    'peignissiez',
    'peinassions',
    'peindraient',
    'peineraient',
    'peinturâmes',
    'peinturasse',
    'peinturâtes',
    'peinturerai',
    'peintureras',
    'peinturerez',
    'peinturions',
    'peinturlura',
    'peinturlure',
    'peinturluré',
    'péjorations',
    'péjoratives',
    'pélagiennes',
    'pélargonium',
    'pélasgienne',
    'pélasgiques',
    'pèlerinages',
    'pellagreuse',
    'pelletaient',
    'pelletasses',
    'pelletèrent',
    'pelleteries',
    'pelleteuses',
    'pelletières',
    'pelletterai',
    'pelletteras',
    'pelletterez',
    'pelliculage',
    'pelliculées',
    'pelliculeux',
    'pelotassent',
    'pelotassiez',
    'peloterions',
    'pelotonnais',
    'pelotonnait',
    'pelotonnant',
    'pelotonnées',
    'pelotonnent',
    'pelotonnera',
    'pelotonniez',
    'pelotonnons',
    'peluchaient',
    'peluchasses',
    'pelucherais',
    'pelucherait',
    'peluchèrent',
    'pelucheriez',
    'pelucherons',
    'pelucheront',
    'pelucheuses',
    'pénalisâmes',
    'pénalisasse',
    'pénalisâtes',
    'pénaliserai',
    'pénaliseras',
    'pénaliserez',
    'pénalisions',
    'pénardement',
    'penchassent',
    'penchassiez',
    'pencherions',
    'pendeloques',
    'pendillâmes',
    'pendillasse',
    'pendillâtes',
    'pendillerai',
    'pendilleras',
    'pendillerez',
    'pendillions',
    'pendissions',
    'pendouillai',
    'pendouillas',
    'pendouillât',
    'pendouiller',
    'pendouilles',
    'pendouillez',
    'pendulaient',
    'pendulaires',
    'pendulasses',
    'pendulerais',
    'pendulerait',
    'pendulèrent',
    'penduleriez',
    'pendulerons',
    'penduleront',
    'pendulettes',
    'pénéplaines',
    'pénétrables',
    'pénétraient',
    'pénétrantes',
    'pénétrasses',
    'pénétration',
    'pénétrerais',
    'pénétrerait',
    'pénétrèrent',
    'pénétreriez',
    'pénétrerons',
    'pénétreront',
    'péniblement',
    'pénicillées',
    'pénicilline',
    'pénicillium',
    'pénitencier',
    'pénitential',
    'pénitentiel',
    'penniformes',
    'pensassions',
    'pense-bêtes',
    'penseraient',
    'pensionnais',
    'pensionnait',
    'pensionnant',
    'pensionnats',
    'pensionnées',
    'pensionnent',
    'pensionnera',
    'pensionniez',
    'pensionnons',
    'pensivement',
    'pentacordes',
    'pentacrines',
    'pentagonale',
    'pentagonaux',
    'pentamètres',
    'pentarchies',
    'pentateuque',
    'pentathlons',
    'pénultièmes',
    'pépiassions',
    'pépieraient',
    'peppermints',
    'péquenaudes',
    'perçassions',
    'perce-neige',
    'percepteurs',
    'perceptible',
    'perceptions',
    'perceptives',
    'perceptrice',
    'perceraient',
    'percerettes',
    'percevables',
    'percevaient',
    'percevrions',
    'perchassent',
    'perchassiez',
    'percherions',
    'percheronne',
    'perchlorate',
    'percnoptère',
    'percolateur',
    'percussions',
    'perçussions',
    'percutaient',
    'percutantes',
    'percutasses',
    'percuterais',
    'percuterait',
    'percutèrent',
    'percuteriez',
    'percuterons',
    'percuteront',
    'perdissions',
    'perdurables',
    'perduraient',
    'perdurasses',
    'perdurerais',
    'perdurerait',
    'perdurèrent',
    'perdureriez',
    'perdurerons',
    'perdureront',
    'pérégrinais',
    'pérégrinait',
    'pérégrinant',
    'pérégrinent',
    'pérégrinera',
    'pérégriniez',
    'pérégrinons',
    'péremptions',
    'péremptoire',
    'pérennisais',
    'pérennisait',
    'pérennisant',
    'pérennisées',
    'pérennisent',
    'pérennisera',
    'pérennisiez',
    'pérennisons',
    'péréquation',
    'perfectible',
    'perfections',
    'perfectives',
    'perfidement',
    'perforaient',
    'perforantes',
    'perforasses',
    'perforateur',
    'perforation',
    'perforerais',
    'perforerait',
    'perforèrent',
    'perforeriez',
    'perforerons',
    'perforeront',
    'perforeuses',
    'performance',
    'performante',
    'performants',
    'performatif',
    'perfusaient',
    'perfusasses',
    'perfuserais',
    'perfuserait',
    'perfusèrent',
    'perfuseriez',
    'perfuserons',
    'perfuseront',
    'pergélisols',
    'périchondre',
    'périclitais',
    'périclitait',
    'périclitant',
    'périclitent',
    'périclitera',
    'périclitiez',
    'périclitons',
    'péridurales',
    'périlleuses',
    'périmassent',
    'périmassiez',
    'périmerions',
    'périnatales',
    'périodicité',
    'périodiques',
    'périostites',
    'périphéries',
    'périphrasai',
    'périphrasas',
    'périphrasât',
    'périphraser',
    'périphrases',
    'périphrasez',
    'périspermes',
    'périssables',
    'périssaient',
    'périssoires',
    'péritonéale',
    'péritonéaux',
    'péritonites',
    'perlassions',
    'perleraient',
    'permafrosts',
    'permanences',
    'permanentai',
    'permanentas',
    'permanentât',
    'permanentée',
    'permanenter',
    'permanentes',
    'permanentés',
    'permanentez',
    'permettions',
    'permettrais',
    'permettrait',
    'permettriez',
    'permettrons',
    'permettront',
    'permissions',
    'permissives',
    'permutables',
    'permutaient',
    'permutantes',
    'permutasses',
    'permutation',
    'permuterais',
    'permuterait',
    'permutèrent',
    'permuteriez',
    'permuterons',
    'permuteront',
    'pernicieuse',
    'péronnelles',
    'péroraisons',
    'pérorassent',
    'pérorassiez',
    'pérorerions',
    'peroxydâmes',
    'péroxydâmes',
    'peroxydases',
    'peroxydasse',
    'péroxydasse',
    'peroxydâtes',
    'péroxydâtes',
    'peroxyderai',
    'péroxyderai',
    'peroxyderas',
    'péroxyderas',
    'peroxyderez',
    'péroxyderez',
    'peroxydions',
    'péroxydions',
    'perpétrâmes',
    'perpétrasse',
    'perpétrâtes',
    'perpétrerai',
    'perpétreras',
    'perpétrerez',
    'perpétrions',
    'perpétuâmes',
    'perpétuasse',
    'perpétuâtes',
    'perpétuelle',
    'perpétuerai',
    'perpétueras',
    'perpétuerez',
    'perpétuions',
    'perpétuités',
    'perplexités',
    'perruquière',
    'perruquiers',
    'persécutais',
    'persécutait',
    'persécutant',
    'persécutées',
    'persécutent',
    'persécutera',
    'persécuteur',
    'persécutiez',
    'persécution',
    'persécutons',
    'persévérais',
    'persévérait',
    'persévérant',
    'persévèrent',
    'persévérera',
    'persévériez',
    'persévérons',
    'persicaires',
    'persiflages',
    'persiflâmes',
    'persiflasse',
    'persiflâtes',
    'persiflerai',
    'persifleras',
    'persiflerez',
    'persifleurs',
    'persifleuse',
    'persiflions',
    'persillades',
    'persillâmes',
    'persillasse',
    'persillâtes',
    'persillerai',
    'persilleras',
    'persillères',
    'persillerez',
    'persillions',
    'persistâmes',
    'persistance',
    'persistante',
    'persistants',
    'persistasse',
    'persistâtes',
    'persisterai',
    'persisteras',
    'persisterez',
    'persistions',
    'personnages',
    'personnelle',
    'personnifia',
    'personnifie',
    'personnifié',
    'perspectifs',
    'perspective',
    'perspicaces',
    'persuadâmes',
    'persuadasse',
    'persuadâtes',
    'persuaderai',
    'persuaderas',
    'persuaderez',
    'persuadions',
    'persuasions',
    'persuasives',
    'persulfates',
    'persulfurée',
    'persulfures',
    'persulfurés',
    'pertinences',
    'pertinentes',
    'pertuisanes',
    'perturbâmes',
    'perturbante',
    'perturbants',
    'perturbasse',
    'perturbâtes',
    'perturberai',
    'perturberas',
    'perturberez',
    'perturbions',
    'péruviennes',
    'perversions',
    'perversités',
    'pervertîmes',
    'pervertirai',
    'pervertiras',
    'pervertirez',
    'pervertisse',
    'pervertîtes',
    'pervibrages',
    'pervibrâmes',
    'pervibrasse',
    'pervibrâtes',
    'pervibrerai',
    'pervibreras',
    'pervibrerez',
    'pervibrions',
    'pèse-acides',
    'pèse-alcool',
    'pèse-esprit',
    'pèse-lettre',
    'pèse-sirops',
    'pessimismes',
    'pessimistes',
    'pestassions',
    'pesteraient',
    'pestiférais',
    'pestiférait',
    'pestiférant',
    'pestiférées',
    'pestifèrent',
    'pestiférera',
    'pestifériez',
    'pestiférons',
    'pestilences',
    'pétaradâmes',
    'pétaradante',
    'pétaradants',
    'pétaradasse',
    'pétaradâtes',
    'pétaraderai',
    'pétaraderas',
    'pétaraderez',
    'pétaradions',
    'pétardaient',
    'pétardasses',
    'pétarderais',
    'pétarderait',
    'pétardèrent',
    'pétarderiez',
    'pétarderons',
    'pétarderont',
    'pétaudières',
    'pétauristes',
    'pétéchiales',
    'pétillaient',
    'pétillantes',
    'pétillasses',
    'pétillement',
    'pétillerais',
    'pétillerait',
    'pétillèrent',
    'pétilleriez',
    'pétillerons',
    'pétilleront',
    'pétitionnai',
    'pétitionnas',
    'pétitionnât',
    'pétitionner',
    'pétitionnes',
    'pétitionnez',
    'petit-nègre',
    'petit-neveu',
    'petits-bois',
    'petits-fils',
    'petits-gris',
    'petits-pois',
    'pétouillais',
    'pétouillait',
    'pétouillant',
    'pétouillent',
    'pétouillera',
    'pétouilliez',
    'pétouillons',
    'pétrarquisa',
    'pétrarquise',
    'pétrarquisé',
    'pétrifiâmes',
    'pétrifiante',
    'pétrifiants',
    'pétrifiasse',
    'pétrifiâtes',
    'pétrifierai',
    'pétrifieras',
    'pétrifierez',
    'pétrifiions',
    'pétriraient',
    'pétrissable',
    'pétrissages',
    'pétrisseurs',
    'pétrisseuse',
    'pétrissions',
    'pétrochimie',
    'pétrodollar',
    'pétrolettes',
    'pétroleuses',
    'pétrolières',
    'pétrolifère',
    'pétunassent',
    'pétunassiez',
    'pétunerions',
    'peuplassent',
    'peuplassiez',
    'peuplements',
    'peupleraies',
    'peuplerions',
    'phacochères',
    'phacomètres',
    'phagocytais',
    'phagocytait',
    'phagocytant',
    'phagocytées',
    'phagocytent',
    'phagocytera',
    'phagocytiez',
    'phagocytons',
    'phagocytose',
    'phalangette',
    'phalangiens',
    'phalangines',
    'phalangiste',
    'phalanstère',
    'phallocrate',
    'phanérogame',
    'pharamineux',
    'pharaoniens',
    'pharisaïque',
    'pharisaïsme',
    'pharisienne',
    'pharmaciens',
    'pharmacopée',
    'pharyngales',
    'pharyngiens',
    'pharyngites',
    'phascolomes',
    'phasemètres',
    'phelloderme',
    'phellogènes',
    'phénicienne',
    'phénoménale',
    'phénoménaux',
    'philatélies',
    'philippines',
    'philippique',
    'philologies',
    'philologues',
    'philosophai',
    'philosophas',
    'philosophât',
    'philosopher',
    'philosophes',
    'philosophez',
    'philosophie',
    'phlébologie',
    'phlébotomie',
    'phlegmasies',
    'phlegmoneux',
    'phonatoires',
    'phonatrices',
    'phonémiques',
    'phonéticien',
    'phonétiques',
    'phoniatries',
    'phonogénies',
    'phonographe',
    'phonologies',
    'phonologues',
    'phonométrie',
    'phonothèque',
    'phosphatage',
    'phosphatais',
    'phosphatait',
    'phosphatant',
    'phosphatées',
    'phosphatent',
    'phosphatera',
    'phosphatiez',
    'phosphatons',
    'phosphorais',
    'phosphorait',
    'phosphorant',
    'phosphorées',
    'phosphorent',
    'phosphorera',
    'phosphoriez',
    'phosphorons',
    'photocalque',
    'photochimie',
    'photocopiai',
    'photocopias',
    'photocopiât',
    'photocopiée',
    'photocopier',
    'photocopies',
    'photocopiés',
    'photocopiez',
    'photogénies',
    'photographe',
    'photomatons',
    'photomètres',
    'photométrie',
    'photoniques',
    'photophobie',
    'photophores',
    'photosphère',
    'photostyles',
    'photothèque',
    'phototypies',
    'phrasassent',
    'phrasassiez',
    'phraserions',
    'phrastiques',
    'phréatiques',
    'phrénologie',
    'phrénologue',
    'phrygiennes',
    'phylactères',
    'phylétiques',
    'phylloxeras',
    'phylloxéras',
    'phylloxérée',
    'phylloxérés',
    'phylogenèse',
    'phylogénies',
    'physicienne',
    'physiocrate',
    'physiologie',
    'physionomie',
    'physostigma',
    'phytéléphas',
    'phytozoaire',
    'piaculaires',
    'piaffassent',
    'piaffassiez',
    'piaffements',
    'piafferions',
    'piaillaient',
    'piaillardes',
    'piaillasses',
    'piaillement',
    'piaillerais',
    'piaillerait',
    'piaillèrent',
    'piailleries',
    'piailleriez',
    'piaillerons',
    'piailleront',
    'piailleuses',
    'pianistique',
    'pianofortes',
    'pianotaient',
    'pianotasses',
    'pianoterais',
    'pianoterait',
    'pianotèrent',
    'pianoteriez',
    'pianoterons',
    'pianoteront',
    'piaulassent',
    'piaulassiez',
    'piaulements',
    'piaulerions',
    'picaresques',
    'pichenettes',
    'pickpockets',
    'picolassent',
    'picolassiez',
    'picolerions',
    'picorassent',
    'picorassiez',
    'picorerions',
    'picotassent',
    'picotassiez',
    'picotements',
    'picoterions',
    'pictogramme',
    'pieds-forts',
    'pieds-noirs',
    'pieds-plats',
    'piégeassent',
    'piégeassiez',
    'piégeraient',
    'pie-grièche',
    'piémontaise',
    'pierrailles',
    'pierrassent',
    'pierrassiez',
    'pierrerions',
    'piétassions',
    'piéteraient',
    'piétinaient',
    'piétinantes',
    'piétinasses',
    'piétinement',
    'piétinerais',
    'piétinerait',
    'piétinèrent',
    'piétineriez',
    'piétinerons',
    'piétineront',
    'piétonnière',
    'piétonniers',
    'pieutassent',
    'pieutassiez',
    'pieuterions',
    'piézographe',
    'piézomètres',
    'piffassions',
    'pifferaient',
    'pigeassions',
    'pigeonnâmes',
    'pigeonnante',
    'pigeonnants',
    'pigeonnasse',
    'pigeonnâtes',
    'pigeonneaux',
    'pigeonnerai',
    'pigeonneras',
    'pigeonnerez',
    'pigeonniers',
    'pigeonnions',
    'pigmentaire',
    'pigmentâmes',
    'pigmentasse',
    'pigmentâtes',
    'pigmenterai',
    'pigmenteras',
    'pigmenterez',
    'pigmentions',
    'pignatelles',
    'pignochâmes',
    'pignochasse',
    'pignochâtes',
    'pignocherai',
    'pignocheras',
    'pignocherez',
    'pignochions',
    'pignoratifs',
    'pignorative',
    'pillassions',
    'pilleraient',
    'pilocarpine',
    'pilonnaient',
    'pilonnasses',
    'pilonnerais',
    'pilonnerait',
    'pilonnèrent',
    'pilonneriez',
    'pilonnerons',
    'pilonneront',
    'pilo-sébacé',
    'pilotassent',
    'pilotassiez',
    'piloterions',
    'pimentaient',
    'pimentasses',
    'pimenterais',
    'pimenterait',
    'pimentèrent',
    'pimenteriez',
    'pimenterons',
    'pimenteront',
    'pimprenelle',
    'pinaillages',
    'pinaillâmes',
    'pinaillasse',
    'pinaillâtes',
    'pinaillerai',
    'pinailleras',
    'pinaillerez',
    'pinailleurs',
    'pinailleuse',
    'pinaillions',
    'pinçassions',
    'pince-fesse',
    'pinceraient',
    'pindariques',
    'pindarisais',
    'pindarisait',
    'pindarisant',
    'pindarisent',
    'pindarisera',
    'pindarisiez',
    'pindarisons',
    'pinnothères',
    'pintassions',
    'pinteraient',
    'piochassent',
    'piochassiez',
    'piocherions',
    'pionçassent',
    'pionçassiez',
    'pioncerions',
    'pionnassent',
    'pionnassiez',
    'pionnerions',
    'pipistrelle',
    'piquassions',
    'pique-boeuf',
    'pique-niqua',
    'pique-nique',
    'pique-niqué',
    'pique-notes',
    'piqueraient',
    'piquetaient',
    'piquetasses',
    'piquetèrent',
    'piquetterai',
    'piquetteras',
    'piquetterez',
    'piratassent',
    'piratassiez',
    'piraterions',
    'piroguières',
    'pirouettais',
    'pirouettait',
    'pirouettant',
    'pirouettent',
    'pirouettera',
    'pirouettiez',
    'pirouettons',
    'pisciformes',
    'pissassions',
    'pisse-froid',
    'pisseraient',
    'pissotières',
    'pistachâmes',
    'pistachasse',
    'pistachâtes',
    'pistacherai',
    'pistacheras',
    'pistacherez',
    'pistachiers',
    'pistachions',
    'pistassions',
    'pisteraient',
    'pistonnâmes',
    'pistonnasse',
    'pistonnâtes',
    'pistonnerai',
    'pistonneras',
    'pistonnerez',
    'pistonnions',
    'piteusement',
    'pithiatique',
    'pithiatisme',
    'pitonnaient',
    'pitonnasses',
    'pitonnerais',
    'pitonnerait',
    'pitonnèrent',
    'pitonneriez',
    'pitonnerons',
    'pitonneront',
    'pittoresque',
    'pittosporum',
    'pituitaires',
    'pivotassent',
    'pivotassiez',
    'pivotements',
    'pivoterions',
    'placardâmes',
    'placardasse',
    'placardâtes',
    'placarderai',
    'placarderas',
    'placarderez',
    'placardions',
    'plaçassions',
    'placentaire',
    'placeraient',
    'placidement',
    'plafonnages',
    'plafonnâmes',
    'plafonnasse',
    'plafonnâtes',
    'plafonnerai',
    'plafonneras',
    'plafonnerez',
    'plafonneurs',
    'plafonniers',
    'plafonnions',
    'plagiassent',
    'plagiassiez',
    'plagierions',
    'plaidassent',
    'plaidassiez',
    'plaiderions',
    'plaidoiries',
    'plaignaient',
    'plaignantes',
    'plaignirent',
    'plaignisses',
    'plainassent',
    'plainassiez',
    'plain-chant',
    'plaindrions',
    'plainerions',
    'plaisamment',
    'plaisancier',
    'plaisantais',
    'plaisantait',
    'plaisantant',
    'plaisantées',
    'plaisantent',
    'plaisantera',
    'plaisantiez',
    'plaisantins',
    'plaisantons',
    'planassions',
    'planchaient',
    'planchasses',
    'planchéiage',
    'planchéiais',
    'planchéiait',
    'planchéiaît',
    'planchéiées',
    'planchéient',
    'planchéiera',
    'plancherais',
    'plancherait',
    'planchèrent',
    'plancheriez',
    'plancherons',
    'plancheront',
    'planchettes',
    'planeraient',
    'planétaires',
    'planétarium',
    'planifiâmes',
    'planifiasse',
    'planifiâtes',
    'planifierai',
    'planifieras',
    'planifierez',
    'planifiions',
    'planimètres',
    'planimétrie',
    'planisphère',
    'planquaient',
    'planquasses',
    'planquerais',
    'planquerait',
    'planquèrent',
    'planqueriez',
    'planquerons',
    'planqueront',
    'plantassent',
    'plantassiez',
    'plantations',
    'planterions',
    'plantigrade',
    'plantureuse',
    'plaquassent',
    'plaquassiez',
    'plaquemines',
    'plaquerions',
    'plasmagènes',
    'plasmatique',
    'plasmifiais',
    'plasmifiait',
    'plasmifiant',
    'plasmifiées',
    'plasmifient',
    'plasmifiera',
    'plasmifiiez',
    'plasmifions',
    'plasmocytes',
    'plasmodiums',
    'plasmolyses',
    'plasticages',
    'plasticiens',
    'plasticités',
    'plastifiais',
    'plastifiait',
    'plastifiant',
    'plastifiées',
    'plastifient',
    'plastifiera',
    'plastifiiez',
    'plastifions',
    'plastiquage',
    'plastiquais',
    'plastiquait',
    'plastiquant',
    'plastiquées',
    'plastiquent',
    'plastiquera',
    'plastiqueur',
    'plastiquiez',
    'plastiquons',
    'plastronnai',
    'plastronnas',
    'plastronnât',
    'plastronnée',
    'plastronner',
    'plastronnes',
    'plastronnés',
    'plastronnez',
    'plate-bande',
    'plate-forme',
    'plateresque',
    'platinaient',
    'platinasses',
    'platinerais',
    'platinerait',
    'platinèrent',
    'platineriez',
    'platinerons',
    'platineront',
    'platinisais',
    'platinisait',
    'platinisant',
    'platinisées',
    'platinisent',
    'platinisera',
    'platinisiez',
    'platinisons',
    'platonicien',
    'platoniques',
    'platonismes',
    'plâtrassent',
    'plâtrassiez',
    'plâtrerions',
    'plébéiennes',
    'plébiscitai',
    'plébiscitas',
    'plébiscitât',
    'plébiscitée',
    'plébisciter',
    'plébiscites',
    'plébiscités',
    'plébiscitez',
    'plein-temps',
    'pléistocène',
    'plésiosaure',
    'pléthorique',
    'pleurassent',
    'pleurassiez',
    'pleurerions',
    'pleurétique',
    'pleurnichai',
    'pleurnichas',
    'pleurnichât',
    'pleurnicher',
    'pleurniches',
    'pleurnichez',
    'pleurodynie',
    'pleurotomie',
    'pleutreries',
    'pleuvassait',
    'pleuvassera',
    'pleuvraient',
    'plissassent',
    'plissassiez',
    'plissements',
    'plisserions',
    'ploieraient',
    'plombagines',
    'plombassent',
    'plombassiez',
    'plomberions',
    'plombifères',
    'plongeaient',
    'plongeantes',
    'plongeasses',
    'plongerions',
    'ploquassent',
    'ploquassiez',
    'ploquerions',
    'ploutocrate',
    'ployassions',
    'pluchassent',
    'pluchassiez',
    'plucherions',
    'plumasserie',
    'plumassière',
    'plumassiers',
    'plumassions',
    'plumeraient',
    'pluralismes',
    'pluralistes',
    'pluriannuel',
    'plurivalent',
    'plurivoques',
    'plus-values',
    'plutonienne',
    'plutonigène',
    'plutoniques',
    'plutonismes',
    'pluvinerait',
    'pluviomètre',
    'pluviosités',
    'pneumatique',
    'pneumocoque',
    'pneumologie',
    'pneumologue',
    'pneumonique',
    'pochardises',
    'pochassions',
    'pocheraient',
    'podoscaphes',
    'podzoliques',
    'poêlassions',
    'poêleraient',
    'poétisaient',
    'poétisasses',
    'poétiserais',
    'poétiserait',
    'poétisèrent',
    'poétiseriez',
    'poétiserons',
    'poétiseront',
    'poignardais',
    'poignardait',
    'poignardant',
    'poignardées',
    'poignardent',
    'poignardera',
    'poignardiez',
    'poignardons',
    'poignissent',
    'poignissiez',
    'poilassions',
    'poileraient',
    'poinçonnage',
    'poinçonnais',
    'poinçonnait',
    'poinçonnant',
    'poinçonnées',
    'poinçonnent',
    'poinçonnera',
    'poinçonneur',
    'poinçonniez',
    'poinçonnons',
    'poindraient',
    'pointassent',
    'pointassiez',
    'pointerions',
    'pointillage',
    'pointillais',
    'pointillait',
    'pointillant',
    'pointillées',
    'pointillent',
    'pointillera',
    'pointilleux',
    'pointilliez',
    'pointillons',
    'poireautais',
    'poireautait',
    'poireautant',
    'poireautent',
    'poireautera',
    'poireautiez',
    'poireautons',
    'poirotaient',
    'poirotasses',
    'poiroterais',
    'poiroterait',
    'poirotèrent',
    'poiroteriez',
    'poiroterons',
    'poiroteront',
    'poiscailles',
    'poiseuilles',
    'poissassent',
    'poissassiez',
    'poisserions',
    'poissonneux',
    'poissonnier',
    'poitrinaire',
    'poitrinière',
    'poivrassent',
    'poivrassiez',
    'poivrerions',
    'polarimètre',
    'polarisable',
    'polarisâmes',
    'polarisasse',
    'polarisâtes',
    'polariscope',
    'polariserai',
    'polariseras',
    'polariserez',
    'polariseurs',
    'polarisions',
    'polatouches',
    'polémarques',
    'polémiquais',
    'polémiquait',
    'polémiquant',
    'polémiquent',
    'polémiquera',
    'polémiquiez',
    'polémiquons',
    'polémologie',
    'poliçassent',
    'poliçassiez',
    'policerions',
    'polissables',
    'polissaient',
    'polisseuses',
    'polissoires',
    'polissonnai',
    'polissonnas',
    'polissonnât',
    'polissonner',
    'polissonnes',
    'polissonnez',
    'politicarde',
    'politicards',
    'politiciens',
    'politiquais',
    'politiquait',
    'politiquant',
    'politiquées',
    'politiquent',
    'politiquera',
    'politiquiez',
    'politiquons',
    'politisâmes',
    'politisasse',
    'politisâtes',
    'politiserai',
    'politiseras',
    'politiserez',
    'politisions',
    'polluassent',
    'polluassiez',
    'polluerions',
    'polyalcools',
    'polyandries',
    'polybasique',
    'polycéphale',
    'polychromes',
    'polychromie',
    'polycopiais',
    'polycopiait',
    'polycopiant',
    'polycopiées',
    'polycopient',
    'polycopiera',
    'polycopiiez',
    'polycopions',
    'polyculture',
    'polydactyle',
    'polyédrique',
    'polygénisme',
    'polygéniste',
    'polyglottes',
    'polygonales',
    'polygraphes',
    'polymérisai',
    'polymérisas',
    'polymérisât',
    'polymérisée',
    'polymériser',
    'polymérises',
    'polymérisés',
    'polymérisez',
    'polymorphes',
    'polymorphie',
    'polynésiens',
    'polynévrite',
    'polynomiale',
    'polynomiaux',
    'polypeptide',
    'polypétales',
    'polyphasées',
    'polyphonies',
    'polyploïdes',
    'polyploïdie',
    'polyptyques',
    'polysémique',
    'polystyrène',
    'polysulfure',
    'polysyllabe',
    'polythéisme',
    'polythéiste',
    'polytonales',
    'polyvalente',
    'polyvalents',
    'pommadaient',
    'pommadasses',
    'pommaderais',
    'pommaderait',
    'pommadèrent',
    'pommaderiez',
    'pommaderons',
    'pommaderont',
    'pommassions',
    'pommelaient',
    'pommelasses',
    'pommelèrent',
    'pommellerai',
    'pommelleras',
    'pommellerez',
    'pommerasses',
    'pommerèrent',
    'pomologique',
    'pomologiste',
    'pompassions',
    'pompéiennes',
    'pomperaient',
    'pompiérisme',
    'pomponnâmes',
    'pomponnasse',
    'pomponnâtes',
    'pomponnerai',
    'pomponneras',
    'pomponnerez',
    'pomponnions',
    'ponantaises',
    'ponçassions',
    'ponceraient',
    'ponctionnai',
    'ponctionnas',
    'ponctionnât',
    'ponctionnée',
    'ponctionner',
    'ponctionnes',
    'ponctionnés',
    'ponctionnez',
    'ponctuaient',
    'ponctualité',
    'ponctuasses',
    'ponctuation',
    'ponctuelles',
    'ponctuerais',
    'ponctuerait',
    'ponctuèrent',
    'ponctueriez',
    'ponctuerons',
    'ponctueront',
    'pondérables',
    'pondéraient',
    'pondérasses',
    'pondérateur',
    'pondération',
    'pondérerais',
    'pondérerait',
    'pondérèrent',
    'pondéreriez',
    'pondérerons',
    'pondéreront',
    'pondéreuses',
    'pondissions',
    'pontassions',
    'ponteraient',
    'pontifiâmes',
    'pontifiante',
    'pontifiants',
    'pontifiasse',
    'pontifiâtes',
    'pontificale',
    'pontificats',
    'pontificaux',
    'pontifierai',
    'pontifieras',
    'pontifierez',
    'pontifiions',
    'pontillâmes',
    'pontillasse',
    'pontillâtes',
    'pontillerai',
    'pontilleras',
    'pontillerez',
    'pontillions',
    'pontonniers',
    'ponts-levis',
    'populacière',
    'populaciers',
    'popularisai',
    'popularisas',
    'popularisât',
    'popularisée',
    'populariser',
    'popularises',
    'popularisés',
    'popularisez',
    'popularités',
    'populations',
    'poquassions',
    'poqueraient',
    'porcelaines',
    'porchaisons',
    'porcs-épics',
    'pornographe',
    'porphyrique',
    'porphyrisai',
    'porphyrisas',
    'porphyrisât',
    'porphyrisée',
    'porphyriser',
    'porphyrises',
    'porphyrisés',
    'porphyrisez',
    'porphyroïde',
    'porrections',
    'portassions',
    'porte-bébés',
    'porte-clefs',
    'porte-copie',
    'porte-croix',
    'porte-épées',
    'porte-jupes',
    'porte-lames',
    'porte-menus',
    'porte-mines',
    'porte-objet',
    'porte-outil',
    'porte-plume',
    'porte-queue',
    'porteraient',
    'porte-savon',
    'portlandien',
    'portraitura',
    'portraiture',
    'portraituré',
    'portugaises',
    'positionnai',
    'positionnas',
    'positionnât',
    'positionnée',
    'positionner',
    'positionnes',
    'positionnés',
    'positionnez',
    'positivisme',
    'positiviste',
    'positoniums',
    'possédaient',
    'possédantes',
    'possédasses',
    'posséderais',
    'posséderait',
    'possédèrent',
    'posséderiez',
    'posséderons',
    'posséderont',
    'possesseurs',
    'possessions',
    'possessives',
    'possessoire',
    'possibilité',
    'postassions',
    'postdatâmes',
    'postdatasse',
    'postdatâtes',
    'postdaterai',
    'postdateras',
    'postdaterez',
    'postdations',
    'posteraient',
    'postérieure',
    'postérieurs',
    'postichâmes',
    'postichasse',
    'postichâtes',
    'posticherai',
    'posticheras',
    'posticherez',
    'posticheurs',
    'postichions',
    'postillonna',
    'postillonne',
    'postillonné',
    'postnatales',
    'postposâmes',
    'postposasse',
    'postposâtes',
    'postposerai',
    'postposeras',
    'postposerez',
    'postposions',
    'postulaient',
    'postulantes',
    'postulasses',
    'postulation',
    'postulerais',
    'postulerait',
    'postulèrent',
    'postuleriez',
    'postulerons',
    'postuleront',
    'potamochère',
    'potamologie',
    'potassaient',
    'potassasses',
    'potasserais',
    'potasserait',
    'potassèrent',
    'potasseriez',
    'potasserons',
    'potasseront',
    'potassiques',
    'potentielle',
    'potentilles',
    'potestatifs',
    'potestative',
    'potinassent',
    'potinassiez',
    'potinerions',
    'pots-de-vin',
    'pou-de-soie',
    'poudrassent',
    'poudrassiez',
    'poudrerions',
    'poudroierai',
    'poudroieras',
    'poudroierez',
    'poudroyâmes',
    'poudroyasse',
    'poudroyâtes',
    'poudroyions',
    'pouffassent',
    'pouffassiez',
    'poufferions',
    'pouffiasses',
    'pouilleries',
    'pouilleuses',
    'poujadismes',
    'poulaillers',
    'poulinaient',
    'poulinasses',
    'poulinerais',
    'poulinerait',
    'poulinèrent',
    'poulineriez',
    'poulinerons',
    'poulineront',
    'poulinières',
    'pouponnâmes',
    'pouponnasse',
    'pouponnâtes',
    'pouponnerai',
    'pouponneras',
    'pouponnerez',
    'pouponnière',
    'pouponnions',
    'pourcentage',
    'pourchassai',
    'pourchassas',
    'pourchassât',
    'pourchassée',
    'pourchasser',
    'pourchasses',
    'pourchassés',
    'pourchassez',
    'pour-compte',
    'pourfendais',
    'pourfendait',
    'pourfendant',
    'pourfendent',
    'pourfendeur',
    'pourfendiez',
    'pourfendons',
    'pourfendrai',
    'pourfendras',
    'pourfendrez',
    'pourfendues',
    'pourléchais',
    'pourléchait',
    'pourléchant',
    'pourléchées',
    'pourlèchent',
    'pourléchera',
    'pourléchiez',
    'pourléchons',
    'pourparlers',
    'pourpraient',
    'pourprasses',
    'pourprerais',
    'pourprerait',
    'pourprèrent',
    'pourpreriez',
    'pourprerons',
    'pourpreront',
    'pourprinnes',
    'pourririons',
    'pourrissage',
    'pourrissais',
    'pourrissait',
    'pourrissant',
    'pourrissent',
    'pourrissiez',
    'pourrissoir',
    'pourrissons',
    'pourritures',
    'poursuivais',
    'poursuivait',
    'poursuivant',
    'poursuivent',
    'poursuiveur',
    'poursuivies',
    'poursuiviez',
    'poursuivons',
    'poursuivrai',
    'poursuivras',
    'poursuivrez',
    'pourvoirais',
    'pourvoirait',
    'pourvoiriez',
    'pourvoirons',
    'pourvoiront',
    'pourvoyions',
    'pourvussent',
    'pourvussiez',
    'poussassent',
    'poussassiez',
    'pousse-café',
    'pousse-pied',
    'pousserions',
    'poussiéreux',
    'poussinière',
    'poutsassent',
    'poutsassiez',
    'poutserions',
    'pouzzolanes',
    'praesidiums',
    'pragmatique',
    'pragmatisme',
    'pragmatiste',
    'pralinaient',
    'pralinasses',
    'pralinerais',
    'pralinerait',
    'pralinèrent',
    'pralineriez',
    'pralinerons',
    'pralineront',
    'praséodymes',
    'praticables',
    'praticienne',
    'pratiquâmes',
    'pratiquante',
    'pratiquants',
    'pratiquasse',
    'pratiquâtes',
    'pratiquerai',
    'pratiqueras',
    'pratiquerez',
    'pratiquions',
    'préachetais',
    'préachetait',
    'préachetant',
    'préachetées',
    'préachètent',
    'préachètera',
    'préachetiez',
    'préachetons',
    'préadamisme',
    'préadamites',
    'préavisâmes',
    'préavisasse',
    'préavisâtes',
    'préaviserai',
    'préaviseras',
    'préaviserez',
    'préavisions',
    'prébendiers',
    'précambrien',
    'précarisais',
    'précarisait',
    'précarisant',
    'précarisées',
    'précarisent',
    'précarisera',
    'précarisiez',
    'précarisons',
    'précautions',
    'précédaient',
    'précédasses',
    'précédentes',
    'précéderais',
    'précéderait',
    'précédèrent',
    'précéderiez',
    'précéderons',
    'précéderont',
    'précellence',
    'précepteurs',
    'préceptorat',
    'préceptrice',
    'précessions',
    'préchambres',
    'prêchassent',
    'prêchassiez',
    'préchauffai',
    'préchauffas',
    'préchauffât',
    'préchauffée',
    'préchauffer',
    'préchauffes',
    'préchauffés',
    'préchauffez',
    'prêcherions',
    'préciosités',
    'précipitais',
    'précipitait',
    'précipitant',
    'précipitées',
    'précipitent',
    'précipitera',
    'précipitiez',
    'précipitons',
    'précisaient',
    'précisasses',
    'précisément',
    'préciserais',
    'préciserait',
    'précisèrent',
    'préciseriez',
    'préciserons',
    'préciseront',
    'précocement',
    'précomptais',
    'précomptait',
    'précomptant',
    'précomptées',
    'précomptent',
    'précomptera',
    'précomptiez',
    'précomptons',
    'préconisais',
    'préconisait',
    'préconisant',
    'préconisées',
    'préconisent',
    'préconisera',
    'préconiseur',
    'préconisiez',
    'préconisons',
    'précordiale',
    'précordiaux',
    'précurseurs',
    'prédatrices',
    'prédécoupée',
    'prédécoupés',
    'prédéfinies',
    'prédéfinira',
    'prédestinai',
    'prédestinas',
    'prédestinât',
    'prédestinée',
    'prédestiner',
    'prédestines',
    'prédestinés',
    'prédestinez',
    'prédicables',
    'prédicateur',
    'prédicatifs',
    'prédication',
    'prédicative',
    'prédictions',
    'prédigérées',
    'prédiquâmes',
    'prédiquasse',
    'prédiquâtes',
    'prédiquerai',
    'prédiqueras',
    'prédiquerez',
    'prédiquions',
    'prédiraient',
    'prédisaient',
    'prédisposai',
    'prédisposas',
    'prédisposât',
    'prédisposée',
    'prédisposer',
    'prédisposes',
    'prédisposés',
    'prédisposez',
    'prédissions',
    'prédominais',
    'prédominait',
    'prédominant',
    'prédominent',
    'prédominera',
    'prédominiez',
    'prédominons',
    'préembalées',
    'préemballée',
    'préemballés',
    'prééminence',
    'prééminente',
    'prééminents',
    'préemptâmes',
    'préemptasse',
    'préemptâtes',
    'préempterai',
    'préempteras',
    'préempterez',
    'préemptions',
    'préencollée',
    'préencollés',
    'préétablies',
    'préétablira',
    'préexistais',
    'préexistait',
    'préexistant',
    'préexistent',
    'préexistera',
    'préexistiez',
    'préexistons',
    'préfabriqué',
    'préfaçaient',
    'préfaçasses',
    'préfacerais',
    'préfacerait',
    'préfacèrent',
    'préfaceriez',
    'préfacerons',
    'préfaceront',
    'préfectoral',
    'préfectures',
    'préférables',
    'préféraient',
    'préférasses',
    'préférences',
    'préférerais',
    'préférerait',
    'préférèrent',
    'préféreriez',
    'préférerons',
    'préféreront',
    'préfigurais',
    'préfigurait',
    'préfigurant',
    'préfigurées',
    'préfigurent',
    'préfigurera',
    'préfiguriez',
    'préfigurons',
    'préfixaient',
    'préfixasses',
    'préfixation',
    'préfixerais',
    'préfixerait',
    'préfixèrent',
    'préfixeriez',
    'préfixerons',
    'préfixeront',
    'préformâmes',
    'préformasse',
    'préformâtes',
    'préformerai',
    'préformeras',
    'préformerez',
    'préformions',
    'préhenseurs',
    'préhensiles',
    'préhensions',
    'préhistoire',
    'préjudiciai',
    'préjudicias',
    'préjudiciât',
    'préjudiciel',
    'préjudicier',
    'préjudicies',
    'préjudiciez',
    'préjugeâmes',
    'préjugeasse',
    'préjugeâtes',
    'préjugerais',
    'préjugerait',
    'préjugèrent',
    'préjugeriez',
    'préjugerons',
    'préjugeront',
    'prélassâmes',
    'prélassasse',
    'prélassâtes',
    'prélasserai',
    'prélasseras',
    'prélasserez',
    'prélassions',
    'prélevaient',
    'prélevasses',
    'prélèvement',
    'prélèverais',
    'prélèverait',
    'prélevèrent',
    'prélèveriez',
    'prélèverons',
    'prélèveront',
    'prélogiques',
    'préludaient',
    'préludasses',
    'préluderais',
    'préluderait',
    'préludèrent',
    'préluderiez',
    'préluderons',
    'préluderont',
    'prématurées',
    'prématurité',
    'préméditais',
    'préméditait',
    'préméditant',
    'préméditées',
    'préméditent',
    'préméditera',
    'préméditiez',
    'préméditons',
    'prémolaires',
    'prémonition',
    'prémunirais',
    'prémunirait',
    'prémunirent',
    'prémuniriez',
    'prémunirons',
    'prémuniront',
    'prémunisses',
    'prémunissez',
    'prémunition',
    'prendraient',
    'prénommâmes',
    'prénommasse',
    'prénommâtes',
    'prénommerai',
    'prénommeras',
    'prénommerez',
    'prénommions',
    'prénuptiale',
    'prénuptiaux',
    'préoccupais',
    'préoccupait',
    'préoccupant',
    'préoccupées',
    'préoccupent',
    'préoccupera',
    'préoccupiez',
    'préoccupons',
    'prépaierais',
    'prépaierait',
    'prépaieriez',
    'prépaierons',
    'prépaieront',
    'préparaient',
    'préparasses',
    'préparateur',
    'préparatifs',
    'préparation',
    'préparerais',
    'préparerait',
    'préparèrent',
    'prépareriez',
    'préparerons',
    'prépareront',
    'prépayaient',
    'prépayasses',
    'prépayerais',
    'prépayerait',
    'prépayèrent',
    'prépayeriez',
    'prépayerons',
    'prépayeront',
    'préposaient',
    'préposasses',
    'préposerais',
    'préposerait',
    'préposèrent',
    'préposeriez',
    'préposerons',
    'préposeront',
    'prépositifs',
    'préposition',
    'prépositive',
    'prépotences',
    'préréglâmes',
    'préréglasse',
    'préréglâtes',
    'préréglerai',
    'prérégleras',
    'préréglerez',
    'préréglions',
    'préretraite',
    'prérogative',
    'présageâmes',
    'présageasse',
    'présageâtes',
    'présagerais',
    'présagerait',
    'présagèrent',
    'présageriez',
    'présagerons',
    'présageront',
    'présalaires',
    'presbytéral',
    'presbytères',
    'presciences',
    'prescientes',
    'préscolaire',
    'prescrirais',
    'prescrirait',
    'prescririez',
    'prescrirons',
    'prescriront',
    'prescrivais',
    'prescrivait',
    'prescrivant',
    'prescrivent',
    'prescriviez',
    'prescrivons',
    'présentable',
    'présentâmes',
    'présentasse',
    'présentâtes',
    'présenterai',
    'présenteras',
    'présenterez',
    'présentions',
    'présentoirs',
    'préservâmes',
    'préservasse',
    'préservâtes',
    'préservatif',
    'préserverai',
    'préserveras',
    'préserverez',
    'préservions',
    'présidaient',
    'présidasses',
    'présidences',
    'présidentes',
    'présiderais',
    'présiderait',
    'présidèrent',
    'présideriez',
    'présiderons',
    'présideront',
    'présomptifs',
    'présomption',
    'présomptive',
    'pressassent',
    'pressassiez',
    'pressentais',
    'pressentait',
    'pressentant',
    'pressentent',
    'pressenties',
    'pressentiez',
    'pressentira',
    'pressentons',
    'presserions',
    'pressurages',
    'pressurâmes',
    'pressurasse',
    'pressurâtes',
    'pressurerai',
    'pressureras',
    'pressurerez',
    'pressureurs',
    'pressureuse',
    'pressurions',
    'pressurisai',
    'pressurisas',
    'pressurisât',
    'pressurisée',
    'pressuriser',
    'pressurises',
    'pressurisés',
    'pressurisez',
    'prestataire',
    'prestations',
    'prestigieux',
    'prestissimo',
    'présumables',
    'présumaient',
    'présumasses',
    'présumerais',
    'présumerait',
    'présumèrent',
    'présumeriez',
    'présumerons',
    'présumeront',
    'présupposai',
    'présupposas',
    'présupposât',
    'présupposée',
    'présupposer',
    'présupposes',
    'présupposés',
    'présupposez',
    'présuraient',
    'présurasses',
    'présurerais',
    'présurerait',
    'présurèrent',
    'présureriez',
    'présurerons',
    'présureront',
    'prétantaine',
    'prêtassions',
    'prétendante',
    'prétendants',
    'prétendîmes',
    'prétendions',
    'prétendisse',
    'prétendîtes',
    'prétendrais',
    'prétendrait',
    'prétendriez',
    'prétendrons',
    'prétendront',
    'prétentaine',
    'prétentiard',
    'prétentieux',
    'prétentions',
    'prêteraient',
    'prétérition',
    'prétextâmes',
    'prétextasse',
    'prétextâtes',
    'prétexterai',
    'prétexteras',
    'prétexterez',
    'prétextions',
    'prétoriales',
    'prétorienne',
    'prêts-bails',
    'prévalaient',
    'prévalences',
    'prévalurent',
    'prévalusses',
    'prévariquai',
    'prévariquas',
    'prévariquât',
    'prévariquer',
    'prévariques',
    'prévariquez',
    'prévaudrais',
    'prévaudrait',
    'prévaudriez',
    'prévaudrons',
    'prévaudront',
    'prévenaient',
    'prévenances',
    'prévenantes',
    'préventions',
    'préventives',
    'préviendrai',
    'préviendras',
    'préviendrez',
    'préviennent',
    'prévinssent',
    'prévinssiez',
    'prévisibles',
    'prévissions',
    'prévoirions',
    'prévoyaient',
    'prévoyances',
    'prévoyantes',
    'primarisais',
    'primarisait',
    'primarisant',
    'primarisées',
    'primarisent',
    'primarisera',
    'primarisiez',
    'primarisons',
    'primassions',
    'primatiales',
    'primeraient',
    'primeuriste',
    'primordiale',
    'primordiaux',
    'principalat',
    'principales',
    'principauté',
    'printanière',
    'printaniers',
    'prioritaire',
    'prisassions',
    'priseraient',
    'prismatique',
    'prisonnière',
    'prisonniers',
    'privassions',
    'privatiques',
    'privatisais',
    'privatisait',
    'privatisant',
    'privatisées',
    'privatisent',
    'privatisera',
    'privatisiez',
    'privatisons',
    'priveraient',
    'privilégiai',
    'privilégias',
    'privilégiât',
    'privilégiée',
    'privilégier',
    'privilégies',
    'privilégiés',
    'privilégiez',
    'probabilité',
    'probatiques',
    'probatoires',
    'procédaient',
    'procédasses',
    'procéderais',
    'procéderait',
    'procédèrent',
    'procéderiez',
    'procéderons',
    'procéderont',
    'procédurier',
    'procesionné',
    'processeurs',
    'processions',
    'processives',
    'prochinoise',
    'proclamâmes',
    'proclamasse',
    'proclamâtes',
    'proclamerai',
    'proclameras',
    'proclamerez',
    'proclamions',
    'proclitique',
    'proconsulat',
    'procréaient',
    'procréasses',
    'procréateur',
    'procréation',
    'procréerais',
    'procréerait',
    'procréèrent',
    'procréeriez',
    'procréerons',
    'procréeront',
    'proctalgies',
    'proctologie',
    'proctologue',
    'proctorrhée',
    'procuraient',
    'procurasses',
    'procurateur',
    'procuraties',
    'procuration',
    'procurèrent',
    'prodigalité',
    'prodigieuse',
    'prodiguâmes',
    'prodiguasse',
    'prodiguâtes',
    'prodiguerai',
    'prodigueras',
    'prodiguerez',
    'prodiguions',
    'prodromique',
    'producteurs',
    'productible',
    'productions',
    'productives',
    'productrice',
    'produirions',
    'produisîmes',
    'produisions',
    'produisisse',
    'produisîtes',
    'proéminence',
    'proéminente',
    'proéminents',
    'profanaient',
    'profanasses',
    'profanateur',
    'profanation',
    'profanerais',
    'profanerait',
    'profanèrent',
    'profaneriez',
    'profanerons',
    'profaneront',
    'proféraient',
    'proférasses',
    'proférerais',
    'proférerait',
    'proférèrent',
    'proféreriez',
    'proférerons',
    'proféreront',
    'professâmes',
    'professasse',
    'professâtes',
    'professerai',
    'professeras',
    'professerez',
    'professeurs',
    'professions',
    'professoral',
    'professorat',
    'profilaient',
    'profilasses',
    'profilerais',
    'profilerait',
    'profilèrent',
    'profileriez',
    'profilerons',
    'profileront',
    'profitables',
    'profitaient',
    'profitasses',
    'profiterais',
    'profiterait',
    'profitèrent',
    'profiteriez',
    'profiterole',
    'profiterons',
    'profiteront',
    'profiteuses',
    'profondeurs',
    'profusément',
    'progéniture',
    'progestatif',
    'programmais',
    'programmait',
    'programmant',
    'programmées',
    'programment',
    'programmera',
    'programmeur',
    'programmiez',
    'programmons',
    'progressais',
    'progressait',
    'progressant',
    'progressent',
    'progressera',
    'progressiez',
    'progressifs',
    'progression',
    'progressive',
    'progressons',
    'prohibaient',
    'prohibasses',
    'prohiberais',
    'prohiberait',
    'prohibèrent',
    'prohiberiez',
    'prohiberons',
    'prohiberont',
    'prohibitifs',
    'prohibition',
    'prohibitive',
    'projecteurs',
    'projectiles',
    'projections',
    'projectures',
    'projetaient',
    'projetasses',
    'projetèrent',
    'projetterai',
    'projetteras',
    'projetterez',
    'prolactines',
    'prolétaires',
    'prolétariat',
    'prolétarien',
    'prolétarisa',
    'prolétarise',
    'prolétarisé',
    'proliférais',
    'proliférait',
    'proliférant',
    'prolifèrent',
    'proliférera',
    'prolifériez',
    'proliférons',
    'prolificité',
    'prolifiques',
    'prolongeais',
    'prolongeait',
    'prolongeant',
    'prolongeons',
    'prolongerai',
    'prolongeras',
    'prolongerez',
    'prolongions',
    'promenaient',
    'promenasses',
    'promènerais',
    'promènerait',
    'promenèrent',
    'promèneriez',
    'promènerons',
    'promèneront',
    'promeneuses',
    'prométhéens',
    'prométhéums',
    'prometteurs',
    'prometteuse',
    'promettions',
    'promettrais',
    'promettrait',
    'promettriez',
    'promettrons',
    'promettront',
    'promiscuité',
    'promissions',
    'promontoire',
    'promotrices',
    'promouvions',
    'promouvrais',
    'promouvrait',
    'promouvriez',
    'promouvrons',
    'promouvront',
    'promptement',
    'promptitude',
    'promulguais',
    'promulguait',
    'promulguant',
    'promulguées',
    'promulguent',
    'promulguera',
    'promulguiez',
    'promulguons',
    'promussions',
    'prônassions',
    'pronatrices',
    'prôneraient',
    'pronominale',
    'pronominaux',
    'prononçable',
    'prononçâmes',
    'prononçasse',
    'prononçâtes',
    'prononcerai',
    'prononceras',
    'prononcerez',
    'prononcions',
    'pronostiqua',
    'pronostique',
    'pronostiqué',
    'propagandes',
    'propagateur',
    'propagation',
    'propageâmes',
    'propageasse',
    'propageâtes',
    'propagerais',
    'propagerait',
    'propagèrent',
    'propageriez',
    'propagerons',
    'propageront',
    'propédeutes',
    'propensions',
    'prophétesse',
    'prophétique',
    'prophétisai',
    'prophétisas',
    'prophétisât',
    'prophétisée',
    'prophétiser',
    'prophétises',
    'prophétisés',
    'prophétisez',
    'prophylaxie',
    'proportions',
    'proposables',
    'proposaient',
    'proposasses',
    'proposerais',
    'proposerait',
    'proposèrent',
    'proposeriez',
    'proposerons',
    'proposeront',
    'proposition',
    'propréteurs',
    'proprétures',
    'propulsâmes',
    'propulsasse',
    'propulsâtes',
    'propulserai',
    'propulseras',
    'propulserez',
    'propulseurs',
    'propulsions',
    'propulsives',
    'prorogatifs',
    'prorogation',
    'prorogative',
    'prorogeâmes',
    'prorogeasse',
    'prorogeâtes',
    'prorogerais',
    'prorogerait',
    'prorogèrent',
    'prorogeriez',
    'prorogerons',
    'prorogeront',
    'prosceniums',
    'proscrirais',
    'proscrirait',
    'proscririez',
    'proscrirons',
    'proscriront',
    'proscrivais',
    'proscrivait',
    'proscrivant',
    'proscrivent',
    'proscriviez',
    'proscrivons',
    'prosecteurs',
    'prosectorat',
    'prosociales',
    'prosodiâmes',
    'prosodiasse',
    'prosodiâtes',
    'prosodierai',
    'prosodieras',
    'prosodierez',
    'prosodiions',
    'prosodiques',
    'prosopopées',
    'prospectais',
    'prospectait',
    'prospectant',
    'prospectées',
    'prospectent',
    'prospectera',
    'prospecteur',
    'prospectiez',
    'prospectifs',
    'prospection',
    'prospective',
    'prospectons',
    'prospérâmes',
    'prospérasse',
    'prospérâtes',
    'prospérerai',
    'prospéreras',
    'prospérerez',
    'prospérions',
    'prospérités',
    'prostatique',
    'prostatisme',
    'prosternais',
    'prosternait',
    'prosternant',
    'prosternées',
    'prosternent',
    'prosternera',
    'prosterniez',
    'prosternons',
    'prostituais',
    'prostituait',
    'prostituant',
    'prostituées',
    'prostituent',
    'prostituera',
    'prostituiez',
    'prostituons',
    'prostration',
    'protecteurs',
    'protections',
    'protectorat',
    'protectrice',
    'protégeâmes',
    'protégeasse',
    'protégeâtes',
    'protégerais',
    'protégerait',
    'protégèrent',
    'protégeriez',
    'protégerons',
    'protégeront',
    'protéiforme',
    'protéinurie',
    'protéolyses',
    'protestâmes',
    'protestante',
    'protestants',
    'protestasse',
    'protestâtes',
    'protesterai',
    'protesteras',
    'protesterez',
    'protestions',
    'prothésiste',
    'prothétique',
    'protidiques',
    'protococcus',
    'protogynies',
    'protoplasme',
    'protoptères',
    'prototypage',
    'protozoaire',
    'protractile',
    'protubérant',
    'protutrices',
    'proudhonien',
    'prouvassent',
    'prouvassiez',
    'prouverions',
    'provéditeur',
    'provenaient',
    'provenances',
    'provençales',
    'proverbiale',
    'proverbiaux',
    'providences',
    'proviendrai',
    'proviendras',
    'proviendrez',
    'proviennent',
    'provignages',
    'provignâmes',
    'provignasse',
    'provignâtes',
    'provignerai',
    'provigneras',
    'provignerez',
    'provignions',
    'provinciale',
    'provinciaux',
    'provinssent',
    'provinssiez',
    'provisoires',
    'provisorats',
    'provitamine',
    'provocantes',
    'provocateur',
    'provocation',
    'provoquâmes',
    'provoquasse',
    'provoquâtes',
    'provoquerai',
    'provoqueras',
    'provoquerez',
    'provoquions',
    'proxémiques',
    'prunelliers',
    'prurigineux',
    'prussiennes',
    'psalmodiais',
    'psalmodiait',
    'psalmodiant',
    'psalmodiées',
    'psalmodient',
    'psalmodiera',
    'psalmodiiez',
    'psalmodions',
    'psaltérions',
    'pseudonymes',
    'pseudopodes',
    'psittacisme',
    'psittacoses',
    'psychiatres',
    'psychiatrie',
    'psychodrame',
    'psychologie',
    'psychologue',
    'psychopathe',
    'psychopompe',
    'psychotique',
    'ptolémaïque',
    'pubertaires',
    'pubescences',
    'pubescentes',
    'publiassent',
    'publiassiez',
    'publication',
    'publicistes',
    'publierions',
    'pudiquement',
    'puérilement',
    'puérilismes',
    'puerpérales',
    'puisassions',
    'puiseraient',
    'puissamment',
    'pullulaient',
    'pullulantes',
    'pullulasses',
    'pullulation',
    'pullulement',
    'pullulerais',
    'pullulerait',
    'pullulèrent',
    'pulluleriez',
    'pullulerons',
    'pulluleront',
    'pulmonaires',
    'pulsassions',
    'pulseraient',
    'pulsionnels',
    'pulvérisais',
    'pulvérisait',
    'pulvérisant',
    'pulvérisées',
    'pulvérisent',
    'pulvérisera',
    'pulvériseur',
    'pulvérisiez',
    'pulvérisons',
    'pulvérulent',
    'punaisaient',
    'punaisasses',
    'punaiserais',
    'punaiserait',
    'punaisèrent',
    'punaiseriez',
    'punaiserons',
    'punaiseront',
    'punissables',
    'punissaient',
    'puntarelles',
    'pupillaires',
    'pupillarité',
    'pupitreuses',
    'purgatoires',
    'purgeassent',
    'purgeassiez',
    'purgeraient',
    'purifiaient',
    'purifiasses',
    'purifierais',
    'purifierait',
    'purifièrent',
    'purifieriez',
    'purifierons',
    'purifieront',
    'puritanisme',
    'pusillanime',
    'pustuleuses',
    'putréfiable',
    'putréfiâmes',
    'putréfiasse',
    'putréfiâtes',
    'putréfierai',
    'putréfieras',
    'putréfierez',
    'putréfiions',
    'putrescence',
    'putrescente',
    'putrescents',
    'putrescible',
    'putschistes',
    'pycnomètres',
    'pyodermites',
    'pyramidales',
    'pyramidâmes',
    'pyramidasse',
    'pyramidâtes',
    'pyramiderai',
    'pyramideras',
    'pyramiderez',
    'pyramidions',
    'pyrénéennes',
    'pyridoxines',
    'pyrimidines',
    'pyrogallols',
    'pyrogravais',
    'pyrogravait',
    'pyrogravant',
    'pyrogravées',
    'pyrogravent',
    'pyrogravera',
    'pyrograveur',
    'pyrograviez',
    'pyrogravons',
    'pyrogravure',
    'pyroligneux',
    'pyrométries',
    'pyrotechnie',
    'pyrrhoniens',
    'pyrrhonisme',
    'pythonisses',
    'quadrangles',
    'quadratique',
    'quadratures',
    'quadriennal',
    'quadrifides',
    'quadrillage',
    'quadrillais',
    'quadrillait',
    'quadrillant',
    'quadrillées',
    'quadrillent',
    'quadrillera',
    'quadrilliez',
    'quadrillion',
    'quadrillons',
    'quadriparti',
    'quadrirèmes',
    'quadrumanes',
    'quadrupèdes',
    'quadruplais',
    'quadruplait',
    'quadruplant',
    'quadruplées',
    'quadruplent',
    'quadruplera',
    'quadrupliez',
    'quadruplons',
    'quakeresses',
    'qualifiable',
    'qualifiâmes',
    'qualifiante',
    'qualifiants',
    'qualifiasse',
    'qualifiâtes',
    'qualifierai',
    'qualifieras',
    'qualifierez',
    'qualifiions',
    'qualitatifs',
    'qualitative',
    'quant-à-soi',
    'quantifiais',
    'quantifiait',
    'quantifiant',
    'quantifiées',
    'quantifient',
    'quantifiera',
    'quantifiiez',
    'quantifions',
    'quantitatif',
    'quarantaine',
    'quarantième',
    'quarderonna',
    'quarderonne',
    'quarderonné',
    'quarrassent',
    'quarrassiez',
    'quarrerions',
    'quartageais',
    'quartageait',
    'quartageant',
    'quartageons',
    'quartagerai',
    'quartageras',
    'quartagerez',
    'quartagions',
    'quartaniers',
    'quartassent',
    'quartassiez',
    'quartations',
    'quarteniers',
    'quarterions',
    'quartzeuses',
    'quartzifère',
    'quasi-délit',
    'quaternaire',
    'quatorzième',
    'quatre-mâts',
    'quatrillion',
    'québécismes',
    'québécoises',
    'quelconques',
    'quelquefois',
    'quémandâmes',
    'quémandasse',
    'quémandâtes',
    'quémanderai',
    'quémanderas',
    'quémanderez',
    'quémandeurs',
    'quémandeuse',
    'quémandions',
    'quenouilles',
    'quercitrine',
    'quercitrons',
    'querellâmes',
    'querellasse',
    'querellâtes',
    'querellerai',
    'querelleras',
    'querellerez',
    'querelleurs',
    'querelleuse',
    'querellions',
    'quérulences',
    'quérulentes',
    'questionnai',
    'questionnas',
    'questionnât',
    'questionnée',
    'questionner',
    'questionnes',
    'questionnés',
    'questionnez',
    'quêtassions',
    'quêteraient',
    'queutassent',
    'queutassiez',
    'queuterions',
    'quinoléines',
    'quinquennal',
    'quinquennat',
    'quintillion',
    'quintuplais',
    'quintuplait',
    'quintuplant',
    'quintuplées',
    'quintuplent',
    'quintuplera',
    'quintupliez',
    'quintuplons',
    'quittançais',
    'quittançait',
    'quittançant',
    'quittancées',
    'quittancent',
    'quittancera',
    'quittanciez',
    'quittançons',
    'quittassent',
    'quittassiez',
    'quitterions',
    'quotidienne',
    'quottassent',
    'quottassiez',
    'quotterions',
    'rabâchaient',
    'rabâchasses',
    'rabâcherais',
    'rabâcherait',
    'rabâchèrent',
    'rabâcheriez',
    'rabâcherons',
    'rabâcheront',
    'rabâcheuses',
    'rabaissâmes',
    'rabaissasse',
    'rabaissâtes',
    'rabaisserai',
    'rabaisseras',
    'rabaisserez',
    'rabaissions',
    'rabantaient',
    'rabantasses',
    'rabanterais',
    'rabanterait',
    'rabantèrent',
    'rabanteriez',
    'rabanterons',
    'rabanteront',
    'rabattaient',
    'rabattantes',
    'rabattement',
    'rabatteuses',
    'rabattirent',
    'rabattisses',
    'rabattrions',
    'rabbiniques',
    'rabbinismes',
    'rabdomancie',
    'rabelaisien',
    'rabibochage',
    'rabibochais',
    'rabibochait',
    'rabibochant',
    'rabibochées',
    'rabibochent',
    'rabibochera',
    'rabibochiez',
    'rabibochons',
    'rabiotaient',
    'rabiotasses',
    'rabioterais',
    'rabioterait',
    'rabiotèrent',
    'rabioteriez',
    'rabioterons',
    'rabioteront',
    'râblassions',
    'râbleraient',
    'rabonnirais',
    'rabonnirait',
    'rabonnirent',
    'rabonniriez',
    'rabonnirons',
    'rabonniront',
    'rabonnisses',
    'rabonnissez',
    'rabotassent',
    'rabotassiez',
    'rabotements',
    'raboterions',
    'rabougrîmes',
    'rabougrirai',
    'rabougriras',
    'rabougrirez',
    'rabougrisse',
    'rabougrîtes',
    'rabouillère',
    'rabouilleur',
    'raboutaient',
    'raboutasses',
    'rabouterais',
    'rabouterait',
    'raboutèrent',
    'rabouteriez',
    'rabouterons',
    'rabouteront',
    'rabrouaient',
    'rabrouasses',
    'rabrouement',
    'rabrouerais',
    'rabrouerait',
    'rabrouèrent',
    'rabroueriez',
    'rabrouerons',
    'rabroueront',
    'raccommodai',
    'raccommodas',
    'raccommodât',
    'raccommodée',
    'raccommoder',
    'raccommodes',
    'raccommodés',
    'raccommodez',
    'raccompagna',
    'raccompagne',
    'raccompagné',
    'raccordâmes',
    'raccordasse',
    'raccordâtes',
    'raccorderai',
    'raccorderas',
    'raccorderez',
    'raccordions',
    'raccourcies',
    'raccourcira',
    'raccoutrais',
    'raccoutrait',
    'raccoutrant',
    'raccoutrées',
    'raccoutrent',
    'raccoutrera',
    'raccoutriez',
    'raccoutrons',
    'raccoutumai',
    'raccoutumas',
    'raccoutumât',
    'raccoutumée',
    'raccoutumer',
    'raccoutumes',
    'raccoutumés',
    'raccoutumez',
    'raccrochage',
    'raccrochais',
    'raccrochait',
    'raccrochant',
    'raccrochées',
    'raccrochent',
    'raccrochera',
    'raccrocheur',
    'raccrochiez',
    'raccrochons',
    'rachetables',
    'rachetaient',
    'rachetasses',
    'rachèterais',
    'rachèterait',
    'rachetèrent',
    'rachèteriez',
    'rachèterons',
    'rachèteront',
    'rachialgies',
    'rachidienne',
    'rachitiques',
    'rachitismes',
    'racinassent',
    'racinassiez',
    'racinerions',
    'raciniennes',
    'raciologies',
    'racketteurs',
    'racketteuse',
    'raclassions',
    'racleraient',
    'racolassent',
    'racolassiez',
    'racolerions',
    'racontables',
    'racontaient',
    'racontasses',
    'raconterais',
    'raconterait',
    'racontèrent',
    'raconteriez',
    'raconterons',
    'raconteront',
    'raconteuses',
    'racornirais',
    'racornirait',
    'racornirent',
    'racorniriez',
    'racornirons',
    'racorniront',
    'racornisses',
    'racornissez',
    'radiassions',
    'radicalisai',
    'radicalisas',
    'radicalisât',
    'radicalisée',
    'radicaliser',
    'radicalises',
    'radicalisés',
    'radicalisez',
    'radicalisme',
    'radiculaire',
    'radieraient',
    'radinassent',
    'radinassiez',
    'radinerions',
    'radioactifs',
    'radioactive',
    'radiobalisa',
    'radiobalise',
    'radiobalisé',
    'radiocobalt',
    'radiocompas',
    'radioguidai',
    'radioguidas',
    'radioguidât',
    'radioguidée',
    'radioguider',
    'radioguides',
    'radioguidés',
    'radioguidez',
    'radiolésion',
    'radiologies',
    'radiologues',
    'radiomètres',
    'radiophares',
    'radiophonie',
    'radioscopai',
    'radioscopas',
    'radioscopât',
    'radioscopée',
    'radioscoper',
    'radioscopes',
    'radioscopés',
    'radioscopez',
    'radioscopie',
    'radiosondes',
    'radio-taxis',
    'radotassent',
    'radotassiez',
    'radoterions',
    'radoubaient',
    'radoubasses',
    'radouberais',
    'radouberait',
    'radoubèrent',
    'radouberiez',
    'radouberons',
    'radouberont',
    'radoucirais',
    'radoucirait',
    'radoucirent',
    'radouciriez',
    'radoucirons',
    'radouciront',
    'radoucisses',
    'radoucissez',
    'raffermîmes',
    'raffermirai',
    'raffermiras',
    'raffermirez',
    'raffermisse',
    'raffermîtes',
    'raffinaient',
    'raffinasses',
    'raffinement',
    'raffinerais',
    'raffinerait',
    'raffinèrent',
    'raffineries',
    'raffineriez',
    'raffinerons',
    'raffineront',
    'raffineuses',
    'raffolaient',
    'raffolasses',
    'raffolerais',
    'raffolerait',
    'raffolèrent',
    'raffoleriez',
    'raffolerons',
    'raffoleront',
    'raffûtaient',
    'raffûtasses',
    'raffûterais',
    'raffûterait',
    'raffûtèrent',
    'raffûteriez',
    'raffûterons',
    'raffûteront',
    'rafistolage',
    'rafistolais',
    'rafistolait',
    'rafistolant',
    'rafistolées',
    'rafistolent',
    'rafistolera',
    'rafistoliez',
    'rafistolons',
    'raflassions',
    'rafleraient',
    'rafraîchies',
    'rafraîchira',
    'ragaillardi',
    'rageassions',
    'rageusement',
    'ragotassent',
    'ragotassiez',
    'ragoterions',
    'ragougnasse',
    'ragoûtaient',
    'ragoûtantes',
    'ragoûtasses',
    'ragoûterais',
    'ragoûterait',
    'ragoûtèrent',
    'ragoûteriez',
    'ragoûterons',
    'ragoûteront',
    'ragrafaient',
    'ragrafasses',
    'ragraferais',
    'ragraferait',
    'ragrafèrent',
    'ragraferiez',
    'ragraferons',
    'ragraferont',
    'ragréassent',
    'ragréassiez',
    'ragréerions',
    'raguassions',
    'ragueraient',
    'raidirasses',
    'raidirèrent',
    'raidisseurs',
    'raidissions',
    'raillassent',
    'raillassiez',
    'raillerions',
    'rainassions',
    'raineraient',
    'rainetaient',
    'rainetasses',
    'rainetèrent',
    'rainetterai',
    'rainetteras',
    'rainetterez',
    'rainuraient',
    'rainurasses',
    'rainurerais',
    'rainurerait',
    'rainurèrent',
    'rainureriez',
    'rainurerons',
    'rainureront',
    'raisonnable',
    'raisonnâmes',
    'raisonnasse',
    'raisonnâtes',
    'raisonnerai',
    'raisonneras',
    'raisonnerez',
    'raisonneurs',
    'raisonneuse',
    'raisonnions',
    'rajeunirais',
    'rajeunirait',
    'rajeunirent',
    'rajeuniriez',
    'rajeunirons',
    'rajeuniront',
    'rajeunisses',
    'rajeunissez',
    'rajoutaient',
    'rajoutasses',
    'rajouterais',
    'rajouterait',
    'rajoutèrent',
    'rajouteriez',
    'rajouterons',
    'rajouteront',
    'rajustaient',
    'rajustasses',
    'rajustement',
    'rajusterais',
    'rajusterait',
    'rajustèrent',
    'rajusteriez',
    'rajusterons',
    'rajusteront',
    'ralentirais',
    'ralentirait',
    'ralentirent',
    'ralentiriez',
    'ralentirons',
    'ralentiront',
    'ralentisses',
    'ralentissez',
    'ralinguâmes',
    'ralinguasse',
    'ralinguâtes',
    'ralinguerai',
    'ralingueras',
    'ralinguerez',
    'ralinguions',
    'rallégeâmes',
    'rallégeasse',
    'rallégeâtes',
    'rallégerais',
    'rallégerait',
    'rallégèrent',
    'rallégeriez',
    'rallégerons',
    'rallégeront',
    'ralliassent',
    'ralliassiez',
    'ralliements',
    'rallierions',
    'rallongeais',
    'rallongeait',
    'rallongeant',
    'rallongeons',
    'rallongerai',
    'rallongeras',
    'rallongerez',
    'rallongions',
    'rallumaient',
    'rallumasses',
    'rallumerais',
    'rallumerait',
    'rallumèrent',
    'rallumeriez',
    'rallumerons',
    'rallumeront',
    'ramageaient',
    'ramageasses',
    'ramagerions',
    'ramaillâmes',
    'ramaillasse',
    'ramaillâtes',
    'ramaillerai',
    'ramailleras',
    'ramaillerez',
    'ramaillions',
    'ramandaient',
    'ramandasses',
    'ramanderais',
    'ramanderait',
    'ramandèrent',
    'ramanderiez',
    'ramanderons',
    'ramanderont',
    'ramarraient',
    'ramarrasses',
    'ramarrerais',
    'ramarrerait',
    'ramarrèrent',
    'ramarreriez',
    'ramarrerons',
    'ramarreront',
    'ramassaient',
    'ramassasses',
    'ramasserais',
    'ramasserait',
    'ramassèrent',
    'ramasseriez',
    'ramasserons',
    'ramasseront',
    'ramassettes',
    'ramasseuses',
    'ramastiquai',
    'ramastiquas',
    'ramastiquât',
    'ramastiquée',
    'ramastiquer',
    'ramastiques',
    'ramastiqués',
    'ramastiquez',
    'ramenassent',
    'ramenassiez',
    'ramendaient',
    'ramendasses',
    'ramenderais',
    'ramenderait',
    'ramendèrent',
    'ramenderiez',
    'ramenderons',
    'ramenderont',
    'ramendeuses',
    'ramènerions',
    'ramescences',
    'rameutaient',
    'rameutasses',
    'rameuterais',
    'rameuterait',
    'rameutèrent',
    'rameuteriez',
    'rameuterons',
    'rameuteront',
    'ramifiaient',
    'ramifiasses',
    'ramifierais',
    'ramifierait',
    'ramifièrent',
    'ramifieriez',
    'ramifierons',
    'ramifieront',
    'ramollirais',
    'ramollirait',
    'ramollirent',
    'ramolliriez',
    'ramollirons',
    'ramolliront',
    'ramollisses',
    'ramollissez',
    'ramonassent',
    'ramonassiez',
    'ramonerions',
    'rampassions',
    'ramperaient',
    'ramponneaux',
    'rancardâmes',
    'rancardasse',
    'rancardâtes',
    'rancarderai',
    'rancarderas',
    'rancarderez',
    'rancardions',
    'rancescible',
    'ranciraient',
    'rancissions',
    'rancissures',
    'rançonnâmes',
    'rançonnasse',
    'rançonnâtes',
    'rançonnerai',
    'rançonneras',
    'rançonnerez',
    'rançonnions',
    'rancunières',
    'randomisais',
    'randomisait',
    'randomisant',
    'randomisées',
    'randomisent',
    'randomisera',
    'randomisiez',
    'randomisons',
    'randonnâmes',
    'randonnasse',
    'randonnâtes',
    'randonnerai',
    'randonneras',
    'randonnerez',
    'randonneurs',
    'randonneuse',
    'randonnions',
    'rangeassent',
    'rangeassiez',
    'rangeraient',
    'ranimassent',
    'ranimassiez',
    'ranimerions',
    'rapapillota',
    'rapapillote',
    'rapapilloté',
    'rapatriâmes',
    'rapatriasse',
    'rapatriâtes',
    'rapatrierai',
    'rapatrieras',
    'rapatrierez',
    'rapatriions',
    'rapetassage',
    'rapetassais',
    'rapetassait',
    'rapetassant',
    'rapetassées',
    'rapetassent',
    'rapetassera',
    'rapetassiez',
    'rapetassons',
    'rapetissais',
    'rapetissait',
    'rapetissant',
    'rapetissées',
    'rapetissent',
    'rapetissera',
    'rapetissiez',
    'rapetissons',
    'rapiéçaient',
    'rapiéçasses',
    'rapiécerais',
    'rapiécerait',
    'rapiécèrent',
    'rapiéceriez',
    'rapiécerons',
    'rapiéceront',
    'rapiécetais',
    'rapiécetait',
    'rapiécetant',
    'rapiécetées',
    'rapiécètent',
    'rapiécétera',
    'rapiécetiez',
    'rapiécetons',
    'rapinassent',
    'rapinassiez',
    'rapinerions',
    'raplatirais',
    'raplatirait',
    'raplatirent',
    'raplatiriez',
    'raplatirons',
    'raplatiront',
    'raplatisses',
    'raplatissez',
    'rapointîmes',
    'rapointirai',
    'rapointiras',
    'rapointirez',
    'rapointisse',
    'rapointîtes',
    'rappareilla',
    'rappareille',
    'rappareillé',
    'rappariâmes',
    'rappariasse',
    'rappariâtes',
    'rapparierai',
    'rapparieras',
    'rapparierez',
    'rappariions',
    'rappelables',
    'rappelaient',
    'rappelasses',
    'rappelèrent',
    'rappellerai',
    'rappelleras',
    'rappellerez',
    'rappliquais',
    'rappliquait',
    'rappliquant',
    'rappliquent',
    'rappliquera',
    'rappliquiez',
    'rappliquons',
    'rapportable',
    'rapportages',
    'rapportâmes',
    'rapportasse',
    'rapportâtes',
    'rapporterai',
    'rapporteras',
    'rapporterez',
    'rapporteurs',
    'rapporteuse',
    'rapportions',
    'rapprendent',
    'rapprendrai',
    'rapprendras',
    'rapprendrez',
    'rapprenions',
    'rapprêtâmes',
    'rapprêtasse',
    'rapprêtâtes',
    'rapprêterai',
    'rapprêteras',
    'rapprêterez',
    'rapprêtions',
    'rapprissent',
    'rapprissiez',
    'rapprochais',
    'rapprochait',
    'rapprochant',
    'rapprochées',
    'rapprochent',
    'rapprochera',
    'rapprochiez',
    'rapprochons',
    'rappropriai',
    'rapproprias',
    'rappropriât',
    'rappropriée',
    'rapproprier',
    'rappropries',
    'rappropriés',
    'rappropriez',
    'raquassions',
    'raqueraient',
    'raréfaction',
    'raréfiaient',
    'raréfiasses',
    'raréfierais',
    'raréfierait',
    'raréfièrent',
    'raréfieriez',
    'raréfierons',
    'raréfieront',
    'rarescentes',
    'rase-mottes',
    'raspoutitsa',
    'rassasiâmes',
    'rassasiasse',
    'rassasiâtes',
    'rassasierai',
    'rassasieras',
    'rassasierez',
    'rassasiions',
    'rassemblais',
    'rassemblait',
    'rassemblant',
    'rassemblées',
    'rassemblent',
    'rassemblera',
    'rassembleur',
    'rassembliez',
    'rassemblons',
    'rassérénais',
    'rassérénait',
    'rassérénant',
    'rassérénées',
    'rassérènent',
    'rassérénera',
    'rasséréniez',
    'rassérénons',
    'rasseyaient',
    'rassiérions',
    'rassissions',
    'rassortîmes',
    'rassortirai',
    'rassortiras',
    'rassortirez',
    'rassortisse',
    'rassortîtes',
    'rassoyaient',
    'rassuraient',
    'rassurantes',
    'rassurasses',
    'rassurerais',
    'rassurerait',
    'rassurèrent',
    'rassureriez',
    'rassurerons',
    'rassureront',
    'ratatinâmes',
    'ratatinasse',
    'ratatinâtes',
    'ratatinerai',
    'ratatineras',
    'ratatinerez',
    'ratatinions',
    'ratatouille',
    'râtelassent',
    'râtelassiez',
    'râtellerais',
    'râtellerait',
    'râtelleriez',
    'râtellerons',
    'râtelleront',
    'ratiboisais',
    'ratiboisait',
    'ratiboisant',
    'ratiboisées',
    'ratiboisent',
    'ratiboisera',
    'ratiboisiez',
    'ratiboisons',
    'ratifiaient',
    'ratifierais',
    'ratifierait',
    'ratifieriez',
    'ratifierons',
    'ratifieront',
    'ratinassent',
    'ratinassiez',
    'ratinerions',
    'ratiocinais',
    'ratiocinait',
    'ratiocinant',
    'ratiocinent',
    'ratiocinera',
    'ratiocineur',
    'ratiociniez',
    'ratiocinons',
    'rationalisa',
    'rationalise',
    'rationalisé',
    'rationalité',
    'rationnaire',
    'rationnâmes',
    'rationnasse',
    'rationnâtes',
    'rationnelle',
    'rationnerai',
    'rationneras',
    'rationnerez',
    'rationnions',
    'ratissaient',
    'ratissasses',
    'ratisserais',
    'ratisserait',
    'ratissèrent',
    'ratisseriez',
    'ratisserons',
    'ratisseront',
    'ratissoires',
    'rattachâmes',
    'rattachasse',
    'rattachâtes',
    'rattacherai',
    'rattacheras',
    'rattacherez',
    'rattachions',
    'rattrapable',
    'rattrapages',
    'rattrapâmes',
    'rattrapasse',
    'rattrapâtes',
    'rattraperai',
    'rattraperas',
    'rattraperez',
    'rattrapions',
    'raturassent',
    'raturassiez',
    'raturerions',
    'raugmentais',
    'raugmentait',
    'raugmentant',
    'raugmentent',
    'raugmentera',
    'raugmentiez',
    'raugmentons',
    'rauquements',
    'ravageaient',
    'ravageasses',
    'ravagerions',
    'ravalassent',
    'ravalassiez',
    'ravalements',
    'ravalerions',
    'ravaudaient',
    'ravaudasses',
    'ravauderais',
    'ravauderait',
    'ravaudèrent',
    'ravauderiez',
    'ravauderons',
    'ravauderont',
    'ravaudeuses',
    'ravigotâmes',
    'ravigotante',
    'ravigotants',
    'ravigotasse',
    'ravigotâtes',
    'ravigoterai',
    'ravigoteras',
    'ravigoterez',
    'ravigotions',
    'ravilirions',
    'ravilissais',
    'ravilissait',
    'ravilissant',
    'ravilissent',
    'ravilissiez',
    'ravilissons',
    'ravinassent',
    'ravinassiez',
    'ravinements',
    'ravinerions',
    'ravisassent',
    'ravisassiez',
    'raviserions',
    'ravissaient',
    'ravissantes',
    'ravissement',
    'ravisseuses',
    'ravitaillai',
    'ravitaillas',
    'ravitaillât',
    'ravitaillée',
    'ravitailler',
    'ravitailles',
    'ravitaillés',
    'ravitaillez',
    'ravivassent',
    'ravivassiez',
    'raviverâmes',
    'raviverasse',
    'raviverâtes',
    'rayonnaient',
    'rayonnantes',
    'rayonnasses',
    'rayonnement',
    'rayonnerais',
    'rayonnerait',
    'rayonnèrent',
    'rayonneriez',
    'rayonnerons',
    'rayonneront',
    'razziassent',
    'razziassiez',
    'razzierâmes',
    'razzierasse',
    'razzierâtes',
    'réabonnâmes',
    'réabonnasse',
    'réabonnâtes',
    'réabonnerai',
    'réabonneras',
    'réabonnerât',
    'réabonnions',
    'réabsorbais',
    'réabsorbait',
    'réabsorbant',
    'réabsorbées',
    'réabsorbent',
    'réabsorbera',
    'réabsorbiez',
    'réabsorbons',
    'réaccoutuma',
    'réaccoutume',
    'réaccoutumé',
    'réactionnel',
    'réactivâmes',
    'réactivasse',
    'réactivâtes',
    'réactiverai',
    'réactiveras',
    'réactiverez',
    'réactivions',
    'réactivités',
    'réactualisa',
    'réactualise',
    'réactualisé',
    'réadaptâmes',
    'réadaptasse',
    'réadaptâtes',
    'réadapterai',
    'réadapteras',
    'réadapterez',
    'réadaptions',
    'réadmettais',
    'réadmettait',
    'réadmettant',
    'réadmettent',
    'réadmettiez',
    'réadmettons',
    'réadmettrai',
    'réadmettras',
    'réadmettrez',
    'réadmissent',
    'réadmissiez',
    'réaffectais',
    'réaffectait',
    'réaffectant',
    'réaffectées',
    'réaffectent',
    'réaffectera',
    'réaffectiez',
    'réaffectons',
    'réaffirmais',
    'réaffirmait',
    'réaffirmant',
    'réaffirmées',
    'réaffirment',
    'réaffirmera',
    'réaffirmiez',
    'réaffirmons',
    'réaffûtâmes',
    'réaffûtasse',
    'réaffûtâtes',
    'réaffûterai',
    'réaffûteras',
    'réaffûterez',
    'réaffûtions',
    'réagiraient',
    'réagissions',
    'réajustâmes',
    'réajustasse',
    'réajustâtes',
    'réajusterai',
    'réajusteras',
    'réajusterez',
    'réajustions',
    'réalésaient',
    'réalésasses',
    'réaléserais',
    'réaléserait',
    'réalésèrent',
    'réaléseriez',
    'réaléserons',
    'réaléseront',
    'réalignâmes',
    'réalignasse',
    'réalignâtes',
    'réalignerai',
    'réaligneras',
    'réalignerez',
    'réalignions',
    'réalisables',
    'réalisaient',
    'réalisasses',
    'réalisateur',
    'réalisation',
    'réaliserais',
    'réaliserait',
    'réalisèrent',
    'réaliseriez',
    'réaliserons',
    'réaliseront',
    'réaménageai',
    'réaménageas',
    'réaménageât',
    'réaménagées',
    'réaménagent',
    'réaménagera',
    'réaménagiez',
    'réamorçâmes',
    'réamorçasse',
    'réamorçâtes',
    'réamorcerai',
    'réamorceras',
    'réamorcerez',
    'réamorcions',
    'réanimaient',
    'réanimasses',
    'réanimation',
    'réanimerais',
    'réanimerait',
    'réanimèrent',
    'réanimeriez',
    'réanimerons',
    'réanimeront',
    'réapparûmes',
    'réapparusse',
    'réapparûtes',
    'réapprenais',
    'réapprenait',
    'réapprenant',
    'réapprendra',
    'réapprendre',
    'réappreniez',
    'réapprennes',
    'réapprenons',
    'réapprirent',
    'réapprisses',
    'réargentais',
    'réargentait',
    'réargentant',
    'réargentées',
    'réargentent',
    'réargentera',
    'réargentiez',
    'réargentons',
    'réarmassent',
    'réarmassiez',
    'réarmements',
    'réarmerions',
    'réarrangeai',
    'réarrangeas',
    'réarrangeât',
    'réarrangées',
    'réarrangent',
    'réarrangera',
    'réarrangiez',
    'réassignais',
    'réassignait',
    'réassignant',
    'réassignées',
    'réassignent',
    'réassignera',
    'réassigniez',
    'réassignons',
    'réassorties',
    'réassortira',
    'réassurâmes',
    'réassurance',
    'réassurasse',
    'réassurâtes',
    'réassurerai',
    'réassureras',
    'réassurerez',
    'réassurions',
    'rebaissâmes',
    'rebaissasse',
    'rebaissâtes',
    'rebaisserai',
    'rebaisseras',
    'rebaisserez',
    'rebaissions',
    'rebandaient',
    'rebandasses',
    'rebanderais',
    'rebanderait',
    'rebandèrent',
    'rebanderiez',
    'rebanderons',
    'rebanderont',
    'rebaptisais',
    'rebaptisait',
    'rebaptisant',
    'rebaptisées',
    'rebaptisent',
    'rebaptisera',
    'rebaptisiez',
    'rebaptisons',
    'rébarbatifs',
    'rébarbative',
    'rebâtirions',
    'rebâtissais',
    'rebâtissait',
    'rebâtissant',
    'rebâtissent',
    'rebâtissiez',
    'rebâtissons',
    'rebattaient',
    'rebattement',
    'rebattirent',
    'rebattisses',
    'rebattrions',
    'rebellaient',
    'rebellasses',
    'rebellerais',
    'rebellerait',
    'rebellèrent',
    'rebelleriez',
    'rebellerons',
    'rebelleront',
    'rebiffaient',
    'rebiffasses',
    'rebifferais',
    'rebifferait',
    'rebiffèrent',
    'rebifferiez',
    'rebifferons',
    'rebifferont',
    'rebiquaient',
    'rebiquasses',
    'rebiquerais',
    'rebiquerait',
    'rebiquèrent',
    'rebiqueriez',
    'rebiquerons',
    'rebiqueront',
    'reblanchies',
    'reblanchira',
    'reboisaient',
    'reboisasses',
    'reboisement',
    'reboiserais',
    'reboiserait',
    'reboisèrent',
    'reboiseriez',
    'reboiserons',
    'reboiseront',
    'rebondirais',
    'rebondirait',
    'rebondirent',
    'rebondiriez',
    'rebondirons',
    'rebondiront',
    'rebondisses',
    'rebondissez',
    'rebordaient',
    'rebordasses',
    'reborderais',
    'reborderait',
    'rebordèrent',
    'reborderiez',
    'reborderons',
    'reborderont',
    'rebouchâmes',
    'rebouchasse',
    'rebouchâtes',
    'reboucherai',
    'reboucheras',
    'reboucherez',
    'rebouchions',
    'reboutaient',
    'reboutasses',
    'rebouterais',
    'rebouterait',
    'reboutèrent',
    'rebouteriez',
    'rebouterons',
    'rebouteront',
    'rebouteuses',
    'reboutonnai',
    'reboutonnas',
    'reboutonnât',
    'reboutonnée',
    'reboutonner',
    'reboutonnes',
    'reboutonnés',
    'reboutonnez',
    'rebrodaient',
    'rebrodasses',
    'rebroderais',
    'rebroderait',
    'rebrodèrent',
    'rebroderiez',
    'rebroderons',
    'rebroderont',
    'rebroussais',
    'rebroussait',
    'rebroussant',
    'rebroussées',
    'rebroussent',
    'rebroussera',
    'rebroussiez',
    'rebroussons',
    'rebrûlaient',
    'rebrûlasses',
    'rebrûlerais',
    'rebrûlerait',
    'rebrûlèrent',
    'rebrûleriez',
    'rebrûlerons',
    'rebrûleront',
    'rebutassent',
    'rebutassiez',
    'rebuterions',
    'recachetais',
    'recachetait',
    'recachetant',
    'recachetées',
    'recachetiez',
    'recachetons',
    'recachettes',
    'recalassent',
    'recalassiez',
    'recalcifiai',
    'recalcifias',
    'recalcifiât',
    'recalcifiée',
    'recalcifier',
    'recalcifies',
    'recalcifiés',
    'recalcifiez',
    'recalculais',
    'recalculait',
    'recalculant',
    'recalculées',
    'recalculent',
    'recalculera',
    'recalculiez',
    'recalculons',
    'recalerions',
    'récapitulai',
    'récapitulas',
    'récapitulât',
    'récapitulée',
    'récapituler',
    'récapitules',
    'récapitulés',
    'récapitulez',
    'recardaient',
    'recardasses',
    'recarderais',
    'recarderait',
    'recardèrent',
    'recarderiez',
    'recarderons',
    'recarderont',
    'recarrelais',
    'recarrelait',
    'recarrelant',
    'recarrelées',
    'recarreliez',
    'recarrelles',
    'recarrelons',
    'recasassent',
    'recasassiez',
    'recaserions',
    'recausaient',
    'recausasses',
    'recauserais',
    'recauserait',
    'recausèrent',
    'recauseriez',
    'recauserons',
    'recauseront',
    'recédassent',
    'recédassiez',
    'recéderions',
    'recelassent',
    'recélassent',
    'recelassiez',
    'recélassiez',
    'recélerions',
    'recèlerions',
    'recensaient',
    'recensasses',
    'recensement',
    'recenserais',
    'recenserait',
    'recensèrent',
    'recenseriez',
    'recenserons',
    'recenseront',
    'recenseuses',
    'recentrâmes',
    'recentrasse',
    'recentrâtes',
    'recentrerai',
    'recentreras',
    'recentrerez',
    'recentrions',
    'recepassent',
    'recépassent',
    'recepassiez',
    'recépassiez',
    'recéperions',
    'recèperions',
    'réceptacles',
    'réceptionna',
    'réceptionne',
    'réceptionné',
    'réceptivité',
    'réceptrices',
    'recerclâmes',
    'recerclasse',
    'recerclâtes',
    'recerclerai',
    'recercleras',
    'recerclerez',
    'recerclions',
    'recevraient',
    'rechampîmes',
    'réchampîmes',
    'rechampirai',
    'réchampirai',
    'rechampiras',
    'réchampiras',
    'rechampirez',
    'réchampirez',
    'rechampisse',
    'réchampisse',
    'rechampîtes',
    'réchampîtes',
    'rechangeais',
    'rechangeait',
    'rechangeant',
    'rechangeons',
    'rechangerai',
    'rechangeras',
    'rechangerez',
    'rechangions',
    'rechantâmes',
    'rechantasse',
    'rechantâtes',
    'rechanterai',
    'rechanteras',
    'rechanterez',
    'rechantions',
    'rechapaient',
    'rechapasses',
    'rechaperais',
    'rechaperait',
    'rechapèrent',
    'rechaperiez',
    'rechaperons',
    'rechaperont',
    'réchappâmes',
    'réchappasse',
    'réchappâtes',
    'réchapperai',
    'réchapperas',
    'réchapperez',
    'réchappions',
    'rechargeais',
    'rechargeait',
    'rechargeant',
    'rechargeons',
    'rechargerai',
    'rechargeras',
    'rechargerez',
    'rechargions',
    'rechassâmes',
    'rechassasse',
    'rechassâtes',
    'rechasserai',
    'rechasseras',
    'rechasserez',
    'rechassions',
    'réchauffage',
    'réchauffais',
    'réchauffait',
    'réchauffant',
    'réchauffées',
    'réchauffent',
    'réchauffera',
    'réchauffeur',
    'réchauffiez',
    'réchauffons',
    'rechaussais',
    'rechaussait',
    'rechaussant',
    'rechaussées',
    'rechaussent',
    'rechaussera',
    'rechaussiez',
    'rechaussons',
    'recherchais',
    'recherchait',
    'recherchant',
    'recherchées',
    'recherchent',
    'recherchera',
    'recherchiez',
    'recherchons',
    'rechignâmes',
    'rechignasse',
    'rechignâtes',
    'rechignerai',
    'rechigneras',
    'rechignerez',
    'rechignions',
    'rechutaient',
    'rechutasses',
    'rechuterais',
    'rechuterait',
    'rechutèrent',
    'rechuteriez',
    'rechuterons',
    'rechuteront',
    'récidivâmes',
    'récidivante',
    'récidivants',
    'récidivasse',
    'récidivâtes',
    'récidiverai',
    'récidiveras',
    'récidiverez',
    'récidivions',
    'récidivisme',
    'récidiviste',
    'réciprocité',
    'réciproquai',
    'réciproquas',
    'réciproquât',
    'réciproquée',
    'réciproquer',
    'réciproques',
    'réciproqués',
    'réciproquez',
    'récitassent',
    'récitassiez',
    'récitations',
    'réciterions',
    'réclamaient',
    'réclamasses',
    'réclamation',
    'réclamerais',
    'réclamerait',
    'réclamèrent',
    'réclameriez',
    'réclamerons',
    'réclameront',
    'reclassâmes',
    'reclassasse',
    'reclassâtes',
    'reclasserai',
    'reclasseras',
    'reclasserez',
    'reclassions',
    'réclinaient',
    'réclinasses',
    'réclinerais',
    'réclinerait',
    'réclinèrent',
    'réclineriez',
    'réclinerons',
    'réclineront',
    'reclouaient',
    'reclouasses',
    'reclouerais',
    'reclouerait',
    'reclouèrent',
    'recloueriez',
    'reclouerons',
    'recloueront',
    'récognitifs',
    'recognition',
    'recoiffâmes',
    'recoiffasse',
    'recoiffâtes',
    'recoifferai',
    'recoifferas',
    'recoifferez',
    'recoiffions',
    'recolassent',
    'recolassiez',
    'récolements',
    'recolerions',
    'recollaient',
    'recollasses',
    'recollement',
    'recollerais',
    'recollerait',
    'recollèrent',
    'recolleriez',
    'recollerons',
    'recolleront',
    'recolorâmes',
    'recolorasse',
    'recolorâtes',
    'recolorerai',
    'recoloreras',
    'recolorerez',
    'recolorions',
    'récoltaient',
    'récoltantes',
    'récoltasses',
    'récolterais',
    'récolterait',
    'récoltèrent',
    'récolteriez',
    'récolterons',
    'récolteront',
    'recombinais',
    'recombinait',
    'recombinant',
    'recombinées',
    'recombinent',
    'recombinera',
    'recombiniez',
    'recombinons',
    'recommandai',
    'recommandas',
    'recommandât',
    'recommandée',
    'recommander',
    'recommandes',
    'recommandés',
    'recommandez',
    'recommençai',
    'recommenças',
    'recommençât',
    'recommencée',
    'recommencer',
    'recommences',
    'recommencés',
    'recommencez',
    'recomparais',
    'recomparaît',
    'recomparues',
    'récompensai',
    'récompensas',
    'récompensât',
    'récompensée',
    'récompenser',
    'récompenses',
    'récompensés',
    'récompensez',
    'recompilais',
    'recompilait',
    'recompilant',
    'recompilées',
    'recompilent',
    'recompilera',
    'recompiliez',
    'recompilons',
    'recomposais',
    'recomposait',
    'recomposant',
    'recomposées',
    'recomposent',
    'recomposera',
    'recomposiez',
    'recomposons',
    'recomptâmes',
    'recomptasse',
    'recomptâtes',
    'recompterai',
    'recompteras',
    'recompterez',
    'recomptions',
    'réconciliai',
    'réconcilias',
    'réconciliât',
    'réconciliée',
    'réconcilier',
    'réconcilies',
    'réconciliés',
    'réconciliez',
    'recondamnai',
    'recondamnas',
    'recondamnât',
    'recondamnée',
    'recondamner',
    'recondamnes',
    'recondamnés',
    'recondamnez',
    'reconduirai',
    'reconduiras',
    'reconduirez',
    'reconduises',
    'reconduisez',
    'reconduisis',
    'reconduisit',
    'reconduisît',
    'reconduites',
    'réconfortai',
    'réconfortas',
    'réconfortât',
    'réconfortée',
    'réconforter',
    'réconfortes',
    'réconfortés',
    'réconfortez',
    'recongelais',
    'recongelait',
    'recongelant',
    'recongelées',
    'recongèlent',
    'recongèlera',
    'recongeliez',
    'recongelons',
    'reconnaisse',
    'reconnaîtra',
    'reconnaître',
    'reconnectai',
    'reconnectas',
    'reconnectât',
    'reconnectée',
    'reconnecter',
    'reconnectes',
    'reconnectés',
    'reconnectez',
    'reconnurent',
    'reconnusses',
    'reconquérez',
    'reconquérir',
    'reconquerra',
    'reconquérue',
    'reconquérus',
    'reconquêtes',
    'reconquière',
    'reconquiers',
    'reconquiert',
    'reconquîmes',
    'reconquises',
    'reconquisse',
    'reconquîtes',
    'reconsidéra',
    'reconsidéré',
    'reconsidère',
    'reconsolida',
    'reconsolide',
    'reconsolidé',
    'reconstitua',
    'reconstitue',
    'reconstitué',
    'reconstruis',
    'reconstruit',
    'reconvertie',
    'reconvertir',
    'reconvertis',
    'reconvertit',
    'reconvertît',
    'recopiaient',
    'recopiasses',
    'recopierais',
    'recopierait',
    'recopièrent',
    'recopieriez',
    'recopierons',
    'recopieront',
    'recoquillai',
    'recoquillas',
    'recoquillât',
    'recoquillée',
    'recoquiller',
    'recoquilles',
    'recoquillés',
    'recoquillez',
    'recordaient',
    'recordasses',
    'recorderais',
    'recorderait',
    'recordèrent',
    'recorderiez',
    'recorderons',
    'recorderont',
    'recorrigeai',
    'recorrigeas',
    'recorrigeât',
    'recorrigées',
    'recorrigent',
    'recorrigera',
    'recorrigiez',
    'recouchâmes',
    'recouchasse',
    'recouchâtes',
    'recoucherai',
    'recoucheras',
    'recoucherât',
    'recouchions',
    'recoudrions',
    'recoupaient',
    'recoupasses',
    'recoupement',
    'recouperais',
    'recouperait',
    'recoupèrent',
    'recouperiez',
    'recouperons',
    'recouperont',
    'recoupettes',
    'recouraient',
    'recourbâmes',
    'recourbasse',
    'recourbâtes',
    'recourberai',
    'recourberas',
    'recourberez',
    'recourbions',
    'recourbures',
    'recourrions',
    'recoururent',
    'recourusses',
    'recousaient',
    'recousirent',
    'recousisses',
    'recouvertes',
    'recouvrable',
    'recouvrages',
    'recouvrâmes',
    'recouvrance',
    'recouvrante',
    'recouvrants',
    'recouvrasse',
    'recouvrâtes',
    'recouvrerai',
    'recouvreras',
    'recouvrerez',
    'recouvrîmes',
    'recouvrions',
    'recouvrirai',
    'recouvriras',
    'recouvrirez',
    'recouvrisse',
    'recouvrîtes',
    'recrachâmes',
    'recrachasse',
    'recrachâtes',
    'recracherai',
    'recracheras',
    'recracherez',
    'recrachions',
    'recréassent',
    'récréassent',
    'recréassiez',
    'récréassiez',
    'recréations',
    'récréations',
    'récréatives',
    'recréerions',
    'récréerions',
    'recrépirais',
    'recrépirait',
    'recrépirent',
    'recrépiriez',
    'recrépirons',
    'recrépiront',
    'recrépisses',
    'recrépissez',
    'recreusâmes',
    'recreusasse',
    'recreusâtes',
    'recreuserai',
    'recreuseras',
    'recreuserez',
    'recreusions',
    'récriassent',
    'récriassiez',
    'récrierions',
    'récriminais',
    'récriminait',
    'récriminant',
    'récriminent',
    'récriminera',
    'récriminiez',
    'récriminons',
    'récriraient',
    'récrivaient',
    'récrivirent',
    'récrivisses',
    'recroiserai',
    'recroiseras',
    'recroiserez',
    'recroisions',
    'recroissais',
    'recroissait',
    'recroissant',
    'recroissent',
    'recroissiez',
    'recroissons',
    'recroîtrais',
    'recroîtrait',
    'recroîtriez',
    'recroîtrons',
    'recroîtront',
    'recrussions',
    'recrutaient',
    'recrutasses',
    'recrutement',
    'recruterais',
    'recruterait',
    'recrutèrent',
    'recruteriez',
    'recruterons',
    'recruteront',
    'rectifiable',
    'rectifiâmes',
    'rectifiasse',
    'rectifiâtes',
    'rectifierai',
    'rectifieras',
    'rectifierez',
    'rectifieurs',
    'rectifieuse',
    'rectifiions',
    'rectilignes',
    'rectoscopes',
    'rectoscopie',
    'recueillais',
    'recueillait',
    'recueillant',
    'recueillent',
    'recueillera',
    'recueillies',
    'recueilliez',
    'recueillons',
    'recuiraient',
    'recuisaient',
    'recuisirent',
    'recuisisses',
    'reculassent',
    'reculassiez',
    'reculements',
    'reculerions',
    'reculottais',
    'reculottait',
    'reculottant',
    'reculottées',
    'reculottent',
    'reculottera',
    'reculottiez',
    'reculottons',
    'récupérable',
    'récupérâmes',
    'récupérasse',
    'récupérâtes',
    'récupérerai',
    'récupéreras',
    'récupérerez',
    'récupérions',
    'récurassent',
    'récurassiez',
    'récurerions',
    'récurrences',
    'récurrentes',
    'récursoires',
    'récusassent',
    'récusassiez',
    'récusations',
    'récuserions',
    'recyclaient',
    'recyclasses',
    'recyclerais',
    'recyclerait',
    'recyclèrent',
    'recycleriez',
    'recyclerons',
    'recycleront',
    'rédactrices',
    'redéfaisais',
    'redéfaisait',
    'redéfaisant',
    'redéfaisiez',
    'redéfaisons',
    'redéfassent',
    'redéfassiez',
    'redéferions',
    'redéfinîmes',
    'redéfinirai',
    'redéfiniras',
    'redéfinirez',
    'redéfinisse',
    'redéfinîtes',
    'redéfissent',
    'redéfissiez',
    'redemandais',
    'redemandait',
    'redemandant',
    'redemandées',
    'redemandent',
    'redemandera',
    'redemandiez',
    'redemandons',
    'redémarrage',
    'redémarrais',
    'redémarrait',
    'redémarrant',
    'redémarrent',
    'redémarrera',
    'redémarriez',
    'redémarrons',
    'redémolîmes',
    'redémolirai',
    'redémoliras',
    'redémolirez',
    'redémolisse',
    'redémolîtes',
    'redémontrai',
    'redémontras',
    'redémontrât',
    'redémontrée',
    'redémontrer',
    'redémontres',
    'redémontrés',
    'redémontrez',
    'rédempteurs',
    'rédemptions',
    'rédemptrice',
    'redéploient',
    'redéploiera',
    'redéployais',
    'redéployait',
    'redéployant',
    'redéployées',
    'redéployiez',
    'redéployons',
    'redescendes',
    'redescendez',
    'redescendis',
    'redescendit',
    'redescendît',
    'redescendra',
    'redescendre',
    'redescendue',
    'redescendus',
    'redevenions',
    'redeviendra',
    'redeviennes',
    'redevinrent',
    'redevinsses',
    'redevraient',
    'rédhibition',
    'rediffusion',
    'rédigeaient',
    'rédigeasses',
    'rédigerions',
    'rédimassent',
    'rédimassiez',
    'rédimerions',
    'rediscutais',
    'rediscutait',
    'rediscutant',
    'rediscutées',
    'rediscutent',
    'rediscutera',
    'rediscutiez',
    'rediscutons',
    'redistribua',
    'redistribue',
    'redistribué',
    'redondaient',
    'redondances',
    'redondantes',
    'redondasses',
    'redonderais',
    'redonderait',
    'redondèrent',
    'redonderiez',
    'redonderons',
    'redonderont',
    'redonnaient',
    'redonnasses',
    'redonnerais',
    'redonnerait',
    'redonnèrent',
    'redonneriez',
    'redonnerons',
    'redonneront',
    'redorassent',
    'redorassiez',
    'redorerions',
    'redoublâmes',
    'redoublante',
    'redoublants',
    'redoublasse',
    'redoublâtes',
    'redoublerai',
    'redoubleras',
    'redoublerez',
    'redoublions',
    'redoutables',
    'redoutaient',
    'redoutasses',
    'redouterais',
    'redouterait',
    'redoutèrent',
    'redouteriez',
    'redouterons',
    'redouteront',
    'redressâmes',
    'redressasse',
    'redressâtes',
    'redresserai',
    'redresseras',
    'redresserez',
    'redresseurs',
    'redressions',
    'réductibles',
    'réductrices',
    'réduiraient',
    'réduisaient',
    'réduisirent',
    'réduisisses',
    'réécoutâmes',
    'réécoutasse',
    'réécoutâtes',
    'réécouterai',
    'réécouteras',
    'réécouterez',
    'réécoutions',
    'réécritures',
    'réédifiâmes',
    'réédifiasse',
    'réédifiâtes',
    'réédifierai',
    'réédifieras',
    'réédifierez',
    'réédifiions',
    'rééditaient',
    'rééditasses',
    'rééditerais',
    'rééditerait',
    'rééditèrent',
    'rééditeriez',
    'rééditerons',
    'rééditeront',
    'rééducation',
    'rééduquâmes',
    'rééduquasse',
    'rééduquâtes',
    'rééduquerai',
    'rééduqueras',
    'rééduquerez',
    'rééduquions',
    'réélections',
    'rééligibles',
    'rééliraient',
    'réélisaient',
    'réélussions',
    'réembauchai',
    'réembauchas',
    'réembauchât',
    'réembauchée',
    'réembaucher',
    'réembauches',
    'réembauchés',
    'réembauchez',
    'réemploient',
    'réemploiera',
    'réemployais',
    'réemployait',
    'réemployant',
    'réemployées',
    'réemployiez',
    'réemployons',
    'réempruntai',
    'réempruntas',
    'réempruntât',
    'réempruntée',
    'réemprunter',
    'réempruntes',
    'réempruntés',
    'réempruntez',
    'réengageais',
    'réengageait',
    'réengageant',
    'réengageons',
    'réengagerai',
    'réengageras',
    'réengagerez',
    'réengagions',
    'réensemença',
    'réensemence',
    'réensemencé',
    'rééquilibra',
    'rééquilibre',
    'rééquilibré',
    'réescomptai',
    'réescomptas',
    'réescomptât',
    'réescomptée',
    'réescompter',
    'réescomptes',
    'réescomptés',
    'réescomptez',
    'réessaierai',
    'réessaieras',
    'réessaierez',
    'réessayages',
    'réessayâmes',
    'réessayasse',
    'réessayâtes',
    'réessayerai',
    'réessayeras',
    'réessayerez',
    'réessayions',
    'réévaluâmes',
    'réévaluasse',
    'réévaluâtes',
    'réévaluerai',
    'réévalueras',
    'réévaluerez',
    'réévaluions',
    'réexaminais',
    'réexaminait',
    'réexaminant',
    'réexaminées',
    'réexaminent',
    'réexaminera',
    'réexaminiez',
    'réexaminons',
    'réexpédiais',
    'réexpédiait',
    'réexpédiant',
    'réexpédiées',
    'réexpédient',
    'réexpédiera',
    'réexpédiiez',
    'réexpédions',
    'réexportais',
    'réexportait',
    'réexportant',
    'réexportées',
    'réexportent',
    'réexportera',
    'réexportiez',
    'réexportons',
    'refaçonnais',
    'refaçonnait',
    'refaçonnant',
    'refaçonnées',
    'refaçonnent',
    'refaçonnera',
    'refaçonniez',
    'refaçonnons',
    'refaisaient',
    'réfectoires',
    'refendaient',
    'refendirent',
    'refendisses',
    'refendrions',
    'référassent',
    'référassiez',
    'référençais',
    'référençait',
    'référençant',
    'référencées',
    'référencent',
    'référencera',
    'référenciez',
    'référençons',
    'référendums',
    'référentiel',
    'référerions',
    'refermaient',
    'refermasses',
    'refermerais',
    'refermerait',
    'refermèrent',
    'refermeriez',
    'refermerons',
    'refermeront',
    'refilassent',
    'refilassiez',
    'refilerions',
    'réfléchîmes',
    'réfléchirai',
    'réfléchiras',
    'réfléchirez',
    'réfléchisse',
    'réfléchîtes',
    'réflecteurs',
    'réflections',
    'réflectives',
    'reflétaient',
    'reflétasses',
    'refléterais',
    'refléterait',
    'reflétèrent',
    'refléteriez',
    'refléterons',
    'refléteront',
    'refleurîmes',
    'refleurirai',
    'refleuriras',
    'refleurirez',
    'refleurisse',
    'refleurîtes',
    'réflexibles',
    'réflexivité',
    'réflexogène',
    'refluassent',
    'refluassiez',
    'refluerions',
    'refondaient',
    'refondirent',
    'refondisses',
    'refondrions',
    'reforgeâmes',
    'reforgeasse',
    'reforgeâtes',
    'reforgerais',
    'reforgerait',
    'reforgèrent',
    'reforgeriez',
    'reforgerons',
    'reforgeront',
    'réformables',
    'réformaient',
    'reformasses',
    'réformasses',
    'réformateur',
    'reformerais',
    'réformerais',
    'reformerait',
    'réformerait',
    'reformèrent',
    'réformèrent',
    'reformeriez',
    'réformeriez',
    'reformerons',
    'réformerons',
    'reformeront',
    'réformeront',
    'réformettes',
    'réformismes',
    'réformistes',
    'reformulais',
    'reformulait',
    'reformulant',
    'reformulées',
    'reformulent',
    'reformulera',
    'reformuliez',
    'reformulons',
    'refouillais',
    'refouillait',
    'refouillant',
    'refouillées',
    'refouillent',
    'refouillera',
    'refouilliez',
    'refouillons',
    'refoulaient',
    'refoulasses',
    'refoulement',
    'refoulerais',
    'refoulerait',
    'refoulèrent',
    'refouleriez',
    'refoulerons',
    'refouleront',
    'refourrâmes',
    'refourrasse',
    'refourrâtes',
    'refourrerai',
    'refourreras',
    'refourrerez',
    'refourrions',
    'réfractaire',
    'réfractâmes',
    'réfractasse',
    'réfractâtes',
    'réfracterai',
    'réfracteras',
    'réfracterez',
    'réfracteurs',
    'réfractions',
    'réfractrice',
    'réfrangible',
    'refrénaient',
    'réfrénaient',
    'refrénasses',
    'réfrénasses',
    'refrènement',
    'refrénerais',
    'réfrénerais',
    'refrénerait',
    'réfrénerait',
    'refrénèrent',
    'réfrénèrent',
    'refréneriez',
    'réfréneriez',
    'refrénerons',
    'réfrénerons',
    'refréneront',
    'réfréneront',
    'réfrigérais',
    'réfrigérait',
    'réfrigérant',
    'réfrigérées',
    'réfrigèrent',
    'réfrigérera',
    'réfrigériez',
    'réfrigérons',
    'réfringence',
    'réfringente',
    'réfringents',
    'refroidîmes',
    'refroidirai',
    'refroidiras',
    'refroidirez',
    'refroidisse',
    'refroidîtes',
    'réfugiaient',
    'réfugiasses',
    'réfugierais',
    'réfugierait',
    'réfugièrent',
    'réfugieriez',
    'réfugierons',
    'réfugieront',
    'refusassent',
    'refusassiez',
    'refuserions',
    'réfutassent',
    'réfutassiez',
    'réfutations',
    'réfuterions',
    'regagnaient',
    'regagnasses',
    'regagnerais',
    'regagnerait',
    'regagnèrent',
    'regagneriez',
    'regagnerons',
    'regagneront',
    'régalassent',
    'régalassiez',
    'régalements',
    'régalerions',
    'régaliennes',
    'regardables',
    'regardaient',
    'regardantes',
    'regardasses',
    'regarderais',
    'regarderait',
    'regardèrent',
    'regarderiez',
    'regarderons',
    'regarderont',
    'regarnirais',
    'regarnirait',
    'regarnirent',
    'regarniriez',
    'regarnirons',
    'regarniront',
    'regarnisses',
    'regarnissez',
    'régatassent',
    'régatassiez',
    'régaterions',
    'regazonnais',
    'regazonnait',
    'regazonnant',
    'regazonnées',
    'regazonnent',
    'regazonnera',
    'regazonniez',
    'regazonnons',
    'régénérâmes',
    'régénérasse',
    'régénérâtes',
    'régénérerai',
    'régénéreras',
    'régénérerez',
    'régénérions',
    'régentaient',
    'régentasses',
    'régenterais',
    'régenterait',
    'régentèrent',
    'régenteriez',
    'régenterons',
    'régenteront',
    'regimbaient',
    'regimbasses',
    'regimbement',
    'regimberais',
    'regimberait',
    'regimbèrent',
    'regimberiez',
    'regimberons',
    'regimberont',
    'regimbeuses',
    'reginglards',
    'régionalisa',
    'régionalise',
    'régionalisé',
    'régissaient',
    'régisseuses',
    'registrâmes',
    'registrasse',
    'registrâtes',
    'registrerai',
    'registreras',
    'registrerez',
    'registrions',
    'réglassions',
    'réglementai',
    'réglementas',
    'réglementât',
    'réglementée',
    'réglementer',
    'réglementes',
    'réglementés',
    'réglementez',
    'régleraient',
    'régnassions',
    'régneraient',
    'regonflâmes',
    'regonflasse',
    'regonflâtes',
    'regonflerai',
    'regonfleras',
    'regonflerez',
    'regonflions',
    'regorgeâmes',
    'regorgeasse',
    'regorgeâtes',
    'regorgement',
    'regorgerais',
    'regorgerait',
    'regorgèrent',
    'regorgeriez',
    'regorgerons',
    'regorgeront',
    'regrattâmes',
    'regrattasse',
    'regrattâtes',
    'regratterai',
    'regratteras',
    'regratterez',
    'regrattière',
    'regrattiers',
    'regrattions',
    'regréassent',
    'regréassiez',
    'regréerions',
    'regreffâmes',
    'regreffasse',
    'regreffâtes',
    'regrefferai',
    'regrefferas',
    'regrefferez',
    'regreffions',
    'régressâmes',
    'régressasse',
    'régressâtes',
    'régresserai',
    'régresseras',
    'régresserez',
    'régressions',
    'régressives',
    'regrettable',
    'regrettâmes',
    'regrettasse',
    'regrettâtes',
    'regretterai',
    'regretteras',
    'regretterez',
    'regrettions',
    'regrèvement',
    'regrimpâmes',
    'regrimpasse',
    'regrimpâtes',
    'regrimperai',
    'regrimperas',
    'regrimperez',
    'regrimpions',
    'regrossîmes',
    'regrossirai',
    'regrossiras',
    'regrossirez',
    'regrossisse',
    'regrossîtes',
    'regroupâmes',
    'regroupasse',
    'regroupâtes',
    'regrouperai',
    'regrouperas',
    'regrouperez',
    'regroupions',
    'régularisai',
    'régularisas',
    'régularisât',
    'régularisée',
    'régulariser',
    'régularises',
    'régularisés',
    'régularisez',
    'régularités',
    'régulateurs',
    'régulations',
    'régulatrice',
    'régurgitais',
    'régurgitait',
    'régurgitant',
    'régurgitées',
    'régurgitent',
    'régurgitera',
    'régurgitiez',
    'régurgitons',
    'réhabilitai',
    'réhabilitas',
    'réhabilitât',
    'réhabilitée',
    'réhabiliter',
    'réhabilites',
    'réhabilités',
    'réhabilitez',
    'réhabituais',
    'réhabituait',
    'réhabituant',
    'réhabituées',
    'réhabituent',
    'réhabituera',
    'réhabituiez',
    'réhabituons',
    'rehaussâmes',
    'rehaussasse',
    'rehaussâtes',
    'rehausserai',
    'rehausseras',
    'rehausserez',
    'rehaussions',
    'réhydratais',
    'réhydratait',
    'réhydratant',
    'réhydratées',
    'réhydratent',
    'réhydratera',
    'réhydratiez',
    'réhydratons',
    'réifiassent',
    'réifiassiez',
    'réification',
    'réifierions',
    'réimplantai',
    'réimplantas',
    'réimplantât',
    'réimplantée',
    'réimplanter',
    'réimplantes',
    'réimplantés',
    'réimplantez',
    'réimportais',
    'réimportait',
    'réimportant',
    'réimportées',
    'réimportent',
    'réimportera',
    'réimportiez',
    'réimportons',
    'réimposâmes',
    'réimposasse',
    'réimposâtes',
    'réimposerai',
    'réimposeras',
    'réimposerez',
    'réimposions',
    'réimprimais',
    'réimprimait',
    'réimprimant',
    'réimprimées',
    'réimpriment',
    'réimprimera',
    'réimprimiez',
    'réimprimons',
    'réincarcéra',
    'réincarcéré',
    'réincarcère',
    'réincarnais',
    'réincarnait',
    'réincarnant',
    'réincarnées',
    'réincarnent',
    'réincarnera',
    'réincarniez',
    'réincarnons',
    'réincorpora',
    'réincorpore',
    'réincorporé',
    'réinfectais',
    'réinfectait',
    'réinfectant',
    'réinfectées',
    'réinfectent',
    'réinfectera',
    'réinfectiez',
    'réinfectons',
    'réinjectais',
    'réinjectait',
    'réinjectant',
    'réinjectées',
    'réinjectent',
    'réinjectera',
    'réinjectiez',
    'réinjectons',
    'réinscrirai',
    'réinscriras',
    'réinscrirez',
    'réinscrites',
    'réinscrives',
    'réinscrivez',
    'réinscrivis',
    'réinscrivit',
    'réinscrivît',
    'réinsérâmes',
    'réinsérasse',
    'réinsérâtes',
    'réinsérerai',
    'réinséreras',
    'réinsérerez',
    'réinsérions',
    'réinstallai',
    'réinstallas',
    'réinstallât',
    'réinstallée',
    'réinstaller',
    'réinstalles',
    'réinstallés',
    'réinstallez',
    'réintégrais',
    'réintégrait',
    'réintégrant',
    'réintégrées',
    'réintègrent',
    'réintégrera',
    'réintégriez',
    'réintégrons',
    'réintroduis',
    'réintroduit',
    'réinventais',
    'réinventait',
    'réinventant',
    'réinventées',
    'réinventent',
    'réinventera',
    'réinventiez',
    'réinvention',
    'réinventons',
    'réinvesties',
    'réinvestira',
    'réinvitâmes',
    'réinvitasse',
    'réinvitâtes',
    'réinviterai',
    'réinviteras',
    'réinviterez',
    'réinvitions',
    'réitéraient',
    'réitérasses',
    'réitération',
    'réitérerais',
    'réitérerait',
    'réitérèrent',
    'réitéreriez',
    'réitérerons',
    'réitéreront',
    'rejaillîmes',
    'rejaillirai',
    'rejailliras',
    'rejaillirez',
    'rejaillisse',
    'rejaillîtes',
    'rejetassent',
    'rejetassiez',
    'rejetterais',
    'rejetterait',
    'rejetteriez',
    'rejetterons',
    'rejetteront',
    'rejoignîmes',
    'rejoignions',
    'rejoignisse',
    'rejoignîtes',
    'rejoindrais',
    'rejoindrait',
    'rejoindriez',
    'rejoindrons',
    'rejoindront',
    'rejointoies',
    'rejointoyai',
    'rejointoyas',
    'rejointoyât',
    'rejointoyée',
    'rejointoyer',
    'rejointoyés',
    'rejointoyez',
    'rejouassent',
    'rejouassiez',
    'rejouerions',
    'réjouirions',
    'réjouissais',
    'réjouissait',
    'réjouissant',
    'réjouissent',
    'réjouissiez',
    'réjouissons',
    'rejugeaient',
    'rejugeasses',
    'rejugerions',
    'relâchaient',
    'relâchasses',
    'relâchement',
    'relâcherais',
    'relâcherait',
    'relâchèrent',
    'relâcheriez',
    'relâcherons',
    'relâcheront',
    'relaierions',
    'relaissâmes',
    'relaissasse',
    'relaissâtes',
    'relaisserai',
    'relaisseras',
    'relaisserez',
    'relaissions',
    'relançaient',
    'relançasses',
    'relancerais',
    'relancerait',
    'relancèrent',
    'relanceriez',
    'relancerons',
    'relanceront',
    'rélargirais',
    'rélargirait',
    'rélargirent',
    'rélargiriez',
    'rélargirons',
    'rélargiront',
    'rélargisses',
    'rélargissez',
    'relatassent',
    'relatassiez',
    'relaterions',
    'relationnel',
    'relativisai',
    'relativisas',
    'relativisât',
    'relativisée',
    'relativiser',
    'relativises',
    'relativisés',
    'relativisez',
    'relativisme',
    'relativités',
    'relavassent',
    'relavassiez',
    'relaverions',
    'relaxassent',
    'relaxassiez',
    'relaxations',
    'relaxerions',
    'relayassent',
    'relayassiez',
    'relayerions',
    'relégations',
    'reléguaient',
    'reléguasses',
    'reléguerais',
    'reléguerait',
    'reléguèrent',
    'relégueriez',
    'reléguerons',
    'relégueront',
    'relevailles',
    'relevassent',
    'relevassiez',
    'relèvements',
    'relèverions',
    'reliassions',
    'relieraient',
    'religieuses',
    'religiosité',
    'reliquaires',
    'relogeaient',
    'relogeasses',
    'relogements',
    'relogerions',
    'relouassent',
    'relouassiez',
    'relouerions',
    'reluiraient',
    'reluisaient',
    'reluisantes',
    'reluisirent',
    'reluisisses',
    'reluquaient',
    'reluquasses',
    'reluquerais',
    'reluquerait',
    'reluquèrent',
    'reluqueriez',
    'reluquerons',
    'reluqueront',
    'remâchaient',
    'remâchasses',
    'remâcherais',
    'remâcherait',
    'remâchèrent',
    'remâcheriez',
    'remâcherons',
    'remâcheront',
    'remaillages',
    'remaillâmes',
    'remaillasse',
    'remaillâtes',
    'remaillerai',
    'remailleras',
    'remaillerez',
    'remaillions',
    'remangeâmes',
    'remangeasse',
    'remangeâtes',
    'remangerais',
    'remangerait',
    'remangèrent',
    'remangeriez',
    'remangerons',
    'remangeront',
    'remaniaient',
    'remaniasses',
    'remaniement',
    'remanierais',
    'remanierait',
    'remanièrent',
    'remanieriez',
    'remanierons',
    'remanieront',
    'remaquillai',
    'remaquillas',
    'remaquillât',
    'remaquillée',
    'remaquiller',
    'remaquilles',
    'remaquillés',
    'remaquillez',
    'remarchâmes',
    'remarchasse',
    'remarchâtes',
    'remarcherai',
    'remarcheras',
    'remarcherez',
    'remarchions',
    'remariaient',
    'remariasses',
    'remarierais',
    'remarierait',
    'remarièrent',
    'remarieriez',
    'remarierons',
    'remarieront',
    'remarquable',
    'remarquâmes',
    'remarquasse',
    'remarquâtes',
    'remarquerai',
    'remarqueras',
    'remarquerez',
    'remarquions',
    'remastiquai',
    'remastiquas',
    'remastiquât',
    'remastiquée',
    'remastiquer',
    'remastiques',
    'remastiqués',
    'remastiquez',
    'remballages',
    'remballâmes',
    'remballasse',
    'remballâtes',
    'remballerai',
    'remballeras',
    'remballerez',
    'remballions',
    'rembarquais',
    'rembarquait',
    'rembarquant',
    'rembarquées',
    'rembarquent',
    'rembarquera',
    'rembarquiez',
    'rembarquons',
    'rembarrâmes',
    'rembarrasse',
    'rembarrâtes',
    'rembarrerai',
    'rembarreras',
    'rembarrerez',
    'rembarrions',
    'rembinaient',
    'rembinasses',
    'rembinerais',
    'rembinerait',
    'rembinèrent',
    'rembineriez',
    'rembinerons',
    'rembineront',
    'remblaierai',
    'remblaieras',
    'remblaierez',
    'remblavâmes',
    'remblavasse',
    'remblavâtes',
    'remblaverai',
    'remblaveras',
    'remblaverez',
    'remblavions',
    'remblayages',
    'remblayâmes',
    'remblayasse',
    'remblayâtes',
    'remblayerai',
    'remblayeras',
    'remblayerez',
    'remblayions',
    'remboîtages',
    'remboîtâmes',
    'remboîtasse',
    'remboîtâtes',
    'remboîterai',
    'remboîteras',
    'remboîterez',
    'remboîtions',
    'rembougeais',
    'rembougeait',
    'rembougeant',
    'rembougeons',
    'rembougerai',
    'rembougeras',
    'rembougerez',
    'rembougions',
    'rembourrage',
    'rembourrais',
    'rembourrait',
    'rembourrant',
    'rembourrées',
    'rembourrent',
    'rembourrera',
    'rembourriez',
    'rembourrons',
    'rembourrure',
    'remboursais',
    'remboursait',
    'remboursant',
    'remboursées',
    'remboursent',
    'remboursera',
    'remboursiez',
    'remboursons',
    'rembrunîmes',
    'rembrunirai',
    'rembruniras',
    'rembrunirez',
    'rembrunisse',
    'rembrunîtes',
    'rembuchâmes',
    'rembuchasse',
    'rembuchâtes',
    'rembucherai',
    'rembucheras',
    'rembucherez',
    'rembuchions',
    'remédiables',
    'remédiaient',
    'remédiasses',
    'remédierais',
    'remédierait',
    'remédièrent',
    'remédieriez',
    'remédierons',
    'remédieront',
    'remembrâmes',
    'remembrasse',
    'remembrâtes',
    'remembrerai',
    'remembreras',
    'remembrerez',
    'remembrions',
    'remémorâmes',
    'remémorasse',
    'remémorâtes',
    'remémorerai',
    'remémoreras',
    'remémorerez',
    'remémorions',
    'remerciâmes',
    'remerciasse',
    'remerciâtes',
    'remercierai',
    'remercieras',
    'remercierez',
    'remerciions',
    'remettaient',
    'remettrions',
    'remeublâmes',
    'remeublasse',
    'remeublâtes',
    'remeublerai',
    'remeubleras',
    'remeublerez',
    'remeublions',
    'remisassent',
    'remisassiez',
    'remiserions',
    'rémissibles',
    'rémittences',
    'rémittentES',
    'remmaillage',
    'remmaillais',
    'remmaillait',
    'remmaillant',
    'remmaillées',
    'remmaillent',
    'remmaillera',
    'remmailliez',
    'remmaillons',
    'remmaillota',
    'remmaillote',
    'remmailloté',
    'remmanchais',
    'remmanchait',
    'remmanchant',
    'remmanchées',
    'remmanchent',
    'remmanchera',
    'remmanchiez',
    'remmanchons',
    'remmenaient',
    'remmenasses',
    'remmènerais',
    'remmènerait',
    'remmenèrent',
    'remmèneriez',
    'remmènerons',
    'remmèneront',
    'remodelages',
    'remodelâmes',
    'remodelasse',
    'remodelâtes',
    'remodèlerai',
    'remodèleras',
    'remodèlerez',
    'remodelions',
    'remontaient',
    'remontantes',
    'remontasses',
    'remonterais',
    'remonterait',
    'remontèrent',
    'remonteriez',
    'remonterons',
    'remonteront',
    'remonteuses',
    'remontrâmes',
    'remontrance',
    'remontrasse',
    'remontrâtes',
    'remontrerai',
    'remontreras',
    'remontrerez',
    'remontrions',
    'remordaient',
    'remordirent',
    'remordisses',
    'remordrions',
    'remorquages',
    'remorquâmes',
    'remorquasse',
    'remorquâtes',
    'remorquerai',
    'remorqueras',
    'remorquerez',
    'remorqueurs',
    'remorqueuse',
    'remorquions',
    'remouchâmes',
    'remouchasse',
    'remouchâtes',
    'remoucherai',
    'remoucheras',
    'remoucherez',
    'remouchions',
    'remoudrions',
    'remouillais',
    'remouillait',
    'remouillant',
    'remouillées',
    'remouillent',
    'remouillera',
    'remouilliez',
    'remouillons',
    'remoulaient',
    'remoulurent',
    'remoulusses',
    'rempaillage',
    'rempaillais',
    'rempaillait',
    'rempaillant',
    'rempaillées',
    'rempaillent',
    'rempaillera',
    'rempailleur',
    'rempailliez',
    'rempaillons',
    'rempaquetai',
    'rempaquetas',
    'rempaquetât',
    'rempaquetée',
    'rempaqueter',
    'rempaquetés',
    'rempaquetez',
    'rempaquette',
    'remparaient',
    'remparasses',
    'remparerais',
    'remparerait',
    'remparèrent',
    'rempareriez',
    'remparerons',
    'rempareront',
    'rempiétâmes',
    'rempiétasse',
    'rempiétâtes',
    'rempiéterai',
    'rempiéteras',
    'rempiéterez',
    'rempiétions',
    'rempilaient',
    'rempilasses',
    'rempilerais',
    'rempilerait',
    'rempilèrent',
    'rempileriez',
    'rempilerons',
    'rempileront',
    'remplaçable',
    'remplaçâmes',
    'remplaçante',
    'remplaçants',
    'remplaçasse',
    'remplaçâtes',
    'remplacerai',
    'remplaceras',
    'remplacerez',
    'remplacions',
    'rempliaient',
    'rempliasses',
    'remplierais',
    'remplierait',
    'remplièrent',
    'remplieriez',
    'remplierons',
    'remplieront',
    'remplirions',
    'remplissage',
    'remplissais',
    'remplissait',
    'remplissant',
    'remplissent',
    'remplisseur',
    'remplissiez',
    'remplissons',
    'remploierai',
    'remploieras',
    'remploierez',
    'remployâmes',
    'remployasse',
    'remployâtes',
    'remployions',
    'remplumâmes',
    'remplumasse',
    'remplumâtes',
    'remplumerai',
    'remplumeras',
    'remplumerez',
    'remplumions',
    'rempochâmes',
    'rempochasse',
    'rempochâtes',
    'rempocherai',
    'rempocheras',
    'rempocherez',
    'rempochions',
    'remportâmes',
    'remportasse',
    'remportâtes',
    'remporterai',
    'remporteras',
    'remporterez',
    'remportions',
    'rempotaient',
    'rempotasses',
    'rempoterais',
    'rempoterait',
    'rempotèrent',
    'rempoteriez',
    'rempoterons',
    'rempoteront',
    'rempruntais',
    'rempruntait',
    'rempruntant',
    'rempruntées',
    'rempruntent',
    'rempruntera',
    'rempruntiez',
    'rempruntons',
    'remuassions',
    'remueraient',
    'rémunérâmes',
    'rémunérasse',
    'rémunérâtes',
    'rémunérerai',
    'rémunéreras',
    'rémunérerez',
    'rémunérions',
    'renâclaient',
    'renaclasses',
    'renâclerais',
    'renâclerait',
    'renaclèrent',
    'renâcleriez',
    'renâclerons',
    'renâcleront',
    'renaissance',
    'renaissante',
    'renaissants',
    'renaissions',
    'renaîtrions',
    'renaquirent',
    'renaquisses',
    'renardaient',
    'renardasses',
    'renarderais',
    'renarderait',
    'renardèrent',
    'renarderiez',
    'renarderons',
    'renarderont',
    'renardières',
    'renaudaient',
    'renaudasses',
    'renauderais',
    'renauderait',
    'renaudèrent',
    'renauderiez',
    'renauderons',
    'renauderont',
    'rencaissage',
    'rencaissais',
    'rencaissait',
    'rencaissant',
    'rencaissées',
    'rencaissent',
    'rencaissera',
    'rencaissiez',
    'rencaissons',
    'rencardâmes',
    'rencardasse',
    'rencardâtes',
    'rencarderai',
    'rencarderas',
    'rencarderez',
    'renchaînais',
    'renchaînait',
    'renchaînant',
    'renchaînées',
    'renchaînent',
    'renchaînera',
    'renchaîniez',
    'renchaînons',
    'renchérîmes',
    'renchérirai',
    'renchériras',
    'renchérirez',
    'renchérisse',
    'renchérîtes',
    'rencognâmes',
    'rencognasse',
    'rencognâtes',
    'rencognerai',
    'rencogneras',
    'rencognerez',
    'rencognions',
    'rencontrais',
    'rencontrait',
    'rencontrant',
    'rencontrées',
    'rencontrent',
    'rencontrera',
    'rencontriez',
    'rencontrons',
    'rendez-vous',
    'rendissions',
    'rendormîmes',
    'rendormions',
    'rendormirai',
    'rendormiras',
    'rendormirez',
    'rendormisse',
    'rendormîtes',
    'rendossâmes',
    'rendossasse',
    'rendossâtes',
    'rendosserai',
    'rendosseras',
    'rendosserez',
    'rendossions',
    'renégociais',
    'renégociait',
    'renégociant',
    'renégociées',
    'renégocient',
    'renégociera',
    'renégociiez',
    'renégocions',
    'reneigerait',
    'renfaîtâmes',
    'renfaîtasse',
    'renfaîtâtes',
    'renfaîterai',
    'renfaîteras',
    'renfaîterez',
    'renfaîtions',
    'renfermâmes',
    'renfermasse',
    'renfermâtes',
    'renfermerai',
    'renfermeras',
    'renfermerez',
    'renfermions',
    'renfilaient',
    'renfilasses',
    'renfilerais',
    'renfilerait',
    'renfilèrent',
    'renfileriez',
    'renfilerons',
    'renfileront',
    'renflammais',
    'renflammait',
    'renflammant',
    'renflammées',
    'renflamment',
    'renflammera',
    'renflammiez',
    'renflammons',
    'renflassent',
    'renflassiez',
    'renflements',
    'renflerions',
    'renflouages',
    'renflouâmes',
    'renflouasse',
    'renflouâtes',
    'renflouerai',
    'renfloueras',
    'renflouerez',
    'renflouions',
    'renfonçâmes',
    'renfonçasse',
    'renfonçâtes',
    'renfoncerai',
    'renfonceras',
    'renfoncerez',
    'renfoncions',
    'renforçâmes',
    'renforçasse',
    'renforçâtes',
    'renforcerai',
    'renforceras',
    'renforcerez',
    'renforcions',
    'renformirai',
    'renformiras',
    'renformirât',
    'renfrognais',
    'renfrognait',
    'renfrognant',
    'renfrognées',
    'renfrognent',
    'renfrognera',
    'renfrogniez',
    'renfrognons',
    'rengageâmes',
    'rengageasse',
    'rengageâtes',
    'rengagement',
    'rengagerais',
    'rengagerait',
    'rengagèrent',
    'rengageriez',
    'rengagerons',
    'rengageront',
    'rengainâmes',
    'rengainasse',
    'rengainâtes',
    'rengainerai',
    'rengaineras',
    'rengainerez',
    'rengainions',
    'rengorgeais',
    'rengorgeait',
    'rengorgeant',
    'rengorgeons',
    'rengorgerai',
    'rengorgeras',
    'rengorgerez',
    'rengorgions',
    'rengraciais',
    'rengraciait',
    'rengraciant',
    'rengracient',
    'rengraciera',
    'rengraciiez',
    'rengracions',
    'rengraissai',
    'rengraissas',
    'rengraissât',
    'rengraisser',
    'rengraisses',
    'rengraissez',
    'rengrenâmes',
    'rengrénâmes',
    'rengrenasse',
    'rengrénasse',
    'rengrenâtes',
    'rengrénâtes',
    'rengrénerai',
    'rengrènerai',
    'rengréneras',
    'rengrèneras',
    'rengrénerez',
    'rengrènerez',
    'rengrenions',
    'rengrénions',
    'reniassions',
    'renieraient',
    'reniflaient',
    'reniflasses',
    'reniflement',
    'reniflerais',
    'reniflerait',
    'reniflèrent',
    'renifleriez',
    'reniflerons',
    'renifleront',
    'renifleuses',
    'renommaient',
    'renommasses',
    'renommerais',
    'renommerait',
    'renommèrent',
    'renommeriez',
    'renommerons',
    'renommeront',
    'renonçaient',
    'renonçasses',
    'renoncement',
    'renoncerais',
    'renoncerait',
    'renoncèrent',
    'renonceriez',
    'renoncerons',
    'renonceront',
    'renouassent',
    'renouassiez',
    'renouements',
    'renouerions',
    'renouvelais',
    'renouvelait',
    'renouvelant',
    'renouvelées',
    'renouveliez',
    'renouvelles',
    'renouvelons',
    'rénovassent',
    'rénovassiez',
    'rénovateurs',
    'rénovations',
    'rénovatrice',
    'rénoverions',
    'renquillais',
    'renquillait',
    'renquillant',
    'renquillées',
    'renquillent',
    'renquillera',
    'renquilliez',
    'renquillons',
    'renseignais',
    'renseignait',
    'renseignant',
    'renseignées',
    'renseignent',
    'renseignera',
    'renseigniez',
    'renseignons',
    'rentabilisa',
    'rentabilise',
    'rentabilisé',
    'rentabilité',
    'rentamaient',
    'rentamasses',
    'rentamerais',
    'rentamerait',
    'rentamèrent',
    'rentameriez',
    'rentamerons',
    'rentameront',
    'rentassions',
    'renteraient',
    'rentoilâmes',
    'rentoilasse',
    'rentoilâtes',
    'rentoilerai',
    'rentoileras',
    'rentoilerez',
    'rentoileurs',
    'rentoileuse',
    'rentoilions',
    'rentrassent',
    'rentrassiez',
    'rentrerions',
    'rentrouvert',
    'rentrouvres',
    'rentrouvrez',
    'rentrouvrir',
    'rentrouvris',
    'rentrouvrit',
    'rentrouvrît',
    'rentrouvrue',
    'rentrouvrus',
    'renveloppai',
    'renveloppas',
    'renveloppât',
    'renveloppée',
    'renvelopper',
    'renveloppés',
    'renveloppez',
    'renvenimais',
    'renvenimait',
    'renvenimant',
    'renvenimées',
    'renveniment',
    'renvenimera',
    'renvenimiez',
    'renvenimons',
    'renvergeais',
    'renvergeait',
    'renvergeant',
    'renvergeons',
    'renvergerai',
    'renvergeras',
    'renvergerez',
    'renvergions',
    'renverrions',
    'renversâmes',
    'renversante',
    'renversants',
    'renversasse',
    'renversâtes',
    'renverserai',
    'renverseras',
    'renverserez',
    'renversions',
    'renviassent',
    'renviassiez',
    'renvidaient',
    'renvidasses',
    'renviderais',
    'renviderait',
    'renvidèrent',
    'renvideriez',
    'renviderons',
    'renvideront',
    'renvierions',
    'renvoyaient',
    'renvoyasses',
    'renvoyèrent',
    'réoccupâmes',
    'réoccupasse',
    'réoccupâtes',
    'réoccuperai',
    'réoccuperas',
    'réoccuperez',
    'réoccupions',
    'réopéraient',
    'réopérasses',
    'réopérerais',
    'réopérerait',
    'réopérèrent',
    'réopéreriez',
    'réopérerons',
    'réopéreront',
    'réorchestra',
    'réorchestre',
    'réorchestré',
    'réordonnais',
    'réordonnait',
    'réordonnant',
    'réordonnées',
    'réordonnent',
    'réordonnera',
    'réordonniez',
    'réordonnons',
    'réorganisai',
    'réorganisas',
    'réorganisât',
    'réorganisée',
    'réorganiser',
    'réorganises',
    'réorganisés',
    'réorganisez',
    'réorientais',
    'réorientait',
    'réorientant',
    'réorientées',
    'réorientent',
    'réorientera',
    'réorientiez',
    'réorientons',
    'réouverture',
    'repaierions',
    'repairaient',
    'repairasses',
    'repairerais',
    'repairerait',
    'repairèrent',
    'repaireriez',
    'repairerons',
    'repaireront',
    'repaissions',
    'repaîtrions',
    'répandaient',
    'répandirent',
    'répandisses',
    'répandrions',
    'reparaisses',
    'reparaissez',
    'reparaîtrai',
    'reparaîtras',
    'reparaîtrez',
    'réparassent',
    'réparassiez',
    'réparateurs',
    'réparations',
    'réparatrice',
    'réparerions',
    'reparlaient',
    'reparlasses',
    'reparlerais',
    'reparlerait',
    'reparlèrent',
    'reparleriez',
    'reparlerons',
    'reparleront',
    'repartageai',
    'repartageas',
    'repartageât',
    'repartagées',
    'repartagent',
    'repartagera',
    'repartagiez',
    'repartaient',
    'répartement',
    'repartirais',
    'répartirais',
    'repartirait',
    'répartirait',
    'repartirent',
    'répartirent',
    'repartiriez',
    'répartiriez',
    'repartirons',
    'répartirons',
    'repartiront',
    'répartiront',
    'repartisses',
    'répartisses',
    'répartissez',
    'répartiteur',
    'répartition',
    'reparussent',
    'reparussiez',
    'repassaient',
    'repassasses',
    'repasserais',
    'repasserait',
    'repassèrent',
    'repasseriez',
    'repasserons',
    'repasseront',
    'repasseuses',
    'repatinâmes',
    'repatinasse',
    'repatinâtes',
    'repatinerai',
    'repatineras',
    'repatinerez',
    'repatinions',
    'repavassent',
    'repavassiez',
    'repaverions',
    'repayassent',
    'repayassiez',
    'repayerions',
    'repêchaient',
    'repêchasses',
    'repêcherais',
    'repêcherait',
    'repêchèrent',
    'repêcheriez',
    'repêcherons',
    'repêcheront',
    'repeignâmes',
    'repeignasse',
    'repeignâtes',
    'repeignerai',
    'repeigneras',
    'repeignerez',
    'repeignîmes',
    'repeignions',
    'repeignisse',
    'repeignîtes',
    'repeindrais',
    'repeindrait',
    'repeindriez',
    'repeindrons',
    'repeindront',
    'rependaient',
    'rependirent',
    'rependisses',
    'rependrions',
    'repensaient',
    'repensasses',
    'repenserais',
    'repenserait',
    'repensèrent',
    'repenseriez',
    'repenserons',
    'repenseront',
    'repentaient',
    'repentances',
    'repentantes',
    'repentirais',
    'repentirait',
    'repentirent',
    'repentiriez',
    'repentirons',
    'repentiront',
    'repentisses',
    'repérassent',
    'repérassiez',
    'reperçaient',
    'reperçasses',
    'repercerais',
    'repercerait',
    'repercèrent',
    'reperceriez',
    'repercerons',
    'reperceront',
    'répercutais',
    'répercutait',
    'répercutant',
    'répercutées',
    'répercutent',
    'répercutera',
    'répercutiez',
    'répercutons',
    'reperdaient',
    'reperdirent',
    'reperdisses',
    'reperdrions',
    'repérerions',
    'répertoires',
    'répertoriai',
    'répertorias',
    'répertoriât',
    'répertoriée',
    'répertorier',
    'répertories',
    'répertoriés',
    'répertoriez',
    'répétassent',
    'répétassiez',
    'répéterions',
    'répétiteurs',
    'répétitions',
    'répétitives',
    'répétitorat',
    'répétitrice',
    'repeuplâmes',
    'repeuplasse',
    'repeuplâtes',
    'repeuplerai',
    'repeupleras',
    'repeuplerez',
    'repeuplions',
    'repinçaient',
    'repinçasses',
    'repincerais',
    'repincerait',
    'repincèrent',
    'repinceriez',
    'repincerons',
    'repinceront',
    'repiquaient',
    'repiquasses',
    'repiquerais',
    'repiquerait',
    'repiquèrent',
    'repiqueriez',
    'repiquerons',
    'repiqueront',
    'replaçaient',
    'replaçasses',
    'replacement',
    'replacerais',
    'replacerait',
    'replacèrent',
    'replaceriez',
    'replacerons',
    'replaceront',
    'replantâmes',
    'replantasse',
    'replantâtes',
    'replanterai',
    'replanteras',
    'replanterez',
    'replantions',
    'replâtrages',
    'replâtrâmes',
    'replâtrasse',
    'replâtrâtes',
    'replâtrerai',
    'replâtreras',
    'replâtrerez',
    'replâtrions',
    'repleuvrait',
    'repleuvront',
    'repliassent',
    'repliassiez',
    'réplication',
    'repliements',
    'replierions',
    'répliquâmes',
    'répliquasse',
    'répliquâtes',
    'répliquerai',
    'répliqueras',
    'répliquerez',
    'répliquions',
    'replissâmes',
    'replissasse',
    'replissâtes',
    'replisserai',
    'replisseras',
    'replisserez',
    'replissions',
    'reploierais',
    'reploierait',
    'reploieriez',
    'reploierons',
    'reploieront',
    'replongeais',
    'replongeait',
    'replongeant',
    'replongeons',
    'replongerai',
    'replongeras',
    'replongerez',
    'replongions',
    'reployaient',
    'reployasses',
    'reployèrent',
    'repolirions',
    'repolissais',
    'repolissait',
    'repolissant',
    'repolissent',
    'repolissiez',
    'repolissons',
    'répondaient',
    'répondantes',
    'répondeuses',
    'répondirent',
    'répondisses',
    'répondrions',
    'reportaient',
    'reportasses',
    'reporterais',
    'reporterait',
    'reportèrent',
    'reporteriez',
    'reporterons',
    'reporteront',
    'reportrices',
    'reposassent',
    'reposassiez',
    'repose-pied',
    'reposerions',
    'repose-tête',
    'repoussages',
    'repoussâmes',
    'repoussante',
    'repoussants',
    'repoussasse',
    'repoussâtes',
    'repousserai',
    'repousseras',
    'repousserez',
    'repoussions',
    'repoussoirs',
    'reprenaient',
    'reprendrais',
    'reprendrait',
    'reprendriez',
    'reprendrons',
    'reprendront',
    'représentai',
    'représentas',
    'représentât',
    'représentée',
    'représenter',
    'représentes',
    'représentés',
    'représentez',
    'répressible',
    'répressions',
    'répressives',
    'réprimaient',
    'réprimandai',
    'réprimandas',
    'réprimandât',
    'réprimandée',
    'réprimander',
    'réprimandes',
    'réprimandés',
    'réprimandez',
    'réprimasses',
    'réprimerais',
    'réprimerait',
    'réprimèrent',
    'réprimeriez',
    'réprimerons',
    'réprimeront',
    'reprisaient',
    'reprisasses',
    'repriserais',
    'repriserait',
    'reprisèrent',
    'repriseriez',
    'repriserons',
    'repriseront',
    'repriseuses',
    'reprissions',
    'réprobateur',
    'réprobation',
    'reprochable',
    'reprochâmes',
    'reprochasse',
    'reprochâtes',
    'reprocherai',
    'reprocheras',
    'reprocherez',
    'reprochions',
    'reproductif',
    'reproduirai',
    'reproduiras',
    'reproduirez',
    'reproduises',
    'reproduisez',
    'reproduisis',
    'reproduisit',
    'reproduisît',
    'reproduites',
    'reprogramma',
    'reprogramme',
    'reprogrammé',
    'reprouvâmes',
    'réprouvâmes',
    'reprouvasse',
    'réprouvasse',
    'reprouvâtes',
    'réprouvâtes',
    'reprouverai',
    'réprouverai',
    'reprouveras',
    'réprouveras',
    'reprouverez',
    'réprouverez',
    'reprouvions',
    'réprouvions',
    'républicain',
    'républiques',
    'répudiaient',
    'répudiasses',
    'répudiation',
    'répudierais',
    'répudierait',
    'répudièrent',
    'répudieriez',
    'répudierons',
    'répudieront',
    'répugnaient',
    'répugnances',
    'répugnantes',
    'répugnasses',
    'répugnerais',
    'répugnerait',
    'répugnèrent',
    'répugneriez',
    'répugnerons',
    'répugneront',
    'réputassent',
    'réputassiez',
    'réputations',
    'réputerions',
    'requéraient',
    'requerrions',
    'requinquais',
    'requinquait',
    'requinquant',
    'requinquées',
    'requinquent',
    'requinquera',
    'requinquiez',
    'requinquons',
    'réquisition',
    'requissions',
    'reroisasses',
    'reroisèrent',
    'resalassent',
    'resalassiez',
    'resalerions',
    'resalirions',
    'resalissais',
    'resalissait',
    'resalissant',
    'resalissiez',
    'resalissons',
    'resaluaient',
    'resaluasses',
    'resaluerais',
    'resaluerait',
    'resaluèrent',
    'resalueriez',
    'resaluerons',
    'resalueront',
    'resarcelées',
    'rescindable',
    'rescindâmes',
    'rescindante',
    'rescindants',
    'rescindasse',
    'rescindâtes',
    'rescinderai',
    'rescinderas',
    'rescinderez',
    'rescindions',
    'rescisoires',
    'rescription',
    'reséquaient',
    'reséquasses',
    'reséquerais',
    'reséquerait',
    'reséquèrent',
    'reséqueriez',
    'reséquerons',
    'reséqueront',
    'réservaient',
    'réservasses',
    'réservation',
    'réserverais',
    'réserverait',
    'réservèrent',
    'réserveriez',
    'réserverons',
    'réserveront',
    'réservistes',
    'résidassent',
    'résidassiez',
    'résidentiel',
    'résiderions',
    'résiduaires',
    'résiduelles',
    'résignaient',
    'résignasses',
    'résignation',
    'résignerais',
    'résignerait',
    'résignèrent',
    'résigneriez',
    'résignerons',
    'résigneront',
    'résiliables',
    'résiliaient',
    'résiliasses',
    'résiliation',
    'résiliences',
    'résilierais',
    'résilierait',
    'résilièrent',
    'résilieriez',
    'résilierons',
    'résilieront',
    'résinassent',
    'résinassiez',
    'résinerions',
    'résinifères',
    'résinifiais',
    'résinifiait',
    'résinifiant',
    'résinifiées',
    'résinifient',
    'résinifiera',
    'résinifiiez',
    'résinifions',
    'résistaient',
    'résistances',
    'résistantes',
    'résistasses',
    'résisterais',
    'résisterait',
    'résistèrent',
    'résisteriez',
    'résisterons',
    'résisteront',
    'résistibles',
    'résistivité',
    'resocialisa',
    'resocialise',
    'resocialisé',
    'résolussent',
    'résolussiez',
    'résolutions',
    'résolutives',
    'résolutoire',
    'résolvaient',
    'résolvantes',
    'résonnaient',
    'résonnantes',
    'résonnasses',
    'résonnerais',
    'résonnerait',
    'résonnèrent',
    'résonneriez',
    'résonnerons',
    'résonneront',
    'résorbaient',
    'résorbasses',
    'résorberais',
    'résorberait',
    'résorbèrent',
    'résorberiez',
    'résorberons',
    'résorberont',
    'résorptions',
    'résoudrions',
    'respectable',
    'respectâmes',
    'respectasse',
    'respectâtes',
    'respecterai',
    'respecteras',
    'respecterez',
    'respections',
    'respectives',
    'respectueux',
    'respirables',
    'respiraient',
    'respirasses',
    'respirateur',
    'respiration',
    'respirerais',
    'respirerait',
    'respirèrent',
    'respireriez',
    'respirerons',
    'respireront',
    'resplendira',
    'responsable',
    'resquillage',
    'resquillais',
    'resquillait',
    'resquillant',
    'resquillées',
    'resquillent',
    'resquillera',
    'resquilleur',
    'resquilliez',
    'resquillons',
    'ressaierais',
    'ressaierait',
    'ressaieriez',
    'ressaierons',
    'ressaieront',
    'ressaignais',
    'ressaignait',
    'ressaignant',
    'ressaignées',
    'ressaignent',
    'ressaignera',
    'ressaigniez',
    'ressaignons',
    'ressaisîmes',
    'ressaisirai',
    'ressaisiras',
    'ressaisirez',
    'ressaisisse',
    'ressaisîtes',
    'ressassâmes',
    'ressassasse',
    'ressassâtes',
    'ressasserai',
    'ressasseras',
    'ressasserez',
    'ressasseurs',
    'ressassions',
    'ressautâmes',
    'ressautasse',
    'ressautâtes',
    'ressauterai',
    'ressauteras',
    'ressauterez',
    'ressautions',
    'ressayaient',
    'ressayasses',
    'ressayerais',
    'ressayerait',
    'ressayèrent',
    'ressayeriez',
    'ressayerons',
    'ressayeront',
    'ressemaient',
    'ressemasses',
    'ressemblais',
    'ressemblait',
    'ressemblant',
    'ressemblent',
    'ressemblera',
    'ressembliez',
    'ressemblons',
    'ressemelage',
    'ressemelais',
    'ressemelait',
    'ressemelant',
    'ressemelées',
    'ressemeliez',
    'ressemelles',
    'ressemelons',
    'ressèmerais',
    'ressèmerait',
    'ressemèrent',
    'ressèmeriez',
    'ressèmerons',
    'ressèmeront',
    'ressentîmes',
    'ressentions',
    'ressentirai',
    'ressentiras',
    'ressentirez',
    'ressentisse',
    'ressentîtes',
    'resserrâmes',
    'resserrasse',
    'resserrâtes',
    'resserrerai',
    'resserreras',
    'resserrerez',
    'resserrions',
    'resservîmes',
    'resservions',
    'resservirai',
    'resserviras',
    'resservirez',
    'resservisse',
    'resservîtes',
    'ressortîmes',
    'ressortions',
    'ressortirai',
    'ressortiras',
    'ressortirez',
    'ressortisse',
    'ressortîtes',
    'ressoudâmes',
    'ressoudasse',
    'ressoudâtes',
    'ressouderai',
    'ressouderas',
    'ressouderez',
    'ressoudions',
    'ressourçais',
    'ressourçait',
    'ressourçant',
    'ressourcées',
    'ressourcent',
    'ressourcera',
    'ressourciez',
    'ressourçons',
    'ressouvenez',
    'ressouvenir',
    'ressouvenue',
    'ressouvenus',
    'ressouviens',
    'ressouvient',
    'ressuassent',
    'ressuassiez',
    'ressuerions',
    'ressuierais',
    'ressuierait',
    'ressuieriez',
    'ressuierons',
    'ressuieront',
    'ressurgîmes',
    'ressurgirai',
    'ressurgiras',
    'ressurgirez',
    'ressurgisse',
    'ressurgîtes',
    'ressuscitai',
    'ressuscitas',
    'ressuscitât',
    'ressuscitée',
    'ressusciter',
    'ressuscites',
    'ressuscités',
    'ressuscitez',
    'ressuyaient',
    'ressuyasses',
    'ressuyèrent',
    'restassions',
    'restaurâmes',
    'restaurante',
    'restaurants',
    'restaurasse',
    'restaurâtes',
    'restaurerai',
    'restaureras',
    'restaurerez',
    'restaurions',
    'resteraient',
    'restituable',
    'restituâmes',
    'restituasse',
    'restituâtes',
    'restituerai',
    'restitueras',
    'restituerez',
    'restituions',
    'restitution',
    'restoroutes',
    'restreignes',
    'restreignez',
    'restreignis',
    'restreignit',
    'restreignît',
    'restreindra',
    'restreindre',
    'restreintes',
    'restrictifs',
    'restriction',
    'restrictive',
    'restringent',
    'restructura',
    'restructure',
    'restructuré',
    'résultantes',
    'résulterait',
    'résumassent',
    'résumassiez',
    'résumerions',
    'résurgences',
    'resurgirais',
    'resurgirait',
    'resurgirent',
    'resurgiriez',
    'resurgirons',
    'resurgiront',
    'resurgisses',
    'resurgissez',
    'rétablirais',
    'rétablirait',
    'rétablirent',
    'rétabliriez',
    'rétablirons',
    'rétabliront',
    'rétablisses',
    'rétablissez',
    'retaillâmes',
    'retaillasse',
    'retaillâtes',
    'retaillerai',
    'retailleras',
    'retaillerez',
    'retaillions',
    'rétamassent',
    'rétamassiez',
    'rétamerions',
    'retapassent',
    'retapassiez',
    'retaperions',
    'retapissais',
    'retapissait',
    'retapissant',
    'retapissées',
    'retapissent',
    'retapissera',
    'retapissiez',
    'retapissons',
    'retardaient',
    'retardasses',
    'retardateur',
    'retardement',
    'retarderais',
    'retarderait',
    'retardèrent',
    'retarderiez',
    'retarderons',
    'retarderont',
    'retâtassent',
    'retâtassiez',
    'retâterions',
    'reteignîmes',
    'reteignions',
    'reteignisse',
    'reteignîtes',
    'reteindrais',
    'reteindrait',
    'reteindriez',
    'reteindrons',
    'reteindront',
    'retendaient',
    'retendirent',
    'retendisses',
    'retendrions',
    'retentaient',
    'retentasses',
    'retenterais',
    'retenterait',
    'retentèrent',
    'retenteriez',
    'retenterons',
    'retenteront',
    'retentirais',
    'retentirait',
    'retentirent',
    'retentiriez',
    'retentirons',
    'retentiront',
    'retentisses',
    'retentissez',
    'reterçaient',
    'reterçasses',
    'retercerais',
    'retercerait',
    'retercèrent',
    'reterceriez',
    'retercerons',
    'reterceront',
    'retersaient',
    'retersasses',
    'reterserais',
    'reterserait',
    'retersèrent',
    'reterseriez',
    'reterserons',
    'reterseront',
    'réticulaire',
    'réticulâmes',
    'réticulasse',
    'réticulâtes',
    'réticulerai',
    'réticuleras',
    'réticulerez',
    'réticulions',
    'retiendrais',
    'retiendrait',
    'retiendriez',
    'retiendrons',
    'retiendront',
    'rétiniennes',
    'retinssions',
    'retirassent',
    'retirassiez',
    'retirations',
    'retirements',
    'retirerions',
    'retissaient',
    'retissasses',
    'retisserais',
    'retisserait',
    'retissèrent',
    'retisseriez',
    'retisserons',
    'retisseront',
    'retombaient',
    'retombantes',
    'retombasses',
    'retombement',
    'retomberais',
    'retomberait',
    'retombèrent',
    'retomberiez',
    'retomberons',
    'retomberont',
    'retondaient',
    'retondirent',
    'retondisses',
    'retondrions',
    'retordaient',
    'retordement',
    'retordeuses',
    'retordirent',
    'retordisses',
    'retordrions',
    'rétorquâmes',
    'rétorquasse',
    'rétorquâtes',
    'rétorquerai',
    'rétorqueras',
    'rétorquerez',
    'rétorquions',
    'retouchable',
    'retouchâmes',
    'retouchasse',
    'retouchâtes',
    'retoucherai',
    'retoucheras',
    'retoucherez',
    'retoucheurs',
    'retoucheuse',
    'retouchions',
    'retournages',
    'retournâmes',
    'retournasse',
    'retournâtes',
    'retournerai',
    'retourneras',
    'retournerez',
    'retournions',
    'retraçaient',
    'retraçasses',
    'retracerais',
    'retracerait',
    'retracèrent',
    'retraceriez',
    'retracerons',
    'retraceront',
    'rétractâmes',
    'rétractasse',
    'rétractâtes',
    'rétracterai',
    'rétracteras',
    'rétracterez',
    'rétracteurs',
    'rétractiles',
    'rétractions',
    'rétractrice',
    'retraduirai',
    'retraduiras',
    'retraduirez',
    'retraduises',
    'retraduisez',
    'retraduisis',
    'retraduisit',
    'retraduisît',
    'retraduites',
    'retrairions',
    'retraitâmes',
    'retraitante',
    'retraitants',
    'retraitasse',
    'retraitâtes',
    'retraiterai',
    'retraiteras',
    'retraiterez',
    'retraitions',
    'retranchais',
    'retranchait',
    'retranchant',
    'retranchées',
    'retranchent',
    'retranchera',
    'retranchiez',
    'retranchons',
    'retranscris',
    'retranscrit',
    'retransmets',
    'retransmise',
    'retravailla',
    'retravaille',
    'retravaillé',
    'retraversai',
    'retraversas',
    'retraversât',
    'retraversée',
    'retraverser',
    'retraverses',
    'retraversés',
    'retraversez',
    'retrayaient',
    'retrayantes',
    'rétrécirais',
    'rétrécirait',
    'rétrécirent',
    'rétréciriez',
    'rétrécirons',
    'rétréciront',
    'rétrécisses',
    'rétrécissez',
    'retrempâmes',
    'retrempasse',
    'retrempâtes',
    'retremperai',
    'retremperas',
    'retremperez',
    'retrempions',
    'rétribuâmes',
    'rétribuasse',
    'rétribuâtes',
    'rétribuerai',
    'rétribueras',
    'rétribuerez',
    'rétribuions',
    'rétribution',
    'rétroactifs',
    'rétroaction',
    'rétroactive',
    'rétroagîmes',
    'rétroagirai',
    'rétroagiras',
    'rétroagirez',
    'rétroagisse',
    'rétroagîtes',
    'rétrocédais',
    'rétrocédait',
    'rétrocédant',
    'rétrocédées',
    'rétrocèdent',
    'rétrocédera',
    'rétrocédiez',
    'rétrocédons',
    'rétrofusées',
    'rétrogradai',
    'rétrogradas',
    'rétrogradât',
    'rétrogradée',
    'rétrograder',
    'rétrogrades',
    'rétrogradés',
    'rétrogradez',
    'retroussage',
    'retroussais',
    'retroussait',
    'retroussant',
    'retroussées',
    'retroussent',
    'retroussera',
    'retroussiez',
    'retroussons',
    'retrouvable',
    'retrouvâmes',
    'retrouvasse',
    'retrouvâtes',
    'retrouverai',
    'retrouveras',
    'retrouverez',
    'retrouvions',
    'rétroviseur',
    'réunifiâmes',
    'réunifiasse',
    'réunifiâtes',
    'réunifierai',
    'réunifieras',
    'réunifierez',
    'réunifiions',
    'réuniraient',
    'réunissages',
    'réunissions',
    'réussirions',
    'réussissais',
    'réussissait',
    'réussissant',
    'réussissent',
    'réussissiez',
    'réussissons',
    'réutilisais',
    'réutilisait',
    'réutilisant',
    'réutilisées',
    'réutilisent',
    'réutilisera',
    'réutilisiez',
    'réutilisons',
    'revaccinais',
    'revaccinait',
    'revaccinant',
    'revaccinées',
    'revaccinent',
    'revaccinera',
    'revacciniez',
    'revaccinons',
    'revalorisai',
    'revalorisas',
    'revalorisât',
    'revalorisée',
    'revaloriser',
    'revalorises',
    'revalorisés',
    'revalorisez',
    'revalussent',
    'revalussiez',
    'revanchâmes',
    'revancharde',
    'revanchards',
    'revanchasse',
    'revanchâtes',
    'revancherai',
    'revancheras',
    'revancherez',
    'revanchions',
    'revanchisme',
    'rêvassaient',
    'rêvassasses',
    'rêvasserais',
    'rêvasserait',
    'rêvassèrent',
    'rêvasseries',
    'rêvasseriez',
    'rêvasserons',
    'rêvasseront',
    'rêvasseuses',
    'revaudrions',
    'revécussent',
    'revécussiez',
    'réveillâmes',
    'réveillasse',
    'réveillâtes',
    'réveillerai',
    'réveilleras',
    'réveillerez',
    'réveilleurs',
    'réveilleuse',
    'réveillions',
    'réveillonna',
    'réveillonne',
    'réveillonné',
    'révélassent',
    'révélassiez',
    'révélateurs',
    'révélations',
    'révélatrice',
    'révélerions',
    'revendaient',
    'revendeuses',
    'revendiquai',
    'revendiquas',
    'revendiquât',
    'revendiquée',
    'revendiquer',
    'revendiques',
    'revendiqués',
    'revendiquez',
    'revendirent',
    'revendisses',
    'revendrions',
    'révérassent',
    'révérassiez',
    'réverbérais',
    'réverbérait',
    'réverbérant',
    'réverbérées',
    'réverbèrent',
    'réverbérera',
    'réverbériez',
    'réverbérons',
    'reverchâmes',
    'reverchasse',
    'reverchâtes',
    'revercherai',
    'revercheras',
    'revercherez',
    'reverchions',
    'reverdirais',
    'reverdirait',
    'reverdirent',
    'reverdiriez',
    'reverdirons',
    'reverdiront',
    'reverdisses',
    'reverdissez',
    'révérerions',
    'revernirais',
    'revernirait',
    'revernirent',
    'reverniriez',
    'revernirons',
    'reverniront',
    'revernisses',
    'revernissez',
    'reverraient',
    'reversaient',
    'reversasses',
    'reverserais',
    'reverserait',
    'reversèrent',
    'reverseriez',
    'reverserons',
    'reverseront',
    'réversibles',
    'revêtements',
    'revêtirions',
    'revêtissent',
    'revêtissiez',
    'reveuillent',
    'rêveusement',
    'reviendrais',
    'reviendrait',
    'reviendriez',
    'reviendrons',
    'reviendront',
    'revigorâmes',
    'revigorasse',
    'revigorâtes',
    'revigorerai',
    'revigoreras',
    'revigorerez',
    'revigorions',
    'revinssions',
    'revirassent',
    'revirassiez',
    'revirements',
    'revirerions',
    'révisassent',
    'révisassiez',
    'réviserions',
    'revissaient',
    'revissasses',
    'revisserais',
    'revisserait',
    'revissèrent',
    'revisseriez',
    'revisserons',
    'revisseront',
    'revitalisai',
    'revitalisas',
    'revitalisât',
    'revitalisée',
    'revitaliser',
    'revitalises',
    'revitalisés',
    'revitalisez',
    'revivifiais',
    'revivifiait',
    'revivifiant',
    'revivifiées',
    'revivifient',
    'revivifiera',
    'revivifiiez',
    'revivifions',
    'revivraient',
    'révocations',
    'revolassent',
    'revolassiez',
    'revolerions',
    'révoltaient',
    'révoltantes',
    'révoltasses',
    'révolterais',
    'révolterait',
    'révoltèrent',
    'révolteriez',
    'révolterons',
    'révolteront',
    'révolutions',
    'révolvérisa',
    'révolvérise',
    'révolvérisé',
    'révoquaient',
    'révoquantes',
    'révoquasses',
    'révoquerais',
    'révoquerait',
    'révoquèrent',
    'révoqueriez',
    'révoquerons',
    'révoqueront',
    'revotassent',
    'revotassiez',
    'revoterions',
    'revoudrions',
    'revoulaient',
    'revoulurent',
    'revoulusses',
    'révulsaient',
    'révulsasses',
    'révulserais',
    'révulserait',
    'révulsèrent',
    'révulseriez',
    'révulserons',
    'révulseront',
    'rhabillages',
    'rhabillâmes',
    'rhabillasse',
    'rhabillâtes',
    'rhabillerai',
    'rhabilleras',
    'rhabillerez',
    'rhabilleurs',
    'rhabilleuse',
    'rhabillions',
    'rhapsodique',
    'rhéologique',
    'rhétoricien',
    'rhétoriques',
    'rhinologies',
    'rhinolophes',
    'rhizocarpée',
    'rhizocarpés',
    'rhizoctones',
    'rhizophages',
    'rhizophores',
    'rhizosphère',
    'rhizostomes',
    'rhodanienne',
    'rhodopsines',
    'rhomboïdale',
    'rhomboïdaux',
    'rhotacismes',
    'rhumassions',
    'rhumatisant',
    'rhumatismal',
    'rhumatismes',
    'rhumatoïdes',
    'rhumeraient',
    'ribambelles',
    'ribaudequin',
    'riblassions',
    'ribleraient',
    'riboflavine',
    'riboulaient',
    'riboulasses',
    'riboulerais',
    'riboulerait',
    'riboulèrent',
    'ribouleriez',
    'riboulerons',
    'ribouleront',
    'ricanassent',
    'ricanassiez',
    'ricanements',
    'ricanerions',
    'richissimes',
    'rickettsies',
    'ricochaient',
    'ricochasses',
    'ricocherais',
    'ricocherait',
    'ricochèrent',
    'ricocheriez',
    'ricocherons',
    'ricocheront',
    'ridiculisai',
    'ridiculisas',
    'ridiculisât',
    'ridiculisée',
    'ridiculiser',
    'ridiculises',
    'ridiculisés',
    'ridiculisez',
    'riffaudâmes',
    'riffaudasse',
    'riffaudâtes',
    'riffauderai',
    'riffauderas',
    'riffauderez',
    'riffaudions',
    'riflassions',
    'rifleraient',
    'rigidifiais',
    'rigidifiait',
    'rigidifiant',
    'rigidifiées',
    'rigidifient',
    'rigidifiera',
    'rigidifiiez',
    'rigidifions',
    'rigolassent',
    'rigolassiez',
    'rigolerions',
    'rigoureuses',
    'rimaillâmes',
    'rimaillasse',
    'rimaillâtes',
    'rimaillerai',
    'rimailleras',
    'rimaillerez',
    'rimailleurs',
    'rimailleuse',
    'rimaillions',
    'rinçassions',
    'rinceraient',
    'rinforzando',
    'ringardages',
    'ringardâmes',
    'ringardasse',
    'ringardâtes',
    'ringarderai',
    'ringarderas',
    'ringarderez',
    'ringardions',
    'ripaillâmes',
    'ripaillasse',
    'ripaillâtes',
    'ripaillerai',
    'ripailleras',
    'ripaillerez',
    'ripailleurs',
    'ripailleuse',
    'ripaillions',
    'ripolinâmes',
    'ripolinasse',
    'ripolinâtes',
    'ripolinerai',
    'ripolineras',
    'ripolinerez',
    'ripolinions',
    'ripostaient',
    'ripostasses',
    'riposterais',
    'riposterait',
    'ripostèrent',
    'riposteriez',
    'riposterons',
    'riposteront',
    'risiblement',
    'risquassent',
    'risquassiez',
    'risquerions',
    'risque-tout',
    'rissolaient',
    'rissolasses',
    'rissolerais',
    'rissolerait',
    'rissolèrent',
    'rissoleriez',
    'rissolerons',
    'rissoleront',
    'ristournais',
    'ristournait',
    'ristournant',
    'ristournées',
    'ristournent',
    'ristournera',
    'ristourniez',
    'ristournons',
    'ritournelle',
    'ritualisais',
    'ritualisait',
    'ritualisant',
    'ritualisées',
    'ritualisent',
    'ritualisera',
    'ritualisiez',
    'ritualismes',
    'ritualisons',
    'ritualistes',
    'rivalisâmes',
    'rivalisasse',
    'rivalisâtes',
    'rivaliserai',
    'rivaliseras',
    'rivaliserez',
    'rivalisions',
    'riveraineté',
    'rivetassent',
    'rivetassiez',
    'rivetterais',
    'rivetterait',
    'rivetteriez',
    'rivetterons',
    'rivetteront',
    'riziculteur',
    'riziculture',
    'roast-beefs',
    'robinetiers',
    'roboratives',
    'robotisâmes',
    'robotisasse',
    'robotisâtes',
    'robotiserai',
    'robotiseras',
    'robotiserez',
    'robotisions',
    'robustement',
    'robustesses',
    'rocaillages',
    'rocailleurs',
    'rocailleuse',
    'rochassière',
    'rochassiers',
    'rocouassent',
    'rocouassiez',
    'rocouerions',
    'rocquassent',
    'rocquassiez',
    'rocquerions',
    'rôdaillâmes',
    'rôdaillasse',
    'rôdaillâtes',
    'rôdaillerai',
    'rôdailleras',
    'rôdaillerez',
    'rôdaillions',
    'rodomontade',
    'rognassions',
    'rogneraient',
    'rognonnâmes',
    'rognonnasse',
    'rognonnâtes',
    'rognonnerai',
    'rognonneras',
    'rognonnerez',
    'rognonnions',
    'roidiraient',
    'roidissions',
    'romançaient',
    'romançasses',
    'romancerais',
    'romancerait',
    'romancèrent',
    'romanceriez',
    'romancerons',
    'romanceront',
    'romancières',
    'romanesques',
    'romanichels',
    'romanisâmes',
    'romanisasse',
    'romanisâtes',
    'romaniserai',
    'romaniseras',
    'romaniserez',
    'romanisions',
    'roman-photo',
    'romantiques',
    'romantismes',
    'rompissions',
    'ronchonnais',
    'ronchonnait',
    'ronchonnant',
    'ronchonneau',
    'ronchonnent',
    'ronchonnera',
    'ronchonneur',
    'ronchonniez',
    'ronchonnons',
    'ronde-bosse',
    'rondelettes',
    'rondiraient',
    'rondissions',
    'ronéotypais',
    'ronéotypait',
    'ronéotypant',
    'ronéotypées',
    'ronéotypent',
    'ronéotypera',
    'ronéotypiez',
    'ronéotypons',
    'ronflaguais',
    'ronflaguait',
    'ronflaguant',
    'ronflaguent',
    'ronflaguera',
    'ronflaguiez',
    'ronflaguons',
    'ronflassent',
    'ronflassiez',
    'ronflements',
    'ronflerions',
    'rongeassent',
    'rongeassiez',
    'rongeraient',
    'ronronnâmes',
    'ronronnasse',
    'ronronnâtes',
    'ronronnerai',
    'ronronneras',
    'ronronnerez',
    'ronronnions',
    'ronsardisai',
    'ronsardisas',
    'ronsardisât',
    'ronsardiser',
    'ronsardises',
    'ronsardisez',
    'roquassions',
    'roquelaures',
    'roqueraient',
    'rosanilines',
    'rosicrucien',
    'rosiéristes',
    'rosissaient',
    'rosissement',
    'rossassions',
    'rosseraient',
    'rossinantes',
    'rôtissaient',
    'rôtisseries',
    'rôtisseuses',
    'rôtissoires',
    'rotogravure',
    'rotrouenges',
    'rotuliennes',
    'rouannettes',
    'roublardise',
    'roucoulades',
    'roucoulâmes',
    'roucoulante',
    'roucoulants',
    'roucoulasse',
    'roucoulâtes',
    'roucoulerai',
    'roucouleras',
    'roucoulerez',
    'roucoulions',
    'rouenneries',
    'rouge-gorge',
    'rougeoierai',
    'rougeoieras',
    'rougeoierez',
    'rougeoyâmes',
    'rougeoyante',
    'rougeoyants',
    'rougeoyasse',
    'rougeoyâtes',
    'rougeoyions',
    'rouge-queue',
    'rougiraient',
    'rougissante',
    'rougissants',
    'rougissions',
    'rouillaient',
    'rouillasses',
    'rouillerais',
    'rouillerait',
    'rouillèrent',
    'rouilleriez',
    'rouillerons',
    'rouilleront',
    'rouissaient',
    'roulassions',
    'rouleautées',
    'roulé-boulé',
    'rouleraient',
    'roulottâmes',
    'roulottasse',
    'roulottâtes',
    'roulotterai',
    'roulotteras',
    'roulotterez',
    'roulottions',
    'roupillâmes',
    'roupillasse',
    'roupillâtes',
    'roupillerai',
    'roupilleras',
    'roupillerez',
    'roupillions',
    'rouscaillai',
    'rouscaillas',
    'rouscaillât',
    'rouscailler',
    'rouscailles',
    'rouscaillez',
    'rouspétâmes',
    'rouspétance',
    'rouspétasse',
    'rouspétâtes',
    'rouspéterai',
    'rouspéteras',
    'rouspéterez',
    'rouspéteurs',
    'rouspéteuse',
    'rouspétions',
    'rousserolle',
    'roussirions',
    'roussissais',
    'roussissait',
    'roussissant',
    'roussissent',
    'roussissiez',
    'roussissons',
    'roustirions',
    'roustissais',
    'roustissait',
    'roustissant',
    'roustissent',
    'roustissiez',
    'roustissons',
    'routassions',
    'routeraient',
    'routinières',
    'rouvririons',
    'rouvrissent',
    'rouvrissiez',
    'rubanassent',
    'rubanassiez',
    'rubanerions',
    'rubéfaction',
    'rubéfiaient',
    'rubéfiantes',
    'rubéfiasses',
    'rubéfierais',
    'rubéfierait',
    'rubéfièrent',
    'rubéfieriez',
    'rubéfierons',
    'rubéfieront',
    'rubéoleuses',
    'rubescentes',
    'rubigineuse',
    'ruchassions',
    'rucheraient',
    'rudentaient',
    'rudentasses',
    'rudenterais',
    'rudenterait',
    'rudentèrent',
    'rudenteriez',
    'rudenterons',
    'rudenteront',
    'rudérations',
    'rudoiements',
    'rudoierions',
    'rudoyassent',
    'rudoyassiez',
    'ruginations',
    'rugissaient',
    'rugissantes',
    'rugissement',
    'ruilassions',
    'ruileraient',
    'ruinassions',
    'ruineraient',
    'ruiniformes',
    'ruisselâmes',
    'ruisselante',
    'ruisselants',
    'ruisselasse',
    'ruisselâtes',
    'ruisselions',
    'ruissellent',
    'ruissellera',
    'ruminassent',
    'ruminassiez',
    'ruminations',
    'ruminerions',
    'rupinassent',
    'rupinassiez',
    'rupinerions',
    'russifiâmes',
    'russifiasse',
    'russifiâtes',
    'russifierai',
    'russifieras',
    'russifierez',
    'russifiions',
    'russophiles',
    'rustauderie',
    'rustiquâmes',
    'rustiquasse',
    'rustiquâtes',
    'rustiquerai',
    'rustiqueras',
    'rustiquerez',
    'rustiquions',
    'rutilassent',
    'rutilassiez',
    'rutilerions',
    'rythmassent',
    'rythmassiez',
    'rythmerions',
    'rythmiciens',
    'rythmicités',
    'sabbathiens',
    'sabbatiques',
    'sablassions',
    'sableraient',
    'sablonnâmes',
    'sablonnasse',
    'sablonnâtes',
    'sablonnerai',
    'sablonneras',
    'sablonnerez',
    'sablonneuse',
    'sablonnière',
    'sablonnions',
    'sabordaient',
    'sabordasses',
    'sabordement',
    'saborderais',
    'saborderait',
    'sabordèrent',
    'saborderiez',
    'saborderons',
    'saborderont',
    'sabotassent',
    'sabotassiez',
    'saboterions',
    'saboulaient',
    'saboulasses',
    'saboulerais',
    'saboulerait',
    'saboulèrent',
    'sabouleriez',
    'saboulerons',
    'sabouleront',
    'sabraissent',
    'sabraissiez',
    'sabreraient',
    'sabretaches',
    'sacagnaient',
    'sacagnasses',
    'sacagnerais',
    'sacagnerait',
    'sacagnèrent',
    'sacagneriez',
    'sacagnerons',
    'sacagneront',
    'saccadaient',
    'saccadasses',
    'saccaderais',
    'saccaderait',
    'saccadèrent',
    'saccaderiez',
    'saccaderons',
    'saccaderont',
    'saccageâmes',
    'saccageasse',
    'saccageâtes',
    'saccagement',
    'saccagerais',
    'saccagerait',
    'saccagèrent',
    'saccageriez',
    'saccagerons',
    'saccageront',
    'saccageuses',
    'saccharases',
    'saccharates',
    'sacchareuse',
    'saccharidés',
    'saccharifia',
    'saccharifie',
    'saccharifié',
    'saccharines',
    'saccharique',
    'saccharoïde',
    'saccharolés',
    'saccharoses',
    'saccharures',
    'sacciformes',
    'sacerdotale',
    'sacerdotaux',
    'sacquassent',
    'sacquassiez',
    'sacquebutes',
    'sacquerions',
    'sacralisais',
    'sacralisait',
    'sacralisant',
    'sacralisées',
    'sacralisent',
    'sacralisera',
    'sacralisiez',
    'sacralisons',
    'sacramental',
    'sacramentel',
    'sacrassions',
    'sacré-coeur',
    'sacreraient',
    'sacrifiâmes',
    'sacrifiasse',
    'sacrifiâtes',
    'sacrificiel',
    'sacrifierai',
    'sacrifieras',
    'sacrifierez',
    'sacrifiions',
    'sacristains',
    'sacristines',
    'sacro-saint',
    'sadiquement',
    'saducéennes',
    'safranaient',
    'safranasses',
    'safranerais',
    'safranerait',
    'safranèrent',
    'safraneriez',
    'safranerons',
    'safraneront',
    'safranières',
    'sagittaires',
    'sahariennes',
    'sahéliennes',
    'saignassent',
    'saignassiez',
    'saignements',
    'saignerions',
    'saillirions',
    'saillissent',
    'saillissiez',
    'Saint-Siège',
    'saisiraient',
    'saisissable',
    'saisissante',
    'saisissants',
    'saisissions',
    'saisonnâmes',
    'saisonnasse',
    'saisonnâtes',
    'saisonnerai',
    'saisonneras',
    'saisonnerez',
    'saisonnière',
    'saisonniers',
    'saisonnions',
    'salamandres',
    'salariaient',
    'salariasses',
    'salarierais',
    'salarierait',
    'salarièrent',
    'salarieriez',
    'salarierons',
    'salarieront',
    'salésiennes',
    'salicionals',
    'salicosides',
    'salicylates',
    'salicylique',
    'salifiables',
    'salifiaient',
    'salifiasses',
    'salifierais',
    'salifierait',
    'salifièrent',
    'salifieriez',
    'salifierons',
    'salifieront',
    'salissaient',
    'salissantes',
    'salivassent',
    'salivassiez',
    'salivations',
    'saliverions',
    'salmonelles',
    'salonnardes',
    'salonnières',
    'salopassent',
    'salopassiez',
    'saloperions',
    'salpêtrages',
    'salpêtrâmes',
    'salpêtrasse',
    'salpêtrâtes',
    'salpêtrerai',
    'salpêtreras',
    'salpêtrerez',
    'salpêtreuse',
    'salpêtrière',
    'salpêtrions',
    'salpingites',
    'saltarelles',
    'saluassions',
    'salueraient',
    'salutations',
    'salvatrices',
    'samaritaine',
    'samaritains',
    'sanatoriums',
    'san-benitos',
    'sanctifiais',
    'sanctifiait',
    'sanctifiant',
    'sanctifiées',
    'sanctifient',
    'sanctifiera',
    'sanctifiiez',
    'sanctifions',
    'sanctionnai',
    'sanctionnas',
    'sanctionnât',
    'sanctionnée',
    'sanctionner',
    'sanctionnes',
    'sanctionnés',
    'sanctionnez',
    'sanctuaires',
    'sanctuarisa',
    'sanctuarise',
    'sanctuarisé',
    'sandalettes',
    'sandaraques',
    'sanderlings',
    'sandwichais',
    'sandwichait',
    'sandwichant',
    'sandwichées',
    'sandwichent',
    'sandwichera',
    'sandwichiez',
    'sandwichons',
    'sanglassent',
    'sanglassiez',
    'sanglerions',
    'sanglotâmes',
    'sanglotante',
    'sanglotants',
    'sanglotasse',
    'sanglotâtes',
    'sangloterai',
    'sangloteras',
    'sangloterez',
    'sanglotions',
    'sanguinaire',
    'sans-emploi',
    'sansevières',
    'sans-le-sou',
    'santonnâmes',
    'santonnasse',
    'santonnâtes',
    'santonnerai',
    'santonneras',
    'santonnerez',
    'santonnions',
    'saoudiennes',
    'saoulassent',
    'saoulassiez',
    'saoulerions',
    'sapientiale',
    'sapientiaux',
    'saponifiais',
    'saponifiait',
    'saponifiant',
    'saponifiées',
    'saponifient',
    'saponifiera',
    'saponifiiez',
    'saponifions',
    'saprophages',
    'saprophytes',
    'saquassions',
    'saqueraient',
    'sarcastique',
    'sarclassent',
    'sarclassiez',
    'sarclerions',
    'sarcomateux',
    'sarcophages',
    'sarcoplasma',
    'sardineries',
    'sardinières',
    'sardoniques',
    'sarmentâmes',
    'sarmentasse',
    'sarmentâtes',
    'sarmenterai',
    'sarmenteras',
    'sarmenterez',
    'sarmenteuse',
    'sarmentions',
    'sarrancolin',
    'sassassions',
    'sasseraient',
    'satanassent',
    'satanassiez',
    'satanerions',
    'satellisais',
    'satellisait',
    'satellisant',
    'satellisées',
    'satellisent',
    'satellisera',
    'satellisiez',
    'satellisons',
    'satinassent',
    'satinassiez',
    'satinerions',
    'satirisâmes',
    'satirisasse',
    'satirisâtes',
    'satiriserai',
    'satiriseras',
    'satiriserez',
    'satirisions',
    'satisfaites',
    'satisfasses',
    'satisferais',
    'satisferait',
    'satisferiez',
    'satisferons',
    'satisferont',
    'satisfirent',
    'satisfisses',
    'satonnaient',
    'satonnasses',
    'satonnerais',
    'satonnerait',
    'satonnèrent',
    'satonneriez',
    'satonnerons',
    'satonneront',
    'saturassent',
    'saturassiez',
    'saturateurs',
    'saturations',
    'saturerions',
    'saturnienne',
    'saturnismes',
    'sauçassions',
    'sauceraient',
    'sauciflards',
    'saucissonna',
    'saucissonne',
    'saucissonné',
    'saumuraient',
    'saumurasses',
    'saumurerais',
    'saumurerait',
    'saumurèrent',
    'saumureriez',
    'saumurerons',
    'saumureront',
    'saunassions',
    'sauneraient',
    'saupoudrage',
    'saupoudrais',
    'saupoudrait',
    'saupoudrant',
    'saupoudrées',
    'saupoudrent',
    'saupoudrera',
    'saupoudreur',
    'saupoudriez',
    'saupoudroir',
    'saupoudrons',
    'saurassions',
    'saureraient',
    'sauriraient',
    'saurissages',
    'saurisserie',
    'saurisseurs',
    'saurissions',
    'sautassions',
    'saut-de-lit',
    'sauteraient',
    'sauterelles',
    'sautillâmes',
    'sautillante',
    'sautillants',
    'sautillasse',
    'sautillâtes',
    'sautillerai',
    'sautilleras',
    'sautillerez',
    'sautillions',
    'sauvagement',
    'sauvageonne',
    'sauvageries',
    'sauvassions',
    'sauvegardai',
    'sauvegardas',
    'sauvegardât',
    'sauvegardée',
    'sauvegarder',
    'sauvegardes',
    'sauvegardés',
    'sauvegardez',
    'sauveraient',
    'savonnaient',
    'savonnasses',
    'savonnerais',
    'savonnerait',
    'savonnèrent',
    'savonneries',
    'savonneriez',
    'savonnerons',
    'savonneront',
    'savonnettes',
    'savonneuses',
    'savonnières',
    'savouraient',
    'savourasses',
    'savourerais',
    'savourerait',
    'savourèrent',
    'savoureriez',
    'savourerons',
    'savoureront',
    'savoureuses',
    'scaferlatis',
    'scalpassent',
    'scalpassiez',
    'scalperions',
    'scandaleuse',
    'scandalisai',
    'scandalisas',
    'scandalisât',
    'scandalisée',
    'scandaliser',
    'scandalises',
    'scandalisés',
    'scandalisez',
    'scandassent',
    'scandassiez',
    'scanderions',
    'scandinaves',
    'scaphandres',
    'scapulaires',
    'scarifiages',
    'scarlatines',
    'scatologies',
    'scatophiles',
    'scellassent',
    'scellassiez',
    'scellements',
    'scellerions',
    'scénaristes',
    'scepticisme',
    'scheidaient',
    'scheidasses',
    'scheiderais',
    'scheiderait',
    'scheidèrent',
    'scheideriez',
    'scheiderons',
    'scheideront',
    'schématique',
    'schématisai',
    'schématisas',
    'schématisât',
    'schématisée',
    'schématiser',
    'schématises',
    'schématisés',
    'schématisez',
    'schématisme',
    'schisteuses',
    'schistoïdes',
    'schizogamie',
    'schizoïdies',
    'schlittages',
    'schlittâmes',
    'schlittasse',
    'schlittâtes',
    'schlitterai',
    'schlitteras',
    'schlitterez',
    'schlitteurs',
    'schlittions',
    'schnorchels',
    'scialytique',
    'scientismes',
    'scientistes',
    'scindassent',
    'scindassiez',
    'scinderions',
    'scintillais',
    'scintillait',
    'scintillant',
    'scintillent',
    'scintillera',
    'scintilliez',
    'scintillons',
    'sciottaient',
    'sciottasses',
    'sciotterais',
    'sciotterait',
    'sciottèrent',
    'sciotteriez',
    'sciotterons',
    'sciotteront',
    'scissionnai',
    'scissionnas',
    'scissionnât',
    'scissionner',
    'scissionnes',
    'scissionnez',
    'scissipares',
    'scléranthes',
    'sclérosâmes',
    'sclérosante',
    'sclérosants',
    'sclérosasse',
    'sclérosâtes',
    'scléroserai',
    'scléroseras',
    'scléroserez',
    'sclérosions',
    'sclérotique',
    'scolarisais',
    'scolarisait',
    'scolarisant',
    'scolarisées',
    'scolarisent',
    'scolarisera',
    'scolarisiez',
    'scolarisons',
    'scolasticat',
    'scolastique',
    'scolopendre',
    'scootériste',
    'scopolamine',
    'scorbutique',
    'scorsonères',
    'scotchaient',
    'scotchasses',
    'scotcherais',
    'scotcherait',
    'scotchèrent',
    'scotcheriez',
    'scotcherons',
    'scotcheront',
    'scotomisais',
    'scotomisait',
    'scotomisant',
    'scotomisées',
    'scotomisent',
    'scotomisera',
    'scotomisiez',
    'scotomisons',
    'scramasaxes',
    'scratchâmes',
    'scratchasse',
    'scratchâtes',
    'scratcherai',
    'scratcheras',
    'scratcherez',
    'scratchions',
    'scribouilla',
    'scribouille',
    'scribouillé',
    'script-girl',
    'scripturale',
    'scripturaux',
    'scrofulaire',
    'scrofuleurs',
    'scrofuleuse',
    'scrupuleuse',
    'scrutassent',
    'scrutassiez',
    'scrutateurs',
    'scrutations',
    'scrutatrice',
    'scruterions',
    'sculptaient',
    'sculptasses',
    'sculpterais',
    'sculpterait',
    'sculptèrent',
    'sculpteriez',
    'sculpterons',
    'sculpteront',
    'sculpturale',
    'sculpturaux',
    'scutellaire',
    'séchassions',
    'sèche-linge',
    'sèche-mains',
    'sécheraient',
    'sécheresses',
    'secondaient',
    'secondaires',
    'secondarité',
    'secondasses',
    'secondement',
    'seconderais',
    'seconderait',
    'secondèrent',
    'seconderiez',
    'seconderons',
    'seconderont',
    'secouassent',
    'secouassiez',
    'secouements',
    'secouerions',
    'secourables',
    'secouraient',
    'secourismes',
    'secouristes',
    'secourrions',
    'secoururent',
    'secourusses',
    'sécrétaient',
    'secrétaires',
    'secrétariat',
    'sécrétasses',
    'secrètement',
    'sécréterais',
    'sécréterait',
    'sécrétèrent',
    'sécréteriez',
    'sécréterons',
    'sécréteront',
    'sécréteuses',
    'sécrétoires',
    'sectarismes',
    'sectatrices',
    'sectionnais',
    'sectionnait',
    'sectionnant',
    'sectionnées',
    'sectionnent',
    'sectionnera',
    'sectionneur',
    'sectionniez',
    'sectionnons',
    'sectorielle',
    'sectorisais',
    'sectorisait',
    'sectorisant',
    'sectorisées',
    'sectorisent',
    'sectorisera',
    'sectorisiez',
    'sectorisons',
    'sécularisai',
    'sécularisas',
    'sécularisât',
    'sécularisée',
    'séculariser',
    'sécularises',
    'sécularisés',
    'sécularisez',
    'sécurisâmes',
    'sécurisasse',
    'sécurisâtes',
    'sécuriserai',
    'sécuriseras',
    'sécuriserez',
    'sécurisions',
    'sécuritaire',
    'sédentaires',
    'sédentarisa',
    'sédentarise',
    'sédentarisé',
    'sédentarité',
    'sédimentais',
    'sédimentait',
    'sédimentant',
    'sédimentées',
    'sédimentent',
    'sédimentera',
    'sédimentiez',
    'sédimentons',
    'séditieuses',
    'séductrices',
    'séduiraient',
    'séduisaient',
    'séduisantes',
    'séduisirent',
    'séduisisses',
    'segmentales',
    'segmentâmes',
    'segmentasse',
    'segmentâtes',
    'segmenterai',
    'segmenteras',
    'segmenterez',
    'segmentions',
    'ségrégatifs',
    'ségrégation',
    'ségrégative',
    'séguedilles',
    'seigneurial',
    'seigneuries',
    'séismicités',
    'seiziémisme',
    'séjournâmes',
    'séjournasse',
    'séjournâtes',
    'séjournerai',
    'séjourneras',
    'séjournerez',
    'séjournions',
    'sélaginelle',
    'sélectionna',
    'sélectionne',
    'sélectionné',
    'sélectivité',
    'sélectrices',
    'séléniteuse',
    'sellassions',
    'selleraient',
    'semainières',
    'sémanticien',
    'sémantiques',
    'semblassent',
    'semblassiez',
    'semblerions',
    'séméiologie',
    'séméiotique',
    'semestriels',
    'semi-arides',
    'sémillantes',
    'séminariste',
    'semi-nomade',
    'sémiologies',
    'sémioticien',
    'sémiotiques',
    'semi-ouvrée',
    'semi-ouvrés',
    'semi-public',
    'semi-rigide',
    'semonçaient',
    'semonçasses',
    'semoncerais',
    'semoncerait',
    'semoncèrent',
    'semonceriez',
    'semoncerons',
    'semonceront',
    'semouleries',
    'sempervivum',
    'sempiternel',
    'sénatoriale',
    'sénatoriaux',
    'sénégalaise',
    'sénescences',
    'sénescentes',
    'sensibilisa',
    'sensibilise',
    'sensibilisé',
    'sensibilité',
    'sensiblerie',
    'sensorielle',
    'sensualisme',
    'sensualiste',
    'sensualités',
    'sentencieux',
    'sentimental',
    'sentinelles',
    'sentiraient',
    'sentissions',
    'séparassent',
    'séparassiez',
    'séparateurs',
    'séparations',
    'séparatisme',
    'séparatiste',
    'séparatrice',
    'séparerions',
    'septénaires',
    'septennales',
    'septentrion',
    'septicémies',
    'septmoncels',
    'septuplâmes',
    'septuplasse',
    'septuplâtes',
    'septuplerai',
    'septupleras',
    'septuplerez',
    'septuplions',
    'sépulcrales',
    'séquenceurs',
    'séquentiels',
    'séquestrais',
    'séquestrait',
    'séquestrant',
    'séquestrées',
    'séquestrent',
    'séquestrera',
    'séquestriez',
    'séquestrons',
    'seraient-ce',
    'serait-elle',
    'sérançaient',
    'sérançasses',
    'sérancerais',
    'sérancerait',
    'sérancèrent',
    'séranceriez',
    'sérancerons',
    'séranceront',
    'séraphiques',
    'sera-t-elle',
    'sereinement',
    'sérénissime',
    'serfouettes',
    'serfouirais',
    'serfouirait',
    'serfouirent',
    'serfouiriez',
    'serfouirons',
    'serfouiront',
    'serfouisses',
    'serfouissez',
    'sérialisais',
    'sérialisait',
    'sérialisant',
    'sérialisées',
    'sérialisent',
    'sérialisera',
    'sérialisiez',
    'sérialisons',
    'sériassions',
    'séricicoles',
    'séricigènes',
    'sérieraient',
    'seriez-vous',
    'sérigraphie',
    'serinassent',
    'serinassiez',
    'serinerions',
    'seringuâmes',
    'seringuasse',
    'seringuâtes',
    'seringuerai',
    'seringueras',
    'seringuerez',
    'seringuions',
    'sermonnaire',
    'sermonnâmes',
    'sermonnasse',
    'sermonnâtes',
    'sermonnerai',
    'sermonneras',
    'sermonnerez',
    'sermonneurs',
    'sermonneuse',
    'sermonnions',
    'serons-nous',
    'sérotonines',
    'serpentaire',
    'serpentâmes',
    'serpentasse',
    'serpentâtes',
    'serpenteaux',
    'serpenterai',
    'serpenteras',
    'serpenterez',
    'serpentines',
    'serpentions',
    'serpigineux',
    'serpillière',
    'serrassions',
    'serre-files',
    'serre-frein',
    'serre-joint',
    'serreraient',
    'serrureries',
    'sertiraient',
    'sertissages',
    'sertisseurs',
    'sertisseuse',
    'sertissions',
    'sertissures',
    'servilement',
    'serviraient',
    'servissions',
    'servofreins',
    'servomoteur',
    'sesquioxyde',
    'sévissaient',
    'sevrassions',
    'sevreraient',
    'sexagénaire',
    'sexagésimal',
    'sexagésimes',
    'sex-appeals',
    'sextillions',
    'sextuplâmes',
    'sextuplasse',
    'sextuplâtes',
    'sextuplerai',
    'sextupleras',
    'sextuplerez',
    'sextuplions',
    'sexualisais',
    'sexualisait',
    'sexualisant',
    'sexualisées',
    'sexualisent',
    'sexualisera',
    'sexualisiez',
    'sexualismes',
    'sexualisons',
    'shampooings',
    'shampouinai',
    'shampouinas',
    'shampouinât',
    'shampouinée',
    'shampouiner',
    'shampouines',
    'shampouinés',
    'shampouinez',
    'shintoïsmes',
    'shootassent',
    'shootassiez',
    'shooterions',
    'shuntassent',
    'shuntassiez',
    'shunterions',
    'sialagogues',
    'sialorrhées',
    'sibériennes',
    'siciliennes',
    'sidérassent',
    'sidérassiez',
    'sidérerions',
    'sidérostats',
    'sidéroxylon',
    'sidérurgies',
    'siégeassent',
    'siégeassiez',
    'siégeraient',
    'sifflassent',
    'sifflassiez',
    'sifflements',
    'sifflerions',
    'sifflotâmes',
    'sifflotasse',
    'sifflotâtes',
    'siffloterai',
    'siffloteras',
    'siffloterez',
    'sifflotions',
    'sigillaires',
    'signalaient',
    'signalasses',
    'signalement',
    'signalerais',
    'signalerait',
    'signalèrent',
    'signaleriez',
    'signalerons',
    'signaleront',
    'signalisais',
    'signalisait',
    'signalisant',
    'signalisées',
    'signalisent',
    'signalisera',
    'signalisiez',
    'signalisons',
    'signassions',
    'signataires',
    'signeraient',
    'signifiâmes',
    'signifiance',
    'signifiante',
    'signifiants',
    'signifiasse',
    'signifiâtes',
    'signifierai',
    'signifieras',
    'signifierez',
    'signifiions',
    'silencieuse',
    'silésiennes',
    'silhouettai',
    'silhouettas',
    'silhouettât',
    'silhouettée',
    'silhouetter',
    'silhouettes',
    'silhouettés',
    'silhouettez',
    'silicatisai',
    'silicatisas',
    'silicatisât',
    'silicatisée',
    'silicatiser',
    'silicatises',
    'silicatisés',
    'silicatisez',
    'silicicoles',
    'siliconâmes',
    'siliconasse',
    'siliconâtes',
    'siliconerai',
    'siliconeras',
    'siliconerez',
    'siliconions',
    'sillonnâmes',
    'sillonnasse',
    'sillonnâtes',
    'sillonnerai',
    'sillonneras',
    'sillonnerez',
    'sillonnions',
    'siluriennes',
    'similarités',
    'similicuirs',
    'similisages',
    'similisâmes',
    'similisasse',
    'similisâtes',
    'similiserai',
    'similiseras',
    'similiserez',
    'similisions',
    'similitudes',
    'simoniaques',
    'simplicités',
    'simplifiais',
    'simplifiait',
    'simplifiant',
    'simplifiées',
    'simplifient',
    'simplifiera',
    'simplifiiez',
    'simplifions',
    'simulassent',
    'simulassiez',
    'simulateurs',
    'simulations',
    'simulatrice',
    'simulerions',
    'simultanées',
    'sinanthrope',
    'sincèrement',
    'sincipitale',
    'sincipitaux',
    'singalettes',
    'singeassent',
    'singeassiez',
    'singeraient',
    'singularisa',
    'singularise',
    'singularisé',
    'singularité',
    'singulières',
    'sinisassent',
    'sinisassiez',
    'sinisations',
    'siniserions',
    'sintérisais',
    'sintérisait',
    'sintérisant',
    'sintérisées',
    'sintérisent',
    'sintérisera',
    'sintérisiez',
    'sintérisons',
    'sinusoïdale',
    'sinusoïdaux',
    'siphonnâmes',
    'siphonnasse',
    'siphonnâtes',
    'siphonnerai',
    'siphonneras',
    'siphonnerez',
    'siphonnions',
    'sirotassent',
    'sirotassiez',
    'siroterions',
    'sismographe',
    'sismologies',
    'sister-ship',
    'situassions',
    'situeraient',
    'sixièmement',
    'skateboards',
    'skiascopies',
    'slalomaient',
    'slalomasses',
    'slalomerais',
    'slalomerait',
    'slalomèrent',
    'slalomeriez',
    'slalomerons',
    'slalomeront',
    'slalomeuses',
    'slavisaient',
    'slavisasses',
    'slaviserais',
    'slaviserait',
    'slavisèrent',
    'slaviseriez',
    'slaviserons',
    'slaviseront',
    'slavophiles',
    'smaragdines',
    'smaragdites',
    'smashassent',
    'smashassiez',
    'smasherions',
    'smillassent',
    'smillassiez',
    'smillerions',
    'smithsonite',
    'sniffassent',
    'sniffassiez',
    'snifferions',
    'snobassions',
    'snoberaient',
    'snobinardes',
    'sociabilité',
    'socialement',
    'socialisais',
    'socialisait',
    'socialisant',
    'socialisées',
    'socialisent',
    'socialisera',
    'socialisiez',
    'socialismes',
    'socialisons',
    'socialistes',
    'sociétaires',
    'sociétariat',
    'sociodrames',
    'sociogramme',
    'sociologies',
    'sociologues',
    'socratiques',
    'socratisais',
    'socratisait',
    'socratisant',
    'socratisent',
    'socratisera',
    'socratisiez',
    'socratisons',
    'sodomisâmes',
    'sodomisasse',
    'sodomisâtes',
    'sodomiserai',
    'sodomiseras',
    'sodomiserez',
    'sodomisions',
    'soignassent',
    'soignassiez',
    'soignerions',
    'soixantaine',
    'soixantième',
    'solarisâmes',
    'solarisasse',
    'solarisâtes',
    'solariserai',
    'solariseras',
    'solariserez',
    'solarisions',
    'soldanelles',
    'soldassions',
    'soldatesque',
    'solderaient',
    'solennelles',
    'solennisais',
    'solennisait',
    'solennisant',
    'solennisées',
    'solennisent',
    'solennisera',
    'solennisiez',
    'solennisons',
    'solénoïdale',
    'solénoïdaux',
    'solfiassent',
    'solfiassiez',
    'solfierions',
    'solidarisai',
    'solidarisas',
    'solidarisât',
    'solidarisée',
    'solidariser',
    'solidarises',
    'solidarisés',
    'solidarisez',
    'solidarités',
    'solidifiais',
    'solidifiait',
    'solidifiant',
    'solidifiées',
    'solidifient',
    'solidifiera',
    'solidifiiez',
    'solidifions',
    'solifluâmes',
    'solifluasse',
    'solifluâtes',
    'solifluerai',
    'soliflueras',
    'solifluerez',
    'solifluions',
    'solifluxion',
    'soliloquais',
    'soliloquait',
    'soliloquant',
    'soliloquent',
    'soliloquera',
    'soliloquiez',
    'soliloquons',
    'solipsismes',
    'sollicitais',
    'sollicitait',
    'sollicitant',
    'sollicitées',
    'sollicitent',
    'sollicitera',
    'solliciteur',
    'sollicitiez',
    'sollicitons',
    'sollicitude',
    'solmisaient',
    'solmisasses',
    'solmisation',
    'solmiserais',
    'solmiserait',
    'solmisèrent',
    'solmiseriez',
    'solmiserons',
    'solmiseront',
    'solsticiale',
    'solsticiaux',
    'solubilisai',
    'solubilisas',
    'solubilisât',
    'solubilisée',
    'solubiliser',
    'solubilises',
    'solubilisés',
    'solubilisez',
    'solubilités',
    'solutionnai',
    'solutionnas',
    'solutionnât',
    'solutionnée',
    'solutionner',
    'solutionnes',
    'solutionnés',
    'solutionnez',
    'solutréenne',
    'solvabilité',
    'somatisâmes',
    'somatisasse',
    'somatisâtes',
    'somatiserai',
    'somatiseras',
    'somatiserez',
    'somatisions',
    'somatotrope',
    'sombrassent',
    'sombrassiez',
    'sombrerions',
    'sommassions',
    'sommeillais',
    'sommeillait',
    'sommeillant',
    'sommeillent',
    'sommeillera',
    'sommeilleux',
    'sommeilliez',
    'sommeillons',
    'sommelières',
    'sommellerie',
    'sommeraient',
    'sommes-nous',
    'somnambules',
    'somnolaient',
    'somnolantes',
    'somnolasses',
    'somnolences',
    'somnolentes',
    'somnolerais',
    'somnolerait',
    'somnolèrent',
    'somnoleriez',
    'somnolerons',
    'somnoleront',
    'somptuaires',
    'somptueuses',
    'somptuosité',
    'sonagraphes',
    'sondassions',
    'sonderaient',
    'songeassent',
    'songeassiez',
    'songe-creux',
    'songeraient',
    'sonnaillais',
    'sonnaillait',
    'sonnaillant',
    'sonnaillées',
    'sonnaillent',
    'sonnaillera',
    'sonnailliez',
    'sonnaillons',
    'sonnassions',
    'sonneraient',
    'sonorisâmes',
    'sonorisasse',
    'sonorisâtes',
    'sonoriserai',
    'sonoriseras',
    'sonoriserez',
    'sonorisions',
    'sonothèques',
    'sophistiqua',
    'sophistique',
    'sophistiqué',
    'sophrologie',
    'soporifique',
    'sopranistes',
    'sorbetières',
    'sorbonnarde',
    'sorbonnards',
    'sorcellerie',
    'sordidement',
    'sorguassent',
    'sorguassiez',
    'sorguerions',
    'sortiraient',
    'sortissions',
    'soubresauta',
    'soubresaute',
    'soubresauté',
    'soubresauts',
    'soubreveste',
    'souchetâmes',
    'souchetasse',
    'souchetâtes',
    'souchetions',
    'souchettent',
    'souchettera',
    'souchevâmes',
    'souchevasse',
    'souchevâtes',
    'souchèverai',
    'souchèveras',
    'souchèverez',
    'souchevions',
    'souciassent',
    'souciassiez',
    'soucierions',
    'soudainetés',
    'soudanaises',
    'soudassions',
    'souderaient',
    'soudoierais',
    'soudoierait',
    'soudoieriez',
    'soudoierons',
    'soudoieront',
    'soudoyaient',
    'soudoyasses',
    'soudoyèrent',
    'soufflaient',
    'soufflantes',
    'soufflasses',
    'soufflement',
    'soufflerais',
    'soufflerait',
    'soufflèrent',
    'souffleries',
    'souffleriez',
    'soufflerons',
    'souffleront',
    'souffletais',
    'souffletait',
    'souffletant',
    'souffletées',
    'souffletier',
    'souffletiez',
    'souffletons',
    'soufflettes',
    'souffleuses',
    'souffraient',
    'souffrances',
    'souffrantes',
    'souffreteux',
    'souffrirais',
    'souffrirait',
    'souffrirent',
    'souffririez',
    'souffrirons',
    'souffriront',
    'souffrisses',
    'soufrassent',
    'soufrassiez',
    'soufrerions',
    'souhaitable',
    'souhaitâmes',
    'souhaitasse',
    'souhaitâtes',
    'souhaiterai',
    'souhaiteras',
    'souhaiterez',
    'souhaitions',
    'souillaient',
    'souillardes',
    'souillasses',
    'souillerais',
    'souillerait',
    'souillèrent',
    'souilleriez',
    'souillerons',
    'souilleront',
    'souï-mangas',
    'soulageâmes',
    'soulageasse',
    'soulageâtes',
    'soulagement',
    'soulagerais',
    'soulagerait',
    'soulagèrent',
    'soulageriez',
    'soulagerons',
    'soulageront',
    'soûlassions',
    'soûleraient',
    'soulevaient',
    'soulevasses',
    'soulèvement',
    'soulèverais',
    'soulèverait',
    'soulevèrent',
    'soulèveriez',
    'soulèverons',
    'soulèveront',
    'soulignages',
    'soulignâmes',
    'soulignasse',
    'soulignâtes',
    'soulignerai',
    'souligneras',
    'soulignerez',
    'soulignions',
    'soûlographe',
    'soumettions',
    'soumettrais',
    'soumettrait',
    'soumettriez',
    'soumettrons',
    'soumettront',
    'soumissions',
    'soupassions',
    'soupçonnais',
    'soupçonnait',
    'soupçonnant',
    'soupçonnées',
    'soupçonnent',
    'soupçonnera',
    'soupçonneux',
    'soupçonniez',
    'soupçonnons',
    'souperaient',
    'soupesaient',
    'soupesasses',
    'soupèserais',
    'soupèserait',
    'soupesèrent',
    'soupèseriez',
    'soupèserons',
    'soupèseront',
    'soupiraient',
    'soupirantes',
    'soupirasses',
    'soupirerais',
    'soupirerait',
    'soupirèrent',
    'soupireriez',
    'soupirerons',
    'soupireront',
    'souquassent',
    'souquassiez',
    'souquenille',
    'souquerions',
    'sourcilière',
    'sourciliers',
    'sourcillais',
    'sourcillait',
    'sourcillant',
    'sourcillent',
    'sourcillera',
    'sourcilleux',
    'sourcilliez',
    'sourcillons',
    'sourdinâmes',
    'sourdinasse',
    'sourdinâtes',
    'sourdinerai',
    'sourdineras',
    'sourdinerez',
    'sourdingues',
    'sourdinions',
    'souricières',
    'souriraient',
    'sourissions',
    'sous-barbes',
    'sous-classe',
    'sous-compte',
    'sous-couche',
    'souscrirais',
    'souscrirait',
    'souscririez',
    'souscrirons',
    'souscriront',
    'souscrivais',
    'souscrivait',
    'souscrivant',
    'souscrivent',
    'souscriviez',
    'souscrivons',
    'sous-cutané',
    'sous-diacre',
    'sous-emploi',
    'sous-entend',
    'sous-estima',
    'sous-estime',
    'sous-estimé',
    'sous-évalua',
    'sous-évalue',
    'sous-évalué',
    'sous-exposa',
    'sous-expose',
    'sous-exposé',
    'sous-fifres',
    'sous-gardes',
    'sous-genres',
    'sous-hommes',
    'sous-jacent',
    'sous-louais',
    'sous-louait',
    'sous-louant',
    'sous-louées',
    'sous-louent',
    'sous-louera',
    'sous-louiez',
    'sous-louons',
    'sous-maître',
    'sous-marins',
    'sous-nappes',
    'sous-oeuvre',
    'sous-ordres',
    'sous-paient',
    'sous-paiera',
    'sous-payais',
    'sous-payait',
    'sous-payant',
    'sous-payées',
    'sous-payent',
    'sous-payera',
    'sous-payiez',
    'sous-payons',
    'sous-peuplé',
    'sous-préfet',
    'sous-pubien',
    'sous-réseau',
    'soussignées',
    'sous-tasses',
    'sous-tendes',
    'sous-tendez',
    'sous-tendis',
    'sous-tendit',
    'sous-tendît',
    'sous-tendra',
    'sous-tendre',
    'sous-tendue',
    'sous-tendus',
    'sous-titrai',
    'sous-titras',
    'sous-titrât',
    'sous-titrée',
    'sous-titrer',
    'sous-titres',
    'sous-titrés',
    'sous-titrez',
    'soustractif',
    'soustraient',
    'soustrairai',
    'soustrairas',
    'soustrairez',
    'sous-traita',
    'sous-traite',
    'sous-traité',
    'soustraites',
    'soustrayais',
    'soustrayait',
    'soustrayant',
    'soustrayiez',
    'soustrayons',
    'sous-virais',
    'sous-virait',
    'sous-virant',
    'sous-virent',
    'sous-virera',
    'sous-viriez',
    'sous-virons',
    'soutachâmes',
    'soutachasse',
    'soutachâtes',
    'soutacherai',
    'soutacheras',
    'soutacherez',
    'soutachions',
    'soutanelles',
    'soutenables',
    'soutenaient',
    'soutenances',
    'soutènement',
    'souterraine',
    'souterrains',
    'soutiendrai',
    'soutiendras',
    'soutiendrez',
    'soutiennent',
    'soutinssent',
    'soutinssiez',
    'soutiraient',
    'soutirasses',
    'soutirerais',
    'soutirerait',
    'soutirèrent',
    'soutireriez',
    'soutirerons',
    'soutireront',
    'souvenaient',
    'souvenances',
    'souveraines',
    'souviendrai',
    'souviendras',
    'souviendrez',
    'souviennent',
    'souvinssent',
    'souvinssiez',
    'soviétiques',
    'soviétisais',
    'soviétisait',
    'soviétisant',
    'soviétisées',
    'soviétisent',
    'soviétisera',
    'soviétisiez',
    'soviétisons',
    'spallations',
    'sparganiers',
    'spartakisme',
    'spartakiste',
    'spasmodique',
    'spathifiais',
    'spathifiait',
    'spathifiant',
    'spathifiées',
    'spathifient',
    'spathifiera',
    'spathifiiez',
    'spathifions',
    'spatialisai',
    'spatialisas',
    'spatialisât',
    'spatialisée',
    'spatialiser',
    'spatialises',
    'spatialisés',
    'spatialisez',
    'spatialités',
    'spationaute',
    'spécialisai',
    'spécialisas',
    'spécialisât',
    'spécialisée',
    'spécialiser',
    'spécialises',
    'spécialisés',
    'spécialisez',
    'spécialiste',
    'spécialités',
    'spécifiâmes',
    'spécifiasse',
    'spécifiâtes',
    'spécificité',
    'spécifierai',
    'spécifieras',
    'spécifierez',
    'spécifiions',
    'spécifiques',
    'spéciosités',
    'spectateurs',
    'spectatrice',
    'spéculaient',
    'spéculaires',
    'spéculasses',
    'spéculateur',
    'spéculatifs',
    'spéculation',
    'spéculative',
    'spéculerais',
    'spéculerait',
    'spéculèrent',
    'spéculeriez',
    'spéculerons',
    'spéculeront',
    'spéléologie',
    'spéléologue',
    'spéléonaute',
    'spermacetis',
    'spermatides',
    'spermatique',
    'spermicides',
    'spermogonie',
    'spermophile',
    'sphacélâmes',
    'sphacélasse',
    'sphacélâtes',
    'sphacélerai',
    'sphacéleras',
    'sphacélerez',
    'sphacélions',
    'sphénoïdale',
    'sphénoïdaux',
    'sphéricités',
    'sphéroïdale',
    'sphéroïdaux',
    'sphéromètre',
    'spinozismes',
    'spinozistes',
    'spirilloses',
    'spiritismes',
    'spiritistes',
    'spirituelle',
    'spiritueuse',
    'spirochètes',
    'spirographe',
    'spiroïdales',
    'spiromètres',
    'spittassent',
    'spittassiez',
    'spitterions',
    'splittaient',
    'splittasses',
    'splitterais',
    'splitterait',
    'splittèrent',
    'splitteriez',
    'splitterons',
    'splitteront',
    'spoliassent',
    'spoliassiez',
    'spoliateurs',
    'spoliations',
    'spoliatrice',
    'spolierions',
    'spongieuses',
    'spongiosité',
    'sponsorisai',
    'sponsorisas',
    'sponsorisât',
    'sponsorisée',
    'sponsoriser',
    'sponsorises',
    'sponsorisés',
    'sponsorisez',
    'spontanéité',
    'spontanisme',
    'sporadicité',
    'sporadiques',
    'sporophytes',
    'sporotriche',
    'sportivités',
    'sporulaient',
    'sporulasses',
    'sporulerais',
    'sporulerait',
    'sporulèrent',
    'sporuleriez',
    'sporulerons',
    'sporuleront',
    'sprintaient',
    'sprintasses',
    'sprinterais',
    'sprinterait',
    'sprintèrent',
    'sprinteriez',
    'sprinterons',
    'sprinteront',
    'squamifères',
    'squattaient',
    'squattasses',
    'squatterais',
    'squatterait',
    'squattèrent',
    'squatteriez',
    'squattérisa',
    'squattérise',
    'squattérisé',
    'squatterons',
    'squatteront',
    'squirreuses',
    'squirrheuse',
    'stabilisais',
    'stabilisait',
    'stabilisant',
    'stabilisées',
    'stabilisent',
    'stabilisera',
    'stabilisiez',
    'stabilisons',
    'stabulation',
    'stadhouders',
    'staffassent',
    'staffassiez',
    'stafferions',
    'stagflation',
    'stagnassent',
    'stagnassiez',
    'stagnations',
    'stagnerions',
    'stalactites',
    'stalagmites',
    'stalinienne',
    'stalinisais',
    'stalinisait',
    'stalinisant',
    'stalinisées',
    'stalinisent',
    'stalinisera',
    'stalinisiez',
    'stalinismes',
    'stalinisons',
    'staminifère',
    'standardisa',
    'standardise',
    'standardisé',
    'staphylines',
    'staphylomes',
    'starifiâmes',
    'starifiasse',
    'starifiâtes',
    'starifierai',
    'starifieras',
    'starifierez',
    'starifiions',
    'starisaient',
    'starisasses',
    'stariserais',
    'stariserait',
    'starisèrent',
    'stariseriez',
    'stariserons',
    'stariseront',
    'stathouders',
    'stationnais',
    'stationnait',
    'stationnant',
    'stationnées',
    'stationnent',
    'stationnera',
    'stationniez',
    'stationnons',
    'statistique',
    'statthalter',
    'statuassent',
    'statuassiez',
    'statuerions',
    'statufiâmes',
    'statufiasse',
    'statufiâtes',
    'statufierai',
    'statufieras',
    'statufierez',
    'statufiions',
    'statutaires',
    'stéarinerie',
    'stéariniers',
    'stéatopyges',
    'stégosaures',
    'stenciliste',
    'stendhalien',
    'sténogramme',
    'sténographe',
    'sténotypais',
    'sténotypait',
    'sténotypant',
    'sténotypées',
    'sténotypent',
    'sténotypera',
    'sténotypies',
    'sténotypiez',
    'sténotypons',
    'stérassions',
    'stercoraire',
    'stercorales',
    'stéréobates',
    'stéréoscope',
    'stéréotomie',
    'stéréotypai',
    'stéréotypas',
    'stéréotypât',
    'stéréotypée',
    'stéréotyper',
    'stéréotypes',
    'stéréotypés',
    'stéréotypez',
    'stéréotypie',
    'stéreraient',
    'stérilement',
    'stérilisais',
    'stérilisait',
    'stérilisant',
    'stérilisées',
    'stérilisent',
    'stérilisera',
    'stérilisiez',
    'stérilisons',
    'stérilistes',
    'stertoreuse',
    'stéthoscope',
    'stigmatique',
    'stigmatisai',
    'stigmatisas',
    'stigmatisât',
    'stigmatisée',
    'stigmatiser',
    'stigmatises',
    'stigmatisés',
    'stigmatisez',
    'stigmatisme',
    'stillations',
    'stimulaient',
    'stimulantes',
    'stimulasses',
    'stimulateur',
    'stimulation',
    'stimulerais',
    'stimulerait',
    'stimulèrent',
    'stimuleriez',
    'stimulerons',
    'stimuleront',
    'stipendiais',
    'stipendiait',
    'stipendiant',
    'stipendiées',
    'stipendient',
    'stipendiera',
    'stipendiiez',
    'stipendions',
    'stipulaient',
    'stipulaires',
    'stipulasses',
    'stipulation',
    'stipulerais',
    'stipulerait',
    'stipulèrent',
    'stipuleriez',
    'stipulerons',
    'stipuleront',
    'stockassent',
    'stockassiez',
    'stockerions',
    'stockfischs',
    'stoïciennes',
    'stoïquement',
    'stolonifère',
    'stomachique',
    'stoppassent',
    'stoppassiez',
    'stopperions',
    'stramoniums',
    'strangulais',
    'strangulait',
    'strangulant',
    'strangulées',
    'strangulent',
    'strangulera',
    'stranguliez',
    'strangulons',
    'strapontins',
    'stratagèmes',
    'stratégique',
    'stratifiais',
    'stratifiait',
    'stratifiant',
    'stratifiées',
    'stratifient',
    'stratifiera',
    'stratifiiez',
    'stratifions',
    'stratopause',
    'stressaient',
    'stressantes',
    'stressasses',
    'stresserais',
    'stresserait',
    'stressèrent',
    'stresseriez',
    'stresserons',
    'stresseront',
    'striassions',
    'strictement',
    'stridulâmes',
    'stridulante',
    'stridulants',
    'stridulasse',
    'stridulâtes',
    'stridulerai',
    'striduleras',
    'stridulerez',
    'striduleuse',
    'stridulions',
    'strieraient',
    'strioscopie',
    'strippaient',
    'strippasses',
    'stripperais',
    'stripperait',
    'strippèrent',
    'stripperiez',
    'stripperons',
    'stripperont',
    'strip-tease',
    'striquaient',
    'striquasses',
    'striquerais',
    'striquerait',
    'striquèrent',
    'striqueriez',
    'striquerons',
    'striqueront',
    'stroboramas',
    'stroboscope',
    'strombolien',
    'strongylose',
    'strontianes',
    'strophantes',
    'strophantus',
    'structurais',
    'structurait',
    'structurale',
    'structurant',
    'structuraux',
    'structurées',
    'structurels',
    'structurent',
    'structurera',
    'structuriez',
    'structurons',
    'strychnines',
    'stupéfaites',
    'stupéfiâmes',
    'stupéfiante',
    'stupéfiants',
    'stupéfiasse',
    'stupéfiâtes',
    'stupéfierai',
    'stupéfieras',
    'stupéfierez',
    'stupéfiions',
    'stupidement',
    'stuquassent',
    'stuquassiez',
    'stuquerions',
    'stylassions',
    'styleraient',
    'stylisaient',
    'stylisasses',
    'stylisation',
    'styliserais',
    'styliserait',
    'stylisèrent',
    'styliseriez',
    'styliserons',
    'styliseront',
    'stylistique',
    'stylographe',
    'subalternes',
    'subarctique',
    'subatomique',
    'subdéléguai',
    'subdéléguas',
    'subdéléguât',
    'subdéléguée',
    'subdéléguer',
    'subdélégués',
    'subdélègues',
    'subdéléguez',
    'subdivisais',
    'subdivisait',
    'subdivisant',
    'subdivisées',
    'subdivisent',
    'subdivisera',
    'subdivisiez',
    'subdivision',
    'subdivisons',
    'subductions',
    'subfébriles',
    'subintrante',
    'subintrants',
    'subissaient',
    'subjacentes',
    'subjectiles',
    'subjectives',
    'subjonctifs',
    'subjuguâmes',
    'subjuguasse',
    'subjuguâtes',
    'subjuguerai',
    'subjugueras',
    'subjuguerez',
    'subjuguions',
    'sublimaient',
    'sublimasses',
    'sublimation',
    'sublimement',
    'sublimerais',
    'sublimerait',
    'sublimèrent',
    'sublimeriez',
    'sublimerons',
    'sublimeront',
    'subliminale',
    'subliminaux',
    'sublinguale',
    'sublinguaux',
    'sublunaires',
    'submergeais',
    'submergeait',
    'submergeant',
    'submergeons',
    'submergerai',
    'submergeras',
    'submergerez',
    'submergions',
    'submersible',
    'submersions',
    'subnarcoses',
    'subodorâmes',
    'subodorasse',
    'subodorâtes',
    'subodorerai',
    'subodoreras',
    'subodorerez',
    'subodorions',
    'subordonnai',
    'subordonnas',
    'subordonnât',
    'subordonnée',
    'subordonner',
    'subordonnes',
    'subordonnés',
    'subordonnez',
    'subornaient',
    'subornasses',
    'subornation',
    'subornerais',
    'subornerait',
    'subornèrent',
    'suborneriez',
    'subornerons',
    'suborneront',
    'suborneuses',
    'subrécargue',
    'subreptices',
    'subreptions',
    'subrogateur',
    'subrogatifs',
    'subrogation',
    'subrogative',
    'subrogeâmes',
    'subrogeasse',
    'subrogeâtes',
    'subrogerais',
    'subrogerait',
    'subrogèrent',
    'subrogeriez',
    'subrogerons',
    'subrogeront',
    'subséquente',
    'subséquents',
    'subsidiaire',
    'subsistâmes',
    'subsistance',
    'subsistante',
    'subsistants',
    'subsistasse',
    'subsistâtes',
    'subsisterai',
    'subsisteras',
    'subsisterez',
    'subsistions',
    'subsoniques',
    'substantiel',
    'substantifs',
    'substantiva',
    'substantive',
    'substantivé',
    'substituais',
    'substituait',
    'substituant',
    'substituées',
    'substituent',
    'substituera',
    'substituiez',
    'substituons',
    'substitutif',
    'substratums',
    'subterfuges',
    'subtilement',
    'subtilisais',
    'subtilisait',
    'subtilisant',
    'subtilisées',
    'subtilisent',
    'subtilisera',
    'subtilisiez',
    'subtilisons',
    'subtropical',
    'suburbaines',
    'subvenaient',
    'subventions',
    'subversions',
    'subversives',
    'subvertîmes',
    'subvertirai',
    'subvertiras',
    'subvertirez',
    'subvertisse',
    'subvertîtes',
    'subviendrai',
    'subviendras',
    'subviendrez',
    'subviennent',
    'subvinssent',
    'subvinssiez',
    'succédaient',
    'succédanées',
    'succédasses',
    'succéderais',
    'succéderait',
    'succédèrent',
    'succéderiez',
    'succéderons',
    'succéderont',
    'succenturié',
    'successeurs',
    'successible',
    'successions',
    'successives',
    'successoral',
    'succiniques',
    'succombâmes',
    'succombasse',
    'succombâtes',
    'succomberai',
    'succomberas',
    'succomberez',
    'succombions',
    'succulences',
    'succulentes',
    'succursales',
    'suçotassent',
    'suçotassiez',
    'suçoterions',
    'sucrassions',
    'sucreraient',
    'sud-coréens',
    'sudorifères',
    'sudorifique',
    'sudoripares',
    'suffiraient',
    'suffisaient',
    'suffisances',
    'suffisantes',
    'suffissions',
    'suffixaient',
    'suffixasses',
    'suffixation',
    'suffixerais',
    'suffixerait',
    'suffixèrent',
    'suffixeriez',
    'suffixerons',
    'suffixeront',
    'suffocantes',
    'suffocation',
    'suffoquâmes',
    'suffoquasse',
    'suffoquâtes',
    'suffoquerai',
    'suffoqueras',
    'suffoquerez',
    'suffoquions',
    'suffragante',
    'suffragants',
    'suffragette',
    'suggéraient',
    'suggérasses',
    'suggérerais',
    'suggérerait',
    'suggérèrent',
    'suggéreriez',
    'suggérerons',
    'suggéreront',
    'suggestible',
    'suggestions',
    'suggestives',
    'suicidaient',
    'suicidaires',
    'suicidasses',
    'suiciderais',
    'suiciderait',
    'suicidèrent',
    'suicideriez',
    'suiciderons',
    'suicideront',
    'suifassions',
    'suiferaient',
    'suiffassent',
    'suiffassiez',
    'suifferions',
    'suintassent',
    'suintassiez',
    'suintements',
    'suinterions',
    'suivissions',
    'sulciformes',
    'sulfataient',
    'sulfatasses',
    'sulfaterais',
    'sulfaterait',
    'sulfatèrent',
    'sulfateriez',
    'sulfaterons',
    'sulfateront',
    'sulfateuses',
    'sulfitaient',
    'sulfitasses',
    'sulfiterais',
    'sulfiterait',
    'sulfitèrent',
    'sulfiteriez',
    'sulfiterons',
    'sulfiteront',
    'sulfonaient',
    'sulfonasses',
    'sulfonerais',
    'sulfonerait',
    'sulfonèrent',
    'sulfoneriez',
    'sulfonerons',
    'sulfoneront',
    'sulfuraient',
    'sulfurasses',
    'sulfuration',
    'sulfurerais',
    'sulfurerait',
    'sulfurèrent',
    'sulfureriez',
    'sulfurerons',
    'sulfureront',
    'sulfureuses',
    'sulfuriques',
    'sulfurisées',
    'sulpicienne',
    'sumériennes',
    'superbement',
    'superbombes',
    'supercarrés',
    'supercherie',
    'superciment',
    'superficiel',
    'superficies',
    'superfinies',
    'superfinira',
    'superfluide',
    'superfluité',
    'super-grand',
    'supergrands',
    'supérieures',
    'supériorité',
    'superlatifs',
    'supermalloy',
    'supermarché',
    'superovarié',
    'superposais',
    'superposait',
    'superposant',
    'superposées',
    'superposent',
    'superposera',
    'superposiez',
    'superposons',
    'superpréfet',
    'superprofit',
    'superstrats',
    'supertanker',
    'supervisais',
    'supervisait',
    'supervisant',
    'supervisées',
    'supervisent',
    'supervisera',
    'superviseur',
    'supervisiez',
    'supervision',
    'supervisons',
    'superwelter',
    'supinateurs',
    'supinations',
    'supplantais',
    'supplantait',
    'supplantant',
    'supplantées',
    'supplantent',
    'supplantera',
    'supplantiez',
    'supplantons',
    'suppléaient',
    'suppléances',
    'suppléantes',
    'suppléasses',
    'suppléerais',
    'suppléerait',
    'suppléèrent',
    'suppléeriez',
    'suppléerons',
    'suppléeront',
    'supplémenta',
    'supplémente',
    'supplémenté',
    'suppléments',
    'supplétives',
    'supplétoire',
    'suppliaient',
    'suppliantes',
    'suppliasses',
    'suppliciais',
    'suppliciait',
    'suppliciant',
    'suppliciées',
    'supplicient',
    'suppliciera',
    'suppliciiez',
    'supplicions',
    'supplierais',
    'supplierait',
    'supplièrent',
    'supplieriez',
    'supplierons',
    'supplieront',
    'supportable',
    'supportâmes',
    'supportasse',
    'supportâtes',
    'supporterai',
    'supporteras',
    'supporterez',
    'supportions',
    'supposables',
    'supposaient',
    'supposasses',
    'supposerais',
    'supposerait',
    'supposèrent',
    'supposeriez',
    'supposerons',
    'supposeront',
    'supposition',
    'suppression',
    'supprimable',
    'supprimâmes',
    'supprimasse',
    'supprimâtes',
    'supprimerai',
    'supprimeras',
    'supprimerez',
    'supprimions',
    'suppuraient',
    'suppurantes',
    'suppurasses',
    'suppuratifs',
    'suppuration',
    'suppurative',
    'suppurerais',
    'suppurerait',
    'suppurèrent',
    'suppureriez',
    'suppurerons',
    'suppureront',
    'supputaient',
    'supputasses',
    'supputation',
    'supputerais',
    'supputerait',
    'supputèrent',
    'supputeriez',
    'supputerons',
    'supputeront',
    'suprématies',
    'suprêmement',
    'surabondais',
    'surabondait',
    'surabondant',
    'surabondent',
    'surabondera',
    'surabondiez',
    'surabondons',
    'suractivées',
    'suractivité',
    'surajoutais',
    'surajoutait',
    'surajoutant',
    'surajoutées',
    'surajoutent',
    'surajoutera',
    'surajoutiez',
    'surajoutons',
    'suralimenta',
    'suralimente',
    'suralimenté',
    'surarbitres',
    'surarmement',
    'surbaissais',
    'surbaissait',
    'surbaissant',
    'surbaissées',
    'surbaissent',
    'surbaissera',
    'surbaissiez',
    'surbaissons',
    'surcapacité',
    'surchargeai',
    'surchargeas',
    'surchargeât',
    'surchargées',
    'surchargent',
    'surchargera',
    'surchargiez',
    'surchauffai',
    'surchauffas',
    'surchauffât',
    'surchauffée',
    'surchauffer',
    'surchauffes',
    'surchauffés',
    'surchauffez',
    'surclassais',
    'surclassait',
    'surclassant',
    'surclassées',
    'surclassent',
    'surclassera',
    'surclassiez',
    'surclassons',
    'surcomposée',
    'surcomposés',
    'surcomprima',
    'surcomprime',
    'surcomprimé',
    'surcontrais',
    'surcontrait',
    'surcontrant',
    'surcontrées',
    'surcontrent',
    'surcontrera',
    'surcontriez',
    'surcontrons',
    'surcoupâmes',
    'surcoupasse',
    'surcoupâtes',
    'surcouperai',
    'surcouperas',
    'surcouperez',
    'surcoupions',
    'surdoraient',
    'surdorasses',
    'surdorerais',
    'surdorerait',
    'surdorèrent',
    'surdoreriez',
    'surdorerons',
    'surdoreront',
    'surédifiais',
    'surédifiait',
    'surédifiant',
    'surédifiées',
    'surédifient',
    'surédifiera',
    'surédifiiez',
    'surédifions',
    'surélevâmes',
    'surélevasse',
    'surélevâtes',
    'surélèverai',
    'surélèveras',
    'surélèverez',
    'surélevions',
    'surenchères',
    'surenchérir',
    'surenchéris',
    'surenchérit',
    'surenchérît',
    'surencombré',
    'surentraîna',
    'surentraîne',
    'surentraîné',
    'suréquipais',
    'suréquipait',
    'suréquipant',
    'suréquipées',
    'suréquipent',
    'suréquipera',
    'suréquipiez',
    'suréquipons',
    'surestimais',
    'surestimait',
    'surestimant',
    'surestimées',
    'surestiment',
    'surestimera',
    'surestimiez',
    'surestimons',
    'surévaluais',
    'surévaluait',
    'surévaluant',
    'surévaluées',
    'surévaluent',
    'surévaluera',
    'surévaluiez',
    'surévaluons',
    'surexcitais',
    'surexcitait',
    'surexcitant',
    'surexcitées',
    'surexcitent',
    'surexcitera',
    'surexcitiez',
    'surexcitons',
    'surexploita',
    'surexploite',
    'surexploité',
    'surexposais',
    'surexposait',
    'surexposant',
    'surexposées',
    'surexposent',
    'surexposera',
    'surexposiez',
    'surexposons',
    'surfaçaient',
    'surfaçasses',
    'surfacerais',
    'surfacerait',
    'surfacèrent',
    'surfaceriez',
    'surfacerons',
    'surfaceront',
    'surfaisions',
    'surfassions',
    'surferaient',
    'surfilaient',
    'surfilasses',
    'surfilerais',
    'surfilerait',
    'surfilèrent',
    'surfileriez',
    'surfilerons',
    'surfileront',
    'surfissions',
    'surgelaient',
    'surgelasses',
    'surgélateur',
    'surgélation',
    'surgèlerais',
    'surgèlerait',
    'surgelèrent',
    'surgèleriez',
    'surgèlerons',
    'surgèleront',
    'surgeonnais',
    'surgeonnait',
    'surgeonnant',
    'surgeonnent',
    'surgeonnera',
    'surgeonniez',
    'surgeonnons',
    'surgiraient',
    'surgissions',
    'surglaçâmes',
    'surglaçasse',
    'surglaçâtes',
    'surglacerai',
    'surglaceras',
    'surglacerez',
    'surglacions',
    'surhaussais',
    'surhaussait',
    'surhaussant',
    'surhaussées',
    'surhaussent',
    'surhaussera',
    'surhaussiez',
    'surhaussons',
    'surhumaines',
    'surhumanité',
    'surimposais',
    'surimposait',
    'surimposant',
    'surimposées',
    'surimposent',
    'surimposera',
    'surimposiez',
    'surimposons',
    'surinassent',
    'surinassiez',
    'surinerions',
    'surissaient',
    'surjalaient',
    'surjalasses',
    'surjalerais',
    'surjalerait',
    'surjalèrent',
    'surjaleriez',
    'surjalerons',
    'surjaleront',
    'surjetaient',
    'surjetasses',
    'surjetèrent',
    'surjetterai',
    'surjetteras',
    'surjetterez',
    'surliassent',
    'surliassiez',
    'surlierions',
    'surlouaient',
    'surlouasses',
    'surlouerais',
    'surlouerait',
    'surlouèrent',
    'surloueriez',
    'surlouerons',
    'surloueront',
    'surmenaient',
    'surmenasses',
    'surmènerais',
    'surmènerait',
    'surmenèrent',
    'surmèneriez',
    'surmènerons',
    'surmèneront',
    'surmontable',
    'surmontâmes',
    'surmontasse',
    'surmontâtes',
    'surmonterai',
    'surmonteras',
    'surmonterez',
    'surmontions',
    'surmontoirs',
    'surmoulages',
    'surmoulâmes',
    'surmoulasse',
    'surmoulâtes',
    'surmoulerai',
    'surmouleras',
    'surmoulerez',
    'surmoulions',
    'surnageâmes',
    'surnageasse',
    'surnageâtes',
    'surnagerais',
    'surnagerait',
    'surnagèrent',
    'surnageriez',
    'surnagerons',
    'surnageront',
    'surnatalité',
    'surnaturels',
    'surnommâmes',
    'surnommasse',
    'surnommâtes',
    'surnommerai',
    'surnommeras',
    'surnommerez',
    'surnommions',
    'suroxydâmes',
    'suroxydasse',
    'suroxydâtes',
    'suroxyderai',
    'suroxyderas',
    'suroxyderez',
    'suroxydions',
    'suroxygénée',
    'suroxygénés',
    'surpaierais',
    'surpaierait',
    'surpaieriez',
    'surpaierons',
    'surpaieront',
    'surpassâmes',
    'surpassasse',
    'surpassâtes',
    'surpasserai',
    'surpasseras',
    'surpasserez',
    'surpassions',
    'surpayaient',
    'surpayasses',
    'surpayerais',
    'surpayerait',
    'surpayèrent',
    'surpayeriez',
    'surpayerons',
    'surpayeront',
    'surpeuplées',
    'surpiquâmes',
    'surpiquasse',
    'surpiquâtes',
    'surpiquerai',
    'surpiqueras',
    'surpiquerez',
    'surpiquions',
    'surplombais',
    'surplombait',
    'surplombant',
    'surplombées',
    'surplombent',
    'surplombera',
    'surplombiez',
    'surplombons',
    'surprenante',
    'surprenants',
    'surprendrai',
    'surprendras',
    'surprendrez',
    'surprenions',
    'surprennent',
    'surpression',
    'surprissent',
    'surprissiez',
    'surprotégea',
    'surprotégée',
    'surprotéger',
    'surprotégés',
    'surprotèges',
    'surprotégez',
    'surpuissant',
    'surréalisme',
    'surréaliste',
    'surrections',
    'sursalaires',
    'sursaturais',
    'sursaturait',
    'sursaturant',
    'sursaturées',
    'sursaturent',
    'sursaturera',
    'sursaturiez',
    'sursaturons',
    'sursautâmes',
    'sursautasse',
    'sursautâtes',
    'sursauterai',
    'sursauteras',
    'sursauterez',
    'sursautions',
    'sursemaient',
    'sursemasses',
    'sursèmerais',
    'sursèmerait',
    'sursemèrent',
    'sursèmeriez',
    'sursèmerons',
    'sursèmeront',
    'surseoirais',
    'surseoirait',
    'surseoiriez',
    'surseoirons',
    'surseoiront',
    'sursissions',
    'sursitaires',
    'sursoyaient',
    'surtaxaient',
    'surtaxasses',
    'surtaxerais',
    'surtaxerait',
    'surtaxèrent',
    'surtaxeriez',
    'surtaxerons',
    'surtaxeront',
    'surtensions',
    'survécurent',
    'survécusses',
    'surveillais',
    'surveillait',
    'surveillant',
    'surveillées',
    'surveillent',
    'surveillera',
    'surveilliez',
    'surveillons',
    'survenaient',
    'survenances',
    'survêtement',
    'surviendrai',
    'surviendras',
    'surviendrez',
    'surviennent',
    'survinssent',
    'survinssiez',
    'surviraient',
    'survirasses',
    'survirerais',
    'survirerait',
    'survirèrent',
    'survireriez',
    'survirerons',
    'survireront',
    'survireuses',
    'survivaient',
    'survivances',
    'survivantes',
    'survivrions',
    'survolaient',
    'survolasses',
    'survolerais',
    'survolerait',
    'survolèrent',
    'survoleriez',
    'survolerons',
    'survoleront',
    'survoltages',
    'survoltâmes',
    'survoltasse',
    'survoltâtes',
    'survolterai',
    'survolteras',
    'survolterez',
    'survolteurs',
    'survoltions',
    'susceptible',
    'suscitaient',
    'suscitasses',
    'susciterais',
    'susciterait',
    'suscitèrent',
    'susciteriez',
    'susciterons',
    'susciteront',
    'suscription',
    'sus-dénommé',
    'suspectâmes',
    'suspectasse',
    'suspectâtes',
    'suspecterai',
    'suspecteras',
    'suspecterez',
    'suspections',
    'suspendîmes',
    'suspendions',
    'suspendisse',
    'suspendîtes',
    'suspendrais',
    'suspendrait',
    'suspendriez',
    'suspendrons',
    'suspendront',
    'suspenseurs',
    'suspensions',
    'suspensives',
    'suspensoirs',
    'suspicieuse',
    'sustentâmes',
    'sustentasse',
    'sustentâtes',
    'sustenterai',
    'sustenteras',
    'sustenterez',
    'sustentions',
    'susurraient',
    'susurrantes',
    'susurrasses',
    'susurrement',
    'susurrerais',
    'susurrerait',
    'susurrèrent',
    'susurreriez',
    'susurrerons',
    'susurreront',
    'suturassent',
    'suturassiez',
    'suturerions',
    'suzeraineté',
    'sweat-shirt',
    'sweepstakes',
    'swinguaient',
    'swinguasses',
    'swinguerais',
    'swinguerait',
    'swinguèrent',
    'swingueriez',
    'swinguerons',
    'swingueront',
    'sybaritique',
    'sybaritisme',
    'sycophantes',
    'syllabaient',
    'syllabasses',
    'syllabation',
    'syllaberais',
    'syllaberait',
    'syllabèrent',
    'syllaberiez',
    'syllaberons',
    'syllaberont',
    'syllabiques',
    'syllabismes',
    'syllogismes',
    'symbiotique',
    'symboliques',
    'symbolisais',
    'symbolisait',
    'symbolisant',
    'symbolisées',
    'symbolisent',
    'symbolisera',
    'symbolisiez',
    'symbolismes',
    'symbolisons',
    'symbolistes',
    'symétriques',
    'symétrisais',
    'symétrisait',
    'symétrisant',
    'symétrisées',
    'symétrisent',
    'symétrisera',
    'symétrisiez',
    'symétrisons',
    'sympathique',
    'sympathisai',
    'sympathisas',
    'sympathisât',
    'sympathiser',
    'sympathises',
    'sympathisez',
    'symphonique',
    'symphoniste',
    'symphorines',
    'synanthérée',
    'synanthérés',
    'synaptiques',
    'synarthrose',
    'synchronies',
    'synchronisa',
    'synchronise',
    'synchronisé',
    'synchrotron',
    'synclinales',
    'syncopaient',
    'syncopasses',
    'syncoperais',
    'syncoperait',
    'syncopèrent',
    'syncoperiez',
    'syncoperons',
    'syncoperont',
    'syncrétisme',
    'syndicalisa',
    'syndicalise',
    'syndicalisé',
    'syndiquâmes',
    'syndiquasse',
    'syndiquâtes',
    'syndiquerai',
    'syndiqueras',
    'syndiquerez',
    'syndiquions',
    'synecdoques',
    'synergiques',
    'synesthésie',
    'synonymique',
    'synoptiques',
    'syntactique',
    'syntaxiques',
    'synthétique',
    'synthétisai',
    'synthétisas',
    'synthétisât',
    'synthétisée',
    'synthétiser',
    'synthétises',
    'synthétisés',
    'synthétisez',
    'syntonisais',
    'syntonisait',
    'syntonisant',
    'syntonisées',
    'syntonisent',
    'syntonisera',
    'syntonisiez',
    'syntonisons',
    'systématisa',
    'systématise',
    'systématisé',
    'systémiques',
    'tabacomanie',
    'tabaculteur',
    'tabassaient',
    'tabassasses',
    'tabasserais',
    'tabasserait',
    'tabassèrent',
    'tabasseriez',
    'tabasserons',
    'tabasseront',
    'tabellaires',
    'tabernacles',
    'tablassions',
    'tableautins',
    'tableraient',
    'tabletières',
    'tabletterie',
    'tabulassent',
    'tabulassiez',
    'tabulateurs',
    'tabulatrice',
    'tabulerions',
    'tachassions',
    'tâchassions',
    'tachéomètre',
    'tacheraient',
    'tâcheraient',
    'tachetaient',
    'tachetasses',
    'tachetèrent',
    'tachetterai',
    'tachetteras',
    'tachetterez',
    'tachycardie',
    'tachygenèse',
    'tachygraphe',
    'tachymètres',
    'tachyphémie',
    'taciturnité',
    'tacticienne',
    'tahitiennes',
    'tailladâmes',
    'tailladasse',
    'tailladâtes',
    'tailladerai',
    'tailladeras',
    'tailladerez',
    'tailladions',
    'taillandier',
    'taillassent',
    'taillassiez',
    'taillerions',
    'talentueuse',
    'tallassions',
    'talleraient',
    'talmudiques',
    'talmudistes',
    'talochaient',
    'talochasses',
    'talocherais',
    'talocherait',
    'talochèrent',
    'talocheriez',
    'talocherons',
    'talocheront',
    'talonnaient',
    'talonnasses',
    'talonnement',
    'talonnerais',
    'talonnerait',
    'talonnèrent',
    'talonneriez',
    'talonnerons',
    'talonneront',
    'talonnettes',
    'talonnières',
    'talquassent',
    'talquassiez',
    'talquerions',
    'tamariniers',
    'tambouilles',
    'tambourinai',
    'tambourinas',
    'tambourinât',
    'tambourinée',
    'tambouriner',
    'tambourines',
    'tambourinés',
    'tambourinez',
    'tamisassent',
    'tamisassiez',
    'tamiserions',
    'tamponnades',
    'tamponnâmes',
    'tamponnasse',
    'tamponnâtes',
    'tamponnerai',
    'tamponneras',
    'tamponnerez',
    'tamponneurs',
    'tamponneuse',
    'tamponnions',
    'tamponnoirs',
    'tançassions',
    'tanceraient',
    'tangibilité',
    'tanguassent',
    'tanguassiez',
    'tanguerions',
    'tanisassent',
    'tanisassiez',
    'taniserions',
    'tannassions',
    'tanneraient',
    'tannisaient',
    'tannisasses',
    'tanniserais',
    'tanniserait',
    'tannisèrent',
    'tanniseriez',
    'tanniserons',
    'tanniseront',
    'tapageaient',
    'tapageasses',
    'tapagerions',
    'taphophilie',
    'tapinassent',
    'tapinassiez',
    'tapinerions',
    'tapissaient',
    'tapissasses',
    'tapisserais',
    'tapisserait',
    'tapissèrent',
    'tapisseries',
    'tapisseriez',
    'tapisserons',
    'tapisseront',
    'tapissières',
    'taponnaient',
    'taponnasses',
    'taponnerais',
    'taponnerait',
    'taponnèrent',
    'taponneriez',
    'taponnerons',
    'taponneront',
    'tapotassent',
    'tapotassiez',
    'tapotements',
    'tapoterions',
    'taquassions',
    'taqueraient',
    'taquinaient',
    'taquinantes',
    'taquinasses',
    'taquinerais',
    'taquinerait',
    'taquinèrent',
    'taquineries',
    'taquineriez',
    'taquinerons',
    'taquineront',
    'tarabiscota',
    'tarabiscote',
    'tarabiscoté',
    'tarabiscots',
    'tarabustais',
    'tarabustait',
    'tarabustant',
    'tarabustées',
    'tarabustent',
    'tarabustera',
    'tarabustiez',
    'tarabustons',
    'taraudaient',
    'taraudasses',
    'tarauderais',
    'tarauderait',
    'taraudèrent',
    'tarauderiez',
    'tarauderons',
    'tarauderont',
    'taraudeuses',
    'tardassions',
    'tarderaient',
    'tardigrades',
    'tardillonne',
    'tardivement',
    'tarentelles',
    'targuassent',
    'targuassiez',
    'targuerions',
    'taricheutes',
    'tarifassent',
    'tarifassiez',
    'tariferions',
    'tarifiaient',
    'tarifiasses',
    'tarifierais',
    'tarifierait',
    'tarifièrent',
    'tarifieriez',
    'tarifierons',
    'tarifieront',
    'tarissables',
    'tarissaient',
    'tarissement',
    'tarmacadams',
    'tarsectomie',
    'tartelettes',
    'tartignolle',
    'tartinaient',
    'tartinasses',
    'tartinerais',
    'tartinerait',
    'tartinèrent',
    'tartineriez',
    'tartinerons',
    'tartineront',
    'tartiraient',
    'tartissions',
    'tartuferies',
    'tartufferie',
    'tassassions',
    'tasseraient',
    'tatillonnai',
    'tatillonnas',
    'tatillonnât',
    'tatillonner',
    'tatillonnes',
    'tatillonnez',
    'tâtonnaient',
    'tâtonnantes',
    'tâtonnasses',
    'tâtonnement',
    'tâtonnerais',
    'tâtonnerait',
    'tâtonnèrent',
    'tâtonneriez',
    'tâtonnerons',
    'tâtonneront',
    'tatouassent',
    'tatouassiez',
    'tatouerions',
    'taupinières',
    'tauromachie',
    'tautochrone',
    'tautologies',
    'tautologues',
    'tautoméries',
    'tavelassent',
    'tavelassiez',
    'tavellaient',
    'tavellasses',
    'tavellerais',
    'tavellerait',
    'tavellèrent',
    'tavelleriez',
    'tavellerons',
    'tavelleront',
    'tavernières',
    'taxidermies',
    'taxinomique',
    'taxinomiste',
    'taxonomique',
    'taxonomiste',
    'taylorisais',
    'taylorisait',
    'taylorisant',
    'taylorisées',
    'taylorisent',
    'taylorisera',
    'taylorisiez',
    'taylorismes',
    'taylorisons',
    'tchérémisse',
    'tchernoziom',
    'tchin-tchin',
    'techniciens',
    'technicisai',
    'technicisas',
    'technicisât',
    'technicisée',
    'techniciser',
    'technicises',
    'technicisés',
    'technicisez',
    'technicités',
    'Technicolor',
    'technisâmes',
    'technisasse',
    'technisâtes',
    'techniserai',
    'techniseras',
    'techniserez',
    'technisions',
    'technocrate',
    'technologie',
    'technologue',
    'technophile',
    'tectoniques',
    'teignissent',
    'teignissiez',
    'teillassent',
    'teillassiez',
    'teillerions',
    'teindraient',
    'teintassent',
    'teintassiez',
    'teinterions',
    'teinturerie',
    'teinturière',
    'teinturiers',
    'télécabines',
    'télécinémas',
    'télécopiais',
    'télécopiait',
    'télécopiant',
    'télécopiées',
    'télécopient',
    'télécopiera',
    'télécopieur',
    'télécopiiez',
    'télécopions',
    'télédiffusa',
    'télédiffuse',
    'télédiffusé',
    'téléférique',
    'télégénique',
    'télégestion',
    'télégrammes',
    'télégraphes',
    'télégraphia',
    'télégraphie',
    'télégraphié',
    'téléguidage',
    'téléguidais',
    'téléguidait',
    'téléguidant',
    'téléguidées',
    'téléguident',
    'téléguidera',
    'téléguidiez',
    'téléguidons',
    'télékinésie',
    'télématique',
    'télémesures',
    'télémétrais',
    'télémétrait',
    'télémétrant',
    'télémétrées',
    'télémètrent',
    'télémétrera',
    'télémétreur',
    'télémétries',
    'télémétriez',
    'télémétrons',
    'téléologies',
    'téléonomies',
    'téléosaures',
    'télépathies',
    'téléphérage',
    'téléphonage',
    'téléphonais',
    'téléphonait',
    'téléphonant',
    'téléphonées',
    'téléphonent',
    'téléphonera',
    'téléphonies',
    'téléphoniez',
    'téléphonons',
    'téléscaphes',
    'télescopage',
    'télescopais',
    'télescopait',
    'télescopant',
    'télescopées',
    'télescopent',
    'télescopera',
    'télescopiez',
    'télescopons',
    'télesthésie',
    'téléthèques',
    'télétoxique',
    'télétravail',
    'télévisâmes',
    'télévisasse',
    'télévisâtes',
    'téléviserai',
    'téléviseras',
    'téléviserez',
    'téléviseurs',
    'télévisions',
    'télévisuels',
    'télexassent',
    'télexassiez',
    'télexerions',
    'tellureuses',
    'tellurienne',
    'telluriques',
    'tellurismes',
    'télolécithe',
    'témoignages',
    'témoignâmes',
    'témoignasse',
    'témoignâtes',
    'témoignerai',
    'témoigneras',
    'témoignerez',
    'témoignions',
    'tempéraient',
    'tempérament',
    'tempérances',
    'tempérantes',
    'tempérasses',
    'température',
    'tempérerais',
    'tempérerait',
    'tempérèrent',
    'tempéreriez',
    'tempérerons',
    'tempéreront',
    'tempêtaient',
    'tempêtasses',
    'tempêterais',
    'tempêterait',
    'tempêtèrent',
    'tempêteriez',
    'tempêterons',
    'tempêteront',
    'tempétueuse',
    'temporaires',
    'temporalité',
    'temporelles',
    'temporisais',
    'temporisait',
    'temporisant',
    'temporisent',
    'temporisera',
    'temporisiez',
    'temporisons',
    'tenaillâmes',
    'tenaillasse',
    'tenaillâtes',
    'tenaillerai',
    'tenailleras',
    'tenaillerez',
    'tenaillions',
    'tenancières',
    'tendanciels',
    'tendancieux',
    'tendineuses',
    'tendissions',
    'ténébreuses',
    'teneurmètre',
    'tennistique',
    'tenonnaient',
    'tenonnasses',
    'tenonnerais',
    'tenonnerait',
    'tenonnèrent',
    'tenonneriez',
    'tenonnerons',
    'tenonneront',
    'tenonneuses',
    'ténorisâmes',
    'ténorisasse',
    'ténorisâtes',
    'ténoriserai',
    'ténoriseras',
    'ténoriserez',
    'ténorisions',
    'tensioactif',
    'tensiomètre',
    'tentassions',
    'tentatrices',
    'tenteraient',
    'tepidariums',
    'tératogènes',
    'tératogénie',
    'tératologie',
    'tératologue',
    'terçassions',
    'terceraient',
    'terebellums',
    'térébinthes',
    'térébrantes',
    'térébratule',
    'tergiversai',
    'tergiversas',
    'tergiversât',
    'tergiverser',
    'tergiverses',
    'tergiversez',
    'termaillage',
    'terminaient',
    'terminaison',
    'terminasses',
    'terminerais',
    'terminerait',
    'terminèrent',
    'termineriez',
    'terminerons',
    'termineront',
    'termitières',
    'terniraient',
    'ternissante',
    'ternissants',
    'ternissions',
    'ternissures',
    'terpéniques',
    'terraplanes',
    'terrassâmes',
    'terrassasse',
    'terrassâtes',
    'terrasserai',
    'terrasseras',
    'terrasserez',
    'terrassiers',
    'terrassions',
    'terreautage',
    'terreautais',
    'terreautait',
    'terreautant',
    'terreautées',
    'terreautent',
    'terreautera',
    'terreautiez',
    'terreautons',
    'terre-neuve',
    'terre-plein',
    'terreraient',
    'terrifiâmes',
    'terrifiante',
    'terrifiants',
    'terrifiasse',
    'terrifiâtes',
    'terrifierai',
    'terrifieras',
    'terrifierez',
    'terrifiions',
    'terriraient',
    'terrissions',
    'territoires',
    'territorial',
    'terrorisais',
    'terrorisait',
    'terrorisant',
    'terrorisées',
    'terrorisent',
    'terrorisera',
    'terrorisiez',
    'terrorismes',
    'terrorisons',
    'terroristes',
    'tersassions',
    'terseraient',
    'testabilité',
    'testacelles',
    'testassions',
    'testatrices',
    'testeraient',
    'testimonial',
    'testologies',
    'tétanisâmes',
    'tétanisante',
    'tétanisants',
    'tétanisasse',
    'tétanisâtes',
    'tétaniserai',
    'tétaniseras',
    'tétaniserez',
    'tétanisions',
    'tête-à-tête',
    'tétonnières',
    'tétracordes',
    'tétralogies',
    'tétramètres',
    'tétraphonie',
    'tétraplégie',
    'tétraploïde',
    'tétraptères',
    'tétrarchats',
    'tétrarchies',
    'tétrastyles',
    'teufs-teufs',
    'teutoniques',
    'texturaient',
    'texturasses',
    'texturerais',
    'texturerait',
    'texturèrent',
    'textureriez',
    'texturerons',
    'textureront',
    'texturisais',
    'texturisait',
    'texturisant',
    'texturisées',
    'texturisent',
    'texturisera',
    'texturisiez',
    'texturisons',
    'thaïlandais',
    'thalamiques',
    'thalassémie',
    'thalidomide',
    'thaumaturge',
    'théâtralisa',
    'théâtralise',
    'théâtralisé',
    'théâtralité',
    'thématiques',
    'thématisais',
    'thématisait',
    'thématisant',
    'thématisées',
    'thématisent',
    'thématisera',
    'thématisiez',
    'thématismes',
    'thématisons',
    'théobromine',
    'théocraties',
    'théodolites',
    'théogonique',
    'théologales',
    'théologiens',
    'théologique',
    'théorétique',
    'théoriciens',
    'théorisâmes',
    'théorisasse',
    'théorisâtes',
    'théoriserai',
    'théoriseras',
    'théoriserez',
    'théorisions',
    'théosophies',
    'thérapeutes',
    'thermalisme',
    'thermisteur',
    'thermistors',
    'thermogènes',
    'thermolyses',
    'thermomètre',
    'thermopompe',
    'thermoscope',
    'thermostats',
    'thésaurisai',
    'thésaurisas',
    'thésaurisât',
    'thésaurisée',
    'thésauriser',
    'thésaurises',
    'thésaurisés',
    'thésaurisez',
    'thesmothète',
    'théurgiques',
    'thioalcools',
    'thoraciques',
    'thrombocyte',
    'thyréotrope',
    'thyroïdiens',
    'thyroïdisme',
    'thyroïdites',
    'tictaquâmes',
    'tictaquasse',
    'tictaquâtes',
    'tictaquerai',
    'tictaqueras',
    'tictaquerez',
    'tictaquions',
    'tiédiraient',
    'tiédissante',
    'tiédissants',
    'tiédissions',
    'tiendraient',
    'tierçassent',
    'tierçassiez',
    'tiercerions',
    'tiers-point',
    'tigrassions',
    'tigreraient',
    'tillandsies',
    'tillassions',
    'tilleraient',
    'timbrassent',
    'timbrassiez',
    'timbrerions',
    'tinctoriale',
    'tinctoriaux',
    'tintamarres',
    'tintassions',
    'tinteraient',
    'tiquassions',
    'tiqueraient',
    'tiraillâmes',
    'tiraillante',
    'tiraillants',
    'tiraillasse',
    'tiraillâtes',
    'tiraillerai',
    'tirailleras',
    'tiraillerez',
    'tiraillerie',
    'tirailleurs',
    'tiraillions',
    'tire-au-cul',
    'tire-balles',
    'tire-bondes',
    'tire-bottes',
    'tire-bouton',
    'tire-braise',
    'tire-fesses',
    'tire-filets',
    'tire-lignes',
    'tire-veille',
    'tire-veines',
    'tisonnaient',
    'tisonnasses',
    'tisonnerais',
    'tisonnerait',
    'tisonnèrent',
    'tisonneriez',
    'tisonnerons',
    'tisonneront',
    'tissassions',
    'tisseraient',
    'tisserandes',
    'tissulaires',
    'titanesques',
    'titillaient',
    'titillasses',
    'titillation',
    'titillerais',
    'titillerait',
    'titillèrent',
    'titilleriez',
    'titillerons',
    'titilleront',
    'titrassions',
    'titreraient',
    'titubassent',
    'titubassiez',
    'titubations',
    'tituberions',
    'titularisai',
    'titularisas',
    'titularisât',
    'titularisée',
    'titulariser',
    'titularises',
    'titularisés',
    'titularisez',
    'toastassent',
    'toastassiez',
    'toasterions',
    'toilassions',
    'toileraient',
    'toilettages',
    'toilettâmes',
    'toilettasse',
    'toilettâtes',
    'toiletterai',
    'toiletteras',
    'toiletterez',
    'toilettions',
    'toisassions',
    'toiseraient',
    'tokharienne',
    'tolérassent',
    'tolérassiez',
    'tolérerions',
    'tombassions',
    'tomberaient',
    'tomenteuses',
    'tondissions',
    'tonifiaient',
    'tonifiantes',
    'tonifiasses',
    'tonifierais',
    'tonifierait',
    'tonifièrent',
    'tonifieriez',
    'tonifierons',
    'tonifieront',
    'tonitruâmes',
    'tonitruante',
    'tonitruants',
    'tonitruasse',
    'tonitruâtes',
    'tonitruerai',
    'tonitrueras',
    'tonitruerez',
    'tonitruions',
    'tonnellerie',
    'tonneraient',
    'tonométries',
    'tonsuraient',
    'tonsurasses',
    'tonsurerais',
    'tonsurerait',
    'tonsurèrent',
    'tonsureriez',
    'tonsurerons',
    'tonsureront',
    'tontinaient',
    'tontinasses',
    'tontinerais',
    'tontinerait',
    'tontinèrent',
    'tontineriez',
    'tontinerons',
    'tontineront',
    'topicalisai',
    'topicalisas',
    'topicalisât',
    'topicalisée',
    'topicaliser',
    'topicalises',
    'topicalisés',
    'topicalisez',
    'topinambour',
    'topographes',
    'topographie',
    'toponymique',
    'toquassions',
    'toqueraient',
    'torchassent',
    'torchassiez',
    'torche-culs',
    'torcherions',
    'torchonnais',
    'torchonnait',
    'torchonnant',
    'torchonnées',
    'torchonnent',
    'torchonnera',
    'torchonniez',
    'torchonnons',
    'tord-boyaux',
    'tordissions',
    'toréassions',
    'toréeraient',
    'toreutiques',
    'tormentille',
    'toronnaient',
    'toronnasses',
    'toronnerais',
    'toronnerait',
    'toronnèrent',
    'toronneriez',
    'toronnerons',
    'toronneront',
    'toronneuses',
    'torpillages',
    'torpillâmes',
    'torpillasse',
    'torpillâtes',
    'torpillerai',
    'torpilleras',
    'torpillerez',
    'torpillerie',
    'torpilleurs',
    'torpillions',
    'torréfiâmes',
    'torréfiasse',
    'torréfiâtes',
    'torréfierai',
    'torréfieras',
    'torréfierez',
    'torréfiions',
    'torrentiels',
    'torrentueux',
    'torsadaient',
    'torsadantes',
    'torsadasses',
    'torsaderais',
    'torsaderait',
    'torsadèrent',
    'torsaderiez',
    'torsaderons',
    'torsaderont',
    'tortillages',
    'tortillâmes',
    'tortillards',
    'tortillasse',
    'tortillâtes',
    'tortillerai',
    'tortilleras',
    'tortillerez',
    'tortillions',
    'tortoraient',
    'tortorasses',
    'tortorerais',
    'tortorerait',
    'tortorèrent',
    'tortoreriez',
    'tortorerons',
    'tortoreront',
    'torturaient',
    'torturantes',
    'torturasses',
    'torturerais',
    'torturerait',
    'torturèrent',
    'tortureriez',
    'torturerons',
    'tortureront',
    'totalisâmes',
    'totalisasse',
    'totalisâtes',
    'totaliserai',
    'totaliseras',
    'totaliserez',
    'totaliseurs',
    'totalisions',
    'totalitaire',
    'touchassent',
    'touchassiez',
    'toucherions',
    'touillaient',
    'touillasses',
    'touillerais',
    'touillerait',
    'touillèrent',
    'touilleriez',
    'touillerons',
    'touilleront',
    'toupillâmes',
    'toupillasse',
    'toupillâtes',
    'toupillerai',
    'toupilleras',
    'toupillerez',
    'toupilleurs',
    'toupilleuse',
    'toupillions',
    'touraillage',
    'touranienne',
    'tourbassent',
    'tourbassiez',
    'tourberions',
    'tourbillons',
    'tourillonna',
    'tourillonne',
    'tourillonné',
    'touristique',
    'tourmalines',
    'tourmentais',
    'tourmentait',
    'tourmentant',
    'tourmentées',
    'tourmentent',
    'tourmentera',
    'tourmenteur',
    'tourmentiez',
    'tourmentins',
    'tourmentons',
    'tournaillai',
    'tournaillas',
    'tournaillât',
    'tournailler',
    'tournailles',
    'tournaillez',
    'tournassais',
    'tournassait',
    'tournassant',
    'tournassées',
    'tournassent',
    'tournassera',
    'tournassiez',
    'tournassons',
    'tourneboula',
    'tourneboule',
    'tourneboulé',
    'tournebride',
    'tournerions',
    'tournicotai',
    'tournicotas',
    'tournicotât',
    'tournicotée',
    'tournicoter',
    'tournicotes',
    'tournicotés',
    'tournicotez',
    'tournillais',
    'tournillait',
    'tournillant',
    'tournillent',
    'tournillera',
    'tournilliez',
    'tournillons',
    'tourniquais',
    'tourniquait',
    'tourniquant',
    'tourniquent',
    'tourniquera',
    'tourniquets',
    'tourniquiez',
    'tourniquons',
    'tournoierai',
    'tournoieras',
    'tournoierez',
    'tournoyâmes',
    'tournoyasse',
    'tournoyâtes',
    'tournoyions',
    'tourtereaux',
    'tourterelle',
    'toussaillai',
    'toussaillas',
    'toussaillât',
    'toussailler',
    'toussailles',
    'toussaillez',
    'toussassent',
    'toussassiez',
    'tousserions',
    'toussotâmes',
    'toussotasse',
    'toussotâtes',
    'toussoterai',
    'toussoteras',
    'toussoterez',
    'toussotions',
    'toute-bonne',
    'toute-épice',
    'tout-petits',
    'tout-venant',
    'toxicologie',
    'toxicologue',
    'toxicomanes',
    'toxicomanie',
    'toxoplasmes',
    'traboulâmes',
    'traboulasse',
    'traboulâtes',
    'traboulerai',
    'trabouleras',
    'traboulerez',
    'traboulions',
    'tracanaient',
    'tracanasses',
    'tracanerais',
    'tracanerait',
    'tracanèrent',
    'tracaneriez',
    'tracanerons',
    'tracaneront',
    'tracassâmes',
    'tracassasse',
    'tracassâtes',
    'tracasserai',
    'tracasseras',
    'tracasserez',
    'tracasserie',
    'tracassière',
    'tracassiers',
    'tracassions',
    'traçassions',
    'traceraient',
    'trachéennes',
    'tractassent',
    'tractassiez',
    'tractations',
    'tracterions',
    'tractoriste',
    'trade-union',
    'traducteurs',
    'traductions',
    'traductrice',
    'traduirions',
    'traduisible',
    'traduisîmes',
    'traduisions',
    'traduisisse',
    'traduisîtes',
    'traficotais',
    'traficotait',
    'traficotant',
    'traficotées',
    'traficotent',
    'traficotera',
    'traficotiez',
    'traficotons',
    'trafiquante',
    'trafiquants',
    'trafiquerai',
    'trafiqueurs',
    'trafiqueuse',
    'trafiquions',
    'tragédienne',
    'trahiraient',
    'trahissions',
    'traînaillai',
    'traînaillas',
    'traînaillât',
    'traînaillée',
    'traînailler',
    'traînailles',
    'traînaillés',
    'traînaillez',
    'traînassais',
    'traînassait',
    'traînassant',
    'traînassées',
    'traînassent',
    'traînassera',
    'traînassiez',
    'traînassons',
    'traînements',
    'traînerions',
    'train-train',
    'traitassent',
    'traitassiez',
    'traitements',
    'traiterions',
    'traîtresses',
    'trajectoire',
    'tramassions',
    'trameraient',
    'tramontanes',
    'trampolines',
    'tranchaient',
    'tranchantes',
    'tranchasses',
    'tranchefila',
    'tranchefile',
    'tranchefilé',
    'trancherais',
    'trancherait',
    'tranchèrent',
    'trancheriez',
    'trancherons',
    'trancheront',
    'trancheuses',
    'tranquilles',
    'transaction',
    'transalpine',
    'transalpins',
    'transandine',
    'transandins',
    'transbahuta',
    'transbahute',
    'transbahuté',
    'transbordai',
    'transbordas',
    'transbordât',
    'transbordée',
    'transborder',
    'transbordes',
    'transbordés',
    'transbordez',
    'transcendai',
    'transcendas',
    'transcendât',
    'transcendée',
    'transcender',
    'transcendes',
    'transcendés',
    'transcendez',
    'transcodage',
    'transcodais',
    'transcodait',
    'transcodant',
    'transcodées',
    'transcodent',
    'transcodera',
    'transcodeur',
    'transcodiez',
    'transcodons',
    'transcrirai',
    'transcriras',
    'transcrirez',
    'transcrites',
    'transcrives',
    'transcrivez',
    'transcrivis',
    'transcrivit',
    'transcrivît',
    'transférais',
    'transférait',
    'transférant',
    'transférées',
    'transfèrent',
    'transférera',
    'transfériez',
    'transférons',
    'transfigura',
    'transfigure',
    'transfiguré',
    'transfilais',
    'transfilait',
    'transfilant',
    'transfilées',
    'transfilent',
    'transfilera',
    'transfiliez',
    'transfilons',
    'transfinies',
    'transfixion',
    'transformai',
    'transformas',
    'transformât',
    'transformée',
    'transformer',
    'transformes',
    'transformés',
    'transformez',
    'transfusais',
    'transfusait',
    'transfusant',
    'transfusées',
    'transfusent',
    'transfusera',
    'transfusiez',
    'transfusion',
    'transfusons',
    'transgressa',
    'transgresse',
    'transgressé',
    'transhumais',
    'transhumait',
    'transhumant',
    'transhumées',
    'transhument',
    'transhumera',
    'transhumiez',
    'transhumons',
    'transigeais',
    'transigeait',
    'transigeant',
    'transigeons',
    'transigerai',
    'transigeras',
    'transigerez',
    'transigions',
    'transirions',
    'transissais',
    'transissait',
    'transissant',
    'transissent',
    'transissiez',
    'transissons',
    'transistors',
    'transitaire',
    'transitâmes',
    'transitasse',
    'transitâtes',
    'transiterai',
    'transiteras',
    'transiterez',
    'transitions',
    'transitives',
    'transitoire',
    'translatais',
    'translatait',
    'translatant',
    'translatées',
    'translatent',
    'translatera',
    'translatiez',
    'translatifs',
    'translation',
    'translative',
    'translatons',
    'translitéra',
    'translitéré',
    'translitère',
    'translucide',
    'transmettes',
    'transmettez',
    'transmettra',
    'transmettre',
    'transmigrai',
    'transmigras',
    'transmigrât',
    'transmigrer',
    'transmigres',
    'transmigrez',
    'transmirent',
    'transmisses',
    'transmuable',
    'transmuâmes',
    'transmuasse',
    'transmuâtes',
    'transmuerai',
    'transmueras',
    'transmuerez',
    'transmuions',
    'transmutais',
    'transmutait',
    'transmutant',
    'transmutées',
    'transmutent',
    'transmutera',
    'transmutiez',
    'transmutons',
    'transparais',
    'transparaît',
    'transparent',
    'transparues',
    'transperçai',
    'transperças',
    'transperçât',
    'transpercée',
    'transpercer',
    'transperces',
    'transpercés',
    'transpercez',
    'transpirais',
    'transpirait',
    'transpirant',
    'transpirent',
    'transpirera',
    'transpiriez',
    'transpirons',
    'transplanta',
    'transplante',
    'transplanté',
    'transplants',
    'transportai',
    'transportas',
    'transportât',
    'transportée',
    'transporter',
    'transportes',
    'transportés',
    'transportez',
    'transposais',
    'transposait',
    'transposant',
    'transposées',
    'transposent',
    'transposera',
    'transposiez',
    'transposons',
    'transsexuel',
    'transsudais',
    'transsudait',
    'transsudant',
    'transsudats',
    'transsudées',
    'transsudent',
    'transsudera',
    'transsudiez',
    'transsudons',
    'transvasais',
    'transvasait',
    'transvasant',
    'transvasées',
    'transvasent',
    'transvasera',
    'transvasiez',
    'transvasons',
    'transversal',
    'transvidais',
    'transvidait',
    'transvidant',
    'transvidées',
    'transvident',
    'transvidera',
    'transvidiez',
    'transvidons',
    'transylvain',
    'trapézistes',
    'trapézoïdal',
    'trapézoïdes',
    'trappillons',
    'trappistine',
    'traquassent',
    'traquassiez',
    'traquenards',
    'traquerions',
    'traumatique',
    'traumatisai',
    'traumatisas',
    'traumatisât',
    'traumatisée',
    'traumatiser',
    'traumatises',
    'traumatisés',
    'traumatisez',
    'traumatisme',
    'travaillais',
    'travaillait',
    'travaillant',
    'travaillées',
    'travaillent',
    'travaillera',
    'travailleur',
    'travailliez',
    'travaillons',
    'travellings',
    'traversable',
    'traversâmes',
    'traversante',
    'traversants',
    'traversasse',
    'traversâtes',
    'traverserai',
    'traverseras',
    'traverserez',
    'traversière',
    'traversiers',
    'traversions',
    'travestîmes',
    'travestirai',
    'travestiras',
    'travestirez',
    'travestisme',
    'travestisse',
    'travestîtes',
    'trébuchâmes',
    'trébuchante',
    'trébuchants',
    'trébuchasse',
    'trébuchâtes',
    'trébucherai',
    'trébucheras',
    'trébucherez',
    'trébuchions',
    'tréfilaient',
    'tréfilasses',
    'tréfilerais',
    'tréfilerait',
    'tréfilèrent',
    'tréfileries',
    'tréfileriez',
    'tréfilerons',
    'tréfileront',
    'tréfileuses',
    'treillageai',
    'treillageas',
    'treillageât',
    'treillagées',
    'treillagent',
    'treillagera',
    'treillageur',
    'treillagiez',
    'treillissai',
    'treillissas',
    'treillissât',
    'treillissée',
    'treillisser',
    'treillisses',
    'treillissés',
    'treillissez',
    'trélingages',
    'trémataient',
    'trématasses',
    'trématerais',
    'trématerait',
    'trématèrent',
    'trémateriez',
    'trématerons',
    'trémateront',
    'tremblaient',
    'tremblantes',
    'tremblasses',
    'tremblement',
    'tremblerais',
    'tremblerait',
    'tremblèrent',
    'trembleriez',
    'tremblerons',
    'trembleront',
    'trembleuses',
    'tremblotais',
    'tremblotait',
    'tremblotant',
    'tremblotent',
    'tremblotera',
    'tremblotiez',
    'tremblotons',
    'trémoussais',
    'trémoussait',
    'trémoussant',
    'trémoussent',
    'trémoussera',
    'trémoussiez',
    'trémoussons',
    'trempassent',
    'trempassiez',
    'tremperions',
    'trémulaient',
    'trémulasses',
    'trémulation',
    'trémulerais',
    'trémulerait',
    'trémulèrent',
    'trémuleriez',
    'trémulerons',
    'trémuleront',
    'trench-coat',
    'trente-cinq',
    'trente-deux',
    'trente-huit',
    'trentenaire',
    'trente-neuf',
    'trente-sept',
    'trépanaient',
    'trépanasses',
    'trépanation',
    'trépanerais',
    'trépanerait',
    'trépanèrent',
    'trépaneriez',
    'trépanerons',
    'trépaneront',
    'trépasserai',
    'trépasseras',
    'trépasserez',
    'trépassions',
    'tréphocytes',
    'trépidaient',
    'trépidantes',
    'trépidasses',
    'trépidation',
    'trépiderais',
    'trépiderait',
    'trépidèrent',
    'trépideriez',
    'trépiderons',
    'trépideront',
    'trépignâmes',
    'trépignasse',
    'trépignâtes',
    'trépignerai',
    'trépigneras',
    'trépignerez',
    'trépigneuse',
    'trépignions',
    'trésoreries',
    'trésorières',
    'tressailles',
    'tressaillez',
    'tressaillie',
    'tressaillir',
    'tressaillis',
    'tressaillit',
    'tressaillît',
    'tressassent',
    'tressassiez',
    'tressautais',
    'tressautait',
    'tressautant',
    'tressautent',
    'tressautera',
    'tressautiez',
    'tressautons',
    'tresserions',
    'treuillages',
    'treuillâmes',
    'treuillasse',
    'treuillâtes',
    'treuillerai',
    'treuilleras',
    'treuillerez',
    'treuillions',
    'tréviraient',
    'trévirasses',
    'trévirerais',
    'trévirerait',
    'trévirèrent',
    'trévireriez',
    'trévirerons',
    'trévireront',
    'triangulais',
    'triangulait',
    'triangulant',
    'triangulées',
    'triangulent',
    'triangulera',
    'trianguliez',
    'triangulons',
    'triatomique',
    'tribalismes',
    'triballâmes',
    'triballasse',
    'triballâtes',
    'triballerai',
    'triballeras',
    'triballerez',
    'triballions',
    'tribasiques',
    'tribologies',
    'tribomètres',
    'tribométrie',
    'tribulation',
    'tribunitien',
    'tributaires',
    'tricennales',
    'tricéphales',
    'tricératops',
    'trichassent',
    'trichassiez',
    'tricherions',
    'trichineuse',
    'trichinoses',
    'tricholomes',
    'trichomonas',
    'trichromies',
    'triclinique',
    'tricliniums',
    'tricotaient',
    'tricotasses',
    'tricoterais',
    'tricoterait',
    'tricotèrent',
    'tricoteriez',
    'tricoterons',
    'tricoteront',
    'tricoteuses',
    'tricuspides',
    'tridactyles',
    'triérarques',
    'trifoliolée',
    'trifoliolés',
    'trifouillai',
    'trifouillas',
    'trifouillât',
    'trifouillée',
    'trifouiller',
    'trifouilles',
    'trifouillés',
    'trifouillez',
    'trigéminées',
    'trigonelles',
    'trilatérale',
    'trilatéraux',
    'trillassent',
    'trillassiez',
    'trillerions',
    'trimardâmes',
    'trimardasse',
    'trimardâtes',
    'trimarderai',
    'trimarderas',
    'trimarderez',
    'trimardeurs',
    'trimardions',
    'trimassions',
    'trimbalages',
    'trimbalâmes',
    'trimbalasse',
    'trimbalâtes',
    'trimbalerai',
    'trimbaleras',
    'trimbalerez',
    'trimbalions',
    'trimballage',
    'trimballais',
    'trimballait',
    'trimballant',
    'trimballées',
    'trimballent',
    'trimballera',
    'trimballiez',
    'trimballons',
    'trimeraient',
    'trimestriel',
    'tringlaient',
    'tringlasses',
    'tringlerais',
    'tringlerait',
    'tringlèrent',
    'tringleriez',
    'tringlerons',
    'tringleront',
    'trinitaires',
    'trinquaient',
    'trinquasses',
    'trinquerais',
    'trinquerait',
    'trinquèrent',
    'trinqueriez',
    'trinquerons',
    'trinqueront',
    'trinquettes',
    'triomphales',
    'triomphâmes',
    'triomphante',
    'triomphants',
    'triomphasse',
    'triomphâtes',
    'triompherai',
    'triompheras',
    'triompherez',
    'triomphions',
    'tripartisme',
    'tripartites',
    'triplassent',
    'triplassiez',
    'triplements',
    'triplerions',
    'triplicatas',
    'triploïdies',
    'triporteurs',
    'tripotaient',
    'tripotasses',
    'tripoterais',
    'tripoterait',
    'tripotèrent',
    'tripoteriez',
    'tripoterons',
    'tripoteront',
    'tripoteuses',
    'triquassent',
    'triquassiez',
    'triqueballe',
    'triquerions',
    'trisaïeules',
    'trisannuels',
    'trisecteurs',
    'trisections',
    'trisectrice',
    'triséquâmes',
    'triséquasse',
    'triséquâtes',
    'triséquerai',
    'triséqueras',
    'triséquerez',
    'triséquions',
    'trismégiste',
    'trisomiques',
    'trissassent',
    'trissassiez',
    'trisserions',
    'tristounets',
    'trisyllabes',
    'triturables',
    'trituraient',
    'triturasses',
    'triturateur',
    'trituration',
    'triturerais',
    'triturerait',
    'triturèrent',
    'tritureriez',
    'triturerons',
    'tritureront',
    'triumvirale',
    'triumvirats',
    'triumviraux',
    'trivalentes',
    'trivialités',
    'trochaïques',
    'trochanters',
    'troglodytes',
    'trombidions',
    'trombidiose',
    'tromboniste',
    'trompassent',
    'trompassiez',
    'tromperions',
    'trompetâmes',
    'trompetasse',
    'trompetâtes',
    'trompetions',
    'trompettent',
    'trompettera',
    'trompillons',
    'trônassions',
    'troncatures',
    'tronçonnage',
    'tronçonnais',
    'tronçonnait',
    'tronçonnant',
    'tronçonnées',
    'tronçonnent',
    'tronçonnera',
    'tronçonneur',
    'tronçonniez',
    'tronçonnons',
    'tronculaire',
    'trôneraient',
    'tronquaient',
    'tronquasses',
    'tronquerais',
    'tronquerait',
    'tronquèrent',
    'tronqueriez',
    'tronquerons',
    'tronqueront',
    'tropicalisa',
    'tropicalise',
    'tropicalisé',
    'tropopauses',
    'troposphère',
    'trop-perçus',
    'trop-pleins',
    'troquassent',
    'troquassiez',
    'troquerions',
    'trotskismes',
    'trotskistes',
    'trotskystes',
    'trottassent',
    'trottassiez',
    'trotterions',
    'trottinâmes',
    'trottinante',
    'trottinants',
    'trottinasse',
    'trottinâtes',
    'trottinerai',
    'trottineras',
    'trottinerez',
    'trottinette',
    'trottinions',
    'trouassions',
    'troubadours',
    'troublaient',
    'troublantes',
    'troublasses',
    'troublerais',
    'troublerait',
    'troublèrent',
    'troubleriez',
    'troublerons',
    'troubleront',
    'troueraient',
    'troufignons',
    'trouillarde',
    'trouillards',
    'trou-madame',
    'troussaient',
    'troussasses',
    'trousse-pet',
    'troussequin',
    'trousserais',
    'trousserait',
    'troussèrent',
    'trousseriez',
    'trousserons',
    'trousseront',
    'trouvailles',
    'trouvassent',
    'trouvassiez',
    'trouverions',
    'truandaient',
    'truandasses',
    'truanderais',
    'truanderait',
    'truandèrent',
    'truanderies',
    'truanderiez',
    'truanderons',
    'truanderont',
    'truchements',
    'trucidaient',
    'trucidasses',
    'truciderais',
    'truciderait',
    'trucidèrent',
    'trucideriez',
    'truciderons',
    'trucideront',
    'truculences',
    'truculentes',
    'truffassent',
    'truffassiez',
    'trufferions',
    'truquassent',
    'truquassiez',
    'truquerions',
    'trusquinais',
    'trusquinait',
    'trusquinant',
    'trusquinées',
    'trusquinent',
    'trusquinera',
    'trusquiniez',
    'trusquinons',
    'trustassent',
    'trustassiez',
    'trusterions',
    'trypanosome',
    'tryptophane',
    'tsarévitchs',
    'tsoin-tsoin',
    'tuberculeux',
    'tuberculide',
    'tuberculina',
    'tuberculine',
    'tuberculiné',
    'tuberculisa',
    'tuberculise',
    'tuberculisé',
    'tuberculose',
    'tubériforme',
    'tubérosités',
    'tubuliflore',
    'tue-mouches',
    'tuilassions',
    'tuileraient',
    'tuméfaction',
    'tuméfiaient',
    'tuméfiasses',
    'tuméfierais',
    'tuméfierait',
    'tuméfièrent',
    'tuméfieriez',
    'tuméfierons',
    'tuméfieront',
    'tumescences',
    'tumescentes',
    'tumultueuse',
    'tungstiques',
    'tunisiennes',
    'turbinaient',
    'turbinasses',
    'turbinelles',
    'turbinerais',
    'turbinerait',
    'turbinèrent',
    'turbineriez',
    'turbinerons',
    'turbineront',
    'turbofiltre',
    'turbomoteur',
    'turbopompes',
    'turbotières',
    'turbo-train',
    'turbotrains',
    'turbulences',
    'turbulentes',
    'turgescence',
    'turgescente',
    'turgescents',
    'turlupinais',
    'turlupinait',
    'turlupinant',
    'turlupinées',
    'turlupinent',
    'turlupinera',
    'turlupiniez',
    'turlupinons',
    'turlurettes',
    'turlutaines',
    'turoniennes',
    'turpidement',
    'turriculées',
    'turritelles',
    'tuteuraient',
    'tuteurasses',
    'tuteurerais',
    'tuteurerait',
    'tuteurèrent',
    'tuteureriez',
    'tuteurerons',
    'tuteureront',
    'tutoiements',
    'tutoierions',
    'tutoyassent',
    'tutoyassiez',
    'tuyautaient',
    'tuyautasses',
    'tuyauterais',
    'tuyauterait',
    'tuyautèrent',
    'tuyauteries',
    'tuyauteriez',
    'tuyauterons',
    'tuyauteront',
    'tuyauteuses',
    'twistassent',
    'twistassiez',
    'twisterions',
    'tympaniques',
    'tympanisais',
    'tympanisait',
    'tympanisant',
    'tympanisées',
    'tympanisent',
    'tympanisera',
    'tympanisiez',
    'tympanismes',
    'tympanisons',
    'typhoïdique',
    'typhomycine',
    'typiquement',
    'typisassent',
    'typisassiez',
    'typiserions',
    'typochromie',
    'typographes',
    'typographie',
    'typologique',
    'typtologies',
    'tyrannicide',
    'tyranniques',
    'tyrannisais',
    'tyrannisait',
    'tyrannisant',
    'tyrannisées',
    'tyrannisent',
    'tyrannisera',
    'tyrannisiez',
    'tyrannisons',
    'tyroliennes',
    'ukrainienne',
    'ulcérassent',
    'ulcérassiez',
    'ulcérations',
    'ulcératives',
    'ulcérerions',
    'uliginaires',
    'ultérieures',
    'ultra-court',
    'ultracourte',
    'ultracourts',
    'ultrafiltre',
    'ultraviolet',
    'ululassions',
    'ululeraient',
    'unanimement',
    'unanimismes',
    'unanimistes',
    'underground',
    'unifiassent',
    'unifiassiez',
    'unificateur',
    'unification',
    'unifierions',
    'unifilaires',
    'uniformisai',
    'uniformisas',
    'uniformisât',
    'uniformisée',
    'uniformiser',
    'uniformises',
    'uniformisés',
    'uniformisez',
    'uniformités',
    'unijambiste',
    'unilatérale',
    'unilatéraux',
    'unilinéaire',
    'uninominale',
    'uninominaux',
    'unitarienne',
    'unitarismes',
    'universelle',
    'universités',
    'univitellin',
    'upérisation',
    'uranoscopes',
    'urbanisâmes',
    'urbanisasse',
    'urbanisâtes',
    'urbaniserai',
    'urbaniseras',
    'urbaniserez',
    'urbanisions',
    'urédospores',
    'urinassions',
    'urineraient',
    'uro-génital',
    'urographies',
    'uropygiales',
    'uropygienne',
    'urtications',
    'uruguayenne',
    'usinassions',
    'usineraient',
    'usuellement',
    'usufruitier',
    'usurpassent',
    'usurpassiez',
    'usurpateurs',
    'usurpations',
    'usurpatoire',
    'usurpatrice',
    'usurperions',
    'utilisables',
    'utilisaient',
    'utilisasses',
    'utilisateur',
    'utilisation',
    'utiliserais',
    'utiliserait',
    'utilisèrent',
    'utiliseriez',
    'utiliserons',
    'utiliseront',
    'utilitaires',
    'utriculaire',
    'utriculeuse',
    'uxorilocale',
    'uxorilocaux',
    'vacancières',
    'vaccinables',
    'vaccinaient',
    'vaccinasses',
    'vaccinateur',
    'vaccination',
    'vaccinelles',
    'vaccinerais',
    'vaccinerait',
    'vaccinèrent',
    'vaccineriez',
    'vaccinerons',
    'vaccineront',
    'vaccinifère',
    'vaccinogène',
    'vaccinoïdes',
    'vacillaient',
    'vacillantes',
    'vacillasses',
    'vacillation',
    'vacillement',
    'vacillerais',
    'vacillerait',
    'vacillèrent',
    'vacilleriez',
    'vacillerons',
    'vacilleront',
    'vacuolaires',
    'vacuolisais',
    'vacuolisait',
    'vacuolisant',
    'vacuolisées',
    'vacuolisent',
    'vacuolisera',
    'vacuolisiez',
    'vacuolisons',
    'vadrouillai',
    'vadrouillas',
    'vadrouillât',
    'vadrouillée',
    'vadrouiller',
    'vadrouilles',
    'vadrouillés',
    'vadrouillez',
    'va-et-vient',
    'vagabondage',
    'vagabondais',
    'vagabondait',
    'vagabondant',
    'vagabondent',
    'vagabondera',
    'vagabondiez',
    'vagabondons',
    'vagissaient',
    'vagissantes',
    'vagissement',
    'vagolytique',
    'vagotonique',
    'vaguassions',
    'vaguelettes',
    'vaguemestre',
    'vagueraient',
    'vaillamment',
    'vaillanties',
    'vaincraient',
    'vainquaient',
    'vainquirent',
    'vainquisses',
    'vaironnâmes',
    'vaironnasse',
    'vaironnâtes',
    'vaironnerai',
    'vaironneras',
    'vaironnerez',
    'vaironnions',
    'vaisseliers',
    'valablement',
    'valdinguais',
    'valdinguait',
    'valdinguant',
    'valdinguent',
    'valdinguera',
    'valdinguiez',
    'valdinguons',
    'valentinite',
    'valetailles',
    'valetassent',
    'valetassiez',
    'valeterions',
    'valeureuses',
    'validassent',
    'validassiez',
    'validations',
    'validerions',
    'valisassent',
    'valisassiez',
    'valiserions',
    'vallisnérie',
    'vallonnâmes',
    'vallonnasse',
    'vallonnâtes',
    'vallonnerai',
    'vallonneras',
    'vallonnerez',
    'vallonnions',
    'valorisâmes',
    'valorisante',
    'valorisants',
    'valorisasse',
    'valorisâtes',
    'valoriserai',
    'valoriseras',
    'valoriserez',
    'valorisions',
    'valousaient',
    'valousasses',
    'valouserais',
    'valouserait',
    'valousèrent',
    'valouseriez',
    'valouserons',
    'valouseront',
    'valsassions',
    'valseraient',
    'valvulaires',
    'vampassions',
    'vamperaient',
    'vampiriques',
    'vampirisais',
    'vampirisait',
    'vampirisant',
    'vampirisées',
    'vampirisent',
    'vampirisera',
    'vampirisiez',
    'vampirismes',
    'vampirisons',
    'vanadinites',
    'vandalisais',
    'vandalisait',
    'vandalisant',
    'vandalisées',
    'vandalisent',
    'vandalisera',
    'vandalisiez',
    'vandalismes',
    'vandalisons',
    'vanillismes',
    'vannassions',
    'vanneraient',
    'vantardises',
    'vantassions',
    'vanteraient',
    'va-nu-pieds',
    'vaporisages',
    'vaporisâmes',
    'vaporisasse',
    'vaporisâtes',
    'vaporiserai',
    'vaporiseras',
    'vaporiserez',
    'vaporisions',
    'vaquassions',
    'vaqueraient',
    'varappaient',
    'varappasses',
    'varapperais',
    'varapperait',
    'varappèrent',
    'varapperiez',
    'varapperons',
    'varapperont',
    'varappeuses',
    'variabilité',
    'variassions',
    'varicocèles',
    'varieraient',
    'varioleuses',
    'varioliques',
    'variqueuses',
    'varlopaient',
    'varlopasses',
    'varloperais',
    'varloperait',
    'varlopèrent',
    'varloperiez',
    'varloperons',
    'varloperont',
    'vasculaires',
    'vascularisé',
    'vasectomies',
    'vaselinâmes',
    'vaselinasse',
    'vaselinâtes',
    'vaselinerai',
    'vaselineras',
    'vaselinerez',
    'vaselinions',
    'vasomoteurs',
    'vasomotrice',
    'vasouillais',
    'vasouillait',
    'vasouillant',
    'vasouillard',
    'vasouillent',
    'vasouillera',
    'vasouilliez',
    'vasouillons',
    'vassalisais',
    'vassalisait',
    'vassalisant',
    'vassalisées',
    'vassalisent',
    'vassalisera',
    'vassalisiez',
    'vassalisons',
    'vaticinâmes',
    'vaticinasse',
    'vaticinâtes',
    'vaticinerai',
    'vaticineras',
    'vaticinerez',
    'vaticinions',
    'vauclusiens',
    'vaudevilles',
    'vautrassent',
    'vautrassiez',
    'vautrerions',
    'vectorielle',
    'vedettariat',
    'végétalisme',
    'végétariens',
    'végétarisme',
    'végétassent',
    'végétassiez',
    'végétations',
    'végétatives',
    'végéterions',
    'véhiculaire',
    'véhiculâmes',
    'véhiculasse',
    'véhiculâtes',
    'véhiculerai',
    'véhiculeras',
    'véhiculerez',
    'véhiculions',
    'veillassent',
    'veillassiez',
    'veillerions',
    'veinassions',
    'veineraient',
    'vélarisâmes',
    'vélarisasse',
    'vélarisâtes',
    'vélariserai',
    'vélariseras',
    'vélariserez',
    'vélarisions',
    'velléitaire',
    'vélocipèdes',
    'vélomoteurs',
    'veloutaient',
    'veloutasses',
    'veloutement',
    'velouterais',
    'velouterait',
    'veloutèrent',
    'velouteriez',
    'velouterons',
    'velouteront',
    'velouteuses',
    'vendangeais',
    'vendangeait',
    'vendangeant',
    'vendangeoir',
    'vendangeons',
    'vendangerai',
    'vendangeras',
    'vendangerez',
    'vendangerot',
    'vendangette',
    'vendangeurs',
    'vendangeuse',
    'vendangions',
    'vendémiaire',
    'vendissions',
    'vénérassent',
    'vénérassiez',
    'vénérations',
    'vénérerions',
    'vénéricarde',
    'vénériennes',
    'vengeassent',
    'vengeassiez',
    'vengeraient',
    'vengeresses',
    'venimosités',
    'vénitiennes',
    'ventilaient',
    'ventilasses',
    'ventilateur',
    'ventilation',
    'ventilerais',
    'ventilerait',
    'ventilèrent',
    'ventileriez',
    'ventilerons',
    'ventileront',
    'ventileuses',
    'ventousâmes',
    'ventousasse',
    'ventousâtes',
    'ventouserai',
    'ventouseras',
    'ventouserez',
    'ventousions',
    'ventricules',
    'ventriloque',
    'vénusiennes',
    'verbalement',
    'verbalisais',
    'verbalisait',
    'verbalisant',
    'verbalisées',
    'verbalisent',
    'verbalisera',
    'verbalisiez',
    'verbalismes',
    'verbalisons',
    'verbiageais',
    'verbiageait',
    'verbiageant',
    'verbiageons',
    'verbiagerai',
    'verbiageras',
    'verbiagerez',
    'verbiagions',
    'verbomanies',
    'verdelettes',
    'verdiraient',
    'verdissages',
    'verdissions',
    'verdoiement',
    'verdoierais',
    'verdoierait',
    'verdoieriez',
    'verdoierons',
    'verdoieront',
    'verdoyaient',
    'verdoyantes',
    'verdoyasses',
    'verdoyèrent',
    'verdunisais',
    'verdunisait',
    'verdunisant',
    'verdunisées',
    'verdunisent',
    'verdunisera',
    'verdunisiez',
    'verdunisons',
    'vergerettes',
    'véridicités',
    'vérifiables',
    'vérifiaient',
    'vérifiasses',
    'vérificatif',
    'vérifierais',
    'vérifierait',
    'vérifièrent',
    'vérifieriez',
    'vérifierons',
    'vérifieront',
    'vérifieuses',
    'verjutaient',
    'verjutasses',
    'verjuterais',
    'verjuterait',
    'verjutèrent',
    'verjuteriez',
    'verjuterons',
    'verjuteront',
    'vermicelles',
    'vermiculais',
    'vermiculait',
    'vermiculant',
    'vermiculées',
    'vermiculent',
    'vermiculera',
    'vermiculiez',
    'vermiculons',
    'vermiculure',
    'vermiformes',
    'vermillâmes',
    'vermillasse',
    'vermillâtes',
    'vermillerai',
    'vermilleras',
    'vermillerez',
    'vermillions',
    'vermillonna',
    'vermillonne',
    'vermillonné',
    'vermineuses',
    'vermisseaux',
    'vermoulâmes',
    'vermoulasse',
    'vermoulâtes',
    'vermoulerai',
    'vermouleras',
    'vermoulerez',
    'vermoulions',
    'vermoulures',
    'verniraient',
    'vernissages',
    'vernissâmes',
    'vernissasse',
    'vernissâtes',
    'vernisserai',
    'vernisseras',
    'vernisserez',
    'vernisseurs',
    'vernisseuse',
    'vernissions',
    'verroteries',
    'verrouillai',
    'verrouillas',
    'verrouillât',
    'verrouillée',
    'verrouiller',
    'verrouilles',
    'verrouillés',
    'verrouillez',
    'verrucosité',
    'verruqueuse',
    'versaillais',
    'versassions',
    'versatilité',
    'verseraient',
    'versifiâmes',
    'versifiasse',
    'versifiâtes',
    'versifierai',
    'versifieras',
    'versifierez',
    'versifiions',
    'vertébrales',
    'verticalité',
    'verticillée',
    'verticilles',
    'verticillés',
    'vertigineux',
    'vertugadins',
    'vésications',
    'vésicatoire',
    'vésiculaire',
    'vésiculeuse',
    'vespasienne',
    'vessassions',
    'vesseraient',
    'vétérinaire',
    'vétillaient',
    'vétillardes',
    'vétillasses',
    'vétillerais',
    'vétillerait',
    'vétillèrent',
    'vétilleriez',
    'vétillerons',
    'vétilleront',
    'vétilleuses',
    'veveysannes',
    'vexillaires',
    'viabilisais',
    'viabilisait',
    'viabilisant',
    'viabilisées',
    'viabilisent',
    'viabilisera',
    'viabilisiez',
    'viabilisons',
    'viandassent',
    'viandassiez',
    'vianderions',
    'vibraphones',
    'vibrassions',
    'vibratoires',
    'vibreraient',
    'vibrionnais',
    'vibrionnait',
    'vibrionnant',
    'vibrionnent',
    'vibrionnera',
    'vibrionniez',
    'vibrionnons',
    'vicariantes',
    'vice-amiral',
    'vice-consul',
    'vice-légats',
    'vice-reines',
    'vicésimales',
    'vichyssoise',
    'viciassions',
    'viciatrices',
    'vicieraient',
    'vicinalités',
    'vicissitude',
    'vicomtesses',
    'victimaires',
    'victorienne',
    'victorieuse',
    'victuailles',
    'vidangeâmes',
    'vidangeasse',
    'vidangeâtes',
    'vidangerais',
    'vidangerait',
    'vidangèrent',
    'vidangeriez',
    'vidangerons',
    'vidangeront',
    'vidéodisque',
    'vidéophonie',
    'vidéothèque',
    'vide-poches',
    'vide-pommes',
    'vidimassent',
    'vidimassiez',
    'vidimerions',
    'vieilleries',
    'vieillesses',
    'vieillirais',
    'vieillirait',
    'vieillirent',
    'vieilliriez',
    'vieillirons',
    'vieilliront',
    'vieillisses',
    'vieillissez',
    'vieillottes',
    'viellassent',
    'viellassiez',
    'viellerions',
    'viendraient',
    'vietnamiens',
    'vigilamment',
    'vigneronnes',
    'vignettiste',
    'vigoureuses',
    'vilainement',
    'vilebrequin',
    'vilipendais',
    'vilipendait',
    'vilipendant',
    'vilipendées',
    'vilipendent',
    'vilipendera',
    'vilipendiez',
    'vilipendons',
    'villageoise',
    'villanelles',
    'vinaigrâmes',
    'vinaigrasse',
    'vinaigrâtes',
    'vinaigrerai',
    'vinaigreras',
    'vinaigrerez',
    'vinaigrerie',
    'vinaigrette',
    'vinaigriers',
    'vinaigrions',
    'vindicatifs',
    'vindicative',
    'vingt-et-un',
    'vingt-trois',
    'viniculture',
    'vinifiaient',
    'vinifiasses',
    'vinifierais',
    'vinifierait',
    'vinifièrent',
    'vinifieriez',
    'vinifierons',
    'vinifieront',
    'violaçaient',
    'violaçasses',
    'violacerais',
    'violacerait',
    'violacèrent',
    'violaceriez',
    'violacerons',
    'violaceront',
    'violassions',
    'violatrices',
    'violentâmes',
    'violentasse',
    'violentâtes',
    'violenterai',
    'violenteras',
    'violenterez',
    'violentions',
    'violeraient',
    'violonaient',
    'violonasses',
    'violoncelle',
    'violonerais',
    'violonerait',
    'violonèrent',
    'violoneriez',
    'violonerons',
    'violoneront',
    'violonistes',
    'vioquirions',
    'vioquissais',
    'vioquissait',
    'vioquissant',
    'vioquissent',
    'vioquissiez',
    'vioquissons',
    'virescences',
    'virevoltais',
    'virevoltait',
    'virevoltant',
    'virevoltent',
    'virevoltera',
    'virevoltiez',
    'virevoltons',
    'virgulaient',
    'virgulasses',
    'virgulerais',
    'virgulerait',
    'virgulèrent',
    'virguleriez',
    'virgulerons',
    'virguleront',
    'virilisâmes',
    'virilisasse',
    'virilisâtes',
    'viriliserai',
    'viriliseras',
    'viriliserez',
    'virilisions',
    'virilocales',
    'virolassent',
    'virolassiez',
    'virolerions',
    'virologiste',
    'virtualités',
    'virtuosités',
    'visibilités',
    'visiblement',
    'visionnaire',
    'visionnâmes',
    'visionnasse',
    'visionnâtes',
    'visionnerai',
    'visionneras',
    'visionnerez',
    'visionneuse',
    'visionnions',
    'visiophones',
    'visiophonie',
    'visitandine',
    'visitassent',
    'visitassiez',
    'visitations',
    'visiterions',
    'visonnières',
    'vissassions',
    'visseraient',
    'visualisais',
    'visualisait',
    'visualisant',
    'visualisées',
    'visualisent',
    'visualisera',
    'visualisiez',
    'visualisons',
    'vitaminique',
    'viticulteur',
    'viticulture',
    'vitrassions',
    'vitreraient',
    'vitrifiable',
    'vitrifiâmes',
    'vitrifiante',
    'vitrifiants',
    'vitrifiasse',
    'vitrifiâtes',
    'vitrifierai',
    'vitrifieras',
    'vitrifierez',
    'vitrifiions',
    'vitriolages',
    'vitriolâmes',
    'vitriolasse',
    'vitriolâtes',
    'vitriolerai',
    'vitrioleras',
    'vitriolerez',
    'vitrioleurs',
    'vitrioleuse',
    'vitriolions',
    'vitupérâmes',
    'vitupérasse',
    'vitupérâtes',
    'vitupérerai',
    'vitupéreras',
    'vitupérerez',
    'vitupérions',
    'vivandières',
    'vivifiaient',
    'vivifiantes',
    'vivifiasses',
    'vivifierais',
    'vivifierait',
    'vivifièrent',
    'vivifieriez',
    'vivifierons',
    'vivifieront',
    'viviparités',
    'vivisection',
    'vivotassent',
    'vivotassiez',
    'vivoterions',
    'vocabulaire',
    'vocalisâmes',
    'vocalisasse',
    'vocalisâtes',
    'vocaliserai',
    'vocaliseras',
    'vocaliserez',
    'vocalisions',
    'vociférâmes',
    'vociférasse',
    'vociférâtes',
    'vociférerai',
    'vociféreras',
    'vociférerez',
    'vociférions',
    'voguassions',
    'vogueraient',
    'voilassions',
    'voileraient',
    'voisinaient',
    'voisinasses',
    'voisinerais',
    'voisinerait',
    'voisinèrent',
    'voisineriez',
    'voisinerons',
    'voisineront',
    'voituraient',
    'voiturasses',
    'voiturerais',
    'voiturerait',
    'voiturèrent',
    'voitureriez',
    'voiturerons',
    'voitureront',
    'voiturettes',
    'volailleurs',
    'volailleuse',
    'volatilisai',
    'volatilisas',
    'volatilisât',
    'volatilisée',
    'volatiliser',
    'volatilises',
    'volatilisés',
    'volatilisez',
    'volatilités',
    'vol-au-vent',
    'volcaniques',
    'volcanisais',
    'volcanisait',
    'volcanisant',
    'volcanisées',
    'volcanisent',
    'volcanisera',
    'volcanisiez',
    'volcanismes',
    'volcanisons',
    'voletassent',
    'voletassiez',
    'volettement',
    'voletterais',
    'voletterait',
    'voletteriez',
    'voletterons',
    'voletteront',
    'voligeaient',
    'voligeasses',
    'voligerions',
    'volley-ball',
    'volleyeuses',
    'volontaires',
    'volontariat',
    'voltairiens',
    'voltamètres',
    'voltampères',
    'voltassions',
    'volteraient',
    'voltigeâmes',
    'voltigeasse',
    'voltigeâtes',
    'voltigement',
    'voltigerais',
    'voltigerait',
    'voltigèrent',
    'voltigeriez',
    'voltigerons',
    'voltigeront',
    'voltigeuses',
    'volubilités',
    'volumétries',
    'volumineuse',
    'voluptuaire',
    'voluptueuse',
    'vomériennes',
    'vomissaient',
    'vomissement',
    'vorticelles',
    'voulussions',
    'vousoierais',
    'vousoierait',
    'vousoieriez',
    'vousoierons',
    'vousoieront',
    'vousoyaient',
    'vousoyasses',
    'vousoyèrent',
    'voussoierai',
    'voussoieras',
    'voussoierez',
    'voussoyâmes',
    'voussoyasse',
    'voussoyâtes',
    'voussoyions',
    'voûtassions',
    'voûteraient',
    'vouvoiement',
    'vouvoierais',
    'vouvoierait',
    'vouvoieriez',
    'vouvoierons',
    'vouvoieront',
    'vouvoyaient',
    'vouvoyasses',
    'vouvoyèrent',
    'voyageaient',
    'voyageasses',
    'voyagerions',
    'voyeurismes',
    'vrillassent',
    'vrillassiez',
    'vrillerions',
    'vrombirions',
    'vrombissais',
    'vrombissait',
    'vrombissant',
    'vrombissent',
    'vrombissiez',
    'vrombissons',
    'vulcanienne',
    'vulcanisais',
    'vulcanisait',
    'vulcanisant',
    'vulcanisées',
    'vulcanisent',
    'vulcanisera',
    'vulcanisiez',
    'vulcanisons',
    'vulgarisais',
    'vulgarisait',
    'vulgarisant',
    'vulgarisées',
    'vulgarisent',
    'vulgarisera',
    'vulgarisiez',
    'vulgarismes',
    'vulgarisons',
    'vulnérables',
    'vulnéraires',
    'vulnérantes',
    'wagnérienne',
    'wagon-poste',
    'wagon-salon',
    'wagons-bars',
    'wagons-lits',
    'wagon-vanne',
    'wallingante',
    'wallingants',
    'wallonismes',
    'warrantages',
    'warrantâmes',
    'warrantasse',
    'warrantâtes',
    'warranterai',
    'warranteras',
    'warranterez',
    'warrantions',
    'wateringues',
    'water-polos',
    'waterproofs',
    'winchesters',
    'wintergreen',
    'xénophilies',
    'xénophobies',
    'xéranthèmes',
    'xérodermies',
    'xérographie',
    'xiphoïdiens',
    'xiphophores',
    'xylographes',
    'xylographie',
    'yacht-clubs',
    'yachtswoman',
    'yachtswomen',
    'yaourtières',
    'yougoslaves',
    'yponomeutes',
    'yttrialites',
    'zébrassions',
    'zébreraient',
    'zéphyrienne',
    'zestassions',
    'zesteraient',
    'zézaiements',
    'zézaierions',
    'zézayassent',
    'zézayassiez',
    'zézayerions',
    'zieutassent',
    'zieutassiez',
    'zieuterions',
    'zigouillais',
    'zigouillait',
    'zigouillant',
    'zigouillées',
    'zigouillent',
    'zigouillera',
    'zigouilliez',
    'zigouillons',
    'ziguassions',
    'zigueraient',
    'zigzaguâmes',
    'zigzaguasse',
    'zigzaguâtes',
    'zigzaguerai',
    'zigzagueras',
    'zigzaguerez',
    'zigzaguions',
    'zinguassent',
    'zinguassiez',
    'zinguerions',
    'zinzinulais',
    'zinzinulait',
    'zinzinulant',
    'zinzinulent',
    'zinzinulera',
    'zinzinuliez',
    'zinzinulons',
    'zippassions',
    'zipperaient',
    'zoanthropie',
    'zoologiques',
    'zoologistes',
    'zoosporange',
    'zootechnies',
    'zoroastrien',
    'zostérienne',
    'zozotassent',
    'zozotassiez',
    'zozotements',
    'zozoterions',
    'zygomatique',
    'zygomorphes',
    'zygopétales',
    'zymotechnie'
];
