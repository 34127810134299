import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSpendingIsLoading, getDefaultExpenses } from '../spending.selectors';
import { saveDefaultExpenses, fetchConf } from '../spending.actions';
import { useNavigate } from 'react-router-dom';
import PageTitle from '../../common/Components/PageTitle';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import { payers } from '../spending.const';
import TrashIcon from '@mui/icons-material/Delete';
import displayPrice from '../spending.utils';
import ExpenseModal from '../Components/ExpenseModal';
import { Add } from '@mui/icons-material';

export default function AdminSpending() {
    const navigate = useNavigate();
    const [isExpenseModalOpen, setIsExpenseModalOpen] = useState(false);

    const defaultExpenses = useSelector(getDefaultExpenses);
    const isLoading = useSelector(getSpendingIsLoading);
    const dispatch = useDispatch();

    const [expenses, setExpenses] = useState(defaultExpenses);

    useEffect(() => {
        document.title = 'Home - Dépenses - admin';
        if (!isLoading && defaultExpenses.length === 0) {
            dispatch(fetchConf());
        }
    }, []);

    useEffect(() => {
        setExpenses(defaultExpenses);
    }, [defaultExpenses]);

    async function handleSaveDefaultExpenses() {
        await dispatch(saveDefaultExpenses(expenses));
        navigate(-1);
    }

    function removeExpense(index) {
        setExpenses(expenses.filter((x, i) => i !== index));
    }

    function handleExpenseModalClose(expense) {
        if (expense) {
            setExpenses(expenses.concat(expense));
        }
        setIsExpenseModalOpen(false);
    }

    if (isLoading) {
        return (
            <React.Fragment>
                <PageTitle title="Administration des dépenses" />
                <div className="row justify-center mt-l">
                    <CircularProgress />
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <PageTitle title="Administration des dépenses" />
            <Card>
                <CardHeader title="Dépenses par défaut" />
                <CardContent>
                    <div className="row">
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <b>Nom</b>
                                        </TableCell>
                                        <TableCell>
                                            <b>Compté ?</b>
                                        </TableCell>
                                        <TableCell>
                                            <b>Audrey</b>
                                        </TableCell>
                                        <TableCell>
                                            <b>Morgan</b>
                                        </TableCell>
                                        <TableCell>
                                            <b>Commentaire</b>
                                        </TableCell>
                                        <TableCell>
                                            <b>Action</b>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {expenses.map((expense, i) => (
                                        <TableRow
                                            key={i}
                                            sx={{
                                                '&:last-child td, &:last-child th': { border: 0 }
                                            }}
                                        >
                                            <TableCell>{expense.name}</TableCell>
                                            <TableCell>
                                                {expense.consider === false ? 'Non' : 'Oui'}
                                            </TableCell>
                                            <TableCell>
                                                {displayPrice(expense, payers.Audrey, true)}
                                            </TableCell>
                                            <TableCell>
                                                {displayPrice(expense, payers.Morgan, true)}
                                            </TableCell>
                                            <TableCell>{expense.comment}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    size="small"
                                                    color="error"
                                                    onClick={() => removeExpense(i)}
                                                >
                                                    <TrashIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div className="row flex-end mt-xs">
                        <Button
                            variant="outlined"
                            onClick={() => setIsExpenseModalOpen(true)}
                            startIcon={<Add />}
                            size="small"
                        >
                            Nouvelle dépense
                        </Button>
                    </div>
                </CardContent>
                <CardActions>
                    <div className="row space-between">
                        <Button variant="outlined" color="error" onClick={() => navigate(-1)}>
                            Annuler
                        </Button>
                        <Button variant="contained" onClick={() => handleSaveDefaultExpenses()}>
                            Enregistrer
                        </Button>
                    </div>
                </CardActions>
            </Card>
            <ExpenseModal
                open={isExpenseModalOpen}
                onClose={handleExpenseModalClose}
                showConsider={true}
            />
        </React.Fragment>
    );
}
