export default [
    'abaisse-langue',
    'abandonnassent',
    'abandonnassiez',
    'abandonnataire',
    'abandonnerions',
    'abasourdirions',
    'abasourdissais',
    'abasourdissait',
    'abasourdissant',
    'abasourdissent',
    'abasourdissiez',
    'abasourdissons',
    'abâtardiraient',
    'abâtardissions',
    'abbevilliennes',
    'abolitionnisme',
    'abolitionniste',
    'abominablement',
    'abonnissements',
    'aboutissements',
    'abracadabrante',
    'abracadabrants',
    'abricotassions',
    'abricoteraient',
    'abrutissements',
    'académiciennes',
    'académiquement',
    'acagnardassiez',
    'acagnarderions',
    'acanthocéphale',
    'accaparassions',
    'accapareraient',
    'accastillaient',
    'accastillasses',
    'accastillerais',
    'accastillerait',
    'accastillèrent',
    'accastilleriez',
    'accastillerons',
    'accastilleront',
    'accélérassions',
    'accélératrices',
    'accéléreraient',
    'accélérographe',
    'accéléromètres',
    'accentuassions',
    'accentueraient',
    'acceptabilités',
    'accessibilités',
    'accessoirement',
    'accessoirisais',
    'accessoirisait',
    'accessoirisant',
    'accessoirisées',
    'accessoirisent',
    'accessoirisera',
    'accessoirisiez',
    'accessoirisons',
    'accessoiristes',
    'accidentassent',
    'accidentassiez',
    'accidenterions',
    'acclimatassent',
    'acclimatassiez',
    'acclimatations',
    'acclimatements',
    'acclimaterions',
    'accointassions',
    'accommodassent',
    'accommodassiez',
    'accommodateurs',
    'accommodations',
    'accommodatrice',
    'accommodements',
    'accommoderions',
    'accompagnaient',
    'accompagnasses',
    'accompagnateur',
    'accompagnement',
    'accompagnerais',
    'accompagnerait',
    'accompagnèrent',
    'accompagneriez',
    'accompagnerons',
    'accompagneront',
    'accompliraient',
    'accomplissions',
    'accordéonistes',
    'accouchassions',
    'accoucheraient',
    'accouplassions',
    'accoupleraient',
    'accourciraient',
    'accourcissions',
    'accoutrassions',
    'accoutreraient',
    'accoutumassent',
    'accoutumassiez',
    'accoutumerions',
    'accréditassent',
    'accréditassiez',
    'accréditations',
    'accréditerions',
    'accrochassions',
    'accroche-coeur',
    'accrocheraient',
    'accroissements',
    'accroupissions',
    'accueillerions',
    'accueillissent',
    'accueillissiez',
    'acculturassent',
    'acculturassiez',
    'acculturations',
    'acculturerions',
    'accumulassions',
    'accumuleraient',
    'acétifiassions',
    'acétifieraient',
    'achalandassent',
    'achalandassiez',
    'achalanderions',
    'acheminassions',
    'achemineraient',
    'achondroplasie',
    'achromatopsies',
    'acidifiassions',
    'acidifications',
    'acidifieraient',
    'acoquinassions',
    'acousticiennes',
    'acquiesçassent',
    'acquiesçassiez',
    'acquiescements',
    'acquiescerions',
    'acquittassions',
    'acquitteraient',
    'actinothérapie',
    'actionnassions',
    'actionneraient',
    'actualisassent',
    'actualisassiez',
    'actualisations',
    'actualiserions',
    'additionnaient',
    'additionnasses',
    'additionnelles',
    'additionnerais',
    'additionnerait',
    'additionnèrent',
    'additionneriez',
    'additionnerons',
    'additionneront',
    'adénocarcinome',
    'adjectivassent',
    'adjectivassiez',
    'adjectiverions',
    'adjectivisâmes',
    'adjectivisasse',
    'adjectivisâtes',
    'adjectiviserai',
    'adjectiviseras',
    'adjectiviserez',
    'adjectivisions',
    'adjoignissions',
    'adjudicataires',
    'adjudicatrices',
    'administrables',
    'administraient',
    'administrasses',
    'administrateur',
    'administratifs',
    'administration',
    'administrative',
    'administrerais',
    'administrerait',
    'administrèrent',
    'administreriez',
    'administrerons',
    'administreront',
    'admirativement',
    'admissibilités',
    'admonestassent',
    'admonestassiez',
    'admonestations',
    'admonesterions',
    'adoucissements',
    'adultérassions',
    'adultéreraient',
    'adverbialement',
    'aérodynamiques',
    'affabulassions',
    'affabuleraient',
    'affadissements',
    'affaibliraient',
    'affaiblissante',
    'affaiblissants',
    'affaiblissions',
    'affaissassions',
    'affaisseraient',
    'afféageassions',
    'affectionnâmes',
    'affectionnasse',
    'affectionnâtes',
    'affectionnerai',
    'affectionneras',
    'affectionnerez',
    'affectionnions',
    'affermissaient',
    'affermissement',
    'affleurassions',
    'affleureraient',
    'affligeassions',
    'affouageassent',
    'affouageassiez',
    'affouageraient',
    'affouillassent',
    'affouillassiez',
    'affouillements',
    'affouillerions',
    'affourageaient',
    'affourageasses',
    'affouragements',
    'affouragerions',
    'affourchassent',
    'affourchassiez',
    'affourcherions',
    'affourrageâmes',
    'affourrageasse',
    'affourrageâtes',
    'affourragerais',
    'affourragerait',
    'affourragèrent',
    'affourrageriez',
    'affourragerons',
    'affourrageront',
    'affranchirions',
    'affranchissais',
    'affranchissait',
    'affranchissant',
    'affranchissent',
    'affranchissiez',
    'affranchissons',
    'affriandassent',
    'affriandassiez',
    'affrianderions',
    'affrichassions',
    'affricheraient',
    'affriolassions',
    'affrioleraient',
    'affrontassions',
    'affronteraient',
    'affruitassions',
    'affruiteraient',
    'africanisaient',
    'africanisasses',
    'africanisation',
    'africaniserais',
    'africaniserait',
    'africanisèrent',
    'africaniseriez',
    'africaniserons',
    'africaniseront',
    'afro-asiatique',
    'agenouillement',
    'agenouilleriez',
    'agenouillerons',
    'aggiornamentos',
    'agglomérassent',
    'agglomérassiez',
    'agglomérations',
    'agglomérerions',
    'agglutinassent',
    'agglutinassiez',
    'agglutinations',
    'agglutinerions',
    'agrammaticales',
    'agrandissaient',
    'agrandissement',
    'agranulocytose',
    'agrémentassent',
    'agrémentassiez',
    'agrémenterions',
    'aguerrissaient',
    'aguerrissement',
    'aide-comptable',
    'aigues-marines',
    'aiguillassions',
    'aiguilleraient',
    'aiguilletaient',
    'aiguilletasses',
    'aiguilletèrent',
    'aiguilletterai',
    'aiguilletteras',
    'aiguilletterez',
    'aiguillonnâmes',
    'aiguillonnasse',
    'aiguillonnâtes',
    'aiguillonnerai',
    'aiguillonneras',
    'aiguillonnerez',
    'aiguillonnions',
    'alambiquassent',
    'alambiquassiez',
    'alambiquerions',
    'alanguissaient',
    'alanguissement',
    'albuminuriques',
    'alcalinisaient',
    'alcalinisasses',
    'alcaliniserais',
    'alcaliniserait',
    'alcalinisèrent',
    'alcaliniseriez',
    'alcaliniserons',
    'alcaliniseront',
    'alcalisassions',
    'alcaliseraient',
    'alcoolisassent',
    'alcoolisassiez',
    'alcoolisations',
    'alcooliserions',
    'algébriquement',
    'algorithmiques',
    'alimentassions',
    'alimenteraient',
    'allégorisaient',
    'allégorisasses',
    'allégoriserais',
    'allégoriserait',
    'allégorisèrent',
    'allégoriseriez',
    'allégoriserons',
    'allégoriseront',
    'allergologiste',
    'allongeassions',
    'allume-cigares',
    'alluvionnaient',
    'alluvionnaires',
    'alluvionnasses',
    'alluvionnement',
    'alluvionnerais',
    'alluvionnerait',
    'alluvionnèrent',
    'alluvionneriez',
    'alluvionnerons',
    'alluvionneront',
    'alourdissaient',
    'alourdissement',
    'alphabétisâmes',
    'alphabétisasse',
    'alphabétisâtes',
    'alphabétiserai',
    'alphabétiseras',
    'alphabétiserez',
    'alphabétisions',
    'alphanumérique',
    'aluminothermie',
    'amaigrissaient',
    'amaigrissantes',
    'amaigrissement',
    'amalgamassions',
    'amalgameraient',
    'amaryllidacées',
    'ambitieusement',
    'ambitionnaient',
    'ambitionnasses',
    'ambitionnerais',
    'ambitionnerait',
    'ambitionnèrent',
    'ambitionneriez',
    'ambitionnerons',
    'ambitionneront',
    'améliorassions',
    'amélioreraient',
    'aménageassions',
    'amenuisassions',
    'amenuiseraient',
    'américanisâmes',
    'américanisasse',
    'américanisâtes',
    'américaniserai',
    'américaniseras',
    'américaniserez',
    'américanisions',
    'ameublissaient',
    'ameublissement',
    'amidonnassions',
    'amidonneraient',
    'amincissements',
    'amnistiassions',
    'amnistieraient',
    'amoindriraient',
    'amoindrissions',
    'amollissements',
    'amoncelassions',
    'amoncellements',
    'amoncellerions',
    'amordançassent',
    'amordançassiez',
    'amordancerions',
    'amortissements',
    'amourachassiez',
    'amouracherions',
    'amours-propres',
    'ampélographies',
    'amphiarthroses',
    'amphibologique',
    'amphigouriques',
    'amplifiassions',
    'amplificateurs',
    'amplifications',
    'amplificatrice',
    'amplifieraient',
    'amygdalectomie',
    'anachorétiques',
    'anacréontiques',
    'anagrammatique',
    'analgésiassent',
    'analgésiassiez',
    'analgésierions',
    'analphabétisme',
    'analytiquement',
    'anaphylactique',
    'anarchiquement',
    'anastomoseriez',
    'anastomoserons',
    'anathématisais',
    'anathématisait',
    'anathématisant',
    'anathématisées',
    'anathématisent',
    'anathématisera',
    'anathématisiez',
    'anathématisons',
    'anatomiquement',
    'anatomisassent',
    'anatomisassiez',
    'anatomiserions',
    'anéantissaient',
    'anéantissement',
    'anesthésiaient',
    'anesthésiantes',
    'anesthésiasses',
    'anesthésierais',
    'anesthésierait',
    'anesthésièrent',
    'anesthésieriez',
    'anesthésierons',
    'anesthésieront',
    'anfractuosités',
    'anglaisassions',
    'anglaiseraient',
    'anglicisassent',
    'anglicisassiez',
    'angliciserions',
    'anglo-normande',
    'anglo-normands',
    'anglo-saxonnes',
    'angoissassions',
    'angoisseraient',
    'animadversions',
    'animalisassent',
    'animalisassiez',
    'animaliserions',
    'ankylosassions',
    'ankyloseraient',
    'années-lumière',
    'annexionnismes',
    'annexionnistes',
    'annihilassions',
    'annihileraient',
    'annonciatrices',
    'anoblissements',
    'antédiluvienne',
    'antépénultième',
    'antéposassions',
    'antéposeraient',
    'antérieurement',
    'anthraquinones',
    'anthropogenèse',
    'anthropologies',
    'anthropologues',
    'anthropométrie',
    'anthropomorphe',
    'anthropophages',
    'anthropophagie',
    'antialcoolique',
    'antialcoolisme',
    'antiallergique',
    'antibrouillage',
    'antibrouillard',
    'antibrouillées',
    'anticancéreuse',
    'anticipassions',
    'anticiperaient',
    'anticléricales',
    'anticoagulante',
    'anticoagulants',
    'anticommunisme',
    'anticommuniste',
    'anticorpuscule',
    'anticyclonales',
    'anticyclonique',
    'antidatassions',
    'antidateraient',
    'antidéflagrant',
    'antidépresseur',
    'antidérapantes',
    'antidétonantes',
    'antihygiénique',
    'antilogarithme',
    'antimitotiques',
    'antinationales',
    'antinucléaires',
    'antipaludéenne',
    'antipaludiques',
    'antiparallèles',
    'antiparticules',
    'antipyrétiques',
    'antirachitique',
    'antireligieuse',
    'antisatellites',
    'antisémitismes',
    'antisymétrique',
    'antitétaniques',
    'antivariolique',
    'antivenimeuses',
    'apanageassions',
    'aphrodisiaques',
    'aplanissements',
    'aplatissements',
    'apocalyptiques',
    'aponévrotiques',
    'apostasiassent',
    'apostasiassiez',
    'apostasierions',
    'apostillassent',
    'apostillassiez',
    'apostillerions',
    'apostrophaient',
    'apostrophasses',
    'apostropherais',
    'apostropherait',
    'apostrophèrent',
    'apostropheriez',
    'apostropherons',
    'apostropheront',
    'appalachiennes',
    'apparaissaient',
    'apparaîtraient',
    'appareillaient',
    'appareillasses',
    'appareillement',
    'appareillerais',
    'appareillerait',
    'appareillèrent',
    'appareilleriez',
    'appareillerons',
    'appareilleront',
    'apparentassiez',
    'apparentements',
    'apparenterions',
    'appartiendrais',
    'appartiendrait',
    'appartiendriez',
    'appartiendrons',
    'appartiendront',
    'appartinssions',
    'appauvriraient',
    'appauvrissions',
    'appendiculaire',
    'appesantirions',
    'appesantissais',
    'appesantissait',
    'appesantissant',
    'appesantissent',
    'appesantissiez',
    'appesantissons',
    'applaudimètres',
    'applaudiraient',
    'applaudissions',
    'applicabilités',
    'appliquassions',
    'appliqueraient',
    'appointassions',
    'appointeraient',
    'appointiraient',
    'appointissions',
    'appréciabilité',
    'appréciassions',
    'appréciatrices',
    'apprécieraient',
    'appréhendaient',
    'appréhendasses',
    'appréhenderais',
    'appréhenderait',
    'appréhendèrent',
    'appréhenderiez',
    'appréhenderons',
    'appréhenderont',
    'apprentissages',
    'apprivoisables',
    'apprivoisaient',
    'apprivoisasses',
    'apprivoisement',
    'apprivoiserais',
    'apprivoiserait',
    'apprivoisèrent',
    'apprivoiseriez',
    'apprivoiserons',
    'apprivoiseront',
    'apprivoiseuses',
    'approbativités',
    'approchassions',
    'approcheraient',
    'approfondirais',
    'approfondirait',
    'approfondirent',
    'approfondiriez',
    'approfondirons',
    'approfondiront',
    'approfondisses',
    'approfondissez',
    'appropriassent',
    'appropriassiez',
    'appropriations',
    'approprierions',
    'approuvassions',
    'approuveraient',
    'approvisionnai',
    'approvisionnas',
    'approvisionnât',
    'approvisionnée',
    'approvisionner',
    'approvisionnes',
    'approvisionnés',
    'approvisionnez',
    'approximations',
    'approximatives',
    'arbitrairement',
    'arboriculteurs',
    'arboricultrice',
    'arboricultures',
    'arborisassions',
    'arboriseraient',
    'arc-boutassent',
    'arc-boutassiez',
    'arc-bouterions',
    'archaïsassions',
    'archaïseraient',
    'archangéliques',
    'archéologiques',
    'archiconfrérie',
    'archidiocésain',
    'archiduchesses',
    'archiépiscopal',
    'archiépiscopat',
    'archimandrites',
    'architectonies',
    'architecturais',
    'architecturait',
    'architecturale',
    'architecturant',
    'architecturaux',
    'architecturées',
    'architecturent',
    'architecturera',
    'architecturiez',
    'architecturons',
    'archivistiques',
    'arcs-doubleaux',
    'argotisassions',
    'argotiseraient',
    'argumentassent',
    'argumentassiez',
    'argumentateurs',
    'argumentations',
    'argumenterions',
    'aristocratique',
    'aristotélicien',
    'aristotéliques',
    'aristotélismes',
    'aromatisassent',
    'aromatisassiez',
    'aromatiserions',
    'arraisonnaient',
    'arraisonnasses',
    'arraisonnement',
    'arraisonnerais',
    'arraisonnerait',
    'arraisonnèrent',
    'arraisonneriez',
    'arraisonnerons',
    'arraisonneront',
    'arrangeassions',
    'arrérageassent',
    'arrérageassiez',
    'arrérageraient',
    'arrière-bouche',
    'arrière-choeur',
    'arrière-gardes',
    'arrière-gorges',
    'arrière-pensée',
    'arrière-saison',
    'arrière-trains',
    'arrière-vassal',
    'arrondissaient',
    'arrondissement',
    'arsouillassiez',
    'arsouillerions',
    'artériectomies',
    'artériographie',
    'arthrographies',
    'artichautières',
    'articulassions',
    'articulatoires',
    'articuleraient',
    'artisanalement',
    'artistiquement',
    'ascensionnâmes',
    'ascensionnasse',
    'ascensionnâtes',
    'ascensionnelle',
    'ascensionnerai',
    'ascensionneras',
    'ascensionnerez',
    'ascensionnions',
    'ascensionniste',
    'aseptisassions',
    'aseptiseraient',
    'aspergeassions',
    'asphaltassions',
    'asphalteraient',
    'asphyxiassions',
    'asphyxieraient',
    'assagissements',
    'assailliraient',
    'assaillissions',
    'assainissaient',
    'assainissement',
    'assaisonnaient',
    'assaisonnasses',
    'assaisonnement',
    'assaisonnerais',
    'assaisonnerait',
    'assaisonnèrent',
    'assaisonneriez',
    'assaisonnerons',
    'assaisonneront',
    'assarmentaient',
    'assarmentasses',
    'assarmenterais',
    'assarmenterait',
    'assarmentèrent',
    'assarmenteriez',
    'assarmenterons',
    'assarmenteront',
    'assassinassent',
    'assassinassiez',
    'assassinerions',
    'assemblassions',
    'assembleraient',
    'assermentaient',
    'assermentasses',
    'assermentation',
    'assermenterais',
    'assermenterait',
    'assermentèrent',
    'assermenteriez',
    'assermenterons',
    'assermenteront',
    'asservissaient',
    'asservissantes',
    'asservissement',
    'assibilassions',
    'assibileraient',
    'assiégeassions',
    'assimilassions',
    'assimilatrices',
    'assimileraient',
    'assombriraient',
    'assombrissions',
    'assortissaient',
    'assoupissaient',
    'assoupissantes',
    'assoupissement',
    'assoupliraient',
    'assouplissions',
    'assourdiraient',
    'assourdissante',
    'assourdissants',
    'assourdissions',
    'assouvissaient',
    'assouvissement',
    'assujettirions',
    'assujettissais',
    'assujettissait',
    'assujettissant',
    'assujettissent',
    'assujettissiez',
    'assujettissons',
    'astéréognosies',
    'asthénosphères',
    'asticotassions',
    'asticoteraient',
    'astreignissent',
    'astreignissiez',
    'astreindraient',
    'astronautiques',
    'astucieusement',
    'atermoieraient',
    'atermoyassions',
    'athérosclérose',
    'atmosphériques',
    'atomes-grammes',
    'atrophiassions',
    'atrophieraient',
    'attachés-cases',
    'atteignissions',
    'attendriraient',
    'attendrissante',
    'attendrissants',
    'attendrisseurs',
    'attendrissions',
    'atterrissaient',
    'atterrissement',
    'attiédissaient',
    'attiédissement',
    'attrape-mouche',
    'attrape-nigaud',
    'attribuassions',
    'attribueraient',
    'attriquassions',
    'attriqueraient',
    'attristassions',
    'attristeraient',
    'attroupassions',
    'attrouperaient',
    'audacieusement',
    'audiofréquence',
    'audiovisuelles',
    'auditionnaient',
    'auditionnasses',
    'auditionnerais',
    'auditionnerait',
    'auditionnèrent',
    'auditionneriez',
    'auditionnerons',
    'auditionneront',
    'augmentassions',
    'augmenteraient',
    'augustiniennes',
    'auraient-elles',
    'aurignaciennes',
    'auscultassions',
    'auscultatoires',
    'ausculteraient',
    'authentifiâmes',
    'authentifiasse',
    'authentifiâtes',
    'authentifierai',
    'authentifieras',
    'authentifierez',
    'authentifiions',
    'authentiquâmes',
    'authentiquasse',
    'authentiquâtes',
    'authentiquerai',
    'authentiqueras',
    'authentiquerez',
    'authentiquions',
    'auto-adhésives',
    'auto-allumages',
    'auto-amorçages',
    'autobiographie',
    'autocinétiques',
    'autoconduction',
    'autodéterminée',
    'autodéterminer',
    'autodéterminés',
    'autodéterminez',
    'autodiscipline',
    'autofinançâmes',
    'autofinançâtes',
    'autofinancerez',
    'autofinancions',
    'autographiâmes',
    'autographiasse',
    'autographiâtes',
    'autographierai',
    'autographieras',
    'autographierez',
    'autographiions',
    'autoguidassiez',
    'autoguiderions',
    'automatisaient',
    'automatisasses',
    'automatisation',
    'automatiserais',
    'automatiserait',
    'automatisèrent',
    'automatiseriez',
    'automatiserons',
    'automatiseront',
    'automédication',
    'automobilismes',
    'automobilistes',
    'automutilation',
    'autonettoyante',
    'autonettoyants',
    'autopropulsées',
    'autopropulsion',
    'autopsiassions',
    'autopsieraient',
    'autorégulateur',
    'autorégulation',
    'autorisassions',
    'autoriseraient',
    'autoritarismes',
    'auto-stoppeurs',
    'auto-stoppeuse',
    'autosuffisance',
    'autosuggestion',
    'auxiliairement',
    'avachissements',
    'avantageassent',
    'avantageassiez',
    'avantageraient',
    'avant-coureurs',
    'avant-creusets',
    'avant-dernière',
    'avant-derniers',
    'avant-gardisme',
    'avant-gardiste',
    'avant-première',
    'avants-centres',
    'aventurassions',
    'aventureraient',
    'avertissements',
    'aveulissements',
    'avitaillassent',
    'avitaillassiez',
    'avitaillements',
    'avitaillerions',
    'avoisinassions',
    'avoisineraient',
    'axiomatisaient',
    'axiomatisasses',
    'axiomatisation',
    'axiomatiserais',
    'axiomatiserait',
    'axiomatisèrent',
    'axiomatiseriez',
    'axiomatiserons',
    'axiomatiseront',
    'axonométriques',
    'bachi-bouzouks',
    'bactériologies',
    'bactériophages',
    'badigeonnaient',
    'badigeonnasses',
    'badigeonnerais',
    'badigeonnerait',
    'badigeonnèrent',
    'badigeonneriez',
    'badigeonnerons',
    'badigeonneront',
    'bafouillassent',
    'bafouillassiez',
    'bafouillerions',
    'baguenaudaient',
    'baguenaudasses',
    'baguenauderais',
    'baguenauderait',
    'baguenaudèrent',
    'baguenauderiez',
    'baguenauderons',
    'baguenauderont',
    'bâillonnassent',
    'bâillonnassiez',
    'bâillonnements',
    'bâillonnerions',
    'balais-brosses',
    'balbutiassions',
    'balbutieraient',
    'balkanisassent',
    'balkanisassiez',
    'balkanisations',
    'balkaniserions',
    'ballastassions',
    'ballasteraient',
    'ballonnassions',
    'ballonneraient',
    'ballons-sondes',
    'ballottassions',
    'ballotteraient',
    'balnéothérapie',
    'bambochassions',
    'bambocheraient',
    'banalisassions',
    'banaliseraient',
    'banqueroutière',
    'banqueroutiers',
    'banquetassions',
    'banquetterions',
    'baquetteraient',
    'baragouinaient',
    'baragouinasses',
    'baragouinerais',
    'baragouinerait',
    'baragouinèrent',
    'baragouineriez',
    'baragouinerons',
    'baragouineront',
    'baragouineuses',
    'baratinassions',
    'baratineraient',
    'barbifiassions',
    'barbifieraient',
    'barbouillaient',
    'barbouillasses',
    'barbouillerais',
    'barbouillerait',
    'barbouillèrent',
    'barbouilleriez',
    'barbouillerons',
    'barbouilleront',
    'barbouilleuses',
    'barguignassent',
    'barguignassiez',
    'barguignerions',
    'barricadassent',
    'barricadassiez',
    'barricaderions',
    'barycentriques',
    'bastillonnâmes',
    'bastillonnasse',
    'bastillonnâtes',
    'bastillonnerai',
    'bastillonneras',
    'bastillonnerez',
    'bastillonnions',
    'bastionnassent',
    'bastionnassiez',
    'bastionnerions',
    'bataillassions',
    'batailleraient',
    'bateau-citerne',
    'bateaux-pompes',
    'bathymétriques',
    'batifolassions',
    'batifoleraient',
    'béatifiassions',
    'béatifications',
    'béatifieraient',
    'bec-de-corbeau',
    'becquetassions',
    'becquetterions',
    'becs-de-lièvre',
    'belles-de-jour',
    'belles-de-nuit',
    'belles-lettres',
    'bémolisassions',
    'bémoliseraient',
    'bénéficiassent',
    'bénéficiassiez',
    'bénéficierions',
    'béquetteraient',
    'béquillassions',
    'béquilleraient',
    'bercelonnettes',
    'bergeronnettes',
    'berginisations',
    'bertillonnages',
    'bibelotassions',
    'bibeloteraient',
    'biberonnassent',
    'biberonnassiez',
    'biberonnerions',
    'bibliographies',
    'bibliothécaire',
    'bicaméralismes',
    'bichonnassions',
    'bichonneraient',
    'biculturalisme',
    'bidouillassent',
    'bidouillassiez',
    'bidouillerions',
    'bien-pensantes',
    'bienveillances',
    'bienveillantes',
    'bifurquassions',
    'bifurqueraient',
    'bihebdomadaire',
    'billebaudaient',
    'billebaudasses',
    'billebauderais',
    'billebauderait',
    'billebaudèrent',
    'billebauderiez',
    'billebauderons',
    'billebauderont',
    'billonnassions',
    'billonneraient',
    'bioacoustiques',
    'bioclimatiques',
    'biodégradables',
    'biodégradation',
    'biogéographies',
    'biotechnologie',
    'bipolarisation',
    'biquotidiennes',
    'biréfringences',
    'biréfringentes',
    'biscuitassions',
    'biscuiteraient',
    'biseautassions',
    'biseauteraient',
    'bisegmentaient',
    'bisegmentasses',
    'bisegmenterais',
    'bisegmenterait',
    'bisegmentèrent',
    'bisegmenteriez',
    'bisegmenterons',
    'bisegmenteront',
    'bistournassent',
    'bistournassiez',
    'bistournerions',
    'bituminassions',
    'bitumineraient',
    'bivouaquassent',
    'bivouaquassiez',
    'bivouaquerions',
    'blablatassions',
    'blablateraient',
    'blackboulaient',
    'blackboulasses',
    'blackboulerais',
    'blackboulerait',
    'blackboulèrent',
    'blackbouleriez',
    'blackboulerons',
    'blackbouleront',
    'blanchissaient',
    'blanchissantes',
    'blanchissement',
    'blanchisseries',
    'blanchisseuses',
    'blancs-mangers',
    'blasonnassions',
    'blasonneraient',
    'blasphémassent',
    'blasphémassiez',
    'blasphémateurs',
    'blasphématoire',
    'blasphématrice',
    'blasphémerions',
    'blennorragique',
    'blettissements',
    'bloc-cylindres',
    'blocs-cuisines',
    'blondoieraient',
    'blondoyassions',
    'boitillassions',
    'boitilleraient',
    'boit-sans-soif',
    'bolchévisaient',
    'bolchévisasses',
    'bolchéviserais',
    'bolchéviserait',
    'bolchévisèrent',
    'bolchéviseriez',
    'bolchéviserons',
    'bolchéviseront',
    'bombardassions',
    'bombarderaient',
    'bondérisassent',
    'bondérisassiez',
    'bondérisations',
    'bondériserions',
    'bondonnassions',
    'bondonneraient',
    'bonimentassent',
    'bonimentassiez',
    'bonimenterions',
    'bons-chrétiens',
    'borne-fontaine',
    'bosselleraient',
    'botanisassions',
    'botaniseraient',
    'bothriocéphale',
    'bottelleraient',
    'bouchardassent',
    'bouchardassiez',
    'boucharderions',
    'bouchonnassent',
    'bouchonnassiez',
    'bouchonnerions',
    'bouffonnassent',
    'bouffonnassiez',
    'bouffonnerions',
    'bougainvillées',
    'bougainvillier',
    'bougonnassions',
    'bougonneraient',
    'bouillabaisses',
    'bouillonnaient',
    'bouillonnantes',
    'bouillonnasses',
    'bouillonnement',
    'bouillonnerais',
    'bouillonnerait',
    'bouillonnèrent',
    'bouillonneriez',
    'bouillonnerons',
    'bouillonneront',
    'bouillottaient',
    'bouillottasses',
    'bouillotterais',
    'bouillotterait',
    'bouillottèrent',
    'bouillotteriez',
    'bouillotterons',
    'bouillotteront',
    'boulangeassent',
    'boulangeassiez',
    'boulangeraient',
    'boule-de-neige',
    'boulevardières',
    'bouleversaient',
    'bouleversantes',
    'bouleversasses',
    'bouleversement',
    'bouleverserais',
    'bouleverserait',
    'bouleversèrent',
    'bouleverseriez',
    'bouleverserons',
    'bouleverseront',
    'boulonnassions',
    'boulonneraient',
    'boulottassions',
    'boulotteraient',
    'bouquinassions',
    'bouquineraient',
    'bourdonnassent',
    'bourdonnassiez',
    'bourdonnements',
    'bourdonnerions',
    'bourgeoisement',
    'bourgeoisiales',
    'bourgeonnaient',
    'bourgeonnantes',
    'bourgeonnasses',
    'bourgeonnement',
    'bourgeonnerais',
    'bourgeonnerait',
    'bourgeonnèrent',
    'bourgeonneriez',
    'bourgeonnerons',
    'bourgeonneront',
    'bourguignonnes',
    'bourlinguaient',
    'bourlinguasses',
    'bourlinguerais',
    'bourlinguerait',
    'bourlinguèrent',
    'bourlingueriez',
    'bourlinguerons',
    'bourlingueront',
    'bourlingueuses',
    'bourrelassions',
    'bourrellerions',
    'bourriquassent',
    'bourriquassiez',
    'bourriquerions',
    'boursicotaient',
    'boursicotasses',
    'boursicoterais',
    'boursicoterait',
    'boursicotèrent',
    'boursicoteriez',
    'boursicoterons',
    'boursicoteront',
    'boursicoteuses',
    'boursicotières',
    'boursouflaient',
    'boursouflasses',
    'boursouflement',
    'boursouflerais',
    'boursouflerait',
    'boursouflèrent',
    'boursoufleriez',
    'boursouflerons',
    'boursoufleront',
    'bousculassions',
    'bousculeraient',
    'bousillassions',
    'bousilleraient',
    'boustifaillais',
    'boustifaillait',
    'boustifaillant',
    'boustifaillent',
    'boustifaillera',
    'boustifailliez',
    'boustifaillons',
    'boustrophédons',
    'boute-en-train',
    'boutonnassions',
    'boutonneraient',
    'boycottassions',
    'boycotteraient',
    'brachycéphales',
    'braconnassions',
    'braconneraient',
    'brainstormings',
    'brandillassent',
    'brandillassiez',
    'brandillerions',
    'brasillassions',
    'brasilleraient',
    'bredouillaient',
    'bredouillasses',
    'bredouillement',
    'bredouillerais',
    'bredouillerait',
    'bredouillèrent',
    'bredouilleriez',
    'bredouillerons',
    'bredouilleront',
    'bredouilleuses',
    'brésillassions',
    'brésilleraient',
    'brettelassions',
    'brettellerions',
    'brevetteraient',
    'brigandassions',
    'briganderaient',
    'brillantassent',
    'brillantassiez',
    'brillanterions',
    'brillantinâmes',
    'brillantinasse',
    'brillantinâtes',
    'brillantinerai',
    'brillantineras',
    'brillantinerez',
    'brillantinions',
    'brimbalassions',
    'brimbaleraient',
    'bringueballais',
    'bringueballait',
    'bringueballant',
    'bringueballées',
    'bringueballent',
    'bringueballera',
    'bringueballiez',
    'bringueballons',
    'brinquebalâmes',
    'brinquebalasse',
    'brinquebalâtes',
    'brinquebalerai',
    'brinquebaleras',
    'brinquebalerez',
    'brinquebalions',
    'brinqueballais',
    'brinqueballait',
    'brinqueballant',
    'brinqueballées',
    'brinqueballent',
    'brinqueballera',
    'brinqueballiez',
    'brinqueballons',
    'briquetassions',
    'briquetterions',
    'brocantassions',
    'brocanteraient',
    'brocardassions',
    'brocarderaient',
    'bronchectasies',
    'bronchoscopies',
    'brouettassions',
    'brouetteraient',
    'brouillassions',
    'brouilleraient',
    'brouillonnâmes',
    'brouillonnasse',
    'brouillonnâtes',
    'brouillonnerai',
    'brouillonneras',
    'brouillonnerez',
    'brouillonnions',
    'broussailleuse',
    'brutalisassent',
    'brutalisassiez',
    'brutaliserions',
    'bucco-dentaire',
    'bucco-génitale',
    'bucco-génitaux',
    'budgétisassent',
    'budgétisassiez',
    'budgétisations',
    'budgétiserions',
    'bureaucratique',
    'bureaucratisai',
    'bureaucratisas',
    'bureaucratisât',
    'bureaucratisée',
    'bureaucratiser',
    'bureaucratises',
    'bureaucratisés',
    'bureaucratisez',
    'byzantinologie',
    'cabotinassions',
    'cabotineraient',
    'cabriolassions',
    'cabrioleraient',
    'cachetteraient',
    'cadanchassions',
    'cadancheraient',
    'cadastrassions',
    'cadastreraient',
    'cadenassassent',
    'cadenassassiez',
    'cadenasserions',
    'cafés-concerts',
    'cafés-théâtres',
    'cafouillassent',
    'cafouillassiez',
    'cafouillerions',
    'cagnardassions',
    'cagnarderaient',
    'caillebotterai',
    'caillebotteras',
    'caillebotterez',
    'caillebottions',
    'cailletassions',
    'cailletterions',
    'cailloutassent',
    'cailloutassiez',
    'caillouterions',
    'calaminassions',
    'calamineraient',
    'calamistraient',
    'calamistrasses',
    'calamistrerais',
    'calamistrerait',
    'calamistrèrent',
    'calamistreriez',
    'calamistrerons',
    'calamistreront',
    'calanchassions',
    'calancheraient',
    'calandrassions',
    'calandreraient',
    'calcifications',
    'calembredaines',
    'calfeutrassent',
    'calfeutrassiez',
    'calfeutrements',
    'calfeutrerions',
    'calligraphiais',
    'calligraphiait',
    'calligraphiant',
    'calligraphiées',
    'calligraphient',
    'calligraphiera',
    'calligraphiiez',
    'calligraphions',
    'calligraphique',
    'calomniassions',
    'calomniatrices',
    'calomnieraient',
    'calorification',
    'calorifugeâmes',
    'calorifugeasse',
    'calorifugeâtes',
    'calorifugerais',
    'calorifugerait',
    'calorifugèrent',
    'calorifugeriez',
    'calorifugerons',
    'calorifugeront',
    'calorimétrique',
    'cambriolassent',
    'cambriolassiez',
    'cambriolerions',
    'camelotassions',
    'cameloteraient',
    'camion-citerne',
    'camionnassions',
    'camionneraient',
    'camouflassions',
    'camoufleraient',
    'canalisassions',
    'canaliseraient',
    'cancérisations',
    'cannelleraient',
    'canonisassions',
    'canoniseraient',
    'cantonnassions',
    'cantonneraient',
    'caoutchoutages',
    'caoutchoutâmes',
    'caoutchoutasse',
    'caoutchoutâtes',
    'caoutchouterai',
    'caoutchouteras',
    'caoutchouterez',
    'caoutchouteuse',
    'caoutchoutions',
    'caparaçonnâmes',
    'caparaçonnasse',
    'caparaçonnâtes',
    'caparaçonnerai',
    'caparaçonneras',
    'caparaçonnerez',
    'caparaçonnions',
    'capilliculture',
    'capitalisables',
    'capitalisaient',
    'capitalisantes',
    'capitalisasses',
    'capitalisation',
    'capitaliserais',
    'capitaliserait',
    'capitalisèrent',
    'capitaliseriez',
    'capitaliserons',
    'capitaliseront',
    'capitonnassent',
    'capitonnassiez',
    'capitonnerions',
    'capitulassions',
    'capituleraient',
    'caporalisaient',
    'caporalisasses',
    'caporaliserais',
    'caporaliserait',
    'caporalisèrent',
    'caporaliseriez',
    'caporaliserons',
    'caporaliseront',
    'capuchonnaient',
    'capuchonnasses',
    'capuchonnerais',
    'capuchonnerait',
    'capuchonnèrent',
    'capuchonneriez',
    'capuchonnerons',
    'capuchonneront',
    'caquetteraient',
    'caracolassions',
    'caracoleraient',
    'caractérielles',
    'caractérisâmes',
    'caractérisante',
    'caractérisants',
    'caractérisasse',
    'caractérisâtes',
    'caractériserai',
    'caractériseras',
    'caractériserez',
    'caractérisions',
    'caractérologie',
    'carambolassent',
    'carambolassiez',
    'carambolerions',
    'carambouillage',
    'carambouilleur',
    'caramélisaient',
    'caramélisasses',
    'caramélisation',
    'caraméliserais',
    'caraméliserait',
    'caramélisèrent',
    'caraméliseriez',
    'caraméliserons',
    'caraméliseront',
    'carapatassions',
    'carapateraient',
    'caravansérails',
    'carbonatassent',
    'carbonatassiez',
    'carbonatations',
    'carbonaterions',
    'carbonisassent',
    'carbonisassiez',
    'carbonisations',
    'carboniserions',
    'carburéacteurs',
    'carcaillassent',
    'carcaillassiez',
    'carcaillerions',
    'cardiotoniques',
    'caricaturaient',
    'caricaturasses',
    'caricaturerais',
    'caricaturerait',
    'caricaturèrent',
    'caricatureriez',
    'caricaturerons',
    'caricatureront',
    'caricaturistes',
    'carillonnaient',
    'carillonnasses',
    'carillonnement',
    'carillonnerais',
    'carillonnerait',
    'carillonnèrent',
    'carillonneriez',
    'carillonnerons',
    'carillonneront',
    'carnavalesques',
    'carnifiassions',
    'carnifications',
    'carnifieraient',
    'carolingiennes',
    'carrelleraient',
    'carrossassions',
    'carrosseraient',
    'cartellisation',
    'cartésianismes',
    'cartes-lettres',
    'carthaginoises',
    'cartilagineuse',
    'cartographique',
    'cartomancienne',
    'cartonnassions',
    'cartonneraient',
    'casematassions',
    'casemateraient',
    'castagnassions',
    'castagneraient',
    'catadioptrique',
    'cataloguassent',
    'cataloguassiez',
    'cataloguerions',
    'catalysassions',
    'catalyseraient',
    'catapultassent',
    'catapultassiez',
    'catapulterions',
    'catastrophâmes',
    'catastrophasse',
    'catastrophâtes',
    'catastropherai',
    'catastropheras',
    'catastropherez',
    'catastrophions',
    'catastrophique',
    'catéchisassent',
    'catéchisassiez',
    'catéchiserions',
    'catéchistiques',
    'catégorisation',
    'cauchemardâmes',
    'cauchemardasse',
    'cauchemardâtes',
    'cauchemarderai',
    'cauchemarderas',
    'cauchemarderez',
    'cauchemardeuse',
    'cauchemardions',
    'cautérisassent',
    'cautérisassiez',
    'cautérisations',
    'cautériserions',
    'cautionnassent',
    'cautionnassiez',
    'cautionnements',
    'cautionnerions',
    'cavalcadassent',
    'cavalcadassiez',
    'cavalcaderions',
    'caviardassions',
    'caviarderaient',
    'ceinturassions',
    'ceintureraient',
    'cénesthésiques',
    'centralisaient',
    'centralisasses',
    'centralisateur',
    'centralisation',
    'centraliserais',
    'centraliserait',
    'centralisèrent',
    'centraliseriez',
    'centraliserons',
    'centraliseront',
    'centrifugation',
    'centrifugeâmes',
    'centrifugeasse',
    'centrifugeâtes',
    'centrifugerais',
    'centrifugerait',
    'centrifugèrent',
    'centrifugeriez',
    'centrifugerons',
    'centrifugeront',
    'centrifugeuses',
    'centuplassions',
    'centupleraient',
    'cercopithèques',
    'cérébro-spinal',
    'certifiassions',
    'certificateurs',
    'certifications',
    'certifieraient',
    'chagrinassions',
    'chagrineraient',
    'chalcographies',
    'challengeaient',
    'challengeasses',
    'challengerions',
    'chaloupassions',
    'chalouperaient',
    'chamaillassent',
    'chamaillassiez',
    'chamaillerions',
    'chamarrassions',
    'chamarreraient',
    'chambardassent',
    'chambardassiez',
    'chambardements',
    'chambarderions',
    'chamboulassent',
    'chamboulassiez',
    'chamboulements',
    'chamboulerions',
    'chamoisassions',
    'chamoiseraient',
    'champagnisâmes',
    'champagnisasse',
    'champagnisâtes',
    'champagniserai',
    'champagniseras',
    'champagniserez',
    'champagnisions',
    'champlevassent',
    'champlevassiez',
    'champlèverions',
    'chancelassions',
    'chancellerions',
    'chanfreinaient',
    'chanfreinasses',
    'chanfreinerais',
    'chanfreinerait',
    'chanfreinèrent',
    'chanfreineriez',
    'chanfreinerons',
    'chanfreineront',
    'chansonnassent',
    'chansonnassiez',
    'chansonnerions',
    'chanstiquaient',
    'chanstiquasses',
    'chanstiquerais',
    'chanstiquerait',
    'chanstiquèrent',
    'chanstiqueriez',
    'chanstiquerons',
    'chanstiqueront',
    'chantonnassent',
    'chantonnassiez',
    'chantonnements',
    'chantonnerions',
    'chantournaient',
    'chantournasses',
    'chantournerais',
    'chantournerait',
    'chantournèrent',
    'chantourneriez',
    'chantournerons',
    'chantourneront',
    'chapardassions',
    'chaparderaient',
    'chapeautassent',
    'chapeautassiez',
    'chapeauterions',
    'chapelleraient',
    'chaperonnaient',
    'chaperonnasses',
    'chaperonnerais',
    'chaperonnerait',
    'chaperonnèrent',
    'chaperonneriez',
    'chaperonnerons',
    'chaperonneront',
    'chapitrassions',
    'chapitreraient',
    'chaponnassions',
    'chaponneraient',
    'chaptalisaient',
    'chaptalisasses',
    'chaptalisation',
    'chaptaliserais',
    'chaptaliserait',
    'chaptalisèrent',
    'chaptaliseriez',
    'chaptaliserons',
    'chaptaliseront',
    'charbonnassent',
    'charbonnassiez',
    'charbonnerions',
    'charcutassions',
    'charcuteraient',
    'charismatiques',
    'charitablement',
    'charlataneries',
    'charlatanesque',
    'charlatanismes',
    'charpentassent',
    'charpentassiez',
    'charpenterions',
    'charroieraient',
    'charroyassions',
    'chasse-mouches',
    'châtaigneraies',
    'chateaubriands',
    'chateaubriants',
    'châteaubriants',
    'chatonnassions',
    'chatonneraient',
    'chatouillaient',
    'chatouillasses',
    'chatouillement',
    'chatouillerais',
    'chatouillerait',
    'chatouillèrent',
    'chatouilleriez',
    'chatouillerons',
    'chatouilleront',
    'chatouilleuses',
    'chaudronneries',
    'chaudronnières',
    'chausse-trapes',
    'chausse-trappe',
    'chauves-souris',
    'chelinguassent',
    'chelinguassiez',
    'chelinguerions',
    'cherchons-nous',
    'chevaleresques',
    'chevauchassent',
    'chevauchassiez',
    'chevauchements',
    'chevaucherions',
    'chevaux-vapeur',
    'chevillassions',
    'chevilleraient',
    'chèvrefeuilles',
    'chevretassions',
    'chevretterions',
    'chevronnassent',
    'chevronnassiez',
    'chevronnerions',
    'chevrotassions',
    'chevroteraient',
    'chiffonnassent',
    'chiffonnassiez',
    'chiffonnements',
    'chiffonnerions',
    'chimiothérapie',
    'chinoisassions',
    'chinoiseraient',
    'chirographaire',
    'chirographiais',
    'chirographiait',
    'chirographiant',
    'chirographiées',
    'chirographient',
    'chirographiera',
    'chirographiiez',
    'chirographions',
    'chiromancienne',
    'chiropracteurs',
    'chiropractrice',
    'chlinguassions',
    'chlingueraient',
    'chlorhydriques',
    'chloroformâmes',
    'chloroformasse',
    'chloroformâtes',
    'chloroformerai',
    'chloroformeras',
    'chloroformerez',
    'chloroformions',
    'chlorophyllien',
    'chloropicrines',
    'chlorurassions',
    'chlorureraient',
    'chondroblastes',
    'chorégraphique',
    'chosifiassions',
    'chosifications',
    'chosifieraient',
    'chouchoutaient',
    'chouchoutasses',
    'chouchouterais',
    'chouchouterait',
    'chouchoutèrent',
    'chouchouteriez',
    'chouchouterons',
    'chouchouteront',
    'chouravassions',
    'chouraveraient',
    'chourinassions',
    'chourineraient',
    'chrestomathies',
    'chrétiennement',
    'christianisais',
    'christianisait',
    'christianisant',
    'christianisées',
    'christianisent',
    'christianisera',
    'christianisiez',
    'christianismes',
    'christianisons',
    'chromatogramme',
    'chromosomiques',
    'chroniquassent',
    'chroniquassiez',
    'chroniquerions',
    'chronologiques',
    'chronométrages',
    'chronométrâmes',
    'chronométrasse',
    'chronométrâtes',
    'chronométrerai',
    'chronométreras',
    'chronométrerez',
    'chronométreurs',
    'chronométreuse',
    'chronométrions',
    'chronométrique',
    'chuchotassions',
    'chuchoteraient',
    'cicatricielles',
    'cicatrisassent',
    'cicatrisassiez',
    'cicatrisations',
    'cicatriserions',
    'cinématographe',
    'cinéthéodolite',
    'cinquante-cinq',
    'cinquante-deux',
    'cinquante-huit',
    'cinquantenaire',
    'cinquante-neuf',
    'cinquante-sept',
    'circonciraient',
    'circoncisaient',
    'circoncissions',
    'circonférences',
    'circonlocution',
    'circonscrirais',
    'circonscrirait',
    'circonscririez',
    'circonscrirons',
    'circonscriront',
    'circonscrivais',
    'circonscrivait',
    'circonscrivant',
    'circonscrivent',
    'circonscriviez',
    'circonscrivons',
    'circonspection',
    'circonstanciai',
    'circonstancias',
    'circonstanciât',
    'circonstanciée',
    'circonstanciel',
    'circonstancier',
    'circonstancies',
    'circonstanciés',
    'circonstanciez',
    'circonvenaient',
    'circonviendrai',
    'circonviendras',
    'circonviendrez',
    'circonviennent',
    'circonvinssent',
    'circonvinssiez',
    'circonvolution',
    'circulairement',
    'circumductions',
    'circumpolaires',
    'cisaillassions',
    'cisailleraient',
    'cités-dortoirs',
    'civilisassions',
    'civilisatrices',
    'civiliseraient',
    'clabaudassions',
    'clabauderaient',
    'claironnassent',
    'claironnassiez',
    'claironnerions',
    'clairs-obscurs',
    'clandestinités',
    'claquemuraient',
    'claquemurasses',
    'claquemurerais',
    'claquemurerait',
    'claquemurèrent',
    'claquemureriez',
    'claquemurerons',
    'claquemureront',
    'claquetassions',
    'claquetterions',
    'clarifiassions',
    'clarifications',
    'clarifieraient',
    'clarinettistes',
    'classifiassent',
    'classifiassiez',
    'classificateur',
    'classification',
    'classifierions',
    'claudiquassent',
    'claudiquassiez',
    'claudiquerions',
    'claustrassions',
    'claustreraient',
    'claustrophobes',
    'claustrophobie',
    'clavettassions',
    'clavetteraient',
    'clayonnassions',
    'clayonneraient',
    'clignotassions',
    'clignoteraient',
    'climatisassent',
    'climatisassiez',
    'climatisations',
    'climatiserions',
    'climatologique',
    'clinorhombique',
    'cliquetassions',
    'cliquettements',
    'cliquetterions',
    'clitoridiennes',
    'clochardisâmes',
    'clochardisasse',
    'clochardisâtes',
    'clochardiserai',
    'clochardiseras',
    'clochardiserez',
    'clochardisions',
    'cloisonnassent',
    'cloisonnassiez',
    'cloisonnements',
    'cloisonnerions',
    'clopin-clopant',
    'coagulabilités',
    'cobelligérante',
    'cobelligérants',
    'cocaïnisations',
    'cochonnassions',
    'cochonneraient',
    'cocontractante',
    'cocontractants',
    'codemanderesse',
    'coexistassions',
    'coexisteraient',
    'cohabitassions',
    'cohabiteraient',
    'cohéritassions',
    'cohériteraient',
    'coïncidassions',
    'coïncideraient',
    'colibacilloses',
    'colin-maillard',
    'collaborassent',
    'collaborassiez',
    'collaborateurs',
    'collaborations',
    'collaboratrice',
    'collaborerions',
    'collationnâmes',
    'collationnasse',
    'collationnâtes',
    'collationnerai',
    'collationneras',
    'collationnerez',
    'collationnions',
    'collectassions',
    'collecteraient',
    'collectionnais',
    'collectionnait',
    'collectionnant',
    'collectionnées',
    'collectionnent',
    'collectionnera',
    'collectionneur',
    'collectionniez',
    'collectionnons',
    'collectivement',
    'collectivisais',
    'collectivisait',
    'collectivisant',
    'collectivisées',
    'collectivisent',
    'collectivisera',
    'collectivisiez',
    'collectivismes',
    'collectivisons',
    'collectivistes',
    'collets-montés',
    'colletteraient',
    'colligeassions',
    'colloquassions',
    'colloqueraient',
    'colonisassions',
    'colonisatrices',
    'coloniseraient',
    'colportassions',
    'colporteraient',
    'combattissions',
    'combustibilité',
    'comestibilités',
    'commandassions',
    'commanderaient',
    'commanditaient',
    'commanditaires',
    'commanditasses',
    'commanditerais',
    'commanditerait',
    'commanditèrent',
    'commanditeriez',
    'commanditerons',
    'commanditeront',
    'commémoraisons',
    'commémorassent',
    'commémorassiez',
    'commémorations',
    'commémoratives',
    'commémorerions',
    'commençassions',
    'commenceraient',
    'commendataires',
    'commensalismes',
    'commentassions',
    'commentatrices',
    'commenteraient',
    'commerçassions',
    'commerceraient',
    'commercialisai',
    'commercialisas',
    'commercialisât',
    'commercialisée',
    'commercialiser',
    'commercialises',
    'commercialisés',
    'commercialisez',
    'commisérations',
    'commissionnais',
    'commissionnait',
    'commissionnant',
    'commissionnées',
    'commissionnent',
    'commissionnera',
    'commissionniez',
    'commissionnons',
    'commotionnâmes',
    'commotionnasse',
    'commotionnâtes',
    'commotionnerai',
    'commotionneras',
    'commotionnerez',
    'commotionnions',
    'communaliserai',
    'communaliseras',
    'communaliserez',
    'communalisions',
    'communautaires',
    'communiassions',
    'communicateurs',
    'communications',
    'communicatives',
    'communicatrice',
    'communieraient',
    'communiquaient',
    'communiquantes',
    'communiquasses',
    'communiquerais',
    'communiquerait',
    'communiquèrent',
    'communiqueriez',
    'communiquerons',
    'communiqueront',
    'commutativités',
    'compagnonnages',
    'comparabilités',
    'comparaissions',
    'comparaîtrions',
    'compartimentai',
    'compartimentas',
    'compartimentât',
    'compartimentée',
    'compartimenter',
    'compartimentes',
    'compartimentés',
    'compartimentez',
    'compassassions',
    'compasseraient',
    'compatibilités',
    'compatissaient',
    'compatissantes',
    'compensassions',
    'compensatoires',
    'compensatrices',
    'compenseraient',
    'compère-loriot',
    'compétitivités',
    'compissassions',
    'compisseraient',
    'complaisamment',
    'complémentaire',
    'complétassions',
    'compléteraient',
    'complexassions',
    'complexeraient',
    'complexifiâmes',
    'complexifiasse',
    'complexifiâtes',
    'complexifierai',
    'complexifieras',
    'complexifierez',
    'complexifiions',
    'complimentâmes',
    'complimentasse',
    'complimentâtes',
    'complimenterai',
    'complimenteras',
    'complimenterez',
    'complimenteurs',
    'complimenteuse',
    'complimentions',
    'compliquassent',
    'compliquassiez',
    'compliquerions',
    'complotassions',
    'comportassions',
    'comportemental',
    'comporteraient',
    'composerassent',
    'composerassiez',
    'compostassions',
    'composteraient',
    'compréhensible',
    'compréhensions',
    'compréhensives',
    'comprendraient',
    'compressassent',
    'compressassiez',
    'compresserions',
    'comprimassions',
    'comprimeraient',
    'compromettante',
    'compromettants',
    'compromettions',
    'compromettrais',
    'compromettrait',
    'compromettriez',
    'compromettrons',
    'compromettront',
    'compromissions',
    'compromissoire',
    'comptabilisais',
    'comptabilisait',
    'comptabilisant',
    'comptabilisées',
    'comptabilisent',
    'comptabilisera',
    'comptabilisiez',
    'comptabilisons',
    'compte-chèques',
    'compte-gouttes',
    'comptes-rendus',
    'compulsassions',
    'compulseraient',
    'compulsivement',
    'concassassions',
    'concasseraient',
    'concaténassent',
    'concaténassiez',
    'concaténations',
    'concaténerions',
    'concélébraient',
    'concélébrasses',
    'concélébrerais',
    'concélébrerait',
    'concélébrèrent',
    'concélébreriez',
    'concélébrerons',
    'concélébreront',
    'concentrassent',
    'concentrassiez',
    'concentrations',
    'concentrerions',
    'conceptualisai',
    'conceptualisas',
    'conceptualisât',
    'conceptualisée',
    'conceptualiser',
    'conceptualises',
    'conceptualisés',
    'conceptualisez',
    'conceptualisme',
    'concernassions',
    'concerneraient',
    'concertassions',
    'concerteraient',
    'conchyliologie',
    'conciliassions',
    'conciliatoires',
    'conciliatrices',
    'concilieraient',
    'concoctassions',
    'concocteraient',
    'concordassions',
    'concordataires',
    'concorderaient',
    'concourussions',
    'concrétassions',
    'concréteraient',
    'concrétisaient',
    'concrétisasses',
    'concrétisation',
    'concrétiserais',
    'concrétiserait',
    'concrétisèrent',
    'concrétiseriez',
    'concrétiserons',
    'concrétiseront',
    'concupiscences',
    'concupiscentes',
    'concurrençâmes',
    'concurrençasse',
    'concurrençâtes',
    'concurrencerai',
    'concurrenceras',
    'concurrencerez',
    'concurrencions',
    'concurrentiels',
    'condamnassions',
    'condamnatoires',
    'condamneraient',
    'condensassions',
    'condenseraient',
    'condescendance',
    'condescendante',
    'condescendants',
    'condescendîmes',
    'condescendions',
    'condescendisse',
    'condescendîtes',
    'condescendrais',
    'condescendrait',
    'condescendriez',
    'condescendrons',
    'condescendront',
    'conditionnâmes',
    'conditionnasse',
    'conditionnâtes',
    'conditionnelle',
    'conditionnerai',
    'conditionneras',
    'conditionnerez',
    'conditionneurs',
    'conditionneuse',
    'conditionnions',
    'conductibilité',
    'conduisissions',
    'confectionnais',
    'confectionnait',
    'confectionnant',
    'confectionnées',
    'confectionnent',
    'confectionnera',
    'confectionneur',
    'confectionniez',
    'confectionnons',
    'confédérassent',
    'confédérassiez',
    'confédérations',
    'confédérerions',
    'conférencières',
    'confessassions',
    'confesseraient',
    'confessionnaux',
    'confessionnels',
    'confidentielle',
    'configurassent',
    'configurassiez',
    'configurations',
    'configurerions',
    'confirmassions',
    'confirmeraient',
    'confisquassent',
    'confisquassiez',
    'confisquerions',
    'conflagrations',
    'conflictuelles',
    'confondissions',
    'conformassions',
    'conformeraient',
    'confortassions',
    'conforteraient',
    'confraternelle',
    'confraternités',
    'confrontassent',
    'confrontassiez',
    'confrontations',
    'confronterions',
    'confucianismes',
    'confucianistes',
    'confusionnelle',
    'confusionnisme',
    'confusionniste',
    'congédiassions',
    'congédieraient',
    'congestionnais',
    'congestionnait',
    'congestionnant',
    'congestionnées',
    'congestionnent',
    'congestionnera',
    'congestionniez',
    'congestionnons',
    'congloméraient',
    'conglomérasses',
    'conglomérerais',
    'conglomérerait',
    'conglomérèrent',
    'congloméreriez',
    'conglomérerons',
    'congloméreront',
    'conglutinaient',
    'conglutinasses',
    'conglutination',
    'conglutinerais',
    'conglutinerait',
    'conglutinèrent',
    'conglutineriez',
    'conglutinerons',
    'conglutineront',
    'congratulaient',
    'congratulasses',
    'congratulation',
    'congratulerais',
    'congratulerait',
    'congratulèrent',
    'congratuleriez',
    'congratulerons',
    'congratuleront',
    'congréganistes',
    'conjecturaient',
    'conjecturasses',
    'conjecturerais',
    'conjecturerait',
    'conjecturèrent',
    'conjectureriez',
    'conjecturerons',
    'conjectureront',
    'conjoignissent',
    'conjoignissiez',
    'conjoindraient',
    'conjonctivales',
    'conjonctivites',
    'conjoncturelle',
    'conjuguassions',
    'conjugueraient',
    'connaissements',
    'connectassions',
    'connecteraient',
    'conpressassent',
    'conpressassiez',
    'conpresserions',
    'conquistadores',
    'consacrassions',
    'consacreraient',
    'consanguinités',
    'consciencieuse',
    'conseillassent',
    'conseillassiez',
    'conseillerions',
    'consentiraient',
    'consentissions',
    'conservassions',
    'conservatismes',
    'conservatoires',
    'conservatrices',
    'conserveraient',
    'considérassent',
    'considérassiez',
    'considérations',
    'considérerions',
    'consignassions',
    'consignataires',
    'consigneraient',
    'consistassions',
    'consisteraient',
    'consistoriales',
    'consolidassent',
    'consolidassiez',
    'consolidations',
    'consoliderions',
    'consommassions',
    'consommatrices',
    'consommeraient',
    'consonantiques',
    'consonantismes',
    'conspirassions',
    'conspiratrices',
    'conspireraient',
    'constatassions',
    'constateraient',
    'constellassent',
    'constellassiez',
    'constellations',
    'constellerions',
    'consternassent',
    'consternassiez',
    'consternations',
    'consternerions',
    'constipassions',
    'constiperaient',
    'constituassent',
    'constituassiez',
    'constituerions',
    'constructrices',
    'construiraient',
    'construisaient',
    'construisirent',
    'construisisses',
    'consubstantiel',
    'consultassions',
    'consulteraient',
    'contactassions',
    'contacteraient',
    'contagionnâmes',
    'contagionnasse',
    'contagionnâtes',
    'contagionnerai',
    'contagionneras',
    'contagionnerez',
    'contagionnions',
    'containerisais',
    'containerisait',
    'containerisant',
    'containerisées',
    'containerisent',
    'containerisera',
    'containerisiez',
    'containerisons',
    'contaminassent',
    'contaminassiez',
    'contaminations',
    'contaminerions',
    'contemplassent',
    'contemplassiez',
    'contemplateurs',
    'contemplations',
    'contemplatives',
    'contemplatrice',
    'contemplerions',
    'contemporaines',
    'conteneurisais',
    'conteneurisait',
    'conteneurisant',
    'conteneurisées',
    'conteneurisent',
    'conteneurisera',
    'conteneurisiez',
    'conteneurisons',
    'contentassions',
    'contenteraient',
    'contestassions',
    'contestataires',
    'contesteraient',
    'contiendraient',
    'continentalité',
    'contingentâmes',
    'contingentasse',
    'contingentâtes',
    'contingenterai',
    'contingenteras',
    'contingenterez',
    'contingentions',
    'continuassions',
    'continuatrices',
    'continueraient',
    'contorsionnais',
    'contorsionnait',
    'contorsionnant',
    'contorsionnées',
    'contorsionnent',
    'contorsionnera',
    'contorsionniez',
    'contorsionnons',
    'contournassent',
    'contournassiez',
    'contournements',
    'contournerions',
    'contraceptions',
    'contraceptives',
    'contractassent',
    'contractassiez',
    'contracterions',
    'contractilités',
    'contractualisa',
    'contractualise',
    'contractualisé',
    'contractuelles',
    'contracturâmes',
    'contracturasse',
    'contracturâtes',
    'contracturerai',
    'contractureras',
    'contracturerez',
    'contracturions',
    'contradicteurs',
    'contradictions',
    'contradictoire',
    'contraignables',
    'contraignaient',
    'contraignantes',
    'contraignirent',
    'contraignisses',
    'contraindrions',
    'contrapontiste',
    'contraposaient',
    'contraposasses',
    'contraposerais',
    'contraposerait',
    'contraposèrent',
    'contraposeriez',
    'contraposerons',
    'contraposeront',
    'contrapuntique',
    'contrapuntiste',
    'contrariassent',
    'contrariassiez',
    'contrarierions',
    'contrarotatifs',
    'contrarotative',
    'contrastassent',
    'contrastassiez',
    'contrasterions',
    'contraventions',
    'contre-amiraux',
    'contre-attaqua',
    'contre-attaque',
    'contre-attaqué',
    'contrebalançai',
    'contrebalanças',
    'contrebalançât',
    'contrebalancée',
    'contrebalancer',
    'contrebalances',
    'contrebalancés',
    'contrebalancez',
    'contrebandiers',
    'contrebassiste',
    'contrebatterie',
    'contrebattîmes',
    'contrebattions',
    'contrebattisse',
    'contrebattîtes',
    'contrebattrais',
    'contrebattrait',
    'contrebattriez',
    'contrebattrons',
    'contrebattront',
    'contreboutâmes',
    'contreboutasse',
    'contreboutâtes',
    'contrebouterai',
    'contrebouteras',
    'contrebouterez',
    'contreboutions',
    'contrebutaient',
    'contre-butâmes',
    'contre-butasse',
    'contrebutasses',
    'contre-butâtes',
    'contre-buterai',
    'contrebuterais',
    'contrebuterait',
    'contre-buteras',
    'contrebutèrent',
    'contre-buterez',
    'contrebuteriez',
    'contrebuterons',
    'contrebuteront',
    'contre-butions',
    'contrecarrâmes',
    'contrecarrasse',
    'contrecarrâtes',
    'contrecarrerai',
    'contrecarreras',
    'contrecarrerez',
    'contrecarrions',
    'contre-caution',
    'contre-courant',
    'contrediraient',
    'contredisaient',
    'contredissions',
    'contre-enquête',
    'contre-épreuve',
    'contre-exemple',
    'contrefacteurs',
    'contrefactrice',
    'contrefaisions',
    'contrefassions',
    'contreferaient',
    'contrefichâmes',
    'contrefichasse',
    'contrefichâtes',
    'contreficherai',
    'contreficheras',
    'contreficherez',
    'contrefichions',
    'contrefissions',
    'contrefoutions',
    'contrefoutrais',
    'contrefoutrait',
    'contrefoutriez',
    'contrefoutrons',
    'contrefoutront',
    'contre-hermine',
    'contre-indiqua',
    'contre-indique',
    'contre-indiqué',
    'contre-lettres',
    'contremandâmes',
    'contremandasse',
    'contremandâtes',
    'contremanderai',
    'contremanderas',
    'contremanderez',
    'contremandions',
    'contremarquais',
    'contremarquait',
    'contremarquant',
    'contremarquées',
    'contremarquent',
    'contremarquera',
    'contremarquiez',
    'contremarquons',
    'contre-mesures',
    'contre-minâmes',
    'contre-minasse',
    'contre-minâtes',
    'contre-minerai',
    'contre-mineras',
    'contre-minerez',
    'contre-minions',
    'contre-murâmes',
    'contre-murasse',
    'contre-murâtes',
    'contre-murerai',
    'contre-mureras',
    'contre-murerez',
    'contre-murions',
    'contre-passais',
    'contre-passait',
    'contre-passant',
    'contre-passées',
    'contre-passent',
    'contre-passera',
    'contre-passiez',
    'contre-passons',
    'contrepèteries',
    'contre-placage',
    'contre-plaquai',
    'contre-plaquas',
    'contre-plaquât',
    'contre-plaquée',
    'contre-plaquer',
    'contre-plaques',
    'contre-plaqués',
    'contre-plaquez',
    'contre-plongée',
    'contre-pointes',
    'contre-projets',
    'contre-réforme',
    'contre-scellai',
    'contre-scellas',
    'contre-scellât',
    'contre-scellée',
    'contre-sceller',
    'contre-scelles',
    'contre-scellés',
    'contre-scellez',
    'contresignâmes',
    'contresignasse',
    'contresignâtes',
    'contresignerai',
    'contresigneras',
    'contresignerez',
    'contresignions',
    'contre-tailles',
    'contre-timbres',
    'contre-tirâmes',
    'contre-tirasse',
    'contre-tirâtes',
    'contre-tirerai',
    'contre-tireras',
    'contre-tirerez',
    'contre-tirions',
    'contre-valeurs',
    'contrevenaient',
    'contrevenantes',
    'contre-vérités',
    'contreviendrai',
    'contreviendras',
    'contreviendrez',
    'contreviennent',
    'contrevinssent',
    'contrevinssiez',
    'contre-visites',
    'contribuassent',
    'contribuassiez',
    'contribuerions',
    'contristassent',
    'contristassiez',
    'contristerions',
    'contrôlassions',
    'controlatérale',
    'controlatéraux',
    'contrôleraient',
    'controuvassent',
    'controuvassiez',
    'controuverions',
    'controversable',
    'controversâmes',
    'controversasse',
    'controversâtes',
    'controverserai',
    'controverseras',
    'controverserez',
    'controversions',
    'controversiste',
    'contusionnâmes',
    'contusionnasse',
    'contusionnâtes',
    'contusionnerai',
    'contusionneras',
    'contusionnerez',
    'contusionnions',
    'convaincraient',
    'convainquaient',
    'convainquirent',
    'convainquisses',
    'convalescences',
    'convalescentes',
    'convenablement',
    'conventionnais',
    'conventionnait',
    'conventionnant',
    'conventionnées',
    'conventionnels',
    'conventionnent',
    'conventionnera',
    'conventionniez',
    'conventionnons',
    'convergeassent',
    'convergeassiez',
    'convergeraient',
    'conversassions',
    'converseraient',
    'convertibilité',
    'convertiraient',
    'convertisseurs',
    'convertissions',
    'conviendraient',
    'convoitassions',
    'convoiteraient',
    'convoquassions',
    'convoqueraient',
    'convulsassions',
    'convulseraient',
    'convulsionnais',
    'convulsionnait',
    'convulsionnant',
    'convulsionnées',
    'convulsionnent',
    'convulsionnera',
    'convulsionniez',
    'convulsionnons',
    'convulsivement',
    'coordinatrices',
    'coordonnassent',
    'coordonnassiez',
    'coordonnateurs',
    'coordonnatrice',
    'coordonnerions',
    'copartageantes',
    'copermutassent',
    'copermutassiez',
    'copermuterions',
    'coperniciennes',
    'copposédassent',
    'copposédassiez',
    'copposéderions',
    'copropriétaire',
    'coquelucheuses',
    'coquetteraient',
    'coquillassions',
    'coquilleraient',
    'cordelleraient',
    'cordonnassions',
    'cordonneraient',
    'corpusculaires',
    'correctionnels',
    'correspondance',
    'correspondante',
    'correspondants',
    'correspondîmes',
    'correspondions',
    'correspondisse',
    'correspondîtes',
    'correspondrais',
    'correspondrait',
    'correspondriez',
    'correspondrons',
    'correspondront',
    'corrigeassions',
    'corroborassent',
    'corroborassiez',
    'corroborations',
    'corroborerions',
    'corrompissions',
    'cosmétiquaient',
    'cosmétiquasses',
    'cosmétiquerais',
    'cosmétiquerait',
    'cosmétiquèrent',
    'cosmétiqueriez',
    'cosmétiquerons',
    'cosmétiqueront',
    'cosmographique',
    'cosmopolitisme',
    'couchaillasses',
    'couchaillerais',
    'couchaillerait',
    'couchaillèrent',
    'couchailleriez',
    'couchaillerons',
    'couchailleront',
    'couche-culotte',
    'couillonnaient',
    'couillonnasses',
    'couillonnerais',
    'couillonnerait',
    'couillonnèrent',
    'couillonneriez',
    'couillonnerons',
    'couillonneront',
    'coulissassions',
    'coulisseraient',
    'coupaillassent',
    'coupaillassiez',
    'coupaillerions',
    'coupellassions',
    'coupelleraient',
    'coupon-réponse',
    'courageusement',
    'couraillassent',
    'couraillassiez',
    'couraillerions',
    'courbaturaient',
    'courbaturasses',
    'courbaturerais',
    'courbaturerait',
    'courbaturèrent',
    'courbatureriez',
    'courbaturerons',
    'courbatureront',
    'couronnassions',
    'couronneraient',
    'courrouçassent',
    'courrouçassiez',
    'courroucerions',
    'courtaudassent',
    'courtaudassiez',
    'courtauderions',
    'court-bouillon',
    'court-circuita',
    'court-circuite',
    'court-circuité',
    'court-courrier',
    'courtisaneries',
    'courtisassions',
    'courtiseraient',
    'couteaux-scies',
    'crachotassions',
    'crachoteraient',
    'crachouillâmes',
    'crachouillasse',
    'crachouillâtes',
    'crachouillerai',
    'crachouilleras',
    'crachouillerez',
    'crachouillions',
    'cramponnassent',
    'cramponnassiez',
    'cramponnements',
    'cramponnerions',
    'crapahutassent',
    'crapahutassiez',
    'crapahuterions',
    'crapaütassions',
    'crapaüteraient',
    'crapuleusement',
    'craquelassions',
    'craquellements',
    'craquellerions',
    'craquetassions',
    'craquettements',
    'craquetterions',
    'cravachassions',
    'cravacheraient',
    'crayonnassions',
    'crayonneraient',
    'crénelleraient',
    'créolisassions',
    'créoliseraient',
    'créosotassions',
    'créosoteraient',
    'crépusculaires',
    'crétinisassent',
    'crétinisassiez',
    'crétiniserions',
    'crevassassions',
    'crevasseraient',
    'criaillassions',
    'criailleraient',
    'criminalisâmes',
    'criminalisasse',
    'criminalisâtes',
    'criminaliserai',
    'criminaliseras',
    'criminaliserez',
    'criminalisions',
    'criminellement',
    'criminologiste',
    'cristallisâmes',
    'cristallisante',
    'cristallisants',
    'cristallisasse',
    'cristallisâtes',
    'cristalliserai',
    'cristalliseras',
    'cristalliserez',
    'cristallisions',
    'criticaillâmes',
    'criticaillasse',
    'criticaillâtes',
    'criticaillerai',
    'criticailleras',
    'criticaillerez',
    'criticaillions',
    'critiquassions',
    'critiqueraient',
    'crochetassions',
    'crochèteraient',
    'crocs-en-jambe',
    'croquemitaines',
    'croustillaient',
    'croustillantes',
    'croustillasses',
    'croustillerais',
    'croustillerait',
    'croustillèrent',
    'croustilleriez',
    'croustillerons',
    'croustilleront',
    'crucifiassions',
    'crucifieraient',
    'cruciverbistes',
    'cryptographies',
    'cuirassassions',
    'cuirasseraient',
    'culpabilisâmes',
    'culpabilisante',
    'culpabilisants',
    'culpabilisasse',
    'culpabilisâtes',
    'culpabiliserai',
    'culpabiliseras',
    'culpabiliserez',
    'culpabilisions',
    'cumulativement',
    'curiethérapies',
    'cybernéticiens',
    'cyclomotoriste',
    'cyclothymiques',
    'cylindrassions',
    'cylindreraient',
    'cytodiagnostic',
    'cytogénéticien',
    'cytogénétiques',
    'cytoplasmiques',
    'dactylographes',
    'dactylographia',
    'dactylographie',
    'dactylographié',
    'dactyloscopies',
    'daguerréotypes',
    'damasquinaient',
    'damasquinasses',
    'damasquinerais',
    'damasquinerait',
    'damasquinèrent',
    'damasquineriez',
    'damasquinerons',
    'damasquineront',
    'dangereusement',
    'dansottassions',
    'dansotteraient',
    'déactivassions',
    'déactiveraient',
    'déambulassions',
    'déambulatoires',
    'déambuleraient',
    'débagoulassent',
    'débagoulassiez',
    'débagoulerions',
    'débâillonnâmes',
    'débâillonnasse',
    'débâillonnâtes',
    'débâillonnerai',
    'débâillonneras',
    'débâillonnerez',
    'débâillonnions',
    'déballonnaient',
    'déballonnasses',
    'déballonnerais',
    'déballonnerait',
    'déballonnèrent',
    'déballonneriez',
    'déballonnerons',
    'déballonneront',
    'débalourdaient',
    'débalourdasses',
    'débalourderais',
    'débalourderait',
    'débalourdèrent',
    'débalourderiez',
    'débalourderons',
    'débalourderont',
    'débanalisaient',
    'débanalisasses',
    'débanaliserais',
    'débanaliserait',
    'débanalisèrent',
    'débanaliseriez',
    'débanaliserons',
    'débanaliseront',
    'débaptisassent',
    'débaptisassiez',
    'débaptiserions',
    'débarbouillage',
    'débarbouillais',
    'débarbouillait',
    'débarbouillant',
    'débarbouillées',
    'débarbouillent',
    'débarbouillera',
    'débarbouilliez',
    'débarbouillons',
    'débarquassions',
    'débarqueraient',
    'débarrassaient',
    'débarrassasses',
    'débarrasserais',
    'débarrasserait',
    'débarrassèrent',
    'débarrasseriez',
    'débarrasserons',
    'débarrasseront',
    'débauchassions',
    'débaucheraient',
    'débecquetaient',
    'débecquetasses',
    'débecquetèrent',
    'débecquetterai',
    'débecquetteras',
    'débecquetterez',
    'débenzolassent',
    'débenzolassiez',
    'débenzolerions',
    'débilitassions',
    'débiliteraient',
    'débillardaient',
    'débillardasses',
    'débillarderais',
    'débillarderait',
    'débillardèrent',
    'débillarderiez',
    'débillarderons',
    'débillarderont',
    'déblatérassent',
    'déblatérassiez',
    'déblatérerions',
    'débleuissaient',
    'débloquassions',
    'débloqueraient',
    'débobinassions',
    'débobineraient',
    'débonnairement',
    'débosselassent',
    'débosselassiez',
    'débossellerais',
    'débossellerait',
    'débosselleriez',
    'débossellerons',
    'débosselleront',
    'débouchassions',
    'déboucheraient',
    'débouclassions',
    'déboucleraient',
    'débouillirions',
    'débouillissent',
    'débouillissiez',
    'déboulonnaient',
    'déboulonnasses',
    'déboulonnement',
    'déboulonnerais',
    'déboulonnerait',
    'déboulonnèrent',
    'déboulonneriez',
    'déboulonnerons',
    'déboulonneront',
    'débouquassions',
    'débouqueraient',
    'débourbassions',
    'débourberaient',
    'débourgeoisées',
    'débourrassions',
    'débourreraient',
    'déboursassions',
    'débourseraient',
    'déboussolaient',
    'déboussolasses',
    'déboussolerais',
    'déboussolerait',
    'déboussolèrent',
    'déboussoleriez',
    'déboussolerons',
    'déboussoleront',
    'déboutonnaient',
    'déboutonnasses',
    'déboutonnerais',
    'déboutonnerait',
    'déboutonnèrent',
    'déboutonneriez',
    'déboutonnerons',
    'déboutonneront',
    'débraguettâmes',
    'débraguettasse',
    'débraguettâtes',
    'débraguetterai',
    'débraguetteras',
    'débraguetterez',
    'débraguettions',
    'débraillassent',
    'débraillassiez',
    'débraillerions',
    'débranchassent',
    'débranchassiez',
    'débranchements',
    'débrancherions',
    'débrochassions',
    'débrocheraient',
    'débrouillaient',
    'débrouillardes',
    'débrouillasses',
    'débrouillement',
    'débrouillerais',
    'débrouillerait',
    'débrouillèrent',
    'débrouilleriez',
    'débrouillerons',
    'débrouilleront',
    'débroussaillai',
    'débroussaillas',
    'débroussaillât',
    'débroussaillée',
    'débroussailler',
    'débroussailles',
    'débroussaillés',
    'débroussaillez',
    'débudgétisâmes',
    'débudgétisasse',
    'débudgétisâtes',
    'débudgétiserai',
    'débudgétiseras',
    'débudgétiserez',
    'débudgétisions',
    'débusquassions',
    'débusqueraient',
    'décachetassent',
    'décachetassiez',
    'décachetterais',
    'décachetterait',
    'décachetteriez',
    'décachetterons',
    'décachetteront',
    'décadenassâmes',
    'décadenassasse',
    'décadenassâtes',
    'décadenasserai',
    'décadenasseras',
    'décadenasserez',
    'décadenassions',
    'décaféinassent',
    'décaféinassiez',
    'décaféinerions',
    'décaissassions',
    'décaisseraient',
    'décalaminaient',
    'décalaminasses',
    'décalaminerais',
    'décalaminerait',
    'décalaminèrent',
    'décalamineriez',
    'décalaminerons',
    'décalamineront',
    'décalcifiaient',
    'décalcifiasses',
    'décalcifierais',
    'décalcifierait',
    'décalcifièrent',
    'décalcifieriez',
    'décalcifierons',
    'décalcifieront',
    'décalottassent',
    'décalottassiez',
    'décalotterions',
    'décalquassions',
    'décalqueraient',
    'décanillassent',
    'décanillassiez',
    'décanillerions',
    'décapelassions',
    'décapellerions',
    'décapitalisais',
    'décapitalisait',
    'décapitalisant',
    'décapitalisées',
    'décapitalisent',
    'décapitalisera',
    'décapitalisiez',
    'décapitalisons',
    'décapitassions',
    'décapiteraient',
    'décapotassions',
    'décapoteraient',
    'décapsulassent',
    'décapsulassiez',
    'décapsulations',
    'décapsulerions',
    'décapuchonnais',
    'décapuchonnait',
    'décapuchonnant',
    'décapuchonnées',
    'décapuchonnent',
    'décapuchonnera',
    'décapuchonniez',
    'décapuchonnons',
    'décarbonatâmes',
    'décarbonatasse',
    'décarbonatâtes',
    'décarbonaterai',
    'décarbonateras',
    'décarbonaterez',
    'décarbonations',
    'décarburassent',
    'décarburassiez',
    'décarburations',
    'décarburerions',
    'décarcassaient',
    'décarcassasses',
    'décarcasserais',
    'décarcasserait',
    'décarcassèrent',
    'décarcasseriez',
    'décarcasserons',
    'décarcasseront',
    'décarrelassent',
    'décarrelassiez',
    'décarrellerais',
    'décarrellerait',
    'décarrelleriez',
    'décarrellerons',
    'décarrelleront',
    'décartonnaient',
    'décartonnasses',
    'décartonnerais',
    'décartonnerait',
    'décartonnèrent',
    'décartonneriez',
    'décartonnerons',
    'décartonneront',
    'décasyllabique',
    'décathlonienne',
    'décélérassions',
    'décéléreraient',
    'décentralisais',
    'décentralisait',
    'décentralisant',
    'décentralisées',
    'décentralisent',
    'décentralisera',
    'décentralisiez',
    'décentralisons',
    'décentrassions',
    'décentreraient',
    'décerclassions',
    'décercleraient',
    'déchagrinaient',
    'déchagrinasses',
    'déchagrinerais',
    'déchagrinerait',
    'déchagrinèrent',
    'déchagrineriez',
    'déchagrinerons',
    'déchagrineront',
    'déchaînassions',
    'déchaîneraient',
    'déchantassions',
    'déchanteraient',
    'déchaperonnais',
    'déchaperonnait',
    'déchaperonnant',
    'déchaperonnées',
    'déchaperonnent',
    'déchaperonnera',
    'déchaperonniez',
    'déchaperonnons',
    'déchargeassent',
    'déchargeassiez',
    'déchargeraient',
    'décharnassions',
    'décharneraient',
    'déchaumassions',
    'déchaumeraient',
    'déchaussassent',
    'déchaussassiez',
    'déchaussements',
    'déchausserions',
    'déchevêtraient',
    'déchevêtrasses',
    'déchevêtrerais',
    'déchevêtrerait',
    'déchevêtrèrent',
    'déchevêtreriez',
    'déchevêtrerons',
    'déchevêtreront',
    'déchevillaient',
    'déchevillasses',
    'déchevillerais',
    'déchevillerait',
    'déchevillèrent',
    'déchevilleriez',
    'déchevillerons',
    'déchevilleront',
    'déchiffonnâmes',
    'déchiffonnasse',
    'déchiffonnâtes',
    'déchiffonnerai',
    'déchiffonneras',
    'déchiffonnerez',
    'déchiffonnions',
    'déchiffrassent',
    'déchiffrassiez',
    'déchiffrements',
    'déchiffrerions',
    'déchiquetaient',
    'déchiquetasses',
    'déchiquetèrent',
    'déchiqueteuses',
    'déchiquetterai',
    'déchiquetteras',
    'déchiquetterez',
    'déchloruraient',
    'déchlorurasses',
    'déchlorurerais',
    'déchlorurerait',
    'déchlorurèrent',
    'déchlorureriez',
    'déchlorurerons',
    'déchlorureront',
    'déchristianisa',
    'déchristianise',
    'déchristianisé',
    'déchromassions',
    'déchromeraient',
    'déchronologies',
    'décimalisaient',
    'décimalisasses',
    'décimalisation',
    'décimaliserais',
    'décimaliserait',
    'décimalisèrent',
    'décimaliseriez',
    'décimaliserons',
    'décimaliseront',
    'décintrassions',
    'décintrerasses',
    'décintrerèrent',
    'décisionnaires',
    'décisionnelles',
    'déclassassions',
    'déclasseraient',
    'déclavetassent',
    'déclavetassiez',
    'déclavetterais',
    'déclavetterait',
    'déclavetteriez',
    'déclavetterons',
    'déclavetteront',
    'déclenchassent',
    'déclenchassiez',
    'déclenchements',
    'déclencherions',
    'décléricalisai',
    'décléricalisas',
    'décléricalisât',
    'décléricalisée',
    'décléricaliser',
    'décléricalises',
    'décléricalisés',
    'décléricalisez',
    'décliquetaient',
    'décliquetasses',
    'décliquetèrent',
    'décliquetterai',
    'décliquetteras',
    'décliquetterez',
    'décloisonnâmes',
    'décloisonnasse',
    'décloisonnâtes',
    'décloisonnerai',
    'décloisonneras',
    'décloisonnerez',
    'décloisonnions',
    'décoffrassions',
    'décoffreraient',
    'décoiffassions',
    'décoifferaient',
    'décoinçassions',
    'décoinceraient',
    'décolérassions',
    'décoléreraient',
    'décolletassent',
    'décolletassiez',
    'décolletterais',
    'décolletterait',
    'décolletteriez',
    'décolletterons',
    'décolletteront',
    'décolonisaient',
    'décolonisasses',
    'décolonisation',
    'décoloniserais',
    'décoloniserait',
    'décolonisèrent',
    'décoloniseriez',
    'décoloniserons',
    'décoloniseront',
    'décolorassions',
    'décoloreraient',
    'décommandaient',
    'décommandasses',
    'décommanderais',
    'décommanderait',
    'décommandèrent',
    'décommanderiez',
    'décommanderons',
    'décommanderont',
    'décompensation',
    'décomplexaient',
    'décomplexasses',
    'décomplexerais',
    'décomplexerait',
    'décomplexèrent',
    'décomplexeriez',
    'décomplexerons',
    'décomplexeront',
    'décomposassent',
    'décomposassiez',
    'décomposerions',
    'décompositions',
    'décompressâmes',
    'décompressasse',
    'décompressâtes',
    'décompresserai',
    'décompresseras',
    'décompresserez',
    'décompressions',
    'décomprimaient',
    'décomprimasses',
    'décomprimerais',
    'décomprimerait',
    'décomprimèrent',
    'décomprimeriez',
    'décomprimerons',
    'décomprimeront',
    'décomptassions',
    'décompteraient',
    'déconcentrâmes',
    'déconcentrasse',
    'déconcentrâtes',
    'déconcentrerai',
    'déconcentreras',
    'déconcentrerez',
    'déconcentrions',
    'déconcertaient',
    'déconcertantes',
    'déconcertasses',
    'déconcerterais',
    'déconcerterait',
    'déconcertèrent',
    'déconcerteriez',
    'déconcerterons',
    'déconcerteront',
    'déconditionnai',
    'déconditionnas',
    'déconditionnât',
    'déconditionnée',
    'déconditionner',
    'déconditionnes',
    'déconditionnés',
    'déconditionnez',
    'décongelassent',
    'décongelassiez',
    'décongélations',
    'décongèlerions',
    'décongestionna',
    'décongestionne',
    'décongestionné',
    'déconnectaient',
    'déconnectasses',
    'déconnecterais',
    'déconnecterait',
    'déconnectèrent',
    'déconnecteriez',
    'déconnecterons',
    'déconnecteront',
    'déconseillâmes',
    'déconseillasse',
    'déconseillâtes',
    'déconseillerai',
    'déconseilleras',
    'déconseillerez',
    'déconseillions',
    'déconsidérâmes',
    'déconsidérasse',
    'déconsidérâtes',
    'déconsidérerai',
    'déconsidéreras',
    'déconsidérerez',
    'déconsidérions',
    'déconsignaient',
    'déconsignasses',
    'déconsignerais',
    'déconsignerait',
    'déconsignèrent',
    'déconsigneriez',
    'déconsignerons',
    'déconsigneront',
    'déconstipaient',
    'déconstipasses',
    'déconstiperais',
    'déconstiperait',
    'déconstipèrent',
    'déconstiperiez',
    'déconstiperons',
    'déconstiperont',
    'décontaminâmes',
    'décontaminasse',
    'décontaminâtes',
    'décontaminerai',
    'décontamineras',
    'décontaminerez',
    'décontaminions',
    'décontenançais',
    'décontenançait',
    'décontenançant',
    'décontenancées',
    'décontenancent',
    'décontenancera',
    'décontenanciez',
    'décontenançons',
    'décontractâmes',
    'décontractasse',
    'décontractâtes',
    'décontracterai',
    'décontracteras',
    'décontracterez',
    'décontractions',
    'décortications',
    'décortiquaient',
    'décortiquasses',
    'décortiquerais',
    'décortiquerait',
    'décortiquèrent',
    'décortiqueriez',
    'décortiquerons',
    'décortiqueront',
    'découchassions',
    'découcheraient',
    'découplassions',
    'découpleraient',
    'décourageaient',
    'décourageantes',
    'décourageasses',
    'découragements',
    'découragerions',
    'découronnaient',
    'découronnasses',
    'découronnement',
    'découronnerais',
    'découronnerait',
    'découronnèrent',
    'découronneriez',
    'découronnerons',
    'découronneront',
    'découvriraient',
    'découvrissions',
    'décrassassions',
    'décrasseraient',
    'décréditassent',
    'décréditassiez',
    'décréditerions',
    'décrépissaient',
    'décrépitassent',
    'décrépitassiez',
    'décrépiterions',
    'décreusassions',
    'décreuseraient',
    'décriminalisai',
    'décriminalisas',
    'décriminalisât',
    'décriminalisée',
    'décriminaliser',
    'décriminalises',
    'décriminalisés',
    'décriminalisez',
    'décrispassions',
    'décrisperaient',
    'décrochassions',
    'décrocheraient',
    'décroisassions',
    'décroiseraient',
    'décroissements',
    'décrottassions',
    'décrotteraient',
    'décroûtassions',
    'décroûteraient',
    'décryptassions',
    'décrypteraient',
    'décuivrassions',
    'décuivreraient',
    'déculassassent',
    'déculassassiez',
    'déculasserions',
    'déculottassent',
    'déculottassiez',
    'déculotterions',
    'déculpabilisai',
    'déculpabilisas',
    'déculpabilisât',
    'déculpabilisée',
    'déculpabiliser',
    'déculpabilises',
    'déculpabilisés',
    'déculpabilisez',
    'dédaignassions',
    'dédaigneraient',
    'dédicaçassions',
    'dédicaceraient',
    'dédifférenciai',
    'dédifférencias',
    'dédifférenciât',
    'dédifférenciée',
    'dédifférencier',
    'dédifférencies',
    'dédifférenciés',
    'dédifférenciez',
    'dédommageaient',
    'dédommageasses',
    'dédommagements',
    'dédommagerions',
    'dédouanassions',
    'dédouaneraient',
    'dédoublassions',
    'dédoubleraient',
    'dédramatisâmes',
    'dédramatisasse',
    'dédramatisâtes',
    'dédramatiserai',
    'dédramatiseras',
    'dédramatiserez',
    'dédramatisions',
    'défailliraient',
    'défaillissions',
    'défalquassions',
    'défalqueraient',
    'défatiguassent',
    'défatiguassiez',
    'défatiguerions',
    'défaufilassent',
    'défaufilassiez',
    'défaufilerions',
    'défaussassions',
    'défausseraient',
    'défavorisaient',
    'défavorisasses',
    'défavoriserais',
    'défavoriserait',
    'défavorisèrent',
    'défavoriseriez',
    'défavoriserons',
    'défavoriseront',
    'défenestration',
    'déferrisassent',
    'déferrisassiez',
    'déferriserions',
    'défervescences',
    'défeuillaisons',
    'défeuillassent',
    'défeuillassiez',
    'défeuillerions',
    'défeutrassions',
    'défeutreraient',
    'défibrillateur',
    'défibrillation',
    'déficelassions',
    'déficellerions',
    'défigurassions',
    'défigureraient',
    'définitionnels',
    'définitivement',
    'déflagrassions',
    'déflagreraient',
    'déflaquassions',
    'déflaqueraient',
    'déflationniste',
    'défleuriraient',
    'défleurissions',
    'déforestations',
    'défouraillâmes',
    'défouraillasse',
    'défouraillâtes',
    'défouraillerai',
    'défourailleras',
    'défouraillerez',
    'défouraillions',
    'défournassions',
    'défourneraient',
    'défourrassions',
    'défourreraient',
    'défraîchirions',
    'défraîchissais',
    'défraîchissait',
    'défraîchissant',
    'défraîchissent',
    'défraîchissiez',
    'défraîchissons',
    'défrancisaient',
    'défrancisasses',
    'défranciserais',
    'défranciserait',
    'défrancisèrent',
    'défranciseriez',
    'défranciserons',
    'défranciseront',
    'défrettassions',
    'défretteraient',
    'défrichassions',
    'défricheraient',
    'défringuassent',
    'défringuassiez',
    'défringuerions',
    'défroissassent',
    'défroissassiez',
    'défroisserions',
    'défronçassions',
    'défronceraient',
    'défroquassions',
    'défroqueraient',
    'défruitassions',
    'défruiteraient',
    'dégalonnassent',
    'dégalonnassiez',
    'dégalonnerions',
    'dégarnissaient',
    'dégasolinaient',
    'dégasolinasses',
    'dégasolinerais',
    'dégasolinerait',
    'dégasolinèrent',
    'dégasolineriez',
    'dégasolinerons',
    'dégasolineront',
    'dégauchiraient',
    'dégauchissages',
    'dégauchisseuse',
    'dégauchissions',
    'dégazolinaient',
    'dégazolinasses',
    'dégazolinerais',
    'dégazolinerait',
    'dégazolinèrent',
    'dégazolineriez',
    'dégazolinerons',
    'dégazolineront',
    'dégazonnassent',
    'dégazonnassiez',
    'dégazonnements',
    'dégazonnerions',
    'dégénérassions',
    'dégénéreraient',
    'dégénérescence',
    'dégingandaient',
    'dégingandasses',
    'déginganderais',
    'déginganderait',
    'dégingandèrent',
    'déginganderiez',
    'déginganderons',
    'déginganderont',
    'déglinguassent',
    'déglinguassiez',
    'déglinguerions',
    'déglutinations',
    'déglutissaient',
    'dégobillassent',
    'dégobillassiez',
    'dégobillerions',
    'dégonflassions',
    'dégonfleraient',
    'dégorgeassions',
    'dégoudronnâmes',
    'dégoudronnasse',
    'dégoudronnâtes',
    'dégoudronnerai',
    'dégoudronneras',
    'dégoudronnerez',
    'dégoudronnions',
    'dégoulinassent',
    'dégoulinassiez',
    'dégoulinements',
    'dégoulinerions',
    'dégoupillaient',
    'dégoupillasses',
    'dégoupillerais',
    'dégoupillerait',
    'dégoupillèrent',
    'dégoupilleriez',
    'dégoupillerons',
    'dégoupilleront',
    'dégourdiraient',
    'dégourdissante',
    'dégourdissants',
    'dégourdissions',
    'dégouttassions',
    'dégoutteraient',
    'dégraissassent',
    'dégraissassiez',
    'dégraisserions',
    'dégravoierions',
    'dégravoyassent',
    'dégravoyassiez',
    'dégringolaient',
    'dégringolasses',
    'dégringolerais',
    'dégringolerait',
    'dégringolèrent',
    'dégringoleriez',
    'dégringolerons',
    'dégringoleront',
    'dégrippassions',
    'dégripperaient',
    'dégrossassions',
    'dégrosseraient',
    'dégrossiraient',
    'dégrossissages',
    'dégrossissions',
    'dégrouillaient',
    'dégrouillasses',
    'dégrouillerais',
    'dégrouillerait',
    'dégrouillèrent',
    'dégrouilleriez',
    'dégrouillerons',
    'dégrouilleront',
    'déguerpiraient',
    'déguerpissions',
    'dégueulassions',
    'dégueuleraient',
    'dégurgitassent',
    'dégurgitassiez',
    'dégurgiterions',
    'déhanchassions',
    'déhancheraient',
    'déharnachaient',
    'déharnachasses',
    'déharnacherais',
    'déharnacherait',
    'déharnachèrent',
    'déharnacheriez',
    'déharnacherons',
    'déharnacheront',
    'déhouillassent',
    'déhouillassiez',
    'déhouillerions',
    'déjaugeassions',
    'déjaunissaient',
    'délabialisâmes',
    'délabialisasse',
    'délabialisâtes',
    'délabialiserai',
    'délabialiseras',
    'délabialiserez',
    'délabialisions',
    'délaissassions',
    'délaisseraient',
    'délibérassions',
    'délibératoires',
    'délibéreraient',
    'délicieusement',
    'délignifiaient',
    'délignifiasses',
    'délignifierais',
    'délignifierait',
    'délignifièrent',
    'délignifieriez',
    'délignifierons',
    'délignifieront',
    'délimitassions',
    'délimiteraient',
    'déliquescences',
    'déliquescentes',
    'délustrassions',
    'délustreraient',
    'démaçonnassent',
    'démaçonnassiez',
    'démaçonnerions',
    'démagnétisâmes',
    'démagnétisasse',
    'démagnétisâtes',
    'démagnétiserai',
    'démagnétiseras',
    'démagnétiserez',
    'démagnétisions',
    'démaigriraient',
    'démaigrissions',
    'démaillassions',
    'démailleraient',
    'démaillotaient',
    'démaillotasses',
    'démailloterais',
    'démailloterait',
    'démaillotèrent',
    'démailloteriez',
    'démailloterons',
    'démailloteront',
    'démanchassions',
    'démancheraient',
    'démangeassions',
    'démantelassent',
    'démantelassiez',
    'démantèlements',
    'démantèlerions',
    'démantibulâmes',
    'démantibulasse',
    'démantibulâtes',
    'démantibulerai',
    'démantibuleras',
    'démantibulerez',
    'démantibulions',
    'démaquillaient',
    'démaquillantes',
    'démaquillasses',
    'démaquillerais',
    'démaquillerait',
    'démaquillèrent',
    'démaquilleriez',
    'démaquillerons',
    'démaquilleront',
    'démarchassions',
    'démarcheraient',
    'démarquassions',
    'démarqueraient',
    'démasclassions',
    'démascleraient',
    'démasquassions',
    'démasqueraient',
    'démastiquaient',
    'démastiquasses',
    'démastiquerais',
    'démastiquerait',
    'démastiquèrent',
    'démastiqueriez',
    'démastiquerons',
    'démastiqueront',
    'dématérialisai',
    'dématérialisas',
    'dématérialisât',
    'dématérialisée',
    'dématérialiser',
    'dématérialises',
    'dématérialisés',
    'dématérialisez',
    'démazoutassent',
    'démazoutassiez',
    'démazouterions',
    'démembrassions',
    'démembreraient',
    'déménageassent',
    'déménageassiez',
    'déménageraient',
    'déméritassions',
    'démériteraient',
    'déméthanisâmes',
    'déméthanisasse',
    'déméthanisâtes',
    'déméthaniserai',
    'déméthaniseras',
    'déméthaniserez',
    'déméthanisions',
    'démeublassions',
    'démeubleraient',
    'demi-bouteille',
    'demi-douzaines',
    'démiellassions',
    'démielleraient',
    'demi-finaliste',
    'démilitarisais',
    'démilitarisait',
    'démilitarisant',
    'démilitarisées',
    'démilitarisent',
    'démilitarisera',
    'démilitarisiez',
    'démilitarisons',
    'demi-longueurs',
    'demi-mondaines',
    'déminéralisais',
    'déminéralisait',
    'déminéralisant',
    'déminéralisées',
    'déminéralisent',
    'déminéralisera',
    'déminéralisiez',
    'déminéralisons',
    'démissionnaire',
    'démissionnâmes',
    'démissionnasse',
    'démissionnâtes',
    'démissionnerai',
    'démissionneras',
    'démissionnerez',
    'démissionnions',
    'démobilisables',
    'démobilisaient',
    'démobilisasses',
    'démobilisateur',
    'démobilisation',
    'démobiliserais',
    'démobiliserait',
    'démobilisèrent',
    'démobiliseriez',
    'démobiliserons',
    'démobiliseront',
    'démocratisâmes',
    'démocratisasse',
    'démocratisâtes',
    'démocratiserai',
    'démocratiseras',
    'démocratiserez',
    'démocratisions',
    'démodulassions',
    'démoduleraient',
    'démographiques',
    'démonétisaient',
    'démonétisasses',
    'démonétisation',
    'démonétiserais',
    'démonétiserait',
    'démonétisèrent',
    'démonétiseriez',
    'démonétiserons',
    'démonétiseront',
    'démonstrateurs',
    'démonstrations',
    'démonstratives',
    'démonstratrice',
    'démontrassions',
    'démontreraient',
    'démoralisaient',
    'démoralisantes',
    'démoralisasses',
    'démoralisateur',
    'démoralisation',
    'démoraliserais',
    'démoraliserait',
    'démoralisèrent',
    'démoraliseriez',
    'démoraliserons',
    'démoraliseront',
    'démotivassions',
    'démotiveraient',
    'démouchetaient',
    'démouchetasses',
    'démouchetèrent',
    'démouchetterai',
    'démouchetteras',
    'démouchetterez',
    'démouscaillais',
    'démouscaillait',
    'démouscaillant',
    'démouscaillées',
    'démouscaillent',
    'démouscaillera',
    'démouscailliez',
    'démouscaillons',
    'démoustiquâmes',
    'démoustiquasse',
    'démoustiquâtes',
    'démoustiquerai',
    'démoustiqueras',
    'démoustiquerez',
    'démoustiquions',
    'démultipliâmes',
    'démultipliasse',
    'démultipliâtes',
    'démultiplierai',
    'démultiplieras',
    'démultiplierez',
    'démultipliions',
    'démurgeassions',
    'démuselassions',
    'démusellerions',
    'démystifiaient',
    'démystifiasses',
    'démystifierais',
    'démystifierait',
    'démystifièrent',
    'démystifieriez',
    'démystifierons',
    'démystifieront',
    'démythifiaient',
    'démythifiasses',
    'démythifierais',
    'démythifierait',
    'démythifièrent',
    'démythifieriez',
    'démythifierons',
    'démythifieront',
    'dénantissaient',
    'dénasalisaient',
    'dénasalisasses',
    'dénasalisation',
    'dénasaliserais',
    'dénasaliserait',
    'dénasalisèrent',
    'dénasaliseriez',
    'dénasaliserons',
    'dénasaliseront',
    'dénationalisai',
    'dénationalisas',
    'dénationalisât',
    'dénationalisée',
    'dénationaliser',
    'dénationalises',
    'dénationalisés',
    'dénationalisez',
    'dénaturalisais',
    'dénaturalisait',
    'dénaturalisant',
    'dénaturalisées',
    'dénaturalisent',
    'dénaturalisera',
    'dénaturalisiez',
    'dénaturalisons',
    'dénaturassions',
    'dénatureraient',
    'dénébulisaîmes',
    'dénébulisaisse',
    'dénébulisaîtes',
    'dénébulisasses',
    'dénébuliserais',
    'dénébuliserait',
    'dénébulisèrent',
    'dénébuliseriez',
    'dénébuliserons',
    'dénébuliseront',
    'déneigeassions',
    'déniaisassions',
    'déniaiseraient',
    'dénickelassent',
    'dénickelassiez',
    'dénickelerions',
    'dénicotinisais',
    'dénicotinisait',
    'dénicotinisant',
    'dénicotinisées',
    'dénicotinisent',
    'dénicotinisera',
    'dénicotiniseur',
    'dénicotinisiez',
    'dénicotinisons',
    'dénitrifiaient',
    'dénitrifiasses',
    'dénitrifierais',
    'dénitrifierait',
    'dénitrifièrent',
    'dénitrifieriez',
    'dénitrifierons',
    'dénitrifieront',
    'dénivelassions',
    'dénivellations',
    'dénivellements',
    'dénivellerions',
    'dénombrassions',
    'dénombreraient',
    'dénonciatrices',
    'dénotationnels',
    'dénoyautassent',
    'dénoyautassiez',
    'dénoyauterions',
    'dentelleraient',
    'dénucléarisais',
    'dénucléarisait',
    'dénucléarisant',
    'dénucléarisées',
    'dénucléarisent',
    'dénucléarisera',
    'dénucléarisiez',
    'dénucléarisons',
    'déontologiques',
    'dépaillassions',
    'dépailleraient',
    'dépaisselaient',
    'dépaisselasses',
    'dépaisselèrent',
    'dépaissellerai',
    'dépaisselleras',
    'dépaissellerez',
    'dépalissassent',
    'dépalissassiez',
    'dépalisserions',
    'dépaquetassent',
    'dépaquetassiez',
    'dépaquetterais',
    'dépaquetterait',
    'dépaquetteriez',
    'dépaquetterons',
    'dépaquetteront',
    'déparaffinages',
    'déparaffinâmes',
    'déparaffinasse',
    'déparaffinâtes',
    'déparaffinerai',
    'déparaffineras',
    'déparaffinerez',
    'déparaffinions',
    'déparasitaient',
    'déparasitasses',
    'déparasiterais',
    'déparasiterait',
    'déparasitèrent',
    'déparasiteriez',
    'déparasiterons',
    'déparasiteront',
    'dépareillaient',
    'dépareillasses',
    'dépareillerais',
    'dépareillerait',
    'dépareillèrent',
    'dépareilleriez',
    'dépareillerons',
    'dépareilleront',
    'départageaient',
    'départageasses',
    'départagerions',
    'départementale',
    'départementaux',
    'dépassionnâmes',
    'dépassionnasse',
    'dépassionnâtes',
    'dépassionnerai',
    'dépassionneras',
    'dépassionnerez',
    'dépassionnions',
    'dépatouillâmes',
    'dépatouillasse',
    'dépatouillâtes',
    'dépatouillerai',
    'dépatouilleras',
    'dépatouillerez',
    'dépatouillions',
    'dépeignassions',
    'dépeigneraient',
    'dépeignissions',
    'dépelotonnâmes',
    'dépelotonnasse',
    'dépelotonnâtes',
    'dépelotonnerai',
    'dépelotonneras',
    'dépelotonnerez',
    'dépelotonnions',
    'dépérissements',
    'dépersonnalisa',
    'dépersonnalise',
    'dépersonnalisé',
    'dépeuplassions',
    'dépeupleraient',
    'dépiautassions',
    'dépiauteraient',
    'dépinglassions',
    'dépingleraient',
    'déplafonnaient',
    'déplafonnasses',
    'déplafonnement',
    'déplafonnerais',
    'déplafonnerait',
    'déplafonnèrent',
    'déplafonneriez',
    'déplafonnerons',
    'déplafonneront',
    'déplanquassent',
    'déplanquassiez',
    'déplanquerions',
    'déplantassions',
    'déplanteraient',
    'déplâtrassions',
    'déplâtreraient',
    'déplissassions',
    'déplisseraient',
    'déplombassions',
    'déplomberaient',
    'déplorablement',
    'dépoétisassent',
    'dépoétisassiez',
    'dépoétiserions',
    'dépointassions',
    'dépointeraient',
    'dépoitraillées',
    'dépolarisaient',
    'dépolarisasses',
    'dépolariserais',
    'dépolariserait',
    'dépolarisèrent',
    'dépolariseriez',
    'dépolariserons',
    'dépolariseront',
    'dépolitisaient',
    'dépolitisasses',
    'dépolitisation',
    'dépolitiserais',
    'dépolitiserait',
    'dépolitisèrent',
    'dépolitiseriez',
    'dépolitiserons',
    'dépolitiseront',
    'dépolluassions',
    'dépollueraient',
    'dépolymérisais',
    'dépolymérisait',
    'dépolymérisant',
    'dépolymérisées',
    'dépolymérisent',
    'dépolymérisera',
    'dépolymérisiez',
    'dépolymérisons',
    'dépontillaient',
    'dépontillasses',
    'dépontillerais',
    'dépontillerait',
    'dépontillèrent',
    'dépontilleriez',
    'dépontillerons',
    'dépontilleront',
    'dépossédassent',
    'dépossédassiez',
    'déposséderions',
    'dépoudrassions',
    'dépoudreraient',
    'dépouillassent',
    'dépouillassiez',
    'dépouillements',
    'dépouillerions',
    'dépoussiérages',
    'dépoussiérâmes',
    'dépoussiérante',
    'dépoussiérants',
    'dépoussiérasse',
    'dépoussiérâtes',
    'dépoussiérerai',
    'dépoussiéreras',
    'dépoussiérerez',
    'dépoussiéreurs',
    'dépoussiérions',
    'dépréciassions',
    'dépréciatrices',
    'déprécieraient',
    'dépressurisais',
    'dépressurisait',
    'dépressurisant',
    'dépressurisées',
    'dépressurisent',
    'dépressurisera',
    'dépressurisiez',
    'dépressurisons',
    'déprogrammâmes',
    'déprogrammasse',
    'déprogrammâtes',
    'déprogrammerai',
    'déprogrammeras',
    'déprogrammerez',
    'déprogrammions',
    'déprolétarisai',
    'déprolétarisas',
    'déprolétarisât',
    'déprolétarisée',
    'déprolétariser',
    'déprolétarises',
    'déprolétarisés',
    'déprolétarisez',
    'dépropanisâmes',
    'dépropanisasse',
    'dépropanisâtes',
    'dépropaniserai',
    'dépropaniseras',
    'dépropaniserez',
    'dépropanisions',
    'dépucelassions',
    'dépucellerions',
    'déquillassions',
    'déquilleraient',
    'déracinassions',
    'déracineraient',
    'déraidissaient',
    'déraillassions',
    'dérailleraient',
    'déraisonnables',
    'déraisonnaient',
    'déraisonnasses',
    'déraisonnerais',
    'déraisonnerait',
    'déraisonnèrent',
    'déraisonneriez',
    'déraisonnerons',
    'déraisonneront',
    'dérangeassions',
    'dératisassions',
    'dératiseraient',
    'déréalisassent',
    'déréalisassiez',
    'déréaliserions',
    'dérégulassions',
    'déréguleraient',
    'dermatologiste',
    'dernières-nées',
    'dérouillassent',
    'dérouillassiez',
    'dérouillerions',
    'désabonnassent',
    'désabonnassiez',
    'désabonnements',
    'désabonnerions',
    'désabusassions',
    'désabuseraient',
    'désacclimatais',
    'désacclimatait',
    'désacclimatant',
    'désacclimatées',
    'désacclimatent',
    'désacclimatera',
    'désacclimatiez',
    'désacclimatons',
    'désaccordaient',
    'désaccordasses',
    'désaccorderais',
    'désaccorderait',
    'désaccordèrent',
    'désaccorderiez',
    'désaccorderons',
    'désaccorderont',
    'désaccouplâmes',
    'désaccouplasse',
    'désaccouplâtes',
    'désaccouplerai',
    'désaccoupleras',
    'désaccouplerez',
    'désaccouplions',
    'désaccoutumais',
    'désaccoutumait',
    'désaccoutumant',
    'désaccoutumées',
    'désaccoutument',
    'désaccoutumera',
    'désaccoutumiez',
    'désaccoutumons',
    'désacralisâmes',
    'désacralisasse',
    'désacralisâtes',
    'désacraliserai',
    'désacraliseras',
    'désacraliserez',
    'désacralisions',
    'désactivassent',
    'désactivassiez',
    'désactiverions',
    'désadaptassent',
    'désadaptassiez',
    'désadapterions',
    'désaffectaient',
    'désaffectasses',
    'désaffectation',
    'désaffecterais',
    'désaffecterait',
    'désaffectèrent',
    'désaffecteriez',
    'désaffecterons',
    'désaffecteront',
    'désaffectionna',
    'désaffectionne',
    'désaffectionné',
    'désaffiliaient',
    'désaffiliasses',
    'désaffilierais',
    'désaffilierait',
    'désaffilièrent',
    'désaffilieriez',
    'désaffilierons',
    'désaffilieront',
    'désagençassent',
    'désagençassiez',
    'désagencerions',
    'désagrafassent',
    'désagrafassiez',
    'désagraferions',
    'désagrégations',
    'désagrégeaient',
    'désagrégeasses',
    'désagrégerions',
    'désaimantaient',
    'désaimantasses',
    'désaimanterais',
    'désaimanterait',
    'désaimantèrent',
    'désaimanteriez',
    'désaimanterons',
    'désaimanteront',
    'désajustassent',
    'désajustassiez',
    'désajusterions',
    'désaliénassent',
    'désaliénassiez',
    'désaliénerions',
    'désalignassent',
    'désalignassiez',
    'désalignerions',
    'désaltérassent',
    'désaltérassiez',
    'désaltérerions',
    'désamarrassent',
    'désamarrassiez',
    'désamarrerions',
    'désambiguïsais',
    'désambiguïsait',
    'désambiguïsant',
    'désambiguïsées',
    'désambiguïsent',
    'désambiguïsera',
    'désambiguïsiez',
    'désambiguïsons',
    'désamidonnâmes',
    'désamidonnasse',
    'désamidonnâtes',
    'désamidonnerai',
    'désamidonneras',
    'désamidonnerez',
    'désamidonnions',
    'désamorçassent',
    'désamorçassiez',
    'désamorcerions',
    'désannexassent',
    'désannexassiez',
    'désannexerions',
    'désappariaient',
    'désappariasses',
    'désapparierais',
    'désapparierait',
    'désapparièrent',
    'désapparieriez',
    'désapparierons',
    'désapparieront',
    'désappointâmes',
    'désappointasse',
    'désappointâtes',
    'désappointerai',
    'désappointeras',
    'désappointerez',
    'désappointions',
    'désapprenaient',
    'désapprendrais',
    'désapprendrait',
    'désapprendriez',
    'désapprendrons',
    'désapprendront',
    'désapprissions',
    'désapprobateur',
    'désapprobation',
    'désapprouvâmes',
    'désapprouvasse',
    'désapprouvâtes',
    'désapprouverai',
    'désapprouveras',
    'désapprouverez',
    'désapprouvions',
    'désarçonnaient',
    'désarçonnasses',
    'désarçonnerais',
    'désarçonnerait',
    'désarçonnèrent',
    'désarçonneriez',
    'désarçonnerons',
    'désarçonneront',
    'désargentaient',
    'désargentasses',
    'désargenterais',
    'désargenterait',
    'désargentèrent',
    'désargenteriez',
    'désargenterons',
    'désargenteront',
    'désarrimassent',
    'désarrimassiez',
    'désarrimerions',
    'désarticulâmes',
    'désarticulasse',
    'désarticulâtes',
    'désarticulerai',
    'désarticuleras',
    'désarticulerez',
    'désarticulions',
    'désassemblages',
    'désassemblâmes',
    'désassemblasse',
    'désassemblâtes',
    'désassemblerai',
    'désassembleras',
    'désassemblerez',
    'désassemblions',
    'désassimilâmes',
    'désassimilasse',
    'désassimilâtes',
    'désassimilerai',
    'désassimileras',
    'désassimilerez',
    'désassimilions',
    'désassortiment',
    'désassortirais',
    'désassortirait',
    'désassortirent',
    'désassortiriez',
    'désassortirons',
    'désassortiront',
    'désassortisses',
    'désassortissez',
    'désatomisaient',
    'désatomisasses',
    'désatomiserais',
    'désatomiserait',
    'désatomisèrent',
    'désatomiseriez',
    'désatomiserons',
    'désatomiseront',
    'désavantageais',
    'désavantageait',
    'désavantageant',
    'désavantageons',
    'désavantagerai',
    'désavantageras',
    'désavantagerez',
    'désavantageuse',
    'désavantagions',
    'désaveuglaient',
    'désaveuglasses',
    'désaveuglerais',
    'désaveuglerait',
    'désaveuglèrent',
    'désaveugleriez',
    'désaveuglerons',
    'désaveugleront',
    'désavouassions',
    'désavoueraient',
    'descellassions',
    'descelleraient',
    'descendissions',
    'déséchouassent',
    'déséchouassiez',
    'déséchouerions',
    'déségrégations',
    'désembourbâmes',
    'désembourbasse',
    'désembourbâtes',
    'désembourberai',
    'désembourberas',
    'désembourberez',
    'désembourbions',
    'désembouteilla',
    'désembouteille',
    'désembouteillé',
    'désembraierais',
    'désembraierait',
    'désembraieriez',
    'désembraierons',
    'désembraieront',
    'désembrayaient',
    'désembrayasses',
    'désembrayerais',
    'désembrayerait',
    'désembrayèrent',
    'désembrayeriez',
    'désembrayerons',
    'désembrayeront',
    'désembuassions',
    'désembueraient',
    'désemmanchâmes',
    'désemmanchasse',
    'désemmanchâtes',
    'désemmancherai',
    'désemmancheras',
    'désemmancherez',
    'désemmanchions',
    'désemparassent',
    'désemparassiez',
    'désemparerions',
    'désempesassent',
    'désempesassiez',
    'désempèserions',
    'désempliraient',
    'désemplissions',
    'désemprisonnai',
    'désemprisonnas',
    'désemprisonnât',
    'désemprisonnée',
    'désemprisonner',
    'désemprisonnes',
    'désemprisonnés',
    'désemprisonnez',
    'désencadraient',
    'désencadrasses',
    'désencadrerais',
    'désencadrerait',
    'désencadrèrent',
    'désencadreriez',
    'désencadrerons',
    'désencadreront',
    'désencartaient',
    'désencartasses',
    'désencarterais',
    'désencarterait',
    'désencartèrent',
    'désencarteriez',
    'désencarterons',
    'désencarteront',
    'désenchaînâmes',
    'désenchaînasse',
    'désenchaînâtes',
    'désenchaînerai',
    'désenchaîneras',
    'désenchaînerez',
    'désenchaînions',
    'désenchantâmes',
    'désenchantasse',
    'désenchantâtes',
    'désenchanterai',
    'désenchanteras',
    'désenchanterez',
    'désenchantions',
    'désenclavaient',
    'désenclavasses',
    'désenclavement',
    'désenclaverais',
    'désenclaverait',
    'désenclavèrent',
    'désenclaveriez',
    'désenclaverons',
    'désenclaveront',
    'désencollaient',
    'désencollasses',
    'désencollerais',
    'désencollerait',
    'désencollèrent',
    'désencolleriez',
    'désencollerons',
    'désencolleront',
    'désencombrâmes',
    'désencombrasse',
    'désencombrâtes',
    'désencombrerai',
    'désencombreras',
    'désencombrerez',
    'désencombrions',
    'désencrassâmes',
    'désencrassasse',
    'désencrassâtes',
    'désencrasserai',
    'désencrasseras',
    'désencrasserez',
    'désencrassions',
    'désendettaient',
    'désendettasses',
    'désendettement',
    'désendetterais',
    'désendetterait',
    'désendettèrent',
    'désendetteriez',
    'désendetterons',
    'désendetteront',
    'désénervassent',
    'désénervassiez',
    'désénerverions',
    'désenfilassent',
    'désenfilassiez',
    'désenfilerions',
    'désenflammâmes',
    'désenflammasse',
    'désenflammâtes',
    'désenflammerai',
    'désenflammeras',
    'désenflammerez',
    'désenflammions',
    'désenflassions',
    'désenfleraient',
    'désenfumassent',
    'désenfumassiez',
    'désenfumerions',
    'désengageaient',
    'désengageasses',
    'désengagements',
    'désengagerions',
    'désengorgeâmes',
    'désengorgeasse',
    'désengorgeâtes',
    'désengorgerais',
    'désengorgerait',
    'désengorgèrent',
    'désengorgeriez',
    'désengorgerons',
    'désengorgeront',
    'désengourdîmes',
    'désengourdirai',
    'désengourdiras',
    'désengourdirez',
    'désengourdisse',
    'désengourdîtes',
    'désenivrassent',
    'désenivrassiez',
    'désenivrerions',
    'désenlaçassent',
    'désenlaçassiez',
    'désenlacerions',
    'désenlaidirais',
    'désenlaidirait',
    'désenlaidirent',
    'désenlaidiriez',
    'désenlaidirons',
    'désenlaidiront',
    'désenlaidisses',
    'désenlaidissez',
    'désennuierions',
    'désennuyassent',
    'désennuyassiez',
    'désenraierions',
    'désenrayassent',
    'désenrayassiez',
    'désenrayerions',
    'désenrhumaient',
    'désenrhumasses',
    'désenrhumerais',
    'désenrhumerait',
    'désenrhumèrent',
    'désenrhumeriez',
    'désenrhumerons',
    'désenrhumeront',
    'désenrouassent',
    'désenrouassiez',
    'désenrouerions',
    'désensablaient',
    'désensablasses',
    'désensablerais',
    'désensablerait',
    'désensablèrent',
    'désensableriez',
    'désensablerons',
    'désensableront',
    'désensibilisai',
    'désensibilisas',
    'désensibilisât',
    'désensibilisée',
    'désensibiliser',
    'désensibilises',
    'désensibilisés',
    'désensibilisez',
    'désensorcelais',
    'désensorcelait',
    'désensorcelant',
    'désensorcelées',
    'désensorceliez',
    'désensorcelles',
    'désensorcelons',
    'désentoilaient',
    'désentoilasses',
    'désentoilerais',
    'désentoilerait',
    'désentoilèrent',
    'désentoileriez',
    'désentoilerons',
    'désentoileront',
    'désentortillai',
    'désentortillas',
    'désentortillât',
    'désentortillée',
    'désentortiller',
    'désentortilles',
    'désentortillés',
    'désentortillez',
    'désentravaient',
    'désentravasses',
    'désentraverais',
    'désentraverait',
    'désentravèrent',
    'désentraveriez',
    'désentraverons',
    'désentraveront',
    'désenvasassent',
    'désenvasassiez',
    'désenvaserions',
    'désenveloppais',
    'désenveloppait',
    'désenveloppant',
    'désenveloppées',
    'désenveloppent',
    'désenveloppera',
    'désenveloppiez',
    'désenveloppons',
    'désenvenimâmes',
    'désenvenimasse',
    'désenvenimâtes',
    'désenvenimerai',
    'désenvenimeras',
    'désenvenimerez',
    'désenvenimions',
    'désenverguâmes',
    'désenverguasse',
    'désenverguâtes',
    'désenverguerai',
    'désenvergueras',
    'désenverguerez',
    'désenverguions',
    'désépaissirais',
    'désépaissirait',
    'désépaissirent',
    'désépaissiriez',
    'désépaissirons',
    'désépaissiront',
    'désépaississes',
    'désépaississez',
    'déséquilibrais',
    'déséquilibrait',
    'déséquilibrant',
    'déséquilibrées',
    'déséquilibrent',
    'déséquilibrera',
    'déséquilibriez',
    'déséquilibrons',
    'déséquipassent',
    'déséquipassiez',
    'déséquiperions',
    'désespérassent',
    'désespérassiez',
    'désespérerions',
    'désétablirions',
    'désétablissais',
    'désétablissait',
    'désétablissant',
    'désétablissent',
    'désétablissiez',
    'désétablissons',
    'désétamassions',
    'désétameraient',
    'désétatisaient',
    'désétatisasses',
    'désétatiserais',
    'désétatiserait',
    'désétatisèrent',
    'désétatiseriez',
    'désétatiserons',
    'désétatiseront',
    'déshabillaient',
    'déshabillasses',
    'déshabillerais',
    'déshabillerait',
    'déshabillèrent',
    'déshabilleriez',
    'déshabillerons',
    'déshabilleront',
    'déshabituaient',
    'déshabituasses',
    'déshabituerais',
    'déshabituerait',
    'déshabituèrent',
    'déshabitueriez',
    'déshabituerons',
    'déshabitueront',
    'désherbassions',
    'désherberaient',
    'déshéritassent',
    'déshéritassiez',
    'déshériterions',
    'déshonnêtement',
    'déshonorassent',
    'déshonorassiez',
    'déshonorerions',
    'déshuilassions',
    'déshuileraient',
    'déshumanisâmes',
    'déshumanisasse',
    'déshumanisâtes',
    'déshumaniserai',
    'déshumaniseras',
    'déshumaniserez',
    'déshumanisions',
    'déshumidifiais',
    'déshumidifiait',
    'déshumidifiant',
    'déshumidifiées',
    'déshumidifient',
    'déshumidifiera',
    'déshumidifiiez',
    'déshumidifions',
    'déshydrataient',
    'déshydratantes',
    'déshydratasses',
    'déshydratation',
    'déshydraterais',
    'déshydraterait',
    'déshydratèrent',
    'déshydrateriez',
    'déshydraterons',
    'déshydrateront',
    'déshydrogénais',
    'déshydrogénait',
    'déshydrogénant',
    'déshydrogénées',
    'déshydrogènent',
    'déshydrogénera',
    'déshydrogéniez',
    'déshydrogénons',
    'déshypothéquai',
    'déshypothéquas',
    'déshypothéquât',
    'déshypothéquée',
    'déshypothéquer',
    'déshypothéqués',
    'déshypothèques',
    'déshypothéquez',
    'désidérabilité',
    'désillusionnai',
    'désillusionnas',
    'désillusionnât',
    'désillusionnée',
    'désillusionner',
    'désillusionnes',
    'désillusionnés',
    'désillusionnez',
    'désincarnaient',
    'désincarnasses',
    'désincarnerais',
    'désincarnerait',
    'désincarnèrent',
    'désincarneriez',
    'désincarnerons',
    'désincarneront',
    'désincorporais',
    'désincorporait',
    'désincorporant',
    'désincorporées',
    'désincorporent',
    'désincorporera',
    'désincorporiez',
    'désincorporons',
    'désincrustâmes',
    'désincrustasse',
    'désincrustâtes',
    'désincrusterai',
    'désincrusteras',
    'désincrusterez',
    'désincrustions',
    'désinculpaient',
    'désinculpasses',
    'désinculperais',
    'désinculperait',
    'désinculpèrent',
    'désinculperiez',
    'désinculperons',
    'désinculperont',
    'désindexassent',
    'désindexassiez',
    'désindexerions',
    'désinentielles',
    'désinfectaient',
    'désinfectantes',
    'désinfectasses',
    'désinfecterais',
    'désinfecterait',
    'désinfectèrent',
    'désinfecteriez',
    'désinfecterons',
    'désinfecteront',
    'désinformation',
    'désingularisai',
    'désingularisas',
    'désingularisât',
    'désingularisée',
    'désingulariser',
    'désingularises',
    'désingularisés',
    'désingularisez',
    'désinsectisais',
    'désinsectisait',
    'désinsectisant',
    'désinsectisées',
    'désinsectisent',
    'désinsectisera',
    'désinsectisiez',
    'désinsectisons',
    'désintégraient',
    'désintégrasses',
    'désintégration',
    'désintégrerais',
    'désintégrerait',
    'désintégrèrent',
    'désintégreriez',
    'désintégrerons',
    'désintégreront',
    'désintéressais',
    'désintéressait',
    'désintéressant',
    'désintéressées',
    'désintéressent',
    'désintéressera',
    'désintéressiez',
    'désintéressons',
    'désintoxiquais',
    'désintoxiquait',
    'désintoxiquant',
    'désintoxiquées',
    'désintoxiquent',
    'désintoxiquera',
    'désintoxiquiez',
    'désintoxiquons',
    'désinvestirais',
    'désinvestirait',
    'désinvestirent',
    'désinvestiriez',
    'désinvestirons',
    'désinvestiront',
    'désinvestisses',
    'désinvestissez',
    'désinvitassent',
    'désinvitassiez',
    'désinviterions',
    'désobéissaient',
    'désobéissances',
    'désobéissantes',
    'désobligeaient',
    'désobligeances',
    'désobligeantes',
    'désobligeasses',
    'désobligerions',
    'désobstruaîmes',
    'désobstruaisse',
    'désobstruaîtes',
    'désobstruasses',
    'désobstruerais',
    'désobstruerait',
    'désobstruèrent',
    'désobstrueriez',
    'désobstruerons',
    'désobstrueront',
    'désoccupassent',
    'désoccupassiez',
    'désoccuperions',
    'désodorisaient',
    'désodorisantes',
    'désodorisasses',
    'désodoriserais',
    'désodoriserait',
    'désodorisèrent',
    'désodoriseriez',
    'désodoriserons',
    'désodoriseront',
    'désoeuvrements',
    'désolidarisais',
    'désolidarisait',
    'désolidarisant',
    'désolidarisées',
    'désolidarisent',
    'désolidarisera',
    'désolidarisiez',
    'désolidarisons',
    'désopilassions',
    'désopileraient',
    'désorbitassent',
    'désorbitassiez',
    'désorbiterions',
    'désordonnaient',
    'désordonnasses',
    'désordonnerais',
    'désordonnerait',
    'désordonnèrent',
    'désordonneriez',
    'désordonnerons',
    'désordonneront',
    'désorganisâmes',
    'désorganisasse',
    'désorganisâtes',
    'désorganiserai',
    'désorganiseras',
    'désorganiserez',
    'désorganisions',
    'désorientaient',
    'désorientasses',
    'désorientation',
    'désorienterais',
    'désorienterait',
    'désorientèrent',
    'désorienteriez',
    'désorienterons',
    'désorienteront',
    'désoxydassions',
    'désoxyderaient',
    'désoxygénaient',
    'désoxygénasses',
    'désoxygénation',
    'désoxygénerais',
    'désoxygénerait',
    'désoxygénèrent',
    'désoxygéneriez',
    'désoxygénerons',
    'désoxygéneront',
    'despotiquement',
    'desquamassions',
    'desquameraient',
    'dessablassions',
    'dessableraient',
    'dessaisiraient',
    'dessaisissions',
    'dessanglassent',
    'dessanglassiez',
    'dessanglerions',
    'dessaoulassent',
    'dessaoulassiez',
    'dessaoulerions',
    'desséchassions',
    'dessécheraient',
    'dessellassions',
    'desselleraient',
    'desserrassions',
    'desserreraient',
    'dessertiraient',
    'dessertissions',
    'desserviraient',
    'desservissions',
    'dessillassions',
    'dessilleraient',
    'dessoudassions',
    'dessouderaient',
    'dessoulassions',
    'dessoûlassions',
    'dessouleraient',
    'dessoûleraient',
    'dessuitassions',
    'dessuiteraient',
    'dessus-de-plat',
    'déstabilisâmes',
    'déstabilisasse',
    'déstabilisâtes',
    'déstabiliserai',
    'déstabiliseras',
    'déstabiliserez',
    'déstabilisions',
    'destitueraient',
    'déstockassions',
    'déstockeraient',
    'destructurâmes',
    'destructurasse',
    'destructurâtes',
    'destructurerai',
    'destructureras',
    'destructurerez',
    'destructurions',
    'désubjectivisa',
    'désubjectivise',
    'désubjectivisé',
    'désulfitassent',
    'désulfitassiez',
    'désulfiterions',
    'désulfurassent',
    'désulfurassiez',
    'désulfurations',
    'désulfurerions',
    'désynchronisai',
    'désynchronisas',
    'désynchronisât',
    'désynchronisée',
    'désynchroniser',
    'désynchronises',
    'désynchronisés',
    'désynchronisez',
    'détaillassions',
    'détailleraient',
    'détapissassent',
    'détapissassiez',
    'détapisserions',
    'détartrassions',
    'détartreraient',
    'déteignissions',
    'détergeassions',
    'détériorassent',
    'détériorassiez',
    'détériorations',
    'détériorerions',
    'déterminassent',
    'déterminassiez',
    'déterminations',
    'déterminatives',
    'déterminerions',
    'détestablement',
    'détonnelassent',
    'détonnelassiez',
    'détonnellerais',
    'détonnellerait',
    'détonnelleriez',
    'détonnellerons',
    'détonnelleront',
    'détortillaient',
    'détortillasses',
    'détortillerais',
    'détortillerait',
    'détortillèrent',
    'détortilleriez',
    'détortillerons',
    'détortilleront',
    'détournassions',
    'détourneraient',
    'détractassions',
    'détracteraient',
    'détranchassent',
    'détranchassiez',
    'détrancherions',
    'détransposâmes',
    'détransposasse',
    'détransposâtes',
    'détransposerai',
    'détransposeras',
    'détransposerez',
    'détransposions',
    'détraquassions',
    'détraqueraient',
    'détrempassions',
    'détremperaient',
    'détressassions',
    'détresseraient',
    'détricotassent',
    'détricotassiez',
    'détricoterions',
    'détrompassions',
    'détromperaient',
    'détronchassent',
    'détronchassiez',
    'détroncherions',
    'détroquassions',
    'détroqueraient',
    'détroussassent',
    'détroussassiez',
    'détrousserions',
    'détruisissions',
    'dévalisassions',
    'dévaliseraient',
    'dévalorisaient',
    'dévalorisantes',
    'dévalorisasses',
    'dévalorisation',
    'dévaloriserais',
    'dévaloriserait',
    'dévalorisèrent',
    'dévaloriseriez',
    'dévaloriserons',
    'dévaloriseront',
    'dévaluerassent',
    'dévaluerassiez',
    'développassent',
    'développassiez',
    'développements',
    'développerions',
    'déverdissaient',
    'dévergondaient',
    'dévergondasses',
    'dévergonderais',
    'dévergonderait',
    'dévergondèrent',
    'dévergonderiez',
    'dévergonderons',
    'dévergonderont',
    'déverguassions',
    'dévergueraient',
    'dévernissaient',
    'déverrouillage',
    'déverrouillais',
    'déverrouillait',
    'déverrouillant',
    'déverrouillées',
    'déverrouillent',
    'déverrouillera',
    'déverrouilliez',
    'déverrouillons',
    'déviationnisme',
    'déviationniste',
    'dévirilisaient',
    'dévirilisasses',
    'déviriliserais',
    'déviriliserait',
    'dévirilisèrent',
    'déviriliseriez',
    'déviriliserons',
    'déviriliseront',
    'dévirolassions',
    'déviroleraient',
    'dévisageassent',
    'dévisageassiez',
    'dévisageraient',
    'dévisserassent',
    'dévisserassiez',
    'dévitalisaient',
    'dévitalisasses',
    'dévitalisation',
    'dévitaliserais',
    'dévitaliserait',
    'dévitalisèrent',
    'dévitaliseriez',
    'dévitaliserons',
    'dévitaliseront',
    'dévitrifiaient',
    'dévitrifiasses',
    'dévitrifierais',
    'dévitrifierait',
    'dévitrifièrent',
    'dévitrifieriez',
    'dévitrifierons',
    'dévitrifieront',
    'dévoilerassent',
    'dévoilerassiez',
    'diaboliquement',
    'diagnostiquais',
    'diagnostiquait',
    'diagnostiquant',
    'diagnostiquées',
    'diagnostiquent',
    'diagnostiquera',
    'diagnostiquiez',
    'diagnostiquons',
    'dialectalisais',
    'dialectalisait',
    'dialectalisant',
    'dialectalisées',
    'dialectalisent',
    'dialectalisera',
    'dialectalisiez',
    'dialectalisons',
    'dialecticienne',
    'dialectisaient',
    'dialectisasses',
    'dialectiserais',
    'dialectiserait',
    'dialectisèrent',
    'dialectiseriez',
    'dialectiserons',
    'dialectiseront',
    'dialectologies',
    'dialectologues',
    'dialoguassions',
    'dialogueraient',
    'diamagnétismes',
    'diamantassions',
    'diamanteraient',
    'diamétralement',
    'diamidophénols',
    'diaphanoscopie',
    'diaphorétiques',
    'diaphragmaient',
    'diaphragmasses',
    'diaphragmerais',
    'diaphragmerait',
    'diaphragmèrent',
    'diaphragmeriez',
    'diaphragmerons',
    'diaphragmeront',
    'diatoniquement',
    'dichromatiques',
    'didactiquement',
    'diencéphalique',
    'diététiciennes',
    'différenciâmes',
    'différenciasse',
    'différenciâtes',
    'différencierai',
    'différencieras',
    'différencierez',
    'différenciions',
    'différentielle',
    'difformassions',
    'difformeraient',
    'diffractassent',
    'diffractassiez',
    'diffracterions',
    'digestibilités',
    'digitalisaient',
    'digitalisasses',
    'digitalisèrent',
    'digtaliserions',
    'dilacérassions',
    'dilacéreraient',
    'dilapidassions',
    'dilapidatrices',
    'dilapideraient',
    'dilettantismes',
    'dimensionnâmes',
    'dimensionnasse',
    'dimensionnâtes',
    'dimensionnelle',
    'dimensionnerai',
    'dimensionneras',
    'dimensionnerez',
    'dimensionnions',
    'dindonnassions',
    'dindonneraient',
    'diphtongaisons',
    'diphtonguaient',
    'diphtonguasses',
    'diphtonguerais',
    'diphtonguerait',
    'diphtonguèrent',
    'diphtongueriez',
    'diphtonguerons',
    'diphtongueront',
    'discernassions',
    'discerneraient',
    'disciplinables',
    'disciplinaient',
    'disciplinaires',
    'disciplinasses',
    'disciplinerais',
    'disciplinerait',
    'disciplinèrent',
    'disciplineriez',
    'disciplinerons',
    'disciplineront',
    'discographique',
    'discontacteurs',
    'discontinuâmes',
    'discontinuasse',
    'discontinuâtes',
    'discontinuerai',
    'discontinueras',
    'discontinuerez',
    'discontinuions',
    'discontinuités',
    'disconvenaient',
    'disconviendrai',
    'disconviendras',
    'disconviendrez',
    'disconviennent',
    'disconvinssent',
    'disconvinssiez',
    'discordassions',
    'discorderaient',
    'discothécaires',
    'discourtoisies',
    'discourussions',
    'discréditaient',
    'discréditasses',
    'discréditerais',
    'discréditerait',
    'discréditèrent',
    'discréditeriez',
    'discréditerons',
    'discréditeront',
    'discriminaient',
    'discriminantes',
    'discriminasses',
    'discrimination',
    'discriminerais',
    'discriminerait',
    'discriminèrent',
    'discrimineriez',
    'discriminerons',
    'discrimineront',
    'disculpassions',
    'disculperaient',
    'discutaillâmes',
    'discutaillasse',
    'discutaillâtes',
    'discutaillerai',
    'discutailleras',
    'discutaillerez',
    'discutaillions',
    'disgraciassent',
    'disgraciassiez',
    'disgracierions',
    'disjoignissent',
    'disjoignissiez',
    'disjoindraient',
    'disjonctassent',
    'disjonctassiez',
    'disjoncterions',
    'disloquassions',
    'disloqueraient',
    'disparaissions',
    'disparaîtrions',
    'dispensassions',
    'dispensatrices',
    'dispenseraient',
    'dispersassions',
    'disperseraient',
    'disponibilités',
    'disproportions',
    'disputaillâmes',
    'disputaillasse',
    'disputaillâtes',
    'disputaillerai',
    'disputailleras',
    'disputaillerez',
    'disputaillions',
    'disqualifiâmes',
    'disqualifiasse',
    'disqualifiâtes',
    'disqualifierai',
    'disqualifieras',
    'disqualifierez',
    'disqualifiions',
    'disséminassent',
    'disséminassiez',
    'disséminations',
    'disséminerions',
    'disséquassions',
    'disséqueraient',
    'dissertassions',
    'disserteraient',
    'dissimilations',
    'dissimilitudes',
    'dissimulassent',
    'dissimulassiez',
    'dissimulateurs',
    'dissimulations',
    'dissimulatrice',
    'dissimulerions',
    'dissociassions',
    'dissocieraient',
    'dissolubilités',
    'dissuadassions',
    'dissuaderaient',
    'dissyllabiques',
    'dissymétriques',
    'distançassions',
    'distanceraient',
    'distanciassent',
    'distanciassiez',
    'distanciations',
    'distancierions',
    'distendissions',
    'distillassions',
    'distillatrices',
    'distilleraient',
    'distinguassent',
    'distinguassiez',
    'distinguerions',
    'distordissions',
    'distractivités',
    'distribuassent',
    'distribuassiez',
    'distribuerions',
    'distributrices',
    'dithyrambiques',
    'divergeassions',
    'diversifiaient',
    'diversifiasses',
    'diversifierais',
    'diversifierait',
    'diversifièrent',
    'diversifieriez',
    'diversifierons',
    'diversifieront',
    'divertissaient',
    'divertissantes',
    'divertissement',
    'divinisassions',
    'diviniseraient',
    'divisionnaires',
    'divisionnismes',
    'divisionnistes',
    'divulguassions',
    'divulgueraient',
    'documentaliste',
    'documentariste',
    'documentassent',
    'documentassiez',
    'documentations',
    'documenterions',
    'dodécaphonique',
    'dodécaphonisme',
    'dodécasyllabes',
    'dodelinassions',
    'dodelineraient',
    'dogmatiquement',
    'dogmatisassent',
    'dogmatisassiez',
    'dogmatiserions',
    'dolichocéphale',
    'domestications',
    'domestiquaient',
    'domestiquasses',
    'domestiquerais',
    'domestiquerait',
    'domestiquèrent',
    'domestiqueriez',
    'domestiquerons',
    'domestiqueront',
    'domiciliassent',
    'domiciliassiez',
    'domiciliataire',
    'domiciliations',
    'domicilierions',
    'doubles-crèmes',
    'doucereusement',
    'douillettement',
    'douilletteries',
    'dragéifiassent',
    'dragéifiassiez',
    'dragéifierions',
    'drageonnassent',
    'drageonnassiez',
    'drageonnements',
    'drageonnerions',
    'dramatiquement',
    'dramatisassent',
    'dramatisassiez',
    'dramatisations',
    'dramatiserions',
    'dressing-rooms',
    'dubitativement',
    'dulcifiassions',
    'dulcifications',
    'dulcifieraient',
    'dupliquassions',
    'dupliqueraient',
    'dynamisassions',
    'dynamiseraient',
    'dynamitassions',
    'dynamiteraient',
    'dynamogéniques',
    'dynamométrique',
    'éblouissements',
    'ébouillantages',
    'ébouillantâmes',
    'ébouillantasse',
    'ébouillantâtes',
    'ébouillanterai',
    'ébouillanteras',
    'ébouillanterez',
    'ébouillantions',
    'ébourgeonnages',
    'ébourgeonnâmes',
    'ébourgeonnasse',
    'ébourgeonnâtes',
    'ébourgeonnerai',
    'ébourgeonneras',
    'ébourgeonnerez',
    'ébourgeonnions',
    'ébouriffassent',
    'ébouriffassiez',
    'ébourifferions',
    'ébranchassions',
    'ébrancheraient',
    'ébulliométries',
    'ébullioscopies',
    'écarquillaient',
    'écarquillasses',
    'écarquillerais',
    'écarquillerait',
    'écarquillèrent',
    'écarquilleriez',
    'écarquillerons',
    'écarquilleront',
    'écartelassions',
    'écartèleraient',
    'ecclésiastique',
    'échafaudassent',
    'échafaudassiez',
    'échafauderions',
    'échalassassent',
    'échalassassiez',
    'échalasserions',
    'échampissaient',
    'échancrassions',
    'échancreraient',
    'échanfreinâmes',
    'échanfreinasse',
    'échanfreinâtes',
    'échanfreinerai',
    'échanfreineras',
    'échanfreinerez',
    'échanfreinions',
    'échangeassions',
    'échantillonnai',
    'échantillonnas',
    'échantillonnât',
    'échantillonnée',
    'échantillonner',
    'échantillonnes',
    'échantillonnés',
    'échantillonnez',
    'échardonnaient',
    'échardonnasses',
    'échardonnerais',
    'échardonnerait',
    'échardonnèrent',
    'échardonneriez',
    'échardonnerons',
    'échardonneront',
    'échauffassions',
    'échaufferaient',
    'échelonnassent',
    'échelonnassiez',
    'échelonnements',
    'échelonnerions',
    'échenillassent',
    'échenillassiez',
    'échenillerions',
    'échevelassions',
    'échevellerions',
    'éclaboussaient',
    'éclaboussasses',
    'éclaboussement',
    'éclabousserais',
    'éclabousserait',
    'éclaboussèrent',
    'éclabousseriez',
    'éclabousserons',
    'éclabousseront',
    'éclairciraient',
    'éclaircissages',
    'éclaircissions',
    'éconduisissent',
    'éconduisissiez',
    'économétricien',
    'économétriques',
    'économiquement',
    'économisassent',
    'économisassiez',
    'économiserions',
    'écorniflassent',
    'écorniflassiez',
    'écorniflerions',
    'écouvillonnais',
    'écouvillonnait',
    'écouvillonnant',
    'écouvillonnées',
    'écouvillonnent',
    'écouvillonnera',
    'écouvillonniez',
    'écouvillonnons',
    'écrabouillages',
    'écrabouillâmes',
    'écrabouillasse',
    'écrabouillâtes',
    'écrabouillerai',
    'écrabouilleras',
    'écrabouillerez',
    'écrabouillions',
    'écrivaillaient',
    'écrivaillasses',
    'écrivaillerais',
    'écrivaillerait',
    'écrivaillèrent',
    'écrivailleriez',
    'écrivaillerons',
    'écrivailleront',
    'écrivailleuses',
    'écrivassassent',
    'écrivassassiez',
    'écrivasserions',
    'écussonnassent',
    'écussonnassiez',
    'écussonnerions',
    'éditionnassent',
    'éditionnassiez',
    'éditionnerions',
    'éditorialistes',
    'éducationnelle',
    'édulcorassions',
    'édulcoreraient',
    'éfaufilassions',
    'éfaufileraient',
    'effarouchaient',
    'effarouchasses',
    'effarouchement',
    'effaroucherais',
    'effaroucherait',
    'effarouchèrent',
    'effaroucheriez',
    'effaroucherons',
    'effaroucheront',
    'effectuassions',
    'effectueraient',
    'efféminassions',
    'effémineraient',
    'effervescences',
    'effervescentes',
    'effeuillaisons',
    'effeuillassent',
    'effeuillassiez',
    'effeuillements',
    'effeuillerions',
    'effilochassent',
    'effilochassiez',
    'effilocherions',
    'efflanquassent',
    'efflanquassiez',
    'efflanquerions',
    'effleurassions',
    'effleureraient',
    'effleuriraient',
    'effleurissions',
    'efflorescences',
    'efflorescentes',
    'effondrassions',
    'effondreraient',
    'effrangeassent',
    'effrangeassiez',
    'effrangeraient',
    'effroyablement',
    'égosillassions',
    'égratignassent',
    'égratignassiez',
    'égratignerions',
    'égravillonnais',
    'égravillonnait',
    'égravillonnant',
    'égravillonnées',
    'égravillonnent',
    'égravillonnera',
    'égravillonniez',
    'égravillonnons',
    'élargissements',
    'élasticimétrie',
    'électoralismes',
    'électoralistes',
    'électriciennes',
    'électrifiaient',
    'électrifiasses',
    'électrifierais',
    'électrifierait',
    'électrifièrent',
    'électrifieriez',
    'électrifierons',
    'électrifieront',
    'électriquement',
    'électrisassent',
    'électrisassiez',
    'électrisations',
    'électriserions',
    'électrocautère',
    'électrochimies',
    'électrocutâmes',
    'électrocutasse',
    'électrocutâtes',
    'électrocuterai',
    'électrocuteras',
    'électrocuterez',
    'électrocutions',
    'électrolysable',
    'électrolysâmes',
    'électrolysasse',
    'électrolysâtes',
    'électrolyserai',
    'électrolyseras',
    'électrolyserez',
    'électrolysions',
    'électrolytique',
    'électroménager',
    'électromoteurs',
    'électromotrice',
    'électroniciens',
    'électronisâmes',
    'électronisasse',
    'électronisâtes',
    'électroniserai',
    'électroniseras',
    'électroniserez',
    'électronisions',
    'électrophorèse',
    'électropositif',
    'électrothermie',
    'électrovalence',
    'éléphantesques',
    'élisabéthaines',
    'elliptiquement',
    'élucubrassions',
    'élucubreraient',
    'émancipassions',
    'émancipatrices',
    'émanciperaient',
    'émasculassions',
    'émasculeraient',
    'emballottaient',
    'emballottasses',
    'emballotterais',
    'emballotterait',
    'emballottèrent',
    'emballotteriez',
    'emballotterons',
    'emballotteront',
    'embarbouillais',
    'embarbouillait',
    'embarbouillant',
    'embarbouillées',
    'embarbouillent',
    'embarbouillera',
    'embarbouilliez',
    'embarbouillons',
    'embarquassions',
    'embarqueraient',
    'embarrassaient',
    'embarrassantes',
    'embarrassasses',
    'embarrasserais',
    'embarrasserait',
    'embarrassèrent',
    'embarrasseriez',
    'embarrasserons',
    'embarrasseront',
    'embastillaient',
    'embastillasses',
    'embastillerais',
    'embastillerait',
    'embastillèrent',
    'embastilleriez',
    'embastillerons',
    'embastilleront',
    'embastionnâmes',
    'embastionnasse',
    'embastionnâtes',
    'embastionnerai',
    'embastionneras',
    'embastionnerez',
    'embastionnions',
    'embauchassions',
    'embaucheraient',
    'embecquassions',
    'embecqueraient',
    'émbéguinassiez',
    'émbéguinerions',
    'embellissaient',
    'embellissasses',
    'embellissement',
    'embellissèrent',
    'emberlificotai',
    'emberlificotas',
    'emberlificotât',
    'emberlificotée',
    'emberlificoter',
    'emberlificotes',
    'emberlificotés',
    'emberlificotez',
    'embidonnassent',
    'embidonnassiez',
    'embidonnerions',
    'embobelinaient',
    'embobelinasses',
    'embobelinerais',
    'embobelinerait',
    'embobelinèrent',
    'embobelineriez',
    'embobelinerons',
    'embobelineront',
    'embobinassions',
    'embobineraient',
    'embottelassent',
    'embottelassiez',
    'embottellerais',
    'embottellerait',
    'embottelleriez',
    'embottellerons',
    'embottelleront',
    'embouchassions',
    'emboucheraient',
    'embouquassions',
    'embouqueraient',
    'embourbassions',
    'embourberaient',
    'embourgeoisais',
    'embourgeoisait',
    'embourgeoisant',
    'embourgeoisées',
    'embourgeoisent',
    'embourgeoisera',
    'embourgeoisiez',
    'embourgeoisons',
    'embourrassions',
    'embourreraient',
    'embouteillages',
    'embouteillâmes',
    'embouteillasse',
    'embouteillâtes',
    'embouteillerai',
    'embouteilleras',
    'embouteillerez',
    'embouteillions',
    'emboutissaient',
    'embranchassent',
    'embranchassiez',
    'embranchements',
    'embrancherions',
    'embraquassions',
    'embraqueraient',
    'embrassassions',
    'embrasseraient',
    'embrigadassent',
    'embrigadassiez',
    'embrigadements',
    'embrigaderions',
    'embringuassent',
    'embringuassiez',
    'embringuerions',
    'embrochassions',
    'embrocheraient',
    'embronchassent',
    'embronchassiez',
    'embronchements',
    'embroncherions',
    'embrouillaient',
    'embrouillamini',
    'embrouillasses',
    'embrouillement',
    'embrouillerais',
    'embrouillerait',
    'embrouillèrent',
    'embrouilleriez',
    'embrouillerons',
    'embrouilleront',
    'embroussaillai',
    'embroussaillas',
    'embroussaillât',
    'embroussaillée',
    'embroussailler',
    'embroussailles',
    'embroussaillés',
    'embroussaillez',
    'embrunissaient',
    'embryogéniques',
    'embryologiques',
    'embryologistes',
    'embusquassions',
    'embusqueraient',
    'émerillonnâmes',
    'émerillonnasse',
    'émerillonnâtes',
    'émerillonnerai',
    'émerillonneras',
    'émerillonnerez',
    'émerillonnions',
    'émerveillaient',
    'émerveillasses',
    'émerveillement',
    'émerveillerais',
    'émerveillerait',
    'émerveillèrent',
    'émerveilleriez',
    'émerveillerons',
    'émerveilleront',
    'éminentissimes',
    'emmagasinaient',
    'emmagasinasses',
    'emmagasinerais',
    'emmagasinerait',
    'emmagasinèrent',
    'emmagasineriez',
    'emmagasinerons',
    'emmagasineront',
    'emmaillotaient',
    'emmaillotasses',
    'emmaillotement',
    'emmailloterais',
    'emmailloterait',
    'emmaillotèrent',
    'emmailloteriez',
    'emmailloterons',
    'emmailloteront',
    'emmanchassions',
    'emmancheraient',
    'emmargeassions',
    'emménageassent',
    'emménageassiez',
    'emménageraient',
    'emmiellassions',
    'emmielleraient',
    'emmitonnassent',
    'emmitonnassiez',
    'emmitonnerions',
    'emmitouflaient',
    'emmitouflasses',
    'emmitouflerais',
    'emmitouflerait',
    'emmitouflèrent',
    'emmitoufleriez',
    'emmitouflerons',
    'emmitoufleront',
    'emmortaisaient',
    'emmortaisasses',
    'emmortaiserais',
    'emmortaiserait',
    'emmortaisèrent',
    'emmortaiseriez',
    'emmortaiserons',
    'emmortaiseront',
    'emmouscaillais',
    'emmouscaillait',
    'emmouscaillant',
    'emmouscaillées',
    'emmouscaillent',
    'emmouscaillera',
    'emmouscailliez',
    'emmouscaillons',
    'émorfilassions',
    'émorfileraient',
    'émotionnassent',
    'émotionnassiez',
    'émotionnerions',
    'émouchetassent',
    'émouchetassiez',
    'émouchèterions',
    'émoustillaient',
    'émoustillantes',
    'émoustillasses',
    'émoustillerais',
    'émoustillerait',
    'émoustillèrent',
    'émoustilleriez',
    'émoustillerons',
    'émoustilleront',
    'empaillassions',
    'empanachassent',
    'empanachassiez',
    'empanacherions',
    'empapillotâmes',
    'empapillotasse',
    'empapillotâtes',
    'empapilloterai',
    'empapilloteras',
    'empapilloterez',
    'empapillotions',
    'empaquetassent',
    'empaquetassiez',
    'empaquetterais',
    'empaquetterait',
    'empaquetteriez',
    'empaquetterons',
    'empaquetteront',
    'emparquassions',
    'emparqueraient',
    'empeignassions',
    'empeigneraient',
    'emperchassions',
    'empercheraient',
    'emphatiquement',
    'emphysémateuse',
    'emphytéotiques',
    'empierrassions',
    'empierreraient',
    'empiffrassions',
    'emplaillerions',
    'emplâtrassions',
    'empoignassions',
    'empoigneraient',
    'empoisonnaient',
    'empoisonnantes',
    'empoisonnasses',
    'empoisonnement',
    'empoisonnerais',
    'empoisonnerait',
    'empoisonnèrent',
    'empoisonneriez',
    'empoisonnerons',
    'empoisonneront',
    'empoisonneuses',
    'empoissassions',
    'empoisseraient',
    'empoissonnâmes',
    'empoissonnasse',
    'empoissonnâtes',
    'empoissonnerai',
    'empoissonneras',
    'empoissonnerez',
    'empoissonnions',
    'empourprassent',
    'empourprassiez',
    'empourprerions',
    'empoussiérâmes',
    'empoussiérasse',
    'empoussiérâtes',
    'empoussiérerai',
    'empoussiéreras',
    'empoussiérerez',
    'empoussiérions',
    'empreignissent',
    'empreignissiez',
    'empreindraient',
    'empressassions',
    'emprésurassent',
    'emprésurassiez',
    'emprésurerions',
    'emprisonnaient',
    'emprisonnasses',
    'emprisonnement',
    'emprisonnerais',
    'emprisonnerait',
    'emprisonnèrent',
    'emprisonneriez',
    'emprisonnerons',
    'emprisonneront',
    'empruntassions',
    'emprunteraient',
    'empuantiraient',
    'empuantissions',
    'empyreumatique',
    'émulsifiassent',
    'émulsifiassiez',
    'émulsifierions',
    'émulsionnaient',
    'émulsionnasses',
    'émulsionnerais',
    'émulsionnerait',
    'émulsionnèrent',
    'émulsionneriez',
    'émulsionnerons',
    'émulsionneront',
    'enamourassions',
    'énamourassions',
    'énantiomorphes',
    'encabanassions',
    'encabaneraient',
    'encaissassions',
    'encaisseraient',
    'encanaillaient',
    'encanaillasses',
    'encanaillement',
    'encanaillerais',
    'encanaillerait',
    'encanaillèrent',
    'encanailleriez',
    'encanaillerons',
    'encanailleront',
    'encapuchonnais',
    'encapuchonnait',
    'encapuchonnant',
    'encapuchonnées',
    'encapuchonnent',
    'encapuchonnera',
    'encapuchonniez',
    'encapuchonnons',
    'encartonnaient',
    'encartonnasses',
    'encartonnerais',
    'encartonnerait',
    'encartonnèrent',
    'encartonneriez',
    'encartonnerons',
    'encartonneront',
    'encartouchâmes',
    'encartouchasse',
    'encartouchâtes',
    'encartoucherai',
    'encartoucheras',
    'encartoucherez',
    'encartouchions',
    'encasernassent',
    'encasernassiez',
    'encasernerions',
    'encastelassiez',
    'encastèlerions',
    'encastrassions',
    'encastreraient',
    'encaustiquages',
    'encaustiquâmes',
    'encaustiquasse',
    'encaustiquâtes',
    'encaustiquerai',
    'encaustiqueras',
    'encaustiquerez',
    'encaustiquions',
    'encerclassions',
    'encercleraient',
    'enchaînassions',
    'enchaîneraient',
    'enchantassions',
    'enchanteraient',
    'enchanteresses',
    'enchaperonnais',
    'enchaperonnait',
    'enchaperonnant',
    'enchaperonnées',
    'enchaperonnent',
    'enchaperonnera',
    'enchaperonniez',
    'enchaperonnons',
    'encharnassions',
    'encharneraient',
    'enchâssassions',
    'enchâsseraient',
    'enchatonnaient',
    'enchatonnasses',
    'enchatonnement',
    'enchatonnerais',
    'enchatonnerait',
    'enchatonnèrent',
    'enchatonneriez',
    'enchatonnerons',
    'enchatonneront',
    'enchaussassent',
    'enchaussassiez',
    'enchausserions',
    'enchemisassent',
    'enchemisassiez',
    'enchemiserions',
    'enchérissaient',
    'enchérisseuses',
    'enchevalements',
    'enchevauchâmes',
    'enchevauchasse',
    'enchevauchâtes',
    'enchevaucherai',
    'enchevaucheras',
    'enchevaucherez',
    'enchevauchions',
    'enchevauchures',
    'enchevêtraient',
    'enchevêtrasses',
    'enchevêtrement',
    'enchevêtrerais',
    'enchevêtrerait',
    'enchevêtrèrent',
    'enchevêtreriez',
    'enchevêtrerons',
    'enchevêtreront',
    'enchifrenaient',
    'enchifrenasses',
    'enchifrènement',
    'enchifrènerais',
    'enchifrènerait',
    'enchifrenèrent',
    'enchifrèneriez',
    'enchifrènerons',
    'enchifrèneront',
    'enclenchassent',
    'enclenchassiez',
    'enclenchements',
    'enclencherions',
    'encliquetaient',
    'encliquetasses',
    'encliquetèrent',
    'encliquetterai',
    'encliquetteras',
    'encliquetterez',
    'encloîtrassent',
    'encloîtrassiez',
    'encloîtrerions',
    'encoffrassions',
    'encoffreraient',
    'encombrassions',
    'encombreraient',
    'encorbellement',
    'encourageaient',
    'encourageantes',
    'encourageasses',
    'encouragements',
    'encouragerions',
    'encrassassions',
    'encrasseraient',
    'encroûtassions',
    'encroûteraient',
    'encyclopédique',
    'encyclopédiste',
    'endeuillassent',
    'endeuillassiez',
    'endeuillerions',
    'endiablassions',
    'endiableraient',
    'endimanchaient',
    'endimanchasses',
    'endimancherais',
    'endimancherait',
    'endimanchèrent',
    'endimancheriez',
    'endimancherons',
    'endimancheront',
    'endivisionnais',
    'endivisionnait',
    'endivisionnant',
    'endivisionnées',
    'endivisionnent',
    'endivisionnera',
    'endivisionniez',
    'endivisionnons',
    'endocriniennes',
    'endocrinologie',
    'endoctrinaient',
    'endoctrinasses',
    'endoctrinement',
    'endoctrinerais',
    'endoctrinerait',
    'endoctrinèrent',
    'endoctrineriez',
    'endoctrinerons',
    'endoctrineront',
    'endoloriraient',
    'endolorissions',
    'endommageaient',
    'endommageasses',
    'endommagements',
    'endommagerions',
    'endothermiques',
    'endurcissaient',
    'endurcissement',
    'enfarinassions',
    'enfarineraient',
    'enfiellassions',
    'enfielleraient',
    'enfiévrassions',
    'enfiévreraient',
    'enflammassions',
    'enflammeraient',
    'enfléchassions',
    'enflécheraient',
    'enfleurassions',
    'enfleureraient',
    'enforcissaient',
    'enfouissements',
    'enfourchassent',
    'enfourchassiez',
    'enfourchements',
    'enfourcherions',
    'enfournassions',
    'enfourneraient',
    'enfreignissent',
    'enfreignissiez',
    'enfreindraient',
    'enfutaillaient',
    'enfutaillasses',
    'enfutaillerais',
    'enfutaillerait',
    'enfutaillèrent',
    'enfutailleriez',
    'enfutaillerons',
    'enfutailleront',
    'engargoussâmes',
    'engargoussasse',
    'engargoussâtes',
    'engargousserai',
    'engargousseras',
    'engargousserez',
    'engargoussions',
    'engazonnassent',
    'engazonnassiez',
    'engazonnements',
    'engazonnerions',
    'engendrassions',
    'engendreraient',
    'engloutiraient',
    'engloutissions',
    'engorgeassions',
    'engouffrassent',
    'engouffrassiez',
    'engouffrements',
    'engouffrerions',
    'engourdiraient',
    'engourdissions',
    'engraissassent',
    'engraissassiez',
    'engraissements',
    'engraisserions',
    'engrangeassent',
    'engrangeassiez',
    'engrangeraient',
    'engrossassions',
    'engrosseraient',
    'engrumelassent',
    'engrumelassiez',
    'engrumellerais',
    'engrumellerait',
    'engrumelleriez',
    'engrumellerons',
    'engrumelleront',
    'engueulassions',
    'engueuleraient',
    'enguirlandâmes',
    'enguirlandasse',
    'enguirlandâtes',
    'enguirlanderai',
    'enguirlanderas',
    'enguirlanderez',
    'enguirlandions',
    'enhardissaient',
    'enharnachaient',
    'enharnachasses',
    'enharnacherais',
    'enharnacherait',
    'enharnachèrent',
    'enharnacheriez',
    'enharnacherons',
    'enharnacheront',
    'enjavelassions',
    'enjavellerions',
    'enjoignissions',
    'enjolivassions',
    'enjoliveraient',
    'enjuponnassent',
    'enjuponnassiez',
    'enjuponnerions',
    'enlaidissaient',
    'enlaidissement',
    'enliassassions',
    'enliasseraient',
    'enluminassions',
    'enlumineraient',
    'enneigeassions',
    'ennoblissaient',
    'ennoblissement',
    'ennuageassions',
    'enorgueillîmes',
    'enorgueillirai',
    'enorgueilliras',
    'enorgueillirez',
    'enorgueillisse',
    'enorgueillîtes',
    'enquiquinaient',
    'enquiquinantes',
    'enquiquinasses',
    'enquiquinerais',
    'enquiquinerait',
    'enquiquinèrent',
    'enquiquineriez',
    'enquiquinerons',
    'enquiquineront',
    'enquiquineuses',
    'enracinassions',
    'enracineraient',
    'enraillassions',
    'enrailleraient',
    'enrégimentâmes',
    'enrégimentasse',
    'enrégimentâtes',
    'enrégimenterai',
    'enrégimenteras',
    'enrégimenterez',
    'enrégimentions',
    'enregistrables',
    'enregistraient',
    'enregistrasses',
    'enregistrement',
    'enregistrerais',
    'enregistrerait',
    'enregistrèrent',
    'enregistreriez',
    'enregistrerons',
    'enregistreront',
    'enregistreuses',
    'enrichissaient',
    'enrichissantes',
    'enrichissement',
    'enrouillassent',
    'enrouillassiez',
    'enrouillerions',
    'enrubannassent',
    'enrubannassiez',
    'enrubannerions',
    'ensabotassions',
    'ensaboteraient',
    'ensaisinassent',
    'ensaisinassiez',
    'ensaisinerions',
    'ensanglantâmes',
    'ensanglantasse',
    'ensanglantâtes',
    'ensanglanterai',
    'ensanglanteras',
    'ensanglanterez',
    'ensanglantions',
    'enseignassions',
    'enseigneraient',
    'ensemençassent',
    'ensemençassiez',
    'ensemencements',
    'ensemencerions',
    'enseveliraient',
    'ensevelissions',
    'ensoleillaient',
    'ensoleillasses',
    'ensoleillement',
    'ensoleillerais',
    'ensoleillerait',
    'ensoleillèrent',
    'ensoleilleriez',
    'ensoleillerons',
    'ensoleilleront',
    'ensorcelassent',
    'ensorcelassiez',
    'ensorcellement',
    'ensorcellerais',
    'ensorcellerait',
    'ensorcelleriez',
    'ensorcellerons',
    'ensorcelleront',
    'ensoufrassions',
    'ensoufreraient',
    'entaillassions',
    'entailleraient',
    'entartrassions',
    'entartreraient',
    'enténébrassent',
    'enténébrassiez',
    'enténébrerions',
    'entérinassions',
    'entérineraient',
    'entéro-rénales',
    'enthousiasmais',
    'enthousiasmait',
    'enthousiasmant',
    'enthousiasmées',
    'enthousiasment',
    'enthousiasmera',
    'enthousiasmiez',
    'enthousiasmons',
    'entomologiques',
    'entomologistes',
    'entortillaient',
    'entortillasses',
    'entortillement',
    'entortillerais',
    'entortillerait',
    'entortillèrent',
    'entortilleriez',
    'entortillerons',
    'entortilleront',
    'entourloupette',
    'entraccordâmes',
    'entraccordâtes',
    'entraccorderez',
    'entraccordions',
    'entraccuserons',
    'entradmireriez',
    'entradmirerons',
    'entraidassions',
    'entraînassions',
    'entraîneraient',
    'entrebâillâmes',
    'entrebâillasse',
    'entrebâillâtes',
    'entrebâillerai',
    'entrebâilleras',
    'entrebâillerez',
    'entrebâilleurs',
    'entrebâillions',
    'entrebattrions',
    'entrechoquâmes',
    'entrechoquasse',
    'entrechoquâtes',
    'entrechoquerai',
    'entrechoqueras',
    'entrechoquerez',
    'entrechoquions',
    'entrecoupaient',
    'entrecoupasses',
    'entrecouperais',
    'entrecouperait',
    'entrecoupèrent',
    'entrecouperiez',
    'entrecouperons',
    'entrecouperont',
    'entrecroisâmes',
    'entrecroisasse',
    'entrecroisâtes',
    'entrecroiserai',
    'entrecroiseras',
    'entrecroiserez',
    'entrecroisions',
    'entre-déchirée',
    'entre-déchirer',
    'entre-déchirés',
    'entre-déchirez',
    'entre-détruire',
    'entre-détruite',
    'entre-détruits',
    'entre-dévorées',
    'entre-dévoriez',
    'entre-dévorons',
    'entre-égorgées',
    'entre-frappées',
    'entre-frappiez',
    'entre-frappons',
    'entre-haïrions',
    'entre-haïssiez',
    'entre-haïssons',
    'entre-heurtées',
    'entre-heurtiez',
    'entre-heurtons',
    'entrelaçassent',
    'entrelaçassiez',
    'entrelacements',
    'entrelacerions',
    'entrelardaient',
    'entrelardasses',
    'entrelarderais',
    'entrelarderait',
    'entrelardèrent',
    'entrelarderiez',
    'entrelarderons',
    'entrelarderont',
    'entre-loueriez',
    'entre-louerons',
    'entre-mangeons',
    'entre-mangerez',
    'entre-mangions',
    'entremêlassent',
    'entremêlassiez',
    'entremêlements',
    'entremêlerions',
    'entremettaient',
    'entremetteuses',
    'entremettrions',
    'entre-nuirions',
    'entre-nuisîmes',
    'entre-nuisions',
    'entre-nuisîtes',
    'entreposassent',
    'entreposassiez',
    'entreposerions',
    'entrepositaire',
    'entreprenaient',
    'entreprenantes',
    'entreprendrais',
    'entreprendrait',
    'entreprendriez',
    'entreprendrons',
    'entreprendront',
    'entrepreneuses',
    'entreprissions',
    'entre-regardée',
    'entre-regarder',
    'entre-regardés',
    'entre-regardez',
    'entretaillâmes',
    'entretaillâtes',
    'entretaillerez',
    'entretaillions',
    'entretiendrais',
    'entretiendrait',
    'entretiendriez',
    'entretiendrons',
    'entretiendront',
    'entretinssions',
    'entretoisaient',
    'entretoisasses',
    'entretoiserais',
    'entretoiserait',
    'entretoisèrent',
    'entretoiseriez',
    'entretoiserons',
    'entretoiseront',
    'entre-tuassiez',
    'entre-tuerions',
    'entreverraient',
    'entrevoûtaient',
    'entrevoûtasses',
    'entrevouterais',
    'entrevouterait',
    'entrevoûtèrent',
    'entrevouteriez',
    'entrevouterons',
    'entrevouteront',
    'entrouvririons',
    'entrouvrissent',
    'entrouvrissiez',
    'envahissements',
    'enveloppassent',
    'enveloppassiez',
    'enveloppements',
    'envelopperions',
    'envenimassions',
    'envenimeraient',
    'envergeassions',
    'enverguassions',
    'envergueraient',
    'envieillirions',
    'envieillissais',
    'envieillissait',
    'envieillissant',
    'envieillissent',
    'envieillissiez',
    'envieillissons',
    'environnassent',
    'environnassiez',
    'environnements',
    'environnerions',
    'envisageassent',
    'envisageassiez',
    'envisageraient',
    'épaississaient',
    'épaississantes',
    'épaississement',
    'épannelassions',
    'épannellerions',
    'épanouissaient',
    'épanouissement',
    'éparpillassent',
    'éparpillassiez',
    'éparpillements',
    'éparpillerions',
    'épeirogéniques',
    'éperonnassions',
    'éperonneraient',
    'épicycloïdales',
    'épidémiologies',
    'épigrammatique',
    'épileptiformes',
    'épiloguassions',
    'épilogueraient',
    'épiloguerasses',
    'épiloguerèrent',
    'épincelassions',
    'épincèleraient',
    'épincetassions',
    'épinceteraient',
    'épisodiquement',
    'épistémologies',
    'épontillassent',
    'épontillassiez',
    'épontillerions',
    'épouillassions',
    'épouilleraient',
    'époumonassions',
    'époumoneraient',
    'époussetassent',
    'époussetassiez',
    'époussetterais',
    'époussetterait',
    'époussetteriez',
    'époussetterons',
    'époussetteront',
    'époustouflâmes',
    'époustouflante',
    'époustouflants',
    'époustouflasse',
    'époustouflâtes',
    'époustouflerai',
    'époustoufleras',
    'époustouflerez',
    'époustouflions',
    'épouvantassent',
    'épouvantassiez',
    'épouvantements',
    'épouvanterions',
    'équarrissaient',
    'équarrissement',
    'équilibrassent',
    'équilibrassiez',
    'équilibrations',
    'équilibrerions',
    'équipartitions',
    'équipotentiels',
    'équivalussions',
    'équivaudraient',
    'équivoquassent',
    'équivoquassiez',
    'équivoquerions',
    'éradiquassions',
    'éradiqueraient',
    'érotomaniaques',
    'érysipélateuse',
    'érythémateuses',
    'érythroblastes',
    'érythromycines',
    'esbroufassions',
    'esbrouferaient',
    'escaladassions',
    'escaladeraient',
    'escamotassions',
    'escamoteraient',
    'escarmouchâmes',
    'escarmouchasse',
    'escarmouchâtes',
    'escarmoucherai',
    'escarmoucheras',
    'escarmoucherez',
    'escarmouchions',
    'escarrifiaient',
    'escarrifiasses',
    'escarrifierais',
    'escarrifierait',
    'escarrifièrent',
    'escarrifieriez',
    'escarrifierons',
    'escarrifieront',
    'esclaffassions',
    'esclavageaient',
    'esclavageasses',
    'esclavagerions',
    'escomptassions',
    'escompteraient',
    'escroquassions',
    'escroqueraient',
    'espionnassions',
    'espionneraient',
    'espolinassions',
    'espolineraient',
    'espoulinassent',
    'espoulinassiez',
    'espoulinerions',
    'esprit-de-bois',
    'esquintassions',
    'esquinteraient',
    'esquissassions',
    'esquisseraient',
    'essangeassions',
    'essentialismes',
    'essorillassent',
    'essorillassiez',
    'essorillerions',
    'essouchassions',
    'essoucheraient',
    'essoufflassent',
    'essoufflassiez',
    'essoufflements',
    'essoufflerions',
    'essuie-meubles',
    'establishments',
    'est-allemandes',
    'estampillaient',
    'estampillasses',
    'estampillerais',
    'estampillerait',
    'estampillèrent',
    'estampilleriez',
    'estampillerons',
    'estampilleront',
    'estérifiassent',
    'estérifiassiez',
    'estérification',
    'estérifierions',
    'esthéticiennes',
    'esthétiquement',
    'esthétisassent',
    'esthétisassiez',
    'esthétiserions',
    'estomaquassent',
    'estomaquassiez',
    'estomaquerions',
    'estourbiraient',
    'estourbissions',
    'estrapadassent',
    'estrapadassiez',
    'estrapaderions',
    'estrapassaient',
    'estrapassasses',
    'estrapasserais',
    'estrapasserait',
    'estrapassèrent',
    'estrapasseriez',
    'estrapasserons',
    'estrapasseront',
    'estropiassions',
    'estropieraient',
    'établissements',
    'étalageassions',
    'étalinguassent',
    'étalinguassiez',
    'étalinguerions',
    'étalonnassions',
    'étalonneraient',
    'étançonnassent',
    'étançonnassiez',
    'étançonnerions',
    'éternisassions',
    'éterniseraient',
    'éthérifiassent',
    'éthérifiassiez',
    'éthérification',
    'éthérifierions',
    'éthérisassions',
    'éthériseraient',
    'ethnographique',
    'étincelassions',
    'étincellements',
    'étincellerions',
    'étiquetassions',
    'étiquetterions',
    'étoupillassent',
    'étoupillassiez',
    'étoupillerions',
    'étourdissaient',
    'étourdissantes',
    'étourdissement',
    'étranglassions',
    'étrangleraient',
    'étreignissions',
    'étrésillonnais',
    'étrésillonnait',
    'étrésillonnant',
    'étrésillonnées',
    'étrésillonnent',
    'étrésillonnera',
    'étrésillonniez',
    'étrésillonnons',
    'étronçonnaient',
    'étronçonnasses',
    'étronçonnerais',
    'étronçonnerait',
    'étronçonnèrent',
    'étronçonneriez',
    'étronçonnerons',
    'étronçonneront',
    'eucharistiques',
    'eudiométriques',
    'euphémiquement',
    'euphoniquement',
    'euphorisassent',
    'euphorisassiez',
    'euphoriserions',
    'européanisâmes',
    'européanisasse',
    'européanisâtes',
    'européaniserai',
    'européaniseras',
    'européaniserez',
    'européanisions',
    'eutrophisation',
    'évangélisaient',
    'évangélisasses',
    'évangélisateur',
    'évangélisation',
    'évangéliserais',
    'évangéliserait',
    'évangélisèrent',
    'évangéliseriez',
    'évangéliserons',
    'évangéliseront',
    'évanouissement',
    'événementielle',
    'éventuellement',
    'éviscérassions',
    'éviscéreraient',
    'évolutionnisme',
    'évolutionniste',
    'exacerbassions',
    'exacerberaient',
    'exanthématique',
    'exaspérassions',
    'exaspéreraient',
    'excentrassions',
    'excentreraient',
    'exceptionnelle',
    'excommuniaient',
    'excommuniasses',
    'excommunierais',
    'excommunierait',
    'excommunièrent',
    'excommunieriez',
    'excommunierons',
    'excommunieront',
    'excrémentielle',
    'excursionnâmes',
    'excursionnasse',
    'excursionnâtes',
    'excursionnerai',
    'excursionneras',
    'excursionnerez',
    'excursionnions',
    'excursionniste',
    'exemplairement',
    'exemplifiaient',
    'exemplifiasses',
    'exemplifierais',
    'exemplifierait',
    'exemplifièrent',
    'exemplifieriez',
    'exemplifierons',
    'exemplifieront',
    'exhaussassions',
    'exhausseraient',
    'exhaustivement',
    'exhérédassions',
    'exhéréderaient',
    'existentielles',
    'exophtalmiques',
    'exorcisassions',
    'exorciseraient',
    'expansibilités',
    'expansionnisme',
    'expansionniste',
    'expatriassions',
    'expatrieraient',
    'expectorassent',
    'expectorassiez',
    'expectorations',
    'expectorerions',
    'expéditivement',
    'expérimentales',
    'expérimentâmes',
    'expérimentasse',
    'expérimentâtes',
    'expérimenterai',
    'expérimenteras',
    'expérimenterez',
    'expérimentions',
    'expertisassent',
    'expertisassiez',
    'expertiserions',
    'explicitassent',
    'explicitassiez',
    'explicitations',
    'expliciterions',
    'expliquassions',
    'expliqueraient',
    'exploitabilité',
    'exploitassions',
    'exploiteraient',
    'exponentielles',
    'expressivement',
    'expropriassent',
    'expropriassiez',
    'expropriations',
    'exproprierions',
    'expurgeassions',
    'extensibilités',
    'extérieurement',
    'extériorisâmes',
    'extériorisasse',
    'extériorisâtes',
    'extérioriserai',
    'extérioriseras',
    'extérioriserez',
    'extériorisions',
    'exterminassent',
    'exterminassiez',
    'exterminateurs',
    'exterminations',
    'exterminatrice',
    'exterminerions',
    'extéroceptives',
    'extorquassions',
    'extorqueraient',
    'extraordinaire',
    'extrapolassent',
    'extrapolassiez',
    'extrapolations',
    'extrapolerions',
    'extra-sensible',
    'extraterrestre',
    'extravaguaient',
    'extravaguasses',
    'extravaguerais',
    'extravaguerait',
    'extravaguèrent',
    'extravagueriez',
    'extravaguerons',
    'extravagueront',
    'extravasassent',
    'extravasassiez',
    'extravasations',
    'extravaserions',
    'exulcérassions',
    'exulcéreraient',
    'fabriquassions',
    'fabriqueraient',
    'facétieusement',
    'facilitassions',
    'faciliteraient',
    'factorisassent',
    'factorisassiez',
    'factorisations',
    'factoriserions',
    'faignantassent',
    'faignantassiez',
    'faignanterions',
    'fainéantassent',
    'fainéantassiez',
    'fainéanterions',
    'faisandassions',
    'faisanderaient',
    'falsifiassions',
    'falsificateurs',
    'falsifications',
    'falsificatrice',
    'falsifieraient',
    'familiarisâmes',
    'familiarisasse',
    'familiarisâtes',
    'familiariserai',
    'familiariseras',
    'familiariserez',
    'familiarisions',
    'fanatisassions',
    'fanatiseraient',
    'fanfaronnaient',
    'fanfaronnasses',
    'fanfaronnerais',
    'fanfaronnerait',
    'fanfaronnèrent',
    'fanfaronneriez',
    'fanfaronnerons',
    'fanfaronneront',
    'fanfreluchâmes',
    'fanfreluchasse',
    'fanfreluchâtes',
    'fanfrelucherai',
    'fanfrelucheras',
    'fanfrelucherez',
    'fanfreluchions',
    'fangothérapies',
    'fantasmagories',
    'fantasmassions',
    'fantasmatiques',
    'fantasmeraient',
    'farfouillaient',
    'farfouillasses',
    'farfouillerais',
    'farfouillerait',
    'farfouillèrent',
    'farfouilleriez',
    'farfouillerons',
    'farfouilleront',
    'faseillassions',
    'faseilleraient',
    'faucardassions',
    'faucarderaient',
    'fauconnassions',
    'fauconneraient',
    'faux-monnayeur',
    'faux-semblants',
    'favorisassions',
    'favoriseraient',
    'fécondabilités',
    'fédéralisaient',
    'fédéralisasses',
    'fédéraliserais',
    'fédéraliserait',
    'fédéralisèrent',
    'fédéraliseriez',
    'fédéraliserons',
    'fédéraliseront',
    'feignantassent',
    'feignantassiez',
    'feignanterions',
    'feldspathiques',
    'félicitassions',
    'féliciteraient',
    'féminisassions',
    'féminiseraient',
    'fendillassions',
    'fendilleraient',
    'fenestrassions',
    'fenestreraient',
    'fermentassions',
    'fermenteraient',
    'fermentescible',
    'ferraillassent',
    'ferraillassiez',
    'ferraillements',
    'ferraillerions',
    'ferroprussiate',
    'fertilisassent',
    'fertilisassiez',
    'fertilisations',
    'fertiliserions',
    'festonnassions',
    'festonneraient',
    'fétichisassent',
    'fétichisassiez',
    'fétichiserions',
    'feuiletisasses',
    'feuiletisèrent',
    'feuilletassent',
    'feuilletassiez',
    'feuilletiserai',
    'feuilletiseras',
    'feuilletiserez',
    'feuilletisions',
    'feuilletoniste',
    'feuilletterais',
    'feuilletterait',
    'feuilletteriez',
    'feuilletterons',
    'feuilletteront',
    'fiduciairement',
    'figurativement',
    'filigranassent',
    'filigranassiez',
    'filigranerions',
    'financièrement',
    'finlandisaient',
    'finlandisasses',
    'finlandiserais',
    'finlandiserait',
    'finlandisèrent',
    'finlandiseriez',
    'finlandiserons',
    'finlandiseront',
    'finno-ougriens',
    'fiscalisassent',
    'fiscalisassiez',
    'fiscalisations',
    'fiscaliserions',
    'fissionnassent',
    'fissionnassiez',
    'fissionnerions',
    'flagellassions',
    'flagellatrices',
    'flagelleraient',
    'flageolassions',
    'flageoleraient',
    'flagornassions',
    'flagorneraient',
    'flamboieraient',
    'flamboyassions',
    'fléchissements',
    'flemmardassent',
    'flemmardassiez',
    'flemmarderions',
    'flétrissements',
    'flibustassions',
    'flibusteraient',
    'floconnassions',
    'floconneraient',
    'fluidifiassent',
    'fluidifiassiez',
    'fluidification',
    'fluidifierions',
    'fluidisassions',
    'fluidiseraient',
    'fluorhydriques',
    'fluorisassions',
    'fluoriseraient',
    'fluviométrique',
    'focalisassions',
    'focaliseraient',
    'foisonnassions',
    'foisonneraient',
    'folichonnaient',
    'folichonnasses',
    'folichonnerais',
    'folichonnerait',
    'folichonnèrent',
    'folichonneriez',
    'folichonnerons',
    'folichonneront',
    'fonctionnaient',
    'fonctionnaires',
    'fonctionnalité',
    'fonctionnarisa',
    'fonctionnarise',
    'fonctionnarisé',
    'fonctionnasses',
    'fonctionnelles',
    'fonctionnement',
    'fonctionnerais',
    'fonctionnerait',
    'fonctionnèrent',
    'fonctionneriez',
    'fonctionnerons',
    'fonctionneront',
    'fongistatiques',
    'fontainebleaus',
    'forcipressures',
    'forjetteraient',
    'forlançassions',
    'forlanceraient',
    'forlignassions',
    'forligneraient',
    'formalisassent',
    'formalisassiez',
    'formalisations',
    'formaliserions',
    'formidablement',
    'forniquassions',
    'forniqueraient',
    'fortifiassions',
    'fortifications',
    'fortifieraient',
    'fossilisassent',
    'fossilisassiez',
    'fossilisations',
    'fossiliserions',
    'fouaillassions',
    'fouailleraient',
    'foudroieraient',
    'foudroyassions',
    'fouraillassent',
    'fouraillassiez',
    'fouraillerions',
    'fourcherassent',
    'fourcherassiez',
    'fourgonnassent',
    'fourgonnassiez',
    'fourgonnerions',
    'fourmillassent',
    'fourmillassiez',
    'fourmillements',
    'fourmillerions',
    'fourrageassent',
    'fourrageassiez',
    'fourrageraient',
    'fourvoieraient',
    'fourvoyassions',
    'fracassassions',
    'fracasseraient',
    'fractionnaient',
    'fractionnantes',
    'fractionnasses',
    'fractionnelles',
    'fractionnement',
    'fractionnerais',
    'fractionnerait',
    'fractionnèrent',
    'fractionneriez',
    'fractionnerons',
    'fractionneront',
    'fractionnismes',
    'fracturassions',
    'fractureraient',
    'fragilisassent',
    'fragilisassiez',
    'fragiliserions',
    'fragmentassent',
    'fragmentassiez',
    'fragmentations',
    'fragmenterions',
    'fraîchissaient',
    'fraîchissantes',
    'framboisassent',
    'framboisassiez',
    'framboiserions',
    'franchissables',
    'franchissaient',
    'franchissement',
    'francisassions',
    'franciseraient',
    'franc-quartier',
    'francs-parlers',
    'francs-tireurs',
    'fraternisaient',
    'fraternisantes',
    'fraternisasses',
    'fraternisation',
    'fraterniserais',
    'fraterniserait',
    'fraternisèrent',
    'fraterniseriez',
    'fraterniserons',
    'fraterniseront',
    'fredonnassions',
    'fredonneraient',
    'frénétiquement',
    'fréquencemètre',
    'fréquentassent',
    'fréquentassiez',
    'fréquentations',
    'fréquentatives',
    'fréquenterions',
    'fréquentielles',
    'frétillassions',
    'frétilleraient',
    'fricassassions',
    'fricasseraient',
    'frictionnaient',
    'frictionnasses',
    'frictionnelles',
    'frictionnerais',
    'frictionnerait',
    'frictionnèrent',
    'frictionneriez',
    'frictionnerons',
    'frictionneront',
    'frigorifiaient',
    'frigorifiantes',
    'frigorifiasses',
    'frigorifierais',
    'frigorifierait',
    'frigorifièrent',
    'frigorifieriez',
    'frigorifierons',
    'frigorifieront',
    'frigorifugeais',
    'frigorifugeait',
    'frigorifugeant',
    'frigorifugeons',
    'frigorifugerai',
    'frigorifugeras',
    'frigorifugerez',
    'frigorifugions',
    'friponnassions',
    'friponneraient',
    'fripouilleries',
    'frisottassions',
    'frisotteraient',
    'frissonnassent',
    'frissonnassiez',
    'frissonnements',
    'frissonnerions',
    'froufroutaient',
    'froufroutantes',
    'froufroutasses',
    'froufroutement',
    'froufrouterais',
    'froufrouterait',
    'froufroutèrent',
    'froufrouteriez',
    'froufrouterons',
    'froufrouteront',
    'fructifiassent',
    'fructifiassiez',
    'fructification',
    'fructifierions',
    'fructueusement',
    'fruiticulteurs',
    'fruiticultrice',
    'fume-cigarette',
    'funambulesques',
    'fusionnassions',
    'fusionneraient',
    'fustigeassions',
    'gabionnassions',
    'gabionneraient',
    'gadgétisassent',
    'gadgétisassiez',
    'gadgétiserions',
    'galéopithèques',
    'galipotassions',
    'galipoteraient',
    'gallo-romaines',
    'galvanisassent',
    'galvanisassiez',
    'galvanisations',
    'galvaniserions',
    'galvanocautère',
    'galvanoplastie',
    'galvaudassions',
    'galvauderaient',
    'gambergeassent',
    'gambergeassiez',
    'gambergeraient',
    'gambillassions',
    'gambilleraient',
    'ganglionnaires',
    'gangrenassions',
    'gangrèneraient',
    'garantissaient',
    'garde-barrière',
    'garde-chiourme',
    'garden-parties',
    'gardes-chasses',
    'gardes-magasin',
    'gardes-malades',
    'gargantuesques',
    'gargarisassent',
    'gargarisassiez',
    'gargariserions',
    'gargouillaient',
    'gargouillasses',
    'gargouillement',
    'gargouillerais',
    'gargouillerait',
    'gargouillèrent',
    'gargouilleriez',
    'gargouillerons',
    'gargouilleront',
    'garibaldiennes',
    'garrottassions',
    'garrotteraient',
    'gasconnassions',
    'gasconneraient',
    'gaspillassions',
    'gaspilleraient',
    'gastronomiques',
    'gauchissements',
    'gazéifiassions',
    'gazéifications',
    'gazéifieraient',
    'gazouillassent',
    'gazouillassiez',
    'gazouillements',
    'gazouillerions',
    'gélatinassions',
    'gélatineraient',
    'gélatinisaient',
    'gélatinisasses',
    'gélatiniserais',
    'gélatiniserait',
    'gélatinisèrent',
    'gélatiniseriez',
    'gélatiniserons',
    'gélatiniseront',
    'gendarmassions',
    'gendarmeraient',
    'généralisables',
    'généralisaient',
    'généralisantes',
    'généralisasses',
    'généralisateur',
    'généralisation',
    'généraliserais',
    'généraliserait',
    'généralisèrent',
    'généraliseriez',
    'généraliserons',
    'généraliseront',
    'généralissimes',
    'gentilhommière',
    'géologiquement',
    'géomagnétiques',
    'géomagnétismes',
    'géométrisaient',
    'géométrisasses',
    'géométriserais',
    'géométriserait',
    'géométrisèrent',
    'géométriseriez',
    'géométriserons',
    'géométriseront',
    'géomorphologie',
    'géophysicienne',
    'germanisassent',
    'germanisassiez',
    'germanisations',
    'germaniserions',
    'gérontocraties',
    'gérontophilies',
    'gesticulassent',
    'gesticulassiez',
    'gesticulations',
    'gesticulerions',
    'gigantomachies',
    'girouettassent',
    'girouettassiez',
    'girouetterions',
    'glaciologiques',
    'glandouillâmes',
    'glandouillasse',
    'glandouillâtes',
    'glandouillerai',
    'glandouilleras',
    'glandouillerez',
    'glandouillions',
    'glaviotassions',
    'glavioteraient',
    'glaviottassent',
    'glaviottassiez',
    'glaviotterions',
    'globalisassent',
    'globalisassiez',
    'globaliserions',
    'globe-trotters',
    'glorifiassions',
    'glorificateurs',
    'glorifications',
    'glorificatrice',
    'glorifieraient',
    'glougloutaient',
    'glougloutantes',
    'glougloutasses',
    'glouglouterais',
    'glouglouterait',
    'glougloutèrent',
    'glouglouteriez',
    'glouglouterons',
    'glouglouteront',
    'glycérinassent',
    'glycérinassiez',
    'glycérinerions',
    'glycoprotéines',
    'glyptographies',
    'gobergeassions',
    'godaillassions',
    'godailleraient',
    'godronnassions',
    'godronneraient',
    'goguenardaient',
    'goguenardasses',
    'goguenarderais',
    'goguenarderait',
    'goguenardèrent',
    'goguenarderiez',
    'goguenarderons',
    'goguenarderont',
    'gommes-résines',
    'gonadotrophine',
    'gouaillassions',
    'gouailleraient',
    'goudronnassent',
    'goudronnassiez',
    'goudronnerions',
    'goujonnassions',
    'goujonneraient',
    'goupillassions',
    'goupilleraient',
    'goupillonnâmes',
    'goupillonnasse',
    'goupillonnâtes',
    'goupillonnerai',
    'goupillonneras',
    'goupillonnerez',
    'goupillonnions',
    'gourmandassent',
    'gourmandassiez',
    'gourmanderions',
    'gouvernassions',
    'gouvernemental',
    'gouverneraient',
    'graillonnaient',
    'graillonnasses',
    'graillonnerais',
    'graillonnerait',
    'graillonnèrent',
    'graillonneriez',
    'graillonnerons',
    'graillonneront',
    'grammairiennes',
    'grammaticalise',
    'grammaticalisé',
    'grammaticalité',
    'grandiloquence',
    'grandiloquente',
    'grandiloquents',
    'grands-parents',
    'granulométries',
    'graphitassions',
    'graphiteraient',
    'graphologiques',
    'grappillassent',
    'grappillassiez',
    'grappillerions',
    'grasseyassions',
    'grasseyeraient',
    'grassouillette',
    'graticulassent',
    'graticulassiez',
    'graticulerions',
    'gratifiassions',
    'gratifications',
    'gratifieraient',
    'gravillonnages',
    'grelottassions',
    'grelotteraient',
    'grenaillassent',
    'grenaillassiez',
    'grenaillerions',
    'grenelleraient',
    'grenouillaient',
    'grenouillasses',
    'grenouillerais',
    'grenouillerait',
    'grenouillèrent',
    'grenouilleriez',
    'grenouillerons',
    'grenouilleront',
    'grenouillettes',
    'gribouillaient',
    'gribouillasses',
    'gribouillerais',
    'gribouillerait',
    'gribouillèrent',
    'gribouilleriez',
    'gribouillerons',
    'gribouilleront',
    'gribouilleuses',
    'griffonnassent',
    'griffonnassiez',
    'griffonnements',
    'griffonnerions',
    'grignotassions',
    'grignoteraient',
    'grillageassent',
    'grillageassiez',
    'grillageraient',
    'grisaillassent',
    'grisaillassiez',
    'grisaillerions',
    'grisollassions',
    'grisolleraient',
    'grisonnassions',
    'grisonneraient',
    'grivelleraient',
    'grognassassent',
    'grognassassiez',
    'grognasserions',
    'grommelassions',
    'grommellements',
    'grommellerions',
    'grossissements',
    'grossoieraient',
    'grossoyassions',
    'grouilleraient',
    'grumelleraient',
    'guerroieraient',
    'guerroyassions',
    'gueule-de-loup',
    'gueuletonnâmes',
    'gueuletonnasse',
    'gueuletonnâtes',
    'gueuletonnerai',
    'gueuletonneras',
    'gueuletonnerez',
    'gueuletonnions',
    'guillemetaient',
    'guillemetasses',
    'guillemetèrent',
    'guillemetterai',
    'guillemetteras',
    'guillemetterez',
    'guillochassent',
    'guillochassiez',
    'guillocherions',
    'guillotinaient',
    'guillotinasses',
    'guillotinerais',
    'guillotinerait',
    'guillotinèrent',
    'guillotineriez',
    'guillotinerons',
    'guillotineront',
    'guttas-perchas',
    'gymnosophistes',
    'gynécologiques',
    'habilitassions',
    'habiliteraient',
    'habituellement',
    'hagiographique',
    'hallstattienne',
    'hallucinassent',
    'hallucinassiez',
    'hallucinations',
    'hallucinatoire',
    'hallucinerions',
    'hallucinogènes',
    'haltérophilies',
    'hameçonnassent',
    'hameçonnassiez',
    'hameçonnerions',
    'handicapassent',
    'handicapassiez',
    'handicaperions',
    'hannetonnaient',
    'hannetonnasses',
    'hannetonnerais',
    'hannetonnerait',
    'hannetonnèrent',
    'hannetonneriez',
    'hannetonnerons',
    'hannetonneront',
    'haranguassions',
    'harangueraient',
    'harcelleraient',
    'harmoniquement',
    'harmonisassent',
    'harmonisassiez',
    'harmonisations',
    'harmoniserions',
    'harnachassions',
    'harnacheraient',
    'harpaillassent',
    'harpaillassiez',
    'harpaillerions',
    'harponnassions',
    'harponneraient',
    'haute-fidélité',
    'hauts-de-forme',
    'hébéphréniques',
    'hébergeassions',
    'hébraïsassions',
    'hébraïseraient',
    'hectométriques',
    'héliciculteurs',
    'hélicicultrice',
    'hélicicultures',
    'héliocentrique',
    'héliothérapies',
    'héliotropismes',
    'hellénisassent',
    'hellénisassiez',
    'hellénisations',
    'helléniserions',
    'hellénistiques',
    'hématologiques',
    'hématologistes',
    'hémisphériques',
    'hémodynamiques',
    'hémorroïdaires',
    'hendécasyllabe',
    'hépatomégalies',
    'herbageassions',
    'herborisassent',
    'herborisassiez',
    'herborisateurs',
    'herborisations',
    'herborisatrice',
    'herboriserions',
    'herboristeries',
    'hérédosyphilis',
    'hérissonnaient',
    'hérissonnasses',
    'hérissonnerais',
    'hérissonnerait',
    'hérissonnèrent',
    'hérissonneriez',
    'hérissonnerons',
    'hérissonneront',
    'hermaphrodisme',
    'hermaphrodites',
    'herméneutiques',
    'hermétiquement',
    'héroï-comiques',
    'hétérocyclique',
    'hétérogénéités',
    'hétérosexuelle',
    'heures-machine',
    'hidrosadénites',
    'hiérarchisâmes',
    'hiérarchisasse',
    'hiérarchisâtes',
    'hiérarchiserai',
    'hiérarchiseras',
    'hiérarchiserez',
    'hiérarchisions',
    'hiératiquement',
    'hiéroglyphique',
    'hiérogrammates',
    'hippocratiques',
    'hippocratismes',
    'hippophagiques',
    'hispano-arabes',
    'histoplasmoses',
    'historiassions',
    'historieraient',
    'historiographe',
    'historiquement',
    'hollywoodienne',
    'holophrastique',
    'homéopathiques',
    'homme-sandwich',
    'homocentriques',
    'homocinétiques',
    'homogénéifiais',
    'homogénéifiait',
    'homogénéifiant',
    'homogénéifiées',
    'homogénéifient',
    'homogénéifiera',
    'homogénéifiiez',
    'homogénéifions',
    'homogénéisâmes',
    'homogénéisasse',
    'homogénéisâtes',
    'homogénéiserai',
    'homogénéiseras',
    'homogénéiserez',
    'homogénéisions',
    'homologuassent',
    'homologuassiez',
    'homologuerions',
    'homosexualités',
    'hongroieraient',
    'hongroyassions',
    'hoquetteraient',
    'horizontalités',
    'horrifiassions',
    'horrifieraient',
    'horripilassent',
    'horripilassiez',
    'horripilations',
    'horripilerions',
    'horticultrices',
    'hortillonnages',
    'hospitalisâmes',
    'hospitalisasse',
    'hospitalisâtes',
    'hospitaliserai',
    'hospitaliseras',
    'hospitaliserez',
    'hospitalisions',
    'houblonnassent',
    'houblonnassiez',
    'houblonnerions',
    'houspillassent',
    'houspillassiez',
    'houspillerions',
    'humanisassions',
    'humaniseraient',
    'humanitarismes',
    'humanitaristes',
    'humidifiassent',
    'humidifiassiez',
    'humidificateur',
    'humidification',
    'humidifierions',
    'hydrargyrismes',
    'hydraulicienne',
    'hydrocarbonate',
    'hydrocarbonées',
    'hydrocéphalies',
    'hydrocortisone',
    'hydrofugeaient',
    'hydrofugeasses',
    'hydrofugerions',
    'hydrogénassent',
    'hydrogénassiez',
    'hydrogénations',
    'hydrogénerions',
    'hydrogéologies',
    'hydroglisseurs',
    'hydrographique',
    'hydrolysassent',
    'hydrolysassiez',
    'hydrolyserions',
    'hydromécanique',
    'hydrominérales',
    'hydronéphroses',
    'hydropéricarde',
    'hydrosilicates',
    'hydrostatiques',
    'hydrothérapies',
    'hygiéniquement',
    'hygrométriques',
    'hygroscopiques',
    'hyperboréennes',
    'hypercalcémies',
    'hypercorrectes',
    'hyperémotivité',
    'hyperesthésies',
    'hyperfréquence',
    'hyperglycémies',
    'hyperlipidémie',
    'hypermétropies',
    'hypernerveuses',
    'hyperréalismes',
    'hypersécrétion',
    'hypersensibles',
    'hyperstatiques',
    'hyperthyroïdie',
    'hypertrophiées',
    'hypertrophiiez',
    'hypertrophions',
    'hypertrophique',
    'hypnotisassent',
    'hypnotisassiez',
    'hypnotiserions',
    'hypocaloriques',
    'hypocondriaque',
    'hypocoristique',
    'hypogastriques',
    'hyposécrétions',
    'hypostasiaient',
    'hypostasiasses',
    'hypostasierais',
    'hypostasierait',
    'hypostasièrent',
    'hypostasieriez',
    'hypostasierons',
    'hypostasieront',
    'hyposulfureuse',
    'hypothéquaient',
    'hypothéquasses',
    'hypothéquerais',
    'hypothéquerait',
    'hypothéquèrent',
    'hypothéqueriez',
    'hypothéquerons',
    'hypothéqueront',
    'hypothyroïdies',
    'hystérectomies',
    'hystérographie',
    'ichtyologiques',
    'ichtyologistes',
    'iconographique',
    'idéalisassions',
    'idéalisatrices',
    'idéaliseraient',
    'identifiassent',
    'identifiassiez',
    'identification',
    'identifierions',
    'idéographiques',
    'idéologisaient',
    'idéologisasses',
    'idéologiserais',
    'idéologiserait',
    'idéologisèrent',
    'idéologiseriez',
    'idéologiserons',
    'idéologiseront',
    'idiosyncrasies',
    'idiotifiassent',
    'idiotifiassiez',
    'idiotifierions',
    'idiotisassions',
    'idiotiseraient',
    'idolâtrassions',
    'idolâtreraient',
    'ignifugeassent',
    'ignifugeassiez',
    'ignifugeraient',
    'illégitimement',
    'illuminassions',
    'illumineraient',
    'illusionnaient',
    'illusionnasses',
    'illusionnerais',
    'illusionnerait',
    'illusionnèrent',
    'illusionneriez',
    'illusionnerons',
    'illusionneront',
    'illusionnismes',
    'illusionnistes',
    'illustrassions',
    'illustratrices',
    'illustreraient',
    'imbriquassions',
    'imbriqueraient',
    'immarcescibles',
    'immatérialisai',
    'immatérialisas',
    'immatérialisât',
    'immatérialisée',
    'immatérialiser',
    'immatérialises',
    'immatérialisés',
    'immatérialisez',
    'immatérialisme',
    'immatérialiste',
    'immatérialités',
    'immatriculâmes',
    'immatriculasse',
    'immatriculâtes',
    'immatriculerai',
    'immatriculeras',
    'immatriculerez',
    'immatriculions',
    'immergeassions',
    'immobilisaient',
    'immobilisasses',
    'immobilisation',
    'immobiliserais',
    'immobiliserait',
    'immobilisèrent',
    'immobiliseriez',
    'immobiliserons',
    'immobiliseront',
    'immortalisâmes',
    'immortalisasse',
    'immortalisâtes',
    'immortaliserai',
    'immortaliseras',
    'immortaliserez',
    'immortalisions',
    'immunisassions',
    'immuniseraient',
    'immunothérapie',
    'immunotolérant',
    'impardonnables',
    'imparfaitement',
    'impartageables',
    'impartialement',
    'impartissaient',
    'impassibilités',
    'impassiblement',
    'impatientaient',
    'impatientantes',
    'impatientasses',
    'impatienterais',
    'impatienterait',
    'impatientèrent',
    'impatienteriez',
    'impatienterons',
    'impatienteront',
    'impatronisâmes',
    'impatronisasse',
    'impatronisâtes',
    'impatroniserai',
    'impatroniseras',
    'impatroniserez',
    'impatronisions',
    'impeccabilités',
    'impeccablement',
    'impécuniosités',
    'impérativement',
    'imperceptibles',
    'imperfectibles',
    'impérieusement',
    'imperméabilisa',
    'imperméabilise',
    'imperméabilisé',
    'imperméabilité',
    'impersonnalité',
    'impersonnelles',
    'impertinemment',
    'imperturbables',
    'impétigineuses',
    'impétueusement',
    'implacabilités',
    'implacablement',
    'implantassions',
    'implanteraient',
    'implémentaient',
    'implémentasses',
    'implémenterais',
    'implémenterait',
    'implémentèrent',
    'implémenteriez',
    'implémenterons',
    'implémenteront',
    'impliquassions',
    'impliqueraient',
    'importunassent',
    'importunassiez',
    'importunerions',
    'impossibilités',
    'imprégnassions',
    'imprégneraient',
    'impréparations',
    'impressionnais',
    'impressionnait',
    'impressionnant',
    'impressionnées',
    'impressionnent',
    'impressionnera',
    'impressionniez',
    'impressionnons',
    'improbabilités',
    'improductivité',
    'imprononçables',
    'improuvassions',
    'improuveraient',
    'improvisassent',
    'improvisassiez',
    'improvisateurs',
    'improvisations',
    'improvisatrice',
    'improviserions',
    'imputrescibles',
    'inacceptations',
    'inactivassions',
    'inactiveraient',
    'inaltérabilité',
    'inamovibilités',
    'inapplications',
    'inappréciables',
    'inapprivoisées',
    'inassimilables',
    'inaugurassions',
    'inaugureraient',
    'inauthenticité',
    'inauthentiques',
    'incandescences',
    'incandescentes',
    'incapacitantes',
    'incarcérassent',
    'incarcérassiez',
    'incarcérations',
    'incarcérerions',
    'incendiassions',
    'incendieraient',
    'incessibilités',
    'incinérassions',
    'incinéreraient',
    'incoercibilité',
    'incombustibles',
    'incommodassent',
    'incommodassiez',
    'incommoderions',
    'incommunicable',
    'incomplètement',
    'incompréhensif',
    'incompressible',
    'inconciliables',
    'inconditionnée',
    'inconditionnel',
    'inconditionnés',
    'inconfortables',
    'inconnaissable',
    'inconnaissance',
    'inconsciemment',
    'inconséquences',
    'inconséquentes',
    'inconsistances',
    'inconsistantes',
    'inconsommables',
    'inconstatables',
    'incontestables',
    'incontournable',
    'incontrôlables',
    'inconvertibles',
    'incoordination',
    'incorporassent',
    'incorporassiez',
    'incorporations',
    'incorporerions',
    'incorrectement',
    'incorruptibles',
    'incrémentaient',
    'incrémentasses',
    'incrémenterais',
    'incrémenterait',
    'incrémentèrent',
    'incrémenteriez',
    'incrémenterons',
    'incrémenteront',
    'incriminassent',
    'incriminassiez',
    'incriminations',
    'incriminerions',
    'incrochetables',
    'incroyablement',
    'incrustassions',
    'incrusteraient',
    'inculquassions',
    'inculqueraient',
    'indécachetable',
    'indéchiffrable',
    'indécomposable',
    'indécrochables',
    'indécrottables',
    'indéfinissable',
    'indéfrichables',
    'indélicatesses',
    'indémaillables',
    'indemnisassent',
    'indemnisassiez',
    'indemnisations',
    'indemniserions',
    'indémontrables',
    'indéniablement',
    'indénombrables',
    'indépendamment',
    'indéracinables',
    'indescriptible',
    'indestructible',
    'indéterminable',
    'indéterminisme',
    'indifférassent',
    'indifférassiez',
    'indifféremment',
    'indifférenciée',
    'indifférenciés',
    'indifférerions',
    'indiscernables',
    'indisciplinées',
    'indiscrètement',
    'indispensables',
    'indisposassent',
    'indisposassiez',
    'indisposerions',
    'indispositions',
    'indissociables',
    'indistinguable',
    'individualisai',
    'individualisas',
    'individualisât',
    'individualisée',
    'individualiser',
    'individualises',
    'individualisés',
    'individualisez',
    'individualisme',
    'individualiste',
    'individualités',
    'individuations',
    'indivisibilité',
    'indo-européens',
    'indulgenciâmes',
    'indulgenciasse',
    'indulgenciâtes',
    'indulgencierai',
    'indulgencieras',
    'indulgencierez',
    'indulgenciions',
    'industrialisai',
    'industrialisas',
    'industrialisât',
    'industrialisée',
    'industrialiser',
    'industrialises',
    'industrialisés',
    'industrialisez',
    'industrialisme',
    'inéchangeables',
    'inefficacement',
    'inéligibilités',
    'inescomptables',
    'inévitablement',
    'inexorablement',
    'inexpérimentée',
    'inexpérimentés',
    'inexploitables',
    'inextinguibles',
    'infaillibilité',
    'infalsifiables',
    'infantilisâmes',
    'infantilisasse',
    'infantilisâtes',
    'infantiliserai',
    'infantiliseras',
    'infantiliserez',
    'infantilisions',
    'infériorisâmes',
    'infériorisasse',
    'infériorisâtes',
    'inférioriserai',
    'inférioriseras',
    'inférioriserez',
    'infériorisions',
    'infiltrassions',
    'infiltreraient',
    'infinitésimale',
    'infinitésimaux',
    'inflammabilité',
    'inflammatoires',
    'inflationniste',
    'infléchiraient',
    'infléchissions',
    'inflexibilités',
    'inflexiblement',
    'infligeassions',
    'inflorescences',
    'influençassent',
    'influençassiez',
    'influencerions',
    'informaticiens',
    'informationnel',
    'informatisable',
    'informatisâmes',
    'informatisasse',
    'informatisâtes',
    'informatiserai',
    'informatiseras',
    'informatiserez',
    'informatisions',
    'infrastructure',
    'ingénieusement',
    'ingouvernables',
    'inguérissables',
    'ingurgitassent',
    'ingurgitassiez',
    'ingurgitations',
    'ingurgiterions',
    'inharmonieuses',
    'inhospitalière',
    'inhospitaliers',
    'ininflammables',
    'inintelligence',
    'inintelligente',
    'inintelligents',
    'inintelligible',
    'inintéressante',
    'inintéressants',
    'ininterrompues',
    'initialisaient',
    'initialisasses',
    'initialisation',
    'initialiserais',
    'initialiserait',
    'initialisèrent',
    'initialiseriez',
    'initialiserons',
    'initialiseront',
    'injurieusement',
    'injustifiables',
    'inlassablement',
    'innocentassent',
    'innocentassiez',
    'innocenterions',
    'inobservations',
    'inopportunités',
    'inopposabilité',
    'inorganisation',
    'inqualifiables',
    'inquiétassions',
    'inquiéteraient',
    'inquisitoriale',
    'inquisitoriaux',
    'insaisissables',
    'insatiabilités',
    'insatiablement',
    'insatisfaction',
    'insatisfaisant',
    'inscrivissions',
    'insculpassions',
    'insculperaient',
    'inséminassions',
    'insémineraient',
    'insensibilisai',
    'insensibilisas',
    'insensibilisât',
    'insensibilisée',
    'insensibiliser',
    'insensibilises',
    'insensibilisés',
    'insensibilisez',
    'insensibilités',
    'insensiblement',
    'insidieusement',
    'insignifiances',
    'insignifiantes',
    'insolubilisais',
    'insolubilisait',
    'insolubilisant',
    'insolubilisées',
    'insolubilisent',
    'insolubilisera',
    'insolubilisiez',
    'insolubilisons',
    'insolvabilités',
    'insonorisaient',
    'insonorisasses',
    'insonorisation',
    'insonoriserais',
    'insonoriserait',
    'insonorisèrent',
    'insonoriseriez',
    'insonoriserons',
    'insonoriseront',
    'insoupçonnable',
    'inspectassions',
    'inspecteraient',
    'installassions',
    'installatrices',
    'installeraient',
    'instantanéités',
    'instantanément',
    'instaurassions',
    'instaureraient',
    'instiguassions',
    'instigueraient',
    'instillassions',
    'instilleraient',
    'instinctuelles',
    'instituassions',
    'institueraient',
    'institutionnel',
    'instruisissent',
    'instruisissiez',
    'instrumentaire',
    'instrumentâmes',
    'instrumentasse',
    'instrumentâtes',
    'instrumenterai',
    'instrumenteras',
    'instrumenterez',
    'instrumentions',
    'instrumentiste',
    'insubmersibles',
    'insubordonnées',
    'insuffisamment',
    'insufflassions',
    'insuffleraient',
    'insupportables',
    'insurgeassions',
    'insurmontables',
    'insurpassables',
    'intaillassions',
    'intailleraient',
    'intangibilités',
    'intellectuelle',
    'intelligemment',
    'intelligentsia',
    'intemporalités',
    'intensifiaient',
    'intensifiasses',
    'intensifierais',
    'intensifierait',
    'intensifièrent',
    'intensifieriez',
    'intensifierons',
    'intensifieront',
    'intentionnelle',
    'interbancaires',
    'intercalassent',
    'intercalassiez',
    'intercalations',
    'intercalerions',
    'intercédassent',
    'intercédassiez',
    'intercéderions',
    'interceptaient',
    'interceptasses',
    'intercepterais',
    'intercepterait',
    'interceptèrent',
    'intercepteriez',
    'intercepterons',
    'intercepteront',
    'interclassâmes',
    'interclassasse',
    'interclassâtes',
    'interclasserai',
    'interclasseras',
    'interclasserez',
    'interclassions',
    'intercommunale',
    'intercommunaux',
    'interconnexion',
    'intercotidales',
    'intercurrentes',
    'interdépendant',
    'interdigitales',
    'intéressassent',
    'intéressassiez',
    'intéressements',
    'intéresserions',
    'interférassent',
    'interférassiez',
    'interférerions',
    'interféromètre',
    'interfoliaient',
    'interfoliasses',
    'interfolierais',
    'interfolierait',
    'interfolièrent',
    'interfolieriez',
    'interfolierons',
    'interfolieront',
    'interglaciaire',
    'intérieurement',
    'intériorisâmes',
    'intériorisasse',
    'intériorisâtes',
    'intérioriserai',
    'intérioriseras',
    'intérioriserez',
    'intériorisions',
    'interjetassent',
    'interjetassiez',
    'interjetterais',
    'interjetterait',
    'interjetteriez',
    'interjetterons',
    'interjetteront',
    'interlignaient',
    'interlignasses',
    'interlignerais',
    'interlignerait',
    'interlignèrent',
    'interligneriez',
    'interlignerons',
    'interligneront',
    'interlinéaires',
    'interlocuteurs',
    'interlocutoire',
    'interlocutrice',
    'interloquaient',
    'interloquasses',
    'interloquerais',
    'interloquerait',
    'interloquèrent',
    'interloqueriez',
    'interloquerons',
    'interloqueront',
    'intermédiaires',
    'intermittences',
    'intermittentes',
    'internationale',
    'internationaux',
    'interocéanique',
    'intéroceptives',
    'interoculaires',
    'interpariétale',
    'interpariétaux',
    'interpellaient',
    'interpellasses',
    'interpellateur',
    'interpellation',
    'interpellerais',
    'interpellerait',
    'interpellèrent',
    'interpelleriez',
    'interpellerons',
    'interpelleront',
    'interpénétrées',
    'interpénétriez',
    'interpénétrons',
    'interpersonnel',
    'interpolassent',
    'interpolassiez',
    'interpolateurs',
    'interpolations',
    'interpolatrice',
    'interpolerions',
    'interposassent',
    'interposassiez',
    'interposerions',
    'interpositions',
    'interprétaient',
    'interprétantes',
    'interprétariat',
    'interprétasses',
    'interprétatifs',
    'interprétation',
    'interprétative',
    'interpréterais',
    'interpréterait',
    'interprétèrent',
    'interpréteriez',
    'interpréterons',
    'interpréteront',
    'interrelations',
    'interrogateurs',
    'interrogations',
    'interrogatives',
    'interrogatoire',
    'interrogatrice',
    'interrogeaient',
    'interrogeasses',
    'interrogerions',
    'interrompaient',
    'interrompirent',
    'interrompisses',
    'interromprions',
    'interruptrices',
    'intersectoriel',
    'intersexualité',
    'intersexuelles',
    'intersidérales',
    'interstellaire',
    'intersyndicale',
    'intersyndicaux',
    'intertropicale',
    'intertropicaux',
    'intervallaires',
    'intervertébral',
    'intervertirais',
    'intervertirait',
    'intervertirent',
    'intervertiriez',
    'intervertirons',
    'intervertiront',
    'intervertisses',
    'intervertissez',
    'interviendrais',
    'interviendrait',
    'interviendriez',
    'interviendrons',
    'interviendront',
    'interviewaient',
    'interviewasses',
    'interviewerais',
    'interviewerait',
    'interviewèrent',
    'intervieweriez',
    'interviewerons',
    'intervieweront',
    'intervieweuses',
    'intervinssions',
    'intervocalique',
    'intimidassions',
    'intimidatrices',
    'intimideraient',
    'intitulassions',
    'intituleraient',
    'intoxiquassent',
    'intoxiquassiez',
    'intoxiquerions',
    'intra-atomique',
    'intracardiaque',
    'intracrânienne',
    'intradermiques',
    'intraduisibles',
    'intransigeance',
    'intransigeante',
    'intransigeants',
    'intransitivité',
    'intranucléaire',
    'intraveineuses',
    'intriguassions',
    'intrigueraient',
    'intriquassions',
    'intriqueraient',
    'introductrices',
    'introduiraient',
    'introduisaient',
    'introduisirent',
    'introduisisses',
    'intronisassent',
    'intronisassiez',
    'intronisations',
    'introniserions',
    'introspections',
    'introspectives',
    'intuitionnisme',
    'intuitionniste',
    'invaginassions',
    'invagineraient',
    'invalidassions',
    'invalideraient',
    'invariabilités',
    'invariablement',
    'invectivassent',
    'invectivassiez',
    'invectiverions',
    'inventoriaient',
    'inventoriasses',
    'inventorierais',
    'inventorierait',
    'inventorièrent',
    'inventorieriez',
    'inventorierons',
    'inventorieront',
    'invertissaient',
    'investigateurs',
    'investigations',
    'investigatrice',
    'investissaient',
    'investissement',
    'investisseuses',
    'invétérassions',
    'invincibilités',
    'invinciblement',
    'inviolabilités',
    'inviolablement',
    'ionosphériques',
    'irrationalisme',
    'irrationalités',
    'irrationnelles',
    'irrattrapables',
    'irrecevabilité',
    'irrécouvrables',
    'irrécupérables',
    'irréfutabilité',
    'irremplaçables',
    'irrépressibles',
    'irréprochables',
    'irrespectueuse',
    'irresponsables',
    'irrévérencieux',
    'islamisassions',
    'islamiseraient',
    'isochromatique',
    'isolationnisme',
    'isolationniste',
    'isomérisations',
    'italianisaient',
    'italianisantes',
    'italianisasses',
    'italianiserais',
    'italianiserait',
    'italianisèrent',
    'italianiseriez',
    'italianiserons',
    'italianiseront',
    'ithyphalliques',
    'jaillissements',
    'jargonaphasies',
    'jargonnassions',
    'jargonneraient',
    'javellisassent',
    'javellisassiez',
    'javellisations',
    'javelliserions',
    'jésuitiquement',
    'jointoieraient',
    'jointoyassions',
    'jouaillassions',
    'jouailleraient',
    'journalistique',
    'judiciairement',
    'judicieusement',
    'jupes-culottes',
    'juridictionnel',
    'jurisconsultes',
    'jurisprudences',
    'justifiassions',
    'justificateurs',
    'justifications',
    'justificatives',
    'justificatrice',
    'justifieraient',
    'juxtalinéaires',
    'juxtaposassent',
    'juxtaposassiez',
    'juxtaposerions',
    'juxtapositions',
    'kabbalistiques',
    'kaolinisations',
    'kératinisation',
    'kidnappassions',
    'kidnapperaient',
    'kilogrammètres',
    'kilométrassent',
    'kilométrassiez',
    'kilométrerions',
    'kilowattheures',
    'kinésithérapie',
    'kinesthésiques',
    'klaxonnassions',
    'klaxonneraient',
    'knickerbockers',
    'kremlinologies',
    'labialisassent',
    'labialisassiez',
    'labialisations',
    'labialiserions',
    'laborieusement',
    'labyrinthiques',
    'labyrinthodons',
    'lacédémonienne',
    'laissez-passer',
    'laitonnassions',
    'laitonneraient',
    'lambrissassent',
    'lambrissassiez',
    'lambrisserions',
    'lamentablement',
    'lance-grenades',
    'lance-missiles',
    'langueyassions',
    'langueyeraient',
    'languissamment',
    'lanternassions',
    'lanterneraient',
    'lapidification',
    'lardonnassions',
    'lardonneraient',
    'laryngectomies',
    'laryngologiste',
    'laryngoscopies',
    'latéralisation',
    'latifundiaires',
    'latinisassions',
    'latiniseraient',
    'latitudinaires',
    'lauriers-roses',
    'lave-vaisselle',
    'lèche-vitrines',
    'légalisassions',
    'légaliseraient',
    'légiférassions',
    'légiféreraient',
    'légitimassions',
    'légitimeraient',
    'lépidodendrons',
    'leptolithiques',
    'levrettassions',
    'levretteraient',
    'lexicalisaient',
    'lexicalisasses',
    'lexicalisation',
    'lexicaliserais',
    'lexicaliserait',
    'lexicalisèrent',
    'lexicaliseriez',
    'lexicaliserons',
    'lexicaliseront',
    'lexicographies',
    'lexicologiques',
    'liaisonnassent',
    'liaisonnassiez',
    'liaisonnerions',
    'libéralisaient',
    'libéralisantes',
    'libéralisasses',
    'libéralisation',
    'libéraliserais',
    'libéraliserait',
    'libéralisèrent',
    'libéraliseriez',
    'libéraliserons',
    'libéraliseront',
    'libre-échanges',
    'libre-penseuse',
    'licenciassions',
    'licencieraient',
    'ligaturassions',
    'ligatureraient',
    'lignifiassions',
    'lignifications',
    'lignifieraient',
    'lilliputiennes',
    'limitativement',
    'limousinassent',
    'limousinassiez',
    'limousinerions',
    'liquéfiassions',
    'liquéfieraient',
    'lithographiais',
    'lithographiait',
    'lithographiant',
    'lithographiées',
    'lithographient',
    'lithographiera',
    'lithographiiez',
    'lithographions',
    'lithographique',
    'lithothamniums',
    'litispendances',
    'littérairement',
    'localisassions',
    'localisatrices',
    'localiseraient',
    'location-vente',
    'lock-outassent',
    'lock-outassiez',
    'lock-outerions',
    'logisticiennes',
    'long-courriers',
    'longitudinales',
    'lotionnassions',
    'lotionneraient',
    'louangeassions',
    'loups-cerviers',
    'louvetteraient',
    'lubrifiassions',
    'lubrifications',
    'lubrifieraient',
    'lutéinisations',
    'lymphocytaires',
    'lymphographies',
    'lyophilisaient',
    'lyophilisantes',
    'lyophilisasses',
    'lyophilisation',
    'lyophiliserais',
    'lyophiliserait',
    'lyophilisèrent',
    'lyophiliseriez',
    'lyophiliserons',
    'lyophiliseront',
    'macadamisaient',
    'macadamisasses',
    'macadamiserais',
    'macadamiserait',
    'macadamisèrent',
    'macadamiseriez',
    'macadamiserons',
    'macadamiseront',
    'machiavéliques',
    'machiavélismes',
    'machicotassent',
    'machicotassiez',
    'machicoterions',
    'machinaissions',
    'mâchonnassions',
    'mâchonneraient',
    'mâchouillaient',
    'mâchouillantes',
    'mâchouillasses',
    'mâchouillerais',
    'mâchouillerait',
    'mâchouillèrent',
    'mâchouilleriez',
    'mâchouillerons',
    'mâchouilleront',
    'macrobiotiques',
    'macrocéphalies',
    'macrocosmiques',
    'macrodécisions',
    'macroscopiques',
    'macrosporanges',
    'madérisassions',
    'madériseraient',
    'madrigalisâmes',
    'madrigalisasse',
    'madrigalisâtes',
    'madrigaliserai',
    'madrigaliseras',
    'madrigaliserez',
    'madrigalisions',
    'magasinassions',
    'magasineraient',
    'magdaléniennes',
    'magistralement',
    'magnétisassent',
    'magnétisassiez',
    'magnétisations',
    'magnétiserions',
    'magnétométries',
    'magnétomoteurs',
    'magnétomotrice',
    'magnétoscopais',
    'magnétoscopait',
    'magnétoscopant',
    'magnétoscopées',
    'magnétoscopent',
    'magnétoscopera',
    'magnétoscopiez',
    'magnétoscopons',
    'magnétosphères',
    'magnifiassions',
    'magnifieraient',
    'magnifiquement',
    'magouillerions',
    'maintiendrions',
    'maîtrisassions',
    'maîtriseraient',
    'maladroitement',
    'malencontreuse',
    'malentendantes',
    'malhonnêtement',
    'malicieusement',
    'malintentionné',
    'malléabilisais',
    'malléabilisait',
    'malléabilisant',
    'malléabilisées',
    'malléabilisent',
    'malléabilisera',
    'malléabilisiez',
    'malléabilisons',
    'malthusianisme',
    'maltraitassent',
    'maltraitassiez',
    'maltraiterions',
    'mamelonnassent',
    'mamelonnassiez',
    'mamelonnerions',
    'manchonnassent',
    'manchonnassiez',
    'manchonnerions',
    'mandats-cartes',
    'mangeottassent',
    'mangeottassiez',
    'mangeotterions',
    'manifestassent',
    'manifestassiez',
    'manifestations',
    'manifesterions',
    'manigançassent',
    'manigançassiez',
    'manigancerions',
    'manipulassions',
    'manipulatrices',
    'manipuleraient',
    'mannequinaient',
    'mannequinasses',
    'mannequinerais',
    'mannequinerait',
    'mannequinèrent',
    'mannequineriez',
    'mannequinerons',
    'mannequineront',
    'manodétendeurs',
    'manoeuvrassent',
    'manoeuvrassiez',
    'manoeuvrerions',
    'mansardassions',
    'mansarderaient',
    'manucurassions',
    'manucureraient',
    'manufacturable',
    'manufacturâmes',
    'manufacturasse',
    'manufacturâtes',
    'manufacturerai',
    'manufactureras',
    'manufacturerez',
    'manufacturière',
    'manufacturiers',
    'manufacturions',
    'manutentionnai',
    'manutentionnas',
    'manutentionnât',
    'manutentionnée',
    'manutentionner',
    'manutentionnes',
    'manutentionnés',
    'manutentionnez',
    'maquereautâmes',
    'maquereautasse',
    'maquereautâtes',
    'maquereauterai',
    'maquereauteras',
    'maquereauterez',
    'maquereautions',
    'maquignonnages',
    'maquignonnâmes',
    'maquignonnasse',
    'maquignonnâtes',
    'maquignonnerai',
    'maquignonneras',
    'maquignonnerez',
    'maquignonnions',
    'maquillassions',
    'maquilleraient',
    'marathoniennes',
    'marchandassent',
    'marchandassiez',
    'marchanderions',
    'marcottassions',
    'marcotteraient',
    'marginalisâmes',
    'marginalisasse',
    'marginalisâtes',
    'marginaliserai',
    'marginaliseras',
    'marginaliserez',
    'marginalisions',
    'margottassions',
    'margotteraient',
    'marionnettiste',
    'marivaudassent',
    'marivaudassiez',
    'marivauderions',
    'marmonnassions',
    'marmonneraient',
    'marmorisassent',
    'marmorisassiez',
    'marmoriserions',
    'marmottassions',
    'marmotteraient',
    'maroquinassent',
    'maroquinassiez',
    'maroquinerions',
    'marouflassions',
    'maroufleraient',
    'marquetassions',
    'marquetterions',
    'marronnassions',
    'marronneraient',
    'marsouinassent',
    'marsouinassiez',
    'marsouinerions',
    'martin-pêcheur',
    'martyrisassent',
    'martyrisassiez',
    'martyriserions',
    'masculinisâmes',
    'masculinisasse',
    'masculinisâtes',
    'masculiniserai',
    'masculiniseras',
    'masculiniserez',
    'masculinisions',
    'massacrassions',
    'massacreraient',
    'massicotassent',
    'massicotassiez',
    'massicoterions',
    'massifiassions',
    'massifications',
    'massifieraient',
    'mastiquassions',
    'mastiqueraient',
    'masturbassions',
    'masturberaient',
    'matelassassent',
    'matelassassiez',
    'matelasserions',
    'matérialisâmes',
    'matérialisasse',
    'matérialisâtes',
    'matérialiserai',
    'matérialiseras',
    'matérialiserez',
    'matérialisions',
    'matériellement',
    'maternellement',
    'maternisassent',
    'maternisassiez',
    'materniserions',
    'mathématiciens',
    'mathématisâmes',
    'mathématisasse',
    'mathématisâtes',
    'mathématiserai',
    'mathématiseras',
    'mathématiserez',
    'mathématisions',
    'matraquassions',
    'matraqueraient',
    'matriculassent',
    'matriculassiez',
    'matriculerions',
    'matrilinéaires',
    'maximalisaient',
    'maximalisasses',
    'maximalisation',
    'maximaliserais',
    'maximaliserait',
    'maximalisèrent',
    'maximaliseriez',
    'maximaliserons',
    'maximaliseront',
    'maximisassions',
    'maximiseraient',
    'mécanisassions',
    'mécaniseraient',
    'mécanographies',
    'mécanothérapie',
    'mécomptassions',
    'mécompteraient',
    'méconnaissable',
    'méconnaissance',
    'méconnaissions',
    'méconnaîtrions',
    'mécontentaient',
    'mécontentasses',
    'mécontentement',
    'mécontenterais',
    'mécontenterait',
    'mécontentèrent',
    'mécontenteriez',
    'mécontenterons',
    'mécontenteront',
    'médaillassions',
    'médailleraient',
    'médecine-balls',
    'médiatisassent',
    'médiatisassiez',
    'médiatisations',
    'médiatiserions',
    'médicalisaient',
    'médicalisantes',
    'médicalisasses',
    'médicalisation',
    'médicaliserais',
    'médicaliserait',
    'médicalisèrent',
    'médicaliseriez',
    'médicaliserons',
    'médicaliseront',
    'médicamentâmes',
    'médicamentasse',
    'médicamentâtes',
    'médicamenterai',
    'médicamenteras',
    'médicamenterez',
    'médicamenteuse',
    'médicamentions',
    'medicine-balls',
    'médico-légales',
    'médico-sociale',
    'médico-sociaux',
    'méditerranéens',
    'mélangeassions',
    'mellifications',
    'mélodieusement',
    'mélodramatique',
    'mémorisassions',
    'mémoriseraient',
    'mendigotassent',
    'mendigotassiez',
    'mendigoterions',
    'mensongèrement',
    'mensualisaient',
    'mensualisasses',
    'mensualisation',
    'mensualiserais',
    'mensualiserait',
    'mensualisèrent',
    'mensualiseriez',
    'mensualiserons',
    'mensualiseront',
    'mentionnassent',
    'mentionnassiez',
    'mentionnerions',
    'mercantilisais',
    'mercantilisait',
    'mercantilisant',
    'mercantilisées',
    'mercantilisent',
    'mercantilisera',
    'mercantilisiez',
    'mercantilismes',
    'mercantilisons',
    'mercantilistes',
    'mercaticiennes',
    'mercerisassent',
    'mercerisassiez',
    'merceriserions',
    'merchandisings',
    'mercurochromes',
    'meringuassions',
    'meringueraient',
    'mérovingiennes',
    'mésalliassions',
    'mésallieraient',
    'mesdemoiselles',
    'mésestimassent',
    'mésestimassiez',
    'mésestimations',
    'mésestimerions',
    'métallisassent',
    'métallisassiez',
    'métallisations',
    'métalliserions',
    'métallochromie',
    'métallographie',
    'métallurgiques',
    'métallurgistes',
    'métamorphiques',
    'métamorphisais',
    'métamorphisait',
    'métamorphisant',
    'métamorphisées',
    'métamorphisent',
    'métamorphisera',
    'métamorphisiez',
    'métamorphismes',
    'métamorphisons',
    'métamorphosais',
    'métamorphosait',
    'métamorphosant',
    'métamorphosées',
    'métamorphosent',
    'métamorphosera',
    'métamorphosiez',
    'métamorphosons',
    'métaphysiciens',
    'métapsychiques',
    'métatarsiennes',
    'métempsychoses',
    'météorisassent',
    'météorisassiez',
    'météoriserions',
    'météorologique',
    'météorologiste',
    'méthacryliques',
    'méthémoglobine',
    'méthodiquement',
    'méthodologique',
    'métropolitains',
    'meurtrissaient',
    'meurtrissantes',
    'mezzo-sopranos',
    'microbiologies',
    'microchirurgie',
    'microcosmiques',
    'microéconomies',
    'microfilmaient',
    'microfilmasses',
    'microfilmerais',
    'microfilmerait',
    'microfilmèrent',
    'microfilmeriez',
    'microfilmerons',
    'microfilmeront',
    'microglossaire',
    'micrographique',
    'microlithiques',
    'micrométriques',
    'microphoniques',
    'microscopiques',
    'mignardassions',
    'mignarderaient',
    'militarisaient',
    'militarisasses',
    'militarisation',
    'militariserais',
    'militariserait',
    'militarisèrent',
    'militariseriez',
    'militariserons',
    'militariseront',
    'mille-feuilles',
    'millésimassent',
    'millésimassiez',
    'millésimerions',
    'millimétriques',
    'minéralisaient',
    'minéralisantes',
    'minéralisasses',
    'minéralisateur',
    'minéralisation',
    'minéraliserais',
    'minéraliserait',
    'minéralisèrent',
    'minéraliseriez',
    'minéraliserons',
    'minéraliseront',
    'minéralogiques',
    'minéralogistes',
    'miniaturassent',
    'miniaturassiez',
    'miniaturerions',
    'miniaturisâmes',
    'miniaturisasse',
    'miniaturisâtes',
    'miniaturiserai',
    'miniaturiseras',
    'miniaturiserez',
    'miniaturisions',
    'Mini-cassettes',
    'minimisassions',
    'minimiseraient',
    'ministérielles',
    'minutieusement',
    'misanthropique',
    'misérabilismes',
    'misérabilistes',
    'miséricordieux',
    'mithridataient',
    'mithridatasses',
    'mithridaterais',
    'mithridaterait',
    'mithridatèrent',
    'mithridateriez',
    'mithridaterons',
    'mithridateront',
    'mithridatisais',
    'mithridatisait',
    'mithridatisant',
    'mithridatisées',
    'mithridatisent',
    'mithridatisera',
    'mithridatisiez',
    'mithridatismes',
    'mithridatisons',
    'mitraillassent',
    'mitraillassiez',
    'mitraillerions',
    'mixtionnassent',
    'mixtionnassiez',
    'mixtionnerions',
    'mnémotechnique',
    'mobilisassions',
    'mobilisatrices',
    'mobiliseraient',
    'modéliseraient',
    'modernisassent',
    'modernisassiez',
    'modernisations',
    'moderniserions',
    'modificatrices',
    'moissonnassent',
    'moissonnassiez',
    'moissonnerions',
    'mollardassions',
    'mollarderaient',
    'molletonnaient',
    'molletonnasses',
    'molletonnerais',
    'molletonnerait',
    'molletonnèrent',
    'molletonneriez',
    'molletonnerons',
    'molletonneront',
    'molletonneuses',
    'mondialisaient',
    'mondialisasses',
    'mondialisation',
    'mondialiserais',
    'mondialiserait',
    'mondialisèrent',
    'mondialiseriez',
    'mondialiserons',
    'mondialiseront',
    'monétisassions',
    'monétiseraient',
    'monocellulaire',
    'monocotylédone',
    'monographiques',
    'monolinguismes',
    'monologuassent',
    'monologuassiez',
    'monologuerions',
    'monométallisme',
    'monométalliste',
    'mononucléaires',
    'monopolisaient',
    'monopolisantes',
    'monopolisasses',
    'monopolisateur',
    'monopolisation',
    'monopoliserais',
    'monopoliserait',
    'monopolisèrent',
    'monopoliseriez',
    'monopoliserons',
    'monopoliseront',
    'monopolistique',
    'monosaccharide',
    'monosyllabique',
    'monosyllabisme',
    'monts-de-piété',
    'monumentalités',
    'moquettassions',
    'moquetteraient',
    'moralisassions',
    'moralisatrices',
    'moraliseraient',
    'morcelleraient',
    'mordançassions',
    'mordanceraient',
    'mordillassions',
    'mordilleraient',
    'morfondissions',
    'morigénassions',
    'morigéneraient',
    'morphinomanies',
    'morphologiques',
    'mortaisassions',
    'mortaiseraient',
    'mortes-saisons',
    'mortifiassions',
    'mortifications',
    'mortifieraient',
    'mortinatalités',
    'motionnassions',
    'motionneraient',
    'motopropulseur',
    'motorisassions',
    'motoriseraient',
    'mots-croisiste',
    'mouchardassent',
    'mouchardassiez',
    'moucharderions',
    'moucheronnâmes',
    'moucheronnasse',
    'moucheronnâtes',
    'moucheronnerai',
    'moucheronneras',
    'moucheronnerez',
    'moucheronnions',
    'mouchetassions',
    'mouchetterions',
    'mouronnassions',
    'mouronneraient',
    'moutonnassions',
    'moutonneraient',
    'mouvementaient',
    'mouvementasses',
    'mouvementerais',
    'mouvementerait',
    'mouvementèrent',
    'mouvementeriez',
    'mouvementerons',
    'mouvementeront',
    'moyen-courrier',
    'mucilagineuses',
    'muguetteraient',
    'multilatérales',
    'multinationale',
    'multinationaux',
    'multiplexaient',
    'multiplexasses',
    'multiplexerais',
    'multiplexerait',
    'multiplexèrent',
    'multiplexeriez',
    'multiplexerons',
    'multiplexeront',
    'multipliassent',
    'multipliassiez',
    'multiplicandes',
    'multiplicateur',
    'multiplication',
    'multiplierions',
    'multipropriété',
    'multistandards',
    'multitubulaire',
    'municipalisais',
    'municipalisait',
    'municipalisant',
    'municipalisées',
    'municipalisent',
    'municipalisera',
    'municipalisiez',
    'municipalisons',
    'munitionnaient',
    'munitionnaires',
    'munitionnasses',
    'munitionnerais',
    'munitionnerait',
    'munitionnèrent',
    'munitionneriez',
    'munitionnerons',
    'munitionneront',
    'muraillassions',
    'murailleraient',
    'musicographies',
    'mutationnismes',
    'mutationnistes',
    'mystifiassions',
    'mystificateurs',
    'mystifications',
    'mystificatrice',
    'mystifieraient',
    'mytiliculteurs',
    'mytilicultrice',
    'mytilicultures',
    'myxoedémateuse',
    'napoléoniennes',
    'narcothérapies',
    'nasalisassions',
    'nasaliseraient',
    'nationalisâmes',
    'nationalisasse',
    'nationalisâtes',
    'nationaliserai',
    'nationaliseras',
    'nationaliserez',
    'nationalisions',
    'naturalisaient',
    'naturalisasses',
    'naturalisation',
    'naturaliserais',
    'naturaliserait',
    'naturalisèrent',
    'naturaliseriez',
    'naturaliserons',
    'naturaliseront',
    'naufrageassent',
    'naufrageassiez',
    'naufrageraient',
    'navires-écoles',
    'néantisassions',
    'néantiseraient',
    'nécessairement',
    'nécessitassent',
    'nécessitassiez',
    'nécessiterions',
    'nec-plus-ultra',
    'nécromancienne',
    'négligeassions',
    'négociabilités',
    'négro-africain',
    'néo-criticisme',
    'néo-darwinisme',
    'néo-hébridaise',
    'néo-platonisme',
    'néo-zélandaise',
    'néphrographies',
    'neurasthénique',
    'neurobiologies',
    'neurochirurgie',
    'neuroleptiques',
    'neuroplégiques',
    'neutralisaient',
    'neutralisantes',
    'neutralisasses',
    'neutralisation',
    'neutraliserais',
    'neutraliserait',
    'neutralisèrent',
    'neutraliseriez',
    'neutraliserons',
    'neutraliseront',
    'neutrographies',
    'nickelleraient',
    'nicotinisaient',
    'nicotinisasses',
    'nicotiniserais',
    'nicotiniserait',
    'nicotinisèrent',
    'nicotiniseriez',
    'nicotiniserons',
    'nicotiniseront',
    'nietzschéennes',
    'nitrifiassions',
    'nitrifications',
    'nitrifieraient',
    'nitrocellulose',
    'nitroglycérine',
    'nivo-glaciaire',
    'nivo-pluviales',
    'noctambulismes',
    'noircissements',
    'nomadisassions',
    'nomadiseraient',
    'nomenclatrices',
    'nominalisaient',
    'nominalisasses',
    'nominaliserais',
    'nominaliserait',
    'nominalisèrent',
    'nominaliseriez',
    'nominaliserons',
    'nominaliseront',
    'nominativement',
    'non-agressions',
    'non-alignement',
    'non-assistance',
    'non-combattant',
    'non-comparante',
    'non-comparants',
    'non-conformité',
    'non-directives',
    'non-engagement',
    'non-euclidiens',
    'non-exécutions',
    'non-existences',
    'non-figuratifs',
    'non-figurative',
    'non-ingérences',
    'non-résidentes',
    'non-révisables',
    'noradrénalines',
    'nord-africaine',
    'nord-africains',
    'nord-américain',
    'nord-coréennes',
    'normalisassent',
    'normalisassiez',
    'normalisations',
    'normaliserions',
    'nucléoprotéine',
    'numérisassions',
    'numériseraient',
    'numérotassions',
    'numéroteraient',
    'nutritionnelle',
    'nutritionniste',
    'obédientielles',
    'objectivassent',
    'objectivassiez',
    'objectivations',
    'objectiverions',
    'objurguassions',
    'objurgueraient',
    'oblitérassions',
    'oblitératrices',
    'oblitéreraient',
    'obnubilassions',
    'obnubileraient',
    'obscurantismes',
    'obscurantistes',
    'obscurciraient',
    'obscurcissions',
    'obsessionnelle',
    'obstétricienne',
    'obtempérassent',
    'obtempérassiez',
    'obtempérerions',
    'occasionnaient',
    'occasionnasses',
    'occasionnelles',
    'occasionnerais',
    'occasionnerait',
    'occasionnèrent',
    'occasionneriez',
    'occasionnerons',
    'occasionneront',
    'occidentalisai',
    'occidentalisas',
    'occidentalisât',
    'occidentalisée',
    'occidentaliser',
    'occidentalises',
    'occidentalisés',
    'occidentalisez',
    'océanographies',
    'océanologiques',
    'octosyllabique',
    'oeilletonnages',
    'oeilletonnâmes',
    'oeilletonnasse',
    'oeilletonnâtes',
    'oeilletonnerai',
    'oeilletonneras',
    'oeilletonnerez',
    'oeilletonnions',
    'oeils-de-boeuf',
    'oesophagiennes',
    'oesophagoscope',
    'officialisâmes',
    'officialisasse',
    'officialisâtes',
    'officialiserai',
    'officialiseras',
    'officialiserez',
    'officialisions',
    'officiellement',
    'officieusement',
    'offusquassions',
    'offusqueraient',
    'oligo-éléments',
    'ombrageassions',
    'omnipraticiens',
    'oniromancienne',
    'onomasiologies',
    'onomatopéiques',
    'opacifiassions',
    'opacifications',
    'opacifieraient',
    'opérationnelle',
    'ophtalmologies',
    'ophtalmologues',
    'ophtalmomètres',
    'ophtalmoscopes',
    'ophtalmoscopie',
    'opiniâtrassiez',
    'opiniâtrerions',
    'oppositionnels',
    'oppressassions',
    'oppresseraient',
    'optimalisaient',
    'optimalisasses',
    'optimaliserais',
    'optimaliserait',
    'optimalisèrent',
    'optimaliseriez',
    'optimaliserons',
    'optimaliseront',
    'optimisassions',
    'optimiseraient',
    'orangs-outangs',
    'orchestrassent',
    'orchestrassiez',
    'orchestrateurs',
    'orchestrations',
    'orchestratrice',
    'orchestrerions',
    'ordonnançaient',
    'ordonnançasses',
    'ordonnancement',
    'ordonnancerais',
    'ordonnancerait',
    'ordonnancèrent',
    'ordonnanceriez',
    'ordonnancerons',
    'ordonnanceront',
    'organisassions',
    'organisatrices',
    'organiseraient',
    'organothérapie',
    'organsinassent',
    'organsinassiez',
    'originairement',
    'originellement',
    'ornementassent',
    'ornementassiez',
    'ornementations',
    'ornementerions',
    'ornithologique',
    'ornithologiste',
    'ornithomancies',
    'ornithorynques',
    'orthographiais',
    'orthographiait',
    'orthographiant',
    'orthographiées',
    'orthographient',
    'orthographiera',
    'orthographiiez',
    'orthographions',
    'orthographique',
    'orthophonistes',
    'orthorhombique',
    'orthoscopiques',
    'orthostatiques',
    'oscillogrammes',
    'oscillographes',
    'osiéricultures',
    'ostensiblement',
    'ostéosynthèses',
    'ostracisassent',
    'ostracisassiez',
    'ostraciserions',
    'ostréiculteurs',
    'ostréicultrice',
    'ostréicultures',
    'ouest-allemand',
    'outrageassions',
    'outrageusement',
    'outrecuidances',
    'outrecuidantes',
    'outrepassaient',
    'outrepassasses',
    'outrepasserais',
    'outrepasserait',
    'outrepassèrent',
    'outrepasseriez',
    'outrepasserons',
    'outrepasseront',
    'ouvrageassions',
    'ovationnassent',
    'ovationnassiez',
    'ovationnerions',
    'ovoviviparités',
    'oxydoréduction',
    'oxyhémoglobine',
    'pacificatrices',
    'paganisassions',
    'paganiseraient',
    'paillardassent',
    'paillardassiez',
    'paillarderions',
    'paillassonnage',
    'paillassonnais',
    'paillassonnait',
    'paillassonnant',
    'paillassonnées',
    'paillassonnent',
    'paillassonnera',
    'paillassonniez',
    'paillassonnons',
    'pailletassions',
    'pailletterions',
    'paillonnassent',
    'paillonnassiez',
    'paillonnerions',
    'paisselassions',
    'paissellerions',
    'palabrerassent',
    'palabrerassiez',
    'palancrassions',
    'palancreraient',
    'palangrassions',
    'palangreraient',
    'palanguassions',
    'palangueraient',
    'palanquassions',
    'palanqueraient',
    'palatalisation',
    'paléobotanique',
    'paléochrétiens',
    'paléographique',
    'paléolithiques',
    'paléontologies',
    'paléontologues',
    'palestiniennes',
    'palettisassent',
    'palettisassiez',
    'palettiserions',
    'palingénésique',
    'palissadassent',
    'palissadassiez',
    'palissaderions',
    'palissonnaient',
    'palissonnasses',
    'palissonnerais',
    'palissonnerait',
    'palissonnèrent',
    'palissonneriez',
    'palissonnerons',
    'palissonneront',
    'pallidectomies',
    'pamplemoussier',
    'panafricanisme',
    'panaméricaines',
    'panchromatique',
    'pandiculations',
    'pangermanismes',
    'pangermanistes',
    'panhelléniques',
    'panhellénismes',
    'panneautassent',
    'panneautassiez',
    'panneauterions',
    'panoramiquâmes',
    'panoramiquasse',
    'panoramiquâtes',
    'panoramiquerai',
    'panoramiqueras',
    'panoramiquerez',
    'panoramiquions',
    'pantagruélique',
    'pantelleraient',
    'pantouflassent',
    'pantouflassiez',
    'pantouflerions',
    'papier-monnaie',
    'papillonnaient',
    'papillonnantes',
    'papillonnasses',
    'papillonnement',
    'papillonnerais',
    'papillonnerait',
    'papillonnèrent',
    'papillonneriez',
    'papillonnerons',
    'papillonneront',
    'papillotassent',
    'papillotassiez',
    'papillotements',
    'papilloterions',
    'papouillassent',
    'papouillassiez',
    'papouillerions',
    'parachevassent',
    'parachevassiez',
    'parachèvements',
    'parachèverions',
    'parachronismes',
    'parachutassent',
    'parachutassiez',
    'parachuterions',
    'paradigmatique',
    'paradoxalement',
    'paraffinassent',
    'paraffinassiez',
    'paraffinerions',
    'parafiscalités',
    'paraisonnaient',
    'paraisonnasses',
    'paraisonnerais',
    'paraisonnerait',
    'paraisonnèrent',
    'paraisonneriez',
    'paraisonnerons',
    'paraisonneront',
    'paralittéraire',
    'parallactiques',
    'parallélisâmes',
    'parallélisasse',
    'parallélisâtes',
    'paralléliserai',
    'paralléliseras',
    'paralléliserez',
    'parallélisions',
    'paralysassions',
    'paralyseraient',
    'paramagnétisme',
    'paramétrassent',
    'paramétrassiez',
    'paramétrerions',
    'paramilitaires',
    'parangonnaient',
    'parangonnasses',
    'parangonnerais',
    'parangonnerait',
    'parangonnèrent',
    'parangonneriez',
    'parangonnerons',
    'parangonneront',
    'paraphrasaient',
    'paraphrasasses',
    'paraphraserais',
    'paraphraserait',
    'paraphrasèrent',
    'paraphraseriez',
    'paraphraserons',
    'paraphraseront',
    'paraphraseuses',
    'paraphrastique',
    'parapsychiques',
    'parasexualités',
    'parasitassions',
    'parasiteraient',
    'parcellarisais',
    'parcellarisait',
    'parcellarisant',
    'parcellarisées',
    'parcellarisent',
    'parcellarisera',
    'parcellarisiez',
    'parcellarisons',
    'parcellassions',
    'parcelleraient',
    'parcellisaient',
    'parcellisasses',
    'parcellisation',
    'parcelliserais',
    'parcelliserait',
    'parcellisèrent',
    'parcelliseriez',
    'parcelliserons',
    'parcelliseront',
    'parcheminaient',
    'parcheminasses',
    'parcheminerais',
    'parcheminerait',
    'parcheminèrent',
    'parchemineriez',
    'parcheminerons',
    'parchemineront',
    'parcheminières',
    'parcimonieuses',
    'parcourussions',
    'pardonnassions',
    'pardonneraient',
    'parementassent',
    'parementassiez',
    'parementerions',
    'parenchymateux',
    'paresseusement',
    'parfondissions',
    'paridigitidées',
    'parisyllabique',
    'parkérisassent',
    'parkérisassiez',
    'parkérisations',
    'parkériserions',
    'parkinsonienne',
    'parlementaient',
    'parlementaires',
    'parlementasses',
    'parlementerais',
    'parlementerait',
    'parlementèrent',
    'parlementeriez',
    'parlementerons',
    'parlementeront',
    'parquetassions',
    'parquetterions',
    'parrainassions',
    'parraineraient',
    'partageassions',
    'participassent',
    'participassiez',
    'participations',
    'participatives',
    'participerions',
    'particularisai',
    'particularisas',
    'particularisât',
    'particularisée',
    'particulariser',
    'particularises',
    'particularisés',
    'particularisez',
    'particularisme',
    'particulariste',
    'particularités',
    'partitionnâmes',
    'partitionnasse',
    'partitionnâtes',
    'partitionnerai',
    'partitionneras',
    'partitionnerez',
    'partitionnions',
    'parviendraient',
    'passe-crassane',
    'passementaient',
    'passementasses',
    'passementerais',
    'passementerait',
    'passementèrent',
    'passementeries',
    'passementeriez',
    'passementerons',
    'passementeront',
    'passementières',
    'passe-montagne',
    'passepoilaient',
    'passepoilasses',
    'passepoilerais',
    'passepoilerait',
    'passepoilèrent',
    'passepoileriez',
    'passepoilerons',
    'passepoileront',
    'passionnassent',
    'passionnassiez',
    'passionnerions',
    'pastellassions',
    'pastelleraient',
    'pasteurisaient',
    'pasteurisasses',
    'pasteurisation',
    'pasteuriserais',
    'pasteuriserait',
    'pasteurisèrent',
    'pasteuriseriez',
    'pasteuriserons',
    'pasteuriseront',
    'pastichassions',
    'pasticheraient',
    'pastillassions',
    'pastilleraient',
    'patafiolassent',
    'patafiolassiez',
    'patafiolerions',
    'pataugeaissent',
    'pataugeaissiez',
    'pataugeassions',
    'patelinassions',
    'patelineraient',
    'paternellement',
    'pathétiquement',
    'patientassions',
    'patienteraient',
    'patouillassent',
    'patouillassiez',
    'patouillerions',
    'patrilinéaires',
    'patronnassions',
    'patronneraient',
    'patrouillaient',
    'patrouillasses',
    'patrouillerais',
    'patrouillerait',
    'patrouillèrent',
    'patrouilleriez',
    'patrouillerons',
    'patrouilleront',
    'paupérisassent',
    'paupérisassiez',
    'paupérisations',
    'paupériserions',
    'pavillonnaires',
    'pavillonneries',
    'peaufinassions',
    'peaufineraient',
    'pécuniairement',
    'peinturassions',
    'peintureraient',
    'peinturlurâmes',
    'peinturlurasse',
    'peinturlurâtes',
    'peinturlurerai',
    'peinturlureras',
    'peinturlurerez',
    'peinturlurions',
    'péjorativement',
    'pelles-pioches',
    'pelletteraient',
    'pelotonnassent',
    'pelotonnassiez',
    'pelotonnements',
    'pelotonnerions',
    'pénalisassions',
    'pénaliseraient',
    'pendillassions',
    'pendilleraient',
    'pendouillaient',
    'pendouillasses',
    'pendouillerais',
    'pendouillerait',
    'pendouillèrent',
    'pendouilleriez',
    'pendouillerons',
    'pendouilleront',
    'pénétrabilités',
    'pénitentiaires',
    'pénitentielles',
    'pensionnassent',
    'pensionnassiez',
    'pensionnerions',
    'pentadécagones',
    'pentédécagones',
    'perce-muraille',
    'perce-oreilles',
    'perceptibilité',
    'pérégrinassent',
    'pérégrinassiez',
    'pérégrinations',
    'pérégrinerions',
    'pérennisassent',
    'pérennisassiez',
    'pérennisations',
    'pérenniserions',
    'perfectibilité',
    'perfectionnais',
    'perfectionnait',
    'perfectionnant',
    'perfectionnées',
    'perfectionnent',
    'perfectionnera',
    'perfectionniez',
    'perfectionnons',
    'périclitassent',
    'périclitassiez',
    'péricliterions',
    'périglaciaires',
    'périlleusement',
    'périodiquement',
    'péripatéticien',
    'péripatétismes',
    'périphrasaient',
    'périphrasasses',
    'périphraserais',
    'périphraserait',
    'périphrasèrent',
    'périphraseriez',
    'périphraserons',
    'périphraseront',
    'périphrastique',
    'péristaltiques',
    'péristaltismes',
    'permanentaient',
    'permanentasses',
    'permanenterais',
    'permanenterait',
    'permanentèrent',
    'permanenteriez',
    'permanenterons',
    'permanenteront',
    'permanganiques',
    'perméabilisais',
    'perméabilisait',
    'perméabilisant',
    'perméabilisées',
    'perméabilisent',
    'perméabilisera',
    'perméabilisiez',
    'perméabilisons',
    'permutabilités',
    'peroxydassions',
    'péroxydassions',
    'peroxyderaient',
    'péroxyderaient',
    'perpétrassions',
    'perpétreraient',
    'perpétuassions',
    'perpétueraient',
    'perquisitionna',
    'perquisitionne',
    'perquisitionné',
    'persécutassent',
    'persécutassiez',
    'persécuterions',
    'persévérassent',
    'persévérassiez',
    'persévérations',
    'persévérerions',
    'persiflassions',
    'persifleraient',
    'persillassions',
    'persilleraient',
    'persistassions',
    'persisteraient',
    'personnalisais',
    'personnalisait',
    'personnalisant',
    'personnalisées',
    'personnalisent',
    'personnalisera',
    'personnalisiez',
    'personnalismes',
    'personnalisons',
    'personnalistes',
    'personnifiâmes',
    'personnifiasse',
    'personnifiâtes',
    'personnifierai',
    'personnifieras',
    'personnifierez',
    'personnifiions',
    'perspectivisme',
    'persuadassions',
    'persuaderaient',
    'perturbassions',
    'perturbatrices',
    'perturberaient',
    'pervertiraient',
    'pervertisseurs',
    'pervertisseuse',
    'pervertissions',
    'pervibrassions',
    'pervibreraient',
    'pèse-personnes',
    'pestiférassent',
    'pestiférassiez',
    'pestiférerions',
    'pestilentielle',
    'pétaradassions',
    'pétaraderaient',
    'petit-déjeuner',
    'petites-filles',
    'petites-nièces',
    'pétitionnaient',
    'pétitionnaires',
    'pétitionnasses',
    'pétitionnerais',
    'pétitionnerait',
    'pétitionnèrent',
    'pétitionneriez',
    'pétitionnerons',
    'pétitionneront',
    'petits-enfants',
    'petits-maîtres',
    'petits-suisses',
    'pétouillassent',
    'pétouillassiez',
    'pétouillerions',
    'pétrarquisâmes',
    'pétrarquisante',
    'pétrarquisants',
    'pétrarquisasse',
    'pétrarquisâtes',
    'pétrarquiserai',
    'pétrarquiseras',
    'pétrarquiserez',
    'pétrarquisions',
    'pétrifiassions',
    'pétrifications',
    'pétrifieraient',
    'pétrochimiques',
    'pétrochimistes',
    'pétrographique',
    'pétrolochimies',
    'phagocytassent',
    'phagocytassiez',
    'phagocyterions',
    'phalanstériens',
    'pharmaceutique',
    'pharmacognosie',
    'pharmacologies',
    'pharmacomanies',
    'phénakisticope',
    'phénobarbitals',
    'phénoménologie',
    'phénoménologue',
    'philanthropies',
    'philharmonique',
    'philistinismes',
    'philosophaient',
    'philosophasses',
    'philosopherais',
    'philosopherait',
    'philosophèrent',
    'philosopheriez',
    'philosopherons',
    'philosopheront',
    'philosophiques',
    'philosophismes',
    'phonéticiennes',
    'phonétiquement',
    'phosphatassent',
    'phosphatassiez',
    'phosphaterions',
    'phospholipides',
    'phosphorassent',
    'phosphorassiez',
    'phosphorerions',
    'phosphorescent',
    'photochimiques',
    'photocopiaient',
    'photocopiasses',
    'photocopierais',
    'photocopierait',
    'photocopièrent',
    'photocopieriez',
    'photocopierons',
    'photocopieront',
    'photocopieuses',
    'photographiais',
    'photographiait',
    'photographiant',
    'photographiées',
    'photographient',
    'photographiera',
    'photographiiez',
    'photographions',
    'photographique',
    'photograveuses',
    'photomécanique',
    'photosensibles',
    'photosynthèses',
    'photothérapies',
    'phototropismes',
    'phraséologique',
    'phrénologiques',
    'phylloxérienne',
    'phylloxériques',
    'phylogénétique',
    'physiognomonie',
    'physiologiques',
    'physiologistes',
    'physionomiques',
    'physionomistes',
    'physiothérapie',
    'physisorptions',
    'phytosanitaire',
    'pictographique',
    'pied-de-cheval',
    'pied-de-chèvre',
    'pied-de-mouton',
    'pieds-de-biche',
    'pieds-de-poule',
    'pigeonnassions',
    'pigeonneraient',
    'pigmentassions',
    'pigmenteraient',
    'pignochassions',
    'pignocheraient',
    'pinaillassions',
    'pinailleraient',
    'pinces-mailles',
    'pindarisassent',
    'pindarisassiez',
    'pindariserions',
    'pique-assiette',
    'pique-niquâmes',
    'pique-niquasse',
    'pique-niquâtes',
    'pique-niquerai',
    'pique-niqueras',
    'pique-niquerez',
    'pique-niqueurs',
    'pique-niqueuse',
    'pique-niquions',
    'piquetteraient',
    'pirouettassent',
    'pirouettassiez',
    'pirouetterions',
    'piscicultrices',
    'pisse-vinaigre',
    'pistachassions',
    'pistacheraient',
    'pistonnassions',
    'pistonneraient',
    'pithécanthrope',
    'placardassions',
    'placarderaient',
    'plafonnassions',
    'plafonneraient',
    'plaisantassent',
    'plaisantassiez',
    'plaisanterions',
    'planchéiairent',
    'planchéiaisses',
    'planchéiassent',
    'planchéiassiez',
    'planchéierions',
    'planétairement',
    'planifiassions',
    'planificateurs',
    'planifications',
    'planificatrice',
    'planifieraient',
    'planimétriques',
    'plasmifiassent',
    'plasmifiassiez',
    'plasmifierions',
    'plastifiassent',
    'plastifiassiez',
    'plastifierions',
    'plastiquassent',
    'plastiquassiez',
    'plastiquerions',
    'plastronnaient',
    'plastronnasses',
    'plastronnerais',
    'plastronnerait',
    'plastronnèrent',
    'plastronneriez',
    'plastronnerons',
    'plastronneront',
    'platinisassent',
    'platinisassiez',
    'platiniserions',
    'platoniciennes',
    'platoniquement',
    'plébiscitaient',
    'plébiscitaires',
    'plébiscitasses',
    'plébisciterais',
    'plébisciterait',
    'plébiscitèrent',
    'plébisciteriez',
    'plébisciterons',
    'plébisciteront',
    'pleurnichaient',
    'pleurnichasses',
    'pleurnichement',
    'pleurnicherais',
    'pleurnicherait',
    'pleurnichèrent',
    'pleurnicheries',
    'pleurnicheriez',
    'pleurnicherons',
    'pleurnicheront',
    'pleurnicheuses',
    'pleurobranches',
    'ploutocratique',
    'pluriannuelles',
    'plurilatérales',
    'plurinationale',
    'plurinationaux',
    'pluripartismes',
    'pluviométrique',
    'pneumatologies',
    'pneumatophores',
    'pneumoconioses',
    'pneumographies',
    'poecilothermes',
    'poignardassent',
    'poignardassiez',
    'poignarderions',
    'poïkilothermes',
    'poinçonnassent',
    'poinçonnassiez',
    'poinçonnements',
    'poinçonnerions',
    'pointillassent',
    'pointillassiez',
    'pointillerions',
    'poireautassent',
    'poireautassiez',
    'poireauterions',
    'polarisassions',
    'polariseraient',
    'polémiquassent',
    'polémiquassiez',
    'polémiquerions',
    'poliorcétiques',
    'polissonnaient',
    'polissonnasses',
    'polissonnerais',
    'polissonnerait',
    'polissonnèrent',
    'polissonneries',
    'polissonneriez',
    'polissonnerons',
    'polissonneront',
    'politiquassent',
    'politiquassiez',
    'politiquerions',
    'politisassions',
    'politiseraient',
    'polycentriques',
    'polycentrismes',
    'polycondensats',
    'polycopiassent',
    'polycopiassiez',
    'polycopierions',
    'polyembryonies',
    'polymérisables',
    'polymérisaient',
    'polymérisasses',
    'polymérisation',
    'polymériserais',
    'polymériserait',
    'polymérisèrent',
    'polymériseriez',
    'polymériserons',
    'polymériseront',
    'polymorphismes',
    'polynucléaires',
    'polysaccharide',
    'polysyllabique',
    'polytechnicien',
    'polytechniques',
    'polytraumatisé',
    'pommelleraient',
    'pomponnassions',
    'pomponneraient',
    'ponctionnaient',
    'ponctionnasses',
    'ponctionnerais',
    'ponctionnerait',
    'ponctionnèrent',
    'ponctionneriez',
    'ponctionnerons',
    'ponctionneront',
    'ponctuellement',
    'pontifiassions',
    'pontifieraient',
    'pontillassions',
    'pontilleraient',
    'pont-promenade',
    'popularisaient',
    'popularisasses',
    'populariserais',
    'populariserait',
    'popularisèrent',
    'populariseriez',
    'populariserons',
    'populariseront',
    'porcelainières',
    'pornographique',
    'porphyrisaient',
    'porphyrisasses',
    'porphyriserais',
    'porphyriserait',
    'porphyrisèrent',
    'porphyriseriez',
    'porphyriserons',
    'porphyriseront',
    'porphyrogénète',
    'porte-affiches',
    'porte-aiguille',
    'porte-bannière',
    'porte-bouquets',
    'porte-chapeaux',
    'porte-couteaux',
    'porte-drapeaux',
    'porte-enseigne',
    'porte-étendard',
    'portrait-robot',
    'portraiturâmes',
    'portraiturasse',
    'portraiturâtes',
    'portraiturerai',
    'portraitureras',
    'portraiturerât',
    'portraiturions',
    'positionnaient',
    'positionnasses',
    'positionnement',
    'positionnerais',
    'positionnerait',
    'positionnèrent',
    'positionneriez',
    'positionnerons',
    'positionneront',
    'possessionnels',
    'postclassiques',
    'postcombustion',
    'postcommunions',
    'postdatassions',
    'postdateraient',
    'postglaciaires',
    'posthypophyses',
    'postichassions',
    'posticheraient',
    'postillonnâmes',
    'postillonnasse',
    'postillonnâtes',
    'postillonnerai',
    'postillonneras',
    'postillonnerez',
    'postillonnions',
    'postopératoire',
    'postposassions',
    'postposeraient',
    'postromantique',
    'potentialisais',
    'potentialisait',
    'potentialisant',
    'potentialisées',
    'potentialisent',
    'potentialisera',
    'potentialisiez',
    'potentialisons',
    'potentiomètres',
    'poudroieraient',
    'poudroyassions',
    'poults-de-soie',
    'pouponnassions',
    'pouponneraient',
    'pourchassaient',
    'pourchassasses',
    'pourchasserais',
    'pourchasserait',
    'pourchassèrent',
    'pourchasseriez',
    'pourchasserons',
    'pourchasseront',
    'pourfendissent',
    'pourfendissiez',
    'pourfendraient',
    'pourléchassent',
    'pourléchassiez',
    'pourlécherions',
    'pourrissements',
    'poursuivissent',
    'poursuivissiez',
    'poursuivraient',
    'praticabilités',
    'pratiquassions',
    'pratiqueraient',
    'préachetassent',
    'préachetassiez',
    'préachèterions',
    'préavisassions',
    'préaviseraient',
    'précambriennes',
    'précarisassent',
    'précarisassiez',
    'précariserions',
    'précautionnais',
    'précautionnait',
    'précautionnant',
    'précautionnées',
    'précautionnent',
    'précautionnera',
    'précautionneux',
    'précautionniez',
    'précautionnons',
    'préchauffaient',
    'préchauffasses',
    'préchaufferais',
    'préchaufferait',
    'préchauffèrent',
    'préchaufferiez',
    'préchaufferons',
    'préchaufferont',
    'précipitamment',
    'précipitassent',
    'précipitassiez',
    'précipitations',
    'précipiterions',
    'précolombienne',
    'précombustions',
    'précomptassent',
    'précomptassiez',
    'précompterions',
    'préconceptions',
    'préconisassent',
    'préconisassiez',
    'préconisateurs',
    'préconisations',
    'préconiserions',
    'précontraintes',
    'prédéfinirions',
    'prédéfinissais',
    'prédéfinissait',
    'prédéfinissant',
    'prédéfinissent',
    'prédéfinissiez',
    'prédéfinissons',
    'prédestinaient',
    'prédestinasses',
    'prédestination',
    'prédestinerais',
    'prédestinerait',
    'prédestinèrent',
    'prédestineriez',
    'prédestinerons',
    'prédestineront',
    'prédéterminais',
    'prédéterminait',
    'prédéterminant',
    'prédéterminées',
    'prédéterminent',
    'prédéterminera',
    'prédéterminiez',
    'prédéterminons',
    'prédiquassions',
    'prédiqueraient',
    'prédisposaient',
    'prédisposasses',
    'prédisposerais',
    'prédisposerait',
    'prédisposèrent',
    'prédisposeriez',
    'prédisposerons',
    'prédisposeront',
    'prédisposition',
    'prédominassent',
    'prédominassiez',
    'prédominerions',
    'préélectorales',
    'préemptassions',
    'préempteraient',
    'préétablirions',
    'préétablissais',
    'préétablissait',
    'préétablissant',
    'préétablissent',
    'préétablissiez',
    'préétablissons',
    'préexistassent',
    'préexistassiez',
    'préexisterions',
    'préfabrication',
    'préférablement',
    'préférentielle',
    'préfigurassent',
    'préfigurassiez',
    'préfigurations',
    'préfigurerions',
    'préfinancement',
    'préformassions',
    'préformeraient',
    'préhelléniques',
    'préhistorienne',
    'préhistoriques',
    'préjudiciables',
    'préjudiciaient',
    'préjudiciasses',
    'préjudicielles',
    'préjudicierais',
    'préjudicierait',
    'préjudicièrent',
    'préjudicieriez',
    'préjudicierons',
    'préjudicieront',
    'préjugeassions',
    'prélassassions',
    'prélasseraient',
    'prémédications',
    'préméditassent',
    'préméditassiez',
    'préméditations',
    'préméditerions',
    'prémenstruelle',
    'premières-nées',
    'prémunissaient',
    'prénommassions',
    'prénommeraient',
    'préoccupassent',
    'préoccupassiez',
    'préoccupations',
    'préoccuperions',
    'préopératoires',
    'prépondérances',
    'prépondérantes',
    'préprogrammées',
    'prépsychotique',
    'préraphaélites',
    'préréglassions',
    'prérégleraient',
    'préromantiques',
    'préromantismes',
    'présageassions',
    'présanctifiées',
    'presbytérienne',
    'prescriptibles',
    'prescriptrices',
    'prescrivissent',
    'prescrivissiez',
    'présentassions',
    'présentatrices',
    'présenteraient',
    'préservassions',
    'préservatrices',
    'préserveraient',
    'présidentiable',
    'présidentielle',
    'présomptueuses',
    'pressentiments',
    'pressentirions',
    'pressentissent',
    'pressentissiez',
    'presse-papiers',
    'pressurassions',
    'pressureraient',
    'pressurisaient',
    'pressurisasses',
    'pressurisation',
    'pressuriserais',
    'pressuriserait',
    'pressurisèrent',
    'pressuriseriez',
    'pressuriserons',
    'pressuriseront',
    'présupposaient',
    'présupposasses',
    'présupposerais',
    'présupposerait',
    'présupposèrent',
    'présupposeriez',
    'présupposerons',
    'présupposeront',
    'présupposition',
    'prétendissions',
    'prétextassions',
    'prétexteraient',
    'prêts-à-porter',
    'prévaricateurs',
    'prévarications',
    'prévaricatrice',
    'prévariquaient',
    'prévariquasses',
    'prévariquerais',
    'prévariquerait',
    'prévariquèrent',
    'prévariqueriez',
    'prévariquerons',
    'prévariqueront',
    'préventivement',
    'préviendraient',
    'prévisibilités',
    'prévisionnelle',
    'primarisassent',
    'primarisassiez',
    'primariserions',
    'primesautières',
    'primogénitures',
    'principalement',
    'printanisation',
    'privatisassent',
    'privatisassiez',
    'privatisations',
    'privatiserions',
    'privilégiaient',
    'privilégiasses',
    'privilégierais',
    'privilégierait',
    'privilégièrent',
    'privilégieriez',
    'privilégierons',
    'privilégieront',
    'problématiques',
    'processionnais',
    'processionnait',
    'processionnant',
    'processionnels',
    'processionnent',
    'processionnera',
    'processionniez',
    'processionnons',
    'procès-verbaux',
    'proclamassions',
    'proclameraient',
    'proconsulaires',
    'procurerassent',
    'procurerassiez',
    'prodiguassions',
    'prodigueraient',
    'produisissions',
    'professassions',
    'professeraient',
    'professionnels',
    'profitablement',
    'programmassent',
    'programmassiez',
    'programmateurs',
    'programmations',
    'programmatrice',
    'programmerions',
    'progressassent',
    'progressassiez',
    'progresserions',
    'progressivités',
    'projetteraient',
    'prolétariennes',
    'prolétarisâmes',
    'prolétarisasse',
    'prolétarisâtes',
    'prolétariserai',
    'prolétariseras',
    'prolétariserez',
    'prolétarisions',
    'proliférassent',
    'proliférassiez',
    'proliférations',
    'proliférerions',
    'prolongeassent',
    'prolongeassiez',
    'prolongeraient',
    'promotionnelle',
    'promulguassent',
    'promulguassiez',
    'promulguerions',
    'prononçassions',
    'prononceraient',
    'prononciations',
    'pronostiquâmes',
    'pronostiquasse',
    'pronostiquâtes',
    'pronostiquerai',
    'pronostiqueras',
    'pronostiquerez',
    'pronostiqueurs',
    'pronostiqueuse',
    'pronostiquions',
    'propagandistes',
    'propageassions',
    'propédeutiques',
    'propharmaciens',
    'prophétisaient',
    'prophétisasses',
    'prophétiserais',
    'prophétiserait',
    'prophétisèrent',
    'prophétiseriez',
    'prophétiserons',
    'prophétiseront',
    'prophylactique',
    'propitiatoires',
    'proportionnais',
    'proportionnait',
    'proportionnant',
    'proportionnées',
    'proportionnels',
    'proportionnent',
    'proportionnera',
    'proportionniez',
    'proportionnons',
    'propositionnel',
    'propres-à-rien',
    'proprioceptifs',
    'proprioceptive',
    'propulsassions',
    'propulseraient',
    'prorogeassions',
    'proscrivissent',
    'proscrivissiez',
    'prosodiassions',
    'prosodieraient',
    'prospectassent',
    'prospectassiez',
    'prospecterions',
    'prospérassions',
    'prospéreraient',
    'prostaglandine',
    'prostatectomie',
    'prosternassent',
    'prosternassiez',
    'prosternations',
    'prosternements',
    'prosternerions',
    'prostituassent',
    'prostituassiez',
    'prostituerions',
    'protégeassions',
    'protège-cahier',
    'protège-tibias',
    'protéolytiques',
    'protestantisme',
    'protestassions',
    'protestataires',
    'protesteraient',
    'protohistoires',
    'proudhoniennes',
    'proverbialisai',
    'proverbialisas',
    'proverbialisât',
    'proverbialisée',
    'proverbialiser',
    'proverbialises',
    'proverbialisés',
    'proverbialisez',
    'providentielle',
    'proviendraient',
    'provignassions',
    'provigneraient',
    'provincialisme',
    'provisionnelle',
    'provisoirement',
    'provoquassions',
    'provoqueraient',
    'prudhommesques',
    'psalmodiassent',
    'psalmodiassiez',
    'psalmodierions',
    'pseudarthroses',
    'pseudomembrane',
    'psychanalysais',
    'psychanalysait',
    'psychanalysant',
    'psychanalysées',
    'psychanalysent',
    'psychanalysera',
    'psychanalysiez',
    'psychanalysons',
    'psychanalystes',
    'psychédéliques',
    'psychiatriques',
    'psychiatrisais',
    'psychiatrisait',
    'psychiatrisant',
    'psychiatrisées',
    'psychiatrisent',
    'psychiatrisera',
    'psychiatrisiez',
    'psychiatrisons',
    'psychobiologie',
    'psychocritique',
    'psycholeptique',
    'psychologiques',
    'psychologismes',
    'psychométrique',
    'psychomotrices',
    'psychonévroses',
    'psychophysique',
    'psychorigidité',
    'psychosociales',
    'psychothérapie',
    'puéricultrices',
    'pulsoréacteurs',
    'pulvérisassent',
    'pulvérisassiez',
    'pulvérisateurs',
    'pulvérisations',
    'pulvériserions',
    'punching-balls',
    'purificatoires',
    'purificatrices',
    'pusillanimités',
    'putassassières',
    'putréfiassions',
    'putréfieraient',
    'putrescibilité',
    'pyramidassions',
    'pyramideraient',
    'pyrogravassent',
    'pyrogravassiez',
    'pyrograverions',
    'pyrotechniques',
    'pythagoriciens',
    'quadragénaires',
    'quadragésimale',
    'quadragésimaux',
    'quadrangulaire',
    'quadrichromies',
    'quadrillassent',
    'quadrillassiez',
    'quadrillerions',
    'quadripartites',
    'quadriréacteur',
    'quadrisyllabes',
    'quadruplassent',
    'quadruplassiez',
    'quadruplerions',
    'qualifiassions',
    'qualifications',
    'qualificatives',
    'qualifieraient',
    'quantifiassent',
    'quantifiassiez',
    'quantificateur',
    'quantification',
    'quantifierions',
    'quarante-et-un',
    'quarante-trois',
    'quarderonnâmes',
    'quarderonnasse',
    'quarderonnâtes',
    'quarderonnerai',
    'quarderonneras',
    'quarderonnerez',
    'quarderonnions',
    'quartageassent',
    'quartageassiez',
    'quartageraient',
    'quatre-saisons',
    'quémandassions',
    'quémanderaient',
    'querellassions',
    'querelleraient',
    'questionnaient',
    'questionnaires',
    'questionnasses',
    'questionnement',
    'questionnerais',
    'questionnerait',
    'questionnèrent',
    'questionneriez',
    'questionnerons',
    'questionneront',
    'questionneuses',
    'queue-de-morue',
    'quincailleries',
    'quincaillières',
    'quinquagénaire',
    'quinquagésimes',
    'quintefeuilles',
    'quintessenciai',
    'quintessencias',
    'quintessenciât',
    'quintessenciée',
    'quintessencier',
    'quintessencies',
    'quintessenciés',
    'quintessenciez',
    'quintuplassent',
    'quintuplassiez',
    'quintuplerions',
    'quittançassent',
    'quittançassiez',
    'quittancerions',
    'quotidiennetés',
    'rabaissassions',
    'rabaisseraient',
    'rabelaisiennes',
    'rabibochassent',
    'rabibochassiez',
    'rabibocherions',
    'rabonnissaient',
    'rabougriraient',
    'rabougrissions',
    'raccommodables',
    'raccommodaient',
    'raccommodasses',
    'raccommodement',
    'raccommoderais',
    'raccommoderait',
    'raccommodèrent',
    'raccommoderiez',
    'raccommoderons',
    'raccommoderont',
    'raccommodeuses',
    'raccompagnâmes',
    'raccompagnasse',
    'raccompagnâtes',
    'raccompagnerai',
    'raccompagneras',
    'raccompagnerez',
    'raccompagnions',
    'raccordassions',
    'raccorderaient',
    'raccourcirions',
    'raccourcissais',
    'raccourcissait',
    'raccourcissant',
    'raccourcissent',
    'raccourcissiez',
    'raccourcissons',
    'raccoutrassent',
    'raccoutrassiez',
    'raccoutrerions',
    'raccoutumaient',
    'raccoutumasses',
    'raccoutumerais',
    'raccoutumerait',
    'raccoutumèrent',
    'raccoutumeriez',
    'raccoutumerons',
    'raccoutumeront',
    'raccrochassent',
    'raccrochassiez',
    'raccrocherions',
    'racornissaient',
    'racornissement',
    'radicalisaient',
    'radicalisasses',
    'radicalisation',
    'radicaliserais',
    'radicaliserait',
    'radicalisèrent',
    'radicaliseriez',
    'radicaliserons',
    'radicaliseront',
    'radiesthésiste',
    'radioactivités',
    'radioaltimètre',
    'radioamatrices',
    'radioastronome',
    'radiobalisages',
    'radiobalisâmes',
    'radiobalisasse',
    'radiobalisâtes',
    'radiobaliserai',
    'radiobaliseras',
    'radiobaliserez',
    'radiobalisions',
    'radiocassettes',
    'radiodiffusais',
    'radiodiffusait',
    'radiodiffusant',
    'radiodiffusées',
    'radiodiffusent',
    'radiodiffusera',
    'radiodiffusiez',
    'radiodiffusion',
    'radiodiffusons',
    'radiographiais',
    'radiographiait',
    'radiographiant',
    'radiographiées',
    'radiographient',
    'radiographiera',
    'radiographiiez',
    'radiographions',
    'radioguidaient',
    'radioguidasses',
    'radioguiderais',
    'radioguiderait',
    'radioguidèrent',
    'radioguideriez',
    'radioguiderons',
    'radioguideront',
    'radionavigants',
    'radiophoniques',
    'radioreportage',
    'radioreporters',
    'radioscopaient',
    'radioscopasses',
    'radioscoperais',
    'radioscoperait',
    'radioscopèrent',
    'radioscoperiez',
    'radioscoperons',
    'radioscoperont',
    'radiotechnique',
    'radiotéléphone',
    'radiotélescope',
    'radiotélévisée',
    'radiotélévisés',
    'radiothérapies',
    'radoucissaient',
    'radoucissement',
    'raffermiraient',
    'raffermissions',
    'rafistolassent',
    'rafistolassiez',
    'rafistolerions',
    'rafraîchirions',
    'rafraîchissais',
    'rafraîchissait',
    'rafraîchissant',
    'rafraîchissent',
    'rafraîchissiez',
    'rafraîchissons',
    'ragaillardîmes',
    'ragaillardirai',
    'ragaillardiras',
    'ragaillardirez',
    'ragaillardisse',
    'ragaillardîtes',
    'rahat-loukoums',
    'rainetteraient',
    'raisonnassions',
    'raisonneraient',
    'rajeunissaient',
    'rajeunissantes',
    'rajeunissement',
    'ralentissaient',
    'ralentissement',
    'ralinguassions',
    'ralingueraient',
    'rallégeassions',
    'rallongeassent',
    'rallongeassiez',
    'rallongeraient',
    'ramaillassions',
    'ramailleraient',
    'ramastiquaient',
    'ramastiquasses',
    'ramastiquèrent',
    'ramatisquerais',
    'ramatisquerait',
    'ramatisqueriez',
    'ramatisquerons',
    'ramatisqueront',
    'ramollissaient',
    'ramollissantes',
    'ramollissement',
    'rancardassions',
    'rancarderaient',
    'rançonnassions',
    'rançonneraient',
    'randomisassent',
    'randomisassiez',
    'randomisations',
    'randomiserions',
    'randonnassions',
    'randonneraient',
    'rapapillotâmes',
    'rapapillotasse',
    'rapapillotâtes',
    'rapapilloterai',
    'rapapilloteras',
    'rapapilloterez',
    'rapapillotions',
    'rapatriassions',
    'rapatrieraient',
    'rapetassassent',
    'rapetassassiez',
    'rapetasserions',
    'rapetissassent',
    'rapetissassiez',
    'rapetissements',
    'rapetisserions',
    'rapiécetassent',
    'rapiécetassiez',
    'rapiécéterions',
    'raplatissaient',
    'rapointiraient',
    'rapointissions',
    'rappareillâmes',
    'rappareillasse',
    'rappareillâtes',
    'rappareillerai',
    'rappareilleras',
    'rappareillerez',
    'rappareillions',
    'rappariassions',
    'rapparieraient',
    'rappelleraient',
    'rappliquassent',
    'rappliquassiez',
    'rappliquerions',
    'rapportassions',
    'rapporteraient',
    'rapprendraient',
    'rapprêtassions',
    'rapprêteraient',
    'rapprochassent',
    'rapprochassiez',
    'rapprochements',
    'rapprocherions',
    'rappropriaient',
    'rappropriasses',
    'rapproprierais',
    'rapproprierait',
    'rapproprièrent',
    'rapproprieriez',
    'rapproprierons',
    'rapproprieront',
    'rapprovisionna',
    'rapprovisionne',
    'rapprovisionné',
    'rassasiassions',
    'rassasieraient',
    'rassemblassent',
    'rassemblassiez',
    'rassemblements',
    'rassemblerions',
    'rassérénassent',
    'rassérénassiez',
    'rassérénerions',
    'rassortiraient',
    'rassortissions',
    'ratatinassions',
    'ratatineraient',
    'ratiboisassent',
    'ratiboisassiez',
    'ratiboiserions',
    'ratiocinassent',
    'ratiocinassiez',
    'ratiocinations',
    'ratiocinerions',
    'rationalisâmes',
    'rationalisasse',
    'rationalisâtes',
    'rationaliserai',
    'rationaliseras',
    'rationaliserez',
    'rationalisions',
    'rationnassions',
    'rationneraient',
    'rattachassions',
    'rattacheraient',
    'rattrapassions',
    'rattraperaient',
    'raugmentassent',
    'raugmentassiez',
    'raugmenterions',
    'ravigotassions',
    'ravigoteraient',
    'ravitaillaient',
    'ravitaillasses',
    'ravitaillement',
    'ravitaillerais',
    'ravitaillerait',
    'ravitaillèrent',
    'ravitailleriez',
    'ravitaillerons',
    'ravitailleront',
    'raviverassions',
    'razzierassions',
    'réabonnassions',
    'réabonnerasses',
    'réabonnerèrent',
    'réabsorbassent',
    'réabsorbassiez',
    'réabsorberions',
    'réaccoutumâmes',
    'réaccoutumasse',
    'réaccoutumâtes',
    'réaccoutumerai',
    'réaccoutumeras',
    'réaccoutumerez',
    'réaccoutumions',
    'réactionnaires',
    'réactionnelles',
    'réactivassions',
    'réactiveraient',
    'réactualisâmes',
    'réactualisasse',
    'réactualisâtes',
    'réactualiserai',
    'réactualiseras',
    'réactualiserez',
    'réactualisions',
    'réadaptassions',
    'réadapteraient',
    'réadmettraient',
    'réaffectassent',
    'réaffectassiez',
    'réaffectations',
    'réaffecterions',
    'réaffirmassent',
    'réaffirmassiez',
    'réaffirmerions',
    'réaffûtassions',
    'réaffûteraient',
    'réajustassions',
    'réajusteraient',
    'réalignassions',
    'réaligneraient',
    'réaménageaient',
    'réaménageasses',
    'réaménagerions',
    'réamorçassions',
    'réamorceraient',
    'réapparaissais',
    'réapparaissait',
    'réapparaissant',
    'réapparaissent',
    'réapparaissiez',
    'réapparaissons',
    'réapparaîtrais',
    'réapparaîtrait',
    'réapparaîtriez',
    'réapparaîtrons',
    'réapparaîtront',
    'réapparussions',
    'réapprendrions',
    'réargentassent',
    'réargentassiez',
    'réargenterions',
    'réarrangeaient',
    'réarrangeasses',
    'réarrangerions',
    'réassignassent',
    'réassignassiez',
    'réassignerions',
    'réassortiments',
    'réassortirions',
    'réassortissais',
    'réassortissait',
    'réassortissant',
    'réassortissent',
    'réassortissiez',
    'réassortissons',
    'réassurassions',
    'réassureraient',
    'rebaissassions',
    'rebaisseraient',
    'rebaptisassent',
    'rebaptisassiez',
    'rebaptiserions',
    'reblanchirions',
    'reblanchissais',
    'reblanchissait',
    'reblanchissant',
    'reblanchissent',
    'reblanchissiez',
    'reblanchissons',
    'rebondissaient',
    'rebondissantes',
    'rebondissement',
    'rebouchassions',
    'reboucheraient',
    'reboutonnaient',
    'reboutonnasses',
    'reboutonnerais',
    'reboutonnerait',
    'reboutonnèrent',
    'reboutonneriez',
    'reboutonnerons',
    'reboutonneront',
    'rebroussassent',
    'rebroussassiez',
    'rebroussements',
    'rebrousse-poil',
    'rebrousserions',
    'recachetassent',
    'recachetassiez',
    'recachetterais',
    'recachetterait',
    'recachetteriez',
    'recachetterons',
    'recachetteront',
    'recalcifiaient',
    'recalcifiasses',
    'recalcifierais',
    'recalcifierait',
    'recalcifièrent',
    'recalcifieriez',
    'recalcifierons',
    'recalcifieront',
    'récalcitrantes',
    'recalculassent',
    'recalculassiez',
    'recalculerions',
    'récapitulaient',
    'récapitulasses',
    'récapitulatifs',
    'récapitulation',
    'récapitulative',
    'récapitulerais',
    'récapitulerait',
    'récapitulèrent',
    'récapituleriez',
    'récapitulerons',
    'récapituleront',
    'recarrelassent',
    'recarrelassiez',
    'recarrellerais',
    'recarrellerait',
    'recarrelleriez',
    'recarrellerons',
    'recarrelleront',
    'recentrassions',
    'recentreraient',
    'réceptionnaire',
    'réceptionnâmes',
    'réceptionnasse',
    'réceptionnâtes',
    'réceptionnerai',
    'réceptionneras',
    'réceptionnerez',
    'réceptionnions',
    'réceptionniste',
    'recerclassions',
    'recercleraient',
    'rechampiraient',
    'réchampiraient',
    'rechampissages',
    'réchampissages',
    'rechampissions',
    'réchampissions',
    'rechangeassent',
    'rechangeassiez',
    'rechangeraient',
    'rechantassions',
    'rechanteraient',
    'réchappassions',
    'réchapperaient',
    'rechargeassent',
    'rechargeassiez',
    'rechargeraient',
    'rechassassions',
    'rechasseraient',
    'réchauffassent',
    'réchauffassiez',
    'réchauffements',
    'réchaufferions',
    'rechaussassent',
    'rechaussassiez',
    'rechaussements',
    'rechausserions',
    'recherchassent',
    'recherchassiez',
    'rechercherions',
    'rechignassions',
    'rechigneraient',
    'rechristianisa',
    'rechristianise',
    'rechristianisé',
    'récidivassions',
    'récidiveraient',
    'récipiendaires',
    'réciproquaient',
    'réciproquasses',
    'réciproquement',
    'réciproquerais',
    'réciproquerait',
    'réciproquèrent',
    'réciproqueriez',
    'réciproquerons',
    'réciproqueront',
    'reclassassions',
    'reclasseraient',
    'réclusionnaire',
    'recoiffassions',
    'recoifferaient',
    'recolorassions',
    'recoloreraient',
    'recombinassent',
    'recombinassiez',
    'recombinerions',
    'recommandables',
    'recommandaient',
    'recommandasses',
    'recommandation',
    'recommanderais',
    'recommanderait',
    'recommandèrent',
    'recommanderiez',
    'recommanderons',
    'recommanderont',
    'recommençaient',
    'recommençasses',
    'recommencement',
    'recommencerais',
    'recommencerait',
    'recommencèrent',
    'recommenceriez',
    'recommencerons',
    'recommenceront',
    'recomparaisses',
    'recomparaissez',
    'recomparaîtrai',
    'recomparaîtras',
    'recomparaîtrez',
    'recomparussent',
    'recomparussiez',
    'récompensaient',
    'récompensasses',
    'récompenserais',
    'récompenserait',
    'récompensèrent',
    'récompenseriez',
    'récompenserons',
    'récompenseront',
    'recompilassent',
    'recompilassiez',
    'recompilerions',
    'recomposassent',
    'recomposassiez',
    'recomposerions',
    'recomptassions',
    'recompteraient',
    'réconciliaient',
    'réconciliasses',
    'réconciliateur',
    'réconciliation',
    'réconcilierais',
    'réconcilierait',
    'réconcilièrent',
    'réconcilieriez',
    'réconcilierons',
    'réconcilieront',
    'recondamnaient',
    'recondamnasses',
    'recondamnerais',
    'recondamnerait',
    'recondamnèrent',
    'recondamneriez',
    'recondamnerons',
    'recondamneront',
    'reconductibles',
    'reconduiraient',
    'reconduisaient',
    'reconduisirent',
    'reconduisisses',
    'réconfortaient',
    'réconfortantes',
    'réconfortasses',
    'réconforterais',
    'réconforterait',
    'réconfortèrent',
    'réconforteriez',
    'réconforterons',
    'réconforteront',
    'recongelassent',
    'recongelassiez',
    'recongèlerions',
    'reconnaissable',
    'reconnaissance',
    'reconnaissante',
    'reconnaissants',
    'reconnaissions',
    'reconnaîtrions',
    'reconnectaient',
    'reconnectasses',
    'reconnecterais',
    'reconnecterait',
    'reconnectèrent',
    'reconnecteriez',
    'reconnecterons',
    'reconnecteront',
    'reconquéraient',
    'reconquérantes',
    'reconquerrions',
    'reconquissions',
    'reconsidérâmes',
    'reconsidérasse',
    'reconsidérâtes',
    'reconsidérerai',
    'reconsidéreras',
    'reconsidérerez',
    'reconsidérions',
    'reconsolidâmes',
    'reconsolidasse',
    'reconsolidâtes',
    'reconsoliderai',
    'reconsolideras',
    'reconsoliderez',
    'reconsolidions',
    'reconstituâmes',
    'reconstituante',
    'reconstituants',
    'reconstituasse',
    'reconstituâtes',
    'reconstituerai',
    'reconstitueras',
    'reconstituerez',
    'reconstituions',
    'reconstitution',
    'reconstruction',
    'reconstruirais',
    'reconstruirait',
    'reconstruiriez',
    'reconstruirons',
    'reconstruiront',
    'reconstruisais',
    'reconstruisait',
    'reconstruisant',
    'reconstruisent',
    'reconstruisiez',
    'reconstruisons',
    'reconvertirais',
    'reconvertirait',
    'reconvertirent',
    'reconvertiriez',
    'reconvertirons',
    'reconvertiront',
    'reconvertisses',
    'reconvertissez',
    'recoquillaient',
    'recoquillasses',
    'recoquillerais',
    'recoquillerait',
    'recoquillèrent',
    'recoquilleriez',
    'recoquillerons',
    'recoquilleront',
    'recorrigeaient',
    'recorrigeasses',
    'recorrigerions',
    'recouchassions',
    'recoucherasses',
    'recoucherèrent',
    'recourbassions',
    'recourberaient',
    'recouvrassions',
    'recouvreraient',
    'recouvriraient',
    'recouvrissions',
    'recrachassions',
    'recracheraient',
    'recrépissaient',
    'recreusassions',
    'recreuseraient',
    'récriminassent',
    'récriminassiez',
    'récriminateurs',
    'récriminations',
    'récriminatrice',
    'récriminerions',
    'recristallisai',
    'recristallisas',
    'recristallisât',
    'recristallisée',
    'recristalliser',
    'recristallises',
    'recristallisés',
    'recristallisez',
    'recroiseraient',
    'recroquevillai',
    'recroquevillas',
    'recroquevillât',
    'recroquevillée',
    'recroqueviller',
    'recroquevilles',
    'recroquevillés',
    'recroquevillez',
    'recrudescences',
    'recrudescentes',
    'rectangulaires',
    'rectifiassions',
    'rectificateurs',
    'rectifications',
    'rectificatives',
    'rectificatrice',
    'rectifieraient',
    'rectilinéaires',
    'recueillements',
    'recueillerions',
    'recueillissent',
    'recueillissiez',
    'reculottassent',
    'reculottassiez',
    'reculotterions',
    'récupérassions',
    'récupératrices',
    'récupéreraient',
    'rédactionnelle',
    'redéfiniraient',
    'redéfinissions',
    'redemandassent',
    'redemandassiez',
    'redemanderions',
    'redémarrassent',
    'redémarrassiez',
    'redémarrerions',
    'redémoliraient',
    'redémolissions',
    'redémontraient',
    'redémontrasses',
    'redémontrerais',
    'redémontrerait',
    'redémontrèrent',
    'redémontreriez',
    'redémontrerons',
    'redémontreront',
    'rédemptoristes',
    'redéploierions',
    'redéployassent',
    'redéployassiez',
    'redescendaient',
    'redescendirent',
    'redescendisses',
    'redescendrions',
    'redeviendrions',
    'rédintégration',
    'rediscutassent',
    'rediscutassiez',
    'rediscuterions',
    'redistribuâmes',
    'redistribuasse',
    'redistribuâtes',
    'redistribuerai',
    'redistribueras',
    'redistribuerez',
    'redistribuions',
    'redistribution',
    'redoublassions',
    'redoubleraient',
    'redressassions',
    'redresseraient',
    'réductibilités',
    'réductionnisme',
    'réduplications',
    'réduplicatives',
    'réécoutassions',
    'réécouteraient',
    'réédifiassions',
    'réédifications',
    'réédifieraient',
    'rééduquassions',
    'rééduqueraient',
    'réembauchaient',
    'réembauchasses',
    'réembaucherais',
    'réembaucherait',
    'réembauchèrent',
    'réembaucheriez',
    'réembaucherons',
    'réembaucheront',
    'réemploierions',
    'réemployassent',
    'réemployassiez',
    'réempruntaient',
    'réempruntasses',
    'réemprunterais',
    'réemprunterait',
    'réempruntèrent',
    'réemprunteriez',
    'réemprunterons',
    'réemprunteront',
    'réengageassent',
    'réengageassiez',
    'réengageraient',
    'réenregistrais',
    'réenregistrait',
    'réenregistrant',
    'réenregistrées',
    'réenregistrent',
    'réenregistrera',
    'réenregistriez',
    'réenregistrons',
    'réensemençâmes',
    'réensemençasse',
    'réensemençâtes',
    'réensemencerai',
    'réensemenceras',
    'réensemencerez',
    'réensemencions',
    'rééquilibrages',
    'rééquilibrâmes',
    'rééquilibrasse',
    'rééquilibrâtes',
    'rééquilibrerai',
    'rééquilibreras',
    'rééquilibrerât',
    'rééquilibrions',
    'réescomptaient',
    'réescomptasses',
    'réescompterais',
    'réescompterait',
    'réescomptèrent',
    'réescompteriez',
    'réescompterons',
    'réescompteront',
    'réessaieraient',
    'réessayassions',
    'réessayeraient',
    'réévaluassions',
    'réévalueraient',
    'réexaminassent',
    'réexaminassiez',
    'réexaminerions',
    'réexpédiassent',
    'réexpédiassiez',
    'réexpédierions',
    'réexportassent',
    'réexportassiez',
    'réexporterions',
    'refaçonnassent',
    'refaçonnassiez',
    'refaçonnerions',
    'référençassent',
    'référençassiez',
    'référencerions',
    'référentielles',
    'réfléchiraient',
    'réfléchissante',
    'réfléchissants',
    'réfléchissions',
    'refleuriraient',
    'refleurissions',
    'réflexogrammes',
    'reforgeassions',
    'reformulassent',
    'reformulassiez',
    'reformulerions',
    'refouillassent',
    'refouillassiez',
    'refouillements',
    'refouillerions',
    'refourrassions',
    'refourreraient',
    'réfractassions',
    'réfracteraient',
    'réfrangibilité',
    'réfrigérassent',
    'réfrigérassiez',
    'réfrigérateurs',
    'réfrigérations',
    'réfrigérerions',
    'refroidiraient',
    'refroidisseurs',
    'refroidissions',
    'regarnissaient',
    'regazonnassent',
    'regazonnassiez',
    'regazonnerions',
    'régénérassions',
    'régénératrices',
    'régénéreraient',
    'régionalisâmes',
    'régionalisasse',
    'régionalisâtes',
    'régionaliserai',
    'régionaliseras',
    'régionaliserez',
    'régionalisions',
    'registrassions',
    'registreraient',
    'réglementaient',
    'réglementaires',
    'réglementasses',
    'réglementation',
    'réglementerais',
    'réglementerait',
    'réglementèrent',
    'réglementeriez',
    'réglementerons',
    'réglementeront',
    'regonflassions',
    'regonfleraient',
    'regorgeassions',
    'regrattassions',
    'regratteraient',
    'regreffassions',
    'regrefferaient',
    'régressassions',
    'régresseraient',
    'regrettassions',
    'regretteraient',
    'regrimpassions',
    'regrimperaient',
    'regrossiraient',
    'regrossissions',
    'regroupassions',
    'regrouperaient',
    'régularisaient',
    'régularisantes',
    'régularisasses',
    'régularisation',
    'régulariserais',
    'régulariserait',
    'régularisèrent',
    'régulariseriez',
    'régulariserons',
    'régulariseront',
    'régurgitassent',
    'régurgitassiez',
    'régurgitations',
    'régurgiterions',
    'réhabilitables',
    'réhabilitaient',
    'réhabilitasses',
    'réhabilitation',
    'réhabiliterais',
    'réhabiliterait',
    'réhabilitèrent',
    'réhabiliteriez',
    'réhabiliterons',
    'réhabiliteront',
    'réhabituassent',
    'réhabituassiez',
    'réhabituerions',
    'rehaussassions',
    'rehausseraient',
    'réhydratassent',
    'réhydratassiez',
    'réhydraterions',
    'réimplantaient',
    'réimplantasses',
    'réimplanterais',
    'réimplanterait',
    'réimplantèrent',
    'réimplanteriez',
    'réimplanterons',
    'réimplanteront',
    'réimportassent',
    'réimportassiez',
    'réimporterions',
    'réimposassions',
    'réimposeraient',
    'réimprimassent',
    'réimprimassiez',
    'réimprimerions',
    'réincarcérâmes',
    'réincarcérasse',
    'réincarcérâtes',
    'réincarcérerai',
    'réincarcéreras',
    'réincarcérerez',
    'réincarcérions',
    'réincarnassent',
    'réincarnassiez',
    'réincarnations',
    'réincarnerions',
    'réincorporâmes',
    'réincorporasse',
    'réincorporâtes',
    'réincorporerai',
    'réincorporeras',
    'réincorporerez',
    'réincorporions',
    'reine-des-prés',
    'reines-claudes',
    'réinfectassent',
    'réinfectassiez',
    'réinfecterions',
    'réinitialisais',
    'réinitialisait',
    'réinitialisant',
    'réinitialisées',
    'réinitialisent',
    'réinitialisera',
    'réinitialisiez',
    'réinitialisons',
    'réinjectassent',
    'réinjectassiez',
    'réinjecterions',
    'réinscriraient',
    'réinscrivaient',
    'réinscrivirent',
    'réinscrivisses',
    'réinsérassions',
    'réinséreraient',
    'réinstallaient',
    'réinstallasses',
    'réinstallation',
    'réinstallerais',
    'réinstallerait',
    'réinstallèrent',
    'réinstalleriez',
    'réinstallerons',
    'réinstalleront',
    'réintégrassent',
    'réintégrassiez',
    'réintégrations',
    'réintégrerions',
    'réinterprétais',
    'réinterprétait',
    'réinterprétant',
    'réinterprétées',
    'réinterprètent',
    'réinterprétera',
    'réinterprétiez',
    'réinterprétons',
    'réintroduction',
    'réintroduirais',
    'réintroduirait',
    'réintroduiriez',
    'réintroduirons',
    'réintroduiront',
    'réintroduisais',
    'réintroduisait',
    'réintroduisant',
    'réintroduisent',
    'réintroduisiez',
    'réintroduisons',
    'réinventassent',
    'réinventassiez',
    'réinventerions',
    'réinvestirions',
    'réinvestissais',
    'réinvestissait',
    'réinvestissant',
    'réinvestissent',
    'réinvestissiez',
    'réinvestissons',
    'réinvitassions',
    'réinviteraient',
    'rejailliraient',
    'rejaillissions',
    'rejoignissions',
    'rejointoierais',
    'rejointoierait',
    'rejointoieriez',
    'rejointoierons',
    'rejointoieront',
    'rejointoyaient',
    'rejointoyasses',
    'rejointoyèrent',
    'relaissassions',
    'relaisseraient',
    'rélargissaient',
    'relationnelles',
    'relativisaient',
    'relativisantes',
    'relativisasses',
    'relativisation',
    'relativiserais',
    'relativiserait',
    'relativisèrent',
    'relativiseriez',
    'relativiserons',
    'relativiseront',
    'religieusement',
    'religionnaires',
    'remaillassions',
    'remailleraient',
    'remangeassions',
    'remaquillaient',
    'remaquillasses',
    'remaquillerais',
    'remaquillerait',
    'remaquillèrent',
    'remaquilleriez',
    'remaquillerons',
    'remaquilleront',
    'remarchassions',
    'remarcheraient',
    'remarquassions',
    'remarqueraient',
    'remastiquaient',
    'remastiquasses',
    'remastiquerais',
    'remastiquerait',
    'remastiquèrent',
    'remastiqueriez',
    'remastiquerons',
    'remastiqueront',
    'remballassions',
    'remballeraient',
    'rembarquassent',
    'rembarquassiez',
    'rembarquements',
    'rembarquerions',
    'rembarrassions',
    'rembarreraient',
    'remblaieraient',
    'remblavassions',
    'remblaveraient',
    'remblayassions',
    'remblayeraient',
    'remboîtassions',
    'remboîteraient',
    'rembougeassent',
    'rembougeassiez',
    'rembougeraient',
    'rembourrassent',
    'rembourrassiez',
    'rembourrerions',
    'remboursassent',
    'remboursassiez',
    'remboursements',
    'rembourserions',
    'rembruniraient',
    'rembrunissions',
    'rembuchassions',
    'rembucheraient',
    'remembrassions',
    'remembreraient',
    'remémorassions',
    'remémoreraient',
    'remerciassions',
    'remercieraient',
    'remeublassions',
    'remeubleraient',
    'remilitarisais',
    'remilitarisait',
    'remilitarisant',
    'remilitarisées',
    'remilitarisent',
    'remilitarisera',
    'remilitarisiez',
    'remilitarisons',
    'remmaillassent',
    'remmaillassiez',
    'remmaillerions',
    'remmaillotâmes',
    'remmaillotasse',
    'remmaillotâtes',
    'remmailloterai',
    'remmailloteras',
    'remmailloterez',
    'remmaillotions',
    'remmanchassent',
    'remmanchassiez',
    'remmancherions',
    'remodelassions',
    'remodèleraient',
    'remonte-pentes',
    'remontrassions',
    'remontreraient',
    'remorquassions',
    'remorqueraient',
    'remouchassions',
    'remoucheraient',
    'remouillassent',
    'remouillassiez',
    'remouillerions',
    'rempaillassent',
    'rempaillassiez',
    'rempaillerâmes',
    'rempaillerasse',
    'rempaillerâtes',
    'rempaquetaient',
    'rempaquetasses',
    'rempaquetèrent',
    'rempaquetterai',
    'rempaquetteras',
    'rempaquetterez',
    'rempiétassions',
    'rempiéteraient',
    'remplaçassions',
    'remplaceraient',
    'remploieraient',
    'remployassions',
    'remplumassions',
    'remplumeraient',
    'rempochassions',
    'rempocheraient',
    'rempoissonnais',
    'rempoissonnait',
    'rempoissonnant',
    'rempoissonnées',
    'rempoissonnent',
    'rempoissonnera',
    'rempoissonniez',
    'rempoissonnons',
    'remportassions',
    'remporteraient',
    'rempruntassent',
    'rempruntassiez',
    'remprunterions',
    'remue-méninges',
    'rémunérassions',
    'rémunératoires',
    'rémunératrices',
    'rémunéreraient',
    'rencaissassent',
    'rencaissassiez',
    'rencaissements',
    'rencaisserions',
    'rencardassions',
    'rencarderaient',
    'renchaînassent',
    'renchaînassiez',
    'renchaînerions',
    'renchériraient',
    'renchérisseurs',
    'renchérisseuse',
    'renchérissions',
    'rencognassions',
    'rencogneraient',
    'rencontrassent',
    'rencontrassiez',
    'rencontrerions',
    'rendormiraient',
    'rendormissions',
    'rendossassions',
    'rendosseraient',
    'renégociassent',
    'renégociassiez',
    'renégocierions',
    'renfaîtassions',
    'renfaîteraient',
    'renfermassions',
    'renfermeraient',
    'renflammassent',
    'renflammassiez',
    'renflammerions',
    'renflouassions',
    'renfloueraient',
    'renfonçassions',
    'renfonceraient',
    'renforçassions',
    'renforceraient',
    'renformirasses',
    'renformirèrent',
    'renformissions',
    'renfrognassent',
    'renfrognassiez',
    'renfrognements',
    'renfrognerions',
    'rengageassions',
    'rengainassions',
    'rengaineraient',
    'rengorgeassent',
    'rengorgeassiez',
    'rengorgeraient',
    'rengraciassent',
    'rengraciassiez',
    'rengracierions',
    'rengraissaient',
    'rengraissasses',
    'rengraisserais',
    'rengraisserait',
    'rengraissèrent',
    'rengraisseriez',
    'rengraisserons',
    'rengraisseront',
    'rengrenassions',
    'rengrénassions',
    'rengréneraient',
    'rengrèneraient',
    'renonciataires',
    'renonciatrices',
    'renouvelassent',
    'renouvelassiez',
    'renouvellement',
    'renouvellerais',
    'renouvellerait',
    'renouvelleriez',
    'renouvellerons',
    'renouvelleront',
    'renquillassent',
    'renquillassiez',
    'renquillerions',
    'renseignassent',
    'renseignassiez',
    'renseignements',
    'renseignerions',
    'rentabilisâmes',
    'rentabilisasse',
    'rentabilisâtes',
    'rentabiliserai',
    'rentabiliseras',
    'rentabiliserez',
    'rentabilisions',
    'rentoilassions',
    'rentoileraient',
    'rentrouvraient',
    'rentrouvrirais',
    'rentrouvrirait',
    'rentrouvrirent',
    'rentrouvririez',
    'rentrouvrirons',
    'rentrouvriront',
    'rentrouvrisses',
    'renveloppaient',
    'renveloppasses',
    'renvelopperais',
    'renvelopperait',
    'renveloppèrent',
    'renvelopperiez',
    'renvelopperons',
    'renvelopperont',
    'renvenimassent',
    'renvenimassiez',
    'renvenimerions',
    'renvergeassent',
    'renvergeassiez',
    'renvergeraient',
    'renversassions',
    'renverseraient',
    'réoccupassions',
    'réoccuperaient',
    'réorchestrâmes',
    'réorchestrasse',
    'réorchestrâtes',
    'réorchestrerai',
    'réorchestreras',
    'réorchestrerez',
    'réorchestrions',
    'réordonnançais',
    'réordonnançait',
    'réordonnançant',
    'réordonnancées',
    'réordonnancent',
    'réordonnancera',
    'réordonnanciez',
    'réordonnançons',
    'réordonnassent',
    'réordonnassiez',
    'réordonnerions',
    'réorganisaient',
    'réorganisasses',
    'réorganisateur',
    'réorganisation',
    'réorganiserais',
    'réorganiserait',
    'réorganisèrent',
    'réorganiseriez',
    'réorganiserons',
    'réorganiseront',
    'réorientassent',
    'réorientassiez',
    'réorientations',
    'réorienterions',
    'reparaissaient',
    'reparaîtraient',
    'repartageaient',
    'repartageasses',
    'repartagerions',
    'répartissables',
    'répartissaient',
    'repatinassions',
    'repatineraient',
    'repeignassions',
    'repeigneraient',
    'repeignissions',
    'répercutassent',
    'répercutassiez',
    'répercuterions',
    'répertoriaient',
    'répertoriasses',
    'répertorierais',
    'répertorierait',
    'répertorièrent',
    'répertorieriez',
    'répertorierons',
    'répertorieront',
    'repeuplassions',
    'repeupleraient',
    'replantassions',
    'replanteraient',
    'replâtrassions',
    'replâtreraient',
    'répliquassions',
    'répliqueraient',
    'replissassions',
    'replisseraient',
    'replongeassent',
    'replongeassiez',
    'replongeraient',
    'repositionnais',
    'repositionnait',
    'repositionnant',
    'repositionnées',
    'repositionnent',
    'repositionnera',
    'repositionniez',
    'repositionnons',
    'repoussassions',
    'repousseraient',
    'répréhensibles',
    'représentables',
    'représentaient',
    'représentantes',
    'représentasses',
    'représentatifs',
    'représentation',
    'représentative',
    'représenterais',
    'représenterait',
    'représentèrent',
    'représenteriez',
    'représenterons',
    'représenteront',
    'réprimandaient',
    'réprimandasses',
    'réprimanderais',
    'réprimanderait',
    'réprimandèrent',
    'réprimanderiez',
    'réprimanderons',
    'réprimanderont',
    'reprochassions',
    'reprocheraient',
    'reproductrices',
    'reproduiraient',
    'reproduisaient',
    'reproduisirent',
    'reproduisisses',
    'reprogrammâmes',
    'reprogrammasse',
    'reprogrammâtes',
    'reprogrammerai',
    'reprogrammeras',
    'reprogrammerez',
    'reprogrammions',
    'reprographiais',
    'reprographiait',
    'reprographiant',
    'reprographiées',
    'reprographient',
    'reprographiera',
    'reprographiiez',
    'reprographions',
    'reprouvassions',
    'réprouvassions',
    'reprouveraient',
    'réprouveraient',
    'républicanisai',
    'républicanisas',
    'républicanisât',
    'républicanisée',
    'républicaniser',
    'républicanises',
    'républicanisés',
    'républicanisez',
    'républicanisme',
    'requinquassent',
    'requinquassiez',
    'requinquerions',
    'réquisitionnai',
    'réquisitionnas',
    'réquisitionnât',
    'réquisitionnée',
    'réquisitionner',
    'réquisitionnes',
    'réquisitionnés',
    'réquisitionnez',
    'réquisitoriale',
    'réquisitoriaux',
    'rescindassions',
    'rescinderaient',
    'résidentielles',
    'résinifiassent',
    'résinifiassiez',
    'résinifierions',
    'resocialisâmes',
    'resocialisasse',
    'resocialisâtes',
    'resocialiserai',
    'resocialiseras',
    'resocialiserez',
    'resocialisions',
    'respectabilité',
    'respectassions',
    'respecteraient',
    'respectivement',
    'resplendirions',
    'resplendissais',
    'resplendissait',
    'resplendissant',
    'resplendissent',
    'resplendissiez',
    'resplendissons',
    'responsabilisa',
    'responsabilise',
    'responsabilisé',
    'responsabilité',
    'resquillassent',
    'resquillassiez',
    'resquillerions',
    'ressaignassent',
    'ressaignassiez',
    'ressaignerions',
    'ressaisiraient',
    'ressaisissions',
    'ressassassions',
    'ressasseraient',
    'ressautassions',
    'ressauteraient',
    'ressemblassent',
    'ressemblassiez',
    'ressemblerions',
    'ressemelassent',
    'ressemelassiez',
    'ressemellerais',
    'ressemellerait',
    'ressemelleriez',
    'ressemellerons',
    'ressemelleront',
    'ressentiraient',
    'ressentissions',
    'resserrassions',
    'resserreraient',
    'resserviraient',
    'resservissions',
    'ressortiraient',
    'ressortissante',
    'ressortissants',
    'ressortissions',
    'ressoudassions',
    'ressouderaient',
    'ressourçassent',
    'ressourçassiez',
    'ressourcements',
    'ressourcerions',
    'ressouvenaient',
    'ressouviennent',
    'ressouvinssent',
    'ressouvinssiez',
    'ressurgiraient',
    'ressurgissions',
    'ressuscitaient',
    'ressuscitasses',
    'ressusciterais',
    'ressusciterait',
    'ressuscitèrent',
    'ressusciteriez',
    'ressusciterons',
    'ressusciteront',
    'restaurassions',
    'restauratrices',
    'restaureraient',
    'restituassions',
    'restitueraient',
    'restreignaient',
    'restreignirent',
    'restreignisses',
    'restreindrions',
    'restructurâmes',
    'restructurasse',
    'restructurâtes',
    'restructurerai',
    'restructureras',
    'restructurerez',
    'restructurions',
    'resurchauffais',
    'resurchauffait',
    'resurchauffant',
    'resurchauffées',
    'resurchauffent',
    'resurchauffera',
    'resurchauffiez',
    'resurchauffons',
    'resurgissaient',
    'rétablissaient',
    'rétablissement',
    'retaillassions',
    'retailleraient',
    'retapissassent',
    'retapissassiez',
    'retapisserions',
    'reteignissions',
    'retentissaient',
    'retentissantes',
    'retentissement',
    'réticulassions',
    'réticuleraient',
    'rétorquassions',
    'rétorqueraient',
    'retouchassions',
    'retoucheraient',
    'retournassions',
    'retourneraient',
    'rétractassions',
    'rétracteraient',
    'rétractibilité',
    'retraduiraient',
    'retraduisaient',
    'retraduisirent',
    'retraduisisses',
    'retraitassions',
    'retraiteraient',
    'retranchassent',
    'retranchassiez',
    'retranchements',
    'retrancherions',
    'retranscrirais',
    'retranscrirait',
    'retranscririez',
    'retranscrirons',
    'retranscriront',
    'retranscrivais',
    'retranscrivait',
    'retranscrivant',
    'retranscrivent',
    'retranscriviez',
    'retranscrivons',
    'retransmettais',
    'retransmettait',
    'retransmettant',
    'retransmettent',
    'retransmetteur',
    'retransmettiez',
    'retransmettons',
    'retransmettrai',
    'retransmettras',
    'retransmettrez',
    'retransmissent',
    'retransmissiez',
    'retransmission',
    'retravaillâmes',
    'retravaillasse',
    'retravaillâtes',
    'retravaillerai',
    'retravailleras',
    'retravaillerez',
    'retravaillions',
    'retraversaient',
    'retraversasses',
    'retraverserais',
    'retraverserait',
    'retraversèrent',
    'retraverseriez',
    'retraverserons',
    'retraverseront',
    'rétrécissaient',
    'rétrécissantes',
    'rétrécissement',
    'retrempassions',
    'retremperaient',
    'rétribuassions',
    'rétribueraient',
    'rétroactivités',
    'rétroagiraient',
    'rétroagissions',
    'rétrocédassent',
    'rétrocédassiez',
    'rétrocéderions',
    'rétrogradaient',
    'rétrogradasses',
    'rétrogradation',
    'rétrograderais',
    'rétrograderait',
    'rétrogradèrent',
    'rétrograderiez',
    'rétrograderons',
    'rétrograderont',
    'rétrogressions',
    'rétropédalages',
    'rétropositions',
    'rétrospections',
    'rétrospectives',
    'retroussassent',
    'retroussassiez',
    'retroussements',
    'retrousserions',
    'retrouvassions',
    'retrouveraient',
    'réunifiassions',
    'réunifications',
    'réunifieraient',
    'réutilisassent',
    'réutilisassiez',
    'réutilisations',
    'réutiliserions',
    'revaccinassent',
    'revaccinassiez',
    'revaccinerions',
    'revalorisaient',
    'revalorisasses',
    'revalorisation',
    'revaloriserais',
    'revaloriserait',
    'revalorisèrent',
    'revaloriseriez',
    'revaloriserons',
    'revaloriseront',
    'revanchassions',
    'revancheraient',
    'réveillassions',
    'réveille-matin',
    'réveilleraient',
    'réveillonnâmes',
    'réveillonnasse',
    'réveillonnâtes',
    'réveillonnerai',
    'réveillonneras',
    'réveillonnerez',
    'réveillonneurs',
    'réveillonnions',
    'revendicateurs',
    'revendications',
    'revendicatives',
    'revendicatrice',
    'revendiquaient',
    'revendiquasses',
    'revendiquerais',
    'revendiquerait',
    'revendiquèrent',
    'revendiqueriez',
    'revendiquerons',
    'revendiqueront',
    'réverbérassent',
    'réverbérassiez',
    'réverbérations',
    'réverbérerions',
    'reverchassions',
    'revercheraient',
    'reverdissaient',
    'révérencielles',
    'révérencieuses',
    'révérendissime',
    'revernissaient',
    'réversibilités',
    'revigorassions',
    'revigoreraient',
    'révisionnismes',
    'révisionnistes',
    'revitalisaient',
    'revitalisasses',
    'revitalisation',
    'revitaliserais',
    'revitaliserait',
    'revitalisèrent',
    'revitaliseriez',
    'revitaliserons',
    'revitaliseront',
    'revivifiassent',
    'revivifiassiez',
    'revivifierions',
    'révolutionnais',
    'révolutionnait',
    'révolutionnant',
    'révolutionnées',
    'révolutionnent',
    'révolutionnera',
    'révolutionniez',
    'révolutionnons',
    'révolvérisâmes',
    'révolvérisasse',
    'révolvérisâtes',
    'révolvériserai',
    'révolvériseras',
    'révolvériserez',
    'révolvérisions',
    'rhabdomanciens',
    'rhabillassions',
    'rhabilleraient',
    'rhétoriciennes',
    'rhinencéphales',
    'rhinolaryngite',
    'rhinopharyngée',
    'rhinopharyngés',
    'rhombencéphale',
    'rhumatologique',
    'rhumatologiste',
    'ribonucléiques',
    'ridiculisaient',
    'ridiculisasses',
    'ridiculiserais',
    'ridiculiserait',
    'ridiculisèrent',
    'ridiculiseriez',
    'ridiculiserons',
    'ridiculiseront',
    'riffaudassions',
    'riffauderaient',
    'rigidifiassent',
    'rigidifiassiez',
    'rigidifierions',
    'rigoureusement',
    'rimaillassions',
    'rimailleraient',
    'ringardassions',
    'ringarderaient',
    'ripaillassions',
    'ripailleraient',
    'ripolinassions',
    'ripolineraient',
    'ristournassent',
    'ristournassiez',
    'ristournerions',
    'ritualisassent',
    'ritualisassiez',
    'ritualiserions',
    'rivalisassions',
    'rivaliseraient',
    'robotisassions',
    'robotiseraient',
    'rocambolesques',
    'rocking-chairs',
    'rôdaillassions',
    'rôdailleraient',
    'rognonnassions',
    'rognonneraient',
    'romanisassions',
    'romaniseraient',
    'romans-fleuves',
    'ronchonnassent',
    'ronchonnassiez',
    'ronchonnements',
    'ronchonnerions',
    'rondouillardes',
    'ronéotypassent',
    'ronéotypassiez',
    'ronéotyperions',
    'ronflaguassent',
    'ronflaguassiez',
    'ronflaguerions',
    'ronronnassions',
    'ronronneraient',
    'ronsardisaient',
    'ronsardisasses',
    'ronsardiserais',
    'ronsardiserait',
    'ronsardisèrent',
    'ronsardiseriez',
    'ronsardiserons',
    'ronsardiseront',
    'rosicruciennes',
    'roucoulassions',
    'roucouleraient',
    'rougeoieraient',
    'rougeoyassions',
    'roulottassions',
    'roulotteraient',
    'roupillassions',
    'roupilleraient',
    'rouscaillaient',
    'rouscaillasses',
    'rouscaillerais',
    'rouscaillerait',
    'rouscaillèrent',
    'rouscailleriez',
    'rouscaillerons',
    'rouscailleront',
    'rouspétassions',
    'rouspéteraient',
    'ruisselassions',
    'ruissellements',
    'ruissellerions',
    'russifiassions',
    'russifications',
    'russifieraient',
    'rustiquassions',
    'rustiqueraient',
    'sabellianismes',
    'sablonnassions',
    'sablonneraient',
    'saccageassions',
    'saccharifiâmes',
    'saccharifiasse',
    'saccharifiâtes',
    'saccharifierai',
    'saccharifieras',
    'saccharifierez',
    'saccharifiions',
    'sacralisassent',
    'sacralisassiez',
    'sacralisations',
    'sacraliserions',
    'sacramentaires',
    'sacramentelles',
    'sacrifiassions',
    'sacrificateurs',
    'sacrificatoire',
    'sacrificatrice',
    'sacrificielles',
    'sacrifieraient',
    'sadomasochisme',
    'sadomasochiste',
    'safaris-photos',
    'saint-cyrienne',
    'saint-frusquin',
    'saint-glinglin',
    'saint-nectaire',
    'saint-simonien',
    'saisie-brandon',
    'saisies-arrêts',
    'saisonnassions',
    'saisonneraient',
    'salmoniculteur',
    'salmoniculture',
    'salpêtrassions',
    'salpêtreraient',
    'salpêtrisation',
    'salsepareilles',
    'sanctifiassent',
    'sanctifiassiez',
    'sanctificateur',
    'sanctification',
    'sanctifierions',
    'sanctionnaient',
    'sanctionnasses',
    'sanctionnerais',
    'sanctionnerait',
    'sanctionnèrent',
    'sanctionneriez',
    'sanctionnerons',
    'sanctionneront',
    'sanctuarisâmes',
    'sanctuarisasse',
    'sanctuarisâtes',
    'sanctuariserai',
    'sanctuariseras',
    'sanctuariserez',
    'sanctuarisions',
    'sandwichassent',
    'sandwichassiez',
    'sandwicherions',
    'sanglotassions',
    'sangloteraient',
    'sanguinolentes',
    'santonnassions',
    'santonneraient',
    'saperlipopette',
    'sapeur-pompier',
    'saponifiassent',
    'saponifiassiez',
    'saponification',
    'saponifierions',
    'sardoniquement',
    'sarmentassions',
    'sarmenteraient',
    'satellisassent',
    'satellisassiez',
    'satellisations',
    'satelliserions',
    'satirisassions',
    'satiriseraient',
    'satisfaisaient',
    'satisfaisantes',
    'saucissonnâmes',
    'saucissonnasse',
    'saucissonnâtes',
    'saucissonnerai',
    'saucissonneras',
    'saucissonnerez',
    'saucissonnions',
    'saupoudrassent',
    'saupoudrassiez',
    'saupoudrerions',
    'saut-de-mouton',
    'saute-ruisseau',
    'sautillassions',
    'sautilleraient',
    'sauvegardaient',
    'sauvegardasses',
    'sauvegarderais',
    'sauvegarderait',
    'sauvegardèrent',
    'sauvegarderiez',
    'sauvegarderons',
    'sauvegarderont',
    'sauve-qui-peut',
    'savoureusement',
    'scandalisaient',
    'scandalisasses',
    'scandaliserais',
    'scandaliserait',
    'scandalisèrent',
    'scandaliseriez',
    'scandaliserons',
    'scandaliseront',
    'scaphandrières',
    'scarificateurs',
    'scarifications',
    'schématisaient',
    'schématisasses',
    'schématisation',
    'schématiserais',
    'schématiserait',
    'schématisèrent',
    'schématiseriez',
    'schématiserons',
    'schématiseront',
    'schizonévroses',
    'schizophrénies',
    'schlittassions',
    'schlitteraient',
    'scientificités',
    'scintigraphies',
    'scintillassent',
    'scintillassiez',
    'scintillations',
    'scintillements',
    'scintillerâmes',
    'scintillerasse',
    'scintillerâtes',
    'scissionnaient',
    'scissionnasses',
    'scissionnerais',
    'scissionnerait',
    'scissionnèrent',
    'scissionneriez',
    'scissionnerons',
    'scissionneront',
    'scissionnistes',
    'scléroprotéine',
    'sclérosassions',
    'scléroseraient',
    'scolarisassent',
    'scolarisassiez',
    'scolarisations',
    'scolariserions',
    'scotomisassent',
    'scotomisassiez',
    'scotomisations',
    'scotomiserions',
    'scratchassions',
    'scratcheraient',
    'scribouillâmes',
    'scribouillards',
    'scribouillasse',
    'scribouillâtes',
    'scribouillerai',
    'scribouilleras',
    'scribouillerez',
    'scribouilleurs',
    'scribouilleuse',
    'scribouillions',
    'sécessionniste',
    'secondairement',
    'secrétaireries',
    'sectionnassent',
    'sectionnassiez',
    'sectionnements',
    'sectionnerions',
    'sectorisassent',
    'sectorisassiez',
    'sectorisations',
    'sectoriserions',
    'sécularisaient',
    'sécularisasses',
    'sécularisation',
    'séculariserais',
    'séculariserait',
    'sécularisèrent',
    'séculariseriez',
    'séculariserons',
    'séculariseront',
    'sécurisassions',
    'sécuriseraient',
    'sédentarisâmes',
    'sédentarisasse',
    'sédentarisâtes',
    'sédentariserai',
    'sédentariseras',
    'sédentariserez',
    'sédentarisions',
    'sédimentassent',
    'sédimentassiez',
    'sédimentations',
    'sédimenterions',
    'séditieusement',
    'segmentassions',
    'segmenteraient',
    'séjournassions',
    'séjourneraient',
    'sélectionnâmes',
    'sélectionnasse',
    'sélectionnâtes',
    'sélectionnerai',
    'sélectionneras',
    'sélectionnerez',
    'sélectionneurs',
    'sélectionneuse',
    'sélectionnions',
    'sélénographies',
    'self-induction',
    'sémanticiennes',
    'semi-chenillée',
    'semi-chenillés',
    'semi-consonnes',
    'semi-nomadisme',
    'sémioticiennes',
    'semi-perméable',
    'semi-publiques',
    'semi-remorques',
    'semnopithèques',
    'sempiternelles',
    'senestrochères',
    'sensationnelle',
    'sensibilisâmes',
    'sensibilisante',
    'sensibilisants',
    'sensibilisasse',
    'sensibilisâtes',
    'sensibiliserai',
    'sensibiliseras',
    'sensibiliserez',
    'sensibilisions',
    'sensitométries',
    'sensorimétries',
    'sensorimoteurs',
    'sensorimotrice',
    'sentimentalité',
    'septembrisades',
    'septembriseurs',
    'septentrionale',
    'septentrionaux',
    'septuagénaires',
    'septuplassions',
    'septupleraient',
    'séquestrassent',
    'séquestrassiez',
    'séquestrations',
    'séquestrerions',
    'seraient-elles',
    'serfouissaient',
    'sérialisassent',
    'sérialisassiez',
    'sérialiserions',
    'sériciculteurs',
    'séricicultrice',
    'séricicultures',
    'séricigraphies',
    'seringuassions',
    'seringueraient',
    'sermonnassions',
    'sermonneraient',
    'sérodiagnostic',
    'serpentassions',
    'serpenteraient',
    'servocommandes',
    'servomécanisme',
    'sextuplassions',
    'sextupleraient',
    'sexualisassent',
    'sexualisassiez',
    'sexualisations',
    'sexualiserions',
    'shakespeariens',
    'shampooineuses',
    'shampooingnais',
    'shampooingnait',
    'shampooingnant',
    'shampooingnées',
    'shampooingnent',
    'shampooingnera',
    'shampooingniez',
    'shampooingnons',
    'shampouinaient',
    'shampouinasses',
    'shampouinerais',
    'shampouinerait',
    'shampouinèrent',
    'shampouineriez',
    'shampouinerons',
    'shampouineront',
    'shampouineuses',
    'sidérolithique',
    'sifflotassions',
    'siffloteraient',
    'sigillographie',
    'signalisassent',
    'signalisassiez',
    'signalisations',
    'signaliserions',
    'signifiassions',
    'significations',
    'significatives',
    'signifieraient',
    'silhouettaient',
    'silhouettasses',
    'silhouetterais',
    'silhouetterait',
    'silhouettèrent',
    'silhouetteriez',
    'silhouetterons',
    'silhouetteront',
    'silicatisaient',
    'silicatisasses',
    'silicatiserais',
    'silicatiserait',
    'silicatisèrent',
    'silicatiseriez',
    'silicatiserons',
    'silicatiseront',
    'siliconassions',
    'siliconeraient',
    'sillonnassions',
    'sillonneraient',
    'similigravures',
    'similisassions',
    'similiseraient',
    'simplifiassent',
    'simplifiassiez',
    'simplificateur',
    'simplification',
    'simplifierions',
    'simultanéismes',
    'singularisâmes',
    'singularisasse',
    'singularisâtes',
    'singulariserai',
    'singulariseras',
    'singulariserez',
    'singularisions',
    'singulièrement',
    'sintérisassent',
    'sintérisassiez',
    'sintériserions',
    'siphonnassions',
    'siphonneraient',
    'sismothérapies',
    'sitogoniomètre',
    'socialisassent',
    'socialisassiez',
    'socialisations',
    'socialiserions',
    'socioaffectifs',
    'socioaffective',
    'socio-culturel',
    'socioculturels',
    'socio-éducatif',
    'sociométriques',
    'sociothérapies',
    'socratisassent',
    'socratisassiez',
    'socratiserions',
    'sodomisassions',
    'sodomiseraient',
    'soixante-douze',
    'soixante-et-un',
    'soixante-seize',
    'soixante-trois',
    'solarisassions',
    'solariseraient',
    'solennellement',
    'solennisassent',
    'solennisassiez',
    'solenniserions',
    'solidarisaient',
    'solidarisasses',
    'solidariserais',
    'solidariserait',
    'solidarisèrent',
    'solidariseriez',
    'solidariserons',
    'solidariseront',
    'solidifiassent',
    'solidifiassiez',
    'solidification',
    'solidifierions',
    'solifluassions',
    'soliflueraient',
    'soliloquassent',
    'soliloquassiez',
    'soliloquerions',
    'sollicitassent',
    'sollicitassiez',
    'sollicitations',
    'solliciterions',
    'solubilisaient',
    'solubilisasses',
    'solubilisation',
    'solubiliserais',
    'solubiliserait',
    'solubilisèrent',
    'solubiliseriez',
    'solubiliserons',
    'solubiliseront',
    'solutionnaient',
    'solutionnasses',
    'solutionnerais',
    'solutionnerait',
    'solutionnèrent',
    'solutionneriez',
    'solutionnerons',
    'solutionneront',
    'somatisassions',
    'somatiseraient',
    'somatotrophine',
    'sommeillassent',
    'sommeillassiez',
    'sommeillerions',
    'somnambuliques',
    'somnambulismes',
    'somptueusement',
    'sonnaillassent',
    'sonnaillassiez',
    'sonnaillerions',
    'sonorisassions',
    'sonoriseraient',
    'sophistication',
    'sophistiquâmes',
    'sophistiquasse',
    'sophistiquâtes',
    'sophistiquerai',
    'sophistiqueras',
    'sophistiquerez',
    'sophistiquions',
    'sortie-de-bain',
    'soubresautâmes',
    'soubresautasse',
    'soubresautâtes',
    'soubresauterai',
    'soubresauteras',
    'soubresauterez',
    'soubresautions',
    'souchetassions',
    'souchetterions',
    'souchevassions',
    'souchèveraient',
    'souffletassent',
    'souffletassiez',
    'souffletterais',
    'souffletterait',
    'souffletteriez',
    'souffletterons',
    'souffletteront',
    'souhaitassions',
    'souhaiteraient',
    'soulageassions',
    'soulignassions',
    'souligneraient',
    'soumissionnais',
    'soumissionnait',
    'soumissionnant',
    'soumissionnées',
    'soumissionnent',
    'soumissionnera',
    'soumissionniez',
    'soumissionnons',
    'soupçonnassent',
    'soupçonnassiez',
    'soupçonnerions',
    'sourcillassent',
    'sourcillassiez',
    'sourcillerions',
    'sourdinassions',
    'sourdineraient',
    'sous-activités',
    'sous-alimentai',
    'sous-alimentas',
    'sous-alimentât',
    'sous-alimentée',
    'sous-alimenter',
    'sous-alimentes',
    'sous-alimentés',
    'sous-alimentez',
    'sous-bailleurs',
    'sous-brigadier',
    'sous-calibrées',
    'sous-clavières',
    'sous-comptoirs',
    'souscriptrices',
    'souscrivissent',
    'souscrivissiez',
    'sous-développé',
    'sous-diaconats',
    'sous-directeur',
    'sous-dominante',
    'sous-ensembles',
    'sous-entendais',
    'sous-entendait',
    'sous-entendant',
    'sous-entendent',
    'sous-entendiez',
    'sous-entendons',
    'sous-entendrai',
    'sous-entendras',
    'sous-entendrez',
    'sous-entendues',
    'sous-estimâmes',
    'sous-estimasse',
    'sous-estimâtes',
    'sous-estimerai',
    'sous-estimeras',
    'sous-estimerez',
    'sous-estimions',
    'sous-évaluâmes',
    'sous-évaluasse',
    'sous-évaluâtes',
    'sous-évaluerai',
    'sous-évalueras',
    'sous-évaluerez',
    'sous-évaluions',
    'sous-exploitai',
    'sous-exploitas',
    'sous-exploitât',
    'sous-exploitée',
    'sous-exploiter',
    'sous-exploites',
    'sous-exploités',
    'sous-exploitez',
    'sous-exposâmes',
    'sous-exposasse',
    'sous-exposâtes',
    'sous-exposerai',
    'sous-exposeras',
    'sous-exposerez',
    'sous-exposions',
    'sous-ingénieur',
    'sous-intendant',
    'sous-locataire',
    'sous-locations',
    'sous-louassent',
    'sous-louassiez',
    'sous-louerions',
    'sous-maîtresse',
    'sous-mariniers',
    'sous-multiples',
    'sous-occipital',
    'sous-officiers',
    'sous-orbitaire',
    'sous-paierions',
    'sous-payassent',
    'sous-payassiez',
    'sous-payerions',
    'sous-programme',
    'sous-pubiennes',
    'sous-tangentes',
    'sous-tendaient',
    'sous-tendirent',
    'sous-tendisses',
    'sous-tendrions',
    'sous-titraient',
    'sous-titrasses',
    'sous-titrerais',
    'sous-titrerait',
    'sous-titrèrent',
    'sous-titreriez',
    'sous-titrerons',
    'sous-titreront',
    'soustrairaient',
    'sous-traitâmes',
    'sous-traitance',
    'sous-traitante',
    'sous-traitants',
    'sous-traitasse',
    'sous-traitâtes',
    'sous-traiterai',
    'sous-traiteras',
    'sous-traiterez',
    'sous-traitions',
    'sous-utilisais',
    'sous-utilisait',
    'sous-utilisant',
    'sous-utilisées',
    'sous-utilisent',
    'sous-utilisera',
    'sous-utilisiez',
    'sous-utilisons',
    'sous-ventrière',
    'sous-vêtements',
    'sous-virassent',
    'sous-virassiez',
    'sous-virerions',
    'soutachassions',
    'soutacheraient',
    'soutiendraient',
    'soutiens-gorge',
    'souverainement',
    'souviendraient',
    'soviétisassent',
    'soviétisassiez',
    'soviétiserions',
    'spasmolytiques',
    'spathifiassent',
    'spathifiassiez',
    'spathifierions',
    'spatialisaient',
    'spatialisasses',
    'spatialisation',
    'spatialiserais',
    'spatialiserait',
    'spatialisèrent',
    'spatialiseriez',
    'spatialiserons',
    'spatialiseront',
    'spécialisaient',
    'spécialisasses',
    'spécialisation',
    'spécialiserais',
    'spécialiserait',
    'spécialisèrent',
    'spécialiseriez',
    'spécialiserons',
    'spécialiseront',
    'spécifiassions',
    'spécifications',
    'spécifieraient',
    'spécifiquement',
    'spectaculaires',
    'spéléologiques',
    'spermatogonies',
    'spermatozoïdes',
    'sphacélassions',
    'sphacéleraient',
    'sphinctérienne',
    'sphygmogrammes',
    'sphygmographes',
    'spiritualisais',
    'spiritualisait',
    'spiritualisant',
    'spiritualisées',
    'spiritualisent',
    'spiritualisera',
    'spiritualisiez',
    'spiritualismes',
    'spiritualisons',
    'spiritualistes',
    'splénomégalies',
    'spongicultures',
    'sponsorisaient',
    'sponsorisasses',
    'sponsoriserais',
    'sponsoriserait',
    'sponsorisèrent',
    'sponsoriseriez',
    'sponsoriserons',
    'sponsoriseront',
    'sporadiquement',
    'sporotrichoses',
    'squattérisâmes',
    'squattérisasse',
    'squattérisâtes',
    'squattériserai',
    'squattériseras',
    'squattériserez',
    'squattérisions',
    'stabilisassent',
    'stabilisassiez',
    'stabilisateurs',
    'stabilisations',
    'stabilisatrice',
    'stabiliserions',
    'stakhanovismes',
    'stakhanovistes',
    'stalagmomètres',
    'stalagmométrie',
    'stalinisassent',
    'stalinisassiez',
    'staliniserions',
    'standardisâmes',
    'standardisasse',
    'standardisâtes',
    'standardiserai',
    'standardiseras',
    'standardiserez',
    'standardisions',
    'staphylococcie',
    'staphylocoques',
    'starifiassions',
    'starifieraient',
    'starting-block',
    'starting-gates',
    'stationnarités',
    'stationnassent',
    'stationnassiez',
    'stationnements',
    'stationnerions',
    'statisticienne',
    'statoréacteurs',
    'statufiassions',
    'statufieraient',
    'statutairement',
    'steeple-chases',
    'stendhaliennes',
    'sténographiais',
    'sténographiait',
    'sténographiant',
    'sténographiées',
    'sténographient',
    'sténographiera',
    'sténographiiez',
    'sténographions',
    'sténographique',
    'sténotypassent',
    'sténotypassiez',
    'sténotyperions',
    'stéréochromies',
    'stéréographies',
    'stéréométrique',
    'stéréophonique',
    'stéréoscopique',
    'stéréotomiques',
    'stéréotypaient',
    'stéréotypasses',
    'stéréotyperais',
    'stéréotyperait',
    'stéréotypèrent',
    'stéréotyperiez',
    'stéréotyperons',
    'stéréotyperont',
    'stérilisassent',
    'stérilisassiez',
    'stérilisateurs',
    'stérilisations',
    'stériliserions',
    'sternutatoires',
    'stigmatisaient',
    'stigmatisasses',
    'stigmatisation',
    'stigmatiserais',
    'stigmatiserait',
    'stigmatisèrent',
    'stigmatiseriez',
    'stigmatiserons',
    'stigmatiseront',
    'stipendiassent',
    'stipendiassiez',
    'stipendierions',
    'stoechiométrie',
    'stomatologiste',
    'stomatoplastie',
    'stomatorragies',
    'strangulassent',
    'strangulassiez',
    'strangulations',
    'strangulerions',
    'stratifiassent',
    'stratifiassiez',
    'stratification',
    'stratifierions',
    'stratigraphies',
    'stratigraphiqu',
    'streptobacille',
    'streptococcies',
    'streptomycètes',
    'streptomycines',
    'stridulassions',
    'striduleraient',
    'strioscopiques',
    'strip-teaseuse',
    'stromboliennes',
    'structuralisme',
    'structuraliste',
    'structurassent',
    'structurassiez',
    'structurations',
    'structurerions',
    'structurologie',
    'stupéfiassions',
    'stupéfieraient',
    'stylisticienne',
    'stylographique',
    'subantarctique',
    'subcarpatiques',
    'subconsciences',
    'subconscientes',
    'subdélégations',
    'subdéléguaient',
    'subdéléguasses',
    'subdéléguerais',
    'subdéléguerait',
    'subdéléguèrent',
    'subdélégueriez',
    'subdéléguerons',
    'subdélégueront',
    'subdésertiques',
    'subdivisassent',
    'subdivisassiez',
    'subdiviserions',
    'subéquatoriale',
    'subéquatoriaux',
    'subjectivement',
    'subjectivismes',
    'subjectivistes',
    'subjuguassions',
    'subjugueraient',
    'submergeassent',
    'submergeassiez',
    'submergeraient',
    'subodorassions',
    'subodoreraient',
    'subordinations',
    'subordonnaient',
    'subordonnantes',
    'subordonnasses',
    'subordonnerais',
    'subordonnerait',
    'subordonnèrent',
    'subordonneriez',
    'subordonnerons',
    'subordonneront',
    'subrepticement',
    'subrogeassions',
    'subsistassions',
    'subsisteraient',
    'substantialité',
    'substantielles',
    'substantifique',
    'substantivâmes',
    'substantivasse',
    'substantivâtes',
    'substantiverai',
    'substantiveras',
    'substantiverez',
    'substantivions',
    'substituassent',
    'substituassiez',
    'substituerions',
    'subtilisassent',
    'subtilisassiez',
    'subtilisations',
    'subtiliserions',
    'subventionnais',
    'subventionnait',
    'subventionnant',
    'subventionnées',
    'subventionnels',
    'subventionnent',
    'subventionnera',
    'subventionniez',
    'subventionnons',
    'subversivement',
    'subvertiraient',
    'subvertissions',
    'subviendraient',
    'successibilité',
    'successivement',
    'succombassions',
    'succomberaient',
    'sud-africaines',
    'sud-américaine',
    'sud-américains',
    'sud-vietnamien',
    'suffoquassions',
    'suffoqueraient',
    'suggestibilité',
    'suggestionnais',
    'suggestionnait',
    'suggestionnant',
    'suggestionnées',
    'suggestionnent',
    'suggestionnera',
    'suggestionniez',
    'suggestionnons',
    'sulfinisations',
    'sulfocarbonate',
    'superbénéfices',
    'supercarburant',
    'superchampions',
    'superdividende',
    'superfétations',
    'superfétatoire',
    'superficialité',
    'superficielles',
    'superfinirions',
    'superfinissais',
    'superfinissait',
    'superfinissant',
    'superfinissent',
    'superfinissiez',
    'superfinissons',
    'superfinitions',
    'supérieurement',
    'superphosphate',
    'superposassent',
    'superposassiez',
    'superposerions',
    'superpositions',
    'superpuissance',
    'superréactions',
    'superstitieuse',
    'superstructure',
    'supervisassent',
    'supervisassiez',
    'superviserions',
    'supplantassent',
    'supplantassiez',
    'supplanterions',
    'supplémentaire',
    'supplémentâmes',
    'supplémentasse',
    'supplémentâtes',
    'supplémenterai',
    'supplémenteras',
    'supplémenterez',
    'supplémentions',
    'suppliciassent',
    'suppliciassiez',
    'supplicierions',
    'supportassions',
    'supporteraient',
    'supprimassions',
    'supprimeraient',
    'supraliminaire',
    'supranationale',
    'supranationaux',
    'suprasegmental',
    'suprasensibles',
    'supraterrestre',
    'surabondamment',
    'surabondassent',
    'surabondassiez',
    'surabonderions',
    'surajoutassent',
    'surajoutassiez',
    'surajouterions',
    'suralimentâmes',
    'suralimentasse',
    'suralimentâtes',
    'suralimenterai',
    'suralimenteras',
    'suralimenterez',
    'suralimentions',
    'surbaissassent',
    'surbaissassiez',
    'surbaissements',
    'surbaisserions',
    'surchargeaient',
    'surchargeasses',
    'surchargerions',
    'surchauffaient',
    'surchauffasses',
    'surchaufferais',
    'surchaufferait',
    'surchauffèrent',
    'surchaufferiez',
    'surchaufferons',
    'surchaufferont',
    'surclassassent',
    'surclassassiez',
    'surclasserions',
    'surcompression',
    'surcomprimâmes',
    'surcomprimasse',
    'surcomprimâtes',
    'surcomprimerai',
    'surcomprimeras',
    'surcomprimerez',
    'surcomprimions',
    'surcontrassent',
    'surcontrassiez',
    'surcontrerions',
    'surcoupassions',
    'surcouperaient',
    'surcreusements',
    'surdéterminant',
    'surdéterminées',
    'surdéveloppées',
    'surédifiassent',
    'surédifiassiez',
    'surédifierions',
    'surélevassions',
    'surélèveraient',
    'surenchérirais',
    'surenchérirait',
    'surenchérirent',
    'surenchéririez',
    'surenchérirons',
    'surenchériront',
    'surenchérisses',
    'surenchérissez',
    'surentraînâmes',
    'surentraînasse',
    'surentraînâtes',
    'surentraînerai',
    'surentraîneras',
    'surentraînerez',
    'surentraînions',
    'suréquipassent',
    'suréquipassiez',
    'suréquipements',
    'suréquiperions',
    'surérogatoires',
    'surestimassent',
    'surestimassiez',
    'surestimations',
    'surestimerions',
    'surévaluassent',
    'surévaluassiez',
    'surévaluations',
    'surévaluerions',
    'surexcitassent',
    'surexcitassiez',
    'surexcitations',
    'surexciterions',
    'surexploitâmes',
    'surexploitasse',
    'surexploitâtes',
    'surexploiterai',
    'surexploiteras',
    'surexploiterez',
    'surexploitions',
    'surexposassent',
    'surexposassiez',
    'surexposerions',
    'surexpositions',
    'surgénérateurs',
    'surgénératrice',
    'surgeonnassent',
    'surgeonnassiez',
    'surgeonnerions',
    'surglaçassions',
    'surglaceraient',
    'surhaussassent',
    'surhaussassiez',
    'surhaussements',
    'surhausserions',
    'surhumainement',
    'surimposassent',
    'surimposassiez',
    'surimposerions',
    'surimpositions',
    'surimpressions',
    'surintendances',
    'surintendantes',
    'surjetteraient',
    'surmédicalisai',
    'surmédicalisas',
    'surmédicalisât',
    'surmédicalisée',
    'surmédicaliser',
    'surmédicalises',
    'surmédicalisés',
    'surmédicalisez',
    'surmontassions',
    'surmonteraient',
    'surmoulassions',
    'surmouleraient',
    'surmultipliées',
    'surnageassions',
    'surnaturalisme',
    'surnommassions',
    'surnommeraient',
    'suroxydassions',
    'suroxyderaient',
    'surpassassions',
    'surpasseraient',
    'surpeuplements',
    'surpiquassions',
    'surpiqueraient',
    'surplombassent',
    'surplombassiez',
    'surplombements',
    'surplomberions',
    'surpopulations',
    'surprendraient',
    'surproducteurs',
    'surproductions',
    'surproductrice',
    'surprotégeâmes',
    'surprotégeasse',
    'surprotégeâtes',
    'surprotégerais',
    'surprotégerait',
    'surprotégèrent',
    'surprotégeriez',
    'surprotégerons',
    'surprotégeront',
    'sursaturassent',
    'sursaturassiez',
    'sursaturations',
    'sursaturerions',
    'sursautassions',
    'sursauteraient',
    'surveillassent',
    'surveillassiez',
    'surveillerions',
    'surviendraient',
    'survoltassions',
    'survolteraient',
    'susceptibilité',
    'sus-dominantes',
    'sus-hépatiques',
    'susmentionnées',
    'suspectassions',
    'suspecteraient',
    'suspendissions',
    'sustentassions',
    'sustentatrices',
    'sustenteraient',
    'syllogistiques',
    'sylvicultrices',
    'symboliquement',
    'symbolisassent',
    'symbolisassiez',
    'symbolisations',
    'symboliserions',
    'symétriquement',
    'symétrisassent',
    'symétrisassiez',
    'symétriserions',
    'sympathisaient',
    'sympathisantes',
    'sympathisasses',
    'sympathiserais',
    'sympathiserait',
    'sympathisèrent',
    'sympathiseriez',
    'sympathiserons',
    'sympathiseront',
    'symptomatiques',
    'synchronisable',
    'synchronisâmes',
    'synchronisasse',
    'synchronisâtes',
    'synchroniserai',
    'synchroniseras',
    'synchroniserez',
    'synchroniseurs',
    'synchroniseuse',
    'synchronisions',
    'syncristallisa',
    'syncristallise',
    'syncristallisé',
    'syndicalisâmes',
    'syndicalisasse',
    'syndicalisâtes',
    'syndicaliserai',
    'syndicaliseras',
    'syndicaliserez',
    'syndicalisions',
    'syndiquassions',
    'syndiqueraient',
    'syntacticienne',
    'syntagmatiques',
    'syntaxiquement',
    'synthétisaient',
    'synthétisasses',
    'synthétiserais',
    'synthétiserait',
    'synthétisèrent',
    'synthétiseriez',
    'synthétiserons',
    'synthétiseront',
    'syntonisassent',
    'syntonisassiez',
    'syntonisations',
    'syntoniserions',
    'syphiligraphes',
    'syphiligraphie',
    'syphilographes',
    'syphilographie',
    'syringomyélies',
    'systématisâmes',
    'systématisasse',
    'systématisâtes',
    'systématiserai',
    'systématiseras',
    'systématiserez',
    'systématisions',
    'tachetteraient',
    'tachistoscopes',
    'tachyarythmies',
    'tachyphylaxies',
    'tailladassions',
    'tailladeraient',
    'taille-crayons',
    'tailles-douces',
    'tambourinaient',
    'tambourinaires',
    'tambourinasses',
    'tambourinement',
    'tambourinerais',
    'tambourinerait',
    'tambourinèrent',
    'tambourineriez',
    'tambourinerons',
    'tambourineront',
    'tambourineuses',
    'tamponnassions',
    'tamponneraient',
    'tarabiscotages',
    'tarabiscotâmes',
    'tarabiscotasse',
    'tarabiscotâtes',
    'tarabiscoterai',
    'tarabiscoteras',
    'tarabiscoterez',
    'tarabiscotions',
    'tarabustassent',
    'tarabustassiez',
    'tarabusterions',
    'tatillonnaient',
    'tatillonnasses',
    'tatillonnerais',
    'tatillonnerait',
    'tatillonnèrent',
    'tatillonneriez',
    'tatillonnerons',
    'tatillonneront',
    'tauromachiques',
    'taylorisassent',
    'taylorisassiez',
    'taylorisations',
    'tayloriserions',
    'tchécoslovaque',
    'technétronique',
    'technicisaient',
    'technicisasses',
    'techniciserais',
    'techniciserait',
    'technicisèrent',
    'techniciseriez',
    'techniciserons',
    'techniciseront',
    'technisassions',
    'techniseraient',
    'technocratique',
    'technocratisai',
    'technocratisas',
    'technocratisât',
    'technocratisée',
    'technocratiser',
    'technocratises',
    'technocratisés',
    'technocratisez',
    'technocratisme',
    'technologiques',
    'technologistes',
    'télécommandais',
    'télécommandait',
    'télécommandant',
    'télécommandées',
    'télécommandent',
    'télécommandera',
    'télécommandiez',
    'télécommandons',
    'téléconférence',
    'télécopiassent',
    'télécopiassiez',
    'télécopierions',
    'télédétections',
    'télédiffusâmes',
    'télédiffusasse',
    'télédiffusâtes',
    'télédiffuserai',
    'télédiffuseras',
    'télédiffuserez',
    'télédiffusions',
    'télégraphiâmes',
    'télégraphiasse',
    'télégraphiâtes',
    'télégraphierai',
    'télégraphieras',
    'télégraphierez',
    'télégraphiions',
    'télégraphiques',
    'télégraphistes',
    'téléguidassent',
    'téléguidassiez',
    'téléguiderions',
    'téléimprimeurs',
    'télémécanicien',
    'télémécaniques',
    'télémétrassent',
    'télémétrassiez',
    'télémétrerions',
    'téléopérateurs',
    'téléopérations',
    'téléopératrice',
    'téléphonassent',
    'téléphonassiez',
    'téléphonerions',
    'téléreportages',
    'télérobotiques',
    'télescopassent',
    'télescopassiez',
    'télescoperions',
    'téléscripteurs',
    'téléspectateur',
    'télétraitement',
    'télévisassions',
    'téléviseraient',
    'tellurhydrique',
    'témoignassions',
    'témoigneraient',
    'temporairement',
    'temporisassent',
    'temporisassiez',
    'temporisateurs',
    'temporisations',
    'temporisatrice',
    'temporiserions',
    'tenaillassions',
    'tenailleraient',
    'ténébreusement',
    'ténorisassions',
    'ténoriseraient',
    'tératologiques',
    'tératologistes',
    'tergiversaient',
    'tergiversasses',
    'tergiversation',
    'tergiverserais',
    'tergiverserait',
    'tergiversèrent',
    'tergiverseriez',
    'tergiverserons',
    'tergiverseront',
    'terrassassions',
    'terrasseraient',
    'terreautassent',
    'terreautassiez',
    'terreauterions',
    'terre-neuviens',
    'terre-neuviers',
    'terrifiassions',
    'terrifieraient',
    'territorialité',
    'terrorisassent',
    'terrorisassiez',
    'terroriserions',
    'tertiarisation',
    'testamentaires',
    'tétanisassions',
    'tétaniseraient',
    'têtes-de-Maure',
    'tétrachlorures',
    'tétraplégiques',
    'tétratomicités',
    'texturisassent',
    'texturisassiez',
    'texturisations',
    'texturiserions',
    'thalassotoques',
    'théâtralisâmes',
    'théâtralisasse',
    'théâtralisâtes',
    'théâtraliserai',
    'théâtraliseras',
    'théâtraliserez',
    'théâtralisions',
    'thématisassent',
    'thématisassiez',
    'thématiserions',
    'théorématiques',
    'théorisassions',
    'théoriseraient',
    'thérapeutiques',
    'thermocautères',
    'thermochimique',
    'thermoformages',
    'thermographies',
    'thermométrique',
    'thermopropulsé',
    'thermostatique',
    'thermothérapie',
    'thésaurisaient',
    'thésaurisasses',
    'thésaurisation',
    'thésauriserais',
    'thésauriserait',
    'thésaurisèrent',
    'thésauriseriez',
    'thésauriserons',
    'thésauriseront',
    'thésauriseuses',
    'thoracocentèse',
    'thoracoplastie',
    'thyroglobuline',
    'thyroïdectomie',
    'thyrotrophines',
    'tictaquassions',
    'tictaqueraient',
    'tiercefeuilles',
    'tiers-mondiste',
    'tintinnabulais',
    'tintinnabulait',
    'tintinnabulant',
    'tintinnabulent',
    'tintinnabulera',
    'tintinnabuliez',
    'tintinnabulons',
    'tiraillassions',
    'tirailleraient',
    'tire-bouchonna',
    'tirebouchonnai',
    'tirebouchonnas',
    'tirebouchonnât',
    'tire-bouchonne',
    'tire-bouchonné',
    'tirebouchonnée',
    'tirebouchonner',
    'tirebouchonnes',
    'tirebouchonnés',
    'tirebouchonnez',
    'tissus-éponges',
    'titularisaient',
    'titularisantes',
    'titularisasses',
    'titularisation',
    'titulariserais',
    'titulariserait',
    'titularisèrent',
    'titulariseriez',
    'titulariserons',
    'titulariseront',
    'toilettassions',
    'toiletteraient',
    'tonicardiaques',
    'tonitruassions',
    'tonitrueraient',
    'topicalisaient',
    'topicalisasses',
    'topicaliserais',
    'topicaliserait',
    'topicalisèrent',
    'topicaliseriez',
    'topicaliserons',
    'topicaliseront',
    'topographiques',
    'torchonnassent',
    'torchonnassiez',
    'torchonnerions',
    'torpillassions',
    'torpilleraient',
    'torréfiassions',
    'torréfieraient',
    'tortillassions',
    'tortilleraient',
    'totalisassions',
    'totalisatrices',
    'totaliseraient',
    'totalitarismes',
    'toupillassions',
    'toupilleraient',
    'tourbillonnais',
    'tourbillonnait',
    'tourbillonnant',
    'tourbillonnent',
    'tourbillonnera',
    'tourbillonniez',
    'tourbillonnons',
    'tourillonnâmes',
    'tourillonnasse',
    'tourillonnâtes',
    'tourillonnerai',
    'tourillonneras',
    'tourillonnerez',
    'tourillonnions',
    'tourmentassent',
    'tourmentassiez',
    'tourmenterions',
    'tournaillaient',
    'tournaillasses',
    'tournaillerais',
    'tournaillerait',
    'tournaillèrent',
    'tournailleriez',
    'tournaillerons',
    'tournailleront',
    'tournassassent',
    'tournassassiez',
    'tournasserions',
    'tourneboulâmes',
    'tourneboulasse',
    'tourneboulâtes',
    'tourneboulerai',
    'tournebouleras',
    'tourneboulerez',
    'tourneboulions',
    'tourne-disques',
    'tourne-feuille',
    'tourne-pierres',
    'tournicotaient',
    'tournicotasses',
    'tournicoterais',
    'tournicoterait',
    'tournicotèrent',
    'tournicoteriez',
    'tournicoterons',
    'tournicoteront',
    'tournillassent',
    'tournillassiez',
    'tournillerions',
    'tourniquassent',
    'tourniquassiez',
    'tourniquerions',
    'tournoieraient',
    'tournoyassions',
    'tour-opérateur',
    'toussaillaient',
    'toussaillasses',
    'toussaillerais',
    'toussaillerait',
    'toussaillèrent',
    'toussailleriez',
    'toussaillerons',
    'toussailleront',
    'toussotassions',
    'toussoteraient',
    'tout-puissants',
    'toxicologiques',
    'toxicomaniaque',
    'traboulassions',
    'trabouleraient',
    'tracassassions',
    'tracasseraient',
    'trachée-artère',
    'trachéostomies',
    'tractionnaires',
    'traditionnaire',
    'traditionnelle',
    'traduisissions',
    'traficotassent',
    'traficotassiez',
    'traficoterions',
    'tragi-comédies',
    'tragi-comiques',
    'traînaillaient',
    'traînaillasses',
    'traînaillerais',
    'traînaillerait',
    'traînaillèrent',
    'traînailleriez',
    'traînaillerons',
    'traînailleront',
    'traînassassent',
    'traînassassiez',
    'traînasserions',
    'traîne-buisson',
    'traîne-savates',
    'traîtreusement',
    'tranchefilâmes',
    'tranchefilasse',
    'tranchefilâtes',
    'tranchefilerai',
    'tranchefileras',
    'tranchefilerez',
    'tranchefilions',
    'tranquillement',
    'tranquillisais',
    'tranquillisait',
    'tranquillisant',
    'tranquillisées',
    'tranquillisent',
    'tranquillisera',
    'tranquillisiez',
    'tranquillisons',
    'transactionnel',
    'transafricaine',
    'transafricains',
    'transbahutâmes',
    'transbahutasse',
    'transbahutâtes',
    'transbahuterai',
    'transbahuteras',
    'transbahuterez',
    'transbahutions',
    'transbordaient',
    'transbordasses',
    'transbordement',
    'transborderais',
    'transborderait',
    'transbordèrent',
    'transborderiez',
    'transborderons',
    'transborderont',
    'transcanadiens',
    'transcaspienne',
    'transcendaient',
    'transcendances',
    'transcendantal',
    'transcendantes',
    'transcendasses',
    'transcenderais',
    'transcenderait',
    'transcendèrent',
    'transcenderiez',
    'transcenderons',
    'transcenderont',
    'transcodassent',
    'transcodassiez',
    'transcoderions',
    'transcripteurs',
    'transcriptions',
    'transcriraient',
    'transcrivaient',
    'transcrivirent',
    'transcrivisses',
    'transférassent',
    'transférassiez',
    'transfèrements',
    'transférentiel',
    'transférerions',
    'transfigurâmes',
    'transfigurasse',
    'transfigurâtes',
    'transfigurerai',
    'transfigureras',
    'transfigurerez',
    'transfigurions',
    'transfilassent',
    'transfilassiez',
    'transfilerions',
    'transformables',
    'transformaient',
    'transformantes',
    'transformasses',
    'transformateur',
    'transformation',
    'transformerais',
    'transformerait',
    'transformèrent',
    'transformeriez',
    'transformerons',
    'transformeront',
    'transformismes',
    'transformistes',
    'transfusassent',
    'transfusassiez',
    'transfuserions',
    'transgressâmes',
    'transgressasse',
    'transgressâtes',
    'transgresserai',
    'transgresseras',
    'transgresserez',
    'transgresseurs',
    'transgressions',
    'transhumassent',
    'transhumassiez',
    'transhumerions',
    'transigeassent',
    'transigeassiez',
    'transigeraient',
    'transistorisai',
    'transistorisas',
    'transistorisât',
    'transistorisée',
    'transistoriser',
    'transistorises',
    'transistorisés',
    'transistorisez',
    'transitassions',
    'transiteraient',
    'transitionnels',
    'translatassent',
    'translatassiez',
    'translaterions',
    'translitérâmes',
    'translitérasse',
    'translitérâtes',
    'translitérerai',
    'translitéreras',
    'translitérerez',
    'translitérions',
    'translittérais',
    'translittérait',
    'translittérant',
    'translittérées',
    'translittèrent',
    'translittérera',
    'translittériez',
    'translittérons',
    'translocations',
    'translucidités',
    'transmettaient',
    'transmettrions',
    'transmigraient',
    'transmigrasses',
    'transmigration',
    'transmigrerais',
    'transmigrerait',
    'transmigrèrent',
    'transmigreriez',
    'transmigrerons',
    'transmigreront',
    'transmissibles',
    'transmuassions',
    'transmueraient',
    'transmutassent',
    'transmutassiez',
    'transmutations',
    'transmuterions',
    'transnationale',
    'transnationaux',
    'transocéaniens',
    'transocéanique',
    'transparaisses',
    'transparaissez',
    'transparaîtrai',
    'transparaîtras',
    'transparaîtrez',
    'transparussent',
    'transparussiez',
    'transperçaient',
    'transperçantes',
    'transperçasses',
    'transpercement',
    'transpercerais',
    'transpercerait',
    'transpercèrent',
    'transperceriez',
    'transpercerons',
    'transperceront',
    'transpirassent',
    'transpirassiez',
    'transpirations',
    'transpirerions',
    'transplantâmes',
    'transplantasse',
    'transplantâtes',
    'transplanterai',
    'transplanteras',
    'transplanterez',
    'transplantions',
    'transplantoirs',
    'transportables',
    'transportaient',
    'transportasses',
    'transportation',
    'transporterais',
    'transporterait',
    'transportèrent',
    'transporteriez',
    'transporterons',
    'transporteront',
    'transporteuses',
    'transposassent',
    'transposassiez',
    'transposerions',
    'transpositeurs',
    'transpositions',
    'transpositrice',
    'transpyrénéens',
    'transsahariens',
    'transsexuelles',
    'transsibériens',
    'transsudassent',
    'transsudassiez',
    'transsudations',
    'transsuderions',
    'transvasassent',
    'transvasassiez',
    'transvasements',
    'transvaserions',
    'transvestismes',
    'transvidassent',
    'transvidassiez',
    'transviderions',
    'traumatisaient',
    'traumatisantes',
    'traumatisasses',
    'traumatiserais',
    'traumatiserait',
    'traumatisèrent',
    'traumatiseriez',
    'traumatiserons',
    'traumatiseront',
    'traumatologies',
    'traumatologues',
    'travaillassent',
    'travaillassiez',
    'travaillerions',
    'traversassions',
    'traverseraient',
    'travestiraient',
    'travestissions',
    'trébuchassions',
    'trébucheraient',
    'treillageaient',
    'treillageasses',
    'treillagerions',
    'treillissaient',
    'treillissasses',
    'treillisserais',
    'treillisserait',
    'treillissèrent',
    'treillisseriez',
    'treillisserons',
    'treillisseront',
    'tremblotassent',
    'tremblotassiez',
    'tremblotements',
    'trembloterions',
    'trémoussassent',
    'trémoussassiez',
    'trémoussements',
    'trémousserions',
    'trépasseraient',
    'trépignassions',
    'trépigneraient',
    'tressaillaient',
    'tressaillement',
    'tressaillirais',
    'tressaillirait',
    'tressaillirent',
    'tressailliriez',
    'tressaillirons',
    'tressailliront',
    'tressaillisses',
    'tressautassent',
    'tressautassiez',
    'tressautements',
    'tressauterions',
    'treuillassions',
    'treuilleraient',
    'triangulassent',
    'triangulassiez',
    'triangulations',
    'triangulerions',
    'triballassions',
    'triballeraient',
    'tribunitiennes',
    'tricentenaires',
    'trichocéphales',
    'trifouillaient',
    'trifouillasses',
    'trifouillerais',
    'trifouillerait',
    'trifouillèrent',
    'trifouilleriez',
    'trifouillerons',
    'trifouilleront',
    'trigémellaires',
    'trigonocéphale',
    'trigonométries',
    'trimardassions',
    'trimarderaient',
    'trimbalassions',
    'trimbaleraient',
    'trimballassent',
    'trimballassiez',
    'trimballements',
    'trimballerions',
    'trimestrielles',
    'triomphalement',
    'triomphalismes',
    'triomphalistes',
    'triomphassions',
    'triomphatrices',
    'triompheraient',
    'tripatouillage',
    'tripatouillais',
    'tripatouillait',
    'tripatouillant',
    'tripatouillées',
    'tripatouillent',
    'tripatouillera',
    'tripatouilleur',
    'tripatouilliez',
    'tripatouillons',
    'triséquassions',
    'triséqueraient',
    'trisyllabiques',
    'troglodytiques',
    'trompe-la-mort',
    'trompetassions',
    'trompetterions',
    'tronçonnassent',
    'tronçonnassiez',
    'tronçonnements',
    'tronçonnerions',
    'tropicalisâmes',
    'tropicalisasse',
    'tropicalisâtes',
    'tropicaliserai',
    'tropicaliseras',
    'tropicaliserez',
    'tropicalisions',
    'trottinassions',
    'trottineraient',
    'trouillomètres',
    'trousse-galant',
    'trousse-queues',
    'troussequinais',
    'troussequinait',
    'troussequinant',
    'troussequinées',
    'troussequinent',
    'troussequinera',
    'troussequiniez',
    'troussequinons',
    'trufficultures',
    'truiticultures',
    'trusquinassent',
    'trusquinassiez',
    'trusquinerions',
    'trypanosomiase',
    'tuberculinâmes',
    'tuberculinasse',
    'tuberculinâtes',
    'tuberculinerai',
    'tuberculineras',
    'tuberculinerez',
    'tuberculinions',
    'tuberculinique',
    'tuberculinisai',
    'tuberculinisas',
    'tuberculinisât',
    'tuberculinisée',
    'tuberculiniser',
    'tuberculinises',
    'tuberculinisés',
    'tuberculinisez',
    'tuberculisâmes',
    'tuberculisasse',
    'tuberculisâtes',
    'tuberculiserai',
    'tuberculiseras',
    'tuberculiserez',
    'tuberculisions',
    'turboréacteurs',
    'turco-mongoles',
    'turlupinassent',
    'turlupinassiez',
    'turlupinerions',
    'tympanisassent',
    'tympanisassiez',
    'tympaniserions',
    'tyndallisation',
    'typographiques',
    'tyranniquement',
    'tyrannisassent',
    'tyrannisassiez',
    'tyranniserions',
    'ultérieurement',
    'ultramontaines',
    'ultra-pression',
    'ultra-sensible',
    'ultrasensibles',
    'ultra-soniques',
    'ultra-violette',
    'ultraviolettes',
    'unicellulaires',
    'uniformisaient',
    'uniformisasses',
    'uniformisation',
    'uniformiserais',
    'uniformiserait',
    'uniformisèrent',
    'uniformiseriez',
    'uniformiserons',
    'uniformiseront',
    'universalisais',
    'universalisait',
    'universalisant',
    'universalisées',
    'universalisent',
    'universalisera',
    'universalisiez',
    'universalismes',
    'universalisons',
    'universalistes',
    'universitaires',
    'urbanisassions',
    'urbaniseraient',
    'urobilinogènes',
    'usufructuaires',
    'vacuolisassent',
    'vacuolisassiez',
    'vacuolisations',
    'vacuoliserions',
    'vadrouillaient',
    'vadrouillasses',
    'vadrouillerais',
    'vadrouillerait',
    'vadrouillèrent',
    'vadrouilleriez',
    'vadrouillerons',
    'vadrouilleront',
    'vadrouilleueur',
    'vagabondassent',
    'vagabondassiez',
    'vagabonderions',
    'vaironnassions',
    'vaironneraient',
    'valdinguassent',
    'valdinguassiez',
    'valdinguerions',
    'valence-gramme',
    'valétudinaires',
    'valeureusement',
    'vallonnassions',
    'vallonneraient',
    'valorisassions',
    'valoriseraient',
    'vampirisassent',
    'vampirisassiez',
    'vampiriserions',
    'vandalisassent',
    'vandalisassiez',
    'vandaliserions',
    'vaporisassions',
    'vaporiseraient',
    'variolisations',
    'vaselinassions',
    'vaselineraient',
    'vasodilatateur',
    'vasodilatation',
    'vasouillassent',
    'vasouillassiez',
    'vasouillerions',
    'vassalisassent',
    'vassalisassiez',
    'vassaliserions',
    'vaticinassions',
    'vaticinatrices',
    'vaticineraient',
    'vaudevillesque',
    'vaudevillistes',
    'véhiculassions',
    'véhiculeraient',
    'vélarisassions',
    'vélariseraient',
    'véliplanchiste',
    'vélocipédiques',
    'vendangeassent',
    'vendangeassiez',
    'vendangeraient',
    'ventousassions',
    'ventouseraient',
    'ventriculaires',
    'ventripotentes',
    'verbalisassent',
    'verbalisassiez',
    'verbalisations',
    'verbaliserions',
    'verbiageassent',
    'verbiageassiez',
    'verbiageraient',
    'verbigérations',
    'verdunisassent',
    'verdunisassiez',
    'verdunisations',
    'verduniserions',
    'vérificatrices',
    'vermiculassent',
    'vermiculassiez',
    'vermiculerions',
    'vermillassions',
    'vermilleraient',
    'vermillonnâmes',
    'vermillonnasse',
    'vermillonnâtes',
    'vermillonnerai',
    'vermillonneras',
    'vermillonnerez',
    'vermillonnions',
    'vermoulassions',
    'vermouleraient',
    'vernalisations',
    'vernissassions',
    'vernisseraient',
    'verrouillaient',
    'verrouillasses',
    'verrouillerais',
    'verrouillerait',
    'verrouillèrent',
    'verrouilleriez',
    'verrouillerons',
    'verrouilleront',
    'versifiassions',
    'versificateurs',
    'versifications',
    'versificatrice',
    'versifieraient',
    'vers-librismes',
    'vers-libristes',
    'vert-de-grisée',
    'vert-de-grisés',
    'vesses-de-loup',
    'vestimentaires',
    'viabilisassent',
    'viabilisassiez',
    'viabiliserions',
    'vibraphonistes',
    'vibrionnassent',
    'vibrionnassiez',
    'vibrionnerions',
    'vice-consulats',
    'vice-légations',
    'vice-président',
    'vidangeassions',
    'vide-bouteille',
    'vidéocassettes',
    'vidéofréquence',
    'vieillissaient',
    'vieillissantes',
    'vieillissement',
    'vigoureusement',
    'vilipendassent',
    'vilipendassiez',
    'vilipenderions',
    'villégiaturais',
    'villégiaturait',
    'villégiaturant',
    'villégiaturent',
    'villégiaturera',
    'villégiaturiez',
    'villégiaturons',
    'vinaigrassions',
    'vinaigreraient',
    'violentassions',
    'violenteraient',
    'violoncelliste',
    'virevoltassent',
    'virevoltassiez',
    'virevolterions',
    'virilisassions',
    'viriliseraient',
    'visionnassions',
    'visionneraient',
    'visiophoniques',
    'visualisassent',
    'visualisassiez',
    'visualisations',
    'visualiserions',
    'vitrifiassions',
    'vitrifications',
    'vitrifieraient',
    'vitriolassions',
    'vitrioleraient',
    'vitrocéramique',
    'vitupérassions',
    'vitupératrices',
    'vitupéreraient',
    'vivificatrices',
    'vocalisassions',
    'vocalisatrices',
    'vocaliseraient',
    'vociférassions',
    'vocifératrices',
    'vociféreraient',
    'volatilisables',
    'volatilisaient',
    'volatilisasses',
    'volatilisation',
    'volatiliserais',
    'volatiliserait',
    'volatilisèrent',
    'volatiliseriez',
    'volatiliserons',
    'volatiliseront',
    'volcanisassent',
    'volcanisassiez',
    'volcaniserions',
    'volcanologique',
    'volontairement',
    'voltairianisme',
    'voltigeassions',
    'voussoieraient',
    'voussoyassions',
    'vraisemblables',
    'vraisemblances',
    'vrombissements',
    'vulcanisassent',
    'vulcanisassiez',
    'vulcanisations',
    'vulcaniserions',
    'vulgarisassent',
    'vulgarisassiez',
    'vulgarisateurs',
    'vulgarisations',
    'vulgarisatrice',
    'vulgariserions',
    'vulnérabilités',
    'wagons-foudres',
    'wagons-trémies',
    'warrantassions',
    'warranteraient',
    'water-ballasts',
    'weltanschauung',
    'xylographiques',
    'zigouillassent',
    'zigouillassiez',
    'zigouillerions',
    'zigzaguassions',
    'zigzagueraient',
    'zinzinulassent',
    'zinzinulassiez',
    'zinzinulerions',
    'zoogéographies',
    'zoologiquement',
    'zoosémiotiques',
    'zootechniciens',
    'zoroastriennes',
    'zwinglianismes'
];
