import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNext } from '../spending.actions';
import { getSpendingIsLoading, getAllSpendings } from '../spending.selectors';
import {
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Paper,
    CircularProgress
} from '@mui/material';
import { getAuthIsLoggedIn } from '../../Auth/auth.selectors';
import SpendingLine from '../Components/SpendingLine';
import { useNavigate } from 'react-router-dom';
import PageTitle from '../../common/Components/PageTitle';
import { Add, Search } from '@mui/icons-material';

export default function Spendings() {
    const navigate = useNavigate();
    const [range, setRange] = useState({ amount: 10, lastFetchId: '' });
    const isLoading = useSelector(getSpendingIsLoading);
    const spendings = useSelector(getAllSpendings);
    const isLoggedIn = useSelector(getAuthIsLoggedIn);
    const dispatch = useDispatch();

    useEffect(() => {
        document.title = 'Home - Dépenses';
    }, []);

    useEffect(() => {
        dispatch(fetchNext(range.amount, range.lastFetchId));
    }, [range.amount, range.lastFetchId]);

    function handleFetchNext() {
        const { amount } = range;
        const newRange = { amount, lastFetchId: spendings[spendings.length - 1].docId };
        setRange(newRange);
    }

    function handleNewSpending() {
        navigate('/spending/create');
    }

    return (
        <React.Fragment>
            <PageTitle title={'Liste des dépenses'} />
            <div className="row wrap mt-l justify-end">
                <Button variant="outlined" startIcon={<Add />} onClick={handleNewSpending}>
                    Créer un nouveau mois
                </Button>
            </div>
            <TableContainer component={Paper} className="mt-s">
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <b>Nom</b>
                            </TableCell>
                            <TableCell>
                                <b>Nombre de dépenses</b>
                            </TableCell>
                            <TableCell>
                                <b>Total dépensé</b>
                            </TableCell>
                            {isLoggedIn ? (
                                <TableCell align="right">
                                    <b>Actions</b>
                                </TableCell>
                            ) : null}
                        </TableRow>
                    </TableHead>
                    {isLoading ? (
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={isLoggedIn ? 4 : 3} align="center">
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ) : (
                        <TableBody>
                            {spendings.map((spending) => (
                                <SpendingLine key={spending.docId} spending={spending} />
                            ))}
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
            <div className="row wrap space-evenly">
                {isLoading ? <Typography>Chargement...</Typography> : null}
            </div>
            <div className="row wrap space-evenly mt-l">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleFetchNext}
                    disabled={isLoading || spendings.length % 10 !== 0}
                    startIcon={<Search />}
                >
                    Afficher plus
                </Button>
            </div>
        </React.Fragment>
    );
}
