export default [
    'abandonnassions',
    'abandonnataires',
    'abandonneraient',
    'abasourdiraient',
    'abasourdissante',
    'abasourdissants',
    'abasourdissions',
    'abâtardissaient',
    'abâtardissement',
    'abolitionnismes',
    'abolitionnistes',
    'abracadabrantes',
    'abréviativement',
    'abri-sous-roche',
    'abstentionnisme',
    'abstentionniste',
    'acagnardassions',
    'acanthocéphales',
    'accastillassent',
    'accastillassiez',
    'accastillerions',
    'accélérographes',
    'accessoirisâmes',
    'accessoirisasse',
    'accessoirisâtes',
    'accessoiriserai',
    'accessoiriseras',
    'accessoiriserez',
    'accessoirisions',
    'accidentassions',
    'accidenteraient',
    'acclimatassions',
    'acclimateraient',
    'accommodassions',
    'accommodatrices',
    'accommoderaient',
    'accompagnassent',
    'accompagnassiez',
    'accompagnateurs',
    'accompagnatrice',
    'accompagnements',
    'accompagnerions',
    'accomplissaient',
    'accomplissement',
    'accourcissaient',
    'accoutumassions',
    'accoutumeraient',
    'accréditassions',
    'accréditeraient',
    'accroche-coeurs',
    'accroupissement',
    'accueilleraient',
    'accueillissions',
    'acculturassions',
    'accultureraient',
    'achalandassions',
    'achalanderaient',
    'achondroplasies',
    'acquiesçassions',
    'acquiesceraient',
    'acrobatiquement',
    'actinothérapies',
    'actualisassions',
    'actualiseraient',
    'additionnassent',
    'additionnassiez',
    'additionnerions',
    'adénocarcinomes',
    'adjectivassions',
    'adjectiveraient',
    'adjectivisaient',
    'adjectivisasses',
    'adjectiviserais',
    'adjectiviserait',
    'adjectivisèrent',
    'adjectiviseriez',
    'adjectiviserons',
    'adjectiviseront',
    'adjudants-chefs',
    'administrassent',
    'administrassiez',
    'administrateurs',
    'administrations',
    'administratives',
    'administratrice',
    'administrerions',
    'admonestassions',
    'admonesteraient',
    'affaiblissaient',
    'affaiblissantes',
    'affaiblissement',
    'affectionnaient',
    'affectionnasses',
    'affectionnerais',
    'affectionnerait',
    'affectionnèrent',
    'affectionneriez',
    'affectionnerons',
    'affectionneront',
    'affectueusement',
    'affermissements',
    'affirmativement',
    'affouageassions',
    'affouillassions',
    'affouilleraient',
    'affourageassent',
    'affourageassiez',
    'affourageraient',
    'affourchassions',
    'affourcheraient',
    'affourrageaient',
    'affourrageasses',
    'affourragerions',
    'affranchiraient',
    'affranchissions',
    'affriandassions',
    'affrianderaient',
    'africanisassent',
    'africanisassiez',
    'africanisations',
    'africaniserions',
    'afro-asiatiques',
    'agenouillassiez',
    'agenouillements',
    'agenouillerions',
    'agglomérassions',
    'aggloméreraient',
    'agglutinassions',
    'agglutineraient',
    'agrandissements',
    'agranulocytoses',
    'agrémentassions',
    'agrémenteraient',
    'aguerrissements',
    'aiguilletassent',
    'aiguilletassiez',
    'aiguilletterais',
    'aiguilletterait',
    'aiguilletteriez',
    'aiguilletterons',
    'aiguilletteront',
    'aiguillonnaient',
    'aiguillonnasses',
    'aiguillonnerais',
    'aiguillonnerait',
    'aiguillonnèrent',
    'aiguillonneriez',
    'aiguillonnerons',
    'aiguillonneront',
    'alambiquassions',
    'alambiqueraient',
    'alanguissements',
    'alcalinisassent',
    'alcalinisassiez',
    'alcaliniserions',
    'alcoolification',
    'alcoolisassions',
    'alcooliseraient',
    'allégoriquement',
    'allégorisassent',
    'allégorisassiez',
    'allégoriserions',
    'allergologistes',
    'alluvionnassent',
    'alluvionnassiez',
    'alluvionnements',
    'alluvionnerions',
    'alourdissements',
    'alphabétisaient',
    'alphabétisasses',
    'alphabétisation',
    'alphabétiserais',
    'alphabétiserait',
    'alphabétisèrent',
    'alphabétiseriez',
    'alphabétiserons',
    'alphabétiseront',
    'alphanumériques',
    'alternativement',
    'aluminothermies',
    'amaigrissements',
    'ambitionnassent',
    'ambitionnassiez',
    'ambitionnerions',
    'américanisaient',
    'américanisasses',
    'américanisation',
    'américaniserais',
    'américaniserait',
    'américanisèrent',
    'américaniseriez',
    'américaniserons',
    'américaniseront',
    'ameublissements',
    'amoindrissaient',
    'amoindrissement',
    'amoncelleraient',
    'amordançassions',
    'amordanceraient',
    'amourachassions',
    'amphibologiques',
    'amplificatrices',
    'amygdalectomies',
    'anagrammatiques',
    'analgésiassions',
    'analgésieraient',
    'analphabétismes',
    'anaphrodisiaque',
    'anaphylactiques',
    'anastomosassiez',
    'anastomoserions',
    'anathématisâmes',
    'anathématisasse',
    'anathématisâtes',
    'anathématiserai',
    'anathématiseras',
    'anathématiserez',
    'anathématisions',
    'anatomisassions',
    'anatomiseraient',
    'anéantissements',
    'anesthésiassent',
    'anesthésiassiez',
    'anesthésierions',
    'anesthésiologie',
    'anglicisassions',
    'angliciseraient',
    'anglo-normandes',
    'animalisassions',
    'animaliseraient',
    'antédiluviennes',
    'antépénultièmes',
    'anthropogenèses',
    'anthropologique',
    'anthropométries',
    'anthropomorphes',
    'anthropophagies',
    'antialcooliques',
    'antialcoolismes',
    'antiallergiques',
    'antibrouillages',
    'antibrouillards',
    'anticancéreuses',
    'anticapitaliste',
    'anticoagulantes',
    'anticommunismes',
    'anticommunistes',
    'anticonformisme',
    'anticonformiste',
    'anticorpuscules',
    'anticycloniques',
    'antidéflagrante',
    'antidéflagrants',
    'antidépresseurs',
    'antidiphtérique',
    'antigravitation',
    'antihygiéniques',
    'antilogarithmes',
    'antimilitarisme',
    'antimilitariste',
    'antimonarchique',
    'antimonarchiste',
    'antinévralgique',
    'antipaludéennes',
    'antipatriotique',
    'antipatriotisme',
    'antirachitiques',
    'antireligieuses',
    'antiscorbutique',
    'anti-sous-marin',
    'antispasmodique',
    'antisymétriques',
    'antituberculeux',
    'antivarioliques',
    'apostasiassions',
    'apostasieraient',
    'apostillassions',
    'apostilleraient',
    'apostoliquement',
    'apostrophassent',
    'apostrophassiez',
    'apostropherions',
    'appareillassent',
    'appareillassiez',
    'appareillements',
    'appareillerions',
    'apparentassions',
    'appartiendrions',
    'appauvrissaient',
    'appauvrissement',
    'appendicectomie',
    'appendiculaires',
    'appesantiraient',
    'appesantissions',
    'applaudissaient',
    'applaudissement',
    'appointissaient',
    'appréciabilités',
    'appréhendassent',
    'appréhendassiez',
    'appréhenderions',
    'apprivoisassent',
    'apprivoisassiez',
    'apprivoisements',
    'apprivoiserions',
    'approbativement',
    'approfondirions',
    'approfondissais',
    'approfondissait',
    'approfondissant',
    'approfondissent',
    'approfondissiez',
    'approfondissons',
    'appropriassions',
    'approprieraient',
    'approvisionnais',
    'approvisionnait',
    'approvisionnant',
    'approvisionnées',
    'approvisionnent',
    'approvisionnera',
    'approvisionneur',
    'approvisionniez',
    'approvisionnons',
    'arboricultrices',
    'arc-boutassions',
    'arc-bouteraient',
    'archiconfréries',
    'archidiocésaine',
    'archidiocésains',
    'archiépiscopale',
    'archiépiscopats',
    'archiépiscopaux',
    'architectonique',
    'architecturales',
    'architecturâmes',
    'architecturasse',
    'architecturâtes',
    'architecturerai',
    'architectureras',
    'architecturerez',
    'architecturions',
    'argumentassions',
    'argumenteraient',
    'aristocratiques',
    'aristotéliciens',
    'aromatisassions',
    'aromatiseraient',
    'arraisonnassent',
    'arraisonnassiez',
    'arraisonnements',
    'arraisonnerions',
    'arrérageassions',
    'arrière-bouches',
    'arrière-cerveau',
    'arrière-choeurs',
    'arrière-cuisine',
    'arrière-pensées',
    'arrière-saisons',
    'arrière-vassaux',
    'arrondissements',
    'arsouillassions',
    'artériographies',
    'artérioscléreux',
    'artériosclérose',
    'ascensionnaient',
    'ascensionnasses',
    'ascensionnelles',
    'ascensionnerais',
    'ascensionnerait',
    'ascensionnèrent',
    'ascensionneriez',
    'ascensionnerons',
    'ascensionneront',
    'ascensionnistes',
    'assainissements',
    'assaisonnassent',
    'assaisonnassiez',
    'assaisonnements',
    'assaisonnerions',
    'assarmentassent',
    'assarmentassiez',
    'assarmenterions',
    'assassinassions',
    'assassineraient',
    'assermentassent',
    'assermentassiez',
    'assermentations',
    'assermenterions',
    'asservissements',
    'assombrissaient',
    'assombrissement',
    'assoupissements',
    'assouplissaient',
    'assouplissement',
    'assourdissaient',
    'assourdissantes',
    'assourdissement',
    'assouvissements',
    'assujettiraient',
    'assujettissante',
    'assujettissants',
    'assujettissions',
    'astreignissions',
    'athéroscléroses',
    'attendrissaient',
    'attendrissantes',
    'attendrissement',
    'atterrissements',
    'attiédissements',
    'attrape-mouches',
    'attrape-nigauds',
    'audiofréquences',
    'auditionnassent',
    'auditionnassiez',
    'auditionnerions',
    'authentifiaient',
    'authentifiasses',
    'authentifierais',
    'authentifierait',
    'authentifièrent',
    'authentifieriez',
    'authentifierons',
    'authentifieront',
    'authentiquaient',
    'authentiquasses',
    'authentiquement',
    'authentiquerais',
    'authentiquerait',
    'authentiquèrent',
    'authentiqueriez',
    'authentiquerons',
    'authentiqueront',
    'auto-accusation',
    'autobiographies',
    'autoconductions',
    'auto-couchettes',
    'autodestructeur',
    'autodestruction',
    'autodéterminées',
    'autodéterminiez',
    'autodéterminons',
    'autodisciplines',
    'autofinancement',
    'autofinanceriez',
    'autofinancerons',
    'autographiaient',
    'autographiasses',
    'autographierais',
    'autographierait',
    'autographièrent',
    'autographieriez',
    'autographierons',
    'autographieront',
    'autoguidassions',
    'auto-imposition',
    'automatiquement',
    'automatisassent',
    'automatisassiez',
    'automatisations',
    'automatiserions',
    'automédications',
    'automobilisable',
    'automutilations',
    'autonettoyantes',
    'autopropulsions',
    'autorégulateurs',
    'autorégulations',
    'autorégulatrice',
    'auto-stoppeuses',
    'autosuffisances',
    'autosuggestions',
    'autovaccination',
    'avantageassions',
    'avantageusement',
    'avant-dernières',
    'avant-gardismes',
    'avant-gardistes',
    'avant-premières',
    'aventureusement',
    'avitaillassions',
    'avitailleraient',
    'axiomatisassent',
    'axiomatisassiez',
    'axiomatisations',
    'axiomatiserions',
    'bactériologique',
    'bactériologiste',
    'badigeonnassent',
    'badigeonnassiez',
    'badigeonnerions',
    'bafouillassions',
    'bafouilleraient',
    'baguenaudassent',
    'baguenaudassiez',
    'baguenauderions',
    'bâillonnassions',
    'bâillonneraient',
    'balkanisassions',
    'balkaniseraient',
    'balnéothérapies',
    'banqueroutières',
    'banquetteraient',
    'baragouinassent',
    'baragouinassiez',
    'baragouinerions',
    'barbouillassent',
    'barbouillassiez',
    'barbouillerions',
    'barguignassions',
    'barguigneraient',
    'barricadassions',
    'barricaderaient',
    'bastillonnaient',
    'bastillonnasses',
    'bastillonnerais',
    'bastillonnerait',
    'bastillonnèrent',
    'bastillonneriez',
    'bastillonnerons',
    'bastillonneront',
    'bastionnassions',
    'bastionneraient',
    'bateaux-mouches',
    'bateaux-pilotes',
    'becquetteraient',
    'becs-de-corbeau',
    'belles-familles',
    'bénéficiassions',
    'bénéficieraient',
    'biberonnassions',
    'biberonneraient',
    'bibliographique',
    'bibliothécaires',
    'biculturalismes',
    'bidouillassions',
    'bidouilleraient',
    'bienveillamment',
    'bihebdomadaires',
    'billebaudassent',
    'billebaudassiez',
    'billebauderions',
    'bioclimatologie',
    'biodégradations',
    'bioluminescence',
    'biotechnologies',
    'bipolarisations',
    'bisegmentassent',
    'bisegmentassiez',
    'bisegmenterions',
    'bistournassions',
    'bistourneraient',
    'bivouaquassions',
    'bivouaqueraient',
    'blackboulassent',
    'blackboulassiez',
    'blackboulerions',
    'blanchissements',
    'blasphémassions',
    'blasphématoires',
    'blasphématrices',
    'blasphémeraient',
    'blennorragiques',
    'blocs-cylindres',
    'bolchévisassent',
    'bolchévisassiez',
    'bolchéviserions',
    'bondérisassions',
    'bondériseraient',
    'bonheur-du-jour',
    'bonimentassions',
    'bonimenteraient',
    'bothriocéphales',
    'bouchardassions',
    'boucharderaient',
    'bouche-à-bouche',
    'bouchonnassions',
    'bouchonneraient',
    'bouffonnassions',
    'bouffonneraient',
    'bougainvilliers',
    'bouillonnassent',
    'bouillonnassiez',
    'bouillonnements',
    'bouillonnerions',
    'bouillottassent',
    'bouillottassiez',
    'bouillotterions',
    'boulangeassions',
    'boules-de-neige',
    'bouleversassent',
    'bouleversassiez',
    'bouleversements',
    'bouleverserions',
    'bourdonnassions',
    'bourdonneraient',
    'bourgeonnassent',
    'bourgeonnassiez',
    'bourgeonnements',
    'bourgeonnerions',
    'bourlinguassent',
    'bourlinguassiez',
    'bourlinguerions',
    'bourrelleraient',
    'bourriquassions',
    'bourriqueraient',
    'boursicotassent',
    'boursicotassiez',
    'boursicoterions',
    'boursouflassent',
    'boursouflassiez',
    'boursouflements',
    'boursouflerions',
    'boustifaillâmes',
    'boustifaillasse',
    'boustifaillâtes',
    'boustifaillerai',
    'boustifailleras',
    'boustifaillerez',
    'boustifaillions',
    'bouton-poussoir',
    'bouton-pression',
    'bracelet-montre',
    'brandillassions',
    'brandilleraient',
    'bredouillassent',
    'bredouillassiez',
    'bredouillements',
    'bredouillerions',
    'brettelleraient',
    'brillantassions',
    'brillanteraient',
    'brillantinaient',
    'brillantinasses',
    'brillantinerais',
    'brillantinerait',
    'brillantinèrent',
    'brillantineriez',
    'brillantinerons',
    'brillantineront',
    'bringueballâmes',
    'bringueballasse',
    'bringueballâtes',
    'bringueballerai',
    'bringueballeras',
    'bringueballerez',
    'bringueballions',
    'brinquebalaient',
    'brinquebalasses',
    'brinquebalerais',
    'brinquebalerait',
    'brinquebalèrent',
    'brinquebaleriez',
    'brinquebalerons',
    'brinquebaleront',
    'brinqueballâmes',
    'brinqueballasse',
    'brinqueballâtes',
    'brinqueballerai',
    'brinqueballeras',
    'brinqueballerez',
    'brinqueballions',
    'briquetteraient',
    'brouillasserait',
    'brouillonnaient',
    'brouillonnasses',
    'brouillonnerais',
    'brouillonnerait',
    'brouillonnèrent',
    'brouillonneriez',
    'brouillonnerons',
    'brouillonneront',
    'broussailleuses',
    'brûle-pourpoint',
    'brutalisassions',
    'brutaliseraient',
    'bucco-dentaires',
    'bucco-génitales',
    'budgétisassions',
    'budgétiseraient',
    'bureaucratiques',
    'bureaucratisais',
    'bureaucratisait',
    'bureaucratisant',
    'bureaucratisées',
    'bureaucratisent',
    'bureaucratisera',
    'bureaucratisiez',
    'bureaucratisons',
    'byzantinologies',
    'cache-poussière',
    'cache-radiateur',
    'cadenassassions',
    'cadenasseraient',
    'cafouillassions',
    'cafouilleraient',
    'caillebottaient',
    'caillebotterais',
    'caillebotterait',
    'caillebotteriez',
    'caillebotterons',
    'caillebotteront',
    'cailletteraient',
    'cailloutassions',
    'caillouteraient',
    'calamistrassent',
    'calamistrassiez',
    'calamistrerions',
    'calfeutrassions',
    'calfeutreraient',
    'calligraphiâmes',
    'calligraphiasse',
    'calligraphiâtes',
    'calligraphierai',
    'calligraphieras',
    'calligraphierez',
    'calligraphiions',
    'calligraphiques',
    'calomnieusement',
    'calorifications',
    'calorifugeaient',
    'calorifugeasses',
    'calorifugerions',
    'calorimétriques',
    'cambriolassions',
    'cambrioleraient',
    'caoutchoutaient',
    'caoutchoutasses',
    'caoutchouterais',
    'caoutchouterait',
    'caoutchoutèrent',
    'caoutchouteriez',
    'caoutchouterons',
    'caoutchouteront',
    'caoutchouteuses',
    'caparaçonnaient',
    'caparaçonnasses',
    'caparaçonnerais',
    'caparaçonnerait',
    'caparaçonnèrent',
    'caparaçonneriez',
    'caparaçonnerons',
    'caparaçonneront',
    'capillicultures',
    'capitalisassent',
    'capitalisassiez',
    'capitalisations',
    'capitaliserions',
    'capitonnassions',
    'capitonneraient',
    'caporalisassent',
    'caporalisassiez',
    'caporaliserions',
    'capricieusement',
    'capuchonnassent',
    'capuchonnassiez',
    'capuchonnerions',
    'caractérisaient',
    'caractérisantes',
    'caractérisasses',
    'caractérisation',
    'caractériserais',
    'caractériserait',
    'caractérisèrent',
    'caractériseriez',
    'caractériserons',
    'caractériseront',
    'caractéristique',
    'caractérologies',
    'carambolassions',
    'caramboleraient',
    'carambouillages',
    'carambouilleurs',
    'caramélisassent',
    'caramélisassiez',
    'caramélisations',
    'caraméliserions',
    'carbonatassions',
    'carbonateraient',
    'carbonisassions',
    'carboniseraient',
    'carcaillassions',
    'carcailleraient',
    'caricaturassent',
    'caricaturassiez',
    'caricaturerions',
    'carillonnassent',
    'carillonnassiez',
    'carillonnements',
    'carillonnerions',
    'cartellisations',
    'cartes-réponses',
    'cartilagineuses',
    'cartographiques',
    'cartomanciennes',
    'casse-noisettes',
    'catadioptriques',
    'cataloguassions',
    'catalogueraient',
    'catapultassions',
    'catapulteraient',
    'catastrophaient',
    'catastrophasses',
    'catastropherais',
    'catastropherait',
    'catastrophèrent',
    'catastropheriez',
    'catastropherons',
    'catastropheront',
    'catastrophiques',
    'catéchisassions',
    'catéchiseraient',
    'catégoriquement',
    'catégorisations',
    'cauchemardaient',
    'cauchemardasses',
    'cauchemarderais',
    'cauchemarderait',
    'cauchemardèrent',
    'cauchemarderiez',
    'cauchemarderons',
    'cauchemarderont',
    'cauchemardesque',
    'cauchemardeuses',
    'cautérisassions',
    'cautériseraient',
    'cautionnassions',
    'cautionneraient',
    'cavalcadassions',
    'cavalcaderaient',
    'centralisassent',
    'centralisassiez',
    'centralisateurs',
    'centralisations',
    'centralisatrice',
    'centraliserions',
    'centrifugations',
    'centrifugeaient',
    'centrifugeasses',
    'centrifugerions',
    'cérébro-spinale',
    'cérébro-spinaux',
    'chaleureusement',
    'challengeassent',
    'challengeassiez',
    'challengeraient',
    'chamaillassions',
    'chamailleraient',
    'chambardassions',
    'chambarderaient',
    'chamboulassions',
    'chambouleraient',
    'champagnisaient',
    'champagnisasses',
    'champagnisation',
    'champagniserais',
    'champagniserait',
    'champagnisèrent',
    'champagniseriez',
    'champagniserons',
    'champagniseront',
    'champignonnière',
    'champignonniste',
    'champlevassions',
    'champlèveraient',
    'chancelleraient',
    'chanfreinassent',
    'chanfreinassiez',
    'chanfreinerions',
    'chansonnassions',
    'chansonneraient',
    'chanstiquassent',
    'chanstiquassiez',
    'chanstiquerions',
    'chantonnassions',
    'chantonneraient',
    'chantournassent',
    'chantournassiez',
    'chantournerions',
    'chapeautassions',
    'chapeauteraient',
    'chaperonnassent',
    'chaperonnassiez',
    'chaperonnerions',
    'chaptalisassent',
    'chaptalisassiez',
    'chaptalisations',
    'chaptaliserions',
    'charbonnassions',
    'charbonneraient',
    'charlatanesques',
    'charpentassions',
    'charpenteraient',
    'chassés-croisés',
    'chatouillassent',
    'chatouillassiez',
    'chatouillements',
    'chatouillerions',
    'chauffe-biberon',
    'chausse-trappes',
    'chelinguassions',
    'chelingueraient',
    'chevauchassions',
    'chevaucheraient',
    'chevretteraient',
    'chevronnassions',
    'chevronneraient',
    'chiffonnassions',
    'chiffonneraient',
    'chimiothérapies',
    'chirographaires',
    'chirographiâmes',
    'chirographiasse',
    'chirographiâtes',
    'chirographierai',
    'chirographieras',
    'chirographierez',
    'chirographiions',
    'chiromanciennes',
    'chiropractrices',
    'chloramphénicol',
    'chloroformaient',
    'chloroformasses',
    'chloroformerais',
    'chloroformerait',
    'chloroformèrent',
    'chloroformeriez',
    'chloroformerons',
    'chloroformeront',
    'chlorophylliens',
    'cholécystotomie',
    'cholestérolémie',
    'chorégraphiques',
    'chouchoutassent',
    'chouchoutassiez',
    'chouchouterions',
    'christianisâmes',
    'christianisasse',
    'christianisâtes',
    'christianiserai',
    'christianiseras',
    'christianiserez',
    'christianisions',
    'chromatogrammes',
    'chromatographie',
    'chroniquassions',
    'chroniqueraient',
    'chronométraient',
    'chronométrasses',
    'chronométrerais',
    'chronométrerait',
    'chronométrèrent',
    'chronométreriez',
    'chronométrerons',
    'chronométreront',
    'chronométreuses',
    'chronométriques',
    'chryséléphantin',
    'cicatrisassions',
    'cicatriseraient',
    'cinématographes',
    'cinématographia',
    'cinématographie',
    'cinématographié',
    'cinéthéodolites',
    'cinquante-et-un',
    'cinquantenaires',
    'cinquante-trois',
    'circonlocutions',
    'circonscription',
    'circonscririons',
    'circonscrivîmes',
    'circonscrivions',
    'circonscrivisse',
    'circonscrivîtes',
    'circonspections',
    'circonstanciais',
    'circonstanciait',
    'circonstanciant',
    'circonstanciées',
    'circonstanciels',
    'circonstancient',
    'circonstanciera',
    'circonstanciiez',
    'circonstancions',
    'circonvallation',
    'circonviendrais',
    'circonviendrait',
    'circonviendriez',
    'circonviendrons',
    'circonviendront',
    'circonvinssions',
    'circonvolutions',
    'claironnassions',
    'claironneraient',
    'clandestinement',
    'claquemurassent',
    'claquemurassiez',
    'claquemurerions',
    'claquetteraient',
    'classifiassions',
    'classificateurs',
    'classifications',
    'classificatoire',
    'classificatrice',
    'classifieraient',
    'claudiquassions',
    'claudiqueraient',
    'claustrophobies',
    'climatisassions',
    'climatiseraient',
    'climatologiques',
    'climatothérapie',
    'clinorhombiques',
    'cliquetteraient',
    'clitoridectomie',
    'clochardisaient',
    'clochardisasses',
    'clochardisation',
    'clochardiserais',
    'clochardiserait',
    'clochardisèrent',
    'clochardiseriez',
    'clochardiserons',
    'clochardiseront',
    'cloisonnassions',
    'cloisonneraient',
    'cobelligérantes',
    'cocontractantes',
    'codemanderesses',
    'colin-maillards',
    'collaborassions',
    'collaboratrices',
    'collaboreraient',
    'collationnaient',
    'collationnasses',
    'collationnement',
    'collationnerais',
    'collationnerait',
    'collationnèrent',
    'collationneriez',
    'collationnerons',
    'collationneront',
    'collectionnâmes',
    'collectionnasse',
    'collectionnâtes',
    'collectionnerai',
    'collectionneras',
    'collectionnerez',
    'collectionneurs',
    'collectionneuse',
    'collectionnions',
    'collectivisâmes',
    'collectivisasse',
    'collectivisâtes',
    'collectiviserai',
    'collectiviseras',
    'collectiviserez',
    'collectivisions',
    'combustibilités',
    'commanditassent',
    'commanditassiez',
    'commanditerions',
    'commémorassions',
    'commémoreraient',
    'commercialement',
    'commercialisais',
    'commercialisait',
    'commercialisant',
    'commercialisées',
    'commercialisent',
    'commercialisera',
    'commercialisiez',
    'commercialisons',
    'commissionnaire',
    'commissionnâmes',
    'commissionnasse',
    'commissionnâtes',
    'commissionnerai',
    'commissionneras',
    'commissionnerez',
    'commissionnions',
    'commotionnaient',
    'commotionnasses',
    'commotionnerais',
    'commotionnerait',
    'commotionnèrent',
    'commotionneriez',
    'commotionnerons',
    'commotionneront',
    'communalisaient',
    'communaliserais',
    'communaliserait',
    'communaliseriez',
    'communaliserons',
    'communaliseront',
    'communicatrices',
    'communiquassent',
    'communiquassiez',
    'communiquerions',
    'comparaissaient',
    'comparaîtraient',
    'comparativement',
    'compartimentage',
    'compartimentais',
    'compartimentait',
    'compartimentant',
    'compartimentées',
    'compartimentent',
    'compartimentera',
    'compartimentiez',
    'compartimentons',
    'complémentaires',
    'complémentarité',
    'complexifiaient',
    'complexifiasses',
    'complexifierais',
    'complexifierait',
    'complexifièrent',
    'complexifieriez',
    'complexifierons',
    'complexifieront',
    'complimentaient',
    'complimentasses',
    'complimenterais',
    'complimenterait',
    'complimentèrent',
    'complimenteriez',
    'complimenterons',
    'complimenteront',
    'complimenteuses',
    'compliquassions',
    'compliqueraient',
    'comportementale',
    'comportementaux',
    'composerassions',
    'compréhensibles',
    'compressassions',
    'compresseraient',
    'compressibilité',
    'compromettaient',
    'compromettantes',
    'compromettrions',
    'compromissoires',
    'comptabilisâmes',
    'comptabilisasse',
    'comptabilisâtes',
    'comptabiliserai',
    'comptabiliseras',
    'comptabiliserez',
    'comptabilisions',
    'comptes-chèques',
    'concaténassions',
    'concaténeraient',
    'concélébrassent',
    'concélébrassiez',
    'concélébrerions',
    'concentrassions',
    'concentreraient',
    'conceptualisais',
    'conceptualisait',
    'conceptualisant',
    'conceptualisées',
    'conceptualisent',
    'conceptualisera',
    'conceptualisiez',
    'conceptualismes',
    'conceptualisons',
    'concessionnaire',
    'conchyliculture',
    'conchyliologies',
    'concrétisassent',
    'concrétisassiez',
    'concrétisations',
    'concrétiserions',
    'concurrençaient',
    'concurrençasses',
    'concurrencerais',
    'concurrencerait',
    'concurrencèrent',
    'concurrenceriez',
    'concurrencerons',
    'concurrenceront',
    'concurrentielle',
    'concussionnaire',
    'condescendaient',
    'condescendances',
    'condescendantes',
    'condescendirent',
    'condescendisses',
    'condescendrions',
    'conditionnaient',
    'conditionnasses',
    'conditionnelles',
    'conditionnement',
    'conditionnerais',
    'conditionnerait',
    'conditionnèrent',
    'conditionneriez',
    'conditionnerons',
    'conditionneront',
    'conditionneuses',
    'conductibilités',
    'confectionnâmes',
    'confectionnasse',
    'confectionnâtes',
    'confectionnerai',
    'confectionneras',
    'confectionnerez',
    'confectionneurs',
    'confectionneuse',
    'confectionnions',
    'confédérassions',
    'confédéreraient',
    'confessionnelle',
    'confidentialité',
    'confidentielles',
    'configurassions',
    'configureraient',
    'confisquassions',
    'confisqueraient',
    'confortablement',
    'confraternelles',
    'confrontassions',
    'confronteraient',
    'confusionnelles',
    'confusionnismes',
    'confusionnistes',
    'congestionnâmes',
    'congestionnasse',
    'congestionnâtes',
    'congestionnerai',
    'congestionneras',
    'congestionnerez',
    'congestionnions',
    'conglomérassent',
    'conglomérassiez',
    'conglomérerions',
    'conglutinassent',
    'conglutinassiez',
    'conglutinations',
    'conglutinerions',
    'congratulassent',
    'congratulassiez',
    'congratulations',
    'congratulerions',
    'conjecturassent',
    'conjecturassiez',
    'conjecturerions',
    'conjoignissions',
    'conjoncturelles',
    'conpressassions',
    'conpresseraient',
    'consciencieuses',
    'consécutivement',
    'conseillassions',
    'conseilleraient',
    'considérassions',
    'considéreraient',
    'consolidassions',
    'consolideraient',
    'constellassions',
    'constelleraient',
    'consternassions',
    'consterneraient',
    'constituassions',
    'constitueraient',
    'constitutionnel',
    'construisissent',
    'construisissiez',
    'consubstantiels',
    'contagionnaient',
    'contagionnasses',
    'contagionnerais',
    'contagionnerait',
    'contagionnèrent',
    'contagionneriez',
    'contagionnerons',
    'contagionneront',
    'containerisâmes',
    'containerisasse',
    'containerisâtes',
    'containeriserai',
    'containeriseras',
    'containeriserez',
    'containerisions',
    'contaminassions',
    'contamineraient',
    'contemplassions',
    'contemplatrices',
    'contempleraient',
    'conteneurisâmes',
    'conteneurisasse',
    'conteneurisâtes',
    'conteneuriserai',
    'conteneuriseras',
    'conteneuriserez',
    'conteneurisions',
    'continentalités',
    'contingentaient',
    'contingentasses',
    'contingentement',
    'contingenterais',
    'contingenterait',
    'contingentèrent',
    'contingenteriez',
    'contingenterons',
    'contingenteront',
    'continuellement',
    'contorsionnâmes',
    'contorsionnasse',
    'contorsionnâtes',
    'contorsionnerai',
    'contorsionneras',
    'contorsionnerez',
    'contorsionnions',
    'contorsionniste',
    'contournassions',
    'contourneraient',
    'contractassions',
    'contracteraient',
    'contractualisai',
    'contractualisas',
    'contractualisât',
    'contractualisée',
    'contractualiser',
    'contractualises',
    'contractualisés',
    'contractualisez',
    'contracturaient',
    'contracturasses',
    'contracturerais',
    'contracturerait',
    'contracturèrent',
    'contractureriez',
    'contracturerons',
    'contractureront',
    'contradictoires',
    'contraignissent',
    'contraignissiez',
    'contraindraient',
    'contrapontistes',
    'contraposassent',
    'contraposassiez',
    'contraposerions',
    'contrapuntiques',
    'contrapuntistes',
    'contrariassions',
    'contrarieraient',
    'contrarotatives',
    'contrastassions',
    'contrasteraient',
    'contre-attaquai',
    'contre-attaquas',
    'contre-attaquât',
    'contre-attaquée',
    'contre-attaquer',
    'contre-attaques',
    'contre-attaqués',
    'contre-attaquez',
    'contrebalançais',
    'contrebalançait',
    'contrebalançant',
    'contrebalancées',
    'contrebalancent',
    'contrebalancera',
    'contrebalanciez',
    'contrebalançons',
    'contrebassistes',
    'contrebattaient',
    'contrebatteries',
    'contrebattirent',
    'contrebattisses',
    'contrebattrions',
    'contreboutaient',
    'contreboutasses',
    'contrebouterais',
    'contrebouterait',
    'contreboutèrent',
    'contrebouteriez',
    'contrebouterons',
    'contrebouteront',
    'contre-butaient',
    'contrebutassent',
    'contre-butasses',
    'contrebutassiez',
    'contre-buterais',
    'contre-buterait',
    'contre-butèrent',
    'contre-buteriez',
    'contrebuterions',
    'contre-buterons',
    'contre-buteront',
    'contrecarraient',
    'contrecarrasses',
    'contrecarrerais',
    'contrecarrerait',
    'contrecarrèrent',
    'contrecarreriez',
    'contrecarrerons',
    'contrecarreront',
    'contre-cautions',
    'contre-courants',
    'contre-enquêtes',
    'contre-épreuves',
    'contre-exemples',
    'contrefactrices',
    'contrefaisaient',
    'contrefichaient',
    'contrefichasses',
    'contreficherais',
    'contreficherait',
    'contrefichèrent',
    'contreficheriez',
    'contreficherons',
    'contreficheront',
    'contrefoutaient',
    'contrefoutrions',
    'contre-hermines',
    'contre-indiquai',
    'contre-indiquas',
    'contre-indiquât',
    'contre-indiquée',
    'contre-indiquer',
    'contre-indiques',
    'contre-indiqués',
    'contre-indiquez',
    'contremaîtresse',
    'contremandaient',
    'contremandasses',
    'contremanderais',
    'contremanderait',
    'contremandèrent',
    'contremanderiez',
    'contremanderons',
    'contremanderont',
    'contremarquâmes',
    'contremarquasse',
    'contremarquâtes',
    'contremarquerai',
    'contremarqueras',
    'contremarquerez',
    'contremarquions',
    'contre-minaient',
    'contre-minasses',
    'contre-minerais',
    'contre-minerait',
    'contre-minèrent',
    'contre-mineriez',
    'contre-minerons',
    'contre-mineront',
    'contre-muraient',
    'contre-murasses',
    'contre-murerais',
    'contre-murerait',
    'contre-murèrent',
    'contre-mureriez',
    'contre-murerons',
    'contre-mureront',
    'contre-passâmes',
    'contre-passasse',
    'contre-passâtes',
    'contre-passerai',
    'contre-passeras',
    'contre-passerez',
    'contre-passions',
    'contre-placages',
    'contre-plaquais',
    'contre-plaquait',
    'contre-plaquant',
    'contre-plaquées',
    'contre-plaquent',
    'contre-plaquera',
    'contre-plaquiez',
    'contre-plaquons',
    'contre-plongées',
    'contre-réformes',
    'contre-scellais',
    'contre-scellait',
    'contre-scellant',
    'contre-scellées',
    'contre-scellent',
    'contre-scellera',
    'contre-scelliez',
    'contre-scellons',
    'contresignaient',
    'contresignasses',
    'contresignature',
    'contresignerais',
    'contresignerait',
    'contresignèrent',
    'contresigneriez',
    'contresignerons',
    'contresigneront',
    'contre-tiraient',
    'contre-tirasses',
    'contre-tirerais',
    'contre-tirerait',
    'contre-tirèrent',
    'contre-tireriez',
    'contre-tirerons',
    'contre-tireront',
    'contreviendrais',
    'contreviendrait',
    'contreviendriez',
    'contreviendrons',
    'contreviendront',
    'contrevinssions',
    'contribuassions',
    'contribueraient',
    'contristassions',
    'contristeraient',
    'controlatérales',
    'controuvassions',
    'controuveraient',
    'controversables',
    'controversaient',
    'controversasses',
    'controverserais',
    'controverserait',
    'controversèrent',
    'controverseriez',
    'controverserons',
    'controverseront',
    'controversistes',
    'contusionnaient',
    'contusionnasses',
    'contusionnerais',
    'contusionnerait',
    'contusionnèrent',
    'contusionneriez',
    'contusionnerons',
    'contusionneront',
    'convainquissent',
    'convainquissiez',
    'conventionnâmes',
    'conventionnasse',
    'conventionnâtes',
    'conventionnelle',
    'conventionnerai',
    'conventionneras',
    'conventionnerez',
    'conventionnions',
    'convergeassions',
    'conversationnel',
    'convertibilités',
    'convertissaient',
    'convulsionnâmes',
    'convulsionnasse',
    'convulsionnâtes',
    'convulsionnerai',
    'convulsionneras',
    'convulsionnerez',
    'convulsionnions',
    'coordonnassions',
    'coordonnatrices',
    'coordonneraient',
    'copermutassions',
    'copermuteraient',
    'copposédassions',
    'copposéderaient',
    'copropriétaires',
    'coreligionnaire',
    'correctionnelle',
    'corrélativement',
    'correspondaient',
    'correspondances',
    'correspondantes',
    'correspondirent',
    'correspondisses',
    'correspondrions',
    'corroborassions',
    'corroboreraient',
    'cosmétiquassent',
    'cosmétiquassiez',
    'cosmétiquerions',
    'cosmographiques',
    'cosmopolitismes',
    'couchaillassent',
    'couchaillassiez',
    'couchaillerions',
    'couillonnassent',
    'couillonnassiez',
    'couillonnerions',
    'coupaillassions',
    'coupailleraient',
    'couraillassions',
    'courailleraient',
    'courbaturassent',
    'courbaturassiez',
    'courbaturerions',
    'courrouçassions',
    'courrouceraient',
    'courtaudassions',
    'courtauderaient',
    'court-circuitai',
    'court-circuitas',
    'court-circuitât',
    'court-circuitée',
    'court-circuiter',
    'court-circuites',
    'court-circuités',
    'court-circuitez',
    'court-courriers',
    'courts-circuits',
    'crachouillaient',
    'crachouillasses',
    'crachouillerais',
    'crachouillerait',
    'crachouillèrent',
    'crachouilleriez',
    'crachouillerons',
    'crachouilleront',
    'cramponnassions',
    'cramponneraient',
    'crapahutassions',
    'crapahuteraient',
    'craquelleraient',
    'craquetteraient',
    'crétinisassions',
    'crétiniseraient',
    'criminalisaient',
    'criminalisasses',
    'criminaliserais',
    'criminaliserait',
    'criminalisèrent',
    'criminaliseriez',
    'criminaliserons',
    'criminaliseront',
    'criminologistes',
    'cristallisaient',
    'cristallisantes',
    'cristallisasses',
    'cristallisation',
    'cristalliserais',
    'cristalliserait',
    'cristallisèrent',
    'cristalliseriez',
    'cristalliserons',
    'cristalliseront',
    'criticaillaient',
    'criticaillasses',
    'criticaillerais',
    'criticaillerait',
    'criticaillèrent',
    'criticailleriez',
    'criticaillerons',
    'criticailleront',
    'croque-monsieur',
    'cross-countries',
    'croustillassent',
    'croustillassiez',
    'croustillerions',
    'culpabilisaient',
    'culpabilisantes',
    'culpabilisasses',
    'culpabilisation',
    'culpabiliserais',
    'culpabiliserait',
    'culpabilisèrent',
    'culpabiliseriez',
    'culpabiliserons',
    'culpabiliseront',
    'cuniculiculture',
    'cybernéticienne',
    'cyclomotoristes',
    'cytodiagnostics',
    'cytogénéticiens',
    'dactylographiai',
    'dactylographias',
    'dactylographiât',
    'dactylographiée',
    'dactylographier',
    'dactylographies',
    'dactylographiés',
    'dactylographiez',
    'damasquinassent',
    'damasquinassiez',
    'damasquinerions',
    'débagoulassions',
    'débagouleraient',
    'débâillonnaient',
    'débâillonnasses',
    'débâillonnerais',
    'débâillonnerait',
    'débâillonnèrent',
    'débâillonneriez',
    'débâillonnerons',
    'débâillonneront',
    'déballonnassent',
    'déballonnassiez',
    'déballonnerions',
    'débalourdassent',
    'débalourdassiez',
    'débalourderions',
    'débanalisassent',
    'débanalisassiez',
    'débanaliserions',
    'débaptisassions',
    'débaptiseraient',
    'débarbouillages',
    'débarbouillâmes',
    'débarbouillasse',
    'débarbouillâtes',
    'débarbouillerai',
    'débarbouilleras',
    'débarbouillerez',
    'débarbouillette',
    'débarbouillions',
    'débarrassassent',
    'débarrassassiez',
    'débarrasserions',
    'débecquetassent',
    'débecquetassiez',
    'débecquetterais',
    'débecquetterait',
    'débecquetteriez',
    'débecquetterons',
    'débecquetteront',
    'débenzolassions',
    'débenzoleraient',
    'débillardassent',
    'débillardassiez',
    'débillarderions',
    'déblatérassions',
    'déblatéreraient',
    'débosselassions',
    'débossellerions',
    'débouilliraient',
    'débouillissions',
    'déboulonnassent',
    'déboulonnassiez',
    'déboulonnements',
    'déboulonnerions',
    'déboussolassent',
    'déboussolassiez',
    'déboussolerions',
    'déboutonnassent',
    'déboutonnassiez',
    'déboutonnerions',
    'débraguettaient',
    'débraguettasses',
    'débraguetterais',
    'débraguetterait',
    'débraguettèrent',
    'débraguetteriez',
    'débraguetterons',
    'débraguetteront',
    'débraillassions',
    'débrailleraient',
    'débranchassions',
    'débrancheraient',
    'débrouillardise',
    'débrouillassent',
    'débrouillassiez',
    'débrouillements',
    'débrouillerions',
    'débroussaillais',
    'débroussaillait',
    'débroussaillant',
    'débroussaillées',
    'débroussaillent',
    'débroussaillera',
    'débroussailliez',
    'débroussaillons',
    'débudgétisaient',
    'débudgétisasses',
    'débudgétiserais',
    'débudgétiserait',
    'débudgétisèrent',
    'débudgétiseriez',
    'débudgétiserons',
    'débudgétiseront',
    'débureaucratisa',
    'débureaucratise',
    'débureaucratisé',
    'décachetassions',
    'décachetterions',
    'décadenassaient',
    'décadenassasses',
    'décadenasserais',
    'décadenasserait',
    'décadenassèrent',
    'décadenasseriez',
    'décadenasserons',
    'décadenasseront',
    'décaféinassions',
    'décaféineraient',
    'décalaminassent',
    'décalaminassiez',
    'décalaminerions',
    'décalcifiassent',
    'décalcifiassiez',
    'décalcification',
    'décalcifierions',
    'décalottassions',
    'décalotteraient',
    'décanillassions',
    'décanilleraient',
    'décapelleraient',
    'décapitalisâmes',
    'décapitalisasse',
    'décapitalisâtes',
    'décapitaliserai',
    'décapitaliseras',
    'décapitaliserez',
    'décapitalisions',
    'décapsulassions',
    'décapsuleraient',
    'décapuchonnâmes',
    'décapuchonnasse',
    'décapuchonnâtes',
    'décapuchonnerai',
    'décapuchonneras',
    'décapuchonnerez',
    'décapuchonnions',
    'décarbonataient',
    'décarbonatasses',
    'décarbonaterais',
    'décarbonaterait',
    'décarbonatèrent',
    'décarbonateriez',
    'décarbonaterons',
    'décarbonateront',
    'décarburassions',
    'décarbureraient',
    'décarcassassent',
    'décarcassassiez',
    'décarcasserions',
    'décarrelassions',
    'décarrellerions',
    'décartonnassent',
    'décartonnassiez',
    'décartonnerions',
    'décasyllabiques',
    'décathloniennes',
    'décentralisâmes',
    'décentralisasse',
    'décentralisâtes',
    'décentraliserai',
    'décentraliseras',
    'décentraliserez',
    'décentralisions',
    'déchagrinassent',
    'déchagrinassiez',
    'déchagrinerions',
    'déchaperonnâmes',
    'déchaperonnasse',
    'déchaperonnâtes',
    'déchaperonnerai',
    'déchaperonneras',
    'déchaperonnerez',
    'déchaperonnions',
    'déchargeassions',
    'déchaussassions',
    'déchausseraient',
    'déchevêtrassent',
    'déchevêtrassiez',
    'déchevêtrerions',
    'déchevillassent',
    'déchevillassiez',
    'déchevillerions',
    'déchiffonnaient',
    'déchiffonnasses',
    'déchiffonnerais',
    'déchiffonnerait',
    'déchiffonnèrent',
    'déchiffonneriez',
    'déchiffonnerons',
    'déchiffonneront',
    'déchiffrassions',
    'déchiffreraient',
    'déchiquetassent',
    'déchiquetassiez',
    'déchiquetterais',
    'déchiquetterait',
    'déchiquetteriez',
    'déchiquetterons',
    'déchiquetteront',
    'déchlorurassent',
    'déchlorurassiez',
    'déchlorurerions',
    'déchristianisai',
    'déchristianisas',
    'déchristianisât',
    'déchristianisée',
    'déchristianiser',
    'déchristianises',
    'déchristianisés',
    'déchristianisez',
    'décimalisassent',
    'décimalisassiez',
    'décimalisations',
    'décimaliserions',
    'décintrerassent',
    'décintrerassiez',
    'déclavetassions',
    'déclavetterions',
    'déclenchassions',
    'déclencheraient',
    'décléricalisais',
    'décléricalisait',
    'décléricalisant',
    'décléricalisées',
    'décléricalisent',
    'décléricalisera',
    'décléricalisiez',
    'décléricalisons',
    'décliquetassent',
    'décliquetassiez',
    'décliquetterais',
    'décliquetterait',
    'décliquetteriez',
    'décliquetterons',
    'décliquetteront',
    'décloisonnaient',
    'décloisonnasses',
    'décloisonnerais',
    'décloisonnerait',
    'décloisonnèrent',
    'décloisonneriez',
    'décloisonnerons',
    'décloisonneront',
    'décolletassions',
    'décolletterions',
    'décolonisassent',
    'décolonisassiez',
    'décolonisations',
    'décoloniserions',
    'décommandassent',
    'décommandassiez',
    'décommanderions',
    'décompensations',
    'décomplexassent',
    'décomplexassiez',
    'décomplexerions',
    'décomposassions',
    'décomposeraient',
    'décompressaient',
    'décompressasses',
    'décompresserais',
    'décompresserait',
    'décompressèrent',
    'décompresseriez',
    'décompresserons',
    'décompresseront',
    'décomprimassent',
    'décomprimassiez',
    'décomprimerions',
    'déconcentraient',
    'déconcentrasses',
    'déconcentration',
    'déconcentrerais',
    'déconcentrerait',
    'déconcentrèrent',
    'déconcentreriez',
    'déconcentrerons',
    'déconcentreront',
    'déconcertassent',
    'déconcertassiez',
    'déconcerterions',
    'déconditionnais',
    'déconditionnait',
    'déconditionnant',
    'déconditionnées',
    'déconditionnent',
    'déconditionnera',
    'déconditionniez',
    'déconditionnons',
    'décongelassions',
    'décongèleraient',
    'décongestionnai',
    'décongestionnas',
    'décongestionnât',
    'décongestionnée',
    'décongestionner',
    'décongestionnes',
    'décongestionnés',
    'décongestionnez',
    'déconnectassent',
    'déconnectassiez',
    'déconnecterions',
    'déconseillaient',
    'déconseillasses',
    'déconseillerais',
    'déconseillerait',
    'déconseillèrent',
    'déconseilleriez',
    'déconseillerons',
    'déconseilleront',
    'déconsidéraient',
    'déconsidérasses',
    'déconsidération',
    'déconsidérerais',
    'déconsidérerait',
    'déconsidérèrent',
    'déconsidéreriez',
    'déconsidérerons',
    'déconsidéreront',
    'déconsignassent',
    'déconsignassiez',
    'déconsignerions',
    'déconstipassent',
    'déconstipassiez',
    'déconstiperions',
    'décontaminaient',
    'décontaminasses',
    'décontamination',
    'décontaminerais',
    'décontaminerait',
    'décontaminèrent',
    'décontamineriez',
    'décontaminerons',
    'décontamineront',
    'décontenançâmes',
    'décontenançasse',
    'décontenançâtes',
    'décontenancerai',
    'décontenanceras',
    'décontenancerez',
    'décontenancions',
    'décontractaient',
    'décontractasses',
    'décontracterais',
    'décontracterait',
    'décontractèrent',
    'décontracteriez',
    'décontracterons',
    'décontracteront',
    'décortiquassent',
    'décortiquassiez',
    'décortiquerions',
    'décourageassent',
    'décourageassiez',
    'décourageraient',
    'découronnassent',
    'découronnassiez',
    'découronnements',
    'découronnerions',
    'décréditassions',
    'décréditeraient',
    'décrépitassions',
    'décrépiteraient',
    'décriminalisais',
    'décriminalisait',
    'décriminalisant',
    'décriminalisées',
    'décriminalisent',
    'décriminalisera',
    'décriminalisiez',
    'décriminalisons',
    'déculassassions',
    'déculasseraient',
    'déculottassions',
    'déculotteraient',
    'déculpabilisais',
    'déculpabilisait',
    'déculpabilisant',
    'déculpabilisées',
    'déculpabilisent',
    'déculpabilisera',
    'déculpabilisiez',
    'déculpabilisons',
    'dédaigneusement',
    'dédifférenciais',
    'dédifférenciait',
    'dédifférenciant',
    'dédifférenciées',
    'dédifférencient',
    'dédifférenciera',
    'dédifférenciiez',
    'dédifférencions',
    'dédommageassent',
    'dédommageassiez',
    'dédommageraient',
    'dédramatisaient',
    'dédramatisasses',
    'dédramatiserais',
    'dédramatiserait',
    'dédramatisèrent',
    'dédramatiseriez',
    'dédramatiserons',
    'dédramatiseront',
    'défatiguassions',
    'défatigueraient',
    'défaufilassions',
    'défaufileraient',
    'défavorablement',
    'défavorisassent',
    'défavorisassiez',
    'défavoriserions',
    'défectueusement',
    'défenestrations',
    'déferrisassions',
    'déferriseraient',
    'défeuillassions',
    'défeuilleraient',
    'défibrillateurs',
    'défibrillations',
    'déficelleraient',
    'définitionnelle',
    'déflationnistes',
    'défleurissaient',
    'défouraillaient',
    'défouraillasses',
    'défouraillerais',
    'défouraillerait',
    'défouraillèrent',
    'défourailleriez',
    'défouraillerons',
    'défourailleront',
    'défraîchiraient',
    'défraîchissions',
    'défrancisassent',
    'défrancisassiez',
    'défranciserions',
    'défringuassions',
    'défringueraient',
    'défroissassions',
    'défroisseraient',
    'dégalonnassions',
    'dégalonneraient',
    'dégasolinassent',
    'dégasolinassiez',
    'dégasolinerions',
    'dégauchissaient',
    'dégauchissement',
    'dégauchisseuses',
    'dégazolinassent',
    'dégazolinassiez',
    'dégazolinerions',
    'dégazonnassions',
    'dégazonneraient',
    'dégénérescences',
    'dégingandassent',
    'dégingandassiez',
    'déginganderions',
    'déglinguassions',
    'déglingueraient',
    'dégobillassions',
    'dégobilleraient',
    'dégoudronnaient',
    'dégoudronnasses',
    'dégoudronnerais',
    'dégoudronnerait',
    'dégoudronnèrent',
    'dégoudronneriez',
    'dégoudronnerons',
    'dégoudronneront',
    'dégoulinassions',
    'dégoulineraient',
    'dégoupillassent',
    'dégoupillassiez',
    'dégoupillerions',
    'dégourdissaient',
    'dégourdissantes',
    'dégourdissement',
    'dégraissassions',
    'dégraisseraient',
    'dégravoieraient',
    'dégravoyassions',
    'dégringolassent',
    'dégringolassiez',
    'dégringolerions',
    'dégrossissaient',
    'dégrossissement',
    'dégrouillassent',
    'dégrouillassiez',
    'dégrouillerions',
    'déguerpissaient',
    'dégurgitassions',
    'dégurgiteraient',
    'déharnachassent',
    'déharnachassiez',
    'déharnacherions',
    'déhouillassions',
    'déhouilleraient',
    'délabialisaient',
    'délabialisasses',
    'délabialiserais',
    'délabialiserait',
    'délabialisèrent',
    'délabialiseriez',
    'délabialiserons',
    'délabialiseront',
    'délignifiassent',
    'délignifiassiez',
    'délignifierions',
    'démaçonnassions',
    'démaçonneraient',
    'démagnétisaient',
    'démagnétisasses',
    'démagnétisation',
    'démagnétiserais',
    'démagnétiserait',
    'démagnétisèrent',
    'démagnétiseriez',
    'démagnétiserons',
    'démagnétiseront',
    'démaigrissaient',
    'démaigrissement',
    'démaillotassent',
    'démaillotassiez',
    'démailloterions',
    'démantelassions',
    'démantèleraient',
    'démantibulaient',
    'démantibulasses',
    'démantibulerais',
    'démantibulerait',
    'démantibulèrent',
    'démantibuleriez',
    'démantibulerons',
    'démantibuleront',
    'démaquillassent',
    'démaquillassiez',
    'démaquillerions',
    'démastiquassent',
    'démastiquassiez',
    'démastiquerions',
    'dématérialisais',
    'dématérialisait',
    'dématérialisant',
    'dématérialisées',
    'dématérialisent',
    'dématérialisera',
    'dématérialisiez',
    'dématérialisons',
    'démazoutassions',
    'démazouteraient',
    'déménageassions',
    'déméthanisaient',
    'déméthanisasses',
    'déméthaniserais',
    'déméthaniserait',
    'déméthanisèrent',
    'déméthaniseriez',
    'déméthaniserons',
    'déméthaniseront',
    'demi-bouteilles',
    'demi-circulaire',
    'demi-finalistes',
    'démilitarisâmes',
    'démilitarisasse',
    'démilitarisâtes',
    'démilitariserai',
    'démilitariseras',
    'démilitariserez',
    'démilitarisions',
    'déminéralisâmes',
    'déminéralisasse',
    'déminéralisâtes',
    'déminéraliserai',
    'déminéraliseras',
    'déminéraliserez',
    'déminéralisions',
    'démissionnaient',
    'démissionnaires',
    'démissionnasses',
    'démissionnerais',
    'démissionnerait',
    'démissionnèrent',
    'démissionneriez',
    'démissionnerons',
    'démissionneront',
    'démobilisassent',
    'démobilisassiez',
    'démobilisateurs',
    'démobilisations',
    'démobilisatrice',
    'démobiliserions',
    'démocratisaient',
    'démocratisasses',
    'démocratisation',
    'démocratiserais',
    'démocratiserait',
    'démocratisèrent',
    'démocratiseriez',
    'démocratiserons',
    'démocratiseront',
    'démonétisassent',
    'démonétisassiez',
    'démonétisations',
    'démonétiserions',
    'démonstratrices',
    'démoralisassent',
    'démoralisassiez',
    'démoralisateurs',
    'démoralisations',
    'démoralisatrice',
    'démoraliserions',
    'démouchetassent',
    'démouchetassiez',
    'démouchetterais',
    'démouchetterait',
    'démouchetteriez',
    'démouchetterons',
    'démouchetteront',
    'démouscaillâmes',
    'démouscaillasse',
    'démouscaillâtes',
    'démouscaillerai',
    'démouscailleras',
    'démouscaillerez',
    'démouscaillions',
    'démoustiquaient',
    'démoustiquasses',
    'démoustiquerais',
    'démoustiquerait',
    'démoustiquèrent',
    'démoustiqueriez',
    'démoustiquerons',
    'démoustiqueront',
    'démultipliaient',
    'démultipliasses',
    'démultiplierais',
    'démultiplierait',
    'démultiplièrent',
    'démultiplieriez',
    'démultiplierons',
    'démultiplieront',
    'démuselleraient',
    'démystifiassent',
    'démystifiassiez',
    'démystificateur',
    'démystification',
    'démystifierions',
    'démythifiassent',
    'démythifiassiez',
    'démythification',
    'démythifierions',
    'dénasalisassent',
    'dénasalisassiez',
    'dénasalisations',
    'dénasaliserions',
    'dénationalisais',
    'dénationalisait',
    'dénationalisant',
    'dénationalisées',
    'dénationalisent',
    'dénationalisera',
    'dénationalisiez',
    'dénationalisons',
    'dénaturalisâmes',
    'dénaturalisasse',
    'dénaturalisâtes',
    'dénaturaliserai',
    'dénaturaliseras',
    'dénaturaliserez',
    'dénaturalisions',
    'dénébulisairent',
    'dénébulisaisses',
    'dénébulisassent',
    'dénébulisassiez',
    'dénébuliserions',
    'dénickelassions',
    'dénickeleraient',
    'dénicotinisâmes',
    'dénicotinisasse',
    'dénicotinisâtes',
    'dénicotiniserai',
    'dénicotiniseras',
    'dénicotiniserez',
    'dénicotiniseurs',
    'dénicotinisions',
    'dénitrifiassent',
    'dénitrifiassiez',
    'dénitrification',
    'dénitrifierions',
    'dénivelleraient',
    'dénotationnelle',
    'dénoyautassions',
    'dénoyauteraient',
    'dénucléarisâmes',
    'dénucléarisasse',
    'dénucléarisâtes',
    'dénucléariserai',
    'dénucléariseras',
    'dénucléariserez',
    'dénucléarisions',
    'dépaisselassent',
    'dépaisselassiez',
    'dépaissellerais',
    'dépaissellerait',
    'dépaisselleriez',
    'dépaissellerons',
    'dépaisselleront',
    'dépalissassions',
    'dépalisseraient',
    'dépaquetassions',
    'dépaquetterions',
    'déparaffinaient',
    'déparaffinasses',
    'déparaffinerais',
    'déparaffinerait',
    'déparaffinèrent',
    'déparaffineriez',
    'déparaffinerons',
    'déparaffineront',
    'déparasitassent',
    'déparasitassiez',
    'déparasiterions',
    'dépareillassent',
    'dépareillassiez',
    'dépareillerions',
    'départageassent',
    'départageassiez',
    'départageraient',
    'départementales',
    'dépassionnaient',
    'dépassionnasses',
    'dépassionnerais',
    'dépassionnerait',
    'dépassionnèrent',
    'dépassionneriez',
    'dépassionnerons',
    'dépassionneront',
    'dépatouillaient',
    'dépatouillasses',
    'dépatouillerais',
    'dépatouillerait',
    'dépatouillèrent',
    'dépatouilleriez',
    'dépatouillerons',
    'dépatouilleront',
    'dépelotonnaient',
    'dépelotonnasses',
    'dépelotonnerais',
    'dépelotonnerait',
    'dépelotonnèrent',
    'dépelotonneriez',
    'dépelotonnerons',
    'dépelotonneront',
    'dépersonnalisai',
    'dépersonnalisas',
    'dépersonnalisât',
    'dépersonnalisée',
    'dépersonnaliser',
    'dépersonnalises',
    'dépersonnalisés',
    'dépersonnalisez',
    'déphosphoration',
    'déplafonnassent',
    'déplafonnassiez',
    'déplafonnements',
    'déplafonnerions',
    'déplanquassions',
    'déplanqueraient',
    'dépoétisassions',
    'dépoétiseraient',
    'dépolarisassent',
    'dépolarisassiez',
    'dépolariserions',
    'dépolitisassent',
    'dépolitisassiez',
    'dépolitisations',
    'dépolitiserions',
    'dépolymérisâmes',
    'dépolymérisasse',
    'dépolymérisâtes',
    'dépolymériserai',
    'dépolymériseras',
    'dépolymériserez',
    'dépolymérisions',
    'dépontillassent',
    'dépontillassiez',
    'dépontillerions',
    'dépossédassions',
    'déposséderaient',
    'dépouillassions',
    'dépouilleraient',
    'dépoussiéraient',
    'dépoussiérantes',
    'dépoussiérasses',
    'dépoussiérerais',
    'dépoussiérerait',
    'dépoussiérèrent',
    'dépoussiéreriez',
    'dépoussiérerons',
    'dépoussiéreront',
    'dépressionnaire',
    'dépressurisâmes',
    'dépressurisasse',
    'dépressurisâtes',
    'dépressuriserai',
    'dépressuriseras',
    'dépressuriserez',
    'dépressurisions',
    'déprogrammaient',
    'déprogrammasses',
    'déprogrammerais',
    'déprogrammerait',
    'déprogrammèrent',
    'déprogrammeriez',
    'déprogrammerons',
    'déprogrammeront',
    'déprolétarisais',
    'déprolétarisait',
    'déprolétarisant',
    'déprolétarisées',
    'déprolétarisent',
    'déprolétarisera',
    'déprolétarisiez',
    'déprolétarisons',
    'dépropanisaient',
    'dépropanisasses',
    'dépropaniserais',
    'dépropaniserait',
    'dépropanisèrent',
    'dépropaniseriez',
    'dépropaniserons',
    'dépropaniseront',
    'dépucelleraient',
    'déraisonnassent',
    'déraisonnassiez',
    'déraisonnerions',
    'déréalisassions',
    'déréaliseraient',
    'dermatologistes',
    'dérouillassions',
    'dérouilleraient',
    'désabonnassions',
    'désabonneraient',
    'désacclimatâmes',
    'désacclimatasse',
    'désacclimatâtes',
    'désacclimaterai',
    'désacclimateras',
    'désacclimaterez',
    'désacclimations',
    'désaccordassent',
    'désaccordassiez',
    'désaccorderions',
    'désaccouplaient',
    'désaccouplasses',
    'désaccouplerais',
    'désaccouplerait',
    'désaccouplèrent',
    'désaccoupleriez',
    'désaccouplerons',
    'désaccoupleront',
    'désaccoutumâmes',
    'désaccoutumance',
    'désaccoutumasse',
    'désaccoutumâtes',
    'désaccoutumerai',
    'désaccoutumeras',
    'désaccoutumerez',
    'désaccoutumions',
    'désacralisaient',
    'désacralisasses',
    'désacraliserais',
    'désacraliserait',
    'désacralisèrent',
    'désacraliseriez',
    'désacraliserons',
    'désacraliseront',
    'désactivassions',
    'désactiveraient',
    'désadaptassions',
    'désadapteraient',
    'désaffectassent',
    'désaffectassiez',
    'désaffectations',
    'désaffecterions',
    'désaffectionnai',
    'désaffectionnas',
    'désaffectionnât',
    'désaffectionnée',
    'désaffectionner',
    'désaffectionnes',
    'désaffectionnés',
    'désaffectionnez',
    'désaffiliassent',
    'désaffiliassiez',
    'désaffilierions',
    'désagençassions',
    'désagenceraient',
    'désagrafassions',
    'désagraferaient',
    'désagréablement',
    'désagrégeassent',
    'désagrégeassiez',
    'désagrégeraient',
    'désaimantassent',
    'désaimantassiez',
    'désaimanterions',
    'désajustassions',
    'désajusteraient',
    'désaliénassions',
    'désaliéneraient',
    'désalignassions',
    'désaligneraient',
    'désaltérassions',
    'désaltéreraient',
    'désamarrassions',
    'désamarreraient',
    'désambiguïsâmes',
    'désambiguïsasse',
    'désambiguïsâtes',
    'désambiguïserai',
    'désambiguïseras',
    'désambiguïserez',
    'désambiguïsions',
    'désamidonnaient',
    'désamidonnasses',
    'désamidonnerais',
    'désamidonnerait',
    'désamidonnèrent',
    'désamidonneriez',
    'désamidonnerons',
    'désamidonneront',
    'désamorçassions',
    'désamorceraient',
    'désannexassions',
    'désannexeraient',
    'désappariassent',
    'désappariassiez',
    'désapparierions',
    'désappointaient',
    'désappointasses',
    'désappointement',
    'désappointerais',
    'désappointerait',
    'désappointèrent',
    'désappointeriez',
    'désappointerons',
    'désappointeront',
    'désapprendrions',
    'désapprobateurs',
    'désapprobations',
    'désapprobatrice',
    'désapprouvaient',
    'désapprouvasses',
    'désapprouverais',
    'désapprouverait',
    'désapprouvèrent',
    'désapprouveriez',
    'désapprouverons',
    'désapprouveront',
    'désarçonnassent',
    'désarçonnassiez',
    'désarçonnerions',
    'désargentassent',
    'désargentassiez',
    'désargenterions',
    'désarrimassions',
    'désarrimeraient',
    'désarticulaient',
    'désarticulasses',
    'désarticulation',
    'désarticulerais',
    'désarticulerait',
    'désarticulèrent',
    'désarticuleriez',
    'désarticulerons',
    'désarticuleront',
    'désassemblaient',
    'désassemblasses',
    'désassemblerais',
    'désassemblerait',
    'désassemblèrent',
    'désassembleriez',
    'désassemblerons',
    'désassembleront',
    'désassimilaient',
    'désassimilasses',
    'désassimilation',
    'désassimilerais',
    'désassimilerait',
    'désassimilèrent',
    'désassimileriez',
    'désassimilerons',
    'désassimileront',
    'désassortiments',
    'désassortirions',
    'désassortissais',
    'désassortissait',
    'désassortissant',
    'désassortissent',
    'désassortissiez',
    'désassortissons',
    'désastreusement',
    'désatomisassent',
    'désatomisassiez',
    'désatomiserions',
    'désavantageâmes',
    'désavantageasse',
    'désavantageâtes',
    'désavantagerais',
    'désavantagerait',
    'désavantagèrent',
    'désavantageriez',
    'désavantagerons',
    'désavantageront',
    'désavantageuses',
    'désaveuglassent',
    'désaveuglassiez',
    'désaveuglerions',
    'déséchouassions',
    'déséchoueraient',
    'désembourbaient',
    'désembourbasses',
    'désembourberais',
    'désembourberait',
    'désembourbèrent',
    'désembourberiez',
    'désembourberons',
    'désembourberont',
    'désembourgeoisa',
    'désembourgeoise',
    'désembourgeoisé',
    'désembouteillai',
    'désembouteillas',
    'désembouteillât',
    'désembouteillée',
    'désembouteiller',
    'désembouteilles',
    'désembouteillés',
    'désembouteillez',
    'désembraierions',
    'désembrayassent',
    'désembrayassiez',
    'désembrayerions',
    'désemmanchaient',
    'désemmanchasses',
    'désemmancherais',
    'désemmancherait',
    'désemmanchèrent',
    'désemmancheriez',
    'désemmancherons',
    'désemmancheront',
    'désemparassions',
    'désempareraient',
    'désempesassions',
    'désempèseraient',
    'désemplissaient',
    'désemprisonnais',
    'désemprisonnait',
    'désemprisonnant',
    'désemprisonnées',
    'désemprisonnent',
    'désemprisonnera',
    'désemprisonniez',
    'désemprisonnons',
    'désencadrassent',
    'désencadrassiez',
    'désencadrerions',
    'désencartassent',
    'désencartassiez',
    'désencarterions',
    'désenchaînaient',
    'désenchaînasses',
    'désenchaînerais',
    'désenchaînerait',
    'désenchaînèrent',
    'désenchaîneriez',
    'désenchaînerons',
    'désenchaîneront',
    'désenchantaient',
    'désenchantasses',
    'désenchantement',
    'désenchanterais',
    'désenchanterait',
    'désenchantèrent',
    'désenchanteriez',
    'désenchanterons',
    'désenchanteront',
    'désenclavassent',
    'désenclavassiez',
    'désenclavements',
    'désenclaverions',
    'désencollassent',
    'désencollassiez',
    'désencollerions',
    'désencombraient',
    'désencombrasses',
    'désencombrement',
    'désencombrerais',
    'désencombrerait',
    'désencombrèrent',
    'désencombreriez',
    'désencombrerons',
    'désencombreront',
    'désencrassaient',
    'désencrassasses',
    'désencrasserais',
    'désencrasserait',
    'désencrassèrent',
    'désencrasseriez',
    'désencrasserons',
    'désencrasseront',
    'désendettassent',
    'désendettassiez',
    'désendettements',
    'désendetterions',
    'désénervassions',
    'désénerveraient',
    'désenfilassions',
    'désenfileraient',
    'désenflammaient',
    'désenflammasses',
    'désenflammerais',
    'désenflammerait',
    'désenflammèrent',
    'désenflammeriez',
    'désenflammerons',
    'désenflammeront',
    'désenfumassions',
    'désenfumeraient',
    'désengageassent',
    'désengageassiez',
    'désengageraient',
    'désengorgeaient',
    'désengorgeasses',
    'désengorgerions',
    'désengourdirais',
    'désengourdirait',
    'désengourdirent',
    'désengourdiriez',
    'désengourdirons',
    'désengourdiront',
    'désengourdisses',
    'désengourdissez',
    'désenivrassions',
    'désenivreraient',
    'désenlaçassions',
    'désenlaceraient',
    'désenlaidirions',
    'désenlaidissais',
    'désenlaidissait',
    'désenlaidissant',
    'désenlaidissent',
    'désenlaidissiez',
    'désenlaidissons',
    'désennuieraient',
    'désennuyassions',
    'désenraieraient',
    'désenrayassions',
    'désenrayeraient',
    'désenrhumassent',
    'désenrhumassiez',
    'désenrhumerions',
    'désenrouassions',
    'désenroueraient',
    'désensablassent',
    'désensablassiez',
    'désensablerions',
    'désensibilisais',
    'désensibilisait',
    'désensibilisant',
    'désensibilisées',
    'désensibilisent',
    'désensibilisera',
    'désensibilisiez',
    'désensibilisons',
    'désensorcelâmes',
    'désensorcelasse',
    'désensorcelâtes',
    'désensorcelions',
    'désensorcellent',
    'désensorcellera',
    'désentoilassent',
    'désentoilassiez',
    'désentoilerions',
    'désentortillais',
    'désentortillait',
    'désentortillant',
    'désentortillées',
    'désentortillent',
    'désentortillera',
    'désentortilliez',
    'désentortillons',
    'désentravassent',
    'désentravassiez',
    'désentraverions',
    'désenvasassions',
    'désenvaseraient',
    'désenveloppâmes',
    'désenveloppasse',
    'désenveloppâtes',
    'désenvelopperai',
    'désenvelopperas',
    'désenvelopperez',
    'désenveloppions',
    'désenvenimaient',
    'désenvenimasses',
    'désenvenimerais',
    'désenvenimerait',
    'désenvenimèrent',
    'désenvenimeriez',
    'désenvenimerons',
    'désenvenimeront',
    'désenverguaient',
    'désenverguasses',
    'désenverguerais',
    'désenverguerait',
    'désenverguèrent',
    'désenvergueriez',
    'désenverguerons',
    'désenvergueront',
    'désépaissirions',
    'désépaississais',
    'désépaississait',
    'désépaississant',
    'désépaississent',
    'désépaississiez',
    'désépaississons',
    'déséquilibrâmes',
    'déséquilibrasse',
    'déséquilibrâtes',
    'déséquilibrerai',
    'déséquilibreras',
    'déséquilibrerez',
    'déséquilibrions',
    'déséquipassions',
    'déséquiperaient',
    'désertification',
    'désespérassions',
    'désespéreraient',
    'désétabliraient',
    'désétablissions',
    'désétatisassent',
    'désétatisassiez',
    'désétatiserions',
    'déshabillassent',
    'déshabillassiez',
    'déshabillerions',
    'déshabituassent',
    'déshabituassiez',
    'déshabituerions',
    'déshéritassions',
    'déshériteraient',
    'déshonorassions',
    'déshonoreraient',
    'déshumanisaient',
    'déshumanisasses',
    'déshumanisation',
    'déshumaniserais',
    'déshumaniserait',
    'déshumanisèrent',
    'déshumaniseriez',
    'déshumaniserons',
    'déshumaniseront',
    'déshumidifiâmes',
    'déshumidifiasse',
    'déshumidifiâtes',
    'déshumidifierai',
    'déshumidifieras',
    'déshumidifierez',
    'déshumidifiions',
    'déshydratassent',
    'déshydratassiez',
    'déshydratations',
    'déshydraterions',
    'déshydrogénâmes',
    'déshydrogénasse',
    'déshydrogénâtes',
    'déshydrogénerai',
    'déshydrogéneras',
    'déshydrogénerez',
    'déshydrogénions',
    'déshypothéquais',
    'déshypothéquait',
    'déshypothéquant',
    'déshypothéquées',
    'déshypothèquent',
    'déshypothéquera',
    'déshypothéquiez',
    'déshypothéquons',
    'désidérabilités',
    'désillusionnais',
    'désillusionnait',
    'désillusionnant',
    'désillusionnées',
    'désillusionnent',
    'désillusionnera',
    'désillusionniez',
    'désillusionnons',
    'désincarnassent',
    'désincarnassiez',
    'désincarnerions',
    'désincorporâmes',
    'désincorporasse',
    'désincorporâtes',
    'désincorporerai',
    'désincorporeras',
    'désincorporerez',
    'désincorporions',
    'désincrustaient',
    'désincrustasses',
    'désincrustation',
    'désincrusterais',
    'désincrusterait',
    'désincrustèrent',
    'désincrusteriez',
    'désincrusterons',
    'désincrusteront',
    'désinculpassent',
    'désinculpassiez',
    'désinculperions',
    'désindexassions',
    'désindexeraient',
    'désinfectassent',
    'désinfectassiez',
    'désinfecterions',
    'désingularisais',
    'désingularisait',
    'désingularisant',
    'désingularisées',
    'désingularisent',
    'désingularisera',
    'désingularisiez',
    'désingularisons',
    'désinsectisâmes',
    'désinsectisasse',
    'désinsectisâtes',
    'désinsectiserai',
    'désinsectiseras',
    'désinsectiserez',
    'désinsectisions',
    'désintégrassent',
    'désintégrassiez',
    'désintégrations',
    'désintégrerions',
    'désintéressâmes',
    'désintéressasse',
    'désintéressâtes',
    'désintéresserai',
    'désintéresseras',
    'désintéresserez',
    'désintéressions',
    'désintoxication',
    'désintoxiquâmes',
    'désintoxiquasse',
    'désintoxiquâtes',
    'désintoxiquerai',
    'désintoxiqueras',
    'désintoxiquerez',
    'désintoxiquions',
    'désinvestirions',
    'désinvestissais',
    'désinvestissait',
    'désinvestissant',
    'désinvestissent',
    'désinvestissiez',
    'désinvestissons',
    'désinvitassions',
    'désinviteraient',
    'désobligeassent',
    'désobligeassiez',
    'désobligeraient',
    'désobstruairent',
    'désobstruaisses',
    'désobstruassent',
    'désobstruassiez',
    'désobstruerions',
    'désoccupassions',
    'désoccuperaient',
    'désodorisassent',
    'désodorisassiez',
    'désodoriserions',
    'désolidarisâmes',
    'désolidarisasse',
    'désolidarisâtes',
    'désolidariserai',
    'désolidariseras',
    'désolidariserez',
    'désolidarisions',
    'désorbitassions',
    'désorbiteraient',
    'désordonnassent',
    'désordonnassiez',
    'désordonnerions',
    'désorganisaient',
    'désorganisasses',
    'désorganisateur',
    'désorganisation',
    'désorganiserais',
    'désorganiserait',
    'désorganisèrent',
    'désorganiseriez',
    'désorganiserons',
    'désorganiseront',
    'désorientassent',
    'désorientassiez',
    'désorientations',
    'désorienterions',
    'désoxygénassent',
    'désoxygénassiez',
    'désoxygénations',
    'désoxygénerions',
    'dessaisissaient',
    'dessaisissement',
    'dessanglassions',
    'dessangleraient',
    'dessaoulassions',
    'dessaouleraient',
    'dessertissaient',
    'dessous-de-bras',
    'dessous-de-plat',
    'dessus-de-porte',
    'déstabilisaient',
    'déstabilisasses',
    'déstabilisation',
    'déstabiliserais',
    'déstabiliserait',
    'déstabilisèrent',
    'déstabiliseriez',
    'déstabiliserons',
    'déstabiliseront',
    'destructuraient',
    'destructurasses',
    'déstructuration',
    'destructurerais',
    'destructurerait',
    'destructurèrent',
    'destructureriez',
    'destructurerons',
    'destructureront',
    'désubjectivisai',
    'désubjectivisas',
    'désubjectivisât',
    'désubjectivisée',
    'désubjectiviser',
    'désubjectivises',
    'désubjectivisés',
    'désubjectivisez',
    'désulfitassions',
    'désulfiteraient',
    'désulfurassions',
    'désulfureraient',
    'désynchronisais',
    'désynchronisait',
    'désynchronisant',
    'désynchronisées',
    'désynchronisent',
    'désynchronisera',
    'désynchronisiez',
    'désynchronisons',
    'détapissassions',
    'détapisseraient',
    'détériorassions',
    'détérioreraient',
    'déterminassions',
    'détermineraient',
    'détonnelassions',
    'détonnellerions',
    'détortillassent',
    'détortillassiez',
    'détortillerions',
    'détranchassions',
    'détrancheraient',
    'détransposaient',
    'détransposasses',
    'détransposerais',
    'détransposerait',
    'détransposèrent',
    'détransposeriez',
    'détransposerons',
    'détransposeront',
    'détricotassions',
    'détricoteraient',
    'détronchassions',
    'détroncheraient',
    'détroussassions',
    'détrousseraient',
    'dévalorisassent',
    'dévalorisassiez',
    'dévalorisations',
    'dévaloriserions',
    'dévaluerassions',
    'développassions',
    'développeraient',
    'dévergondassent',
    'dévergondassiez',
    'dévergonderions',
    'déverrouillages',
    'déverrouillâmes',
    'déverrouillasse',
    'déverrouillâtes',
    'déverrouillerai',
    'déverrouilleras',
    'déverrouillerez',
    'déverrouillions',
    'déviationnismes',
    'déviationnistes',
    'dévirilisassent',
    'dévirilisassiez',
    'déviriliserions',
    'dévisageassions',
    'dévisserassions',
    'dévitalisassent',
    'dévitalisassiez',
    'dévitalisations',
    'dévitaliserions',
    'dévitrifiassent',
    'dévitrifiassiez',
    'dévitrification',
    'dévitrifierions',
    'dévoilerassions',
    'diagnostiquâmes',
    'diagnostiquasse',
    'diagnostiquâtes',
    'diagnostiquerai',
    'diagnostiqueras',
    'diagnostiquerez',
    'diagnostiquions',
    'dialectalisâmes',
    'dialectalisasse',
    'dialectalisâtes',
    'dialectaliserai',
    'dialectaliseras',
    'dialectaliserez',
    'dialectalisions',
    'dialecticiennes',
    'dialectiquement',
    'dialectisassent',
    'dialectisassiez',
    'dialectiserions',
    'diaphanoscopies',
    'diaphragmassent',
    'diaphragmassiez',
    'diaphragmatique',
    'diaphragmerions',
    'diencéphaliques',
    'différenciaient',
    'différenciasses',
    'différenciateur',
    'différenciation',
    'différencierais',
    'différencierait',
    'différencièrent',
    'différencieriez',
    'différencierons',
    'différencieront',
    'différentiation',
    'différentielles',
    'diffractassions',
    'diffracteraient',
    'digitalisassent',
    'digitalisassiez',
    'digtaliseraient',
    'dimensionnaient',
    'dimensionnasses',
    'dimensionnelles',
    'dimensionnerais',
    'dimensionnerait',
    'dimensionnèrent',
    'dimensionneriez',
    'dimensionnerons',
    'dimensionneront',
    'diphtonguassent',
    'diphtonguassiez',
    'diphtonguerions',
    'disciplinassent',
    'disciplinassiez',
    'disciplinerions',
    'discographiques',
    'discontinuaient',
    'discontinuasses',
    'discontinuerais',
    'discontinuerait',
    'discontinuèrent',
    'discontinueriez',
    'discontinuerons',
    'discontinueront',
    'disconviendrais',
    'disconviendrait',
    'disconviendriez',
    'disconviendrons',
    'disconviendront',
    'disconvinssions',
    'discréditassent',
    'discréditassiez',
    'discréditerions',
    'discrétionnaire',
    'discriminassent',
    'discriminassiez',
    'discriminations',
    'discriminatoire',
    'discriminerions',
    'discutaillaient',
    'discutaillasses',
    'discutaillerais',
    'discutaillerait',
    'discutaillèrent',
    'discutailleriez',
    'discutaillerons',
    'discutailleront',
    'disgraciassions',
    'disgracieraient',
    'disjoignissions',
    'disjonctassions',
    'disjoncteraient',
    'disparaissaient',
    'disparaîtraient',
    'disproportionna',
    'disproportionne',
    'disproportionné',
    'disputaillaient',
    'disputaillasses',
    'disputaillerais',
    'disputaillerait',
    'disputaillèrent',
    'disputailleriez',
    'disputaillerons',
    'disputailleront',
    'disqualifiaient',
    'disqualifiasses',
    'disqualifierais',
    'disqualifierait',
    'disqualifièrent',
    'disqualifieriez',
    'disqualifierons',
    'disqualifieront',
    'disséminassions',
    'dissémineraient',
    'dissimulassions',
    'dissimulatrices',
    'dissimuleraient',
    'distanciassions',
    'distancieraient',
    'distinguassions',
    'distingueraient',
    'distribuassions',
    'distribueraient',
    'diversifiassent',
    'diversifiassiez',
    'diversification',
    'diversifierions',
    'divertissements',
    'documentalistes',
    'documentaristes',
    'documentassions',
    'documenteraient',
    'dodécaphoniques',
    'dodécaphonismes',
    'dogmatisassions',
    'dogmatiseraient',
    'dolichocéphales',
    'domestiquassent',
    'domestiquassiez',
    'domestiquerions',
    'domiciliassions',
    'domiciliataires',
    'domicilieraient',
    'donquichottisme',
    'double-commande',
    'douloureusement',
    'dragéifiassions',
    'dragéifieraient',
    'drageonnassions',
    'drageonneraient',
    'dramatisassions',
    'dramatiseraient',
    'dynamométriques',
    'dysorthographie',
    'ébouillantaient',
    'ébouillantasses',
    'ébouillanterais',
    'ébouillanterait',
    'ébouillantèrent',
    'ébouillanteriez',
    'ébouillanterons',
    'ébouillanteront',
    'ébourgeonnaient',
    'ébourgeonnasses',
    'ébourgeonnement',
    'ébourgeonnerais',
    'ébourgeonnerait',
    'ébourgeonnèrent',
    'ébourgeonneriez',
    'ébourgeonnerons',
    'ébourgeonneront',
    'ébouriffassions',
    'ébourifferaient',
    'écarquillassent',
    'écarquillassiez',
    'écarquillerions',
    'ecclésiastiques',
    'échafaudassions',
    'échafauderaient',
    'échalassassions',
    'échalasseraient',
    'échanfreinaient',
    'échanfreinasses',
    'échanfreinerais',
    'échanfreinerait',
    'échanfreinèrent',
    'échanfreineriez',
    'échanfreinerons',
    'échanfreineront',
    'échantillonnage',
    'échantillonnais',
    'échantillonnait',
    'échantillonnant',
    'échantillonnées',
    'échantillonnent',
    'échantillonnera',
    'échantillonneur',
    'échantillonniez',
    'échantillonnons',
    'échardonnassent',
    'échardonnassiez',
    'échardonnerions',
    'échelonnassions',
    'échelonneraient',
    'échenillassions',
    'échenilleraient',
    'échevelleraient',
    'échotomographie',
    'éclaboussassent',
    'éclaboussassiez',
    'éclaboussements',
    'éclabousserions',
    'éclaircissaient',
    'éclaircissement',
    'éconduisissions',
    'économétriciens',
    'économisassions',
    'économiseraient',
    'écorniflassions',
    'écornifleraient',
    'écouvillonnâmes',
    'écouvillonnasse',
    'écouvillonnâtes',
    'écouvillonnerai',
    'écouvillonneras',
    'écouvillonnerez',
    'écouvillonnions',
    'écrabouillaient',
    'écrabouillasses',
    'écrabouillement',
    'écrabouillerais',
    'écrabouillerait',
    'écrabouillèrent',
    'écrabouilleriez',
    'écrabouillerons',
    'écrabouilleront',
    'écrivaillassent',
    'écrivaillassiez',
    'écrivaillerions',
    'écrivassassions',
    'écrivasseraient',
    'écussonnassions',
    'écussonneraient',
    'éditionnassions',
    'éditionneraient',
    'éducationnelles',
    'effarouchassent',
    'effarouchassiez',
    'effarouchements',
    'effaroucherions',
    'effeuillassions',
    'effeuilleraient',
    'effilochassions',
    'effilocheraient',
    'efflanquassions',
    'efflanqueraient',
    'effleurissaient',
    'effrangeassions',
    'égratignassions',
    'égratigneraient',
    'égravillonnâmes',
    'égravillonnasse',
    'égravillonnâtes',
    'égravillonnerai',
    'égravillonneras',
    'égravillonnerez',
    'égravillonnions',
    'élasticimétries',
    'électrifiassent',
    'électrifiassiez',
    'électrification',
    'électrifierions',
    'électrisassions',
    'électriseraient',
    'électrobiologie',
    'électrocautères',
    'électrochimique',
    'électrocutaient',
    'électrocutasses',
    'électrocuterais',
    'électrocuterait',
    'électrocutèrent',
    'électrocuteriez',
    'électrocuterons',
    'électrocuteront',
    'électrolysables',
    'électrolysaient',
    'électrolysasses',
    'électrolyserais',
    'électrolyserait',
    'électrolysèrent',
    'électrolyseriez',
    'électrolyserons',
    'électrolyseront',
    'électrolytiques',
    'électroménagers',
    'électromotrices',
    'électronicienne',
    'électronisaient',
    'électronisasses',
    'électroniserais',
    'électroniserait',
    'électronisèrent',
    'électroniseriez',
    'électroniserons',
    'électroniseront',
    'électrophorèses',
    'électroponcture',
    'électropositifs',
    'électropositive',
    'électropuncture',
    'électrothérapie',
    'électrothermies',
    'électrovalences',
    'éléphantiasique',
    'emballottassent',
    'emballottassiez',
    'emballotterions',
    'embarbouillâmes',
    'embarbouillasse',
    'embarbouillâtes',
    'embarbouillerai',
    'embarbouilleras',
    'embarbouillerez',
    'embarbouillions',
    'embarrassassent',
    'embarrassassiez',
    'embarrasserions',
    'embastillassent',
    'embastillassiez',
    'embastillerions',
    'embastionnaient',
    'embastionnasses',
    'embastionnerais',
    'embastionnerait',
    'embastionnèrent',
    'embastionneriez',
    'embastionnerons',
    'embastionneront',
    'émbéguinassions',
    'embellissassent',
    'embellissassiez',
    'embellissements',
    'emberlificotais',
    'emberlificotait',
    'emberlificotant',
    'emberlificotées',
    'emberlificotent',
    'emberlificotera',
    'emberlificoteur',
    'emberlificotiez',
    'emberlificotons',
    'embidonnassions',
    'embidonneraient',
    'embobelinassent',
    'embobelinassiez',
    'embobelinerions',
    'embottelassions',
    'embottellerions',
    'embourgeoisâmes',
    'embourgeoisasse',
    'embourgeoisâtes',
    'embourgeoiserai',
    'embourgeoiseras',
    'embourgeoiserez',
    'embourgeoisions',
    'embouteillaient',
    'embouteillasses',
    'embouteillerais',
    'embouteillerait',
    'embouteillèrent',
    'embouteilleriez',
    'embouteillerons',
    'embouteilleront',
    'embranchassions',
    'embrancheraient',
    'embrigadassions',
    'embrigaderaient',
    'embringuassions',
    'embringueraient',
    'embronchassions',
    'embroncheraient',
    'embrouillaminis',
    'embrouillassent',
    'embrouillassiez',
    'embrouillements',
    'embrouillerions',
    'embroussaillais',
    'embroussaillait',
    'embroussaillant',
    'embroussaillées',
    'embroussaillent',
    'embroussaillera',
    'embroussailliez',
    'embroussaillons',
    'émerillonnaient',
    'émerillonnasses',
    'émerillonnerais',
    'émerillonnerait',
    'émerillonnèrent',
    'émerillonneriez',
    'émerillonnerons',
    'émerillonneront',
    'émerveillassent',
    'émerveillassiez',
    'émerveillements',
    'émerveillerions',
    'emmagasinassent',
    'emmagasinassiez',
    'emmagasinerions',
    'emmaillotassent',
    'emmaillotassiez',
    'emmaillotements',
    'emmailloterions',
    'emménageassions',
    'emmitonnassions',
    'emmitonneraient',
    'emmitouflassent',
    'emmitouflassiez',
    'emmitouflerions',
    'emmortaisassent',
    'emmortaisassiez',
    'emmortaiserions',
    'emmouscaillâmes',
    'emmouscaillasse',
    'emmouscaillâtes',
    'emmouscaillerai',
    'emmouscailleras',
    'emmouscaillerez',
    'emmouscaillions',
    'émotionnassions',
    'émotionneraient',
    'émouchetassions',
    'émouchèteraient',
    'émoustillassent',
    'émoustillassiez',
    'émoustillerions',
    'empanachassions',
    'empanacheraient',
    'empapillotaient',
    'empapillotasses',
    'empapilloterais',
    'empapilloterait',
    'empapillotèrent',
    'empapilloteriez',
    'empapilloterons',
    'empapilloteront',
    'empaquetassions',
    'empaquetterions',
    'emphysémateuses',
    'emplailleraient',
    'empoisonnassent',
    'empoisonnassiez',
    'empoisonnements',
    'empoisonnerions',
    'empoissonnaient',
    'empoissonnasses',
    'empoissonnement',
    'empoissonnerais',
    'empoissonnerait',
    'empoissonnèrent',
    'empoissonneriez',
    'empoissonnerons',
    'empoissonneront',
    'empourprassions',
    'empourpreraient',
    'empoussiéraient',
    'empoussiérasses',
    'empoussiérerais',
    'empoussiérerait',
    'empoussiérèrent',
    'empoussiéreriez',
    'empoussiérerons',
    'empoussiéreront',
    'empreignissions',
    'emprésurassions',
    'emprésureraient',
    'emprisonnassent',
    'emprisonnassiez',
    'emprisonnements',
    'emprisonnerions',
    'empuantissaient',
    'empuantissement',
    'empyreumatiques',
    'émulsifiassions',
    'émulsifieraient',
    'émulsionnassent',
    'émulsionnassiez',
    'émulsionnerions',
    'encanaillassent',
    'encanaillassiez',
    'encanaillements',
    'encanaillerions',
    'encapuchonnâmes',
    'encapuchonnasse',
    'encapuchonnâtes',
    'encapuchonnerai',
    'encapuchonneras',
    'encapuchonnerez',
    'encapuchonnions',
    'encartonnassent',
    'encartonnassiez',
    'encartonnerions',
    'encartouchaient',
    'encartouchasses',
    'encartoucherais',
    'encartoucherait',
    'encartouchèrent',
    'encartoucheriez',
    'encartoucherons',
    'encartoucheront',
    'encasernassions',
    'encaserneraient',
    'encastelassions',
    'encaustiquaient',
    'encaustiquasses',
    'encaustiquerais',
    'encaustiquerait',
    'encaustiquèrent',
    'encaustiqueriez',
    'encaustiquerons',
    'encaustiqueront',
    'encéphalogramme',
    'enchaperonnâmes',
    'enchaperonnasse',
    'enchaperonnâtes',
    'enchaperonnerai',
    'enchaperonneras',
    'enchaperonnerez',
    'enchaperonnions',
    'enchatonnassent',
    'enchatonnassiez',
    'enchatonnements',
    'enchatonnerions',
    'enchaussassions',
    'enchausseraient',
    'enchemisassions',
    'enchemiseraient',
    'enchevauchaient',
    'enchevauchasses',
    'enchevaucherais',
    'enchevaucherait',
    'enchevauchèrent',
    'enchevaucheriez',
    'enchevaucherons',
    'enchevaucheront',
    'enchevêtrassent',
    'enchevêtrassiez',
    'enchevêtrements',
    'enchevêtrerions',
    'enchifrenassent',
    'enchifrenassiez',
    'enchifrènements',
    'enchifrènerions',
    'enclenchassions',
    'enclencheraient',
    'encliquetassent',
    'encliquetassiez',
    'encliquetterais',
    'encliquetterait',
    'encliquetteriez',
    'encliquetterons',
    'encliquetteront',
    'encloîtrassions',
    'encloîtreraient',
    'encorbellements',
    'encourageassent',
    'encourageassiez',
    'encourageraient',
    'encyclopédiques',
    'encyclopédistes',
    'endeuillassions',
    'endeuilleraient',
    'endimanchassent',
    'endimanchassiez',
    'endimancherions',
    'endivisionnâmes',
    'endivisionnasse',
    'endivisionnâtes',
    'endivisionnerai',
    'endivisionneras',
    'endivisionnerez',
    'endivisionnions',
    'endocrinologies',
    'endoctrinassent',
    'endoctrinassiez',
    'endoctrinements',
    'endoctrinerions',
    'endolorissaient',
    'endolorissement',
    'endommageassent',
    'endommageassiez',
    'endommageraient',
    'endurcissements',
    'enfourchassions',
    'enfourcheraient',
    'enfreignissions',
    'enfutaillassent',
    'enfutaillassiez',
    'enfutaillerions',
    'engargoussaient',
    'engargoussasses',
    'engargousserais',
    'engargousserait',
    'engargoussèrent',
    'engargousseriez',
    'engargousserons',
    'engargousseront',
    'engazonnassions',
    'engazonneraient',
    'engloutissaient',
    'engloutissement',
    'engouffrassions',
    'engouffreraient',
    'engourdissaient',
    'engourdissement',
    'engraissassions',
    'engraisseraient',
    'engrangeassions',
    'engrumelassions',
    'engrumellerions',
    'enguirlandaient',
    'enguirlandasses',
    'enguirlanderais',
    'enguirlanderait',
    'enguirlandèrent',
    'enguirlanderiez',
    'enguirlanderons',
    'enguirlanderont',
    'enharnachassent',
    'enharnachassiez',
    'enharnacherions',
    'énigmatiquement',
    'enjavelleraient',
    'enjuponnassions',
    'enjuponneraient',
    'enlaidissements',
    'ennoblissements',
    'enorgueillirais',
    'enorgueillirait',
    'enorgueillirent',
    'enorgueilliriez',
    'enorgueillirons',
    'enorgueilliront',
    'enorgueillisses',
    'enorgueillissez',
    'enquiquinassent',
    'enquiquinassiez',
    'enquiquinerions',
    'enrégimentaient',
    'enrégimentasses',
    'enrégimenterais',
    'enrégimenterait',
    'enrégimentèrent',
    'enrégimenteriez',
    'enrégimenterons',
    'enrégimenteront',
    'enregistrassent',
    'enregistrassiez',
    'enregistrements',
    'enregistrerions',
    'enrichissements',
    'enrouillassions',
    'enrouilleraient',
    'enrubannassions',
    'enrubanneraient',
    'ensaisinassions',
    'ensaisineraient',
    'ensanglantaient',
    'ensanglantasses',
    'ensanglanterais',
    'ensanglanterait',
    'ensanglantèrent',
    'ensanglanteriez',
    'ensanglanterons',
    'ensanglanteront',
    'ensemençassions',
    'ensemenceraient',
    'ensevelissaient',
    'ensevelissement',
    'ensoleillassent',
    'ensoleillassiez',
    'ensoleillements',
    'ensoleillerions',
    'ensorcelassions',
    'ensorcellements',
    'ensorcellerions',
    'enténébrassions',
    'enténébreraient',
    'enthousiasmâmes',
    'enthousiasmasse',
    'enthousiasmâtes',
    'enthousiasmerai',
    'enthousiasmeras',
    'enthousiasmerez',
    'enthousiasmions',
    'entortillassent',
    'entortillassiez',
    'entortillements',
    'entortillerions',
    'entourloupettes',
    'entraccorderiez',
    'entraccorderons',
    'entraccusassiez',
    'entradmirassiez',
    'entradmirerions',
    'entrebâillaient',
    'entrebâillasses',
    'entrebâillement',
    'entrebâillerais',
    'entrebâillerait',
    'entrebâillèrent',
    'entrebâilleriez',
    'entrebâillerons',
    'entrebâilleront',
    'entrebattissiez',
    'entrechoquaient',
    'entrechoquasses',
    'entrechoquement',
    'entrechoquerais',
    'entrechoquerait',
    'entrechoquèrent',
    'entrechoqueriez',
    'entrechoquerons',
    'entrechoqueront',
    'entrecoupassent',
    'entrecoupassiez',
    'entrecouperions',
    'entrecroisaient',
    'entrecroisasses',
    'entrecroisement',
    'entrecroiserais',
    'entrecroiserait',
    'entrecroisèrent',
    'entrecroiseriez',
    'entrecroiserons',
    'entrecroiseront',
    'entre-déchirées',
    'entre-déchiriez',
    'entre-déchirons',
    'entre-détruirez',
    'entre-détruisez',
    'entre-détruites',
    'entre-dévorâmes',
    'entre-dévorâtes',
    'entre-dévorerez',
    'entre-dévorions',
    'entre-frappâmes',
    'entre-frappâtes',
    'entre-frapperez',
    'entre-frappions',
    'entre-haïssions',
    'entre-heurtâmes',
    'entre-heurtâtes',
    'entre-heurterez',
    'entre-heurtions',
    'entrelaçassions',
    'entrelaceraient',
    'entrelardassent',
    'entrelardassiez',
    'entrelarderions',
    'entre-louassiez',
    'entre-louerions',
    'entre-mangeâmes',
    'entre-mangeâtes',
    'entre-mangeriez',
    'entre-mangerons',
    'entremêlassions',
    'entremêleraient',
    'entremettraient',
    'entreposassions',
    'entreposeraient',
    'entrepositaires',
    'entreprendrions',
    'entre-regardées',
    'entre-regardiez',
    'entre-regardons',
    'entretailleriez',
    'entretaillerons',
    'entretiendrions',
    'entretoisassent',
    'entretoisassiez',
    'entretoiserions',
    'entre-tuassions',
    'entrevoûtassent',
    'entrevoûtassiez',
    'entrevouterions',
    'entrouvriraient',
    'entrouvrissions',
    'enveloppassions',
    'envelopperaient',
    'envieilliraient',
    'envieillissions',
    'environnassions',
    'environnemental',
    'environneraient',
    'envisageassions',
    'épaississements',
    'épannelleraient',
    'épanouissements',
    'éparpillassions',
    'éparpilleraient',
    'épigrammatiques',
    'épiloguerassent',
    'épiloguerassiez',
    'épines-vinettes',
    'épiphénoménisme',
    'épiphénoméniste',
    'épistémologique',
    'épistémologiste',
    'épontillassions',
    'épontilleraient',
    'époussetassions',
    'époussetterions',
    'époustouflaient',
    'époustouflantes',
    'époustouflasses',
    'époustouflerais',
    'époustouflerait',
    'époustouflèrent',
    'époustoufleriez',
    'époustouflerons',
    'époustoufleront',
    'épouvantassions',
    'épouvanteraient',
    'équarrissements',
    'équilibrassions',
    'équilibreraient',
    'équimoléculaire',
    'équipotentielle',
    'équiprobabilité',
    'équivoquassions',
    'équivoqueraient',
    'érysipélateuses',
    'érythroblastose',
    'escarmouchaient',
    'escarmouchasses',
    'escarmoucherais',
    'escarmoucherait',
    'escarmouchèrent',
    'escarmoucheriez',
    'escarmoucherons',
    'escarmoucheront',
    'escarrifiassent',
    'escarrifiassiez',
    'escarrifierions',
    'esclavageassent',
    'esclavageassiez',
    'esclavageraient',
    'espoulinassions',
    'espoulineraient',
    'essentiellement',
    'essorillassions',
    'essorilleraient',
    'essoufflassions',
    'essouffleraient',
    'estampillassent',
    'estampillassiez',
    'estampillerions',
    'estérifiassions',
    'estérifications',
    'estérifieraient',
    'esthétisassions',
    'esthétiseraient',
    'estomaquassions',
    'estomaqueraient',
    'estourbissaient',
    'estrapadassions',
    'estrapaderaient',
    'estrapassassent',
    'estrapassassiez',
    'estrapasserions',
    'étalinguassions',
    'étalingueraient',
    'étançonnassions',
    'étançonneraient',
    'éthérifiassions',
    'éthérifications',
    'éthérifieraient',
    'ethnographiques',
    'étincelleraient',
    'étiquetteraient',
    'étoupillassions',
    'étoupilleraient',
    'étourdissements',
    'étrésillonnâmes',
    'étrésillonnasse',
    'étrésillonnâtes',
    'étrésillonnerai',
    'étrésillonneras',
    'étrésillonnerez',
    'étrésillonnions',
    'étronçonnassent',
    'étronçonnassiez',
    'étronçonnerions',
    'euphorisassions',
    'euphoriseraient',
    'euro-obligation',
    'européanisaient',
    'européanisasses',
    'européanisation',
    'européaniserais',
    'européaniserait',
    'européanisèrent',
    'européaniseriez',
    'européaniserons',
    'européaniseront',
    'eutrophisations',
    'évangéliquement',
    'évangélisassent',
    'évangélisassiez',
    'évangélisateurs',
    'évangélisations',
    'évangélisatrice',
    'évangéliserions',
    'évanouissements',
    'événementielles',
    'évolutionnismes',
    'évolutionnistes',
    'exanthématiques',
    'excellentissime',
    'excentriquement',
    'exceptionnelles',
    'excommuniassent',
    'excommuniassiez',
    'excommunication',
    'excommunierions',
    'excrémentielles',
    'excursionnaient',
    'excursionnasses',
    'excursionnerais',
    'excursionnerait',
    'excursionnèrent',
    'excursionneriez',
    'excursionnerons',
    'excursionneront',
    'excursionnistes',
    'exemplifiassent',
    'exemplifiassiez',
    'exemplification',
    'exemplifierions',
    'exhibitionnisme',
    'exhibitionniste',
    'existentialisme',
    'existentialiste',
    'expansionnismes',
    'expansionnistes',
    'expectorassions',
    'expectoreraient',
    'expéditionnaire',
    'expérimentaient',
    'expérimentasses',
    'expérimentateur',
    'expérimentation',
    'expérimenterais',
    'expérimenterait',
    'expérimentèrent',
    'expérimenteriez',
    'expérimenterons',
    'expérimenteront',
    'expertisassions',
    'expertiseraient',
    'explicitassions',
    'expliciteraient',
    'exploitabilités',
    'expressionnisme',
    'expressionniste',
    'expropriassions',
    'exproprieraient',
    'extemporanément',
    'extériorisaient',
    'extériorisasses',
    'extériorisation',
    'extérioriserais',
    'extérioriserait',
    'extériorisèrent',
    'extérioriseriez',
    'extérioriserons',
    'extérioriseront',
    'exterminassions',
    'exterminatrices',
    'extermineraient',
    'extragalactique',
    'extrajudiciaire',
    'extraordinaires',
    'extrapolassions',
    'extrapoleraient',
    'extra-sensibles',
    'extra-sensoriel',
    'extra-terrestre',
    'extraterrestres',
    'extravaguassent',
    'extravaguassiez',
    'extravaguerions',
    'extravasassions',
    'extravaseraient',
    'extrême-onction',
    'extrinsèquement',
    'factorisassions',
    'factoriseraient',
    'facultativement',
    'faignantassions',
    'faignanteraient',
    'fainéantassions',
    'fainéanteraient',
    'fallacieusement',
    'falsificatrices',
    'familiarisaient',
    'familiarisasses',
    'familiarisation',
    'familiariserais',
    'familiariserait',
    'familiarisèrent',
    'familiariseriez',
    'familiariserons',
    'familiariseront',
    'fanfaronnassent',
    'fanfaronnassiez',
    'fanfaronnerions',
    'fanfreluchaient',
    'fanfreluchasses',
    'fanfrelucherais',
    'fanfrelucherait',
    'fanfreluchèrent',
    'fanfrelucheriez',
    'fanfrelucherons',
    'fanfrelucheront',
    'fantasmagorique',
    'fantastiquement',
    'farfouillassent',
    'farfouillassiez',
    'farfouillerions',
    'fastidieusement',
    'faux-monnayeurs',
    'fédéralisassent',
    'fédéralisassiez',
    'fédéraliserions',
    'feignantassions',
    'feignanteraient',
    'fermentescibles',
    'ferraillassions',
    'ferrailleraient',
    'ferrimagnétisme',
    'ferroprussiates',
    'fertilisassions',
    'fertiliseraient',
    'fétichisassions',
    'fétichiseraient',
    'feuiletisassent',
    'feuiletisassiez',
    'feuilletassions',
    'feuilletisaient',
    'feuilletiserais',
    'feuilletiserait',
    'feuilletiseriez',
    'feuilletiserons',
    'feuilletiseront',
    'feuilletonesque',
    'feuilletonistes',
    'feuilletterions',
    'filigranassions',
    'filigraneraient',
    'finlandisassent',
    'finlandisassiez',
    'finlandiserions',
    'finno-ougrienne',
    'fiscalisassions',
    'fiscaliseraient',
    'fissionnassions',
    'fissionneraient',
    'fixe-chaussette',
    'flegmatiquement',
    'flemmardassions',
    'flemmarderaient',
    'fluidifiassions',
    'fluidifications',
    'fluidifieraient',
    'fluviométriques',
    'folichonnassent',
    'folichonnassiez',
    'folichonnerions',
    'fonctionnalisme',
    'fonctionnalités',
    'fonctionnarisai',
    'fonctionnarisas',
    'fonctionnarisât',
    'fonctionnarisée',
    'fonctionnariser',
    'fonctionnarises',
    'fonctionnarisés',
    'fonctionnarisez',
    'fonctionnarisme',
    'fonctionnassent',
    'fonctionnassiez',
    'fonctionnements',
    'fonctionnerions',
    'fondamentalisme',
    'fondamentaliste',
    'forfaitairement',
    'formalisassions',
    'formaliseraient',
    'fossilisassions',
    'fossiliseraient',
    'fouraillassions',
    'fourailleraient',
    'fourcherassions',
    'fourgonnassions',
    'fourgonneraient',
    'fourmillassions',
    'fourmilleraient',
    'fourrageassions',
    'fractionnassent',
    'fractionnassiez',
    'fractionnements',
    'fractionnerions',
    'fragilisassions',
    'fragiliseraient',
    'fragmentassions',
    'fragmenteraient',
    'framboisassions',
    'framboiseraient',
    'franc-bourgeois',
    'franchissements',
    'francs-maçonnes',
    'fraternellement',
    'fraternisassent',
    'fraternisassiez',
    'fraternisations',
    'fraterniserions',
    'frauduleusement',
    'fréquencemètres',
    'fréquentassions',
    'fréquenteraient',
    'frictionnassent',
    'frictionnassiez',
    'frictionnerions',
    'frigorifiassent',
    'frigorifiassiez',
    'frigorifierions',
    'frigorifugeâmes',
    'frigorifugeasse',
    'frigorifugeâtes',
    'frigorifugerais',
    'frigorifugerait',
    'frigorifugèrent',
    'frigorifugeriez',
    'frigorifugerons',
    'frigorifugeront',
    'frissonnassions',
    'frissonneraient',
    'froufroutassent',
    'froufroutassiez',
    'froufroutements',
    'froufrouterions',
    'fructifiassions',
    'fructifications',
    'fructifieraient',
    'fruiticultrices',
    'gadgétisassions',
    'gadgétiseraient',
    'galvanisassions',
    'galvaniseraient',
    'galvanocautères',
    'galvanoplasties',
    'gambergeassions',
    'gardes-barrière',
    'gardes-chiourme',
    'gardes-magasins',
    'gargarisassions',
    'gargariseraient',
    'gargouillassent',
    'gargouillassiez',
    'gargouillements',
    'gargouillerions',
    'gastro-entérite',
    'gazouillassions',
    'gazouilleraient',
    'gélatinisassent',
    'gélatinisassiez',
    'gélatiniserions',
    'généralisassent',
    'généralisassiez',
    'généralisateurs',
    'généralisations',
    'généralisatrice',
    'généraliserions',
    'génito-urinaire',
    'gentilhommières',
    'géométrisassent',
    'géométrisassiez',
    'géométriserions',
    'géomorphologies',
    'géophysiciennes',
    'germanisassions',
    'germaniseraient',
    'gesticulassions',
    'gesticuleraient',
    'gigantesquement',
    'girouettassions',
    'girouetteraient',
    'glandouillaient',
    'glandouillasses',
    'glandouillerais',
    'glandouillerait',
    'glandouillèrent',
    'glandouilleriez',
    'glandouillerons',
    'glandouilleront',
    'glaviottassions',
    'glaviotteraient',
    'globalisassions',
    'globaliseraient',
    'glorificatrices',
    'glougloutassent',
    'glougloutassiez',
    'glouglouterions',
    'glycérinassions',
    'glycérineraient',
    'goguenardassent',
    'goguenardassiez',
    'goguenarderions',
    'gonadostimuline',
    'gonadotrophines',
    'gorge-de-pigeon',
    'goudronnassions',
    'goudronneraient',
    'goupillonnaient',
    'goupillonnasses',
    'goupillonnerais',
    'goupillonnerait',
    'goupillonnèrent',
    'goupillonneriez',
    'goupillonnerons',
    'goupillonneront',
    'gourmandassions',
    'gourmanderaient',
    'goutte-à-goutte',
    'gouvernementale',
    'gouvernementaux',
    'graillonnassent',
    'graillonnassiez',
    'graillonnerions',
    'grammaticalisée',
    'grammaticaliser',
    'grammaticalises',
    'grammaticalisés',
    'grammaticalisez',
    'grammaticalités',
    'grand-angulaire',
    'grande-duchesse',
    'grandiloquences',
    'grandiloquentes',
    'grappillassions',
    'grappilleraient',
    'grassouillettes',
    'graticulassions',
    'graticuleraient',
    'grenaillassions',
    'grenailleraient',
    'grenouillassent',
    'grenouillassiez',
    'grenouillerions',
    'gribouillassent',
    'gribouillassiez',
    'gribouillerions',
    'griffonnassions',
    'griffonneraient',
    'grillageassions',
    'grisaillassions',
    'grisailleraient',
    'grognassassions',
    'grognasseraient',
    'grommelleraient',
    'gueules-de-loup',
    'gueuletonnaient',
    'gueuletonnasses',
    'gueuletonnerais',
    'gueuletonnerait',
    'gueuletonnèrent',
    'gueuletonneriez',
    'gueuletonnerons',
    'gueuletonneront',
    'guillemetassent',
    'guillemetassiez',
    'guillemetterais',
    'guillemetterait',
    'guillemetteriez',
    'guillemetterons',
    'guillemetteront',
    'guillochassions',
    'guillocheraient',
    'guillotinassent',
    'guillotinassiez',
    'guillotinerions',
    'hagiographiques',
    'hallstattiennes',
    'hallucinassions',
    'hallucinatoires',
    'hallucineraient',
    'hameçonnassions',
    'hameçonneraient',
    'handicapassions',
    'handicaperaient',
    'hannetonnassent',
    'hannetonnassiez',
    'hannetonnerions',
    'harmonieusement',
    'harmonisassions',
    'harmoniseraient',
    'harpaillassions',
    'harpailleraient',
    'haut-de-chausse',
    'hélicicultrices',
    'héliocentriques',
    'hellénisassions',
    'helléniseraient',
    'hématopoïétique',
    'hémicylindrique',
    'hendécasyllabes',
    'herborisassions',
    'herborisatrices',
    'herboriseraient',
    'héréditairement',
    'hérissonnassent',
    'hérissonnassiez',
    'hérissonnerions',
    'hermaphrodismes',
    'hétérocentrique',
    'hétérocycliques',
    'hétéromorphisme',
    'hétérosexualité',
    'hétérosexuelles',
    'hiérarchisaient',
    'hiérarchisasses',
    'hiérarchisation',
    'hiérarchiserais',
    'hiérarchiserait',
    'hiérarchisèrent',
    'hiérarchiseriez',
    'hiérarchiserons',
    'hiérarchiseront',
    'hiéroglyphiques',
    'hippopotamesque',
    'historiographes',
    'historiographie',
    'hollywoodiennes',
    'holophrastiques',
    'homme-orchestre',
    'homogénéifiâmes',
    'homogénéifiasse',
    'homogénéifiâtes',
    'homogénéifierai',
    'homogénéifieras',
    'homogénéifierez',
    'homogénéifiions',
    'homogénéisaient',
    'homogénéisasses',
    'homogénéisateur',
    'homogénéisation',
    'homogénéiserais',
    'homogénéiserait',
    'homogénéisèrent',
    'homogénéiseriez',
    'homogénéiserons',
    'homogénéiseront',
    'homologuassions',
    'homologueraient',
    'horizontalement',
    'hormonothérapie',
    'horripilassions',
    'horripileraient',
    'hospitalisaient',
    'hospitalisasses',
    'hospitalisation',
    'hospitaliserais',
    'hospitaliserait',
    'hospitalisèrent',
    'hospitaliseriez',
    'hospitaliserons',
    'hospitaliseront',
    'houblonnassions',
    'houblonneraient',
    'houspillassions',
    'houspilleraient',
    'humidifiassions',
    'humidificateurs',
    'humidifications',
    'humidifieraient',
    'hydrauliciennes',
    'hydrocarbonates',
    'hydrocortisones',
    'hydroélectrique',
    'hydrofugeassent',
    'hydrofugeassiez',
    'hydrofugeraient',
    'hydrogénassions',
    'hydrogéneraient',
    'hydrographiques',
    'hydrolysassions',
    'hydrolyseraient',
    'hydromécaniques',
    'hydropéricardes',
    'hydrothérapique',
    'hyperémotivités',
    'hyperfréquences',
    'hyperlipidémies',
    'hypersécrétions',
    'hyperthyroïdies',
    'hypertrophiâmes',
    'hypertrophiâtes',
    'hypertrophierez',
    'hypertrophiions',
    'hypertrophiques',
    'hypnotisassions',
    'hypnotiseraient',
    'hypochlorhydrie',
    'hypocondriaques',
    'hypocoristiques',
    'hypostasiassent',
    'hypostasiassiez',
    'hypostasierions',
    'hyposulfureuses',
    'hypothéquassent',
    'hypothéquassiez',
    'hypothéquerions',
    'hypothyroïdisme',
    'hystérographies',
    'iconographiques',
    'identifiassions',
    'identifications',
    'identifieraient',
    'idéologisassent',
    'idéologisassiez',
    'idéologiserions',
    'idiotifiassions',
    'idiotifieraient',
    'ignifugeassions',
    'illusionnassent',
    'illusionnassiez',
    'illusionnerions',
    'immanquablement',
    'immatérialisais',
    'immatérialisait',
    'immatérialisant',
    'immatérialisées',
    'immatérialisent',
    'immatérialisera',
    'immatérialisiez',
    'immatérialismes',
    'immatérialisons',
    'immatérialistes',
    'immatriculaient',
    'immatriculasses',
    'immatriculation',
    'immatriculerais',
    'immatriculerait',
    'immatriculèrent',
    'immatriculeriez',
    'immatriculerons',
    'immatriculeront',
    'immobilisassent',
    'immobilisassiez',
    'immobilisations',
    'immobiliserions',
    'immortalisaient',
    'immortalisasses',
    'immortaliserais',
    'immortaliserait',
    'immortalisèrent',
    'immortaliseriez',
    'immortaliserons',
    'immortaliseront',
    'immunocompétent',
    'immunodépressif',
    'immunoglobuline',
    'immunothérapies',
    'immunotolérante',
    'immunotolérants',
    'impatientassent',
    'impatientassiez',
    'impatienterions',
    'impatronisaient',
    'impatronisasses',
    'impatroniserais',
    'impatroniserait',
    'impatronisèrent',
    'impatroniseriez',
    'impatroniserons',
    'impatroniseront',
    'impénétrabilité',
    'imperméabilisai',
    'imperméabilisas',
    'imperméabilisât',
    'imperméabilisée',
    'imperméabiliser',
    'imperméabilises',
    'imperméabilisés',
    'imperméabilisez',
    'imperméabilités',
    'impersonnalités',
    'impitoyablement',
    'implémentassent',
    'implémentassiez',
    'implémenterions',
    'importunassions',
    'importuneraient',
    'impraticabilité',
    'imprescriptible',
    'impressionnable',
    'impressionnâmes',
    'impressionnante',
    'impressionnants',
    'impressionnasse',
    'impressionnâtes',
    'impressionnerai',
    'impressionneras',
    'impressionnerez',
    'impressionnions',
    'impressionnisme',
    'impressionniste',
    'imprévisibilité',
    'improductivités',
    'improvisassions',
    'improvisatrices',
    'improviseraient',
    'inaccessibilité',
    'inadmissibilité',
    'inaltérabilités',
    'inassouvissable',
    'inauthenticités',
    'incarcérassions',
    'incarcéreraient',
    'incoercibilités',
    'incommensurable',
    'incommodassions',
    'incommoderaient',
    'incommunicables',
    'incommutabilité',
    'incompatibilité',
    'incompréhensifs',
    'incompréhension',
    'incompréhensive',
    'incompressibles',
    'inconditionnées',
    'inconditionnels',
    'inconnaissables',
    'inconnaissances',
    'inconsidérément',
    'inconstructible',
    'incontournables',
    'incoordinations',
    'incorporassions',
    'incorporeraient',
    'incrémentassent',
    'incrémentassiez',
    'incrémenterions',
    'incriminassions',
    'incrimineraient',
    'indébrouillable',
    'indécachetables',
    'indéchiffrables',
    'indécomposables',
    'indéfectibilité',
    'indéfinissables',
    'indemnisassions',
    'indemniseraient',
    'indépendantisme',
    'indépendantiste',
    'indescriptibles',
    'indestructibles',
    'indéterminables',
    'indétermination',
    'indéterminismes',
    'indifférassions',
    'indifférenciées',
    'indifféreraient',
    'indisponibilité',
    'indisposassions',
    'indisposeraient',
    'indissolubilité',
    'indistinctement',
    'indistinguables',
    'individualisais',
    'individualisait',
    'individualisant',
    'individualisées',
    'individualisent',
    'individualisera',
    'individualisiez',
    'individualismes',
    'individualisons',
    'individualistes',
    'indivisibilités',
    'indo-européenne',
    'indubitablement',
    'indulgenciaient',
    'indulgenciasses',
    'indulgencierais',
    'indulgencierait',
    'indulgencièrent',
    'indulgencieriez',
    'indulgencierons',
    'indulgencieront',
    'industrialisais',
    'industrialisait',
    'industrialisant',
    'industrialisées',
    'industrialisent',
    'industrialisera',
    'industrialisiez',
    'industrialismes',
    'industrialisons',
    'inéluctablement',
    'inépuisablement',
    'inexpérimentées',
    'infaillibilités',
    'infailliblement',
    'infantilisaient',
    'infantilisasses',
    'infantiliserais',
    'infantiliserait',
    'infantilisèrent',
    'infantiliseriez',
    'infantiliserons',
    'infantiliseront',
    'infatigablement',
    'infériorisaient',
    'infériorisasses',
    'infériorisation',
    'inférioriserais',
    'inférioriserait',
    'infériorisèrent',
    'inférioriseriez',
    'inférioriserons',
    'inférioriseront',
    'infinitésimales',
    'inflammabilités',
    'inflationnistes',
    'infléchissaient',
    'infléchissement',
    'influençassions',
    'influenceraient',
    'informaticienne',
    'informationnels',
    'informatisables',
    'informatisaient',
    'informatisasses',
    'informatisation',
    'informatiserais',
    'informatiserait',
    'informatisèrent',
    'informatiseriez',
    'informatiserons',
    'informatiseront',
    'infranchissable',
    'infrastructures',
    'ingurgitassions',
    'ingurgiteraient',
    'inhospitalières',
    'inintelligences',
    'inintelligentes',
    'inintelligibles',
    'inintéressantes',
    'initialisassent',
    'initialisassiez',
    'initialisations',
    'initialiserions',
    'innocentassions',
    'innocenteraient',
    'inopportunément',
    'inopposabilités',
    'inorganisations',
    'inquisitoriales',
    'insatisfactions',
    'insatisfaisante',
    'insatisfaisants',
    'insensibilisais',
    'insensibilisait',
    'insensibilisant',
    'insensibilisées',
    'insensibilisent',
    'insensibilisera',
    'insensibilisiez',
    'insensibilisons',
    'inséparablement',
    'insolubilisâmes',
    'insolubilisasse',
    'insolubilisâtes',
    'insolubiliserai',
    'insolubiliseras',
    'insolubiliserez',
    'insolubilisions',
    'insonorisassent',
    'insonorisassiez',
    'insonorisations',
    'insonoriserions',
    'insoupçonnables',
    'instinctivement',
    'institutionnels',
    'instruisissions',
    'instrumentaient',
    'instrumentaires',
    'instrumentasses',
    'instrumentation',
    'instrumenterais',
    'instrumenterait',
    'instrumentèrent',
    'instrumenteriez',
    'instrumenterons',
    'instrumenteront',
    'instrumentistes',
    'insubordination',
    'insurrectionnel',
    'intellectualisa',
    'intellectualise',
    'intellectualisé',
    'intellectualité',
    'intellectuelles',
    'intelligentsias',
    'intelligibilité',
    'intensifiassent',
    'intensifiassiez',
    'intensification',
    'intensifierions',
    'intentionnalité',
    'intentionnelles',
    'intercalassions',
    'intercaleraient',
    'intercédassions',
    'intercéderaient',
    'intercellulaire',
    'interceptassent',
    'interceptassiez',
    'intercepterions',
    'interchangeable',
    'interclassaient',
    'interclassasses',
    'interclasserais',
    'interclasserait',
    'interclassèrent',
    'interclasseriez',
    'interclasserons',
    'interclasseront',
    'intercommunales',
    'interconnexions',
    'interdépendance',
    'interdépendante',
    'interdépendants',
    'intéressassions',
    'intéresseraient',
    'interférassions',
    'interféreraient',
    'interféromètres',
    'interférométrie',
    'interfoliassent',
    'interfoliassiez',
    'interfolierions',
    'interglaciaires',
    'interindividuel',
    'intériorisaient',
    'intériorisasses',
    'intériorisation',
    'intérioriserais',
    'intérioriserait',
    'intériorisèrent',
    'intérioriseriez',
    'intérioriserons',
    'intérioriseront',
    'interjetassions',
    'interjetterions',
    'interlignassent',
    'interlignassiez',
    'interlignerions',
    'interlocutoires',
    'interlocutrices',
    'interloquassent',
    'interloquassiez',
    'interloquerions',
    'intermusculaire',
    'internalisation',
    'internationales',
    'interocéaniques',
    'interpariétales',
    'interpellassent',
    'interpellassiez',
    'interpellateurs',
    'interpellations',
    'interpellatrice',
    'interpellerions',
    'interpénétrâmes',
    'interpénétrâtes',
    'interpénétrerez',
    'interpénétrions',
    'interpersonnels',
    'interplanétaire',
    'interpolassions',
    'interpolatrices',
    'interpoleraient',
    'interposassions',
    'interposeraient',
    'interprétariats',
    'interprétassent',
    'interprétassiez',
    'interprétations',
    'interprétatives',
    'interpréterions',
    'interrogatoires',
    'interrogatrices',
    'interrogeassent',
    'interrogeassiez',
    'interrogeraient',
    'interrompissent',
    'interrompissiez',
    'interrompraient',
    'intersectoriels',
    'intersexualités',
    'interstellaires',
    'intersyndicales',
    'intertropicales',
    'intervertébrale',
    'intervertébraux',
    'intervertirions',
    'intervertissais',
    'intervertissait',
    'intervertissant',
    'intervertissent',
    'intervertissiez',
    'intervertissons',
    'interviendrions',
    'interviewassent',
    'interviewassiez',
    'interviewerions',
    'intervocaliques',
    'intoxiquassions',
    'intoxiqueraient',
    'intra-atomiques',
    'intracardiaques',
    'intracellulaire',
    'intracrâniennes',
    'intramusculaire',
    'intransigeances',
    'intransigeantes',
    'intransitivités',
    'intransmissible',
    'intransportable',
    'intranucléaires',
    'intrinsèquement',
    'introduisissent',
    'introduisissiez',
    'intronisassions',
    'introniseraient',
    'intuitionnismes',
    'intuitionnistes',
    'intussusception',
    'invectivassions',
    'invectiveraient',
    'inventoriassent',
    'inventoriassiez',
    'inventorierions',
    'investigatrices',
    'investissements',
    'invraisemblable',
    'invraisemblance',
    'invulnérabilité',
    'irrationalismes',
    'irrecevabilités',
    'irréconciliable',
    'irréductibilité',
    'irréfutabilités',
    'irréfutablement',
    'irrégulièrement',
    'irréparablement',
    'irrépréhensible',
    'irrespectueuses',
    'irrétrécissable',
    'irrévérencieuse',
    'irréversibilité',
    'irrévocablement',
    'isochromatiques',
    'isolationnismes',
    'isolationnistes',
    'italianisassent',
    'italianisassiez',
    'italianiserions',
    'javellisassions',
    'javelliseraient',
    'je-ne-sais-quoi',
    'journalistiques',
    'juridictionnels',
    'jurisprudentiel',
    'justificatrices',
    'juxtaposassions',
    'juxtaposeraient',
    'kaléidoscopique',
    'kératinisations',
    'kilométrassions',
    'kilométreraient',
    'kinésithérapies',
    'labialisassions',
    'labialiseraient',
    'labyrinthodonte',
    'lacédémoniennes',
    'lacryma-christi',
    'lactodensimètre',
    'lambrissassions',
    'lambrisseraient',
    'lance-roquettes',
    'lance-torpilles',
    'langoureusement',
    'lapidifications',
    'laryngologistes',
    'latéralisations',
    'lexicalisassent',
    'lexicalisassiez',
    'lexicalisations',
    'lexicaliserions',
    'lexicographique',
    'liaisonnassions',
    'liaisonneraient',
    'libéralisassent',
    'libéralisassiez',
    'libéralisations',
    'libéraliserions',
    'libres-penseurs',
    'libres-services',
    'limousinassions',
    'limousineraient',
    'lithographiâmes',
    'lithographiasse',
    'lithographiâtes',
    'lithographierai',
    'lithographieras',
    'lithographierez',
    'lithographiions',
    'lithographiques',
    'lock-outassions',
    'lock-outeraient',
    'lois-programmes',
    'lyophilisassent',
    'lyophilisassiez',
    'lyophilisations',
    'lyophiliserions',
    'macadamisassent',
    'macadamisassiez',
    'macadamiserions',
    'machicotassions',
    'machicoteraient',
    'machines-outils',
    'mâchouillassent',
    'mâchouillassiez',
    'mâchouillerions',
    'madrigalisaient',
    'madrigalisasses',
    'madrigaliserais',
    'madrigaliserait',
    'madrigalisèrent',
    'madrigaliseriez',
    'madrigaliserons',
    'madrigaliseront',
    'magnétisassions',
    'magnétiseraient',
    'magnétocassette',
    'magnétomotrices',
    'magnétoscopâmes',
    'magnétoscopasse',
    'magnétoscopâtes',
    'magnétoscoperai',
    'magnétoscoperas',
    'magnétoscoperez',
    'magnétoscopions',
    'magouilleraient',
    'maintiendraient',
    'majestueusement',
    'majoritairement',
    'malencontreuses',
    'malheureusement',
    'malintentionnée',
    'malintentionnés',
    'malléabilisâmes',
    'malléabilisasse',
    'malléabilisâtes',
    'malléabiliserai',
    'malléabiliseras',
    'malléabiliserez',
    'malléabilisions',
    'malthusianismes',
    'maltraitassions',
    'maltraiteraient',
    'mamelonnassions',
    'mamelonneraient',
    'manchonnassions',
    'manchonneraient',
    'mandats-lettres',
    'mangeottassions',
    'mangeotteraient',
    'manifestassions',
    'manifesteraient',
    'manigançassions',
    'maniganceraient',
    'mannequinassent',
    'mannequinassiez',
    'mannequinerions',
    'manoeuvrabilité',
    'manoeuvrassions',
    'manoeuvreraient',
    'manufacturables',
    'manufacturaient',
    'manufacturasses',
    'manufacturerais',
    'manufacturerait',
    'manufacturèrent',
    'manufactureriez',
    'manufacturerons',
    'manufactureront',
    'manufacturières',
    'manutentionnais',
    'manutentionnait',
    'manutentionnant',
    'manutentionnées',
    'manutentionnent',
    'manutentionnera',
    'manutentionniez',
    'manutentionnons',
    'maquereautaient',
    'maquereautasses',
    'maquereauterais',
    'maquereauterait',
    'maquereautèrent',
    'maquereauteriez',
    'maquereauterons',
    'maquereauteront',
    'maquignonnaient',
    'maquignonnasses',
    'maquignonnerais',
    'maquignonnerait',
    'maquignonnèrent',
    'maquignonneriez',
    'maquignonnerons',
    'maquignonneront',
    'marchandassions',
    'marchanderaient',
    'marginalisaient',
    'marginalisasses',
    'marginaliserais',
    'marginaliserait',
    'marginalisèrent',
    'marginaliseriez',
    'marginaliserons',
    'marginaliseront',
    'marionnettistes',
    'marivaudassions',
    'marivauderaient',
    'marmorisassions',
    'marmoriseraient',
    'maroquinassions',
    'maroquineraient',
    'marquetteraient',
    'marsouinassions',
    'marsouineraient',
    'marteaux-pilons',
    'martyrisassions',
    'martyriseraient',
    'masculinisaient',
    'masculinisasses',
    'masculiniserais',
    'masculiniserait',
    'masculinisèrent',
    'masculiniseriez',
    'masculiniserons',
    'masculiniseront',
    'massicotassions',
    'massicoteraient',
    'matelassassions',
    'matelasseraient',
    'matérialisaient',
    'matérialisasses',
    'matérialisation',
    'matérialiserais',
    'matérialiserait',
    'matérialisèrent',
    'matérialiseriez',
    'matérialiserons',
    'matérialiseront',
    'maternisassions',
    'materniseraient',
    'mathématicienne',
    'mathématisaient',
    'mathématisasses',
    'mathématisation',
    'mathématiserais',
    'mathématiserait',
    'mathématisèrent',
    'mathématiseriez',
    'mathématiserons',
    'mathématiseront',
    'matriculassions',
    'matriculeraient',
    'maximalisassent',
    'maximalisassiez',
    'maximalisations',
    'maximaliserions',
    'mécanographique',
    'mécanothérapies',
    'méconnaissables',
    'méconnaissaient',
    'méconnaissances',
    'méconnaîtraient',
    'mécontentassent',
    'mécontentassiez',
    'mécontentements',
    'mécontenterions',
    'médiatisassions',
    'médiatiseraient',
    'médicalisassent',
    'médicalisassiez',
    'médicalisations',
    'médicaliserions',
    'médicamentaient',
    'médicamentasses',
    'médicamenterais',
    'médicamenterait',
    'médicamentèrent',
    'médicamenteriez',
    'médicamenterons',
    'médicamenteront',
    'médicamenteuses',
    'médico-sociales',
    'méditerranéenne',
    'mélanostimuline',
    'mélodramatiques',
    'mendigotassions',
    'mendigoteraient',
    'mensualisassent',
    'mensualisassiez',
    'mensualisations',
    'mensualiserions',
    'mentionnassions',
    'mentionneraient',
    'mercantilisâmes',
    'mercantilisasse',
    'mercantilisâtes',
    'mercantiliserai',
    'mercantiliseras',
    'mercantiliserez',
    'mercantilisions',
    'mercerisassions',
    'merceriseraient',
    'mésestimassions',
    'mésestimeraient',
    'mésintelligence',
    'métallisassions',
    'métalliseraient',
    'métallochromies',
    'métallographies',
    'métalloprotéine',
    'métamorphisâmes',
    'métamorphisasse',
    'métamorphisâtes',
    'métamorphiserai',
    'métamorphiseras',
    'métamorphiserez',
    'métamorphisions',
    'métamorphosâmes',
    'métamorphosasse',
    'métamorphosâtes',
    'métamorphoserai',
    'métamorphoseras',
    'métamorphoserez',
    'métamorphosions',
    'métaphysicienne',
    'métapsychologie',
    'météorisassions',
    'météoriseraient',
    'météorologiques',
    'météorologistes',
    'méthémoglobines',
    'méthodologiques',
    'méticuleusement',
    'microbiologiste',
    'microchirurgies',
    'microdissection',
    'microfilmassent',
    'microfilmassiez',
    'microfilmerions',
    'microglossaires',
    'micrographiques',
    'micro-organisme',
    'microprocesseur',
    'militarisassent',
    'militarisassiez',
    'militarisations',
    'militariserions',
    'millésimassions',
    'millésimeraient',
    'minéralisassent',
    'minéralisassiez',
    'minéralisateurs',
    'minéralisations',
    'minéralisatrice',
    'minéraliserions',
    'miniaturassions',
    'miniatureraient',
    'miniaturisaient',
    'miniaturisasses',
    'miniaturisation',
    'miniaturiserais',
    'miniaturiserait',
    'miniaturisèrent',
    'miniaturiseriez',
    'miniaturiserons',
    'miniaturiseront',
    'mini-ordinateur',
    'miraculeusement',
    'misanthropiques',
    'miséricordieuse',
    'mithridatassent',
    'mithridatassiez',
    'mithridaterions',
    'mithridatisâmes',
    'mithridatisasse',
    'mithridatisâtes',
    'mithridatiserai',
    'mithridatiseras',
    'mithridatiserez',
    'mithridatisions',
    'mitraillassions',
    'mitrailleraient',
    'mixtionnassions',
    'mixtionneraient',
    'mnémotechniques',
    'modernisassions',
    'moderniseraient',
    'moissonnassions',
    'moissonneraient',
    'molletonnassent',
    'molletonnassiez',
    'molletonnerions',
    'mondialisassent',
    'mondialisassiez',
    'mondialisations',
    'mondialiserions',
    'monocaméralisme',
    'monocellulaires',
    'monochromatique',
    'monocotylédones',
    'monologuassions',
    'monologueraient',
    'monométallismes',
    'monométallistes',
    'monopolisassent',
    'monopolisassiez',
    'monopolisateurs',
    'monopolisations',
    'monopolisatrice',
    'monopoliserions',
    'monopolistiques',
    'monosaccharides',
    'monosyllabiques',
    'monosyllabismes',
    'monstrueusement',
    'montre-bracelet',
    'motopropulseurs',
    'mots-croisistes',
    'mouchardassions',
    'moucharderaient',
    'moucheronnaient',
    'moucheronnasses',
    'moucheronnerais',
    'moucheronnerait',
    'moucheronnèrent',
    'moucheronneriez',
    'moucheronnerons',
    'moucheronneront',
    'mouchetteraient',
    'mouvementassent',
    'mouvementassiez',
    'mouvementerions',
    'moyen-courriers',
    'multicellulaire',
    'multinationales',
    'multiplexassent',
    'multiplexassiez',
    'multiplexerions',
    'multipliassions',
    'multiplicateurs',
    'multiplications',
    'multiplieraient',
    'multiprocesseur',
    'multipropriétés',
    'multitraitement',
    'multitubulaires',
    'municipalisâmes',
    'municipalisasse',
    'municipalisâtes',
    'municipaliserai',
    'municipaliseras',
    'municipaliserez',
    'municipalisions',
    'munitionnassent',
    'munitionnassiez',
    'munitionnerions',
    'mystérieusement',
    'mystificatrices',
    'mytilicultrices',
    'myxoedémateuses',
    'nationalisaient',
    'nationalisasses',
    'nationalisation',
    'nationaliserais',
    'nationaliserait',
    'nationalisèrent',
    'nationaliseriez',
    'nationaliserons',
    'nationaliseront',
    'naturalisassent',
    'naturalisassiez',
    'naturalisations',
    'naturaliserions',
    'naufrageassions',
    'nécessitassions',
    'nécessiteraient',
    'nécromanciennes',
    'négro-africaine',
    'négro-africains',
    'negro-spiritual',
    'négro-spiritual',
    'néo-classicisme',
    'néocolonialisme',
    'néocolonialiste',
    'néo-criticismes',
    'néo-darwinismes',
    'néo-hébridaises',
    'néo-platonicien',
    'néo-platonismes',
    'néo-positivisme',
    'néo-positiviste',
    'néo-zélandaises',
    'neurasthéniques',
    'neurochirurgien',
    'neurochirurgies',
    'neurodépresseur',
    'neuropsychiatre',
    'neuro-végétatif',
    'neutralisassent',
    'neutralisassiez',
    'neutralisations',
    'neutraliserions',
    'nicotinisassent',
    'nicotinisassiez',
    'nicotiniserions',
    'nitrocelluloses',
    'nitroglycérines',
    'nivo-glaciaires',
    'noli-me-tangere',
    'nominalisassent',
    'nominalisassiez',
    'nominaliserions',
    'non-acceptation',
    'non-alignements',
    'non-assistances',
    'non-belligérant',
    'non-combattante',
    'non-combattants',
    'non-comparantes',
    'non-comparution',
    'non-conformisme',
    'non-conformiste',
    'non-conformités',
    'non-directivité',
    'non-engagements',
    'non-euclidienne',
    'non-figuratives',
    'non-spécialiste',
    'nord-africaines',
    'nord-américaine',
    'nord-américains',
    'nord-vietnamien',
    'normalisassions',
    'normaliseraient',
    'nucléoprotéines',
    'nues-propriétés',
    'nu-propriétaire',
    'nutritionnelles',
    'nutritionnistes',
    'objectivassions',
    'objectiveraient',
    'obligatoirement',
    'obscurcissaient',
    'obscurcissement',
    'obséquieusement',
    'obsessionnelles',
    'obstétriciennes',
    'obtempérassions',
    'obtempéreraient',
    'occasionnalisme',
    'occasionnassent',
    'occasionnassiez',
    'occasionnerions',
    'occidentalisais',
    'occidentalisait',
    'occidentalisant',
    'occidentalisées',
    'occidentalisent',
    'occidentalisera',
    'occidentalisiez',
    'occidentalisons',
    'océanographique',
    'octosyllabiques',
    'oeil-de-perdrix',
    'oeilletonnaient',
    'oeilletonnasses',
    'oeilletonnerais',
    'oeilletonnerait',
    'oeilletonnèrent',
    'oeilletonneriez',
    'oeilletonnerons',
    'oeilletonneront',
    'oesophagoscopes',
    'officialisaient',
    'officialisasses',
    'officialisation',
    'officialiserais',
    'officialiserait',
    'officialisèrent',
    'officialiseriez',
    'officialiserons',
    'officialiseront',
    'oiseaux-mouches',
    'oligopolistique',
    'omnipraticienne',
    'oniromanciennes',
    'ontologiquement',
    'opéras-comiques',
    'opérationnelles',
    'ophtalmologique',
    'ophtalmologiste',
    'ophtalmoscopies',
    'opiniâtrassions',
    'oppositionnelle',
    'optimalisassent',
    'optimalisassiez',
    'optimaliserions',
    'orchestrassions',
    'orchestratrices',
    'orchestreraient',
    'ordonnançassent',
    'ordonnançassiez',
    'ordonnancements',
    'ordonnancerions',
    'organisationnel',
    'organothérapies',
    'organsinassions',
    'ornementassions',
    'ornementeraient',
    'ornithologiques',
    'ornithologistes',
    'orthogonalement',
    'orthographiâmes',
    'orthographiasse',
    'orthographiâtes',
    'orthographierai',
    'orthographieras',
    'orthographierez',
    'orthographiions',
    'orthographiques',
    'orthorhombiques',
    'ostracisassions',
    'ostraciseraient',
    'ostréicultrices',
    'ouest-allemande',
    'ouest-allemands',
    'outrepassassent',
    'outrepassassiez',
    'outrepasserions',
    'ovationnassions',
    'ovationneraient',
    'oxyacétylénique',
    'oxydoréductions',
    'oxygénothérapie',
    'oxyhémoglobines',
    'paillardassions',
    'paillarderaient',
    'paillassonnages',
    'paillassonnâmes',
    'paillassonnasse',
    'paillassonnâtes',
    'paillassonnerai',
    'paillassonneras',
    'paillassonnerez',
    'paillassonnions',
    'pailletteraient',
    'paillonnassions',
    'paillonneraient',
    'paisselleraient',
    'palabrerassions',
    'palatalisations',
    'paléobotaniques',
    'paléochrétienne',
    'paléographiques',
    'paléohistologie',
    'paléomagnétisme',
    'paléontologique',
    'paléontologiste',
    'palettisassions',
    'palettiseraient',
    'palingénésiques',
    'palissadassions',
    'palissaderaient',
    'palissonnassent',
    'palissonnassiez',
    'palissonnerions',
    'pamplemoussiers',
    'panafricanismes',
    'panaméricanisme',
    'panchromatiques',
    'panneautassions',
    'panneauteraient',
    'panoramiquaient',
    'panoramiquasses',
    'panoramiquerais',
    'panoramiquerait',
    'panoramiquèrent',
    'panoramiqueriez',
    'panoramiquerons',
    'panoramiqueront',
    'pantagruéliques',
    'pantouflassions',
    'pantoufleraient',
    'papillonnassent',
    'papillonnassiez',
    'papillonnements',
    'papillonnerions',
    'papillotassions',
    'papilloteraient',
    'papouillassions',
    'papouilleraient',
    'paraboliquement',
    'parachevassions',
    'parachèveraient',
    'parachutassions',
    'parachuteraient',
    'paradigmatiques',
    'paraffinassions',
    'paraffineraient',
    'paraisonnassent',
    'paraisonnassiez',
    'paraisonnerions',
    'paralittéraires',
    'paralittérature',
    'parallélépipède',
    'parallélisaient',
    'parallélisasses',
    'paralléliserais',
    'paralléliserait',
    'parallélisèrent',
    'paralléliseriez',
    'paralléliserons',
    'paralléliseront',
    'parallélogramme',
    'paramagnétismes',
    'paramétrassions',
    'paramétreraient',
    'parangonnassent',
    'parangonnassiez',
    'parangonnerions',
    'paraphrasassent',
    'paraphrasassiez',
    'paraphraserions',
    'paraphrastiques',
    'parapsychologie',
    'parasympathique',
    'parasynthétique',
    'parcellarisâmes',
    'parcellarisasse',
    'parcellarisâtes',
    'parcellariserai',
    'parcellariseras',
    'parcellariserez',
    'parcellarisions',
    'parcellisassent',
    'parcellisassiez',
    'parcellisations',
    'parcelliserions',
    'parcheminassent',
    'parcheminassiez',
    'parcheminerions',
    'pare-avalanches',
    'pare-étincelles',
    'parementassions',
    'parementeraient',
    'parenchymateuse',
    'parisyllabiques',
    'parkérisassions',
    'parkériseraient',
    'parkinsoniennes',
    'parlementarisme',
    'parlementassent',
    'parlementassiez',
    'parlementerions',
    'parquetteraient',
    'participassions',
    'participeraient',
    'particularisais',
    'particularisait',
    'particularisant',
    'particularisées',
    'particularisent',
    'particularisera',
    'particularisiez',
    'particularismes',
    'particularisons',
    'particularistes',
    'partitionnaient',
    'partitionnasses',
    'partitionnement',
    'partitionnerais',
    'partitionnerait',
    'partitionnèrent',
    'partitionneriez',
    'partitionnerons',
    'partitionneront',
    'passementassent',
    'passementassiez',
    'passementerions',
    'passe-montagnes',
    'passepoilassent',
    'passepoilassiez',
    'passepoilerions',
    'passionnassions',
    'passionneraient',
    'pasteurisassent',
    'pasteurisassiez',
    'pasteurisations',
    'pasteuriserions',
    'patafiolassions',
    'patafioleraient',
    'pataugeaissions',
    'pathognomonique',
    'patouillassions',
    'patouilleraient',
    'patriarcalement',
    'patriotiquement',
    'patrouillassent',
    'patrouillassiez',
    'patrouillerions',
    'paupérisassions',
    'paupériseraient',
    'pédagogiquement',
    'peinturluraient',
    'peinturlurasses',
    'peinturlurerais',
    'peinturlurerait',
    'peinturlurèrent',
    'peinturlureriez',
    'peinturlurerons',
    'peinturlureront',
    'pelotonnassions',
    'pelotonneraient',
    'pendouillassent',
    'pendouillassiez',
    'pendouillerions',
    'pensionnassions',
    'pensionneraient',
    'penthiobarbital',
    'perce-murailles',
    'perceptibilités',
    'perceptiblement',
    'percussionniste',
    'pérégrinassions',
    'pérégrineraient',
    'péremptoirement',
    'pérennisassions',
    'pérenniseraient',
    'perfectibilités',
    'perfectionnâmes',
    'perfectionnasse',
    'perfectionnâtes',
    'perfectionnerai',
    'perfectionneras',
    'perfectionnerez',
    'perfectionnions',
    'perfectionnisme',
    'perfectionniste',
    'périclitassions',
    'péricliteraient',
    'péripatéticiens',
    'périphrasassent',
    'périphrasassiez',
    'périphraserions',
    'périphrastiques',
    'permanentassent',
    'permanentassiez',
    'permanenterions',
    'perméabilisâmes',
    'perméabilisasse',
    'perméabilisâtes',
    'perméabiliserai',
    'perméabiliseras',
    'perméabiliserez',
    'perméabilisions',
    'permissionnaire',
    'pernicieusement',
    'perpendiculaire',
    'perpétuellement',
    'perquisitionnai',
    'perquisitionnas',
    'perquisitionnât',
    'perquisitionnée',
    'perquisitionner',
    'perquisitionnes',
    'perquisitionnés',
    'perquisitionnez',
    'persécutassions',
    'persécuteraient',
    'persévérassions',
    'persévéreraient',
    'personnalisâmes',
    'personnalisasse',
    'personnalisâtes',
    'personnaliserai',
    'personnaliseras',
    'personnaliserez',
    'personnalisions',
    'personnellement',
    'personnifiaient',
    'personnifiasses',
    'personnifierais',
    'personnifierait',
    'personnifièrent',
    'personnifieriez',
    'personnifierons',
    'personnifieront',
    'perspectivement',
    'perspectivismes',
    'pervertissaient',
    'pervertissement',
    'pervertisseuses',
    'pestiférassions',
    'pestiféreraient',
    'pestilentielles',
    'petit-bourgeois',
    'pétitionnassent',
    'pétitionnassiez',
    'pétitionnerions',
    'pétouillassions',
    'pétouilleraient',
    'pétrarquisaient',
    'pétrarquisantes',
    'pétrarquisasses',
    'pétrarquiserais',
    'pétrarquiserait',
    'pétrarquisèrent',
    'pétrarquiseriez',
    'pétrarquiserons',
    'pétrarquiseront',
    'pétrographiques',
    'pétrolochimique',
    'phagocytassions',
    'phagocyteraient',
    'phalanstérienne',
    'phallocentrique',
    'phallocentrisme',
    'pharmaceutiques',
    'pharmacodynamie',
    'pharmacognosies',
    'pharmacologique',
    'phénakisticopes',
    'phénakistiscope',
    'phénoménologies',
    'phénoménologues',
    'philanthropique',
    'philharmoniques',
    'philosophassent',
    'philosophassiez',
    'philosopherions',
    'phosphatassions',
    'phosphateraient',
    'phosphorassions',
    'phosphoreraient',
    'phosphorescence',
    'phosphorescente',
    'phosphorescents',
    'photocomposeuse',
    'photocopiassent',
    'photocopiassiez',
    'photocopierions',
    'photoélectrique',
    'photogrammétrie',
    'photographiâmes',
    'photographiasse',
    'photographiâtes',
    'photographierai',
    'photographieras',
    'photographierez',
    'photographiions',
    'photographiques',
    'photomécaniques',
    'photopériodisme',
    'phraséologiques',
    'phylloxériennes',
    'phylogénétiques',
    'physiognomonies',
    'physiothérapies',
    'phytosanitaires',
    'pictographiques',
    'pieds-de-cheval',
    'pieds-de-chèvre',
    'pieds-de-mouton',
    'piézoélectrique',
    'pince-sans-rire',
    'pindarisassions',
    'pindariseraient',
    'pique-assiettes',
    'pique-niquaient',
    'pique-niquasses',
    'pique-niquerais',
    'pique-niquerait',
    'pique-niquèrent',
    'pique-niqueriez',
    'pique-niquerons',
    'pique-niqueront',
    'pique-niqueuses',
    'pirouettassions',
    'pirouetteraient',
    'pithécanthropes',
    'pittoresquement',
    'plaisantassions',
    'plaisanteraient',
    'planchéiaissent',
    'planchéiaissiez',
    'planchéiassions',
    'planchéieraient',
    'planétarisation',
    'planificatrices',
    'plantureusement',
    'plasmifiassions',
    'plasmifieraient',
    'plastifiassions',
    'plastifieraient',
    'plastiquassions',
    'plastiqueraient',
    'plastronnassent',
    'plastronnassiez',
    'plastronnerions',
    'platinisassions',
    'platiniseraient',
    'plébiscitassent',
    'plébiscitassiez',
    'plébisciterions',
    'pleurnichassent',
    'pleurnichassiez',
    'pleurnichements',
    'pleurnicherions',
    'pleuropneumonie',
    'ploutocratiques',
    'pluricellulaire',
    'plurinationales',
    'pluviométriques',
    'pneumogastrique',
    'poignardassions',
    'poignarderaient',
    'poinçonnassions',
    'poinçonneraient',
    'pointillassions',
    'pointilleraient',
    'points-virgules',
    'poireautassions',
    'poireauteraient',
    'polémiquassions',
    'polémiqueraient',
    'poliomyélitique',
    'polissonnassent',
    'polissonnassiez',
    'polissonnerions',
    'politiquassions',
    'politiqueraient',
    'polycopiassions',
    'polycopieraient',
    'polymérisassent',
    'polymérisassiez',
    'polymérisations',
    'polymériserions',
    'polysaccharides',
    'polysyllabiques',
    'polysynthétique',
    'polytechniciens',
    'polytraumatisée',
    'polytraumatisés',
    'ponctionnassent',
    'ponctionnassiez',
    'ponctionnerions',
    'popularisassent',
    'popularisassiez',
    'populariserions',
    'populationniste',
    'pornographiques',
    'porphyrisassent',
    'porphyrisassiez',
    'porphyriserions',
    'porphyrogénètes',
    'porte-aiguilles',
    'porte-bannières',
    'porte-documents',
    'porte-étendards',
    'porte-étrivière',
    'portes-fenêtres',
    'portraituraient',
    'portraiturasses',
    'portraiturèrent',
    'positionnassent',
    'positionnassiez',
    'positionnements',
    'positionnerions',
    'possessionnelle',
    'postcombustions',
    'postérieurement',
    'postillonnaient',
    'postillonnasses',
    'postillonnerais',
    'postillonnerait',
    'postillonnèrent',
    'postillonneriez',
    'postillonnerons',
    'postillonneront',
    'postopératoires',
    'postromantiques',
    'postsynchronisa',
    'postsynchronise',
    'postsynchronisé',
    'potentialisâmes',
    'potentialisasse',
    'potentialisâtes',
    'potentialiserai',
    'potentialiseras',
    'potentialiserez',
    'potentialisions',
    'potentiellement',
    'pourchassassent',
    'pourchassassiez',
    'pourchasserions',
    'pourfendissions',
    'pourléchassions',
    'pourlécheraient',
    'poursuivissions',
    'pousse-cailloux',
    'préachetassions',
    'préachèteraient',
    'précarisassions',
    'précariseraient',
    'précautionnâmes',
    'précautionnasse',
    'précautionnâtes',
    'précautionnerai',
    'précautionneras',
    'précautionnerez',
    'précautionneuse',
    'précautionnions',
    'préchauffassent',
    'préchauffassiez',
    'préchaufferions',
    'précipitassions',
    'précipiteraient',
    'précolombiennes',
    'précomptassions',
    'précompteraient',
    'préconisassions',
    'préconiseraient',
    'prédéfiniraient',
    'prédéfinissante',
    'prédéfinissants',
    'prédéfinissions',
    'prédestinassent',
    'prédestinassiez',
    'prédestinations',
    'prédestinerions',
    'prédéterminâmes',
    'prédéterminasse',
    'prédéterminâtes',
    'prédéterminerai',
    'prédétermineras',
    'prédéterminerez',
    'prédéterminions',
    'prédisposassent',
    'prédisposassiez',
    'prédisposerions',
    'prédispositions',
    'prédominassions',
    'prédomineraient',
    'préétabliraient',
    'préétablissions',
    'préexistassions',
    'préexisteraient',
    'préfabrications',
    'préférentielles',
    'préfigurassions',
    'préfigureraient',
    'préfinancements',
    'préhistoriennes',
    'préjudiciassent',
    'préjudiciassiez',
    'préjudicierions',
    'préméditassions',
    'préméditeraient',
    'prémenstruelles',
    'préoccupassions',
    'préoccuperaient',
    'prépositivement',
    'prépsychotiques',
    'presbytériennes',
    'prescrivissions',
    'présidentiables',
    'présidentielles',
    'présonorisation',
    'pressentiraient',
    'pressentissions',
    'presse-raquette',
    'pressurisassent',
    'pressurisassiez',
    'pressurisations',
    'pressuriserions',
    'présupposassent',
    'présupposassiez',
    'présupposerions',
    'présuppositions',
    'prévaricatrices',
    'prévariquassent',
    'prévariquassiez',
    'prévariquerions',
    'prévisionnelles',
    'primarisassions',
    'primariseraient',
    'primo-infection',
    'printanisations',
    'prioritairement',
    'privatisassions',
    'privatiseraient',
    'privilégiassent',
    'privilégiassiez',
    'privilégierions',
    'processionnaire',
    'processionnâmes',
    'processionnasse',
    'processionnâtes',
    'processionnelle',
    'processionnerai',
    'processionneras',
    'processionnerez',
    'processionnions',
    'procrastination',
    'procurerassions',
    'prodigieusement',
    'professionnelle',
    'programmassions',
    'programmatrices',
    'programmeraient',
    'progressassions',
    'progresseraient',
    'progressivement',
    'projectionniste',
    'prolétarisaient',
    'prolétarisasses',
    'prolétarisation',
    'prolétariserais',
    'prolétariserait',
    'prolétarisèrent',
    'prolétariseriez',
    'prolétariserons',
    'prolétariseront',
    'proliférassions',
    'proliféreraient',
    'prolongeassions',
    'promotionnelles',
    'promulguassions',
    'promulgueraient',
    'pronominalement',
    'pronostiquaient',
    'pronostiquasses',
    'pronostiquerais',
    'pronostiquerait',
    'pronostiquèrent',
    'pronostiqueriez',
    'pronostiquerons',
    'pronostiqueront',
    'pronostiqueuses',
    'pronunciamiento',
    'propharmacienne',
    'prophétiquement',
    'prophétisassent',
    'prophétisassiez',
    'prophétiserions',
    'prophylactiques',
    'proportionnâmes',
    'proportionnasse',
    'proportionnâtes',
    'proportionnelle',
    'proportionnerai',
    'proportionneras',
    'proportionnerez',
    'proportionnions',
    'propositionnels',
    'proprioceptives',
    'proscrivissions',
    'prospectassions',
    'prospecteraient',
    'prostaglandines',
    'prostatectomies',
    'prosternassions',
    'prosterneraient',
    'prostituassions',
    'prostitueraient',
    'protectionnisme',
    'protectionniste',
    'protège-cahiers',
    'protestantismes',
    'protohistorique',
    'proverbialement',
    'proverbialisais',
    'proverbialisait',
    'proverbialisant',
    'proverbialisées',
    'proverbialisent',
    'proverbialisera',
    'proverbialisiez',
    'proverbialisons',
    'providentielles',
    'provincialismes',
    'provisionnelles',
    'psalmodiassions',
    'psalmodieraient',
    'pseudo-bulbaire',
    'pseudomembranes',
    'psychanalysâmes',
    'psychanalysasse',
    'psychanalysâtes',
    'psychanalyserai',
    'psychanalyseras',
    'psychanalyserez',
    'psychanalysions',
    'psychanalytique',
    'psychiatrisâmes',
    'psychiatrisasse',
    'psychiatrisâtes',
    'psychiatriserai',
    'psychiatriseras',
    'psychiatriserez',
    'psychiatrisions',
    'psychobiologies',
    'psychochirurgie',
    'psychocritiques',
    'psycho-éducatif',
    'psycholeptiques',
    'psychométriques',
    'psychomotricité',
    'psychopédagogie',
    'psychophysiques',
    'psychorigidités',
    'psychosensoriel',
    'psychosomatique',
    'psychotechnique',
    'psychothérapies',
    'pulvérisassions',
    'pulvériseraient',
    'putrescibilités',
    'pyrogravassions',
    'pyrograveraient',
    'pythagoricienne',
    'quadragésimales',
    'quadrangulaires',
    'quadrillassions',
    'quadrilleraient',
    'quadriréacteurs',
    'quadruplassions',
    'quadrupleraient',
    'qualitativement',
    'quantifiassions',
    'quantificateurs',
    'quantifications',
    'quantifieraient',
    'quarante-quatre',
    'quarderonnaient',
    'quarderonnasses',
    'quarderonnerais',
    'quarderonnerait',
    'quarderonnèrent',
    'quarderonneriez',
    'quarderonnerons',
    'quarderonneront',
    'quartageassions',
    'quartier-maître',
    'quatorzièmement',
    'quatre-feuilles',
    'quatre-vingt-un',
    'questionnassent',
    'questionnassiez',
    'questionnements',
    'questionnerions',
    'queue-de-cheval',
    'queue-de-cochon',
    'queue-de-renard',
    'queues-de-morue',
    'quinquagénaires',
    'quintessenciais',
    'quintessenciait',
    'quintessenciant',
    'quintessenciées',
    'quintessencient',
    'quintessenciera',
    'quintessenciiez',
    'quintessencions',
    'quintuplassions',
    'quintupleraient',
    'quittançassions',
    'quittanceraient',
    'quotidiennement',
    'rabibochassions',
    'rabibocheraient',
    'rabougrissaient',
    'rabougrissement',
    'raccommodassent',
    'raccommodassiez',
    'raccommodements',
    'raccommoderions',
    'raccompagnaient',
    'raccompagnasses',
    'raccompagnerais',
    'raccompagnerait',
    'raccompagnèrent',
    'raccompagneriez',
    'raccompagnerons',
    'raccompagneront',
    'raccourciraient',
    'raccourcissions',
    'raccoutrassions',
    'raccoutreraient',
    'raccoutumassent',
    'raccoutumassiez',
    'raccoutumerions',
    'raccrochassions',
    'raccrocheraient',
    'rachianesthésie',
    'racornissements',
    'radicalisassent',
    'radicalisassiez',
    'radicalisations',
    'radicaliserions',
    'radiesthésistes',
    'radioalignement',
    'radioaltimètres',
    'radioastronomes',
    'radioastronomie',
    'radiobalisaient',
    'radiobalisasses',
    'radiobaliserais',
    'radiobaliserait',
    'radiobalisèrent',
    'radiobaliseriez',
    'radiobaliserons',
    'radiobaliseront',
    'radioconducteur',
    'radiodiagnostic',
    'radiodiffusâmes',
    'radiodiffusasse',
    'radiodiffusâtes',
    'radiodiffuserai',
    'radiodiffuseras',
    'radiodiffuserez',
    'radiodiffusions',
    'radioélectrique',
    'radiographiâmes',
    'radiographiasse',
    'radiographiâtes',
    'radiographierai',
    'radiographieras',
    'radiographierez',
    'radiographiions',
    'radioguidassent',
    'radioguidassiez',
    'radioguiderions',
    'radionavigation',
    'radioprotection',
    'radioreportages',
    'radioscopassent',
    'radioscopassiez',
    'radioscoperions',
    'radiotechniques',
    'radiotéléphones',
    'radiotéléphonie',
    'radiotélescopes',
    'radiotélévisées',
    'radiothérapeute',
    'radoucissements',
    'raffermissaient',
    'raffermissement',
    'rafistolassions',
    'rafistoleraient',
    'rafraîchiraient',
    'rafraîchissante',
    'rafraîchissants',
    'rafraîchissions',
    'ragaillardirais',
    'ragaillardirait',
    'ragaillardirent',
    'ragaillardiriez',
    'ragaillardirons',
    'ragaillardiront',
    'ragaillardisses',
    'ragaillardissez',
    'raisonnablement',
    'rajeunissements',
    'ralentissements',
    'rallongeassions',
    'ramasse-miettes',
    'ramasse-monnaie',
    'ramastiquassent',
    'ramastiquassiez',
    'ramatisquerions',
    'ramollissements',
    'randomisassions',
    'randomiseraient',
    'rapapillotaient',
    'rapapillotasses',
    'rapapilloterais',
    'rapapilloterait',
    'rapapillotèrent',
    'rapapilloteriez',
    'rapapilloterons',
    'rapapilloteront',
    'rapetassassions',
    'rapetasseraient',
    'rapetissassions',
    'rapetisseraient',
    'rapiécetassions',
    'rapiécéteraient',
    'rapointissaient',
    'rappareillaient',
    'rappareillasses',
    'rappareillerais',
    'rappareillerait',
    'rappareillèrent',
    'rappareilleriez',
    'rappareillerons',
    'rappareilleront',
    'rappliquassions',
    'rappliqueraient',
    'rapprochassions',
    'rapprocheraient',
    'rappropriassent',
    'rappropriassiez',
    'rapproprierions',
    'rapprovisionnai',
    'rapprovisionnas',
    'rapprovisionnât',
    'rapprovisionnée',
    'rapprovisionner',
    'rapprovisionnes',
    'rapprovisionnés',
    'rapprovisionnez',
    'rassemblassions',
    'rassembleraient',
    'rassérénassions',
    'rasséréneraient',
    'rassortissaient',
    'ratiboisassions',
    'ratiboiseraient',
    'ratiocinassions',
    'ratiocineraient',
    'rationalisaient',
    'rationalisasses',
    'rationalisation',
    'rationaliserais',
    'rationaliserait',
    'rationalisèrent',
    'rationaliseriez',
    'rationaliserons',
    'rationaliseront',
    'rationnellement',
    'raugmentassions',
    'raugmenteraient',
    'ravitaillassent',
    'ravitaillassiez',
    'ravitaillements',
    'ravitaillerions',
    'réabonnerassent',
    'réabonnerassiez',
    'réabsorbassions',
    'réabsorberaient',
    'réaccoutumaient',
    'réaccoutumasses',
    'réaccoutumerais',
    'réaccoutumerait',
    'réaccoutumèrent',
    'réaccoutumeriez',
    'réaccoutumerons',
    'réaccoutumeront',
    'réactualisaient',
    'réactualisasses',
    'réactualiserais',
    'réactualiserait',
    'réactualisèrent',
    'réactualiseriez',
    'réactualiserons',
    'réactualiseront',
    'réaffectassions',
    'réaffecteraient',
    'réaffirmassions',
    'réaffirmeraient',
    'réaménageassent',
    'réaménageassiez',
    'réaménageraient',
    'réapparaissions',
    'réapparaîtrions',
    'réapprendraient',
    'réapprovisionna',
    'réapprovisionne',
    'réapprovisionné',
    'réargentassions',
    'réargenteraient',
    'réarrangeassent',
    'réarrangeassiez',
    'réarrangeraient',
    'réassignassions',
    'réassigneraient',
    'réassortiraient',
    'réassortissions',
    'rebaptisassions',
    'rebaptiseraient',
    'reblanchiraient',
    'reblanchissions',
    'rebondissements',
    'reboutonnassent',
    'reboutonnassiez',
    'reboutonnerions',
    'rebroussassions',
    'rebrousseraient',
    'recachetassions',
    'recachetterions',
    'recalcifiassent',
    'recalcifiassiez',
    'recalcification',
    'recalcifierions',
    'recalculassions',
    'recalculeraient',
    'récapitulassent',
    'récapitulassiez',
    'récapitulations',
    'récapitulatives',
    'récapitulerions',
    'recarrelassions',
    'recarrellerions',
    'réceptionnaient',
    'réceptionnaires',
    'réceptionnasses',
    'réceptionnerais',
    'réceptionnerait',
    'réceptionnèrent',
    'réceptionneriez',
    'réceptionnerons',
    'réceptionneront',
    'réceptionnistes',
    'rechampissaient',
    'réchampissaient',
    'rechangeassions',
    'rechargeassions',
    'réchauffassions',
    'réchaufferaient',
    'rechaussassions',
    'rechausseraient',
    'recherchassions',
    'rechercheraient',
    'rechristianisai',
    'rechristianisas',
    'rechristianisât',
    'rechristianisée',
    'rechristianiser',
    'rechristianises',
    'rechristianisés',
    'rechristianisez',
    'réciproquassent',
    'réciproquassiez',
    'réciproquerions',
    'réclusionnaires',
    'recombinassions',
    'recombineraient',
    'recommandassent',
    'recommandassiez',
    'recommandations',
    'recommanderions',
    'recommençassent',
    'recommençassiez',
    'recommencements',
    'recommencerions',
    'recomparaissais',
    'recomparaissait',
    'recomparaissant',
    'recomparaissent',
    'recomparaissiez',
    'recomparaissons',
    'recomparaîtrais',
    'recomparaîtrait',
    'recomparaîtriez',
    'recomparaîtrons',
    'recomparaîtront',
    'recomparussions',
    'récompensassent',
    'récompensassiez',
    'récompenserions',
    'recompilassions',
    'recompileraient',
    'recomposassions',
    'recomposeraient',
    'réconciliassent',
    'réconciliassiez',
    'réconciliateurs',
    'réconciliations',
    'réconciliatrice',
    'réconcilierions',
    'recondamnassent',
    'recondamnassiez',
    'recondamnerions',
    'reconduisissent',
    'reconduisissiez',
    'réconfortassent',
    'réconfortassiez',
    'réconforterions',
    'recongelassions',
    'recongèleraient',
    'reconnaissables',
    'reconnaissaient',
    'reconnaissances',
    'reconnaissantes',
    'reconnaîtraient',
    'reconnectassent',
    'reconnectassiez',
    'reconnecterions',
    'reconquerraient',
    'reconsidéraient',
    'reconsidérasses',
    'reconsidérerais',
    'reconsidérerait',
    'reconsidérèrent',
    'reconsidéreriez',
    'reconsidérerons',
    'reconsidéreront',
    'reconsolidaient',
    'reconsolidasses',
    'reconsoliderais',
    'reconsoliderait',
    'reconsolidèrent',
    'reconsolideriez',
    'reconsoliderons',
    'reconsolideront',
    'reconstituaient',
    'reconstituantes',
    'reconstituasses',
    'reconstituerais',
    'reconstituerait',
    'reconstituèrent',
    'reconstitueriez',
    'reconstituerons',
    'reconstitueront',
    'reconstitutions',
    'reconstructions',
    'reconstruirions',
    'reconstruisîmes',
    'reconstruisions',
    'reconstruisisse',
    'reconstruisîtes',
    'reconventionnel',
    'reconvertirions',
    'reconvertissais',
    'reconvertissait',
    'reconvertissant',
    'reconvertissent',
    'reconvertissiez',
    'reconvertissons',
    'recoquillassent',
    'recoquillassiez',
    'recoquillerions',
    'recorrigeassent',
    'recorrigeassiez',
    'recorrigeraient',
    'recoucherassent',
    'recoucherassiez',
    'récriminassions',
    'récriminatrices',
    'récrimineraient',
    'recristallisais',
    'recristallisait',
    'recristallisant',
    'recristallisées',
    'recristallisent',
    'recristallisera',
    'recristallisiez',
    'recristallisons',
    'recroquevillais',
    'recroquevillait',
    'recroquevillant',
    'recroquevillées',
    'recroquevillent',
    'recroquevillera',
    'recroquevilliez',
    'recroquevillons',
    'rectificatrices',
    'recueilleraient',
    'recueillissions',
    'reculottassions',
    'reculotteraient',
    'rédactionnelles',
    'redéfinissaient',
    'redemandassions',
    'redemanderaient',
    'redémarrassions',
    'redémarreraient',
    'redémolissaient',
    'redémontrassent',
    'redémontrassiez',
    'redémontrerions',
    'redéploieraient',
    'redéployassions',
    'redescendissent',
    'redescendissiez',
    'redescendraient',
    'redeviendraient',
    'rédintégrations',
    'rediscutassions',
    'rediscuteraient',
    'redistribuaient',
    'redistribuasses',
    'redistribuerais',
    'redistribuerait',
    'redistribuèrent',
    'redistribueriez',
    'redistribuerons',
    'redistribueront',
    'redistributions',
    'réductionnismes',
    'réembauchassent',
    'réembauchassiez',
    'réembaucherions',
    'réemploieraient',
    'réemployassions',
    'réempruntassent',
    'réempruntassiez',
    'réemprunterions',
    'réengageassions',
    'réenregistrâmes',
    'réenregistrasse',
    'réenregistrâtes',
    'réenregistrerai',
    'réenregistreras',
    'réenregistrerez',
    'réenregistrions',
    'réensemençaient',
    'réensemençasses',
    'réensemencement',
    'réensemencerais',
    'réensemencerait',
    'réensemencèrent',
    'réensemenceriez',
    'réensemencerons',
    'réensemenceront',
    'rééquilibraient',
    'rééquilibrasses',
    'rééquilibrèrent',
    'réescomptassent',
    'réescomptassiez',
    'réescompterions',
    'réexaminassions',
    'réexamineraient',
    'réexpédiassions',
    'réexpédieraient',
    'réexportassions',
    'réexporteraient',
    'refaçonnassions',
    'refaçonneraient',
    'référençassions',
    'référenceraient',
    'réfléchissaient',
    'réfléchissantes',
    'refleurissaient',
    'refleurissement',
    'réflexothérapie',
    'reformulassions',
    'reformuleraient',
    'refouillassions',
    'refouilleraient',
    'réfrangibilités',
    'réfrigérassions',
    'réfrigéreraient',
    'refroidissaient',
    'refroidissement',
    'regazonnassions',
    'regazonneraient',
    'régionalisaient',
    'régionalisasses',
    'régionalisation',
    'régionaliserais',
    'régionaliserait',
    'régionalisèrent',
    'régionaliseriez',
    'régionaliserons',
    'régionaliseront',
    'réglementassent',
    'réglementassiez',
    'réglementations',
    'réglementerions',
    'regrettablement',
    'regrossissaient',
    'régularisassent',
    'régularisassiez',
    'régularisations',
    'régulariserions',
    'régurgitassions',
    'régurgiteraient',
    'réhabilitassent',
    'réhabilitassiez',
    'réhabilitations',
    'réhabiliterions',
    'réhabituassions',
    'réhabitueraient',
    'réhydratassions',
    'réhydrateraient',
    'réimplantassent',
    'réimplantassiez',
    'réimplanterions',
    'réimportassions',
    'réimporteraient',
    'réimprimassions',
    'réimprimeraient',
    'réincarcéraient',
    'réincarcérasses',
    'réincarcérerais',
    'réincarcérerait',
    'réincarcérèrent',
    'réincarcéreriez',
    'réincarcérerons',
    'réincarcéreront',
    'réincarnassions',
    'réincarneraient',
    'réincorporaient',
    'réincorporasses',
    'réincorporerais',
    'réincorporerait',
    'réincorporèrent',
    'réincorporeriez',
    'réincorporerons',
    'réincorporeront',
    'reines-des-prés',
    'réinfectassions',
    'réinfecteraient',
    'réinitialisâmes',
    'réinitialisasse',
    'réinitialisâtes',
    'réinitialiserai',
    'réinitialiseras',
    'réinitialiserez',
    'réinitialisions',
    'réinjectassions',
    'réinjecteraient',
    'réinscrivissent',
    'réinscrivissiez',
    'réinstallassent',
    'réinstallassiez',
    'réinstallations',
    'réinstallerions',
    'réintégrassions',
    'réintégreraient',
    'réinterprétâmes',
    'réinterprétasse',
    'réinterprétâtes',
    'réinterpréterai',
    'réinterpréteras',
    'réinterpréterez',
    'réinterprétions',
    'réintroductions',
    'réintroduirions',
    'réintroduisîmes',
    'réintroduisions',
    'réintroduisisse',
    'réintroduisîtes',
    'réinventassions',
    'réinventeraient',
    'réinvestiraient',
    'réinvestissions',
    'rejaillissaient',
    'rejaillissement',
    'rejointoierions',
    'rejointoyassent',
    'rejointoyassiez',
    'relativisassent',
    'relativisassiez',
    'relativisations',
    'relativiserions',
    'remaquillassent',
    'remaquillassiez',
    'remaquillerions',
    'remarquablement',
    'remastiquassent',
    'remastiquassiez',
    'remastiquerions',
    'rembarquassions',
    'rembarqueraient',
    'rembougeassions',
    'rembourrassions',
    'rembourreraient',
    'remboursassions',
    'rembourseraient',
    'rembrunissaient',
    'remilitarisâmes',
    'remilitarisasse',
    'remilitarisâtes',
    'remilitariserai',
    'remilitariseras',
    'remilitariserez',
    'remilitarisions',
    'remmaillassions',
    'remmailleraient',
    'remmaillotaient',
    'remmaillotasses',
    'remmailloterais',
    'remmailloterait',
    'remmaillotèrent',
    'remmailloteriez',
    'remmailloterons',
    'remmailloteront',
    'remmanchassions',
    'remmancheraient',
    'remouillassions',
    'remouilleraient',
    'rempaillassions',
    'rempaillerasses',
    'rempaillerèrent',
    'rempaquetassent',
    'rempaquetassiez',
    'rempaquetterais',
    'rempaquetterait',
    'rempaquetteriez',
    'rempaquetterons',
    'rempaquetteront',
    'rempoissonnâmes',
    'rempoissonnasse',
    'rempoissonnâtes',
    'rempoissonnerai',
    'rempoissonneras',
    'rempoissonnerez',
    'rempoissonnions',
    'rempruntassions',
    'remprunteraient',
    'rencaissassions',
    'rencaisseraient',
    'renchaînassions',
    'renchaîneraient',
    'renchérissaient',
    'renchérissement',
    'renchérisseuses',
    'rencontrassions',
    'rencontreraient',
    'renégociassions',
    'renégocieraient',
    'renflammassions',
    'renflammeraient',
    'renformirassent',
    'renformirassiez',
    'renformissaient',
    'renfrognassions',
    'renfrogneraient',
    'rengorgeassions',
    'rengraciassions',
    'rengracieraient',
    'rengraissassent',
    'rengraissassiez',
    'rengraisserions',
    'renouvelassions',
    'renouvellements',
    'renouvellerions',
    'renquillassions',
    'renquilleraient',
    'renseignassions',
    'renseigneraient',
    'rentabilisaient',
    'rentabilisasses',
    'rentabilisation',
    'rentabiliserais',
    'rentabiliserait',
    'rentabilisèrent',
    'rentabiliseriez',
    'rentabiliserons',
    'rentabiliseront',
    'rentrouvririons',
    'rentrouvrissent',
    'rentrouvrissiez',
    'renveloppassent',
    'renveloppassiez',
    'renvelopperions',
    'renvenimassions',
    'renvenimeraient',
    'renvergeassions',
    'réorchestraient',
    'réorchestrasses',
    'réorchestrerais',
    'réorchestrerait',
    'réorchestrèrent',
    'réorchestreriez',
    'réorchestrerons',
    'réorchestreront',
    'réordonnançâmes',
    'réordonnançasse',
    'réordonnançâtes',
    'réordonnancerai',
    'réordonnanceras',
    'réordonnancerez',
    'réordonnancions',
    'réordonnassions',
    'réordonneraient',
    'réorganisassent',
    'réorganisassiez',
    'réorganisateurs',
    'réorganisations',
    'réorganisatrice',
    'réorganiserions',
    'réorientassions',
    'réorienteraient',
    'repartageassent',
    'repartageassiez',
    'repartageraient',
    'répercutassions',
    'répercuteraient',
    'répertoriassent',
    'répertoriassiez',
    'répertorierions',
    'replongeassions',
    'repositionnâmes',
    'repositionnasse',
    'repositionnâtes',
    'repositionnerai',
    'repositionneras',
    'repositionnerez',
    'repositionnions',
    'représentassent',
    'représentassiez',
    'représentations',
    'représentatives',
    'représenterions',
    'réprimandassent',
    'réprimandassiez',
    'réprimanderions',
    'reproduisissent',
    'reproduisissiez',
    'reprogrammaient',
    'reprogrammasses',
    'reprogrammerais',
    'reprogrammerait',
    'reprogrammèrent',
    'reprogrammeriez',
    'reprogrammerons',
    'reprogrammeront',
    'reprographiâmes',
    'reprographiasse',
    'reprographiâtes',
    'reprographierai',
    'reprographieras',
    'reprographierez',
    'reprographiions',
    'républicanisais',
    'républicanisait',
    'républicanisant',
    'républicanisées',
    'républicanisent',
    'républicanisera',
    'républicanisiez',
    'républicanismes',
    'républicanisons',
    'requinquassions',
    'requinqueraient',
    'réquisitionnais',
    'réquisitionnait',
    'réquisitionnant',
    'réquisitionnées',
    'réquisitionnent',
    'réquisitionnera',
    'réquisitionniez',
    'réquisitionnons',
    'réquisitoriales',
    'résinifiassions',
    'résinifieraient',
    'resocialisaient',
    'resocialisasses',
    'resocialiserais',
    'resocialiserait',
    'resocialisèrent',
    'resocialiseriez',
    'resocialiserons',
    'resocialiseront',
    'respectabilités',
    'resplendiraient',
    'resplendissante',
    'resplendissants',
    'resplendissions',
    'responsabilisai',
    'responsabilisas',
    'responsabilisât',
    'responsabilisée',
    'responsabiliser',
    'responsabilises',
    'responsabilisés',
    'responsabilisez',
    'responsabilités',
    'resquillassions',
    'resquilleraient',
    'ressaignassions',
    'ressaigneraient',
    'ressaisissaient',
    'ressaisissement',
    'ressemblassions',
    'ressembleraient',
    'ressemelassions',
    'ressemellerions',
    'ressortissaient',
    'ressortissantes',
    'ressourçassions',
    'ressourceraient',
    'ressouvinssions',
    'ressurgissaient',
    'ressuscitassent',
    'ressuscitassiez',
    'ressusciterions',
    'restreignissent',
    'restreignissiez',
    'restreindraient',
    'restructuraient',
    'restructurasses',
    'restructuration',
    'restructurerais',
    'restructurerait',
    'restructurèrent',
    'restructureriez',
    'restructurerons',
    'restructureront',
    'resurchauffâmes',
    'resurchauffasse',
    'resurchauffâtes',
    'resurchaufferai',
    'resurchaufferas',
    'resurchaufferez',
    'resurchauffions',
    'résurrectionnel',
    'rétablissements',
    'retapissassions',
    'retapisseraient',
    'retentissements',
    'rétractibilités',
    'retraduisissent',
    'retraduisissiez',
    'retranchassions',
    'retrancheraient',
    'retranscririons',
    'retranscrivîmes',
    'retranscrivions',
    'retranscrivisse',
    'retranscrivîtes',
    'retransmetteurs',
    'retransmettions',
    'retransmettrais',
    'retransmettrait',
    'retransmettriez',
    'retransmettrons',
    'retransmettront',
    'retransmissions',
    'retravaillaient',
    'retravaillasses',
    'retravaillerais',
    'retravaillerait',
    'retravaillèrent',
    'retravailleriez',
    'retravaillerons',
    'retravailleront',
    'retraversassent',
    'retraversassiez',
    'retraverserions',
    'rétrécissements',
    'rétroactivement',
    'rétroagissaient',
    'rétrocédassions',
    'rétrocéderaient',
    'rétrogradassent',
    'rétrogradassiez',
    'rétrogradations',
    'rétrograderions',
    'rétroprojecteur',
    'rétropropulsion',
    'retroussassions',
    'retrousseraient',
    'réutilisassions',
    'réutiliseraient',
    'revaccinassions',
    'revaccineraient',
    'revalorisassent',
    'revalorisassiez',
    'revalorisations',
    'revaloriserions',
    'réveillonnaient',
    'réveillonnasses',
    'réveillonnerais',
    'réveillonnerait',
    'réveillonnèrent',
    'réveillonneriez',
    'réveillonnerons',
    'réveillonneront',
    'revendicatrices',
    'revendiquassent',
    'revendiquassiez',
    'revendiquerions',
    'réverbérassions',
    'réverbéreraient',
    'révérendissimes',
    'revitalisassent',
    'revitalisassiez',
    'revitalisations',
    'revitaliserions',
    'revivifiassions',
    'revivifieraient',
    'révolutionnaire',
    'révolutionnâmes',
    'révolutionnasse',
    'révolutionnâtes',
    'révolutionnerai',
    'révolutionneras',
    'révolutionnerez',
    'révolutionnions',
    'révolvérisaient',
    'révolvérisasses',
    'révolvériserais',
    'révolvériserait',
    'révolvérisèrent',
    'révolvériseriez',
    'révolvériserons',
    'révolvériseront',
    'rez-de-chaussée',
    'rhabdomancienne',
    'rhinolaryngites',
    'rhinopharyngées',
    'rhinopharyngien',
    'rhinopharyngite',
    'rhombencéphales',
    'rhumatologiques',
    'rhumatologistes',
    'ridiculisassent',
    'ridiculisassiez',
    'ridiculiserions',
    'rigidifiassions',
    'rigidifieraient',
    'ristournassions',
    'ristourneraient',
    'ritualisassions',
    'ritualiseraient',
    'ronchonnassions',
    'ronchonneraient',
    'ronéotypassions',
    'ronéotyperaient',
    'ronflaguassions',
    'ronflagueraient',
    'ronsardisassent',
    'ronsardisassiez',
    'ronsardiserions',
    'rouscaillassent',
    'rouscaillassiez',
    'rouscaillerions',
    'ruisselleraient',
    'saccharifiaient',
    'saccharifiasses',
    'saccharifierais',
    'saccharifierait',
    'saccharifièrent',
    'saccharifieriez',
    'saccharifierons',
    'saccharifieront',
    'sacralisassions',
    'sacraliseraient',
    'sacrificatoires',
    'sacrificatrices',
    'sadomasochismes',
    'sadomasochistes',
    'saint-cyriennes',
    'saint-simoniens',
    'saint-simonisme',
    'salmoniculteurs',
    'salmonicultures',
    'salpêtrisations',
    'sanctifiassions',
    'sanctificateurs',
    'sanctifications',
    'sanctificatrice',
    'sanctifieraient',
    'sanctionnassent',
    'sanctionnassiez',
    'sanctionnerions',
    'sanctuarisaient',
    'sanctuarisasses',
    'sanctuariserais',
    'sanctuariserait',
    'sanctuarisèrent',
    'sanctuariseriez',
    'sanctuariserons',
    'sanctuariseront',
    'sandwichassions',
    'sandwicheraient',
    'saponifiassions',
    'saponifications',
    'saponifieraient',
    'sarcastiquement',
    'sardanapalesque',
    'satellisassions',
    'satelliseraient',
    'saucissonnaient',
    'saucissonnasses',
    'saucissonnerais',
    'saucissonnerait',
    'saucissonnèrent',
    'saucissonneriez',
    'saucissonnerons',
    'saucissonneront',
    'saupoudrassions',
    'saupoudreraient',
    'sauts-de-mouton',
    'sauvegardassent',
    'sauvegardassiez',
    'sauvegarderions',
    'scandaleusement',
    'scandalisassent',
    'scandalisassiez',
    'scandaliserions',
    'schématiquement',
    'schématisassent',
    'schématisassiez',
    'schématisations',
    'schématiserions',
    'science-fiction',
    'scintillassions',
    'scintillerasses',
    'scintillerèrent',
    'scissionnassent',
    'scissionnassiez',
    'scissionnerions',
    'scléroprotéines',
    'scolarisassions',
    'scolariseraient',
    'scolastiquement',
    'scotomisassions',
    'scotomiseraient',
    'scribouillaient',
    'scribouillasses',
    'scribouillerais',
    'scribouillerait',
    'scribouillèrent',
    'scribouilleriez',
    'scribouillerons',
    'scribouilleront',
    'scribouilleuses',
    'scrupuleusement',
    'sécessionnistes',
    'sectionnassions',
    'sectionneraient',
    'sectorisassions',
    'sectoriseraient',
    'sécularisassent',
    'sécularisassiez',
    'sécularisations',
    'séculariserions',
    'sédentarisaient',
    'sédentarisasses',
    'sédentarisation',
    'sédentariserais',
    'sédentariserait',
    'sédentarisèrent',
    'sédentariseriez',
    'sédentariserons',
    'sédentariseront',
    'sédimentassions',
    'sédimenteraient',
    'sélectionnaient',
    'sélectionnasses',
    'sélectionnerais',
    'sélectionnerait',
    'sélectionnèrent',
    'sélectionneriez',
    'sélectionnerons',
    'sélectionneront',
    'sélectionneuses',
    'sélénographique',
    'self-government',
    'self-inductance',
    'self-inductions',
    'semi-auxiliaire',
    'semi-chenillées',
    'semi-circulaire',
    'semi-conducteur',
    'semi-nomadismes',
    'semi-perméables',
    'sensationnelles',
    'sensibilisaient',
    'sensibilisantes',
    'sensibilisasses',
    'sensibilisateur',
    'sensibilisation',
    'sensibiliserais',
    'sensibiliserait',
    'sensibilisèrent',
    'sensibiliseriez',
    'sensibiliserons',
    'sensibiliseront',
    'sensorimétrique',
    'sensorimotrices',
    'sentimentalisme',
    'sentimentalités',
    'septentrionales',
    'séquestrassions',
    'séquestreraient',
    'sergents-majors',
    'sérialisassions',
    'sérialiseraient',
    'séricicultrices',
    'sérodiagnostics',
    'sérovaccination',
    'servomécanismes',
    'sexualisassions',
    'sexualiseraient',
    'shakespearienne',
    'shampooingnâmes',
    'shampooingnasse',
    'shampooingnâtes',
    'shampooingnerai',
    'shampooingneras',
    'shampooingnerez',
    'shampooingnions',
    'shampouinassent',
    'shampouinassiez',
    'shampouinerions',
    'sidérolithiques',
    'sigillographies',
    'signalisassions',
    'signaliseraient',
    'silencieusement',
    'silhouettassent',
    'silhouettassiez',
    'silhouetterions',
    'silicatisassent',
    'silicatisassiez',
    'silicatiserions',
    'simplifiassions',
    'simplificateurs',
    'simplifications',
    'simplificatrice',
    'simplifieraient',
    'singularisaient',
    'singularisasses',
    'singulariserais',
    'singulariserait',
    'singularisèrent',
    'singulariseriez',
    'singulariserons',
    'singulariseront',
    'sintérisassions',
    'sintériseraient',
    'sitogoniomètres',
    'social-chrétien',
    'socialisassions',
    'socialiseraient',
    'socioaffectives',
    'socioculturelle',
    'socio-culturels',
    'socio-éducatifs',
    'socio-éducative',
    'socratisassions',
    'socratiseraient',
    'soit-communiqué',
    'soixante-quatre',
    'soixante-quinze',
    'soixante-treize',
    'solennisassions',
    'solenniseraient',
    'solidarisassent',
    'solidarisassiez',
    'solidariserions',
    'solidifiassions',
    'solidifications',
    'solidifieraient',
    'soliloquassions',
    'soliloqueraient',
    'sollicitassions',
    'solliciteraient',
    'solubilisassent',
    'solubilisassiez',
    'solubilisations',
    'solubiliserions',
    'solutionnassent',
    'solutionnassiez',
    'solutionnerions',
    'somatotrophines',
    'sommeillassions',
    'sommeilleraient',
    'sonnaillassions',
    'sonnailleraient',
    'sophistications',
    'sophistiquaient',
    'sophistiquasses',
    'sophistiquerais',
    'sophistiquerait',
    'sophistiquèrent',
    'sophistiqueriez',
    'sophistiquerons',
    'sophistiqueront',
    'sorties-de-bain',
    'soubresautaient',
    'soubresautasses',
    'soubresauterais',
    'soubresauterait',
    'soubresautèrent',
    'soubresauteriez',
    'soubresauterons',
    'soubresauteront',
    'souchetteraient',
    'souffletassions',
    'souffletterions',
    'souffre-douleur',
    'soumissionnaire',
    'soumissionnâmes',
    'soumissionnasse',
    'soumissionnâtes',
    'soumissionnerai',
    'soumissionneras',
    'soumissionnerez',
    'soumissionnions',
    'soupçonnassions',
    'soupçonneraient',
    'sourcillassions',
    'sourcilleraient',
    'sourdes-muettes',
    'sous-admissible',
    'sous-alimentais',
    'sous-alimentait',
    'sous-alimentant',
    'sous-alimentées',
    'sous-alimentent',
    'sous-alimentera',
    'sous-alimentiez',
    'sous-alimentons',
    'sous-amendement',
    'sous-arbrisseau',
    'sous-brigadiers',
    'sous-commission',
    'souscrivissions',
    'sous-développée',
    'sous-développés',
    'sous-directeurs',
    'sous-directrice',
    'sous-dominantes',
    'sous-entendîmes',
    'sous-entendions',
    'sous-entendisse',
    'sous-entendîtes',
    'sous-entendrais',
    'sous-entendrait',
    'sous-entendriez',
    'sous-entendrons',
    'sous-entendront',
    'sous-équipement',
    'sous-estimaient',
    'sous-estimasses',
    'sous-estimation',
    'sous-estimerais',
    'sous-estimerait',
    'sous-estimèrent',
    'sous-estimeriez',
    'sous-estimerons',
    'sous-estimeront',
    'sous-évaluaient',
    'sous-évaluasses',
    'sous-évaluation',
    'sous-évaluerais',
    'sous-évaluerait',
    'sous-évaluèrent',
    'sous-évalueriez',
    'sous-évaluerons',
    'sous-évalueront',
    'sous-exploitais',
    'sous-exploitait',
    'sous-exploitant',
    'sous-exploitées',
    'sous-exploitent',
    'sous-exploitera',
    'sous-exploitiez',
    'sous-exploitons',
    'sous-exposaient',
    'sous-exposasses',
    'sous-exposerais',
    'sous-exposerait',
    'sous-exposèrent',
    'sous-exposeriez',
    'sous-exposerons',
    'sous-exposeront',
    'sous-exposition',
    'sous-expression',
    'sous-frutescent',
    'sous-gouverneur',
    'sous-imposition',
    'sous-ingénieurs',
    'sous-intendants',
    'sous-lieutenant',
    'sous-locataires',
    'sous-louassions',
    'sous-loueraient',
    'sous-maîtresses',
    'sous-occipitale',
    'sous-occipitaux',
    'sous-orbitaires',
    'sous-paieraient',
    'sous-payassions',
    'sous-payeraient',
    'sous-peuplement',
    'sous-préfecture',
    'sous-production',
    'sous-programmes',
    'sous-prolétaire',
    'sous-répertoire',
    'sous-scapulaire',
    'sous-secrétaire',
    'sous-tendissent',
    'sous-tendissiez',
    'sous-tendraient',
    'sous-titrassent',
    'sous-titrassiez',
    'sous-titrerions',
    'sous-traitaient',
    'sous-traitances',
    'sous-traitantes',
    'sous-traitasses',
    'sous-traiterais',
    'sous-traiterait',
    'sous-traitèrent',
    'sous-traiteriez',
    'sous-traiterons',
    'sous-traiteront',
    'sous-utilisâmes',
    'sous-utilisasse',
    'sous-utilisâtes',
    'sous-utiliserai',
    'sous-utiliseras',
    'sous-utiliserez',
    'sous-utilisions',
    'sous-ventrières',
    'sous-virassions',
    'sous-vireraient',
    'souterrainement',
    'soviétisassions',
    'soviétiseraient',
    'spathifiassions',
    'spathifieraient',
    'spatialisassent',
    'spatialisassiez',
    'spatialisations',
    'spatialiserions',
    'spatio-temporel',
    'spécialisassent',
    'spécialisassiez',
    'spécialisations',
    'spécialiserions',
    'spectroscopique',
    'sphinctériennes',
    'spiritualisâmes',
    'spiritualisasse',
    'spiritualisâtes',
    'spiritualiserai',
    'spiritualiseras',
    'spiritualiserez',
    'spiritualisions',
    'spirituellement',
    'sponsorisassent',
    'sponsorisassiez',
    'sponsoriserions',
    'squattérisaient',
    'squattérisasses',
    'squattériserais',
    'squattériserait',
    'squattérisèrent',
    'squattériseriez',
    'squattériserons',
    'squattériseront',
    'stabilisassions',
    'stabilisatrices',
    'stabiliseraient',
    'stalagmométries',
    'stalinisassions',
    'staliniseraient',
    'standardisaient',
    'standardisasses',
    'standardisation',
    'standardiserais',
    'standardiserait',
    'standardisèrent',
    'standardiseriez',
    'standardiserons',
    'standardiseront',
    'staphylococcies',
    'starting-blocks',
    'stationnassions',
    'stationneraient',
    'station-service',
    'statisticiennes',
    'statistiquement',
    'sténographiâmes',
    'sténographiasse',
    'sténographiâtes',
    'sténographierai',
    'sténographieras',
    'sténographierez',
    'sténographiions',
    'sténographiques',
    'sténotypassions',
    'sténotyperaient',
    'stéréométriques',
    'stéréophoniques',
    'stéréoscopiques',
    'stéréotypassent',
    'stéréotypassiez',
    'stéréotyperions',
    'stérilisassions',
    'stériliseraient',
    'stigmatisassent',
    'stigmatisassiez',
    'stigmatisations',
    'stigmatiserions',
    'stipendiassions',
    'stipendieraient',
    'stoechiométries',
    'stomatologistes',
    'stomatoplasties',
    'strangulassions',
    'stranguleraient',
    'stratégiquement',
    'stratifiassions',
    'stratifications',
    'stratifieraient',
    'stratigraphiqus',
    'stratosphérique',
    'streptobacilles',
    'streptococcique',
    'strip-teaseuses',
    'structuralismes',
    'structuralistes',
    'structurassions',
    'structureraient',
    'structurologies',
    'stylisticiennes',
    'stylographiques',
    'subantarctiques',
    'subdéléguassent',
    'subdéléguassiez',
    'subdéléguerions',
    'subdivisassions',
    'subdiviseraient',
    'subéquatoriales',
    'submergeassions',
    'subordonnassent',
    'subordonnassiez',
    'subordonnerions',
    'subsidiairement',
    'substantialisme',
    'substantialiste',
    'substantialités',
    'substantifiques',
    'substantivaient',
    'substantivasses',
    'substantivation',
    'substantivement',
    'substantiverais',
    'substantiverait',
    'substantivèrent',
    'substantiveriez',
    'substantiverons',
    'substantiveront',
    'substituassions',
    'substitueraient',
    'subtilisassions',
    'subtiliseraient',
    'subventionnâmes',
    'subventionnasse',
    'subventionnâtes',
    'subventionnelle',
    'subventionnerai',
    'subventionneras',
    'subventionnerez',
    'subventionnions',
    'subvertissaient',
    'successibilités',
    'sud-américaines',
    'sud-vietnamiens',
    'suggestibilités',
    'suggestionnâmes',
    'suggestionnasse',
    'suggestionnâtes',
    'suggestionnerai',
    'suggestionneras',
    'suggestionnerez',
    'suggestionnions',
    'sulfhémoglobine',
    'sulfocarbonates',
    'sulfocarbonique',
    'supercarburants',
    'superdividendes',
    'superfétatoires',
    'superficialités',
    'superfiniraient',
    'superfinissions',
    'superforteresse',
    'superhétérodyne',
    'superlativement',
    'superphosphates',
    'superposassions',
    'superposeraient',
    'superproduction',
    'superpuissances',
    'superstitieuses',
    'superstructures',
    'supervisassions',
    'superviseraient',
    'supplantassions',
    'supplanteraient',
    'supplémentaient',
    'supplémentaires',
    'supplémentasses',
    'supplémenterais',
    'supplémenterait',
    'supplémentèrent',
    'supplémenteriez',
    'supplémenterons',
    'supplémenteront',
    'suppliciassions',
    'supplicieraient',
    'supraconduction',
    'supraliminaires',
    'supranationales',
    'suprasegmentale',
    'suprasegmentaux',
    'supraterrestres',
    'surabondassions',
    'surabonderaient',
    'surajoutassions',
    'surajouteraient',
    'suralimentaient',
    'suralimentasses',
    'suralimentation',
    'suralimenterais',
    'suralimenterait',
    'suralimentèrent',
    'suralimenteriez',
    'suralimenterons',
    'suralimenteront',
    'surbaissassions',
    'surbaisseraient',
    'surchargeassent',
    'surchargeassiez',
    'surchargeraient',
    'surchauffassent',
    'surchauffassiez',
    'surchaufferions',
    'surclassassions',
    'surclasseraient',
    'surcompensation',
    'surcompressions',
    'surcomprimaient',
    'surcomprimasses',
    'surcomprimerais',
    'surcomprimerait',
    'surcomprimèrent',
    'surcomprimeriez',
    'surcomprimerons',
    'surcomprimeront',
    'surconsommation',
    'surcontrassions',
    'surcontreraient',
    'surdéterminante',
    'surdéterminants',
    'surédifiassions',
    'surédifieraient',
    'surenchéririons',
    'surenchérissais',
    'surenchérissait',
    'surenchérissant',
    'surenchérissent',
    'surenchérisseur',
    'surenchérissiez',
    'surenchérissons',
    'surencombrement',
    'surentraînaient',
    'surentraînasses',
    'surentraînement',
    'surentraînerais',
    'surentraînerait',
    'surentraînèrent',
    'surentraîneriez',
    'surentraînerons',
    'surentraîneront',
    'suréquipassions',
    'suréquiperaient',
    'surestimassions',
    'surestimeraient',
    'surévaluassions',
    'surévalueraient',
    'surexcitassions',
    'surexciteraient',
    'surexploitaient',
    'surexploitasses',
    'surexploitation',
    'surexploiterais',
    'surexploiterait',
    'surexploitèrent',
    'surexploiteriez',
    'surexploiterons',
    'surexploiteront',
    'surexposassions',
    'surexposeraient',
    'surgénératrices',
    'surgeonnassions',
    'surgeonneraient',
    'surhaussassions',
    'surhausseraient',
    'surimposassions',
    'surimposeraient',
    'surmédicalisais',
    'surmédicalisait',
    'surmédicalisant',
    'surmédicalisées',
    'surmédicalisent',
    'surmédicalisera',
    'surmédicalisiez',
    'surmédicalisons',
    'surnaturalismes',
    'surplombassions',
    'surplomberaient',
    'surprise-partie',
    'surproductrices',
    'surprotégeaient',
    'surprotégeasses',
    'surprotégerions',
    'surrégénérateur',
    'sursaturassions',
    'sursatureraient',
    'surveillassions',
    'surveilleraient',
    'susceptibilités',
    'suspicieusement',
    'sweating-system',
    'symbolisassions',
    'symboliseraient',
    'symétrisassions',
    'symétriseraient',
    'sympathiquement',
    'sympathisassent',
    'sympathisassiez',
    'sympathiserions',
    'symptomatologie',
    'synallagmatique',
    'synchronisables',
    'synchronisaient',
    'synchronisasses',
    'synchronisation',
    'synchroniserais',
    'synchroniserait',
    'synchronisèrent',
    'synchroniseriez',
    'synchroniserons',
    'synchroniseront',
    'synchroniseuses',
    'syncristallisai',
    'syncristallisas',
    'syncristallisât',
    'syncristalliser',
    'syncristallises',
    'syncristallisez',
    'syndicalisaient',
    'syndicalisasses',
    'syndicalisation',
    'syndicaliserais',
    'syndicaliserait',
    'syndicalisèrent',
    'syndicaliseriez',
    'syndicaliserons',
    'syndicaliseront',
    'syntacticiennes',
    'synthétiquement',
    'synthétisassent',
    'synthétisassiez',
    'synthétiserions',
    'syntonisassions',
    'syntoniseraient',
    'syphiligraphies',
    'syphilographies',
    'systématisaient',
    'systématisasses',
    'systématisation',
    'systématiserais',
    'systématiserait',
    'systématisèrent',
    'systématiseriez',
    'systématiserons',
    'systématiseront',
    'talentueusement',
    'talkies-walkies',
    'tambourinassent',
    'tambourinassiez',
    'tambourinements',
    'tambourinerions',
    'tambours-majors',
    'tarabiscotaient',
    'tarabiscotasses',
    'tarabiscoterais',
    'tarabiscoterait',
    'tarabiscotèrent',
    'tarabiscoteriez',
    'tarabiscoterons',
    'tarabiscoteront',
    'tarabustassions',
    'tarabusteraient',
    'tatillonnassent',
    'tatillonnassiez',
    'tatillonnerions',
    'taupes-grillons',
    'taylorisassions',
    'tayloriseraient',
    'tchécoslovaques',
    'technétroniques',
    'technicisassent',
    'technicisassiez',
    'techniciserions',
    'technocratiques',
    'technocratisais',
    'technocratisait',
    'technocratisant',
    'technocratisées',
    'technocratisent',
    'technocratisera',
    'technocratisiez',
    'technocratismes',
    'technocratisons',
    'technostructure',
    'télécommandâmes',
    'télécommandasse',
    'télécommandâtes',
    'télécommanderai',
    'télécommanderas',
    'télécommanderez',
    'télécommandions',
    'téléconférences',
    'télécopiassions',
    'télécopieraient',
    'télédiffusaient',
    'télédiffusasses',
    'télédiffuserais',
    'télédiffuserait',
    'télédiffusèrent',
    'télédiffuseriez',
    'télédiffuserons',
    'télédiffuseront',
    'télégraphiaient',
    'télégraphiasses',
    'télégraphierais',
    'télégraphierait',
    'télégraphièrent',
    'télégraphieriez',
    'télégraphierons',
    'télégraphieront',
    'téléguidassions',
    'téléguideraient',
    'télémaintenance',
    'télémécaniciens',
    'télémétrassions',
    'télémétreraient',
    'téléopératrices',
    'téléphonassions',
    'téléphoneraient',
    'télescopassions',
    'télescoperaient',
    'téléspectateurs',
    'téléspectatrice',
    'télétraitements',
    'tellurhydriques',
    'temporisassions',
    'temporisatrices',
    'temporiseraient',
    'tergiversassent',
    'tergiversassiez',
    'tergiversations',
    'tergiverserions',
    'terreautassions',
    'terreauteraient',
    'terre-neuvienne',
    'territorialités',
    'terrorisassions',
    'terroriseraient',
    'tertiairisation',
    'tertiarisations',
    'tête-de-moineau',
    'tétrasyllabique',
    'texturisassions',
    'texturiseraient',
    'théâtralisaient',
    'théâtralisasses',
    'théâtraliserais',
    'théâtraliserait',
    'théâtralisèrent',
    'théâtraliseriez',
    'théâtraliserons',
    'théâtraliseront',
    'thématisassions',
    'thématiseraient',
    'théologiquement',
    'thermochimiques',
    'thermodynamique',
    'thermométriques',
    'thermonucléaire',
    'thermoplastique',
    'thermopropulsée',
    'thermopropulsés',
    'thermopropulsif',
    'thermorésistant',
    'thermostatiques',
    'thermothérapies',
    'thésaurisassent',
    'thésaurisassiez',
    'thésaurisations',
    'thésauriserions',
    'thoracocentèses',
    'thoracoplasties',
    'thromboplastine',
    'thyroglobulines',
    'thyroïdectomies',
    'tiers-mondistes',
    'tintinnabulâmes',
    'tintinnabulante',
    'tintinnabulants',
    'tintinnabulasse',
    'tintinnabulâtes',
    'tintinnabulerai',
    'tintinnabuleras',
    'tintinnabulerez',
    'tintinnabulions',
    'tire-bouchonnai',
    'tirebouchonnais',
    'tirebouchonnait',
    'tirebouchonnant',
    'tire-bouchonnas',
    'tire-bouchonnât',
    'tire-bouchonnée',
    'tirebouchonnées',
    'tirebouchonnent',
    'tire-bouchonner',
    'tirebouchonnera',
    'tire-bouchonnes',
    'tire-bouchonnés',
    'tire-bouchonnez',
    'tirebouchonniez',
    'tirebouchonnons',
    'tiroirs-caisses',
    'titularisassent',
    'titularisassiez',
    'titularisations',
    'titulariserions',
    'topicalisassent',
    'topicalisassiez',
    'topicaliserions',
    'torchonnassions',
    'torchonneraient',
    'tourbillonnaire',
    'tourbillonnâmes',
    'tourbillonnante',
    'tourbillonnants',
    'tourbillonnasse',
    'tourbillonnâtes',
    'tourbillonnerai',
    'tourbillonneras',
    'tourbillonnerez',
    'tourbillonnions',
    'tourillonnaient',
    'tourillonnasses',
    'tourillonnerais',
    'tourillonnerait',
    'tourillonnèrent',
    'tourillonneriez',
    'tourillonnerons',
    'tourillonneront',
    'tourmentassions',
    'tourmenteraient',
    'tournaillassent',
    'tournaillassiez',
    'tournaillerions',
    'tournassassions',
    'tournasseraient',
    'tourneboulaient',
    'tourneboulasses',
    'tourneboulerais',
    'tourneboulerait',
    'tourneboulèrent',
    'tournebouleriez',
    'tourneboulerons',
    'tournebouleront',
    'tourne-feuilles',
    'tournicotassent',
    'tournicotassiez',
    'tournicoterions',
    'tournillassions',
    'tournilleraient',
    'tourniquassions',
    'tourniqueraient',
    'toussaillassent',
    'toussaillassiez',
    'toussaillerions',
    'toute-puissance',
    'toute-puissante',
    'toxicomaniaques',
    'traditionalisme',
    'traditionaliste',
    'traditionnaires',
    'traditionnelles',
    'traficotassions',
    'traficoteraient',
    'traînaillassent',
    'traînaillassiez',
    'traînaillerions',
    'traînassassions',
    'traînasseraient',
    'traîne-semelles',
    'trajectographie',
    'tranchées-abris',
    'tranchefilaient',
    'tranchefilasses',
    'tranchefilerais',
    'tranchefilerait',
    'tranchefilèrent',
    'tranchefileriez',
    'tranchefilerons',
    'tranchefileront',
    'tranquillisâmes',
    'tranquillisante',
    'tranquillisants',
    'tranquillisasse',
    'tranquillisâtes',
    'tranquilliserai',
    'tranquilliseras',
    'tranquilliserez',
    'tranquillisions',
    'transactionnels',
    'transafricaines',
    'transatlantique',
    'transbahutaient',
    'transbahutasses',
    'transbahutement',
    'transbahuterais',
    'transbahuterait',
    'transbahutèrent',
    'transbahuteriez',
    'transbahuterons',
    'transbahuteront',
    'transbordassent',
    'transbordassiez',
    'transbordements',
    'transborderions',
    'transcanadienne',
    'transcaspiennes',
    'transcendantale',
    'transcendantaux',
    'transcendassent',
    'transcendassiez',
    'transcenderions',
    'transcodassions',
    'transcoderaient',
    'transcrivissent',
    'transcrivissiez',
    'transférassions',
    'transférentiels',
    'transféreraient',
    'transfiguraient',
    'transfigurasses',
    'transfigurateur',
    'transfiguration',
    'transfigurerais',
    'transfigurerait',
    'transfigurèrent',
    'transfigureriez',
    'transfigurerons',
    'transfigureront',
    'transfilassions',
    'transfileraient',
    'transformassent',
    'transformassiez',
    'transformateurs',
    'transformations',
    'transformatrice',
    'transformerions',
    'transfusassions',
    'transfuseraient',
    'transgressaient',
    'transgressasses',
    'transgresserais',
    'transgresserait',
    'transgressèrent',
    'transgresseriez',
    'transgresserons',
    'transgresseront',
    'transhumassions',
    'transhumeraient',
    'transigeassions',
    'transistorisais',
    'transistorisait',
    'transistorisant',
    'transistorisées',
    'transistorisent',
    'transistorisera',
    'transistorisiez',
    'transistorisons',
    'transitionnelle',
    'transitoirement',
    'translatassions',
    'translateraient',
    'translitéraient',
    'translitérasses',
    'translitérerais',
    'translitérerait',
    'translitérèrent',
    'translitéreriez',
    'translitérerons',
    'translitéreront',
    'translittérâmes',
    'translittérasse',
    'translittérâtes',
    'translittérerai',
    'translittéreras',
    'translittérerez',
    'translittérions',
    'transmettraient',
    'transmigrassent',
    'transmigrassiez',
    'transmigrations',
    'transmigrerions',
    'transmutabilité',
    'transmutassions',
    'transmuteraient',
    'transnationales',
    'transocéanienne',
    'transocéaniques',
    'transparaissais',
    'transparaissait',
    'transparaissant',
    'transparaissent',
    'transparaissiez',
    'transparaissons',
    'transparaîtrais',
    'transparaîtrait',
    'transparaîtriez',
    'transparaîtrons',
    'transparaîtront',
    'transparussions',
    'transperçassent',
    'transperçassiez',
    'transpercements',
    'transpercerions',
    'transpirassions',
    'transpireraient',
    'transplantaient',
    'transplantasses',
    'transplantation',
    'transplanterais',
    'transplanterait',
    'transplantèrent',
    'transplanteriez',
    'transplanterons',
    'transplanteront',
    'transportassent',
    'transportassiez',
    'transportations',
    'transporterions',
    'transposassions',
    'transposeraient',
    'transpositrices',
    'transpyrénéenne',
    'transsaharienne',
    'transsexualisme',
    'transsibérienne',
    'transsubstantia',
    'transsubstantie',
    'transsubstantié',
    'transsudassions',
    'transsuderaient',
    'transvasassions',
    'transvaseraient',
    'transvidassions',
    'transvideraient',
    'traumatisassent',
    'traumatisassiez',
    'traumatiserions',
    'traumatologique',
    'traumatologiste',
    'travaillassions',
    'travailleraient',
    'travestissaient',
    'travestissement',
    'treillageassent',
    'treillageassiez',
    'treillageraient',
    'treillissassent',
    'treillissassiez',
    'treillisserions',
    'tremblotassions',
    'trembloteraient',
    'trémoussassions',
    'trémousseraient',
    'tressaillements',
    'tressaillirions',
    'tressaillissent',
    'tressaillissiez',
    'tressautassions',
    'tressauteraient',
    'triangulassions',
    'trianguleraient',
    'triboélectrique',
    'tridimensionnel',
    'trifouillassent',
    'trifouillassiez',
    'trifouillerions',
    'trigonocéphales',
    'trigonométrique',
    'trimballassions',
    'trimballeraient',
    'trinitrobenzène',
    'trinitrotoluène',
    'tripatouillages',
    'tripatouillâmes',
    'tripatouillasse',
    'tripatouillâtes',
    'tripatouillerai',
    'tripatouilleras',
    'tripatouillerez',
    'tripatouilleurs',
    'tripatouilleuse',
    'tripatouillions',
    'trompetteraient',
    'tronçonnassions',
    'tronçonneraient',
    'tropicalisaient',
    'tropicalisasses',
    'tropicalisation',
    'tropicaliserais',
    'tropicaliserait',
    'tropicalisèrent',
    'tropicaliseriez',
    'tropicaliserons',
    'tropicaliseront',
    'trousse-galants',
    'troussequinâmes',
    'troussequinasse',
    'troussequinâtes',
    'troussequinerai',
    'troussequineras',
    'troussequinerez',
    'troussequinions',
    'trusquinassions',
    'trusquineraient',
    'trypanosomiases',
    'tuberculinaient',
    'tuberculinasses',
    'tuberculination',
    'tuberculinerais',
    'tuberculinerait',
    'tuberculinèrent',
    'tuberculineriez',
    'tuberculinerons',
    'tuberculineront',
    'tuberculiniques',
    'tuberculinisais',
    'tuberculinisait',
    'tuberculinisant',
    'tuberculinisées',
    'tuberculinisent',
    'tuberculinisera',
    'tuberculinisiez',
    'tuberculinisons',
    'tuberculisaient',
    'tuberculisasses',
    'tuberculisation',
    'tuberculiserais',
    'tuberculiserait',
    'tuberculisèrent',
    'tuberculiseriez',
    'tuberculiserons',
    'tuberculiseront',
    'tumultueusement',
    'turbopropulseur',
    'turbosoufflante',
    'turlupinassions',
    'turlupineraient',
    'tympanisassions',
    'tympaniseraient',
    'tyndallisations',
    'tyrannisassions',
    'tyranniseraient',
    'ultrafiltration',
    'ultramicroscope',
    'ultramontanisme',
    'ultra-pressions',
    'ultra-royaliste',
    'ultra-sensibles',
    'ultra-violettes',
    'unidimensionnel',
    'unidirectionnel',
    'uniformisassent',
    'uniformisassiez',
    'uniformisations',
    'uniformiserions',
    'unilatéralement',
    'universalisâmes',
    'universalisasse',
    'universalisâtes',
    'universaliserai',
    'universaliseras',
    'universaliserez',
    'universalisions',
    'universellement',
    'vaccinothérapie',
    'vacuolisassions',
    'vacuoliseraient',
    'vadrouillassent',
    'vadrouillassiez',
    'vadrouillerions',
    'vadrouilleueurs',
    'vadrouilleueuse',
    'vagabondassions',
    'vagabonderaient',
    'valdinguassions',
    'valdingueraient',
    'vampirisassions',
    'vampiriseraient',
    'vandalisassions',
    'vandaliseraient',
    'vascularisation',
    'vasodilatateurs',
    'vasodilatations',
    'vasodilatatrice',
    'vasouillassions',
    'vasouilleraient',
    'vassalisassions',
    'vassaliseraient',
    'vaudevillesques',
    'véliplanchistes',
    'vendangeassions',
    'verbalisassions',
    'verbaliseraient',
    'verbiageassions',
    'verdunisassions',
    'verduniseraient',
    'vermiculassions',
    'vermiculeraient',
    'vermillonnaient',
    'vermillonnasses',
    'vermillonnerais',
    'vermillonnerait',
    'vermillonnèrent',
    'vermillonneriez',
    'vermillonnerons',
    'vermillonneront',
    'verrouillassent',
    'verrouillassiez',
    'verrouillerions',
    'versificatrices',
    'vert-de-grisées',
    'viabilisassions',
    'viabiliseraient',
    'vibrionnassions',
    'vibrionneraient',
    'vice-chancelier',
    'vice-présidence',
    'vice-présidente',
    'vice-présidents',
    'victorieusement',
    'vide-bouteilles',
    'vidéofréquences',
    'vieillissements',
    'vilipendassions',
    'vilipenderaient',
    'villégiaturâmes',
    'villégiaturasse',
    'villégiaturâtes',
    'villégiaturerai',
    'villégiatureras',
    'villégiaturerez',
    'villégiaturions',
    'villes-dortoirs',
    'vindicativement',
    'violoncellistes',
    'virevoltassions',
    'virevolteraient',
    'visualisassions',
    'visualiseraient',
    'vitrocéramiques',
    'volatilisassent',
    'volatilisassiez',
    'volatilisations',
    'volatiliserions',
    'volcanisassions',
    'volcaniseraient',
    'volcanologiques',
    'voltairianismes',
    'voluptueusement',
    'vulcanisassions',
    'vulcaniseraient',
    'vulgarisassions',
    'vulgarisatrices',
    'vulgariseraient',
    'wagon-réservoir',
    'wagons-citernes',
    'wagon-tombereau',
    'walkies-talkies',
    'weltanschauungs',
    'zigouillassions',
    'zigouilleraient',
    'zinzinulassions',
    'zinzinuleraient',
    'zootechnicienne'
];
