import CreateSpending from './Screens/Create';
import ViewSpending from './Screens/View';
import Spendings from './Screens/List';
import AdminSpending from './Screens/Admin';

const routes = [
    {
        path: '/spending/admin',
        element: <AdminSpending />
    },
    {
        path: '/spending/create',
        element: <CreateSpending />
    },
    {
        path: '/spending/view/:docId',
        element: <ViewSpending />
    },
    {
        path: '/spending',
        element: <Spendings />
    }
];

export default routes;
