export default [
    'abaissassent',
    'abaissassiez',
    'abaissements',
    'abaisserions',
    'abandonnâmes',
    'abandonnasse',
    'abandonnâtes',
    'abandonnerai',
    'abandonneras',
    'abandonnerez',
    'abandonnions',
    'abasourdîmes',
    'abasourdirai',
    'abasourdiras',
    'abasourdirez',
    'abasourdisse',
    'abasourdîtes',
    'abâtardirais',
    'abâtardirait',
    'abâtardirent',
    'abâtardiriez',
    'abâtardirons',
    'abâtardiront',
    'abâtardisses',
    'abâtardissez',
    'abattissions',
    'abbevilliens',
    'abdicataires',
    'abdiquassent',
    'abdiquassiez',
    'abdiquerions',
    'aberrassions',
    'aberreraient',
    'abêtissaient',
    'abêtissantes',
    'abêtissement',
    'abhorrassent',
    'abhorrassiez',
    'abhorrerions',
    'abjurassions',
    'abjureraient',
    'abloquassent',
    'abloquassiez',
    'abloquerions',
    'abolissaient',
    'abominassent',
    'abominassiez',
    'abominations',
    'abominerions',
    'abondanciste',
    'abondassions',
    'abonderaient',
    'abonnassions',
    'abonneraient',
    'abonniraient',
    'abonnissions',
    'abordassions',
    'aborderaient',
    'abouchassent',
    'abouchassiez',
    'abouchements',
    'aboucherions',
    'aboulassions',
    'abouleraient',
    'aboutassions',
    'abouteraient',
    'aboutiraient',
    'aboutissants',
    'aboutissions',
    'abrasassions',
    'abraseraient',
    'abrégeassent',
    'abrégeassiez',
    'abrégeraient',
    'abreuvassent',
    'abreuvassiez',
    'abreuverions',
    'abréviations',
    'abréviatives',
    'abricotaient',
    'abricotasses',
    'abricoterais',
    'abricoterait',
    'abricotèrent',
    'abricoteriez',
    'abricoterons',
    'abricoteront',
    'abritassions',
    'abriteraient',
    'abrogatoires',
    'abrogeassent',
    'abrogeassiez',
    'abrogeraient',
    'abrutiraient',
    'abrutissante',
    'abrutissants',
    'abrutissions',
    'absentassiez',
    'absentéismes',
    'absentéistes',
    'absenterions',
    'absinthismes',
    'absolutismes',
    'absolutistes',
    'absolutoires',
    'absorbassent',
    'absorbassiez',
    'absorberions',
    'absorptivité',
    'absoudraient',
    'abstiendriez',
    'abstiendrons',
    'abstinssions',
    'abstracteurs',
    'abstractions',
    'abstrairions',
    'abstrayaient',
    'abyssinienne',
    'académiciens',
    'acagnardâmes',
    'acagnardâtes',
    'acagnarderez',
    'acagnardions',
    'acariâtretés',
    'accablassent',
    'accablassiez',
    'accablements',
    'accablerions',
    'accaparaient',
    'accaparasses',
    'accaparement',
    'accaparerais',
    'accaparerait',
    'accaparèrent',
    'accapareriez',
    'accaparerons',
    'accapareront',
    'accapareuses',
    'accastillais',
    'accastillait',
    'accastillant',
    'accastillées',
    'accastillent',
    'accastillera',
    'accastilliez',
    'accastillons',
    'accédassions',
    'accéderaient',
    'accéléraient',
    'accélérasses',
    'accélérateur',
    'accélération',
    'accélérerais',
    'accélérerait',
    'accélérèrent',
    'accéléreriez',
    'accélérerons',
    'accéléreront',
    'accentuaient',
    'accentuasses',
    'accentuation',
    'accentuelles',
    'accentuerais',
    'accentuerait',
    'accentuèrent',
    'accentueriez',
    'accentuerons',
    'accentueront',
    'acceptassent',
    'acceptassiez',
    'acceptations',
    'accepterions',
    'accessoirisa',
    'accessoirise',
    'accessoirisé',
    'accidentâmes',
    'accidentasse',
    'accidentâtes',
    'accidentelle',
    'accidenterai',
    'accidenteras',
    'accidenterez',
    'accidentions',
    'acclamassent',
    'acclamassiez',
    'acclamations',
    'acclamerions',
    'acclimatable',
    'acclimatâmes',
    'acclimatasse',
    'acclimatâtes',
    'acclimaterai',
    'acclimateras',
    'acclimaterez',
    'acclimations',
    'accointances',
    'accointeriez',
    'accointerons',
    'accolassions',
    'accoleraient',
    'accommodâmes',
    'accommodante',
    'accommodants',
    'accommodasse',
    'accommodâtes',
    'accommoderai',
    'accommoderas',
    'accommoderez',
    'accommodions',
    'accompagnais',
    'accompagnait',
    'accompagnant',
    'accompagnées',
    'accompagnent',
    'accompagnera',
    'accompagniez',
    'accompagnons',
    'accomplirais',
    'accomplirait',
    'accomplirent',
    'accompliriez',
    'accomplirons',
    'accompliront',
    'accomplisses',
    'accomplissez',
    'accorassions',
    'accordailles',
    'accordassent',
    'accordassiez',
    'accordements',
    'accorderions',
    'accoreraient',
    'accostassent',
    'accostassiez',
    'accosterions',
    'accotassions',
    'accoteraient',
    'accouassions',
    'accouchaient',
    'accouchasses',
    'accouchement',
    'accoucherais',
    'accoucherait',
    'accouchèrent',
    'accoucheriez',
    'accoucherons',
    'accoucheront',
    'accoucheuses',
    'accoudassiez',
    'accoudements',
    'accouderions',
    'accoueraient',
    'accouplaient',
    'accouplasses',
    'accouplement',
    'accouplerais',
    'accouplerait',
    'accouplèrent',
    'accoupleriez',
    'accouplerons',
    'accoupleront',
    'accourcirais',
    'accourcirait',
    'accourcirent',
    'accourciriez',
    'accourcirons',
    'accourciront',
    'accourcisses',
    'accourcissez',
    'accourraient',
    'accourussent',
    'accourussiez',
    'accoutraient',
    'accoutrasses',
    'accoutrement',
    'accoutrerais',
    'accoutrerait',
    'accoutrèrent',
    'accoutreriez',
    'accoutrerons',
    'accoutreront',
    'accoutumâmes',
    'accoutumance',
    'accoutumasse',
    'accoutumâtes',
    'accoutumerai',
    'accoutumeras',
    'accoutumerez',
    'accoutumions',
    'accréditâmes',
    'accréditasse',
    'accréditâtes',
    'accréditerai',
    'accréditeras',
    'accréditerez',
    'accréditeurs',
    'accréditions',
    'accréditives',
    'accréditrice',
    'accrescentes',
    'accrochaient',
    'accrochasses',
    'accrocherais',
    'accrocherait',
    'accrochèrent',
    'accrocheriez',
    'accrocherons',
    'accrocheront',
    'accrocheuses',
    'accroissions',
    'accroîtrions',
    'accroupiriez',
    'accroupirons',
    'accroupissez',
    'accueillante',
    'accueillants',
    'accueillerai',
    'accueilleras',
    'accueillerez',
    'accueillîmes',
    'accueillions',
    'accueillisse',
    'accueillîtes',
    'acculassions',
    'acculeraient',
    'acculturâmes',
    'acculturasse',
    'acculturâtes',
    'acculturerai',
    'accultureras',
    'acculturerez',
    'acculturions',
    'accumulaient',
    'accumulasses',
    'accumulateur',
    'accumulation',
    'accumulerais',
    'accumulerait',
    'accumulèrent',
    'accumuleriez',
    'accumulerons',
    'accumuleront',
    'accusassions',
    'accusatoires',
    'accusatrices',
    'accuseraient',
    'acétifiaient',
    'acétifiasses',
    'acétifierais',
    'acétifierait',
    'acétifièrent',
    'acétifieriez',
    'acétifierons',
    'acétifieront',
    'acétobacters',
    'acétylassent',
    'acétylassiez',
    'acétylénique',
    'acétylerions',
    'achalandages',
    'achalandâmes',
    'achalandasse',
    'achalandâtes',
    'achalanderai',
    'achalanderas',
    'achalanderez',
    'achalandions',
    'acharnassent',
    'acharnassiez',
    'acharnements',
    'acharnerions',
    'acheminaient',
    'acheminasses',
    'acheminement',
    'acheminerais',
    'acheminerait',
    'acheminèrent',
    'achemineriez',
    'acheminerons',
    'achemineront',
    'achetassions',
    'achèteraient',
    'acheuléennes',
    'achevassions',
    'achèveraient',
    'achoppassiez',
    'achoppements',
    'achopperions',
    'achromatique',
    'achromatisme',
    'acidifiables',
    'acidifiaient',
    'acidifiantes',
    'acidifiasses',
    'acidifierais',
    'acidifierait',
    'acidifièrent',
    'acidifieriez',
    'acidifierons',
    'acidifieront',
    'acidimétries',
    'acidulassent',
    'acidulassiez',
    'acidulerions',
    'aciérassions',
    'aciéreraient',
    'aciselassent',
    'aciselassiez',
    'acisèlerions',
    'acoquinement',
    'acoquineriez',
    'acoquinerons',
    'acousticiens',
    'acquerraient',
    'acquiesçâmes',
    'acquiesçasse',
    'acquiesçâtes',
    'acquiescerai',
    'acquiesceras',
    'acquiescerez',
    'acquiescions',
    'acquisitions',
    'acquisitives',
    'acquittables',
    'acquittaient',
    'acquittasses',
    'acquittement',
    'acquitterais',
    'acquitterait',
    'acquittèrent',
    'acquitteriez',
    'acquitterons',
    'acquitteront',
    'acrimonieuse',
    'acrobatiques',
    'acrocéphales',
    'acrocéphalie',
    'acrocyanoses',
    'acromégalies',
    'actinologies',
    'actinomètres',
    'actinométrie',
    'actinomycète',
    'actinomycose',
    'actionnables',
    'actionnaient',
    'actionnaires',
    'actionnariat',
    'actionnasses',
    'actionnement',
    'actionnerais',
    'actionnerait',
    'actionnèrent',
    'actionneriez',
    'actionnerons',
    'actionneront',
    'activassions',
    'activeraient',
    'actualisâmes',
    'actualisasse',
    'actualisâtes',
    'actualiserai',
    'actualiseras',
    'actualiserez',
    'actualisions',
    'actuarielles',
    'actuellement',
    'acuponcteurs',
    'acuponctrice',
    'acuponctures',
    'acupuncteurs',
    'acupunctrice',
    'acupunctures',
    'adaptabilité',
    'adaptassions',
    'adaptatrices',
    'adapteraient',
    'additionnais',
    'additionnait',
    'additionnant',
    'additionnées',
    'additionnels',
    'additionnent',
    'additionnera',
    'additionniez',
    'additionnons',
    'adénopathies',
    'adéquatement',
    'adhérassions',
    'adhéreraient',
    'adiaphorèses',
    'adjectivales',
    'adjectivâmes',
    'adjectivasse',
    'adjectivâtes',
    'adjectiverai',
    'adjectiveras',
    'adjectiverez',
    'adjectivions',
    'adjectivisai',
    'adjectivisas',
    'adjectivisât',
    'adjectivisée',
    'adjectiviser',
    'adjectivises',
    'adjectivisés',
    'adjectivisez',
    'adjoignaient',
    'adjoignirent',
    'adjoignisses',
    'adjoindrions',
    'adjudicateur',
    'adjudicatifs',
    'adjudication',
    'adjudicative',
    'adjugeassent',
    'adjugeassiez',
    'adjugeraient',
    'adjurassions',
    'adjureraient',
    'admettraient',
    'administrais',
    'administrait',
    'administrant',
    'administrées',
    'administrent',
    'administrera',
    'administriez',
    'administrons',
    'admirassions',
    'admiratrices',
    'admireraient',
    'admonestâmes',
    'admonestasse',
    'admonestâtes',
    'admonesterai',
    'admonesteras',
    'admonesterez',
    'admonestions',
    'adolescences',
    'adolescentes',
    'adonisassiez',
    'adoniserions',
    'adonnassions',
    'adoptassions',
    'adopteraient',
    'adorablement',
    'adossassions',
    'adosseraient',
    'adoubassions',
    'adouberaient',
    'adouciraient',
    'adoucissages',
    'adoucissante',
    'adoucissants',
    'adoucisseurs',
    'adoucissions',
    'adrénergique',
    'adressassent',
    'adressassiez',
    'adresserions',
    'adultéraient',
    'adultérasses',
    'adultération',
    'adultérerais',
    'adultérerait',
    'adultérèrent',
    'adultéreriez',
    'adultérerons',
    'adultéreront',
    'adversatives',
    'aéroglisseur',
    'aéronautique',
    'aérospatiale',
    'aérospatiaux',
    'affabulaient',
    'affabulasses',
    'affabulation',
    'affabulerais',
    'affabulerait',
    'affabulèrent',
    'affabuleriez',
    'affabulerons',
    'affabuleront',
    'affacturages',
    'affadiraient',
    'affadissante',
    'affadissants',
    'affadissions',
    'affaiblirais',
    'affaiblirait',
    'affaiblirent',
    'affaibliriez',
    'affaiblirons',
    'affaibliront',
    'affaiblisses',
    'affaiblissez',
    'affairassiez',
    'affairements',
    'affairerions',
    'affaissaient',
    'affaissasses',
    'affaissement',
    'affaisserais',
    'affaisserait',
    'affaissèrent',
    'affaisseriez',
    'affaisserons',
    'affaisseront',
    'affaitements',
    'affalassions',
    'affaleraient',
    'affamassions',
    'affameraient',
    'afféageaient',
    'afféageasses',
    'afféagerions',
    'affectassent',
    'affectassiez',
    'affectations',
    'affecterions',
    'affectionnai',
    'affectionnas',
    'affectionnât',
    'affectionnée',
    'affectionner',
    'affectionnes',
    'affectionnés',
    'affectionnez',
    'affectivités',
    'affectueuses',
    'afférassions',
    'afféreraient',
    'affermassent',
    'affermassiez',
    'affermerions',
    'affermirions',
    'affermissais',
    'affermissait',
    'affermissant',
    'affermissent',
    'affermissiez',
    'affermissons',
    'affichassent',
    'affichassiez',
    'afficherions',
    'affilassions',
    'affileraient',
    'affiliassent',
    'affiliassiez',
    'affiliations',
    'affilierions',
    'affinassions',
    'affineraient',
    'affirmassent',
    'affirmassiez',
    'affirmations',
    'affirmatives',
    'affirmerions',
    'affleuraient',
    'affleurasses',
    'affleurement',
    'affleurerais',
    'affleurerait',
    'affleurèrent',
    'affleureriez',
    'affleurerons',
    'affleureront',
    'affligeaient',
    'affligeantes',
    'affligeasses',
    'affligerions',
    'afflouassent',
    'afflouassiez',
    'afflouerions',
    'affluassions',
    'afflueraient',
    'affolassions',
    'affoleraient',
    'affouageâmes',
    'affouageasse',
    'affouageâtes',
    'affouagerais',
    'affouagerait',
    'affouagèrent',
    'affouageriez',
    'affouagerons',
    'affouageront',
    'affouillâmes',
    'affouillasse',
    'affouillâtes',
    'affouillerai',
    'affouilleras',
    'affouillerez',
    'affouillions',
    'affourageais',
    'affourageait',
    'affourageant',
    'affourageons',
    'affouragerai',
    'affourageras',
    'affouragerez',
    'affouragions',
    'affourchâmes',
    'affourchasse',
    'affourchâtes',
    'affourcherai',
    'affourcheras',
    'affourcherez',
    'affourchions',
    'affourrageai',
    'affourrageas',
    'affourrageât',
    'affourragées',
    'affourragent',
    'affourragera',
    'affourragiez',
    'affranchîmes',
    'affranchirai',
    'affranchiras',
    'affranchirez',
    'affranchisse',
    'affranchîtes',
    'affrétassent',
    'affrétassiez',
    'affrètements',
    'affréterions',
    'affreusement',
    'affriandâmes',
    'affriandasse',
    'affriandâtes',
    'affrianderai',
    'affrianderas',
    'affrianderez',
    'affriandions',
    'affrichaient',
    'affrichasses',
    'affricherais',
    'affricherait',
    'affrichèrent',
    'affricheriez',
    'affricherons',
    'affricheront',
    'affriolaient',
    'affriolantes',
    'affriolasses',
    'affriolerais',
    'affriolerait',
    'affriolèrent',
    'affrioleriez',
    'affriolerons',
    'affrioleront',
    'affritassent',
    'affritassiez',
    'affriterions',
    'affrontaient',
    'affrontasses',
    'affrontement',
    'affronterais',
    'affronterait',
    'affrontèrent',
    'affronteriez',
    'affronterons',
    'affronteront',
    'affruitaient',
    'affruitasses',
    'affruiterais',
    'affruiterait',
    'affruitèrent',
    'affruiteriez',
    'affruiterons',
    'affruiteront',
    'affublassent',
    'affublassiez',
    'affublements',
    'affublerions',
    'affurassions',
    'affureraient',
    'affûtassions',
    'affûteraient',
    'africanisais',
    'africanisait',
    'africanisant',
    'africanisées',
    'africanisent',
    'africanisera',
    'africanisiez',
    'africanisons',
    'africanistes',
    'afro-cubaine',
    'afro-cubains',
    'agençassions',
    'agenceraient',
    'agenouillées',
    'agenouilliez',
    'agenouilloir',
    'agenouillons',
    'agglomérâmes',
    'agglomérants',
    'agglomérasse',
    'agglomérâtes',
    'agglomérerai',
    'aggloméreras',
    'agglomérerez',
    'agglomérions',
    'agglutinâmes',
    'agglutinante',
    'agglutinants',
    'agglutinasse',
    'agglutinâtes',
    'agglutinerai',
    'agglutineras',
    'agglutinerez',
    'agglutinions',
    'aggravassent',
    'aggravassiez',
    'aggravations',
    'aggraverions',
    'agiotassions',
    'agioteraient',
    'agnelassions',
    'agnellerions',
    'agnosticisme',
    'agonisassent',
    'agonisassiez',
    'agoniserions',
    'agonissaient',
    'agoraphobies',
    'agrafassions',
    'agraferaient',
    'agrammatical',
    'agrammatisme',
    'agrandirions',
    'agrandissais',
    'agrandissait',
    'agrandissant',
    'agrandissent',
    'agrandisseur',
    'agrandissiez',
    'agrandissons',
    'agréablement',
    'agrégeassent',
    'agrégeassiez',
    'agrégeraient',
    'agrémentâmes',
    'agrémentasse',
    'agrémentâtes',
    'agrémenterai',
    'agrémenteras',
    'agrémenterez',
    'agrémentions',
    'agressassent',
    'agressassiez',
    'agresserions',
    'agressivités',
    'agrichassent',
    'agrichassiez',
    'agricherions',
    'agriculteurs',
    'agricultrice',
    'agricultures',
    'agriffassiez',
    'agrifferions',
    'agrippassent',
    'agrippassiez',
    'agripperions',
    'agronomiques',
    'aguardientes',
    'aguerririons',
    'aguerrissais',
    'aguerrissait',
    'aguerrissant',
    'aguerrissent',
    'aguerrissiez',
    'aguerrissons',
    'aguichassent',
    'aguichassiez',
    'aguicherions',
    'aheurtassiez',
    'aheurterions',
    'ahurissaient',
    'ahurissantes',
    'ahurissement',
    'aide-mémoire',
    'aigrissaient',
    'aigue-marine',
    'aiguillaient',
    'aiguillasses',
    'aiguillerais',
    'aiguillerait',
    'aiguillèrent',
    'aiguilleriez',
    'aiguillerons',
    'aiguilleront',
    'aiguilletais',
    'aiguilletait',
    'aiguilletant',
    'aiguilletées',
    'aiguilletiez',
    'aiguilletons',
    'aiguillettes',
    'aiguilleuses',
    'aiguillonnai',
    'aiguillonnas',
    'aiguillonnât',
    'aiguillonnée',
    'aiguillonner',
    'aiguillonnes',
    'aiguillonnés',
    'aiguillonnez',
    'aiguisassent',
    'aiguisassiez',
    'aiguiserions',
    'aimantassent',
    'aimantassiez',
    'aimantations',
    'aimanterions',
    'ajointassent',
    'ajointassiez',
    'ajointerions',
    'ajourassions',
    'ajoureraient',
    'ajournassent',
    'ajournassiez',
    'ajournements',
    'ajournerions',
    'ajoutassions',
    'ajouteraient',
    'ajustassions',
    'ajusteraient',
    'alabastrites',
    'alambiquâmes',
    'alambiquasse',
    'alambiquâtes',
    'alambiquerai',
    'alambiqueras',
    'alambiquerez',
    'alambiquions',
    'alanguirions',
    'alanguissais',
    'alanguissait',
    'alanguissant',
    'alanguissent',
    'alanguissiez',
    'alanguissons',
    'alarmassions',
    'alarmeraient',
    'albumineuses',
    'albuminoïdes',
    'albuminuries',
    'alcalescence',
    'alcalescente',
    'alcalescents',
    'alcalifiante',
    'alcalifiants',
    'alcalimètres',
    'alcalimétrie',
    'alcalinisais',
    'alcalinisait',
    'alcalinisant',
    'alcalinisées',
    'alcalinisent',
    'alcalinisera',
    'alcalinisiez',
    'alcalinisons',
    'alcalisaient',
    'alcalisasses',
    'alcaliserais',
    'alcaliserait',
    'alcalisèrent',
    'alcaliseriez',
    'alcaliserons',
    'alcaliseront',
    'alcoolatures',
    'alcoolisable',
    'alcoolisâmes',
    'alcoolisasse',
    'alcoolisâtes',
    'alcooliserai',
    'alcooliseras',
    'alcooliserez',
    'alcoolisions',
    'aldéhydiques',
    'aldostérones',
    'alentiraient',
    'alentissions',
    'alertassions',
    'alerteraient',
    'alevinassent',
    'alevinassiez',
    'alevinerions',
    'alexandrines',
    'aliénabilité',
    'aliénassions',
    'aliénataires',
    'aliénatrices',
    'aliéneraient',
    'alignassions',
    'aligneraient',
    'alimentaient',
    'alimentaires',
    'alimentasses',
    'alimentation',
    'alimenterais',
    'alimenterait',
    'alimentèrent',
    'alimenteriez',
    'alimenterons',
    'alimenteront',
    'aliphatiques',
    'allaitassent',
    'allaitassiez',
    'allaitements',
    'allaiterions',
    'alléchassent',
    'alléchassiez',
    'allèchements',
    'allécherions',
    'allégeassent',
    'allégeassiez',
    'allégeraient',
    'allégiraient',
    'allégissions',
    'allégoriques',
    'allégorisais',
    'allégorisait',
    'allégorisant',
    'allégorisées',
    'allégorisent',
    'allégorisera',
    'allégorisiez',
    'allégorisons',
    'alléguassent',
    'alléguassiez',
    'alléguerions',
    'allélomorphe',
    'allergisante',
    'allergisants',
    'allergologie',
    'allergologue',
    'aller-retour',
    'allitération',
    'allocataires',
    'allocutaires',
    'allongeaient',
    'allongeasses',
    'allongements',
    'allongerions',
    'allopathique',
    'allotropique',
    'allouassions',
    'alloueraient',
    'allumassions',
    'allumeraient',
    'allumettière',
    'allumettiers',
    'alluvionnais',
    'alluvionnait',
    'alluvionnant',
    'alluvionnent',
    'alluvionnera',
    'alluvionniez',
    'alluvionnons',
    'alourdirions',
    'alourdissais',
    'alourdissait',
    'alourdissant',
    'alourdissent',
    'alourdissiez',
    'alourdissons',
    'alpaguassent',
    'alpaguassiez',
    'alpaguerions',
    'alphabétique',
    'alphabétisai',
    'alphabétisas',
    'alphabétisât',
    'alphabétisée',
    'alphabétiser',
    'alphabétises',
    'alphabétisés',
    'alphabétisez',
    'alphabétisme',
    'altérabilité',
    'altérassions',
    'altercations',
    'altéreraient',
    'alternassent',
    'alternassiez',
    'alternateurs',
    'alternatives',
    'alternerions',
    'aluminassent',
    'aluminassiez',
    'aluminerions',
    'alunissaient',
    'amadouassent',
    'amadouassiez',
    'amadouerions',
    'amaigririons',
    'amaigrissais',
    'amaigrissait',
    'amaigrissant',
    'amaigrissent',
    'amaigrissiez',
    'amaigrissons',
    'amalgamaient',
    'amalgamasses',
    'amalgamation',
    'amalgamerais',
    'amalgamerait',
    'amalgamèrent',
    'amalgameriez',
    'amalgamerons',
    'amalgameront',
    'amarinassent',
    'amarinassiez',
    'amarinerions',
    'amarrassions',
    'amarreraient',
    'amassassions',
    'amasseraient',
    'amateurismes',
    'amatissaient',
    'ambassadeurs',
    'ambassadrice',
    'ambitionnais',
    'ambitionnait',
    'ambitionnant',
    'ambitionnées',
    'ambitionnent',
    'ambitionnera',
    'ambitionniez',
    'ambitionnons',
    'ambivalences',
    'ambivalentes',
    'amblyoscopes',
    'ambrosiennes',
    'ambulancière',
    'ambulanciers',
    'ambulatoires',
    'améliorables',
    'amélioraient',
    'améliorantes',
    'améliorasses',
    'amélioration',
    'améliorerais',
    'améliorerait',
    'améliorèrent',
    'amélioreriez',
    'améliorerons',
    'amélioreront',
    'aménageables',
    'aménageaient',
    'aménageasses',
    'aménagements',
    'aménagerions',
    'amendassions',
    'amenderaient',
    'amenuisaient',
    'amenuisasses',
    'amenuisement',
    'amenuiserais',
    'amenuiserait',
    'amenuisèrent',
    'amenuiseriez',
    'amenuiserons',
    'amenuiseront',
    'américanisai',
    'américanisas',
    'américanisât',
    'américanisée',
    'américaniser',
    'américanises',
    'américanisés',
    'américanisez',
    'américanisme',
    'américaniste',
    'amérindienne',
    'amerriraient',
    'amerrissages',
    'amerrissions',
    'ameublements',
    'ameublirions',
    'ameublissais',
    'ameublissait',
    'ameublissant',
    'ameublissent',
    'ameublissiez',
    'ameublissons',
    'ameutassions',
    'ameuteraient',
    'amidonnaient',
    'amidonnasses',
    'amidonnerais',
    'amidonnerait',
    'amidonnèrent',
    'amidonneries',
    'amidonneriez',
    'amidonnerons',
    'amidonneront',
    'amidonnières',
    'amidopyrines',
    'aminciraient',
    'amincissante',
    'amincissants',
    'amincissions',
    'aminoplastes',
    'ammoniacales',
    'ammonisation',
    'amniocentèse',
    'amnioscopies',
    'amnistiables',
    'amnistiaient',
    'amnistiantes',
    'amnistiasses',
    'amnistierais',
    'amnistierait',
    'amnistièrent',
    'amnistieriez',
    'amnistierons',
    'amnistieront',
    'amochassions',
    'amocheraient',
    'amodiassions',
    'amodieraient',
    'amoindrirais',
    'amoindrirait',
    'amoindrirent',
    'amoindririez',
    'amoindrirons',
    'amoindriront',
    'amoindrisses',
    'amoindrissez',
    'amolliraient',
    'amollissante',
    'amollissants',
    'amollissions',
    'amoncelaient',
    'amoncelasses',
    'amoncelèrent',
    'amoncellerai',
    'amoncelleras',
    'amoncellerez',
    'amonibilités',
    'amorçassions',
    'amorceraient',
    'amordançâmes',
    'amordançasse',
    'amordançâtes',
    'amordancerai',
    'amordanceras',
    'amordancerez',
    'amordancions',
    'amortiraient',
    'amortissable',
    'amortisseurs',
    'amortissions',
    'amotissaient',
    'amourachâmes',
    'amourachâtes',
    'amouracherez',
    'amourachions',
    'amour-propre',
    'ampèremètres',
    'amphétamines',
    'amphibologie',
    'amphictyonie',
    'amphithéâtre',
    'amplifiaient',
    'amplifiantes',
    'amplifiasses',
    'amplifierais',
    'amplifierait',
    'amplifièrent',
    'amplifieriez',
    'amplifierons',
    'amplifieront',
    'amputassions',
    'amputeraient',
    'amuïssements',
    'amuse-gueule',
    'amylobacters',
    'amyotrophies',
    'anabaptismes',
    'anabaptistes',
    'anabolisante',
    'anabolisants',
    'anachronique',
    'anachronisme',
    'anaglyptique',
    'analeptiques',
    'analgésiâmes',
    'analgésiasse',
    'analgésiâtes',
    'analgésierai',
    'analgésieras',
    'analgésierez',
    'analgésiions',
    'analgésiques',
    'anallergique',
    'analphabètes',
    'analysassent',
    'analysassiez',
    'analyserions',
    'anamorphoses',
    'anaphoriques',
    'anaphrodisie',
    'anaphylaxies',
    'anarchisante',
    'anarchisants',
    'anastomosées',
    'anastomosiez',
    'anastomosons',
    'anathématisa',
    'anathématise',
    'anathématisé',
    'anatomisâmes',
    'anatomisasse',
    'anatomisâtes',
    'anatomiserai',
    'anatomiseras',
    'anatomiserez',
    'anatomisions',
    'anciennement',
    'andouillette',
    'androcéphale',
    'androgenèses',
    'androstérone',
    'anéantirions',
    'anéantissais',
    'anéantissait',
    'anéantissant',
    'anéantissent',
    'anéantissiez',
    'anéantissons',
    'anecdotières',
    'anecdotiques',
    'anémiassions',
    'anémieraient',
    'anémographes',
    'anesthésiais',
    'anesthésiait',
    'anesthésiant',
    'anesthésiées',
    'anesthésient',
    'anesthésiera',
    'anesthésiiez',
    'anesthésions',
    'anesthésique',
    'anesthésiste',
    'anévrismales',
    'angiectasies',
    'angiocholite',
    'angiographie',
    'angiospermes',
    'anglaisaient',
    'anglaisasses',
    'anglaiserais',
    'anglaiserait',
    'anglaisèrent',
    'anglaiseriez',
    'anglaiserons',
    'anglaiseront',
    'anglicanisme',
    'anglicisâmes',
    'anglicisasse',
    'anglicisâtes',
    'angliciserai',
    'angliciseras',
    'angliciserez',
    'anglicisions',
    'anglo-arabes',
    'anglophilies',
    'anglophobies',
    'anglo-saxons',
    'angoissaient',
    'angoissantes',
    'angoissasses',
    'angoisserais',
    'angoisserait',
    'angoissèrent',
    'angoisseriez',
    'angoisserons',
    'angoisseront',
    'angusticlave',
    'anharmonique',
    'anhélassions',
    'anhéleraient',
    'animalisâmes',
    'animalisasse',
    'animalisâtes',
    'animaliserai',
    'animaliseras',
    'animaliserez',
    'animalisions',
    'anisotropies',
    'ankylosaient',
    'ankylosasses',
    'ankyloserais',
    'ankyloserait',
    'ankylosèrent',
    'ankyloseriez',
    'ankyloserons',
    'ankyloseront',
    'ankylostomes',
    'annelassions',
    'annellerions',
    'annexassions',
    'annexeraient',
    'annihilaient',
    'annihilasses',
    'annihilation',
    'annihilerais',
    'annihilerait',
    'annihilèrent',
    'annihileriez',
    'annihilerons',
    'annihileront',
    'anniversaire',
    'annonçassent',
    'annonçassiez',
    'annoncerions',
    'annonciateur',
    'annonciation',
    'annotassions',
    'annotatrices',
    'annoteraient',
    'annuellement',
    'annulassions',
    'annuleraient',
    'anobliraient',
    'anoblissions',
    'anodisassent',
    'anodisassiez',
    'anodiserions',
    'ânonnassions',
    'ânonneraient',
    'anordiraient',
    'anordissions',
    'anormalement',
    'antagoniques',
    'antagonismes',
    'antagonistes',
    'antarctiques',
    'antécédences',
    'antécédentes',
    'antédiluvien',
    'antéposaient',
    'antéposasses',
    'antéposerais',
    'antéposerait',
    'antéposèrent',
    'antéposeriez',
    'antéposerons',
    'antéposeront',
    'antériorités',
    'antérogrades',
    'anthérozoïde',
    'anthracnoses',
    'anthropoïdes',
    'antiaérienne',
    'antiamariles',
    'antiatomique',
    'antibiotique',
    'antibrouillé',
    'anticasseurs',
    'anticathodes',
    'antichambres',
    'anticipaient',
    'anticipasses',
    'anticipation',
    'anticiperais',
    'anticiperait',
    'anticipèrent',
    'anticiperiez',
    'anticiperons',
    'anticiperont',
    'anticlérical',
    'anticlinales',
    'anticyclique',
    'anticyclonal',
    'anticyclones',
    'antidataient',
    'antidatasses',
    'antidaterais',
    'antidaterait',
    'antidatèrent',
    'antidateriez',
    'antidaterons',
    'antidateront',
    'antidérapant',
    'antidétonant',
    'antifasciste',
    'antilithique',
    'antimatières',
    'antimissiles',
    'antimoniures',
    'antinational',
    'antinomiques',
    'antipaludéen',
    'antiparasite',
    'antipathique',
    'antiphonaire',
    'antiquailles',
    'antirabiques',
    'antiracismes',
    'antiracistes',
    'antirouilles',
    'antiseptique',
    'antisociales',
    'antisportifs',
    'antisportive',
    'antistrophes',
    'antithétique',
    'antitoxiques',
    'antivenimeux',
    'anxieusement',
    'apaisassions',
    'apaiseraient',
    'apanageaient',
    'apanageasses',
    'apanagerions',
    'aperceptions',
    'apercevaient',
    'apercevrions',
    'aperçussions',
    'apeurassions',
    'apeureraient',
    'apicultrices',
    'apiquassions',
    'apiqueraient',
    'apitoiements',
    'apitoierions',
    'apitoyassent',
    'apitoyassiez',
    'aplanétiques',
    'aplaniraient',
    'aplanissions',
    'aplatiraient',
    'aplatissages',
    'aplatisseurs',
    'aplatissions',
    'aplatissoire',
    'aplatissoirs',
    'apodictiques',
    'apollinienne',
    'apologétique',
    'apomorphines',
    'apophysaires',
    'apoplectique',
    'apostasiâmes',
    'apostasiasse',
    'apostasiâtes',
    'apostasierai',
    'apostasieras',
    'apostasierez',
    'apostasiions',
    'apostassions',
    'aposteraient',
    'apostillâmes',
    'apostillasse',
    'apostillâtes',
    'apostillerai',
    'apostilleras',
    'apostillerez',
    'apostillions',
    'apostoliques',
    'apostrophais',
    'apostrophait',
    'apostrophant',
    'apostrophées',
    'apostrophent',
    'apostrophera',
    'apostrophiez',
    'apostrophons',
    'apothicaires',
    'appairassent',
    'appairassiez',
    'appairerions',
    'appalachiens',
    'apparaissais',
    'apparaissait',
    'apparaissant',
    'apparaissent',
    'apparaissiez',
    'apparaissons',
    'apparaîtrais',
    'apparaîtrait',
    'apparaîtriez',
    'apparaîtrons',
    'apparaîtront',
    'appareillade',
    'appareillage',
    'appareillais',
    'appareillait',
    'appareillant',
    'appareillées',
    'appareillent',
    'appareillera',
    'appareilleur',
    'appareilliez',
    'appareillons',
    'apparentâmes',
    'apparentâtes',
    'apparenterez',
    'apparentions',
    'appariassent',
    'appariassiez',
    'appariements',
    'apparierions',
    'appartements',
    'appartenance',
    'appartenions',
    'appartiendra',
    'appartiennes',
    'appartinrent',
    'appartinsses',
    'apparussions',
    'appassionato',
    'appâtassions',
    'appâteraient',
    'appauvrirais',
    'appauvrirait',
    'appauvrirent',
    'appauvririez',
    'appauvrirons',
    'appauvriront',
    'appauvrisses',
    'appauvrissez',
    'appelassions',
    'appellations',
    'appellatives',
    'appellerions',
    'appendicites',
    'appesantîmes',
    'appesantirai',
    'appesantiras',
    'appesantirez',
    'appesantisse',
    'appesantîtes',
    'appétassions',
    'appéteraient',
    'appétissante',
    'appétissants',
    'applaudirais',
    'applaudirait',
    'applaudirent',
    'applaudiriez',
    'applaudirons',
    'applaudiront',
    'applaudisses',
    'applaudissez',
    'applicateurs',
    'applications',
    'applicatrice',
    'appliquaient',
    'appliquasses',
    'appliquerais',
    'appliquerait',
    'appliquèrent',
    'appliqueriez',
    'appliquerons',
    'appliqueront',
    'appoggiature',
    'appointaient',
    'appointasses',
    'appointerais',
    'appointerait',
    'appointèrent',
    'appointeriez',
    'appointerons',
    'appointeront',
    'appointirais',
    'appointirait',
    'appointirent',
    'appointiriez',
    'appointirons',
    'appointiront',
    'appointisses',
    'appointissez',
    'appontassent',
    'appontassiez',
    'appontements',
    'apponterions',
    'apportassent',
    'apportassiez',
    'apporterions',
    'apposassions',
    'apposeraient',
    'appréciables',
    'appréciaient',
    'appréciasses',
    'appréciateur',
    'appréciatifs',
    'appréciation',
    'appréciative',
    'apprécierais',
    'apprécierait',
    'apprécièrent',
    'apprécieriez',
    'apprécierons',
    'apprécieront',
    'appréhendais',
    'appréhendait',
    'appréhendant',
    'appréhendées',
    'appréhendent',
    'appréhendera',
    'appréhendiez',
    'appréhendons',
    'appréhensifs',
    'appréhension',
    'appréhensive',
    'apprendrions',
    'apprêtassent',
    'apprêtassiez',
    'apprêterions',
    'apprivoisais',
    'apprivoisait',
    'apprivoisant',
    'apprivoisées',
    'apprivoisent',
    'apprivoisera',
    'apprivoiseur',
    'apprivoisiez',
    'apprivoisons',
    'approbateurs',
    'approbations',
    'approbatives',
    'approbatrice',
    'approchables',
    'approchaient',
    'approchantes',
    'approchasses',
    'approcherais',
    'approcherait',
    'approchèrent',
    'approcheriez',
    'approcherons',
    'approcheront',
    'approfondies',
    'approfondira',
    'appropriâmes',
    'appropriasse',
    'appropriâtes',
    'approprierai',
    'approprieras',
    'approprierez',
    'appropriions',
    'approuvables',
    'approuvaient',
    'approuvasses',
    'approuverais',
    'approuverait',
    'approuvèrent',
    'approuveriez',
    'approuverons',
    'approuveront',
    'approximatif',
    'appuieraient',
    'appuyassions',
    'apragmatisme',
    'après-demain',
    'après-dîners',
    'après-guerre',
    'après-rasage',
    'aquacultures',
    'aquafortiste',
    'aquarelliste',
    'aquiculteurs',
    'aquicultures',
    'arabisassent',
    'arabisassiez',
    'arabisations',
    'arabiserions',
    'arachnéennes',
    'arbalétrière',
    'arbalétriers',
    'arbitragiste',
    'arbitrassent',
    'arbitrassiez',
    'arbitrerions',
    'arborassions',
    'arboreraient',
    'arborescence',
    'arborescente',
    'arborescents',
    'arborisaient',
    'arborisasses',
    'arborisation',
    'arboriserais',
    'arboriserait',
    'arborisèrent',
    'arboriseriez',
    'arboriserons',
    'arboriseront',
    'arc-boutâmes',
    'arc-boutasse',
    'arc-boutâtes',
    'arc-bouterai',
    'arc-bouteras',
    'arc-bouterez',
    'arc-boutions',
    'arc-doubleau',
    'archaïsaient',
    'archaïsantes',
    'archaïsasses',
    'archaïserais',
    'archaïserait',
    'archaïsèrent',
    'archaïseriez',
    'archaïserons',
    'archaïseront',
    'archéologies',
    'archéologues',
    'archéoptéryx',
    'archidiacres',
    'archiphonème',
    'archiprêtres',
    'architectura',
    'architecture',
    'architecturé',
    'archivassent',
    'archivassiez',
    'archiverions',
    'arçonnassent',
    'arçonnassiez',
    'arçonnerions',
    'arcs-en-ciel',
    'ardoisassent',
    'ardoisassiez',
    'ardoiserions',
    'areligieuses',
    'argentassent',
    'argentassiez',
    'argenterions',
    'argentifères',
    'argotisaient',
    'argotisasses',
    'argotiserais',
    'argotiserait',
    'argotisèrent',
    'argotiseriez',
    'argotiserons',
    'argotiseront',
    'argumentaire',
    'argumentâmes',
    'argumentants',
    'argumentasse',
    'argumentâtes',
    'argumenterai',
    'argumenteras',
    'argumenterez',
    'argumentions',
    'aristocrates',
    'aristocratie',
    'aristoloches',
    'arithmétique',
    'arlequinades',
    'armoriassent',
    'armoriassiez',
    'armorierions',
    'arnaquassent',
    'arnaquassiez',
    'arnaquerions',
    'aromatisâmes',
    'aromatisants',
    'aromatisasse',
    'aromatisâtes',
    'aromatiserai',
    'aromatiseras',
    'aromatiserez',
    'aromatisions',
    'arpégeassent',
    'arpégeassiez',
    'arpégeraient',
    'arpentassent',
    'arpentassiez',
    'arpenterions',
    'arquebusades',
    'arquebusiers',
    'arrachassent',
    'arrachassiez',
    'arrachements',
    'arrache-pied',
    'arracherions',
    'arraisonnais',
    'arraisonnait',
    'arraisonnant',
    'arraisonnées',
    'arraisonnent',
    'arraisonnera',
    'arraisonniez',
    'arraisonnons',
    'arrangeables',
    'arrangeaient',
    'arrangeantes',
    'arrangeasses',
    'arrangements',
    'arrangerions',
    'arrentassent',
    'arrentassiez',
    'arrenterions',
    'arrérageâmes',
    'arrérageasse',
    'arrérageâtes',
    'arréragerais',
    'arréragerait',
    'arréragèrent',
    'arrérageriez',
    'arréragerons',
    'arrérageront',
    'arrestations',
    'arrêtassions',
    'arrêteraient',
    'arriérassent',
    'arriérassiez',
    'arriérations',
    'arrière-bans',
    'arrière-becs',
    'arrière-cour',
    'arrière-faix',
    'arrière-fond',
    'arrière-goût',
    'arrière-main',
    'arrière-pays',
    'arrière-plan',
    'arriérerions',
    'arrimassions',
    'arrimeraient',
    'arrisassions',
    'arriseraient',
    'arrivassions',
    'arriveraient',
    'arrogeassiez',
    'arrondirions',
    'arrondissage',
    'arrondissais',
    'arrondissait',
    'arrondissant',
    'arrondissent',
    'arrondissiez',
    'arrondissons',
    'arrosassions',
    'arroseraient',
    'arsouillâmes',
    'arsouillâtes',
    'arsouillerez',
    'arsouillions',
    'artériotomie',
    'artéritiques',
    'arthritiques',
    'arthritismes',
    'arthropathie',
    'articulaient',
    'articulaires',
    'articulasses',
    'articulation',
    'articulerais',
    'articulerait',
    'articulèrent',
    'articuleriez',
    'articulerons',
    'articuleront',
    'artificielle',
    'artificieuse',
    'ascaridiases',
    'ascaridioses',
    'ascensionnai',
    'ascensionnas',
    'ascensionnât',
    'ascensionnée',
    'ascensionnel',
    'ascensionner',
    'ascensionnes',
    'ascensionnés',
    'ascensionnez',
    'aseptisaient',
    'aseptisasses',
    'aseptisation',
    'aseptiserais',
    'aseptiserait',
    'aseptisèrent',
    'aseptiseriez',
    'aseptiserons',
    'aseptiseront',
    'asociabilité',
    'aspectassent',
    'aspectassiez',
    'aspecterions',
    'aspergeaient',
    'aspergeasses',
    'aspergerions',
    'aspergillose',
    'aspermatisme',
    'asphaltaient',
    'asphaltasses',
    'asphalterais',
    'asphalterait',
    'asphaltèrent',
    'asphalteriez',
    'asphalterons',
    'asphalteront',
    'asphyxiaient',
    'asphyxiantes',
    'asphyxiasses',
    'asphyxierais',
    'asphyxierait',
    'asphyxièrent',
    'asphyxieriez',
    'asphyxierons',
    'asphyxieront',
    'aspirassions',
    'aspiratoires',
    'aspireraient',
    'assa-foetida',
    'assagiraient',
    'assagissions',
    'assaillaient',
    'assaillantes',
    'assaillirais',
    'assaillirait',
    'assaillirent',
    'assailliriez',
    'assaillirons',
    'assailliront',
    'assaillisses',
    'assainirions',
    'assainissais',
    'assainissait',
    'assainissant',
    'assainissent',
    'assainisseur',
    'assainissiez',
    'assainissons',
    'assaisonnais',
    'assaisonnait',
    'assaisonnant',
    'assaisonnées',
    'assaisonnent',
    'assaisonnera',
    'assaisonniez',
    'assaisonnons',
    'assarmentais',
    'assarmentait',
    'assarmentant',
    'assarmentées',
    'assarmentent',
    'assarmentera',
    'assarmentiez',
    'assarmentons',
    'assassinâmes',
    'assassinasse',
    'assassinâtes',
    'assassinerai',
    'assassineras',
    'assassinerez',
    'assassinions',
    'asséchassent',
    'asséchassiez',
    'assèchements',
    'assécherions',
    'assemblaient',
    'assemblasses',
    'assemblerais',
    'assemblerait',
    'assemblèrent',
    'assembleriez',
    'assemblerons',
    'assembleront',
    'assembleuses',
    'assenassions',
    'assénassions',
    'asséneraient',
    'assèneraient',
    'assentiments',
    'assermentais',
    'assermentait',
    'assermentant',
    'assermentées',
    'assermentent',
    'assermentera',
    'assermentiez',
    'assermentons',
    'asservirions',
    'asservissais',
    'asservissait',
    'asservissant',
    'asservissent',
    'asservisseur',
    'asservissiez',
    'asservissons',
    'assibilaient',
    'assibilasses',
    'assibilation',
    'assibilerais',
    'assibilerait',
    'assibilèrent',
    'assibileriez',
    'assibilerons',
    'assibileront',
    'assiégeaient',
    'assiégeantes',
    'assiégeasses',
    'assiégerions',
    'assignassent',
    'assignassiez',
    'assignations',
    'assignerions',
    'assimilables',
    'assimilaient',
    'assimilasses',
    'assimilateur',
    'assimilation',
    'assimilerais',
    'assimilerait',
    'assimilèrent',
    'assimileriez',
    'assimilerons',
    'assimileront',
    'assistassent',
    'assistassiez',
    'assisterions',
    'associassent',
    'associassiez',
    'associations',
    'associatives',
    'associerions',
    'assolassions',
    'assoleraient',
    'assombrirais',
    'assombrirait',
    'assombrirent',
    'assombririez',
    'assombrirons',
    'assombriront',
    'assombrisses',
    'assombrissez',
    'assommassent',
    'assommassiez',
    'assommerions',
    'assonassions',
    'assoneraient',
    'assortiments',
    'assortirions',
    'assortissais',
    'assortissait',
    'assortissant',
    'assortissent',
    'assortissiez',
    'assortissons',
    'assoupirions',
    'assoupissais',
    'assoupissait',
    'assoupissant',
    'assoupissent',
    'assoupissiez',
    'assoupissons',
    'assouplirais',
    'assouplirait',
    'assouplirent',
    'assoupliriez',
    'assouplirons',
    'assoupliront',
    'assouplisses',
    'assouplissez',
    'assourdirais',
    'assourdirait',
    'assourdirent',
    'assourdiriez',
    'assourdirons',
    'assourdiront',
    'assourdisses',
    'assourdissez',
    'assouvirions',
    'assouvissais',
    'assouvissait',
    'assouvissant',
    'assouvissent',
    'assouvissiez',
    'assouvissons',
    'assujettîmes',
    'assujettirai',
    'assujettiras',
    'assujettirez',
    'assujettisse',
    'assujettîtes',
    'assumassions',
    'assumeraient',
    'assurassions',
    'assureraient',
    'assyriologie',
    'assyriologue',
    'asthmatiques',
    'asticotaient',
    'asticotasses',
    'asticoterais',
    'asticoterait',
    'asticotèrent',
    'asticoteriez',
    'asticoterons',
    'asticoteront',
    'astigmatisme',
    'astiquassent',
    'astiquassiez',
    'astiquerions',
    'astreignante',
    'astreignants',
    'astreignîmes',
    'astreignions',
    'astreignisse',
    'astreignîtes',
    'astreindrais',
    'astreindrait',
    'astreindriez',
    'astreindrons',
    'astreindront',
    'astringences',
    'astringentes',
    'astrolâtries',
    'astrologique',
    'astrométries',
    'astronomique',
    'asymétriques',
    'asymptotique',
    'atemporelles',
    'atermoiement',
    'atermoierais',
    'atermoierait',
    'atermoieriez',
    'atermoierons',
    'atermoieront',
    'atermoyaient',
    'atermoyasses',
    'atermoyèrent',
    'athématiques',
    'atome-gramme',
    'atomisassent',
    'atomisassiez',
    'atomisations',
    'atomiserions',
    'atomistiques',
    'atrabilaires',
    'atrophiaient',
    'atrophiasses',
    'atrophierais',
    'atrophierait',
    'atrophièrent',
    'atrophieriez',
    'atrophierons',
    'atrophieront',
    'attablassent',
    'attablassiez',
    'attablerions',
    'attachassent',
    'attachassiez',
    'attaché-case',
    'attachements',
    'attacherions',
    'attaquassent',
    'attaquassiez',
    'attaquerions',
    'attardassent',
    'attardassiez',
    'attarderions',
    'atteignables',
    'atteignaient',
    'atteignirent',
    'atteignisses',
    'atteindrions',
    'attelassions',
    'attellerions',
    'attendissent',
    'attendissiez',
    'attendraient',
    'attendrirais',
    'attendrirait',
    'attendrirent',
    'attendririez',
    'attendrirons',
    'attendriront',
    'attendrisses',
    'attendrissez',
    'attentassent',
    'attentassiez',
    'attentatoire',
    'attenterions',
    'attentionnée',
    'attentionnés',
    'atténuassent',
    'atténuassiez',
    'atténuateurs',
    'atténuations',
    'atténuerions',
    'atterrassent',
    'atterrassiez',
    'atterrerions',
    'atterririons',
    'atterrissage',
    'atterrissais',
    'atterrissait',
    'atterrissant',
    'atterrissent',
    'atterrissiez',
    'atterrissons',
    'attestassent',
    'attestassiez',
    'attestations',
    'attesterions',
    'attiédirions',
    'attiédissais',
    'attiédissait',
    'attiédissant',
    'attiédissent',
    'attiédissiez',
    'attiédissons',
    'attifassions',
    'attiferaient',
    'attigeassent',
    'attigeassiez',
    'attigeraient',
    'attirassions',
    'attireraient',
    'attisassions',
    'attiseraient',
    'attitrassent',
    'attitrassiez',
    'attitrerions',
    'attouchement',
    'attrapassent',
    'attrapassiez',
    'attraperions',
    'attribuables',
    'attribuaient',
    'attribuasses',
    'attribuerais',
    'attribuerait',
    'attribuèrent',
    'attribueriez',
    'attribuerons',
    'attribueront',
    'attributaire',
    'attributions',
    'attributives',
    'attriquaient',
    'attriquasses',
    'attriquerais',
    'attriquerait',
    'attriquèrent',
    'attriqueriez',
    'attriquerons',
    'attriqueront',
    'attristaient',
    'attristantes',
    'attristasses',
    'attristerais',
    'attristerait',
    'attristèrent',
    'attristeriez',
    'attristerons',
    'attristeront',
    'attroupaient',
    'attroupasses',
    'attroupement',
    'attrouperais',
    'attrouperait',
    'attroupèrent',
    'attrouperiez',
    'attrouperons',
    'attrouperont',
    'audi-mutités',
    'audiogrammes',
    'audiovisuels',
    'auditionnais',
    'auditionnait',
    'auditionnant',
    'auditionnées',
    'auditionnent',
    'auditionnera',
    'auditionniez',
    'auditionnons',
    'augmentables',
    'augmentaient',
    'augmentasses',
    'augmentatifs',
    'augmentation',
    'augmentative',
    'augmenterais',
    'augmenterait',
    'augmentèrent',
    'augmenteriez',
    'augmenterons',
    'augmenteront',
    'augurassions',
    'augureraient',
    'augustiniens',
    'auraient-ils',
    'aurantiacées',
    'auréolassent',
    'auréolassiez',
    'auréolerions',
    'auréomycines',
    'auriculaires',
    'aurifiassent',
    'aurifiassiez',
    'aurification',
    'aurifierions',
    'aurignaciens',
    'aurions-nous',
    'auront-elles',
    'auscultaient',
    'auscultasses',
    'auscultation',
    'ausculterais',
    'ausculterait',
    'auscultèrent',
    'ausculteriez',
    'ausculterons',
    'ausculteront',
    'australienne',
    'authenticité',
    'authentifiai',
    'authentifias',
    'authentifiât',
    'authentifiée',
    'authentifier',
    'authentifies',
    'authentifiés',
    'authentifiez',
    'authentiquai',
    'authentiquas',
    'authentiquât',
    'authentiquée',
    'authentiquer',
    'authentiques',
    'authentiqués',
    'authentiquez',
    'auto-adhésif',
    'autocassable',
    'autocensures',
    'autochenille',
    'autocollante',
    'autocollants',
    'autocratique',
    'autocritique',
    'autocuiseurs',
    'autodéfenses',
    'autodidactes',
    'autofinancée',
    'autofinancer',
    'autofinancés',
    'autofinancez',
    'autogestions',
    'autographiai',
    'autographias',
    'autographiât',
    'autographiée',
    'autographier',
    'autographies',
    'autographiés',
    'autographiez',
    'autoguidages',
    'autoguidâmes',
    'autoguidâtes',
    'autoguiderez',
    'autoguidions',
    'automatiques',
    'automatisais',
    'automatisait',
    'automatisant',
    'automatisées',
    'automatisent',
    'automatisera',
    'automatisiez',
    'automatismes',
    'automatisons',
    'automotrices',
    'autonomistes',
    'autoplasties',
    'autoportante',
    'autoportants',
    'autoporteurs',
    'autoporteuse',
    'autoportrait',
    'autopropulsé',
    'autopsiaient',
    'autopsiasses',
    'autopsierais',
    'autopsierait',
    'autopsièrent',
    'autopsieriez',
    'autopsierons',
    'autopsieront',
    'autopunition',
    'autoréglages',
    'autorisaient',
    'autorisasses',
    'autorisation',
    'autoriserais',
    'autoriserait',
    'autorisèrent',
    'autoriseriez',
    'autoriserons',
    'autoriseront',
    'autoritaires',
    'autoroutière',
    'autoroutiers',
    'autrichienne',
    'avachiraient',
    'avachissions',
    'avalisassent',
    'avalisassiez',
    'avaliserions',
    'avançassions',
    'avanceraient',
    'avantageâmes',
    'avantageasse',
    'avantageâtes',
    'avantagerais',
    'avantagerait',
    'avantagèrent',
    'avantageriez',
    'avantagerons',
    'avantageront',
    'avantageuses',
    'avant-centre',
    'avant-gardes',
    'avant-guerre',
    'avant-postes',
    'avant-projet',
    'avant-propos',
    'avant-scènes',
    'avant-trains',
    'avant-veille',
    'avariassions',
    'avaricieuses',
    'avarieraient',
    'aventuraient',
    'aventurasses',
    'aventurerais',
    'aventurerait',
    'aventurèrent',
    'aventureriez',
    'aventurerons',
    'aventureront',
    'aventureuses',
    'aventurières',
    'aventurismes',
    'avertiraient',
    'avertisseurs',
    'avertisseuse',
    'avertissions',
    'aveuglassent',
    'aveuglassiez',
    'aveuglements',
    'aveuglerions',
    'aveuliraient',
    'aveulissions',
    'avicultrices',
    'avilissaient',
    'avilissantes',
    'avilissement',
    'avitaillâmes',
    'avitaillasse',
    'avitaillâtes',
    'avitaillerai',
    'avitailleras',
    'avitaillerez',
    'avitaillions',
    'avitaminoses',
    'avocasseries',
    'avocassières',
    'avoirdupoids',
    'avoisinaient',
    'avoisinantes',
    'avoisinasses',
    'avoisinerais',
    'avoisinerait',
    'avoisinèrent',
    'avoisineriez',
    'avoisinerons',
    'avoisineront',
    'avortassions',
    'avorteraient',
    'avunculaires',
    'axiologiques',
    'axiomatisais',
    'axiomatisait',
    'axiomatisant',
    'axiomatisées',
    'axiomatisent',
    'axiomatisera',
    'axiomatisiez',
    'axiomatisons',
    'axonométries',
    'azoospermies',
    'babillassent',
    'babillassiez',
    'babillerions',
    'baby-sitters',
    'baccalauréat',
    'bachotassent',
    'bachotassiez',
    'bachoterions',
    'bacilliforme',
    'bactéricides',
    'bactériennes',
    'badigeonnage',
    'badigeonnais',
    'badigeonnait',
    'badigeonnant',
    'badigeonnées',
    'badigeonnent',
    'badigeonnera',
    'badigeonneur',
    'badigeonniez',
    'badigeonnons',
    'badinassions',
    'badineraient',
    'bafouassions',
    'bafoueraient',
    'bafouillages',
    'bafouillâmes',
    'bafouillasse',
    'bafouillâtes',
    'bafouillerai',
    'bafouilleras',
    'bafouillerez',
    'bafouilleurs',
    'bafouilleuse',
    'bafouillions',
    'bagarrassent',
    'bagarrassiez',
    'bagarrerions',
    'baguenaudais',
    'baguenaudait',
    'baguenaudant',
    'baguenaudées',
    'baguenaudent',
    'baguenaudera',
    'baguenaudier',
    'baguenaudiez',
    'baguenaudons',
    'baignassions',
    'baigneraient',
    'baillassions',
    'bâillassions',
    'bailleraient',
    'bâilleraient',
    'bailleresses',
    'bâillonnâmes',
    'bâillonnasse',
    'bâillonnâtes',
    'bâillonnerai',
    'bâillonneras',
    'bâillonnerez',
    'bâillonnions',
    'baisotassent',
    'baisotassiez',
    'baisoterions',
    'baissassions',
    'baisseraient',
    'baladassions',
    'baladeraient',
    'balafrassent',
    'balafrassiez',
    'balafrerions',
    'balai-brosse',
    'balaieraient',
    'balançassent',
    'balançassiez',
    'balancements',
    'balancerions',
    'balayassions',
    'balayeraient',
    'balbutiaient',
    'balbutiantes',
    'balbutiasses',
    'balbutiement',
    'balbutierais',
    'balbutierait',
    'balbutièrent',
    'balbutieriez',
    'balbutierons',
    'balbutieront',
    'baleinassent',
    'baleinassiez',
    'baleinerions',
    'balisassions',
    'baliseraient',
    'balkanisâmes',
    'balkanisasse',
    'balkanisâtes',
    'balkaniserai',
    'balkaniseras',
    'balkaniserez',
    'balkanisions',
    'ballastaient',
    'ballastasses',
    'ballasterais',
    'ballasterait',
    'ballastèrent',
    'ballasteriez',
    'ballasterons',
    'ballasteront',
    'ballastières',
    'balletomanes',
    'ballonnaient',
    'ballonnasses',
    'ballonnement',
    'ballonnerais',
    'ballonnerait',
    'ballonnèrent',
    'ballonneriez',
    'ballonnerons',
    'ballonneront',
    'ballon-sonde',
    'ballottaient',
    'ballottasses',
    'ballottement',
    'ballotterais',
    'ballotterait',
    'ballottèrent',
    'ballotteriez',
    'ballotterons',
    'ballotteront',
    'bambochaient',
    'bambochasses',
    'bambocherais',
    'bambocherait',
    'bambochèrent',
    'bambocheriez',
    'bambocherons',
    'bambocheront',
    'bambocheuses',
    'banalisaient',
    'banalisasses',
    'banalisation',
    'banaliserais',
    'banaliserait',
    'banalisèrent',
    'banaliseriez',
    'banaliserons',
    'banaliseront',
    'banderillero',
    'bandoulières',
    'banlieusarde',
    'banlieusards',
    'bannissaient',
    'bannissement',
    'banquassions',
    'banqueraient',
    'banqueroutes',
    'banquetaient',
    'banquetasses',
    'banquetèrent',
    'banquetterai',
    'banquetteras',
    'banquetterez',
    'baptisassent',
    'baptisassiez',
    'baptiserions',
    'baptistaires',
    'baquetassent',
    'baquetassiez',
    'baquetterais',
    'baquetterait',
    'baquetteriez',
    'baquetterons',
    'baquetteront',
    'baragouinage',
    'baragouinais',
    'baragouinait',
    'baragouinant',
    'baragouinées',
    'baragouinent',
    'baragouinera',
    'baragouineur',
    'baragouiniez',
    'baragouinons',
    'baraquassent',
    'baraquassiez',
    'baraquements',
    'baraquerions',
    'baratinaient',
    'baratinasses',
    'baratinerais',
    'baratinerait',
    'baratinèrent',
    'baratineriez',
    'baratinerons',
    'baratineront',
    'baratineuses',
    'barattassent',
    'barattassiez',
    'baratterions',
    'barbaresques',
    'barbichettes',
    'barbifiaient',
    'barbifiasses',
    'barbifierais',
    'barbifierait',
    'barbifièrent',
    'barbifieriez',
    'barbifierons',
    'barbifieront',
    'barbiturique',
    'barbiturisme',
    'barbotassent',
    'barbotassiez',
    'barboterions',
    'barbouillage',
    'barbouillais',
    'barbouillait',
    'barbouillant',
    'barbouillées',
    'barbouillent',
    'barbouillera',
    'barbouilleur',
    'barbouilliez',
    'barbouillons',
    'barétassions',
    'barèteraient',
    'barguignâmes',
    'barguignasse',
    'barguignâtes',
    'barguignerai',
    'barguigneras',
    'barguignerez',
    'barguignions',
    'bariolassent',
    'bariolassiez',
    'bariolerions',
    'barométrique',
    'baronnassent',
    'baronnassiez',
    'baronnerions',
    'baroudassent',
    'baroudassiez',
    'barouderions',
    'barricadâmes',
    'barricadasse',
    'barricadâtes',
    'barricaderai',
    'barricaderas',
    'barricaderez',
    'barricadions',
    'barrissaient',
    'barrissement',
    'basanassions',
    'basaneraient',
    'basculassent',
    'basculassiez',
    'basculements',
    'basculerions',
    'bas-jointées',
    'basket-balls',
    'basketteuses',
    'basses-cours',
    'bassinassent',
    'bassinassiez',
    'bassinerions',
    'bastillonnai',
    'bastillonnas',
    'bastillonnât',
    'bastillonnée',
    'bastillonner',
    'bastillonnes',
    'bastillonnés',
    'bastillonnez',
    'bastionnâmes',
    'bastionnasse',
    'bastionnâtes',
    'bastionnerai',
    'bastionneras',
    'bastionnerez',
    'bastionnions',
    'bataillaient',
    'bataillasses',
    'bataillerais',
    'bataillerait',
    'bataillèrent',
    'batailleriez',
    'bataillerons',
    'batailleront',
    'batailleuses',
    'bateau-pompe',
    'bateaux-feux',
    'batelassions',
    'batellerions',
    'bathymétries',
    'bathyscaphes',
    'bathysphères',
    'batifolaient',
    'batifolantes',
    'batifolasses',
    'batifolerais',
    'batifolerait',
    'batifolèrent',
    'batifoleriez',
    'batifolerons',
    'batifoleront',
    'bâtonnassent',
    'bâtonnassiez',
    'bâtonnerions',
    'battellement',
    'battle-dress',
    'baugeassions',
    'bavardassent',
    'bavardassiez',
    'bavarderions',
    'bavassassent',
    'bavassassiez',
    'bavasserions',
    'bavochassent',
    'bavochassiez',
    'bavocherions',
    'bazardassent',
    'bazardassiez',
    'bazarderions',
    'béatifiaient',
    'béatifiantes',
    'béatifiasses',
    'béatifierais',
    'béatifierait',
    'béatifièrent',
    'béatifieriez',
    'béatifierons',
    'béatifieront',
    'beauceronnes',
    'beaux-frères',
    'bécotassions',
    'bécoteraient',
    'becquassions',
    'becqueraient',
    'becquetaient',
    'becquetances',
    'becquetasses',
    'becquetèrent',
    'becquetterai',
    'becquetteras',
    'becquetterez',
    'becs-croisés',
    'becs-de-cane',
    'bedonnassent',
    'bedonnassiez',
    'bedonnerions',
    'bégaieraient',
    'bégayassions',
    'bégayeraient',
    'béguetassent',
    'béguetassiez',
    'béguètements',
    'béguèterions',
    'bégueuleries',
    'behaviorisme',
    'béhaviorisme',
    'béhavioriste',
    'bélinographe',
    'belles-mères',
    'belligérance',
    'belligérante',
    'belligérants',
    'belliqueuses',
    'bémolisaient',
    'bémolisasses',
    'bémoliserais',
    'bémoliserait',
    'bémolisèrent',
    'bémoliseriez',
    'bémoliserons',
    'bémoliseront',
    'bénédictines',
    'bénédictions',
    'bénéficiaire',
    'bénéficiâmes',
    'bénéficiasse',
    'bénéficiâtes',
    'bénéficierai',
    'bénéficieras',
    'bénéficierez',
    'bénéficiions',
    'bénévolement',
    'béni-oui-oui',
    'béquetassent',
    'béquetassiez',
    'béquetterais',
    'béquetterait',
    'béquetteriez',
    'béquetterons',
    'béquetteront',
    'béquillaient',
    'béquillasses',
    'béquillerais',
    'béquillerait',
    'béquillèrent',
    'béquilleriez',
    'béquillerons',
    'béquilleront',
    'bergamasques',
    'bergamotiers',
    'berrichonnes',
    'besognassent',
    'besognassiez',
    'besognerions',
    'bestialement',
    'best-sellers',
    'bêtathérapie',
    'bêtifiassent',
    'bêtifiassiez',
    'bêtifierions',
    'bêtisassions',
    'bêtiseraient',
    'bétonnassent',
    'bétonnassiez',
    'bétonnerions',
    'betteravière',
    'betteraviers',
    'beuglassions',
    'beugleraient',
    'beurrassions',
    'beurreraient',
    'biaisassions',
    'biaiseraient',
    'bibelotaient',
    'bibelotasses',
    'bibeloterais',
    'bibeloterait',
    'bibelotèrent',
    'bibeloteriez',
    'bibeloterons',
    'bibeloteront',
    'biberonnâmes',
    'biberonnasse',
    'biberonnâtes',
    'biberonnerai',
    'biberonneras',
    'biberonnerez',
    'biberonnions',
    'bibliographe',
    'bibliomanies',
    'bibliophiles',
    'bibliophilie',
    'bibliothèque',
    'bicamérismes',
    'bicarbonates',
    'bicentenaire',
    'bichonnaient',
    'bichonnasses',
    'bichonnerais',
    'bichonnerait',
    'bichonnèrent',
    'bichonneriez',
    'bichonnerons',
    'bichonneront',
    'bichotassent',
    'bichotassiez',
    'bichoterions',
    'biculturelle',
    'bidonnassent',
    'bidonnassiez',
    'bidonnerions',
    'bidouillages',
    'bidouillâmes',
    'bidouillasse',
    'bidouillâtes',
    'bidouillerai',
    'bidouilleras',
    'bidouillerez',
    'bidouillions',
    'bienfaisance',
    'bienfaisante',
    'bienfaisants',
    'bienfaiteurs',
    'bienfaitrice',
    'bienheureuse',
    'bien-pensant',
    'bienveillant',
    'bifurcations',
    'bifurquaient',
    'bifurquasses',
    'bifurquerais',
    'bifurquerait',
    'bifurquèrent',
    'bifurqueriez',
    'bifurquerons',
    'bifurqueront',
    'bigarrassent',
    'bigarrassiez',
    'bigarrerions',
    'bigornassent',
    'bigornassiez',
    'bigornerions',
    'bilharzioses',
    'bilinguismes',
    'billebaudais',
    'billebaudait',
    'billebaudant',
    'billebaudent',
    'billebaudera',
    'billebaudiez',
    'billebaudons',
    'billetteries',
    'billonnaient',
    'billonnasses',
    'billonnerais',
    'billonnerait',
    'billonnèrent',
    'billonneriez',
    'billonnerons',
    'billonneront',
    'biloculaires',
    'biloquassent',
    'biloquassiez',
    'biloquerions',
    'bimbeloterie',
    'bimensuelles',
    'bimestrielle',
    'bimétallique',
    'bimétallisme',
    'bimétalliste',
    'bimillénaire',
    'binationales',
    'binoculaires',
    'biochimistes',
    'biographique',
    'biomécanique',
    'biomédicales',
    'biophysiques',
    'biosynthèses',
    'biothérapies',
    'biotypologie',
    'bipartitions',
    'biquotidiens',
    'biréfringent',
    'bisannuelles',
    'biscotteries',
    'biscuitaient',
    'biscuitasses',
    'biscuiterais',
    'biscuiterait',
    'biscuitèrent',
    'biscuiteries',
    'biscuiteriez',
    'biscuiterons',
    'biscuiteront',
    'biseautaient',
    'biseautasses',
    'biseauterais',
    'biseauterait',
    'biseautèrent',
    'biseauteriez',
    'biseauterons',
    'biseauteront',
    'bisegmentais',
    'bisegmentait',
    'bisegmentant',
    'bisegmentées',
    'bisegmentent',
    'bisegmentera',
    'bisegmentiez',
    'bisegmentons',
    'bisexualités',
    'bisquassions',
    'bisqueraient',
    'bissectrices',
    'bissexualité',
    'bissexuelles',
    'bistournages',
    'bistournâmes',
    'bistournasse',
    'bistournâtes',
    'bistournerai',
    'bistourneras',
    'bistournerez',
    'bistournions',
    'bistrassions',
    'bistreraient',
    'bitturassent',
    'bitturassiez',
    'bitturerions',
    'bitumassions',
    'bitumeraient',
    'bituminaient',
    'bituminasses',
    'bituminerais',
    'bituminerait',
    'bituminèrent',
    'bitumineriez',
    'bituminerons',
    'bitumineront',
    'bitumineuses',
    'biturassions',
    'bitureraient',
    'bivouaquâmes',
    'bivouaquasse',
    'bivouaquâtes',
    'bivouaquerai',
    'bivouaqueras',
    'bivouaquerez',
    'bivouaquions',
    'bizutassions',
    'bizuteraient',
    'blablataient',
    'blablatasses',
    'blablaterais',
    'blablaterait',
    'blablatèrent',
    'blablateriez',
    'blablaterons',
    'blablateront',
    'blackboulage',
    'blackboulais',
    'blackboulait',
    'blackboulant',
    'blackboulées',
    'blackboulent',
    'blackboulera',
    'blackbouliez',
    'blackboulons',
    'blaguassions',
    'blagueraient',
    'blairassions',
    'blaireraient',
    'blanchailles',
    'blanchiments',
    'blanchirions',
    'blanchissage',
    'blanchissais',
    'blanchissait',
    'blanchissant',
    'blanchissent',
    'blanchisseur',
    'blanchissiez',
    'blanchissons',
    'blanc-manger',
    'blancs-étocs',
    'blasonnaient',
    'blasonnasses',
    'blasonnerais',
    'blasonnerait',
    'blasonnèrent',
    'blasonneriez',
    'blasonnerons',
    'blasonneront',
    'blasphémâmes',
    'blasphémasse',
    'blasphémâtes',
    'blasphémerai',
    'blasphémeras',
    'blasphémerez',
    'blasphémions',
    'blastodermes',
    'blastogenèse',
    'blastomycose',
    'blatérassent',
    'blatérassiez',
    'blatérerions',
    'blêmissement',
    'blennorragie',
    'blennorrhées',
    'blessassions',
    'blesseraient',
    'blettiraient',
    'blettissions',
    'blettissures',
    'bleuissaient',
    'bleuissement',
    'bleutassions',
    'bleuteraient',
    'blindassions',
    'blinderaient',
    'bloc-cuisine',
    'blocs-éviers',
    'blondinettes',
    'blondissions',
    'blondoierais',
    'blondoierait',
    'blondoieriez',
    'blondoierons',
    'blondoieront',
    'blondoyaient',
    'blondoyasses',
    'blondoyèrent',
    'bloquassions',
    'bloqueraient',
    'blottiraient',
    'blottissions',
    'blousassions',
    'blouseraient',
    'bluffassions',
    'blufferaient',
    'bobinassions',
    'bobineraient',
    'bocardassent',
    'bocardassiez',
    'bocarderions',
    'boettassions',
    'boetteraient',
    'boissellerie',
    'boitillaient',
    'boitillantes',
    'boitillasses',
    'boitillement',
    'boitillerais',
    'boitillerait',
    'boitillèrent',
    'boitilleriez',
    'boitillerons',
    'boitilleront',
    'bolchévisais',
    'bolchévisait',
    'bolchévisant',
    'bolchévisées',
    'bolchévisent',
    'bolchévisera',
    'bolchévisiez',
    'bolchevismes',
    'bolchévisons',
    'bolchevistes',
    'bombardaient',
    'bombardasses',
    'bombardement',
    'bombarderais',
    'bombarderait',
    'bombardèrent',
    'bombarderiez',
    'bombarderons',
    'bombarderont',
    'bonapartisme',
    'bonapartiste',
    'bonbonnières',
    'bon-chrétien',
    'bondérisâmes',
    'bondérisasse',
    'bondérisâtes',
    'bondériserai',
    'bondériseras',
    'bondériserez',
    'bondérisions',
    'bondieuserie',
    'bondissaient',
    'bondissantes',
    'bondissement',
    'bondonnaient',
    'bondonnasses',
    'bondonnerais',
    'bondonnerait',
    'bondonnèrent',
    'bondonneriez',
    'bondonnerons',
    'bondonneront',
    'bonifiassent',
    'bonifiassiez',
    'bonification',
    'bonifierions',
    'bonimentâmes',
    'bonimentasse',
    'bonimentâtes',
    'bonimenterai',
    'bonimenteras',
    'bonimenterez',
    'bonimenteurs',
    'bonimenteuse',
    'bonimentions',
    'bornoierions',
    'bornoyassent',
    'bornoyassiez',
    'bosselassent',
    'bosselassiez',
    'bossellement',
    'bossellerais',
    'bossellerait',
    'bosselleriez',
    'bossellerons',
    'bosselleront',
    'bossuassions',
    'bossueraient',
    'botanisaient',
    'botanisasses',
    'botaniserais',
    'botaniserait',
    'botanisèrent',
    'botaniseriez',
    'botaniserons',
    'botaniseront',
    'bottelassent',
    'bottelassiez',
    'bottellerais',
    'bottellerait',
    'bottelleriez',
    'bottellerons',
    'bottelleront',
    'boucanassent',
    'boucanassiez',
    'boucanerions',
    'bouchardâmes',
    'bouchardasse',
    'bouchardâtes',
    'boucharderai',
    'boucharderas',
    'boucharderez',
    'bouchardions',
    'bouchassions',
    'boucheraient',
    'bouche-trous',
    'bouchonnâmes',
    'bouchonnasse',
    'bouchonnâtes',
    'bouchonnerai',
    'bouchonneras',
    'bouchonnerez',
    'bouchonniers',
    'bouchonnions',
    'bouclassions',
    'boucleraient',
    'boudinassent',
    'boudinassiez',
    'boudinerions',
    'bouffassions',
    'boufferaient',
    'bouffetances',
    'bouffiraient',
    'bouffissions',
    'bouffissures',
    'bouffonnâmes',
    'bouffonnasse',
    'bouffonnâtes',
    'bouffonnerai',
    'bouffonneras',
    'bouffonnerez',
    'bouffonnerie',
    'bouffonnions',
    'bougeassions',
    'bougonnaient',
    'bougonnasses',
    'bougonnement',
    'bougonnerais',
    'bougonnerait',
    'bougonnèrent',
    'bougonneriez',
    'bougonnerons',
    'bougonneront',
    'bouillirions',
    'bouillissent',
    'bouillissiez',
    'bouillonnais',
    'bouillonnait',
    'bouillonnant',
    'bouillonnent',
    'bouillonnera',
    'bouillonniez',
    'bouillonnons',
    'bouillottais',
    'bouillottait',
    'bouillottant',
    'bouillottent',
    'bouillottera',
    'bouillottiez',
    'bouillottons',
    'boulangeâmes',
    'boulangeasse',
    'boulangeâtes',
    'boulangerais',
    'boulangerait',
    'boulangèrent',
    'boulangeries',
    'boulangeriez',
    'boulangerons',
    'boulangeront',
    'boulangismes',
    'boulangistes',
    'boulevardier',
    'bouleversais',
    'bouleversait',
    'bouleversant',
    'bouleversées',
    'bouleversent',
    'bouleversera',
    'bouleversiez',
    'bouleversons',
    'boulonnaient',
    'boulonnasses',
    'boulonnerais',
    'boulonnerait',
    'boulonnèrent',
    'boulonneries',
    'boulonneriez',
    'boulonnerons',
    'boulonneront',
    'boulottaient',
    'boulottasses',
    'boulotterais',
    'boulotterait',
    'boulottèrent',
    'boulotteriez',
    'boulotterons',
    'boulotteront',
    'bouquetières',
    'bouquinaient',
    'bouquinasses',
    'bouquinerais',
    'bouquinerait',
    'bouquinèrent',
    'bouquineries',
    'bouquineriez',
    'bouquinerons',
    'bouquineront',
    'bouquineuses',
    'bouquinistes',
    'bourbonienne',
    'bourdassions',
    'bourderaient',
    'bourdonnâmes',
    'bourdonnante',
    'bourdonnants',
    'bourdonnasse',
    'bourdonnâtes',
    'bourdonnerai',
    'bourdonneras',
    'bourdonnerez',
    'bourdonnions',
    'bourgeoisial',
    'bourgeoisies',
    'bourgeonnais',
    'bourgeonnait',
    'bourgeonnant',
    'bourgeonnent',
    'bourgeonnera',
    'bourgeonniez',
    'bourgeonnons',
    'bourguignons',
    'bourlinguais',
    'bourlinguait',
    'bourlinguant',
    'bourlinguent',
    'bourlinguera',
    'bourlingueur',
    'bourlinguiez',
    'bourlinguons',
    'bourrassions',
    'bourrelaient',
    'bourrelasses',
    'bourrèlement',
    'bourrelèrent',
    'bourrellerai',
    'bourrelleras',
    'bourrellerez',
    'bourrellerie',
    'bourreraient',
    'bourriquâmes',
    'bourriquasse',
    'bourriquâtes',
    'bourriquerai',
    'bourriqueras',
    'bourriquerez',
    'bourriquions',
    'boursicotage',
    'boursicotais',
    'boursicotait',
    'boursicotant',
    'boursicotent',
    'boursicotera',
    'boursicoteur',
    'boursicotier',
    'boursicotiez',
    'boursicotons',
    'boursouflage',
    'boursouflais',
    'boursouflait',
    'boursouflant',
    'boursouflées',
    'boursouflent',
    'boursouflera',
    'boursoufliez',
    'boursouflons',
    'boursouflure',
    'bousculaient',
    'bousculasses',
    'bousculerais',
    'bousculerait',
    'bousculèrent',
    'bousculeriez',
    'bousculerons',
    'bousculeront',
    'bousillaient',
    'bousillasses',
    'bousillerais',
    'bousillerait',
    'bousillèrent',
    'bousilleriez',
    'bousillerons',
    'bousilleront',
    'bousilleuses',
    'boustifailla',
    'boustifaille',
    'boustifaillé',
    'boutiquières',
    'boutonnaient',
    'boutonnasses',
    'boutonnerais',
    'boutonnerait',
    'boutonnèrent',
    'boutonneriez',
    'boutonnerons',
    'boutonneront',
    'boutonneuses',
    'boutonnières',
    'bouturassent',
    'bouturassiez',
    'bouturerions',
    'boycottaient',
    'boycottasses',
    'boycotterais',
    'boycotterait',
    'boycottèrent',
    'boycotteriez',
    'boycotterons',
    'boycotteront',
    'boycotteuses',
    'brabançonnes',
    'braconnaient',
    'braconnasses',
    'braconnerais',
    'braconnerait',
    'braconnèrent',
    'braconneriez',
    'braconnerons',
    'braconneront',
    'braconnières',
    'bradycardies',
    'brahmaniques',
    'brahmanismes',
    'braillassent',
    'braillassiez',
    'braillements',
    'braillerions',
    'brain-trusts',
    'braisassions',
    'braiseraient',
    'brancardière',
    'brancardiers',
    'branchassent',
    'branchassiez',
    'branchements',
    'brancherions',
    'brandebourgs',
    'brandillâmes',
    'brandillasse',
    'brandillâtes',
    'brandillerai',
    'brandilleras',
    'brandillerez',
    'brandillions',
    'brandiraient',
    'brandissions',
    'branlassions',
    'branleraient',
    'braquassions',
    'braqueraient',
    'brasillaient',
    'brasillasses',
    'brasillerais',
    'brasillerait',
    'brasillèrent',
    'brasilleriez',
    'brasillerons',
    'brasilleront',
    'brassassions',
    'brasseraient',
    'bredouillage',
    'bredouillais',
    'bredouillait',
    'bredouillant',
    'bredouillées',
    'bredouillent',
    'bredouillera',
    'bredouilleur',
    'bredouilliez',
    'bredouillons',
    'brellassions',
    'brelleraient',
    'brésiliennes',
    'brésillaient',
    'brésillasses',
    'brésillerais',
    'brésillerait',
    'brésillèrent',
    'brésilleriez',
    'brésillerons',
    'brésilleront',
    'bretonnantes',
    'brettassions',
    'brettelaient',
    'brettelasses',
    'brettelèrent',
    'brettellerai',
    'brettelleras',
    'brettellerez',
    'bretteraient',
    'brevetassent',
    'brevetassiez',
    'brevetterais',
    'brevetterait',
    'brevetteriez',
    'brevetterons',
    'brevetteront',
    'bricolassent',
    'bricolassiez',
    'bricolerions',
    'bridgeassent',
    'bridgeassiez',
    'bridgeraient',
    'briffassions',
    'brifferaient',
    'brigandaient',
    'brigandasses',
    'briganderais',
    'briganderait',
    'brigandèrent',
    'briganderiez',
    'briganderons',
    'briganderont',
    'briguassions',
    'brigueraient',
    'brillantages',
    'brillantâmes',
    'brillantante',
    'brillantants',
    'brillantasse',
    'brillantâtes',
    'brillanterai',
    'brillanteras',
    'brillanterez',
    'brillantinai',
    'brillantinas',
    'brillantinât',
    'brillantinée',
    'brillantiner',
    'brillantines',
    'brillantinés',
    'brillantinez',
    'brillantions',
    'brillassions',
    'brilleraient',
    'brimbalaient',
    'brimbalasses',
    'brimbalement',
    'brimbalerais',
    'brimbalerait',
    'brimbalèrent',
    'brimbaleriez',
    'brimbalerons',
    'brimbaleront',
    'brindezingue',
    'bringueballa',
    'bringueballe',
    'bringueballé',
    'brinquebalai',
    'brinquebalas',
    'brinquebalât',
    'brinquebalée',
    'brinquebaler',
    'brinquebales',
    'brinquebalés',
    'brinquebalez',
    'brinqueballa',
    'brinqueballe',
    'brinqueballé',
    'briquassions',
    'briqueraient',
    'briquetaient',
    'briquetasses',
    'briquetèrent',
    'briqueteries',
    'briquetterai',
    'briquetteras',
    'briquetterez',
    'brise-glaces',
    'brise-mottes',
    'brise-soleil',
    'britanniques',
    'brocantaient',
    'brocantasses',
    'brocanterais',
    'brocanterait',
    'brocantèrent',
    'brocanteriez',
    'brocanterons',
    'brocanteront',
    'brocanteuses',
    'brocardaient',
    'brocardasses',
    'brocarderais',
    'brocarderait',
    'brocardèrent',
    'brocarderiez',
    'brocarderons',
    'brocarderont',
    'brochassions',
    'brocheraient',
    'bromhydrique',
    'bronchassent',
    'bronchassiez',
    'broncherions',
    'bronchiteuse',
    'bronchitique',
    'brontosaures',
    'bronzassions',
    'bronzeraient',
    'brossassions',
    'brosseraient',
    'brouettaient',
    'brouettasses',
    'brouetterais',
    'brouetterait',
    'brouettèrent',
    'brouetteriez',
    'brouetterons',
    'brouetteront',
    'brouillaient',
    'brouillamini',
    'brouillassât',
    'brouillasser',
    'brouillasses',
    'brouillerais',
    'brouillerait',
    'brouillèrent',
    'brouilleries',
    'brouilleriez',
    'brouillerons',
    'brouilleront',
    'brouillonnai',
    'brouillonnas',
    'brouillonnât',
    'brouillonnée',
    'brouillonner',
    'brouillonnes',
    'brouillonnés',
    'brouillonnez',
    'broussailles',
    'broutassions',
    'brouteraient',
    'bruissements',
    'bruitassions',
    'bruiteraient',
    'brûle-gueule',
    'brûle-parfum',
    'brumasserait',
    'brumisateurs',
    'brunissaient',
    'brunissantes',
    'brunissement',
    'brunisseuses',
    'brusquassent',
    'brusquassiez',
    'brusquerions',
    'brutalisâmes',
    'brutalisasse',
    'brutalisâtes',
    'brutaliserai',
    'brutaliseras',
    'brutaliserez',
    'brutalisions',
    'buccinateurs',
    'budgétisâmes',
    'budgétisasse',
    'budgétisâtes',
    'budgétiserai',
    'budgétiseras',
    'budgétiserez',
    'budgétisions',
    'budgétivores',
    'buffleteries',
    'buissonneuse',
    'buissonnière',
    'buissonniers',
    'bull-terrier',
    'bureaucrates',
    'bureaucratie',
    'bureautiques',
    'burinassions',
    'burineraient',
    'butinassions',
    'butineraient',
    'butyromètres',
    'buvotassions',
    'buvoteraient',
    'byzantinisme',
    'byzantiniste',
    'cabalassions',
    'cabaleraient',
    'cabalistique',
    'cabanassions',
    'cabaneraient',
    'cabaretières',
    'câblogrammes',
    'cabossassent',
    'cabossassiez',
    'cabosserions',
    'cabotassions',
    'caboteraient',
    'cabotinaient',
    'cabotinasses',
    'cabotinerais',
    'cabotinerait',
    'cabotinèrent',
    'cabotineriez',
    'cabotinerons',
    'cabotineront',
    'cabriolaient',
    'cabriolasses',
    'cabriolerais',
    'cabriolerait',
    'cabriolèrent',
    'cabrioleriez',
    'cabriolerons',
    'cabrioleront',
    'cacabassions',
    'cacaberaient',
    'cacardassent',
    'cacardassiez',
    'cacarderions',
    'cache-corset',
    'cachectiques',
    'cache-misère',
    'cache-tampon',
    'cachetassent',
    'cachetassiez',
    'cachetterais',
    'cachetterait',
    'cachetteriez',
    'cachetterons',
    'cachetteront',
    'cachotteries',
    'cachottières',
    'cacographies',
    'cacophonique',
    'cadanchaient',
    'cadanchasses',
    'cadancherais',
    'cadancherait',
    'cadanchèrent',
    'cadancheriez',
    'cadancherons',
    'cadancheront',
    'cadastraient',
    'cadastrasses',
    'cadastrerais',
    'cadastrerait',
    'cadastrèrent',
    'cadastreriez',
    'cadastrerons',
    'cadastreront',
    'cadavéreuses',
    'cadavériques',
    'cadenassâmes',
    'cadenassasse',
    'cadenassâtes',
    'cadenasserai',
    'cadenasseras',
    'cadenasserez',
    'cadenassions',
    'cadençassent',
    'cadençassiez',
    'cadencerions',
    'cadmiassions',
    'cadmieraient',
    'cafardassent',
    'cafardassiez',
    'cafarderions',
    'café-concert',
    'café-théâtre',
    'cafouillages',
    'cafouillâmes',
    'cafouillasse',
    'cafouillâtes',
    'cafouillerai',
    'cafouilleras',
    'cafouillerez',
    'cafouillions',
    'cagnardaient',
    'cagnardasses',
    'cagnarderais',
    'cagnarderait',
    'cagnardèrent',
    'cagnarderiez',
    'cagnarderons',
    'cagnarderont',
    'cahotassions',
    'cahoteraient',
    'caillassions',
    'caillebottai',
    'caillebottée',
    'caillebotter',
    'caillebottes',
    'caillebottés',
    'caillebottez',
    'cailleraient',
    'cailletaient',
    'cailletasses',
    'cailletèrent',
    'cailletterai',
    'cailletteras',
    'cailletterez',
    'cailloutages',
    'cailloutâmes',
    'cailloutante',
    'cailloutants',
    'cailloutasse',
    'cailloutâtes',
    'caillouterai',
    'caillouteras',
    'caillouterez',
    'caillouteuse',
    'cailloutions',
    'cajolassions',
    'cajoleraient',
    'calaminaient',
    'calaminasses',
    'calaminerais',
    'calaminerait',
    'calaminèrent',
    'calamineriez',
    'calaminerons',
    'calamineront',
    'calamistrais',
    'calamistrait',
    'calamistrant',
    'calamistrées',
    'calamistrent',
    'calamistrera',
    'calamistriez',
    'calamistrons',
    'calamiteuses',
    'calanchaient',
    'calanchasses',
    'calancherais',
    'calancherait',
    'calanchèrent',
    'calancheriez',
    'calancherons',
    'calancheront',
    'calandraient',
    'calandrasses',
    'calandrerais',
    'calandrerait',
    'calandrèrent',
    'calandreriez',
    'calandrerons',
    'calandreront',
    'calandreuses',
    'calcéolaires',
    'calcinassent',
    'calcinassiez',
    'calcinations',
    'calcinerions',
    'calculassent',
    'calculassiez',
    'calculateurs',
    'calculatrice',
    'calculerions',
    'calebassiers',
    'calédonienne',
    'caléidoscope',
    'caletassions',
    'caletterions',
    'calfatassent',
    'calfatassiez',
    'calfaterions',
    'calfeutrages',
    'calfeutrâmes',
    'calfeutrasse',
    'calfeutrâtes',
    'calfeutrerai',
    'calfeutreras',
    'calfeutrerez',
    'calfeutrions',
    'calibrassent',
    'calibrassiez',
    'calibrations',
    'calibrerions',
    'californiums',
    'califourchon',
    'câlinassions',
    'câlineraient',
    'calligrammes',
    'calligraphes',
    'calligraphia',
    'calligraphie',
    'calligraphié',
    'calmissaient',
    'calomniaient',
    'calomniasses',
    'calomniateur',
    'calomnierais',
    'calomnierait',
    'calomnièrent',
    'calomnieriez',
    'calomnierons',
    'calomnieront',
    'calomnieuses',
    'calorifiques',
    'calorifugeai',
    'calorifugeas',
    'calorifugeât',
    'calorifugées',
    'calorifugent',
    'calorifugera',
    'calorifugiez',
    'calorimètres',
    'calorimétrie',
    'calorisation',
    'calottassent',
    'calottassiez',
    'calotterions',
    'calquassions',
    'calqueraient',
    'camaraderies',
    'cambodgienne',
    'cambrassions',
    'cambreraient',
    'cambriolages',
    'cambriolâmes',
    'cambriolasse',
    'cambriolâtes',
    'cambriolerai',
    'cambrioleras',
    'cambriolerez',
    'cambrioleurs',
    'cambrioleuse',
    'cambriolions',
    'cambrousards',
    'cambutassent',
    'cambutassiez',
    'cambuterions',
    'camelotaient',
    'camelotasses',
    'cameloterais',
    'cameloterait',
    'camelotèrent',
    'cameloteriez',
    'cameloterons',
    'cameloteront',
    'camerlingues',
    'camionnaient',
    'camionnasses',
    'camionnerais',
    'camionnerait',
    'camionnèrent',
    'camionneriez',
    'camionnerons',
    'camionneront',
    'camionnettes',
    'camouflaient',
    'camouflantes',
    'camouflasses',
    'camouflerais',
    'camouflerait',
    'camouflèrent',
    'camoufleriez',
    'camouflerons',
    'camoufleront',
    'campagnardes',
    'camping-cars',
    'canadianisme',
    'canailleries',
    'canalisables',
    'canalisaient',
    'canalisasses',
    'canalisation',
    'canaliserais',
    'canaliserait',
    'canalisèrent',
    'canaliseriez',
    'canaliserons',
    'canaliseront',
    'canapés-lits',
    'canardassent',
    'canardassiez',
    'canarderions',
    'cancanassent',
    'cancanassiez',
    'cancanerions',
    'cancérigènes',
    'cancérologie',
    'cancérologue',
    'candidatures',
    'candissaient',
    'canepetières',
    'caniculaires',
    'cannelassent',
    'cannelassiez',
    'cannellerais',
    'cannellerait',
    'cannelleriez',
    'cannellerons',
    'cannelleront',
    'cannibalisme',
    'canonisables',
    'canonisaient',
    'canonisasses',
    'canonisation',
    'canoniserais',
    'canoniserait',
    'canonisèrent',
    'canoniseriez',
    'canoniserons',
    'canoniseront',
    'canonnassent',
    'canonnassiez',
    'canonnerions',
    'canotassions',
    'canoteraient',
    'cantharidine',
    'cantonnaient',
    'cantonnasses',
    'cantonnement',
    'cantonnerais',
    'cantonnerait',
    'cantonnèrent',
    'cantonneriez',
    'cantonnerons',
    'cantonneront',
    'cantonnières',
    'canularesque',
    'canulassions',
    'canuleraient',
    'caoutchoutai',
    'caoutchoutas',
    'caoutchoutât',
    'caoutchoutée',
    'caoutchouter',
    'caoutchoutes',
    'caoutchoutés',
    'caoutchoutez',
    'caparaçonnai',
    'caparaçonnas',
    'caparaçonnât',
    'caparaçonnée',
    'caparaçonner',
    'caparaçonnes',
    'caparaçonnés',
    'caparaçonnez',
    'capelassions',
    'capellerions',
    'capeyassions',
    'capeyeraient',
    'capillarites',
    'capillarités',
    'capitainerie',
    'capitalisais',
    'capitalisait',
    'capitalisant',
    'capitalisées',
    'capitalisent',
    'capitalisera',
    'capitalisiez',
    'capitalismes',
    'capitalisons',
    'capitalistes',
    'capitonnages',
    'capitonnâmes',
    'capitonnasse',
    'capitonnâtes',
    'capitonnerai',
    'capitonneras',
    'capitonnerez',
    'capitonnions',
    'capitulaient',
    'capitulaires',
    'capitulardes',
    'capitulasses',
    'capitulation',
    'capitulerais',
    'capitulerait',
    'capitulèrent',
    'capituleriez',
    'capitulerons',
    'capituleront',
    'caponnassent',
    'caponnassiez',
    'caponnerions',
    'caporalisais',
    'caporalisait',
    'caporalisant',
    'caporalisées',
    'caporalisent',
    'caporalisera',
    'caporalisiez',
    'caporalismes',
    'caporalisons',
    'capotassions',
    'capoteraient',
    'capricieuses',
    'capsulassent',
    'capsulassiez',
    'capsulerions',
    'captativités',
    'captivassent',
    'captivassiez',
    'captiverions',
    'capturassent',
    'capturassiez',
    'capturerions',
    'capuchonnais',
    'capuchonnait',
    'capuchonnant',
    'capuchonnées',
    'capuchonnent',
    'capuchonnera',
    'capuchonniez',
    'capuchonnons',
    'caquetassent',
    'caquetassiez',
    'caquètements',
    'caquetterais',
    'caquetterait',
    'caquetteriez',
    'caquetterons',
    'caquetteront',
    'caracolaient',
    'caracolasses',
    'caracolerais',
    'caracolerait',
    'caracolèrent',
    'caracoleriez',
    'caracolerons',
    'caracoleront',
    'caractériels',
    'caractérisai',
    'caractérisas',
    'caractérisât',
    'caractérisée',
    'caractériser',
    'caractérises',
    'caractérisés',
    'caractérisez',
    'carambolages',
    'carambolâmes',
    'carambolasse',
    'carambolâtes',
    'carambolerai',
    'caramboleras',
    'carambolerez',
    'carambolions',
    'caramélisais',
    'caramélisait',
    'caramélisant',
    'caramélisées',
    'caramélisent',
    'caramélisera',
    'caramélisiez',
    'caramélisons',
    'carapataient',
    'carapatasses',
    'carapaterais',
    'carapaterait',
    'carapatèrent',
    'carapateriez',
    'carapaterons',
    'carapateront',
    'caravanières',
    'carbonatâmes',
    'carbonatasse',
    'carbonatâtes',
    'carbonaterai',
    'carbonateras',
    'carbonaterez',
    'carbonations',
    'carbonifères',
    'carbonisâmes',
    'carbonisasse',
    'carbonisâtes',
    'carboniserai',
    'carboniseras',
    'carboniserez',
    'carbonisions',
    'carborundums',
    'carboxylases',
    'carburassent',
    'carburassiez',
    'carburateurs',
    'carburations',
    'carburerions',
    'carcaillâmes',
    'carcaillasse',
    'carcaillâtes',
    'carcaillerai',
    'carcailleras',
    'carcaillerez',
    'carcaillions',
    'carcinogènes',
    'carcinologie',
    'cardinalices',
    'cardiogramme',
    'cardiographe',
    'cardiologies',
    'cardiologues',
    'cardiopathie',
    'cardiotomies',
    'carénassions',
    'carençassent',
    'carençassiez',
    'carencerions',
    'caréneraient',
    'carentielles',
    'caressassent',
    'caressassiez',
    'caresserions',
    'carguassions',
    'cargueraient',
    'caricaturais',
    'caricaturait',
    'caricaturale',
    'caricaturant',
    'caricaturaux',
    'caricaturées',
    'caricaturent',
    'caricaturera',
    'caricaturiez',
    'caricaturons',
    'carillonnais',
    'carillonnait',
    'carillonnant',
    'carillonnées',
    'carillonnent',
    'carillonnera',
    'carillonneur',
    'carillonniez',
    'carillonnons',
    'carlinguiers',
    'carminassent',
    'carminassiez',
    'carminatives',
    'carminerions',
    'carnassières',
    'carnifiaient',
    'carnifiasses',
    'carnifierais',
    'carnifierait',
    'carnifièrent',
    'carnifieriez',
    'carnifierons',
    'carnifieront',
    'carolingiens',
    'carottassent',
    'carottassiez',
    'carotterions',
    'carrelassent',
    'carrelassiez',
    'carrellerais',
    'carrellerait',
    'carrelleriez',
    'carrellerons',
    'carrelleront',
    'carriérismes',
    'carriéristes',
    'carroierions',
    'carrossables',
    'carrossaient',
    'carrossasses',
    'carrosserais',
    'carrosserait',
    'carrossèrent',
    'carrosseries',
    'carrosseriez',
    'carrosserons',
    'carrosseront',
    'carroyassent',
    'carroyassiez',
    'carte-lettre',
    'cartésiennes',
    'carthaginois',
    'cartographes',
    'cartographie',
    'cartomancien',
    'cartomancies',
    'cartonnaient',
    'cartonnasses',
    'cartonnerais',
    'cartonnerait',
    'cartonnèrent',
    'cartonneries',
    'cartonneriez',
    'cartonnerons',
    'cartonneront',
    'cartonneuses',
    'cartonnières',
    'cartothèques',
    'cartoucherie',
    'cartouchière',
    'cascadassent',
    'cascadassiez',
    'cascaderions',
    'caséfiassent',
    'caséfiassiez',
    'caséfierions',
    'casemataient',
    'casematasses',
    'casematerais',
    'casematerait',
    'casematèrent',
    'casemateriez',
    'casematerons',
    'casemateront',
    'casernassent',
    'casernassiez',
    'casernements',
    'casernerions',
    'casquassions',
    'casqueraient',
    'casse-croûte',
    'casse-graine',
    'casse-gueule',
    'casse-pattes',
    'cassitérites',
    'castagnaient',
    'castagnasses',
    'castagnerais',
    'castagnerait',
    'castagnèrent',
    'castagneriez',
    'castagnerons',
    'castagneront',
    'castagnettes',
    'castrassions',
    'castratrices',
    'castreraient',
    'casuistiques',
    'catabolismes',
    'catadioptres',
    'catalectique',
    'cataleptique',
    'cataloguâmes',
    'cataloguasse',
    'cataloguâtes',
    'cataloguerai',
    'catalogueras',
    'cataloguerez',
    'cataloguions',
    'catalysaient',
    'catalysasses',
    'catalyserais',
    'catalyserait',
    'catalysèrent',
    'catalyseriez',
    'catalyserons',
    'catalyseront',
    'catalytiques',
    'catapultages',
    'catapultâmes',
    'catapultasse',
    'catapultâtes',
    'catapulterai',
    'catapulteras',
    'catapulterez',
    'catapultions',
    'catarrheuses',
    'catastrophai',
    'catastrophas',
    'catastrophât',
    'catastrophée',
    'catastropher',
    'catastrophes',
    'catastrophés',
    'catastrophez',
    'catchassions',
    'catcheraient',
    'catéchisâmes',
    'catéchisasse',
    'catéchisâtes',
    'catéchiserai',
    'catéchiseras',
    'catéchiserez',
    'catéchisions',
    'catéchuménat',
    'catéchumènes',
    'catégorielle',
    'catégoriques',
    'catharsiques',
    'catherinette',
    'cathétérisme',
    'catholicisme',
    'catholicités',
    'catilinaires',
    'catoptriques',
    'caucasiennes',
    'cauchemardai',
    'cauchemardas',
    'cauchemardât',
    'cauchemarder',
    'cauchemardes',
    'cauchemardez',
    'caulescentes',
    'cautérisâmes',
    'cautérisante',
    'cautérisants',
    'cautérisasse',
    'cautérisâtes',
    'cautériserai',
    'cautériseras',
    'cautériserez',
    'cautérisions',
    'cautionnâmes',
    'cautionnasse',
    'cautionnâtes',
    'cautionnerai',
    'cautionneras',
    'cautionnerez',
    'cautionnions',
    'cavalassions',
    'cavalcadâmes',
    'cavalcadasse',
    'cavalcadâtes',
    'cavalcaderai',
    'cavalcaderas',
    'cavalcaderez',
    'cavalcadions',
    'cavaleraient',
    'cavernicoles',
    'caviardaient',
    'caviardasses',
    'caviarderais',
    'caviarderait',
    'caviardèrent',
    'caviarderiez',
    'caviarderons',
    'caviarderont',
    'ceignissions',
    'ceinturaient',
    'ceinturasses',
    'ceinturerais',
    'ceinturerait',
    'ceinturèrent',
    'ceintureriez',
    'ceinturerons',
    'ceintureront',
    'célébrassent',
    'célébrassiez',
    'célébrations',
    'célébrerions',
    'célibataires',
    'cellulosique',
    'cémentassent',
    'cémentassiez',
    'cémentations',
    'cémenterions',
    'cendrassions',
    'cendreraient',
    'cénesthésies',
    'cénobitiques',
    'cénobitismes',
    'censurassent',
    'censurassiez',
    'censurerions',
    'centésimales',
    'centigrammes',
    'centralisais',
    'centralisait',
    'centralisant',
    'centralisées',
    'centralisent',
    'centralisera',
    'centralisiez',
    'centralismes',
    'centralisons',
    'centrassions',
    'centreraient',
    'centrifugeai',
    'centrifugeas',
    'centrifugeât',
    'centrifugées',
    'centrifugent',
    'centrifugera',
    'centrifugeur',
    'centrifugiez',
    'centuplaient',
    'centuplasses',
    'centuplerais',
    'centuplerait',
    'centuplèrent',
    'centupleriez',
    'centuplerons',
    'centupleront',
    'céphalalgies',
    'cerclassions',
    'cercleraient',
    'cérébelleuse',
    'cérébralités',
    'cérémonielle',
    'cérémonieuse',
    'certainement',
    'certifiaient',
    'certifiasses',
    'certifierais',
    'certifierait',
    'certifièrent',
    'certifieriez',
    'certifierons',
    'certifieront',
    'cérumineuses',
    'cessibilités',
    'cessionnaire',
    'chablassions',
    'chableraient',
    'chagrinaient',
    'chagrinantes',
    'chagrinasses',
    'chagrinerais',
    'chagrinerait',
    'chagrinèrent',
    'chagrineriez',
    'chagrinerons',
    'chagrineront',
    'chahutassent',
    'chahutassiez',
    'chahuterions',
    'chaînassions',
    'chaîneraient',
    'chalcopyrite',
    'chaleureuses',
    'challengeais',
    'challengeait',
    'challengeant',
    'challengeons',
    'challengerai',
    'challengeras',
    'challengerez',
    'challengeurs',
    'challengions',
    'chaloupaient',
    'chaloupasses',
    'chalouperais',
    'chalouperait',
    'chaloupèrent',
    'chalouperiez',
    'chalouperons',
    'chalouperont',
    'chamaillâmes',
    'chamaillasse',
    'chamaillâtes',
    'chamaillerai',
    'chamailleras',
    'chamaillerez',
    'chamaillerie',
    'chamailleurs',
    'chamailleuse',
    'chamaillions',
    'chamarraient',
    'chamarrasses',
    'chamarrerais',
    'chamarrerait',
    'chamarrèrent',
    'chamarreriez',
    'chamarrerons',
    'chamarreront',
    'chambardâmes',
    'chambardasse',
    'chambardâtes',
    'chambarderai',
    'chambarderas',
    'chambarderez',
    'chambardions',
    'chamboulâmes',
    'chamboulasse',
    'chamboulâtes',
    'chamboulerai',
    'chambouleras',
    'chamboulerez',
    'chamboulions',
    'chambrassent',
    'chambrassiez',
    'chambrerions',
    'chamoisaient',
    'chamoisasses',
    'chamoiserais',
    'chamoiserait',
    'chamoisèrent',
    'chamoiseries',
    'chamoiseriez',
    'chamoiserons',
    'chamoiseront',
    'champagnisai',
    'champagnisas',
    'champagnisât',
    'champagnisée',
    'champagniser',
    'champagnises',
    'champagnisés',
    'champagnisez',
    'champenoises',
    'championnats',
    'champlevâmes',
    'champlevasse',
    'champlevâtes',
    'champlèverai',
    'champlèveras',
    'champlèverez',
    'champlevions',
    'chancelaient',
    'chancelantes',
    'chancelasses',
    'chancelèrent',
    'chancelières',
    'chancellerai',
    'chancelleras',
    'chancellerez',
    'chancellerie',
    'chanciraient',
    'chancissions',
    'chanfreinais',
    'chanfreinait',
    'chanfreinant',
    'chanfreinées',
    'chanfreinent',
    'chanfreinera',
    'chanfreiniez',
    'chanfreinons',
    'changeassent',
    'changeassiez',
    'changeraient',
    'chanoinesses',
    'chansonnâmes',
    'chansonnasse',
    'chansonnâtes',
    'chansonnerai',
    'chansonneras',
    'chansonnerez',
    'chansonnette',
    'chansonnière',
    'chansonniers',
    'chansonnions',
    'chanstiquais',
    'chanstiquait',
    'chanstiquant',
    'chanstiquées',
    'chanstiquent',
    'chanstiquera',
    'chanstiquiez',
    'chanstiquons',
    'chantassions',
    'chantefables',
    'chantepleure',
    'chanteraient',
    'chanterelles',
    'chantignoles',
    'chantonnâmes',
    'chantonnasse',
    'chantonnâtes',
    'chantonnerai',
    'chantonneras',
    'chantonnerez',
    'chantonnions',
    'chantournais',
    'chantournait',
    'chantournant',
    'chantournées',
    'chantournent',
    'chantournera',
    'chantourniez',
    'chantournons',
    'chapardaient',
    'chapardasses',
    'chaparderais',
    'chaparderait',
    'chapardèrent',
    'chaparderiez',
    'chaparderons',
    'chaparderont',
    'chapardeuses',
    'chapeautâmes',
    'chapeautasse',
    'chapeautâtes',
    'chapeauterai',
    'chapeauteras',
    'chapeauterez',
    'chapeautions',
    'chapelassent',
    'chapelassiez',
    'chapellenies',
    'chapellerais',
    'chapellerait',
    'chapelleries',
    'chapelleriez',
    'chapellerons',
    'chapelleront',
    'chaperonnais',
    'chaperonnait',
    'chaperonnant',
    'chaperonnées',
    'chaperonnent',
    'chaperonnera',
    'chaperonniez',
    'chaperonnons',
    'chapitraient',
    'chapitrasses',
    'chapitrerais',
    'chapitrerait',
    'chapitrèrent',
    'chapitreriez',
    'chapitrerons',
    'chapitreront',
    'chaplinesque',
    'chaponnaient',
    'chaponnasses',
    'chaponnerais',
    'chaponnerait',
    'chaponnèrent',
    'chaponneriez',
    'chaponnerons',
    'chaponneront',
    'chaptalisais',
    'chaptalisait',
    'chaptalisant',
    'chaptalisées',
    'chaptalisent',
    'chaptalisera',
    'chaptalisiez',
    'chaptalisons',
    'charançonnée',
    'charançonnés',
    'charbonnages',
    'charbonnâmes',
    'charbonnasse',
    'charbonnâtes',
    'charbonnerai',
    'charbonneras',
    'charbonnerez',
    'charbonneuse',
    'charbonnière',
    'charbonniers',
    'charbonnions',
    'charcutaient',
    'charcutasses',
    'charcuterais',
    'charcuterait',
    'charcutèrent',
    'charcuteries',
    'charcuteriez',
    'charcuterons',
    'charcuteront',
    'charcutières',
    'chardonneret',
    'chargeassent',
    'chargeassiez',
    'chargeraient',
    'charmassions',
    'charmeraient',
    'charpentages',
    'charpentâmes',
    'charpentasse',
    'charpentâtes',
    'charpenterai',
    'charpenteras',
    'charpenterez',
    'charpenterie',
    'charpentiers',
    'charpentions',
    'charretières',
    'charriassent',
    'charriassiez',
    'charrierions',
    'charroierais',
    'charroierait',
    'charroieriez',
    'charroierons',
    'charroieront',
    'charronnages',
    'charroyaient',
    'charroyasses',
    'charroyèrent',
    'chassassions',
    'chasse-clous',
    'chasse-neige',
    'chasseraient',
    'chasseresses',
    'chasse-roues',
    'chasubleries',
    'châtaigniers',
    'châtellenies',
    'châtiassions',
    'châtieraient',
    'chatoiements',
    'chatoierions',
    'chatonnaient',
    'chatonnasses',
    'chatonnerais',
    'chatonnerait',
    'chatonnèrent',
    'chatonneriez',
    'chatonnerons',
    'chatonneront',
    'chatouillais',
    'chatouillait',
    'chatouillant',
    'chatouillées',
    'chatouillent',
    'chatouillera',
    'chatouilleux',
    'chatouilliez',
    'chatouillons',
    'chatoyassent',
    'chatoyassiez',
    'châtrassions',
    'châtreraient',
    'chats-huants',
    'chaudronnier',
    'chauffagiste',
    'chauffassent',
    'chauffassiez',
    'chauffe-bain',
    'chaufferette',
    'chaufferions',
    'chaufournier',
    'chaulassions',
    'chauleraient',
    'chaumassions',
    'chaumeraient',
    'chaussassent',
    'chaussassiez',
    'chausse-pied',
    'chausserions',
    'chauvinismes',
    'chauviraient',
    'chauvissions',
    'chavirassent',
    'chavirassiez',
    'chavirements',
    'chavirerions',
    'chelinguâmes',
    'chelinguasse',
    'chelinguâtes',
    'chelinguerai',
    'chelingueras',
    'chelinguerez',
    'chelinguions',
    'cheminassent',
    'cheminassiez',
    'cheminements',
    'cheminerions',
    'chemisassent',
    'chemisassiez',
    'chemiserions',
    'chenillettes',
    'cherchassent',
    'cherchassiez',
    'chercherions',
    'chérifiennes',
    'chérissaient',
    'cherrassions',
    'cherreraient',
    'chevalassent',
    'chevalassiez',
    'chevalements',
    'chevalerions',
    'chevauchâmes',
    'chevauchasse',
    'chevauchâtes',
    'chevaucherai',
    'chevaucheras',
    'chevaucherez',
    'chevauchions',
    'chevillaient',
    'chevillasses',
    'chevillerais',
    'chevillerait',
    'chevillèrent',
    'chevilleriez',
    'chevillerons',
    'chevilleront',
    'chèvre-pieds',
    'chevretaient',
    'chevretasses',
    'chevretèrent',
    'chevretterai',
    'chevretteras',
    'chevretterez',
    'chevronnâmes',
    'chevronnasse',
    'chevronnâtes',
    'chevronnerai',
    'chevronneras',
    'chevronnerez',
    'chevronnions',
    'chevrotaient',
    'chevrotantes',
    'chevrotasses',
    'chevrotement',
    'chevroterais',
    'chevroterait',
    'chevrotèrent',
    'chevroteriez',
    'chevroterons',
    'chevroteront',
    'chewing-gums',
    'chiadassions',
    'chiaderaient',
    'chialassions',
    'chialeraient',
    'chicanassent',
    'chicanassiez',
    'chicanerions',
    'chichiteuses',
    'chicotassent',
    'chicotassiez',
    'chicoterions',
    'chiennassent',
    'chiennassiez',
    'chiennerions',
    'chiens-loups',
    'chiffonnades',
    'chiffonnages',
    'chiffonnâmes',
    'chiffonnasse',
    'chiffonnâtes',
    'chiffonnerai',
    'chiffonneras',
    'chiffonnerez',
    'chiffonnière',
    'chiffonniers',
    'chiffonnions',
    'chiffrassent',
    'chiffrassiez',
    'chiffrements',
    'chiffrerions',
    'chimiquement',
    'chinoisaient',
    'chinoisasses',
    'chinoiserais',
    'chinoiserait',
    'chinoisèrent',
    'chinoiseries',
    'chinoiseriez',
    'chinoiserons',
    'chinoiseront',
    'chipotassent',
    'chipotassiez',
    'chipoterions',
    'chiquassions',
    'chiquenaudes',
    'chiqueraient',
    'chirographia',
    'chirographie',
    'chirographié',
    'chiromancien',
    'chiromancies',
    'chiropractie',
    'chiropraxies',
    'chirurgicale',
    'chirurgicaux',
    'chirurgienne',
    'chlinguaient',
    'chlinguasses',
    'chlinguerais',
    'chlinguerait',
    'chlinguèrent',
    'chlingueriez',
    'chlinguerons',
    'chlingueront',
    'chlorassions',
    'chloreraient',
    'chlorhydrate',
    'chloroformai',
    'chloroformas',
    'chloroformât',
    'chloroformée',
    'chloroformer',
    'chloroformes',
    'chloroformés',
    'chloroformez',
    'chlorophylle',
    'chlorotiques',
    'chloruraient',
    'chlorurasses',
    'chlorurerais',
    'chlorurerait',
    'chlorurèrent',
    'chlorureriez',
    'chlorurerons',
    'chlorureront',
    'chocolaterie',
    'chocolatière',
    'chocolatiers',
    'choisiraient',
    'choisissions',
    'cholécystite',
    'cholériforme',
    'cholestérols',
    'chondriosome',
    'chopinassent',
    'chopinassiez',
    'chopinerions',
    'choppassions',
    'chopperaient',
    'choquassions',
    'choqueraient',
    'chorégraphes',
    'chorégraphie',
    'choroïdienne',
    'chosifiaient',
    'chosifiasses',
    'chosifierais',
    'chosifierait',
    'chosifièrent',
    'chosifieriez',
    'chosifierons',
    'chosifieront',
    'chouanneries',
    'chouchoutais',
    'chouchoutait',
    'chouchoutant',
    'chouchoutées',
    'chouchoutent',
    'chouchoutera',
    'chouchoutiez',
    'chouchoutons',
    'chouravaient',
    'chouravasses',
    'chouraverais',
    'chouraverait',
    'chouravèrent',
    'chouraveriez',
    'chouraverons',
    'chouraveront',
    'chourinaient',
    'chourinasses',
    'chourinerais',
    'chourinerait',
    'chourinèrent',
    'chourineriez',
    'chourinerons',
    'chourineront',
    'choux-fleurs',
    'choux-navets',
    'christianias',
    'christianisa',
    'christianise',
    'christianisé',
    'christologie',
    'chromassions',
    'chromatiques',
    'chromatismes',
    'chromatopsie',
    'chromeraient',
    'chromosphère',
    'chromotypies',
    'chroniquâmes',
    'chroniquasse',
    'chroniquâtes',
    'chroniquerai',
    'chroniqueras',
    'chroniquerez',
    'chroniqueurs',
    'chroniqueuse',
    'chroniquions',
    'chronologies',
    'chronométrai',
    'chronométras',
    'chronométrât',
    'chronométrée',
    'chronométrer',
    'chronométrés',
    'chronomètres',
    'chronométrez',
    'chronométrie',
    'chroumassent',
    'chroumassiez',
    'chrysanthème',
    'chrysobéryls',
    'chrysolithes',
    'chrysoprases',
    'chthoniennes',
    'chuchotaient',
    'chuchotasses',
    'chuchotement',
    'chuchoterais',
    'chuchoterait',
    'chuchotèrent',
    'chuchoteries',
    'chuchoteriez',
    'chuchoterons',
    'chuchoteront',
    'chuintassent',
    'chuintassiez',
    'chuintements',
    'chuinterions',
    'cicatriciels',
    'cicatricules',
    'cicatrisable',
    'cicatrisâmes',
    'cicatrisante',
    'cicatrisants',
    'cicatrisasse',
    'cicatrisâtes',
    'cicatriserai',
    'cicatriseras',
    'cicatriserez',
    'cicatrisions',
    'cicéronienne',
    'cimentassent',
    'cimentassiez',
    'cimentations',
    'cimenterions',
    'cinémascopes',
    'cinémathèque',
    'cinématiques',
    'cinémomètres',
    'cinglassions',
    'cingleraient',
    'cinquantaine',
    'cinquantième',
    'cintrassions',
    'cintreraient',
    'circadiennes',
    'circoncirais',
    'circoncirait',
    'circoncirent',
    'circonciriez',
    'circoncirons',
    'circonciront',
    'circoncisais',
    'circoncisait',
    'circoncisant',
    'circoncisiez',
    'circoncision',
    'circoncisons',
    'circoncisses',
    'circonflexes',
    'circonscrira',
    'circonscrire',
    'circonscrite',
    'circonscrits',
    'circonscrive',
    'circonspecte',
    'circonspects',
    'circonstance',
    'circonvenais',
    'circonvenait',
    'circonvenant',
    'circonveniez',
    'circonvenons',
    'circonvenues',
    'circonvienne',
    'circonvînmes',
    'circonvinsse',
    'circonvîntes',
    'circularités',
    'circulassent',
    'circulassiez',
    'circulations',
    'circulatoire',
    'circulerions',
    'cisaillaient',
    'cisaillasses',
    'cisaillement',
    'cisaillerais',
    'cisaillerait',
    'cisaillèrent',
    'cisailleriez',
    'cisaillerons',
    'cisailleront',
    'ciselassions',
    'ciséleraient',
    'cisellements',
    'cistercienne',
    'cité-dortoir',
    'citoyennetés',
    'citronnelles',
    'civilisables',
    'civilisaient',
    'civilisasses',
    'civilisateur',
    'civilisation',
    'civiliserais',
    'civiliserait',
    'civilisèrent',
    'civiliseriez',
    'civiliserons',
    'civiliseront',
    'clabaudaient',
    'clabaudasses',
    'clabauderais',
    'clabauderait',
    'clabaudèrent',
    'clabauderies',
    'clabauderiez',
    'clabauderons',
    'clabauderont',
    'clabaudeuses',
    'clabotassent',
    'clabotassiez',
    'claboterions',
    'clair-obscur',
    'claironnâmes',
    'claironnante',
    'claironnants',
    'claironnasse',
    'claironnâtes',
    'claironnerai',
    'claironneras',
    'claironnerez',
    'claironnions',
    'clairvoyance',
    'clairvoyante',
    'clairvoyants',
    'clampassions',
    'clamperaient',
    'clampsassent',
    'clampsassiez',
    'clampserions',
    'clamsassions',
    'clamseraient',
    'clandestines',
    'clapissaient',
    'clapotassent',
    'clapotassiez',
    'clapotements',
    'clapoterions',
    'clappassions',
    'clapperaient',
    'clapsassions',
    'clapseraient',
    'claquassions',
    'claquemurais',
    'claquemurait',
    'claquemurant',
    'claquemurées',
    'claquemurent',
    'claquemurera',
    'claquemuriez',
    'claquemurons',
    'claqueraient',
    'claquetaient',
    'claquetasses',
    'claquetèrent',
    'claquetterai',
    'claquetteras',
    'claquetterez',
    'clarifiaient',
    'clarifiasses',
    'clarifierais',
    'clarifierait',
    'clarifièrent',
    'clarifieriez',
    'clarifierons',
    'clarifieront',
    'classassions',
    'classeraient',
    'classicismes',
    'classifiâmes',
    'classifiasse',
    'classifiâtes',
    'classifierai',
    'classifieras',
    'classifierez',
    'classifiions',
    'claudicantes',
    'claudication',
    'claudiquâmes',
    'claudiquasse',
    'claudiquâtes',
    'claudiquerai',
    'claudiqueras',
    'claudiquerez',
    'claudiquions',
    'claustraient',
    'claustrasses',
    'claustration',
    'claustrerais',
    'claustrerait',
    'claustrèrent',
    'claustreriez',
    'claustrerons',
    'claustreront',
    'claveciniste',
    'clavetassent',
    'clavetassiez',
    'clavettaient',
    'clavettasses',
    'clavetterais',
    'clavetterait',
    'clavettèrent',
    'clavetteriez',
    'clavetterons',
    'clavetteront',
    'clayonnaient',
    'clayonnasses',
    'clayonnerais',
    'clayonnerait',
    'clayonnèrent',
    'clayonneriez',
    'clayonnerons',
    'clayonneront',
    'clémentinier',
    'cleptomanies',
    'cléricalisme',
    'cléricatures',
    'clichassions',
    'clicheraient',
    'clignassions',
    'cligneraient',
    'clignotaient',
    'clignotantes',
    'clignotasses',
    'clignotement',
    'clignoterais',
    'clignoterait',
    'clignotèrent',
    'clignoteriez',
    'clignoterons',
    'clignoteront',
    'climatérique',
    'climatisâmes',
    'climatisante',
    'climatisants',
    'climatisasse',
    'climatisâtes',
    'climatiserai',
    'climatiseras',
    'climatiserez',
    'climatiseurs',
    'climatisions',
    'climatologie',
    'climatologue',
    'cliniciennes',
    'cliniquement',
    'cliquetaient',
    'cliquetasses',
    'cliquètement',
    'cliquetèrent',
    'cliquetterai',
    'cliquetteras',
    'cliquetterez',
    'clissassions',
    'clisseraient',
    'clitoridiens',
    'clochardisai',
    'clochardisas',
    'clochardisât',
    'clochardisée',
    'clochardiser',
    'clochardises',
    'clochardisés',
    'clochardisez',
    'clochassions',
    'clocheraient',
    'cloisonnâmes',
    'cloisonnante',
    'cloisonnants',
    'cloisonnasse',
    'cloisonnâtes',
    'cloisonnerai',
    'cloisonneras',
    'cloisonnerez',
    'cloisonnions',
    'cloîtrassent',
    'cloîtrassiez',
    'cloîtrerions',
    'clopinassent',
    'clopinassiez',
    'clopinerions',
    'cloquassions',
    'cloqueraient',
    'clôturassent',
    'clôturassiez',
    'clôturerions',
    'cloutassions',
    'clouteraient',
    'clunisiennes',
    'coacquéreurs',
    'coadjutrices',
    'coagulassent',
    'coagulassiez',
    'coagulateurs',
    'coagulations',
    'coagulatrice',
    'coagulerions',
    'coalescences',
    'coalisassent',
    'coalisassiez',
    'coaliserions',
    'coarctations',
    'coassassions',
    'coasseraient',
    'coassurances',
    'cocaïnomanes',
    'cocaïnomanie',
    'coccygiennes',
    'cochonnaient',
    'cochonnaille',
    'cochonnasses',
    'cochonnerais',
    'cochonnerait',
    'cochonnèrent',
    'cochonneries',
    'cochonneriez',
    'cochonnerons',
    'cochonneront',
    'cocréancière',
    'cocréanciers',
    'cocufiassent',
    'cocufiassiez',
    'cocufierions',
    'codébitrices',
    'codemandeurs',
    'codétenteurs',
    'codétentrice',
    'codifiassent',
    'codifiassiez',
    'codificateur',
    'codification',
    'codifierions',
    'codirecteurs',
    'codirections',
    'codirectrice',
    'codominances',
    'coefficients',
    'coelacanthes',
    'coelioscopie',
    'coéquipières',
    'coercibilité',
    'coexistaient',
    'coexistantes',
    'coexistasses',
    'coexistences',
    'coexisterais',
    'coexisterait',
    'coexistèrent',
    'coexisteriez',
    'coexisterons',
    'coexisteront',
    'coextensives',
    'coffrassions',
    'coffreraient',
    'cogitassions',
    'cogiteraient',
    'cohabitaient',
    'cohabitantes',
    'cohabitasses',
    'cohabitation',
    'cohabiterais',
    'cohabiterait',
    'cohabitèrent',
    'cohabiteriez',
    'cohabiterons',
    'cohabiteront',
    'cohéritaient',
    'cohéritasses',
    'cohériterais',
    'cohériterait',
    'cohéritèrent',
    'cohériteriez',
    'cohériterons',
    'cohériteront',
    'cohéritières',
    'coiffassions',
    'coifferaient',
    'coinçassions',
    'coinceraient',
    'coïncidaient',
    'coïncidasses',
    'coïncidences',
    'coïncidentes',
    'coïnciderais',
    'coïnciderait',
    'coïncidèrent',
    'coïncideriez',
    'coïnciderons',
    'coïncideront',
    'cokéfactions',
    'cokéfiassent',
    'cokéfiassiez',
    'cokéfierions',
    'col-de-cygne',
    'colégataires',
    'colibacilles',
    'colicitantes',
    'colin-tampon',
    'collaborâmes',
    'collaborasse',
    'collaborâtes',
    'collaborerai',
    'collaboreras',
    'collaborerez',
    'collaborions',
    'collatérales',
    'collationnai',
    'collationnas',
    'collationnât',
    'collationnée',
    'collationner',
    'collationnes',
    'collationnés',
    'collationnez',
    'collectaient',
    'collectasses',
    'collecterais',
    'collecterait',
    'collectèrent',
    'collecteriez',
    'collecterons',
    'collecteront',
    'collectionna',
    'collectionne',
    'collectionné',
    'collectivisa',
    'collectivise',
    'collectivisé',
    'collectivité',
    'collectrices',
    'collégialité',
    'collégiennes',
    'colletassent',
    'colletassiez',
    'colletterais',
    'colletterait',
    'colletteriez',
    'colletterons',
    'colletteront',
    'colligeaient',
    'colligeasses',
    'colligerions',
    'collimateurs',
    'collimations',
    'collocations',
    'colloquaient',
    'colloquasses',
    'colloquerais',
    'colloquerait',
    'colloquèrent',
    'colloqueriez',
    'colloquerons',
    'colloqueront',
    'colmatassent',
    'colmatassiez',
    'colmaterions',
    'colocataires',
    'cologarithme',
    'colombophile',
    'colonialisme',
    'colonialiste',
    'colonisaient',
    'colonisasses',
    'colonisateur',
    'colonisation',
    'coloniserais',
    'coloniserait',
    'colonisèrent',
    'coloniseriez',
    'coloniserons',
    'coloniseront',
    'colorassions',
    'coloreraient',
    'coloriassent',
    'coloriassiez',
    'colorierions',
    'colorimètres',
    'colorimétrie',
    'colorisation',
    'colportaient',
    'colportasses',
    'colporterais',
    'colporterait',
    'colportèrent',
    'colporteriez',
    'colporterons',
    'colporteront',
    'colporteuses',
    'colposcopies',
    'coltinassent',
    'coltinassiez',
    'coltinerions',
    'columbariums',
    'combativités',
    'combattaient',
    'combattantes',
    'combattirent',
    'combattisses',
    'combattrions',
    'combientième',
    'combinaisons',
    'combinassent',
    'combinassiez',
    'combinateurs',
    'combinatoire',
    'combinerions',
    'comblanchien',
    'comblassions',
    'combleraient',
    'combustibles',
    'commandaient',
    'commandantes',
    'commandasses',
    'commandement',
    'commanderais',
    'commanderait',
    'commandèrent',
    'commanderies',
    'commanderiez',
    'commanderons',
    'commanderont',
    'commandeuses',
    'commanditais',
    'commanditait',
    'commanditant',
    'commanditées',
    'commanditent',
    'commanditera',
    'commanditiez',
    'commanditons',
    'commémorâmes',
    'commémorasse',
    'commémorâtes',
    'commémoratif',
    'commémorerai',
    'commémoreras',
    'commémorerez',
    'commémorions',
    'commençaient',
    'commençantes',
    'commençasses',
    'commencement',
    'commencerais',
    'commencerait',
    'commencèrent',
    'commenceriez',
    'commencerons',
    'commenceront',
    'commentaient',
    'commentaires',
    'commentasses',
    'commentateur',
    'commenterais',
    'commenterait',
    'commentèrent',
    'commenteriez',
    'commenterons',
    'commenteront',
    'commérassent',
    'commérassiez',
    'commerçaient',
    'commerçantes',
    'commerçasses',
    'commercerais',
    'commercerait',
    'commercèrent',
    'commerceriez',
    'commercerons',
    'commerceront',
    'commerciales',
    'commérerions',
    'commettaient',
    'commettantes',
    'commettrions',
    'comminatoire',
    'comminutives',
    'commissaires',
    'commissariat',
    'commissionna',
    'commissionne',
    'commissionné',
    'commissoires',
    'commissurale',
    'commissuraux',
    'commotionnai',
    'commotionnas',
    'commotionnât',
    'commotionnée',
    'commotionner',
    'commotionnes',
    'commotionnés',
    'commotionnez',
    'commuassions',
    'commueraient',
    'communalisée',
    'communaliser',
    'communalises',
    'communalisés',
    'communalisez',
    'communiaient',
    'communiantes',
    'communiasses',
    'communicable',
    'communicante',
    'communicants',
    'communicatif',
    'communierais',
    'communierait',
    'communièrent',
    'communieriez',
    'communierons',
    'communieront',
    'communiquais',
    'communiquait',
    'communiquant',
    'communiquées',
    'communiquent',
    'communiquera',
    'communiquiez',
    'communiquons',
    'communisante',
    'communisants',
    'commutassent',
    'commutassiez',
    'commutateurs',
    'commutations',
    'commutatives',
    'commuterions',
    'comparaisons',
    'comparaisses',
    'comparaissez',
    'comparaîtrai',
    'comparaîtras',
    'comparaîtrez',
    'comparassent',
    'comparassiez',
    'comparateurs',
    'comparatisme',
    'comparatiste',
    'comparatives',
    'comparatrice',
    'comparerions',
    'compartiment',
    'comparussent',
    'comparussiez',
    'comparutions',
    'compassaient',
    'compassasses',
    'compasserais',
    'compasserait',
    'compassèrent',
    'compasseriez',
    'compasserons',
    'compasseront',
    'compatirions',
    'compatissais',
    'compatissait',
    'compatissant',
    'compatissent',
    'compatissiez',
    'compatissons',
    'compatriotes',
    'compendieuse',
    'compensables',
    'compensaient',
    'compensasses',
    'compensateur',
    'compensation',
    'compenserais',
    'compenserait',
    'compensèrent',
    'compenseriez',
    'compenserons',
    'compenseront',
    'compétassent',
    'compétassiez',
    'compéterions',
    'compétiteurs',
    'compétitions',
    'compétitives',
    'compétitrice',
    'compilassent',
    'compilassiez',
    'compilateurs',
    'compilations',
    'compilatrice',
    'compilerions',
    'compissaient',
    'compissasses',
    'compisserais',
    'compisserait',
    'compissèrent',
    'compisseriez',
    'compisserons',
    'compisseront',
    'complairions',
    'complaisance',
    'complaisante',
    'complaisants',
    'complaisions',
    'complétaient',
    'complétasses',
    'complètement',
    'compléterais',
    'compléterait',
    'complétèrent',
    'compléteriez',
    'compléterons',
    'compléteront',
    'complexaient',
    'complexasses',
    'complexerais',
    'complexerait',
    'complexèrent',
    'complexeriez',
    'complexerons',
    'complexeront',
    'complexifiai',
    'complexifias',
    'complexifiât',
    'complexifiée',
    'complexifier',
    'complexifies',
    'complexifiés',
    'complexifiez',
    'complication',
    'complimentai',
    'complimentas',
    'complimentât',
    'complimentée',
    'complimenter',
    'complimentes',
    'complimentés',
    'complimentez',
    'compliquâmes',
    'compliquasse',
    'compliquâtes',
    'compliquerai',
    'compliqueras',
    'compliquerez',
    'compliquions',
    'complotaient',
    'complotasses',
    'complotèrent',
    'comploteuses',
    'complussions',
    'componctions',
    'comportaient',
    'comportasses',
    'comportement',
    'comporterais',
    'comporterait',
    'comportèrent',
    'comporteriez',
    'comporterons',
    'comporteront',
    'composassent',
    'composassiez',
    'composerâmes',
    'composerasse',
    'composerâtes',
    'compositeurs',
    'compositions',
    'compositrice',
    'compostaient',
    'compostasses',
    'composterais',
    'composterait',
    'compostèrent',
    'composteriez',
    'composterons',
    'composteront',
    'compréhensif',
    'comprenaient',
    'comprendrais',
    'comprendrait',
    'comprendriez',
    'comprendrons',
    'comprendront',
    'comprenettes',
    'compressâmes',
    'compressasse',
    'compressâtes',
    'compresserai',
    'compresseras',
    'compresserez',
    'compresseurs',
    'compressible',
    'compressions',
    'compressives',
    'comprimables',
    'comprimaient',
    'comprimantes',
    'comprimasses',
    'comprimerais',
    'comprimerait',
    'comprimèrent',
    'comprimeriez',
    'comprimerons',
    'comprimeront',
    'comprissions',
    'compromettes',
    'compromettez',
    'compromettra',
    'compromettre',
    'compromirent',
    'compromisses',
    'comptabilisa',
    'comptabilise',
    'comptabilisé',
    'comptabilité',
    'comptassions',
    'compteraient',
    'compte-rendu',
    'compte-tours',
    'compulsaient',
    'compulsasses',
    'compulserais',
    'compulserait',
    'compulsèrent',
    'compulseriez',
    'compulserons',
    'compulseront',
    'computassent',
    'computassiez',
    'computations',
    'computerions',
    'concassaient',
    'concassasses',
    'concasserais',
    'concasserait',
    'concassèrent',
    'concasseriez',
    'concasserons',
    'concasseront',
    'concaténâmes',
    'concaténasse',
    'concaténâtes',
    'concaténerai',
    'concaténeras',
    'concaténerez',
    'concaténions',
    'concédassent',
    'concédassiez',
    'concéderions',
    'concélébrais',
    'concélébrait',
    'concélébrant',
    'concélébrées',
    'concélèbrent',
    'concélébrera',
    'concélébriez',
    'concélébrons',
    'concentrâmes',
    'concentrasse',
    'concentrâtes',
    'concentrerai',
    'concentreras',
    'concentrerez',
    'concentrions',
    'concentrique',
    'conceptacles',
    'conceptismes',
    'conceptrices',
    'conceptuelle',
    'concernaient',
    'concernasses',
    'concernerais',
    'concernerait',
    'concernèrent',
    'concerneriez',
    'concernerons',
    'concerneront',
    'concertaient',
    'concertantes',
    'concertasses',
    'concertation',
    'concerterais',
    'concerterait',
    'concertèrent',
    'concerteriez',
    'concerterons',
    'concerteront',
    'concertistes',
    'concevraient',
    'conchoïdales',
    'conchylienne',
    'conciergerie',
    'conciliables',
    'conciliabule',
    'conciliaient',
    'conciliaires',
    'conciliantes',
    'conciliasses',
    'conciliateur',
    'conciliation',
    'concilierais',
    'concilierait',
    'concilièrent',
    'concilieriez',
    'concilierons',
    'concilieront',
    'concitoyenne',
    'conclavistes',
    'concluraient',
    'conclussions',
    'concoctaient',
    'concoctasses',
    'concocterais',
    'concocterait',
    'concoctèrent',
    'concocteriez',
    'concocterons',
    'concocteront',
    'concomitance',
    'concomitante',
    'concomitants',
    'concordaient',
    'concordances',
    'concordantes',
    'concordasses',
    'concorderais',
    'concorderait',
    'concordèrent',
    'concorderiez',
    'concorderons',
    'concorderont',
    'concouraient',
    'concourantes',
    'concourrions',
    'concoururent',
    'concourusses',
    'concrétaient',
    'concrétasses',
    'concrètement',
    'concréterais',
    'concréterait',
    'concrétèrent',
    'concréteriez',
    'concréterons',
    'concréteront',
    'concrétisais',
    'concrétisait',
    'concrétisant',
    'concrétisées',
    'concrétisent',
    'concrétisera',
    'concrétisiez',
    'concrétisons',
    'concubinages',
    'concupiscent',
    'concurrençai',
    'concurrenças',
    'concurrençât',
    'concurrencée',
    'concurrencer',
    'concurrences',
    'concurrencés',
    'concurrencez',
    'concurrentes',
    'condamnables',
    'condamnaient',
    'condamnasses',
    'condamnation',
    'condamnerais',
    'condamnerait',
    'condamnèrent',
    'condamneriez',
    'condamnerons',
    'condamneront',
    'condensables',
    'condensaient',
    'condensasses',
    'condensateur',
    'condensation',
    'condenserais',
    'condenserait',
    'condensèrent',
    'condenseriez',
    'condenserons',
    'condenseront',
    'condescendes',
    'condescendez',
    'condescendis',
    'condescendit',
    'condescendît',
    'condescendra',
    'condescendre',
    'condescendue',
    'condescendus',
    'condisciples',
    'conditionnai',
    'conditionnas',
    'conditionnât',
    'conditionnée',
    'conditionnel',
    'conditionner',
    'conditionnes',
    'conditionnés',
    'conditionnez',
    'condoléances',
    'condominiums',
    'condottieres',
    'conductances',
    'conductibles',
    'conductivité',
    'conductrices',
    'conduiraient',
    'conduisaient',
    'conduisirent',
    'conduisisses',
    'condyliennes',
    'confectionna',
    'confectionne',
    'confectionné',
    'confédérales',
    'confédérâmes',
    'confédérasse',
    'confédérâtes',
    'confédérerai',
    'confédéreras',
    'confédérerez',
    'confédérions',
    'conférassent',
    'conférassiez',
    'conférencier',
    'conférerions',
    'confessaient',
    'confessasses',
    'confesserais',
    'confesserait',
    'confessèrent',
    'confesseriez',
    'confesserons',
    'confesseront',
    'confiassions',
    'confidentiel',
    'confieraient',
    'configurâmes',
    'configurasse',
    'configurâtes',
    'configurerai',
    'configureras',
    'configurerez',
    'configurions',
    'confinassent',
    'confinassiez',
    'confinements',
    'confinerions',
    'confirmaient',
    'confirmandes',
    'confirmasses',
    'confirmatifs',
    'confirmation',
    'confirmative',
    'confirmerais',
    'confirmerait',
    'confirmèrent',
    'confirmeriez',
    'confirmerons',
    'confirmeront',
    'confiscables',
    'confiscation',
    'confisquâmes',
    'confisquasse',
    'confisquâtes',
    'confisquerai',
    'confisqueras',
    'confisquerez',
    'confisquions',
    'confiturerie',
    'confiturière',
    'confituriers',
    'conflictuels',
    'confluassent',
    'confluassiez',
    'confluerions',
    'confondaient',
    'confondantes',
    'confondirent',
    'confondisses',
    'confondrions',
    'conformaient',
    'conformasses',
    'conformateur',
    'conformation',
    'conformément',
    'conformerais',
    'conformerait',
    'conformèrent',
    'conformeriez',
    'conformerons',
    'conformeront',
    'conformismes',
    'conformistes',
    'confortables',
    'confortaient',
    'confortasses',
    'conforterais',
    'conforterait',
    'confortèrent',
    'conforteriez',
    'conforterons',
    'conforteront',
    'confraternel',
    'confrontâmes',
    'confrontasse',
    'confrontâtes',
    'confronterai',
    'confronteras',
    'confronterez',
    'confrontions',
    'confusionnel',
    'congédiables',
    'congédiaient',
    'congédiasses',
    'congédiement',
    'congédierais',
    'congédierait',
    'congédièrent',
    'congédieriez',
    'congédierons',
    'congédieront',
    'congelassent',
    'congelassiez',
    'congélateurs',
    'congélations',
    'congèlerions',
    'congénitales',
    'congestionna',
    'congestionne',
    'congestionné',
    'conglomérais',
    'conglomérait',
    'conglomérant',
    'conglomérats',
    'conglomérées',
    'conglomèrent',
    'conglomérera',
    'conglomériez',
    'conglomérons',
    'conglutinais',
    'conglutinait',
    'conglutinant',
    'conglutinées',
    'conglutinent',
    'conglutinera',
    'conglutiniez',
    'conglutinons',
    'congratulais',
    'congratulait',
    'congratulant',
    'congratulées',
    'congratulent',
    'congratulera',
    'congratuliez',
    'congratulons',
    'congréassent',
    'congréassiez',
    'congréerions',
    'congrégation',
    'congressiste',
    'conjecturais',
    'conjecturait',
    'conjecturale',
    'conjecturant',
    'conjecturaux',
    'conjecturées',
    'conjecturent',
    'conjecturera',
    'conjecturiez',
    'conjecturons',
    'conjoignîmes',
    'conjoignions',
    'conjoignisse',
    'conjoignîtes',
    'conjoindrais',
    'conjoindrait',
    'conjoindriez',
    'conjoindrons',
    'conjoindront',
    'conjoncteurs',
    'conjonctions',
    'conjonctival',
    'conjonctives',
    'conjoncturel',
    'conjonctures',
    'conjugaisons',
    'conjuguaient',
    'conjuguasses',
    'conjuguerais',
    'conjuguerait',
    'conjuguèrent',
    'conjugueriez',
    'conjuguerons',
    'conjugueront',
    'conjurassent',
    'conjurassiez',
    'conjurateurs',
    'conjurations',
    'conjuratrice',
    'conjurerions',
    'connaissable',
    'connaissance',
    'connaisseurs',
    'connaisseuse',
    'connaissions',
    'connaîtrions',
    'connectaient',
    'connectasses',
    'connecterais',
    'connecterait',
    'connectèrent',
    'connecteriez',
    'connecterons',
    'connecteront',
    'connotassent',
    'connotassiez',
    'connotations',
    'connoterions',
    'conobrassent',
    'conobrassiez',
    'conobrerions',
    'conpressâmes',
    'conpressante',
    'conpressants',
    'conpressasse',
    'conpressâtes',
    'conpresserai',
    'conpresseras',
    'conpresserez',
    'conpressions',
    'conquéraient',
    'conquérantes',
    'conquerrions',
    'conquissions',
    'conquistador',
    'consacraient',
    'consacrasses',
    'consacrerais',
    'consacrerait',
    'consacrèrent',
    'consacreriez',
    'consacrerons',
    'consacreront',
    'consanguines',
    'consciemment',
    'conscription',
    'consécration',
    'consécutions',
    'consécutives',
    'conseillâmes',
    'conseillasse',
    'conseillâtes',
    'conseillerai',
    'conseilleras',
    'conseillères',
    'conseillerez',
    'conseilleurs',
    'conseilleuse',
    'conseillions',
    'consentaient',
    'consentantes',
    'consentement',
    'consentirais',
    'consentirait',
    'consentirent',
    'consentiriez',
    'consentirons',
    'consentiront',
    'consentisses',
    'conséquences',
    'conséquentes',
    'conservaient',
    'conservasses',
    'conservateur',
    'conservation',
    'conserverais',
    'conserverait',
    'conservèrent',
    'conserveries',
    'conserveriez',
    'conserverons',
    'conserveront',
    'considérable',
    'considérâmes',
    'considérante',
    'considérants',
    'considérasse',
    'considérâtes',
    'considérerai',
    'considéreras',
    'considérerez',
    'considérions',
    'consignaient',
    'consignasses',
    'consignation',
    'consignerais',
    'consignerait',
    'consignèrent',
    'consigneriez',
    'consignerons',
    'consigneront',
    'consistaient',
    'consistances',
    'consistantes',
    'consistasses',
    'consisterais',
    'consisterait',
    'consistèrent',
    'consisteriez',
    'consisterons',
    'consisteront',
    'consistoires',
    'consistorial',
    'consolassent',
    'consolassiez',
    'consolateurs',
    'consolations',
    'consolatrice',
    'consolerions',
    'consolidâmes',
    'consolidasse',
    'consolidâtes',
    'consoliderai',
    'consolideras',
    'consoliderez',
    'consolidions',
    'consommables',
    'consommaient',
    'consommasses',
    'consommateur',
    'consommation',
    'consommerais',
    'consommerait',
    'consommèrent',
    'consommeriez',
    'consommerons',
    'consommeront',
    'consomptible',
    'consomptions',
    'consomptives',
    'consonassent',
    'consonassiez',
    'consonerions',
    'conspiraient',
    'conspirasses',
    'conspirateur',
    'conspiration',
    'conspirerais',
    'conspirerait',
    'conspirèrent',
    'conspireriez',
    'conspirerons',
    'conspireront',
    'conspuassent',
    'conspuassiez',
    'conspuerions',
    'constataient',
    'constatasses',
    'constatation',
    'constaterais',
    'constaterait',
    'constatèrent',
    'constateriez',
    'constaterons',
    'constateront',
    'constellâmes',
    'constellasse',
    'constellâtes',
    'constellerai',
    'constelleras',
    'constellerez',
    'constellions',
    'consternâmes',
    'consternante',
    'consternants',
    'consternasse',
    'consternâtes',
    'consternerai',
    'consterneras',
    'consternerez',
    'consternions',
    'constipaient',
    'constipasses',
    'constipation',
    'constiperais',
    'constiperait',
    'constipèrent',
    'constiperiez',
    'constiperons',
    'constiperont',
    'constituâmes',
    'constituante',
    'constituants',
    'constituasse',
    'constituâtes',
    'constituerai',
    'constitueras',
    'constituerez',
    'constituions',
    'constitutifs',
    'constitution',
    'constitutive',
    'constricteur',
    'constructeur',
    'constructifs',
    'construction',
    'constructive',
    'construirais',
    'construirait',
    'construiriez',
    'construirons',
    'construiront',
    'construisais',
    'construisait',
    'construisant',
    'construisent',
    'construisiez',
    'construisons',
    'consultables',
    'consultaient',
    'consultantes',
    'consultasses',
    'consultatifs',
    'consultation',
    'consultative',
    'consulterais',
    'consulterait',
    'consultèrent',
    'consulteriez',
    'consulterons',
    'consulteront',
    'consumassent',
    'consumassiez',
    'consumerions',
    'consumérisme',
    'consumériste',
    'contactaient',
    'contactasses',
    'contacterais',
    'contacterait',
    'contactèrent',
    'contacteriez',
    'contacterons',
    'contacteront',
    'contactrices',
    'contagieuses',
    'contagionnai',
    'contagionnas',
    'contagionnât',
    'contagionnée',
    'contagionner',
    'contagionnes',
    'contagionnés',
    'contagionnez',
    'contagiosité',
    'containerisa',
    'containerise',
    'containerisé',
    'contaminâmes',
    'contaminasse',
    'contaminâtes',
    'contaminerai',
    'contamineras',
    'contaminerez',
    'contaminions',
    'contemplâmes',
    'contemplasse',
    'contemplâtes',
    'contemplatif',
    'contemplerai',
    'contempleras',
    'contemplerez',
    'contemplions',
    'contemporain',
    'contempteurs',
    'contemptrice',
    'conteneurisa',
    'conteneurise',
    'conteneurisé',
    'contentaient',
    'contentasses',
    'contentement',
    'contenterais',
    'contenterait',
    'contentèrent',
    'contenteriez',
    'contenterons',
    'contenteront',
    'contentieuse',
    'contestables',
    'contestaient',
    'contestasses',
    'contestation',
    'contesterais',
    'contesterait',
    'contestèrent',
    'contesteriez',
    'contesterons',
    'contesteront',
    'contextuelle',
    'contiendrais',
    'contiendrait',
    'contiendriez',
    'contiendrons',
    'contiendront',
    'continentale',
    'continentaux',
    'contingences',
    'contingentai',
    'contingentas',
    'contingentât',
    'contingentée',
    'contingenter',
    'contingentes',
    'contingentés',
    'contingentez',
    'continssions',
    'continuaient',
    'continuasses',
    'continuateur',
    'continuation',
    'continuelles',
    'continuerais',
    'continuerait',
    'continuèrent',
    'continueriez',
    'continuerons',
    'continueront',
    'contondantes',
    'contorsionna',
    'contorsionne',
    'contorsionné',
    'contournâmes',
    'contournasse',
    'contournâtes',
    'contournerai',
    'contourneras',
    'contournerez',
    'contournions',
    'contraceptif',
    'contractâmes',
    'contractante',
    'contractants',
    'contractasse',
    'contractâtes',
    'contracterai',
    'contracteras',
    'contracterez',
    'contractions',
    'contractuels',
    'contracturai',
    'contracturas',
    'contracturât',
    'contracturée',
    'contracturer',
    'contractures',
    'contracturés',
    'contracturez',
    'contraignais',
    'contraignait',
    'contraignant',
    'contraignent',
    'contraigniez',
    'contraignons',
    'contraindrai',
    'contraindras',
    'contraindrez',
    'contraposais',
    'contraposait',
    'contraposant',
    'contraposées',
    'contraposent',
    'contraposera',
    'contraposiez',
    'contraposons',
    'contrariâmes',
    'contrariante',
    'contrariants',
    'contrariasse',
    'contrariâtes',
    'contrarierai',
    'contrarieras',
    'contrarierez',
    'contrariétés',
    'contrariions',
    'contrassions',
    'contrastâmes',
    'contrastasse',
    'contrastâtes',
    'contrasterai',
    'contrasteras',
    'contrasterez',
    'contrastions',
    'contre-alizé',
    'contre-allée',
    'contrebandes',
    'contrebasses',
    'contrebasson',
    'contrebattes',
    'contrebattez',
    'contrebattis',
    'contrebattit',
    'contrebattît',
    'contrebattra',
    'contrebattre',
    'contrebattue',
    'contrebattus',
    'contreboutai',
    'contreboutas',
    'contreboutât',
    'contreboutée',
    'contrebouter',
    'contreboutes',
    'contreboutés',
    'contreboutez',
    'contre-butai',
    'contrebutais',
    'contrebutait',
    'contrebutant',
    'contre-butas',
    'contre-butât',
    'contre-butée',
    'contrebutées',
    'contrebutent',
    'contre-buter',
    'contrebutera',
    'contre-butes',
    'contre-butés',
    'contre-butez',
    'contrebutiez',
    'contrebutons',
    'contrecarrai',
    'contrecarras',
    'contrecarrât',
    'contrecarrée',
    'contrecarrer',
    'contrecarres',
    'contrecarrés',
    'contrecarrez',
    'contrechamps',
    'contre-chant',
    'contrecoeurs',
    'contredanses',
    'contre-digue',
    'contredirais',
    'contredirait',
    'contredirent',
    'contrediriez',
    'contredirons',
    'contrediront',
    'contredisais',
    'contredisait',
    'contredisant',
    'contredisent',
    'contredisiez',
    'contredisons',
    'contredisses',
    'contre-écrou',
    'contre-essai',
    'contrefaçons',
    'contrefaites',
    'contrefasses',
    'contreferais',
    'contreferait',
    'contreferiez',
    'contreferons',
    'contreferont',
    'contrefichai',
    'contrefichas',
    'contrefichât',
    'contrefichée',
    'contreficher',
    'contrefiches',
    'contrefichés',
    'contrefichez',
    'contre-filet',
    'contrefirent',
    'contrefisses',
    'contrefoutes',
    'contrefoutez',
    'contrefoutra',
    'contrefoutre',
    'contrefoutue',
    'contrefoutus',
    'contre-fugue',
    'contre-jours',
    'contremaître',
    'contremandai',
    'contremandas',
    'contremandât',
    'contremandée',
    'contremander',
    'contremandes',
    'contremandés',
    'contremandez',
    'contremarche',
    'contremarqua',
    'contremarque',
    'contremarqué',
    'contre-minai',
    'contre-minas',
    'contre-minât',
    'contre-minée',
    'contre-miner',
    'contre-mines',
    'contre-minés',
    'contre-minez',
    'contre-murai',
    'contre-muras',
    'contre-murât',
    'contre-murée',
    'contre-murer',
    'contre-murés',
    'contre-murez',
    'contre-ordre',
    'contrepartie',
    'contre-passa',
    'contre-passe',
    'contre-passé',
    'contre-pente',
    'contre-pieds',
    'contrepoints',
    'contrepoison',
    'contre-porte',
    'contreraient',
    'contre-rails',
    'contrescarpe',
    'contreseings',
    'contresignai',
    'contresignas',
    'contresignât',
    'contresignée',
    'contresigner',
    'contresignes',
    'contresignés',
    'contresignez',
    'contre-sujet',
    'contre-tirai',
    'contre-tiras',
    'contre-tirât',
    'contre-tirée',
    'contre-tirer',
    'contre-tires',
    'contre-tirés',
    'contre-tirez',
    'contre-vairs',
    'contrevenais',
    'contrevenait',
    'contrevenant',
    'contreveniez',
    'contrevenons',
    'contrevenues',
    'contrevérité',
    'contrevienne',
    'contrevînmes',
    'contrevinsse',
    'contrevîntes',
    'contre-voies',
    'contribuable',
    'contribuâmes',
    'contribuasse',
    'contribuâtes',
    'contribuerai',
    'contribueras',
    'contribuerez',
    'contribuions',
    'contributifs',
    'contribution',
    'contributive',
    'contristâmes',
    'contristasse',
    'contristâtes',
    'contristerai',
    'contristeras',
    'contristerez',
    'contristions',
    'contrôlables',
    'contrôlaient',
    'contrôlasses',
    'contrôlerais',
    'contrôlerait',
    'contrôlèrent',
    'contrôleriez',
    'contrôlerons',
    'contrôleront',
    'contrôleuses',
    'controuvâmes',
    'controuvasse',
    'controuvâtes',
    'controuverai',
    'controuveras',
    'controuverez',
    'controuvions',
    'controversai',
    'controversas',
    'controversât',
    'controversée',
    'controverser',
    'controverses',
    'controversés',
    'controversez',
    'contusionnai',
    'contusionnas',
    'contusionnât',
    'contusionnée',
    'contusionner',
    'contusionnes',
    'contusionnés',
    'contusionnez',
    'conurbations',
    'convaincante',
    'convaincants',
    'convaincrais',
    'convaincrait',
    'convaincriez',
    'convaincrons',
    'convaincront',
    'convainquais',
    'convainquait',
    'convainquant',
    'convainquent',
    'convainquiez',
    'convainquons',
    'convalescent',
    'conventionna',
    'conventionne',
    'conventionné',
    'conventuelle',
    'convergeâmes',
    'convergeante',
    'convergeants',
    'convergeasse',
    'convergeâtes',
    'convergences',
    'convergentes',
    'convergerais',
    'convergerait',
    'convergèrent',
    'convergeriez',
    'convergerons',
    'convergeront',
    'conversaient',
    'conversasses',
    'conversation',
    'converserais',
    'converserait',
    'conversèrent',
    'converseriez',
    'converserons',
    'converseront',
    'convertibles',
    'convertirais',
    'convertirait',
    'convertirent',
    'convertiriez',
    'convertirons',
    'convertiront',
    'convertisses',
    'convertissez',
    'conviassions',
    'conviendrais',
    'conviendrait',
    'conviendriez',
    'conviendrons',
    'conviendront',
    'convieraient',
    'convinssions',
    'convivialité',
    'convocations',
    'convoierions',
    'convoitaient',
    'convoitasses',
    'convoiterais',
    'convoiterait',
    'convoitèrent',
    'convoiteriez',
    'convoiterons',
    'convoiteront',
    'convolassent',
    'convolassiez',
    'convolerions',
    'convolutions',
    'convoquaient',
    'convoquasses',
    'convoquerais',
    'convoquerait',
    'convoquèrent',
    'convoqueriez',
    'convoquerons',
    'convoqueront',
    'convoyassent',
    'convoyassiez',
    'convulsaient',
    'convulsasses',
    'convulserais',
    'convulserait',
    'convulsèrent',
    'convulseriez',
    'convulserons',
    'convulseront',
    'convulsionna',
    'convulsionne',
    'convulsionné',
    'coopérassent',
    'coopérassiez',
    'coopérateurs',
    'coopérations',
    'coopératisme',
    'coopératives',
    'coopératrice',
    'coopérerions',
    'cooptassions',
    'coopteraient',
    'coordinateur',
    'coordination',
    'coordonnâmes',
    'coordonnasse',
    'coordonnâtes',
    'coordonnerai',
    'coordonneras',
    'coordonnerez',
    'coordonnions',
    'copartageant',
    'copermutâmes',
    'copermutasse',
    'copermutâtes',
    'copermuterai',
    'copermuteras',
    'copermuterez',
    'copermutions',
    'coperniciens',
    'copieusement',
    'copinassions',
    'copineraient',
    'copossesseur',
    'copposédâmes',
    'copposédasse',
    'copposédâtes',
    'copposéderai',
    'copposéderas',
    'copposéderez',
    'copposédions',
    'coprésidence',
    'coprésidente',
    'coprésidents',
    'coproduction',
    'copropriétés',
    'copulassions',
    'copuleraient',
    'coquelucheux',
    'coquetassent',
    'coquetassiez',
    'coquettement',
    'coquetterais',
    'coquetterait',
    'coquetteries',
    'coquetteriez',
    'coquetterons',
    'coquetteront',
    'coquillaient',
    'coquillasses',
    'coquillerais',
    'coquillerait',
    'coquillèrent',
    'coquilleriez',
    'coquillerons',
    'coquilleront',
    'coquillettes',
    'coquillières',
    'coralliennes',
    'cordelassent',
    'cordelassiez',
    'cordellerais',
    'cordellerait',
    'cordelleriez',
    'cordellerons',
    'cordelleront',
    'cordialement',
    'cordonnaient',
    'cordonnasses',
    'cordonnerais',
    'cordonnerait',
    'cordonnèrent',
    'cordonneries',
    'cordonneriez',
    'cordonnerons',
    'cordonneront',
    'corinthienne',
    'cornéliennes',
    'cornemuseurs',
    'cornettistes',
    'coronarienne',
    'corporations',
    'corporatisme',
    'corporatiste',
    'corporatives',
    'correctement',
    'correctrices',
    'corrélassent',
    'corrélassiez',
    'corrélations',
    'corrélatives',
    'corrélerions',
    'correspondes',
    'correspondez',
    'correspondis',
    'correspondit',
    'correspondît',
    'correspondra',
    'correspondre',
    'correspondue',
    'correspondus',
    'corrigeaient',
    'corrigeasses',
    'corrigerions',
    'corroborâmes',
    'corroborasse',
    'corroborâtes',
    'corroborerai',
    'corroboreras',
    'corroborerez',
    'corroborions',
    'corrodassent',
    'corrodassiez',
    'corroderions',
    'corroierions',
    'corrompaient',
    'corrompirent',
    'corrompisses',
    'corromprions',
    'corroyassent',
    'corroyassiez',
    'corruptibles',
    'corruptrices',
    'corsetassent',
    'corsetassiez',
    'corsèterions',
    'cosignataire',
    'cosmétiquais',
    'cosmétiquait',
    'cosmétiquant',
    'cosmétiquées',
    'cosmétiquent',
    'cosmétiquera',
    'cosmétiquiez',
    'cosmétiquons',
    'cosmétologie',
    'cosmétologue',
    'cosmogonique',
    'cosmographie',
    'cosmopolites',
    'costumassent',
    'costumassiez',
    'costumerions',
    'cotisassions',
    'cotiseraient',
    'côtoieraient',
    'cotonnassent',
    'cotonnassiez',
    'cotonnerions',
    'côtoyassions',
    'couchaillais',
    'couchaillent',
    'couchaillera',
    'couchassions',
    'coucheraient',
    'coudoiements',
    'coudoierions',
    'coudoyassent',
    'coudoyassiez',
    'couillonnais',
    'couillonnait',
    'couillonnant',
    'couillonnées',
    'couillonnent',
    'couillonnera',
    'couillonniez',
    'couillonnons',
    'couinassions',
    'couineraient',
    'coulissaient',
    'coulissantes',
    'coulissasses',
    'coulisserais',
    'coulisserait',
    'coulissèrent',
    'coulisseriez',
    'coulisserons',
    'coulisseront',
    'coupaillâmes',
    'coupaillasse',
    'coupaillâtes',
    'coupaillerai',
    'coupailleras',
    'coupaillerez',
    'coupaillions',
    'coupellaient',
    'coupellasses',
    'coupellerais',
    'coupellerait',
    'coupellèrent',
    'coupelleriez',
    'coupellerons',
    'coupelleront',
    'coupe-ongles',
    'coupe-papier',
    'couplassions',
    'coupleraient',
    'couraillâmes',
    'couraillasse',
    'couraillâtes',
    'couraillerai',
    'courailleras',
    'couraillerez',
    'couraillions',
    'courbassions',
    'courbaturais',
    'courbaturait',
    'courbaturant',
    'courbaturées',
    'courbaturent',
    'courbaturera',
    'courbaturiez',
    'courbaturons',
    'courberaient',
    'courcaillets',
    'couronnaient',
    'couronnasses',
    'couronnement',
    'couronnerais',
    'couronnerait',
    'couronnèrent',
    'couronneriez',
    'couronnerons',
    'couronneront',
    'courrouçâmes',
    'courrouçasse',
    'courrouçâtes',
    'courroucerai',
    'courrouceras',
    'courroucerez',
    'courroucions',
    'coursassions',
    'courseraient',
    'courtaudâmes',
    'courtaudasse',
    'courtaudâtes',
    'courtauderai',
    'courtauderas',
    'courtauderez',
    'courtaudions',
    'courtepointe',
    'courtilières',
    'courtisaient',
    'courtisasses',
    'courtiserais',
    'courtiserait',
    'courtisèrent',
    'courtiseriez',
    'courtiserons',
    'courtiseront',
    'cous-de-pied',
    'cousinassent',
    'cousinassiez',
    'cousinerions',
    'couteau-scie',
    'coutelleries',
    'coûteusement',
    'couturassent',
    'couturassiez',
    'couturerions',
    'couvre-chefs',
    'couvre-pieds',
    'couvriraient',
    'couvrissions',
    'coxarthroses',
    'crachassions',
    'cracheraient',
    'crachinerait',
    'crachotaient',
    'crachotasses',
    'crachotement',
    'crachoterais',
    'crachoterait',
    'crachotèrent',
    'crachoteriez',
    'crachoterons',
    'crachoteront',
    'crachouillai',
    'crachouillas',
    'crachouillât',
    'crachouiller',
    'crachouilles',
    'crachouillez',
    'craignissent',
    'craignissiez',
    'craillassent',
    'craillassiez',
    'craillerions',
    'craindraient',
    'cramponnâmes',
    'cramponnasse',
    'cramponnâtes',
    'cramponnerai',
    'cramponneras',
    'cramponnerez',
    'cramponnions',
    'crampsassent',
    'crampsassiez',
    'crampserions',
    'cramsassions',
    'cramseraient',
    'crantassions',
    'cranteraient',
    'crapahutâmes',
    'crapahutasse',
    'crapahutâtes',
    'crapahuterai',
    'crapahuteras',
    'crapahuterez',
    'crapahutions',
    'crapaütaient',
    'crapaütasses',
    'crapaüterais',
    'crapaüterait',
    'crapaütèrent',
    'crapaüteriez',
    'crapaüterons',
    'crapaüteront',
    'crapouillots',
    'crapoussines',
    'crapulassent',
    'crapulassiez',
    'crapulerions',
    'craquassions',
    'craquelaient',
    'craquelasses',
    'craquèlement',
    'craquelèrent',
    'craquellerai',
    'craquelleras',
    'craquellerez',
    'craqueraient',
    'craquetaient',
    'craquetasses',
    'craquètement',
    'craquetèrent',
    'craquetterai',
    'craquetteras',
    'craquetterez',
    'crassassions',
    'crasseraient',
    'cratériforme',
    'cravachaient',
    'cravachasses',
    'cravacherais',
    'cravacherait',
    'cravachèrent',
    'cravacheriez',
    'cravacherons',
    'cravacheront',
    'cravatassent',
    'cravatassiez',
    'cravaterions',
    'crawlassions',
    'crawleraient',
    'crayonnaient',
    'crayonnasses',
    'crayonnerais',
    'crayonnerait',
    'crayonnèrent',
    'crayonneriez',
    'crayonnerons',
    'crayonneront',
    'créchassions',
    'crécheraient',
    'crédibilités',
    'créditassent',
    'créditassiez',
    'créditerions',
    'crémaillères',
    'crénelassent',
    'crénelassiez',
    'crénellerais',
    'crénellerait',
    'crénelleriez',
    'crénellerons',
    'crénelleront',
    'créolisaient',
    'créolisasses',
    'créoliserais',
    'créoliserait',
    'créolisèrent',
    'créoliseriez',
    'créoliserons',
    'créoliseront',
    'créosotaient',
    'créosotasses',
    'créosoterais',
    'créosoterait',
    'créosotèrent',
    'créosoteriez',
    'créosoterons',
    'créosoteront',
    'crépissaient',
    'crépitassent',
    'crépitassiez',
    'crépitations',
    'crépitements',
    'crépiterions',
    'cressonnière',
    'crétinisâmes',
    'crétinisasse',
    'crétinisâtes',
    'crétiniserai',
    'crétiniseras',
    'crétiniserez',
    'crétinisions',
    'creusassions',
    'creuseraient',
    'crevassaient',
    'crevassasses',
    'crevasserais',
    'crevasserait',
    'crevassèrent',
    'crevasseriez',
    'crevasserons',
    'crevasseront',
    'criaillaient',
    'criaillasses',
    'criaillement',
    'criaillerais',
    'criaillerait',
    'criaillèrent',
    'criailleries',
    'criailleriez',
    'criaillerons',
    'criailleront',
    'criblassions',
    'cribleraient',
    'criminalisai',
    'criminalisas',
    'criminalisât',
    'criminalisée',
    'criminaliser',
    'criminalises',
    'criminalisés',
    'criminalisez',
    'criminalités',
    'criminologie',
    'criminologue',
    'crispassions',
    'crisperaient',
    'crissassions',
    'crisseraient',
    'cristallerie',
    'cristallines',
    'cristallisai',
    'cristallisas',
    'cristallisât',
    'cristallisée',
    'cristalliser',
    'cristallises',
    'cristallisés',
    'cristallisez',
    'criticaillai',
    'criticaillas',
    'criticaillât',
    'criticaillée',
    'criticailler',
    'criticailles',
    'criticaillés',
    'criticaillez',
    'critiquables',
    'critiquaient',
    'critiquasses',
    'critiquerais',
    'critiquerait',
    'critiquèrent',
    'critiqueriez',
    'critiquerons',
    'critiqueront',
    'croassassent',
    'croassassiez',
    'croassements',
    'croasserions',
    'crochassions',
    'croche-pieds',
    'crocheraient',
    'crochetaient',
    'crochetasses',
    'crochèterais',
    'crochèterait',
    'crochetèrent',
    'crochèteriez',
    'crochèterons',
    'crochèteront',
    'croisassions',
    'croiseraient',
    'croquassions',
    'croqueraient',
    'crossassions',
    'crosseraient',
    'crottassions',
    'crotteraient',
    'croulassions',
    'crouleraient',
    'croupiraient',
    'croupissante',
    'croupissants',
    'croupissions',
    'croustillais',
    'croustillait',
    'croustillant',
    'croustillent',
    'croustillera',
    'croustilliez',
    'croustillons',
    'croûtassions',
    'croûteraient',
    'crucifiaient',
    'crucifiasses',
    'crucifiement',
    'crucifierais',
    'crucifierait',
    'crucifièrent',
    'crucifieriez',
    'crucifierons',
    'crucifieront',
    'crucifixions',
    'cryptassions',
    'crypteraient',
    'cryptogramme',
    'cueillerions',
    'cueillissent',
    'cueillissiez',
    'cuirassaient',
    'cuirassasses',
    'cuirasserais',
    'cuirasserait',
    'cuirassèrent',
    'cuirasseriez',
    'cuirasserons',
    'cuirasseront',
    'cuisinassent',
    'cuisinassiez',
    'cuisinerions',
    'cuivrassions',
    'cuivreraient',
    'culbutassent',
    'culbutassiez',
    'culbuterions',
    'cul-de-jatte',
    'culminassent',
    'culminassiez',
    'culminations',
    'culminerions',
    'culottassent',
    'culottassiez',
    'culotterions',
    'culpabilisai',
    'culpabilisas',
    'culpabilisât',
    'culpabilisée',
    'culpabiliser',
    'culpabilises',
    'culpabilisés',
    'culpabilisez',
    'culpabilités',
    'cultivassent',
    'cultivassiez',
    'cultivateurs',
    'cultivatrice',
    'cultiverions',
    'culturalisme',
    'cumulassions',
    'cumulatrices',
    'cumuleraient',
    'cunicultures',
    'cunnilinctus',
    'curarisantes',
    'curarisation',
    'cure-oreille',
    'curetassions',
    'curetterions',
    'curieusement',
    'cuvelassions',
    'cuvellerions',
    'cyanhydrique',
    'cyanosassent',
    'cyanosassiez',
    'cyanoserions',
    'cyanurassent',
    'cyanurassiez',
    'cyanurations',
    'cyanurerions',
    'cybernétique',
    'cyclisassent',
    'cyclisassiez',
    'cycliserions',
    'cyclomoteurs',
    'cyclopéennes',
    'cyclorameurs',
    'cyclothymies',
    'cylindraient',
    'cylindrasses',
    'cylindrerais',
    'cylindrerait',
    'cylindrèrent',
    'cylindreriez',
    'cylindrerons',
    'cylindreront',
    'cylindreuses',
    'cylindriques',
    'cynégétiques',
    'cynocéphales',
    'cystectomies',
    'cysticerques',
    'cystographie',
    'cystoscopies',
    'cytologiques',
    'cytologistes',
    'dactylologie',
    'daignassions',
    'daigneraient',
    'daltoniennes',
    'damasquinage',
    'damasquinais',
    'damasquinait',
    'damasquinant',
    'damasquinées',
    'damasquinent',
    'damasquinera',
    'damasquineur',
    'damasquiniez',
    'damasquinons',
    'damassassent',
    'damassassiez',
    'damasserions',
    'dandinassent',
    'dandinassiez',
    'dandinements',
    'dandinerions',
    'dangerosités',
    'dansottaient',
    'dansottasses',
    'dansotterais',
    'dansotterait',
    'dansottèrent',
    'dansotteriez',
    'dansotterons',
    'dansotteront',
    'darwiniennes',
    'dauphinelles',
    'dauphinoises',
    'déactivaient',
    'déactivasses',
    'déactiverais',
    'déactiverait',
    'déactivèrent',
    'déactiveriez',
    'déactiverons',
    'déactiveront',
    'déambulaient',
    'déambulasses',
    'déambulation',
    'déambulerais',
    'déambulerait',
    'déambulèrent',
    'déambuleriez',
    'déambulerons',
    'déambuleront',
    'débâchassent',
    'débâchassiez',
    'débâcherions',
    'débâclassent',
    'débâclassiez',
    'débâclerions',
    'débagoulâmes',
    'débagoulasse',
    'débagoulâtes',
    'débagoulerai',
    'débagouleras',
    'débagoulerez',
    'débagoulions',
    'débâillonnai',
    'débâillonnas',
    'débâillonnât',
    'débâillonnée',
    'débâillonner',
    'débâillonnes',
    'débâillonnés',
    'débâillonnez',
    'déballassent',
    'déballassiez',
    'déballastage',
    'déballerions',
    'déballonnais',
    'déballonnait',
    'déballonnant',
    'déballonnées',
    'déballonnent',
    'déballonnera',
    'déballonniez',
    'déballonnons',
    'débalourdais',
    'débalourdait',
    'débalourdant',
    'débalourdées',
    'débalourdent',
    'débalourdera',
    'débalourdiez',
    'débalourdons',
    'débanalisais',
    'débanalisait',
    'débanalisant',
    'débanalisées',
    'débanalisent',
    'débanalisera',
    'débanalisiez',
    'débanalisons',
    'débandassent',
    'débandassiez',
    'débanderions',
    'débaptisâmes',
    'débaptisasse',
    'débaptisâtes',
    'débaptiserai',
    'débaptiseras',
    'débaptiserez',
    'débaptisions',
    'débarbouilla',
    'débarbouille',
    'débarbouillé',
    'débarcadères',
    'débardassent',
    'débardassiez',
    'débarderions',
    'débarquaient',
    'débarquasses',
    'débarquement',
    'débarquerais',
    'débarquerait',
    'débarquèrent',
    'débarqueriez',
    'débarquerons',
    'débarqueront',
    'débarrassais',
    'débarrassait',
    'débarrassant',
    'débarrassées',
    'débarrassent',
    'débarrassera',
    'débarrassiez',
    'débarrassons',
    'débarrerions',
    'débâtassions',
    'débâteraient',
    'débâtiraient',
    'débâtissions',
    'débattements',
    'débattissent',
    'débattissiez',
    'débattraient',
    'débauchaient',
    'débauchasses',
    'débaucherais',
    'débaucherait',
    'débauchèrent',
    'débaucheriez',
    'débaucherons',
    'débaucheront',
    'débecquetais',
    'débecquetait',
    'débecquetant',
    'débecquetées',
    'débecquetiez',
    'débecquetons',
    'débecquettes',
    'débectassent',
    'débectassiez',
    'débecterions',
    'débenzolages',
    'débenzolâmes',
    'débenzolasse',
    'débenzolâtes',
    'débenzolerai',
    'débenzoleras',
    'débenzolerez',
    'débenzolions',
    'débilitaient',
    'débilitantes',
    'débilitasses',
    'débiliterais',
    'débiliterait',
    'débilitèrent',
    'débiliteriez',
    'débiliterons',
    'débiliteront',
    'débillardais',
    'débillardait',
    'débillardant',
    'débillardées',
    'débillardent',
    'débillardera',
    'débillardiez',
    'débillardons',
    'débinassions',
    'débineraient',
    'débitassions',
    'débiteraient',
    'déblaiements',
    'déblaierions',
    'déblatérâmes',
    'déblatérasse',
    'déblatérâtes',
    'déblatérerai',
    'déblatéreras',
    'déblatérerez',
    'déblatérions',
    'déblayassent',
    'déblayassiez',
    'déblayerions',
    'débleuirions',
    'débleuissais',
    'débleuissait',
    'débleuissant',
    'débleuissent',
    'débleuissiez',
    'débleuissons',
    'débloquaient',
    'débloquasses',
    'débloquerais',
    'débloquerait',
    'débloquèrent',
    'débloqueriez',
    'débloquerons',
    'débloqueront',
    'débobinaient',
    'débobinasses',
    'débobinerais',
    'débobinerait',
    'débobinèrent',
    'débobineriez',
    'débobinerons',
    'débobineront',
    'déboguassent',
    'déboguassiez',
    'déboguerions',
    'déboisassent',
    'déboisassiez',
    'déboisements',
    'déboiserions',
    'déboîtassent',
    'déboîtassiez',
    'déboîtements',
    'déboîterions',
    'débondassent',
    'débondassiez',
    'débonderions',
    'débonnaireté',
    'débordassent',
    'débordassiez',
    'débordements',
    'déborderions',
    'débosselâmes',
    'débosselasse',
    'débosselâtes',
    'débosselions',
    'débossellent',
    'débossellera',
    'débottassent',
    'débottassiez',
    'débotterions',
    'débouchaient',
    'débouchasses',
    'débouchement',
    'déboucherais',
    'déboucherait',
    'débouchèrent',
    'déboucheriez',
    'déboucherons',
    'déboucheront',
    'débouclaient',
    'débouclasses',
    'débouclerais',
    'débouclerait',
    'débouclèrent',
    'déboucleriez',
    'débouclerons',
    'déboucleront',
    'déboudassent',
    'déboudassiez',
    'débouderions',
    'débouillîmes',
    'débouillions',
    'débouillirai',
    'débouilliras',
    'débouillirez',
    'débouillisse',
    'débouillîtes',
    'déboulassent',
    'déboulassiez',
    'déboulerions',
    'déboulonnage',
    'déboulonnais',
    'déboulonnait',
    'déboulonnant',
    'déboulonnées',
    'déboulonnent',
    'déboulonnera',
    'déboulonniez',
    'déboulonnons',
    'débouquaient',
    'débouquasses',
    'débouquement',
    'débouquerais',
    'débouquerait',
    'débouquèrent',
    'débouqueriez',
    'débouquerons',
    'débouqueront',
    'débourbaient',
    'débourbasses',
    'débourberais',
    'débourberait',
    'débourbèrent',
    'débourberiez',
    'débourberons',
    'débourberont',
    'débourgeoisé',
    'débourraient',
    'débourrasses',
    'débourrement',
    'débourrerais',
    'débourrerait',
    'débourrèrent',
    'débourreriez',
    'débourrerons',
    'débourreront',
    'déboursaient',
    'déboursasses',
    'déboursement',
    'débourserais',
    'débourserait',
    'déboursèrent',
    'débourseriez',
    'débourserons',
    'débourseront',
    'déboussolais',
    'déboussolait',
    'déboussolant',
    'déboussolées',
    'déboussolent',
    'déboussolera',
    'déboussoliez',
    'déboussolons',
    'déboutassent',
    'déboutassiez',
    'déboutements',
    'débouterions',
    'déboutonnage',
    'déboutonnais',
    'déboutonnait',
    'déboutonnant',
    'déboutonnées',
    'déboutonnent',
    'déboutonnera',
    'déboutonniez',
    'déboutonnons',
    'débraguettai',
    'débraguettas',
    'débraguettât',
    'débraguettée',
    'débraguetter',
    'débraguettes',
    'débraguettés',
    'débraguettez',
    'débraierions',
    'débraillâmes',
    'débraillasse',
    'débraillâtes',
    'débraillerai',
    'débrailleras',
    'débraillerez',
    'débraillions',
    'débranchâmes',
    'débranchasse',
    'débranchâtes',
    'débrancherai',
    'débrancheras',
    'débrancherez',
    'débranchions',
    'débrayassent',
    'débrayassiez',
    'débrayerions',
    'débridassent',
    'débridassiez',
    'débridements',
    'débriderions',
    'débrochaient',
    'débrochasses',
    'débrocherais',
    'débrocherait',
    'débrochèrent',
    'débrocheriez',
    'débrocherons',
    'débrocheront',
    'débrouillage',
    'débrouillais',
    'débrouillait',
    'débrouillant',
    'débrouillard',
    'débrouillées',
    'débrouillent',
    'débrouillera',
    'débrouilliez',
    'débrouillons',
    'débuchassent',
    'débuchassiez',
    'débucherions',
    'débudgétisai',
    'débudgétisas',
    'débudgétisât',
    'débudgétisée',
    'débudgétiser',
    'débudgétises',
    'débudgétisés',
    'débudgétisez',
    'débullassent',
    'débullassiez',
    'débullerions',
    'débusquaient',
    'débusquasses',
    'débusquement',
    'débusquerais',
    'débusquerait',
    'débusquèrent',
    'débusqueriez',
    'débusquerons',
    'débusqueront',
    'débutassions',
    'débuteraient',
    'décachetâmes',
    'décachetasse',
    'décachetâtes',
    'décachetions',
    'décachettent',
    'décachettera',
    'décadenassai',
    'décadenassas',
    'décadenassât',
    'décadenassée',
    'décadenasser',
    'décadenasses',
    'décadenassés',
    'décadenassez',
    'décaféinâmes',
    'décaféinasse',
    'décaféinâtes',
    'décaféinerai',
    'décaféineras',
    'décaféinerez',
    'décaféinions',
    'décaissaient',
    'décaissasses',
    'décaissement',
    'décaisserais',
    'décaisserait',
    'décaissèrent',
    'décaisseriez',
    'décaisserons',
    'décaisseront',
    'décalaminage',
    'décalaminais',
    'décalaminait',
    'décalaminant',
    'décalaminées',
    'décalaminent',
    'décalaminera',
    'décalaminiez',
    'décalaminons',
    'décalassions',
    'décalcifiais',
    'décalcifiait',
    'décalcifiant',
    'décalcifiées',
    'décalcifient',
    'décalcifiera',
    'décalcifiiez',
    'décalcifions',
    'décalcomanie',
    'décaleraient',
    'décalottâmes',
    'décalottasse',
    'décalottâtes',
    'décalotterai',
    'décalotteras',
    'décalotterez',
    'décalottions',
    'décalquaient',
    'décalquasses',
    'décalquerais',
    'décalquerait',
    'décalquèrent',
    'décalqueriez',
    'décalquerons',
    'décalqueront',
    'décampassent',
    'décampassiez',
    'décamperions',
    'décanillâmes',
    'décanillasse',
    'décanillâtes',
    'décanillerai',
    'décanilleras',
    'décanillerez',
    'décanillions',
    'décantassent',
    'décantassiez',
    'décantations',
    'décanterions',
    'décapassions',
    'décapelaient',
    'décapelasses',
    'décapelèrent',
    'décapellerai',
    'décapelleras',
    'décapellerez',
    'décaperaient',
    'décapitaient',
    'décapitalisa',
    'décapitalise',
    'décapitalisé',
    'décapitasses',
    'décapitation',
    'décapiterais',
    'décapiterait',
    'décapitèrent',
    'décapiteriez',
    'décapiterons',
    'décapiteront',
    'décapotables',
    'décapotaient',
    'décapotasses',
    'décapoterais',
    'décapoterait',
    'décapotèrent',
    'décapoteriez',
    'décapoterons',
    'décapoteront',
    'décapsulages',
    'décapsulâmes',
    'décapsulasse',
    'décapsulâtes',
    'décapsulerai',
    'décapsuleras',
    'décapsulerez',
    'décapsuleurs',
    'décapsulions',
    'décapuchonna',
    'décapuchonne',
    'décapuchonné',
    'décarbonatai',
    'décarbonatas',
    'décarbonatât',
    'décarbonatée',
    'décarbonater',
    'décarbonates',
    'décarbonatés',
    'décarbonatez',
    'décarburâmes',
    'décarburasse',
    'décarburâtes',
    'décarburerai',
    'décarbureras',
    'décarburerez',
    'décarburions',
    'décarcassais',
    'décarcassait',
    'décarcassant',
    'décarcassées',
    'décarcassent',
    'décarcassera',
    'décarcassiez',
    'décarcassons',
    'décarrassent',
    'décarrassiez',
    'décarrelâmes',
    'décarrelasse',
    'décarrelâtes',
    'décarrelions',
    'décarrellent',
    'décarrellera',
    'décarrerions',
    'décartonnais',
    'décartonnait',
    'décartonnant',
    'décartonnées',
    'décartonnent',
    'décartonnera',
    'décartonniez',
    'décartonnons',
    'décasyllabes',
    'décathlonien',
    'décatiraient',
    'décatissions',
    'décausassent',
    'décausassiez',
    'décauserions',
    'décédassions',
    'décéderaient',
    'décelassions',
    'décéléraient',
    'décèleraient',
    'décélérasses',
    'décélération',
    'décélérerais',
    'décélérerait',
    'décélérèrent',
    'décéléreriez',
    'décélérerons',
    'décéléreront',
    'décemvirales',
    'décentraient',
    'décentralisa',
    'décentralise',
    'décentralisé',
    'décentrasses',
    'décentrerais',
    'décentrerait',
    'décentrèrent',
    'décentreriez',
    'décentrerons',
    'décentreront',
    'décerclaient',
    'décerclasses',
    'décerclerais',
    'décerclerait',
    'décerclèrent',
    'décercleriez',
    'décerclerons',
    'décercleront',
    'décernassent',
    'décernassiez',
    'décernerions',
    'décervelages',
    'décessassent',
    'décessassiez',
    'décesserions',
    'déchagrinais',
    'déchagrinait',
    'déchagrinant',
    'déchagrinées',
    'déchagrinent',
    'déchagrinera',
    'déchagriniez',
    'déchagrinons',
    'déchaînaient',
    'déchaînasses',
    'déchaînement',
    'déchaînerais',
    'déchaînerait',
    'déchaînèrent',
    'déchaîneriez',
    'déchaînerons',
    'déchaîneront',
    'déchantaient',
    'déchantasses',
    'déchanterais',
    'déchanterait',
    'déchantèrent',
    'déchanteriez',
    'déchanterons',
    'déchanteront',
    'déchapassent',
    'déchapassiez',
    'déchaperions',
    'déchaperonna',
    'déchaperonne',
    'déchaperonné',
    'déchargeâmes',
    'déchargeasse',
    'déchargeâtes',
    'déchargement',
    'déchargeoirs',
    'déchargerais',
    'déchargerait',
    'déchargèrent',
    'déchargeriez',
    'déchargerons',
    'déchargeront',
    'décharnaient',
    'décharnasses',
    'décharnerais',
    'décharnerait',
    'décharnèrent',
    'décharneriez',
    'décharnerons',
    'décharneront',
    'déchaumaient',
    'déchaumasses',
    'déchaumerais',
    'déchaumerait',
    'déchaumèrent',
    'déchaumeriez',
    'déchaumerons',
    'déchaumeront',
    'déchaumeuses',
    'déchaussages',
    'déchaussâmes',
    'déchaussasse',
    'déchaussâtes',
    'déchausserai',
    'déchausseras',
    'déchausserez',
    'déchaussions',
    'déchevêtrais',
    'déchevêtrait',
    'déchevêtrant',
    'déchevêtrées',
    'déchevêtrent',
    'déchevêtrera',
    'déchevêtriez',
    'déchevêtrons',
    'déchevillais',
    'déchevillait',
    'déchevillant',
    'déchevillées',
    'déchevillent',
    'déchevillera',
    'déchevilliez',
    'déchevillons',
    'déchiffonnai',
    'déchiffonnas',
    'déchiffonnât',
    'déchiffonnée',
    'déchiffonner',
    'déchiffonnes',
    'déchiffonnés',
    'déchiffonnez',
    'déchiffrable',
    'déchiffrages',
    'déchiffrâmes',
    'déchiffrasse',
    'déchiffrâtes',
    'déchiffrerai',
    'déchiffreras',
    'déchiffrerez',
    'déchiffrions',
    'déchiquetage',
    'déchiquetais',
    'déchiquetait',
    'déchiquetant',
    'déchiquetées',
    'déchiqueteur',
    'déchiquetiez',
    'déchiquetons',
    'déchiquettes',
    'déchiqueture',
    'déchirassent',
    'déchirassiez',
    'déchirements',
    'déchirerions',
    'déchlorurais',
    'déchlorurait',
    'déchlorurant',
    'déchlorurées',
    'déchlorurent',
    'déchlorurera',
    'déchloruriez',
    'déchlorurons',
    'déchoiraient',
    'déchromaient',
    'déchromasses',
    'déchromerais',
    'déchromerait',
    'déchromèrent',
    'déchromeriez',
    'déchromerons',
    'déchromeront',
    'décidabilité',
    'décidassions',
    'décideraient',
    'décimalisais',
    'décimalisait',
    'décimalisant',
    'décimalisées',
    'décimalisent',
    'décimalisera',
    'décimalisiez',
    'décimalisons',
    'décimassions',
    'décimeraient',
    'décimétrique',
    'décintraient',
    'décintrasses',
    'décintrement',
    'décintrèrent',
    'décisionnels',
    'décisivement',
    'déclamassent',
    'déclamassiez',
    'déclamateurs',
    'déclamations',
    'déclamatoire',
    'déclamatrice',
    'déclamerions',
    'déclarassent',
    'déclarassiez',
    'déclarations',
    'déclaratives',
    'déclaratoire',
    'déclarerions',
    'déclassaient',
    'déclassasses',
    'déclassement',
    'déclasserais',
    'déclasserait',
    'déclassèrent',
    'déclasseriez',
    'déclasserons',
    'déclasseront',
    'déclavetâmes',
    'déclavetasse',
    'déclavetâtes',
    'déclavetions',
    'déclavettent',
    'déclavettera',
    'déclenchable',
    'déclenchâmes',
    'déclenchasse',
    'déclenchâtes',
    'déclencherai',
    'déclencheras',
    'déclencherez',
    'déclencheurs',
    'déclenchions',
    'déclinaisons',
    'déclinassent',
    'déclinassiez',
    'déclinerions',
    'décliquetage',
    'décliquetais',
    'décliquetait',
    'décliquetant',
    'décliquetées',
    'décliquetiez',
    'décliquetons',
    'décliquettes',
    'décloisonnai',
    'décloisonnas',
    'décloisonnât',
    'décloisonnée',
    'décloisonner',
    'décloisonnes',
    'décloisonnés',
    'décloisonnez',
    'déclouassent',
    'déclouassiez',
    'déclouerions',
    'décochassent',
    'décochassiez',
    'décocherions',
    'décodassions',
    'décoderaient',
    'décoffraient',
    'décoffrasses',
    'décoffrerais',
    'décoffrerait',
    'décoffrèrent',
    'décoffreriez',
    'décoffrerons',
    'décoffreront',
    'décoiffaient',
    'décoiffasses',
    'décoiffement',
    'décoifferais',
    'décoifferait',
    'décoiffèrent',
    'décoifferiez',
    'décoifferons',
    'décoifferont',
    'décoinçaient',
    'décoinçasses',
    'décoincerais',
    'décoincerait',
    'décoincèrent',
    'décoinceriez',
    'décoincerons',
    'décoinceront',
    'décoléraient',
    'décolérasses',
    'décolérerais',
    'décolérerait',
    'décolérèrent',
    'décoléreriez',
    'décolérerons',
    'décoléreront',
    'décollassent',
    'décollassiez',
    'décollations',
    'décollements',
    'décollerions',
    'décolletages',
    'décolletâmes',
    'décolletasse',
    'décolletâtes',
    'décolleteurs',
    'décolleteuse',
    'décolletions',
    'décollettent',
    'décollettera',
    'décolonisais',
    'décolonisait',
    'décolonisant',
    'décolonisées',
    'décolonisent',
    'décolonisera',
    'décolonisiez',
    'décolonisons',
    'décoloraient',
    'décolorasses',
    'décoloration',
    'décolorerais',
    'décolorerait',
    'décolorèrent',
    'décoloreriez',
    'décolorerons',
    'décoloreront',
    'décommandais',
    'décommandait',
    'décommandant',
    'décommandées',
    'décommandent',
    'décommandera',
    'décommandiez',
    'décommandons',
    'décomplexais',
    'décomplexait',
    'décomplexant',
    'décomplexées',
    'décomplexent',
    'décomplexera',
    'décomplexiez',
    'décomplexons',
    'décomposable',
    'décomposâmes',
    'décomposasse',
    'décomposâtes',
    'décomposerai',
    'décomposeras',
    'décomposerez',
    'décomposions',
    'décompressai',
    'décompressas',
    'décompressât',
    'décompressée',
    'décompresser',
    'décompresses',
    'décompressés',
    'décompressez',
    'décomprimais',
    'décomprimait',
    'décomprimant',
    'décomprimées',
    'décompriment',
    'décomprimera',
    'décomprimiez',
    'décomprimons',
    'décomptaient',
    'décomptasses',
    'décompterais',
    'décompterait',
    'décomptèrent',
    'décompteriez',
    'décompterons',
    'décompteront',
    'déconcentrai',
    'déconcentras',
    'déconcentrât',
    'déconcentrée',
    'déconcentrer',
    'déconcentres',
    'déconcentrés',
    'déconcentrez',
    'déconcertais',
    'déconcertait',
    'déconcertant',
    'déconcertées',
    'déconcertent',
    'déconcertera',
    'déconcertiez',
    'déconcertons',
    'déconfirions',
    'déconfisions',
    'déconfissent',
    'déconfissiez',
    'déconfitures',
    'décongelâmes',
    'décongelasse',
    'décongelâtes',
    'décongèlerai',
    'décongèleras',
    'décongèlerez',
    'décongelions',
    'décongestifs',
    'décongestion',
    'décongestive',
    'déconnassent',
    'déconnassiez',
    'déconnectais',
    'déconnectait',
    'déconnectant',
    'déconnectées',
    'déconnectent',
    'déconnectera',
    'déconnectiez',
    'déconnectons',
    'déconnerions',
    'déconnexions',
    'déconseillai',
    'déconseillas',
    'déconseillât',
    'déconseillée',
    'déconseiller',
    'déconseilles',
    'déconseillés',
    'déconseillez',
    'déconsidérai',
    'déconsidéras',
    'déconsidérât',
    'déconsidérée',
    'déconsidérer',
    'déconsidérés',
    'déconsidères',
    'déconsidérez',
    'déconsignais',
    'déconsignait',
    'déconsignant',
    'déconsignées',
    'déconsignent',
    'déconsignera',
    'déconsigniez',
    'déconsignons',
    'déconstipais',
    'déconstipait',
    'déconstipant',
    'déconstipées',
    'déconstipent',
    'déconstipera',
    'déconstipiez',
    'déconstipons',
    'décontaminai',
    'décontaminas',
    'décontaminât',
    'décontaminée',
    'décontaminer',
    'décontamines',
    'décontaminés',
    'décontaminez',
    'décontenança',
    'décontenance',
    'décontenancé',
    'décontractai',
    'décontractas',
    'décontractât',
    'décontractée',
    'décontracter',
    'décontractes',
    'décontractés',
    'décontractez',
    'décorassions',
    'décoratrices',
    'décordassent',
    'décordassiez',
    'décorderions',
    'décoreraient',
    'décornassent',
    'décornassiez',
    'décornerions',
    'décorticages',
    'décortiquais',
    'décortiquait',
    'décortiquant',
    'décortiquées',
    'décortiquent',
    'décortiquera',
    'décortiquiez',
    'décortiquons',
    'découchaient',
    'découchasses',
    'découcherais',
    'découcherait',
    'découchèrent',
    'découcheriez',
    'découcherons',
    'découcheront',
    'découdraient',
    'découlassent',
    'découlassiez',
    'découlerions',
    'découpassent',
    'découpassiez',
    'découperions',
    'découplaient',
    'découplasses',
    'découplerais',
    'découplerait',
    'découplèrent',
    'découpleriez',
    'découplerons',
    'découpleront',
    'décourageais',
    'décourageait',
    'décourageant',
    'décourageons',
    'découragerai',
    'décourageras',
    'découragerez',
    'découragions',
    'découronnais',
    'découronnait',
    'découronnant',
    'découronnées',
    'découronnent',
    'découronnera',
    'découronniez',
    'découronnons',
    'décousissent',
    'décousissiez',
    'découvraient',
    'découvrement',
    'découvreuses',
    'découvrirais',
    'découvrirait',
    'découvrirent',
    'découvririez',
    'découvrirons',
    'découvriront',
    'découvrisses',
    'décrassaient',
    'décrassasses',
    'décrassement',
    'décrasserais',
    'décrasserait',
    'décrassèrent',
    'décrasseriez',
    'décrasserons',
    'décrasseront',
    'décréditâmes',
    'décréditasse',
    'décréditâtes',
    'décréditerai',
    'décréditeras',
    'décréditerez',
    'décréditions',
    'décrêpassent',
    'décrêpassiez',
    'décrêperions',
    'décrépirions',
    'décrépissage',
    'décrépissais',
    'décrépissait',
    'décrépissant',
    'décrépissent',
    'décrépissiez',
    'décrépissons',
    'décrépitâmes',
    'décrépitasse',
    'décrépitâtes',
    'décrépiterai',
    'décrépiteras',
    'décrépiterez',
    'décrépitions',
    'décrépitudes',
    'décrétassent',
    'décrétassiez',
    'décréterions',
    'décrets-lois',
    'décreusaient',
    'décreusasses',
    'décreuserais',
    'décreuserait',
    'décreusèrent',
    'décreuseriez',
    'décreuserons',
    'décreuseront',
    'décriassions',
    'décrieraient',
    'décrispaient',
    'décrispasses',
    'décrispation',
    'décrisperais',
    'décrisperait',
    'décrispèrent',
    'décrisperiez',
    'décrisperons',
    'décrisperont',
    'décrivissent',
    'décrivissiez',
    'décrochaient',
    'décrochasses',
    'décrochement',
    'décrocherais',
    'décrocherait',
    'décrochèrent',
    'décrocheriez',
    'décrocherons',
    'décrocheront',
    'décroisaient',
    'décroisasses',
    'décroiserais',
    'décroiserait',
    'décroisèrent',
    'décroiseriez',
    'décroiserons',
    'décroiseront',
    'décroissance',
    'décroissante',
    'décroissants',
    'décroissions',
    'décroîtrions',
    'décrottaient',
    'décrottasses',
    'décrotterais',
    'décrotterait',
    'décrottèrent',
    'décrotteriez',
    'décrotterons',
    'décrotteront',
    'décroûtaient',
    'décroûtasses',
    'décroûterais',
    'décroûterait',
    'décroûtèrent',
    'décroûteriez',
    'décroûterons',
    'décroûteront',
    'décruassions',
    'décrueraient',
    'décrusassent',
    'décrusassiez',
    'décruserions',
    'décryptaient',
    'décryptasses',
    'décrypterais',
    'décrypterait',
    'décryptèrent',
    'décrypteriez',
    'décrypterons',
    'décrypteront',
    'décuivraient',
    'décuivrasses',
    'décuivrerais',
    'décuivrerait',
    'décuivrèrent',
    'décuivreriez',
    'décuivrerons',
    'décuivreront',
    'déculassâmes',
    'déculassasse',
    'déculassâtes',
    'déculasserai',
    'déculasseras',
    'déculasserez',
    'déculassions',
    'déculottâmes',
    'déculottasse',
    'déculottâtes',
    'déculotterai',
    'déculotteras',
    'déculotterez',
    'déculottions',
    'décuplassent',
    'décuplassiez',
    'décuplements',
    'décuplerions',
    'décuscuteuse',
    'décuvassions',
    'décuveraient',
    'dédaignables',
    'dédaignaient',
    'dédaignasses',
    'dédaignerais',
    'dédaignerait',
    'dédaignèrent',
    'dédaigneriez',
    'dédaignerons',
    'dédaigneront',
    'dédaigneuses',
    'dédicaçaient',
    'dédicaçasses',
    'dédicacerais',
    'dédicacerait',
    'dédicacèrent',
    'dédicaceriez',
    'dédicacerons',
    'dédicaceront',
    'dédicataires',
    'dédicatoires',
    'dédommageais',
    'dédommageait',
    'dédommageant',
    'dédommageons',
    'dédommagerai',
    'dédommageras',
    'dédommagerez',
    'dédommagions',
    'dédorassions',
    'dédoreraient',
    'dédouanaient',
    'dédouanasses',
    'dédouanement',
    'dédouanerais',
    'dédouanerait',
    'dédouanèrent',
    'dédouaneriez',
    'dédouanerons',
    'dédouaneront',
    'dédoublaient',
    'dédoublasses',
    'dédoublement',
    'dédoublerais',
    'dédoublerait',
    'dédoublèrent',
    'dédoubleriez',
    'dédoublerons',
    'dédoubleront',
    'dédramatisai',
    'dédramatisas',
    'dédramatisât',
    'dédramatisée',
    'dédramatiser',
    'dédramatises',
    'dédramatisés',
    'dédramatisez',
    'déduisissent',
    'déduisissiez',
    'défaillaient',
    'défaillances',
    'défaillantes',
    'défaillirais',
    'défaillirait',
    'défaillirent',
    'défailliriez',
    'défaillirons',
    'défailliront',
    'défaillisses',
    'défalcations',
    'défalquaient',
    'défalquasses',
    'défalquerais',
    'défalquerait',
    'défalquèrent',
    'défalqueriez',
    'défalquerons',
    'défalqueront',
    'défardassent',
    'défardassiez',
    'défarderions',
    'défatiguâmes',
    'défatiguasse',
    'défatiguâtes',
    'défatiguerai',
    'défatigueras',
    'défatiguerez',
    'défatiguions',
    'défaufilâmes',
    'défaufilasse',
    'défaufilâtes',
    'défaufilerai',
    'défaufileras',
    'défaufilerez',
    'défaufilions',
    'défaussaient',
    'défaussasses',
    'défausserais',
    'défausserait',
    'défaussèrent',
    'défausseriez',
    'défausserons',
    'défausseront',
    'défavorables',
    'défavorisais',
    'défavorisait',
    'défavorisant',
    'défavorisées',
    'défavorisent',
    'défavorisera',
    'défavorisiez',
    'défavorisons',
    'défectueuses',
    'défectuosité',
    'défenderesse',
    'défendissent',
    'défendissiez',
    'défendraient',
    'déféquassent',
    'déféquassiez',
    'déféquerions',
    'déférassions',
    'déféreraient',
    'déferlassent',
    'déferlassiez',
    'déferlements',
    'déferlerions',
    'déferrassent',
    'déferrassiez',
    'déferrements',
    'déferrerions',
    'déferrisâmes',
    'déferrisasse',
    'déferrisâtes',
    'déferriserai',
    'déferriseras',
    'déferriserez',
    'déferrisions',
    'défeuillâmes',
    'défeuillasse',
    'défeuillâtes',
    'défeuillerai',
    'défeuilleras',
    'défeuillerez',
    'défeuillions',
    'défeutraient',
    'défeutrasses',
    'défeutrerais',
    'défeutrerait',
    'défeutrèrent',
    'défeutreriez',
    'défeutrerons',
    'défeutreront',
    'défibrassent',
    'défibrassiez',
    'défibrerions',
    'déficelaient',
    'déficelasses',
    'déficelèrent',
    'déficellerai',
    'déficelleras',
    'déficellerez',
    'défichassent',
    'défichassiez',
    'déficherions',
    'déficitaires',
    'défigeassent',
    'défigeassiez',
    'défigeraient',
    'défiguraient',
    'défigurasses',
    'défiguration',
    'défigurement',
    'défigurerais',
    'défigurerait',
    'défigurèrent',
    'défigureriez',
    'défigurerons',
    'défigureront',
    'défilassions',
    'défileraient',
    'définiraient',
    'définissable',
    'définissante',
    'définissants',
    'définissions',
    'définitoires',
    'déflagraient',
    'déflagrantes',
    'déflagrasses',
    'déflagrateur',
    'déflagration',
    'déflagrerais',
    'déflagrerait',
    'déflagrèrent',
    'déflagreriez',
    'déflagrerons',
    'déflagreront',
    'déflaquaient',
    'déflaquasses',
    'déflaquerais',
    'déflaquerait',
    'déflaquèrent',
    'déflaqueriez',
    'déflaquerons',
    'déflaqueront',
    'défleuraison',
    'défleurirais',
    'défleurirait',
    'défleurirent',
    'défleuririez',
    'défleurirons',
    'défleuriront',
    'défleurisses',
    'défleurissez',
    'déflorassent',
    'déflorassiez',
    'déflorations',
    'déflorerions',
    'défluviation',
    'défoliassent',
    'défoliassiez',
    'défoliations',
    'défolierions',
    'défonçassent',
    'défonçassiez',
    'défoncements',
    'défoncerions',
    'déforçassent',
    'déforçassiez',
    'déforcerions',
    'déformassent',
    'déformassiez',
    'déformateurs',
    'déformations',
    'déformatrice',
    'déformerions',
    'défoulassent',
    'défoulassiez',
    'défoulements',
    'défoulerions',
    'défouraillai',
    'défouraillas',
    'défouraillât',
    'défouraillée',
    'défourailler',
    'défourailles',
    'défouraillés',
    'défouraillez',
    'défournaient',
    'défournasses',
    'défournement',
    'défournerais',
    'défournerait',
    'défournèrent',
    'défourneriez',
    'défournerons',
    'défourneront',
    'défourneuses',
    'défourraient',
    'défourrasses',
    'défourrerais',
    'défourrerait',
    'défourrèrent',
    'défourreriez',
    'défourrerons',
    'défourreront',
    'défraîchîmes',
    'défraîchirai',
    'défraîchiras',
    'défraîchirez',
    'défraîchisse',
    'défraîchîtes',
    'défraierions',
    'défrancisais',
    'défrancisait',
    'défrancisant',
    'défrancisées',
    'défrancisent',
    'défrancisera',
    'défrancisiez',
    'défrancisons',
    'défrayassent',
    'défrayassiez',
    'défrayerions',
    'défrettaient',
    'défrettasses',
    'défretterais',
    'défretterait',
    'défrettèrent',
    'défretteriez',
    'défretterons',
    'défretteront',
    'défrichaient',
    'défrichasses',
    'défrichement',
    'défricherais',
    'défricherait',
    'défrichèrent',
    'défricheriez',
    'défricherons',
    'défricheront',
    'défricheuses',
    'défringuâmes',
    'défringuasse',
    'défringuâtes',
    'défringuerai',
    'défringueras',
    'défringuerez',
    'défringuions',
    'défripassent',
    'défripassiez',
    'défriperions',
    'défrisassent',
    'défrisassiez',
    'défrisements',
    'défriserions',
    'défroissable',
    'défroissâmes',
    'défroissasse',
    'défroissâtes',
    'défroisserai',
    'défroisseras',
    'défroisserez',
    'défroissions',
    'défronçaient',
    'défronçasses',
    'défroncerais',
    'défroncerait',
    'défroncèrent',
    'défronceriez',
    'défroncerons',
    'défronceront',
    'défroquaient',
    'défroquasses',
    'défroquerais',
    'défroquerait',
    'défroquèrent',
    'défroqueriez',
    'défroquerons',
    'défroqueront',
    'défruitaient',
    'défruitasses',
    'défruiterais',
    'défruiterait',
    'défruitèrent',
    'défruiteriez',
    'défruiterons',
    'défruiteront',
    'dégageassent',
    'dégageassiez',
    'dégageraient',
    'dégainassent',
    'dégainassiez',
    'dégainerions',
    'dégalonnâmes',
    'dégalonnasse',
    'dégalonnâtes',
    'dégalonnerai',
    'dégalonneras',
    'dégalonnerez',
    'dégalonnions',
    'dégantassent',
    'dégantassiez',
    'déganterions',
    'dégarnirions',
    'dégarnissais',
    'dégarnissait',
    'dégarnissant',
    'dégarnissent',
    'dégarnissiez',
    'dégarnissons',
    'dégasolinage',
    'dégasolinais',
    'dégasolinait',
    'dégasolinant',
    'dégasolinées',
    'dégasolinent',
    'dégasolinera',
    'dégasoliniez',
    'dégasolinons',
    'dégauchirais',
    'dégauchirait',
    'dégauchirent',
    'dégauchiriez',
    'dégauchirons',
    'dégauchiront',
    'dégauchisses',
    'dégauchissez',
    'dégazassions',
    'dégazeraient',
    'dégazolinage',
    'dégazolinais',
    'dégazolinait',
    'dégazolinant',
    'dégazolinées',
    'dégazolinent',
    'dégazolinera',
    'dégazoliniez',
    'dégazolinons',
    'dégazonnages',
    'dégazonnâmes',
    'dégazonnasse',
    'dégazonnâtes',
    'dégazonnerai',
    'dégazonneras',
    'dégazonnerez',
    'dégazonnions',
    'dégelassions',
    'dégèleraient',
    'dégénéraient',
    'dégénérasses',
    'dégénératifs',
    'dégénérative',
    'dégénérerais',
    'dégénérerait',
    'dégénérèrent',
    'dégénéreriez',
    'dégénérerons',
    'dégénéreront',
    'dégermassent',
    'dégermassiez',
    'dégermerions',
    'dégingandais',
    'dégingandait',
    'dégingandant',
    'dégingandées',
    'dégingandent',
    'dégingandera',
    'dégingandiez',
    'dégingandons',
    'dégîtassions',
    'dégîteraient',
    'dégivrassent',
    'dégivrassiez',
    'dégivrerions',
    'déglaçassent',
    'déglaçassiez',
    'déglacements',
    'déglacerions',
    'déglaciation',
    'déglinguâmes',
    'déglinguasse',
    'déglinguâtes',
    'déglinguerai',
    'déglingueras',
    'déglinguerez',
    'déglinguions',
    'dégluassions',
    'déglueraient',
    'déglutirions',
    'déglutissais',
    'déglutissait',
    'déglutissant',
    'déglutissent',
    'déglutissiez',
    'déglutissons',
    'déglutitions',
    'dégobillâmes',
    'dégobillasse',
    'dégobillâtes',
    'dégobillerai',
    'dégobilleras',
    'dégobillerez',
    'dégobillions',
    'dégoisassent',
    'dégoisassiez',
    'dégoiserions',
    'dégommassent',
    'dégommassiez',
    'dégommerions',
    'dégondassent',
    'dégondassiez',
    'dégonderions',
    'dégonflaient',
    'dégonflasses',
    'dégonflement',
    'dégonflerais',
    'dégonflerait',
    'dégonflèrent',
    'dégonfleriez',
    'dégonflerons',
    'dégonfleront',
    'dégorgeaient',
    'dégorgeasses',
    'dégorgements',
    'dégorgerions',
    'dégotassions',
    'dégoteraient',
    'dégottassent',
    'dégottassiez',
    'dégotterions',
    'dégoudronnai',
    'dégoudronnas',
    'dégoudronnât',
    'dégoudronnée',
    'dégoudronner',
    'dégoudronnes',
    'dégoudronnés',
    'dégoudronnez',
    'dégoulinades',
    'dégoulinâmes',
    'dégoulinasse',
    'dégoulinâtes',
    'dégoulinerai',
    'dégoulineras',
    'dégoulinerez',
    'dégoulinions',
    'dégoupillais',
    'dégoupillait',
    'dégoupillant',
    'dégoupillées',
    'dégoupillent',
    'dégoupillera',
    'dégoupilliez',
    'dégoupillons',
    'dégourdirais',
    'dégourdirait',
    'dégourdirent',
    'dégourdiriez',
    'dégourdirons',
    'dégourdiront',
    'dégourdisses',
    'dégourdissez',
    'dégoûtamment',
    'dégoûtassent',
    'dégoûtassiez',
    'dégoûtations',
    'dégoûterions',
    'dégouttaient',
    'dégouttasses',
    'dégoutterais',
    'dégoutterait',
    'dégouttèrent',
    'dégoutteriez',
    'dégoutterons',
    'dégoutteront',
    'dégradassent',
    'dégradassiez',
    'dégradateurs',
    'dégradations',
    'dégraderions',
    'dégrafassent',
    'dégrafassiez',
    'dégraferions',
    'dégraissages',
    'dégraissâmes',
    'dégraissante',
    'dégraissants',
    'dégraissasse',
    'dégraissâtes',
    'dégraisserai',
    'dégraisseras',
    'dégraisserez',
    'dégraissions',
    'dégravoierai',
    'dégravoieras',
    'dégravoierez',
    'dégravoyâmes',
    'dégravoyasse',
    'dégravoyâtes',
    'dégravoyions',
    'dégréassions',
    'dégréeraient',
    'dégressivité',
    'dégrevassent',
    'dégrevassiez',
    'dégrèvements',
    'dégrèverions',
    'dégringolade',
    'dégringolais',
    'dégringolait',
    'dégringolant',
    'dégringolées',
    'dégringolent',
    'dégringolera',
    'dégringoliez',
    'dégringolons',
    'dégrippaient',
    'dégrippasses',
    'dégripperais',
    'dégripperait',
    'dégrippèrent',
    'dégripperiez',
    'dégripperons',
    'dégripperont',
    'dégrisassent',
    'dégrisassiez',
    'dégrisements',
    'dégriserions',
    'dégrossaient',
    'dégrossasses',
    'dégrosserais',
    'dégrosserait',
    'dégrossèrent',
    'dégrosseriez',
    'dégrosserons',
    'dégrosseront',
    'dégrossirais',
    'dégrossirait',
    'dégrossirent',
    'dégrossiriez',
    'dégrossirons',
    'dégrossiront',
    'dégrossisses',
    'dégrossissez',
    'dégrouillais',
    'dégrouillait',
    'dégrouillant',
    'dégrouillées',
    'dégrouillent',
    'dégrouillera',
    'dégrouilliez',
    'dégrouillons',
    'déguenillées',
    'déguerpirais',
    'déguerpirait',
    'déguerpirent',
    'déguerpiriez',
    'déguerpirons',
    'déguerpiront',
    'déguerpisses',
    'déguerpissez',
    'dégueulaient',
    'dégueulasses',
    'dégueulerais',
    'dégueulerait',
    'dégueulèrent',
    'dégueuleriez',
    'dégueulerons',
    'dégueuleront',
    'déguisassent',
    'déguisassiez',
    'déguisements',
    'déguiserions',
    'dégurgitâmes',
    'dégurgitasse',
    'dégurgitâtes',
    'dégurgiterai',
    'dégurgiteras',
    'dégurgiterez',
    'dégurgitions',
    'dégustassent',
    'dégustassiez',
    'dégustateurs',
    'dégustations',
    'dégustatrice',
    'dégusterions',
    'déhalassions',
    'déhaleraient',
    'déhanchaient',
    'déhanchasses',
    'déhanchement',
    'déhancherais',
    'déhancherait',
    'déhanchèrent',
    'déhancheriez',
    'déhancherons',
    'déhancheront',
    'déhardassent',
    'déhardassiez',
    'déharderions',
    'déharnachais',
    'déharnachait',
    'déharnachant',
    'déharnachées',
    'déharnachent',
    'déharnachera',
    'déharnachiez',
    'déharnachons',
    'déhottassent',
    'déhottassiez',
    'déhotterions',
    'déhouillâmes',
    'déhouillasse',
    'déhouillâtes',
    'déhouillerai',
    'déhouilleras',
    'déhouillerez',
    'déhouillions',
    'déifiassions',
    'déifications',
    'déifieraient',
    'déjantassent',
    'déjantassiez',
    'déjanterions',
    'déjaugeaient',
    'déjaugeasses',
    'déjaugerions',
    'déjaunirions',
    'déjaunissais',
    'déjaunissait',
    'déjaunissant',
    'déjaunissent',
    'déjaunissiez',
    'déjaunissons',
    'déjetassions',
    'déjetterions',
    'déjeunassent',
    'déjeunassiez',
    'déjeunerions',
    'déjouassions',
    'déjoueraient',
    'déjuchassent',
    'déjuchassiez',
    'déjucherions',
    'déjugeassent',
    'déjugeassiez',
    'déjugeraient',
    'délabialisai',
    'délabialisas',
    'délabialisât',
    'délabialisée',
    'délabialiser',
    'délabialises',
    'délabialisés',
    'délabialisez',
    'délabrassent',
    'délabrassiez',
    'délabrements',
    'délabrerions',
    'délaçassions',
    'délaceraient',
    'délaieraient',
    'délainassent',
    'délainassiez',
    'délainerions',
    'délaissaient',
    'délaissasses',
    'délaissement',
    'délaisserais',
    'délaisserait',
    'délaissèrent',
    'délaisseriez',
    'délaisserons',
    'délaisseront',
    'délaitassent',
    'délaitassiez',
    'délaiterions',
    'délardassent',
    'délardassiez',
    'délarderions',
    'délassassent',
    'délassassiez',
    'délassements',
    'délasserions',
    'délattassent',
    'délattassiez',
    'délatterions',
    'délavassions',
    'délaveraient',
    'délayassions',
    'délayeraient',
    'délectassent',
    'délectassiez',
    'délectations',
    'délecterions',
    'délégataires',
    'déléguassent',
    'déléguassiez',
    'déléguerions',
    'délestassent',
    'délestassiez',
    'délesterions',
    'délibéraient',
    'délibérasses',
    'délibératifs',
    'délibération',
    'délibérative',
    'délibérément',
    'délibérerais',
    'délibérerait',
    'délibérèrent',
    'délibéreriez',
    'délibérerons',
    'délibéreront',
    'délicatement',
    'délicatesses',
    'délictueuses',
    'délignifiais',
    'délignifiait',
    'délignifiant',
    'délignifiées',
    'délignifient',
    'délignifiera',
    'délignifiiez',
    'délignifions',
    'délimitaient',
    'délimitasses',
    'délimitation',
    'délimiterais',
    'délimiterait',
    'délimitèrent',
    'délimiteriez',
    'délimiterons',
    'délimiteront',
    'délinéaments',
    'délinéassent',
    'délinéassiez',
    'délinéerions',
    'délinquances',
    'délinquantes',
    'déliquescent',
    'délirassions',
    'délireraient',
    'délissassent',
    'délissassiez',
    'délisserions',
    'délitassions',
    'déliteraient',
    'délitescence',
    'délitescente',
    'délitescents',
    'délivrassent',
    'délivrassiez',
    'délivrerions',
    'délogeassent',
    'délogeassiez',
    'délogeraient',
    'déloquassent',
    'déloquassiez',
    'déloquerions',
    'délovassions',
    'déloveraient',
    'déloyalement',
    'deltoïdienne',
    'délurassions',
    'délureraient',
    'délustraient',
    'délustrasses',
    'délustrerais',
    'délustrerait',
    'délustrèrent',
    'délustreriez',
    'délustrerons',
    'délustreront',
    'délutassions',
    'déluteraient',
    'démaçonnâmes',
    'démaçonnasse',
    'démaçonnâtes',
    'démaçonnerai',
    'démaçonneras',
    'démaçonnerez',
    'démaçonnions',
    'démagnétisai',
    'démagnétisas',
    'démagnétisât',
    'démagnétisée',
    'démagnétiser',
    'démagnétises',
    'démagnétisés',
    'démagnétisez',
    'démagogiques',
    'démaigrirais',
    'démaigrirait',
    'démaigrirent',
    'démaigririez',
    'démaigrirons',
    'démaigriront',
    'démaigrisses',
    'démaigrissez',
    'démaillaient',
    'démaillasses',
    'démaillerais',
    'démaillerait',
    'démaillèrent',
    'démailleriez',
    'démaillerons',
    'démailleront',
    'démaillotais',
    'démaillotait',
    'démaillotant',
    'démaillotées',
    'démaillotent',
    'démaillotera',
    'démaillotiez',
    'démaillotons',
    'démanchaient',
    'démanchasses',
    'démancherais',
    'démancherait',
    'démanchèrent',
    'démancheriez',
    'démancherons',
    'démancheront',
    'demandassent',
    'demandassiez',
    'demanderions',
    'démangeaient',
    'démangeaison',
    'démangeasses',
    'démangerions',
    'démantelâmes',
    'démantelasse',
    'démantelâtes',
    'démantèlerai',
    'démantèleras',
    'démantèlerez',
    'démantelions',
    'démantibulai',
    'démantibulas',
    'démantibulât',
    'démantibulée',
    'démantibuler',
    'démantibules',
    'démantibulés',
    'démantibulez',
    'démaquillais',
    'démaquillait',
    'démaquillant',
    'démaquillées',
    'démaquillent',
    'démaquillera',
    'démaquilliez',
    'démaquillons',
    'démarcations',
    'démarchaient',
    'démarchasses',
    'démarcherais',
    'démarcherait',
    'démarchèrent',
    'démarcheriez',
    'démarcherons',
    'démarcheront',
    'démarcheuses',
    'démariassent',
    'démariassiez',
    'démarierions',
    'démarquaient',
    'démarquasses',
    'démarquerais',
    'démarquerait',
    'démarquèrent',
    'démarqueriez',
    'démarquerons',
    'démarqueront',
    'démarrassent',
    'démarrassiez',
    'démarrerions',
    'démasclaient',
    'démasclasses',
    'démasclerais',
    'démasclerait',
    'démasclèrent',
    'démascleriez',
    'démasclerons',
    'démascleront',
    'démasquaient',
    'démasquasses',
    'démasquerais',
    'démasquerait',
    'démasquèrent',
    'démasqueriez',
    'démasquerons',
    'démasqueront',
    'démastiquais',
    'démastiquait',
    'démastiquant',
    'démastiquées',
    'démastiquent',
    'démastiquera',
    'démastiquiez',
    'démastiquons',
    'démâtassions',
    'démâteraient',
    'démazoutâmes',
    'démazoutasse',
    'démazoutâtes',
    'démazouterai',
    'démazouteras',
    'démazouterez',
    'démazoutions',
    'démêlassions',
    'démêleraient',
    'démembraient',
    'démembrasses',
    'démembrement',
    'démembrerais',
    'démembrerait',
    'démembrèrent',
    'démembreriez',
    'démembrerons',
    'démembreront',
    'déménageâmes',
    'déménageasse',
    'déménageâtes',
    'déménagement',
    'déménagerais',
    'déménagerait',
    'déménagèrent',
    'déménageriez',
    'déménagerons',
    'déménageront',
    'démenassions',
    'démèneraient',
    'démentielles',
    'démentirions',
    'démentissent',
    'démentissiez',
    'démerdassent',
    'démerdassiez',
    'démerderions',
    'déméritaient',
    'déméritasses',
    'démériterais',
    'démériterait',
    'déméritèrent',
    'démériteriez',
    'démériterons',
    'démériteront',
    'démesurément',
    'déméthanisai',
    'déméthanisas',
    'déméthanisât',
    'déméthanisée',
    'déméthaniser',
    'déméthanises',
    'déméthanisés',
    'déméthanisez',
    'démettraient',
    'démeublaient',
    'démeublasses',
    'démeublerais',
    'démeublerait',
    'démeublèrent',
    'démeubleriez',
    'démeublerons',
    'démeubleront',
    'demeurassent',
    'demeurassiez',
    'demeurerions',
    'demi-brigade',
    'demi-cercles',
    'demi-colonne',
    'demi-droites',
    'démiellaient',
    'démiellasses',
    'démiellerais',
    'démiellerait',
    'démiellèrent',
    'démielleriez',
    'démiellerons',
    'démielleront',
    'demi-finales',
    'demi-journée',
    'démilitarisa',
    'démilitarise',
    'démilitarisé',
    'demi-mesures',
    'déminassions',
    'démineraient',
    'déminéralisa',
    'déminéralise',
    'déminéralisé',
    'demi-pension',
    'demi-portion',
    'demi-produit',
    'demi-reliure',
    'demi-saisons',
    'demi-sommeil',
    'demi-soupirs',
    'démissionnai',
    'démissionnas',
    'démissionnât',
    'démissionnée',
    'démissionner',
    'démissionnes',
    'démissionnés',
    'démissionnez',
    'demi-teintes',
    'démobilisais',
    'démobilisait',
    'démobilisant',
    'démobilisées',
    'démobilisent',
    'démobilisera',
    'démobilisiez',
    'démobilisons',
    'démocratique',
    'démocratisai',
    'démocratisas',
    'démocratisât',
    'démocratisée',
    'démocratiser',
    'démocratises',
    'démocratisés',
    'démocratisez',
    'démodassions',
    'démoderaient',
    'démodulaient',
    'démodulasses',
    'démodulation',
    'démodulerais',
    'démodulerait',
    'démodulèrent',
    'démoduleriez',
    'démodulerons',
    'démoduleront',
    'démographies',
    'démoliraient',
    'démolissages',
    'démolisseurs',
    'démolisseuse',
    'démolissions',
    'démonétisais',
    'démonétisait',
    'démonétisant',
    'démonétisées',
    'démonétisent',
    'démonétisera',
    'démonétisiez',
    'démonétisons',
    'démonologies',
    'démonomanies',
    'démonstratif',
    'démontassent',
    'démontassiez',
    'démonte-pneu',
    'démonterions',
    'démontrables',
    'démontraient',
    'démontrasses',
    'démontrerais',
    'démontrerait',
    'démontrèrent',
    'démontreriez',
    'démontrerons',
    'démontreront',
    'démoralisais',
    'démoralisait',
    'démoralisant',
    'démoralisées',
    'démoralisent',
    'démoralisera',
    'démoralisiez',
    'démoralisons',
    'démordissent',
    'démordissiez',
    'démordraient',
    'démotivaient',
    'démotivantes',
    'démotivasses',
    'démotiverais',
    'démotiverait',
    'démotivèrent',
    'démotiveriez',
    'démotiverons',
    'démotiveront',
    'démouchetais',
    'démouchetait',
    'démouchetant',
    'démouchetées',
    'démouchetiez',
    'démouchetons',
    'démouchettes',
    'démoulassent',
    'démoulassiez',
    'démoulerions',
    'démouscailla',
    'démouscaille',
    'démouscaillé',
    'démoustiquai',
    'démoustiquas',
    'démoustiquât',
    'démoustiquée',
    'démoustiquer',
    'démoustiques',
    'démoustiqués',
    'démoustiquez',
    'démultipliai',
    'démultiplias',
    'démultipliât',
    'démultipliée',
    'démultiplier',
    'démultiplies',
    'démultipliés',
    'démultipliez',
    'démuniraient',
    'démunissions',
    'démurassions',
    'démureraient',
    'démurgeaient',
    'démurgeasses',
    'démurgerions',
    'démuselaient',
    'démuselasses',
    'démuselèrent',
    'démusellerai',
    'démuselleras',
    'démusellerez',
    'démystifiais',
    'démystifiait',
    'démystifiant',
    'démystifiées',
    'démystifient',
    'démystifiera',
    'démystifiiez',
    'démystifions',
    'démythifiais',
    'démythifiait',
    'démythifiant',
    'démythifiées',
    'démythifient',
    'démythifiera',
    'démythifiiez',
    'démythifions',
    'dénantirions',
    'dénantissais',
    'dénantissait',
    'dénantissant',
    'dénantissent',
    'dénantissiez',
    'dénantissons',
    'dénasalisais',
    'dénasalisait',
    'dénasalisant',
    'dénasalisées',
    'dénasalisent',
    'dénasalisera',
    'dénasalisiez',
    'dénasalisons',
    'dénattassent',
    'dénattassiez',
    'dénatterions',
    'dénaturaient',
    'dénaturalisa',
    'dénaturalise',
    'dénaturalisé',
    'dénaturasses',
    'dénaturation',
    'dénaturerais',
    'dénaturerait',
    'dénaturèrent',
    'dénatureriez',
    'dénaturerons',
    'dénatureront',
    'dénébulisais',
    'dénébulisait',
    'dénébulisaît',
    'dénébulisées',
    'dénébulisent',
    'dénébulisera',
    'dénégatoires',
    'déneigeaient',
    'déneigeasses',
    'déneigements',
    'déneigerions',
    'dénervassent',
    'dénervassiez',
    'dénerverions',
    'déniaisaient',
    'déniaisasses',
    'déniaiserais',
    'déniaiserait',
    'déniaisèrent',
    'déniaiseriez',
    'déniaiserons',
    'déniaiseront',
    'dénichassent',
    'dénichassiez',
    'dénicherions',
    'dénickelâmes',
    'dénickelasse',
    'dénickelâtes',
    'dénickelerai',
    'dénickeleras',
    'dénickelerez',
    'dénickelions',
    'dénicotinisa',
    'dénicotinise',
    'dénicotinisé',
    'dénigrassent',
    'dénigrassiez',
    'dénigrements',
    'dénigrerions',
    'dénitrassent',
    'dénitrassiez',
    'dénitrerions',
    'dénitrifiais',
    'dénitrifiait',
    'dénitrifiant',
    'dénitrifiées',
    'dénitrifient',
    'dénitrifiera',
    'dénitrifiiez',
    'dénitrifions',
    'dénivelaient',
    'dénivelasses',
    'dénivelèrent',
    'dénivellerai',
    'dénivelleras',
    'dénivellerez',
    'dénoieraient',
    'dénombrables',
    'dénombraient',
    'dénombrasses',
    'dénombrement',
    'dénombrerais',
    'dénombrerait',
    'dénombrèrent',
    'dénombreriez',
    'dénombrerons',
    'dénombreront',
    'dénominateur',
    'dénominatifs',
    'dénomination',
    'dénominative',
    'dénommassent',
    'dénommassiez',
    'dénommerions',
    'dénonçassent',
    'dénonçassiez',
    'dénoncerions',
    'dénonciateur',
    'dénonciation',
    'dénotassions',
    'dénoteraient',
    'dénouassions',
    'dénoueraient',
    'dénoyassions',
    'dénoyautâmes',
    'dénoyautasse',
    'dénoyautâtes',
    'dénoyauterai',
    'dénoyauteras',
    'dénoyauterez',
    'dénoyautions',
    'densimétries',
    'dentelassent',
    'dentelassiez',
    'dentellerais',
    'dentellerait',
    'dentelleries',
    'dentelleriez',
    'dentellerons',
    'dentelleront',
    'dentellières',
    'dentisteries',
    'dénucléarisa',
    'dénucléarise',
    'dénucléarisé',
    'dénudassions',
    'dénuderaient',
    'dénutritions',
    'déontologies',
    'dépaillaient',
    'dépaillasses',
    'dépaillerais',
    'dépaillerait',
    'dépaillèrent',
    'dépailleriez',
    'dépaillerons',
    'dépailleront',
    'dépaisselais',
    'dépaisselait',
    'dépaisselant',
    'dépaisselées',
    'dépaisseliez',
    'dépaisselles',
    'dépaisselons',
    'dépalissâmes',
    'dépalissasse',
    'dépalissâtes',
    'dépalisserai',
    'dépalisseras',
    'dépalisserez',
    'dépalissions',
    'dépannassent',
    'dépannassiez',
    'dépannerions',
    'dépaquetages',
    'dépaquetâmes',
    'dépaquetasse',
    'dépaquetâtes',
    'dépaquetions',
    'dépaquettent',
    'dépaquettera',
    'déparaffinai',
    'déparaffinas',
    'déparaffinât',
    'déparaffinée',
    'déparaffiner',
    'déparaffines',
    'déparaffinés',
    'déparaffinez',
    'déparasitais',
    'déparasitait',
    'déparasitant',
    'déparasitées',
    'déparasitera',
    'déparasitiez',
    'déparasitons',
    'déparassions',
    'dépareillais',
    'dépareillait',
    'dépareillant',
    'dépareillées',
    'dépareillent',
    'dépareillera',
    'dépareilliez',
    'dépareillons',
    'dépareraient',
    'dépariassent',
    'dépariassiez',
    'déparierions',
    'déparlassent',
    'déparlassiez',
    'déparlerions',
    'départageais',
    'départageait',
    'départageant',
    'départageons',
    'départagerai',
    'départageras',
    'départagerez',
    'départagions',
    'départements',
    'départirions',
    'départissent',
    'départissiez',
    'dépassassent',
    'dépassassiez',
    'dépassements',
    'dépasserions',
    'dépassionnai',
    'dépassionnas',
    'dépassionnât',
    'dépassionnée',
    'dépassionner',
    'dépassionnes',
    'dépassionnés',
    'dépassionnez',
    'dépatouillai',
    'dépatouillas',
    'dépatouillât',
    'dépatouillée',
    'dépatouiller',
    'dépatouilles',
    'dépatouillés',
    'dépatouillez',
    'dépavassions',
    'dépaveraient',
    'dépaysassent',
    'dépaysassiez',
    'dépaysements',
    'dépayserions',
    'dépeçassions',
    'dépèceraient',
    'dépêchassent',
    'dépêchassiez',
    'dépêcherions',
    'dépeignaient',
    'dépeignasses',
    'dépeignerais',
    'dépeignerait',
    'dépeignèrent',
    'dépeigneriez',
    'dépeignerons',
    'dépeigneront',
    'dépeignirent',
    'dépeignisses',
    'dépeindrions',
    'dépelotonnai',
    'dépelotonnas',
    'dépelotonnât',
    'dépelotonnée',
    'dépelotonner',
    'dépelotonnes',
    'dépelotonnés',
    'dépelotonnez',
    'dépenaillées',
    'dépendissent',
    'dépendissiez',
    'dépendraient',
    'dépensassent',
    'dépensassiez',
    'dépenserions',
    'déperditions',
    'dépériraient',
    'dépérissions',
    'dépêtrassent',
    'dépêtrassiez',
    'dépêtrerions',
    'dépeuplaient',
    'dépeuplasses',
    'dépeuplement',
    'dépeuplerais',
    'dépeuplerait',
    'dépeuplèrent',
    'dépeupleriez',
    'dépeuplerons',
    'dépeupleront',
    'déphasassent',
    'déphasassiez',
    'déphaserions',
    'dépiautaient',
    'dépiautasses',
    'dépiauterais',
    'dépiauterait',
    'dépiautèrent',
    'dépiauteriez',
    'dépiauterons',
    'dépiauteront',
    'dépilassions',
    'dépilatoires',
    'dépileraient',
    'dépinglaient',
    'dépinglasses',
    'dépinglerais',
    'dépinglerait',
    'dépinglèrent',
    'dépingleriez',
    'dépinglerons',
    'dépingleront',
    'dépiquassent',
    'dépiquassiez',
    'dépiquerions',
    'dépistassent',
    'dépistassiez',
    'dépisterions',
    'dépitassions',
    'dépiteraient',
    'déplaçassent',
    'déplaçassiez',
    'déplacements',
    'déplacerions',
    'déplafonnais',
    'déplafonnait',
    'déplafonnant',
    'déplafonnées',
    'déplafonnent',
    'déplafonnera',
    'déplafonniez',
    'déplafonnons',
    'déplairaient',
    'déplaisaient',
    'déplaisantes',
    'déplanquâmes',
    'déplanquasse',
    'déplanquâtes',
    'déplanquerai',
    'déplanqueras',
    'déplanquerez',
    'déplanquions',
    'déplantaient',
    'déplantasses',
    'déplantation',
    'déplanterais',
    'déplanterait',
    'déplantèrent',
    'déplanteriez',
    'déplanterons',
    'déplanteront',
    'déplâtraient',
    'déplâtrasses',
    'déplâtrerais',
    'déplâtrerait',
    'déplâtrèrent',
    'déplâtreriez',
    'déplâtrerons',
    'déplâtreront',
    'dépliassions',
    'déplieraient',
    'déplissaient',
    'déplissasses',
    'déplisserais',
    'déplisserait',
    'déplissèrent',
    'déplisseriez',
    'déplisserons',
    'déplisseront',
    'déploiements',
    'déploierions',
    'déplombaient',
    'déplombasses',
    'déplomberais',
    'déplomberait',
    'déplombèrent',
    'déplomberiez',
    'déplomberons',
    'déplomberont',
    'déplorassent',
    'déplorassiez',
    'déplorerions',
    'déployassent',
    'déployassiez',
    'déplumassent',
    'déplumassiez',
    'déplumerions',
    'dépoétisâmes',
    'dépoétisasse',
    'dépoétisâtes',
    'dépoétiserai',
    'dépoétiseras',
    'dépoétiserez',
    'dépoétisions',
    'dépointaient',
    'dépointasses',
    'dépointerais',
    'dépointerait',
    'dépointèrent',
    'dépointeriez',
    'dépointerons',
    'dépointeront',
    'dépoitraillé',
    'dépolarisais',
    'dépolarisait',
    'dépolarisant',
    'dépolarisées',
    'dépolarisent',
    'dépolarisera',
    'dépolarisiez',
    'dépolarisons',
    'dépoliraient',
    'dépolissages',
    'dépolissions',
    'dépolitisais',
    'dépolitisait',
    'dépolitisant',
    'dépolitisées',
    'dépolitisent',
    'dépolitisera',
    'dépolitisiez',
    'dépolitisons',
    'dépolluaient',
    'dépolluasses',
    'dépolluerais',
    'dépolluerait',
    'dépolluèrent',
    'dépollueriez',
    'dépolluerons',
    'dépollueront',
    'dépolymérisa',
    'dépolymérise',
    'dépolymérisé',
    'dépontillais',
    'dépontillait',
    'dépontillant',
    'dépontillent',
    'dépontillera',
    'dépontilliez',
    'dépontillons',
    'dépopulation',
    'déportassent',
    'déportassiez',
    'déportations',
    'déportements',
    'déporterions',
    'déposassions',
    'déposeraient',
    'dépositaires',
    'dépossédâmes',
    'dépossédasse',
    'dépossédâtes',
    'déposséderai',
    'déposséderas',
    'déposséderez',
    'dépossédions',
    'dépossession',
    'dépotassions',
    'dépoteraient',
    'dépoudraient',
    'dépoudrasses',
    'dépoudrerais',
    'dépoudrerait',
    'dépoudrèrent',
    'dépoudreriez',
    'dépoudrerons',
    'dépoudreront',
    'dépouillâmes',
    'dépouillasse',
    'dépouillâtes',
    'dépouillerai',
    'dépouilleras',
    'dépouillerez',
    'dépouillions',
    'dépourvurent',
    'dépoussiérai',
    'dépoussiéras',
    'dépoussiérât',
    'dépoussiérée',
    'dépoussiérer',
    'dépoussiérés',
    'dépoussières',
    'dépoussiérez',
    'dépravassent',
    'dépravassiez',
    'dépravations',
    'dépraverions',
    'déprécations',
    'dépréciaient',
    'dépréciasses',
    'dépréciateur',
    'dépréciatifs',
    'dépréciation',
    'dépréciative',
    'déprécierais',
    'déprécierait',
    'déprécièrent',
    'déprécieriez',
    'déprécierons',
    'déprécieront',
    'déprédateurs',
    'déprédations',
    'déprédatrice',
    'déprendrions',
    'dépressurisa',
    'dépressurise',
    'dépressurisé',
    'déprimassent',
    'déprimassiez',
    'déprimerions',
    'déprisassent',
    'déprisassiez',
    'dépriserions',
    'déprogrammai',
    'déprogrammas',
    'déprogrammât',
    'déprogrammée',
    'déprogrammer',
    'déprogrammes',
    'déprogrammés',
    'déprogrammez',
    'dépropanisai',
    'dépropanisas',
    'dépropanisât',
    'dépropanisée',
    'dépropaniser',
    'dépropanises',
    'dépropanisés',
    'dépropanisez',
    'dépucelaient',
    'dépucelasses',
    'dépucelèrent',
    'dépucellerai',
    'dépucelleras',
    'dépucellerez',
    'dépulpassent',
    'dépulpassiez',
    'dépulperions',
    'dépurassions',
    'dépureraient',
    'députassions',
    'députeraient',
    'déquillaient',
    'déquillasses',
    'déquillerais',
    'déquillerait',
    'déquillèrent',
    'déquilleriez',
    'déquillerons',
    'déquilleront',
    'déracinaient',
    'déracinasses',
    'déracinement',
    'déracinerais',
    'déracinerait',
    'déracinèrent',
    'déracineriez',
    'déracinerons',
    'déracineront',
    'déradassions',
    'déraderaient',
    'dérageassent',
    'dérageassiez',
    'dérageraient',
    'déraidirions',
    'déraidissais',
    'déraidissait',
    'déraidissant',
    'déraidissent',
    'déraidissiez',
    'déraidissons',
    'déraieraient',
    'déraillaient',
    'déraillasses',
    'déraillement',
    'déraillerais',
    'déraillerait',
    'déraillèrent',
    'dérailleriez',
    'déraillerons',
    'dérailleront',
    'déraisonnais',
    'déraisonnait',
    'déraisonnant',
    'déraisonnent',
    'déraisonnera',
    'déraisonniez',
    'déraisonnons',
    'dérangeaient',
    'dérangeantes',
    'dérangeasses',
    'dérangements',
    'dérangerions',
    'dérapassions',
    'déraperaient',
    'dérasassions',
    'déraseraient',
    'dératassions',
    'dérateraient',
    'dératisaient',
    'dératisasses',
    'dératisation',
    'dératiserais',
    'dératiserait',
    'dératisèrent',
    'dératiseriez',
    'dératiserons',
    'dératiseront',
    'dérayassions',
    'dérayeraient',
    'déréalisâmes',
    'déréalisasse',
    'déréalisâtes',
    'déréaliserai',
    'déréaliseras',
    'déréaliserez',
    'déréalisions',
    'déréglassent',
    'déréglassiez',
    'dérèglements',
    'déréglerions',
    'dérégulaient',
    'dérégulasses',
    'dérégulerais',
    'dérégulerait',
    'dérégulèrent',
    'déréguleriez',
    'dérégulerons',
    'déréguleront',
    'dérélictions',
    'déridassions',
    'dérideraient',
    'dérivassions',
    'dériveraient',
    'dermatologie',
    'dermatologue',
    'dermographie',
    'dernièrement',
    'dernière-née',
    'derniers-nés',
    'dérobassions',
    'déroberaient',
    'dérochassent',
    'dérochassiez',
    'dérochements',
    'dérocherions',
    'dérodassions',
    'déroderaient',
    'dérogatoires',
    'dérogeassent',
    'dérogeassiez',
    'dérogeraient',
    'dérouillâmes',
    'dérouillasse',
    'dérouillâtes',
    'dérouillerai',
    'dérouilleras',
    'dérouillerez',
    'dérouillions',
    'déroulassent',
    'déroulassiez',
    'déroulements',
    'déroulerions',
    'déroutassent',
    'déroutassiez',
    'déroutements',
    'dérouterions',
    'désabonnâmes',
    'désabonnasse',
    'désabonnâtes',
    'désabonnerai',
    'désabonneras',
    'désabonnerez',
    'désabonnions',
    'désabusaient',
    'désabusasses',
    'désabusement',
    'désabuserais',
    'désabuserait',
    'désabusèrent',
    'désabuseriez',
    'désabuserons',
    'désabuseront',
    'désacclimata',
    'désacclimate',
    'désacclimaté',
    'désaccordais',
    'désaccordait',
    'désaccordant',
    'désaccordées',
    'désaccordent',
    'désaccordera',
    'désaccordiez',
    'désaccordons',
    'désaccouplai',
    'désaccouplas',
    'désaccouplât',
    'désaccouplée',
    'désaccoupler',
    'désaccouples',
    'désaccouplés',
    'désaccouplez',
    'désaccoutuma',
    'désaccoutume',
    'désaccoutumé',
    'désacralisai',
    'désacralisas',
    'désacralisât',
    'désacralisée',
    'désacraliser',
    'désacralises',
    'désacralisés',
    'désacralisez',
    'désactivâmes',
    'désactivasse',
    'désactivâtes',
    'désactiverai',
    'désactiveras',
    'désactiverez',
    'désactivions',
    'désadaptâmes',
    'désadaptasse',
    'désadaptâtes',
    'désadapterai',
    'désadapteras',
    'désadapterez',
    'désadaptions',
    'désaffectais',
    'désaffectait',
    'désaffectant',
    'désaffectées',
    'désaffectent',
    'désaffectera',
    'désaffectiez',
    'désaffection',
    'désaffectons',
    'désaffiliais',
    'désaffiliait',
    'désaffiliant',
    'désaffiliées',
    'désaffilient',
    'désaffiliera',
    'désaffiliiez',
    'désaffilions',
    'désagençâmes',
    'désagençasse',
    'désagençâtes',
    'désagencerai',
    'désagenceras',
    'désagencerez',
    'désagencions',
    'désagrafâmes',
    'désagrafasse',
    'désagrafâtes',
    'désagraferai',
    'désagraferas',
    'désagraferez',
    'désagrafions',
    'désagréables',
    'désagrégeais',
    'désagrégeait',
    'désagrégeant',
    'désagrégeons',
    'désagrégerai',
    'désagrégeras',
    'désagrégerez',
    'désagrégions',
    'désagréments',
    'désaimantais',
    'désaimantait',
    'désaimantant',
    'désaimantées',
    'désaimantent',
    'désaimantera',
    'désaimantiez',
    'désaimantons',
    'désajustâmes',
    'désajustasse',
    'désajustâtes',
    'désajusterai',
    'désajusteras',
    'désajusterez',
    'désajustions',
    'désaliénâmes',
    'désaliénasse',
    'désaliénâtes',
    'désaliénerai',
    'désaliéneras',
    'désaliénerez',
    'désaliénions',
    'désalignâmes',
    'désalignasse',
    'désalignâtes',
    'désalignerai',
    'désaligneras',
    'désalignerez',
    'désalignions',
    'désaltérâmes',
    'désaltérasse',
    'désaltérâtes',
    'désaltérerai',
    'désaltéreras',
    'désaltérerez',
    'désaltérions',
    'désamarrâmes',
    'désamarrasse',
    'désamarrâtes',
    'désamarrerai',
    'désamarreras',
    'désamarrerez',
    'désamarrions',
    'désambiguïsa',
    'désambiguïse',
    'désambiguïsé',
    'désamidonnai',
    'désamidonnas',
    'désamidonnât',
    'désamidonnée',
    'désamidonner',
    'désamidonnes',
    'désamidonnés',
    'désamidonnez',
    'désamorçages',
    'désamorçâmes',
    'désamorçasse',
    'désamorçâtes',
    'désamorcerai',
    'désamorceras',
    'désamorcerez',
    'désamorcions',
    'désannexâmes',
    'désannexasse',
    'désannexâtes',
    'désannexerai',
    'désannexeras',
    'désannexerez',
    'désannexions',
    'désappariais',
    'désappariait',
    'désappariant',
    'désappariées',
    'désapparient',
    'désappariera',
    'désappariiez',
    'désapparions',
    'désappointai',
    'désappointas',
    'désappointât',
    'désappointée',
    'désappointer',
    'désappointes',
    'désappointés',
    'désappointez',
    'désapprenais',
    'désapprenait',
    'désapprenant',
    'désapprendra',
    'désapprendre',
    'désappreniez',
    'désapprennes',
    'désapprenons',
    'désapprirent',
    'désapprisses',
    'désapprouvai',
    'désapprouvas',
    'désapprouvât',
    'désapprouvée',
    'désapprouver',
    'désapprouves',
    'désapprouvés',
    'désapprouvez',
    'désarçonnais',
    'désarçonnait',
    'désarçonnant',
    'désarçonnées',
    'désarçonnent',
    'désarçonnera',
    'désarçonniez',
    'désarçonnons',
    'désargentais',
    'désargentait',
    'désargentant',
    'désargentées',
    'désargentent',
    'désargentera',
    'désargentiez',
    'désargentons',
    'désarmassent',
    'désarmassiez',
    'désarmements',
    'désarmerions',
    'désarrimages',
    'désarrimâmes',
    'désarrimasse',
    'désarrimâtes',
    'désarrimerai',
    'désarrimeras',
    'désarrimerez',
    'désarrimions',
    'désarticulai',
    'désarticulas',
    'désarticulât',
    'désarticulée',
    'désarticuler',
    'désarticules',
    'désarticulés',
    'désarticulez',
    'désassemblai',
    'désassemblas',
    'désassemblât',
    'désassemblée',
    'désassembler',
    'désassembles',
    'désassemblés',
    'désassemblez',
    'désassimilai',
    'désassimilas',
    'désassimilât',
    'désassimilée',
    'désassimiler',
    'désassimiles',
    'désassimilés',
    'désassimilez',
    'désassorties',
    'désassortira',
    'désastreuses',
    'désatomisais',
    'désatomisait',
    'désatomisant',
    'désatomisées',
    'désatomisent',
    'désatomisera',
    'désatomisiez',
    'désatomisons',
    'désavantagea',
    'désavantagée',
    'désavantager',
    'désavantages',
    'désavantagés',
    'désavantagez',
    'désaveuglais',
    'désaveuglait',
    'désaveuglant',
    'désaveuglées',
    'désaveuglent',
    'désaveuglera',
    'désaveugliez',
    'désaveuglons',
    'désavouaient',
    'désavouasses',
    'désavouerais',
    'désavouerait',
    'désavouèrent',
    'désavoueriez',
    'désavouerons',
    'désavoueront',
    'désaxassions',
    'désaxeraient',
    'descellaient',
    'descellasses',
    'descellement',
    'descellerais',
    'descellerait',
    'descellèrent',
    'descelleriez',
    'descellerons',
    'descelleront',
    'descendaient',
    'descendances',
    'descendantes',
    'descenderies',
    'descendeuses',
    'descendirent',
    'descendisses',
    'descendrions',
    'descriptible',
    'descriptions',
    'descriptives',
    'déséchouâmes',
    'déséchouasse',
    'déséchouâtes',
    'déséchouerai',
    'déséchoueras',
    'déséchouerez',
    'déséchouions',
    'désembourbai',
    'désembourbas',
    'désembourbât',
    'désembourbée',
    'désembourber',
    'désembourbes',
    'désembourbés',
    'désembourbez',
    'désembraient',
    'désembraiera',
    'désembrayais',
    'désembrayait',
    'désembrayant',
    'désembrayées',
    'désembrayent',
    'désembrayera',
    'désembrayiez',
    'désembrayons',
    'désembuaient',
    'désembuasses',
    'désembuerais',
    'désembuerait',
    'désembuèrent',
    'désembueriez',
    'désembuerons',
    'désembueront',
    'désemmanchai',
    'désemmanchas',
    'désemmanchât',
    'désemmanchée',
    'désemmancher',
    'désemmanches',
    'désemmanchés',
    'désemmanchez',
    'désemparâmes',
    'désemparasse',
    'désemparâtes',
    'désemparerai',
    'désempareras',
    'désemparerez',
    'désemparions',
    'désempesâmes',
    'désempesasse',
    'désempesâtes',
    'désempèserai',
    'désempèseras',
    'désempèserez',
    'désempesions',
    'désemplirais',
    'désemplirait',
    'désempliriez',
    'désemplirons',
    'désempliront',
    'désemplissez',
    'désencadrais',
    'désencadrait',
    'désencadrant',
    'désencadrées',
    'désencadrent',
    'désencadrera',
    'désencadriez',
    'désencadrons',
    'désencartais',
    'désencartait',
    'désencartant',
    'désencartées',
    'désencartent',
    'désencartera',
    'désencartiez',
    'désencartons',
    'désenchaînai',
    'désenchaînas',
    'désenchaînât',
    'désenchaînée',
    'désenchaîner',
    'désenchaînes',
    'désenchaînés',
    'désenchaînez',
    'désenchantai',
    'désenchantas',
    'désenchantât',
    'désenchantée',
    'désenchanter',
    'désenchantes',
    'désenchantés',
    'désenchantez',
    'désenclavais',
    'désenclavait',
    'désenclavant',
    'désenclavées',
    'désenclavent',
    'désenclavera',
    'désenclaviez',
    'désenclavons',
    'désencollais',
    'désencollait',
    'désencollant',
    'désencollées',
    'désencollent',
    'désencollera',
    'désencolliez',
    'désencollons',
    'désencombrai',
    'désencombras',
    'désencombrât',
    'désencombrée',
    'désencombrer',
    'désencombres',
    'désencombrés',
    'désencombrez',
    'désencrassai',
    'désencrassas',
    'désencrassât',
    'désencrassée',
    'désencrasser',
    'désencrasses',
    'désencrassés',
    'désencrassez',
    'désendettais',
    'désendettait',
    'désendettant',
    'désendettées',
    'désendettent',
    'désendettera',
    'désendettiez',
    'désendettons',
    'désénervâmes',
    'désénervasse',
    'désénervâtes',
    'désénerverai',
    'désénerveras',
    'désénerverez',
    'désénervions',
    'désenfilâmes',
    'désenfilasse',
    'désenfilâtes',
    'désenfilerai',
    'désenfileras',
    'désenfilerez',
    'désenfilions',
    'désenflaient',
    'désenflammai',
    'désenflammas',
    'désenflammât',
    'désenflammée',
    'désenflammer',
    'désenflammes',
    'désenflammés',
    'désenflammez',
    'désenflasses',
    'désenflerais',
    'désenflerait',
    'désenflèrent',
    'désenfleriez',
    'désenflerons',
    'désenfleront',
    'désenfumâmes',
    'désenfumasse',
    'désenfumâtes',
    'désenfumerai',
    'désenfumeras',
    'désenfumerez',
    'désenfumions',
    'désengageais',
    'désengageait',
    'désengageant',
    'désengageons',
    'désengagerai',
    'désengageras',
    'désengagerez',
    'désengagions',
    'désengorgeai',
    'désengorgeas',
    'désengorgeât',
    'désengorgées',
    'désengorgent',
    'désengorgera',
    'désengorgiez',
    'désengourdie',
    'désengourdir',
    'désengourdis',
    'désengourdit',
    'désengourdît',
    'désenivrâmes',
    'désenivrasse',
    'désenivrâtes',
    'désenivrerai',
    'désenivreras',
    'désenivrerez',
    'désenivrions',
    'désenlaçâmes',
    'désenlaçasse',
    'désenlaçâtes',
    'désenlacerai',
    'désenlaceras',
    'désenlacerez',
    'désenlacions',
    'désenlaidies',
    'désenlaidira',
    'désennuierai',
    'désennuieras',
    'désennuierez',
    'désennuyâmes',
    'désennuyasse',
    'désennuyâtes',
    'désennuyions',
    'désenraierai',
    'désenraieras',
    'désenraierez',
    'désenrayâmes',
    'désenrayasse',
    'désenrayâtes',
    'désenrayerai',
    'désenrayeras',
    'désenrayerez',
    'désenrayions',
    'désenrhumais',
    'désenrhumait',
    'désenrhumant',
    'désenrhumées',
    'désenrhument',
    'désenrhumera',
    'désenrhumiez',
    'désenrhumons',
    'désenrouâmes',
    'désenrouasse',
    'désenrouâtes',
    'désenrouerai',
    'désenroueras',
    'désenrouerez',
    'désenrouions',
    'désensablais',
    'désensablait',
    'désensablant',
    'désensablées',
    'désensablent',
    'désensablera',
    'désensabliez',
    'désensablons',
    'désensorcela',
    'désensorcelé',
    'désentoilage',
    'désentoilais',
    'désentoilait',
    'désentoilant',
    'désentoilées',
    'désentoilent',
    'désentoilera',
    'désentoiliez',
    'désentoilons',
    'désentravais',
    'désentravait',
    'désentravant',
    'désentravées',
    'désentravent',
    'désentravera',
    'désentraviez',
    'désentravons',
    'désenvasâmes',
    'désenvasasse',
    'désenvasâtes',
    'désenvaserai',
    'désenvaseras',
    'désenvaserez',
    'désenvasions',
    'désenveloppa',
    'désenveloppe',
    'désenveloppé',
    'désenvenimai',
    'désenvenimas',
    'désenvenimât',
    'désenvenimée',
    'désenvenimer',
    'désenvenimes',
    'désenvenimés',
    'désenvenimez',
    'désenverguai',
    'désenverguas',
    'désenverguât',
    'désenverguée',
    'désenverguer',
    'désenvergues',
    'désenvergués',
    'désenverguez',
    'désépaissies',
    'désépaissira',
    'déséquilibra',
    'déséquilibre',
    'déséquilibré',
    'déséquipâmes',
    'déséquipasse',
    'déséquipâtes',
    'déséquiperai',
    'déséquiperas',
    'déséquiperez',
    'déséquipions',
    'désertassent',
    'désertassiez',
    'déserterions',
    'désescalades',
    'désespérâmes',
    'désespérance',
    'désespérante',
    'désespérants',
    'désespérasse',
    'désespérâtes',
    'désespérerai',
    'désespéreras',
    'désespérerez',
    'désespérions',
    'désétablîmes',
    'désétablirai',
    'désétabliras',
    'désétablirez',
    'désétablisse',
    'désétablîtes',
    'désétamaient',
    'désétamasses',
    'désétamerais',
    'désétamerait',
    'désétamèrent',
    'désétameriez',
    'désétamerons',
    'désétameront',
    'désétatisais',
    'désétatisait',
    'désétatisant',
    'désétatisées',
    'désétatisent',
    'désétatisera',
    'désétatisiez',
    'désétatisons',
    'déshabillage',
    'déshabillais',
    'déshabillait',
    'déshabillant',
    'déshabillées',
    'déshabillent',
    'déshabillera',
    'déshabilliez',
    'déshabillons',
    'déshabituais',
    'déshabituait',
    'déshabituant',
    'déshabituées',
    'déshabituent',
    'déshabituera',
    'déshabituiez',
    'déshabituons',
    'désherbaient',
    'désherbantes',
    'désherbasses',
    'désherberais',
    'désherberait',
    'désherbèrent',
    'désherberiez',
    'désherberons',
    'désherberont',
    'déshéritâmes',
    'déshéritasse',
    'déshéritâtes',
    'déshériterai',
    'déshériteras',
    'déshériterez',
    'déshéritions',
    'déshonnêteté',
    'déshonorâmes',
    'déshonorante',
    'déshonorants',
    'déshonorasse',
    'déshonorâtes',
    'déshonorerai',
    'déshonoreras',
    'déshonorerez',
    'déshonorions',
    'déshuilaient',
    'déshuilasses',
    'déshuilerais',
    'déshuilerait',
    'déshuilèrent',
    'déshuileriez',
    'déshuilerons',
    'déshuileront',
    'déshumanisai',
    'déshumanisas',
    'déshumanisât',
    'déshumanisée',
    'déshumaniser',
    'déshumanises',
    'déshumanisés',
    'déshumanisez',
    'déshumidifia',
    'déshumidifie',
    'déshumidifié',
    'déshydratais',
    'déshydratait',
    'déshydratant',
    'déshydratées',
    'déshydratent',
    'déshydratera',
    'déshydratiez',
    'déshydratons',
    'déshydrogéna',
    'déshydrogéné',
    'déshydrogène',
    'désignassent',
    'désignassiez',
    'désignations',
    'désignatives',
    'désignerions',
    'désiliciages',
    'désillusions',
    'désincarnais',
    'désincarnait',
    'désincarnant',
    'désincarnées',
    'désincarnent',
    'désincarnera',
    'désincarniez',
    'désincarnons',
    'désincorpora',
    'désincorpore',
    'désincorporé',
    'désincrustai',
    'désincrustas',
    'désincrustât',
    'désincrustée',
    'désincruster',
    'désincrustes',
    'désincrustés',
    'désincrustez',
    'désinculpais',
    'désinculpait',
    'désinculpant',
    'désinculpées',
    'désinculpent',
    'désinculpera',
    'désinculpiez',
    'désinculpons',
    'désindexâmes',
    'désindexasse',
    'désindexâtes',
    'désindexerai',
    'désindexeras',
    'désindexerez',
    'désindexions',
    'désinentiels',
    'désinfectais',
    'désinfectait',
    'désinfectant',
    'désinfectées',
    'désinfectent',
    'désinfectera',
    'désinfectiez',
    'désinfection',
    'désinfectons',
    'désinflation',
    'désinsectisa',
    'désinsectise',
    'désinsectisé',
    'désinsertion',
    'désintégrais',
    'désintégrait',
    'désintégrant',
    'désintégrées',
    'désintègrent',
    'désintégrera',
    'désintégriez',
    'désintégrons',
    'désintéressa',
    'désintéresse',
    'désintéressé',
    'désintoxiqua',
    'désintoxique',
    'désintoxiqué',
    'désinvesties',
    'désinvestira',
    'désinvitâmes',
    'désinvitasse',
    'désinvitâtes',
    'désinviterai',
    'désinviteras',
    'désinviterez',
    'désinvitions',
    'désinvolture',
    'désirassions',
    'désireraient',
    'désistassent',
    'désistassiez',
    'désistements',
    'désisterions',
    'désobéirions',
    'désobéissais',
    'désobéissait',
    'désobéissant',
    'désobéissent',
    'désobéissiez',
    'désobéissons',
    'désobligeais',
    'désobligeait',
    'désobligeant',
    'désobligeons',
    'désobligerai',
    'désobligeras',
    'désobligerez',
    'désobligions',
    'désobstruais',
    'désobstruait',
    'désobstruaît',
    'désobstruées',
    'désobstruent',
    'désobstruera',
    'désoccupâmes',
    'désoccupasse',
    'désoccupâtes',
    'désoccuperai',
    'désoccuperas',
    'désoccuperez',
    'désoccupions',
    'désodorisais',
    'désodorisait',
    'désodorisant',
    'désodorisées',
    'désodorisent',
    'désodorisera',
    'désodorisiez',
    'désodorisons',
    'désolassions',
    'désoleraient',
    'désolidarisa',
    'désolidarise',
    'désolidarisé',
    'désopilaient',
    'désopilantes',
    'désopilasses',
    'désopilerais',
    'désopilerait',
    'désopilèrent',
    'désopileriez',
    'désopilerons',
    'désopileront',
    'désorbassent',
    'désorbassiez',
    'désorberions',
    'désorbitâmes',
    'désorbitasse',
    'désorbitâtes',
    'désorbiterai',
    'désorbiteras',
    'désorbiterez',
    'désorbitions',
    'désordonnais',
    'désordonnait',
    'désordonnant',
    'désordonnées',
    'désordonnent',
    'désordonnera',
    'désordonniez',
    'désordonnons',
    'désorganisai',
    'désorganisas',
    'désorganisât',
    'désorganisée',
    'désorganiser',
    'désorganises',
    'désorganisés',
    'désorganisez',
    'désorientais',
    'désorientait',
    'désorientant',
    'désorientées',
    'désorientent',
    'désorientera',
    'désorientiez',
    'désorientons',
    'désossassent',
    'désossassiez',
    'désossements',
    'désosserions',
    'désoxydaient',
    'désoxydantes',
    'désoxydasses',
    'désoxydation',
    'désoxyderais',
    'désoxyderait',
    'désoxydèrent',
    'désoxyderiez',
    'désoxyderons',
    'désoxyderont',
    'désoxygénais',
    'désoxygénait',
    'désoxygénant',
    'désoxygénées',
    'désoxygènent',
    'désoxygénera',
    'désoxygéniez',
    'désoxygénons',
    'desquamaient',
    'desquamasses',
    'desquamation',
    'desquamerais',
    'desquamerait',
    'desquamèrent',
    'desquameriez',
    'desquamerons',
    'desquameront',
    'dessablaient',
    'dessablasses',
    'dessablerais',
    'dessablerait',
    'dessablèrent',
    'dessableriez',
    'dessablerons',
    'dessableront',
    'dessaisirais',
    'dessaisirait',
    'dessaisirent',
    'dessaisiriez',
    'dessaisirons',
    'dessaisiront',
    'dessaisisses',
    'dessaisissez',
    'dessalassent',
    'dessalassiez',
    'dessalements',
    'dessalerions',
    'dessanglâmes',
    'dessanglasse',
    'dessanglâtes',
    'dessanglerai',
    'dessangleras',
    'dessanglerez',
    'dessanglions',
    'dessaoulâmes',
    'dessaoulasse',
    'dessaoulâtes',
    'dessaoulerai',
    'dessaouleras',
    'dessaoulerez',
    'dessaoulions',
    'desséchaient',
    'desséchantes',
    'desséchasses',
    'dessèchement',
    'dessécherais',
    'dessécherait',
    'desséchèrent',
    'dessécheriez',
    'dessécherons',
    'dessécheront',
    'dessellaient',
    'dessellasses',
    'dessellerais',
    'dessellerait',
    'dessellèrent',
    'desselleriez',
    'dessellerons',
    'desselleront',
    'desserraient',
    'desserrasses',
    'desserrerais',
    'desserrerait',
    'desserrèrent',
    'desserreriez',
    'desserrerons',
    'desserreront',
    'dessertirais',
    'dessertirait',
    'dessertirent',
    'dessertiriez',
    'dessertirons',
    'dessertiront',
    'dessertisses',
    'dessertissez',
    'desservaient',
    'desservirais',
    'desservirait',
    'desservirent',
    'desserviriez',
    'desservirons',
    'desserviront',
    'desservisses',
    'dessiccatifs',
    'dessiccation',
    'dessiccative',
    'dessillaient',
    'dessillasses',
    'dessillerais',
    'dessillerait',
    'dessillèrent',
    'dessilleriez',
    'dessillerons',
    'dessilleront',
    'dessinassent',
    'dessinassiez',
    'dessinateurs',
    'dessinatrice',
    'dessinerions',
    'dessolassent',
    'dessolassiez',
    'dessolements',
    'dessolerions',
    'dessoudaient',
    'dessoudasses',
    'dessouderais',
    'dessouderait',
    'dessoudèrent',
    'dessouderiez',
    'dessouderons',
    'dessouderont',
    'dessoulaient',
    'dessoûlaient',
    'dessoulasses',
    'dessoûlasses',
    'dessoulerais',
    'dessoûlerais',
    'dessoulerait',
    'dessoûlerait',
    'dessoulèrent',
    'dessoûlèrent',
    'dessouleriez',
    'dessoûleriez',
    'dessoulerons',
    'dessoûlerons',
    'dessouleront',
    'dessoûleront',
    'dessuintages',
    'dessuitaient',
    'dessuitasses',
    'dessuiterais',
    'dessuiterait',
    'dessuitèrent',
    'dessuiteriez',
    'dessuiterons',
    'dessuiteront',
    'déstabilisai',
    'déstabilisas',
    'déstabilisât',
    'déstabilisée',
    'déstabiliser',
    'déstabilises',
    'déstabilisés',
    'déstabilisez',
    'destinassent',
    'destinassiez',
    'destinataire',
    'destinations',
    'destinerions',
    'destituaient',
    'destituerais',
    'destituerait',
    'destitueriez',
    'destituerons',
    'destitueront',
    'destitutions',
    'déstockaient',
    'déstockasses',
    'déstockerais',
    'déstockerait',
    'déstockèrent',
    'déstockeriez',
    'déstockerons',
    'déstockeront',
    'destructeurs',
    'destructible',
    'destructions',
    'destructives',
    'destructrice',
    'destructurai',
    'destructuras',
    'destructurât',
    'destructurée',
    'destructurer',
    'destructures',
    'destructurés',
    'destructurez',
    'désulfitâmes',
    'désulfitasse',
    'désulfitâtes',
    'désulfiterai',
    'désulfiteras',
    'désulfiterez',
    'désulfitions',
    'désulfurâmes',
    'désulfurasse',
    'désulfurâtes',
    'désulfurerai',
    'désulfureras',
    'désulfurerez',
    'désulfurions',
    'désuniraient',
    'désunissions',
    'détachassent',
    'détachassiez',
    'détachements',
    'détacherions',
    'détaillaient',
    'détaillantes',
    'détaillasses',
    'détaillerais',
    'détaillerait',
    'détaillèrent',
    'détailleriez',
    'détaillerons',
    'détailleront',
    'détalassions',
    'détaleraient',
    'détallassent',
    'détallassiez',
    'détallerions',
    'détapissâmes',
    'détapissasse',
    'détapissâtes',
    'détapisserai',
    'détapisseras',
    'détapisserez',
    'détapissions',
    'détartraient',
    'détartrantes',
    'détartrasses',
    'détartrerais',
    'détartrerait',
    'détartrèrent',
    'détartreriez',
    'détartrerons',
    'détartreront',
    'détaxassions',
    'détaxeraient',
    'détectassent',
    'détectassiez',
    'détecterions',
    'déteignaient',
    'déteignirent',
    'déteignisses',
    'déteindrions',
    'dételassions',
    'détellerions',
    'détendissent',
    'détendissiez',
    'détendraient',
    'détergeaient',
    'détergeasses',
    'détergerions',
    'détériorâmes',
    'détériorasse',
    'détériorâtes',
    'détériorerai',
    'détérioreras',
    'détériorerez',
    'détériorions',
    'déterminable',
    'déterminâmes',
    'déterminante',
    'déterminants',
    'déterminasse',
    'déterminâtes',
    'déterminatif',
    'déterminerai',
    'détermineras',
    'déterminerez',
    'déterminions',
    'déterminisme',
    'déterministe',
    'déterrassent',
    'déterrassiez',
    'déterrements',
    'déterrerions',
    'détestassent',
    'détestassiez',
    'détestations',
    'détesterions',
    'détiendrions',
    'détirassions',
    'détireraient',
    'détissassent',
    'détissassiez',
    'détisserions',
    'détonassions',
    'détoneraient',
    'détonnassent',
    'détonnassiez',
    'détonnelâmes',
    'détonnelasse',
    'détonnelâtes',
    'détonnelions',
    'détonnellent',
    'détonnellera',
    'détonnerions',
    'détordissent',
    'détordissiez',
    'détordraient',
    'détortillais',
    'détortillait',
    'détortillant',
    'détortillées',
    'détortillent',
    'détortillera',
    'détortilliez',
    'détortillons',
    'détourassent',
    'détourassiez',
    'détourerions',
    'détournaient',
    'détournasses',
    'détournement',
    'détournerais',
    'détournerait',
    'détournèrent',
    'détourneriez',
    'détournerons',
    'détourneront',
    'détoxication',
    'détractaient',
    'détractasses',
    'détracterais',
    'détracterait',
    'détractèrent',
    'détracteriez',
    'détracterons',
    'détracteront',
    'détractrices',
    'détranchâmes',
    'détranchasse',
    'détranchâtes',
    'détrancherai',
    'détrancheras',
    'détrancherez',
    'détranchions',
    'détransposai',
    'détransposas',
    'détransposât',
    'détransposée',
    'détransposer',
    'détransposes',
    'détransposés',
    'détransposez',
    'détraquaient',
    'détraquasses',
    'détraquement',
    'détraquerais',
    'détraquerait',
    'détraquèrent',
    'détraqueriez',
    'détraquerons',
    'détraqueront',
    'détrempaient',
    'détrempasses',
    'détremperais',
    'détremperait',
    'détrempèrent',
    'détremperiez',
    'détremperons',
    'détremperont',
    'détressaient',
    'détressasses',
    'détresserais',
    'détresserait',
    'détressèrent',
    'détresseriez',
    'détresserons',
    'détresseront',
    'détricotâmes',
    'détricotasse',
    'détricotâtes',
    'détricoterai',
    'détricoteras',
    'détricoterez',
    'détricotions',
    'détrompaient',
    'détrompasses',
    'détromperais',
    'détromperait',
    'détrompèrent',
    'détromperiez',
    'détromperons',
    'détromperont',
    'détrônassent',
    'détrônassiez',
    'détronchâmes',
    'détronchasse',
    'détronchâtes',
    'détroncherai',
    'détroncheras',
    'détroncherez',
    'détronchions',
    'détrônerions',
    'détroquaient',
    'détroquasses',
    'détroquerais',
    'détroquerait',
    'détroquèrent',
    'détroqueriez',
    'détroquerons',
    'détroqueront',
    'détroussâmes',
    'détroussasse',
    'détroussâtes',
    'détrousserai',
    'détrousseras',
    'détrousserez',
    'détrousseurs',
    'détrousseuse',
    'détroussions',
    'détruiraient',
    'détruisaient',
    'détruisirent',
    'détruisisses',
    'détumescence',
    'deuxièmement',
    'dévalassions',
    'dévaleraient',
    'dévalisaient',
    'dévalisasses',
    'dévaliserais',
    'dévaliserait',
    'dévalisèrent',
    'dévaliseriez',
    'dévaliserons',
    'dévaliseront',
    'dévalorisais',
    'dévalorisait',
    'dévalorisant',
    'dévalorisées',
    'dévalorisent',
    'dévalorisera',
    'dévalorisiez',
    'dévalorisons',
    'dévaluassent',
    'dévaluassiez',
    'dévaluations',
    'dévaluerâmes',
    'dévaluerasse',
    'dévaluerâtes',
    'devançassent',
    'devançassiez',
    'devancements',
    'devancerions',
    'dévasassions',
    'dévaseraient',
    'dévastassent',
    'dévastassiez',
    'dévastateurs',
    'dévastations',
    'dévastatrice',
    'dévasterions',
    'développable',
    'développâmes',
    'développante',
    'développants',
    'développasse',
    'développâtes',
    'développerai',
    'développeras',
    'développerez',
    'développeurs',
    'développions',
    'déventassent',
    'déventassiez',
    'déventerions',
    'déverdirions',
    'déverdissais',
    'déverdissait',
    'déverdissant',
    'déverdissent',
    'déverdissiez',
    'déverdissons',
    'dévergondage',
    'dévergondais',
    'dévergondait',
    'dévergondant',
    'dévergondées',
    'dévergondent',
    'dévergondera',
    'dévergondiez',
    'dévergondons',
    'déverguaient',
    'déverguasses',
    'déverguerais',
    'déverguerait',
    'déverguèrent',
    'dévergueriez',
    'déverguerons',
    'dévergueront',
    'dévernirions',
    'dévernissais',
    'dévernissait',
    'dévernissant',
    'dévernissent',
    'dévernissiez',
    'dévernissons',
    'déverrouilla',
    'déverrouille',
    'déverrouillé',
    'déversassent',
    'déversassiez',
    'déversements',
    'déverserions',
    'dévêtiraient',
    'dévêtissions',
    'dévidassions',
    'dévideraient',
    'deviendrions',
    'devinassions',
    'devineraient',
    'devineresses',
    'dévirilisais',
    'dévirilisait',
    'dévirilisant',
    'dévirilisées',
    'dévirilisent',
    'dévirilisera',
    'dévirilisiez',
    'dévirilisons',
    'dévirolaient',
    'dévirolasses',
    'dévirolerais',
    'dévirolerait',
    'dévirolèrent',
    'déviroleriez',
    'dévirolerons',
    'déviroleront',
    'dévisageâmes',
    'dévisageasse',
    'dévisageâtes',
    'dévisagerais',
    'dévisagerait',
    'dévisagèrent',
    'dévisageriez',
    'dévisagerons',
    'dévisageront',
    'devisassions',
    'deviseraient',
    'dévissassent',
    'dévissassiez',
    'dévisserâmes',
    'dévisserasse',
    'dévisserâtes',
    'dévitalisais',
    'dévitalisait',
    'dévitalisant',
    'dévitalisées',
    'dévitalisent',
    'dévitalisera',
    'dévitalisiez',
    'dévitalisons',
    'dévitrifiais',
    'dévitrifiait',
    'dévitrifiant',
    'dévitrifiées',
    'dévitrifient',
    'dévitrifiera',
    'dévitrifiiez',
    'dévitrifions',
    'dévoieraient',
    'dévoilassent',
    'dévoilassiez',
    'dévoilements',
    'dévoilerâmes',
    'dévoilerasse',
    'dévoilerâtes',
    'dévoltassent',
    'dévoltassiez',
    'dévolterions',
    'dévorassions',
    'dévoratrices',
    'dévoreraient',
    'dévouassions',
    'dévoueraient',
    'dévoyassions',
    'dextrocardie',
    'diachronique',
    'diacoustique',
    'diacritiques',
    'diagnostiqua',
    'diagnostique',
    'diagnostiqué',
    'dialectalisa',
    'dialectalise',
    'dialectalisé',
    'dialecticien',
    'dialectiques',
    'dialectisais',
    'dialectisait',
    'dialectisant',
    'dialectisées',
    'dialectisent',
    'dialectisera',
    'dialectisiez',
    'dialectisons',
    'dialoguaient',
    'dialoguasses',
    'dialoguerais',
    'dialoguerait',
    'dialoguèrent',
    'dialogueriez',
    'dialoguerons',
    'dialogueront',
    'dialoguistes',
    'dialypétales',
    'dialysassent',
    'dialysassiez',
    'dialyserions',
    'diamantaient',
    'diamantaires',
    'diamantasses',
    'diamanterais',
    'diamanterait',
    'diamantèrent',
    'diamanteriez',
    'diamanterons',
    'diamanteront',
    'diamantifère',
    'diaphanéités',
    'diaphragmais',
    'diaphragmait',
    'diaphragmant',
    'diaphragmées',
    'diaphragment',
    'diaphragmera',
    'diaphragmiez',
    'diaphragmons',
    'diapositives',
    'diaprassions',
    'diapreraient',
    'diarrhéiques',
    'diastasiques',
    'diastoliques',
    'dichotomique',
    'dicotylédone',
    'dictatoriale',
    'dictatoriaux',
    'dictionnaire',
    'didacticiels',
    'diélectrique',
    'diencéphales',
    'diététiciens',
    'diffamassent',
    'diffamassiez',
    'diffamateurs',
    'diffamations',
    'diffamatoire',
    'diffamatrice',
    'diffamerions',
    'différassent',
    'différassiez',
    'différemment',
    'différenciai',
    'différencias',
    'différenciât',
    'différenciée',
    'différencier',
    'différencies',
    'différenciés',
    'différenciez',
    'différentiel',
    'différerions',
    'difformaient',
    'difformasses',
    'difformerais',
    'difformerait',
    'difformèrent',
    'difformeriez',
    'difformerons',
    'difformeront',
    'diffractâmes',
    'diffractasse',
    'diffractâtes',
    'diffracterai',
    'diffracteras',
    'diffracterez',
    'diffractions',
    'diffusassent',
    'diffusassiez',
    'diffuserions',
    'digérassions',
    'digéreraient',
    'digitalisais',
    'digitalisait',
    'digitalisant',
    'digitalisées',
    'digitalisent',
    'digitalisiez',
    'digitalisons',
    'digitiformes',
    'digitigrades',
    'digtaliserai',
    'digtaliseras',
    'digtaliserez',
    'dilacéraient',
    'dilacérasses',
    'dilacération',
    'dilacérerais',
    'dilacérerait',
    'dilacérèrent',
    'dilacéreriez',
    'dilacérerons',
    'dilacéreront',
    'dilapidaient',
    'dilapidasses',
    'dilapidateur',
    'dilapidation',
    'dilapiderais',
    'dilapiderait',
    'dilapidèrent',
    'dilapideriez',
    'dilapiderons',
    'dilapideront',
    'dilatabilité',
    'dilatassions',
    'dilatatrices',
    'dilateraient',
    'dilatomètres',
    'dimensionnai',
    'dimensionnas',
    'dimensionnât',
    'dimensionnée',
    'dimensionnel',
    'dimensionner',
    'dimensionnes',
    'dimensionnés',
    'dimensionnez',
    'diminuassent',
    'diminuassiez',
    'diminuerions',
    'dimorphismes',
    'dindonnaient',
    'dindonnasses',
    'dindonnerais',
    'dindonnerait',
    'dindonnèrent',
    'dindonneriez',
    'dindonnerons',
    'dindonneront',
    'dinguassions',
    'dingueraient',
    'dinothériums',
    'dionysiaques',
    'diphtériques',
    'diphtonguais',
    'diphtonguait',
    'diphtonguant',
    'diphtonguées',
    'diphtonguent',
    'diphtonguera',
    'diphtonguiez',
    'diphtonguons',
    'diplômassent',
    'diplômassiez',
    'diplomatique',
    'diplômerions',
    'dipneumonées',
    'directivisme',
    'directivités',
    'directoriale',
    'directoriaux',
    'dirigeassent',
    'dirigeassiez',
    'dirigeraient',
    'disaccharide',
    'discarthrose',
    'discernables',
    'discernaient',
    'discernasses',
    'discernement',
    'discernerais',
    'discernerait',
    'discernèrent',
    'discerneriez',
    'discernerons',
    'discerneront',
    'disciplinais',
    'disciplinait',
    'disciplinant',
    'disciplinées',
    'disciplinent',
    'disciplinera',
    'discipliniez',
    'disciplinons',
    'discographie',
    'discontinuai',
    'discontinuas',
    'discontinuât',
    'discontinuer',
    'discontinues',
    'discontinuez',
    'disconvenais',
    'disconvenait',
    'disconvenant',
    'disconveniez',
    'disconvenons',
    'disconvenues',
    'disconvienne',
    'disconvînmes',
    'disconvinsse',
    'disconvîntes',
    'discopathies',
    'discophilies',
    'discordaient',
    'discordances',
    'discordantes',
    'discordasses',
    'discorderais',
    'discorderait',
    'discordèrent',
    'discorderiez',
    'discorderons',
    'discorderont',
    'discothèques',
    'discouraient',
    'discoureuses',
    'discourrions',
    'discourtoise',
    'discoururent',
    'discourusses',
    'discréditais',
    'discréditait',
    'discréditant',
    'discréditées',
    'discréditent',
    'discréditera',
    'discréditiez',
    'discréditons',
    'discrètement',
    'discrétoires',
    'discriminais',
    'discriminait',
    'discriminant',
    'discriminées',
    'discriminent',
    'discriminera',
    'discriminiez',
    'discriminons',
    'disculpaient',
    'disculpasses',
    'disculpation',
    'disculperais',
    'disculperait',
    'disculpèrent',
    'disculperiez',
    'disculperons',
    'disculperont',
    'discutaillai',
    'discutaillas',
    'discutaillât',
    'discutaillée',
    'discutailler',
    'discutailles',
    'discutaillés',
    'discutaillez',
    'discutassent',
    'discutassiez',
    'discuterions',
    'disgraciâmes',
    'disgraciasse',
    'disgraciâtes',
    'disgracierai',
    'disgracieras',
    'disgracierez',
    'disgracieuse',
    'disgraciions',
    'disharmonies',
    'disjoignîmes',
    'disjoignions',
    'disjoignisse',
    'disjoignîtes',
    'disjoindrais',
    'disjoindrait',
    'disjoindriez',
    'disjoindrons',
    'disjoindront',
    'disjonctâmes',
    'disjonctasse',
    'disjonctâtes',
    'disjoncterai',
    'disjoncteras',
    'disjoncterez',
    'disjoncteurs',
    'disjonctions',
    'disjonctives',
    'dislocations',
    'disloquaient',
    'disloquasses',
    'disloquerais',
    'disloquerait',
    'disloquèrent',
    'disloqueriez',
    'disloquerons',
    'disloqueront',
    'disparaisses',
    'disparaissez',
    'disparaîtrai',
    'disparaîtras',
    'disparaîtrez',
    'disparations',
    'disparitions',
    'disparussent',
    'disparussiez',
    'dispatchings',
    'dispendieuse',
    'dispensables',
    'dispensaient',
    'dispensaires',
    'dispensasses',
    'dispensateur',
    'dispenserais',
    'dispenserait',
    'dispensèrent',
    'dispenseriez',
    'dispenserons',
    'dispenseront',
    'dispersaient',
    'dispersantes',
    'dispersasses',
    'dispersement',
    'disperserais',
    'disperserait',
    'dispersèrent',
    'disperseriez',
    'disperserons',
    'disperseront',
    'disposassent',
    'disposassiez',
    'disposerions',
    'dispositions',
    'disputaillai',
    'disputaillas',
    'disputaillât',
    'disputailler',
    'disputailles',
    'disputaillez',
    'disputassent',
    'disputassiez',
    'disputerions',
    'disqualifiai',
    'disqualifias',
    'disqualifiât',
    'disqualifiée',
    'disqualifier',
    'disqualifies',
    'disqualifiés',
    'disqualifiez',
    'dissemblable',
    'dissemblance',
    'disséminâmes',
    'disséminasse',
    'disséminâtes',
    'disséminerai',
    'dissémineras',
    'disséminerez',
    'disséminions',
    'dissentiment',
    'disséquaient',
    'disséquasses',
    'disséquerais',
    'disséquerait',
    'disséquèrent',
    'disséqueriez',
    'disséquerons',
    'disséqueront',
    'dissertaient',
    'dissertasses',
    'dissertation',
    'disserterais',
    'disserterait',
    'dissertèrent',
    'disserteriez',
    'disserterons',
    'disserteront',
    'dissimulâmes',
    'dissimulasse',
    'dissimulâtes',
    'dissimulerai',
    'dissimuleras',
    'dissimulerez',
    'dissimulions',
    'dissipassent',
    'dissipassiez',
    'dissipateurs',
    'dissipations',
    'dissipatrice',
    'dissiperions',
    'dissociables',
    'dissociaient',
    'dissociasses',
    'dissociation',
    'dissocierais',
    'dissocierait',
    'dissocièrent',
    'dissocieriez',
    'dissocierons',
    'dissocieront',
    'dissolutions',
    'dissolutives',
    'dissolvaient',
    'dissolvantes',
    'dissonassent',
    'dissonassiez',
    'dissonerions',
    'dissoudrions',
    'dissuadaient',
    'dissuadasses',
    'dissuaderais',
    'dissuaderait',
    'dissuadèrent',
    'dissuaderiez',
    'dissuaderons',
    'dissuaderont',
    'dissymétries',
    'distançaient',
    'distançasses',
    'distancerais',
    'distancerait',
    'distancèrent',
    'distanceriez',
    'distancerons',
    'distanceront',
    'distanciâmes',
    'distanciasse',
    'distanciâtes',
    'distancierai',
    'distancieras',
    'distancierez',
    'distanciions',
    'distendaient',
    'distendirent',
    'distendisses',
    'distendrions',
    'distillaient',
    'distillasses',
    'distillateur',
    'distillation',
    'distillerais',
    'distillerait',
    'distillèrent',
    'distilleries',
    'distilleriez',
    'distillerons',
    'distilleront',
    'distinctions',
    'distinctives',
    'distinguable',
    'distinguâmes',
    'distinguasse',
    'distinguâtes',
    'distinguerai',
    'distingueras',
    'distinguerez',
    'distinguions',
    'distordaient',
    'distordirent',
    'distordisses',
    'distordrions',
    'distractions',
    'distrairions',
    'distrayaient',
    'distrayantes',
    'distribuable',
    'distribuâmes',
    'distribuasse',
    'distribuâtes',
    'distribuerai',
    'distribueras',
    'distribuerez',
    'distribuions',
    'distributeur',
    'distributifs',
    'distribution',
    'distributive',
    'divagatrices',
    'divaguassent',
    'divaguassiez',
    'divaguerions',
    'divergeaient',
    'divergeasses',
    'divergerions',
    'diversifiais',
    'diversifiait',
    'diversifiant',
    'diversifiées',
    'diversifient',
    'diversifiera',
    'diversifiiez',
    'diversifions',
    'diversiforme',
    'diverticules',
    'divertimento',
    'divertirions',
    'divertissais',
    'divertissait',
    'divertissant',
    'divertissent',
    'divertissiez',
    'divertissons',
    'divinatoires',
    'divinatrices',
    'divinisaient',
    'divinisasses',
    'divinisation',
    'diviniserais',
    'diviniserait',
    'divinisèrent',
    'diviniseriez',
    'diviniserons',
    'diviniseront',
    'divisassions',
    'diviseraient',
    'divisibilité',
    'divorçassent',
    'divorçassiez',
    'divorcerions',
    'divulgateurs',
    'divulgations',
    'divulgatrice',
    'divulguaient',
    'divulguasses',
    'divulguerais',
    'divulguerait',
    'divulguèrent',
    'divulgueriez',
    'divulguerons',
    'divulgueront',
    'dix-huitième',
    'dix-neuvième',
    'dix-septième',
    'docimologies',
    'doctrinaires',
    'documentaire',
    'documentâmes',
    'documentasse',
    'documentâtes',
    'documenterai',
    'documenteras',
    'documenterez',
    'documentions',
    'dodécagonale',
    'dodécagonaux',
    'dodécastyles',
    'dodelinaient',
    'dodelinasses',
    'dodelinement',
    'dodelinerais',
    'dodelinerait',
    'dodelinèrent',
    'dodelineriez',
    'dodelinerons',
    'dodelineront',
    'dodinassions',
    'dodineraient',
    'dogmatisâmes',
    'dogmatisasse',
    'dogmatisâtes',
    'dogmatiserai',
    'dogmatiseras',
    'dogmatiserez',
    'dogmatiseurs',
    'dogmatisions',
    'doigtassions',
    'doigteraient',
    'dolomitiques',
    'domanialités',
    'domesticités',
    'domestiquais',
    'domestiquait',
    'domestiquant',
    'domestiquées',
    'domestiquent',
    'domestiquera',
    'domestiquiez',
    'domestiquons',
    'domiciliaire',
    'domiciliâmes',
    'domiciliasse',
    'domiciliâtes',
    'domicilierai',
    'domicilieras',
    'domicilierez',
    'domiciliions',
    'dominassions',
    'dominatrices',
    'domineraient',
    'dominicaines',
    'dominoteries',
    'dominotières',
    'dommageables',
    'domptassions',
    'dompteraient',
    'donjuanesque',
    'donjuanismes',
    'dorlotassent',
    'dorlotassiez',
    'dorlotements',
    'dorloterions',
    'doublassions',
    'double-crème',
    'doubleraient',
    'doucettement',
    'douchassions',
    'doucheraient',
    'doucissaient',
    'douloureuses',
    'douteusement',
    'douzièmement',
    'draconiennes',
    'dragéifiâmes',
    'dragéifiasse',
    'dragéifiâtes',
    'dragéifierai',
    'dragéifieras',
    'dragéifierez',
    'dragéifiions',
    'drageonnages',
    'drageonnâmes',
    'drageonnasse',
    'drageonnâtes',
    'drageonnerai',
    'drageonneras',
    'drageonnerez',
    'drageonnions',
    'draguassions',
    'dragueraient',
    'drainassions',
    'draineraient',
    'dramatisâmes',
    'dramatisasse',
    'dramatisâtes',
    'dramatiserai',
    'dramatiseras',
    'dramatiserez',
    'dramatisions',
    'dramaturgies',
    'dravidiennes',
    'dreadnoughts',
    'dressassions',
    'dresseraient',
    'dreyfusardes',
    'dribblassent',
    'dribblassiez',
    'dribblerions',
    'drillassions',
    'drilleraient',
    'droguassions',
    'drogueraient',
    'droppassions',
    'dropperaient',
    'drossassions',
    'drosseraient',
    'duffel-coats',
    'duffle-coats',
    'dulcifiaient',
    'dulcifiasses',
    'dulcifierais',
    'dulcifierait',
    'dulcifièrent',
    'dulcifieriez',
    'dulcifierons',
    'dulcifieront',
    'duodécimales',
    'duplexassent',
    'duplexassiez',
    'duplexerions',
    'duplicateurs',
    'duplications',
    'dupliquaient',
    'dupliquasses',
    'dupliquerais',
    'dupliquerait',
    'dupliquèrent',
    'dupliqueriez',
    'dupliquerons',
    'dupliqueront',
    'durcissaient',
    'durcissement',
    'duvetassions',
    'duvetterions',
    'dynamisaient',
    'dynamisasses',
    'dynamisation',
    'dynamiserais',
    'dynamiserait',
    'dynamisèrent',
    'dynamiseriez',
    'dynamiserons',
    'dynamiseront',
    'dynamitaient',
    'dynamitasses',
    'dynamiterais',
    'dynamiterait',
    'dynamitèrent',
    'dynamiteriez',
    'dynamiterons',
    'dynamiteront',
    'dynamographe',
    'dynamomètres',
    'dyscalculies',
    'dysentérique',
    'dysfonctions',
    'dysménorrhée',
    'dyspepsiques',
    'dyspeptiques',
    'ébahissaient',
    'ébahissement',
    'ébarbassions',
    'ébarberaient',
    'ébattissions',
    'ébaubissions',
    'ébauchassent',
    'ébauchassiez',
    'ébaucherions',
    'ébaudissions',
    'ébavurassent',
    'ébavurassiez',
    'ébavurerions',
    'ébénisteries',
    'éberluassent',
    'éberluassiez',
    'éberluerions',
    'ébiselassent',
    'ébiselassiez',
    'ébiselerions',
    'éblouiraient',
    'éblouissante',
    'éblouissants',
    'éblouissions',
    'éborgnassent',
    'éborgnassiez',
    'éborgnements',
    'éborgnerions',
    'ébossassions',
    'ébosseraient',
    'ébouillantai',
    'ébouillantas',
    'ébouillantât',
    'ébouillantée',
    'ébouillanter',
    'ébouillantes',
    'ébouillantés',
    'ébouillantez',
    'éboulassions',
    'ébouleraient',
    'ébourgeonnai',
    'ébourgeonnas',
    'ébourgeonnât',
    'ébourgeonnée',
    'ébourgeonner',
    'ébourgeonnes',
    'ébourgeonnés',
    'ébourgeonnez',
    'ébouriffâmes',
    'ébouriffante',
    'ébouriffants',
    'ébouriffasse',
    'ébouriffâtes',
    'ébourifferai',
    'ébourifferas',
    'ébourifferez',
    'ébouriffions',
    'ébourrassent',
    'ébourrassiez',
    'ébourrerions',
    'éboutassions',
    'ébouteraient',
    'ébraisassent',
    'ébraisassiez',
    'ébraiserions',
    'ébranchaient',
    'ébranchasses',
    'ébrancherais',
    'ébrancherait',
    'ébranchèrent',
    'ébrancheriez',
    'ébrancherons',
    'ébrancheront',
    'ébranlassent',
    'ébranlassiez',
    'ébranlements',
    'ébranlerions',
    'ébrasassions',
    'ébraseraient',
    'ébréchassent',
    'ébréchassiez',
    'ébrécherions',
    'ébrouassions',
    'ébruitassent',
    'ébruitassiez',
    'ébruitements',
    'ébruiterions',
    'ébulliomètre',
    'ébullioscope',
    'écachassions',
    'écacheraient',
    'écaffassions',
    'écafferaient',
    'écaillassent',
    'écaillassiez',
    'écaillerions',
    'écanguassent',
    'écanguassiez',
    'écanguerions',
    'écarquillais',
    'écarquillait',
    'écarquillant',
    'écarquillées',
    'écarquillent',
    'écarquillera',
    'écarquilliez',
    'écarquillons',
    'écartassions',
    'écartelaient',
    'écartelasses',
    'écartèlement',
    'écartèlerais',
    'écartèlerait',
    'écartelèrent',
    'écartèleriez',
    'écartèlerons',
    'écartèleront',
    'écarteraient',
    'écatissaient',
    'échafaudages',
    'échafaudâmes',
    'échafaudasse',
    'échafaudâtes',
    'échafauderai',
    'échafauderas',
    'échafauderez',
    'échafaudions',
    'échalassâmes',
    'échalassasse',
    'échalassâtes',
    'échalasserai',
    'échalasseras',
    'échalasserez',
    'échalassions',
    'échampirions',
    'échampissais',
    'échampissait',
    'échampissant',
    'échampissent',
    'échampissiez',
    'échampissons',
    'échancraient',
    'échancrasses',
    'échancrerais',
    'échancrerait',
    'échancrèrent',
    'échancreriez',
    'échancrerons',
    'échancreront',
    'échanfreinai',
    'échanfreinas',
    'échanfreinât',
    'échanfreinée',
    'échanfreiner',
    'échanfreines',
    'échanfreinés',
    'échanfreinez',
    'échangeables',
    'échangeaient',
    'échangeasses',
    'échangerions',
    'échantillons',
    'échappassent',
    'échappassiez',
    'échappatoire',
    'échappements',
    'échapperions',
    'échardonnage',
    'échardonnais',
    'échardonnait',
    'échardonnant',
    'échardonnées',
    'échardonnent',
    'échardonnera',
    'échardonniez',
    'échardonnons',
    'écharnassent',
    'écharnassiez',
    'écharnements',
    'écharnerions',
    'écharpassent',
    'écharpassiez',
    'écharperions',
    'échauboulure',
    'échaudassent',
    'échaudassiez',
    'échauderions',
    'échauffaient',
    'échauffantes',
    'échauffasses',
    'échauffement',
    'échaufferais',
    'échaufferait',
    'échauffèrent',
    'échaufferiez',
    'échaufferons',
    'échaufferont',
    'échauffourée',
    'échauguettes',
    'échaulassent',
    'échaulassiez',
    'échaulerions',
    'échaumassent',
    'échaumassiez',
    'échaumerions',
    'échelonnâmes',
    'échelonnasse',
    'échelonnâtes',
    'échelonnerai',
    'échelonneras',
    'échelonnerez',
    'échelonnions',
    'échenillages',
    'échenillâmes',
    'échenillasse',
    'échenillâtes',
    'échenillerai',
    'échenilleras',
    'échenillerez',
    'échenillions',
    'échenilloirs',
    'échevelaient',
    'échevelasses',
    'échevelèrent',
    'échevellerai',
    'échevelleras',
    'échevellerez',
    'échinassions',
    'échineraient',
    'échinocactus',
    'échinocoques',
    'échographies',
    'échoppassent',
    'échoppassiez',
    'échopperions',
    'échouassions',
    'échoueraient',
    'éclaboussais',
    'éclaboussait',
    'éclaboussant',
    'éclaboussées',
    'éclaboussent',
    'éclaboussera',
    'éclaboussiez',
    'éclaboussons',
    'éclaboussure',
    'éclairagiste',
    'éclairassent',
    'éclairassiez',
    'éclaircirais',
    'éclaircirait',
    'éclaircirent',
    'éclairciriez',
    'éclaircirons',
    'éclairciront',
    'éclaircisses',
    'éclaircissez',
    'éclairements',
    'éclairerions',
    'éclatassions',
    'éclateraient',
    'éclipsassent',
    'éclipsassiez',
    'éclipserions',
    'éclissassent',
    'éclissassiez',
    'éclisserions',
    'éclopassions',
    'écloperaient',
    'éclusassions',
    'écluseraient',
    'écobuassions',
    'écobueraient',
    'écoeurassent',
    'écoeurassiez',
    'écoeurements',
    'écoeurerions',
    'éconduirions',
    'éconduisîmes',
    'éconduisions',
    'éconduisisse',
    'éconduisîtes',
    'économétries',
    'économisâmes',
    'économisasse',
    'économisâtes',
    'économiserai',
    'économiseras',
    'économiserez',
    'économiseurs',
    'économisions',
    'écorçassions',
    'écorceraient',
    'écorchassent',
    'écorchassiez',
    'écorchements',
    'écorcherions',
    'écornassions',
    'écorneraient',
    'écorniflâmes',
    'écorniflasse',
    'écorniflâtes',
    'écorniflerai',
    'écornifleras',
    'écorniflerez',
    'écornifleurs',
    'écornifleuse',
    'écorniflions',
    'écossassions',
    'écosseraient',
    'écoulassions',
    'écouleraient',
    'écourtassent',
    'écourtassiez',
    'écourterions',
    'écoutassions',
    'écouteraient',
    'écouvillonna',
    'écouvillonne',
    'écouvillonné',
    'écrabouillai',
    'écrabouillas',
    'écrabouillât',
    'écrabouillée',
    'écrabouiller',
    'écrabouilles',
    'écrabouillés',
    'écrabouillez',
    'écrasassions',
    'écraseraient',
    'écrémassions',
    'écrémeraient',
    'écrêtassions',
    'écrêteraient',
    'écrivaillais',
    'écrivaillait',
    'écrivaillant',
    'écrivaillées',
    'écrivaillent',
    'écrivaillera',
    'écrivailleur',
    'écrivailliez',
    'écrivaillons',
    'écrivassâmes',
    'écrivassasse',
    'écrivassâtes',
    'écrivasserai',
    'écrivasseras',
    'écrivasserez',
    'écrivassière',
    'écrivassiers',
    'écrivassions',
    'écrivissions',
    'écrouassions',
    'écroueraient',
    'écrouiraient',
    'écrouissages',
    'écrouissions',
    'écroulassiez',
    'écroulements',
    'écroulerions',
    'écroûtassent',
    'écroûtassiez',
    'écroûterions',
    'ectodermique',
    'ectoparasite',
    'écuissassent',
    'écuissassiez',
    'écuisserions',
    'écussonnages',
    'écussonnâmes',
    'écussonnasse',
    'écussonnâtes',
    'écussonnerai',
    'écussonneras',
    'écussonnerez',
    'écussonnions',
    'écussonnoirs',
    'eczémateuses',
    'édentassions',
    'édenteraient',
    'édictassions',
    'édicteraient',
    'édifiassions',
    'édificateurs',
    'édifications',
    'édificatrice',
    'édifieraient',
    'éditionnâmes',
    'éditionnasse',
    'éditionnâtes',
    'éditionnerai',
    'éditionneras',
    'éditionnerez',
    'éditionnions',
    'éducabilités',
    'éducationnel',
    'édulcoraient',
    'édulcorantes',
    'édulcorasses',
    'édulcoration',
    'édulcorerais',
    'édulcorerait',
    'édulcorèrent',
    'édulcoreriez',
    'édulcorerons',
    'édulcoreront',
    'éduquassions',
    'éduqueraient',
    'éfaufilaient',
    'éfaufilasses',
    'éfaufilerais',
    'éfaufilerait',
    'éfaufilèrent',
    'éfaufileriez',
    'éfaufilerons',
    'éfaufileront',
    'effaçassions',
    'effaceraient',
    'effanassions',
    'effaneraient',
    'effarassions',
    'effareraient',
    'effarouchais',
    'effarouchait',
    'effarouchant',
    'effarouchées',
    'effarouchent',
    'effarouchera',
    'effarouchiez',
    'effarouchons',
    'effectuaient',
    'effectuasses',
    'effectuerais',
    'effectuerait',
    'effectuèrent',
    'effectueriez',
    'effectuerons',
    'effectueront',
    'efféminaient',
    'efféminasses',
    'efféminement',
    'efféminerais',
    'efféminerait',
    'efféminèrent',
    'effémineriez',
    'efféminerons',
    'effémineront',
    'effervescent',
    'effeuillages',
    'effeuillâmes',
    'effeuillasse',
    'effeuillâtes',
    'effeuillerai',
    'effeuilleras',
    'effeuillerez',
    'effeuilleuse',
    'effeuillions',
    'efficacement',
    'effilassions',
    'effileraient',
    'effilochages',
    'effilochâmes',
    'effilochasse',
    'effilochâtes',
    'effilocherai',
    'effilocheras',
    'effilocherez',
    'effilocheurs',
    'effilocheuse',
    'effilochions',
    'effilochures',
    'efflanquâmes',
    'efflanquasse',
    'efflanquâtes',
    'efflanquerai',
    'efflanqueras',
    'efflanquerez',
    'efflanquions',
    'effleuraient',
    'effleurasses',
    'effleurement',
    'effleurerais',
    'effleurerait',
    'effleurèrent',
    'effleureriez',
    'effleurerons',
    'effleureront',
    'effleurirais',
    'effleurirait',
    'effleurirent',
    'effleuririez',
    'effleurirons',
    'effleuriront',
    'effleurisses',
    'effleurissez',
    'effloraisons',
    'efflorescent',
    'effluvassent',
    'effluvassiez',
    'effluverions',
    'effondraient',
    'effondrasses',
    'effondrement',
    'effondrerais',
    'effondrerait',
    'effondrèrent',
    'effondreriez',
    'effondrerons',
    'effondreront',
    'efforçassiez',
    'efforcerions',
    'effraierions',
    'effrangeâmes',
    'effrangeasse',
    'effrangeâtes',
    'effrangerais',
    'effrangerait',
    'effrangèrent',
    'effrangeriez',
    'effrangerons',
    'effrangeront',
    'effrayassent',
    'effrayassiez',
    'effrayerions',
    'effritassent',
    'effritassiez',
    'effritements',
    'effriterions',
    'effrontément',
    'effronteries',
    'égaillassiez',
    'égaillerions',
    'égalisassent',
    'égalisassiez',
    'égalisateurs',
    'égalisations',
    'égalisatrice',
    'égaliserions',
    'égalitarisme',
    'égalitariste',
    'égermassions',
    'égermeraient',
    'égocentrique',
    'égocentrisme',
    'égorgeassent',
    'égorgeassiez',
    'égorgeraient',
    'égosilleriez',
    'égosillerons',
    'égouttassent',
    'égouttassiez',
    'égouttements',
    'égoutterions',
    'égrainassent',
    'égrainassiez',
    'égrainerions',
    'égrappassent',
    'égrappassiez',
    'égrapperions',
    'égratignâmes',
    'égratignasse',
    'égratignâtes',
    'égratignerai',
    'égratigneras',
    'égratignerez',
    'égratignions',
    'égratignures',
    'égravillonna',
    'égravillonne',
    'égravillonné',
    'égrenassions',
    'égrèneraient',
    'égrisassions',
    'égriseraient',
    'égrugeassent',
    'égrugeassiez',
    'égrugeraient',
    'égueulements',
    'égyptologies',
    'égyptologues',
    'éjaculassent',
    'éjaculassiez',
    'éjaculations',
    'éjaculerions',
    'éjectassions',
    'éjecteraient',
    'éjointassent',
    'éjointassiez',
    'éjointerions',
    'élaborassent',
    'élaborassiez',
    'élaborations',
    'élaborerions',
    'élaguassions',
    'élagueraient',
    'élançassions',
    'élanceraient',
    'élargiraient',
    'élargissions',
    'électriciens',
    'électricités',
    'électrifiais',
    'électrifiait',
    'électrifiant',
    'électrifiées',
    'électrifient',
    'électrifiera',
    'électrifiiez',
    'électrifions',
    'électrisable',
    'électrisâmes',
    'électrisante',
    'électrisants',
    'électrisasse',
    'électrisâtes',
    'électriserai',
    'électriseras',
    'électriserez',
    'électrisions',
    'électrochocs',
    'électrocutai',
    'électrocutas',
    'électrocutât',
    'électrocutée',
    'électrocuter',
    'électrocutes',
    'électrocutés',
    'électrocutez',
    'électrogènes',
    'électrologie',
    'électrolysai',
    'électrolysas',
    'électrolysât',
    'électrolysée',
    'électrolyser',
    'électrolyses',
    'électrolysés',
    'électrolysez',
    'électrolytes',
    'électronique',
    'électronisai',
    'électronisas',
    'électronisât',
    'électronisée',
    'électroniser',
    'électronises',
    'électronisés',
    'électronisez',
    'électrophone',
    'électroscope',
    'élégissaient',
    'élémentaires',
    'éléphanteaux',
    'éléphantines',
    'éligibilités',
    'éliminassent',
    'éliminassiez',
    'éliminateurs',
    'éliminations',
    'éliminatoire',
    'éliminatrice',
    'éliminerions',
    'élinguassent',
    'élinguassiez',
    'élinguerions',
    'élisabéthain',
    'ellipsoïdale',
    'ellipsoïdaux',
    'éloignassent',
    'éloignassiez',
    'éloignements',
    'éloignerions',
    'élongeassent',
    'élongeassiez',
    'élongeraient',
    'élucidassent',
    'élucidassiez',
    'élucidations',
    'éluciderions',
    'élucubraient',
    'élucubrasses',
    'élucubration',
    'élucubrerais',
    'élucubrerait',
    'élucubrèrent',
    'élucubreriez',
    'élucubrerons',
    'élucubreront',
    'elzévirienne',
    'émaciassions',
    'émaillassent',
    'émaillassiez',
    'émaillerions',
    'émancipaient',
    'émancipasses',
    'émancipateur',
    'émancipation',
    'émanciperais',
    'émanciperait',
    'émancipèrent',
    'émanciperiez',
    'émanciperons',
    'émanciperont',
    'émargeassent',
    'émargeassiez',
    'émargeraient',
    'émasculaient',
    'émasculasses',
    'émasculation',
    'émasculerais',
    'émasculerait',
    'émasculèrent',
    'émasculeriez',
    'émasculerons',
    'émasculeront',
    'emballassent',
    'emballassiez',
    'emballements',
    'emballerions',
    'emballottais',
    'emballottait',
    'emballottant',
    'emballottées',
    'emballottent',
    'emballottera',
    'emballottiez',
    'emballottons',
    'embarbouilla',
    'embarbouille',
    'embarbouillé',
    'embarcadères',
    'embarcations',
    'embardassent',
    'embardassiez',
    'embarderions',
    'embarquaient',
    'embarquasses',
    'embarquement',
    'embarquerais',
    'embarquerait',
    'embarquèrent',
    'embarqueriez',
    'embarquerons',
    'embarqueront',
    'embarrassais',
    'embarrassait',
    'embarrassant',
    'embarrassées',
    'embarrassent',
    'embarrassera',
    'embarrassiez',
    'embarrassons',
    'embarrerions',
    'embastillais',
    'embastillait',
    'embastillant',
    'embastillées',
    'embastillent',
    'embastillera',
    'embastilliez',
    'embastillons',
    'embastionnai',
    'embastionnas',
    'embastionnât',
    'embastionnée',
    'embastionner',
    'embastionnes',
    'embastionnés',
    'embastionnez',
    'embattissent',
    'embattissiez',
    'embattraient',
    'embauchaient',
    'embauchasses',
    'embaucherais',
    'embaucherait',
    'embauchèrent',
    'embaucheriez',
    'embaucherons',
    'embaucheront',
    'embaucheuses',
    'embaumassent',
    'embaumassiez',
    'embaumements',
    'embaumerions',
    'embecquaient',
    'embecquasses',
    'embecquerais',
    'embecquerait',
    'embecquèrent',
    'embecqueriez',
    'embecquerons',
    'embecqueront',
    'émbéguinâmes',
    'émbéguinâtes',
    'émbéguinerez',
    'émbéguinions',
    'embellirions',
    'embellissais',
    'embellissait',
    'embellissant',
    'embellissent',
    'embellissiez',
    'embellissons',
    'embêtassions',
    'embêteraient',
    'embidonnâmes',
    'embidonnasse',
    'embidonnâtes',
    'embidonnerai',
    'embidonneras',
    'embidonnerez',
    'embidonnions',
    'emblavassent',
    'emblavassiez',
    'emblaverions',
    'emblématique',
    'embobelinais',
    'embobelinait',
    'embobelinant',
    'embobelinées',
    'embobelinent',
    'embobelinera',
    'embobeliniez',
    'embobelinons',
    'embobinaient',
    'embobinasses',
    'embobinerais',
    'embobinerait',
    'embobinèrent',
    'embobineriez',
    'embobinerons',
    'embobineront',
    'emboîtassent',
    'emboîtassiez',
    'emboîtements',
    'emboîterions',
    'embossassent',
    'embossassiez',
    'embosserions',
    'embottelâmes',
    'embottelasse',
    'embottelâtes',
    'embottelions',
    'embottellent',
    'embottellera',
    'embouassions',
    'embouchaient',
    'embouchasses',
    'emboucherais',
    'emboucherait',
    'embouchèrent',
    'emboucheriez',
    'emboucherons',
    'emboucheront',
    'emboueraient',
    'embouquaient',
    'embouquasses',
    'embouquement',
    'embouquerais',
    'embouquerait',
    'embouquèrent',
    'embouqueriez',
    'embouquerons',
    'embouqueront',
    'embourbaient',
    'embourbasses',
    'embourberais',
    'embourberait',
    'embourbèrent',
    'embourberiez',
    'embourberons',
    'embourberont',
    'embourgeoisa',
    'embourgeoise',
    'embourgeoisé',
    'embourraient',
    'embourrasses',
    'embourrerais',
    'embourrerait',
    'embourrèrent',
    'embourreriez',
    'embourrerons',
    'embourreront',
    'embouteillai',
    'embouteillas',
    'embouteillât',
    'embouteillée',
    'embouteiller',
    'embouteilles',
    'embouteillés',
    'embouteillez',
    'emboutirions',
    'emboutissage',
    'emboutissais',
    'emboutissait',
    'emboutissant',
    'emboutissent',
    'emboutissiez',
    'emboutissoir',
    'emboutissons',
    'embraierions',
    'embranchâmes',
    'embranchasse',
    'embranchâtes',
    'embrancherai',
    'embrancheras',
    'embrancherez',
    'embranchions',
    'embraquaient',
    'embraquasses',
    'embraquerais',
    'embraquerait',
    'embraquèrent',
    'embraqueriez',
    'embraquerons',
    'embraqueront',
    'embrasassent',
    'embrasassiez',
    'embrasements',
    'embraserions',
    'embrassaient',
    'embrassasses',
    'embrassement',
    'embrasserais',
    'embrasserait',
    'embrassèrent',
    'embrasseriez',
    'embrasserons',
    'embrasseront',
    'embrasseuses',
    'embrayassent',
    'embrayassiez',
    'embrayerions',
    'embrelassent',
    'embrelassiez',
    'embrèlerions',
    'embrevassent',
    'embrevassiez',
    'embrèvements',
    'embrèverions',
    'embrigadâmes',
    'embrigadasse',
    'embrigadâtes',
    'embrigaderai',
    'embrigaderas',
    'embrigaderez',
    'embrigadions',
    'embringuâmes',
    'embringuasse',
    'embringuâtes',
    'embringuerai',
    'embringueras',
    'embringuerez',
    'embringuions',
    'embrocations',
    'embrochaient',
    'embrochasses',
    'embrochement',
    'embrocherais',
    'embrocherait',
    'embrochèrent',
    'embrocheriez',
    'embrocherons',
    'embrocheront',
    'embronchâmes',
    'embronchasse',
    'embronchâtes',
    'embroncherai',
    'embroncheras',
    'embroncherez',
    'embronchions',
    'embrouillage',
    'embrouillais',
    'embrouillait',
    'embrouillant',
    'embrouillées',
    'embrouillent',
    'embrouillera',
    'embrouilliez',
    'embrouillons',
    'embrumassent',
    'embrumassiez',
    'embrumerions',
    'embrunirions',
    'embrunissais',
    'embrunissait',
    'embrunissant',
    'embrunissent',
    'embrunissiez',
    'embrunissons',
    'embryogénies',
    'embryologies',
    'embryonnaire',
    'embryopathie',
    'embusquaient',
    'embusquasses',
    'embusquerais',
    'embusquerait',
    'embusquèrent',
    'embusqueriez',
    'embusquerons',
    'embusqueront',
    'éméchassions',
    'émécheraient',
    'émergeassent',
    'émergeassiez',
    'émergeraient',
    'émerillonnai',
    'émerillonnas',
    'émerillonnât',
    'émerillonnée',
    'émerillonner',
    'émerillonnes',
    'émerillonnés',
    'émerillonnez',
    'émerisassent',
    'émerisassiez',
    'émeriserions',
    'émerveillais',
    'émerveillait',
    'émerveillant',
    'émerveillées',
    'émerveillent',
    'émerveillera',
    'émerveilliez',
    'émerveillons',
    'émiettassent',
    'émiettassiez',
    'émiettements',
    'émietterions',
    'émigrassions',
    'émigreraient',
    'éminçassions',
    'éminceraient',
    'emmagasinage',
    'emmagasinais',
    'emmagasinait',
    'emmagasinant',
    'emmagasinées',
    'emmagasinent',
    'emmagasinera',
    'emmagasiniez',
    'emmagasinons',
    'emmaillotais',
    'emmaillotait',
    'emmaillotant',
    'emmaillotées',
    'emmaillotent',
    'emmaillotera',
    'emmaillotiez',
    'emmaillotons',
    'emmanchaient',
    'emmanchasses',
    'emmanchement',
    'emmancherais',
    'emmancherait',
    'emmanchèrent',
    'emmancheriez',
    'emmancherons',
    'emmancheront',
    'emmargeaient',
    'emmargeasses',
    'emmargerions',
    'emmêlassions',
    'emmêleraient',
    'emménageâmes',
    'emménageasse',
    'emménageâtes',
    'emménagement',
    'emménagerais',
    'emménagerait',
    'emménagèrent',
    'emménageriez',
    'emménagerons',
    'emménageront',
    'emménagogues',
    'emmenassions',
    'emmèneraient',
    'emmerdassent',
    'emmerdassiez',
    'emmerdements',
    'emmerderions',
    'emmétrassent',
    'emmétrassiez',
    'emmétrerions',
    'emmiellaient',
    'emmiellasses',
    'emmiellerais',
    'emmiellerait',
    'emmiellèrent',
    'emmielleriez',
    'emmiellerons',
    'emmielleront',
    'emmitonnâmes',
    'emmitonnasse',
    'emmitonnâtes',
    'emmitonnerai',
    'emmitonneras',
    'emmitonnerez',
    'emmitonnions',
    'emmitouflais',
    'emmitouflait',
    'emmitouflant',
    'emmitouflées',
    'emmitouflent',
    'emmitouflera',
    'emmitoufliez',
    'emmitouflons',
    'emmortaisais',
    'emmortaisait',
    'emmortaisant',
    'emmortaisées',
    'emmortaisent',
    'emmortaisera',
    'emmortaisiez',
    'emmortaisons',
    'emmouscailla',
    'emmouscaille',
    'emmouscaillé',
    'emmurassions',
    'emmureraient',
    'émondassions',
    'émonderaient',
    'émorfilaient',
    'émorfilasses',
    'émorfilerais',
    'émorfilerait',
    'émorfilèrent',
    'émorfileriez',
    'émorfilerons',
    'émorfileront',
    'émotionnable',
    'émotionnâmes',
    'émotionnasse',
    'émotionnâtes',
    'émotionnelle',
    'émotionnerai',
    'émotionneras',
    'émotionnerez',
    'émotionnions',
    'émottassions',
    'émotteraient',
    'émouchassent',
    'émouchassiez',
    'émoucherions',
    'émouchetâmes',
    'émouchetasse',
    'émouchetâtes',
    'émouchèterai',
    'émouchèteras',
    'émouchèterez',
    'émouchetions',
    'émoulussions',
    'émoussassent',
    'émoussassiez',
    'émousserions',
    'émoustillais',
    'émoustillait',
    'émoustillant',
    'émoustillées',
    'émoustillent',
    'émoustillera',
    'émoustilliez',
    'émoustillons',
    'empaillaient',
    'empaillasses',
    'empaillement',
    'empaillèrent',
    'empailleuses',
    'empalassions',
    'empaleraient',
    'empalmassent',
    'empalmassiez',
    'empalmerions',
    'empanachâmes',
    'empanachasse',
    'empanachâtes',
    'empanacherai',
    'empanacheras',
    'empanacherez',
    'empanachions',
    'empannassent',
    'empannassiez',
    'empannerions',
    'empapillotai',
    'empapillotas',
    'empapillotât',
    'empapillotée',
    'empapilloter',
    'empapillotes',
    'empapillotés',
    'empapillotez',
    'empaquetages',
    'empaquetâmes',
    'empaquetasse',
    'empaquetâtes',
    'empaqueteurs',
    'empaqueteuse',
    'empaquetions',
    'empaquettent',
    'empaquettera',
    'emparassions',
    'emparquaient',
    'emparquasses',
    'emparquerais',
    'emparquerait',
    'emparquèrent',
    'emparqueriez',
    'emparquerons',
    'emparqueront',
    'empâtassions',
    'empâteraient',
    'empâtrerions',
    'empattassent',
    'empattassiez',
    'empattements',
    'empatterions',
    'empaumassent',
    'empaumassiez',
    'empaumerions',
    'empêchassent',
    'empêchassiez',
    'empêchements',
    'empêcherions',
    'empeignaient',
    'empeignasses',
    'empeignerais',
    'empeignerait',
    'empeignèrent',
    'empeigneriez',
    'empeignerons',
    'empeigneront',
    'empênassions',
    'empêneraient',
    'empennassent',
    'empennassiez',
    'empennerions',
    'emperchaient',
    'emperchasses',
    'empercherais',
    'empercherait',
    'emperchèrent',
    'empercheriez',
    'empercherons',
    'empercheront',
    'emperlassent',
    'emperlassiez',
    'emperlerions',
    'empesassions',
    'empèseraient',
    'empestassent',
    'empestassiez',
    'empesterions',
    'empêtrassent',
    'empêtrassiez',
    'empêtrerions',
    'empiècements',
    'empierraient',
    'empierrasses',
    'empierrement',
    'empierrerais',
    'empierrerait',
    'empierrèrent',
    'empierreriez',
    'empierrerons',
    'empierreront',
    'empiétassent',
    'empiétassiez',
    'empiétements',
    'empiètements',
    'empiéterions',
    'empiffreriez',
    'empiffrerons',
    'empilassions',
    'empileraient',
    'empirassions',
    'empireraient',
    'emplacements',
    'emplaillerai',
    'emplailleras',
    'emplaillerez',
    'emplâtraient',
    'emplâtrasses',
    'emplâtrèrent',
    'emplissaient',
    'emploierions',
    'employassent',
    'employassiez',
    'emplumassent',
    'emplumassiez',
    'emplumerions',
    'empochassent',
    'empochassiez',
    'empocherions',
    'empoignaient',
    'empoignantes',
    'empoignasses',
    'empoignerais',
    'empoignerait',
    'empoignèrent',
    'empoigneriez',
    'empoignerons',
    'empoigneront',
    'empoisonnais',
    'empoisonnait',
    'empoisonnant',
    'empoisonnées',
    'empoisonnent',
    'empoisonnera',
    'empoisonneur',
    'empoisonniez',
    'empoisonnons',
    'empoissaient',
    'empoissasses',
    'empoisserais',
    'empoisserait',
    'empoissèrent',
    'empoisseriez',
    'empoisserons',
    'empoisseront',
    'empoissonnai',
    'empoissonnas',
    'empoissonnât',
    'empoissonnée',
    'empoissonner',
    'empoissonnes',
    'empoissonnés',
    'empoissonnez',
    'emportassent',
    'emportassiez',
    'emportements',
    'emporterions',
    'empotassions',
    'empoteraient',
    'empourprâmes',
    'empourprasse',
    'empourprâtes',
    'empourprerai',
    'empourpreras',
    'empourprerez',
    'empourprions',
    'empoussiérai',
    'empoussiéras',
    'empoussiérât',
    'empoussiérée',
    'empoussiérer',
    'empoussiérés',
    'empoussières',
    'empoussiérez',
    'empreignîmes',
    'empreignions',
    'empreignisse',
    'empreignîtes',
    'empreindrais',
    'empreindrait',
    'empreindriez',
    'empreindrons',
    'empreindront',
    'empressement',
    'empresseriez',
    'empresserons',
    'emprésurâmes',
    'emprésurasse',
    'emprésurâtes',
    'emprésurerai',
    'emprésureras',
    'emprésurerez',
    'emprésurions',
    'emprisonnais',
    'emprisonnait',
    'emprisonnant',
    'emprisonnées',
    'emprisonnent',
    'emprisonnera',
    'emprisonniez',
    'emprisonnons',
    'empruntaient',
    'empruntasses',
    'emprunterais',
    'emprunterait',
    'empruntèrent',
    'emprunteriez',
    'emprunterons',
    'emprunteront',
    'emprunteuses',
    'empuantirais',
    'empuantirait',
    'empuantirent',
    'empuantiriez',
    'empuantirons',
    'empuantiront',
    'empuantisses',
    'empuantissez',
    'émulsifiable',
    'émulsifiâmes',
    'émulsifiasse',
    'émulsifiâtes',
    'émulsifierai',
    'émulsifieras',
    'émulsifierez',
    'émulsifiions',
    'émulsionnais',
    'émulsionnait',
    'émulsionnant',
    'émulsionnées',
    'émulsionnent',
    'émulsionnera',
    'émulsionniez',
    'émulsionnons',
    'enamoureriez',
    'énamoureriez',
    'enamourerons',
    'énamourerons',
    'énantiotrope',
    'encabanaient',
    'encabanasses',
    'encabanerais',
    'encabanerait',
    'encabanèrent',
    'encabaneriez',
    'encabanerons',
    'encabaneront',
    'encadrassent',
    'encadrassiez',
    'encadrements',
    'encadrerions',
    'encageassent',
    'encageassiez',
    'encageraient',
    'encaissables',
    'encaissaient',
    'encaissasses',
    'encaissement',
    'encaisserais',
    'encaisserait',
    'encaissèrent',
    'encaisseriez',
    'encaisserons',
    'encaisseront',
    'encanaillais',
    'encanaillait',
    'encanaillant',
    'encanaillées',
    'encanaillent',
    'encanaillera',
    'encanailliez',
    'encanaillons',
    'encapuchonna',
    'encapuchonne',
    'encapuchonné',
    'encaquassent',
    'encaquassiez',
    'encaquements',
    'encaquerions',
    'encartassent',
    'encartassiez',
    'encarterions',
    'encartonnais',
    'encartonnait',
    'encartonnant',
    'encartonnées',
    'encartonnent',
    'encartonnera',
    'encartonniez',
    'encartonnons',
    'encartouchai',
    'encartouchas',
    'encartouchât',
    'encartouchée',
    'encartoucher',
    'encartouches',
    'encartouchés',
    'encartouchez',
    'encasernâmes',
    'encasernasse',
    'encasernâtes',
    'encasernerai',
    'encaserneras',
    'encasernerez',
    'encasernions',
    'encastelâmes',
    'encastelâtes',
    'encastèlerez',
    'encastelions',
    'encastelures',
    'encastrables',
    'encastraient',
    'encastrasses',
    'encastrement',
    'encastrerais',
    'encastrerait',
    'encastrèrent',
    'encastreriez',
    'encastrerons',
    'encastreront',
    'encaustiquai',
    'encaustiquas',
    'encaustiquât',
    'encaustiquée',
    'encaustiquer',
    'encaustiques',
    'encaustiqués',
    'encaustiquez',
    'encavassions',
    'encaveraient',
    'encensassent',
    'encensassiez',
    'encensements',
    'encenserions',
    'encéphalites',
    'encerclaient',
    'encerclasses',
    'encerclement',
    'encerclerais',
    'encerclerait',
    'encerclèrent',
    'encercleriez',
    'encerclerons',
    'encercleront',
    'enchaînaient',
    'enchaînasses',
    'enchaînement',
    'enchaînerais',
    'enchaînerait',
    'enchaînèrent',
    'enchaîneriez',
    'enchaînerons',
    'enchaîneront',
    'enchantaient',
    'enchantasses',
    'enchantement',
    'enchanterais',
    'enchanterait',
    'enchantèrent',
    'enchanteriez',
    'enchanterons',
    'enchanteront',
    'enchaperonna',
    'enchaperonne',
    'enchaperonné',
    'encharnaient',
    'encharnasses',
    'encharnerais',
    'encharnerait',
    'encharnèrent',
    'encharneriez',
    'encharnerons',
    'encharneront',
    'enchâssaient',
    'enchâssasses',
    'enchâssement',
    'enchâsserais',
    'enchâsserait',
    'enchâssèrent',
    'enchâsseriez',
    'enchâsserons',
    'enchâsseront',
    'enchatonnais',
    'enchatonnait',
    'enchatonnant',
    'enchatonnées',
    'enchatonnent',
    'enchatonnera',
    'enchatonniez',
    'enchatonnons',
    'enchaussâmes',
    'enchaussasse',
    'enchaussâtes',
    'enchausserai',
    'enchausseras',
    'enchausserez',
    'enchaussions',
    'enchemisages',
    'enchemisâmes',
    'enchemisasse',
    'enchemisâtes',
    'enchemiserai',
    'enchemiseras',
    'enchemiserez',
    'enchemisions',
    'enchéririons',
    'enchérissais',
    'enchérissait',
    'enchérissant',
    'enchérissent',
    'enchérisseur',
    'enchérissiez',
    'enchérissons',
    'enchevauchai',
    'enchevauchas',
    'enchevauchât',
    'enchevauchée',
    'enchevaucher',
    'enchevauches',
    'enchevauchés',
    'enchevauchez',
    'enchevêtrais',
    'enchevêtrait',
    'enchevêtrant',
    'enchevêtrées',
    'enchevêtrent',
    'enchevêtrera',
    'enchevêtriez',
    'enchevêtrons',
    'enchevêtrure',
    'enchifrenais',
    'enchifrenait',
    'enchifrenant',
    'enchifrenées',
    'enchifrènent',
    'enchifrènera',
    'enchifreniez',
    'enchifrenons',
    'encirassions',
    'encireraient',
    'enclavassent',
    'enclavassiez',
    'enclavements',
    'enclaverions',
    'enclenchâmes',
    'enclenchasse',
    'enclenchâtes',
    'enclencherai',
    'enclencheras',
    'enclencherez',
    'enclenchions',
    'encliquetage',
    'encliquetais',
    'encliquetait',
    'encliquetant',
    'encliquetées',
    'encliquetiez',
    'encliquetons',
    'encliquettes',
    'encloîtrâmes',
    'encloîtrasse',
    'encloîtrâtes',
    'encloîtrerai',
    'encloîtreras',
    'encloîtrerez',
    'encloîtrions',
    'enclouassent',
    'enclouassiez',
    'enclouerions',
    'encochassent',
    'encochassiez',
    'encocherions',
    'encodassions',
    'encoderaient',
    'encoffraient',
    'encoffrasses',
    'encoffrerais',
    'encoffrerait',
    'encoffrèrent',
    'encoffreriez',
    'encoffrerons',
    'encoffreront',
    'encollassent',
    'encollassiez',
    'encollerions',
    'encombraient',
    'encombrantes',
    'encombrasses',
    'encombrement',
    'encombrerais',
    'encombrerait',
    'encombrèrent',
    'encombreriez',
    'encombrerons',
    'encombreront',
    'encordassent',
    'encordassiez',
    'encorderions',
    'encornassent',
    'encornassiez',
    'encornerions',
    'encourageais',
    'encourageait',
    'encourageant',
    'encourageons',
    'encouragerai',
    'encourageras',
    'encouragerez',
    'encouragions',
    'encourraient',
    'encourussent',
    'encourussiez',
    'encrassaient',
    'encrassasses',
    'encrassement',
    'encrasserais',
    'encrasserait',
    'encrassèrent',
    'encrasseriez',
    'encrasserons',
    'encrasseront',
    'encrêpassent',
    'encrêpassiez',
    'encrêperions',
    'encroûtaient',
    'encroûtasses',
    'encroûtement',
    'encroûterais',
    'encroûterait',
    'encroûtèrent',
    'encroûteriez',
    'encroûterons',
    'encroûteront',
    'encuvassions',
    'encuveraient',
    'encyclopédie',
    'endaubassent',
    'endaubassiez',
    'endauberions',
    'endentassent',
    'endentassiez',
    'endenterions',
    'endettassent',
    'endettassiez',
    'endettements',
    'endetterions',
    'endeuillâmes',
    'endeuillasse',
    'endeuillâtes',
    'endeuillerai',
    'endeuilleras',
    'endeuillerez',
    'endeuillions',
    'endiablaient',
    'endiablasses',
    'endiablerais',
    'endiablerait',
    'endiablèrent',
    'endiableriez',
    'endiablerons',
    'endiableront',
    'endiguassent',
    'endiguassiez',
    'endiguements',
    'endiguerions',
    'endimanchais',
    'endimanchait',
    'endimanchant',
    'endimanchées',
    'endimanchent',
    'endimanchera',
    'endimanchiez',
    'endimanchons',
    'endivisionna',
    'endivisionne',
    'endivisionné',
    'endocardites',
    'endocriniens',
    'endoctrinais',
    'endoctrinait',
    'endoctrinant',
    'endoctrinées',
    'endoctrinent',
    'endoctrinera',
    'endoctriniez',
    'endoctrinons',
    'endolorirais',
    'endolorirait',
    'endolorirent',
    'endoloririez',
    'endolorirons',
    'endoloriront',
    'endolorisses',
    'endolorissez',
    'endométrites',
    'endommageais',
    'endommageait',
    'endommageant',
    'endommageons',
    'endommagerai',
    'endommageras',
    'endommagerez',
    'endommagions',
    'endormirions',
    'endormissent',
    'endormissiez',
    'endoscopique',
    'endosmomètre',
    'endossassent',
    'endossassiez',
    'endossataire',
    'endossements',
    'endosserions',
    'endothéliale',
    'endothéliaux',
    'endothéliums',
    'enduisissent',
    'enduisissiez',
    'endurassions',
    'endurcirions',
    'endurcissais',
    'endurcissait',
    'endurcissant',
    'endurcissent',
    'endurcissiez',
    'endurcissons',
    'endureraient',
    'énergétiques',
    'énergisantes',
    'énervassions',
    'énerveraient',
    'enfaîtassent',
    'enfaîtassiez',
    'enfaîterions',
    'enfantassent',
    'enfantassiez',
    'enfantements',
    'enfanterions',
    'enfantillage',
    'enfarinaient',
    'enfarinasses',
    'enfarinerais',
    'enfarinerait',
    'enfarinèrent',
    'enfarineriez',
    'enfarinerons',
    'enfarineront',
    'enfermassent',
    'enfermassiez',
    'enfermerions',
    'enferrassent',
    'enferrassiez',
    'enferrerions',
    'enfichassent',
    'enfichassiez',
    'enficherions',
    'enfiellaient',
    'enfiellasses',
    'enfiellerais',
    'enfiellerait',
    'enfiellèrent',
    'enfielleriez',
    'enfiellerons',
    'enfielleront',
    'enfiévraient',
    'enfiévrasses',
    'enfiévrerais',
    'enfiévrerait',
    'enfiévrèrent',
    'enfiévreriez',
    'enfiévrerons',
    'enfiévreront',
    'enfilassions',
    'enfileraient',
    'enflammaient',
    'enflammasses',
    'enflammerais',
    'enflammerait',
    'enflammèrent',
    'enflammeriez',
    'enflammerons',
    'enflammeront',
    'enfléchaient',
    'enfléchasses',
    'enflécherais',
    'enflécherait',
    'enfléchèrent',
    'enflécheriez',
    'enflécherons',
    'enflécheront',
    'enfleuraient',
    'enfleurasses',
    'enfleurerais',
    'enfleurerait',
    'enfleurèrent',
    'enfleureriez',
    'enfleurerons',
    'enfleureront',
    'enfonçassent',
    'enfonçassiez',
    'enfoncements',
    'enfoncerions',
    'enforcirions',
    'enforcissais',
    'enforcissait',
    'enforcissant',
    'enforcissent',
    'enforcissiez',
    'enforcissons',
    'enfouiraient',
    'enfouisseurs',
    'enfouissions',
    'enfourchâmes',
    'enfourchasse',
    'enfourchâtes',
    'enfourcherai',
    'enfourcheras',
    'enfourcherez',
    'enfourchions',
    'enfourchures',
    'enfournaient',
    'enfournasses',
    'enfournement',
    'enfournerais',
    'enfournerait',
    'enfournèrent',
    'enfourneriez',
    'enfournerons',
    'enfourneront',
    'enfreignîmes',
    'enfreignions',
    'enfreignisse',
    'enfreignîtes',
    'enfreindrais',
    'enfreindrait',
    'enfreindriez',
    'enfreindrons',
    'enfreindront',
    'enfumassions',
    'enfumeraient',
    'enfutaillais',
    'enfutaillait',
    'enfutaillant',
    'enfutaillées',
    'enfutaillent',
    'enfutaillera',
    'enfutailliez',
    'enfutaillons',
    'enfûtassions',
    'enfûteraient',
    'engageassent',
    'engageassiez',
    'engageraient',
    'engainassent',
    'engainassiez',
    'engainerions',
    'engamassions',
    'engameraient',
    'engargoussai',
    'engargoussas',
    'engargoussât',
    'engargoussée',
    'engargousser',
    'engargousses',
    'engargoussés',
    'engargoussez',
    'engavassions',
    'engaveraient',
    'engazonnâmes',
    'engazonnasse',
    'engazonnâtes',
    'engazonnerai',
    'engazonneras',
    'engazonnerez',
    'engazonnions',
    'engendraient',
    'engendrasses',
    'engendrement',
    'engendrerais',
    'engendrerait',
    'engendrèrent',
    'engendreriez',
    'engendrerons',
    'engendreront',
    'engerbassent',
    'engerbassiez',
    'engerberions',
    'engineerings',
    'englaçassent',
    'englaçassiez',
    'englacerions',
    'englobassent',
    'englobassiez',
    'engloberions',
    'engloutirais',
    'engloutirait',
    'engloutirent',
    'engloutiriez',
    'engloutirons',
    'engloutiront',
    'engloutisses',
    'engloutissez',
    'engluassions',
    'englueraient',
    'engobassions',
    'engoberaient',
    'engommassent',
    'engommassiez',
    'engommerions',
    'engonçassent',
    'engonçassiez',
    'engoncerions',
    'engorgeaient',
    'engorgeasses',
    'engorgements',
    'engorgerions',
    'engouassions',
    'engouffrâmes',
    'engouffrasse',
    'engouffrâtes',
    'engouffrerai',
    'engouffreras',
    'engouffrerez',
    'engouffrions',
    'engoulassent',
    'engoulassiez',
    'engoulerions',
    'engoulevents',
    'engourdirais',
    'engourdirait',
    'engourdirent',
    'engourdiriez',
    'engourdirons',
    'engourdiront',
    'engourdisses',
    'engourdissez',
    'engraissages',
    'engraissâmes',
    'engraissasse',
    'engraissâtes',
    'engraisserai',
    'engraisseras',
    'engraisserez',
    'engraissions',
    'engrangeâmes',
    'engrangeasse',
    'engrangeâtes',
    'engrangement',
    'engrangerais',
    'engrangerait',
    'engrangèrent',
    'engrangeriez',
    'engrangerons',
    'engrangeront',
    'engravassent',
    'engravassiez',
    'engraverions',
    'engrenassent',
    'engrenassiez',
    'engrènerions',
    'engrossaient',
    'engrossasses',
    'engrosserais',
    'engrosserait',
    'engrossèrent',
    'engrosseriez',
    'engrosserons',
    'engrosseront',
    'engrumelâmes',
    'engrumelasse',
    'engrumelâtes',
    'engrumelions',
    'engrumellent',
    'engrumellera',
    'engueulaient',
    'engueulasses',
    'engueulerais',
    'engueulerait',
    'engueulèrent',
    'engueuleriez',
    'engueulerons',
    'engueuleront',
    'enguirlandai',
    'enguirlandas',
    'enguirlandât',
    'enguirlandée',
    'enguirlander',
    'enguirlandes',
    'enguirlandés',
    'enguirlandez',
    'enhardirions',
    'enhardissais',
    'enhardissait',
    'enhardissant',
    'enhardissent',
    'enhardissiez',
    'enhardissons',
    'enharmonique',
    'enharnachais',
    'enharnachait',
    'enharnachant',
    'enharnachées',
    'enharnachent',
    'enharnachera',
    'enharnachiez',
    'enharnachons',
    'enherbassent',
    'enherbassiez',
    'enherberions',
    'éniellassent',
    'éniellassiez',
    'éniellerions',
    'énigmatiques',
    'enivrassions',
    'enivreraient',
    'enjambassent',
    'enjambassiez',
    'enjambements',
    'enjamberions',
    'enjavelaient',
    'enjavelasses',
    'enjavelèrent',
    'enjavellerai',
    'enjavelleras',
    'enjavellerez',
    'enjoignaient',
    'enjoignirent',
    'enjoignisses',
    'enjoindrions',
    'enjôlassions',
    'enjôleraient',
    'enjolivaient',
    'enjolivasses',
    'enjolivement',
    'enjoliverais',
    'enjoliverait',
    'enjolivèrent',
    'enjoliveriez',
    'enjoliverons',
    'enjoliveront',
    'enjonçassent',
    'enjonçassiez',
    'enjoncerions',
    'enjouassions',
    'enjoueraient',
    'enjuguassent',
    'enjuguassiez',
    'enjuguerions',
    'enjuivassent',
    'enjuivassiez',
    'enjuiverions',
    'enjuponnâmes',
    'enjuponnasse',
    'enjuponnâtes',
    'enjuponnerai',
    'enjuponneras',
    'enjuponnerez',
    'enjuponnions',
    'enkystassiez',
    'enkystements',
    'enkysterions',
    'enlaçassions',
    'enlaceraient',
    'enlaidirions',
    'enlaidissais',
    'enlaidissait',
    'enlaidissant',
    'enlaidissent',
    'enlaidissiez',
    'enlaidissons',
    'enlevassions',
    'enlèveraient',
    'enliassaient',
    'enliassasses',
    'enliasserais',
    'enliasserait',
    'enliassèrent',
    'enliasseriez',
    'enliasserons',
    'enliasseront',
    'enlignassent',
    'enlignassiez',
    'enlignerions',
    'enlisassions',
    'enliseraient',
    'enluminaient',
    'enluminasses',
    'enluminerais',
    'enluminerait',
    'enluminèrent',
    'enlumineriez',
    'enluminerons',
    'enlumineront',
    'enlumineuses',
    'ennéagonales',
    'enneigeaient',
    'enneigeasses',
    'enneigements',
    'enneigerions',
    'ennoblirions',
    'ennoblissais',
    'ennoblissait',
    'ennoblissant',
    'ennoblissent',
    'ennoblissiez',
    'ennoblissons',
    'ennuageaient',
    'ennuageasses',
    'ennuagerions',
    'ennuieraient',
    'ennuyassions',
    'énonçassions',
    'énonceraient',
    'énonciations',
    'énonciatives',
    'enorgueillie',
    'enorgueillir',
    'enorgueillis',
    'enorgueillit',
    'enorgueillît',
    'enquêtassent',
    'enquêtassiez',
    'enquêterions',
    'enquiquinais',
    'enquiquinait',
    'enquiquinant',
    'enquiquinées',
    'enquiquinent',
    'enquiquinera',
    'enquiquineur',
    'enquiquiniez',
    'enquiquinons',
    'enracinaient',
    'enracinasses',
    'enracinement',
    'enracinerais',
    'enracinerait',
    'enracinèrent',
    'enracineriez',
    'enracinerons',
    'enracineront',
    'enrageassent',
    'enrageassiez',
    'enrageraient',
    'enraieraient',
    'enraillaient',
    'enraillasses',
    'enraillerais',
    'enraillerait',
    'enraillèrent',
    'enrailleriez',
    'enraillerons',
    'enrailleront',
    'enraquassent',
    'enraquassiez',
    'enrayassions',
    'enrayeraient',
    'enrégimentai',
    'enrégimentas',
    'enrégimentât',
    'enrégimentée',
    'enrégimenter',
    'enrégimentes',
    'enrégimentés',
    'enrégimentez',
    'enregistrais',
    'enregistrait',
    'enregistrant',
    'enregistrées',
    'enregistrent',
    'enregistrera',
    'enregistreur',
    'enregistriez',
    'enregistrons',
    'enrênassions',
    'enrêneraient',
    'enrhumassent',
    'enrhumassiez',
    'enrhumerions',
    'enrichirions',
    'enrichissais',
    'enrichissait',
    'enrichissant',
    'enrichissent',
    'enrichissiez',
    'enrichissons',
    'enrobassions',
    'enroberaient',
    'enrochassent',
    'enrochassiez',
    'enrochements',
    'enrocherions',
    'enrôlassions',
    'enrôleraient',
    'enrouassions',
    'enroueraient',
    'enrouillâmes',
    'enrouillasse',
    'enrouillâtes',
    'enrouillerai',
    'enrouilleras',
    'enrouillerez',
    'enrouillions',
    'enroulassent',
    'enroulassiez',
    'enroulements',
    'enroulerions',
    'enrubannâmes',
    'enrubannasse',
    'enrubannâtes',
    'enrubannerai',
    'enrubanneras',
    'enrubannerez',
    'enrubannions',
    'ensablassent',
    'ensablassiez',
    'ensablements',
    'ensablerions',
    'ensabotaient',
    'ensabotasses',
    'ensaboterais',
    'ensaboterait',
    'ensabotèrent',
    'ensaboteriez',
    'ensaboterons',
    'ensaboteront',
    'ensachassent',
    'ensachassiez',
    'ensacherions',
    'ensaisinâmes',
    'ensaisinasse',
    'ensaisinâtes',
    'ensaisinerai',
    'ensaisineras',
    'ensaisinerez',
    'ensaisinions',
    'ensanglantai',
    'ensanglantas',
    'ensanglantât',
    'ensanglantée',
    'ensanglanter',
    'ensanglantes',
    'ensanglantés',
    'ensanglantez',
    'ensauvassiez',
    'ensauverions',
    'enseignaient',
    'enseignantes',
    'enseignasses',
    'enseignement',
    'enseignerais',
    'enseignerait',
    'enseignèrent',
    'enseigneriez',
    'enseignerons',
    'enseigneront',
    'ensemençâmes',
    'ensemençasse',
    'ensemençâtes',
    'ensemencerai',
    'ensemenceras',
    'ensemencerez',
    'ensemencions',
    'enserrassent',
    'enserrassiez',
    'enserrerions',
    'ensevelirais',
    'ensevelirait',
    'ensevelirent',
    'enseveliriez',
    'ensevelirons',
    'enseveliront',
    'ensevelisses',
    'ensevelissez',
    'ensilassions',
    'ensileraient',
    'ensoleillais',
    'ensoleillait',
    'ensoleillant',
    'ensoleillées',
    'ensoleillent',
    'ensoleillera',
    'ensoleilliez',
    'ensoleillons',
    'ensommeillée',
    'ensommeillés',
    'ensorcelâmes',
    'ensorcelante',
    'ensorcelants',
    'ensorcelasse',
    'ensorcelâtes',
    'ensorceleurs',
    'ensorceleuse',
    'ensorcelions',
    'ensorcellent',
    'ensorcellera',
    'ensoufraient',
    'ensoufrasses',
    'ensoufrerais',
    'ensoufrerait',
    'ensoufrèrent',
    'ensoufreriez',
    'ensoufrerons',
    'ensoufreront',
    'enstérassent',
    'enstérassiez',
    'enstérerions',
    'entablassent',
    'entablassiez',
    'entablements',
    'entablerions',
    'entachassent',
    'entachassiez',
    'entacherions',
    'entaillaient',
    'entaillasses',
    'entaillerais',
    'entaillerait',
    'entaillèrent',
    'entailleriez',
    'entaillerons',
    'entailleront',
    'entamassions',
    'entameraient',
    'entaquerions',
    'entartraient',
    'entartrasses',
    'entartrerais',
    'entartrerait',
    'entartrèrent',
    'entartreriez',
    'entartrerons',
    'entartreront',
    'entassassent',
    'entassassiez',
    'entassements',
    'entasserions',
    'entendements',
    'entendissent',
    'entendissiez',
    'entendraient',
    'enténébrâmes',
    'enténébrasse',
    'enténébrâtes',
    'enténébrerai',
    'enténébreras',
    'enténébrerez',
    'enténébrions',
    'entérinaient',
    'entérinasses',
    'entérinement',
    'entérinerais',
    'entérinerait',
    'entérinèrent',
    'entérineriez',
    'entérinerons',
    'entérineront',
    'entérocolite',
    'entérocoques',
    'entérokinase',
    'entéro-rénal',
    'entérovaccin',
    'enterrassent',
    'enterrassiez',
    'enterrements',
    'enterrerions',
    'entêtassions',
    'entêteraient',
    'enthousiasma',
    'enthousiasme',
    'enthousiasmé',
    'enthousiaste',
    'entichassiez',
    'entichements',
    'enticherions',
    'entoilassent',
    'entoilassiez',
    'entoilerions',
    'entôlassions',
    'entôleraient',
    'entomologies',
    'entomophages',
    'entomophiles',
    'entonnaisons',
    'entonnassent',
    'entonnassiez',
    'entonnements',
    'entonnerions',
    'entortillage',
    'entortillais',
    'entortillait',
    'entortillant',
    'entortillées',
    'entortillent',
    'entortillera',
    'entortilliez',
    'entortillons',
    'entourassent',
    'entourassiez',
    'entourerions',
    'entraccordée',
    'entraccorder',
    'entraccordés',
    'entraccordez',
    'entraccusées',
    'entraccusiez',
    'entraccusons',
    'entradmirées',
    'entradmiriez',
    'entradmirons',
    'entraideriez',
    'entraiderons',
    'entraînables',
    'entraînaient',
    'entraînantes',
    'entraînasses',
    'entraînement',
    'entraînerais',
    'entraînerait',
    'entraînèrent',
    'entraîneriez',
    'entraînerons',
    'entraîneront',
    'entraîneuses',
    'entravassent',
    'entravassiez',
    'entraverions',
    'entrebâillai',
    'entrebâillas',
    'entrebâillât',
    'entrebâillée',
    'entrebâiller',
    'entrebâilles',
    'entrebâillés',
    'entrebâillez',
    'entre-bandes',
    'entrebattiez',
    'entrebattons',
    'entrebattrez',
    'entrebattues',
    'entrechoquai',
    'entrechoquas',
    'entrechoquât',
    'entrechoquée',
    'entrechoquer',
    'entrechoques',
    'entrechoqués',
    'entrechoquez',
    'entrecoupais',
    'entrecoupait',
    'entrecoupant',
    'entrecoupées',
    'entrecoupent',
    'entrecoupera',
    'entrecoupiez',
    'entrecoupons',
    'entrecroisai',
    'entrecroisas',
    'entrecroisât',
    'entrecroisée',
    'entrecroiser',
    'entrecroises',
    'entrecroisés',
    'entrecroisez',
    'entrecuisses',
    'entre-dévoré',
    'entre-égorgé',
    'entre-frappé',
    'entre-haïmes',
    'entre-haïrez',
    'entre-haïtes',
    'entre-heurté',
    'entre-jambes',
    'entrelaçâmes',
    'entrelaçasse',
    'entrelaçâtes',
    'entrelacerai',
    'entrelaceras',
    'entrelacerez',
    'entrelacions',
    'entrelardais',
    'entrelardait',
    'entrelardant',
    'entrelardées',
    'entrelardent',
    'entrelardera',
    'entrelardiez',
    'entrelardons',
    'entre-lignes',
    'entre-louées',
    'entre-louiez',
    'entre-louons',
    'entre-mangée',
    'entre-manger',
    'entre-mangés',
    'entre-mangez',
    'entremêlâmes',
    'entremêlasse',
    'entremêlâtes',
    'entremêlerai',
    'entremêleras',
    'entremêlerez',
    'entremêlions',
    'entremettais',
    'entremettait',
    'entremettant',
    'entremettent',
    'entremetteur',
    'entremettiez',
    'entremettons',
    'entremettrai',
    'entremettras',
    'entremettrez',
    'entremissent',
    'entremissiez',
    'entre-noeuds',
    'entre-nuirez',
    'entre-nuisez',
    'entreposages',
    'entreposâmes',
    'entreposasse',
    'entreposâtes',
    'entreposerai',
    'entreposeras',
    'entreposerez',
    'entreposeurs',
    'entreposions',
    'entreprenais',
    'entreprenait',
    'entreprenant',
    'entreprendra',
    'entreprendre',
    'entrepreneur',
    'entrepreniez',
    'entreprennes',
    'entreprenons',
    'entreprirent',
    'entreprisses',
    'entretaillée',
    'entretailler',
    'entretailles',
    'entretaillés',
    'entretaillez',
    'entretenions',
    'entretiendra',
    'entretiennes',
    'entretinrent',
    'entretinsses',
    'entretoisais',
    'entretoisait',
    'entretoisant',
    'entretoisées',
    'entretoisent',
    'entretoisera',
    'entretoisiez',
    'entretoisons',
    'entre-tuâmes',
    'entre-tuâtes',
    'entre-tuerez',
    'entre-tuions',
    'entreverrais',
    'entreverrait',
    'entreverriez',
    'entreverrons',
    'entreverront',
    'entrevissent',
    'entrevissiez',
    'entrevoûtais',
    'entrevoûtait',
    'entrevoûtant',
    'entrevoûtées',
    'entrevoûtent',
    'entrevoutera',
    'entrevoûtiez',
    'entrevoûtons',
    'entrevoyions',
    'entrouvertes',
    'entrouvrîmes',
    'entrouvrions',
    'entrouvrirai',
    'entrouvriras',
    'entrouvrirez',
    'entrouvrisse',
    'entrouvrîtes',
    'entubassions',
    'entuberaient',
    'enturbannées',
    'énucléassent',
    'énucléassiez',
    'énucléations',
    'énucléerions',
    'énumérassent',
    'énumérassiez',
    'énumérations',
    'énumératives',
    'énumérerions',
    'envahiraient',
    'envahissante',
    'envahissants',
    'envahisseurs',
    'envahissions',
    'envasassions',
    'envaseraient',
    'enveloppâmes',
    'enveloppante',
    'enveloppants',
    'enveloppasse',
    'enveloppâtes',
    'envelopperai',
    'envelopperas',
    'envelopperez',
    'enveloppions',
    'envenimaient',
    'envenimasses',
    'envenimation',
    'envenimement',
    'envenimerais',
    'envenimerait',
    'envenimèrent',
    'envenimeriez',
    'envenimerons',
    'envenimeront',
    'envergeaient',
    'envergeasses',
    'envergerions',
    'enverguaient',
    'enverguasses',
    'enverguerais',
    'enverguerait',
    'enverguèrent',
    'envergueriez',
    'enverguerons',
    'envergueront',
    'envidassions',
    'envideraient',
    'envieillîmes',
    'envieillirai',
    'envieilliras',
    'envieillirez',
    'envieillisse',
    'envieillîtes',
    'envieusement',
    'environnâmes',
    'environnante',
    'environnants',
    'environnasse',
    'environnâtes',
    'environnerai',
    'environneras',
    'environnerez',
    'environnions',
    'envisageable',
    'envisageâmes',
    'envisageasse',
    'envisageâtes',
    'envisagerais',
    'envisagerait',
    'envisagèrent',
    'envisageriez',
    'envisagerons',
    'envisageront',
    'envoilassiez',
    'envoilerions',
    'envolassions',
    'envoûtassent',
    'envoûtassiez',
    'envoûtements',
    'envoûterions',
    'envoyassions',
    'enzymatiques',
    'enzymologies',
    'enzymopathie',
    'éosinophiles',
    'éosinophilie',
    'épaissirions',
    'épaississais',
    'épaississait',
    'épaississant',
    'épaississent',
    'épaississeur',
    'épaississiez',
    'épaississons',
    'épamprassent',
    'épamprassiez',
    'épamprements',
    'épamprerions',
    'épanchassent',
    'épanchassiez',
    'épanchements',
    'épancherions',
    'épandissions',
    'épannassions',
    'épannelaient',
    'épannelasses',
    'épannelèrent',
    'épannellerai',
    'épannelleras',
    'épannellerez',
    'épanneraient',
    'épanouirions',
    'épanouissais',
    'épanouissait',
    'épanouissant',
    'épanouissent',
    'épanouissiez',
    'épanouissons',
    'épargnassent',
    'épargnassiez',
    'épargnerions',
    'éparpillâmes',
    'éparpillasse',
    'éparpillâtes',
    'éparpillerai',
    'éparpilleras',
    'éparpillerez',
    'éparpillions',
    'épaufrassent',
    'épaufrassiez',
    'épaufrerions',
    'épaulassions',
    'épauleraient',
    'épelleraient',
    'épenthétique',
    'épépinassent',
    'épépinassiez',
    'épépinerions',
    'éperdissions',
    'éperonnaient',
    'éperonnasses',
    'éperonnerais',
    'éperonnerait',
    'éperonnèrent',
    'éperonneriez',
    'éperonnerons',
    'éperonneront',
    'épeulassions',
    'épeuleraient',
    'épeurassions',
    'épeureraient',
    'épicrânienne',
    'épicuriennes',
    'épicycloïdal',
    'épicycloïdes',
    'épidémicités',
    'épidermiques',
    'épierrassent',
    'épierrassiez',
    'épierrements',
    'épierrerions',
    'épigastrique',
    'épigraphique',
    'épigraphiste',
    'épileptiques',
    'épiloguaient',
    'épiloguasses',
    'épiloguerais',
    'épiloguerait',
    'épiloguèrent',
    'épilogueriez',
    'épiloguerons',
    'épilogueront',
    'épinçassions',
    'épincelaient',
    'épincelasses',
    'épincèlerais',
    'épincèlerait',
    'épincelèrent',
    'épincèleriez',
    'épincèlerons',
    'épincèleront',
    'épinceraient',
    'épincetaient',
    'épincetasses',
    'épinceterais',
    'épinceterait',
    'épincetèrent',
    'épinceteriez',
    'épinceterons',
    'épinceteront',
    'épinglassent',
    'épinglassiez',
    'épinglerions',
    'épinochettes',
    'épiphénomène',
    'épirogénique',
    'épissassions',
    'épisseraient',
    'épistolaires',
    'épithéliales',
    'épizootiques',
    'éploieraient',
    'éployassions',
    'épluchassent',
    'épluchassiez',
    'éplucherions',
    'épointassent',
    'épointassiez',
    'épointements',
    'épointerions',
    'épongeassent',
    'épongeassiez',
    'épongeraient',
    'épontillâmes',
    'épontillasse',
    'épontillâtes',
    'épontillerai',
    'épontilleras',
    'épontillerez',
    'épontillions',
    'épouillaient',
    'épouillasses',
    'épouillerais',
    'épouillerait',
    'épouillèrent',
    'épouilleriez',
    'épouillerons',
    'épouilleront',
    'époumonaient',
    'époumonasses',
    'époumonerais',
    'époumonerait',
    'époumonèrent',
    'époumoneriez',
    'époumonerons',
    'époumoneront',
    'épousassions',
    'épouseraient',
    'époussetages',
    'époussetâmes',
    'époussetasse',
    'époussetâtes',
    'époussetions',
    'époussettent',
    'époussettera',
    'époustouflai',
    'époustouflas',
    'époustouflât',
    'époustouflée',
    'époustoufler',
    'époustoufles',
    'époustouflés',
    'époustouflez',
    'époutiassent',
    'époutiassiez',
    'époutierions',
    'époutiraient',
    'époutissions',
    'épouvantable',
    'épouvantails',
    'épouvantâmes',
    'épouvantasse',
    'épouvantâtes',
    'épouvanterai',
    'épouvanteras',
    'épouvanterez',
    'épouvantions',
    'épreignaient',
    'épreindrions',
    'éprouvassent',
    'éprouvassiez',
    'éprouverions',
    'épuisassions',
    'épuiseraient',
    'équarririons',
    'équarrissage',
    'équarrissais',
    'équarrissait',
    'équarrissant',
    'équarrissent',
    'équarrisseur',
    'équarrissiez',
    'équarrissoir',
    'équarrissons',
    'équatoriales',
    'équerrassent',
    'équerrassiez',
    'équerrerions',
    'équeutassent',
    'équeutassiez',
    'équeuterions',
    'équidistance',
    'équidistante',
    'équidistants',
    'équilatérale',
    'équilatéraux',
    'équilibrages',
    'équilibrâmes',
    'équilibrante',
    'équilibrants',
    'équilibrasse',
    'équilibrâtes',
    'équilibrerai',
    'équilibreras',
    'équilibrerez',
    'équilibrions',
    'équilibriste',
    'équimultiple',
    'équinoxiales',
    'équipassions',
    'équiperaient',
    'équipollence',
    'équipollente',
    'équipollents',
    'équiprobable',
    'équivaillent',
    'équivalaient',
    'équivalences',
    'équivalentes',
    'équivalurent',
    'équivalusses',
    'équivaudrais',
    'équivaudrait',
    'équivaudriez',
    'équivaudrons',
    'équivaudront',
    'équivoquâmes',
    'équivoquasse',
    'équivoquâtes',
    'équivoquerai',
    'équivoqueras',
    'équivoquerez',
    'équivoquions',
    'éradications',
    'éradiquaient',
    'éradiquasses',
    'éradiquerais',
    'éradiquerait',
    'éradiquèrent',
    'éradiqueriez',
    'éradiquerons',
    'éradiqueront',
    'éraflassions',
    'érafleraient',
    'éraillassent',
    'éraillassiez',
    'éraillements',
    'éraillerions',
    'éreintassent',
    'éreintassiez',
    'éreintements',
    'éreinterions',
    'ergonomiques',
    'ergonomistes',
    'ergotassions',
    'ergoteraient',
    'ergothérapie',
    'érigeassions',
    'érotiquement',
    'érotisassent',
    'érotisassiez',
    'érotiserions',
    'erpétologies',
    'érubescences',
    'érubescentes',
    'éructassions',
    'éructeraient',
    'érythémateux',
    'érythrocytes',
    'érythrosines',
    'esbaudirions',
    'esbaudissiez',
    'esbaudissons',
    'esbignassiez',
    'esbignerions',
    'esbroufaient',
    'esbroufasses',
    'esbrouferais',
    'esbrouferait',
    'esbroufèrent',
    'esbrouferiez',
    'esbrouferons',
    'esbrouferont',
    'esbroufeuses',
    'escaladaient',
    'escaladasses',
    'escaladerais',
    'escaladerait',
    'escaladèrent',
    'escaladeriez',
    'escaladerons',
    'escaladeront',
    'escamotables',
    'escamotaient',
    'escamotasses',
    'escamoterais',
    'escamoterait',
    'escamotèrent',
    'escamoteriez',
    'escamoterons',
    'escamoteront',
    'escamoteuses',
    'escarboucles',
    'escargotière',
    'escarmouchai',
    'escarmouchas',
    'escarmouchât',
    'escarmoucher',
    'escarmouches',
    'escarmouchez',
    'escarpements',
    'escarpolette',
    'escarrifiais',
    'escarrifiait',
    'escarrifiant',
    'escarrifiées',
    'escarrifient',
    'escarrifiera',
    'escarrifiiez',
    'escarrifions',
    'eschatologie',
    'esclafferiez',
    'esclafferons',
    'esclavageais',
    'esclavageait',
    'esclavageant',
    'esclavageons',
    'esclavagerai',
    'esclavageras',
    'esclavagerez',
    'esclavagions',
    'esclavagisme',
    'esclavagiste',
    'escobarderie',
    'escomptables',
    'escomptaient',
    'escomptasses',
    'escompterais',
    'escompterait',
    'escomptèrent',
    'escompteriez',
    'escompterons',
    'escompteront',
    'escortassent',
    'escortassiez',
    'escorterions',
    'escrimassiez',
    'escrimerions',
    'escroquaient',
    'escroquasses',
    'escroquerais',
    'escroquerait',
    'escroquèrent',
    'escroqueries',
    'escroqueriez',
    'escroquerons',
    'escroqueront',
    'espaçassions',
    'espaceraient',
    'espace-temps',
    'espagnolette',
    'espagnolisme',
    'espérantiste',
    'espérassions',
    'espéreraient',
    'espiègleries',
    'espionnaient',
    'espionnasses',
    'espionnerais',
    'espionnerait',
    'espionnèrent',
    'espionneriez',
    'espionnerons',
    'espionneront',
    'espolinaient',
    'espolinasses',
    'espolinerais',
    'espolinerait',
    'espolinèrent',
    'espolineriez',
    'espolinerons',
    'espolineront',
    'espoulinâmes',
    'espoulinasse',
    'espoulinâtes',
    'espoulinerai',
    'espoulineras',
    'espoulinerez',
    'espoulinions',
    'esquimautage',
    'esquintaient',
    'esquintantes',
    'esquintasses',
    'esquinterais',
    'esquinterait',
    'esquintèrent',
    'esquinteriez',
    'esquinterons',
    'esquinteront',
    'esquissaient',
    'esquissasses',
    'esquisserais',
    'esquisserait',
    'esquissèrent',
    'esquisseriez',
    'esquisserons',
    'esquisseront',
    'esquivassent',
    'esquivassiez',
    'esquiverions',
    'essaieraient',
    'essaimassent',
    'essaimassiez',
    'essaimerions',
    'essangeaient',
    'essangeasses',
    'essangerions',
    'essartassent',
    'essartassiez',
    'essartements',
    'essarterions',
    'essayassions',
    'essayeraient',
    'essentielles',
    'essorassions',
    'essoreraient',
    'essorillâmes',
    'essorillasse',
    'essorillâtes',
    'essorillerai',
    'essorilleras',
    'essorillerez',
    'essorillions',
    'essouchaient',
    'essouchasses',
    'essouchement',
    'essoucherais',
    'essoucherait',
    'essouchèrent',
    'essoucheriez',
    'essoucherons',
    'essoucheront',
    'essoufflâmes',
    'essoufflasse',
    'essoufflâtes',
    'essoufflerai',
    'essouffleras',
    'essoufflerez',
    'essoufflions',
    'essuie-glace',
    'essuie-mains',
    'essuie-pieds',
    'essuie-plume',
    'essuieraient',
    'essuyassions',
    'est-allemand',
    'estampassent',
    'estampassiez',
    'estamperions',
    'estampillage',
    'estampillais',
    'estampillait',
    'estampillant',
    'estampillées',
    'estampillent',
    'estampillera',
    'estampilliez',
    'estampillons',
    'estérifiâmes',
    'estérifiasse',
    'estérifiâtes',
    'estérifierai',
    'estérifieras',
    'estérifierez',
    'estérifiions',
    'esthésiogène',
    'esthéticiens',
    'esthétisâmes',
    'esthétisante',
    'esthétisants',
    'esthétisasse',
    'esthétisâtes',
    'esthétiserai',
    'esthétiseras',
    'esthétiserez',
    'esthétisions',
    'estimassions',
    'estimatoires',
    'estimeraient',
    'estivassions',
    'estiveraient',
    'estomaquâmes',
    'estomaquasse',
    'estomaquâtes',
    'estomaquerai',
    'estomaqueras',
    'estomaquerez',
    'estomaquions',
    'estompassent',
    'estompassiez',
    'estomperions',
    'estoquassent',
    'estoquassiez',
    'estoquerions',
    'estourbirais',
    'estourbirait',
    'estourbirent',
    'estourbiriez',
    'estourbirons',
    'estourbiront',
    'estourbisses',
    'estourbissez',
    'estrapadâmes',
    'estrapadasse',
    'estrapadâtes',
    'estrapaderai',
    'estrapaderas',
    'estrapaderez',
    'estrapadions',
    'estrapassais',
    'estrapassait',
    'estrapassant',
    'estrapassées',
    'estrapassent',
    'estrapassera',
    'estrapassiez',
    'estrapassons',
    'estropiaient',
    'estropiasses',
    'estropierais',
    'estropierait',
    'estropièrent',
    'estropieriez',
    'estropierons',
    'estropieront',
    'estuariennes',
    'estudiantine',
    'estudiantins',
    'établassions',
    'étableraient',
    'établiraient',
    'établissions',
    'étageassions',
    'étalageaient',
    'étalageasses',
    'étalagerions',
    'étalinguâmes',
    'étalinguasse',
    'étalinguâtes',
    'étalinguerai',
    'étalingueras',
    'étalinguerez',
    'étalinguions',
    'étalisassent',
    'étalisassiez',
    'étaliserions',
    'étalonnaient',
    'étalonnasses',
    'étalonnement',
    'étalonnerais',
    'étalonnerait',
    'étalonnèrent',
    'étalonneriez',
    'étalonnerons',
    'étalonneront',
    'étampassions',
    'étamperaient',
    'étanchassent',
    'étanchassiez',
    'étanchements',
    'étancherions',
    'étançonnâmes',
    'étançonnasse',
    'étançonnâtes',
    'étançonnerai',
    'étançonneras',
    'étançonnerez',
    'étançonnions',
    'étarquassent',
    'étarquassiez',
    'étarquerions',
    'étasuniennes',
    'étatisations',
    'états-majors',
    'éteignissent',
    'éteignissiez',
    'éteindraient',
    'étendissions',
    'éternisaient',
    'éternisasses',
    'éterniserais',
    'éterniserait',
    'éternisèrent',
    'éterniseriez',
    'éterniserons',
    'éterniseront',
    'éternuassent',
    'éternuassiez',
    'éternuements',
    'éternuerions',
    'éthérifiâmes',
    'éthérifiasse',
    'éthérifiâtes',
    'éthérifierai',
    'éthérifieras',
    'éthérifierez',
    'éthérifiions',
    'éthérisaient',
    'éthérisasses',
    'éthérisation',
    'éthériserais',
    'éthériserait',
    'éthérisèrent',
    'éthériseriez',
    'éthériserons',
    'éthériseront',
    'éthéromanies',
    'éthiopiennes',
    'ethnographes',
    'ethnographie',
    'ethnologique',
    'éthographies',
    'éthologiques',
    'éthyléniques',
    'étincelaient',
    'étincelantes',
    'étincelasses',
    'étincelèrent',
    'étincellerai',
    'étincelleras',
    'étincellerez',
    'étiolassions',
    'étioleraient',
    'étiologiques',
    'étiquetaient',
    'étiquetasses',
    'étiquetèrent',
    'étiqueteuses',
    'étiquetterai',
    'étiquetteras',
    'étiquetterez',
    'étoffassions',
    'étofferaient',
    'étoilassions',
    'étoileraient',
    'étonnassions',
    'étonneraient',
    'étouffassent',
    'étouffassiez',
    'étouffements',
    'étoufferions',
    'étoupassions',
    'étouperaient',
    'étoupillâmes',
    'étoupillasse',
    'étoupillâtes',
    'étoupillerai',
    'étoupilleras',
    'étoupillerez',
    'étoupillions',
    'étourdirions',
    'étourdissais',
    'étourdissait',
    'étourdissant',
    'étourdissent',
    'étourdissiez',
    'étourdissons',
    'étranglaient',
    'étranglasses',
    'étranglement',
    'étranglerais',
    'étranglerait',
    'étranglèrent',
    'étrangleriez',
    'étranglerons',
    'étrangleront',
    'étrangleuses',
    'étréciraient',
    'étrécissions',
    'étreignaient',
    'étreignirent',
    'étreignisses',
    'étreindrions',
    'étrennassent',
    'étrennassiez',
    'étrennerions',
    'étrésillonna',
    'étrésillonne',
    'étrésillonné',
    'étrillassent',
    'étrillassiez',
    'étrillerions',
    'étripassions',
    'étriperaient',
    'étriquassent',
    'étriquassiez',
    'étriquerions',
    'étronçonnais',
    'étronçonnait',
    'étronçonnant',
    'étronçonnées',
    'étronçonnent',
    'étronçonnera',
    'étronçonniez',
    'étronçonnons',
    'étudiassions',
    'étudieraient',
    'étymologique',
    'étymologiste',
    'eucharisties',
    'euclidiennes',
    'eudémonismes',
    'eudiométries',
    'euphorisâmes',
    'euphorisante',
    'euphorisants',
    'euphorisasse',
    'euphorisâtes',
    'euphoriserai',
    'euphoriseras',
    'euphoriserez',
    'euphorisions',
    'eurafricaine',
    'eurafricains',
    'eurasiatique',
    'eurent-elles',
    'euromissiles',
    'européanisai',
    'européanisas',
    'européanisât',
    'européanisée',
    'européaniser',
    'européanises',
    'européanisés',
    'européanisez',
    'européanisme',
    'eurythmiques',
    'euscariennes',
    'euskariennes',
    'eussiez-vous',
    'évacuassions',
    'évacuatrices',
    'évacueraient',
    'évaginations',
    'évaluassions',
    'évalueraient',
    'évanescences',
    'évanescentes',
    'évangéliaire',
    'évangéliques',
    'évangélisais',
    'évangélisait',
    'évangélisant',
    'évangélisées',
    'évangélisent',
    'évangélisera',
    'évangélisiez',
    'évangélismes',
    'évangélisons',
    'évangélistes',
    'évanouirions',
    'évanouissiez',
    'évanouissons',
    'évaporassent',
    'évaporassiez',
    'évaporateurs',
    'évaporations',
    'évaporatoire',
    'évaporerions',
    'éveillassent',
    'éveillassiez',
    'éveillerions',
    'événementiel',
    'éventassions',
    'éventeraient',
    'éventrassent',
    'éventrassiez',
    'éventrations',
    'éventrerions',
    'éventualités',
    'évertuassiez',
    'évertuerions',
    'évinçassions',
    'évinceraient',
    'éviscéraient',
    'éviscérasses',
    'éviscérerais',
    'éviscérerait',
    'éviscérèrent',
    'éviscéreriez',
    'éviscérerons',
    'éviscéreront',
    'évoluassions',
    'évolueraient',
    'évoquassions',
    'évoqueraient',
    'exacerbaient',
    'exacerbasses',
    'exacerbation',
    'exacerberais',
    'exacerberait',
    'exacerbèrent',
    'exacerberiez',
    'exacerberons',
    'exacerberont',
    'exagérassent',
    'exagérassiez',
    'exagérateurs',
    'exagérations',
    'exagératrice',
    'exagérerions',
    'exaltassions',
    'exalteraient',
    'examinassent',
    'examinassiez',
    'examinateurs',
    'examinatrice',
    'examinerions',
    'exaspéraient',
    'exaspérantes',
    'exaspérasses',
    'exaspération',
    'exaspérerais',
    'exaspérerait',
    'exaspérèrent',
    'exaspéreriez',
    'exaspérerons',
    'exaspéreront',
    'exauçassions',
    'exauceraient',
    'excavassions',
    'excavatrices',
    'excaveraient',
    'excédassions',
    'excédentaire',
    'excéderaient',
    'excellassent',
    'excellassiez',
    'excellemment',
    'excellerions',
    'excentraient',
    'excentrasses',
    'excentration',
    'excentrerais',
    'excentrerait',
    'excentrèrent',
    'excentreriez',
    'excentrerons',
    'excentreront',
    'excentricité',
    'excentriques',
    'exceptassent',
    'exceptassiez',
    'excepterions',
    'exceptionnel',
    'excipassions',
    'exciperaient',
    'excisassions',
    'exciseraient',
    'excitabilité',
    'excitassions',
    'excitatrices',
    'exciteraient',
    'exclamassiez',
    'exclamations',
    'exclamatives',
    'exclamerions',
    'exclusivisme',
    'exclusivités',
    'excommuniais',
    'excommuniait',
    'excommuniant',
    'excommuniées',
    'excommunient',
    'excommuniera',
    'excommuniiez',
    'excommunions',
    'excoriassent',
    'excoriassiez',
    'excoriations',
    'excorierions',
    'excrémentiel',
    'excrétassent',
    'excrétassiez',
    'excréterions',
    'excroissance',
    'excursionnai',
    'excursionnas',
    'excursionnât',
    'excursionner',
    'excursionnes',
    'excursionnez',
    'excusassions',
    'excuseraient',
    'exécrassions',
    'exécreraient',
    'exécutassent',
    'exécutassiez',
    'exécuterions',
    'exemplarités',
    'exemplifiais',
    'exemplifiait',
    'exemplifiant',
    'exemplifiées',
    'exemplifient',
    'exemplifiera',
    'exemplifiiez',
    'exemplifions',
    'exemptassent',
    'exemptassiez',
    'exempterions',
    'exerçassions',
    'exerceraient',
    'exfoliassent',
    'exfoliassiez',
    'exfoliations',
    'exfolierions',
    'exhalassions',
    'exhaleraient',
    'exhaussaient',
    'exhaussasses',
    'exhaussement',
    'exhausserais',
    'exhausserait',
    'exhaussèrent',
    'exhausseriez',
    'exhausserons',
    'exhausseront',
    'exhaustivité',
    'exhérédaient',
    'exhérédasses',
    'exhérédation',
    'exhéréderais',
    'exhéréderait',
    'exhérédèrent',
    'exhéréderiez',
    'exhéréderons',
    'exhéréderont',
    'exhibassions',
    'exhiberaient',
    'exhortassent',
    'exhortassiez',
    'exhortations',
    'exhorterions',
    'exhumassions',
    'exhumeraient',
    'exigeassions',
    'exigibilités',
    'existassions',
    'existentiels',
    'existeraient',
    'exondassions',
    'exonérassent',
    'exonérassiez',
    'exonérations',
    'exonérerions',
    'exophtalmies',
    'exorbitantes',
    'exorcisaient',
    'exorcisasses',
    'exorcisation',
    'exorciserais',
    'exorciserait',
    'exorcisèrent',
    'exorciseriez',
    'exorciserons',
    'exorciseront',
    'exothermique',
    'expansivités',
    'expatriaient',
    'expatriasses',
    'expatriation',
    'expatrierais',
    'expatrierait',
    'expatrièrent',
    'expatrieriez',
    'expatrierons',
    'expatrieront',
    'expectatives',
    'expectorâmes',
    'expectorante',
    'expectorants',
    'expectorasse',
    'expectorâtes',
    'expectorerai',
    'expectoreras',
    'expectorerez',
    'expectorions',
    'expédiassent',
    'expédiassiez',
    'expédierions',
    'expéditrices',
    'expérimentai',
    'expérimental',
    'expérimentas',
    'expérimentât',
    'expérimentée',
    'expérimenter',
    'expérimentes',
    'expérimentés',
    'expérimentez',
    'expertisâmes',
    'expertisasse',
    'expertisâtes',
    'expertiserai',
    'expertiseras',
    'expertiserez',
    'expertisions',
    'expirassions',
    'expireraient',
    'explications',
    'explicatives',
    'explicitâmes',
    'explicitasse',
    'explicitâtes',
    'expliciterai',
    'expliciteras',
    'expliciterez',
    'explicitions',
    'expliquaient',
    'expliquasses',
    'expliquerais',
    'expliquerait',
    'expliquèrent',
    'expliqueriez',
    'expliquerons',
    'expliqueront',
    'exploitables',
    'exploitaient',
    'exploitantes',
    'exploitasses',
    'exploitation',
    'exploiterais',
    'exploiterait',
    'exploitèrent',
    'exploiteriez',
    'exploiterons',
    'exploiteront',
    'exploiteuses',
    'explorassent',
    'explorassiez',
    'explorateurs',
    'explorations',
    'exploratoire',
    'exploratrice',
    'explorerions',
    'explosassent',
    'explosassiez',
    'exploserions',
    'exponentiels',
    'exportassent',
    'exportassiez',
    'exportateurs',
    'exportations',
    'exportatrice',
    'exporterions',
    'exposassions',
    'exposeraient',
    'expressément',
    'expressivité',
    'exprimassent',
    'exprimassiez',
    'exprimerions',
    'expromission',
    'expropriâmes',
    'expropriante',
    'expropriants',
    'expropriasse',
    'expropriâtes',
    'exproprierai',
    'exproprieras',
    'exproprierez',
    'expropriions',
    'expulsassent',
    'expulsassiez',
    'expulserions',
    'expurgations',
    'expurgatoire',
    'expurgeaient',
    'expurgeasses',
    'expurgerions',
    'exsudassions',
    'exsuderaient',
    'extasiassiez',
    'extasierions',
    'extemporanée',
    'extemporanés',
    'extensomètre',
    'exténuassent',
    'exténuassiez',
    'exténuations',
    'exténuerions',
    'extériorisai',
    'extériorisas',
    'extériorisât',
    'extériorisée',
    'extérioriser',
    'extériorises',
    'extériorisés',
    'extériorisez',
    'extériorités',
    'exterminâmes',
    'exterminasse',
    'exterminâtes',
    'exterminerai',
    'extermineras',
    'exterminerez',
    'exterminions',
    'extéroceptif',
    'extinctrices',
    'extinguibles',
    'extirpassent',
    'extirpassiez',
    'extirpateurs',
    'extirpations',
    'extirperions',
    'extorquaient',
    'extorquasses',
    'extorquerais',
    'extorquerait',
    'extorquèrent',
    'extorqueriez',
    'extorquerons',
    'extorqueront',
    'extorqueuses',
    'extractibles',
    'extradassent',
    'extradassiez',
    'extraderions',
    'extraditions',
    'extrairaient',
    'extra-légale',
    'extra-légaux',
    'extra-lucide',
    'extralucides',
    'extrapolâmes',
    'extrapolasse',
    'extrapolâtes',
    'extrapolerai',
    'extrapoleras',
    'extrapolerez',
    'extrapolions',
    'extrasystole',
    'extravagance',
    'extravagante',
    'extravagants',
    'extravaguais',
    'extravaguait',
    'extravaguant',
    'extravaguent',
    'extravaguera',
    'extravaguiez',
    'extravaguons',
    'extravasâmes',
    'extravasasse',
    'extravasâtes',
    'extravaserai',
    'extravaseras',
    'extravaserez',
    'extravasions',
    'extraversion',
    'extraverties',
    'extrinsèques',
    'extrudassent',
    'extrudassiez',
    'extruderions',
    'exulcéraient',
    'exulcérasses',
    'exulcération',
    'exulcérerais',
    'exulcérerait',
    'exulcérèrent',
    'exulcéreriez',
    'exulcérerons',
    'exulcéreront',
    'exultassions',
    'exulteraient',
    'fabricateurs',
    'fabrications',
    'fabricatrice',
    'fabriquaient',
    'fabriquantes',
    'fabriquasses',
    'fabriquerais',
    'fabriquerait',
    'fabriquèrent',
    'fabriqueriez',
    'fabriquerons',
    'fabriqueront',
    'fabulassions',
    'fabulatrices',
    'fabuleraient',
    'faces-à-main',
    'facettassent',
    'facettassiez',
    'facetterions',
    'fâcheusement',
    'facilitaient',
    'facilitasses',
    'facilitation',
    'faciliterais',
    'faciliterait',
    'facilitèrent',
    'faciliteriez',
    'faciliterons',
    'faciliteront',
    'façonnassent',
    'façonnassiez',
    'façonnements',
    'façonnerions',
    'factionnaire',
    'factorielles',
    'factorisâmes',
    'factorisasse',
    'factorisâtes',
    'factoriserai',
    'factoriseras',
    'factoriserez',
    'factorisions',
    'facturassent',
    'facturassiez',
    'facturations',
    'facturerions',
    'facultatives',
    'fagotassions',
    'fagoteraient',
    'faibliraient',
    'faiblissante',
    'faiblissants',
    'faiblissions',
    'faignantâmes',
    'faignantasse',
    'faignantâtes',
    'faignanterai',
    'faignanteras',
    'faignanterez',
    'faignantions',
    'faillassions',
    'failleraient',
    'faillibilité',
    'failliraient',
    'faillissions',
    'fainéantâmes',
    'fainéantasse',
    'fainéantâtes',
    'fainéanterai',
    'fainéanteras',
    'fainéanterez',
    'fainéantions',
    'fainéantises',
    'faire-valoir',
    'faisabilités',
    'faisandaient',
    'faisandasses',
    'faisanderais',
    'faisanderait',
    'faisandèrent',
    'faisanderies',
    'faisanderiez',
    'faisanderons',
    'faisanderont',
    'faldistoires',
    'fallacieuses',
    'falsifiables',
    'falsifiaient',
    'falsifiasses',
    'falsifierais',
    'falsifierait',
    'falsifièrent',
    'falsifieriez',
    'falsifierons',
    'falsifieront',
    'familiarisai',
    'familiarisas',
    'familiarisât',
    'familiarisée',
    'familiariser',
    'familiarises',
    'familiarisés',
    'familiarisez',
    'familiarités',
    'familistères',
    'fanatisaient',
    'fanatisantes',
    'fanatisasses',
    'fanatiserais',
    'fanatiserait',
    'fanatisèrent',
    'fanatiseriez',
    'fanatiserons',
    'fanatiseront',
    'fanfaronnade',
    'fanfaronnais',
    'fanfaronnait',
    'fanfaronnant',
    'fanfaronnent',
    'fanfaronnera',
    'fanfaronniez',
    'fanfaronnons',
    'fanfreluchai',
    'fanfreluchas',
    'fanfreluchât',
    'fanfreluchée',
    'fanfrelucher',
    'fanfreluches',
    'fanfreluchés',
    'fanfreluchez',
    'fantaisistes',
    'fantasmaient',
    'fantasmasses',
    'fantasmerais',
    'fantasmerait',
    'fantasmèrent',
    'fantasmeriez',
    'fantasmerons',
    'fantasmeront',
    'fantastiques',
    'fantomatique',
    'faramineuses',
    'farcissaient',
    'farfouillais',
    'farfouillait',
    'farfouillant',
    'farfouillées',
    'farfouillent',
    'farfouillera',
    'farfouilliez',
    'farfouillons',
    'farinassions',
    'farineraient',
    'farouchement',
    'fascinassent',
    'fascinassiez',
    'fascinateurs',
    'fascinations',
    'fascinatrice',
    'fascinerions',
    'fascisassent',
    'fascisassiez',
    'fascisations',
    'fasciserions',
    'faseillaient',
    'faseillasses',
    'faseillerais',
    'faseillerait',
    'faseillèrent',
    'faseilleriez',
    'faseillerons',
    'faseilleront',
    'fastidieuses',
    'fatigabilité',
    'fatiguassent',
    'fatiguassiez',
    'fatiguerions',
    'faubourienne',
    'faucardaient',
    'faucardasses',
    'faucarderais',
    'faucarderait',
    'faucardèrent',
    'faucarderiez',
    'faucarderons',
    'faucarderont',
    'fauchassions',
    'faucheraient',
    'fauconnaient',
    'fauconnasses',
    'fauconnerais',
    'fauconnerait',
    'fauconnèrent',
    'fauconneries',
    'fauconneriez',
    'fauconnerons',
    'fauconneront',
    'faufilassent',
    'faufilassiez',
    'faufilerions',
    'faussassions',
    'fausseraient',
    'faux-bourdon',
    'faux-fuyants',
    'faux-négatif',
    'faux-positif',
    'favorisaient',
    'favorisantes',
    'favorisasses',
    'favoriserais',
    'favoriserait',
    'favorisèrent',
    'favoriseriez',
    'favoriserons',
    'favoriseront',
    'favoritismes',
    'fayotassions',
    'fayoteraient',
    'fayottassent',
    'fayottassiez',
    'fayotterions',
    'fécondassent',
    'fécondassiez',
    'fécondateurs',
    'fécondations',
    'fécondatrice',
    'féconderions',
    'féculassions',
    'féculeraient',
    'fédéralisais',
    'fédéralisait',
    'fédéralisant',
    'fédéralisées',
    'fédéralisent',
    'fédéralisera',
    'fédéralisiez',
    'fédéralismes',
    'fédéralisons',
    'fédéralistes',
    'fédérassions',
    'fédératrices',
    'fédéreraient',
    'fégateraient',
    'feignantâmes',
    'feignantasse',
    'feignantâtes',
    'feignanterai',
    'feignanteras',
    'feignanterez',
    'feignantions',
    'feignissions',
    'feintassions',
    'feinteraient',
    'félicitaient',
    'félicitasses',
    'félicitation',
    'féliciterais',
    'féliciterait',
    'félicitèrent',
    'féliciteriez',
    'féliciterons',
    'féliciteront',
    'féminisaient',
    'féminisasses',
    'féminisation',
    'féminiserais',
    'féminiserait',
    'féminisèrent',
    'féminiseriez',
    'féminiserons',
    'féminiseront',
    'fendillaient',
    'fendillasses',
    'fendillement',
    'fendillerais',
    'fendillerait',
    'fendillèrent',
    'fendilleriez',
    'fendillerons',
    'fendilleront',
    'fenestraient',
    'fenestrasses',
    'fenestration',
    'fenestrerais',
    'fenestrerait',
    'fenestrèrent',
    'fenestreriez',
    'fenestrerons',
    'fenestreront',
    'fenêtrassent',
    'fenêtrassiez',
    'fenêtrerions',
    'fenouillette',
    'ferblanterie',
    'ferblantiers',
    'fermentables',
    'fermentaient',
    'fermentasses',
    'fermentatifs',
    'fermentation',
    'fermentative',
    'fermenterais',
    'fermenterait',
    'fermentèrent',
    'fermenteriez',
    'fermenterons',
    'fermenteront',
    'ferraillages',
    'ferraillâmes',
    'ferraillasse',
    'ferraillâtes',
    'ferraillerai',
    'ferrailleras',
    'ferraillerez',
    'ferrailleurs',
    'ferrailleuse',
    'ferraillions',
    'ferricyanure',
    'ferronneries',
    'ferronnières',
    'ferroviaires',
    'ferrugineuse',
    'fertilisable',
    'fertilisâmes',
    'fertilisante',
    'fertilisants',
    'fertilisasse',
    'fertilisâtes',
    'fertiliserai',
    'fertiliseras',
    'fertiliserez',
    'fertilisions',
    'festivalière',
    'festivaliers',
    'festoierions',
    'festonnaient',
    'festonnasses',
    'festonnerais',
    'festonnerait',
    'festonnèrent',
    'festonneriez',
    'festonnerons',
    'festonneront',
    'festoyassent',
    'festoyassiez',
    'fétichisâmes',
    'fétichisasse',
    'fétichisâtes',
    'fétichiserai',
    'fétichiseras',
    'fétichiserez',
    'fétichisions',
    'feuillagiste',
    'feuillaisons',
    'feuillantine',
    'feuillassent',
    'feuillassiez',
    'feuillerions',
    'feuilletâmes',
    'feuilletasse',
    'feuilletâtes',
    'feuilletions',
    'feuilletisée',
    'feuilletiser',
    'feuilletises',
    'feuilletisés',
    'feuilletisez',
    'feuillettent',
    'feuillettera',
    'feutrassions',
    'feutreraient',
    'fiançassions',
    'fianceraient',
    'fibrillaires',
    'fibrillation',
    'fibrinogènes',
    'fibrociments',
    'fibromateuse',
    'fibromatoses',
    'ficelassions',
    'ficellerions',
    'fidéjusseurs',
    'fidéjussions',
    'fidéjussoire',
    'fieffassions',
    'fiefferaient',
    'fientassions',
    'fienteraient',
    'fiers-à-bras',
    'fignolassent',
    'fignolassiez',
    'fignolerions',
    'figurassions',
    'figureraient',
    'filamenteuse',
    'filandreuses',
    'filetassions',
    'filèteraient',
    'filigranâmes',
    'filigranasse',
    'filigranâtes',
    'filigranerai',
    'filigraneras',
    'filigranerez',
    'filigranions',
    'filipendules',
    'filmographie',
    'filmothèques',
    'filochassent',
    'filochassiez',
    'filocherions',
    'filoutassent',
    'filoutassiez',
    'filouterions',
    'filtrassions',
    'filtreraient',
    'finalisation',
    'finalitaires',
    'finançassent',
    'finançassiez',
    'financements',
    'financerions',
    'finassassent',
    'finassassiez',
    'finasserions',
    'finlandaises',
    'finlandisais',
    'finlandisait',
    'finlandisant',
    'finlandisées',
    'finlandisent',
    'finlandisera',
    'finlandisiez',
    'finlandisons',
    'fiscalisâmes',
    'fiscalisasse',
    'fiscalisâtes',
    'fiscaliserai',
    'fiscaliseras',
    'fiscaliserez',
    'fiscalisions',
    'fissionnâmes',
    'fissionnasse',
    'fissionnâtes',
    'fissionnerai',
    'fissionneras',
    'fissionnerez',
    'fissionnions',
    'fissurassent',
    'fissurassiez',
    'fissurations',
    'fissurerions',
    'flagellaient',
    'flagellaires',
    'flagellasses',
    'flagellateur',
    'flagellation',
    'flagellerais',
    'flagellerait',
    'flagellèrent',
    'flagelleriez',
    'flagellerons',
    'flagelleront',
    'flageolaient',
    'flageolantes',
    'flageolasses',
    'flageolement',
    'flageolerais',
    'flageolerait',
    'flageolèrent',
    'flageoleriez',
    'flageolerons',
    'flageoleront',
    'flagornaient',
    'flagornasses',
    'flagornerais',
    'flagornerait',
    'flagornèrent',
    'flagorneries',
    'flagorneriez',
    'flagornerons',
    'flagorneront',
    'flagorneuses',
    'flairassions',
    'flaireraient',
    'flambassions',
    'flamberaient',
    'flamboiement',
    'flamboierais',
    'flamboierait',
    'flamboieriez',
    'flamboierons',
    'flamboieront',
    'flamboyaient',
    'flamboyantes',
    'flamboyasses',
    'flamboyèrent',
    'flamingantes',
    'flanchassent',
    'flanchassiez',
    'flancherions',
    'flandricisme',
    'flanquassent',
    'flanquassiez',
    'flanquements',
    'flanquerions',
    'flaquassions',
    'flaqueraient',
    'flashassions',
    'flasheraient',
    'flattassions',
    'flatteraient',
    'flaupassions',
    'flauperaient',
    'flavescentes',
    'fléchassions',
    'flécheraient',
    'fléchiraient',
    'fléchisseurs',
    'fléchissions',
    'flegmatiques',
    'flemmardâmes',
    'flemmardasse',
    'flemmardâtes',
    'flemmarderai',
    'flemmarderas',
    'flemmarderez',
    'flemmardions',
    'flemmardises',
    'flétriraient',
    'flétrissante',
    'flétrissants',
    'flétrissions',
    'flétrissures',
    'fleurassions',
    'fleurdelisée',
    'fleurdelisés',
    'fleureraient',
    'fleurettiste',
    'fleuriraient',
    'fleurissante',
    'fleurissants',
    'fleurissions',
    'flexibilités',
    'flexionnelle',
    'flibustaient',
    'flibustasses',
    'flibusterais',
    'flibusterait',
    'flibustèrent',
    'flibusteriez',
    'flibusterons',
    'flibusteront',
    'flinguassent',
    'flinguassiez',
    'flinguerions',
    'flippassions',
    'flipperaient',
    'flirtassions',
    'flirteraient',
    'floconnaient',
    'floconnantes',
    'floconnasses',
    'floconnerais',
    'floconnerait',
    'floconnèrent',
    'floconneriez',
    'floconnerons',
    'floconneront',
    'floconneuses',
    'floculassent',
    'floculassiez',
    'floculations',
    'floculerions',
    'floriculture',
    'florissantes',
    'flosculeuses',
    'flottabilité',
    'flottassions',
    'flotteraient',
    'flousassions',
    'flouseraient',
    'fluctuassent',
    'fluctuassiez',
    'fluctuations',
    'fluctuerions',
    'fluidifiâmes',
    'fluidifiante',
    'fluidifiants',
    'fluidifiasse',
    'fluidifiâtes',
    'fluidifierai',
    'fluidifieras',
    'fluidifierez',
    'fluidifiions',
    'fluidisaient',
    'fluidisasses',
    'fluidiserais',
    'fluidiserait',
    'fluidisèrent',
    'fluidiseriez',
    'fluidiserons',
    'fluidiseront',
    'fluorescéine',
    'fluorescence',
    'fluorescente',
    'fluorescents',
    'fluorisaient',
    'fluorisantes',
    'fluorisasses',
    'fluoriserais',
    'fluoriserait',
    'fluorisèrent',
    'fluoriseriez',
    'fluoriserons',
    'fluoriseront',
    'fluoruration',
    'fluotournage',
    'fluviomètres',
    'focalisaient',
    'focalisantes',
    'focalisasses',
    'focalisation',
    'focaliserais',
    'focaliserait',
    'focalisèrent',
    'focaliseriez',
    'focaliserons',
    'focaliseront',
    'foisonnaient',
    'foisonnantes',
    'foisonnasses',
    'foisonnement',
    'foisonnerais',
    'foisonnerait',
    'foisonnèrent',
    'foisonneriez',
    'foisonnerons',
    'foisonneront',
    'folâtrassent',
    'folâtrassiez',
    'folâtrerions',
    'folichonnais',
    'folichonnait',
    'folichonnant',
    'folichonnent',
    'folichonnera',
    'folichonniez',
    'folichonnons',
    'foliotassent',
    'foliotassiez',
    'folioterions',
    'folkloriques',
    'folkloristes',
    'folliculaire',
    'folliculines',
    'folliculites',
    'fomentassent',
    'fomentassiez',
    'fomentateurs',
    'fomentations',
    'fomentatrice',
    'fomenterions',
    'foncièrement',
    'fonctionnais',
    'fonctionnait',
    'fonctionnant',
    'fonctionnels',
    'fonctionnent',
    'fonctionnera',
    'fonctionniez',
    'fonctionnons',
    'fondamentale',
    'fondamentaux',
    'fongibilités',
    'footballeurs',
    'footballeuse',
    'forcissaient',
    'forfaisaient',
    'forfaitaires',
    'forfanteries',
    'forgeassions',
    'forjetassent',
    'forjetassiez',
    'forjetterais',
    'forjetterait',
    'forjetteriez',
    'forjetterons',
    'forjetteront',
    'forlançaient',
    'forlançasses',
    'forlancerais',
    'forlancerait',
    'forlancèrent',
    'forlanceriez',
    'forlancerons',
    'forlanceront',
    'forlignaient',
    'forlignasses',
    'forlignerais',
    'forlignerait',
    'forlignèrent',
    'forligneriez',
    'forlignerons',
    'forligneront',
    'formaldéhyde',
    'formalisâmes',
    'formalisante',
    'formalisants',
    'formalisasse',
    'formalisâtes',
    'formaliserai',
    'formaliseras',
    'formaliserez',
    'formalisions',
    'formellement',
    'formications',
    'formolassent',
    'formolassiez',
    'formolerions',
    'formulassent',
    'formulassiez',
    'formulations',
    'formulerions',
    'fornicateurs',
    'fornications',
    'fornicatrice',
    'forniquaient',
    'forniquasses',
    'forniquerais',
    'forniquerait',
    'forniquèrent',
    'forniqueriez',
    'forniquerons',
    'forniqueront',
    'fortifiaient',
    'fortifiantes',
    'fortifiasses',
    'fortifierais',
    'fortifierait',
    'fortifièrent',
    'fortifieriez',
    'fortifierons',
    'fortifieront',
    'fortraitures',
    'fortuitement',
    'fossilifères',
    'fossilisâmes',
    'fossilisasse',
    'fossilisâtes',
    'fossiliserai',
    'fossiliseras',
    'fossiliserez',
    'fossilisions',
    'fossoierions',
    'fossoyassent',
    'fossoyassiez',
    'fouaillaient',
    'fouaillasses',
    'fouaillerais',
    'fouaillerait',
    'fouaillèrent',
    'fouailleriez',
    'fouaillerons',
    'fouailleront',
    'foudroiement',
    'foudroierais',
    'foudroierait',
    'foudroieriez',
    'foudroierons',
    'foudroieront',
    'foudroyaient',
    'foudroyantes',
    'foudroyasses',
    'foudroyèrent',
    'fouettassent',
    'fouettassiez',
    'fouettements',
    'fouetterions',
    'fougeassions',
    'fouillassent',
    'fouillassiez',
    'fouillerions',
    'fouinassions',
    'fouineraient',
    'fouraillâmes',
    'fouraillasse',
    'fouraillâtes',
    'fouraillerai',
    'fourailleras',
    'fouraillerez',
    'fouraillions',
    'fourbassions',
    'fourberaient',
    'fourbiraient',
    'fourbissages',
    'fourbisseurs',
    'fourbissions',
    'fourchassent',
    'fourchassiez',
    'fourcherâmes',
    'fourcherasse',
    'fourcherâtes',
    'fourgonnâmes',
    'fourgonnasse',
    'fourgonnâtes',
    'fourgonnerai',
    'fourgonneras',
    'fourgonnerez',
    'fourgonnette',
    'fourgonnions',
    'fourguassent',
    'fourguassiez',
    'fourguerions',
    'fouriérismes',
    'fouriéristes',
    'fourmilières',
    'fourmillâmes',
    'fourmillante',
    'fourmillants',
    'fourmillasse',
    'fourmillâtes',
    'fourmillerai',
    'fourmilleras',
    'fourmillerez',
    'fourmillions',
    'fourniraient',
    'fournisseurs',
    'fournisseuse',
    'fournissions',
    'fourrageâmes',
    'fourrageasse',
    'fourrageâtes',
    'fourragerais',
    'fourragerait',
    'fourragèrent',
    'fourrageriez',
    'fourragerons',
    'fourrageront',
    'fourrassions',
    'fourreraient',
    'fourvoiement',
    'fourvoierais',
    'fourvoierait',
    'fourvoieriez',
    'fourvoierons',
    'fourvoieront',
    'fourvoyaient',
    'fourvoyantes',
    'fourvoyasses',
    'fourvoyèrent',
    'fox-terriers',
    'fracassaient',
    'fracassantes',
    'fracassasses',
    'fracasserais',
    'fracasserait',
    'fracassèrent',
    'fracasseriez',
    'fracasserons',
    'fracasseront',
    'fractionnais',
    'fractionnait',
    'fractionnant',
    'fractionnées',
    'fractionnels',
    'fractionnent',
    'fractionnera',
    'fractionniez',
    'fractionnons',
    'fracturaient',
    'fracturasses',
    'fracturerais',
    'fracturerait',
    'fracturèrent',
    'fractureriez',
    'fracturerons',
    'fractureront',
    'fragilisâmes',
    'fragilisante',
    'fragilisants',
    'fragilisasse',
    'fragilisâtes',
    'fragiliserai',
    'fragiliseras',
    'fragiliserez',
    'fragilisions',
    'fragmentaire',
    'fragmentâmes',
    'fragmentasse',
    'fragmentâtes',
    'fragmenterai',
    'fragmenteras',
    'fragmenterez',
    'fragmentions',
    'fraîchirions',
    'fraîchissais',
    'fraîchissait',
    'fraîchissant',
    'fraîchissent',
    'fraîchissiez',
    'fraîchissons',
    'fraisassions',
    'fraiseraient',
    'framboisâmes',
    'framboisasse',
    'framboisâtes',
    'framboiserai',
    'framboiseras',
    'framboiserez',
    'framboisiers',
    'framboisions',
    'franchirions',
    'franchisages',
    'franchissais',
    'franchissait',
    'franchissant',
    'franchissent',
    'franchissiez',
    'franchissons',
    'francisaient',
    'francisasses',
    'francisation',
    'franciscaine',
    'franciscains',
    'franciserais',
    'franciserait',
    'francisèrent',
    'franciseriez',
    'franciserons',
    'franciseront',
    'francophiles',
    'francophilie',
    'francophobes',
    'francophobie',
    'francophones',
    'francophonie',
    'franc-parler',
    'francs-bords',
    'franc-tireur',
    'frangeassent',
    'frangeassiez',
    'frangeraient',
    'frangipanier',
    'frappassions',
    'frapperaient',
    'fraternelles',
    'fraternisais',
    'fraternisait',
    'fraternisant',
    'fraternisent',
    'fraternisera',
    'fraternisiez',
    'fraternisons',
    'fraudassions',
    'frauderaient',
    'frauduleuses',
    'fredonnaient',
    'fredonnasses',
    'fredonnement',
    'fredonnerais',
    'fredonnerait',
    'fredonnèrent',
    'fredonneriez',
    'fredonnerons',
    'fredonneront',
    'frégatassent',
    'frégatassiez',
    'freinassions',
    'freineraient',
    'frelatassent',
    'frelatassiez',
    'frelaterions',
    'frémissaient',
    'frémissantes',
    'frémissement',
    'fréquentable',
    'fréquentâmes',
    'fréquentasse',
    'fréquentâtes',
    'fréquentatif',
    'fréquenterai',
    'fréquenteras',
    'fréquenterez',
    'fréquentiels',
    'fréquentions',
    'frétillaient',
    'frétillantes',
    'frétillasses',
    'frétillement',
    'frétillerais',
    'frétillerait',
    'frétillèrent',
    'frétilleriez',
    'frétillerons',
    'frétilleront',
    'frettassions',
    'fretteraient',
    'fricassaient',
    'fricassasses',
    'fricasserais',
    'fricasserait',
    'fricassèrent',
    'fricasseriez',
    'fricasserons',
    'fricasseront',
    'fricotassent',
    'fricotassiez',
    'fricoterions',
    'frictionnais',
    'frictionnait',
    'frictionnant',
    'frictionnées',
    'frictionnels',
    'frictionnent',
    'frictionnera',
    'frictionniez',
    'frictionnons',
    'frigidariums',
    'frigorifiais',
    'frigorifiait',
    'frigorifiant',
    'frigorifiées',
    'frigorifient',
    'frigorifiera',
    'frigorifiiez',
    'frigorifions',
    'frigorifique',
    'frigorifugea',
    'frigorifugée',
    'frigorifuger',
    'frigorifuges',
    'frigorifugés',
    'frigorifugez',
    'frileusement',
    'fringuassent',
    'fringuassiez',
    'fringuerions',
    'friponnaient',
    'friponnasses',
    'friponnerais',
    'friponnerait',
    'friponnèrent',
    'friponneries',
    'friponneriez',
    'friponnerons',
    'friponneront',
    'frisottaient',
    'frisottantes',
    'frisottasses',
    'frisotterais',
    'frisotterait',
    'frisottèrent',
    'frisotteriez',
    'frisotterons',
    'frisotteront',
    'frissonnâmes',
    'frissonnante',
    'frissonnants',
    'frissonnasse',
    'frissonnâtes',
    'frissonnerai',
    'frissonneras',
    'frissonnerez',
    'frissonnions',
    'fritillaires',
    'frittassions',
    'fritteraient',
    'froidiraient',
    'froidissions',
    'froissassent',
    'froissassiez',
    'froissements',
    'froisserions',
    'fronçassions',
    'fronceraient',
    'frondassions',
    'fronderaient',
    'frontalières',
    'frontispices',
    'frottassions',
    'frotteraient',
    'froufroutais',
    'froufroutait',
    'froufroutant',
    'froufroutent',
    'froufroutera',
    'froufroutiez',
    'froufroutons',
    'fructifiâmes',
    'fructifiante',
    'fructifiants',
    'fructifiasse',
    'fructifiâtes',
    'fructifierai',
    'fructifieras',
    'fructifierez',
    'fructifiions',
    'frumentacées',
    'frumentaires',
    'frusquassent',
    'frusquassiez',
    'frusquerions',
    'frustrassent',
    'frustrassiez',
    'frustrations',
    'frustratoire',
    'frustrerions',
    'frutescentes',
    'fugitivement',
    'fulgurassent',
    'fulgurassiez',
    'fulgurations',
    'fulgurerions',
    'fuligineuses',
    'fulminassent',
    'fulminassiez',
    'fulminations',
    'fulminatoire',
    'fulminerions',
    'fumigatoires',
    'fumigeassent',
    'fumigeassiez',
    'fumigeraient',
    'funiculaires',
    'furent-elles',
    'furetassions',
    'furèteraient',
    'furieusement',
    'furonculeuse',
    'furonculoses',
    'fuselassions',
    'fusellerions',
    'fusillassent',
    'fusillassiez',
    'fusillerions',
    'fusionnaient',
    'fusionnantes',
    'fusionnasses',
    'fusionnement',
    'fusionnerais',
    'fusionnerait',
    'fusionnèrent',
    'fusionneriez',
    'fusionnerons',
    'fusionneront',
    'fustigations',
    'fustigeaient',
    'fustigeasses',
    'fustigerions',
    'futurologies',
    'futurologues',
    'gabionnaient',
    'gabionnasses',
    'gabionnerais',
    'gabionnerait',
    'gabionnèrent',
    'gabionneriez',
    'gabionnerons',
    'gabionneront',
    'gadgétisâmes',
    'gadgétisasse',
    'gadgétisâtes',
    'gadgétiserai',
    'gadgétiseras',
    'gadgétiserez',
    'gadgétisions',
    'gaillardises',
    'gailleteries',
    'galactagogue',
    'galactogènes',
    'galactomètre',
    'galactophore',
    'galéjassions',
    'galéjeraient',
    'galipotaient',
    'galipotasses',
    'galipoterais',
    'galipoterait',
    'galipotèrent',
    'galipoteriez',
    'galipoterons',
    'galipoteront',
    'gallicanisme',
    'gallo-romain',
    'galonnassent',
    'galonnassiez',
    'galonnerions',
    'galopassions',
    'galoperaient',
    'galvanisâmes',
    'galvanisante',
    'galvanisants',
    'galvanisasse',
    'galvanisâtes',
    'galvaniserai',
    'galvaniseras',
    'galvaniserez',
    'galvanisions',
    'galvanomètre',
    'galvanotypes',
    'galvanotypie',
    'galvaudaient',
    'galvaudasses',
    'galvauderais',
    'galvauderait',
    'galvaudèrent',
    'galvauderiez',
    'galvauderons',
    'galvauderont',
    'galvaudeuses',
    'gambadassent',
    'gambadassiez',
    'gambaderions',
    'gambergeâmes',
    'gambergeasse',
    'gambergeâtes',
    'gambergerais',
    'gambergerait',
    'gambergèrent',
    'gambergeriez',
    'gambergerons',
    'gambergeront',
    'gambillaient',
    'gambillasses',
    'gambillerais',
    'gambillerait',
    'gambillèrent',
    'gambilleriez',
    'gambillerons',
    'gambilleront',
    'gaminassions',
    'gamineraient',
    'gangrenaient',
    'gangrenantes',
    'gangrenasses',
    'gangrènerais',
    'gangrènerait',
    'gangrenèrent',
    'gangrèneriez',
    'gangrènerons',
    'gangrèneront',
    'gangreneuses',
    'gangréneuses',
    'gangstérisme',
    'garançassent',
    'garançassiez',
    'garancerions',
    'garantirions',
    'garantissais',
    'garantissait',
    'garantissant',
    'garantissent',
    'garantissiez',
    'garantissons',
    'garçonnières',
    'garde-à-vous',
    'garde-chasse',
    'garde-malade',
    'garde-manger',
    'garde-meuble',
    'garde-nappes',
    'garden-party',
    'garde-places',
    'gardes-boeuf',
    'gardes-frein',
    'gardes-pêche',
    'gardes-voies',
    'gardiennages',
    'gargarisâmes',
    'gargarisante',
    'gargarisants',
    'gargarisasse',
    'gargarisâtes',
    'gargariserai',
    'gargariseras',
    'gargariserez',
    'gargarisions',
    'gargotassent',
    'gargotassiez',
    'gargoterions',
    'gargouillais',
    'gargouillait',
    'gargouillant',
    'gargouillent',
    'gargouillera',
    'gargouilliez',
    'gargouillons',
    'gargoulettes',
    'garibaldiens',
    'garnissaient',
    'garnisseuses',
    'garrottaient',
    'garrottasses',
    'garrotterais',
    'garrotterait',
    'garrottèrent',
    'garrotteriez',
    'garrotterons',
    'garrotteront',
    'gasconnaient',
    'gasconnasses',
    'gasconnerais',
    'gasconnerait',
    'gasconnèrent',
    'gasconneriez',
    'gasconnerons',
    'gasconneront',
    'gasconnismes',
    'gaspillaient',
    'gaspillasses',
    'gaspillerais',
    'gaspillerait',
    'gaspillèrent',
    'gaspilleriez',
    'gaspillerons',
    'gaspilleront',
    'gaspilleuses',
    'gastéropodes',
    'gastralgique',
    'gastrectomie',
    'gastronomies',
    'gastroscopie',
    'gastrotomies',
    'gâtifiassent',
    'gâtifiassiez',
    'gâtifierions',
    'gauchiraient',
    'gauchisantes',
    'gauchissions',
    'gaufrassions',
    'gaufreraient',
    'gauloisement',
    'gauloiseries',
    'gaussassions',
    'gausseraient',
    'gazéifiaient',
    'gazéifiasses',
    'gazéifierais',
    'gazéifierait',
    'gazéifièrent',
    'gazéifieriez',
    'gazéifierons',
    'gazéifieront',
    'gazonnassent',
    'gazonnassiez',
    'gazonnements',
    'gazonnerions',
    'gazouillâmes',
    'gazouillasse',
    'gazouillâtes',
    'gazouillerai',
    'gazouilleras',
    'gazouillerez',
    'gazouilleurs',
    'gazouilleuse',
    'gazouillions',
    'geignissions',
    'gélatinaient',
    'gélatinasses',
    'gélatinerais',
    'gélatinerait',
    'gélatinèrent',
    'gélatineriez',
    'gélatinerons',
    'gélatineront',
    'gélatineuses',
    'gélatinisais',
    'gélatinisait',
    'gélatinisant',
    'gélatinisées',
    'gélatinisent',
    'gélatinisera',
    'gélatinisiez',
    'gélatinisons',
    'gélifiassent',
    'gélifiassiez',
    'gélification',
    'gélifierions',
    'gémellipares',
    'géminassions',
    'gémineraient',
    'gémissements',
    'gemmologiste',
    'gendarmaient',
    'gendarmasses',
    'gendarmerais',
    'gendarmerait',
    'gendarmèrent',
    'gendarmeries',
    'gendarmeriez',
    'gendarmerons',
    'gendarmeront',
    'généalogique',
    'généalogiste',
    'généralement',
    'généralisais',
    'généralisait',
    'généralisant',
    'généralisées',
    'généralisent',
    'généralisera',
    'généralisiez',
    'généralisons',
    'généralistes',
    'générassions',
    'génératrices',
    'généreraient',
    'généthliaque',
    'généticienne',
    'genouillères',
    'gentillesses',
    'gentillettes',
    'génuflexions',
    'géocentrique',
    'géodynamique',
    'géographique',
    'géométriques',
    'géométrisais',
    'géométrisait',
    'géométrisant',
    'géométrisées',
    'géométrisent',
    'géométrisera',
    'géométrisiez',
    'géométrisons',
    'géophysicien',
    'géophysiques',
    'géopolitique',
    'géosynclinal',
    'géotechnique',
    'géothermique',
    'géotropismes',
    'gériatriques',
    'germanisâmes',
    'germanisasse',
    'germanisâtes',
    'germaniserai',
    'germaniseras',
    'germaniserez',
    'germanisions',
    'germanophile',
    'germanophobe',
    'germinateurs',
    'germinations',
    'germinatives',
    'germinatrice',
    'gérontologie',
    'gérontologue',
    'gérontophile',
    'gestaltismes',
    'gesticulâmes',
    'gesticulante',
    'gesticulants',
    'gesticulasse',
    'gesticulâtes',
    'gesticulerai',
    'gesticuleras',
    'gesticulerez',
    'gesticulions',
    'gestionnaire',
    'giboieraient',
    'giboyassions',
    'gigantesques',
    'gigotassions',
    'gigoteraient',
    'gironnassent',
    'gironnassiez',
    'gironnerions',
    'girouettâmes',
    'girouettasse',
    'girouettâtes',
    'girouetterai',
    'girouetteras',
    'girouetterez',
    'girouettions',
    'glaciologues',
    'glagolitique',
    'glairassions',
    'glaireraient',
    'glaisassions',
    'glaiseraient',
    'glandassions',
    'glanderaient',
    'glandouillai',
    'glandouillas',
    'glandouillât',
    'glandouiller',
    'glandouilles',
    'glandouillez',
    'glandulaires',
    'glanduleuses',
    'glapissaient',
    'glapissantes',
    'glapissement',
    'glatissaient',
    'glaviotaient',
    'glaviotasses',
    'glavioterais',
    'glavioterait',
    'glaviotèrent',
    'glavioteriez',
    'glavioterons',
    'glavioteront',
    'glaviottâmes',
    'glaviottasse',
    'glaviottâtes',
    'glaviotterai',
    'glaviotteras',
    'glaviotterez',
    'glaviottions',
    'glissassions',
    'glisseraient',
    'globalisâmes',
    'globalisante',
    'globalisants',
    'globalisasse',
    'globalisâtes',
    'globaliserai',
    'globaliseras',
    'globaliserez',
    'globalisions',
    'globigérines',
    'glockenspiel',
    'glorifiaient',
    'glorifiantes',
    'glorifiasses',
    'glorifierais',
    'glorifierait',
    'glorifièrent',
    'glorifieriez',
    'glorifierons',
    'glorifieront',
    'glossolalies',
    'glossotomies',
    'glougloutais',
    'glougloutait',
    'glougloutant',
    'glougloutent',
    'glougloutera',
    'glougloutiez',
    'glougloutons',
    'gloussassent',
    'gloussassiez',
    'gloussements',
    'glousserions',
    'gloutonnerie',
    'glycérinâmes',
    'glycérinasse',
    'glycérinâtes',
    'glycérinerai',
    'glycérineras',
    'glycérinerez',
    'glycérinions',
    'glycogénique',
    'glycolipides',
    'glycosurique',
    'glyptodontes',
    'glyptothèque',
    'gnoséologies',
    'gnosticismes',
    'gobeleteries',
    'gobe-mouches',
    'gobergeaient',
    'gobergeasses',
    'gobergerions',
    'gobetassions',
    'gobetterions',
    'godaillaient',
    'godaillasses',
    'godaillerais',
    'godaillerait',
    'godaillèrent',
    'godailleriez',
    'godaillerons',
    'godailleront',
    'godillassent',
    'godillassiez',
    'godillerions',
    'godronnaient',
    'godronnasses',
    'godronnerais',
    'godronnerait',
    'godronnèrent',
    'godronneriez',
    'godronnerons',
    'godronneront',
    'goguenardais',
    'goguenardait',
    'goguenardant',
    'goguenardent',
    'goguenardera',
    'goguenardiez',
    'goguenardise',
    'goguenardons',
    'goinfrassent',
    'goinfrassiez',
    'goinfrerions',
    'gominassions',
    'gomineraient',
    'gomme-résine',
    'gomorrhéenne',
    'gonadotropes',
    'gondolassent',
    'gondolassiez',
    'gondolements',
    'gondolerions',
    'gonfaloniers',
    'gonfanoniers',
    'gonflassions',
    'gonfleraient',
    'goniométries',
    'gonochorisme',
    'gorgeassions',
    'gouachassent',
    'gouachassiez',
    'gouacherions',
    'gouaillaient',
    'gouaillantes',
    'gouaillasses',
    'gouaillerais',
    'gouaillerait',
    'gouaillèrent',
    'gouailleries',
    'gouailleriez',
    'gouaillerons',
    'gouailleront',
    'gouailleuses',
    'goudronnages',
    'goudronnâmes',
    'goudronnasse',
    'goudronnâtes',
    'goudronnerai',
    'goudronneras',
    'goudronnerez',
    'goudronneurs',
    'goudronneuse',
    'goudronnions',
    'goujonnaient',
    'goujonnasses',
    'goujonnerais',
    'goujonnerait',
    'goujonnèrent',
    'goujonneriez',
    'goujonnerons',
    'goujonneront',
    'goujonnières',
    'goupillaient',
    'goupillasses',
    'goupillerais',
    'goupillerait',
    'goupillèrent',
    'goupilleriez',
    'goupillerons',
    'goupilleront',
    'goupillonnai',
    'goupillonnas',
    'goupillonnât',
    'goupillonnée',
    'goupillonner',
    'goupillonnes',
    'goupillonnés',
    'goupillonnez',
    'gourgandines',
    'gourmandâmes',
    'gourmandasse',
    'gourmandâtes',
    'gourmanderai',
    'gourmanderas',
    'gourmanderez',
    'gourmandions',
    'gourmandises',
    'gouttassions',
    'gouttelettes',
    'goutteraient',
    'gouvernables',
    'gouvernaient',
    'gouvernances',
    'gouvernantes',
    'gouvernasses',
    'gouvernement',
    'gouvernerais',
    'gouvernerait',
    'gouvernèrent',
    'gouverneriez',
    'gouvernerons',
    'gouverneront',
    'graciassions',
    'gracieraient',
    'gracieusetés',
    'graduassions',
    'gradueraient',
    'graillassent',
    'graillassiez',
    'graillements',
    'graillerions',
    'graillonnais',
    'graillonnait',
    'graillonnant',
    'graillonnent',
    'graillonnera',
    'graillonniez',
    'graillonnons',
    'grainassions',
    'graineraient',
    'graineteries',
    'grainetières',
    'graissassent',
    'graissassiez',
    'graisserions',
    'grammairiens',
    'grammaticale',
    'grammaticaux',
    'grammatistes',
    'grand-ducale',
    'grand-ducaux',
    'grandelettes',
    'grandiraient',
    'grandissante',
    'grandissants',
    'grandissions',
    'grand-mamans',
    'grand-messes',
    'grands-croix',
    'grands-mères',
    'grands-papas',
    'grands-pères',
    'grand-tantes',
    'grand-voiles',
    'granitassent',
    'granitassiez',
    'graniterions',
    'granny-smith',
    'granulassent',
    'granulassiez',
    'granulations',
    'granulerions',
    'granulocytes',
    'grape-fruits',
    'graphitaient',
    'graphitasses',
    'graphiterais',
    'graphiterait',
    'graphitèrent',
    'graphiteriez',
    'graphiterons',
    'graphiteront',
    'graphiteuses',
    'graphologies',
    'graphologues',
    'graphomètres',
    'grappillages',
    'grappillâmes',
    'grappillasse',
    'grappillâtes',
    'grappillerai',
    'grappilleras',
    'grappillerez',
    'grappilleurs',
    'grappilleuse',
    'grappillions',
    'gras-doubles',
    'grasseyaient',
    'grasseyasses',
    'grasseyement',
    'grasseyerais',
    'grasseyerait',
    'grasseyèrent',
    'grasseyeriez',
    'grasseyerons',
    'grasseyeront',
    'grassouillet',
    'graticulâmes',
    'graticulasse',
    'graticulâtes',
    'graticulerai',
    'graticuleras',
    'graticulerez',
    'graticulions',
    'gratifiaient',
    'gratifiantes',
    'gratifiasses',
    'gratifierais',
    'gratifierait',
    'gratifièrent',
    'gratifieriez',
    'gratifierons',
    'gratifieront',
    'gratinassent',
    'gratinassiez',
    'gratinerions',
    'grattassions',
    'gratteraient',
    'gratuitement',
    'gravimétries',
    'gravissaient',
    'gravitassent',
    'gravitassiez',
    'gravitations',
    'graviterions',
    'grécisassent',
    'grécisassiez',
    'gréciserions',
    'gréco-romain',
    'grecquassent',
    'grecquassiez',
    'grecquerions',
    'greffassions',
    'grefferaient',
    'grégoriennes',
    'grelottaient',
    'grelottantes',
    'grelottasses',
    'grelottement',
    'grelotterais',
    'grelotterait',
    'grelottèrent',
    'grelotteriez',
    'grelotterons',
    'grelotteront',
    'grenaillâmes',
    'grenaillasse',
    'grenaillâtes',
    'grenaillerai',
    'grenailleras',
    'grenaillerez',
    'grenaillions',
    'grenelassent',
    'grenelassiez',
    'grenellerais',
    'grenellerait',
    'grenelleriez',
    'grenellerons',
    'grenelleront',
    'grenouillage',
    'grenouillais',
    'grenouillait',
    'grenouillant',
    'grenouillent',
    'grenouillera',
    'grenouillère',
    'grenouilliez',
    'grenouillons',
    'grésillement',
    'gribouillage',
    'gribouillais',
    'gribouillait',
    'gribouillant',
    'gribouillées',
    'gribouillent',
    'gribouillera',
    'gribouilleur',
    'gribouilliez',
    'gribouillons',
    'griffassions',
    'grifferaient',
    'griffonnages',
    'griffonnâmes',
    'griffonnasse',
    'griffonnâtes',
    'griffonnerai',
    'griffonneras',
    'griffonnerez',
    'griffonneurs',
    'griffonneuse',
    'griffonnions',
    'grignassions',
    'grigneraient',
    'grignotaient',
    'grignotasses',
    'grignotement',
    'grignoterais',
    'grignoterait',
    'grignotèrent',
    'grignoteriez',
    'grignoterons',
    'grignoteront',
    'grignoteuses',
    'grillageâmes',
    'grillageasse',
    'grillageâtes',
    'grillagerais',
    'grillagerait',
    'grillagèrent',
    'grillageriez',
    'grillagerons',
    'grillageront',
    'grillassions',
    'grilleraient',
    'grimaçassent',
    'grimaçassiez',
    'grimacerions',
    'grimpassions',
    'grimperaient',
    'grinçassions',
    'grinceraient',
    'grinchassent',
    'grinchassiez',
    'grincherions',
    'gringuassent',
    'gringuassiez',
    'gringuerions',
    'grippassions',
    'gripperaient',
    'grisaillâmes',
    'grisaillasse',
    'grisaillâtes',
    'grisaillerai',
    'grisailleras',
    'grisaillerez',
    'grisaillions',
    'grisolassent',
    'grisolassiez',
    'grisolerions',
    'grisollaient',
    'grisollasses',
    'grisollerais',
    'grisollerait',
    'grisollèrent',
    'grisolleriez',
    'grisollerons',
    'grisolleront',
    'grisonnaient',
    'grisonnantes',
    'grisonnasses',
    'grisonnement',
    'grisonnerais',
    'grisonnerait',
    'grisonnèrent',
    'grisonneriez',
    'grisonnerons',
    'grisonneront',
    'grisoumètres',
    'grisouteuses',
    'grivelassent',
    'grivelassiez',
    'grivellerais',
    'grivellerait',
    'grivelleriez',
    'grivellerons',
    'grivelleront',
    'grivoiseries',
    'groenendaels',
    'grognassâmes',
    'grognassasse',
    'grognassâtes',
    'grognasserai',
    'grognasseras',
    'grognasserez',
    'grognassions',
    'grogneraient',
    'grommelaient',
    'grommelasses',
    'grommelèrent',
    'grommellerai',
    'grommelleras',
    'grommellerez',
    'grondassions',
    'gronderaient',
    'groseilliers',
    'gros-porteur',
    'grossièretés',
    'grossiraient',
    'grossissante',
    'grossissants',
    'grossissions',
    'grossoierais',
    'grossoierait',
    'grossoieriez',
    'grossoierons',
    'grossoieront',
    'grossoyaient',
    'grossoyasses',
    'grossoyèrent',
    'grouillaient',
    'grouillantes',
    'grouillement',
    'grouillerais',
    'grouillerait',
    'grouilleriez',
    'grouillerons',
    'grouilleront',
    'groullassent',
    'groullassiez',
    'groupassions',
    'grouperaient',
    'groupuscules',
    'grugeassions',
    'grumelassent',
    'grumelassiez',
    'grumellerais',
    'grumellerait',
    'grumelleriez',
    'grumellerons',
    'grumelleront',
    'guérissables',
    'guérissaient',
    'guérissantes',
    'guérisseuses',
    'guerroierais',
    'guerroierait',
    'guerroieriez',
    'guerroierons',
    'guerroieront',
    'guerroyaient',
    'guerroyasses',
    'guerroyèrent',
    'guêtrassions',
    'guêtreraient',
    'guettassions',
    'guetteraient',
    'gueulassions',
    'gueuleraient',
    'gueuletonnai',
    'gueuletonnas',
    'gueuletonnât',
    'gueuletonner',
    'gueuletonnes',
    'gueuletonnez',
    'gueusassions',
    'gueuseraient',
    'guichetières',
    'guignassions',
    'guigneraient',
    'guillemetais',
    'guillemetait',
    'guillemetant',
    'guillemetées',
    'guillemetiez',
    'guillemetons',
    'guillemettes',
    'guillerettes',
    'guillochages',
    'guillochâmes',
    'guillochasse',
    'guillochâtes',
    'guillocherai',
    'guillocheras',
    'guillocherez',
    'guillocheurs',
    'guillochions',
    'guillochures',
    'guillotinais',
    'guillotinait',
    'guillotinant',
    'guillotinées',
    'guillotinent',
    'guillotinera',
    'guillotiniez',
    'guillotinons',
    'guinchassent',
    'guinchassiez',
    'guincherions',
    'guindassions',
    'guinderaient',
    'guinderesses',
    'gustométries',
    'gutta-percha',
    'gymnasiarque',
    'gymnastiques',
    'gymnospermes',
    'gynécologies',
    'gynécologues',
    'gynécomastie',
    'gyroscopique',
    'habilitaient',
    'habilitasses',
    'habilitation',
    'habiliterais',
    'habiliterait',
    'habilitèrent',
    'habiliteriez',
    'habiliterons',
    'habiliteront',
    'habillassent',
    'habillassiez',
    'habillements',
    'habillerions',
    'habitabilité',
    'habitassions',
    'habiteraient',
    'habituassent',
    'habituassiez',
    'habituations',
    'habituerions',
    'hache-paille',
    'hache-viande',
    'hachurassent',
    'hachurassiez',
    'hachurerions',
    'hagiographes',
    'hagiographie',
    'haillonneuse',
    'haineusement',
    'haletassions',
    'halèteraient',
    'halieutiques',
    'hallebardier',
    'hallstattien',
    'hallucinâmes',
    'hallucinante',
    'hallucinants',
    'hallucinasse',
    'hallucinâtes',
    'hallucinerai',
    'hallucineras',
    'hallucinerez',
    'hallucinions',
    'hallucinoses',
    'halogénation',
    'halographies',
    'haltérophile',
    'hameçonnâmes',
    'hameçonnasse',
    'hameçonnâtes',
    'hameçonnerai',
    'hameçonneras',
    'hameçonnerez',
    'hameçonnions',
    'hanchassions',
    'hancheraient',
    'handballeurs',
    'handballeuse',
    'handicapâmes',
    'handicapasse',
    'handicapâtes',
    'handicaperai',
    'handicaperas',
    'handicaperez',
    'handicapeurs',
    'handicapions',
    'hannetonnage',
    'hannetonnais',
    'hannetonnait',
    'hannetonnant',
    'hannetonnent',
    'hannetonnera',
    'hannetonniez',
    'hannetonnons',
    'hanséatiques',
    'haranguaient',
    'haranguasses',
    'haranguerais',
    'haranguerait',
    'haranguèrent',
    'harangueriez',
    'haranguerons',
    'harangueront',
    'harangueuses',
    'harassassent',
    'harassassiez',
    'harassements',
    'harasserions',
    'harcelassent',
    'harcelassiez',
    'harcèlements',
    'harcèlerions',
    'harcellement',
    'harcellerais',
    'harcellerait',
    'harcelleriez',
    'harcellerons',
    'harcelleront',
    'harengaisons',
    'harmoniciste',
    'harmonicorde',
    'harmonieuses',
    'harmonisâmes',
    'harmonisasse',
    'harmonisâtes',
    'harmoniserai',
    'harmoniseras',
    'harmoniserez',
    'harmonisions',
    'harnachaient',
    'harnachasses',
    'harnachement',
    'harnacherais',
    'harnacherait',
    'harnachèrent',
    'harnacheriez',
    'harnacherons',
    'harnacheront',
    'harpaillâmes',
    'harpaillasse',
    'harpaillâtes',
    'harpaillerai',
    'harpailleras',
    'harpaillerez',
    'harpaillions',
    'harponnaient',
    'harponnasses',
    'harponnement',
    'harponnerais',
    'harponnerait',
    'harponnèrent',
    'harponneriez',
    'harponnerons',
    'harponneront',
    'hasardassent',
    'hasardassiez',
    'hasarderions',
    'haschischins',
    'haubanassent',
    'haubanassiez',
    'haubanerions',
    'haussassions',
    'hausseraient',
    'haute-contre',
    'haut-parleur',
    'hebdomadaire',
    'hebdomadière',
    'hebdomadiers',
    'hébéphrénies',
    'hébergeaient',
    'hébergeasses',
    'hébergements',
    'hébergerions',
    'hébétassions',
    'hébéteraient',
    'hébraïsaient',
    'hébraïsantes',
    'hébraïsasses',
    'hébraïserais',
    'hébraïserait',
    'hébraïsèrent',
    'hébraïseriez',
    'hébraïserons',
    'hébraïseront',
    'hectogrammes',
    'hégélianisme',
    'hégémoniques',
    'hélianthèmes',
    'hélianthines',
    'hélicoïdales',
    'hélicoptères',
    'héliochromie',
    'héliographes',
    'héliographie',
    'héliograveur',
    'héliogravure',
    'héliomarines',
    'héliotropine',
    'héliportages',
    'hellénisâmes',
    'hellénisante',
    'hellénisants',
    'hellénisasse',
    'hellénisâtes',
    'helléniserai',
    'helléniseras',
    'helléniserez',
    'hellénisions',
    'helminthiase',
    'helminthique',
    'hémarthroses',
    'hématidroses',
    'hématologies',
    'hématologues',
    'hématopoïèse',
    'hématozoaire',
    'héméralopies',
    'hémérocalles',
    'hémiédriques',
    'hémiparésies',
    'hémiplégique',
    'hémocultures',
    'hémocyanines',
    'hémodialyses',
    'hémoglobines',
    'hémolytiques',
    'hémoptysique',
    'hémorragique',
    'hémorroïdale',
    'hémorroïdaux',
    'hémostatique',
    'hendécagones',
    'hennissaient',
    'hennissantes',
    'hennissement',
    'hépatisation',
    'hépatologies',
    'heptagonales',
    'herbageaient',
    'herbageasses',
    'herbagerions',
    'herborisâmes',
    'herborisasse',
    'herborisâtes',
    'herboriserai',
    'herboriseras',
    'herboriserez',
    'herborisions',
    'herculéennes',
    'hercyniennes',
    'héréditaires',
    'hérésiarques',
    'hérissassent',
    'hérissassiez',
    'hérissements',
    'hérisserions',
    'hérissonnais',
    'hérissonnait',
    'hérissonnant',
    'hérissonnées',
    'hérissonnent',
    'hérissonnera',
    'hérissonniez',
    'hérissonnons',
    'héritassions',
    'hériteraient',
    'herméticités',
    'héroïnomanes',
    'héroïquement',
    'herpétologie',
    'hésitassions',
    'hésiteraient',
    'hétérocerque',
    'hétéroclites',
    'hétérodoxies',
    'hétérogamies',
    'hétérogénies',
    'hétérogreffe',
    'hétérologues',
    'hétéromorphe',
    'hétérosexuel',
    'hétérotrophe',
    'hétérozygote',
    'heures-homme',
    'heureusement',
    'heuristiques',
    'heurtassions',
    'heurteraient',
    'hexadécimale',
    'hexadécimaux',
    'hibernassent',
    'hibernassiez',
    'hibernations',
    'hibernerions',
    'hiérarchique',
    'hiérarchisai',
    'hiérarchisas',
    'hiérarchisât',
    'hiérarchisée',
    'hiérarchiser',
    'hiérarchises',
    'hiérarchisés',
    'hiérarchisez',
    'hiéroglyphes',
    'himalayennes',
    'hindoustanis',
    'hippiatrique',
    'hippogriffes',
    'hippologique',
    'hippomobiles',
    'hippopotames',
    'hispanisante',
    'hispanisants',
    'histaminique',
    'histogenèses',
    'histogrammes',
    'histologique',
    'historiaient',
    'historiasses',
    'historicités',
    'historiennes',
    'historierais',
    'historierait',
    'historièrent',
    'historieriez',
    'historierons',
    'historieront',
    'historiettes',
    'hitlériennes',
    'hivernassent',
    'hivernassiez',
    'hivernerions',
    'hollandaises',
    'hollywoodien',
    'holographies',
    'holoprotéine',
    'homéopathies',
    'homéostasies',
    'homéothermes',
    'homilétiques',
    'hominisation',
    'homochromies',
    'homocyclique',
    'homogénéifia',
    'homogénéifie',
    'homogénéifié',
    'homogénéisai',
    'homogénéisas',
    'homogénéisât',
    'homogénéisée',
    'homogénéiser',
    'homogénéises',
    'homogénéisés',
    'homogénéisez',
    'homogénéités',
    'homologation',
    'homologuâmes',
    'homologuasse',
    'homologuâtes',
    'homologuerai',
    'homologueras',
    'homologuerez',
    'homologuions',
    'homonymiques',
    'homosexuelle',
    'homothétique',
    'hongrassions',
    'hongreraient',
    'hongroierais',
    'hongroierait',
    'hongroieries',
    'hongroieriez',
    'hongroierons',
    'hongroieront',
    'hongroyaient',
    'hongroyasses',
    'hongroyèrent',
    'honnissaient',
    'honorabilité',
    'honorassions',
    'honoreraient',
    'honorifiques',
    'honteusement',
    'hoquetassent',
    'hoquetassiez',
    'hoquetterais',
    'hoquetterait',
    'hoquetteriez',
    'hoquetterons',
    'hoquetteront',
    'horizontales',
    'hormonassent',
    'hormonassiez',
    'hormonerions',
    'horriblement',
    'horrifiaient',
    'horrifiantes',
    'horrifiasses',
    'horrifierais',
    'horrifierait',
    'horrifièrent',
    'horrifieriez',
    'horrifierons',
    'horrifieront',
    'horripilâmes',
    'horripilante',
    'horripilants',
    'horripilasse',
    'horripilâtes',
    'horripilerai',
    'horripileras',
    'horripilerez',
    'horripilions',
    'horse-guards',
    'horticulteur',
    'horticulture',
    'hospitalière',
    'hospitaliers',
    'hospitalisai',
    'hospitalisas',
    'hospitalisât',
    'hospitalisée',
    'hospitaliser',
    'hospitalises',
    'hospitalisés',
    'hospitalisez',
    'hospitalisme',
    'hospitalités',
    'hostelleries',
    'houblonnages',
    'houblonnâmes',
    'houblonnasse',
    'houblonnâtes',
    'houblonnerai',
    'houblonneras',
    'houblonnerez',
    'houblonnière',
    'houblonniers',
    'houblonnions',
    'houppassions',
    'houppelandes',
    'houpperaient',
    'hourdassions',
    'hourderaient',
    'hourdiraient',
    'hourdissions',
    'houspillâmes',
    'houspillasse',
    'houspillâtes',
    'houspillerai',
    'houspilleras',
    'houspillerez',
    'houspilleurs',
    'houspilleuse',
    'houspillions',
    'houssassions',
    'housseraient',
    'huitièmement',
    'huit-reflets',
    'hululassions',
    'hululeraient',
    'humanisaient',
    'humanisasses',
    'humanisation',
    'humaniserais',
    'humaniserait',
    'humanisèrent',
    'humaniseriez',
    'humaniserons',
    'humaniseront',
    'humanitaires',
    'humectassent',
    'humectassiez',
    'humecterions',
    'humidifiâmes',
    'humidifiasse',
    'humidifiâtes',
    'humidifierai',
    'humidifieras',
    'humidifierez',
    'humidifiions',
    'humification',
    'humiliassent',
    'humiliassiez',
    'humiliations',
    'humilierions',
    'humoristique',
    'hurluberlues',
    'hybridassent',
    'hybridassiez',
    'hybridations',
    'hybriderions',
    'hydarthroses',
    'hydratassent',
    'hydratassiez',
    'hydratations',
    'hydraterions',
    'hydraulicien',
    'hydrauliques',
    'hydrocarboné',
    'hydrocarbure',
    'hydrocéphale',
    'hydrocotyles',
    'hydrocutions',
    'hydrofugeais',
    'hydrofugeait',
    'hydrofugeant',
    'hydrofugeons',
    'hydrofugerai',
    'hydrofugeras',
    'hydrofugerez',
    'hydrofugions',
    'hydrogénâmes',
    'hydrogénasse',
    'hydrogénâtes',
    'hydrogénerai',
    'hydrogéneras',
    'hydrogénerez',
    'hydrogénions',
    'hydrographes',
    'hydrographie',
    'hydrologique',
    'hydrologiste',
    'hydrolysable',
    'hydrolysâmes',
    'hydrolysasse',
    'hydrolysâtes',
    'hydrolyserai',
    'hydrolyseras',
    'hydrolyserez',
    'hydrolysions',
    'hydrométéore',
    'hydrométries',
    'hydrominéral',
    'hydrophobies',
    'hydroquinone',
    'hydrosoluble',
    'hydrosphères',
    'hygrométries',
    'hygroscopies',
    'hyperacidité',
    'hyperacousie',
    'hyperactives',
    'hyperalgésie',
    'hyperbolique',
    'hyperboloïde',
    'hyperboréens',
    'hypercapnies',
    'hyperchromes',
    'hyperchromie',
    'hypercorrect',
    'hypergenèses',
    'hyperlipémie',
    'hypermarchés',
    'hypermétrope',
    'hypermnésies',
    'hypernerveux',
    'hyperplasies',
    'hypersonique',
    'hypertendues',
    'hypertension',
    'hyperthermie',
    'hypertrophie',
    'hypertrophié',
    'hypnotisâmes',
    'hypnotisasse',
    'hypnotisâtes',
    'hypnotiserai',
    'hypnotiseras',
    'hypnotiserez',
    'hypnotiseurs',
    'hypnotiseuse',
    'hypnotisions',
    'hypoacousies',
    'hypochloreux',
    'hypochlorite',
    'hypocondries',
    'hypodermique',
    'hypodermoses',
    'hypoglycémie',
    'hypolipémies',
    'hypophysaire',
    'hypostasiais',
    'hypostasiait',
    'hypostasiant',
    'hypostasiées',
    'hypostasient',
    'hypostasiera',
    'hypostasiiez',
    'hypostasions',
    'hypostatique',
    'hyposulfites',
    'hypotensions',
    'hypothécable',
    'hypothécaire',
    'hypothéquais',
    'hypothéquait',
    'hypothéquant',
    'hypothéquées',
    'hypothèquent',
    'hypothéquera',
    'hypothéquiez',
    'hypothéquons',
    'hypothermies',
    'hypothétique',
    'hypotoniques',
    'hypotrophies',
    'ichtyocolles',
    'ichtyologies',
    'ichtyophages',
    'ichtyosaures',
    'iconoclasmes',
    'iconoclastes',
    'iconoclastie',
    'iconographes',
    'iconographie',
    'iconolâtries',
    'idéalisaient',
    'idéalisasses',
    'idéalisateur',
    'idéalisation',
    'idéaliserais',
    'idéaliserait',
    'idéalisèrent',
    'idéaliseriez',
    'idéaliserons',
    'idéaliseront',
    'idées-forces',
    'identifiable',
    'identifiâmes',
    'identifiasse',
    'identifiâtes',
    'identifierai',
    'identifieras',
    'identifierez',
    'identifiions',
    'idéographies',
    'idéologiques',
    'idéologisais',
    'idéologisait',
    'idéologisant',
    'idéologisées',
    'idéologisent',
    'idéologisera',
    'idéologisiez',
    'idéologisons',
    'idiomatiques',
    'idiopathique',
    'idiotifiâmes',
    'idiotifiasse',
    'idiotifiâtes',
    'idiotifierai',
    'idiotifieras',
    'idiotifierez',
    'idiotifiions',
    'idiotisaient',
    'idiotisasses',
    'idiotiserais',
    'idiotiserait',
    'idiotisèrent',
    'idiotiseriez',
    'idiotiserons',
    'idiotiseront',
    'idolâtraient',
    'idolâtrasses',
    'idolâtrerais',
    'idolâtrerait',
    'idolâtrèrent',
    'idolâtreriez',
    'idolâtrerons',
    'idolâtreront',
    'idolâtriques',
    'ignifugation',
    'ignifugeâmes',
    'ignifugeants',
    'ignifugeasse',
    'ignifugeâtes',
    'ignifugerais',
    'ignifugerait',
    'ignifugèrent',
    'ignifugeriez',
    'ignifugerons',
    'ignifugeront',
    'ignipuncture',
    'ignominieuse',
    'ignorassions',
    'ignoreraient',
    'iléo-caecale',
    'iléo-caecaux',
    'illégalement',
    'illégitimité',
    'illettrismes',
    'illicitement',
    'illisibilité',
    'illuminaient',
    'illuminasses',
    'illumination',
    'illuminerais',
    'illuminerait',
    'illuminèrent',
    'illumineriez',
    'illuminerons',
    'illumineront',
    'illuminismes',
    'illusionnais',
    'illusionnait',
    'illusionnant',
    'illusionnées',
    'illusionnent',
    'illusionnera',
    'illusionniez',
    'illusionnons',
    'illustraient',
    'illustrasses',
    'illustrateur',
    'illustration',
    'illustrerais',
    'illustrerait',
    'illustrèrent',
    'illustreriez',
    'illustrerons',
    'illustreront',
    'imageassions',
    'imaginassent',
    'imaginassiez',
    'imaginations',
    'imaginatives',
    'imaginerions',
    'imbécillités',
    'imbibassions',
    'imbiberaient',
    'imbrications',
    'imbriquaient',
    'imbriquasses',
    'imbriquerais',
    'imbriquerait',
    'imbriquèrent',
    'imbriqueriez',
    'imbriquerons',
    'imbriqueront',
    'immanentisme',
    'immangeables',
    'immanquables',
    'immatérielle',
    'immatriculai',
    'immatriculas',
    'immatriculât',
    'immatriculée',
    'immatriculer',
    'immatricules',
    'immatriculés',
    'immatriculez',
    'immédiatetés',
    'immémoriales',
    'immensurable',
    'immergeaient',
    'immergeasses',
    'immergerions',
    'immigrassent',
    'immigrassiez',
    'immigrations',
    'immigrerions',
    'immisçassiez',
    'immiscerions',
    'immobilières',
    'immobilisais',
    'immobilisait',
    'immobilisant',
    'immobilisées',
    'immobilisent',
    'immobilisera',
    'immobilisiez',
    'immobilismes',
    'immobilisons',
    'immobilistes',
    'immodérément',
    'immolassions',
    'immoleraient',
    'immoralement',
    'immoralismes',
    'immoralistes',
    'immortalisai',
    'immortalisas',
    'immortalisât',
    'immortalisée',
    'immortaliser',
    'immortalises',
    'immortalisés',
    'immortalisez',
    'immortalités',
    'immuabilités',
    'immuablement',
    'immunisaient',
    'immunisantes',
    'immunisasses',
    'immunisation',
    'immuniserais',
    'immuniserait',
    'immunisèrent',
    'immuniseriez',
    'immuniserons',
    'immuniseront',
    'immunitaires',
    'immunochimie',
    'immunologies',
    'immutabilité',
    'impactassent',
    'impactassiez',
    'impacterions',
    'impaludation',
    'imparidigité',
    'imparipennée',
    'imparipennés',
    'impartialité',
    'impartirions',
    'impartissais',
    'impartissait',
    'impartissant',
    'impartissent',
    'impartissiez',
    'impartissons',
    'impatiemment',
    'impatientais',
    'impatientait',
    'impatientant',
    'impatientées',
    'impatientent',
    'impatientera',
    'impatientiez',
    'impatientons',
    'impatronisai',
    'impatronisas',
    'impatronisât',
    'impatronisée',
    'impatroniser',
    'impatronises',
    'impatronisés',
    'impatronisez',
    'impeachments',
    'impécunieuse',
    'impénétrable',
    'impénitences',
    'impénitentes',
    'impératrices',
    'imperfectifs',
    'imperfection',
    'imperfective',
    'impérialisme',
    'impérialiste',
    'impérissable',
    'imperméables',
    'impersonnels',
    'impertinence',
    'impertinente',
    'impertinents',
    'impétigineux',
    'impétrassent',
    'impétrassiez',
    'impétrations',
    'impétrerions',
    'impétuosités',
    'impitoyables',
    'implantaient',
    'implantasses',
    'implantation',
    'implanterais',
    'implanterait',
    'implantèrent',
    'implanteriez',
    'implanterons',
    'implanteront',
    'implémentais',
    'implémentait',
    'implémentant',
    'implémentées',
    'implémentent',
    'implémentera',
    'implémentiez',
    'implémentons',
    'implications',
    'impliquaient',
    'impliquasses',
    'impliquerais',
    'impliquerait',
    'impliquèrent',
    'impliqueriez',
    'impliquerons',
    'impliqueront',
    'implorassent',
    'implorassiez',
    'implorations',
    'implorerions',
    'implosassent',
    'implosassiez',
    'imploserions',
    'impolitesses',
    'impolitiques',
    'impondérable',
    'impopulaires',
    'impopularité',
    'importassent',
    'importassiez',
    'importateurs',
    'importations',
    'importatrice',
    'importerions',
    'importunâmes',
    'importunasse',
    'importunâtes',
    'importunerai',
    'importuneras',
    'importunerez',
    'importunions',
    'importunités',
    'imposassions',
    'imposeraient',
    'impraticable',
    'imprécations',
    'imprécatoire',
    'imprécisions',
    'imprégnaient',
    'imprégnasses',
    'imprégnation',
    'imprégnerais',
    'imprégnerait',
    'imprégnèrent',
    'imprégneriez',
    'imprégnerons',
    'imprégneront',
    'impressionna',
    'impressionne',
    'impressionné',
    'imprévisible',
    'imprévisions',
    'imprévoyance',
    'imprévoyante',
    'imprévoyants',
    'imprimassent',
    'imprimassiez',
    'imprimerions',
    'improbations',
    'improductifs',
    'improductive',
    'improprement',
    'impropriétés',
    'improuvables',
    'improuvaient',
    'improuvasses',
    'improuverais',
    'improuverait',
    'improuvèrent',
    'improuveriez',
    'improuverons',
    'improuveront',
    'improvisâmes',
    'improvisasse',
    'improvisâtes',
    'improviserai',
    'improviseras',
    'improviserez',
    'improvisions',
    'imprudemment',
    'impubliables',
    'impuissances',
    'impuissantes',
    'impulsassent',
    'impulsassiez',
    'impulserions',
    'impulsivités',
    'imputassions',
    'imputeraient',
    'inabordables',
    'inaccentuées',
    'inacceptable',
    'inaccessible',
    'inaccomplies',
    'inaccordable',
    'inaccoutumée',
    'inaccoutumés',
    'inachèvement',
    'inactivaient',
    'inactivasses',
    'inactivation',
    'inactiverais',
    'inactiverait',
    'inactivèrent',
    'inactiveriez',
    'inactiverons',
    'inactiveront',
    'inadaptables',
    'inadaptation',
    'inadéquation',
    'inadmissible',
    'inadvertance',
    'inaliénables',
    'inaliénation',
    'inaltérables',
    'inamissibles',
    'inanalysable',
    'inapaisables',
    'inapplicable',
    'inappliquées',
    'inappréciées',
    'inapprivoisé',
    'inappropriée',
    'inappropriés',
    'inarticulées',
    'inattaquable',
    'inattentions',
    'inattentives',
    'inauguraient',
    'inaugurasses',
    'inauguration',
    'inaugurerais',
    'inaugurerait',
    'inaugurèrent',
    'inaugureriez',
    'inaugurerons',
    'inaugureront',
    'incalculable',
    'incandescent',
    'incantations',
    'incantatoire',
    'incapacitant',
    'incarcérâmes',
    'incarcérasse',
    'incarcérâtes',
    'incarcérerai',
    'incarcéreras',
    'incarcérerez',
    'incarcérions',
    'incarnadines',
    'incarnassent',
    'incarnassiez',
    'incarnations',
    'incarnerions',
    'incendiaient',
    'incendiaires',
    'incendiasses',
    'incendierais',
    'incendierait',
    'incendièrent',
    'incendieriez',
    'incendierons',
    'incendieront',
    'incertitudes',
    'incessamment',
    'incestueuses',
    'inchantables',
    'inchauffable',
    'inchavirable',
    'incinéraient',
    'incinérasses',
    'incinérateur',
    'incinération',
    'incinérerais',
    'incinérerait',
    'incinérèrent',
    'incinéreriez',
    'incinérerons',
    'incinéreront',
    'incisassions',
    'inciseraient',
    'incitassions',
    'incitatrices',
    'inciteraient',
    'inclassables',
    'inclinaisons',
    'inclinassent',
    'inclinassiez',
    'inclinations',
    'inclinerions',
    'incluaissent',
    'incoagulable',
    'incoercibles',
    'incohérences',
    'incohérentes',
    'incoiffables',
    'incombassent',
    'incombassiez',
    'incomberions',
    'incomestible',
    'incommodâmes',
    'incommodante',
    'incommodants',
    'incommodasse',
    'incommodâtes',
    'incommoderai',
    'incommoderas',
    'incommoderez',
    'incommodions',
    'incommodités',
    'incomparable',
    'incompatible',
    'incompétence',
    'incompétente',
    'incompétents',
    'inconcevable',
    'incongelable',
    'incongruités',
    'incongrûment',
    'inconscience',
    'inconsciente',
    'inconscients',
    'inconséquent',
    'inconsidérée',
    'inconsidérés',
    'inconsistant',
    'inconsolable',
    'inconstances',
    'inconstantes',
    'incontestées',
    'incontinence',
    'incontinente',
    'incontinents',
    'incontrôlées',
    'inconvenance',
    'inconvenante',
    'inconvenants',
    'inconvénient',
    'incorporable',
    'incorporâmes',
    'incorporasse',
    'incorporâtes',
    'incorporelle',
    'incorporerai',
    'incorporeras',
    'incorporerez',
    'incorporions',
    'incorrection',
    'incorrigible',
    'incrédulités',
    'incrémentais',
    'incrémentait',
    'incrémentant',
    'incrémentées',
    'incrémentent',
    'incrémentera',
    'incrémentiez',
    'incrémentons',
    'incriminable',
    'incriminâmes',
    'incriminasse',
    'incriminâtes',
    'incriminerai',
    'incrimineras',
    'incriminerez',
    'incriminions',
    'incrustaient',
    'incrustantes',
    'incrustasses',
    'incrustation',
    'incrusterais',
    'incrusterait',
    'incrustèrent',
    'incrusteriez',
    'incrusterons',
    'incrusteront',
    'incrusteuses',
    'incubassions',
    'incubatrices',
    'incuberaient',
    'inculcations',
    'inculpassent',
    'inculpassiez',
    'inculpations',
    'inculperions',
    'inculquaient',
    'inculquasses',
    'inculquerais',
    'inculquerait',
    'inculquèrent',
    'inculqueriez',
    'inculquerons',
    'inculqueront',
    'incultivable',
    'incuriosités',
    'incurvassent',
    'incurvassiez',
    'incurvations',
    'incurverions',
    'indécelables',
    'indéchirable',
    'indécidables',
    'indéclinable',
    'indécollable',
    'indéfectible',
    'indéfendable',
    'indéfiniment',
    'indéformable',
    'indéfrisable',
    'indélébilité',
    'indélibérées',
    'indemnisable',
    'indemnisâmes',
    'indemnisasse',
    'indemnisâtes',
    'indemniserai',
    'indemniseras',
    'indemniserez',
    'indemnisions',
    'indemnitaire',
    'indémodables',
    'indémontable',
    'indentations',
    'indépassable',
    'indépendance',
    'indépendante',
    'indépendants',
    'indéréglable',
    'indésirables',
    'indéterminée',
    'indéterminés',
    'indexassions',
    'indexeraient',
    'indiçassions',
    'indicatrices',
    'indiceraient',
    'indifférâmes',
    'indifférasse',
    'indifférâtes',
    'indifférence',
    'indifférente',
    'indifférents',
    'indifférerai',
    'indifféreras',
    'indifférerez',
    'indifférions',
    'indigestions',
    'indignassent',
    'indignassiez',
    'indignations',
    'indignerions',
    'indiquassent',
    'indiquassiez',
    'indiquerions',
    'indiscipline',
    'indiscipliné',
    'indiscrétion',
    'indiscutable',
    'indisponible',
    'indisposâmes',
    'indisposasse',
    'indisposâtes',
    'indisposerai',
    'indisposeras',
    'indisposerez',
    'indisposions',
    'indissoluble',
    'indistinctes',
    'individuelle',
    'indivisaires',
    'indivisément',
    'indivisibles',
    'indochinoise',
    'indomptables',
    'indonésienne',
    'indubitables',
    'induisissent',
    'induisissiez',
    'indulgenciai',
    'indulgencias',
    'indulgenciât',
    'indulgenciée',
    'indulgencier',
    'indulgencies',
    'indulgenciés',
    'indulgenciez',
    'indurassions',
    'indureraient',
    'industrielle',
    'industrieuse',
    'inébranlable',
    'inécoutables',
    'ineffaçables',
    'inefficacité',
    'inégalitaire',
    'inélasticité',
    'inélastiques',
    'inéluctables',
    'inemployable',
    'inénarrables',
    'inépuisables',
    'inéquitables',
    'inesthétique',
    'inestimables',
    'inexactement',
    'inexactitude',
    'inexcusables',
    'inexécutable',
    'inexécutions',
    'inexistantes',
    'inexistences',
    'inexpérience',
    'inexplicable',
    'inexpliquées',
    'inexploitées',
    'inexplorable',
    'inexplosible',
    'inexpressifs',
    'inexpressive',
    'inexprimable',
    'inexpugnable',
    'inextensible',
    'inextirpable',
    'inextricable',
    'infaillibles',
    'infanticides',
    'infantilisai',
    'infantilisas',
    'infantilisât',
    'infantilisée',
    'infantiliser',
    'infantilises',
    'infantilisés',
    'infantilisez',
    'infantilisme',
    'infatigables',
    'infatuassent',
    'infatuassiez',
    'infatuations',
    'infatuerions',
    'infécondités',
    'infectassent',
    'infectassiez',
    'infecterions',
    'infectieuses',
    'inféodassent',
    'inféodassiez',
    'inféoderions',
    'inférassions',
    'inféreraient',
    'infériorisai',
    'infériorisas',
    'infériorisât',
    'infériorisée',
    'inférioriser',
    'infériorises',
    'infériorisés',
    'infériorisez',
    'infériorités',
    'infertilités',
    'infestassent',
    'infestassiez',
    'infestations',
    'infesterions',
    'infeutrables',
    'infibulation',
    'infidèlement',
    'infiltraient',
    'infiltrasses',
    'infiltration',
    'infiltrerais',
    'infiltrerait',
    'infiltrèrent',
    'infiltreriez',
    'infiltrerons',
    'infiltreront',
    'infirmassent',
    'infirmassiez',
    'infirmations',
    'infirmatives',
    'infirmerions',
    'inflammables',
    'inflammation',
    'infléchirais',
    'infléchirait',
    'infléchirent',
    'infléchiriez',
    'infléchirons',
    'infléchiront',
    'infléchisses',
    'infléchissez',
    'infligeaient',
    'infligeasses',
    'infligerions',
    'influassions',
    'influençable',
    'influençâmes',
    'influençasse',
    'influençâtes',
    'influencerai',
    'influenceras',
    'influencerez',
    'influencions',
    'influeraient',
    'infographies',
    'informassent',
    'informassiez',
    'informateurs',
    'informations',
    'informatique',
    'informatisai',
    'informatisas',
    'informatisât',
    'informatisée',
    'informatiser',
    'informatises',
    'informatisés',
    'informatisez',
    'informatives',
    'informatrice',
    'informerions',
    'infroissable',
    'infructueuse',
    'infundibulum',
    'infusassions',
    'infuseraient',
    'ingéniassiez',
    'ingénierions',
    'ingéniosités',
    'ingérassions',
    'ingéreraient',
    'ingratitudes',
    'ingurgitâmes',
    'ingurgitasse',
    'ingurgitâtes',
    'ingurgiterai',
    'ingurgiteras',
    'ingurgiterez',
    'ingurgitions',
    'inhabitables',
    'inhabituelle',
    'inhalassions',
    'inhalatrices',
    'inhaleraient',
    'inharmonieux',
    'inhibassions',
    'inhiberaient',
    'inhibitrices',
    'inhumassions',
    'inhumeraient',
    'inimaginable',
    'ininterrompu',
    'initialement',
    'initialisais',
    'initialisait',
    'initialisant',
    'initialisées',
    'initialisent',
    'initialisera',
    'initialisiez',
    'initialisons',
    'initiassions',
    'initiatiques',
    'initiatrices',
    'initieraient',
    'injectassent',
    'injectassiez',
    'injecterions',
    'injuriassent',
    'injuriassiez',
    'injurierions',
    'injustifiées',
    'innervassent',
    'innervassiez',
    'innervations',
    'innerverions',
    'innocentâmes',
    'innocentasse',
    'innocentâtes',
    'innocenterai',
    'innocenteras',
    'innocenterez',
    'innocentions',
    'innombrables',
    'innovassions',
    'innovatrices',
    'innoveraient',
    'inobservable',
    'inobservance',
    'inoccupation',
    'inoculassent',
    'inoculassiez',
    'inoculations',
    'inoculerions',
    'inoffensives',
    'inondassions',
    'inonderaient',
    'inopportunes',
    'inopposables',
    'inorganiques',
    'inorganisées',
    'inoubliables',
    'inquiétaient',
    'inquiétantes',
    'inquiétasses',
    'inquiéterais',
    'inquiéterait',
    'inquiétèrent',
    'inquiéteriez',
    'inquiéterons',
    'inquiéteront',
    'inquisiteurs',
    'inquisitions',
    'inquisitrice',
    'inracontable',
    'insalissable',
    'insalivation',
    'insalubrités',
    'insatisfaite',
    'insatisfaits',
    'inscriptible',
    'inscriptions',
    'inscriraient',
    'inscrivaient',
    'inscrivantes',
    'inscrivirent',
    'inscrivisses',
    'insculpaient',
    'insculpasses',
    'insculperais',
    'insculperait',
    'insculpèrent',
    'insculperiez',
    'insculperons',
    'insculperont',
    'insectariums',
    'insecticides',
    'insectifuges',
    'insectivores',
    'inséminaient',
    'inséminasses',
    'insémination',
    'inséminerais',
    'inséminerait',
    'inséminèrent',
    'insémineriez',
    'inséminerons',
    'insémineront',
    'inséparables',
    'insérassions',
    'inséreraient',
    'insignifiant',
    'insinuassent',
    'insinuassiez',
    'insinuations',
    'insinuerions',
    'insistassent',
    'insistassiez',
    'insisterions',
    'insolassions',
    'insoleraient',
    'insolubilisa',
    'insolubilise',
    'insolubilisé',
    'insomniaques',
    'insomnieuses',
    'insonorisais',
    'insonorisait',
    'insonorisant',
    'insonorisées',
    'insonorisent',
    'insonorisera',
    'insonorisiez',
    'insonorisons',
    'insouciances',
    'insouciantes',
    'insoucieuses',
    'insoumission',
    'insoupçonnée',
    'insoupçonnés',
    'insoutenable',
    'inspectaient',
    'inspectasses',
    'inspecterais',
    'inspecterait',
    'inspectèrent',
    'inspecteriez',
    'inspecterons',
    'inspecteront',
    'inspectorats',
    'inspectrices',
    'inspirassent',
    'inspirassiez',
    'inspirateurs',
    'inspirations',
    'inspiratrice',
    'inspirerions',
    'instabilités',
    'installaient',
    'installasses',
    'installateur',
    'installation',
    'installerais',
    'installerait',
    'installèrent',
    'installeriez',
    'installerons',
    'installeront',
    'instantanées',
    'instauraient',
    'instaurasses',
    'instauration',
    'instaurerais',
    'instaurerait',
    'instaurèrent',
    'instaureriez',
    'instaurerons',
    'instaureront',
    'instigateurs',
    'instigations',
    'instigatrice',
    'instiguaient',
    'instiguasses',
    'instiguerais',
    'instiguerait',
    'instiguèrent',
    'instigueriez',
    'instiguerons',
    'instigueront',
    'instillaient',
    'instillasses',
    'instillation',
    'instillerais',
    'instillerait',
    'instillèrent',
    'instilleriez',
    'instillerons',
    'instilleront',
    'instinctives',
    'instinctuels',
    'instituaient',
    'instituasses',
    'instituerais',
    'instituerait',
    'instituèrent',
    'institueriez',
    'instituerons',
    'institueront',
    'instituteurs',
    'institutions',
    'institutrice',
    'instructeurs',
    'instructions',
    'instructives',
    'instructrice',
    'instruirions',
    'instruisîmes',
    'instruisions',
    'instruisisse',
    'instruisîtes',
    'instrumentai',
    'instrumentas',
    'instrumentât',
    'instrumentée',
    'instrumenter',
    'instrumentes',
    'instrumentés',
    'instrumentez',
    'insubordonné',
    'insuffisance',
    'insuffisante',
    'insuffisants',
    'insufflaient',
    'insufflasses',
    'insufflateur',
    'insufflation',
    'insufflerais',
    'insufflerait',
    'insufflèrent',
    'insuffleriez',
    'insufflerons',
    'insuffleront',
    'insultassent',
    'insultassiez',
    'insulterions',
    'insurgerions',
    'insurrection',
    'intaillaient',
    'intaillasses',
    'intaillerais',
    'intaillerait',
    'intaillèrent',
    'intailleriez',
    'intaillerons',
    'intailleront',
    'intarissable',
    'intégralités',
    'intégrassent',
    'intégrassiez',
    'intégrateurs',
    'intégrations',
    'intégrerions',
    'intellectuel',
    'intelligence',
    'intelligente',
    'intelligents',
    'intelligible',
    'intempérance',
    'intempérante',
    'intempérants',
    'intempestifs',
    'intempestive',
    'intemporelle',
    'intensifiais',
    'intensifiait',
    'intensifiant',
    'intensifiées',
    'intensifient',
    'intensifiera',
    'intensifiiez',
    'intensifions',
    'intentassent',
    'intentassiez',
    'intenterions',
    'intentionnée',
    'intentionnel',
    'intentionnés',
    'interactions',
    'interactives',
    'interalliées',
    'interastrale',
    'interastraux',
    'intercalaire',
    'intercalâmes',
    'intercalasse',
    'intercalâtes',
    'intercalerai',
    'intercaleras',
    'intercalerez',
    'intercalions',
    'intercédâmes',
    'intercédasse',
    'intercédâtes',
    'intercéderai',
    'intercéderas',
    'intercéderez',
    'intercédions',
    'interceptais',
    'interceptait',
    'interceptant',
    'interceptées',
    'interceptent',
    'interceptera',
    'intercepteur',
    'interceptiez',
    'interception',
    'interceptons',
    'intercesseur',
    'intercession',
    'interclassai',
    'interclassas',
    'interclassât',
    'interclassée',
    'interclasser',
    'interclasses',
    'interclassés',
    'interclassez',
    'intercostale',
    'intercostaux',
    'intercotidal',
    'intercourses',
    'intercurrent',
    'interdiction',
    'interdigital',
    'interdirions',
    'interdisions',
    'interdissent',
    'interdissiez',
    'intéressâmes',
    'intéressante',
    'intéressants',
    'intéressasse',
    'intéressâtes',
    'intéresserai',
    'intéresseras',
    'intéresserez',
    'intéressions',
    'interférâmes',
    'interférasse',
    'interférâtes',
    'interférence',
    'interférerai',
    'interféreras',
    'interférerez',
    'interférions',
    'interfoliage',
    'interfoliais',
    'interfoliait',
    'interfoliant',
    'interfoliées',
    'interfolient',
    'interfoliera',
    'interfoliiez',
    'interfolions',
    'intergroupes',
    'intérimaires',
    'intériorisai',
    'intériorisas',
    'intériorisât',
    'intériorisée',
    'intérioriser',
    'intériorises',
    'intériorisés',
    'intériorisez',
    'intériorités',
    'interjectifs',
    'interjection',
    'interjective',
    'interjetâmes',
    'interjetasse',
    'interjetâtes',
    'interjetions',
    'interjettent',
    'interjettera',
    'interlignais',
    'interlignait',
    'interlignant',
    'interlignées',
    'interlignent',
    'interlignera',
    'interligniez',
    'interlignons',
    'interloquais',
    'interloquait',
    'interloquant',
    'interloquées',
    'interloquent',
    'interloquera',
    'interloquiez',
    'interloquons',
    'interminable',
    'intermittent',
    'internassent',
    'internassiez',
    'internements',
    'internerions',
    'intéroceptif',
    'interosseuse',
    'interpellais',
    'interpellait',
    'interpellant',
    'interpellées',
    'interpellent',
    'interpellera',
    'interpelliez',
    'interpellons',
    'interpénétré',
    'interpolâmes',
    'interpolasse',
    'interpolâtes',
    'interpolerai',
    'interpoleras',
    'interpolerez',
    'interpolions',
    'interposâmes',
    'interposasse',
    'interposâtes',
    'interposerai',
    'interposeras',
    'interposerez',
    'interposions',
    'interprétais',
    'interprétait',
    'interprétant',
    'interprétées',
    'interprètent',
    'interprétera',
    'interpréteur',
    'interprétiez',
    'interprétons',
    'interrogatif',
    'interrogeais',
    'interrogeait',
    'interrogeant',
    'interrogeons',
    'interrogerai',
    'interrogeras',
    'interrogerez',
    'interrogions',
    'interrompais',
    'interrompait',
    'interrompant',
    'interrompent',
    'interrompiez',
    'interrompons',
    'interromprai',
    'interrompras',
    'interromprez',
    'interrompues',
    'interrupteur',
    'interruptifs',
    'interruption',
    'interruptive',
    'intersection',
    'intersession',
    'intersexuées',
    'intersexuels',
    'intersidéral',
    'interurbaine',
    'interurbains',
    'intervenante',
    'intervenants',
    'intervenions',
    'intervention',
    'interversion',
    'interverties',
    'intervertira',
    'interviendra',
    'interviennes',
    'interviewais',
    'interviewait',
    'interviewant',
    'interviewées',
    'interviewent',
    'interviewera',
    'intervieweur',
    'interviewiez',
    'interviewons',
    'intervinrent',
    'intervinsses',
    'interzonales',
    'intestinales',
    'intimassions',
    'intimeraient',
    'intimidables',
    'intimidaient',
    'intimidantes',
    'intimidasses',
    'intimidateur',
    'intimidation',
    'intimiderais',
    'intimiderait',
    'intimidèrent',
    'intimideriez',
    'intimiderons',
    'intimideront',
    'intitulaient',
    'intitulasses',
    'intitulerais',
    'intitulerait',
    'intitulèrent',
    'intituleriez',
    'intitulerons',
    'intituleront',
    'intolérables',
    'intolérances',
    'intolérantes',
    'intouchables',
    'intoxicantes',
    'intoxication',
    'intoxiquâmes',
    'intoxiquasse',
    'intoxiquâtes',
    'intoxiquerai',
    'intoxiqueras',
    'intoxiquerez',
    'intoxiquions',
    'intracrânien',
    'intraitables',
    'intransitifs',
    'intransitive',
    'intraveineux',
    'intrépidités',
    'intrications',
    'intriguaient',
    'intriguasses',
    'intriguerais',
    'intriguerait',
    'intriguèrent',
    'intrigueriez',
    'intriguerons',
    'intrigueront',
    'intrinsèques',
    'intriquaient',
    'intriquasses',
    'intriquerais',
    'intriquerait',
    'intriquèrent',
    'intriqueriez',
    'intriquerons',
    'intriqueront',
    'introducteur',
    'introductifs',
    'introduction',
    'introductive',
    'introduirais',
    'introduirait',
    'introduiriez',
    'introduirons',
    'introduiront',
    'introduisais',
    'introduisait',
    'introduisant',
    'introduisent',
    'introduisiez',
    'introduisons',
    'introjection',
    'intromission',
    'intronisâmes',
    'intronisasse',
    'intronisâtes',
    'introniserai',
    'introniseras',
    'introniserez',
    'intronisions',
    'introspectif',
    'introuvables',
    'introversion',
    'introverties',
    'intubassions',
    'intuberaient',
    'inutilisable',
    'invaginaient',
    'invaginasses',
    'invagination',
    'invaginerais',
    'invaginerait',
    'invaginèrent',
    'invagineriez',
    'invaginerons',
    'invagineront',
    'invalidaient',
    'invalidantes',
    'invalidasses',
    'invalidation',
    'invaliderais',
    'invaliderait',
    'invalidèrent',
    'invalideriez',
    'invaliderons',
    'invalideront',
    'invectivâmes',
    'invectivasse',
    'invectivâtes',
    'invectiverai',
    'invectiveras',
    'invectiverez',
    'invectivions',
    'inventassent',
    'inventassiez',
    'inventerions',
    'inventivités',
    'inventoriage',
    'inventoriais',
    'inventoriait',
    'inventoriant',
    'inventoriées',
    'inventorient',
    'inventoriera',
    'inventoriiez',
    'inventorions',
    'invérifiable',
    'inversassent',
    'inversassiez',
    'inverserions',
    'invertébrées',
    'invertirions',
    'invertissais',
    'invertissait',
    'invertissant',
    'invertissent',
    'invertissiez',
    'invertissons',
    'investirions',
    'investissais',
    'investissait',
    'investissant',
    'investissent',
    'investisseur',
    'investissiez',
    'investissons',
    'investitures',
    'invétéreriez',
    'invétérerons',
    'invisibilité',
    'invitassions',
    'invitatoires',
    'inviteraient',
    'invocatoires',
    'involontaire',
    'invoquassent',
    'invoquassiez',
    'invoquerions',
    'invulnérable',
    'iodhydriques',
    'ionisassions',
    'ioniseraient',
    'irascibilité',
    'iridectomies',
    'iridescentes',
    'ironiquement',
    'ironisassent',
    'ironisassiez',
    'ironiserions',
    'irrachetable',
    'irradiassent',
    'irradiassiez',
    'irradiations',
    'irradierions',
    'irraisonnées',
    'irrationnels',
    'irréalisable',
    'irrecevables',
    'irrécusables',
    'irrédentiste',
    'irréductible',
    'irréfléchies',
    'irréflexions',
    'irréformable',
    'irréfragable',
    'irréfrénable',
    'irréfutables',
    'irrégularité',
    'irrégulières',
    'irréligieuse',
    'irrémédiable',
    'irrémissible',
    'irréparables',
    'irrésistible',
    'irrésolution',
    'irrespirable',
    'irrévérences',
    'irréversible',
    'irrévocables',
    'irriguassent',
    'irriguassiez',
    'irriguerions',
    'irritabilité',
    'irritassions',
    'irriteraient',
    'ischiatiques',
    'islamisaient',
    'islamisasses',
    'islamisation',
    'islamiserais',
    'islamiserait',
    'islamisèrent',
    'islamiseriez',
    'islamiserons',
    'islamiseront',
    'isochronisme',
    'isodynamique',
    'israéliennes',
    'italianisais',
    'italianisait',
    'italianisant',
    'italianisées',
    'italianisent',
    'italianisera',
    'italianisiez',
    'italianismes',
    'italianisons',
    'jabotassions',
    'jaboteraient',
    'jacassassent',
    'jacassassiez',
    'jacassements',
    'jacasserions',
    'jacobinismes',
    'jaculatoires',
    'jailliraient',
    'jaillissante',
    'jaillissants',
    'jaillissions',
    'jalonnassent',
    'jalonnassiez',
    'jalonnements',
    'jalonnerions',
    'jalousassent',
    'jalousassiez',
    'jalouserions',
    'jamaïquaines',
    'jam-sessions',
    'japonaiserie',
    'japonisantes',
    'japonnassent',
    'japonnassiez',
    'japonnerions',
    'jardinassent',
    'jardinassiez',
    'jardinerions',
    'jaretterions',
    'jargonnaient',
    'jargonnasses',
    'jargonnerais',
    'jargonnerait',
    'jargonnèrent',
    'jargonneriez',
    'jargonnerons',
    'jargonneront',
    'jarovisation',
    'jarretassent',
    'jarretassiez',
    'jaspinassent',
    'jaspinassiez',
    'jaspinerions',
    'jaugeassions',
    'jaunissaient',
    'jaunissantes',
    'jaunissement',
    'javelassions',
    'javellerions',
    'javellisâmes',
    'javellisasse',
    'javellisâtes',
    'javelliserai',
    'javelliseras',
    'javelliserez',
    'javellisions',
    'jennériennes',
    'jobardassent',
    'jobardassiez',
    'jobarderions',
    'joignissions',
    'jointoiement',
    'jointoierais',
    'jointoierait',
    'jointoieriez',
    'jointoierons',
    'jointoieront',
    'jointoyasses',
    'jointoyèrent',
    'jonchassions',
    'joncheraient',
    'jonglassions',
    'jongleraient',
    'jordaniennes',
    'jouaillaient',
    'jouaillasses',
    'jouaillerais',
    'jouaillerait',
    'jouaillèrent',
    'jouailleriez',
    'jouaillerons',
    'jouailleront',
    'journalières',
    'journalismes',
    'journalistes',
    'jouvencelles',
    'jouxtassions',
    'jouxteraient',
    'jubilassions',
    'jubileraient',
    'jugulassions',
    'juguleraient',
    'jumelassions',
    'jumellerions',
    'jupe-culotte',
    'jupitérienne',
    'juponnassent',
    'juponnassiez',
    'juponnerions',
    'jurassiennes',
    'juridictions',
    'juste-milieu',
    'justiciables',
    'justifiables',
    'justifiaient',
    'justifiantes',
    'justifiasses',
    'justificatif',
    'justifierais',
    'justifierait',
    'justifièrent',
    'justifieriez',
    'justifierons',
    'justifieront',
    'juxtaposable',
    'juxtaposâmes',
    'juxtaposante',
    'juxtaposants',
    'juxtaposasse',
    'juxtaposâtes',
    'juxtaposerai',
    'juxtaposeras',
    'juxtaposerez',
    'juxtaposions',
    'kaléidoscope',
    'kentrophylle',
    'kératotomies',
    'keynésiennes',
    'kidnappaient',
    'kidnappasses',
    'kidnapperais',
    'kidnapperait',
    'kidnappèrent',
    'kidnapperiez',
    'kidnapperons',
    'kidnapperont',
    'kidnappeuses',
    'kilocalories',
    'kilométrages',
    'kilométrâmes',
    'kilométrasse',
    'kilométrâtes',
    'kilométrerai',
    'kilométreras',
    'kilométrerez',
    'kilométrions',
    'kilométrique',
    'kinesthésies',
    'kitchenettes',
    'klaxonnaient',
    'klaxonnasses',
    'klaxonnerais',
    'klaxonnerait',
    'klaxonnèrent',
    'klaxonneriez',
    'klaxonnerons',
    'klaxonneront',
    'kleptomanies',
    'kolkhozienne',
    'kommandantur',
    'kymographies',
    'labialisâmes',
    'labialisasse',
    'labialisâtes',
    'labialiserai',
    'labialiseras',
    'labialiserez',
    'labialisions',
    'labiodentale',
    'laborantines',
    'laboratoires',
    'labourassent',
    'labourassiez',
    'labourerions',
    'lacédémonien',
    'lacérassions',
    'lacéreraient',
    'lacrymogènes',
    'lactalbumine',
    'lactescences',
    'lactescentes',
    'lactoflavine',
    'lactucariums',
    'laïcisassent',
    'laïcisassiez',
    'laïcisations',
    'laïciserions',
    'laissassions',
    'laisseraient',
    'laitonnaient',
    'laitonnasses',
    'laitonnerais',
    'laitonnerait',
    'laitonnèrent',
    'laitonneriez',
    'laitonnerons',
    'laitonneront',
    'lamarckismes',
    'lambdacismes',
    'lambinassent',
    'lambinassiez',
    'lambinerions',
    'lambrissages',
    'lambrissâmes',
    'lambrissasse',
    'lambrissâtes',
    'lambrisserai',
    'lambrisseras',
    'lambrisserez',
    'lambrissions',
    'lamelliforme',
    'lamentassent',
    'lamentassiez',
    'lamentations',
    'lamenterions',
    'laminassions',
    'laminectomie',
    'lamineraient',
    'lampadophore',
    'lampisteries',
    'lance-amarre',
    'lance-bombes',
    'lance-fusées',
    'lancinassent',
    'lancinassiez',
    'lancinerions',
    'landgraviats',
    'langeassions',
    'langoureuses',
    'langoustière',
    'langoustiers',
    'langoustines',
    'langueyaient',
    'langueyasses',
    'langueyerais',
    'langueyerait',
    'langueyèrent',
    'langueyeriez',
    'langueyerons',
    'langueyeront',
    'languiraient',
    'languissante',
    'languissants',
    'languissions',
    'lanternaient',
    'lanternasses',
    'lanternerais',
    'lanternerait',
    'lanternèrent',
    'lanterneriez',
    'lanternerons',
    'lanterneront',
    'lanugineuses',
    'lapalissades',
    'lapidassions',
    'lapideraient',
    'lapis-lazuli',
    'lardonnaient',
    'lardonnasses',
    'lardonnerais',
    'lardonnerait',
    'lardonnèrent',
    'lardonneriez',
    'lardonnerons',
    'lardonneront',
    'larguassions',
    'largueraient',
    'larmoierions',
    'larmoyassent',
    'larmoyassiez',
    'laryngiennes',
    'laryngologie',
    'laryngologue',
    'laryngoscope',
    'latéralement',
    'latéritiques',
    'latifundiums',
    'latinisaient',
    'latinisasses',
    'latinisation',
    'latiniserais',
    'latiniserait',
    'latinisèrent',
    'latiniseriez',
    'latiniserons',
    'latiniseront',
    'laurier-rose',
    'légalisaient',
    'légalisantes',
    'légalisasses',
    'légalisation',
    'légaliserais',
    'légaliserait',
    'légalisèrent',
    'légaliseriez',
    'légaliserons',
    'légaliseront',
    'légiféraient',
    'légiférasses',
    'légiférerais',
    'légiférerait',
    'légiférèrent',
    'légiféreriez',
    'légiférerons',
    'légiféreront',
    'légionnaires',
    'législateurs',
    'législations',
    'législatives',
    'législatrice',
    'législatures',
    'légitimaient',
    'légitimasses',
    'légitimation',
    'légitimement',
    'légitimerais',
    'légitimerait',
    'légitimèrent',
    'légitimeriez',
    'légitimerons',
    'légitimeront',
    'légitimistes',
    'légumineuses',
    'leishmaniose',
    'lénifiassent',
    'lénifiassiez',
    'lénifierions',
    'lenticulaire',
    'lépidosirène',
    'léprologiste',
    'leptocéphale',
    'leptospirose',
    'lesbianismes',
    'lèse-majesté',
    'lésinassions',
    'lésineraient',
    'lésionnaires',
    'lésionnelles',
    'lessivassent',
    'lessivassiez',
    'lessiverions',
    'lessivielles',
    'léthargiques',
    'leucocytaire',
    'leucocytoses',
    'leucoplasies',
    'leurrassions',
    'leurreraient',
    'lévigeassent',
    'lévigeassiez',
    'lévigeraient',
    'levrettaient',
    'levrettasses',
    'levretterais',
    'levretterait',
    'levrettèrent',
    'levretteriez',
    'levretterons',
    'levretteront',
    'lexicalisais',
    'lexicalisait',
    'lexicalisant',
    'lexicalisées',
    'lexicalisent',
    'lexicalisera',
    'lexicalisiez',
    'lexicalisons',
    'lexicographe',
    'lexicologies',
    'lexicologues',
    'lézardassent',
    'lézardassiez',
    'lézarderions',
    'liaisonnâmes',
    'liaisonnasse',
    'liaisonnâtes',
    'liaisonnerai',
    'liaisonneras',
    'liaisonnerez',
    'liaisonnions',
    'liardassions',
    'liarderaient',
    'libellassent',
    'libellassiez',
    'libellerions',
    'libéralement',
    'libéralisais',
    'libéralisait',
    'libéralisant',
    'libéralisées',
    'libéralisent',
    'libéralisera',
    'libéralisiez',
    'libéralismes',
    'libéralisons',
    'libérassions',
    'libératoires',
    'libératrices',
    'libéreraient',
    'liberticides',
    'libertinages',
    'libidineuses',
    'librettistes',
    'licenciaient',
    'licenciasses',
    'licenciement',
    'licencierais',
    'licencierait',
    'licencièrent',
    'licencieriez',
    'licencierons',
    'licencieront',
    'licencieuses',
    'licitassions',
    'liciteraient',
    'liégeassions',
    'lieutenances',
    'ligamentaire',
    'ligamenteuse',
    'ligaturaient',
    'ligaturasses',
    'ligaturerais',
    'ligaturerait',
    'ligaturèrent',
    'ligatureriez',
    'ligaturerons',
    'ligatureront',
    'lignifiaient',
    'lignifiantes',
    'lignifiasses',
    'lignifierais',
    'lignifierait',
    'lignifièrent',
    'lignifieriez',
    'lignifierons',
    'lignifieront',
    'ligotassions',
    'ligoteraient',
    'liguliflores',
    'lilliputiens',
    'limandassent',
    'limandassiez',
    'limanderions',
    'limitassions',
    'limiteraient',
    'limniculture',
    'limogeassent',
    'limogeassiez',
    'limogeraient',
    'limonadières',
    'limonassions',
    'limoneraient',
    'limousinages',
    'limousinâmes',
    'limousinasse',
    'limousinâtes',
    'limousinerai',
    'limousineras',
    'limousinerez',
    'limousinions',
    'linaigrettes',
    'linéairement',
    'lingeassions',
    'linguiformes',
    'linguistique',
    'linotypistes',
    'lipoprotéine',
    'liposarcomes',
    'liposolubles',
    'liquéfacteur',
    'liquéfaction',
    'liquéfiables',
    'liquéfiaient',
    'liquéfiantes',
    'liquéfiasses',
    'liquéfierais',
    'liquéfierait',
    'liquéfièrent',
    'liquéfieriez',
    'liquéfierons',
    'liquéfieront',
    'liquidambars',
    'liquidassent',
    'liquidassiez',
    'liquidateurs',
    'liquidations',
    'liquidatives',
    'liquidatrice',
    'liquiderions',
    'liquidiennes',
    'liserassions',
    'lisérassions',
    'liséreraient',
    'lisèreraient',
    'lithiasiques',
    'lithinifères',
    'lithogenèses',
    'lithographes',
    'lithographia',
    'lithographie',
    'lithographié',
    'lithologique',
    'lithophanies',
    'lithosphères',
    'lithotriteur',
    'littéralités',
    'littérarités',
    'littérateurs',
    'littératrice',
    'littératures',
    'lituaniennes',
    'living-rooms',
    'lixiviations',
    'localisables',
    'localisaient',
    'localisantes',
    'localisasses',
    'localisateur',
    'localisation',
    'localiserais',
    'localiserait',
    'localisèrent',
    'localiseriez',
    'localiserons',
    'localiseront',
    'lock-outâmes',
    'lock-outasse',
    'lock-outâtes',
    'lock-outerai',
    'lock-outeras',
    'lock-outerez',
    'lock-outions',
    'locomotrices',
    'locotracteur',
    'logisticiens',
    'logomachique',
    'lombarthrose',
    'londoniennes',
    'longanimités',
    'longeassions',
    'longitudinal',
    'longues-vues',
    'lords-maires',
    'lorgnassions',
    'lorgneraient',
    'lotionnaient',
    'lotionnasses',
    'lotionnerais',
    'lotionnerait',
    'lotionnèrent',
    'lotionneriez',
    'lotionnerons',
    'lotionneront',
    'lotissements',
    'louangeaient',
    'louangeasses',
    'louangerions',
    'louchassions',
    'loucheraient',
    'louchiraient',
    'louchissions',
    'loufoqueries',
    'loup-cervier',
    'loups-garous',
    'lourdassions',
    'lourderaient',
    'louvetassent',
    'louvetassiez',
    'louvetterais',
    'louvetterait',
    'louvetteriez',
    'louvetterons',
    'louvetteront',
    'louvoiements',
    'louvoierions',
    'louvoyassent',
    'louvoyassiez',
    'loxodromique',
    'lubrifiaient',
    'lubrifiantes',
    'lubrifiasses',
    'lubrifierais',
    'lubrifierait',
    'lubrifièrent',
    'lubrifieriez',
    'lubrifierons',
    'lubrifieront',
    'lubriquement',
    'luciférienne',
    'luminescence',
    'luminescente',
    'luminescents',
    'luminophores',
    'lunchassions',
    'luncheraient',
    'lusitanienne',
    'lustrassions',
    'lustreraient',
    'luthéranisme',
    'luthériennes',
    'lutinassions',
    'lutineraient',
    'luxueusement',
    'lycanthropes',
    'lycanthropie',
    'lymphangites',
    'lymphatiques',
    'lymphatismes',
    'lymphocytose',
    'lymphopénies',
    'lynchassions',
    'lyncheraient',
    'lyophilisais',
    'lyophilisait',
    'lyophilisant',
    'lyophilisées',
    'lyophilisent',
    'lyophilisera',
    'lyophilisiez',
    'lyophilisons',
    'lysergamides',
    'macadamisais',
    'macadamisait',
    'macadamisant',
    'macadamisées',
    'macadamisent',
    'macadamisera',
    'macadamisiez',
    'macadamisons',
    'macaroniques',
    'macédonienne',
    'macérassions',
    'macéreraient',
    'machicotâmes',
    'machicotasse',
    'machicotâtes',
    'machicoterai',
    'machicoteras',
    'machicoterez',
    'machicotions',
    'machinairent',
    'machinaisses',
    'machinassent',
    'machinassiez',
    'machinations',
    'machinerions',
    'mâchonnaient',
    'mâchonnasses',
    'mâchonnement',
    'mâchonnerais',
    'mâchonnerait',
    'mâchonnèrent',
    'mâchonneriez',
    'mâchonnerons',
    'mâchonneront',
    'mâchouillais',
    'mâchouillait',
    'mâchouillant',
    'mâchouillent',
    'mâchouillera',
    'mâchouilliez',
    'mâchouillons',
    'mâchurassent',
    'mâchurassiez',
    'mâchurerions',
    'maçonnassent',
    'maçonnassiez',
    'maçonnerions',
    'macquassions',
    'macqueraient',
    'macrocéphale',
    'macrographie',
    'macroscélide',
    'macroséismes',
    'maculassions',
    'maculeraient',
    'madéfiassent',
    'madéfiassiez',
    'madéfierions',
    'mademoiselle',
    'madérisaient',
    'madérisasses',
    'madériserais',
    'madériserait',
    'madérisèrent',
    'madériseriez',
    'madériserons',
    'madériseront',
    'madréporique',
    'madrigalisai',
    'madrigalisas',
    'madrigalisât',
    'madrigaliser',
    'madrigalises',
    'madrigalisez',
    'madrigaliste',
    'magasinaient',
    'magasinasses',
    'magasinerais',
    'magasinerait',
    'magasinèrent',
    'magasineriez',
    'magasinerons',
    'magasineront',
    'magasinières',
    'magdaléniens',
    'magistrature',
    'magnanarelle',
    'magnanimités',
    'magnésiennes',
    'magnétisâmes',
    'magnétisante',
    'magnétisants',
    'magnétisasse',
    'magnétisâtes',
    'magnétiserai',
    'magnétiseras',
    'magnétiserez',
    'magnétiseurs',
    'magnétiseuse',
    'magnétisions',
    'magnétomètre',
    'magnétophone',
    'magnétoscopa',
    'magnétoscope',
    'magnétoscopé',
    'magnifiaient',
    'magnifiasses',
    'magnificence',
    'magnifierais',
    'magnifierait',
    'magnifièrent',
    'magnifieriez',
    'magnifierons',
    'magnifieront',
    'magouillante',
    'magouillants',
    'magouillerai',
    'magouilleras',
    'magouillerez',
    'magouilleurs',
    'magouilleuse',
    'magouillions',
    'mahométismes',
    'maigrelettes',
    'maigrichonne',
    'maigriraient',
    'maigrissions',
    'mail-coaches',
    'maillassions',
    'maillechorts',
    'mailleraient',
    'mainmortable',
    'maintenaient',
    'maintenances',
    'maintiendrai',
    'maintiendras',
    'maintiendrez',
    'maintiennent',
    'maintinssent',
    'maintinssiez',
    'maisonnettes',
    'maîtrisables',
    'maîtrisaient',
    'maîtrisasses',
    'maîtriserais',
    'maîtriserait',
    'maîtrisèrent',
    'maîtriseriez',
    'maîtriserons',
    'maîtriseront',
    'majestueuses',
    'majorassions',
    'majoreraient',
    'majoritaires',
    'malacologies',
    'maladivement',
    'malandreuses',
    'malaxassions',
    'malaxeraient',
    'malchanceuse',
    'malédictions',
    'mal-en-point',
    'malentendant',
    'malfaisaient',
    'malfaisances',
    'malfaisantes',
    'malformation',
    'malgracieuse',
    'malheureuses',
    'malhonnêteté',
    'malléabilisa',
    'malléabilise',
    'malléabilisé',
    'malléabilité',
    'malléolaires',
    'malmenassent',
    'malmenassiez',
    'malmènerions',
    'malnutrition',
    'malocclusion',
    'malodorantes',
    'malpositions',
    'malpropretés',
    'malsonnantes',
    'malthusienne',
    'maltraitâmes',
    'maltraitante',
    'maltraitants',
    'maltraitasse',
    'maltraitâtes',
    'maltraiterai',
    'maltraiteras',
    'maltraiterez',
    'maltraitions',
    'malveillance',
    'malveillante',
    'malveillants',
    'malversation',
    'mamelonnâmes',
    'mamelonnasse',
    'mamelonnâtes',
    'mamelonnerai',
    'mamelonneras',
    'mamelonnerez',
    'mamelonnions',
    'mammaliennes',
    'mammographie',
    'manageassent',
    'manageassiez',
    'manageraient',
    'mancenillier',
    'manchonnâmes',
    'manchonnasse',
    'manchonnâtes',
    'manchonnerai',
    'manchonneras',
    'manchonnerez',
    'manchonnions',
    'mancipations',
    'mandarinales',
    'mandariniers',
    'mandatassent',
    'mandatassiez',
    'mandat-carte',
    'mandatements',
    'mandaterions',
    'mandibulaire',
    'mandoliniste',
    'manducations',
    'manécanterie',
    'manégeassent',
    'manégeassiez',
    'manégeraient',
    'mangeassions',
    'mangeottâmes',
    'mangeottasse',
    'mangeottâtes',
    'mangeotterai',
    'mangeotteras',
    'mangeotterez',
    'mangeottions',
    'maniabilités',
    'maniaqueries',
    'manichéennes',
    'manichéismes',
    'manichordion',
    'maniérassent',
    'maniérassiez',
    'maniérerions',
    'manifestâmes',
    'manifestante',
    'manifestants',
    'manifestasse',
    'manifestâtes',
    'manifesterai',
    'manifesteras',
    'manifesterez',
    'manifestions',
    'manigançâmes',
    'manigançasse',
    'manigançâtes',
    'manigancerai',
    'maniganceras',
    'manigancerez',
    'manigancions',
    'manipulaient',
    'manipulantes',
    'manipulasses',
    'manipulateur',
    'manipulation',
    'manipulerais',
    'manipulerait',
    'manipulèrent',
    'manipuleriez',
    'manipulerons',
    'manipuleront',
    'mannequinais',
    'mannequinait',
    'mannequinant',
    'mannequinées',
    'mannequinent',
    'mannequinera',
    'mannequiniez',
    'mannequinons',
    'manoeuvrable',
    'manoeuvrâmes',
    'manoeuvrasse',
    'manoeuvrâtes',
    'manoeuvrerai',
    'manoeuvreras',
    'manoeuvrerez',
    'manoeuvrière',
    'manoeuvriers',
    'manoeuvrions',
    'manométrique',
    'manoquassent',
    'manoquassiez',
    'manoquerions',
    'manouvrières',
    'manquassions',
    'manqueraient',
    'mansardaient',
    'mansardasses',
    'mansarderais',
    'mansarderait',
    'mansardèrent',
    'mansarderiez',
    'mansarderons',
    'mansarderont',
    'manucuraient',
    'manucurasses',
    'manucurerais',
    'manucurerait',
    'manucurèrent',
    'manucureriez',
    'manucurerons',
    'manucureront',
    'manuellement',
    'manufacturai',
    'manufacturas',
    'manufacturât',
    'manufacturée',
    'manufacturer',
    'manufactures',
    'manufacturés',
    'manufacturez',
    'manumissions',
    'manutentions',
    'maquereautai',
    'maquereautas',
    'maquereautât',
    'maquereautée',
    'maquereauter',
    'maquereautes',
    'maquereautés',
    'maquereautez',
    'maquettistes',
    'maquignonnai',
    'maquignonnas',
    'maquignonnât',
    'maquignonnée',
    'maquignonner',
    'maquignonnes',
    'maquignonnés',
    'maquignonnez',
    'maquillaient',
    'maquillantes',
    'maquillasses',
    'maquillerais',
    'maquillerait',
    'maquillèrent',
    'maquilleriez',
    'maquillerons',
    'maquilleront',
    'maquilleuses',
    'marathoniens',
    'maraudassent',
    'maraudassiez',
    'marauderions',
    'marbrassions',
    'marbreraient',
    'marcescences',
    'marcescentes',
    'marcescibles',
    'marchandages',
    'marchandâmes',
    'marchandasse',
    'marchandâtes',
    'marchanderai',
    'marchanderas',
    'marchanderez',
    'marchandions',
    'marchandises',
    'marchassions',
    'marcheraient',
    'marcottaient',
    'marcottasses',
    'marcotterais',
    'marcotterait',
    'marcottèrent',
    'marcotteriez',
    'marcotterons',
    'marcotteront',
    'marécageuses',
    'maréchalerie',
    'maréchaussée',
    'maremmatique',
    'marémotrices',
    'margeassions',
    'marginalisai',
    'marginalisas',
    'marginalisât',
    'marginalisée',
    'marginaliser',
    'marginalises',
    'marginalisés',
    'marginalisez',
    'marginalisme',
    'marginalités',
    'marginassent',
    'marginassiez',
    'marginerions',
    'margotassent',
    'margotassiez',
    'margoterions',
    'margottaient',
    'margottasses',
    'margotterais',
    'margotterait',
    'margottèrent',
    'margotteriez',
    'margotterons',
    'margotteront',
    'margoulettes',
    'marguilliers',
    'marie-jeanne',
    'marinassions',
    'marineraient',
    'marionnettes',
    'maritalement',
    'marivaudages',
    'marivaudâmes',
    'marivaudasse',
    'marivaudâtes',
    'marivauderai',
    'marivauderas',
    'marivauderez',
    'marivaudions',
    'marmitassent',
    'marmitassiez',
    'marmiterions',
    'marmonnaient',
    'marmonnasses',
    'marmonnement',
    'marmonnerais',
    'marmonnerait',
    'marmonnèrent',
    'marmonneriez',
    'marmonnerons',
    'marmonneront',
    'marmoréennes',
    'marmorisâmes',
    'marmorisasse',
    'marmorisâtes',
    'marmoriserai',
    'marmoriseras',
    'marmoriserez',
    'marmorisions',
    'marmottaient',
    'marmottasses',
    'marmottement',
    'marmotterais',
    'marmotterait',
    'marmottèrent',
    'marmotteriez',
    'marmotterons',
    'marmotteront',
    'marmotteuses',
    'maronnassent',
    'maronnassiez',
    'maronnerions',
    'maroquinages',
    'maroquinâmes',
    'maroquinasse',
    'maroquinâtes',
    'maroquinerai',
    'maroquineras',
    'maroquinerez',
    'maroquinerie',
    'maroquinière',
    'maroquiniers',
    'maroquinions',
    'marouflaient',
    'marouflasses',
    'marouflerais',
    'marouflerait',
    'marouflèrent',
    'maroufleriez',
    'marouflerons',
    'maroufleront',
    'marquassions',
    'marqueraient',
    'marquetaient',
    'marquetasses',
    'marquetèrent',
    'marqueteries',
    'marquetterai',
    'marquetteras',
    'marquetterez',
    'marronnaient',
    'marronnasses',
    'marronnerais',
    'marronnerait',
    'marronnèrent',
    'marronneriez',
    'marronnerons',
    'marronneront',
    'marseillaise',
    'marsouinâmes',
    'marsouinasse',
    'marsouinâtes',
    'marsouinerai',
    'marsouineras',
    'marsouinerez',
    'marsouinions',
    'martelassent',
    'martelassiez',
    'martèlements',
    'martèlerions',
    'martellement',
    'martialement',
    'martyrisâmes',
    'martyrisasse',
    'martyrisâtes',
    'martyriserai',
    'martyriseras',
    'martyriserez',
    'martyrisions',
    'martyrologes',
    'marxisassent',
    'marxisassiez',
    'marxiserions',
    'masculinisai',
    'masculinisas',
    'masculinisât',
    'masculinisée',
    'masculiniser',
    'masculinises',
    'masculinisés',
    'masculinisez',
    'masculinités',
    'masquassions',
    'masqueraient',
    'massacraient',
    'massacrantes',
    'massacrasses',
    'massacrerais',
    'massacrerait',
    'massacrèrent',
    'massacreriez',
    'massacrerons',
    'massacreront',
    'massacreuses',
    'massicotâmes',
    'massicotasse',
    'massicotâtes',
    'massicoterai',
    'massicoteras',
    'massicoterez',
    'massicotions',
    'massifiaient',
    'massifiasses',
    'massifierais',
    'massifierait',
    'massifièrent',
    'massifieriez',
    'massifierons',
    'massifieront',
    'mastectomies',
    'masticateurs',
    'mastications',
    'masticatoire',
    'masticatrice',
    'mastiquaient',
    'mastiquasses',
    'mastiquerais',
    'mastiquerait',
    'mastiquèrent',
    'mastiqueriez',
    'mastiquerons',
    'mastiqueront',
    'mastoïdienne',
    'masturbaient',
    'masturbasses',
    'masturbation',
    'masturberais',
    'masturberait',
    'masturbèrent',
    'masturberiez',
    'masturberons',
    'masturberont',
    'matchassions',
    'matcheraient',
    'matelassâmes',
    'matelassasse',
    'matelassâtes',
    'matelasserai',
    'matelasseras',
    'matelasserez',
    'matelassière',
    'matelassiers',
    'matelassions',
    'matérialisai',
    'matérialisas',
    'matérialisât',
    'matérialisée',
    'matérialiser',
    'matérialises',
    'matérialisés',
    'matérialisez',
    'matérialisme',
    'matérialiste',
    'matérialités',
    'maternassent',
    'maternassiez',
    'maternerions',
    'maternisâmes',
    'maternisasse',
    'maternisâtes',
    'materniserai',
    'materniseras',
    'materniserez',
    'maternisions',
    'mathématique',
    'mathématisai',
    'mathématisas',
    'mathématisât',
    'mathématisée',
    'mathématiser',
    'mathématises',
    'mathématisés',
    'mathématisez',
    'matinalement',
    'mâtinassions',
    'mâtineraient',
    'matraquaient',
    'matraquasses',
    'matraquerais',
    'matraquerait',
    'matraquèrent',
    'matraqueriez',
    'matraquerons',
    'matraqueront',
    'matraqueuses',
    'matriarcales',
    'matriçassent',
    'matriçassiez',
    'matricerions',
    'matriculâmes',
    'matriculasse',
    'matriculâtes',
    'matriculerai',
    'matriculeras',
    'matriculerez',
    'matriculions',
    'matrilocales',
    'matrimoniale',
    'matrimoniaux',
    'maturassions',
    'matureraient',
    'maudissaient',
    'maudissantes',
    'maugréassent',
    'maugréassiez',
    'maugréerions',
    'maussadement',
    'maussaderies',
    'maximalisais',
    'maximalisait',
    'maximalisant',
    'maximalisées',
    'maximalisent',
    'maximalisera',
    'maximalisiez',
    'maximalismes',
    'maximalisons',
    'maximalistes',
    'maximisaient',
    'maximisasses',
    'maximisation',
    'maximiserais',
    'maximiserait',
    'maximisèrent',
    'maximiseriez',
    'maximiserons',
    'maximiseront',
    'mazoutassent',
    'mazoutassiez',
    'mazouterions',
    'mécanicienne',
    'mécanisaient',
    'mécanisasses',
    'mécanisation',
    'mécaniserais',
    'mécaniserait',
    'mécanisèrent',
    'mécaniseriez',
    'mécaniserons',
    'mécaniseront',
    'mécanographe',
    'mécomptaient',
    'mécomptasses',
    'mécompterais',
    'mécompterait',
    'mécomptèrent',
    'mécompteriez',
    'mécompterons',
    'mécompteront',
    'méconnaisses',
    'méconnaissez',
    'méconnaîtrai',
    'méconnaîtras',
    'méconnaîtrez',
    'méconnussent',
    'méconnussiez',
    'mécontentais',
    'mécontentait',
    'mécontentant',
    'mécontentées',
    'mécontentent',
    'mécontentera',
    'mécontentiez',
    'mécontentons',
    'médaillaient',
    'médaillasses',
    'médaillerais',
    'médaillerait',
    'médaillèrent',
    'médailleriez',
    'médaillerons',
    'médailleront',
    'médaillistes',
    'médiatisâmes',
    'médiatisante',
    'médiatisants',
    'médiatisasse',
    'médiatisâtes',
    'médiatiserai',
    'médiatiseras',
    'médiatiserez',
    'médiatisions',
    'médicalement',
    'médicalisais',
    'médicalisait',
    'médicalisant',
    'médicalisées',
    'médicalisent',
    'médicalisera',
    'médicalisiez',
    'médicalisons',
    'médicamentai',
    'médicamentas',
    'médicamentât',
    'médicamentée',
    'médicamenter',
    'médicamentes',
    'médicamentés',
    'médicamentez',
    'médico-légal',
    'médiocraties',
    'médiocrement',
    'médisassions',
    'médiseraient',
    'méditassions',
    'méditeraient',
    'méditerranée',
    'méditerranés',
    'médiumniques',
    'médusassions',
    'méduseraient',
    'mégalithique',
    'mégalomanies',
    'mégathériums',
    'mégissassent',
    'mégissassiez',
    'mégisserions',
    'mégohmmètres',
    'mégotassions',
    'mégoteraient',
    'méjugeassent',
    'méjugeassiez',
    'méjugeraient',
    'mélancolique',
    'mélangeaient',
    'mélangeantes',
    'mélangeasses',
    'mélangerions',
    'mélanodermie',
    'mélioratives',
    'membraneuses',
    'mémorialiste',
    'mémorisaient',
    'mémorisantes',
    'mémorisasses',
    'mémorisation',
    'mémoriserais',
    'mémoriserait',
    'mémorisèrent',
    'mémoriseriez',
    'mémoriserons',
    'mémoriseront',
    'menaçassions',
    'menaceraient',
    'ménageassent',
    'ménageassiez',
    'ménageraient',
    'mendéléviums',
    'mendéliennes',
    'mendiassions',
    'mendieraient',
    'mendigotâmes',
    'mendigotasse',
    'mendigotâtes',
    'mendigoterai',
    'mendigoteras',
    'mendigoterez',
    'mendigotions',
    'méningitique',
    'méningocoque',
    'ménopausique',
    'menstruation',
    'menstruelles',
    'mensualisais',
    'mensualisait',
    'mensualisant',
    'mensualisées',
    'mensualisent',
    'mensualisera',
    'mensualisiez',
    'mensualisons',
    'mensurassent',
    'mensurassiez',
    'mensurations',
    'mensurerions',
    'mentionnâmes',
    'mentionnasse',
    'mentionnâtes',
    'mentionnerai',
    'mentionneras',
    'mentionnerez',
    'mentionnions',
    'mentonnières',
    'menuisassent',
    'menuisassiez',
    'menuiserions',
    'méprendrions',
    'méprisassent',
    'méprisassiez',
    'mépriserions',
    'mercantilisa',
    'mercantilise',
    'mercantilisé',
    'mercaticiens',
    'mercerisages',
    'mercerisâmes',
    'mercerisasse',
    'mercerisâtes',
    'merceriserai',
    'merceriseras',
    'merceriserez',
    'mercerisions',
    'mercurielles',
    'merdoierions',
    'merdoyassent',
    'merdoyassiez',
    'méridionales',
    'meringuaient',
    'meringuasses',
    'meringuerais',
    'meringuerait',
    'meringuèrent',
    'meringueriez',
    'meringuerons',
    'meringueront',
    'méritassions',
    'mériteraient',
    'méritocratie',
    'mérovingiens',
    'merveilleuse',
    'mésalliaient',
    'mésalliances',
    'mésalliasses',
    'mésallierais',
    'mésallierait',
    'mésallièrent',
    'mésallieriez',
    'mésallierons',
    'mésallieront',
    'mésaventures',
    'mésencéphale',
    'mésentérique',
    'mésestimâmes',
    'mésestimasse',
    'mésestimâtes',
    'mésestimerai',
    'mésestimeras',
    'mésestimerez',
    'mésestimions',
    'mésolithique',
    'mesquinement',
    'mesquineries',
    'messeigneurs',
    'messianiques',
    'messianismes',
    'messiéraient',
    'mesurassions',
    'mesureraient',
    'mésusassions',
    'mésuseraient',
    'métaboliques',
    'métabolismes',
    'métacarpiens',
    'métalangages',
    'métaldéhydes',
    'métallifères',
    'métallisâmes',
    'métallisante',
    'métallisants',
    'métallisasse',
    'métallisâtes',
    'métalliserai',
    'métalliseras',
    'métalliserez',
    'métallisions',
    'métallurgies',
    'métamorphisa',
    'métamorphise',
    'métamorphisé',
    'métamorphosa',
    'métamorphose',
    'métamorphosé',
    'métaphorique',
    'métaphysique',
    'métatarsiens',
    'métempsycose',
    'métencéphale',
    'météorisâmes',
    'météorisasse',
    'météorisâtes',
    'météoriserai',
    'météoriseras',
    'météoriserez',
    'météorisions',
    'météorologie',
    'météorologue',
    'méthodologie',
    'méticuleuses',
    'méticulosité',
    'métissassent',
    'métissassiez',
    'métisserions',
    'métonymiques',
    'métriciennes',
    'métrologique',
    'métropolites',
    'métrorragies',
    'meublassions',
    'meubleraient',
    'meuglassions',
    'meugleraient',
    'meurtririons',
    'meurtrissais',
    'meurtrissait',
    'meurtrissant',
    'meurtrissent',
    'meurtrissiez',
    'meurtrissons',
    'meurtrissure',
    'mévendissent',
    'mévendissiez',
    'mévendraient',
    'miasmatiques',
    'miaulassions',
    'miauleraient',
    'micaschistes',
    'micocouliers',
    'microbalance',
    'microbicides',
    'microbiennes',
    'microcéphale',
    'microcinémas',
    'microcircuit',
    'microclimats',
    'microcristal',
    'microfilmais',
    'microfilmait',
    'microfilmant',
    'microfilmées',
    'microfilment',
    'microfilmera',
    'microfilmiez',
    'microfilmons',
    'micrographie',
    'microgrenues',
    'micrométries',
    'microseconde',
    'microsillons',
    'mignardaient',
    'mignardasses',
    'mignarderais',
    'mignarderait',
    'mignardèrent',
    'mignarderiez',
    'mignarderons',
    'mignarderont',
    'mignonnement',
    'mignonnettes',
    'mignotassent',
    'mignotassiez',
    'mignoterions',
    'migraineuses',
    'mijotassions',
    'mijoteraient',
    'militantisme',
    'militarisais',
    'militarisait',
    'militarisant',
    'militarisées',
    'militarisent',
    'militarisera',
    'militarisiez',
    'militarismes',
    'militarisons',
    'militaristes',
    'militassions',
    'militeraient',
    'millefeuille',
    'millénarisme',
    'mille-pattes',
    'millepertuis',
    'millerandage',
    'millerandées',
    'millésimâmes',
    'millésimasse',
    'millésimâtes',
    'millésimerai',
    'millésimeras',
    'millésimerez',
    'millésimions',
    'milliampères',
    'milliardaire',
    'milliardième',
    'milligrammes',
    'millimétrées',
    'millionièmes',
    'millionnaire',
    'millithermie',
    'minaudassent',
    'minaudassiez',
    'minauderions',
    'mincissaient',
    'mincissantes',
    'minéralisais',
    'minéralisait',
    'minéralisant',
    'minéralisées',
    'minéralisent',
    'minéralisera',
    'minéralisiez',
    'minéralisons',
    'minéralogies',
    'miniaturâmes',
    'miniaturasse',
    'miniaturâtes',
    'miniaturerai',
    'miniatureras',
    'miniaturerez',
    'miniaturions',
    'miniaturisai',
    'miniaturisas',
    'miniaturisât',
    'miniaturisée',
    'miniaturiser',
    'miniaturises',
    'miniaturisés',
    'miniaturisez',
    'miniaturiste',
    'minicassette',
    'mini-chaînes',
    'minimisaient',
    'minimisantes',
    'minimisasses',
    'minimisation',
    'minimiserais',
    'minimiserait',
    'minimisèrent',
    'minimiseriez',
    'minimiserons',
    'minimiseront',
    'ministériels',
    'ministrables',
    'minnesingers',
    'minorassions',
    'minoreraient',
    'minoritaires',
    'minutassions',
    'minuteraient',
    'mirabelliers',
    'miraculeuses',
    'mirobolantes',
    'miroitassent',
    'miroitassiez',
    'miroitements',
    'miroiterions',
    'misanthropes',
    'misanthropie',
    'miscellanées',
    'miscibilités',
    'mi-septembre',
    'miséricordes',
    'missionnaire',
    'mithracismes',
    'mithriacisme',
    'mithridatais',
    'mithridatait',
    'mithridatant',
    'mithridatées',
    'mithridatent',
    'mithridatera',
    'mithridatiez',
    'mithridatisa',
    'mithridatise',
    'mithridatisé',
    'mithridatons',
    'mitigeassent',
    'mitigeassiez',
    'mitigeraient',
    'mitochondrie',
    'mitonnassent',
    'mitonnassiez',
    'mitonnerions',
    'mitoyennetés',
    'mitraillades',
    'mitraillages',
    'mitraillâmes',
    'mitraillante',
    'mitraillants',
    'mitraillasse',
    'mitraillâtes',
    'mitraillerai',
    'mitrailleras',
    'mitraillerez',
    'mitraillette',
    'mitrailleurs',
    'mitrailleuse',
    'mitraillions',
    'mixtionnâmes',
    'mixtionnasse',
    'mixtionnâtes',
    'mixtionnerai',
    'mixtionneras',
    'mixtionnerez',
    'mixtionnions',
    'mnémotechnie',
    'mobilisables',
    'mobilisaient',
    'mobilisantes',
    'mobilisasses',
    'mobilisateur',
    'mobilisation',
    'mobiliserais',
    'mobiliserait',
    'mobilisèrent',
    'mobiliseriez',
    'mobiliserons',
    'mobiliseront',
    'modelassions',
    'modèleraient',
    'modélisaient',
    'modélisation',
    'modéliserais',
    'modéliserait',
    'modéliseriez',
    'modéliserons',
    'modéliseront',
    'modérantisme',
    'modérassions',
    'modératrices',
    'modéreraient',
    'modernisâmes',
    'modernisasse',
    'modernisâtes',
    'moderniserai',
    'moderniseras',
    'moderniserez',
    'modernisions',
    'modifiassent',
    'modifiassiez',
    'modificateur',
    'modificatifs',
    'modification',
    'modificative',
    'modifierions',
    'modulassions',
    'modulatrices',
    'moduleraient',
    'moins-perçus',
    'moins-values',
    'moisissaient',
    'moisissantes',
    'moissonnages',
    'moissonnâmes',
    'moissonnasse',
    'moissonnâtes',
    'moissonnerai',
    'moissonneras',
    'moissonnerez',
    'moissonneurs',
    'moissonneuse',
    'moissonnions',
    'moitissaient',
    'moléculaires',
    'molestassent',
    'molestassiez',
    'molesterions',
    'moletassions',
    'moletterions',
    'moliéresques',
    'molinosistes',
    'mollardaient',
    'mollardasses',
    'mollarderais',
    'mollarderait',
    'mollardèrent',
    'mollarderiez',
    'mollarderons',
    'mollarderont',
    'mollasseries',
    'mollassonnes',
    'molletonnais',
    'molletonnait',
    'molletonnant',
    'molletonnées',
    'molletonnent',
    'molletonnera',
    'molletonneux',
    'molletonniez',
    'molletonnons',
    'mollissaient',
    'momifiassent',
    'momifiassiez',
    'momification',
    'momifierions',
    'monarchiques',
    'monarchismes',
    'monarchistes',
    'mondialement',
    'mondialisais',
    'mondialisait',
    'mondialisant',
    'mondialisent',
    'mondialisera',
    'mondialisiez',
    'mondialisons',
    'mondialistes',
    'mondovisions',
    'monétarismes',
    'monétaristes',
    'monétisaient',
    'monétisasses',
    'monétiserais',
    'monétiserait',
    'monétisèrent',
    'monétiseriez',
    'monétiserons',
    'monétiseront',
    'mongoliennes',
    'monnaierions',
    'monnayassent',
    'monnayassiez',
    'monnayerions',
    'monoatomique',
    'monochromies',
    'monoclinales',
    'monoclinique',
    'monoculaires',
    'monocultures',
    'monogamiques',
    'monogénismes',
    'monographies',
    'monoïdéismes',
    'monolithique',
    'monolithisme',
    'monologuâmes',
    'monologuasse',
    'monologuâtes',
    'monologuerai',
    'monologueras',
    'monologuerez',
    'monologuions',
    'monomotrices',
    'mononucléose',
    'monophonique',
    'monopolisais',
    'monopolisait',
    'monopolisant',
    'monopolisées',
    'monopolisent',
    'monopolisera',
    'monopolisiez',
    'monopolisons',
    'monopolistes',
    'monosulfites',
    'monosyllabes',
    'monothéiques',
    'monothéismes',
    'monothéistes',
    'monovalentes',
    'monstrueuses',
    'monstruosité',
    'montagnardes',
    'montagnettes',
    'montagneuses',
    'monte-charge',
    'monte-pentes',
    'montgolfière',
    'montmorencys',
    'montrassions',
    'montreraient',
    'monts-blancs',
    'monumentales',
    'moquettaient',
    'moquettasses',
    'moquetterais',
    'moquetterait',
    'moquettèrent',
    'moquetteriez',
    'moquetterons',
    'moquetteront',
    'moralisaient',
    'moralisantes',
    'moralisasses',
    'moralisateur',
    'moralisation',
    'moraliserais',
    'moraliserait',
    'moralisèrent',
    'moraliseriez',
    'moraliserons',
    'moraliseront',
    'morbilleuses',
    'morcelassent',
    'morcelassiez',
    'morcellement',
    'morcellerais',
    'morcellerait',
    'morcelleriez',
    'morcellerons',
    'morcelleront',
    'mordançaient',
    'mordançasses',
    'mordancerais',
    'mordancerait',
    'mordancèrent',
    'mordanceriez',
    'mordancerons',
    'mordanceront',
    'mordillaient',
    'mordillantes',
    'mordillasses',
    'mordillement',
    'mordillerais',
    'mordillerait',
    'mordillèrent',
    'mordilleriez',
    'mordillerons',
    'mordilleront',
    'mordorassent',
    'mordorassiez',
    'mordorerions',
    'morfilassent',
    'morfilassiez',
    'morfilerions',
    'morflassions',
    'morfleraient',
    'morfondaient',
    'morfondirent',
    'morfondisses',
    'morfondrions',
    'morganatique',
    'morguassions',
    'morgueraient',
    'morigénaient',
    'morigénasses',
    'morigénerais',
    'morigénerait',
    'morigénèrent',
    'morigéneriez',
    'morigénerons',
    'morigéneront',
    'morphinismes',
    'morphinomane',
    'morphologies',
    'mortaisaient',
    'mortaisasses',
    'mortaiserais',
    'mortaiserait',
    'mortaisèrent',
    'mortaiseriez',
    'mortaiserons',
    'mortaiseront',
    'mortaiseuses',
    'mortellement',
    'morte-saison',
    'mortifiaient',
    'mortifiantes',
    'mortifiasses',
    'mortifierais',
    'mortifierait',
    'mortifièrent',
    'mortifieriez',
    'mortifierons',
    'mortifieront',
    'moscoutaires',
    'motionnaient',
    'motionnasses',
    'motionnerais',
    'motionnerait',
    'motionnèrent',
    'motionneriez',
    'motionnerons',
    'motionneront',
    'motivassions',
    'motiveraient',
    'motoculteurs',
    'motocultures',
    'motocyclette',
    'motocyclisme',
    'motocycliste',
    'motogodilles',
    'motonautique',
    'motonautisme',
    'motoneigiste',
    'motorisaient',
    'motorisasses',
    'motorisation',
    'motoriserais',
    'motoriserait',
    'motorisèrent',
    'motoriseriez',
    'motoriserons',
    'motoriseront',
    'mototracteur',
    'mots-valises',
    'moucharabieh',
    'mouchardages',
    'mouchardâmes',
    'mouchardasse',
    'mouchardâtes',
    'moucharderai',
    'moucharderas',
    'moucharderez',
    'mouchardions',
    'mouchassions',
    'moucheraient',
    'moucheronnai',
    'moucheronnas',
    'moucheronnât',
    'moucheronner',
    'moucheronnes',
    'moucheronnez',
    'mouchetaient',
    'mouchetasses',
    'mouchetèrent',
    'mouchetterai',
    'mouchetteras',
    'mouchetterez',
    'mouettassent',
    'mouettassiez',
    'mouetterions',
    'mouftassions',
    'moufteraient',
    'mouillassent',
    'mouillassiez',
    'mouillerions',
    'moulinassent',
    'moulinassiez',
    'moulinerions',
    'moulurassent',
    'moulurassiez',
    'moulurerions',
    'mouronnaient',
    'mouronnasses',
    'mouronnerais',
    'mouronnerait',
    'mouronnèrent',
    'mouronneriez',
    'mouronnerons',
    'mouronneront',
    'mousquetades',
    'mousquetaire',
    'mousqueterie',
    'moussaillons',
    'moussassions',
    'mousseraient',
    'moustérienne',
    'moustiquaire',
    'moutardières',
    'moutonnaient',
    'moutonnantes',
    'moutonnasses',
    'moutonnement',
    'moutonnerais',
    'moutonnerait',
    'moutonnèrent',
    'moutonneries',
    'moutonneriez',
    'moutonnerons',
    'moutonneront',
    'moutonneuses',
    'moutonnières',
    'mouvementais',
    'mouvementait',
    'mouvementant',
    'mouvementées',
    'mouvementent',
    'mouvementera',
    'mouvementiez',
    'mouvementons',
    'moyenâgeuses',
    'moyennassent',
    'moyennassiez',
    'moyennerions',
    'mucilagineux',
    'mugissements',
    'muguetassent',
    'muguetassiez',
    'muguetterais',
    'muguetterait',
    'muguetteriez',
    'muguetterons',
    'muguetteront',
    'mulotassions',
    'muloteraient',
    'multicolores',
    'multifilaire',
    'multilatéral',
    'multimodales',
    'multiplexais',
    'multiplexait',
    'multiplexant',
    'multiplexées',
    'multiplexent',
    'multiplexera',
    'multiplexeur',
    'multiplexiez',
    'multiplexons',
    'multipliable',
    'multipliâmes',
    'multipliasse',
    'multipliâtes',
    'multiplicité',
    'multiplierai',
    'multiplieras',
    'multiplierez',
    'multipliions',
    'multiraciale',
    'multiraciaux',
    'multirisques',
    'municipalisa',
    'municipalise',
    'municipalisé',
    'municipalité',
    'munificences',
    'munificentes',
    'munitionnais',
    'munitionnait',
    'munitionnant',
    'munitionnées',
    'munitionnent',
    'munitionnera',
    'munitionniez',
    'munitionnons',
    'muraillaient',
    'muraillasses',
    'muraillerais',
    'muraillerait',
    'muraillèrent',
    'murailleriez',
    'muraillerons',
    'murailleront',
    'mûrissements',
    'murmurassent',
    'murmurassiez',
    'murmurerions',
    'musardassent',
    'musardassiez',
    'musarderions',
    'musclassions',
    'muscleraient',
    'musculations',
    'musculatures',
    'muselassions',
    'musellements',
    'musellerions',
    'muséographie',
    'musicalement',
    'musicographe',
    'musicologies',
    'musicologues',
    'musiquassent',
    'musiquassiez',
    'musiquerions',
    'musquassions',
    'musqueraient',
    'mussitations',
    'mutilassions',
    'mutilatrices',
    'mutileraient',
    'mutinassions',
    'mutineraient',
    'mutuellement',
    'mycodermique',
    'mydriatiques',
    'myéloblastes',
    'myélographie',
    'myriophylles',
    'myrmécophile',
    'mystérieuses',
    'mystifiables',
    'mystifiaient',
    'mystifiantes',
    'mystifiasses',
    'mystifierais',
    'mystifierait',
    'mystifièrent',
    'mystifieriez',
    'mystifierons',
    'mystifieront',
    'mystiquement',
    'mythographes',
    'mythologique',
    'mytilotoxine',
    'nantissables',
    'nantissaient',
    'nantissement',
    'napoléoniens',
    'napolitaines',
    'narcissiques',
    'narcissismes',
    'narcolepsies',
    'narguassions',
    'nargueraient',
    'nasalisaient',
    'nasalisasses',
    'nasalisation',
    'nasaliserais',
    'nasaliserait',
    'nasalisèrent',
    'nasaliseriez',
    'nasaliserons',
    'nasaliseront',
    'nasillassent',
    'nasillassiez',
    'nasillements',
    'nasillerions',
    'nasonnements',
    'nationalisai',
    'nationalisas',
    'nationalisât',
    'nationalisée',
    'nationaliser',
    'nationalises',
    'nationalisés',
    'nationalisez',
    'nationalisme',
    'nationaliste',
    'nationalités',
    'naturalisais',
    'naturalisait',
    'naturalisant',
    'naturalisées',
    'naturalisent',
    'naturalisera',
    'naturalisiez',
    'naturalismes',
    'naturalisons',
    'naturalistes',
    'naufrageâmes',
    'naufrageasse',
    'naufrageâtes',
    'naufragerais',
    'naufragerait',
    'naufragèrent',
    'naufrageriez',
    'naufragerons',
    'naufrageront',
    'naufrageuses',
    'nauséabondes',
    'navalisation',
    'naviculaires',
    'navigabilité',
    'navigatrices',
    'naviguassent',
    'naviguassiez',
    'naviguerions',
    'navire-école',
    'néantisaient',
    'néantisasses',
    'néantisation',
    'néantiserais',
    'néantiserait',
    'néantisèrent',
    'néantiseriez',
    'néantiserons',
    'néantiseront',
    'nébulisation',
    'nécessitâmes',
    'nécessitante',
    'nécessitants',
    'nécessitasse',
    'nécessitâtes',
    'nécessiterai',
    'nécessiteras',
    'nécessiterez',
    'nécessiteuse',
    'nécessitions',
    'nécrologique',
    'nécromancien',
    'nécromancies',
    'nécrophilies',
    'nécrosassent',
    'nécrosassiez',
    'nécroserions',
    'néerlandaise',
    'négativement',
    'négatoscopes',
    'négligeables',
    'négligeaient',
    'négligeantes',
    'négligeasses',
    'négligemment',
    'négligerions',
    'négociassent',
    'négociassiez',
    'négociateurs',
    'négociations',
    'négociatrice',
    'négocierions',
    'négrillonnes',
    'néguentropie',
    'nématocystes',
    'néo-celtique',
    'néocomiennes',
    'néoformation',
    'néo-gothique',
    'néo-grecques',
    'néo-kantisme',
    'néolithiques',
    'néonatalogie',
    'néoplasiques',
    'néo-réalisme',
    'néo-réaliste',
    'néo-thomisme',
    'néphrectomie',
    'néphrétiques',
    'néphrologies',
    'néphrologues',
    'néphropathie',
    'nerveusement',
    'nervurassent',
    'nervurassiez',
    'nervurerions',
    'nestoriennes',
    'nettoiements',
    'nettoierions',
    'nettoyassent',
    'nettoyassiez',
    'neurasthénie',
    'neuroblastes',
    'neurologique',
    'neurologiste',
    'neutralisais',
    'neutralisait',
    'neutralisant',
    'neutralisées',
    'neutralisent',
    'neutralisera',
    'neutralisiez',
    'neutralismes',
    'neutralisons',
    'neutralistes',
    'neutroniques',
    'neutrophiles',
    'neuvièmement',
    'névralgiques',
    'névrodermite',
    'névropathies',
    'niaisassions',
    'niaiseraient',
    'nickelassent',
    'nickelassiez',
    'nickélifères',
    'nickellerais',
    'nickellerait',
    'nickelleriez',
    'nickellerons',
    'nickelleront',
    'nicotinisais',
    'nicotinisait',
    'nicotinisant',
    'nicotinisées',
    'nicotinisent',
    'nicotinisera',
    'nicotinisiez',
    'nicotinismes',
    'nicotinisons',
    'nictitations',
    'nid-de-poule',
    'nidifiassent',
    'nidifiassiez',
    'nidification',
    'nidifierions',
    'niellassions',
    'nielleraient',
    'nietzschéens',
    'niquedouille',
    'nitratassent',
    'nitratassiez',
    'nitratations',
    'nitraterions',
    'nitrifiaient',
    'nitrifiasses',
    'nitrifierais',
    'nitrifierait',
    'nitrifièrent',
    'nitrifieriez',
    'nitrifierons',
    'nitrifieront',
    'nitrobenzène',
    'nitrobenzine',
    'nitrosations',
    'nitrotoluène',
    'nitrurassent',
    'nitrurassiez',
    'nitrurations',
    'nitrurerions',
    'nivelassions',
    'nivellements',
    'nivellerions',
    'nivo-pluvial',
    'noirciraient',
    'noircissante',
    'noircissants',
    'noircisseurs',
    'noircissions',
    'noircissures',
    'nolisassions',
    'noliseraient',
    'nomadisaient',
    'nomadisasses',
    'nomadiserais',
    'nomadiserait',
    'nomadisèrent',
    'nomadiseriez',
    'nomadiserons',
    'nomadiseront',
    'nombrassions',
    'nombrilismes',
    'nomenclateur',
    'nomenclature',
    'nominalement',
    'nominalisais',
    'nominalisait',
    'nominalisant',
    'nominalisées',
    'nominalisent',
    'nominalisera',
    'nominalisiez',
    'nominalismes',
    'nominalisons',
    'nominalistes',
    'nomographies',
    'nonagénaires',
    'non-alignées',
    'nonchalances',
    'nonchalantes',
    'non-croyante',
    'non-croyants',
    'non-directif',
    'non-engagées',
    'non-fumeuses',
    'non-initiées',
    'non-inscrite',
    'non-inscrits',
    'non-paiement',
    'nonpareilles',
    'non-recevoir',
    'non-résident',
    'non-respects',
    'non-violence',
    'non-violente',
    'non-violents',
    'nord-coréens',
    'nordissaient',
    'normaliennes',
    'normalisâmes',
    'normalisante',
    'normalisants',
    'normalisasse',
    'normalisâtes',
    'normaliserai',
    'normaliseras',
    'normaliserez',
    'normalisions',
    'norvégiennes',
    'nosographies',
    'nostalgiques',
    'notifiassent',
    'notifiassiez',
    'notification',
    'notifierions',
    'notionnelles',
    'nourriceries',
    'nourricières',
    'nourrissages',
    'nourrissante',
    'nourrissants',
    'nourrisseurs',
    'nourrissions',
    'nouvellement',
    'nouvellistes',
    'noyautassent',
    'noyautassiez',
    'noyauterions',
    'nuançassions',
    'nuanceraient',
    'nucléassions',
    'nucléeraient',
    'nucléoniques',
    'nuisiblement',
    'numérisaient',
    'numérisasses',
    'numériserais',
    'numériserait',
    'numérisèrent',
    'numériseriez',
    'numériserons',
    'numériseront',
    'numérotaient',
    'numérotasses',
    'numérotation',
    'numéroterais',
    'numéroterait',
    'numérotèrent',
    'numéroteriez',
    'numéroterons',
    'numéroteront',
    'numismatique',
    'nummulitique',
    'nuncupations',
    'nutritionnel',
    'nymphomanies',
    'obédienciers',
    'obédientiels',
    'objectassent',
    'objectassiez',
    'objecterions',
    'objectivâmes',
    'objectivasse',
    'objectivâtes',
    'objectiverai',
    'objectiveras',
    'objectiverez',
    'objectivions',
    'objectivisme',
    'objectivités',
    'objurgations',
    'objurguaient',
    'objurguasses',
    'objurguerais',
    'objurguerait',
    'objurguèrent',
    'objurgueriez',
    'objurguerons',
    'objurgueront',
    'obligataires',
    'obligatoires',
    'obligeamment',
    'obligeassent',
    'obligeassiez',
    'obligeraient',
    'obliquassent',
    'obliquassiez',
    'obliquerions',
    'oblitéraient',
    'oblitérasses',
    'oblitérateur',
    'oblitération',
    'oblitérerais',
    'oblitérerait',
    'oblitérèrent',
    'oblitéreriez',
    'oblitérerons',
    'oblitéreront',
    'obnubilaient',
    'obnubilasses',
    'obnubilation',
    'obnubilerais',
    'obnubilerait',
    'obnubilèrent',
    'obnubileriez',
    'obnubilerons',
    'obnubileront',
    'obombrassent',
    'obombrassiez',
    'obombrerions',
    'obscurcirais',
    'obscurcirait',
    'obscurcirent',
    'obscurciriez',
    'obscurcirons',
    'obscurciront',
    'obscurcisses',
    'obscurcissez',
    'obsécrations',
    'obsédassions',
    'obséderaient',
    'obséquieuses',
    'obséquiosité',
    'observassent',
    'observassiez',
    'observateurs',
    'observations',
    'observatoire',
    'observatrice',
    'observerions',
    'obsessionnel',
    'obsidionales',
    'obsolescence',
    'obsolescente',
    'obsolescents',
    'obstétricale',
    'obstétricaux',
    'obstétricien',
    'obstétriques',
    'obstinassiez',
    'obstinations',
    'obstruassent',
    'obstruassiez',
    'obstructions',
    'obstructives',
    'obstruerions',
    'obtempérâmes',
    'obtempérasse',
    'obtempérâtes',
    'obtempérerai',
    'obtempéreras',
    'obtempérerez',
    'obtempérions',
    'obtiendrions',
    'obturassions',
    'obturatrices',
    'obtureraient',
    'obviendrions',
    'occasionnais',
    'occasionnait',
    'occasionnant',
    'occasionnées',
    'occasionnels',
    'occasionnent',
    'occasionnera',
    'occasionniez',
    'occasionnons',
    'occidentales',
    'occultassent',
    'occultassiez',
    'occultations',
    'occulterions',
    'occupassions',
    'occuperaient',
    'océanographe',
    'océanologies',
    'océanologues',
    'octaviassent',
    'octaviassiez',
    'octavierions',
    'octogénaires',
    'octosyllabes',
    'octroierions',
    'octroyassent',
    'octroyassiez',
    'octuplassent',
    'octuplassiez',
    'octuplerions',
    'odontologies',
    'odoriférante',
    'odoriférants',
    'oecuménicité',
    'oecuméniques',
    'oecuménismes',
    'oedémateuses',
    'oeil-de-chat',
    'oeilletonnai',
    'oeilletonnas',
    'oeilletonnât',
    'oeilletonnée',
    'oeilletonner',
    'oeilletonnes',
    'oeilletonnés',
    'oeilletonnez',
    'oeils-de-pie',
    'oenanthiques',
    'oenologiques',
    'oenométrique',
    'oesophagiens',
    'oesophagites',
    'oeuvrassions',
    'oeuvreraient',
    'offensassent',
    'offensassiez',
    'offenserions',
    'officialisai',
    'officialisas',
    'officialisât',
    'officialisée',
    'officialiser',
    'officialises',
    'officialisés',
    'officialisez',
    'officialités',
    'officiassent',
    'officiassiez',
    'officierions',
    'offsettistes',
    'offusquaient',
    'offusquasses',
    'offusquerais',
    'offusquerait',
    'offusquèrent',
    'offusqueriez',
    'offusquerons',
    'offusqueront',
    'oiselassions',
    'oisellerions',
    'oléagineuses',
    'oléiculteurs',
    'oléicultrice',
    'oléicultures',
    'oligarchique',
    'oligophrénie',
    'ombellifères',
    'ombelliforme',
    'ombrageaient',
    'ombrageasses',
    'ombragerions',
    'omnipotences',
    'omnipotentes',
    'omniprésence',
    'omniprésente',
    'omniprésents',
    'omnisciences',
    'omniscientes',
    'ondoieraient',
    'ondoyassions',
    'ondulassions',
    'ondulatoires',
    'onduleraient',
    'one-man-show',
    'onéreusement',
    'onguligrades',
    'oniromancien',
    'oniromancies',
    'onomastiques',
    'ontogéniques',
    'ontologiques',
    'ontologismes',
    'onychophagie',
    'opacifiaient',
    'opacifiasses',
    'opacifierais',
    'opacifierait',
    'opacifièrent',
    'opacifieriez',
    'opacifierons',
    'opacifieront',
    'opacimétries',
    'opalescences',
    'opalescentes',
    'opalisassent',
    'opalisassiez',
    'opaliserions',
    'opérationnel',
    'operculaires',
    'ophioglosses',
    'ophiographie',
    'ophiolâtries',
    'ophtalmiques',
    'opiaçassions',
    'opiaceraient',
    'opiniâtrâmes',
    'opiniâtrâtes',
    'opiniâtrerez',
    'opiniâtretés',
    'opiniâtrions',
    'opisthodomes',
    'opothérapies',
    'opportunisme',
    'opportuniste',
    'opportunités',
    'opposabilité',
    'opposassions',
    'opposeraient',
    'oppressaient',
    'oppressantes',
    'oppressasses',
    'oppresserais',
    'oppresserait',
    'oppressèrent',
    'oppresseriez',
    'oppresserons',
    'oppresseront',
    'opprimassent',
    'opprimassiez',
    'opprimerions',
    'optimalisais',
    'optimalisait',
    'optimalisant',
    'optimalisées',
    'optimalisent',
    'optimalisera',
    'optimalisiez',
    'optimalisons',
    'optimisaient',
    'optimisasses',
    'optimisation',
    'optimiserais',
    'optimiserait',
    'optimisèrent',
    'optimiseriez',
    'optimiserons',
    'optimiseront',
    'optionnelles',
    'orageusement',
    'orangeassent',
    'orangeassiez',
    'orangeraient',
    'orang-outang',
    'orbiculaires',
    'orbitassions',
    'orbiteraient',
    'orchestiques',
    'orchestrales',
    'orchestrâmes',
    'orchestrasse',
    'orchestrâtes',
    'orchestrerai',
    'orchestreras',
    'orchestrerez',
    'orchestrions',
    'ordinatrices',
    'ordonnançais',
    'ordonnançait',
    'ordonnançant',
    'ordonnancées',
    'ordonnancent',
    'ordonnancera',
    'ordonnanciez',
    'ordonnançons',
    'ordonnassent',
    'ordonnassiez',
    'ordonnateurs',
    'ordonnatrice',
    'ordonnerions',
    'organicismes',
    'organigramme',
    'organisables',
    'organisaient',
    'organisasses',
    'organisateur',
    'organisation',
    'organiserais',
    'organiserait',
    'organisèrent',
    'organiseriez',
    'organiserons',
    'organiseront',
    'organsinâmes',
    'organsinasse',
    'organsinâtes',
    'organsinions',
    'orgueilleuse',
    'orientalisme',
    'orientaliste',
    'orientassent',
    'orientassiez',
    'orientateurs',
    'orientations',
    'orientatrice',
    'orientements',
    'orienterions',
    'originalités',
    'oringuassent',
    'oringuassiez',
    'oringuerions',
    'ornemanistes',
    'ornementales',
    'ornementâmes',
    'ornementasse',
    'ornementâtes',
    'ornementerai',
    'ornementeras',
    'ornementerez',
    'ornementions',
    'ornithogales',
    'ornithologie',
    'ornithologue',
    'orographique',
    'orphéonistes',
    'orthocentres',
    'orthodonties',
    'orthogenèses',
    'orthogonales',
    'orthographes',
    'orthographia',
    'orthographie',
    'orthographié',
    'orthopédique',
    'orthopédiste',
    'orthophonies',
    'oscillassent',
    'oscillassiez',
    'oscillations',
    'oscillatoire',
    'oscillerions',
    'oscillomètre',
    'ossifiassent',
    'ossifiassiez',
    'ossification',
    'ossifierions',
    'ostentations',
    'ostentatoire',
    'ostéoblastes',
    'ostéoclasies',
    'ostéogenèses',
    'ostéomalacie',
    'ostéomyélite',
    'ostéopathies',
    'ostéoplastie',
    'ostéoporoses',
    'ostéosarcome',
    'ostracisâmes',
    'ostracisasse',
    'ostracisâtes',
    'ostraciserai',
    'ostraciseras',
    'ostraciserez',
    'ostracisions',
    'ouatinassent',
    'ouatinassiez',
    'ouatinerions',
    'oubliassions',
    'oublieraient',
    'ouillassions',
    'ouilleraient',
    'ourdissaient',
    'ourdisseuses',
    'outillassent',
    'outillassiez',
    'outillerions',
    'outrageaient',
    'outrageantes',
    'outrageasses',
    'outragerions',
    'outrancières',
    'outrecuidant',
    'outrepassais',
    'outrepassait',
    'outrepassant',
    'outrepassées',
    'outrepassent',
    'outrepassera',
    'outrepassiez',
    'outrepassons',
    'ouvrageaient',
    'ouvrageasses',
    'ouvragerions',
    'ouvre-boîtes',
    'ouvriérismes',
    'ouvriéristes',
    'ovalisassent',
    'ovalisassiez',
    'ovalisations',
    'ovaliserions',
    'ovariectomie',
    'ovationnâmes',
    'ovationnasse',
    'ovationnâtes',
    'ovationnerai',
    'ovationneras',
    'ovationnerez',
    'ovationnions',
    'ovipositeurs',
    'ovovivipares',
    'oxycarbonées',
    'oxychlorures',
    'oxygénassent',
    'oxygénassiez',
    'oxygénations',
    'oxygénerions',
    'ozonisassent',
    'ozonisassiez',
    'ozonisateurs',
    'ozonisations',
    'ozoniserions',
    'pacageassent',
    'pacageassiez',
    'pacageraient',
    'pachydermies',
    'pacifiassent',
    'pacifiassiez',
    'pacificateur',
    'pacification',
    'pacifierions',
    'pacquassions',
    'pacqueraient',
    'pactisassent',
    'pactisassiez',
    'pactiserions',
    'pagaieraient',
    'paganisaient',
    'paganisasses',
    'paganiserais',
    'paganiserait',
    'paganisèrent',
    'paganiseriez',
    'paganiserons',
    'paganiseront',
    'pagayassions',
    'pagayeraient',
    'pageotassent',
    'pageotassiez',
    'pageoterions',
    'paginassions',
    'pagineraient',
    'pagnotassent',
    'pagnotassiez',
    'pagnoterions',
    'paillardâmes',
    'paillardasse',
    'paillardâtes',
    'paillarderai',
    'paillarderas',
    'paillarderez',
    'paillardions',
    'paillardises',
    'paillassions',
    'paillassonna',
    'paillassonne',
    'paillassonné',
    'pailleraient',
    'pailletaient',
    'pailletasses',
    'pailletèrent',
    'pailletterai',
    'pailletteras',
    'pailletterez',
    'paillonnâmes',
    'paillonnasse',
    'paillonnâtes',
    'paillonnerai',
    'paillonneras',
    'paillonnerez',
    'paillonnions',
    'paisiblement',
    'paisselaient',
    'paisselasses',
    'paisselèrent',
    'paissellerai',
    'paisselleras',
    'paissellerez',
    'pajotassions',
    'pajoteraient',
    'pakistanaise',
    'palabrassent',
    'palabrassiez',
    'palabrerâmes',
    'palabrerasse',
    'palabrerâtes',
    'palancraient',
    'palancrasses',
    'palancrerais',
    'palancrerait',
    'palancrèrent',
    'palancreriez',
    'palancrerons',
    'palancreront',
    'palangraient',
    'palangrasses',
    'palangrerais',
    'palangrerait',
    'palangrèrent',
    'palangreriez',
    'palangrerons',
    'palangreront',
    'palanguaient',
    'palanguasses',
    'palanguerais',
    'palanguerait',
    'palanguèrent',
    'palangueriez',
    'palanguerons',
    'palangueront',
    'palanquaient',
    'palanquasses',
    'palanquerais',
    'palanquerait',
    'palanquèrent',
    'palanqueriez',
    'palanquerons',
    'palanqueront',
    'palatalisées',
    'palefrenière',
    'palefreniers',
    'paléoclimats',
    'paléographes',
    'paléographie',
    'paléothérium',
    'paléozoïques',
    'palestiniens',
    'palettassent',
    'palettassiez',
    'paletterions',
    'palettisâmes',
    'palettisasse',
    'palettisâtes',
    'palettiserai',
    'palettiseras',
    'palettiserez',
    'palettisions',
    'palimpsestes',
    'palingénésie',
    'palinodiques',
    'palissadâmes',
    'palissadasse',
    'palissadâtes',
    'palissaderai',
    'palissaderas',
    'palissaderez',
    'palissadions',
    'palissandres',
    'palissassent',
    'palissassiez',
    'palisserions',
    'palissonnais',
    'palissonnait',
    'palissonnant',
    'palissonnées',
    'palissonnent',
    'palissonnera',
    'palissonneur',
    'palissonniez',
    'palissonnons',
    'palliassions',
    'pallieraient',
    'palmiparties',
    'palmipartite',
    'palmiséquées',
    'palotassions',
    'paloteraient',
    'palpitassent',
    'palpitassiez',
    'palpitations',
    'palpiterions',
    'paludologies',
    'palynologies',
    'pamphlétaire',
    'pamplemousse',
    'panachassent',
    'panachassiez',
    'panacherions',
    'panafricaine',
    'panafricains',
    'panaméricain',
    'panarabismes',
    'panclastites',
    'pancosmismes',
    'pancréatines',
    'pancréatique',
    'pancréatites',
    'pandémoniums',
    'panégyriques',
    'panégyristes',
    'panier-repas',
    'panifiassent',
    'panifiassiez',
    'panification',
    'panifierions',
    'paniquassent',
    'paniquassiez',
    'paniquerions',
    'panislamique',
    'panislamisme',
    'panneautages',
    'panneautâmes',
    'panneautasse',
    'panneautâtes',
    'panneauterai',
    'panneauteras',
    'panneauterez',
    'panneautions',
    'panophtalmie',
    'panoramiquai',
    'panoramiquas',
    'panoramiquât',
    'panoramiquer',
    'panoramiques',
    'panoramiquez',
    'panslavismes',
    'pantalonnade',
    'pantelassent',
    'pantelassiez',
    'pantellerais',
    'pantellerait',
    'pantelleriez',
    'pantellerons',
    'pantelleront',
    'pantographes',
    'pantouflâmes',
    'pantouflarde',
    'pantouflards',
    'pantouflasse',
    'pantouflâtes',
    'pantouflerai',
    'pantoufleras',
    'pantouflerez',
    'pantouflière',
    'pantoufliers',
    'pantouflions',
    'papelardises',
    'paperasserie',
    'paperassière',
    'paperassiers',
    'papilionacée',
    'papilionacés',
    'papillifères',
    'papillonnage',
    'papillonnais',
    'papillonnait',
    'papillonnant',
    'papillonnent',
    'papillonnera',
    'papillonniez',
    'papillonnons',
    'papillotages',
    'papillotâmes',
    'papillotante',
    'papillotants',
    'papillotasse',
    'papillotâtes',
    'papilloterai',
    'papilloteras',
    'papilloterez',
    'papillotions',
    'papotassions',
    'papoteraient',
    'papouillâmes',
    'papouillasse',
    'papouillâtes',
    'papouillerai',
    'papouilleras',
    'papouillerez',
    'papouillions',
    'papyrologies',
    'papyrologues',
    'paraboloïdes',
    'paracentèses',
    'parachevâmes',
    'parachevasse',
    'parachevâtes',
    'parachèverai',
    'parachèveras',
    'parachèverez',
    'parachevions',
    'parachutages',
    'parachutâmes',
    'parachutasse',
    'parachutâtes',
    'parachuterai',
    'parachuteras',
    'parachuterez',
    'parachutions',
    'parachutisme',
    'parachutiste',
    'paradassions',
    'paraderaient',
    'paradisiaque',
    'parafassions',
    'paraferaient',
    'paraffinages',
    'paraffinâmes',
    'paraffinasse',
    'paraffinâtes',
    'paraffinerai',
    'paraffineras',
    'paraffinerez',
    'paraffinions',
    'parafiscales',
    'paraisonnais',
    'paraisonnait',
    'paraisonnant',
    'paraisonnées',
    'paraisonnent',
    'paraisonnera',
    'paraisonniez',
    'paraisonnons',
    'paraissaient',
    'paraîtraient',
    'paralangages',
    'parallélisai',
    'parallélisas',
    'parallélisât',
    'parallélisée',
    'paralléliser',
    'parallélises',
    'parallélisés',
    'parallélisez',
    'parallélisme',
    'paralogismes',
    'paralysaient',
    'paralysantes',
    'paralysasses',
    'paralyserais',
    'paralyserait',
    'paralysèrent',
    'paralyseriez',
    'paralyserons',
    'paralyseront',
    'paralytiques',
    'paramédicale',
    'paramédicaux',
    'paramétrable',
    'paramétrages',
    'paramétrâmes',
    'paramétrasse',
    'paramétrâtes',
    'paramétrerai',
    'paramétreras',
    'paramétrerez',
    'paramétrions',
    'paramétrique',
    'parangonnais',
    'parangonnait',
    'parangonnant',
    'parangonnées',
    'parangonnent',
    'parangonnera',
    'parangonniez',
    'parangonnons',
    'paranoïaques',
    'paranormales',
    'paraphassent',
    'paraphassiez',
    'parapherions',
    'paraphernale',
    'paraphernaux',
    'paraphimosis',
    'paraphrasais',
    'paraphrasait',
    'paraphrasant',
    'paraphrasées',
    'paraphrasent',
    'paraphrasera',
    'paraphraseur',
    'paraphrasiez',
    'paraphrasons',
    'paraphrénies',
    'paraplégique',
    'parascolaire',
    'parasitaient',
    'parasitaires',
    'parasitasses',
    'parasiterais',
    'parasiterait',
    'parasitèrent',
    'parasiteriez',
    'parasiterons',
    'parasiteront',
    'parasitismes',
    'parastatales',
    'parathormone',
    'paratonnerre',
    'paratyphique',
    'paratyphoïde',
    'parcellaient',
    'parcellaires',
    'parcellarisa',
    'parcellarise',
    'parcellarisé',
    'parcellasses',
    'parcellerais',
    'parcellerait',
    'parcellèrent',
    'parcelleriez',
    'parcellerons',
    'parcelleront',
    'parcellisais',
    'parcellisait',
    'parcellisant',
    'parcellisées',
    'parcellisent',
    'parcellisera',
    'parcellisiez',
    'parcellisons',
    'parcheminais',
    'parcheminait',
    'parcheminant',
    'parcheminées',
    'parcheminent',
    'parcheminera',
    'parcheminier',
    'parcheminiez',
    'parcheminons',
    'parcimonieux',
    'parcouraient',
    'parcourrions',
    'parcoururent',
    'parcourusses',
    'par-derrière',
    'pardonnables',
    'pardonnaient',
    'pardonnasses',
    'pardonnerais',
    'pardonnerait',
    'pardonnèrent',
    'pardonneriez',
    'pardonnerons',
    'pardonneront',
    'parégoriques',
    'pareillement',
    'parementâmes',
    'parementasse',
    'parementâtes',
    'parementerai',
    'parementeras',
    'parementerez',
    'parementions',
    'parémiologie',
    'parentérales',
    'parenthésées',
    'parerassions',
    'paressassent',
    'paressassiez',
    'paresserions',
    'paresthésies',
    'parfaisaient',
    'parfaitement',
    'parfilassent',
    'parfilassiez',
    'parfilerions',
    'parfondaient',
    'parfondirent',
    'parfondisses',
    'parfondrions',
    'parfumassent',
    'parfumassiez',
    'parfumerions',
    'paridigitidé',
    'parisianisme',
    'parjurassent',
    'parjurassiez',
    'parjurerions',
    'parkérisâmes',
    'parkérisasse',
    'parkérisâtes',
    'parkériserai',
    'parkériseras',
    'parkériserez',
    'parkérisions',
    'parkinsonien',
    'parlementais',
    'parlementait',
    'parlementant',
    'parlementent',
    'parlementera',
    'parlementiez',
    'parlementons',
    'parlotassent',
    'parlotassiez',
    'parloterions',
    'parnassienne',
    'parodiassent',
    'parodiassiez',
    'parodierions',
    'paroissiales',
    'paroissienne',
    'paronymiques',
    'parotidienne',
    'paroxysmales',
    'paroxysmique',
    'paroxystique',
    'parpaillotes',
    'parquassions',
    'parqueraient',
    'parquetaient',
    'parquetasses',
    'parquetèrent',
    'parqueteries',
    'parquetterai',
    'parquetteras',
    'parquetterez',
    'parrainaient',
    'parrainasses',
    'parrainerais',
    'parrainerait',
    'parrainèrent',
    'parraineriez',
    'parrainerons',
    'parraineront',
    'parsemassent',
    'parsemassiez',
    'parsèmerions',
    'partageables',
    'partageaient',
    'partageasses',
    'partagerions',
    'partenariats',
    'partialement',
    'participâmes',
    'participante',
    'participants',
    'participasse',
    'participâtes',
    'participatif',
    'participerai',
    'participeras',
    'participerez',
    'participiale',
    'participiaux',
    'participions',
    'particulière',
    'particuliers',
    'partitionnai',
    'partitionnas',
    'partitionnât',
    'partitionnée',
    'partitionner',
    'partitionnes',
    'partitionnés',
    'partitionnez',
    'parturientes',
    'parturitions',
    'parviendrais',
    'parviendrait',
    'parviendriez',
    'parviendrons',
    'parviendront',
    'parvinssions',
    'pas-de-géant',
    'pas-de-porte',
    'passablement',
    'passacailles',
    'passe-boules',
    'passe-droits',
    'passe-lacets',
    'passementais',
    'passementait',
    'passementant',
    'passementées',
    'passementent',
    'passementera',
    'passementier',
    'passementiez',
    'passementons',
    'passepoilais',
    'passepoilait',
    'passepoilant',
    'passepoilées',
    'passepoilent',
    'passepoilera',
    'passepoiliez',
    'passepoilons',
    'passerinette',
    'passe-volant',
    'passing-shot',
    'passionnâmes',
    'passionnante',
    'passionnants',
    'passionnasse',
    'passionnâtes',
    'passionnelle',
    'passionnerai',
    'passionneras',
    'passionnerez',
    'passionnette',
    'passionnions',
    'passivations',
    'pastellaient',
    'pastellasses',
    'pastellerais',
    'pastellerait',
    'pastellèrent',
    'pastelleriez',
    'pastellerons',
    'pastelleront',
    'pastellistes',
    'pasteurienne',
    'pasteurisais',
    'pasteurisait',
    'pasteurisant',
    'pasteurisées',
    'pasteurisent',
    'pasteurisera',
    'pasteurisiez',
    'pasteurisons',
    'pastichaient',
    'pastichasses',
    'pasticherais',
    'pasticherait',
    'pastichèrent',
    'pasticheriez',
    'pasticherons',
    'pasticheront',
    'pasticheuses',
    'pastillaient',
    'pastillasses',
    'pastillerais',
    'pastillerait',
    'pastillèrent',
    'pastilleriez',
    'pastillerons',
    'pastilleront',
    'pastourelles',
    'patafiolâmes',
    'patafiolasse',
    'patafiolâtes',
    'patafiolerai',
    'patafioleras',
    'patafiolerez',
    'patafiolions',
    'pataphysique',
    'pataugeaient',
    'pataugeaîmes',
    'pataugeaisse',
    'pataugeaîtes',
    'pataugeasses',
    'pataugeoires',
    'pataugerions',
    'patelinaient',
    'patelinasses',
    'patelinerais',
    'patelinerait',
    'patelinèrent',
    'patelineries',
    'patelineriez',
    'patelinerons',
    'patelineront',
    'patentassent',
    'patentassiez',
    'patenterions',
    'paternalisme',
    'paternaliste',
    'pathologique',
    'pathologiste',
    'patibulaires',
    'patientaient',
    'patientasses',
    'patienterais',
    'patienterait',
    'patientèrent',
    'patienteriez',
    'patienterons',
    'patienteront',
    'patinassions',
    'patineraient',
    'pâtissassent',
    'pâtissassiez',
    'pâtisserions',
    'patoisassent',
    'patoisassiez',
    'patoiserions',
    'patouillâmes',
    'patouillasse',
    'patouillâtes',
    'patouillerai',
    'patouilleras',
    'patouillerez',
    'patouillions',
    'patriarcales',
    'patriciennes',
    'patrilocales',
    'patrimoniale',
    'patrimoniaux',
    'patriotardes',
    'patriotiques',
    'patriotismes',
    'patristiques',
    'patronnaient',
    'patronnasses',
    'patronnerais',
    'patronnerait',
    'patronnèrent',
    'patronneriez',
    'patronnerons',
    'patronneront',
    'patronnesses',
    'patronymique',
    'patrouillais',
    'patrouillait',
    'patrouillant',
    'patrouillent',
    'patrouillera',
    'patrouilleur',
    'patrouilliez',
    'patrouillons',
    'pattemouille',
    'pattinsonage',
    'pâturassions',
    'pâtureraient',
    'pauliniennes',
    'paumoierions',
    'paumoyassent',
    'paumoyassiez',
    'paupérisâmes',
    'paupérisasse',
    'paupérisâtes',
    'paupériserai',
    'paupériseras',
    'paupériserez',
    'paupérisions',
    'pavanassions',
    'pavaneraient',
    'pavimenteuse',
    'pavoisassent',
    'pavoisassiez',
    'pavoisements',
    'pavoiserions',
    'paysanneries',
    'peaufinaient',
    'peaufinasses',
    'peaufinerais',
    'peaufinerait',
    'peaufinèrent',
    'peaufineriez',
    'peaufinerons',
    'peaufineront',
    'peaussassent',
    'peaussassiez',
    'peausserions',
    'peaux-rouges',
    'péclotassent',
    'péclotassiez',
    'pécloterions',
    'pédagogiques',
    'pédalassions',
    'pédaleraient',
    'pédantesques',
    'pédérastique',
    'pédestrement',
    'pédicellaire',
    'pédiculaires',
    'pédonculaire',
    'peignassions',
    'peigneraient',
    'peignissions',
    'peinardement',
    'peinturaient',
    'peinturasses',
    'peinturerais',
    'peinturerait',
    'peinturèrent',
    'peintureriez',
    'peinturerons',
    'peintureront',
    'peinturlurai',
    'peinturluras',
    'peinturlurât',
    'peinturlurée',
    'peinturlurer',
    'peinturlures',
    'peinturlurés',
    'peinturlurez',
    'pélagianisme',
    'pélargoniums',
    'pélasgiennes',
    'pellagreuses',
    'pelle-pioche',
    'pelletassent',
    'pelletassiez',
    'pelletiérine',
    'pelletterais',
    'pelletterait',
    'pelletteriez',
    'pelletterons',
    'pelletteront',
    'pelliculages',
    'pelliculaire',
    'pelliculeuse',
    'pelotassions',
    'peloteraient',
    'pelotonnâmes',
    'pelotonnasse',
    'pelotonnâtes',
    'pelotonnerai',
    'pelotonneras',
    'pelotonnerez',
    'pelotonnions',
    'peluchassent',
    'peluchassiez',
    'pelucherions',
    'pelvigraphie',
    'pénalisaient',
    'pénalisasses',
    'pénalisation',
    'pénaliserais',
    'pénaliserait',
    'pénalisèrent',
    'pénaliseriez',
    'pénaliserons',
    'pénaliseront',
    'penchassions',
    'pencheraient',
    'pendillaient',
    'pendillasses',
    'pendillerais',
    'pendillerait',
    'pendillèrent',
    'pendilleriez',
    'pendillerons',
    'pendilleront',
    'pendouillais',
    'pendouillait',
    'pendouillant',
    'pendouillent',
    'pendouillera',
    'pendouilliez',
    'pendouillons',
    'pendulassent',
    'pendulassiez',
    'pendulerions',
    'pénétrassent',
    'pénétrassiez',
    'pénétrations',
    'pénétrerions',
    'pénétromètre',
    'pénicillines',
    'pénicilliums',
    'péninsulaire',
    'pénitencerie',
    'pénitenciers',
    'pénitentiale',
    'pénitentiaux',
    'pénitentiels',
    'pensionnaire',
    'pensionnâmes',
    'pensionnasse',
    'pensionnâtes',
    'pensionnerai',
    'pensionneras',
    'pensionnerez',
    'pensionnions',
    'pentadactyle',
    'pentagonales',
    'pentateuques',
    'pentatonique',
    'pépiniériste',
    'perce-pierre',
    'perceptibles',
    'perceptrices',
    'percevraient',
    'perchassions',
    'percheraient',
    'percheronnes',
    'perchlorates',
    'perchlorique',
    'percnoptères',
    'percolateurs',
    'percutassent',
    'percutassiez',
    'percuterions',
    'perdurassent',
    'perdurassiez',
    'perdurerions',
    'pérégrinâmes',
    'pérégrinasse',
    'pérégrinâtes',
    'pérégrinerai',
    'pérégrineras',
    'pérégrinerez',
    'pérégrinions',
    'péremptoires',
    'pérennisâmes',
    'pérennisasse',
    'pérennisâtes',
    'pérenniserai',
    'pérenniseras',
    'pérenniserez',
    'pérennisions',
    'péréquations',
    'perfectibles',
    'perfectionna',
    'perfectionne',
    'perfectionné',
    'perforassent',
    'perforassiez',
    'perforateurs',
    'perforations',
    'perforatrice',
    'perforerions',
    'performances',
    'performantes',
    'performatifs',
    'performative',
    'perfusassent',
    'perfusassiez',
    'perfuserions',
    'périchondres',
    'périclitâmes',
    'périclitasse',
    'périclitâtes',
    'péricliterai',
    'péricliteras',
    'péricliterez',
    'périclitions',
    'périmassions',
    'périmeraient',
    'périodicités',
    'périphérique',
    'périphlébite',
    'périphrasais',
    'périphrasait',
    'périphrasant',
    'périphrasent',
    'périphrasera',
    'périphrasiez',
    'périphrasons',
    'périscolaire',
    'périscopique',
    'périssologie',
    'péritonéales',
    'pérityphlite',
    'perlimpinpin',
    'permanentais',
    'permanentait',
    'permanentant',
    'permanentées',
    'permanentent',
    'permanentera',
    'permanentiez',
    'permanentons',
    'permanganate',
    'perméabilisa',
    'perméabilise',
    'perméabilisé',
    'perméabilité',
    'permettaient',
    'permettrions',
    'permissivité',
    'permittivité',
    'permutassent',
    'permutassiez',
    'permutations',
    'permuterions',
    'pernicieuses',
    'pérorassions',
    'péroreraient',
    'peroxydaient',
    'péroxydaient',
    'peroxydasses',
    'péroxydasses',
    'peroxyderais',
    'péroxyderais',
    'peroxyderait',
    'péroxyderait',
    'peroxydèrent',
    'péroxydèrent',
    'peroxyderiez',
    'péroxyderiez',
    'peroxyderons',
    'péroxyderons',
    'peroxyderont',
    'péroxyderont',
    'perpétraient',
    'perpétrasses',
    'perpétration',
    'perpétrerais',
    'perpétrerait',
    'perpétrèrent',
    'perpétreriez',
    'perpétrerons',
    'perpétreront',
    'perpétuaient',
    'perpétuasses',
    'perpétuation',
    'perpétuelles',
    'perpétuerais',
    'perpétuerait',
    'perpétuèrent',
    'perpétueriez',
    'perpétuerons',
    'perpétueront',
    'perquisiteur',
    'perquisition',
    'perruquières',
    'persécutâmes',
    'persécutasse',
    'persécutâtes',
    'persécuterai',
    'persécuteras',
    'persécuterez',
    'persécuteurs',
    'persécutions',
    'persécutrice',
    'persévérâmes',
    'persévérance',
    'persévérante',
    'persévérants',
    'persévérasse',
    'persévérâtes',
    'persévérerai',
    'persévéreras',
    'persévérerez',
    'persévérions',
    'persiflaient',
    'persiflasses',
    'persiflerais',
    'persiflerait',
    'persiflèrent',
    'persifleriez',
    'persiflerons',
    'persifleront',
    'persifleuses',
    'persillaient',
    'persillasses',
    'persillerais',
    'persillerait',
    'persillèrent',
    'persilleriez',
    'persillerons',
    'persilleront',
    'persistaient',
    'persistances',
    'persistantes',
    'persistasses',
    'persisterais',
    'persisterait',
    'persistèrent',
    'persisteriez',
    'persisterons',
    'persisteront',
    'personnalisa',
    'personnalise',
    'personnalisé',
    'personnalité',
    'personnelles',
    'personnifiai',
    'personnifias',
    'personnifiât',
    'personnifiée',
    'personnifier',
    'personnifies',
    'personnifiés',
    'personnifiez',
    'perspectives',
    'perspicacité',
    'perspiration',
    'persuadaient',
    'persuadasses',
    'persuaderais',
    'persuaderait',
    'persuadèrent',
    'persuaderiez',
    'persuaderons',
    'persuaderont',
    'persulfurées',
    'pertinemment',
    'pertuisanier',
    'perturbaient',
    'perturbantes',
    'perturbasses',
    'perturbateur',
    'perturbation',
    'perturberais',
    'perturberait',
    'perturbèrent',
    'perturberiez',
    'perturberons',
    'perturberont',
    'perversement',
    'pervertirais',
    'pervertirait',
    'pervertirent',
    'pervertiriez',
    'pervertirons',
    'pervertiront',
    'pervertisses',
    'pervertissez',
    'pervibraient',
    'pervibrasses',
    'pervibrerais',
    'pervibrerait',
    'pervibrèrent',
    'pervibreriez',
    'pervibrerons',
    'pervibreront',
    'pèse-esprits',
    'pèse-lettres',
    'pèse-liqueur',
    'pestiférâmes',
    'pestiférasse',
    'pestiférâtes',
    'pestiférerai',
    'pestiféreras',
    'pestiférerez',
    'pestiférions',
    'pestilentiel',
    'pétaradaient',
    'pétaradantes',
    'pétaradasses',
    'pétaraderais',
    'pétaraderait',
    'pétaradèrent',
    'pétaraderiez',
    'pétaraderons',
    'pétaraderont',
    'pétardassent',
    'pétardassiez',
    'pétarderions',
    'pet-de-nonne',
    'pétillassent',
    'pétillassiez',
    'pétillements',
    'pétillerions',
    'petit-beurre',
    'petite-fille',
    'petite-nièce',
    'pétitionnais',
    'pétitionnait',
    'pétitionnant',
    'pétitionnent',
    'pétitionnera',
    'pétitionniez',
    'pétitionnons',
    'petit-maître',
    'petits-laits',
    'petit-suisse',
    'pétouillâmes',
    'pétouillasse',
    'pétouillâtes',
    'pétouillerai',
    'pétouilleras',
    'pétouillerez',
    'pétouillions',
    'pétrarquisai',
    'pétrarquisas',
    'pétrarquisât',
    'pétrarquiser',
    'pétrarquises',
    'pétrarquisez',
    'pétrarquisme',
    'pétrarquiste',
    'pétrifiaient',
    'pétrifiantes',
    'pétrifiasses',
    'pétrifierais',
    'pétrifierait',
    'pétrifièrent',
    'pétrifieriez',
    'pétrifierons',
    'pétrifieront',
    'pétrissables',
    'pétrissaient',
    'pétrisseuses',
    'pétrochimies',
    'pétrodollars',
    'pétrographie',
    'pétrolifères',
    'pétunassions',
    'pétuneraient',
    'peuplassions',
    'peupleraient',
    'peureusement',
    'phagédénique',
    'phagédénisme',
    'phagocytaire',
    'phagocytâmes',
    'phagocytasse',
    'phagocytâtes',
    'phagocyterai',
    'phagocyteras',
    'phagocyterez',
    'phagocytions',
    'phagocytoses',
    'phalangettes',
    'phalangienne',
    'phalangistes',
    'phalanstères',
    'phallocrates',
    'phallocratie',
    'phanérogames',
    'pharamineuse',
    'pharaonienne',
    'pharisaïques',
    'pharisaïsmes',
    'pharisiennes',
    'pharmacienne',
    'pharmacopées',
    'pharyngienne',
    'phellodermes',
    'phénanthrène',
    'phéniciennes',
    'phénoménales',
    'phénotypique',
    'philanthrope',
    'philatélique',
    'philatéliste',
    'philharmonie',
    'philippiques',
    'philodendron',
    'philologique',
    'philosophais',
    'philosophait',
    'philosophale',
    'philosophant',
    'philosophent',
    'philosophera',
    'philosophies',
    'philosophiez',
    'philosophons',
    'phlébologies',
    'phlébotomies',
    'phlegmoneuse',
    'phlogistique',
    'phonématique',
    'phonéticiens',
    'phonogénique',
    'phonographes',
    'phonolitique',
    'phonologique',
    'phonométries',
    'phonothèques',
    'phosphatages',
    'phosphatâmes',
    'phosphatasse',
    'phosphatâtes',
    'phosphaterai',
    'phosphateras',
    'phosphaterez',
    'phosphations',
    'phosphaturie',
    'phosphorâmes',
    'phosphorasse',
    'phosphorâtes',
    'phosphorerai',
    'phosphoreras',
    'phosphorerez',
    'phosphorions',
    'phosphorique',
    'photocalques',
    'photocellule',
    'photochimies',
    'photocopiais',
    'photocopiait',
    'photocopiant',
    'photocopiées',
    'photocopient',
    'photocopiera',
    'photocopieur',
    'photocopiiez',
    'photocopions',
    'photo-finish',
    'photogénique',
    'photographes',
    'photographia',
    'photographie',
    'photographié',
    'photograveur',
    'photogravure',
    'photométries',
    'photomontage',
    'photophobies',
    'photosphères',
    'photothèques',
    'phrasassions',
    'phraséologie',
    'phraseraient',
    'phrénologies',
    'phrénologues',
    'phtisiologie',
    'phtisiologue',
    'phylloxérées',
    'phylloxérien',
    'phylogenèses',
    'phylogénique',
    'physicalisme',
    'physiciennes',
    'physiocrates',
    'physiocratie',
    'physiologies',
    'physionomies',
    'physiquement',
    'physostigmas',
    'piaffassions',
    'piafferaient',
    'piaillassent',
    'piaillassiez',
    'piaillements',
    'piaillerions',
    'pianistiques',
    'pianotassent',
    'pianotassiez',
    'pianoterions',
    'piaulassions',
    'piauleraient',
    'picolassions',
    'picoleraient',
    'picorassions',
    'picoreraient',
    'picotassions',
    'picoteraient',
    'pictogrammes',
    'pictographie',
    'pied-à-terre',
    'pied-de-loup',
    'pied-de-veau',
    'pieds-droits',
    'piégeassions',
    'piémontaises',
    'pierrassions',
    'pierreraient',
    'piétinassent',
    'piétinassiez',
    'piétinements',
    'piétinerions',
    'piétonnières',
    'pieutassions',
    'pieuteraient',
    'piézographes',
    'pigeonnaient',
    'pigeonnantes',
    'pigeonnasses',
    'pigeonnerais',
    'pigeonnerait',
    'pigeonnèrent',
    'pigeonneriez',
    'pigeonnerons',
    'pigeonneront',
    'pigmentaient',
    'pigmentaires',
    'pigmentasses',
    'pigmentation',
    'pigmenterais',
    'pigmenterait',
    'pigmentèrent',
    'pigmenteriez',
    'pigmenterons',
    'pigmenteront',
    'pignochaient',
    'pignochasses',
    'pignocherais',
    'pignocherait',
    'pignochèrent',
    'pignocheriez',
    'pignocherons',
    'pignocheront',
    'pignoratives',
    'pilocarpines',
    'pilonnassent',
    'pilonnassiez',
    'pilonnerions',
    'pilo-sébacée',
    'pilo-sébacés',
    'pilotassions',
    'piloteraient',
    'pimentassent',
    'pimentassiez',
    'pimenterions',
    'pimprenelles',
    'pinacothèque',
    'pinaillaient',
    'pinaillasses',
    'pinaillerais',
    'pinaillerait',
    'pinaillèrent',
    'pinailleriez',
    'pinaillerons',
    'pinailleront',
    'pinailleuses',
    'pince-fesses',
    'pince-maille',
    'pindarisâmes',
    'pindarisasse',
    'pindarisâtes',
    'pindariserai',
    'pindariseras',
    'pindariserez',
    'pindarisions',
    'piochassions',
    'piocheraient',
    'pionçassions',
    'pionceraient',
    'pionnassions',
    'pionneraient',
    'pipistrelles',
    'pique-boeufs',
    'pique-fleurs',
    'pique-niquai',
    'pique-niquas',
    'pique-niquât',
    'pique-niquer',
    'pique-niques',
    'pique-niquez',
    'piquetassent',
    'piquetassiez',
    'piquetterais',
    'piquetterait',
    'piquetteriez',
    'piquetterons',
    'piquetteront',
    'piratassions',
    'pirateraient',
    'pirouettâmes',
    'pirouettasse',
    'pirouettâtes',
    'pirouetterai',
    'pirouetteras',
    'pirouetterez',
    'pirouettions',
    'pisciculteur',
    'pisciculture',
    'pisolithique',
    'pissaladière',
    'pistachaient',
    'pistachasses',
    'pistacherais',
    'pistacherait',
    'pistachèrent',
    'pistacheriez',
    'pistacherons',
    'pistacheront',
    'pistonnaient',
    'pistonnasses',
    'pistonnerais',
    'pistonnerait',
    'pistonnèrent',
    'pistonneriez',
    'pistonnerons',
    'pistonneront',
    'pithiatiques',
    'pithiatismes',
    'pitonnassent',
    'pitonnassiez',
    'pitonnerions',
    'pittoresques',
    'pittosporums',
    'pivotassions',
    'pivoteraient',
    'placardaient',
    'placardasses',
    'placarderais',
    'placarderait',
    'placardèrent',
    'placarderiez',
    'placarderons',
    'placarderont',
    'placentaires',
    'placentation',
    'plafonnaient',
    'plafonnasses',
    'plafonnement',
    'plafonnerais',
    'plafonnerait',
    'plafonnèrent',
    'plafonneriez',
    'plafonnerons',
    'plafonneront',
    'plagiassions',
    'plagieraient',
    'plaidassions',
    'plaideraient',
    'plaignissent',
    'plaignissiez',
    'plainassions',
    'plaindraient',
    'plaineraient',
    'plaisanciers',
    'plaisantâmes',
    'plaisantasse',
    'plaisantâtes',
    'plaisanterai',
    'plaisanteras',
    'plaisanterez',
    'plaisanterie',
    'plaisantions',
    'planchassent',
    'planchassiez',
    'planchéiages',
    'planchéiâmes',
    'planchéiasse',
    'planchéiâtes',
    'planchéierai',
    'planchéieras',
    'planchéierez',
    'plancherions',
    'plan-concave',
    'plan-convexe',
    'planctonique',
    'planétariums',
    'planifiaient',
    'planifiasses',
    'planifierais',
    'planifierait',
    'planifièrent',
    'planifieriez',
    'planifierons',
    'planifieront',
    'planimétries',
    'planisphères',
    'planquassent',
    'planquassiez',
    'planquerions',
    'plantassions',
    'planteraient',
    'plantigrades',
    'plantureuses',
    'plaquassions',
    'plaqueminier',
    'plaqueraient',
    'plasmatiques',
    'plasmifiâmes',
    'plasmifiasse',
    'plasmifiâtes',
    'plasmifierai',
    'plasmifieras',
    'plasmifierez',
    'plasmifiions',
    'plasticienne',
    'plastifiâmes',
    'plastifiante',
    'plastifiants',
    'plastifiasse',
    'plastifiâtes',
    'plastifierai',
    'plastifieras',
    'plastifierez',
    'plastifiions',
    'plastiquages',
    'plastiquâmes',
    'plastiquasse',
    'plastiquâtes',
    'plastiquerai',
    'plastiqueras',
    'plastiquerez',
    'plastiqueurs',
    'plastiquions',
    'plastronnais',
    'plastronnait',
    'plastronnant',
    'plastronnées',
    'plastronnent',
    'plastronnera',
    'plastronniez',
    'plastronnons',
    'plateresques',
    'platinassent',
    'platinassiez',
    'platinerions',
    'platinisâmes',
    'platinisasse',
    'platinisâtes',
    'platiniserai',
    'platiniseras',
    'platiniserez',
    'platinisions',
    'platoniciens',
    'plâtrassions',
    'plâtreraient',
    'plausibilité',
    'plébiscitais',
    'plébiscitait',
    'plébiscitant',
    'plébiscitées',
    'plébiscitent',
    'plébiscitera',
    'plébiscitiez',
    'plébiscitons',
    'plein-emploi',
    'pleins-temps',
    'pleins-vents',
    'pléistocènes',
    'pléonastique',
    'plésiosaures',
    'pléthoriques',
    'pleurassions',
    'pleureraient',
    'pleurétiques',
    'pleurnichais',
    'pleurnichait',
    'pleurnichant',
    'pleurnichent',
    'pleurnichera',
    'pleurnicheur',
    'pleurnichiez',
    'pleurnichons',
    'pleurodynies',
    'pleurotomies',
    'pleuvinerait',
    'pleuvoterait',
    'plissassions',
    'plisseraient',
    'plombassions',
    'plomberaient',
    'plongeassent',
    'plongeassiez',
    'plongeraient',
    'ploquassions',
    'ploqueraient',
    'ploutocrates',
    'ploutocratie',
    'pluchassions',
    'plucheraient',
    'plumasseries',
    'plumassières',
    'pluriannuels',
    'plurilatéral',
    'plurivalente',
    'plurivalents',
    'plutoniennes',
    'plutonigènes',
    'pluviomètres',
    'pluviométrie',
    'pneumatiques',
    'pneumectomie',
    'pneumocoques',
    'pneumologies',
    'pneumologues',
    'pneumoniques',
    'pneumothorax',
    'poétiquement',
    'poétisassent',
    'poétisassiez',
    'poétiserions',
    'poignardâmes',
    'poignardasse',
    'poignardâtes',
    'poignarderai',
    'poignarderas',
    'poignarderez',
    'poignardions',
    'poignissions',
    'poinçonnages',
    'poinçonnâmes',
    'poinçonnasse',
    'poinçonnâtes',
    'poinçonnerai',
    'poinçonneras',
    'poinçonnerez',
    'poinçonneurs',
    'poinçonneuse',
    'poinçonnions',
    'pointassions',
    'pointeraient',
    'pointillages',
    'pointillâmes',
    'pointillasse',
    'pointillâtes',
    'pointillerai',
    'pointilleras',
    'pointillerez',
    'pointilleuse',
    'pointillions',
    'pointillisme',
    'pointilliste',
    'poireautâmes',
    'poireautasse',
    'poireautâtes',
    'poireauterai',
    'poireauteras',
    'poireauterez',
    'poireautions',
    'poirotassent',
    'poirotassiez',
    'poiroterions',
    'poissassions',
    'poisseraient',
    'poissonnerie',
    'poissonneuse',
    'poissonnière',
    'poissonniers',
    'poitrinaires',
    'poitrinières',
    'poivrassions',
    'poivreraient',
    'polarimètres',
    'polarisables',
    'polarisaient',
    'polarisasses',
    'polarisation',
    'polariscopes',
    'polariserais',
    'polariserait',
    'polarisèrent',
    'polariseriez',
    'polariserons',
    'polariseront',
    'polémiquâmes',
    'polémiquasse',
    'polémiquâtes',
    'polémiquerai',
    'polémiqueras',
    'polémiquerez',
    'polémiquions',
    'polémologies',
    'poliçassions',
    'policeraient',
    'polichinelle',
    'policlinique',
    'poliomyélite',
    'polissonnais',
    'polissonnait',
    'polissonnant',
    'polissonnent',
    'polissonnera',
    'polissonniez',
    'polissonnons',
    'politicardes',
    'politicienne',
    'politiquâmes',
    'politiquasse',
    'politiquâtes',
    'politiquerai',
    'politiqueras',
    'politiquerez',
    'politiquions',
    'politisaient',
    'politisasses',
    'politisation',
    'politiserais',
    'politiserait',
    'politisèrent',
    'politiseriez',
    'politiserons',
    'politiseront',
    'polluassions',
    'pollueraient',
    'poltronnerie',
    'polyarthrite',
    'polybasiques',
    'polycéphales',
    'polychroïsme',
    'polychromies',
    'polyclinique',
    'polycopiâmes',
    'polycopiasse',
    'polycopiâtes',
    'polycopierai',
    'polycopieras',
    'polycopierez',
    'polycopiions',
    'polycultures',
    'polycyclique',
    'polydactyles',
    'polydactylie',
    'polyédriques',
    'polyéthylène',
    'polygénismes',
    'polygénistes',
    'polyglobulie',
    'polygonation',
    'polymérisais',
    'polymérisait',
    'polymérisant',
    'polymérisées',
    'polymérisent',
    'polymérisera',
    'polymérisiez',
    'polymérisons',
    'polymorphies',
    'polynésienne',
    'polynévrites',
    'polynomiales',
    'polypeptides',
    'polyphonique',
    'polyploïdies',
    'polysémiques',
    'polystyrènes',
    'polysulfures',
    'polysyllabes',
    'polythéismes',
    'polythéistes',
    'polyvalentes',
    'pommadassent',
    'pommadassiez',
    'pommaderions',
    'pommelassent',
    'pommelassiez',
    'pommellerais',
    'pommellerait',
    'pommelleriez',
    'pommellerons',
    'pommelleront',
    'pommerassent',
    'pommerassiez',
    'pomologiques',
    'pomologistes',
    'pompeusement',
    'pompiérismes',
    'pomponnaient',
    'pomponnasses',
    'pomponnerais',
    'pomponnerait',
    'pomponnèrent',
    'pomponneriez',
    'pomponnerons',
    'pomponneront',
    'ponctionnais',
    'ponctionnait',
    'ponctionnant',
    'ponctionnées',
    'ponctionnent',
    'ponctionnera',
    'ponctionniez',
    'ponctionnons',
    'ponctualités',
    'ponctuassent',
    'ponctuassiez',
    'ponctuations',
    'ponctuerions',
    'pondérassent',
    'pondérassiez',
    'pondérateurs',
    'pondérations',
    'pondératrice',
    'pondérerions',
    'pontifiaient',
    'pontifiantes',
    'pontifiasses',
    'pontificales',
    'pontifierais',
    'pontifierait',
    'pontifièrent',
    'pontifieriez',
    'pontifierons',
    'pontifieront',
    'pontillaient',
    'pontillasses',
    'pontillerais',
    'pontillerait',
    'pontillèrent',
    'pontilleriez',
    'pontillerons',
    'pontilleront',
    'populacières',
    'popularisais',
    'popularisait',
    'popularisant',
    'popularisées',
    'popularisent',
    'popularisera',
    'popularisiez',
    'popularisons',
    'porcelainier',
    'pornographes',
    'pornographie',
    'porphyriques',
    'porphyrisais',
    'porphyrisait',
    'porphyrisant',
    'porphyrisées',
    'porphyrisent',
    'porphyrisera',
    'porphyrisiez',
    'porphyrisons',
    'porphyroïdes',
    'porte-à-faux',
    'porte-amarre',
    'porte-avions',
    'porte-balais',
    'porte-cartes',
    'porte-copies',
    'porte-crayon',
    'porte-crosse',
    'porte-fanion',
    'portefeuille',
    'porte-glaive',
    'porte-greffe',
    'porte-hauban',
    'portemanteau',
    'porte-montre',
    'porte-objets',
    'porte-outils',
    'porte-parole',
    'porte-savons',
    'portionnaire',
    'portlandiens',
    'portraitiste',
    'portraiturai',
    'portraituras',
    'portraiturât',
    'portraiturée',
    'portraiturer',
    'portraitures',
    'portraiturés',
    'portraiturez',
    'positionnais',
    'positionnait',
    'positionnant',
    'positionnées',
    'positionnent',
    'positionnera',
    'positionniez',
    'positionnons',
    'positivement',
    'positivismes',
    'positivistes',
    'possédassent',
    'possédassiez',
    'posséderions',
    'possessivité',
    'possessoires',
    'possibilités',
    'possiblement',
    'postdataient',
    'postdatasses',
    'postdaterais',
    'postdaterait',
    'postdatèrent',
    'postdateriez',
    'postdaterons',
    'postdateront',
    'postérieures',
    'postériorité',
    'postichaient',
    'postichasses',
    'posticherais',
    'posticherait',
    'postichèrent',
    'posticheriez',
    'posticherons',
    'posticheront',
    'postillonnai',
    'postillonnas',
    'postillonnât',
    'postillonner',
    'postillonnes',
    'postillonnez',
    'postposaient',
    'postposasses',
    'postposerais',
    'postposerait',
    'postposèrent',
    'postposeriez',
    'postposerons',
    'postposeront',
    'postposition',
    'postscolaire',
    'postulassent',
    'postulassiez',
    'postulations',
    'postulerions',
    'potamochères',
    'potamologies',
    'potassassent',
    'potassassiez',
    'potasserions',
    'potentialisa',
    'potentialise',
    'potentialisé',
    'potentialité',
    'potentielles',
    'potestatives',
    'potinassions',
    'potineraient',
    'pots-pourris',
    'pouces-pieds',
    'poudrassions',
    'poudreraient',
    'poudroiement',
    'poudroierais',
    'poudroierait',
    'poudroieriez',
    'poudroierons',
    'poudroieront',
    'poudroyaient',
    'poudroyasses',
    'poudroyèrent',
    'pouffassions',
    'poufferaient',
    'poulinassent',
    'poulinassiez',
    'poulinerions',
    'pouponnaient',
    'pouponnasses',
    'pouponnerais',
    'pouponnerait',
    'pouponnèrent',
    'pouponneriez',
    'pouponnerons',
    'pouponneront',
    'pouponnières',
    'pourcentages',
    'pourchassais',
    'pourchassait',
    'pourchassant',
    'pourchassées',
    'pourchassent',
    'pourchassera',
    'pourchassiez',
    'pourchassons',
    'pourfendeurs',
    'pourfendîmes',
    'pourfendions',
    'pourfendisse',
    'pourfendîtes',
    'pourfendrais',
    'pourfendrait',
    'pourfendriez',
    'pourfendrons',
    'pourfendront',
    'pourléchâmes',
    'pourléchasse',
    'pourléchâtes',
    'pourlécherai',
    'pourlécheras',
    'pourlécherez',
    'pourléchions',
    'pourprassent',
    'pourprassiez',
    'pourprerions',
    'pourriraient',
    'pourrissages',
    'pourrissante',
    'pourrissants',
    'pourrissions',
    'pourrissoirs',
    'poursuivante',
    'poursuivants',
    'poursuiveurs',
    'poursuivîmes',
    'poursuivions',
    'poursuivisse',
    'poursuivîtes',
    'poursuivrais',
    'poursuivrait',
    'poursuivriez',
    'poursuivrons',
    'poursuivront',
    'pourvoirions',
    'pourvoyaient',
    'pourvussions',
    'poussassions',
    'pousseraient',
    'poussiéreuse',
    'poussinières',
    'poussivement',
    'pout-de-soie',
    'poutsassions',
    'poutseraient',
    'poux-de-soie',
    'pragmatiques',
    'pragmatismes',
    'pragmatistes',
    'pralinassent',
    'pralinassiez',
    'pralinerions',
    'praticiennes',
    'pratiquaient',
    'pratiquantes',
    'pratiquasses',
    'pratiquement',
    'pratiquerais',
    'pratiquerait',
    'pratiquèrent',
    'pratiqueriez',
    'pratiquerons',
    'pratiqueront',
    'préachetâmes',
    'préachetasse',
    'préachetâtes',
    'préachèterai',
    'préachèteras',
    'préachèterez',
    'préachetions',
    'préadamismes',
    'préavisaient',
    'préavisasses',
    'préaviserais',
    'préaviserait',
    'préavisèrent',
    'préaviseriez',
    'préaviserons',
    'préaviseront',
    'précairement',
    'précambriens',
    'précarisâmes',
    'précarisasse',
    'précarisâtes',
    'précariserai',
    'précariseras',
    'précariserez',
    'précarisions',
    'précautionna',
    'précautionne',
    'précautionné',
    'précédassent',
    'précédassiez',
    'précédemment',
    'précéderions',
    'précellences',
    'préceptorats',
    'préceptrices',
    'prêchassions',
    'préchauffage',
    'préchauffais',
    'préchauffait',
    'préchauffant',
    'préchauffées',
    'préchauffent',
    'préchauffera',
    'préchauffiez',
    'préchauffons',
    'prêcheraient',
    'précipitâmes',
    'précipitasse',
    'précipitâtes',
    'précipiterai',
    'précipiteras',
    'précipiterez',
    'précipitions',
    'préciputaire',
    'précisassent',
    'précisassiez',
    'préciserions',
    'précolombien',
    'précomptâmes',
    'précomptasse',
    'précomptâtes',
    'précompterai',
    'précompteras',
    'précompterez',
    'précomptions',
    'préconisâmes',
    'préconisasse',
    'préconisâtes',
    'préconiserai',
    'préconiseras',
    'préconiserez',
    'préconiseurs',
    'préconisions',
    'précontraint',
    'précordiales',
    'prédécesseur',
    'prédécoupées',
    'prédéfinîmes',
    'prédéfinirai',
    'prédéfiniras',
    'prédéfinirez',
    'prédéfinisse',
    'prédéfinîtes',
    'prédestinais',
    'prédestinait',
    'prédestinant',
    'prédestinées',
    'prédestinent',
    'prédestinera',
    'prédestiniez',
    'prédestinons',
    'prédétermina',
    'prédétermine',
    'prédéterminé',
    'prédicateurs',
    'prédications',
    'prédicatives',
    'prédicatrice',
    'prédilection',
    'prédiquaient',
    'prédiquasses',
    'prédiquerais',
    'prédiquerait',
    'prédiquèrent',
    'prédiqueriez',
    'prédiquerons',
    'prédiqueront',
    'prédisposais',
    'prédisposait',
    'prédisposant',
    'prédisposées',
    'prédisposent',
    'prédisposera',
    'prédisposiez',
    'prédisposons',
    'prédominâmes',
    'prédominance',
    'prédominante',
    'prédominants',
    'prédominasse',
    'prédominâtes',
    'prédominerai',
    'prédomineras',
    'prédominerez',
    'prédominions',
    'préélectoral',
    'préemballées',
    'prééminences',
    'prééminentes',
    'préemptaient',
    'préemptasses',
    'préempterais',
    'préempterait',
    'préemptèrent',
    'préempteriez',
    'préempterons',
    'préempteront',
    'préencollées',
    'préétablîmes',
    'préétablirai',
    'préétabliras',
    'préétablirez',
    'préétablisse',
    'préétablîtes',
    'préexistâmes',
    'préexistante',
    'préexistants',
    'préexistasse',
    'préexistâtes',
    'préexistence',
    'préexisterai',
    'préexisteras',
    'préexisterez',
    'préexistions',
    'préfabriquée',
    'préfabriqués',
    'préfaçassent',
    'préfaçassiez',
    'préfacerions',
    'préfectorale',
    'préfectoraux',
    'préférassent',
    'préférassiez',
    'préférentiel',
    'préférerions',
    'préfigurâmes',
    'préfigurasse',
    'préfigurâtes',
    'préfigurerai',
    'préfigureras',
    'préfigurerez',
    'préfigurions',
    'préfixassent',
    'préfixassiez',
    'préfixations',
    'préfixerions',
    'préfloraison',
    'préfoliation',
    'préformaient',
    'préformasses',
    'préformation',
    'préformerais',
    'préformerait',
    'préformèrent',
    'préformeriez',
    'préformerons',
    'préformeront',
    'préglaciaire',
    'préhistoires',
    'préhistorien',
    'préjudiciais',
    'préjudiciait',
    'préjudiciant',
    'préjudiciels',
    'préjudicient',
    'préjudiciera',
    'préjudiciiez',
    'préjudicions',
    'préjugeaient',
    'préjugeasses',
    'préjugerions',
    'prélassaient',
    'prélassasses',
    'prélasserais',
    'prélasserait',
    'prélassèrent',
    'prélasseriez',
    'prélasserons',
    'prélasseront',
    'prélevassent',
    'prélevassiez',
    'prélèvements',
    'prélèverions',
    'préliminaire',
    'préludassent',
    'préludassiez',
    'préluderions',
    'prématurités',
    'préméditâmes',
    'préméditasse',
    'préméditâtes',
    'préméditerai',
    'préméditeras',
    'préméditerez',
    'préméditions',
    'prémenstruel',
    'premièrement',
    'première-née',
    'premiers-nés',
    'prémilitaire',
    'prémonitions',
    'prémonitoire',
    'prémunirions',
    'prémunissais',
    'prémunissait',
    'prémunissant',
    'prémunissent',
    'prémunissiez',
    'prémunissons',
    'prémunitions',
    'prénommaient',
    'prénommasses',
    'prénommerais',
    'prénommerait',
    'prénommèrent',
    'prénommeriez',
    'prénommerons',
    'prénommeront',
    'prénuptiales',
    'préoccupâmes',
    'préoccupante',
    'préoccupants',
    'préoccupasse',
    'préoccupâtes',
    'préoccuperai',
    'préoccuperas',
    'préoccuperez',
    'préoccupions',
    'prépaierions',
    'préparassent',
    'préparassiez',
    'préparateurs',
    'préparations',
    'préparatoire',
    'préparatrice',
    'préparerions',
    'prépayassent',
    'prépayassiez',
    'prépayerions',
    'prépondérant',
    'préposassent',
    'préposassiez',
    'préposerions',
    'prépositions',
    'prépositives',
    'préprogrammé',
    'préréglaient',
    'préréglasses',
    'préréglerais',
    'préréglerait',
    'préréglèrent',
    'prérégleriez',
    'préréglerons',
    'prérégleront',
    'préretraites',
    'prérogatives',
    'présageaient',
    'présageasses',
    'présagerions',
    'présanctifié',
    'presbytérale',
    'presbytéraux',
    'presbytérien',
    'préscolaires',
    'prescripteur',
    'prescription',
    'prescririons',
    'prescrivîmes',
    'prescrivions',
    'prescrivisse',
    'prescrivîtes',
    'présélecteur',
    'présélection',
    'présentables',
    'présentaient',
    'présentasses',
    'présentateur',
    'présentation',
    'présentement',
    'présenterais',
    'présenterait',
    'présentèrent',
    'présenteriez',
    'présenterons',
    'présenteront',
    'préservaient',
    'préservasses',
    'préservateur',
    'préservatifs',
    'préservation',
    'préserverais',
    'préserverait',
    'préservèrent',
    'préserveriez',
    'préserverons',
    'préserveront',
    'présidassent',
    'présidassiez',
    'présidentiel',
    'présiderions',
    'présomptions',
    'présomptives',
    'présomptueux',
    'pressassions',
    'pressentîmes',
    'pressentions',
    'pressentirai',
    'pressentiras',
    'pressentirez',
    'pressentisse',
    'pressentîtes',
    'presse-purée',
    'presseraient',
    'pressuraient',
    'pressurasses',
    'pressurerais',
    'pressurerait',
    'pressurèrent',
    'pressureriez',
    'pressurerons',
    'pressureront',
    'pressureuses',
    'pressurisais',
    'pressurisait',
    'pressurisant',
    'pressurisées',
    'pressurisent',
    'pressurisera',
    'pressurisiez',
    'pressurisons',
    'prestataires',
    'prestigieuse',
    'présumassent',
    'présumassiez',
    'présumerions',
    'présupposais',
    'présupposait',
    'présupposant',
    'présupposées',
    'présupposent',
    'présupposera',
    'présupposiez',
    'présupposons',
    'présurassent',
    'présurassiez',
    'présurerions',
    'prétantaines',
    'prétendaient',
    'prétendantes',
    'prétendirent',
    'prétendisses',
    'prétendrions',
    'prétendument',
    'prétentaines',
    'prétentiarde',
    'prétentiards',
    'prétentieuse',
    'prétéritions',
    'prétextaient',
    'prétextasses',
    'prétexterais',
    'prétexterait',
    'prétextèrent',
    'prétexteriez',
    'prétexterons',
    'prétexteront',
    'prétoriennes',
    'prévalussent',
    'prévalussiez',
    'prévariquais',
    'prévariquait',
    'prévariquant',
    'prévariquent',
    'prévariquera',
    'prévariquiez',
    'prévariquons',
    'prévaudrions',
    'préventorium',
    'préviendrais',
    'préviendrait',
    'préviendriez',
    'préviendrons',
    'préviendront',
    'prévinssions',
    'prévisionnel',
    'prévoiraient',
    'primarisâmes',
    'primarisasse',
    'primarisâtes',
    'primariserai',
    'primariseras',
    'primariserez',
    'primarisions',
    'primesautier',
    'primeuristes',
    'primipilaire',
    'primitivisme',
    'primordiales',
    'principalats',
    'principautés',
    'printanières',
    'prioritaires',
    'prismatiques',
    'prisonnières',
    'privatdocent',
    'privatdozent',
    'privatisâmes',
    'privatisasse',
    'privatisâtes',
    'privatiserai',
    'privatiseras',
    'privatiserez',
    'privatisions',
    'privilégiais',
    'privilégiait',
    'privilégiant',
    'privilégiées',
    'privilégient',
    'privilégiera',
    'privilégiiez',
    'privilégions',
    'probabilisme',
    'probabiliste',
    'probabilités',
    'probablement',
    'procédassent',
    'procédassiez',
    'procéderions',
    'procédurière',
    'procéduriers',
    'processionna',
    'processionne',
    'prochinoises',
    'proclamaient',
    'proclamasses',
    'proclamation',
    'proclamerais',
    'proclamerait',
    'proclamèrent',
    'proclameriez',
    'proclamerons',
    'proclameront',
    'proclitiques',
    'proconsulats',
    'procréassent',
    'procréassiez',
    'procréateurs',
    'procréations',
    'procréatrice',
    'procréerions',
    'proctologies',
    'proctologues',
    'proctorrhées',
    'procurassent',
    'procurassiez',
    'procurateurs',
    'procurations',
    'procurerâmes',
    'procurerasse',
    'procurerâtes',
    'prodigalités',
    'prodigieuses',
    'prodiguaient',
    'prodiguasses',
    'prodiguerais',
    'prodiguerait',
    'prodiguèrent',
    'prodigueriez',
    'prodiguerons',
    'prodigueront',
    'prodromiques',
    'productibles',
    'productivité',
    'productrices',
    'produiraient',
    'produisaient',
    'produisirent',
    'produisisses',
    'proéminences',
    'proéminentes',
    'profanassent',
    'profanassiez',
    'profanateurs',
    'profanations',
    'profanatrice',
    'profanerions',
    'proférassent',
    'proférassiez',
    'proférerions',
    'professaient',
    'professasses',
    'professerais',
    'professerait',
    'professèrent',
    'professeriez',
    'professerons',
    'professeront',
    'professorale',
    'professorats',
    'professoraux',
    'profilassent',
    'profilassiez',
    'profilerions',
    'profitassent',
    'profitassiez',
    'profiterions',
    'profiteroles',
    'profondément',
    'progénitures',
    'progestatifs',
    'progestative',
    'progestérone',
    'prognathisme',
    'programmable',
    'programmâmes',
    'programmasse',
    'programmâtes',
    'programmerai',
    'programmeras',
    'programmerez',
    'programmeurs',
    'programmeuse',
    'programmions',
    'progressâmes',
    'progressasse',
    'progressâtes',
    'progresserai',
    'progresseras',
    'progresserez',
    'progressions',
    'progressisme',
    'progressiste',
    'progressives',
    'prohibassent',
    'prohibassiez',
    'prohiberions',
    'prohibitions',
    'prohibitives',
    'projetassent',
    'projetassiez',
    'projetterais',
    'projetterait',
    'projetteriez',
    'projetterons',
    'projetteront',
    'prolégomènes',
    'prolétariats',
    'prolétariens',
    'prolétarisai',
    'prolétarisas',
    'prolétarisât',
    'prolétarisée',
    'prolétariser',
    'prolétarises',
    'prolétarisés',
    'prolétarisez',
    'proliférâmes',
    'proliférasse',
    'proliférâtes',
    'proliférerai',
    'proliféreras',
    'proliférerez',
    'proliférions',
    'prolificités',
    'prolongateur',
    'prolongation',
    'prolongeâmes',
    'prolongeasse',
    'prolongeâtes',
    'prolongement',
    'prolongerais',
    'prolongerait',
    'prolongèrent',
    'prolongeriez',
    'prolongerons',
    'prolongeront',
    'promenassent',
    'promenassiez',
    'promènerions',
    'prométhéenne',
    'promettaient',
    'prometteuses',
    'promettrions',
    'promiscuités',
    'promontoires',
    'promotionnel',
    'promouvaient',
    'promouvrions',
    'promptitudes',
    'promulgation',
    'promulguâmes',
    'promulguasse',
    'promulguâtes',
    'promulguerai',
    'promulgueras',
    'promulguerez',
    'promulguions',
    'pronominales',
    'prononçables',
    'prononçaient',
    'prononçasses',
    'prononcerais',
    'prononcerait',
    'prononcèrent',
    'prononceriez',
    'prononcerons',
    'prononceront',
    'pronostiquai',
    'pronostiquas',
    'pronostiquât',
    'pronostiquée',
    'pronostiquer',
    'pronostiques',
    'pronostiqués',
    'pronostiquez',
    'propagateurs',
    'propagations',
    'propagatrice',
    'propageaient',
    'propageasses',
    'propagerions',
    'proparoxyton',
    'prophétesses',
    'prophétiques',
    'prophétisais',
    'prophétisait',
    'prophétisant',
    'prophétisées',
    'prophétisent',
    'prophétisera',
    'prophétisiez',
    'prophétisons',
    'prophylaxies',
    'propitiation',
    'proportionna',
    'proportionne',
    'proportionné',
    'proposassent',
    'proposassiez',
    'proposerions',
    'propositions',
    'propriétaire',
    'propulsaient',
    'propulsasses',
    'propulserais',
    'propulserait',
    'propulsèrent',
    'propulseriez',
    'propulserons',
    'propulseront',
    'prorogations',
    'prorogatives',
    'prorogeaient',
    'prorogeasses',
    'prorogerions',
    'proscripteur',
    'proscription',
    'proscririons',
    'proscrivîmes',
    'proscrivions',
    'proscrivisse',
    'proscrivîtes',
    'prosectorats',
    'prosélytisme',
    'prosodiaient',
    'prosodiasses',
    'prosodierais',
    'prosodierait',
    'prosodièrent',
    'prosodieriez',
    'prosodierons',
    'prosodieront',
    'prospectâmes',
    'prospectasse',
    'prospectâtes',
    'prospecterai',
    'prospecteras',
    'prospecterez',
    'prospecteurs',
    'prospections',
    'prospectives',
    'prospectrice',
    'prospéraient',
    'prospérasses',
    'prospérerais',
    'prospérerait',
    'prospérèrent',
    'prospéreriez',
    'prospérerons',
    'prospéreront',
    'prostatiques',
    'prostatismes',
    'prosternâmes',
    'prosternasse',
    'prosternâtes',
    'prosternerai',
    'prosterneras',
    'prosternerez',
    'prosternions',
    'prosthétique',
    'prostituâmes',
    'prostituasse',
    'prostituâtes',
    'prostituerai',
    'prostitueras',
    'prostituerez',
    'prostituions',
    'prostitution',
    'prostrations',
    'protactinium',
    'protagoniste',
    'protectorats',
    'protectrices',
    'protégeaient',
    'protégeasses',
    'protégerions',
    'protéiformes',
    'protéinuries',
    'protérandrie',
    'protestaient',
    'protestantes',
    'protestasses',
    'protestation',
    'protesterais',
    'protesterait',
    'protestèrent',
    'protesteriez',
    'protesterons',
    'protesteront',
    'prothésistes',
    'prothétiques',
    'prothrombine',
    'protocolaire',
    'protoplasmes',
    'prototypages',
    'protozoaires',
    'protractiles',
    'protubérance',
    'protubérante',
    'protubérants',
    'proudhoniens',
    'prouvassions',
    'prouveraient',
    'provéditeurs',
    'proverbiales',
    'providentiel',
    'proviendrais',
    'proviendrait',
    'proviendriez',
    'proviendrons',
    'proviendront',
    'provignaient',
    'provignasses',
    'provignement',
    'provignerais',
    'provignerait',
    'provignèrent',
    'provigneriez',
    'provignerons',
    'provigneront',
    'provincialat',
    'provinciales',
    'provinssions',
    'provisionnel',
    'provitamines',
    'provocateurs',
    'provocations',
    'provocatrice',
    'provoquaient',
    'provoquasses',
    'provoquerais',
    'provoquerait',
    'provoquèrent',
    'provoqueriez',
    'provoquerons',
    'provoqueront',
    'proxénétisme',
    'prudhommerie',
    'prurigineuse',
    'psalmodiâmes',
    'psalmodiasse',
    'psalmodiâtes',
    'psalmodierai',
    'psalmodieras',
    'psalmodierez',
    'psalmodiions',
    'psittacismes',
    'psychanalysa',
    'psychanalyse',
    'psychanalysé',
    'psychiatries',
    'psychiatrisa',
    'psychiatrise',
    'psychiatrisé',
    'psychodrames',
    'psychologies',
    'psychologues',
    'psychométrie',
    'psychomoteur',
    'psychopathes',
    'psychopathie',
    'psychopompes',
    'psychorigide',
    'psychosocial',
    'psychotiques',
    'ptérodactyle',
    'ptolémaïques',
    'publiassions',
    'publications',
    'publicitaire',
    'publieraient',
    'publipostage',
    'publiquement',
    'pudibonderie',
    'puériculture',
    'pugilistique',
    'pullulassent',
    'pullulassiez',
    'pullulations',
    'pullulements',
    'pullulerions',
    'pulsionnelle',
    'pulvérisable',
    'pulvérisâmes',
    'pulvérisasse',
    'pulvérisâtes',
    'pulvériserai',
    'pulvériseras',
    'pulvériserez',
    'pulvériseurs',
    'pulvérisions',
    'pulvérulence',
    'pulvérulente',
    'pulvérulents',
    'punaisassent',
    'punaisassiez',
    'punaiserions',
    'pupillarités',
    'pupinisation',
    'purgeassions',
    'purifiassent',
    'purifiassiez',
    'purificateur',
    'purification',
    'purifierions',
    'puritanismes',
    'pusillanimes',
    'putassassier',
    'putréfaction',
    'putréfiables',
    'putréfiaient',
    'putréfiasses',
    'putréfierais',
    'putréfierait',
    'putréfièrent',
    'putréfieriez',
    'putréfierons',
    'putréfieront',
    'putrescences',
    'putrescentes',
    'putrescibles',
    'pyramidaient',
    'pyramidasses',
    'pyramiderais',
    'pyramiderait',
    'pyramidèrent',
    'pyramideriez',
    'pyramiderons',
    'pyramideront',
    'pyrogénation',
    'pyrogravâmes',
    'pyrogravasse',
    'pyrogravâtes',
    'pyrograverai',
    'pyrograveras',
    'pyrograverez',
    'pyrograveurs',
    'pyrograveuse',
    'pyrogravions',
    'pyrogravures',
    'pyrométrique',
    'pyrotechnies',
    'pyrrhonienne',
    'pyrrhonismes',
    'pythagorique',
    'pythagorisme',
    'quadragésime',
    'quadratiques',
    'quadriennale',
    'quadriennaux',
    'quadrilatère',
    'quadrillages',
    'quadrillâmes',
    'quadrillasse',
    'quadrillâtes',
    'quadrillerai',
    'quadrilleras',
    'quadrillerez',
    'quadrillions',
    'quadrimoteur',
    'quadripartie',
    'quadripartis',
    'quadriphonie',
    'quadruplâmes',
    'quadruplasse',
    'quadruplâtes',
    'quadruplerai',
    'quadrupleras',
    'quadruplerez',
    'quadruplions',
    'qualifiables',
    'qualifiaient',
    'qualifiantes',
    'qualifiasses',
    'qualificatif',
    'qualifierais',
    'qualifierait',
    'qualifièrent',
    'qualifieriez',
    'qualifierons',
    'qualifieront',
    'qualitatives',
    'quantifiable',
    'quantifiâmes',
    'quantifiasse',
    'quantifiâtes',
    'quantifierai',
    'quantifieras',
    'quantifierez',
    'quantifiions',
    'quantitatifs',
    'quantitative',
    'quarantaines',
    'quarante-six',
    'quarantièmes',
    'quarderonnai',
    'quarderonnas',
    'quarderonnât',
    'quarderonnée',
    'quarderonner',
    'quarderonnes',
    'quarderonnés',
    'quarderonnez',
    'quarrassions',
    'quarreraient',
    'quartageâmes',
    'quartageasse',
    'quartageâtes',
    'quartagerais',
    'quartagerait',
    'quartagèrent',
    'quartageriez',
    'quartagerons',
    'quartageront',
    'quartassions',
    'quarteraient',
    'quartzifères',
    'quasi-délits',
    'quaternaires',
    'quatorzièmes',
    'quatre-temps',
    'quatre-vingt',
    'quatrillions',
    'quattrocento',
    'quelques-uns',
    'quémandaient',
    'quémandasses',
    'quémanderais',
    'quémanderait',
    'quémandèrent',
    'quémanderiez',
    'quémanderons',
    'quémanderont',
    'quémandeuses',
    'quercitrines',
    'querellaient',
    'querellasses',
    'querellerais',
    'querellerait',
    'querellèrent',
    'querelleriez',
    'querellerons',
    'querelleront',
    'querelleuses',
    'questionnais',
    'questionnait',
    'questionnant',
    'questionnées',
    'questionnent',
    'questionnera',
    'questionneur',
    'questionniez',
    'questionnons',
    'queue-de-pie',
    'queue-de-rat',
    'queutassions',
    'queuteraient',
    'quincaillier',
    'quinquennale',
    'quinquennats',
    'quinquennaux',
    'quintessence',
    'quintillions',
    'quintuplâmes',
    'quintuplasse',
    'quintuplâtes',
    'quintuplerai',
    'quintupleras',
    'quintuplerez',
    'quintuplions',
    'quittançâmes',
    'quittançasse',
    'quittançâtes',
    'quittancerai',
    'quittanceras',
    'quittancerez',
    'quittancions',
    'quittassions',
    'quitteraient',
    'quotes-parts',
    'quotidiennes',
    'quottassions',
    'quotteraient',
    'rabâchassent',
    'rabâchassiez',
    'rabâcherions',
    'rabaissaient',
    'rabaissasses',
    'rabaissement',
    'rabaisserais',
    'rabaisserait',
    'rabaissèrent',
    'rabaisseriez',
    'rabaisserons',
    'rabaisseront',
    'rabantassent',
    'rabantassiez',
    'rabanterions',
    'rabattements',
    'rabattissent',
    'rabattissiez',
    'rabattraient',
    'rabdomancies',
    'rabelaisiens',
    'rabibochages',
    'rabibochâmes',
    'rabibochasse',
    'rabibochâtes',
    'rabibocherai',
    'rabibocheras',
    'rabibocherez',
    'rabibochions',
    'rabiotassent',
    'rabiotassiez',
    'rabioterions',
    'rabonnirions',
    'rabonnissais',
    'rabonnissait',
    'rabonnissant',
    'rabonnissent',
    'rabonnissiez',
    'rabonnissons',
    'rabotassions',
    'raboteraient',
    'rabougrirais',
    'rabougrirait',
    'rabougrirent',
    'rabougririez',
    'rabougrirons',
    'rabougriront',
    'rabougrisses',
    'rabougrissez',
    'rabouillères',
    'rabouilleurs',
    'rabouilleuse',
    'raboutassent',
    'raboutassiez',
    'rabouterions',
    'rabrouassent',
    'rabrouassiez',
    'rabrouements',
    'rabrouerions',
    'raccommodage',
    'raccommodais',
    'raccommodait',
    'raccommodant',
    'raccommodées',
    'raccommodent',
    'raccommodera',
    'raccommodeur',
    'raccommodiez',
    'raccommodons',
    'raccompagnai',
    'raccompagnas',
    'raccompagnât',
    'raccompagnée',
    'raccompagner',
    'raccompagnes',
    'raccompagnés',
    'raccompagnez',
    'raccordaient',
    'raccordasses',
    'raccordement',
    'raccorderais',
    'raccorderait',
    'raccordèrent',
    'raccorderiez',
    'raccorderons',
    'raccorderont',
    'raccourcîmes',
    'raccourcirai',
    'raccourciras',
    'raccourcirez',
    'raccourcisse',
    'raccourcîtes',
    'raccoutrâmes',
    'raccoutrasse',
    'raccoutrâtes',
    'raccoutrerai',
    'raccoutreras',
    'raccoutrerez',
    'raccoutrions',
    'raccoutumais',
    'raccoutumait',
    'raccoutumant',
    'raccoutumées',
    'raccoutument',
    'raccoutumera',
    'raccoutumiez',
    'raccoutumons',
    'raccrochages',
    'raccrochâmes',
    'raccrochasse',
    'raccrochâtes',
    'raccrocherai',
    'raccrocheras',
    'raccrocherez',
    'raccrocheurs',
    'raccrocheuse',
    'raccrochions',
    'rachetassent',
    'rachetassiez',
    'rachèterions',
    'rachidiennes',
    'racinassions',
    'racineraient',
    'racketteuses',
    'racolassions',
    'racoleraient',
    'racontassent',
    'racontassiez',
    'raconterions',
    'racornirions',
    'racornissais',
    'racornissait',
    'racornissant',
    'racornissent',
    'racornissiez',
    'racornissons',
    'radicalement',
    'radicalisais',
    'radicalisait',
    'radicalisant',
    'radicalisées',
    'radicalisent',
    'radicalisera',
    'radicalisiez',
    'radicalismes',
    'radicalisons',
    'radiculaires',
    'radiesthésie',
    'radinassions',
    'radineraient',
    'radioactives',
    'radioamateur',
    'radiobalisai',
    'radiobalisas',
    'radiobalisât',
    'radiobalisée',
    'radiobaliser',
    'radiobalises',
    'radiobalisés',
    'radiobalisez',
    'radiocarbone',
    'radiocobalts',
    'radiodiffusa',
    'radiodiffuse',
    'radiodiffusé',
    'radiographia',
    'radiographie',
    'radiographié',
    'radioguidage',
    'radioguidais',
    'radioguidait',
    'radioguidant',
    'radioguidées',
    'radioguident',
    'radioguidera',
    'radioguidiez',
    'radioguidons',
    'radiolésions',
    'radiologique',
    'radiologiste',
    'radionécrose',
    'radiophonies',
    'radioscopais',
    'radioscopait',
    'radioscopant',
    'radioscopées',
    'radioscopent',
    'radioscopera',
    'radioscopies',
    'radioscopiez',
    'radioscopons',
    'radiosondage',
    'radotassions',
    'radoteraient',
    'radoubassent',
    'radoubassiez',
    'radouberions',
    'radoucirions',
    'radoucissais',
    'radoucissait',
    'radoucissant',
    'radoucissent',
    'radoucissiez',
    'radoucissons',
    'raffermirais',
    'raffermirait',
    'raffermirent',
    'raffermiriez',
    'raffermirons',
    'raffermiront',
    'raffermisses',
    'raffermissez',
    'raffinassent',
    'raffinassiez',
    'raffinements',
    'raffinerions',
    'raffolassent',
    'raffolassiez',
    'raffolerions',
    'raffûtassent',
    'raffûtassiez',
    'raffûterions',
    'rafistolages',
    'rafistolâmes',
    'rafistolasse',
    'rafistolâtes',
    'rafistolerai',
    'rafistoleras',
    'rafistolerez',
    'rafistolions',
    'rafraîchîmes',
    'rafraîchirai',
    'rafraîchiras',
    'rafraîchirez',
    'rafraîchisse',
    'rafraîchîtes',
    'ragaillardie',
    'ragaillardir',
    'ragaillardis',
    'ragaillardit',
    'ragaillardît',
    'ragotassions',
    'ragoteraient',
    'ragougnasses',
    'ragoûtassent',
    'ragoûtassiez',
    'ragoûterions',
    'ragrafassent',
    'ragrafassiez',
    'ragraferions',
    'ragréassions',
    'ragréeraient',
    'rahat-lokoum',
    'rai-de-coeur',
    'raidirassent',
    'raidirassiez',
    'raidissaient',
    'raidissement',
    'raillassions',
    'railleraient',
    'rainetassent',
    'rainetassiez',
    'rainetterais',
    'rainetterait',
    'rainetteriez',
    'rainetterons',
    'rainetteront',
    'rainurassent',
    'rainurassiez',
    'rainurerions',
    'raisonnables',
    'raisonnaient',
    'raisonnasses',
    'raisonnement',
    'raisonnerais',
    'raisonnerait',
    'raisonnèrent',
    'raisonneriez',
    'raisonnerons',
    'raisonneront',
    'raisonneuses',
    'rajeunirions',
    'rajeunissais',
    'rajeunissait',
    'rajeunissant',
    'rajeunissent',
    'rajeunissiez',
    'rajeunissons',
    'rajoutassent',
    'rajoutassiez',
    'rajouterions',
    'rajustassent',
    'rajustassiez',
    'rajustements',
    'rajusterions',
    'ralentirions',
    'ralentissais',
    'ralentissait',
    'ralentissant',
    'ralentissent',
    'ralentisseur',
    'ralentissiez',
    'ralentissons',
    'ralinguaient',
    'ralinguasses',
    'ralinguerais',
    'ralinguerait',
    'ralinguèrent',
    'ralingueriez',
    'ralinguerons',
    'ralingueront',
    'rallégeaient',
    'rallégeasses',
    'rallégerions',
    'ralliassions',
    'rallieraient',
    'rallongeâmes',
    'rallongeasse',
    'rallongeâtes',
    'rallongement',
    'rallongerais',
    'rallongerait',
    'rallongèrent',
    'rallongeriez',
    'rallongerons',
    'rallongeront',
    'rallumassent',
    'rallumassiez',
    'rallumerions',
    'ramageassent',
    'ramageassiez',
    'ramageraient',
    'ramaillaient',
    'ramaillasses',
    'ramaillerais',
    'ramaillerait',
    'ramaillèrent',
    'ramailleriez',
    'ramaillerons',
    'ramailleront',
    'ramandassent',
    'ramandassiez',
    'ramanderions',
    'ramarrassent',
    'ramarrassiez',
    'ramarrerions',
    'ramassassent',
    'ramassassiez',
    'ramasserions',
    'ramastiquais',
    'ramastiquait',
    'ramastiquant',
    'ramastiquées',
    'ramastiquent',
    'ramastiquiez',
    'ramastiquons',
    'ramatisquera',
    'ramenassions',
    'ramendassent',
    'ramendassiez',
    'ramenderions',
    'ramèneraient',
    'rameutassent',
    'rameutassiez',
    'rameuterions',
    'ramifiassent',
    'ramifiassiez',
    'ramification',
    'ramifierions',
    'ramollirions',
    'ramollissais',
    'ramollissait',
    'ramollissant',
    'ramollissent',
    'ramollissiez',
    'ramollissons',
    'ramonassions',
    'ramoneraient',
    'rancardaient',
    'rancardasses',
    'rancarderais',
    'rancarderait',
    'rancardèrent',
    'rancarderiez',
    'rancarderons',
    'rancarderont',
    'rancescibles',
    'rancissaient',
    'rancissement',
    'rançonnaient',
    'rançonnasses',
    'rançonnement',
    'rançonnerais',
    'rançonnerait',
    'rançonnèrent',
    'rançonneriez',
    'rançonnerons',
    'rançonneront',
    'randomisâmes',
    'randomisasse',
    'randomisâtes',
    'randomiserai',
    'randomiseras',
    'randomiserez',
    'randomisions',
    'randonnaient',
    'randonnasses',
    'randonnerais',
    'randonnerait',
    'randonnèrent',
    'randonneriez',
    'randonnerons',
    'randonneront',
    'randonneuses',
    'rangeassions',
    'ranimassions',
    'ranimeraient',
    'rapapillotai',
    'rapapillotas',
    'rapapillotât',
    'rapapillotée',
    'rapapilloter',
    'rapapillotes',
    'rapapillotés',
    'rapapillotez',
    'rapatriaient',
    'rapatriasses',
    'rapatriement',
    'rapatrierais',
    'rapatrierait',
    'rapatrièrent',
    'rapatrieriez',
    'rapatrierons',
    'rapatrieront',
    'rapatronnage',
    'rapetassages',
    'rapetassâmes',
    'rapetassasse',
    'rapetassâtes',
    'rapetasserai',
    'rapetasseras',
    'rapetasserez',
    'rapetassions',
    'rapetissâmes',
    'rapetissasse',
    'rapetissâtes',
    'rapetisserai',
    'rapetisseras',
    'rapetisserez',
    'rapetissions',
    'rapiéçassent',
    'rapiéçassiez',
    'rapiécerions',
    'rapiécetâmes',
    'rapiécetasse',
    'rapiécetâtes',
    'rapiécéterai',
    'rapiécéteras',
    'rapiécéterez',
    'rapiécetions',
    'rapinassions',
    'rapineraient',
    'raplatirions',
    'raplatissais',
    'raplatissait',
    'raplatissant',
    'raplatissent',
    'raplatissiez',
    'raplatissons',
    'rapointirais',
    'rapointirait',
    'rapointirent',
    'rapointiriez',
    'rapointirons',
    'rapointiront',
    'rapointisses',
    'rapointissez',
    'rappareillai',
    'rappareillas',
    'rappareillât',
    'rappareillée',
    'rappareiller',
    'rappareilles',
    'rappareillés',
    'rappareillez',
    'rappariaient',
    'rappariasses',
    'rappariement',
    'rapparierais',
    'rapparierait',
    'rapparièrent',
    'rapparieriez',
    'rapparierons',
    'rapparieront',
    'rappelassent',
    'rappelassiez',
    'rappellerais',
    'rappellerait',
    'rappelleriez',
    'rappellerons',
    'rappelleront',
    'rappliquâmes',
    'rappliquasse',
    'rappliquâtes',
    'rappliquerai',
    'rappliqueras',
    'rappliquerez',
    'rappliquions',
    'rapportables',
    'rapportaient',
    'rapportasses',
    'rapporterais',
    'rapporterait',
    'rapportèrent',
    'rapporteriez',
    'rapporterons',
    'rapporteront',
    'rapporteuses',
    'rapprenaient',
    'rapprendrais',
    'rapprendrait',
    'rapprendriez',
    'rapprendrons',
    'rapprendront',
    'rapprêtaient',
    'rapprêtasses',
    'rapprêterais',
    'rapprêterait',
    'rapprêtèrent',
    'rapprêteriez',
    'rapprêterons',
    'rapprêteront',
    'rapprissions',
    'rapprochâmes',
    'rapprochante',
    'rapprochants',
    'rapprochasse',
    'rapprochâtes',
    'rapprocherai',
    'rapprocheras',
    'rapprocherez',
    'rapprochions',
    'rappropriais',
    'rappropriait',
    'rappropriant',
    'rappropriées',
    'rapproprient',
    'rappropriera',
    'rappropriiez',
    'rapproprions',
    'raréfactions',
    'raréfiassent',
    'raréfiassiez',
    'raréfierions',
    'raspoutitsas',
    'rassasiaient',
    'rassasiasses',
    'rassasiement',
    'rassasierais',
    'rassasierait',
    'rassasièrent',
    'rassasieriez',
    'rassasierons',
    'rassasieront',
    'rassemblâmes',
    'rassemblasse',
    'rassemblâtes',
    'rassemblerai',
    'rassembleras',
    'rassemblerez',
    'rassembleurs',
    'rassembleuse',
    'rassemblions',
    'rassérénâmes',
    'rassérénasse',
    'rassérénâtes',
    'rassérénerai',
    'rasséréneras',
    'rassérénerez',
    'rassérénions',
    'rassiéraient',
    'rassortiment',
    'rassortirais',
    'rassortirait',
    'rassortirent',
    'rassortiriez',
    'rassortirons',
    'rassortiront',
    'rassortisses',
    'rassortissez',
    'rassurassent',
    'rassurassiez',
    'rassurerions',
    'rastaquouère',
    'ratatinaient',
    'ratatinasses',
    'ratatinerais',
    'ratatinerait',
    'ratatinèrent',
    'ratatineriez',
    'ratatinerons',
    'ratatineront',
    'ratatouilles',
    'râtelassions',
    'râtellerions',
    'ratiboisâmes',
    'ratiboisasse',
    'ratiboisâtes',
    'ratiboiserai',
    'ratiboiseras',
    'ratiboiserez',
    'ratiboisions',
    'ratification',
    'ratifierions',
    'ratinassions',
    'ratineraient',
    'ratiocinâmes',
    'ratiocinasse',
    'ratiocinâtes',
    'ratiocinerai',
    'ratiocineras',
    'ratiocinerez',
    'ratiocineurs',
    'ratiocinions',
    'rationalisai',
    'rationalisas',
    'rationalisât',
    'rationalisée',
    'rationaliser',
    'rationalises',
    'rationalisés',
    'rationalisez',
    'rationalisme',
    'rationaliste',
    'rationalités',
    'rationnaient',
    'rationnaires',
    'rationnasses',
    'rationnelles',
    'rationnement',
    'rationnerais',
    'rationnerait',
    'rationnèrent',
    'rationneriez',
    'rationnerons',
    'rationneront',
    'ratissassent',
    'ratissassiez',
    'ratisserions',
    'rattachaient',
    'rattachasses',
    'rattachement',
    'rattacherais',
    'rattacherait',
    'rattachèrent',
    'rattacheriez',
    'rattacherons',
    'rattacheront',
    'rattrapables',
    'rattrapaient',
    'rattrapasses',
    'rattraperais',
    'rattraperait',
    'rattrapèrent',
    'rattraperiez',
    'rattraperons',
    'rattraperont',
    'raturassions',
    'ratureraient',
    'raugmentâmes',
    'raugmentasse',
    'raugmentâtes',
    'raugmenterai',
    'raugmenteras',
    'raugmenterez',
    'raugmentions',
    'ravageassent',
    'ravageassiez',
    'ravageraient',
    'ravalassions',
    'ravaleraient',
    'ravaudassent',
    'ravaudassiez',
    'ravauderions',
    'ravigotaient',
    'ravigotantes',
    'ravigotasses',
    'ravigoterais',
    'ravigoterait',
    'ravigotèrent',
    'ravigoteriez',
    'ravigoterons',
    'ravigoteront',
    'raviliraient',
    'ravilissions',
    'ravinassions',
    'ravineraient',
    'ravisassions',
    'raviseraient',
    'ravissements',
    'ravitaillais',
    'ravitaillait',
    'ravitaillant',
    'ravitaillées',
    'ravitaillent',
    'ravitaillera',
    'ravitailleur',
    'ravitailliez',
    'ravitaillons',
    'ravivassions',
    'raviverasses',
    'raviverèrent',
    'rayonnassent',
    'rayonnassiez',
    'rayonnements',
    'rayonnerions',
    'razziassions',
    'razzierasses',
    'razzierèrent',
    'réabonnaient',
    'réabonnasses',
    'réabonnement',
    'réabonnèrent',
    'réabsorbâmes',
    'réabsorbasse',
    'réabsorbâtes',
    'réabsorberai',
    'réabsorberas',
    'réabsorberez',
    'réabsorbions',
    'réabsorption',
    'réaccoutumai',
    'réaccoutumas',
    'réaccoutumât',
    'réaccoutumée',
    'réaccoutumer',
    'réaccoutumes',
    'réaccoutumés',
    'réaccoutumez',
    'réactionnels',
    'réactivaient',
    'réactivasses',
    'réactivation',
    'réactiverais',
    'réactiverait',
    'réactivèrent',
    'réactiveriez',
    'réactiverons',
    'réactiveront',
    'réactualisai',
    'réactualisas',
    'réactualisât',
    'réactualisée',
    'réactualiser',
    'réactualises',
    'réactualisés',
    'réactualisez',
    'réadaptaient',
    'réadaptasses',
    'réadaptation',
    'réadapterais',
    'réadapterait',
    'réadaptèrent',
    'réadapteriez',
    'réadapterons',
    'réadapteront',
    'réadmettions',
    'réadmettrais',
    'réadmettrait',
    'réadmettriez',
    'réadmettrons',
    'réadmettront',
    'réadmissions',
    'réaffectâmes',
    'réaffectasse',
    'réaffectâtes',
    'réaffecterai',
    'réaffecteras',
    'réaffecterez',
    'réaffections',
    'réaffirmâmes',
    'réaffirmasse',
    'réaffirmâtes',
    'réaffirmerai',
    'réaffirmeras',
    'réaffirmerez',
    'réaffirmions',
    'réaffûtaient',
    'réaffûtasses',
    'réaffûterais',
    'réaffûterait',
    'réaffûtèrent',
    'réaffûteriez',
    'réaffûterons',
    'réaffûteront',
    'réagissaient',
    'réajustaient',
    'réajustasses',
    'réajustement',
    'réajusterais',
    'réajusterait',
    'réajustèrent',
    'réajusteriez',
    'réajusterons',
    'réajusteront',
    'réalésassent',
    'réalésassiez',
    'réaléserions',
    'réalignaient',
    'réalignasses',
    'réalignerais',
    'réalignerait',
    'réalignèrent',
    'réaligneriez',
    'réalignerons',
    'réaligneront',
    'réalisassent',
    'réalisassiez',
    'réalisateurs',
    'réalisations',
    'réalisatrice',
    'réaliserions',
    'réalistement',
    'réaménageais',
    'réaménageait',
    'réaménageant',
    'réaménageons',
    'réaménagerai',
    'réaménageras',
    'réaménagerez',
    'réaménagions',
    'réamorçaient',
    'réamorçasses',
    'réamorcerais',
    'réamorcerait',
    'réamorcèrent',
    'réamorceriez',
    'réamorcerons',
    'réamorceront',
    'réanimassent',
    'réanimassiez',
    'réanimations',
    'réanimerions',
    'réapparaisse',
    'réapparaîtra',
    'réapparaître',
    'réapparition',
    'réapparurent',
    'réapparusses',
    'réapprendrai',
    'réapprendras',
    'réapprendrez',
    'réapprenions',
    'réapprennent',
    'réapprissent',
    'réapprissiez',
    'réargentâmes',
    'réargentasse',
    'réargentâtes',
    'réargenterai',
    'réargenteras',
    'réargenterez',
    'réargentions',
    'réarmassions',
    'réarmeraient',
    'réarrangeais',
    'réarrangeait',
    'réarrangeant',
    'réarrangeons',
    'réarrangerai',
    'réarrangeras',
    'réarrangerez',
    'réarrangions',
    'réassignâmes',
    'réassignasse',
    'réassignâtes',
    'réassignerai',
    'réassigneras',
    'réassignerez',
    'réassignions',
    'réassortîmes',
    'réassortirai',
    'réassortiras',
    'réassortirez',
    'réassortisse',
    'réassortîtes',
    'réassuraient',
    'réassurances',
    'réassurasses',
    'réassurerais',
    'réassurerait',
    'réassurèrent',
    'réassureriez',
    'réassurerons',
    'réassureront',
    'rebaissaient',
    'rebaissasses',
    'rebaisserais',
    'rebaisserait',
    'rebaissèrent',
    'rebaisseriez',
    'rebaisserons',
    'rebaisseront',
    'rebandassent',
    'rebandassiez',
    'rebanderions',
    'rebaptisâmes',
    'rebaptisasse',
    'rebaptisâtes',
    'rebaptiserai',
    'rebaptiseras',
    'rebaptiserez',
    'rebaptisions',
    'rébarbatives',
    'rebâtiraient',
    'rebâtissions',
    'rebattements',
    'rebattissent',
    'rebattissiez',
    'rebattraient',
    'rebellassent',
    'rebellassiez',
    'rebellerions',
    'rebiffassent',
    'rebiffassiez',
    'rebifferions',
    'rebiquassent',
    'rebiquassiez',
    'rebiquerions',
    'reblanchîmes',
    'reblanchirai',
    'reblanchiras',
    'reblanchirez',
    'reblanchisse',
    'reblanchîtes',
    'reboisassent',
    'reboisassiez',
    'reboisements',
    'reboiserions',
    'rebondirions',
    'rebondissais',
    'rebondissait',
    'rebondissant',
    'rebondissent',
    'rebondissiez',
    'rebondissons',
    'rebordassent',
    'rebordassiez',
    'reborderions',
    'rebouchaient',
    'rebouchasses',
    'reboucherais',
    'reboucherait',
    'rebouchèrent',
    'reboucheriez',
    'reboucherons',
    'reboucheront',
    'reboutassent',
    'reboutassiez',
    'rebouterions',
    'reboutonnais',
    'reboutonnait',
    'reboutonnant',
    'reboutonnées',
    'reboutonnent',
    'reboutonnera',
    'reboutonniez',
    'reboutonnons',
    'rebrodassent',
    'rebrodassiez',
    'rebroderions',
    'rebroussâmes',
    'rebroussasse',
    'rebroussâtes',
    'rebrousserai',
    'rebrousseras',
    'rebrousserez',
    'rebroussions',
    'rebrûlassent',
    'rebrûlassiez',
    'rebrûlerions',
    'rebutassions',
    'rebuteraient',
    'recachetâmes',
    'recachetasse',
    'recachetâtes',
    'recachetions',
    'recachettent',
    'recachettera',
    'recalassions',
    'recalcifiais',
    'recalcifiait',
    'recalcifiant',
    'recalcifiées',
    'recalcifient',
    'recalcifiera',
    'recalcifiiez',
    'recalcifions',
    'récalcitrant',
    'recalculâmes',
    'recalculasse',
    'recalculâtes',
    'recalculerai',
    'recalculeras',
    'recalculerez',
    'recalculions',
    'recaleraient',
    'récapitulais',
    'récapitulait',
    'récapitulant',
    'récapitulées',
    'récapitulent',
    'récapitulera',
    'récapituliez',
    'récapitulons',
    'recardassent',
    'recardassiez',
    'recarderions',
    'recarrelâmes',
    'recarrelasse',
    'recarrelâtes',
    'recarrelions',
    'recarrellent',
    'recarrellera',
    'recasassions',
    'recaseraient',
    'recausassent',
    'recausassiez',
    'recauserions',
    'recédassions',
    'recéderaient',
    'recelassions',
    'recélassions',
    'recéleraient',
    'recèleraient',
    'recensassent',
    'recensassiez',
    'recensements',
    'recenserions',
    'recentraient',
    'recentrasses',
    'recentrerais',
    'recentrerait',
    'recentrèrent',
    'recentreriez',
    'recentrerons',
    'recentreront',
    'recepassions',
    'recépassions',
    'recéperaient',
    'recèperaient',
    'réceptionnai',
    'réceptionnas',
    'réceptionnât',
    'réceptionnée',
    'réceptionner',
    'réceptionnes',
    'réceptionnés',
    'réceptionnez',
    'réceptivités',
    'recerclaient',
    'recerclasses',
    'recerclerais',
    'recerclerait',
    'recerclèrent',
    'recercleriez',
    'recerclerons',
    'recercleront',
    'recevabilité',
    'rechampirais',
    'réchampirais',
    'rechampirait',
    'réchampirait',
    'rechampirent',
    'réchampirent',
    'rechampiriez',
    'réchampiriez',
    'rechampirons',
    'réchampirons',
    'rechampiront',
    'réchampiront',
    'rechampisses',
    'réchampisses',
    'rechampissez',
    'réchampissez',
    'rechangeâmes',
    'rechangeasse',
    'rechangeâtes',
    'rechangerais',
    'rechangerait',
    'rechangèrent',
    'rechangeriez',
    'rechangerons',
    'rechangeront',
    'rechantaient',
    'rechantasses',
    'rechanterais',
    'rechanterait',
    'rechantèrent',
    'rechanteriez',
    'rechanterons',
    'rechanteront',
    'rechapassent',
    'rechapassiez',
    'rechaperions',
    'réchappaient',
    'réchappasses',
    'réchapperais',
    'réchapperait',
    'réchappèrent',
    'réchapperiez',
    'réchapperons',
    'réchapperont',
    'rechargeable',
    'rechargeâmes',
    'rechargeasse',
    'rechargeâtes',
    'rechargerais',
    'rechargerait',
    'rechargèrent',
    'rechargeriez',
    'rechargerons',
    'rechargeront',
    'rechassaient',
    'rechassasses',
    'rechasserais',
    'rechasserait',
    'rechassèrent',
    'rechasseriez',
    'rechasserons',
    'rechasseront',
    'réchauffages',
    'réchauffâmes',
    'réchauffante',
    'réchauffants',
    'réchauffasse',
    'réchauffâtes',
    'réchaufferai',
    'réchaufferas',
    'réchaufferez',
    'réchauffeurs',
    'réchauffions',
    'rechaussâmes',
    'rechaussasse',
    'rechaussâtes',
    'rechausserai',
    'rechausseras',
    'rechausserez',
    'rechaussions',
    'recherchâmes',
    'recherchasse',
    'recherchâtes',
    'rechercherai',
    'rechercheras',
    'rechercherez',
    'recherchions',
    'rechignaient',
    'rechignasses',
    'rechignerais',
    'rechignerait',
    'rechignèrent',
    'rechigneriez',
    'rechignerons',
    'rechigneront',
    'rechutassent',
    'rechutassiez',
    'rechuterions',
    'récidivaient',
    'récidivantes',
    'récidivasses',
    'récidiverais',
    'récidiverait',
    'récidivèrent',
    'récidiveriez',
    'récidiverons',
    'récidiveront',
    'récidivismes',
    'récidivistes',
    'réciprocités',
    'réciproquais',
    'réciproquait',
    'réciproquant',
    'réciproquées',
    'réciproquent',
    'réciproquera',
    'réciproquiez',
    'réciproquons',
    'récitassions',
    'réciteraient',
    'réclamassent',
    'réclamassiez',
    'réclamations',
    'réclamerions',
    'reclassaient',
    'reclassasses',
    'reclassement',
    'reclasserais',
    'reclasserait',
    'reclassèrent',
    'reclasseriez',
    'reclasserons',
    'reclasseront',
    'réclinassent',
    'réclinassiez',
    'réclinerions',
    'reclouassent',
    'reclouassiez',
    'reclouerions',
    'recognitions',
    'recoiffaient',
    'recoiffasses',
    'recoifferais',
    'recoifferait',
    'recoiffèrent',
    'recoifferiez',
    'recoifferons',
    'recoifferont',
    'recolassions',
    'recoleraient',
    'recollassent',
    'recollassiez',
    'récollection',
    'recollements',
    'recollerions',
    'recoloraient',
    'recolorasses',
    'recolorerais',
    'recolorerait',
    'recolorèrent',
    'recoloreriez',
    'recolorerons',
    'recoloreront',
    'récoltassent',
    'récoltassiez',
    'récolterions',
    'recombinâmes',
    'recombinasse',
    'recombinâtes',
    'recombinerai',
    'recombineras',
    'recombinerez',
    'recombinions',
    'recommandais',
    'recommandait',
    'recommandant',
    'recommandées',
    'recommandent',
    'recommandera',
    'recommandiez',
    'recommandons',
    'recommençais',
    'recommençait',
    'recommençant',
    'recommencées',
    'recommencent',
    'recommencera',
    'recommenciez',
    'recommençons',
    'recomparûmes',
    'recomparusse',
    'recomparûtes',
    'récompensais',
    'récompensait',
    'récompensant',
    'récompensées',
    'récompensent',
    'récompensera',
    'récompensiez',
    'récompensons',
    'recompilâmes',
    'recompilasse',
    'recompilâtes',
    'recompilerai',
    'recompileras',
    'recompilerez',
    'recompilions',
    'recomposable',
    'recomposâmes',
    'recomposasse',
    'recomposâtes',
    'recomposerai',
    'recomposeras',
    'recomposerez',
    'recomposions',
    'recomptaient',
    'recomptasses',
    'recompterais',
    'recompterait',
    'recomptèrent',
    'recompteriez',
    'recompterons',
    'recompteront',
    'réconciliais',
    'réconciliait',
    'réconciliant',
    'réconciliées',
    'réconcilient',
    'réconciliera',
    'réconciliiez',
    'réconcilions',
    'recondamnais',
    'recondamnait',
    'recondamnant',
    'recondamnées',
    'recondamnent',
    'recondamnera',
    'recondamniez',
    'recondamnons',
    'reconduction',
    'reconduirais',
    'reconduirait',
    'reconduiriez',
    'reconduirons',
    'reconduiront',
    'reconduisais',
    'reconduisait',
    'reconduisant',
    'reconduisent',
    'reconduisiez',
    'reconduisons',
    'réconfortais',
    'réconfortait',
    'réconfortant',
    'réconfortées',
    'réconfortent',
    'réconfortera',
    'réconfortiez',
    'réconfortons',
    'recongelâmes',
    'recongelasse',
    'recongelâtes',
    'recongèlerai',
    'recongèleras',
    'recongèlerez',
    'recongelions',
    'reconnaisses',
    'reconnaissez',
    'reconnaîtrai',
    'reconnaîtras',
    'reconnaîtrez',
    'reconnectais',
    'reconnectait',
    'reconnectant',
    'reconnectées',
    'reconnectent',
    'reconnectera',
    'reconnectiez',
    'reconnectons',
    'reconnussent',
    'reconnussiez',
    'reconquérais',
    'reconquérait',
    'reconquérant',
    'reconquériez',
    'reconquérons',
    'reconquerrai',
    'reconquerras',
    'reconquerrez',
    'reconquérues',
    'reconquières',
    'reconquirent',
    'reconquisses',
    'reconsidérai',
    'reconsidéras',
    'reconsidérât',
    'reconsidérée',
    'reconsidérer',
    'reconsidérés',
    'reconsidères',
    'reconsidérez',
    'reconsolidai',
    'reconsolidas',
    'reconsolidât',
    'reconsolidée',
    'reconsolider',
    'reconsolides',
    'reconsolidés',
    'reconsolidez',
    'reconstituai',
    'reconstituas',
    'reconstituât',
    'reconstituée',
    'reconstituer',
    'reconstitues',
    'reconstitués',
    'reconstituez',
    'reconstruira',
    'reconstruire',
    'reconstruise',
    'reconstruite',
    'reconstruits',
    'reconversion',
    'reconverties',
    'reconvertira',
    'recopiassent',
    'recopiassiez',
    'recopierions',
    'recoquillais',
    'recoquillait',
    'recoquillant',
    'recoquillées',
    'recoquillent',
    'recoquillera',
    'recoquilliez',
    'recoquillons',
    'recordassent',
    'recordassiez',
    'recorderions',
    'recorrigeais',
    'recorrigeait',
    'recorrigeant',
    'recorrigeons',
    'recorrigerai',
    'recorrigeras',
    'recorrigerez',
    'recorrigions',
    'recouchaient',
    'recouchasses',
    'recouchèrent',
    'recoudraient',
    'recoupassent',
    'recoupassiez',
    'recoupements',
    'recouperions',
    'recourbaient',
    'recourbasses',
    'recourberais',
    'recourberait',
    'recourbèrent',
    'recourberiez',
    'recourberons',
    'recourberont',
    'recourraient',
    'recourussent',
    'recourussiez',
    'recousissent',
    'recousissiez',
    'recouvrables',
    'recouvraient',
    'recouvrances',
    'recouvrantes',
    'recouvrasses',
    'recouvrement',
    'recouvrerais',
    'recouvrerait',
    'recouvrèrent',
    'recouvreriez',
    'recouvrerons',
    'recouvreront',
    'recouvrirais',
    'recouvrirait',
    'recouvrirent',
    'recouvririez',
    'recouvrirons',
    'recouvriront',
    'recouvrisses',
    'recrachaient',
    'recrachasses',
    'recracherais',
    'recracherait',
    'recrachèrent',
    'recracheriez',
    'recracherons',
    'recracheront',
    'recréassions',
    'récréassions',
    'recréeraient',
    'récréeraient',
    'recrépirions',
    'recrépissais',
    'recrépissait',
    'recrépissant',
    'recrépissent',
    'recrépissiez',
    'recrépissons',
    'recreusaient',
    'recreusasses',
    'recreuserais',
    'recreuserait',
    'recreusèrent',
    'recreuseriez',
    'recreuserons',
    'recreuseront',
    'récriassions',
    'récrieraient',
    'récriminâmes',
    'récriminasse',
    'récriminâtes',
    'récriminerai',
    'récrimineras',
    'récriminerez',
    'récriminions',
    'récrivissent',
    'récrivissiez',
    'recroisaient',
    'recroiserais',
    'recroiserait',
    'recroiseriez',
    'recroiserons',
    'recroiseront',
    'recroissante',
    'recroissants',
    'recroissions',
    'recroîtrions',
    'recrudescent',
    'recrutassent',
    'recrutassiez',
    'recrutements',
    'recruterions',
    'rectifiables',
    'rectifiaient',
    'rectifiasses',
    'rectificatif',
    'rectifierais',
    'rectifierait',
    'rectifièrent',
    'rectifieriez',
    'rectifierons',
    'rectifieront',
    'rectifieuses',
    'rectoscopies',
    'recueillerai',
    'recueilleras',
    'recueillerez',
    'recueillîmes',
    'recueillions',
    'recueillisse',
    'recueillîtes',
    'recuisissent',
    'recuisissiez',
    'reculassions',
    'reculeraient',
    'reculottâmes',
    'reculottasse',
    'reculottâtes',
    'reculotterai',
    'reculotteras',
    'reculotterez',
    'reculottions',
    'récupérables',
    'récupéraient',
    'récupérasses',
    'récupérateur',
    'récupération',
    'récupérerais',
    'récupérerait',
    'récupérèrent',
    'récupéreriez',
    'récupérerons',
    'récupéreront',
    'récurassions',
    'récureraient',
    'récusassions',
    'récuseraient',
    'recyclassent',
    'recyclassiez',
    'recyclerions',
    'rédactionnel',
    'redéfaisions',
    'redéfassions',
    'redéferaient',
    'redéfinirais',
    'redéfinirait',
    'redéfinirent',
    'redéfiniriez',
    'redéfinirons',
    'redéfiniront',
    'redéfinisses',
    'redéfinissez',
    'redéfinition',
    'redéfissions',
    'redemandâmes',
    'redemandasse',
    'redemandâtes',
    'redemanderai',
    'redemanderas',
    'redemanderez',
    'redemandions',
    'redémarrages',
    'redémarrâmes',
    'redémarrasse',
    'redémarrâtes',
    'redémarrerai',
    'redémarreras',
    'redémarrerez',
    'redémarrions',
    'redémolirais',
    'redémolirait',
    'redémolirent',
    'redémoliriez',
    'redémolirons',
    'redémoliront',
    'redémolisses',
    'redémolissez',
    'redémontrais',
    'redémontrait',
    'redémontrant',
    'redémontrées',
    'redémontrent',
    'redémontrera',
    'redémontriez',
    'redémontrons',
    'rédemptrices',
    'redéploierai',
    'redéploieras',
    'redéploierez',
    'redéployâmes',
    'redéployasse',
    'redéployâtes',
    'redéployions',
    'redescendais',
    'redescendait',
    'redescendant',
    'redescendent',
    'redescendiez',
    'redescendons',
    'redescendrai',
    'redescendras',
    'redescendrez',
    'redescendues',
    'redevenaient',
    'redeviendrai',
    'redeviendras',
    'redeviendrez',
    'redeviennent',
    'redevinssent',
    'redevinssiez',
    'rédhibitions',
    'rédhibitoire',
    'rediffusions',
    'rédigeassent',
    'rédigeassiez',
    'rédigeraient',
    'rédimassions',
    'rédimeraient',
    'rediscutâmes',
    'rediscutasse',
    'rediscutâtes',
    'rediscuterai',
    'rediscuteras',
    'rediscuterez',
    'rediscutions',
    'redistribuai',
    'redistribuas',
    'redistribuât',
    'redistribuée',
    'redistribuer',
    'redistribues',
    'redistribués',
    'redistribuez',
    'redondassent',
    'redondassiez',
    'redonderions',
    'redonnassent',
    'redonnassiez',
    'redonnerions',
    'redorassions',
    'redoreraient',
    'redoublaient',
    'redoublantes',
    'redoublasses',
    'redoublement',
    'redoublerais',
    'redoublerait',
    'redoublèrent',
    'redoubleriez',
    'redoublerons',
    'redoubleront',
    'redoutassent',
    'redoutassiez',
    'redouterions',
    'redressaient',
    'redressasses',
    'redressement',
    'redresserais',
    'redresserait',
    'redressèrent',
    'redresseriez',
    'redresserons',
    'redresseront',
    'réduisissent',
    'réduisissiez',
    'réduplicatif',
    'réécoutaient',
    'réécoutasses',
    'réécouterais',
    'réécouterait',
    'réécoutèrent',
    'réécouteriez',
    'réécouterons',
    'réécouteront',
    'réédifiaient',
    'réédifiasses',
    'réédifierais',
    'réédifierait',
    'réédifièrent',
    'réédifieriez',
    'réédifierons',
    'réédifieront',
    'rééditassent',
    'rééditassiez',
    'rééditerions',
    'rééducations',
    'rééduquaient',
    'rééduquasses',
    'rééduquerais',
    'rééduquerait',
    'rééduquèrent',
    'rééduqueriez',
    'rééduquerons',
    'rééduqueront',
    'réembauchais',
    'réembauchait',
    'réembauchant',
    'réembauchées',
    'réembauchent',
    'réembauchera',
    'réembauchiez',
    'réembauchons',
    'réemploierai',
    'réemploieras',
    'réemploierez',
    'réemployâmes',
    'réemployasse',
    'réemployâtes',
    'réemployions',
    'réempruntais',
    'réempruntait',
    'réempruntant',
    'réempruntées',
    'réempruntent',
    'réempruntera',
    'réempruntiez',
    'réempruntons',
    'réengageâmes',
    'réengageasse',
    'réengageâtes',
    'réengagement',
    'réengagerais',
    'réengagerait',
    'réengagèrent',
    'réengageriez',
    'réengagerons',
    'réengageront',
    'réenregistra',
    'réenregistre',
    'réenregistré',
    'réensemençai',
    'réensemenças',
    'réensemençât',
    'réensemencée',
    'réensemencer',
    'réensemences',
    'réensemencés',
    'réensemencez',
    'rééquilibrai',
    'rééquilibras',
    'rééquilibrât',
    'rééquilibrée',
    'rééquilibrer',
    'rééquilibres',
    'rééquilibrés',
    'rééquilibrez',
    'rééquipement',
    'réescomptais',
    'réescomptait',
    'réescomptant',
    'réescomptées',
    'réescomptent',
    'réescomptera',
    'réescomptiez',
    'réescomptons',
    'réessaierais',
    'réessaierait',
    'réessaieriez',
    'réessaierons',
    'réessaieront',
    'réessayaient',
    'réessayasses',
    'réessayerais',
    'réessayerait',
    'réessayèrent',
    'réessayeriez',
    'réessayerons',
    'réessayeront',
    'réévaluaient',
    'réévaluasses',
    'réévaluation',
    'réévaluerais',
    'réévaluerait',
    'réévaluèrent',
    'réévalueriez',
    'réévaluerons',
    'réévalueront',
    'réexaminâmes',
    'réexaminasse',
    'réexaminâtes',
    'réexaminerai',
    'réexamineras',
    'réexaminerez',
    'réexaminions',
    'réexpédiâmes',
    'réexpédiasse',
    'réexpédiâtes',
    'réexpédierai',
    'réexpédieras',
    'réexpédierez',
    'réexpédiions',
    'réexpédition',
    'réexportâmes',
    'réexportasse',
    'réexportâtes',
    'réexporterai',
    'réexporteras',
    'réexporterez',
    'réexportions',
    'refaçonnâmes',
    'refaçonnasse',
    'refaçonnâtes',
    'refaçonnerai',
    'refaçonneras',
    'refaçonnerez',
    'refaçonnions',
    'refendissent',
    'refendissiez',
    'refendraient',
    'référassions',
    'référençâmes',
    'référençasse',
    'référençâtes',
    'référencerai',
    'référenceras',
    'référencerez',
    'référencions',
    'référendaire',
    'référentiels',
    'référeraient',
    'refermassent',
    'refermassiez',
    'refermerions',
    'refilassions',
    'refileraient',
    'réfléchirais',
    'réfléchirait',
    'réfléchirent',
    'réfléchiriez',
    'réfléchirons',
    'réfléchiront',
    'réfléchisses',
    'réfléchissez',
    'réflectivité',
    'reflétassent',
    'reflétassiez',
    'refléterions',
    'refleurirais',
    'refleurirait',
    'refleurirent',
    'refleuririez',
    'refleurirons',
    'refleuriront',
    'refleurisses',
    'refleurissez',
    'réflexivités',
    'réflexogènes',
    'réflexologie',
    'refluassions',
    'reflueraient',
    'refondissent',
    'refondissiez',
    'refondraient',
    'reforgeaient',
    'reforgeasses',
    'reforgerions',
    'reformassent',
    'réformassent',
    'reformassiez',
    'réformassiez',
    'réformateurs',
    'réformatrice',
    'reformerions',
    'réformerions',
    'reformulâmes',
    'reformulasse',
    'reformulâtes',
    'reformulerai',
    'reformuleras',
    'reformulerez',
    'reformulions',
    'refouillâmes',
    'refouillasse',
    'refouillâtes',
    'refouillerai',
    'refouilleras',
    'refouillerez',
    'refouillions',
    'refoulassent',
    'refoulassiez',
    'refoulements',
    'refoulerions',
    'refourraient',
    'refourrasses',
    'refourrerais',
    'refourrerait',
    'refourrèrent',
    'refourreriez',
    'refourrerons',
    'refourreront',
    'réfractaient',
    'réfractaires',
    'réfractasses',
    'réfracterais',
    'réfracterait',
    'réfractèrent',
    'réfracteriez',
    'réfracterons',
    'réfracteront',
    'réfractrices',
    'réfrangibles',
    'refrénassent',
    'réfrénassent',
    'refrénassiez',
    'réfrénassiez',
    'refrènements',
    'refrénerions',
    'réfrénerions',
    'réfrigérâmes',
    'réfrigérante',
    'réfrigérants',
    'réfrigérasse',
    'réfrigérâtes',
    'réfrigérerai',
    'réfrigéreras',
    'réfrigérerez',
    'réfrigérions',
    'réfringences',
    'réfringentes',
    'refroidirais',
    'refroidirait',
    'refroidirent',
    'refroidiriez',
    'refroidirons',
    'refroidiront',
    'refroidisses',
    'refroidissez',
    'réfugiassent',
    'réfugiassiez',
    'réfugierions',
    'refusassions',
    'refuseraient',
    'réfutassions',
    'réfuteraient',
    'regagnassent',
    'regagnassiez',
    'regagnerions',
    'régalassions',
    'régaleraient',
    'regardassent',
    'regardassiez',
    'regarderions',
    'regarnirions',
    'regarnissais',
    'regarnissait',
    'regarnissant',
    'regarnissent',
    'regarnissiez',
    'regarnissons',
    'régatassions',
    'régateraient',
    'regazonnâmes',
    'regazonnasse',
    'regazonnâtes',
    'regazonnerai',
    'regazonneras',
    'regazonnerez',
    'regazonnions',
    'régénéraient',
    'régénérasses',
    'régénérateur',
    'régénération',
    'régénérerais',
    'régénérerait',
    'régénérèrent',
    'régénéreriez',
    'régénérerons',
    'régénéreront',
    'régentassent',
    'régentassiez',
    'régenterions',
    'regimbassent',
    'regimbassiez',
    'regimbements',
    'regimberions',
    'régimentaire',
    'régionalisai',
    'régionalisas',
    'régionalisât',
    'régionalisée',
    'régionaliser',
    'régionalises',
    'régionalisés',
    'régionalisez',
    'régionalisme',
    'régionaliste',
    'registraient',
    'registrasses',
    'registrerais',
    'registrerait',
    'registrèrent',
    'registreriez',
    'registrerons',
    'registreront',
    'réglementais',
    'réglementait',
    'réglementant',
    'réglementées',
    'réglementent',
    'réglementera',
    'réglementiez',
    'réglementons',
    'regonflaient',
    'regonflasses',
    'regonflerais',
    'regonflerait',
    'regonflèrent',
    'regonfleriez',
    'regonflerons',
    'regonfleront',
    'regorgeaient',
    'regorgeasses',
    'regorgements',
    'regorgerions',
    'regrattaient',
    'regrattasses',
    'regratterais',
    'regratterait',
    'regrattèrent',
    'regratteriez',
    'regratterons',
    'regratteront',
    'regrattières',
    'regréassions',
    'regréeraient',
    'regreffaient',
    'regreffasses',
    'regrefferais',
    'regrefferait',
    'regreffèrent',
    'regrefferiez',
    'regrefferons',
    'regrefferont',
    'régressaient',
    'régressasses',
    'régresserais',
    'régresserait',
    'régressèrent',
    'régresseriez',
    'régresserons',
    'régresseront',
    'regrettables',
    'regrettaient',
    'regrettasses',
    'regretterais',
    'regretterait',
    'regrettèrent',
    'regretteriez',
    'regretterons',
    'regretteront',
    'regrèvements',
    'regrimpaient',
    'regrimpasses',
    'regrimperais',
    'regrimperait',
    'regrimpèrent',
    'regrimperiez',
    'regrimperons',
    'regrimperont',
    'regrossirais',
    'regrossirait',
    'regrossirent',
    'regrossiriez',
    'regrossirons',
    'regrossiront',
    'regrossisses',
    'regrossissez',
    'regroupaient',
    'regroupasses',
    'regroupement',
    'regrouperais',
    'regrouperait',
    'regroupèrent',
    'regrouperiez',
    'regrouperons',
    'regrouperont',
    'régularisais',
    'régularisait',
    'régularisant',
    'régularisées',
    'régularisent',
    'régularisera',
    'régularisiez',
    'régularisons',
    'régulatrices',
    'régurgitâmes',
    'régurgitasse',
    'régurgitâtes',
    'régurgiterai',
    'régurgiteras',
    'régurgiterez',
    'régurgitions',
    'réhabilitais',
    'réhabilitait',
    'réhabilitant',
    'réhabilitées',
    'réhabilitent',
    'réhabilitera',
    'réhabilitiez',
    'réhabilitons',
    'réhabituâmes',
    'réhabituasse',
    'réhabituâtes',
    'réhabituerai',
    'réhabitueras',
    'réhabituerez',
    'réhabituions',
    'rehaussaient',
    'rehaussasses',
    'rehaussement',
    'rehausserais',
    'rehausserait',
    'rehaussèrent',
    'rehausseriez',
    'rehausserons',
    'rehausseront',
    'réhydratâmes',
    'réhydratasse',
    'réhydratâtes',
    'réhydraterai',
    'réhydrateras',
    'réhydraterez',
    'réhydrations',
    'réifiassions',
    'réifications',
    'réifieraient',
    'réimplantais',
    'réimplantait',
    'réimplantant',
    'réimplantées',
    'réimplantent',
    'réimplantera',
    'réimplantiez',
    'réimplantons',
    'réimportâmes',
    'réimportasse',
    'réimportâtes',
    'réimporterai',
    'réimporteras',
    'réimporterez',
    'réimportions',
    'réimposaient',
    'réimposasses',
    'réimposerais',
    'réimposerait',
    'réimposèrent',
    'réimposeriez',
    'réimposerons',
    'réimposeront',
    'réimposition',
    'réimpression',
    'réimprimâmes',
    'réimprimasse',
    'réimprimâtes',
    'réimprimerai',
    'réimprimeras',
    'réimprimerez',
    'réimprimions',
    'réincarcérai',
    'réincarcéras',
    'réincarcérât',
    'réincarcérée',
    'réincarcérer',
    'réincarcérés',
    'réincarcères',
    'réincarcérez',
    'réincarnâmes',
    'réincarnasse',
    'réincarnâtes',
    'réincarnerai',
    'réincarneras',
    'réincarnerez',
    'réincarnions',
    'réincorporai',
    'réincorporas',
    'réincorporât',
    'réincorporée',
    'réincorporer',
    'réincorpores',
    'réincorporés',
    'réincorporez',
    'reine-claude',
    'réinfectâmes',
    'réinfectasse',
    'réinfectâtes',
    'réinfecterai',
    'réinfecteras',
    'réinfecterez',
    'réinfections',
    'réinitialisa',
    'réinitialise',
    'réinitialisé',
    'réinjectâmes',
    'réinjectasse',
    'réinjectâtes',
    'réinjecterai',
    'réinjecteras',
    'réinjecterez',
    'réinjections',
    'réinscrirais',
    'réinscrirait',
    'réinscririez',
    'réinscrirons',
    'réinscriront',
    'réinscrivais',
    'réinscrivait',
    'réinscrivant',
    'réinscrivent',
    'réinscriviez',
    'réinscrivons',
    'réinséraient',
    'réinsérasses',
    'réinsérerais',
    'réinsérerait',
    'réinsérèrent',
    'réinséreriez',
    'réinsérerons',
    'réinséreront',
    'réinstallais',
    'réinstallait',
    'réinstallant',
    'réinstallées',
    'réinstallent',
    'réinstallera',
    'réinstalliez',
    'réinstallons',
    'réintégrable',
    'réintégrâmes',
    'réintégrasse',
    'réintégrâtes',
    'réintégrerai',
    'réintégreras',
    'réintégrerez',
    'réintégrions',
    'réinterpréta',
    'réinterprété',
    'réinterprète',
    'réintroduira',
    'réintroduire',
    'réintroduise',
    'réintroduite',
    'réintroduits',
    'réinventâmes',
    'réinventasse',
    'réinventâtes',
    'réinventerai',
    'réinventeras',
    'réinventerez',
    'réinventions',
    'réinvestîmes',
    'réinvestirai',
    'réinvestiras',
    'réinvestirez',
    'réinvestisse',
    'réinvestîtes',
    'réinvitaient',
    'réinvitasses',
    'réinviterais',
    'réinviterait',
    'réinvitèrent',
    'réinviteriez',
    'réinviterons',
    'réinviteront',
    'réitérassent',
    'réitérassiez',
    'réitérations',
    'réitérerions',
    'rejaillirais',
    'rejaillirait',
    'rejaillirent',
    'rejailliriez',
    'rejaillirons',
    'rejailliront',
    'rejaillisses',
    'rejaillissez',
    'rejetassions',
    'rejetterions',
    'rejoignaient',
    'rejoignirent',
    'rejoignisses',
    'rejoindrions',
    'rejointoient',
    'rejointoiera',
    'rejointoyais',
    'rejointoyait',
    'rejointoyant',
    'rejointoyées',
    'rejointoyiez',
    'rejointoyons',
    'rejouassions',
    'rejoueraient',
    'réjouiraient',
    'réjouissance',
    'réjouissante',
    'réjouissants',
    'réjouissions',
    'rejugeassent',
    'rejugeassiez',
    'rejugeraient',
    'relâchassent',
    'relâchassiez',
    'relâchements',
    'relâcherions',
    'relaieraient',
    'relaissaient',
    'relaissasses',
    'relaisserais',
    'relaisserait',
    'relaissèrent',
    'relaisseriez',
    'relaisserons',
    'relaisseront',
    'relançassent',
    'relançassiez',
    'relancerions',
    'rélargirions',
    'rélargissais',
    'rélargissait',
    'rélargissant',
    'rélargissent',
    'rélargissiez',
    'rélargissons',
    'relatassions',
    'relateraient',
    'relationnels',
    'relativement',
    'relativisais',
    'relativisait',
    'relativisant',
    'relativisées',
    'relativisent',
    'relativisera',
    'relativisiez',
    'relativismes',
    'relativisons',
    'relavassions',
    'relaveraient',
    'relaxassions',
    'relaxeraient',
    'relayassions',
    'relayeraient',
    'reléguassent',
    'reléguassiez',
    'reléguerions',
    'relevassions',
    'relèveraient',
    'religiosités',
    'relogeassent',
    'relogeassiez',
    'relogeraient',
    'relouassions',
    'reloueraient',
    'reluisissent',
    'reluisissiez',
    'reluquassent',
    'reluquassiez',
    'reluquerions',
    'remâchassent',
    'remâchassiez',
    'remâcherions',
    'remaillaient',
    'remaillasses',
    'remaillerais',
    'remaillerait',
    'remaillèrent',
    'remailleriez',
    'remaillerons',
    'remailleront',
    'remangeaient',
    'remangeasses',
    'remangerions',
    'remaniassent',
    'remaniassiez',
    'remaniements',
    'remanierions',
    'remaquillais',
    'remaquillait',
    'remaquillant',
    'remaquillées',
    'remaquillent',
    'remaquillera',
    'remaquilliez',
    'remaquillons',
    'remarchaient',
    'remarchasses',
    'remarcherais',
    'remarcherait',
    'remarchèrent',
    'remarcheriez',
    'remarcherons',
    'remarcheront',
    'remariassent',
    'remariassiez',
    'remarierions',
    'remarquables',
    'remarquaient',
    'remarquasses',
    'remarquerais',
    'remarquerait',
    'remarquèrent',
    'remarqueriez',
    'remarquerons',
    'remarqueront',
    'remastiquais',
    'remastiquait',
    'remastiquant',
    'remastiquées',
    'remastiquent',
    'remastiquera',
    'remastiquiez',
    'remastiquons',
    'remballaient',
    'remballasses',
    'remballerais',
    'remballerait',
    'remballèrent',
    'remballeriez',
    'remballerons',
    'remballeront',
    'rembarquâmes',
    'rembarquasse',
    'rembarquâtes',
    'rembarquerai',
    'rembarqueras',
    'rembarquerez',
    'rembarquions',
    'rembarraient',
    'rembarrasses',
    'rembarrerais',
    'rembarrerait',
    'rembarrèrent',
    'rembarreriez',
    'rembarrerons',
    'rembarreront',
    'rembinassent',
    'rembinassiez',
    'rembinerions',
    'remblaiement',
    'remblaierais',
    'remblaierait',
    'remblaieriez',
    'remblaierons',
    'remblaieront',
    'remblavaient',
    'remblavasses',
    'remblaverais',
    'remblaverait',
    'remblavèrent',
    'remblaveriez',
    'remblaverons',
    'remblaveront',
    'remblayaient',
    'remblayasses',
    'remblayerais',
    'remblayerait',
    'remblayèrent',
    'remblayeriez',
    'remblayerons',
    'remblayeront',
    'remboîtaient',
    'remboîtasses',
    'remboîtement',
    'remboîterais',
    'remboîterait',
    'remboîtèrent',
    'remboîteriez',
    'remboîterons',
    'remboîteront',
    'rembougeâmes',
    'rembougeasse',
    'rembougeâtes',
    'rembougerais',
    'rembougerait',
    'rembougèrent',
    'rembougeriez',
    'rembougerons',
    'rembougeront',
    'rembourrages',
    'rembourrâmes',
    'rembourrasse',
    'rembourrâtes',
    'rembourrerai',
    'rembourreras',
    'rembourrerez',
    'rembourrions',
    'rembourrures',
    'remboursable',
    'remboursâmes',
    'remboursasse',
    'remboursâtes',
    'rembourserai',
    'rembourseras',
    'rembourserez',
    'remboursions',
    'rembrunirais',
    'rembrunirait',
    'rembrunirent',
    'rembruniriez',
    'rembrunirons',
    'rembruniront',
    'rembrunisses',
    'rembrunissez',
    'rembuchaient',
    'rembuchasses',
    'rembucherais',
    'rembucherait',
    'rembuchèrent',
    'rembucheriez',
    'rembucherons',
    'rembucheront',
    'remédiassent',
    'remédiassiez',
    'remédierions',
    'remembraient',
    'remembrasses',
    'remembrement',
    'remembrerais',
    'remembrerait',
    'remembrèrent',
    'remembreriez',
    'remembrerons',
    'remembreront',
    'remémoraient',
    'remémorasses',
    'remémoration',
    'remémorerais',
    'remémorerait',
    'remémorèrent',
    'remémoreriez',
    'remémorerons',
    'remémoreront',
    'remerciaient',
    'remerciasses',
    'remerciement',
    'remercierais',
    'remercierait',
    'remercièrent',
    'remercieriez',
    'remercierons',
    'remercieront',
    'remettraient',
    'remeublaient',
    'remeublasses',
    'remeublerais',
    'remeublerait',
    'remeublèrent',
    'remeubleriez',
    'remeublerons',
    'remeubleront',
    'remilitarisa',
    'remilitarise',
    'remilitarisé',
    'réminiscence',
    'remisassions',
    'remiseraient',
    'remmaillages',
    'remmaillâmes',
    'remmaillasse',
    'remmaillâtes',
    'remmaillerai',
    'remmailleras',
    'remmaillerez',
    'remmailleuse',
    'remmaillions',
    'remmaillotai',
    'remmaillotas',
    'remmaillotât',
    'remmaillotée',
    'remmailloter',
    'remmaillotes',
    'remmaillotés',
    'remmaillotez',
    'remmanchâmes',
    'remmanchasse',
    'remmanchâtes',
    'remmancherai',
    'remmancheras',
    'remmancherez',
    'remmanchions',
    'remmenassent',
    'remmenassiez',
    'remmènerions',
    'remodelaient',
    'remodelasses',
    'remodèlerais',
    'remodèlerait',
    'remodelèrent',
    'remodèleriez',
    'remodèlerons',
    'remodèleront',
    'remontassent',
    'remontassiez',
    'remonterions',
    'remontraient',
    'remontrances',
    'remontrasses',
    'remontrerais',
    'remontrerait',
    'remontrèrent',
    'remontreriez',
    'remontrerons',
    'remontreront',
    'remordissent',
    'remordissiez',
    'remordraient',
    'remorquaient',
    'remorquasses',
    'remorquerais',
    'remorquerait',
    'remorquèrent',
    'remorqueriez',
    'remorquerons',
    'remorqueront',
    'remorqueuses',
    'remouchaient',
    'remouchasses',
    'remoucherais',
    'remoucherait',
    'remouchèrent',
    'remoucheriez',
    'remoucherons',
    'remoucheront',
    'remoudraient',
    'remouillâmes',
    'remouillasse',
    'remouillâtes',
    'remouillerai',
    'remouilleras',
    'remouillerez',
    'remouillions',
    'remoulussent',
    'remoulussiez',
    'rempaillages',
    'rempaillâmes',
    'rempaillasse',
    'rempaillâtes',
    'rempaillerai',
    'rempailleras',
    'rempaillerât',
    'rempailleurs',
    'rempailleuse',
    'rempaillions',
    'rempaquetais',
    'rempaquetait',
    'rempaquetant',
    'rempaquetées',
    'rempaquetiez',
    'rempaquetons',
    'rempaquettes',
    'remparassent',
    'remparassiez',
    'remparerions',
    'rempiétaient',
    'rempiétasses',
    'rempiéterais',
    'rempiéterait',
    'rempiétèrent',
    'rempiéteriez',
    'rempiéterons',
    'rempiéteront',
    'rempilassent',
    'rempilassiez',
    'rempilerions',
    'remplaçables',
    'remplaçaient',
    'remplaçantes',
    'remplaçasses',
    'remplacement',
    'remplacerais',
    'remplacerait',
    'remplacèrent',
    'remplaceriez',
    'remplacerons',
    'remplaceront',
    'rempliassent',
    'rempliassiez',
    'remplierions',
    'rempliraient',
    'remplissages',
    'remplisseurs',
    'remplisseuse',
    'remplissions',
    'remploierais',
    'remploierait',
    'remploieriez',
    'remploierons',
    'remploieront',
    'remployaient',
    'remployasses',
    'remployèrent',
    'remplumaient',
    'remplumasses',
    'remplumerais',
    'remplumerait',
    'remplumèrent',
    'remplumeriez',
    'remplumerons',
    'remplumeront',
    'rempochaient',
    'rempochasses',
    'rempocherais',
    'rempocherait',
    'rempochèrent',
    'rempocheriez',
    'rempocherons',
    'rempocheront',
    'rempoissonna',
    'rempoissonne',
    'rempoissonné',
    'remportaient',
    'remportasses',
    'remporterais',
    'remporterait',
    'remportèrent',
    'remporteriez',
    'remporterons',
    'remporteront',
    'rempotassent',
    'rempotassiez',
    'rempoterions',
    'rempruntâmes',
    'rempruntasse',
    'rempruntâtes',
    'remprunterai',
    'remprunteras',
    'remprunterez',
    'rempruntions',
    'remue-ménage',
    'rémunéraient',
    'rémunérasses',
    'rémunérateur',
    'rémunération',
    'rémunérerais',
    'rémunérerait',
    'rémunérèrent',
    'rémunéreriez',
    'rémunérerons',
    'rémunéreront',
    'renaclassent',
    'renaclassiez',
    'renâclerions',
    'renaissaient',
    'renaissances',
    'renaissantes',
    'renaîtraient',
    'renaquissent',
    'renaquissiez',
    'renardassent',
    'renardassiez',
    'renarderions',
    'renaudassent',
    'renaudassiez',
    'renauderions',
    'rencaissages',
    'rencaissâmes',
    'rencaissasse',
    'rencaissâtes',
    'rencaisserai',
    'rencaisseras',
    'rencaisserez',
    'rencaissions',
    'rencardasses',
    'rencarderais',
    'rencarderait',
    'rencardèrent',
    'rencarderiez',
    'rencarderons',
    'rencarderont',
    'renchaînâmes',
    'renchaînasse',
    'renchaînâtes',
    'renchaînerai',
    'renchaîneras',
    'renchaînerez',
    'renchaînions',
    'renchérirais',
    'renchérirait',
    'renchérirent',
    'renchéririez',
    'renchérirons',
    'renchériront',
    'renchérisses',
    'renchérissez',
    'rencognaient',
    'rencognasses',
    'rencognerais',
    'rencognerait',
    'rencognèrent',
    'rencogneriez',
    'rencognerons',
    'rencogneront',
    'rencontrâmes',
    'rencontrasse',
    'rencontrâtes',
    'rencontrerai',
    'rencontreras',
    'rencontrerez',
    'rencontrions',
    'rendormaient',
    'rendormirais',
    'rendormirait',
    'rendormirent',
    'rendormiriez',
    'rendormirons',
    'rendormiront',
    'rendormisses',
    'rendossaient',
    'rendossasses',
    'rendosserais',
    'rendosserait',
    'rendossèrent',
    'rendosseriez',
    'rendosserons',
    'rendosseront',
    'renégociâmes',
    'renégociasse',
    'renégociâtes',
    'renégocierai',
    'renégocieras',
    'renégocierez',
    'renégociions',
    'renfaîtaient',
    'renfaîtasses',
    'renfaîterais',
    'renfaîterait',
    'renfaîtèrent',
    'renfaîteriez',
    'renfaîterons',
    'renfaîteront',
    'renfermaient',
    'renfermasses',
    'renfermement',
    'renfermerais',
    'renfermerait',
    'renfermèrent',
    'renfermeriez',
    'renfermerons',
    'renfermeront',
    'renfilassent',
    'renfilassiez',
    'renfilerions',
    'renflammâmes',
    'renflammasse',
    'renflammâtes',
    'renflammerai',
    'renflammeras',
    'renflammerez',
    'renflammions',
    'renflassions',
    'renfleraient',
    'renflouaient',
    'renflouasses',
    'renflouement',
    'renflouerais',
    'renflouerait',
    'renflouèrent',
    'renfloueriez',
    'renflouerons',
    'renfloueront',
    'renfonçaient',
    'renfonçasses',
    'renfoncement',
    'renfoncerais',
    'renfoncerait',
    'renfoncèrent',
    'renfonceriez',
    'renfoncerons',
    'renfonceront',
    'renforçaient',
    'renforçasses',
    'renforcement',
    'renforcerais',
    'renforcerait',
    'renforcèrent',
    'renforceriez',
    'renforcerons',
    'renforceront',
    'renformissez',
    'renfrognâmes',
    'renfrognasse',
    'renfrognâtes',
    'renfrognerai',
    'renfrogneras',
    'renfrognerez',
    'renfrognions',
    'rengageaient',
    'rengageasses',
    'rengagements',
    'rengagerions',
    'rengainaient',
    'rengainasses',
    'rengainerais',
    'rengainerait',
    'rengainèrent',
    'rengaineriez',
    'rengainerons',
    'rengaineront',
    'rengorgeâmes',
    'rengorgeasse',
    'rengorgeâtes',
    'rengorgement',
    'rengorgerais',
    'rengorgerait',
    'rengorgèrent',
    'rengorgeriez',
    'rengorgerons',
    'rengorgeront',
    'rengraciâmes',
    'rengraciasse',
    'rengraciâtes',
    'rengracierai',
    'rengracieras',
    'rengracierez',
    'rengraciions',
    'rengraissais',
    'rengraissait',
    'rengraissant',
    'rengraissent',
    'rengraissera',
    'rengraissiez',
    'rengraissons',
    'rengrenaient',
    'rengrénaient',
    'rengrenasses',
    'rengrénasses',
    'rengrénerais',
    'rengrènerais',
    'rengrénerait',
    'rengrènerait',
    'rengrenèrent',
    'rengrénèrent',
    'rengréneriez',
    'rengrèneriez',
    'rengrénerons',
    'rengrènerons',
    'rengréneront',
    'rengrèneront',
    'reniflassent',
    'reniflassiez',
    'reniflements',
    'reniflerions',
    'renommassent',
    'renommassiez',
    'renommerions',
    'renonçassent',
    'renonçassiez',
    'renoncements',
    'renoncerions',
    'renonciateur',
    'renonciation',
    'renouassions',
    'renoueraient',
    'renouvelable',
    'renouvelâmes',
    'renouvelante',
    'renouvelants',
    'renouvelasse',
    'renouvelâtes',
    'renouvelions',
    'renouvellent',
    'renouvellera',
    'rénovassions',
    'rénovatrices',
    'rénoveraient',
    'renquillâmes',
    'renquillasse',
    'renquillâtes',
    'renquillerai',
    'renquilleras',
    'renquillerez',
    'renquillions',
    'renseignâmes',
    'renseignasse',
    'renseignâtes',
    'renseignerai',
    'renseigneras',
    'renseignerez',
    'renseignions',
    'rentabilisai',
    'rentabilisas',
    'rentabilisât',
    'rentabilisée',
    'rentabiliser',
    'rentabilises',
    'rentabilisés',
    'rentabilisez',
    'rentabilités',
    'rentamassent',
    'rentamassiez',
    'rentamerions',
    'rentoilaient',
    'rentoilasses',
    'rentoilerais',
    'rentoilerait',
    'rentoilèrent',
    'rentoileriez',
    'rentoilerons',
    'rentoileront',
    'rentoileuses',
    'rentrassions',
    'rentreraient',
    'rentrouverte',
    'rentrouverts',
    'rentrouvrais',
    'rentrouvrait',
    'rentrouvrant',
    'rentrouvrent',
    'rentrouvriez',
    'rentrouvrira',
    'rentrouvrons',
    'rentrouvrues',
    'renveloppais',
    'renveloppait',
    'renveloppant',
    'renveloppées',
    'renveloppera',
    'renveloppiez',
    'renveloppons',
    'renvenimâmes',
    'renvenimasse',
    'renvenimâtes',
    'renvenimerai',
    'renvenimeras',
    'renvenimerez',
    'renvenimions',
    'renvergeâmes',
    'renvergeasse',
    'renvergeâtes',
    'renvergerais',
    'renvergerait',
    'renvergèrent',
    'renvergeriez',
    'renvergerons',
    'renvergeront',
    'renverraient',
    'renversaient',
    'renversantes',
    'renversasses',
    'renversement',
    'renverserais',
    'renverserait',
    'renversèrent',
    'renverseriez',
    'renverserons',
    'renverseront',
    'renviassions',
    'renvidassent',
    'renvidassiez',
    'renviderions',
    'renvieraient',
    'renvoyassent',
    'renvoyassiez',
    'réoccupaient',
    'réoccupasses',
    'réoccupation',
    'réoccuperais',
    'réoccuperait',
    'réoccupèrent',
    'réoccuperiez',
    'réoccuperons',
    'réoccuperont',
    'réopérassent',
    'réopérassiez',
    'réopérerions',
    'réorchestrai',
    'réorchestras',
    'réorchestrât',
    'réorchestrée',
    'réorchestrer',
    'réorchestres',
    'réorchestrés',
    'réorchestrez',
    'réordonnâmes',
    'réordonnança',
    'réordonnance',
    'réordonnancé',
    'réordonnasse',
    'réordonnâtes',
    'réordonnerai',
    'réordonneras',
    'réordonnerez',
    'réordonnions',
    'réorganisais',
    'réorganisait',
    'réorganisant',
    'réorganisées',
    'réorganisent',
    'réorganisera',
    'réorganisiez',
    'réorganisons',
    'réorientâmes',
    'réorientasse',
    'réorientâtes',
    'réorienterai',
    'réorienteras',
    'réorienterez',
    'réorientions',
    'réouvertures',
    'repaieraient',
    'repairassent',
    'repairassiez',
    'repairerions',
    'repaissaient',
    'repaîtraient',
    'répandissent',
    'répandissiez',
    'répandraient',
    'reparaissais',
    'reparaissait',
    'reparaissant',
    'reparaissent',
    'reparaissiez',
    'reparaissons',
    'reparaîtrais',
    'reparaîtrait',
    'reparaîtriez',
    'reparaîtrons',
    'reparaîtront',
    'réparassions',
    'réparatrices',
    'répareraient',
    'reparlassent',
    'reparlassiez',
    'reparlerions',
    'repartageais',
    'repartageait',
    'repartageant',
    'repartageons',
    'repartagerai',
    'repartageras',
    'repartagerez',
    'repartagions',
    'répartements',
    'repartirions',
    'répartirions',
    'répartissais',
    'répartissait',
    'répartissant',
    'repartissent',
    'répartissent',
    'repartissiez',
    'répartissiez',
    'répartissons',
    'répartiteurs',
    'répartitions',
    'reparussions',
    'repassassent',
    'repassassiez',
    'repasserions',
    'repatinaient',
    'repatinasses',
    'repatinerais',
    'repatinerait',
    'repatinèrent',
    'repatineriez',
    'repatinerons',
    'repatineront',
    'repavassions',
    'repaveraient',
    'repayassions',
    'repayeraient',
    'repêchassent',
    'repêchassiez',
    'repêcherions',
    'repeignaient',
    'repeignasses',
    'repeignerais',
    'repeignerait',
    'repeignèrent',
    'repeigneriez',
    'repeignerons',
    'repeigneront',
    'repeignirent',
    'repeignisses',
    'repeindrions',
    'rependissent',
    'rependissiez',
    'rependraient',
    'repensassent',
    'repensassiez',
    'repenserions',
    'repentirions',
    'repentissent',
    'repentissiez',
    'repérassions',
    'reperçassent',
    'reperçassiez',
    'repercerions',
    'répercussion',
    'répercutâmes',
    'répercutasse',
    'répercutâtes',
    'répercuterai',
    'répercuteras',
    'répercuterez',
    'répercutions',
    'reperdissent',
    'reperdissiez',
    'reperdraient',
    'repéreraient',
    'répertoriais',
    'répertoriait',
    'répertoriant',
    'répertoriées',
    'répertorient',
    'répertoriera',
    'répertoriiez',
    'répertorions',
    'répétabilité',
    'répétassions',
    'répéteraient',
    'répétitorats',
    'répétitrices',
    'repeuplaient',
    'repeuplasses',
    'repeuplement',
    'repeuplerais',
    'repeuplerait',
    'repeuplèrent',
    'repeupleriez',
    'repeuplerons',
    'repeupleront',
    'repinçassent',
    'repinçassiez',
    'repincerions',
    'repiquassent',
    'repiquassiez',
    'repiquerions',
    'replaçassent',
    'replaçassiez',
    'replacements',
    'replacerions',
    'replantaient',
    'replantasses',
    'replanterais',
    'replanterait',
    'replantèrent',
    'replanteriez',
    'replanterons',
    'replanteront',
    'replâtraient',
    'replâtrasses',
    'replâtrerais',
    'replâtrerait',
    'replâtrèrent',
    'replâtreriez',
    'replâtrerons',
    'replâtreront',
    'repleuvaient',
    'repliassions',
    'réplications',
    'replieraient',
    'répliquaient',
    'répliquasses',
    'répliquerais',
    'répliquerait',
    'répliquèrent',
    'répliqueriez',
    'répliquerons',
    'répliqueront',
    'replissaient',
    'replissasses',
    'replisserais',
    'replisserait',
    'replissèrent',
    'replisseriez',
    'replisserons',
    'replisseront',
    'reploierions',
    'replongeâmes',
    'replongeasse',
    'replongeâtes',
    'replongerais',
    'replongerait',
    'replongèrent',
    'replongeriez',
    'replongerons',
    'replongeront',
    'reployassent',
    'reployassiez',
    'repoliraient',
    'repolissions',
    'répondissent',
    'répondissiez',
    'répondraient',
    'reportassent',
    'reportassiez',
    'reporterions',
    'reposassions',
    'repose-pieds',
    'reposeraient',
    'repositionna',
    'repositionne',
    'repositionné',
    'repoussaient',
    'repoussantes',
    'repoussasses',
    'repoussement',
    'repousserais',
    'repousserait',
    'repoussèrent',
    'repousseriez',
    'repousserons',
    'repousseront',
    'répréhension',
    'reprendrions',
    'représailles',
    'représentais',
    'représentait',
    'représentant',
    'représentées',
    'représentent',
    'représentera',
    'représentiez',
    'représentons',
    'répressibles',
    'réprimandais',
    'réprimandait',
    'réprimandant',
    'réprimandées',
    'réprimandent',
    'réprimandera',
    'réprimandiez',
    'réprimandons',
    'réprimassent',
    'réprimassiez',
    'réprimerions',
    'reprisassent',
    'reprisassiez',
    'repriserions',
    'réprobateurs',
    'réprobations',
    'réprobatrice',
    'reprochables',
    'reprochaient',
    'reprochasses',
    'reprocherais',
    'reprocherait',
    'reprochèrent',
    'reprocheriez',
    'reprocherons',
    'reprocheront',
    'reproducteur',
    'reproductifs',
    'reproduction',
    'reproductive',
    'reproduirais',
    'reproduirait',
    'reproduiriez',
    'reproduirons',
    'reproduiront',
    'reproduisais',
    'reproduisait',
    'reproduisant',
    'reproduisent',
    'reproduisiez',
    'reproduisons',
    'reprogrammai',
    'reprogrammas',
    'reprogrammât',
    'reprogrammée',
    'reprogrammer',
    'reprogrammes',
    'reprogrammés',
    'reprogrammez',
    'reprographia',
    'reprographie',
    'reprographié',
    'reprouvaient',
    'réprouvaient',
    'reprouvasses',
    'réprouvasses',
    'reprouverais',
    'réprouverais',
    'reprouverait',
    'réprouverait',
    'reprouvèrent',
    'réprouvèrent',
    'reprouveriez',
    'réprouveriez',
    'reprouverons',
    'réprouverons',
    'reprouveront',
    'réprouveront',
    'républicaine',
    'républicains',
    'répudiassent',
    'répudiassiez',
    'répudiations',
    'répudierions',
    'répugnassent',
    'répugnassiez',
    'répugnerions',
    'réputassions',
    'réputeraient',
    'requerraient',
    'requinquâmes',
    'requinquasse',
    'requinquâtes',
    'requinquerai',
    'requinqueras',
    'requinquerez',
    'requinquions',
    'réquisitions',
    'réquisitoire',
    'reroisassent',
    'reroisassiez',
    'resalassions',
    'resaleraient',
    'resaliraient',
    'resalissions',
    'resaluassent',
    'resaluassiez',
    'resaluerions',
    'rescindables',
    'rescindaient',
    'rescindantes',
    'rescindasses',
    'rescinderais',
    'rescinderait',
    'rescindèrent',
    'rescinderiez',
    'rescinderons',
    'rescinderont',
    'rescriptions',
    'reséquassent',
    'reséquassiez',
    'reséquerions',
    'réservassent',
    'réservassiez',
    'réservataire',
    'réservations',
    'réserverions',
    'résidassions',
    'résidentiels',
    'résideraient',
    'résignassent',
    'résignassiez',
    'résignataire',
    'résignations',
    'résignerions',
    'résiliassent',
    'résiliassiez',
    'résiliations',
    'résilierions',
    'résinassions',
    'résineraient',
    'résinifiâmes',
    'résinifiasse',
    'résinifiâtes',
    'résinifierai',
    'résinifieras',
    'résinifierez',
    'résinifiions',
    'résipiscence',
    'résistassent',
    'résistassiez',
    'résisterions',
    'résistivités',
    'resocialisai',
    'resocialisas',
    'resocialisât',
    'resocialisée',
    'resocialiser',
    'resocialises',
    'resocialisés',
    'resocialisez',
    'résolussions',
    'résolutoires',
    'résonnassent',
    'résonnassiez',
    'résonnerions',
    'résorbassent',
    'résorbassiez',
    'résorberions',
    'résoudraient',
    'respectables',
    'respectaient',
    'respectasses',
    'respecterais',
    'respecterait',
    'respectèrent',
    'respecteriez',
    'respecterons',
    'respecteront',
    'respectueuse',
    'respirassent',
    'respirassiez',
    'respirateurs',
    'respirations',
    'respiratoire',
    'respirerions',
    'resplendîmes',
    'resplendirai',
    'resplendiras',
    'resplendirez',
    'resplendisse',
    'resplendîtes',
    'responsables',
    'resquillages',
    'resquillâmes',
    'resquillasse',
    'resquillâtes',
    'resquillerai',
    'resquilleras',
    'resquillerez',
    'resquilleurs',
    'resquilleuse',
    'resquillions',
    'ressaierions',
    'ressaignâmes',
    'ressaignasse',
    'ressaignâtes',
    'ressaignerai',
    'ressaigneras',
    'ressaignerez',
    'ressaignions',
    'ressaisirais',
    'ressaisirait',
    'ressaisirent',
    'ressaisiriez',
    'ressaisirons',
    'ressaisiront',
    'ressaisisses',
    'ressaisissez',
    'ressassaient',
    'ressassasses',
    'ressasserais',
    'ressasserait',
    'ressassèrent',
    'ressasseriez',
    'ressasserons',
    'ressasseront',
    'ressautaient',
    'ressautasses',
    'ressauterais',
    'ressauterait',
    'ressautèrent',
    'ressauteriez',
    'ressauterons',
    'ressauteront',
    'ressayassent',
    'ressayassiez',
    'ressayerions',
    'ressemassent',
    'ressemassiez',
    'ressemblâmes',
    'ressemblance',
    'ressemblante',
    'ressemblants',
    'ressemblasse',
    'ressemblâtes',
    'ressemblerai',
    'ressembleras',
    'ressemblerez',
    'ressemblions',
    'ressemelages',
    'ressemelâmes',
    'ressemelasse',
    'ressemelâtes',
    'ressemelions',
    'ressemellent',
    'ressemellera',
    'ressèmerions',
    'ressentaient',
    'ressentiment',
    'ressentirais',
    'ressentirait',
    'ressentirent',
    'ressentiriez',
    'ressentirons',
    'ressentiront',
    'ressentisses',
    'resserraient',
    'resserrasses',
    'resserrement',
    'resserrerais',
    'resserrerait',
    'resserrèrent',
    'resserreriez',
    'resserrerons',
    'resserreront',
    'resservaient',
    'resservirais',
    'resservirait',
    'resservirent',
    'resserviriez',
    'resservirons',
    'resserviront',
    'resservisses',
    'ressortaient',
    'ressortirais',
    'ressortirait',
    'ressortirent',
    'ressortiriez',
    'ressortirons',
    'ressortiront',
    'ressortisses',
    'ressortissez',
    'ressoudaient',
    'ressoudasses',
    'ressouderais',
    'ressouderait',
    'ressoudèrent',
    'ressouderiez',
    'ressouderons',
    'ressouderont',
    'ressourçâmes',
    'ressourçasse',
    'ressourçâtes',
    'ressourcerai',
    'ressourceras',
    'ressourcerez',
    'ressourcions',
    'ressouvenais',
    'ressouvenait',
    'ressouvenant',
    'ressouveniez',
    'ressouvenons',
    'ressouvenues',
    'ressouvienne',
    'ressouvînmes',
    'ressouvinsse',
    'ressouvîntes',
    'ressuassions',
    'ressueraient',
    'ressuierions',
    'ressurgirais',
    'ressurgirait',
    'ressurgirent',
    'ressurgiriez',
    'ressurgirons',
    'ressurgiront',
    'ressurgisses',
    'ressurgissez',
    'ressuscitais',
    'ressuscitait',
    'ressuscitant',
    'ressuscitées',
    'ressuscitent',
    'ressuscitera',
    'ressuscitiez',
    'ressuscitons',
    'ressuyassent',
    'ressuyassiez',
    'restauraient',
    'restaurantes',
    'restaurasses',
    'restaurateur',
    'restauration',
    'restaurerais',
    'restaurerait',
    'restaurèrent',
    'restaureriez',
    'restaurerons',
    'restaureront',
    'restituables',
    'restituaient',
    'restituasses',
    'restituerais',
    'restituerait',
    'restituèrent',
    'restitueriez',
    'restituerons',
    'restitueront',
    'restitutions',
    'restreignais',
    'restreignait',
    'restreignant',
    'restreignent',
    'restreigniez',
    'restreignons',
    'restreindrai',
    'restreindras',
    'restreindrez',
    'restrictions',
    'restrictives',
    'restringente',
    'restringents',
    'restructurai',
    'restructuras',
    'restructurât',
    'restructurée',
    'restructurer',
    'restructures',
    'restructurés',
    'restructurez',
    'résumassions',
    'résumeraient',
    'resurchauffa',
    'resurchauffe',
    'resurchauffé',
    'resurgirions',
    'resurgissais',
    'resurgissait',
    'resurgissant',
    'resurgissent',
    'resurgissiez',
    'resurgissons',
    'résurrection',
    'rétablirions',
    'rétablissais',
    'rétablissait',
    'rétablissant',
    'rétablissent',
    'rétablissiez',
    'rétablissons',
    'retaillaient',
    'retaillasses',
    'retaillerais',
    'retaillerait',
    'retaillèrent',
    'retailleriez',
    'retaillerons',
    'retailleront',
    'rétamassions',
    'rétameraient',
    'retapassions',
    'retaperaient',
    'retapissâmes',
    'retapissasse',
    'retapissâtes',
    'retapisserai',
    'retapisseras',
    'retapisserez',
    'retapissions',
    'retardassent',
    'retardassiez',
    'retardataire',
    'retardateurs',
    'retardatrice',
    'retardements',
    'retarderions',
    'retâtassions',
    'retâteraient',
    'reteignaient',
    'reteignirent',
    'reteignisses',
    'reteindrions',
    'retendissent',
    'retendissiez',
    'retendraient',
    'retentassent',
    'retentassiez',
    'retenterions',
    'retentirions',
    'retentissais',
    'retentissait',
    'retentissant',
    'retentissent',
    'retentissiez',
    'retentissons',
    'reterçassent',
    'reterçassiez',
    'retercerions',
    'retersassent',
    'retersassiez',
    'reterserions',
    'réticulaient',
    'réticulaires',
    'réticulasses',
    'réticulation',
    'réticulerais',
    'réticulerait',
    'réticulèrent',
    'réticuleriez',
    'réticulerons',
    'réticuleront',
    'retiendrions',
    'retirassions',
    'retireraient',
    'retissassent',
    'retissassiez',
    'retisserions',
    'retombassent',
    'retombassiez',
    'retombements',
    'retomberions',
    'retondissent',
    'retondissiez',
    'retondraient',
    'retordements',
    'retordissent',
    'retordissiez',
    'retordraient',
    'rétorquaient',
    'rétorquasses',
    'rétorquerais',
    'rétorquerait',
    'rétorquèrent',
    'rétorqueriez',
    'rétorquerons',
    'rétorqueront',
    'retouchables',
    'retouchaient',
    'retouchasses',
    'retoucherais',
    'retoucherait',
    'retouchèrent',
    'retoucheriez',
    'retoucherons',
    'retoucheront',
    'retoucheuses',
    'retournaient',
    'retournasses',
    'retournement',
    'retournerais',
    'retournerait',
    'retournèrent',
    'retourneriez',
    'retournerons',
    'retourneront',
    'retraçassent',
    'retraçassiez',
    'retracerions',
    'rétractaient',
    'rétractasses',
    'rétractation',
    'rétracterais',
    'rétracterait',
    'rétractèrent',
    'rétracteriez',
    'rétracterons',
    'rétracteront',
    'rétractilité',
    'rétractrices',
    'retraduction',
    'retraduirais',
    'retraduirait',
    'retraduiriez',
    'retraduirons',
    'retraduiront',
    'retraduisais',
    'retraduisait',
    'retraduisant',
    'retraduisent',
    'retraduisiez',
    'retraduisons',
    'retrairaient',
    'retraitaient',
    'retraitantes',
    'retraitasses',
    'retraitement',
    'retraiterais',
    'retraiterait',
    'retraitèrent',
    'retraiteriez',
    'retraiterons',
    'retraiteront',
    'retranchâmes',
    'retranchasse',
    'retranchâtes',
    'retrancherai',
    'retrancheras',
    'retrancherez',
    'retranchions',
    'retranscrira',
    'retranscrire',
    'retranscrite',
    'retranscrits',
    'retranscrive',
    'retransmette',
    'retransmîmes',
    'retransmises',
    'retransmisse',
    'retransmîtes',
    'retravaillai',
    'retravaillas',
    'retravaillât',
    'retravaillée',
    'retravailler',
    'retravailles',
    'retravaillés',
    'retravaillez',
    'retraversais',
    'retraversait',
    'retraversant',
    'retraversées',
    'retraversent',
    'retraversera',
    'retraversiez',
    'retraversons',
    'rétrécirions',
    'rétrécissais',
    'rétrécissait',
    'rétrécissant',
    'rétrécissent',
    'rétrécissiez',
    'rétrécissons',
    'retrempaient',
    'retrempasses',
    'retremperais',
    'retremperait',
    'retrempèrent',
    'retremperiez',
    'retremperons',
    'retremperont',
    'rétribuaient',
    'rétribuasses',
    'rétribuerais',
    'rétribuerait',
    'rétribuèrent',
    'rétribueriez',
    'rétribuerons',
    'rétribueront',
    'rétributions',
    'rétroactions',
    'rétroactives',
    'rétroagirais',
    'rétroagirait',
    'rétroagirent',
    'rétroagiriez',
    'rétroagirons',
    'rétroagiront',
    'rétroagisses',
    'rétroagissez',
    'rétrocédâmes',
    'rétrocédasse',
    'rétrocédâtes',
    'rétrocéderai',
    'rétrocéderas',
    'rétrocéderez',
    'rétrocédions',
    'rétrocession',
    'rétrogradais',
    'rétrogradait',
    'rétrogradant',
    'rétrogradées',
    'rétrogradent',
    'rétrogradera',
    'rétrogradiez',
    'rétrogradons',
    'rétrospectif',
    'retroussages',
    'retroussâmes',
    'retroussasse',
    'retroussâtes',
    'retrousserai',
    'retrousseras',
    'retrousserez',
    'retroussions',
    'retrouvables',
    'retrouvaient',
    'retrouvasses',
    'retrouverais',
    'retrouverait',
    'retrouvèrent',
    'retrouveriez',
    'retrouverons',
    'retrouveront',
    'rétroversion',
    'rétroviseurs',
    'réunifiaient',
    'réunifiasses',
    'réunifierais',
    'réunifierait',
    'réunifièrent',
    'réunifieriez',
    'réunifierons',
    'réunifieront',
    'réunissaient',
    'réussiraient',
    'réussissions',
    'réutilisable',
    'réutilisâmes',
    'réutilisasse',
    'réutilisâtes',
    'réutiliserai',
    'réutiliseras',
    'réutiliserez',
    'réutilisions',
    'revaccinâmes',
    'revaccinasse',
    'revaccinâtes',
    'revaccinerai',
    'revaccineras',
    'revaccinerez',
    'revaccinions',
    'revalorisais',
    'revalorisait',
    'revalorisant',
    'revalorisées',
    'revalorisent',
    'revalorisera',
    'revalorisiez',
    'revalorisons',
    'revalussions',
    'revanchaient',
    'revanchardes',
    'revanchasses',
    'revancherais',
    'revancherait',
    'revanchèrent',
    'revancheriez',
    'revancherons',
    'revancheront',
    'revanchismes',
    'rêvassassent',
    'rêvassassiez',
    'rêvasserions',
    'revaudraient',
    'revécussions',
    'réveillaient',
    'réveillasses',
    'réveillerais',
    'réveillerait',
    'réveillèrent',
    'réveilleriez',
    'réveillerons',
    'réveilleront',
    'réveilleuses',
    'réveillonnai',
    'réveillonnas',
    'réveillonnât',
    'réveillonner',
    'réveillonnes',
    'réveillonnez',
    'révélassions',
    'révélatrices',
    'révéleraient',
    'revenant-bon',
    'revendicatif',
    'revendiquais',
    'revendiquait',
    'revendiquant',
    'revendiquées',
    'revendiquent',
    'revendiquera',
    'revendiquiez',
    'revendiquons',
    'revendissent',
    'revendissiez',
    'revendraient',
    'révérassions',
    'réverbérâmes',
    'réverbérante',
    'réverbérants',
    'réverbérasse',
    'réverbérâtes',
    'réverbérerai',
    'réverbéreras',
    'réverbérerez',
    'réverbérions',
    'reverchaient',
    'reverchasses',
    'revercherais',
    'revercherait',
    'reverchèrent',
    'revercheriez',
    'revercherons',
    'revercheront',
    'reverdirions',
    'reverdissais',
    'reverdissait',
    'reverdissant',
    'reverdissent',
    'reverdissiez',
    'reverdissons',
    'révérencieux',
    'révéreraient',
    'revernirions',
    'revernissais',
    'revernissait',
    'revernissant',
    'revernissent',
    'revernissiez',
    'revernissons',
    'reversassent',
    'reversassiez',
    'reverserions',
    'revêtiraient',
    'revêtissions',
    'reviendrions',
    'revigoraient',
    'revigorasses',
    'revigoration',
    'revigorerais',
    'revigorerait',
    'revigorèrent',
    'revigoreriez',
    'revigorerons',
    'revigoreront',
    'revirassions',
    'revireraient',
    'révisassions',
    'réviseraient',
    'revissassent',
    'revissassiez',
    'revisserions',
    'revitalisais',
    'revitalisait',
    'revitalisant',
    'revitalisées',
    'revitalisent',
    'revitalisera',
    'revitalisiez',
    'revitalisons',
    'revivifiâmes',
    'revivifiante',
    'revivifiants',
    'revivifiasse',
    'revivifiâtes',
    'revivifierai',
    'revivifieras',
    'revivifierez',
    'revivifiions',
    'reviviscence',
    'revolassions',
    'revoleraient',
    'révoltassent',
    'révoltassiez',
    'révolterions',
    'révolutionna',
    'révolutionne',
    'révolutionné',
    'révolvérisai',
    'révolvérisas',
    'révolvérisât',
    'révolvérisée',
    'révolvériser',
    'révolvérises',
    'révolvérisés',
    'révolvérisez',
    'révoquassent',
    'révoquassiez',
    'révoquerions',
    'revotassions',
    'revoteraient',
    'revoudraient',
    'revoulussent',
    'revoulussiez',
    'révulsassent',
    'révulsassiez',
    'révulserions',
    'rhabdomancie',
    'rhabillaient',
    'rhabillasses',
    'rhabillement',
    'rhabillerais',
    'rhabillerait',
    'rhabillèrent',
    'rhabilleriez',
    'rhabillerons',
    'rhabilleront',
    'rhabilleuses',
    'rhapsodiques',
    'rhéologiques',
    'rhéostatique',
    'rhétoriciens',
    'rhétoriqueur',
    'rhinopharynx',
    'rhinoplastie',
    'rhizocarpées',
    'rhizosphères',
    'rhodaniennes',
    'rhododendron',
    'rhomboïdales',
    'rhumatisante',
    'rhumatisants',
    'rhumatismale',
    'rhumatismaux',
    'rhumatologie',
    'rhumatologue',
    'rhynchonelle',
    'ribaudequins',
    'riboflavines',
    'ribonucléase',
    'riboulassent',
    'riboulassiez',
    'ribouldingue',
    'riboulerions',
    'ricanassions',
    'ricaneraient',
    'rickettsiose',
    'ricochassent',
    'ricochassiez',
    'ricocherions',
    'ridiculement',
    'ridiculisais',
    'ridiculisait',
    'ridiculisant',
    'ridiculisées',
    'ridiculisent',
    'ridiculisera',
    'ridiculisiez',
    'ridiculisons',
    'riffaudaient',
    'riffaudasses',
    'riffauderais',
    'riffauderait',
    'riffaudèrent',
    'riffauderiez',
    'riffauderons',
    'riffauderont',
    'rigidifiâmes',
    'rigidifiasse',
    'rigidifiâtes',
    'rigidifierai',
    'rigidifieras',
    'rigidifierez',
    'rigidifiions',
    'rigolassions',
    'rigoleraient',
    'rimaillaient',
    'rimaillasses',
    'rimaillerais',
    'rimaillerait',
    'rimaillèrent',
    'rimailleriez',
    'rimaillerons',
    'rimailleront',
    'rimailleuses',
    'rince-bouche',
    'rince-doigts',
    'ringardaient',
    'ringardasses',
    'ringarderais',
    'ringarderait',
    'ringardèrent',
    'ringarderiez',
    'ringarderons',
    'ringarderont',
    'ripaillaient',
    'ripaillasses',
    'ripaillerais',
    'ripaillerait',
    'ripaillèrent',
    'ripailleriez',
    'ripaillerons',
    'ripailleront',
    'ripailleuses',
    'ripolinaient',
    'ripolinasses',
    'ripolinerais',
    'ripolinerait',
    'ripolinèrent',
    'ripolineriez',
    'ripolinerons',
    'ripolineront',
    'ripostassent',
    'ripostassiez',
    'riposterions',
    'risquassions',
    'risqueraient',
    'rissolassent',
    'rissolassiez',
    'rissolerions',
    'ristournâmes',
    'ristournasse',
    'ristournâtes',
    'ristournerai',
    'ristourneras',
    'ristournerez',
    'ristournions',
    'ritournelles',
    'ritualisâmes',
    'ritualisasse',
    'ritualisâtes',
    'ritualiserai',
    'ritualiseras',
    'ritualiserez',
    'ritualisions',
    'rituellement',
    'rivalisaient',
    'rivalisasses',
    'rivaliserais',
    'rivaliserait',
    'rivalisèrent',
    'rivaliseriez',
    'rivaliserons',
    'rivaliseront',
    'riverainetés',
    'rivetassions',
    'rivetterions',
    'riziculteurs',
    'rizicultrice',
    'rizicultures',
    'riz-pain-sel',
    'robinetterie',
    'robotisaient',
    'robotisasses',
    'robotisation',
    'robotiserais',
    'robotiserait',
    'robotisèrent',
    'robotiseriez',
    'robotiserons',
    'robotiseront',
    'rocailleuses',
    'rochassières',
    'rocouassions',
    'rocoueraient',
    'rocquassions',
    'rocqueraient',
    'rôdaillaient',
    'rôdaillasses',
    'rôdaillerais',
    'rôdaillerait',
    'rôdaillèrent',
    'rôdailleriez',
    'rôdaillerons',
    'rôdailleront',
    'rodomontades',
    'rognonnaient',
    'rognonnasses',
    'rognonnerais',
    'rognonnerait',
    'rognonnèrent',
    'rognonneriez',
    'rognonnerons',
    'rognonneront',
    'roidissaient',
    'romançassent',
    'romançassiez',
    'romancerions',
    'roman-fleuve',
    'romanichelle',
    'romanisaient',
    'romanisasses',
    'romanisation',
    'romaniserais',
    'romaniserait',
    'romanisèrent',
    'romaniseriez',
    'romaniserons',
    'romaniseront',
    'ronchonnâmes',
    'ronchonnasse',
    'ronchonnâtes',
    'ronchonneaux',
    'ronchonnerai',
    'ronchonneras',
    'ronchonnerez',
    'ronchonneurs',
    'ronchonneuse',
    'ronchonnions',
    'rond-de-cuir',
    'rondissaient',
    'rondouillard',
    'ronds-points',
    'ronéotypâmes',
    'ronéotypasse',
    'ronéotypâtes',
    'ronéotyperai',
    'ronéotyperas',
    'ronéotyperez',
    'ronéotypions',
    'ronflaguâmes',
    'ronflaguasse',
    'ronflaguâtes',
    'ronflaguerai',
    'ronflagueras',
    'ronflaguerez',
    'ronflaguions',
    'ronflassions',
    'ronfleraient',
    'rongeassions',
    'ronronnaient',
    'ronronnasses',
    'ronronnement',
    'ronronnerais',
    'ronronnerait',
    'ronronnèrent',
    'ronronneriez',
    'ronronnerons',
    'ronronneront',
    'ronsardisais',
    'ronsardisait',
    'ronsardisant',
    'ronsardisent',
    'ronsardisera',
    'ronsardisiez',
    'ronsardisons',
    'rosicruciens',
    'rosissements',
    'rotogravures',
    'roublardises',
    'roucoulaient',
    'roucoulantes',
    'roucoulasses',
    'roucoulement',
    'roucoulerais',
    'roucoulerait',
    'roucoulèrent',
    'roucouleriez',
    'roucoulerons',
    'roucouleront',
    'rouflaquette',
    'rougeoiement',
    'rougeoierais',
    'rougeoierait',
    'rougeoieriez',
    'rougeoierons',
    'rougeoieront',
    'rougeoyaient',
    'rougeoyantes',
    'rougeoyasses',
    'rougeoyèrent',
    'rougissaient',
    'rougissantes',
    'rougissement',
    'rouillassent',
    'rouillassiez',
    'rouillerions',
    'roulottaient',
    'roulottasses',
    'roulotterais',
    'roulotterait',
    'roulottèrent',
    'roulotteriez',
    'roulotterons',
    'roulotteront',
    'roupillaient',
    'roupillasses',
    'roupillerais',
    'roupillerait',
    'roupillèrent',
    'roupilleriez',
    'roupillerons',
    'roupilleront',
    'rouscaillais',
    'rouscaillait',
    'rouscaillant',
    'rouscaillent',
    'rouscaillera',
    'rouscailliez',
    'rouscaillons',
    'rouspétaient',
    'rouspétances',
    'rouspétasses',
    'rouspéterais',
    'rouspéterait',
    'rouspétèrent',
    'rouspéteriez',
    'rouspéterons',
    'rouspéteront',
    'rouspéteuses',
    'rousserolles',
    'roussiraient',
    'roussissions',
    'roustiraient',
    'roustissions',
    'rouvriraient',
    'rouvrissions',
    'rubanassions',
    'rubaneraient',
    'rubéfactions',
    'rubéfiassent',
    'rubéfiassiez',
    'rubéfierions',
    'rubigineuses',
    'rudentassent',
    'rudentassiez',
    'rudenterions',
    'rudimentaire',
    'rudoieraient',
    'rudoyassions',
    'rugissements',
    'ruineusement',
    'ruisselaient',
    'ruisselantes',
    'ruisselasses',
    'ruisselèrent',
    'ruissellerai',
    'ruisselleras',
    'ruissellerez',
    'ruminassions',
    'rumineraient',
    'rupinassions',
    'rupineraient',
    'russifiaient',
    'russifiasses',
    'russifierais',
    'russifierait',
    'russifièrent',
    'russifieriez',
    'russifierons',
    'russifieront',
    'rustauderies',
    'rustiquaient',
    'rustiquasses',
    'rustiquement',
    'rustiquerais',
    'rustiquerait',
    'rustiquèrent',
    'rustiqueriez',
    'rustiquerons',
    'rustiqueront',
    'rutilassions',
    'rutileraient',
    'rythmassions',
    'rythmeraient',
    'sabbathienne',
    'sablonnaient',
    'sablonnasses',
    'sablonnerais',
    'sablonnerait',
    'sablonnèrent',
    'sablonneriez',
    'sablonnerons',
    'sablonneront',
    'sablonneuses',
    'sablonnières',
    'sabordassent',
    'sabordassiez',
    'sabordements',
    'saborderions',
    'sabotassions',
    'saboteraient',
    'saboulassent',
    'saboulassiez',
    'saboulerions',
    'sabraissions',
    'sacagnassent',
    'sacagnassiez',
    'sacagnerions',
    'saccadassent',
    'saccadassiez',
    'saccaderions',
    'saccageaient',
    'saccageasses',
    'saccagements',
    'saccagerions',
    'sacchareuses',
    'saccharifère',
    'saccharifiai',
    'saccharifias',
    'saccharifiât',
    'saccharifiée',
    'saccharifier',
    'saccharifies',
    'saccharifiés',
    'saccharifiez',
    'sacchariques',
    'saccharoïdes',
    'sacculiforme',
    'sacerdotales',
    'sacquassions',
    'sacqueraient',
    'sacralisâmes',
    'sacralisasse',
    'sacralisâtes',
    'sacraliserai',
    'sacraliseras',
    'sacraliserez',
    'sacralisions',
    'sacramentaux',
    'sacramentels',
    'sacrifiaient',
    'sacrifiasses',
    'sacrificiels',
    'sacrifierais',
    'sacrifierait',
    'sacrifièrent',
    'sacrifieriez',
    'sacrifierons',
    'sacrifieront',
    'sacro-sainte',
    'sacro-saints',
    'safari-photo',
    'safranassent',
    'safranassiez',
    'safranerions',
    'sages-femmes',
    'saignassions',
    'saigneraient',
    'sailliraient',
    'saillissions',
    'saint-crépin',
    'saint-cyrien',
    'saint-honoré',
    'Saint-Office',
    'saint-paulin',
    'saint-pierre',
    'saints-pères',
    'saisie-arrêt',
    'saisissables',
    'saisissaient',
    'saisissantes',
    'saisissement',
    'saisonnaient',
    'saisonnasses',
    'saisonnerais',
    'saisonnerait',
    'saisonnèrent',
    'saisonneriez',
    'saisonnerons',
    'saisonneront',
    'saisonnières',
    'salariassent',
    'salariassiez',
    'salarierions',
    'salicyliques',
    'salifiassent',
    'salifiassiez',
    'salification',
    'salifierions',
    'salivassions',
    'saliveraient',
    'salmonellose',
    'salopassions',
    'saloperaient',
    'salpêtraient',
    'salpêtrasses',
    'salpêtrerais',
    'salpêtrerait',
    'salpêtrèrent',
    'salpêtreriez',
    'salpêtrerons',
    'salpêtreront',
    'salpêtreuses',
    'salpêtrières',
    'saltimbanque',
    'samaritaines',
    'sanctifiâmes',
    'sanctifiasse',
    'sanctifiâtes',
    'sanctifierai',
    'sanctifieras',
    'sanctifierez',
    'sanctifiions',
    'sanctionnais',
    'sanctionnait',
    'sanctionnant',
    'sanctionnées',
    'sanctionnent',
    'sanctionnera',
    'sanctionniez',
    'sanctionnons',
    'sanctuarisai',
    'sanctuarisas',
    'sanctuarisât',
    'sanctuarisée',
    'sanctuariser',
    'sanctuarises',
    'sanctuarisés',
    'sanctuarisez',
    'sandwichâmes',
    'sandwichasse',
    'sandwichâtes',
    'sandwicherai',
    'sandwicheras',
    'sandwicherez',
    'sandwichions',
    'sanglassions',
    'sangleraient',
    'sanglotaient',
    'sanglotantes',
    'sanglotasses',
    'sanglotement',
    'sangloterais',
    'sangloterait',
    'sanglotèrent',
    'sangloteriez',
    'sangloterons',
    'sangloteront',
    'sanguinaires',
    'sanguinolent',
    'sanscritiste',
    'sans-culotte',
    'sans-filiste',
    'sanskritisme',
    'sanskritiste',
    'santonnaient',
    'santonnasses',
    'santonnerais',
    'santonnerait',
    'santonnèrent',
    'santonneriez',
    'santonnerons',
    'santonneront',
    'saoulassions',
    'saouleraient',
    'sapientiales',
    'saponifiable',
    'saponifiâmes',
    'saponifiasse',
    'saponifiâtes',
    'saponifierai',
    'saponifieras',
    'saponifierez',
    'saponifiions',
    'sarcastiques',
    'sarclassions',
    'sarcleraient',
    'sarcomateuse',
    'sarcoplasmas',
    'sarmentaient',
    'sarmentasses',
    'sarmenterais',
    'sarmenterait',
    'sarmentèrent',
    'sarmenteriez',
    'sarmenterons',
    'sarmenteront',
    'sarmenteuses',
    'sarrancolins',
    'satanassions',
    'sataneraient',
    'satellisâmes',
    'satellisasse',
    'satellisâtes',
    'satelliserai',
    'satelliseras',
    'satelliserez',
    'satellisions',
    'satinassions',
    'satineraient',
    'satirisaient',
    'satirisasses',
    'satiriserais',
    'satiriserait',
    'satirisèrent',
    'satiriseriez',
    'satiriserons',
    'satiriseront',
    'satisfaction',
    'satisfaisais',
    'satisfaisait',
    'satisfaisant',
    'satisfaisiez',
    'satisfaisons',
    'satisfassent',
    'satisfassiez',
    'satisferions',
    'satisfissent',
    'satisfissiez',
    'satonnassent',
    'satonnassiez',
    'satonnerions',
    'saturabilité',
    'saturassions',
    'satureraient',
    'saturniennes',
    'saucissonnai',
    'saucissonnas',
    'saucissonnât',
    'saucissonnée',
    'saucissonner',
    'saucissonnes',
    'saucissonnés',
    'saucissonnez',
    'sauf-conduit',
    'saumurassent',
    'saumurassiez',
    'saumurerions',
    'saupoudrages',
    'saupoudrâmes',
    'saupoudrasse',
    'saupoudrâtes',
    'saupoudrerai',
    'saupoudreras',
    'saupoudrerez',
    'saupoudreurs',
    'saupoudreuse',
    'saupoudrions',
    'saupoudroirs',
    'saurissaient',
    'saurisseries',
    'saut-de-loup',
    'saute-mouton',
    'sautillaient',
    'sautillantes',
    'sautillasses',
    'sautillement',
    'sautillerais',
    'sautillerait',
    'sautillèrent',
    'sautilleriez',
    'sautillerons',
    'sautilleront',
    'sauts-de-lit',
    'sauvageonnes',
    'sauvegardais',
    'sauvegardait',
    'sauvegardant',
    'sauvegardées',
    'sauvegardent',
    'sauvegardera',
    'sauvegardiez',
    'sauvegardons',
    'savoir-faire',
    'savoir-vivre',
    'savonnassent',
    'savonnassiez',
    'savonnerions',
    'savourassent',
    'savourassiez',
    'savourerions',
    'saxophoniste',
    'scalpassions',
    'scalperaient',
    'scandaleuses',
    'scandalisais',
    'scandalisait',
    'scandalisant',
    'scandalisées',
    'scandalisent',
    'scandalisera',
    'scandalisiez',
    'scandalisons',
    'scandassions',
    'scanderaient',
    'scaphandrier',
    'scatologique',
    'scélératesse',
    'scellassions',
    'scelleraient',
    'scéniquement',
    'scénographie',
    'scepticismes',
    'scheidassent',
    'scheidassiez',
    'scheiderions',
    'schématiques',
    'schématisais',
    'schématisait',
    'schématisant',
    'schématisées',
    'schématisent',
    'schématisera',
    'schématisiez',
    'schématismes',
    'schématisons',
    'schismatique',
    'schizogamies',
    'schizogenèse',
    'schizophrène',
    'schizothymie',
    'schlittaient',
    'schlittasses',
    'schlitterais',
    'schlitterait',
    'schlittèrent',
    'schlitteriez',
    'schlitterons',
    'schlitteront',
    'scialytiques',
    'scientifique',
    'scientologie',
    'scientologue',
    'scindassions',
    'scinderaient',
    'scintillâmes',
    'scintillante',
    'scintillants',
    'scintillasse',
    'scintillâtes',
    'scintillerai',
    'scintilleras',
    'scintillerât',
    'scintillions',
    'sciottassent',
    'sciottassiez',
    'sciotterions',
    'scissionnais',
    'scissionnait',
    'scissionnant',
    'scissionnent',
    'scissionnera',
    'scissionniez',
    'scissionnons',
    'scissiparité',
    'sclérosaient',
    'sclérosantes',
    'sclérosasses',
    'scléroserais',
    'scléroserait',
    'sclérosèrent',
    'scléroseriez',
    'scléroserons',
    'scléroseront',
    'sclérotiques',
    'scolairement',
    'scolarisâmes',
    'scolarisasse',
    'scolarisâtes',
    'scolariserai',
    'scolariseras',
    'scolariserez',
    'scolarisions',
    'scolasticats',
    'scolastiques',
    'scolopendres',
    'scootéristes',
    'scopolamines',
    'scorbutiques',
    'scotchassent',
    'scotchassiez',
    'scotcherions',
    'scotomisâmes',
    'scotomisasse',
    'scotomisâtes',
    'scotomiserai',
    'scotomiseras',
    'scotomiserez',
    'scotomisions',
    'scratchaient',
    'scratchasses',
    'scratcherais',
    'scratcherait',
    'scratchèrent',
    'scratcheriez',
    'scratcherons',
    'scratcheront',
    'scribouillai',
    'scribouillas',
    'scribouillât',
    'scribouillée',
    'scribouiller',
    'scribouilles',
    'scribouillés',
    'scribouillez',
    'script-girls',
    'scripturaire',
    'scripturales',
    'scrofulaires',
    'scrofuleuses',
    'scrogneugneu',
    'scrupuleuses',
    'scrutassions',
    'scrutatrices',
    'scruteraient',
    'sculptassent',
    'sculptassiez',
    'sculpterions',
    'sculpturales',
    'scutellaires',
    'secondarités',
    'secondassent',
    'secondassiez',
    'seconderions',
    'secouassions',
    'secoueraient',
    'secourraient',
    'secourussent',
    'secourussiez',
    'secrétariats',
    'sécrétassent',
    'sécrétassiez',
    'sécréterions',
    'sectionnâmes',
    'sectionnasse',
    'sectionnâtes',
    'sectionnerai',
    'sectionneras',
    'sectionnerez',
    'sectionneurs',
    'sectionnions',
    'sectorielles',
    'sectorisâmes',
    'sectorisasse',
    'sectorisâtes',
    'sectoriserai',
    'sectoriseras',
    'sectoriserez',
    'sectorisions',
    'sécularisais',
    'sécularisait',
    'sécularisant',
    'sécularisées',
    'sécularisent',
    'sécularisera',
    'sécularisiez',
    'sécularisons',
    'sécurisaient',
    'sécurisasses',
    'sécurisation',
    'sécuriserais',
    'sécuriserait',
    'sécurisèrent',
    'sécuriseriez',
    'sécuriserons',
    'sécuriseront',
    'sécuritaires',
    'sédentarisai',
    'sédentarisas',
    'sédentarisât',
    'sédentarisée',
    'sédentariser',
    'sédentarises',
    'sédentarisés',
    'sédentarisez',
    'sédentarités',
    'sédimentaire',
    'sédimentâmes',
    'sédimentasse',
    'sédimentâtes',
    'sédimenterai',
    'sédimenteras',
    'sédimenterez',
    'sédimentions',
    'séduisissent',
    'séduisissiez',
    'segmentaient',
    'segmentasses',
    'segmentation',
    'segmenterais',
    'segmenterait',
    'segmentèrent',
    'segmenteriez',
    'segmenterons',
    'segmenteront',
    'ségrégations',
    'ségrégatives',
    'seigneuriage',
    'seigneuriale',
    'seigneuriaux',
    'séismographe',
    'seizièmement',
    'seiziémismes',
    'séjournaient',
    'séjournasses',
    'séjournerais',
    'séjournerait',
    'séjournèrent',
    'séjourneriez',
    'séjournerons',
    'séjourneront',
    'sélaginelles',
    'sélectionnai',
    'sélectionnas',
    'sélectionnât',
    'sélectionnée',
    'sélectionner',
    'sélectionnes',
    'sélectionnés',
    'sélectionnez',
    'sélectivités',
    'séléniteuses',
    'self-control',
    'self-service',
    'sémanticiens',
    'sémaphorique',
    'sémasiologie',
    'semblassions',
    'sembleraient',
    'séméiologies',
    'séméiotiques',
    'semestrielle',
    'semi-lunaire',
    'séminaristes',
    'semi-nomades',
    'sémiologique',
    'sémioticiens',
    'semi-ouvrées',
    'semi-produit',
    'semi-publics',
    'semi-rigides',
    'semi-voyelle',
    'semonçassent',
    'semonçassiez',
    'semoncerions',
    'sempiternels',
    'sénatoriales',
    'sénéchaussée',
    'sénégalaises',
    'senestrorsum',
    'sensationnel',
    'sensibilisai',
    'sensibilisas',
    'sensibilisât',
    'sensibilisée',
    'sensibiliser',
    'sensibilises',
    'sensibilisés',
    'sensibilisez',
    'sensibilités',
    'sensiblement',
    'sensibleries',
    'sensorielles',
    'sensualismes',
    'sensualistes',
    'sentencieuse',
    'sentimentale',
    'sentimentaux',
    'séparassions',
    'séparatismes',
    'séparatistes',
    'séparatrices',
    'sépareraient',
    'septennalité',
    'septicémique',
    'septièmement',
    'septuagésime',
    'septuplaient',
    'septuplasses',
    'septuplerais',
    'septuplerait',
    'septuplèrent',
    'septupleriez',
    'septuplerons',
    'septupleront',
    'séquentielle',
    'séquestrâmes',
    'séquestrasse',
    'séquestrâtes',
    'séquestrerai',
    'séquestreras',
    'séquestrerez',
    'séquestrions',
    'seraient-ils',
    'sérançassent',
    'sérançassiez',
    'sérancerions',
    'sérénissimes',
    'serfouirions',
    'serfouissage',
    'serfouissais',
    'serfouissait',
    'serfouissant',
    'serfouissent',
    'serfouissiez',
    'serfouissons',
    'sérialisâmes',
    'sérialisasse',
    'sérialisâtes',
    'sérialiserai',
    'sérialiseras',
    'sérialiserez',
    'sérialisions',
    'sérieusement',
    'sérigraphies',
    'serinassions',
    'serineraient',
    'seringuaient',
    'seringuasses',
    'seringuerais',
    'seringuerait',
    'seringuèrent',
    'seringueriez',
    'seringuerons',
    'seringueront',
    'serions-nous',
    'sermonnaient',
    'sermonnaires',
    'sermonnasses',
    'sermonnerais',
    'sermonnerait',
    'sermonnèrent',
    'sermonneriez',
    'sermonnerons',
    'sermonneront',
    'sermonneuses',
    'seront-elles',
    'sérothérapie',
    'serpentaient',
    'serpentaires',
    'serpentasses',
    'serpentement',
    'serpenterais',
    'serpenterait',
    'serpentèrent',
    'serpenteriez',
    'serpenterons',
    'serpenteront',
    'serpigineuse',
    'serpillières',
    'serre-freins',
    'serre-joints',
    'serre-livres',
    'sertissaient',
    'sertisseuses',
    'serviabilité',
    'servomoteurs',
    'sesquialtère',
    'sesquioxydes',
    'sexagénaires',
    'sexagésimale',
    'sexagésimaux',
    'sextuplaient',
    'sextuplasses',
    'sextuplerais',
    'sextuplerait',
    'sextuplèrent',
    'sextupleriez',
    'sextuplerons',
    'sextupleront',
    'sexualisâmes',
    'sexualisasse',
    'sexualisâtes',
    'sexualiserai',
    'sexualiseras',
    'sexualiserez',
    'sexualisions',
    'sexuellement',
    'shampooineur',
    'shampooingna',
    'shampooingne',
    'shampooingné',
    'shampouinais',
    'shampouinait',
    'shampouinant',
    'shampouinées',
    'shampouinent',
    'shampouinera',
    'shampouineur',
    'shampouiniez',
    'shampouinons',
    'shipchandler',
    'shootassions',
    'shooteraient',
    'shuntassions',
    'shunteraient',
    'sidérassions',
    'sidéreraient',
    'sidéroxylons',
    'sidérurgique',
    'sidérurgiste',
    'siégeassions',
    'sifflassions',
    'siffleraient',
    'sifflotaient',
    'sifflotasses',
    'sifflotement',
    'siffloterais',
    'siffloterait',
    'sifflotèrent',
    'siffloteriez',
    'siffloterons',
    'siffloteront',
    'signalassent',
    'signalassiez',
    'signalements',
    'signalerions',
    'signalétique',
    'signalisâmes',
    'signalisasse',
    'signalisâtes',
    'signaliserai',
    'signaliseras',
    'signaliserez',
    'signalisions',
    'signifiaient',
    'signifiances',
    'signifiantes',
    'signifiasses',
    'significatif',
    'signifierais',
    'signifierait',
    'signifièrent',
    'signifieriez',
    'signifierons',
    'signifieront',
    'silencieuses',
    'silhouettais',
    'silhouettait',
    'silhouettant',
    'silhouettées',
    'silhouettent',
    'silhouettera',
    'silhouettiez',
    'silhouettons',
    'silicatisais',
    'silicatisait',
    'silicatisant',
    'silicatisées',
    'silicatisent',
    'silicatisera',
    'silicatisiez',
    'silicatisons',
    'siliconaient',
    'siliconasses',
    'siliconerais',
    'siliconerait',
    'siliconèrent',
    'siliconeriez',
    'siliconerons',
    'siliconeront',
    'sillonnaient',
    'sillonnasses',
    'sillonnerais',
    'sillonnerait',
    'sillonnèrent',
    'sillonneriez',
    'sillonnerons',
    'sillonneront',
    'similisaient',
    'similisasses',
    'similiserais',
    'similiserait',
    'similisèrent',
    'similiseriez',
    'similiserons',
    'similiseront',
    'simplifiable',
    'simplifiâmes',
    'simplifiasse',
    'simplifiâtes',
    'simplifierai',
    'simplifieras',
    'simplifierez',
    'simplifiions',
    'simulassions',
    'simulatrices',
    'simuleraient',
    'simultanéité',
    'sinanthropes',
    'sincipitales',
    'singeassions',
    'singularisai',
    'singularisas',
    'singularisât',
    'singularisée',
    'singulariser',
    'singularises',
    'singularisés',
    'singularisez',
    'singularités',
    'sinisassions',
    'siniseraient',
    'sinistrement',
    'sintérisâmes',
    'sintérisasse',
    'sintérisâtes',
    'sintériserai',
    'sintériseras',
    'sintériserez',
    'sintérisions',
    'sinusoïdales',
    'siphonnaient',
    'siphonnasses',
    'siphonnerais',
    'siphonnerait',
    'siphonnèrent',
    'siphonneriez',
    'siphonnerons',
    'siphonneront',
    'sirotassions',
    'siroteraient',
    'sismographes',
    'sister-ships',
    'slalomassent',
    'slalomassiez',
    'slalomerions',
    'slavisassent',
    'slavisassiez',
    'slaviserions',
    'smashassions',
    'smasheraient',
    'smillassions',
    'smilleraient',
    'smithsonites',
    'sniffassions',
    'snifferaient',
    'sociabilités',
    'socialisâmes',
    'socialisante',
    'socialisants',
    'socialisasse',
    'socialisâtes',
    'socialiserai',
    'socialiseras',
    'socialiserez',
    'socialisions',
    'sociétariats',
    'socinianisme',
    'sociogrammes',
    'sociologique',
    'sociologisme',
    'socratisâmes',
    'socratisasse',
    'socratisâtes',
    'socratiserai',
    'socratiseras',
    'socratiserez',
    'socratisions',
    'sodomisaient',
    'sodomisasses',
    'sodomiserais',
    'sodomiserait',
    'sodomisèrent',
    'sodomiseriez',
    'sodomiserons',
    'sodomiseront',
    'soignassions',
    'soigneraient',
    'soixantaines',
    'soixante-dix',
    'soixante-six',
    'soixantièmes',
    'solarigraphe',
    'solarisaient',
    'solarisasses',
    'solariserais',
    'solariserait',
    'solarisèrent',
    'solariseriez',
    'solariserons',
    'solariseront',
    'soldatesques',
    'solennisâmes',
    'solennisasse',
    'solennisâtes',
    'solenniserai',
    'solenniseras',
    'solenniserez',
    'solennisions',
    'solénoïdales',
    'solfiassions',
    'solfieraient',
    'solidarisais',
    'solidarisait',
    'solidarisant',
    'solidarisées',
    'solidarisent',
    'solidarisera',
    'solidarisiez',
    'solidarisons',
    'solidifiâmes',
    'solidifiasse',
    'solidifiâtes',
    'solidifierai',
    'solidifieras',
    'solidifierez',
    'solidifiions',
    'solifluaient',
    'solifluasses',
    'solifluerais',
    'solifluerait',
    'solifluèrent',
    'soliflueriez',
    'solifluerons',
    'soliflueront',
    'solifluxions',
    'soliloquâmes',
    'soliloquasse',
    'soliloquâtes',
    'soliloquerai',
    'soliloqueras',
    'soliloquerez',
    'soliloquions',
    'sollicitâmes',
    'sollicitasse',
    'sollicitâtes',
    'solliciterai',
    'solliciteras',
    'solliciterez',
    'solliciteurs',
    'solliciteuse',
    'sollicitions',
    'sollicitudes',
    'solmisassent',
    'solmisassiez',
    'solmisations',
    'solmiserions',
    'solsticiales',
    'solubilisais',
    'solubilisait',
    'solubilisant',
    'solubilisées',
    'solubilisent',
    'solubilisera',
    'solubilisiez',
    'solubilisons',
    'solutionnais',
    'solutionnait',
    'solutionnant',
    'solutionnées',
    'solutionnent',
    'solutionnera',
    'solutionniez',
    'solutionnons',
    'solutréennes',
    'solvabilités',
    'somatisaient',
    'somatisasses',
    'somatisation',
    'somatiserais',
    'somatiserait',
    'somatisèrent',
    'somatiseriez',
    'somatiserons',
    'somatiseront',
    'somatotropes',
    'sombrassions',
    'sombreraient',
    'sommairement',
    'sommeillâmes',
    'sommeillasse',
    'sommeillâtes',
    'sommeillerai',
    'sommeilleras',
    'sommeillerez',
    'sommeilleuse',
    'sommeillions',
    'sommelleries',
    'somnolassent',
    'somnolassiez',
    'somnolerions',
    'somptuosités',
    'songeassions',
    'sonnaillâmes',
    'sonnaillasse',
    'sonnaillâtes',
    'sonnaillerai',
    'sonnailleras',
    'sonnaillerez',
    'sonnaillions',
    'sonorisaient',
    'sonorisasses',
    'sonorisation',
    'sonoriserais',
    'sonoriserait',
    'sonorisèrent',
    'sonoriseriez',
    'sonoriserons',
    'sonoriseront',
    'sophistiquai',
    'sophistiquas',
    'sophistiquât',
    'sophistiquée',
    'sophistiquer',
    'sophistiques',
    'sophistiqués',
    'sophistiquez',
    'sophrologies',
    'soporifiques',
    'sorbonnardes',
    'sorcelleries',
    'sorguassions',
    'sorgueraient',
    'soubassement',
    'soubresautai',
    'soubresautas',
    'soubresautât',
    'soubresauter',
    'soubresautes',
    'soubresautez',
    'soubrevestes',
    'souchetaient',
    'souchetasses',
    'souchetèrent',
    'souchetterai',
    'souchetteras',
    'souchetterez',
    'souchevaient',
    'souchevasses',
    'souchèverais',
    'souchèverait',
    'souchevèrent',
    'souchèveriez',
    'souchèverons',
    'souchèveront',
    'souciassions',
    'soucieraient',
    'soudainement',
    'soudoierions',
    'soudoyassent',
    'soudoyassiez',
    'soufflassent',
    'soufflassiez',
    'soufflements',
    'soufflerions',
    'souffletâmes',
    'souffletasse',
    'souffletâtes',
    'souffletiers',
    'souffletions',
    'soufflettent',
    'soufflettera',
    'souffreteuse',
    'souffririons',
    'souffrissent',
    'souffrissiez',
    'soufrassions',
    'soufreraient',
    'souhaitables',
    'souhaitaient',
    'souhaitasses',
    'souhaiterais',
    'souhaiterait',
    'souhaitèrent',
    'souhaiteriez',
    'souhaiterons',
    'souhaiteront',
    'souillassent',
    'souillassiez',
    'souillerions',
    'soulageaient',
    'soulageasses',
    'soulagements',
    'soulagerions',
    'soulevassent',
    'soulevassiez',
    'soulèvements',
    'soulèverions',
    'soulignaient',
    'soulignasses',
    'soulignement',
    'soulignerais',
    'soulignerait',
    'soulignèrent',
    'souligneriez',
    'soulignerons',
    'souligneront',
    'soûlographes',
    'soûlographie',
    'soumettaient',
    'soumettrions',
    'soumissionna',
    'soumissionne',
    'soumissionné',
    'soupçonnable',
    'soupçonnâmes',
    'soupçonnasse',
    'soupçonnâtes',
    'soupçonnerai',
    'soupçonneras',
    'soupçonnerez',
    'soupçonneuse',
    'soupçonnions',
    'soupesassent',
    'soupesassiez',
    'soupèserions',
    'soupirassent',
    'soupirassiez',
    'soupirerions',
    'souquassions',
    'souquenilles',
    'souqueraient',
    'sourcilières',
    'sourcillâmes',
    'sourcillasse',
    'sourcillâtes',
    'sourcillerai',
    'sourcilleras',
    'sourcillerez',
    'sourcilleuse',
    'sourcillions',
    'sourdinaient',
    'sourdinasses',
    'sourdinerais',
    'sourdinerait',
    'sourdinèrent',
    'sourdineriez',
    'sourdinerons',
    'sourdineront',
    'sourds-muets',
    'sournoiserie',
    'sous-calibré',
    'sous-classes',
    'sous-clavier',
    'sous-comptes',
    'sous-couches',
    'souscripteur',
    'souscription',
    'souscririons',
    'souscrivîmes',
    'souscrivions',
    'souscrivisse',
    'souscrivîtes',
    'sous-cutanée',
    'sous-cutanés',
    'sous-diacres',
    'sous-économe',
    'sous-emplois',
    'sous-entende',
    'sous-entends',
    'sous-entendu',
    'sous-équipée',
    'sous-estimai',
    'sous-estimas',
    'sous-estimât',
    'sous-estimée',
    'sous-estimer',
    'sous-estimes',
    'sous-estimés',
    'sous-estimez',
    'sous-évaluai',
    'sous-évaluas',
    'sous-évaluât',
    'sous-évaluée',
    'sous-évaluer',
    'sous-évalues',
    'sous-évalués',
    'sous-évaluez',
    'sous-exposai',
    'sous-exposas',
    'sous-exposât',
    'sous-exposée',
    'sous-exposer',
    'sous-exposes',
    'sous-exposés',
    'sous-exposez',
    'sous-filiale',
    'sous-jacente',
    'sous-jacents',
    'sous-louâmes',
    'sous-louasse',
    'sous-louâtes',
    'sous-louerai',
    'sous-loueras',
    'sous-louerez',
    'sous-louions',
    'sous-maîtres',
    'sous-normale',
    'sous-oeuvres',
    'sous-paierai',
    'sous-paieras',
    'sous-paierez',
    'sous-payâmes',
    'sous-payasse',
    'sous-payâtes',
    'sous-payerai',
    'sous-payeras',
    'sous-payerez',
    'sous-payions',
    'sous-peuplée',
    'sous-peuplés',
    'sous-préfète',
    'sous-préfets',
    'sous-produit',
    'sous-pubiens',
    'sous-réseaux',
    'sous-routine',
    'sous-section',
    'sous-station',
    'sous-système',
    'sous-tendais',
    'sous-tendait',
    'sous-tendant',
    'sous-tendent',
    'sous-tendiez',
    'sous-tendons',
    'sous-tendrai',
    'sous-tendras',
    'sous-tendrez',
    'sous-tendues',
    'sous-tension',
    'sous-titrage',
    'sous-titrais',
    'sous-titrait',
    'sous-titrant',
    'sous-titrées',
    'sous-titrent',
    'sous-titrera',
    'sous-titriez',
    'sous-titrons',
    'soustractifs',
    'soustraction',
    'soustractive',
    'soustrairais',
    'soustrairait',
    'soustrairiez',
    'soustrairons',
    'soustrairont',
    'sous-traitai',
    'sous-traitas',
    'sous-traitât',
    'sous-traitée',
    'sous-traiter',
    'sous-traites',
    'sous-traités',
    'sous-traitez',
    'soustrayions',
    'sous-utilisa',
    'sous-utilise',
    'sous-utilisé',
    'sous-virâmes',
    'sous-virasse',
    'sous-virâtes',
    'sous-virerai',
    'sous-vireras',
    'sous-virerez',
    'sous-virions',
    'soutachaient',
    'soutachasses',
    'soutacherais',
    'soutacherait',
    'soutachèrent',
    'soutacheriez',
    'soutacherons',
    'soutacheront',
    'soutènements',
    'souterraines',
    'soutiendrais',
    'soutiendrait',
    'soutiendriez',
    'soutiendrons',
    'soutiendront',
    'soutinssions',
    'soutirassent',
    'soutirassiez',
    'soutirerions',
    'souveraineté',
    'souviendrais',
    'souviendrait',
    'souviendriez',
    'souviendrons',
    'souviendront',
    'souvinssions',
    'soviétisâmes',
    'soviétisasse',
    'soviétisâtes',
    'soviétiserai',
    'soviétiseras',
    'soviétiserez',
    'soviétisions',
    'spartakismes',
    'spartakistes',
    'spasmodiques',
    'spathifiâmes',
    'spathifiasse',
    'spathifiâtes',
    'spathifierai',
    'spathifieras',
    'spathifierez',
    'spathifiions',
    'spatialisais',
    'spatialisait',
    'spatialisant',
    'spatialisées',
    'spatialisent',
    'spatialisera',
    'spatialisiez',
    'spatialisons',
    'spationautes',
    'spécialement',
    'spécialisais',
    'spécialisait',
    'spécialisant',
    'spécialisées',
    'spécialisent',
    'spécialisera',
    'spécialisiez',
    'spécialisons',
    'spécialistes',
    'spécifiaient',
    'spécifiasses',
    'spécificités',
    'spécifierais',
    'spécifierait',
    'spécifièrent',
    'spécifieriez',
    'spécifierons',
    'spécifieront',
    'spectatrices',
    'spectroscope',
    'spéculassent',
    'spéculassiez',
    'spéculateurs',
    'spéculations',
    'spéculatives',
    'spéculatrice',
    'spéculerions',
    'spéléologies',
    'spéléologues',
    'spéléonautes',
    'spermatiques',
    'spermatocyte',
    'spermogonies',
    'spermogramme',
    'spermophiles',
    'sphacélaient',
    'sphacélasses',
    'sphacélerais',
    'sphacélerait',
    'sphacélèrent',
    'sphacéleriez',
    'sphacélerons',
    'sphacéleront',
    'sphénoïdales',
    'sphéroïdales',
    'sphéromètres',
    'sphinctérien',
    'spina-bifida',
    'spiritualisa',
    'spiritualise',
    'spiritualisé',
    'spiritualité',
    'spirituelles',
    'spiritueuses',
    'spirochétose',
    'spirographes',
    'spittassions',
    'spitteraient',
    'splanchnique',
    'spleenétique',
    'splittassent',
    'splittassiez',
    'splitterions',
    'spoliassions',
    'spoliatrices',
    'spolieraient',
    'spongiosités',
    'sponsorisais',
    'sponsorisait',
    'sponsorisant',
    'sponsorisées',
    'sponsorisent',
    'sponsorisera',
    'sponsorisiez',
    'sponsorisons',
    'spontanéisme',
    'spontanéités',
    'spontanément',
    'spontanismes',
    'sporadicités',
    'sporotriches',
    'sportivement',
    'sporulassent',
    'sporulassiez',
    'sporulerions',
    'sprintassent',
    'sprintassiez',
    'sprinterions',
    'squattassent',
    'squattassiez',
    'squatterions',
    'squattérisai',
    'squattérisas',
    'squattérisât',
    'squattérisée',
    'squattériser',
    'squattérises',
    'squattérisés',
    'squattérisez',
    'squelettique',
    'squirrheuses',
    'stabilimètre',
    'stabilisâmes',
    'stabilisante',
    'stabilisants',
    'stabilisasse',
    'stabilisâtes',
    'stabiliserai',
    'stabiliseras',
    'stabiliserez',
    'stabilisions',
    'stabulations',
    'staffassions',
    'stafferaient',
    'stagflations',
    'stagnassions',
    'stagneraient',
    'staliniennes',
    'stalinisâmes',
    'stalinisasse',
    'stalinisâtes',
    'staliniserai',
    'staliniseras',
    'staliniserez',
    'stalinisions',
    'staminifères',
    'standardisai',
    'standardisas',
    'standardisât',
    'standardisée',
    'standardiser',
    'standardises',
    'standardisés',
    'standardisez',
    'standardiste',
    'starifiaient',
    'starifiasses',
    'starifierais',
    'starifierait',
    'starifièrent',
    'starifieriez',
    'starifierons',
    'starifieront',
    'starisassent',
    'starisassiez',
    'stariserions',
    'stathoudérat',
    'stationnaire',
    'stationnâmes',
    'stationnasse',
    'stationnâtes',
    'stationnerai',
    'stationneras',
    'stationnerez',
    'stationnions',
    'statiquement',
    'statisticien',
    'statistiques',
    'statthalters',
    'statuassions',
    'statueraient',
    'statufiaient',
    'statufiasses',
    'statufierais',
    'statufierait',
    'statufièrent',
    'statufieriez',
    'statufierons',
    'statufieront',
    'stéarineries',
    'stencilistes',
    'stendhaliens',
    'sténodactylo',
    'sténogrammes',
    'sténographes',
    'sténographia',
    'sténographie',
    'sténographié',
    'sténotypâmes',
    'sténotypasse',
    'sténotypâtes',
    'sténotyperai',
    'sténotyperas',
    'sténotyperez',
    'sténotypions',
    'sténotypiste',
    'stercoraires',
    'stéréochimie',
    'stéréognosie',
    'stéréogramme',
    'stéréométrie',
    'stéréophonie',
    'stéréoscopes',
    'stéréoscopie',
    'stéréotomies',
    'stéréotypais',
    'stéréotypait',
    'stéréotypant',
    'stéréotypées',
    'stéréotypent',
    'stéréotypera',
    'stéréotypies',
    'stéréotypiez',
    'stéréotypons',
    'stérilisâmes',
    'stérilisante',
    'stérilisants',
    'stérilisasse',
    'stérilisâtes',
    'stériliserai',
    'stériliseras',
    'stériliserez',
    'stérilisions',
    'sternutation',
    'stertoreuses',
    'stéthoscopes',
    'stichomythie',
    'stigmatiques',
    'stigmatisais',
    'stigmatisait',
    'stigmatisant',
    'stigmatisées',
    'stigmatisent',
    'stigmatisera',
    'stigmatisiez',
    'stigmatismes',
    'stigmatisons',
    'stilligoutte',
    'stimulassent',
    'stimulassiez',
    'stimulateurs',
    'stimulations',
    'stimulatrice',
    'stimulerions',
    'stipendiaire',
    'stipendiâmes',
    'stipendiasse',
    'stipendiâtes',
    'stipendierai',
    'stipendieras',
    'stipendierez',
    'stipendiions',
    'stipulassent',
    'stipulassiez',
    'stipulations',
    'stipulerions',
    'stochastique',
    'stockassions',
    'stockeraient',
    'stolonifères',
    'stomachiques',
    'stomatologie',
    'stomatologue',
    'stomatoscope',
    'stoppassions',
    'stopperaient',
    'stradivarius',
    'strangulâmes',
    'strangulasse',
    'strangulâtes',
    'strangulerai',
    'stranguleras',
    'strangulerez',
    'strangulions',
    'stratégiques',
    'stratifiâmes',
    'stratifiasse',
    'stratifiâtes',
    'stratifierai',
    'stratifieras',
    'stratifierez',
    'stratifiions',
    'stratopauses',
    'stratosphère',
    'stratovision',
    'streptocoque',
    'stressassent',
    'stressassiez',
    'stresserions',
    'stridulaient',
    'stridulantes',
    'stridulasses',
    'stridulation',
    'stridulerais',
    'stridulerait',
    'stridulèrent',
    'striduleriez',
    'stridulerons',
    'striduleront',
    'striduleuses',
    'strioscopies',
    'strippassent',
    'strippassiez',
    'stripperions',
    'strip-teases',
    'striquassent',
    'striquassiez',
    'striquerions',
    'stroboscopes',
    'stroboscopie',
    'stromboliens',
    'strongyloses',
    'strophantine',
    'structurable',
    'structurales',
    'structurâmes',
    'structurante',
    'structurants',
    'structurasse',
    'structurâtes',
    'structurelle',
    'structurerai',
    'structureras',
    'structurerez',
    'structurions',
    'stupéfaction',
    'stupéfiaient',
    'stupéfiantes',
    'stupéfiasses',
    'stupéfierais',
    'stupéfierait',
    'stupéfièrent',
    'stupéfieriez',
    'stupéfierons',
    'stupéfieront',
    'stuquassions',
    'stuqueraient',
    'stylisassent',
    'stylisassiez',
    'stylisations',
    'styliserions',
    'stylisticien',
    'stylistiques',
    'stylographes',
    'subaquatique',
    'subarctiques',
    'subatomiques',
    'subconscient',
    'subdéléguais',
    'subdéléguait',
    'subdéléguant',
    'subdéléguées',
    'subdélèguent',
    'subdéléguera',
    'subdéléguiez',
    'subdéléguons',
    'subdivisâmes',
    'subdivisasse',
    'subdivisâtes',
    'subdiviserai',
    'subdiviseras',
    'subdiviserez',
    'subdivisions',
    'subintrantes',
    'subjectivité',
    'subjuguaient',
    'subjuguasses',
    'subjuguerais',
    'subjuguerait',
    'subjuguèrent',
    'subjugueriez',
    'subjuguerons',
    'subjugueront',
    'sublimassent',
    'sublimassiez',
    'sublimations',
    'sublimerions',
    'subliminaire',
    'subliminales',
    'sublinguales',
    'submergeâmes',
    'submergeasse',
    'submergeâtes',
    'submergerais',
    'submergerait',
    'submergèrent',
    'submergeriez',
    'submergerons',
    'submergeront',
    'submersibles',
    'subodoraient',
    'subodorasses',
    'subodorerais',
    'subodorerait',
    'subodorèrent',
    'subodoreriez',
    'subodorerons',
    'subodoreront',
    'subordonnais',
    'subordonnait',
    'subordonnant',
    'subordonnées',
    'subordonnent',
    'subordonnera',
    'subordonniez',
    'subordonnons',
    'subornassent',
    'subornassiez',
    'subornations',
    'subornerions',
    'subrécargues',
    'subrogateurs',
    'subrogations',
    'subrogatives',
    'subrogatoire',
    'subrogeaient',
    'subrogeasses',
    'subrogerions',
    'subséquentes',
    'subsidiaires',
    'subsistaient',
    'subsistances',
    'subsistantes',
    'subsistasses',
    'subsisterais',
    'subsisterait',
    'subsistèrent',
    'subsisteriez',
    'subsisterons',
    'subsisteront',
    'substantiels',
    'substantivai',
    'substantivas',
    'substantivât',
    'substantivée',
    'substantiver',
    'substantives',
    'substantivés',
    'substantivez',
    'substituâmes',
    'substituasse',
    'substituâtes',
    'substituerai',
    'substitueras',
    'substituerez',
    'substituions',
    'substitutifs',
    'substitution',
    'substitutive',
    'subtilisâmes',
    'subtilisasse',
    'subtilisâtes',
    'subtiliserai',
    'subtiliseras',
    'subtiliserez',
    'subtilisions',
    'subtropicale',
    'subtropicaux',
    'suburbicaire',
    'subventionna',
    'subventionne',
    'subventionné',
    'subvertirais',
    'subvertirait',
    'subvertirent',
    'subvertiriez',
    'subvertirons',
    'subvertiront',
    'subvertisses',
    'subvertissez',
    'subviendrais',
    'subviendrait',
    'subviendriez',
    'subviendrons',
    'subviendront',
    'subvinssions',
    'succédassent',
    'succédassiez',
    'succéderions',
    'succenturiée',
    'succenturiés',
    'successibles',
    'successorale',
    'successoraux',
    'succombaient',
    'succombasses',
    'succomberais',
    'succomberait',
    'succombèrent',
    'succomberiez',
    'succomberons',
    'succomberont',
    'suçotassions',
    'suçoteraient',
    'sud-africain',
    'sud-coréenne',
    'sudorifiques',
    'suffisamment',
    'suffixassent',
    'suffixassiez',
    'suffixations',
    'suffixerions',
    'suffocations',
    'suffoquaient',
    'suffoquasses',
    'suffoquerais',
    'suffoquerait',
    'suffoquèrent',
    'suffoqueriez',
    'suffoquerons',
    'suffoqueront',
    'suffragantes',
    'suffragettes',
    'suggérassent',
    'suggérassiez',
    'suggérerions',
    'suggestibles',
    'suggestionna',
    'suggestionne',
    'suggestionné',
    'suicidassent',
    'suicidassiez',
    'suiciderions',
    'suiffassions',
    'suifferaient',
    'suintassions',
    'suinteraient',
    'sulfatassent',
    'sulfatassiez',
    'sulfaterions',
    'sulfitassent',
    'sulfitassiez',
    'sulfiterions',
    'sulfonassent',
    'sulfonassiez',
    'sulfonerions',
    'sulfovinique',
    'sulfurassent',
    'sulfurassiez',
    'sulfurations',
    'sulfurerions',
    'sulpiciennes',
    'supercheries',
    'superciments',
    'superficiels',
    'superfinîmes',
    'superfinirai',
    'superfiniras',
    'superfinirez',
    'superfinisse',
    'superfinîtes',
    'superfluides',
    'superfluités',
    'super-grands',
    'supériorités',
    'supermalloys',
    'supermarchés',
    'superovariée',
    'superovariés',
    'superposable',
    'superposâmes',
    'superposasse',
    'superposâtes',
    'superposerai',
    'superposeras',
    'superposerez',
    'superposions',
    'superpréfets',
    'superprofits',
    'supersonique',
    'superstition',
    'supertankers',
    'supervisâmes',
    'supervisasse',
    'supervisâtes',
    'superviserai',
    'superviseras',
    'superviserez',
    'superviseurs',
    'superviseuse',
    'supervisions',
    'superwelters',
    'supplantâmes',
    'supplantasse',
    'supplantâtes',
    'supplanterai',
    'supplanteras',
    'supplanterez',
    'supplantions',
    'suppléassent',
    'suppléassiez',
    'suppléerions',
    'supplémentai',
    'supplémentas',
    'supplémentât',
    'supplémentée',
    'supplémenter',
    'supplémentes',
    'supplémentés',
    'supplémentez',
    'supplétoires',
    'suppliassent',
    'suppliassiez',
    'supplication',
    'suppliciâmes',
    'suppliciasse',
    'suppliciâtes',
    'supplicierai',
    'supplicieras',
    'supplicierez',
    'suppliciions',
    'supplierions',
    'supportables',
    'supportaient',
    'supportasses',
    'supporterais',
    'supporterait',
    'supportèrent',
    'supporteriez',
    'supporterons',
    'supporteront',
    'supposassent',
    'supposassiez',
    'supposerions',
    'suppositions',
    'suppositoire',
    'suppressions',
    'supprimables',
    'supprimaient',
    'supprimasses',
    'supprimerais',
    'supprimerait',
    'supprimèrent',
    'supprimeriez',
    'supprimerons',
    'supprimeront',
    'suppurassent',
    'suppurassiez',
    'suppurations',
    'suppuratives',
    'suppurerions',
    'supputassent',
    'supputassiez',
    'supputations',
    'supputerions',
    'surabondâmes',
    'surabondance',
    'surabondante',
    'surabondants',
    'surabondasse',
    'surabondâtes',
    'surabonderai',
    'surabonderas',
    'surabonderez',
    'surabondions',
    'suractivités',
    'surajoutâmes',
    'surajoutasse',
    'surajoutâtes',
    'surajouterai',
    'surajouteras',
    'surajouterez',
    'surajoutions',
    'suralimentai',
    'suralimentas',
    'suralimentât',
    'suralimentée',
    'suralimenter',
    'suralimentes',
    'suralimentés',
    'suralimentez',
    'surarmements',
    'surbaissâmes',
    'surbaissasse',
    'surbaissâtes',
    'surbaisserai',
    'surbaisseras',
    'surbaisserez',
    'surbaissions',
    'surcapacités',
    'surchargeais',
    'surchargeait',
    'surchargeant',
    'surchargeons',
    'surchargerai',
    'surchargeras',
    'surchargerez',
    'surchargions',
    'surchauffais',
    'surchauffait',
    'surchauffant',
    'surchauffées',
    'surchauffent',
    'surchauffera',
    'surchauffeur',
    'surchauffiez',
    'surchauffons',
    'surclassâmes',
    'surclassasse',
    'surclassâtes',
    'surclasserai',
    'surclasseras',
    'surclasserez',
    'surclassions',
    'surcomposées',
    'surcomprimai',
    'surcomprimas',
    'surcomprimât',
    'surcomprimée',
    'surcomprimer',
    'surcomprimes',
    'surcomprimés',
    'surcomprimez',
    'surcontrâmes',
    'surcontrasse',
    'surcontrâtes',
    'surcontrerai',
    'surcontreras',
    'surcontrerez',
    'surcontrions',
    'surcoupaient',
    'surcoupasses',
    'surcouperais',
    'surcouperait',
    'surcoupèrent',
    'surcouperiez',
    'surcouperons',
    'surcouperont',
    'surdéterminé',
    'surdéveloppé',
    'surdi-mutité',
    'surdorassent',
    'surdorassiez',
    'surdorerions',
    'surédifiâmes',
    'surédifiasse',
    'surédifiâtes',
    'surédifierai',
    'surédifieras',
    'surédifierez',
    'surédifiions',
    'surélevaient',
    'surélevasses',
    'surélévation',
    'surélèverais',
    'surélèverait',
    'surélevèrent',
    'surélèveriez',
    'surélèverons',
    'surélèveront',
    'surenchérira',
    'surencombrée',
    'surencombrés',
    'surentraînai',
    'surentraînas',
    'surentraînât',
    'surentraînée',
    'surentraîner',
    'surentraînes',
    'surentraînés',
    'surentraînez',
    'suréquipâmes',
    'suréquipasse',
    'suréquipâtes',
    'suréquiperai',
    'suréquiperas',
    'suréquiperez',
    'suréquipions',
    'surérogation',
    'surestimâmes',
    'surestimasse',
    'surestimâtes',
    'surestimerai',
    'surestimeras',
    'surestimerez',
    'surestimions',
    'surévaluâmes',
    'surévaluasse',
    'surévaluâtes',
    'surévaluerai',
    'surévalueras',
    'surévaluerez',
    'surévaluions',
    'surexcitâmes',
    'surexcitante',
    'surexcitants',
    'surexcitasse',
    'surexcitâtes',
    'surexciterai',
    'surexciteras',
    'surexciterez',
    'surexcitions',
    'surexploitai',
    'surexploitas',
    'surexploitât',
    'surexploitée',
    'surexploiter',
    'surexploites',
    'surexploités',
    'surexploitez',
    'surexposâmes',
    'surexposasse',
    'surexposâtes',
    'surexposerai',
    'surexposeras',
    'surexposerez',
    'surexposions',
    'surfaçassent',
    'surfaçassiez',
    'surfacerions',
    'surfaisaient',
    'surfilassent',
    'surfilassiez',
    'surfilerions',
    'surgelassent',
    'surgelassiez',
    'surgélateurs',
    'surgélations',
    'surgèlerions',
    'surgeonnâmes',
    'surgeonnasse',
    'surgeonnâtes',
    'surgeonnerai',
    'surgeonneras',
    'surgeonnerez',
    'surgeonnions',
    'surgissaient',
    'surgissement',
    'surglaçaient',
    'surglaçasses',
    'surglacerais',
    'surglacerait',
    'surglacèrent',
    'surglaceriez',
    'surglacerons',
    'surglaceront',
    'surhaussâmes',
    'surhaussasse',
    'surhaussâtes',
    'surhausserai',
    'surhausseras',
    'surhausserez',
    'surhaussions',
    'surhumanités',
    'surimposâmes',
    'surimposasse',
    'surimposâtes',
    'surimposerai',
    'surimposeras',
    'surimposerez',
    'surimposions',
    'surinassions',
    'surineraient',
    'surinfection',
    'surintendant',
    'surintensité',
    'surjalassent',
    'surjalassiez',
    'surjalerions',
    'surjetassent',
    'surjetassiez',
    'surjetterais',
    'surjetterait',
    'surjetteriez',
    'surjetterons',
    'surjetteront',
    'sur-le-champ',
    'surlendemain',
    'surliassions',
    'surlieraient',
    'surlouassent',
    'surlouassiez',
    'surlouerions',
    'surmenassent',
    'surmenassiez',
    'surmènerions',
    'surmontables',
    'surmontaient',
    'surmontasses',
    'surmonterais',
    'surmonterait',
    'surmontèrent',
    'surmonteriez',
    'surmonterons',
    'surmonteront',
    'surmortalité',
    'surmoulaient',
    'surmoulasses',
    'surmoulerais',
    'surmoulerait',
    'surmoulèrent',
    'surmouleriez',
    'surmoulerons',
    'surmouleront',
    'surmultiplié',
    'surnageaient',
    'surnageasses',
    'surnagerions',
    'surnatalités',
    'surnaturelle',
    'surnommaient',
    'surnommasses',
    'surnommerais',
    'surnommerait',
    'surnommèrent',
    'surnommeriez',
    'surnommerons',
    'surnommeront',
    'surnuméraire',
    'suroxydaient',
    'suroxydasses',
    'suroxyderais',
    'suroxyderait',
    'suroxydèrent',
    'suroxyderiez',
    'suroxyderons',
    'suroxyderont',
    'suroxygénées',
    'surpaierions',
    'surpassaient',
    'surpassasses',
    'surpassement',
    'surpasserais',
    'surpasserait',
    'surpassèrent',
    'surpasseriez',
    'surpasserons',
    'surpasseront',
    'surpayassent',
    'surpayassiez',
    'surpayerions',
    'surpiquaient',
    'surpiquasses',
    'surpiquerais',
    'surpiquerait',
    'surpiquèrent',
    'surpiqueriez',
    'surpiquerons',
    'surpiqueront',
    'surplombâmes',
    'surplombante',
    'surplombants',
    'surplombasse',
    'surplombâtes',
    'surplomberai',
    'surplomberas',
    'surplomberez',
    'surplombions',
    'surprenaient',
    'surprenantes',
    'surprendrais',
    'surprendrait',
    'surprendriez',
    'surprendrons',
    'surprendront',
    'surpressions',
    'surprissions',
    'surprotégeai',
    'surprotégeas',
    'surprotégeât',
    'surprotégées',
    'surprotègent',
    'surprotégera',
    'surprotégiez',
    'surpuissante',
    'surpuissants',
    'surréalismes',
    'surréalistes',
    'sursaturâmes',
    'sursaturasse',
    'sursaturâtes',
    'sursaturerai',
    'sursatureras',
    'sursaturerez',
    'sursaturions',
    'sursautaient',
    'sursautasses',
    'sursauterais',
    'sursauterait',
    'sursautèrent',
    'sursauteriez',
    'sursauterons',
    'sursauteront',
    'sursemassent',
    'sursemassiez',
    'sursèmerions',
    'surseoirions',
    'surtaxassent',
    'surtaxassiez',
    'surtaxerions',
    'survécussent',
    'survécussiez',
    'surveillâmes',
    'surveillance',
    'surveillante',
    'surveillants',
    'surveillasse',
    'surveillâtes',
    'surveillerai',
    'surveilleras',
    'surveillerez',
    'surveillions',
    'survêtements',
    'surviendrais',
    'surviendrait',
    'surviendriez',
    'surviendrons',
    'surviendront',
    'survinssions',
    'survirassent',
    'survirassiez',
    'survirerions',
    'survivraient',
    'survolassent',
    'survolassiez',
    'survolerions',
    'survoltaient',
    'survoltasses',
    'survolterais',
    'survolterait',
    'survoltèrent',
    'survolteriez',
    'survolterons',
    'survolteront',
    'susceptibles',
    'suscitassent',
    'suscitassiez',
    'susciterions',
    'suscriptions',
    'sus-dénommée',
    'sus-dénommés',
    'susmentionné',
    'suspectaient',
    'suspectasses',
    'suspecterais',
    'suspecterait',
    'suspectèrent',
    'suspecteriez',
    'suspecterons',
    'suspecteront',
    'suspendaient',
    'suspendirent',
    'suspendisses',
    'suspendrions',
    'suspicieuses',
    'sustentaient',
    'sustentasses',
    'sustentateur',
    'sustentation',
    'sustenterais',
    'sustenterait',
    'sustentèrent',
    'sustenteriez',
    'sustenterons',
    'sustenteront',
    'susurrassent',
    'susurrassiez',
    'susurrements',
    'susurrerions',
    'suturassions',
    'sutureraient',
    'suzerainetés',
    'sweat-shirts',
    'swinguassent',
    'swinguassiez',
    'swinguerions',
    'sybaritiques',
    'sybaritismes',
    'syllabassent',
    'syllabassiez',
    'syllabations',
    'syllaberions',
    'sylviculteur',
    'sylviculture',
    'symbiotiques',
    'symbolisâmes',
    'symbolisasse',
    'symbolisâtes',
    'symboliserai',
    'symboliseras',
    'symboliserez',
    'symbolisions',
    'symétrisâmes',
    'symétrisasse',
    'symétrisâtes',
    'symétriserai',
    'symétriseras',
    'symétriserez',
    'symétrisions',
    'sympathiques',
    'sympathisais',
    'sympathisait',
    'sympathisant',
    'sympathisent',
    'sympathisera',
    'sympathisiez',
    'sympathisons',
    'symphoniques',
    'symphonistes',
    'synanthérées',
    'synarthroses',
    'synchondrose',
    'synchronique',
    'synchronisai',
    'synchronisas',
    'synchronisât',
    'synchronisée',
    'synchroniser',
    'synchronises',
    'synchronisés',
    'synchronisez',
    'synchronisme',
    'synchrotrons',
    'syncopassent',
    'syncopassiez',
    'syncoperions',
    'syncrétismes',
    'syndicalisai',
    'syndicalisas',
    'syndicalisât',
    'syndicalisée',
    'syndicaliser',
    'syndicalises',
    'syndicalisés',
    'syndicalisez',
    'syndicalisme',
    'syndicaliste',
    'syndicataire',
    'syndiquaient',
    'syndiquasses',
    'syndiquerais',
    'syndiquerait',
    'syndiquèrent',
    'syndiqueriez',
    'syndiquerons',
    'syndiqueront',
    'synesthésies',
    'synonymiques',
    'syntacticien',
    'syntactiques',
    'synthétiques',
    'synthétisais',
    'synthétisait',
    'synthétisant',
    'synthétisées',
    'synthétisent',
    'synthétisera',
    'synthétiseur',
    'synthétisiez',
    'synthétisons',
    'syntonisâmes',
    'syntonisasse',
    'syntonisâtes',
    'syntoniserai',
    'syntoniseras',
    'syntoniserez',
    'syntonisions',
    'syphilitique',
    'systématique',
    'systématisai',
    'systématisas',
    'systématisât',
    'systématisée',
    'systématiser',
    'systématises',
    'systématisés',
    'systématisez',
    'tabacomanies',
    'tabaculteurs',
    'tabacultrice',
    'tabassassent',
    'tabassassiez',
    'tabasserions',
    'tabletteries',
    'tabulassions',
    'tabulatrices',
    'tabuleraient',
    'tachéographe',
    'tachéomètres',
    'tachéométrie',
    'tachetassent',
    'tachetassiez',
    'tachetterais',
    'tachetterait',
    'tachetteriez',
    'tachetterons',
    'tachetteront',
    'tachycardies',
    'tachygenèses',
    'tachygraphes',
    'tachyphémies',
    'taciturnités',
    'tacticiennes',
    'tactiquement',
    'tailladaient',
    'tailladasses',
    'tailladerais',
    'tailladerait',
    'tailladèrent',
    'tailladeriez',
    'tailladerons',
    'tailladeront',
    'taillanderie',
    'taillandiers',
    'taillassions',
    'taille-douce',
    'tailleraient',
    'talentueuses',
    'talismanique',
    'talochassent',
    'talochassiez',
    'talocherions',
    'talonnassent',
    'talonnassiez',
    'talonnements',
    'talonnerions',
    'talquassions',
    'talqueraient',
    'tambourinage',
    'tambourinais',
    'tambourinait',
    'tambourinant',
    'tambourinées',
    'tambourinent',
    'tambourinera',
    'tambourineur',
    'tambouriniez',
    'tambourinons',
    'tamisassions',
    'tamiseraient',
    'tamponnaient',
    'tamponnasses',
    'tamponnement',
    'tamponnerais',
    'tamponnerait',
    'tamponnèrent',
    'tamponneriez',
    'tamponnerons',
    'tamponneront',
    'tamponneuses',
    'tangibilités',
    'tangiblement',
    'tanguassions',
    'tangueraient',
    'tanisassions',
    'taniseraient',
    'tannisassent',
    'tannisassiez',
    'tanniserions',
    'tapageassent',
    'tapageassiez',
    'tapageraient',
    'taphophilies',
    'tapinassions',
    'tapineraient',
    'tapis-brosse',
    'tapissassent',
    'tapissassiez',
    'tapisserions',
    'taponnassent',
    'taponnassiez',
    'taponnerions',
    'tapotassions',
    'tapoteraient',
    'taquinassent',
    'taquinassiez',
    'taquinerions',
    'tarabiscotai',
    'tarabiscotas',
    'tarabiscotât',
    'tarabiscotée',
    'tarabiscoter',
    'tarabiscotes',
    'tarabiscotés',
    'tarabiscotez',
    'tarabustâmes',
    'tarabustasse',
    'tarabustâtes',
    'tarabusterai',
    'tarabusteras',
    'tarabusterez',
    'tarabustions',
    'taraudassent',
    'taraudassiez',
    'tarauderions',
    'tardillonnes',
    'targuassions',
    'targueraient',
    'tarifassions',
    'tariferaient',
    'tarifiassent',
    'tarifiassiez',
    'tarification',
    'tarifierions',
    'tarissements',
    'tarsectomies',
    'tartignolles',
    'tartinassent',
    'tartinassiez',
    'tartinerions',
    'tartissaient',
    'tartufferies',
    'tatillonnais',
    'tatillonnait',
    'tatillonnant',
    'tatillonnent',
    'tatillonnera',
    'tatillonniez',
    'tatillonnons',
    'tâtonnassent',
    'tâtonnassiez',
    'tâtonnements',
    'tâtonnerions',
    'tatouassions',
    'tatoueraient',
    'tauromachies',
    'tautochrones',
    'tavelassions',
    'tavellassent',
    'tavellassiez',
    'tavellerions',
    'taxidermiste',
    'taxinomiques',
    'taxinomistes',
    'taxonomiques',
    'taxonomistes',
    'taylorisâmes',
    'taylorisasse',
    'taylorisâtes',
    'tayloriserai',
    'tayloriseras',
    'tayloriserez',
    'taylorisions',
    'tchernozioms',
    'technicienne',
    'technicisais',
    'technicisait',
    'technicisant',
    'technicisées',
    'technicisent',
    'technicisera',
    'technicisiez',
    'technicisons',
    'technisaient',
    'technisasses',
    'techniserais',
    'techniserait',
    'technisèrent',
    'techniseriez',
    'techniserons',
    'techniseront',
    'technocrates',
    'technocratie',
    'technologies',
    'technologues',
    'technophiles',
    'tégumentaire',
    'teignissions',
    'teillassions',
    'teilleraient',
    'teintassions',
    'teinteraient',
    'teintureries',
    'teinturières',
    'télécommanda',
    'télécommande',
    'télécommandé',
    'télécopiâmes',
    'télécopiasse',
    'télécopiâtes',
    'télécopierai',
    'télécopieras',
    'télécopierez',
    'télécopieurs',
    'télécopiions',
    'télédiffusai',
    'télédiffusas',
    'télédiffusât',
    'télédiffusée',
    'télédiffuser',
    'télédiffuses',
    'télédiffusés',
    'télédiffusez',
    'téléfériques',
    'télégéniques',
    'télégestions',
    'télégraphiai',
    'télégraphias',
    'télégraphiât',
    'télégraphiée',
    'télégraphier',
    'télégraphies',
    'télégraphiés',
    'télégraphiez',
    'téléguidages',
    'téléguidâmes',
    'téléguidasse',
    'téléguidâtes',
    'téléguiderai',
    'téléguideras',
    'téléguiderez',
    'téléguidions',
    'télékinésies',
    'télématiques',
    'télémétrâmes',
    'télémétrasse',
    'télémétrâtes',
    'télémétrerai',
    'télémétreras',
    'télémétrerez',
    'télémétreurs',
    'télémétrions',
    'télémétrique',
    'télencéphale',
    'téléobjectif',
    'téléologique',
    'télépathique',
    'téléphérages',
    'téléphérique',
    'téléphonages',
    'téléphonâmes',
    'téléphonasse',
    'téléphonâtes',
    'téléphonerai',
    'téléphoneras',
    'téléphonerez',
    'téléphonions',
    'téléphonique',
    'téléphoniste',
    'télépointage',
    'télescopages',
    'télescopâmes',
    'télescopasse',
    'télescopâtes',
    'télescoperai',
    'télescoperas',
    'télescoperez',
    'télescopions',
    'télescopique',
    'télesthésies',
    'télétoxiques',
    'télétravails',
    'télévisaient',
    'télévisasses',
    'téléviserais',
    'téléviserait',
    'télévisèrent',
    'téléviseriez',
    'téléviserons',
    'téléviseront',
    'télévisuelle',
    'télexassions',
    'télexeraient',
    'telluriennes',
    'télolécithes',
    'témoignaient',
    'témoignasses',
    'témoignerais',
    'témoignerait',
    'témoignèrent',
    'témoigneriez',
    'témoignerons',
    'témoigneront',
    'tempéraments',
    'tempérassent',
    'tempérassiez',
    'températures',
    'tempérerions',
    'tempêtassent',
    'tempêtassiez',
    'tempêterions',
    'tempétueuses',
    'temporalités',
    'temporisâmes',
    'temporisante',
    'temporisants',
    'temporisasse',
    'temporisâtes',
    'temporiserai',
    'temporiseras',
    'temporiserez',
    'temporisions',
    'tenaillaient',
    'tenaillasses',
    'tenaillement',
    'tenaillerais',
    'tenaillerait',
    'tenaillèrent',
    'tenailleriez',
    'tenaillerons',
    'tenailleront',
    'tendancielle',
    'tendancieuse',
    'teneurmètres',
    'tennistiques',
    'tenonnassent',
    'tenonnassiez',
    'tenonnerions',
    'ténorisaient',
    'ténorisasses',
    'ténoriserais',
    'ténoriserait',
    'ténorisèrent',
    'ténoriseriez',
    'ténoriserons',
    'ténoriseront',
    'tensioactifs',
    'tensioactive',
    'tensiomètres',
    'tentaculaire',
    'tentes-abris',
    'tératogenèse',
    'tératogénies',
    'tératologies',
    'tératologues',
    'térébenthine',
    'térébratules',
    'tergiversais',
    'tergiversait',
    'tergiversant',
    'tergiversent',
    'tergiversera',
    'tergiversiez',
    'tergiversons',
    'termaillages',
    'terminaisons',
    'terminassent',
    'terminassiez',
    'terminerions',
    'terminologie',
    'terminologue',
    'ternissaient',
    'ternissantes',
    'ternissement',
    'terrafungine',
    'terrassaient',
    'terrassasses',
    'terrassement',
    'terrasserais',
    'terrasserait',
    'terrassèrent',
    'terrasseriez',
    'terrasserons',
    'terrasseront',
    'terreautages',
    'terreautâmes',
    'terreautasse',
    'terreautâtes',
    'terreauterai',
    'terreauteras',
    'terreauterez',
    'terreautions',
    'terre-neuvas',
    'terre-pleins',
    'terriblement',
    'terrifiaient',
    'terrifiantes',
    'terrifiasses',
    'terrifierais',
    'terrifierait',
    'terrifièrent',
    'terrifieriez',
    'terrifierons',
    'terrifieront',
    'terrissaient',
    'territoriale',
    'territoriaux',
    'terrorisâmes',
    'terrorisante',
    'terrorisants',
    'terrorisasse',
    'terrorisâtes',
    'terroriserai',
    'terroriseras',
    'terroriserez',
    'terrorisions',
    'testabilités',
    'testiculaire',
    'testimoniale',
    'testimoniaux',
    'testostérone',
    'tétanisaient',
    'tétanisantes',
    'tétanisasses',
    'tétanisation',
    'tétaniserais',
    'tétaniserait',
    'tétanisèrent',
    'tétaniseriez',
    'tétaniserons',
    'tétaniseront',
    'tête-à-queue',
    'tête-de-clou',
    'tête-de-loup',
    'tête-de-mort',
    'tétracycline',
    'tétradactyle',
    'tétraphonies',
    'tétraplégies',
    'tétraploïdes',
    'tétraploïdie',
    'tétrasyllabe',
    'tétratomique',
    'texturassent',
    'texturassiez',
    'texturerions',
    'texturisâmes',
    'texturisasse',
    'texturisâtes',
    'texturiserai',
    'texturiseras',
    'texturiserez',
    'texturisions',
    'thaïlandaise',
    'thalassémies',
    'thalidomides',
    'thanatologie',
    'thaumaturges',
    'théâtralisai',
    'théâtralisas',
    'théâtralisât',
    'théâtralisée',
    'théâtraliser',
    'théâtralises',
    'théâtralisés',
    'théâtralisez',
    'théâtralisme',
    'théâtralités',
    'thématisâmes',
    'thématisasse',
    'thématisâtes',
    'thématiserai',
    'thématiseras',
    'thématiserez',
    'thématisions',
    'théobromines',
    'théocratique',
    'théogoniques',
    'théologienne',
    'théologiques',
    'théophylline',
    'théorétiques',
    'théoricienne',
    'théorisaient',
    'théorisasses',
    'théorisation',
    'théoriserais',
    'théoriserait',
    'théorisèrent',
    'théoriseriez',
    'théoriserons',
    'théoriseront',
    'thermalismes',
    'thermisteurs',
    'thermochimie',
    'thermocouple',
    'thermolabile',
    'thermomètres',
    'thermométrie',
    'thermopompes',
    'thermoscopes',
    'thermosiphon',
    'thermosphère',
    'thermostable',
    'thésaurisais',
    'thésaurisait',
    'thésaurisant',
    'thésaurisées',
    'thésaurisent',
    'thésaurisera',
    'thésauriseur',
    'thésaurisiez',
    'thésaurisons',
    'thesmothètes',
    'thoracentèse',
    'thrombocytes',
    'thuriféraire',
    'thyréotropes',
    'thyroïdienne',
    'thyroïdismes',
    'tictaquaient',
    'tictaquasses',
    'tictaquerais',
    'tictaquerait',
    'tictaquèrent',
    'tictaqueriez',
    'tictaquerons',
    'tictaqueront',
    'tiédissaient',
    'tiédissantes',
    'tiédissement',
    'tierçassions',
    'tierceraient',
    'tiers-points',
    'timbrassions',
    'timbre-poste',
    'timbreraient',
    'tinctoriales',
    'tintinnabula',
    'tintinnabule',
    'tintinnabulé',
    'tiraillaient',
    'tiraillantes',
    'tiraillasses',
    'tiraillement',
    'tiraillerais',
    'tiraillerait',
    'tiraillèrent',
    'tirailleries',
    'tirailleriez',
    'tiraillerons',
    'tirailleront',
    'tire-bouchon',
    'tire-boutons',
    'tire-veilles',
    'tisonnassent',
    'tisonnassiez',
    'tisonnerions',
    'tissu-éponge',
    'titillassent',
    'titillassiez',
    'titillations',
    'titillerions',
    'titubassions',
    'tituberaient',
    'titularisais',
    'titularisait',
    'titularisant',
    'titularisées',
    'titularisent',
    'titularisera',
    'titularisiez',
    'titularisons',
    'toastassions',
    'toasteraient',
    'toilettaient',
    'toilettasses',
    'toiletterais',
    'toiletterait',
    'toilettèrent',
    'toiletteriez',
    'toiletterons',
    'toiletteront',
    'tokhariennes',
    'tolérantisme',
    'tolérassions',
    'toléreraient',
    'tonifiassent',
    'tonifiassiez',
    'tonifierions',
    'tonitruaient',
    'tonitruantes',
    'tonitruasses',
    'tonitruerais',
    'tonitruerait',
    'tonitruèrent',
    'tonitrueriez',
    'tonitruerons',
    'tonitrueront',
    'tonnelleries',
    'tonsurassent',
    'tonsurassiez',
    'tonsurerions',
    'tontinassent',
    'tontinassiez',
    'tontinerions',
    'topicalisais',
    'topicalisait',
    'topicalisant',
    'topicalisées',
    'topicalisent',
    'topicalisera',
    'topicalisiez',
    'topicalisons',
    'topinambours',
    'topographies',
    'toponymiques',
    'torchassions',
    'torcheraient',
    'torchonnâmes',
    'torchonnasse',
    'torchonnâtes',
    'torchonnerai',
    'torchonneras',
    'torchonnerez',
    'torchonnions',
    'tormentilles',
    'toronnassent',
    'toronnassiez',
    'toronnerions',
    'torpillaient',
    'torpillasses',
    'torpillerais',
    'torpillerait',
    'torpillèrent',
    'torpilleries',
    'torpilleriez',
    'torpillerons',
    'torpilleront',
    'torréfacteur',
    'torréfaction',
    'torréfiaient',
    'torréfiasses',
    'torréfierais',
    'torréfierait',
    'torréfièrent',
    'torréfieriez',
    'torréfierons',
    'torréfieront',
    'torrentielle',
    'torrentueuse',
    'torsadassent',
    'torsadassiez',
    'torsaderions',
    'tortillaient',
    'tortillasses',
    'tortillement',
    'tortillerais',
    'tortillerait',
    'tortillèrent',
    'tortilleriez',
    'tortillerons',
    'tortilleront',
    'tortionnaire',
    'tortorassent',
    'tortorassiez',
    'tortorerions',
    'torturassent',
    'torturassiez',
    'torturerions',
    'totalisaient',
    'totalisasses',
    'totalisateur',
    'totalisation',
    'totaliserais',
    'totaliserait',
    'totalisèrent',
    'totaliseriez',
    'totaliserons',
    'totaliseront',
    'totalitaires',
    'touchassions',
    'toucheraient',
    'touillassent',
    'touillassiez',
    'touillerions',
    'toupillaient',
    'toupillasses',
    'toupillerais',
    'toupillerait',
    'toupillèrent',
    'toupilleriez',
    'toupillerons',
    'toupilleront',
    'toupilleuses',
    'touraillages',
    'touraniennes',
    'tourbassions',
    'tourberaient',
    'tourbillonna',
    'tourbillonne',
    'tourbillonné',
    'tourillonnai',
    'tourillonnas',
    'tourillonnât',
    'tourillonner',
    'tourillonnes',
    'tourillonnez',
    'touristiques',
    'tourmentâmes',
    'tourmentasse',
    'tourmentâtes',
    'tourmenterai',
    'tourmenteras',
    'tourmenterez',
    'tourmenteurs',
    'tourmenteuse',
    'tourmentions',
    'tournaillais',
    'tournaillait',
    'tournaillant',
    'tournaillent',
    'tournaillera',
    'tournailliez',
    'tournaillons',
    'tournassâmes',
    'tournassasse',
    'tournassâtes',
    'tournasserai',
    'tournasseras',
    'tournasserez',
    'tournassions',
    'tourneboulai',
    'tourneboulas',
    'tourneboulât',
    'tourneboulée',
    'tournebouler',
    'tourneboules',
    'tourneboulés',
    'tourneboulez',
    'tournebrides',
    'tournebroche',
    'tourneraient',
    'tournicotais',
    'tournicotait',
    'tournicotant',
    'tournicotées',
    'tournicotent',
    'tournicotera',
    'tournicotiez',
    'tournicotons',
    'tournillâmes',
    'tournillasse',
    'tournillâtes',
    'tournillerai',
    'tournilleras',
    'tournillerez',
    'tournillions',
    'tourniquâmes',
    'tourniquasse',
    'tourniquâtes',
    'tourniquerai',
    'tourniqueras',
    'tourniquerez',
    'tourniquions',
    'tournoiement',
    'tournoierais',
    'tournoierait',
    'tournoieriez',
    'tournoierons',
    'tournoieront',
    'tournoyaient',
    'tournoyasses',
    'tournoyèrent',
    'tourterelles',
    'toussaillais',
    'toussaillait',
    'toussaillant',
    'toussaillent',
    'toussaillera',
    'toussailliez',
    'toussaillons',
    'toussassions',
    'tousseraient',
    'toussotaient',
    'toussotasses',
    'toussotement',
    'toussoterais',
    'toussoterait',
    'toussotèrent',
    'toussoteriez',
    'toussoterons',
    'toussoteront',
    'toxicodermie',
    'toxicologies',
    'toxicologues',
    'toxicomanies',
    'toxoplasmose',
    'traboulaient',
    'traboulasses',
    'traboulerais',
    'traboulerait',
    'traboulèrent',
    'trabouleriez',
    'traboulerons',
    'trabouleront',
    'tracanassent',
    'tracanassiez',
    'tracanerions',
    'tracassaient',
    'tracassasses',
    'tracasserais',
    'tracasserait',
    'tracassèrent',
    'tracasseries',
    'tracasseriez',
    'tracasserons',
    'tracasseront',
    'tracassières',
    'trachéotomie',
    'tractassions',
    'tracteraient',
    'tractoristes',
    'tradescantia',
    'trade-unions',
    'traditionnel',
    'traductrices',
    'traduiraient',
    'traduisaient',
    'traduisibles',
    'traduisirent',
    'traduisisses',
    'traficotâmes',
    'traficotasse',
    'traficotâtes',
    'traficoterai',
    'traficoteras',
    'traficoterez',
    'traficotions',
    'trafiquaient',
    'trafiquantes',
    'trafiqueuses',
    'tragédiennes',
    'tragiquement',
    'trahissaient',
    'traînaillais',
    'traînaillait',
    'traînaillant',
    'traînaillées',
    'traînaillent',
    'traînaillera',
    'traînailliez',
    'traînaillons',
    'traînassâmes',
    'traînassasse',
    'traînassâtes',
    'traînasserai',
    'traînasseras',
    'traînasserez',
    'traînassions',
    'traîneraient',
    'traitassions',
    'traiteraient',
    'trajectoires',
    'tranchassent',
    'tranchassiez',
    'tranchefilai',
    'tranchefilas',
    'tranchefilât',
    'tranchefilée',
    'tranchefiler',
    'tranchefiles',
    'tranchefilés',
    'tranchefilez',
    'trancherions',
    'tranquillisa',
    'tranquillise',
    'tranquillisé',
    'tranquillité',
    'transactions',
    'transalpines',
    'transandines',
    'transbahutai',
    'transbahutas',
    'transbahutât',
    'transbahutée',
    'transbahuter',
    'transbahutes',
    'transbahutés',
    'transbahutez',
    'transbordais',
    'transbordait',
    'transbordant',
    'transbordées',
    'transbordent',
    'transbordera',
    'transbordeur',
    'transbordiez',
    'transbordons',
    'transcaspien',
    'transcendais',
    'transcendait',
    'transcendant',
    'transcendées',
    'transcendent',
    'transcendera',
    'transcendiez',
    'transcendons',
    'transcodages',
    'transcodâmes',
    'transcodasse',
    'transcodâtes',
    'transcoderai',
    'transcoderas',
    'transcoderez',
    'transcodeurs',
    'transcodions',
    'transcrirais',
    'transcrirait',
    'transcririez',
    'transcrirons',
    'transcriront',
    'transcrivais',
    'transcrivait',
    'transcrivant',
    'transcrivent',
    'transcriviez',
    'transcrivons',
    'transducteur',
    'transduction',
    'transférable',
    'transférâmes',
    'transférasse',
    'transférâtes',
    'transférerai',
    'transféreras',
    'transférerez',
    'transférions',
    'transfigurai',
    'transfiguras',
    'transfigurât',
    'transfigurée',
    'transfigurer',
    'transfigures',
    'transfigurés',
    'transfigurez',
    'transfilâmes',
    'transfilasse',
    'transfilâtes',
    'transfilerai',
    'transfileras',
    'transfilerez',
    'transfilions',
    'transfixions',
    'transformais',
    'transformait',
    'transformant',
    'transformées',
    'transforment',
    'transformera',
    'transformiez',
    'transformons',
    'transfusâmes',
    'transfusasse',
    'transfusâtes',
    'transfuserai',
    'transfuseras',
    'transfuserez',
    'transfusions',
    'transgressai',
    'transgressas',
    'transgressât',
    'transgressée',
    'transgresser',
    'transgresses',
    'transgressés',
    'transgressez',
    'transhumâmes',
    'transhumance',
    'transhumante',
    'transhumants',
    'transhumasse',
    'transhumâtes',
    'transhumerai',
    'transhumeras',
    'transhumerez',
    'transhumions',
    'transigeâmes',
    'transigeante',
    'transigeants',
    'transigeasse',
    'transigeâtes',
    'transigerais',
    'transigerait',
    'transigèrent',
    'transigeriez',
    'transigerons',
    'transigeront',
    'transiraient',
    'transissions',
    'transitaient',
    'transitaires',
    'transitasses',
    'transiterais',
    'transiterait',
    'transitèrent',
    'transiteriez',
    'transiterons',
    'transiteront',
    'transitivité',
    'transitoires',
    'translatâmes',
    'translatasse',
    'translatâtes',
    'translaterai',
    'translateras',
    'translaterez',
    'translations',
    'translatives',
    'translitérai',
    'translitéras',
    'translitérât',
    'translitérée',
    'translitérer',
    'translitérés',
    'translitères',
    'translitérez',
    'translittéra',
    'translittéré',
    'translittère',
    'translucides',
    'transmettais',
    'transmettait',
    'transmettant',
    'transmettent',
    'transmetteur',
    'transmettiez',
    'transmettons',
    'transmettrai',
    'transmettras',
    'transmettrez',
    'transmigrais',
    'transmigrait',
    'transmigrant',
    'transmigrent',
    'transmigrera',
    'transmigriez',
    'transmigrons',
    'transmissent',
    'transmissiez',
    'transmission',
    'transmuables',
    'transmuaient',
    'transmuasses',
    'transmuerais',
    'transmuerait',
    'transmuèrent',
    'transmueriez',
    'transmuerons',
    'transmueront',
    'transmutable',
    'transmutâmes',
    'transmutasse',
    'transmutâtes',
    'transmuterai',
    'transmuteras',
    'transmuterez',
    'transmutions',
    'transparence',
    'transparente',
    'transparents',
    'transparûmes',
    'transparusse',
    'transparûtes',
    'transperçais',
    'transperçait',
    'transperçant',
    'transpercées',
    'transpercent',
    'transpercera',
    'transperciez',
    'transperçons',
    'transpirâmes',
    'transpirante',
    'transpirants',
    'transpirasse',
    'transpirâtes',
    'transpirerai',
    'transpireras',
    'transpirerez',
    'transpirions',
    'transplantai',
    'transplantas',
    'transplantât',
    'transplantée',
    'transplanter',
    'transplantes',
    'transplantés',
    'transplantez',
    'transpolaire',
    'transportais',
    'transportait',
    'transportant',
    'transportées',
    'transportent',
    'transportera',
    'transporteur',
    'transportiez',
    'transportons',
    'transposable',
    'transposâmes',
    'transposasse',
    'transposâtes',
    'transposerai',
    'transposeras',
    'transposerez',
    'transposions',
    'transsexuels',
    'transsonique',
    'transsudâmes',
    'transsudasse',
    'transsudâtes',
    'transsuderai',
    'transsuderas',
    'transsuderez',
    'transsudions',
    'transvasâmes',
    'transvasasse',
    'transvasâtes',
    'transvaserai',
    'transvaseras',
    'transvaserez',
    'transvasions',
    'transversale',
    'transversaux',
    'transvidâmes',
    'transvidasse',
    'transvidâtes',
    'transviderai',
    'transvideras',
    'transviderez',
    'transvidions',
    'transylvaine',
    'transylvains',
    'trapézoïdale',
    'trapézoïdaux',
    'trappistines',
    'traquassions',
    'traqueraient',
    'traumatiques',
    'traumatisais',
    'traumatisait',
    'traumatisant',
    'traumatisées',
    'traumatisent',
    'traumatisera',
    'traumatisiez',
    'traumatismes',
    'traumatisons',
    'travaillâmes',
    'travaillasse',
    'travaillâtes',
    'travaillerai',
    'travailleras',
    'travaillerez',
    'travailleurs',
    'travailleuse',
    'travaillions',
    'travaillisme',
    'travailliste',
    'traversables',
    'traversaient',
    'traversantes',
    'traversasses',
    'traverserais',
    'traverserait',
    'traversèrent',
    'traverseriez',
    'traverserons',
    'traverseront',
    'traversières',
    'travestirais',
    'travestirait',
    'travestirent',
    'travestiriez',
    'travestirons',
    'travestiront',
    'travestismes',
    'travestisses',
    'travestissez',
    'trébuchaient',
    'trébuchantes',
    'trébuchasses',
    'trébuchement',
    'trébucherais',
    'trébucherait',
    'trébuchèrent',
    'trébucheriez',
    'trébucherons',
    'trébucheront',
    'tréfilassent',
    'tréfilassiez',
    'tréfilerions',
    'treillageais',
    'treillageait',
    'treillageant',
    'treillageons',
    'treillagerai',
    'treillageras',
    'treillagerez',
    'treillageurs',
    'treillagions',
    'treillagiste',
    'treillissais',
    'treillissait',
    'treillissant',
    'treillissées',
    'treillissent',
    'treillissera',
    'treillissiez',
    'treillissons',
    'trématassent',
    'trématassiez',
    'trématerions',
    'tremblassent',
    'tremblassiez',
    'tremblements',
    'tremblerions',
    'tremblotâmes',
    'tremblotante',
    'tremblotants',
    'tremblotasse',
    'tremblotâtes',
    'trembloterai',
    'trembloteras',
    'trembloterez',
    'tremblotions',
    'trémoussâmes',
    'trémoussasse',
    'trémoussâtes',
    'trémousserai',
    'trémousseras',
    'trémousserez',
    'trémoussions',
    'trempassions',
    'tremperaient',
    'trémulassent',
    'trémulassiez',
    'trémulations',
    'trémulerions',
    'trench-coats',
    'trente-et-un',
    'trentenaires',
    'trente-trois',
    'trépanassent',
    'trépanassiez',
    'trépanations',
    'trépanerions',
    'trépassaient',
    'trépasserais',
    'trépasserait',
    'trépasseriez',
    'trépasserons',
    'trépasseront',
    'trépidassent',
    'trépidassiez',
    'trépidations',
    'trépiderions',
    'trépignaient',
    'trépignasses',
    'trépignement',
    'trépignerais',
    'trépignerait',
    'trépignèrent',
    'trépigneriez',
    'trépignerons',
    'trépigneront',
    'trépigneuses',
    'tressaillais',
    'tressaillait',
    'tressaillant',
    'tressaillent',
    'tressaillies',
    'tressailliez',
    'tressaillira',
    'tressaillons',
    'tressassions',
    'tressautâmes',
    'tressautasse',
    'tressautâtes',
    'tressauterai',
    'tressauteras',
    'tressauterez',
    'tressautions',
    'tresseraient',
    'treuillaient',
    'treuillasses',
    'treuillerais',
    'treuillerait',
    'treuillèrent',
    'treuilleriez',
    'treuillerons',
    'treuilleront',
    'trévirassent',
    'trévirassiez',
    'trévirerions',
    'triangulaire',
    'triangulâmes',
    'triangulasse',
    'triangulâtes',
    'triangulerai',
    'trianguleras',
    'triangulerez',
    'triangulions',
    'triatomiques',
    'triballaient',
    'triballasses',
    'triballerais',
    'triballerait',
    'triballèrent',
    'triballeriez',
    'triballerons',
    'triballeront',
    'tribométries',
    'tribulations',
    'tribunitiens',
    'trichassions',
    'tricheraient',
    'trichineuses',
    'trichophyton',
    'tricliniques',
    'tricotassent',
    'tricotassiez',
    'tricoterions',
    'trifoliolées',
    'trifouillais',
    'trifouillait',
    'trifouillant',
    'trifouillées',
    'trifouillent',
    'trifouillera',
    'trifouilliez',
    'trifouillons',
    'trilatérales',
    'trillassions',
    'trilleraient',
    'triloculaire',
    'trimardaient',
    'trimardasses',
    'trimarderais',
    'trimarderait',
    'trimardèrent',
    'trimarderiez',
    'trimarderons',
    'trimarderont',
    'trimbalaient',
    'trimbalasses',
    'trimbalement',
    'trimbalerais',
    'trimbalerait',
    'trimbalèrent',
    'trimbaleriez',
    'trimbalerons',
    'trimbaleront',
    'trimballages',
    'trimballâmes',
    'trimballasse',
    'trimballâtes',
    'trimballerai',
    'trimballeras',
    'trimballerez',
    'trimballions',
    'trimestriels',
    'tringlassent',
    'tringlassiez',
    'tringlerions',
    'trinquassent',
    'trinquassiez',
    'trinquerions',
    'triomphaient',
    'triomphantes',
    'triomphasses',
    'triomphateur',
    'triompherais',
    'triompherait',
    'triomphèrent',
    'triompheriez',
    'triompherons',
    'triompheront',
    'tripartismes',
    'tripartition',
    'tripatouilla',
    'tripatouille',
    'tripatouillé',
    'triplassions',
    'tripleraient',
    'tripotassent',
    'tripotassiez',
    'tripoterions',
    'triquassions',
    'triqueballes',
    'triqueraient',
    'trirectangle',
    'trisannuelle',
    'trisectrices',
    'triséquaient',
    'triséquasses',
    'triséquerais',
    'triséquerait',
    'triséquèrent',
    'triséqueriez',
    'triséquerons',
    'triséqueront',
    'trismégistes',
    'trissassions',
    'trisseraient',
    'tristounette',
    'triturassent',
    'triturassiez',
    'triturateurs',
    'triturations',
    'triturerions',
    'triumvirales',
    'trois-points',
    'trois-quarts',
    'trois-quatre',
    'trombidioses',
    'trombonistes',
    'trompassions',
    'tromperaient',
    'trompetaient',
    'trompetasses',
    'trompetèrent',
    'trompetterai',
    'trompetteras',
    'trompetterez',
    'trompettiste',
    'tronçonnages',
    'tronçonnâmes',
    'tronçonnasse',
    'tronçonnâtes',
    'tronçonnerai',
    'tronçonneras',
    'tronçonnerez',
    'tronçonneurs',
    'tronçonneuse',
    'tronçonnions',
    'tronculaires',
    'tronquassent',
    'tronquassiez',
    'tronquerions',
    'trophoblaste',
    'tropicalisai',
    'tropicalisas',
    'tropicalisât',
    'tropicalisée',
    'tropicaliser',
    'tropicalises',
    'tropicalisés',
    'tropicalisez',
    'troposphères',
    'troquassions',
    'troqueraient',
    'trottassions',
    'trotteraient',
    'trottinaient',
    'trottinantes',
    'trottinasses',
    'trottinement',
    'trottinerais',
    'trottinerait',
    'trottinèrent',
    'trottineriez',
    'trottinerons',
    'trottineront',
    'trottinettes',
    'troublassent',
    'troublassiez',
    'trouble-fête',
    'troublerions',
    'trouillardes',
    'trous-madame',
    'troussassent',
    'troussassiez',
    'trousse-pied',
    'troussequina',
    'troussequine',
    'troussequiné',
    'troussequins',
    'trousserions',
    'trouvassions',
    'trouveraient',
    'truandassent',
    'truandassiez',
    'truanderions',
    'trucidassent',
    'trucidassiez',
    'truciderions',
    'truffassions',
    'trufferaient',
    'truquassions',
    'truqueraient',
    'trusquinâmes',
    'trusquinasse',
    'trusquinâtes',
    'trusquinerai',
    'trusquineras',
    'trusquinerez',
    'trusquinions',
    'trustassions',
    'trusteraient',
    'trypanosomes',
    'trypsinogène',
    'tryptophanes',
    'tuberculeuse',
    'tuberculides',
    'tuberculinai',
    'tuberculinas',
    'tuberculinât',
    'tuberculinée',
    'tuberculiner',
    'tuberculines',
    'tuberculinés',
    'tuberculinez',
    'tuberculisai',
    'tuberculisas',
    'tuberculisât',
    'tuberculisée',
    'tuberculiser',
    'tuberculises',
    'tuberculisés',
    'tuberculisez',
    'tuberculoses',
    'tubériformes',
    'tubérisation',
    'tubuliflores',
    'tuméfactions',
    'tuméfiassent',
    'tuméfiassiez',
    'tuméfierions',
    'tumultueuses',
    'turbinassent',
    'turbinassiez',
    'turbinerions',
    'turbofiltres',
    'turbomachine',
    'turbomoteurs',
    'turbo-trains',
    'turco-mongol',
    'turgescences',
    'turgescentes',
    'turlupinâmes',
    'turlupinasse',
    'turlupinâtes',
    'turlupinerai',
    'turlupineras',
    'turlupinerez',
    'turlupinions',
    'tuteurassent',
    'tuteurassiez',
    'tuteurerions',
    'tutoieraient',
    'tutoyassions',
    'tuyautassent',
    'tuyautassiez',
    'tuyauterions',
    'twistassions',
    'twisteraient',
    'tympanisâmes',
    'tympanisasse',
    'tympanisâtes',
    'tympaniserai',
    'tympaniseras',
    'tympaniserez',
    'tympanisions',
    'typhoïdiques',
    'typhomycines',
    'typisassions',
    'typiseraient',
    'typochromies',
    'typographies',
    'typologiques',
    'tyrannicides',
    'tyrannisâmes',
    'tyrannisante',
    'tyrannisants',
    'tyrannisasse',
    'tyrannisâtes',
    'tyranniserai',
    'tyranniseras',
    'tyranniserez',
    'tyrannisions',
    'tyrannosaure',
    'ukrainiennes',
    'ulcérassions',
    'ulcéreraient',
    'ultra-courte',
    'ultracourtes',
    'ultra-courts',
    'ultrafiltres',
    'ultramoderne',
    'ultramontain',
    'ultra-petita',
    'ultrasonique',
    'ultra-violet',
    'ultraviolets',
    'unifiassions',
    'unificateurs',
    'unifications',
    'unificatrice',
    'unifieraient',
    'uniformément',
    'uniformisais',
    'uniformisait',
    'uniformisant',
    'uniformisées',
    'uniformisent',
    'uniformisera',
    'uniformisiez',
    'uniformisons',
    'unijambistes',
    'unilatérales',
    'unilinéaires',
    'uniloculaire',
    'uninominales',
    'unisexualité',
    'unitariennes',
    'universalisa',
    'universalise',
    'universalisé',
    'universalité',
    'universelles',
    'univitelline',
    'univitellins',
    'upérisations',
    'uranographie',
    'uranoplastie',
    'urbanisaient',
    'urbanisasses',
    'urbanisation',
    'urbaniserais',
    'urbaniserait',
    'urbanisèrent',
    'urbaniseriez',
    'urbaniserons',
    'urbaniseront',
    'urbanistique',
    'urobilinurie',
    'uro-génitale',
    'uro-génitaux',
    'uropygiennes',
    'uruguayennes',
    'usufruitière',
    'usufruitiers',
    'usurpassions',
    'usurpatoires',
    'usurpatrices',
    'usurperaient',
    'utilisassent',
    'utilisassiez',
    'utilisateurs',
    'utilisations',
    'utilisatrice',
    'utiliserions',
    'utilitarisme',
    'utilitariste',
    'utriculaires',
    'utriculeuses',
    'uxorilocales',
    'vaccinassent',
    'vaccinassiez',
    'vaccinateurs',
    'vaccinations',
    'vaccinatrice',
    'vaccinerions',
    'vaccinifères',
    'vaccinogènes',
    'vaccinostyle',
    'vacillassent',
    'vacillassiez',
    'vacillations',
    'vacillements',
    'vacillerions',
    'vacuolisâmes',
    'vacuolisasse',
    'vacuolisâtes',
    'vacuoliserai',
    'vacuoliseras',
    'vacuoliserez',
    'vacuolisions',
    'vadrouillais',
    'vadrouillait',
    'vadrouillant',
    'vadrouillées',
    'vadrouillent',
    'vadrouillera',
    'vadrouilliez',
    'vadrouillons',
    'vagabondages',
    'vagabondâmes',
    'vagabondasse',
    'vagabondâtes',
    'vagabonderai',
    'vagabonderas',
    'vagabonderez',
    'vagabondions',
    'vagissements',
    'vagolytiques',
    'vagotoniques',
    'vaguemestres',
    'vainquissent',
    'vainquissiez',
    'vaironnaient',
    'vaironnasses',
    'vaironnerais',
    'vaironnerait',
    'vaironnèrent',
    'vaironneriez',
    'vaironnerons',
    'vaironneront',
    'valdinguâmes',
    'valdinguasse',
    'valdinguâtes',
    'valdinguerai',
    'valdingueras',
    'valdinguerez',
    'valdinguions',
    'valenciennes',
    'valentinites',
    'valérianelle',
    'valérianique',
    'valetassions',
    'valeteraient',
    'validassions',
    'valideraient',
    'valisassions',
    'valiseraient',
    'vallisnéries',
    'vallonnaient',
    'vallonnasses',
    'vallonnement',
    'vallonnerais',
    'vallonnerait',
    'vallonnèrent',
    'vallonneriez',
    'vallonnerons',
    'vallonneront',
    'valorisaient',
    'valorisantes',
    'valorisasses',
    'valorisation',
    'valoriserais',
    'valoriserait',
    'valorisèrent',
    'valoriseriez',
    'valoriserons',
    'valoriseront',
    'valousassent',
    'valousassiez',
    'valouserions',
    'vampirisâmes',
    'vampirisasse',
    'vampirisâtes',
    'vampiriserai',
    'vampiriseras',
    'vampiriserez',
    'vampirisions',
    'vandalisâmes',
    'vandalisasse',
    'vandalisâtes',
    'vandaliserai',
    'vandaliseras',
    'vandaliserez',
    'vandalisions',
    'vaporisaient',
    'vaporisasses',
    'vaporisateur',
    'vaporisation',
    'vaporiserais',
    'vaporiserait',
    'vaporisèrent',
    'vaporiseriez',
    'vaporiserons',
    'vaporiseront',
    'varappassent',
    'varappassiez',
    'varapperions',
    'variabilités',
    'variablement',
    'varlopassent',
    'varlopassiez',
    'varloperions',
    'vascularisée',
    'vascularisés',
    'vaselinaient',
    'vaselinasses',
    'vaselinerais',
    'vaselinerait',
    'vaselinèrent',
    'vaselineriez',
    'vaselinerons',
    'vaselineront',
    'vasomotrices',
    'vasouillâmes',
    'vasouillarde',
    'vasouillards',
    'vasouillasse',
    'vasouillâtes',
    'vasouillerai',
    'vasouilleras',
    'vasouillerez',
    'vasouillions',
    'vassalisâmes',
    'vassalisasse',
    'vassalisâtes',
    'vassaliserai',
    'vassaliseras',
    'vassaliserez',
    'vassalisions',
    'vaticinaient',
    'vaticinasses',
    'vaticinateur',
    'vaticination',
    'vaticinerais',
    'vaticinerait',
    'vaticinèrent',
    'vaticineriez',
    'vaticinerons',
    'vaticineront',
    'vauclusienne',
    'vautrassions',
    'vautreraient',
    'vectorielles',
    'vedettariats',
    'végétalismes',
    'végétarienne',
    'végétarismes',
    'végétassions',
    'végéteraient',
    'véhiculaient',
    'véhiculaires',
    'véhiculasses',
    'véhiculerais',
    'véhiculerait',
    'véhiculèrent',
    'véhiculeriez',
    'véhiculerons',
    'véhiculeront',
    'veillassions',
    'veilleraient',
    'vélarisaient',
    'vélarisasses',
    'vélariserais',
    'vélariserait',
    'vélarisèrent',
    'vélariseriez',
    'vélariserons',
    'vélariseront',
    'velléitaires',
    'veloutassent',
    'veloutassiez',
    'veloutements',
    'velouterions',
    'vendangeâmes',
    'vendangeasse',
    'vendangeâtes',
    'vendangeoirs',
    'vendangerais',
    'vendangerait',
    'vendangèrent',
    'vendangeriez',
    'vendangerons',
    'vendangeront',
    'vendangerots',
    'vendangettes',
    'vendangeuses',
    'vénérassions',
    'vénéréologie',
    'vénéreraient',
    'vénéricardes',
    'vengeassions',
    'véniellement',
    'ventilassent',
    'ventilassiez',
    'ventilateurs',
    'ventilations',
    'ventilerions',
    'ventousaient',
    'ventousasses',
    'ventouserais',
    'ventouserait',
    'ventousèrent',
    'ventouseriez',
    'ventouserons',
    'ventouseront',
    'ventriloques',
    'ventriloquie',
    'ventripotent',
    'verbalisâmes',
    'verbalisasse',
    'verbalisâtes',
    'verbaliserai',
    'verbaliseras',
    'verbaliserez',
    'verbalisions',
    'verbeusement',
    'verbiageâmes',
    'verbiageasse',
    'verbiageâtes',
    'verbiagerais',
    'verbiagerait',
    'verbiagèrent',
    'verbiageriez',
    'verbiagerons',
    'verbiageront',
    'verdissaient',
    'verdissement',
    'verdoiements',
    'verdoierions',
    'verdoyassent',
    'verdoyassiez',
    'verdunisâmes',
    'verdunisasse',
    'verdunisâtes',
    'verduniserai',
    'verduniseras',
    'verduniserez',
    'verdunisions',
    'verglacerait',
    'vérifiassent',
    'vérifiassiez',
    'vérificateur',
    'vérificatifs',
    'vérification',
    'vérificative',
    'vérifierions',
    'verjutassent',
    'verjutassiez',
    'verjuterions',
    'vermiculaire',
    'vermiculâmes',
    'vermiculasse',
    'vermiculâtes',
    'vermiculerai',
    'vermiculeras',
    'vermiculerez',
    'vermiculions',
    'vermiculures',
    'vermillaient',
    'vermillasses',
    'vermillerais',
    'vermillerait',
    'vermillèrent',
    'vermilleriez',
    'vermillerons',
    'vermilleront',
    'vermillonnai',
    'vermillonnas',
    'vermillonnât',
    'vermillonnée',
    'vermillonner',
    'vermillonnes',
    'vermillonnés',
    'vermillonnez',
    'vermoulaient',
    'vermoulasses',
    'vermoulerais',
    'vermoulerait',
    'vermoulèrent',
    'vermouleriez',
    'vermoulerons',
    'vermouleront',
    'vernaculaire',
    'vernissaient',
    'vernissasses',
    'vernisserais',
    'vernisserait',
    'vernissèrent',
    'vernisseriez',
    'vernisserons',
    'vernisseront',
    'vernisseuses',
    'verrouillage',
    'verrouillais',
    'verrouillait',
    'verrouillant',
    'verrouillées',
    'verrouillent',
    'verrouillera',
    'verrouilliez',
    'verrouillons',
    'verrucosités',
    'verruqueuses',
    'versaillaise',
    'versatilités',
    'versifiaient',
    'versifiasses',
    'versifierais',
    'versifierait',
    'versifièrent',
    'versifieriez',
    'versifierons',
    'versifieront',
    'vert-de-gris',
    'verticalités',
    'verticillées',
    'vertigineuse',
    'vésicatoires',
    'vésiculaires',
    'vésiculeuses',
    'vespasiennes',
    'vespertilion',
    'vestibulaire',
    'vétérinaires',
    'vétillassent',
    'vétillassiez',
    'vétillerions',
    'viabilisâmes',
    'viabilisasse',
    'viabilisâtes',
    'viabiliserai',
    'viabiliseras',
    'viabiliserez',
    'viabilisions',
    'viandassions',
    'vianderaient',
    'vibrionnâmes',
    'vibrionnasse',
    'vibrionnâtes',
    'vibrionnerai',
    'vibrionneras',
    'vibrionnerez',
    'vibrionnions',
    'vibromasseur',
    'vice-amiraux',
    'vice-consuls',
    'vice-recteur',
    'vice-royauté',
    'vichyssoises',
    'vicieusement',
    'vicissitudes',
    'victoriennes',
    'victorieuses',
    'vidangeaient',
    'vidangeasses',
    'vidangerions',
    'vidéodisques',
    'vidéophonies',
    'vide-ordures',
    'vidéothèques',
    'vidimassions',
    'vidimeraient',
    'vieillirions',
    'vieillissais',
    'vieillissait',
    'vieillissant',
    'vieillissent',
    'vieillissiez',
    'vieillissons',
    'viellassions',
    'vielleraient',
    'vietnamienne',
    'vifs-argents',
    'vignettistes',
    'vilebrequins',
    'vilipendâmes',
    'vilipendasse',
    'vilipendâtes',
    'vilipenderai',
    'vilipenderas',
    'vilipenderez',
    'vilipendions',
    'villageoises',
    'villégiateur',
    'villégiatura',
    'villégiature',
    'villégiaturé',
    'vinaigraient',
    'vinaigrasses',
    'vinaigrerais',
    'vinaigrerait',
    'vinaigrèrent',
    'vinaigreries',
    'vinaigreriez',
    'vinaigrerons',
    'vinaigreront',
    'vinaigrettes',
    'vindicatives',
    'vingt-quatre',
    'vinicultures',
    'vinifiassent',
    'vinifiassiez',
    'vinification',
    'vinifierions',
    'violaçassent',
    'violaçassiez',
    'violacerions',
    'violentaient',
    'violentasses',
    'violenterais',
    'violenterait',
    'violentèrent',
    'violenteriez',
    'violenterons',
    'violenteront',
    'violonassent',
    'violonassiez',
    'violoncelles',
    'violonerions',
    'vioquiraient',
    'vioquissions',
    'virevoltâmes',
    'virevoltasse',
    'virevoltâtes',
    'virevolterai',
    'virevolteras',
    'virevolterez',
    'virevoltions',
    'virgulassent',
    'virgulassiez',
    'virgulerions',
    'virilisaient',
    'virilisasses',
    'virilisation',
    'viriliserais',
    'viriliserait',
    'virilisèrent',
    'viriliseriez',
    'viriliserons',
    'viriliseront',
    'virolassions',
    'viroleraient',
    'virologistes',
    'virostatique',
    'viscosimètre',
    'visionnaient',
    'visionnaires',
    'visionnasses',
    'visionnement',
    'visionnerais',
    'visionnerait',
    'visionnèrent',
    'visionneriez',
    'visionnerons',
    'visionneront',
    'visionneuses',
    'visiophonies',
    'visitandines',
    'visitassions',
    'visiteraient',
    'visualisâmes',
    'visualisasse',
    'visualisâtes',
    'visualiserai',
    'visualiseras',
    'visualiserez',
    'visualisions',
    'visuellement',
    'vitaminiques',
    'viticulteurs',
    'viticultrice',
    'viticultures',
    'vitrifiables',
    'vitrifiaient',
    'vitrifiantes',
    'vitrifiasses',
    'vitrifierais',
    'vitrifierait',
    'vitrifièrent',
    'vitrifieriez',
    'vitrifierons',
    'vitrifieront',
    'vitriolaient',
    'vitriolasses',
    'vitriolerais',
    'vitriolerait',
    'vitriolèrent',
    'vitrioleriez',
    'vitriolerons',
    'vitrioleront',
    'vitrioleuses',
    'vitupéraient',
    'vitupérasses',
    'vitupérateur',
    'vitupération',
    'vitupérerais',
    'vitupérerait',
    'vitupérèrent',
    'vitupéreriez',
    'vitupérerons',
    'vitupéreront',
    'vivifiassent',
    'vivifiassiez',
    'vivificateur',
    'vivification',
    'vivifierions',
    'vivisections',
    'vivotassions',
    'vivoteraient',
    'vocabulaires',
    'vocalisaient',
    'vocalisasses',
    'vocalisateur',
    'vocalisation',
    'vocaliserais',
    'vocaliserait',
    'vocalisèrent',
    'vocaliseriez',
    'vocaliserons',
    'vocaliseront',
    'vociféraient',
    'vociférasses',
    'vociférateur',
    'vocifération',
    'vociférerais',
    'vociférerait',
    'vociférèrent',
    'vociféreriez',
    'vociférerons',
    'vociféreront',
    'voisinassent',
    'voisinassiez',
    'voisinerions',
    'voiturassent',
    'voiturassiez',
    'voiturerions',
    'volailleuses',
    'volatilisais',
    'volatilisait',
    'volatilisant',
    'volatilisées',
    'volatilisent',
    'volatilisera',
    'volatilisiez',
    'volatilisons',
    'volcanisâmes',
    'volcanisasse',
    'volcanisâtes',
    'volcaniserai',
    'volcaniseras',
    'volcaniserez',
    'volcanisions',
    'volcanologie',
    'volcanologue',
    'voletassions',
    'volettements',
    'voletterions',
    'voligeassent',
    'voligeassiez',
    'voligeraient',
    'volley-balls',
    'volontariats',
    'volontarisme',
    'volontariste',
    'voltairienne',
    'voltaïsation',
    'voltigeaient',
    'voltigeasses',
    'voltigements',
    'voltigerions',
    'volubilement',
    'Volucompteur',
    'volumétrique',
    'volumineuses',
    'voluptuaires',
    'voluptueuses',
    'vomissements',
    'vousoierions',
    'vousoyassent',
    'vousoyassiez',
    'voussoiement',
    'voussoierais',
    'voussoierait',
    'voussoieriez',
    'voussoierons',
    'voussoieront',
    'voussoyaient',
    'voussoyasses',
    'voussoyèrent',
    'vouvoiements',
    'vouvoierions',
    'vouvoyassent',
    'vouvoyassiez',
    'voyageassent',
    'voyageassiez',
    'voyageraient',
    'vrillassions',
    'vrilleraient',
    'vrombiraient',
    'vrombissions',
    'vulcaniennes',
    'vulcanisâmes',
    'vulcanisasse',
    'vulcanisâtes',
    'vulcaniserai',
    'vulcaniseras',
    'vulcaniserez',
    'vulcanisions',
    'vulcanologie',
    'vulcanologue',
    'vulgairement',
    'vulgarisâmes',
    'vulgarisasse',
    'vulgarisâtes',
    'vulgariserai',
    'vulgariseras',
    'vulgariserez',
    'vulgarisions',
    'wagnériennes',
    'wagon-foudre',
    'wagons-poste',
    'wagon-trémie',
    'wallingantes',
    'warrantaient',
    'warrantasses',
    'warranterais',
    'warranterait',
    'warrantèrent',
    'warranteriez',
    'warranterons',
    'warranteront',
    'washingtonia',
    'water-closet',
    'wellingtonia',
    'white-spirit',
    'wintergreens',
    'wisigothique',
    'xanthophylle',
    'xérographies',
    'xérophtalmie',
    'xiphoïdienne',
    'xylographies',
    'zéphyriennes',
    'zézaieraient',
    'zézayassions',
    'zézayeraient',
    'zieutassions',
    'zieuteraient',
    'zigouillâmes',
    'zigouillasse',
    'zigouillâtes',
    'zigouillerai',
    'zigouilleras',
    'zigouillerez',
    'zigouillions',
    'zigzaguaient',
    'zigzaguasses',
    'zigzaguerais',
    'zigzaguerait',
    'zigzaguèrent',
    'zigzagueriez',
    'zigzaguerons',
    'zigzagueront',
    'zincographie',
    'zinguassions',
    'zingueraient',
    'zinjanthrope',
    'zinzinulâmes',
    'zinzinulasse',
    'zinzinulâtes',
    'zinzinulerai',
    'zinzinuleras',
    'zinzinulerez',
    'zinzinulions',
    'zoanthropies',
    'zoomorphisme',
    'zoosporanges',
    'zootechnique',
    'zoroastriens',
    'zoroastrisme',
    'zostériennes',
    'zozotassions',
    'zozoteraient',
    'zygomatiques',
    'zymotechnies'
];
