import KidGames from './KidGames.screen';

const routes = [
    {
        path: '/kid-games',
        element: <KidGames />,
        name: 'Jeux pour enfant'
    }
];

export default routes;
