import {
    AUTH_LOADING,
    AUTH_LOADING_FINISHED,
    SIGN_SUCCESS,
    SIGN_FAIL,
    SIGN_OUT
} from './auth.types';

const initialState = {
    isLoggedIn: false,
    hasError: false,
    loading: false,
    error: null,
    authenticatedUser: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case AUTH_LOADING: {
            return {
                ...state,
                loading: true,
                hasError: false,
                error: null
            };
        }
        case AUTH_LOADING_FINISHED: {
            return {
                ...state,
                loading: false
            };
        }
        case SIGN_SUCCESS: {
            return {
                ...state,
                isLoggedIn: true,
                hasError: false,
                error: null,
                authenticatedUser: action.payload.user
            };
        }
        case SIGN_FAIL: {
            return {
                ...state,
                isLoggedIn: false,
                hasError: true,
                error: action.payload.error
            };
        }
        case SIGN_OUT: {
            return {
                ...state,
                isLoggedIn: false,
                authenticatedUser: null
            };
        }
        default: {
            return state;
        }
    }
};
