import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOne, update } from '../spending.actions';
import { getSpending, getSpendingIsLoading } from '../spending.selectors';
import { payers } from '../spending.const';
import {
    Alert,
    Button,
    CircularProgress,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import TrashIcon from '@mui/icons-material/Delete';
import { useNavigate, useParams } from 'react-router-dom';
import SpendingResume from '../Components/Resume';
import ExpenseModal from '../Components/ExpenseModal';
import PageTitle from '../../common/Components/PageTitle';
import { Add } from '@mui/icons-material';
import displayPrice from '../spending.utils';

export default function ViewSpending() {
    const { docId } = useParams();
    const navigate = useNavigate();
    const spending = useSelector((state) => getSpending(state, docId));
    const [expenses, setExpenses] = useState([]);
    const isLoading = useSelector(getSpendingIsLoading);
    const dispatch = useDispatch();

    const [isExpenseModalOpen, setIsExpenseModalOpen] = useState(false);

    useEffect(() => {
        document.title = 'Home - Dépenses - détails';
        if (!spending) {
            dispatch(getOne(docId));
        }
    }, []);

    useEffect(() => {
        if (spending && spending.expenses) {
            setExpenses(spending.expenses);
        }
    }, [spending]);

    function handleExpenseModalClose(expense) {
        if (expense) {
            setExpenses(expenses.concat(expense));
        }
        setIsExpenseModalOpen(false);
    }

    function removeExpense(index) {
        setExpenses(expenses.filter((s, i) => i !== index));
    }

    async function handleSave() {
        spending.expenses = expenses;
        await dispatch(update(spending));
        navigate('/spending', { replace: true });
    }

    if (isLoading) {
        return (
            <React.Fragment>
                <PageTitle title="Détail du mois" />
                <div className="row justify-center mt-l">
                    <CircularProgress />
                </div>
            </React.Fragment>
        );
    }

    if (!spending) {
        return (
            <React.Fragment>
                <PageTitle title="Détail du mois" />
                <div className="row justify-center mt-l">
                    <Alert severity="error">Mois non trouvé</Alert>
                </div>
                <div className="row justify-center mt-l">
                    <Button variant="outlined" onClick={() => navigate('/spending')}>
                        Retour à la liste des mois
                    </Button>
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <PageTitle title={`Détail du mois de ${spending.name}`} />
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <b>Nom</b>
                            </TableCell>
                            <TableCell>
                                <b>Audrey</b>
                            </TableCell>
                            <TableCell>
                                <b>Morgan</b>
                            </TableCell>
                            <TableCell>
                                <b>Commentaire</b>
                            </TableCell>
                            <TableCell>
                                <b>Action</b>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {expenses.map((expense, i) => (
                            <TableRow key={i}>
                                <TableCell>{expense.name}</TableCell>
                                <TableCell>{displayPrice(expense, payers.Audrey)}</TableCell>
                                <TableCell>{displayPrice(expense, payers.Morgan)}</TableCell>
                                <TableCell>{expense.comment}</TableCell>
                                <TableCell>
                                    <IconButton color="error" onClick={() => removeExpense(i)}>
                                        <TrashIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="row mt-s justify-end">
                <Button
                    variant="outlined"
                    onClick={() => setIsExpenseModalOpen(true)}
                    startIcon={<Add />}
                    size="small"
                >
                    Nouvelle dépense
                </Button>
            </div>
            <SpendingResume expenses={spending.expenses} />
            <div className="row mt-m space-between">
                <Button
                    variant="outlined"
                    color="error"
                    onClick={() => navigate('/spending')}
                    disabled={isLoading}
                >
                    Annuler
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    disabled={isLoading}
                >
                    Enregistrer
                </Button>
            </div>
            <ExpenseModal open={isExpenseModalOpen} onClose={handleExpenseModalClose} />
        </React.Fragment>
    );
}
