import {
    SPENDING_CREATE_FAIL,
    SPENDING_CREATE_SUCCESS,
    SPENDING_DELETE_FAIL,
    SPENDING_DELETE_SUCCESS,
    SPENDING_FETCH_FAIL,
    SPENDING_FETCH_SUCCESS,
    SPENDING_LOADING,
    SPENDING_LOADING_FINISHED,
    SPENDING_UPDATE_FAIL,
    SPENDING_UPDATE_SUCCESS,
    DEFAULT_EXPENSES_UPDATE_FAIL,
    DEFAULT_EXPENSES_UPDATE_SUCCESS,
    SPENDING_CONF_FETCH_FAIL,
    SPENDING_CONF_FETCH_SUCCESS
} from './spending.types';

const initialState = {
    hasError: false,
    isLoading: false,
    defaultExpenses: [],
    error: null,
    spendings: [],
    changes: 0
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SPENDING_LOADING:
            return {
                ...state,
                isLoading: true,
                hasError: false,
                error: null
            };
        case SPENDING_CREATE_SUCCESS: {
            const { spending } = payload;
            const nextSpendings = state.spendings;
            nextSpendings.unshift(spending);
            return {
                ...state,
                hasError: false,
                spendings: [...nextSpendings],
                changes: state.changes + 1,
                error: null
            };
        }
        case SPENDING_LOADING_FINISHED: {
            return {
                ...state,
                isLoading: false
            };
        }
        case SPENDING_FETCH_SUCCESS: {
            const newSpendings = [...state.spendings];
            const knowIdsMap = newSpendings.reduce((acc, s) => acc.set(s.docId, null), new Map());
            payload.spendings.forEach((spending) => {
                if (!knowIdsMap.has(spending.docId)) {
                    newSpendings.push(spending);
                }
            });
            return {
                ...state,
                spendings: newSpendings,
                changes: state.changes + 1,
                hasError: false,
                error: null
            };
        }
        case SPENDING_UPDATE_SUCCESS: {
            const { spending: updatedSpending } = payload;
            return {
                ...state,
                spendings: state.spendings.map((spending) => {
                    if (spending.docId !== updatedSpending.docId) {
                        return spending;
                    }
                    return updatedSpending;
                }),
                changes: state.changes + 1,
                hasError: false,
                error: null
            };
        }
        case SPENDING_UPDATE_FAIL:
        case SPENDING_CREATE_FAIL:
        case SPENDING_DELETE_FAIL:
        case SPENDING_CONF_FETCH_FAIL:
        case DEFAULT_EXPENSES_UPDATE_FAIL:
        case SPENDING_FETCH_FAIL: {
            return {
                ...state,
                hasError: true,
                error: payload.error
            };
        }
        case SPENDING_DELETE_SUCCESS: {
            return {
                ...state,
                spendings: state.spendings.filter((spending) => spending.docId !== payload.docId),
                changes: state.changes + 1,
                hasError: false,
                error: null
            };
        }
        case DEFAULT_EXPENSES_UPDATE_SUCCESS: {
            const { defaultExpenses } = payload;
            return {
                ...state,
                defaultExpenses,
                hasError: false,
                error: null
            };
        }
        case SPENDING_CONF_FETCH_SUCCESS: {
            const { conf } = payload;
            return {
                ...state,
                ...conf,
                hasError: false,
                error: null
            };
        }
        default:
            return state;
    }
};
