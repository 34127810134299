import oneLetter from './dictionaries/1-letter';
import twoLetter from './dictionaries/2-letter';
import threeLetter from './dictionaries/3-letter';
import fourLetter from './dictionaries/4-letter';
import fiveLetter from './dictionaries/5-letter';
import sixLetter from './dictionaries/6-letter';
import sevenLetter from './dictionaries/7-letter';
import eightLetter from './dictionaries/8-letter';
import nineLetter from './dictionaries/9-letter';
import tenLetter from './dictionaries/10-letter';
import elevenLetter from './dictionaries/11-letter';
import twelveLetter from './dictionaries/12-letter';
import thirteenLetter from './dictionaries/13-letter';
import fourteenLetter from './dictionaries/14-letter';
import fifteenLetter from './dictionaries/15-letter';
import sixteenLetter from './dictionaries/16-letter';
import seventeenLetter from './dictionaries/17-letter';
import eighteenLetter from './dictionaries/18-letter';
import nineteenLetter from './dictionaries/19-letter';
import twentyLetter from './dictionaries/20-letter';
import twentyOneLetter from './dictionaries/21-letter';
import twentyTwoLetter from './dictionaries/22-letter';
import twentyThreeLetter from './dictionaries/23-letter';
import twentyFourLetter from './dictionaries/24-letter';
import twentyFiveLetter from './dictionaries/25-letter';

export default {
    1: oneLetter,
    2: twoLetter,
    3: threeLetter,
    4: fourLetter,
    5: fiveLetter,
    6: sixLetter,
    7: sevenLetter,
    8: eightLetter,
    9: nineLetter,
    10: tenLetter,
    11: elevenLetter,
    12: twelveLetter,
    13: thirteenLetter,
    14: fourteenLetter,
    15: fifteenLetter,
    16: sixteenLetter,
    17: seventeenLetter,
    18: eighteenLetter,
    19: nineteenLetter,
    20: twentyLetter,
    21: twentyOneLetter,
    22: twentyTwoLetter,
    23: twentyThreeLetter,
    24: twentyFourLetter,
    25: twentyFiveLetter
};
