import React, { useCallback, useEffect, useState } from 'react';
import './Bongo.css';
import useKeyDownListener from '../../common/hooks/keyDown.hook';
import useSound from 'use-sound';
import machoIn from '../../common/sounds/bongo/macho-in.wav';
import machoOut from '../../common/sounds/bongo/macho-out.wav';
import hembraIn from '../../common/sounds/bongo/hembra-in.wav';
import hembraOut from '../../common/sounds/bongo/hembra-out.wav';
import { Tooltip, Typography } from '@mui/material';
import { Info } from '@mui/icons-material';

let cleanHembraOut = 0;
let cleanHembraIn = 0;
let cleanMachoOut = 0;
let cleanMachoIn = 0;
const ANIMATION_TIMEOUT = 500;

export default function Bongo() {
    const [userHasInteracted, setUserHasInteracted] = useState(false);
    const [hitMachoIn, setHitMachoIn] = useState(false);
    const [hitMachoOut, setHitMachoOut] = useState(false);
    const [hitHembraIn, setHitHembraIn] = useState(false);
    const [hitHembraOut, setHitHembraOut] = useState(false);
    const [playMachoIn] = useSound(machoIn);
    const [playMachoOut] = useSound(machoOut);
    const [playHembraIn] = useSound(hembraIn);
    const [playHembraOut] = useSound(hembraOut);

    useEffect(() => {
        document.title = 'Home - Jeux - Bongo';
    }, []);

    const handler = useCallback(
        (keyCode) => {
            if (!userHasInteracted) return;
            switch (keyCode) {
                case 37:
                    playHembraOut();
                    setHitHembraOut(true);
                    if (cleanHembraOut !== 0) {
                        clearTimeout(cleanHembraOut);
                    }
                    cleanHembraOut = setTimeout(() => setHitHembraOut(false), ANIMATION_TIMEOUT);
                    break;
                case 38:
                    playHembraIn();
                    setHitHembraIn(true);
                    if (cleanHembraIn !== 0) {
                        clearTimeout(cleanHembraIn);
                    }
                    cleanHembraIn = setTimeout(() => setHitHembraIn(false), ANIMATION_TIMEOUT);
                    break;
                case 39:
                    playMachoOut();
                    setHitMachoOut(true);
                    if (cleanMachoOut !== 0) {
                        clearTimeout(cleanMachoOut);
                    }
                    cleanMachoOut = setTimeout(() => setHitMachoOut(false), ANIMATION_TIMEOUT);
                    break;
                case 40:
                    playMachoIn();
                    setHitMachoIn(true);
                    if (cleanMachoIn !== 0) {
                        clearTimeout(cleanMachoIn);
                    }
                    cleanMachoIn = setTimeout(() => setHitMachoIn(false), ANIMATION_TIMEOUT);
                    break;
                default:
                    break;
            }
        },
        [
            userHasInteracted,
            setHitMachoIn,
            setHitMachoOut,
            setHitHembraIn,
            setHitHembraOut,
            playHembraOut,
            playHembraIn,
            playMachoOut,
            playMachoIn
        ]
    );

    useKeyDownListener(handler);

    return (
        <React.Fragment>
            <div className="col full" onClick={() => setUserHasInteracted(true)}>
                <div className="row mt-l space-evenly">
                    <Typography variant="h3">
                        Bongos fever{' '}
                        <Tooltip
                            title={
                                <React.Fragment>
                                    <Typography variant="h5">Configuration :</Typography>
                                    <ul style={{ paddingLeft: '5px' }}>
                                        <li>← : hembra (gros) extérieur</li>
                                        <li>↑ : hembra (gros) intérieur</li>
                                        <li>→ : macho (petit) extérieur</li>
                                        <li>↓ : macho (petit) intérieur</li>
                                    </ul>
                                </React.Fragment>
                            }
                            arrow
                        >
                            <Info />
                        </Tooltip>
                    </Typography>
                </div>
                {!userHasInteracted ? (
                    <div className="row justify-center">
                        <p>Il faut cliquer sur la page afin de pouvoir jouer</p>
                    </div>
                ) : null}

                <div className="row mt-l space-evenly">
                    <div className="col main-circle justify-center">
                        <div className="row">
                            <div className={`circle justify-center ${hitHembraOut ? 'hit' : ''}`}>
                                <div className="row justify-center">
                                    <div className={`sub-circle ${hitHembraIn ? 'hit' : ''}`}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col next-circle justify-center">
                        <div className="row">
                            <div className={`circle justify-center ${hitMachoOut ? 'hit' : ''}`}>
                                <div className="row justify-center">
                                    <div className={`sub-circle ${hitMachoIn ? 'hit' : ''}`}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
