export default [
    'abaissai',
    'abaissas',
    'abaissât',
    'abaissée',
    'abaisser',
    'abaisses',
    'abaissés',
    'abaissez',
    'abajoues',
    'abandons',
    'abatages',
    'abâtardi',
    'abattage',
    'abattais',
    'abattait',
    'abattant',
    'abattées',
    'abattent',
    'abatteur',
    'abattiez',
    'abattoir',
    'abattons',
    'abattrai',
    'abattras',
    'abattrez',
    'abattues',
    'abbatial',
    'abbesses',
    'abdiquai',
    'abdiquas',
    'abdiquât',
    'abdiquée',
    'abdiquer',
    'abdiques',
    'abdiqués',
    'abdiquez',
    'abdomens',
    'abeilles',
    'aberrais',
    'aberrait',
    'aberrant',
    'aberrent',
    'aberrera',
    'aberriez',
    'aberrons',
    'abêtîmes',
    'abêtirai',
    'abêtiras',
    'abêtirez',
    'abêtisse',
    'abêtîtes',
    'abhorrai',
    'abhorras',
    'abhorrât',
    'abhorrée',
    'abhorrer',
    'abhorres',
    'abhorrés',
    'abhorrez',
    'abîmâmes',
    'abîmasse',
    'abîmâtes',
    'abîmerai',
    'abîmeras',
    'abîmerez',
    'abîmions',
    'abjectes',
    'abjurais',
    'abjurait',
    'abjurant',
    'abjurées',
    'abjurent',
    'abjurera',
    'abjuriez',
    'abjurons',
    'ablatifs',
    'ablation',
    'ablative',
    'ablégats',
    'ablettes',
    'abloquai',
    'abloquas',
    'abloquât',
    'abloquée',
    'abloquer',
    'abloques',
    'abloqués',
    'abloquez',
    'ablution',
    'aboierai',
    'aboieras',
    'aboierez',
    'abolîmes',
    'abolirai',
    'aboliras',
    'abolirez',
    'abolisse',
    'abolîtes',
    'abominai',
    'abominas',
    'abominât',
    'abominée',
    'abominer',
    'abomines',
    'abominés',
    'abominez',
    'abondais',
    'abondait',
    'abondant',
    'abondent',
    'abondera',
    'abondiez',
    'abondons',
    'abonnais',
    'abonnait',
    'abonnant',
    'abonnées',
    'abonnent',
    'abonnera',
    'abonnies',
    'abonniez',
    'abonnira',
    'abonnons',
    'abordage',
    'abordais',
    'abordait',
    'abordant',
    'abordées',
    'abordent',
    'abordera',
    'abordiez',
    'abordons',
    'abortifs',
    'abortive',
    'abouchai',
    'abouchas',
    'abouchât',
    'abouchée',
    'aboucher',
    'abouches',
    'abouchés',
    'abouchez',
    'aboulais',
    'aboulait',
    'aboulant',
    'aboulées',
    'aboulent',
    'aboulera',
    'aboulies',
    'abouliez',
    'aboulons',
    'aboutais',
    'aboutait',
    'aboutant',
    'aboutées',
    'aboutent',
    'aboutera',
    'aboutiez',
    'aboutira',
    'aboutons',
    'aboyâmes',
    'aboyasse',
    'aboyâtes',
    'aboyeurs',
    'aboyeuse',
    'aboyions',
    'abrasais',
    'abrasait',
    'abrasant',
    'abrasées',
    'abrasent',
    'abrasera',
    'abrasiez',
    'abrasifs',
    'abrasion',
    'abrasive',
    'abrasons',
    'abrégeai',
    'abrégeas',
    'abrégeât',
    'abrégées',
    'abrègent',
    'abrégera',
    'abrégiez',
    'abreuvai',
    'abreuvas',
    'abreuvât',
    'abreuvée',
    'abreuver',
    'abreuves',
    'abreuvés',
    'abreuvez',
    'abricota',
    'abricote',
    'abricoté',
    'abricots',
    'abritais',
    'abritait',
    'abritant',
    'abritées',
    'abritent',
    'abritera',
    'abritiez',
    'abritons',
    'abrivent',
    'abrogeai',
    'abrogeas',
    'abrogeât',
    'abrogées',
    'abrogent',
    'abrogera',
    'abrogiez',
    'abruptes',
    'abruties',
    'abrutira',
    'abscisse',
    'absconse',
    'absences',
    'absentée',
    'absenter',
    'absentes',
    'absentés',
    'absentez',
    'absinthe',
    'absolues',
    'absolves',
    'absolvez',
    'absorbai',
    'absorbas',
    'absorbât',
    'absorbée',
    'absorber',
    'absorbes',
    'absorbés',
    'absorbez',
    'absoudra',
    'absoudre',
    'absoutes',
    'abstèmes',
    'abstenez',
    'abstenir',
    'abstenue',
    'abstenus',
    'abstraie',
    'abstrais',
    'abstrait',
    'abstruse',
    'absurdes',
    'abusâmes',
    'abusasse',
    'abusâtes',
    'abuserai',
    'abuseras',
    'abuserez',
    'abusions',
    'abusives',
    'abyssale',
    'abyssaux',
    'abyssine',
    'abyssins',
    'académie',
    'acadiens',
    'acalèphe',
    'acanthes',
    'acariens',
    'accablai',
    'accablas',
    'accablât',
    'accablée',
    'accabler',
    'accables',
    'accablés',
    'accablez',
    'accalmie',
    'accapara',
    'accapare',
    'accaparé',
    'accédais',
    'accédait',
    'accédant',
    'accèdent',
    'accédera',
    'accédiez',
    'accédons',
    'accéléra',
    'accéléré',
    'accélère',
    'accentua',
    'accentue',
    'accentué',
    'acceptai',
    'acceptas',
    'acceptât',
    'acceptée',
    'accepter',
    'acceptes',
    'acceptés',
    'acceptez',
    'accessit',
    'accident',
    'acclamai',
    'acclamas',
    'acclamât',
    'acclamée',
    'acclamer',
    'acclames',
    'acclamés',
    'acclamez',
    'accointé',
    'accolade',
    'accolais',
    'accolait',
    'accolant',
    'accolées',
    'accolent',
    'accolera',
    'accoliez',
    'accolons',
    'accompli',
    'accorais',
    'accorait',
    'accorant',
    'accordai',
    'accordas',
    'accordât',
    'accordée',
    'accorder',
    'accordes',
    'accordés',
    'accordez',
    'accorées',
    'accorent',
    'accorera',
    'accoriez',
    'accorons',
    'accortes',
    'accostai',
    'accostas',
    'accostât',
    'accostée',
    'accoster',
    'accostes',
    'accostés',
    'accostez',
    'accotais',
    'accotait',
    'accotant',
    'accotées',
    'accotent',
    'accotera',
    'accotiez',
    'accotoir',
    'accotons',
    'accouais',
    'accouait',
    'accouant',
    'accoucha',
    'accouche',
    'accouché',
    'accoudée',
    'accouder',
    'accoudés',
    'accoudez',
    'accouées',
    'accouent',
    'accouera',
    'accouiez',
    'accouons',
    'accoupla',
    'accouple',
    'accouplé',
    'accourci',
    'accoures',
    'accourez',
    'accourir',
    'accourra',
    'accourue',
    'accourus',
    'accourut',
    'accourût',
    'accoutra',
    'accoutre',
    'accoutré',
    'accrocha',
    'accroche',
    'accroché',
    'accroire',
    'accroupi',
    'accrûmes',
    'accrusse',
    'accrûtes',
    'accueils',
    'acculais',
    'acculait',
    'acculant',
    'acculées',
    'acculent',
    'acculera',
    'acculiez',
    'acculons',
    'accumula',
    'accumule',
    'accumulé',
    'accusais',
    'accusait',
    'accusant',
    'accusées',
    'accusent',
    'accusera',
    'accusiez',
    'accusons',
    'acéphale',
    'acérâmes',
    'acérasse',
    'acérâtes',
    'acerbité',
    'acérerai',
    'acéreras',
    'acérerez',
    'acérions',
    'acescent',
    'acétates',
    'acétifia',
    'acétifie',
    'acétifié',
    'acétique',
    'acétones',
    'acétylai',
    'acétylas',
    'acétylât',
    'acétylée',
    'acétyler',
    'acétyles',
    'acétylés',
    'acétylez',
    'acharnai',
    'acharnas',
    'acharnât',
    'acharnée',
    'acharner',
    'acharnes',
    'acharnés',
    'acharnez',
    'achéenne',
    'achemina',
    'achemine',
    'acheminé',
    'achetais',
    'achetait',
    'achetant',
    'achetées',
    'achètent',
    'achètera',
    'acheteur',
    'achetiez',
    'achetons',
    'achevais',
    'achevait',
    'achevant',
    'achevées',
    'achèvent',
    'achèvera',
    'acheviez',
    'achevons',
    'achigans',
    'achillée',
    'acholies',
    'achoppée',
    'achopper',
    'achoppés',
    'achoppez',
    'achromat',
    'achromes',
    'achromie',
    'achylies',
    'acidalie',
    'acidifia',
    'acidifie',
    'acidifié',
    'acidités',
    'acidoses',
    'acidulai',
    'acidulas',
    'acidulât',
    'acidulée',
    'aciduler',
    'acidules',
    'acidulés',
    'acidulez',
    'aciérage',
    'aciérais',
    'aciérait',
    'aciérant',
    'aciérées',
    'acièrent',
    'aciérera',
    'aciéries',
    'aciériez',
    'aciérons',
    'aciselai',
    'aciselas',
    'aciselât',
    'aciselée',
    'aciseler',
    'aciselés',
    'acisèles',
    'aciselez',
    'acnéique',
    'acolytes',
    'acomptes',
    'aconages',
    'aconiers',
    'acoquiné',
    'acquérez',
    'acquérir',
    'acquerra',
    'acquière',
    'acquiers',
    'acquiert',
    'acquîmes',
    'acquises',
    'acquisse',
    'acquîtes',
    'acquitta',
    'acquitte',
    'acquitté',
    'acrobate',
    'acromion',
    'acronyme',
    'acropole',
    'acrotère',
    'actaient',
    'actasses',
    'acterais',
    'acterait',
    'actèrent',
    'acteriez',
    'acterons',
    'acteront',
    'actinies',
    'actinite',
    'actinium',
    'actinote',
    'actionna',
    'actionne',
    'actionné',
    'activais',
    'activait',
    'activant',
    'activées',
    'activent',
    'activera',
    'activeur',
    'activiez',
    'activité',
    'activons',
    'actrices',
    'actuaire',
    'actuelle',
    'acuminée',
    'acuminés',
    'adamisme',
    'adaptais',
    'adaptait',
    'adaptant',
    'adaptées',
    'adaptent',
    'adaptera',
    'adaptiez',
    'adaptons',
    'additifs',
    'addition',
    'additive',
    'adénites',
    'adénoïde',
    'adénomes',
    'adéquate',
    'adéquats',
    'adextrée',
    'adextrés',
    'adhérais',
    'adhérait',
    'adhérant',
    'adhérent',
    'adhèrent',
    'adhérera',
    'adhériez',
    'adhérons',
    'adhésifs',
    'adhésion',
    'adhésive',
    'adipeuse',
    'adipique',
    'adiposes',
    'adipsies',
    'adjacent',
    'adjectif',
    'adjoigne',
    'adjointe',
    'adjoints',
    'adjudant',
    'adjugeai',
    'adjugeas',
    'adjugeât',
    'adjugées',
    'adjugent',
    'adjugera',
    'adjugiez',
    'adjurais',
    'adjurait',
    'adjurant',
    'adjurées',
    'adjurent',
    'adjurera',
    'adjuriez',
    'adjurons',
    'adjuvant',
    'adjuvats',
    'admettes',
    'admettez',
    'admettra',
    'admettre',
    'admirais',
    'admirait',
    'admirant',
    'admirées',
    'admirent',
    'admirera',
    'admiriez',
    'admirons',
    'admisses',
    'adonisée',
    'adoniser',
    'adonisés',
    'adonisez',
    'adonnées',
    'adonniez',
    'adonnons',
    'adoptais',
    'adoptait',
    'adoptant',
    'adoptées',
    'adoptent',
    'adoptera',
    'adoptiez',
    'adoptifs',
    'adoption',
    'adoptive',
    'adoptons',
    'adorable',
    'adorâmes',
    'adorasse',
    'adorâtes',
    'adorerai',
    'adoreras',
    'adorerez',
    'adorions',
    'adossais',
    'adossait',
    'adossant',
    'adossées',
    'adossent',
    'adossera',
    'adossiez',
    'adossons',
    'adoubais',
    'adoubait',
    'adoubant',
    'adoubées',
    'adoubent',
    'adoubera',
    'adoubiez',
    'adoubons',
    'adoucies',
    'adoucira',
    'adressai',
    'adressas',
    'adressât',
    'adressée',
    'adresser',
    'adresses',
    'adressés',
    'adressez',
    'adroites',
    'adstrats',
    'adulâmes',
    'adulasse',
    'adulâtes',
    'adulerai',
    'aduleras',
    'adulerez',
    'adulions',
    'adultéra',
    'adultéré',
    'adultère',
    'advenait',
    'adventif',
    'advenues',
    'adverbes',
    'adverses',
    'advienne',
    'adynamie',
    'aegosome',
    'aéraient',
    'aérasses',
    'aérateur',
    'aération',
    'aérerais',
    'aérerait',
    'aérèrent',
    'aéreriez',
    'aérerons',
    'aéreront',
    'aérienne',
    'aérobies',
    'aérogare',
    'aérolite',
    'aéronefs',
    'aéroport',
    'aérosols',
    'aérostat',
    'aeschnes',
    'affables',
    'affabula',
    'affabule',
    'affabulé',
    'affadies',
    'affadira',
    'affaibli',
    'affairée',
    'affairer',
    'affaires',
    'affairés',
    'affairez',
    'affaissa',
    'affaisse',
    'affaissé',
    'affalais',
    'affalait',
    'affalant',
    'affalées',
    'affalent',
    'affalera',
    'affaliez',
    'affalons',
    'affamais',
    'affamait',
    'affamant',
    'affamées',
    'affament',
    'affamera',
    'affameur',
    'affamiez',
    'affamons',
    'afféagea',
    'afféagée',
    'afféager',
    'afféages',
    'afféagés',
    'afféagez',
    'affectai',
    'affectas',
    'affectât',
    'affectée',
    'affecter',
    'affectes',
    'affectés',
    'affectez',
    'affectif',
    'affenage',
    'afférais',
    'afférait',
    'afférant',
    'afférent',
    'affèrent',
    'afférera',
    'affériez',
    'affermai',
    'affermas',
    'affermât',
    'affermée',
    'affermer',
    'affermes',
    'affermés',
    'affermez',
    'affermie',
    'affermir',
    'affermis',
    'affermit',
    'affermît',
    'afférons',
    'affétées',
    'affichai',
    'affichas',
    'affichât',
    'affichée',
    'afficher',
    'affiches',
    'affichés',
    'affichez',
    'affidées',
    'affilage',
    'affilais',
    'affilait',
    'affilant',
    'affilées',
    'affilent',
    'affilera',
    'affiliai',
    'affilias',
    'affiliât',
    'affiliée',
    'affilier',
    'affilies',
    'affiliés',
    'affiliez',
    'affiloir',
    'affilons',
    'affinage',
    'affinais',
    'affinait',
    'affinant',
    'affinées',
    'affinent',
    'affinera',
    'affineur',
    'affiniez',
    'affinité',
    'affinons',
    'affiquet',
    'affirmai',
    'affirmas',
    'affirmât',
    'affirmée',
    'affirmer',
    'affirmes',
    'affirmés',
    'affirmez',
    'affixale',
    'affixaux',
    'affleura',
    'affleure',
    'affleuré',
    'affligea',
    'affligée',
    'affliger',
    'affliges',
    'affligés',
    'affligez',
    'afflouai',
    'afflouas',
    'afflouât',
    'afflouée',
    'afflouer',
    'affloues',
    'affloués',
    'afflouez',
    'affluais',
    'affluait',
    'affluant',
    'affluent',
    'affluera',
    'affluiez',
    'affluons',
    'affolais',
    'affolait',
    'affolant',
    'affolées',
    'affolent',
    'affolera',
    'affoliez',
    'affolons',
    'affouage',
    'affouagé',
    'affrétai',
    'affrétas',
    'affrétât',
    'affrétée',
    'affréter',
    'affrétés',
    'affrètes',
    'affrétez',
    'affreuse',
    'affricha',
    'affriche',
    'affriché',
    'affriola',
    'affriole',
    'affriolé',
    'affritai',
    'affritas',
    'affritât',
    'affritée',
    'affriter',
    'affrites',
    'affrités',
    'affritez',
    'affronta',
    'affronte',
    'affronté',
    'affronts',
    'affruita',
    'affruite',
    'affruité',
    'affublai',
    'affublas',
    'affublât',
    'affublée',
    'affubler',
    'affubles',
    'affublés',
    'affublez',
    'affurais',
    'affurait',
    'affurant',
    'affurées',
    'affurent',
    'affurera',
    'affuriez',
    'affurons',
    'affusion',
    'affûtage',
    'affûtais',
    'affûtait',
    'affûtant',
    'affûtées',
    'affûtent',
    'affûtera',
    'affûteur',
    'affûtiez',
    'affûtons',
    'afghanes',
    'afocales',
    'africain',
    'agaçâmes',
    'agaçante',
    'agaçants',
    'agaçasse',
    'agaçâtes',
    'agacerai',
    'agaceras',
    'agacerez',
    'agacerie',
    'agacions',
    'agatisée',
    'agatisés',
    'agençais',
    'agençait',
    'agençant',
    'agencées',
    'agencent',
    'agencera',
    'agenciez',
    'agençons',
    'agénésie',
    'agérates',
    'aggravai',
    'aggravas',
    'aggravât',
    'aggravée',
    'aggraver',
    'aggraves',
    'aggravés',
    'aggravez',
    'agilités',
    'agiotage',
    'agiotais',
    'agiotait',
    'agiotant',
    'agiotent',
    'agiotera',
    'agioteur',
    'agiotiez',
    'agiotons',
    'agirions',
    'agissais',
    'agissait',
    'agissant',
    'agissent',
    'agissiez',
    'agissons',
    'agitâmes',
    'agitasse',
    'agitâtes',
    'agiterai',
    'agiteras',
    'agiterez',
    'agitions',
    'aglyphes',
    'agnathes',
    'agnelage',
    'agnelais',
    'agnelait',
    'agnelant',
    'agnelets',
    'agneliez',
    'agneline',
    'agnelins',
    'agnelles',
    'agnelons',
    'agnosies',
    'agonîmes',
    'agonirai',
    'agoniras',
    'agonirez',
    'agonisai',
    'agonisas',
    'agonisât',
    'agoniser',
    'agonises',
    'agonisez',
    'agonisse',
    'agonîtes',
    'agrafage',
    'agrafais',
    'agrafait',
    'agrafant',
    'agrafées',
    'agrafent',
    'agrafera',
    'agrafiez',
    'agrafons',
    'agraires',
    'agrandie',
    'agrandir',
    'agrandis',
    'agrandit',
    'agrandît',
    'agraphie',
    'agrarien',
    'agréable',
    'agréâmes',
    'agréasse',
    'agréâtes',
    'agréerai',
    'agréeras',
    'agréerez',
    'agrégats',
    'agrégeai',
    'agrégeas',
    'agrégeât',
    'agrégées',
    'agrègent',
    'agrégera',
    'agrégiez',
    'agréions',
    'agrément',
    'agressai',
    'agressas',
    'agressât',
    'agressée',
    'agresser',
    'agresses',
    'agressés',
    'agressez',
    'agressif',
    'agrestes',
    'agrichai',
    'agrichas',
    'agrichât',
    'agrichée',
    'agricher',
    'agriches',
    'agrichés',
    'agrichez',
    'agricole',
    'agriffée',
    'agriffer',
    'agriffés',
    'agriffez',
    'agriotes',
    'agrippai',
    'agrippas',
    'agrippât',
    'agrippée',
    'agripper',
    'agrippes',
    'agrippés',
    'agrippez',
    'agronome',
    'agrostis',
    'aguerrie',
    'aguerrir',
    'aguerris',
    'aguerrit',
    'aguerrît',
    'agueusie',
    'aguichai',
    'aguichas',
    'aguichât',
    'aguichée',
    'aguicher',
    'aguiches',
    'aguichés',
    'aguichez',
    'ahanâmes',
    'ahanasse',
    'ahanâtes',
    'ahanerai',
    'ahaneras',
    'ahanerez',
    'ahanions',
    'aheurtée',
    'aheurter',
    'aheurtés',
    'aheurtez',
    'ahurîmes',
    'ahurirai',
    'ahuriras',
    'ahurirez',
    'ahurisse',
    'ahurîtes',
    'aidaient',
    'aidasses',
    'aiderais',
    'aiderait',
    'aidèrent',
    'aideriez',
    'aiderons',
    'aideront',
    'aiglefin',
    'aiglonne',
    'aigrefin',
    'aigrelet',
    'aigrette',
    'aigretté',
    'aigreurs',
    'aigrîmes',
    'aigrirai',
    'aigriras',
    'aigrirez',
    'aigrisse',
    'aigrîtes',
    'aiguière',
    'aiguilla',
    'aiguille',
    'aiguisai',
    'aiguisas',
    'aiguisât',
    'aiguisée',
    'aiguiser',
    'aiguises',
    'aiguisés',
    'aiguisez',
    'ailantes',
    'ailerons',
    'ailettes',
    'aillâmes',
    'aillasse',
    'aillâtes',
    'aillerai',
    'ailleras',
    'aillerez',
    'ailleurs',
    'aillions',
    'aillolis',
    'aimables',
    'aimaient',
    'aimantai',
    'aimantas',
    'aimantât',
    'aimantée',
    'aimanter',
    'aimantes',
    'aimantés',
    'aimantez',
    'aimasses',
    'aimerais',
    'aimerait',
    'aimèrent',
    'aimeriez',
    'aimerons',
    'aimeront',
    'aînesses',
    'airaient',
    'airasses',
    'airedale',
    'airelles',
    'airerais',
    'airerait',
    'airèrent',
    'aireriez',
    'airerons',
    'aireront',
    'aisances',
    'aisément',
    'aisselle',
    'ajointai',
    'ajointas',
    'ajointât',
    'ajointée',
    'ajointer',
    'ajointes',
    'ajointés',
    'ajointez',
    'ajourais',
    'ajourait',
    'ajourant',
    'ajourées',
    'ajourent',
    'ajourera',
    'ajouriez',
    'ajournai',
    'ajournas',
    'ajournât',
    'ajournée',
    'ajourner',
    'ajournes',
    'ajournés',
    'ajournez',
    'ajourons',
    'ajoutais',
    'ajoutait',
    'ajoutant',
    'ajoutées',
    'ajoutent',
    'ajoutera',
    'ajoutiez',
    'ajoutons',
    'ajustage',
    'ajustais',
    'ajustait',
    'ajustant',
    'ajustées',
    'ajustent',
    'ajustera',
    'ajusteur',
    'ajustiez',
    'ajustons',
    'akinésie',
    'akkadien',
    'akvavits',
    'alambics',
    'alandier',
    'alanguie',
    'alanguir',
    'alanguis',
    'alanguit',
    'alanguît',
    'alanines',
    'alarmais',
    'alarmait',
    'alarmant',
    'alarmées',
    'alarment',
    'alarmera',
    'alarmiez',
    'alarmons',
    'alastrim',
    'alaterne',
    'albanais',
    'albâtres',
    'albatros',
    'alberges',
    'albuginé',
    'albumens',
    'albumine',
    'albuminé',
    'albumose',
    'alcaïque',
    'alcaline',
    'alcalins',
    'alcalisa',
    'alcalise',
    'alcalisé',
    'alcalose',
    'alchimie',
    'alcoolat',
    'alcoolés',
    'alcoyles',
    'aldéhyde',
    'alenties',
    'alentira',
    'alentour',
    'alépines',
    'alérions',
    'alertais',
    'alertait',
    'alertant',
    'alertées',
    'alertent',
    'alertera',
    'alertiez',
    'alertons',
    'alésages',
    'alésâmes',
    'alésasse',
    'alésâtes',
    'aléserai',
    'aléseras',
    'aléserez',
    'aléseurs',
    'aléseuse',
    'alésions',
    'alésoirs',
    'aleurite',
    'aleurode',
    'aleurone',
    'alevinai',
    'alevinas',
    'alevinât',
    'alevinée',
    'aleviner',
    'alevines',
    'alevinés',
    'alevinez',
    'alfatier',
    'alfénide',
    'algarade',
    'algèbres',
    'algérien',
    'algidité',
    'alginate',
    'algiques',
    'alicante',
    'alicants',
    'alidades',
    'aliénais',
    'aliénait',
    'aliénant',
    'aliénées',
    'aliènent',
    'aliénera',
    'aliéniez',
    'aliénons',
    'alifères',
    'alignais',
    'alignait',
    'alignant',
    'alignées',
    'alignent',
    'alignera',
    'aligniez',
    'alignons',
    'aligotés',
    'alimenta',
    'alimente',
    'aliments',
    'aliquote',
    'alisiers',
    'alitâmes',
    'alitasse',
    'alitâtes',
    'aliterai',
    'aliteras',
    'aliterez',
    'alitions',
    'alkermès',
    'allaient',
    'allaitai',
    'allaitas',
    'allaitât',
    'allaitée',
    'allaiter',
    'allaites',
    'allaités',
    'allaitez',
    'allantes',
    'allasses',
    'alléchai',
    'alléchas',
    'alléchât',
    'alléchée',
    'allécher',
    'alléchés',
    'allèches',
    'alléchez',
    'allégeai',
    'allégeas',
    'allégeât',
    'allégées',
    'allègent',
    'allégera',
    'allégies',
    'allégiez',
    'allégira',
    'allègres',
    'allegros',
    'alléguai',
    'alléguas',
    'alléguât',
    'alléguée',
    'alléguer',
    'allégués',
    'allègues',
    'alléguez',
    'alléluia',
    'allemand',
    'allèrent',
    'allergie',
    'alliacée',
    'alliacés',
    'alliages',
    'alliâmes',
    'alliance',
    'alliasse',
    'alliâtes',
    'allierai',
    'allieras',
    'allierez',
    'alliions',
    'allodial',
    'allogène',
    'allongea',
    'allongée',
    'allonger',
    'allonges',
    'allongés',
    'allongez',
    'allouais',
    'allouait',
    'allouant',
    'allouées',
    'allouent',
    'allouera',
    'allouiez',
    'allouons',
    'alluchon',
    'allumage',
    'allumais',
    'allumait',
    'allumant',
    'allumées',
    'allument',
    'allumera',
    'allumeur',
    'allumiez',
    'allumons',
    'allurées',
    'allusifs',
    'allusion',
    'allusive',
    'alluvial',
    'alluvion',
    'almanach',
    'almandin',
    'alogique',
    'alopécie',
    'alouates',
    'alouette',
    'alourdie',
    'alourdir',
    'alourdis',
    'alourdit',
    'alourdît',
    'alpaguai',
    'alpaguas',
    'alpaguât',
    'alpaguée',
    'alpaguer',
    'alpagues',
    'alpaguez',
    'alpestre',
    'alphabet',
    'alpistes',
    'alsacien',
    'altérais',
    'altérait',
    'altérant',
    'altérées',
    'altèrent',
    'altérera',
    'altériez',
    'altérité',
    'alternai',
    'alternas',
    'alternat',
    'alternât',
    'alternée',
    'alterner',
    'alternes',
    'alternés',
    'alternez',
    'altérons',
    'altesses',
    'altières',
    'altiport',
    'altistes',
    'altitude',
    'alucites',
    'aluminai',
    'aluminas',
    'aluminât',
    'aluminée',
    'aluminer',
    'alumines',
    'aluminés',
    'aluminez',
    'alunâmes',
    'alunasse',
    'alunâtes',
    'alunerai',
    'aluneras',
    'alunerez',
    'alunîmes',
    'alunions',
    'alunirai',
    'aluniras',
    'alunirez',
    'alunisse',
    'alunites',
    'alunîtes',
    'alvéolée',
    'alvéoles',
    'alvéolés',
    'amadouai',
    'amadouas',
    'amadouât',
    'amadouée',
    'amadouer',
    'amadoues',
    'amadoués',
    'amadouez',
    'amaigrie',
    'amaigrir',
    'amaigris',
    'amaigrit',
    'amaigrît',
    'amalgama',
    'amalgame',
    'amalgamé',
    'amandaie',
    'amandier',
    'amanites',
    'amarante',
    'amariles',
    'amarinai',
    'amarinas',
    'amarinât',
    'amarinée',
    'amariner',
    'amarines',
    'amarinés',
    'amarinez',
    'amarrage',
    'amarrais',
    'amarrait',
    'amarrant',
    'amarrées',
    'amarrent',
    'amarrera',
    'amarriez',
    'amarrons',
    'amassais',
    'amassait',
    'amassant',
    'amassées',
    'amassent',
    'amassera',
    'amassiez',
    'amassons',
    'amateurs',
    'amatîmes',
    'amatirai',
    'amatiras',
    'amatirez',
    'amatisse',
    'amatîtes',
    'amaurose',
    'amazones',
    'ambiance',
    'ambiante',
    'ambiants',
    'ambiguës',
    'ambition',
    'amblâmes',
    'amblasse',
    'amblâtes',
    'amblerai',
    'ambleras',
    'amblerez',
    'ambleurs',
    'ambleuse',
    'amblions',
    'amblyope',
    'ambrâmes',
    'ambrasse',
    'ambrâtes',
    'ambrerai',
    'ambreras',
    'ambrerez',
    'ambrions',
    'ambulant',
    'améliora',
    'améliore',
    'amélioré',
    'aménagea',
    'aménagée',
    'aménager',
    'aménages',
    'aménagés',
    'aménagez',
    'amenâmes',
    'amenasse',
    'amenâtes',
    'amendais',
    'amendait',
    'amendant',
    'amendées',
    'amendent',
    'amendera',
    'amendiez',
    'amendons',
    'amènerai',
    'amèneras',
    'amènerez',
    'amenions',
    'aménités',
    'amenuisa',
    'amenuise',
    'amenuisé',
    'amerrira',
    'amertume',
    'amétrope',
    'ameublie',
    'ameublir',
    'ameublis',
    'ameublit',
    'ameublît',
    'ameutais',
    'ameutait',
    'ameutant',
    'ameutées',
    'ameutent',
    'ameutera',
    'ameutiez',
    'ameutons',
    'amiables',
    'amiantes',
    'amibiase',
    'amibiens',
    'amiboïde',
    'amicales',
    'amidonna',
    'amidonne',
    'amidonné',
    'amincies',
    'amincira',
    'amirales',
    'amirauté',
    'amitoses',
    'ammodyte',
    'ammoniac',
    'ammonite',
    'ammonium',
    'amnésies',
    'amnistia',
    'amnistie',
    'amnistié',
    'amochais',
    'amochait',
    'amochant',
    'amochées',
    'amochent',
    'amochera',
    'amochiez',
    'amochons',
    'amodiais',
    'amodiait',
    'amodiant',
    'amodiées',
    'amodient',
    'amodiera',
    'amodiiez',
    'amodions',
    'amoindri',
    'amollies',
    'amollira',
    'amoncela',
    'amoncelé',
    'amorales',
    'amorçage',
    'amorçais',
    'amorçait',
    'amorçant',
    'amorcées',
    'amorcent',
    'amorcera',
    'amorciez',
    'amorçons',
    'amorphes',
    'amorties',
    'amortira',
    'amoureux',
    'amovible',
    'amphibie',
    'amphores',
    'ampleurs',
    'amplifia',
    'amplifie',
    'amplifié',
    'ampoulée',
    'ampoules',
    'ampoulés',
    'amputais',
    'amputait',
    'amputant',
    'amputées',
    'amputent',
    'amputera',
    'amputiez',
    'amputons',
    'amuïrent',
    'amuïriez',
    'amuïrons',
    'amuïssez',
    'amulette',
    'amurâmes',
    'amurasse',
    'amurâtes',
    'amurerai',
    'amureras',
    'amurerez',
    'amurions',
    'amusâmes',
    'amusante',
    'amusants',
    'amusasse',
    'amusâtes',
    'amuserai',
    'amuseras',
    'amuserez',
    'amusette',
    'amuseurs',
    'amuseuse',
    'amusions',
    'amygdale',
    'amylacée',
    'amylacés',
    'amylases',
    'amylènes',
    'amylique',
    'anaconda',
    'anagogie',
    'analecta',
    'analités',
    'analogie',
    'analogon',
    'analogue',
    'analysai',
    'analysas',
    'analysât',
    'analysée',
    'analyser',
    'analyses',
    'analysés',
    'analysez',
    'analyste',
    'anamnèse',
    'anapeste',
    'anaphase',
    'anaphore',
    'anarchie',
    'anathème',
    'anatifes',
    'anatomie',
    'anavenin',
    'ancêtres',
    'ancienne',
    'ancolies',
    'ancrages',
    'ancrâmes',
    'ancrasse',
    'ancrâtes',
    'ancrerai',
    'ancreras',
    'ancrerez',
    'ancrions',
    'andalous',
    'andantes',
    'andésite',
    'andrènes',
    'androcée',
    'androïde',
    'anéantie',
    'anéantir',
    'anéantis',
    'anéantit',
    'anéantît',
    'anecdote',
    'anémiais',
    'anémiait',
    'anémiant',
    'anémiées',
    'anémient',
    'anémiera',
    'anémiiez',
    'anémions',
    'anémique',
    'anémones',
    'anéroïde',
    'angaries',
    'angéites',
    'angelots',
    'angevine',
    'angevins',
    'angineux',
    'angiomes',
    'anglaisa',
    'anglaise',
    'anglaisé',
    'anglican',
    'angoissa',
    'angoisse',
    'angoissé',
    'anguille',
    'anguleux',
    'anhélais',
    'anhélait',
    'anhélant',
    'anhèlent',
    'anhélera',
    'anhéliez',
    'anhélons',
    'anhydres',
    'anilines',
    'anilisme',
    'animales',
    'animâmes',
    'animasse',
    'animâtes',
    'animerai',
    'animeras',
    'animerez',
    'animions',
    'animisme',
    'animiste',
    'anisâmes',
    'anisasse',
    'anisâtes',
    'aniserai',
    'aniseras',
    'aniserez',
    'anisette',
    'anisions',
    'ankylosa',
    'ankylose',
    'ankylosé',
    'annalité',
    'annamite',
    'annelais',
    'annelait',
    'annelant',
    'annelées',
    'anneliez',
    'annelles',
    'annelons',
    'annexais',
    'annexait',
    'annexant',
    'annexées',
    'annexent',
    'annexera',
    'annexiez',
    'annexion',
    'annexons',
    'annihila',
    'annihile',
    'annihilé',
    'annonçai',
    'annonças',
    'annonçât',
    'annoncée',
    'annoncer',
    'annonces',
    'annoncés',
    'annoncez',
    'annotais',
    'annotait',
    'annotant',
    'annotées',
    'annotent',
    'annotera',
    'annotiez',
    'annotons',
    'annuaire',
    'annuelle',
    'annuités',
    'annulais',
    'annulait',
    'annulant',
    'annulées',
    'annulent',
    'annulera',
    'annuliez',
    'annulons',
    'anoblies',
    'anoblira',
    'anodines',
    'anodisai',
    'anodisas',
    'anodisât',
    'anodisée',
    'anodiser',
    'anodises',
    'anodisés',
    'anodisez',
    'anodonte',
    'anomales',
    'anomalie',
    'ânonnais',
    'ânonnait',
    'ânonnant',
    'ânonnées',
    'ânonnent',
    'ânonnera',
    'ânonniez',
    'ânonnons',
    'anonymat',
    'anonymes',
    'anophèle',
    'anordira',
    'anorexie',
    'anormale',
    'anormaux',
    'anosmies',
    'anoxémie',
    'ansérine',
    'antéfixe',
    'antenais',
    'antennes',
    'antéposa',
    'antépose',
    'antéposé',
    'anthémis',
    'anthères',
    'anthrène',
    'antibois',
    'antichar',
    'anticipa',
    'anticipe',
    'anticipé',
    'antidata',
    'antidate',
    'antidaté',
    'antidote',
    'antienne',
    'antigang',
    'antigels',
    'antigène',
    'antihalo',
    'antilope',
    'antimite',
    'antinazi',
    'antipape',
    'antipode',
    'antiques',
    'antivols',
    'antonyme',
    'anxiétés',
    'anxieuse',
    'aoristes',
    'aortique',
    'aortites',
    'aoûtâmes',
    'aoûtasse',
    'aoûtâtes',
    'aoûterai',
    'aoûteras',
    'aoûterez',
    'aoûtiens',
    'aoûtions',
    'apagogie',
    'apaisais',
    'apaisait',
    'apaisant',
    'apaisées',
    'apaisent',
    'apaisera',
    'apaisiez',
    'apaisons',
    'apanagea',
    'apanagée',
    'apanager',
    'apanages',
    'apanagés',
    'apanagez',
    'apathies',
    'apatride',
    'apepsies',
    'aperçois',
    'aperçoit',
    'aperçues',
    'apéritif',
    'aperture',
    'apétales',
    'apeurais',
    'apeurait',
    'apeurant',
    'apeurées',
    'apeurent',
    'apeurera',
    'apeuriez',
    'apeurons',
    'aphasies',
    'aphélies',
    'aphérèse',
    'aphonies',
    'aphteuse',
    'aphylles',
    'apicales',
    'apiéceur',
    'apiquage',
    'apiquais',
    'apiquait',
    'apiquant',
    'apiquées',
    'apiquent',
    'apiquera',
    'apiquiez',
    'apiquons',
    'apitoies',
    'apitoyai',
    'apitoyas',
    'apitoyât',
    'apitoyée',
    'apitoyer',
    'apitoyés',
    'apitoyez',
    'aplanies',
    'aplanira',
    'aplasies',
    'aplaties',
    'aplatira',
    'apoastre',
    'apocopes',
    'apogamie',
    'apollons',
    'apologie',
    'apologue',
    'apophyse',
    'apostais',
    'apostait',
    'apostant',
    'apostate',
    'apostats',
    'apostées',
    'apostent',
    'apostera',
    'apostiez',
    'apostons',
    'apothème',
    'appairai',
    'appairas',
    'appairât',
    'appairée',
    'appairer',
    'appaires',
    'appairés',
    'appairez',
    'apparais',
    'apparaît',
    'apparats',
    'apparaux',
    'appareil',
    'apparent',
    'appariai',
    'apparias',
    'appariât',
    'appariée',
    'apparier',
    'apparies',
    'appariés',
    'appariez',
    'apparues',
    'appâtais',
    'appâtait',
    'appâtant',
    'appâtées',
    'appâtent',
    'appâtera',
    'appâtiez',
    'appâtons',
    'appauvri',
    'appelais',
    'appelait',
    'appelant',
    'appelées',
    'appeliez',
    'appelles',
    'appelons',
    'appentis',
    'appétais',
    'appétait',
    'appétant',
    'appétées',
    'appètent',
    'appétera',
    'appétiez',
    'appétits',
    'appétons',
    'applaudi',
    'appliqua',
    'applique',
    'appliqué',
    'appointa',
    'appointe',
    'appointé',
    'appointi',
    'appoints',
    'appontai',
    'appontas',
    'appontât',
    'apponter',
    'appontes',
    'appontez',
    'apportai',
    'apportas',
    'apportât',
    'apportée',
    'apporter',
    'apportes',
    'apportés',
    'apportez',
    'apposais',
    'apposait',
    'apposant',
    'apposées',
    'apposent',
    'apposera',
    'apposiez',
    'apposons',
    'apprécia',
    'apprécie',
    'apprécié',
    'apprends',
    'apprenez',
    'apprenne',
    'apprenti',
    'apprêtai',
    'apprêtas',
    'apprêtât',
    'apprêtée',
    'apprêter',
    'apprêtes',
    'apprêtés',
    'apprêtez',
    'apprîmes',
    'apprises',
    'apprisse',
    'apprîtes',
    'approcha',
    'approche',
    'approché',
    'approuva',
    'approuve',
    'approuvé',
    'appuient',
    'appuiera',
    'appuyais',
    'appuyait',
    'appuyant',
    'appuyées',
    'appuyiez',
    'appuyons',
    'apraxies',
    'âprement',
    'à-propos',
    'aptitude',
    'apurâmes',
    'apurasse',
    'apurâtes',
    'apurerai',
    'apureras',
    'apurerez',
    'apurions',
    'aquarium',
    'aqueducs',
    'aqueuses',
    'aquicole',
    'aquilins',
    'aquilons',
    'arabicas',
    'arabique',
    'arabisai',
    'arabisas',
    'arabisât',
    'arabisée',
    'arabiser',
    'arabises',
    'arabisés',
    'arabisez',
    'arachide',
    'araignée',
    'araméens',
    'aranéide',
    'arantèle',
    'arasâmes',
    'arasasse',
    'arasâtes',
    'araserai',
    'araseras',
    'araserez',
    'arasions',
    'aratoire',
    'arbalète',
    'arbitrai',
    'arbitral',
    'arbitras',
    'arbitrât',
    'arbitrée',
    'arbitrer',
    'arbitres',
    'arbitrés',
    'arbitrez',
    'arborais',
    'arborait',
    'arborant',
    'arborées',
    'arborent',
    'arborera',
    'arboriez',
    'arborisa',
    'arborise',
    'arborisé',
    'arborons',
    'arbouses',
    'arbustes',
    'arbustif',
    'arcanson',
    'arcasses',
    'arcature',
    'archaïsa',
    'archaïse',
    'archaïsé',
    'archange',
    'archéens',
    'archelle',
    'archères',
    'archerie',
    'archiduc',
    'archipel',
    'archivai',
    'archivas',
    'archivât',
    'archivée',
    'archiver',
    'archives',
    'archivés',
    'archivez',
    'archonte',
    'arçonnai',
    'arçonnas',
    'arçonnât',
    'arçonnée',
    'arçonner',
    'arçonnes',
    'arçonnés',
    'arçonnez',
    'arctique',
    'ardentes',
    'ardillon',
    'ardoisai',
    'ardoisas',
    'ardoisât',
    'ardoisée',
    'ardoiser',
    'ardoises',
    'ardoisés',
    'ardoisez',
    'aréiques',
    'arénacée',
    'arénacés',
    'aréopage',
    'aréquier',
    'arêtiere',
    'argentai',
    'argentan',
    'argentas',
    'argentât',
    'argentée',
    'argenter',
    'argentes',
    'argentés',
    'argentez',
    'argentin',
    'argilacé',
    'argileux',
    'argotisa',
    'argotise',
    'argousin',
    'arguâmes',
    'arguasse',
    'arguâtes',
    'arguerai',
    'argueras',
    'arguerez',
    'arguions',
    'argument',
    'arguties',
    'argyrose',
    'aridités',
    'ariennes',
    'ariettes',
    'arisâmes',
    'arisasse',
    'arisâtes',
    'ariserai',
    'ariseras',
    'ariserez',
    'arisions',
    'arlequin',
    'armagnac',
    'armaient',
    'armailli',
    'armasses',
    'armateur',
    'armature',
    'armement',
    'arménien',
    'armerais',
    'armerait',
    'armèrent',
    'armeriez',
    'armerons',
    'armeront',
    'armeuses',
    'armilles',
    'arminien',
    'armoires',
    'armoises',
    'armoriai',
    'armorial',
    'armorias',
    'armoriât',
    'armoriée',
    'armorier',
    'armories',
    'armoriés',
    'armoriez',
    'armurier',
    'arnaquai',
    'arnaquas',
    'arnaquât',
    'arnaquée',
    'arnaquer',
    'arnaques',
    'arnaqués',
    'arnaquez',
    'aromates',
    'arpégeai',
    'arpégeas',
    'arpégeât',
    'arpégées',
    'arpègent',
    'arpégera',
    'arpégiez',
    'arpentai',
    'arpentas',
    'arpentât',
    'arpentée',
    'arpenter',
    'arpentes',
    'arpentés',
    'arpentez',
    'arpettes',
    'arquâmes',
    'arquasse',
    'arquâtes',
    'arquerai',
    'arqueras',
    'arquerez',
    'arquions',
    'arrachai',
    'arrachas',
    'arrachât',
    'arrachée',
    'arracher',
    'arraches',
    'arrachés',
    'arrachez',
    'arrachis',
    'arrangea',
    'arrangée',
    'arranger',
    'arranges',
    'arrangés',
    'arrangez',
    'arrentai',
    'arrentas',
    'arrentât',
    'arrentée',
    'arrenter',
    'arrentes',
    'arrentés',
    'arrentez',
    'arrérage',
    'arréragé',
    'arrêtais',
    'arrêtait',
    'arrêtant',
    'arrêtées',
    'arrêtent',
    'arrêtera',
    'arrêtiez',
    'arrêtoir',
    'arrêtons',
    'arriérai',
    'arriéras',
    'arriérât',
    'arriérée',
    'arriérer',
    'arriérés',
    'arrières',
    'arriérez',
    'arrimage',
    'arrimais',
    'arrimait',
    'arrimant',
    'arrimées',
    'arriment',
    'arrimera',
    'arrimeur',
    'arrimiez',
    'arrimons',
    'arrisais',
    'arrisait',
    'arrisant',
    'arrisent',
    'arrisera',
    'arrisiez',
    'arrisons',
    'arrivage',
    'arrivais',
    'arrivait',
    'arrivant',
    'arrivées',
    'arrivent',
    'arrivera',
    'arriviez',
    'arrivons',
    'arroches',
    'arrogant',
    'arrogées',
    'arrogiez',
    'arrondie',
    'arrondir',
    'arrondis',
    'arrondit',
    'arrondît',
    'arrosage',
    'arrosais',
    'arrosait',
    'arrosant',
    'arrosées',
    'arrosent',
    'arrosera',
    'arroseur',
    'arrosiez',
    'arrosoir',
    'arrosons',
    'arsenaux',
    'arsenics',
    'arsénite',
    'artefact',
    'artériel',
    'artérite',
    'artésien',
    'arthrite',
    'arthrose',
    'articles',
    'articula',
    'articule',
    'articulé',
    'artifice',
    'artimons',
    'artisans',
    'artistes',
    'aruspice',
    'aryennes',
    'arythmie',
    'asbestes',
    'ascaride',
    'ascidies',
    'asepsies',
    'aseptisa',
    'aseptise',
    'aseptisé',
    'asexuées',
    'asexuels',
    'asialies',
    'asilaire',
    'asiniens',
    'asociale',
    'asociaux',
    'aspectai',
    'aspectas',
    'aspectât',
    'aspectée',
    'aspecter',
    'aspectes',
    'aspectés',
    'aspectez',
    'aspergea',
    'aspergée',
    'asperger',
    'asperges',
    'aspergés',
    'aspergez',
    'aspérité',
    'aspermes',
    'asphalta',
    'asphalte',
    'asphalté',
    'asphyxia',
    'asphyxie',
    'asphyxié',
    'aspirais',
    'aspirait',
    'aspirant',
    'aspirées',
    'aspirent',
    'aspirera',
    'aspiriez',
    'aspirine',
    'aspirons',
    'assagies',
    'assagira',
    'assaille',
    'assailli',
    'assainie',
    'assainir',
    'assainis',
    'assainit',
    'assainît',
    'assassin',
    'asséchai',
    'asséchas',
    'asséchât',
    'asséchée',
    'assécher',
    'asséchés',
    'assèches',
    'asséchez',
    'assembla',
    'assemble',
    'assemblé',
    'assenais',
    'assénais',
    'assenait',
    'assénait',
    'assenant',
    'assénant',
    'assenées',
    'assénées',
    'assènent',
    'assénera',
    'assènera',
    'asseniez',
    'asséniez',
    'assenons',
    'assénons',
    'asservie',
    'asservir',
    'asservis',
    'asservit',
    'asservît',
    'asseyais',
    'asseyait',
    'asseyant',
    'asseyent',
    'asseyiez',
    'asseyons',
    'assibila',
    'assibile',
    'assibilé',
    'assidues',
    'assiégea',
    'assiégée',
    'assiéger',
    'assiégés',
    'assièges',
    'assiégez',
    'assiérai',
    'assiéras',
    'assiérez',
    'assiette',
    'assignai',
    'assignas',
    'assignat',
    'assignât',
    'assignée',
    'assigner',
    'assignes',
    'assignés',
    'assignez',
    'assimila',
    'assimile',
    'assimilé',
    'assirent',
    'assisses',
    'assistai',
    'assistas',
    'assistât',
    'assistée',
    'assister',
    'assistes',
    'assistés',
    'assistez',
    'associai',
    'associas',
    'associât',
    'associée',
    'associer',
    'associes',
    'associés',
    'associez',
    'assoient',
    'assoiffé',
    'assoirai',
    'assoiras',
    'assoirez',
    'assolais',
    'assolait',
    'assolant',
    'assolées',
    'assolent',
    'assolera',
    'assoliez',
    'assolons',
    'assombri',
    'assommai',
    'assommas',
    'assommât',
    'assommée',
    'assommer',
    'assommes',
    'assommés',
    'assommez',
    'assonais',
    'assonait',
    'assonant',
    'assonent',
    'assonera',
    'assoniez',
    'assonons',
    'assortie',
    'assortir',
    'assortis',
    'assortit',
    'assortît',
    'assoupie',
    'assoupir',
    'assoupis',
    'assoupit',
    'assoupît',
    'assoupli',
    'assourdi',
    'assouvie',
    'assouvir',
    'assouvis',
    'assouvit',
    'assouvît',
    'assoyais',
    'assoyait',
    'assoyant',
    'assoyiez',
    'assoyons',
    'assumais',
    'assumait',
    'assumant',
    'assumées',
    'assument',
    'assumera',
    'assumiez',
    'assumons',
    'assurais',
    'assurait',
    'assurant',
    'assurées',
    'assurent',
    'assurera',
    'assureur',
    'assuriez',
    'assurons',
    'assyrien',
    'astasies',
    'astéries',
    'asthénie',
    'asticota',
    'asticote',
    'asticoté',
    'asticots',
    'astiquai',
    'astiquas',
    'astiquât',
    'astiquée',
    'astiquer',
    'astiques',
    'astiqués',
    'astiquez',
    'astrakan',
    'astrales',
    'astreins',
    'astreint',
    'astronef',
    'asyndète',
    'ataraxie',
    'atavique',
    'atavisme',
    'ataxique',
    'ateliers',
    'a-t-elle',
    'atermoie',
    'atermoya',
    'atermoyé',
    'athanors',
    'athéisme',
    'athénées',
    'athérome',
    'athétose',
    'athlètes',
    'athymies',
    'atlantes',
    'atomique',
    'atomisai',
    'atomisas',
    'atomisât',
    'atomisée',
    'atomiser',
    'atomises',
    'atomisés',
    'atomisez',
    'atomisme',
    'atomiste',
    'atonales',
    'atonique',
    'atoxique',
    'atrabile',
    'atrocité',
    'atrophia',
    'atrophie',
    'atrophié',
    'atropine',
    'attablai',
    'attablas',
    'attablât',
    'attablée',
    'attabler',
    'attables',
    'attablés',
    'attablez',
    'attachai',
    'attachas',
    'attachât',
    'attachée',
    'attacher',
    'attaches',
    'attachés',
    'attachez',
    'attagène',
    'attaquai',
    'attaquas',
    'attaquât',
    'attaquée',
    'attaquer',
    'attaques',
    'attaqués',
    'attaquez',
    'attardai',
    'attardas',
    'attardât',
    'attardée',
    'attarder',
    'attardes',
    'attardés',
    'attardez',
    'atteigne',
    'atteinte',
    'atteints',
    'attelage',
    'attelais',
    'attelait',
    'attelant',
    'attelées',
    'atteliez',
    'attelles',
    'attelons',
    'attenant',
    'attendes',
    'attendez',
    'attendis',
    'attendit',
    'attendît',
    'attendra',
    'attendre',
    'attendri',
    'attendue',
    'attendus',
    'attentai',
    'attentas',
    'attentat',
    'attentât',
    'attenter',
    'attentes',
    'attentez',
    'attentif',
    'atténuai',
    'atténuas',
    'atténuât',
    'atténuée',
    'atténuer',
    'atténues',
    'atténués',
    'atténuez',
    'atterrai',
    'atterras',
    'atterrât',
    'atterrée',
    'atterrer',
    'atterres',
    'atterrés',
    'atterrez',
    'atterrir',
    'atterris',
    'atterrit',
    'atterrît',
    'attestai',
    'attestas',
    'attestât',
    'attestée',
    'attester',
    'attestes',
    'attestés',
    'attestez',
    'attiédie',
    'attiédir',
    'attiédis',
    'attiédit',
    'attiédît',
    'attifais',
    'attifait',
    'attifant',
    'attifées',
    'attifent',
    'attifera',
    'attifiez',
    'attifons',
    'attigeai',
    'attigeas',
    'attigeât',
    'attigent',
    'attigera',
    'attigiez',
    'attiques',
    'attirail',
    'attirais',
    'attirait',
    'attirant',
    'attirées',
    'attirent',
    'attirera',
    'attiriez',
    'attirons',
    'attisais',
    'attisait',
    'attisant',
    'attisées',
    'attisent',
    'attisera',
    'attisiez',
    'attisons',
    'attitrai',
    'attitras',
    'attitrât',
    'attitrée',
    'attitrer',
    'attitres',
    'attitrés',
    'attitrez',
    'attitude',
    'attorney',
    'attraits',
    'attrapai',
    'attrapas',
    'attrapât',
    'attrapée',
    'attraper',
    'attrapes',
    'attrapés',
    'attrapez',
    'attribua',
    'attribue',
    'attribué',
    'attribut',
    'attriqua',
    'attrique',
    'attriqué',
    'attrista',
    'attriste',
    'attristé',
    'attroupa',
    'attroupe',
    'attroupé',
    'atypique',
    'aubaines',
    'aubépine',
    'auberges',
    'aubettes',
    'audibles',
    'audience',
    'auditeur',
    'auditifs',
    'audition',
    'auditive',
    'augmenta',
    'augmente',
    'augmenté',
    'augments',
    'augurais',
    'augurait',
    'augurale',
    'augurant',
    'auguraux',
    'augurées',
    'augurent',
    'augurera',
    'auguriez',
    'augurons',
    'augustes',
    'aumônier',
    'auraient',
    'aurai-je',
    'auras-tu',
    'auréolai',
    'auréolas',
    'auréolât',
    'auréolée',
    'auréoler',
    'auréoles',
    'auréolés',
    'auréolez',
    'auricule',
    'aurifère',
    'aurifiai',
    'aurifias',
    'aurifiât',
    'aurifiée',
    'aurifier',
    'aurifies',
    'aurifiés',
    'aurifiez',
    'ausculta',
    'ausculte',
    'ausculté',
    'auspices',
    'aussitôt',
    'austères',
    'australe',
    'austraux',
    'autarcie',
    'autismes',
    'autistes',
    'autocars',
    'autodafé',
    'autogame',
    'autogène',
    'autogéré',
    'autogire',
    'automate',
    'automnal',
    'automnes',
    'autonome',
    'autonyme',
    'autopsia',
    'autopsie',
    'autopsié',
    'autorail',
    'autorisa',
    'autorise',
    'autorisé',
    'autorité',
    'autruche',
    'auxquels',
    'avachies',
    'avachira',
    'avais-je',
    'avais-tu',
    'avait-il',
    'avait-on',
    'avalâmes',
    'avalasse',
    'avalâtes',
    'avalerai',
    'avaleras',
    'avalerez',
    'avaleurs',
    'avalions',
    'avalisai',
    'avalisas',
    'avalisât',
    'avalisée',
    'avaliser',
    'avalises',
    'avalisés',
    'avalisez',
    'avaliste',
    'à-valoir',
    'avançais',
    'avançait',
    'avançant',
    'avancées',
    'avancent',
    'avancera',
    'avanciez',
    'avançons',
    'avantage',
    'avantagé',
    'avariais',
    'avariait',
    'avariant',
    'avarices',
    'avariées',
    'avarient',
    'avariera',
    'avariiez',
    'avarions',
    'avelines',
    'avenante',
    'avenants',
    'aventura',
    'aventure',
    'aventuré',
    'avérâmes',
    'avérasse',
    'avérâtes',
    'avérerai',
    'avéreras',
    'avérerez',
    'avérions',
    'aversion',
    'averties',
    'avertira',
    'aveuglai',
    'aveuglas',
    'aveuglât',
    'aveuglée',
    'aveugler',
    'aveugles',
    'aveuglés',
    'aveuglez',
    'aveulies',
    'aveulira',
    'aviaires',
    'aviateur',
    'aviation',
    'avicoles',
    'avidités',
    'avilîmes',
    'avilirai',
    'aviliras',
    'avilirez',
    'avilisse',
    'avilîtes',
    'avinâmes',
    'avinasse',
    'avinâtes',
    'avinerai',
    'avineras',
    'avinerez',
    'avinions',
    'avisâmes',
    'avisasse',
    'avisâtes',
    'aviserai',
    'aviseras',
    'aviserez',
    'avisions',
    'avivages',
    'avivâmes',
    'avivasse',
    'avivâtes',
    'aviverai',
    'aviveras',
    'aviverez',
    'avivions',
    'avocates',
    'avocette',
    'avoisina',
    'avoisine',
    'avoisiné',
    'avortais',
    'avortait',
    'avortant',
    'avortées',
    'avortent',
    'avortera',
    'avorteur',
    'avortiez',
    'avortons',
    'avouable',
    'avouâmes',
    'avouasse',
    'avouâtes',
    'avouerai',
    'avoueras',
    'avouerez',
    'avouions',
    'avulsion',
    'axassent',
    'axassiez',
    'axerions',
    'axolotls',
    'azeroles',
    'azimutal',
    'azimutée',
    'azimutés',
    'azoïques',
    'azotates',
    'azotémie',
    'azotique',
    'azotites',
    'azoturie',
    'aztèques',
    'azulejos',
    'azurages',
    'azurâmes',
    'azurants',
    'azurasse',
    'azurâtes',
    'azurerai',
    'azureras',
    'azurerez',
    'azurions',
    'azurites',
    'babeurre',
    'babillai',
    'babillas',
    'babillât',
    'babiller',
    'babilles',
    'babillez',
    'babioles',
    'babouche',
    'babouins',
    'baccarat',
    'bâchages',
    'bâchâmes',
    'bâchasse',
    'bâchâtes',
    'bâcherai',
    'bâcheras',
    'bâcherez',
    'bâchions',
    'bachique',
    'bachotai',
    'bachotas',
    'bachotât',
    'bachotée',
    'bachoter',
    'bachotes',
    'bachotés',
    'bachotez',
    'bacilles',
    'bâclages',
    'bâclâmes',
    'bâclasse',
    'bâclâtes',
    'bâclerai',
    'bâcleras',
    'bâclerez',
    'bâclions',
    'bactérie',
    'badaudes',
    'badernes',
    'badianes',
    'badigeon',
    'badinage',
    'badinais',
    'badinait',
    'badinant',
    'badinent',
    'badinera',
    'badiniez',
    'badinons',
    'baffâmes',
    'baffasse',
    'baffâtes',
    'bafferai',
    'bafferas',
    'bafferez',
    'baffions',
    'bafouais',
    'bafouait',
    'bafouant',
    'bafouées',
    'bafouent',
    'bafouera',
    'bafouiez',
    'bafouons',
    'bâfrâmes',
    'bâfrasse',
    'bâfrâtes',
    'bâfrerai',
    'bâfreras',
    'bâfrerez',
    'bâfreurs',
    'bâfreuse',
    'bâfrions',
    'bagarrai',
    'bagarras',
    'bagarrât',
    'bagarrée',
    'bagarrer',
    'bagarres',
    'bagarrés',
    'bagarrez',
    'bagasses',
    'bagnards',
    'bagnoles',
    'baguages',
    'baguâmes',
    'baguasse',
    'baguâtes',
    'baguerai',
    'bagueras',
    'baguerez',
    'baguette',
    'baguiers',
    'baguions',
    'baierais',
    'baierait',
    'baieriez',
    'baierons',
    'baieront',
    'baignade',
    'baignais',
    'baignait',
    'baignant',
    'baignées',
    'baignent',
    'baignera',
    'baigneur',
    'baigniez',
    'baignons',
    'baillais',
    'bâillais',
    'baillait',
    'bâillait',
    'baillant',
    'bâillant',
    'baillées',
    'baillent',
    'bâillent',
    'baillera',
    'bâillera',
    'bailleur',
    'bailliez',
    'bâilliez',
    'baillons',
    'bâillons',
    'baisâmes',
    'baisasse',
    'baisâtes',
    'baiserai',
    'baiseras',
    'baiserez',
    'baiseurs',
    'baiseuse',
    'baisions',
    'baisotai',
    'baisotas',
    'baisotât',
    'baisotée',
    'baisoter',
    'baisotes',
    'baisotés',
    'baisotez',
    'baissais',
    'baissait',
    'baissant',
    'baissées',
    'baissent',
    'baissera',
    'baissier',
    'baissiez',
    'baissons',
    'bajoyers',
    'bakchich',
    'bakélite',
    'baklavas',
    'baladais',
    'baladait',
    'baladant',
    'baladées',
    'baladent',
    'baladera',
    'baladeur',
    'baladiez',
    'baladins',
    'baladons',
    'balafons',
    'balafrai',
    'balafras',
    'balafrât',
    'balafrée',
    'balafrer',
    'balafres',
    'balafrés',
    'balafrez',
    'balaient',
    'balaiera',
    'balançai',
    'balanças',
    'balançât',
    'balancée',
    'balancer',
    'balances',
    'balancés',
    'balancez',
    'balanite',
    'balayage',
    'balayais',
    'balayait',
    'balayant',
    'balayées',
    'balayent',
    'balayera',
    'balayeur',
    'balayiez',
    'balayons',
    'balbutia',
    'balbutie',
    'balbutié',
    'baleinai',
    'baleinas',
    'baleinât',
    'baleinée',
    'baleiner',
    'baleines',
    'baleinés',
    'baleinez',
    'balèvres',
    'balisage',
    'balisais',
    'balisait',
    'balisant',
    'balisées',
    'balisent',
    'balisera',
    'baliseur',
    'balisier',
    'balisiez',
    'balisons',
    'balistes',
    'balivage',
    'baliveau',
    'ballades',
    'ballâmes',
    'ballante',
    'ballants',
    'ballasse',
    'ballasta',
    'ballaste',
    'ballasté',
    'ballasts',
    'ballâtes',
    'ballerai',
    'balleras',
    'ballerez',
    'ballions',
    'ballonna',
    'ballonne',
    'ballonné',
    'ballotes',
    'ballotta',
    'ballotte',
    'ballotté',
    'balourde',
    'balourds',
    'baluchon',
    'balustre',
    'balzanes',
    'bambocha',
    'bamboche',
    'bamboché',
    'bamboula',
    'banalisa',
    'banalise',
    'banalisé',
    'banalité',
    'bananier',
    'bancable',
    'bancaire',
    'bancales',
    'bandages',
    'bandâmes',
    'bandasse',
    'bandâtes',
    'bandeaux',
    'banderai',
    'banderas',
    'banderez',
    'bandions',
    'banlieue',
    'bannâmes',
    'bannasse',
    'bannâtes',
    'bannerai',
    'banneras',
    'banneret',
    'bannerez',
    'banneton',
    'bannette',
    'bannière',
    'bannîmes',
    'bannions',
    'bannirai',
    'banniras',
    'bannirez',
    'bannisse',
    'bannîtes',
    'banquais',
    'banquait',
    'banquant',
    'banquées',
    'banquent',
    'banquera',
    'banqueta',
    'banqueté',
    'banquets',
    'banquier',
    'banquiez',
    'banquise',
    'banquons',
    'bantoues',
    'baptêmes',
    'baptisai',
    'baptisas',
    'baptisât',
    'baptisée',
    'baptiser',
    'baptises',
    'baptisés',
    'baptisez',
    'baptisme',
    'baptiste',
    'baquetai',
    'baquetas',
    'baquetât',
    'baquetée',
    'baqueter',
    'baquetés',
    'baquetez',
    'baquette',
    'baraquai',
    'baraquas',
    'baraquât',
    'baraquer',
    'baraques',
    'baraquez',
    'baratina',
    'baratine',
    'baratiné',
    'barattai',
    'barattas',
    'barattât',
    'barattée',
    'baratter',
    'barattes',
    'barattés',
    'barattez',
    'barbâmes',
    'barbante',
    'barbants',
    'barbaque',
    'barbares',
    'barbarie',
    'barbasse',
    'barbâtes',
    'barbeaux',
    'barbecue',
    'barbelée',
    'barbelés',
    'barberai',
    'barberas',
    'barberez',
    'barbette',
    'barbiche',
    'barbiers',
    'barbifia',
    'barbifie',
    'barbifié',
    'barbille',
    'barbions',
    'barbital',
    'barbotai',
    'barbotas',
    'barbotât',
    'barbotée',
    'barboter',
    'barbotes',
    'barbotés',
    'barbotez',
    'barbotin',
    'barbouze',
    'barbules',
    'barcasse',
    'bardages',
    'bardâmes',
    'bardanes',
    'bardasse',
    'bardâtes',
    'bardeaux',
    'barderai',
    'barderas',
    'barderez',
    'bardions',
    'barétais',
    'barétait',
    'barétant',
    'barètent',
    'barètera',
    'barétiez',
    'barétons',
    'barillet',
    'bariolai',
    'bariolas',
    'bariolât',
    'bariolée',
    'barioler',
    'barioles',
    'bariolés',
    'bariolez',
    'barlongs',
    'barmaids',
    'baronnai',
    'baronnas',
    'baronnât',
    'baronnée',
    'baronner',
    'baronnes',
    'baronnés',
    'baronnet',
    'baronnez',
    'baronnie',
    'baroques',
    'baroudai',
    'baroudas',
    'baroudât',
    'barouder',
    'baroudes',
    'baroudez',
    'baroufle',
    'barrages',
    'barrâmes',
    'barrasse',
    'barrâtes',
    'barreaux',
    'barrerai',
    'barreras',
    'barrerez',
    'barrette',
    'barreurs',
    'barreuse',
    'barrière',
    'barrîmes',
    'barrions',
    'barrique',
    'barrirai',
    'barriras',
    'barrirez',
    'barrisse',
    'barrîtes',
    'barytine',
    'barytons',
    'basaient',
    'basaltes',
    'basanais',
    'basanait',
    'basanant',
    'basanées',
    'basanent',
    'basanera',
    'basaniez',
    'basanons',
    'basasses',
    'bas-bleu',
    'bas-côté',
    'basculai',
    'basculas',
    'basculât',
    'basculée',
    'basculer',
    'bascules',
    'basculés',
    'basculez',
    'baselles',
    'baserais',
    'baserait',
    'basèrent',
    'baseriez',
    'baserons',
    'baseront',
    'bas-fond',
    'basicité',
    'basiques',
    'bas-mâts',
    'basoches',
    'basquine',
    'bassesse',
    'bassinai',
    'bassinas',
    'bassinât',
    'bassinée',
    'bassiner',
    'bassines',
    'bassinés',
    'bassinet',
    'bassinez',
    'bassiste',
    'basterne',
    'bastides',
    'bastidon',
    'bastille',
    'bastillé',
    'bastings',
    'bastions',
    'bataclan',
    'bâtaient',
    'batailla',
    'bataille',
    'bataillé',
    'bâtardes',
    'bâtasses',
    'batavias',
    'batayole',
    'batelage',
    'batelais',
    'batelait',
    'batelant',
    'batelées',
    'batelets',
    'bateleur',
    'batelier',
    'bateliez',
    'batelles',
    'batelons',
    'bâterais',
    'bâterait',
    'bâtèrent',
    'bâteriez',
    'bâterons',
    'bâteront',
    'bathyale',
    'bathyaux',
    'batifola',
    'batifole',
    'batifolé',
    'bâtiment',
    'bâtirais',
    'bâtirait',
    'bâtirent',
    'bâtiriez',
    'bâtirons',
    'bâtiront',
    'bâtisses',
    'bâtissez',
    'batistes',
    'bâtonnai',
    'bâtonnas',
    'bâtonnat',
    'bâtonnât',
    'bâtonnée',
    'bâtonner',
    'bâtonnes',
    'bâtonnés',
    'bâtonnet',
    'bâtonnez',
    'batoudes',
    'battages',
    'battante',
    'battants',
    'batterie',
    'batteurs',
    'batteuse',
    'battîmes',
    'battions',
    'battisse',
    'battîtes',
    'battoirs',
    'battrais',
    'battrait',
    'battriez',
    'battrons',
    'battront',
    'battures',
    'baudrier',
    'baudroie',
    'baugeais',
    'baugeait',
    'baugeant',
    'baugeons',
    'baugerai',
    'baugeras',
    'baugerez',
    'baugions',
    'baumiers',
    'bavaient',
    'bavardai',
    'bavardas',
    'bavardât',
    'bavarder',
    'bavardes',
    'bavardez',
    'bavarois',
    'bavassai',
    'bavassas',
    'bavassât',
    'bavasser',
    'bavasses',
    'bavassez',
    'baverais',
    'baverait',
    'bavèrent',
    'baveriez',
    'baverons',
    'baveront',
    'bavettes',
    'baveuses',
    'bavochai',
    'bavochas',
    'bavochât',
    'bavocher',
    'bavoches',
    'bavochez',
    'bavolets',
    'bayadère',
    'bayaient',
    'bayasses',
    'bayerais',
    'bayerait',
    'bayèrent',
    'bayeriez',
    'bayerons',
    'bayeront',
    'bazardai',
    'bazardas',
    'bazardât',
    'bazardée',
    'bazarder',
    'bazardes',
    'bazardés',
    'bazardez',
    'bazookas',
    'béarnais',
    'béassent',
    'béassiez',
    'béatifia',
    'béatifie',
    'béatifié',
    'beatniks',
    'beaucoup',
    'beauprés',
    'bécarres',
    'bécasses',
    'becfigue',
    'bêchages',
    'béchamel',
    'bêchâmes',
    'bêchasse',
    'bêchâtes',
    'bêcherai',
    'bêcheras',
    'bêcherez',
    'bêcheurs',
    'bêcheuse',
    'bêchions',
    'béchique',
    'bécotais',
    'bécotait',
    'bécotant',
    'bécotées',
    'bécotent',
    'bécotera',
    'bécotiez',
    'bécotons',
    'becquais',
    'becquait',
    'becquant',
    'becquées',
    'becquent',
    'becquera',
    'becqueta',
    'becqueté',
    'becquiez',
    'becquons',
    'bectâmes',
    'bectasse',
    'bectâtes',
    'becterai',
    'becteras',
    'becterez',
    'bections',
    'bedaines',
    'bédégars',
    'bedonnai',
    'bedonnas',
    'bedonnât',
    'bedonner',
    'bedonnes',
    'bedonnez',
    'bédouine',
    'bédouins',
    'béerions',
    'beffrois',
    'bégaient',
    'bégaiera',
    'bégayais',
    'bégayait',
    'bégayant',
    'bégayées',
    'bégayent',
    'bégayera',
    'bégayeur',
    'bégayiez',
    'bégayons',
    'bégonias',
    'béguetai',
    'béguetas',
    'béguetât',
    'bégueter',
    'béguètes',
    'béguetez',
    'bégueule',
    'béguines',
    'beignets',
    'béjaunes',
    'bêlaient',
    'bélandre',
    'bêlantes',
    'bêlasses',
    'bêlement',
    'bêlerais',
    'bêlerait',
    'bêlèrent',
    'bêleriez',
    'bêlerons',
    'bêleront',
    'belettes',
    'bélières',
    'bélîtres',
    'bellâtre',
    'belougas',
    'bélougas',
    'bémolisa',
    'bémolise',
    'bémolisé',
    'bénardes',
    'bénéfice',
    'bénévole',
    'bengalis',
    'bénignes',
    'bénirais',
    'bénirait',
    'bénirent',
    'béniriez',
    'bénirons',
    'béniront',
    'bénisses',
    'bénissez',
    'bénitier',
    'benjamin',
    'benoîtes',
    'benzines',
    'benzoate',
    'béotiens',
    'béotisme',
    'béquâmes',
    'béquasse',
    'béquâtes',
    'béquerai',
    'béqueras',
    'béquerez',
    'béquetai',
    'béquetas',
    'béquetât',
    'béquetée',
    'béqueter',
    'béquetés',
    'béquetez',
    'béquette',
    'béquilla',
    'béquille',
    'béquillé',
    'béquions',
    'berbères',
    'berçâmes',
    'berçante',
    'berçasse',
    'berçâtes',
    'berceaux',
    'bercerai',
    'berceras',
    'bercerez',
    'berceurs',
    'berceuse',
    'bercions',
    'bergères',
    'bergerie',
    'béribéri',
    'berlines',
    'bermudas',
    'bernache',
    'bernacle',
    'bernâmes',
    'bernasse',
    'bernâtes',
    'bernerai',
    'berneras',
    'bernerez',
    'bernicle',
    'bernions',
    'bernique',
    'berthons',
    'besaiguë',
    'bésicles',
    'bésigues',
    'besognai',
    'besognas',
    'besognât',
    'besogner',
    'besognes',
    'besognez',
    'bessemer',
    'bestiale',
    'bestiaux',
    'bestiole',
    'bêtasses',
    'bêtatron',
    'bêtement',
    'bêtifiai',
    'bêtifias',
    'bêtifiât',
    'bêtifier',
    'bêtifies',
    'bêtifiez',
    'bêtisais',
    'bêtisait',
    'bêtisant',
    'bêtisent',
    'bêtisera',
    'bêtisier',
    'bêtisiez',
    'bêtisons',
    'bétoines',
    'bétoires',
    'bétonnai',
    'bétonnas',
    'bétonnât',
    'bétonnée',
    'bétonner',
    'bétonnes',
    'bétonnés',
    'bétonnez',
    'beuglais',
    'beuglait',
    'beuglant',
    'beuglées',
    'beuglent',
    'beuglera',
    'beugliez',
    'beuglons',
    'beurrais',
    'beurrait',
    'beurrant',
    'beurrées',
    'beurrent',
    'beurrera',
    'beurrier',
    'beurriez',
    'beurrons',
    'beuverie',
    'bévatron',
    'beylical',
    'beylicat',
    'beylisme',
    'bézoards',
    'biacides',
    'biaisais',
    'biaisait',
    'biaisant',
    'biaisées',
    'biaisent',
    'biaisera',
    'biaisiez',
    'biaisons',
    'bibelota',
    'bibelote',
    'bibeloté',
    'bibelots',
    'biberons',
    'biblique',
    'bicarrée',
    'bicarrés',
    'bichâmes',
    'bichasse',
    'bichâtes',
    'bicherai',
    'bicheras',
    'bicherez',
    'bichette',
    'bichions',
    'bichonna',
    'bichonne',
    'bichonné',
    'bichotai',
    'bichotas',
    'bichotât',
    'bichoter',
    'bichotes',
    'bichotez',
    'bickford',
    'bicolore',
    'bicoques',
    'bicornes',
    'bicycles',
    'bidasses',
    'bidoches',
    'bidonnai',
    'bidonnas',
    'bidonnât',
    'bidonnée',
    'bidonner',
    'bidonnes',
    'bidonnés',
    'bidonnez',
    'bienfait',
    'biennale',
    'biennaux',
    'bienvenu',
    'biffages',
    'biffâmes',
    'biffasse',
    'biffâtes',
    'bifferai',
    'bifferas',
    'bifferez',
    'biffions',
    'biffures',
    'bifocale',
    'bifocaux',
    'biftecks',
    'bifurqua',
    'bifurque',
    'bifurqué',
    'bigarade',
    'bigarrai',
    'bigarras',
    'bigarrât',
    'bigarrée',
    'bigarrer',
    'bigarres',
    'bigarrés',
    'bigarrez',
    'biglâmes',
    'biglasse',
    'biglâtes',
    'biglerai',
    'bigleras',
    'biglerez',
    'bigleuse',
    'biglions',
    'bignonia',
    'bigornai',
    'bigornas',
    'bigornât',
    'bigornée',
    'bigorner',
    'bigornes',
    'bigornés',
    'bigornez',
    'bigouden',
    'bigoudis',
    'biguines',
    'bihoreau',
    'bijectif',
    'bilabiée',
    'bilabiés',
    'bilaient',
    'bilasses',
    'bilerais',
    'bilerait',
    'bilèrent',
    'bileriez',
    'bilerons',
    'bileront',
    'bileuses',
    'biliaire',
    'bilieuse',
    'bilingue',
    'billards',
    'billetée',
    'billetés',
    'billette',
    'billions',
    'billonna',
    'billonne',
    'billonné',
    'bilobées',
    'biloquai',
    'biloquas',
    'biloquât',
    'biloquée',
    'biloquer',
    'biloques',
    'biloqués',
    'biloquez',
    'bimoteur',
    'binaient',
    'binaires',
    'binasses',
    'binerais',
    'binerait',
    'binèrent',
    'bineriez',
    'binerons',
    'bineront',
    'binettes',
    'bineuses',
    'binocles',
    'biocides',
    'biologie',
    'biomasse',
    'bionique',
    'biopsies',
    'biotique',
    'biotites',
    'biotopes',
    'biotypes',
    'bioxydes',
    'bipartie',
    'bipartis',
    'bipennée',
    'bipennés',
    'biphasée',
    'biphasés',
    'biplaces',
    'bipoutre',
    'biquette',
    'biroutes',
    'bisaient',
    'bisaïeul',
    'bisasses',
    'bisbille',
    'biscaïen',
    'biscômes',
    'biscornu',
    'biscotte',
    'biscuita',
    'biscuite',
    'biscuité',
    'biscuits',
    'biseauta',
    'biseaute',
    'biseauté',
    'biserais',
    'biserait',
    'bisèrent',
    'biseriez',
    'biserons',
    'biseront',
    'bisexuée',
    'bisexuel',
    'bisexués',
    'bismuths',
    'bisquais',
    'bisquait',
    'bisquant',
    'bisquées',
    'bisquent',
    'bisquera',
    'bisquiez',
    'bisquons',
    'bissâmes',
    'bissasse',
    'bissâtes',
    'bisserai',
    'bisseras',
    'bisserez',
    'bissexte',
    'bissexué',
    'bissions',
    'bistorte',
    'bistouri',
    'bistrais',
    'bistrait',
    'bistrant',
    'bistrées',
    'bistrent',
    'bistrera',
    'bistriez',
    'bistrons',
    'bistrots',
    'bitaient',
    'bitasses',
    'biterais',
    'biterait',
    'bitèrent',
    'biteriez',
    'biterons',
    'biteront',
    'bitonale',
    'bitonaux',
    'bittâmes',
    'bittasse',
    'bittâtes',
    'bitterai',
    'bitteras',
    'bitterez',
    'bittions',
    'bitturai',
    'bitturas',
    'bitturât',
    'bitturée',
    'bitturer',
    'bittures',
    'bitturés',
    'bitturez',
    'bitumage',
    'bitumais',
    'bitumait',
    'bitumant',
    'bitumées',
    'bitument',
    'bitumera',
    'bitumeux',
    'bitumiez',
    'bitumina',
    'bitumine',
    'bituminé',
    'bitumons',
    'biturais',
    'biturait',
    'biturant',
    'biturées',
    'biturent',
    'biturera',
    'bituriez',
    'biturons',
    'bivalent',
    'bivalves',
    'bivouacs',
    'bizarres',
    'bizutage',
    'bizutais',
    'bizutait',
    'bizutant',
    'bizutées',
    'bizutent',
    'bizutera',
    'bizutiez',
    'bizutons',
    'blablata',
    'blablate',
    'blablaté',
    'blafarde',
    'blafards',
    'blaguais',
    'blaguait',
    'blaguant',
    'blaguées',
    'blaguent',
    'blaguera',
    'blagueur',
    'blaguiez',
    'blaguons',
    'blairais',
    'blairait',
    'blairant',
    'blaireau',
    'blairées',
    'blairent',
    'blairera',
    'blairiez',
    'blairons',
    'blâmable',
    'blâmâmes',
    'blâmasse',
    'blâmâtes',
    'blâmerai',
    'blâmeras',
    'blâmerez',
    'blâmions',
    'blanches',
    'blanchet',
    'blanchie',
    'blanchir',
    'blanchis',
    'blanchit',
    'blanchît',
    'blandice',
    'blasâmes',
    'blasasse',
    'blasâtes',
    'blaserai',
    'blaseras',
    'blaserez',
    'blasions',
    'blasonna',
    'blasonne',
    'blasonné',
    'blastula',
    'blatérai',
    'blatéras',
    'blatérât',
    'blatérée',
    'blatérer',
    'blatérés',
    'blatères',
    'blatérez',
    'blêmerai',
    'blêmeras',
    'blêmerez',
    'blêmîmes',
    'blêmions',
    'blêmisse',
    'blêmîtes',
    'blennies',
    'blésâmes',
    'blésasse',
    'blésâtes',
    'bléserai',
    'bléseras',
    'bléserez',
    'blésions',
    'blésités',
    'blessais',
    'blessait',
    'blessant',
    'blessées',
    'blessent',
    'blessera',
    'blessiez',
    'blessons',
    'blessure',
    'blettira',
    'bleuâtre',
    'bleuîmes',
    'bleuirai',
    'bleuiras',
    'bleuirez',
    'bleuisse',
    'bleuîtes',
    'bleutais',
    'bleutait',
    'bleutant',
    'bleutées',
    'bleutent',
    'bleutera',
    'bleutiez',
    'bleutons',
    'blindage',
    'blindais',
    'blindait',
    'blindant',
    'blindées',
    'blindent',
    'blindera',
    'blindiez',
    'blindons',
    'blisters',
    'blizzard',
    'blocages',
    'blondels',
    'blondeur',
    'blondies',
    'blondine',
    'blondins',
    'blondoie',
    'blondoya',
    'blondoyé',
    'bloomers',
    'bloquais',
    'bloquait',
    'bloquant',
    'bloquées',
    'bloquent',
    'bloquera',
    'bloquiez',
    'bloquons',
    'blotties',
    'blottira',
    'blousais',
    'blousait',
    'blousant',
    'blousées',
    'blousent',
    'blousera',
    'blousiez',
    'blousons',
    'bluettes',
    'bluffais',
    'bluffait',
    'bluffant',
    'bluffées',
    'bluffent',
    'bluffera',
    'bluffeur',
    'bluffiez',
    'bluffons',
    'blutages',
    'blutâmes',
    'blutasse',
    'blutâtes',
    'bluterai',
    'bluteras',
    'bluterez',
    'blutions',
    'blutoirs',
    'bobèches',
    'bobinage',
    'bobinais',
    'bobinait',
    'bobinant',
    'bobinées',
    'bobinent',
    'bobinera',
    'bobineur',
    'bobinier',
    'bobiniez',
    'bobinoir',
    'bobinons',
    'bobonnes',
    'bocagère',
    'bocagers',
    'bocardai',
    'bocardas',
    'bocardât',
    'bocardée',
    'bocarder',
    'bocardes',
    'bocardés',
    'bocardez',
    'boettais',
    'boettait',
    'boettant',
    'boettées',
    'boettent',
    'boettera',
    'boettiez',
    'boettons',
    'bohémien',
    'boirions',
    'boisages',
    'boisâmes',
    'boisasse',
    'boisâtes',
    'boiserai',
    'boiseras',
    'boiserez',
    'boiserie',
    'boiseurs',
    'boisions',
    'boisseau',
    'boissons',
    'boitâmes',
    'boitasse',
    'boitâtes',
    'boiterai',
    'boiteras',
    'boiterez',
    'boiterie',
    'boiteuse',
    'boîtiers',
    'boitilla',
    'boitille',
    'boitillé',
    'boitions',
    'bolivars',
    'bollards',
    'bombages',
    'bombâmes',
    'bombance',
    'bombante',
    'bombants',
    'bombarda',
    'bombarde',
    'bombardé',
    'bombasse',
    'bombâtes',
    'bomberai',
    'bomberas',
    'bomberez',
    'bombions',
    'bonasses',
    'bonbonne',
    'bondâmes',
    'bondasse',
    'bondâtes',
    'bondelle',
    'bonderai',
    'bonderas',
    'bonderez',
    'bondîmes',
    'bondions',
    'bondirai',
    'bondiras',
    'bondirez',
    'bondisse',
    'bondîtes',
    'bondonna',
    'bondonne',
    'bondonné',
    'bondrées',
    'bonheurs',
    'bonhomie',
    'bonhomme',
    'boniches',
    'bonichon',
    'bonifiai',
    'bonifias',
    'bonifiât',
    'bonifiée',
    'bonifier',
    'bonifies',
    'bonifiés',
    'bonifiez',
    'boniment',
    'bonjours',
    'bonnette',
    'bon-papa',
    'bonsoirs',
    'bonzerie',
    'booléens',
    'boosters',
    'bordages',
    'bordâmes',
    'bordasse',
    'bordâtes',
    'bordeaux',
    'borderai',
    'borderas',
    'borderez',
    'borderie',
    'bordière',
    'bordiers',
    'bordigue',
    'bordions',
    'bordures',
    'boréales',
    'boriqués',
    'bornages',
    'bornâmes',
    'bornasse',
    'bornâtes',
    'bornerai',
    'borneras',
    'bornerez',
    'bornions',
    'bornoies',
    'bornoyai',
    'bornoyas',
    'bornoyât',
    'bornoyée',
    'bornoyer',
    'bornoyés',
    'bornoyez',
    'bortschs',
    'boscotte',
    'bosquets',
    'bossages',
    'bossâmes',
    'bossasse',
    'bossâtes',
    'bosselai',
    'bosselas',
    'bosselât',
    'bosselée',
    'bosseler',
    'bosselés',
    'bosselez',
    'bosselle',
    'bosserai',
    'bosseras',
    'bosserez',
    'bossette',
    'bosseurs',
    'bosseuse',
    'bossions',
    'bossoirs',
    'bossuais',
    'bossuait',
    'bossuant',
    'bossuées',
    'bossuent',
    'bossuera',
    'bossuiez',
    'bossuons',
    'botanisa',
    'botanise',
    'botanisé',
    'bottâmes',
    'bottasse',
    'bottâtes',
    'bottelai',
    'bottelas',
    'bottelât',
    'bottelée',
    'botteler',
    'bottelés',
    'bottelez',
    'bottelle',
    'botterai',
    'botteras',
    'botterez',
    'bottiers',
    'bottines',
    'bottions',
    'boucanai',
    'boucanas',
    'boucanât',
    'boucanée',
    'boucaner',
    'boucanes',
    'boucanés',
    'boucanez',
    'bouchage',
    'bouchais',
    'bouchait',
    'bouchant',
    'bouchées',
    'bouchent',
    'bouchera',
    'bouchers',
    'bouchiez',
    'bouchons',
    'bouchots',
    'bouclage',
    'bouclais',
    'bouclait',
    'bouclant',
    'bouclées',
    'bouclent',
    'bouclera',
    'bouclier',
    'boucliez',
    'bouclons',
    'boudâmes',
    'boudasse',
    'boudâtes',
    'bouddhas',
    'bouderai',
    'bouderas',
    'bouderez',
    'bouderie',
    'boudeurs',
    'boudeuse',
    'boudinai',
    'boudinas',
    'boudinât',
    'boudinée',
    'boudiner',
    'boudines',
    'boudinés',
    'boudinez',
    'boudions',
    'boudoirs',
    'boueuses',
    'bouffais',
    'bouffait',
    'bouffant',
    'bouffées',
    'bouffent',
    'bouffera',
    'bouffies',
    'bouffiez',
    'bouffira',
    'bouffons',
    'bougeais',
    'bougeait',
    'bougeant',
    'bougeoir',
    'bougeons',
    'bougerai',
    'bougeras',
    'bougerez',
    'bougions',
    'bougnats',
    'bougonna',
    'bougonne',
    'bougonné',
    'bougrans',
    'bouilles',
    'bouillez',
    'bouillie',
    'bouillir',
    'bouillis',
    'bouillit',
    'bouillît',
    'bouillon',
    'boulaies',
    'boulâmes',
    'boulange',
    'boulangé',
    'boulasse',
    'boulâtes',
    'boulbène',
    'bouleaux',
    'boulerai',
    'bouleras',
    'boulerez',
    'bouletée',
    'bouletés',
    'boulette',
    'bouliers',
    'boulimie',
    'boulines',
    'boulions',
    'bouliste',
    'bouloirs',
    'boulonna',
    'boulonne',
    'boulonné',
    'boulotta',
    'boulotte',
    'boulotté',
    'boumerai',
    'boumeras',
    'boumerez',
    'bouquets',
    'bouquina',
    'bouquine',
    'bouquiné',
    'bouquins',
    'bourbeux',
    'bourbier',
    'bourbons',
    'bourdais',
    'bourdait',
    'bourdant',
    'bourdent',
    'bourdera',
    'bourdiez',
    'bourdons',
    'bourgade',
    'bourgeon',
    'bourrade',
    'bourrage',
    'bourrais',
    'bourrait',
    'bourrant',
    'bourreau',
    'bourrées',
    'bourrela',
    'bourrelé',
    'bourrent',
    'bourrera',
    'bourriez',
    'bourrins',
    'bourroir',
    'bourrons',
    'bourrues',
    'boursier',
    'bouscula',
    'bouscule',
    'bousculé',
    'bousiers',
    'bousilla',
    'bousille',
    'bousillé',
    'boussole',
    'boutades',
    'boutâmes',
    'boutasse',
    'boutâtes',
    'boutefeu',
    'bouterai',
    'bouteras',
    'bouterez',
    'bouteurs',
    'boutions',
    'boutique',
    'boutisse',
    'boutoirs',
    'boutonna',
    'boutonne',
    'boutonné',
    'bouturai',
    'bouturas',
    'bouturât',
    'bouturer',
    'boutures',
    'bouturez',
    'bouverie',
    'bouvière',
    'bouviers',
    'bouvrils',
    'bowlings',
    'boxaient',
    'boxasses',
    'box-calf',
    'boxerais',
    'boxerait',
    'boxèrent',
    'boxeriez',
    'boxerons',
    'boxeront',
    'boycotta',
    'boycotte',
    'boycotté',
    'boycotts',
    'brabants',
    'bracelet',
    'brachial',
    'braconna',
    'braconne',
    'braconné',
    'bractéal',
    'bractées',
    'bradages',
    'bradâmes',
    'bradasse',
    'bradâtes',
    'braderai',
    'braderas',
    'braderez',
    'braderie',
    'bradeurs',
    'bradeuse',
    'bradions',
    'bradypes',
    'brahmane',
    'braierai',
    'braieras',
    'braierez',
    'braillai',
    'braillas',
    'braillât',
    'braillée',
    'brailler',
    'brailles',
    'braillés',
    'braillez',
    'braiment',
    'brairait',
    'brairont',
    'braisais',
    'braisait',
    'braisant',
    'braisées',
    'braisent',
    'braisera',
    'braisiez',
    'braisons',
    'bramâmes',
    'bramasse',
    'bramâtes',
    'bramerai',
    'brameras',
    'bramerez',
    'bramions',
    'brancard',
    'branchai',
    'branchas',
    'branchât',
    'branchée',
    'brancher',
    'branches',
    'branchés',
    'branchez',
    'branchue',
    'branchus',
    'brandade',
    'brandies',
    'brandira',
    'brandons',
    'branlais',
    'branlait',
    'branlant',
    'branlées',
    'branlent',
    'branlera',
    'branliez',
    'branlons',
    'braquage',
    'braquais',
    'braquait',
    'braquant',
    'braquées',
    'braquent',
    'braquera',
    'braquets',
    'braquiez',
    'braquons',
    'brasages',
    'brasâmes',
    'brasasse',
    'brasâtes',
    'braserai',
    'braseras',
    'braserez',
    'braseros',
    'brasiers',
    'brasilla',
    'brasille',
    'brasillé',
    'brasions',
    'brassage',
    'brassais',
    'brassait',
    'brassant',
    'brassard',
    'brassées',
    'brassent',
    'brassera',
    'brasseur',
    'brassiez',
    'brassins',
    'brassons',
    'brasures',
    'bravache',
    'bravades',
    'bravâmes',
    'bravasse',
    'bravâtes',
    'braverai',
    'braveras',
    'braverez',
    'bravions',
    'bravoure',
    'brayâmes',
    'brayasse',
    'brayâtes',
    'brayerai',
    'brayeras',
    'brayerez',
    'brayions',
    'bréchets',
    'brélâmes',
    'brêlâmes',
    'brélasse',
    'brêlasse',
    'brélâtes',
    'brêlâtes',
    'brélerai',
    'brêlerai',
    'bréleras',
    'brêleras',
    'brélerez',
    'brêlerez',
    'brélions',
    'brêlions',
    'brellais',
    'brellait',
    'brellant',
    'brellées',
    'brellent',
    'brellera',
    'brelliez',
    'brellons',
    'breloque',
    'brésilla',
    'brésille',
    'brésillé',
    'bretèche',
    'bretelle',
    'bretessé',
    'brettais',
    'brettait',
    'brettant',
    'brettées',
    'brettela',
    'brettelé',
    'brettent',
    'brettera',
    'bretteur',
    'brettiez',
    'brettons',
    'bretzels',
    'breuvage',
    'brevetai',
    'brevetas',
    'brevetât',
    'brevetée',
    'breveter',
    'brevetés',
    'brevetez',
    'brevette',
    'brévités',
    'bricolai',
    'bricolas',
    'bricolât',
    'bricolée',
    'bricoler',
    'bricoles',
    'bricolés',
    'bricolez',
    'bridâmes',
    'bridasse',
    'bridâtes',
    'briderai',
    'brideras',
    'briderez',
    'bridgeai',
    'bridgeas',
    'bridgeât',
    'bridgent',
    'bridgera',
    'bridgeur',
    'bridgiez',
    'bridions',
    'briefing',
    'brièveté',
    'brifâmes',
    'brifasse',
    'brifâtes',
    'briferai',
    'briferas',
    'briferez',
    'briffais',
    'briffait',
    'briffant',
    'briffées',
    'briffent',
    'briffera',
    'briffiez',
    'briffons',
    'brifions',
    'brigades',
    'briganda',
    'brigande',
    'brigandé',
    'brigands',
    'briguais',
    'briguait',
    'briguant',
    'briguées',
    'briguent',
    'briguera',
    'briguiez',
    'briguons',
    'brillais',
    'brillait',
    'brillant',
    'brillent',
    'brillera',
    'brilliez',
    'brillons',
    'brimades',
    'brimâmes',
    'brimasse',
    'brimâtes',
    'brimbala',
    'brimbale',
    'brimbalé',
    'brimerai',
    'brimeras',
    'brimerez',
    'brimions',
    'bringues',
    'briochée',
    'brioches',
    'briochés',
    'briquais',
    'briquait',
    'briquant',
    'briquées',
    'briquent',
    'briquera',
    'briqueta',
    'briqueté',
    'briquets',
    'briquiez',
    'briquons',
    'brisâmes',
    'brisasse',
    'brisâtes',
    'briscard',
    'briserai',
    'briseras',
    'briserez',
    'briseurs',
    'briseuse',
    'brisions',
    'brisques',
    'bristols',
    'brisures',
    'brocanta',
    'brocante',
    'brocanté',
    'brocarda',
    'brocarde',
    'brocardé',
    'brocards',
    'brochage',
    'brochais',
    'brochait',
    'brochant',
    'brochées',
    'brochent',
    'brochera',
    'brochets',
    'brocheur',
    'brochiez',
    'brochons',
    'brochure',
    'brocolis',
    'brodâmes',
    'brodasse',
    'brodâtes',
    'broderai',
    'broderas',
    'broderez',
    'broderie',
    'brodeurs',
    'brodeuse',
    'brodions',
    'broierai',
    'broieras',
    'broierez',
    'bromates',
    'bromique',
    'bromisme',
    'bromures',
    'bronchai',
    'bronchas',
    'bronchât',
    'broncher',
    'bronches',
    'bronchez',
    'bronzage',
    'bronzais',
    'bronzait',
    'bronzant',
    'bronzées',
    'bronzent',
    'bronzera',
    'bronzeur',
    'bronzier',
    'bronziez',
    'bronzons',
    'brossage',
    'brossais',
    'brossait',
    'brossant',
    'brossées',
    'brossent',
    'brossera',
    'brossier',
    'brossiez',
    'brossons',
    'brouetta',
    'brouette',
    'brouetté',
    'brouhaha',
    'brouilla',
    'brouille',
    'brouillé',
    'brousses',
    'broussin',
    'broutais',
    'broutait',
    'broutant',
    'broutard',
    'broutées',
    'broutent',
    'broutera',
    'broutiez',
    'broutons',
    'browning',
    'broyages',
    'broyâmes',
    'broyasse',
    'broyâtes',
    'broyeurs',
    'broyeuse',
    'broyions',
    'brucines',
    'brugnons',
    'bruinait',
    'bruinera',
    'bruineux',
    'bruirais',
    'bruirait',
    'bruirent',
    'bruiriez',
    'bruirons',
    'bruiront',
    'bruisses',
    'bruissez',
    'bruitage',
    'bruitais',
    'bruitait',
    'bruitant',
    'bruitent',
    'bruitera',
    'bruiteur',
    'bruitiez',
    'bruitons',
    'brûlages',
    'brûlâmes',
    'brûlante',
    'brûlants',
    'brûlasse',
    'brûlâtes',
    'brûlerai',
    'brûleras',
    'brûlerez',
    'brûlerie',
    'brûleurs',
    'brûlions',
    'brûloirs',
    'brûlures',
    'brumaire',
    'brumassa',
    'brumasse',
    'brumassé',
    'brumeuse',
    'brunante',
    'brunâtre',
    'brunette',
    'brunîmes',
    'brunirai',
    'bruniras',
    'brunirez',
    'brunisse',
    'brunîtes',
    'brushing',
    'brusquai',
    'brusquas',
    'brusquât',
    'brusquée',
    'brusquer',
    'brusques',
    'brusqués',
    'brusquez',
    'brutales',
    'bruyante',
    'bruyants',
    'bruyères',
    'buandier',
    'buccales',
    'bûchâmes',
    'bûchasse',
    'bûchâtes',
    'bûcherai',
    'bûcheras',
    'bûcherez',
    'bûcheron',
    'bûchette',
    'bûcheurs',
    'bûcheuse',
    'bûchions',
    'bucranes',
    'buglosse',
    'bugranes',
    'building',
    'buissons',
    'bulbaire',
    'bulbeuse',
    'bulbille',
    'bulgares',
    'bullaire',
    'bulletin',
    'bulleuse',
    'bungalow',
    'bupreste',
    'burelées',
    'burelles',
    'burettes',
    'burinage',
    'burinais',
    'burinait',
    'burinant',
    'burinées',
    'burinent',
    'burinera',
    'burineur',
    'buriniez',
    'burinons',
    'business',
    'bussions',
    'bustiers',
    'butaient',
    'butanier',
    'butasses',
    'buterais',
    'buterait',
    'butèrent',
    'buteriez',
    'buterons',
    'buteront',
    'butinais',
    'butinait',
    'butinant',
    'butinées',
    'butinent',
    'butinera',
    'butineur',
    'butiniez',
    'butinons',
    'buttages',
    'buttâmes',
    'buttasse',
    'buttâtes',
    'butterai',
    'butteras',
    'butterez',
    'buttions',
    'buttoirs',
    'butylène',
    'butyrate',
    'butyreux',
    'butyrine',
    'buvables',
    'buvaient',
    'buvetier',
    'buvettes',
    'buveuses',
    'buvotais',
    'buvotait',
    'buvotant',
    'buvotent',
    'buvotera',
    'buvotiez',
    'buvotons',
    'byzantin',
    'cabalais',
    'cabalait',
    'cabalant',
    'cabalent',
    'cabalera',
    'cabaliez',
    'cabalons',
    'cabanais',
    'cabanait',
    'cabanant',
    'cabanées',
    'cabanent',
    'cabanera',
    'cabaniez',
    'cabanons',
    'cabarets',
    'cabasset',
    'cabernet',
    'cabestan',
    'cabillot',
    'cabinets',
    'câblages',
    'câblâmes',
    'câblasse',
    'câblâtes',
    'câblerai',
    'câbleras',
    'câblerez',
    'câblerie',
    'câbleurs',
    'câbleuse',
    'câbliers',
    'câblions',
    'câbliste',
    'caboches',
    'cabochon',
    'cabossai',
    'cabossas',
    'cabossât',
    'cabossée',
    'cabosser',
    'cabosses',
    'cabossés',
    'cabossez',
    'cabotage',
    'cabotais',
    'cabotait',
    'cabotant',
    'cabotent',
    'cabotera',
    'caboteur',
    'cabotiez',
    'cabotina',
    'cabotine',
    'cabotiné',
    'cabotins',
    'cabotons',
    'caboulot',
    'cabrâmes',
    'cabrasse',
    'cabrâtes',
    'cabrerai',
    'cabreras',
    'cabrerez',
    'cabriola',
    'cabriole',
    'cabriolé',
    'cabrions',
    'cacabais',
    'cacabait',
    'cacabant',
    'cacabent',
    'cacabera',
    'cacabiez',
    'cacabons',
    'cacaotée',
    'cacaotés',
    'cacaoyer',
    'cacardai',
    'cacardas',
    'cacardât',
    'cacarder',
    'cacardes',
    'cacardez',
    'cacatoès',
    'cacatois',
    'cachalot',
    'cachâmes',
    'cachasse',
    'cachâtes',
    'cacherai',
    'cacheras',
    'cacherez',
    'cachetai',
    'cachetas',
    'cachetât',
    'cachetée',
    'cacheter',
    'cachetés',
    'cachetez',
    'cachette',
    'cachexie',
    'cachions',
    'cachucha',
    'caciques',
    'cacolets',
    'cadancha',
    'cadanche',
    'cadanché',
    'cadastra',
    'cadastre',
    'cadastré',
    'cadavres',
    'cadençai',
    'cadenças',
    'cadençât',
    'cadencée',
    'cadencer',
    'cadences',
    'cadencés',
    'cadencez',
    'cadettes',
    'cadmiage',
    'cadmiais',
    'cadmiait',
    'cadmiant',
    'cadmiées',
    'cadmient',
    'cadmiera',
    'cadmiiez',
    'cadmions',
    'cadmiums',
    'cadogans',
    'cadrages',
    'cadrâmes',
    'cadrante',
    'cadrants',
    'cadrasse',
    'cadrâtes',
    'cadrerai',
    'cadreras',
    'cadrerez',
    'cadreurs',
    'cadreuse',
    'cadrions',
    'caducées',
    'caducité',
    'caduques',
    'caecales',
    'caesiums',
    'cafardai',
    'cafardas',
    'cafardât',
    'cafardée',
    'cafarder',
    'cafardes',
    'cafardés',
    'cafardez',
    'caféière',
    'caféiers',
    'caféines',
    'caféisme',
    'cafetans',
    'cafetier',
    'caftâmes',
    'caftasse',
    'caftâtes',
    'cafterai',
    'cafteras',
    'cafterez',
    'caftions',
    'cagnâmes',
    'cagnarda',
    'cagnarde',
    'cagnardé',
    'cagnards',
    'cagnasse',
    'cagnâtes',
    'cagnerai',
    'cagneras',
    'cagnerez',
    'cagneuse',
    'cagnions',
    'cagnotte',
    'cagoules',
    'cahotais',
    'cahotait',
    'cahotant',
    'cahotées',
    'cahotent',
    'cahotera',
    'cahoteux',
    'cahotiez',
    'cahotons',
    'caillais',
    'caillait',
    'caillant',
    'caillées',
    'caillent',
    'caillera',
    'cailleta',
    'cailleté',
    'cailliez',
    'caillons',
    'caillots',
    'cailloux',
    'cairotes',
    'caissier',
    'caissons',
    'cajeputs',
    'cajolais',
    'cajolait',
    'cajolant',
    'cajolées',
    'cajolent',
    'cajolera',
    'cajoleur',
    'cajoliez',
    'cajolons',
    'caladium',
    'calaient',
    'calaison',
    'calamars',
    'calambac',
    'calamina',
    'calamine',
    'calaminé',
    'calamité',
    'calancha',
    'calanche',
    'calanché',
    'calandra',
    'calandre',
    'calandré',
    'calanque',
    'calasses',
    'calcaire',
    'calcémie',
    'calcifié',
    'calcinai',
    'calcinas',
    'calcinât',
    'calcinée',
    'calciner',
    'calcines',
    'calcinés',
    'calcinez',
    'calcique',
    'calcites',
    'calculai',
    'calculas',
    'calculât',
    'calculée',
    'calculer',
    'calcules',
    'calculés',
    'calculez',
    'caldeira',
    'calèches',
    'caleçons',
    'calendes',
    'calepins',
    'calerais',
    'calerait',
    'calèrent',
    'caleriez',
    'calerons',
    'caleront',
    'caletais',
    'caletait',
    'caletant',
    'caletées',
    'caletiez',
    'caletons',
    'calettes',
    'calfatai',
    'calfatas',
    'calfatât',
    'calfatée',
    'calfater',
    'calfates',
    'calfatés',
    'calfatez',
    'calibrai',
    'calibras',
    'calibrât',
    'calibrée',
    'calibrer',
    'calibres',
    'calibrés',
    'calibrez',
    'calicots',
    'calicule',
    'califats',
    'câlinais',
    'câlinait',
    'câlinant',
    'câlinées',
    'câlinent',
    'câlinera',
    'câliniez',
    'câlinons',
    'caliorne',
    'calisson',
    'calleuse',
    'calmâmes',
    'calmante',
    'calmants',
    'calmasse',
    'calmâtes',
    'calmerai',
    'calmeras',
    'calmerez',
    'calmîmes',
    'calmions',
    'calmirai',
    'calmiras',
    'calmirez',
    'calmisse',
    'calmîtes',
    'calomels',
    'calomnia',
    'calomnie',
    'calomnié',
    'calories',
    'calotins',
    'calottai',
    'calottas',
    'calottât',
    'calottée',
    'calotter',
    'calottes',
    'calottés',
    'calottez',
    'caloyère',
    'caloyers',
    'calquage',
    'calquais',
    'calquait',
    'calquant',
    'calquées',
    'calquent',
    'calquera',
    'calquiez',
    'calquons',
    'caltâmes',
    'caltasse',
    'caltâtes',
    'calterai',
    'calteras',
    'calterez',
    'caltions',
    'calumets',
    'calvados',
    'calvaire',
    'calville',
    'calvitie',
    'calypsos',
    'camaïeux',
    'camarade',
    'camardes',
    'cambiale',
    'cambiaux',
    'cambiste',
    'cambouis',
    'cambrage',
    'cambrais',
    'cambrait',
    'cambrant',
    'cambrées',
    'cambrent',
    'cambrera',
    'cambrien',
    'cambriez',
    'cambrons',
    'cambrure',
    'cambuses',
    'cambutai',
    'cambutas',
    'cambutât',
    'cambutée',
    'cambuter',
    'cambutes',
    'cambutés',
    'cambutez',
    'caméléon',
    'camélias',
    'cameline',
    'caméline',
    'camelles',
    'camelota',
    'camelote',
    'cameloté',
    'camelots',
    'camérier',
    'camionna',
    'camionne',
    'camionné',
    'camisard',
    'camisole',
    'camoufla',
    'camoufle',
    'camouflé',
    'campagne',
    'campâmes',
    'campanes',
    'campasse',
    'campâtes',
    'campêche',
    'camperai',
    'camperas',
    'camperez',
    'campeurs',
    'campeuse',
    'camphrée',
    'camphres',
    'camphrés',
    'campings',
    'campions',
    'canadien',
    'canaient',
    'canaille',
    'canalisa',
    'canalise',
    'canalisé',
    'cananéen',
    'canardai',
    'canardas',
    'canardât',
    'canardée',
    'canarder',
    'canardes',
    'canardés',
    'canardez',
    'canasses',
    'canasson',
    'cancales',
    'cancanai',
    'cancanas',
    'cancanât',
    'cancaner',
    'cancanes',
    'cancanez',
    'candelas',
    'candeurs',
    'candidat',
    'candides',
    'candîmes',
    'candirai',
    'candiras',
    'candirez',
    'candisse',
    'candîtes',
    'canerais',
    'canerait',
    'canèrent',
    'caneriez',
    'canerons',
    'caneront',
    'canetons',
    'canettes',
    'caniches',
    'canicule',
    'canisses',
    'canities',
    'caniveau',
    'cannabis',
    'cannages',
    'cannâmes',
    'cannasse',
    'cannâtes',
    'cannelai',
    'cannelas',
    'cannelât',
    'cannelée',
    'canneler',
    'cannelés',
    'cannelez',
    'cannelle',
    'cannerai',
    'canneras',
    'cannerez',
    'canneurs',
    'canneuse',
    'cannions',
    'cannisse',
    'canonial',
    'canonisa',
    'canonise',
    'canonisé',
    'canonnai',
    'canonnas',
    'canonnât',
    'canonnée',
    'canonner',
    'canonnes',
    'canonnés',
    'canonnez',
    'canotage',
    'canotais',
    'canotait',
    'canotant',
    'canotent',
    'canotera',
    'canoteur',
    'canotier',
    'canotiez',
    'canotons',
    'cantates',
    'cantines',
    'cantique',
    'cantonal',
    'cantonna',
    'cantonne',
    'cantonné',
    'canulais',
    'canulait',
    'canulant',
    'canulars',
    'canulées',
    'canulent',
    'canulera',
    'canuliez',
    'canulons',
    'canutuse',
    'canzones',
    'capables',
    'capacité',
    'capéâmes',
    'capéasse',
    'capéâtes',
    'capéerai',
    'capéeras',
    'capéerez',
    'capéions',
    'capelage',
    'capelais',
    'capelait',
    'capelans',
    'capelant',
    'capelées',
    'capelets',
    'capeliez',
    'capeline',
    'capelles',
    'capelons',
    'capésien',
    'capétien',
    'capeyais',
    'capeyait',
    'capeyant',
    'capeyent',
    'capeyera',
    'capeyiez',
    'capeyons',
    'capiston',
    'capitale',
    'capitaux',
    'capitées',
    'capiteux',
    'capitole',
    'capitons',
    'capitoul',
    'capitula',
    'capitule',
    'capitulé',
    'caponnai',
    'caponnas',
    'caponnât',
    'caponner',
    'caponnes',
    'caponnez',
    'caporaux',
    'capotage',
    'capotais',
    'capotait',
    'capotant',
    'capotées',
    'capotent',
    'capotera',
    'capotiez',
    'capotons',
    'caprices',
    'câpriers',
    'caprines',
    'capselle',
    'capsides',
    'capsulai',
    'capsulas',
    'capsulât',
    'capsulée',
    'capsuler',
    'capsules',
    'capsulés',
    'capsulez',
    'captages',
    'captâmes',
    'captasse',
    'captâtes',
    'captatif',
    'capterai',
    'capteras',
    'capterez',
    'capteurs',
    'captieux',
    'captions',
    'captivai',
    'captivas',
    'captivât',
    'captivée',
    'captiver',
    'captives',
    'captivés',
    'captivez',
    'capturai',
    'capturas',
    'capturât',
    'capturée',
    'capturer',
    'captures',
    'capturés',
    'capturez',
    'capuches',
    'capuchon',
    'capucine',
    'capucins',
    'capulets',
    'caquâmes',
    'caquasse',
    'caquâtes',
    'caquelon',
    'caquerai',
    'caqueras',
    'caquerez',
    'caquetai',
    'caquetas',
    'caquetât',
    'caqueter',
    'caquetez',
    'caquette',
    'caquions',
    'carabine',
    'carabiné',
    'carabins',
    'caracals',
    'caracola',
    'caracole',
    'caracolé',
    'caraculs',
    'carafons',
    'caraïbes',
    'caramélé',
    'caramels',
    'carapace',
    'carapata',
    'carapate',
    'carapaté',
    'caravane',
    'carbonée',
    'carbones',
    'carbonés',
    'carburai',
    'carburas',
    'carburât',
    'carburée',
    'carburer',
    'carbures',
    'carburés',
    'carburez',
    'carcajou',
    'carcasse',
    'carcéral',
    'cardages',
    'cardâmes',
    'cardasse',
    'cardâtes',
    'carderai',
    'carderas',
    'cardères',
    'carderez',
    'cardeurs',
    'cardeuse',
    'cardiale',
    'cardiaux',
    'cardigan',
    'cardinal',
    'cardions',
    'cardites',
    'carénage',
    'carénais',
    'carénait',
    'carénant',
    'carençai',
    'carenças',
    'carençât',
    'carencée',
    'carencer',
    'carences',
    'carencés',
    'carencez',
    'carénées',
    'carènent',
    'carénera',
    'caréniez',
    'carénons',
    'caressai',
    'caressas',
    'caressât',
    'caressée',
    'caresser',
    'caresses',
    'caressés',
    'caressez',
    'carguais',
    'carguait',
    'carguant',
    'carguées',
    'carguent',
    'carguera',
    'carguiez',
    'carguons',
    'cariacou',
    'cariâmes',
    'cariasse',
    'cariâtes',
    'caribous',
    'carierai',
    'carieras',
    'carierez',
    'carieuse',
    'cariions',
    'carillon',
    'caristes',
    'carlines',
    'carlisme',
    'carliste',
    'carmâmes',
    'carmasse',
    'carmâtes',
    'carmerai',
    'carmeras',
    'carmerez',
    'carminai',
    'carminas',
    'carminât',
    'carminée',
    'carminer',
    'carmines',
    'carminés',
    'carminez',
    'carmions',
    'carnages',
    'carnaval',
    'carneaux',
    'carnèles',
    'carniers',
    'carnifia',
    'carnifie',
    'carnifié',
    'caronade',
    'carotène',
    'carotide',
    'carottai',
    'carottas',
    'carottât',
    'carottée',
    'carotter',
    'carottes',
    'carottés',
    'carottez',
    'caroubes',
    'carpeaux',
    'carpelle',
    'carpette',
    'carpiens',
    'carrâmes',
    'carrares',
    'carrasse',
    'carrâtes',
    'carreaux',
    'carrelai',
    'carrelas',
    'carrelât',
    'carrelée',
    'carreler',
    'carrelés',
    'carrelet',
    'carrelez',
    'carrelle',
    'carrerai',
    'carreras',
    'carrerez',
    'carricks',
    'carrière',
    'carriers',
    'carriole',
    'carrions',
    'carroies',
    'carrossa',
    'carrosse',
    'carrossé',
    'carroyai',
    'carroyas',
    'carroyât',
    'carroyée',
    'carroyer',
    'carroyés',
    'carroyez',
    'carrures',
    'cartable',
    'cartiers',
    'cartonna',
    'cartonne',
    'cartonné',
    'cartoons',
    'caryopse',
    'casaient',
    'casanier',
    'casaques',
    'casaquin',
    'casasses',
    'cascadai',
    'cascadas',
    'cascadât',
    'cascader',
    'cascades',
    'cascadez',
    'caséfiai',
    'caséfias',
    'caséfiât',
    'caséfiée',
    'caséfier',
    'caséfies',
    'caséfiés',
    'caséfiez',
    'casemata',
    'casemate',
    'casematé',
    'caserais',
    'caserait',
    'casèrent',
    'caseriez',
    'casernai',
    'casernas',
    'casernât',
    'casernée',
    'caserner',
    'casernes',
    'casernés',
    'casernez',
    'caserons',
    'caseront',
    'cashmere',
    'casimirs',
    'casquais',
    'casquait',
    'casquant',
    'casquées',
    'casquent',
    'casquera',
    'casquiez',
    'casquons',
    'cassable',
    'cassages',
    'cassâmes',
    'cassante',
    'cassants',
    'cassasse',
    'cassates',
    'cassâtes',
    'casseaux',
    'casserai',
    'casseras',
    'casserez',
    'cassetin',
    'cassette',
    'casseurs',
    'casseuse',
    'cassiers',
    'cassines',
    'cassions',
    'cassures',
    'castagna',
    'castagne',
    'castagné',
    'castines',
    'castrais',
    'castrait',
    'castrant',
    'castrats',
    'castrées',
    'castrent',
    'castrera',
    'castriez',
    'castrons',
    'casuelle',
    'casuiste',
    'cataires',
    'catalane',
    'catalans',
    'catalpas',
    'catalysa',
    'catalyse',
    'catalysé',
    'catarrhe',
    'catchais',
    'catchait',
    'catchant',
    'catchent',
    'catchera',
    'catcheur',
    'catchiez',
    'catchons',
    'cathares',
    'cathéter',
    'cathodes',
    'catimini',
    'catogans',
    'cattleya',
    'caudales',
    'caudillo',
    'causales',
    'causâmes',
    'causante',
    'causants',
    'causasse',
    'causâtes',
    'causatif',
    'causerai',
    'causeras',
    'causerez',
    'causerie',
    'causette',
    'causeurs',
    'causeuse',
    'causions',
    'cautèles',
    'cautères',
    'cautions',
    'cavaient',
    'cavalais',
    'cavalait',
    'cavalant',
    'cavalées',
    'cavalent',
    'cavalera',
    'cavaleur',
    'cavalier',
    'cavaliez',
    'cavalons',
    'cavasses',
    'cavatine',
    'caverais',
    'caverait',
    'cavèrent',
    'caveriez',
    'cavernes',
    'caverons',
    'caveront',
    'caviarda',
    'caviarde',
    'caviardé',
    'cavistes',
    'cécidies',
    'cédaient',
    'cédantes',
    'cédasses',
    'céderais',
    'céderait',
    'cédèrent',
    'céderiez',
    'céderons',
    'céderont',
    'cédilles',
    'cédraies',
    'ceignais',
    'ceignait',
    'ceignant',
    'ceignent',
    'ceigniez',
    'ceignons',
    'ceindrai',
    'ceindras',
    'ceindrez',
    'ceintura',
    'ceinture',
    'ceinturé',
    'celaient',
    'celasses',
    'célébrai',
    'célébras',
    'célébrât',
    'célébrée',
    'célébrer',
    'célébrés',
    'célèbres',
    'celebret',
    'célébrez',
    'cèlerais',
    'cèlerait',
    'celèrent',
    'cèleriez',
    'célérité',
    'cèlerons',
    'cèleront',
    'célestas',
    'célestes',
    'célestin',
    'célibats',
    'celle-ci',
    'celle-là',
    'celliers',
    'cellular',
    'cellules',
    'celtique',
    'celui-ci',
    'celui-là',
    'cémentai',
    'cémentas',
    'cémentât',
    'cémentée',
    'cémenter',
    'cémentes',
    'cémentés',
    'cémentez',
    'cénacles',
    'cendrais',
    'cendrait',
    'cendrant',
    'cendrées',
    'cendrent',
    'cendrera',
    'cendreux',
    'cendrier',
    'cendriez',
    'cendrons',
    'cenelles',
    'cénobite',
    'censeurs',
    'censière',
    'censiers',
    'censorat',
    'censurai',
    'censuras',
    'censurât',
    'censurée',
    'censurer',
    'censures',
    'censurés',
    'censurez',
    'centaine',
    'centaure',
    'centavos',
    'centiare',
    'centibar',
    'centième',
    'centimes',
    'centrage',
    'centrais',
    'centrait',
    'centrale',
    'centrant',
    'centraux',
    'centrées',
    'centrent',
    'centrera',
    'centreur',
    'centriez',
    'centrons',
    'centupla',
    'centuple',
    'centuplé',
    'centurie',
    'céphalée',
    'céphéide',
    'cérambyx',
    'cérastes',
    'cerbères',
    'cerceaux',
    'cerclage',
    'cerclais',
    'cerclait',
    'cerclant',
    'cerclées',
    'cerclent',
    'cerclera',
    'cercliez',
    'cerclons',
    'cercueil',
    'céréales',
    'cérébral',
    'cerfeuil',
    'cerisaie',
    'cerisier',
    'cérithes',
    'cernâmes',
    'cernasse',
    'cernâtes',
    'cerneaux',
    'cernerai',
    'cerneras',
    'cernerez',
    'cernions',
    'certaine',
    'certains',
    'certifia',
    'certifie',
    'certifié',
    'céruléen',
    'cerveaux',
    'cervelas',
    'cervelet',
    'cervelle',
    'cervical',
    'cerviers',
    'cervoise',
    'césarien',
    'cessâmes',
    'cessante',
    'cessants',
    'cessasse',
    'cessâtes',
    'cesserai',
    'cesseras',
    'cesserez',
    'cessible',
    'cessions',
    'cétéracs',
    'cétoines',
    'chablais',
    'chablait',
    'chablant',
    'chablées',
    'chablent',
    'chablera',
    'chabliez',
    'chablons',
    'chabrols',
    'chabrots',
    'chacones',
    'chaconne',
    'chadburn',
    'chadoufs',
    'chafouin',
    'chagrina',
    'chagrine',
    'chagriné',
    'chagrins',
    'chahutai',
    'chahutas',
    'chahutât',
    'chahutée',
    'chahuter',
    'chahutes',
    'chahutés',
    'chahutez',
    'chaînage',
    'chaînais',
    'chaînait',
    'chaînant',
    'chaînées',
    'chaînent',
    'chaînera',
    'chaîneur',
    'chaînier',
    'chaîniez',
    'chaînons',
    'chaisier',
    'chalands',
    'chalazes',
    'chaldéen',
    'chaleurs',
    'chaloupa',
    'chaloupe',
    'chaloupé',
    'chamades',
    'chamarra',
    'chamarre',
    'chamarré',
    'chambard',
    'chambrai',
    'chambras',
    'chambrât',
    'chambrée',
    'chambrer',
    'chambres',
    'chambrés',
    'chambrez',
    'chameaux',
    'chamelle',
    'chamoisa',
    'chamoise',
    'chamoisé',
    'champart',
    'champion',
    'chançard',
    'chancela',
    'chancelé',
    'chancels',
    'chanceux',
    'chancira',
    'chancres',
    'chandail',
    'changeai',
    'changeas',
    'changeât',
    'changées',
    'changent',
    'changera',
    'changeur',
    'changiez',
    'chanoine',
    'chansons',
    'chantage',
    'chantais',
    'chantait',
    'chantant',
    'chanteau',
    'chantées',
    'chantent',
    'chantera',
    'chanteur',
    'chantier',
    'chantiez',
    'chantons',
    'chantres',
    'chanvres',
    'chaouchs',
    'chaparda',
    'chaparde',
    'chapardé',
    'chapeaux',
    'chapelai',
    'chapelas',
    'chapelât',
    'chapelée',
    'chapeler',
    'chapelés',
    'chapelet',
    'chapelez',
    'chapelle',
    'chaperon',
    'chapitra',
    'chapitre',
    'chapitré',
    'chaponna',
    'chaponne',
    'chaponné',
    'chapskas',
    'charabia',
    'charades',
    'charbons',
    'charcuta',
    'charcute',
    'charcuté',
    'chardons',
    'chargeai',
    'chargeas',
    'chargeât',
    'chargées',
    'chargent',
    'chargera',
    'chargeur',
    'chargiez',
    'chariots',
    'charisme',
    'charités',
    'charmais',
    'charmait',
    'charmant',
    'charmées',
    'charment',
    'charmera',
    'charmeur',
    'charmiez',
    'charmons',
    'charnels',
    'charnier',
    'charnues',
    'charogne',
    'charpies',
    'charriai',
    'charrias',
    'charriât',
    'charriée',
    'charrier',
    'charries',
    'charriés',
    'charriez',
    'charroie',
    'charrois',
    'charrons',
    'charroya',
    'charroyé',
    'charrues',
    'charters',
    'chassais',
    'chassait',
    'chassant',
    'chassées',
    'chassent',
    'chassera',
    'chasseur',
    'chassies',
    'chassiez',
    'chassons',
    'chasteté',
    'chasuble',
    'châtains',
    'châteaux',
    'châtiais',
    'châtiait',
    'châtiant',
    'châtiées',
    'châtient',
    'châtiera',
    'chatière',
    'châtiiez',
    'châtions',
    'chatoies',
    'chatonna',
    'chatonne',
    'chatonné',
    'chatoyai',
    'chatoyas',
    'chatoyât',
    'chatoyer',
    'chatoyez',
    'châtrais',
    'châtrait',
    'châtrant',
    'châtrées',
    'châtrent',
    'châtrera',
    'châtriez',
    'châtrons',
    'chaudeau',
    'chaudron',
    'chauffai',
    'chauffas',
    'chauffât',
    'chauffée',
    'chauffer',
    'chauffes',
    'chauffés',
    'chauffez',
    'chaufour',
    'chaulage',
    'chaulais',
    'chaulait',
    'chaulant',
    'chaulées',
    'chaulent',
    'chaulera',
    'chauliez',
    'chaulons',
    'chaumais',
    'chaumait',
    'chaumant',
    'chaumées',
    'chaument',
    'chaumera',
    'chaumiez',
    'chaumine',
    'chaumons',
    'chaussai',
    'chaussas',
    'chaussât',
    'chaussée',
    'chausser',
    'chausses',
    'chaussés',
    'chaussez',
    'chausson',
    'chauvine',
    'chauvins',
    'chauvira',
    'chavirai',
    'chaviras',
    'chavirât',
    'chavirée',
    'chavirer',
    'chavires',
    'chavirés',
    'chavirez',
    'chéchias',
    'check-up',
    'cheddite',
    'chelléen',
    'cheminai',
    'cheminas',
    'cheminât',
    'cheminée',
    'cheminer',
    'chemines',
    'cheminez',
    'cheminot',
    'chemisai',
    'chemisas',
    'chemisât',
    'chemisée',
    'chemiser',
    'chemises',
    'chemisés',
    'chemisez',
    'chênaies',
    'chenapan',
    'chènevis',
    'chenille',
    'chenillé',
    'cheptels',
    'chéquier',
    'chérâmes',
    'chérasse',
    'chérâtes',
    'cherchai',
    'cherchas',
    'cherchât',
    'cherchée',
    'chercher',
    'cherches',
    'cherchés',
    'cherchez',
    'chérerai',
    'chéreras',
    'chérerez',
    'cherguis',
    'chérîmes',
    'chérions',
    'chérirai',
    'chériras',
    'chérirez',
    'chérisse',
    'chérîtes',
    'cherrais',
    'cherrait',
    'cherrant',
    'cherrent',
    'cherrera',
    'cherriez',
    'cherrons',
    'chérubin',
    'chesters',
    'chétives',
    'chevalai',
    'chevalas',
    'chevalât',
    'chevalée',
    'chevaler',
    'chevales',
    'chevalés',
    'chevalet',
    'chevalez',
    'chevalin',
    'chevêche',
    'chevelue',
    'chevelus',
    'chevêtre',
    'chevilla',
    'cheville',
    'chevillé',
    'chevreau',
    'chevreta',
    'chevreté',
    'chevrier',
    'chevrons',
    'chevrota',
    'chevrote',
    'chevroté',
    'chez-moi',
    'chez-soi',
    'chez-toi',
    'chiadais',
    'chiadait',
    'chiadant',
    'chiadées',
    'chiadent',
    'chiadera',
    'chiadiez',
    'chiadons',
    'chiaient',
    'chialais',
    'chialait',
    'chialant',
    'chialent',
    'chialera',
    'chialiez',
    'chialons',
    'chiantes',
    'chiantis',
    'chiasmas',
    'chiasmes',
    'chiasses',
    'chicanai',
    'chicanas',
    'chicanât',
    'chicanée',
    'chicaner',
    'chicanes',
    'chicanés',
    'chicanez',
    'chicorée',
    'chicotai',
    'chicotas',
    'chicotât',
    'chicoter',
    'chicotes',
    'chicotez',
    'chicotin',
    'chienlit',
    'chiennai',
    'chiennas',
    'chiennât',
    'chienner',
    'chiennes',
    'chiennez',
    'chierais',
    'chierait',
    'chièrent',
    'chieries',
    'chieriez',
    'chierons',
    'chieront',
    'chiffons',
    'chiffrai',
    'chiffras',
    'chiffrât',
    'chiffrée',
    'chiffrer',
    'chiffres',
    'chiffrés',
    'chiffrez',
    'chignole',
    'chignons',
    'chiliens',
    'chimères',
    'chimique',
    'chimisme',
    'chimiste',
    'chinages',
    'chinâmes',
    'chinasse',
    'chinâtes',
    'chinerai',
    'chineras',
    'chinerez',
    'chineurs',
    'chineuse',
    'chinions',
    'chinoisa',
    'chinoise',
    'chinoisé',
    'chinooks',
    'chinures',
    'chiottes',
    'chiourme',
    'chipâmes',
    'chipasse',
    'chipâtes',
    'chiperai',
    'chiperas',
    'chiperez',
    'chipions',
    'chipotai',
    'chipotas',
    'chipotât',
    'chipotée',
    'chipoter',
    'chipotes',
    'chipotés',
    'chipotez',
    'chiquais',
    'chiquait',
    'chiquant',
    'chiquées',
    'chiquent',
    'chiquera',
    'chiqueur',
    'chiquiez',
    'chiquons',
    'chistera',
    'chitines',
    'chlamyde',
    'chleuhes',
    'chlingua',
    'chlingue',
    'chlingué',
    'chlorais',
    'chlorait',
    'chlorals',
    'chlorant',
    'chlorate',
    'chlorées',
    'chlorent',
    'chlorera',
    'chloriez',
    'chlorons',
    'chlorose',
    'chlorura',
    'chlorure',
    'chloruré',
    'chocolat',
    'choierai',
    'choieras',
    'choierez',
    'choirais',
    'choisies',
    'choisira',
    'cholémie',
    'choléras',
    'cholines',
    'cholurie',
    'chômable',
    'chômages',
    'chômâmes',
    'chômasse',
    'chômâtes',
    'chômerai',
    'chômeras',
    'chômerez',
    'chômeurs',
    'chômeuse',
    'chômions',
    'chopâmes',
    'chopasse',
    'chopâtes',
    'choperai',
    'choperas',
    'choperez',
    'chopinai',
    'chopinas',
    'chopinât',
    'chopiner',
    'chopines',
    'chopinez',
    'chopions',
    'choppais',
    'choppait',
    'choppant',
    'choppent',
    'choppera',
    'choppiez',
    'choppons',
    'choquais',
    'choquait',
    'choquant',
    'choquées',
    'choquent',
    'choquera',
    'choquiez',
    'choquons',
    'chorales',
    'chorèges',
    'choreute',
    'chorions',
    'choriste',
    'chorizos',
    'choroïde',
    'chosifia',
    'chosifie',
    'chosifié',
    'chouchou',
    'chouette',
    'chouleur',
    'chourava',
    'chourave',
    'chouravé',
    'chourina',
    'chourine',
    'chouriné',
    'choyâmes',
    'choyasse',
    'choyâtes',
    'choyions',
    'chrémeau',
    'chrétien',
    'chrismes',
    'chromage',
    'chromais',
    'chromait',
    'chromant',
    'chromate',
    'chromées',
    'chroment',
    'chromera',
    'chromiez',
    'chromons',
    'chroumai',
    'chroumas',
    'chroumât',
    'chroumée',
    'chroumer',
    'chroumes',
    'chroumés',
    'chroumez',
    'chuchota',
    'chuchote',
    'chuchoté',
    'chuintai',
    'chuintas',
    'chuintât',
    'chuinter',
    'chuintes',
    'chuintez',
    'chutâmes',
    'chutasse',
    'chutâtes',
    'chuterai',
    'chuteras',
    'chuterez',
    'chutions',
    'ci-après',
    'ci-avant',
    'cibiches',
    'cibistes',
    'ciboires',
    'ciborium',
    'ciboules',
    'ciboulot',
    'cicérone',
    'cicutine',
    'cidrerie',
    'cigognes',
    'ci-joint',
    'ciliaire',
    'cillâmes',
    'cillasse',
    'cillâtes',
    'cillerai',
    'cilleras',
    'cillerez',
    'cillions',
    'cimaises',
    'cimentai',
    'cimentas',
    'cimentât',
    'cimentée',
    'cimenter',
    'cimentes',
    'cimentés',
    'cimentez',
    'cinabres',
    'cinéaste',
    'cinérama',
    'cinérite',
    'cinétirs',
    'cinglais',
    'cinglait',
    'cinglant',
    'cinglées',
    'cinglent',
    'cinglera',
    'cingliez',
    'cinglons',
    'cinoches',
    'cinoques',
    'cintrage',
    'cintrais',
    'cintrait',
    'cintrant',
    'cintrées',
    'cintrent',
    'cintrera',
    'cintriez',
    'cintrons',
    'cipolins',
    'ciraient',
    'cirasses',
    'circuits',
    'circulai',
    'circulas',
    'circulât',
    'circuler',
    'circules',
    'circulez',
    'cirerais',
    'cirerait',
    'cirèrent',
    'cireriez',
    'cirerons',
    'cireront',
    'cireuses',
    'cirières',
    'cirrhose',
    'cisailla',
    'cisaille',
    'cisaillé',
    'cisalpin',
    'ciselais',
    'ciselait',
    'ciselant',
    'ciselées',
    'cisèlent',
    'cisélera',
    'ciselets',
    'ciseleur',
    'ciseliez',
    'ciselons',
    'ciselure',
    'cisoires',
    'cistrons',
    'cistudes',
    'citadine',
    'citadins',
    'citaient',
    'citasses',
    'citation',
    'citerais',
    'citerait',
    'citèrent',
    'citeriez',
    'citernes',
    'citerons',
    'citeront',
    'cithares',
    'citoyens',
    'citrates',
    'citrines',
    'citrique',
    'citronné',
    'civelles',
    'civettes',
    'civières',
    'civilisa',
    'civilise',
    'civilisé',
    'civilité',
    'civiques',
    'civismes',
    'clabauda',
    'clabaude',
    'clabaudé',
    'clabauds',
    'clabotai',
    'clabotas',
    'clabotât',
    'clabotée',
    'claboter',
    'clabotes',
    'clabotés',
    'clabotez',
    'clairets',
    'clairons',
    'clamâmes',
    'clamasse',
    'clamâtes',
    'clamerai',
    'clameras',
    'clamerez',
    'clameurs',
    'clamions',
    'clampais',
    'clampait',
    'clampant',
    'clampées',
    'clampent',
    'clampera',
    'clampiez',
    'clampons',
    'clampsai',
    'clampsas',
    'clampsât',
    'clampsée',
    'clampser',
    'clampses',
    'clampsés',
    'clampsez',
    'clamsais',
    'clamsait',
    'clamsant',
    'clamsent',
    'clamsera',
    'clamsiez',
    'clamsons',
    'clanique',
    'clapâmes',
    'clapasse',
    'clapâtes',
    'claperai',
    'claperas',
    'claperez',
    'clapiers',
    'clapîmes',
    'clapions',
    'clapirai',
    'clapiras',
    'clapirez',
    'clapisse',
    'clapîtes',
    'clapotai',
    'clapotas',
    'clapotât',
    'clapoter',
    'clapotes',
    'clapotez',
    'clapotis',
    'clappais',
    'clappait',
    'clappant',
    'clappent',
    'clappera',
    'clappiez',
    'clappons',
    'clapsais',
    'clapsait',
    'clapsant',
    'clapsent',
    'clapsera',
    'clapsiez',
    'clapsons',
    'claquage',
    'claquais',
    'claquait',
    'claquant',
    'claquées',
    'claquent',
    'claquera',
    'claqueta',
    'claqueté',
    'claquets',
    'claquiez',
    'claquoir',
    'claquons',
    'clarifia',
    'clarifie',
    'clarifié',
    'clarines',
    'clarisse',
    'classais',
    'classait',
    'classant',
    'classées',
    'classent',
    'classera',
    'classeur',
    'classiez',
    'classons',
    'claustra',
    'claustre',
    'claustré',
    'clausule',
    'clavaire',
    'clavâmes',
    'clavasse',
    'clavâtes',
    'claveaus',
    'clavecin',
    'clavelée',
    'clavelés',
    'claverai',
    'claveras',
    'claverez',
    'clavetai',
    'clavetas',
    'clavetât',
    'clavetée',
    'claveter',
    'clavetés',
    'clavetez',
    'clavetta',
    'clavette',
    'clavetté',
    'claviers',
    'clavions',
    'claviste',
    'clayères',
    'clayette',
    'claymore',
    'clayonna',
    'clayonne',
    'clayonné',
    'clearing',
    'clémence',
    'clémente',
    'cléments',
    'clenches',
    'clephtes',
    'clergies',
    'clérical',
    'clichage',
    'clichais',
    'clichait',
    'clichant',
    'clichées',
    'clichent',
    'clichera',
    'clicheur',
    'clichiez',
    'clichons',
    'clientes',
    'clignais',
    'clignait',
    'clignant',
    'clignées',
    'clignent',
    'clignera',
    'cligniez',
    'clignons',
    'clignota',
    'clignote',
    'clignoté',
    'clinfocs',
    'clinicat',
    'clinique',
    'cliqueta',
    'cliqueté',
    'cliquets',
    'clissais',
    'clissait',
    'clissant',
    'clissées',
    'clissent',
    'clissera',
    'clissiez',
    'clissons',
    'clitoris',
    'clivable',
    'clivages',
    'clivâmes',
    'clivasse',
    'clivâtes',
    'cliverai',
    'cliveras',
    'cliverez',
    'clivions',
    'cloaques',
    'clochais',
    'clochait',
    'clochant',
    'clochard',
    'clochées',
    'clochent',
    'clochera',
    'clochers',
    'clochiez',
    'clochons',
    'cloisons',
    'cloîtrai',
    'cloîtras',
    'cloîtrât',
    'cloîtrée',
    'cloîtrer',
    'cloîtres',
    'cloîtrés',
    'cloîtrez',
    'clopinai',
    'clopinas',
    'clopinât',
    'clopiner',
    'clopines',
    'clopinez',
    'cloporte',
    'cloquais',
    'cloquait',
    'cloquant',
    'cloquent',
    'cloquera',
    'cloquiez',
    'cloquons',
    'clorions',
    'closerie',
    'clôturai',
    'clôturas',
    'clôturât',
    'clôturée',
    'clôturer',
    'clôtures',
    'clôturés',
    'clôturez',
    'clouâmes',
    'clouasse',
    'clouâtes',
    'clouerai',
    'cloueras',
    'clouerez',
    'clouions',
    'cloutage',
    'cloutais',
    'cloutait',
    'cloutant',
    'cloutard',
    'cloutées',
    'cloutent',
    'cloutera',
    'cloutier',
    'cloutiez',
    'cloutons',
    'clovisse',
    'cloyères',
    'clubiste',
    'clystère',
    'cnémides',
    'coaccusé',
    'coagulai',
    'coagulas',
    'coagulât',
    'coagulée',
    'coaguler',
    'coagules',
    'coagulés',
    'coagulez',
    'coagulum',
    'coalisai',
    'coalisas',
    'coalisât',
    'coalisée',
    'coaliser',
    'coalises',
    'coalisés',
    'coalisez',
    'coaltars',
    'coassais',
    'coassait',
    'coassant',
    'coassent',
    'coassera',
    'coassiez',
    'coassons',
    'coauteur',
    'coaxiale',
    'coaxiaux',
    'cocardes',
    'cocasses',
    'coccidie',
    'cochâmes',
    'côchâmes',
    'cochasse',
    'côchasse',
    'cochâtes',
    'côchâtes',
    'cocherai',
    'côcherai',
    'cocheras',
    'côcheras',
    'cochères',
    'cocherez',
    'côcherez',
    'cochions',
    'côchions',
    'cochonna',
    'cochonne',
    'cochonné',
    'cochylis',
    'cockneys',
    'cockpits',
    'cocktail',
    'cocorico',
    'cocotier',
    'cocottes',
    'coctions',
    'cocuages',
    'cocufiai',
    'cocufias',
    'cocufiât',
    'cocufiée',
    'cocufier',
    'cocufies',
    'cocufiés',
    'cocufiez',
    'codaient',
    'codasses',
    'coderais',
    'coderait',
    'codèrent',
    'coderiez',
    'coderons',
    'coderont',
    'codétenu',
    'codeuses',
    'codifiai',
    'codifias',
    'codifiât',
    'codifiée',
    'codifier',
    'codifies',
    'codifiés',
    'codifiez',
    'coenzyme',
    'coexista',
    'coexiste',
    'coexisté',
    'coffrage',
    'coffrais',
    'coffrait',
    'coffrant',
    'coffrées',
    'coffrent',
    'coffrera',
    'coffrets',
    'coffriez',
    'coffrons',
    'cogérant',
    'cogitais',
    'cogitait',
    'cogitant',
    'cogitées',
    'cogitent',
    'cogitera',
    'cogitiez',
    'cogitons',
    'cognâmes',
    'cognasse',
    'cognâtes',
    'cognerai',
    'cogneras',
    'cognerez',
    'cognions',
    'cognitif',
    'cohabita',
    'cohabite',
    'cohabité',
    'cohérent',
    'cohéreur',
    'cohérita',
    'cohérite',
    'cohérité',
    'cohésifs',
    'cohésion',
    'cohésive',
    'cohortes',
    'coiffais',
    'coiffait',
    'coiffant',
    'coiffées',
    'coiffent',
    'coiffera',
    'coiffeur',
    'coiffiez',
    'coiffons',
    'coiffure',
    'coinçais',
    'coinçait',
    'coinçant',
    'coincées',
    'coincent',
    'coincera',
    'coïncida',
    'coïncide',
    'coïncidé',
    'coinciez',
    'coinçons',
    'coïtâmes',
    'coïtasse',
    'coïtâtes',
    'coïterai',
    'coïteras',
    'coïterez',
    'coïtions',
    'cokéfiai',
    'cokéfias',
    'cokéfiât',
    'cokéfiée',
    'cokéfier',
    'cokéfies',
    'cokéfiés',
    'cokéfiez',
    'cokeries',
    'colature',
    'colbacks',
    'colcotar',
    'coléreux',
    'colibris',
    'colineau',
    'colinots',
    'coliques',
    'collages',
    'collâmes',
    'collante',
    'collants',
    'collasse',
    'collâtes',
    'collecta',
    'collecte',
    'collecté',
    'collèges',
    'collègue',
    'collerai',
    'colleras',
    'collerez',
    'colletai',
    'colletas',
    'colletât',
    'colletée',
    'colleter',
    'colletés',
    'colletez',
    'collette',
    'colleurs',
    'colleuse',
    'colliers',
    'colligea',
    'colligée',
    'colliger',
    'colliges',
    'colligés',
    'colligez',
    'collines',
    'collions',
    'colloïde',
    'colloqua',
    'colloque',
    'colloqué',
    'collyres',
    'colmatai',
    'colmatas',
    'colmatât',
    'colmatée',
    'colmater',
    'colmates',
    'colmatés',
    'colmatez',
    'colocase',
    'colombes',
    'colombin',
    'colonage',
    'colonats',
    'colonels',
    'colonial',
    'colonies',
    'colonisa',
    'colonise',
    'colonisé',
    'colonnes',
    'colorais',
    'colorait',
    'colorant',
    'colorées',
    'colorent',
    'colorera',
    'coloriai',
    'colorias',
    'coloriât',
    'coloriée',
    'colorier',
    'colories',
    'coloriés',
    'coloriez',
    'colorons',
    'colossal',
    'colosses',
    'colporta',
    'colporte',
    'colporté',
    'coltinai',
    'coltinas',
    'coltinât',
    'coltinée',
    'coltiner',
    'coltines',
    'coltinés',
    'coltinez',
    'colverts',
    'comateux',
    'combatif',
    'combatte',
    'combattu',
    'combinai',
    'combinas',
    'combinat',
    'combinât',
    'combinée',
    'combiner',
    'combines',
    'combinés',
    'combinez',
    'comblais',
    'comblait',
    'comblant',
    'comblées',
    'comblent',
    'comblera',
    'combliez',
    'comblons',
    'comédien',
    'comédies',
    'comédons',
    'comiques',
    'commanda',
    'commande',
    'commandé',
    'commando',
    'commença',
    'commence',
    'commencé',
    'commenta',
    'commente',
    'commenté',
    'commérai',
    'comméras',
    'commérât',
    'commerça',
    'commerce',
    'commercé',
    'commérer',
    'commères',
    'commérez',
    'commette',
    'commîmes',
    'commises',
    'commisse',
    'commîtes',
    'commodes',
    'commuais',
    'commuait',
    'commuant',
    'commuées',
    'commuent',
    'commuera',
    'commuiez',
    'communal',
    'communes',
    'communia',
    'communie',
    'communié',
    'commuons',
    'commutai',
    'commutas',
    'commutât',
    'commutée',
    'commuter',
    'commutes',
    'commutés',
    'commutez',
    'compacte',
    'compacts',
    'compagne',
    'comparai',
    'comparas',
    'comparât',
    'comparée',
    'comparer',
    'compares',
    'comparés',
    'comparez',
    'comparse',
    'comparue',
    'comparus',
    'comparut',
    'comparût',
    'compassa',
    'compasse',
    'compassé',
    'compatir',
    'compatis',
    'compatit',
    'compatît',
    'compensa',
    'compense',
    'compensé',
    'compères',
    'compétai',
    'compétas',
    'compétât',
    'compéter',
    'compètes',
    'compétez',
    'compilai',
    'compilas',
    'compilât',
    'compilée',
    'compiler',
    'compiles',
    'compilés',
    'compilez',
    'compissa',
    'compisse',
    'compissé',
    'complais',
    'complaît',
    'compléta',
    'complété',
    'complète',
    'complets',
    'complexa',
    'complexe',
    'complexé',
    'complice',
    'complies',
    'complota',
    'complote',
    'comploté',
    'complots',
    'componée',
    'componés',
    'comporta',
    'comporte',
    'comporté',
    'composai',
    'composas',
    'composât',
    'composée',
    'composer',
    'composes',
    'composés',
    'composez',
    'composta',
    'composte',
    'composté',
    'composts',
    'compotes',
    'compound',
    'comprend',
    'comprima',
    'comprime',
    'comprimé',
    'comprise',
    'comptage',
    'comptais',
    'comptait',
    'comptant',
    'comptées',
    'comptent',
    'comptera',
    'compteur',
    'comptiez',
    'comptine',
    'comptoir',
    'comptons',
    'compulsa',
    'compulse',
    'compulsé',
    'computai',
    'computas',
    'computât',
    'computée',
    'computer',
    'computes',
    'computés',
    'computez',
    'comtales',
    'comtesse',
    'conardes',
    'conasses',
    'conatifs',
    'conation',
    'conative',
    'concassa',
    'concasse',
    'concassé',
    'concaves',
    'concédai',
    'concédas',
    'concédât',
    'concédée',
    'concéder',
    'concédés',
    'concèdes',
    'concédez',
    'concepts',
    'concerna',
    'concerne',
    'concerné',
    'concerta',
    'concerte',
    'concerté',
    'concerto',
    'concerts',
    'concetti',
    'concevez',
    'concevra',
    'conciles',
    'concilia',
    'concilie',
    'concilié',
    'concises',
    'conclave',
    'conclues',
    'concluez',
    'conclura',
    'conclure',
    'concocta',
    'concocte',
    'concocté',
    'conçoive',
    'concorda',
    'concorde',
    'concordé',
    'concoure',
    'concours',
    'concourt',
    'concouru',
    'concréta',
    'concrété',
    'concrète',
    'concrets',
    'concubin',
    'conçûmes',
    'conçusse',
    'conçûtes',
    'condamna',
    'condamne',
    'condamné',
    'condensa',
    'condense',
    'condensé',
    'conduira',
    'conduire',
    'conduise',
    'conduite',
    'conduits',
    'condyles',
    'conférai',
    'conféras',
    'conférât',
    'conférée',
    'conférer',
    'conférés',
    'confères',
    'conférez',
    'conferve',
    'confessa',
    'confesse',
    'confessé',
    'confetti',
    'confiais',
    'confiait',
    'confiant',
    'confiées',
    'confient',
    'confiera',
    'confiiez',
    'confîmes',
    'confinai',
    'confinas',
    'confinât',
    'confinée',
    'confiner',
    'confines',
    'confinés',
    'confinez',
    'confions',
    'confirai',
    'confiras',
    'confirez',
    'confirma',
    'confirme',
    'confirmé',
    'confises',
    'confisez',
    'confisse',
    'confites',
    'confîtes',
    'conflits',
    'confluai',
    'confluas',
    'confluât',
    'confluer',
    'conflues',
    'confluez',
    'confonde',
    'confonds',
    'confondu',
    'conforma',
    'conforme',
    'conformé',
    'conforta',
    'conforte',
    'conforté',
    'conforts',
    'confrère',
    'confuses',
    'congayes',
    'congédia',
    'congédie',
    'congédié',
    'congelai',
    'congelas',
    'congelât',
    'congelée',
    'congeler',
    'congelés',
    'congèles',
    'congelez',
    'congères',
    'congréai',
    'congréas',
    'congréât',
    'congréée',
    'congréer',
    'congrées',
    'congréés',
    'congréez',
    'congrues',
    'conicine',
    'conicité',
    'conidies',
    'conifère',
    'coniques',
    'cônirais',
    'cônirait',
    'cônirent',
    'côniriez',
    'cônirons',
    'côniront',
    'cônisses',
    'cônissez',
    'conjoins',
    'conjoint',
    'conjugal',
    'conjugua',
    'conjugue',
    'conjugué',
    'conjungo',
    'conjurai',
    'conjuras',
    'conjurât',
    'conjurée',
    'conjurer',
    'conjures',
    'conjurés',
    'conjurez',
    'connarde',
    'connards',
    'connasse',
    'connecta',
    'connecte',
    'connecté',
    'connerie',
    'connexes',
    'connotai',
    'connotas',
    'connotât',
    'connotée',
    'connoter',
    'connotes',
    'connotés',
    'connotez',
    'connûmes',
    'connusse',
    'connûtes',
    'conobrai',
    'conobras',
    'conobrât',
    'conobrée',
    'conobrer',
    'conobres',
    'conobrés',
    'conobrez',
    'conoïdes',
    'conopées',
    'conquéru',
    'conquête',
    'conquêts',
    'conquise',
    'consacra',
    'consacre',
    'consacré',
    'conscrit',
    'conseils',
    'consente',
    'consenti',
    'conserva',
    'conserve',
    'conservé',
    'consigna',
    'consigne',
    'consigné',
    'consista',
    'consiste',
    'consisté',
    'consoeur',
    'consolai',
    'consolas',
    'consolât',
    'consolée',
    'consoler',
    'consoles',
    'consolés',
    'consolez',
    'consomma',
    'consomme',
    'consommé',
    'consonai',
    'consonas',
    'consonât',
    'consoner',
    'consones',
    'consonez',
    'consonne',
    'consorts',
    'conspira',
    'conspire',
    'conspiré',
    'conspuai',
    'conspuas',
    'conspuât',
    'conspuée',
    'conspuer',
    'conspues',
    'conspués',
    'conspuez',
    'constant',
    'constata',
    'constate',
    'constaté',
    'constats',
    'constipa',
    'constipe',
    'constipé',
    'consulat',
    'consulta',
    'consulte',
    'consulté',
    'consumai',
    'consumas',
    'consumât',
    'consumée',
    'consumer',
    'consumes',
    'consumés',
    'consumez',
    'contacta',
    'contacte',
    'contacté',
    'contacts',
    'contages',
    'contâmes',
    'contasse',
    'contâtes',
    'contenez',
    'contenir',
    'contenta',
    'contente',
    'contenté',
    'contents',
    'contenue',
    'contenus',
    'conterai',
    'conteras',
    'conterez',
    'contesta',
    'conteste',
    'contesté',
    'conteurs',
    'conteuse',
    'contexte',
    'contiens',
    'contient',
    'contiguë',
    'contigus',
    'continua',
    'continue',
    'continué',
    'continuo',
    'continus',
    'contions',
    'contours',
    'contrais',
    'contrait',
    'contrant',
    'contrats',
    'contrées',
    'contrent',
    'contrera',
    'contriez',
    'contrite',
    'contrits',
    'contrôla',
    'contrôle',
    'contrôlé',
    'controns',
    'contumax',
    'convainc',
    'convenez',
    'convenir',
    'convenue',
    'convenus',
    'converge',
    'convergé',
    'conversa',
    'converse',
    'conversé',
    'converti',
    'convexes',
    'conviais',
    'conviait',
    'conviant',
    'conviées',
    'conviens',
    'convient',
    'conviera',
    'conviiez',
    'convions',
    'convives',
    'convoies',
    'convoita',
    'convoite',
    'convoité',
    'convolai',
    'convolas',
    'convolât',
    'convoler',
    'convoles',
    'convolez',
    'convoqua',
    'convoque',
    'convoqué',
    'convoyai',
    'convoyas',
    'convoyât',
    'convoyée',
    'convoyer',
    'convoyés',
    'convoyez',
    'convulsa',
    'convulse',
    'convulsé',
    'coopérai',
    'coopéras',
    'coopérât',
    'coopérer',
    'coopères',
    'coopérez',
    'cooptais',
    'cooptait',
    'cooptant',
    'cooptées',
    'cooptent',
    'cooptera',
    'cooptiez',
    'cooptons',
    'copaïers',
    'copiages',
    'copiâmes',
    'copiasse',
    'copiâtes',
    'copierai',
    'copieras',
    'copierez',
    'copieurs',
    'copieuse',
    'copiions',
    'copilote',
    'copinage',
    'copinais',
    'copinait',
    'copinant',
    'copinent',
    'copinera',
    'copiniez',
    'copinons',
    'copistes',
    'copulais',
    'copulait',
    'copulant',
    'copulent',
    'copulera',
    'copuliez',
    'copulons',
    'coquâmes',
    'coquards',
    'coquarts',
    'coquasse',
    'coquâtes',
    'coquelet',
    'coquerai',
    'coqueras',
    'coquerez',
    'coquetai',
    'coquetas',
    'coquetât',
    'coqueter',
    'coquetez',
    'coquette',
    'coquilla',
    'coquille',
    'coquillé',
    'coquines',
    'coquions',
    'corbeaux',
    'cordages',
    'cordâmes',
    'cordasse',
    'cordâtes',
    'cordeaux',
    'cordelai',
    'cordelas',
    'cordelât',
    'cordelée',
    'cordeler',
    'cordelés',
    'cordelez',
    'cordelle',
    'corderai',
    'corderas',
    'corderez',
    'cordiale',
    'cordiaux',
    'cordiers',
    'cordions',
    'cordonna',
    'cordonne',
    'cordonné',
    'coréenne',
    'coriaces',
    'coricide',
    'corindon',
    'cormiers',
    'cormoran',
    'cornâmes',
    'cornards',
    'cornasse',
    'cornâtes',
    'cornéens',
    'cornerai',
    'corneras',
    'cornerez',
    'cornette',
    'corniaud',
    'corniche',
    'cornière',
    'corniers',
    'cornions',
    'corniste',
    'corolles',
    'coroners',
    'corporal',
    'corporel',
    'correcte',
    'corrects',
    'corrélai',
    'corrélas',
    'corrélât',
    'corrélée',
    'corréler',
    'corrélés',
    'corrèles',
    'corrélez',
    'corridas',
    'corridor',
    'corrigea',
    'corrigée',
    'corriger',
    'corriges',
    'corrigés',
    'corrigez',
    'corrodai',
    'corrodas',
    'corrodât',
    'corrodée',
    'corroder',
    'corrodes',
    'corrodés',
    'corrodez',
    'corroies',
    'corrompe',
    'corromps',
    'corrompt',
    'corrompu',
    'corrosif',
    'corroyai',
    'corroyas',
    'corroyât',
    'corroyée',
    'corroyer',
    'corroyés',
    'corroyez',
    'corsages',
    'corsaire',
    'corsâmes',
    'corsasse',
    'corsâtes',
    'corselet',
    'corserai',
    'corseras',
    'corserez',
    'corsetai',
    'corsetas',
    'corsetât',
    'corsetée',
    'corseter',
    'corsetés',
    'corsètes',
    'corsetez',
    'corsions',
    'cortèges',
    'cortical',
    'corvette',
    'coryphée',
    'cosaques',
    'cosmique',
    'cossâmes',
    'cossarde',
    'cossards',
    'cossasse',
    'cossâtes',
    'cosserai',
    'cosseras',
    'cosserez',
    'cossions',
    'costales',
    'costards',
    'costaude',
    'costauds',
    'costumai',
    'costumas',
    'costumât',
    'costumée',
    'costumer',
    'costumes',
    'costumés',
    'costumez',
    'cotaient',
    'cotasses',
    'cotation',
    'côtelées',
    'coterais',
    'coterait',
    'cotèrent',
    'coteries',
    'coteriez',
    'coterons',
    'coteront',
    'cothurne',
    'côtières',
    'cotillon',
    'cotirais',
    'cotirait',
    'cotirent',
    'cotiriez',
    'cotirons',
    'cotiront',
    'cotisais',
    'cotisait',
    'cotisant',
    'cotisées',
    'cotisent',
    'cotisera',
    'cotisiez',
    'cotisons',
    'cotisses',
    'cotissez',
    'côtoient',
    'côtoiera',
    'cotonnai',
    'cotonnas',
    'cotonnât',
    'cotonnée',
    'cotonner',
    'cotonnes',
    'cotonnés',
    'cotonnez',
    'côtoyais',
    'côtoyait',
    'côtoyant',
    'côtoyées',
    'côtoyiez',
    'côtoyons',
    'cottages',
    'couardes',
    'couchage',
    'couchais',
    'couchait',
    'couchant',
    'couchées',
    'couchent',
    'couchera',
    'coucheur',
    'couchiez',
    'couchons',
    'coudâmes',
    'coudasse',
    'coudâtes',
    'couderai',
    'couderas',
    'couderez',
    'coudions',
    'coudoies',
    'coudoyai',
    'coudoyas',
    'coudoyât',
    'coudoyée',
    'coudoyer',
    'coudoyés',
    'coudoyez',
    'coudraie',
    'coudrais',
    'coudrait',
    'coudrier',
    'coudriez',
    'coudrons',
    'coudront',
    'couennes',
    'couettes',
    'couffins',
    'coufique',
    'cougouar',
    'couguars',
    'couilles',
    'couillon',
    'couinais',
    'couinait',
    'couinant',
    'couinent',
    'couinera',
    'couiniez',
    'couinons',
    'coulages',
    'coulâmes',
    'coulante',
    'coulants',
    'coulasse',
    'coulâtes',
    'coulerai',
    'couleras',
    'coulerez',
    'couleurs',
    'coulions',
    'coulissa',
    'coulisse',
    'coulissé',
    'couloirs',
    'coulombs',
    'coulures',
    'coupable',
    'coupages',
    'coupâmes',
    'coupante',
    'coupants',
    'coupasse',
    'coupâtes',
    'coupella',
    'coupelle',
    'coupellé',
    'couperai',
    'couperas',
    'couperet',
    'couperez',
    'coupions',
    'couplage',
    'couplais',
    'couplait',
    'couplant',
    'couplées',
    'couplent',
    'couplera',
    'couplets',
    'coupleur',
    'coupliez',
    'couplons',
    'coupoles',
    'coupures',
    'courages',
    'courante',
    'courants',
    'courbais',
    'courbait',
    'courbant',
    'courbatu',
    'courbées',
    'courbent',
    'courbera',
    'courbiez',
    'courbons',
    'courbure',
    'courette',
    'coureurs',
    'coureuse',
    'courions',
    'courlieu',
    'couronna',
    'couronne',
    'couronné',
    'courrais',
    'courrait',
    'courrier',
    'courriez',
    'courroie',
    'courrons',
    'courront',
    'courroux',
    'coursais',
    'coursait',
    'coursant',
    'coursées',
    'coursent',
    'coursera',
    'coursier',
    'coursiez',
    'coursive',
    'coursons',
    'courtage',
    'courtaud',
    'courtier',
    'courtine',
    'courtisa',
    'courtise',
    'courtisé',
    'courtois',
    'courûmes',
    'courusse',
    'courûtes',
    'couscous',
    'couseurs',
    'couseuse',
    'cousîmes',
    'cousinai',
    'cousinas',
    'cousinât',
    'cousinée',
    'cousiner',
    'cousines',
    'cousinés',
    'cousinez',
    'cousions',
    'cousisse',
    'cousîtes',
    'coussins',
    'coûtâmes',
    'coûtante',
    'coûtants',
    'coûtasse',
    'coûtâtes',
    'couteaux',
    'coutelas',
    'coûterai',
    'coûteras',
    'coûterez',
    'coûteuse',
    'coûtions',
    'coutumes',
    'couturai',
    'couturas',
    'couturât',
    'couturée',
    'couturer',
    'coutures',
    'couturés',
    'couturez',
    'couvains',
    'couvâmes',
    'couvasse',
    'couvâtes',
    'couvents',
    'couverai',
    'couveras',
    'couverez',
    'couverte',
    'couverts',
    'couveuse',
    'couvions',
    'couvrais',
    'couvrait',
    'couvrant',
    'couvrent',
    'couvreur',
    'couvriez',
    'couvrira',
    'couvrons',
    'couvrues',
    'coxalgie',
    'crabiers',
    'crachais',
    'crachait',
    'crachant',
    'crachats',
    'crachées',
    'crachent',
    'crachera',
    'cracheur',
    'crachiez',
    'crachina',
    'crachine',
    'crachiné',
    'crachins',
    'crachoir',
    'crachons',
    'crachota',
    'crachote',
    'crachoté',
    'crackers',
    'cracking',
    'craignes',
    'craignez',
    'craignis',
    'craignit',
    'craignît',
    'craillai',
    'craillas',
    'craillât',
    'crailler',
    'crailles',
    'craillez',
    'craindra',
    'craindre',
    'craintes',
    'craintif',
    'cramâmes',
    'cramasse',
    'cramâtes',
    'cramerai',
    'crameras',
    'cramerez',
    'cramions',
    'cramique',
    'cramoisi',
    'crampons',
    'crampsai',
    'crampsas',
    'crampsât',
    'crampser',
    'crampses',
    'crampsez',
    'cramsais',
    'cramsait',
    'cramsant',
    'cramsent',
    'cramsera',
    'cramsiez',
    'cramsons',
    'cranâmes',
    'crânâmes',
    'cranasse',
    'crânasse',
    'cranâtes',
    'crânâtes',
    'cranerai',
    'crânerai',
    'craneras',
    'crâneras',
    'cranerez',
    'crânerez',
    'crânerie',
    'crâneurs',
    'crâneuse',
    'crâniens',
    'cranions',
    'crânions',
    'crantais',
    'crantait',
    'crantant',
    'crantées',
    'crantent',
    'crantera',
    'crantiez',
    'crantons',
    'crapauds',
    'crapaüta',
    'crapaüte',
    'crapaüté',
    'crapulai',
    'crapulas',
    'crapulât',
    'crapuler',
    'crapules',
    'crapulez',
    'craquage',
    'craquais',
    'craquait',
    'craquant',
    'craquées',
    'craquela',
    'craquelé',
    'craquent',
    'craquera',
    'craqueta',
    'craqueté',
    'craquiez',
    'craquons',
    'crassais',
    'crassait',
    'crassane',
    'crassant',
    'crassées',
    'crassent',
    'crassera',
    'crasseux',
    'crassier',
    'crassiez',
    'crassons',
    'cratères',
    'cravacha',
    'cravache',
    'cravaché',
    'cravatai',
    'cravatas',
    'cravatât',
    'cravatée',
    'cravater',
    'cravates',
    'cravatés',
    'cravatez',
    'crawlais',
    'crawlait',
    'crawlant',
    'crawlées',
    'crawlent',
    'crawlera',
    'crawleur',
    'crawliez',
    'crawlons',
    'crayeuse',
    'crayonna',
    'crayonne',
    'crayonné',
    'créaient',
    'créances',
    'créasses',
    'créateur',
    'créatifs',
    'créatine',
    'création',
    'créative',
    'créature',
    'crécelle',
    'créchais',
    'créchait',
    'créchant',
    'crèchent',
    'créchera',
    'créchiez',
    'créchons',
    'crédence',
    'crédible',
    'créditai',
    'créditas',
    'créditât',
    'créditée',
    'créditer',
    'crédites',
    'crédités',
    'créditez',
    'crédules',
    'créerais',
    'créerait',
    'créèrent',
    'créeriez',
    'créerons',
    'créeront',
    'crémâmes',
    'crémante',
    'crémants',
    'crémasse',
    'crémâtes',
    'crémerai',
    'crémeras',
    'crémerez',
    'crémerie',
    'crémeuse',
    'crémière',
    'crémiers',
    'crémions',
    'crénâmes',
    'crénasse',
    'crénâtes',
    'créneaux',
    'crénelai',
    'crénelas',
    'crénelât',
    'crénelée',
    'créneler',
    'crénelés',
    'crénelez',
    'crénelle',
    'crénerai',
    'créneras',
    'crénerez',
    'crénions',
    'créolisa',
    'créolise',
    'créolisé',
    'créosota',
    'créosote',
    'créosoté',
    'crêpages',
    'crêpâmes',
    'crêpasse',
    'crêpâtes',
    'crêperai',
    'crêperas',
    'crêperez',
    'crêperie',
    'crépîmes',
    'crépines',
    'crêpions',
    'crépirai',
    'crépiras',
    'crépirez',
    'crépisse',
    'crépitai',
    'crépitas',
    'crépitât',
    'crépiter',
    'crépites',
    'crépîtes',
    'crépitez',
    'cressons',
    'crétacée',
    'crétacés',
    'crétines',
    'crétoise',
    'cretonne',
    'creusage',
    'creusais',
    'creusait',
    'creusant',
    'creusées',
    'creusent',
    'creusera',
    'creusets',
    'creusiez',
    'creusons',
    'crevâmes',
    'crevassa',
    'crevasse',
    'crevassé',
    'crevâtes',
    'crèverai',
    'crèveras',
    'crèverez',
    'crevette',
    'crevions',
    'criaient',
    'criailla',
    'criaille',
    'criaillé',
    'criantes',
    'criardes',
    'criasses',
    'criblage',
    'criblais',
    'criblait',
    'criblant',
    'criblées',
    'criblent',
    'criblera',
    'cribliez',
    'criblons',
    'crickets',
    'crierais',
    'crierait',
    'crièrent',
    'crieriez',
    'crierons',
    'crieront',
    'crieuses',
    'criminel',
    'crinière',
    'criquets',
    'crispais',
    'crispait',
    'crispant',
    'crispées',
    'crispent',
    'crispera',
    'crispiez',
    'crispons',
    'crissais',
    'crissait',
    'crissant',
    'crissent',
    'crissera',
    'crissiez',
    'crissons',
    'cristaux',
    'critères',
    'critiqua',
    'critique',
    'critiqué',
    'croassai',
    'croassas',
    'croassât',
    'croasser',
    'croasses',
    'croassez',
    'crochais',
    'crochait',
    'crochant',
    'crochées',
    'crochent',
    'crochera',
    'crocheta',
    'crocheté',
    'crochète',
    'crochets',
    'crochiez',
    'crochons',
    'crochues',
    'croirais',
    'croirait',
    'croiriez',
    'croirons',
    'croiront',
    'croisade',
    'croisais',
    'croisait',
    'croisant',
    'croisées',
    'croisent',
    'croisera',
    'croiseur',
    'croisiez',
    'croisons',
    'croisses',
    'croissez',
    'croîtrai',
    'croîtras',
    'croîtrez',
    'croquais',
    'croquait',
    'croquant',
    'croquées',
    'croquent',
    'croquera',
    'croquets',
    'croquiez',
    'croquons',
    'crossais',
    'crossait',
    'crossant',
    'crossées',
    'crossent',
    'crossera',
    'crossiez',
    'crossons',
    'crotales',
    'crottais',
    'crottait',
    'crottant',
    'crottées',
    'crottent',
    'crottera',
    'crottiez',
    'crottins',
    'crottons',
    'crouille',
    'croulais',
    'croulait',
    'croulant',
    'croulent',
    'croulera',
    'crouliez',
    'croulons',
    'croupier',
    'croupion',
    'croupira',
    'croûtais',
    'croûtait',
    'croûtant',
    'croûtées',
    'croûtent',
    'croûtera',
    'croûtiez',
    'croûtons',
    'croyable',
    'croyance',
    'croyante',
    'croyants',
    'croyions',
    'cruautés',
    'cruchons',
    'cruciale',
    'cruciaux',
    'crucifia',
    'crucifie',
    'crucifié',
    'crucifix',
    'crudités',
    'cruelles',
    'crussent',
    'crûssent',
    'crussiez',
    'crûssiez',
    'crustacé',
    'cruzeiro',
    'cryptais',
    'cryptait',
    'cryptant',
    'cryptées',
    'cryptent',
    'cryptera',
    'cryptiez',
    'cryptons',
    'cubaient',
    'cubaines',
    'cubasses',
    'cuberais',
    'cuberait',
    'cubèrent',
    'cuberiez',
    'cuberons',
    'cuberont',
    'cubiques',
    'cubismes',
    'cubistes',
    'cueilles',
    'cueillez',
    'cueillie',
    'cueillir',
    'cueillis',
    'cueillit',
    'cueillît',
    'cuillère',
    'cuillers',
    'cuirassa',
    'cuirasse',
    'cuirassé',
    'cuirions',
    'cuisante',
    'cuisants',
    'cuisîmes',
    'cuisinai',
    'cuisinas',
    'cuisinât',
    'cuisinée',
    'cuisiner',
    'cuisines',
    'cuisinés',
    'cuisinez',
    'cuisions',
    'cuisisse',
    'cuisîtes',
    'cuissard',
    'cuisseau',
    'cuissons',
    'cuissots',
    'cuistres',
    'cuitâmes',
    'cuitasse',
    'cuitâtes',
    'cuiterai',
    'cuiteras',
    'cuiterez',
    'cuitions',
    'cuivrais',
    'cuivrait',
    'cuivrant',
    'cuivrées',
    'cuivrent',
    'cuivrera',
    'cuivreux',
    'cuivriez',
    'cuivrons',
    'culaient',
    'culasses',
    'culbutai',
    'culbutas',
    'culbutât',
    'culbutée',
    'culbuter',
    'culbutes',
    'culbutés',
    'culbutez',
    'culerais',
    'culerait',
    'culèrent',
    'culeriez',
    'culerons',
    'culeront',
    'culminai',
    'culminas',
    'culminât',
    'culminer',
    'culmines',
    'culminez',
    'culottai',
    'culottas',
    'culottât',
    'culottée',
    'culotter',
    'culottes',
    'culottés',
    'culottez',
    'cultisme',
    'cultivai',
    'cultivas',
    'cultivât',
    'cultivée',
    'cultiver',
    'cultives',
    'cultivés',
    'cultivez',
    'cultuels',
    'cultural',
    'culturel',
    'cultures',
    'cumulais',
    'cumulait',
    'cumulant',
    'cumulard',
    'cumulées',
    'cumulent',
    'cumulera',
    'cumuliez',
    'cumulons',
    'cupidité',
    'cuprique',
    'cuprites',
    'curables',
    'curaient',
    'curasses',
    'curateur',
    'curatifs',
    'curative',
    'curcumas',
    'curerais',
    'curerait',
    'curèrent',
    'cureriez',
    'curerons',
    'cureront',
    'curetage',
    'curetais',
    'curetait',
    'curetant',
    'curetées',
    'curetiez',
    'curetons',
    'curettes',
    'curiales',
    'curieuse',
    'curistes',
    'curseurs',
    'cursives',
    'cuscutes',
    'custodes',
    'cutanées',
    'cuticule',
    'cuvaient',
    'cuvaison',
    'cuvasses',
    'cuvelage',
    'cuvelais',
    'cuvelait',
    'cuvelant',
    'cuvelées',
    'cuveliez',
    'cuvelles',
    'cuvelons',
    'cuverais',
    'cuverait',
    'cuvèrent',
    'cuveriez',
    'cuverons',
    'cuveront',
    'cuvettes',
    'cyanosai',
    'cyanosas',
    'cyanosât',
    'cyanosée',
    'cyanoser',
    'cyanoses',
    'cyanosés',
    'cyanosez',
    'cyanurai',
    'cyanuras',
    'cyanurât',
    'cyanurée',
    'cyanurer',
    'cyanures',
    'cyanurés',
    'cyanurez',
    'cyclable',
    'cyclamen',
    'cyclanes',
    'cyclique',
    'cyclisai',
    'cyclisas',
    'cyclisât',
    'cyclisée',
    'cycliser',
    'cyclises',
    'cyclisés',
    'cyclisez',
    'cyclisme',
    'cycliste',
    'cycloïde',
    'cyclonal',
    'cyclones',
    'cyclopes',
    'cylindra',
    'cylindre',
    'cylindré',
    'cymaises',
    'cymbales',
    'cymbalum',
    'cyniques',
    'cynismes',
    'cyphoses',
    'cyprière',
    'cypriote',
    'cystines',
    'cystique',
    'cystites',
    'cytolyse',
    'dactyles',
    'dactylos',
    'dadaïsme',
    'daguâmes',
    'daguasse',
    'daguâtes',
    'daguerai',
    'dagueras',
    'daguerez',
    'daguions',
    'dahoméen',
    'daignais',
    'daignait',
    'daignant',
    'daignées',
    'daignent',
    'daignera',
    'daigniez',
    'daignons',
    'dallages',
    'dallâmes',
    'dallasse',
    'dallâtes',
    'dallerai',
    'dalleras',
    'dallerez',
    'dalleurs',
    'dallions',
    'dalmates',
    'damaient',
    'damassai',
    'damassas',
    'damassât',
    'damassée',
    'damasser',
    'damasses',
    'damassés',
    'damassez',
    'damerais',
    'damerait',
    'damèrent',
    'dameriez',
    'damerons',
    'dameront',
    'damnable',
    'damnâmes',
    'damnasse',
    'damnâtes',
    'damnerai',
    'damneras',
    'damnerez',
    'damnions',
    'danaïdes',
    'dancings',
    'dandinai',
    'dandinas',
    'dandinât',
    'dandinée',
    'dandiner',
    'dandines',
    'dandinés',
    'dandinez',
    'dandysme',
    'danienne',
    'danoises',
    'dansable',
    'dansâmes',
    'dansante',
    'dansants',
    'dansasse',
    'dansâtes',
    'danserai',
    'danseras',
    'danserez',
    'danseurs',
    'danseuse',
    'dansions',
    'dansotta',
    'dansotte',
    'dansotté',
    'daphnies',
    'daraises',
    'darbouka',
    'dardâmes',
    'dardasse',
    'dardâtes',
    'darderai',
    'darderas',
    'darderez',
    'dardions',
    'darioles',
    'dariques',
    'dartreux',
    'dartrose',
    'dasyures',
    'datables',
    'dataient',
    'dataires',
    'datasses',
    'datation',
    'daterais',
    'daterait',
    'datèrent',
    'dateries',
    'dateriez',
    'daterons',
    'dateront',
    'dateuses',
    'dattiers',
    'daubâmes',
    'daubasse',
    'daubâtes',
    'dauberai',
    'dauberas',
    'dauberez',
    'daubeurs',
    'daubeuse',
    'daubière',
    'daubions',
    'dauphine',
    'dauphins',
    'daurades',
    'dazibaos',
    'déactiva',
    'déactive',
    'déactivé',
    'déambula',
    'déambule',
    'déambulé',
    'débâchai',
    'débâchas',
    'débâchât',
    'débâchée',
    'débâcher',
    'débâches',
    'débâchés',
    'débâchez',
    'débâclai',
    'débâclas',
    'débâclât',
    'débâcler',
    'débâcles',
    'débâclez',
    'déballai',
    'déballas',
    'déballât',
    'déballée',
    'déballer',
    'déballes',
    'déballés',
    'déballez',
    'débandai',
    'débandas',
    'débandât',
    'débandée',
    'débander',
    'débandes',
    'débandés',
    'débandez',
    'débardai',
    'débardas',
    'débardât',
    'débardée',
    'débarder',
    'débardes',
    'débardés',
    'débardez',
    'débarqua',
    'débarque',
    'débarqué',
    'débarrai',
    'débarras',
    'débarrât',
    'débarrée',
    'débarrer',
    'débarres',
    'débarrés',
    'débarrez',
    'débâtais',
    'débâtait',
    'débâtant',
    'débâtées',
    'débâtent',
    'débâtera',
    'débâties',
    'débâtiez',
    'débâtira',
    'débâtons',
    'débattes',
    'débattez',
    'débattis',
    'débattit',
    'débattît',
    'débattra',
    'débattre',
    'débattue',
    'débattus',
    'débaucha',
    'débauche',
    'débauché',
    'débectai',
    'débectas',
    'débectât',
    'débectée',
    'débecter',
    'débectes',
    'débectés',
    'débectez',
    'débilita',
    'débilite',
    'débilité',
    'débinais',
    'débinait',
    'débinant',
    'débinées',
    'débinent',
    'débinera',
    'débiniez',
    'débinons',
    'débitage',
    'débitais',
    'débitait',
    'débitant',
    'débitées',
    'débitent',
    'débitera',
    'débiteur',
    'débitiez',
    'débitons',
    'déblaies',
    'déblayai',
    'déblayas',
    'déblayât',
    'déblayée',
    'déblayer',
    'déblayes',
    'déblayés',
    'déblayez',
    'débleuie',
    'débleuir',
    'débleuis',
    'débleuit',
    'débleuît',
    'débloqua',
    'débloque',
    'débloqué',
    'débobina',
    'débobine',
    'débobiné',
    'déboguai',
    'déboguas',
    'déboguât',
    'déboguée',
    'déboguer',
    'débogues',
    'débogués',
    'déboguez',
    'déboires',
    'déboisai',
    'déboisas',
    'déboisât',
    'déboisée',
    'déboiser',
    'déboises',
    'déboisés',
    'déboisez',
    'déboîtai',
    'déboîtas',
    'déboîtât',
    'déboîtée',
    'déboîter',
    'déboîtes',
    'déboîtés',
    'déboîtez',
    'débondai',
    'débondas',
    'débondât',
    'débondée',
    'débonder',
    'débondes',
    'débondés',
    'débondez',
    'débordai',
    'débordas',
    'débordât',
    'débordée',
    'déborder',
    'débordes',
    'débordés',
    'débordez',
    'débottai',
    'débottas',
    'débottât',
    'débottée',
    'débotter',
    'débottes',
    'débottés',
    'débottez',
    'déboucha',
    'débouche',
    'débouché',
    'déboucla',
    'déboucle',
    'débouclé',
    'déboudai',
    'déboudas',
    'déboudât',
    'déboudée',
    'débouder',
    'déboudes',
    'déboudés',
    'déboudez',
    'déboulai',
    'déboulas',
    'déboulât',
    'déboulée',
    'débouler',
    'déboules',
    'déboulés',
    'déboulez',
    'débouqua',
    'débouque',
    'débouqué',
    'débourba',
    'débourbe',
    'débourbé',
    'débourra',
    'débourre',
    'débourré',
    'déboursa',
    'débourse',
    'déboursé',
    'déboutai',
    'déboutas',
    'déboutât',
    'déboutée',
    'débouter',
    'déboutes',
    'déboutés',
    'déboutez',
    'débraies',
    'débrayai',
    'débrayas',
    'débrayât',
    'débrayée',
    'débrayer',
    'débrayes',
    'débrayés',
    'débrayez',
    'débridai',
    'débridas',
    'débridât',
    'débridée',
    'débrider',
    'débrides',
    'débridés',
    'débridez',
    'débrocha',
    'débroche',
    'débroché',
    'débuchai',
    'débuchas',
    'débuchât',
    'débuchée',
    'débucher',
    'débuches',
    'débuchés',
    'débuchez',
    'débullai',
    'débullas',
    'débullât',
    'débullée',
    'débuller',
    'débulles',
    'débullés',
    'débullez',
    'débusqua',
    'débusque',
    'débusqué',
    'débutais',
    'débutait',
    'débutant',
    'débutées',
    'débutent',
    'débutera',
    'débutiez',
    'débutons',
    'décadent',
    'décaèdre',
    'décagone',
    'décaissa',
    'décaisse',
    'décaissé',
    'décalage',
    'décalais',
    'décalait',
    'décalant',
    'décalées',
    'décalent',
    'décalera',
    'décaliez',
    'décalons',
    'décalqua',
    'décalque',
    'décalqué',
    'décampai',
    'décampas',
    'décampât',
    'décamper',
    'décampes',
    'décampez',
    'décanale',
    'décanats',
    'décanaux',
    'décantai',
    'décantas',
    'décantât',
    'décantée',
    'décanter',
    'décantes',
    'décantés',
    'décantez',
    'décapage',
    'décapais',
    'décapait',
    'décapant',
    'décapées',
    'décapela',
    'décapelé',
    'décapent',
    'décapera',
    'décapeur',
    'décapiez',
    'décapita',
    'décapite',
    'décapité',
    'décapons',
    'décapota',
    'décapote',
    'décapoté',
    'décarrai',
    'décarras',
    'décarrât',
    'décarrer',
    'décarres',
    'décarrez',
    'décaties',
    'décatira',
    'décausai',
    'décausas',
    'décausât',
    'décausée',
    'décauser',
    'décauses',
    'décausés',
    'décausez',
    'décavées',
    'décédais',
    'décédait',
    'décédant',
    'décédées',
    'décèdent',
    'décédera',
    'décédiez',
    'décédons',
    'décelais',
    'décelait',
    'décelant',
    'décelées',
    'décèlent',
    'décéléra',
    'décèlera',
    'décéléré',
    'décélère',
    'déceliez',
    'décelons',
    'décembre',
    'décemvir',
    'décences',
    'décennal',
    'décennie',
    'décentes',
    'décentra',
    'décentre',
    'décentré',
    'décercla',
    'décercle',
    'décerclé',
    'décernai',
    'décernas',
    'décernât',
    'décernée',
    'décerner',
    'décernes',
    'décernés',
    'décernez',
    'décessai',
    'décessas',
    'décessât',
    'décessée',
    'décesser',
    'décesses',
    'décessés',
    'décessez',
    'décevais',
    'décevait',
    'décevant',
    'déceviez',
    'décevoir',
    'décevons',
    'décevrai',
    'décevras',
    'décevrez',
    'déchaîna',
    'déchaîne',
    'déchaîné',
    'déchanta',
    'déchante',
    'déchanté',
    'déchapai',
    'déchapas',
    'déchapât',
    'déchapée',
    'déchaper',
    'déchapes',
    'déchapés',
    'déchapez',
    'décharge',
    'déchargé',
    'décharna',
    'décharne',
    'décharné',
    'déchauma',
    'déchaume',
    'déchaumé',
    'déchirai',
    'déchiras',
    'déchirât',
    'déchirée',
    'déchirer',
    'déchires',
    'déchirés',
    'déchirez',
    'déchoies',
    'déchoira',
    'déchoyez',
    'déchroma',
    'déchrome',
    'déchromé',
    'déchûmes',
    'déchusse',
    'déchûtes',
    'décibels',
    'décidais',
    'décidait',
    'décidant',
    'décidées',
    'décident',
    'décidera',
    'décideur',
    'décidiez',
    'décidons',
    'décilage',
    'décimais',
    'décimait',
    'décimale',
    'décimant',
    'décimaux',
    'décimées',
    'déciment',
    'décimera',
    'décimiez',
    'décimons',
    'décintra',
    'décintre',
    'décintré',
    'décisifs',
    'décision',
    'décisive',
    'déclamai',
    'déclamas',
    'déclamât',
    'déclamée',
    'déclamer',
    'déclames',
    'déclamés',
    'déclamez',
    'déclarai',
    'déclaras',
    'déclarât',
    'déclarée',
    'déclarer',
    'déclares',
    'déclarés',
    'déclarez',
    'déclassa',
    'déclasse',
    'déclassé',
    'déclinai',
    'déclinas',
    'déclinât',
    'déclinée',
    'décliner',
    'déclines',
    'déclinés',
    'déclinez',
    'déclives',
    'déclorai',
    'décloras',
    'déclorez',
    'décloses',
    'déclouai',
    'déclouas',
    'déclouât',
    'déclouée',
    'déclouer',
    'décloues',
    'décloués',
    'déclouez',
    'décochai',
    'décochas',
    'décochât',
    'décochée',
    'décocher',
    'décoches',
    'décochés',
    'décochez',
    'décodage',
    'décodais',
    'décodait',
    'décodant',
    'décodées',
    'décodent',
    'décodera',
    'décodeur',
    'décodiez',
    'décodons',
    'décoffra',
    'décoffre',
    'décoffré',
    'décoiffa',
    'décoiffe',
    'décoiffé',
    'décoinça',
    'décoince',
    'décoincé',
    'déçoives',
    'décoléra',
    'décoléré',
    'décolère',
    'décollai',
    'décollas',
    'décollât',
    'décollée',
    'décoller',
    'décolles',
    'décollés',
    'décollez',
    'décolora',
    'décolore',
    'décoloré',
    'décompta',
    'décompte',
    'décompté',
    'déconfis',
    'déconfit',
    'déconfît',
    'déconnai',
    'déconnas',
    'déconnât',
    'déconner',
    'déconnes',
    'déconnez',
    'décorais',
    'décorait',
    'décorant',
    'décordai',
    'décordas',
    'décordât',
    'décordée',
    'décorder',
    'décordes',
    'décordés',
    'décordez',
    'décorées',
    'décorent',
    'décorera',
    'décoriez',
    'décornai',
    'décornas',
    'décornât',
    'décornée',
    'décorner',
    'décornes',
    'décornés',
    'décornez',
    'décorons',
    'décorums',
    'découcha',
    'découche',
    'découché',
    'découdra',
    'découdre',
    'découlai',
    'découlas',
    'découlât',
    'découler',
    'découles',
    'découlez',
    'découpai',
    'découpas',
    'découpât',
    'découpée',
    'découper',
    'découpes',
    'découpés',
    'découpez',
    'découpla',
    'découple',
    'découplé',
    'découses',
    'décousez',
    'décousis',
    'décousit',
    'décousît',
    'décousue',
    'décousus',
    'découvre',
    'découvru',
    'décrassa',
    'décrasse',
    'décrassé',
    'décrêpai',
    'décrêpas',
    'décrêpât',
    'décrêpée',
    'décrêper',
    'décrêpes',
    'décrêpés',
    'décrêpez',
    'décrépie',
    'décrépir',
    'décrépis',
    'décrépit',
    'décrépît',
    'décrétai',
    'décrétas',
    'décrétât',
    'décrétée',
    'décréter',
    'décrétés',
    'décrètes',
    'décrétez',
    'décreusa',
    'décreuse',
    'décreusé',
    'décriais',
    'décriait',
    'décriant',
    'décriées',
    'décrient',
    'décriera',
    'décriiez',
    'décrions',
    'décrirai',
    'décriras',
    'décrirez',
    'décrispa',
    'décrispe',
    'décrispé',
    'décrites',
    'décrives',
    'décrivez',
    'décrivis',
    'décrivit',
    'décrivît',
    'décrocha',
    'décroche',
    'décroché',
    'décroisa',
    'décroise',
    'décroisé',
    'décrotta',
    'décrotte',
    'décrotté',
    'décroûta',
    'décroûte',
    'décroûté',
    'décruage',
    'décruais',
    'décruait',
    'décruant',
    'décruées',
    'décruent',
    'décruera',
    'décruiez',
    'décrûmes',
    'décruons',
    'décrusai',
    'décrusas',
    'décrusât',
    'décrusée',
    'décruser',
    'décruses',
    'décrusés',
    'décrusez',
    'décrusse',
    'décrûtes',
    'décrypta',
    'décrypte',
    'décrypté',
    'décuivra',
    'décuivre',
    'décuivré',
    'décuplai',
    'décuplas',
    'décuplât',
    'décuplée',
    'décupler',
    'décuples',
    'décuplés',
    'décuplez',
    'déçurent',
    'décurion',
    'décussée',
    'décussés',
    'déçusses',
    'décuvage',
    'décuvais',
    'décuvait',
    'décuvant',
    'décuvées',
    'décuvent',
    'décuvera',
    'décuviez',
    'décuvons',
    'dédaigna',
    'dédaigne',
    'dédaigné',
    'dédaléen',
    'dédiâmes',
    'dédiasse',
    'dédiâtes',
    'dédicaça',
    'dédicace',
    'dédicacé',
    'dédierai',
    'dédieras',
    'dédierez',
    'dédiions',
    'dédirais',
    'dédirait',
    'dédirent',
    'dédiriez',
    'dédirons',
    'dédiront',
    'dédisais',
    'dédisait',
    'dédisant',
    'dédisent',
    'dédisiez',
    'dédisons',
    'dédisses',
    'dédorage',
    'dédorais',
    'dédorait',
    'dédorant',
    'dédorées',
    'dédorent',
    'dédorera',
    'dédoriez',
    'dédorons',
    'dédouana',
    'dédouane',
    'dédouané',
    'dédoubla',
    'dédouble',
    'dédoublé',
    'déductif',
    'déduirai',
    'déduiras',
    'déduirez',
    'déduises',
    'déduisez',
    'déduisis',
    'déduisit',
    'déduisît',
    'déduites',
    'défaille',
    'défailli',
    'défaites',
    'défalqua',
    'défalque',
    'défalqué',
    'défardai',
    'défardas',
    'défardât',
    'défardée',
    'défarder',
    'défardes',
    'défardés',
    'défardez',
    'défasses',
    'défaussa',
    'défausse',
    'défaussé',
    'défaveur',
    'défectif',
    'défendes',
    'défendez',
    'défendis',
    'défendit',
    'défendît',
    'défendra',
    'défendre',
    'défendue',
    'défendus',
    'défenses',
    'défensif',
    'déféquai',
    'déféquas',
    'déféquât',
    'déféquée',
    'déféquer',
    'déféqués',
    'défèques',
    'déféquez',
    'déferais',
    'déférais',
    'déferait',
    'déférait',
    'déférant',
    'déférées',
    'déférent',
    'défèrent',
    'déférera',
    'déferiez',
    'défériez',
    'déferlai',
    'déferlas',
    'déferlât',
    'déferlée',
    'déferler',
    'déferles',
    'déferlés',
    'déferlez',
    'déferons',
    'déférons',
    'déferont',
    'déferrai',
    'déferras',
    'déferrât',
    'déferrée',
    'déferrer',
    'déferres',
    'déferrés',
    'déferrez',
    'défeutra',
    'défeutre',
    'défeutré',
    'défiâmes',
    'défiance',
    'défiante',
    'défiants',
    'défiasse',
    'défiâtes',
    'défibrai',
    'défibras',
    'défibrât',
    'défibrée',
    'défibrer',
    'défibres',
    'défibrés',
    'défibrez',
    'déficela',
    'déficelé',
    'défichai',
    'défichas',
    'défichât',
    'défichée',
    'déficher',
    'défiches',
    'défichés',
    'défichez',
    'déficits',
    'défierai',
    'défieras',
    'défierez',
    'défigeai',
    'défigeas',
    'défigeât',
    'défigées',
    'défigent',
    'défigera',
    'défigiez',
    'défigura',
    'défigure',
    'défiguré',
    'défiions',
    'défilade',
    'défilage',
    'défilais',
    'défilait',
    'défilant',
    'défilées',
    'défilent',
    'défilera',
    'défiliez',
    'défilons',
    'définies',
    'définira',
    'défirent',
    'défisses',
    'déflagra',
    'déflagre',
    'déflagré',
    'déflaqua',
    'déflaque',
    'déflaqué',
    'défleuri',
    'déflorai',
    'défloras',
    'déflorât',
    'déflorée',
    'déflorer',
    'déflores',
    'déflorés',
    'déflorez',
    'défluent',
    'défoliai',
    'défolias',
    'défoliât',
    'défoliée',
    'défolier',
    'défolies',
    'défoliés',
    'défoliez',
    'défonçai',
    'défonças',
    'défonçât',
    'défoncée',
    'défoncer',
    'défonces',
    'défoncés',
    'défoncez',
    'déforçai',
    'déforças',
    'déforçât',
    'déforcée',
    'déforcer',
    'déforces',
    'déforcés',
    'déforcez',
    'déformai',
    'déformas',
    'déformât',
    'déformée',
    'déformer',
    'déformes',
    'déformés',
    'déformez',
    'défoulai',
    'défoulas',
    'défoulât',
    'défoulée',
    'défouler',
    'défoules',
    'défoulés',
    'défoulez',
    'défourna',
    'défourne',
    'défourné',
    'défourra',
    'défourre',
    'défourré',
    'défraies',
    'défrayai',
    'défrayas',
    'défrayât',
    'défrayée',
    'défrayer',
    'défrayes',
    'défrayés',
    'défrayez',
    'défretta',
    'défrette',
    'défretté',
    'défricha',
    'défriche',
    'défriché',
    'défripai',
    'défripas',
    'défripât',
    'défripée',
    'défriper',
    'défripes',
    'défripés',
    'défripez',
    'défrisai',
    'défrisas',
    'défrisât',
    'défrisée',
    'défriser',
    'défrises',
    'défrisés',
    'défrisez',
    'défronça',
    'défronce',
    'défroncé',
    'défroqua',
    'défroque',
    'défroqué',
    'défruita',
    'défruite',
    'défruité',
    'défuntes',
    'dégageai',
    'dégageas',
    'dégageât',
    'dégagées',
    'dégagent',
    'dégagera',
    'dégagiez',
    'dégainai',
    'dégainas',
    'dégainât',
    'dégainée',
    'dégainer',
    'dégaines',
    'dégainés',
    'dégainez',
    'dégantai',
    'dégantas',
    'dégantât',
    'dégantée',
    'déganter',
    'dégantes',
    'dégantés',
    'dégantez',
    'dégarnie',
    'dégarnir',
    'dégarnis',
    'dégarnit',
    'dégarnît',
    'dégauchi',
    'dégazage',
    'dégazais',
    'dégazait',
    'dégazant',
    'dégazées',
    'dégazent',
    'dégazera',
    'dégaziez',
    'dégazons',
    'dégelais',
    'dégelait',
    'dégelant',
    'dégelées',
    'dégèlent',
    'dégèlera',
    'dégeliez',
    'dégelons',
    'dégénéra',
    'dégénéré',
    'dégénère',
    'dégermai',
    'dégermas',
    'dégermât',
    'dégermée',
    'dégermer',
    'dégermes',
    'dégermés',
    'dégermez',
    'dégîtais',
    'dégîtait',
    'dégîtant',
    'dégîtées',
    'dégîtent',
    'dégîtera',
    'dégîtiez',
    'dégîtons',
    'dégivrai',
    'dégivras',
    'dégivrât',
    'dégivrée',
    'dégivrer',
    'dégivres',
    'dégivrés',
    'dégivrez',
    'déglaçai',
    'déglaças',
    'déglaçât',
    'déglacée',
    'déglacer',
    'déglaces',
    'déglacés',
    'déglacez',
    'dégluais',
    'dégluait',
    'dégluant',
    'dégluées',
    'dégluent',
    'dégluera',
    'dégluiez',
    'dégluons',
    'déglutie',
    'déglutir',
    'déglutis',
    'déglutit',
    'déglutît',
    'dégoisai',
    'dégoisas',
    'dégoisât',
    'dégoisée',
    'dégoiser',
    'dégoises',
    'dégoisés',
    'dégoisez',
    'dégommai',
    'dégommas',
    'dégommât',
    'dégommée',
    'dégommer',
    'dégommes',
    'dégommés',
    'dégommez',
    'dégondai',
    'dégondas',
    'dégondât',
    'dégondée',
    'dégonder',
    'dégondes',
    'dégondés',
    'dégondez',
    'dégonfla',
    'dégonfle',
    'dégonflé',
    'dégorgea',
    'dégorgée',
    'dégorger',
    'dégorges',
    'dégorgés',
    'dégorgez',
    'dégotais',
    'dégotait',
    'dégotant',
    'dégotées',
    'dégotent',
    'dégotera',
    'dégotiez',
    'dégotons',
    'dégottai',
    'dégottas',
    'dégottât',
    'dégottée',
    'dégotter',
    'dégottes',
    'dégottés',
    'dégottez',
    'dégourdi',
    'dégoûtai',
    'dégoûtas',
    'dégoûtât',
    'dégoûtée',
    'dégoûter',
    'dégoûtes',
    'dégoûtés',
    'dégoûtez',
    'dégoutta',
    'dégoutte',
    'dégoutté',
    'dégradai',
    'dégradas',
    'dégradât',
    'dégradée',
    'dégrader',
    'dégrades',
    'dégradés',
    'dégradez',
    'dégrafai',
    'dégrafas',
    'dégrafât',
    'dégrafée',
    'dégrafer',
    'dégrafes',
    'dégrafés',
    'dégrafez',
    'dégréais',
    'dégréait',
    'dégréant',
    'dégréées',
    'dégréent',
    'dégréera',
    'dégréiez',
    'dégréons',
    'dégrevai',
    'dégrevas',
    'dégrevât',
    'dégrevée',
    'dégrever',
    'dégrevés',
    'dégrèves',
    'dégrevez',
    'dégriffé',
    'dégrippa',
    'dégrippe',
    'dégrippé',
    'dégrisai',
    'dégrisas',
    'dégrisât',
    'dégrisée',
    'dégriser',
    'dégrises',
    'dégrisés',
    'dégrisez',
    'dégrossa',
    'dégrosse',
    'dégrossé',
    'dégrossi',
    'déguerpi',
    'dégueula',
    'dégueule',
    'dégueulé',
    'déguisai',
    'déguisas',
    'déguisât',
    'déguisée',
    'déguiser',
    'déguises',
    'déguisés',
    'déguisez',
    'dégustai',
    'dégustas',
    'dégustât',
    'dégustée',
    'déguster',
    'dégustes',
    'dégustés',
    'dégustez',
    'déhalais',
    'déhalait',
    'déhalant',
    'déhalées',
    'déhalent',
    'déhalera',
    'déhaliez',
    'déhalons',
    'déhancha',
    'déhanche',
    'déhanché',
    'déhardai',
    'déhardas',
    'déhardât',
    'déhardée',
    'déharder',
    'déhardes',
    'déhardés',
    'déhardez',
    'déhottai',
    'déhottas',
    'déhottât',
    'déhottée',
    'déhotter',
    'déhottes',
    'déhottés',
    'déhottez',
    'déicides',
    'déifiais',
    'déifiait',
    'déifiant',
    'déifiées',
    'déifient',
    'déifiera',
    'déifiiez',
    'déifions',
    'déjantai',
    'déjantas',
    'déjantât',
    'déjantée',
    'déjanter',
    'déjantes',
    'déjantés',
    'déjantez',
    'déjaugea',
    'déjauger',
    'déjauges',
    'déjaugez',
    'déjaunis',
    'déjaunit',
    'déjaunît',
    'déjetais',
    'déjetait',
    'déjetant',
    'déjetées',
    'déjetiez',
    'déjetons',
    'déjettes',
    'déjeunai',
    'déjeunas',
    'déjeunât',
    'déjeuner',
    'déjeunes',
    'déjeunez',
    'déjouais',
    'déjouait',
    'déjouant',
    'déjouées',
    'déjouent',
    'déjouera',
    'déjouiez',
    'déjouons',
    'déjuchai',
    'déjuchas',
    'déjuchât',
    'déjuchée',
    'déjucher',
    'déjuches',
    'déjuchés',
    'déjuchez',
    'déjugeai',
    'déjugeas',
    'déjugeât',
    'déjugées',
    'déjugent',
    'déjugera',
    'déjugiez',
    'délabrai',
    'délabras',
    'délabrât',
    'délabrée',
    'délabrer',
    'délabres',
    'délabrés',
    'délabrez',
    'délaçais',
    'délaçait',
    'délaçant',
    'délacées',
    'délacent',
    'délacera',
    'délaciez',
    'délaçons',
    'délaient',
    'délaiera',
    'délainai',
    'délainas',
    'délainât',
    'délainée',
    'délainer',
    'délaines',
    'délainés',
    'délainez',
    'délaissa',
    'délaisse',
    'délaissé',
    'délaitai',
    'délaitas',
    'délaitât',
    'délaitée',
    'délaiter',
    'délaites',
    'délaités',
    'délaitez',
    'délardai',
    'délardas',
    'délardât',
    'délardée',
    'délarder',
    'délardes',
    'délardés',
    'délardez',
    'délassai',
    'délassas',
    'délassât',
    'délassée',
    'délasser',
    'délasses',
    'délassés',
    'délassez',
    'délateur',
    'délation',
    'délattai',
    'délattas',
    'délattât',
    'délattée',
    'délatter',
    'délattes',
    'délattés',
    'délattez',
    'délavage',
    'délavais',
    'délavait',
    'délavant',
    'délavées',
    'délavent',
    'délavera',
    'délaviez',
    'délavons',
    'délayage',
    'délayais',
    'délayait',
    'délayant',
    'délayées',
    'délayent',
    'délayera',
    'délayiez',
    'délayons',
    'deleatur',
    'délébile',
    'délectai',
    'délectas',
    'délectât',
    'délectée',
    'délecter',
    'délectes',
    'délectés',
    'délectez',
    'délégant',
    'déléguai',
    'déléguas',
    'déléguât',
    'déléguée',
    'déléguer',
    'délégués',
    'délègues',
    'déléguez',
    'délestai',
    'délestas',
    'délestât',
    'délestée',
    'délester',
    'délestes',
    'délestés',
    'délestez',
    'délétère',
    'délétion',
    'déliâmes',
    'déliasse',
    'déliâtes',
    'délibéra',
    'délibéré',
    'délibère',
    'délicate',
    'délicats',
    'délierai',
    'délieras',
    'délierez',
    'déliions',
    'délimita',
    'délimite',
    'délimité',
    'délinéai',
    'délinéas',
    'délinéât',
    'délinéée',
    'délinéer',
    'délinées',
    'délinéés',
    'délinéez',
    'délirais',
    'délirait',
    'délirant',
    'délirent',
    'délirera',
    'déliriez',
    'délirons',
    'délissai',
    'délissas',
    'délissât',
    'délissée',
    'délisser',
    'délisses',
    'délissés',
    'délissez',
    'délitage',
    'délitais',
    'délitait',
    'délitant',
    'délitées',
    'délitent',
    'délitera',
    'délitiez',
    'délitons',
    'délivrai',
    'délivras',
    'délivrât',
    'délivrée',
    'délivrer',
    'délivres',
    'délivrés',
    'délivrez',
    'délogeai',
    'délogeas',
    'délogeât',
    'délogées',
    'délogent',
    'délogera',
    'délogiez',
    'déloquai',
    'déloquas',
    'déloquât',
    'déloquée',
    'déloquer',
    'déloques',
    'déloqués',
    'déloquez',
    'délovais',
    'délovait',
    'délovant',
    'délovées',
    'délovent',
    'délovera',
    'déloviez',
    'délovons',
    'déloyale',
    'déloyaux',
    'deltoïde',
    'délurais',
    'délurait',
    'délurant',
    'délurées',
    'délurent',
    'délurera',
    'déluriez',
    'délurons',
    'délustra',
    'délustre',
    'délustré',
    'délutage',
    'délutais',
    'délutait',
    'délutant',
    'délutées',
    'délutent',
    'délutera',
    'délutiez',
    'délutons',
    'démaigri',
    'démailla',
    'démaille',
    'démaillé',
    'démancha',
    'démanche',
    'démanché',
    'demandai',
    'demandas',
    'demandât',
    'demandée',
    'demander',
    'demandes',
    'demandés',
    'demandez',
    'démangea',
    'démangée',
    'démanger',
    'démanges',
    'démangés',
    'démangez',
    'démarcha',
    'démarche',
    'démarché',
    'démariai',
    'démarias',
    'démariât',
    'démariée',
    'démarier',
    'démaries',
    'démariés',
    'démariez',
    'démarqua',
    'démarque',
    'démarqué',
    'démarrai',
    'démarras',
    'démarrât',
    'démarrée',
    'démarrer',
    'démarres',
    'démarrés',
    'démarrez',
    'démascla',
    'démascle',
    'démasclé',
    'démasqua',
    'démasque',
    'démasqué',
    'démâtage',
    'démâtais',
    'démâtait',
    'démâtant',
    'démâtées',
    'démâtent',
    'démâtera',
    'démâtiez',
    'démâtons',
    'démêlage',
    'démêlais',
    'démêlait',
    'démêlant',
    'démêlées',
    'démêlent',
    'démêlera',
    'démêliez',
    'démêloir',
    'démêlons',
    'démêlure',
    'démembra',
    'démembre',
    'démembré',
    'déménage',
    'déménagé',
    'démenais',
    'démenait',
    'démenant',
    'démences',
    'démenées',
    'démènent',
    'démènera',
    'démeniez',
    'démenons',
    'démentes',
    'démentez',
    'démentie',
    'démentir',
    'démentis',
    'démentit',
    'démentît',
    'démerdai',
    'démerdas',
    'démerdât',
    'démerdée',
    'démerder',
    'démerdes',
    'démerdés',
    'démerdez',
    'démérita',
    'démérite',
    'démérité',
    'démesure',
    'démesuré',
    'démettes',
    'démettez',
    'démettra',
    'démettre',
    'démeubla',
    'démeuble',
    'démeublé',
    'demeurai',
    'demeuras',
    'demeurât',
    'demeurée',
    'demeurer',
    'demeures',
    'demeurés',
    'demeurez',
    'demiards',
    'demi-bas',
    'démiella',
    'démielle',
    'démiellé',
    'demi-fin',
    'demi-mal',
    'demi-mot',
    'déminage',
    'déminais',
    'déminait',
    'déminant',
    'déminées',
    'déminent',
    'déminera',
    'démineur',
    'déminiez',
    'déminons',
    'démirent',
    'demi-sel',
    'démisses',
    'demi-ton',
    'démiurge',
    'démodais',
    'démodait',
    'démodant',
    'démodées',
    'démodent',
    'démodera',
    'démodiez',
    'démodons',
    'démodula',
    'démodule',
    'démodulé',
    'démolies',
    'démolira',
    'démontai',
    'démontas',
    'démontât',
    'démontée',
    'démonter',
    'démontes',
    'démontés',
    'démontez',
    'démontra',
    'démontre',
    'démontré',
    'démordes',
    'démordez',
    'démordis',
    'démordit',
    'démordît',
    'démordra',
    'démordre',
    'démordue',
    'démordus',
    'démotiva',
    'démotive',
    'démotivé',
    'démoulai',
    'démoulas',
    'démoulât',
    'démoulée',
    'démouler',
    'démoules',
    'démoulés',
    'démoulez',
    'démunies',
    'démunira',
    'démurais',
    'démurait',
    'démurant',
    'démurées',
    'démurent',
    'démurera',
    'démurgea',
    'démurgée',
    'démurger',
    'démurges',
    'démurgés',
    'démurgez',
    'démuriez',
    'démurons',
    'démusela',
    'démuselé',
    'dénantie',
    'dénantir',
    'dénantis',
    'dénantit',
    'dénantît',
    'dénattai',
    'dénattas',
    'dénattât',
    'dénattée',
    'dénatter',
    'dénattes',
    'dénattés',
    'dénattez',
    'dénatura',
    'dénature',
    'dénaturé',
    'dendrite',
    'déneigea',
    'déneigée',
    'déneiger',
    'déneiges',
    'déneigés',
    'déneigez',
    'dénervai',
    'dénervas',
    'dénervât',
    'dénervée',
    'dénerver',
    'dénerves',
    'dénervés',
    'dénervez',
    'déniaisa',
    'déniaise',
    'déniaisé',
    'déniâmes',
    'déniasse',
    'déniâtes',
    'dénichai',
    'dénichas',
    'dénichât',
    'dénichée',
    'dénicher',
    'déniches',
    'dénichés',
    'dénichez',
    'dénierai',
    'dénieras',
    'dénierez',
    'dénigrai',
    'dénigras',
    'dénigrât',
    'dénigrée',
    'dénigrer',
    'dénigres',
    'dénigrés',
    'dénigrez',
    'déniions',
    'dénitrai',
    'dénitras',
    'dénitrât',
    'dénitrée',
    'dénitrer',
    'dénitres',
    'dénitrés',
    'dénitrez',
    'dénivela',
    'dénivelé',
    'dénoient',
    'dénoiera',
    'dénombra',
    'dénombre',
    'dénombré',
    'dénommai',
    'dénommas',
    'dénommât',
    'dénommée',
    'dénommer',
    'dénommes',
    'dénommés',
    'dénommez',
    'dénonçai',
    'dénonças',
    'dénonçât',
    'dénoncée',
    'dénoncer',
    'dénonces',
    'dénoncés',
    'dénoncez',
    'dénotais',
    'dénotait',
    'dénotant',
    'dénotées',
    'dénotent',
    'dénotera',
    'dénotiez',
    'dénotons',
    'dénouais',
    'dénouait',
    'dénouant',
    'dénouées',
    'dénouent',
    'dénouera',
    'dénouiez',
    'dénouons',
    'dénoyais',
    'dénoyait',
    'dénoyant',
    'dénoyées',
    'dénoyiez',
    'dénoyons',
    'densités',
    'dentaire',
    'dentales',
    'dentelai',
    'dentelas',
    'dentelât',
    'dentelée',
    'denteler',
    'dentelés',
    'dentelez',
    'dentelle',
    'dentiers',
    'dentines',
    'dentiste',
    'dentures',
    'dénuâmes',
    'dénuasse',
    'dénuâtes',
    'dénudais',
    'dénudait',
    'dénudant',
    'dénudées',
    'dénudent',
    'dénudera',
    'dénudiez',
    'dénudons',
    'dénuerai',
    'dénueras',
    'dénuerez',
    'dénuions',
    'dépailla',
    'dépaille',
    'dépaillé',
    'dépannai',
    'dépannas',
    'dépannât',
    'dépannée',
    'dépanner',
    'dépannes',
    'dépannés',
    'dépannez',
    'déparais',
    'déparait',
    'déparant',
    'déparées',
    'déparent',
    'déparera',
    'dépariai',
    'déparias',
    'dépariât',
    'dépariée',
    'déparier',
    'déparies',
    'dépariés',
    'dépariez',
    'déparlai',
    'déparlas',
    'déparlât',
    'déparler',
    'déparles',
    'déparlez',
    'déparons',
    'départes',
    'départez',
    'départie',
    'départir',
    'départis',
    'départit',
    'départît',
    'dépassai',
    'dépassas',
    'dépassât',
    'dépassée',
    'dépasser',
    'dépasses',
    'dépassés',
    'dépassez',
    'dépavage',
    'dépavais',
    'dépavait',
    'dépavant',
    'dépavées',
    'dépavent',
    'dépavera',
    'dépaviez',
    'dépavons',
    'dépaysai',
    'dépaysas',
    'dépaysât',
    'dépaysée',
    'dépayser',
    'dépayses',
    'dépaysés',
    'dépaysez',
    'dépeçage',
    'dépeçais',
    'dépeçait',
    'dépeçant',
    'dépecées',
    'dépècent',
    'dépècera',
    'dépeceur',
    'dépêchai',
    'dépêchas',
    'dépêchât',
    'dépêchée',
    'dépêcher',
    'dépêches',
    'dépêchés',
    'dépêchez',
    'dépeciez',
    'dépeçons',
    'dépeigna',
    'dépeigne',
    'dépeigné',
    'dépeinte',
    'dépeints',
    'dépendes',
    'dépendez',
    'dépendis',
    'dépendit',
    'dépendît',
    'dépendra',
    'dépendre',
    'dépendue',
    'dépendus',
    'dépensai',
    'dépensas',
    'dépensât',
    'dépensée',
    'dépenser',
    'dépenses',
    'dépensés',
    'dépensez',
    'dépérira',
    'dépêtrai',
    'dépêtras',
    'dépêtrât',
    'dépêtrée',
    'dépêtrer',
    'dépêtres',
    'dépêtrés',
    'dépêtrez',
    'dépeupla',
    'dépeuple',
    'dépeuplé',
    'déphasai',
    'déphasas',
    'déphasât',
    'déphasée',
    'déphaser',
    'déphases',
    'déphasés',
    'déphasez',
    'dépiauta',
    'dépiaute',
    'dépiauté',
    'dépicage',
    'dépilage',
    'dépilais',
    'dépilait',
    'dépilant',
    'dépilées',
    'dépilent',
    'dépilera',
    'dépiliez',
    'dépilons',
    'dépingla',
    'dépingle',
    'dépinglé',
    'dépiquai',
    'dépiquas',
    'dépiquât',
    'dépiquée',
    'dépiquer',
    'dépiques',
    'dépiqués',
    'dépiquez',
    'dépistai',
    'dépistas',
    'dépistât',
    'dépistée',
    'dépister',
    'dépistes',
    'dépistés',
    'dépistez',
    'dépitais',
    'dépitait',
    'dépitant',
    'dépitées',
    'dépitent',
    'dépitera',
    'dépitiez',
    'dépitons',
    'déplaçai',
    'déplaças',
    'déplaçât',
    'déplacée',
    'déplacer',
    'déplaces',
    'déplacés',
    'déplacez',
    'déplaira',
    'déplaire',
    'déplaise',
    'déplanta',
    'déplante',
    'déplanté',
    'déplâtra',
    'déplâtre',
    'déplâtré',
    'dépliage',
    'dépliais',
    'dépliait',
    'dépliant',
    'dépliées',
    'déplient',
    'dépliera',
    'dépliiez',
    'déplions',
    'déplissa',
    'déplisse',
    'déplissé',
    'déploies',
    'déplomba',
    'déplombe',
    'déplombé',
    'déplorai',
    'déploras',
    'déplorât',
    'déplorée',
    'déplorer',
    'déplores',
    'déplorés',
    'déplorez',
    'déployai',
    'déployas',
    'déployât',
    'déployée',
    'déployer',
    'déployés',
    'déployez',
    'déplumai',
    'déplumas',
    'déplumât',
    'déplumée',
    'déplumer',
    'déplumes',
    'déplumés',
    'déplûmes',
    'déplumez',
    'déplusse',
    'déplûtes',
    'dépointa',
    'dépointe',
    'dépointé',
    'dépolies',
    'dépolira',
    'dépollua',
    'dépollue',
    'dépollué',
    'déponent',
    'déportai',
    'déportas',
    'déportât',
    'déportée',
    'déporter',
    'déportes',
    'déportés',
    'déportez',
    'déposais',
    'déposait',
    'déposant',
    'déposées',
    'déposent',
    'déposera',
    'déposiez',
    'déposons',
    'dépotage',
    'dépotais',
    'dépotait',
    'dépotant',
    'dépotées',
    'dépotent',
    'dépotera',
    'dépotiez',
    'dépotoir',
    'dépotons',
    'dépoudra',
    'dépoudre',
    'dépoudré',
    'dépourvu',
    'dépravai',
    'dépravas',
    'dépravât',
    'dépravée',
    'dépraver',
    'dépraves',
    'dépravés',
    'dépravez',
    'déprécia',
    'déprécie',
    'déprécié',
    'déprends',
    'déprenez',
    'déprenne',
    'déprimai',
    'déprimas',
    'déprimât',
    'déprimée',
    'déprimer',
    'déprimes',
    'déprimés',
    'déprîmes',
    'déprimez',
    'déprisai',
    'déprisas',
    'déprisât',
    'déprisée',
    'dépriser',
    'déprises',
    'déprisés',
    'déprisez',
    'déprisse',
    'déprîtes',
    'dépucela',
    'dépucelé',
    'dépulpai',
    'dépulpas',
    'dépulpât',
    'dépulpée',
    'dépulper',
    'dépulpes',
    'dépulpés',
    'dépulpez',
    'dépurais',
    'dépurait',
    'dépurant',
    'dépurées',
    'dépurent',
    'dépurera',
    'dépuriez',
    'dépurons',
    'députais',
    'députait',
    'députant',
    'députées',
    'députent',
    'députera',
    'députiez',
    'députons',
    'déquilla',
    'déquille',
    'déquillé',
    'déracina',
    'déracine',
    'déraciné',
    'déradais',
    'déradait',
    'déradant',
    'déradent',
    'déradera',
    'déradiez',
    'déradons',
    'dérageai',
    'dérageas',
    'dérageât',
    'déragent',
    'déragera',
    'déragiez',
    'déraidie',
    'déraidir',
    'déraidis',
    'déraidit',
    'déraidît',
    'déraient',
    'déraiera',
    'dérailla',
    'déraille',
    'déraillé',
    'déraison',
    'dérangea',
    'dérangée',
    'déranger',
    'déranges',
    'dérangés',
    'dérangez',
    'dérapage',
    'dérapais',
    'dérapait',
    'dérapant',
    'dérapent',
    'dérapera',
    'dérapiez',
    'dérapons',
    'dérasais',
    'dérasait',
    'dérasant',
    'dérasées',
    'dérasent',
    'dérasera',
    'dérasiez',
    'dérasons',
    'dératais',
    'dératait',
    'dératant',
    'dératées',
    'dératent',
    'dératera',
    'dératiez',
    'dératisa',
    'dératise',
    'dératisé',
    'dératons',
    'dérayais',
    'dérayait',
    'dérayant',
    'dérayées',
    'dérayent',
    'dérayera',
    'dérayiez',
    'dérayons',
    'dérayure',
    'derechef',
    'déréglai',
    'déréglas',
    'déréglât',
    'déréglée',
    'dérégler',
    'déréglés',
    'dérègles',
    'déréglez',
    'dérégula',
    'dérégule',
    'dérégulé',
    'déridais',
    'déridait',
    'déridant',
    'déridées',
    'dérident',
    'déridera',
    'déridiez',
    'déridons',
    'dérision',
    'dérivais',
    'dérivait',
    'dérivant',
    'dérivées',
    'dérivent',
    'dérivera',
    'dériveur',
    'dériviez',
    'dérivons',
    'dermeste',
    'dermique',
    'dermites',
    'dernière',
    'derniers',
    'dérobade',
    'dérobais',
    'dérobait',
    'dérobant',
    'dérobées',
    'dérobent',
    'dérobera',
    'dérobiez',
    'dérobons',
    'dérochai',
    'dérochas',
    'dérochât',
    'dérochée',
    'dérocher',
    'déroches',
    'dérochés',
    'dérochez',
    'dérodais',
    'dérodait',
    'dérodant',
    'dérodées',
    'dérodent',
    'dérodera',
    'dérodiez',
    'dérodons',
    'dérogeai',
    'dérogeas',
    'dérogeât',
    'dérogent',
    'dérogera',
    'dérogiez',
    'déroulai',
    'déroulas',
    'déroulât',
    'déroulée',
    'dérouler',
    'déroules',
    'déroulés',
    'déroulez',
    'déroutai',
    'déroutas',
    'déroutât',
    'déroutée',
    'dérouter',
    'déroutes',
    'déroutés',
    'déroutez',
    'derricks',
    'derrière',
    'derviche',
    'désabusa',
    'désabuse',
    'désabusé',
    'désarmai',
    'désarmas',
    'désarmât',
    'désarmée',
    'désarmer',
    'désarmes',
    'désarmés',
    'désarmez',
    'désarroi',
    'désastre',
    'désaveux',
    'désavoua',
    'désavoue',
    'désavoué',
    'désaxais',
    'désaxait',
    'désaxant',
    'désaxées',
    'désaxent',
    'désaxera',
    'désaxiez',
    'désaxons',
    'descella',
    'descelle',
    'descellé',
    'descende',
    'descends',
    'descendu',
    'descente',
    'désembua',
    'désembue',
    'désembué',
    'désempli',
    'désenfla',
    'désenfle',
    'désenflé',
    'désertai',
    'désertas',
    'désertât',
    'désertée',
    'déserter',
    'désertes',
    'désertés',
    'désertez',
    'désétama',
    'désétame',
    'désétamé',
    'désherba',
    'désherbe',
    'désherbé',
    'déshuila',
    'déshuile',
    'déshuilé',
    'désignai',
    'désignas',
    'désignât',
    'désignée',
    'désigner',
    'désignes',
    'désignés',
    'désignez',
    'désirais',
    'désirait',
    'désirant',
    'désirées',
    'désirent',
    'désirera',
    'désireux',
    'désiriez',
    'désirons',
    'désistai',
    'désistas',
    'désistât',
    'désistée',
    'désister',
    'désistes',
    'désistés',
    'désistez',
    'désobéie',
    'désobéir',
    'désobéis',
    'désobéit',
    'désobéît',
    'désolais',
    'désolait',
    'désolant',
    'désolées',
    'désolent',
    'désolera',
    'désoliez',
    'désolons',
    'désopila',
    'désopile',
    'désopilé',
    'désorbai',
    'désorbas',
    'désorbât',
    'désorbée',
    'désorber',
    'désorbes',
    'désorbés',
    'désorbez',
    'désordre',
    'désossai',
    'désossas',
    'désossât',
    'désossée',
    'désosser',
    'désosses',
    'désossés',
    'désossez',
    'désoxyda',
    'désoxyde',
    'désoxydé',
    'despotes',
    'desquama',
    'desquame',
    'desquamé',
    'desquels',
    'dessabla',
    'dessable',
    'dessablé',
    'dessaisi',
    'dessalai',
    'dessalas',
    'dessalât',
    'dessalée',
    'dessaler',
    'dessales',
    'dessalés',
    'dessalez',
    'dessécha',
    'desséché',
    'dessèche',
    'desseins',
    'dessella',
    'desselle',
    'dessellé',
    'desserra',
    'desserre',
    'desserré',
    'desserte',
    'desserti',
    'desserts',
    'desserve',
    'desservi',
    'dessilla',
    'dessille',
    'dessillé',
    'dessinai',
    'dessinas',
    'dessinât',
    'dessinée',
    'dessiner',
    'dessines',
    'dessinés',
    'dessinez',
    'dessolai',
    'dessolas',
    'dessolât',
    'dessolée',
    'dessoler',
    'dessoles',
    'dessolés',
    'dessolez',
    'dessouda',
    'dessoude',
    'dessoudé',
    'dessoula',
    'dessoûla',
    'dessoule',
    'dessoulé',
    'dessoûle',
    'dessoûlé',
    'dessuita',
    'dessuite',
    'dessuité',
    'destinai',
    'destinas',
    'destinât',
    'destinée',
    'destiner',
    'destines',
    'destinés',
    'destinez',
    'destitue',
    'destitué',
    'déstocka',
    'déstocke',
    'déstocké',
    'destrier',
    'désuètes',
    'désunies',
    'désunion',
    'désunira',
    'détachai',
    'détachas',
    'détachât',
    'détachée',
    'détacher',
    'détaches',
    'détachés',
    'détachez',
    'détailla',
    'détaille',
    'détaillé',
    'détalais',
    'détalait',
    'détalant',
    'détalent',
    'détalera',
    'détaliez',
    'détallai',
    'détallas',
    'détallât',
    'détallée',
    'détaller',
    'détalles',
    'détallés',
    'détallez',
    'détalons',
    'détartra',
    'détartre',
    'détartré',
    'détaxais',
    'détaxait',
    'détaxant',
    'détaxées',
    'détaxent',
    'détaxera',
    'détaxiez',
    'détaxons',
    'détectai',
    'détectas',
    'détectât',
    'détectée',
    'détecter',
    'détectes',
    'détectés',
    'détectez',
    'déteigne',
    'déteinte',
    'déteints',
    'dételage',
    'dételais',
    'dételait',
    'dételant',
    'dételées',
    'dételiez',
    'dételles',
    'dételons',
    'détenais',
    'détenait',
    'détenant',
    'détendes',
    'détendez',
    'détendis',
    'détendit',
    'détendît',
    'détendra',
    'détendre',
    'détendue',
    'détendus',
    'déteniez',
    'détenons',
    'détentes',
    'détenues',
    'détergea',
    'détergée',
    'déterger',
    'déterges',
    'détergés',
    'détergez',
    'déterrai',
    'déterras',
    'déterrât',
    'déterrée',
    'déterrer',
    'déterres',
    'déterrés',
    'déterrez',
    'détersif',
    'détestai',
    'détestas',
    'détestât',
    'détestée',
    'détester',
    'détestes',
    'détestés',
    'détestez',
    'détienne',
    'détînmes',
    'détinsse',
    'détîntes',
    'détirais',
    'détirait',
    'détirant',
    'détirées',
    'détirent',
    'détirera',
    'détiriez',
    'détirons',
    'détissai',
    'détissas',
    'détissât',
    'détissée',
    'détisser',
    'détisses',
    'détissés',
    'détissez',
    'détonais',
    'détonait',
    'détonant',
    'détonent',
    'détonera',
    'détoniez',
    'détonnai',
    'détonnas',
    'détonnât',
    'détonner',
    'détonnes',
    'détonnez',
    'détonons',
    'détordes',
    'détordez',
    'détordis',
    'détordit',
    'détordît',
    'détordra',
    'détordre',
    'détordue',
    'détordus',
    'détourai',
    'détouras',
    'détourât',
    'détourée',
    'détourer',
    'détoures',
    'détourés',
    'détourez',
    'détourna',
    'détourne',
    'détourné',
    'détracta',
    'détracte',
    'détracté',
    'détraqua',
    'détraque',
    'détraqué',
    'détrempa',
    'détrempe',
    'détrempé',
    'détressa',
    'détresse',
    'détressé',
    'détritus',
    'détroits',
    'détrompa',
    'détrompe',
    'détrompé',
    'détrônai',
    'détrônas',
    'détrônât',
    'détrônée',
    'détrôner',
    'détrônes',
    'détrônés',
    'détrônez',
    'détroqua',
    'détroque',
    'détroqué',
    'détruira',
    'détruire',
    'détruise',
    'détruite',
    'détruits',
    'deutéron',
    'deuxième',
    'devaient',
    'dévalais',
    'dévalait',
    'dévalant',
    'dévalées',
    'dévalent',
    'dévalera',
    'dévaliez',
    'dévalisa',
    'dévalise',
    'dévalisé',
    'dévalons',
    'dévaluai',
    'dévaluas',
    'dévaluât',
    'dévaluée',
    'dévaluer',
    'dévalues',
    'dévalués',
    'dévaluez',
    'devançai',
    'devanças',
    'devançât',
    'devancée',
    'devancer',
    'devances',
    'devancés',
    'devancez',
    'dévasais',
    'dévasait',
    'dévasant',
    'dévasées',
    'dévasent',
    'dévasera',
    'dévasiez',
    'dévasons',
    'dévastai',
    'dévastas',
    'dévastât',
    'dévastée',
    'dévaster',
    'dévastes',
    'dévastés',
    'dévastez',
    'déveines',
    'devenais',
    'devenait',
    'devenant',
    'deveniez',
    'devenons',
    'déventai',
    'déventas',
    'déventât',
    'déventée',
    'déventer',
    'déventes',
    'déventés',
    'déventez',
    'devenues',
    'déverbal',
    'déverdir',
    'déverdis',
    'déverdit',
    'déverdît',
    'dévergua',
    'dévergue',
    'dévergué',
    'dévernie',
    'dévernir',
    'dévernis',
    'dévernit',
    'dévernît',
    'déversai',
    'déversas',
    'déversât',
    'déversée',
    'déverser',
    'déverses',
    'déversés',
    'déversez',
    'dévêtais',
    'dévêtait',
    'dévêtant',
    'dévêtent',
    'dévêtiez',
    'dévêtira',
    'dévêtons',
    'dévêtues',
    'déviâmes',
    'déviante',
    'déviants',
    'déviasse',
    'déviâtes',
    'dévidage',
    'dévidais',
    'dévidait',
    'dévidant',
    'dévidées',
    'dévident',
    'dévidera',
    'dévideur',
    'dévidiez',
    'dévidoir',
    'dévidons',
    'devienne',
    'dévierai',
    'dévieras',
    'dévierez',
    'déviions',
    'devinais',
    'devinait',
    'devinant',
    'devinées',
    'devinent',
    'devinera',
    'deviniez',
    'devînmes',
    'devinons',
    'devinsse',
    'devîntes',
    'dévirola',
    'dévirole',
    'dévirolé',
    'dévisage',
    'dévisagé',
    'devisais',
    'devisait',
    'devisant',
    'devisent',
    'devisera',
    'devisiez',
    'devisons',
    'dévissai',
    'dévissas',
    'dévissât',
    'dévissée',
    'dévisser',
    'dévisses',
    'dévissés',
    'dévissez',
    'dévoient',
    'dévoiera',
    'dévoilai',
    'dévoilas',
    'dévoilât',
    'dévoilée',
    'dévoiler',
    'dévoiles',
    'dévoilés',
    'dévoilez',
    'dévoltai',
    'dévoltas',
    'dévoltât',
    'dévoltée',
    'dévolter',
    'dévoltes',
    'dévoltés',
    'dévoltez',
    'dévolues',
    'dévonien',
    'dévorais',
    'dévorait',
    'dévorant',
    'dévorées',
    'dévorent',
    'dévorera',
    'dévoreur',
    'dévoriez',
    'dévorons',
    'dévotion',
    'dévouais',
    'dévouait',
    'dévouant',
    'dévouées',
    'dévouent',
    'dévouera',
    'dévouiez',
    'dévouons',
    'dévoyais',
    'dévoyait',
    'dévoyant',
    'dévoyées',
    'dévoyiez',
    'dévoyons',
    'devrions',
    'dextrine',
    'dextrose',
    'diabètes',
    'diabolos',
    'diacides',
    'diaclase',
    'diacodes',
    'diaconal',
    'diaconat',
    'diadèmes',
    'diadoque',
    'diagnose',
    'diagonal',
    'dialecte',
    'dialogua',
    'dialogue',
    'dialogué',
    'dialysai',
    'dialysas',
    'dialysât',
    'dialysée',
    'dialyser',
    'dialyses',
    'dialysés',
    'dialysez',
    'diamanta',
    'diamante',
    'diamanté',
    'diamants',
    'diamètre',
    'diamides',
    'diapason',
    'diaphane',
    'diaphyse',
    'diaprais',
    'diaprait',
    'diaprant',
    'diaprées',
    'diaprent',
    'diaprera',
    'diapriez',
    'diaprons',
    'diaprure',
    'diarrhée',
    'diascope',
    'diaspora',
    'diastase',
    'diastole',
    'diathèse',
    'diatomée',
    'diatribe',
    'diclines',
    'dicrotes',
    'dictames',
    'dictâmes',
    'dictasse',
    'dictâtes',
    'dicterai',
    'dicteras',
    'dicterez',
    'dictions',
    'diérèses',
    'diergols',
    'diffamai',
    'diffamas',
    'diffamât',
    'diffamée',
    'diffamer',
    'diffames',
    'diffamés',
    'diffamez',
    'différai',
    'différas',
    'différât',
    'différée',
    'différer',
    'différés',
    'diffères',
    'différez',
    'difforma',
    'difforme',
    'difformé',
    'diffusai',
    'diffusas',
    'diffusât',
    'diffusée',
    'diffuser',
    'diffuses',
    'diffusés',
    'diffusez',
    'digérais',
    'digérait',
    'digérant',
    'digérées',
    'digèrent',
    'digérera',
    'digériez',
    'digérons',
    'digestes',
    'digestif',
    'digitale',
    'digitaux',
    'digitées',
    'dignités',
    'digramme',
    'dilacéra',
    'dilacéré',
    'dilacère',
    'dilapida',
    'dilapidé',
    'dilatais',
    'dilatait',
    'dilatant',
    'dilatées',
    'dilatent',
    'dilatera',
    'dilatiez',
    'dilatons',
    'dilemmes',
    'diligent',
    'diluâmes',
    'diluante',
    'diluants',
    'diluasse',
    'diluâtes',
    'diluerai',
    'dilueras',
    'diluerez',
    'diluions',
    'dilution',
    'diluvial',
    'diluvien',
    'diluvium',
    'dimanche',
    'diminuai',
    'diminuas',
    'diminuât',
    'diminuée',
    'diminuer',
    'diminues',
    'diminués',
    'diminuez',
    'dimorphe',
    'dînaient',
    'dînasses',
    'dindonna',
    'dindonne',
    'dindonné',
    'dînerais',
    'dînerait',
    'dînèrent',
    'dîneriez',
    'dînerons',
    'dîneront',
    'dînettes',
    'dîneuses',
    'dinghies',
    'dinguais',
    'dinguait',
    'dinguant',
    'dinguées',
    'dinguent',
    'dinguera',
    'dinguiez',
    'dinguons',
    'dinornis',
    'diocèses',
    'dioptres',
    'dioptrie',
    'dioramas',
    'diorites',
    'dioxines',
    'dioxydes',
    'dipalide',
    'dipétale',
    'diphasée',
    'diphasés',
    'diploïde',
    'diplômai',
    'diplômas',
    'diplômât',
    'diplômée',
    'diplômer',
    'diplômes',
    'diplômés',
    'diplômez',
    'diplopie',
    'diptères',
    'diptyque',
    'diraient',
    'directes',
    'directif',
    'dirigeai',
    'dirigeas',
    'dirigeât',
    'dirigées',
    'dirigent',
    'dirigera',
    'dirigiez',
    'dirimant',
    'disaient',
    'discales',
    'discerna',
    'discerne',
    'discerné',
    'disciple',
    'discoïde',
    'discorda',
    'discorde',
    'discordé',
    'discount',
    'discoure',
    'discours',
    'discourt',
    'discouru',
    'discrète',
    'discrets',
    'disculpa',
    'disculpe',
    'disculpé',
    'discutai',
    'discutas',
    'discutât',
    'discutée',
    'discuter',
    'discutes',
    'discutés',
    'discutez',
    'disertes',
    'disettes',
    'diseuses',
    'disgrâce',
    'disjoins',
    'disjoint',
    'disloqua',
    'disloque',
    'disloqué',
    'disparue',
    'disparus',
    'disparut',
    'disparût',
    'dispensa',
    'dispense',
    'dispensé',
    'dispersa',
    'disperse',
    'dispersé',
    'disposai',
    'disposas',
    'disposât',
    'disposée',
    'disposer',
    'disposes',
    'disposés',
    'disposez',
    'disputai',
    'disputas',
    'disputât',
    'disputée',
    'disputer',
    'disputes',
    'disputés',
    'disputez',
    'disséqua',
    'disséqué',
    'dissèque',
    'disserta',
    'disserte',
    'disserté',
    'dissions',
    'dissipai',
    'dissipas',
    'dissipât',
    'dissipée',
    'dissiper',
    'dissipes',
    'dissipés',
    'dissipez',
    'dissocia',
    'dissocie',
    'dissocié',
    'dissolue',
    'dissolus',
    'dissolve',
    'dissonai',
    'dissonas',
    'dissonât',
    'dissoner',
    'dissones',
    'dissonez',
    'dissoute',
    'dissuada',
    'dissuade',
    'dissuadé',
    'distales',
    'distança',
    'distance',
    'distancé',
    'distante',
    'distants',
    'distende',
    'distends',
    'distendu',
    'disthène',
    'distilla',
    'distille',
    'distillé',
    'distinct',
    'distique',
    'distorde',
    'distords',
    'distordu',
    'distraie',
    'distrais',
    'distrait',
    'district',
    'distyles',
    'diurèses',
    'diurnaux',
    'divaguai',
    'divaguas',
    'divaguât',
    'divaguer',
    'divagues',
    'divaguez',
    'divergea',
    'divergée',
    'diverger',
    'diverges',
    'divergés',
    'divergez',
    'diverses',
    'divertie',
    'divertir',
    'divertis',
    'divertit',
    'divertît',
    'divettes',
    'divinisa',
    'divinise',
    'divinisé',
    'divinité',
    'divisais',
    'divisait',
    'divisant',
    'divisées',
    'divisent',
    'divisera',
    'diviseur',
    'divisiez',
    'division',
    'divisons',
    'divorçai',
    'divorças',
    'divorçât',
    'divorcée',
    'divorcer',
    'divorces',
    'divorcés',
    'divorcez',
    'divulgua',
    'divulgue',
    'divulgué',
    'dix-huit',
    'dixièmes',
    'dix-neuf',
    'dix-sept',
    'dizaines',
    'dizygote',
    'djellaba',
    'doberman',
    'docilité',
    'docteurs',
    'doctoral',
    'doctorat',
    'doctrine',
    'document',
    'dodelina',
    'dodeline',
    'dodeliné',
    'dodinais',
    'dodinait',
    'dodinant',
    'dodinées',
    'dodinent',
    'dodinera',
    'dodiniez',
    'dodinons',
    'doigtais',
    'doigtait',
    'doigtant',
    'doigtées',
    'doigtent',
    'doigtera',
    'doigtier',
    'doigtiez',
    'doigtons',
    'dolaient',
    'dolasses',
    'dolentes',
    'dolerais',
    'dolerait',
    'dolèrent',
    'doleriez',
    'dolerons',
    'doleront',
    'doloires',
    'dolomies',
    'dolomite',
    'dolosifs',
    'dolosive',
    'domaines',
    'domanial',
    'domicile',
    'dominais',
    'dominait',
    'dominant',
    'dominées',
    'dominent',
    'dominera',
    'dominiez',
    'dominion',
    'dominons',
    'domismes',
    'dommages',
    'domptage',
    'domptais',
    'domptait',
    'domptant',
    'domptées',
    'domptent',
    'domptera',
    'dompteur',
    'domptiez',
    'domptons',
    'donacies',
    'donateur',
    'donation',
    'dondaine',
    'donnâmes',
    'donnasse',
    'donnâtes',
    'donnerai',
    'donneras',
    'donnerez',
    'donneurs',
    'donneuse',
    'donnions',
    'donzelle',
    'dopaient',
    'dopamine',
    'dopantes',
    'dopasses',
    'doperais',
    'doperait',
    'dopèrent',
    'doperiez',
    'doperons',
    'doperont',
    'doraient',
    'dorasses',
    'dorerais',
    'dorerait',
    'dorèrent',
    'doreriez',
    'dorerons',
    'doreront',
    'doreuses',
    'dorienne',
    'doriques',
    'dorlotai',
    'dorlotas',
    'dorlotât',
    'dorlotée',
    'dorloter',
    'dorlotes',
    'dorlotés',
    'dorlotez',
    'dormante',
    'dormants',
    'dormeurs',
    'dormeuse',
    'dormîmes',
    'dormions',
    'dormirai',
    'dormiras',
    'dormirez',
    'dormisse',
    'dormîtes',
    'dormitif',
    'dorsales',
    'dortoirs',
    'dosables',
    'dosaient',
    'dosasses',
    'doserais',
    'doserait',
    'dosèrent',
    'doseriez',
    'doserons',
    'doseront',
    'dossards',
    'dosseret',
    'dossière',
    'dossiers',
    'dotaient',
    'dotalité',
    'dotasses',
    'dotation',
    'doterais',
    'doterait',
    'dotèrent',
    'doteriez',
    'doterons',
    'doteront',
    'douaient',
    'douaires',
    'douanier',
    'douasses',
    'doublage',
    'doublais',
    'doublait',
    'doublant',
    'doubleau',
    'doublées',
    'doublent',
    'doublera',
    'doublets',
    'doubleur',
    'doublier',
    'doubliez',
    'doublons',
    'doublure',
    'doucette',
    'douceurs',
    'douchais',
    'douchait',
    'douchant',
    'douchées',
    'douchent',
    'douchera',
    'doucheur',
    'douchiez',
    'douchons',
    'doucîmes',
    'doucines',
    'doucirai',
    'douciras',
    'doucirez',
    'doucisse',
    'doucîtes',
    'douelles',
    'douerais',
    'douerait',
    'douèrent',
    'doueriez',
    'douerons',
    'doueront',
    'douilles',
    'douillet',
    'douleurs',
    'dourines',
    'doutâmes',
    'doutasse',
    'doutâtes',
    'douterai',
    'douteras',
    'douterez',
    'douteurs',
    'douteuse',
    'doutions',
    'douvains',
    'douvelle',
    'douzaine',
    'douzains',
    'douzième',
    'doyennes',
    'doyennés',
    'dracénas',
    'drachmes',
    'dragages',
    'drageoir',
    'drageons',
    'dragline',
    'dragonne',
    'draguais',
    'draguait',
    'draguant',
    'draguées',
    'draguent',
    'draguera',
    'dragueur',
    'draguiez',
    'draguons',
    'draierai',
    'draieras',
    'draierez',
    'drailles',
    'drainage',
    'drainais',
    'drainait',
    'drainant',
    'drainées',
    'drainent',
    'drainera',
    'draineur',
    'drainiez',
    'drainons',
    'draisine',
    'drakkars',
    'drapâmes',
    'drapasse',
    'drapâtes',
    'drapeaux',
    'draperai',
    'draperas',
    'draperez',
    'draperie',
    'drapière',
    'drapiers',
    'drapions',
    'draveurs',
    'drawback',
    'drayâmes',
    'drayasse',
    'drayâtes',
    'drayerai',
    'drayeras',
    'drayerez',
    'drayions',
    'drayoire',
    'drayoirs',
    'dressage',
    'dressais',
    'dressait',
    'dressant',
    'dressées',
    'dressent',
    'dressera',
    'dresseur',
    'dressiez',
    'dressoir',
    'dressons',
    'dribblai',
    'dribblas',
    'dribblât',
    'dribblée',
    'dribbler',
    'dribbles',
    'dribblés',
    'dribblez',
    'drillais',
    'drillait',
    'drillant',
    'drillées',
    'drillent',
    'drillera',
    'drilliez',
    'drillons',
    'drivâmes',
    'drivasse',
    'drivâtes',
    'drive-in',
    'driverai',
    'driveras',
    'driverez',
    'drivions',
    'drogmans',
    'droguais',
    'droguait',
    'droguant',
    'droguées',
    'droguent',
    'droguera',
    'droguets',
    'droguiez',
    'droguons',
    'droitier',
    'droiture',
    'drôlerie',
    'drôlesse',
    'dropâmes',
    'dropasse',
    'dropâtes',
    'droperai',
    'droperas',
    'droperez',
    'dropions',
    'droppage',
    'droppais',
    'droppait',
    'droppant',
    'droppées',
    'droppent',
    'droppera',
    'droppiez',
    'droppons',
    'drossais',
    'drossait',
    'drossant',
    'drossées',
    'drossent',
    'drossera',
    'drossiez',
    'drossons',
    'drumlins',
    'drummers',
    'dualisme',
    'dualiste',
    'dualités',
    'ducasses',
    'duchesse',
    'ducroire',
    'ductiles',
    'dulcifia',
    'dulcifie',
    'dulcifié',
    'dulcinée',
    'dulcites',
    'dumpings',
    'dunettes',
    'duodénal',
    'duodénum',
    'duopoles',
    'dupaient',
    'dupasses',
    'duperais',
    'duperait',
    'dupèrent',
    'duperies',
    'duperiez',
    'duperons',
    'duperont',
    'dupeuses',
    'duplexai',
    'duplexas',
    'duplexât',
    'duplexée',
    'duplexer',
    'duplexes',
    'duplexés',
    'duplexez',
    'dupliqua',
    'duplique',
    'dupliqué',
    'durables',
    'duraient',
    'duramens',
    'durasses',
    'duratifs',
    'durative',
    'durcîmes',
    'durcirai',
    'durciras',
    'durcirez',
    'durcisse',
    'durcîtes',
    'durement',
    'durerais',
    'durerait',
    'durèrent',
    'dureriez',
    'durerons',
    'dureront',
    'durillon',
    'dussions',
    'duumviri',
    'duumvirs',
    'duvetais',
    'duvetait',
    'duvetant',
    'duvetées',
    'duveteux',
    'duvetiez',
    'duvetons',
    'duvettes',
    'dynamisa',
    'dynamise',
    'dynamisé',
    'dynamita',
    'dynamite',
    'dynamité',
    'dynastes',
    'dynastie',
    'dyslexie',
    'dyspnées',
    'dystasie',
    'dystocie',
    'dystomie',
    'dystonie',
    'dysuries',
    'dytiques',
    'ébahîmes',
    'ébahirai',
    'ébahiras',
    'ébahirez',
    'ébahisse',
    'ébahîtes',
    'ébarbage',
    'ébarbais',
    'ébarbait',
    'ébarbant',
    'ébarbées',
    'ébarbent',
    'ébarbera',
    'ébarbeur',
    'ébarbiez',
    'ébarboir',
    'ébarbons',
    'ébarbure',
    'ébattiez',
    'ébattons',
    'ébattrez',
    'ébattues',
    'ébaubies',
    'ébauchai',
    'ébauchas',
    'ébauchât',
    'ébauchée',
    'ébaucher',
    'ébauches',
    'ébauchés',
    'ébauchez',
    'ébaudies',
    'ébavurai',
    'ébavuras',
    'ébavurât',
    'ébavurée',
    'ébavurer',
    'ébavures',
    'ébavurés',
    'ébavurez',
    'ébéniers',
    'ébéniste',
    'éberluai',
    'éberluas',
    'éberluât',
    'éberluée',
    'éberluer',
    'éberlues',
    'éberlués',
    'éberluez',
    'ébiselai',
    'ébiselas',
    'ébiselât',
    'ébiselée',
    'ébiseler',
    'ébiselés',
    'ébisèles',
    'ébiselez',
    'éblouies',
    'éblouira',
    'ébonites',
    'éborgnai',
    'éborgnas',
    'éborgnât',
    'éborgnée',
    'éborgner',
    'éborgnes',
    'éborgnés',
    'éborgnez',
    'ébossais',
    'ébossait',
    'ébossant',
    'ébossées',
    'ébossent',
    'ébossera',
    'ébossiez',
    'ébossons',
    'ébouâmes',
    'ébouasse',
    'ébouâtes',
    'ébouerai',
    'éboueras',
    'ébouerez',
    'éboueurs',
    'ébouions',
    'éboulais',
    'éboulait',
    'éboulant',
    'éboulées',
    'éboulent',
    'éboulera',
    'ébouliez',
    'éboulons',
    'ébourrai',
    'ébourras',
    'ébourrât',
    'ébourrée',
    'ébourrer',
    'ébourres',
    'ébourrés',
    'ébourrez',
    'éboutais',
    'éboutait',
    'éboutant',
    'éboutées',
    'éboutent',
    'éboutera',
    'éboutiez',
    'éboutons',
    'ébraisai',
    'ébraisas',
    'ébraisât',
    'ébraisée',
    'ébraiser',
    'ébraises',
    'ébraisés',
    'ébraisez',
    'ébrancha',
    'ébranche',
    'ébranché',
    'ébranlai',
    'ébranlas',
    'ébranlât',
    'ébranlée',
    'ébranler',
    'ébranles',
    'ébranlés',
    'ébranlez',
    'ébrasais',
    'ébrasait',
    'ébrasant',
    'ébrasées',
    'ébrasent',
    'ébrasera',
    'ébrasiez',
    'ébrasons',
    'ébréchai',
    'ébréchas',
    'ébréchât',
    'ébréchée',
    'ébrécher',
    'ébréchés',
    'ébrèches',
    'ébréchez',
    'ébriétés',
    'ébrouées',
    'ébrouiez',
    'ébrouons',
    'ébruitai',
    'ébruitas',
    'ébruitât',
    'ébruitée',
    'ébruiter',
    'ébruites',
    'ébruités',
    'ébruitez',
    'éburnéen',
    'éburnées',
    'écachais',
    'écachait',
    'écachant',
    'écachées',
    'écachent',
    'écachera',
    'écachiez',
    'écachons',
    'écaffais',
    'écaffait',
    'écaffant',
    'écaffées',
    'écaffent',
    'écaffera',
    'écaffiez',
    'écaffons',
    'écaillai',
    'écaillas',
    'écaillât',
    'écaillée',
    'écailler',
    'écailles',
    'écaillés',
    'écaillez',
    'écalâmes',
    'écalasse',
    'écalâtes',
    'écalerai',
    'écaleras',
    'écalerez',
    'écalions',
    'écalures',
    'écanguai',
    'écanguas',
    'écanguât',
    'écanguée',
    'écanguer',
    'écangues',
    'écangués',
    'écanguez',
    'écarlate',
    'écartais',
    'écartait',
    'écartant',
    'écartées',
    'écartela',
    'écartelé',
    'écartèle',
    'écartent',
    'écartera',
    'écarteur',
    'écartiez',
    'écartons',
    'écatîmes',
    'écatirai',
    'écatiras',
    'écatirez',
    'écatisse',
    'écatîtes',
    'eccéités',
    'écervelé',
    'échafaud',
    'échalier',
    'échalote',
    'échampie',
    'échampir',
    'échampis',
    'échampit',
    'échampît',
    'échancra',
    'échancre',
    'échancré',
    'échangea',
    'échangée',
    'échanger',
    'échanges',
    'échangés',
    'échangez',
    'échanson',
    'échappai',
    'échappas',
    'échappât',
    'échappée',
    'échapper',
    'échappes',
    'échappés',
    'échappez',
    'échardes',
    'écharnai',
    'écharnas',
    'écharnât',
    'écharnée',
    'écharner',
    'écharnes',
    'écharnés',
    'écharnez',
    'écharpai',
    'écharpas',
    'écharpât',
    'écharpée',
    'écharper',
    'écharpes',
    'écharpés',
    'écharpez',
    'échasses',
    'échaudai',
    'échaudas',
    'échaudât',
    'échaudée',
    'échauder',
    'échaudes',
    'échaudés',
    'échaudez',
    'échauffa',
    'échauffe',
    'échauffé',
    'échaulai',
    'échaulas',
    'échaulât',
    'échaulée',
    'échauler',
    'échaules',
    'échaulés',
    'échaulez',
    'échaumai',
    'échaumas',
    'échaumât',
    'échaumée',
    'échaumer',
    'échaumes',
    'échaumés',
    'échaumez',
    'échéance',
    'échelier',
    'échelles',
    'échelons',
    'écheveau',
    'échevela',
    'échevelé',
    'échevins',
    'échidnés',
    'échinais',
    'échinait',
    'échinant',
    'échinées',
    'échinent',
    'échinera',
    'échiniez',
    'échinons',
    'échoient',
    'échoppai',
    'échoppas',
    'échoppât',
    'échoppée',
    'échopper',
    'échoppes',
    'échoppés',
    'échoppez',
    'échotier',
    'échouage',
    'échouais',
    'échouait',
    'échouant',
    'échouées',
    'échouent',
    'échouera',
    'échouiez',
    'échouons',
    'échurent',
    'écimages',
    'écimâmes',
    'écimasse',
    'écimâtes',
    'écimerai',
    'écimeras',
    'écimerez',
    'écimions',
    'éclairai',
    'éclairas',
    'éclairât',
    'éclairci',
    'éclairée',
    'éclairer',
    'éclaires',
    'éclairés',
    'éclairez',
    'éclatais',
    'éclatait',
    'éclatant',
    'éclatées',
    'éclatent',
    'éclatera',
    'éclateur',
    'éclatiez',
    'éclatons',
    'éclipsai',
    'éclipsas',
    'éclipsât',
    'éclipsée',
    'éclipser',
    'éclipses',
    'éclipsés',
    'éclipsez',
    'éclissai',
    'éclissas',
    'éclissât',
    'éclissée',
    'éclisser',
    'éclisses',
    'éclissés',
    'éclissez',
    'éclopais',
    'éclopait',
    'éclopant',
    'éclopées',
    'éclopent',
    'éclopera',
    'éclopiez',
    'éclopons',
    'éclorais',
    'éclorait',
    'écloriez',
    'éclorons',
    'écloront',
    'éclosent',
    'éclosion',
    'éclusage',
    'éclusais',
    'éclusait',
    'éclusant',
    'éclusées',
    'éclusent',
    'éclusera',
    'éclusier',
    'éclusiez',
    'éclusons',
    'écobuage',
    'écobuais',
    'écobuait',
    'écobuant',
    'écobuées',
    'écobuent',
    'écobuera',
    'écobuiez',
    'écobuons',
    'écocides',
    'écoeurai',
    'écoeuras',
    'écoeurât',
    'écoeurée',
    'écoeurer',
    'écoeures',
    'écoeurés',
    'écoeurez',
    'écolages',
    'écolâtre',
    'écoliers',
    'écologie',
    'écomusée',
    'éconduis',
    'éconduit',
    'économat',
    'économes',
    'économie',
    'écopâmes',
    'écopasse',
    'écopâtes',
    'écoperai',
    'écoperas',
    'écoperez',
    'écopions',
    'écorâmes',
    'écorasse',
    'écorâtes',
    'écorçais',
    'écorçait',
    'écorçant',
    'écorcées',
    'écorcent',
    'écorcera',
    'écorceur',
    'écorchai',
    'écorchas',
    'écorchât',
    'écorchée',
    'écorcher',
    'écorches',
    'écorchés',
    'écorchez',
    'écorciez',
    'écorçons',
    'écorerai',
    'écoreras',
    'écorerez',
    'écorions',
    'écornais',
    'écornait',
    'écornant',
    'écornées',
    'écornent',
    'écornera',
    'écorniez',
    'écornons',
    'écornure',
    'écossais',
    'écossait',
    'écossant',
    'écossées',
    'écossent',
    'écossera',
    'écossiez',
    'écossons',
    'écotypes',
    'écoulais',
    'écoulait',
    'écoulant',
    'écoulées',
    'écoulent',
    'écoulera',
    'écouliez',
    'écoulons',
    'écoumène',
    'écourtai',
    'écourtas',
    'écourtât',
    'écourtée',
    'écourter',
    'écourtes',
    'écourtés',
    'écourtez',
    'écoutais',
    'écoutait',
    'écoutant',
    'écoutées',
    'écoutent',
    'écoutera',
    'écouteur',
    'écoutiez',
    'écoutons',
    'écrasais',
    'écrasait',
    'écrasant',
    'écrasées',
    'écrasent',
    'écrasera',
    'écraseur',
    'écrasiez',
    'écrasons',
    'écrémage',
    'écrémais',
    'écrémait',
    'écrémant',
    'écrémées',
    'écrèment',
    'écrémera',
    'écrémiez',
    'écrémons',
    'écrêtais',
    'écrêtait',
    'écrêtant',
    'écrêtées',
    'écrêtent',
    'écrêtera',
    'écrêtiez',
    'écrêtons',
    'écriâmes',
    'écriâtes',
    'écrierez',
    'écriions',
    'écrirais',
    'écrirait',
    'écririez',
    'écrirons',
    'écriront',
    'écriteau',
    'écriture',
    'écrivain',
    'écrivais',
    'écrivait',
    'écrivant',
    'écrivent',
    'écriviez',
    'écrivons',
    'écrouais',
    'écrouait',
    'écrouant',
    'écrouées',
    'écrouent',
    'écrouera',
    'écrouies',
    'écrouiez',
    'écrouira',
    'écroulée',
    'écrouler',
    'écroulés',
    'écroulez',
    'écrouons',
    'écroûtai',
    'écroûtas',
    'écroûtât',
    'écroûtée',
    'écroûter',
    'écroûtes',
    'écroûtés',
    'écroûtez',
    'ectasies',
    'ecthymas',
    'ectopies',
    'écubiers',
    'écuelles',
    'écuissai',
    'écuissas',
    'écuissât',
    'écuissée',
    'écuisser',
    'écuisses',
    'écuissés',
    'écuissez',
    'éculâmes',
    'éculasse',
    'éculâtes',
    'éculerai',
    'éculeras',
    'éculerez',
    'éculions',
    'écumages',
    'écumâmes',
    'écumante',
    'écumants',
    'écumasse',
    'écumâtes',
    'écumerai',
    'écumeras',
    'écumerez',
    'écumeurs',
    'écumeuse',
    'écumions',
    'écumoire',
    'écurâmes',
    'écurasse',
    'écurâtes',
    'écurerai',
    'écureras',
    'écurerez',
    'écureuil',
    'écurions',
    'écussons',
    'écuyères',
    'édénique',
    'édentais',
    'édentait',
    'édentant',
    'édentées',
    'édentent',
    'édentera',
    'édentiez',
    'édentons',
    'édictais',
    'édictait',
    'édictant',
    'édictées',
    'édictent',
    'édictera',
    'édictiez',
    'édictons',
    'édicules',
    'édifiais',
    'édifiait',
    'édifiant',
    'édifices',
    'édifiées',
    'édifient',
    'édifiera',
    'édifiiez',
    'édifions',
    'édilités',
    'éditable',
    'éditâmes',
    'éditasse',
    'éditâtes',
    'éditerai',
    'éditeras',
    'éditerez',
    'éditeurs',
    'éditions',
    'éditrice',
    'édredons',
    'éducable',
    'éducatif',
    'édulcora',
    'édulcore',
    'édulcoré',
    'éduquais',
    'éduquait',
    'éduquant',
    'éduquées',
    'éduquent',
    'éduquera',
    'éduquiez',
    'éduquons',
    'éfaufila',
    'éfaufile',
    'éfaufilé',
    'effaçais',
    'effaçait',
    'effaçant',
    'effacées',
    'effacent',
    'effacera',
    'effaciez',
    'effaçons',
    'effanais',
    'effanait',
    'effanant',
    'effanées',
    'effanent',
    'effanera',
    'effaniez',
    'effanons',
    'effanure',
    'effarais',
    'effarait',
    'effarant',
    'effarées',
    'effarent',
    'effarera',
    'effariez',
    'effarons',
    'effectif',
    'effectua',
    'effectue',
    'effectué',
    'effémina',
    'effémine',
    'efféminé',
    'effendis',
    'efférent',
    'efficace',
    'effigies',
    'effilage',
    'effilais',
    'effilait',
    'effilant',
    'effilées',
    'effilent',
    'effilera',
    'effiliez',
    'effilons',
    'effilure',
    'effleura',
    'effleure',
    'effleuré',
    'effleuri',
    'effluent',
    'effluvai',
    'effluvas',
    'effluvât',
    'effluver',
    'effluves',
    'effluvez',
    'effondra',
    'effondre',
    'effondré',
    'efforcée',
    'efforcer',
    'efforcés',
    'efforcez',
    'effraies',
    'effrange',
    'effrangé',
    'effrayai',
    'effrayas',
    'effrayât',
    'effrayée',
    'effrayer',
    'effrayes',
    'effrayés',
    'effrayez',
    'effrénée',
    'effrénés',
    'effritai',
    'effritas',
    'effritât',
    'effritée',
    'effriter',
    'effrites',
    'effrités',
    'effritez',
    'effronté',
    'effusion',
    'égaierai',
    'égaieras',
    'égaierez',
    'égaillée',
    'égailler',
    'égaillés',
    'égaillez',
    'égalable',
    'égalâmes',
    'égalasse',
    'égalâtes',
    'égalerai',
    'égaleras',
    'égalerez',
    'égalions',
    'égalisai',
    'égalisas',
    'égalisât',
    'égalisée',
    'égaliser',
    'égalises',
    'égalisés',
    'égalisez',
    'égalités',
    'égarâmes',
    'égarasse',
    'égarâtes',
    'égarerai',
    'égareras',
    'égarerez',
    'égarions',
    'égayâmes',
    'égayante',
    'égayants',
    'égayasse',
    'égayâtes',
    'égayerai',
    'égayeras',
    'égayerez',
    'égayions',
    'égéennes',
    'égermage',
    'égermais',
    'égermait',
    'égermant',
    'égermées',
    'égerment',
    'égermera',
    'égermiez',
    'égermons',
    'églefins',
    'églogues',
    'égoïsmes',
    'égoïstes',
    'égorgeai',
    'égorgeas',
    'égorgeât',
    'égorgées',
    'égorgent',
    'égorgera',
    'égorgeur',
    'égorgiez',
    'égosillé',
    'égotisme',
    'égotiste',
    'égoutier',
    'égouttai',
    'égouttas',
    'égouttât',
    'égouttée',
    'égoutter',
    'égouttes',
    'égouttés',
    'égouttez',
    'égrainai',
    'égrainas',
    'égrainât',
    'égrainée',
    'égrainer',
    'égraines',
    'égrainés',
    'égrainez',
    'égrappai',
    'égrappas',
    'égrappât',
    'égrappée',
    'égrapper',
    'égrappes',
    'égrappés',
    'égrappez',
    'égrenage',
    'égrenais',
    'égrenait',
    'égrenant',
    'égrenées',
    'égrènent',
    'égrènera',
    'égreniez',
    'égrenons',
    'égrisage',
    'égrisais',
    'égrisait',
    'égrisant',
    'égrisées',
    'égrisent',
    'égrisera',
    'égrisiez',
    'égrisons',
    'égrotant',
    'égrugeai',
    'égrugeas',
    'égrugeât',
    'égrugées',
    'égrugent',
    'égrugera',
    'égrugiez',
    'égyptien',
    'éhontées',
    'éjaculai',
    'éjaculas',
    'éjaculât',
    'éjaculée',
    'éjaculer',
    'éjacules',
    'éjaculés',
    'éjaculez',
    'éjectais',
    'éjectait',
    'éjectant',
    'éjectées',
    'éjectent',
    'éjectera',
    'éjecteur',
    'éjectiez',
    'éjection',
    'éjectons',
    'éjointai',
    'éjointas',
    'éjointât',
    'éjointée',
    'éjointer',
    'éjointes',
    'éjointés',
    'éjointez',
    'élaborai',
    'élaboras',
    'élaborât',
    'élaborée',
    'élaborer',
    'élabores',
    'élaborés',
    'élaborez',
    'élagages',
    'élaguais',
    'élaguait',
    'élaguant',
    'élaguées',
    'élaguent',
    'élaguera',
    'élagueur',
    'élaguiez',
    'élaguons',
    'élançais',
    'élançait',
    'élançant',
    'élancées',
    'élancent',
    'élancera',
    'élanciez',
    'élançons',
    'élargies',
    'élargira',
    'eldorado',
    'électeur',
    'électifs',
    'élection',
    'élective',
    'électron',
    'électrum',
    'élégance',
    'élégante',
    'élégants',
    'élégirai',
    'élégiras',
    'élégirez',
    'éléments',
    'éléphant',
    'élevages',
    'élevâmes',
    'élevasse',
    'élevâtes',
    'élèverai',
    'élèveras',
    'élèverez',
    'éleveurs',
    'éleveuse',
    'élevions',
    'élidâmes',
    'élidasse',
    'élidâtes',
    'éliderai',
    'élideras',
    'éliderez',
    'élidions',
    'éligible',
    'élimâmes',
    'élimasse',
    'élimâtes',
    'élimerai',
    'élimeras',
    'élimerez',
    'éliminai',
    'éliminas',
    'éliminât',
    'éliminée',
    'éliminer',
    'élimines',
    'éliminés',
    'éliminez',
    'élimions',
    'élinguai',
    'élinguas',
    'élinguât',
    'élinguée',
    'élinguer',
    'élingues',
    'élingués',
    'élinguez',
    'élirions',
    'élisions',
    'élitaire',
    'élitisme',
    'élitiste',
    'ellébore',
    'ellipses',
    'élogieux',
    'éloignai',
    'éloignas',
    'éloignât',
    'éloignée',
    'éloigner',
    'éloignes',
    'éloignés',
    'éloignez',
    'élongeai',
    'élongeas',
    'élongeât',
    'élongées',
    'élongent',
    'élongera',
    'élongiez',
    'éloquent',
    'élucidai',
    'élucidas',
    'élucidât',
    'élucidée',
    'élucider',
    'élucides',
    'élucidés',
    'élucidez',
    'élucubra',
    'élucubre',
    'élucubré',
    'éludâmes',
    'éludasse',
    'éludâtes',
    'éluderai',
    'éluderas',
    'éluderez',
    'éludions',
    'élusives',
    'élussent',
    'élussiez',
    'éluviale',
    'éluviaux',
    'éluvions',
    'élyséens',
    'émaciées',
    'émaciiez',
    'émacions',
    'émaillai',
    'émaillas',
    'émaillât',
    'émaillée',
    'émailler',
    'émailles',
    'émaillés',
    'émaillez',
    'émanâmes',
    'émanasse',
    'émanâtes',
    'émanches',
    'émancipa',
    'émancipe',
    'émancipé',
    'émanerai',
    'émaneras',
    'émanerez',
    'émanions',
    'émargeai',
    'émargeas',
    'émargeât',
    'émargées',
    'émargent',
    'émargera',
    'émargiez',
    'émascula',
    'émascule',
    'émasculé',
    'embâcles',
    'emballai',
    'emballas',
    'emballât',
    'emballée',
    'emballer',
    'emballes',
    'emballés',
    'emballez',
    'embardai',
    'embardas',
    'embardât',
    'embardée',
    'embarder',
    'embardes',
    'embardés',
    'embardez',
    'embargos',
    'embarqua',
    'embarque',
    'embarqué',
    'embarrai',
    'embarras',
    'embarrât',
    'embarrée',
    'embarrer',
    'embarres',
    'embarrés',
    'embarrez',
    'embattes',
    'embattez',
    'embattis',
    'embattit',
    'embattît',
    'embattra',
    'embattre',
    'embattue',
    'embattus',
    'embaucha',
    'embauche',
    'embauché',
    'embaumai',
    'embaumas',
    'embaumât',
    'embaumée',
    'embaumer',
    'embaumes',
    'embaumés',
    'embaumez',
    'embecqua',
    'embecque',
    'embecqué',
    'embellie',
    'embellir',
    'embellis',
    'embellit',
    'embellît',
    'embêtais',
    'embêtait',
    'embêtant',
    'embêtées',
    'embêtent',
    'embêtera',
    'embêtiez',
    'embêtons',
    'emblavai',
    'emblavas',
    'emblavât',
    'emblavée',
    'emblaver',
    'emblaves',
    'emblavés',
    'emblavez',
    'emblèmes',
    'embobina',
    'embobine',
    'embobiné',
    'emboirez',
    'emboîtai',
    'emboîtas',
    'emboîtât',
    'emboîtée',
    'emboîter',
    'emboîtes',
    'emboîtés',
    'emboîtez',
    'embolies',
    'embossai',
    'embossas',
    'embossât',
    'embossée',
    'embosser',
    'embosses',
    'embossés',
    'embossez',
    'embouais',
    'embouait',
    'embouant',
    'emboucha',
    'embouche',
    'embouché',
    'embouées',
    'embouent',
    'embouera',
    'embouiez',
    'embouons',
    'embouqua',
    'embouque',
    'embouqué',
    'embourba',
    'embourbe',
    'embourbé',
    'embourra',
    'embourre',
    'embourré',
    'emboutie',
    'emboutir',
    'emboutis',
    'emboutit',
    'emboutît',
    'embraies',
    'embraqua',
    'embraque',
    'embraqué',
    'embrasai',
    'embrasas',
    'embrasât',
    'embrasée',
    'embraser',
    'embrases',
    'embrasés',
    'embrasez',
    'embrassa',
    'embrasse',
    'embrassé',
    'embrayai',
    'embrayas',
    'embrayât',
    'embrayée',
    'embrayer',
    'embrayes',
    'embrayés',
    'embrayez',
    'embrelai',
    'embrelas',
    'embrelât',
    'embrelée',
    'embreler',
    'embrelés',
    'embrèles',
    'embrelez',
    'embrevai',
    'embrevas',
    'embrevât',
    'embrevée',
    'embrever',
    'embrevés',
    'embrèves',
    'embrevez',
    'embrocha',
    'embroche',
    'embroché',
    'embrumai',
    'embrumas',
    'embrumât',
    'embrumée',
    'embrumer',
    'embrumes',
    'embrumés',
    'embrumez',
    'embrunie',
    'embrunir',
    'embrunis',
    'embrunit',
    'embrunît',
    'embryons',
    'embuâmes',
    'embuasse',
    'embuâtes',
    'embûches',
    'embuerai',
    'embueras',
    'embuerez',
    'embuions',
    'embusqua',
    'embusque',
    'embusqué',
    'embuviez',
    'embuvons',
    'éméchais',
    'éméchait',
    'éméchant',
    'éméchées',
    'émèchent',
    'éméchera',
    'éméchiez',
    'éméchons',
    'émeraude',
    'émergeai',
    'émergeas',
    'émergeât',
    'émergent',
    'émergera',
    'émergiez',
    'émerisai',
    'émerisas',
    'émerisât',
    'émerisée',
    'émeriser',
    'émerises',
    'émerisés',
    'émerisez',
    'émérites',
    'émersion',
    'émétines',
    'émétique',
    'émettais',
    'émettait',
    'émettant',
    'émettent',
    'émetteur',
    'émettiez',
    'émettons',
    'émettrai',
    'émettras',
    'émettrez',
    'émeutier',
    'émeuvent',
    'émiaient',
    'émiasses',
    'émierais',
    'émierait',
    'émièrent',
    'émieriez',
    'émierons',
    'émieront',
    'émiettai',
    'émiettas',
    'émiettât',
    'émiettée',
    'émietter',
    'émiettes',
    'émiettés',
    'émiettez',
    'émigrais',
    'émigrait',
    'émigrant',
    'émigrées',
    'émigrent',
    'émigrera',
    'émigriez',
    'émigrons',
    'éminçais',
    'éminçait',
    'éminçant',
    'émincées',
    'émincent',
    'émincera',
    'éminciez',
    'éminçons',
    'éminence',
    'éminente',
    'éminents',
    'émissent',
    'émissiez',
    'émissifs',
    'émission',
    'émissive',
    'émissole',
    'emmancha',
    'emmanche',
    'emmanché',
    'emmargea',
    'emmargée',
    'emmarger',
    'emmarges',
    'emmargés',
    'emmargez',
    'emmêlais',
    'emmêlait',
    'emmêlant',
    'emmêlées',
    'emmêlent',
    'emmêlera',
    'emmêliez',
    'emmêlons',
    'emménage',
    'emménagé',
    'emmenais',
    'emmenait',
    'emmenant',
    'emmenées',
    'emmènent',
    'emmènera',
    'emmeniez',
    'emmenons',
    'emmental',
    'emmerdai',
    'emmerdas',
    'emmerdât',
    'emmerdée',
    'emmerder',
    'emmerdes',
    'emmerdés',
    'emmerdez',
    'emmétrai',
    'emmétras',
    'emmétrât',
    'emmétrée',
    'emmétrer',
    'emmétrés',
    'emmètres',
    'emmétrez',
    'emmiella',
    'emmielle',
    'emmiellé',
    'emmottée',
    'emmottés',
    'emmurais',
    'emmurait',
    'emmurant',
    'emmurées',
    'emmurent',
    'emmurera',
    'emmuriez',
    'emmurons',
    'émondage',
    'émondais',
    'émondait',
    'émondant',
    'émondées',
    'émondent',
    'émondera',
    'émondeur',
    'émondiez',
    'émondoir',
    'émondons',
    'émorfila',
    'émorfile',
    'émorfilé',
    'émotions',
    'émotives',
    'émottage',
    'émottais',
    'émottait',
    'émottant',
    'émottées',
    'émottent',
    'émottera',
    'émotteur',
    'émottiez',
    'émottons',
    'émouchai',
    'émouchas',
    'émouchât',
    'émouchée',
    'émoucher',
    'émouches',
    'émouchés',
    'émouchet',
    'émouchez',
    'émoudrai',
    'émoudras',
    'émoudrez',
    'émoulage',
    'émoulais',
    'émoulait',
    'émoulant',
    'émoulent',
    'émouleur',
    'émouliez',
    'émoulons',
    'émoulues',
    'émoussai',
    'émoussas',
    'émoussât',
    'émoussée',
    'émousser',
    'émousses',
    'émoussés',
    'émoussez',
    'émouvais',
    'émouvait',
    'émouvant',
    'émouviez',
    'émouvoir',
    'émouvons',
    'émouvrai',
    'émouvras',
    'émouvrez',
    'empailla',
    'empaille',
    'empaillé',
    'empalais',
    'empalait',
    'empalant',
    'empalées',
    'empalent',
    'empalera',
    'empaliez',
    'empalmai',
    'empalmas',
    'empalmât',
    'empalmée',
    'empalmer',
    'empalmes',
    'empalmés',
    'empalmez',
    'empalons',
    'empannai',
    'empannas',
    'empannât',
    'empannée',
    'empanner',
    'empannes',
    'empannés',
    'empannez',
    'emparées',
    'empariez',
    'emparons',
    'emparqua',
    'emparque',
    'emparqué',
    'empâtais',
    'empâtait',
    'empâtant',
    'empâtées',
    'empâtent',
    'empâtera',
    'empathie',
    'empâtiez',
    'empâtons',
    'empattai',
    'empattas',
    'empattât',
    'empattée',
    'empatter',
    'empattes',
    'empattés',
    'empattez',
    'empaumai',
    'empaumas',
    'empaumât',
    'empaumée',
    'empaumer',
    'empaumes',
    'empaumés',
    'empaumez',
    'empêchai',
    'empêchas',
    'empêchât',
    'empêchée',
    'empêcher',
    'empêches',
    'empêchés',
    'empêchez',
    'empeigna',
    'empeigne',
    'empeigné',
    'empênais',
    'empênait',
    'empênant',
    'empênées',
    'empênent',
    'empênera',
    'empêniez',
    'empennai',
    'empennas',
    'empennât',
    'empennée',
    'empenner',
    'empennes',
    'empennés',
    'empennez',
    'empênons',
    'empercha',
    'emperche',
    'emperché',
    'empereur',
    'emperlai',
    'emperlas',
    'emperlât',
    'emperlée',
    'emperler',
    'emperles',
    'emperlés',
    'emperlez',
    'empesage',
    'empesais',
    'empesait',
    'empesant',
    'empesées',
    'empèsent',
    'empèsera',
    'empesiez',
    'empesons',
    'empestai',
    'empestas',
    'empestât',
    'empestée',
    'empester',
    'empestes',
    'empestés',
    'empestez',
    'empêtrai',
    'empêtras',
    'empêtrât',
    'empêtrée',
    'empêtrer',
    'empêtres',
    'empêtrés',
    'empêtrez',
    'emphases',
    'empierra',
    'empierre',
    'empierré',
    'empiétai',
    'empiétas',
    'empiétât',
    'empiéter',
    'empiètes',
    'empiétez',
    'empiffré',
    'empilage',
    'empilais',
    'empilait',
    'empilant',
    'empilées',
    'empilent',
    'empilera',
    'empileur',
    'empiliez',
    'empilons',
    'empirais',
    'empirait',
    'empirant',
    'empirées',
    'empirent',
    'empirera',
    'empiriez',
    'empirons',
    'emplâtra',
    'emplâtre',
    'emplâtré',
    'emplette',
    'emplîmes',
    'emplirai',
    'empliras',
    'emplirez',
    'emplisse',
    'emplîtes',
    'emploies',
    'employai',
    'employas',
    'employât',
    'employée',
    'employer',
    'employés',
    'employez',
    'emplumai',
    'emplumas',
    'emplumât',
    'emplumée',
    'emplumer',
    'emplumes',
    'emplumés',
    'emplumez',
    'empochai',
    'empochas',
    'empochât',
    'empochée',
    'empocher',
    'empoches',
    'empochés',
    'empochez',
    'empoigna',
    'empoigne',
    'empoigné',
    'empoissa',
    'empoisse',
    'empoissé',
    'emporium',
    'emportai',
    'emportas',
    'emportât',
    'emportée',
    'emporter',
    'emportes',
    'emportés',
    'emportez',
    'empotais',
    'empotait',
    'empotant',
    'empotées',
    'empotent',
    'empotera',
    'empotiez',
    'empotons',
    'empreins',
    'empreint',
    'empressé',
    'emprises',
    'emprunta',
    'emprunte',
    'emprunté',
    'emprunts',
    'empuanti',
    'empyèmes',
    'empyrées',
    'émulâmes',
    'émulasse',
    'émulâtes',
    'émulerai',
    'émuleras',
    'émulerez',
    'émulions',
    'émulseur',
    'émulsifs',
    'émulsine',
    'émulsion',
    'émulsive',
    'émussent',
    'émussiez',
    'enamouré',
    'énamouré',
    'énarques',
    'en-avant',
    'encabana',
    'encabane',
    'encabané',
    'encadrai',
    'encadras',
    'encadrât',
    'encadrée',
    'encadrer',
    'encadres',
    'encadrés',
    'encadrez',
    'encageai',
    'encageas',
    'encageât',
    'encagées',
    'encagent',
    'encagera',
    'encagiez',
    'encaissa',
    'encaisse',
    'encaissé',
    'encaquai',
    'encaquas',
    'encaquât',
    'encaquée',
    'encaquer',
    'encaques',
    'encaqués',
    'encaquez',
    'encartai',
    'encartas',
    'encartât',
    'encartée',
    'encarter',
    'encartes',
    'encartés',
    'encartez',
    'encastra',
    'encastre',
    'encastré',
    'encavage',
    'encavais',
    'encavait',
    'encavant',
    'encavées',
    'encavent',
    'encavera',
    'encaviez',
    'encavons',
    'enceinte',
    'encensai',
    'encensas',
    'encensât',
    'encensée',
    'encenser',
    'encenses',
    'encensés',
    'encensez',
    'encercla',
    'encercle',
    'encerclé',
    'enchaîna',
    'enchaîne',
    'enchaîné',
    'enchanta',
    'enchante',
    'enchanté',
    'encharna',
    'encharne',
    'encharné',
    'enchâssa',
    'enchâsse',
    'enchâssé',
    'enchères',
    'enchérie',
    'enchérir',
    'enchéris',
    'enchérit',
    'enchérît',
    'encirais',
    'encirait',
    'encirant',
    'encirées',
    'encirent',
    'encirera',
    'enciriez',
    'encirons',
    'enclavai',
    'enclavas',
    'enclavât',
    'enclavée',
    'enclaver',
    'enclaves',
    'enclavés',
    'enclavez',
    'enclines',
    'enclorai',
    'encloras',
    'enclorez',
    'encloses',
    'enclosez',
    'enclouai',
    'enclouas',
    'enclouât',
    'enclouée',
    'enclouer',
    'encloues',
    'encloués',
    'enclouez',
    'enclumes',
    'encochai',
    'encochas',
    'encochât',
    'encochée',
    'encocher',
    'encoches',
    'encochés',
    'encochez',
    'encodage',
    'encodais',
    'encodait',
    'encodant',
    'encodées',
    'encodent',
    'encodera',
    'encodeur',
    'encodiez',
    'encodons',
    'encoffra',
    'encoffre',
    'encoffré',
    'encollai',
    'encollas',
    'encollât',
    'encollée',
    'encoller',
    'encolles',
    'encollés',
    'encollez',
    'encolure',
    'encombra',
    'encombre',
    'encombré',
    'encontre',
    'encordai',
    'encordas',
    'encordât',
    'encordée',
    'encorder',
    'encordes',
    'encordés',
    'encordez',
    'encornai',
    'encornas',
    'encornât',
    'encornée',
    'encorner',
    'encornes',
    'encornés',
    'encornet',
    'encornez',
    'encoures',
    'encourez',
    'encourir',
    'encourra',
    'en-cours',
    'encourue',
    'encourus',
    'encourut',
    'encourût',
    'encrages',
    'encrâmes',
    'encrassa',
    'encrasse',
    'encrassé',
    'encrâtes',
    'encrêpai',
    'encrêpas',
    'encrêpât',
    'encrêpée',
    'encrêper',
    'encrêpes',
    'encrêpés',
    'encrêpez',
    'encrerai',
    'encreras',
    'encrerez',
    'encreurs',
    'encriers',
    'encrines',
    'encrions',
    'encrouée',
    'encroués',
    'encroûta',
    'encroûte',
    'encroûté',
    'encuvage',
    'encuvais',
    'encuvait',
    'encuvant',
    'encuvées',
    'encuvent',
    'encuvera',
    'encuviez',
    'encuvons',
    'endaubai',
    'endaubas',
    'endaubât',
    'endaubée',
    'endauber',
    'endaubes',
    'endaubés',
    'endaubez',
    'endémies',
    'endentai',
    'endentas',
    'endentât',
    'endentée',
    'endenter',
    'endentes',
    'endentés',
    'endentez',
    'endettai',
    'endettas',
    'endettât',
    'endettée',
    'endetter',
    'endettes',
    'endettés',
    'endettez',
    'endiabla',
    'endiable',
    'endiablé',
    'endigage',
    'endiguai',
    'endiguas',
    'endiguât',
    'endiguée',
    'endiguer',
    'endigues',
    'endigués',
    'endiguez',
    'endogame',
    'endogène',
    'endolori',
    'endormes',
    'endormez',
    'endormie',
    'endormir',
    'endormis',
    'endormit',
    'endormît',
    'endossai',
    'endossas',
    'endossât',
    'endossée',
    'endosser',
    'endosses',
    'endossés',
    'endossez',
    'endroits',
    'enduirai',
    'enduiras',
    'enduirez',
    'enduises',
    'enduisez',
    'enduisis',
    'enduisit',
    'enduisît',
    'enduites',
    'endurais',
    'endurait',
    'endurant',
    'endurcie',
    'endurcir',
    'endurcis',
    'endurcit',
    'endurcît',
    'endurées',
    'endurent',
    'endurera',
    'enduriez',
    'endurons',
    'endymion',
    'énergies',
    'énervais',
    'énervait',
    'énervant',
    'énervées',
    'énervent',
    'énervera',
    'énerviez',
    'énervons',
    'enfaîtai',
    'enfaîtas',
    'enfaîtât',
    'enfaîtée',
    'enfaîter',
    'enfaîtes',
    'enfaîtés',
    'enfaîtez',
    'enfances',
    'enfantai',
    'enfantas',
    'enfantât',
    'enfantée',
    'enfanter',
    'enfantes',
    'enfantés',
    'enfantez',
    'enfantin',
    'enfarina',
    'enfarine',
    'enfariné',
    'enfermai',
    'enfermas',
    'enfermât',
    'enfermée',
    'enfermer',
    'enfermes',
    'enfermés',
    'enfermez',
    'enferrai',
    'enferras',
    'enferrât',
    'enferrée',
    'enferrer',
    'enferres',
    'enferrés',
    'enferrez',
    'enfichai',
    'enfichas',
    'enfichât',
    'enfichée',
    'enficher',
    'enfiches',
    'enfichés',
    'enfichez',
    'enfiella',
    'enfielle',
    'enfiellé',
    'enfiévra',
    'enfiévré',
    'enfièvre',
    'enfilade',
    'enfilage',
    'enfilais',
    'enfilait',
    'enfilant',
    'enfilées',
    'enfilent',
    'enfilera',
    'enfileur',
    'enfiliez',
    'enfilons',
    'enflâmes',
    'enflamma',
    'enflamme',
    'enflammé',
    'enflasse',
    'enflâtes',
    'enflécha',
    'enfléché',
    'enflèche',
    'enflerai',
    'enfleras',
    'enflerez',
    'enfleura',
    'enfleure',
    'enfleuré',
    'enflions',
    'enflures',
    'enfoirée',
    'enfoirés',
    'enfonçai',
    'enfonças',
    'enfonçât',
    'enfoncée',
    'enfoncer',
    'enfonces',
    'enfoncés',
    'enfoncez',
    'enforcir',
    'enforcis',
    'enforcit',
    'enforcît',
    'enfouies',
    'enfouira',
    'enfourna',
    'enfourne',
    'enfourné',
    'enfreins',
    'enfreint',
    'enfuîmes',
    'enfuirez',
    'enfuîtes',
    'enfumage',
    'enfumais',
    'enfumait',
    'enfumant',
    'enfumées',
    'enfument',
    'enfumera',
    'enfumiez',
    'enfumons',
    'enfûtage',
    'enfûtais',
    'enfûtait',
    'enfûtant',
    'enfûtées',
    'enfûtent',
    'enfûtera',
    'enfûtiez',
    'enfûtons',
    'enfuyiez',
    'enfuyons',
    'engageai',
    'engageas',
    'engageât',
    'engagées',
    'engagent',
    'engagera',
    'engagiez',
    'engainai',
    'engainas',
    'engainât',
    'engainée',
    'engainer',
    'engaines',
    'engainés',
    'engainez',
    'engamais',
    'engamait',
    'engamant',
    'engamées',
    'engament',
    'engamera',
    'engamiez',
    'engamons',
    'engavais',
    'engavait',
    'engavant',
    'engavées',
    'engavent',
    'engavera',
    'engaviez',
    'engavons',
    'engeance',
    'engelure',
    'engendra',
    'engendre',
    'engendré',
    'engerbai',
    'engerbas',
    'engerbât',
    'engerbée',
    'engerber',
    'engerbes',
    'engerbés',
    'engerbez',
    'englaçai',
    'englaças',
    'englaçât',
    'englacée',
    'englacer',
    'englaces',
    'englacés',
    'englacez',
    'englobai',
    'englobas',
    'englobât',
    'englobée',
    'englober',
    'englobes',
    'englobés',
    'englobez',
    'englouti',
    'engluage',
    'engluais',
    'engluait',
    'engluant',
    'engluées',
    'engluent',
    'engluera',
    'engluiez',
    'engluons',
    'engobage',
    'engobais',
    'engobait',
    'engobant',
    'engobées',
    'engobent',
    'engobera',
    'engobiez',
    'engobons',
    'engommai',
    'engommas',
    'engommât',
    'engommée',
    'engommer',
    'engommes',
    'engommés',
    'engommez',
    'engonçai',
    'engonças',
    'engonçât',
    'engoncée',
    'engoncer',
    'engonces',
    'engoncés',
    'engoncez',
    'engorgea',
    'engorgée',
    'engorger',
    'engorges',
    'engorgés',
    'engorgez',
    'engouées',
    'engouiez',
    'engoulai',
    'engoulas',
    'engoulât',
    'engoulée',
    'engouler',
    'engoules',
    'engoulés',
    'engoulez',
    'engouons',
    'engourdi',
    'engramme',
    'engrange',
    'engrangé',
    'engravai',
    'engravas',
    'engravât',
    'engravée',
    'engraver',
    'engraves',
    'engravés',
    'engravez',
    'engrenai',
    'engrenas',
    'engrenât',
    'engrenée',
    'engrener',
    'engrenés',
    'engrènes',
    'engrenez',
    'engrossa',
    'engrosse',
    'engrossé',
    'engueula',
    'engueule',
    'engueulé',
    'enhardie',
    'enhardir',
    'enhardis',
    'enhardit',
    'enhardît',
    'enherbai',
    'enherbas',
    'enherbât',
    'enherbée',
    'enherber',
    'enherbes',
    'enherbés',
    'enherbez',
    'éniellai',
    'éniellas',
    'éniellât',
    'éniellée',
    'énieller',
    'énielles',
    'éniellés',
    'éniellez',
    'enivrais',
    'enivrait',
    'enivrant',
    'enivrées',
    'enivrent',
    'enivrera',
    'enivriez',
    'enivrons',
    'enjambai',
    'enjambas',
    'enjambât',
    'enjambée',
    'enjamber',
    'enjambes',
    'enjambés',
    'enjambez',
    'enjavela',
    'enjavelé',
    'enjoigne',
    'enjointe',
    'enjoints',
    'enjôlais',
    'enjôlait',
    'enjôlant',
    'enjôlées',
    'enjôlent',
    'enjôlera',
    'enjôleur',
    'enjôliez',
    'enjoliva',
    'enjolive',
    'enjolivé',
    'enjôlons',
    'enjonçai',
    'enjonças',
    'enjonçât',
    'enjoncée',
    'enjoncer',
    'enjonces',
    'enjoncés',
    'enjoncez',
    'enjouais',
    'enjouait',
    'enjouant',
    'enjouées',
    'enjouent',
    'enjouera',
    'enjouiez',
    'enjouons',
    'enjuguai',
    'enjuguas',
    'enjuguât',
    'enjuguée',
    'enjuguer',
    'enjugues',
    'enjugués',
    'enjuguez',
    'enjuivai',
    'enjuivas',
    'enjuivât',
    'enjuivée',
    'enjuiver',
    'enjuives',
    'enjuivés',
    'enjuivez',
    'enkystée',
    'enkyster',
    'enkystés',
    'enkystez',
    'enlaçais',
    'enlaçait',
    'enlaçant',
    'enlacées',
    'enlacent',
    'enlacera',
    'enlaciez',
    'enlaçons',
    'enlaidie',
    'enlaidir',
    'enlaidis',
    'enlaidit',
    'enlaidît',
    'enlevage',
    'enlevais',
    'enlevait',
    'enlevant',
    'enlevées',
    'enlèvent',
    'enlèvera',
    'enleviez',
    'enlevons',
    'enlevure',
    'enliâmes',
    'enliassa',
    'enliasse',
    'enliassé',
    'enliâtes',
    'enlierai',
    'enlieras',
    'enlierez',
    'enlignai',
    'enlignas',
    'enlignât',
    'enlignée',
    'enligner',
    'enlignes',
    'enlignés',
    'enlignez',
    'enliions',
    'enlisais',
    'enlisait',
    'enlisant',
    'enlisées',
    'enlisent',
    'enlisera',
    'enlisiez',
    'enlisons',
    'enlumina',
    'enlumine',
    'enluminé',
    'ennéades',
    'enneigea',
    'enneigée',
    'enneiger',
    'enneiges',
    'enneigés',
    'enneigez',
    'ennemies',
    'ennoblie',
    'ennoblir',
    'ennoblis',
    'ennoblit',
    'ennoblît',
    'ennuagea',
    'ennuagée',
    'ennuager',
    'ennuages',
    'ennuagés',
    'ennuagez',
    'ennuient',
    'ennuiera',
    'ennuyais',
    'ennuyait',
    'ennuyant',
    'ennuyées',
    'ennuyeux',
    'ennuyiez',
    'ennuyons',
    'énonçais',
    'énonçait',
    'énonçant',
    'énoncées',
    'énoncent',
    'énoncera',
    'énonciez',
    'énonçons',
    'énormité',
    'énostose',
    'énouâmes',
    'énouasse',
    'énouâtes',
    'énouerai',
    'énoueras',
    'énouerez',
    'énouions',
    'enquérez',
    'enquérir',
    'enquêtai',
    'enquêtas',
    'enquêtât',
    'enquêter',
    'enquêtes',
    'enquêtez',
    'enquîmes',
    'enquises',
    'enquîtes',
    'enracina',
    'enracine',
    'enraciné',
    'enrageai',
    'enrageas',
    'enrageât',
    'enragées',
    'enragent',
    'enragera',
    'enragiez',
    'enraient',
    'enraiera',
    'enrailla',
    'enraille',
    'enraillé',
    'enraquai',
    'enraquas',
    'enraquât',
    'enrayage',
    'enrayais',
    'enrayait',
    'enrayant',
    'enrayées',
    'enrayent',
    'enrayera',
    'enrayiez',
    'enrayoir',
    'enrayons',
    'enrayure',
    'enrênais',
    'enrênait',
    'enrênant',
    'enrênées',
    'enrênent',
    'enrênera',
    'enrêniez',
    'enrênons',
    'enrhumai',
    'enrhumas',
    'enrhumât',
    'enrhumée',
    'enrhumer',
    'enrhumes',
    'enrhumés',
    'enrhumez',
    'enrichie',
    'enrichir',
    'enrichis',
    'enrichit',
    'enrichît',
    'enrobage',
    'enrobais',
    'enrobait',
    'enrobant',
    'enrobées',
    'enrobent',
    'enrobera',
    'enrobiez',
    'enrobons',
    'enrochai',
    'enrochas',
    'enrochât',
    'enrochée',
    'enrocher',
    'enroches',
    'enrochés',
    'enrochez',
    'enrôlais',
    'enrôlait',
    'enrôlant',
    'enrôlées',
    'enrôlent',
    'enrôlera',
    'enrôleur',
    'enrôliez',
    'enrôlons',
    'enrouais',
    'enrouait',
    'enrouant',
    'enrouées',
    'enrouent',
    'enrouera',
    'enrouiez',
    'enroulai',
    'enroulas',
    'enroulât',
    'enroulée',
    'enrouler',
    'enroules',
    'enroulés',
    'enroulez',
    'enrouons',
    'ensablai',
    'ensablas',
    'ensablât',
    'ensablée',
    'ensabler',
    'ensables',
    'ensablés',
    'ensablez',
    'ensabota',
    'ensabote',
    'ensaboté',
    'ensachai',
    'ensachas',
    'ensachât',
    'ensachée',
    'ensacher',
    'ensaches',
    'ensachés',
    'ensachez',
    'ensauvée',
    'ensauver',
    'ensauvés',
    'ensauvez',
    'enseigna',
    'enseigne',
    'enseigné',
    'ensemble',
    'enserrai',
    'enserras',
    'enserrât',
    'enserrée',
    'enserrer',
    'enserres',
    'enserrés',
    'enserrez',
    'enseveli',
    'ensilage',
    'ensilais',
    'ensilait',
    'ensilant',
    'ensilées',
    'ensilent',
    'ensilera',
    'ensiliez',
    'ensilons',
    'ensoufra',
    'ensoufre',
    'ensoufré',
    'ensouple',
    'enstérai',
    'enstéras',
    'enstérât',
    'enstérée',
    'enstérer',
    'enstérés',
    'enstères',
    'enstérez',
    'ensuivie',
    'ensuivis',
    'entablai',
    'entablas',
    'entablât',
    'entablée',
    'entabler',
    'entables',
    'entablés',
    'entablez',
    'entachai',
    'entachas',
    'entachât',
    'entachée',
    'entacher',
    'entaches',
    'entachés',
    'entachez',
    'entaient',
    'entailla',
    'entaille',
    'entaillé',
    'entamais',
    'entamait',
    'entamant',
    'entamées',
    'entament',
    'entamera',
    'entamiez',
    'entamons',
    'entaquée',
    'entaquer',
    'entaques',
    'entaqués',
    'entaquez',
    'entartra',
    'entartre',
    'entartré',
    'entassai',
    'entassas',
    'entassât',
    'entassée',
    'entasser',
    'entasses',
    'entassés',
    'entassez',
    'entendes',
    'entendez',
    'entendis',
    'entendit',
    'entendît',
    'entendra',
    'entendre',
    'entendue',
    'entendus',
    'ententes',
    'enterais',
    'enterait',
    'entèrent',
    'enteriez',
    'entérina',
    'entérine',
    'entériné',
    'entérite',
    'enterons',
    'enteront',
    'enterrai',
    'enterras',
    'enterrât',
    'enterrée',
    'enterrer',
    'enterres',
    'enterrés',
    'enterrez',
    'entêtais',
    'entêtait',
    'entêtant',
    'entêtées',
    'entêtent',
    'entêtera',
    'en-têtes',
    'entêtiez',
    'entêtons',
    'entichée',
    'enticher',
    'entichés',
    'entichez',
    'entières',
    'entoilai',
    'entoilas',
    'entoilât',
    'entoilée',
    'entoiler',
    'entoiles',
    'entoilés',
    'entoilez',
    'entôlage',
    'entôlais',
    'entôlait',
    'entôlant',
    'entôlées',
    'entôlent',
    'entôlera',
    'entôliez',
    'entolome',
    'entôlons',
    'entonnai',
    'entonnas',
    'entonnât',
    'entonnée',
    'entonner',
    'entonnes',
    'entonnés',
    'entonnez',
    'entorses',
    'entourai',
    'entouras',
    'entourât',
    'entourée',
    'entourer',
    'entoures',
    'entourés',
    'entourez',
    'entracte',
    'entraide',
    'entraidé',
    'entraîna',
    'entraîne',
    'entraîné',
    'entrains',
    'entrâmes',
    'entrante',
    'entrants',
    'entrasse',
    'entrâtes',
    'entravai',
    'entravas',
    'entravât',
    'entravée',
    'entraver',
    'entraves',
    'entravés',
    'entravez',
    'entrefer',
    'entremet',
    'entremis',
    'entremit',
    'entremît',
    'entrepôt',
    'entrerai',
    'entreras',
    'entrerez',
    'entresol',
    'entrevis',
    'entrevit',
    'entrevît',
    'entrevue',
    'entrevus',
    'entrions',
    'entrisme',
    'entropie',
    'entroque',
    'entubais',
    'entubait',
    'entubant',
    'entubées',
    'entubent',
    'entubera',
    'entubiez',
    'entubons',
    'énucléai',
    'énucléas',
    'énucléât',
    'énucléée',
    'énucléer',
    'énuclées',
    'énucléés',
    'énucléez',
    'énumérai',
    'énuméras',
    'énumérât',
    'énumérée',
    'énumérer',
    'énumérés',
    'énumères',
    'énumérez',
    'énurésie',
    'envahies',
    'envahira',
    'envasais',
    'envasait',
    'envasant',
    'envasées',
    'envasent',
    'envasera',
    'envasiez',
    'envasons',
    'envenima',
    'envenime',
    'envenimé',
    'envergea',
    'envergée',
    'enverger',
    'enverges',
    'envergés',
    'envergez',
    'envergua',
    'envergue',
    'envergué',
    'enverrai',
    'enverras',
    'enverrez',
    'enviable',
    'enviâmes',
    'enviasse',
    'enviâtes',
    'envidais',
    'envidait',
    'envidant',
    'envidées',
    'envident',
    'envidera',
    'envidiez',
    'envidons',
    'envierai',
    'envieras',
    'envierez',
    'envieuse',
    'enviions',
    'envinées',
    'environs',
    'envisage',
    'envisagé',
    'envoient',
    'envoilée',
    'envoiler',
    'envoilés',
    'envoilez',
    'envolées',
    'envoliez',
    'envolons',
    'envoûtai',
    'envoûtas',
    'envoûtât',
    'envoûtée',
    'envoûter',
    'envoûtes',
    'envoûtés',
    'envoûtez',
    'envoyais',
    'envoyait',
    'envoyant',
    'envoyées',
    'envoyeur',
    'envoyiez',
    'envoyons',
    'enzootie',
    'éolienne',
    'éolipile',
    'éolithes',
    'épagneul',
    'épaisses',
    'épaissie',
    'épaissir',
    'épaissis',
    'épaissit',
    'épaissît',
    'épalâmes',
    'épalasse',
    'épalâtes',
    'épalerai',
    'épaleras',
    'épalerez',
    'épalions',
    'épamprai',
    'épampras',
    'épamprât',
    'épamprée',
    'épamprer',
    'épampres',
    'épamprés',
    'épamprez',
    'épanchai',
    'épanchas',
    'épanchât',
    'épanchée',
    'épancher',
    'épanches',
    'épanchés',
    'épanchez',
    'épandage',
    'épandais',
    'épandait',
    'épandant',
    'épandent',
    'épandeur',
    'épandiez',
    'épandons',
    'épandrai',
    'épandras',
    'épandrez',
    'épandues',
    'épannais',
    'épannait',
    'épannant',
    'épannées',
    'épannela',
    'épannelé',
    'épannent',
    'épannera',
    'épanniez',
    'épannons',
    'épanouie',
    'épanouir',
    'épanouis',
    'épanouit',
    'épanouît',
    'éparchie',
    'épargnai',
    'épargnas',
    'épargnât',
    'épargnée',
    'épargner',
    'épargnes',
    'épargnés',
    'épargnez',
    'éparques',
    'épatâmes',
    'épatante',
    'épatants',
    'épatasse',
    'épatâtes',
    'épaterai',
    'épateras',
    'épaterez',
    'épateurs',
    'épateuse',
    'épations',
    'épaufrai',
    'épaufras',
    'épaufrât',
    'épaufrée',
    'épaufrer',
    'épaufres',
    'épaufrés',
    'épaufrez',
    'épaulais',
    'épaulait',
    'épaulant',
    'épaulard',
    'épaulées',
    'épaulent',
    'épaulera',
    'épauliez',
    'épaulons',
    'épeautre',
    'épeiches',
    'épéismes',
    'épéistes',
    'épelâmes',
    'épelasse',
    'épelâtes',
    'épelions',
    'épellent',
    'épellera',
    'épendyme',
    'épépinai',
    'épépinas',
    'épépinât',
    'épépinée',
    'épépiner',
    'épépines',
    'épépinés',
    'épépinez',
    'éperdiez',
    'éperdons',
    'éperdrez',
    'éperdues',
    'éperlans',
    'éperonna',
    'éperonne',
    'éperonné',
    'épervier',
    'épervins',
    'épeulais',
    'épeulait',
    'épeulant',
    'épeulées',
    'épeulent',
    'épeulera',
    'épeuliez',
    'épeulons',
    'épeurais',
    'épeurait',
    'épeurant',
    'épeurées',
    'épeurent',
    'épeurera',
    'épeuriez',
    'épeurons',
    'éphémère',
    'épiaient',
    'épiaires',
    'épiaison',
    'épiasses',
    'épiçâmes',
    'épicarpe',
    'épiçasse',
    'épiçâtes',
    'épicènes',
    'épicerai',
    'épiceras',
    'épicerez',
    'épicerie',
    'épicière',
    'épiciers',
    'épicions',
    'épicycle',
    'épidémie',
    'épiderme',
    'épierais',
    'épierait',
    'épièrent',
    'épieriez',
    'épierons',
    'épieront',
    'épierrai',
    'épierras',
    'épierrât',
    'épierrée',
    'épierrer',
    'épierres',
    'épierrés',
    'épierrez',
    'épieuses',
    'épigones',
    'épigynes',
    'épilâmes',
    'épilasse',
    'épilâtes',
    'épilerai',
    'épileras',
    'épilerez',
    'épilions',
    'épillets',
    'épilobes',
    'épilogua',
    'épilogue',
    'épilogué',
    'épinâmes',
    'épinards',
    'épinasse',
    'épinâtes',
    'épinçais',
    'épinçait',
    'épinçant',
    'épincées',
    'épincela',
    'épincelé',
    'épincèle',
    'épincent',
    'épincera',
    'épinceta',
    'épinceté',
    'épincète',
    'épinciez',
    'épinçons',
    'épinerai',
    'épineras',
    'épinerez',
    'épinette',
    'épineuse',
    'épinglai',
    'épinglas',
    'épinglât',
    'épinglée',
    'épingler',
    'épingles',
    'épinglés',
    'épinglez',
    'épinière',
    'épiniers',
    'épinions',
    'épinoche',
    'épiphane',
    'épiphyse',
    'épiphyte',
    'épiploon',
    'épiscope',
    'épisodes',
    'épissais',
    'épissait',
    'épissant',
    'épissées',
    'épissent',
    'épissera',
    'épissiez',
    'épissoir',
    'épissons',
    'épissure',
    'épistyle',
    'épitaphe',
    'épithème',
    'épithète',
    'épitoges',
    'épitomés',
    'éploient',
    'éploiera',
    'éplorées',
    'éployais',
    'éployait',
    'éployant',
    'éployées',
    'éployiez',
    'éployons',
    'épluchai',
    'épluchas',
    'épluchât',
    'épluchée',
    'éplucher',
    'épluches',
    'épluchés',
    'épluchez',
    'épointai',
    'épointas',
    'épointât',
    'épointée',
    'épointer',
    'épointes',
    'épointés',
    'épointez',
    'épongeai',
    'épongeas',
    'épongeât',
    'épongées',
    'épongent',
    'épongera',
    'épongiez',
    'éponymes',
    'éponymie',
    'épouilla',
    'épouille',
    'épouillé',
    'époumona',
    'époumone',
    'époumoné',
    'épousais',
    'épousait',
    'épousant',
    'épousées',
    'épousent',
    'épousera',
    'épouseur',
    'épousiez',
    'épousons',
    'époutiai',
    'époutias',
    'époutiât',
    'époutiée',
    'époutier',
    'épouties',
    'époutiés',
    'époutiez',
    'époutira',
    'épreigne',
    'épreinte',
    'épreints',
    'éprendre',
    'épreniez',
    'éprenons',
    'épreuves',
    'éprirent',
    'éprouvai',
    'éprouvas',
    'éprouvât',
    'éprouvée',
    'éprouver',
    'éprouves',
    'éprouvés',
    'éprouvez',
    'epsomite',
    'épuçâmes',
    'épuçasse',
    'épuçâtes',
    'épucerai',
    'épuceras',
    'épucerez',
    'épucions',
    'épuisais',
    'épuisait',
    'épuisant',
    'épuisées',
    'épuisent',
    'épuisera',
    'épuisiez',
    'épuisons',
    'épulides',
    'épulpeur',
    'épurâmes',
    'épurasse',
    'épurâtes',
    'épuratif',
    'épurerai',
    'épureras',
    'épurerez',
    'épurions',
    'épyornis',
    'équarrie',
    'équarrir',
    'équarris',
    'équarrit',
    'équarrît',
    'équateur',
    'équation',
    'équerrai',
    'équerras',
    'équerrât',
    'équerrée',
    'équerrer',
    'équerres',
    'équerrés',
    'équerrez',
    'équestre',
    'équeutai',
    'équeutas',
    'équeutât',
    'équeutée',
    'équeuter',
    'équeutes',
    'équeutés',
    'équeutez',
    'équilles',
    'équinoxe',
    'équipage',
    'équipais',
    'équipait',
    'équipant',
    'équipées',
    'équipent',
    'équipera',
    'équipier',
    'équipiez',
    'équipolé',
    'équipons',
    'équitant',
    'équivalu',
    'équivaut',
    'équivaux',
    'éradiqua',
    'éradique',
    'éradiqué',
    'éraflais',
    'éraflait',
    'éraflant',
    'éraflées',
    'éraflent',
    'éraflera',
    'érafliez',
    'éraflons',
    'éraflure',
    'éraierai',
    'éraieras',
    'éraierez',
    'éraillai',
    'éraillas',
    'éraillât',
    'éraillée',
    'érailler',
    'érailles',
    'éraillés',
    'éraillez',
    'érayâmes',
    'érayasse',
    'érayâtes',
    'érayerai',
    'érayeras',
    'érayerez',
    'érayions',
    'érecteur',
    'érectile',
    'érection',
    'éreintai',
    'éreintas',
    'éreintât',
    'éreintée',
    'éreinter',
    'éreintes',
    'éreintés',
    'éreintez',
    'érepsine',
    'ergonome',
    'ergotage',
    'ergotais',
    'ergotait',
    'ergotant',
    'ergotent',
    'ergotera',
    'ergoteur',
    'ergotiez',
    'ergotine',
    'ergotons',
    'érigeais',
    'érigeait',
    'érigeant',
    'érigeons',
    'érigerai',
    'érigeras',
    'érigerez',
    'érigéron',
    'érigions',
    'éristale',
    'ermitage',
    'érodâmes',
    'érodasse',
    'érodâtes',
    'éroderai',
    'éroderas',
    'éroderez',
    'érodions',
    'érogènes',
    'érosions',
    'érosives',
    'érotique',
    'érotisai',
    'érotisas',
    'érotisât',
    'érotisée',
    'érotiser',
    'érotises',
    'érotisés',
    'érotisez',
    'érotisme',
    'erraient',
    'errances',
    'errantes',
    'errasses',
    'errerais',
    'errerait',
    'errèrent',
    'erreriez',
    'errerons',
    'erreront',
    'erronées',
    'éructais',
    'éructait',
    'éructant',
    'éructées',
    'éructent',
    'éructera',
    'éructiez',
    'éructons',
    'érudites',
    'éruptifs',
    'éruption',
    'éruptive',
    'érythème',
    'esbaudie',
    'esbaudir',
    'esbaudis',
    'esbignée',
    'esbigner',
    'esbignés',
    'esbignez',
    'esbroufa',
    'esbroufe',
    'esbroufé',
    'escabeau',
    'escadres',
    'escadron',
    'escalada',
    'escalade',
    'escaladé',
    'escalier',
    'escalope',
    'escamota',
    'escamote',
    'escamoté',
    'escapade',
    'escarbot',
    'escargot',
    'escarole',
    'escarpée',
    'escarpés',
    'escarpin',
    'escarres',
    'eschâmes',
    'eschasse',
    'eschâtes',
    'escherai',
    'escheras',
    'escherez',
    'eschions',
    'escients',
    'esclaffé',
    'esclaves',
    'esclavon',
    'escobars',
    'escompta',
    'escompte',
    'escompté',
    'escortai',
    'escortas',
    'escortât',
    'escortée',
    'escorter',
    'escortes',
    'escortés',
    'escortez',
    'escouade',
    'escrimée',
    'escrimer',
    'escrimes',
    'escrimés',
    'escrimez',
    'escroqua',
    'escroque',
    'escroqué',
    'esculape',
    'esculine',
    'ésérines',
    'esgourde',
    'espaçais',
    'espaçait',
    'espaçant',
    'espacées',
    'espacent',
    'espacera',
    'espaciez',
    'espaçons',
    'espadons',
    'espagnol',
    'espalier',
    'esparcet',
    'espérais',
    'espérait',
    'espérant',
    'espérées',
    'espèrent',
    'espérera',
    'espériez',
    'espérons',
    'espiègle',
    'espionna',
    'espionne',
    'espionné',
    'espolina',
    'espoline',
    'espoliné',
    'esponton',
    'esquarre',
    'esquille',
    'Esquimau',
    'esquinta',
    'esquinte',
    'esquinté',
    'esquires',
    'esquissa',
    'esquisse',
    'esquissé',
    'esquivai',
    'esquivas',
    'esquivât',
    'esquivée',
    'esquiver',
    'esquives',
    'esquivés',
    'esquivez',
    'essaient',
    'essaiera',
    'essaimai',
    'essaimas',
    'essaimât',
    'essaimer',
    'essaimes',
    'essaimez',
    'essangea',
    'essangée',
    'essanger',
    'essanges',
    'essangés',
    'essangez',
    'essartai',
    'essartas',
    'essartât',
    'essartée',
    'essarter',
    'essartes',
    'essartés',
    'essartez',
    'essayage',
    'essayais',
    'essayait',
    'essayant',
    'essayées',
    'essayent',
    'essayera',
    'essayeur',
    'essayiez',
    'essayons',
    'essences',
    'esseulée',
    'esseulés',
    'essorage',
    'essorais',
    'essorait',
    'essorant',
    'essorées',
    'essorent',
    'essorera',
    'essoriez',
    'essorons',
    'essoucha',
    'essouche',
    'essouché',
    'essuient',
    'essuiera',
    'essuyage',
    'essuyais',
    'essuyait',
    'essuyant',
    'essuyées',
    'essuyeur',
    'essuyiez',
    'essuyons',
    'estacade',
    'estafier',
    'estagnon',
    'estampai',
    'estampas',
    'estampât',
    'estampée',
    'estamper',
    'estampes',
    'estampés',
    'estampez',
    'estancia',
    'estaries',
    'est-elle',
    'esterlin',
    'esthésie',
    'esthètes',
    'estimais',
    'estimait',
    'estimant',
    'estimées',
    'estiment',
    'estimera',
    'estimiez',
    'estimons',
    'estivage',
    'estivais',
    'estivait',
    'estivale',
    'estivant',
    'estivaux',
    'estivées',
    'estivent',
    'estivera',
    'estiviez',
    'estivons',
    'estocade',
    'estomacs',
    'estompai',
    'estompas',
    'estompât',
    'estompée',
    'estomper',
    'estompes',
    'estompés',
    'estompez',
    'estonien',
    'estoquai',
    'estoquas',
    'estoquât',
    'estoquée',
    'estoquer',
    'estoques',
    'estoqués',
    'estoquez',
    'estourbi',
    'estrades',
    'estragon',
    'estropes',
    'estropia',
    'estropie',
    'estropié',
    'estuaire',
    'établais',
    'établait',
    'établant',
    'établées',
    'établent',
    'établera',
    'établies',
    'établiez',
    'établira',
    'établons',
    'étageais',
    'étageait',
    'étageant',
    'étageons',
    'étagerai',
    'étageras',
    'étagères',
    'étagerez',
    'étagions',
    'étaierai',
    'étaieras',
    'étaierez',
    'étais-je',
    'étais-tu',
    'était-ce',
    'était-il',
    'était-on',
    'étalagea',
    'étalagée',
    'étalager',
    'étalages',
    'étalagés',
    'étalagez',
    'étalâmes',
    'étalasse',
    'étalâtes',
    'étalerai',
    'étaleras',
    'étalerez',
    'étaleuse',
    'étalière',
    'étaliers',
    'étalions',
    'étalisai',
    'étalisas',
    'étalisât',
    'étaliser',
    'étalises',
    'étalisez',
    'étalonna',
    'étalonne',
    'étalonné',
    'étamages',
    'étamâmes',
    'étamasse',
    'étamâtes',
    'étambots',
    'étambrai',
    'étamerai',
    'étameras',
    'étamerez',
    'étameurs',
    'étamines',
    'étamions',
    'étampage',
    'étampais',
    'étampait',
    'étampant',
    'étampées',
    'étampent',
    'étampera',
    'étampeur',
    'étampiez',
    'étampons',
    'étampure',
    'étamures',
    'étanchai',
    'étanchas',
    'étanchât',
    'étanchée',
    'étancher',
    'étanches',
    'étanchés',
    'étanchez',
    'étançons',
    'étarquai',
    'étarquas',
    'étarquât',
    'étarquée',
    'étarquer',
    'étarques',
    'étarqués',
    'étarquez',
    'étatique',
    'étatisme',
    'étatiste',
    'étayages',
    'étayâmes',
    'étayasse',
    'étayâtes',
    'étayerai',
    'étayeras',
    'étayerez',
    'étayions',
    'éteignes',
    'éteignez',
    'éteignis',
    'éteignit',
    'éteignît',
    'éteindra',
    'éteindre',
    'éteintes',
    'étendage',
    'étendais',
    'étendait',
    'étendant',
    'étendard',
    'étendent',
    'étendiez',
    'étendoir',
    'étendons',
    'étendrai',
    'étendras',
    'étendrez',
    'étendues',
    'éternels',
    'éternisa',
    'éternise',
    'éternisé',
    'éternité',
    'éternuai',
    'éternuas',
    'éternuât',
    'éternuer',
    'éternues',
    'éternuez',
    'étésiens',
    'étêtages',
    'étêtâmes',
    'étêtasse',
    'étêtâtes',
    'étêterai',
    'étêteras',
    'étêterez',
    'étêtions',
    'éthérées',
    'éthérisa',
    'éthérise',
    'éthérisé',
    'éthiques',
    'ethnique',
    'éthylène',
    'étincela',
    'étincelé',
    'étiolais',
    'étiolait',
    'étiolant',
    'étiolées',
    'étiolent',
    'étiolera',
    'étioliez',
    'étiolons',
    'étiqueta',
    'étiqueté',
    'étirable',
    'étirages',
    'étirâmes',
    'étirasse',
    'étirâtes',
    'étirerai',
    'étireras',
    'étirerez',
    'étireurs',
    'étireuse',
    'étirions',
    'étoffais',
    'étoffait',
    'étoffant',
    'étoffées',
    'étoffent',
    'étoffera',
    'étoffiez',
    'étoffons',
    'étoilais',
    'étoilait',
    'étoilant',
    'étoilées',
    'étoilent',
    'étoilera',
    'étoiliez',
    'étoilons',
    'étonnais',
    'étonnait',
    'étonnant',
    'étonnées',
    'étonnent',
    'étonnera',
    'étonniez',
    'étonnons',
    'étouffai',
    'étouffas',
    'étouffât',
    'étouffée',
    'étouffer',
    'étouffes',
    'étouffés',
    'étouffez',
    'étoupais',
    'étoupait',
    'étoupant',
    'étoupées',
    'étoupent',
    'étoupera',
    'étoupiez',
    'étoupons',
    'étourdie',
    'étourdir',
    'étourdis',
    'étourdit',
    'étourdît',
    'étranger',
    'étranges',
    'étrangla',
    'étrangle',
    'étranglé',
    'étrécies',
    'étrécira',
    'étreigne',
    'étreinte',
    'étreints',
    'étrennai',
    'étrennas',
    'étrennât',
    'étrennée',
    'étrenner',
    'étrennes',
    'étrennés',
    'étrennez',
    'étrillai',
    'étrillas',
    'étrillât',
    'étrillée',
    'étriller',
    'étrilles',
    'étrillés',
    'étrillez',
    'étripage',
    'étripais',
    'étripait',
    'étripant',
    'étripées',
    'étripent',
    'étripera',
    'étripiez',
    'étripons',
    'étriquai',
    'étriquas',
    'étriquât',
    'étriquée',
    'étriquer',
    'étriques',
    'étriqués',
    'étriquez',
    'étroites',
    'étrusque',
    'étudiais',
    'étudiait',
    'étudiant',
    'étudiées',
    'étudient',
    'étudiera',
    'étudiiez',
    'étudions',
    'étuvages',
    'étuvâmes',
    'étuvasse',
    'étuvâtes',
    'étuverai',
    'étuveras',
    'étuverez',
    'étuveurs',
    'étuveuse',
    'étuvions',
    'eucologe',
    'eudistes',
    'eugénate',
    'euglènes',
    'eunectes',
    'eunuques',
    'euphonie',
    'euphorbe',
    'euphorie',
    'eurasien',
    'européen',
    'europium',
    'eusse-je',
    'eusse-tu',
    'eussions',
    'eustache',
    'eut-elle',
    'eût-elle',
    'eutexies',
    'évacuais',
    'évacuait',
    'évacuant',
    'évacuées',
    'évacuent',
    'évacuera',
    'évacuiez',
    'évacuons',
    'évadâmes',
    'évadâtes',
    'évaderez',
    'évadions',
    'évaluais',
    'évaluait',
    'évaluant',
    'évaluées',
    'évaluent',
    'évaluera',
    'évaluiez',
    'évaluons',
    'évangile',
    'évanouie',
    'évanouir',
    'évanouis',
    'évaporai',
    'évaporas',
    'évaporât',
    'évaporée',
    'évaporer',
    'évapores',
    'évaporés',
    'évaporez',
    'évasâmes',
    'évasasse',
    'évasâtes',
    'évaserai',
    'évaseras',
    'évaserez',
    'évasions',
    'évasives',
    'évasures',
    'évection',
    'éveillai',
    'éveillas',
    'éveillât',
    'éveillée',
    'éveiller',
    'éveilles',
    'éveillés',
    'éveillez',
    'éventail',
    'éventais',
    'éventait',
    'éventant',
    'éventées',
    'éventent',
    'éventera',
    'éventiez',
    'éventons',
    'éventrai',
    'éventras',
    'éventrât',
    'éventrée',
    'éventrer',
    'éventres',
    'éventrés',
    'éventrez',
    'éventuel',
    'évertuée',
    'évertuer',
    'évertuez',
    'éviction',
    'évidages',
    'évidâmes',
    'évidasse',
    'évidâtes',
    'évidence',
    'évidente',
    'évidents',
    'éviderai',
    'évideras',
    'éviderez',
    'évidions',
    'évinçais',
    'évinçait',
    'évinçant',
    'évincées',
    'évincent',
    'évincera',
    'évinciez',
    'évinçons',
    'éviscéra',
    'éviscéré',
    'éviscère',
    'évitable',
    'évitages',
    'évitâmes',
    'évitasse',
    'évitâtes',
    'éviterai',
    'éviteras',
    'éviterez',
    'évitions',
    'évocable',
    'évoluais',
    'évoluait',
    'évoluant',
    'évoluées',
    'évoluent',
    'évoluera',
    'évoluiez',
    'évoluons',
    'évolutif',
    'évoquais',
    'évoquait',
    'évoquant',
    'évoquées',
    'évoquent',
    'évoquera',
    'évoquiez',
    'évoquons',
    'exacerba',
    'exacerbe',
    'exacerbé',
    'exaction',
    'exagérai',
    'exagéras',
    'exagérât',
    'exagérée',
    'exagérer',
    'exagérés',
    'exagères',
    'exagérez',
    'exaltais',
    'exaltait',
    'exaltant',
    'exaltées',
    'exaltent',
    'exaltera',
    'exaltiez',
    'exaltons',
    'examinai',
    'examinas',
    'examinât',
    'examinée',
    'examiner',
    'examines',
    'examinés',
    'examinez',
    'exarchat',
    'exarques',
    'exaspéra',
    'exaspéré',
    'exaspère',
    'exauçais',
    'exauçait',
    'exauçant',
    'exaucées',
    'exaucent',
    'exaucera',
    'exauciez',
    'exauçons',
    'excavais',
    'excavait',
    'excavant',
    'excavées',
    'excavent',
    'excavera',
    'excaviez',
    'excavons',
    'excédais',
    'excédait',
    'excédant',
    'excédées',
    'excédent',
    'excèdent',
    'excédera',
    'excédiez',
    'excédons',
    'excellai',
    'excellas',
    'excellât',
    'exceller',
    'excelles',
    'excellez',
    'excentra',
    'excentre',
    'excentré',
    'exceptai',
    'exceptas',
    'exceptât',
    'exceptée',
    'excepter',
    'exceptes',
    'exceptés',
    'exceptez',
    'excessif',
    'excipais',
    'excipait',
    'excipant',
    'excipées',
    'excipent',
    'excipera',
    'excipiez',
    'excipons',
    'excisais',
    'excisait',
    'excisant',
    'excisées',
    'excisent',
    'excisera',
    'excisiez',
    'excision',
    'excisons',
    'excitais',
    'excitait',
    'excitant',
    'excitées',
    'excitent',
    'excitera',
    'excitiez',
    'excitons',
    'exclamée',
    'exclamer',
    'exclamés',
    'exclamez',
    'excluais',
    'excluait',
    'excluant',
    'excluent',
    'excluiez',
    'exclûmes',
    'excluons',
    'exclurai',
    'excluras',
    'exclurez',
    'exclusif',
    'exclusse',
    'exclûtes',
    'excoriai',
    'excorias',
    'excoriât',
    'excoriée',
    'excorier',
    'excories',
    'excoriés',
    'excoriez',
    'excrétai',
    'excrétas',
    'excrétât',
    'excrétée',
    'excréter',
    'excrétés',
    'excrètes',
    'excrétez',
    'excusais',
    'excusait',
    'excusant',
    'excusées',
    'excusent',
    'excusera',
    'excusiez',
    'excusons',
    'exécrais',
    'exécrait',
    'exécrant',
    'exécrées',
    'exècrent',
    'exécrera',
    'exécriez',
    'exécrons',
    'exécutai',
    'exécutas',
    'exécutât',
    'exécutée',
    'exécuter',
    'exécutes',
    'exécutés',
    'exécutez',
    'exécutif',
    'exégèses',
    'exégètes',
    'exemples',
    'exemptai',
    'exemptas',
    'exemptât',
    'exemptée',
    'exempter',
    'exemptes',
    'exemptés',
    'exemptez',
    'exerçais',
    'exerçait',
    'exerçant',
    'exercées',
    'exercent',
    'exercera',
    'exercice',
    'exerciez',
    'exerçons',
    'exergues',
    'exfoliai',
    'exfolias',
    'exfoliât',
    'exfoliée',
    'exfolier',
    'exfolies',
    'exfoliés',
    'exfoliez',
    'exhalais',
    'exhalait',
    'exhalant',
    'exhalées',
    'exhalent',
    'exhalera',
    'exhaliez',
    'exhalons',
    'exhaures',
    'exhaussa',
    'exhausse',
    'exhaussé',
    'exhéréda',
    'exhérédé',
    'exhérède',
    'exhibais',
    'exhibait',
    'exhibant',
    'exhibées',
    'exhibent',
    'exhibera',
    'exhibiez',
    'exhibons',
    'exhortai',
    'exhortas',
    'exhortât',
    'exhortée',
    'exhorter',
    'exhortes',
    'exhortés',
    'exhortez',
    'exhumais',
    'exhumait',
    'exhumant',
    'exhumées',
    'exhument',
    'exhumera',
    'exhumiez',
    'exhumons',
    'exigeais',
    'exigeait',
    'exigeant',
    'exigence',
    'exigeons',
    'exigerai',
    'exigeras',
    'exigerez',
    'exigible',
    'exigions',
    'exiguïté',
    'exilâmes',
    'exilasse',
    'exilâtes',
    'exilerai',
    'exileras',
    'exilerez',
    'exilions',
    'existais',
    'existait',
    'existant',
    'existent',
    'existera',
    'existiez',
    'existons',
    'exocrine',
    'exogames',
    'exogamie',
    'exogènes',
    'exondées',
    'exondiez',
    'exondons',
    'exonérai',
    'exonéras',
    'exonérât',
    'exonérée',
    'exonérer',
    'exonérés',
    'exonères',
    'exonérez',
    'exorbité',
    'exorcisa',
    'exorcise',
    'exorcisé',
    'exosmose',
    'exostose',
    'exotique',
    'exotisme',
    'expansée',
    'expansés',
    'expansif',
    'expatria',
    'expatrie',
    'expatrié',
    'expédiai',
    'expédias',
    'expédiât',
    'expédiée',
    'expédier',
    'expédies',
    'expédiés',
    'expédiez',
    'expertes',
    'expiable',
    'expiâmes',
    'expiasse',
    'expiâtes',
    'expierai',
    'expieras',
    'expierez',
    'expiions',
    'expirais',
    'expirait',
    'expirant',
    'expirées',
    'expirent',
    'expirera',
    'expiriez',
    'expirons',
    'explétif',
    'expliqua',
    'explique',
    'expliqué',
    'exploita',
    'exploite',
    'exploité',
    'exploits',
    'explorai',
    'exploras',
    'explorât',
    'explorée',
    'explorer',
    'explores',
    'explorés',
    'explorez',
    'explosai',
    'explosas',
    'explosât',
    'exploser',
    'exploses',
    'explosez',
    'explosif',
    'exportai',
    'exportas',
    'exportât',
    'exportée',
    'exporter',
    'exportes',
    'exportés',
    'exportez',
    'exposais',
    'exposait',
    'exposant',
    'exposées',
    'exposent',
    'exposera',
    'exposiez',
    'exposons',
    'expresse',
    'exprimai',
    'exprimas',
    'exprimât',
    'exprimée',
    'exprimer',
    'exprimes',
    'exprimés',
    'exprimez',
    'expulsai',
    'expulsas',
    'expulsât',
    'expulsée',
    'expulser',
    'expulses',
    'expulsés',
    'expulsez',
    'expurgea',
    'expurgée',
    'expurger',
    'expurges',
    'expurgés',
    'expurgez',
    'exquises',
    'exsangue',
    'exsudais',
    'exsudait',
    'exsudant',
    'exsudats',
    'exsudées',
    'exsudent',
    'exsudera',
    'exsudiez',
    'exsudons',
    'extasiée',
    'extasier',
    'extasiés',
    'extasiez',
    'extensif',
    'exténuai',
    'exténuas',
    'exténuât',
    'exténuée',
    'exténuer',
    'exténues',
    'exténués',
    'exténuez',
    'externat',
    'externes',
    'extirpai',
    'extirpas',
    'extirpât',
    'extirpée',
    'extirper',
    'extirpes',
    'extirpés',
    'extirpez',
    'extorqua',
    'extorque',
    'extorqué',
    'extradai',
    'extradas',
    'extradât',
    'extradée',
    'extrader',
    'extrades',
    'extradés',
    'extradez',
    'extrados',
    'extraies',
    'extraira',
    'extraire',
    'extraite',
    'extraits',
    'extrayez',
    'extrêmes',
    'extremum',
    'extrorse',
    'extrudai',
    'extrudas',
    'extrudât',
    'extrudée',
    'extruder',
    'extrudes',
    'extrudés',
    'extrudez',
    'exulcéra',
    'exulcéré',
    'exulcère',
    'exultais',
    'exultait',
    'exultant',
    'exultent',
    'exultera',
    'exultiez',
    'exultons',
    'exutoire',
    'fabliaux',
    'fabliers',
    'fabriqua',
    'fabrique',
    'fabriqué',
    'fabulais',
    'fabulait',
    'fabulant',
    'fabulent',
    'fabulera',
    'fabuleux',
    'fabuliez',
    'fabulons',
    'facéties',
    'facettai',
    'facettas',
    'facettât',
    'facettée',
    'facetter',
    'facettes',
    'facettés',
    'facettez',
    'fâchâmes',
    'fâchasse',
    'fâchâtes',
    'fâcherai',
    'fâcheras',
    'fâcherez',
    'fâcherie',
    'fâcheuse',
    'fâchions',
    'faciales',
    'facilita',
    'facilite',
    'facilité',
    'facondes',
    'façonnai',
    'façonnas',
    'façonnât',
    'façonnée',
    'façonner',
    'façonnes',
    'façonnés',
    'façonnez',
    'factages',
    'facteurs',
    'factices',
    'factieux',
    'factions',
    'factitif',
    'factotum',
    'factrice',
    'factuels',
    'facturai',
    'facturas',
    'facturât',
    'facturée',
    'facturer',
    'factures',
    'facturés',
    'facturez',
    'facultés',
    'fadaient',
    'fadaises',
    'fadasses',
    'fadement',
    'faderais',
    'faderait',
    'fadèrent',
    'faderiez',
    'faderons',
    'faderont',
    'fagotage',
    'fagotais',
    'fagotait',
    'fagotant',
    'fagotées',
    'fagotent',
    'fagotera',
    'fagotier',
    'fagotiez',
    'fagotins',
    'fagotons',
    'faiblard',
    'faiblira',
    'faïencée',
    'faïences',
    'faïencés',
    'faignant',
    'faillais',
    'faillait',
    'faillant',
    'faillées',
    'faillent',
    'faillera',
    'failliez',
    'faillira',
    'faillite',
    'faillons',
    'fainéant',
    'faisable',
    'faisanda',
    'faisande',
    'faisandé',
    'faisceau',
    'faiseurs',
    'faiseuse',
    'faisions',
    'faîtages',
    'faîteaux',
    'faîtière',
    'faitouts',
    'falaises',
    'falbalas',
    'falernes',
    'falourde',
    'falsifia',
    'falsifie',
    'falsifié',
    'faluches',
    'fameuses',
    'familial',
    'familier',
    'familles',
    'fanaient',
    'fanasses',
    'fanatisa',
    'fanatise',
    'fanatisé',
    'fandango',
    'fanerais',
    'fanerait',
    'fanèrent',
    'faneriez',
    'fanerons',
    'faneront',
    'faneuses',
    'fanfares',
    'fanfaron',
    'fangeuse',
    'fantasia',
    'fantasma',
    'fantasme',
    'fantasmé',
    'fantoche',
    'fantômes',
    'fanzines',
    'faradays',
    'faraudes',
    'farceurs',
    'farceuse',
    'farcîmes',
    'farcirai',
    'farciras',
    'farcirez',
    'farcisse',
    'farcîtes',
    'fardages',
    'fardâmes',
    'fardasse',
    'fardâtes',
    'fardeaux',
    'farderai',
    'farderas',
    'farderez',
    'fardiers',
    'fardions',
    'farfadet',
    'farfelue',
    'farfelus',
    'faribole',
    'farinacé',
    'farinais',
    'farinait',
    'farinant',
    'farinées',
    'farinent',
    'farinera',
    'farineux',
    'fariniez',
    'farinons',
    'farlouse',
    'farouche',
    'farragos',
    'fartages',
    'fartâmes',
    'fartasse',
    'fartâtes',
    'farterai',
    'farteras',
    'farterez',
    'fartions',
    'fasciées',
    'fascinai',
    'fascinas',
    'fascinât',
    'fascinée',
    'fasciner',
    'fascines',
    'fascinés',
    'fascinez',
    'fascisai',
    'fascisas',
    'fascisât',
    'fascisée',
    'fasciser',
    'fascises',
    'fascisés',
    'fascisez',
    'fascisme',
    'fasciste',
    'faseilla',
    'faseille',
    'faseillé',
    'fassions',
    'fastigié',
    'fastueux',
    'fatalité',
    'fatigant',
    'fatiguai',
    'fatiguas',
    'fatiguât',
    'fatiguée',
    'fatiguer',
    'fatigues',
    'fatigués',
    'fatiguez',
    'fatrasie',
    'fatuités',
    'fauberts',
    'faubourg',
    'faucarda',
    'faucarde',
    'faucardé',
    'faucards',
    'fauchage',
    'fauchais',
    'fauchait',
    'fauchant',
    'fauchard',
    'fauchées',
    'fauchent',
    'fauchera',
    'fauchets',
    'faucheur',
    'faucheux',
    'fauchiez',
    'fauchons',
    'faucille',
    'fauconna',
    'fauconne',
    'fauconné',
    'faudrais',
    'faudrait',
    'faudriez',
    'faudrons',
    'faudront',
    'faufilai',
    'faufilas',
    'faufilât',
    'faufilée',
    'faufiler',
    'faufiles',
    'faufilés',
    'faufilez',
    'faunique',
    'faussais',
    'faussait',
    'faussant',
    'faussées',
    'faussent',
    'faussera',
    'fausseté',
    'faussets',
    'faussiez',
    'faussons',
    'fautâmes',
    'fautasse',
    'fautâtes',
    'fauterai',
    'fauteras',
    'fauterez',
    'fauteuil',
    'fauteurs',
    'fautions',
    'fautives',
    'fautrice',
    'fauverie',
    'fauvette',
    'fauvisme',
    'faverole',
    'favorisa',
    'favorise',
    'favorisé',
    'favorite',
    'faxaient',
    'faxasses',
    'faxerais',
    'faxerait',
    'faxèrent',
    'faxeriez',
    'faxerons',
    'faxeront',
    'fayotais',
    'fayotait',
    'fayotant',
    'fayotent',
    'fayotera',
    'fayotiez',
    'fayotons',
    'fayottai',
    'fayottas',
    'fayottât',
    'fayotter',
    'fayottes',
    'fayottez',
    'fazendas',
    'fébriles',
    'fécondai',
    'fécondas',
    'fécondât',
    'fécondée',
    'féconder',
    'fécondes',
    'fécondés',
    'fécondez',
    'féculais',
    'féculait',
    'féculant',
    'féculées',
    'féculent',
    'féculera',
    'féculiez',
    'féculons',
    'fedayins',
    'fédérais',
    'fédérait',
    'fédérale',
    'fédérant',
    'fédéraux',
    'fédérées',
    'fédèrent',
    'fédérera',
    'fédériez',
    'fédérons',
    'féerique',
    'fégatera',
    'feignais',
    'feignait',
    'feignant',
    'feignent',
    'feigniez',
    'feignons',
    'feindrai',
    'feindras',
    'feindrez',
    'feintais',
    'feintait',
    'feintant',
    'feintées',
    'feintent',
    'feintera',
    'feinteur',
    'feintiez',
    'feintise',
    'feintons',
    'fêlaient',
    'fêlasses',
    'fêlerais',
    'fêlerait',
    'fêlèrent',
    'fêleriez',
    'fêlerons',
    'fêleront',
    'félibres',
    'félicita',
    'félicite',
    'félicité',
    'félinité',
    'fellagas',
    'fellagha',
    'félonies',
    'félonnes',
    'felouque',
    'femelles',
    'féminine',
    'féminins',
    'féminisa',
    'féminise',
    'féminisé',
    'féminité',
    'fémorale',
    'fémoraux',
    'fenaison',
    'fendages',
    'fendante',
    'fendants',
    'fendards',
    'fendarts',
    'fendeurs',
    'fendilla',
    'fendille',
    'fendillé',
    'fendîmes',
    'fendions',
    'fendisse',
    'fendîtes',
    'fendoirs',
    'fendrais',
    'fendrait',
    'fendriez',
    'fendrons',
    'fendront',
    'fenestra',
    'fenestre',
    'fenestré',
    'fenêtrai',
    'fenêtras',
    'fenêtrât',
    'fenêtrée',
    'fenêtrer',
    'fenêtres',
    'fenêtrés',
    'fenêtrez',
    'fenouils',
    'fenugrec',
    'féodales',
    'feraient',
    'fériales',
    'ferlâmes',
    'ferlasse',
    'ferlâtes',
    'ferlerai',
    'ferleras',
    'ferlerez',
    'ferlions',
    'fermages',
    'fermâmes',
    'fermante',
    'fermants',
    'fermasse',
    'fermâtes',
    'fermenta',
    'fermente',
    'fermenté',
    'fermerai',
    'fermeras',
    'fermerez',
    'fermetés',
    'fermette',
    'fermière',
    'fermiers',
    'fermions',
    'fermiums',
    'fermoirs',
    'férocité',
    'ferrades',
    'ferrages',
    'ferrâmes',
    'ferrasse',
    'ferrates',
    'ferrâtes',
    'ferrerai',
    'ferreras',
    'ferrerez',
    'ferreurs',
    'ferrions',
    'ferrique',
    'ferrites',
    'ferrures',
    'fertiles',
    'fervente',
    'fervents',
    'ferveurs',
    'fessâmes',
    'fessasse',
    'fessâtes',
    'fesserai',
    'fesseras',
    'fesserez',
    'fessière',
    'fessiers',
    'fessions',
    'festival',
    'festives',
    'festoies',
    'festonna',
    'festonne',
    'festonné',
    'festoyai',
    'festoyas',
    'festoyât',
    'festoyée',
    'festoyer',
    'festoyés',
    'festoyez',
    'fêtaient',
    'fêtasses',
    'fêterais',
    'fêterait',
    'fêtèrent',
    'fêteriez',
    'fêterons',
    'fêteront',
    'fétiches',
    'fétidité',
    'fétuques',
    'feudiste',
    'feuillai',
    'feuillas',
    'feuillât',
    'feuillée',
    'feuiller',
    'feuilles',
    'feuillés',
    'feuillet',
    'feuillez',
    'feuillue',
    'feuillus',
    'feulâmes',
    'feulasse',
    'feulâtes',
    'feulerai',
    'feuleras',
    'feulerez',
    'feulions',
    'feutrage',
    'feutrais',
    'feutrait',
    'feutrant',
    'feutrées',
    'feutrent',
    'feutrera',
    'feutriez',
    'feutrine',
    'feutrons',
    'féverole',
    'fiançais',
    'fiançait',
    'fiançant',
    'fiancées',
    'fiancent',
    'fiancera',
    'fianciez',
    'fiançons',
    'fiasques',
    'fiassent',
    'fiassiez',
    'fibranne',
    'fibreuse',
    'fibrines',
    'fibroïne',
    'fibromes',
    'fibroses',
    'ficaires',
    'ficelage',
    'ficelais',
    'ficelait',
    'ficelant',
    'ficelées',
    'ficeliez',
    'ficelles',
    'ficelons',
    'fichages',
    'fichâmes',
    'fichasse',
    'fichâtes',
    'ficherai',
    'ficheras',
    'ficherez',
    'fichiers',
    'fichions',
    'fichiste',
    'fichoirs',
    'fictions',
    'fictives',
    'fidéisme',
    'fidéiste',
    'fidélité',
    'fieffais',
    'fieffait',
    'fieffant',
    'fieffées',
    'fieffent',
    'fieffera',
    'fieffiez',
    'fieffons',
    'fielleux',
    'fientais',
    'fientait',
    'fientant',
    'fientent',
    'fientera',
    'fientiez',
    'fientons',
    'fierions',
    'fiérotes',
    'fiévreux',
    'fifilles',
    'fifrelin',
    'figeâmes',
    'figeasse',
    'figeâtes',
    'figement',
    'figerais',
    'figerait',
    'figèrent',
    'figeriez',
    'figerons',
    'figeront',
    'fignolai',
    'fignolas',
    'fignolât',
    'fignolée',
    'fignoler',
    'fignoles',
    'fignolés',
    'fignolez',
    'figuerie',
    'figuiers',
    'figuline',
    'figurais',
    'figurait',
    'figurant',
    'figurées',
    'figurent',
    'figurera',
    'figuriez',
    'figurine',
    'figurons',
    'filables',
    'filaient',
    'filaires',
    'filament',
    'filandre',
    'filantes',
    'filasses',
    'filateur',
    'filature',
    'filerais',
    'filerait',
    'filèrent',
    'fileriez',
    'filerons',
    'fileront',
    'filetage',
    'filetais',
    'filetait',
    'filetant',
    'filetées',
    'filètent',
    'filètera',
    'filetiez',
    'filetons',
    'fileuses',
    'filiales',
    'filières',
    'fillasse',
    'fillette',
    'filleule',
    'filleuls',
    'filmages',
    'filmâmes',
    'filmasse',
    'filmâtes',
    'filmerai',
    'filmeras',
    'filmerez',
    'filmions',
    'filmique',
    'filochai',
    'filochas',
    'filochât',
    'filochée',
    'filocher',
    'filoches',
    'filochés',
    'filochez',
    'filonien',
    'filoutai',
    'filoutas',
    'filoutât',
    'filoutée',
    'filouter',
    'filoutes',
    'filoutés',
    'filoutez',
    'filtrage',
    'filtrais',
    'filtrait',
    'filtrant',
    'filtrats',
    'filtrées',
    'filtrent',
    'filtrera',
    'filtriez',
    'filtrons',
    'finalité',
    'finançai',
    'finanças',
    'finançât',
    'financée',
    'financer',
    'finances',
    'financés',
    'financez',
    'finassai',
    'finassas',
    'finassât',
    'finassée',
    'finasser',
    'finasses',
    'finassés',
    'finassez',
    'finaudes',
    'finement',
    'fineries',
    'finesses',
    'finettes',
    'finirais',
    'finirait',
    'finirent',
    'finiriez',
    'finirons',
    'finiront',
    'finisses',
    'finissez',
    'finition',
    'finnoise',
    'fiscales',
    'fissible',
    'fissiles',
    'fissions',
    'fissurai',
    'fissuras',
    'fissurât',
    'fissurée',
    'fissurer',
    'fissures',
    'fissurés',
    'fissurez',
    'fistules',
    'fixaient',
    'fixantes',
    'fixasses',
    'fixateur',
    'fixatifs',
    'fixation',
    'fixative',
    'fixement',
    'fixerais',
    'fixerait',
    'fixèrent',
    'fixeriez',
    'fixerons',
    'fixeront',
    'fixismes',
    'fixistes',
    'fla-flas',
    'flagadas',
    'flagella',
    'flagelle',
    'flagellé',
    'flageola',
    'flageole',
    'flageolé',
    'flagorna',
    'flagorne',
    'flagorné',
    'flagrant',
    'flairais',
    'flairait',
    'flairant',
    'flairées',
    'flairent',
    'flairera',
    'flaireur',
    'flairiez',
    'flairons',
    'flamande',
    'flamands',
    'flamants',
    'flambage',
    'flambais',
    'flambait',
    'flambant',
    'flambard',
    'flambart',
    'flambeau',
    'flambées',
    'flambent',
    'flambera',
    'flambeur',
    'flambiez',
    'flamboie',
    'flambons',
    'flamboya',
    'flamboyé',
    'flamenco',
    'flamines',
    'flammées',
    'flânâmes',
    'flânasse',
    'flânâtes',
    'flanchai',
    'flanchas',
    'flanchât',
    'flanchée',
    'flancher',
    'flanches',
    'flanchés',
    'flanchet',
    'flanchez',
    'flandrin',
    'flanelle',
    'flânerai',
    'flâneras',
    'flânerez',
    'flânerie',
    'flâneurs',
    'flâneuse',
    'flânions',
    'flanquai',
    'flanquas',
    'flanquât',
    'flanquée',
    'flanquer',
    'flanques',
    'flanqués',
    'flanquez',
    'flaquais',
    'flaquait',
    'flaquant',
    'flaquent',
    'flaquera',
    'flaquiez',
    'flaquons',
    'flashais',
    'flashait',
    'flashant',
    'flashent',
    'flashera',
    'flashiez',
    'flashons',
    'flasques',
    'flattais',
    'flattait',
    'flattant',
    'flattées',
    'flattent',
    'flattera',
    'flatteur',
    'flattiez',
    'flattons',
    'flaupais',
    'flaupait',
    'flaupant',
    'flaupées',
    'flaupent',
    'flaupera',
    'flaupiez',
    'flaupons',
    'flavines',
    'fléchage',
    'fléchais',
    'fléchait',
    'fléchant',
    'fléchées',
    'flèchent',
    'fléchera',
    'fléchies',
    'fléchiez',
    'fléchira',
    'fléchons',
    'flegmons',
    'flémarde',
    'flémards',
    'flemmard',
    'flétries',
    'flétrira',
    'fleurage',
    'fleurais',
    'fleurait',
    'fleurant',
    'fleurées',
    'fleurent',
    'fleurera',
    'fleurets',
    'fleuries',
    'fleuriez',
    'fleurira',
    'fleurons',
    'flexible',
    'flexions',
    'flexures',
    'flibusta',
    'flibuste',
    'flibusté',
    'flingots',
    'flinguai',
    'flinguas',
    'flinguât',
    'flinguée',
    'flinguer',
    'flingues',
    'flingués',
    'flinguez',
    'flippais',
    'flippait',
    'flippant',
    'flippent',
    'flippera',
    'flippers',
    'flippiez',
    'flippons',
    'flirtais',
    'flirtait',
    'flirtant',
    'flirtent',
    'flirtera',
    'flirteur',
    'flirtiez',
    'flirtons',
    'flocages',
    'floconna',
    'floconne',
    'floconné',
    'floculai',
    'floculas',
    'floculât',
    'floculer',
    'floculez',
    'flonflon',
    'florales',
    'florence',
    'flottage',
    'flottais',
    'flottait',
    'flottant',
    'flottard',
    'flottées',
    'flottent',
    'flottera',
    'flotteur',
    'flottiez',
    'flottons',
    'flouâmes',
    'flouasse',
    'flouâtes',
    'flouerai',
    'floueras',
    'flouerez',
    'flouions',
    'flousais',
    'flousait',
    'flousant',
    'flousent',
    'flousera',
    'flousiez',
    'flousons',
    'fluaient',
    'fluasses',
    'fluctuai',
    'fluctuas',
    'fluctuât',
    'fluctuer',
    'fluctues',
    'fluctuez',
    'fluerais',
    'fluerait',
    'fluèrent',
    'flueriez',
    'fluerons',
    'flueront',
    'fluettes',
    'fluidisa',
    'fluidise',
    'fluidisé',
    'fluidité',
    'fluorine',
    'fluorisa',
    'fluorise',
    'fluorisé',
    'fluorose',
    'fluorure',
    'flûtâmes',
    'flûtasse',
    'flûtâtes',
    'flûteaux',
    'flûterai',
    'flûteras',
    'flûterez',
    'flûtions',
    'flûtiste',
    'fluviale',
    'fluviaux',
    'fluxâmes',
    'fluxasse',
    'fluxâtes',
    'fluxerai',
    'fluxeras',
    'fluxerez',
    'fluxions',
    'focalisa',
    'focalise',
    'focalisé',
    'foetales',
    'foirades',
    'foirails',
    'foirâmes',
    'foirante',
    'foirants',
    'foirasse',
    'foirâtes',
    'foirerai',
    'foireras',
    'foirerez',
    'foireuse',
    'foirions',
    'foisonna',
    'foisonne',
    'foisonné',
    'folasses',
    'folâtrai',
    'folâtras',
    'folâtrât',
    'folâtrer',
    'folâtres',
    'folâtrez',
    'foliacée',
    'foliacés',
    'foliaire',
    'folichon',
    'folioles',
    'foliotai',
    'foliotas',
    'foliotât',
    'foliotée',
    'folioter',
    'foliotes',
    'foliotés',
    'foliotez',
    'folklore',
    'folksong',
    'follette',
    'fomentai',
    'fomentas',
    'fomentât',
    'fomentée',
    'fomenter',
    'fomentes',
    'fomentés',
    'fomentez',
    'fonçâmes',
    'fonçasse',
    'fonçâtes',
    'foncerai',
    'fonceras',
    'foncerez',
    'fonceurs',
    'fonceuse',
    'foncière',
    'fonciers',
    'foncions',
    'fonction',
    'fondâmes',
    'fondante',
    'fondants',
    'fondasse',
    'fondâtes',
    'fonderai',
    'fonderas',
    'fonderez',
    'fonderie',
    'fondeurs',
    'fondeuse',
    'fondîmes',
    'fondions',
    'fondisse',
    'fondîtes',
    'fondoirs',
    'fondouks',
    'fondrais',
    'fondrait',
    'fondriez',
    'fondrons',
    'fondront',
    'fongible',
    'fongique',
    'fongueux',
    'fontaine',
    'fontange',
    'football',
    'footings',
    'foraient',
    'foraines',
    'foraminé',
    'forasses',
    'forçages',
    'forçâmes',
    'forçasse',
    'forçâtes',
    'forcenée',
    'forcenés',
    'forcerai',
    'forceras',
    'forcerez',
    'forcerie',
    'forcîmes',
    'forcings',
    'forcions',
    'forcirai',
    'forciras',
    'forcirez',
    'forcisse',
    'forcîtes',
    'forclore',
    'forclose',
    'forerais',
    'forerait',
    'forèrent',
    'foreriez',
    'forerons',
    'foreront',
    'foreuses',
    'forfaire',
    'forfaite',
    'forfaits',
    'forfasse',
    'forferai',
    'forferas',
    'forferez',
    'forfîmes',
    'forfisse',
    'forfîtes',
    'forgeage',
    'forgeais',
    'forgeait',
    'forgeant',
    'forgeons',
    'forgerai',
    'forgeras',
    'forgerez',
    'forgeron',
    'forgeurs',
    'forgeuse',
    'forgions',
    'forjetai',
    'forjetas',
    'forjetât',
    'forjetée',
    'forjeter',
    'forjetés',
    'forjetez',
    'forjette',
    'forlança',
    'forlance',
    'forlancé',
    'forlanes',
    'forligna',
    'forligne',
    'forligné',
    'formages',
    'formâmes',
    'formasse',
    'formâtes',
    'formatif',
    'formelle',
    'formènes',
    'formerai',
    'formeras',
    'formeret',
    'formerez',
    'formiate',
    'formions',
    'formique',
    'formolai',
    'formolas',
    'formolât',
    'formolée',
    'formoler',
    'formoles',
    'formolés',
    'formolez',
    'formulai',
    'formulas',
    'formulât',
    'formulée',
    'formuler',
    'formules',
    'formulés',
    'formulez',
    'forniqua',
    'fornique',
    'forniqué',
    'fortiche',
    'fortifia',
    'fortifie',
    'fortifié',
    'fortiori',
    'fortrait',
    'fortrans',
    'fortuite',
    'fortuits',
    'fortunée',
    'fortunes',
    'fortunés',
    'fossette',
    'fossiles',
    'fossoies',
    'fossoirs',
    'fossoyai',
    'fossoyas',
    'fossoyât',
    'fossoyée',
    'fossoyer',
    'fossoyés',
    'fossoyez',
    'fouacier',
    'fouailla',
    'fouaille',
    'fouaillé',
    'foucades',
    'fouchtra',
    'foudroie',
    'foudroya',
    'foudroyé',
    'fouettai',
    'fouettas',
    'fouettât',
    'fouettée',
    'fouetter',
    'fouettes',
    'fouettés',
    'fouettez',
    'fougasse',
    'fougeais',
    'fougeait',
    'fougeant',
    'fougeons',
    'fougerai',
    'fougeras',
    'fougères',
    'fougerez',
    'fougions',
    'fougueux',
    'fouillai',
    'fouillas',
    'fouillât',
    'fouillée',
    'fouiller',
    'fouilles',
    'fouillés',
    'fouillez',
    'fouillis',
    'fouinais',
    'fouinait',
    'fouinant',
    'fouinard',
    'fouinent',
    'fouinera',
    'fouineur',
    'fouiniez',
    'fouinons',
    'fouirais',
    'fouirait',
    'fouirent',
    'fouiriez',
    'fouirons',
    'fouiront',
    'fouisses',
    'fouissez',
    'foulages',
    'foulâmes',
    'foulards',
    'foulasse',
    'foulâtes',
    'foulerai',
    'fouleras',
    'foulerez',
    'foulerie',
    'fouleurs',
    'fouleuse',
    'foulions',
    'fouloirs',
    'foulques',
    'foulures',
    'fourbais',
    'fourbait',
    'fourbant',
    'fourbées',
    'fourbent',
    'fourbera',
    'fourbies',
    'fourbiez',
    'fourbira',
    'fourbons',
    'fourbues',
    'fourbure',
    'fourchai',
    'fourchas',
    'fourchât',
    'fourchée',
    'fourcher',
    'fourches',
    'fourchés',
    'fourchet',
    'fourchez',
    'fourchon',
    'fourchue',
    'fourchus',
    'fourgons',
    'fourguai',
    'fourguas',
    'fourguât',
    'fourguée',
    'fourguer',
    'fourgues',
    'fourgués',
    'fourguez',
    'fourneau',
    'fournées',
    'fournier',
    'fournies',
    'fournils',
    'fournira',
    'fourrage',
    'fourragé',
    'fourrais',
    'fourrait',
    'fourrant',
    'fourreau',
    'fourrées',
    'fourrent',
    'fourrera',
    'fourreur',
    'fourrier',
    'fourriez',
    'fourrons',
    'fourrure',
    'fourvoie',
    'fourvoya',
    'fourvoyé',
    'foutaise',
    'foutions',
    'foutoirs',
    'foutrais',
    'foutrait',
    'foutrale',
    'foutrals',
    'foutriez',
    'foutrons',
    'foutront',
    'fox-trot',
    'fracassa',
    'fracasse',
    'fracassé',
    'fraction',
    'fractura',
    'fracture',
    'fracturé',
    'fragiles',
    'fragment',
    'fraîches',
    'fraîchin',
    'fraîchir',
    'fraîchis',
    'fraîchit',
    'fraîchît',
    'fraierai',
    'fraieras',
    'fraierez',
    'frairies',
    'fraisage',
    'fraisais',
    'fraisait',
    'fraisant',
    'fraisées',
    'fraisent',
    'fraisera',
    'fraiseur',
    'fraisier',
    'fraisiez',
    'fraisils',
    'fraisons',
    'fraisure',
    'français',
    'franches',
    'franchie',
    'franchir',
    'franchis',
    'franchit',
    'franchît',
    'francien',
    'francisa',
    'francise',
    'francisé',
    'francité',
    'francium',
    'frangeai',
    'frangeas',
    'frangeât',
    'frangées',
    'frangent',
    'frangera',
    'frangiez',
    'frangine',
    'frangins',
    'frappage',
    'frappais',
    'frappait',
    'frappant',
    'frappées',
    'frappent',
    'frappera',
    'frappeur',
    'frappiez',
    'frappons',
    'frasques',
    'fratries',
    'fraudais',
    'fraudait',
    'fraudant',
    'fraudées',
    'fraudent',
    'fraudera',
    'fraudeur',
    'fraudiez',
    'fraudons',
    'frayages',
    'frayâmes',
    'frayasse',
    'frayâtes',
    'frayerai',
    'frayeras',
    'frayères',
    'frayerez',
    'frayeurs',
    'frayions',
    'fredaine',
    'fredonna',
    'fredonne',
    'fredonné',
    'freesias',
    'freezers',
    'frégatai',
    'frégatas',
    'frégatât',
    'frégatée',
    'frégater',
    'frégates',
    'frégatés',
    'frégatez',
    'freinage',
    'freinais',
    'freinait',
    'freinant',
    'freinées',
    'freinent',
    'freinera',
    'freiniez',
    'freinons',
    'freintes',
    'frelatai',
    'frelatas',
    'frelatât',
    'frelatée',
    'frelater',
    'frelates',
    'frelatés',
    'frelatez',
    'frémîmes',
    'frémirai',
    'frémiras',
    'frémirez',
    'frémisse',
    'frémîtes',
    'frênaies',
    'frénésie',
    'fréquent',
    'fresques',
    'fressure',
    'frétâmes',
    'frétasse',
    'frétâtes',
    'fréterai',
    'fréteras',
    'fréterez',
    'fréteurs',
    'frétilla',
    'frétille',
    'frétillé',
    'frétions',
    'frettage',
    'frettais',
    'frettait',
    'frettant',
    'frettées',
    'frettent',
    'frettera',
    'frettiez',
    'frettons',
    'freudien',
    'friables',
    'friandes',
    'fricassa',
    'fricasse',
    'fricassé',
    'fricatif',
    'frichtis',
    'fricotai',
    'fricotas',
    'fricotât',
    'fricotée',
    'fricoter',
    'fricotes',
    'fricotés',
    'fricotez',
    'friction',
    'fridolin',
    'frigides',
    'frigorie',
    'frileuse',
    'frimaire',
    'frimâmes',
    'frimasse',
    'frimâtes',
    'frimerai',
    'frimeras',
    'frimerez',
    'frimeurs',
    'frimeuse',
    'frimions',
    'fringale',
    'fringant',
    'fringuai',
    'fringuas',
    'fringuât',
    'fringuée',
    'fringuer',
    'fringues',
    'fringués',
    'fringuez',
    'fripâmes',
    'fripasse',
    'fripâtes',
    'friperai',
    'friperas',
    'friperez',
    'friperie',
    'fripière',
    'fripiers',
    'fripions',
    'friponna',
    'friponne',
    'friponné',
    'friquées',
    'friquets',
    'fririons',
    'frisages',
    'frisâmes',
    'frisante',
    'frisants',
    'frisasse',
    'frisâtes',
    'friselis',
    'friserai',
    'friseras',
    'friserez',
    'frisette',
    'frisions',
    'frisolée',
    'frisonne',
    'frisotta',
    'frisotte',
    'frisotté',
    'frisquet',
    'frissent',
    'frissiez',
    'frissons',
    'frisures',
    'friterie',
    'friteuse',
    'frittage',
    'frittais',
    'frittait',
    'frittant',
    'frittées',
    'frittent',
    'frittera',
    'frittiez',
    'frittons',
    'fritures',
    'frivoles',
    'frocards',
    'froideur',
    'froidira',
    'froidure',
    'froissai',
    'froissas',
    'froissât',
    'froissée',
    'froisser',
    'froisses',
    'froissés',
    'froissez',
    'frôlâmes',
    'frôlasse',
    'frôlâtes',
    'frôlerai',
    'frôleras',
    'frôlerez',
    'frôleurs',
    'frôleuse',
    'frôlions',
    'fromager',
    'fromages',
    'froments',
    'fronçais',
    'fronçait',
    'fronçant',
    'froncées',
    'froncent',
    'froncera',
    'fronciez',
    'fronçons',
    'frondais',
    'frondait',
    'frondant',
    'frondées',
    'frondent',
    'frondera',
    'frondeur',
    'frondiez',
    'frondons',
    'frontail',
    'frontale',
    'frontaux',
    'fronteau',
    'frontons',
    'frottage',
    'frottais',
    'frottait',
    'frottant',
    'frottées',
    'frottent',
    'frottera',
    'frotteur',
    'frottiez',
    'frottoir',
    'frottons',
    'frouâmes',
    'frouasse',
    'frouâtes',
    'frouerai',
    'froueras',
    'frouerez',
    'froufrou',
    'frouions',
    'frousses',
    'fructose',
    'frugales',
    'fruitées',
    'fruitier',
    'frusquai',
    'frusquas',
    'frusquât',
    'frusquée',
    'frusquer',
    'frusques',
    'frusqués',
    'frusquez',
    'frustrai',
    'frustras',
    'frustrât',
    'frustrée',
    'frustrer',
    'frustres',
    'frustrés',
    'frustrez',
    'fuchsias',
    'fuchsine',
    'fuégiens',
    'fugacité',
    'fugitifs',
    'fugitive',
    'fuguâmes',
    'fuguasse',
    'fuguâtes',
    'fuguerai',
    'fugueras',
    'fuguerez',
    'fugueurs',
    'fugueuse',
    'fuguions',
    'fuirions',
    'fuissent',
    'fuissiez',
    'fulgurai',
    'fulguras',
    'fulgurât',
    'fulgurée',
    'fulgurer',
    'fulgures',
    'fulgurés',
    'fulgurez',
    'fulminai',
    'fulminas',
    'fulminât',
    'fulminée',
    'fulminer',
    'fulmines',
    'fulminés',
    'fulminez',
    'fumables',
    'fumagine',
    'fumaient',
    'fumaison',
    'fumantes',
    'fumasses',
    'fumerais',
    'fumerait',
    'fumèrent',
    'fumeries',
    'fumeriez',
    'fumerons',
    'fumeront',
    'fumeuses',
    'fumigeai',
    'fumigeas',
    'fumigeât',
    'fumigées',
    'fumigène',
    'fumigent',
    'fumigera',
    'fumigiez',
    'fumistes',
    'fumivore',
    'funèbres',
    'funestes',
    'funicule',
    'furetage',
    'furetais',
    'furetait',
    'furetant',
    'furètent',
    'furètera',
    'fureteur',
    'furetiez',
    'furetons',
    'furfural',
    'furibard',
    'furibond',
    'furieuse',
    'furiosos',
    'furoncle',
    'furtives',
    'fusaient',
    'fusasses',
    'fuscines',
    'fuselage',
    'fuselais',
    'fuselait',
    'fuselant',
    'fuselées',
    'fuseliez',
    'fuselles',
    'fuselons',
    'fuserais',
    'fuserait',
    'fusèrent',
    'fuseriez',
    'fuserons',
    'fuseront',
    'fusettes',
    'fusibles',
    'fusilier',
    'fusillai',
    'fusillas',
    'fusillât',
    'fusillée',
    'fusiller',
    'fusilles',
    'fusillés',
    'fusillez',
    'fusionna',
    'fusionne',
    'fusionné',
    'fussions',
    'fustigea',
    'fustigée',
    'fustiger',
    'fustiges',
    'fustigés',
    'fustigez',
    'futaille',
    'futaines',
    'fut-elle',
    'fût-elle',
    'futilité',
    'fuyaient',
    'fuyantes',
    'fuyardes',
    'gabarier',
    'gabarits',
    'gabarres',
    'gabegies',
    'gabelles',
    'gabelous',
    'gabionna',
    'gabionne',
    'gabionné',
    'gâchages',
    'gâchâmes',
    'gâchasse',
    'gâchâtes',
    'gâcherai',
    'gâcheras',
    'gâcherez',
    'gâchette',
    'gâcheurs',
    'gâcheuse',
    'gâchions',
    'gaélique',
    'gaffâmes',
    'gaffasse',
    'gaffâtes',
    'gafferai',
    'gafferas',
    'gafferez',
    'gaffeurs',
    'gaffeuse',
    'gaffions',
    'gageâmes',
    'gageasse',
    'gageâtes',
    'gagerais',
    'gagerait',
    'gagèrent',
    'gageriez',
    'gagerons',
    'gageront',
    'gageures',
    'gagistes',
    'gagnable',
    'gagnages',
    'gagnâmes',
    'gagnante',
    'gagnants',
    'gagnasse',
    'gagnâtes',
    'gagnerai',
    'gagneras',
    'gagnerez',
    'gagneurs',
    'gagneuse',
    'gagnions',
    'gaïacols',
    'gaiement',
    'gaillard',
    'gaillets',
    'gainages',
    'gainâmes',
    'gainasse',
    'gainâtes',
    'gainerai',
    'gaineras',
    'gainerez',
    'gainerie',
    'gainiers',
    'gainions',
    'galantes',
    'galantin',
    'galapiat',
    'galaxies',
    'galbâmes',
    'galbante',
    'galbants',
    'galbasse',
    'galbâtes',
    'galberai',
    'galberas',
    'galberez',
    'galbions',
    'galéaces',
    'galéasse',
    'galéjade',
    'galéjais',
    'galéjait',
    'galéjant',
    'galèjent',
    'galéjera',
    'galéjiez',
    'galéjons',
    'galérien',
    'galeries',
    'galernes',
    'galettes',
    'galeuses',
    'galibots',
    'galiléen',
    'galiotes',
    'galipota',
    'galipote',
    'galipoté',
    'galipots',
    'gallérie',
    'gallican',
    'gallique',
    'galliums',
    'galloise',
    'galoches',
    'galonnai',
    'galonnas',
    'galonnât',
    'galonnée',
    'galonner',
    'galonnes',
    'galonnés',
    'galonnez',
    'galopade',
    'galopais',
    'galopait',
    'galopant',
    'galopées',
    'galopent',
    'galopera',
    'galopeur',
    'galopiez',
    'galopins',
    'galopons',
    'galoubet',
    'galuchat',
    'galurins',
    'galvanos',
    'galvauda',
    'galvaude',
    'galvaudé',
    'gambadai',
    'gambadas',
    'gambadât',
    'gambader',
    'gambades',
    'gambadez',
    'gamberge',
    'gambergé',
    'gambette',
    'gambilla',
    'gambille',
    'gambillé',
    'gamelles',
    'gaminais',
    'gaminait',
    'gaminant',
    'gaminent',
    'gaminera',
    'gaminiez',
    'gaminons',
    'gammares',
    'ganaches',
    'gandoura',
    'ganglion',
    'gangrena',
    'gangrené',
    'gangrène',
    'gangster',
    'ganoïdes',
    'gansâmes',
    'gansasse',
    'gansâtes',
    'ganserai',
    'ganseras',
    'ganserez',
    'gansette',
    'gansions',
    'gantâmes',
    'gantasse',
    'gantâtes',
    'gantelée',
    'gantelés',
    'gantelet',
    'ganterai',
    'ganteras',
    'ganterez',
    'ganterie',
    'gantière',
    'gantiers',
    'gantions',
    'garaient',
    'garançai',
    'garanças',
    'garançât',
    'garancée',
    'garancer',
    'garances',
    'garancés',
    'garancez',
    'garantes',
    'garantie',
    'garantir',
    'garantis',
    'garantit',
    'garantît',
    'garasses',
    'garbures',
    'garcette',
    'garçonne',
    'gardâmes',
    'gardasse',
    'gardâtes',
    'gardénal',
    'gardénia',
    'garderai',
    'garderas',
    'garderez',
    'garderie',
    'gardeurs',
    'gardeuse',
    'gardians',
    'gardiens',
    'gardions',
    'garennes',
    'garerais',
    'garerait',
    'garèrent',
    'gareriez',
    'garerons',
    'gareront',
    'gargotai',
    'gargotas',
    'gargotât',
    'gargoter',
    'gargotes',
    'gargotez',
    'garnîmes',
    'garnirai',
    'garniras',
    'garnirez',
    'garnison',
    'garnisse',
    'garnîtes',
    'garrigue',
    'garrotta',
    'garrotte',
    'garrotté',
    'gasconna',
    'gasconne',
    'gasconné',
    'gas-oils',
    'gaspacho',
    'gaspilla',
    'gaspille',
    'gaspillé',
    'gastrite',
    'gastrula',
    'gâtaient',
    'gâtasses',
    'gâterais',
    'gâterait',
    'gâtèrent',
    'gâteries',
    'gâteriez',
    'gâterons',
    'gâteront',
    'gâteuses',
    'gâtifiai',
    'gâtifias',
    'gâtifiât',
    'gâtifier',
    'gâtifies',
    'gâtifiez',
    'gâtismes',
    'gauchère',
    'gauchers',
    'gauchies',
    'gauchira',
    'gaufrage',
    'gaufrais',
    'gaufrait',
    'gaufrant',
    'gaufrées',
    'gaufrent',
    'gaufrera',
    'gaufreur',
    'gaufrier',
    'gaufriez',
    'gaufroir',
    'gaufrons',
    'gaufrure',
    'gaulages',
    'gaulâmes',
    'gaulasse',
    'gaulâtes',
    'gaulerai',
    'gauleras',
    'gaulerez',
    'gaulions',
    'gaullien',
    'gauloise',
    'gaussais',
    'gaussait',
    'gaussant',
    'gaussées',
    'gaussent',
    'gaussera',
    'gaussiez',
    'gaussons',
    'gavaches',
    'gavaient',
    'gavasses',
    'gaverais',
    'gaverait',
    'gavèrent',
    'gaveriez',
    'gaverons',
    'gaveront',
    'gaveuses',
    'gavottes',
    'gavroche',
    'gazaient',
    'gazantes',
    'gazasses',
    'gazéifia',
    'gazéifie',
    'gazéifié',
    'gazelles',
    'gazerais',
    'gazerait',
    'gazèrent',
    'gazeriez',
    'gazerons',
    'gazeront',
    'gazetier',
    'gazettes',
    'gazeuses',
    'gazières',
    'gazoducs',
    'gazogène',
    'gazoline',
    'gazonnai',
    'gazonnas',
    'gazonnât',
    'gazonnée',
    'gazonner',
    'gazonnes',
    'gazonnés',
    'gazonnez',
    'géhennes',
    'geignais',
    'geignait',
    'geignant',
    'geignard',
    'geignent',
    'geigniez',
    'geignons',
    'geindrai',
    'geindras',
    'geindrez',
    'gelaient',
    'gelasses',
    'gélatina',
    'gélatine',
    'gélatiné',
    'gèlerais',
    'gèlerait',
    'gelèrent',
    'gèleriez',
    'gèlerons',
    'gèleront',
    'gélifiai',
    'gélifias',
    'gélifiât',
    'gélifiée',
    'gélifier',
    'gélifies',
    'gélifiés',
    'gélifiez',
    'gélivure',
    'gémelles',
    'géminais',
    'géminait',
    'géminant',
    'géminées',
    'géminent',
    'géminera',
    'géminiez',
    'géminons',
    'gémirais',
    'gémirait',
    'gémirent',
    'gémiriez',
    'gémirons',
    'gémiront',
    'gémisses',
    'gémissez',
    'gemmages',
    'gemmâmes',
    'gemmasse',
    'gemmâtes',
    'gemmerai',
    'gemmeras',
    'gemmerez',
    'gemmeurs',
    'gemmeuse',
    'gemmions',
    'gemmules',
    'gémonies',
    'gênaient',
    'gênantes',
    'gênasses',
    'gencives',
    'gendarma',
    'gendarme',
    'gendarmé',
    'générais',
    'gênerais',
    'générait',
    'gênerait',
    'générale',
    'générant',
    'généraux',
    'générées',
    'génèrent',
    'gênèrent',
    'générera',
    'généreux',
    'génériez',
    'gêneriez',
    'générons',
    'gênerons',
    'gêneront',
    'genettes',
    'gêneuses',
    'géniales',
    'genièvre',
    'génisses',
    'génitale',
    'génitaux',
    'géniteur',
    'génitifs',
    'génocide',
    'génoises',
    'génotype',
    'gentiane',
    'gentille',
    'géodésie',
    'geôlière',
    'geôliers',
    'géologie',
    'géologue',
    'géomètre',
    'géophage',
    'géophile',
    'géorgien',
    'géotrupe',
    'géraient',
    'gérances',
    'géranium',
    'gérantes',
    'gérasses',
    'gerbages',
    'gerbâmes',
    'gerbante',
    'gerbants',
    'gerbasse',
    'gerbâtes',
    'gerberai',
    'gerberas',
    'gerberez',
    'gerbeurs',
    'gerbeuse',
    'gerbière',
    'gerbiers',
    'gerbille',
    'gerbions',
    'gerboise',
    'gerçâmes',
    'gerçasse',
    'gerçâtes',
    'gercerai',
    'gerceras',
    'gercerez',
    'gercions',
    'gerçures',
    'gérerais',
    'gérerait',
    'gérèrent',
    'géreriez',
    'gérerons',
    'géreront',
    'gerfauts',
    'gériatre',
    'germaine',
    'germains',
    'germâmes',
    'germasse',
    'germâtes',
    'germerai',
    'germeras',
    'germerez',
    'germinal',
    'germions',
    'germoirs',
    'gérondif',
    'gérontes',
    'gerseaux',
    'gestions',
    'gestuels',
    'gibbeuse',
    'gibeline',
    'gibelins',
    'gibernes',
    'giboient',
    'giboiera',
    'giboulée',
    'giboyais',
    'giboyait',
    'giboyant',
    'giboyées',
    'giboyeux',
    'giboyiez',
    'giboyons',
    'giclâmes',
    'giclasse',
    'giclâtes',
    'giclerai',
    'gicleras',
    'giclerez',
    'gicleurs',
    'giclions',
    'giflâmes',
    'giflasse',
    'giflâtes',
    'giflerai',
    'gifleras',
    'giflerez',
    'giflions',
    'gigognes',
    'gigotais',
    'gigotait',
    'gigotant',
    'gigotent',
    'gigotera',
    'gigotiez',
    'gigotons',
    'giletier',
    'gin-fizz',
    'gingival',
    'ginguets',
    'ginsengs',
    'girafeau',
    'girafons',
    'girasols',
    'giration',
    'giraumon',
    'giravion',
    'girelles',
    'girodyne',
    'giroflée',
    'girofles',
    'girolles',
    'girondes',
    'girondin',
    'gironnai',
    'gironnas',
    'gironnât',
    'gironnée',
    'gironner',
    'gironnes',
    'gironnés',
    'gironnez',
    'gisaient',
    'gisement',
    'gîtaient',
    'gîtasses',
    'gîterais',
    'gîterait',
    'gîtèrent',
    'gîteriez',
    'gîterons',
    'gîteront',
    'givrages',
    'givrâmes',
    'givrante',
    'givrants',
    'givrasse',
    'givrâtes',
    'givrerai',
    'givreras',
    'givrerez',
    'givreuse',
    'givrions',
    'givrures',
    'glabelle',
    'glaçages',
    'glaçâmes',
    'glaçante',
    'glaçants',
    'glaçasse',
    'glaçâtes',
    'glacerai',
    'glaceras',
    'glacerez',
    'glacerie',
    'glaceurs',
    'glaceuse',
    'glaciale',
    'glaciaux',
    'glacière',
    'glaciers',
    'glacions',
    'glaçures',
    'glaïeuls',
    'glairais',
    'glairait',
    'glairant',
    'glairées',
    'glairent',
    'glairera',
    'glaireux',
    'glairiez',
    'glairons',
    'glairure',
    'glaisais',
    'glaisait',
    'glaisant',
    'glaisées',
    'glaisent',
    'glaisera',
    'glaiseux',
    'glaisiez',
    'glaisons',
    'glanages',
    'glanâmes',
    'glanasse',
    'glanâtes',
    'glandage',
    'glandais',
    'glandait',
    'glandant',
    'glandées',
    'glandent',
    'glandera',
    'glandeur',
    'glandiez',
    'glandons',
    'glanerai',
    'glaneras',
    'glanerez',
    'glaneurs',
    'glaneuse',
    'glanions',
    'glanures',
    'glapîmes',
    'glapirai',
    'glapiras',
    'glapirez',
    'glapisse',
    'glapîtes',
    'glaréole',
    'glatîmes',
    'glatirai',
    'glatiras',
    'glatirez',
    'glatisse',
    'glatîtes',
    'glaucome',
    'glauques',
    'glaviota',
    'glaviote',
    'glavioté',
    'glaviots',
    'gléchome',
    'glécomes',
    'glénoïde',
    'glissade',
    'glissage',
    'glissais',
    'glissait',
    'glissant',
    'glissées',
    'glissent',
    'glissera',
    'glisseur',
    'glissiez',
    'glissoir',
    'glissons',
    'globales',
    'globines',
    'globules',
    'gloméris',
    'glorieux',
    'glorifia',
    'glorifie',
    'glorifié',
    'gloriole',
    'glosâmes',
    'glosasse',
    'glosâtes',
    'gloserai',
    'gloseras',
    'gloserez',
    'glosions',
    'glossine',
    'glossite',
    'glottale',
    'glottaux',
    'glouglou',
    'gloussai',
    'gloussas',
    'gloussât',
    'glousser',
    'glousses',
    'gloussez',
    'gloutons',
    'gluantes',
    'glucides',
    'glucoses',
    'glumelle',
    'glycémie',
    'glycérie',
    'glycines',
    'gnangnan',
    'gnocchis',
    'gnognote',
    'gnomique',
    'gobaient',
    'gobasses',
    'gobelets',
    'gobelins',
    'goberais',
    'goberait',
    'gobèrent',
    'gobergea',
    'gobergée',
    'goberger',
    'goberges',
    'gobergés',
    'gobergez',
    'goberiez',
    'goberons',
    'goberont',
    'gobetais',
    'gobetait',
    'gobetant',
    'gobetées',
    'gobetiez',
    'gobetons',
    'gobettes',
    'gobeuses',
    'godaient',
    'godailla',
    'godaille',
    'godaillé',
    'godasses',
    'goderais',
    'goderait',
    'godèrent',
    'goderiez',
    'goderons',
    'goderont',
    'godiches',
    'godillai',
    'godillas',
    'godillât',
    'godiller',
    'godilles',
    'godillez',
    'godillot',
    'godiveau',
    'godronna',
    'godronne',
    'godronné',
    'goélands',
    'goélette',
    'goguette',
    'goinfrai',
    'goinfras',
    'goinfrât',
    'goinfrée',
    'goinfrer',
    'goinfres',
    'goinfrés',
    'goinfrez',
    'goitreux',
    'golfeurs',
    'golfeuse',
    'golmotte',
    'goménolé',
    'goménols',
    'gominais',
    'gominait',
    'gominant',
    'gominées',
    'gominent',
    'gominera',
    'gominiez',
    'gominons',
    'gommages',
    'gommâmes',
    'gommasse',
    'gommâtes',
    'gommerai',
    'gommeras',
    'gommerez',
    'gommeuse',
    'gommiers',
    'gommions',
    'gondâmes',
    'gondasse',
    'gondâtes',
    'gonderai',
    'gonderas',
    'gonderez',
    'gondions',
    'gondolai',
    'gondolas',
    'gondolât',
    'gondoler',
    'gondoles',
    'gondolez',
    'gonelles',
    'gonfalon',
    'gonfanon',
    'gonflage',
    'gonflais',
    'gonflait',
    'gonflant',
    'gonflées',
    'gonflent',
    'gonflera',
    'gonfleur',
    'gonfliez',
    'gonflons',
    'gonnelle',
    'gonzesse',
    'gordiens',
    'gorgeais',
    'gorgeait',
    'gorgeant',
    'gorgeons',
    'gorgerai',
    'gorgeras',
    'gorgerez',
    'gorgerin',
    'gorgions',
    'gorgones',
    'gorilles',
    'gosettes',
    'gothique',
    'gotiques',
    'gouachai',
    'gouachas',
    'gouachât',
    'gouachée',
    'gouacher',
    'gouaches',
    'gouachés',
    'gouachez',
    'gouailla',
    'gouaille',
    'gouaillé',
    'goudrons',
    'gouffres',
    'gougères',
    'goujonna',
    'goujonne',
    'goujonné',
    'goulache',
    'goulafre',
    'goulasch',
    'goulotte',
    'goupilla',
    'goupille',
    'goupillé',
    'gourâmes',
    'gourance',
    'gourasse',
    'gourâtes',
    'gourdins',
    'gourerai',
    'goureras',
    'gourerez',
    'gourions',
    'gourmand',
    'gourmées',
    'gourmets',
    'goussets',
    'goûtâmes',
    'goûtasse',
    'goûtâtes',
    'goûterai',
    'goûteras',
    'goûterez',
    'goûteurs',
    'goûteuse',
    'goûtions',
    'gouttais',
    'gouttait',
    'gouttant',
    'gouttent',
    'gouttera',
    'goutteux',
    'gouttiez',
    'gouttons',
    'gouverna',
    'gouverne',
    'gouverné',
    'goyavier',
    'grabuges',
    'graciais',
    'graciait',
    'graciant',
    'graciées',
    'gracient',
    'graciera',
    'gracieux',
    'graciiez',
    'graciles',
    'gracions',
    'gradient',
    'graduais',
    'graduait',
    'graduant',
    'graduées',
    'graduels',
    'graduent',
    'graduera',
    'graduiez',
    'graduons',
    'graffiti',
    'graillai',
    'graillas',
    'graillât',
    'graillée',
    'grailler',
    'grailles',
    'graillés',
    'graillez',
    'graillon',
    'grainage',
    'grainais',
    'grainait',
    'grainant',
    'grainées',
    'grainent',
    'grainera',
    'grainier',
    'grainiez',
    'grainons',
    'graissai',
    'graissas',
    'graissât',
    'graissée',
    'graisser',
    'graisses',
    'graissés',
    'graissez',
    'graminée',
    'grandets',
    'grandeur',
    'grandies',
    'grandira',
    'granitai',
    'granitas',
    'granitât',
    'granitée',
    'graniter',
    'granites',
    'granités',
    'granitez',
    'granulai',
    'granulas',
    'granulat',
    'granulât',
    'granulée',
    'granuler',
    'granules',
    'granulés',
    'granulez',
    'granulie',
    'graphème',
    'graphies',
    'graphita',
    'graphite',
    'graphité',
    'grappins',
    'grassets',
    'grasseya',
    'grasseye',
    'grasseyé',
    'grateron',
    'gratifia',
    'gratifie',
    'gratifié',
    'gratinai',
    'gratinas',
    'gratinât',
    'gratinée',
    'gratiner',
    'gratines',
    'gratinés',
    'gratinez',
    'gratiole',
    'grattage',
    'grattais',
    'grattait',
    'grattant',
    'grattées',
    'grattent',
    'grattera',
    'gratteur',
    'grattiez',
    'grattoir',
    'grattons',
    'gratture',
    'gratuite',
    'gratuité',
    'gratuits',
    'gravâmes',
    'gravasse',
    'gravâtes',
    'gravelée',
    'gravelle',
    'graverai',
    'graveras',
    'graverez',
    'graveurs',
    'graveuse',
    'gravides',
    'graviers',
    'gravîmes',
    'gravions',
    'gravirai',
    'graviras',
    'gravirez',
    'gravisse',
    'gravitai',
    'gravitas',
    'gravitât',
    'graviter',
    'gravites',
    'gravîtes',
    'gravitez',
    'gravures',
    'gréaient',
    'gréasses',
    'grébiche',
    'grécisai',
    'grécisas',
    'grécisât',
    'grécisée',
    'gréciser',
    'grécises',
    'grécisés',
    'grécisez',
    'grecquai',
    'grecquas',
    'grecquât',
    'grecquée',
    'grecquer',
    'grecques',
    'grecqués',
    'grecquez',
    'gredines',
    'gréement',
    'gréèrent',
    'greffage',
    'greffais',
    'greffait',
    'greffant',
    'greffées',
    'greffent',
    'greffera',
    'greffeur',
    'greffier',
    'greffiez',
    'greffoir',
    'greffons',
    'grégaire',
    'grégeois',
    'grelotta',
    'grelotte',
    'grelotté',
    'grenache',
    'grenades',
    'grenadin',
    'grenages',
    'grenâmes',
    'grenasse',
    'grenâtes',
    'grenelai',
    'grenelas',
    'grenelât',
    'grenelée',
    'greneler',
    'grenelés',
    'grenelez',
    'grenelle',
    'grènerai',
    'grèneras',
    'grènerez',
    'grènetis',
    'greneurs',
    'greniers',
    'grenions',
    'grenures',
    'grésâmes',
    'grésasse',
    'grésâtes',
    'gréserai',
    'gréseras',
    'gréserez',
    'grésions',
    'gressins',
    'grevâmes',
    'grevante',
    'grevants',
    'grevasse',
    'grevâtes',
    'grèverai',
    'grèveras',
    'grèverez',
    'grevions',
    'gréviste',
    'gribiche',
    'grièches',
    'griffade',
    'griffais',
    'griffait',
    'griffant',
    'griffées',
    'griffent',
    'griffera',
    'griffeur',
    'griffiez',
    'griffons',
    'griffues',
    'griffure',
    'grignais',
    'grignait',
    'grignant',
    'grignent',
    'grignera',
    'grigniez',
    'grignons',
    'grignota',
    'grignote',
    'grignoté',
    'grillade',
    'grillage',
    'grillagé',
    'grillais',
    'grillait',
    'grillant',
    'grillées',
    'grillent',
    'grillera',
    'grilliez',
    'grilloir',
    'grillons',
    'grimaçai',
    'grimaças',
    'grimaçât',
    'grimacée',
    'grimacer',
    'grimaces',
    'grimacés',
    'grimacez',
    'grimages',
    'grimâmes',
    'grimasse',
    'grimâtes',
    'grimauds',
    'grimerai',
    'grimeras',
    'grimerez',
    'grimions',
    'grimoire',
    'grimpais',
    'grimpait',
    'grimpant',
    'grimpées',
    'grimpent',
    'grimpera',
    'grimpeur',
    'grimpiez',
    'grimpons',
    'grinçais',
    'grinçait',
    'grinçant',
    'grincent',
    'grincera',
    'grinchai',
    'grinchas',
    'grinchât',
    'grinchée',
    'grincher',
    'grinches',
    'grinchés',
    'grinchez',
    'grinciez',
    'grinçons',
    'gringuai',
    'gringuas',
    'gringuât',
    'gringuer',
    'gringues',
    'gringuez',
    'griottes',
    'grippage',
    'grippais',
    'grippait',
    'grippale',
    'grippant',
    'grippaux',
    'grippées',
    'grippent',
    'grippera',
    'grippiez',
    'grippons',
    'grisâmes',
    'grisante',
    'grisants',
    'grisards',
    'grisasse',
    'grisâtes',
    'grisâtre',
    'griserai',
    'griseras',
    'griserez',
    'griserie',
    'grisette',
    'grisions',
    'grisolai',
    'grisolas',
    'grisolât',
    'grisoler',
    'grisoles',
    'grisolez',
    'grisolla',
    'grisolle',
    'grisollé',
    'grisonna',
    'grisonne',
    'grisonné',
    'grivelai',
    'grivelas',
    'grivelât',
    'grivelée',
    'griveler',
    'grivelés',
    'grivelez',
    'grivelle',
    'griveton',
    'grivoise',
    'grizzlis',
    'grizzlys',
    'grognais',
    'grognait',
    'grognant',
    'grognard',
    'grognent',
    'grognera',
    'grogneur',
    'grogniez',
    'grognons',
    'groisils',
    'grommela',
    'grommelé',
    'grondais',
    'grondait',
    'grondant',
    'grondées',
    'grondent',
    'grondera',
    'grondiez',
    'grondins',
    'grondons',
    'grosseur',
    'grossier',
    'grossies',
    'grossira',
    'grossoie',
    'grossoya',
    'grossoyé',
    'grouille',
    'grouillé',
    'groullai',
    'groullas',
    'groullât',
    'groupage',
    'groupais',
    'groupait',
    'groupant',
    'groupées',
    'groupent',
    'groupera',
    'groupies',
    'groupiez',
    'groupons',
    'grugeais',
    'grugeait',
    'grugeant',
    'grugeoir',
    'grugeons',
    'grugerai',
    'grugeras',
    'grugerez',
    'grugions',
    'grumeaux',
    'grumelai',
    'grumelas',
    'grumelât',
    'grumelée',
    'grumeler',
    'grumelés',
    'grumelez',
    'grumelle',
    'grutiers',
    'gruyères',
    'gryphées',
    'guanacos',
    'guéables',
    'guéaient',
    'guéasses',
    'guéerais',
    'guéerait',
    'guéèrent',
    'guéeriez',
    'guéerons',
    'guéeront',
    'guenille',
    'guépards',
    'guêpière',
    'guêpiers',
    'guéridon',
    'guérilla',
    'guérîmes',
    'guérirai',
    'guériras',
    'guérirez',
    'guérison',
    'guérisse',
    'guérites',
    'guérîtes',
    'guerrier',
    'guerroie',
    'guerroya',
    'guerroyé',
    'guêtrais',
    'guêtrait',
    'guêtrant',
    'guêtrées',
    'guêtrent',
    'guêtrera',
    'guêtriez',
    'guêtrons',
    'guettais',
    'guettait',
    'guettant',
    'guettées',
    'guettent',
    'guettera',
    'guetteur',
    'guettiez',
    'guettons',
    'gueulais',
    'gueulait',
    'gueulant',
    'gueulard',
    'gueulées',
    'gueulent',
    'gueulera',
    'gueuliez',
    'gueulons',
    'gueusais',
    'gueusait',
    'gueusant',
    'gueusées',
    'gueusent',
    'gueusera',
    'gueusiez',
    'gueusons',
    'guiboles',
    'guibolle',
    'guichets',
    'guidages',
    'guidâmes',
    'guidasse',
    'guidâtes',
    'guideaux',
    'guiderai',
    'guideras',
    'guiderez',
    'guidions',
    'guignais',
    'guignait',
    'guignant',
    'guignard',
    'guignées',
    'guignent',
    'guignera',
    'guignier',
    'guigniez',
    'guignols',
    'guignons',
    'guimauve',
    'guinchai',
    'guinchas',
    'guinchât',
    'guincher',
    'guinches',
    'guinchez',
    'guindais',
    'guindait',
    'guindant',
    'guindeau',
    'guindées',
    'guindent',
    'guindera',
    'guindiez',
    'guindons',
    'guingois',
    'guipages',
    'guipâmes',
    'guipasse',
    'guipâtes',
    'guiperai',
    'guiperas',
    'guiperez',
    'guipions',
    'guipoirs',
    'guipures',
    'guisarme',
    'guitares',
    'guitoune',
    'guivrées',
    'gustatif',
    'guttural',
    'gymkhana',
    'gymnases',
    'gymnaste',
    'gymnique',
    'gymnotes',
    'gypaètes',
    'gypseuse',
    'gyrostat',
    'habanera',
    'habileté',
    'habilita',
    'habilite',
    'habilité',
    'habillai',
    'habillas',
    'habillât',
    'habillée',
    'habiller',
    'habilles',
    'habillés',
    'habillez',
    'habitais',
    'habitait',
    'habitant',
    'habitats',
    'habitées',
    'habitent',
    'habitera',
    'habitiez',
    'habitons',
    'habituai',
    'habituas',
    'habituât',
    'habitude',
    'habituée',
    'habituel',
    'habituer',
    'habitues',
    'habitués',
    'habituez',
    'hablâmes',
    'hablasse',
    'hablâtes',
    'hablerai',
    'hableras',
    'hablerez',
    'hâblerie',
    'hâbleurs',
    'hâbleuse',
    'hablions',
    'hachages',
    'hachâmes',
    'hachasse',
    'hachâtes',
    'hacherai',
    'hacheras',
    'hacherez',
    'hachette',
    'hacheurs',
    'hachions',
    'hachisch',
    'hachoirs',
    'hachurai',
    'hachuras',
    'hachurât',
    'hachurée',
    'hachurer',
    'hachures',
    'hachurés',
    'hachurez',
    'hacienda',
    'haddocks',
    'hafniums',
    'hagardes',
    'haillons',
    'haineuse',
    'haïrions',
    'haïssais',
    'haïssait',
    'haïssant',
    'haïssent',
    'haïssiez',
    'haïssons',
    'haïtiens',
    'halaient',
    'hâlaient',
    'halasses',
    'hâlasses',
    'halbrans',
    'halecret',
    'haleines',
    'halerais',
    'hâlerais',
    'halerait',
    'hâlerait',
    'halèrent',
    'hâlèrent',
    'haleriez',
    'hâleriez',
    'halerons',
    'hâlerons',
    'haleront',
    'hâleront',
    'haletais',
    'haletait',
    'haletant',
    'haletées',
    'halètent',
    'halètera',
    'haletiez',
    'haletons',
    'haleuses',
    'halictes',
    'haliples',
    'hallalis',
    'halliers',
    'halogène',
    'haltères',
    'hameçons',
    'hamsters',
    'hanchais',
    'hanchait',
    'hanchant',
    'hanchées',
    'hanchent',
    'hanchera',
    'hanchiez',
    'hanchons',
    'handball',
    'handicap',
    'hanneton',
    'hansarts',
    'hantâmes',
    'hantasse',
    'hantâtes',
    'hanterai',
    'hanteras',
    'hanterez',
    'hantions',
    'hantises',
    'haploïde',
    'happâmes',
    'happasse',
    'happâtes',
    'happerai',
    'happeras',
    'happerez',
    'happions',
    'haquenée',
    'harangua',
    'harangue',
    'harangué',
    'harassai',
    'harassas',
    'harassât',
    'harassée',
    'harasser',
    'harasses',
    'harassés',
    'harassez',
    'harcelai',
    'harcelas',
    'harcelât',
    'harcelée',
    'harceler',
    'harcelés',
    'harcèles',
    'harcelez',
    'harcelle',
    'hardâmes',
    'hardasse',
    'hardâtes',
    'harderai',
    'harderas',
    'harderez',
    'hardions',
    'harfangs',
    'hargneux',
    'haricots',
    'harissas',
    'harmonie',
    'harnacha',
    'harnache',
    'harnaché',
    'harpagon',
    'harpails',
    'harpâmes',
    'harpasse',
    'harpâtes',
    'harperai',
    'harperas',
    'harperez',
    'harpions',
    'harpiste',
    'harponna',
    'harponne',
    'harponné',
    'hasardai',
    'hasardas',
    'hasardât',
    'hasardée',
    'hasarder',
    'hasardes',
    'hasardés',
    'hasardez',
    'haschich',
    'hâtaient',
    'hâtasses',
    'hâtelets',
    'hâterais',
    'hâterait',
    'hâtereau',
    'hâtèrent',
    'hâteriez',
    'hâterons',
    'hâteront',
    'hâtiveau',
    'haubanai',
    'haubanas',
    'haubanât',
    'haubanée',
    'haubaner',
    'haubanes',
    'haubanés',
    'haubanez',
    'hauberts',
    'haussais',
    'haussait',
    'haussant',
    'haussées',
    'haussent',
    'haussera',
    'haussier',
    'haussiez',
    'haussons',
    'hautaine',
    'hautains',
    'hautbois',
    'hauteurs',
    'havaient',
    'havanais',
    'havasses',
    'haveneau',
    'havenets',
    'haverais',
    'haverait',
    'havèrent',
    'haveriez',
    'haverons',
    'haveront',
    'haveuses',
    'havirais',
    'havirait',
    'havirent',
    'haviriez',
    'havirons',
    'haviront',
    'havisses',
    'havissez',
    'havresac',
    'hawaïens',
    'heaumier',
    'hébergea',
    'hébergée',
    'héberger',
    'héberges',
    'hébergés',
    'hébergez',
    'hébétais',
    'hébétait',
    'hébétant',
    'hébétées',
    'hébètent',
    'hébétera',
    'hébétiez',
    'hébétons',
    'hébétude',
    'hébraïsa',
    'hébraïse',
    'hébraïsé',
    'hectares',
    'hectique',
    'hégélien',
    'heiduque',
    'hélaient',
    'hélasses',
    'hélépole',
    'hélerais',
    'hélerait',
    'hélèrent',
    'héleriez',
    'hélerons',
    'héleront',
    'héliaque',
    'héliaste',
    'hélicons',
    'héligare',
    'héliport',
    'hellènes',
    'hélodées',
    'helvelle',
    'helvètes',
    'hématies',
    'hématine',
    'hématite',
    'hématome',
    'hématose',
    'hémièdre',
    'hémiones',
    'hémolyse',
    'hennîmes',
    'hennirai',
    'henniras',
    'hennirez',
    'hennisse',
    'hennîtes',
    'héparine',
    'hépatite',
    'herbacée',
    'herbacés',
    'herbagea',
    'herbagée',
    'herbager',
    'herbages',
    'herbagés',
    'herbagez',
    'herbâmes',
    'herbasse',
    'herbâtes',
    'herberai',
    'herberas',
    'herberez',
    'herberie',
    'herbette',
    'herbeuse',
    'herbiers',
    'herbions',
    'hercules',
    'hérédité',
    'hérésies',
    'hérissai',
    'hérissas',
    'hérissât',
    'hérissée',
    'hérisser',
    'hérisses',
    'hérissés',
    'hérissez',
    'hérisson',
    'héritage',
    'héritais',
    'héritait',
    'héritant',
    'héritées',
    'héritent',
    'héritera',
    'héritier',
    'héritiez',
    'héritons',
    'hermesse',
    'hermines',
    'herniées',
    'hernieux',
    'héroïnes',
    'héroïque',
    'héroïsme',
    'hersages',
    'hersâmes',
    'hersasse',
    'hersâtes',
    'herserai',
    'herseras',
    'herserez',
    'herseurs',
    'herseuse',
    'hersions',
    'hésitais',
    'hésitait',
    'hésitant',
    'hésitent',
    'hésitera',
    'hésitiez',
    'hésitons',
    'hétaïres',
    'hétairie',
    'hêtraies',
    'heureuse',
    'heurtais',
    'heurtait',
    'heurtant',
    'heurtées',
    'heurtent',
    'heurtera',
    'heurtiez',
    'heurtoir',
    'heurtons',
    'hexaèdre',
    'hexagone',
    'hexapode',
    'hiatales',
    'hibernai',
    'hibernal',
    'hibernas',
    'hibernât',
    'hibernée',
    'hiberner',
    'hibernes',
    'hibernés',
    'hibernez',
    'hibiscus',
    'hickorys',
    'hidalgos',
    'hideuses',
    'hiémales',
    'hilaires',
    'hilarant',
    'hilarité',
    'himation',
    'hindoues',
    'hippique',
    'hippisme',
    'hircines',
    'hirsutes',
    'hispides',
    'hissâmes',
    'hissasse',
    'hissâtes',
    'hisserai',
    'hisseras',
    'hisserez',
    'hissions',
    'histoire',
    'historia',
    'historie',
    'historié',
    'histrion',
    'hittites',
    'hivernai',
    'hivernal',
    'hivernas',
    'hivernât',
    'hivernée',
    'hiverner',
    'hivernes',
    'hivernés',
    'hivernez',
    'hobereau',
    'hochâmes',
    'hochasse',
    'hochâtes',
    'hocherai',
    'hocheras',
    'hocherez',
    'hochions',
    'holdings',
    'hollande',
    'holmiums',
    'holocène',
    'holoside',
    'homélies',
    'homespun',
    'homicide',
    'hominisé',
    'hommages',
    'hommasse',
    'homogène',
    'homonyme',
    'hongrais',
    'hongrait',
    'hongrant',
    'hongrées',
    'hongrent',
    'hongrera',
    'hongriez',
    'hongroie',
    'hongrois',
    'hongrons',
    'hongroya',
    'hongroyé',
    'honnêtes',
    'honneurs',
    'honnîmes',
    'honnirai',
    'honniras',
    'honnirez',
    'honnisse',
    'honnîtes',
    'honorais',
    'honorait',
    'honorant',
    'honorées',
    'honorent',
    'honorera',
    'honoriez',
    'honorons',
    'honteuse',
    'hooligan',
    'hôpitaux',
    'hoplites',
    'hoquetai',
    'hoquetas',
    'hoquetât',
    'hoquetée',
    'hoqueter',
    'hoquetés',
    'hoquetez',
    'hoqueton',
    'hoquette',
    'horaires',
    'hordéine',
    'horizons',
    'horloger',
    'horloges',
    'hormonai',
    'hormonal',
    'hormonas',
    'hormonât',
    'hormonée',
    'hormoner',
    'hormones',
    'hormonés',
    'hormonez',
    'horodaté',
    'horreurs',
    'horrible',
    'horrifia',
    'horrifie',
    'horrifié',
    'hors-jeu',
    'hosannas',
    'hospices',
    'hospodar',
    'hostiles',
    'hot-dogs',
    'hôtelier',
    'hôtesses',
    'hotteret',
    'houaches',
    'houaiche',
    'houaient',
    'houasses',
    'houblons',
    'houerais',
    'houerait',
    'houèrent',
    'houeriez',
    'houerons',
    'houeront',
    'houiller',
    'houilles',
    'houlette',
    'houleuse',
    'houlques',
    'houppais',
    'houppait',
    'houppant',
    'houppées',
    'houppent',
    'houppera',
    'houppier',
    'houppiez',
    'houppons',
    'hourdage',
    'hourdais',
    'hourdait',
    'hourdant',
    'hourdées',
    'hourdent',
    'hourdera',
    'hourdies',
    'hourdiez',
    'hourdira',
    'hourdons',
    'hourques',
    'hourvari',
    'houseaux',
    'houssaie',
    'houssais',
    'houssait',
    'houssant',
    'houssées',
    'houssent',
    'houssera',
    'houssiez',
    'houssine',
    'houssoir',
    'houssons',
    'huassent',
    'huassiez',
    'huchâmes',
    'huchasse',
    'huchâtes',
    'hucherai',
    'hucheras',
    'hucherez',
    'huchions',
    'huerions',
    'huguenot',
    'huilages',
    'huilâmes',
    'huilasse',
    'huilâtes',
    'huilerai',
    'huileras',
    'huilerez',
    'huilerie',
    'huileuse',
    'huiliers',
    'huilions',
    'huissier',
    'huitaine',
    'huitains',
    'huitième',
    'huîtrier',
    'hulottes',
    'hululais',
    'hululait',
    'hululant',
    'hululées',
    'hululent',
    'hululera',
    'hululiez',
    'hululons',
    'humaient',
    'humaines',
    'humanisa',
    'humanise',
    'humanisé',
    'humanité',
    'humasses',
    'humectai',
    'humectas',
    'humectât',
    'humectée',
    'humecter',
    'humectes',
    'humectés',
    'humectez',
    'humerais',
    'humerait',
    'humérale',
    'huméraux',
    'humèrent',
    'humeriez',
    'humerons',
    'humeront',
    'humidité',
    'humiliai',
    'humilias',
    'humiliât',
    'humiliée',
    'humilier',
    'humilies',
    'humiliés',
    'humiliez',
    'humilité',
    'humorale',
    'humoraux',
    'hurdlers',
    'hurlâmes',
    'hurlante',
    'hurlants',
    'hurlasse',
    'hurlâtes',
    'hurlerai',
    'hurleras',
    'hurlerez',
    'hurleurs',
    'hurleuse',
    'hurlions',
    'huronien',
    'huronnes',
    'hussarde',
    'hussards',
    'hussites',
    'hutinets',
    'hyalines',
    'hyalites',
    'hyaloïde',
    'hybridai',
    'hybridas',
    'hybridât',
    'hybridée',
    'hybrider',
    'hybrides',
    'hybridés',
    'hybridez',
    'hydatide',
    'hydratai',
    'hydratas',
    'hydratât',
    'hydratée',
    'hydrater',
    'hydrates',
    'hydratés',
    'hydratez',
    'hydrémie',
    'hydrique',
    'hydrolat',
    'hydromel',
    'hydrures',
    'hygiènes',
    'hygromas',
    'hyménées',
    'hyoïdien',
    'hypérons',
    'hypnoses',
    'hypocras',
    'hypogées',
    'hypogyne',
    'hystérie',
    'ibérides',
    'ibérique',
    'icaquier',
    'icariens',
    'icebergs',
    'icefield',
    'ichtyols',
    'ichtyose',
    'icoglans',
    'iconique',
    'idéalisa',
    'idéalise',
    'idéalisé',
    'idéalité',
    'idéation',
    'idéelles',
    'identité',
    'idioties',
    'idiotisa',
    'idiotise',
    'idiotisé',
    'idolâtra',
    'idolâtre',
    'idolâtré',
    'ignifuge',
    'ignifugé',
    'ignition',
    'ignivome',
    'ignobles',
    'ignorais',
    'ignorait',
    'ignorant',
    'ignorées',
    'ignorent',
    'ignorera',
    'ignoriez',
    'ignorons',
    'iliaques',
    'îliennes',
    'illégale',
    'illégaux',
    'illettré',
    'illicite',
    'illimité',
    'illumina',
    'illumine',
    'illuminé',
    'illusion',
    'illustra',
    'illustre',
    'illustré',
    'îlotages',
    'îlotiers',
    'imageais',
    'imageait',
    'imageant',
    'imageons',
    'imagerai',
    'imageras',
    'imagerez',
    'imagerie',
    'imagière',
    'imagiers',
    'imaginai',
    'imaginas',
    'imaginât',
    'imaginée',
    'imaginer',
    'imagines',
    'imaginés',
    'imaginez',
    'imagions',
    'imbécile',
    'imberbes',
    'imbibais',
    'imbibait',
    'imbibant',
    'imbibées',
    'imbibent',
    'imbibera',
    'imbibiez',
    'imbibons',
    'imbriqua',
    'imbrique',
    'imbriqué',
    'imitable',
    'imitâmes',
    'imitasse',
    'imitâtes',
    'imitatif',
    'imiterai',
    'imiteras',
    'imiterez',
    'imitions',
    'immaculé',
    'immanent',
    'immature',
    'immédiat',
    'immenses',
    'immergea',
    'immergée',
    'immerger',
    'immerges',
    'immergés',
    'immergez',
    'immérité',
    'immersif',
    'immeuble',
    'immigrai',
    'immigras',
    'immigrât',
    'immigrée',
    'immigrer',
    'immigres',
    'immigrés',
    'immigrez',
    'imminent',
    'immiscée',
    'immiscer',
    'immiscés',
    'immiscez',
    'immobile',
    'immodéré',
    'immolais',
    'immolait',
    'immolant',
    'immolées',
    'immolent',
    'immolera',
    'immoliez',
    'immolons',
    'immondes',
    'immorale',
    'immoraux',
    'immortel',
    'immotivé',
    'immuable',
    'immunisa',
    'immunise',
    'immunisé',
    'immunité',
    'impactai',
    'impactas',
    'impactât',
    'impactée',
    'impacter',
    'impactes',
    'impactés',
    'impactez',
    'impaires',
    'impaludé',
    'impartie',
    'impartir',
    'impartis',
    'impartit',
    'impartît',
    'impasses',
    'impavide',
    'impayées',
    'impérial',
    'impétigo',
    'impétrai',
    'impétras',
    'impétrât',
    'impétrée',
    'impétrer',
    'impétrés',
    'impètres',
    'impétrez',
    'impiétés',
    'implanta',
    'implante',
    'implanté',
    'implants',
    'implexes',
    'impliqua',
    'implique',
    'impliqué',
    'implorai',
    'imploras',
    'implorât',
    'implorée',
    'implorer',
    'implores',
    'implorés',
    'implorez',
    'implosai',
    'implosas',
    'implosât',
    'imploser',
    'imploses',
    'implosez',
    'implosif',
    'impolies',
    'importai',
    'importas',
    'importât',
    'importée',
    'importer',
    'importes',
    'importés',
    'importez',
    'importun',
    'imposais',
    'imposait',
    'imposant',
    'imposées',
    'imposent',
    'imposera',
    'imposeur',
    'imposiez',
    'imposons',
    'impostes',
    'impotent',
    'imprécis',
    'imprégna',
    'imprégné',
    'imprègne',
    'imprévue',
    'imprévus',
    'imprimai',
    'imprimas',
    'imprimât',
    'imprimée',
    'imprimer',
    'imprimes',
    'imprimés',
    'imprimez',
    'impropre',
    'improuva',
    'improuve',
    'improuvé',
    'impubère',
    'impudent',
    'impudeur',
    'impulsai',
    'impulsas',
    'impulsât',
    'impulsée',
    'impulser',
    'impulses',
    'impulsés',
    'impulsez',
    'impulsif',
    'impunies',
    'impunité',
    'impureté',
    'imputais',
    'imputait',
    'imputant',
    'imputées',
    'imputent',
    'imputera',
    'imputiez',
    'imputons',
    'inabrité',
    'inachevé',
    'inactifs',
    'inaction',
    'inactiva',
    'inactive',
    'inactuel',
    'inadapté',
    'inaltéré',
    'inamical',
    'inanimée',
    'inanimés',
    'inanités',
    'inanitié',
    'inapaisé',
    'inaperçu',
    'inaugura',
    'inaugure',
    'inauguré',
    'inavouée',
    'inavoués',
    'incarnai',
    'incarnas',
    'incarnat',
    'incarnât',
    'incarnée',
    'incarner',
    'incarnes',
    'incarnés',
    'incarnez',
    'incendia',
    'incendie',
    'incendié',
    'incestes',
    'inchangé',
    'incident',
    'incinéra',
    'incinéré',
    'incinère',
    'incisais',
    'incisait',
    'incisant',
    'incisées',
    'incisent',
    'incisera',
    'incisiez',
    'incisifs',
    'incision',
    'incisive',
    'incisons',
    'incisure',
    'incitais',
    'incitait',
    'incitant',
    'incitées',
    'incitent',
    'incitera',
    'incitiez',
    'incitons',
    'incivile',
    'incivils',
    'inclinai',
    'inclinas',
    'inclinât',
    'inclinée',
    'incliner',
    'inclines',
    'inclinés',
    'inclinez',
    'incluais',
    'incluait',
    'incluant',
    'incluent',
    'incluiez',
    'inclûmes',
    'incluons',
    'inclurai',
    'incluras',
    'inclurez',
    'incluses',
    'inclusif',
    'inclusse',
    'inclûtes',
    'incolore',
    'incombai',
    'incombas',
    'incombât',
    'incomber',
    'incombes',
    'incombez',
    'incongru',
    'inconnue',
    'inconnus',
    'incréées',
    'incrusta',
    'incruste',
    'incrusté',
    'incubais',
    'incubait',
    'incubant',
    'incubées',
    'incubent',
    'incubera',
    'incubiez',
    'incubons',
    'inculpai',
    'inculpas',
    'inculpât',
    'inculpée',
    'inculper',
    'inculpes',
    'inculpés',
    'inculpez',
    'inculqua',
    'inculque',
    'inculqué',
    'incultes',
    'incuries',
    'incurvai',
    'incurvas',
    'incurvât',
    'incurvée',
    'incurver',
    'incurves',
    'incurvés',
    'incurvez',
    'indécent',
    'indécise',
    'indéfini',
    'indemnes',
    'indexage',
    'indexais',
    'indexait',
    'indexant',
    'indexées',
    'indexent',
    'indexera',
    'indexiez',
    'indexons',
    'indiçais',
    'indiçait',
    'indicans',
    'indiçant',
    'indicées',
    'indicent',
    'indicera',
    'indiciel',
    'indiciez',
    'indiçons',
    'indienne',
    'indigène',
    'indigent',
    'indigète',
    'indignai',
    'indignas',
    'indignât',
    'indignée',
    'indigner',
    'indignes',
    'indignés',
    'indignez',
    'indiquai',
    'indiquas',
    'indiquât',
    'indiquée',
    'indiquer',
    'indiques',
    'indiqués',
    'indiquez',
    'indirect',
    'individu',
    'indivise',
    'indocile',
    'indolent',
    'indolore',
    'indompté',
    'in-douze',
    'inductif',
    'induirai',
    'induiras',
    'induirez',
    'induises',
    'induisez',
    'induisis',
    'induisit',
    'induisît',
    'induites',
    'induline',
    'indûment',
    'indurais',
    'indurait',
    'indurant',
    'indurées',
    'indurent',
    'indurera',
    'induriez',
    'indurons',
    'indusies',
    'induvies',
    'inécouté',
    'inédites',
    'inégalée',
    'inégales',
    'inégalés',
    'inepties',
    'inépuisé',
    'inertiel',
    'inerties',
    'inespéré',
    'inétendu',
    'inexacte',
    'inexacts',
    'inexercé',
    'inexpert',
    'inexpiée',
    'inexpiés',
    'infamant',
    'infamies',
    'infantes',
    'infatuai',
    'infatuas',
    'infatuât',
    'infatuée',
    'infatuer',
    'infatues',
    'infatués',
    'infatuez',
    'infécond',
    'infectai',
    'infectas',
    'infectât',
    'infectée',
    'infecter',
    'infectes',
    'infectés',
    'infectez',
    'inféodai',
    'inféodas',
    'inféodât',
    'inféodée',
    'inféoder',
    'inféodes',
    'inféodés',
    'inféodez',
    'inférais',
    'inférait',
    'inférant',
    'inférées',
    'infèrent',
    'inférera',
    'infériez',
    'infernal',
    'inférons',
    'infestai',
    'infestas',
    'infestât',
    'infestée',
    'infester',
    'infestes',
    'infestés',
    'infestez',
    'infidèle',
    'infiltra',
    'infiltre',
    'infiltré',
    'infinies',
    'infinité',
    'infirmai',
    'infirmas',
    'infirmât',
    'infirmée',
    'infirmer',
    'infirmes',
    'infirmés',
    'infirmez',
    'infléchi',
    'infligea',
    'infligée',
    'infliger',
    'infliges',
    'infligés',
    'infligez',
    'influais',
    'influait',
    'influant',
    'influent',
    'influera',
    'influiez',
    'influons',
    'in-folio',
    'infondée',
    'infondés',
    'informai',
    'informas',
    'informât',
    'informée',
    'informel',
    'informer',
    'informes',
    'informés',
    'informez',
    'infrason',
    'infusais',
    'infusait',
    'infusant',
    'infusées',
    'infusent',
    'infusera',
    'infusiez',
    'infusion',
    'infusons',
    'ingambes',
    'ingéniée',
    'ingénier',
    'ingéniés',
    'ingéniez',
    'ingénues',
    'ingérais',
    'ingérait',
    'ingérant',
    'ingérées',
    'ingèrent',
    'ingérera',
    'ingériez',
    'ingérons',
    'ingrates',
    'inguinal',
    'inhabile',
    'inhabité',
    'inhalais',
    'inhalait',
    'inhalant',
    'inhalées',
    'inhalent',
    'inhalera',
    'inhaliez',
    'inhalons',
    'inhérent',
    'inhibais',
    'inhibait',
    'inhibant',
    'inhibées',
    'inhibent',
    'inhibera',
    'inhibiez',
    'inhibons',
    'inhumain',
    'inhumais',
    'inhumait',
    'inhumant',
    'inhumées',
    'inhument',
    'inhumera',
    'inhumiez',
    'inhumons',
    'inimitée',
    'inimités',
    'inimitié',
    'iniquité',
    'initiais',
    'initiait',
    'initiale',
    'initiant',
    'initiaux',
    'initiées',
    'initient',
    'initiera',
    'initiiez',
    'initions',
    'injectai',
    'injectas',
    'injectât',
    'injectée',
    'injecter',
    'injectes',
    'injectés',
    'injectez',
    'injuriai',
    'injurias',
    'injuriât',
    'injuriée',
    'injurier',
    'injuries',
    'injuriés',
    'injuriez',
    'injustes',
    'innéisme',
    'innéiste',
    'innéités',
    'innervai',
    'innervas',
    'innervât',
    'innervée',
    'innerver',
    'innerves',
    'innervés',
    'innervez',
    'innocent',
    'innomées',
    'innominé',
    'innommée',
    'innommés',
    'innovais',
    'innovait',
    'innovant',
    'innovées',
    'innovent',
    'innovera',
    'innoviez',
    'innovons',
    'inoccupé',
    'inoculai',
    'inoculas',
    'inoculât',
    'inoculée',
    'inoculer',
    'inocules',
    'inoculés',
    'inoculez',
    'inodores',
    'inondais',
    'inondait',
    'inondant',
    'inondées',
    'inondent',
    'inondera',
    'inondiez',
    'inondons',
    'inopinée',
    'inopinés',
    'in-plano',
    'inquarts',
    'inquiéta',
    'inquiété',
    'inquiète',
    'inquiets',
    'insanité',
    'inscrira',
    'inscrire',
    'inscrite',
    'inscrits',
    'inscrive',
    'insculpa',
    'insculpe',
    'insculpé',
    'insectes',
    'in-seize',
    'insémina',
    'insémine',
    'inséminé',
    'insensée',
    'insensés',
    'insérais',
    'insérait',
    'insérant',
    'insérées',
    'insèrent',
    'insérera',
    'insériez',
    'insérons',
    'insignes',
    'insinuai',
    'insinuas',
    'insinuât',
    'insinuée',
    'insinuer',
    'insinues',
    'insinués',
    'insinuez',
    'insipide',
    'insistai',
    'insistas',
    'insistât',
    'insister',
    'insistes',
    'insistez',
    'insolais',
    'insolait',
    'insolant',
    'insolées',
    'insolent',
    'insolera',
    'insoliez',
    'insolite',
    'insolons',
    'insomnie',
    'insonore',
    'insoumis',
    'inspecta',
    'inspecte',
    'inspecté',
    'inspirai',
    'inspiras',
    'inspirât',
    'inspirée',
    'inspirer',
    'inspires',
    'inspirés',
    'inspirez',
    'instable',
    'installa',
    'installe',
    'installé',
    'instance',
    'instante',
    'instants',
    'instaura',
    'instaure',
    'instauré',
    'instigua',
    'instigue',
    'instigué',
    'instilla',
    'instille',
    'instillé',
    'instinct',
    'institua',
    'institue',
    'institué',
    'institut',
    'instruis',
    'instruit',
    'insuccès',
    'insuffla',
    'insuffle',
    'insufflé',
    'insuline',
    'insultai',
    'insultas',
    'insultât',
    'insultée',
    'insulter',
    'insultes',
    'insultés',
    'insultez',
    'insurgée',
    'insurger',
    'insurgés',
    'insurgez',
    'intactes',
    'intailla',
    'intaille',
    'intaillé',
    'intégrai',
    'intégral',
    'intégras',
    'intégrât',
    'intégrée',
    'intégrer',
    'intégrés',
    'intègres',
    'intégrez',
    'intenses',
    'intensif',
    'intentai',
    'intentas',
    'intentât',
    'intentée',
    'intenter',
    'intentes',
    'intentés',
    'intentez',
    'interdis',
    'interdit',
    'interdît',
    'intérêts',
    'intérims',
    'internai',
    'internas',
    'internat',
    'internât',
    'internée',
    'interner',
    'internes',
    'internés',
    'internet',
    'Internet',
    'internez',
    'intestat',
    'intestin',
    'intimais',
    'intimait',
    'intimant',
    'intimées',
    'intiment',
    'intimera',
    'intimida',
    'intimide',
    'intimidé',
    'intimiez',
    'intimité',
    'intimons',
    'intitula',
    'intitule',
    'intitulé',
    'intrados',
    'intrigua',
    'intrigue',
    'intrigué',
    'intriqua',
    'intrique',
    'intriqué',
    'introïts',
    'intruses',
    'intubais',
    'intubait',
    'intubant',
    'intubées',
    'intubent',
    'intubera',
    'intubiez',
    'intubons',
    'intuitif',
    'inulines',
    'inusable',
    'inusitée',
    'inusités',
    'inusuels',
    'inutiles',
    'invagina',
    'invagine',
    'invaginé',
    'invaincu',
    'invalida',
    'invalide',
    'invalidé',
    'invasion',
    'invendue',
    'invendus',
    'inventai',
    'inventas',
    'inventât',
    'inventée',
    'inventer',
    'inventes',
    'inventés',
    'inventez',
    'inventif',
    'inversai',
    'inversas',
    'inversât',
    'inversée',
    'inverser',
    'inverses',
    'inversés',
    'inversez',
    'inversif',
    'invertie',
    'invertir',
    'invertis',
    'invertit',
    'invertît',
    'investie',
    'investir',
    'investis',
    'investit',
    'investît',
    'invétéré',
    'inviolée',
    'inviolés',
    'invitais',
    'invitait',
    'invitant',
    'invitées',
    'invitent',
    'invitera',
    'invitiez',
    'invitons',
    'involuté',
    'invoquai',
    'invoquas',
    'invoquât',
    'invoquée',
    'invoquer',
    'invoques',
    'invoqués',
    'invoquez',
    'iodaient',
    'iodasses',
    'ioderais',
    'ioderait',
    'iodèrent',
    'ioderiez',
    'ioderons',
    'ioderont',
    'iodiques',
    'iodismes',
    'iodlâmes',
    'iodlasse',
    'iodlâtes',
    'iodlerai',
    'iodleras',
    'iodlerez',
    'iodlions',
    'iodurées',
    'ionienne',
    'ioniques',
    'ionisais',
    'ionisait',
    'ionisant',
    'ionisées',
    'ionisent',
    'ionisera',
    'ionisiez',
    'ionisons',
    'ioulâmes',
    'ioulasse',
    'ioulâtes',
    'ioulerai',
    'iouleras',
    'ioulerez',
    'ioulions',
    'irakiens',
    'iraniens',
    'iraqiens',
    'irénique',
    'irénisme',
    'iridiées',
    'iridiens',
    'iridiums',
    'irisable',
    'irisâmes',
    'irisasse',
    'irisâtes',
    'iriserai',
    'iriseras',
    'iriserez',
    'irisions',
    'ironique',
    'ironisai',
    'ironisas',
    'ironisât',
    'ironiser',
    'ironises',
    'ironisez',
    'ironiste',
    'iroquois',
    'irradiai',
    'irradias',
    'irradiât',
    'irradiée',
    'irradier',
    'irradies',
    'irradiés',
    'irradiez',
    'irréelle',
    'irréfuté',
    'irrésolu',
    'irriguai',
    'irriguas',
    'irriguât',
    'irriguée',
    'irriguer',
    'irrigues',
    'irrigués',
    'irriguez',
    'irritais',
    'irritait',
    'irritant',
    'irritées',
    'irritent',
    'irritera',
    'irritiez',
    'irritons',
    'ischémie',
    'ischions',
    'isiaques',
    'islamisa',
    'islamise',
    'islamisé',
    'isobares',
    'isobathe',
    'isocarde',
    'isocèles',
    'isochore',
    'isocline',
    'isogames',
    'isogamie',
    'isogones',
    'isohyète',
    'isohypse',
    'isolable',
    'isolâmes',
    'isolante',
    'isolants',
    'isolasse',
    'isolâtes',
    'isolerai',
    'isoleras',
    'isolerez',
    'isolions',
    'isologue',
    'isoloirs',
    'isomères',
    'isomérie',
    'isonomie',
    'isopodes',
    'isosiste',
    'isotonie',
    'isotopes',
    'issantes',
    'italiens',
    'italique',
    'itérâmes',
    'itérasse',
    'itérâtes',
    'itératif',
    'itérerai',
    'itéreras',
    'itérerez',
    'itérions',
    'ivoirien',
    'ivoirier',
    'ivoirine',
    'ivoirins',
    'ivresses',
    'ivrognes',
    'jablâmes',
    'jablasse',
    'jablâtes',
    'jablerai',
    'jableras',
    'jablerez',
    'jablière',
    'jablions',
    'jabloire',
    'jabloirs',
    'jabotais',
    'jabotait',
    'jabotant',
    'jabotées',
    'jabotent',
    'jabotera',
    'jabotiez',
    'jabotons',
    'jacassai',
    'jacassas',
    'jacassât',
    'jacasser',
    'jacasses',
    'jacassez',
    'jachères',
    'jacinthe',
    'jacobées',
    'jacobine',
    'jacobins',
    'jacquard',
    'jacquées',
    'jacquets',
    'jacquier',
    'jacquots',
    'jactâmes',
    'jactance',
    'jactasse',
    'jactâtes',
    'jacterai',
    'jacteras',
    'jacterez',
    'jactions',
    'jadéites',
    'jaillira',
    'jaïnisme',
    'jalonnai',
    'jalonnas',
    'jalonnât',
    'jalonnée',
    'jalonner',
    'jalonnes',
    'jalonnés',
    'jalonnez',
    'jalousai',
    'jalousas',
    'jalousât',
    'jalousée',
    'jalouser',
    'jalouses',
    'jalousés',
    'jalousez',
    'jalousie',
    'jambages',
    'jambette',
    'jambière',
    'jambiers',
    'jamboree',
    'jamboses',
    'jangadas',
    'japonais',
    'japonnai',
    'japonnas',
    'japonnât',
    'japonnée',
    'japonner',
    'japonnes',
    'japonnés',
    'japonnez',
    'jappâmes',
    'jappasse',
    'jappâtes',
    'japperai',
    'japperas',
    'japperez',
    'jappeurs',
    'jappeuse',
    'jappions',
    'jaquette',
    'jaquiers',
    'jardinai',
    'jardinas',
    'jardinât',
    'jardinée',
    'jardiner',
    'jardines',
    'jardinés',
    'jardinet',
    'jardinez',
    'jargonna',
    'jargonne',
    'jargonné',
    'jarosses',
    'jarretai',
    'jarretas',
    'jarretât',
    'jarretée',
    'jarreter',
    'jarretés',
    'jarretez',
    'jarrette',
    'jasaient',
    'jasasses',
    'jaserais',
    'jaserait',
    'jaserans',
    'jasèrent',
    'jaseriez',
    'jaserons',
    'jaseront',
    'jaseuses',
    'jaspâmes',
    'jaspasse',
    'jaspâtes',
    'jasperai',
    'jasperas',
    'jasperez',
    'jaspinai',
    'jaspinas',
    'jaspinât',
    'jaspinée',
    'jaspiner',
    'jaspines',
    'jaspinés',
    'jaspinez',
    'jaspions',
    'jaspures',
    'jaugeage',
    'jaugeais',
    'jaugeait',
    'jaugeant',
    'jaugeons',
    'jaugerai',
    'jaugeras',
    'jaugerez',
    'jaugeurs',
    'jaugions',
    'jaumière',
    'jaunâtre',
    'jaunette',
    'jaunîmes',
    'jaunirai',
    'jauniras',
    'jaunirez',
    'jaunisse',
    'jaunîtes',
    'javanais',
    'javelage',
    'javelais',
    'javelait',
    'javelant',
    'javelées',
    'javeleur',
    'javeliez',
    'javeline',
    'javelles',
    'javelons',
    'javelots',
    'jectisse',
    'jéjunums',
    'jerkâmes',
    'jerkasse',
    'jerkâtes',
    'jerkerai',
    'jerkeras',
    'jerkerez',
    'jerkions',
    'jéroboam',
    'jerrican',
    'jerrycan',
    'jésuites',
    'jetables',
    'jetaient',
    'jetasses',
    'jetèrent',
    'jeteuses',
    'jetterai',
    'jetteras',
    'jetterez',
    'jeûnâmes',
    'jeûnasse',
    'jeûnâtes',
    'jeûnerai',
    'jeûneras',
    'jeûnerez',
    'jeunesse',
    'jeunette',
    'jeûneurs',
    'jeûneuse',
    'jeûnions',
    'jeunotte',
    'jobardai',
    'jobardas',
    'jobardât',
    'jobardée',
    'jobarder',
    'jobardes',
    'jobardés',
    'jobardez',
    'jobelins',
    'jocasses',
    'jocistes',
    'jocrisse',
    'jodhpurs',
    'jodlâmes',
    'jodlasse',
    'jodlâtes',
    'jodlerai',
    'jodleras',
    'jodlerez',
    'jodlions',
    'joggings',
    'joignais',
    'joignait',
    'joignant',
    'joignent',
    'joigniez',
    'joignons',
    'joindrai',
    'joindras',
    'joindrez',
    'jointifs',
    'jointive',
    'jointoie',
    'jointoya',
    'jointoyé',
    'jointure',
    'joliesse',
    'joliment',
    'jonçâmes',
    'jonçasse',
    'jonçâtes',
    'joncerai',
    'jonceras',
    'joncerez',
    'jonchais',
    'jonchait',
    'jonchant',
    'jonchées',
    'jonchent',
    'jonchera',
    'jonchère',
    'jonchets',
    'jonchiez',
    'jonchons',
    'joncions',
    'jonction',
    'jonglais',
    'jonglait',
    'jonglant',
    'jonglées',
    'jonglent',
    'jonglera',
    'jongleur',
    'jongliez',
    'jonglons',
    'jonkheer',
    'jontoyez',
    'jouables',
    'jouaient',
    'jouailla',
    'jouaille',
    'jouaillé',
    'jouasses',
    'joubarbe',
    'jouerais',
    'jouerait',
    'jouèrent',
    'joueriez',
    'jouerons',
    'joueront',
    'joueuses',
    'joufflue',
    'joufflus',
    'jouirais',
    'jouirait',
    'jouirent',
    'jouiriez',
    'jouirons',
    'jouiront',
    'jouisses',
    'jouissez',
    'jouissif',
    'journaux',
    'journées',
    'joutâmes',
    'joutasse',
    'joutâtes',
    'jouterai',
    'jouteras',
    'jouterez',
    'jouteurs',
    'jouteuse',
    'joutions',
    'jouvence',
    'jouxtais',
    'jouxtait',
    'jouxtant',
    'jouxtées',
    'jouxtent',
    'jouxtera',
    'jouxtiez',
    'jouxtons',
    'joviales',
    'jovienne',
    'joyeuses',
    'jubartes',
    'jubilais',
    'jubilait',
    'jubilant',
    'jubilent',
    'jubilera',
    'jubiliez',
    'jubilons',
    'juchâmes',
    'juchasse',
    'juchâtes',
    'jucherai',
    'jucheras',
    'jucherez',
    'juchions',
    'juchoirs',
    'judaïque',
    'judaïsme',
    'judaïtés',
    'judéités',
    'judelles',
    'jugeable',
    'jugeâmes',
    'jugeasse',
    'jugeâtes',
    'jugement',
    'jugeotes',
    'jugerais',
    'jugerait',
    'jugèrent',
    'jugeriez',
    'jugerons',
    'jugeront',
    'jugeuses',
    'jugulais',
    'jugulait',
    'jugulant',
    'jugulées',
    'jugulent',
    'jugulera',
    'juguliez',
    'jugulons',
    'juiverie',
    'jujubier',
    'juke-box',
    'julienne',
    'jumelage',
    'jumelais',
    'jumelait',
    'jumelant',
    'jumelées',
    'jumeliez',
    'jumelles',
    'jumelons',
    'jumpings',
    'junonien',
    'jupettes',
    'juponnai',
    'juponnas',
    'juponnât',
    'juponnée',
    'juponner',
    'juponnes',
    'juponnés',
    'juponnez',
    'juraient',
    'jurasses',
    'jurement',
    'jurerais',
    'jurerait',
    'jurèrent',
    'jureriez',
    'jurerons',
    'jureront',
    'juristes',
    'jussiées',
    'jussions',
    'justesse',
    'justices',
    'justifia',
    'justifie',
    'justifié',
    'jutaient',
    'jutasses',
    'juterais',
    'juterait',
    'jutèrent',
    'juteriez',
    'juterons',
    'juteront',
    'juteuses',
    'juvénats',
    'juvénile',
    'kabbales',
    'kafkaïen',
    'kaïnites',
    'kakatoès',
    'kakémono',
    'kaliémie',
    'kalmouke',
    'kamichis',
    'kamikaze',
    'kandjars',
    'kantiens',
    'kantisme',
    'kaoliang',
    'kapokier',
    'karakuls',
    'karatéka',
    'kartings',
    'keepsake',
    'keffiehs',
    'kénotron',
    'kérabaus',
    'kératine',
    'kératite',
    'kératose',
    'kermesse',
    'kérogène',
    'kérosène',
    'ketchups',
    'khâgneux',
    'khalifat',
    'khalifes',
    'khamsins',
    'khédival',
    'khédivat',
    'khédives',
    'kibboutz',
    'kidnappa',
    'kidnappe',
    'kidnappé',
    'kilovolt',
    'kilowatt',
    'kinkajou',
    'kiosques',
    'klaxonna',
    'klaxonne',
    'klaxonné',
    'klephtes',
    'klystron',
    'knickers',
    'kolatier',
    'kolinski',
    'kolkhoze',
    'konzerns',
    'korrigan',
    'kouglofs',
    'kreutzer',
    'kroumirs',
    'kufiques',
    'kumquats',
    'kymrique',
    'kyrielle',
    'kystique',
    'labadens',
    'labarums',
    'labdanum',
    'labelles',
    'labiales',
    'labourai',
    'labouras',
    'labourât',
    'labourée',
    'labourer',
    'laboures',
    'labourés',
    'labourez',
    'labrador',
    'laçaient',
    'laçasses',
    'laccases',
    'lacement',
    'lacerais',
    'lacérais',
    'lacerait',
    'lacérait',
    'lacérant',
    'lacérées',
    'lacèrent',
    'lacérera',
    'laceries',
    'laceriez',
    'lacériez',
    'lacerons',
    'lacérons',
    'laceront',
    'laceuses',
    'lâchages',
    'lâchâmes',
    'lâchasse',
    'lâchâtes',
    'lâcherai',
    'lâcheras',
    'lâcherez',
    'lâchetés',
    'lâcheurs',
    'lâcheuse',
    'lâchions',
    'laciniée',
    'laciniés',
    'lacrymal',
    'lactaire',
    'lactases',
    'lactates',
    'lactique',
    'lactoses',
    'lacuneux',
    'lacustre',
    'ladanums',
    'ladrerie',
    'lagopède',
    'laïcisai',
    'laïcisas',
    'laïcisât',
    'laïcisée',
    'laïciser',
    'laïcises',
    'laïcisés',
    'laïcisez',
    'laïcisme',
    'laïcités',
    'laideron',
    'laideurs',
    'laierais',
    'laierait',
    'laieriez',
    'laierons',
    'laieront',
    'lainages',
    'lainâmes',
    'lainasse',
    'lainâtes',
    'lainerai',
    'laineras',
    'lainerez',
    'lainerie',
    'laineurs',
    'laineuse',
    'lainière',
    'lainiers',
    'lainions',
    'laissais',
    'laissait',
    'laissant',
    'laissées',
    'laissent',
    'laissera',
    'laissiez',
    'laissons',
    'laitages',
    'laitance',
    'laiterie',
    'laiteron',
    'laiteuse',
    'laitière',
    'laitiers',
    'laitonna',
    'laitonne',
    'laitonné',
    'lakistes',
    'lamaient',
    'lamaïsme',
    'lamaïste',
    'lamanage',
    'lamaneur',
    'lamantin',
    'lamasses',
    'lambeaux',
    'lambinai',
    'lambinas',
    'lambinât',
    'lambiner',
    'lambines',
    'lambinez',
    'lamellée',
    'lamelles',
    'lamellés',
    'lamentai',
    'lamentas',
    'lamentât',
    'lamentée',
    'lamenter',
    'lamentes',
    'lamentés',
    'lamentez',
    'lamentos',
    'lamerais',
    'lamerait',
    'lamèrent',
    'lameriez',
    'lamerons',
    'lameront',
    'lamifiée',
    'lamifiés',
    'laminage',
    'laminais',
    'laminait',
    'laminant',
    'laminées',
    'laminent',
    'laminera',
    'lamineur',
    'lamineux',
    'laminiez',
    'laminoir',
    'laminons',
    'lampâmes',
    'lamparos',
    'lampasse',
    'lampassé',
    'lampâtes',
    'lamperai',
    'lamperas',
    'lamperez',
    'lampions',
    'lampiste',
    'lamproie',
    'lampyres',
    'lançages',
    'lançâmes',
    'lançasse',
    'lançâtes',
    'lancéolé',
    'lancerai',
    'lanceras',
    'lancerez',
    'lancette',
    'lanceurs',
    'lanceuse',
    'lanciers',
    'lancinai',
    'lancinas',
    'lancinât',
    'lancinée',
    'lanciner',
    'lancines',
    'lancinés',
    'lancinez',
    'lancions',
    'landaise',
    'landiers',
    'langages',
    'langeais',
    'langeait',
    'langeant',
    'langeons',
    'langerai',
    'langeras',
    'langerez',
    'langions',
    'langueur',
    'langueya',
    'langueye',
    'langueyé',
    'languide',
    'languier',
    'languies',
    'languira',
    'lanières',
    'lanifère',
    'lanigère',
    'lanlaire',
    'lanoline',
    'lanterna',
    'lanterne',
    'lanterné',
    'lanthane',
    'laotiens',
    'lapaient',
    'lapasses',
    'lapement',
    'laperais',
    'laperait',
    'lapereau',
    'lapèrent',
    'laperiez',
    'laperons',
    'laperont',
    'lapicide',
    'lapidais',
    'lapidait',
    'lapidant',
    'lapidées',
    'lapident',
    'lapidera',
    'lapidiez',
    'lapidons',
    'laquages',
    'laquâmes',
    'laquante',
    'laquants',
    'laquasse',
    'laquâtes',
    'laquelle',
    'laquerai',
    'laqueras',
    'laquerez',
    'laqueurs',
    'laqueuse',
    'laquions',
    'laraires',
    'lardâmes',
    'lardasse',
    'lardâtes',
    'larderai',
    'larderas',
    'larderez',
    'lardions',
    'lardoire',
    'lardonna',
    'lardonne',
    'lardonné',
    'largable',
    'largages',
    'largesse',
    'largeurs',
    'larguais',
    'larguait',
    'larguant',
    'larguées',
    'larguent',
    'larguera',
    'larguiez',
    'larguons',
    'larigots',
    'larmiers',
    'larmoies',
    'larmoyai',
    'larmoyas',
    'larmoyât',
    'larmoyée',
    'larmoyer',
    'larmoyés',
    'larmoyez',
    'larvaire',
    'laryngée',
    'laryngés',
    'lasagnes',
    'lascives',
    'lassâmes',
    'lassante',
    'lassants',
    'lassasse',
    'lassâtes',
    'lasserai',
    'lasseras',
    'lasserez',
    'lassions',
    'lastings',
    'latanier',
    'latences',
    'latentes',
    'latérale',
    'latéraux',
    'latérite',
    'latinisa',
    'latinise',
    'latinisé',
    'latinité',
    'latitude',
    'latomies',
    'latrines',
    'lattages',
    'lattâmes',
    'lattasse',
    'lattâtes',
    'latterai',
    'latteras',
    'latterez',
    'lattions',
    'laudanum',
    'laudatif',
    'lauréate',
    'lauréats',
    'lauriers',
    'lavables',
    'lavaient',
    'lavandes',
    'lavandin',
    'lavarets',
    'lavasses',
    'lavatory',
    'lavement',
    'laverais',
    'laverait',
    'lavèrent',
    'laveries',
    'laveriez',
    'laverons',
    'laveront',
    'lavettes',
    'laveuses',
    'laxatifs',
    'laxative',
    'laxismes',
    'laxistes',
    'layaient',
    'layasses',
    'layerais',
    'layerait',
    'layèrent',
    'layeriez',
    'layerons',
    'layeront',
    'layetier',
    'layettes',
    'lazarets',
    'lazulite',
    'lazurite',
    'leasings',
    'léchages',
    'léchâmes',
    'léchasse',
    'léchâtes',
    'lécherai',
    'lécheras',
    'lécherez',
    'lécheurs',
    'lécheuse',
    'léchions',
    'lecteurs',
    'lectorat',
    'lectrice',
    'lectures',
    'légalisa',
    'légalise',
    'légalisé',
    'légalité',
    'légation',
    'légendes',
    'légèreté',
    'leggings',
    'leghorns',
    'légiféra',
    'légiféré',
    'légifère',
    'légistes',
    'légitima',
    'légitime',
    'légitimé',
    'léguâmes',
    'léguasse',
    'léguâtes',
    'léguerai',
    'légueras',
    'léguerez',
    'léguions',
    'légumier',
    'légumine',
    'lemmings',
    'lénifiai',
    'lénifias',
    'lénifiât',
    'lénifiée',
    'lénifier',
    'lénifies',
    'lénifiés',
    'lénifiez',
    'lénitifs',
    'lénitive',
    'lenteurs',
    'lentigos',
    'lentille',
    'léonarde',
    'léonards',
    'léonines',
    'léonures',
    'léopardé',
    'léopards',
    'lépiotes',
    'lépismes',
    'lépreuse',
    'leptures',
    'lésaient',
    'lésantes',
    'lésasses',
    'lesbiens',
    'lesdites',
    'léserais',
    'léserait',
    'lésèrent',
    'léseriez',
    'léserons',
    'léseront',
    'lésinais',
    'lésinait',
    'lésinant',
    'lésinent',
    'lésinera',
    'lésineur',
    'lésiniez',
    'lésinons',
    'lesquels',
    'lessivai',
    'lessivas',
    'lessivât',
    'lessivée',
    'lessiver',
    'lessives',
    'lessivés',
    'lessivez',
    'lestages',
    'lestâmes',
    'lestasse',
    'lestâtes',
    'lesterai',
    'lesteras',
    'lesterez',
    'lestions',
    'létalité',
    'lettones',
    'lettonne',
    'lettrage',
    'lettrées',
    'lettrine',
    'leucanie',
    'leucémie',
    'leucines',
    'leucites',
    'leucomes',
    'leucoses',
    'leurrais',
    'leurrait',
    'leurrant',
    'leurrées',
    'leurrent',
    'leurrera',
    'leurriez',
    'leurrons',
    'levaient',
    'levantin',
    'levasses',
    'lèverais',
    'lèverait',
    'levèrent',
    'lèveriez',
    'lèverons',
    'lèveront',
    'lévigeai',
    'lévigeas',
    'lévigeât',
    'lévigées',
    'lévigent',
    'lévigera',
    'lévigiez',
    'lévirats',
    'levrauts',
    'levretta',
    'levrette',
    'levretté',
    'lévriers',
    'levronne',
    'lévulose',
    'lexicale',
    'lexicaux',
    'lexiques',
    'lézardai',
    'lézardas',
    'lézardât',
    'lézardée',
    'lézarder',
    'lézardes',
    'lézardés',
    'lézardez',
    'liaisons',
    'liardais',
    'liardait',
    'liardant',
    'liardent',
    'liardera',
    'liardiez',
    'liardons',
    'liasique',
    'liassent',
    'liassiez',
    'libanais',
    'libation',
    'libellai',
    'libellas',
    'libellât',
    'libellée',
    'libeller',
    'libelles',
    'libellés',
    'libellez',
    'libérais',
    'libérait',
    'libérale',
    'libérant',
    'libéraux',
    'libérées',
    'libèrent',
    'libérera',
    'libérien',
    'libériez',
    'libérons',
    'libertés',
    'libertin',
    'libraire',
    'libretti',
    'libretto',
    'libyenne',
    'licences',
    'licencia',
    'licencie',
    'licencié',
    'lichâmes',
    'lichasse',
    'lichâtes',
    'licherai',
    'licheras',
    'licherez',
    'lichette',
    'lichions',
    'licitais',
    'licitait',
    'licitant',
    'licitées',
    'licitent',
    'licitera',
    'licitiez',
    'licitons',
    'licornes',
    'licteurs',
    'liégeais',
    'liégeait',
    'liégeant',
    'liégeois',
    'liégeons',
    'liégerai',
    'liégeras',
    'liégerez',
    'liégions',
    'lierions',
    'lieu-dit',
    'lieudits',
    'liftâmes',
    'liftasse',
    'liftâtes',
    'lifterai',
    'lifteras',
    'lifterez',
    'liftière',
    'liftiers',
    'liftings',
    'liftions',
    'ligament',
    'ligatura',
    'ligature',
    'ligaturé',
    'lignages',
    'lignâmes',
    'lignards',
    'lignasse',
    'lignâtes',
    'lignerai',
    'ligneras',
    'lignerez',
    'ligneuls',
    'ligneuse',
    'lignifia',
    'lignifie',
    'lignifié',
    'lignines',
    'lignions',
    'lignites',
    'ligotage',
    'ligotais',
    'ligotait',
    'ligotant',
    'ligotées',
    'ligotent',
    'ligotera',
    'ligotiez',
    'ligotons',
    'liguâmes',
    'liguasse',
    'liguâtes',
    'liguerai',
    'ligueras',
    'liguerez',
    'ligueurs',
    'ligueuse',
    'liguions',
    'ligulées',
    'liliacée',
    'liliacés',
    'liliales',
    'limaçons',
    'limaient',
    'limaille',
    'limandai',
    'limandas',
    'limandât',
    'limandée',
    'limander',
    'limandes',
    'limandés',
    'limandez',
    'limasses',
    'limbaire',
    'limerais',
    'limerait',
    'limèrent',
    'limerick',
    'limeriez',
    'limerons',
    'limeront',
    'limettes',
    'limeuses',
    'limicole',
    'liminale',
    'liminaux',
    'limitais',
    'limitait',
    'limitant',
    'limitées',
    'limitent',
    'limitera',
    'limiteur',
    'limitiez',
    'limitons',
    'limogeai',
    'limogeas',
    'limogeât',
    'limogées',
    'limogent',
    'limogera',
    'limogiez',
    'limonade',
    'limonage',
    'limonais',
    'limonait',
    'limonant',
    'limonées',
    'limonène',
    'limonent',
    'limonera',
    'limoneux',
    'limonier',
    'limoniez',
    'limonite',
    'limonons',
    'limousin',
    'limpides',
    'linaires',
    'linceuls',
    'linéaire',
    'linéales',
    'linettes',
    'lingeais',
    'lingeait',
    'lingeant',
    'lingeons',
    'lingerai',
    'lingeras',
    'lingères',
    'lingerez',
    'lingerie',
    'lingions',
    'linguale',
    'linguaux',
    'linières',
    'liniment',
    'linkages',
    'linnéens',
    'linoléum',
    'linottes',
    'linotype',
    'linsangs',
    'linsoirs',
    'linteaux',
    'lionceau',
    'lipémies',
    'lipoïdes',
    'lipolyse',
    'liquéfia',
    'liquéfie',
    'liquéfié',
    'liquette',
    'liqueurs',
    'liquidai',
    'liquidas',
    'liquidât',
    'liquidée',
    'liquider',
    'liquides',
    'liquidés',
    'liquidez',
    'liraient',
    'lisaient',
    'lisérage',
    'liserais',
    'lisérais',
    'liserait',
    'lisérait',
    'liserant',
    'lisérant',
    'liserées',
    'lisérées',
    'lisèrent',
    'lisérera',
    'lisèrera',
    'liseriez',
    'lisériez',
    'liserons',
    'lisérons',
    'liseuses',
    'lisibles',
    'lisières',
    'lissages',
    'lissâmes',
    'lissante',
    'lissants',
    'lissasse',
    'lissâtes',
    'lisserai',
    'lisseras',
    'lisserez',
    'lisseurs',
    'lisseuse',
    'lissiers',
    'lissions',
    'lissoirs',
    'listages',
    'listâmes',
    'listasse',
    'listâtes',
    'listeaux',
    'listerai',
    'listeras',
    'listerez',
    'listings',
    'listions',
    'litaient',
    'litanies',
    'litasses',
    'literais',
    'literait',
    'litèrent',
    'literies',
    'literiez',
    'literons',
    'literont',
    'litharge',
    'lithiase',
    'lithinée',
    'lithinés',
    'lithique',
    'lithiums',
    'litières',
    'litornes',
    'littéral',
    'littoral',
    'liturgie',
    'livarots',
    'livèches',
    'lividité',
    'livrable',
    'livrâmes',
    'livrasse',
    'livrâtes',
    'livrerai',
    'livreras',
    'livrerez',
    'livreurs',
    'livreuse',
    'livrions',
    'lobaient',
    'lobaires',
    'lobasses',
    'lobélies',
    'loberais',
    'loberait',
    'lobèrent',
    'loberiez',
    'loberons',
    'loberont',
    'lobulées',
    'lobuleux',
    'localisa',
    'localise',
    'localisé',
    'localité',
    'locatifs',
    'location',
    'locative',
    'lochâmes',
    'lochasse',
    'lochâtes',
    'locherai',
    'locheras',
    'locherez',
    'lochions',
    'lock-out',
    'loculées',
    'loculeux',
    'locustes',
    'locuteur',
    'locution',
    'lofaient',
    'lofasses',
    'loferais',
    'loferait',
    'lofèrent',
    'loferiez',
    'loferons',
    'loferont',
    'logeable',
    'logeâmes',
    'logeasse',
    'logeâtes',
    'logement',
    'logerais',
    'logerait',
    'logèrent',
    'logeriez',
    'logerons',
    'logeront',
    'logettes',
    'logeuses',
    'logiciel',
    'logicien',
    'logiques',
    'logistes',
    'lointain',
    'loisible',
    'lombagos',
    'lombaire',
    'lombarde',
    'lombards',
    'lombrics',
    'longanes',
    'longeais',
    'longeait',
    'longeant',
    'longeons',
    'longerai',
    'longeras',
    'longerez',
    'longeron',
    'longions',
    'longotte',
    'longrine',
    'longuets',
    'longueur',
    'loopings',
    'lopettes',
    'loquaces',
    'loquâmes',
    'loquasse',
    'loquâtes',
    'loquerai',
    'loqueras',
    'loquerez',
    'loquions',
    'lordoses',
    'lorettes',
    'lorgnais',
    'lorgnait',
    'lorgnant',
    'lorgnées',
    'lorgnent',
    'lorgnera',
    'lorgniez',
    'lorgnons',
    'lorraine',
    'lorrains',
    'losangée',
    'losanges',
    'losangés',
    'loteries',
    'lotionna',
    'lotionne',
    'lotionné',
    'lotirais',
    'lotirait',
    'lotirent',
    'lotiriez',
    'lotirons',
    'lotiront',
    'lotisses',
    'lotissez',
    'louables',
    'louaient',
    'louangea',
    'louangée',
    'louanger',
    'louanges',
    'louangés',
    'louangez',
    'louasses',
    'loubards',
    'louchais',
    'louchait',
    'louchant',
    'louchent',
    'louchera',
    'louchets',
    'loucheur',
    'louchiez',
    'louchira',
    'louchons',
    'louerais',
    'louerait',
    'louèrent',
    'loueriez',
    'louerons',
    'loueront',
    'loueuses',
    'loufâmes',
    'loufasse',
    'loufâtes',
    'louferai',
    'louferas',
    'louferez',
    'loufiats',
    'loufions',
    'loufoque',
    'loukoums',
    'loupages',
    'loupâmes',
    'loupasse',
    'loupâtes',
    'louperai',
    'louperas',
    'louperez',
    'loupions',
    'loupiote',
    'loupiots',
    'lourâmes',
    'lourasse',
    'lourâtes',
    'lourdais',
    'lourdait',
    'lourdant',
    'lourdaud',
    'lourdées',
    'lourdent',
    'lourdera',
    'lourdeur',
    'lourdiez',
    'lourdons',
    'lourerai',
    'loureras',
    'lourerez',
    'lourions',
    'loustics',
    'louvâmes',
    'louvasse',
    'louvâtes',
    'louverai',
    'louveras',
    'louverez',
    'louvetai',
    'louvetas',
    'louvetât',
    'louvetée',
    'louveter',
    'louvetés',
    'louvetez',
    'louvette',
    'louvions',
    'louvoies',
    'louvoyai',
    'louvoyas',
    'louvoyât',
    'louvoyer',
    'louvoyez',
    'lovaient',
    'lovasses',
    'lovelace',
    'loverais',
    'loverait',
    'lovèrent',
    'loveriez',
    'loverons',
    'loveront',
    'loyautés',
    'lubrifia',
    'lubrifie',
    'lubrifié',
    'lubrique',
    'lucarnes',
    'lucidité',
    'lucifuge',
    'lucilies',
    'lucioles',
    'lucratif',
    'ludiciel',
    'ludiques',
    'ludismes',
    'luétines',
    'lugeâmes',
    'lugeasse',
    'lugeâtes',
    'lugerais',
    'lugerait',
    'lugèrent',
    'lugeriez',
    'lugerons',
    'lugeront',
    'lugeuses',
    'lugubres',
    'lui-même',
    'luirions',
    'luisance',
    'luisante',
    'luisants',
    'luisîmes',
    'luisions',
    'luisisse',
    'luisîtes',
    'lumbagos',
    'lumières',
    'lumignon',
    'lumineux',
    'lumitype',
    'lunaires',
    'lunaison',
    'lunchais',
    'lunchait',
    'lunchant',
    'lunchent',
    'lunchera',
    'lunchiez',
    'lunchons',
    'lunetier',
    'lunettes',
    'lupanars',
    'lupuline',
    'lupulins',
    'lurettes',
    'luronnes',
    'lussions',
    'lustrage',
    'lustrais',
    'lustrait',
    'lustrale',
    'lustrant',
    'lustraux',
    'lustrées',
    'lustrent',
    'lustrera',
    'lustriez',
    'lustrine',
    'lustrons',
    'lutaient',
    'lutasses',
    'lutécien',
    'lutécium',
    'lutéines',
    'luterais',
    'luterait',
    'lutèrent',
    'luteriez',
    'luterons',
    'luteront',
    'lutherie',
    'luthiers',
    'luthiste',
    'lutinais',
    'lutinait',
    'lutinant',
    'lutinées',
    'lutinent',
    'lutinera',
    'lutiniez',
    'lutinons',
    'luttâmes',
    'luttasse',
    'luttâtes',
    'lutterai',
    'lutteras',
    'lutterez',
    'lutteurs',
    'lutteuse',
    'luttions',
    'luxaient',
    'luxasses',
    'luxation',
    'luxerais',
    'luxerait',
    'luxèrent',
    'luxeriez',
    'luxerons',
    'luxeront',
    'luxmètre',
    'luxueuse',
    'luzernes',
    'lycéenne',
    'lycopode',
    'lyddites',
    'lydienne',
    'lynchage',
    'lynchais',
    'lynchait',
    'lynchant',
    'lynchées',
    'lynchent',
    'lynchera',
    'lyncheur',
    'lynchiez',
    'lynchons',
    'lyonnais',
    'lyophile',
    'lyriques',
    'lyrismes',
    'lysaient',
    'lysasses',
    'lyserais',
    'lyserait',
    'lysèrent',
    'lyseriez',
    'lyserons',
    'lyseront',
    'lysozyme',
    'lytiques',
    'maboules',
    'macabres',
    'macadams',
    'macaques',
    'macareux',
    'macaroni',
    'macarons',
    'macassar',
    'macérais',
    'macérait',
    'macérant',
    'macérées',
    'macèrent',
    'macérera',
    'macériez',
    'macerons',
    'macérons',
    'mâchâmes',
    'machaons',
    'mâchasse',
    'mâchâtes',
    'mâchefer',
    'mâcherai',
    'mâcheras',
    'mâcherez',
    'machette',
    'mâcheurs',
    'mâcheuse',
    'machinai',
    'machinal',
    'machinas',
    'machinât',
    'machinée',
    'machiner',
    'machines',
    'machinés',
    'mâchions',
    'machisme',
    'mâchoire',
    'mâchonna',
    'mâchonne',
    'mâchonné',
    'mâchurai',
    'mâchuras',
    'mâchurât',
    'mâchurée',
    'mâchurer',
    'mâchures',
    'mâchurés',
    'mâchurez',
    'maclâmes',
    'maclasse',
    'maclâtes',
    'maclerai',
    'macleras',
    'maclerez',
    'maclions',
    'maçonnai',
    'maçonnas',
    'maçonnât',
    'maçonnée',
    'maçonner',
    'maçonnes',
    'maçonnés',
    'maçonnez',
    'macquais',
    'macquait',
    'macquant',
    'macquées',
    'macquent',
    'macquera',
    'macquiez',
    'macquons',
    'macramés',
    'macreuse',
    'macroure',
    'maculage',
    'maculais',
    'maculait',
    'maculant',
    'maculées',
    'maculent',
    'maculera',
    'maculiez',
    'maculons',
    'madéfiai',
    'madéfias',
    'madéfiât',
    'madéfiée',
    'madéfier',
    'madéfies',
    'madéfiés',
    'madéfiez',
    'madérisa',
    'madérise',
    'madérisé',
    'madrague',
    'madriers',
    'madrigal',
    'maestoso',
    'maestria',
    'maestros',
    'maffiosi',
    'maffioso',
    'mafflues',
    'magasina',
    'magasine',
    'magasiné',
    'magasins',
    'magazine',
    'maghzens',
    'magicien',
    'magiques',
    'magister',
    'magnâmes',
    'magnasse',
    'magnâtes',
    'magnerai',
    'magneras',
    'magnerez',
    'magnésie',
    'magnétos',
    'magnifia',
    'magnifie',
    'magnifié',
    'magnions',
    'magnolia',
    'magyares',
    'maharani',
    'mahatmas',
    'mahdiste',
    'mah-jong',
    'mahonias',
    'mahonnes',
    'mahousse',
    'mahratte',
    'maigreur',
    'maigries',
    'maigriot',
    'maigrira',
    'mailings',
    'maillais',
    'maillait',
    'maillant',
    'maillées',
    'maillera',
    'maillets',
    'mailliez',
    'maillons',
    'maillots',
    'maillure',
    'mainates',
    'mainmise',
    'maintenu',
    'maintien',
    'maintins',
    'maintint',
    'maintînt',
    'maïserie',
    'maîtrisa',
    'maîtrise',
    'maîtrisé',
    'maïzenas',
    'majestés',
    'majeures',
    'ma-jongs',
    'majorais',
    'majorait',
    'majorant',
    'majorats',
    'majoraux',
    'majorées',
    'majorent',
    'majorera',
    'majoriez',
    'majorité',
    'majorons',
    'makimono',
    'malabars',
    'maladies',
    'maladifs',
    'maladive',
    'malaires',
    'malaisée',
    'malaises',
    'malaisés',
    'malandre',
    'malavisé',
    'malaxage',
    'malaxais',
    'malaxait',
    'malaxant',
    'malaxées',
    'malaxent',
    'malaxera',
    'malaxeur',
    'malaxiez',
    'malaxons',
    'maldonne',
    'maléfice',
    'mal-être',
    'malfaçon',
    'malfaire',
    'malfaite',
    'malfaits',
    'malfamée',
    'malfamés',
    'malfasse',
    'malferai',
    'malferas',
    'malferez',
    'malfîmes',
    'malfisse',
    'malfîtes',
    'malfrats',
    'malgache',
    'malheurs',
    'malienne',
    'malignes',
    'malingre',
    'malinois',
    'maliques',
    'mal-jugé',
    'malléole',
    'mallette',
    'malmenai',
    'malmenas',
    'malmenât',
    'malmenée',
    'malmener',
    'malmenés',
    'malmènes',
    'malmenez',
    'malotrue',
    'malotrus',
    'malpolie',
    'malpolis',
    'malsaine',
    'malsains',
    'malséant',
    'maltages',
    'maltâmes',
    'maltases',
    'maltasse',
    'maltâtes',
    'malterai',
    'malteras',
    'malterez',
    'malterie',
    'malteurs',
    'maltions',
    'maltoses',
    'maltôtes',
    'malvenue',
    'malvenus',
    'mamelles',
    'mamelons',
    'mamelues',
    'mameluke',
    'mammaire',
    'mammites',
    'mammouth',
    'manageai',
    'manageas',
    'manageât',
    'managées',
    'managent',
    'managera',
    'managers',
    'managiez',
    'mancelle',
    'manchons',
    'manchote',
    'manchots',
    'mandales',
    'mandâmes',
    'mandante',
    'mandants',
    'mandarin',
    'mandasse',
    'mandatai',
    'mandatas',
    'mandatât',
    'mandatée',
    'mandater',
    'mandates',
    'mandatés',
    'mandâtes',
    'mandatez',
    'mandchou',
    'manderai',
    'manderas',
    'manderez',
    'mandions',
    'mandores',
    'mandorle',
    'mandrill',
    'mandrins',
    'manégeai',
    'manégeas',
    'manégeât',
    'manégées',
    'manègent',
    'manégera',
    'manégiez',
    'manetons',
    'manettes',
    'mangeais',
    'mangeait',
    'mangeant',
    'mangeons',
    'mangerai',
    'mangeras',
    'mangerez',
    'mangeure',
    'mangeurs',
    'mangeuse',
    'mangions',
    'manglier',
    'mangrove',
    'manguier',
    'maniable',
    'maniâmes',
    'maniaque',
    'maniasse',
    'maniâtes',
    'manicles',
    'manierai',
    'maniérai',
    'manieras',
    'maniéras',
    'maniérât',
    'maniérée',
    'maniérer',
    'maniérés',
    'manières',
    'manierez',
    'maniérez',
    'manieurs',
    'manieuse',
    'manifold',
    'maniions',
    'manilles',
    'manillon',
    'manipula',
    'manipule',
    'manipulé',
    'maniques',
    'manitous',
    'mannites',
    'mannoses',
    'manoquai',
    'manoquas',
    'manoquât',
    'manoquée',
    'manoquer',
    'manoques',
    'manoqués',
    'manoquez',
    'manouche',
    'manquais',
    'manquait',
    'manquant',
    'manquées',
    'manquent',
    'manquera',
    'manquiez',
    'manquons',
    'mansarda',
    'mansarde',
    'mansardé',
    'mansions',
    'manteaux',
    'mantelée',
    'mantelés',
    'mantelet',
    'mantille',
    'mantique',
    'mantisse',
    'manucura',
    'manucure',
    'manucuré',
    'manuélin',
    'manuelle',
    'maoïsmes',
    'maoïstes',
    'maousses',
    'maquâmes',
    'maquasse',
    'maquâtes',
    'maquerai',
    'maqueras',
    'maquerez',
    'maquette',
    'maquilla',
    'maquille',
    'maquillé',
    'maquions',
    'marabout',
    'marantas',
    'marantes',
    'marasmes',
    'marasque',
    'marathon',
    'marâtres',
    'maraudai',
    'maraudas',
    'maraudât',
    'marauder',
    'maraudes',
    'maraudez',
    'marbrais',
    'marbrait',
    'marbrant',
    'marbrées',
    'marbrent',
    'marbrera',
    'marbreur',
    'marbrier',
    'marbriez',
    'marbrons',
    'marbrure',
    'marchais',
    'marchait',
    'marchand',
    'marchant',
    'marchent',
    'marchera',
    'marcheur',
    'marchiez',
    'marchons',
    'marcotta',
    'marcotte',
    'marcotté',
    'marécage',
    'maréchal',
    'marelles',
    'maremmes',
    'marennes',
    'mareyage',
    'mareyeur',
    'margeais',
    'margeait',
    'margeant',
    'margelle',
    'margeons',
    'margerai',
    'margeras',
    'margerez',
    'margeurs',
    'margeuse',
    'marginai',
    'marginal',
    'marginas',
    'marginât',
    'marginée',
    'marginer',
    'margines',
    'marginés',
    'marginez',
    'margions',
    'margotai',
    'margotas',
    'margotât',
    'margoter',
    'margotes',
    'margotez',
    'margotin',
    'margotta',
    'margotte',
    'margotté',
    'margrave',
    'mariable',
    'mariages',
    'mariales',
    'mariâmes',
    'mariante',
    'mariants',
    'mariasse',
    'mariâtes',
    'marierai',
    'marieras',
    'marierez',
    'marieurs',
    'marieuse',
    'marigots',
    'mariions',
    'marinade',
    'marinage',
    'marinais',
    'marinait',
    'marinant',
    'marinées',
    'marinent',
    'marinera',
    'marinier',
    'mariniez',
    'marinons',
    'marioles',
    'mariolle',
    'maristes',
    'maritale',
    'maritaux',
    'maritime',
    'marmitai',
    'marmitas',
    'marmitât',
    'marmitée',
    'marmiter',
    'marmites',
    'marmités',
    'marmitez',
    'marmiton',
    'marmonna',
    'marmonne',
    'marmonné',
    'marmotta',
    'marmotte',
    'marmotté',
    'marnages',
    'marnâmes',
    'marnasse',
    'marnâtes',
    'marnerai',
    'marneras',
    'marnerez',
    'marneurs',
    'marneuse',
    'marnière',
    'marnions',
    'marocain',
    'maronite',
    'maronnai',
    'maronnas',
    'maronnât',
    'maronner',
    'maronnes',
    'maronnez',
    'maroquin',
    'marottes',
    'maroufla',
    'maroufle',
    'marouflé',
    'maroutes',
    'marquage',
    'marquais',
    'marquait',
    'marquant',
    'marquées',
    'marquent',
    'marquera',
    'marqueta',
    'marqueté',
    'marqueur',
    'marquiez',
    'marquise',
    'marquoir',
    'marquons',
    'marraine',
    'marrâmes',
    'marrante',
    'marrants',
    'marrasse',
    'marrâtes',
    'marrerai',
    'marreras',
    'marrerez',
    'marrions',
    'marronna',
    'marronne',
    'marronné',
    'marrubes',
    'marsault',
    'marsouin',
    'martagon',
    'marteaux',
    'martelai',
    'martelas',
    'martelât',
    'martelée',
    'marteler',
    'martelés',
    'martèles',
    'martelez',
    'martiale',
    'martiaux',
    'martiens',
    'martinet',
    'martyres',
    'marxiens',
    'marxisai',
    'marxisas',
    'marxisât',
    'marxisée',
    'marxiser',
    'marxises',
    'marxisés',
    'marxisez',
    'marxisme',
    'marxiste',
    'maryland',
    'mascaras',
    'mascaret',
    'mascaron',
    'mascotte',
    'masculin',
    'masquage',
    'masquais',
    'masquait',
    'masquant',
    'masquées',
    'masquent',
    'masquera',
    'masquiez',
    'masquons',
    'massacra',
    'massacre',
    'massacré',
    'massages',
    'massâmes',
    'massasse',
    'massâtes',
    'masserai',
    'masseras',
    'masserez',
    'masséter',
    'massette',
    'masseurs',
    'masseuse',
    'massicot',
    'massière',
    'massiers',
    'massifia',
    'massifie',
    'massifié',
    'massions',
    'massique',
    'massives',
    'massorah',
    'mastabas',
    'mastiffs',
    'mastiqua',
    'mastique',
    'mastiqué',
    'mastites',
    'mastoïde',
    'masturba',
    'masturbe',
    'masturbé',
    'masurium',
    'matadors',
    'mataient',
    'mâtaient',
    'matamore',
    'matasses',
    'mâtasses',
    'matchais',
    'matchait',
    'matchant',
    'matchées',
    'matchent',
    'matchera',
    'matchiez',
    'matchons',
    'matefaim',
    'matelote',
    'matelots',
    'materais',
    'mâterais',
    'materait',
    'mâterait',
    'mâtereau',
    'matèrent',
    'mâtèrent',
    'matériau',
    'matériel',
    'materiez',
    'mâteriez',
    'maternai',
    'maternas',
    'maternât',
    'maternée',
    'maternel',
    'materner',
    'maternes',
    'maternés',
    'maternez',
    'materons',
    'mâterons',
    'materont',
    'mâteront',
    'matheuse',
    'matières',
    'mâtinais',
    'mâtinait',
    'matinale',
    'mâtinant',
    'matinaux',
    'matinées',
    'mâtinées',
    'matinent',
    'mâtinera',
    'matineux',
    'matinier',
    'mâtiniez',
    'mâtinons',
    'matirais',
    'matirait',
    'matirent',
    'matiriez',
    'matirons',
    'matiront',
    'matisses',
    'matissez',
    'matoises',
    'matonnes',
    'matraqua',
    'matraque',
    'matraqué',
    'matriçai',
    'matriças',
    'matriçât',
    'matricée',
    'matricer',
    'matrices',
    'matricés',
    'matricez',
    'matrones',
    'maturais',
    'maturait',
    'maturant',
    'maturées',
    'maturent',
    'maturera',
    'maturiez',
    'maturité',
    'maturons',
    'maubèche',
    'maudîmes',
    'maudirai',
    'maudiras',
    'maudirez',
    'maudisse',
    'maudites',
    'maudîtes',
    'maugréai',
    'maugréas',
    'maugréât',
    'maugréée',
    'maugréer',
    'maugrées',
    'maugréés',
    'maugréez',
    'maurelle',
    'mausolée',
    'maussade',
    'mauvaise',
    'mauvéine',
    'maxilles',
    'maximale',
    'maximaux',
    'maximisa',
    'maximise',
    'maximisé',
    'maximums',
    'maxwells',
    'mazagran',
    'mazoutai',
    'mazoutas',
    'mazoutât',
    'mazoutée',
    'mazouter',
    'mazoutes',
    'mazoutés',
    'mazoutez',
    'mazurkas',
    'méandres',
    'mécanisa',
    'mécanise',
    'mécanisé',
    'meccanos',
    'mécénats',
    'méchages',
    'méchâmes',
    'méchante',
    'méchants',
    'méchasse',
    'méchâtes',
    'mécherai',
    'mécheras',
    'mécherez',
    'mécheuse',
    'méchions',
    'méchouis',
    'mécompta',
    'mécompte',
    'mécompté',
    'méconium',
    'méconnue',
    'méconnus',
    'méconnut',
    'méconnût',
    'mécréant',
    'médailla',
    'médaille',
    'médaillé',
    'médecine',
    'médecins',
    'medersas',
    'médersas',
    'médiales',
    'médianes',
    'médiante',
    'médiator',
    'médicale',
    'médicaux',
    'médiéval',
    'médiocre',
    'médiques',
    'médirais',
    'médirait',
    'médirent',
    'médiriez',
    'médirons',
    'médiront',
    'médisais',
    'médisait',
    'médisant',
    'médisent',
    'médisera',
    'médisiez',
    'médisons',
    'médisses',
    'méditais',
    'méditait',
    'méditant',
    'méditées',
    'méditent',
    'méditera',
    'méditiez',
    'méditons',
    'médusais',
    'médusait',
    'médusant',
    'médusées',
    'médusent',
    'médusera',
    'médusiez',
    'médusons',
    'meetings',
    'méfaites',
    'méfasses',
    'méferais',
    'méferait',
    'méferiez',
    'méferons',
    'méferont',
    'méfiâmes',
    'méfiance',
    'méfiante',
    'méfiants',
    'méfiasse',
    'méfiâtes',
    'méfierai',
    'méfieras',
    'méfierez',
    'méfiions',
    'méfirent',
    'méfisses',
    'méformes',
    'mégardes',
    'mégirais',
    'mégirait',
    'mégirent',
    'mégiriez',
    'mégirons',
    'mégiront',
    'mégissai',
    'mégissas',
    'mégissât',
    'mégissée',
    'mégisser',
    'mégisses',
    'mégissés',
    'mégissez',
    'mégotais',
    'mégotait',
    'mégotant',
    'mégotées',
    'mégotent',
    'mégotera',
    'mégotiez',
    'mégotons',
    'méharées',
    'meilleur',
    'meistres',
    'méjanage',
    'méjugeai',
    'méjugeas',
    'méjugeât',
    'méjugées',
    'méjugent',
    'méjugera',
    'méjugiez',
    'mélaenas',
    'mêlaient',
    'mélangea',
    'mélangée',
    'mélanger',
    'mélanges',
    'mélangés',
    'mélangez',
    'mélanine',
    'mélanome',
    'mélanose',
    'mélasses',
    'mêlasses',
    'melchior',
    'melchite',
    'mêlerais',
    'mêlerait',
    'mêlèrent',
    'mêleriez',
    'mêlerons',
    'mêleront',
    'mélilots',
    'mélinite',
    'méliques',
    'mélisses',
    'mélittes',
    'mellites',
    'mélodies',
    'mélomane',
    'melonnée',
    'melonnés',
    'mélopées',
    'mélusine',
    'membrane',
    'membrées',
    'membrons',
    'membrues',
    'membrure',
    'mêmement',
    'mémentos',
    'mémoires',
    'mémorial',
    'mémoriel',
    'mémorisa',
    'mémorise',
    'mémorisé',
    'menaçais',
    'menaçait',
    'menaçant',
    'menacées',
    'menacent',
    'menacera',
    'menaciez',
    'menaçons',
    'ménageai',
    'ménageas',
    'ménageât',
    'ménagées',
    'ménagent',
    'ménagera',
    'ménagère',
    'ménagiez',
    'menaient',
    'menasses',
    'mendiais',
    'mendiait',
    'mendiant',
    'mendiées',
    'mendient',
    'mendiera',
    'mendigot',
    'mendiiez',
    'mendions',
    'mènerais',
    'mènerait',
    'menèrent',
    'mèneriez',
    'mènerons',
    'mèneront',
    'meneuses',
    'méningée',
    'méninges',
    'méningés',
    'ménisque',
    'ménologe',
    'menottes',
    'mensonge',
    'mensuels',
    'mensurai',
    'mensuras',
    'mensurât',
    'mensurée',
    'mensurer',
    'mensures',
    'mensurés',
    'mensurez',
    'mentales',
    'menterie',
    'menteurs',
    'menteuse',
    'mentholé',
    'menthols',
    'mentîmes',
    'mentions',
    'mentirai',
    'mentiras',
    'mentirez',
    'mentisme',
    'mentisse',
    'mentîtes',
    'menuisai',
    'menuisas',
    'menuisât',
    'menuisée',
    'menuiser',
    'menuises',
    'menuisés',
    'menuisez',
    'méplates',
    'méprends',
    'méprenez',
    'méprenne',
    'méprîmes',
    'méprisai',
    'méprisas',
    'méprisât',
    'méprisée',
    'mépriser',
    'méprises',
    'méprisés',
    'méprisez',
    'méprisse',
    'méprîtes',
    'mercanti',
    'mercerie',
    'mercière',
    'merciers',
    'mercredi',
    'mercures',
    'merdeuse',
    'merdiers',
    'merdique',
    'merdoies',
    'merdoyai',
    'merdoyas',
    'merdoyât',
    'merdoyer',
    'merdoyez',
    'méridien',
    'meringua',
    'meringue',
    'meringué',
    'merisier',
    'méritais',
    'méritait',
    'méritant',
    'méritées',
    'méritent',
    'méritera',
    'méritiez',
    'méritons',
    'merlette',
    'merluche',
    'merrains',
    'mésallia',
    'mésallie',
    'mésallié',
    'mésanges',
    'mescluns',
    'mesdames',
    'mesquine',
    'mesquins',
    'messager',
    'messages',
    'messéant',
    'messeoir',
    'messidor',
    'messiéra',
    'messiers',
    'messires',
    'mesurage',
    'mesurais',
    'mesurait',
    'mesurant',
    'mesurées',
    'mesurent',
    'mesurera',
    'mesureur',
    'mesuriez',
    'mesurons',
    'mésusais',
    'mésusait',
    'mésusant',
    'mésusent',
    'mésusera',
    'mésusiez',
    'mésusons',
    'métairie',
    'métallos',
    'métayage',
    'métayère',
    'métayers',
    'météores',
    'métèques',
    'méthanes',
    'méthodes',
    'méthyles',
    'métissai',
    'métissas',
    'métissât',
    'métissée',
    'métisser',
    'métisses',
    'métissés',
    'métissez',
    'métrages',
    'métrâmes',
    'métrasse',
    'métrâtes',
    'métrerai',
    'métreras',
    'métrerez',
    'métreurs',
    'métreuse',
    'métrions',
    'métrique',
    'métrites',
    'mettable',
    'metteurs',
    'metteuse',
    'mettions',
    'mettrais',
    'mettrait',
    'mettriez',
    'mettrons',
    'mettront',
    'meublais',
    'meublait',
    'meublant',
    'meublées',
    'meublent',
    'meublera',
    'meubliez',
    'meublons',
    'meuglais',
    'meuglait',
    'meuglant',
    'meuglent',
    'meuglera',
    'meugliez',
    'meuglons',
    'meulages',
    'meulâmes',
    'meulasse',
    'meulâtes',
    'meulerai',
    'meuleras',
    'meulerez',
    'meulette',
    'meulière',
    'meuliers',
    'meulions',
    'meunerie',
    'meunière',
    'meuniers',
    'meurette',
    'meurtres',
    'meurtrie',
    'meurtrir',
    'meurtris',
    'meurtrit',
    'meurtrît',
    'mévendes',
    'mévendez',
    'mévendis',
    'mévendit',
    'mévendît',
    'mévendra',
    'mévendre',
    'mévendue',
    'mévendus',
    'méventes',
    'mexicain',
    'mézigues',
    'miaulais',
    'miaulait',
    'miaulant',
    'miaulent',
    'miaulera',
    'miauleur',
    'miauliez',
    'miaulons',
    'mi-avril',
    'micacées',
    'micelles',
    'micheton',
    'mi-close',
    'microbes',
    'microbus',
    'mictions',
    'midships',
    'miellées',
    'mielleux',
    'mignarda',
    'mignarde',
    'mignardé',
    'mignards',
    'mignonne',
    'mignotai',
    'mignotas',
    'mignotât',
    'mignotée',
    'mignoter',
    'mignotes',
    'mignotés',
    'mignotez',
    'migraine',
    'migrâmes',
    'migrasse',
    'migrâtes',
    'migrerai',
    'migreras',
    'migrerez',
    'migrions',
    'mi-jambe',
    'mijaurée',
    'mijotais',
    'mijotait',
    'mijotant',
    'mijotées',
    'mijotent',
    'mijotera',
    'mijotiez',
    'mijotons',
    'milanais',
    'mildious',
    'miliaire',
    'milicien',
    'militais',
    'militait',
    'militant',
    'militent',
    'militera',
    'militiez',
    'militons',
    'milk-bar',
    'millages',
    'millasse',
    'milliard',
    'millibar',
    'millième',
    'milliers',
    'millions',
    'milouins',
    'mimaient',
    'mimasses',
    'mimerais',
    'mimerait',
    'mimèrent',
    'mimeriez',
    'mimerons',
    'mimeront',
    'mimiques',
    'minables',
    'minaient',
    'minantes',
    'minarets',
    'minasses',
    'minaudai',
    'minaudas',
    'minaudât',
    'minaudée',
    'minauder',
    'minaudes',
    'minaudés',
    'minaudez',
    'minceurs',
    'mincîmes',
    'mincirai',
    'minciras',
    'mincirez',
    'mincisse',
    'mincîtes',
    'minerais',
    'minerait',
    'minérale',
    'minéraux',
    'minèrent',
    'mineriez',
    'minerons',
    'mineront',
    'minerval',
    'minerves',
    'minettes',
    'mineures',
    'minicars',
    'minières',
    'minimale',
    'minimaux',
    'minimisa',
    'minimise',
    'minimisé',
    'minimums',
    'ministre',
    'Minitels',
    'minoenne',
    'minorais',
    'minorait',
    'minorant',
    'minorées',
    'minorent',
    'minorera',
    'minoriez',
    'minorité',
    'minorons',
    'minotier',
    'minutage',
    'minutais',
    'minutait',
    'minutant',
    'minutées',
    'minutent',
    'minutera',
    'minuteur',
    'minutier',
    'minuties',
    'minutiez',
    'minutons',
    'miocènes',
    'mi-parti',
    'miracles',
    'miraculé',
    'miradors',
    'miraient',
    'mirasses',
    'mirbanes',
    'mirepoix',
    'mirerais',
    'mirerait',
    'mirèrent',
    'mireriez',
    'mirerons',
    'mireront',
    'mirettes',
    'mireuses',
    'mirliton',
    'mirmidon',
    'miroitai',
    'miroitas',
    'miroitât',
    'miroitée',
    'miroiter',
    'miroites',
    'miroités',
    'miroitez',
    'mironton',
    'mirotons',
    'misaient',
    'misaines',
    'misasses',
    'miscible',
    'miserais',
    'miserait',
    'misèrent',
    'miserere',
    'miséréré',
    'miséreux',
    'miseriez',
    'miserons',
    'miseront',
    'misogyne',
    'missiles',
    'missions',
    'missives',
    'mistelle',
    'mistigri',
    'mistonne',
    'mistrals',
    'mitaient',
    'mitaines',
    'mitasses',
    'mi-temps',
    'miterais',
    'miterait',
    'mitèrent',
    'miteriez',
    'miterons',
    'miteront',
    'miteuses',
    'mitigeai',
    'mitigeas',
    'mitigeât',
    'mitigées',
    'mitigent',
    'mitigera',
    'mitigeur',
    'mitigiez',
    'mitonnai',
    'mitonnas',
    'mitonnât',
    'mitonnée',
    'mitonner',
    'mitonnes',
    'mitonnés',
    'mitonnez',
    'mitoyens',
    'mitrales',
    'mixaient',
    'mixasses',
    'mixerais',
    'mixerait',
    'mixèrent',
    'mixeriez',
    'mixerons',
    'mixeront',
    'mixtions',
    'mixtures',
    'mobilier',
    'mobilisa',
    'mobilise',
    'mobilisé',
    'mobilité',
    'mocassin',
    'mocharde',
    'mochards',
    'mochetés',
    'modalité',
    'modelage',
    'modelais',
    'modelait',
    'modelant',
    'modelées',
    'modèlent',
    'modèlera',
    'modeleur',
    'modeliez',
    'modélisa',
    'modélise',
    'modélisé',
    'modelons',
    'modérais',
    'modérait',
    'modérant',
    'moderato',
    'modérées',
    'modèrent',
    'modérera',
    'modériez',
    'modernes',
    'modérons',
    'modestes',
    'modestie',
    'modicité',
    'modifiai',
    'modifias',
    'modifiât',
    'modifiée',
    'modifier',
    'modifies',
    'modifiés',
    'modifiez',
    'modillon',
    'modiques',
    'modistes',
    'modulais',
    'modulait',
    'modulant',
    'modulées',
    'modulent',
    'modulera',
    'moduliez',
    'modulons',
    'modulors',
    'moelleux',
    'moellons',
    'mofettes',
    'moignons',
    'moi-même',
    'moindres',
    'moineaux',
    'moinerie',
    'moirages',
    'moirâmes',
    'moirasse',
    'moirâtes',
    'moirerai',
    'moireras',
    'moirerez',
    'moirions',
    'moirures',
    'moisâmes',
    'moisasse',
    'moisâtes',
    'moiserai',
    'moiseras',
    'moiserez',
    'moisîmes',
    'moisions',
    'moisirai',
    'moisiras',
    'moisirez',
    'moisisse',
    'moisîtes',
    'moissine',
    'moissons',
    'moitâmes',
    'moitasse',
    'moitâtes',
    'moiterai',
    'moiteras',
    'moiterez',
    'moiteurs',
    'moitîmes',
    'moitions',
    'moitirai',
    'moitiras',
    'moitirez',
    'moitisse',
    'moitîtes',
    'molaires',
    'molasses',
    'molécule',
    'molestai',
    'molestas',
    'molestât',
    'molestée',
    'molester',
    'molestes',
    'molestés',
    'molestez',
    'moletage',
    'moletais',
    'moletait',
    'moletant',
    'moletées',
    'moletiez',
    'moletons',
    'molettes',
    'mollarda',
    'mollarde',
    'mollardé',
    'mollards',
    'mollasse',
    'mollesse',
    'molleton',
    'mollîmes',
    'mollirai',
    'molliras',
    'mollirez',
    'mollisse',
    'mollîtes',
    'molosses',
    'momeries',
    'momifiai',
    'momifias',
    'momifiât',
    'momifiée',
    'momifier',
    'momifies',
    'momifiés',
    'momifiez',
    'monacale',
    'monacaux',
    'monarque',
    'monazite',
    'monceaux',
    'mondaine',
    'mondains',
    'mondâmes',
    'mondasse',
    'mondâtes',
    'monderai',
    'monderas',
    'monderez',
    'mondiale',
    'mondiaux',
    'mondions',
    'monétisa',
    'monétise',
    'monétisé',
    'mongoles',
    'moniales',
    'monismes',
    'monistes',
    'moniteur',
    'monition',
    'monitors',
    'monnaies',
    'monnayai',
    'monnayas',
    'monnayât',
    'monnayée',
    'monnayer',
    'monnayes',
    'monnayés',
    'monnayez',
    'monobloc',
    'monocles',
    'monocyte',
    'monodies',
    'monoecie',
    'monogame',
    'monoïque',
    'monomère',
    'monoplan',
    'monopole',
    'monoprix',
    'monorail',
    'monorime',
    'monoskis',
    'monotone',
    'Monotype',
    'monoxyle',
    'monsieur',
    'monstres',
    'montages',
    'montagne',
    'montâmes',
    'montante',
    'montants',
    'montasse',
    'montâtes',
    'monterai',
    'monteras',
    'monterez',
    'monteurs',
    'monteuse',
    'montions',
    'montoirs',
    'montrais',
    'montrait',
    'montrant',
    'montrées',
    'montrent',
    'montrera',
    'montreur',
    'montriez',
    'montrons',
    'montueux',
    'montures',
    'monument',
    'moquâmes',
    'moquasse',
    'moquâtes',
    'moquerai',
    'moqueras',
    'moquerez',
    'moquerie',
    'moquetta',
    'moquette',
    'moquetté',
    'moqueurs',
    'moqueuse',
    'moquions',
    'moraines',
    'moralisa',
    'moralise',
    'moralisé',
    'moralité',
    'morasses',
    'morbides',
    'morceaux',
    'morcelai',
    'morcelas',
    'morcelât',
    'morcelée',
    'morceler',
    'morcelés',
    'morcelez',
    'morcelle',
    'mordache',
    'mordança',
    'mordance',
    'mordancé',
    'mordante',
    'mordants',
    'mordicus',
    'mordilla',
    'mordille',
    'mordillé',
    'mordîmes',
    'mordions',
    'mordisse',
    'mordîtes',
    'mordorai',
    'mordoras',
    'mordorât',
    'mordorée',
    'mordorer',
    'mordores',
    'mordorés',
    'mordorez',
    'mordrais',
    'mordrait',
    'mordriez',
    'mordrons',
    'mordront',
    'morelles',
    'moresque',
    'morfales',
    'morfilai',
    'morfilas',
    'morfilât',
    'morfilée',
    'morfiler',
    'morfiles',
    'morfilés',
    'morfilez',
    'morflais',
    'morflait',
    'morflant',
    'morflées',
    'morflent',
    'morflera',
    'morfliez',
    'morflons',
    'morfonde',
    'morfonds',
    'morfondu',
    'morguais',
    'morguait',
    'morguant',
    'morguées',
    'morguent',
    'morguera',
    'morguiez',
    'morguons',
    'moribond',
    'moricaud',
    'morigéna',
    'morigéné',
    'morigène',
    'morilles',
    'morillon',
    'mormones',
    'mornifle',
    'morosité',
    'morphème',
    'morphine',
    'morpions',
    'morsures',
    'mortaisa',
    'mortaise',
    'mortaisé',
    'mortelle',
    'mortiers',
    'mortifia',
    'mortifie',
    'mortifié',
    'mort-née',
    'mort-nés',
    'morutier',
    'morveuse',
    'mosaïque',
    'mosaïqué',
    'mosaïsme',
    'mosaïste',
    'mosellan',
    'mosettes',
    'mosquées',
    'motardes',
    'motilité',
    'motionna',
    'motionne',
    'motionné',
    'motivais',
    'motivait',
    'motivant',
    'motivées',
    'motivent',
    'motivera',
    'motiviez',
    'motivons',
    'motorisa',
    'motorise',
    'motorisé',
    'motrices',
    'mottâmes',
    'mottasse',
    'mottâtes',
    'motterai',
    'motteras',
    'motterez',
    'mottions',
    'mouchage',
    'mouchais',
    'mouchait',
    'mouchant',
    'mouchard',
    'mouchées',
    'mouchent',
    'mouchera',
    'moucheta',
    'moucheté',
    'mouchiez',
    'mouchoir',
    'mouchons',
    'mouchure',
    'moudrais',
    'moudrait',
    'moudriez',
    'moudrons',
    'moudront',
    'mouettai',
    'mouettas',
    'mouettât',
    'mouetter',
    'mouettes',
    'mouettez',
    'moufette',
    'mouflets',
    'mouflons',
    'mouftais',
    'mouftait',
    'mouftant',
    'mouftent',
    'mouftera',
    'mouftiez',
    'mouftons',
    'mouillai',
    'mouillas',
    'mouillât',
    'mouillée',
    'mouiller',
    'mouilles',
    'mouillés',
    'mouillez',
    'moukères',
    'moulages',
    'moulâmes',
    'moulante',
    'moulants',
    'moulasse',
    'moulâtes',
    'moulerai',
    'mouleras',
    'moulerez',
    'mouleurs',
    'moulière',
    'moulinai',
    'moulinas',
    'moulinât',
    'moulinée',
    'mouliner',
    'moulines',
    'moulinés',
    'moulinet',
    'moulinez',
    'moulions',
    'moulûmes',
    'moulurai',
    'mouluras',
    'moulurât',
    'moulurée',
    'moulurer',
    'moulures',
    'moulurés',
    'moulurez',
    'moulusse',
    'moulûtes',
    'moumoute',
    'mouquère',
    'mourante',
    'mourants',
    'mourions',
    'mouroirs',
    'mouronna',
    'mouronne',
    'mouronné',
    'mourrais',
    'mourrait',
    'mourriez',
    'mourrons',
    'mourront',
    'mourûmes',
    'mourusse',
    'mourûtes',
    'mousquet',
    'moussais',
    'moussait',
    'moussant',
    'moussent',
    'moussera',
    'mousseux',
    'moussiez',
    'moussoir',
    'moussons',
    'moussues',
    'moutarde',
    'moutards',
    'moutiers',
    'moutonna',
    'moutonne',
    'moutonné',
    'moutures',
    'mouvâmes',
    'mouvance',
    'mouvante',
    'mouvants',
    'mouvasse',
    'mouvâtes',
    'mouverai',
    'mouveras',
    'mouverez',
    'mouvions',
    'mouvrais',
    'mouvrait',
    'mouvriez',
    'mouvrons',
    'mouvront',
    'Moviolas',
    'moyennai',
    'moyennas',
    'moyennât',
    'moyennée',
    'moyenner',
    'moyennes',
    'moyennés',
    'moyennez',
    'moyettes',
    'mozabite',
    'mozarabe',
    'mozettes',
    'muassent',
    'muassiez',
    'muchâmes',
    'muchasse',
    'muchâtes',
    'mucherai',
    'mucheras',
    'mucherez',
    'muchions',
    'mucilage',
    'mucosité',
    'mudéjare',
    'muerions',
    'muezzins',
    'muflerie',
    'mufliers',
    'mugirais',
    'mugirait',
    'mugirent',
    'mugiriez',
    'mugirons',
    'mugiront',
    'mugisses',
    'mugissez',
    'muguetai',
    'muguetas',
    'muguetât',
    'muguetée',
    'mugueter',
    'muguetés',
    'muguetez',
    'muguette',
    'mulardes',
    'mulâtres',
    'muletier',
    'mulettes',
    'mulotais',
    'mulotait',
    'mulotant',
    'mulotent',
    'mulotera',
    'mulotiez',
    'mulotons',
    'mulsions',
    'multiple',
    'municipe',
    'munirais',
    'munirait',
    'munirent',
    'muniriez',
    'munirons',
    'muniront',
    'munisses',
    'munissez',
    'munition',
    'munsters',
    'muqueuse',
    'muraient',
    'murailla',
    'muraille',
    'muraillé',
    'murasses',
    'mûrement',
    'murerais',
    'murerait',
    'murèrent',
    'mureriez',
    'murerons',
    'mureront',
    'muretins',
    'murettes',
    'muriates',
    'mûrirais',
    'mûrirait',
    'mûrirent',
    'mûririez',
    'mûrirons',
    'mûriront',
    'mûrisses',
    'mûrissez',
    'murmurai',
    'murmuras',
    'murmurât',
    'murmurée',
    'murmurer',
    'murmures',
    'murmurés',
    'murmurez',
    'murrhine',
    'murrhins',
    'musagète',
    'musaient',
    'musardai',
    'musardas',
    'musardât',
    'musarder',
    'musardes',
    'musardez',
    'musasses',
    'muscades',
    'muscadet',
    'muscadin',
    'muscaris',
    'musclais',
    'musclait',
    'musclant',
    'musclées',
    'musclent',
    'musclera',
    'muscliez',
    'musclons',
    'muselais',
    'muselait',
    'muselant',
    'muselées',
    'muselets',
    'museliez',
    'muselles',
    'muselons',
    'muserais',
    'muserait',
    'musèrent',
    'museriez',
    'muserons',
    'museront',
    'musettes',
    'musicale',
    'musicaux',
    'musicien',
    'musiquai',
    'musiquas',
    'musiquât',
    'musiquée',
    'musiquer',
    'musiques',
    'musiqués',
    'musiquez',
    'musquais',
    'musquait',
    'musquant',
    'musquées',
    'musquent',
    'musquera',
    'musquiez',
    'musquons',
    'mussâmes',
    'mussasse',
    'mussâtes',
    'musserai',
    'musseras',
    'musserez',
    'mussions',
    'mussives',
    'mustangs',
    'musulman',
    'mutables',
    'mutagène',
    'mutaient',
    'mutantes',
    'mutasses',
    'mutation',
    'muterais',
    'muterait',
    'mutèrent',
    'muteriez',
    'muterons',
    'muteront',
    'mutilais',
    'mutilait',
    'mutilant',
    'mutilées',
    'mutilent',
    'mutilera',
    'mutiliez',
    'mutilons',
    'mutinais',
    'mutinait',
    'mutinant',
    'mutinées',
    'mutinent',
    'mutinera',
    'mutiniez',
    'mutinons',
    'mutiques',
    'mutismes',
    'mutuelle',
    'myalgies',
    'mycélien',
    'mycélium',
    'mycénien',
    'mydriase',
    'myélines',
    'myélites',
    'myélomes',
    'myocarde',
    'myologie',
    'myosites',
    'myosotis',
    'myriades',
    'myrmidon',
    'myrosine',
    'myroxyle',
    'myrtille',
    'mystères',
    'mystifia',
    'mystifie',
    'mystifié',
    'mystique',
    'mythique',
    'nacarats',
    'nacelles',
    'nacrâmes',
    'nacrasse',
    'nacrâtes',
    'nacrerai',
    'nacreras',
    'nacrerez',
    'nacrions',
    'nagaïkas',
    'nageâmes',
    'nageasse',
    'nageâtes',
    'nageoire',
    'nagerais',
    'nagerait',
    'nagèrent',
    'nageriez',
    'nagerons',
    'nageront',
    'nageuses',
    'nahaïkas',
    'naissain',
    'naissais',
    'naissait',
    'naissant',
    'naissent',
    'naissiez',
    'naissons',
    'naîtrais',
    'naîtrait',
    'naîtriez',
    'naîtrons',
    'naîtront',
    'naïvetés',
    'nanismes',
    'nansouks',
    'nantîmes',
    'nantirai',
    'nantiras',
    'nantirez',
    'nantisse',
    'nantîtes',
    'nanzouks',
    'naphtols',
    'napoléon',
    'Napoléon',
    'nappages',
    'nappâmes',
    'nappasse',
    'nappâtes',
    'napperai',
    'napperas',
    'napperez',
    'napperon',
    'nappions',
    'naquîmes',
    'naquisse',
    'naquîtes',
    'narcéine',
    'narcisse',
    'narcoses',
    'narghilé',
    'narguais',
    'narguait',
    'narguant',
    'narguées',
    'narguent',
    'narguera',
    'narguiez',
    'narguilé',
    'narguons',
    'narquois',
    'narrâmes',
    'narrasse',
    'narrâtes',
    'narratif',
    'narrerai',
    'narreras',
    'narrerez',
    'narrions',
    'nasalisa',
    'nasalise',
    'nasalisé',
    'nasalité',
    'nasardes',
    'nasillai',
    'nasillas',
    'nasillât',
    'nasillée',
    'nasiller',
    'nasilles',
    'nasillés',
    'nasillez',
    'nasiques',
    'nasitort',
    'natalité',
    'natation',
    'national',
    'nativité',
    'nattages',
    'nattâmes',
    'nattasse',
    'nattâtes',
    'natterai',
    'natteras',
    'natterez',
    'nattière',
    'nattiers',
    'nattions',
    'naturels',
    'naucores',
    'naufrage',
    'naufragé',
    'nauplius',
    'nauséeux',
    'nautiles',
    'nautique',
    'nautisme',
    'navarins',
    'navarque',
    'navettes',
    'navicert',
    'navicule',
    'navigant',
    'naviguai',
    'naviguas',
    'naviguât',
    'naviguer',
    'navigues',
    'naviguez',
    'navrâmes',
    'navrante',
    'navrants',
    'navrasse',
    'navrâtes',
    'navrerai',
    'navreras',
    'navrerez',
    'navrions',
    'nazaréen',
    'nazismes',
    'néantisa',
    'néantise',
    'néantisé',
    'nébuleux',
    'nécrobie',
    'nécrosai',
    'nécrosas',
    'nécrosât',
    'nécrosée',
    'nécroser',
    'nécroses',
    'nécrosés',
    'nécrosez',
    'nectaire',
    'néfastes',
    'néfliers',
    'négateur',
    'négatifs',
    'négation',
    'négative',
    'négatons',
    'négligea',
    'négligée',
    'négliger',
    'négliges',
    'négligés',
    'négligez',
    'négociai',
    'négocias',
    'négociât',
    'négociée',
    'négocier',
    'négocies',
    'négociés',
    'négociez',
    'négondos',
    'négresse',
    'négriers',
    'négrille',
    'négroïde',
    'neigeait',
    'neigeuse',
    'nélombos',
    'némalion',
    'nénuphar',
    'néodymes',
    'néoformé',
    'néogènes',
    'néo-grec',
    'néologie',
    'néoménie',
    'néonatal',
    'néophyte',
    'néotties',
    'népérien',
    'néphrite',
    'néphrons',
    'néphrose',
    'néréides',
    'néronien',
    'nerpruns',
    'nerveuse',
    'nervurai',
    'nervuras',
    'nervurât',
    'nervurée',
    'nervurer',
    'nervures',
    'nervurés',
    'nervurez',
    'nescafés',
    'nettetés',
    'nettoies',
    'nettoyai',
    'nettoyas',
    'nettoyât',
    'nettoyée',
    'nettoyer',
    'nettoyés',
    'nettoyez',
    'neurales',
    'neuronal',
    'neurones',
    'neurulas',
    'neutrino',
    'neutrons',
    'neuvaine',
    'neuvième',
    'névraxes',
    'névrites',
    'névrosée',
    'névroses',
    'névrosés',
    'niaisais',
    'niaisait',
    'niaisant',
    'niaisent',
    'niaisera',
    'niaisiez',
    'niaisons',
    'niaoulis',
    'niassent',
    'niassiez',
    'nichâmes',
    'nichasse',
    'nichâtes',
    'nicherai',
    'nicheras',
    'nicherez',
    'nichions',
    'nichoirs',
    'nichrome',
    'nickelai',
    'nickelas',
    'nickelât',
    'nickelée',
    'nickeler',
    'nickelés',
    'nickelez',
    'nickelle',
    'nicodème',
    'nicotine',
    'nidation',
    'nidifiai',
    'nidifias',
    'nidifiât',
    'nidifier',
    'nidifies',
    'nidifiez',
    'niellage',
    'niellais',
    'niellait',
    'niellant',
    'niellées',
    'niellent',
    'niellera',
    'nielleur',
    'nielliez',
    'niellons',
    'niellure',
    'nierions',
    'nigaudes',
    'nigelles',
    'nilgauts',
    'nimbâmes',
    'nimbasse',
    'nimbâtes',
    'nimberai',
    'nimberas',
    'nimberez',
    'nimbions',
    'niobiums',
    'nippâmes',
    'nippasse',
    'nippâtes',
    'nipperai',
    'nipperas',
    'nipperez',
    'nippions',
    'nippones',
    'nipponne',
    'nirvanas',
    'nitouche',
    'nitrâmes',
    'nitrasse',
    'nitratai',
    'nitratas',
    'nitratât',
    'nitratée',
    'nitrater',
    'nitrates',
    'nitratés',
    'nitrâtes',
    'nitratez',
    'nitrerai',
    'nitreras',
    'nitrerez',
    'nitreuse',
    'nitrière',
    'nitrifia',
    'nitrifie',
    'nitrifié',
    'nitriles',
    'nitrions',
    'nitrique',
    'nitrites',
    'nitrurai',
    'nitruras',
    'nitrurât',
    'nitrurée',
    'nitrurer',
    'nitrures',
    'nitrurés',
    'nitrurez',
    'nivéales',
    'nivelage',
    'nivelais',
    'nivelait',
    'nivelant',
    'nivelées',
    'niveleur',
    'niveliez',
    'nivelles',
    'nivelons',
    'nivéoles',
    'noblesse',
    'nobliaus',
    'noceuses',
    'nocivité',
    'noctules',
    'nocturne',
    'nocuités',
    'nodosité',
    'noduleux',
    'noétique',
    'noierais',
    'noierait',
    'noieriez',
    'noierons',
    'noieront',
    'noirâtre',
    'noiraude',
    'noirauds',
    'noirceur',
    'noircies',
    'noircira',
    'noisette',
    'nolisais',
    'nolisait',
    'nolisant',
    'nolisées',
    'nolisent',
    'nolisera',
    'nolisiez',
    'nolisons',
    'nomadisa',
    'nomadise',
    'nomadisé',
    'nombrais',
    'nombrait',
    'nombrant',
    'nombrées',
    'nombrent',
    'nombreux',
    'nombriez',
    'nombrils',
    'nombrons',
    'nominale',
    'nominaux',
    'nommâmes',
    'nommasse',
    'nommâtes',
    'nommerai',
    'nommeras',
    'nommerez',
    'nommions',
    'non-être',
    'non-lieu',
    'nonnette',
    'non-sens',
    'non-stop',
    'nopaient',
    'nopasses',
    'noperais',
    'noperait',
    'nopèrent',
    'noperiez',
    'noperons',
    'noperont',
    'nord-est',
    'nordîmes',
    'nordique',
    'nordirai',
    'nordiras',
    'nordirez',
    'nordisse',
    'nordiste',
    'nordîtes',
    'normales',
    'normande',
    'normands',
    'normatif',
    'notables',
    'notaient',
    'notaires',
    'notarial',
    'notariat',
    'notariée',
    'notariés',
    'notasses',
    'notation',
    'noterais',
    'noterait',
    'notèrent',
    'noteriez',
    'noterons',
    'noteront',
    'notifiai',
    'notifias',
    'notifiât',
    'notifiée',
    'notifier',
    'notifies',
    'notifiés',
    'notifiez',
    'notoires',
    'nouaient',
    'nouaison',
    'nouasses',
    'nouerais',
    'nouerait',
    'nouèrent',
    'noueriez',
    'nouerons',
    'noueront',
    'noueuses',
    'nouilles',
    'noumènes',
    'nourrain',
    'nourrice',
    'nourries',
    'nouveaux',
    'nouvelle',
    'novaient',
    'novasses',
    'novateur',
    'novation',
    'novembre',
    'noverais',
    'noverait',
    'novèrent',
    'noveriez',
    'noverons',
    'noveront',
    'noviciat',
    'noyaient',
    'noyasses',
    'noyautai',
    'noyautas',
    'noyautât',
    'noyautée',
    'noyauter',
    'noyautes',
    'noyautés',
    'noyautez',
    'noyèrent',
    'nuageuse',
    'nuançais',
    'nuançait',
    'nuançant',
    'nuancées',
    'nuancent',
    'nuancera',
    'nuanciez',
    'nuançons',
    'nuassent',
    'nuassiez',
    'nubienne',
    'nubilité',
    'nucelles',
    'nucléais',
    'nucléait',
    'nucléant',
    'nucléées',
    'nucléent',
    'nucléera',
    'nucléiez',
    'nucléine',
    'nucléole',
    'nucléons',
    'nudismes',
    'nudistes',
    'nuerions',
    'nuirions',
    'nuisance',
    'nuisible',
    'nuisîmes',
    'nuisions',
    'nuisisse',
    'nuisîtes',
    'nullarde',
    'nullards',
    'nullités',
    'numérale',
    'numéraux',
    'numérisa',
    'numérise',
    'numérisé',
    'numérota',
    'numérote',
    'numéroté',
    'nunataks',
    'nu-pieds',
    'nuptiale',
    'nuptiaux',
    'nuraghes',
    'nurserys',
    'nutation',
    'nutritif',
    'nycturie',
    'nymphale',
    'nymphaux',
    'nymphéas',
    'nymphées',
    'nymphose',
    'oaristys',
    'oasienne',
    'obéirais',
    'obéirait',
    'obéirent',
    'obéiriez',
    'obéirons',
    'obéiront',
    'obéisses',
    'obéissez',
    'obérâmes',
    'obérasse',
    'obérâtes',
    'obérerai',
    'obéreras',
    'obérerez',
    'obérions',
    'obésités',
    'objectai',
    'objectal',
    'objectas',
    'objectât',
    'objectée',
    'objecter',
    'objectes',
    'objectés',
    'objectez',
    'objectif',
    'objurgua',
    'objurgue',
    'objurgué',
    'oblatifs',
    'oblation',
    'oblative',
    'obligeai',
    'obligeas',
    'obligeât',
    'obligées',
    'obligent',
    'obligera',
    'obligiez',
    'obliquai',
    'obliquas',
    'obliquât',
    'obliquer',
    'obliques',
    'obliquez',
    'oblitéra',
    'oblitéré',
    'oblitère',
    'oblongue',
    'obnubila',
    'obnubile',
    'obnubilé',
    'obombrai',
    'obombras',
    'obombrât',
    'obombrée',
    'obombrer',
    'obombres',
    'obombrés',
    'obombrez',
    'obscènes',
    'obscurci',
    'obscures',
    'obsédais',
    'obsédait',
    'obsédant',
    'obsédées',
    'obsèdent',
    'obsédera',
    'obsédiez',
    'obsédons',
    'obsèques',
    'observai',
    'observas',
    'observât',
    'observée',
    'observer',
    'observes',
    'observés',
    'observez',
    'obsolète',
    'obstacle',
    'obstinée',
    'obstiner',
    'obstinés',
    'obstinez',
    'obstruai',
    'obstruas',
    'obstruât',
    'obstruée',
    'obstruer',
    'obstrues',
    'obstrués',
    'obstruez',
    'obtenais',
    'obtenait',
    'obtenant',
    'obteniez',
    'obtenons',
    'obtenues',
    'obtienne',
    'obtînmes',
    'obtinsse',
    'obtîntes',
    'obturais',
    'obturait',
    'obturant',
    'obturées',
    'obturent',
    'obturera',
    'obturiez',
    'obturons',
    'obusiers',
    'obvenais',
    'obvenait',
    'obvenant',
    'obveniez',
    'obvenons',
    'obviâmes',
    'obviasse',
    'obviâtes',
    'obvienne',
    'obvierai',
    'obvieras',
    'obvierez',
    'obviions',
    'obvînmes',
    'obvinsse',
    'obvîntes',
    'ocarinas',
    'occasion',
    'occident',
    'occiputs',
    'occitane',
    'occitans',
    'occluais',
    'occluait',
    'occluant',
    'occluent',
    'occluiez',
    'occlûmes',
    'occluons',
    'occlurai',
    'occluras',
    'occlurez',
    'occlusif',
    'occlusse',
    'occlûtes',
    'occultai',
    'occultas',
    'occultât',
    'occultée',
    'occulter',
    'occultes',
    'occultés',
    'occultez',
    'occupais',
    'occupait',
    'occupant',
    'occupées',
    'occupent',
    'occupera',
    'occupiez',
    'occupons',
    'océanien',
    'ocellées',
    'ocraient',
    'ocrasses',
    'ocrerais',
    'ocrerait',
    'ocrèrent',
    'ocreriez',
    'ocrerons',
    'ocreront',
    'ocreuses',
    'octaviai',
    'octavias',
    'octaviât',
    'octaviée',
    'octavier',
    'octavies',
    'octaviés',
    'octaviez',
    'octavins',
    'octogone',
    'octopode',
    'octroies',
    'octroyai',
    'octroyas',
    'octroyât',
    'octroyée',
    'octroyer',
    'octroyés',
    'octroyez',
    'octuplai',
    'octuplas',
    'octuplât',
    'octuplée',
    'octupler',
    'octuples',
    'octuplés',
    'octuplez',
    'oculaire',
    'oculiste',
    'odelette',
    'odieuses',
    'odomètre',
    'odorante',
    'odorants',
    'odyssées',
    'oedipien',
    'oeillade',
    'oeillard',
    'oeillère',
    'oeillets',
    'oenanthe',
    'oersteds',
    'oerstite',
    'oestrale',
    'oestraux',
    'oeufrier',
    'oeuvrais',
    'oeuvrait',
    'oeuvrant',
    'oeuvrent',
    'oeuvrera',
    'oeuvriez',
    'oeuvrons',
    'offensai',
    'offensas',
    'offensât',
    'offensée',
    'offenser',
    'offenses',
    'offensés',
    'offensez',
    'offensif',
    'offertes',
    'officiai',
    'official',
    'officias',
    'officiât',
    'officiel',
    'officier',
    'officies',
    'officiez',
    'officine',
    'offrande',
    'offrante',
    'offrants',
    'offreurs',
    'offreuse',
    'offrîmes',
    'offrions',
    'offrirai',
    'offriras',
    'offrirez',
    'offrisse',
    'offrîtes',
    'offusqua',
    'offusque',
    'offusqué',
    'ogivales',
    'ogresses',
    'ohmiques',
    'ohmmètre',
    'oiselais',
    'oiselait',
    'oiselant',
    'oiselées',
    'oiselets',
    'oiseleur',
    'oiselier',
    'oiseliez',
    'oiselles',
    'oiselons',
    'oiseuses',
    'oisillon',
    'oisiveté',
    'oléfiant',
    'oléfines',
    'oléicole',
    'oléifère',
    'oléiques',
    'oléoducs',
    'oléolats',
    'olfactif',
    'olibrius',
    'olifants',
    'oligiste',
    'oligurie',
    'oliphant',
    'olivaies',
    'olivâtre',
    'olivette',
    'oliviers',
    'olivines',
    'ollaires',
    'olympien',
    'ombellée',
    'ombelles',
    'ombellés',
    'ombilics',
    'ombragea',
    'ombragée',
    'ombrager',
    'ombrages',
    'ombragés',
    'ombragez',
    'ombrâmes',
    'ombrasse',
    'ombrâtes',
    'ombrelle',
    'ombrerai',
    'ombreras',
    'ombrerez',
    'ombrette',
    'ombreuse',
    'ombriens',
    'ombrines',
    'ombrions',
    'omelette',
    'omettais',
    'omettait',
    'omettant',
    'omettent',
    'omettiez',
    'omettons',
    'omettrai',
    'omettras',
    'omettrez',
    'omissent',
    'omissiez',
    'omission',
    'omnivore',
    'omoplate',
    'onanisme',
    'onciales',
    'oncogène',
    'onctions',
    'onctueux',
    'ondatras',
    'ondoient',
    'ondoiera',
    'ondoyais',
    'ondoyait',
    'ondoyant',
    'ondoyées',
    'ondoyiez',
    'ondoyons',
    'ondulais',
    'ondulait',
    'ondulant',
    'ondulées',
    'ondulent',
    'ondulera',
    'onduleux',
    'onduliez',
    'ondulons',
    'onéreuse',
    'one-step',
    'onglette',
    'ongliers',
    'onguents',
    'ongulées',
    'onirique',
    'onirisme',
    'onusiens',
    'onzièmes',
    'oolithes',
    'oosphère',
    'oospores',
    'oothèque',
    'opacifia',
    'opacifie',
    'opacifié',
    'opacités',
    'opalines',
    'opalisai',
    'opalisas',
    'opalisât',
    'opalisée',
    'opaliser',
    'opalises',
    'opalisés',
    'opalisez',
    'opérable',
    'opérâmes',
    'opérande',
    'opérante',
    'opérants',
    'opérasse',
    'opérâtes',
    'opercule',
    'operculé',
    'opérerai',
    'opéreras',
    'opérerez',
    'opérette',
    'opérions',
    'ophidien',
    'ophiures',
    'opiaçais',
    'opiaçait',
    'opiaçant',
    'opiacées',
    'opiacent',
    'opiacera',
    'opiaciez',
    'opiaçons',
    'opinâmes',
    'opinasse',
    'opinâtes',
    'opinerai',
    'opineras',
    'opinerez',
    'opinions',
    'opiomane',
    'opopanax',
    'opossums',
    'oppidums',
    'opportun',
    'opposais',
    'opposait',
    'opposant',
    'opposées',
    'opposent',
    'opposera',
    'opposiez',
    'opposite',
    'opposons',
    'oppressa',
    'oppresse',
    'oppressé',
    'opprimai',
    'opprimas',
    'opprimât',
    'opprimée',
    'opprimer',
    'opprimes',
    'opprimés',
    'opprimez',
    'opprobre',
    'optaient',
    'optasses',
    'optatifs',
    'optative',
    'opterais',
    'opterait',
    'optèrent',
    'opteriez',
    'opterons',
    'opteront',
    'opticien',
    'optimale',
    'optimaux',
    'optimisa',
    'optimise',
    'optimisé',
    'optimums',
    'optiques',
    'opulence',
    'opulente',
    'opulents',
    'opuntias',
    'opuscule',
    'orageuse',
    'oraisons',
    'orangeai',
    'orangeas',
    'orangeât',
    'orangées',
    'orangent',
    'orangera',
    'orangers',
    'orangiez',
    'orateurs',
    'oratoire',
    'oratorio',
    'oratrice',
    'orbicole',
    'orbitais',
    'orbitait',
    'orbitale',
    'orbitant',
    'orbitaux',
    'orbitent',
    'orbitera',
    'orbitiez',
    'orbitons',
    'orchidée',
    'orchites',
    'ordalies',
    'ordinale',
    'ordinand',
    'ordinaux',
    'ordonnai',
    'ordonnas',
    'ordonnât',
    'ordonnée',
    'ordonner',
    'ordonnes',
    'ordonnés',
    'ordonnez',
    'ordurier',
    'oreiller',
    'oreilles',
    'oreillon',
    'orfèvres',
    'orfraies',
    'organdis',
    'organeau',
    'organisa',
    'organise',
    'organisé',
    'organite',
    'organsin',
    'orgasmes',
    'orgelets',
    'orgiaque',
    'orgueils',
    'orientai',
    'oriental',
    'orientas',
    'orientât',
    'orientée',
    'orienter',
    'orientes',
    'orientés',
    'orientez',
    'orifices',
    'original',
    'originel',
    'origines',
    'orignaux',
    'orillons',
    'oringuai',
    'oringuas',
    'oringuât',
    'oringuée',
    'oringuer',
    'oringues',
    'oringués',
    'oringuez',
    'oripeaux',
    'ormilles',
    'ornaient',
    'ornasses',
    'ornement',
    'ornerais',
    'ornerait',
    'ornèrent',
    'orneriez',
    'ornerons',
    'orneront',
    'ornières',
    'orogénie',
    'orphelin',
    'orphéons',
    'orphique',
    'orphisme',
    'orpiment',
    'orseille',
    'orthoses',
    'ortolans',
    'orviétan',
    'osassent',
    'osassiez',
    'oscillai',
    'oscillas',
    'oscillât',
    'osciller',
    'oscilles',
    'oscillez',
    'oseilles',
    'oseraies',
    'oserions',
    'osmiques',
    'osmondes',
    'ossature',
    'osséines',
    'osselets',
    'ossement',
    'osseuses',
    'ossifiai',
    'ossifias',
    'ossifiât',
    'ossifiée',
    'ossifier',
    'ossifies',
    'ossifiés',
    'ossifiez',
    'ossuaire',
    'ostéites',
    'ostioles',
    'ostrogot',
    'otalgies',
    'ôtassent',
    'ôtassiez',
    'ôterions',
    'otocyons',
    'otocyste',
    'otolithe',
    'otologie',
    'otorrhée',
    'otoscope',
    'ottomane',
    'ottomans',
    'ouabaïne',
    'ouailles',
    'ouatâmes',
    'ouatasse',
    'ouatâtes',
    'ouaterai',
    'ouateras',
    'ouaterez',
    'ouatinai',
    'ouatinas',
    'ouatinât',
    'ouatinée',
    'ouatiner',
    'ouatines',
    'ouatinés',
    'ouatinez',
    'ouations',
    'oubliais',
    'oubliait',
    'oubliant',
    'oubliées',
    'oublient',
    'oubliera',
    'oublieux',
    'oubliiez',
    'oublions',
    'ougriens',
    'ouï-dire',
    'ouïghour',
    'ouïgours',
    'ouillais',
    'ouillait',
    'ouillant',
    'ouillées',
    'ouillent',
    'ouillera',
    'ouillère',
    'ouilliez',
    'ouillons',
    'ouïrions',
    'ouïssent',
    'ouïssiez',
    'ouistiti',
    'ouragans',
    'ouralien',
    'ourdîmes',
    'ourdirai',
    'ourdiras',
    'ourdirez',
    'ourdisse',
    'ourdîtes',
    'ourlâmes',
    'ourlasse',
    'ourlâtes',
    'ourlerai',
    'ourleras',
    'ourlerez',
    'ourliens',
    'ourlions',
    'outardes',
    'outillai',
    'outillas',
    'outillât',
    'outillée',
    'outiller',
    'outilles',
    'outillés',
    'outillez',
    'outragea',
    'outragée',
    'outrager',
    'outrages',
    'outragés',
    'outragez',
    'outrâmes',
    'outrance',
    'outrasse',
    'outrâtes',
    'outremer',
    'outrerai',
    'outreras',
    'outrerez',
    'outrions',
    'outsider',
    'ouvertes',
    'ouvrable',
    'ouvragea',
    'ouvragée',
    'ouvrager',
    'ouvrages',
    'ouvragés',
    'ouvragez',
    'ouvrâmes',
    'ouvrante',
    'ouvrants',
    'ouvrasse',
    'ouvrâtes',
    'ouvreaus',
    'ouvrerai',
    'ouvreras',
    'ouvrerez',
    'ouvreurs',
    'ouvreuse',
    'ouvrière',
    'ouvriers',
    'ouvrîmes',
    'ouvrions',
    'ouvrirai',
    'ouvriras',
    'ouvrirez',
    'ouvrisse',
    'ouvrîtes',
    'ouvroirs',
    'ovalisai',
    'ovalisas',
    'ovalisât',
    'ovalisée',
    'ovaliser',
    'ovalises',
    'ovalisés',
    'ovalisez',
    'ovariens',
    'ovarites',
    'ovations',
    'overdose',
    'oviducte',
    'ovipares',
    'ovocytes',
    'ovoïdale',
    'ovoïdaux',
    'ovulaire',
    'ovulâmes',
    'ovulasse',
    'ovulâtes',
    'ovulerai',
    'ovuleras',
    'ovulerez',
    'ovulions',
    'oxacides',
    'oxalates',
    'oxalique',
    'oxydable',
    'oxydâmes',
    'oxydases',
    'oxydasse',
    'oxydâtes',
    'oxyderai',
    'oxyderas',
    'oxyderez',
    'oxydions',
    'oxygénai',
    'oxygénas',
    'oxygénât',
    'oxygénée',
    'oxygéner',
    'oxygénés',
    'oxygènes',
    'oxygénez',
    'oxylithe',
    'oxyurose',
    'ozonisai',
    'ozonisas',
    'ozonisât',
    'ozonisée',
    'ozoniser',
    'ozonises',
    'ozonisés',
    'ozonisez',
    'pacageai',
    'pacageas',
    'pacageât',
    'pacagées',
    'pacagent',
    'pacagera',
    'pacagiez',
    'pacfungs',
    'pachalik',
    'pacifiai',
    'pacifias',
    'pacifiât',
    'pacifiée',
    'pacifier',
    'pacifies',
    'pacifiés',
    'pacifiez',
    'packfung',
    'pacquage',
    'pacquais',
    'pacquait',
    'pacquant',
    'pacquées',
    'pacquent',
    'pacquera',
    'pacquiez',
    'pacquons',
    'pactisai',
    'pactisas',
    'pactisât',
    'pactiser',
    'pactises',
    'pactisez',
    'pactoles',
    'paddocks',
    'padichah',
    'pagaient',
    'pagaiera',
    'pagaille',
    'paganisa',
    'paganise',
    'paganisé',
    'pagayais',
    'pagayait',
    'pagayant',
    'pagayent',
    'pagayera',
    'pagayeur',
    'pagayiez',
    'pagayons',
    'pageâmes',
    'pageasse',
    'pageâtes',
    'pageotai',
    'pageotas',
    'pageotât',
    'pageotée',
    'pageoter',
    'pageotes',
    'pageotés',
    'pageotez',
    'pagerais',
    'pagerait',
    'pagèrent',
    'pageriez',
    'pagerons',
    'pageront',
    'paginais',
    'paginait',
    'paginant',
    'paginées',
    'paginent',
    'paginera',
    'paginiez',
    'paginons',
    'pagnotai',
    'pagnotas',
    'pagnotât',
    'pagnoter',
    'pagnotes',
    'pagnotez',
    'pagodons',
    'paiement',
    'païennes',
    'paierais',
    'paierait',
    'paieries',
    'paieriez',
    'paierons',
    'paieront',
    'paillage',
    'paillais',
    'paillait',
    'paillant',
    'paillard',
    'paillées',
    'paillent',
    'paillera',
    'pailleta',
    'pailleté',
    'paillets',
    'pailleux',
    'pailliez',
    'paillons',
    'paillote',
    'paillots',
    'pairesse',
    'paisible',
    'paissais',
    'paissait',
    'paissant',
    'paisseau',
    'paissela',
    'paisselé',
    'paissent',
    'paissiez',
    'paissons',
    'paîtrais',
    'paîtrait',
    'paîtriez',
    'paîtrons',
    'paîtront',
    'pajotais',
    'pajotait',
    'pajotant',
    'pajotées',
    'pajotent',
    'pajotera',
    'pajotiez',
    'pajotons',
    'palabrai',
    'palabras',
    'palabrât',
    'palabrer',
    'palabres',
    'palabrez',
    'paladins',
    'palanche',
    'palancra',
    'palancre',
    'palancré',
    'palangra',
    'palangre',
    'palangré',
    'palangua',
    'palangue',
    'palangué',
    'palanqua',
    'palanque',
    'palanqué',
    'palastre',
    'palatale',
    'palataux',
    'palatine',
    'palatins',
    'palefroi',
    'palémons',
    'paléosol',
    'palerons',
    'palestre',
    'paletots',
    'palettai',
    'palettas',
    'palettât',
    'palettée',
    'paletter',
    'palettes',
    'palettés',
    'palettez',
    'palicare',
    'pâlichon',
    'palières',
    'palikare',
    'pâlirais',
    'pâlirait',
    'pâlirent',
    'pâliriez',
    'pâlirons',
    'pâliront',
    'palissai',
    'palissas',
    'palissât',
    'palissée',
    'palisser',
    'palisses',
    'palissés',
    'pâlisses',
    'palissez',
    'pâlissez',
    'paliures',
    'palléale',
    'palléaux',
    'palliais',
    'palliait',
    'palliant',
    'pallidum',
    'palliées',
    'pallient',
    'palliera',
    'palliiez',
    'pallions',
    'palliums',
    'palmaire',
    'palmâmes',
    'palmarès',
    'palmasse',
    'palmâtes',
    'palmerai',
    'palmeras',
    'palmerez',
    'palmette',
    'palmiers',
    'palmions',
    'palmiste',
    'palmites',
    'palmures',
    'palombes',
    'palotais',
    'palotait',
    'palotant',
    'palotées',
    'palotent',
    'palotera',
    'palotiez',
    'palotons',
    'pâlottes',
    'palourde',
    'palpable',
    'palpâmes',
    'palpasse',
    'palpâtes',
    'palperai',
    'palperas',
    'palperez',
    'palpeurs',
    'palpions',
    'palpitai',
    'palpitas',
    'palpitât',
    'palpiter',
    'palpites',
    'palpitez',
    'paluches',
    'paludéen',
    'paludier',
    'paludine',
    'palustre',
    'pâmaient',
    'pâmasses',
    'pâmerais',
    'pâmerait',
    'pâmèrent',
    'pâmeriez',
    'pâmerons',
    'pâmeront',
    'pâmoison',
    'pampéros',
    'pamphlet',
    'pampille',
    'panacées',
    'panachai',
    'panachas',
    'panachât',
    'panachée',
    'panacher',
    'panaches',
    'panachés',
    'panachez',
    'panaient',
    'panardes',
    'panasses',
    'panatela',
    'pancarte',
    'pancrace',
    'pancréas',
    'pandanus',
    'pandèmes',
    'pandémie',
    'pandores',
    'panerais',
    'panerait',
    'panerées',
    'panèrent',
    'paneriez',
    'panerons',
    'paneront',
    'panetier',
    'panetons',
    'pangolin',
    'panicaut',
    'paniculé',
    'panières',
    'panifiai',
    'panifias',
    'panifiât',
    'panifiée',
    'panifier',
    'panifies',
    'panifiés',
    'panifiez',
    'paniquai',
    'paniquas',
    'paniquât',
    'paniquée',
    'paniquer',
    'paniques',
    'paniqués',
    'paniquez',
    'panmixie',
    'pannâmes',
    'pannasse',
    'pannâtes',
    'panneaux',
    'pannerai',
    'panneras',
    'pannerez',
    'panneton',
    'pannions',
    'panoplie',
    'panorama',
    'panorpes',
    'panosses',
    'panoufle',
    'pansages',
    'pansâmes',
    'pansasse',
    'pansâtes',
    'panserai',
    'panseras',
    'panserez',
    'pansions',
    'pantalon',
    'pantelai',
    'pantelas',
    'pantelât',
    'panteler',
    'pantelez',
    'pantelle',
    'pantènes',
    'pantenne',
    'panthéon',
    'panthère',
    'pantière',
    'pantoire',
    'pantoise',
    'pantoums',
    'papables',
    'papaïnes',
    'papautés',
    'papavers',
    'papayers',
    'papegais',
    'papelard',
    'papesses',
    'papetier',
    'papilles',
    'papillon',
    'papismes',
    'papistes',
    'papotage',
    'papotais',
    'papotait',
    'papotant',
    'papotent',
    'papotera',
    'papotiez',
    'papotons',
    'paprikas',
    'papuleux',
    'paquebot',
    'parabase',
    'parabole',
    'paraclet',
    'paradais',
    'paradait',
    'paradant',
    'paradent',
    'paradera',
    'paradeur',
    'paradiez',
    'paradons',
    'paradoxe',
    'parafais',
    'parafait',
    'parafant',
    'parafées',
    'parafent',
    'parafera',
    'parafiez',
    'parafons',
    'paraient',
    'paraisse',
    'paraîtra',
    'paraître',
    'paralysa',
    'paralyse',
    'paralysé',
    'parangon',
    'paranoïa',
    'parapets',
    'paraphai',
    'paraphas',
    'paraphât',
    'paraphée',
    'parapher',
    'paraphes',
    'paraphés',
    'paraphez',
    'parasita',
    'parasite',
    'parasité',
    'parasols',
    'parasses',
    'parataxe',
    'parâtres',
    'paravent',
    'parcages',
    'parcella',
    'parcelle',
    'parcellé',
    'parcoure',
    'parcours',
    'parcourt',
    'parcouru',
    'par-delà',
    'pardonna',
    'pardonne',
    'pardonné',
    'paréages',
    'pare-feu',
    'pareille',
    'parélies',
    'parement',
    'parental',
    'parentes',
    'parentés',
    'parèrent',
    'parésies',
    'paressai',
    'paressas',
    'paressât',
    'paresser',
    'paresses',
    'paressez',
    'pareuses',
    'parfaire',
    'parfaite',
    'parfaits',
    'parfasse',
    'parferai',
    'parferas',
    'parferez',
    'parfilai',
    'parfilas',
    'parfilât',
    'parfilée',
    'parfiler',
    'parfiles',
    'parfilés',
    'parfilez',
    'parfîmes',
    'parfisse',
    'parfîtes',
    'parfonde',
    'parfonds',
    'parfondu',
    'parfumai',
    'parfumas',
    'parfumât',
    'parfumée',
    'parfumer',
    'parfumes',
    'parfumés',
    'parfumez',
    'parhélie',
    'pariades',
    'pariages',
    'pariâmes',
    'pariasse',
    'pariâtes',
    'parierai',
    'parieras',
    'parierez',
    'pariétal',
    'parieurs',
    'parieuse',
    'parigote',
    'parigots',
    'pariions',
    'parisien',
    'parjurai',
    'parjuras',
    'parjurât',
    'parjurée',
    'parjurer',
    'parjures',
    'parjurés',
    'parjurez',
    'parkings',
    'parlâmes',
    'parlante',
    'parlants',
    'parlasse',
    'parlâtes',
    'parlerai',
    'parleras',
    'parlerez',
    'parleurs',
    'parleuse',
    'parlions',
    'parloirs',
    'parlotai',
    'parlotas',
    'parlotât',
    'parloter',
    'parlotes',
    'parlotez',
    'parlotte',
    'parmélie',
    'parmesan',
    'parnasse',
    'parodiai',
    'parodias',
    'parodiât',
    'parodiée',
    'parodier',
    'parodies',
    'parodiés',
    'parodiez',
    'paroisse',
    'parolier',
    'paronyme',
    'parotide',
    'parousie',
    'parpaing',
    'parquais',
    'parquait',
    'parquant',
    'parquées',
    'parquent',
    'parquera',
    'parqueta',
    'parqueté',
    'parquets',
    'parqueur',
    'parquiez',
    'parquons',
    'parraina',
    'parraine',
    'parrainé',
    'parrains',
    'parsemai',
    'parsemas',
    'parsemât',
    'parsemée',
    'parsemer',
    'parsemés',
    'parsèmes',
    'parsemez',
    'parsisme',
    'partagea',
    'partagée',
    'partager',
    'partages',
    'partagés',
    'partagez',
    'partance',
    'partante',
    'partants',
    'parterre',
    'partiale',
    'partiaux',
    'partiels',
    'partîmes',
    'partions',
    'partirai',
    'partiras',
    'partirez',
    'partisan',
    'partisse',
    'partîtes',
    'partitif',
    'partouse',
    'partouze',
    'parulies',
    'parurent',
    'parurier',
    'parusses',
    'parution',
    'parvenez',
    'parvenir',
    'parvenue',
    'parvenus',
    'parviens',
    'parvient',
    'pascales',
    'pasquins',
    'passable',
    'passades',
    'passager',
    'passages',
    'passâmes',
    'passante',
    'passants',
    'passasse',
    'passâtes',
    'passerai',
    'passeras',
    'passerez',
    'passeurs',
    'passeuse',
    'passible',
    'passions',
    'passives',
    'passoire',
    'pastella',
    'pastelle',
    'pastellé',
    'pastèque',
    'pasteurs',
    'pasticha',
    'pastiche',
    'pastiché',
    'pastilla',
    'pastille',
    'pastillé',
    'pastoral',
    'pataches',
    'patachon',
    'pâtaient',
    'patapouf',
    'pataquès',
    'pâtasses',
    'patatras',
    'pataudes',
    'pataugea',
    'patauger',
    'patauges',
    'pataugez',
    'patelina',
    'pateline',
    'pateliné',
    'patelins',
    'patelles',
    'patentai',
    'patentas',
    'patentât',
    'patentée',
    'patenter',
    'patentes',
    'patentés',
    'patentez',
    'pâterais',
    'pâterait',
    'pâtèrent',
    'pâteriez',
    'paternel',
    'paternes',
    'pâterons',
    'pâteront',
    'pâteuses',
    'patience',
    'patienta',
    'patiente',
    'patienté',
    'patients',
    'patinage',
    'patinais',
    'patinait',
    'patinant',
    'patinées',
    'patinent',
    'patinera',
    'patineur',
    'patiniez',
    'patinons',
    'pâtirais',
    'pâtirait',
    'pâtirent',
    'pâtiriez',
    'pâtirons',
    'pâtiront',
    'pâtissai',
    'pâtissas',
    'pâtissât',
    'pâtissée',
    'pâtisser',
    'pâtisses',
    'pâtissés',
    'pâtissez',
    'pâtisson',
    'patoches',
    'patoisai',
    'patoisas',
    'patoisât',
    'patoiser',
    'patoises',
    'patoisez',
    'patraque',
    'patrices',
    'patriote',
    'patronal',
    'patronat',
    'patronna',
    'patronne',
    'patronné',
    'pattâmes',
    'pattasse',
    'pattâtes',
    'patterai',
    'patteras',
    'patterez',
    'patterns',
    'pattions',
    'pâturage',
    'pâturais',
    'pâturait',
    'pâturant',
    'pâturées',
    'pâturent',
    'pâturera',
    'pâturiez',
    'pâturins',
    'paturons',
    'pâturons',
    'paulette',
    'pauliens',
    'pauliste',
    'paumâmes',
    'paumasse',
    'paumâtes',
    'paumelle',
    'paumerai',
    'paumeras',
    'paumerez',
    'paumiers',
    'paumions',
    'paumoies',
    'paumoyai',
    'paumoyas',
    'paumoyât',
    'paumoyée',
    'paumoyer',
    'paumoyés',
    'paumoyez',
    'paumures',
    'paupière',
    'pausâmes',
    'pausasse',
    'pausâtes',
    'pauserai',
    'pauseras',
    'pauserez',
    'pausions',
    'pauvreté',
    'pauvrets',
    'pavaient',
    'pavanais',
    'pavanait',
    'pavanant',
    'pavanées',
    'pavanent',
    'pavanera',
    'pavaniez',
    'pavanons',
    'pavasses',
    'pavement',
    'paverais',
    'paverait',
    'pavèrent',
    'paveriez',
    'paverons',
    'paveront',
    'pavillon',
    'pavoisai',
    'pavoisas',
    'pavoisât',
    'pavoisée',
    'pavoiser',
    'pavoises',
    'pavoisés',
    'pavoisez',
    'payables',
    'payaient',
    'payantes',
    'payasses',
    'payement',
    'payerais',
    'payerait',
    'payèrent',
    'payeriez',
    'payerons',
    'payeront',
    'payeuses',
    'paysager',
    'paysages',
    'paysanne',
    'péageère',
    'péageers',
    'péagiste',
    'peaucier',
    'peaufina',
    'peaufine',
    'peaufiné',
    'peaussai',
    'peaussas',
    'peaussât',
    'peausser',
    'peausses',
    'peaussez',
    'pébrines',
    'pébroque',
    'peccable',
    'péchâmes',
    'pêchâmes',
    'péchasse',
    'pêchasse',
    'péchâtes',
    'pêchâtes',
    'pécherai',
    'pêcherai',
    'pécheras',
    'pêcheras',
    'pécherez',
    'pêcherez',
    'pêcherie',
    'pêchette',
    'pécheurs',
    'pêcheurs',
    'pêcheuse',
    'péchions',
    'pêchions',
    'péclotai',
    'péclotas',
    'péclotât',
    'pécloter',
    'péclotes',
    'péclotez',
    'pectinée',
    'pectines',
    'pectinés',
    'pectique',
    'pectoral',
    'péculats',
    'pédalais',
    'pédalait',
    'pédalant',
    'pédalent',
    'pédalera',
    'pédaleur',
    'pédalier',
    'pédaliez',
    'pédalons',
    'pédantes',
    'pédestre',
    'pédiatre',
    'pédiculé',
    'pédicure',
    'pédieuse',
    'pedigree',
    'pédimane',
    'pédiment',
    'peelings',
    'peignage',
    'peignais',
    'peignait',
    'peignant',
    'peignées',
    'peignent',
    'peignera',
    'peigneur',
    'peignier',
    'peigniez',
    'peignoir',
    'peignons',
    'peinâmes',
    'peinarde',
    'peinards',
    'peinasse',
    'peinâtes',
    'peindrai',
    'peindras',
    'peindrez',
    'peinerai',
    'peineras',
    'peinerez',
    'peinions',
    'peintres',
    'peintura',
    'peinture',
    'peinturé',
    'pékinées',
    'pékinois',
    'pélagien',
    'pelaient',
    'pélamide',
    'pélamyde',
    'pelasses',
    'peléenne',
    'péléenne',
    'pèlerais',
    'pèlerait',
    'pelèrent',
    'pèleriez',
    'pèlerine',
    'pèlerins',
    'pèlerons',
    'pèleront',
    'péliades',
    'pélicans',
    'pelisses',
    'pellagre',
    'pelletai',
    'pelletas',
    'pelletât',
    'pelletée',
    'pelleter',
    'pelletés',
    'pelletez',
    'pellette',
    'pélobate',
    'pélodyte',
    'pelotage',
    'pelotais',
    'pelotait',
    'pelotant',
    'pelotari',
    'pelotées',
    'pelotent',
    'pelotera',
    'peloteur',
    'pelotiez',
    'pelotons',
    'pelouses',
    'peltaste',
    'peluchai',
    'peluchas',
    'peluchât',
    'peluchée',
    'pelucher',
    'peluches',
    'peluchés',
    'peluchez',
    'pelviens',
    'pemmican',
    'pénalisa',
    'pénalise',
    'pénalisé',
    'pénalité',
    'penaltys',
    'pénardes',
    'penaudes',
    'penchais',
    'penchait',
    'penchant',
    'penchées',
    'penchent',
    'penchera',
    'penchiez',
    'penchons',
    'pendable',
    'pendages',
    'pendante',
    'pendants',
    'pendards',
    'penderie',
    'pendilla',
    'pendille',
    'pendillé',
    'pendîmes',
    'pendions',
    'pendisse',
    'pendîtes',
    'pendoirs',
    'pendrais',
    'pendrait',
    'pendriez',
    'pendrons',
    'pendront',
    'pendulai',
    'pendulas',
    'pendulât',
    'penduler',
    'pendules',
    'pendulez',
    'pénétrai',
    'pénétras',
    'pénétrât',
    'pénétrée',
    'pénétrer',
    'pénétrés',
    'pénètres',
    'pénétrez',
    'pénibles',
    'péniches',
    'pénienne',
    'pénitent',
    'pénombre',
    'pensable',
    'pensâmes',
    'pensante',
    'pensants',
    'pensasse',
    'pensâtes',
    'penserai',
    'penseras',
    'penserez',
    'penseurs',
    'penseuse',
    'pensions',
    'pensives',
    'pentacle',
    'pentanes',
    'pentapol',
    'penthode',
    'pentodes',
    'pentoses',
    'pentures',
    'pénuries',
    'pépiâmes',
    'pépiasse',
    'pépiâtes',
    'pépierai',
    'pépieras',
    'pépierez',
    'pépiions',
    'péponide',
    'pepsines',
    'peptides',
    'peptique',
    'peptones',
    'péquenot',
    'péquiste',
    'péramèle',
    'perçages',
    'percales',
    'perçâmes',
    'perçante',
    'perçants',
    'perçasse',
    'perçâtes',
    'percepts',
    'percerai',
    'perceras',
    'percerez',
    'perceurs',
    'perceuse',
    'percevez',
    'percevra',
    'perchais',
    'perchait',
    'perchant',
    'perchées',
    'perchent',
    'perchera',
    'percheur',
    'perchiez',
    'perchman',
    'perchmen',
    'perchoir',
    'perchons',
    'percions',
    'percluse',
    'perçoive',
    'perçûmes',
    'perçusse',
    'percutai',
    'percutas',
    'percutât',
    'percutée',
    'percuter',
    'percutes',
    'percutés',
    'perçûtes',
    'percutez',
    'perdable',
    'perdante',
    'perdants',
    'perdîmes',
    'perdions',
    'perdisse',
    'perdîtes',
    'perdrais',
    'perdrait',
    'perdreau',
    'perdriez',
    'perdrons',
    'perdront',
    'perdurai',
    'perduras',
    'perdurât',
    'perdurer',
    'perdures',
    'perdurez',
    'pérennes',
    'perfides',
    'perfidie',
    'perfolié',
    'perforai',
    'perforas',
    'perforât',
    'perforée',
    'perforer',
    'perfores',
    'perforés',
    'perforez',
    'perfusai',
    'perfusas',
    'perfusât',
    'perfusée',
    'perfuser',
    'perfuses',
    'perfusés',
    'perfusez',
    'pergolas',
    'péridots',
    'périgées',
    'périmais',
    'périmait',
    'périmant',
    'périmées',
    'périment',
    'périmera',
    'périmiez',
    'périmons',
    'périnéal',
    'périnées',
    'périodes',
    'périoste',
    'périples',
    'périrais',
    'périrait',
    'périrent',
    'péririez',
    'périrons',
    'périront',
    'périsses',
    'périssez',
    'perlâmes',
    'perlasse',
    'perlâtes',
    'perlèche',
    'perlerai',
    'perleras',
    'perlerez',
    'perlière',
    'perliers',
    'perlions',
    'perlouse',
    'perlouze',
    'permette',
    'permiens',
    'permîmes',
    'permises',
    'permisse',
    'permîtes',
    'permutai',
    'permutas',
    'permutât',
    'permutée',
    'permuter',
    'permutes',
    'permutés',
    'permutez',
    'péronier',
    'pérorais',
    'pérorait',
    'pérorant',
    'pérorent',
    'pérorera',
    'péroreur',
    'péroriez',
    'pérorons',
    'peroxyda',
    'péroxyda',
    'peroxyde',
    'peroxydé',
    'péroxyde',
    'péroxydé',
    'perpétra',
    'perpétré',
    'perpètre',
    'perpétua',
    'perpétue',
    'perpétué',
    'perplexe',
    'perruche',
    'perruque',
    'persanes',
    'persicot',
    'persifla',
    'persifle',
    'persiflé',
    'persilla',
    'persille',
    'persillé',
    'persista',
    'persiste',
    'persisté',
    'personne',
    'persuada',
    'persuade',
    'persuadé',
    'perturba',
    'perturbe',
    'perturbé',
    'péruvien',
    'perverse',
    'perverti',
    'pervibra',
    'pervibre',
    'pervibré',
    'pesaient',
    'pesantes',
    'pesasses',
    'pèserais',
    'pèserait',
    'pesèrent',
    'pèseriez',
    'pèserons',
    'pèseront',
    'pèse-sel',
    'pesettes',
    'peseuses',
    'pessaire',
    'pestâmes',
    'pestasse',
    'pestâtes',
    'pesterai',
    'pesteras',
    'pesterez',
    'pesteuse',
    'pestions',
    'pétaient',
    'pétanque',
    'pétantes',
    'pétarada',
    'pétarade',
    'pétaradé',
    'pétardai',
    'pétardas',
    'pétardât',
    'pétardée',
    'pétarder',
    'pétardes',
    'pétardés',
    'pétardez',
    'pétasses',
    'pétéchie',
    'péterais',
    'péterait',
    'pétèrent',
    'péteriez',
    'péterons',
    'péteront',
    'pète-sec',
    'péteuses',
    'pétillai',
    'pétillas',
    'pétillât',
    'pétiller',
    'pétilles',
    'pétillez',
    'pétiolée',
    'pétioles',
    'pétiolés',
    'petiotes',
    'pétition',
    'pétoches',
    'pétoires',
    'pétoncle',
    'pétreuse',
    'pétrifia',
    'pétrifie',
    'pétrifié',
    'pétrîmes',
    'pétrirai',
    'pétriras',
    'pétrirez',
    'pétrisse',
    'pétrîtes',
    'pétroles',
    'pétulant',
    'pétunais',
    'pétunait',
    'pétunant',
    'pétunent',
    'pétunera',
    'pétunias',
    'pétuniez',
    'pétunons',
    'peucédan',
    'peuchère',
    'peulvens',
    'peuplade',
    'peuplais',
    'peuplait',
    'peuplant',
    'peuplées',
    'peuplent',
    'peuplera',
    'peuplier',
    'peupliez',
    'peuplons',
    'peureuse',
    'pfennigs',
    'phalange',
    'phalènes',
    'phalères',
    'phalline',
    'phanères',
    'pharaons',
    'pharyngé',
    'phénates',
    'phénique',
    'phéniqué',
    'philtres',
    'phlébite',
    'phlegmon',
    'phobique',
    'phocéens',
    'pholades',
    'pholiote',
    'phonèmes',
    'phonique',
    'phormium',
    'phosgène',
    'phrasais',
    'phrasait',
    'phrasant',
    'phrasées',
    'phrasent',
    'phrasera',
    'phraseur',
    'phrasiez',
    'phrasons',
    'phratrie',
    'phrygane',
    'phrygien',
    'phtisies',
    'phyllade',
    'phyllies',
    'physalie',
    'physalis',
    'physique',
    'piaffais',
    'piaffait',
    'piaffant',
    'piaffent',
    'piaffera',
    'piaffeur',
    'piaffiez',
    'piaffons',
    'piaillai',
    'piaillas',
    'piaillât',
    'piailler',
    'piailles',
    'piaillez',
    'pianiste',
    'pianotai',
    'pianotas',
    'pianotât',
    'pianotée',
    'pianoter',
    'pianotes',
    'pianotés',
    'pianotez',
    'piassava',
    'piastres',
    'piaulais',
    'piaulait',
    'piaulant',
    'piaulent',
    'piaulera',
    'piauliez',
    'piaulons',
    'pibrocks',
    'picadors',
    'picardan',
    'picardes',
    'picarels',
    'piccolos',
    'picolais',
    'picolait',
    'picolant',
    'picolées',
    'picolent',
    'picolera',
    'picoleur',
    'picoliez',
    'picolons',
    'picorais',
    'picorait',
    'picorant',
    'picorées',
    'picorent',
    'picorera',
    'picoriez',
    'picorons',
    'picotais',
    'picotait',
    'picotant',
    'picotées',
    'picotent',
    'picotera',
    'picotiez',
    'picotins',
    'picotons',
    'picpouls',
    'picrates',
    'picrique',
    'pictural',
    'piécette',
    'pied-bot',
    'piédroit',
    'piéforts',
    'piégeage',
    'piégeais',
    'piégeait',
    'piégeant',
    'piégeons',
    'piégerai',
    'piégeras',
    'piégerez',
    'piégeurs',
    'piégions',
    'piémonts',
    'piérides',
    'pierrais',
    'pierrait',
    'pierrant',
    'pierrées',
    'pierrent',
    'pierrera',
    'pierreux',
    'pierrier',
    'pierriez',
    'pierrons',
    'pierrots',
    'piétâmes',
    'piétasse',
    'piétâtes',
    'piéterai',
    'piéteras',
    'piéterez',
    'piétinai',
    'piétinas',
    'piétinât',
    'piétinée',
    'piétiner',
    'piétines',
    'piétinés',
    'piétinez',
    'piétions',
    'piétisme',
    'piétiste',
    'piétonne',
    'pieutais',
    'pieutait',
    'pieutant',
    'pieutées',
    'pieutent',
    'pieutera',
    'pieutiez',
    'pieutons',
    'pieuvres',
    'pifaient',
    'pifasses',
    'piferais',
    'piferait',
    'pifèrent',
    'piferiez',
    'piferons',
    'piferont',
    'piffâmes',
    'piffasse',
    'piffâtes',
    'pifferai',
    'pifferas',
    'pifferez',
    'piffions',
    'pigeâmes',
    'pigeasse',
    'pigeâtes',
    'pigeonna',
    'pigeonne',
    'pigeonné',
    'pigerais',
    'pigerait',
    'pigèrent',
    'pigeriez',
    'pigerons',
    'pigeront',
    'pigistes',
    'pigmenta',
    'pigmente',
    'pigmenté',
    'pigments',
    'pignadas',
    'pignades',
    'pignocha',
    'pignoche',
    'pignoché',
    'pignoufs',
    'pilaient',
    'pilaires',
    'pilasses',
    'pilastre',
    'pilchard',
    'pilerais',
    'pilerait',
    'pilèrent',
    'pileriez',
    'pilerons',
    'pileront',
    'pileuses',
    'pilifère',
    'pillages',
    'pillâmes',
    'pillarde',
    'pillards',
    'pillasse',
    'pillâtes',
    'pillerai',
    'pilleras',
    'pillerez',
    'pilleurs',
    'pilleuse',
    'pillions',
    'pilonnai',
    'pilonnas',
    'pilonnât',
    'pilonnée',
    'pilonner',
    'pilonnes',
    'pilonnés',
    'pilonnez',
    'pilosité',
    'pilotage',
    'pilotais',
    'pilotait',
    'pilotant',
    'pilotées',
    'pilotent',
    'pilotera',
    'pilotiez',
    'pilotins',
    'pilotons',
    'pilulier',
    'pimbêche',
    'pimentai',
    'pimentas',
    'pimentât',
    'pimentée',
    'pimenter',
    'pimentes',
    'pimentés',
    'pimentez',
    'pimpante',
    'pimpants',
    'pinacles',
    'pinailla',
    'pinaille',
    'pinaillé',
    'pinasses',
    'pinastre',
    'pinçâmes',
    'pinçasse',
    'pinçâtes',
    'pinceaux',
    'pincerai',
    'pinceras',
    'pincerez',
    'pincette',
    'pinchard',
    'pincions',
    'pinéales',
    'pineraie',
    'pingouin',
    'pinières',
    'pinnules',
    'pintades',
    'pintâmes',
    'pintasse',
    'pintâtes',
    'pinterai',
    'pinteras',
    'pinterez',
    'pintions',
    'piochage',
    'piochais',
    'piochait',
    'piochant',
    'piochées',
    'piochent',
    'piochera',
    'piocheur',
    'piochiez',
    'piochons',
    'pionçais',
    'pionçait',
    'pionçant',
    'pioncent',
    'pioncera',
    'pionciez',
    'pionçons',
    'pionnais',
    'pionnait',
    'pionnant',
    'pionnent',
    'pionnera',
    'pionnier',
    'pionniez',
    'pionnons',
    'pioupiou',
    'pipaient',
    'pipasses',
    'pipelets',
    'pipeline',
    'piperade',
    'piperais',
    'piperait',
    'pipèrent',
    'piperies',
    'piperiez',
    'pipérine',
    'piperons',
    'piperont',
    'pipettes',
    'pipeuses',
    'pipières',
    'piquages',
    'piquâmes',
    'piquante',
    'piquants',
    'piquasse',
    'piquâtes',
    'piquerai',
    'piqueras',
    'piquerez',
    'piquetai',
    'piquetas',
    'piquetât',
    'piquetée',
    'piqueter',
    'piquetés',
    'piquetez',
    'piquette',
    'piqueurs',
    'piqueuse',
    'piquiers',
    'piquions',
    'piranhas',
    'piratage',
    'piratais',
    'piratait',
    'piratant',
    'piratent',
    'piratera',
    'piratiez',
    'piratons',
    'pirogues',
    'piscines',
    'pissâmes',
    'pissasse',
    'pissâtes',
    'pisserai',
    'pisseras',
    'pisserez',
    'pissette',
    'pisseurs',
    'pisseuse',
    'pissions',
    'pissoirs',
    'pistacha',
    'pistache',
    'pistaché',
    'pistages',
    'pistâmes',
    'pistards',
    'pistasse',
    'pistâtes',
    'pisterai',
    'pisteras',
    'pisterez',
    'pisteurs',
    'pistions',
    'pistoles',
    'pistolet',
    'pistonna',
    'pistonne',
    'pistonné',
    'pitances',
    'pitchpin',
    'piteuses',
    'pitonnai',
    'pitonnas',
    'pitonnât',
    'pitonner',
    'pitonnes',
    'pitonnez',
    'pitrerie',
    'pituites',
    'pivoines',
    'pivotais',
    'pivotait',
    'pivotant',
    'pivotées',
    'pivotent',
    'pivotera',
    'pivotiez',
    'pivotons',
    'pizzeria',
    'placages',
    'plaçâmes',
    'placarda',
    'placarde',
    'placardé',
    'placards',
    'plaçasse',
    'plaçâtes',
    'placebos',
    'placenta',
    'placerai',
    'placeras',
    'placerez',
    'placette',
    'placeurs',
    'placeuse',
    'placides',
    'placiers',
    'placions',
    'plafonds',
    'plafonna',
    'plafonne',
    'plafonné',
    'plagales',
    'plagiais',
    'plagiait',
    'plagiant',
    'plagiats',
    'plagiées',
    'plagient',
    'plagiera',
    'plagiiez',
    'plagions',
    'plagiste',
    'plaidais',
    'plaidait',
    'plaidant',
    'plaidées',
    'plaident',
    'plaidera',
    'plaideur',
    'plaidiez',
    'plaidons',
    'plaignes',
    'plaignez',
    'plaignis',
    'plaignit',
    'plaignît',
    'plainais',
    'plainait',
    'plainant',
    'plaindra',
    'plaindre',
    'plainées',
    'plainent',
    'plainera',
    'plainiez',
    'plainons',
    'plaintes',
    'plaintif',
    'plairais',
    'plairait',
    'plairiez',
    'plairons',
    'plairont',
    'plaisais',
    'plaisait',
    'plaisant',
    'plaisent',
    'plaisiez',
    'plaisirs',
    'plaisons',
    'planages',
    'planaire',
    'planâmes',
    'planasse',
    'planâtes',
    'planchai',
    'planchas',
    'planchât',
    'plancher',
    'planches',
    'planchez',
    'plancton',
    'planéité',
    'planerai',
    'planeras',
    'planerez',
    'planètes',
    'planeurs',
    'planeuse',
    'planèzes',
    'planifia',
    'planifie',
    'planifié',
    'planions',
    'planisme',
    'planning',
    'planoirs',
    'planorbe',
    'planquai',
    'planquas',
    'planquât',
    'planquée',
    'planquer',
    'planques',
    'planqués',
    'planquez',
    'plantage',
    'plantain',
    'plantais',
    'plantait',
    'plantant',
    'plantées',
    'plantent',
    'plantera',
    'planteur',
    'plantiez',
    'plantoir',
    'plantons',
    'plantule',
    'plaquage',
    'plaquais',
    'plaquait',
    'plaquant',
    'plaquées',
    'plaquent',
    'plaquera',
    'plaqueur',
    'plaquiez',
    'plaquons',
    'plasmode',
    'plastics',
    'plasties',
    'plastron',
    'platanes',
    'plateaux',
    'platière',
    'platinai',
    'platinas',
    'platinât',
    'platinée',
    'platiner',
    'platines',
    'platinés',
    'platinez',
    'plâtrage',
    'plâtrais',
    'plâtrait',
    'plâtrant',
    'plâtrées',
    'plâtrent',
    'plâtrera',
    'plâtreux',
    'plâtrier',
    'plâtriez',
    'plâtrons',
    'play-boy',
    'plébéien',
    'plectres',
    'pléiades',
    'plénière',
    'pléniers',
    'pléthore',
    'pleurage',
    'pleurais',
    'pleurait',
    'pleurale',
    'pleurant',
    'pleurard',
    'pleuraux',
    'pleurées',
    'pleurent',
    'pleurera',
    'pleureur',
    'pleuriez',
    'pleurite',
    'pleurons',
    'pleurote',
    'pleutres',
    'pleuvait',
    'pleuvent',
    'pleuvina',
    'pleuvine',
    'pleuviné',
    'pleuvoir',
    'pleuvota',
    'pleuvote',
    'pleuvoté',
    'pliables',
    'pliaient',
    'pliantes',
    'pliasses',
    'pliement',
    'plierais',
    'plierait',
    'plièrent',
    'plieriez',
    'plierons',
    'plieront',
    'plieuses',
    'plinthes',
    'pliocène',
    'plissage',
    'plissais',
    'plissait',
    'plissant',
    'plissées',
    'plissent',
    'plissera',
    'plisseur',
    'plissiez',
    'plissons',
    'plissure',
    'ploierai',
    'ploieras',
    'ploierez',
    'plombage',
    'plombais',
    'plombait',
    'plombant',
    'plombées',
    'plombent',
    'plombera',
    'plombeur',
    'plombier',
    'plombiez',
    'plomboir',
    'plombons',
    'plombure',
    'plommées',
    'plongeai',
    'plongeas',
    'plongeât',
    'plongées',
    'plongent',
    'plongeon',
    'plongera',
    'plongeur',
    'plongiez',
    'ploquais',
    'ploquait',
    'ploquant',
    'ploquées',
    'ploquent',
    'ploquera',
    'ploquiez',
    'ploquons',
    'ployable',
    'ployâmes',
    'ployasse',
    'ployâtes',
    'ployions',
    'pluchais',
    'pluchait',
    'pluchant',
    'pluchent',
    'pluchera',
    'plucheux',
    'pluchiez',
    'pluchons',
    'plumages',
    'plumâmes',
    'plumards',
    'plumasse',
    'plumâtes',
    'plumeaux',
    'plumerai',
    'plumeras',
    'plumerez',
    'plumetée',
    'plumetés',
    'plumetis',
    'plumeurs',
    'plumeuse',
    'plumiers',
    'plumions',
    'plumitif',
    'plumules',
    'plurales',
    'pluriels',
    'plussent',
    'plussiez',
    'pluviale',
    'pluvians',
    'pluviaux',
    'pluviers',
    'pluvieux',
    'pluvinât',
    'pluviner',
    'pluviôse',
    'pochades',
    'pochâmes',
    'pocharde',
    'pochards',
    'pochasse',
    'pochâtes',
    'pocherai',
    'pocheras',
    'pocherez',
    'pochetée',
    'pochette',
    'pocheuse',
    'pochions',
    'pochoirs',
    'pochouse',
    'podagres',
    'podaires',
    'podestat',
    'poeciles',
    'poêlâmes',
    'poêlasse',
    'poêlâtes',
    'poêlerai',
    'poêleras',
    'poêlerez',
    'poêlions',
    'poétesse',
    'poétique',
    'poétisai',
    'poétisas',
    'poétisât',
    'poétisée',
    'poétiser',
    'poétises',
    'poétisés',
    'poétisez',
    'pogromes',
    'poignais',
    'poignait',
    'poignant',
    'poignard',
    'poignées',
    'poignent',
    'poignets',
    'poigniez',
    'poignons',
    'poilâmes',
    'poilante',
    'poilants',
    'poilasse',
    'poilâtes',
    'poilerai',
    'poileras',
    'poilerez',
    'poilions',
    'poinçons',
    'poindrai',
    'poindras',
    'poindrez',
    'pointage',
    'pointais',
    'pointait',
    'pointant',
    'pointaux',
    'pointeau',
    'pointées',
    'pointent',
    'pointera',
    'pointeur',
    'pointiez',
    'pointons',
    'pointues',
    'pointure',
    'poireaux',
    'poiriers',
    'poirotai',
    'poirotas',
    'poirotât',
    'poiroter',
    'poirotes',
    'poirotez',
    'poissais',
    'poissait',
    'poissant',
    'poissard',
    'poissées',
    'poissent',
    'poissera',
    'poisseux',
    'poissiez',
    'poissons',
    'poitevin',
    'poitrail',
    'poitrine',
    'poivrade',
    'poivrais',
    'poivrait',
    'poivrant',
    'poivrées',
    'poivrent',
    'poivrera',
    'poivrier',
    'poivriez',
    'poivrons',
    'poivrote',
    'poivrots',
    'polacres',
    'polaires',
    'polaques',
    'polarisa',
    'polarise',
    'polarisé',
    'polarité',
    'Polaroïd',
    'polentas',
    'poliçais',
    'poliçait',
    'poliçant',
    'policées',
    'policent',
    'policera',
    'policier',
    'policiez',
    'poliçons',
    'poliment',
    'polirais',
    'polirait',
    'polirent',
    'poliriez',
    'polirons',
    'poliront',
    'polisses',
    'polissez',
    'polisson',
    'politisa',
    'politise',
    'politisé',
    'polluais',
    'polluait',
    'polluant',
    'polluées',
    'polluent',
    'polluera',
    'pollueur',
    'polluiez',
    'polluons',
    'polochon',
    'polonais',
    'polonium',
    'poltrons',
    'polyèdre',
    'polygala',
    'polygale',
    'polygame',
    'polygone',
    'polynôme',
    'polypeux',
    'polypier',
    'polypode',
    'polypore',
    'polysocs',
    'polytric',
    'polyurie',
    'pommadai',
    'pommadas',
    'pommadât',
    'pommadée',
    'pommader',
    'pommades',
    'pommadés',
    'pommadez',
    'pommâmes',
    'pommards',
    'pommasse',
    'pommâtes',
    'pommeaux',
    'pommelai',
    'pommelas',
    'pommelât',
    'pommelée',
    'pommeler',
    'pommelés',
    'pommelez',
    'pommelle',
    'pommerai',
    'pommeras',
    'pommerât',
    'pommetée',
    'pommetés',
    'pommette',
    'pommiers',
    'pommions',
    'pompages',
    'pompâmes',
    'pompasse',
    'pompâtes',
    'pompéien',
    'pomperai',
    'pomperas',
    'pomperez',
    'pompette',
    'pompeurs',
    'pompeuse',
    'pompière',
    'pompiers',
    'pompiles',
    'pompions',
    'pompiste',
    'pomponna',
    'pomponne',
    'pomponné',
    'ponçages',
    'ponçâmes',
    'ponçasse',
    'ponçâtes',
    'ponceaux',
    'poncelet',
    'poncerai',
    'ponceras',
    'poncerez',
    'ponceurs',
    'ponceuse',
    'poncions',
    'ponction',
    'ponctuai',
    'ponctuas',
    'ponctuât',
    'ponctuée',
    'ponctuel',
    'ponctuer',
    'ponctues',
    'ponctués',
    'ponctuez',
    'pondérai',
    'pondéral',
    'pondéras',
    'pondérât',
    'pondérée',
    'pondérer',
    'pondérés',
    'pondères',
    'pondérez',
    'pondeurs',
    'pondeuse',
    'pondîmes',
    'pondions',
    'pondisse',
    'pondîtes',
    'pondoirs',
    'pondrais',
    'pondrait',
    'pondriez',
    'pondrons',
    'pondront',
    'pongiste',
    'pontages',
    'pontâmes',
    'pontasse',
    'pontâtes',
    'ponterai',
    'ponteras',
    'ponterez',
    'pontiers',
    'pontifes',
    'pontifia',
    'pontifie',
    'pontifié',
    'pontilla',
    'pontille',
    'pontillé',
    'pontions',
    'pop-corn',
    'popeline',
    'poplitée',
    'poplités',
    'popotins',
    'populace',
    'populage',
    'populéum',
    'populeux',
    'poquâmes',
    'poquasse',
    'poquâtes',
    'poquerai',
    'poqueras',
    'poquerez',
    'poquions',
    'porcelet',
    'porchère',
    'porchers',
    'porcines',
    'poreuses',
    'porosité',
    'porphyre',
    'porreaux',
    'porridge',
    'portable',
    'portages',
    'portails',
    'portâmes',
    'portance',
    'portante',
    'portants',
    'portasse',
    'portâtes',
    'portatif',
    'porterai',
    'porteras',
    'porterez',
    'porterie',
    'porteurs',
    'porteuse',
    'portière',
    'portiers',
    'portions',
    'portique',
    'portland',
    'portrait',
    'portulan',
    'portunes',
    'posaient',
    'posasses',
    'posément',
    'poserais',
    'poserait',
    'posèrent',
    'poseriez',
    'poserons',
    'poseront',
    'poseuses',
    'positifs',
    'position',
    'positive',
    'positons',
    'positron',
    'possédai',
    'possédas',
    'possédât',
    'possédée',
    'posséder',
    'possédés',
    'possèdes',
    'possédez',
    'possible',
    'postages',
    'postales',
    'postâmes',
    'postasse',
    'postâtes',
    'postcure',
    'postdata',
    'postdate',
    'postdaté',
    'posterai',
    'posteras',
    'posterez',
    'postface',
    'posthite',
    'posthume',
    'posticha',
    'postiche',
    'postiché',
    'postière',
    'postiers',
    'postions',
    'postposa',
    'postpose',
    'postposé',
    'postulai',
    'postulas',
    'postulat',
    'postulât',
    'postulée',
    'postuler',
    'postules',
    'postulés',
    'postulez',
    'postural',
    'postures',
    'potables',
    'potaches',
    'potagère',
    'potagers',
    'potamots',
    'potassai',
    'potassas',
    'potassât',
    'potassée',
    'potasser',
    'potasses',
    'potassés',
    'potassez',
    'potelées',
    'potencée',
    'potences',
    'potencés',
    'potentat',
    'poteries',
    'poternes',
    'potiches',
    'potières',
    'potinais',
    'potinait',
    'potinant',
    'potinent',
    'potinera',
    'potinier',
    'potiniez',
    'potinons',
    'potirons',
    'potlatch',
    'pouacres',
    'poubelle',
    'pouciers',
    'poudings',
    'poudrage',
    'poudrais',
    'poudrait',
    'poudrant',
    'poudrées',
    'poudrent',
    'poudrera',
    'poudreux',
    'poudrier',
    'poudriez',
    'poudroie',
    'poudrons',
    'poudroya',
    'poudroyé',
    'pouffais',
    'pouffait',
    'pouffant',
    'pouffent',
    'pouffera',
    'pouffiez',
    'pouffons',
    'pouillés',
    'pouillot',
    'poulaine',
    'poulains',
    'poularde',
    'poulbots',
    'poulette',
    'pouliche',
    'poulinai',
    'poulinas',
    'poulinât',
    'pouliner',
    'poulines',
    'poulinez',
    'pouliots',
    'poulotes',
    'poupines',
    'pouponna',
    'pouponne',
    'pouponné',
    'pourceau',
    'pourfend',
    'pourpier',
    'pourprai',
    'pourpras',
    'pourprât',
    'pourprée',
    'pourprer',
    'pourpres',
    'pourprés',
    'pourprez',
    'pourquoi',
    'pourrais',
    'pourrait',
    'pourries',
    'pourriez',
    'pourrira',
    'pourrons',
    'pourront',
    'pour-soi',
    'poursuis',
    'poursuit',
    'pourtant',
    'pourtour',
    'pourvoie',
    'pourvoir',
    'pourvois',
    'pourvoit',
    'pourvues',
    'poussage',
    'poussahs',
    'poussais',
    'poussait',
    'poussant',
    'poussées',
    'poussent',
    'poussera',
    'pousseur',
    'poussier',
    'poussiez',
    'poussifs',
    'poussine',
    'poussins',
    'poussive',
    'poussoir',
    'poussons',
    'poutsais',
    'poutsait',
    'poutsant',
    'poutsées',
    'poutsent',
    'poutsera',
    'poutsiez',
    'poutsons',
    'poutures',
    'pouvions',
    'pouvoirs',
    'prairial',
    'prairies',
    'pralinai',
    'pralinas',
    'pralinât',
    'pralinée',
    'praliner',
    'pralines',
    'pralinés',
    'pralinez',
    'pratiqua',
    'pratique',
    'pratiqué',
    'préalpin',
    'préavisa',
    'préavise',
    'préavisé',
    'prébende',
    'prébendé',
    'précaire',
    'précédai',
    'précédas',
    'précédât',
    'précédée',
    'précéder',
    'précédés',
    'précèdes',
    'précédez',
    'précepte',
    'prêchais',
    'prêchait',
    'prêchant',
    'prêchées',
    'prêchent',
    'prêchera',
    'prêcheur',
    'prêchiez',
    'prêchons',
    'précieux',
    'préciput',
    'précisai',
    'précisas',
    'précisât',
    'précisée',
    'préciser',
    'précises',
    'précisés',
    'précisez',
    'précitée',
    'précités',
    'précoces',
    'préconçu',
    'précuite',
    'précuits',
    'prédicat',
    'prédîmes',
    'prédiqua',
    'prédique',
    'prédiqué',
    'prédirai',
    'prédiras',
    'prédirez',
    'prédises',
    'prédisse',
    'prédites',
    'prédîtes',
    'préempta',
    'préempte',
    'préempté',
    'préfaçai',
    'préfaças',
    'préfaçât',
    'préfacée',
    'préfacer',
    'préfaces',
    'préfacés',
    'préfacez',
    'préférai',
    'préféras',
    'préférât',
    'préférée',
    'préférer',
    'préférés',
    'préfères',
    'préférez',
    'préfixai',
    'préfixas',
    'préfixât',
    'préfixée',
    'préfixer',
    'préfixes',
    'préfixés',
    'préfixez',
    'préforma',
    'préforme',
    'préformé',
    'prégnant',
    'préjugea',
    'préjugée',
    'préjuger',
    'préjuges',
    'préjugés',
    'préjugez',
    'prélarts',
    'prélassa',
    'prélasse',
    'prélassé',
    'prélatin',
    'prélevai',
    'prélevas',
    'prélevât',
    'prélevée',
    'prélever',
    'prélevés',
    'prélèves',
    'prélevez',
    'préludai',
    'préludas',
    'préludât',
    'préluder',
    'préludes',
    'préludez',
    'prémices',
    'première',
    'premiers',
    'prémisse',
    'prémunie',
    'prémunir',
    'prémunis',
    'prémunit',
    'prémunît',
    'prenable',
    'prenante',
    'prenants',
    'prénatal',
    'prendrai',
    'prendras',
    'prendrez',
    'preneurs',
    'prenions',
    'prennent',
    'prénomma',
    'prénomme',
    'prénommé',
    'préorale',
    'préoraux',
    'prépaies',
    'préparai',
    'préparas',
    'préparât',
    'préparée',
    'préparer',
    'prépares',
    'préparés',
    'préparez',
    'prépayai',
    'prépayas',
    'prépayât',
    'prépayée',
    'prépayer',
    'prépayes',
    'prépayés',
    'prépayez',
    'préposai',
    'préposas',
    'préposât',
    'préposée',
    'préposer',
    'préposes',
    'préposés',
    'préposez',
    'prépuces',
    'prérégla',
    'préréglé',
    'prérègle',
    'présagea',
    'présagée',
    'présager',
    'présages',
    'présagés',
    'présagez',
    'pré-salé',
    'presbyte',
    'prescris',
    'prescrit',
    'présence',
    'présenta',
    'présente',
    'présenté',
    'présents',
    'présérie',
    'préserva',
    'préserve',
    'préservé',
    'présidai',
    'présidas',
    'présidât',
    'présidée',
    'présider',
    'présides',
    'présidés',
    'présidez',
    'pressage',
    'pressais',
    'pressait',
    'pressant',
    'pressées',
    'pressens',
    'pressent',
    'pressera',
    'presseur',
    'pressier',
    'pressiez',
    'pressing',
    'pression',
    'pressoir',
    'pressons',
    'pressura',
    'pressure',
    'pressuré',
    'prestant',
    'prestige',
    'présumai',
    'présumas',
    'présumât',
    'présumée',
    'présumer',
    'présumes',
    'présumés',
    'présumez',
    'présurai',
    'présuras',
    'présurât',
    'présurée',
    'présurer',
    'présures',
    'présurés',
    'présurez',
    'prêtâmes',
    'prêtasse',
    'prêtâtes',
    'prétende',
    'prétends',
    'prétendu',
    'prêterai',
    'prêteras',
    'prêterez',
    'prétérit',
    'préteurs',
    'prêteurs',
    'préteuse',
    'prêteuse',
    'prétexta',
    'prétexte',
    'prétexté',
    'prêtions',
    'prétoire',
    'prêtrise',
    'prévales',
    'prévalez',
    'prévalue',
    'prévalus',
    'prévalut',
    'prévalût',
    'prévenez',
    'prévenir',
    'prévenue',
    'prévenus',
    'préverbe',
    'préviens',
    'prévient',
    'prévîmes',
    'prévisse',
    'prévîtes',
    'prévoies',
    'prévoira',
    'prévôtal',
    'prévôtés',
    'prévoyez',
    'priaient',
    'priantes',
    'priapées',
    'priasses',
    'prierais',
    'prierait',
    'prièrent',
    'prieriez',
    'prierons',
    'prieront',
    'prieures',
    'prieurés',
    'primages',
    'primaire',
    'primâmes',
    'primasse',
    'primates',
    'primâtes',
    'primatie',
    'primauté',
    'primerai',
    'primeras',
    'primerez',
    'primeurs',
    'primidis',
    'primions',
    'primitif',
    'princeps',
    'princier',
    'principe',
    'priorats',
    'priorité',
    'prisâmes',
    'prisasse',
    'prisâtes',
    'priserai',
    'priseras',
    'priserez',
    'priseurs',
    'priseuse',
    'prisions',
    'prissent',
    'prissiez',
    'privâmes',
    'privasse',
    'privâtes',
    'privatif',
    'privauté',
    'priverai',
    'priveras',
    'priverez',
    'privions',
    'probable',
    'probante',
    'probants',
    'probités',
    'problème',
    'procédai',
    'procédas',
    'procédât',
    'procéder',
    'procédés',
    'procèdes',
    'procédez',
    'prochain',
    'proclama',
    'proclame',
    'proclamé',
    'procréai',
    'procréas',
    'procréât',
    'procréée',
    'procréer',
    'procrées',
    'procréés',
    'procréez',
    'proctite',
    'procurai',
    'procuras',
    'procurât',
    'procurée',
    'procurer',
    'procures',
    'procurés',
    'procurez',
    'prodiges',
    'prodigua',
    'prodigue',
    'prodigué',
    'prodrome',
    'produira',
    'produire',
    'produise',
    'produite',
    'produits',
    'profanai',
    'profanas',
    'profanât',
    'profanée',
    'profaner',
    'profanes',
    'profanés',
    'profanez',
    'proférai',
    'proféras',
    'proférât',
    'proférée',
    'proférer',
    'proférés',
    'profères',
    'proférez',
    'professa',
    'professe',
    'professé',
    'profilai',
    'profilas',
    'profilât',
    'profilée',
    'profiler',
    'profiles',
    'profilés',
    'profilez',
    'profitai',
    'profitas',
    'profitât',
    'profiter',
    'profites',
    'profitez',
    'profonde',
    'profonds',
    'profuses',
    'prohibai',
    'prohibas',
    'prohibât',
    'prohibée',
    'prohiber',
    'prohibes',
    'prohibés',
    'prohibez',
    'projetai',
    'projetas',
    'projetât',
    'projetée',
    'projeter',
    'projetés',
    'projetez',
    'projette',
    'prolepse',
    'prolixes',
    'prologue',
    'prolonge',
    'prolongé',
    'promenai',
    'promenas',
    'promenât',
    'promenée',
    'promener',
    'promenés',
    'promènes',
    'promenez',
    'promesse',
    'promette',
    'promeuve',
    'promîmes',
    'promises',
    'promisse',
    'promîtes',
    'promptes',
    'promûmes',
    'promusse',
    'promûtes',
    'prônâmes',
    'prônasse',
    'prônâtes',
    'prônerai',
    'prôneras',
    'prônerez',
    'prôneurs',
    'prôneuse',
    'prônions',
    'prononça',
    'prononce',
    'prononcé',
    'propagea',
    'propagée',
    'propager',
    'propages',
    'propagés',
    'propagez',
    'propanes',
    'propènes',
    'prophase',
    'prophète',
    'propices',
    'propolis',
    'proposai',
    'proposas',
    'proposât',
    'proposée',
    'proposer',
    'proposes',
    'proposés',
    'proposez',
    'propreté',
    'proprets',
    'proprios',
    'propulsa',
    'propulse',
    'propulsé',
    'propylée',
    'prorogea',
    'prorogée',
    'proroger',
    'proroges',
    'prorogés',
    'prorogez',
    'proscris',
    'proscrit',
    'prosodia',
    'prosodie',
    'prosodié',
    'prospect',
    'prospéra',
    'prospéré',
    'prospère',
    'prostate',
    'prostrée',
    'prostrés',
    'prostyle',
    'protases',
    'protéase',
    'protégea',
    'protégée',
    'protéger',
    'protégés',
    'protèges',
    'protégez',
    'protéide',
    'protéine',
    'protèles',
    'protesta',
    'proteste',
    'protesté',
    'prothèse',
    'protides',
    'prouesse',
    'prouvais',
    'prouvait',
    'prouvant',
    'prouvées',
    'prouvent',
    'prouvera',
    'prouviez',
    'prouvons',
    'provende',
    'provenez',
    'provenir',
    'provenue',
    'provenus',
    'proverbe',
    'proviens',
    'provient',
    'provigna',
    'provigne',
    'provigné',
    'province',
    'provoqua',
    'provoque',
    'provoqué',
    'proximal',
    'prudence',
    'prudente',
    'prudents',
    'pruderie',
    'pruneaux',
    'prunelée',
    'prunelle',
    'pruniers',
    'prurigos',
    'prussien',
    'prytanée',
    'prytanes',
    'psautier',
    'pschents',
    'psychose',
    'ptomaïne',
    'ptyaline',
    'puanteur',
    'puassent',
    'puassiez',
    'pubertés',
    'pubienne',
    'publiais',
    'publiait',
    'publiant',
    'publiées',
    'publient',
    'publiera',
    'publiiez',
    'publions',
    'publique',
    'puccinia',
    'puccinie',
    'pucelage',
    'pucerons',
    'puddings',
    'puddlage',
    'puddleur',
    'pudibond',
    'pudicité',
    'pudiques',
    'puériles',
    'puerions',
    'pugilats',
    'pugnaces',
    'puisages',
    'puisâmes',
    'puisards',
    'puisasse',
    'puisâtes',
    'puiserai',
    'puiseras',
    'puiserez',
    'puisette',
    'puisions',
    'puissant',
    'puissent',
    'puissiez',
    'pullmans',
    'pullulai',
    'pullulas',
    'pullulât',
    'pulluler',
    'pullules',
    'pullulez',
    'pulpaire',
    'pulpeuse',
    'pulsâmes',
    'pulsasse',
    'pulsâtes',
    'pulserai',
    'pulseras',
    'pulserez',
    'pulsions',
    'pultacée',
    'pultacés',
    'pulvérin',
    'punaisai',
    'punaisas',
    'punaisât',
    'punaisée',
    'punaiser',
    'punaises',
    'punaisés',
    'punaisez',
    'puncheur',
    'puniques',
    'punirais',
    'punirait',
    'punirent',
    'puniriez',
    'punirons',
    'puniront',
    'punisses',
    'punissez',
    'punitifs',
    'punition',
    'punitive',
    'pupilles',
    'pupitres',
    'purement',
    'purgatif',
    'purgeais',
    'purgeait',
    'purgeant',
    'purgeoir',
    'purgeons',
    'purgerai',
    'purgeras',
    'purgerez',
    'purgeurs',
    'purgions',
    'purifiai',
    'purifias',
    'purifiât',
    'purifiée',
    'purifier',
    'purifies',
    'purifiés',
    'purifiez',
    'purismes',
    'puristes',
    'puritain',
    'purotins',
    'purpuras',
    'purpurin',
    'pur-sang',
    'purulent',
    'pussions',
    'pustules',
    'putatifs',
    'putative',
    'putréfia',
    'putréfie',
    'putréfié',
    'putrides',
    'pycnoses',
    'pyélites',
    'pygargue',
    'pyogènes',
    'pyorrhée',
    'pyramida',
    'pyramide',
    'pyramidé',
    'pyrénéen',
    'pyrèthre',
    'pyrexies',
    'pyrogène',
    'pyrolyse',
    'pyromane',
    'pyroxylé',
    'pyrroles',
    'pythiens',
    'quadrant',
    'quadrige',
    'qualifia',
    'qualifie',
    'qualifié',
    'qualités',
    'quantité',
    'quarante',
    'quarrais',
    'quarrait',
    'quarrant',
    'quarrées',
    'quarrent',
    'quarrera',
    'quarriez',
    'quarrons',
    'quartage',
    'quartagé',
    'quartais',
    'quartait',
    'quartant',
    'quartaut',
    'quartées',
    'quartent',
    'quartera',
    'quartidi',
    'quartier',
    'quartiez',
    'quartile',
    'quartons',
    'quassias',
    'quassier',
    'quassine',
    'quaterne',
    'quatorze',
    'quatrain',
    'quatuors',
    'quelques',
    'quémanda',
    'quémande',
    'quémandé',
    'quenelle',
    'quenotte',
    'quérable',
    'querella',
    'querelle',
    'querellé',
    'questeur',
    'question',
    'questure',
    'quêtâmes',
    'quêtasse',
    'quêtâtes',
    'quêterai',
    'quêteras',
    'quêterez',
    'quêteurs',
    'quêteuse',
    'quêtions',
    'quetsche',
    'queusots',
    'queutais',
    'queutait',
    'queutant',
    'queutent',
    'queutera',
    'queutiez',
    'queutons',
    'quichuas',
    'quiddité',
    'quiétude',
    'quignons',
    'quilleur',
    'quillier',
    'quillons',
    'quinaire',
    'quinaude',
    'quinauds',
    'quinines',
    'quinquet',
    'quintaux',
    'quinteux',
    'quintidi',
    'quirites',
    'quiscale',
    'quittais',
    'quittait',
    'quittant',
    'quittées',
    'quittent',
    'quittera',
    'quittiez',
    'quittons',
    'qui-vive',
    'quolibet',
    'quotient',
    'quotités',
    'quottais',
    'quottait',
    'quottant',
    'quottent',
    'quottera',
    'quottiez',
    'quottons',
    'rabâchai',
    'rabâchas',
    'rabâchât',
    'rabâchée',
    'rabâcher',
    'rabâches',
    'rabâchés',
    'rabâchez',
    'rabaissa',
    'rabaisse',
    'rabaissé',
    'rabantai',
    'rabantas',
    'rabantât',
    'rabantée',
    'rabanter',
    'rabantes',
    'rabantés',
    'rabantez',
    'rabattes',
    'rabattez',
    'rabattis',
    'rabattit',
    'rabattît',
    'rabattra',
    'rabattre',
    'rabattue',
    'rabattus',
    'rabbinat',
    'rabiotai',
    'rabiotas',
    'rabiotât',
    'rabiotée',
    'rabioter',
    'rabiotes',
    'rabiotés',
    'rabiotez',
    'rabiques',
    'râblâmes',
    'râblasse',
    'râblâtes',
    'râblerai',
    'râbleras',
    'râblerez',
    'râblions',
    'râblures',
    'rabonnie',
    'rabonnir',
    'rabonnis',
    'rabonnit',
    'rabonnît',
    'rabotage',
    'rabotais',
    'rabotait',
    'rabotant',
    'rabotées',
    'rabotent',
    'rabotera',
    'raboteur',
    'raboteux',
    'rabotiez',
    'rabotons',
    'rabougri',
    'raboutai',
    'raboutas',
    'raboutât',
    'raboutée',
    'rabouter',
    'raboutes',
    'raboutés',
    'raboutez',
    'rabrouai',
    'rabrouas',
    'rabrouât',
    'rabrouée',
    'rabrouer',
    'rabroues',
    'rabroués',
    'rabrouez',
    'racaille',
    'raccorda',
    'raccorde',
    'raccordé',
    'raccords',
    'raccrocs',
    'rachetai',
    'rachetas',
    'rachetât',
    'rachetée',
    'racheter',
    'rachetés',
    'rachètes',
    'rachetez',
    'raciales',
    'racinage',
    'racinais',
    'racinait',
    'racinant',
    'racinaux',
    'racinées',
    'racinent',
    'racinera',
    'racinien',
    'raciniez',
    'racinons',
    'racismes',
    'racistes',
    'raclages',
    'raclâmes',
    'raclasse',
    'raclâtes',
    'raclerai',
    'racleras',
    'raclerez',
    'raclette',
    'racleurs',
    'racleuse',
    'raclions',
    'racloirs',
    'raclures',
    'racolage',
    'racolais',
    'racolait',
    'racolant',
    'racolées',
    'racolent',
    'racolera',
    'racoleur',
    'racoliez',
    'racolons',
    'racontai',
    'racontar',
    'racontas',
    'racontât',
    'racontée',
    'raconter',
    'racontes',
    'racontés',
    'racontez',
    'racornie',
    'racornir',
    'racornis',
    'racornit',
    'racornît',
    'radaient',
    'radasses',
    'raderais',
    'raderait',
    'radèrent',
    'raderiez',
    'raderons',
    'raderont',
    'radeuses',
    'radiaire',
    'radiales',
    'radiâmes',
    'radiance',
    'radiasse',
    'radiâtes',
    'radiatif',
    'radicale',
    'radicant',
    'radicaux',
    'radierai',
    'radieras',
    'radierez',
    'radieuse',
    'radiions',
    'radinais',
    'radinait',
    'radinant',
    'radinées',
    'radinent',
    'radinera',
    'radiniez',
    'radinons',
    'radotage',
    'radotais',
    'radotait',
    'radotant',
    'radotent',
    'radotera',
    'radoteur',
    'radotiez',
    'radotons',
    'radoubai',
    'radoubas',
    'radoubât',
    'radoubée',
    'radouber',
    'radoubes',
    'radoubés',
    'radoubez',
    'radoucie',
    'radoucir',
    'radoucis',
    'radoucit',
    'radoucît',
    'raffermi',
    'raffinai',
    'raffinas',
    'raffinât',
    'raffinée',
    'raffiner',
    'raffines',
    'raffinés',
    'raffinez',
    'raffolai',
    'raffolas',
    'raffolât',
    'raffolée',
    'raffoler',
    'raffoles',
    'raffolés',
    'raffolez',
    'raffûtai',
    'raffûtas',
    'raffûtât',
    'raffûtée',
    'raffûter',
    'raffûtes',
    'raffûtés',
    'raffûtez',
    'raflâmes',
    'raflasse',
    'raflâtes',
    'raflerai',
    'rafleras',
    'raflerez',
    'raflions',
    'rageâmes',
    'rageante',
    'rageants',
    'rageasse',
    'rageâtes',
    'ragerais',
    'ragerait',
    'ragèrent',
    'rageriez',
    'ragerons',
    'rageront',
    'rageuses',
    'ragondin',
    'ragotais',
    'ragotait',
    'ragotant',
    'ragotent',
    'ragotera',
    'ragotiez',
    'ragotons',
    'ragoûtai',
    'ragoûtas',
    'ragoûtât',
    'ragoûtée',
    'ragoûter',
    'ragoûtes',
    'ragoûtés',
    'ragoûtez',
    'ragrafai',
    'ragrafas',
    'ragrafât',
    'ragrafée',
    'ragrafer',
    'ragrafes',
    'ragrafés',
    'ragrafez',
    'ragréais',
    'ragréait',
    'ragréant',
    'ragréées',
    'ragréent',
    'ragréera',
    'ragréiez',
    'ragréons',
    'rag-time',
    'raguâmes',
    'raguasse',
    'raguâtes',
    'raguerai',
    'ragueras',
    'raguerez',
    'raguions',
    'raideurs',
    'raidîmes',
    'raidirai',
    'raidiras',
    'raidirât',
    'raidisse',
    'raidîtes',
    'raierais',
    'raierait',
    'raieriez',
    'raierons',
    'raieront',
    'raiforts',
    'raillais',
    'raillait',
    'raillant',
    'raillées',
    'raillent',
    'raillera',
    'railleur',
    'railliez',
    'raillons',
    'rainâmes',
    'rainasse',
    'rainâtes',
    'rainerai',
    'raineras',
    'rainerez',
    'rainetai',
    'rainetas',
    'rainetât',
    'rainetée',
    'raineter',
    'rainetés',
    'rainetez',
    'rainette',
    'rainions',
    'rainurai',
    'rainuras',
    'rainurât',
    'rainurée',
    'rainurer',
    'rainures',
    'rainurés',
    'rainurez',
    'raisinés',
    'raisonna',
    'raisonne',
    'raisonné',
    'rajeunie',
    'rajeunir',
    'rajeunis',
    'rajeunit',
    'rajeunît',
    'rajoutai',
    'rajoutas',
    'rajoutât',
    'rajoutée',
    'rajouter',
    'rajoutes',
    'rajoutés',
    'rajoutez',
    'rajustai',
    'rajustas',
    'rajustât',
    'rajustée',
    'rajuster',
    'rajustes',
    'rajustés',
    'rajustez',
    'râlaient',
    'râlantes',
    'râlasses',
    'râlement',
    'ralentie',
    'ralentir',
    'ralentis',
    'ralentit',
    'ralentît',
    'râlerais',
    'râlerait',
    'râlèrent',
    'râleriez',
    'râlerons',
    'râleront',
    'râleuses',
    'ralingua',
    'ralingue',
    'ralingué',
    'rallégea',
    'ralléger',
    'rallèges',
    'rallégez',
    'ralliais',
    'ralliait',
    'ralliant',
    'ralliées',
    'rallient',
    'ralliera',
    'ralliiez',
    'rallions',
    'rallonge',
    'rallongé',
    'rallumai',
    'rallumas',
    'rallumât',
    'rallumée',
    'rallumer',
    'rallumes',
    'rallumés',
    'rallumez',
    'ramadans',
    'ramageai',
    'ramageas',
    'ramageât',
    'ramagées',
    'ramagent',
    'ramagera',
    'ramagiez',
    'ramaient',
    'ramailla',
    'ramaille',
    'ramaillé',
    'ramandai',
    'ramandas',
    'ramandât',
    'ramandée',
    'ramander',
    'ramandes',
    'ramandés',
    'ramandez',
    'ramarrai',
    'ramarras',
    'ramarrât',
    'ramarrée',
    'ramarrer',
    'ramarres',
    'ramarrés',
    'ramarrez',
    'ramassai',
    'ramassas',
    'ramassât',
    'ramassée',
    'ramasser',
    'ramasses',
    'ramassés',
    'ramassez',
    'ramassis',
    'rambarde',
    'rambours',
    'ramenais',
    'ramenait',
    'ramenant',
    'ramendai',
    'ramendas',
    'ramendât',
    'ramendée',
    'ramender',
    'ramendes',
    'ramendés',
    'ramendez',
    'ramenées',
    'ramènent',
    'ramènera',
    'rameniez',
    'ramenons',
    'ramequin',
    'ramerais',
    'ramerait',
    'ramereau',
    'ramèrent',
    'rameriez',
    'ramerons',
    'rameront',
    'ramerots',
    'ramettes',
    'rameuses',
    'rameutai',
    'rameutas',
    'rameutât',
    'rameutée',
    'rameuter',
    'rameutes',
    'rameutés',
    'rameutez',
    'ramifiai',
    'ramifias',
    'ramifiât',
    'ramifiée',
    'ramifier',
    'ramifies',
    'ramifiés',
    'ramifiez',
    'ramilles',
    'ramingue',
    'ramollie',
    'ramollir',
    'ramollis',
    'ramollit',
    'ramollît',
    'ramollos',
    'ramonage',
    'ramonais',
    'ramonait',
    'ramonant',
    'ramonées',
    'ramonent',
    'ramonera',
    'ramoneur',
    'ramoniez',
    'ramonons',
    'rampâmes',
    'rampante',
    'rampants',
    'rampasse',
    'rampâtes',
    'rampeaux',
    'ramperai',
    'ramperas',
    'ramperez',
    'rampions',
    'ranatres',
    'rancarda',
    'rancarde',
    'rancardé',
    'rancards',
    'rancarts',
    'ranchers',
    'rancîmes',
    'rancirai',
    'ranciras',
    'rancirez',
    'rancisse',
    'rancîtes',
    'rancoeur',
    'rançonna',
    'rançonne',
    'rançonné',
    'rancunes',
    'randonna',
    'randonne',
    'randonné',
    'rangeais',
    'rangeait',
    'rangeant',
    'rangeons',
    'rangerai',
    'rangeras',
    'rangerez',
    'rangions',
    'ranimais',
    'ranimait',
    'ranimant',
    'ranimées',
    'raniment',
    'ranimera',
    'ranimiez',
    'ranimons',
    'rapacité',
    'râpaient',
    'râpasses',
    'rapatria',
    'rapatrie',
    'rapatrié',
    'râperais',
    'râperait',
    'râpèrent',
    'râperies',
    'râperiez',
    'râperons',
    'râperont',
    'râpeuses',
    'rapiates',
    'rapidité',
    'rapiéçai',
    'rapiéças',
    'rapiéçât',
    'rapiécée',
    'rapiécer',
    'rapiécés',
    'rapièces',
    'rapiécez',
    'rapières',
    'rapinais',
    'rapinait',
    'rapinant',
    'rapinées',
    'rapinent',
    'rapinera',
    'rapiniez',
    'rapinons',
    'raplatie',
    'raplatir',
    'raplatis',
    'raplatit',
    'raplatît',
    'rapointi',
    'rapparia',
    'rapparie',
    'rapparié',
    'rappelai',
    'rappelas',
    'rappelât',
    'rappelée',
    'rappeler',
    'rappelés',
    'rappelez',
    'rappelle',
    'rapporta',
    'rapporte',
    'rapporté',
    'rapports',
    'rapprend',
    'rapprêta',
    'rapprête',
    'rapprêté',
    'rapprise',
    'rapsodes',
    'rapsodie',
    'raquâmes',
    'raquasse',
    'raquâtes',
    'raquerai',
    'raqueras',
    'raquerez',
    'raquette',
    'raquions',
    'raréfiai',
    'raréfias',
    'raréfiât',
    'raréfiée',
    'raréfier',
    'raréfies',
    'raréfiés',
    'raréfiez',
    'rarement',
    'rasaient',
    'rasances',
    'rasantes',
    'rasasses',
    'rascasse',
    'raserais',
    'raserait',
    'rasèrent',
    'raseriez',
    'raserons',
    'raseront',
    'rasettes',
    'raseuses',
    'rassasia',
    'rassasie',
    'rassasié',
    'rasseoir',
    'rasseyes',
    'rasseyez',
    'rassieds',
    'rassiéra',
    'rassîmes',
    'rassises',
    'rassisse',
    'rassîtes',
    'rassoies',
    'rassorti',
    'rassoyez',
    'rassurai',
    'rassuras',
    'rassurât',
    'rassurée',
    'rassurer',
    'rassures',
    'rassurés',
    'rassurez',
    'ratafias',
    'rataient',
    'rataplan',
    'ratasses',
    'ratatina',
    'ratatine',
    'ratatiné',
    'râtelage',
    'râtelais',
    'râtelait',
    'râtelant',
    'râtelées',
    'râteleur',
    'râtelier',
    'râteliez',
    'râtelles',
    'râtelons',
    'raterais',
    'raterait',
    'ratèrent',
    'rateriez',
    'raterons',
    'rateront',
    'ratichon',
    'ratières',
    'ratifiée',
    'ratifier',
    'ratifies',
    'ratifiés',
    'ratifiez',
    'ratinage',
    'ratinais',
    'ratinait',
    'ratinant',
    'ratinées',
    'ratinent',
    'ratinera',
    'ratiniez',
    'ratinons',
    'rational',
    'rationna',
    'rationne',
    'rationné',
    'ratissai',
    'ratissas',
    'ratissât',
    'ratissée',
    'ratisser',
    'ratisses',
    'ratissés',
    'ratissez',
    'rattacha',
    'rattache',
    'rattaché',
    'rattrapa',
    'rattrape',
    'rattrapé',
    'raturage',
    'raturais',
    'raturait',
    'raturant',
    'raturées',
    'raturent',
    'raturera',
    'raturiez',
    'raturons',
    'raucheur',
    'raucités',
    'ravageai',
    'ravageas',
    'ravageât',
    'ravagées',
    'ravagent',
    'ravagera',
    'ravageur',
    'ravagiez',
    'ravalais',
    'ravalait',
    'ravalant',
    'ravalées',
    'ravalent',
    'ravalera',
    'ravaleur',
    'ravaliez',
    'ravalons',
    'ravaudai',
    'ravaudas',
    'ravaudât',
    'ravaudée',
    'ravauder',
    'ravaudes',
    'ravaudés',
    'ravaudez',
    'ravelins',
    'ravenala',
    'ravières',
    'ravigota',
    'ravigote',
    'ravigoté',
    'ravilies',
    'ravilira',
    'ravinais',
    'ravinait',
    'ravinant',
    'ravinées',
    'ravinent',
    'ravinera',
    'raviniez',
    'ravinons',
    'raviolis',
    'ravirais',
    'ravirait',
    'ravirent',
    'raviriez',
    'ravirons',
    'raviront',
    'ravisais',
    'ravisait',
    'ravisant',
    'ravisées',
    'ravisent',
    'ravisera',
    'ravisiez',
    'ravisons',
    'ravisses',
    'ravissez',
    'ravivage',
    'ravivais',
    'ravivait',
    'ravivant',
    'ravivées',
    'ravivent',
    'ravivera',
    'raviviez',
    'ravivons',
    'rayaient',
    'rayasses',
    'rayerais',
    'rayerait',
    'rayèrent',
    'rayeriez',
    'rayerons',
    'rayeront',
    'rayonnai',
    'rayonnas',
    'rayonnât',
    'rayonnée',
    'rayonner',
    'rayonnes',
    'rayonnés',
    'rayonnez',
    'razziais',
    'razziait',
    'razziant',
    'razziées',
    'razzient',
    'razziera',
    'razziiez',
    'razzions',
    'réabonna',
    'réabonne',
    'réabonné',
    'réacteur',
    'réactifs',
    'réaction',
    'réactiva',
    'réactive',
    'réactivé',
    'réadapta',
    'réadapte',
    'réadapté',
    'réadmets',
    'réadmise',
    'réaffûta',
    'réaffûte',
    'réaffûté',
    'réagîmes',
    'réagirai',
    'réagiras',
    'réagirez',
    'réagisse',
    'réagîtes',
    'réajusta',
    'réajuste',
    'réajusté',
    'réalésai',
    'réalésas',
    'réalésât',
    'réalésée',
    'réaléser',
    'réalésés',
    'réalèses',
    'réalésez',
    'réalgars',
    'réaligna',
    'réaligne',
    'réaligné',
    'réalisai',
    'réalisas',
    'réalisât',
    'réalisée',
    'réaliser',
    'réalises',
    'réalisés',
    'réalisez',
    'réalisme',
    'réaliste',
    'réalités',
    'réamorça',
    'réamorce',
    'réamorcé',
    'réanimai',
    'réanimas',
    'réanimât',
    'réanimée',
    'réanimer',
    'réanimes',
    'réanimés',
    'réanimez',
    'réapparu',
    'réappris',
    'réapprit',
    'réapprît',
    'réarmais',
    'réarmait',
    'réarmant',
    'réarmées',
    'réarment',
    'réarmera',
    'réarmiez',
    'réarmons',
    'réassent',
    'réassiez',
    'réassura',
    'réassure',
    'réassuré',
    'rebaissa',
    'rebaisse',
    'rebaissé',
    'rebandai',
    'rebandas',
    'rebandât',
    'rebandée',
    'rebander',
    'rebandes',
    'rebandés',
    'rebandez',
    'rebâties',
    'rebâtira',
    'rebattes',
    'rebattez',
    'rebattis',
    'rebattit',
    'rebattît',
    'rebattra',
    'rebattre',
    'rebattue',
    'rebattus',
    'rebellai',
    'rebellas',
    'rebellât',
    'rebellée',
    'rebeller',
    'rebelles',
    'rebellés',
    'rebellez',
    'rebiffai',
    'rebiffas',
    'rebiffât',
    'rebiffée',
    'rebiffer',
    'rebiffes',
    'rebiffés',
    'rebiffez',
    'rebiquai',
    'rebiquas',
    'rebiquât',
    'rebiquée',
    'rebiquer',
    'rebiques',
    'rebiqués',
    'rebiquez',
    'reboisai',
    'reboisas',
    'reboisât',
    'reboisée',
    'reboiser',
    'reboises',
    'reboisés',
    'reboisez',
    'rebondir',
    'rebondis',
    'rebondit',
    'rebondît',
    'rebordai',
    'rebordas',
    'rebordât',
    'rebordée',
    'reborder',
    'rebordes',
    'rebordés',
    'rebordez',
    'reboucha',
    'rebouche',
    'rebouché',
    'reboutai',
    'reboutas',
    'reboutât',
    'reboutée',
    'rebouter',
    'reboutes',
    'reboutés',
    'reboutez',
    'rebrodai',
    'rebrodas',
    'rebrodât',
    'rebrodée',
    'rebroder',
    'rebrodes',
    'rebrodés',
    'rebrodez',
    'rebrûlai',
    'rebrûlas',
    'rebrûlât',
    'rebrûlée',
    'rebrûler',
    'rebrûles',
    'rebrûlés',
    'rebrûlez',
    'rebutais',
    'rebutait',
    'rebutant',
    'rebutées',
    'rebutent',
    'rebutera',
    'rebutiez',
    'rebutons',
    'recalais',
    'recalait',
    'recalant',
    'recalées',
    'recalent',
    'recalera',
    'recaliez',
    'recalons',
    'recardai',
    'recardas',
    'recardât',
    'recardée',
    'recarder',
    'recardes',
    'recardés',
    'recardez',
    'recasais',
    'recasait',
    'recasant',
    'recasées',
    'recasent',
    'recasera',
    'recasiez',
    'recasons',
    'recausai',
    'recausas',
    'recausât',
    'recauser',
    'recauses',
    'recausez',
    'recédais',
    'recédait',
    'recédant',
    'recédées',
    'recèdent',
    'recédera',
    'recédiez',
    'recédons',
    'recelais',
    'recélais',
    'recelait',
    'recélait',
    'recelant',
    'recélant',
    'recelées',
    'recélées',
    'recèlent',
    'recélera',
    'recèlera',
    'receleur',
    'receliez',
    'recéliez',
    'recelons',
    'recélons',
    'recensai',
    'recensas',
    'recensât',
    'recensée',
    'recenser',
    'recenses',
    'recensés',
    'recensez',
    'récentes',
    'recentra',
    'recentre',
    'recentré',
    'recépage',
    'recepais',
    'recépais',
    'recepait',
    'recépait',
    'recepant',
    'recépant',
    'recepées',
    'recépées',
    'recèpent',
    'recépera',
    'recèpera',
    'recepiez',
    'recépiez',
    'recepons',
    'recépons',
    'réceptif',
    'recercla',
    'recercle',
    'recerclé',
    'récessif',
    'recettes',
    'recevais',
    'recevait',
    'recevant',
    'receveur',
    'receviez',
    'recevoir',
    'recevons',
    'recevrai',
    'recevras',
    'recevrez',
    'rechampi',
    'réchampi',
    'rechange',
    'rechangé',
    'rechanta',
    'rechante',
    'rechanté',
    'rechapai',
    'rechapas',
    'rechapât',
    'rechapée',
    'rechaper',
    'rechapes',
    'rechapés',
    'rechapez',
    'réchappa',
    'réchappe',
    'réchappé',
    'recharge',
    'rechargé',
    'rechassa',
    'rechasse',
    'rechassé',
    'réchauds',
    'rechigna',
    'rechigne',
    'rechigné',
    'rechutai',
    'rechutas',
    'rechutât',
    'rechuter',
    'rechutes',
    'rechutez',
    'récidiva',
    'récidive',
    'récidivé',
    'récifale',
    'récifaux',
    'récitais',
    'récitait',
    'récitals',
    'récitant',
    'récitées',
    'récitent',
    'récitera',
    'récitiez',
    'récitons',
    'réclamai',
    'réclamas',
    'réclamât',
    'réclamée',
    'réclamer',
    'réclames',
    'réclamés',
    'réclamez',
    'reclassa',
    'reclasse',
    'reclassé',
    'réclinai',
    'réclinas',
    'réclinât',
    'récliner',
    'réclines',
    'réclinez',
    'reclouai',
    'reclouas',
    'reclouât',
    'reclouée',
    'reclouer',
    'recloues',
    'recloués',
    'reclouez',
    'recluses',
    'recoiffa',
    'recoiffe',
    'recoiffé',
    'reçoives',
    'recolais',
    'recolait',
    'recolant',
    'recolées',
    'recolent',
    'recolera',
    'recoliez',
    'recollai',
    'recollas',
    'recollât',
    'recollée',
    'recoller',
    'recolles',
    'recollés',
    'récollet',
    'recollez',
    'recolons',
    'recolora',
    'recolore',
    'recoloré',
    'récoltai',
    'récoltas',
    'récoltât',
    'récoltée',
    'récolter',
    'récoltes',
    'récoltés',
    'récoltez',
    'recompta',
    'recompte',
    'recompté',
    'reconnue',
    'reconnus',
    'reconnut',
    'reconnût',
    'recopiai',
    'recopias',
    'recopiât',
    'recopiée',
    'recopier',
    'recopies',
    'recopiés',
    'recopiez',
    'recordai',
    'recordas',
    'recordât',
    'recordée',
    'recorder',
    'recordes',
    'recordés',
    'recordez',
    'recoucha',
    'recouche',
    'recouché',
    'recoudra',
    'recoudre',
    'recoupai',
    'recoupas',
    'recoupât',
    'recoupée',
    'recouper',
    'recoupes',
    'recoupés',
    'recoupez',
    'recourba',
    'recourbe',
    'recourbé',
    'recoures',
    'recourez',
    'recourir',
    'recourra',
    'recourue',
    'recourus',
    'recourut',
    'recourût',
    'recouses',
    'recousez',
    'recousis',
    'recousit',
    'recousît',
    'recousue',
    'recousus',
    'recouvra',
    'recouvre',
    'recouvré',
    'recouvru',
    'recracha',
    'recrache',
    'recraché',
    'recréais',
    'récréais',
    'recréait',
    'récréait',
    'recréant',
    'récréant',
    'recréées',
    'récréées',
    'recréent',
    'récréent',
    'recréera',
    'récréera',
    'recréiez',
    'récréiez',
    'recréons',
    'récréons',
    'recrépie',
    'recrépir',
    'recrépis',
    'recrépit',
    'recrépît',
    'recreusa',
    'recreuse',
    'recreusé',
    'récriais',
    'récriait',
    'récriant',
    'récriées',
    'récrient',
    'récriera',
    'récriiez',
    'récrions',
    'récrirai',
    'récriras',
    'récrirez',
    'récrites',
    'récrives',
    'récrivez',
    'récrivis',
    'récrivit',
    'récrivît',
    'recroise',
    'recroisé',
    'recrûmes',
    'recrusse',
    'recrutai',
    'recrutas',
    'recrutât',
    'recrutée',
    'recruter',
    'recrutes',
    'recrutés',
    'recrûtes',
    'recrutez',
    'rectales',
    'recteurs',
    'rectifia',
    'rectifie',
    'rectifié',
    'rections',
    'rectites',
    'rectoral',
    'rectorat',
    'rectrice',
    'recueils',
    'recuirai',
    'recuiras',
    'recuirez',
    'recuises',
    'recuisez',
    'recuisis',
    'recuisit',
    'recuisît',
    'recuites',
    'reculade',
    'reculais',
    'reculait',
    'reculant',
    'reculées',
    'reculent',
    'reculera',
    'reculiez',
    'reculons',
    'récupéra',
    'récupéré',
    'récupère',
    'récurage',
    'récurais',
    'récurait',
    'récurant',
    'récurées',
    'reçurent',
    'récurent',
    'récurera',
    'récuriez',
    'récurons',
    'récursif',
    'récusais',
    'récusait',
    'récusant',
    'récusées',
    'récusent',
    'récusera',
    'récusiez',
    'récusons',
    'reçusses',
    'recyclai',
    'recyclas',
    'recyclât',
    'recyclée',
    'recycler',
    'recycles',
    'recyclés',
    'recyclez',
    'redéfais',
    'redéfait',
    'redéfera',
    'redéfini',
    'redéfont',
    'redémoli',
    'redentée',
    'redentés',
    'redevais',
    'redevait',
    'redevant',
    'redevenu',
    'redeviez',
    'redevins',
    'redevint',
    'redevînt',
    'redevoir',
    'redevons',
    'redevrai',
    'redevras',
    'redevrez',
    'rédigeai',
    'rédigeas',
    'rédigeât',
    'rédigées',
    'rédigent',
    'rédigera',
    'rédigiez',
    'rédimais',
    'rédimait',
    'rédimant',
    'rédimées',
    'rédiment',
    'rédimera',
    'rédimiez',
    'rédimons',
    'redirais',
    'redirait',
    'redirent',
    'rediriez',
    'redirons',
    'rediront',
    'redisais',
    'redisait',
    'redisant',
    'redisent',
    'redisiez',
    'redisons',
    'redisses',
    'redoives',
    'redondai',
    'redondas',
    'redondât',
    'redonder',
    'redondes',
    'redondez',
    'redonnai',
    'redonnas',
    'redonnât',
    'redonnée',
    'redonner',
    'redonnes',
    'redonnés',
    'redonnez',
    'redorais',
    'redorait',
    'redorant',
    'redorées',
    'redorent',
    'redorera',
    'redoriez',
    'redorons',
    'redoubla',
    'redouble',
    'redoublé',
    'redoutai',
    'redoutas',
    'redoutât',
    'redoutée',
    'redouter',
    'redoutes',
    'redoutés',
    'redoutez',
    'redressa',
    'redresse',
    'redressé',
    'réduirai',
    'réduiras',
    'réduirez',
    'réduises',
    'réduisez',
    'réduisis',
    'réduisit',
    'réduisît',
    'réduites',
    'redurent',
    'redusses',
    'réécouta',
    'réécoute',
    'réécouté',
    'réédifia',
    'réédifie',
    'réédifié',
    'rééditai',
    'rééditas',
    'rééditât',
    'rééditée',
    'rééditer',
    'réédites',
    'réédités',
    'rééditez',
    'rééduqua',
    'rééduque',
    'rééduqué',
    'réélirai',
    'rééliras',
    'réélirez',
    'réélises',
    'réélisez',
    'réélûmes',
    'réélusse',
    'réélûtes',
    'réemploi',
    'réengage',
    'réengagé',
    'réerions',
    'réessaie',
    'réessaya',
    'réessaye',
    'réessayé',
    'réévalua',
    'réévalue',
    'réévalué',
    'réexamen',
    'refaites',
    'refasses',
    'refendes',
    'refendez',
    'refendis',
    'refendit',
    'refendît',
    'refendra',
    'refendre',
    'refendue',
    'refendus',
    'referais',
    'référais',
    'referait',
    'référait',
    'référant',
    'référées',
    'référent',
    'réfèrent',
    'référera',
    'referiez',
    'référiez',
    'refermai',
    'refermas',
    'refermât',
    'refermée',
    'refermer',
    'refermes',
    'refermés',
    'refermez',
    'referons',
    'référons',
    'referont',
    'refilais',
    'refilait',
    'refilant',
    'refilées',
    'refilent',
    'refilera',
    'refiliez',
    'refilons',
    'refirent',
    'refisses',
    'réfléchi',
    'reflétai',
    'reflétas',
    'reflétât',
    'reflétée',
    'refléter',
    'reflétés',
    'reflètes',
    'reflétez',
    'refleuri',
    'réflexes',
    'réflexif',
    'refluais',
    'refluait',
    'refluant',
    'refluent',
    'refluera',
    'refluiez',
    'refluons',
    'refondes',
    'refondez',
    'refondis',
    'refondit',
    'refondît',
    'refondra',
    'refondre',
    'refondue',
    'refondus',
    'refontes',
    'reforgea',
    'reforgée',
    'reforger',
    'reforges',
    'reforgés',
    'reforgez',
    'reformai',
    'réformai',
    'reformas',
    'réformas',
    'reformât',
    'réformât',
    'reformée',
    'réformée',
    'reformer',
    'réformer',
    'reformes',
    'reformés',
    'réformes',
    'réformés',
    'réformez',
    'refoulai',
    'refoulas',
    'refoulât',
    'refoulée',
    'refouler',
    'refoules',
    'refoulés',
    'refoulez',
    'refourra',
    'refourre',
    'refourré',
    'réfracta',
    'réfracte',
    'réfracté',
    'refrains',
    'refrénai',
    'réfrénai',
    'refrénas',
    'réfrénas',
    'refrénât',
    'réfrénât',
    'refrénée',
    'réfrénée',
    'refréner',
    'réfréner',
    'refrénés',
    'refrènes',
    'réfrénés',
    'réfrènes',
    'refrénez',
    'réfrénez',
    'refroidi',
    'réfugiai',
    'réfugias',
    'réfugiât',
    'réfugiée',
    'réfugier',
    'réfugies',
    'réfugiés',
    'réfugiez',
    'refusais',
    'refusait',
    'refusant',
    'refusées',
    'refusent',
    'refusera',
    'refusiez',
    'refusons',
    'réfutais',
    'réfutait',
    'réfutant',
    'réfutées',
    'réfutent',
    'réfutera',
    'réfutiez',
    'réfutons',
    'refuznik',
    'regagnai',
    'regagnas',
    'regagnât',
    'regagnée',
    'regagner',
    'regagnes',
    'regagnés',
    'regagnez',
    'régalade',
    'régalage',
    'régalais',
    'régalait',
    'régalant',
    'régalées',
    'régalent',
    'régalera',
    'régalien',
    'régaliez',
    'régalons',
    'regardai',
    'regardas',
    'regardât',
    'regardée',
    'regarder',
    'regardes',
    'regardés',
    'regardez',
    'regarnie',
    'regarnir',
    'regarnis',
    'regarnit',
    'regarnît',
    'régatais',
    'régatait',
    'régatant',
    'régatent',
    'régatera',
    'régatier',
    'régatiez',
    'régatons',
    'régences',
    'régénéra',
    'régénéré',
    'régénère',
    'régentai',
    'régentas',
    'régentât',
    'régentée',
    'régenter',
    'régentes',
    'régentés',
    'régentez',
    'régicide',
    'regimbai',
    'regimbas',
    'regimbât',
    'regimbée',
    'regimber',
    'regimbes',
    'regimbés',
    'regimbez',
    'régiment',
    'régional',
    'régirais',
    'régirait',
    'régirent',
    'régiriez',
    'régirons',
    'régiront',
    'régisses',
    'régissez',
    'registra',
    'registre',
    'registré',
    'réglable',
    'réglages',
    'réglâmes',
    'réglasse',
    'réglâtes',
    'réglerai',
    'régleras',
    'réglerez',
    'réglette',
    'régleurs',
    'régleuse',
    'réglions',
    'réglisse',
    'régloirs',
    'réglures',
    'régnâmes',
    'régnante',
    'régnants',
    'régnasse',
    'régnâtes',
    'régnerai',
    'régneras',
    'régnerez',
    'régnions',
    'regonfla',
    'regonfle',
    'regonflé',
    'regorgea',
    'regorger',
    'regorges',
    'regorgez',
    'regratta',
    'regratte',
    'regratté',
    'regréais',
    'regréait',
    'regréant',
    'regréées',
    'regréent',
    'regréera',
    'regreffa',
    'regreffe',
    'regreffé',
    'regréiez',
    'regréons',
    'régressa',
    'régresse',
    'régressé',
    'regretta',
    'regrette',
    'regretté',
    'regrimpa',
    'regrimpe',
    'regrimpé',
    'regrossi',
    'regroupa',
    'regroupe',
    'regroupé',
    'régulier',
    'rehaussa',
    'rehausse',
    'rehaussé',
    'réifiais',
    'réifiait',
    'réifiant',
    'réifiées',
    'réifient',
    'réifiera',
    'réifiiez',
    'réifions',
    'réimposa',
    'réimpose',
    'réimposé',
    'reinette',
    'réinséra',
    'réinséré',
    'réinsère',
    'réinvita',
    'réinvite',
    'réinvité',
    'réitérai',
    'réitéras',
    'réitérât',
    'réitérée',
    'réitérer',
    'réitérés',
    'réitères',
    'réitérez',
    'rejailli',
    'rejetais',
    'rejetait',
    'rejetant',
    'rejetées',
    'rejetiez',
    'rejetons',
    'rejettes',
    'rejoigne',
    'rejointe',
    'rejoints',
    'rejouais',
    'rejouait',
    'rejouant',
    'rejouées',
    'rejouent',
    'rejouera',
    'réjouies',
    'rejouiez',
    'réjouira',
    'rejouons',
    'rejugeai',
    'rejugeas',
    'rejugeât',
    'rejugées',
    'rejugent',
    'rejugera',
    'rejugiez',
    'relâchai',
    'relâchas',
    'relâchât',
    'relâchée',
    'relâcher',
    'relâches',
    'relâchés',
    'relâchez',
    'relaient',
    'relaiera',
    'relaissa',
    'relaisse',
    'relaissé',
    'relançai',
    'relanças',
    'relançât',
    'relancée',
    'relancer',
    'relances',
    'relancés',
    'relancez',
    'relapses',
    'rélargie',
    'rélargir',
    'rélargis',
    'rélargit',
    'rélargît',
    'relatais',
    'relatait',
    'relatant',
    'relatées',
    'relatent',
    'relatera',
    'relatiez',
    'relatifs',
    'relation',
    'relative',
    'relatons',
    'relavais',
    'relavait',
    'relavant',
    'relavées',
    'relavent',
    'relavera',
    'relaviez',
    'relavons',
    'relaxais',
    'relaxait',
    'relaxant',
    'relaxées',
    'relaxent',
    'relaxera',
    'relaxiez',
    'relaxons',
    'relayais',
    'relayait',
    'relayant',
    'relayées',
    'relayent',
    'relayera',
    'relayeur',
    'relayiez',
    'relayons',
    'reléguai',
    'reléguas',
    'reléguât',
    'reléguée',
    'reléguer',
    'relégués',
    'relègues',
    'reléguez',
    'relevais',
    'relevait',
    'relevant',
    'relevées',
    'relèvent',
    'relèvera',
    'releveur',
    'releviez',
    'relevons',
    'reliâmes',
    'reliasse',
    'reliâtes',
    'relierai',
    'relieras',
    'relierez',
    'relieurs',
    'relieuse',
    'religion',
    'reliions',
    'reliquat',
    'reliques',
    'relirais',
    'relirait',
    'reliriez',
    'relirons',
    'reliront',
    'relisais',
    'relisait',
    'relisant',
    'relisent',
    'relisiez',
    'relisons',
    'reliures',
    'relogeai',
    'relogeas',
    'relogeât',
    'relogées',
    'relogent',
    'relogera',
    'relogiez',
    'relouais',
    'relouait',
    'relouant',
    'relouées',
    'relouent',
    'relouera',
    'relouiez',
    'relouons',
    'reluirai',
    'reluiras',
    'reluirez',
    'reluises',
    'reluisez',
    'reluisis',
    'reluisit',
    'reluisît',
    'reluites',
    'reluquai',
    'reluquas',
    'reluquât',
    'reluquée',
    'reluquer',
    'reluques',
    'reluqués',
    'reluquez',
    'relurent',
    'relusses',
    'remâchai',
    'remâchas',
    'remâchât',
    'remâchée',
    'remâcher',
    'remâches',
    'remâchés',
    'remâchez',
    'remailla',
    'remaille',
    'remaillé',
    'rémanent',
    'remangea',
    'remangée',
    'remanger',
    'remanges',
    'remangés',
    'remangez',
    'remaniai',
    'remanias',
    'remaniât',
    'remaniée',
    'remanier',
    'remanies',
    'remaniés',
    'remaniez',
    'remarcha',
    'remarche',
    'remarché',
    'remariai',
    'remarias',
    'remariât',
    'remariée',
    'remarier',
    'remaries',
    'remariés',
    'remariez',
    'remarqua',
    'remarque',
    'remarqué',
    'remballa',
    'remballe',
    'remballé',
    'rembarra',
    'rembarre',
    'rembarré',
    'rembinai',
    'rembinas',
    'rembinât',
    'rembinée',
    'rembiner',
    'rembines',
    'rembinés',
    'rembinez',
    'remblaie',
    'remblais',
    'remblava',
    'remblave',
    'remblavé',
    'remblaya',
    'remblaye',
    'remblayé',
    'remboîta',
    'remboîte',
    'remboîté',
    'rembouge',
    'rembougé',
    'rembruni',
    'rembucha',
    'rembuche',
    'rembuché',
    'remédiai',
    'remédias',
    'remédiât',
    'remédier',
    'remédies',
    'remédiez',
    'remembra',
    'remembre',
    'remembré',
    'remémora',
    'remémore',
    'remémoré',
    'remercia',
    'remercie',
    'remercié',
    'remettes',
    'remettez',
    'remettra',
    'remettre',
    'remeubla',
    'remeuble',
    'remeublé',
    'remirent',
    'remisage',
    'remisais',
    'remisait',
    'remisant',
    'remisées',
    'remisent',
    'remisera',
    'remisier',
    'remisiez',
    'remisons',
    'remisses',
    'remmenai',
    'remmenas',
    'remmenât',
    'remmenée',
    'remmener',
    'remmenés',
    'remmènes',
    'remmenez',
    'remodela',
    'remodelé',
    'remodèle',
    'remontai',
    'remontas',
    'remontât',
    'remontée',
    'remonter',
    'remontes',
    'remontés',
    'remontez',
    'remontra',
    'remontre',
    'remontré',
    'remordes',
    'remordez',
    'remordis',
    'remordit',
    'remordît',
    'remordra',
    'remordre',
    'remordue',
    'remordus',
    'remorqua',
    'remorque',
    'remorqué',
    'remoucha',
    'remouche',
    'remouché',
    'remoudra',
    'remoudre',
    'remoules',
    'remoulez',
    'remoulue',
    'remoulus',
    'remoulut',
    'remoulût',
    'remparai',
    'remparas',
    'remparât',
    'remparée',
    'remparer',
    'rempares',
    'remparés',
    'remparez',
    'remparts',
    'rempiéta',
    'rempiété',
    'rempiète',
    'rempilai',
    'rempilas',
    'rempilât',
    'rempilée',
    'rempiler',
    'rempiles',
    'rempilés',
    'rempilez',
    'remplaça',
    'remplace',
    'remplacé',
    'remplage',
    'rempliai',
    'remplias',
    'rempliât',
    'rempliée',
    'remplier',
    'remplies',
    'rempliés',
    'rempliez',
    'remplira',
    'remploie',
    'remplois',
    'remploya',
    'remployé',
    'rempluma',
    'remplume',
    'remplumé',
    'rempocha',
    'rempoche',
    'rempoché',
    'remporta',
    'remporte',
    'remporté',
    'rempotai',
    'rempotas',
    'rempotât',
    'rempotée',
    'rempoter',
    'rempotes',
    'rempotés',
    'rempotez',
    'remuâmes',
    'remuante',
    'remuants',
    'remuasse',
    'remuâtes',
    'remuerai',
    'remueras',
    'remuerez',
    'remugles',
    'remuions',
    'rémunéra',
    'rémunéré',
    'rémunère',
    'renaclai',
    'renaclas',
    'renaclât',
    'renâcler',
    'renâcles',
    'renâclez',
    'renaisse',
    'renaîtra',
    'renaître',
    'renaquis',
    'renaquit',
    'renaquît',
    'renardai',
    'renardas',
    'renardât',
    'renarder',
    'renardes',
    'renardez',
    'renaudai',
    'renaudas',
    'renaudât',
    'renauder',
    'renaudes',
    'renaudez',
    'rencarda',
    'rencarde',
    'rencardé',
    'rencards',
    'rencarts',
    'renchéri',
    'rencogna',
    'rencogne',
    'rencogné',
    'rendîmes',
    'rendions',
    'rendisse',
    'rendîtes',
    'rendorme',
    'rendormi',
    'rendossa',
    'rendosse',
    'rendossé',
    'rendrais',
    'rendrait',
    'rendriez',
    'rendrons',
    'rendront',
    'renégate',
    'renégats',
    'reneigea',
    'reneiger',
    'renfaîta',
    'renfaîte',
    'renfaîté',
    'renferma',
    'renferme',
    'renfermé',
    'renfilai',
    'renfilas',
    'renfilât',
    'renfilée',
    'renfiler',
    'renfiles',
    'renfilés',
    'renfilez',
    'renflais',
    'renflait',
    'renflant',
    'renflées',
    'renflent',
    'renflera',
    'renfliez',
    'renflons',
    'renfloua',
    'renfloue',
    'renfloué',
    'renfonça',
    'renfonce',
    'renfoncé',
    'renforça',
    'renforce',
    'renforcé',
    'renformi',
    'renforts',
    'rengagea',
    'rengagée',
    'rengager',
    'rengages',
    'rengagés',
    'rengagez',
    'rengaina',
    'rengaine',
    'rengainé',
    'rengorge',
    'rengorgé',
    'rengrena',
    'rengréna',
    'rengrené',
    'rengréné',
    'rengrène',
    'reniâmes',
    'reniasse',
    'reniâtes',
    'renierai',
    'renieras',
    'renierez',
    'reniflai',
    'reniflas',
    'reniflât',
    'reniflée',
    'renifler',
    'renifles',
    'reniflés',
    'reniflez',
    'reniions',
    'renommai',
    'renommas',
    'renommât',
    'renommée',
    'renommer',
    'renommes',
    'renommés',
    'renommez',
    'renonçai',
    'renonças',
    'renonçât',
    'renoncée',
    'renoncer',
    'renonces',
    'renoncés',
    'renoncez',
    'renouais',
    'renouait',
    'renouant',
    'renouées',
    'renouent',
    'renouera',
    'renouiez',
    'renouons',
    'rénovais',
    'rénovait',
    'rénovant',
    'rénovées',
    'rénovent',
    'rénovera',
    'rénoviez',
    'rénovons',
    'rentable',
    'rentamai',
    'rentamas',
    'rentamât',
    'rentamée',
    'rentamer',
    'rentames',
    'rentamés',
    'rentâmes',
    'rentamez',
    'rentasse',
    'rentâtes',
    'renterai',
    'renteras',
    'renterez',
    'rentière',
    'rentiers',
    'rentions',
    'rentoila',
    'rentoile',
    'rentoilé',
    'rentrais',
    'rentrait',
    'rentrant',
    'rentrées',
    'rentrent',
    'rentrera',
    'rentriez',
    'rentrons',
    'renverge',
    'renvergé',
    'renverra',
    'renversa',
    'renverse',
    'renversé',
    'renviais',
    'renviait',
    'renviant',
    'renvidai',
    'renvidas',
    'renvidât',
    'renvidée',
    'renvider',
    'renvides',
    'renvidés',
    'renvidez',
    'renviées',
    'renvient',
    'renviera',
    'renviiez',
    'renvions',
    'renvoies',
    'renvoyai',
    'renvoyas',
    'renvoyât',
    'renvoyée',
    'renvoyer',
    'renvoyés',
    'renvoyez',
    'réoccupa',
    'réoccupe',
    'réoccupé',
    'réopérai',
    'réopéras',
    'réopérât',
    'réopérée',
    'réopérer',
    'réopérés',
    'réopères',
    'réopérez',
    'repaient',
    'repaiera',
    'repairai',
    'repairas',
    'repairât',
    'repairer',
    'repaires',
    'repairez',
    'repaisse',
    'repaîtra',
    'repaître',
    'répandes',
    'répandez',
    'répandis',
    'répandit',
    'répandît',
    'répandra',
    'répandre',
    'répandue',
    'répandus',
    'reparais',
    'réparais',
    'reparaît',
    'réparait',
    'réparant',
    'réparées',
    'réparent',
    'réparera',
    'répariez',
    'reparlai',
    'reparlas',
    'reparlât',
    'reparler',
    'reparles',
    'reparlez',
    'réparons',
    'repartes',
    'repartez',
    'repartie',
    'répartie',
    'repartir',
    'répartir',
    'repartis',
    'répartis',
    'repartit',
    'repartît',
    'répartit',
    'répartît',
    'reparues',
    'repassai',
    'repassas',
    'repassât',
    'repassée',
    'repasser',
    'repasses',
    'repassés',
    'repassez',
    'repatina',
    'repatine',
    'repatiné',
    'repavais',
    'repavait',
    'repavant',
    'repavées',
    'repavent',
    'repavera',
    'repaviez',
    'repavons',
    'repayais',
    'repayait',
    'repayant',
    'repayées',
    'repayent',
    'repayera',
    'repayiez',
    'repayons',
    'repêchai',
    'repêchas',
    'repêchât',
    'repêchée',
    'repêcher',
    'repêches',
    'repêchés',
    'repêchez',
    'repeigna',
    'repeigne',
    'repeigné',
    'repeinte',
    'repeints',
    'rependes',
    'rependez',
    'rependis',
    'rependit',
    'rependît',
    'rependra',
    'rependre',
    'rependue',
    'rependus',
    'repensai',
    'repensas',
    'repensât',
    'repensée',
    'repenser',
    'repenses',
    'repensés',
    'repensez',
    'repentes',
    'repentez',
    'repentie',
    'repentir',
    'repentis',
    'repentit',
    'repentît',
    'repérage',
    'repérais',
    'repérait',
    'repérant',
    'reperçai',
    'reperças',
    'reperçât',
    'repercée',
    'repercer',
    'reperces',
    'repercés',
    'repercez',
    'reperdes',
    'reperdez',
    'reperdis',
    'reperdit',
    'reperdît',
    'reperdra',
    'reperdre',
    'reperdue',
    'reperdus',
    'repérées',
    'repèrent',
    'repérera',
    'repériez',
    'repérons',
    'répétais',
    'répétait',
    'répétant',
    'répétées',
    'répètent',
    'répétera',
    'répéteur',
    'répétiez',
    'répétons',
    'repeupla',
    'repeuple',
    'repeuplé',
    'repinçai',
    'repinças',
    'repinçât',
    'repincée',
    'repincer',
    'repinces',
    'repincés',
    'repincez',
    'repiquai',
    'repiquas',
    'repiquât',
    'repiquée',
    'repiquer',
    'repiques',
    'repiqués',
    'repiquez',
    'replaçai',
    'replaças',
    'replaçât',
    'replacée',
    'replacer',
    'replaces',
    'replacés',
    'replacez',
    'replanta',
    'replante',
    'replanté',
    'replâtra',
    'replâtre',
    'replâtré',
    'replètes',
    'réplétif',
    'repleuve',
    'repliais',
    'repliait',
    'repliant',
    'repliées',
    'replient',
    'repliera',
    'repliiez',
    'replions',
    'répliqua',
    'réplique',
    'répliqué',
    'replissa',
    'replisse',
    'replissé',
    'reploies',
    'replonge',
    'replongé',
    'reployai',
    'reployas',
    'reployât',
    'reployée',
    'reployer',
    'reployés',
    'reployez',
    'repolies',
    'repolira',
    'répondes',
    'répondez',
    'répondis',
    'répondit',
    'répondît',
    'répondra',
    'répondre',
    'répondue',
    'répondus',
    'réponses',
    'reportai',
    'reportas',
    'reportât',
    'reportée',
    'reporter',
    'reportes',
    'reportés',
    'reportez',
    'reposais',
    'reposait',
    'reposant',
    'reposées',
    'reposent',
    'reposera',
    'reposiez',
    'reposoir',
    'reposons',
    'repoussa',
    'repousse',
    'repoussé',
    'reprends',
    'reprenez',
    'reprenne',
    'réprimai',
    'réprimas',
    'réprimât',
    'réprimée',
    'réprimer',
    'reprîmes',
    'réprimes',
    'réprimés',
    'réprimez',
    'reprisai',
    'reprisas',
    'reprisât',
    'reprisée',
    'repriser',
    'reprises',
    'reprisés',
    'reprisez',
    'reprisse',
    'reprîtes',
    'reprocha',
    'reproche',
    'reproché',
    'reprouva',
    'réprouva',
    'reprouve',
    'reprouvé',
    'réprouve',
    'réprouvé',
    'reptiles',
    'répudiai',
    'répudias',
    'répudiât',
    'répudiée',
    'répudier',
    'répudies',
    'répudiés',
    'répudiez',
    'répugnai',
    'répugnas',
    'répugnât',
    'répugner',
    'répugnes',
    'répugnez',
    'repurent',
    'repusses',
    'réputais',
    'réputait',
    'réputant',
    'réputées',
    'réputent',
    'réputera',
    'réputiez',
    'réputons',
    'requérez',
    'requérir',
    'requerra',
    'requérue',
    'requérus',
    'requêtes',
    'requiems',
    'requière',
    'requiers',
    'requiert',
    'requîmes',
    'requises',
    'réquisit',
    'requisse',
    'requîtes',
    'reroisai',
    'reroisas',
    'reroisât',
    'resalais',
    'resalait',
    'resalant',
    'resalées',
    'resalent',
    'resalera',
    'resalies',
    'resaliez',
    'resalira',
    'resalons',
    'resaluai',
    'resaluas',
    'resaluât',
    'resaluée',
    'resaluer',
    'resalues',
    'resalués',
    'resaluez',
    'rescapée',
    'rescapés',
    'rescinda',
    'rescinde',
    'rescrits',
    'reséquai',
    'reséquas',
    'reséquât',
    'reséquée',
    'reséquer',
    'reséqués',
    'resèques',
    'reséquez',
    'réservai',
    'réservas',
    'réservât',
    'réservée',
    'réserver',
    'réserves',
    'réservés',
    'réservez',
    'résidais',
    'résidait',
    'résidant',
    'résident',
    'résidera',
    'résidiez',
    'résidons',
    'résiduel',
    'résignai',
    'résignas',
    'résignât',
    'résignée',
    'résigner',
    'résignes',
    'résignés',
    'résignez',
    'résiliai',
    'résilias',
    'résiliât',
    'résiliée',
    'résilier',
    'résilies',
    'résiliés',
    'résiliez',
    'résilles',
    'résinais',
    'résinait',
    'résinant',
    'résinées',
    'résinent',
    'résinera',
    'résineux',
    'résinier',
    'résiniez',
    'résinons',
    'résistai',
    'résistas',
    'résistât',
    'résister',
    'résistes',
    'résistez',
    'résolues',
    'résolves',
    'résolvez',
    'résonant',
    'résonnai',
    'résonnas',
    'résonnât',
    'résonner',
    'résonnes',
    'résonnez',
    'résorbai',
    'résorbas',
    'résorbât',
    'résorbée',
    'résorber',
    'résorbes',
    'résorbés',
    'résorbez',
    'résoudra',
    'résoudre',
    'résoutes',
    'respecta',
    'respecte',
    'respecté',
    'respects',
    'respirai',
    'respiras',
    'respirât',
    'respirée',
    'respirer',
    'respires',
    'respirés',
    'respirez',
    'ressaies',
    'ressaisi',
    'ressassa',
    'ressasse',
    'ressassé',
    'ressauta',
    'ressaute',
    'ressauté',
    'ressauts',
    'ressayai',
    'ressayas',
    'ressayât',
    'ressayée',
    'ressayer',
    'ressayes',
    'ressayés',
    'ressayez',
    'ressemai',
    'ressemas',
    'ressemât',
    'ressemée',
    'ressemer',
    'ressemés',
    'ressèmes',
    'ressemez',
    'ressente',
    'ressenti',
    'resserra',
    'resserre',
    'resserré',
    'resserve',
    'resservi',
    'ressorte',
    'ressorti',
    'ressorts',
    'ressouda',
    'ressoude',
    'ressoudé',
    'ressuais',
    'ressuait',
    'ressuant',
    'ressuent',
    'ressuera',
    'ressuies',
    'ressuiez',
    'ressuons',
    'ressurgi',
    'ressuyai',
    'ressuyas',
    'ressuyât',
    'ressuyée',
    'ressuyer',
    'ressuyés',
    'ressuyez',
    'restâmes',
    'restante',
    'restants',
    'restasse',
    'restâtes',
    'restaura',
    'restaure',
    'restauré',
    'resterai',
    'resteras',
    'resterez',
    'restions',
    'restitua',
    'restitue',
    'restitué',
    'resucées',
    'résultat',
    'résultât',
    'résulter',
    'résumais',
    'résumait',
    'résumant',
    'résumées',
    'résument',
    'résumera',
    'résumiez',
    'résumons',
    'resurgir',
    'resurgis',
    'resurgit',
    'resurgît',
    'retables',
    'rétablie',
    'rétablir',
    'rétablis',
    'rétablit',
    'rétablît',
    'retailla',
    'retaille',
    'retaillé',
    'rétamage',
    'rétamais',
    'rétamait',
    'rétamant',
    'rétamées',
    'rétament',
    'rétamera',
    'rétameur',
    'rétamiez',
    'rétamons',
    'retapais',
    'retapait',
    'retapant',
    'retapées',
    'retapent',
    'retapera',
    'retapiez',
    'retapons',
    'retardai',
    'retardas',
    'retardât',
    'retardée',
    'retarder',
    'retardes',
    'retardés',
    'retardez',
    'retâtais',
    'retâtait',
    'retâtant',
    'retâtées',
    'retâtent',
    'retâtera',
    'retâtiez',
    'retâtons',
    'reteigne',
    'reteinte',
    'reteints',
    'retenais',
    'retenait',
    'retenant',
    'retendes',
    'retendez',
    'retendis',
    'retendit',
    'retendît',
    'retendra',
    'retendre',
    'retendue',
    'retendus',
    'reteniez',
    'retenons',
    'retentai',
    'retentas',
    'retentât',
    'retentée',
    'retenter',
    'retentes',
    'retentés',
    'retentez',
    'retentir',
    'retentis',
    'retentit',
    'retentît',
    'retenues',
    'reterçai',
    'reterças',
    'reterçât',
    'retercée',
    'retercer',
    'reterces',
    'retercés',
    'retercez',
    'retersai',
    'retersas',
    'retersât',
    'retersée',
    'reterser',
    'reterses',
    'retersés',
    'retersez',
    'rétiaire',
    'réticent',
    'réticula',
    'réticule',
    'réticulé',
    'retienne',
    'rétinien',
    'rétinite',
    'retînmes',
    'retinsse',
    'retîntes',
    'retirage',
    'retirais',
    'retirait',
    'retirant',
    'retirées',
    'retirent',
    'retirera',
    'retiriez',
    'retirons',
    'retissai',
    'retissas',
    'retissât',
    'retissée',
    'retisser',
    'retisses',
    'retissés',
    'retissez',
    'rétivité',
    'retombai',
    'retombas',
    'retombât',
    'retombée',
    'retomber',
    'retombes',
    'retombés',
    'retombez',
    'retondes',
    'retondez',
    'retondis',
    'retondit',
    'retondît',
    'retondra',
    'retondre',
    'retondue',
    'retondus',
    'retordes',
    'retordez',
    'retordis',
    'retordit',
    'retordît',
    'retordra',
    'retordre',
    'retordue',
    'retordus',
    'rétorqua',
    'rétorque',
    'rétorqué',
    'retoucha',
    'retouche',
    'retouché',
    'retourna',
    'retourne',
    'retourné',
    'retraçai',
    'retraças',
    'retraçât',
    'retracée',
    'retracer',
    'retraces',
    'retracés',
    'retracez',
    'rétracta',
    'rétracte',
    'rétracté',
    'retraies',
    'retraira',
    'retraire',
    'retraita',
    'retraite',
    'retraité',
    'retraits',
    'retrayez',
    'rétrécie',
    'rétrécir',
    'rétrécis',
    'rétrécit',
    'rétrécît',
    'retrempa',
    'retrempe',
    'retrempé',
    'rétribua',
    'rétribue',
    'rétribué',
    'rétroagi',
    'retrouva',
    'retrouve',
    'retrouvé',
    'réunifia',
    'réunifie',
    'réunifié',
    'réunîmes',
    'réunions',
    'réunirai',
    'réuniras',
    'réunirez',
    'réunisse',
    'réunîtes',
    'réussies',
    'réussira',
    'réussite',
    'rêvaient',
    'revaille',
    'revalais',
    'revalait',
    'revalant',
    'revalent',
    'revaliez',
    'revaloir',
    'revalons',
    'revalues',
    'revancha',
    'revanche',
    'revanché',
    'rêvassai',
    'rêvassas',
    'rêvassât',
    'rêvasser',
    'rêvasses',
    'rêvassez',
    'revaudra',
    'revêches',
    'réveilla',
    'réveille',
    'réveillé',
    'révélais',
    'révélait',
    'révélant',
    'révélées',
    'révèlent',
    'révélera',
    'révéliez',
    'révélons',
    'revenais',
    'revenait',
    'revenant',
    'revendes',
    'revendez',
    'revendis',
    'revendit',
    'revendît',
    'revendra',
    'revendre',
    'revendue',
    'revendus',
    'reveniez',
    'revenons',
    'reventes',
    'revenues',
    'révérais',
    'rêverais',
    'révérait',
    'rêverait',
    'révérant',
    'revercha',
    'reverche',
    'reverché',
    'reverdie',
    'reverdir',
    'reverdis',
    'reverdit',
    'reverdît',
    'révérées',
    'révérend',
    'révèrent',
    'rêvèrent',
    'révérera',
    'rêveries',
    'révériez',
    'rêveriez',
    'revernie',
    'revernir',
    'revernis',
    'revernit',
    'revernît',
    'révérons',
    'rêverons',
    'rêveront',
    'reverrai',
    'reverras',
    'reverrez',
    'reversai',
    'reversas',
    'reversât',
    'reversée',
    'reverser',
    'reverses',
    'reversés',
    'reversez',
    'revêtais',
    'revêtait',
    'revêtant',
    'revêtent',
    'revêtiez',
    'revêtira',
    'revêtons',
    'revêtues',
    'rêveuses',
    'revienne',
    'revigora',
    'revigore',
    'revigoré',
    'revînmes',
    'revinsse',
    'revîntes',
    'revirais',
    'revirait',
    'revirant',
    'revirent',
    'revirera',
    'reviriez',
    'revirons',
    'révisais',
    'révisait',
    'révisant',
    'révisées',
    'révisent',
    'révisera',
    'réviseur',
    'révisiez',
    'révision',
    'révisons',
    'revissai',
    'revissas',
    'revissât',
    'revissée',
    'revisser',
    'revisses',
    'revissés',
    'revissez',
    'revivais',
    'revivait',
    'revivant',
    'revivent',
    'reviviez',
    'revivons',
    'revivrai',
    'revivras',
    'revivrez',
    'revoient',
    'revolais',
    'revolait',
    'revolant',
    'revolent',
    'revolera',
    'revoliez',
    'revolons',
    'révoltai',
    'révoltas',
    'révoltât',
    'révoltée',
    'révolter',
    'révoltes',
    'révoltés',
    'révoltez',
    'révolues',
    'revolver',
    'révoquai',
    'révoquas',
    'révoquât',
    'révoquée',
    'révoquer',
    'révoques',
    'révoqués',
    'révoquez',
    'revotais',
    'revotait',
    'revotant',
    'revotées',
    'revotent',
    'revotera',
    'revotiez',
    'revotons',
    'revoudra',
    'revoulez',
    'revoulue',
    'revoulus',
    'revoulut',
    'revoulût',
    'revoyais',
    'revoyait',
    'revoyant',
    'revoyiez',
    'revoyons',
    'revoyure',
    'révulsai',
    'révulsas',
    'révulsât',
    'révulsée',
    'révulser',
    'révulses',
    'révulsés',
    'révulsez',
    'révulsif',
    'rewriter',
    'rhabilla',
    'rhabille',
    'rhabillé',
    'rhapsode',
    'rhénanes',
    'rhéniums',
    'rhéobase',
    'rhéostat',
    'rhéteurs',
    'rhétiens',
    'rhétique',
    'rhinites',
    'rhizomes',
    'rhodiées',
    'rhodinol',
    'rhodites',
    'rhodiums',
    'rhubarbe',
    'rhumâmes',
    'rhumasse',
    'rhumâtes',
    'rhumerai',
    'rhumeras',
    'rhumerez',
    'rhumerie',
    'rhumions',
    'rhyolite',
    'ribaudes',
    'riblages',
    'riblâmes',
    'riblasse',
    'riblâtes',
    'riblerai',
    'ribleras',
    'riblerez',
    'riblions',
    'ribosome',
    'riboulai',
    'riboulas',
    'riboulât',
    'ribouler',
    'riboules',
    'riboulez',
    'ricaines',
    'ricanais',
    'ricanait',
    'ricanant',
    'ricanent',
    'ricanera',
    'ricaneur',
    'ricaniez',
    'ricanons',
    'richarde',
    'richards',
    'richesse',
    'ricinées',
    'ricochai',
    'ricochas',
    'ricochât',
    'ricocher',
    'ricoches',
    'ricochet',
    'ricochez',
    'ridaient',
    'ridasses',
    'ridelles',
    'ridement',
    'riderais',
    'riderait',
    'ridèrent',
    'rideriez',
    'riderons',
    'rideront',
    'ridicule',
    'riesling',
    'riffauda',
    'riffaude',
    'riffaudé',
    'riflâmes',
    'riflards',
    'riflasse',
    'riflâtes',
    'riflerai',
    'rifleras',
    'riflerez',
    'riflette',
    'riflions',
    'rifloirs',
    'rigaudon',
    'rigidité',
    'rigodons',
    'rigolade',
    'rigolage',
    'rigolais',
    'rigolait',
    'rigolant',
    'rigolard',
    'rigolent',
    'rigolera',
    'rigoleur',
    'rigoliez',
    'rigolons',
    'rigolote',
    'rigottes',
    'rigueurs',
    'rimaient',
    'rimailla',
    'rimaille',
    'rimaillé',
    'rimasses',
    'rimerais',
    'rimerait',
    'rimèrent',
    'rimeriez',
    'rimerons',
    'rimeront',
    'rimeuses',
    'rinçages',
    'rinçâmes',
    'rinçasse',
    'rinçâtes',
    'rinceaux',
    'rincerai',
    'rinceras',
    'rincerez',
    'rincette',
    'rinceurs',
    'rinceuse',
    'rincions',
    'rinçures',
    'ringarda',
    'ringarde',
    'ringardé',
    'ringards',
    'ripaient',
    'ripailla',
    'ripaille',
    'ripaillé',
    'ripasses',
    'ripatons',
    'riperais',
    'riperait',
    'ripèrent',
    'riperiez',
    'riperons',
    'riperont',
    'ripolina',
    'ripoline',
    'ripoliné',
    'Ripolins',
    'ripostai',
    'ripostas',
    'ripostât',
    'riposter',
    'ripostes',
    'ripostez',
    'ripuaire',
    'riquiqui',
    'riraient',
    'risberme',
    'risettes',
    'risibles',
    'risorius',
    'risottos',
    'risquais',
    'risquait',
    'risquant',
    'risquées',
    'risquent',
    'risquera',
    'risquiez',
    'risquons',
    'rissions',
    'rissolai',
    'rissolas',
    'rissolât',
    'rissolée',
    'rissoler',
    'rissoles',
    'rissolés',
    'rissolez',
    'rituelle',
    'rivaient',
    'rivalisa',
    'rivalise',
    'rivalisé',
    'rivalité',
    'rivasses',
    'riverain',
    'riverais',
    'riverait',
    'rivèrent',
    'riveriez',
    'riverons',
    'riveront',
    'rivetage',
    'rivetais',
    'rivetait',
    'rivetant',
    'rivetées',
    'rivetiez',
    'rivetons',
    'rivettes',
    'riveuses',
    'rivières',
    'rivulair',
    'rixdales',
    'rizeries',
    'rizières',
    'roadster',
    'robaient',
    'robasses',
    'robelage',
    'roberais',
    'roberait',
    'robèrent',
    'roberiez',
    'roberons',
    'roberont',
    'robinets',
    'robinier',
    'robotisa',
    'robotise',
    'robotisé',
    'robustes',
    'rocaille',
    'rochages',
    'rocheuse',
    'rochiers',
    'rockeuse',
    'rocouais',
    'rocouait',
    'rocouant',
    'rocouées',
    'rocouent',
    'rocouera',
    'rocouiez',
    'rocouons',
    'rocouyer',
    'rocquais',
    'rocquait',
    'rocquant',
    'rocquent',
    'rocquera',
    'rocquiez',
    'rocquons',
    'rodaient',
    'rôdaient',
    'rôdailla',
    'rôdaille',
    'rôdaillé',
    'rodasses',
    'rôdasses',
    'roderais',
    'rôderais',
    'roderait',
    'rôderait',
    'rodèrent',
    'rôdèrent',
    'roderiez',
    'rôderiez',
    'roderons',
    'rôderons',
    'roderont',
    'rôderont',
    'rôdeuses',
    'rodomont',
    'roentgen',
    'rogatons',
    'rognages',
    'rognâmes',
    'rognasse',
    'rognâtes',
    'rognerai',
    'rogneras',
    'rognerez',
    'rogneurs',
    'rogneuse',
    'rognions',
    'rognonna',
    'rognonne',
    'rognonné',
    'rognures',
    'rogommes',
    'roideurs',
    'roidîmes',
    'roidirai',
    'roidiras',
    'roidirez',
    'roidisse',
    'roidîtes',
    'roitelet',
    'rolliers',
    'rollmops',
    'romaines',
    'romançai',
    'romanças',
    'romançât',
    'romancée',
    'romancer',
    'romances',
    'romancés',
    'romancez',
    'romanche',
    'romandes',
    'romanisa',
    'romanise',
    'romanisé',
    'romanité',
    'romarins',
    'rombière',
    'rompîmes',
    'rompions',
    'rompisse',
    'rompîtes',
    'romprais',
    'romprait',
    'rompriez',
    'romprons',
    'rompront',
    'romsteck',
    'ronceuse',
    'ronchons',
    'roncière',
    'ronciers',
    'rondache',
    'rondeaux',
    'rondelet',
    'rondelle',
    'rondeurs',
    'rondiers',
    'rondîmes',
    'rondirai',
    'rondiras',
    'rondirez',
    'rondisse',
    'rondîtes',
    'ronflais',
    'ronflait',
    'ronflant',
    'ronflent',
    'ronflera',
    'ronfleur',
    'ronfliez',
    'ronflons',
    'rongeais',
    'rongeait',
    'rongeant',
    'rongeons',
    'rongerai',
    'rongeras',
    'rongerez',
    'rongeurs',
    'rongeuse',
    'rongions',
    'ronronna',
    'ronronne',
    'ronronné',
    'rookerie',
    'roquâmes',
    'roquasse',
    'roquâtes',
    'roquerai',
    'roqueras',
    'roquerez',
    'roquerie',
    'roquette',
    'roquions',
    'rorquals',
    'rosacées',
    'rosaient',
    'rosaires',
    'rosalbin',
    'rosasses',
    'rosâtres',
    'roselets',
    'roselier',
    'roséoles',
    'roseraie',
    'roserais',
    'roserait',
    'rosèrent',
    'roseriez',
    'roserons',
    'roseront',
    'rosettes',
    'rosières',
    'rosirais',
    'rosirait',
    'rosirent',
    'rosiriez',
    'rosirons',
    'rosiront',
    'rosisses',
    'rosissez',
    'rossâmes',
    'rossards',
    'rossasse',
    'rossâtes',
    'rosserai',
    'rosseras',
    'rosserez',
    'rosserie',
    'rossions',
    'rossolis',
    'rostrale',
    'rostraux',
    'rotacées',
    'rotaient',
    'rotarien',
    'rotasses',
    'rotateur',
    'rotatifs',
    'rotation',
    'rotative',
    'rotengle',
    'roténone',
    'roterais',
    'roterait',
    'rotèrent',
    'roteriez',
    'roterons',
    'roteront',
    'rotifère',
    'rôtirais',
    'rôtirait',
    'rôtirent',
    'rôtiriez',
    'rôtirons',
    'rôtiront',
    'rôtisses',
    'rôtissez',
    'rotondes',
    'rotulien',
    'roturier',
    'rouableS',
    'rouaient',
    'rouannes',
    'rouasses',
    'roublard',
    'roucoula',
    'roucoule',
    'roucoulé',
    'rouelles',
    'rouerais',
    'rouerait',
    'rouèrent',
    'roueries',
    'roueriez',
    'rouerons',
    'roueront',
    'rouettes',
    'rougeaud',
    'rougeoie',
    'rougeole',
    'rougeoya',
    'rougeoyé',
    'rougeurs',
    'rougîmes',
    'rougirai',
    'rougiras',
    'rougirez',
    'rougisse',
    'rougîtes',
    'rouillai',
    'rouillas',
    'rouillât',
    'rouillée',
    'rouiller',
    'rouilles',
    'rouillés',
    'rouillez',
    'rouirais',
    'rouirait',
    'rouirent',
    'rouiriez',
    'rouirons',
    'rouiront',
    'rouisses',
    'rouissez',
    'roulades',
    'roulages',
    'roulâmes',
    'roulante',
    'roulants',
    'roulasse',
    'roulâtes',
    'rouleaux',
    'roulerai',
    'rouleras',
    'roulerez',
    'roulette',
    'rouleurs',
    'rouliers',
    'roulions',
    'rouloirs',
    'roulotta',
    'roulotte',
    'roulotté',
    'roulures',
    'roumaine',
    'roumains',
    'roupilla',
    'roupille',
    'roupillé',
    'rouquine',
    'rouquins',
    'rouspéta',
    'rouspété',
    'rouspète',
    'rousseau',
    'rousseur',
    'roussies',
    'roussins',
    'roussira',
    'rousties',
    'roustira',
    'roustons',
    'routages',
    'routâmes',
    'routarde',
    'routards',
    'routasse',
    'routâtes',
    'routerai',
    'routeras',
    'routerez',
    'routière',
    'routiers',
    'routines',
    'routions',
    'rouverin',
    'rouverte',
    'rouverts',
    'rouvieux',
    'rouvraie',
    'rouvrais',
    'rouvrait',
    'rouvrant',
    'rouvrent',
    'rouvriez',
    'rouvrira',
    'rouvrons',
    'rouvrues',
    'royaumes',
    'royautés',
    'ruassent',
    'ruassiez',
    'rubanais',
    'rubanait',
    'rubanant',
    'rubanées',
    'rubanent',
    'rubanera',
    'rubanier',
    'rubaniez',
    'rubanons',
    'rubéfiai',
    'rubéfias',
    'rubéfiât',
    'rubéfiée',
    'rubéfier',
    'rubéfies',
    'rubéfiés',
    'rubéfiez',
    'rubéoles',
    'rubiacée',
    'rubiacés',
    'rubicans',
    'rubicond',
    'rubidium',
    'rubiette',
    'rubrique',
    'ruchâmes',
    'ruchasse',
    'ruchâtes',
    'rucherai',
    'rucheras',
    'rucherez',
    'ruchions',
    'rudement',
    'rudentai',
    'rudentas',
    'rudentât',
    'rudentée',
    'rudenter',
    'rudentes',
    'rudentés',
    'rudentez',
    'rudérale',
    'rudéraux',
    'rudesses',
    'rudiment',
    'rudoient',
    'rudoiera',
    'rudoyais',
    'rudoyait',
    'rudoyant',
    'rudoyées',
    'rudoyiez',
    'rudoyons',
    'ruerions',
    'ruffians',
    'rugbyman',
    'rugbymen',
    'rugirais',
    'rugirait',
    'rugirent',
    'rugiriez',
    'rugirons',
    'rugiront',
    'rugisses',
    'rugissez',
    'rugosité',
    'rugueuse',
    'ruilâmes',
    'ruilasse',
    'ruilâtes',
    'ruilerai',
    'ruileras',
    'ruilerez',
    'ruilions',
    'ruinâmes',
    'ruinasse',
    'ruinâtes',
    'ruinerai',
    'ruineras',
    'ruinerez',
    'ruineuse',
    'ruinions',
    'ruiniste',
    'ruinures',
    'ruisseau',
    'ruissela',
    'ruisselé',
    'ruminais',
    'ruminait',
    'ruminant',
    'ruminées',
    'ruminent',
    'ruminera',
    'ruminiez',
    'ruminons',
    'rumsteck',
    'runabout',
    'rupestre',
    'rupicole',
    'rupinais',
    'rupinait',
    'rupinant',
    'rupinent',
    'rupinera',
    'rupiniez',
    'rupinons',
    'rupteurs',
    'ruptures',
    'rusaient',
    'rusasses',
    'ruserais',
    'ruserait',
    'rusèrent',
    'ruseriez',
    'ruserons',
    'ruseront',
    'russifia',
    'russifie',
    'russifié',
    'russules',
    'rustaude',
    'rustauds',
    'rustines',
    'rustiqua',
    'rustique',
    'rustiqué',
    'rutabaga',
    'rutilais',
    'rutilait',
    'rutilant',
    'rutilent',
    'rutilera',
    'rutiliez',
    'rutilons',
    'rythmais',
    'rythmait',
    'rythmant',
    'rythmées',
    'rythment',
    'rythmera',
    'rythmiez',
    'rythmons',
    'sabayons',
    'sabéenne',
    'sabéisme',
    'sabelles',
    'sablages',
    'sablâmes',
    'sablasse',
    'sablâtes',
    'sablerai',
    'sableras',
    'sablerez',
    'sablerie',
    'sableurs',
    'sableuse',
    'sablière',
    'sabliers',
    'sablions',
    'sablonna',
    'sablonne',
    'sablonné',
    'sabordai',
    'sabordas',
    'sabordât',
    'sabordée',
    'saborder',
    'sabordes',
    'sabordés',
    'sabordez',
    'sabotage',
    'sabotais',
    'sabotait',
    'sabotant',
    'sabotées',
    'sabotent',
    'sabotera',
    'saboteur',
    'sabotier',
    'sabotiez',
    'sabotons',
    'saboulai',
    'saboulas',
    'saboulât',
    'saboulée',
    'sabouler',
    'saboules',
    'saboulés',
    'saboulez',
    'sabrages',
    'sabrerai',
    'sabreras',
    'sabrerez',
    'sabreurs',
    'sabreuse',
    'sabrions',
    'saburral',
    'saburres',
    'sacagnai',
    'sacagnas',
    'sacagnât',
    'sacagnée',
    'sacagner',
    'sacagnes',
    'sacagnés',
    'sacagnez',
    'saccadai',
    'saccadas',
    'saccadât',
    'saccadée',
    'saccader',
    'saccades',
    'saccadés',
    'saccadez',
    'saccagea',
    'saccagée',
    'saccager',
    'saccages',
    'saccagés',
    'saccagez',
    'saccules',
    'sachions',
    'sacoches',
    'sacoléva',
    'sacolève',
    'sacquais',
    'sacquait',
    'sacquant',
    'sacquées',
    'sacquent',
    'sacquera',
    'sacquiez',
    'sacquons',
    'sacrales',
    'sacrâmes',
    'sacrasse',
    'sacrâtes',
    'sacrerai',
    'sacreras',
    'sacrerez',
    'sacrifia',
    'sacrifie',
    'sacrifié',
    'sacrions',
    'sacristi',
    'sadiques',
    'sadismes',
    'saducéen',
    'safranai',
    'safranas',
    'safranât',
    'safranée',
    'safraner',
    'safranes',
    'safranés',
    'safranez',
    'sagacité',
    'sagement',
    'sagesses',
    'sagettes',
    'sagittal',
    'sagittée',
    'sagittés',
    'sagouins',
    'saharien',
    'sahélien',
    'saignais',
    'saignait',
    'saignant',
    'saignées',
    'saignent',
    'saignera',
    'saigneur',
    'saigneux',
    'saigniez',
    'saignoir',
    'saignons',
    'saillais',
    'saillait',
    'saillant',
    'saillent',
    'saillies',
    'sailliez',
    'saillira',
    'saillons',
    'saïmiris',
    'sainbois',
    'saindoux',
    'sainfoin',
    'sainteté',
    'saisîmes',
    'saisines',
    'saisirai',
    'saisiras',
    'saisirez',
    'saisisse',
    'saisîtes',
    'saisonna',
    'saisonne',
    'saisonné',
    'salacité',
    'saladero',
    'saladier',
    'salaient',
    'salaires',
    'salaison',
    'salariai',
    'salarial',
    'salarias',
    'salariat',
    'salariât',
    'salariée',
    'salarier',
    'salaries',
    'salariés',
    'salariez',
    'salasses',
    'salement',
    'salerais',
    'salerait',
    'salèrent',
    'saleriez',
    'salerons',
    'saleront',
    'salésien',
    'saleuses',
    'salicine',
    'salicole',
    'salienne',
    'salières',
    'salifère',
    'salifiai',
    'salifias',
    'salifiât',
    'salifiée',
    'salifier',
    'salifies',
    'salifiés',
    'salifiez',
    'saligaud',
    'salignon',
    'salinage',
    'salinité',
    'saliques',
    'salirais',
    'salirait',
    'salirent',
    'saliriez',
    'salirons',
    'saliront',
    'salisses',
    'salissez',
    'salisson',
    'salivais',
    'salivait',
    'salivant',
    'salivent',
    'salivera',
    'saliviez',
    'salivons',
    'salonard',
    'salopais',
    'salopait',
    'salopant',
    'salopard',
    'salopées',
    'salopent',
    'salopera',
    'salopiez',
    'salopiot',
    'salopons',
    'salpêtra',
    'salpêtre',
    'salpêtré',
    'salpicon',
    'salsifis',
    'saluâmes',
    'saluasse',
    'saluâtes',
    'salubres',
    'saluerai',
    'salueras',
    'saluerez',
    'saluions',
    'samarium',
    'samouraï',
    'samovars',
    'samoyède',
    'sampangs',
    'samurais',
    'sancerre',
    'sanction',
    'sandales',
    'sandjaks',
    'sandwich',
    'sanglais',
    'sanglait',
    'sanglant',
    'sanglées',
    'sanglent',
    'sanglera',
    'sanglier',
    'sangliez',
    'sanglons',
    'sanglota',
    'sanglote',
    'sangloté',
    'sanglots',
    'sangrias',
    'sangsues',
    'sanguine',
    'sanguins',
    'sanicles',
    'sanieuse',
    'sanscrit',
    'sans-fil',
    'sanskrit',
    'santonna',
    'santonne',
    'santonné',
    'saoudien',
    'saoudite',
    'saoulais',
    'saoulait',
    'saoulant',
    'saoulées',
    'saoulent',
    'saoulera',
    'saouliez',
    'saoulons',
    'sapaient',
    'sapajous',
    'sapasses',
    'sapement',
    'sapèques',
    'saperais',
    'saperait',
    'saperdes',
    'sapèrent',
    'saperiez',
    'saperons',
    'saperont',
    'saphènes',
    'saphique',
    'saphisme',
    'sapidité',
    'sapience',
    'saponacé',
    'saponine',
    'sapotier',
    'sapristi',
    'saquâmes',
    'saquasse',
    'saquâtes',
    'saquerai',
    'saqueras',
    'saquerez',
    'saquions',
    'sarcasme',
    'sarcelle',
    'sarclage',
    'sarclais',
    'sarclait',
    'sarclant',
    'sarclées',
    'sarclent',
    'sarclera',
    'sarcleur',
    'sarcliez',
    'sarcloir',
    'sarclons',
    'sarcoïde',
    'sarcomes',
    'sarcopte',
    'sardanes',
    'sardines',
    'sardoine',
    'sardonyx',
    'sargasse',
    'sarigues',
    'sarisses',
    'sarmenta',
    'sarmente',
    'sarmenté',
    'sarments',
    'sarrasin',
    'sarrette',
    'sassâmes',
    'sassasse',
    'sassâtes',
    'sasserai',
    'sasseras',
    'sasserez',
    'sasseurs',
    'sasseuse',
    'sassions',
    'satanais',
    'satanait',
    'satanant',
    'satanées',
    'satanent',
    'satanera',
    'sataniez',
    'satanons',
    'satiétés',
    'satinage',
    'satinais',
    'satinait',
    'satinant',
    'satinées',
    'satinent',
    'satinera',
    'satineur',
    'satiniez',
    'satinons',
    'satirisa',
    'satirise',
    'satirisé',
    'satisfis',
    'satisfit',
    'satisfît',
    'satonnai',
    'satonnas',
    'satonnât',
    'satonnée',
    'satonner',
    'satonnes',
    'satonnés',
    'satonnez',
    'satrapes',
    'satrapie',
    'saturais',
    'saturait',
    'saturant',
    'saturées',
    'saturent',
    'saturera',
    'saturiez',
    'saturnes',
    'saturnie',
    'saturnin',
    'saturons',
    'sauçâmes',
    'sauçasse',
    'sauçâtes',
    'saucerai',
    'sauceras',
    'saucerez',
    'saucière',
    'sauciers',
    'saucions',
    'saucisse',
    'saugrenu',
    'saulaies',
    'saumâtre',
    'saumonée',
    'saumonés',
    'saumurai',
    'saumuras',
    'saumurât',
    'saumurée',
    'saumurer',
    'saumures',
    'saumurés',
    'saumurez',
    'saunages',
    'saunâmes',
    'saunasse',
    'saunâtes',
    'saunerai',
    'sauneras',
    'saunerez',
    'saunière',
    'sauniers',
    'saunions',
    'saurâmes',
    'saurasse',
    'saurâtes',
    'saurerai',
    'saureras',
    'saurerez',
    'sauriens',
    'saurîmes',
    'saurions',
    'saurirai',
    'sauriras',
    'saurirez',
    'saurisse',
    'saurîtes',
    'saussaie',
    'sautâmes',
    'sautasse',
    'sautâtes',
    'sautelle',
    'sauterai',
    'sauteras',
    'sauterez',
    'sauterie',
    'sauteurs',
    'sauteuse',
    'sautilla',
    'sautille',
    'sautillé',
    'sautions',
    'sautoirs',
    'sauvages',
    'sauvagin',
    'sauvâmes',
    'sauvasse',
    'sauvâtes',
    'sauverai',
    'sauveras',
    'sauverez',
    'sauvetés',
    'sauvette',
    'sauveurs',
    'sauvions',
    'savaient',
    'savantes',
    'savarins',
    'savetier',
    'savonnai',
    'savonnas',
    'savonnât',
    'savonnée',
    'savonner',
    'savonnes',
    'savonnés',
    'savonnez',
    'savourai',
    'savouras',
    'savourât',
    'savourée',
    'savourer',
    'savoures',
    'savourés',
    'savourez',
    'savoyard',
    'saxatile',
    'saxhorns',
    'saxicole',
    'saxonnes',
    'saynètes',
    'scabieux',
    'scabreux',
    'scalaire',
    'scalènes',
    'scalpais',
    'scalpait',
    'scalpant',
    'scalpées',
    'scalpels',
    'scalpent',
    'scalpera',
    'scalpiez',
    'scalpons',
    'scandais',
    'scandait',
    'scandale',
    'scandant',
    'scandées',
    'scandent',
    'scandera',
    'scandiez',
    'scandium',
    'scandons',
    'scanners',
    'scansion',
    'scaphite',
    'scarabée',
    'scarieux',
    'scaroles',
    'scélérat',
    'scellage',
    'scellais',
    'scellait',
    'scellant',
    'scellées',
    'scellent',
    'scellera',
    'scelliez',
    'scellons',
    'scénario',
    'scénique',
    'sceptres',
    'schappes',
    'schapska',
    'scheidai',
    'scheidas',
    'scheidât',
    'scheidée',
    'scheider',
    'scheides',
    'scheidés',
    'scheidez',
    'schelems',
    'scherzos',
    'schismes',
    'schistes',
    'schlague',
    'schlamms',
    'schlichs',
    'schlitta',
    'schlitte',
    'schlitté',
    'schnocks',
    'schnoque',
    'schnouff',
    'schofars',
    'schooner',
    'schorres',
    'sciables',
    'sciaient',
    'sciasses',
    'sciences',
    'scierais',
    'scierait',
    'scièrent',
    'scieries',
    'scieriez',
    'scierons',
    'scieront',
    'scieuses',
    'scindais',
    'scindait',
    'scindant',
    'scindées',
    'scindent',
    'scindera',
    'scindiez',
    'scindons',
    'sciottai',
    'sciottas',
    'sciottât',
    'sciottée',
    'sciotter',
    'sciottes',
    'sciottés',
    'sciottez',
    'scissile',
    'scission',
    'scissure',
    'scléreux',
    'sclérosa',
    'sclérose',
    'sclérosé',
    'scolaire',
    'scoliose',
    'scolytes',
    'scooters',
    'scorbuts',
    'scoriacé',
    'scorpion',
    'scotchai',
    'scotchas',
    'scotchât',
    'scotchée',
    'scotcher',
    'scotches',
    'scotchés',
    'scotchez',
    'scotisme',
    'scotiste',
    'scottish',
    'scoureds',
    'Scrabble',
    'scrapers',
    'scratcha',
    'scratche',
    'scratché',
    'scratchs',
    'scribans',
    'scriptes',
    'scrofule',
    'scrubber',
    'scrupule',
    'scrutais',
    'scrutait',
    'scrutant',
    'scrutées',
    'scrutent',
    'scrutera',
    'scrutiez',
    'scrutins',
    'scrutons',
    'sculptai',
    'sculptas',
    'sculptât',
    'sculptée',
    'sculpter',
    'sculptes',
    'sculptés',
    'sculptez',
    'sébacées',
    'sébastes',
    'sécables',
    'sécantes',
    'sécateur',
    'séchages',
    'séchâmes',
    'séchasse',
    'séchâtes',
    'sécherai',
    'sécheras',
    'sécherez',
    'sécherie',
    'sécheurs',
    'sécheuse',
    'séchions',
    'séchoirs',
    'secondai',
    'secondas',
    'secondât',
    'secondée',
    'seconder',
    'secondes',
    'secondés',
    'secondez',
    'secouais',
    'secouait',
    'secouant',
    'secouées',
    'secouent',
    'secouera',
    'secoueur',
    'secouiez',
    'secouons',
    'secoures',
    'secourez',
    'secourir',
    'secourra',
    'secourue',
    'secourus',
    'secourut',
    'secourût',
    'secousse',
    'sécrétai',
    'sécrétas',
    'sécrétât',
    'sécrétée',
    'sécréter',
    'secrètes',
    'sécrétés',
    'sécrètes',
    'sécrétez',
    'sectaire',
    'secteurs',
    'sections',
    'séculier',
    'sécurisa',
    'sécurise',
    'sécurisé',
    'sécurité',
    'sédatifs',
    'sédative',
    'sédiment',
    'sédition',
    'séduirai',
    'séduiras',
    'séduirez',
    'séduises',
    'séduisez',
    'séduisis',
    'séduisit',
    'séduisît',
    'séduites',
    'séfarade',
    'segmenta',
    'segmente',
    'segmenté',
    'segments',
    'ségrégée',
    'seigneur',
    'seillons',
    'seizième',
    'séjourna',
    'séjourne',
    'séjourné',
    'sélacien',
    'sélectif',
    'sélénite',
    'sélénium',
    'sellâmes',
    'sellasse',
    'sellâtes',
    'sellerai',
    'selleras',
    'sellerez',
    'sellerie',
    'sellette',
    'selliers',
    'sellions',
    'semaient',
    'semaines',
    'semasses',
    'semblais',
    'semblait',
    'semblant',
    'semblent',
    'semblera',
    'sembliez',
    'semblons',
    'semelles',
    'semences',
    'sèmerais',
    'sèmerait',
    'semèrent',
    'sèmeriez',
    'sèmerons',
    'sèmeront',
    'semestre',
    'semeuses',
    'sémillon',
    'séminale',
    'séminaux',
    'semonçai',
    'semonças',
    'semonçât',
    'semoncée',
    'semoncer',
    'semonces',
    'semoncés',
    'semoncez',
    'semoules',
    'sénateur',
    'sénéchal',
    'sénestre',
    'sénilité',
    'senseurs',
    'sensible',
    'sensitif',
    'sensuels',
    'sentence',
    'senteurs',
    'sentiers',
    'sentîmes',
    'sentines',
    'sentions',
    'sentirai',
    'sentiras',
    'sentirez',
    'sentisse',
    'sentîtes',
    'séparais',
    'séparait',
    'séparant',
    'séparées',
    'séparent',
    'séparera',
    'sépariez',
    'séparons',
    'septains',
    'septante',
    'septidis',
    'septième',
    'septimes',
    'septique',
    'septuors',
    'septupla',
    'septuple',
    'septuplé',
    'sépulcre',
    'séquelle',
    'séquence',
    'séquoias',
    'seraient',
    'serai-je',
    'sérançai',
    'séranças',
    'sérançât',
    'sérancée',
    'sérancer',
    'sérances',
    'sérancés',
    'sérancez',
    'séraphin',
    'seras-tu',
    'serdeaus',
    'sereines',
    'sérénade',
    'sérénité',
    'séreuses',
    'serfouie',
    'serfouir',
    'serfouis',
    'serfouit',
    'serfouît',
    'sergents',
    'sergette',
    'sériâmes',
    'sériasse',
    'sériâtes',
    'sérielle',
    'sérierai',
    'sérieras',
    'sérierez',
    'sérieuse',
    'sériions',
    'serinais',
    'serinait',
    'serinant',
    'serinées',
    'serinent',
    'serinera',
    'seringas',
    'seringat',
    'seringua',
    'seringue',
    'seringué',
    'seriniez',
    'serinons',
    'sériques',
    'serments',
    'sermonna',
    'sermonne',
    'sermonné',
    'sérosité',
    'serpenta',
    'serpente',
    'serpenté',
    'serpents',
    'serpette',
    'serpolet',
    'serpules',
    'serrages',
    'serrâmes',
    'serrasse',
    'serrates',
    'serrâtes',
    'serrerai',
    'serreras',
    'serrerez',
    'serrions',
    'serrures',
    'sertîmes',
    'sertirai',
    'sertiras',
    'sertirez',
    'sertisse',
    'sertîtes',
    'servages',
    'servante',
    'servants',
    'serveurs',
    'serveuse',
    'services',
    'serviles',
    'servîmes',
    'servions',
    'servirai',
    'serviras',
    'servirez',
    'servisse',
    'servîtes',
    'sesbania',
    'sesbanie',
    'sessiles',
    'sessions',
    'sesterce',
    'sétacées',
    'seulette',
    'sévérité',
    'sévirais',
    'sévirait',
    'sévirent',
    'séviriez',
    'sévirons',
    'séviront',
    'sévisses',
    'sévissez',
    'sevrages',
    'sevrâmes',
    'sevrasse',
    'sevrâtes',
    'sevrerai',
    'sevreras',
    'sevrerez',
    'sevrions',
    'sexismes',
    'sexistes',
    'sex-shop',
    'sextants',
    'sextidis',
    'sextines',
    'sextolet',
    'sextupla',
    'sextuple',
    'sextuplé',
    'sexuelle',
    'seyaient',
    'seyantes',
    'sfumatos',
    'shantung',
    'shetland',
    'shilling',
    'shocking',
    'shogouns',
    'shootais',
    'shootait',
    'shootant',
    'shootées',
    'shootent',
    'shootera',
    'shootiez',
    'shootons',
    'shopings',
    'shopping',
    'shrapnel',
    'shuntais',
    'shuntait',
    'shuntant',
    'shuntées',
    'shuntent',
    'shuntera',
    'shuntiez',
    'shuntons',
    'siamoise',
    'sibérien',
    'sibilant',
    'sibylles',
    'sibyllin',
    'sicaires',
    'siccatif',
    'siccités',
    'sicilien',
    'side-car',
    'sidérais',
    'sidérait',
    'sidérale',
    'sidérant',
    'sidéraux',
    'sidérées',
    'sidèrent',
    'sidérera',
    'sidériez',
    'sidérite',
    'sidérons',
    'sidérose',
    'siégeais',
    'siégeait',
    'siégeant',
    'siégeons',
    'siégerai',
    'siégeras',
    'siégerez',
    'siégions',
    'sifflage',
    'sifflais',
    'sifflait',
    'sifflant',
    'sifflées',
    'sifflent',
    'sifflera',
    'sifflets',
    'siffleur',
    'siffliez',
    'sifflons',
    'sifflota',
    'sifflote',
    'siffloté',
    'sifilets',
    'sigillée',
    'sigillés',
    'sigisbée',
    'sigmoïde',
    'signalai',
    'signalas',
    'signalât',
    'signalée',
    'signaler',
    'signales',
    'signalés',
    'signalez',
    'signâmes',
    'signasse',
    'signâtes',
    'signerai',
    'signeras',
    'signerez',
    'signifia',
    'signifie',
    'signifié',
    'signions',
    'silences',
    'silicate',
    'siliceux',
    'silicium',
    'silicona',
    'silicone',
    'siliconé',
    'silicose',
    'silicule',
    'silionne',
    'siliques',
    'sillages',
    'sillonna',
    'sillonne',
    'sillonné',
    'silotage',
    'silurien',
    'simagrée',
    'simarres',
    'simaruba',
    'simbleau',
    'simienne',
    'similisa',
    'similise',
    'similisé',
    'similors',
    'simonies',
    'simplets',
    'simplexe',
    'simulais',
    'simulait',
    'simulant',
    'simulées',
    'simulent',
    'simulera',
    'simuliez',
    'simulons',
    'sinapisé',
    'sincères',
    'sinciput',
    'sinécure',
    'singeais',
    'singeait',
    'singeant',
    'singeons',
    'singerai',
    'singeras',
    'singerez',
    'singerie',
    'singions',
    'sinisais',
    'sinisait',
    'sinisant',
    'sinisées',
    'sinisent',
    'sinisera',
    'sinisiez',
    'sinisons',
    'sinistre',
    'sinistré',
    'sinueuse',
    'sinusite',
    'sionisme',
    'sioniste',
    'siphoïde',
    'siphonna',
    'siphonne',
    'siphonné',
    'siroccos',
    'sirotais',
    'sirotait',
    'sirotant',
    'sirotées',
    'sirotent',
    'sirotera',
    'sirotiez',
    'sirotons',
    'sirupeux',
    'sirvente',
    'sismales',
    'sismique',
    'sisymbre',
    'sittelle',
    'situâmes',
    'situasse',
    'situâtes',
    'situerai',
    'situeras',
    'situerez',
    'situions',
    'sixièmes',
    'sizerins',
    'skatings',
    'sketches',
    'skiables',
    'skiaient',
    'skiasses',
    'skierais',
    'skierait',
    'skièrent',
    'skieriez',
    'skierons',
    'skieront',
    'skieuses',
    'skippers',
    'slalomai',
    'slalomas',
    'slalomât',
    'slalomer',
    'slalomes',
    'slalomez',
    'slavisai',
    'slavisas',
    'slavisât',
    'slavisée',
    'slaviser',
    'slavises',
    'slavisés',
    'slavisez',
    'slaviste',
    'sleeping',
    'sloughis',
    'slovaque',
    'slovènes',
    'smaltine',
    'smashais',
    'smashait',
    'smashant',
    'smashées',
    'smashent',
    'smashera',
    'smashiez',
    'smashons',
    'smicarde',
    'smicards',
    'smillage',
    'smillais',
    'smillait',
    'smillant',
    'smillées',
    'smillent',
    'smillera',
    'smilliez',
    'smillons',
    'smokings',
    'sniffais',
    'sniffait',
    'sniffant',
    'sniffées',
    'sniffent',
    'sniffera',
    'sniffiez',
    'sniffons',
    'snobâmes',
    'snobasse',
    'snobâtes',
    'snoberai',
    'snoberas',
    'snoberez',
    'snobions',
    'snobisme',
    'sobriété',
    'sociable',
    'sociales',
    'sociétés',
    'sodiques',
    'sodomies',
    'sodomisa',
    'sodomise',
    'sodomisé',
    'sodomite',
    'soffites',
    'software',
    'soieries',
    'soiffard',
    'soignais',
    'soignait',
    'soignant',
    'soignées',
    'soignent',
    'soignera',
    'soigneur',
    'soigneux',
    'soigniez',
    'soignons',
    'soi-même',
    'soixante',
    'solaires',
    'solarisa',
    'solarise',
    'solarisé',
    'solarium',
    'soldâmes',
    'soldasse',
    'soldates',
    'soldâtes',
    'solderai',
    'solderas',
    'solderez',
    'soldeurs',
    'soldeuse',
    'soldions',
    'soléaire',
    'solennel',
    'solerets',
    'solfèges',
    'solfiais',
    'solfiait',
    'solfiant',
    'solfiées',
    'solfient',
    'solfiera',
    'solfiiez',
    'solfions',
    'solidage',
    'solidité',
    'soliflua',
    'soliflue',
    'soliflué',
    'solipède',
    'solistes',
    'solitude',
    'soliveau',
    'solmisai',
    'solmisas',
    'solmisât',
    'solmisée',
    'solmiser',
    'solmises',
    'solmisés',
    'solmisez',
    'solstice',
    'solubles',
    'solution',
    'solvable',
    'solvants',
    'somation',
    'somatisa',
    'somatise',
    'somatisé',
    'sombrais',
    'sombrait',
    'sombrant',
    'sombrent',
    'sombrera',
    'sombrero',
    'sombriez',
    'sombrons',
    'sommaire',
    'sommâmes',
    'sommasse',
    'sommâtes',
    'sommeils',
    'sommerai',
    'sommeras',
    'sommerez',
    'sommiers',
    'sommions',
    'sommital',
    'sommités',
    'somnolai',
    'somnolas',
    'somnolât',
    'somnoler',
    'somnoles',
    'somnolez',
    'sonatine',
    'sondages',
    'sondâmes',
    'sondasse',
    'sondâtes',
    'sonderai',
    'sonderas',
    'sonderez',
    'sondeurs',
    'sondeuse',
    'sondions',
    'songeais',
    'songeait',
    'songeant',
    'songeons',
    'songerai',
    'songeras',
    'songerez',
    'songerie',
    'songeurs',
    'songeuse',
    'songions',
    'soniques',
    'sonnâmes',
    'sonnante',
    'sonnants',
    'sonnasse',
    'sonnâtes',
    'sonnerai',
    'sonneras',
    'sonnerez',
    'sonnerie',
    'sonnette',
    'sonneurs',
    'sonnions',
    'sonorisa',
    'sonorise',
    'sonorisé',
    'sonorité',
    'sont-ils',
    'sophisme',
    'sophiste',
    'sophoras',
    'sopranos',
    'sorbiers',
    'sorbitol',
    'sorcière',
    'sorciers',
    'sordides',
    'sorguais',
    'sorguait',
    'sorguant',
    'sorguent',
    'sorguera',
    'sorguiez',
    'sorguons',
    'sornette',
    'sororale',
    'sororaux',
    'sortable',
    'sortante',
    'sortants',
    'sortîmes',
    'sortions',
    'sortirai',
    'sortiras',
    'sortirez',
    'sortisse',
    'sortîtes',
    'sottises',
    'souahéli',
    'soucheta',
    'soucheté',
    'souchets',
    'soucheva',
    'souchevé',
    'souchève',
    'souciais',
    'souciait',
    'souciant',
    'souciées',
    'soucient',
    'souciera',
    'soucieux',
    'souciiez',
    'soucions',
    'soucoupe',
    'soudable',
    'soudages',
    'soudaine',
    'soudains',
    'soudâmes',
    'soudante',
    'soudants',
    'soudards',
    'soudasse',
    'soudâtes',
    'souderai',
    'souderas',
    'souderez',
    'soudeurs',
    'soudeuse',
    'soudière',
    'soudiers',
    'soudions',
    'soudoies',
    'soudoyai',
    'soudoyas',
    'soudoyât',
    'soudoyée',
    'soudoyer',
    'soudoyés',
    'soudoyez',
    'soudures',
    'souffert',
    'soufflai',
    'soufflas',
    'soufflât',
    'soufflée',
    'souffler',
    'souffles',
    'soufflés',
    'soufflet',
    'soufflez',
    'souffres',
    'souffrez',
    'souffrir',
    'souffris',
    'souffrit',
    'souffrît',
    'soufisme',
    'soufrage',
    'soufrais',
    'soufrait',
    'soufrant',
    'soufrées',
    'soufrent',
    'soufrera',
    'soufreur',
    'soufriez',
    'soufroir',
    'soufrons',
    'souhaita',
    'souhaite',
    'souhaité',
    'souhaits',
    'souillai',
    'souillas',
    'souillât',
    'souillée',
    'souiller',
    'souilles',
    'souillés',
    'souillez',
    'souillon',
    'soulagea',
    'soulagée',
    'soulager',
    'soulages',
    'soulagés',
    'soulagez',
    'soûlâmes',
    'soulanes',
    'soûlarde',
    'soûlards',
    'soûlasse',
    'soûlâtes',
    'soûlaude',
    'soûlauds',
    'soûlerai',
    'soûleras',
    'soûlerez',
    'soûlerie',
    'soulevai',
    'soulevas',
    'soulevât',
    'soulevée',
    'soulever',
    'soulevés',
    'soulèves',
    'soulevez',
    'souliers',
    'souligna',
    'souligne',
    'souligné',
    'soûlions',
    'soûlotes',
    'soumette',
    'soumîmes',
    'soumises',
    'soumisse',
    'soumîtes',
    'soupâmes',
    'soupapes',
    'soupasse',
    'soupâtes',
    'soupçons',
    'soupente',
    'souperai',
    'souperas',
    'souperez',
    'soupesai',
    'soupesas',
    'soupesât',
    'soupesée',
    'soupeser',
    'soupesés',
    'soupèses',
    'soupesez',
    'soupeurs',
    'soupeuse',
    'soupière',
    'soupions',
    'soupirai',
    'soupiras',
    'soupirât',
    'soupirer',
    'soupires',
    'soupirez',
    'souquais',
    'souquait',
    'souquant',
    'souquées',
    'souquent',
    'souquera',
    'souquiez',
    'souquons',
    'sourates',
    'sourcier',
    'sourcils',
    'sourdina',
    'sourdine',
    'sourdiné',
    'souriais',
    'souriait',
    'souriant',
    'sourient',
    'souriiez',
    'sourîmes',
    'sourions',
    'sourirai',
    'souriras',
    'sourires',
    'sourirez',
    'sourisse',
    'sourîtes',
    'sournois',
    'sous-bas',
    'souscris',
    'souscrit',
    'sous-off',
    'sous-sol',
    'soutacha',
    'soutache',
    'soutaché',
    'soutanes',
    'soutenez',
    'soutenir',
    'soutenue',
    'soutenus',
    'soutiens',
    'soutient',
    'soutiers',
    'soutirai',
    'soutiras',
    'soutirât',
    'soutirée',
    'soutirer',
    'soutires',
    'soutirés',
    'soutirez',
    'souvenez',
    'souvenir',
    'souvenue',
    'souvenus',
    'souviens',
    'souvient',
    'sovkhoze',
    'soyeuses',
    'spacieux',
    'spadices',
    'spalters',
    'spardeck',
    'spatiale',
    'spatiaux',
    'spatules',
    'speakers',
    'spéciale',
    'spéciaux',
    'spécieux',
    'spécifia',
    'spécifie',
    'spécifié',
    'spécimen',
    'spectral',
    'spectres',
    'spéculai',
    'spéculas',
    'spéculât',
    'spéculer',
    'spécules',
    'spéculez',
    'spéculum',
    'speeches',
    'spencers',
    'spergule',
    'sphacéla',
    'sphacélé',
    'sphacèle',
    'sphaigne',
    'spinales',
    'spinelle',
    'spiracle',
    'spirales',
    'spirante',
    'spirants',
    'spirifer',
    'spirille',
    'spirites',
    'spirorbe',
    'spitante',
    'spitants',
    'spittais',
    'spittait',
    'spittant',
    'spittées',
    'spittent',
    'spittera',
    'spittiez',
    'spittons',
    'splittai',
    'splittas',
    'splittât',
    'splittée',
    'splitter',
    'splittes',
    'splittés',
    'splittez',
    'spoliais',
    'spoliait',
    'spoliant',
    'spoliées',
    'spolient',
    'spoliera',
    'spoliiez',
    'spolions',
    'spondées',
    'sponsors',
    'spontané',
    'sporange',
    'sportifs',
    'sportive',
    'sportule',
    'sporulai',
    'sporulas',
    'sporulât',
    'sporuler',
    'sporules',
    'sporulez',
    'spoutnik',
    'sprintai',
    'sprintas',
    'sprintât',
    'sprinter',
    'sprintes',
    'sprintez',
    'spumeuse',
    'squameux',
    'squamule',
    'squatine',
    'squattai',
    'squattas',
    'squattât',
    'squattée',
    'squatter',
    'squattes',
    'squattés',
    'squattez',
    'squilles',
    'squirres',
    'squirrhe',
    'staccato',
    'staffais',
    'staffait',
    'staffant',
    'staffées',
    'staffent',
    'staffera',
    'staffeur',
    'staffiez',
    'staffons',
    'stagnais',
    'stagnait',
    'stagnant',
    'stagnent',
    'stagnera',
    'stagniez',
    'stagnons',
    'stakning',
    'staminal',
    'staminée',
    'staminés',
    'standard',
    'standing',
    'stariets',
    'starifia',
    'starifie',
    'starifié',
    'starisai',
    'starisas',
    'starisât',
    'starisée',
    'stariser',
    'starises',
    'starisés',
    'starisez',
    'staroste',
    'starters',
    'statères',
    'statices',
    'stations',
    'statique',
    'statives',
    'statuais',
    'statuait',
    'statuant',
    'statuées',
    'statuent',
    'statuera',
    'statufia',
    'statufie',
    'statufié',
    'statuiez',
    'statuons',
    'statures',
    'steamers',
    'stéarate',
    'stéarine',
    'stéatite',
    'stéatose',
    'steeples',
    'stellage',
    'stencils',
    'sténopés',
    'sténoses',
    'stentors',
    'steppage',
    'steppers',
    'steppeur',
    'stérâmes',
    'stérasse',
    'stérâtes',
    'stérerai',
    'stéreras',
    'stérerez',
    'stérides',
    'stériles',
    'stérilet',
    'stérions',
    'stérique',
    'sterlets',
    'sterling',
    'sternale',
    'sternaux',
    'sternums',
    'stéroïde',
    'stewards',
    'stibiées',
    'stibines',
    'stigmate',
    'stimulai',
    'stimulas',
    'stimulât',
    'stimulée',
    'stimuler',
    'stimules',
    'stimulés',
    'stimulez',
    'stimulus',
    'stipitée',
    'stipités',
    'stipulai',
    'stipulas',
    'stipulât',
    'stipulée',
    'stipuler',
    'stipules',
    'stipulés',
    'stipulez',
    'stockage',
    'stockais',
    'stockait',
    'stockant',
    'stockées',
    'stockent',
    'stockera',
    'stockiez',
    'stockons',
    'stoïcien',
    'stoïques',
    'stomacal',
    'stomates',
    'stomoxes',
    'stoppage',
    'stoppais',
    'stoppait',
    'stoppant',
    'stoppées',
    'stoppent',
    'stoppera',
    'stoppeur',
    'stoppiez',
    'stoppons',
    'storiste',
    'stradiot',
    'stratège',
    'stratums',
    'stressai',
    'stressas',
    'stressât',
    'stressée',
    'stresser',
    'stresses',
    'stressés',
    'stressez',
    'strettes',
    'striâmes',
    'striasse',
    'striâtes',
    'strictes',
    'strident',
    'stridors',
    'stridula',
    'stridule',
    'stridulé',
    'strierai',
    'strieras',
    'strierez',
    'strigile',
    'striions',
    'stripage',
    'strippai',
    'strippas',
    'strippât',
    'strippée',
    'stripper',
    'strippes',
    'strippés',
    'strippez',
    'striquai',
    'striquas',
    'striquât',
    'striquée',
    'striquer',
    'striques',
    'striqués',
    'striquez',
    'striures',
    'strobile',
    'strombes',
    'strongle',
    'strophes',
    'stucages',
    'studette',
    'studieux',
    'stupéfia',
    'stupéfie',
    'stupéfié',
    'stupeurs',
    'stupides',
    'stuquais',
    'stuquait',
    'stuquant',
    'stuquées',
    'stuquent',
    'stuquera',
    'stuquiez',
    'stuquons',
    'stylâmes',
    'stylasse',
    'stylâtes',
    'stylerai',
    'styleras',
    'stylerez',
    'stylions',
    'stylisai',
    'stylisas',
    'stylisât',
    'stylisée',
    'styliser',
    'stylises',
    'stylisés',
    'stylisez',
    'stylisme',
    'styliste',
    'stylites',
    'styloïde',
    'styrènes',
    'suassent',
    'suassiez',
    'suavités',
    'subaigue',
    'subaigus',
    'subalpin',
    'subéreux',
    'subérine',
    'subirais',
    'subirait',
    'subirent',
    'subiriez',
    'subirons',
    'subiront',
    'subisses',
    'subissez',
    'subjugua',
    'subjugue',
    'subjugué',
    'sublimai',
    'sublimas',
    'sublimât',
    'sublimée',
    'sublimer',
    'sublimes',
    'sublimés',
    'sublimez',
    'submerge',
    'submergé',
    'subodora',
    'subodore',
    'subodoré',
    'subornai',
    'subornas',
    'subornât',
    'subornée',
    'suborner',
    'subornes',
    'subornés',
    'subornez',
    'subrogea',
    'subrogée',
    'subroger',
    'subroges',
    'subrogés',
    'subrogez',
    'subsides',
    'subsista',
    'subsiste',
    'subsisté',
    'substrat',
    'subtiles',
    'subulées',
    'subvenez',
    'subvenir',
    'subvenue',
    'subvenus',
    'subverti',
    'subviens',
    'subvient',
    'suçaient',
    'suçasses',
    'succédai',
    'succédas',
    'succédât',
    'succédée',
    'succéder',
    'succédés',
    'succèdes',
    'succédez',
    'succinct',
    'succions',
    'succomba',
    'succombe',
    'succombé',
    'succubes',
    'sucement',
    'sucerais',
    'sucerait',
    'sucèrent',
    'suceriez',
    'sucerons',
    'suceront',
    'sucettes',
    'suceuses',
    'suçotais',
    'suçotait',
    'suçotant',
    'suçotées',
    'suçotent',
    'suçotera',
    'suçotiez',
    'suçotons',
    'sucrages',
    'sucrâmes',
    'sucrante',
    'sucrants',
    'sucrases',
    'sucrasse',
    'sucrates',
    'sucrâtes',
    'sucrerai',
    'sucreras',
    'sucrerez',
    'sucrerie',
    'sucrière',
    'sucriers',
    'sucrions',
    'sudation',
    'sudistes',
    'sudorale',
    'sudoraux',
    'suédines',
    'suédoise',
    'suerions',
    'suffètes',
    'suffîmes',
    'suffirai',
    'suffiras',
    'suffirez',
    'suffises',
    'suffisez',
    'suffisse',
    'suffites',
    'suffîtes',
    'suffixai',
    'suffixal',
    'suffixas',
    'suffixât',
    'suffixée',
    'suffixer',
    'suffixes',
    'suffixés',
    'suffixez',
    'suffoqua',
    'suffoque',
    'suffoqué',
    'suffrage',
    'suggérai',
    'suggéras',
    'suggérât',
    'suggérée',
    'suggérer',
    'suggérés',
    'suggères',
    'suggérez',
    'suicidai',
    'suicidas',
    'suicidât',
    'suicidée',
    'suicider',
    'suicides',
    'suicidés',
    'suicidez',
    'suifâmes',
    'suifasse',
    'suifâtes',
    'suiferai',
    'suiferas',
    'suiferez',
    'suiffais',
    'suiffait',
    'suiffant',
    'suiffées',
    'suiffent',
    'suiffera',
    'suiffeux',
    'suiffiez',
    'suiffons',
    'suifions',
    'suintais',
    'suintait',
    'suintant',
    'suintées',
    'suintent',
    'suintera',
    'suintiez',
    'suintons',
    'suivante',
    'suivants',
    'suiveurs',
    'suiveuse',
    'suivîmes',
    'suivions',
    'suivisme',
    'suivisse',
    'suiviste',
    'suivîtes',
    'suivrais',
    'suivrait',
    'suivriez',
    'suivrons',
    'suivront',
    'sujétion',
    'sujettes',
    'sulfatai',
    'sulfatas',
    'sulfatât',
    'sulfatée',
    'sulfater',
    'sulfates',
    'sulfatés',
    'sulfatez',
    'sulfitai',
    'sulfitas',
    'sulfitât',
    'sulfitée',
    'sulfiter',
    'sulfites',
    'sulfités',
    'sulfitez',
    'sulfonai',
    'sulfonas',
    'sulfonât',
    'sulfonée',
    'sulfoner',
    'sulfones',
    'sulfonés',
    'sulfonez',
    'sulfosel',
    'sulfurai',
    'sulfuras',
    'sulfurât',
    'sulfurée',
    'sulfurer',
    'sulfures',
    'sulfurés',
    'sulfurez',
    'sultanat',
    'sultanes',
    'sumérien',
    'sunlight',
    'sunnites',
    'superbes',
    'superfin',
    'superflu',
    'superman',
    'supermen',
    'suppléai',
    'suppléas',
    'suppléât',
    'suppléée',
    'suppléer',
    'supplées',
    'suppléés',
    'suppléez',
    'suppliai',
    'supplias',
    'suppliât',
    'supplice',
    'suppliée',
    'supplier',
    'supplies',
    'suppliés',
    'suppliez',
    'supporta',
    'supporte',
    'supporté',
    'supports',
    'supposai',
    'supposas',
    'supposât',
    'supposée',
    'supposer',
    'supposes',
    'supposés',
    'supposez',
    'supprima',
    'supprime',
    'supprimé',
    'suppurai',
    'suppuras',
    'suppurât',
    'suppurer',
    'suppures',
    'suppurez',
    'supputai',
    'supputas',
    'supputât',
    'supputée',
    'supputer',
    'supputes',
    'supputés',
    'supputez',
    'suprêmes',
    'suraiguë',
    'suraigus',
    'surannée',
    'surannés',
    'surboums',
    'surchoix',
    'surcoupa',
    'surcoupe',
    'surcoupé',
    'surcroît',
    'surdents',
    'surdités',
    'surdorai',
    'surdoras',
    'surdorât',
    'surdorée',
    'surdorer',
    'surdores',
    'surdorés',
    'surdorez',
    'surdouée',
    'surdoués',
    'suréleva',
    'surélevé',
    'surélève',
    'sûrement',
    'surettes',
    'surfaçai',
    'surfaças',
    'surfaçât',
    'surfacée',
    'surfacer',
    'surfaces',
    'surfacés',
    'surfacez',
    'surfaire',
    'surfaite',
    'surfaits',
    'surfâmes',
    'surfasse',
    'surfâtes',
    'surferai',
    'surferas',
    'surferez',
    'surfilai',
    'surfilas',
    'surfilât',
    'surfilée',
    'surfiler',
    'surfiles',
    'surfilés',
    'surfilez',
    'surfîmes',
    'surfines',
    'surfions',
    'surfisse',
    'surfîtes',
    'surfondu',
    'surgelai',
    'surgelas',
    'surgelât',
    'surgelée',
    'surgeler',
    'surgelés',
    'surgèles',
    'surgelez',
    'surgeons',
    'surgîmes',
    'surgirai',
    'surgiras',
    'surgirez',
    'surgisse',
    'surgîtes',
    'surglaça',
    'surglace',
    'surglacé',
    'surhomme',
    'suricate',
    'surikate',
    'surinais',
    'surinait',
    'surinant',
    'surinées',
    'surinent',
    'surinera',
    'suriniez',
    'surinons',
    'surirais',
    'surirait',
    'surirent',
    'suririez',
    'surirons',
    'suriront',
    'surisses',
    'surissez',
    'surjalai',
    'surjalas',
    'surjalât',
    'surjaler',
    'surjales',
    'surjalez',
    'surjetai',
    'surjetas',
    'surjetât',
    'surjetée',
    'surjeter',
    'surjetés',
    'surjetez',
    'surjette',
    'surliais',
    'surliait',
    'surliant',
    'surliées',
    'surlient',
    'surliera',
    'surliiez',
    'surlions',
    'surlonge',
    'surlouai',
    'surlouas',
    'surlouât',
    'surlouée',
    'surlouer',
    'surloues',
    'surloués',
    'surlouez',
    'surloyer',
    'surmenai',
    'surmenas',
    'surmenât',
    'surmenée',
    'surmener',
    'surmenés',
    'surmènes',
    'surmenez',
    'surmonta',
    'surmonte',
    'surmonté',
    'surmoula',
    'surmoule',
    'surmoulé',
    'surmulet',
    'surmulot',
    'surnagea',
    'surnager',
    'surnages',
    'surnagez',
    'surnomma',
    'surnomme',
    'surnommé',
    'suroffre',
    'suroxyda',
    'suroxyde',
    'suroxydé',
    'surpaies',
    'surpassa',
    'surpasse',
    'surpassé',
    'surpayai',
    'surpayas',
    'surpayât',
    'surpayée',
    'surpayer',
    'surpayes',
    'surpayés',
    'surpayez',
    'surpiqua',
    'surpique',
    'surpiqué',
    'surplace',
    'surplomb',
    'surprend',
    'surprime',
    'surprise',
    'sursauta',
    'sursaute',
    'sursauté',
    'sursauts',
    'sursemai',
    'sursemas',
    'sursemât',
    'sursemée',
    'sursemer',
    'sursemés',
    'sursèmes',
    'sursemez',
    'surseoir',
    'sursîmes',
    'sursises',
    'sursisse',
    'sursîtes',
    'sursoies',
    'sursoyez',
    'surtaxai',
    'surtaxas',
    'surtaxât',
    'surtaxée',
    'surtaxer',
    'surtaxes',
    'surtaxés',
    'surtaxez',
    'surtonte',
    'survécue',
    'survécus',
    'survécut',
    'survécût',
    'survenez',
    'survenir',
    'survente',
    'survenue',
    'survenus',
    'surviens',
    'survient',
    'survirai',
    'surviras',
    'survirât',
    'survirer',
    'survires',
    'survirez',
    'survives',
    'survivez',
    'survivra',
    'survivre',
    'survolai',
    'survolas',
    'survolât',
    'survolée',
    'survoler',
    'survoles',
    'survolés',
    'survolez',
    'survolta',
    'survolte',
    'survolté',
    'suscitai',
    'suscitas',
    'suscitât',
    'suscitée',
    'susciter',
    'suscites',
    'suscités',
    'suscitez',
    'susdites',
    'susnommé',
    'suspecta',
    'suspecte',
    'suspecté',
    'suspects',
    'suspende',
    'suspends',
    'suspendu',
    'suspense',
    'suspente',
    'sussions',
    'sustenta',
    'sustente',
    'sustenté',
    'susurrai',
    'susurras',
    'susurrât',
    'susurrée',
    'susurrer',
    'susurres',
    'susurrés',
    'susurrez',
    'susvisée',
    'susvisés',
    'suturais',
    'suturait',
    'suturale',
    'suturant',
    'suturaux',
    'suturées',
    'suturent',
    'suturera',
    'suturiez',
    'suturons',
    'suzerain',
    'svastika',
    'swahélie',
    'swahilie',
    'swastika',
    'sweaters',
    'swinguai',
    'swinguas',
    'swinguât',
    'swinguer',
    'swingues',
    'swinguez',
    'sybarite',
    'sycomore',
    'syénites',
    'syllabai',
    'syllabas',
    'syllabât',
    'syllabée',
    'syllaber',
    'syllabes',
    'syllabés',
    'syllabez',
    'syllabus',
    'syllepse',
    'sylphide',
    'sylvains',
    'symbiose',
    'symbiote',
    'symboles',
    'symétrie',
    'symphyse',
    'symptôme',
    'synapses',
    'syncopai',
    'syncopal',
    'syncopas',
    'syncopât',
    'syncopée',
    'syncoper',
    'syncopes',
    'syncopés',
    'syncopez',
    'syndical',
    'syndicat',
    'syndiqua',
    'syndique',
    'syndiqué',
    'syndrome',
    'synéchie',
    'synérèse',
    'synergie',
    'synodale',
    'synodaux',
    'synonyme',
    'synopses',
    'synopsie',
    'synopsis',
    'synovies',
    'syntagme',
    'syntaxes',
    'synthèse',
    'syntones',
    'syntonie',
    'syphilis',
    'syriaque',
    'syrienne',
    'syringes',
    'systèmes',
    'systyles',
    'tabagies',
    'tabassai',
    'tabassas',
    'tabassât',
    'tabassée',
    'tabasser',
    'tabasses',
    'tabassés',
    'tabassez',
    'tablâmes',
    'tablasse',
    'tablâtes',
    'tableaux',
    'tablerai',
    'tableras',
    'tablerez',
    'tablette',
    'tableurs',
    'tabliers',
    'tablions',
    'tabloïds',
    'tabouret',
    'tabulais',
    'tabulait',
    'tabulant',
    'tabulent',
    'tabulera',
    'tabuliez',
    'tabulons',
    'tachâmes',
    'tâchâmes',
    'tâchante',
    'tâchants',
    'tachasse',
    'tâchasse',
    'tachâtes',
    'tâchâtes',
    'tacherai',
    'tâcherai',
    'tacheras',
    'tâcheras',
    'tacherez',
    'tâcherez',
    'tâcheron',
    'tachetai',
    'tachetas',
    'tachetât',
    'tachetée',
    'tacheter',
    'tachetés',
    'tachetez',
    'tachette',
    'tachines',
    'tachions',
    'tâchions',
    'tachisme',
    'tachiste',
    'tachyons',
    'taconeos',
    'tactiles',
    'tactique',
    'tactisme',
    'tadornes',
    'taffetas',
    'tagettes',
    'tahitien',
    'taillada',
    'taillade',
    'tailladé',
    'taillage',
    'taillais',
    'taillait',
    'taillant',
    'taillées',
    'taillent',
    'taillera',
    'tailleur',
    'tailliez',
    'tailloir',
    'taillole',
    'taillons',
    'tairions',
    'taiseuse',
    'taisions',
    'talaient',
    'talasses',
    'talerais',
    'talerait',
    'talèrent',
    'taleriez',
    'talerons',
    'taleront',
    'talisman',
    'talitres',
    'tallages',
    'tallâmes',
    'tallasse',
    'tallâtes',
    'tallerai',
    'talleras',
    'tallerez',
    'talleths',
    'tallions',
    'tallipot',
    'talmouse',
    'talochai',
    'talochas',
    'talochât',
    'talochée',
    'talocher',
    'taloches',
    'talochés',
    'talochez',
    'talonnai',
    'talonnas',
    'talonnât',
    'talonnée',
    'talonner',
    'talonnes',
    'talonnés',
    'talonnez',
    'talpacks',
    'talquais',
    'talquait',
    'talquant',
    'talquées',
    'talquent',
    'talquera',
    'talqueux',
    'talquiez',
    'talquons',
    'tamandua',
    'tamanoir',
    'tamarins',
    'tambours',
    'tamisage',
    'tamisais',
    'tamisait',
    'tamisant',
    'tamisées',
    'tamisent',
    'tamisera',
    'tamiseur',
    'tamisier',
    'tamisiez',
    'tamisons',
    'tamoules',
    'tampicos',
    'tamponna',
    'tamponne',
    'tamponné',
    'tam-tams',
    'tanagras',
    'tanaisie',
    'tançâmes',
    'tançasse',
    'tançâtes',
    'tancerai',
    'tanceras',
    'tancerez',
    'tancions',
    'tangages',
    'tangaras',
    'tangence',
    'tangente',
    'tangents',
    'tangible',
    'tanguais',
    'tanguait',
    'tanguant',
    'tanguent',
    'tanguera',
    'tanguiez',
    'tanguons',
    'tanières',
    'tanisage',
    'tanisais',
    'tanisait',
    'tanisant',
    'tanisées',
    'tanisent',
    'tanisera',
    'tanisiez',
    'tanisons',
    'tankiste',
    'tannages',
    'tannâmes',
    'tannasse',
    'tannâtes',
    'tannerai',
    'tanneras',
    'tannerez',
    'tannerie',
    'tanneurs',
    'tanneuse',
    'tannions',
    'tannique',
    'tannisai',
    'tannisas',
    'tannisât',
    'tannisée',
    'tanniser',
    'tannises',
    'tannisés',
    'tannisez',
    'tan-sads',
    'tantales',
    'tantième',
    'tantines',
    'tantinet',
    'taoïsmes',
    'taôismes',
    'taoïstes',
    'taôistes',
    'tapageai',
    'tapageas',
    'tapageât',
    'tapagent',
    'tapagera',
    'tapageur',
    'tapagiez',
    'tapaient',
    'tapantes',
    'tapasses',
    'tape-cul',
    'tapeculs',
    'tapement',
    'tapenade',
    'taperais',
    'taperait',
    'tapèrent',
    'taperiez',
    'taperons',
    'taperont',
    'tapettes',
    'tapeuses',
    'tapinais',
    'tapinait',
    'tapinant',
    'tapinent',
    'tapinera',
    'tapiniez',
    'tapinois',
    'tapinons',
    'tapiocas',
    'tapirais',
    'tapirait',
    'tapirent',
    'tapiriez',
    'tapirons',
    'tapiront',
    'tapissai',
    'tapissas',
    'tapissât',
    'tapissée',
    'tapisser',
    'tapisses',
    'tapissés',
    'tapissez',
    'taponnai',
    'taponnas',
    'taponnât',
    'taponnée',
    'taponner',
    'taponnes',
    'taponnés',
    'taponnez',
    'tapotage',
    'tapotais',
    'tapotait',
    'tapotant',
    'tapotées',
    'tapotent',
    'tapotera',
    'tapotiez',
    'tapotons',
    'taquâmes',
    'taquasse',
    'taquâtes',
    'taquerai',
    'taqueras',
    'taquerez',
    'taquinai',
    'taquinas',
    'taquinât',
    'taquinée',
    'taquiner',
    'taquines',
    'taquinés',
    'taquinez',
    'taquions',
    'taquoirs',
    'taraient',
    'tararage',
    'tarasque',
    'tarasses',
    'taratata',
    'taraudai',
    'taraudas',
    'taraudât',
    'taraudée',
    'tarauder',
    'taraudes',
    'taraudés',
    'taraudez',
    'tarbouch',
    'tardâmes',
    'tardasse',
    'tardâtes',
    'tarderai',
    'tarderas',
    'tarderez',
    'tardions',
    'tardives',
    'tarentes',
    'tarerais',
    'tarerait',
    'tarèrent',
    'tareriez',
    'tarerons',
    'tareront',
    'targette',
    'targuais',
    'targuait',
    'targuant',
    'targuées',
    'targuent',
    'targuera',
    'targuiez',
    'targuons',
    'tarières',
    'tarifais',
    'tarifait',
    'tarifant',
    'tarifées',
    'tarifent',
    'tarifera',
    'tarifiai',
    'tarifias',
    'tarifiât',
    'tarifiée',
    'tarifier',
    'tarifies',
    'tarifiés',
    'tarifiez',
    'tarifons',
    'tarirais',
    'tarirait',
    'tarirent',
    'taririez',
    'tarirons',
    'tariront',
    'tarisses',
    'tarissez',
    'tarotées',
    'tarsales',
    'tarsiens',
    'tarsiers',
    'tartanes',
    'tartares',
    'tartarin',
    'tartîmes',
    'tartinai',
    'tartinas',
    'tartinât',
    'tartinée',
    'tartiner',
    'tartines',
    'tartinés',
    'tartinez',
    'tartirai',
    'tartiras',
    'tartirez',
    'tartisse',
    'tartîtes',
    'tartrate',
    'tartreux',
    'tartufes',
    'tartuffe',
    'tassages',
    'tassâmes',
    'tassasse',
    'tassâtes',
    'tasseaux',
    'tasserai',
    'tasseras',
    'tasserez',
    'tassette',
    'tassilis',
    'tassions',
    'tâtaient',
    'tâtasses',
    'tâterais',
    'tâterait',
    'tâtèrent',
    'tâteriez',
    'tâterons',
    'tâteront',
    'tâte-vin',
    'tatillon',
    'tâtonnai',
    'tâtonnas',
    'tâtonnât',
    'tâtonner',
    'tâtonnes',
    'tâtonnez',
    'tatouage',
    'tatouais',
    'tatouait',
    'tatouant',
    'tatouées',
    'tatouent',
    'tatouera',
    'tatoueur',
    'tatouiez',
    'tatouons',
    'taularde',
    'taulards',
    'taulière',
    'tauliers',
    'taupière',
    'taupiers',
    'taureaux',
    'taurines',
    'tavelais',
    'tavelait',
    'tavelant',
    'tavelées',
    'taveliez',
    'tavellai',
    'tavellas',
    'tavellât',
    'tavellée',
    'taveller',
    'tavelles',
    'tavellés',
    'tavellez',
    'tavelons',
    'tavelure',
    'tavernes',
    'tavillon',
    'taxables',
    'taxaient',
    'taxasses',
    'taxateur',
    'taxatifs',
    'taxation',
    'taxative',
    'taxerais',
    'taxerait',
    'taxèrent',
    'taxeriez',
    'taxerons',
    'taxeront',
    'taxiways',
    'taxodium',
    'tchadors',
    'tchèques',
    'tchitola',
    'technisa',
    'technise',
    'technisé',
    'tectrice',
    'tégument',
    'teignais',
    'teignait',
    'teignant',
    'teignent',
    'teigneux',
    'teigniez',
    'teignons',
    'teillage',
    'teillais',
    'teillait',
    'teillant',
    'teillées',
    'teillent',
    'teillera',
    'teilleur',
    'teilliez',
    'teillons',
    'teindrai',
    'teindras',
    'teindrez',
    'teintais',
    'teintait',
    'teintant',
    'teintées',
    'teintent',
    'teintera',
    'teintiez',
    'teintons',
    'teinture',
    'télamons',
    'téléfilm',
    'télémark',
    'téléskis',
    'télétype',
    'télévisa',
    'télévise',
    'télévisé',
    'télexais',
    'télexait',
    'télexant',
    'télexées',
    'télexent',
    'télexera',
    'télexiez',
    'télexons',
    'tellière',
    'tellures',
    'témérité',
    'témoigna',
    'témoigne',
    'témoigné',
    'tempérai',
    'tempéras',
    'tempérât',
    'tempérée',
    'tempérer',
    'tempérés',
    'tempères',
    'tempérez',
    'tempêtai',
    'tempêtas',
    'tempêtât',
    'tempêter',
    'tempêtes',
    'tempêtez',
    'templier',
    'temporal',
    'temporel',
    'tenables',
    'ténacité',
    'tenaient',
    'tenailla',
    'tenaille',
    'tenaillé',
    'tenantes',
    'tendance',
    'tendelle',
    'tenderie',
    'tendeurs',
    'tendeuse',
    'tendîmes',
    'tendions',
    'tendisse',
    'tendîtes',
    'tendoirs',
    'tendrais',
    'tendrait',
    'tendreté',
    'tendriez',
    'tendrons',
    'tendront',
    'ténèbres',
    'tènement',
    'ténesmes',
    'teneuses',
    'ténifuge',
    'tenonnai',
    'tenonnas',
    'tenonnât',
    'tenonnée',
    'tenonner',
    'tenonnes',
    'tenonnés',
    'tenonnez',
    'ténorino',
    'ténorisa',
    'ténorise',
    'ténorisé',
    'ténorite',
    'tensions',
    'tentâmes',
    'tentante',
    'tentants',
    'tentasse',
    'tentâtes',
    'tenterai',
    'tenteras',
    'tenterez',
    'tentions',
    'tentures',
    'ténuités',
    'tequilas',
    'terbines',
    'terbiums',
    'terçâmes',
    'terçasse',
    'terçâtes',
    'tercerai',
    'terceras',
    'tercerez',
    'tercions',
    'terminai',
    'terminal',
    'terminas',
    'terminât',
    'terminée',
    'terminer',
    'termines',
    'terminés',
    'terminez',
    'terminus',
    'termites',
    'ternaire',
    'ternîmes',
    'ternirai',
    'terniras',
    'ternirez',
    'ternisse',
    'ternîtes',
    'terpènes',
    'terpines',
    'terpinol',
    'terrages',
    'terrains',
    'terrâmes',
    'terraqué',
    'terrassa',
    'terrasse',
    'terrassé',
    'terrâtes',
    'terreaux',
    'terrerai',
    'terreras',
    'terrerez',
    'terreurs',
    'terreuse',
    'terrible',
    'terriens',
    'terriers',
    'terrifia',
    'terrifie',
    'terrifié',
    'terrîmes',
    'terrines',
    'terrions',
    'terrirai',
    'terriras',
    'terrirez',
    'terrisse',
    'terrîtes',
    'terroirs',
    'tersâmes',
    'tersasse',
    'tersâtes',
    'terserai',
    'terseras',
    'terserez',
    'tersions',
    'terzetto',
    'testable',
    'testacée',
    'testacés',
    'testages',
    'testâmes',
    'testasse',
    'testâtes',
    'testerai',
    'testeras',
    'testerez',
    'testeurs',
    'testions',
    'tétaient',
    'tétanies',
    'tétanisa',
    'tétanise',
    'tétanisé',
    'tétasses',
    'téterais',
    'téterait',
    'tétèrent',
    'téteriez',
    'téterons',
    'téteront',
    'têtières',
    'tétrades',
    'tétrodon',
    'teutonne',
    'textiles',
    'textuels',
    'texturai',
    'texturas',
    'texturât',
    'texturée',
    'texturer',
    'textures',
    'texturés',
    'texturez',
    'thalamus',
    'thallium',
    'thalwegs',
    'théatins',
    'théâtral',
    'théâtres',
    'thébaïde',
    'thébaine',
    'thébaïne',
    'thébains',
    'théières',
    'théismes',
    'théistes',
    'théorbes',
    'théorème',
    'théories',
    'théorisa',
    'théorise',
    'théorisé',
    'thérapie',
    'thermale',
    'thermaux',
    'thermies',
    'thermite',
    'thésarde',
    'thésards',
    'thétique',
    'théurgie',
    'thiamine',
    'thibaude',
    'thomiste',
    'thonaire',
    'thoniers',
    'thorines',
    'thorites',
    'thoriums',
    'thridace',
    'thriller',
    'thrombus',
    'thuliums',
    'thymines',
    'thymique',
    'thyroïde',
    'tibétain',
    'tibiales',
    'tictaqua',
    'tictaque',
    'tictaqué',
    'tiédasse',
    'tiédeurs',
    'tiédîmes',
    'tiédirai',
    'tiédiras',
    'tiédirez',
    'tiédisse',
    'tiédîtes',
    'tiendrai',
    'tiendras',
    'tiendrez',
    'tiennent',
    'tierçais',
    'tierçait',
    'tierçant',
    'tiercées',
    'tiercent',
    'tiercera',
    'tierciez',
    'tierçons',
    'tigelles',
    'tigettes',
    'tignasse',
    'tigrâmes',
    'tigrasse',
    'tigrâtes',
    'tigrerai',
    'tigreras',
    'tigrerez',
    'tigresse',
    'tigridie',
    'tigrions',
    'tilburys',
    'tillages',
    'tillâmes',
    'tillasse',
    'tillâtes',
    'tillerai',
    'tilleras',
    'tillerez',
    'tilleuls',
    'tillions',
    'timbales',
    'timbrage',
    'timbrais',
    'timbrait',
    'timbrant',
    'timbrées',
    'timbrent',
    'timbrera',
    'timbriez',
    'timbrons',
    'timidité',
    'timonier',
    'timorées',
    'tinamous',
    'tinettes',
    'tinssent',
    'tinssiez',
    'tintâmes',
    'tintante',
    'tintants',
    'tintasse',
    'tintâtes',
    'tinterai',
    'tinteras',
    'tinterez',
    'tintions',
    'tintouin',
    'tiquâmes',
    'tiquasse',
    'tiquâtes',
    'tiquerai',
    'tiqueras',
    'tiquerez',
    'tiquetée',
    'tiquetés',
    'tiqueurs',
    'tiqueuse',
    'tiquions',
    'tiraient',
    'tirailla',
    'tiraille',
    'tiraillé',
    'tirasses',
    'tire-jus',
    'tirelire',
    'tirerais',
    'tirerait',
    'tirèrent',
    'tireriez',
    'tirerons',
    'tireront',
    'tire-sou',
    'tirettes',
    'tireuses',
    'tisaient',
    'tisasses',
    'tiserais',
    'tiserait',
    'tisèrent',
    'tiseriez',
    'tiserons',
    'tiseront',
    'tisonnai',
    'tisonnas',
    'tisonnât',
    'tisonnée',
    'tisonner',
    'tisonnes',
    'tisonnés',
    'tisonnez',
    'tissages',
    'tissâmes',
    'tissasse',
    'tissâtes',
    'tisserai',
    'tisseras',
    'tisserez',
    'tisserin',
    'tisseurs',
    'tisseuse',
    'tissions',
    'tissures',
    'titillai',
    'titillas',
    'titillât',
    'titillée',
    'titiller',
    'titilles',
    'titillés',
    'titillez',
    'titrages',
    'titrâmes',
    'titrasse',
    'titrâtes',
    'titrerai',
    'titreras',
    'titrerez',
    'titrions',
    'titubais',
    'titubait',
    'titubant',
    'titubent',
    'titubera',
    'titubiez',
    'titubons',
    'toastais',
    'toastait',
    'toastant',
    'toastées',
    'toastent',
    'toastera',
    'toasters',
    'toasteur',
    'toastiez',
    'toastons',
    'toboggan',
    'tocantes',
    'tocardes',
    'toccatas',
    'toilâmes',
    'toilasse',
    'toilâtes',
    'toilerai',
    'toileras',
    'toilerez',
    'toilerie',
    'toiletta',
    'toilette',
    'toiletté',
    'toilions',
    'toi-même',
    'toisâmes',
    'toisasse',
    'toisâtes',
    'toiserai',
    'toiseras',
    'toiserez',
    'toisions',
    'toitures',
    'tolérais',
    'tolérait',
    'tolérant',
    'tolérées',
    'tolèrent',
    'tolérera',
    'tôleries',
    'tolériez',
    'tolérons',
    'tôlières',
    'toluènes',
    'tomahawk',
    'tomaient',
    'tomaison',
    'tomasses',
    'tombales',
    'tombâmes',
    'tombante',
    'tombants',
    'tombasse',
    'tombâtes',
    'tombeaux',
    'tombelle',
    'tomberai',
    'tomberas',
    'tomberez',
    'tombeurs',
    'tombions',
    'tombolas',
    'tomerais',
    'tomerait',
    'tomèrent',
    'tomeriez',
    'tomerons',
    'tomeront',
    'tomettes',
    'tommette',
    'tonalité',
    'tondages',
    'tondeurs',
    'tondeuse',
    'tondîmes',
    'tondions',
    'tondisse',
    'tondîtes',
    'tondrais',
    'tondrait',
    'tondriez',
    'tondrons',
    'tondront',
    'tonicité',
    'tonifiai',
    'tonifias',
    'tonifiât',
    'tonifiée',
    'tonifier',
    'tonifies',
    'tonifiés',
    'tonifiez',
    'toniques',
    'tonitrua',
    'tonitrue',
    'tonitrué',
    'tonnages',
    'tonneaux',
    'tonnelet',
    'tonnelle',
    'tonnerai',
    'tonneras',
    'tonnerez',
    'tonnerre',
    'tonnions',
    'tonsurai',
    'tonsuras',
    'tonsurât',
    'tonsurée',
    'tonsurer',
    'tonsures',
    'tonsurés',
    'tonsurez',
    'tontinai',
    'tontinas',
    'tontinât',
    'tontinée',
    'tontiner',
    'tontines',
    'tontinés',
    'tontinez',
    'tontisse',
    'tontures',
    'topaient',
    'topasses',
    'toperais',
    'toperait',
    'topèrent',
    'toperiez',
    'toperons',
    'toperont',
    'topettes',
    'topiques',
    'toponyme',
    'toquades',
    'toquâmes',
    'toquante',
    'toquards',
    'toquasse',
    'toquâtes',
    'toquerai',
    'toqueras',
    'toquerez',
    'toquions',
    'torchais',
    'torchait',
    'torchant',
    'torchées',
    'torchent',
    'torchera',
    'torchère',
    'torchiez',
    'torchons',
    'tordages',
    'tordante',
    'tordants',
    'tordeurs',
    'tordeuse',
    'tordîmes',
    'tordions',
    'tordisse',
    'tordîtes',
    'tordoirs',
    'tordrais',
    'tordrait',
    'tordriez',
    'tordrons',
    'tordront',
    'toréador',
    'toréâmes',
    'toréasse',
    'toréâtes',
    'toréerai',
    'toréeras',
    'toréerez',
    'toréions',
    'torgnole',
    'tornades',
    'toronnai',
    'toronnas',
    'toronnât',
    'toronner',
    'toronnes',
    'toronnez',
    'torpédos',
    'torpeurs',
    'torpides',
    'torpilla',
    'torpille',
    'torpillé',
    'torréfia',
    'torréfie',
    'torréfié',
    'torrents',
    'torrides',
    'torsadai',
    'torsadas',
    'torsadât',
    'torsadée',
    'torsader',
    'torsades',
    'torsadés',
    'torsadez',
    'torseurs',
    'torsions',
    'tortilla',
    'tortille',
    'tortillé',
    'tortorai',
    'tortoras',
    'tortorât',
    'tortorée',
    'tortorer',
    'tortores',
    'tortorés',
    'tortorez',
    'tortueux',
    'torturai',
    'torturas',
    'torturât',
    'torturée',
    'torturer',
    'tortures',
    'torturés',
    'torturez',
    'torysmes',
    'toscanes',
    'totalisa',
    'totalise',
    'totalisé',
    'totalité',
    'tôt-fait',
    'touaient',
    'touaille',
    'touaregs',
    'touasses',
    'touchais',
    'touchait',
    'touchant',
    'touchaux',
    'touchées',
    'touchent',
    'touchera',
    'toucheur',
    'touchiez',
    'touchons',
    'touerais',
    'touerait',
    'touèrent',
    'toueriez',
    'touerons',
    'toueront',
    'touffeur',
    'touffues',
    'touillai',
    'touillas',
    'touillât',
    'touillée',
    'touiller',
    'touilles',
    'touillés',
    'touillez',
    'toujours',
    'touloupe',
    'toundras',
    'toupilla',
    'toupille',
    'toupillé',
    'tourbais',
    'tourbait',
    'tourbant',
    'tourbent',
    'tourbera',
    'tourbeux',
    'tourbier',
    'tourbiez',
    'tourbons',
    'tourelle',
    'tourière',
    'touriers',
    'tourisme',
    'touriste',
    'tourment',
    'tournage',
    'tournais',
    'tournait',
    'tournant',
    'tournées',
    'tournent',
    'tournera',
    'tourneur',
    'tourniez',
    'tournoie',
    'tournois',
    'tournons',
    'tournoya',
    'tournoyé',
    'tournure',
    'tourteau',
    'touselle',
    'toussais',
    'toussait',
    'toussant',
    'toussent',
    'toussera',
    'tousseur',
    'toussiez',
    'toussons',
    'toussota',
    'toussote',
    'toussoté',
    'tout-fou',
    'toutimes',
    'toxémies',
    'toxicité',
    'toxicose',
    'toxiques',
    'traboula',
    'traboule',
    'traboulé',
    'traçages',
    'traçâmes',
    'tracanai',
    'tracanas',
    'tracanât',
    'tracanée',
    'tracaner',
    'tracanes',
    'tracanés',
    'tracanez',
    'tracassa',
    'tracasse',
    'tracassé',
    'traçasse',
    'traçâtes',
    'tracerai',
    'traceras',
    'traceret',
    'tracerez',
    'traceurs',
    'traceuse',
    'trachéal',
    'trachéen',
    'trachées',
    'trachome',
    'trachyte',
    'tracions',
    'tractais',
    'tractait',
    'tractant',
    'tractées',
    'tractent',
    'tractera',
    'tracteur',
    'tractiez',
    'tractifs',
    'traction',
    'tractive',
    'tractons',
    'traduira',
    'traduire',
    'traduise',
    'traduite',
    'traduits',
    'trafiqua',
    'trafique',
    'trafiqué',
    'tragédie',
    'tragique',
    'trahîmes',
    'trahirai',
    'trahiras',
    'trahirez',
    'trahison',
    'trahisse',
    'trahîtes',
    'trailles',
    'traînage',
    'traînais',
    'traînait',
    'traînant',
    'traînard',
    'traîneau',
    'traînées',
    'traînent',
    'traînera',
    'traîneur',
    'traîniez',
    'training',
    'traînons',
    'trairais',
    'trairait',
    'trairiez',
    'trairons',
    'trairont',
    'traitais',
    'traitait',
    'traitant',
    'traitées',
    'traitent',
    'traitera',
    'traiteur',
    'traitiez',
    'traitons',
    'traîtres',
    'tralalas',
    'tramails',
    'tramâmes',
    'tramasse',
    'tramâtes',
    'tramerai',
    'trameras',
    'tramerez',
    'traminot',
    'tramions',
    'tramways',
    'tranchai',
    'tranchas',
    'tranchât',
    'tranchée',
    'trancher',
    'tranches',
    'tranchés',
    'tranchet',
    'tranchez',
    'transats',
    'transept',
    'transfos',
    'transies',
    'transige',
    'transigé',
    'transira',
    'transita',
    'transite',
    'transité',
    'transits',
    'transmet',
    'transmis',
    'transmit',
    'transmît',
    'transmua',
    'transmue',
    'transmué',
    'trantran',
    'trapèzes',
    'trappeur',
    'traquais',
    'traquait',
    'traquant',
    'traquées',
    'traquent',
    'traquera',
    'traquets',
    'traqueur',
    'traquiez',
    'traquons',
    'travails',
    'travelos',
    'traversa',
    'traverse',
    'traversé',
    'travesti',
    'trayeurs',
    'trayeuse',
    'trayions',
    'trébucha',
    'trébuche',
    'trébuché',
    'tréfilai',
    'tréfilas',
    'tréfilât',
    'tréfilée',
    'tréfiler',
    'tréfiles',
    'tréfilés',
    'tréfilez',
    'tréflées',
    'tréfonds',
    'treilles',
    'treillis',
    'trekking',
    'trémails',
    'trématai',
    'trématas',
    'trématât',
    'trémater',
    'trémates',
    'trématez',
    'tremblai',
    'tremblas',
    'tremblât',
    'tremblée',
    'trembler',
    'trembles',
    'tremblés',
    'tremblez',
    'trémelle',
    'trémière',
    'trémolos',
    'trempage',
    'trempais',
    'trempait',
    'trempant',
    'trempées',
    'trempent',
    'trempera',
    'trempiez',
    'tremplin',
    'trempons',
    'trémulai',
    'trémulas',
    'trémulât',
    'trémulée',
    'trémuler',
    'trémules',
    'trémulés',
    'trémulez',
    'trentain',
    'trépanai',
    'trépanas',
    'trépanât',
    'trépanée',
    'trépaner',
    'trépanes',
    'trépanés',
    'trépanez',
    'trépangs',
    'trépassa',
    'trépasse',
    'trépassé',
    'tréphone',
    'trépidai',
    'trépidas',
    'trépidât',
    'trépidée',
    'trépider',
    'trépides',
    'trépidés',
    'trépidez',
    'trépieds',
    'trépigna',
    'trépigne',
    'trépigné',
    'tressage',
    'tressais',
    'tressait',
    'tressant',
    'tressées',
    'tressent',
    'tressera',
    'tressiez',
    'tressons',
    'tréteaux',
    'treuilla',
    'treuille',
    'treuillé',
    'trévirai',
    'tréviras',
    'trévirât',
    'trévirée',
    'trévirer',
    'trévires',
    'trévirés',
    'trévirez',
    'triacide',
    'triaient',
    'triaires',
    'triangle',
    'triasses',
    'tribades',
    'tribales',
    'triballa',
    'triballe',
    'triballé',
    'tribarts',
    'tribords',
    'tribunal',
    'tribunat',
    'tribunes',
    'trichais',
    'trichait',
    'trichant',
    'trichent',
    'trichera',
    'tricheur',
    'trichiez',
    'trichiné',
    'trichite',
    'trichoma',
    'trichome',
    'trichons',
    'tricorne',
    'tricotai',
    'tricotas',
    'tricotât',
    'tricotée',
    'tricoter',
    'tricotes',
    'tricotés',
    'tricotez',
    'tricouni',
    'trictrac',
    'tricycle',
    'tridacne',
    'tridenté',
    'tridents',
    'triennal',
    'trierais',
    'trierait',
    'trièrent',
    'trieriez',
    'trierons',
    'trieront',
    'trieuses',
    'trifides',
    'triglyph',
    'trigones',
    'trillais',
    'trillait',
    'trillant',
    'trillent',
    'trillera',
    'trilliez',
    'trillion',
    'trillons',
    'trilobée',
    'trilobés',
    'trilogie',
    'trimâmes',
    'trimaran',
    'trimarda',
    'trimarde',
    'trimardé',
    'trimards',
    'trimasse',
    'trimâtes',
    'trimbala',
    'trimbale',
    'trimbalé',
    'trimerai',
    'trimeras',
    'trimères',
    'trimerez',
    'trimètre',
    'trimions',
    'trinervé',
    'tringlai',
    'tringlas',
    'tringlât',
    'tringlée',
    'tringler',
    'tringles',
    'tringlés',
    'tringlez',
    'tringlot',
    'trinités',
    'trinquai',
    'trinquas',
    'trinquât',
    'trinquée',
    'trinquer',
    'trinques',
    'trinqués',
    'trinquez',
    'triolets',
    'triompha',
    'triomphe',
    'triomphé',
    'tripales',
    'tripangs',
    'triparti',
    'triperie',
    'tripette',
    'triphasé',
    'tripière',
    'tripiers',
    'triplace',
    'triplais',
    'triplait',
    'triplant',
    'triplées',
    'triplent',
    'triplera',
    'tripliez',
    'triplons',
    'triplure',
    'tripodie',
    'tripolis',
    'tripotai',
    'tripotas',
    'tripotât',
    'tripotée',
    'tripoter',
    'tripotes',
    'tripotés',
    'tripotez',
    'triquais',
    'triquait',
    'triquant',
    'triquées',
    'triquent',
    'triquera',
    'triquets',
    'triquiez',
    'triquons',
    'trirègne',
    'trirèmes',
    'triséqua',
    'triséqué',
    'trisèque',
    'trisomie',
    'trissais',
    'trissait',
    'trissant',
    'trissées',
    'trissent',
    'trissera',
    'trissiez',
    'trissons',
    'tritiums',
    'triturai',
    'trituras',
    'triturât',
    'triturée',
    'triturer',
    'tritures',
    'triturés',
    'triturez',
    'triumvir',
    'trivalve',
    'triviale',
    'triviaux',
    'trocarts',
    'trochées',
    'trochets',
    'trochile',
    'trochins',
    'trochlée',
    'trochure',
    'trognons',
    'trolleys',
    'trombine',
    'tromblon',
    'trombone',
    'trommels',
    'trompais',
    'trompait',
    'trompant',
    'trompées',
    'trompent',
    'trompera',
    'trompeta',
    'trompeté',
    'trompeur',
    'trompiez',
    'trompons',
    'trônâmes',
    'trônasse',
    'trônâtes',
    'tronches',
    'tronchet',
    'tronçons',
    'trônerai',
    'trôneras',
    'trônerez',
    'trônions',
    'tronquai',
    'tronquas',
    'tronquât',
    'tronquée',
    'tronquer',
    'tronques',
    'tronqués',
    'tronquez',
    'trophées',
    'tropical',
    'tropique',
    'tropisme',
    'troquais',
    'troquait',
    'troquant',
    'troquées',
    'troquent',
    'troquera',
    'troquets',
    'troqueur',
    'troquiez',
    'troquons',
    'trottais',
    'trottait',
    'trottant',
    'trottées',
    'trottent',
    'trottera',
    'trotteur',
    'trottiez',
    'trottina',
    'trottine',
    'trottiné',
    'trottins',
    'trottoir',
    'trottons',
    'trouâmes',
    'trouasse',
    'trouâtes',
    'troubade',
    'troublai',
    'troublas',
    'troublât',
    'troublée',
    'troubler',
    'troubles',
    'troublés',
    'troublez',
    'trouerai',
    'troueras',
    'trouerez',
    'troufion',
    'trouille',
    'trouions',
    'troupeau',
    'troupier',
    'troussai',
    'troussas',
    'troussât',
    'troussée',
    'trousser',
    'trousses',
    'troussés',
    'troussez',
    'trouvais',
    'trouvait',
    'trouvant',
    'trouvées',
    'trouvent',
    'trouvera',
    'trouvère',
    'trouveur',
    'trouviez',
    'trouvons',
    'troyenne',
    'truandai',
    'truandas',
    'truandât',
    'truandée',
    'truander',
    'truandes',
    'truandés',
    'truandez',
    'trublion',
    'trucages',
    'trucidai',
    'trucidas',
    'trucidât',
    'trucidée',
    'trucider',
    'trucides',
    'trucidés',
    'trucidez',
    'trudgeon',
    'truellée',
    'truelles',
    'truffais',
    'truffait',
    'truffant',
    'truffées',
    'truffent',
    'truffera',
    'truffier',
    'truffiez',
    'truffons',
    'truismes',
    'truitées',
    'trumeaux',
    'truquage',
    'truquais',
    'truquait',
    'truquant',
    'truquées',
    'truquent',
    'truquera',
    'truqueur',
    'truquiez',
    'truquons',
    'trusquin',
    'trustais',
    'trustait',
    'trustant',
    'trustées',
    'trustent',
    'trustera',
    'trusteur',
    'trustiez',
    'trustons',
    'trypsine',
    'tsarines',
    'tsarisme',
    'tsariste',
    't-shirts',
    'tsiganes',
    'tsunamis',
    'tuassent',
    'tuassiez',
    'tubaient',
    'tubaires',
    'tubardes',
    'tubasses',
    'tubéracé',
    'tuberais',
    'tuberait',
    'tubèrent',
    'tubéreux',
    'tuberiez',
    'tubérisé',
    'tuberons',
    'tuberont',
    'tubicole',
    'tubipore',
    'tubistes',
    'tubulées',
    'tubuleux',
    'tubulure',
    'tudesque',
    'tue-loup',
    'tuerions',
    'tue-tête',
    'tuffeaux',
    'tufières',
    'tuilâmes',
    'tuilasse',
    'tuilâtes',
    'tuileaux',
    'tuilerai',
    'tuileras',
    'tuilerez',
    'tuilerie',
    'tuilette',
    'tuilière',
    'tuiliers',
    'tuilions',
    'tulipier',
    'tullerie',
    'tullière',
    'tulliers',
    'tulliste',
    'tuméfiai',
    'tuméfias',
    'tuméfiât',
    'tuméfiée',
    'tuméfier',
    'tuméfies',
    'tuméfiés',
    'tuméfiez',
    'tumorale',
    'tumoraux',
    'tumultes',
    'tuniquée',
    'tuniques',
    'tuniqués',
    'tunisien',
    'turbides',
    'turbinai',
    'turbinas',
    'turbinât',
    'turbinée',
    'turbiner',
    'turbines',
    'turbinés',
    'turbinez',
    'turbiths',
    'turbotin',
    'turcique',
    'turfiste',
    'turgides',
    'turkmène',
    'turlutte',
    'turonien',
    'turpides',
    'turquins',
    'tussions',
    'tussores',
    'tutelles',
    'tuteurai',
    'tuteuras',
    'tuteurât',
    'tuteurée',
    'tuteurer',
    'tuteures',
    'tuteurés',
    'tuteurez',
    'tutoient',
    'tutoiera',
    'tutoyais',
    'tutoyait',
    'tutoyant',
    'tutoyées',
    'tutoyeur',
    'tutoyiez',
    'tutoyons',
    'tutrices',
    'tuyautai',
    'tuyautas',
    'tuyautât',
    'tuyautée',
    'tuyauter',
    'tuyautes',
    'tuyautés',
    'tuyautez',
    'twin-set',
    'twistais',
    'twistait',
    'twistant',
    'twistent',
    'twistera',
    'twistiez',
    'twistons',
    'tympanal',
    'tympanon',
    'typaient',
    'typasses',
    'typerais',
    'typerait',
    'typèrent',
    'typeriez',
    'typerons',
    'typeront',
    'typhique',
    'typhlite',
    'typhoïde',
    'typhoses',
    'typiques',
    'typisais',
    'typisait',
    'typisant',
    'typisées',
    'typisent',
    'typisera',
    'typisiez',
    'typisons',
    'tyrannie',
    'tyrolien',
    'tyrosine',
    'tzarines',
    'tziganes',
    'ubicuité',
    'ubiquité',
    'ubuesque',
    'ulcérais',
    'ulcérait',
    'ulcérant',
    'ulcérées',
    'ulcèrent',
    'ulcérera',
    'ulcéreux',
    'ulcériez',
    'ulcérons',
    'ulmaires',
    'ulnaires',
    'ultrason',
    'ululâmes',
    'ululasse',
    'ululâtes',
    'ululerai',
    'ululeras',
    'ululerez',
    'ululions',
    'unanimes',
    'uncinées',
    'unguéale',
    'unguéaux',
    'unicaule',
    'unicités',
    'unicorne',
    'unifiais',
    'unifiait',
    'unifiant',
    'unifiées',
    'unifient',
    'unifiera',
    'unifiiez',
    'unifions',
    'uniflore',
    'unifolié',
    'uniforme',
    'unilobée',
    'unilobés',
    'unipares',
    'unirions',
    'unisexes',
    'unisexué',
    'unissais',
    'unissait',
    'unissant',
    'unissent',
    'unissiez',
    'unissons',
    'unitaire',
    'unitives',
    'univalve',
    'univoque',
    'uppercut',
    'uraciles',
    'uranates',
    'uranique',
    'uranisme',
    'uraniums',
    'uranyles',
    'urbaines',
    'urbanisa',
    'urbanise',
    'urbanisé',
    'urbanité',
    'urcéolée',
    'urcéolés',
    'urémique',
    'urétéral',
    'uretères',
    'urétrale',
    'urétraux',
    'urétrite',
    'urgences',
    'urgentes',
    'urgerait',
    'uricémie',
    'urinaire',
    'urinâmes',
    'urinasse',
    'urinâtes',
    'urinerai',
    'urineras',
    'urinerez',
    'urineuse',
    'urinions',
    'urinoirs',
    'urologie',
    'urologue',
    'uromètre',
    'uropodes',
    'ursuline',
    'urticant',
    'usassent',
    'usassiez',
    'userions',
    'usinages',
    'usinâmes',
    'usinasse',
    'usinâtes',
    'usinerai',
    'usineras',
    'usinerez',
    'usinière',
    'usiniers',
    'usinions',
    'usuelles',
    'usufruit',
    'usuraire',
    'usurière',
    'usuriers',
    'usurpais',
    'usurpait',
    'usurpant',
    'usurpées',
    'usurpent',
    'usurpera',
    'usurpiez',
    'usurpons',
    'utérines',
    'utilisai',
    'utilisas',
    'utilisât',
    'utilisée',
    'utiliser',
    'utilises',
    'utilisés',
    'utilisez',
    'utilités',
    'utopique',
    'utopiste',
    'utricule',
    'uva-ursi',
    'uvulaire',
    'vacances',
    'vacantes',
    'vacarmes',
    'vacation',
    'vaccaire',
    'vaccinai',
    'vaccinal',
    'vaccinas',
    'vaccinât',
    'vaccinée',
    'vacciner',
    'vaccines',
    'vaccinés',
    'vaccinez',
    'vacharde',
    'vachards',
    'vachères',
    'vacherie',
    'vacherin',
    'vachette',
    'vacillai',
    'vacillas',
    'vacillât',
    'vaciller',
    'vacilles',
    'vacillez',
    'vacuités',
    'vacuoles',
    'vacuomes',
    'vagabond',
    'vaginale',
    'vaginaux',
    'vaginite',
    'vagirais',
    'vagirait',
    'vagiriez',
    'vagirons',
    'vagiront',
    'vagisses',
    'vagissez',
    'vaguâmes',
    'vaguasse',
    'vaguâtes',
    'vaguerai',
    'vagueras',
    'vaguerez',
    'vaguions',
    'vaigrage',
    'vaillant',
    'vaillent',
    'vaincrai',
    'vaincras',
    'vaincrez',
    'vaincues',
    'vainques',
    'vainquez',
    'vainquis',
    'vainquit',
    'vainquît',
    'vaironna',
    'vaironne',
    'vaironné',
    'vaisseau',
    'valables',
    'valaient',
    'valences',
    'valetais',
    'valetait',
    'valetant',
    'valetera',
    'valetiez',
    'valetons',
    'valettes',
    'validais',
    'validait',
    'validant',
    'validées',
    'valident',
    'validera',
    'validiez',
    'validité',
    'validons',
    'valisais',
    'valisait',
    'valisant',
    'valisées',
    'valisent',
    'valisera',
    'valisiez',
    'valisons',
    'valkyrie',
    'valleuse',
    'vallonna',
    'vallonne',
    'vallonné',
    'valoches',
    'valorisa',
    'valorise',
    'valorisé',
    'valousai',
    'valousas',
    'valousât',
    'valousée',
    'valouser',
    'valouses',
    'valousés',
    'valousez',
    'valsâmes',
    'valsasse',
    'valsâtes',
    'valserai',
    'valseras',
    'valserez',
    'valseurs',
    'valseuse',
    'valsions',
    'valurent',
    'valusses',
    'valvaire',
    'valvules',
    'vampâmes',
    'vampasse',
    'vampâtes',
    'vamperai',
    'vamperas',
    'vamperez',
    'vampions',
    'vampires',
    'vanadium',
    'vandales',
    'vandoise',
    'vanesses',
    'vanillée',
    'vanilles',
    'vanillés',
    'vanillon',
    'vaniteux',
    'vannages',
    'vannâmes',
    'vannasse',
    'vannâtes',
    'vanneaux',
    'vannelle',
    'vannerai',
    'vanneras',
    'vannerez',
    'vannerie',
    'vanneurs',
    'vanneuse',
    'vannière',
    'vanniers',
    'vannions',
    'vannures',
    'vantâmes',
    'vantarde',
    'vantards',
    'vantasse',
    'vantâtes',
    'vanterai',
    'vanteras',
    'vanterez',
    'vanterie',
    'vantions',
    'vaporeux',
    'vaporisa',
    'vaporise',
    'vaporisé',
    'vaquâmes',
    'vaquasse',
    'vaquâtes',
    'vaquerai',
    'vaqueras',
    'vaquerez',
    'vaquions',
    'varaigne',
    'varangue',
    'varappai',
    'varappas',
    'varappât',
    'varapper',
    'varappes',
    'varappez',
    'vareuses',
    'varheure',
    'variable',
    'variâmes',
    'variance',
    'variante',
    'variants',
    'variasse',
    'variâtes',
    'varierai',
    'varieras',
    'varierez',
    'variétal',
    'variétés',
    'variions',
    'variolée',
    'varioles',
    'variolés',
    'variorum',
    'varlopai',
    'varlopas',
    'varlopât',
    'varlopée',
    'varloper',
    'varlopes',
    'varlopés',
    'varlopez',
    'vasardes',
    'vaselina',
    'vaseline',
    'vaseliné',
    'vaseuses',
    'vasières',
    'vasistas',
    'vassales',
    'vaticane',
    'vaticina',
    'vaticine',
    'vaticiné',
    'vaudoise',
    'vaudoues',
    'vaudrais',
    'vaudrait',
    'vaudriez',
    'vaudrons',
    'vaudront',
    'vauriens',
    'vautours',
    'vautrais',
    'vautrait',
    'vautrant',
    'vautrées',
    'vautrent',
    'vautrera',
    'vautriez',
    'vautrons',
    'vecteurs',
    'vécurent',
    'vécusses',
    'vedettes',
    'védiques',
    'védismes',
    'végétais',
    'végétait',
    'végétale',
    'végétant',
    'végétaux',
    'végètent',
    'végétera',
    'végétiez',
    'végétons',
    'véhément',
    'véhicula',
    'véhicule',
    'véhiculé',
    'veillais',
    'veillait',
    'veillant',
    'veillées',
    'veillent',
    'veillera',
    'veilleur',
    'veilliez',
    'veillons',
    'veinâmes',
    'veinarde',
    'veinards',
    'veinasse',
    'veinâtes',
    'veinerai',
    'veineras',
    'veinerez',
    'veinette',
    'veineuse',
    'veinions',
    'veinules',
    'veinures',
    'vêlaient',
    'vélaires',
    'vélarisa',
    'vélarise',
    'vélarisé',
    'vêlasses',
    'vêlement',
    'vêlerais',
    'vêlerait',
    'vêlèrent',
    'vêleriez',
    'vêlerons',
    'vêleront',
    'véliques',
    'vélivole',
    'velléité',
    'vélocité',
    'véloskis',
    'veloutai',
    'veloutas',
    'veloutât',
    'veloutée',
    'velouter',
    'veloutes',
    'veloutés',
    'veloutez',
    'venaient',
    'venaison',
    'vénalité',
    'vendable',
    'vendange',
    'vendangé',
    'vendéens',
    'vendetta',
    'vendeurs',
    'vendeuse',
    'vendîmes',
    'vendions',
    'vendisse',
    'vendîtes',
    'vendrais',
    'vendrait',
    'vendredi',
    'vendriez',
    'vendrons',
    'vendront',
    'venelles',
    'vénéneux',
    'vénérais',
    'vénérait',
    'vénérant',
    'vénérées',
    'vénèrent',
    'vénérera',
    'vénérien',
    'véneries',
    'vénériez',
    'vénérons',
    'venettes',
    'vengeais',
    'vengeait',
    'vengeant',
    'vengeons',
    'vengerai',
    'vengeras',
    'vengerez',
    'vengeurs',
    'vengions',
    'vénielle',
    'venimeux',
    'vénitien',
    'ventages',
    'ventails',
    'venteuse',
    'ventilai',
    'ventilas',
    'ventilât',
    'ventilée',
    'ventiler',
    'ventiles',
    'ventilés',
    'ventilez',
    'ventousa',
    'ventouse',
    'ventousé',
    'ventrale',
    'ventraux',
    'ventrées',
    'ventrues',
    'venturis',
    'vénusien',
    'vénustés',
    'véracité',
    'véraison',
    'vérandas',
    'vératres',
    'verbales',
    'verbeuse',
    'verbiage',
    'verbiagé',
    'verdages',
    'verdâtre',
    'verdelet',
    'verdeurs',
    'verdicts',
    'verdiers',
    'verdîmes',
    'verdirai',
    'verdiras',
    'verdirez',
    'verdisse',
    'verdîtes',
    'verdoies',
    'verdoyai',
    'verdoyas',
    'verdoyât',
    'verdoyer',
    'verdoyez',
    'verdures',
    'véreuses',
    'vergence',
    'vergetés',
    'vergette',
    'vergeure',
    'verglaça',
    'verglace',
    'verglacé',
    'vergogne',
    'vérifiai',
    'vérifias',
    'vérifiât',
    'vérifiée',
    'vérifier',
    'vérifies',
    'vérifiés',
    'vérifiez',
    'vérismes',
    'véristes',
    'verjutai',
    'verjutas',
    'verjutât',
    'verjutée',
    'verjuter',
    'verjutes',
    'verjutés',
    'verjutez',
    'vermeils',
    'vermilla',
    'vermille',
    'vermillé',
    'vermines',
    'vermoula',
    'vermoule',
    'vermoulé',
    'vermoulu',
    'vermouth',
    'vermouts',
    'vernales',
    'verniers',
    'vernîmes',
    'vernirai',
    'verniras',
    'vernirez',
    'vernissa',
    'vernisse',
    'vernissé',
    'vernîtes',
    'vérolées',
    'véronals',
    'verranne',
    'verrerie',
    'verrière',
    'verriers',
    'verrines',
    'verrions',
    'versâmes',
    'versants',
    'versasse',
    'versâtes',
    'verseaux',
    'verserai',
    'verseras',
    'verserez',
    'verseurs',
    'verseuse',
    'versifia',
    'versifie',
    'versifié',
    'versions',
    'versoirs',
    'vertébré',
    'vertèbre',
    'vertical',
    'vertiges',
    'vertigos',
    'vertueux',
    'verveine',
    'vervelle',
    'verveuse',
    'vésanies',
    'vésicale',
    'vésicant',
    'vésicaux',
    'vésicule',
    'vespéral',
    'vessâmes',
    'vessasse',
    'vessâtes',
    'vesserai',
    'vesseras',
    'vesserez',
    'vessigon',
    'vessions',
    'vestales',
    'vestiges',
    'vêtaient',
    'vêtement',
    'vétérans',
    'vétillai',
    'vétillas',
    'vétillât',
    'vétiller',
    'vétilles',
    'vétillez',
    'vêtirais',
    'vêtirait',
    'vêtirent',
    'vêtiriez',
    'vêtirons',
    'vêtiront',
    'vêtisses',
    'vétivers',
    'vétustes',
    'vétustés',
    'veuilles',
    'veuillez',
    'veulerie',
    'veuvages',
    'veveysan',
    'vexaient',
    'vexantes',
    'vexasses',
    'vexateur',
    'vexation',
    'vexerais',
    'vexerait',
    'vexèrent',
    'vexeriez',
    'vexerons',
    'vexeront',
    'vexilles',
    'viagères',
    'viandais',
    'viandait',
    'viandant',
    'viandées',
    'viandent',
    'viandera',
    'viandiez',
    'viandons',
    'viatique',
    'vibrages',
    'vibrâmes',
    'vibrante',
    'vibrants',
    'vibrasse',
    'vibrâtes',
    'vibratos',
    'vibrerai',
    'vibreras',
    'vibrerez',
    'vibreurs',
    'vibrions',
    'vibrisse',
    'vicaires',
    'vicarial',
    'vicariat',
    'vicennal',
    'vice-roi',
    'viciable',
    'viciâmes',
    'viciasse',
    'viciâtes',
    'vicierai',
    'vicieras',
    'vicierez',
    'vicieuse',
    'viciions',
    'vicinale',
    'vicinaux',
    'vicomtal',
    'vicomtes',
    'victimes',
    'victoire',
    'victoria',
    'vidaient',
    'vidamies',
    'vidangea',
    'vidangée',
    'vidanger',
    'vidanges',
    'vidangés',
    'vidangez',
    'vidasses',
    'videlles',
    'vidéotex',
    'viderais',
    'viderait',
    'vidèrent',
    'videriez',
    'viderons',
    'videront',
    'videuses',
    'vidimais',
    'vidimait',
    'vidimant',
    'vidimées',
    'vidiment',
    'vidimera',
    'vidimiez',
    'vidimons',
    'viduités',
    'vieilles',
    'vieillie',
    'vieillir',
    'vieillis',
    'vieillit',
    'vieillît',
    'vieillot',
    'viellais',
    'viellait',
    'viellant',
    'viellent',
    'viellera',
    'vielleur',
    'vielleux',
    'vielliez',
    'viellons',
    'viendrai',
    'viendras',
    'viendrez',
    'viennent',
    'vigilant',
    'vigneaux',
    'vigneron',
    'vignette',
    'vignoble',
    'vigognes',
    'viguerie',
    'vigueurs',
    'viguiers',
    'vilaines',
    'vilement',
    'vilenies',
    'villages',
    'vinaient',
    'vinaigra',
    'vinaigre',
    'vinaigré',
    'vinaires',
    'vinasses',
    'vindicte',
    'vinerais',
    'vinerait',
    'vinèrent',
    'vineriez',
    'vinerons',
    'vineront',
    'vineuses',
    'vinicole',
    'vinifère',
    'vinifiai',
    'vinifias',
    'vinifiât',
    'vinifiée',
    'vinifier',
    'vinifies',
    'vinifiés',
    'vinifiez',
    'viniques',
    'vinosité',
    'vinssent',
    'vinssiez',
    'vinylite',
    'violaçai',
    'violaças',
    'violaçât',
    'violacée',
    'violacer',
    'violaces',
    'violacés',
    'violacez',
    'violâmes',
    'violasse',
    'violâtes',
    'violâtre',
    'violence',
    'violenta',
    'violente',
    'violenté',
    'violents',
    'violerai',
    'violeras',
    'violerez',
    'violette',
    'violeurs',
    'violeuse',
    'violiers',
    'violines',
    'violions',
    'violiste',
    'violonai',
    'violonas',
    'violonât',
    'violoner',
    'violones',
    'violonez',
    'vioquira',
    'vipéreau',
    'vipérine',
    'vipérins',
    'viraient',
    'virasses',
    'virelais',
    'virement',
    'virerais',
    'virerait',
    'virèrent',
    'vireriez',
    'virerons',
    'vireront',
    'viretons',
    'vireuses',
    'virginal',
    'virginie',
    'virgulai',
    'virgulas',
    'virgulât',
    'virgulée',
    'virguler',
    'virgules',
    'virgulés',
    'virgulez',
    'virilisa',
    'virilise',
    'virilisé',
    'virilité',
    'virolage',
    'virolais',
    'virolait',
    'virolant',
    'virolées',
    'virolent',
    'virolera',
    'virolier',
    'viroliez',
    'virolons',
    'virtuels',
    'virtuose',
    'virulent',
    'visaient',
    'visasses',
    'viscache',
    'viscéral',
    'viscères',
    'viscoses',
    'viserais',
    'viserait',
    'visèrent',
    'viseriez',
    'viserons',
    'viseront',
    'viseuses',
    'visibles',
    'visières',
    'visionna',
    'visionne',
    'visionné',
    'visitais',
    'visitait',
    'visitant',
    'visitées',
    'visitent',
    'visitera',
    'visiteur',
    'visitiez',
    'visitons',
    'visnages',
    'visqueux',
    'vissages',
    'vissâmes',
    'vissasse',
    'vissâtes',
    'visserai',
    'visseras',
    'visserez',
    'visserie',
    'visseuse',
    'vissions',
    'visuelle',
    'vitalité',
    'vitamine',
    'vitaminé',
    'vitellin',
    'vitellus',
    'vitesses',
    'viticole',
    'vitiligo',
    'vitrages',
    'vitrâmes',
    'vitrasse',
    'vitrâtes',
    'vitrerai',
    'vitreras',
    'vitrerez',
    'vitrerie',
    'vitreuse',
    'vitrière',
    'vitriers',
    'vitrifia',
    'vitrifie',
    'vitrifié',
    'vitrines',
    'vitriola',
    'vitriole',
    'vitriolé',
    'vitriols',
    'vitrions',
    'vitupéra',
    'vitupéré',
    'vitupère',
    'vivables',
    'vivacité',
    'vivaient',
    'vivantes',
    'vivarium',
    'vivement',
    'viveuses',
    'vivifiai',
    'vivifias',
    'vivifiât',
    'vivifiée',
    'vivifier',
    'vivifies',
    'vivifiés',
    'vivifiez',
    'vivipare',
    'vivotais',
    'vivotait',
    'vivotant',
    'vivotent',
    'vivotera',
    'vivotiez',
    'vivotons',
    'vivrière',
    'vivriers',
    'vivrions',
    'vizirats',
    'vocables',
    'vocalisa',
    'vocalise',
    'vocalisé',
    'vocatifs',
    'vocation',
    'vociféra',
    'vociféré',
    'vocifère',
    'voguâmes',
    'voguasse',
    'voguâtes',
    'voguerai',
    'vogueras',
    'voguerez',
    'voguions',
    'voilages',
    'voilâmes',
    'voilasse',
    'voilâtes',
    'voilerai',
    'voileras',
    'voilerez',
    'voilerie',
    'voilette',
    'voiliers',
    'voilions',
    'voilures',
    'voisinai',
    'voisinas',
    'voisinât',
    'voisiner',
    'voisines',
    'voisinez',
    'voiturai',
    'voituras',
    'voiturât',
    'voiturée',
    'voiturer',
    'voitures',
    'voiturés',
    'voiturez',
    'voiturin',
    'voïvodat',
    'voïvodes',
    'voïvodie',
    'volables',
    'volaient',
    'volaille',
    'volantes',
    'volasses',
    'volatile',
    'volatils',
    'volatisé',
    'volerais',
    'volerait',
    'volèrent',
    'voleries',
    'voleriez',
    'volerons',
    'voleront',
    'voletais',
    'voletait',
    'voletant',
    'voletiez',
    'voletons',
    'volettes',
    'voleuses',
    'volières',
    'voligeai',
    'voligeas',
    'voligeât',
    'voligées',
    'voligent',
    'voligera',
    'voligiez',
    'volitifs',
    'volition',
    'volitive',
    'volontés',
    'voltages',
    'voltaire',
    'voltâmes',
    'voltasse',
    'voltâtes',
    'volterai',
    'volteras',
    'volterez',
    'voltigea',
    'voltiger',
    'voltiges',
    'voltigez',
    'voltions',
    'volubile',
    'voluptés',
    'volvaire',
    'volvulus',
    'vomérien',
    'vomiques',
    'vomirais',
    'vomirait',
    'vomirent',
    'vomiriez',
    'vomirons',
    'vomiront',
    'vomisses',
    'vomissez',
    'vomitifs',
    'vomitive',
    'voracité',
    'votaient',
    'votantes',
    'votasses',
    'votation',
    'voterais',
    'voterait',
    'votèrent',
    'voteriez',
    'voterons',
    'voteront',
    'vouaient',
    'vouasses',
    'voudrais',
    'voudrait',
    'voudriez',
    'voudrons',
    'voudront',
    'vouerais',
    'vouerait',
    'vouèrent',
    'voueriez',
    'vouerons',
    'voueront',
    'vouivres',
    'voulions',
    'voulûmes',
    'voulusse',
    'voulûtes',
    'vousoies',
    'vousoyai',
    'vousoyas',
    'vousoyât',
    'vousoyée',
    'vousoyer',
    'vousoyés',
    'vousoyez',
    'vousseau',
    'voussoie',
    'voussoir',
    'voussoya',
    'voussoyé',
    'voussure',
    'voûtâmes',
    'voûtasse',
    'voûtâtes',
    'voûterai',
    'voûteras',
    'voûterez',
    'voûtions',
    'vouvoies',
    'vouvoyai',
    'vouvoyas',
    'vouvoyât',
    'vouvoyée',
    'vouvoyer',
    'vouvoyés',
    'vouvoyez',
    'voyageai',
    'voyageas',
    'voyageât',
    'voyagent',
    'voyagera',
    'voyageur',
    'voyagiez',
    'voyaient',
    'voyances',
    'voyantes',
    'voyelles',
    'voyeuses',
    'vraiment',
    'vraquier',
    'vrenelis',
    'vrillage',
    'vrillais',
    'vrillait',
    'vrillant',
    'vrillées',
    'vrillent',
    'vrillera',
    'vrilliez',
    'vrillons',
    'vrombira',
    'vulcains',
    'vulgaire',
    'vulgates',
    'vulvaire',
    'wagonnée',
    'wagonnet',
    'Walkmans',
    'walkyrie',
    'wallonne',
    'wargames',
    'warranta',
    'warrante',
    'warranté',
    'warrants',
    'week-end',
    'wergelds',
    'westerns',
    'whipcord',
    'whiskies',
    'wishbone',
    'witloofs',
    'wolframs',
    'wormiens',
    'wurmiens',
    'xanthies',
    'xanthine',
    'xanthome',
    'ximénies',
    'xiphoïde',
    'xylidine',
    'xylocope',
    'yachting',
    'yachtman',
    'yachtmen',
    'yatagans',
    'yearling',
    'yoghourt',
    'yogourts',
    'youpines',
    'ypérites',
    'yttrique',
    'yttriums',
    'zaïroise',
    'zakouski',
    'zambiens',
    'zanzibar',
    'zarzuela',
    'zébrâmes',
    'zébrasse',
    'zébrâtes',
    'zébrerai',
    'zébreras',
    'zébrerez',
    'zébrions',
    'zébrures',
    'zélateur',
    'zelliges',
    'zénithal',
    'zéolites',
    'zéolithe',
    'zeppelin',
    'zérotage',
    'zérumbet',
    'zestâmes',
    'zestasse',
    'zestâtes',
    'zesterai',
    'zesteras',
    'zesterez',
    'zestions',
    'zeuzères',
    'zézaient',
    'zézaiera',
    'zézayais',
    'zézayait',
    'zézayant',
    'zézayées',
    'zézayent',
    'zézayera',
    'zézayiez',
    'zézayons',
    'zibaient',
    'zibasses',
    'zibeline',
    'ziberais',
    'ziberait',
    'zibèrent',
    'ziberiez',
    'ziberons',
    'ziberont',
    'zieutais',
    'zieutait',
    'zieutant',
    'zieutées',
    'zieutent',
    'zieutera',
    'zieutiez',
    'zieutons',
    'zigoteau',
    'ziguâmes',
    'ziguasse',
    'ziguâtes',
    'ziguerai',
    'zigueras',
    'ziguerez',
    'ziguions',
    'zigzagua',
    'zigzague',
    'zigzagué',
    'zincages',
    'zingages',
    'zinguais',
    'zinguait',
    'zinguant',
    'zinguées',
    'zinguent',
    'zinguera',
    'zingueur',
    'zinguiez',
    'zinguons',
    'zinzolin',
    'zippâmes',
    'zippasse',
    'zippâtes',
    'zipperai',
    'zipperas',
    'zipperez',
    'zippions',
    'zircones',
    'zizanies',
    'zodiacal',
    'zodiaque',
    'zonaient',
    'zonardes',
    'zonasses',
    'zonerais',
    'zonerait',
    'zonèrent',
    'zoneriez',
    'zonerons',
    'zoneront',
    'zooglées',
    'zoolâtre',
    'zoolites',
    'zoolithe',
    'zoologie',
    'zoonoses',
    'zoophile',
    'zoophore',
    'zoophyte',
    'zoopsies',
    'zoospore',
    'zootaxie',
    'zorilles',
    'zostères',
    'zozotais',
    'zozotait',
    'zozotant',
    'zozotent',
    'zozotera',
    'zozotiez',
    'zozotons',
    'zuchette',
    'zutiques',
    'zutistes'
];
