import { TABLES } from '../firebase';
import {
    query,
    orderBy,
    limit,
    doc,
    getDoc,
    getDocs,
    startAfter,
    collection,
    getFirestore,
    setDoc,
    deleteDoc
} from 'firebase/firestore';
import {
    DEFAULT_EXPENSES_UPDATE_FAIL,
    DEFAULT_EXPENSES_UPDATE_SUCCESS,
    SPENDING_CONF_FETCH_FAIL,
    SPENDING_CONF_FETCH_SUCCESS,
    SPENDING_CREATE_FAIL,
    SPENDING_CREATE_SUCCESS,
    SPENDING_DELETE_FAIL,
    SPENDING_DELETE_SUCCESS,
    SPENDING_FETCH_FAIL,
    SPENDING_FETCH_SUCCESS,
    SPENDING_LOADING,
    SPENDING_LOADING_FINISHED,
    SPENDING_UPDATE_FAIL,
    SPENDING_UPDATE_SUCCESS
} from './spending.types';
import { v4 as uuid } from 'uuid';
import { CONF_SPENDING_ID } from './spending.const';

/**
 * @typedef Expense
 * @property {boolean} both Is an expanse for us
 * @property {string} comment
 * @property {string} name
 * @property {string} payer
 * @property {string} [consider]
 * @property {number} price
 */

/**
 * @typedef Spending
 * @property {string} name
 * @property {Expense[]} expenses
 * @property {any} [createdAt]
 * @property {any} [updatedAt]
 * @property {string} [docId]
 */

const db = getFirestore();
const TABLE_NAME = TABLES.spendings;
const ADMIN_TABLE_NAME = TABLES.admin;

/**
 * Create a new spending
 * @param {Spending} spending
 * @returns {function}
 */
export const create = (spending) => async (dispatch) => {
    dispatch({ type: SPENDING_LOADING });
    const meta = { createdAt: new Date(), updatedAt: new Date(), docId: uuid() };
    const spendingToSave = { ...spending, ...meta };
    try {
        await setDoc(doc(db, TABLE_NAME, spendingToSave.docId), spendingToSave);
        dispatch({
            type: SPENDING_CREATE_SUCCESS,
            payload: { spending: spendingToSave, isNew: true }
        });
    } catch (error) {
        dispatch({ type: SPENDING_CREATE_FAIL, payload: { error } });
    }
    dispatch({ type: SPENDING_LOADING_FINISHED });
};

export const getOne = (id) => async (dispatch) => {
    dispatch({ type: SPENDING_LOADING });
    try {
        const spending = await getDoc(doc(db, TABLE_NAME, id));
        dispatch({ type: SPENDING_FETCH_SUCCESS, payload: { spendings: [spending.data()] } });
    } catch (error) {
        dispatch({ type: SPENDING_FETCH_FAIL, payload: { error } });
    }
    dispatch({ type: SPENDING_LOADING_FINISHED });
};

/**
 * Fetch next spendings
 * @param {number} amount
 * @param {string} [lastFetchedId]
 * @return {(function(*): Promise<void>)|*}
 */
export const fetchNext = (amount, lastFetchedId) => async (dispatch) => {
    dispatch({ type: SPENDING_LOADING });
    try {
        let spendingList;
        if (lastFetchedId) {
            const lastDoc = await getDoc(doc(db, TABLE_NAME, lastFetchedId));
            const q = query(
                collection(db, TABLE_NAME),
                orderBy('createdAt', 'desc'),
                startAfter(lastDoc),
                limit(amount)
            );
            spendingList = await getDocs(q);
        } else {
            const q = query(
                collection(db, TABLE_NAME),
                orderBy('createdAt', 'desc'),
                limit(amount)
            );
            spendingList = await getDocs(q);
        }
        spendingList = spendingList.docs.map((doc) => doc.data());
        dispatch({ type: SPENDING_FETCH_SUCCESS, payload: { spendings: spendingList } });
    } catch (error) {
        dispatch({ type: SPENDING_FETCH_FAIL, payload: { error } });
    }
    dispatch({ type: SPENDING_LOADING_FINISHED });
};

/**
 * Remove a spending from firebase
 * @param {Spending} spending
 * @return {function(*): Promise<void>}
 */
export const remove = (spending) => async (dispatch) => {
    dispatch({ type: SPENDING_LOADING });
    try {
        await deleteDoc(doc(db, TABLE_NAME, spending.docId));
        dispatch({ type: SPENDING_DELETE_SUCCESS, payload: { docId: spending.docId } });
    } catch (error) {
        dispatch({ type: SPENDING_DELETE_FAIL, payload: { error } });
    }
    dispatch({ type: SPENDING_LOADING_FINISHED });
};

/**
 * Update a spending
 * @param {Spending} spending
 * @return {function(*): Promise<void>}
 */
export const update = (spending) => async (dispatch) => {
    dispatch({ type: SPENDING_LOADING });
    const spendingToSave = { ...spending, updatedAt: new Date() };
    try {
        await setDoc(doc(db, TABLE_NAME, spending.docId), spendingToSave);
        dispatch({ type: SPENDING_UPDATE_SUCCESS, payload: { spending: spendingToSave } });
    } catch (error) {
        dispatch({ type: SPENDING_UPDATE_FAIL, payload: { error } });
    }
    dispatch({ type: SPENDING_LOADING_FINISHED });
};

/**
 * Update default expenses
 * @param {Expense[]} defaultExpenses
 * @return {(function(*): Promise<void>)|*}
 */
export const saveDefaultExpenses = (defaultExpenses) => async (dispatch) => {
    dispatch({ type: SPENDING_LOADING });
    try {
        await setDoc(doc(db, ADMIN_TABLE_NAME, CONF_SPENDING_ID), { defaultExpenses });
        dispatch({ type: DEFAULT_EXPENSES_UPDATE_SUCCESS, payload: { defaultExpenses } });
    } catch (error) {
        dispatch({ type: DEFAULT_EXPENSES_UPDATE_FAIL, payload: { error } });
    }
    dispatch({ type: SPENDING_LOADING_FINISHED });
};

/**
 * Fetch default expenses
 * @return {(function(*): Promise<void>)|*}
 */
export const fetchConf = () => async (dispatch) => {
    dispatch({ type: SPENDING_LOADING });
    try {
        const conf = await getDoc(doc(db, ADMIN_TABLE_NAME, CONF_SPENDING_ID));
        dispatch({ type: SPENDING_CONF_FETCH_SUCCESS, payload: { conf: conf.data() } });
    } catch (error) {
        dispatch({ type: SPENDING_CONF_FETCH_FAIL, payload: { error } });
    }
    dispatch({ type: SPENDING_LOADING_FINISHED });
};
