import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

export default function PageTitle(props) {
    const { title } = props;
    return (
        <React.Fragment>
            <div className="row mt-s justify-center">
                <Typography variant="h5">{title}</Typography>
            </div>
        </React.Fragment>
    );
}

PageTitle.propTypes = {
    title: PropTypes.string.isRequired
};
