import isLocalhost from './utils';

const API_KEY = 'HjdLNrITbnGeVPpXSoPrwA0n3gQ6K02W';

const BASE_OPTIONS = {
    method: 'GET',
    headers: {
        apikey: API_KEY,
        'Content-Type': 'application/json'
    }
};
const BASE_URL = isLocalhost()
    ? 'http://0.0.0.0:8080/https://prim.iledefrance-mobilites.fr/marketplace'
    : 'https://prim.iledefrance-mobilites.fr/marketplace';

/**
 *
 * @param {string} lineRef
 * @returns {Promise<*[]|*>}
 */
export async function getLineStatus(lineRef) {
    const url = `${BASE_URL}/general-message?LineRef=${lineRef}`;
    const response = await fetch(url, BASE_OPTIONS);
    if (response.status > 200) {
        console.error('Fail to fetch');
        return [];
    }
    const data = await response.json();
    const info = data?.Siri?.ServiceDelivery?.GeneralMessageDelivery;
    if (info.length > 0) {
        return info[0]?.InfoMessage.map((d) => ({
            message: d?.Content?.Message.filter((m) => m.MessageType === 'TEXT_ONLY')[0],
            title: d?.InfoChannelRef?.value
        }));
    }
    return [];
}

/**
 *
 * @param {string} lineStopPoint
 * @returns {Promise<*[]>}
 */
export async function getNextDepartures(lineStopPoint) {
    const url = `${BASE_URL}/stop-monitoring?MonitoringRef=${lineStopPoint}`;
    const response = await fetch(url, BASE_OPTIONS);
    if (response.status > 200) {
        console.error('Fail to fetch');
        return [];
    }
    const data = await response.json();
    const info = data?.Siri?.ServiceDelivery?.StopMonitoringDelivery;

    if (info.length > 0) {
        const nextStops = info[0].MonitoredStopVisit;
        if (nextStops.length > 0) {
            return nextStops
                .map((stop) => {
                    const vehicleJourney = stop?.MonitoredVehicleJourney;
                    const now = Date.now();
                    const departureTimestamp = +new Date(
                        vehicleJourney?.MonitoredCall?.ExpectedDepartureTime
                    );
                    return {
                        direction: vehicleJourney?.DestinationName[0].value,
                        timeToWait: Math.round((departureTimestamp - now) / 1000 / 60),
                        departureStatus: vehicleJourney?.MonitoredCall?.DepartureStatus
                    };
                })
                .slice(0, 3);
        }
    }
    return [];
}
