import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import PageTitle from '../common/Components/PageTitle';
import { useNavigate } from 'react-router-dom';

export default function Administration() {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Home - Administration';
    }, []);

    return (
        <React.Fragment>
            <PageTitle title="Administration" />
            <div className="row justify-center mt-l">
                <Button onClick={() => navigate('/spending/admin')}>
                    Administration des dépenses
                </Button>
            </div>
        </React.Fragment>
    );
}
