import './MainContainer.css';
import { Route, Routes } from 'react-router-dom';
import { routes } from '../app.routes';

function MainContainer() {
    return (
        <div className="main-container flex full justify-center">
            <div className="main-container__wrapper justify-center">
                <Routes>
                    {routes.map((route, i) => (
                        <Route key={i} {...route} />
                    ))}
                </Routes>
                <div className="mb-s" />
            </div>
        </div>
    );
}

export default MainContainer;
