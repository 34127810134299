import Words from './Words.screen';

const routes = [
    {
        path: '/cheats/words-search',
        element: <Words />,
        title: 'Cherche mots',
        info: 'Outil de recherche de mots à partir de lettres.'
    }
];

export default routes;
