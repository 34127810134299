export default [
    'ah',
    'ai',
    'aï',
    'an',
    'as',
    'au',
    'bu',
    'ça',
    'çà',
    'cc',
    'ce',
    'cf',
    'cg',
    'ch',
    'ci',
    'cl',
    'cm',
    'de',
    'dé',
    'dg',
    'dl',
    'dm',
    'do',
    'du',
    'dû',
    'eh',
    'en',
    'es',
    'ès',
    'et',
    'eu',
    'ex',
    'fa',
    'fi',
    'go',
    'ha',
    'hé',
    'hi',
    'ho',
    'if',
    'il',
    'in',
    'je',
    'kg',
    'km',
    'la',
    'là',
    'le',
    'li',
    'lu',
    'ma',
    'me',
    'mg',
    'mi',
    'ms',
    'mu',
    'mû',
    'na',
    'ne',
    'né',
    'ni',
    'nô',
    'nu',
    'oc',
    'oh',
    'on',
    'or',
    'os',
    'ou',
    'où',
    'ph',
    'pi',
    'pu',
    'ra',
    'ré',
    'ri',
    'ru',
    'sa',
    'se',
    'si',
    'su',
    'ta',
    'te',
    'té',
    'tu',
    'un',
    'us',
    'ut',
    'va',
    'vu',
    'xi'
];
