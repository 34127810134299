import Administration from './index';

const routes = [
    {
        path: '/admin',
        element: <Administration />,
        name: 'Administration'
    }
];

export default routes;
