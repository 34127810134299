import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { TableRow, TableCell, IconButton } from '@mui/material';
import { getAuthIsLoggedIn } from '../../Auth/auth.selectors';
import { remove } from '../spending.actions';
import { useNavigate } from 'react-router-dom';
import { Delete, ModeEdit } from '@mui/icons-material';

export default function SpendingLine(props) {
    const navigate = useNavigate();

    const isLoggedIn = useSelector(getAuthIsLoggedIn);
    const dispatch = useDispatch();

    const { name, expenses, docId } = props.spending;

    function computeTotalExpense() {
        return expenses
            .reduce((acc, expense) => {
                return acc + expense.price;
            }, 0)
            .toFixed(2);
    }

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>{name}</TableCell>
                <TableCell>{expenses.length}</TableCell>
                <TableCell>{computeTotalExpense(expenses)} €</TableCell>
                {isLoggedIn ? (
                    <React.Fragment>
                        <TableCell>
                            <IconButton
                                aria-controls="supprimer"
                                color="error"
                                style={{ float: 'right' }}
                                onClick={() => dispatch(remove(props.spending))}
                            >
                                <Delete />
                            </IconButton>
                            <IconButton
                                aria-controls="modifier"
                                style={{ float: 'right' }}
                                className="mr-xs"
                                color="warning"
                                onClick={() => navigate(`/spending/view/${docId}`)}
                            >
                                <ModeEdit />
                            </IconButton>
                        </TableCell>
                    </React.Fragment>
                ) : null}
            </TableRow>
        </React.Fragment>
    );
}

SpendingLine.propTypes = {
    spending: PropTypes.object.isRequired
};
