import React, { useEffect } from 'react';
import PageTitle from '../common/Components/PageTitle';
import { Grid, Card, CardHeader, CardContent, CardActions, Button } from '@mui/material';
import routes from './sub.routes';
import { useNavigate } from 'react-router-dom';

export default function Cheat() {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Home - Cheats';
    }, []);

    function navigateTo(path) {
        navigate(path);
    }

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={12} md={12} xl={12} sm={12}>
                    <PageTitle title="Outils disponibles" />
                </Grid>
                {routes.map((route, i) => (
                    <Grid key={i} item xs={12} sm={12} md={6} lg={4} xl={2}>
                        <Card>
                            <CardHeader title={route.title} />
                            <CardContent>{route.info}</CardContent>
                            <CardActions>
                                <Button onClick={() => navigateTo(route.path)}>Voir</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </React.Fragment>
    );
}
