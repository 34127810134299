export default [
    'abasourdissaient',
    'abasourdissantes',
    'abasourdissement',
    'abâtardissements',
    'abris-sous-roche',
    'abstentionnismes',
    'abstentionnistes',
    'abstractionnisme',
    'acanthoptérygien',
    'accastillassions',
    'accastilleraient',
    'accessoirisaient',
    'accessoirisasses',
    'accessoiriserais',
    'accessoiriserait',
    'accessoirisèrent',
    'accessoiriseriez',
    'accessoiriserons',
    'accessoiriseront',
    'accidentellement',
    'accompagnassions',
    'accompagnatrices',
    'accompagneraient',
    'accomplissements',
    'accroupissements',
    'additionnassions',
    'additionneraient',
    'adjectivisassent',
    'adjectivisassiez',
    'adjectiviserions',
    'administrassions',
    'administratrices',
    'administreraient',
    'affaiblissements',
    'affectionnassent',
    'affectionnassiez',
    'affectionnerions',
    'affourageassions',
    'affourrageassent',
    'affourrageassiez',
    'affourrageraient',
    'affranchissaient',
    'affranchissement',
    'africanisassions',
    'africaniseraient',
    'agenouillassions',
    'agro-alimentaire',
    'aides-comptables',
    'aiguilletassions',
    'aiguilletterions',
    'aiguillonnassent',
    'aiguillonnassiez',
    'aiguillonnerions',
    'alcalinisassions',
    'alcaliniseraient',
    'alcoolifications',
    'allégorisassions',
    'allégoriseraient',
    'alluvionnassions',
    'alluvionneraient',
    'alphabétiquement',
    'alphabétisassent',
    'alphabétisassiez',
    'alphabétisations',
    'alphabétiserions',
    'ambitionnassions',
    'ambitionneraient',
    'américanisassent',
    'américanisassiez',
    'américanisations',
    'américaniserions',
    'amoindrissements',
    'anaphrodisiaques',
    'anastomosassions',
    'anathématisaient',
    'anathématisasses',
    'anathématiserais',
    'anathématiserait',
    'anathématisèrent',
    'anathématiseriez',
    'anathématiserons',
    'anathématiseront',
    'anesthésiassions',
    'anesthésieraient',
    'anesthésiologies',
    'anthropologiques',
    'anthropométrique',
    'anticapitalistes',
    'anticléricalisme',
    'anticolonialisme',
    'anticolonialiste',
    'anticonformismes',
    'anticonformistes',
    'anticonjoncturel',
    'antidéflagrantes',
    'antidémocratique',
    'antidiphtériques',
    'antiesclavagiste',
    'antigravitations',
    'antihistaminique',
    'antimilitarismes',
    'antimilitaristes',
    'antimonarchiques',
    'antimonarchistes',
    'antinévralgiques',
    'antipatriotiques',
    'antipatriotismes',
    'antiphlogistique',
    'antiscorbutiques',
    'anti-sous-marine',
    'anti-sous-marins',
    'antispasmodiques',
    'antisyphilitique',
    'antituberculeuse',
    'apostrophassions',
    'apostropheraient',
    'appareillassions',
    'appareilleraient',
    'appartiendraient',
    'appauvrissements',
    'appendicectomies',
    'appesantissaient',
    'appesantissement',
    'applaudissements',
    'appréhendassions',
    'appréhenderaient',
    'apprivoisassions',
    'apprivoiseraient',
    'approfondiraient',
    'approfondissions',
    'approvisionnâmes',
    'approvisionnasse',
    'approvisionnâtes',
    'approvisionnerai',
    'approvisionneras',
    'approvisionnerez',
    'approvisionneurs',
    'approvisionneuse',
    'approvisionnions',
    'archidiocésaines',
    'archiépiscopales',
    'archipresbytéral',
    'architectoniques',
    'architecturaient',
    'architecturasses',
    'architecturerais',
    'architecturerait',
    'architecturèrent',
    'architectureriez',
    'architecturerons',
    'architectureront',
    'aristotélicienne',
    'arraisonnassions',
    'arraisonneraient',
    'arrière-boutique',
    'arrière-cerveaux',
    'arrière-cuisines',
    'artérioscléreuse',
    'artérioscléroses',
    'artificiellement',
    'artificieusement',
    'ascensionnassent',
    'ascensionnassiez',
    'ascensionnerions',
    'assaisonnassions',
    'assaisonneraient',
    'assarmentassions',
    'assarmenteraient',
    'assermentassions',
    'assermenteraient',
    'associationnisme',
    'associationniste',
    'assombrissements',
    'assouplissements',
    'assourdissements',
    'assujettissaient',
    'assujettissantes',
    'assujettissement',
    'assurance-crédit',
    'astronomiquement',
    'attendrissements',
    'audioprothésiste',
    'auditionnassions',
    'auditionneraient',
    'australopithèque',
    'authentifiassent',
    'authentifiassiez',
    'authentification',
    'authentifierions',
    'authentiquassent',
    'authentiquassiez',
    'authentiquerions',
    'auto-accusations',
    'autobiographique',
    'auto-calibration',
    'autoconsommation',
    'autodestructeurs',
    'autodestructions',
    'autodestructrice',
    'autodéterminâmes',
    'autodéterminâtes',
    'autodéterminerez',
    'autodéterminions',
    'auto-équilibrant',
    'autofinançassiez',
    'autofinancements',
    'autofinancerions',
    'autogestionnaire',
    'autographiassent',
    'autographiassiez',
    'autographierions',
    'auto-impositions',
    'automatisassions',
    'automatiseraient',
    'automitrailleuse',
    'automobilisables',
    'autoradiographie',
    'autorégulatrices',
    'autosatisfaction',
    'autos-couchettes',
    'autosuggestionné',
    'autovaccinations',
    'axiomatisassions',
    'axiomatiseraient',
    'bactériologiques',
    'bactériologistes',
    'bactériostatique',
    'badigeonnassions',
    'badigeonneraient',
    'baguenaudassions',
    'baguenauderaient',
    'baragouinassions',
    'baragouineraient',
    'barbouillassions',
    'barbouilleraient',
    'bastillonnassent',
    'bastillonnassiez',
    'bastillonnerions',
    'bateaux-citernes',
    'bibliographiques',
    'bibliothéconomie',
    'billebaudassions',
    'billebauderaient',
    'biobibliographie',
    'bioclimatologies',
    'bioluminescences',
    'bisegmentassions',
    'bisegmenteraient',
    'blackboulassions',
    'blackbouleraient',
    'bolchévisassions',
    'bolchéviseraient',
    'bonheurs-du-jour',
    'bornes-fontaines',
    'bouillonnassions',
    'bouillonneraient',
    'bouillottassions',
    'bouillotteraient',
    'bouleversassions',
    'bouleverseraient',
    'bourgeonnassions',
    'bourgeonneraient',
    'bourlinguassions',
    'bourlingueraient',
    'boursicotassions',
    'boursicoteraient',
    'boursouflassions',
    'boursoufleraient',
    'boustifaillaient',
    'boustifaillasses',
    'boustifaillerais',
    'boustifaillerait',
    'boustifaillèrent',
    'boustifailleriez',
    'boustifaillerons',
    'boustifailleront',
    'boutons-pression',
    'bredouillassions',
    'bredouilleraient',
    'brillantinassent',
    'brillantinassiez',
    'brillantinerions',
    'bringueballaient',
    'bringueballasses',
    'bringueballerais',
    'bringueballerait',
    'bringueballèrent',
    'bringueballeriez',
    'bringueballerons',
    'bringueballeront',
    'brinquebalassent',
    'brinquebalassiez',
    'brinquebalerions',
    'brinqueballaient',
    'brinqueballasses',
    'brinqueballerais',
    'brinqueballerait',
    'brinqueballèrent',
    'brinqueballeriez',
    'brinqueballerons',
    'brinqueballeront',
    'brouillonnassent',
    'brouillonnassiez',
    'brouillonnerions',
    'bureaucratisâmes',
    'bureaucratisasse',
    'bureaucratisâtes',
    'bureaucratiserai',
    'bureaucratiseras',
    'bureaucratiserez',
    'bureaucratisions',
    'caillebotterions',
    'calamistrassions',
    'calamistreraient',
    'calligraphiaient',
    'calligraphiasses',
    'calligraphierais',
    'calligraphierait',
    'calligraphièrent',
    'calligraphieriez',
    'calligraphierons',
    'calligraphieront',
    'calorifugeassent',
    'calorifugeassiez',
    'calorifugeraient',
    'camions-citernes',
    'caoutchoutassent',
    'caoutchoutassiez',
    'caoutchouterions',
    'caparaçonnassent',
    'caparaçonnassiez',
    'caparaçonnerions',
    'capitalisassions',
    'capitaliseraient',
    'caporalisassions',
    'caporaliseraient',
    'capuchonnassions',
    'capuchonneraient',
    'caractérisassent',
    'caractérisassiez',
    'caractérisations',
    'caractériserions',
    'caractéristiques',
    'caractérologique',
    'caramélisassions',
    'caraméliseraient',
    'carcinogénétique',
    'caricaturassions',
    'caricatureraient',
    'carillonnassions',
    'carillonneraient',
    'catastrophassent',
    'catastrophassiez',
    'catastropherions',
    'cauchemardassent',
    'cauchemardassiez',
    'cauchemarderions',
    'cauchemardesques',
    'centralisassions',
    'centralisatrices',
    'centraliseraient',
    'centrifugeassent',
    'centrifugeassiez',
    'centrifugeraient',
    'cérébro-spinales',
    'cérémonieusement',
    'challengeassions',
    'champagnisassent',
    'champagnisassiez',
    'champagnisations',
    'champagniserions',
    'champignonnières',
    'champignonnistes',
    'chanfreinassions',
    'chanfreineraient',
    'chanstiquassions',
    'chanstiqueraient',
    'chantournassions',
    'chantourneraient',
    'chaperonnassions',
    'chaperonneraient',
    'chaptalisassions',
    'chaptaliseraient',
    'chatouillassions',
    'chatouilleraient',
    'chauffe-biberons',
    'chirographiaient',
    'chirographiasses',
    'chirographierais',
    'chirographierait',
    'chirographièrent',
    'chirographieriez',
    'chirographierons',
    'chirographieront',
    'chloramphénicols',
    'chloroformassent',
    'chloroformassiez',
    'chloroformerions',
    'chlorophyllienne',
    'cholécystotomies',
    'cholestérolémies',
    'chouchoutassions',
    'chouchouteraient',
    'christianisaient',
    'christianisasses',
    'christianisation',
    'christianiserais',
    'christianiserait',
    'christianisèrent',
    'christianiseriez',
    'christianiserons',
    'christianiseront',
    'chromatographies',
    'chronométrassent',
    'chronométrassiez',
    'chronométrerions',
    'chryséléphantine',
    'chryséléphantins',
    'cinématographiai',
    'cinématographias',
    'cinématographiât',
    'cinématographiée',
    'cinématographier',
    'cinématographies',
    'cinématographiés',
    'cinématographiez',
    'cinquante-quatre',
    'circonscriptible',
    'circonscriptions',
    'circonscriraient',
    'circonscrivaient',
    'circonscrivirent',
    'circonscrivisses',
    'circonstanciâmes',
    'circonstanciasse',
    'circonstanciâtes',
    'circonstancielle',
    'circonstancierai',
    'circonstancieras',
    'circonstancierez',
    'circonstanciions',
    'circonvallations',
    'circonviendrions',
    'circumnavigation',
    'claquemurassions',
    'claquemureraient',
    'classificatoires',
    'classificatrices',
    'climatothérapies',
    'clitoridectomies',
    'clochardisassent',
    'clochardisassiez',
    'clochardisations',
    'clochardiserions',
    'coadministrateur',
    'collationnassent',
    'collationnassiez',
    'collationnements',
    'collationnerions',
    'collectionnaient',
    'collectionnasses',
    'collectionnerais',
    'collectionnerait',
    'collectionnèrent',
    'collectionneriez',
    'collectionnerons',
    'collectionneront',
    'collectionneuses',
    'collectivisaient',
    'collectivisasses',
    'collectivisation',
    'collectiviserais',
    'collectiviserait',
    'collectivisèrent',
    'collectiviseriez',
    'collectiviserons',
    'collectiviseront',
    'commanditassions',
    'commanditeraient',
    'commercialisâmes',
    'commercialisasse',
    'commercialisâtes',
    'commercialiserai',
    'commercialiseras',
    'commercialiserez',
    'commercialisions',
    'commissionnaient',
    'commissionnaires',
    'commissionnasses',
    'commissionnement',
    'commissionnerais',
    'commissionnerait',
    'commissionnèrent',
    'commissionneriez',
    'commissionnerons',
    'commissionneront',
    'commotionnassent',
    'commotionnassiez',
    'commotionnerions',
    'communaliserions',
    'communiquassions',
    'communiquent-ils',
    'communiqueraient',
    'compartimentages',
    'compartimentâmes',
    'compartimentasse',
    'compartimentâtes',
    'compartimenterai',
    'compartimenteras',
    'compartimenterez',
    'compartimentions',
    'compendieusement',
    'compères-loriots',
    'complémentarités',
    'complexifiassent',
    'complexifiassiez',
    'complexifierions',
    'complimentassent',
    'complimentassiez',
    'complimenterions',
    'comportementales',
    'compressibilités',
    'compromettraient',
    'comptabilisaient',
    'comptabilisasses',
    'comptabilisation',
    'comptabiliserais',
    'comptabiliserait',
    'comptabilisèrent',
    'comptabiliseriez',
    'comptabiliserons',
    'comptabiliseront',
    'concélébrassions',
    'concélébreraient',
    'concentriquement',
    'conceptualisâmes',
    'conceptualisasse',
    'conceptualisâtes',
    'conceptualiserai',
    'conceptualiseras',
    'conceptualiserez',
    'conceptualisions',
    'concessionnaires',
    'conchylicultures',
    'concrétisassions',
    'concrétiseraient',
    'concurrençassent',
    'concurrençassiez',
    'concurrencerions',
    'concurrentielles',
    'concussionnaires',
    'condescendissent',
    'condescendissiez',
    'condescendraient',
    'conditionnassent',
    'conditionnassiez',
    'conditionnements',
    'conditionnerions',
    'confectionnaient',
    'confectionnasses',
    'confectionnerais',
    'confectionnerait',
    'confectionnèrent',
    'confectionneriez',
    'confectionnerons',
    'confectionneront',
    'confectionneuses',
    'confessionnelles',
    'confidentialités',
    'congestionnaient',
    'congestionnasses',
    'congestionnerais',
    'congestionnerait',
    'congestionnèrent',
    'congestionneriez',
    'congestionnerons',
    'congestionneront',
    'conglomérassions',
    'congloméreraient',
    'conglutinassions',
    'conglutineraient',
    'congratulassions',
    'congratuleraient',
    'conjecturalement',
    'conjecturassions',
    'conjectureraient',
    'considérablement',
    'constitutionnels',
    'construisissions',
    'consubstantielle',
    'contagionnassent',
    'contagionnassiez',
    'contagionnerions',
    'containerisaient',
    'containerisasses',
    'containeriserais',
    'containeriserait',
    'containerisèrent',
    'containeriseriez',
    'containeriserons',
    'containeriseront',
    'conteneurisaient',
    'conteneurisasses',
    'conteneurisation',
    'conteneuriserais',
    'conteneuriserait',
    'conteneurisèrent',
    'conteneuriseriez',
    'conteneuriserons',
    'conteneuriseront',
    'contingentassent',
    'contingentassiez',
    'contingentements',
    'contingenterions',
    'contorsionnaient',
    'contorsionnasses',
    'contorsionnerais',
    'contorsionnerait',
    'contorsionnèrent',
    'contorsionneriez',
    'contorsionnerons',
    'contorsionneront',
    'contorsionnistes',
    'contractualisais',
    'contractualisait',
    'contractualisant',
    'contractualisées',
    'contractualisent',
    'contractualisera',
    'contractualisiez',
    'contractualisons',
    'contracturassent',
    'contracturassiez',
    'contracturerions',
    'contraignissions',
    'contraposassions',
    'contraposeraient',
    'contre-assurance',
    'contre-attaquais',
    'contre-attaquait',
    'contre-attaquant',
    'contre-attaquées',
    'contre-attaquent',
    'contre-attaquera',
    'contre-attaquiez',
    'contre-attaquons',
    'contrebalançâmes',
    'contrebalançasse',
    'contrebalançâtes',
    'contrebalancerai',
    'contrebalanceras',
    'contrebalancerez',
    'contrebalancions',
    'contrebattissent',
    'contrebattissiez',
    'contrebattraient',
    'contreboutassent',
    'contreboutassiez',
    'contrebouterions',
    'contre-butassent',
    'contre-butassiez',
    'contrebutassions',
    'contrebuteraient',
    'contre-buterions',
    'contrecarrassent',
    'contrecarrassiez',
    'contrecarrerions',
    'contre-empreinte',
    'contre-épaulette',
    'contre-expertise',
    'contre-extension',
    'contrefichassent',
    'contrefichassiez',
    'contreficherions',
    'contrefoutraient',
    'contre-indiquais',
    'contre-indiquait',
    'contre-indiquant',
    'contre-indiquées',
    'contre-indiquent',
    'contre-indiquera',
    'contre-indiquiez',
    'contre-indiquons',
    'contremaîtresses',
    'contremandassent',
    'contremandassiez',
    'contremanderions',
    'contre-manifesta',
    'contre-manifeste',
    'contre-manifesté',
    'contremarquaient',
    'contremarquasses',
    'contremarquerais',
    'contremarquerait',
    'contremarquèrent',
    'contremarqueriez',
    'contremarquerons',
    'contremarqueront',
    'contre-minassent',
    'contre-minassiez',
    'contre-minerions',
    'contre-murassent',
    'contre-murassiez',
    'contre-murerions',
    'contre-offensive',
    'contre-passaient',
    'contre-passasses',
    'contre-passation',
    'contre-passerais',
    'contre-passerait',
    'contre-passèrent',
    'contre-passeriez',
    'contre-passerons',
    'contre-passeront',
    'contre-plaquâmes',
    'contre-plaquasse',
    'contre-plaquâtes',
    'contre-plaquerai',
    'contre-plaqueras',
    'contre-plaquerez',
    'contre-plaquions',
    'contre-publicité',
    'contre-scellâmes',
    'contre-scellasse',
    'contre-scellâtes',
    'contre-scellerai',
    'contre-scelleras',
    'contre-scellerez',
    'contre-scellions',
    'contresignassent',
    'contresignassiez',
    'contresignataire',
    'contresignatures',
    'contresignerions',
    'contre-tirassent',
    'contre-tirassiez',
    'contre-tirerions',
    'contreviendrions',
    'controversassent',
    'controversassiez',
    'controverserions',
    'contusionnassent',
    'contusionnassiez',
    'contusionnerions',
    'convainquissions',
    'conventionnaient',
    'conventionnasses',
    'conventionnelles',
    'conventionnement',
    'conventionnerais',
    'conventionnerait',
    'conventionnèrent',
    'conventionneriez',
    'conventionnerons',
    'conventionneront',
    'conversationnels',
    'convulsionnaient',
    'convulsionnasses',
    'convulsionnerais',
    'convulsionnerait',
    'convulsionnèrent',
    'convulsionneriez',
    'convulsionnerons',
    'convulsionneront',
    'coreligionnaires',
    'correctionnalisa',
    'correctionnalise',
    'correctionnalisé',
    'correctionnelles',
    'correspondancier',
    'correspondissent',
    'correspondissiez',
    'correspondraient',
    'cosmétiquassions',
    'cosmétiqueraient',
    'couchaillassions',
    'couchailleraient',
    'couches-culottes',
    'couillonnassions',
    'couillonneraient',
    'coupons-réponses',
    'courbaturassions',
    'courbatureraient',
    'court-circuitais',
    'court-circuitait',
    'court-circuitant',
    'court-circuitées',
    'court-circuitent',
    'court-circuitera',
    'court-circuitiez',
    'court-circuitons',
    'courts-bouillons',
    'crachouillassent',
    'crachouillassiez',
    'crachouillerions',
    'criminalisassent',
    'criminalisassiez',
    'criminaliserions',
    'cristallisassent',
    'cristallisassiez',
    'cristallisations',
    'cristalliserions',
    'criticaillassent',
    'criticaillassiez',
    'criticaillerions',
    'croustillassions',
    'croustilleraient',
    'culpabilisassent',
    'culpabilisassiez',
    'culpabilisations',
    'culpabiliserions',
    'cuniculicultures',
    'cybernéticiennes',
    'cytogénéticienne',
    'dactylographiais',
    'dactylographiait',
    'dactylographiant',
    'dactylographiées',
    'dactylographient',
    'dactylographiera',
    'dactylographiiez',
    'dactylographions',
    'dactylographique',
    'damasquinassions',
    'damasquineraient',
    'débâillonnassent',
    'débâillonnassiez',
    'débâillonnerions',
    'déballonnassions',
    'déballonneraient',
    'débalourdassions',
    'débalourderaient',
    'débanalisassions',
    'débanaliseraient',
    'débarbouillaient',
    'débarbouillasses',
    'débarbouillerais',
    'débarbouillerait',
    'débarbouillèrent',
    'débarbouilleriez',
    'débarbouillerons',
    'débarbouilleront',
    'débarbouillettes',
    'débarrassassions',
    'débarrasseraient',
    'débecquetassions',
    'débecquetterions',
    'débillardassions',
    'débillarderaient',
    'débosselleraient',
    'déboulonnassions',
    'déboulonneraient',
    'déboussolassions',
    'déboussoleraient',
    'déboutonnassions',
    'déboutonneraient',
    'débraguettassent',
    'débraguettassiez',
    'débraguetterions',
    'débrouillardises',
    'débrouillassions',
    'débrouilleraient',
    'débroussaillâmes',
    'débroussaillasse',
    'débroussaillâtes',
    'débroussaillerai',
    'débroussailleras',
    'débroussaillerez',
    'débroussaillions',
    'débudgétisassent',
    'débudgétisassiez',
    'débudgétiserions',
    'débureaucratisai',
    'débureaucratisas',
    'débureaucratisât',
    'débureaucratisée',
    'débureaucratiser',
    'débureaucratises',
    'débureaucratisés',
    'débureaucratisez',
    'décachetteraient',
    'décadenassassent',
    'décadenassassiez',
    'décadenasserions',
    'décalaminassions',
    'décalamineraient',
    'décalcifiassions',
    'décalcifications',
    'décalcifieraient',
    'décapitalisaient',
    'décapitalisasses',
    'décapitaliserais',
    'décapitaliserait',
    'décapitalisèrent',
    'décapitaliseriez',
    'décapitaliserons',
    'décapitaliseront',
    'décapuchonnaient',
    'décapuchonnasses',
    'décapuchonnerais',
    'décapuchonnerait',
    'décapuchonnèrent',
    'décapuchonneriez',
    'décapuchonnerons',
    'décapuchonneront',
    'décarbonatassent',
    'décarbonatassiez',
    'décarbonaterions',
    'décarcassassions',
    'décarcasseraient',
    'décarrelleraient',
    'décartellisation',
    'décartonnassions',
    'décartonneraient',
    'décentralisaient',
    'décentralisasses',
    'décentralisateur',
    'décentralisation',
    'décentraliserais',
    'décentraliserait',
    'décentralisèrent',
    'décentraliseriez',
    'décentraliserons',
    'décentraliseront',
    'déchagrinassions',
    'déchagrineraient',
    'déchaperonnaient',
    'déchaperonnasses',
    'déchaperonnerais',
    'déchaperonnerait',
    'déchaperonnèrent',
    'déchaperonneriez',
    'déchaperonnerons',
    'déchaperonneront',
    'déchevêtrassions',
    'déchevêtreraient',
    'déchevillassions',
    'déchevilleraient',
    'déchiffonnassent',
    'déchiffonnassiez',
    'déchiffonnerions',
    'déchiquetassions',
    'déchiquetterions',
    'déchlorurassions',
    'déchlorureraient',
    'déchristianisais',
    'déchristianisait',
    'déchristianisant',
    'déchristianisées',
    'déchristianisent',
    'déchristianisera',
    'déchristianisiez',
    'déchristianisons',
    'décimalisassions',
    'décimaliseraient',
    'décintrerassions',
    'déclavetteraient',
    'décléricalisâmes',
    'décléricalisasse',
    'décléricalisâtes',
    'décléricaliserai',
    'décléricaliseras',
    'décléricaliserez',
    'décléricalisions',
    'décliquetassions',
    'décliquetterions',
    'décloisonnassent',
    'décloisonnassiez',
    'décloisonnerions',
    'décolletteraient',
    'décolonisassions',
    'décoloniseraient',
    'décommandassions',
    'décommanderaient',
    'décomplexassions',
    'décomplexeraient',
    'décompressassent',
    'décompressassiez',
    'décompresserions',
    'décomprimassions',
    'décomprimeraient',
    'déconcentrassent',
    'déconcentrassiez',
    'déconcentrations',
    'déconcentrerions',
    'déconcertassions',
    'déconcerteraient',
    'déconditionnâmes',
    'déconditionnasse',
    'déconditionnâtes',
    'déconditionnerai',
    'déconditionneras',
    'déconditionnerez',
    'déconditionnions',
    'décongestionnais',
    'décongestionnait',
    'décongestionnant',
    'décongestionnées',
    'décongestionnent',
    'décongestionnera',
    'décongestionniez',
    'décongestionnons',
    'déconnectassions',
    'déconnecteraient',
    'déconseillassent',
    'déconseillassiez',
    'déconseillerions',
    'déconsidérassent',
    'déconsidérassiez',
    'déconsidérations',
    'déconsidérerions',
    'déconsignassions',
    'déconsigneraient',
    'déconstipassions',
    'déconstiperaient',
    'décontaminassent',
    'décontaminassiez',
    'décontaminations',
    'décontaminerions',
    'décontenançaient',
    'décontenançasses',
    'décontenancerais',
    'décontenancerait',
    'décontenancèrent',
    'décontenanceriez',
    'décontenancerons',
    'décontenanceront',
    'décontractassent',
    'décontractassiez',
    'décontracterions',
    'décortiquassions',
    'décortiqueraient',
    'décourageassions',
    'découronnassions',
    'découronneraient',
    'décriminalisâmes',
    'décriminalisasse',
    'décriminalisâtes',
    'décriminaliserai',
    'décriminaliseras',
    'décriminaliserez',
    'décriminalisions',
    'déculpabilisâmes',
    'déculpabilisasse',
    'déculpabilisâtes',
    'déculpabiliserai',
    'déculpabiliseras',
    'déculpabiliserez',
    'déculpabilisions',
    'dédifférenciâmes',
    'dédifférenciasse',
    'dédifférenciâtes',
    'dédifférencierai',
    'dédifférencieras',
    'dédifférencierez',
    'dédifférenciions',
    'dédommageassions',
    'dédramatisassent',
    'dédramatisassiez',
    'dédramatiserions',
    'défavorisassions',
    'défavoriseraient',
    'définitionnelles',
    'défouraillassent',
    'défouraillassiez',
    'défouraillerions',
    'défraîchissaient',
    'défrancisassions',
    'défranciseraient',
    'dégasolinassions',
    'dégasolineraient',
    'dégauchissements',
    'dégazolinassions',
    'dégazolineraient',
    'dégingandassions',
    'déginganderaient',
    'dégoudronnassent',
    'dégoudronnassiez',
    'dégoudronnerions',
    'dégoupillassions',
    'dégoupilleraient',
    'dégourdissements',
    'dégringolassions',
    'dégringoleraient',
    'dégrossissements',
    'dégrouillassions',
    'dégrouilleraient',
    'déharnachassions',
    'déharnacheraient',
    'délabialisassent',
    'délabialisassiez',
    'délabialiserions',
    'délignifiassions',
    'délignifieraient',
    'démagnétisassent',
    'démagnétisassiez',
    'démagnétisations',
    'démagnétiserions',
    'démaigrissements',
    'démaillotassions',
    'démailloteraient',
    'démantibulassent',
    'démantibulassiez',
    'démantibulerions',
    'démaquillassions',
    'démaquilleraient',
    'démastiquassions',
    'démastiqueraient',
    'dématérialisâmes',
    'dématérialisasse',
    'dématérialisâtes',
    'dématérialiserai',
    'dématérialiseras',
    'dématérialiserez',
    'dématérialisions',
    'déméthanisassent',
    'déméthanisassiez',
    'déméthaniserions',
    'demi-circulaires',
    'démilitarisaient',
    'démilitarisasses',
    'démilitarisation',
    'démilitariserais',
    'démilitariserait',
    'démilitarisèrent',
    'démilitariseriez',
    'démilitariserons',
    'démilitariseront',
    'déminéralisaient',
    'déminéralisasses',
    'déminéralisation',
    'déminéraliserais',
    'déminéraliserait',
    'déminéralisèrent',
    'déminéraliseriez',
    'déminéraliserons',
    'déminéraliseront',
    'démissionnassent',
    'démissionnassiez',
    'démissionnerions',
    'démobilisassions',
    'démobilisatrices',
    'démobiliseraient',
    'démocratiquement',
    'démocratisassent',
    'démocratisassiez',
    'démocratisations',
    'démocratiserions',
    'démonétisassions',
    'démonétiseraient',
    'démoralisassions',
    'démoralisatrices',
    'démoraliseraient',
    'démouchetassions',
    'démouchetterions',
    'démouscaillaient',
    'démouscaillasses',
    'démouscaillerais',
    'démouscaillerait',
    'démouscaillèrent',
    'démouscailleriez',
    'démouscaillerons',
    'démouscailleront',
    'démoustiquassent',
    'démoustiquassiez',
    'démoustiquerions',
    'démultipliassent',
    'démultipliassiez',
    'démultiplicateur',
    'démultiplication',
    'démultiplierions',
    'démystifiassions',
    'démystificateurs',
    'démystifications',
    'démystificatrice',
    'démystifieraient',
    'démythifiassions',
    'démythifications',
    'démythifieraient',
    'dénasalisassions',
    'dénasaliseraient',
    'dénationalisâmes',
    'dénationalisasse',
    'dénationalisâtes',
    'dénationaliserai',
    'dénationaliseras',
    'dénationaliserez',
    'dénationalisions',
    'dénaturalisaient',
    'dénaturalisasses',
    'dénaturalisation',
    'dénaturaliserais',
    'dénaturaliserait',
    'dénaturalisèrent',
    'dénaturaliseriez',
    'dénaturaliserons',
    'dénaturaliseront',
    'dénébulisaissent',
    'dénébulisaissiez',
    'dénébulisassions',
    'dénébuliseraient',
    'dénicotinisaient',
    'dénicotinisasses',
    'dénicotiniserais',
    'dénicotiniserait',
    'dénicotinisèrent',
    'dénicotiniseriez',
    'dénicotiniserons',
    'dénicotiniseront',
    'dénitrifiassions',
    'dénitrifications',
    'dénitrifieraient',
    'dénotationnelles',
    'dénucléarisaient',
    'dénucléarisasses',
    'dénucléariserais',
    'dénucléariserait',
    'dénucléarisèrent',
    'dénucléariseriez',
    'dénucléariserons',
    'dénucléariseront',
    'dépaisselassions',
    'dépaissellerions',
    'dépaquetteraient',
    'déparaffinassent',
    'déparaffinassiez',
    'déparaffinerions',
    'déparasitassions',
    'déparasiteraient',
    'dépareillassions',
    'dépareilleraient',
    'départageassions',
    'départementalisa',
    'départementalise',
    'départementalisé',
    'dépassionnassent',
    'dépassionnassiez',
    'dépassionnerions',
    'dépatouillassent',
    'dépatouillassiez',
    'dépatouillerions',
    'dépelotonnassent',
    'dépelotonnassiez',
    'dépelotonnerions',
    'dépersonnalisais',
    'dépersonnalisait',
    'dépersonnalisant',
    'dépersonnalisées',
    'dépersonnalisent',
    'dépersonnalisera',
    'dépersonnalisiez',
    'dépersonnalisons',
    'déphosphorations',
    'déplafonnassions',
    'déplafonneraient',
    'dépolarisassions',
    'dépolariseraient',
    'dépolitisassions',
    'dépolitiseraient',
    'dépolymérisaient',
    'dépolymérisasses',
    'dépolymériserais',
    'dépolymériserait',
    'dépolymérisèrent',
    'dépolymériseriez',
    'dépolymériserons',
    'dépolymériseront',
    'dépontillassions',
    'dépontilleraient',
    'dépoussiérassent',
    'dépoussiérassiez',
    'dépoussiérerions',
    'dépressionnaires',
    'dépressurisaient',
    'dépressurisasses',
    'dépressurisation',
    'dépressuriserais',
    'dépressuriserait',
    'dépressurisèrent',
    'dépressuriseriez',
    'dépressuriserons',
    'dépressuriseront',
    'déprogrammassent',
    'déprogrammassiez',
    'déprogrammerions',
    'déprolétarisâmes',
    'déprolétarisasse',
    'déprolétarisâtes',
    'déprolétariserai',
    'déprolétariseras',
    'déprolétariserez',
    'déprolétarisions',
    'dépropanisassent',
    'dépropanisassiez',
    'dépropaniserions',
    'déraisonnassions',
    'déraisonneraient',
    'déréglementation',
    'désacclimataient',
    'désacclimatasses',
    'désacclimaterais',
    'désacclimaterait',
    'désacclimatèrent',
    'désacclimateriez',
    'désacclimaterons',
    'désacclimateront',
    'désaccordassions',
    'désaccorderaient',
    'désaccouplassent',
    'désaccouplassiez',
    'désaccouplerions',
    'désaccoutumaient',
    'désaccoutumances',
    'désaccoutumasses',
    'désaccoutumerais',
    'désaccoutumerait',
    'désaccoutumèrent',
    'désaccoutumeriez',
    'désaccoutumerons',
    'désaccoutumeront',
    'désacralisassent',
    'désacralisassiez',
    'désacraliserions',
    'désaffectassions',
    'désaffecteraient',
    'désaffectionnais',
    'désaffectionnait',
    'désaffectionnant',
    'désaffectionnées',
    'désaffectionnent',
    'désaffectionnera',
    'désaffectionniez',
    'désaffectionnons',
    'désaffiliassions',
    'désaffilieraient',
    'désagrégeassions',
    'désaimantassions',
    'désaimanteraient',
    'désambiguïsaient',
    'désambiguïsasses',
    'désambiguïserais',
    'désambiguïserait',
    'désambiguïsèrent',
    'désambiguïseriez',
    'désambiguïserons',
    'désambiguïseront',
    'désamidonnassent',
    'désamidonnassiez',
    'désamidonnerions',
    'désappariassions',
    'désapparieraient',
    'désappointassent',
    'désappointassiez',
    'désappointements',
    'désappointerions',
    'désapprendraient',
    'désapprobatrices',
    'désapprouvassent',
    'désapprouvassiez',
    'désapprouverions',
    'désapprovisionna',
    'désapprovisionne',
    'désapprovisionné',
    'désarçonnassions',
    'désarçonneraient',
    'désargentassions',
    'désargenteraient',
    'désarticulassent',
    'désarticulassiez',
    'désarticulations',
    'désarticulerions',
    'désassemblassent',
    'désassemblassiez',
    'désassemblerions',
    'désassimilassent',
    'désassimilassiez',
    'désassimilations',
    'désassimilerions',
    'désassortiraient',
    'désassortissions',
    'désatomisassions',
    'désatomiseraient',
    'désavantageaient',
    'désavantageasses',
    'désavantagerions',
    'désaveuglassions',
    'désaveugleraient',
    'désembourbassent',
    'désembourbassiez',
    'désembourberions',
    'désembourgeoisai',
    'désembourgeoisas',
    'désembourgeoisât',
    'désembourgeoisée',
    'désembourgeoiser',
    'désembourgeoisés',
    'désembourgeoisez',
    'désembouteillais',
    'désembouteillait',
    'désembouteillant',
    'désembouteillées',
    'désembouteillent',
    'désembouteillera',
    'désembouteilliez',
    'désembouteillons',
    'désembraieraient',
    'désembrayassions',
    'désembrayeraient',
    'désemmanchassent',
    'désemmanchassiez',
    'désemmancherions',
    'désemprisonnâmes',
    'désemprisonnasse',
    'désemprisonnâtes',
    'désemprisonnerai',
    'désemprisonneras',
    'désemprisonnerez',
    'désemprisonnions',
    'désencadrassions',
    'désencadreraient',
    'désencartassions',
    'désencarteraient',
    'désenchaînassent',
    'désenchaînassiez',
    'désenchaînerions',
    'désenchantassent',
    'désenchantassiez',
    'désenchantements',
    'désenchanterions',
    'désenclavassions',
    'désenclaveraient',
    'désencollassions',
    'désencolleraient',
    'désencombrassent',
    'désencombrassiez',
    'désencombrements',
    'désencombrerions',
    'désencrassassent',
    'désencrassassiez',
    'désencrasserions',
    'désendettassions',
    'désendetteraient',
    'désenflammassent',
    'désenflammassiez',
    'désenflammerions',
    'désengageassions',
    'désengorgeassent',
    'désengorgeassiez',
    'désengorgeraient',
    'désengourdirions',
    'désengourdissais',
    'désengourdissait',
    'désengourdissant',
    'désengourdissent',
    'désengourdissiez',
    'désengourdissons',
    'désenlaidiraient',
    'désenlaidissions',
    'désenrhumassions',
    'désenrhumeraient',
    'désensablassions',
    'désensableraient',
    'désensibilisâmes',
    'désensibilisasse',
    'désensibilisâtes',
    'désensibiliserai',
    'désensibiliseras',
    'désensibiliserez',
    'désensibilisions',
    'désensorcelaient',
    'désensorcelasses',
    'désensorcelèrent',
    'désensorcellerai',
    'désensorcelleras',
    'désensorcellerez',
    'désentoilassions',
    'désentoileraient',
    'désentortillâmes',
    'désentortillasse',
    'désentortillâtes',
    'désentortillerai',
    'désentortilleras',
    'désentortillerez',
    'désentortillions',
    'désentravassions',
    'désentraveraient',
    'désenveloppaient',
    'désenveloppasses',
    'désenvelopperais',
    'désenvelopperait',
    'désenveloppèrent',
    'désenvelopperiez',
    'désenvelopperons',
    'désenvelopperont',
    'désenvenimassent',
    'désenvenimassiez',
    'désenvenimerions',
    'désenverguassent',
    'désenverguassiez',
    'désenverguerions',
    'désépaissiraient',
    'désépaississions',
    'déséquilibraient',
    'déséquilibrasses',
    'déséquilibrerais',
    'déséquilibrerait',
    'déséquilibrèrent',
    'déséquilibreriez',
    'déséquilibrerons',
    'déséquilibreront',
    'désertifications',
    'désétablissaient',
    'désétatisassions',
    'désétatiseraient',
    'déshabillassions',
    'déshabilleraient',
    'déshabituassions',
    'déshabitueraient',
    'déshumanisassent',
    'déshumanisassiez',
    'déshumanisations',
    'déshumaniserions',
    'déshumidifiaient',
    'déshumidifiasses',
    'déshumidifierais',
    'déshumidifierait',
    'déshumidifièrent',
    'déshumidifieriez',
    'déshumidifierons',
    'déshumidifieront',
    'déshydratassions',
    'déshydrateraient',
    'déshydrogénaient',
    'déshydrogénasses',
    'déshydrogénation',
    'déshydrogénerais',
    'déshydrogénerait',
    'déshydrogénèrent',
    'déshydrogéneriez',
    'déshydrogénerons',
    'déshydrogéneront',
    'déshypothéquâmes',
    'déshypothéquasse',
    'déshypothéquâtes',
    'déshypothéquerai',
    'déshypothéqueras',
    'déshypothéquerez',
    'déshypothéquions',
    'désillusionnâmes',
    'désillusionnasse',
    'désillusionnâtes',
    'désillusionnerai',
    'désillusionneras',
    'désillusionnerez',
    'désillusionnions',
    'désincarnassions',
    'désincarneraient',
    'désincorporaient',
    'désincorporasses',
    'désincorporerais',
    'désincorporerait',
    'désincorporèrent',
    'désincorporeriez',
    'désincorporerons',
    'désincorporeront',
    'désincrustassent',
    'désincrustassiez',
    'désincrustations',
    'désincrusterions',
    'désinculpassions',
    'désinculperaient',
    'désinfectassions',
    'désinfecteraient',
    'désingularisâmes',
    'désingularisasse',
    'désingularisâtes',
    'désingulariserai',
    'désingulariseras',
    'désingulariserez',
    'désingularisions',
    'désinsectisaient',
    'désinsectisasses',
    'désinsectisation',
    'désinsectiserais',
    'désinsectiserait',
    'désinsectisèrent',
    'désinsectiseriez',
    'désinsectiserons',
    'désinsectiseront',
    'désintégrassions',
    'désintégreraient',
    'désintéressaient',
    'désintéressasses',
    'désintéressement',
    'désintéresserais',
    'désintéresserait',
    'désintéressèrent',
    'désintéresseriez',
    'désintéresserons',
    'désintéresseront',
    'désintoxications',
    'désintoxiquaient',
    'désintoxiquasses',
    'désintoxiquerais',
    'désintoxiquerait',
    'désintoxiquèrent',
    'désintoxiqueriez',
    'désintoxiquerons',
    'désintoxiqueront',
    'désinvestiraient',
    'désinvestissions',
    'désobligeassions',
    'désobstruaissent',
    'désobstruaissiez',
    'désobstruassions',
    'désobstrueraient',
    'désodorisassions',
    'désodoriseraient',
    'désolidarisaient',
    'désolidarisasses',
    'désolidariserais',
    'désolidariserait',
    'désolidarisèrent',
    'désolidariseriez',
    'désolidariserons',
    'désolidariseront',
    'désordonnassions',
    'désordonneraient',
    'désorganisassent',
    'désorganisassiez',
    'désorganisateurs',
    'désorganisations',
    'désorganisatrice',
    'désorganiserions',
    'désorientassions',
    'désorienteraient',
    'désoxygénassions',
    'désoxygéneraient',
    'dessaisissements',
    'dessous-de-table',
    'déstabilisassent',
    'déstabilisassiez',
    'déstabilisations',
    'déstabiliserions',
    'destructurassent',
    'destructurassiez',
    'déstructurations',
    'destructurerions',
    'désubjectivisais',
    'désubjectivisait',
    'désubjectivisant',
    'désubjectivisées',
    'désubjectivisent',
    'désubjectivisera',
    'désubjectivisiez',
    'désubjectivisons',
    'désynchronisâmes',
    'désynchronisasse',
    'désynchronisâtes',
    'désynchroniserai',
    'désynchroniseras',
    'désynchroniserez',
    'désynchronisions',
    'détonnelleraient',
    'détortillassions',
    'détortilleraient',
    'détransposassent',
    'détransposassiez',
    'détransposerions',
    'deutérocanonique',
    'dévalorisassions',
    'dévaloriseraient',
    'dévergondassions',
    'dévergonderaient',
    'déverrouillaient',
    'déverrouillasses',
    'déverrouillerais',
    'déverrouillerait',
    'déverrouillèrent',
    'déverrouilleriez',
    'déverrouillerons',
    'déverrouilleront',
    'dévirilisassions',
    'déviriliseraient',
    'dévitalisassions',
    'dévitaliseraient',
    'dévitrifiassions',
    'dévitrifications',
    'dévitrifieraient',
    'diagnostiquaient',
    'diagnostiquasses',
    'diagnostiquerais',
    'diagnostiquerait',
    'diagnostiquèrent',
    'diagnostiqueriez',
    'diagnostiquerons',
    'diagnostiqueront',
    'dialectalisaient',
    'dialectalisasses',
    'dialectaliserais',
    'dialectaliserait',
    'dialectalisèrent',
    'dialectaliseriez',
    'dialectaliserons',
    'dialectaliseront',
    'dialectisassions',
    'dialectiseraient',
    'diaphragmassions',
    'diaphragmatiques',
    'diaphragmeraient',
    'dictatorialement',
    'différenciassent',
    'différenciassiez',
    'différenciateurs',
    'différenciations',
    'différencierions',
    'différentiations',
    'digitalisassions',
    'dimensionnassent',
    'dimensionnassiez',
    'dimensionnerions',
    'diphtonguassions',
    'diphtongueraient',
    'diplomatiquement',
    'disciplinassions',
    'disciplineraient',
    'discontinuassent',
    'discontinuassiez',
    'discontinuerions',
    'disconviendrions',
    'discourtoisement',
    'discréditassions',
    'discréditeraient',
    'discrétionnaires',
    'discriminassions',
    'discriminatoires',
    'discrimineraient',
    'discutaillassent',
    'discutaillassiez',
    'discutaillerions',
    'dispendieusement',
    'disproportionnai',
    'disproportionnas',
    'disproportionnât',
    'disproportionnée',
    'disproportionner',
    'disproportionnes',
    'disproportionnés',
    'disproportionnez',
    'disputaillassent',
    'disputaillassiez',
    'disputaillerions',
    'disqualifiassent',
    'disqualifiassiez',
    'disqualification',
    'disqualifierions',
    'diversifiassions',
    'diversifications',
    'diversifieraient',
    'domestiquassions',
    'domestiqueraient',
    'donquichottismes',
    'dysorthographies',
    'ébouillantassent',
    'ébouillantassiez',
    'ébouillanterions',
    'ébourgeonnassent',
    'ébourgeonnassiez',
    'ébourgeonnements',
    'ébourgeonnerions',
    'écarquillassions',
    'écarquilleraient',
    'échanfreinassent',
    'échanfreinassiez',
    'échanfreinerions',
    'échantillonnages',
    'échantillonnâmes',
    'échantillonnasse',
    'échantillonnâtes',
    'échantillonnerai',
    'échantillonneras',
    'échantillonnerez',
    'échantillonneurs',
    'échantillonneuse',
    'échantillonnions',
    'échardonnassions',
    'échardonneraient',
    'échotomographies',
    'éclaboussassions',
    'éclabousseraient',
    'éclaircissements',
    'écouvillonnaient',
    'écouvillonnasses',
    'écouvillonnerais',
    'écouvillonnerait',
    'écouvillonnèrent',
    'écouvillonneriez',
    'écouvillonnerons',
    'écouvillonneront',
    'écrabouillassent',
    'écrabouillassiez',
    'écrabouillements',
    'écrabouillerions',
    'écrivaillassions',
    'écrivailleraient',
    'effarouchassions',
    'effaroucheraient',
    'égravillonnaient',
    'égravillonnasses',
    'égravillonnerais',
    'égravillonnerait',
    'égravillonnèrent',
    'égravillonneriez',
    'égravillonnerons',
    'égravillonneront',
    'électrifiassions',
    'électrifications',
    'électrifieraient',
    'électrobiologies',
    'électrochimiques',
    'électrocutassent',
    'électrocutassiez',
    'électrocuterions',
    'électrolysassent',
    'électrolysassiez',
    'électrolyserions',
    'électromécanique',
    'électroniciennes',
    'électronisassent',
    'électronisassiez',
    'électroniserions',
    'électroponctures',
    'électropositives',
    'électropunctures',
    'électrostriction',
    'électrotechnique',
    'électrothérapies',
    'éléphantiasiques',
    'emballottassions',
    'emballotteraient',
    'embarbouillaient',
    'embarbouillasses',
    'embarbouillerais',
    'embarbouillerait',
    'embarbouillèrent',
    'embarbouilleriez',
    'embarbouillerons',
    'embarbouilleront',
    'embarrassassions',
    'embarrasseraient',
    'embastillassions',
    'embastilleraient',
    'embastionnassent',
    'embastionnassiez',
    'embastionnerions',
    'embellissassions',
    'emberlificotâmes',
    'emberlificotasse',
    'emberlificotâtes',
    'emberlificoterai',
    'emberlificoteras',
    'emberlificoterez',
    'emberlificoteurs',
    'emberlificoteuse',
    'emberlificotions',
    'embobelinassions',
    'embobelineraient',
    'embottelleraient',
    'embourgeoisaient',
    'embourgeoisasses',
    'embourgeoisement',
    'embourgeoiserais',
    'embourgeoiserait',
    'embourgeoisèrent',
    'embourgeoiseriez',
    'embourgeoiserons',
    'embourgeoiseront',
    'embouteillassent',
    'embouteillassiez',
    'embouteillerions',
    'embrouillassions',
    'embrouilleraient',
    'embroussaillâmes',
    'embroussaillasse',
    'embroussaillâtes',
    'embroussaillerai',
    'embroussailleras',
    'embroussaillerez',
    'embroussaillions',
    'émerillonnassent',
    'émerillonnassiez',
    'émerillonnerions',
    'émerveillassions',
    'émerveilleraient',
    'emmagasinassions',
    'emmagasineraient',
    'emmaillotassions',
    'emmailloteraient',
    'emmitouflassions',
    'emmitoufleraient',
    'emmortaisassions',
    'emmortaiseraient',
    'emmouscaillaient',
    'emmouscaillasses',
    'emmouscaillerais',
    'emmouscaillerait',
    'emmouscaillèrent',
    'emmouscailleriez',
    'emmouscaillerons',
    'emmouscailleront',
    'émoustillassions',
    'émoustilleraient',
    'empapillotassent',
    'empapillotassiez',
    'empapilloterions',
    'empaquetteraient',
    'empoisonnassions',
    'empoisonneraient',
    'empoissonnassent',
    'empoissonnassiez',
    'empoissonnements',
    'empoissonnerions',
    'empoussiérassent',
    'empoussiérassiez',
    'empoussiérerions',
    'emprisonnassions',
    'emprisonneraient',
    'empuantissements',
    'émulsionnassions',
    'émulsionneraient',
    'encanaillassions',
    'encanailleraient',
    'encapuchonnaient',
    'encapuchonnasses',
    'encapuchonnerais',
    'encapuchonnerait',
    'encapuchonnèrent',
    'encapuchonneriez',
    'encapuchonnerons',
    'encapuchonneront',
    'encartonnassions',
    'encartonneraient',
    'encartouchassent',
    'encartouchassiez',
    'encartoucherions',
    'encaustiquassent',
    'encaustiquassiez',
    'encaustiquerions',
    'encéphalogrammes',
    'encéphalographie',
    'enchaperonnaient',
    'enchaperonnasses',
    'enchaperonnerais',
    'enchaperonnerait',
    'enchaperonnèrent',
    'enchaperonneriez',
    'enchaperonnerons',
    'enchaperonneront',
    'enchatonnassions',
    'enchatonneraient',
    'enchevauchassent',
    'enchevauchassiez',
    'enchevaucherions',
    'enchevêtrassions',
    'enchevêtreraient',
    'enchifrenassions',
    'enchifrèneraient',
    'encliquetassions',
    'encliquetterions',
    'encourageassions',
    'endimanchassions',
    'endimancheraient',
    'endivisionnaient',
    'endivisionnasses',
    'endivisionnerais',
    'endivisionnerait',
    'endivisionnèrent',
    'endivisionneriez',
    'endivisionnerons',
    'endivisionneront',
    'endoctrinassions',
    'endoctrineraient',
    'endolorissements',
    'endommageassions',
    'enfutaillassions',
    'enfutailleraient',
    'engargoussassent',
    'engargoussassiez',
    'engargousserions',
    'engloutissements',
    'engourdissements',
    'engrumelleraient',
    'enguirlandassent',
    'enguirlandassiez',
    'enguirlanderions',
    'enharnachassions',
    'enharnacheraient',
    'enorgueillirions',
    'enorgueillissais',
    'enorgueillissait',
    'enorgueillissant',
    'enorgueillissent',
    'enorgueillissiez',
    'enorgueillissons',
    'enquiquinassions',
    'enquiquineraient',
    'enrégimentassent',
    'enrégimentassiez',
    'enrégimenterions',
    'enregistrassions',
    'enregistreraient',
    'ensanglantassent',
    'ensanglantassiez',
    'ensanglanterions',
    'ensevelissements',
    'ensoleillassions',
    'ensoleilleraient',
    'ensorcelleraient',
    'enthousiasmaient',
    'enthousiasmasses',
    'enthousiasmerais',
    'enthousiasmerait',
    'enthousiasmèrent',
    'enthousiasmeriez',
    'enthousiasmerons',
    'enthousiasmeront',
    'entortillassions',
    'entortilleraient',
    'entraccordassiez',
    'entraccorderions',
    'entraccusassions',
    'entradmirassions',
    'entrebâillassent',
    'entrebâillassiez',
    'entrebâillements',
    'entrebâillerions',
    'entrebattissions',
    'entrechoquassent',
    'entrechoquassiez',
    'entrechoquements',
    'entrechoquerions',
    'entrecoupassions',
    'entrecouperaient',
    'entrecroisassent',
    'entrecroisassiez',
    'entrecroisements',
    'entrecroiserions',
    'entre-déchirâmes',
    'entre-déchirâtes',
    'entre-déchirerez',
    'entre-déchirions',
    'entre-détruiriez',
    'entre-détruirons',
    'entre-détruisiez',
    'entre-détruisons',
    'entre-dévoreriez',
    'entre-dévorerons',
    'entre-frapperiez',
    'entre-frapperons',
    'entre-haïssaient',
    'entre-heurteriez',
    'entre-heurterons',
    'entrelardassions',
    'entrelarderaient',
    'entre-louassions',
    'entre-mangerions',
    'entre-nuisissiez',
    'entreprendraient',
    'entre-regardâmes',
    'entre-regardâtes',
    'entre-regarderez',
    'entre-regardions',
    'entretaillassiez',
    'entretaillerions',
    'entretiendraient',
    'entretoisassions',
    'entretoiseraient',
    'entrevoûtassions',
    'entrevouteraient',
    'envieillissaient',
    'environnementale',
    'environnementaux',
    'épiloguerassions',
    'épiphénoménismes',
    'épiphénoménistes',
    'épistémologiques',
    'épistémologistes',
    'époussetteraient',
    'époustouflassent',
    'époustouflassiez',
    'époustouflerions',
    'épouvantablement',
    'équimoléculaires',
    'équipotentielles',
    'équiprobabilités',
    'érythroblastoses',
    'escarmouchassent',
    'escarmouchassiez',
    'escarmoucherions',
    'escarrifiassions',
    'escarrifieraient',
    'esclavageassions',
    'estampillassions',
    'estampilleraient',
    'estrapassassions',
    'estrapasseraient',
    'étouffe-chrétien',
    'étrésillonnaient',
    'étrésillonnasses',
    'étrésillonnement',
    'étrésillonnerais',
    'étrésillonnerait',
    'étrésillonnèrent',
    'étrésillonneriez',
    'étrésillonnerons',
    'étrésillonneront',
    'étronçonnassions',
    'étronçonneraient',
    'étymologiquement',
    'euro-obligations',
    'européanisassent',
    'européanisassiez',
    'européanisations',
    'européaniserions',
    'évangélisassions',
    'évangélisatrices',
    'évangéliseraient',
    'excellentissimes',
    'excommuniassions',
    'excommunications',
    'excommunieraient',
    'excursionnassent',
    'excursionnassiez',
    'excursionnerions',
    'exemplifiassions',
    'exemplifications',
    'exemplifieraient',
    'exhibitionnismes',
    'exhibitionnistes',
    'existentialismes',
    'existentialistes',
    'expéditionnaires',
    'expérimentassent',
    'expérimentassiez',
    'expérimentateurs',
    'expérimentations',
    'expérimentatrice',
    'expérimenterions',
    'expert-comptable',
    'expressionnismes',
    'expressionnistes',
    'extériorisassent',
    'extériorisassiez',
    'extériorisations',
    'extérioriserions',
    'exterritorialité',
    'extragalactiques',
    'extrajudiciaires',
    'extra-sensoriels',
    'extra-terrestres',
    'extravaguassions',
    'extravagueraient',
    'extrême-oriental',
    'familiarisassent',
    'familiarisassiez',
    'familiarisations',
    'familiariserions',
    'fanfaronnassions',
    'fanfaronneraient',
    'fanfreluchassent',
    'fanfreluchassiez',
    'fanfrelucherions',
    'fantasmagoriques',
    'farfouillassions',
    'farfouilleraient',
    'fédéralisassions',
    'fédéraliseraient',
    'ferrimagnétismes',
    'feuiletisassions',
    'feuilletiserions',
    'feuilletonesques',
    'feuilletonnesque',
    'feuilletteraient',
    'fidéicommissaire',
    'finlandisassions',
    'finlandiseraient',
    'finno-ougriennes',
    'fixe-chaussettes',
    'folichonnassions',
    'folichonneraient',
    'fonctionnalismes',
    'fonctionnarisais',
    'fonctionnarisait',
    'fonctionnarisant',
    'fonctionnarisées',
    'fonctionnarisent',
    'fonctionnarisera',
    'fonctionnarisiez',
    'fonctionnarismes',
    'fonctionnarisons',
    'fonctionnassions',
    'fonctionneraient',
    'fondamentalement',
    'fondamentalismes',
    'fondamentalistes',
    'fractionnassions',
    'fractionneraient',
    'fragmentairement',
    'franc-maçonnerie',
    'franc-maçonnique',
    'francs-bourgeois',
    'francs-quartiers',
    'fraternisassions',
    'fraterniseraient',
    'frictionnassions',
    'frictionneraient',
    'frigorifiassions',
    'frigorifieraient',
    'frigorifugeaient',
    'frigorifugeasses',
    'frigorifugerions',
    'froufroutassions',
    'froufrouteraient',
    'galvanoplastique',
    'gardes-barrières',
    'gardes-chiourmes',
    'gargouillassions',
    'gargouilleraient',
    'gastro-entérites',
    'gélatinisassions',
    'gélatiniseraient',
    'généalogiquement',
    'généralisassions',
    'généralisatrices',
    'généraliseraient',
    'génito-urinaires',
    'géographiquement',
    'géométrisassions',
    'géométriseraient',
    'glandouillassent',
    'glandouillassiez',
    'glandouillerions',
    'glougloutassions',
    'glouglouteraient',
    'goguenardassions',
    'goguenarderaient',
    'gonadostimulines',
    'goupillonnassent',
    'goupillonnassiez',
    'goupillonnerions',
    'gouvernementales',
    'graillonnassions',
    'graillonneraient',
    'grammaticalement',
    'grammaticalisais',
    'grammaticalisait',
    'grammaticalisant',
    'grammaticalisées',
    'grammaticalisent',
    'grammaticalisera',
    'grammaticalisiez',
    'grammaticalisons',
    'grenouillassions',
    'grenouilleraient',
    'gribouillassions',
    'gribouilleraient',
    'gueuletonnassent',
    'gueuletonnassiez',
    'gueuletonnerions',
    'guillemetassions',
    'guillemetterions',
    'guillotinassions',
    'guillotineraient',
    'hannetonnassions',
    'hannetonneraient',
    'haut-commissaire',
    'haut-de-chausses',
    'hautes-fidélités',
    'hauts-de-chausse',
    'hebdomadairement',
    'hématopoïétiques',
    'hémicylindriques',
    'hérissonnassions',
    'hérissonneraient',
    'hétérocentriques',
    'hétérochromosome',
    'hétéromorphismes',
    'hétérosexualités',
    'hiérarchiquement',
    'hiérarchisassent',
    'hiérarchisassiez',
    'hiérarchisations',
    'hiérarchiserions',
    'hiérogrammatiste',
    'hippopotamesques',
    'historiographies',
    'homme-grenouille',
    'hommes-sandwichs',
    'homogénéifiaient',
    'homogénéifiasses',
    'homogénéifierais',
    'homogénéifierait',
    'homogénéifièrent',
    'homogénéifieriez',
    'homogénéifierons',
    'homogénéifieront',
    'homogénéisassent',
    'homogénéisassiez',
    'homogénéisateurs',
    'homogénéisations',
    'homogénéisatrice',
    'homogénéiserions',
    'hormonothérapies',
    'horokilométrique',
    'hospitalisassent',
    'hospitalisassiez',
    'hospitalisations',
    'hospitaliserions',
    'hydro-électrique',
    'hydroélectriques',
    'hydrofugeassions',
    'hydropneumatique',
    'hydrothérapiques',
    'hyperboliquement',
    'hyperchlorhydrie',
    'hypersensibilité',
    'hypertrophieriez',
    'hypertrophierons',
    'hypochlorhydries',
    'hypostasiassions',
    'hypostasieraient',
    'hypothéquassions',
    'hypothéqueraient',
    'hypothétiquement',
    'hypothyroïdismes',
    'idéologisassions',
    'idéologiseraient',
    'ignominieusement',
    'illusionnassions',
    'illusionneraient',
    'immatérialisâmes',
    'immatérialisasse',
    'immatérialisâtes',
    'immatérialiserai',
    'immatérialiseras',
    'immatérialiserez',
    'immatérialisions',
    'immatriculassent',
    'immatriculassiez',
    'immatriculations',
    'immatriculerions',
    'immobilisassions',
    'immobiliseraient',
    'immortalisassent',
    'immortalisassiez',
    'immortaliserions',
    'immunocompétente',
    'immunocompétents',
    'immunodépresseur',
    'immunodépressifs',
    'immunodépressive',
    'immunoglobulines',
    'immunotolérantes',
    'imparisyllabique',
    'impatientassions',
    'impatienteraient',
    'impatronisassent',
    'impatronisassiez',
    'impatroniserions',
    'impénétrabilités',
    'imperceptibilité',
    'imperméabilisais',
    'imperméabilisait',
    'imperméabilisant',
    'imperméabilisées',
    'imperméabilisent',
    'imperméabilisera',
    'imperméabilisiez',
    'imperméabilisons',
    'imperturbabilité',
    'implémentassions',
    'implémenteraient',
    'impraticabilités',
    'imprescriptibles',
    'impressionnables',
    'impressionnaient',
    'impressionnantes',
    'impressionnasses',
    'impressionnerais',
    'impressionnerait',
    'impressionnèrent',
    'impressionneriez',
    'impressionnerons',
    'impressionneront',
    'impressionnismes',
    'impressionnistes',
    'imprévisibilités',
    'inaccessibilités',
    'inadmissibilités',
    'inassouvissables',
    'incombustibilité',
    'incommensurables',
    'incommutabilités',
    'incomparablement',
    'incompatibilités',
    'incompréhensible',
    'incompréhensions',
    'incompréhensives',
    'inconcevablement',
    'inconditionnelle',
    'inconstructibles',
    'incontestabilité',
    'incorrigiblement',
    'incorruptibilité',
    'incrémentassions',
    'incrémenteraient',
    'incristallisable',
    'indébrouillables',
    'indéfectibilités',
    'indéfectiblement',
    'indépendantismes',
    'indépendantistes',
    'indéterminations',
    'indiscutablement',
    'indisponibilités',
    'indissolubilités',
    'indissolublement',
    'individualisâmes',
    'individualisasse',
    'individualisâtes',
    'individualiserai',
    'individualiseras',
    'individualiserez',
    'individualisions',
    'individuellement',
    'indo-européennes',
    'indulgenciassent',
    'indulgenciassiez',
    'indulgencierions',
    'industrialisâmes',
    'industrialisasse',
    'industrialisâtes',
    'industrialiserai',
    'industrialiseras',
    'industrialiserez',
    'industrialisions',
    'industriellement',
    'inébranlablement',
    'inexplicablement',
    'inextricablement',
    'infantilisassent',
    'infantilisassiez',
    'infantiliserions',
    'infériorisassent',
    'infériorisassiez',
    'infériorisations',
    'inférioriserions',
    'infermentescible',
    'infléchissements',
    'informaticiennes',
    'informationnelle',
    'informatisassent',
    'informatisassiez',
    'informatisations',
    'informatiserions',
    'infranchissables',
    'infructueusement',
    'infundibuliforme',
    'inintelligemment',
    'initialisassions',
    'initialiseraient',
    'insaisissabilité',
    'insatisfaisantes',
    'insensibilisâmes',
    'insensibilisasse',
    'insensibilisâtes',
    'insensibiliserai',
    'insensibiliseras',
    'insensibiliserez',
    'insensibilisions',
    'insolubilisaient',
    'insolubilisasses',
    'insolubiliserais',
    'insolubiliserait',
    'insolubilisèrent',
    'insolubiliseriez',
    'insolubiliserons',
    'insolubiliseront',
    'insonorisassions',
    'insonoriseraient',
    'institutionnelle',
    'instrumentalisme',
    'instrumentassent',
    'instrumentassiez',
    'instrumentations',
    'instrumenterions',
    'insubmersibilité',
    'insubordinations',
    'insulinothérapie',
    'insurrectionnels',
    'intarissablement',
    'intégrationniste',
    'intellectualisai',
    'intellectualisas',
    'intellectualisât',
    'intellectualisée',
    'intellectualiser',
    'intellectualises',
    'intellectualisés',
    'intellectualisez',
    'intellectualisme',
    'intellectualiste',
    'intellectualités',
    'intelligibilités',
    'intelligiblement',
    'intempestivement',
    'intensifiassions',
    'intensifications',
    'intensifieraient',
    'intentionnalités',
    'intercellulaires',
    'interceptassions',
    'intercepteraient',
    'interchangeables',
    'interclassassent',
    'interclassassiez',
    'interclasserions',
    'intercontinental',
    'interdépendances',
    'interdépendantes',
    'interférométries',
    'interfoliassions',
    'interfolieraient',
    'interindividuels',
    'intériorisassent',
    'intériorisassiez',
    'intériorisations',
    'intérioriserions',
    'interjetteraient',
    'interlignassions',
    'interligneraient',
    'interloquassions',
    'interloqueraient',
    'interminablement',
    'interministériel',
    'intermoléculaire',
    'intermusculaires',
    'internalisations',
    'internationalisa',
    'internationalise',
    'internationalisé',
    'internationalité',
    'interpellassions',
    'interpellatrices',
    'interpelleraient',
    'interpénétration',
    'interpénétreriez',
    'interpénétrerons',
    'interpersonnelle',
    'interplanétaires',
    'interprétassions',
    'interpréteraient',
    'interrogeassions',
    'interrompissions',
    'intersectorielle',
    'intervertébrales',
    'intervertiraient',
    'intervertissions',
    'interviendraient',
    'interviewassions',
    'intervieweraient',
    'intracellulaires',
    'intramoléculaire',
    'intramusculaires',
    'intransitivement',
    'intransmissibles',
    'intransportables',
    'introduisissions',
    'intussusceptions',
    'inventoriassions',
    'inventorieraient',
    'involontairement',
    'invraisemblables',
    'invraisemblances',
    'invulnérabilités',
    'irréconciliables',
    'irréductibilités',
    'irréductiblement',
    'irrémédiablement',
    'irrémissiblement',
    'irrépréhensibles',
    'irrésistiblement',
    'irresponsabilité',
    'irrétrécissables',
    'irrévérencieuses',
    'irréversibilités',
    'irréversiblement',
    'italianisassions',
    'italianiseraient',
    'juridictionnelle',
    'jurisprudentiels',
    'kaléidoscopiques',
    'kinésithérapeute',
    'labyrinthodontes',
    'lactodensimètres',
    'latino-américain',
    'lexicalisassions',
    'lexicaliseraient',
    'lexicographiques',
    'libéralisassions',
    'libéraliseraient',
    'libre-échangisme',
    'libre-échangiste',
    'libres-penseuses',
    'linguistiquement',
    'lithographiaient',
    'lithographiasses',
    'lithographierais',
    'lithographierait',
    'lithographièrent',
    'lithographieriez',
    'lithographierons',
    'lithographieront',
    'lithotypographie',
    'locataire-gérant',
    'locations-ventes',
    'lyophilisassions',
    'lyophiliseraient',
    'macadamisassions',
    'macadamiseraient',
    'mâchouillassions',
    'mâchouilleraient',
    'macro-économique',
    'madrigalisassent',
    'madrigalisassiez',
    'madrigaliserions',
    'magnétocassettes',
    'magnétodynamique',
    'magnétoscopaient',
    'magnétoscopasses',
    'magnétoscoperais',
    'magnétoscoperait',
    'magnétoscopèrent',
    'magnétoscoperiez',
    'magnétoscoperons',
    'magnétoscoperont',
    'magnétostriction',
    'maître-assistant',
    'malintentionnées',
    'malléabilisaient',
    'malléabilisasses',
    'malléabilisation',
    'malléabiliserais',
    'malléabiliserait',
    'malléabilisèrent',
    'malléabiliseriez',
    'malléabiliserons',
    'malléabiliseront',
    'mannequinassions',
    'mannequineraient',
    'manoeuvrabilités',
    'manufacturassent',
    'manufacturassiez',
    'manufacturerions',
    'manutentionnaire',
    'manutentionnâmes',
    'manutentionnasse',
    'manutentionnâtes',
    'manutentionnerai',
    'manutentionneras',
    'manutentionnerez',
    'manutentionnions',
    'maquereautassent',
    'maquereautassiez',
    'maquereauterions',
    'maquignonnassent',
    'maquignonnassiez',
    'maquignonnerions',
    'maréchal-ferrant',
    'marginalisassent',
    'marginalisassiez',
    'marginaliserions',
    'martins-pêcheurs',
    'masculinisassent',
    'masculinisassiez',
    'masculiniserions',
    'matérialisassent',
    'matérialisassiez',
    'matérialisations',
    'matérialiserions',
    'mathématiciennes',
    'mathématiquement',
    'mathématisassent',
    'mathématisassiez',
    'mathématisations',
    'mathématiserions',
    'matrimonialement',
    'maximalisassions',
    'maximaliseraient',
    'mécanographiques',
    'mécontentassions',
    'mécontenteraient',
    'médicalisassions',
    'médicaliseraient',
    'médicamentassent',
    'médicamentassiez',
    'médicamenterions',
    'méditerranéennes',
    'mélancoliquement',
    'mélanostimulines',
    'mensualisassions',
    'mensualiseraient',
    'méphistophélique',
    'mercantilisaient',
    'mercantilisasses',
    'mercantiliserais',
    'mercantiliserait',
    'mercantilisèrent',
    'mercantiliseriez',
    'mercantiliserons',
    'mercantiliseront',
    'merveilleusement',
    'mésintelligences',
    'métalinguistique',
    'métallographique',
    'métalloplastique',
    'métalloprotéines',
    'métamorphisaient',
    'métamorphisasses',
    'métamorphiserais',
    'métamorphiserait',
    'métamorphisèrent',
    'métamorphiseriez',
    'métamorphiserons',
    'métamorphiseront',
    'métamorphosaient',
    'métamorphosasses',
    'métamorphoserais',
    'métamorphoserait',
    'métamorphosèrent',
    'métamorphoseriez',
    'métamorphoserons',
    'métamorphoseront',
    'métaphoriquement',
    'métaphosphorique',
    'métaphysiciennes',
    'métaphysiquement',
    'métapsychologies',
    'microbiologistes',
    'microdissections',
    'microfilmassions',
    'microfilmeraient',
    'micro-ordinateur',
    'micro-organismes',
    'microprocesseurs',
    'militarisassions',
    'militariseraient',
    'minéralisassions',
    'minéralisatrices',
    'minéraliseraient',
    'miniaturisassent',
    'miniaturisassiez',
    'miniaturisations',
    'miniaturiserions',
    'mini-ordinateurs',
    'miséricordieuses',
    'mithridatassions',
    'mithridateraient',
    'mithridatisaient',
    'mithridatisasses',
    'mithridatisation',
    'mithridatiserais',
    'mithridatiserait',
    'mithridatisèrent',
    'mithridatiseriez',
    'mithridatiserons',
    'mithridatiseront',
    'molletonnassions',
    'molletonneraient',
    'mondialisassions',
    'mondialiseraient',
    'monocaméralismes',
    'monochromatiques',
    'monopolisassions',
    'monopolisatrices',
    'monopoliseraient',
    'morganatiquement',
    'moucheronnassent',
    'moucheronnassiez',
    'moucheronnerions',
    'mouvementassions',
    'mouvementeraient',
    'multicellulaires',
    'multiplexassions',
    'multiplexeraient',
    'multiprocesseurs',
    'multitraitements',
    'municipalisaient',
    'municipalisasses',
    'municipalisation',
    'municipaliserais',
    'municipaliserait',
    'municipalisèrent',
    'municipaliseriez',
    'municipaliserons',
    'municipaliseront',
    'munitionnassions',
    'munitionneraient',
    'nationalisassent',
    'nationalisassiez',
    'nationalisations',
    'nationaliserions',
    'naturalisassions',
    'naturaliseraient',
    'négro-africaines',
    'negro-spirituals',
    'négro-spirituals',
    'néo-classicismes',
    'néo-colonialisme',
    'néocolonialismes',
    'néocolonialistes',
    'néo-platoniciens',
    'néo-positivismes',
    'néo-positivistes',
    'neurochirurgical',
    'neurochirurgiens',
    'neurodépresseurs',
    'neurophysiologie',
    'neuropsychiatres',
    'neuropsychiatrie',
    'neuropsychologie',
    'neuro-végétatifs',
    'neuro-végétative',
    'neutralisassions',
    'neutraliseraient',
    'nicotinisassions',
    'nicotiniseraient',
    'nominalisassions',
    'nominaliseraient',
    'non-acceptations',
    'non-belligérance',
    'non-belligérante',
    'non-belligérants',
    'non-combattantes',
    'non-comparutions',
    'non-conciliation',
    'non-conformismes',
    'non-conformistes',
    'non-directivités',
    'non-euclidiennes',
    'non-intervention',
    'non-rapatriement',
    'non-remboursable',
    'non-satisfaction',
    'non-spécialistes',
    'nord-américaines',
    'nord-vietnamiens',
    'nue-propriétaire',
    'obscurcissements',
    'obstructionnisme',
    'obstructionniste',
    'occasionnalismes',
    'occasionnassions',
    'occasionneraient',
    'occidentalisâmes',
    'occidentalisasse',
    'occidentalisâtes',
    'occidentaliserai',
    'occidentaliseras',
    'occidentaliserez',
    'occidentalisions',
    'océanographiques',
    'oeilletonnassent',
    'oeilletonnassiez',
    'oeilletonnerions',
    'oeils-de-perdrix',
    'officialisassent',
    'officialisassiez',
    'officialisations',
    'officialiserions',
    'oligopolistiques',
    'omnidirectionnel',
    'omnipraticiennes',
    'ophtalmologiques',
    'ophtalmologistes',
    'oppositionnelles',
    'optimalisassions',
    'optimaliseraient',
    'ordonnançassions',
    'ordonnanceraient',
    'organisationnels',
    'orgueilleusement',
    'orthochromatique',
    'orthographiaient',
    'orthographiasses',
    'orthographierais',
    'orthographierait',
    'orthographièrent',
    'orthographieriez',
    'orthographierons',
    'orthographieront',
    'orthosympathique',
    'ouest-allemandes',
    'outrepassassions',
    'outrepasseraient',
    'ouvre-bouteilles',
    'oxyacétyléniques',
    'oxygénothérapies',
    'paillassonnaient',
    'paillassonnasses',
    'paillassonnerais',
    'paillassonnerait',
    'paillassonnèrent',
    'paillassonneriez',
    'paillassonnerons',
    'paillassonneront',
    'paléochrétiennes',
    'paléohistologies',
    'paléomagnétismes',
    'paléontologiques',
    'paléontologistes',
    'palissonnassions',
    'palissonneraient',
    'panaméricanismes',
    'panoramiquassent',
    'panoramiquassiez',
    'panoramiquerions',
    'papiers-monnaies',
    'papillonnassions',
    'papillonneraient',
    'paraisonnassions',
    'paraisonneraient',
    'paralittératures',
    'parallélépipèdes',
    'parallélisassent',
    'parallélisassiez',
    'paralléliserions',
    'parallélogrammes',
    'parangonnassions',
    'parangonneraient',
    'paraphrasassions',
    'paraphraseraient',
    'parapsychologies',
    'parasympathiques',
    'parasynthétiques',
    'parcellarisaient',
    'parcellarisasses',
    'parcellarisation',
    'parcellariserais',
    'parcellariserait',
    'parcellarisèrent',
    'parcellariseriez',
    'parcellariserons',
    'parcellariseront',
    'parcellisassions',
    'parcelliseraient',
    'parcheminassions',
    'parchemineraient',
    'parenchymateuses',
    'parlementarismes',
    'parlementassions',
    'parlementeraient',
    'particularisâmes',
    'particularisasse',
    'particularisâtes',
    'particulariserai',
    'particulariseras',
    'particulariserez',
    'particularisions',
    'particulièrement',
    'partitionnassent',
    'partitionnassiez',
    'partitionnements',
    'partitionnerions',
    'passementassions',
    'passementeraient',
    'passepoilassions',
    'passepoileraient',
    'pasteurisassions',
    'pasteuriseraient',
    'pathognomoniques',
    'pathologiquement',
    'patrouillassions',
    'patrouilleraient',
    'peinturlurassent',
    'peinturlurassiez',
    'peinturlurerions',
    'pendouillassions',
    'pendouilleraient',
    'penthiobarbitals',
    'percussionnistes',
    'perfectionnaient',
    'perfectionnasses',
    'perfectionnement',
    'perfectionnerais',
    'perfectionnerait',
    'perfectionnèrent',
    'perfectionneriez',
    'perfectionnerons',
    'perfectionneront',
    'perfectionnismes',
    'perfectionnistes',
    'péripatéticienne',
    'périphrasassions',
    'périphraseraient',
    'permanentassions',
    'permanenteraient',
    'perméabilisaient',
    'perméabilisasses',
    'perméabiliserais',
    'perméabiliserait',
    'perméabilisèrent',
    'perméabiliseriez',
    'perméabiliserons',
    'perméabiliseront',
    'permissionnaires',
    'perpendiculaires',
    'perquisitionnais',
    'perquisitionnait',
    'perquisitionnant',
    'perquisitionnées',
    'perquisitionnent',
    'perquisitionnera',
    'perquisitionniez',
    'perquisitionnons',
    'personnalisaient',
    'personnalisasses',
    'personnalisation',
    'personnaliserais',
    'personnaliserait',
    'personnalisèrent',
    'personnaliseriez',
    'personnaliserons',
    'personnaliseront',
    'personnifiassent',
    'personnifiassiez',
    'personnification',
    'personnifierions',
    'petite-maîtresse',
    'pétitionnassions',
    'pétitionneraient',
    'petits-bourgeois',
    'petits-déjeuners',
    'pétrarquisassent',
    'pétrarquisassiez',
    'pétrarquiserions',
    'pétrolochimiques',
    'phalanstériennes',
    'phallocentriques',
    'phallocentrismes',
    'pharmacodynamies',
    'pharmacologiques',
    'pharmacothérapie',
    'phénakistiscopes',
    'phénoménologique',
    'philanthropiques',
    'philologiquement',
    'philosophassions',
    'philosopheraient',
    'phosphorescences',
    'phosphorescentes',
    'photocomposeuses',
    'photoconductteur',
    'photocopiassions',
    'photocopieraient',
    'photoélectricité',
    'photoélectriques',
    'photogrammétries',
    'photographiaient',
    'photographiasses',
    'photographierais',
    'photographierait',
    'photographièrent',
    'photographieriez',
    'photographierons',
    'photographieront',
    'photopériodismes',
    'physico-chimique',
    'physiognomonique',
    'physiognomoniste',
    'physiopathologie',
    'piézo-électrique',
    'piézoélectriques',
    'pique-niquassent',
    'pique-niquassiez',
    'pique-niquerions',
    'planchéiaissions',
    'planétarisations',
    'plastronnassions',
    'plastronneraient',
    'plébiscitassions',
    'plébisciteraient',
    'plénipotentiaire',
    'pleurnichassions',
    'pleurnicheraient',
    'pleuropneumonies',
    'pluricellulaires',
    'plus-que-parfait',
    'pneumatothérapie',
    'pneumogastriques',
    'poliomyélitiques',
    'polissonnassions',
    'polissonneraient',
    'polycondensation',
    'polymérisassions',
    'polymériseraient',
    'polysynthétiques',
    'polytechnicienne',
    'polytraumatisées',
    'ponctionnassions',
    'ponctionneraient',
    'ponts-promenades',
    'popularisassions',
    'populariseraient',
    'populationnistes',
    'porphyrisassions',
    'porphyriseraient',
    'porte-allumettes',
    'porte-baïonnette',
    'porte-bouteilles',
    'porte-cigarettes',
    'porte-étrivières',
    'porte-parapluies',
    'porte-serviettes',
    'portraits-robots',
    'portraiturassent',
    'portraiturassiez',
    'portraiturerâmes',
    'portraiturerasse',
    'portraiturerâtes',
    'positionnassions',
    'positionneraient',
    'possessionnelles',
    'postillonnassent',
    'postillonnassiez',
    'postillonnerions',
    'postsonorisation',
    'postsynchronisai',
    'postsynchronisas',
    'postsynchronisât',
    'postsynchronisée',
    'postsynchroniser',
    'postsynchronises',
    'postsynchronisés',
    'postsynchronisez',
    'potentialisaient',
    'potentialisasses',
    'potentialiserais',
    'potentialiserait',
    'potentialisèrent',
    'potentialiseriez',
    'potentialiserons',
    'potentialiseront',
    'pourchassassions',
    'pourchasseraient',
    'précautionnaient',
    'précautionnasses',
    'précautionnerais',
    'précautionnerait',
    'précautionnèrent',
    'précautionneriez',
    'précautionnerons',
    'précautionneront',
    'précautionneuses',
    'préchauffassions',
    'préchaufferaient',
    'prédéfinissaient',
    'prédéfinissantes',
    'prédestinassions',
    'prédestineraient',
    'prédéterminaient',
    'prédéterminasses',
    'prédétermination',
    'prédéterminerais',
    'prédéterminerait',
    'prédéterminèrent',
    'prédétermineriez',
    'prédéterminerons',
    'prédétermineront',
    'prédisposassions',
    'prédisposeraient',
    'préétablissaient',
    'préjudiciassions',
    'préjudicieraient',
    'préliminairement',
    'presbytérianisme',
    'présidentialisme',
    'présonorisations',
    'pressurisassions',
    'pressuriseraient',
    'prestidigitateur',
    'prestidigitation',
    'présupposassions',
    'présupposeraient',
    'prétentieusement',
    'prévariquassions',
    'prévariqueraient',
    'primo-infections',
    'prince-de-galles',
    'privilégiassions',
    'privilégieraient',
    'processionnaient',
    'processionnaires',
    'processionnasses',
    'processionnelles',
    'processionnerais',
    'processionnerait',
    'processionnèrent',
    'processionneriez',
    'processionnerons',
    'processionneront',
    'procrastinations',
    'professionnelles',
    'prohibitionnisme',
    'prohibitionniste',
    'projectionnistes',
    'prolétarisassent',
    'prolétarisassiez',
    'prolétarisations',
    'prolétariserions',
    'pronostiquassent',
    'pronostiquassiez',
    'pronostiquerions',
    'pronunciamientos',
    'propharmaciennes',
    'prophétisassions',
    'prophétiseraient',
    'proportionnaient',
    'proportionnalité',
    'proportionnasses',
    'proportionnelles',
    'proportionnerais',
    'proportionnerait',
    'proportionnèrent',
    'proportionneriez',
    'proportionnerons',
    'proportionneront',
    'propositionnelle',
    'protectionnismes',
    'protectionnistes',
    'protohistoriques',
    'proverbialisâmes',
    'proverbialisasse',
    'proverbialisâtes',
    'proverbialiserai',
    'proverbialiseras',
    'proverbialiserez',
    'proverbialisions',
    'providentialisme',
    'pseudo-bulbaires',
    'pseudomembraneux',
    'psychanalysaient',
    'psychanalysasses',
    'psychanalyserais',
    'psychanalyserait',
    'psychanalysèrent',
    'psychanalyseriez',
    'psychanalyserons',
    'psychanalyseront',
    'psychanalytiques',
    'psychiatrisaient',
    'psychiatrisasses',
    'psychiatriserais',
    'psychiatriserait',
    'psychiatrisèrent',
    'psychiatriseriez',
    'psychiatriserons',
    'psychiatriseront',
    'psychochirurgies',
    'psychodramatique',
    'psycho-éducatifs',
    'psycho-éducation',
    'psycho-éducative',
    'psychomotricités',
    'psychopathologie',
    'psychopédagogies',
    'psychosensoriels',
    'psychosociologie',
    'psychosomatiques',
    'psychotechnicien',
    'psychotechniques',
    'psychothérapeute',
    'public-relations',
    'pyrophosphorique',
    'pythagoriciennes',
    'quadrisyllabique',
    'quantitativement',
    'quarderonnassent',
    'quarderonnassiez',
    'quarderonnerions',
    'quatre-vingt-dix',
    'quatre-vingtième',
    'quatre-vingt-six',
    'questionnassions',
    'questionneraient',
    'queues-de-cheval',
    'queues-de-cochon',
    'queues-de-renard',
    'quintessenciâmes',
    'quintessenciasse',
    'quintessenciâtes',
    'quintessencierai',
    'quintessencieras',
    'quintessencierez',
    'quintessenciions',
    'rabougrissements',
    'raccommodassions',
    'raccommoderaient',
    'raccompagnassent',
    'raccompagnassiez',
    'raccompagnerions',
    'raccourcissaient',
    'raccourcissement',
    'raccoutumassions',
    'raccoutumeraient',
    'rachianesthésies',
    'radicalisassions',
    'radicaliseraient',
    'radioalignements',
    'radioastronomies',
    'radiobalisassent',
    'radiobalisassiez',
    'radiobaliserions',
    'radioconducteurs',
    'radiodiagnostics',
    'radiodiffusaient',
    'radiodiffusasses',
    'radiodiffuserais',
    'radiodiffuserait',
    'radiodiffusèrent',
    'radiodiffuseriez',
    'radiodiffuserons',
    'radiodiffuseront',
    'radioélectricité',
    'radioélectriques',
    'radiographiaient',
    'radiographiasses',
    'radiographierais',
    'radiographierait',
    'radiographièrent',
    'radiographieriez',
    'radiographierons',
    'radiographieront',
    'radioguidassions',
    'radioguideraient',
    'radionavigations',
    'radioprotections',
    'radioscopassions',
    'radioscoperaient',
    'radiotélégraphia',
    'radiotélégraphie',
    'radiotélégraphié',
    'radiotéléphonies',
    'radiothérapeutes',
    'raffermissements',
    'rafraîchissaient',
    'rafraîchissantes',
    'rafraîchissement',
    'ragaillardirions',
    'ragaillardissais',
    'ragaillardissait',
    'ragaillardissant',
    'ragaillardissent',
    'ragaillardissiez',
    'ragaillardissons',
    'ramastiquassions',
    'ramatisqueraient',
    'rapapillotassent',
    'rapapillotassiez',
    'rapapilloterions',
    'rappareillassent',
    'rappareillassiez',
    'rappareillerions',
    'rappropriassions',
    'rapproprieraient',
    'rapprovisionnais',
    'rapprovisionnait',
    'rapprovisionnant',
    'rapprovisionnées',
    'rapprovisionnent',
    'rapprovisionnera',
    'rapprovisionniez',
    'rapprovisionnons',
    'rationalisassent',
    'rationalisassiez',
    'rationalisations',
    'rationaliserions',
    'ravitaillassions',
    'ravitailleraient',
    'réabonnerassions',
    'réaccoutumassent',
    'réaccoutumassiez',
    'réaccoutumerions',
    'réactualisassent',
    'réactualisassiez',
    'réactualiserions',
    'réaménageassions',
    'réapparaissaient',
    'réapparaîtraient',
    'réapprovisionnai',
    'réapprovisionnas',
    'réapprovisionnât',
    'réapprovisionnée',
    'réapprovisionner',
    'réapprovisionnes',
    'réapprovisionnés',
    'réapprovisionnez',
    'réarrangeassions',
    'réassortissaient',
    'reblanchissaient',
    'reboutonnassions',
    'reboutonneraient',
    'recachetteraient',
    'recalcifiassions',
    'recalcifications',
    'recalcifieraient',
    'récapitulassions',
    'récapituleraient',
    'recarrelleraient',
    'réceptionnassent',
    'réceptionnassiez',
    'réceptionnerions',
    'rechristianisais',
    'rechristianisait',
    'rechristianisant',
    'rechristianisées',
    'rechristianisent',
    'rechristianisera',
    'rechristianisiez',
    'rechristianisons',
    'réciproquassions',
    'réciproqueraient',
    'recommandassions',
    'recommanderaient',
    'recommençassions',
    'recommenceraient',
    'recomparaissions',
    'recomparaîtrions',
    'récompensassions',
    'récompenseraient',
    'réconciliassions',
    'réconciliatrices',
    'réconcilieraient',
    'recondamnassions',
    'recondamneraient',
    'reconduisissions',
    'réconfortassions',
    'réconforteraient',
    'reconnectassions',
    'reconnecteraient',
    'reconsidérassent',
    'reconsidérassiez',
    'reconsidérerions',
    'reconsolidassent',
    'reconsolidassiez',
    'reconsoliderions',
    'reconstituassent',
    'reconstituassiez',
    'reconstituerions',
    'reconstruiraient',
    'reconstruisaient',
    'reconstruisirent',
    'reconstruisisses',
    'reconventionnels',
    'reconvertiraient',
    'reconvertissions',
    'recoquillassions',
    'recoquilleraient',
    'recorrigeassions',
    'recoucherassions',
    'recristallisâmes',
    'recristallisasse',
    'recristallisâtes',
    'recristalliserai',
    'recristalliseras',
    'recristalliserez',
    'recristallisions',
    'recroquevillâmes',
    'recroquevillasse',
    'recroquevillâtes',
    'recroquevillerai',
    'recroquevilleras',
    'recroquevillerez',
    'recroquevillions',
    'redémontrassions',
    'redémontreraient',
    'redescendissions',
    'redistribuassent',
    'redistribuassiez',
    'redistribuerions',
    'réembauchassions',
    'réembaucheraient',
    'réempruntassions',
    'réemprunteraient',
    'réenregistraient',
    'réenregistrasses',
    'réenregistrerais',
    'réenregistrerait',
    'réenregistrèrent',
    'réenregistreriez',
    'réenregistrerons',
    'réenregistreront',
    'réensemençassent',
    'réensemençassiez',
    'réensemencements',
    'réensemencerions',
    'rééquilibrassent',
    'rééquilibrassiez',
    'rééquilibrerâmes',
    'rééquilibrerasse',
    'rééquilibrerâtes',
    'réescomptassions',
    'réescompteraient',
    'refleurissements',
    'réflexothérapies',
    'refroidissements',
    'régionalisassent',
    'régionalisassiez',
    'régionalisations',
    'régionaliserions',
    'réglementassions',
    'réglementeraient',
    'régularisassions',
    'régulariseraient',
    'réhabilitassions',
    'réhabiliteraient',
    'réimperméabilisa',
    'réimperméabilise',
    'réimperméabilisé',
    'réimplantassions',
    'réimplanteraient',
    'réincarcérassent',
    'réincarcérassiez',
    'réincarcérerions',
    'réincorporassent',
    'réincorporassiez',
    'réincorporerions',
    'reine-marguerite',
    'réinitialisaient',
    'réinitialisasses',
    'réinitialiserais',
    'réinitialiserait',
    'réinitialisèrent',
    'réinitialiseriez',
    'réinitialiserons',
    'réinitialiseront',
    'réinscrivissions',
    'réinstallassions',
    'réinstalleraient',
    'réinterprétaient',
    'réinterprétasses',
    'réinterpréterais',
    'réinterpréterait',
    'réinterprétèrent',
    'réinterpréteriez',
    'réinterpréterons',
    'réinterpréteront',
    'réintroduiraient',
    'réintroduisaient',
    'réintroduisirent',
    'réintroduisisses',
    'réinvestissaient',
    'rejaillissements',
    'rejointoieraient',
    'rejointoyassions',
    'relativisassions',
    'relativiseraient',
    'remaquillassions',
    'remaquilleraient',
    'remastiquassions',
    'remastiqueraient',
    'remilitarisaient',
    'remilitarisasses',
    'remilitarisation',
    'remilitariserais',
    'remilitariserait',
    'remilitarisèrent',
    'remilitariseriez',
    'remilitariserons',
    'remilitariseront',
    'remmaillotassent',
    'remmaillotassiez',
    'remmailloterions',
    'rempaillerassent',
    'rempaillerassiez',
    'rempaquetassions',
    'rempaquetterions',
    'rempoissonnaient',
    'rempoissonnasses',
    'rempoissonnerais',
    'rempoissonnerait',
    'rempoissonnèrent',
    'rempoissonneriez',
    'rempoissonnerons',
    'rempoissonneront',
    'renchérissements',
    'renformirassions',
    'rengraissassions',
    'rengraisseraient',
    'renouvelleraient',
    'rentabilisassent',
    'rentabilisassiez',
    'rentabilisations',
    'rentabiliserions',
    'rentrouvriraient',
    'rentrouvrissions',
    'renveloppassions',
    'renvelopperaient',
    'réorchestrassent',
    'réorchestrassiez',
    'réorchestrerions',
    'réordonnançaient',
    'réordonnançasses',
    'réordonnancerais',
    'réordonnancerait',
    'réordonnancèrent',
    'réordonnanceriez',
    'réordonnancerons',
    'réordonnanceront',
    'réorganisassions',
    'réorganisatrices',
    'réorganiseraient',
    'repartageassions',
    'répertoriassions',
    'répertorieraient',
    'repositionnaient',
    'repositionnasses',
    'repositionnerais',
    'repositionnerait',
    'repositionnèrent',
    'repositionneriez',
    'repositionnerons',
    'repositionneront',
    'représentassions',
    'représentativité',
    'représenteraient',
    'réprimandassions',
    'réprimanderaient',
    'reproductibilité',
    'reproduisissions',
    'reprogrammassent',
    'reprogrammassiez',
    'reprogrammerions',
    'reprographiaient',
    'reprographiasses',
    'reprographierais',
    'reprographierait',
    'reprographièrent',
    'reprographieriez',
    'reprographierons',
    'reprographieront',
    'républicanisâmes',
    'républicanisasse',
    'républicanisâtes',
    'républicaniserai',
    'républicaniseras',
    'républicaniserez',
    'républicanisions',
    'réquisitionnâmes',
    'réquisitionnasse',
    'réquisitionnâtes',
    'réquisitionnerai',
    'réquisitionneras',
    'réquisitionnerez',
    'réquisitionnions',
    'resocialisassent',
    'resocialisassiez',
    'resocialiserions',
    'respectueusement',
    'resplendissaient',
    'resplendissantes',
    'resplendissement',
    'responsabilisais',
    'responsabilisait',
    'responsabilisant',
    'responsabilisées',
    'responsabilisent',
    'responsabilisera',
    'responsabilisiez',
    'responsabilisons',
    'ressaisissements',
    'ressemelleraient',
    'ressuscitassions',
    'ressusciteraient',
    'restreignissions',
    'restructurassent',
    'restructurassiez',
    'restructurations',
    'restructurerions',
    'resurchauffaient',
    'resurchauffasses',
    'resurchaufferais',
    'resurchaufferait',
    'resurchauffèrent',
    'resurchaufferiez',
    'resurchaufferons',
    'resurchaufferont',
    'résurrectionnels',
    'retraduisissions',
    'retranscriraient',
    'retranscrivaient',
    'retranscrivirent',
    'retranscrivisses',
    'retransmettaient',
    'retransmettrions',
    'retravaillassent',
    'retravaillassiez',
    'retravaillerions',
    'retraversassions',
    'retraverseraient',
    'rétrogradassions',
    'rétrograderaient',
    'rétroprojecteurs',
    'rétropropulsions',
    'revalorisassions',
    'revaloriseraient',
    'réveillonnassent',
    'réveillonnassiez',
    'réveillonnerions',
    'revendiquassions',
    'revendiqueraient',
    'revitalisassions',
    'revitaliseraient',
    'révolutionnaient',
    'révolutionnaires',
    'révolutionnasses',
    'révolutionnerais',
    'révolutionnerait',
    'révolutionnèrent',
    'révolutionneriez',
    'révolutionnerons',
    'révolutionneront',
    'révolvérisassent',
    'révolvérisassiez',
    'révolvériserions',
    'rhabdomanciennes',
    'rhinopharyngiens',
    'rhinopharyngites',
    'ridiculisassions',
    'ridiculiseraient',
    'rince-bouteilles',
    'roman-feuilleton',
    'ronsardisassions',
    'ronsardiseraient',
    'rouscaillassions',
    'rouscailleraient',
    'saccharifiassent',
    'saccharifiassiez',
    'saccharification',
    'saccharifierions',
    'saint-simonienne',
    'saisie-exécution',
    'saisies-brandons',
    'sanctificatrices',
    'sanctionnassions',
    'sanctionneraient',
    'sanctuarisassent',
    'sanctuarisassiez',
    'sanctuariserions',
    'sapeurs-pompiers',
    'sardanapalesques',
    'saucissonnassent',
    'saucissonnassiez',
    'saucissonnerions',
    'sauvegardassions',
    'sauvegarderaient',
    'scandalisassions',
    'scandaliseraient',
    'sceau-de-Salomon',
    'schématisassions',
    'schématiseraient',
    'scientifiquement',
    'scintillerassent',
    'scintillerassiez',
    'scissionnassions',
    'scissionneraient',
    'scribouillassent',
    'scribouillassiez',
    'scribouillerions',
    'sécularisassions',
    'séculariseraient',
    'sédentarisassent',
    'sédentarisassiez',
    'sédentarisations',
    'sédentariserions',
    'ségrégationnisme',
    'ségrégationniste',
    'sélectionnassent',
    'sélectionnassiez',
    'sélectionnerions',
    'sélénographiques',
    'self-governments',
    'self-inductances',
    'semestriellement',
    'semi-automatique',
    'semi-auxiliaires',
    'semi-circulaires',
    'semi-conducteurs',
    'semi-conductrice',
    'semi-manufacturé',
    'sensationnalisme',
    'sensationnaliste',
    'sensibilisassent',
    'sensibilisassiez',
    'sensibilisateurs',
    'sensibilisations',
    'sensibilisatrice',
    'sensibiliserions',
    'sensorimétriques',
    'sentencieusement',
    'sentimentalement',
    'sentimentalismes',
    'sérovaccinations',
    'serviette-éponge',
    'shakespeariennes',
    'shampooingnaient',
    'shampooingnasses',
    'shampooingnerais',
    'shampooingnerait',
    'shampooingnèrent',
    'shampooingneriez',
    'shampooingnerons',
    'shampooingneront',
    'shampouinassions',
    'shampouineraient',
    'sigillographique',
    'silhouettassions',
    'silhouetteraient',
    'silicatisassions',
    'silicatiseraient',
    'simplificatrices',
    'singularisassent',
    'singularisassiez',
    'singulariserions',
    'social-démocrate',
    'socio-culturelle',
    'socioculturelles',
    'socio-économique',
    'socio-éducatives',
    'sociologiquement',
    'soit-communiqués',
    'soixante-et-onze',
    'solidarisassions',
    'solidariseraient',
    'solubilisassions',
    'solubiliseraient',
    'solutionnassions',
    'solutionneraient',
    'somato-psychique',
    'sophistiquassent',
    'sophistiquassiez',
    'sophistiquerions',
    'soubresautassent',
    'soubresautassiez',
    'soubresauterions',
    'souffletteraient',
    'soumissionnaient',
    'soumissionnaires',
    'soumissionnasses',
    'soumissionnerais',
    'soumissionnerait',
    'soumissionnèrent',
    'soumissionneriez',
    'soumissionnerons',
    'soumissionneront',
    'soupçonneusement',
    'sous-admissibles',
    'sous-alimentâmes',
    'sous-alimentasse',
    'sous-alimentâtes',
    'sous-alimenterai',
    'sous-alimenteras',
    'sous-alimenterez',
    'sous-alimentions',
    'sous-amendements',
    'sous-arbrisseaux',
    'sous-commissions',
    'sous-développées',
    'sous-directrices',
    'sous-entendaient',
    'sous-entendirent',
    'sous-entendisses',
    'sous-entendrions',
    'sous-épidermique',
    'sous-équipements',
    'sous-estimassent',
    'sous-estimassiez',
    'sous-estimations',
    'sous-estimerions',
    'sous-évaluassent',
    'sous-évaluassiez',
    'sous-évaluations',
    'sous-évaluerions',
    'sous-exploitâmes',
    'sous-exploitasse',
    'sous-exploitâtes',
    'sous-exploiterai',
    'sous-exploiteras',
    'sous-exploiterez',
    'sous-exploitions',
    'sous-exposassent',
    'sous-exposassiez',
    'sous-exposerions',
    'sous-expositions',
    'sous-expressions',
    'sous-frutescente',
    'sous-frutescents',
    'sous-gouverneurs',
    'sous-impositions',
    'sous-lieutenants',
    'sous-occipitales',
    'sous-peuplements',
    'sous-préfectures',
    'sous-productions',
    'sous-prolétaires',
    'sous-prolétariat',
    'sous-répertoires',
    'sous-scapulaires',
    'sous-secrétaires',
    'sous-secrétariat',
    'sous-tendissions',
    'sous-titrassions',
    'sous-titreraient',
    'sous-traitassent',
    'sous-traitassiez',
    'sous-traiterions',
    'sous-utilisaient',
    'sous-utilisasses',
    'sous-utiliserais',
    'sous-utiliserait',
    'sous-utilisèrent',
    'sous-utiliseriez',
    'sous-utiliserons',
    'sous-utiliseront',
    'spatialisassions',
    'spatialiseraient',
    'spatio-temporels',
    'spécialisassions',
    'spécialiseraient',
    'spectroscopiques',
    'spiritualisaient',
    'spiritualisasses',
    'spiritualisation',
    'spiritualiserais',
    'spiritualiserait',
    'spiritualisèrent',
    'spiritualiseriez',
    'spiritualiserons',
    'spiritualiseront',
    'sponsorisassions',
    'sponsoriseraient',
    'squattérisassent',
    'squattérisassiez',
    'squattériserions',
    'standardisassent',
    'standardisassiez',
    'standardisations',
    'standardiserions',
    'staphylococcique',
    'stations-service',
    'statokinésimètre',
    'sténographiaient',
    'sténographiasses',
    'sténographierais',
    'sténographierait',
    'sténographièrent',
    'sténographieriez',
    'sténographierons',
    'sténographieront',
    'stéréotypassions',
    'stéréotyperaient',
    'stigmatisassions',
    'stigmatiseraient',
    'stoechiométrique',
    'stratosphériques',
    'streptococciques',
    'subdéléguassions',
    'subdélégueraient',
    'subdivisionnaire',
    'subordonnassions',
    'subordonneraient',
    'substantialismes',
    'substantialistes',
    'substantivassent',
    'substantivassiez',
    'substantivations',
    'substantiverions',
    'subventionnaient',
    'subventionnasses',
    'subventionnelles',
    'subventionnerais',
    'subventionnerait',
    'subventionnèrent',
    'subventionneriez',
    'subventionnerons',
    'subventionneront',
    'sud-vietnamienne',
    'suggestionnaient',
    'suggestionnasses',
    'suggestionnerais',
    'suggestionnerait',
    'suggestionnèrent',
    'suggestionneriez',
    'suggestionnerons',
    'suggestionneront',
    'sulfhémoglobines',
    'sulfocarboniques',
    'superfécondation',
    'superfinissaient',
    'superforteresses',
    'superhétérodynes',
    'superproductions',
    'supplémentassent',
    'supplémentassiez',
    'supplémenterions',
    'supraconductions',
    'suprasegmentales',
    'suralimentassent',
    'suralimentassiez',
    'suralimentations',
    'suralimenterions',
    'surchargeassions',
    'surchauffassions',
    'surchaufferaient',
    'surcompensations',
    'surcomprimassent',
    'surcomprimassiez',
    'surcomprimerions',
    'surconsommations',
    'surdéterminantes',
    'surdétermination',
    'surenchériraient',
    'surenchérisseurs',
    'surenchérisseuse',
    'surenchérissions',
    'surencombrements',
    'surentraînassent',
    'surentraînassiez',
    'surentraînements',
    'surentraînerions',
    'surexploitassent',
    'surexploitassiez',
    'surexploitations',
    'surexploiterions',
    'surmédicalisâmes',
    'surmédicalisasse',
    'surmédicalisâtes',
    'surmédicaliserai',
    'surmédicaliseras',
    'surmédicaliserez',
    'surmédicalisions',
    'surprotégeassent',
    'surprotégeassiez',
    'surprotégeraient',
    'surrégénérateurs',
    'sweating-systems',
    'sympathisassions',
    'sympathiseraient',
    'symptomatologies',
    'synallagmatiques',
    'synchrocyclotron',
    'synchroniquement',
    'synchronisassent',
    'synchronisassiez',
    'synchronisations',
    'synchroniserions',
    'syncristallisais',
    'syncristallisait',
    'syncristallisant',
    'syncristallisent',
    'syncristallisera',
    'syncristallisiez',
    'syncristallisons',
    'syndicalisassent',
    'syndicalisassiez',
    'syndicalisations',
    'syndicaliserions',
    'synthétisassions',
    'synthétiseraient',
    'systématiquement',
    'systématisassent',
    'systématisassiez',
    'systématisations',
    'systématiserions',
    'systèmes-experts',
    'tachistoscopique',
    'tambourinassions',
    'tambourineraient',
    'tarabiscotassent',
    'tarabiscotassiez',
    'tarabiscoterions',
    'tatillonnassions',
    'tatillonneraient',
    'technicisassions',
    'techniciseraient',
    'technocratisâmes',
    'technocratisante',
    'technocratisants',
    'technocratisasse',
    'technocratisâtes',
    'technocratiserai',
    'technocratiseras',
    'technocratiserez',
    'technocratisions',
    'technostructures',
    'télécommandaient',
    'télécommandasses',
    'télécommanderais',
    'télécommanderait',
    'télécommandèrent',
    'télécommanderiez',
    'télécommanderons',
    'télécommanderont',
    'télédiffusassent',
    'télédiffusassiez',
    'télédiffuserions',
    'télédistribution',
    'téléenseignement',
    'télégraphiassent',
    'télégraphiassiez',
    'télégraphierions',
    'téléinformatique',
    'télémaintenances',
    'télémanipulateur',
    'télémanipulation',
    'téléphoniquement',
    'téléphotographie',
    'téléradiographie',
    'téléspectatrices',
    'télésurveillance',
    'tendancieusement',
    'tergiversassions',
    'tergiverseraient',
    'terre-neuviennes',
    'territorialement',
    'tertiairisations',
    'têtes-de-moineau',
    'tétrasyllabiques',
    'thalassothérapie',
    'théâtralisassent',
    'théâtralisassiez',
    'théâtraliserions',
    'théophilanthrope',
    'thermodynamicien',
    'thermodynamiques',
    'thermoélectrique',
    'thermomagnétique',
    'thermonucléaires',
    'thermoplastiques',
    'thermopropulsées',
    'thermopropulsifs',
    'thermopropulsion',
    'thermopropulsive',
    'thermorégulateur',
    'thermorégulation',
    'thermorésistante',
    'thermorésistants',
    'thésaurisassions',
    'thésauriseraient',
    'thromboplastines',
    'timbre-quittance',
    'tintinnabulaient',
    'tintinnabulantes',
    'tintinnabulasses',
    'tintinnabulerais',
    'tintinnabulerait',
    'tintinnabulèrent',
    'tintinnabuleriez',
    'tintinnabulerons',
    'tintinnabuleront',
    'tire-bouchonnais',
    'tire-bouchonnait',
    'tirebouchonnâmes',
    'tire-bouchonnant',
    'tirebouchonnasse',
    'tirebouchonnâtes',
    'tire-bouchonnées',
    'tire-bouchonnent',
    'tire-bouchonnera',
    'tirebouchonnerai',
    'tirebouchonneras',
    'tirebouchonnerez',
    'tire-bouchonniez',
    'tirebouchonnions',
    'tire-bouchonnons',
    'titularisassions',
    'titulariseraient',
    'topicalisassions',
    'topicaliseraient',
    'torrentiellement',
    'tourbillonnaient',
    'tourbillonnaires',
    'tourbillonnantes',
    'tourbillonnasses',
    'tourbillonnement',
    'tourbillonnerais',
    'tourbillonnerait',
    'tourbillonnèrent',
    'tourbillonneriez',
    'tourbillonnerons',
    'tourbillonneront',
    'tourillonnassent',
    'tourillonnassiez',
    'tourillonnerions',
    'tournaillassions',
    'tournailleraient',
    'tourneboulassent',
    'tourneboulassiez',
    'tourneboulerions',
    'tournicotassions',
    'tournicoteraient',
    'tours-opérateurs',
    'toussaillassions',
    'toussailleraient',
    'trachées-artères',
    'traditionalismes',
    'traditionalistes',
    'traînaillassions',
    'traînailleraient',
    'trajectographies',
    'tranchefilassent',
    'tranchefilassiez',
    'tranchefilerions',
    'tranquillisaient',
    'tranquillisantes',
    'tranquillisasses',
    'tranquilliserais',
    'tranquilliserait',
    'tranquillisèrent',
    'tranquilliseriez',
    'tranquilliserons',
    'tranquilliseront',
    'transactionnelle',
    'transatlantiques',
    'transbahutassent',
    'transbahutassiez',
    'transbahutements',
    'transbahuterions',
    'transbordassions',
    'transborderaient',
    'transcanadiennes',
    'transcendantales',
    'transcendassions',
    'transcenderaient',
    'transcontinental',
    'transcrivissions',
    'transférentielle',
    'transfigurassent',
    'transfigurassiez',
    'transfigurateurs',
    'transfigurations',
    'transfiguratrice',
    'transfigurerions',
    'transformassions',
    'transformatrices',
    'transformeraient',
    'transgressassent',
    'transgressassiez',
    'transgresserions',
    'transistorisâmes',
    'transistorisasse',
    'transistorisâtes',
    'transistoriserai',
    'transistoriseras',
    'transistoriserez',
    'transistorisions',
    'transitionnelles',
    'translitérassent',
    'translitérassiez',
    'translitérerions',
    'translittéraient',
    'translittérasses',
    'translittération',
    'translittérerais',
    'translittérerait',
    'translittérèrent',
    'translittéreriez',
    'translittérerons',
    'translittéreront',
    'transmigrassions',
    'transmigreraient',
    'transmissibilité',
    'transmutabilités',
    'transocéaniennes',
    'transparaissions',
    'transparaîtrions',
    'transperçassions',
    'transperceraient',
    'transplantassent',
    'transplantassiez',
    'transplantations',
    'transplanterions',
    'transportassions',
    'transporteraient',
    'transpyrénéennes',
    'transsahariennes',
    'transsexualismes',
    'transsibériennes',
    'transsubstantiai',
    'transsubstantias',
    'transsubstantiât',
    'transsubstantiée',
    'transsubstantier',
    'transsubstanties',
    'transsubstantiés',
    'transsubstantiez',
    'transversalement',
    'traumatisassions',
    'traumatiseraient',
    'traumatologiques',
    'traumatologistes',
    'travestissements',
    'treillageassions',
    'treillissassions',
    'treillisseraient',
    'tressailliraient',
    'tressaillissions',
    'triboélectricité',
    'triboélectriques',
    'trichloracétique',
    'trichloréthylène',
    'tridimensionnels',
    'trifouillassions',
    'trifouilleraient',
    'trigonométriques',
    'trinitrobenzènes',
    'trinitrotoluènes',
    'tripatouillaient',
    'tripatouillasses',
    'tripatouillerais',
    'tripatouillerait',
    'tripatouillèrent',
    'tripatouilleriez',
    'tripatouillerons',
    'tripatouilleront',
    'tripatouilleuses',
    'tropicalisassent',
    'tropicalisassiez',
    'tropicalisations',
    'tropicaliserions',
    'troussequinaient',
    'troussequinasses',
    'troussequinerais',
    'troussequinerait',
    'troussequinèrent',
    'troussequineriez',
    'troussequinerons',
    'troussequineront',
    'tuberculinassent',
    'tuberculinassiez',
    'tuberculinations',
    'tuberculinerions',
    'tuberculinisâmes',
    'tuberculinisasse',
    'tuberculinisâtes',
    'tuberculinisatio',
    'tuberculiniserai',
    'tuberculiniseras',
    'tuberculiniserez',
    'tuberculinisions',
    'tuberculisassent',
    'tuberculisassiez',
    'tuberculisations',
    'tuberculiserions',
    'turbocompresseur',
    'turbopropulseurs',
    'turbosoufflantes',
    'typolithographie',
    'ultrafiltrations',
    'ultramicroscopes',
    'ultramicroscopie',
    'ultramontanismes',
    'ultra-royalistes',
    'unidimensionnels',
    'unidirectionnels',
    'uniformisassions',
    'uniformiseraient',
    'universalisaient',
    'universalisasses',
    'universalisation',
    'universaliserais',
    'universaliserait',
    'universalisèrent',
    'universaliseriez',
    'universaliserons',
    'universaliseront',
    'vaccinothérapies',
    'vadrouillassions',
    'vadrouilleraient',
    'vadrouilleueuses',
    'valences-grammes',
    'valse-hésitation',
    'vascularisations',
    'vasoconstricteur',
    'vasoconstriction',
    'vasodilatatrices',
    'vermillonnassent',
    'vermillonnassiez',
    'vermillonnerions',
    'verrouillassions',
    'verrouilleraient',
    'vertigineusement',
    'vice-chanceliers',
    'vice-présidences',
    'vice-présidentes',
    'ville-champignon',
    'villégiaturaient',
    'villégiaturasses',
    'villégiaturerais',
    'villégiaturerait',
    'villégiaturèrent',
    'villégiatureriez',
    'villégiaturerons',
    'villégiatureront',
    'volatilisassions',
    'volatiliseraient',
    'wagon-restaurant',
    'zootechniciennes'
];
