import { combineReducers } from 'redux';
import app from './app.reducers';
import auth from '../Auth/auth.reducers';
import spending from '../Spending/spending.reducers';

export default combineReducers({
    app,
    auth,
    spending
});
