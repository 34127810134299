import store from './main.store';
import { windowResize, windowScroll } from './app.actions';

function dispatchResize() {
    store.dispatch(
        windowResize(document.documentElement.clientHeight, document.documentElement.clientWidth)
    );
}

function resizeEvent() {
    window.addEventListener('resize', () => {
        dispatchResize();
    });
}

function getScrollableContainer() {
    // return document.querySelector('.main-container');
    return document.querySelector('#root');
}

function dispatchScroll() {
    const { scrollTop } = getScrollableContainer();
    store.dispatch(windowScroll(scrollTop));
}

function scroll() {
    getScrollableContainer().addEventListener('scroll', () => {
        dispatchScroll();
    });
}

export default () => {
    resizeEvent();
    dispatchResize();
    scroll();
    dispatchScroll();
};
