import React, { useEffect } from 'react';
import monsters from './monsters';
import {
    Grid,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Typography
} from '@mui/material';
import HuntTableRow from './HuntTableRow';
import PageTitle from '../../../common/Components/PageTitle';

export default function LordsMobileHunt() {
    useEffect(() => {
        document.title = 'Home - Cheats - LM chasse';
    }, []);

    return (
        <React.Fragment>
            <PageTitle title="Chasse" />
            <div className="mt-m" />
            <Grid container spacing={3}>
                {monsters.map((monster, i) => (
                    <Grid key={i} item xs={12} sm={12} md={6} lg={6} xl={4}>
                        <Paper elevation={6} className="p-s">
                            <Typography variant="h6">{monster.name}</Typography>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Type</TableCell>
                                        <TableCell align="right">lv1</TableCell>
                                        <TableCell align="right">lv2</TableCell>
                                        <TableCell align="right">lv3</TableCell>
                                        <TableCell align="right">lv4</TableCell>
                                        <TableCell align="right">lv5</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <HuntTableRow title={'OS'} values={monster.oneShot} />
                                    <HuntTableRow
                                        title={'Boost OS'}
                                        values={monster.boostOneShot}
                                    />
                                    <HuntTableRow title={'1p1'} values={monster.oneByOne} />
                                    <HuntTableRow
                                        title={'Boost 1p1'}
                                        values={monster.boostOneByOne}
                                    />
                                </TableBody>
                            </Table>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </React.Fragment>
    );
}
