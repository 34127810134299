import React, { useEffect } from 'react';
import { Stack } from '@mui/material';
import { ALL } from './transport.const';
import TransportLine from './Components/TransportLine';
import PageTitle from '../common/Components/PageTitle';

export default function Transport() {
    useEffect(() => {
        document.title = 'Home - Transport';
    }, []);

    return (
        <React.Fragment>
            <PageTitle title={'Info Transport'} />
            <Stack spacing={2} className="mt-m">
                {ALL.map((t, index) => (
                    <TransportLine key={index} transport={t} />
                ))}
            </Stack>
        </React.Fragment>
    );
}
