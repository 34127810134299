export default {
    name: 'Golem antique',
    boostOneShot: {
        lv1: 1,
        lv2: 2,
        lv3: 6,
        lv4: NaN,
        lv5: NaN
    },
    oneShot: {
        lv1: 3,
        lv2: 6,
        lv3: 18,
        lv4: NaN,
        lv5: NaN
    },
    boostOneByOne: {
        lv1: 2,
        lv2: 3,
        lv3: NaN,
        lv4: NaN,
        lv5: NaN
    },
    oneByOne: {
        lv1: 2,
        lv2: 4,
        lv3: NaN,
        lv4: NaN,
        lv5: NaN
    }
};
