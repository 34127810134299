export default [
    'abasourdissements',
    'abstractionnismes',
    'acanthoptérygiens',
    'accessoirisassent',
    'accessoirisassiez',
    'accessoiriserions',
    'acétylsalicylique',
    'adjectivisassions',
    'adjectiviseraient',
    'affectionnassions',
    'affectionneraient',
    'affourrageassions',
    'affranchissements',
    'agro-alimentaires',
    'aiguilletteraient',
    'aiguillonnassions',
    'aiguillonneraient',
    'alphabétisassions',
    'alphabétiseraient',
    'américanisassions',
    'américaniseraient',
    'anathématisassent',
    'anathématisassiez',
    'anathématiserions',
    'anatomopathologie',
    'anesthésiologiste',
    'anthropocentrique',
    'anthropocentrisme',
    'anthropométriques',
    'anthropomorphique',
    'anthropomorphisme',
    'anti-américanisme',
    'anticléricalismes',
    'anticolonialismes',
    'anticolonialistes',
    'anticonceptionnel',
    'anticonjoncturels',
    'anticryptogamique',
    'antidémocratiques',
    'antiesclavagistes',
    'antihistaminiques',
    'anti-impérialisme',
    'anti-impérialiste',
    'antiparlementaire',
    'antipéristaltique',
    'antiphlogistiques',
    'antiréglementaire',
    'anti-scientifique',
    'anti-sous-marines',
    'antisyphilitiques',
    'antituberculeuses',
    'appesantissements',
    'approfondissaient',
    'approfondissement',
    'approvisionnaient',
    'approvisionnasses',
    'approvisionnement',
    'approvisionnerais',
    'approvisionnerait',
    'approvisionnèrent',
    'approvisionneriez',
    'approvisionnerons',
    'approvisionneront',
    'approvisionneuses',
    'approximativement',
    'archipresbytérale',
    'archipresbytéraux',
    'architecturassent',
    'architecturassiez',
    'architecturerions',
    'aristotéliciennes',
    'arrière-boutiques',
    'artérioscléreuses',
    'ascensionnassions',
    'ascensionneraient',
    'associationnismes',
    'associationnistes',
    'assujettissements',
    'assurance-chômage',
    'assurance-maladie',
    'audioprothésistes',
    'australopithèques',
    'authentifiassions',
    'authentifications',
    'authentifieraient',
    'authentiquassions',
    'authentiqueraient',
    'autobiographiques',
    'auto-calibrations',
    'autoconsommations',
    'autodestructrices',
    'autodétermination',
    'autodétermineriez',
    'autodéterminerons',
    'auto-équilibrante',
    'auto-équilibrants',
    'autofinançassions',
    'autogestionnaires',
    'autographiassions',
    'autographieraient',
    'auto-intoxication',
    'automitrailleuses',
    'autoradiographies',
    'autosatisfactions',
    'autosuggestionnée',
    'autosuggestionner',
    'autosuggestionnés',
    'autosuggestionnez',
    'bactériostatiques',
    'bastillonnassions',
    'bastillonneraient',
    'bibliothéconomies',
    'biobibliographies',
    'boustifaillassent',
    'boustifaillassiez',
    'boustifaillerions',
    'bracelets-montres',
    'brillantinassions',
    'brillantineraient',
    'bringueballassent',
    'bringueballassiez',
    'bringueballerions',
    'brinquebalassions',
    'brinquebaleraient',
    'brinqueballassent',
    'brinqueballassiez',
    'brinqueballerions',
    'broncho-pneumonie',
    'brouillonnassions',
    'brouillonneraient',
    'bureaucratisaient',
    'bureaucratisasses',
    'bureaucratisation',
    'bureaucratiserais',
    'bureaucratiserait',
    'bureaucratisèrent',
    'bureaucratiseriez',
    'bureaucratiserons',
    'bureaucratiseront',
    'caillebotteraient',
    'calligraphiassent',
    'calligraphiassiez',
    'calligraphierions',
    'calorifugeassions',
    'caoutchoutassions',
    'caoutchouteraient',
    'caparaçonnassions',
    'caparaçonneraient',
    'caractérisassions',
    'caractériseraient',
    'caractérologiques',
    'carcinogénétiques',
    'cardio-vasculaire',
    'catastrophassions',
    'catastropheraient',
    'cauchemardassions',
    'cauchemarderaient',
    'centrifugeassions',
    'céphalo-rachidien',
    'champagnisassions',
    'champagniseraient',
    'chauffe-assiettes',
    'chèque-restaurant',
    'chirographiassent',
    'chirographiassiez',
    'chirographierions',
    'chloroformassions',
    'chloroformeraient',
    'chlorophylliennes',
    'christianisassent',
    'christianisassiez',
    'christianisations',
    'christianiserions',
    'chromotypographie',
    'chronologiquement',
    'chronométrassions',
    'chronométreraient',
    'chryséléphantines',
    'cinématographiais',
    'cinématographiait',
    'cinématographiant',
    'cinématographiées',
    'cinématographient',
    'cinématographiera',
    'cinématographiiez',
    'cinématographions',
    'cinématographique',
    'circonscriptibles',
    'circonscrivissent',
    'circonscrivissiez',
    'circonstanciaient',
    'circonstanciasses',
    'circonstancielles',
    'circonstancierais',
    'circonstancierait',
    'circonstancièrent',
    'circonstancieriez',
    'circonstancierons',
    'circonstancieront',
    'circonviendraient',
    'circumnavigations',
    'climatopathologie',
    'clochardisassions',
    'clochardiseraient',
    'coadministrateurs',
    'coadministratrice',
    'collationnassions',
    'collationneraient',
    'collectionnassent',
    'collectionnassiez',
    'collectionnerions',
    'collectivisassent',
    'collectivisassiez',
    'collectivisations',
    'collectiviserions',
    'commercialisaient',
    'commercialisasses',
    'commercialisation',
    'commercialiserais',
    'commercialiserait',
    'commercialisèrent',
    'commercialiseriez',
    'commercialiserons',
    'commercialiseront',
    'commissionnassent',
    'commissionnassiez',
    'commissionnements',
    'commissionnerions',
    'commotionnassions',
    'commotionneraient',
    'communaliseraient',
    'compartimentaient',
    'compartimentasses',
    'compartimentation',
    'compartimenterais',
    'compartimenterait',
    'compartimentèrent',
    'compartimenteriez',
    'compartimenterons',
    'compartimenteront',
    'complexifiassions',
    'complexifieraient',
    'complimentassions',
    'complimenteraient',
    'compréhensibilité',
    'comptabilisassent',
    'comptabilisassiez',
    'comptabilisations',
    'comptabiliserions',
    'conceptualisaient',
    'conceptualisasses',
    'conceptualisation',
    'conceptualiserais',
    'conceptualiserait',
    'conceptualisèrent',
    'conceptualiseriez',
    'conceptualiserons',
    'conceptualiseront',
    'concurrençassions',
    'concurrenceraient',
    'condescendissions',
    'conditionnassions',
    'conditionneraient',
    'confectionnassent',
    'confectionnassiez',
    'confectionnerions',
    'congestionnassent',
    'congestionnassiez',
    'congestionnerions',
    'constitutionnelle',
    'consubstantialité',
    'consubstantiation',
    'consubstantielles',
    'contagionnassions',
    'contagionneraient',
    'containerisassent',
    'containerisassiez',
    'containeriserions',
    'conteneurisassent',
    'conteneurisassiez',
    'conteneurisations',
    'conteneuriserions',
    'contingentassions',
    'contingenteraient',
    'contorsionnassent',
    'contorsionnassiez',
    'contorsionnerions',
    'contractualisâmes',
    'contractualisasse',
    'contractualisâtes',
    'contractualiserai',
    'contractualiseras',
    'contractualiserez',
    'contractualisions',
    'contractuellement',
    'contracturassions',
    'contractureraient',
    'contre-assurances',
    'contre-attaquâmes',
    'contre-attaquasse',
    'contre-attaquâtes',
    'contre-attaquerai',
    'contre-attaqueras',
    'contre-attaquerez',
    'contre-attaquions',
    'contrebalançaient',
    'contrebalançasses',
    'contrebalancerais',
    'contrebalancerait',
    'contrebalancèrent',
    'contrebalanceriez',
    'contrebalancerons',
    'contrebalanceront',
    'contrebattissions',
    'contreboutassions',
    'contrebouteraient',
    'contre-butassions',
    'contre-buteraient',
    'contrecarrassions',
    'contrecarreraient',
    'contre-empreintes',
    'contre-épaulettes',
    'contre-espionnage',
    'contre-expertises',
    'contre-extensions',
    'contrefichassions',
    'contreficheraient',
    'contre-indication',
    'contre-indiquâmes',
    'contre-indiquasse',
    'contre-indiquâtes',
    'contre-indiquerai',
    'contre-indiqueras',
    'contre-indiquerez',
    'contre-indiquions',
    'contremandassions',
    'contremanderaient',
    'contre-manifestai',
    'contre-manifestas',
    'contre-manifestât',
    'contre-manifester',
    'contre-manifestes',
    'contre-manifestez',
    'contremarquassent',
    'contremarquassiez',
    'contremarquerions',
    'contre-minassions',
    'contre-mineraient',
    'contre-murassions',
    'contre-mureraient',
    'contre-offensives',
    'contre-passassent',
    'contre-passassiez',
    'contre-passations',
    'contre-passerions',
    'contre-plaquaient',
    'contre-plaquasses',
    'contre-plaquerais',
    'contre-plaquerait',
    'contre-plaquèrent',
    'contre-plaqueriez',
    'contre-plaquerons',
    'contre-plaqueront',
    'contre-prestation',
    'contre-propagande',
    'contre-publicités',
    'contre-révolution',
    'contre-scellaient',
    'contre-scellasses',
    'contre-scellerais',
    'contre-scellerait',
    'contre-scellèrent',
    'contre-scelleriez',
    'contre-scellerons',
    'contre-scelleront',
    'contresignassions',
    'contresignataires',
    'contresigneraient',
    'contre-terrorisme',
    'contre-terroriste',
    'contre-tirassions',
    'contre-tireraient',
    'contre-torpilleur',
    'contreviendraient',
    'controversassions',
    'controverseraient',
    'contusionnassions',
    'contusionneraient',
    'conventionnassent',
    'conventionnassiez',
    'conventionnements',
    'conventionnerions',
    'conversationnelle',
    'convulsionnassent',
    'convulsionnassiez',
    'convulsionnerions',
    'correctionnalisai',
    'correctionnalisas',
    'correctionnalisât',
    'correctionnalisée',
    'correctionnaliser',
    'correctionnalisés',
    'correctionnalisez',
    'correspondancière',
    'correspondanciers',
    'correspondissions',
    'court-circuitâmes',
    'court-circuitasse',
    'court-circuitâtes',
    'court-circuiterai',
    'court-circuiteras',
    'court-circuiterez',
    'court-circuitions',
    'crachouillassions',
    'crachouilleraient',
    'criminalisassions',
    'criminaliseraient',
    'cristallisassions',
    'cristalliseraient',
    'criticaillassions',
    'criticailleraient',
    'culpabilisassions',
    'culpabiliseraient',
    'cytogénéticiennes',
    'dactylographiâmes',
    'dactylographiasse',
    'dactylographiâtes',
    'dactylographierai',
    'dactylographieras',
    'dactylographierez',
    'dactylographiions',
    'dactylographiques',
    'débâillonnassions',
    'débâillonneraient',
    'débarbouillassent',
    'débarbouillassiez',
    'débarbouillerions',
    'débecquetteraient',
    'débraguettassions',
    'débraguetteraient',
    'débroussaillaient',
    'débroussaillasses',
    'débroussaillement',
    'débroussaillerais',
    'débroussaillerait',
    'débroussaillèrent',
    'débroussailleriez',
    'débroussaillerons',
    'débroussailleront',
    'débudgétisassions',
    'débudgétiseraient',
    'débureaucratisais',
    'débureaucratisait',
    'débureaucratisant',
    'débureaucratisées',
    'débureaucratisent',
    'débureaucratisera',
    'débureaucratisiez',
    'débureaucratisons',
    'décadenassassions',
    'décadenasseraient',
    'décapitalisassent',
    'décapitalisassiez',
    'décapitaliserions',
    'décapuchonnassent',
    'décapuchonnassiez',
    'décapuchonnerions',
    'décarbonatassions',
    'décarbonateraient',
    'décartellisations',
    'décentralisassent',
    'décentralisassiez',
    'décentralisateurs',
    'décentralisations',
    'décentralisatrice',
    'décentraliserions',
    'déchaperonnassent',
    'déchaperonnassiez',
    'déchaperonnerions',
    'déchiffonnassions',
    'déchiffonneraient',
    'déchiquetteraient',
    'déchristianisâmes',
    'déchristianisasse',
    'déchristianisâtes',
    'déchristianiserai',
    'déchristianiseras',
    'déchristianiserez',
    'déchristianisions',
    'décléricalisaient',
    'décléricalisasses',
    'décléricaliserais',
    'décléricaliserait',
    'décléricalisèrent',
    'décléricaliseriez',
    'décléricaliserons',
    'décléricaliseront',
    'décliquetteraient',
    'décloisonnassions',
    'décloisonneraient',
    'décompressassions',
    'décompresseraient',
    'déconcentrassions',
    'déconcentreraient',
    'déconditionnaient',
    'déconditionnasses',
    'déconditionnement',
    'déconditionnerais',
    'déconditionnerait',
    'déconditionnèrent',
    'déconditionneriez',
    'déconditionnerons',
    'déconditionneront',
    'décongestionnâmes',
    'décongestionnasse',
    'décongestionnâtes',
    'décongestionnerai',
    'décongestionneras',
    'décongestionnerez',
    'décongestionnions',
    'déconseillassions',
    'déconseilleraient',
    'déconsidérassions',
    'déconsidéreraient',
    'décontaminassions',
    'décontamineraient',
    'décontenançassent',
    'décontenançassiez',
    'décontenancerions',
    'décontractassions',
    'décontracteraient',
    'décriminalisaient',
    'décriminalisasses',
    'décriminaliserais',
    'décriminaliserait',
    'décriminalisèrent',
    'décriminaliseriez',
    'décriminaliserons',
    'décriminaliseront',
    'déculpabilisaient',
    'déculpabilisasses',
    'déculpabiliserais',
    'déculpabiliserait',
    'déculpabilisèrent',
    'déculpabiliseriez',
    'déculpabiliserons',
    'déculpabiliseront',
    'dédifférenciaient',
    'dédifférenciasses',
    'dédifférencierais',
    'dédifférencierait',
    'dédifférencièrent',
    'dédifférencieriez',
    'dédifférencierons',
    'dédifférencieront',
    'dédramatisassions',
    'dédramatiseraient',
    'défouraillassions',
    'défourailleraient',
    'dégoudronnassions',
    'dégoudronneraient',
    'délabialisassions',
    'délabialiseraient',
    'démagnétisassions',
    'démagnétiseraient',
    'démantibulassions',
    'démantibuleraient',
    'dématérialisaient',
    'dématérialisasses',
    'dématérialisation',
    'dématérialiserais',
    'dématérialiserait',
    'dématérialisèrent',
    'dématérialiseriez',
    'dématérialiserons',
    'dématérialiseront',
    'déméthanisassions',
    'déméthaniseraient',
    'démilitarisassent',
    'démilitarisassiez',
    'démilitarisations',
    'démilitariserions',
    'déminéralisassent',
    'déminéralisassiez',
    'déminéralisations',
    'déminéraliserions',
    'demi-pensionnaire',
    'démissionnassions',
    'démissionneraient',
    'démocratisassions',
    'démocratiseraient',
    'démonstrativement',
    'démouchetteraient',
    'démouscaillassent',
    'démouscaillassiez',
    'démouscaillerions',
    'démoustiquassions',
    'démoustiqueraient',
    'démultipliassions',
    'démultiplicateurs',
    'démultiplications',
    'démultiplicatrice',
    'démultiplieraient',
    'démystificatrices',
    'dénationalisaient',
    'dénationalisasses',
    'dénationalisation',
    'dénationaliserais',
    'dénationaliserait',
    'dénationalisèrent',
    'dénationaliseriez',
    'dénationaliserons',
    'dénationaliseront',
    'dénaturalisassent',
    'dénaturalisassiez',
    'dénaturalisations',
    'dénaturaliserions',
    'dénébulisaissions',
    'dénicotinisassent',
    'dénicotinisassiez',
    'dénicotiniserions',
    'dénucléarisassent',
    'dénucléarisassiez',
    'dénucléariserions',
    'dépaisselleraient',
    'déparaffinassions',
    'déparaffineraient',
    'départementaliera',
    'départementalisai',
    'départementalisas',
    'départementalisât',
    'départementalisée',
    'départementaliser',
    'départementalises',
    'départementalisés',
    'départementalisez',
    'dépassionnassions',
    'dépassionneraient',
    'dépatouillassions',
    'dépatouilleraient',
    'dépelotonnassions',
    'dépelotonneraient',
    'dépersonnalisâmes',
    'dépersonnalisasse',
    'dépersonnalisâtes',
    'dépersonnaliserai',
    'dépersonnaliseras',
    'dépersonnaliserez',
    'dépersonnalisions',
    'dépolymérisassent',
    'dépolymérisassiez',
    'dépolymériserions',
    'dépoussiérassions',
    'dépoussiéreraient',
    'dépressurisassent',
    'dépressurisassiez',
    'dépressurisations',
    'dépressuriserions',
    'déprogrammassions',
    'déprogrammeraient',
    'déprolétarisaient',
    'déprolétarisasses',
    'déprolétariserais',
    'déprolétariserait',
    'déprolétarisèrent',
    'déprolétariseriez',
    'déprolétariserons',
    'déprolétariseront',
    'dépropanisassions',
    'dépropaniseraient',
    'déraisonnablement',
    'déréglementations',
    'désacclimatassent',
    'désacclimatassiez',
    'désacclimaterions',
    'désaccouplassions',
    'désaccoupleraient',
    'désaccoutumassent',
    'désaccoutumassiez',
    'désaccoutumerions',
    'désacralisassions',
    'désacraliseraient',
    'désaffectionnâmes',
    'désaffectionnasse',
    'désaffectionnâtes',
    'désaffectionnerai',
    'désaffectionneras',
    'désaffectionnerez',
    'désaffectionnions',
    'désambiguïsassent',
    'désambiguïsassiez',
    'désambiguïserions',
    'désamidonnassions',
    'désamidonneraient',
    'désappointassions',
    'désappointeraient',
    'désapprouvassions',
    'désapprouveraient',
    'désapprovisionnai',
    'désapprovisionnas',
    'désapprovisionnât',
    'désapprovisionnée',
    'désapprovisionner',
    'désapprovisionnes',
    'désapprovisionnés',
    'désapprovisionnez',
    'désarticulassions',
    'désarticuleraient',
    'désassemblassions',
    'désassembleraient',
    'désassimilassions',
    'désassimileraient',
    'désassortissaient',
    'désavantageassent',
    'désavantageassiez',
    'désavantageraient',
    'désembourbassions',
    'désembourberaient',
    'désembourgeoisais',
    'désembourgeoisait',
    'désembourgeoisant',
    'désembourgeoisées',
    'désembourgeoisera',
    'désembourgeoisiez',
    'désembourgeoisons',
    'désembouteillâmes',
    'désembouteillasse',
    'désembouteillâtes',
    'désembouteillerai',
    'désembouteilleras',
    'désembouteillerez',
    'désembouteillions',
    'désemmanchassions',
    'désemmancheraient',
    'désemprisonnaient',
    'désemprisonnasses',
    'désemprisonnerais',
    'désemprisonnerait',
    'désemprisonnèrent',
    'désemprisonneriez',
    'désemprisonnerons',
    'désemprisonneront',
    'désenchaînassions',
    'désenchaîneraient',
    'désenchantassions',
    'désenchanteraient',
    'désencombrassions',
    'désencombreraient',
    'désencrassassions',
    'désencrasseraient',
    'désenflammassions',
    'désenflammeraient',
    'désengorgeassions',
    'désengourdiraient',
    'désengourdissions',
    'désenlaidissaient',
    'désensibilisaient',
    'désensibilisasses',
    'désensibilisateur',
    'désensibilisation',
    'désensibiliserais',
    'désensibiliserait',
    'désensibilisèrent',
    'désensibiliseriez',
    'désensibiliserons',
    'désensibiliseront',
    'désensorcelassent',
    'désensorcelassiez',
    'désensorcellerais',
    'désensorcellerait',
    'désensorcelleriez',
    'désensorcellerons',
    'désensorcelleront',
    'désentortillaient',
    'désentortillasses',
    'désentortillerais',
    'désentortillerait',
    'désentortillèrent',
    'désentortilleriez',
    'désentortillerons',
    'désentortilleront',
    'désenveloppassent',
    'désenveloppassiez',
    'désenvelopperions',
    'désenvenimassions',
    'désenvenimeraient',
    'désenverguassions',
    'désenvergueraient',
    'désépaississaient',
    'déséquilibrassent',
    'déséquilibrassiez',
    'déséquilibrerions',
    'déshumanisassions',
    'déshumaniseraient',
    'déshumidifiassent',
    'déshumidifiassiez',
    'déshumidifierions',
    'déshydrogénassent',
    'déshydrogénassiez',
    'déshydrogénations',
    'déshydrogénerions',
    'déshypothéquaient',
    'déshypothéquasses',
    'déshypothéquerais',
    'déshypothéquerait',
    'déshypothéquèrent',
    'déshypothéqueriez',
    'déshypothéquerons',
    'déshypothéqueront',
    'désillusionnaient',
    'désillusionnasses',
    'désillusionnerais',
    'désillusionnerait',
    'désillusionnèrent',
    'désillusionneriez',
    'désillusionnerons',
    'désillusionneront',
    'désincorporassent',
    'désincorporassiez',
    'désincorporerions',
    'désincrustassions',
    'désincrusteraient',
    'désingularisaient',
    'désingularisasses',
    'désingulariserais',
    'désingulariserait',
    'désingularisèrent',
    'désingulariseriez',
    'désingulariserons',
    'désingulariseront',
    'désinsectisassent',
    'désinsectisassiez',
    'désinsectisations',
    'désinsectiserions',
    'désintéressassent',
    'désintéressassiez',
    'désintéressements',
    'désintéresserions',
    'désintoxiquassent',
    'désintoxiquassiez',
    'désintoxiquerions',
    'désinvestissaient',
    'désinvestissement',
    'désobstruaissions',
    'désolidarisassent',
    'désolidarisassiez',
    'désolidariserions',
    'désorganisassions',
    'désorganisatrices',
    'désorganiseraient',
    'déstabilisassions',
    'déstabiliseraient',
    'destructurassions',
    'destructureraient',
    'désubjectivisâmes',
    'désubjectivisasse',
    'désubjectivisâtes',
    'désubjectiviserai',
    'désubjectiviseras',
    'désubjectiviserez',
    'désubjectivisions',
    'désynchronisaient',
    'désynchronisasses',
    'désynchronisation',
    'désynchroniserais',
    'désynchroniserait',
    'désynchronisèrent',
    'désynchroniseriez',
    'désynchroniserons',
    'désynchroniseront',
    'détransposassions',
    'détransposeraient',
    'deutérocanoniques',
    'déverrouillassent',
    'déverrouillassiez',
    'déverrouillerions',
    'diagnostiquassent',
    'diagnostiquassiez',
    'diagnostiquerions',
    'dialectalisassent',
    'dialectalisassiez',
    'dialectaliserions',
    'différenciassions',
    'différencieraient',
    'dimensionnassions',
    'dimensionneraient',
    'disciplinairement',
    'discontinuassions',
    'discontinueraient',
    'disconviendraient',
    'discutaillassions',
    'discutailleraient',
    'disproportionnais',
    'disproportionnait',
    'disproportionnant',
    'disproportionnées',
    'disproportionnent',
    'disproportionnera',
    'disproportionniez',
    'disproportionnons',
    'disputaillassions',
    'disputailleraient',
    'disqualifiassions',
    'disqualifications',
    'disqualifieraient',
    'dommages-intérêts',
    'doubles-commandes',
    'dysfonctionnement',
    'ébouillantassions',
    'ébouillanteraient',
    'ébourgeonnassions',
    'ébourgeonneraient',
    'échanfreinassions',
    'échanfreineraient',
    'échantillonnaient',
    'échantillonnasses',
    'échantillonnerais',
    'échantillonnerait',
    'échantillonnèrent',
    'échantillonneriez',
    'échantillonnerons',
    'échantillonneront',
    'échantillonneuses',
    'écouvillonnassent',
    'écouvillonnassiez',
    'écouvillonnerions',
    'écrabouillassions',
    'écrabouilleraient',
    'égravillonnassent',
    'égravillonnassiez',
    'égravillonnerions',
    'électrocutassions',
    'électrocuteraient',
    'électrodiagnostic',
    'électrolysassions',
    'électrolyseraient',
    'électromagnétique',
    'électromécaniques',
    'électronisassions',
    'électroniseraient',
    'électroradiologie',
    'électrostrictions',
    'électrotechniques',
    'embarbouillassent',
    'embarbouillassiez',
    'embarbouillerions',
    'embastionnassions',
    'embastionneraient',
    'emberlificotaient',
    'emberlificotasses',
    'emberlificoterais',
    'emberlificoterait',
    'emberlificotèrent',
    'emberlificoteriez',
    'emberlificoterons',
    'emberlificoteront',
    'emberlificoteuses',
    'embourgeoisassent',
    'embourgeoisassiez',
    'embourgeoisements',
    'embourgeoiserions',
    'embouteillassions',
    'embouteilleraient',
    'embroussaillaient',
    'embroussaillasses',
    'embroussaillerais',
    'embroussaillerait',
    'embroussaillèrent',
    'embroussailleriez',
    'embroussaillerons',
    'embroussailleront',
    'émerillonnassions',
    'émerillonneraient',
    'emmouscaillassent',
    'emmouscaillassiez',
    'emmouscaillerions',
    'empapillotassions',
    'empapilloteraient',
    'empiriocriticisme',
    'empoissonnassions',
    'empoissonneraient',
    'empoussiérassions',
    'empoussiéreraient',
    'encapuchonnassent',
    'encapuchonnassiez',
    'encapuchonnerions',
    'encartouchassions',
    'encartoucheraient',
    'encaustiquassions',
    'encaustiqueraient',
    'encéphalographies',
    'enchaperonnassent',
    'enchaperonnassiez',
    'enchaperonnerions',
    'enchevauchassions',
    'enchevaucheraient',
    'encliquetteraient',
    'endivisionnassent',
    'endivisionnassiez',
    'endivisionnerions',
    'engargoussassions',
    'engargousseraient',
    'enguirlandassions',
    'enguirlanderaient',
    'enorgueilliraient',
    'enorgueillissions',
    'enrégimentassions',
    'enrégimenteraient',
    'ensanglantassions',
    'ensanglanteraient',
    'enthousiasmassent',
    'enthousiasmassiez',
    'enthousiasmerions',
    'entraccordassions',
    'entrebâillassions',
    'entrebâilleraient',
    'entrechoquassions',
    'entrechoqueraient',
    'entrecroisassions',
    'entrecroiseraient',
    'entre-déchireriez',
    'entre-déchirerons',
    'entre-détruirions',
    'entre-détruisîmes',
    'entre-détruisions',
    'entre-détruisîtes',
    'entre-dévorassiez',
    'entre-dévorerions',
    'entre-frappassiez',
    'entre-frapperions',
    'entre-heurtassiez',
    'entre-heurterions',
    'entre-mangeassiez',
    'entre-nuisissions',
    'entre-regarderiez',
    'entre-regarderons',
    'entretaillassions',
    'environnementales',
    'époustouflassions',
    'époustoufleraient',
    'escarmouchassions',
    'escarmoucheraient',
    'étrésillonnassent',
    'étrésillonnassiez',
    'étrésillonnements',
    'étrésillonnerions',
    'européanisassions',
    'européaniseraient',
    'excursionnassions',
    'excursionneraient',
    'expérimentalement',
    'expérimentassions',
    'expérimentatrices',
    'expérimenteraient',
    'extériorisassions',
    'extérioriseraient',
    'exterritorialités',
    'extra-sensorielle',
    'extrême-orientale',
    'extrême-orientaux',
    'familiarisassions',
    'familiariseraient',
    'fanfreluchassions',
    'fanfrelucheraient',
    'feuilletiseraient',
    'feuilletonnesques',
    'fidéicommissaires',
    'fonctionnarisâmes',
    'fonctionnarisasse',
    'fonctionnarisâtes',
    'fonctionnariserai',
    'fonctionnariseras',
    'fonctionnariserez',
    'fonctionnarisions',
    'franc-maçonneries',
    'franc-maçonniques',
    'frigorifugeassent',
    'frigorifugeassiez',
    'frigorifugeraient',
    'fusil-mitrailleur',
    'galvanoplastiques',
    'gastro-intestinal',
    'glandouillassions',
    'glandouilleraient',
    'goupillonnassions',
    'goupillonneraient',
    'grammaticalements',
    'grammaticaliserai',
    'grammaticaliseras',
    'grammaticaliserez',
    'grammaticalisions',
    'grandes-duchesses',
    'grands-angulaires',
    'gueuletonnassions',
    'gueuletonneraient',
    'guillemetteraient',
    'gynandromorphisme',
    'haut-commissariat',
    'hauts-de-chausses',
    'hétérochromosomes',
    'hiérarchisassions',
    'hiérarchiseraient',
    'hiérogrammatistes',
    'hispano-américain',
    'hommes-orchestres',
    'homogénéifiassent',
    'homogénéifiassiez',
    'homogénéifierions',
    'homogénéisassions',
    'homogénéisatrices',
    'homogénéiseraient',
    'horokilométriques',
    'hospitalisassions',
    'hospitaliseraient',
    'hydro-électricité',
    'hydro-électriques',
    'hydropneumatiques',
    'hyperchlorhydries',
    'hypersensibilités',
    'hypersustentateur',
    'hypersustentation',
    'hypertrophiassiez',
    'hypertrophierions',
    'immatérialisaient',
    'immatérialisasses',
    'immatérialiserais',
    'immatérialiserait',
    'immatérialisèrent',
    'immatérialiseriez',
    'immatérialiserons',
    'immatérialiseront',
    'immatriculassions',
    'immatriculeraient',
    'immortalisassions',
    'immortaliseraient',
    'immunocompétentes',
    'immunodépresseurs',
    'immunodépressives',
    'immunosuppresseur',
    'imparisyllabiques',
    'impatronisassions',
    'impatroniseraient',
    'imperceptibilités',
    'imperceptiblement',
    'imperméabilisâmes',
    'imperméabilisasse',
    'imperméabilisâtes',
    'imperméabiliserai',
    'imperméabiliseras',
    'imperméabiliserez',
    'imperméabilisions',
    'impersonnellement',
    'imperturbabilités',
    'imperturbablement',
    'impressionnassent',
    'impressionnassiez',
    'impressionnerions',
    'inaccomplissement',
    'incombustibilités',
    'incommunicabilité',
    'incompréhensibles',
    'inconditionnalité',
    'inconditionnelles',
    'inconfortablement',
    'inconstitutionnel',
    'incontestabilités',
    'incontestablement',
    'incorruptibilités',
    'incorruptiblement',
    'incristallisables',
    'indestructibilité',
    'indifférenciation',
    'individualisaient',
    'individualisasses',
    'individualisation',
    'individualiserais',
    'individualiserait',
    'individualisèrent',
    'individualiseriez',
    'individualiserons',
    'individualiseront',
    'indulgenciassions',
    'indulgencieraient',
    'industrialisaient',
    'industrialisasses',
    'industrialisation',
    'industrialiserais',
    'industrialiserait',
    'industrialisèrent',
    'industrialiseriez',
    'industrialiserons',
    'industrialiseront',
    'infantilisassions',
    'infantiliseraient',
    'infériorisassions',
    'inférioriseraient',
    'infermentescibles',
    'informationnelles',
    'informatisassions',
    'informatiseraient',
    'infundibuliformes',
    'ingénieur-conseil',
    'inintelligibilité',
    'insaisissabilités',
    'insensibilisaient',
    'insensibilisasses',
    'insensibilisation',
    'insensibiliserais',
    'insensibiliserait',
    'insensibilisèrent',
    'insensibiliseriez',
    'insensibiliserons',
    'insensibiliseront',
    'insolubilisassent',
    'insolubilisassiez',
    'insolubiliserions',
    'institutionnalisa',
    'institutionnalise',
    'institutionnalisé',
    'institutionnelles',
    'instrumentalismes',
    'instrumentassions',
    'instrumenteraient',
    'insubmersibilités',
    'insulinothérapies',
    'insupportablement',
    'insurrectionnelle',
    'intégrationnistes',
    'intellectualisais',
    'intellectualisait',
    'intellectualisant',
    'intellectualisées',
    'intellectualisent',
    'intellectualisera',
    'intellectualisiez',
    'intellectualismes',
    'intellectualisons',
    'intellectualistes',
    'interclassassions',
    'interclasseraient',
    'intercontinentale',
    'intercontinentaux',
    'interindividuelle',
    'intériorisassions',
    'intérioriseraient',
    'interministériels',
    'intermoléculaires',
    'internationalisai',
    'internationalisas',
    'internationalisât',
    'internationalisée',
    'internationaliser',
    'internationalises',
    'internationalisés',
    'internationalisez',
    'internationalisme',
    'internationaliste',
    'internationalités',
    'interpénétrassiez',
    'interpénétrations',
    'interpénétrerions',
    'interpersonnelles',
    'interrogativement',
    'intersectorielles',
    'interventionnisme',
    'interventionniste',
    'intervertissaient',
    'intramoléculaires',
    'irréprochablement',
    'irresponsabilités',
    'juridictionnelles',
    'jurisprudentielle',
    'kinésithérapeutes',
    'latino-américaine',
    'latino-américains',
    'libre-échangistes',
    'lithographiassent',
    'lithographiassiez',
    'lithographierions',
    'lithotypographies',
    'longitudinalement',
    'macro-économiques',
    'macrophotographie',
    'madrigalisassions',
    'madrigaliseraient',
    'magnétodynamiques',
    'magnétoscopassent',
    'magnétoscopassiez',
    'magnétoscoperions',
    'magnétostrictions',
    'maître-assistante',
    'maîtres-assitants',
    'malléabilisassent',
    'malléabilisassiez',
    'malléabilisations',
    'malléabiliserions',
    'manufacturassions',
    'manufactureraient',
    'manutentionnaient',
    'manutentionnaires',
    'manutentionnasses',
    'manutentionnerais',
    'manutentionnerait',
    'manutentionnèrent',
    'manutentionneriez',
    'manutentionnerons',
    'manutentionneront',
    'maquereautassions',
    'maquereauteraient',
    'maquignonnassions',
    'maquignonneraient',
    'marginalisassions',
    'marginaliseraient',
    'masculinisassions',
    'masculiniseraient',
    'matérialisassions',
    'matérialiseraient',
    'mathématisassions',
    'mathématiseraient',
    'médicamentassions',
    'médicamenteraient',
    'méphistophéliques',
    'mercantilisassent',
    'mercantilisassiez',
    'mercantiliserions',
    'métalinguistiques',
    'métallographiques',
    'métalloplastiques',
    'métamorphisassent',
    'métamorphisassiez',
    'métamorphiserions',
    'métamorphosassent',
    'métamorphosassiez',
    'métamorphoserions',
    'métaphosphoriques',
    'métaphysiquements',
    'micromanipulation',
    'micro-ordinateurs',
    'microphotographie',
    'miniaturisassions',
    'miniaturiseraient',
    'mithridatisassent',
    'mithridatisassiez',
    'mithridatisations',
    'mithridatiserions',
    'montres-bracelets',
    'morphologiquement',
    'morphopsychologie',
    'moucheronnassions',
    'moucheronneraient',
    'multidimensionnel',
    'multimilliardaire',
    'multimillionnaire',
    'municipalisassent',
    'municipalisassiez',
    'municipalisations',
    'municipaliserions',
    'nationalisassions',
    'nationaliseraient',
    'néo-colonialismes',
    'néo-platonicienne',
    'neurochirurgicale',
    'neurochirurgicaux',
    'neurochirurgienne',
    'neurolinguistique',
    'neurophysiologies',
    'neuropsychiatries',
    'neuropsychologies',
    'neurotransmetteur',
    'neuro-végétatives',
    'non-belligérances',
    'non-belligérantes',
    'non-conciliations',
    'non-contradiction',
    'non-interventions',
    'non-participation',
    'non-prolifération',
    'non-rapatriements',
    'non-remboursables',
    'non-remboursement',
    'non-satisfactions',
    'nord-vietnamienne',
    'nus-propriétaires',
    'obstructionnismes',
    'obstructionnistes',
    'occasionnellement',
    'occidentalisaient',
    'occidentalisasses',
    'occidentalisation',
    'occidentaliserais',
    'occidentaliserait',
    'occidentalisèrent',
    'occidentaliseriez',
    'occidentaliserons',
    'occidentaliseront',
    'oeilletonnassions',
    'oeilletonneraient',
    'officialisassions',
    'officialiseraient',
    'omnidirectionnels',
    'organisationnelle',
    'orthochromatiques',
    'orthographiassent',
    'orthographiassiez',
    'orthographierions',
    'orthosympathiques',
    'paillassonnassent',
    'paillassonnassiez',
    'paillassonnerions',
    'panoramiquassions',
    'panoramiqueraient',
    'parallélisassions',
    'paralléliseraient',
    'parcellarisassent',
    'parcellarisassiez',
    'parcellarisations',
    'parcellariserions',
    'parcimonieusement',
    'parlementairement',
    'parthénogénétique',
    'particularisaient',
    'particularisasses',
    'particularisation',
    'particulariserais',
    'particulariserait',
    'particularisèrent',
    'particulariseriez',
    'particulariserons',
    'particulariseront',
    'partitionnassions',
    'partitionneraient',
    'pathologiquements',
    'peinturlurassions',
    'peinturlureraient',
    'perfectionnassent',
    'perfectionnassiez',
    'perfectionnements',
    'perfectionnerions',
    'péripatéticiennes',
    'perméabilisassent',
    'perméabilisassiez',
    'perméabiliserions',
    'perquisitionnâmes',
    'perquisitionnasse',
    'perquisitionnâtes',
    'perquisitionnerai',
    'perquisitionneras',
    'perquisitionnerez',
    'perquisitionnions',
    'personnalisassent',
    'personnalisassiez',
    'personnalisations',
    'personnaliserions',
    'personnifiassions',
    'personnifications',
    'personnifieraient',
    'petite-bourgeoise',
    'pétrarquisassions',
    'pétrarquiseraient',
    'pharmacodynamique',
    'pharmacogénétique',
    'pharmacothérapies',
    'pharmacovigilance',
    'phénoménologiques',
    'philosophiquement',
    'photoconductteurs',
    'photoconducttrice',
    'photoélectricités',
    'photographiassent',
    'photographiassiez',
    'photographierions',
    'photolithographie',
    'photoluminescence',
    'photomacrographie',
    'photomicrographie',
    'physico-chimiques',
    'physiognomoniques',
    'physiognomonistes',
    'physiologiquement',
    'physiopathologies',
    'piézo-électricité',
    'piézo-électriques',
    'pince-monseigneur',
    'pique-niquassions',
    'pique-niqueraient',
    'plénipotentiaires',
    'plus-que-parfaite',
    'plus-que-parfaits',
    'pneumatothérapies',
    'pochette-surprise',
    'polycondensations',
    'polytechniciennes',
    'porte-baïonnettes',
    'porte-jarretelles',
    'portraiturassions',
    'portraiturerasses',
    'portraiturerèrent',
    'postillonnassions',
    'postillonneraient',
    'postsonorisations',
    'postsynchronisais',
    'postsynchronisait',
    'postsynchronisant',
    'postsynchronisées',
    'postsynchronisent',
    'postsynchronisera',
    'postsynchronisiez',
    'postsynchronisons',
    'potentialisassent',
    'potentialisassiez',
    'potentialiserions',
    'précautionnassent',
    'précautionnassiez',
    'précautionnerions',
    'prédéterminassent',
    'prédéterminassiez',
    'prédéterminations',
    'prédéterminerions',
    'presbytérianismes',
    'présidentialismes',
    'présomptueusement',
    'prestidigitateurs',
    'prestidigitations',
    'prestidigitatrice',
    'processionnassent',
    'processionnassiez',
    'processionnerions',
    'professionnalisme',
    'prohibitionnismes',
    'prohibitionnistes',
    'prolétarisassions',
    'prolétariseraient',
    'pronostiquassions',
    'pronostiqueraient',
    'proportionnalités',
    'proportionnassent',
    'proportionnassiez',
    'proportionnerions',
    'propositionnelles',
    'proverbialisaient',
    'proverbialisasses',
    'proverbialiserais',
    'proverbialiserait',
    'proverbialisèrent',
    'proverbialiseriez',
    'proverbialiserons',
    'proverbialiseront',
    'providentialismes',
    'pseudomembraneuse',
    'psychanalysassent',
    'psychanalysassiez',
    'psychanalyserions',
    'psychiatrisassent',
    'psychiatrisassiez',
    'psychiatriserions',
    'psychoanaleptique',
    'psychodramatiques',
    'psychodysleptique',
    'psycho-éducatives',
    'psychologiquement',
    'psychopathologies',
    'psychophysiologie',
    'psychosensorielle',
    'psychosociologies',
    'psychotechniciens',
    'psychothérapeutes',
    'pyrophosphoriques',
    'quadrisyllabiques',
    'quarderonnassions',
    'quarderonneraient',
    'quartiers-maîtres',
    'quatre-de-chiffre',
    'quatre-vingt-cinq',
    'quatre-vingt-deux',
    'quatre-vingt-huit',
    'quatre-vingtièmes',
    'quatre-vingt-neuf',
    'quatre-vingt-onze',
    'quatre-vingt-sept',
    'quintessenciaient',
    'quintessenciasses',
    'quintessencierais',
    'quintessencierait',
    'quintessencièrent',
    'quintessencieriez',
    'quintessencierons',
    'quintessencieront',
    'raccompagnassions',
    'raccompagneraient',
    'raccourcissements',
    'radiobalisassions',
    'radiobaliseraient',
    'radiodiffusassent',
    'radiodiffusassiez',
    'radiodiffuserions',
    'radioélectricités',
    'radiographiassent',
    'radiographiassiez',
    'radiographierions',
    'radiophotographie',
    'radiotélégraphiai',
    'radiotélégraphias',
    'radiotélégraphiât',
    'radiotélégraphiée',
    'radiotélégraphier',
    'radiotélégraphies',
    'radiotélégraphiés',
    'radiotélégraphiez',
    'radiotéléphoniste',
    'rafraîchissements',
    'ragaillardiraient',
    'ragaillardissions',
    'ramasse-poussière',
    'rapapillotassions',
    'rapapilloteraient',
    'rappareillassions',
    'rappareilleraient',
    'rapprovisionnâmes',
    'rapprovisionnasse',
    'rapprovisionnâtes',
    'rapprovisionnerai',
    'rapprovisionneras',
    'rapprovisionnerez',
    'rapprovisionnions',
    'rationalisassions',
    'rationaliseraient',
    'réaccoutumassions',
    'réaccoutumeraient',
    'réactualisassions',
    'réactualiseraient',
    'réapprovisionnais',
    'réapprovisionnait',
    'réapprovisionnant',
    'réapprovisionnées',
    'réapprovisionnent',
    'réapprovisionnera',
    'réapprovisionniez',
    'réapprovisionnons',
    'réceptionnassions',
    'réceptionneraient',
    'rechristianisâmes',
    'rechristianisasse',
    'rechristianisâtes',
    'rechristianiserai',
    'rechristianiseras',
    'rechristianiserez',
    'rechristianisions',
    'recomparaissaient',
    'recomparaîtraient',
    'reconsidérassions',
    'reconsidéreraient',
    'reconsolidassions',
    'reconsolideraient',
    'reconstituassions',
    'reconstitueraient',
    'reconstruisissent',
    'reconstruisissiez',
    'reconventionnelle',
    'reconvertissaient',
    'recristallisaient',
    'recristallisasses',
    'recristalliserais',
    'recristalliserait',
    'recristallisèrent',
    'recristalliseriez',
    'recristalliserons',
    'recristalliseront',
    'recroquevillaient',
    'recroquevillasses',
    'recroquevillerais',
    'recroquevillerait',
    'recroquevillèrent',
    'recroquevilleriez',
    'recroquevillerons',
    'recroquevilleront',
    'redistribuassions',
    'redistribueraient',
    'réenregistrassent',
    'réenregistrassiez',
    'réenregistrerions',
    'réensemençassions',
    'réensemenceraient',
    'rééquilibrassions',
    'rééquilibrerasses',
    'rééquilibrerèrent',
    'régionalisassions',
    'régionaliseraient',
    'réglementairement',
    'réimperméabilisai',
    'réimperméabilisas',
    'réimperméabilisât',
    'réimperméabilisée',
    'réimperméabiliser',
    'réimperméabilises',
    'réimperméabilisés',
    'réimperméabilisez',
    'réincarcérassions',
    'réincarcéreraient',
    'réincorporassions',
    'réincorporeraient',
    'réinitialisassent',
    'réinitialisassiez',
    'réinitialiserions',
    'réinterprétassent',
    'réinterprétassiez',
    'réinterpréterions',
    'réintroduisissent',
    'réintroduisissiez',
    'remilitarisassent',
    'remilitarisassiez',
    'remilitarisations',
    'remilitariserions',
    'remmaillotassions',
    'remmailloteraient',
    'rempaillerassions',
    'rempaquetteraient',
    'rempoissonnassent',
    'rempoissonnassiez',
    'rempoissonnerions',
    'rentabilisassions',
    'rentabiliseraient',
    'réorchestrassions',
    'réorchestreraient',
    'réordonnançassent',
    'réordonnançassiez',
    'réordonnancerions',
    'repositionnassent',
    'repositionnassiez',
    'repositionnerions',
    'représentativités',
    'reproductibilités',
    'reprogrammassions',
    'reprogrammeraient',
    'reprographiassent',
    'reprographiassiez',
    'reprographierions',
    'républicanisaient',
    'républicanisasses',
    'républicaniserais',
    'républicaniserait',
    'républicanisèrent',
    'républicaniseriez',
    'républicaniserons',
    'républicaniseront',
    'réquisitionnaient',
    'réquisitionnasses',
    'réquisitionnerais',
    'réquisitionnerait',
    'réquisitionnèrent',
    'réquisitionneriez',
    'réquisitionnerons',
    'réquisitionneront',
    'resocialisassions',
    'resocialiseraient',
    'resplendissements',
    'responsabilisâmes',
    'responsabilisasse',
    'responsabilisâtes',
    'responsabiliserai',
    'responsabiliseras',
    'responsabiliserez',
    'responsabilisions',
    'restructurassions',
    'restructureraient',
    'resurchauffassent',
    'resurchauffassiez',
    'resurchaufferions',
    'résurrectionnelle',
    'retranscrivissent',
    'retranscrivissiez',
    'retransmettraient',
    'retravaillassions',
    'retravailleraient',
    'rétrospectivement',
    'revascularisation',
    'réveillonnassions',
    'réveillonneraient',
    'révérencieusement',
    'révolutionnarisme',
    'révolutionnariste',
    'révolutionnassent',
    'révolutionnassiez',
    'révolutionnerions',
    'révolvérisassions',
    'révolvériseraient',
    'rhinopharyngienne',
    'saccharifiassions',
    'saccharifications',
    'saccharifieraient',
    'saint-simoniennes',
    'sanctuarisassions',
    'sanctuariseraient',
    'saucissonnassions',
    'saucissonneraient',
    'sceaux-de-Salomon',
    'sciences-fictions',
    'scintillerassions',
    'scribouillassions',
    'scribouilleraient',
    'sédentarisassions',
    'sédentariseraient',
    'ségrégationnismes',
    'ségrégationnistes',
    'sélectionnassions',
    'sélectionneraient',
    'semi-automatiques',
    'semi-conductrices',
    'semi-manufacturée',
    'semi-manufacturés',
    'sempiternellement',
    'sensationnalismes',
    'sensationnalistes',
    'sensibilisassions',
    'sensibilisatrices',
    'sensibiliseraient',
    'shampooingnassent',
    'shampooingnassiez',
    'shampooingnerions',
    'sigillographiques',
    'significativement',
    'singularisassions',
    'singulariseraient',
    'social-démocratie',
    'sociaux-chrétiens',
    'socio-culturelles',
    'socio-économiques',
    'sociolinguistique',
    'soixante-dix-huit',
    'soixante-dix-neuf',
    'soixante-dix-sept',
    'soixante-quatorze',
    'somato-psychiques',
    'sophistiquassions',
    'sophistiqueraient',
    'soubresautassions',
    'soubresauteraient',
    'soumissionnassent',
    'soumissionnassiez',
    'soumissionnerions',
    'sous-alimentaient',
    'sous-alimentasses',
    'sous-alimentation',
    'sous-alimenterais',
    'sous-alimenterait',
    'sous-alimentèrent',
    'sous-alimenteriez',
    'sous-alimenterons',
    'sous-alimenteront',
    'sous-consommation',
    'sous-entendissent',
    'sous-entendissiez',
    'sous-entendraient',
    'sous-épidermiques',
    'sous-estimassions',
    'sous-estimeraient',
    'sous-évaluassions',
    'sous-évalueraient',
    'sous-exploitaient',
    'sous-exploitasses',
    'sous-exploitation',
    'sous-exploiterais',
    'sous-exploiterait',
    'sous-exploitèrent',
    'sous-exploiteriez',
    'sous-exploiterons',
    'sous-exploiteront',
    'sous-exposassions',
    'sous-exposeraient',
    'sous-frutescentes',
    'sous-prolétariats',
    'sous-secrétariats',
    'sous-traitassions',
    'sous-traiteraient',
    'sous-utilisassent',
    'sous-utilisassiez',
    'sous-utiliserions',
    'spatio-temporelle',
    'spectaculairement',
    'spiritualisassent',
    'spiritualisassiez',
    'spiritualisations',
    'spiritualiserions',
    'squattérisassions',
    'squattériseraient',
    'standardisassions',
    'standardiseraient',
    'staphylococciques',
    'statokinésimètres',
    'sténographiassent',
    'sténographiassiez',
    'sténographierions',
    'stéréocomparateur',
    'stoechiométriques',
    'subdivisionnaires',
    'substantiellement',
    'substantivassions',
    'substantiveraient',
    'subventionnassent',
    'subventionnassiez',
    'subventionnerions',
    'sud-vietnamiennes',
    'suggestionnassent',
    'suggestionnassiez',
    'suggestionnerions',
    'superfécondations',
    'superficiellement',
    'supplémentassions',
    'supplémenteraient',
    'supraconductivité',
    'suralimentassions',
    'suralimenteraient',
    'surcapitalisation',
    'surcomprimassions',
    'surcomprimeraient',
    'surdéterminations',
    'surenchérissaient',
    'surenchérissement',
    'surenchérisseuses',
    'surentraînassions',
    'surentraîneraient',
    'surexploitassions',
    'surexploiteraient',
    'surmédicalisaient',
    'surmédicalisasses',
    'surmédicaliserais',
    'surmédicaliserait',
    'surmédicalisèrent',
    'surmédicaliseriez',
    'surmédicaliserons',
    'surmédicaliseront',
    'surmultiplication',
    'surprises-parties',
    'surprotégeassions',
    'symptomatiquement',
    'synchrocyclotrons',
    'synchronisassions',
    'synchroniseraient',
    'syncristallisâmes',
    'syncristallisasse',
    'syncristallisâtes',
    'syncristalliserai',
    'syncristalliseras',
    'syncristalliserez',
    'syncristallisions',
    'syndicalisassions',
    'syndicaliseraient',
    'systématisassions',
    'systématiseraient',
    'tachistoscopiques',
    'tarabiscotassions',
    'tarabiscoteraient',
    'technocratisaient',
    'technocratisantes',
    'technocratisasses',
    'technocratisation',
    'technocratiserais',
    'technocratiserait',
    'technocratisèrent',
    'technocratiseriez',
    'technocratiserons',
    'technocratiseront',
    'télécommandassent',
    'télécommandassiez',
    'télécommanderions',
    'télécommunication',
    'télédiffusassions',
    'télédiffuseraient',
    'télédistributions',
    'télé-enseignement',
    'téléenseignements',
    'télégraphiassions',
    'télégraphieraient',
    'télégraphiquement',
    'téléinformatiques',
    'télémanipulateurs',
    'télémanipulations',
    'téléphotographies',
    'téléradiographies',
    'télésignalisation',
    'télésurveillances',
    'thalassothérapies',
    'théâtralisassions',
    'théâtraliseraient',
    'théophilanthropes',
    'théophilanthropie',
    'thermodurcissable',
    'thermodynamiciens',
    'thermoélectricité',
    'thermoélectriques',
    'thermogravimétrie',
    'thermomagnétiques',
    'thermopropulsions',
    'thermopropulsives',
    'thermorégulateurs',
    'thermorégulations',
    'thermorégulatrice',
    'thermorésistantes',
    'tintinnabulassent',
    'tintinnabulassiez',
    'tintinnabulerions',
    'tirebouchonnaient',
    'tire-bouchonnâmes',
    'tire-bouchonnasse',
    'tirebouchonnasses',
    'tire-bouchonnâtes',
    'tire-bouchonnerai',
    'tirebouchonnerais',
    'tirebouchonnerait',
    'tire-bouchonneras',
    'tirebouchonnèrent',
    'tire-bouchonnerez',
    'tirebouchonneriez',
    'tirebouchonnerons',
    'tirebouchonneront',
    'tire-bouchonnions',
    'topographiquement',
    'tourbillonnassent',
    'tourbillonnassiez',
    'tourbillonnements',
    'tourbillonnerions',
    'tourillonnassions',
    'tourillonneraient',
    'tourneboulassions',
    'tournebouleraient',
    'toutes-puissantes',
    'trachéo-bronchite',
    'tranchefilassions',
    'tranchefileraient',
    'tranquillisassent',
    'tranquillisassiez',
    'tranquilliserions',
    'transactionnelles',
    'transbahutassions',
    'transbahuteraient',
    'transcontinentale',
    'transcontinentaux',
    'transférentielles',
    'transfigurassions',
    'transfiguratrices',
    'transfigureraient',
    'transformationnel',
    'transgressassions',
    'transgresseraient',
    'transistorisaient',
    'transistorisasses',
    'transistorisation',
    'transistoriserais',
    'transistoriserait',
    'transistorisèrent',
    'transistoriseriez',
    'transistoriserons',
    'transistoriseront',
    'translitérassions',
    'translitéreraient',
    'translittérassent',
    'translittérassiez',
    'translittérations',
    'translittérerions',
    'transmissibilités',
    'transparaissaient',
    'transparaîtraient',
    'transplantassions',
    'transplanteraient',
    'transsubstantiais',
    'transsubstantiait',
    'transsubstantiant',
    'transsubstantiées',
    'transsubstantient',
    'transsubstantiera',
    'transsubstantiiez',
    'transsubstantions',
    'triboélectricités',
    'triboluminescence',
    'trichloracétiques',
    'trichloréthylènes',
    'tridimensionnelle',
    'trimestriellement',
    'tripatouillassent',
    'tripatouillassiez',
    'tripatouillerions',
    'tropicalisassions',
    'tropicaliseraient',
    'troussequinassent',
    'troussequinassiez',
    'troussequinerions',
    'tuberculinassions',
    'tuberculineraient',
    'tuberculinisaient',
    'tuberculinisasses',
    'tuberculinisatios',
    'tuberculiniserais',
    'tuberculiniserait',
    'tuberculinisèrent',
    'tuberculiniseriez',
    'tuberculiniserons',
    'tuberculiniseront',
    'tuberculisassions',
    'tuberculiseraient',
    'turbo-alternateur',
    'turbocompresseurs',
    'typographiquement',
    'typolithographies',
    'ultramicroscopies',
    'unidimensionnelle',
    'unidirectionnelle',
    'universalisassent',
    'universalisassiez',
    'universalisations',
    'universaliserions',
    'vasoconstricteurs',
    'vasoconstrictions',
    'vasoconstrictrice',
    'vermillonnassions',
    'vermillonneraient',
    'villégiaturassent',
    'villégiaturassiez',
    'villégiaturerions',
    'vraisemblablement',
    'wagons-réservoirs',
    'wagons-tombereaux'
];
