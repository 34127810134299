export default [
    'abaissable',
    'abaissâmes',
    'abaissante',
    'abaissants',
    'abaissasse',
    'abaissâtes',
    'abaisserai',
    'abaisseras',
    'abaisserez',
    'abaisseurs',
    'abaissions',
    'abandonnai',
    'abandonnas',
    'abandonnât',
    'abandonnée',
    'abandonner',
    'abandonnes',
    'abandonnés',
    'abandonnez',
    'abasourdie',
    'abasourdir',
    'abasourdis',
    'abasourdit',
    'abasourdît',
    'abâtardies',
    'abâtardira',
    'abattaient',
    'abattantes',
    'abattement',
    'abattirent',
    'abattisses',
    'abattrions',
    'abbatiales',
    'abdication',
    'abdiquâmes',
    'abdiquasse',
    'abdiquâtes',
    'abdiquerai',
    'abdiqueras',
    'abdiquerez',
    'abdiquions',
    'abdominale',
    'abdominaux',
    'abducteurs',
    'abductions',
    'abécédaire',
    'aberraient',
    'aberrances',
    'aberrantes',
    'aberrasses',
    'aberration',
    'aberrerais',
    'aberrerait',
    'aberrèrent',
    'aberreriez',
    'aberrerons',
    'aberreront',
    'abêtirions',
    'abêtissais',
    'abêtissait',
    'abêtissant',
    'abêtissent',
    'abêtissiez',
    'abêtissons',
    'abhorrâmes',
    'abhorrasse',
    'abhorrâtes',
    'abhorrerai',
    'abhorreras',
    'abhorrerez',
    'abhorrions',
    'abîmassent',
    'abîmassiez',
    'abîmerions',
    'abiotiques',
    'abjections',
    'abjuraient',
    'abjurasses',
    'abjuration',
    'abjurerais',
    'abjurerait',
    'abjurèrent',
    'abjureriez',
    'abjurerons',
    'abjureront',
    'abloquâmes',
    'abloquasse',
    'abloquâtes',
    'abloquerai',
    'abloqueras',
    'abloquerez',
    'abloquions',
    'abnégation',
    'aboiements',
    'aboierions',
    'abolirions',
    'abolissais',
    'abolissait',
    'abolissant',
    'abolissent',
    'abolissiez',
    'abolissons',
    'abolitions',
    'abominable',
    'abominâmes',
    'abominasse',
    'abominâtes',
    'abominerai',
    'abomineras',
    'abominerez',
    'abominions',
    'abondaient',
    'abondances',
    'abondantes',
    'abondasses',
    'abonderais',
    'abonderait',
    'abondèrent',
    'abonderiez',
    'abonderons',
    'abonderont',
    'abonnaient',
    'abonnasses',
    'abonnement',
    'abonnerais',
    'abonnerait',
    'abonnèrent',
    'abonneriez',
    'abonnerons',
    'abonneront',
    'abonnirais',
    'abonnirait',
    'abonnirent',
    'abonniriez',
    'abonnirons',
    'abonniront',
    'abonnisses',
    'abonnissez',
    'abordables',
    'abordaient',
    'abordasses',
    'aborderais',
    'aborderait',
    'abordèrent',
    'aborderiez',
    'aborderons',
    'aborderont',
    'aborigènes',
    'abouchâmes',
    'abouchasse',
    'abouchâtes',
    'aboucherai',
    'aboucheras',
    'aboucherez',
    'abouchions',
    'aboulaient',
    'aboulasses',
    'aboulerais',
    'aboulerait',
    'aboulèrent',
    'abouleriez',
    'aboulerons',
    'abouleront',
    'abouliques',
    'aboutaient',
    'aboutasses',
    'aboutement',
    'abouterais',
    'abouterait',
    'aboutèrent',
    'abouteriez',
    'abouterons',
    'abouteront',
    'aboutirais',
    'aboutirait',
    'aboutirent',
    'aboutiriez',
    'aboutirons',
    'aboutiront',
    'aboutisses',
    'aboutissez',
    'aboyassent',
    'aboyassiez',
    'abrasaient',
    'abrasasses',
    'abraserais',
    'abraserait',
    'abrasèrent',
    'abraseriez',
    'abraserons',
    'abraseront',
    'abréaction',
    'abrégeâmes',
    'abrégeasse',
    'abrégeâtes',
    'abrégement',
    'abrègement',
    'abrégerais',
    'abrégerait',
    'abrégèrent',
    'abrégeriez',
    'abrégerons',
    'abrégeront',
    'abreuvâmes',
    'abreuvasse',
    'abreuvâtes',
    'abreuverai',
    'abreuveras',
    'abreuverez',
    'abreuvions',
    'abreuvoirs',
    'abréviatif',
    'abricotais',
    'abricotait',
    'abricotant',
    'abricotées',
    'abricotent',
    'abricotera',
    'abricotier',
    'abricotiez',
    'abricotons',
    'abritaient',
    'abritasses',
    'abriterais',
    'abriterait',
    'abritèrent',
    'abriteriez',
    'abriterons',
    'abriteront',
    'abrogatifs',
    'abrogation',
    'abrogative',
    'abrogeable',
    'abrogeâmes',
    'abrogeasse',
    'abrogeâtes',
    'abrogerais',
    'abrogerait',
    'abrogèrent',
    'abrogeriez',
    'abrogerons',
    'abrogeront',
    'abrutirais',
    'abrutirait',
    'abrutirent',
    'abrutiriez',
    'abrutirons',
    'abrutiront',
    'abrutisses',
    'abrutissez',
    'absentâmes',
    'absentâtes',
    'absenterez',
    'absentions',
    'absolument',
    'absolution',
    'absolvions',
    'absorbâmes',
    'absorbante',
    'absorbants',
    'absorbasse',
    'absorbâtes',
    'absorberai',
    'absorberas',
    'absorberez',
    'absorbeurs',
    'absorbions',
    'absorption',
    'absoudrais',
    'absoudrait',
    'absoudriez',
    'absoudrons',
    'absoudront',
    'abstenions',
    'abstention',
    'abstinence',
    'abstinente',
    'abstinents',
    'abstraient',
    'abstrairai',
    'abstrairas',
    'abstrairez',
    'abstraites',
    'abstrayais',
    'abstrayait',
    'abstrayant',
    'abstrayiez',
    'abstrayons',
    'absurdités',
    'abusassent',
    'abusassiez',
    'abuserions',
    'abyssinien',
    'académique',
    'académisme',
    'acadiennes',
    'acagnardée',
    'acagnarder',
    'acagnardés',
    'acagnardez',
    'acanthacée',
    'acariâtres',
    'accablâmes',
    'accablante',
    'accablants',
    'accablasse',
    'accablâtes',
    'accablerai',
    'accableras',
    'accablerez',
    'accablions',
    'accaparais',
    'accaparait',
    'accaparant',
    'accaparées',
    'accaparent',
    'accaparera',
    'accapareur',
    'accapariez',
    'accaparons',
    'accastilla',
    'accastille',
    'accastillé',
    'accédaient',
    'accédasses',
    'accéderais',
    'accéderait',
    'accédèrent',
    'accéderiez',
    'accéderons',
    'accéderont',
    'accélérais',
    'accélérait',
    'accélérant',
    'accélérées',
    'accélèrent',
    'accélérera',
    'accélériez',
    'accélérons',
    'accentuais',
    'accentuait',
    'accentuant',
    'accentuées',
    'accentuels',
    'accentuent',
    'accentuera',
    'accentuiez',
    'accentuons',
    'acceptable',
    'acceptâmes',
    'acceptante',
    'acceptants',
    'acceptasse',
    'acceptâtes',
    'accepterai',
    'accepteras',
    'accepterez',
    'accepteurs',
    'acceptions',
    'accessible',
    'accessions',
    'accessoire',
    'accidentai',
    'accidentas',
    'accidentât',
    'accidentée',
    'accidentel',
    'accidenter',
    'accidentes',
    'accidentés',
    'accidentez',
    'acclamâmes',
    'acclamasse',
    'acclamâtes',
    'acclamerai',
    'acclameras',
    'acclamerez',
    'acclamions',
    'acclimatai',
    'acclimatas',
    'acclimatât',
    'acclimatée',
    'acclimater',
    'acclimates',
    'acclimatés',
    'acclimatez',
    'accointées',
    'accointiez',
    'accointons',
    'accolaient',
    'accolasses',
    'accolerais',
    'accolerait',
    'accolèrent',
    'accoleriez',
    'accolerons',
    'accoleront',
    'accommodai',
    'accommodas',
    'accommodat',
    'accommodât',
    'accommodée',
    'accommoder',
    'accommodes',
    'accommodés',
    'accommodez',
    'accompagna',
    'accompagne',
    'accompagné',
    'accomplies',
    'accomplira',
    'accoraient',
    'accorasses',
    'accordable',
    'accordâmes',
    'accordasse',
    'accordâtes',
    'accordéons',
    'accorderai',
    'accorderas',
    'accorderez',
    'accordeurs',
    'accordions',
    'accordoirs',
    'accorerais',
    'accorerait',
    'accorèrent',
    'accoreriez',
    'accorerons',
    'accoreront',
    'accostable',
    'accostages',
    'accostâmes',
    'accostasse',
    'accostâtes',
    'accosterai',
    'accosteras',
    'accosterez',
    'accostions',
    'accotaient',
    'accotasses',
    'accotement',
    'accoterais',
    'accoterait',
    'accotèrent',
    'accoteriez',
    'accoterons',
    'accoteront',
    'accouaient',
    'accouasses',
    'accouchais',
    'accouchait',
    'accouchant',
    'accouchées',
    'accouchent',
    'accouchera',
    'accoucheur',
    'accouchiez',
    'accouchons',
    'accoudâmes',
    'accoudâtes',
    'accouderez',
    'accoudions',
    'accoudoirs',
    'accouerais',
    'accouerait',
    'accouèrent',
    'accoueriez',
    'accouerons',
    'accoueront',
    'accouplais',
    'accouplait',
    'accouplant',
    'accouplées',
    'accouplent',
    'accouplera',
    'accoupliez',
    'accouplons',
    'accourcira',
    'accourions',
    'accourrais',
    'accourrait',
    'accourriez',
    'accourrons',
    'accourront',
    'accourûmes',
    'accourusse',
    'accourûtes',
    'accoutrais',
    'accoutrait',
    'accoutrant',
    'accoutrées',
    'accoutrent',
    'accoutrera',
    'accoutriez',
    'accoutrons',
    'accoutumai',
    'accoutumas',
    'accoutumât',
    'accoutumée',
    'accoutumer',
    'accoutumes',
    'accoutumés',
    'accoutumez',
    'accouvages',
    'accréditai',
    'accréditas',
    'accréditât',
    'accréditée',
    'accréditer',
    'accrédites',
    'accrédités',
    'accréditez',
    'accréditif',
    'accrescent',
    'accrétions',
    'accrochage',
    'accrochais',
    'accrochait',
    'accrochant',
    'accrochées',
    'accrochent',
    'accrochera',
    'accrocheur',
    'accrochiez',
    'accrochons',
    'accroissez',
    'accroîtrai',
    'accroîtras',
    'accroîtrez',
    'accroupies',
    'accrussent',
    'accrussiez',
    'accueilles',
    'accueillez',
    'accueillie',
    'accueillir',
    'accueillis',
    'accueillit',
    'accueillît',
    'acculaient',
    'acculasses',
    'acculerais',
    'acculerait',
    'acculèrent',
    'acculeriez',
    'acculerons',
    'acculeront',
    'acculturai',
    'acculturas',
    'acculturât',
    'acculturée',
    'acculturer',
    'accultures',
    'acculturés',
    'acculturez',
    'accumulais',
    'accumulait',
    'accumulant',
    'accumulées',
    'accumulent',
    'accumulera',
    'accumuliez',
    'accumulons',
    'accusaient',
    'accusasses',
    'accusateur',
    'accusatifs',
    'accusation',
    'accusative',
    'accuserais',
    'accuserait',
    'accusèrent',
    'accuseriez',
    'accuserons',
    'accuseront',
    'acérassent',
    'acérassiez',
    'acérerions',
    'acescences',
    'acescentes',
    'acétamides',
    'acétifiais',
    'acétifiait',
    'acétifiant',
    'acétifiées',
    'acétifient',
    'acétifiera',
    'acétifiiez',
    'acétifions',
    'acétonémie',
    'acétonurie',
    'acétylâmes',
    'acétylasse',
    'acétylâtes',
    'acétylènes',
    'acétylerai',
    'acétyleras',
    'acétylerez',
    'acétylions',
    'achalandai',
    'achalandas',
    'achalandât',
    'achalandée',
    'achalander',
    'achalandes',
    'achalandés',
    'achalandez',
    'acharnâmes',
    'acharnasse',
    'acharnâtes',
    'acharnerai',
    'acharneras',
    'acharnerez',
    'acharnions',
    'acheminais',
    'acheminait',
    'acheminant',
    'acheminées',
    'acheminent',
    'acheminera',
    'acheminiez',
    'acheminons',
    'achetables',
    'achetaient',
    'achetasses',
    'achèterais',
    'achèterait',
    'achetèrent',
    'achèteriez',
    'achèterons',
    'achèteront',
    'acheteuses',
    'acheuléens',
    'achevaient',
    'achevasses',
    'achèvement',
    'achèverais',
    'achèverait',
    'achevèrent',
    'achèveriez',
    'achèverons',
    'achèveront',
    'achoppâmes',
    'achoppâtes',
    'achopperez',
    'achoppions',
    'aciculaire',
    'acidifiais',
    'acidifiait',
    'acidifiant',
    'acidifiées',
    'acidifient',
    'acidifiera',
    'acidifiiez',
    'acidifions',
    'acidimètre',
    'acidophile',
    'acidulâmes',
    'acidulasse',
    'acidulâtes',
    'acidulerai',
    'aciduleras',
    'acidulerez',
    'acidulions',
    'aciéraient',
    'aciérasses',
    'aciération',
    'aciérerais',
    'aciérerait',
    'aciérèrent',
    'aciéreriez',
    'aciérerons',
    'aciéreront',
    'aciselâmes',
    'aciselasse',
    'aciselâtes',
    'acisèlerai',
    'acisèleras',
    'acisèlerez',
    'aciselions',
    'acliniques',
    'aconitines',
    'acoquinées',
    'acoquiniez',
    'acoquinons',
    'acouphènes',
    'acoustique',
    'acquéresse',
    'acquéreurs',
    'acquérions',
    'acquerrais',
    'acquerrait',
    'acquerriez',
    'acquerrons',
    'acquerront',
    'acquièrent',
    'acquiesçai',
    'acquiesças',
    'acquiesçât',
    'acquiescer',
    'acquiesces',
    'acquiescez',
    'acquisitif',
    'acquissent',
    'acquissiez',
    'acquittais',
    'acquittait',
    'acquittant',
    'acquittées',
    'acquittent',
    'acquittera',
    'acquittiez',
    'acquittons',
    'acrimonies',
    'acrobaties',
    'acroléines',
    'acrophobie',
    'acrostiche',
    'acryliques',
    'actassions',
    'acteraient',
    'actiniques',
    'actinismes',
    'actionnais',
    'actionnait',
    'actionnant',
    'actionnées',
    'actionnent',
    'actionnera',
    'actionneur',
    'actionniez',
    'actionnons',
    'activaient',
    'activasses',
    'activateur',
    'activation',
    'activement',
    'activerais',
    'activerait',
    'activèrent',
    'activeriez',
    'activerons',
    'activeront',
    'activismes',
    'activistes',
    'actualisai',
    'actualisas',
    'actualisât',
    'actualisée',
    'actualiser',
    'actualises',
    'actualisés',
    'actualisez',
    'actualités',
    'actuariats',
    'actuariels',
    'acutangles',
    'adamantine',
    'adamantins',
    'adaptables',
    'adaptaient',
    'adaptasses',
    'adaptateur',
    'adaptatifs',
    'adaptation',
    'adaptative',
    'adapterais',
    'adapterait',
    'adaptèrent',
    'adapteriez',
    'adapterons',
    'adapteront',
    'additionna',
    'additionne',
    'additionné',
    'adducteurs',
    'adductions',
    'adénosines',
    'adéquation',
    'adhéraient',
    'adhérantes',
    'adhérasses',
    'adhérences',
    'adhérentes',
    'adhérerais',
    'adhérerait',
    'adhérèrent',
    'adhéreriez',
    'adhérerons',
    'adhéreront',
    'adhésivité',
    'adipolyses',
    'adipopexie',
    'adiposités',
    'adjacences',
    'adjacentes',
    'adjectivai',
    'adjectival',
    'adjectivas',
    'adjectivât',
    'adjectivée',
    'adjectiver',
    'adjectives',
    'adjectivés',
    'adjectivez',
    'adjoignais',
    'adjoignait',
    'adjoignant',
    'adjoignent',
    'adjoigniez',
    'adjoignons',
    'adjoindrai',
    'adjoindras',
    'adjoindrez',
    'adjonction',
    'adjudantes',
    'adjugeâmes',
    'adjugeasse',
    'adjugeâtes',
    'adjugerais',
    'adjugerait',
    'adjugèrent',
    'adjugeriez',
    'adjugerons',
    'adjugeront',
    'adjuraient',
    'adjurasses',
    'adjuration',
    'adjurerais',
    'adjurerait',
    'adjurèrent',
    'adjureriez',
    'adjurerons',
    'adjureront',
    'adjuvantes',
    'admettions',
    'admettrais',
    'admettrait',
    'admettriez',
    'admettrons',
    'admettront',
    'adminicule',
    'administra',
    'administre',
    'administré',
    'admirables',
    'admiraient',
    'admirasses',
    'admirateur',
    'admiratifs',
    'admiration',
    'admirative',
    'admirerais',
    'admirerait',
    'admirèrent',
    'admireriez',
    'admirerons',
    'admireront',
    'admissible',
    'admissions',
    'admittance',
    'admixtions',
    'admonestai',
    'admonestas',
    'admonestât',
    'admonestée',
    'admonester',
    'admonestes',
    'admonestés',
    'admonestez',
    'admonition',
    'adolescent',
    'adonisâmes',
    'adonisâtes',
    'adoniserez',
    'adonisions',
    'adonneriez',
    'adonnerons',
    'adoptables',
    'adoptaient',
    'adoptantes',
    'adoptasses',
    'adopterais',
    'adopterait',
    'adoptèrent',
    'adopteriez',
    'adopterons',
    'adopteront',
    'adorassent',
    'adorassiez',
    'adorateurs',
    'adorations',
    'adoratrice',
    'adorerions',
    'adossaient',
    'adossasses',
    'adossement',
    'adosserais',
    'adosserait',
    'adossèrent',
    'adosseriez',
    'adosserons',
    'adosseront',
    'adoubaient',
    'adoubasses',
    'adouberais',
    'adouberait',
    'adoubèrent',
    'adouberiez',
    'adouberons',
    'adouberont',
    'adoucirais',
    'adoucirait',
    'adoucirent',
    'adouciriez',
    'adoucirons',
    'adouciront',
    'adoucisses',
    'adoucissez',
    'adragantes',
    'adrénaline',
    'adressages',
    'adressâmes',
    'adressasse',
    'adressâtes',
    'adresserai',
    'adresseras',
    'adresserez',
    'adressions',
    'adsorbante',
    'adsorbants',
    'adsorption',
    'adulassent',
    'adulassiez',
    'adulateurs',
    'adulations',
    'adulatrice',
    'adulerions',
    'adultérais',
    'adultérait',
    'adultérant',
    'adultérées',
    'adultèrent',
    'adultérera',
    'adultériez',
    'adultérine',
    'adultérins',
    'adultérons',
    'adultismes',
    'advections',
    'adventiste',
    'adventives',
    'adverbiale',
    'adverbiaux',
    'adversaire',
    'adversatif',
    'adversités',
    'aérassions',
    'aéreraient',
    'aéro-clubs',
    'aérodromes',
    'aérolithes',
    'aérologies',
    'aéronautes',
    'aéronavale',
    'aéronavals',
    'aérophagie',
    'aéroplanes',
    'aéroportée',
    'aéroportés',
    'aérotrains',
    'affabilité',
    'affabulais',
    'affabulait',
    'affabulant',
    'affabulées',
    'affabulent',
    'affabulera',
    'affabuliez',
    'affabulons',
    'affadirais',
    'affadirait',
    'affadirent',
    'affadiriez',
    'affadirons',
    'affadiront',
    'affadisses',
    'affadissez',
    'affaiblies',
    'affaiblira',
    'affairâmes',
    'affairâtes',
    'affairerez',
    'affairions',
    'affairisme',
    'affairiste',
    'affaissais',
    'affaissait',
    'affaissant',
    'affaissées',
    'affaissent',
    'affaissera',
    'affaissiez',
    'affaissons',
    'affaitages',
    'affalaient',
    'affalasses',
    'affalement',
    'affalerais',
    'affalerait',
    'affalèrent',
    'affaleriez',
    'affalerons',
    'affaleront',
    'affamaient',
    'affamasses',
    'affamerais',
    'affamerait',
    'affamèrent',
    'affameriez',
    'affamerons',
    'affameront',
    'afféageais',
    'afféageait',
    'afféageant',
    'afféageons',
    'afféagerai',
    'afféageras',
    'afféagerez',
    'afféagions',
    'affectâmes',
    'affectasse',
    'affectâtes',
    'affecterai',
    'affecteras',
    'affecterez',
    'affections',
    'affectives',
    'affectueux',
    'afféraient',
    'afférasses',
    'afférentes',
    'afférerais',
    'afférerait',
    'afférèrent',
    'afféreriez',
    'afférerons',
    'afféreront',
    'affermages',
    'affermâmes',
    'affermasse',
    'affermâtes',
    'affermerai',
    'affermeras',
    'affermerez',
    'affermîmes',
    'affermions',
    'affermirai',
    'affermiras',
    'affermirez',
    'affermisse',
    'affermîtes',
    'afféteries',
    'affichable',
    'affichages',
    'affichâmes',
    'affichasse',
    'affichâtes',
    'afficherai',
    'afficheras',
    'afficherez',
    'affichette',
    'afficheurs',
    'affichions',
    'affichiste',
    'affidavits',
    'affilaient',
    'affilasses',
    'affilerais',
    'affilerait',
    'affilèrent',
    'affileriez',
    'affilerons',
    'affileront',
    'affiliâmes',
    'affiliasse',
    'affiliâtes',
    'affilierai',
    'affilieras',
    'affilierez',
    'affiliions',
    'affinaient',
    'affinasses',
    'affinement',
    'affinerais',
    'affinerait',
    'affinèrent',
    'affineriez',
    'affinerons',
    'affineront',
    'affineuses',
    'affirmâmes',
    'affirmasse',
    'affirmâtes',
    'affirmatif',
    'affirmerai',
    'affirmeras',
    'affirmerez',
    'affirmions',
    'affixation',
    'affleurais',
    'affleurait',
    'affleurant',
    'affleurées',
    'affleurent',
    'affleurera',
    'affleuriez',
    'affleurons',
    'afflictifs',
    'affliction',
    'afflictive',
    'affligeais',
    'affligeait',
    'affligeant',
    'affligeons',
    'affligerai',
    'affligeras',
    'affligerez',
    'affligions',
    'afflouâmes',
    'afflouasse',
    'afflouâtes',
    'afflouerai',
    'affloueras',
    'afflouerez',
    'afflouions',
    'affluaient',
    'affluasses',
    'affluences',
    'affluentes',
    'affluerais',
    'affluerait',
    'affluèrent',
    'afflueriez',
    'affluerons',
    'afflueront',
    'affolaient',
    'affolantes',
    'affolasses',
    'affolement',
    'affolerais',
    'affolerait',
    'affolèrent',
    'affoleriez',
    'affolerons',
    'affoleront',
    'affouageai',
    'affouageas',
    'affouageât',
    'affouagées',
    'affouagent',
    'affouagera',
    'affouagiez',
    'affouillai',
    'affouillas',
    'affouillât',
    'affouillée',
    'affouiller',
    'affouilles',
    'affouillés',
    'affouillez',
    'affouragea',
    'affouragée',
    'affourager',
    'affourages',
    'affouragés',
    'affouragez',
    'affourchai',
    'affourchas',
    'affourchât',
    'affourchée',
    'affourcher',
    'affourches',
    'affourchés',
    'affourchez',
    'affourrage',
    'affourragé',
    'affranchie',
    'affranchir',
    'affranchis',
    'affranchit',
    'affranchît',
    'affrétâmes',
    'affrétasse',
    'affrétâtes',
    'affréterai',
    'affréteras',
    'affréterez',
    'affréteurs',
    'affrétions',
    'affriandai',
    'affriandas',
    'affriandât',
    'affriandée',
    'affriander',
    'affriandes',
    'affriandés',
    'affriandez',
    'affrichais',
    'affrichait',
    'affrichant',
    'affrichées',
    'affrichent',
    'affrichera',
    'affrichiez',
    'affrichons',
    'affriolais',
    'affriolait',
    'affriolant',
    'affriolées',
    'affriolent',
    'affriolera',
    'affrioliez',
    'affriolons',
    'affriquées',
    'affritâmes',
    'affritasse',
    'affritâtes',
    'affriterai',
    'affriteras',
    'affriterez',
    'affritions',
    'affrontais',
    'affrontait',
    'affrontant',
    'affrontées',
    'affrontent',
    'affrontera',
    'affrontiez',
    'affrontons',
    'affruitais',
    'affruitait',
    'affruitant',
    'affruitent',
    'affruitera',
    'affruitiez',
    'affruitons',
    'affublâmes',
    'affublasse',
    'affublâtes',
    'affublerai',
    'affubleras',
    'affublerez',
    'affublions',
    'affuraient',
    'affurasses',
    'affurerais',
    'affurerait',
    'affurèrent',
    'affureriez',
    'affurerons',
    'affureront',
    'affûtaient',
    'affûtasses',
    'affûterais',
    'affûterait',
    'affûtèrent',
    'affûteriez',
    'affûterons',
    'affûteront',
    'affûteuses',
    'aficionado',
    'africaines',
    'africanisa',
    'africanise',
    'africanisé',
    'afrikander',
    'agaçassent',
    'agaçassiez',
    'agacements',
    'agacerions',
    'agalacties',
    'agençaient',
    'agençasses',
    'agencement',
    'agencerais',
    'agencerait',
    'agencèrent',
    'agenceriez',
    'agencerons',
    'agenceront',
    'agenouillé',
    'agglomérai',
    'aggloméras',
    'agglomérat',
    'agglomérât',
    'agglomérée',
    'agglomérer',
    'agglomérés',
    'agglomères',
    'agglomérez',
    'agglutinai',
    'agglutinas',
    'agglutinât',
    'agglutinée',
    'agglutiner',
    'agglutines',
    'agglutinés',
    'agglutinez',
    'aggravâmes',
    'aggravante',
    'aggravants',
    'aggravasse',
    'aggravâtes',
    'aggraverai',
    'aggraveras',
    'aggraverez',
    'aggravions',
    'agiotaient',
    'agiotasses',
    'agioterais',
    'agioterait',
    'agiotèrent',
    'agioteriez',
    'agioterons',
    'agioteront',
    'agissaient',
    'agissement',
    'agitassent',
    'agitassiez',
    'agitateurs',
    'agitations',
    'agitatrice',
    'agiterions',
    'agnelaient',
    'agnelasses',
    'agnelèrent',
    'agnellerai',
    'agnelleras',
    'agnellerez',
    'agnostique',
    'agonirions',
    'agonisâmes',
    'agonisante',
    'agonisants',
    'agonisasse',
    'agonisâtes',
    'agoniserai',
    'agoniseras',
    'agoniserez',
    'agonisions',
    'agonissais',
    'agonissait',
    'agonissant',
    'agonissent',
    'agonissiez',
    'agonissons',
    'agrafaient',
    'agrafasses',
    'agraferais',
    'agraferait',
    'agrafèrent',
    'agraferiez',
    'agraferons',
    'agraferont',
    'agrafeuses',
    'agrandîmes',
    'agrandirai',
    'agrandiras',
    'agrandirez',
    'agrandisse',
    'agrandîtes',
    'agrarienne',
    'agréassent',
    'agréassiez',
    'agréerions',
    'agrégatifs',
    'agrégation',
    'agrégative',
    'agrégeâmes',
    'agrégeasse',
    'agrégeâtes',
    'agrégerais',
    'agrégerait',
    'agrégèrent',
    'agrégeriez',
    'agrégerons',
    'agrégeront',
    'agrémentai',
    'agrémentas',
    'agrémentât',
    'agrémentée',
    'agrémenter',
    'agrémentes',
    'agrémentés',
    'agrémentez',
    'agressâmes',
    'agressasse',
    'agressâtes',
    'agresserai',
    'agresseras',
    'agresserez',
    'agresseurs',
    'agressions',
    'agressives',
    'agrichâmes',
    'agrichasse',
    'agrichâtes',
    'agricherai',
    'agricheras',
    'agricherez',
    'agrichions',
    'agriffâmes',
    'agriffâtes',
    'agrifferez',
    'agriffions',
    'agripaumes',
    'agrippâmes',
    'agrippasse',
    'agrippâtes',
    'agripperai',
    'agripperas',
    'agripperez',
    'agrippions',
    'agrochimie',
    'agrologies',
    'agronomies',
    'agrostides',
    'aguerrîmes',
    'aguerrirai',
    'aguerriras',
    'aguerrirez',
    'aguerrisse',
    'aguerrîtes',
    'aguichâmes',
    'aguichante',
    'aguichants',
    'aguichasse',
    'aguichâtes',
    'aguicherai',
    'aguicheras',
    'aguicherez',
    'aguicheurs',
    'aguicheuse',
    'aguichions',
    'ahanassent',
    'ahanassiez',
    'ahanerions',
    'aheurtâmes',
    'aheurtâtes',
    'aheurterez',
    'aheurtions',
    'ahuririons',
    'ahurissais',
    'ahurissait',
    'ahurissant',
    'ahurissent',
    'ahurissiez',
    'ahurissons',
    'aidassions',
    'aideraient',
    'aigre-doux',
    'aigrelette',
    'aigremoine',
    'aigrettées',
    'aigririons',
    'aigrissais',
    'aigrissait',
    'aigrissant',
    'aigrissent',
    'aigrissiez',
    'aigrissons',
    'aiguillage',
    'aiguillais',
    'aiguillait',
    'aiguillant',
    'aiguillats',
    'aiguillées',
    'aiguillent',
    'aiguillera',
    'aiguilleta',
    'aiguilleté',
    'aiguilleur',
    'aiguilliez',
    'aiguillons',
    'aiguillots',
    'aiguisages',
    'aiguisâmes',
    'aiguisasse',
    'aiguisâtes',
    'aiguiserai',
    'aiguiseras',
    'aiguiserez',
    'aiguiseurs',
    'aiguiseuse',
    'aiguisions',
    'aiguisoirs',
    'aillassent',
    'aillassiez',
    'aillerions',
    'aimantâmes',
    'aimantasse',
    'aimantâtes',
    'aimanterai',
    'aimanteras',
    'aimanterez',
    'aimantions',
    'aimassions',
    'aimeraient',
    'airassions',
    'aireraient',
    'ajointâmes',
    'ajointasse',
    'ajointâtes',
    'ajointerai',
    'ajointeras',
    'ajointerez',
    'ajointions',
    'ajouraient',
    'ajourasses',
    'ajourerais',
    'ajourerait',
    'ajourèrent',
    'ajoureriez',
    'ajourerons',
    'ajoureront',
    'ajournâmes',
    'ajournasse',
    'ajournâtes',
    'ajournerai',
    'ajourneras',
    'ajournerez',
    'ajournions',
    'ajoutaient',
    'ajoutasses',
    'ajouterais',
    'ajouterait',
    'ajoutèrent',
    'ajouteriez',
    'ajouterons',
    'ajouteront',
    'ajustables',
    'ajustaient',
    'ajustasses',
    'ajustement',
    'ajusterais',
    'ajusterait',
    'ajustèrent',
    'ajusteriez',
    'ajusterons',
    'ajusteront',
    'ajusteuses',
    'akkadienne',
    'alabandine',
    'alambiquai',
    'alambiquas',
    'alambiquât',
    'alambiquée',
    'alambiquer',
    'alambiques',
    'alambiqués',
    'alambiquez',
    'alanguîmes',
    'alanguirai',
    'alanguiras',
    'alanguirez',
    'alanguisse',
    'alanguîtes',
    'alarmaient',
    'alarmantes',
    'alarmasses',
    'alarmerais',
    'alarmerait',
    'alarmèrent',
    'alarmeriez',
    'alarmerons',
    'alarmeront',
    'alarmistes',
    'albanaises',
    'albergiers',
    'albigeoise',
    'albinismes',
    'albuginées',
    'albuminées',
    'albumineux',
    'alcalinisa',
    'alcalinise',
    'alcalinisé',
    'alcalinité',
    'alcalisais',
    'alcalisait',
    'alcalisant',
    'alcalisées',
    'alcalisent',
    'alcalisera',
    'alcalisiez',
    'alcalisons',
    'alcaloïdes',
    'alchémille',
    'alchimique',
    'alchimiste',
    'alcoolémie',
    'alcoolique',
    'alcoolisai',
    'alcoolisas',
    'alcoolisât',
    'alcoolisée',
    'alcooliser',
    'alcoolises',
    'alcoolisés',
    'alcoolisez',
    'alcoolisme',
    'alcoomètre',
    'alcootests',
    'aléatoires',
    'alémanique',
    'alentirais',
    'alentirait',
    'alentirent',
    'alentiriez',
    'alentirons',
    'alentiront',
    'alentisses',
    'alentissez',
    'alertaient',
    'alertasses',
    'alertement',
    'alerterais',
    'alerterait',
    'alertèrent',
    'alerteriez',
    'alerterons',
    'alerteront',
    'alésassent',
    'alésassiez',
    'aléserions',
    'alevinages',
    'alevinâmes',
    'alevinasse',
    'alevinâtes',
    'alevinerai',
    'alevineras',
    'alevinerez',
    'alevinière',
    'aleviniers',
    'alevinions',
    'alexandrin',
    'alfatières',
    'algazelles',
    'algébrique',
    'algérienne',
    'algonquins',
    'algorithme',
    'aliénables',
    'aliénaient',
    'aliénantes',
    'aliénasses',
    'aliénateur',
    'aliénation',
    'aliénerais',
    'aliénerait',
    'aliénèrent',
    'aliéneriez',
    'aliénerons',
    'aliéneront',
    'aliénistes',
    'alignaient',
    'alignasses',
    'alignement',
    'alignerais',
    'alignerait',
    'alignèrent',
    'aligneriez',
    'alignerons',
    'aligneront',
    'alimentais',
    'alimentait',
    'alimentant',
    'alimentent',
    'alimentera',
    'alimentiez',
    'alimentons',
    'aliquantes',
    'alitassent',
    'alitassiez',
    'alitements',
    'aliterions',
    'alizarines',
    'alkékenges',
    'allaitâmes',
    'allaitasse',
    'allaitâtes',
    'allaiterai',
    'allaiteras',
    'allaiterez',
    'allaitions',
    'allantoïde',
    'allassions',
    'alléchâmes',
    'alléchante',
    'alléchants',
    'alléchasse',
    'alléchâtes',
    'allécherai',
    'allécheras',
    'allécherez',
    'alléchions',
    'allégation',
    'allégeâmes',
    'allégeance',
    'allégeasse',
    'allégeâtes',
    'allégement',
    'allègement',
    'allégerais',
    'allégerait',
    'allégèrent',
    'allégeriez',
    'allégerons',
    'allégeront',
    'allégirais',
    'allégirait',
    'allégirent',
    'allégiriez',
    'allégirons',
    'allégiront',
    'allégisses',
    'allégissez',
    'allégories',
    'allégorisa',
    'allégorise',
    'allégorisé',
    'allégresse',
    'allegretto',
    'alléguâmes',
    'alléguasse',
    'alléguâtes',
    'alléguerai',
    'allégueras',
    'alléguerez',
    'alléguions',
    'allemandes',
    'allergènes',
    'allergique',
    'alliassent',
    'alliassiez',
    'allierions',
    'alligators',
    'allocation',
    'allocution',
    'allodiales',
    'allogamies',
    'allongeais',
    'allongeait',
    'allongeant',
    'allongeons',
    'allongerai',
    'allongeras',
    'allongerez',
    'allongions',
    'allopathes',
    'allopathie',
    'allotropie',
    'allouaient',
    'allouasses',
    'allouerais',
    'allouerait',
    'allouèrent',
    'alloueriez',
    'allouerons',
    'alloueront',
    'allumaient',
    'allumasses',
    'allume-feu',
    'allume-gaz',
    'allumerais',
    'allumerait',
    'allumèrent',
    'allumeriez',
    'allumerons',
    'allumeront',
    'allumettes',
    'allumeuses',
    'alluviales',
    'alluvionna',
    'alluvionne',
    'alluvionné',
    'allyliques',
    'almasilium',
    'alourdîmes',
    'alourdirai',
    'alourdiras',
    'alourdirez',
    'alourdisse',
    'alourdîtes',
    'alpaguâmes',
    'alpaguasse',
    'alpaguâtes',
    'alpaguerai',
    'alpagueras',
    'alpaguerez',
    'alpaguions',
    'alpinismes',
    'alpinistes',
    'alsacienne',
    'altérables',
    'altéragène',
    'altéraient',
    'altérantes',
    'altérasses',
    'altération',
    'altérerais',
    'altérerait',
    'altérèrent',
    'altéreriez',
    'altérerons',
    'altéreront',
    'alternâmes',
    'alternance',
    'alternante',
    'alternants',
    'alternasse',
    'alternâtes',
    'alternatif',
    'alternerai',
    'alterneras',
    'alternerez',
    'alternions',
    'altimètres',
    'altimétrie',
    'altruismes',
    'altruistes',
    'aluminages',
    'aluminâmes',
    'aluminasse',
    'aluminates',
    'aluminâtes',
    'aluminerai',
    'alumineras',
    'aluminerez',
    'alumineuse',
    'aluminiage',
    'aluminions',
    'aluminiums',
    'alunassent',
    'alunassiez',
    'alunerions',
    'alunirions',
    'alunissage',
    'alunissais',
    'alunissait',
    'alunissant',
    'alunissent',
    'alunissiez',
    'alunissons',
    'alvéolaire',
    'alvéolites',
    'amabilités',
    'amadouâmes',
    'amadouasse',
    'amadouâtes',
    'amadouerai',
    'amadoueras',
    'amadouerez',
    'amadouions',
    'amadouvier',
    'amaigrîmes',
    'amaigrirai',
    'amaigriras',
    'amaigrirez',
    'amaigrisse',
    'amaigrîtes',
    'amalgamais',
    'amalgamait',
    'amalgamant',
    'amalgamées',
    'amalgament',
    'amalgamera',
    'amalgamiez',
    'amalgamons',
    'amareyeurs',
    'amareyeuse',
    'amarinâmes',
    'amarinasse',
    'amarinâtes',
    'amarinerai',
    'amarineras',
    'amarinerez',
    'amarinions',
    'amarraient',
    'amarrasses',
    'amarrerais',
    'amarrerait',
    'amarrèrent',
    'amarreriez',
    'amarrerons',
    'amarreront',
    'amassaient',
    'amassasses',
    'amasserais',
    'amasserait',
    'amassèrent',
    'amasseriez',
    'amasserons',
    'amasseront',
    'amatirions',
    'amatissais',
    'amatissait',
    'amatissant',
    'amatissent',
    'amatissiez',
    'amatissons',
    'ambassades',
    'ambidextre',
    'ambiguïtés',
    'ambigument',
    'ambisexuée',
    'ambisexués',
    'ambitieuse',
    'ambitionna',
    'ambitionne',
    'ambitionné',
    'ambivalent',
    'amblassent',
    'amblassiez',
    'amblerions',
    'amblyopies',
    'amblystome',
    'ambrassent',
    'ambrassiez',
    'ambrerions',
    'ambroisies',
    'ambrosiens',
    'ambulacres',
    'ambulances',
    'ambulantes',
    'améliorais',
    'améliorait',
    'améliorant',
    'améliorées',
    'améliorent',
    'améliorera',
    'amélioriez',
    'améliorons',
    'aménageais',
    'aménageait',
    'aménageant',
    'aménageons',
    'aménagerai',
    'aménageras',
    'aménagerez',
    'aménageurs',
    'aménageuse',
    'aménagions',
    'amenassent',
    'amenassiez',
    'amendables',
    'amendaient',
    'amendasses',
    'amendement',
    'amenderais',
    'amenderait',
    'amendèrent',
    'amenderiez',
    'amenderons',
    'amenderont',
    'amènerions',
    'aménorrhée',
    'amentifère',
    'amenuisais',
    'amenuisait',
    'amenuisant',
    'amenuisées',
    'amenuisent',
    'amenuisera',
    'amenuisiez',
    'amenuisons',
    'américaine',
    'américains',
    'américiums',
    'amérindien',
    'amerloques',
    'amerrirais',
    'amerrirait',
    'amerrirent',
    'amerririez',
    'amerrirons',
    'amerriront',
    'amerrisses',
    'amerrissez',
    'améthystes',
    'amétropies',
    'ameublîmes',
    'ameublirai',
    'ameubliras',
    'ameublirez',
    'ameublisse',
    'ameublîtes',
    'ameutaient',
    'ameutasses',
    'ameuterais',
    'ameuterait',
    'ameutèrent',
    'ameuteriez',
    'ameuterons',
    'ameuteront',
    'amhariques',
    'amibiennes',
    'amidonnage',
    'amidonnais',
    'amidonnait',
    'amidonnant',
    'amidonnées',
    'amidonnent',
    'amidonnera',
    'amidonnier',
    'amidonniez',
    'amidonnons',
    'amincirais',
    'amincirait',
    'amincirent',
    'aminciriez',
    'amincirons',
    'aminciront',
    'amincisses',
    'amincissez',
    'ammoniacal',
    'ammoniaque',
    'ammophiles',
    'amnésiques',
    'amniotique',
    'amnistiais',
    'amnistiait',
    'amnistiant',
    'amnistiées',
    'amnistient',
    'amnistiera',
    'amnistiiez',
    'amnistions',
    'amochaient',
    'amochasses',
    'amocherais',
    'amocherait',
    'amochèrent',
    'amocheriez',
    'amocherons',
    'amocheront',
    'amodiaient',
    'amodiasses',
    'amodiation',
    'amodierais',
    'amodierait',
    'amodièrent',
    'amodieriez',
    'amodierons',
    'amodieront',
    'amoindries',
    'amoindrira',
    'amollirais',
    'amollirait',
    'amollirent',
    'amolliriez',
    'amollirons',
    'amolliront',
    'amollisses',
    'amollissez',
    'amoncelais',
    'amoncelait',
    'amoncelant',
    'amoncelées',
    'amonceliez',
    'amoncelles',
    'amoncelons',
    'amoralisme',
    'amorçaient',
    'amorçasses',
    'amorcerais',
    'amorcerait',
    'amorcèrent',
    'amorceriez',
    'amorcerons',
    'amorceront',
    'amordançai',
    'amordanças',
    'amordançât',
    'amordancée',
    'amordancer',
    'amordances',
    'amordancés',
    'amordancez',
    'amortirais',
    'amortirait',
    'amortirent',
    'amortiriez',
    'amortirons',
    'amortiront',
    'amortisses',
    'amotissais',
    'amotissait',
    'amotissant',
    'amotissiez',
    'amotissons',
    'amourachée',
    'amouracher',
    'amourachés',
    'amourachez',
    'amourettes',
    'amoureuses',
    'amphiboles',
    'amphictyon',
    'amphigouri',
    'amphimixie',
    'amphisbène',
    'amphitryon',
    'ampholytes',
    'amphotères',
    'amplectifs',
    'amplective',
    'ampliatifs',
    'ampliation',
    'ampliative',
    'amplifiais',
    'amplifiait',
    'amplifiant',
    'amplifiées',
    'amplifient',
    'amplifiera',
    'amplifiiez',
    'amplifions',
    'amplitudes',
    'amputaient',
    'amputasses',
    'amputation',
    'amputerais',
    'amputerait',
    'amputèrent',
    'amputeriez',
    'amputerons',
    'amputeront',
    'amuïssions',
    'amurassent',
    'amurassiez',
    'amurerions',
    'amusassent',
    'amusassiez',
    'amusements',
    'amuserions',
    'amygdalite',
    'anabolisme',
    'anabolites',
    'anacardier',
    'anachorète',
    'anacoluthe',
    'anacrouses',
    'anaérobies',
    'anaglyphes',
    'anagnostes',
    'anagogique',
    'anagrammes',
    'analgésiai',
    'analgésias',
    'analgésiât',
    'analgésiée',
    'analgésier',
    'analgésies',
    'analgésiés',
    'analgésiez',
    'analogique',
    'analysable',
    'analysâmes',
    'analysante',
    'analysants',
    'analysasse',
    'analysâtes',
    'analyserai',
    'analyseras',
    'analyserez',
    'analyseurs',
    'analysions',
    'analytique',
    'anaphorèse',
    'anaplastie',
    'anarchique',
    'anarchisme',
    'anarchiste',
    'anarthries',
    'anasarques',
    'anastigmat',
    'anastomose',
    'anastomosé',
    'anastrophe',
    'anatocisme',
    'anatomique',
    'anatomisai',
    'anatomisas',
    'anatomisât',
    'anatomisée',
    'anatomiser',
    'anatomises',
    'anatomisés',
    'anatomisez',
    'anatomiste',
    'ancestrale',
    'ancestraux',
    'ancienneté',
    'ancillaire',
    'ancrassent',
    'ancrassiez',
    'ancrerions',
    'andalouses',
    'andantinos',
    'andouiller',
    'andouilles',
    'andrinople',
    'androgènes',
    'androgénie',
    'androgynes',
    'andrologie',
    'andrologue',
    'andropause',
    'anéantîmes',
    'anéantirai',
    'anéantiras',
    'anéantirez',
    'anéantisse',
    'anéantîtes',
    'anecdotier',
    'anémiaient',
    'anémiasses',
    'anémierais',
    'anémierait',
    'anémièrent',
    'anémieriez',
    'anémierons',
    'anémieront',
    'anémomètre',
    'anémophile',
    'anesthésia',
    'anesthésie',
    'anesthésié',
    'anévrismal',
    'anévrismes',
    'anévrysmes',
    'angéliques',
    'angélismes',
    'angineuses',
    'angiologie',
    'anglaisais',
    'anglaisait',
    'anglaisant',
    'anglaisées',
    'anglaisent',
    'anglaisera',
    'anglaisiez',
    'anglaisons',
    'angledozer',
    'anglicanes',
    'anglicisai',
    'anglicisas',
    'anglicisât',
    'anglicisée',
    'angliciser',
    'anglicises',
    'anglicisés',
    'anglicisez',
    'anglicisme',
    'angliciste',
    'anglomanes',
    'anglomanie',
    'anglophile',
    'anglophobe',
    'anglophone',
    'angoissais',
    'angoissait',
    'angoissant',
    'angoissées',
    'angoissent',
    'angoissera',
    'angoissiez',
    'angoissons',
    'angstroems',
    'anguiforme',
    'anguillère',
    'anguillule',
    'angulaires',
    'anguleuses',
    'angusturas',
    'angustures',
    'anhélaient',
    'anhélasses',
    'anhélation',
    'anhélerais',
    'anhélerait',
    'anhélèrent',
    'anhéleriez',
    'anhélerons',
    'anhéleront',
    'anhidroses',
    'anhydrides',
    'anhydrites',
    'anicroches',
    'animalcule',
    'animalerie',
    'animalière',
    'animaliers',
    'animalisai',
    'animalisas',
    'animalisât',
    'animalisée',
    'animaliser',
    'animalises',
    'animalisés',
    'animalisez',
    'animalités',
    'animassent',
    'animassiez',
    'animateurs',
    'animations',
    'animatrice',
    'animerions',
    'animosités',
    'anisassent',
    'anisassiez',
    'aniserions',
    'anisotrope',
    'ankylosais',
    'ankylosait',
    'ankylosant',
    'ankylosées',
    'ankylosent',
    'ankylosera',
    'ankylosiez',
    'ankylosons',
    'annalistes',
    'annelaient',
    'annelasses',
    'annelèrent',
    'annellerai',
    'annelleras',
    'annellerez',
    'annexaient',
    'annexasses',
    'annexerais',
    'annexerait',
    'annexèrent',
    'annexeriez',
    'annexerons',
    'annexeront',
    'annihilais',
    'annihilait',
    'annihilant',
    'annihilées',
    'annihilent',
    'annihilera',
    'annihiliez',
    'annihilons',
    'annonçâmes',
    'annonçasse',
    'annonçâtes',
    'annoncerai',
    'annonceras',
    'annoncerez',
    'annonceurs',
    'annonceuse',
    'annoncière',
    'annonciers',
    'annoncions',
    'annotaient',
    'annotasses',
    'annotateur',
    'annotation',
    'annoterais',
    'annoterait',
    'annotèrent',
    'annoteriez',
    'annoterons',
    'annoteront',
    'annualités',
    'annulables',
    'annulaient',
    'annulaires',
    'annulasses',
    'annulation',
    'annulerais',
    'annulerait',
    'annulèrent',
    'annuleriez',
    'annulerons',
    'annuleront',
    'anoblirais',
    'anoblirait',
    'anoblirent',
    'anobliriez',
    'anoblirons',
    'anobliront',
    'anoblisses',
    'anoblissez',
    'anodisâmes',
    'anodisasse',
    'anodisâtes',
    'anodiserai',
    'anodiseras',
    'anodiserez',
    'anodisions',
    'ânonnaient',
    'ânonnasses',
    'ânonnement',
    'ânonnerais',
    'ânonnerait',
    'ânonnèrent',
    'ânonneriez',
    'ânonnerons',
    'ânonneront',
    'anordirais',
    'anordirait',
    'anordirent',
    'anordiriez',
    'anordirons',
    'anordiront',
    'anordisses',
    'anordissez',
    'anorexique',
    'anormalité',
    'antalgique',
    'antécédent',
    'Antéchrist',
    'antenaises',
    'antéposais',
    'antéposait',
    'antéposant',
    'antéposées',
    'antéposent',
    'antéposera',
    'antéposiez',
    'antéposons',
    'antérieure',
    'antérieurs',
    'anthologie',
    'anthonomes',
    'anthracène',
    'anthracite',
    'anthracose',
    'antiaérien',
    'antiamaril',
    'antiatomes',
    'antichrèse',
    'anticipais',
    'anticipait',
    'anticipant',
    'anticipées',
    'anticipent',
    'anticipera',
    'anticipiez',
    'anticipons',
    'anticlinal',
    'antidatais',
    'antidatait',
    'antidatant',
    'antidatées',
    'antidatent',
    'antidatera',
    'antidatiez',
    'antidatons',
    'antidopage',
    'antidoping',
    'antiglisse',
    'antihausse',
    'antillaise',
    'antimoines',
    'antinazies',
    'antinomies',
    'antipathie',
    'antiphrase',
    'antipoison',
    'antiquaire',
    'antiquités',
    'antisémite',
    'antisepsie',
    'antisocial',
    'antithèses',
    'antitoxine',
    'antonymies',
    'anxiogènes',
    'aoûtassent',
    'aoûtassiez',
    'aoûtements',
    'aoûterions',
    'aoûtiennes',
    'apaisaient',
    'apaisantes',
    'apaisasses',
    'apaisement',
    'apaiserais',
    'apaiserait',
    'apaisèrent',
    'apaiseriez',
    'apaiserons',
    'apaiseront',
    'apanageais',
    'apanageait',
    'apanageant',
    'apanageons',
    'apanagerai',
    'apanageras',
    'apanagerez',
    'apanagions',
    'apartheids',
    'apathiques',
    'apercevais',
    'apercevait',
    'apercevant',
    'aperceviez',
    'apercevoir',
    'apercevons',
    'apercevrai',
    'apercevras',
    'apercevrez',
    'aperçoives',
    'aperçurent',
    'aperçusses',
    'apériteurs',
    'apéritives',
    'apesanteur',
    'à-peu-près',
    'apeuraient',
    'apeurasses',
    'apeurerais',
    'apeurerait',
    'apeurèrent',
    'apeureriez',
    'apeurerons',
    'apeureront',
    'aphasiques',
    'aphorismes',
    'apiculteur',
    'apiculture',
    'apiéceuses',
    'apiquaient',
    'apiquasses',
    'apiquerais',
    'apiquerait',
    'apiquèrent',
    'apiqueriez',
    'apiquerons',
    'apiqueront',
    'apitoierai',
    'apitoieras',
    'apitoierez',
    'apitoyâmes',
    'apitoyasse',
    'apitoyâtes',
    'apitoyions',
    'aplanirais',
    'aplanirait',
    'aplanirent',
    'aplaniriez',
    'aplanirons',
    'aplaniront',
    'aplanisses',
    'aplanissez',
    'aplatirais',
    'aplatirait',
    'aplatirent',
    'aplatiriez',
    'aplatirons',
    'aplatiront',
    'aplatisses',
    'aplatissez',
    'apocalypse',
    'apocryphes',
    'apolitique',
    'apolitisme',
    'apollinien',
    'apologiste',
    'aponévrose',
    'apophonies',
    'apophtegme',
    'apoplexies',
    'aporétique',
    'aposiopèse',
    'apostaient',
    'apostasiai',
    'apostasias',
    'apostasiât',
    'apostasier',
    'apostasies',
    'apostasiez',
    'apostasses',
    'aposterais',
    'aposterait',
    'apostèrent',
    'aposteriez',
    'aposterons',
    'aposteront',
    'apostillai',
    'apostillas',
    'apostillât',
    'apostillée',
    'apostiller',
    'apostilles',
    'apostillés',
    'apostillez',
    'apostolats',
    'apostropha',
    'apostrophe',
    'apostrophé',
    'apothéoses',
    'appairâmes',
    'appairasse',
    'appairâtes',
    'appairerai',
    'appaireras',
    'appairerez',
    'appairions',
    'apparaisse',
    'apparaîtra',
    'apparaître',
    'appareilla',
    'appareille',
    'appareillé',
    'apparences',
    'apparentée',
    'apparenter',
    'apparentes',
    'apparentés',
    'apparentez',
    'appariâmes',
    'appariasse',
    'appariâtes',
    'apparierai',
    'apparieras',
    'apparierez',
    'appariions',
    'appariteur',
    'apparition',
    'appartenez',
    'appartenir',
    'appartiens',
    'appartient',
    'apparurent',
    'apparusses',
    'appâtaient',
    'appâtasses',
    'appâterais',
    'appâterait',
    'appâtèrent',
    'appâteriez',
    'appâterons',
    'appâteront',
    'appauvries',
    'appauvrira',
    'appelaient',
    'appelantes',
    'appelasses',
    'appelèrent',
    'appellatif',
    'appellerai',
    'appelleras',
    'appellerez',
    'appendices',
    'appesantie',
    'appesantir',
    'appesantis',
    'appesantit',
    'appesantît',
    'appétaient',
    'appétasses',
    'appétences',
    'appéterais',
    'appéterait',
    'appétèrent',
    'appéteriez',
    'appéterons',
    'appéteront',
    'applaudies',
    'applaudira',
    'applicable',
    'applicages',
    'applicatif',
    'appliquais',
    'appliquait',
    'appliquant',
    'appliquées',
    'appliquent',
    'appliquera',
    'appliquiez',
    'appliquons',
    'appointage',
    'appointais',
    'appointait',
    'appointant',
    'appointées',
    'appointent',
    'appointera',
    'appointies',
    'appointiez',
    'appointira',
    'appointons',
    'appontages',
    'appontâmes',
    'appontasse',
    'appontâtes',
    'apponterai',
    'apponteras',
    'apponterez',
    'apponteurs',
    'appontions',
    'apportâmes',
    'apportasse',
    'apportâtes',
    'apporterai',
    'apporteras',
    'apporterez',
    'apporteurs',
    'apportions',
    'apposaient',
    'apposasses',
    'apposerais',
    'apposerait',
    'apposèrent',
    'apposeriez',
    'apposerons',
    'apposeront',
    'apposition',
    'appréciais',
    'appréciait',
    'appréciant',
    'appréciées',
    'apprécient',
    'appréciera',
    'appréciiez',
    'apprécions',
    'appréhenda',
    'appréhende',
    'appréhendé',
    'apprenante',
    'apprenants',
    'apprendrai',
    'apprendras',
    'apprendrez',
    'apprenions',
    'apprennent',
    'apprenties',
    'apprêtages',
    'apprêtâmes',
    'apprêtasse',
    'apprêtâtes',
    'apprêterai',
    'apprêteras',
    'apprêterez',
    'apprêteurs',
    'apprêteuse',
    'apprêtions',
    'apprissent',
    'apprissiez',
    'apprivoisa',
    'apprivoise',
    'apprivoisé',
    'approbatif',
    'approchais',
    'approchait',
    'approchant',
    'approchées',
    'approchent',
    'approchera',
    'approchiez',
    'approchons',
    'approfondi',
    'appropriai',
    'approprias',
    'appropriât',
    'appropriée',
    'approprier',
    'appropries',
    'appropriés',
    'appropriez',
    'approuvais',
    'approuvait',
    'approuvant',
    'approuvées',
    'approuvent',
    'approuvera',
    'approuviez',
    'approuvons',
    'appui-bras',
    'appuierais',
    'appuierait',
    'appuieriez',
    'appuierons',
    'appuieront',
    'appui-main',
    'appui-tête',
    'appuyaient',
    'appuyasses',
    'appuyèrent',
    'après-midi',
    'après-skis',
    'apriorique',
    'apriorisme',
    'aprioriste',
    'apurassent',
    'apurassiez',
    'apurements',
    'apurerions',
    'aquaplanes',
    'aquarelles',
    'aquatintes',
    'aquatiques',
    'arabesques',
    'arabisâmes',
    'arabisante',
    'arabisants',
    'arabisasse',
    'arabisâtes',
    'arabiserai',
    'arabiseras',
    'arabiserez',
    'arabisions',
    'arabophone',
    'arachnéens',
    'arachnoïde',
    'aragonites',
    'araméennes',
    'arasassent',
    'arasassiez',
    'arasements',
    'araserions',
    'araucarias',
    'arbitrages',
    'arbitraire',
    'arbitrales',
    'arbitrâmes',
    'arbitrasse',
    'arbitrâtes',
    'arbitrerai',
    'arbitreras',
    'arbitrerez',
    'arbitrions',
    'arboraient',
    'arborasses',
    'arborerais',
    'arborerait',
    'arborèrent',
    'arboreriez',
    'arborerons',
    'arboreront',
    'arboretums',
    'arboricole',
    'arborisais',
    'arborisait',
    'arborisant',
    'arborisent',
    'arborisera',
    'arborisiez',
    'arborisons',
    'arbousiers',
    'arbrisseau',
    'arbustives',
    'arc-boutai',
    'arc-boutas',
    'arc-boutât',
    'arc-boutée',
    'arc-bouter',
    'arc-boutes',
    'arc-boutés',
    'arc-boutez',
    'archaïques',
    'archaïsais',
    'archaïsait',
    'archaïsant',
    'archaïsent',
    'archaïsera',
    'archaïsiez',
    'archaïsmes',
    'archaïsons',
    'archéennes',
    'archégones',
    'archétypes',
    'archevêché',
    'archevêque',
    'archiatres',
    'archicubes',
    'architecte',
    'architrave',
    'archivages',
    'archivâmes',
    'archivasse',
    'archivâtes',
    'archiverai',
    'archiveras',
    'archiverez',
    'archivions',
    'archiviste',
    'archivolte',
    'arçonnâmes',
    'arçonnasse',
    'arçonnâtes',
    'arçonnerai',
    'arçonneras',
    'arçonnerez',
    'arçonnions',
    'ardoisâmes',
    'ardoisasse',
    'ardoisâtes',
    'ardoiserai',
    'ardoiseras',
    'ardoiserez',
    'ardoisière',
    'ardoisiers',
    'ardoisions',
    'aréflexies',
    'areligieux',
    'arénicoles',
    'aréolaires',
    'aréomètres',
    'aréométrie',
    'aréostyles',
    'argentâmes',
    'argentasse',
    'argentâtes',
    'argenterai',
    'argenteras',
    'argenterez',
    'argenterie',
    'argenteurs',
    'argentiers',
    'argentines',
    'argentions',
    'argentique',
    'argentites',
    'argentures',
    'argilacées',
    'argileuses',
    'argonautes',
    'argotiques',
    'argotisais',
    'argotisait',
    'argotisant',
    'argotisent',
    'argotisera',
    'argotisiez',
    'argotismes',
    'argotisons',
    'argotistes',
    'argousiers',
    'arguassent',
    'arguassiez',
    'arguerions',
    'argumentai',
    'argumentas',
    'argumentât',
    'argumenter',
    'argumentes',
    'argumentez',
    'argyrismes',
    'argyronète',
    'arianismes',
    'arisassent',
    'arisassiez',
    'ariserions',
    'armassions',
    'arménienne',
    'armeraient',
    'armillaire',
    'arminienne',
    'armistices',
    'armoriales',
    'armoriâmes',
    'armoriasse',
    'armoriâtes',
    'armorierai',
    'armorieras',
    'armorierez',
    'armoriions',
    'armureries',
    'arnaquâmes',
    'arnaquasse',
    'arnaquâtes',
    'arnaquerai',
    'arnaqueras',
    'arnaquerez',
    'arnaquions',
    'aromatique',
    'aromatisai',
    'aromatisas',
    'aromatisât',
    'aromatisée',
    'aromatiser',
    'aromatises',
    'aromatisés',
    'aromatisez',
    'arpégeâmes',
    'arpégeasse',
    'arpégeâtes',
    'arpégerais',
    'arpégerait',
    'arpégèrent',
    'arpégeriez',
    'arpégerons',
    'arpégeront',
    'arpentages',
    'arpentâmes',
    'arpentasse',
    'arpentâtes',
    'arpenterai',
    'arpenteras',
    'arpenterez',
    'arpenteurs',
    'arpenteuse',
    'arpentions',
    'arquassent',
    'arquassiez',
    'arquebuses',
    'arquerions',
    'arrachages',
    'arrachâmes',
    'arrachasse',
    'arrachâtes',
    'arracherai',
    'arracheras',
    'arracherez',
    'arracheurs',
    'arracheuse',
    'arrachions',
    'arraisonna',
    'arraisonne',
    'arraisonné',
    'arrangeais',
    'arrangeait',
    'arrangeant',
    'arrangeons',
    'arrangerai',
    'arrangeras',
    'arrangerez',
    'arrangeurs',
    'arrangions',
    'arrentâmes',
    'arrentasse',
    'arrentâtes',
    'arrenterai',
    'arrenteras',
    'arrenterez',
    'arrentions',
    'arrérageai',
    'arrérageas',
    'arrérageât',
    'arréragées',
    'arréragent',
    'arréragera',
    'arréragiez',
    'arrêtaient',
    'arrêtasses',
    'arrêterais',
    'arrêterait',
    'arrêtèrent',
    'arrêteriez',
    'arrêterons',
    'arrêteront',
    'arrêtistes',
    'arriérâmes',
    'arriérasse',
    'arriérâtes',
    'arriérerai',
    'arriéreras',
    'arriérerez',
    'arriérions',
    'arrimaient',
    'arrimasses',
    'arrimerais',
    'arrimerait',
    'arrimèrent',
    'arrimeriez',
    'arrimerons',
    'arrimeront',
    'arrisaient',
    'arrisasses',
    'arriserais',
    'arriserait',
    'arrisèrent',
    'arriseriez',
    'arriserons',
    'arriseront',
    'arrivaient',
    'arrivasses',
    'arriverais',
    'arriverait',
    'arrivèrent',
    'arriveriez',
    'arriverons',
    'arriveront',
    'arrivismes',
    'arrivistes',
    'arrogances',
    'arrogantes',
    'arrogeâmes',
    'arrogeâtes',
    'arrogèrent',
    'arrogeriez',
    'arrogerons',
    'arrondîmes',
    'arrondirai',
    'arrondiras',
    'arrondirez',
    'arrondisse',
    'arrondîtes',
    'arrosables',
    'arrosaient',
    'arrosasses',
    'arrosement',
    'arroserais',
    'arroserait',
    'arrosèrent',
    'arroseriez',
    'arroserons',
    'arroseront',
    'arroseuses',
    'arséniates',
    'arsenicale',
    'arsenicaux',
    'arséniures',
    'arsouillée',
    'arsouiller',
    'arsouilles',
    'arsouillés',
    'arsouillez',
    'artérielle',
    'artérioles',
    'artésienne',
    'arthralgie',
    'arthrodies',
    'artichauts',
    'articulais',
    'articulait',
    'articulant',
    'articulées',
    'articulent',
    'articulera',
    'articulets',
    'articuliez',
    'articulons',
    'artificiel',
    'artificier',
    'artillerie',
    'artilleurs',
    'artisanale',
    'artisanats',
    'artisanaux',
    'artistique',
    'artocarpes',
    'aryténoïde',
    'asbestoses',
    'ascendance',
    'ascendante',
    'ascendants',
    'ascenseurs',
    'ascensions',
    'ascétiques',
    'ascétismes',
    'ascitiques',
    'asclépiade',
    'aseptiques',
    'aseptisais',
    'aseptisait',
    'aseptisant',
    'aseptisées',
    'aseptisent',
    'aseptisera',
    'aseptisiez',
    'aseptisons',
    'asexualité',
    'asexuelles',
    'ashkénazes',
    'asiatiques',
    'asiniennes',
    'asparagine',
    'aspectâmes',
    'aspectasse',
    'aspectâtes',
    'aspecterai',
    'aspecteras',
    'aspecterez',
    'aspections',
    'aspergeais',
    'aspergeait',
    'aspergeant',
    'aspergeons',
    'aspergerai',
    'aspergeras',
    'aspergerez',
    'aspergille',
    'aspergions',
    'aspersions',
    'aspersoirs',
    'asphaltage',
    'asphaltais',
    'asphaltait',
    'asphaltant',
    'asphaltées',
    'asphaltent',
    'asphaltera',
    'asphaltiez',
    'asphaltons',
    'asphodèles',
    'asphyxiais',
    'asphyxiait',
    'asphyxiant',
    'asphyxiées',
    'asphyxient',
    'asphyxiera',
    'asphyxiiez',
    'asphyxions',
    'aspidistra',
    'aspiraient',
    'aspirantes',
    'aspirasses',
    'aspirateur',
    'aspiration',
    'aspirerais',
    'aspirerait',
    'aspirèrent',
    'aspireriez',
    'aspirerons',
    'aspireront',
    'assagirais',
    'assagirait',
    'assagirent',
    'assagiriez',
    'assagirons',
    'assagiront',
    'assagisses',
    'assagissez',
    'assaillais',
    'assaillait',
    'assaillant',
    'assaillent',
    'assaillies',
    'assailliez',
    'assaillira',
    'assaillons',
    'assainîmes',
    'assainirai',
    'assainiras',
    'assainirez',
    'assainisse',
    'assainîtes',
    'assaisonna',
    'assaisonne',
    'assaisonné',
    'assarmenta',
    'assarmente',
    'assarmenté',
    'assassinai',
    'assassinas',
    'assassinat',
    'assassinât',
    'assassinée',
    'assassiner',
    'assassines',
    'assassinés',
    'assassinez',
    'asséchâmes',
    'asséchasse',
    'asséchâtes',
    'assécherai',
    'assécheras',
    'assécherez',
    'asséchions',
    'assemblage',
    'assemblais',
    'assemblait',
    'assemblant',
    'assemblées',
    'assemblent',
    'assemblera',
    'assembleur',
    'assembliez',
    'assemblons',
    'assenaient',
    'assénaient',
    'assenasses',
    'assénasses',
    'assénerais',
    'assènerais',
    'assénerait',
    'assènerait',
    'assenèrent',
    'assénèrent',
    'asséneriez',
    'assèneriez',
    'assénerons',
    'assènerons',
    'asséneront',
    'assèneront',
    'assermenta',
    'assermente',
    'assermenté',
    'assertions',
    'asservîmes',
    'asservirai',
    'asserviras',
    'asservirez',
    'asservisse',
    'asservîtes',
    'assesseurs',
    'asseyaient',
    'assibilais',
    'assibilait',
    'assibilant',
    'assibilées',
    'assibilent',
    'assibilera',
    'assibiliez',
    'assibilons',
    'assiduités',
    'assidûment',
    'assiégeais',
    'assiégeait',
    'assiégeant',
    'assiégeons',
    'assiégerai',
    'assiégeras',
    'assiégerez',
    'assiégions',
    'assiérions',
    'assiettées',
    'assignable',
    'assignâmes',
    'assignasse',
    'assignâtes',
    'assignerai',
    'assigneras',
    'assignerez',
    'assignions',
    'assimilais',
    'assimilait',
    'assimilant',
    'assimilées',
    'assimilent',
    'assimilera',
    'assimiliez',
    'assimilons',
    'assissions',
    'assistâmes',
    'assistanat',
    'assistance',
    'assistante',
    'assistants',
    'assistasse',
    'assistâtes',
    'assisterai',
    'assisteras',
    'assisterez',
    'assistions',
    'associâmes',
    'associasse',
    'associâtes',
    'associatif',
    'associerai',
    'associeras',
    'associerez',
    'associions',
    'assoiffées',
    'assoirions',
    'assolaient',
    'assolasses',
    'assolement',
    'assolerais',
    'assolerait',
    'assolèrent',
    'assoleriez',
    'assolerons',
    'assoleront',
    'assombries',
    'assombrira',
    'assommâmes',
    'assommante',
    'assommants',
    'assommasse',
    'assommâtes',
    'assommerai',
    'assommeras',
    'assommerez',
    'assommeurs',
    'assommions',
    'assommoirs',
    'assomption',
    'assonaient',
    'assonancée',
    'assonances',
    'assonancés',
    'assonantes',
    'assonasses',
    'assonerais',
    'assonerait',
    'assonèrent',
    'assoneriez',
    'assonerons',
    'assoneront',
    'assortîmes',
    'assortirai',
    'assortiras',
    'assortirez',
    'assortisse',
    'assortîtes',
    'assoupîmes',
    'assoupirai',
    'assoupiras',
    'assoupirez',
    'assoupisse',
    'assoupîtes',
    'assouplies',
    'assouplira',
    'assourdies',
    'assourdira',
    'assouvîmes',
    'assouvirai',
    'assouviras',
    'assouvirez',
    'assouvisse',
    'assouvîtes',
    'assoyaient',
    'assuétudes',
    'assujettie',
    'assujettir',
    'assujettis',
    'assujettit',
    'assujettît',
    'assumaient',
    'assumasses',
    'assumerais',
    'assumerait',
    'assumèrent',
    'assumeriez',
    'assumerons',
    'assumeront',
    'assurables',
    'assuraient',
    'assurances',
    'assurasses',
    'assurément',
    'assurerais',
    'assurerait',
    'assurèrent',
    'assureriez',
    'assurerons',
    'assureront',
    'assyrienne',
    'astatiques',
    'astérisque',
    'astéroïdes',
    'asthénique',
    'asticotais',
    'asticotait',
    'asticotant',
    'asticotées',
    'asticotent',
    'asticotera',
    'asticotiez',
    'asticotons',
    'astigmates',
    'astiquages',
    'astiquâmes',
    'astiquasse',
    'astiquâtes',
    'astiquerai',
    'astiqueras',
    'astiquerez',
    'astiquions',
    'astragales',
    'astreignes',
    'astreignez',
    'astreignis',
    'astreignit',
    'astreignît',
    'astreindra',
    'astreindre',
    'astreintes',
    'astringent',
    'astrolabes',
    'astrologie',
    'astrologue',
    'astronaute',
    'astronomes',
    'astronomie',
    'astucieuse',
    'asymétries',
    'asymptotes',
    'asynchrone',
    'asynergies',
    'asystolies',
    'ataraxique',
    'atemporels',
    'atermoient',
    'atermoiera',
    'atermoyais',
    'atermoyait',
    'atermoyant',
    'atermoyiez',
    'atermoyons',
    'athermanes',
    'athermique',
    'athlétique',
    'athlétisme',
    'atlantique',
    'atlantisme',
    'atmosphère',
    'atomicités',
    'atomisâmes',
    'atomisasse',
    'atomisâtes',
    'atomiserai',
    'atomiseras',
    'atomiserez',
    'atomiseurs',
    'atomisions',
    'atonalités',
    'atrocement',
    'atrophiais',
    'atrophiait',
    'atrophiant',
    'atrophiées',
    'atrophient',
    'atrophiera',
    'atrophiiez',
    'atrophions',
    'attablâmes',
    'attablasse',
    'attablâtes',
    'attablerai',
    'attableras',
    'attablerez',
    'attablions',
    'attachâmes',
    'attachante',
    'attachants',
    'attachasse',
    'attachâtes',
    'attacherai',
    'attacheras',
    'attacherez',
    'attachions',
    'attaquable',
    'attaquâmes',
    'attaquante',
    'attaquants',
    'attaquasse',
    'attaquâtes',
    'attaquerai',
    'attaqueras',
    'attaquerez',
    'attaquions',
    'attardâmes',
    'attardasse',
    'attardâtes',
    'attarderai',
    'attarderas',
    'attarderez',
    'attardions',
    'atteignais',
    'atteignait',
    'atteignant',
    'atteignent',
    'atteigniez',
    'atteignons',
    'atteindrai',
    'atteindras',
    'atteindrez',
    'attelaient',
    'attelasses',
    'attelèrent',
    'attellerai',
    'attelleras',
    'attellerez',
    'attenantes',
    'attendîmes',
    'attendions',
    'attendisse',
    'attendîtes',
    'attendrais',
    'attendrait',
    'attendries',
    'attendriez',
    'attendrira',
    'attendrons',
    'attendront',
    'attentâmes',
    'attentasse',
    'attentâtes',
    'attenterai',
    'attenteras',
    'attenterez',
    'attentions',
    'attentisme',
    'attentiste',
    'attentives',
    'atténuâmes',
    'atténuante',
    'atténuants',
    'atténuasse',
    'atténuâtes',
    'atténuerai',
    'atténueras',
    'atténuerez',
    'atténuions',
    'atterrages',
    'atterrâmes',
    'atterrante',
    'atterrants',
    'atterrasse',
    'atterrâtes',
    'atterrerai',
    'atterreras',
    'atterrerez',
    'atterrîmes',
    'atterrions',
    'atterrirai',
    'atterriras',
    'atterrirez',
    'atterrisse',
    'atterrîtes',
    'attestâmes',
    'attestasse',
    'attestâtes',
    'attesterai',
    'attesteras',
    'attesterez',
    'attestions',
    'atticismes',
    'attiédîmes',
    'attiédirai',
    'attiédiras',
    'attiédirez',
    'attiédisse',
    'attiédîtes',
    'attifaient',
    'attifasses',
    'attifement',
    'attiferais',
    'attiferait',
    'attifèrent',
    'attiferiez',
    'attiferons',
    'attiferont',
    'attigeâmes',
    'attigeasse',
    'attigeâtes',
    'attigerais',
    'attigerait',
    'attigèrent',
    'attigeriez',
    'attigerons',
    'attigeront',
    'attirables',
    'attiraient',
    'attirances',
    'attirantes',
    'attirasses',
    'attirerais',
    'attirerait',
    'attirèrent',
    'attireriez',
    'attirerons',
    'attireront',
    'attisaient',
    'attisasses',
    'attisement',
    'attiserais',
    'attiserait',
    'attisèrent',
    'attiseriez',
    'attiserons',
    'attiseront',
    'attitrâmes',
    'attitrasse',
    'attitrâtes',
    'attitrerai',
    'attitreras',
    'attitrerez',
    'attitrions',
    'attractifs',
    'attraction',
    'attractive',
    'attrapades',
    'attrapages',
    'attrapâmes',
    'attrapasse',
    'attrapâtes',
    'attraperai',
    'attraperas',
    'attraperez',
    'attrapions',
    'attrayante',
    'attrayants',
    'attribuais',
    'attribuait',
    'attribuant',
    'attribuées',
    'attribuent',
    'attribuera',
    'attribuiez',
    'attribuons',
    'attributif',
    'attriquais',
    'attriquait',
    'attriquant',
    'attriquées',
    'attriquent',
    'attriquera',
    'attriquiez',
    'attriquons',
    'attristais',
    'attristait',
    'attristant',
    'attristées',
    'attristent',
    'attristera',
    'attristiez',
    'attristons',
    'attritions',
    'attroupais',
    'attroupait',
    'attroupant',
    'attroupées',
    'attroupent',
    'attroupera',
    'attroupiez',
    'attroupons',
    'aubergines',
    'aubergiste',
    'aucunement',
    'audacieuse',
    'au-dessous',
    'audibilité',
    'audiomètre',
    'audiophone',
    'auditionna',
    'auditionne',
    'auditionné',
    'auditoires',
    'auditorats',
    'auditorium',
    'auditrices',
    'augmentais',
    'augmentait',
    'augmentant',
    'augmentées',
    'augmentent',
    'augmentera',
    'augmentiez',
    'augmentons',
    'auguraient',
    'augurasses',
    'augurerais',
    'augurerait',
    'augurèrent',
    'augureriez',
    'augurerons',
    'augureront',
    'aumôneries',
    'aumônières',
    'auparavant',
    'auréolâmes',
    'auréolasse',
    'auréolâtes',
    'auréolerai',
    'auréoleras',
    'auréolerez',
    'auréolions',
    'aurez-vous',
    'aurifiâmes',
    'aurifiasse',
    'aurifiâtes',
    'aurifierai',
    'aurifieras',
    'aurifierez',
    'aurifiions',
    'auront-ils',
    'auscultais',
    'auscultait',
    'auscultant',
    'auscultées',
    'auscultent',
    'auscultera',
    'auscultiez',
    'auscultons',
    'austérités',
    'australien',
    'autarcique',
    'autistique',
    'autoberges',
    'autochtone',
    'autoclaves',
    'autocopies',
    'autocrates',
    'autocratie',
    'autodromes',
    'auto-école',
    'autogamies',
    'autogérées',
    'autographe',
    'autoguidée',
    'autoguider',
    'autoguidés',
    'autoguidez',
    'automation',
    'automatisa',
    'automatise',
    'automatisé',
    'automédons',
    'automnales',
    'automobile',
    'automoteur',
    'autoneiges',
    'autonomies',
    'autopompes',
    'autopsiais',
    'autopsiait',
    'autopsiant',
    'autopsiées',
    'autopsient',
    'autopsiera',
    'autopsiiez',
    'autopsions',
    'autoradios',
    'autorisais',
    'autorisait',
    'autorisant',
    'autorisées',
    'autorisent',
    'autorisera',
    'autorisiez',
    'autorisons',
    'autoroutes',
    'autoscopie',
    'autotomies',
    'autotrophe',
    'autovaccin',
    'autrichien',
    'auvergnate',
    'auvergnats',
    'auxiliaire',
    'auxquelles',
    'avachirais',
    'avachirait',
    'avachirent',
    'avachiriez',
    'avachirons',
    'avachiront',
    'avachisses',
    'avachissez',
    'avait-elle',
    'avalanches',
    'avalassent',
    'avalassiez',
    'avalerions',
    'avalisâmes',
    'avalisasse',
    'avalisâtes',
    'avaliserai',
    'avaliseras',
    'avaliserez',
    'avaliseurs',
    'avalisions',
    'avançaient',
    'avançasses',
    'avancement',
    'avancerais',
    'avancerait',
    'avancèrent',
    'avanceriez',
    'avancerons',
    'avanceront',
    'avantageai',
    'avantageas',
    'avantageât',
    'avantagées',
    'avantagent',
    'avantagera',
    'avantageux',
    'avantagiez',
    'avant-bras',
    'avant-goût',
    'avant-hier',
    'avant-main',
    'avant-midi',
    'avant-port',
    'avant-toit',
    'avariaient',
    'avariasses',
    'avaricieux',
    'avarierais',
    'avarierait',
    'avarièrent',
    'avarieriez',
    'avarierons',
    'avarieront',
    'aveliniers',
    'avènements',
    'aventurais',
    'aventurait',
    'aventurant',
    'aventurées',
    'aventurent',
    'aventurera',
    'aventureux',
    'aventurier',
    'aventuriez',
    'aventurine',
    'aventurons',
    'avérassent',
    'avérassiez',
    'avérerions',
    'avertirais',
    'avertirait',
    'avertirent',
    'avertiriez',
    'avertirons',
    'avertiront',
    'avertisses',
    'avertissez',
    'aveuglâmes',
    'aveuglante',
    'aveuglants',
    'aveuglasse',
    'aveuglâtes',
    'aveuglerai',
    'aveugleras',
    'aveuglerez',
    'aveuglette',
    'aveuglions',
    'aveulirais',
    'aveulirait',
    'aveulirent',
    'aveuliriez',
    'aveulirons',
    'aveuliront',
    'aveulisses',
    'aveulissez',
    'aviatrices',
    'aviculteur',
    'aviculture',
    'aviez-vous',
    'avilirions',
    'avilissais',
    'avilissait',
    'avilissant',
    'avilissent',
    'avilissiez',
    'avilissons',
    'avinassent',
    'avinassiez',
    'avinerions',
    'avioniques',
    'avionnette',
    'avionneurs',
    'avisassent',
    'avisassiez',
    'aviserions',
    'avitaillai',
    'avitaillas',
    'avitaillât',
    'avitaillée',
    'avitailler',
    'avitailles',
    'avitaillés',
    'avitaillez',
    'avivassent',
    'avivassiez',
    'avivements',
    'aviverions',
    'avocaillon',
    'avocassier',
    'avocatiers',
    'avoisinais',
    'avoisinait',
    'avoisinant',
    'avoisinées',
    'avoisinent',
    'avoisinera',
    'avoisiniez',
    'avoisinons',
    'avons-nous',
    'avortaient',
    'avortasses',
    'avortement',
    'avorterais',
    'avorterait',
    'avortèrent',
    'avorteriez',
    'avorterons',
    'avorteront',
    'avorteuses',
    'avouassent',
    'avouassiez',
    'avouerions',
    'axillaires',
    'axiologies',
    'axiomatisa',
    'axiomatise',
    'axiomatisé',
    'ayatollahs',
    'azeroliers',
    'azimutales',
    'azurassent',
    'azurassiez',
    'azurerions',
    'babillages',
    'babillâmes',
    'babillarde',
    'babillards',
    'babillasse',
    'babillâtes',
    'babillerai',
    'babilleras',
    'babillerez',
    'babillions',
    'babiroussa',
    'babouvisme',
    'bacchanale',
    'bacchantes',
    'bacciforme',
    'bâchassent',
    'bâchassiez',
    'bachelière',
    'bacheliers',
    'bâcherions',
    'bachotages',
    'bachotâmes',
    'bachotasse',
    'bachotâtes',
    'bachoterai',
    'bachoteras',
    'bachoterez',
    'bachotions',
    'bacillaire',
    'bacilloses',
    'bâclassent',
    'bâclassiez',
    'bâclerions',
    'bactéridie',
    'bactériens',
    'badauderie',
    'badigeonna',
    'badigeonne',
    'badigeonné',
    'badinaient',
    'badinasses',
    'badinerais',
    'badinerait',
    'badinèrent',
    'badineries',
    'badineriez',
    'badinerons',
    'badineront',
    'baffassent',
    'baffassiez',
    'bafferions',
    'bafouaient',
    'bafouasses',
    'bafouerais',
    'bafouerait',
    'bafouèrent',
    'bafoueriez',
    'bafouerons',
    'bafoueront',
    'bafouillai',
    'bafouillas',
    'bafouillât',
    'bafouillée',
    'bafouiller',
    'bafouilles',
    'bafouillés',
    'bafouillez',
    'bâfrassent',
    'bâfrassiez',
    'bâfrerions',
    'bagagistes',
    'bagarrâmes',
    'bagarrasse',
    'bagarrâtes',
    'bagarrerai',
    'bagarreras',
    'bagarrerez',
    'bagarreurs',
    'bagarreuse',
    'bagarrions',
    'bagatelles',
    'baguassent',
    'baguassiez',
    'baguenauda',
    'baguenaude',
    'baguenaudé',
    'baguerions',
    'baieraient',
    'baignaient',
    'baignasses',
    'baignerais',
    'baignerait',
    'baignèrent',
    'baigneriez',
    'baignerons',
    'baigneront',
    'baigneuses',
    'baignoires',
    'baillaient',
    'bâillaient',
    'baillasses',
    'bâillasses',
    'bâillement',
    'baillerais',
    'bâillerais',
    'baillerait',
    'bâillerait',
    'baillèrent',
    'bâillèrent',
    'bailleriez',
    'bâilleriez',
    'baillerons',
    'bâillerons',
    'bailleront',
    'bâilleront',
    'bailliages',
    'bâillonnai',
    'bâillonnas',
    'bâillonnât',
    'bâillonnée',
    'bâillonner',
    'bâillonnes',
    'bâillonnés',
    'bâillonnez',
    'bain-marie',
    'baïonnette',
    'baisassent',
    'baisassiez',
    'baisemains',
    'baisements',
    'baiserions',
    'baisotâmes',
    'baisotasse',
    'baisotâtes',
    'baisoterai',
    'baisoteras',
    'baisoterez',
    'baisotions',
    'baissaient',
    'baissasses',
    'baisserais',
    'baisserait',
    'baissèrent',
    'baisseriez',
    'baisserons',
    'baisseront',
    'baissières',
    'baladaient',
    'baladasses',
    'baladerais',
    'baladerait',
    'baladèrent',
    'baladeriez',
    'baladerons',
    'baladeront',
    'baladeuses',
    'balafrâmes',
    'balafrasse',
    'balafrâtes',
    'balafrerai',
    'balafreras',
    'balafrerez',
    'balafrions',
    'balaierais',
    'balaierait',
    'balaieriez',
    'balaierons',
    'balaieront',
    'balalaïkas',
    'balançâmes',
    'balançasse',
    'balançâtes',
    'balancelle',
    'balancerai',
    'balanceras',
    'balancerez',
    'balanciers',
    'balancines',
    'balancions',
    'balançoire',
    'balayaient',
    'balayasses',
    'balayerais',
    'balayerait',
    'balayèrent',
    'balayeriez',
    'balayerons',
    'balayeront',
    'balayettes',
    'balayeuses',
    'balbutiais',
    'balbutiait',
    'balbutiant',
    'balbutiées',
    'balbutient',
    'balbutiera',
    'balbutiiez',
    'balbutions',
    'balbuzards',
    'balconnets',
    'baldaquins',
    'baleinâmes',
    'baleinasse',
    'baleinâtes',
    'baleineaux',
    'baleinerai',
    'baleineras',
    'baleinerez',
    'baleinière',
    'baleiniers',
    'baleinions',
    'balisaient',
    'balisasses',
    'baliserais',
    'baliserait',
    'balisèrent',
    'baliseriez',
    'baliserons',
    'baliseront',
    'balistique',
    'balivernes',
    'balkanique',
    'balkanisai',
    'balkanisas',
    'balkanisât',
    'balkanisée',
    'balkaniser',
    'balkanises',
    'balkanisés',
    'balkanisez',
    'ballassent',
    'ballassiez',
    'ballastage',
    'ballastais',
    'ballastait',
    'ballastant',
    'ballastées',
    'ballastent',
    'ballastera',
    'ballastiez',
    'ballastons',
    'ballerines',
    'ballerions',
    'ballonnais',
    'ballonnait',
    'ballonnant',
    'ballonnées',
    'ballonnent',
    'ballonnera',
    'ballonnets',
    'ballonniez',
    'ballonnons',
    'ballottage',
    'ballottais',
    'ballottait',
    'ballottant',
    'ballottées',
    'ballottent',
    'ballottera',
    'ballottiez',
    'ballottine',
    'ballottons',
    'ball-traps',
    'balluchons',
    'balnéaires',
    'balnéation',
    'balourdise',
    'balsamiers',
    'balsamines',
    'balsamique',
    'balthazars',
    'balustrade',
    'bambochais',
    'bambochait',
    'bambochant',
    'bambochent',
    'bambochera',
    'bambocheur',
    'bambochiez',
    'bambochons',
    'banalement',
    'banalisais',
    'banalisait',
    'banalisant',
    'banalisées',
    'banalisent',
    'banalisera',
    'banalisiez',
    'banalisons',
    'bananeraie',
    'bancroches',
    'bandagiste',
    'bandassent',
    'bandassiez',
    'bandelette',
    'banderille',
    'banderions',
    'banderoles',
    'banditisme',
    'bandonéons',
    'banjoïstes',
    'bannassent',
    'bannassiez',
    'bannerions',
    'bannirions',
    'bannissais',
    'bannissait',
    'bannissant',
    'bannissent',
    'bannissiez',
    'bannissons',
    'banquaient',
    'banquasses',
    'banquerais',
    'banquerait',
    'banquèrent',
    'banqueriez',
    'banquerons',
    'banqueront',
    'banquetais',
    'banquetait',
    'banquetant',
    'banqueteur',
    'banquetiez',
    'banquetons',
    'banquettes',
    'banquières',
    'banquistes',
    'baptisâmes',
    'baptisasse',
    'baptisâtes',
    'baptiserai',
    'baptiseras',
    'baptiserez',
    'baptisions',
    'baptismale',
    'baptismaux',
    'baquetâmes',
    'baquetasse',
    'baquetâtes',
    'baquetions',
    'baquettent',
    'baquettera',
    'baquetures',
    'baragouina',
    'baragouine',
    'baragouiné',
    'baragouins',
    'baraquâmes',
    'baraquasse',
    'baraquâtes',
    'baraquerai',
    'baraqueras',
    'baraquerez',
    'baraquions',
    'barateries',
    'baratinais',
    'baratinait',
    'baratinant',
    'baratinées',
    'baratinent',
    'baratinera',
    'baratineur',
    'baratiniez',
    'baratinons',
    'barattages',
    'barattâmes',
    'barattasse',
    'barattâtes',
    'baratterai',
    'baratteras',
    'baratterez',
    'barattions',
    'barbacanes',
    'barbarisme',
    'barbassent',
    'barbassiez',
    'barbelures',
    'barberions',
    'barbifiais',
    'barbifiait',
    'barbifiant',
    'barbifiées',
    'barbifient',
    'barbifiera',
    'barbifiiez',
    'barbifions',
    'barbillons',
    'barbotages',
    'barbotâmes',
    'barbotasse',
    'barbotâtes',
    'barboterai',
    'barboteras',
    'barboterez',
    'barboteurs',
    'barboteuse',
    'barbotière',
    'barbotines',
    'barbotions',
    'barbouilla',
    'barbouille',
    'barbouillé',
    'barcarolle',
    'bardassent',
    'bardassiez',
    'barderions',
    'barétaient',
    'barétasses',
    'barèterais',
    'barèterait',
    'barétèrent',
    'barèteriez',
    'barèterons',
    'barèteront',
    'barguignai',
    'barguignas',
    'barguignât',
    'barguigner',
    'barguignes',
    'barguignez',
    'bariolages',
    'bariolâmes',
    'bariolasse',
    'bariolâtes',
    'bariolerai',
    'barioleras',
    'bariolerez',
    'bariolions',
    'bariolures',
    'barlongues',
    'barlotière',
    'barnabites',
    'barographe',
    'baromètres',
    'baronnages',
    'baronnâmes',
    'baronnasse',
    'baronnâtes',
    'baronnerai',
    'baronneras',
    'baronnerez',
    'baronnette',
    'baronnions',
    'baroquisme',
    'baroscopes',
    'baroudâmes',
    'baroudasse',
    'baroudâtes',
    'barouderai',
    'barouderas',
    'barouderez',
    'baroudeurs',
    'baroudions',
    'barquettes',
    'barracudas',
    'barrassent',
    'barrassiez',
    'barrements',
    'barrerions',
    'barricadai',
    'barricadas',
    'barricadât',
    'barricadée',
    'barricader',
    'barricades',
    'barricadés',
    'barricadez',
    'barririons',
    'barrissais',
    'barrissait',
    'barrissant',
    'barrissent',
    'barrissiez',
    'barrissons',
    'bartavelle',
    'barycentre',
    'barymétrie',
    'barysphère',
    'basaltique',
    'basanaient',
    'basanasses',
    'basanerais',
    'basanerait',
    'basanèrent',
    'basaneriez',
    'basanerons',
    'basaneront',
    'basassions',
    'basculâmes',
    'basculante',
    'basculants',
    'basculasse',
    'basculâtes',
    'basculerai',
    'basculeras',
    'basculerez',
    'basculeurs',
    'basculions',
    'base-balls',
    'baseraient',
    'basilaires',
    'basilicale',
    'basilicaux',
    'basiliques',
    'bas-jointé',
    'basketteur',
    'basophiles',
    'basquaises',
    'bas-relief',
    'basse-cour',
    'bassinâmes',
    'bassinante',
    'bassinants',
    'bassinasse',
    'bassinâtes',
    'bassinerai',
    'bassineras',
    'bassinerez',
    'bassinions',
    'bassinoire',
    'bastillées',
    'bastingage',
    'bastionnai',
    'bastionnas',
    'bastionnât',
    'bastionnée',
    'bastionner',
    'bastionnes',
    'bastionnés',
    'bastionnez',
    'bastonnade',
    'bastringue',
    'bas-ventre',
    'bataillais',
    'bataillait',
    'bataillant',
    'bataillent',
    'bataillera',
    'batailleur',
    'batailliez',
    'bataillons',
    'batardeaux',
    'bâtardises',
    'bâtassions',
    'bataviques',
    'bateau-feu',
    'batelaient',
    'batelasses',
    'batelèrent',
    'bateleuses',
    'batelières',
    'batellerai',
    'batelleras',
    'batellerez',
    'batellerie',
    'bâteraient',
    'batifolage',
    'batifolais',
    'batifolait',
    'batifolant',
    'batifolées',
    'batifolent',
    'batifolera',
    'batifoliez',
    'batifolons',
    'bâtiraient',
    'bâtisseurs',
    'bâtisseuse',
    'bâtissions',
    'bâtonnâmes',
    'bâtonnasse',
    'bâtonnâtes',
    'bâtonnerai',
    'bâtonneras',
    'bâtonnerez',
    'bâtonniers',
    'bâtonnions',
    'batraciens',
    'battements',
    'battissent',
    'battissiez',
    'battitures',
    'battraient',
    'baudruches',
    'baugeaient',
    'baugeasses',
    'baugerions',
    'bauquières',
    'bavardages',
    'bavardâmes',
    'bavardasse',
    'bavardâtes',
    'bavarderai',
    'bavarderas',
    'bavarderez',
    'bavardions',
    'bavaroises',
    'bavassâmes',
    'bavassasse',
    'bavassâtes',
    'bavasserai',
    'bavasseras',
    'bavasserez',
    'bavassions',
    'baveraient',
    'bavochâmes',
    'bavochasse',
    'bavochâtes',
    'bavocherai',
    'bavocheras',
    'bavocherez',
    'bavochions',
    'bavochures',
    'bayassions',
    'bayeraient',
    'bazardâmes',
    'bazardasse',
    'bazardâtes',
    'bazarderai',
    'bazarderas',
    'bazarderez',
    'bazardions',
    'béarnaises',
    'béatifiais',
    'béatifiait',
    'béatifiant',
    'béatifiées',
    'béatifient',
    'béatifiera',
    'béatifiiez',
    'béatifions',
    'béatifique',
    'béatitudes',
    'beaucerons',
    'beau-frère',
    'beaujolais',
    'beaux-arts',
    'beaux-fils',
    'bécasseaux',
    'bécassines',
    'bec-croisé',
    'bêchassent',
    'bêchassiez',
    'bêcherions',
    'bécotaient',
    'bécotasses',
    'bécoterais',
    'bécoterait',
    'bécotèrent',
    'bécoteriez',
    'bécoterons',
    'bécoteront',
    'becquaient',
    'becquasses',
    'becquerais',
    'becquerait',
    'becquerels',
    'becquèrent',
    'becqueriez',
    'becquerons',
    'becqueront',
    'becquetais',
    'becquetait',
    'becquetant',
    'becquetées',
    'becquetiez',
    'becquetons',
    'becquettes',
    'bectassent',
    'bectassiez',
    'becterions',
    'bedonnâmes',
    'bedonnante',
    'bedonnants',
    'bedonnasse',
    'bedonnâtes',
    'bedonnerai',
    'bedonneras',
    'bedonnerez',
    'bedonnions',
    'beefsteaks',
    'bégaiement',
    'bégaierais',
    'bégaierait',
    'bégaieriez',
    'bégaierons',
    'bégaieront',
    'bégayaient',
    'bégayasses',
    'bégayerais',
    'bégayerait',
    'bégayèrent',
    'bégayeriez',
    'bégayerons',
    'bégayeront',
    'bégayeuses',
    'béguetâmes',
    'béguetasse',
    'béguetâtes',
    'béguèterai',
    'béguèteras',
    'béguèterez',
    'béguetions',
    'béguinages',
    'bêlassions',
    'bélemnites',
    'bêleraient',
    'belgicisme',
    'belle-mère',
    'bellicisme',
    'belliciste',
    'belliqueux',
    'belluaires',
    'belvédères',
    'bémolisais',
    'bémolisait',
    'bémolisant',
    'bémolisées',
    'bémolisent',
    'bémolisera',
    'bémolisiez',
    'bémolisons',
    'bénédicité',
    'bénédictin',
    'bénéficiai',
    'bénéficias',
    'bénéficiât',
    'bénéficier',
    'bénéficies',
    'bénéficiez',
    'bénéfiques',
    'bénévolats',
    'bénignités',
    'béniraient',
    'bénisseurs',
    'bénisseuse',
    'bénissions',
    'benjamines',
    'benthiques',
    'bentonites',
    'benzénique',
    'benzolisme',
    'béquassent',
    'béquassiez',
    'béquerions',
    'béquetâmes',
    'béquetasse',
    'béquetâtes',
    'béquetions',
    'béquettent',
    'béquettera',
    'béquillais',
    'béquillait',
    'béquillant',
    'béquillées',
    'béquillent',
    'béquillera',
    'béquilliez',
    'béquillons',
    'berçassent',
    'berçassiez',
    'bercements',
    'bercerions',
    'berkéliums',
    'berlingots',
    'bernardine',
    'bernardins',
    'bernassent',
    'bernassiez',
    'bernerions',
    'berrichons',
    'bersaglier',
    'bérylliums',
    'besognâmes',
    'besognasse',
    'besognâtes',
    'besognerai',
    'besogneras',
    'besognerez',
    'besogneuse',
    'besognions',
    'bestiaires',
    'bestialité',
    'bétaillère',
    'bêtifiâmes',
    'bêtifiasse',
    'bêtifiâtes',
    'bêtifierai',
    'bêtifieras',
    'bêtifierez',
    'bêtifiions',
    'bêtisaient',
    'bêtisasses',
    'bêtiserais',
    'bêtiserait',
    'bêtisèrent',
    'bêtiseriez',
    'bêtiserons',
    'bêtiseront',
    'bétonnages',
    'bétonnâmes',
    'bétonnasse',
    'bétonnâtes',
    'bétonnerai',
    'bétonneras',
    'bétonnerez',
    'bétonneuse',
    'bétonnière',
    'bétonnions',
    'betteraves',
    'beuglaient',
    'beuglantes',
    'beuglasses',
    'beuglement',
    'beuglerais',
    'beuglerait',
    'beuglèrent',
    'beugleriez',
    'beuglerons',
    'beugleront',
    'beurraient',
    'beurrasses',
    'beurrerais',
    'beurrerait',
    'beurrèrent',
    'beurreries',
    'beurreriez',
    'beurrerons',
    'beurreront',
    'beylicales',
    'biacuminée',
    'biacuminés',
    'biaisaient',
    'biaisasses',
    'biaiserais',
    'biaiserait',
    'biaisèrent',
    'biaiseriez',
    'biaiserons',
    'biaiseront',
    'bibelotais',
    'bibelotait',
    'bibelotant',
    'bibelotent',
    'bibelotera',
    'bibelotiez',
    'bibelotons',
    'biberonnai',
    'biberonnas',
    'biberonnât',
    'biberonner',
    'biberonnes',
    'biberonnez',
    'bicéphales',
    'bichassent',
    'bichassiez',
    'bicherions',
    'bichlamars',
    'bichlorure',
    'bichonnais',
    'bichonnait',
    'bichonnant',
    'bichonnées',
    'bichonnent',
    'bichonnera',
    'bichonniez',
    'bichonnons',
    'bichotâmes',
    'bichotasse',
    'bichotâtes',
    'bichoterai',
    'bichoteras',
    'bichoterez',
    'bichotions',
    'bichromate',
    'bichromies',
    'biconcaves',
    'biconvexes',
    'biculturel',
    'bicyclette',
    'bidonnâmes',
    'bidonnante',
    'bidonnants',
    'bidonnasse',
    'bidonnâtes',
    'bidonnerai',
    'bidonneras',
    'bidonnerez',
    'bidonnions',
    'bidonville',
    'bidouillai',
    'bidouillas',
    'bidouillât',
    'bidouillée',
    'bidouiller',
    'bidouilles',
    'bidouillés',
    'bidouillez',
    'biellettes',
    'bien-aimée',
    'bien-aimés',
    'bien-fondé',
    'bien-fonds',
    'bien-jugés',
    'bienséance',
    'bienséante',
    'bienséants',
    'bienvenues',
    'biffassent',
    'biffassiez',
    'bifferions',
    'bifilaires',
    'bifurquais',
    'bifurquait',
    'bifurquant',
    'bifurquent',
    'bifurquera',
    'bifurquiez',
    'bifurquons',
    'bigaradier',
    'bigarrâmes',
    'bigarrasse',
    'bigarrâtes',
    'bigarreaux',
    'bigarrerai',
    'bigarreras',
    'bigarrerez',
    'bigarrions',
    'bigarrures',
    'biglassent',
    'biglassiez',
    'biglerions',
    'bigophones',
    'bigornâmes',
    'bigornasse',
    'bigornâtes',
    'bigorneaux',
    'bigornerai',
    'bigorneras',
    'bigornerez',
    'bigornions',
    'bigoteries',
    'bigotismes',
    'bijections',
    'bijectives',
    'bijouterie',
    'bijoutière',
    'bijoutiers',
    'bilabiales',
    'bilassions',
    'bilatérale',
    'bilatéraux',
    'bilboquets',
    'bileraient',
    'bilharzies',
    'bilirubine',
    'billebauda',
    'billebaude',
    'billebaudé',
    'billevesée',
    'billonnage',
    'billonnais',
    'billonnait',
    'billonnant',
    'billonnées',
    'billonnent',
    'billonnera',
    'billonniez',
    'billonnons',
    'biloquâmes',
    'biloquasse',
    'biloquâtes',
    'biloquerai',
    'biloqueras',
    'biloquerez',
    'biloquions',
    'bimensuels',
    'bimestriel',
    'binassions',
    'binational',
    'bineraient',
    'binoclarde',
    'binoclards',
    'biocénoses',
    'biochimies',
    'bioénergie',
    'biogenèses',
    'biographes',
    'biographie',
    'biologique',
    'biologiste',
    'biomédical',
    'biosphères',
    'bipartisme',
    'bipartites',
    'bipolaires',
    'bipolarité',
    'biréacteur',
    'bisaïeules',
    'bisannuels',
    'bisassions',
    'biscaïenne',
    'biscornues',
    'biscuitais',
    'biscuitait',
    'biscuitant',
    'biscuitées',
    'biscuitent',
    'biscuitera',
    'biscuitiez',
    'biscuitons',
    'biseautage',
    'biseautais',
    'biseautait',
    'biseautant',
    'biseautées',
    'biseautent',
    'biseautera',
    'biseautiez',
    'biseautons',
    'bisegmenta',
    'bisegmente',
    'bisegmenté',
    'biseraient',
    'bisexuelle',
    'bismuthine',
    'bisquaient',
    'bisquasses',
    'bisquerais',
    'bisquerait',
    'bisquèrent',
    'bisqueriez',
    'bisquerons',
    'bisqueront',
    'bissassent',
    'bissassiez',
    'bissecteur',
    'bissection',
    'bisserions',
    'bissextile',
    'bissexuées',
    'bissexuels',
    'bistouille',
    'bistournai',
    'bistournas',
    'bistournât',
    'bistournée',
    'bistourner',
    'bistournes',
    'bistournés',
    'bistournez',
    'bistraient',
    'bistrasses',
    'bistrerais',
    'bistrerait',
    'bistrèrent',
    'bistreriez',
    'bistrerons',
    'bistreront',
    'bisulfates',
    'bisulfures',
    'bitassions',
    'bitensions',
    'biteraient',
    'bittassent',
    'bittassiez',
    'bitterions',
    'bitturâmes',
    'bitturasse',
    'bitturâtes',
    'bitturerai',
    'bittureras',
    'bitturerez',
    'bitturions',
    'bitumaient',
    'bitumasses',
    'bitumerais',
    'bitumerait',
    'bitumèrent',
    'bitumeriez',
    'bitumerons',
    'bitumeront',
    'bitumeuses',
    'bituminais',
    'bituminait',
    'bituminant',
    'bituminées',
    'bituminent',
    'bituminera',
    'bitumineux',
    'bituminiez',
    'bituminons',
    'bituraient',
    'biturasses',
    'biturbines',
    'biturerais',
    'biturerait',
    'biturèrent',
    'bitureriez',
    'biturerons',
    'bitureront',
    'bivalences',
    'bivalentes',
    'bivouaquai',
    'bivouaquas',
    'bivouaquât',
    'bivouaquer',
    'bivouaques',
    'bivouaquez',
    'bizarrerie',
    'bizarroïde',
    'bizutaient',
    'bizutasses',
    'bizuterais',
    'bizuterait',
    'bizutèrent',
    'bizuteriez',
    'bizuterons',
    'bizuteront',
    'blablablas',
    'blablatais',
    'blablatait',
    'blablatant',
    'blablatent',
    'blablatera',
    'blablatiez',
    'blablatons',
    'blackboula',
    'blackboule',
    'blackboulé',
    'blaguaient',
    'blaguasses',
    'blaguerais',
    'blaguerait',
    'blaguèrent',
    'blagueriez',
    'blaguerons',
    'blagueront',
    'blagueuses',
    'blairaient',
    'blairasses',
    'blairerais',
    'blairerait',
    'blairèrent',
    'blaireriez',
    'blairerons',
    'blaireront',
    'blâmassent',
    'blâmassiez',
    'blâmerions',
    'blanc-étoc',
    'blanchâtre',
    'blancheurs',
    'blanchîmes',
    'blanchirai',
    'blanchiras',
    'blanchirez',
    'blanchisse',
    'blanchîtes',
    'blanquette',
    'blasassent',
    'blasassiez',
    'blasements',
    'blaserions',
    'blasonnais',
    'blasonnait',
    'blasonnant',
    'blasonnées',
    'blasonnent',
    'blasonnera',
    'blasonniez',
    'blasonnons',
    'blasphémai',
    'blasphémas',
    'blasphémât',
    'blasphémée',
    'blasphémer',
    'blasphémés',
    'blasphèmes',
    'blasphémez',
    'blastopore',
    'blatérâmes',
    'blatérasse',
    'blatérâtes',
    'blatérerai',
    'blatéreras',
    'blatérerez',
    'blatérions',
    'blêmerions',
    'blêmissent',
    'blêmissiez',
    'blépharite',
    'blésassent',
    'blésassiez',
    'blèsements',
    'bléserions',
    'blessaient',
    'blessantes',
    'blessasses',
    'blesserais',
    'blesserait',
    'blessèrent',
    'blesseriez',
    'blesserons',
    'blesseront',
    'blettirais',
    'blettirait',
    'blettirent',
    'blettiriez',
    'blettirons',
    'blettiront',
    'blettisses',
    'blettissez',
    'bleuetière',
    'bleuirions',
    'bleuissais',
    'bleuissait',
    'bleuissant',
    'bleuissent',
    'bleuissiez',
    'bleuissons',
    'bleusaille',
    'bleutaient',
    'bleutasses',
    'bleuterais',
    'bleuterait',
    'bleutèrent',
    'bleuteriez',
    'bleuterons',
    'bleuteront',
    'blindaient',
    'blindasses',
    'blinderais',
    'blinderait',
    'blindèrent',
    'blinderiez',
    'blinderons',
    'blinderont',
    'blocailles',
    'bloc-évier',
    'bloc-notes',
    'blondasses',
    'blondinets',
    'blondirent',
    'blondisses',
    'blondissez',
    'blondoient',
    'blondoiera',
    'blondoyais',
    'blondoyait',
    'blondoyant',
    'blondoyiez',
    'blondoyons',
    'bloquaient',
    'bloquantes',
    'bloquasses',
    'bloquerais',
    'bloquerait',
    'bloquèrent',
    'bloqueriez',
    'bloquerons',
    'bloqueront',
    'blottirais',
    'blottirait',
    'blottirent',
    'blottiriez',
    'blottirons',
    'blottiront',
    'blottisses',
    'blottissez',
    'blousaient',
    'blousasses',
    'blouserais',
    'blouserait',
    'blousèrent',
    'blouseriez',
    'blouserons',
    'blouseront',
    'blue-jeans',
    'bluffaient',
    'bluffasses',
    'blufferais',
    'blufferait',
    'bluffèrent',
    'blufferiez',
    'blufferons',
    'blufferont',
    'bluffeuses',
    'blutassent',
    'blutassiez',
    'bluterions',
    'bobinaient',
    'bobinasses',
    'bobinerais',
    'bobinerait',
    'bobinèrent',
    'bobineriez',
    'bobinerons',
    'bobineront',
    'bobinettes',
    'bobineuses',
    'bobsleighs',
    'bocardages',
    'bocardâmes',
    'bocardasse',
    'bocardâtes',
    'bocarderai',
    'bocarderas',
    'bocarderez',
    'bocardions',
    'boettaient',
    'boettasses',
    'boetterais',
    'boetterait',
    'boettèrent',
    'boetteriez',
    'boetterons',
    'boetteront',
    'bohémienne',
    'boisassent',
    'boisassiez',
    'boisements',
    'boiserions',
    'boisselier',
    'boitassent',
    'boitassiez',
    'boitements',
    'boiterions',
    'boitillais',
    'boitillait',
    'boitillant',
    'boitillent',
    'boitillera',
    'boitilliez',
    'boitillons',
    'bolcheviks',
    'bolchévisa',
    'bolchévise',
    'bolchévisé',
    'bolomètres',
    'bombagiste',
    'bombardais',
    'bombardait',
    'bombardant',
    'bombardées',
    'bombardent',
    'bombardera',
    'bombardier',
    'bombardiez',
    'bombardons',
    'bombassent',
    'bombassiez',
    'bombements',
    'bomberions',
    'bonasserie',
    'bondassent',
    'bondassiez',
    'bonderions',
    'bondérisai',
    'bondérisas',
    'bondérisât',
    'bondérisée',
    'bondériser',
    'bondérises',
    'bondérisés',
    'bondérisez',
    'bondirions',
    'bondissais',
    'bondissait',
    'bondissant',
    'bondissent',
    'bondissiez',
    'bondissons',
    'bondonnais',
    'bondonnait',
    'bondonnant',
    'bondonnées',
    'bondonnent',
    'bondonnera',
    'bondonniez',
    'bondonnons',
    'bonifiâmes',
    'bonifiante',
    'bonifiants',
    'bonifiasse',
    'bonifiâtes',
    'bonifierai',
    'bonifieras',
    'bonifierez',
    'bonifiions',
    'bonimentai',
    'bonimentas',
    'bonimentât',
    'bonimenter',
    'bonimentes',
    'bonimentez',
    'bonneteaux',
    'bonneterie',
    'bonneteurs',
    'bonnetière',
    'bonnetiers',
    'bonshommes',
    'bons-papas',
    'bookmakers',
    'booléennes',
    'boomerangs',
    'bootlegger',
    'boqueteaux',
    'borborygme',
    'bordassent',
    'bordassiez',
    'bordelaise',
    'bordélique',
    'bordereaux',
    'borderions',
    'bornassent',
    'bornassiez',
    'bornerions',
    'bornoierai',
    'bornoieras',
    'bornoierez',
    'bornoyâmes',
    'bornoyasse',
    'bornoyâtes',
    'bornoyions',
    'bossassent',
    'bossassiez',
    'bosselages',
    'bosselâmes',
    'bosselasse',
    'bosselâtes',
    'bosselions',
    'bossellent',
    'bossellera',
    'bosselures',
    'bosserions',
    'bossuaient',
    'bossuasses',
    'bossuerais',
    'bossuerait',
    'bossuèrent',
    'bossueriez',
    'bossuerons',
    'bossueront',
    'bostryches',
    'botaniques',
    'botanisais',
    'botanisait',
    'botanisant',
    'botanisent',
    'botanisera',
    'botanisiez',
    'botanisons',
    'botanistes',
    'bottassent',
    'bottassiez',
    'bottelages',
    'bottelâmes',
    'bottelasse',
    'bottelâtes',
    'botteleurs',
    'botteleuse',
    'bottelions',
    'bottellent',
    'bottellera',
    'botterions',
    'bottillons',
    'botuliques',
    'boucanages',
    'boucanâmes',
    'boucanasse',
    'boucanâtes',
    'boucanerai',
    'boucaneras',
    'boucanerez',
    'boucaniers',
    'boucanions',
    'bouchaient',
    'bouchardai',
    'bouchardas',
    'bouchardât',
    'bouchardée',
    'boucharder',
    'bouchardes',
    'bouchardés',
    'bouchardez',
    'bouchasses',
    'boucherais',
    'boucherait',
    'bouchèrent',
    'boucheries',
    'boucheriez',
    'boucherons',
    'boucheront',
    'bouchonnai',
    'bouchonnas',
    'bouchonnât',
    'bouchonnée',
    'bouchonner',
    'bouchonnes',
    'bouchonnés',
    'bouchonnez',
    'bouchoteur',
    'bouclaient',
    'bouclasses',
    'bouclerais',
    'bouclerait',
    'bouclèrent',
    'boucleriez',
    'bouclerons',
    'boucleront',
    'bouclettes',
    'boudassent',
    'boudassiez',
    'bouddhique',
    'bouddhisme',
    'bouddhiste',
    'bouderions',
    'boudinages',
    'boudinâmes',
    'boudinasse',
    'boudinâtes',
    'boudinerai',
    'boudineras',
    'boudinerez',
    'boudineuse',
    'boudinions',
    'bouffaient',
    'bouffantes',
    'bouffardes',
    'bouffasses',
    'boufferais',
    'boufferait',
    'bouffèrent',
    'boufferiez',
    'boufferons',
    'boufferont',
    'bouffettes',
    'bouffirais',
    'bouffirait',
    'bouffirent',
    'bouffiriez',
    'bouffirons',
    'bouffiront',
    'bouffisses',
    'bouffissez',
    'bouffonnai',
    'bouffonnas',
    'bouffonnât',
    'bouffonner',
    'bouffonnes',
    'bouffonnez',
    'bougeaient',
    'bougeasses',
    'bougeottes',
    'bougerions',
    'bougonnais',
    'bougonnait',
    'bougonnant',
    'bougonnent',
    'bougonnera',
    'bougonniez',
    'bougonnons',
    'bougrement',
    'bouillante',
    'bouillants',
    'bouilleurs',
    'bouillîmes',
    'bouillions',
    'bouillirai',
    'bouilliras',
    'bouillirez',
    'bouillisse',
    'bouillîtes',
    'bouilloire',
    'bouillonna',
    'bouillonne',
    'bouillonné',
    'bouillotta',
    'bouillotte',
    'bouillotté',
    'boulangeai',
    'boulangeas',
    'boulangeât',
    'boulangées',
    'boulangent',
    'boulangera',
    'boulangère',
    'boulangers',
    'boulangiez',
    'boulassent',
    'boulassiez',
    'bouldozeur',
    'bouledogue',
    'boulerions',
    'boulevards',
    'bouleversa',
    'bouleverse',
    'bouleversé',
    'boulimique',
    'boulingrin',
    'boulinière',
    'bouliniers',
    'boulodrome',
    'boulonnage',
    'boulonnais',
    'boulonnait',
    'boulonnant',
    'boulonnées',
    'boulonnent',
    'boulonnera',
    'boulonniez',
    'boulonnons',
    'boulottais',
    'boulottait',
    'boulottant',
    'boulottées',
    'boulottent',
    'boulottera',
    'boulottiez',
    'boulottons',
    'boumerions',
    'bouquetier',
    'bouquetins',
    'bouquinais',
    'bouquinait',
    'bouquinant',
    'bouquinées',
    'bouquinent',
    'bouquinera',
    'bouquineur',
    'bouquiniez',
    'bouquinons',
    'bourbeuses',
    'bourbillon',
    'bourbonien',
    'bourdaient',
    'bourdaines',
    'bourdasses',
    'bourderais',
    'bourderait',
    'bourdèrent',
    'bourderiez',
    'bourderons',
    'bourderont',
    'bourdonnai',
    'bourdonnas',
    'bourdonnât',
    'bourdonner',
    'bourdonnes',
    'bourdonnez',
    'bourgeoise',
    'bourgeonna',
    'bourgeonne',
    'bourgeonné',
    'bourgerons',
    'bourgognes',
    'bourlingua',
    'bourlingue',
    'bourlingué',
    'bourraches',
    'bourraient',
    'bourrasque',
    'bourrasses',
    'bourratifs',
    'bourrative',
    'bourrelais',
    'bourrelait',
    'bourrelant',
    'bourrelées',
    'bourrelets',
    'bourrelier',
    'bourreliez',
    'bourrelles',
    'bourrelons',
    'bourrerais',
    'bourrerait',
    'bourrèrent',
    'bourreriez',
    'bourrerons',
    'bourreront',
    'bourrettes',
    'bourriches',
    'bourrichon',
    'bourricots',
    'bourriquai',
    'bourriquas',
    'bourriquât',
    'bourriquer',
    'bourriques',
    'bourriquet',
    'bourriquez',
    'boursicota',
    'boursicote',
    'boursicoté',
    'boursières',
    'boursoufla',
    'boursoufle',
    'boursouflé',
    'bouscueils',
    'bousculade',
    'bousculais',
    'bousculait',
    'bousculant',
    'bousculées',
    'bousculent',
    'bousculera',
    'bousculiez',
    'bousculons',
    'bousillage',
    'bousillais',
    'bousillait',
    'bousillant',
    'bousillées',
    'bousillent',
    'bousillera',
    'bousilleur',
    'bousilliez',
    'bousillons',
    'boutassent',
    'boutassiez',
    'bouteiller',
    'bouteilles',
    'bouteillon',
    'bouterions',
    'bouterolle',
    'bouteroues',
    'boutiquier',
    'boutonnage',
    'boutonnais',
    'boutonnait',
    'boutonnant',
    'boutonnées',
    'boutonnent',
    'boutonnera',
    'boutonneux',
    'boutonnier',
    'boutonniez',
    'boutonnons',
    'bouturages',
    'bouturâmes',
    'bouturasse',
    'bouturâtes',
    'bouturerai',
    'boutureras',
    'bouturerez',
    'bouturions',
    'bouveteuse',
    'bouvillons',
    'bouvreuils',
    'bovarysmes',
    'bow-window',
    'boxassions',
    'boxeraient',
    'box-office',
    'boyauderie',
    'boyaudière',
    'boyaudiers',
    'boycottage',
    'boycottais',
    'boycottait',
    'boycottant',
    'boycottées',
    'boycottent',
    'boycottera',
    'boycotteur',
    'boycottiez',
    'boycottons',
    'boy-scouts',
    'brabançons',
    'brachiales',
    'braconnage',
    'braconnais',
    'braconnait',
    'braconnant',
    'braconnées',
    'braconnent',
    'braconnera',
    'braconnier',
    'braconniez',
    'braconnons',
    'bractéales',
    'bradassent',
    'bradassiez',
    'braderions',
    'braguettes',
    'braierions',
    'braillâmes',
    'braillarde',
    'braillards',
    'braillasse',
    'braillâtes',
    'braillerai',
    'brailleras',
    'braillerez',
    'brailleurs',
    'brailleuse',
    'braillions',
    'brairaient',
    'braisaient',
    'braisasses',
    'braiserais',
    'braiserait',
    'braisèrent',
    'braiseriez',
    'braiserons',
    'braiseront',
    'braisières',
    'bramassent',
    'bramassiez',
    'bramements',
    'bramerions',
    'branchages',
    'branchâmes',
    'branchasse',
    'branchâtes',
    'brancherai',
    'brancheras',
    'brancherez',
    'branchette',
    'branchiale',
    'branchiaux',
    'branchions',
    'brandillai',
    'brandillas',
    'brandillât',
    'brandillée',
    'brandiller',
    'brandilles',
    'brandillés',
    'brandillez',
    'brandirais',
    'brandirait',
    'brandirent',
    'brandiriez',
    'brandirons',
    'brandiront',
    'brandisses',
    'brandissez',
    'branlaient',
    'branlantes',
    'branlasses',
    'branle-bas',
    'branlement',
    'branlerais',
    'branlerait',
    'branlèrent',
    'branleriez',
    'branlerons',
    'branleront',
    'braquaient',
    'braquasses',
    'braquemart',
    'braquerais',
    'braquerait',
    'braquèrent',
    'braqueriez',
    'braquerons',
    'braqueront',
    'brasassent',
    'brasassiez',
    'braserions',
    'brasillais',
    'brasillait',
    'brasillant',
    'brasillent',
    'brasillera',
    'brasilliez',
    'brasillons',
    'brassaient',
    'brassasses',
    'brasserais',
    'brasserait',
    'brassèrent',
    'brasseries',
    'brasseriez',
    'brasserons',
    'brasseront',
    'brasseuses',
    'brassières',
    'bravassent',
    'bravassiez',
    'braverions',
    'brayassent',
    'brayassiez',
    'brayerions',
    'breakfasts',
    'bredouilla',
    'bredouille',
    'bredouillé',
    'brélassent',
    'brêlassent',
    'brélassiez',
    'brêlassiez',
    'brélerions',
    'brêlerions',
    'brellaient',
    'brellasses',
    'brellerais',
    'brellerait',
    'brellèrent',
    'brelleriez',
    'brellerons',
    'brelleront',
    'brésiliens',
    'brésillais',
    'brésillait',
    'brésillant',
    'brésillées',
    'brésillent',
    'brésillera',
    'brésilliez',
    'brésillons',
    'bretessées',
    'bretonnant',
    'brettaient',
    'brettasses',
    'brettelais',
    'brettelait',
    'brettelant',
    'brettelées',
    'bretteliez',
    'brettelles',
    'brettelons',
    'bretterais',
    'bretterait',
    'brettèrent',
    'bretteriez',
    'bretterons',
    'bretteront',
    'brevetable',
    'brevetâmes',
    'brevetasse',
    'brevetâtes',
    'brevetions',
    'brevettent',
    'brevettera',
    'bréviaires',
    'bréviligne',
    'bricolages',
    'bricolâmes',
    'bricolasse',
    'bricolâtes',
    'bricolerai',
    'bricoleras',
    'bricolerez',
    'bricoleurs',
    'bricoleuse',
    'bricolions',
    'bridassent',
    'bridassiez',
    'briderions',
    'bridgeâmes',
    'bridgeasse',
    'bridgeâtes',
    'bridgerais',
    'bridgerait',
    'bridgèrent',
    'bridgeriez',
    'bridgerons',
    'bridgeront',
    'bridgeuses',
    'brièvement',
    'brifassent',
    'brifassiez',
    'briferions',
    'briffaient',
    'briffasses',
    'brifferais',
    'brifferait',
    'briffèrent',
    'brifferiez',
    'brifferons',
    'brifferont',
    'brigadiers',
    'brigandage',
    'brigandais',
    'brigandait',
    'brigandant',
    'brigandées',
    'brigandent',
    'brigandera',
    'brigandiez',
    'brigandine',
    'brigandons',
    'brigantine',
    'brigantins',
    'brightisme',
    'briguaient',
    'briguasses',
    'briguerais',
    'briguerait',
    'briguèrent',
    'brigueriez',
    'briguerons',
    'brigueront',
    'brillaient',
    'brillances',
    'brillantai',
    'brillantas',
    'brillantât',
    'brillantée',
    'brillanter',
    'brillantes',
    'brillantés',
    'brillantez',
    'brillasses',
    'brillerais',
    'brillerait',
    'brillèrent',
    'brilleriez',
    'brillerons',
    'brilleront',
    'brimassent',
    'brimassiez',
    'brimbalais',
    'brimbalait',
    'brimbalant',
    'brimbalées',
    'brimbalent',
    'brimbalera',
    'brimbaliez',
    'brimbalons',
    'brimborion',
    'brimerions',
    'brindilles',
    'briquaient',
    'briquasses',
    'briquerais',
    'briquerait',
    'briquèrent',
    'briqueriez',
    'briquerons',
    'briqueront',
    'briquetage',
    'briquetais',
    'briquetait',
    'briquetant',
    'briquetées',
    'briquetier',
    'briquetiez',
    'briquetons',
    'briquettes',
    'brisassent',
    'brisassiez',
    'brise-bise',
    'brisements',
    'briserions',
    'brise-tout',
    'brise-vent',
    'brocantage',
    'brocantais',
    'brocantait',
    'brocantant',
    'brocantées',
    'brocantent',
    'brocantera',
    'brocanteur',
    'brocantiez',
    'brocantons',
    'brocardais',
    'brocardait',
    'brocardant',
    'brocardées',
    'brocardent',
    'brocardera',
    'brocardiez',
    'brocardons',
    'brocatelle',
    'brochaient',
    'brochantes',
    'brochasses',
    'brocherais',
    'brocherait',
    'brochèrent',
    'brocheriez',
    'brocherons',
    'brocheront',
    'brochetons',
    'brochettes',
    'brocheuses',
    'brodassent',
    'brodassiez',
    'brodequins',
    'broderions',
    'broiements',
    'broierions',
    'bronchâmes',
    'bronchasse',
    'bronchâtes',
    'broncherai',
    'broncheras',
    'broncherez',
    'bronchiole',
    'bronchions',
    'bronchique',
    'bronchites',
    'bronzaient',
    'bronzantes',
    'bronzasses',
    'bronzerais',
    'bronzerait',
    'bronzèrent',
    'bronzeriez',
    'bronzerons',
    'bronzeront',
    'broquettes',
    'brossaient',
    'brossasses',
    'brosserais',
    'brosserait',
    'brossèrent',
    'brosseries',
    'brosseriez',
    'brosserons',
    'brosseront',
    'brossières',
    'brouettais',
    'brouettait',
    'brouettant',
    'brouettées',
    'brouettent',
    'brouettera',
    'brouettiez',
    'brouettons',
    'brouillage',
    'brouillais',
    'brouillait',
    'brouillant',
    'brouillard',
    'brouillées',
    'brouillent',
    'brouillera',
    'brouilleur',
    'brouilliez',
    'brouillons',
    'broussards',
    'broutaient',
    'broutasses',
    'broutement',
    'brouterais',
    'brouterait',
    'broutèrent',
    'brouteriez',
    'brouterons',
    'brouteront',
    'broutilles',
    'broyassent',
    'broyassiez',
    'brucellose',
    'brugnonier',
    'bruinerait',
    'bruineuses',
    'bruiraient',
    'bruissages',
    'bruissions',
    'bruitaient',
    'bruitasses',
    'bruiterais',
    'bruiterait',
    'bruitèrent',
    'bruiteriez',
    'bruiterons',
    'bruiteront',
    'bruiteuses',
    'brûlassent',
    'brûlassiez',
    'brûlerions',
    'brumassait',
    'brumassera',
    'brunirions',
    'brunissage',
    'brunissais',
    'brunissait',
    'brunissant',
    'brunissent',
    'brunisseur',
    'brunissiez',
    'brunissoir',
    'brunissons',
    'brunissure',
    'brusquâmes',
    'brusquasse',
    'brusquâtes',
    'brusquerai',
    'brusqueras',
    'brusquerez',
    'brusquerie',
    'brusquions',
    'brutalisai',
    'brutalisas',
    'brutalisât',
    'brutalisée',
    'brutaliser',
    'brutalises',
    'brutalisés',
    'brutalisez',
    'brutalités',
    'bruyamment',
    'bryologies',
    'buanderies',
    'buandières',
    'buboniques',
    'bûchassent',
    'bûchassiez',
    'bûcherions',
    'bûcheronne',
    'bucoliques',
    'budgétaire',
    'budgétisai',
    'budgétisas',
    'budgétisât',
    'budgétisée',
    'budgétiser',
    'budgétises',
    'budgétisés',
    'budgétisez',
    'buffetière',
    'buffetiers',
    'bulldozers',
    'buralistes',
    'burinaient',
    'burinasses',
    'burinerais',
    'burinerait',
    'burinèrent',
    'burineriez',
    'burinerons',
    'burineront',
    'burlesques',
    'burlingues',
    'busseroles',
    'butadiènes',
    'butassions',
    'buteraient',
    'butinaient',
    'butinasses',
    'butinerais',
    'butinerait',
    'butinèrent',
    'butineriez',
    'butinerons',
    'butineront',
    'butineuses',
    'buttassent',
    'buttassiez',
    'butterions',
    'butyliques',
    'butyreuses',
    'butyriques',
    'buvetières',
    'buvotaient',
    'buvotasses',
    'buvoterais',
    'buvoterait',
    'buvotèrent',
    'buvoteriez',
    'buvoterons',
    'buvoteront',
    'byssinoses',
    'byzantines',
    'cabalaient',
    'cabalasses',
    'cabalerais',
    'cabalerait',
    'cabalèrent',
    'cabaleriez',
    'cabalerons',
    'cabaleront',
    'cabalistes',
    'cabanaient',
    'cabanasses',
    'cabanerais',
    'cabanerait',
    'cabanèrent',
    'cabaneriez',
    'cabanerons',
    'cabaneront',
    'cabaretier',
    'cabillauds',
    'câblassent',
    'câblassiez',
    'câblerions',
    'cabocharde',
    'cabochards',
    'cabossâmes',
    'cabossasse',
    'cabossâtes',
    'cabosserai',
    'cabosseras',
    'cabosserez',
    'cabossions',
    'cabotaient',
    'cabotasses',
    'caboterais',
    'caboterait',
    'cabotèrent',
    'caboteriez',
    'caboterons',
    'caboteront',
    'cabotinage',
    'cabotinais',
    'cabotinait',
    'cabotinant',
    'cabotinent',
    'cabotinera',
    'cabotiniez',
    'cabotinons',
    'cabrassent',
    'cabrassiez',
    'cabrerions',
    'cabriolais',
    'cabriolait',
    'cabriolant',
    'cabriolent',
    'cabriolera',
    'cabriolets',
    'cabrioliez',
    'cabriolons',
    'cacabaient',
    'cacabasses',
    'cacaberais',
    'cacaberait',
    'cacabèrent',
    'cacaberiez',
    'cacaberons',
    'cacaberont',
    'cacahouète',
    'cacahuètes',
    'cacaotière',
    'cacaotiers',
    'cacaoyères',
    'cacardâmes',
    'cacardasse',
    'cacardâtes',
    'cacarderai',
    'cacarderas',
    'cacarderez',
    'cacardions',
    'cachassent',
    'cachassiez',
    'cachemires',
    'cacherions',
    'cache-sexe',
    'cachetages',
    'cachetâmes',
    'cachetasse',
    'cachetâtes',
    'cachetions',
    'cachettent',
    'cachettera',
    'cachottier',
    'cacochymes',
    'cacodylate',
    'cacographe',
    'cacologies',
    'cacophonie',
    'cadanchais',
    'cadanchait',
    'cadanchant',
    'cadanchent',
    'cadanchera',
    'cadanchiez',
    'cadanchons',
    'cadastrais',
    'cadastrait',
    'cadastrale',
    'cadastrant',
    'cadastraux',
    'cadastrées',
    'cadastrent',
    'cadastrera',
    'cadastriez',
    'cadastrons',
    'cadavéreux',
    'cadenassai',
    'cadenassas',
    'cadenassât',
    'cadenassée',
    'cadenasser',
    'cadenasses',
    'cadenassés',
    'cadenassez',
    'cadençâmes',
    'cadençasse',
    'cadençâtes',
    'cadencerai',
    'cadenceras',
    'cadencerez',
    'cadencions',
    'cadenettes',
    'cadmiaient',
    'cadmiasses',
    'cadmierais',
    'cadmierait',
    'cadmièrent',
    'cadmieriez',
    'cadmierons',
    'cadmieront',
    'cadrassent',
    'cadrassiez',
    'cadratures',
    'cadrerions',
    'cafardages',
    'cafardâmes',
    'cafardasse',
    'cafardâtes',
    'cafarderai',
    'cafarderas',
    'cafarderez',
    'cafardeurs',
    'cafardeuse',
    'cafardions',
    'cafétérias',
    'cafetières',
    'cafouillai',
    'cafouillas',
    'cafouillât',
    'cafouiller',
    'cafouilles',
    'cafouillez',
    'cafouillis',
    'caftassent',
    'caftassiez',
    'cafterions',
    'cagerottes',
    'cagnardais',
    'cagnardait',
    'cagnardant',
    'cagnardent',
    'cagnardera',
    'cagnardiez',
    'cagnardons',
    'cagnassent',
    'cagnassiez',
    'cagnerions',
    'cagoteries',
    'cagoularde',
    'cagoulards',
    'cahin-caha',
    'cahotaient',
    'cahotantes',
    'cahotasses',
    'cahotement',
    'cahoterais',
    'cahoterait',
    'cahotèrent',
    'cahoteriez',
    'cahoterons',
    'cahoteront',
    'cahoteuses',
    'caillaient',
    'caillasses',
    'caillement',
    'caillerais',
    'caillerait',
    'caillèrent',
    'cailleriez',
    'caillerons',
    'cailleront',
    'cailletais',
    'cailletait',
    'cailletant',
    'cailletiez',
    'cailletons',
    'caillettes',
    'cailloutai',
    'cailloutas',
    'cailloutât',
    'cailloutée',
    'caillouter',
    'cailloutes',
    'cailloutés',
    'cailloutez',
    'cailloutis',
    'caisseries',
    'caissettes',
    'caissières',
    'cajeputier',
    'cajolaient',
    'cajolantes',
    'cajolasses',
    'cajolerais',
    'cajolerait',
    'cajolèrent',
    'cajoleries',
    'cajoleriez',
    'cajolerons',
    'cajoleront',
    'cajoleuses',
    'calabraise',
    'calaminage',
    'calaminais',
    'calaminait',
    'calaminant',
    'calaminées',
    'calaminent',
    'calaminera',
    'calaminiez',
    'calaminons',
    'calamistra',
    'calamistre',
    'calamistré',
    'calamiteux',
    'calanchais',
    'calanchait',
    'calanchant',
    'calanchent',
    'calanchera',
    'calanchiez',
    'calanchons',
    'calandrage',
    'calandrais',
    'calandrait',
    'calandrant',
    'calandrées',
    'calandrent',
    'calandrera',
    'calandreur',
    'calandriez',
    'calandrons',
    'calassions',
    'calcanéums',
    'calcédoine',
    'calcicoles',
    'calcifiées',
    'calcinâmes',
    'calcinasse',
    'calcinâtes',
    'calcinerai',
    'calcineras',
    'calcinerez',
    'calcinions',
    'calciuries',
    'calculable',
    'calculâmes',
    'calculasse',
    'calculâtes',
    'calculerai',
    'calculeras',
    'calculerez',
    'calculette',
    'calculeuse',
    'calculions',
    'caldariums',
    'calebasses',
    'calédonien',
    'calembours',
    'calendrier',
    'cale-pieds',
    'caleraient',
    'caletaient',
    'caletasses',
    'caletèrent',
    'caletterai',
    'caletteras',
    'caletterez',
    'calfatages',
    'calfatâmes',
    'calfatasse',
    'calfatâtes',
    'calfaterai',
    'calfateras',
    'calfaterez',
    'calfations',
    'calfeutrai',
    'calfeutras',
    'calfeutrât',
    'calfeutrée',
    'calfeutrer',
    'calfeutres',
    'calfeutrés',
    'calfeutrez',
    'calibrages',
    'calibrâmes',
    'calibrasse',
    'calibrâtes',
    'calibrerai',
    'calibreras',
    'calibrerez',
    'calibreurs',
    'calibreuse',
    'calibrions',
    'câlinaient',
    'câlinantes',
    'câlinasses',
    'câlinerais',
    'câlinerait',
    'câlinèrent',
    'câlineries',
    'câlineriez',
    'câlinerons',
    'câlineront',
    'call-girls',
    'callipyges',
    'callosités',
    'calmassent',
    'calmassiez',
    'calmerions',
    'calmirions',
    'calmissais',
    'calmissait',
    'calmissant',
    'calmissent',
    'calmissiez',
    'calmissons',
    'calomniais',
    'calomniait',
    'calomniant',
    'calomniées',
    'calomnient',
    'calomniera',
    'calomnieux',
    'calomniiez',
    'calomnions',
    'calorifère',
    'calorifuge',
    'calorifugé',
    'caloriques',
    'calottâmes',
    'calottasse',
    'calottâtes',
    'calotterai',
    'calotteras',
    'calotterez',
    'calottions',
    'calquaient',
    'calquasses',
    'calquerais',
    'calquerait',
    'calquèrent',
    'calqueriez',
    'calquerons',
    'calqueront',
    'caltassent',
    'caltassiez',
    'calterions',
    'calvinisme',
    'calviniste',
    'camaldules',
    'camarillas',
    'cambodgien',
    'cambraient',
    'cambrasses',
    'cambrement',
    'cambrerais',
    'cambrerait',
    'cambrèrent',
    'cambreriez',
    'cambrerons',
    'cambreront',
    'cambrienne',
    'cambriolai',
    'cambriolas',
    'cambriolât',
    'cambriolée',
    'cambrioler',
    'cambrioles',
    'cambriolés',
    'cambriolez',
    'cambrousse',
    'cambusiers',
    'cambutâmes',
    'cambutasse',
    'cambutâtes',
    'cambuterai',
    'cambuteras',
    'cambuterez',
    'cambutions',
    'camelotais',
    'camelotait',
    'camelotant',
    'camelotent',
    'camelotera',
    'camelotiez',
    'camelotons',
    'camemberts',
    'caméristes',
    'camionnage',
    'camionnais',
    'camionnait',
    'camionnant',
    'camionnées',
    'camionnent',
    'camionnera',
    'camionneur',
    'camionniez',
    'camionnons',
    'camomilles',
    'camouflage',
    'camouflais',
    'camouflait',
    'camouflant',
    'camouflées',
    'camouflent',
    'camouflera',
    'camouflets',
    'camoufliez',
    'camouflons',
    'campagnard',
    'campagnols',
    'campaniles',
    'campanules',
    'campassent',
    'campassiez',
    'campements',
    'camperions',
    'camphriers',
    'canadienne',
    'canalicule',
    'canalisais',
    'canalisait',
    'canalisant',
    'canalisées',
    'canalisent',
    'canalisera',
    'canalisiez',
    'canalisons',
    'cananéenne',
    'canapé-lit',
    'canardâmes',
    'canardasse',
    'canardâtes',
    'canardeaus',
    'canarderai',
    'canarderas',
    'canarderez',
    'canardière',
    'canardions',
    'canassions',
    'cancanâmes',
    'cancanasse',
    'cancanâtes',
    'cancanerai',
    'cancaneras',
    'cancanerez',
    'cancanière',
    'cancaniers',
    'cancanions',
    'cancéreuse',
    'cancrelats',
    'candélabre',
    'candidates',
    'candirions',
    'candissais',
    'candissait',
    'candissant',
    'candissent',
    'candissiez',
    'candissons',
    'canéphores',
    'caneraient',
    'cannassent',
    'cannassiez',
    'cannelâmes',
    'cannelasse',
    'cannelâtes',
    'canneliers',
    'cannelions',
    'cannellent',
    'cannellera',
    'cannelloni',
    'cannelures',
    'cannerions',
    'cannetille',
    'cannibales',
    'canoéistes',
    'canoniales',
    'canonicats',
    'canoniques',
    'canonisais',
    'canonisait',
    'canonisant',
    'canonisées',
    'canonisent',
    'canonisera',
    'canonisiez',
    'canonisons',
    'canonistes',
    'canonnades',
    'canonnages',
    'canonnâmes',
    'canonnasse',
    'canonnâtes',
    'canonnerai',
    'canonneras',
    'canonnerez',
    'canonnière',
    'canonniers',
    'canonnions',
    'canotaient',
    'canotasses',
    'canoterais',
    'canoterait',
    'canotèrent',
    'canoteriez',
    'canoterons',
    'canoteront',
    'canoteuses',
    'cantaloups',
    'cantatrice',
    'cantharide',
    'cantilènes',
    'cantilever',
    'cantinière',
    'cantiniers',
    'cantonales',
    'cantonnais',
    'cantonnait',
    'cantonnant',
    'cantonnées',
    'cantonnent',
    'cantonnera',
    'cantonnier',
    'cantonniez',
    'cantonnons',
    'canulaient',
    'canulasses',
    'canulerais',
    'canulerait',
    'canulèrent',
    'canuleriez',
    'canulerons',
    'canuleront',
    'caoutchouc',
    'caparaçons',
    'capéassent',
    'capéassiez',
    'capéerions',
    'capelaient',
    'capelasses',
    'capelèrent',
    'capellerai',
    'capelleras',
    'capellerez',
    'capésienne',
    'capétienne',
    'capeyaient',
    'capeyasses',
    'capeyerais',
    'capeyerait',
    'capeyèrent',
    'capeyeriez',
    'capeyerons',
    'capeyeront',
    'capharnaüm',
    'capillaire',
    'capilotade',
    'capitaines',
    'capitalisa',
    'capitalise',
    'capitalisé',
    'capiteuses',
    'capitoline',
    'capitolins',
    'capitonnai',
    'capitonnas',
    'capitonnât',
    'capitonnée',
    'capitonner',
    'capitonnes',
    'capitonnés',
    'capitonnez',
    'capitulais',
    'capitulait',
    'capitulant',
    'capitulard',
    'capitulent',
    'capitulera',
    'capituliez',
    'capitulons',
    'caponnâmes',
    'caponnasse',
    'caponnâtes',
    'caponnerai',
    'caponneras',
    'caponnerez',
    'caponnière',
    'caponnions',
    'caporalisa',
    'caporalise',
    'caporalisé',
    'capotaient',
    'capotasses',
    'capoterais',
    'capoterait',
    'capotèrent',
    'capoteriez',
    'capoterons',
    'capoteront',
    'capricante',
    'capricants',
    'capriccios',
    'capricieux',
    'capricorne',
    'caprylique',
    'capsulages',
    'capsulaire',
    'capsulâmes',
    'capsulasse',
    'capsulâtes',
    'capsulerai',
    'capsuleras',
    'capsulerez',
    'capsulions',
    'captassent',
    'captassiez',
    'captateurs',
    'captations',
    'captatives',
    'captatoire',
    'captatrice',
    'capterions',
    'captieuses',
    'captivâmes',
    'captivante',
    'captivants',
    'captivasse',
    'captivâtes',
    'captiverai',
    'captiveras',
    'captiverez',
    'captivions',
    'captivités',
    'capturâmes',
    'capturasse',
    'capturâtes',
    'capturerai',
    'captureras',
    'capturerez',
    'capturions',
    'capuchonna',
    'capuchonne',
    'capuchonné',
    'capucinade',
    'caquassent',
    'caquassiez',
    'caquerions',
    'caquetages',
    'caquetâmes',
    'caquetante',
    'caquetants',
    'caquetasse',
    'caquetâtes',
    'caquetions',
    'caquettent',
    'caquettera',
    'carabinées',
    'carabinier',
    'caracolais',
    'caracolait',
    'caracolant',
    'caracolent',
    'caracolera',
    'caracoliez',
    'caracolons',
    'caractères',
    'carambolai',
    'carambolas',
    'carambolât',
    'carambolée',
    'caramboler',
    'caramboles',
    'carambolés',
    'carambolez',
    'caramélées',
    'caramélisa',
    'caramélise',
    'caramélisé',
    'carapatais',
    'carapatait',
    'carapatant',
    'carapatées',
    'carapatent',
    'carapatera',
    'carapatiez',
    'carapatons',
    'caravanage',
    'caravanier',
    'caravaning',
    'caravelles',
    'carbonatai',
    'carbonatas',
    'carbonatât',
    'carbonatée',
    'carbonater',
    'carbonates',
    'carbonatés',
    'carbonatez',
    'carbonique',
    'carbonisai',
    'carbonisas',
    'carbonisât',
    'carbonisée',
    'carboniser',
    'carbonises',
    'carbonisés',
    'carbonisez',
    'carbonnade',
    'carbonyles',
    'carboxyles',
    'carburâmes',
    'carburants',
    'carburasse',
    'carburâtes',
    'carburerai',
    'carbureras',
    'carburerez',
    'carburions',
    'carcaillai',
    'carcaillas',
    'carcaillât',
    'carcailler',
    'carcailles',
    'carcaillez',
    'carcérales',
    'carcinomes',
    'cardamines',
    'cardamomes',
    'cardassent',
    'cardassiez',
    'carderions',
    'cardialgie',
    'cardiaques',
    'cardinalat',
    'cardinales',
    'cardioïdes',
    'carénaient',
    'carénasses',
    'carençâmes',
    'carençasse',
    'carençâtes',
    'carencerai',
    'carenceras',
    'carencerez',
    'carencions',
    'carénerais',
    'carénerait',
    'carénèrent',
    'caréneriez',
    'carénerons',
    'caréneront',
    'carentiels',
    'caressâmes',
    'caressante',
    'caressants',
    'caressasse',
    'caressâtes',
    'caresserai',
    'caresseras',
    'caresserez',
    'caressions',
    'cargaisons',
    'carguaient',
    'carguasses',
    'carguerais',
    'carguerait',
    'carguèrent',
    'cargueriez',
    'carguerons',
    'cargueront',
    'cariassent',
    'cariassiez',
    'cariatides',
    'caricatura',
    'caricature',
    'caricaturé',
    'carierions',
    'carillonna',
    'carillonne',
    'carillonné',
    'caritatifs',
    'caritative',
    'carlingues',
    'carmagnole',
    'carmassent',
    'carmassiez',
    'carmelines',
    'carmélites',
    'carmerions',
    'carminâmes',
    'carminasse',
    'carminâtes',
    'carminatif',
    'carminerai',
    'carmineras',
    'carminerez',
    'carminions',
    'carnassier',
    'carnations',
    'carnifiais',
    'carnifiait',
    'carnifiant',
    'carnifiées',
    'carnifient',
    'carnifiera',
    'carnifiiez',
    'carnifions',
    'carnivores',
    'carnotsets',
    'caroncules',
    'carottages',
    'carottâmes',
    'carottasse',
    'carottâtes',
    'carotterai',
    'carotteras',
    'carotterez',
    'carotteurs',
    'carotteuse',
    'carottière',
    'carottiers',
    'carottions',
    'caroubiers',
    'carpatique',
    'carpiennes',
    'carpillons',
    'carpocapse',
    'carrassent',
    'carrassiez',
    'carrefours',
    'carrelages',
    'carrelâmes',
    'carrelasse',
    'carrelâtes',
    'carreleurs',
    'carrelions',
    'carrellent',
    'carrellera',
    'carrerions',
    'carroierai',
    'carroieras',
    'carroierez',
    'carrossage',
    'carrossais',
    'carrossait',
    'carrossant',
    'carrossées',
    'carrossent',
    'carrossera',
    'carrossier',
    'carrossiez',
    'carrossons',
    'carrousels',
    'carroyages',
    'carroyâmes',
    'carroyasse',
    'carroyâtes',
    'carroyions',
    'cartésiens',
    'cartilages',
    'cartonnage',
    'cartonnais',
    'cartonnait',
    'cartonnant',
    'cartonnées',
    'cartonnent',
    'cartonnera',
    'cartonneux',
    'cartonnier',
    'cartonniez',
    'cartonnons',
    'cartouches',
    'casanières',
    'casassions',
    'cascadâmes',
    'cascadasse',
    'cascadâtes',
    'cascaderai',
    'cascaderas',
    'cascaderez',
    'cascadeurs',
    'cascadeuse',
    'cascadions',
    'cascatelle',
    'caséfiâmes',
    'caséfiasse',
    'caséfiâtes',
    'caséfierai',
    'caséfieras',
    'caséfierez',
    'caséfiions',
    'casematais',
    'casematait',
    'casematant',
    'casematées',
    'casematent',
    'casematera',
    'casematiez',
    'casematons',
    'caseraient',
    'casernâmes',
    'casernasse',
    'casernâtes',
    'casernerai',
    'caserneras',
    'casernerez',
    'caserniers',
    'casernions',
    'cash-flows',
    'casquaient',
    'casquasses',
    'casquerais',
    'casquerait',
    'casquèrent',
    'casqueriez',
    'casquerons',
    'casqueront',
    'casquettes',
    'cassassent',
    'cassassiez',
    'cassations',
    'cassements',
    'casse-noix',
    'casse-pipe',
    'casserions',
    'casseroles',
    'casse-tête',
    'cassolette',
    'cassonades',
    'cassoulets',
    'castagnais',
    'castagnait',
    'castagnant',
    'castagnées',
    'castagnent',
    'castagnera',
    'castagniez',
    'castagnons',
    'castillane',
    'castillans',
    'castoréums',
    'castraient',
    'castrasses',
    'castrateur',
    'castration',
    'castrerais',
    'castrerait',
    'castrèrent',
    'castreriez',
    'castrerons',
    'castreront',
    'catachrèse',
    'cataclysme',
    'catacombes',
    'catafalque',
    'catalepsie',
    'catalognes',
    'cataloguai',
    'cataloguas',
    'cataloguât',
    'cataloguée',
    'cataloguer',
    'catalogues',
    'catalogués',
    'cataloguez',
    'catalysais',
    'catalysait',
    'catalysant',
    'catalysées',
    'catalysent',
    'catalysera',
    'catalyseur',
    'catalysiez',
    'catalysons',
    'catamarans',
    'cataphotes',
    'cataplasme',
    'catapultai',
    'catapultas',
    'catapultât',
    'catapultée',
    'catapulter',
    'catapultes',
    'catapultés',
    'catapultez',
    'cataractes',
    'catarrhale',
    'catarrhaux',
    'catarrheux',
    'catchaient',
    'catchasses',
    'catcherais',
    'catcherait',
    'catchèrent',
    'catcheriez',
    'catcherons',
    'catcheront',
    'catcheuses',
    'catéchisai',
    'catéchisas',
    'catéchisât',
    'catéchisée',
    'catéchiser',
    'catéchises',
    'catéchisés',
    'catéchisez',
    'catéchisme',
    'catéchiste',
    'catégorème',
    'catégoriel',
    'catégories',
    'caténaires',
    'cathédrale',
    'cathodique',
    'catholique',
    'catissages',
    'catoblépas',
    'caucasiens',
    'cauchemars',
    'caudataire',
    'caudrettes',
    'caulescent',
    'causalisme',
    'causalités',
    'causassent',
    'causassiez',
    'causatives',
    'causerions',
    'causticité',
    'caustiques',
    'cauteleuse',
    'cautérisai',
    'cautérisas',
    'cautérisât',
    'cautérisée',
    'cautériser',
    'cautérises',
    'cautérisés',
    'cautérisez',
    'cautionnai',
    'cautionnas',
    'cautionnât',
    'cautionnée',
    'cautionner',
    'cautionnes',
    'cautionnés',
    'cautionnez',
    'cavaillons',
    'cavalaient',
    'cavalasses',
    'cavalcadai',
    'cavalcadas',
    'cavalcadât',
    'cavalcader',
    'cavalcades',
    'cavalcadez',
    'cavalerais',
    'cavalerait',
    'cavalèrent',
    'cavaleries',
    'cavaleriez',
    'cavalerons',
    'cavaleront',
    'cavaleuses',
    'cavalières',
    'cavassions',
    'caveraient',
    'caverneuse',
    'caviardais',
    'caviardait',
    'caviardant',
    'caviardées',
    'caviardent',
    'caviardera',
    'caviardiez',
    'caviardons',
    'cavicornes',
    'cavitation',
    'cédassions',
    'céderaient',
    'cédétistes',
    'cédratiers',
    'cédulaires',
    'cégésimale',
    'cégésimaux',
    'cégétistes',
    'ceignaient',
    'ceignirent',
    'ceignisses',
    'ceindrions',
    'ceinturage',
    'ceinturais',
    'ceinturait',
    'ceinturant',
    'ceinturées',
    'ceinturent',
    'ceinturera',
    'ceinturiez',
    'ceinturons',
    'celassions',
    'célébrâmes',
    'célébrasse',
    'célébrâtes',
    'célébrerai',
    'célébreras',
    'célébrerez',
    'célébrions',
    'célébrités',
    'cèleraient',
    'célérifère',
    'cellérière',
    'cellériers',
    'cellophane',
    'cellulaire',
    'cellulites',
    'celluloïds',
    'celluloses',
    'cémentâmes',
    'cémentasse',
    'cémentâtes',
    'cémenterai',
    'cémenteras',
    'cémenterez',
    'cémentions',
    'cendraient',
    'cendrasses',
    'cendrerais',
    'cendrerait',
    'cendrèrent',
    'cendreriez',
    'cendrerons',
    'cendreront',
    'cendreuses',
    'cendrillon',
    'cénotaphes',
    'censitaire',
    'censoriale',
    'censoriaux',
    'censurâmes',
    'censurasse',
    'censurâtes',
    'censurerai',
    'censureras',
    'censurerez',
    'censurions',
    'centaurées',
    'centenaire',
    'centeniers',
    'centennale',
    'centennaux',
    'centésimal',
    'centigrade',
    'centilitre',
    'centimètre',
    'centraient',
    'centralisa',
    'centralise',
    'centralisé',
    'centrasses',
    'centrerais',
    'centrerait',
    'centrèrent',
    'centreriez',
    'centrerons',
    'centreront',
    'centrifuge',
    'centrifugé',
    'centripète',
    'centrismes',
    'centristes',
    'centrosome',
    'centuplais',
    'centuplait',
    'centuplant',
    'centuplées',
    'centuplent',
    'centuplera',
    'centupliez',
    'centuplons',
    'centurions',
    'céphalique',
    'céramiques',
    'céramistes',
    'cerclaient',
    'cerclasses',
    'cerclerais',
    'cerclerait',
    'cerclèrent',
    'cercleriez',
    'cerclerons',
    'cercleront',
    'céréaliers',
    'cérébrales',
    'cérémonial',
    'cérémoniel',
    'cérémonies',
    'cerisettes',
    'cernassent',
    'cernassiez',
    'cernerions',
    'certifiais',
    'certifiait',
    'certifiant',
    'certificat',
    'certifiées',
    'certifient',
    'certifiera',
    'certifiiez',
    'certifions',
    'certitudes',
    'céruléenne',
    'cérumineux',
    'cervicales',
    'césarienne',
    'césarismes',
    'cessassent',
    'cessassiez',
    'cessations',
    'cesserions',
    'chabichous',
    'chablaient',
    'chablasses',
    'chablerais',
    'chablerait',
    'chablèrent',
    'chableriez',
    'chablerons',
    'chableront',
    'chabraques',
    'chafouines',
    'chagrinais',
    'chagrinait',
    'chagrinant',
    'chagrinées',
    'chagrinent',
    'chagrinera',
    'chagriniez',
    'chagrinons',
    'chahutâmes',
    'chahutante',
    'chahutants',
    'chahutasse',
    'chahutâtes',
    'chahuterai',
    'chahuteras',
    'chahuterez',
    'chahuteurs',
    'chahuteuse',
    'chahutions',
    'chaînaient',
    'chaînasses',
    'chaînerais',
    'chaînerait',
    'chaînèrent',
    'chaîneriez',
    'chaînerons',
    'chaîneront',
    'chaînettes',
    'chaîneuses',
    'chaînistes',
    'chaisières',
    'chalazions',
    'chalcosine',
    'chaldéenne',
    'chaleureux',
    'challengea',
    'challengée',
    'challenger',
    'challenges',
    'challengés',
    'challengez',
    'chaloupais',
    'chaloupait',
    'chaloupant',
    'chaloupées',
    'chaloupent',
    'chaloupera',
    'chaloupiez',
    'chaloupons',
    'chalumeaux',
    'chalutages',
    'chalutiers',
    'chamaillai',
    'chamaillas',
    'chamaillât',
    'chamaillée',
    'chamailler',
    'chamailles',
    'chamaillés',
    'chamaillez',
    'chamanisme',
    'chamarrais',
    'chamarrait',
    'chamarrant',
    'chamarrées',
    'chamarrent',
    'chamarrera',
    'chamarriez',
    'chamarrons',
    'chamarrure',
    'chambardai',
    'chambardas',
    'chambardât',
    'chambardée',
    'chambarder',
    'chambardes',
    'chambardés',
    'chambardez',
    'chambellan',
    'chambertin',
    'chamboulai',
    'chamboulas',
    'chamboulât',
    'chamboulée',
    'chambouler',
    'chamboules',
    'chamboulés',
    'chamboulez',
    'chambrâmes',
    'chambranle',
    'chambrasse',
    'chambrâtes',
    'chambrerai',
    'chambreras',
    'chambrerez',
    'chambrette',
    'chambrière',
    'chambriers',
    'chambrions',
    'chameliers',
    'chamoisage',
    'chamoisais',
    'chamoisait',
    'chamoisant',
    'chamoisées',
    'chamoisent',
    'chamoisera',
    'chamoisiez',
    'chamoisons',
    'champagnes',
    'champenois',
    'champêtres',
    'champignon',
    'championne',
    'champlevai',
    'champlevas',
    'champlevât',
    'champlevée',
    'champlever',
    'champlevés',
    'champlèves',
    'champlevez',
    'chançardes',
    'chancelais',
    'chancelait',
    'chancelant',
    'chancelier',
    'chanceliez',
    'chancelles',
    'chancelons',
    'chanceuses',
    'chancirais',
    'chancirait',
    'chancirent',
    'chanciriez',
    'chancirons',
    'chanciront',
    'chancisses',
    'chancissez',
    'chandeleur',
    'chandelier',
    'chandelles',
    'chanfreina',
    'chanfreine',
    'chanfreiné',
    'chanfreins',
    'changeable',
    'changeâmes',
    'changeante',
    'changeants',
    'changeasse',
    'changeâtes',
    'changement',
    'changerais',
    'changerait',
    'changèrent',
    'changeriez',
    'changerons',
    'changeront',
    'chanlattes',
    'chansonnai',
    'chansonnas',
    'chansonnât',
    'chansonnée',
    'chansonner',
    'chansonnes',
    'chansonnés',
    'chansonnez',
    'chanstiqua',
    'chanstique',
    'chanstiqué',
    'chantaient',
    'chantantes',
    'chantasses',
    'chanterais',
    'chanterait',
    'chantèrent',
    'chanteriez',
    'chanterons',
    'chanteront',
    'chanteuses',
    'chantonnai',
    'chantonnas',
    'chantonnât',
    'chantonnée',
    'chantonner',
    'chantonnes',
    'chantonnés',
    'chantonnez',
    'chantoungs',
    'chantourna',
    'chantourne',
    'chantourné',
    'chanvrière',
    'chanvriers',
    'chaotiques',
    'chapardage',
    'chapardais',
    'chapardait',
    'chapardant',
    'chapardées',
    'chapardent',
    'chapardera',
    'chapardeur',
    'chapardiez',
    'chapardons',
    'chapeautai',
    'chapeautas',
    'chapeautât',
    'chapeautée',
    'chapeauter',
    'chapeautes',
    'chapeautés',
    'chapeautez',
    'chapelains',
    'chapelâmes',
    'chapelasse',
    'chapelâtes',
    'chapelière',
    'chapeliers',
    'chapelions',
    'chapellent',
    'chapellera',
    'chapelures',
    'chaperonna',
    'chaperonne',
    'chaperonné',
    'chapiteaux',
    'chapitrais',
    'chapitrait',
    'chapitrant',
    'chapitrées',
    'chapitrent',
    'chapitrera',
    'chapitriez',
    'chapitrons',
    'chaponnais',
    'chaponnait',
    'chaponnant',
    'chaponnées',
    'chaponnent',
    'chaponnera',
    'chaponniez',
    'chaponnons',
    'chaptalisa',
    'chaptalise',
    'chaptalisé',
    'charançons',
    'charbonnai',
    'charbonnas',
    'charbonnât',
    'charbonnée',
    'charbonner',
    'charbonnes',
    'charbonnés',
    'charbonnez',
    'charcutais',
    'charcutait',
    'charcutant',
    'charcutées',
    'charcutent',
    'charcutera',
    'charcutier',
    'charcutiez',
    'charcutons',
    'chargeâmes',
    'chargeasse',
    'chargeâtes',
    'chargement',
    'chargerais',
    'chargerait',
    'chargèrent',
    'chargeriez',
    'chargerons',
    'chargeront',
    'chariotage',
    'charitable',
    'charivaris',
    'charlatans',
    'charleston',
    'charlottes',
    'charmaient',
    'charmantes',
    'charmasses',
    'charmerais',
    'charmerait',
    'charmèrent',
    'charmeriez',
    'charmerons',
    'charmeront',
    'charmeuses',
    'charmilles',
    'charnelles',
    'charnières',
    'charognard',
    'charpentai',
    'charpentas',
    'charpentât',
    'charpentée',
    'charpenter',
    'charpentes',
    'charpentés',
    'charpentez',
    'charretées',
    'charretier',
    'charretins',
    'charretons',
    'charrettes',
    'charriages',
    'charriâmes',
    'charriasse',
    'charriâtes',
    'charrierai',
    'charrieras',
    'charrierez',
    'charriions',
    'charroient',
    'charroiera',
    'charroyais',
    'charroyait',
    'charroyant',
    'charroyées',
    'charroyiez',
    'charroyons',
    'charruages',
    'chartismes',
    'chartistes',
    'chartreuse',
    'chartriers',
    'chassaient',
    'chassasses',
    'chassepots',
    'chasserais',
    'chasserait',
    'chassèrent',
    'chasseriez',
    'chasserons',
    'chasseront',
    'chasseuses',
    'chassieuse',
    'chastement',
    'châtaigner',
    'châtaignes',
    'châtelaine',
    'châtelains',
    'chat-huant',
    'châtiaient',
    'châtiasses',
    'châtierais',
    'châtierait',
    'châtièrent',
    'châtieriez',
    'châtierons',
    'châtieront',
    'châtiments',
    'chatoierai',
    'chatoieras',
    'chatoierez',
    'chatonnais',
    'chatonnait',
    'chatonnant',
    'chatonnent',
    'chatonnera',
    'chatonniez',
    'chatonnons',
    'chatouilla',
    'chatouille',
    'chatouillé',
    'chatoyâmes',
    'chatoyasse',
    'chatoyâtes',
    'chatoyions',
    'châtraient',
    'châtrasses',
    'châtrerais',
    'châtrerait',
    'châtrèrent',
    'châtreriez',
    'châtrerons',
    'châtreront',
    'chattemite',
    'chatteries',
    'chatterton',
    'chaudement',
    'chaudières',
    'chauffages',
    'chauffâmes',
    'chauffante',
    'chauffants',
    'chauffards',
    'chauffasse',
    'chauffâtes',
    'chaufferai',
    'chaufferas',
    'chaufferez',
    'chaufferie',
    'chauffeurs',
    'chauffeuse',
    'chauffions',
    'chaulaient',
    'chaulasses',
    'chaulerais',
    'chaulerait',
    'chaulèrent',
    'chauleriez',
    'chaulerons',
    'chauleront',
    'chauleuses',
    'chaumaient',
    'chaumasses',
    'chaumerais',
    'chaumerait',
    'chaumèrent',
    'chaumeriez',
    'chaumerons',
    'chaumeront',
    'chaumières',
    'chaussâmes',
    'chaussante',
    'chaussants',
    'chaussasse',
    'chaussâtes',
    'chausserai',
    'chausseras',
    'chausserez',
    'chaussette',
    'chausseurs',
    'chaussions',
    'chaussures',
    'chauvirais',
    'chauvirait',
    'chauvirent',
    'chauviriez',
    'chauvirons',
    'chauviront',
    'chauvisses',
    'chauvissez',
    'chavirâmes',
    'chavirasse',
    'chavirâtes',
    'chavirerai',
    'chavireras',
    'chavirerez',
    'chavirions',
    'check-list',
    'chefferies',
    'cheftaines',
    'chélicères',
    'chélidoine',
    'chelinguai',
    'chelinguas',
    'chelinguât',
    'chelinguée',
    'chelinguer',
    'chelingues',
    'chelingués',
    'chelinguez',
    'chelléenne',
    'cheminâmes',
    'cheminasse',
    'cheminâtes',
    'cheminerai',
    'chemineras',
    'cheminerez',
    'cheminions',
    'chemisages',
    'chemisâmes',
    'chemisasse',
    'chemisâtes',
    'chemiserai',
    'chemiseras',
    'chemiserez',
    'chemiserie',
    'chemisette',
    'chemisière',
    'chemisiers',
    'chemisions',
    'chènevière',
    'chenillées',
    'chénopodes',
    'chérassent',
    'chérassiez',
    'cherchâmes',
    'cherchasse',
    'cherchâtes',
    'chercherai',
    'chercheras',
    'chercherez',
    'chercheurs',
    'chercheuse',
    'cherchions',
    'chérerions',
    'chérifiens',
    'chéririons',
    'chérissais',
    'chérissait',
    'chérissant',
    'chérissent',
    'chérissiez',
    'chérissons',
    'cherraient',
    'cherrasses',
    'cherrerais',
    'cherrerait',
    'cherrèrent',
    'cherreriez',
    'cherrerons',
    'cherreront',
    'chevalâmes',
    'chevalasse',
    'chevalâtes',
    'chevalerai',
    'chevaleras',
    'chevalerez',
    'chevalerie',
    'chevalière',
    'chevaliers',
    'chevalines',
    'chevalions',
    'chevauchai',
    'chevauchas',
    'chevauchât',
    'chevauchée',
    'chevaucher',
    'chevauches',
    'chevauchés',
    'chevauchez',
    'chevelures',
    'chevillais',
    'chevillait',
    'chevillant',
    'chevillard',
    'chevillées',
    'chevillent',
    'chevillera',
    'chevilliez',
    'chevillons',
    'cheviottes',
    'chevretais',
    'chevretait',
    'chevretant',
    'chevretiez',
    'chevretons',
    'chevrettes',
    'chevreuils',
    'chevrières',
    'chevronnai',
    'chevronnas',
    'chevronnât',
    'chevronnée',
    'chevronner',
    'chevronnes',
    'chevronnés',
    'chevronnez',
    'chevrotais',
    'chevrotait',
    'chevrotant',
    'chevrotent',
    'chevrotera',
    'chevrotiez',
    'chevrotine',
    'chevrotins',
    'chevrotons',
    'chiadaient',
    'chiadasses',
    'chiaderais',
    'chiaderait',
    'chiadèrent',
    'chiaderiez',
    'chiaderons',
    'chiaderont',
    'chialaient',
    'chialasses',
    'chialerais',
    'chialerait',
    'chialèrent',
    'chialeriez',
    'chialerons',
    'chialeront',
    'chiassions',
    'chibouques',
    'chicanâmes',
    'chicanasse',
    'chicanâtes',
    'chicanerai',
    'chicaneras',
    'chicanerez',
    'chicanerie',
    'chicaneurs',
    'chicaneuse',
    'chicanière',
    'chicaniers',
    'chicanions',
    'chichement',
    'chichiteux',
    'chicotâmes',
    'chicotasse',
    'chicotâtes',
    'chicoterai',
    'chicoteras',
    'chicoterez',
    'chicotions',
    'chiendents',
    'chien-loup',
    'chiennâmes',
    'chiennasse',
    'chiennâtes',
    'chiennerai',
    'chienneras',
    'chiennerez',
    'chiennerie',
    'chiennions',
    'chieraient',
    'chiffonnai',
    'chiffonnas',
    'chiffonnât',
    'chiffonnée',
    'chiffonner',
    'chiffonnes',
    'chiffonnés',
    'chiffonnez',
    'chiffrable',
    'chiffrages',
    'chiffrâmes',
    'chiffrasse',
    'chiffrâtes',
    'chiffrerai',
    'chiffreras',
    'chiffrerez',
    'chiffreurs',
    'chiffreuse',
    'chiffriers',
    'chiffrions',
    'chiliennes',
    'chimérique',
    'chimpanzés',
    'chinassent',
    'chinassiez',
    'chinchilla',
    'chinerions',
    'chinoisais',
    'chinoisait',
    'chinoisant',
    'chinoisent',
    'chinoisera',
    'chinoisiez',
    'chinoisons',
    'chipassent',
    'chipassiez',
    'chiperions',
    'chipolatas',
    'chipotages',
    'chipotâmes',
    'chipotasse',
    'chipotâtes',
    'chipoterai',
    'chipoteras',
    'chipoterez',
    'chipoteurs',
    'chipoteuse',
    'chipotions',
    'chiquaient',
    'chiquasses',
    'chiquement',
    'chiquerais',
    'chiquerait',
    'chiquèrent',
    'chiqueriez',
    'chiquerons',
    'chiqueront',
    'chiqueuses',
    'chirurgien',
    'chirurgies',
    'chitineuse',
    'chlinguais',
    'chlinguait',
    'chlinguant',
    'chlinguées',
    'chlinguent',
    'chlinguera',
    'chlinguiez',
    'chlinguons',
    'chloraient',
    'chlorasses',
    'chlorerais',
    'chlorerait',
    'chlorèrent',
    'chloreriez',
    'chlorerons',
    'chloreront',
    'chloriques',
    'chlorurais',
    'chlorurait',
    'chlorurant',
    'chlorurées',
    'chlorurent',
    'chlorurera',
    'chloruriez',
    'chlorurons',
    'chochottes',
    'chocolatée',
    'chocolatés',
    'choierions',
    'choisirais',
    'choisirait',
    'choisirent',
    'choisiriez',
    'choisirons',
    'choisiront',
    'choisisses',
    'choisissez',
    'choisit-il',
    'cholagogue',
    'cholédoque',
    'cholérines',
    'cholérique',
    'chômassent',
    'chômassiez',
    'chômerions',
    'chondriome',
    'chopassent',
    'chopassiez',
    'choperions',
    'chopinâmes',
    'chopinasse',
    'chopinâtes',
    'chopinerai',
    'chopineras',
    'chopinerez',
    'chopinions',
    'choppaient',
    'choppasses',
    'chopperais',
    'chopperait',
    'choppèrent',
    'chopperiez',
    'chopperons',
    'chopperont',
    'choquaient',
    'choquantes',
    'choquasses',
    'choquerais',
    'choquerait',
    'choquèrent',
    'choqueriez',
    'choquerons',
    'choqueront',
    'choréiques',
    'choroïdien',
    'chosifiais',
    'chosifiait',
    'chosifiant',
    'chosifiées',
    'chosifient',
    'chosifiera',
    'chosifiiez',
    'chosifions',
    'chouchouta',
    'chouchoute',
    'chouchouté',
    'choucroute',
    'chou-fleur',
    'chou-navet',
    'chouravais',
    'chouravait',
    'chouravant',
    'chouravées',
    'chouravent',
    'chouravera',
    'chouraviez',
    'chouravons',
    'chourinais',
    'chourinait',
    'chourinant',
    'chourinées',
    'chourinent',
    'chourinera',
    'chouriniez',
    'chourinons',
    'choyassent',
    'choyassiez',
    'chrétienne',
    'chrétienté',
    'christique',
    'chromaient',
    'chromasses',
    'chromatine',
    'chromerais',
    'chromerait',
    'chromèrent',
    'chromeriez',
    'chromerons',
    'chromeront',
    'chromiques',
    'chromistes',
    'chromogène',
    'chromosome',
    'chronaxies',
    'chronicité',
    'chroniquai',
    'chroniquas',
    'chroniquât',
    'chroniquer',
    'chroniques',
    'chroniquez',
    'chroumâmes',
    'chroumasse',
    'chroumâtes',
    'chroumions',
    'chrysalide',
    'chrysocale',
    'chrysomèle',
    'chthoniens',
    'chuchotais',
    'chuchotait',
    'chuchotant',
    'chuchotées',
    'chuchotent',
    'chuchotera',
    'chuchotiez',
    'chuchotons',
    'chuintâmes',
    'chuintasse',
    'chuintâtes',
    'chuinterai',
    'chuinteras',
    'chuinterez',
    'chuintions',
    'chutassent',
    'chutassiez',
    'chuterions',
    'chylifères',
    'ciboulette',
    'cicatrices',
    'cicatrisai',
    'cicatrisas',
    'cicatrisât',
    'cicatrisée',
    'cicatriser',
    'cicatrises',
    'cicatrisés',
    'cicatrisez',
    'cicéronien',
    'cicindèles',
    'ci-dessous',
    'cigarettes',
    'cigarières',
    'cigarillos',
    'cigogneaux',
    'ci-incluse',
    'ci-jointes',
    'cillassent',
    'cillassiez',
    'cillements',
    'cillerions',
    'cimentâmes',
    'cimentasse',
    'cimentâtes',
    'cimenterai',
    'cimenteras',
    'cimenterez',
    'cimenterie',
    'cimentiers',
    'cimentions',
    'cimeterres',
    'cimetières',
    'cinchonine',
    'ciné-clubs',
    'cinéphiles',
    'cinéraires',
    'ciné-roman',
    'cinétiques',
    'cingalaise',
    'cinglaient',
    'cinglantes',
    'cinglasses',
    'cinglerais',
    'cinglerait',
    'cinglèrent',
    'cingleriez',
    'cinglerons',
    'cingleront',
    'cinnamomes',
    'cinquièmes',
    'cintraient',
    'cintrasses',
    'cintrerais',
    'cintrerait',
    'cintrèrent',
    'cintreriez',
    'cintrerons',
    'cintreront',
    'cirassions',
    'circadiens',
    'circoncira',
    'circoncire',
    'circonvenu',
    'circonvins',
    'circonvint',
    'circonvînt',
    'circulaire',
    'circulâmes',
    'circulasse',
    'circulâtes',
    'circulerai',
    'circuleras',
    'circulerez',
    'circulions',
    'cireraient',
    'cisaillais',
    'cisaillait',
    'cisaillant',
    'cisaillées',
    'cisaillent',
    'cisaillera',
    'cisailliez',
    'cisaillons',
    'ciselaient',
    'ciselasses',
    'cisèlement',
    'cisélerais',
    'cisélerait',
    'ciselèrent',
    'ciséleriez',
    'cisélerons',
    'ciséleront',
    'cistercien',
    'citadelles',
    'citassions',
    'citatteurs',
    'citattrice',
    'citeraient',
    'cithariste',
    'citoyennes',
    'citronnade',
    'citronnées',
    'citronnier',
    'citrouille',
    'civilement',
    'civilisais',
    'civilisait',
    'civilisant',
    'civilisées',
    'civilisent',
    'civilisera',
    'civilisiez',
    'civilisons',
    'civilistes',
    'clabaudage',
    'clabaudais',
    'clabaudait',
    'clabaudant',
    'clabaudent',
    'clabaudera',
    'clabaudeur',
    'clabaudiez',
    'clabaudons',
    'clabotages',
    'clabotâmes',
    'clabotasse',
    'clabotâtes',
    'claboterai',
    'claboteras',
    'claboterez',
    'clabotions',
    'clairances',
    'clairement',
    'clairettes',
    'clairières',
    'claironnai',
    'claironnas',
    'claironnât',
    'claironnée',
    'claironner',
    'claironnes',
    'claironnés',
    'claironnez',
    'clairsemée',
    'clairsemés',
    'clamassent',
    'clamassiez',
    'clamerions',
    'clampaient',
    'clampasses',
    'clamperais',
    'clamperait',
    'clampèrent',
    'clamperiez',
    'clamperons',
    'clamperont',
    'clampsâmes',
    'clampsasse',
    'clampsâtes',
    'clampserai',
    'clampseras',
    'clampserez',
    'clampsions',
    'clamsaient',
    'clamsasses',
    'clamserais',
    'clamserait',
    'clamsèrent',
    'clamseriez',
    'clamserons',
    'clamseront',
    'clandestin',
    'clapassent',
    'clapassiez',
    'claperions',
    'clapirions',
    'clapissais',
    'clapissait',
    'clapissant',
    'clapissent',
    'clapissiez',
    'clapissons',
    'clapotages',
    'clapotâmes',
    'clapotante',
    'clapotants',
    'clapotasse',
    'clapotâtes',
    'clapoterai',
    'clapoteras',
    'clapoterez',
    'clapotions',
    'clappaient',
    'clappasses',
    'clappement',
    'clapperais',
    'clapperait',
    'clappèrent',
    'clapperiez',
    'clapperons',
    'clapperont',
    'clapsaient',
    'clapsasses',
    'clapserais',
    'clapserait',
    'clapsèrent',
    'clapseriez',
    'clapserons',
    'clapseront',
    'claquaient',
    'claquantes',
    'claquasses',
    'claquement',
    'claquemura',
    'claquemure',
    'claquemuré',
    'claquerais',
    'claquerait',
    'claquèrent',
    'claqueriez',
    'claquerons',
    'claqueront',
    'claquetais',
    'claquetait',
    'claquetant',
    'claquetiez',
    'claquetons',
    'claquettes',
    'clarifiais',
    'clarifiait',
    'clarifiant',
    'clarifiées',
    'clarifient',
    'clarifiera',
    'clarifiiez',
    'clarifions',
    'clarinette',
    'classables',
    'classaient',
    'classasses',
    'classement',
    'classerais',
    'classerait',
    'classèrent',
    'classeriez',
    'classerons',
    'classeront',
    'classifiai',
    'classifias',
    'classifiât',
    'classifiée',
    'classifier',
    'classifies',
    'classifiés',
    'classifiez',
    'classiques',
    'clastiques',
    'claudicant',
    'claudiquai',
    'claudiquas',
    'claudiquât',
    'claudiquer',
    'claudiques',
    'claudiquez',
    'claustrais',
    'claustrait',
    'claustrale',
    'claustrant',
    'claustraux',
    'claustrées',
    'claustrent',
    'claustrera',
    'claustriez',
    'claustrons',
    'clavassent',
    'clavassiez',
    'claverions',
    'clavetages',
    'clavetâmes',
    'clavetasse',
    'clavetâtes',
    'clavetions',
    'clavettais',
    'clavettait',
    'clavettant',
    'clavettées',
    'clavettent',
    'clavettera',
    'clavettiez',
    'clavettons',
    'clavicorde',
    'clavicules',
    'clayonnage',
    'clayonnais',
    'clayonnait',
    'clayonnant',
    'clayonnées',
    'clayonnent',
    'clayonnera',
    'clayonniez',
    'clayonnons',
    'clématites',
    'clémentine',
    'clepsydres',
    'cleptomane',
    'cléricales',
    'clichaient',
    'clichasses',
    'clicherais',
    'clicherait',
    'clichèrent',
    'clicheries',
    'clicheriez',
    'clicherons',
    'clicheront',
    'clientèles',
    'clignaient',
    'clignasses',
    'clignement',
    'clignerais',
    'clignerait',
    'clignèrent',
    'cligneriez',
    'clignerons',
    'cligneront',
    'clignotais',
    'clignotait',
    'clignotant',
    'clignotent',
    'clignotera',
    'clignotiez',
    'clignotons',
    'climatères',
    'climatique',
    'climatisai',
    'climatisas',
    'climatisât',
    'climatisée',
    'climatiser',
    'climatises',
    'climatisés',
    'climatisez',
    'climatisme',
    'cliniciens',
    'clinomètre',
    'clinquante',
    'clinquants',
    'cliquetais',
    'cliquetait',
    'cliquetant',
    'cliquetiez',
    'cliquetons',
    'cliquettes',
    'clissaient',
    'clissasses',
    'clisserais',
    'clisserait',
    'clissèrent',
    'clisseriez',
    'clisserons',
    'clisseront',
    'clivassent',
    'clivassiez',
    'cliverions',
    'clochaient',
    'clochardes',
    'clochasses',
    'clocherais',
    'clocherait',
    'clochèrent',
    'clocheriez',
    'clocherons',
    'clocheront',
    'clochetons',
    'clochettes',
    'cloisonnai',
    'cloisonnas',
    'cloisonnât',
    'cloisonnée',
    'cloisonner',
    'cloisonnes',
    'cloisonnés',
    'cloisonnez',
    'cloîtrâmes',
    'cloîtrasse',
    'cloîtrâtes',
    'cloîtrerai',
    'cloîtreras',
    'cloîtrerez',
    'cloîtrions',
    'clopinâmes',
    'clopinasse',
    'clopinâtes',
    'clopinerai',
    'clopineras',
    'clopinerez',
    'clopinions',
    'cloquaient',
    'cloquasses',
    'cloquerais',
    'cloquerait',
    'cloquèrent',
    'cloqueriez',
    'cloquerons',
    'cloqueront',
    'clôturâmes',
    'clôturasse',
    'clôturâtes',
    'clôturerai',
    'clôtureras',
    'clôturerez',
    'clôturions',
    'clouassent',
    'clouassiez',
    'clouerions',
    'cloutaient',
    'cloutasses',
    'clouterais',
    'clouterait',
    'cloutèrent',
    'clouteries',
    'clouteriez',
    'clouterons',
    'clouteront',
    'clowneries',
    'clownesque',
    'clunisiens',
    'coaccusées',
    'coadjuteur',
    'coagulable',
    'coagulâmes',
    'coagulante',
    'coagulants',
    'coagulasse',
    'coagulâtes',
    'coagulerai',
    'coaguleras',
    'coagulerez',
    'coagulions',
    'coalisâmes',
    'coalisasse',
    'coalisâtes',
    'coaliserai',
    'coaliseras',
    'coaliserez',
    'coalisions',
    'coalitions',
    'coaptation',
    'coassaient',
    'coassasses',
    'coassement',
    'coasserais',
    'coasserait',
    'coassèrent',
    'coasseriez',
    'coasserons',
    'coasseront',
    'coassociée',
    'coassociés',
    'cocardière',
    'cocardiers',
    'cocasserie',
    'coccidiose',
    'coccinelle',
    'coccygiens',
    'cochassent',
    'côchassent',
    'cochassiez',
    'côchassiez',
    'cochenille',
    'cocherions',
    'côcherions',
    'cochléaire',
    'cochléaria',
    'cochonceté',
    'cochonnais',
    'cochonnait',
    'cochonnant',
    'cochonnées',
    'cochonnent',
    'cochonnera',
    'cochonnets',
    'cochonniez',
    'cochonnons',
    'cocufiâmes',
    'cocufiasse',
    'cocufiâtes',
    'cocufierai',
    'cocufieras',
    'cocufierez',
    'cocufiions',
    'cocyclique',
    'codassions',
    'codébiteur',
    'coderaient',
    'codétenues',
    'codicilles',
    'codifiâmes',
    'codifiasse',
    'codifiâtes',
    'codifierai',
    'codifieras',
    'codifierez',
    'codifiions',
    'coéditeurs',
    'coéditions',
    'coéditrice',
    'coeliaques',
    'coéquipier',
    'coercibles',
    'coercitifs',
    'coercition',
    'coercitive',
    'coexistais',
    'coexistait',
    'coexistant',
    'coexistent',
    'coexistera',
    'coexistiez',
    'coexistons',
    'coextensif',
    'coffraient',
    'coffrasses',
    'coffrerais',
    'coffrerait',
    'coffrèrent',
    'coffreriez',
    'coffrerons',
    'coffreront',
    'cogérances',
    'cogérantes',
    'cogestions',
    'cogitaient',
    'cogitantes',
    'cogitasses',
    'cogitation',
    'cogiterais',
    'cogiterait',
    'cogitèrent',
    'cogiteriez',
    'cogiterons',
    'cogiteront',
    'cognassent',
    'cognassier',
    'cognassiez',
    'cognations',
    'cognements',
    'cognerions',
    'cognitives',
    'cohabitais',
    'cohabitait',
    'cohabitant',
    'cohabitent',
    'cohabitera',
    'cohabitiez',
    'cohabitons',
    'cohérences',
    'cohérentes',
    'cohéritais',
    'cohéritait',
    'cohéritant',
    'cohéritent',
    'cohéritera',
    'cohéritier',
    'cohéritiez',
    'cohéritons',
    'coiffaient',
    'coiffantes',
    'coiffasses',
    'coifferais',
    'coifferait',
    'coiffèrent',
    'coifferiez',
    'coifferons',
    'coifferont',
    'coiffeuses',
    'coinçaient',
    'coinçasses',
    'coincement',
    'coincerais',
    'coincerait',
    'coincèrent',
    'coinceriez',
    'coincerons',
    'coinceront',
    'coïncidais',
    'coïncidait',
    'coïncidant',
    'coïncident',
    'coïncidera',
    'coïncidiez',
    'coïncidons',
    'coïnculpée',
    'coïnculpés',
    'coïtassent',
    'coïtassiez',
    'coïterions',
    'cokéfiâmes',
    'cokéfiante',
    'cokéfiants',
    'cokéfiasse',
    'cokéfiâtes',
    'cokéfierai',
    'cokéfieras',
    'cokéfierez',
    'cokéfiions',
    'colchicine',
    'colchiques',
    'cold-cream',
    'coléoptère',
    'coléreuses',
    'colériques',
    'colicitant',
    'colifichet',
    'colimaçons',
    'colistière',
    'colistiers',
    'collaborai',
    'collaboras',
    'collaborât',
    'collaborer',
    'collabores',
    'collaborez',
    'collagènes',
    'collassent',
    'collassiez',
    'collatéral',
    'collateurs',
    'collations',
    'collectage',
    'collectais',
    'collectait',
    'collectant',
    'collectées',
    'collectent',
    'collectera',
    'collecteur',
    'collectiez',
    'collectifs',
    'collection',
    'collective',
    'collectons',
    'collégiale',
    'collégiaux',
    'collégiens',
    'collerette',
    'collerions',
    'colletâmes',
    'colletasse',
    'colletâtes',
    'colleteurs',
    'colletions',
    'collettent',
    'collettera',
    'colligeais',
    'colligeait',
    'colligeant',
    'colligeons',
    'colligerai',
    'colligeras',
    'colligerez',
    'colligions',
    'collisions',
    'collodions',
    'colloïdale',
    'colloïdaux',
    'colloquais',
    'colloquait',
    'colloquant',
    'colloquées',
    'colloquent',
    'colloquera',
    'colloquiez',
    'colloquons',
    'collusions',
    'collusoire',
    'collutoire',
    'colluvions',
    'colmatages',
    'colmatâmes',
    'colmatasse',
    'colmatâtes',
    'colmaterai',
    'colmateras',
    'colmaterez',
    'colmations',
    'colombages',
    'colombiers',
    'colombines',
    'colonelles',
    'coloniales',
    'colonisais',
    'colonisait',
    'colonisant',
    'colonisées',
    'colonisent',
    'colonisera',
    'colonisiez',
    'colonisons',
    'colonnades',
    'colonnette',
    'colophanes',
    'coloquinte',
    'coloraient',
    'colorantes',
    'colorasses',
    'coloration',
    'colorerais',
    'colorerait',
    'colorèrent',
    'coloreriez',
    'colorerons',
    'coloreront',
    'coloriages',
    'coloriâmes',
    'coloriasse',
    'coloriâtes',
    'colorierai',
    'colorieras',
    'colorierez',
    'coloriions',
    'coloristes',
    'colossales',
    'colostrums',
    'colportage',
    'colportais',
    'colportait',
    'colportant',
    'colportées',
    'colportent',
    'colportera',
    'colporteur',
    'colportiez',
    'colportons',
    'coltinages',
    'coltinâmes',
    'coltinasse',
    'coltinâtes',
    'coltinerai',
    'coltineras',
    'coltinerez',
    'coltinions',
    'columelles',
    'colzatiers',
    'comateuses',
    'combatives',
    'combattais',
    'combattait',
    'combattant',
    'combattent',
    'combattiez',
    'combattons',
    'combattrai',
    'combattras',
    'combattrez',
    'combattues',
    'combinable',
    'combinâmes',
    'combinarde',
    'combinards',
    'combinasse',
    'combinâtes',
    'combinerai',
    'combineras',
    'combinerez',
    'combinions',
    'comblaient',
    'comblasses',
    'comblement',
    'comblerais',
    'comblerait',
    'comblèrent',
    'combleriez',
    'comblerons',
    'combleront',
    'comburante',
    'comburants',
    'combustion',
    'comédienne',
    'comestible',
    'cométaires',
    'commandais',
    'commandait',
    'commandant',
    'commandées',
    'commandent',
    'commandera',
    'commandeur',
    'commandiez',
    'commandita',
    'commandite',
    'commandité',
    'commandons',
    'commémorai',
    'commémoras',
    'commémorât',
    'commémorée',
    'commémorer',
    'commémores',
    'commémorés',
    'commémorez',
    'commençais',
    'commençait',
    'commençant',
    'commencées',
    'commencent',
    'commencera',
    'commenciez',
    'commençons',
    'commensale',
    'commensaux',
    'commentais',
    'commentait',
    'commentant',
    'commentées',
    'commentent',
    'commentera',
    'commentiez',
    'commentons',
    'commérages',
    'commérâmes',
    'commérasse',
    'commérâtes',
    'commerçais',
    'commerçait',
    'commerçant',
    'commercent',
    'commercera',
    'commercial',
    'commerciez',
    'commerçons',
    'commérerai',
    'comméreras',
    'commérerez',
    'commérions',
    'commettais',
    'commettait',
    'commettant',
    'commettent',
    'commettiez',
    'commettons',
    'commettrai',
    'commettras',
    'commettrez',
    'comminutif',
    'commissent',
    'commissiez',
    'commission',
    'commissure',
    'commodités',
    'commodores',
    'commotions',
    'commuables',
    'commuaient',
    'commuasses',
    'commuerais',
    'commuerait',
    'commuèrent',
    'commueriez',
    'commuerons',
    'commueront',
    'communales',
    'communarde',
    'communards',
    'communauté',
    'communiais',
    'communiait',
    'communiant',
    'communient',
    'communiera',
    'communiiez',
    'communions',
    'communiqua',
    'communique',
    'communiqué',
    'communisme',
    'communiste',
    'commutable',
    'commutâmes',
    'commutasse',
    'commutâtes',
    'commutatif',
    'commuterai',
    'commuteras',
    'commuterez',
    'commutions',
    'compacités',
    'compactage',
    'compagnies',
    'compagnons',
    'comparable',
    'comparâmes',
    'comparasse',
    'comparâtes',
    'comparatif',
    'comparerai',
    'compareras',
    'comparerez',
    'comparions',
    'comparûmes',
    'comparusse',
    'comparûtes',
    'compassais',
    'compassait',
    'compassant',
    'compassées',
    'compassent',
    'compassera',
    'compassiez',
    'compassion',
    'compassons',
    'compatible',
    'compatîmes',
    'compatirai',
    'compatiras',
    'compatirez',
    'compatisse',
    'compatîtes',
    'compendium',
    'compensais',
    'compensait',
    'compensant',
    'compensées',
    'compensent',
    'compensera',
    'compensiez',
    'compensons',
    'compérages',
    'compétâmes',
    'compétasse',
    'compétâtes',
    'compétence',
    'compétente',
    'compétents',
    'compéterai',
    'compéteras',
    'compéterez',
    'compétions',
    'compétitif',
    'compilâmes',
    'compilasse',
    'compilâtes',
    'compilerai',
    'compileras',
    'compilerez',
    'compilions',
    'compissais',
    'compissait',
    'compissant',
    'compissées',
    'compissent',
    'compissera',
    'compissiez',
    'compissons',
    'complainte',
    'complairai',
    'complairas',
    'complairez',
    'complaises',
    'complaisez',
    'complément',
    'complétais',
    'complétait',
    'complétant',
    'complétées',
    'complètent',
    'complétera',
    'complétiez',
    'complétifs',
    'complétion',
    'complétive',
    'complétons',
    'complexais',
    'complexait',
    'complexant',
    'complexées',
    'complexent',
    'complexera',
    'complexiez',
    'complexion',
    'complexité',
    'complexons',
    'complicité',
    'compliment',
    'compliquai',
    'compliquas',
    'compliquât',
    'compliquée',
    'compliquer',
    'compliques',
    'compliqués',
    'compliquez',
    'complotais',
    'complotait',
    'complotant',
    'complotées',
    'complotent',
    'comploteur',
    'complotiez',
    'complotons',
    'complurent',
    'complusses',
    'comportais',
    'comportait',
    'comportant',
    'comportées',
    'comportent',
    'comportera',
    'comportiez',
    'comportons',
    'composâmes',
    'composante',
    'composants',
    'composasse',
    'composâtes',
    'composerai',
    'composeras',
    'composerât',
    'composeurs',
    'composeuse',
    'composions',
    'composites',
    'compostage',
    'compostais',
    'compostait',
    'compostant',
    'compostées',
    'compostent',
    'compostera',
    'composteur',
    'compostiez',
    'compostons',
    'compotiers',
    'comprenais',
    'comprenait',
    'comprenant',
    'comprendra',
    'comprendre',
    'compreniez',
    'comprennes',
    'comprenons',
    'compressai',
    'compressas',
    'compressât',
    'compressée',
    'compresser',
    'compresses',
    'compressés',
    'compressez',
    'compressif',
    'comprimais',
    'comprimait',
    'comprimant',
    'comprimées',
    'compriment',
    'comprimera',
    'comprimiez',
    'comprimons',
    'comprirent',
    'comprisses',
    'compromets',
    'compromise',
    'comptables',
    'comptaient',
    'comptasses',
    'compterais',
    'compterait',
    'comptèrent',
    'compteriez',
    'compterons',
    'compteront',
    'compulsais',
    'compulsait',
    'compulsant',
    'compulsées',
    'compulsent',
    'compulsera',
    'compulsiez',
    'compulsifs',
    'compulsion',
    'compulsive',
    'compulsons',
    'computâmes',
    'computasse',
    'computâtes',
    'computerai',
    'computeras',
    'computerez',
    'computions',
    'concassage',
    'concassais',
    'concassait',
    'concassant',
    'concassées',
    'concassent',
    'concassera',
    'concasseur',
    'concassiez',
    'concassons',
    'concaténai',
    'concaténas',
    'concaténât',
    'concaténée',
    'concaténer',
    'concaténés',
    'concatènes',
    'concaténez',
    'concavités',
    'concédâmes',
    'concédasse',
    'concédâtes',
    'concéderai',
    'concéderas',
    'concéderez',
    'concédions',
    'concélébra',
    'concélébré',
    'concélèbre',
    'concentrai',
    'concentras',
    'concentrât',
    'concentrée',
    'concentrer',
    'concentres',
    'concentrés',
    'concentrez',
    'concepteur',
    'conception',
    'conceptuel',
    'concernais',
    'concernait',
    'concernant',
    'concernées',
    'concernent',
    'concernera',
    'concerniez',
    'concernons',
    'concertais',
    'concertait',
    'concertant',
    'concertées',
    'concertent',
    'concertera',
    'concertiez',
    'concertino',
    'concertons',
    'concessifs',
    'concession',
    'concessive',
    'concevable',
    'concevions',
    'concevrais',
    'concevrait',
    'concevriez',
    'concevrons',
    'concevront',
    'conchoïdal',
    'conchoïdes',
    'conchylien',
    'concierges',
    'conciliais',
    'conciliait',
    'conciliant',
    'conciliées',
    'concilient',
    'conciliera',
    'conciliiez',
    'concilions',
    'concisions',
    'concitoyen',
    'concluante',
    'concluants',
    'concluions',
    'conclurais',
    'conclurait',
    'conclurent',
    'concluriez',
    'conclurons',
    'concluront',
    'conclusion',
    'conclusses',
    'concoctais',
    'concoctait',
    'concoctant',
    'concoctées',
    'concoctent',
    'concoctera',
    'concoctiez',
    'concoctons',
    'conçoivent',
    'concombres',
    'concordais',
    'concordait',
    'concordant',
    'concordats',
    'concordent',
    'concordera',
    'concordiez',
    'concordons',
    'concourais',
    'concourait',
    'concourant',
    'concourent',
    'concouriez',
    'concourons',
    'concourrai',
    'concourras',
    'concourrez',
    'concourues',
    'concrétais',
    'concrétait',
    'concrétant',
    'concrétées',
    'concrètent',
    'concrétera',
    'concrétiez',
    'concrétion',
    'concrétisa',
    'concrétise',
    'concrétisé',
    'concrétons',
    'concubines',
    'concurrent',
    'conçussent',
    'conçussiez',
    'concussion',
    'condamnais',
    'condamnait',
    'condamnant',
    'condamnées',
    'condamnent',
    'condamnera',
    'condamniez',
    'condamnons',
    'condensais',
    'condensait',
    'condensant',
    'condensées',
    'condensent',
    'condensera',
    'condenseur',
    'condensiez',
    'condensons',
    'condescend',
    'condiments',
    'conditions',
    'conducteur',
    'conduction',
    'conduirais',
    'conduirait',
    'conduiriez',
    'conduirons',
    'conduiront',
    'conduisais',
    'conduisait',
    'conduisant',
    'conduisent',
    'conduisiez',
    'conduisons',
    'condyliens',
    'condylomes',
    'confection',
    'confédérai',
    'confédéral',
    'confédéras',
    'confédérât',
    'confédérée',
    'confédérer',
    'confédérés',
    'confédères',
    'confédérez',
    'conférâmes',
    'conférasse',
    'conférâtes',
    'conférence',
    'conférerai',
    'conféreras',
    'conférerez',
    'conférions',
    'confessais',
    'confessait',
    'confessant',
    'confessées',
    'confessent',
    'confessera',
    'confesseur',
    'confessiez',
    'confession',
    'confessons',
    'confiaient',
    'confiances',
    'confiantes',
    'confiasses',
    'confidence',
    'confidente',
    'confidents',
    'confierais',
    'confierait',
    'confièrent',
    'confieriez',
    'confierons',
    'confieront',
    'configurai',
    'configuras',
    'configurât',
    'configurée',
    'configurer',
    'configures',
    'configurés',
    'configurez',
    'confinâmes',
    'confinasse',
    'confinâtes',
    'confinerai',
    'confineras',
    'confinerez',
    'confinions',
    'confirions',
    'confirmais',
    'confirmait',
    'confirmand',
    'confirmant',
    'confirmées',
    'confirment',
    'confirmera',
    'confirmiez',
    'confirmons',
    'confiserie',
    'confiseurs',
    'confiseuse',
    'confisions',
    'confisquai',
    'confisquas',
    'confisquât',
    'confisquée',
    'confisquer',
    'confisques',
    'confisqués',
    'confisquez',
    'confissent',
    'confissiez',
    'confitures',
    'confluâmes',
    'confluasse',
    'confluâtes',
    'confluence',
    'confluents',
    'confluerai',
    'conflueras',
    'confluerez',
    'confluions',
    'confondais',
    'confondait',
    'confondant',
    'confondent',
    'confondiez',
    'confondons',
    'confondrai',
    'confondras',
    'confondrez',
    'confondues',
    'conformais',
    'conformait',
    'conformant',
    'conformées',
    'conforment',
    'conformera',
    'conformiez',
    'conformité',
    'conformons',
    'confortais',
    'confortait',
    'confortant',
    'confortées',
    'confortent',
    'confortera',
    'confortiez',
    'confortons',
    'confréries',
    'confrontai',
    'confrontas',
    'confrontât',
    'confrontée',
    'confronter',
    'confrontes',
    'confrontés',
    'confrontez',
    'confusions',
    'congédiais',
    'congédiait',
    'congédiant',
    'congédiées',
    'congédient',
    'congédiera',
    'congédiiez',
    'congédions',
    'congelable',
    'congelâmes',
    'congelasse',
    'congelâtes',
    'congèlerai',
    'congèleras',
    'congèlerez',
    'congelions',
    'congénères',
    'congénital',
    'congestifs',
    'congestion',
    'congestive',
    'congloméra',
    'congloméré',
    'conglomère',
    'conglutina',
    'conglutine',
    'conglutiné',
    'congolaise',
    'congratula',
    'congratule',
    'congratulé',
    'congréâmes',
    'congréasse',
    'congréâtes',
    'congréerai',
    'congréeras',
    'congréerez',
    'congréions',
    'congruente',
    'congruents',
    'côniraient',
    'conirostre',
    'cônissions',
    'conjectura',
    'conjecture',
    'conjecturé',
    'conjoignes',
    'conjoignez',
    'conjoignis',
    'conjoignit',
    'conjoignît',
    'conjoindra',
    'conjoindre',
    'conjointes',
    'conjonctif',
    'conjugales',
    'conjuguais',
    'conjuguait',
    'conjuguant',
    'conjuguées',
    'conjuguent',
    'conjuguera',
    'conjuguiez',
    'conjuguons',
    'conjurâmes',
    'conjurasse',
    'conjurâtes',
    'conjurerai',
    'conjureras',
    'conjurerez',
    'conjurions',
    'connaisses',
    'connaissez',
    'connaîtrai',
    'connaîtras',
    'connaîtrez',
    'connectais',
    'connectait',
    'connectant',
    'connectées',
    'connectent',
    'connectera',
    'connecteur',
    'connectiez',
    'connectifs',
    'connective',
    'connectons',
    'connétable',
    'connexions',
    'connexités',
    'connivence',
    'connivente',
    'connivents',
    'connotâmes',
    'connotasse',
    'connotâtes',
    'connoterai',
    'connoteras',
    'connoterez',
    'connotions',
    'connussent',
    'connussiez',
    'conobrâmes',
    'conobrasse',
    'conobrâtes',
    'conobrerai',
    'conobreras',
    'conobrerez',
    'conobrions',
    'conpressai',
    'conpressas',
    'conpressât',
    'conpressée',
    'conpresser',
    'conpresses',
    'conpressés',
    'conpressez',
    'conquérais',
    'conquérait',
    'conquérant',
    'conquériez',
    'conquérons',
    'conquerrai',
    'conquerras',
    'conquerrez',
    'conquérues',
    'conquières',
    'conquirent',
    'conquisses',
    'consacrais',
    'consacrait',
    'consacrant',
    'consacrées',
    'consacrent',
    'consacrera',
    'consacriez',
    'consacrons',
    'consanguin',
    'conscience',
    'consciente',
    'conscients',
    'consécutif',
    'conseillai',
    'conseillas',
    'conseillât',
    'conseillée',
    'conseiller',
    'conseilles',
    'conseillés',
    'conseillez',
    'consentais',
    'consentait',
    'consentant',
    'consentent',
    'consenties',
    'consentiez',
    'consentira',
    'consentons',
    'conséquent',
    'conservais',
    'conservait',
    'conservant',
    'conservées',
    'conservent',
    'conservera',
    'conserviez',
    'conservons',
    'considérai',
    'considéras',
    'considérât',
    'considérée',
    'considérer',
    'considérés',
    'considères',
    'considérez',
    'consignais',
    'consignait',
    'consignant',
    'consignées',
    'consignent',
    'consignera',
    'consigniez',
    'consignons',
    'consistais',
    'consistait',
    'consistant',
    'consistent',
    'consistera',
    'consistiez',
    'consistons',
    'consolable',
    'consolâmes',
    'consolante',
    'consolants',
    'consolasse',
    'consolâtes',
    'consolerai',
    'consoleras',
    'consolerez',
    'consolidai',
    'consolidas',
    'consolidât',
    'consolidée',
    'consolider',
    'consolides',
    'consolidés',
    'consolidez',
    'consolions',
    'consommais',
    'consommait',
    'consommant',
    'consommées',
    'consomment',
    'consommera',
    'consommiez',
    'consommons',
    'consomptif',
    'consonâmes',
    'consonance',
    'consonasse',
    'consonâtes',
    'consonerai',
    'consoneras',
    'consonerez',
    'consonions',
    'consortium',
    'conspirais',
    'conspirait',
    'conspirant',
    'conspirées',
    'conspirent',
    'conspirera',
    'conspiriez',
    'conspirons',
    'conspuâmes',
    'conspuasse',
    'conspuâtes',
    'conspuerai',
    'conspueras',
    'conspuerez',
    'conspuions',
    'constables',
    'constances',
    'constantan',
    'constantes',
    'constatais',
    'constatait',
    'constatant',
    'constatées',
    'constatent',
    'constatera',
    'constatiez',
    'constatons',
    'constellai',
    'constellas',
    'constellât',
    'constellée',
    'consteller',
    'constelles',
    'constellés',
    'constellez',
    'consternai',
    'consternas',
    'consternât',
    'consternée',
    'consterner',
    'consternes',
    'consternés',
    'consternez',
    'constipais',
    'constipait',
    'constipant',
    'constipées',
    'constipent',
    'constipera',
    'constipiez',
    'constipons',
    'constituai',
    'constituas',
    'constituât',
    'constituée',
    'constituer',
    'constitues',
    'constitués',
    'constituez',
    'construira',
    'construire',
    'construise',
    'construite',
    'construits',
    'consulaire',
    'consultais',
    'consultait',
    'consultant',
    'consultées',
    'consultent',
    'consultera',
    'consultiez',
    'consultons',
    'consumâmes',
    'consumasse',
    'consumâtes',
    'consumerai',
    'consumeras',
    'consumerez',
    'consumions',
    'contactais',
    'contactait',
    'contactant',
    'contactées',
    'contactent',
    'contactera',
    'contacteur',
    'contactiez',
    'contactons',
    'contagieux',
    'contagions',
    'containers',
    'contaminai',
    'contaminas',
    'contaminât',
    'contaminée',
    'contaminer',
    'contamines',
    'contaminés',
    'contaminez',
    'contassent',
    'contassiez',
    'contemplai',
    'contemplas',
    'contemplât',
    'contemplée',
    'contempler',
    'contemples',
    'contemplés',
    'contemplez',
    'contenance',
    'contenante',
    'contenants',
    'conteneurs',
    'contenions',
    'contentais',
    'contentait',
    'contentant',
    'contentées',
    'contentent',
    'contentera',
    'contentiez',
    'contentifs',
    'contention',
    'contentive',
    'contentons',
    'conterions',
    'contestais',
    'contestait',
    'contestant',
    'contestées',
    'contestent',
    'contestera',
    'contestiez',
    'contestons',
    'contextuel',
    'contexture',
    'contiendra',
    'contiennes',
    'contiguïté',
    'continence',
    'continents',
    'contingent',
    'continrent',
    'continsses',
    'continuais',
    'continuait',
    'continuant',
    'continuées',
    'continuels',
    'continuent',
    'continuera',
    'continuiez',
    'continuité',
    'continuons',
    'continuums',
    'contondant',
    'contorsion',
    'contournai',
    'contournas',
    'contournât',
    'contournée',
    'contourner',
    'contournes',
    'contournés',
    'contournez',
    'contractai',
    'contractas',
    'contractât',
    'contractée',
    'contracter',
    'contractes',
    'contractés',
    'contractez',
    'contraient',
    'contraigne',
    'contrainte',
    'contraints',
    'contraires',
    'contraltos',
    'contraposa',
    'contrapose',
    'contraposé',
    'contrariai',
    'contrarias',
    'contrariât',
    'contrariée',
    'contrarier',
    'contraries',
    'contrariés',
    'contrariez',
    'contrasses',
    'contrastai',
    'contrastas',
    'contrastât',
    'contrastée',
    'contraster',
    'contrastes',
    'contrastés',
    'contrastez',
    'contrebats',
    'contrebuta',
    'contrebute',
    'contrebuté',
    'contrecoup',
    'contredira',
    'contredire',
    'contredise',
    'contredite',
    'contredits',
    'contrefais',
    'contrefait',
    'contre-fer',
    'contrefera',
    'contre-feu',
    'contre-fil',
    'contrefont',
    'contrefort',
    'contrefous',
    'contrefout',
    'contrerais',
    'contrerait',
    'contrèrent',
    'contreriez',
    'contrerons',
    'contreront',
    'contresens',
    'contretype',
    'contrevent',
    'contrevenu',
    'contrevins',
    'contrevint',
    'contrevînt',
    'contribuai',
    'contribuas',
    'contribuât',
    'contribuer',
    'contribues',
    'contribuez',
    'contristai',
    'contristas',
    'contristât',
    'contristée',
    'contrister',
    'contristes',
    'contristés',
    'contristez',
    'contrition',
    'contrôlais',
    'contrôlait',
    'contrôlant',
    'contrôlées',
    'contrôlent',
    'contrôlera',
    'contrôleur',
    'contrôliez',
    'contrôlons',
    'contrordre',
    'controuvai',
    'controuvas',
    'controuvât',
    'controuvée',
    'controuver',
    'controuves',
    'controuvés',
    'controuvez',
    'contumaces',
    'contusions',
    'convaincra',
    'convaincre',
    'convaincue',
    'convaincus',
    'convainque',
    'convenable',
    'convenance',
    'convenante',
    'convenants',
    'convenions',
    'convention',
    'conventuel',
    'convergeai',
    'convergeas',
    'convergeât',
    'convergent',
    'convergera',
    'convergiez',
    'conversais',
    'conversait',
    'conversant',
    'conversent',
    'conversera',
    'conversiez',
    'conversion',
    'conversons',
    'converties',
    'convertira',
    'convexités',
    'conviaient',
    'conviasses',
    'conviction',
    'conviendra',
    'conviennes',
    'convierais',
    'convierait',
    'convièrent',
    'convieriez',
    'convierons',
    'convieront',
    'convinrent',
    'convinsses',
    'conviviale',
    'conviviaux',
    'convoierai',
    'convoieras',
    'convoierez',
    'convoitais',
    'convoitait',
    'convoitant',
    'convoitées',
    'convoitent',
    'convoitera',
    'convoitiez',
    'convoitise',
    'convoitons',
    'convolâmes',
    'convolasse',
    'convolâtes',
    'convolerai',
    'convoleras',
    'convolerez',
    'convolions',
    'convoquais',
    'convoquait',
    'convoquant',
    'convoquées',
    'convoquent',
    'convoquera',
    'convoquiez',
    'convoquons',
    'convoyâmes',
    'convoyasse',
    'convoyâtes',
    'convoyeurs',
    'convoyeuse',
    'convoyions',
    'convulsais',
    'convulsait',
    'convulsant',
    'convulsées',
    'convulsent',
    'convulsera',
    'convulsiez',
    'convulsifs',
    'convulsion',
    'convulsive',
    'convulsons',
    'coopérâmes',
    'coopérante',
    'coopérants',
    'coopérasse',
    'coopérâtes',
    'coopératif',
    'coopérerai',
    'coopéreras',
    'coopérerez',
    'coopérions',
    'cooptaient',
    'cooptasses',
    'cooptation',
    'coopterais',
    'coopterait',
    'cooptèrent',
    'coopteriez',
    'coopterons',
    'coopteront',
    'coordonnai',
    'coordonnas',
    'coordonnât',
    'coordonnée',
    'coordonner',
    'coordonnes',
    'coordonnés',
    'coordonnez',
    'copartages',
    'copermutai',
    'copermutas',
    'copermutât',
    'copermutée',
    'copermuter',
    'copermutes',
    'copermutés',
    'copermutez',
    'copiassent',
    'copiassiez',
    'copierions',
    'copinaient',
    'copinasses',
    'copinerais',
    'copinerait',
    'copinèrent',
    'copineries',
    'copineriez',
    'copinerons',
    'copineront',
    'coplanaire',
    'copossédée',
    'coposséder',
    'copossédés',
    'copossèdes',
    'copposédai',
    'copposédas',
    'copposédât',
    'copposédez',
    'coprolithe',
    'copulaient',
    'copulasses',
    'copulatifs',
    'copulation',
    'copulative',
    'copulerais',
    'copulerait',
    'copulèrent',
    'copuleriez',
    'copulerons',
    'copuleront',
    'coquassent',
    'coquassiez',
    'coquelicot',
    'coqueluche',
    'coquericos',
    'coquerions',
    'coquetâmes',
    'coquetasse',
    'coquetâtes',
    'coquetiers',
    'coquetions',
    'coquettent',
    'coquettera',
    'coquillage',
    'coquillais',
    'coquillait',
    'coquillant',
    'coquillart',
    'coquillent',
    'coquillera',
    'coquillier',
    'coquilliez',
    'coquillons',
    'coquinerie',
    'coralliens',
    'coraniques',
    'corbeilles',
    'corbillard',
    'corbillats',
    'cordassent',
    'cordassiez',
    'cordelâmes',
    'cordelasse',
    'cordelâtes',
    'cordelette',
    'cordelière',
    'cordeliers',
    'cordelions',
    'cordellent',
    'cordellera',
    'corderions',
    'cordialité',
    'cordillère',
    'cordonnais',
    'cordonnait',
    'cordonnant',
    'cordonnées',
    'cordonnent',
    'cordonnera',
    'cordonnets',
    'cordonnier',
    'cordonniez',
    'cordonnons',
    'coriandres',
    'corinthien',
    'cornalines',
    'cornassent',
    'cornassiez',
    'cornéennes',
    'corneilles',
    'cornéliens',
    'cornemuses',
    'cornerions',
    'cornichons',
    'corollaire',
    'coronaires',
    'coronarien',
    'coronarite',
    'coronilles',
    'corporatif',
    'corporelle',
    'corpulence',
    'corpulente',
    'corpulents',
    'corpuscule',
    'correcteur',
    'correctifs',
    'correction',
    'corrective',
    'corrélâmes',
    'corrélasse',
    'corrélâtes',
    'corrélatif',
    'corrélerai',
    'corréleras',
    'corrélerez',
    'corrélions',
    'correspond',
    'corrigeais',
    'corrigeait',
    'corrigeant',
    'corrigeons',
    'corrigerai',
    'corrigeras',
    'corrigerez',
    'corrigible',
    'corrigions',
    'corroborai',
    'corroboras',
    'corroborât',
    'corroborée',
    'corroborer',
    'corrobores',
    'corroborés',
    'corroborez',
    'corrodâmes',
    'corrodasse',
    'corrodâtes',
    'corroderai',
    'corroderas',
    'corroderez',
    'corrodions',
    'corroierai',
    'corroieras',
    'corroierez',
    'corrompais',
    'corrompait',
    'corrompant',
    'corrompent',
    'corrompiez',
    'corrompons',
    'corromprai',
    'corrompras',
    'corromprez',
    'corrompues',
    'corrosions',
    'corrosives',
    'corroyages',
    'corroyâmes',
    'corroyasse',
    'corroyâtes',
    'corroyeurs',
    'corroyions',
    'corrupteur',
    'corruption',
    'corsassent',
    'corsassiez',
    'corserions',
    'corsetâmes',
    'corsetasse',
    'corsetâtes',
    'corsèterai',
    'corsèteras',
    'corsèterez',
    'corsetière',
    'corsetiers',
    'corsetions',
    'corticales',
    'corvéables',
    'cosmétiqua',
    'cosmétique',
    'cosmétiqué',
    'cosmodrome',
    'cosmogonie',
    'cosmologie',
    'cosmonaute',
    'cossassent',
    'cossassiez',
    'cosserions',
    'costumâmes',
    'costumasse',
    'costumâtes',
    'costumerai',
    'costumeras',
    'costumerez',
    'costumière',
    'costumiers',
    'costumions',
    'cotassions',
    'côtelettes',
    'coteraient',
    'cotiraient',
    'cotisaient',
    'cotisantes',
    'cotisasses',
    'cotisation',
    'cotiserais',
    'cotiserait',
    'cotisèrent',
    'cotiseriez',
    'cotiserons',
    'cotiseront',
    'cotissions',
    'côtoiement',
    'côtoierais',
    'côtoierait',
    'côtoieriez',
    'côtoierons',
    'côtoieront',
    'cotonnades',
    'cotonnâmes',
    'cotonnasse',
    'cotonnâtes',
    'cotonnerai',
    'cotonneras',
    'cotonnerez',
    'cotonneuse',
    'cotonnière',
    'cotonniers',
    'cotonnions',
    'côtoyaient',
    'côtoyasses',
    'côtoyèrent',
    'cotylédons',
    'couardises',
    'couchaient',
    'couchailla',
    'couchaille',
    'couchaillé',
    'couchantes',
    'couchasses',
    'coucherais',
    'coucherait',
    'couchèrent',
    'coucheriez',
    'coucherons',
    'coucheront',
    'couche-tôt',
    'couchettes',
    'coucheuses',
    'coudassent',
    'coudassiez',
    'couderions',
    'coudoierai',
    'coudoieras',
    'coudoierez',
    'coudoyâmes',
    'coudoyasse',
    'coudoyâtes',
    'coudoyions',
    'coudraient',
    'couillonna',
    'couillonne',
    'couillonné',
    'couinaient',
    'couinasses',
    'couinement',
    'couinerais',
    'couinerait',
    'couinèrent',
    'couineriez',
    'couinerons',
    'couineront',
    'coulassent',
    'coulassiez',
    'coulerions',
    'couleuvres',
    'coulissais',
    'coulissait',
    'coulissant',
    'coulissées',
    'coulissent',
    'coulissera',
    'coulissier',
    'coulissiez',
    'coulissons',
    'coupaillai',
    'coupaillas',
    'coupaillât',
    'coupaillée',
    'coupailler',
    'coupailles',
    'coupaillés',
    'coupaillez',
    'coupassent',
    'coupassiez',
    'coupe-chou',
    'coupe-file',
    'coupellais',
    'coupellait',
    'coupellant',
    'coupellées',
    'coupellent',
    'coupellera',
    'coupelliez',
    'coupellons',
    'couperions',
    'couperosée',
    'couperoses',
    'couperosés',
    'coupe-vent',
    'couplaient',
    'couplasses',
    'couplerais',
    'couplerait',
    'couplèrent',
    'coupleriez',
    'couplerons',
    'coupleront',
    'courageuse',
    'couraillai',
    'couraillas',
    'couraillât',
    'courailler',
    'courailles',
    'couraillez',
    'couramment',
    'courbaient',
    'courbasses',
    'courbatues',
    'courbatura',
    'courbature',
    'courbaturé',
    'courbement',
    'courberais',
    'courberait',
    'courbèrent',
    'courberiez',
    'courberons',
    'courberont',
    'courbettes',
    'courgettes',
    'couronnais',
    'couronnait',
    'couronnant',
    'couronnées',
    'couronnent',
    'couronnera',
    'couronniez',
    'couronnons',
    'courraient',
    'courrouçai',
    'courrouças',
    'courrouçât',
    'courroucée',
    'courroucer',
    'courrouces',
    'courroucés',
    'courroucez',
    'coursaient',
    'coursasses',
    'courserais',
    'courserait',
    'coursèrent',
    'courseriez',
    'courserons',
    'courseront',
    'coursières',
    'courtaudai',
    'courtaudas',
    'courtaudât',
    'courtaudée',
    'courtauder',
    'courtaudes',
    'courtaudés',
    'courtaudez',
    'courtières',
    'courtisais',
    'courtisait',
    'courtisane',
    'courtisans',
    'courtisant',
    'courtisées',
    'courtisent',
    'courtisera',
    'courtisiez',
    'courtisons',
    'courtoises',
    'courtoisie',
    'courussent',
    'courussiez',
    'cousinâmes',
    'cousinasse',
    'cousinâtes',
    'cousinerai',
    'cousineras',
    'cousinerez',
    'cousinions',
    'cousissent',
    'cousissiez',
    'coussinets',
    'coûtassent',
    'coûtassiez',
    'coutelière',
    'couteliers',
    'coûterions',
    'coutumière',
    'coutumiers',
    'couturâmes',
    'couturasse',
    'couturâtes',
    'couturerai',
    'coutureras',
    'couturerez',
    'couturière',
    'couturiers',
    'couturions',
    'couvaisons',
    'couvassent',
    'couvassiez',
    'couvercles',
    'couverions',
    'couverture',
    'couvraient',
    'couvrantes',
    'couvre-feu',
    'couvre-lit',
    'couvreuses',
    'couvrirais',
    'couvrirait',
    'couvrirent',
    'couvririez',
    'couvrirons',
    'couvriront',
    'couvrisses',
    'covariance',
    'covendeurs',
    'covendeuse',
    'cover-girl',
    'coxalgique',
    'crabotages',
    'crachaient',
    'crachasses',
    'crachement',
    'cracherais',
    'cracherait',
    'crachèrent',
    'cracheriez',
    'cracherons',
    'cracheront',
    'cracheuses',
    'crachinait',
    'crachinera',
    'crachotais',
    'crachotait',
    'crachotant',
    'crachotent',
    'crachotera',
    'crachotiez',
    'crachotons',
    'craignîmes',
    'craignions',
    'craignisse',
    'craignîtes',
    'craillâmes',
    'craillasse',
    'craillâtes',
    'craillerai',
    'crailleras',
    'craillerez',
    'craillions',
    'craindrais',
    'craindrait',
    'craindriez',
    'craindrons',
    'craindront',
    'craintives',
    'cramassent',
    'cramassiez',
    'cramerions',
    'cramoisies',
    'crampillon',
    'cramponnai',
    'cramponnas',
    'cramponnât',
    'cramponnée',
    'cramponner',
    'cramponnes',
    'cramponnés',
    'cramponnet',
    'cramponnez',
    'crampsâmes',
    'crampsasse',
    'crampsâtes',
    'crampserai',
    'crampseras',
    'crampserez',
    'crampsions',
    'cramsaient',
    'cramsasses',
    'cramserais',
    'cramserait',
    'cramsèrent',
    'cramseriez',
    'cramserons',
    'cramseront',
    'cranassent',
    'crânassent',
    'cranassiez',
    'crânassiez',
    'cranerions',
    'crânerions',
    'crâniennes',
    'crantaient',
    'crantasses',
    'cranterais',
    'cranterait',
    'crantèrent',
    'cranteriez',
    'cranterons',
    'cranteront',
    'crapahutai',
    'crapahutas',
    'crapahutât',
    'crapahutée',
    'crapahuter',
    'crapahutes',
    'crapahutés',
    'crapahutez',
    'crapaudine',
    'crapaütais',
    'crapaütait',
    'crapaütant',
    'crapaütées',
    'crapaütent',
    'crapaütera',
    'crapaütiez',
    'crapaütons',
    'crapoussin',
    'crapulâmes',
    'crapulasse',
    'crapulâtes',
    'crapulerai',
    'crapuleras',
    'crapulerez',
    'crapulerie',
    'crapuleuse',
    'crapulions',
    'craquaient',
    'craquasses',
    'craquelage',
    'craquelais',
    'craquelait',
    'craquelant',
    'craquelées',
    'craqueliez',
    'craquelles',
    'craquelons',
    'craquelure',
    'craquement',
    'craquerais',
    'craquerait',
    'craquèrent',
    'craqueriez',
    'craquerons',
    'craqueront',
    'craquetais',
    'craquetait',
    'craquetant',
    'craquetiez',
    'craquetons',
    'craquettes',
    'crassaient',
    'crassasses',
    'crasserais',
    'crasserait',
    'crassèrent',
    'crasseriez',
    'crasserons',
    'crasseront',
    'crasseuses',
    'craterelle',
    'cravachais',
    'cravachait',
    'cravachant',
    'cravachées',
    'cravachent',
    'cravachera',
    'cravachiez',
    'cravachons',
    'cravatâmes',
    'cravatasse',
    'cravatâtes',
    'cravaterai',
    'cravateras',
    'cravaterez',
    'cravations',
    'crawlaient',
    'crawlasses',
    'crawlerais',
    'crawlerait',
    'crawlèrent',
    'crawleriez',
    'crawlerons',
    'crawleront',
    'crawleuses',
    'crayonnage',
    'crayonnais',
    'crayonnait',
    'crayonnant',
    'crayonnées',
    'crayonnent',
    'crayonnera',
    'crayonniez',
    'crayonnons',
    'créancière',
    'créanciers',
    'créassions',
    'créatinine',
    'créativité',
    'créatrices',
    'crécerelle',
    'créchaient',
    'créchasses',
    'crécherais',
    'crécherait',
    'créchèrent',
    'crécheriez',
    'crécherons',
    'crécheront',
    'créditâmes',
    'créditasse',
    'créditâtes',
    'créditerai',
    'créditeras',
    'créditerez',
    'créditeurs',
    'créditions',
    'créditiste',
    'créditrice',
    'crédulités',
    'créeraient',
    'crémassent',
    'crémassiez',
    'crémations',
    'crématoire',
    'crémerions',
    'crénassent',
    'crénassiez',
    'crénelâmes',
    'crénelasse',
    'crénelâtes',
    'crénelions',
    'crénellent',
    'crénellera',
    'crénerions',
    'créolisais',
    'créolisait',
    'créolisant',
    'créolisées',
    'créolisent',
    'créolisera',
    'créolisiez',
    'créolisons',
    'créosotais',
    'créosotait',
    'créosotant',
    'créosotées',
    'créosotent',
    'créosotera',
    'créosotiez',
    'créosotons',
    'crêpassent',
    'crêpassiez',
    'crêperions',
    'crépirions',
    'crépissage',
    'crépissais',
    'crépissait',
    'crépissant',
    'crépissent',
    'crépissiez',
    'crépissons',
    'crépitâmes',
    'crépitasse',
    'crépitâtes',
    'crépiterai',
    'crépiteras',
    'crépiterez',
    'crépitions',
    'crépuscule',
    'crescendos',
    'crétinisai',
    'crétinisas',
    'crétinisât',
    'crétinisée',
    'crétiniser',
    'crétinises',
    'crétinisés',
    'crétinisez',
    'crétinisme',
    'creusaient',
    'creusasses',
    'creusement',
    'creuserais',
    'creuserait',
    'creusèrent',
    'creuseriez',
    'creuserons',
    'creuseront',
    'crevaisons',
    'crevassais',
    'crevassait',
    'crevassant',
    'crevassées',
    'crevassent',
    'crevassera',
    'crevassiez',
    'crevassons',
    'crèverions',
    'criaillais',
    'criaillait',
    'criaillant',
    'criaillent',
    'criaillera',
    'criailliez',
    'criaillons',
    'criassions',
    'criblaient',
    'criblasses',
    'criblerais',
    'criblerait',
    'criblèrent',
    'cribleriez',
    'criblerons',
    'cribleront',
    'crieraient',
    'criminelle',
    'crinolines',
    'crispaient',
    'crispantes',
    'crispasses',
    'crispation',
    'crisperais',
    'crisperait',
    'crispèrent',
    'crisperiez',
    'crisperons',
    'crisperont',
    'crissaient',
    'crissasses',
    'crissement',
    'crisserais',
    'crisserait',
    'crissèrent',
    'crisseriez',
    'crisserons',
    'crisseront',
    'cristallin',
    'critériums',
    'critiquais',
    'critiquait',
    'critiquant',
    'critiquées',
    'critiquent',
    'critiquera',
    'critiquiez',
    'critiquons',
    'croassâmes',
    'croassasse',
    'croassâtes',
    'croasserai',
    'croasseras',
    'croasserez',
    'croassions',
    'crochaient',
    'crochasses',
    'crocherais',
    'crocherait',
    'crochèrent',
    'crocheriez',
    'crocherons',
    'crocheront',
    'crochetage',
    'crochetais',
    'crochetait',
    'crochetant',
    'crochetées',
    'crochètent',
    'crochètera',
    'crocheteur',
    'crochetiez',
    'crochetons',
    'crocodiles',
    'croiraient',
    'croisaient',
    'croisasses',
    'croisement',
    'croiserais',
    'croiserait',
    'croisèrent',
    'croiseriez',
    'croiserons',
    'croiseront',
    'croisières',
    'croisillon',
    'croissance',
    'croissante',
    'croissants',
    'croissions',
    'croîtrions',
    'croquaient',
    'croquantes',
    'croquasses',
    'croquerais',
    'croquerait',
    'croquèrent',
    'croqueriez',
    'croquerons',
    'croqueront',
    'croquettes',
    'crossaient',
    'crossasses',
    'crosserais',
    'crosserait',
    'crossèrent',
    'crosseriez',
    'crosserons',
    'crosseront',
    'crottaient',
    'crottasses',
    'crotterais',
    'crotterait',
    'crottèrent',
    'crotteriez',
    'crotterons',
    'crotteront',
    'crouillats',
    'croulaient',
    'croulantes',
    'croulasses',
    'croulerais',
    'croulerait',
    'croulèrent',
    'crouleriez',
    'croulerons',
    'crouleront',
    'croupières',
    'croupirais',
    'croupirait',
    'croupirent',
    'croupiriez',
    'croupirons',
    'croupiront',
    'croupisses',
    'croupissez',
    'croustades',
    'croustilla',
    'croustille',
    'croustillé',
    'croûtaient',
    'croûtasses',
    'croûterais',
    'croûterait',
    'croûtèrent',
    'croûteriez',
    'croûterons',
    'croûteront',
    'crucifiais',
    'crucifiait',
    'crucifiant',
    'crucifiées',
    'crucifient',
    'crucifiera',
    'crucifiiez',
    'crucifions',
    'cryptaient',
    'cryptasses',
    'crypterais',
    'crypterait',
    'cryptèrent',
    'crypteriez',
    'crypterons',
    'crypteront',
    'cryptiques',
    'cubassions',
    'cuberaient',
    'cucurbites',
    'cucurbitin',
    'cueillerai',
    'cueilleras',
    'cueillerez',
    'cueillette',
    'cueillîmes',
    'cueillions',
    'cueillisse',
    'cueillîtes',
    'cuillerées',
    'cuirassais',
    'cuirassait',
    'cuirassant',
    'cuirassées',
    'cuirassent',
    'cuirassera',
    'cuirassier',
    'cuirassiez',
    'cuirassons',
    'cuisinâmes',
    'cuisinasse',
    'cuisinâtes',
    'cuisinerai',
    'cuisineras',
    'cuisinerez',
    'cuisinette',
    'cuisinière',
    'cuisiniers',
    'cuisinions',
    'cuisissent',
    'cuisissiez',
    'cuissardes',
    'cuistrerie',
    'cuitassent',
    'cuitassiez',
    'cuiterions',
    'cuivraient',
    'cuivrasses',
    'cuivrerais',
    'cuivrerait',
    'cuivrèrent',
    'cuivreriez',
    'cuivrerons',
    'cuivreront',
    'cuivreuses',
    'cuivriques',
    'culassions',
    'culbutages',
    'culbutâmes',
    'culbutasse',
    'culbutâtes',
    'culbuterai',
    'culbuteras',
    'culbuterez',
    'culbuteurs',
    'culbutions',
    'cul-de-sac',
    'culeraient',
    'culinaires',
    'culminâmes',
    'culminante',
    'culminants',
    'culminasse',
    'culminâtes',
    'culminerai',
    'culmineras',
    'culminerez',
    'culminions',
    'culottages',
    'culottâmes',
    'culottasse',
    'culottâtes',
    'culotterai',
    'culotteras',
    'culotterez',
    'culottière',
    'culottiers',
    'culottions',
    'cultivable',
    'cultivâmes',
    'cultivasse',
    'cultivâtes',
    'cultiverai',
    'cultiveras',
    'cultiverez',
    'cultivions',
    'cultuelles',
    'culturales',
    'culturelle',
    'culturisme',
    'cumulables',
    'cumulaient',
    'cumulardes',
    'cumulasses',
    'cumulateur',
    'cumulatifs',
    'cumulative',
    'cumulerais',
    'cumulerait',
    'cumulèrent',
    'cumuleriez',
    'cumulerons',
    'cumuleront',
    'cunéiforme',
    'cupidement',
    'cuprifères',
    'curaillons',
    'curarisant',
    'curassions',
    'curatelles',
    'curatrices',
    'cure-dents',
    'cure-pipes',
    'cureraient',
    'curetaient',
    'curetasses',
    'curetèrent',
    'curettages',
    'curetterai',
    'curetteras',
    'curetterez',
    'curiosités',
    'curriculum',
    'curviligne',
    'curvimètre',
    'cuvassions',
    'cuvelaient',
    'cuvelasses',
    'cuvelèrent',
    'cuvellerai',
    'cuvelleras',
    'cuvellerez',
    'cuveraient',
    'cyanamides',
    'cyanogènes',
    'cyanosâmes',
    'cyanosasse',
    'cyanosâtes',
    'cyanoserai',
    'cyanoseras',
    'cyanoserez',
    'cyanosions',
    'cyanurâmes',
    'cyanurasse',
    'cyanurâtes',
    'cyanurerai',
    'cyanureras',
    'cyanurerez',
    'cyanurions',
    'cyclisâmes',
    'cyclisasse',
    'cyclisâtes',
    'cycliserai',
    'cycliseras',
    'cycliserez',
    'cyclisions',
    'cycloïdale',
    'cycloïdaux',
    'cyclonales',
    'cyclonique',
    'cyclopéens',
    'cyclostome',
    'cyclotrons',
    'cylindrage',
    'cylindrais',
    'cylindrait',
    'cylindrant',
    'cylindraxe',
    'cylindrées',
    'cylindrent',
    'cylindrera',
    'cylindreur',
    'cylindriez',
    'cylindrons',
    'cymbalaire',
    'cymbalière',
    'cymbaliers',
    'cymbaliste',
    'cynodromes',
    'cynoglosse',
    'cynophiles',
    'cynorhodon',
    'cyrillique',
    'cystoscope',
    'cystotomie',
    'cytologies',
    'cytoplasme',
    'czimbalums',
    'dactylique',
    'daguassent',
    'daguassiez',
    'daguerions',
    'dahoméenne',
    'daignaient',
    'daignasses',
    'daignerais',
    'daignerait',
    'daignèrent',
    'daigneriez',
    'daignerons',
    'daigneront',
    'dalaï-lama',
    'dallassent',
    'dallassiez',
    'dallerions',
    'dalmatiens',
    'dalmatique',
    'daltoniens',
    'daltonisme',
    'damalisque',
    'damasquina',
    'damasquine',
    'damasquiné',
    'damassâmes',
    'damassasse',
    'damassâtes',
    'damasserai',
    'damasseras',
    'damasserez',
    'damassions',
    'damassures',
    'dameraient',
    'damnassent',
    'damnassiez',
    'damnations',
    'damnerions',
    'damoiseaux',
    'dandinâmes',
    'dandinasse',
    'dandinâtes',
    'dandinerai',
    'dandineras',
    'dandinerez',
    'dandinette',
    'dandinions',
    'dangereuse',
    'dansassent',
    'dansassiez',
    'danserions',
    'dansottais',
    'dansottait',
    'dansottant',
    'dansottent',
    'dansottera',
    'dansottiez',
    'dansottons',
    'dantesques',
    'dardassent',
    'dardassiez',
    'darderions',
    'dartreuses',
    'darwiniens',
    'darwinisme',
    'darwiniste',
    'datassions',
    'dateraient',
    'daubassent',
    'daubassiez',
    'dauberions',
    'dauphinois',
    'déactivais',
    'déactivait',
    'déactivant',
    'déactivées',
    'déactivent',
    'déactivera',
    'déactiviez',
    'déactivons',
    'déambulais',
    'déambulait',
    'déambulant',
    'déambulent',
    'déambulera',
    'déambuliez',
    'déambulons',
    'débâchâmes',
    'débâchasse',
    'débâchâtes',
    'débâcherai',
    'débâcheras',
    'débâcherez',
    'débâchions',
    'débâclâmes',
    'débâclasse',
    'débâclâtes',
    'débâclerai',
    'débâcleras',
    'débâclerez',
    'débâclions',
    'débagoulai',
    'débagoulas',
    'débagoulât',
    'débagoulée',
    'débagouler',
    'débagoules',
    'débagoulés',
    'débagoulez',
    'déballages',
    'déballâmes',
    'déballasse',
    'déballâtes',
    'déballerai',
    'déballeras',
    'déballerez',
    'déballions',
    'déballonna',
    'déballonne',
    'déballonné',
    'débalourda',
    'débalourde',
    'débalourdé',
    'débanalisa',
    'débanalise',
    'débanalisé',
    'débandades',
    'débandâmes',
    'débandasse',
    'débandâtes',
    'débanderai',
    'débanderas',
    'débanderez',
    'débandions',
    'débaptisai',
    'débaptisas',
    'débaptisât',
    'débaptisée',
    'débaptiser',
    'débaptises',
    'débaptisés',
    'débaptisez',
    'débardages',
    'débardâmes',
    'débardasse',
    'débardâtes',
    'débarderai',
    'débarderas',
    'débarderez',
    'débardeurs',
    'débardeuse',
    'débardions',
    'débarquais',
    'débarquait',
    'débarquant',
    'débarquées',
    'débarquent',
    'débarquera',
    'débarquiez',
    'débarquons',
    'débarrâmes',
    'débarrassa',
    'débarrasse',
    'débarrassé',
    'débarrâtes',
    'débarrerai',
    'débarreras',
    'débarrerez',
    'débarrions',
    'débâtaient',
    'débâtasses',
    'débâterais',
    'débâterait',
    'débâtèrent',
    'débâteriez',
    'débâterons',
    'débâteront',
    'débâtirais',
    'débâtirait',
    'débâtirent',
    'débâtiriez',
    'débâtirons',
    'débâtiront',
    'débâtisses',
    'débâtissez',
    'débatteurs',
    'débattîmes',
    'débattions',
    'débattisse',
    'débattîtes',
    'débattrais',
    'débattrait',
    'débattriez',
    'débattrons',
    'débattront',
    'débauchage',
    'débauchais',
    'débauchait',
    'débauchant',
    'débauchées',
    'débauchent',
    'débauchera',
    'débauchiez',
    'débauchons',
    'débecqueta',
    'débecqueté',
    'débectâmes',
    'débectasse',
    'débectâtes',
    'débecterai',
    'débecteras',
    'débecterez',
    'débections',
    'débenzolai',
    'débenzolas',
    'débenzolât',
    'débenzolée',
    'débenzoler',
    'débenzoles',
    'débenzolés',
    'débenzolez',
    'débilement',
    'débilitais',
    'débilitait',
    'débilitant',
    'débilitées',
    'débilitent',
    'débilitera',
    'débilitiez',
    'débilitons',
    'débillarda',
    'débillarde',
    'débillardé',
    'débinaient',
    'débinasses',
    'débinerais',
    'débinerait',
    'débinèrent',
    'débineriez',
    'débinerons',
    'débineront',
    'débitables',
    'débitaient',
    'débitantes',
    'débitasses',
    'débiterais',
    'débiterait',
    'débitèrent',
    'débiteriez',
    'débiterons',
    'débiteront',
    'débitmètre',
    'débitrices',
    'déblaierai',
    'déblaieras',
    'déblaierez',
    'déblatérai',
    'déblatéras',
    'déblatérât',
    'déblatérer',
    'déblatères',
    'déblatérez',
    'déblayages',
    'déblayâmes',
    'déblayasse',
    'déblayâtes',
    'déblayerai',
    'déblayeras',
    'déblayerez',
    'déblayions',
    'débleuîmes',
    'débleuirai',
    'débleuiras',
    'débleuirez',
    'débleuisse',
    'débleuîtes',
    'déblocages',
    'débloquais',
    'débloquait',
    'débloquant',
    'débloquées',
    'débloquent',
    'débloquera',
    'débloquiez',
    'débloquons',
    'débobinais',
    'débobinait',
    'débobinant',
    'débobinées',
    'débobinent',
    'débobinera',
    'débobiniez',
    'débobinons',
    'déboguâmes',
    'déboguasse',
    'déboguâtes',
    'déboguerai',
    'débogueras',
    'déboguerez',
    'déboguions',
    'déboisages',
    'déboisâmes',
    'déboisasse',
    'déboisâtes',
    'déboiserai',
    'déboiseras',
    'déboiserez',
    'déboisions',
    'déboîtâmes',
    'déboîtasse',
    'déboîtâtes',
    'déboîterai',
    'déboîteras',
    'déboîterez',
    'déboîtions',
    'débondâmes',
    'débondasse',
    'débondâtes',
    'débonderai',
    'débonderas',
    'débonderez',
    'débondions',
    'débonnaire',
    'débordâmes',
    'débordante',
    'débordants',
    'débordasse',
    'débordâtes',
    'déborderai',
    'déborderas',
    'déborderez',
    'débordions',
    'débosselai',
    'débosselas',
    'débosselât',
    'débosselée',
    'débosseler',
    'débosselés',
    'débosselez',
    'débosselle',
    'débottâmes',
    'débottasse',
    'débottâtes',
    'débotterai',
    'débotteras',
    'débotterez',
    'débottions',
    'débouchage',
    'débouchais',
    'débouchait',
    'débouchant',
    'débouchées',
    'débouchent',
    'débouchera',
    'déboucheur',
    'débouchiez',
    'débouchoir',
    'débouchons',
    'débouclais',
    'débouclait',
    'débouclant',
    'débouclées',
    'débouclent',
    'débouclera',
    'déboucliez',
    'débouclons',
    'déboudâmes',
    'déboudasse',
    'déboudâtes',
    'débouderai',
    'débouderas',
    'débouderez',
    'déboudions',
    'débouilles',
    'débouillez',
    'débouillie',
    'débouillir',
    'débouillis',
    'débouillit',
    'débouillît',
    'déboulâmes',
    'déboulasse',
    'déboulâtes',
    'déboulerai',
    'débouleras',
    'déboulerez',
    'déboulions',
    'déboulonna',
    'déboulonne',
    'déboulonné',
    'débouquais',
    'débouquait',
    'débouquant',
    'débouquent',
    'débouquera',
    'débouquiez',
    'débouquons',
    'débourbage',
    'débourbais',
    'débourbait',
    'débourbant',
    'débourbées',
    'débourbent',
    'débourbera',
    'débourbeur',
    'débourbiez',
    'débourbons',
    'débourrage',
    'débourrais',
    'débourrait',
    'débourrant',
    'débourrées',
    'débourrent',
    'débourrera',
    'débourriez',
    'débourrons',
    'déboursais',
    'déboursait',
    'déboursant',
    'déboursées',
    'déboursent',
    'déboursera',
    'déboursiez',
    'déboursons',
    'déboussola',
    'déboussole',
    'déboussolé',
    'déboutâmes',
    'déboutasse',
    'déboutâtes',
    'débouterai',
    'débouteras',
    'débouterez',
    'déboutions',
    'déboutonna',
    'déboutonne',
    'déboutonné',
    'débraierai',
    'débraieras',
    'débraierez',
    'débraillai',
    'débraillas',
    'débraillât',
    'débraillée',
    'débrailler',
    'débrailles',
    'débraillés',
    'débraillez',
    'débranchai',
    'débranchas',
    'débranchât',
    'débranchée',
    'débrancher',
    'débranches',
    'débranchés',
    'débranchez',
    'débrayages',
    'débrayâmes',
    'débrayasse',
    'débrayâtes',
    'débrayerai',
    'débrayeras',
    'débrayerez',
    'débrayions',
    'débridâmes',
    'débridasse',
    'débridâtes',
    'débriderai',
    'débrideras',
    'débriderez',
    'débridions',
    'débrochage',
    'débrochais',
    'débrochait',
    'débrochant',
    'débrochées',
    'débrochent',
    'débrochera',
    'débrochiez',
    'débrochons',
    'débrouilla',
    'débrouille',
    'débrouillé',
    'débuchâmes',
    'débuchasse',
    'débuchâtes',
    'débucherai',
    'débucheras',
    'débucherez',
    'débuchions',
    'débullâmes',
    'débullasse',
    'débullâtes',
    'débullerai',
    'débulleras',
    'débullerez',
    'débullions',
    'débusquais',
    'débusquait',
    'débusquant',
    'débusquées',
    'débusquent',
    'débusquera',
    'débusquiez',
    'débusquons',
    'débutaient',
    'débutantes',
    'débutasses',
    'débuterais',
    'débuterait',
    'débutèrent',
    'débuteriez',
    'débuterons',
    'débuteront',
    'décachetai',
    'décachetas',
    'décachetât',
    'décachetée',
    'décacheter',
    'décachetés',
    'décachetez',
    'décachette',
    'décadaires',
    'décadences',
    'décadentes',
    'décaféinai',
    'décaféinas',
    'décaféinât',
    'décaféinée',
    'décaféiner',
    'décaféines',
    'décaféinés',
    'décaféinez',
    'décagonale',
    'décagonaux',
    'décagramme',
    'décaissais',
    'décaissait',
    'décaissant',
    'décaissées',
    'décaissent',
    'décaissera',
    'décaissiez',
    'décaissons',
    'décalaient',
    'décalamina',
    'décalamine',
    'décalaminé',
    'décalasses',
    'décalcifia',
    'décalcifie',
    'décalcifié',
    'décalerais',
    'décalerait',
    'décalèrent',
    'décaleriez',
    'décalerons',
    'décaleront',
    'décalitres',
    'décalogues',
    'décalottai',
    'décalottas',
    'décalottât',
    'décalottée',
    'décalotter',
    'décalottes',
    'décalottés',
    'décalottez',
    'décalquage',
    'décalquais',
    'décalquait',
    'décalquant',
    'décalquées',
    'décalquent',
    'décalquera',
    'décalquiez',
    'décalquons',
    'décalvante',
    'décalvants',
    'décamètres',
    'décampâmes',
    'décampasse',
    'décampâtes',
    'décamperai',
    'décamperas',
    'décamperez',
    'décampions',
    'décanillai',
    'décanillas',
    'décanillât',
    'décaniller',
    'décanilles',
    'décanillez',
    'décantâmes',
    'décantasse',
    'décantâtes',
    'décanterai',
    'décanteras',
    'décanterez',
    'décanteurs',
    'décanteuse',
    'décantions',
    'décapaient',
    'décapantes',
    'décapasses',
    'décapelais',
    'décapelait',
    'décapelant',
    'décapelées',
    'décapeliez',
    'décapelles',
    'décapelons',
    'décaperais',
    'décaperait',
    'décapèrent',
    'décaperiez',
    'décaperons',
    'décaperont',
    'décapeuses',
    'décapitais',
    'décapitait',
    'décapitant',
    'décapitées',
    'décapitent',
    'décapitera',
    'décapitiez',
    'décapitons',
    'décapotais',
    'décapotait',
    'décapotant',
    'décapotées',
    'décapotent',
    'décapotera',
    'décapotiez',
    'décapotons',
    'décapsulai',
    'décapsulas',
    'décapsulât',
    'décapsulée',
    'décapsuler',
    'décapsules',
    'décapsulés',
    'décapsulez',
    'décarburai',
    'décarburas',
    'décarburât',
    'décarburée',
    'décarburer',
    'décarbures',
    'décarburés',
    'décarburez',
    'décarcassa',
    'décarcasse',
    'décarcassé',
    'décarrâmes',
    'décarrasse',
    'décarrâtes',
    'décarrelai',
    'décarrelas',
    'décarrelât',
    'décarrelée',
    'décarreler',
    'décarrelés',
    'décarrelez',
    'décarrelle',
    'décarrerai',
    'décarreras',
    'décarrerez',
    'décarrions',
    'décartonna',
    'décartonne',
    'décartonné',
    'décathlons',
    'décatirais',
    'décatirait',
    'décatirent',
    'décatiriez',
    'décatirons',
    'décatiront',
    'décatisses',
    'décatissez',
    'décausâmes',
    'décausasse',
    'décausâtes',
    'décauserai',
    'décauseras',
    'décauserez',
    'décausions',
    'decauville',
    'décédaient',
    'décédasses',
    'décéderais',
    'décéderait',
    'décédèrent',
    'décéderiez',
    'décéderons',
    'décéderont',
    'décelables',
    'décelaient',
    'décelasses',
    'décèlement',
    'décélérais',
    'décèlerais',
    'décélérait',
    'décèlerait',
    'décélérant',
    'décelèrent',
    'décélèrent',
    'décélérera',
    'décélériez',
    'décèleriez',
    'décélérons',
    'décèlerons',
    'décèleront',
    'décemviral',
    'décemvirat',
    'décennales',
    'décentrais',
    'décentrait',
    'décentrant',
    'décentrées',
    'décentrent',
    'décentrera',
    'décentriez',
    'décentrons',
    'déceptions',
    'décerclais',
    'décerclait',
    'décerclant',
    'décerclées',
    'décerclent',
    'décerclera',
    'décercliez',
    'décerclons',
    'décernâmes',
    'décernasse',
    'décernâtes',
    'décernerai',
    'décerneras',
    'décernerez',
    'décernions',
    'décessâmes',
    'décessasse',
    'décessâtes',
    'décesserai',
    'décesseras',
    'décesserez',
    'décessions',
    'décevaient',
    'décevantes',
    'décevrions',
    'déchagrina',
    'déchagrine',
    'déchagriné',
    'déchaînais',
    'déchaînait',
    'déchaînant',
    'déchaînées',
    'déchaînent',
    'déchaînera',
    'déchaîniez',
    'déchaînons',
    'déchantais',
    'déchantait',
    'déchantant',
    'déchantent',
    'déchantera',
    'déchantiez',
    'déchantons',
    'déchapâmes',
    'déchapasse',
    'déchapâtes',
    'déchaperai',
    'déchaperas',
    'déchaperez',
    'déchapions',
    'déchargeai',
    'déchargeas',
    'déchargeât',
    'déchargées',
    'déchargent',
    'déchargera',
    'déchargiez',
    'décharnais',
    'décharnait',
    'décharnant',
    'décharnées',
    'décharnent',
    'décharnera',
    'décharniez',
    'décharnons',
    'déchaumage',
    'déchaumais',
    'déchaumait',
    'déchaumant',
    'déchaumées',
    'déchaument',
    'déchaumera',
    'déchaumiez',
    'déchaumons',
    'déchaussai',
    'déchaussas',
    'déchaussât',
    'déchaussée',
    'déchausser',
    'déchausses',
    'déchaussés',
    'déchaussez',
    'déchéances',
    'déchevêtra',
    'déchevêtre',
    'déchevêtré',
    'déchevilla',
    'décheville',
    'déchevillé',
    'déchiffrai',
    'déchiffras',
    'déchiffrât',
    'déchiffrée',
    'déchiffrer',
    'déchiffres',
    'déchiffrés',
    'déchiffrez',
    'déchiqueta',
    'déchiqueté',
    'déchirâmes',
    'déchirante',
    'déchirants',
    'déchirasse',
    'déchirâtes',
    'déchirerai',
    'déchireras',
    'déchirerez',
    'déchirions',
    'déchirures',
    'déchlorura',
    'déchlorure',
    'déchloruré',
    'déchoirais',
    'déchoirait',
    'déchoiriez',
    'déchoirons',
    'déchoiront',
    'déchoyions',
    'déchromais',
    'déchromait',
    'déchromant',
    'déchromées',
    'déchroment',
    'déchromera',
    'déchromiez',
    'déchromons',
    'déchussent',
    'déchussiez',
    'décidables',
    'décidaient',
    'décidasses',
    'décidément',
    'déciderais',
    'déciderait',
    'décidèrent',
    'décideriez',
    'déciderons',
    'décideront',
    'décideuses',
    'déciduales',
    'décigrades',
    'décigramme',
    'décilitres',
    'décimaient',
    'décimalisa',
    'décimalise',
    'décimalisé',
    'décimalité',
    'décimasses',
    'décimation',
    'décimerais',
    'décimerait',
    'décimèrent',
    'décimeriez',
    'décimerons',
    'décimeront',
    'décimètres',
    'décintrage',
    'décintrais',
    'décintrait',
    'décintrant',
    'décintrées',
    'décintrent',
    'décintrera',
    'décintriez',
    'décintrons',
    'décisoires',
    'déclamâmes',
    'déclamasse',
    'déclamâtes',
    'déclamerai',
    'déclameras',
    'déclamerez',
    'déclamions',
    'déclarâmes',
    'déclarante',
    'déclarants',
    'déclarasse',
    'déclarâtes',
    'déclaratif',
    'déclarerai',
    'déclareras',
    'déclarerez',
    'déclarions',
    'déclassais',
    'déclassait',
    'déclassant',
    'déclassées',
    'déclassent',
    'déclassera',
    'déclassiez',
    'déclassons',
    'déclavetai',
    'déclavetas',
    'déclavetât',
    'déclavetée',
    'déclaveter',
    'déclavetés',
    'déclavetez',
    'déclavette',
    'déclenchai',
    'déclenchas',
    'déclenchât',
    'déclenchée',
    'déclencher',
    'déclenches',
    'déclenchés',
    'déclenchez',
    'déclinable',
    'déclinâmes',
    'déclinante',
    'déclinants',
    'déclinasse',
    'déclinâtes',
    'déclinerai',
    'déclineras',
    'déclinerez',
    'déclinions',
    'décliqueta',
    'décliqueté',
    'déclivités',
    'déclorions',
    'déclouâmes',
    'déclouasse',
    'déclouâtes',
    'déclouerai',
    'décloueras',
    'déclouerez',
    'déclouions',
    'décochages',
    'décochâmes',
    'décochasse',
    'décochâtes',
    'décocherai',
    'décocheras',
    'décocherez',
    'décochions',
    'décoctions',
    'décodaient',
    'décodasses',
    'décoderais',
    'décoderait',
    'décodèrent',
    'décoderiez',
    'décoderons',
    'décoderont',
    'décodeuses',
    'décoffrage',
    'décoffrais',
    'décoffrait',
    'décoffrant',
    'décoffrées',
    'décoffrent',
    'décoffrera',
    'décoffriez',
    'décoffrons',
    'décoiffage',
    'décoiffais',
    'décoiffait',
    'décoiffant',
    'décoiffées',
    'décoiffent',
    'décoiffera',
    'décoiffiez',
    'décoiffons',
    'décoinçais',
    'décoinçait',
    'décoinçant',
    'décoincées',
    'décoincent',
    'décoincera',
    'décoinciez',
    'décoinçons',
    'décolérais',
    'décolérait',
    'décolérant',
    'décolèrent',
    'décolérera',
    'décolériez',
    'décolérons',
    'décollages',
    'décollâmes',
    'décollasse',
    'décollâtes',
    'décollerai',
    'décolleras',
    'décollerez',
    'décolletai',
    'décolletas',
    'décolletât',
    'décolletée',
    'décolleter',
    'décolletés',
    'décolletez',
    'décollette',
    'décollions',
    'décolonisa',
    'décolonise',
    'décolonisé',
    'décolorais',
    'décolorait',
    'décolorant',
    'décolorées',
    'décolorent',
    'décolorera',
    'décoloriez',
    'décolorons',
    'décommanda',
    'décommande',
    'décommandé',
    'décomplexa',
    'décomplexe',
    'décomplexé',
    'décomposai',
    'décomposas',
    'décomposât',
    'décomposée',
    'décomposer',
    'décomposes',
    'décomposés',
    'décomposez',
    'décomprima',
    'décomprime',
    'décomprimé',
    'décomptais',
    'décomptait',
    'décomptant',
    'décomptées',
    'décomptent',
    'décomptera',
    'décomptiez',
    'décomptons',
    'déconcerta',
    'déconcerte',
    'déconcerté',
    'déconfîmes',
    'déconfirai',
    'déconfiras',
    'déconfirez',
    'déconfises',
    'déconfisez',
    'déconfisse',
    'déconfites',
    'déconfîtes',
    'décongelai',
    'décongelas',
    'décongelât',
    'décongelée',
    'décongeler',
    'décongelés',
    'décongèles',
    'décongelez',
    'déconnâmes',
    'déconnasse',
    'déconnâtes',
    'déconnecta',
    'déconnecte',
    'déconnecté',
    'déconnerai',
    'déconneras',
    'déconnerez',
    'déconnions',
    'déconsigna',
    'déconsigne',
    'déconsigné',
    'déconstipa',
    'déconstipe',
    'déconstipé',
    'déconvenue',
    'décoraient',
    'décorasses',
    'décorateur',
    'décoratifs',
    'décoration',
    'décorative',
    'décordâmes',
    'décordasse',
    'décordâtes',
    'décorderai',
    'décorderas',
    'décorderez',
    'décordions',
    'décorerais',
    'décorerait',
    'décorèrent',
    'décoreriez',
    'décorerons',
    'décoreront',
    'décornâmes',
    'décornasse',
    'décornâtes',
    'décornerai',
    'décorneras',
    'décornerez',
    'décornions',
    'décortiqua',
    'décortique',
    'décortiqué',
    'découchais',
    'découchait',
    'découchant',
    'découchent',
    'découchera',
    'découchiez',
    'découchons',
    'découdrais',
    'découdrait',
    'découdriez',
    'découdrons',
    'découdront',
    'découlâmes',
    'découlasse',
    'découlâtes',
    'découlerai',
    'découleras',
    'découlerez',
    'découlions',
    'découpages',
    'découpâmes',
    'découpasse',
    'découpâtes',
    'découperai',
    'découperas',
    'découperez',
    'découpeurs',
    'découpeuse',
    'découpions',
    'découplage',
    'découplais',
    'découplait',
    'découplant',
    'découplées',
    'découplent',
    'découplera',
    'découpliez',
    'découplons',
    'découpoirs',
    'découpures',
    'découragea',
    'découragée',
    'décourager',
    'décourages',
    'découragés',
    'découragez',
    'découronna',
    'découronne',
    'découronné',
    'décousîmes',
    'décousions',
    'décousisse',
    'décousîtes',
    'décousures',
    'découverte',
    'découverts',
    'découvrais',
    'découvrait',
    'découvrant',
    'découvrent',
    'découvreur',
    'découvriez',
    'découvrira',
    'découvrons',
    'découvrues',
    'décrassage',
    'décrassais',
    'décrassait',
    'décrassant',
    'décrassées',
    'décrassent',
    'décrassera',
    'décrassiez',
    'décrassons',
    'décréditai',
    'décréditas',
    'décréditât',
    'décréditée',
    'décréditer',
    'décrédites',
    'décrédités',
    'décréditez',
    'décréments',
    'décrêpages',
    'décrêpâmes',
    'décrêpasse',
    'décrêpâtes',
    'décrêperai',
    'décrêperas',
    'décrêperez',
    'décrépîmes',
    'décrêpions',
    'décrépirai',
    'décrépiras',
    'décrépirez',
    'décrépisse',
    'décrépitai',
    'décrépitas',
    'décrépitât',
    'décrépitée',
    'décrépiter',
    'décrépites',
    'décrépités',
    'décrépîtes',
    'décrépitez',
    'décrétâmes',
    'décrétasse',
    'décrétâtes',
    'décréterai',
    'décréteras',
    'décréterez',
    'décrétions',
    'décret-loi',
    'décreusage',
    'décreusais',
    'décreusait',
    'décreusant',
    'décreusées',
    'décreusent',
    'décreusera',
    'décreusiez',
    'décreusons',
    'décriaient',
    'décriasses',
    'décrierais',
    'décrierait',
    'décrièrent',
    'décrieriez',
    'décrierons',
    'décrieront',
    'décririons',
    'décrispais',
    'décrispait',
    'décrispant',
    'décrispées',
    'décrispent',
    'décrispera',
    'décrispiez',
    'décrispons',
    'décrivîmes',
    'décrivions',
    'décrivisse',
    'décrivîtes',
    'décrochage',
    'décrochais',
    'décrochait',
    'décrochant',
    'décrochées',
    'décrochent',
    'décrochera',
    'décrochiez',
    'décrochons',
    'décroisais',
    'décroisait',
    'décroisant',
    'décroisées',
    'décroisent',
    'décroisera',
    'décroisiez',
    'décroisons',
    'décroisses',
    'décroissez',
    'décroîtrai',
    'décroîtras',
    'décroîtrez',
    'décrottage',
    'décrottais',
    'décrottait',
    'décrottant',
    'décrottées',
    'décrottent',
    'décrottera',
    'décrotteur',
    'décrottiez',
    'décrottoir',
    'décrottons',
    'décroûtais',
    'décroûtait',
    'décroûtant',
    'décroûtées',
    'décroûtent',
    'décroûtera',
    'décroûtiez',
    'décroûtons',
    'décruaient',
    'décruasses',
    'décruerais',
    'décruerait',
    'décruèrent',
    'décrueriez',
    'décruerons',
    'décrueront',
    'décrusages',
    'décrusâmes',
    'décrusasse',
    'décrusâtes',
    'décruserai',
    'décruseras',
    'décruserez',
    'décrusions',
    'décrussent',
    'décrussiez',
    'décryptage',
    'décryptais',
    'décryptait',
    'décryptant',
    'décryptées',
    'décryptent',
    'décryptera',
    'décryptiez',
    'décryptons',
    'décuivrais',
    'décuivrait',
    'décuivrant',
    'décuivrées',
    'décuivrent',
    'décuivrera',
    'décuivriez',
    'décuivrons',
    'déculassai',
    'déculassas',
    'déculassât',
    'déculassée',
    'déculasser',
    'déculasses',
    'déculassés',
    'déculassez',
    'déculottai',
    'déculottas',
    'déculottât',
    'déculottée',
    'déculotter',
    'déculottes',
    'déculottés',
    'déculottez',
    'décuplâmes',
    'décuplasse',
    'décuplâtes',
    'décuplerai',
    'décupleras',
    'décuplerez',
    'décuplions',
    'décurrente',
    'décurrents',
    'déçussions',
    'décuvaient',
    'décuvaison',
    'décuvasses',
    'décuverais',
    'décuverait',
    'décuvèrent',
    'décuveriez',
    'décuverons',
    'décuveront',
    'dédaignais',
    'dédaignait',
    'dédaignant',
    'dédaignées',
    'dédaignent',
    'dédaignera',
    'dédaigneux',
    'dédaigniez',
    'dédaignons',
    'dédaléenne',
    'dédiassent',
    'dédiassiez',
    'dédicaçais',
    'dédicaçait',
    'dédicaçant',
    'dédicacées',
    'dédicacent',
    'dédicacera',
    'dédicaciez',
    'dédicaçons',
    'dédierions',
    'dédiraient',
    'dédisaient',
    'dédissions',
    'dédommagea',
    'dédommagée',
    'dédommager',
    'dédommages',
    'dédommagés',
    'dédommagez',
    'dédoraient',
    'dédorasses',
    'dédorerais',
    'dédorerait',
    'dédorèrent',
    'dédoreriez',
    'dédorerons',
    'dédoreront',
    'dédouanais',
    'dédouanait',
    'dédouanant',
    'dédouanées',
    'dédouanent',
    'dédouanera',
    'dédouaniez',
    'dédouanons',
    'dédoublage',
    'dédoublais',
    'dédoublait',
    'dédoublant',
    'dédoublées',
    'dédoublent',
    'dédoublera',
    'dédoubliez',
    'dédoublons',
    'déductible',
    'déductions',
    'déductives',
    'déduirions',
    'déduisîmes',
    'déduisions',
    'déduisisse',
    'déduisîtes',
    'défaillais',
    'défaillait',
    'défaillant',
    'défaillent',
    'défaillies',
    'défailliez',
    'défaillira',
    'défaillons',
    'défaisions',
    'défaitisme',
    'défaitiste',
    'défalquais',
    'défalquait',
    'défalquant',
    'défalquées',
    'défalquent',
    'défalquera',
    'défalquiez',
    'défalquons',
    'défardâmes',
    'défardasse',
    'défardâtes',
    'défarderai',
    'défarderas',
    'défarderez',
    'défardions',
    'défassions',
    'défatiguai',
    'défatiguas',
    'défatiguât',
    'défatiguée',
    'défatiguer',
    'défatigues',
    'défatigués',
    'défatiguez',
    'défaufilai',
    'défaufilas',
    'défaufilât',
    'défaufilée',
    'défaufiler',
    'défaufiles',
    'défaufilés',
    'défaufilez',
    'défaussais',
    'défaussait',
    'défaussant',
    'défaussées',
    'défaussent',
    'défaussera',
    'défaussiez',
    'défaussons',
    'défavorisa',
    'défavorise',
    'défavorisé',
    'défécation',
    'défections',
    'défectives',
    'défectueux',
    'défendable',
    'défendeurs',
    'défendîmes',
    'défendions',
    'défendisse',
    'défendîtes',
    'défendrais',
    'défendrait',
    'défendriez',
    'défendrons',
    'défendront',
    'défenseurs',
    'défensives',
    'déféquâmes',
    'déféquasse',
    'déféquâtes',
    'déféquerai',
    'déféqueras',
    'déféquerez',
    'déféquions',
    'déferaient',
    'déféraient',
    'déférasses',
    'déférences',
    'déférentes',
    'déférerais',
    'déférerait',
    'déférèrent',
    'déféreriez',
    'déférerons',
    'déféreront',
    'déferlâmes',
    'déferlante',
    'déferlants',
    'déferlasse',
    'déferlâtes',
    'déferlerai',
    'déferleras',
    'déferlerez',
    'déferlions',
    'déferrages',
    'déferrâmes',
    'déferrasse',
    'déferrâtes',
    'déferrerai',
    'déferreras',
    'déferrerez',
    'déferrions',
    'déferrisai',
    'déferrisas',
    'déferrisât',
    'déferrisée',
    'déferriser',
    'déferrises',
    'déferrisés',
    'déferrisez',
    'déferrures',
    'défeuillai',
    'défeuillas',
    'défeuillât',
    'défeuillée',
    'défeuiller',
    'défeuilles',
    'défeuillés',
    'défeuillez',
    'défeutrais',
    'défeutrait',
    'défeutrant',
    'défeutrées',
    'défeutrent',
    'défeutrera',
    'défeutriez',
    'défeutrons',
    'défiassent',
    'défiassiez',
    'défibrages',
    'défibrâmes',
    'défibrasse',
    'défibrâtes',
    'défibrerai',
    'défibreras',
    'défibrerez',
    'défibreurs',
    'défibreuse',
    'défibrions',
    'déficelais',
    'déficelait',
    'déficelant',
    'déficelées',
    'déficeliez',
    'déficelles',
    'déficelons',
    'défichâmes',
    'défichasse',
    'défichâtes',
    'déficherai',
    'déficheras',
    'déficherez',
    'défichions',
    'déficience',
    'déficiente',
    'déficients',
    'défierions',
    'défigeâmes',
    'défigeasse',
    'défigeâtes',
    'défigerais',
    'défigerait',
    'défigèrent',
    'défigeriez',
    'défigerons',
    'défigeront',
    'défigurais',
    'défigurait',
    'défigurant',
    'défigurées',
    'défigurent',
    'défigurera',
    'défiguriez',
    'défigurons',
    'défilaient',
    'défilasses',
    'défilement',
    'défilerais',
    'défilerait',
    'défilèrent',
    'défileriez',
    'défilerons',
    'défileront',
    'défileuses',
    'définirais',
    'définirait',
    'définirent',
    'définiriez',
    'définirons',
    'définiront',
    'définisses',
    'définissez',
    'définiteur',
    'définitifs',
    'définition',
    'définitive',
    'défissions',
    'déflagrais',
    'déflagrait',
    'déflagrant',
    'déflagrent',
    'déflagrera',
    'déflagriez',
    'déflagrons',
    'déflaquais',
    'déflaquait',
    'déflaquant',
    'déflaquent',
    'déflaquera',
    'déflaquiez',
    'déflaquons',
    'déflations',
    'déflecteur',
    'défleuries',
    'défleurira',
    'déflorâmes',
    'déflorasse',
    'déflorâtes',
    'déflorerai',
    'défloreras',
    'déflorerez',
    'déflorions',
    'défoliâmes',
    'défoliante',
    'défoliants',
    'défoliasse',
    'défoliâtes',
    'défolierai',
    'défolieras',
    'défolierez',
    'défoliions',
    'défonçages',
    'défonçâmes',
    'défonçasse',
    'défonçâtes',
    'défoncerai',
    'défonceras',
    'défoncerez',
    'défonceuse',
    'défoncions',
    'déforçâmes',
    'déforçasse',
    'déforçâtes',
    'déforcerai',
    'déforceras',
    'déforcerez',
    'déforcions',
    'déformable',
    'déformâmes',
    'déformante',
    'déformants',
    'déformasse',
    'déformâtes',
    'déformerai',
    'déformeras',
    'déformerez',
    'déformions',
    'défoulâmes',
    'défoulasse',
    'défoulâtes',
    'défoulerai',
    'défouleras',
    'défoulerez',
    'défoulions',
    'défournage',
    'défournais',
    'défournait',
    'défournant',
    'défournées',
    'défournent',
    'défournera',
    'défourneur',
    'défourniez',
    'défournons',
    'défourrais',
    'défourrait',
    'défourrant',
    'défourrées',
    'défourrent',
    'défourrera',
    'défourriez',
    'défourrons',
    'défraîchie',
    'défraîchir',
    'défraîchis',
    'défraîchit',
    'défraîchît',
    'défraierai',
    'défraieras',
    'défraierez',
    'défranchie',
    'défranchis',
    'défrancisa',
    'défrancise',
    'défrancisé',
    'défrayâmes',
    'défrayasse',
    'défrayâtes',
    'défrayerai',
    'défrayeras',
    'défrayerez',
    'défrayions',
    'défrettais',
    'défrettait',
    'défrettant',
    'défrettées',
    'défrettent',
    'défrettera',
    'défrettiez',
    'défrettons',
    'défrichage',
    'défrichais',
    'défrichait',
    'défrichant',
    'défrichées',
    'défrichent',
    'défrichera',
    'défricheur',
    'défrichiez',
    'défrichons',
    'défringuai',
    'défringuas',
    'défringuât',
    'défringuée',
    'défringuer',
    'défringues',
    'défringués',
    'défringuez',
    'défripâmes',
    'défripasse',
    'défripâtes',
    'défriperai',
    'défriperas',
    'défriperez',
    'défripions',
    'défrisâmes',
    'défrisasse',
    'défrisâtes',
    'défriserai',
    'défriseras',
    'défriserez',
    'défrisions',
    'défroissai',
    'défroissas',
    'défroissât',
    'défroissée',
    'défroisser',
    'défroisses',
    'défroissés',
    'défroissez',
    'défronçais',
    'défronçait',
    'défronçant',
    'défroncées',
    'défroncent',
    'défroncera',
    'défronciez',
    'défronçons',
    'défroquais',
    'défroquait',
    'défroquant',
    'défroquées',
    'défroquent',
    'défroquera',
    'défroquiez',
    'défroquons',
    'défruitais',
    'défruitait',
    'défruitant',
    'défruitées',
    'défruitent',
    'défruitera',
    'défruitiez',
    'défruitons',
    'dégageâmes',
    'dégageasse',
    'dégageâtes',
    'dégagement',
    'dégagerais',
    'dégagerait',
    'dégagèrent',
    'dégageriez',
    'dégagerons',
    'dégageront',
    'dégainâmes',
    'dégainasse',
    'dégainâtes',
    'dégainerai',
    'dégaineras',
    'dégainerez',
    'dégainions',
    'dégalonnai',
    'dégalonnas',
    'dégalonnât',
    'dégalonnée',
    'dégalonner',
    'dégalonnes',
    'dégalonnés',
    'dégalonnez',
    'dégantâmes',
    'dégantasse',
    'dégantâtes',
    'déganterai',
    'déganteras',
    'déganterez',
    'dégantions',
    'dégarnîmes',
    'dégarnirai',
    'dégarniras',
    'dégarnirez',
    'dégarnisse',
    'dégarnîtes',
    'dégasolina',
    'dégasoline',
    'dégasoliné',
    'dégauchies',
    'dégauchira',
    'dégazaient',
    'dégazasses',
    'dégazerais',
    'dégazerait',
    'dégazèrent',
    'dégazeriez',
    'dégazerons',
    'dégazeront',
    'dégazolina',
    'dégazoline',
    'dégazoliné',
    'dégazonnai',
    'dégazonnas',
    'dégazonnât',
    'dégazonnée',
    'dégazonner',
    'dégazonnes',
    'dégazonnés',
    'dégazonnez',
    'dégelaient',
    'dégelasses',
    'dégèlerais',
    'dégèlerait',
    'dégelèrent',
    'dégèleriez',
    'dégèlerons',
    'dégèleront',
    'dégénérais',
    'dégénérait',
    'dégénérant',
    'dégénèrent',
    'dégénérera',
    'dégénériez',
    'dégénérons',
    'dégermâmes',
    'dégermasse',
    'dégermâtes',
    'dégermerai',
    'dégermeras',
    'dégermerez',
    'dégermions',
    'déginganda',
    'dégingande',
    'dégingandé',
    'dégîtaient',
    'dégîtasses',
    'dégîterais',
    'dégîterait',
    'dégîtèrent',
    'dégîteriez',
    'dégîterons',
    'dégîteront',
    'dégivrages',
    'dégivrâmes',
    'dégivrasse',
    'dégivrâtes',
    'dégivrerai',
    'dégivreras',
    'dégivrerez',
    'dégivreurs',
    'dégivrions',
    'déglaçâmes',
    'déglaçasse',
    'déglaçâtes',
    'déglacerai',
    'déglaceras',
    'déglacerez',
    'déglacions',
    'déglinguai',
    'déglinguas',
    'déglinguât',
    'déglinguée',
    'déglinguer',
    'déglingues',
    'déglingués',
    'déglinguez',
    'dégluaient',
    'dégluasses',
    'dégluerais',
    'dégluerait',
    'dégluèrent',
    'déglueriez',
    'dégluerons',
    'déglueront',
    'déglutîmes',
    'déglutirai',
    'déglutiras',
    'déglutirez',
    'déglutisse',
    'déglutîtes',
    'dégobillai',
    'dégobillas',
    'dégobillât',
    'dégobillée',
    'dégobiller',
    'dégobilles',
    'dégobillés',
    'dégobillez',
    'dégoisâmes',
    'dégoisasse',
    'dégoisâtes',
    'dégoiserai',
    'dégoiseras',
    'dégoiserez',
    'dégoisions',
    'dégommages',
    'dégommâmes',
    'dégommasse',
    'dégommâtes',
    'dégommerai',
    'dégommeras',
    'dégommerez',
    'dégommions',
    'dégondâmes',
    'dégondasse',
    'dégondâtes',
    'dégonderai',
    'dégonderas',
    'dégonderez',
    'dégondions',
    'dégonflage',
    'dégonflais',
    'dégonflait',
    'dégonflant',
    'dégonflées',
    'dégonflent',
    'dégonflera',
    'dégonfliez',
    'dégonflons',
    'dégorgeais',
    'dégorgeait',
    'dégorgeant',
    'dégorgeoir',
    'dégorgeons',
    'dégorgerai',
    'dégorgeras',
    'dégorgerez',
    'dégorgions',
    'dégotaient',
    'dégotasses',
    'dégoterais',
    'dégoterait',
    'dégotèrent',
    'dégoteriez',
    'dégoterons',
    'dégoteront',
    'dégottâmes',
    'dégottasse',
    'dégottâtes',
    'dégotterai',
    'dégotteras',
    'dégotterez',
    'dégottions',
    'dégoulinai',
    'dégoulinas',
    'dégoulinât',
    'dégouliner',
    'dégoulines',
    'dégoulinez',
    'dégoupilla',
    'dégoupille',
    'dégoupillé',
    'dégourdies',
    'dégourdira',
    'dégoûtâmes',
    'dégoûtante',
    'dégoûtants',
    'dégoûtasse',
    'dégoûtâtes',
    'dégoûterai',
    'dégoûteras',
    'dégoûterez',
    'dégoûtions',
    'dégouttais',
    'dégouttait',
    'dégouttant',
    'dégouttées',
    'dégouttent',
    'dégouttera',
    'dégouttiez',
    'dégouttons',
    'dégradâmes',
    'dégradante',
    'dégradants',
    'dégradasse',
    'dégradâtes',
    'dégraderai',
    'dégraderas',
    'dégraderez',
    'dégradions',
    'dégrafâmes',
    'dégrafasse',
    'dégrafâtes',
    'dégraferai',
    'dégraferas',
    'dégraferez',
    'dégrafions',
    'dégraissai',
    'dégraissas',
    'dégraissât',
    'dégraissée',
    'dégraisser',
    'dégraisses',
    'dégraissés',
    'dégraissez',
    'dégravoies',
    'dégravoyai',
    'dégravoyas',
    'dégravoyât',
    'dégravoyée',
    'dégravoyer',
    'dégravoyés',
    'dégravoyez',
    'dégréaient',
    'dégréasses',
    'dégréerais',
    'dégréerait',
    'dégréèrent',
    'dégréeriez',
    'dégréerons',
    'dégréeront',
    'dégressifs',
    'dégressive',
    'dégrevâmes',
    'dégrevasse',
    'dégrevâtes',
    'dégrèverai',
    'dégrèveras',
    'dégrèverez',
    'dégrevions',
    'dégriffées',
    'dégringola',
    'dégringole',
    'dégringolé',
    'dégrippais',
    'dégrippait',
    'dégrippant',
    'dégrippées',
    'dégrippent',
    'dégrippera',
    'dégrippiez',
    'dégrippons',
    'dégrisâmes',
    'dégrisasse',
    'dégrisâtes',
    'dégriserai',
    'dégriseras',
    'dégriserez',
    'dégrisions',
    'dégrossais',
    'dégrossait',
    'dégrossant',
    'dégrossées',
    'dégrossent',
    'dégrossera',
    'dégrossies',
    'dégrossiez',
    'dégrossira',
    'dégrossons',
    'dégrouilla',
    'dégrouille',
    'dégrouillé',
    'déguenillé',
    'déguerpies',
    'déguerpira',
    'dégueulais',
    'dégueulait',
    'dégueulant',
    'dégueulées',
    'dégueulent',
    'dégueulera',
    'dégueuliez',
    'dégueulons',
    'déguisâmes',
    'déguisasse',
    'déguisâtes',
    'déguiserai',
    'déguiseras',
    'déguiserez',
    'déguisions',
    'dégurgitai',
    'dégurgitas',
    'dégurgitât',
    'dégurgitée',
    'dégurgiter',
    'dégurgites',
    'dégurgités',
    'dégurgitez',
    'dégustâmes',
    'dégustasse',
    'dégustâtes',
    'dégusterai',
    'dégusteras',
    'dégusterez',
    'dégustions',
    'déhalaient',
    'déhalasses',
    'déhalerais',
    'déhalerait',
    'déhalèrent',
    'déhaleriez',
    'déhalerons',
    'déhaleront',
    'déhanchais',
    'déhanchait',
    'déhanchant',
    'déhanchées',
    'déhanchent',
    'déhanchera',
    'déhanchiez',
    'déhanchons',
    'déhardâmes',
    'déhardasse',
    'déhardâtes',
    'déharderai',
    'déharderas',
    'déharderez',
    'déhardions',
    'déharnacha',
    'déharnache',
    'déharnaché',
    'déhiscence',
    'déhiscente',
    'déhiscents',
    'déhottâmes',
    'déhottasse',
    'déhottâtes',
    'déhotterai',
    'déhotteras',
    'déhotterez',
    'déhottions',
    'déhouillai',
    'déhouillas',
    'déhouillât',
    'déhouillée',
    'déhouiller',
    'déhouilles',
    'déhouillés',
    'déhouillez',
    'déictiques',
    'déifiaient',
    'déifiasses',
    'déifierais',
    'déifierait',
    'déifièrent',
    'déifieriez',
    'déifierons',
    'déifieront',
    'déjantâmes',
    'déjantasse',
    'déjantâtes',
    'déjanterai',
    'déjanteras',
    'déjanterez',
    'déjantions',
    'déjaugeais',
    'déjaugeait',
    'déjaugeant',
    'déjaugeons',
    'déjaugerai',
    'déjaugeras',
    'déjaugerez',
    'déjaugions',
    'déjaunîmes',
    'déjaunirai',
    'déjauniras',
    'déjaunirez',
    'déjaunisse',
    'déjaunîtes',
    'déjections',
    'déjetaient',
    'déjetasses',
    'déjetèrent',
    'déjetterai',
    'déjetteras',
    'déjetterez',
    'déjeunâmes',
    'déjeunasse',
    'déjeunâtes',
    'déjeunerai',
    'déjeuneras',
    'déjeunerez',
    'déjeunions',
    'déjouaient',
    'déjouasses',
    'déjouerais',
    'déjouerait',
    'déjouèrent',
    'déjoueriez',
    'déjouerons',
    'déjoueront',
    'déjuchâmes',
    'déjuchasse',
    'déjuchâtes',
    'déjucherai',
    'déjucheras',
    'déjucherez',
    'déjuchions',
    'déjugeâmes',
    'déjugeasse',
    'déjugeâtes',
    'déjugerais',
    'déjugerait',
    'déjugèrent',
    'déjugeriez',
    'déjugerons',
    'déjugeront',
    'délabrâmes',
    'délabrasse',
    'délabrâtes',
    'délabrerai',
    'délabreras',
    'délabrerez',
    'délabrions',
    'délaçaient',
    'délaçasses',
    'délacerais',
    'délacerait',
    'délacèrent',
    'délaceriez',
    'délacerons',
    'délaceront',
    'délaierais',
    'délaierait',
    'délaieriez',
    'délaierons',
    'délaieront',
    'délainages',
    'délainâmes',
    'délainasse',
    'délainâtes',
    'délainerai',
    'délaineras',
    'délainerez',
    'délainions',
    'délaissais',
    'délaissait',
    'délaissant',
    'délaissées',
    'délaissent',
    'délaissera',
    'délaissiez',
    'délaissons',
    'délaitâmes',
    'délaitasse',
    'délaitâtes',
    'délaiterai',
    'délaiteras',
    'délaiterez',
    'délaiteuse',
    'délaitions',
    'délardâmes',
    'délardasse',
    'délardâtes',
    'délarderai',
    'délarderas',
    'délarderez',
    'délardions',
    'délassâmes',
    'délassante',
    'délassants',
    'délassasse',
    'délassâtes',
    'délasserai',
    'délasseras',
    'délasserez',
    'délassions',
    'délatrices',
    'délattâmes',
    'délattasse',
    'délattâtes',
    'délatterai',
    'délatteras',
    'délatterez',
    'délattions',
    'délavaient',
    'délavasses',
    'délaverais',
    'délaverait',
    'délavèrent',
    'délaveriez',
    'délaverons',
    'délaveront',
    'délayaient',
    'délayasses',
    'délayerais',
    'délayerait',
    'délayèrent',
    'délayeriez',
    'délayerons',
    'délayeront',
    'délectable',
    'délectâmes',
    'délectasse',
    'délectâtes',
    'délecterai',
    'délecteras',
    'délecterez',
    'délections',
    'délégation',
    'déléguâmes',
    'déléguasse',
    'déléguâtes',
    'déléguerai',
    'délégueras',
    'déléguerez',
    'déléguions',
    'délestages',
    'délestâmes',
    'délestasse',
    'délestâtes',
    'délesterai',
    'délesteras',
    'délesterez',
    'délestions',
    'déliassent',
    'déliassiez',
    'délibérais',
    'délibérait',
    'délibérant',
    'délibérées',
    'délibèrent',
    'délibérera',
    'délibériez',
    'délibérons',
    'délicieuse',
    'délictueux',
    'déliements',
    'délierions',
    'délignifia',
    'délignifie',
    'délignifié',
    'délimitais',
    'délimitait',
    'délimitant',
    'délimitées',
    'délimitent',
    'délimitera',
    'délimiteur',
    'délimitiez',
    'délimitons',
    'délinéâmes',
    'délinéasse',
    'délinéâtes',
    'délinéerai',
    'délinéeras',
    'délinéerez',
    'délinéions',
    'délinquant',
    'déliraient',
    'délirantes',
    'délirasses',
    'délirerais',
    'délirerait',
    'délirèrent',
    'délireriez',
    'délirerons',
    'délireront',
    'délissages',
    'délissâmes',
    'délissasse',
    'délissâtes',
    'délisserai',
    'délisseras',
    'délisserez',
    'délissions',
    'délitaient',
    'délitasses',
    'déliterais',
    'déliterait',
    'délitèrent',
    'déliteriez',
    'déliterons',
    'déliteront',
    'délivrâmes',
    'délivrance',
    'délivrasse',
    'délivrâtes',
    'délivrerai',
    'délivreras',
    'délivrerez',
    'délivreurs',
    'délivrions',
    'délogeâmes',
    'délogeasse',
    'délogeâtes',
    'délogement',
    'délogerais',
    'délogerait',
    'délogèrent',
    'délogeriez',
    'délogerons',
    'délogeront',
    'déloquâmes',
    'déloquasse',
    'déloquâtes',
    'déloquerai',
    'déloqueras',
    'déloquerez',
    'déloquions',
    'délovaient',
    'délovasses',
    'déloverais',
    'déloverait',
    'délovèrent',
    'déloveriez',
    'déloverons',
    'déloveront',
    'déloyautés',
    'delphinium',
    'deltacisme',
    'deltaïques',
    'deltaplane',
    'deltoïdien',
    'déluraient',
    'délurasses',
    'délurerais',
    'délurerait',
    'délurèrent',
    'délureriez',
    'délurerons',
    'délureront',
    'délustrage',
    'délustrais',
    'délustrait',
    'délustrant',
    'délustrées',
    'délustrent',
    'délustrera',
    'délustriez',
    'délustrons',
    'délutaient',
    'délutasses',
    'déluterais',
    'déluterait',
    'délutèrent',
    'déluteriez',
    'déluterons',
    'déluteront',
    'démaçonnai',
    'démaçonnas',
    'démaçonnât',
    'démaçonnée',
    'démaçonner',
    'démaçonnes',
    'démaçonnés',
    'démaçonnez',
    'démagogies',
    'démagogues',
    'démaigries',
    'démaigrira',
    'démaillage',
    'démaillais',
    'démaillait',
    'démaillant',
    'démaillées',
    'démaillent',
    'démaillera',
    'démailliez',
    'démaillons',
    'démaillota',
    'démaillote',
    'démailloté',
    'démanchais',
    'démanchait',
    'démanchant',
    'démanchées',
    'démanchent',
    'démanchera',
    'démanchiez',
    'démanchons',
    'demandâmes',
    'demandasse',
    'demandâtes',
    'demanderai',
    'demanderas',
    'demanderez',
    'demandeurs',
    'demandeuse',
    'demandions',
    'démangeais',
    'démangeait',
    'démangeant',
    'démangeons',
    'démangerai',
    'démangeras',
    'démangerez',
    'démangions',
    'démantelai',
    'démantelas',
    'démantelât',
    'démantelée',
    'démanteler',
    'démantelés',
    'démantèles',
    'démantelez',
    'démaquilla',
    'démaquille',
    'démaquillé',
    'démarchage',
    'démarchais',
    'démarchait',
    'démarchant',
    'démarchées',
    'démarchent',
    'démarchera',
    'démarcheur',
    'démarchiez',
    'démarchons',
    'démariâmes',
    'démariasse',
    'démariâtes',
    'démarierai',
    'démarieras',
    'démarierez',
    'démariions',
    'démarquage',
    'démarquais',
    'démarquait',
    'démarquant',
    'démarquées',
    'démarquent',
    'démarquera',
    'démarqueur',
    'démarquiez',
    'démarquons',
    'démarrages',
    'démarrâmes',
    'démarrasse',
    'démarrâtes',
    'démarrerai',
    'démarreras',
    'démarrerez',
    'démarreurs',
    'démarrions',
    'démasclais',
    'démasclait',
    'démasclant',
    'démasclées',
    'démasclent',
    'démasclera',
    'démascliez',
    'démasclons',
    'démasquais',
    'démasquait',
    'démasquant',
    'démasquées',
    'démasquent',
    'démasquera',
    'démasquiez',
    'démasquons',
    'démastiqua',
    'démastique',
    'démastiqué',
    'démâtaient',
    'démâtasses',
    'démâterais',
    'démâterait',
    'démâtèrent',
    'démâteriez',
    'démâterons',
    'démâteront',
    'démazoutai',
    'démazoutas',
    'démazoutât',
    'démazouter',
    'démazoutes',
    'démazoutez',
    'déméchages',
    'démêlaient',
    'démêlasses',
    'démêlement',
    'démêlerais',
    'démêlerait',
    'démêlèrent',
    'démêleriez',
    'démêlerons',
    'démêleront',
    'démembrais',
    'démembrait',
    'démembrant',
    'démembrées',
    'démembrent',
    'démembrera',
    'démembriez',
    'démembrons',
    'déménageai',
    'déménageas',
    'déménageât',
    'déménagées',
    'déménagent',
    'déménagera',
    'déménageur',
    'déménagiez',
    'démenaient',
    'démenasses',
    'démènerais',
    'démènerait',
    'démenèrent',
    'démèneriez',
    'démènerons',
    'démèneront',
    'démentiels',
    'démentîmes',
    'démentions',
    'démentirai',
    'démentiras',
    'démentirez',
    'démentisse',
    'démentîtes',
    'démerdâmes',
    'démerdasse',
    'démerdâtes',
    'démerderai',
    'démerderas',
    'démerderez',
    'démerdions',
    'déméritais',
    'déméritait',
    'déméritant',
    'déméritent',
    'déméritera',
    'déméritiez',
    'déméritons',
    'démesurées',
    'démettions',
    'démettrais',
    'démettrait',
    'démettriez',
    'démettrons',
    'démettront',
    'démeublais',
    'démeublait',
    'démeublant',
    'démeublées',
    'démeublent',
    'démeublera',
    'démeubliez',
    'démeublons',
    'demeurâmes',
    'demeurasse',
    'demeurâtes',
    'demeurerai',
    'demeureras',
    'demeurerez',
    'demeurions',
    'demi-botte',
    'demi-clefs',
    'demi-deuil',
    'demi-dieux',
    'démiellais',
    'démiellait',
    'démiellant',
    'démiellées',
    'démiellent',
    'démiellera',
    'démielliez',
    'démiellons',
    'demi-fines',
    'demi-frère',
    'demi-heure',
    'demi-jours',
    'demi-litre',
    'demi-lunes',
    'demi-monde',
    'demi-morte',
    'demi-morts',
    'déminaient',
    'déminasses',
    'déminerais',
    'déminerait',
    'déminèrent',
    'démineriez',
    'déminerons',
    'démineront',
    'demi-pause',
    'demi-place',
    'demi-plans',
    'demi-quart',
    'demi-queue',
    'demi-ronde',
    'demi-soeur',
    'demi-solde',
    'démissions',
    'demi-tarif',
    'demi-tiges',
    'demi-tours',
    'demi-volée',
    'démobilisa',
    'démobilise',
    'démobilisé',
    'démocrates',
    'démocratie',
    'démodaient',
    'démodasses',
    'démoderais',
    'démoderait',
    'démodèrent',
    'démoderiez',
    'démoderons',
    'démoderont',
    'démodulais',
    'démodulait',
    'démodulant',
    'démodulées',
    'démodulent',
    'démodulera',
    'démoduliez',
    'démodulons',
    'démographe',
    'demoiselle',
    'démolirais',
    'démolirait',
    'démolirent',
    'démoliriez',
    'démolirons',
    'démoliront',
    'démolisses',
    'démolissez',
    'démolition',
    'démonétisa',
    'démonétise',
    'démonétisé',
    'démoniaque',
    'démonismes',
    'démontable',
    'démontages',
    'démontâmes',
    'démontasse',
    'démontâtes',
    'démonterai',
    'démonteras',
    'démonterez',
    'démontions',
    'démontrais',
    'démontrait',
    'démontrant',
    'démontrées',
    'démontrent',
    'démontrera',
    'démontriez',
    'démontrons',
    'démoralisa',
    'démoralise',
    'démoralisé',
    'démordîmes',
    'démordions',
    'démordisse',
    'démordîtes',
    'démordrais',
    'démordrait',
    'démordriez',
    'démordrons',
    'démordront',
    'démotiques',
    'démotivais',
    'démotivait',
    'démotivant',
    'démotivées',
    'démotivent',
    'démotivera',
    'démotiviez',
    'démotivons',
    'démoucheta',
    'démoucheté',
    'démoulages',
    'démoulâmes',
    'démoulasse',
    'démoulâtes',
    'démoulerai',
    'démouleras',
    'démoulerez',
    'démoulions',
    'démunirais',
    'démunirait',
    'démunirent',
    'démuniriez',
    'démunirons',
    'démuniront',
    'démunisses',
    'démunissez',
    'démuraient',
    'démurasses',
    'démurerais',
    'démurerait',
    'démurèrent',
    'démureriez',
    'démurerons',
    'démureront',
    'démurgeais',
    'démurgeait',
    'démurgeant',
    'démurgeons',
    'démurgerai',
    'démurgeras',
    'démurgerez',
    'démurgions',
    'démuselais',
    'démuselait',
    'démuselant',
    'démuselées',
    'démuseliez',
    'démuselles',
    'démuselons',
    'démystifia',
    'démystifie',
    'démystifié',
    'démythifia',
    'démythifie',
    'démythifié',
    'dénantîmes',
    'dénantirai',
    'dénantiras',
    'dénantirez',
    'dénantisse',
    'dénantîtes',
    'dénasalisa',
    'dénasalise',
    'dénasalisé',
    'dénatalité',
    'dénattâmes',
    'dénattasse',
    'dénattâtes',
    'dénatterai',
    'dénatteras',
    'dénatterez',
    'dénattions',
    'dénaturais',
    'dénaturait',
    'dénaturant',
    'dénaturées',
    'dénaturent',
    'dénaturera',
    'dénaturiez',
    'dénaturons',
    'dénébulisa',
    'dénébulise',
    'dénébulisé',
    'dénégation',
    'déneigeais',
    'déneigeait',
    'déneigeant',
    'déneigeons',
    'déneigerai',
    'déneigeras',
    'déneigerez',
    'déneigions',
    'dénervâmes',
    'dénervasse',
    'dénervâtes',
    'dénerverai',
    'dénerveras',
    'dénerverez',
    'dénervions',
    'déniaisais',
    'déniaisait',
    'déniaisant',
    'déniaisées',
    'déniaisent',
    'déniaisera',
    'déniaisiez',
    'déniaisons',
    'déniassent',
    'déniassiez',
    'dénichâmes',
    'dénichasse',
    'dénichâtes',
    'dénicherai',
    'dénicheras',
    'dénicherez',
    'dénicheurs',
    'dénicheuse',
    'dénichions',
    'dénickelai',
    'dénickelas',
    'dénickelât',
    'dénickelée',
    'dénickeler',
    'dénickeles',
    'dénickelés',
    'dénickelez',
    'dénierions',
    'dénigrâmes',
    'dénigrasse',
    'dénigrâtes',
    'dénigrerai',
    'dénigreras',
    'dénigrerez',
    'dénigreurs',
    'dénigreuse',
    'dénigrions',
    'dénitrâmes',
    'dénitrasse',
    'dénitrâtes',
    'dénitrerai',
    'dénitreras',
    'dénitrerez',
    'dénitrifia',
    'dénitrifie',
    'dénitrifié',
    'dénitrions',
    'dénivelais',
    'dénivelait',
    'dénivelant',
    'dénivelées',
    'déniveliez',
    'dénivelles',
    'dénivelons',
    'dénoierais',
    'dénoierait',
    'dénoieriez',
    'dénoierons',
    'dénoieront',
    'dénombrais',
    'dénombrait',
    'dénombrant',
    'dénombrées',
    'dénombrent',
    'dénombrera',
    'dénombriez',
    'dénombrons',
    'dénommâmes',
    'dénommasse',
    'dénommâtes',
    'dénommerai',
    'dénommeras',
    'dénommerez',
    'dénommions',
    'dénonçâmes',
    'dénonçasse',
    'dénonçâtes',
    'dénoncerai',
    'dénonceras',
    'dénoncerez',
    'dénoncions',
    'dénotaient',
    'dénotasses',
    'dénotation',
    'dénoterais',
    'dénoterait',
    'dénotèrent',
    'dénoteriez',
    'dénoterons',
    'dénoteront',
    'dénouaient',
    'dénouasses',
    'dénouement',
    'dénouerais',
    'dénouerait',
    'dénouèrent',
    'dénoueriez',
    'dénouerons',
    'dénoueront',
    'dénoyaient',
    'dénoyasses',
    'dénoyautai',
    'dénoyautas',
    'dénoyautât',
    'dénoyautée',
    'dénoyauter',
    'dénoyautes',
    'dénoyautés',
    'dénoyautez',
    'dénoyèrent',
    'dentelaire',
    'dentelâmes',
    'dentelasse',
    'dentelâtes',
    'dentelions',
    'dentellent',
    'dentellera',
    'dentellier',
    'dentelures',
    'denticules',
    'dentifrice',
    'dentitions',
    'dénuassent',
    'dénuassiez',
    'dénudaient',
    'dénudasses',
    'dénudation',
    'dénuderais',
    'dénuderait',
    'dénudèrent',
    'dénuderiez',
    'dénuderons',
    'dénuderont',
    'dénuements',
    'dénuerions',
    'déodorante',
    'déodorants',
    'dépaillage',
    'dépaillais',
    'dépaillait',
    'dépaillant',
    'dépaillées',
    'dépaillent',
    'dépaillera',
    'dépailliez',
    'dépaillons',
    'dépaissela',
    'dépaisselé',
    'dépalissai',
    'dépalissas',
    'dépalissât',
    'dépalissée',
    'dépalisser',
    'dépalisses',
    'dépalissés',
    'dépalissez',
    'dépannages',
    'dépannâmes',
    'dépannasse',
    'dépannâtes',
    'dépannerai',
    'dépanneras',
    'dépannerez',
    'dépanneurs',
    'dépanneuse',
    'dépannions',
    'dépaquetai',
    'dépaquetas',
    'dépaquetât',
    'dépaquetée',
    'dépaqueter',
    'dépaquetés',
    'dépaquetez',
    'dépaquette',
    'déparaient',
    'déparasita',
    'déparasite',
    'déparasité',
    'déparasses',
    'dépareilla',
    'dépareille',
    'dépareillé',
    'déparerais',
    'déparerait',
    'déparèrent',
    'dépareriez',
    'déparerons',
    'dépareront',
    'dépariâmes',
    'dépariasse',
    'dépariâtes',
    'déparierai',
    'déparieras',
    'déparierez',
    'dépariions',
    'déparlâmes',
    'déparlasse',
    'déparlâtes',
    'déparlerai',
    'déparleras',
    'déparlerez',
    'déparlions',
    'départagea',
    'départagée',
    'départager',
    'départages',
    'départagés',
    'départagez',
    'départîmes',
    'départions',
    'départirai',
    'départiras',
    'départirez',
    'départisse',
    'départîtes',
    'dépassâmes',
    'dépassante',
    'dépassants',
    'dépassasse',
    'dépassâtes',
    'dépasserai',
    'dépasseras',
    'dépasserez',
    'dépassions',
    'dépavaient',
    'dépavasses',
    'dépaverais',
    'dépaverait',
    'dépavèrent',
    'dépaveriez',
    'dépaverons',
    'dépaveront',
    'dépaysâmes',
    'dépaysasse',
    'dépaysâtes',
    'dépayserai',
    'dépayseras',
    'dépayserez',
    'dépaysions',
    'dépeçaient',
    'dépeçasses',
    'dépècement',
    'dépècerais',
    'dépècerait',
    'dépecèrent',
    'dépèceriez',
    'dépècerons',
    'dépèceront',
    'dépeceuses',
    'dépêchâmes',
    'dépêchasse',
    'dépêchâtes',
    'dépêcherai',
    'dépêcheras',
    'dépêcherez',
    'dépêchions',
    'dépeignais',
    'dépeignait',
    'dépeignant',
    'dépeignées',
    'dépeignent',
    'dépeignera',
    'dépeigniez',
    'dépeignons',
    'dépeindrai',
    'dépeindras',
    'dépeindrez',
    'dépenaillé',
    'dépendance',
    'dépendante',
    'dépendants',
    'dépendeurs',
    'dépendeuse',
    'dépendîmes',
    'dépendions',
    'dépendisse',
    'dépendîtes',
    'dépendrais',
    'dépendrait',
    'dépendriez',
    'dépendrons',
    'dépendront',
    'dépensâmes',
    'dépensasse',
    'dépensâtes',
    'dépenserai',
    'dépenseras',
    'dépenserez',
    'dépensière',
    'dépensiers',
    'dépensions',
    'dépérirais',
    'dépérirait',
    'dépérirent',
    'dépéririez',
    'dépérirons',
    'dépériront',
    'dépérisses',
    'dépérissez',
    'dépêtrâmes',
    'dépêtrasse',
    'dépêtrâtes',
    'dépêtrerai',
    'dépêtreras',
    'dépêtrerez',
    'dépêtrions',
    'dépeuplais',
    'dépeuplait',
    'dépeuplant',
    'dépeuplées',
    'dépeuplent',
    'dépeuplera',
    'dépeupliez',
    'dépeuplons',
    'déphasages',
    'déphasâmes',
    'déphasasse',
    'déphasâtes',
    'déphaserai',
    'déphaseras',
    'déphaserez',
    'déphasions',
    'dépiautais',
    'dépiautait',
    'dépiautant',
    'dépiautées',
    'dépiautent',
    'dépiautera',
    'dépiautiez',
    'dépiautons',
    'dépilaient',
    'dépilasses',
    'dépilation',
    'dépilerais',
    'dépilerait',
    'dépilèrent',
    'dépileriez',
    'dépilerons',
    'dépileront',
    'dépinglais',
    'dépinglait',
    'dépinglant',
    'dépinglées',
    'dépinglent',
    'dépinglera',
    'dépingliez',
    'dépinglons',
    'dépiquages',
    'dépiquâmes',
    'dépiquasse',
    'dépiquâtes',
    'dépiquerai',
    'dépiqueras',
    'dépiquerez',
    'dépiquions',
    'dépistages',
    'dépistâmes',
    'dépistasse',
    'dépistâtes',
    'dépisterai',
    'dépisteras',
    'dépisterez',
    'dépistions',
    'dépitaient',
    'dépitasses',
    'dépiterais',
    'dépiterait',
    'dépitèrent',
    'dépiteriez',
    'dépiterons',
    'dépiteront',
    'déplaçâmes',
    'déplaçasse',
    'déplaçâtes',
    'déplacerai',
    'déplaceras',
    'déplacerez',
    'déplacions',
    'déplafonna',
    'déplafonne',
    'déplafonné',
    'déplairais',
    'déplairait',
    'déplairiez',
    'déplairons',
    'déplairont',
    'déplaisais',
    'déplaisait',
    'déplaisant',
    'déplaisent',
    'déplaisiez',
    'déplaisirs',
    'déplaisons',
    'déplanquai',
    'déplanquas',
    'déplanquât',
    'déplanquée',
    'déplanquer',
    'déplanques',
    'déplanqués',
    'déplanquez',
    'déplantage',
    'déplantais',
    'déplantait',
    'déplantant',
    'déplantées',
    'déplantent',
    'déplantera',
    'déplantiez',
    'déplantoir',
    'déplantons',
    'déplâtrage',
    'déplâtrais',
    'déplâtrait',
    'déplâtrant',
    'déplâtrées',
    'déplâtrent',
    'déplâtrera',
    'déplâtriez',
    'déplâtrons',
    'dépliaient',
    'dépliantes',
    'dépliasses',
    'déplierais',
    'déplierait',
    'déplièrent',
    'déplieriez',
    'déplierons',
    'déplieront',
    'déplissage',
    'déplissais',
    'déplissait',
    'déplissant',
    'déplissées',
    'déplissent',
    'déplissera',
    'déplissiez',
    'déplissons',
    'déploierai',
    'déploieras',
    'déploierez',
    'déplombage',
    'déplombais',
    'déplombait',
    'déplombant',
    'déplombées',
    'déplombent',
    'déplombera',
    'déplombiez',
    'déplombons',
    'déplorable',
    'déplorâmes',
    'déplorasse',
    'déplorâtes',
    'déplorerai',
    'déploreras',
    'déplorerez',
    'déplorions',
    'déployâmes',
    'déployasse',
    'déployâtes',
    'déployions',
    'déplumâmes',
    'déplumasse',
    'déplumâtes',
    'déplumerai',
    'déplumeras',
    'déplumerez',
    'déplumions',
    'déplussent',
    'déplussiez',
    'dépoétisai',
    'dépoétisas',
    'dépoétisât',
    'dépoétisée',
    'dépoétiser',
    'dépoétises',
    'dépoétisés',
    'dépoétisez',
    'dépointais',
    'dépointait',
    'dépointant',
    'dépointées',
    'dépointent',
    'dépointera',
    'dépointiez',
    'dépointons',
    'dépolarisa',
    'dépolarise',
    'dépolarisé',
    'dépolirais',
    'dépolirait',
    'dépolirent',
    'dépoliriez',
    'dépolirons',
    'dépoliront',
    'dépolisses',
    'dépolissez',
    'dépolitisa',
    'dépolitise',
    'dépolitisé',
    'dépolluais',
    'dépolluait',
    'dépolluant',
    'dépolluées',
    'dépolluent',
    'dépolluera',
    'dépolluiez',
    'dépolluons',
    'déponentes',
    'dépontilla',
    'dépontille',
    'dépontillé',
    'déportâmes',
    'déportasse',
    'déportâtes',
    'déporterai',
    'déporteras',
    'déporterez',
    'déportions',
    'déposaient',
    'déposantes',
    'déposasses',
    'déposerais',
    'déposerait',
    'déposèrent',
    'déposeriez',
    'déposerons',
    'déposeront',
    'déposition',
    'dépossédai',
    'dépossédas',
    'dépossédât',
    'dépossédée',
    'déposséder',
    'dépossédés',
    'dépossèdes',
    'dépossédez',
    'dépotaient',
    'dépotasses',
    'dépotement',
    'dépoterais',
    'dépoterait',
    'dépotèrent',
    'dépoteriez',
    'dépoterons',
    'dépoteront',
    'dépoudrais',
    'dépoudrait',
    'dépoudrant',
    'dépoudrées',
    'dépoudrent',
    'dépoudrera',
    'dépoudriez',
    'dépoudrons',
    'dépouillai',
    'dépouillas',
    'dépouillât',
    'dépouillée',
    'dépouiller',
    'dépouilles',
    'dépouillés',
    'dépouillez',
    'dépourvoir',
    'dépourvues',
    'dépravâmes',
    'dépravasse',
    'dépravâtes',
    'dépraverai',
    'dépraveras',
    'dépraverez',
    'dépravions',
    'dépréciais',
    'dépréciait',
    'dépréciant',
    'dépréciées',
    'déprécient',
    'dépréciera',
    'dépréciiez',
    'déprécions',
    'déprendrai',
    'déprendras',
    'déprendrez',
    'déprenions',
    'déprennent',
    'dépressifs',
    'dépression',
    'dépressive',
    'déprimâmes',
    'déprimante',
    'déprimants',
    'déprimasse',
    'déprimâtes',
    'déprimerai',
    'déprimeras',
    'déprimerez',
    'déprimions',
    'déprisâmes',
    'déprisasse',
    'déprisâtes',
    'dépriserai',
    'dépriseras',
    'dépriserez',
    'déprisions',
    'déprissent',
    'déprissiez',
    'dépucelais',
    'dépucelait',
    'dépucelant',
    'dépucelées',
    'dépuceliez',
    'dépucelles',
    'dépucelons',
    'dépulpâmes',
    'dépulpasse',
    'dépulpâtes',
    'dépulperai',
    'dépulperas',
    'dépulperez',
    'dépulpions',
    'dépuraient',
    'dépurasses',
    'dépuratifs',
    'dépuration',
    'dépurative',
    'dépurerais',
    'dépurerait',
    'dépurèrent',
    'dépureriez',
    'dépurerons',
    'dépureront',
    'députaient',
    'députasses',
    'députation',
    'députerais',
    'députerait',
    'députèrent',
    'députeriez',
    'députerons',
    'députeront',
    'déquillais',
    'déquillait',
    'déquillant',
    'déquillées',
    'déquillent',
    'déquillera',
    'déquilliez',
    'déquillons',
    'déracinais',
    'déracinait',
    'déracinant',
    'déracinées',
    'déracinent',
    'déracinera',
    'déraciniez',
    'déracinons',
    'déradaient',
    'déradasses',
    'déraderais',
    'déraderait',
    'déradèrent',
    'déraderiez',
    'déraderons',
    'déraderont',
    'dérageâmes',
    'dérageasse',
    'dérageâtes',
    'déragerais',
    'déragerait',
    'déragèrent',
    'dérageriez',
    'déragerons',
    'dérageront',
    'déraidîmes',
    'déraidirai',
    'déraidiras',
    'déraidirez',
    'déraidisse',
    'déraidîtes',
    'déraierais',
    'déraierait',
    'déraieriez',
    'déraierons',
    'déraieront',
    'déraillais',
    'déraillait',
    'déraillant',
    'déraillent',
    'déraillera',
    'dérailleur',
    'dérailliez',
    'déraillons',
    'déraisonna',
    'déraisonne',
    'déraisonné',
    'dérangeais',
    'dérangeait',
    'dérangeant',
    'dérangeons',
    'dérangerai',
    'dérangeras',
    'dérangerez',
    'dérangions',
    'dérapaient',
    'dérapasses',
    'déraperais',
    'déraperait',
    'dérapèrent',
    'déraperiez',
    'déraperons',
    'déraperont',
    'dérasaient',
    'dérasasses',
    'déraserais',
    'déraserait',
    'dérasèrent',
    'déraseriez',
    'déraserons',
    'déraseront',
    'dérataient',
    'dératasses',
    'dératerais',
    'dératerait',
    'dératèrent',
    'dérateriez',
    'dératerons',
    'dérateront',
    'dératisais',
    'dératisait',
    'dératisant',
    'dératisées',
    'dératisent',
    'dératisera',
    'dératisiez',
    'dératisons',
    'dérayaient',
    'dérayasses',
    'dérayerais',
    'dérayerait',
    'dérayèrent',
    'dérayeriez',
    'dérayerons',
    'dérayeront',
    'déréalisai',
    'déréalisas',
    'déréalisât',
    'déréalisée',
    'déréaliser',
    'déréalises',
    'déréalisés',
    'déréalisez',
    'déréglâmes',
    'déréglasse',
    'déréglâtes',
    'déréglerai',
    'dérégleras',
    'déréglerez',
    'déréglions',
    'dérégulais',
    'dérégulait',
    'dérégulant',
    'dérégulées',
    'dérégulent',
    'dérégulera',
    'déréguliez',
    'dérégulons',
    'déridaient',
    'déridasses',
    'dériderais',
    'dériderait',
    'déridèrent',
    'dérideriez',
    'dériderons',
    'dérideront',
    'dérisoires',
    'dérivaient',
    'dérivasses',
    'dérivatifs',
    'dérivation',
    'dérivative',
    'dériverais',
    'dériverait',
    'dérivèrent',
    'dériveriez',
    'dériverons',
    'dériveront',
    'dermatites',
    'dermatoses',
    'dernier-né',
    'dérobaient',
    'dérobasses',
    'déroberais',
    'déroberait',
    'dérobèrent',
    'déroberiez',
    'déroberons',
    'déroberont',
    'dérochages',
    'dérochâmes',
    'dérochasse',
    'dérochâtes',
    'dérocherai',
    'dérocheras',
    'dérocherez',
    'dérochions',
    'dérodaient',
    'dérodasses',
    'déroderais',
    'déroderait',
    'dérodèrent',
    'déroderiez',
    'déroderons',
    'déroderont',
    'dérogation',
    'dérogeâmes',
    'dérogeasse',
    'dérogeâtes',
    'dérogerais',
    'dérogerait',
    'dérogèrent',
    'dérogeriez',
    'dérogerons',
    'dérogeront',
    'dérouillai',
    'dérouillas',
    'dérouillât',
    'dérouillée',
    'dérouiller',
    'dérouilles',
    'dérouillés',
    'dérouillez',
    'déroulages',
    'déroulâmes',
    'déroulasse',
    'déroulâtes',
    'déroulerai',
    'dérouleras',
    'déroulerez',
    'dérouleurs',
    'dérouleuse',
    'déroulions',
    'déroutages',
    'déroutâmes',
    'déroutasse',
    'déroutâtes',
    'dérouterai',
    'dérouteras',
    'dérouterez',
    'déroutions',
    'désabonnai',
    'désabonnas',
    'désabonnât',
    'désabonnée',
    'désabonner',
    'désabonnes',
    'désabonnés',
    'désabonnez',
    'désabusais',
    'désabusait',
    'désabusant',
    'désabusées',
    'désabusent',
    'désabusera',
    'désabusiez',
    'désabusons',
    'désaccorda',
    'désaccorde',
    'désaccordé',
    'désaccords',
    'désactivai',
    'désactivas',
    'désactivât',
    'désactivée',
    'désactiver',
    'désactives',
    'désactivés',
    'désactivez',
    'désadaptai',
    'désadaptas',
    'désadaptât',
    'désadapter',
    'désadaptes',
    'désadaptez',
    'désaffecta',
    'désaffecte',
    'désaffecté',
    'désaffilia',
    'désaffilie',
    'désaffilié',
    'désagençai',
    'désagenças',
    'désagençât',
    'désagencée',
    'désagencer',
    'désagences',
    'désagencés',
    'désagencez',
    'désagrafai',
    'désagrafas',
    'désagrafât',
    'désagrafée',
    'désagrafer',
    'désagrafes',
    'désagrafés',
    'désagrafez',
    'désagrégea',
    'désagrégée',
    'désagréger',
    'désagrégés',
    'désagrèges',
    'désagrégez',
    'désaimanta',
    'désaimante',
    'désaimanté',
    'désajustai',
    'désajustas',
    'désajustât',
    'désajustée',
    'désajuster',
    'désajustes',
    'désajustés',
    'désajustez',
    'désaliénai',
    'désaliénas',
    'désaliénât',
    'désaliénée',
    'désaliéner',
    'désaliénés',
    'désaliènes',
    'désaliénez',
    'désalignai',
    'désalignas',
    'désalignât',
    'désalignée',
    'désaligner',
    'désalignes',
    'désalignés',
    'désalignez',
    'désaltérai',
    'désaltéras',
    'désaltérât',
    'désaltérée',
    'désaltérer',
    'désaltérés',
    'désaltères',
    'désaltérez',
    'désamarrai',
    'désamarras',
    'désamarrât',
    'désamarrée',
    'désamarrer',
    'désamarres',
    'désamarrés',
    'désamarrez',
    'désamorçai',
    'désamorças',
    'désamorçât',
    'désamorcée',
    'désamorcer',
    'désamorces',
    'désamorcés',
    'désamorcez',
    'désannexai',
    'désannexas',
    'désannexât',
    'désannexée',
    'désannexer',
    'désannexes',
    'désannexés',
    'désannexez',
    'désapparia',
    'désapparie',
    'désapparié',
    'désapprend',
    'désapprise',
    'désarçonna',
    'désarçonne',
    'désarçonné',
    'désargenta',
    'désargente',
    'désargenté',
    'désarmâmes',
    'désarmante',
    'désarmants',
    'désarmasse',
    'désarmâtes',
    'désarmerai',
    'désarmeras',
    'désarmerez',
    'désarmions',
    'désarrimai',
    'désarrimas',
    'désarrimât',
    'désarrimée',
    'désarrimer',
    'désarrimes',
    'désarrimés',
    'désarrimez',
    'désassorti',
    'désastreux',
    'désatomisa',
    'désatomise',
    'désatomisé',
    'désaveugla',
    'désaveugle',
    'désaveuglé',
    'désavouais',
    'désavouait',
    'désavouant',
    'désavouées',
    'désavouent',
    'désavouera',
    'désavouiez',
    'désavouons',
    'désaxaient',
    'désaxasses',
    'désaxerais',
    'désaxerait',
    'désaxèrent',
    'désaxeriez',
    'désaxerons',
    'désaxeront',
    'descellais',
    'descellait',
    'descellant',
    'descellées',
    'descellent',
    'descellera',
    'descelliez',
    'descellons',
    'descendais',
    'descendait',
    'descendant',
    'descendent',
    'descendeur',
    'descendiez',
    'descendons',
    'descendrai',
    'descendras',
    'descendrez',
    'descendues',
    'descenseur',
    'descriptif',
    'déséchouai',
    'déséchouas',
    'déséchouât',
    'déséchouée',
    'déséchouer',
    'déséchoues',
    'déséchoués',
    'déséchouez',
    'désembraie',
    'désembraya',
    'désembraye',
    'désembrayé',
    'désembuais',
    'désembuait',
    'désembuant',
    'désembuées',
    'désembuent',
    'désembuera',
    'désembuiez',
    'désembuons',
    'désemparai',
    'désemparas',
    'désemparât',
    'désemparée',
    'désemparer',
    'désempares',
    'désemparés',
    'désemparez',
    'désempesai',
    'désempesas',
    'désempesât',
    'désempesée',
    'désempeser',
    'désempesés',
    'désempèses',
    'désempesez',
    'désemplies',
    'désemplira',
    'désencadra',
    'désencadre',
    'désencadré',
    'désencarta',
    'désencarte',
    'désencarté',
    'désenclava',
    'désenclave',
    'désenclavé',
    'désencolla',
    'désencolle',
    'désencollé',
    'désendetta',
    'désendette',
    'désendetté',
    'désénervai',
    'désénervas',
    'désénervât',
    'désénervée',
    'désénerver',
    'désénerves',
    'désénervés',
    'désénervez',
    'désenfilai',
    'désenfilas',
    'désenfilât',
    'désenfilée',
    'désenfiler',
    'désenfiles',
    'désenfilés',
    'désenfilez',
    'désenflais',
    'désenflait',
    'désenflant',
    'désenflées',
    'désenflent',
    'désenflera',
    'désenfliez',
    'désenflons',
    'désenfumai',
    'désenfumas',
    'désenfumât',
    'désenfumée',
    'désenfumer',
    'désenfumes',
    'désenfumés',
    'désenfumez',
    'désengagea',
    'désengagée',
    'désengager',
    'désengages',
    'désengagés',
    'désengagez',
    'désengorge',
    'désengorgé',
    'désenivrai',
    'désenivras',
    'désenivrât',
    'désenivrée',
    'désenivrer',
    'désenivres',
    'désenivrés',
    'désenivrez',
    'désenlaçai',
    'désenlaças',
    'désenlaçât',
    'désenlacée',
    'désenlacer',
    'désenlaces',
    'désenlacés',
    'désenlacez',
    'désenlaidi',
    'désennuies',
    'désennuyai',
    'désennuyas',
    'désennuyât',
    'désennuyée',
    'désennuyer',
    'désennuyés',
    'désennuyez',
    'désenraies',
    'désenrayai',
    'désenrayas',
    'désenrayât',
    'désenrayée',
    'désenrayer',
    'désenrayes',
    'désenrayés',
    'désenrayez',
    'désenrhuma',
    'désenrhume',
    'désenrhumé',
    'désenrouai',
    'désenrouas',
    'désenrouât',
    'désenrouée',
    'désenrouer',
    'désenroues',
    'désenroués',
    'désenrouez',
    'désensabla',
    'désensable',
    'désensablé',
    'désentoila',
    'désentoile',
    'désentoilé',
    'désentrava',
    'désentrave',
    'désentravé',
    'désenvasai',
    'désenvasas',
    'désenvasât',
    'désenvasée',
    'désenvaser',
    'désenvases',
    'désenvasés',
    'désenvasez',
    'désépaissi',
    'déséquipai',
    'déséquipas',
    'déséquipât',
    'déséquipée',
    'déséquiper',
    'déséquipes',
    'déséquipés',
    'déséquipez',
    'désertâmes',
    'désertasse',
    'désertâtes',
    'déserterai',
    'déserteras',
    'déserterez',
    'déserteurs',
    'déserteuse',
    'désertions',
    'désertique',
    'désespérai',
    'désespéras',
    'désespérât',
    'désespérée',
    'désespérer',
    'désespérés',
    'désespères',
    'désespérez',
    'désespoirs',
    'désétablie',
    'désétablir',
    'désétablis',
    'désétablit',
    'désétablît',
    'désétamais',
    'désétamait',
    'désétamant',
    'désétamées',
    'désétament',
    'désétamera',
    'désétamiez',
    'désétamons',
    'désétatisa',
    'désétatise',
    'désétatisé',
    'déshabilla',
    'déshabille',
    'déshabillé',
    'déshabitua',
    'déshabitue',
    'déshabitué',
    'désherbage',
    'désherbais',
    'désherbait',
    'désherbant',
    'désherbées',
    'désherbent',
    'désherbera',
    'désherbiez',
    'désherbons',
    'déshérence',
    'déshéritai',
    'déshéritas',
    'déshéritât',
    'déshéritée',
    'déshériter',
    'déshérites',
    'déshérités',
    'déshéritez',
    'déshonnête',
    'déshonneur',
    'déshonorai',
    'déshonoras',
    'déshonorât',
    'déshonorée',
    'déshonorer',
    'déshonores',
    'déshonorés',
    'déshonorez',
    'déshuilais',
    'déshuilait',
    'déshuilant',
    'déshuilées',
    'déshuilent',
    'déshuilera',
    'déshuiliez',
    'déshuilons',
    'déshydrata',
    'déshydrate',
    'déshydraté',
    'desiderata',
    'désignâmes',
    'désignasse',
    'désignâtes',
    'désignatif',
    'désignerai',
    'désigneras',
    'désignerez',
    'désignions',
    'désincarna',
    'désincarne',
    'désincarné',
    'désinculpa',
    'désinculpe',
    'désinculpé',
    'désindexai',
    'désindexas',
    'désindexât',
    'désindexée',
    'désindexer',
    'désindexes',
    'désindexés',
    'désindexez',
    'désinences',
    'désinfecta',
    'désinfecte',
    'désinfecté',
    'désintégra',
    'désintégré',
    'désintègre',
    'désintérêt',
    'désinvesti',
    'désinvitai',
    'désinvitas',
    'désinvitât',
    'désinvitée',
    'désinviter',
    'désinvites',
    'désinvités',
    'désinvitez',
    'désinvolte',
    'désirables',
    'désiraient',
    'désirasses',
    'désirerais',
    'désirerait',
    'désirèrent',
    'désireriez',
    'désirerons',
    'désireront',
    'désireuses',
    'désistâmes',
    'désistasse',
    'désistâtes',
    'désisterai',
    'désisteras',
    'désisterez',
    'désistions',
    'désobéîmes',
    'désobéirai',
    'désobéiras',
    'désobéirez',
    'désobéisse',
    'désobéîtes',
    'désobligea',
    'désobligée',
    'désobliger',
    'désobliges',
    'désobligés',
    'désobligez',
    'désobstrua',
    'désobstrue',
    'désobstrué',
    'désoccupai',
    'désoccupas',
    'désoccupât',
    'désoccupée',
    'désoccuper',
    'désoccupes',
    'désoccupés',
    'désoccupez',
    'désodorisa',
    'désodorise',
    'désodorisé',
    'désoeuvrée',
    'désoeuvrés',
    'désolaient',
    'désolantes',
    'désolasses',
    'désolation',
    'désolerais',
    'désolerait',
    'désolèrent',
    'désoleriez',
    'désolerons',
    'désoleront',
    'désopilais',
    'désopilait',
    'désopilant',
    'désopilées',
    'désopilent',
    'désopilera',
    'désopiliez',
    'désopilons',
    'désorbâmes',
    'désorbasse',
    'désorbâtes',
    'désorberai',
    'désorberas',
    'désorberez',
    'désorbions',
    'désorbitai',
    'désorbitas',
    'désorbitât',
    'désorbitée',
    'désorbiter',
    'désorbites',
    'désorbités',
    'désorbitez',
    'désordonna',
    'désordonne',
    'désordonné',
    'désorienta',
    'désoriente',
    'désorienté',
    'désorption',
    'désossâmes',
    'désossasse',
    'désossâtes',
    'désosserai',
    'désosseras',
    'désosserez',
    'désossions',
    'désoxydais',
    'désoxydait',
    'désoxydant',
    'désoxydées',
    'désoxydent',
    'désoxydera',
    'désoxydiez',
    'désoxydons',
    'désoxygéna',
    'désoxygéné',
    'désoxygène',
    'desperados',
    'despotique',
    'despotisme',
    'desquamais',
    'desquamait',
    'desquamant',
    'desquamées',
    'desquament',
    'desquamera',
    'desquamiez',
    'desquamons',
    'desquelles',
    'dessablais',
    'dessablait',
    'dessablant',
    'dessablées',
    'dessablent',
    'dessablera',
    'dessabliez',
    'dessablons',
    'dessaisies',
    'dessaisira',
    'dessalages',
    'dessalâmes',
    'dessalasse',
    'dessalâtes',
    'dessalerai',
    'dessaleras',
    'dessalerez',
    'dessalions',
    'dessanglai',
    'dessanglas',
    'dessanglât',
    'dessanglée',
    'dessangler',
    'dessangles',
    'dessanglés',
    'dessanglez',
    'dessaoulai',
    'dessaoulas',
    'dessaoulât',
    'dessaoulée',
    'dessaouler',
    'dessaoules',
    'dessaoulés',
    'dessaoulez',
    'desséchais',
    'desséchait',
    'desséchant',
    'desséchées',
    'dessèchent',
    'desséchera',
    'desséchiez',
    'desséchons',
    'dessellais',
    'dessellait',
    'dessellant',
    'dessellées',
    'dessellent',
    'dessellera',
    'desselliez',
    'dessellons',
    'desserrage',
    'desserrais',
    'desserrait',
    'desserrant',
    'desserrées',
    'desserrent',
    'desserrera',
    'desserriez',
    'desserrons',
    'desserties',
    'dessertira',
    'desservais',
    'desservait',
    'desservant',
    'desservent',
    'desservies',
    'desserviez',
    'desservira',
    'desservons',
    'dessillais',
    'dessillait',
    'dessillant',
    'dessillées',
    'dessillent',
    'dessillera',
    'dessilliez',
    'dessillons',
    'dessinâmes',
    'dessinasse',
    'dessinâtes',
    'dessinerai',
    'dessineras',
    'dessinerez',
    'dessinions',
    'dessolâmes',
    'dessolasse',
    'dessolâtes',
    'dessolerai',
    'dessoleras',
    'dessolerez',
    'dessolions',
    'dessoudais',
    'dessoudait',
    'dessoudant',
    'dessoudées',
    'dessoudent',
    'dessoudera',
    'dessoudiez',
    'dessoudons',
    'dessoulais',
    'dessoûlais',
    'dessoulait',
    'dessoûlait',
    'dessoulant',
    'dessoûlant',
    'dessoulées',
    'dessoûlées',
    'dessoulent',
    'dessoûlent',
    'dessoulera',
    'dessoûlera',
    'dessouliez',
    'dessoûliez',
    'dessoulons',
    'dessoûlons',
    'dessuitais',
    'dessuitait',
    'dessuitant',
    'dessuitées',
    'dessuitent',
    'dessuitera',
    'dessuitiez',
    'dessuitons',
    'destinâmes',
    'destinasse',
    'destinâtes',
    'destinerai',
    'destineras',
    'destinerez',
    'destinions',
    'destituais',
    'destituait',
    'destituant',
    'destituées',
    'destituent',
    'destituera',
    'destituiez',
    'destituons',
    'déstockais',
    'déstockait',
    'déstockant',
    'déstockées',
    'déstockent',
    'déstockera',
    'déstockiez',
    'déstockons',
    'destroyers',
    'destructif',
    'désuétudes',
    'désulfitai',
    'désulfitas',
    'désulfitât',
    'désulfitée',
    'désulfiter',
    'désulfites',
    'désulfités',
    'désulfitez',
    'désulfurai',
    'désulfuras',
    'désulfurât',
    'désulfurée',
    'désulfurer',
    'désulfures',
    'désulfurés',
    'désulfurez',
    'désunirais',
    'désunirait',
    'désunirent',
    'désuniriez',
    'désunirons',
    'désuniront',
    'désunisses',
    'désunissez',
    'détachable',
    'détachages',
    'détachâmes',
    'détachante',
    'détachants',
    'détachasse',
    'détachâtes',
    'détacherai',
    'détacheras',
    'détacherez',
    'détacheurs',
    'détacheuse',
    'détachions',
    'détaillais',
    'détaillait',
    'détaillant',
    'détaillées',
    'détaillent',
    'détaillera',
    'détailliez',
    'détaillons',
    'détalaient',
    'détalasses',
    'détalerais',
    'détalerait',
    'détalèrent',
    'détaleriez',
    'détalerons',
    'détaleront',
    'détallâmes',
    'détallasse',
    'détallâtes',
    'détallerai',
    'détalleras',
    'détallerez',
    'détallions',
    'détapissai',
    'détapissas',
    'détapissât',
    'détapissée',
    'détapisser',
    'détapisses',
    'détapissés',
    'détapissez',
    'détartrage',
    'détartrais',
    'détartrait',
    'détartrant',
    'détartrées',
    'détartrent',
    'détartrera',
    'détartreur',
    'détartriez',
    'détartrons',
    'détaxaient',
    'détaxasses',
    'détaxation',
    'détaxerais',
    'détaxerait',
    'détaxèrent',
    'détaxeriez',
    'détaxerons',
    'détaxeront',
    'détectable',
    'détectâmes',
    'détectasse',
    'détectâtes',
    'détecterai',
    'détecteras',
    'détecterez',
    'détecteurs',
    'détections',
    'détectives',
    'déteignais',
    'déteignait',
    'déteignant',
    'déteignent',
    'déteigniez',
    'déteignons',
    'déteindrai',
    'déteindras',
    'déteindrez',
    'dételaient',
    'dételasses',
    'dételèrent',
    'détellerai',
    'dételleras',
    'détellerez',
    'détenaient',
    'détenantes',
    'détendeurs',
    'détendîmes',
    'détendions',
    'détendisse',
    'détendîtes',
    'détendrais',
    'détendrait',
    'détendriez',
    'détendrons',
    'détendront',
    'détenteurs',
    'détentions',
    'détentrice',
    'détergeais',
    'détergeait',
    'détergeant',
    'détergente',
    'détergents',
    'détergeons',
    'détergerai',
    'détergeras',
    'détergerez',
    'détergions',
    'détériorai',
    'détérioras',
    'détériorât',
    'détériorée',
    'détériorer',
    'détériores',
    'détériorés',
    'détériorez',
    'déterminai',
    'déterminas',
    'déterminât',
    'déterminée',
    'déterminer',
    'détermines',
    'déterminés',
    'déterminez',
    'déterrages',
    'déterrâmes',
    'déterrasse',
    'déterrâtes',
    'déterrerai',
    'déterreras',
    'déterrerez',
    'déterreurs',
    'déterrions',
    'détersions',
    'détersives',
    'détestable',
    'détestâmes',
    'détestasse',
    'détestâtes',
    'détesterai',
    'détesteras',
    'détesterez',
    'détestions',
    'détiendrai',
    'détiendras',
    'détiendrez',
    'détiennent',
    'détinssent',
    'détinssiez',
    'détiraient',
    'détirasses',
    'détirerais',
    'détirerait',
    'détirèrent',
    'détireriez',
    'détirerons',
    'détireront',
    'détireuses',
    'détissâmes',
    'détissasse',
    'détissâtes',
    'détisserai',
    'détisseras',
    'détisserez',
    'détissions',
    'détonaient',
    'détonantes',
    'détonasses',
    'détonateur',
    'détonation',
    'détonerais',
    'détonerait',
    'détonèrent',
    'détoneriez',
    'détonerons',
    'détoneront',
    'détonnâmes',
    'détonnasse',
    'détonnâtes',
    'détonnelai',
    'détonnelas',
    'détonnelât',
    'détonnelée',
    'détonneler',
    'détonnelés',
    'détonnelez',
    'détonnelle',
    'détonnerai',
    'détonneras',
    'détonnerez',
    'détonnions',
    'détordîmes',
    'détordions',
    'détordisse',
    'détordîtes',
    'détordrais',
    'détordrait',
    'détordriez',
    'détordrons',
    'détordront',
    'détortilla',
    'détortille',
    'détortillé',
    'détourâmes',
    'détourasse',
    'détourâtes',
    'détourerai',
    'détoureras',
    'détourerez',
    'détourions',
    'détournais',
    'détournait',
    'détournant',
    'détournées',
    'détournent',
    'détournera',
    'détourniez',
    'détournons',
    'détractais',
    'détractait',
    'détractant',
    'détractées',
    'détractent',
    'détractera',
    'détracteur',
    'détractiez',
    'détraction',
    'détractons',
    'détranchai',
    'détranchas',
    'détranchât',
    'détrancher',
    'détranches',
    'détranchez',
    'détraquais',
    'détraquait',
    'détraquant',
    'détraquées',
    'détraquent',
    'détraquera',
    'détraquiez',
    'détraquons',
    'détrempais',
    'détrempait',
    'détrempant',
    'détrempées',
    'détrempent',
    'détrempera',
    'détrempiez',
    'détrempons',
    'détressais',
    'détressait',
    'détressant',
    'détressées',
    'détressent',
    'détressera',
    'détressiez',
    'détressons',
    'détricotai',
    'détricotas',
    'détricotât',
    'détricotée',
    'détricoter',
    'détricotes',
    'détricotés',
    'détricotez',
    'détriments',
    'détritique',
    'détrompais',
    'détrompait',
    'détrompant',
    'détrompées',
    'détrompent',
    'détrompera',
    'détrompeur',
    'détrompiez',
    'détrompons',
    'détrônâmes',
    'détrônasse',
    'détrônâtes',
    'détronchai',
    'détronchas',
    'détronchât',
    'détroncher',
    'détronches',
    'détronchez',
    'détrônerai',
    'détrôneras',
    'détrônerez',
    'détrônions',
    'détroquais',
    'détroquait',
    'détroquant',
    'détroquées',
    'détroquent',
    'détroquera',
    'détroquiez',
    'détroquons',
    'détroussai',
    'détroussas',
    'détroussât',
    'détroussée',
    'détrousser',
    'détrousses',
    'détroussés',
    'détroussez',
    'détruirais',
    'détruirait',
    'détruiriez',
    'détruirons',
    'détruiront',
    'détruisais',
    'détruisait',
    'détruisant',
    'détruisent',
    'détruisiez',
    'détruisons',
    'deutériums',
    'deux-ponts',
    'deux-roues',
    'deux-seize',
    'deux-temps',
    'dévalaient',
    'dévalasses',
    'dévalerais',
    'dévalerait',
    'dévalèrent',
    'dévaleriez',
    'dévalerons',
    'dévaleront',
    'dévalisais',
    'dévalisait',
    'dévalisant',
    'dévalisées',
    'dévalisent',
    'dévalisera',
    'dévalisiez',
    'dévalisons',
    'dévalorisa',
    'dévalorise',
    'dévalorisé',
    'dévaluâmes',
    'dévaluasse',
    'dévaluâtes',
    'dévaluerai',
    'dévalueras',
    'dévaluerât',
    'dévaluions',
    'devançâmes',
    'devançasse',
    'devançâtes',
    'devancerai',
    'devanceras',
    'devancerez',
    'devancière',
    'devanciers',
    'devancions',
    'devantures',
    'dévasaient',
    'dévasasses',
    'dévaserais',
    'dévaserait',
    'dévasèrent',
    'dévaseriez',
    'dévaserons',
    'dévaseront',
    'dévastâmes',
    'dévastasse',
    'dévastâtes',
    'dévasterai',
    'dévasteras',
    'dévasterez',
    'dévastions',
    'développai',
    'développas',
    'développât',
    'développée',
    'développer',
    'développes',
    'développés',
    'développez',
    'devenaient',
    'déventâmes',
    'déventasse',
    'déventâtes',
    'déventerai',
    'déventeras',
    'déventerez',
    'déventions',
    'déverbales',
    'déverdîmes',
    'déverdirai',
    'déverdiras',
    'déverdirez',
    'déverdisse',
    'déverdîtes',
    'dévergonda',
    'dévergonde',
    'dévergondé',
    'déverguais',
    'déverguait',
    'déverguant',
    'déverguées',
    'déverguent',
    'déverguera',
    'déverguiez',
    'déverguons',
    'dévernîmes',
    'dévernirai',
    'déverniras',
    'dévernirez',
    'dévernisse',
    'dévernîtes',
    'déversâmes',
    'déversasse',
    'déversâtes',
    'déverserai',
    'déverseras',
    'déverserez',
    'déversions',
    'déversoirs',
    'dévêtaient',
    'dévêtirais',
    'dévêtirait',
    'dévêtirent',
    'dévêtiriez',
    'dévêtirons',
    'dévêtiront',
    'dévêtisses',
    'déviassent',
    'déviassiez',
    'déviateurs',
    'déviations',
    'déviatrice',
    'dévidaient',
    'dévidasses',
    'déviderais',
    'déviderait',
    'dévidèrent',
    'dévideriez',
    'déviderons',
    'dévideront',
    'dévideuses',
    'deviendrai',
    'deviendras',
    'deviendrez',
    'deviennent',
    'dévierions',
    'devinables',
    'devinaient',
    'devinasses',
    'devinerais',
    'devinerait',
    'devinèrent',
    'devineriez',
    'devinerons',
    'devineront',
    'devinettes',
    'devinssent',
    'devinssiez',
    'dévirilisa',
    'dévirilise',
    'dévirilisé',
    'dévirolais',
    'dévirolait',
    'dévirolant',
    'dévirolées',
    'dévirolent',
    'dévirolera',
    'déviroliez',
    'dévirolons',
    'dévisageai',
    'dévisageas',
    'dévisageât',
    'dévisagées',
    'dévisagent',
    'dévisagera',
    'dévisagiez',
    'devisaient',
    'devisasses',
    'deviserais',
    'deviserait',
    'devisèrent',
    'deviseriez',
    'deviserons',
    'deviseront',
    'dévissages',
    'dévissâmes',
    'dévissasse',
    'dévissâtes',
    'dévisserai',
    'dévisseras',
    'dévisserât',
    'dévissions',
    'dévitalisa',
    'dévitalise',
    'dévitalisé',
    'dévitrifia',
    'dévitrifie',
    'dévitrifié',
    'dévoiement',
    'dévoierais',
    'dévoierait',
    'dévoieriez',
    'dévoierons',
    'dévoieront',
    'dévoilâmes',
    'dévoilasse',
    'dévoilâtes',
    'dévoilerai',
    'dévoileras',
    'dévoilerât',
    'dévoilions',
    'dévoltages',
    'dévoltâmes',
    'dévoltasse',
    'dévoltâtes',
    'dévolterai',
    'dévolteras',
    'dévolterez',
    'dévoltions',
    'dévolutifs',
    'dévolution',
    'dévolutive',
    'dévonienne',
    'dévoraient',
    'dévorantes',
    'dévorasses',
    'dévorateur',
    'dévorerais',
    'dévorerait',
    'dévorèrent',
    'dévoreriez',
    'dévorerons',
    'dévoreront',
    'dévoreuses',
    'dévotement',
    'dévouaient',
    'dévouasses',
    'dévouement',
    'dévouerais',
    'dévouerait',
    'dévouèrent',
    'dévoueriez',
    'dévouerons',
    'dévoueront',
    'dévoyaient',
    'dévoyasses',
    'dévoyèrent',
    'dextérités',
    'dextralité',
    'dextrogyre',
    'dextrorsum',
    'diabétique',
    'diablement',
    'diableries',
    'diablesses',
    'diablotins',
    'diabolique',
    'diachronie',
    'diachylons',
    'diaconales',
    'diaconesse',
    'diagnostic',
    'diagonales',
    'diagrammes',
    'dialectale',
    'dialectaux',
    'dialectisa',
    'dialectise',
    'dialectisé',
    'dialogique',
    'dialoguais',
    'dialoguait',
    'dialoguant',
    'dialoguent',
    'dialoguera',
    'dialoguiez',
    'dialoguons',
    'dialysâmes',
    'dialysasse',
    'dialysâtes',
    'dialyserai',
    'dialyseras',
    'dialyserez',
    'dialyseurs',
    'dialysions',
    'diamantais',
    'diamantait',
    'diamantant',
    'diamantées',
    'diamantent',
    'diamantera',
    'diamantiez',
    'diamantine',
    'diamantins',
    'diamantons',
    'diamétrale',
    'diamétraux',
    'diapédèses',
    'diaphonies',
    'diaphorèse',
    'diaphragma',
    'diaphragme',
    'diaphragmé',
    'diapraient',
    'diaprasses',
    'diaprerais',
    'diaprerait',
    'diaprèrent',
    'diapreriez',
    'diaprerons',
    'diapreront',
    'diarthrose',
    'diascopies',
    'diatomique',
    'diatomites',
    'diatonique',
    'diazoïques',
    'dibasiques',
    'dichotomie',
    'dichroïque',
    'dichroïsme',
    'dictaphone',
    'dictassent',
    'dictassiez',
    'dictateurs',
    'dictatrice',
    'dictatures',
    'dicterions',
    'didactique',
    'didactisme',
    'didactyles',
    'didascalie',
    'diductions',
    'diéséliste',
    'diététique',
    'diffamâmes',
    'diffamante',
    'diffamants',
    'diffamasse',
    'diffamâtes',
    'diffamerai',
    'diffameras',
    'diffamerez',
    'diffamions',
    'différâmes',
    'différasse',
    'différâtes',
    'différence',
    'différends',
    'différente',
    'différents',
    'différerai',
    'différeras',
    'différerez',
    'différions',
    'difficiles',
    'difficulté',
    'difformais',
    'difformait',
    'difformant',
    'difformées',
    'difforment',
    'difformera',
    'difformiez',
    'difformité',
    'difformons',
    'diffractai',
    'diffractas',
    'diffractât',
    'diffractée',
    'diffracter',
    'diffractes',
    'diffractés',
    'diffractez',
    'diffusâmes',
    'diffusasse',
    'diffusâtes',
    'diffuserai',
    'diffuseras',
    'diffuserez',
    'diffuseurs',
    'diffuseuse',
    'diffusible',
    'diffusions',
    'digéraient',
    'digérasses',
    'digérerais',
    'digérerait',
    'digérèrent',
    'digéreriez',
    'digérerons',
    'digéreront',
    'digesteurs',
    'digestible',
    'digestions',
    'digestives',
    'digitaline',
    'digitalisa',
    'digitalise',
    'digitalisé',
    'dignitaire',
    'digraphies',
    'digression',
    'dilacérais',
    'dilacérait',
    'dilacérant',
    'dilacérées',
    'dilacèrent',
    'dilacérera',
    'dilacériez',
    'dilacérons',
    'dilapidais',
    'dilapidait',
    'dilapidant',
    'dilapidées',
    'dilapidera',
    'dilapidiez',
    'dilapidons',
    'dilatables',
    'dilataient',
    'dilatantes',
    'dilatasses',
    'dilatateur',
    'dilatation',
    'dilaterais',
    'dilaterait',
    'dilatèrent',
    'dilateriez',
    'dilaterons',
    'dilateront',
    'dilatoires',
    'dilections',
    'dilettante',
    'diligences',
    'diligentes',
    'diluassent',
    'diluassiez',
    'diluerions',
    'diluviales',
    'diluvienne',
    'dimensions',
    'diminuâmes',
    'diminuasse',
    'diminuâtes',
    'diminuendo',
    'diminuerai',
    'diminueras',
    'diminuerez',
    'diminuions',
    'diminutifs',
    'diminution',
    'diminutive',
    'dimissoire',
    'dinanderie',
    'dinandiers',
    'dînassions',
    'dînatoires',
    'dindonnais',
    'dindonnait',
    'dindonnant',
    'dindonneau',
    'dindonnées',
    'dindonnent',
    'dindonnera',
    'dindonniez',
    'dindonnons',
    'dîneraient',
    'dinguaient',
    'dinguasses',
    'dinguerais',
    'dinguerait',
    'dinguèrent',
    'dingueries',
    'dingueriez',
    'dinguerons',
    'dingueront',
    'dinosaures',
    'diocésaine',
    'diocésains',
    'dioptrique',
    'dipalident',
    'diphtéries',
    'diphtongua',
    'diphtongue',
    'diphtongué',
    'diplocoque',
    'diplodocus',
    'diplômâmes',
    'diplômasse',
    'diplomates',
    'diplômâtes',
    'diplomatie',
    'diplômerai',
    'diplômeras',
    'diplômerez',
    'diplômions',
    'dipneumoné',
    'dipolaires',
    'dipsomanes',
    'dipsomanie',
    'directeurs',
    'directions',
    'directives',
    'directoire',
    'directorat',
    'directrice',
    'dirigeable',
    'dirigeâmes',
    'dirigeante',
    'dirigeants',
    'dirigeasse',
    'dirigeâtes',
    'dirigerais',
    'dirigerait',
    'dirigèrent',
    'dirigeriez',
    'dirigerons',
    'dirigeront',
    'dirigismes',
    'dirigistes',
    'dirimantes',
    'discernais',
    'discernait',
    'discernant',
    'discernées',
    'discernent',
    'discernera',
    'discerniez',
    'discernons',
    'disciplina',
    'discipline',
    'discipliné',
    'discoboles',
    'discoïdale',
    'discoïdaux',
    'discontinu',
    'disconvenu',
    'disconvins',
    'disconvint',
    'disconvînt',
    'discophile',
    'discordais',
    'discordait',
    'discordant',
    'discordent',
    'discordera',
    'discordiez',
    'discordons',
    'discountée',
    'discounter',
    'discountés',
    'discourais',
    'discourait',
    'discourant',
    'discourent',
    'discoureur',
    'discouriez',
    'discourons',
    'discourrai',
    'discourras',
    'discourrez',
    'discourues',
    'discrédita',
    'discrédite',
    'discrédité',
    'discrédits',
    'discrétion',
    'discrimina',
    'discrimine',
    'discriminé',
    'disculpais',
    'disculpait',
    'disculpant',
    'disculpées',
    'disculpent',
    'disculpera',
    'disculpiez',
    'disculpons',
    'discursifs',
    'discursive',
    'discussion',
    'discutable',
    'discutâmes',
    'discutasse',
    'discutâtes',
    'discuterai',
    'discuteras',
    'discuterez',
    'discuteurs',
    'discuteuse',
    'discutions',
    'disetteuse',
    'disgraciai',
    'disgracias',
    'disgraciât',
    'disgraciée',
    'disgracier',
    'disgracies',
    'disgraciés',
    'disgraciez',
    'disjoignes',
    'disjoignez',
    'disjoignis',
    'disjoignit',
    'disjoignît',
    'disjoindra',
    'disjoindre',
    'disjointes',
    'disjonctai',
    'disjonctas',
    'disjonctât',
    'disjonctée',
    'disjoncter',
    'disjonctes',
    'disjonctés',
    'disjonctez',
    'disjonctif',
    'disloquais',
    'disloquait',
    'disloquant',
    'disloquées',
    'disloquent',
    'disloquera',
    'disloquiez',
    'disloquons',
    'disparates',
    'disparités',
    'disparûmes',
    'disparusse',
    'disparûtes',
    'dispatcher',
    'dispensais',
    'dispensait',
    'dispensant',
    'dispensées',
    'dispensent',
    'dispensera',
    'dispensiez',
    'dispensons',
    'dispersais',
    'dispersait',
    'dispersals',
    'dispersant',
    'dispersées',
    'dispersent',
    'dispersera',
    'dispersiez',
    'dispersifs',
    'dispersion',
    'dispersive',
    'dispersons',
    'disponible',
    'disposâmes',
    'disposasse',
    'disposâtes',
    'disposerai',
    'disposeras',
    'disposerez',
    'disposions',
    'dispositif',
    'disputâmes',
    'disputasse',
    'disputâtes',
    'disputerai',
    'disputeras',
    'disputerez',
    'disputions',
    'disquaires',
    'disquettes',
    'disruptifs',
    'disruptive',
    'dissecteur',
    'dissection',
    'disséminai',
    'disséminas',
    'disséminât',
    'disséminée',
    'disséminer',
    'dissémines',
    'disséminés',
    'disséminez',
    'dissension',
    'disséquais',
    'disséquait',
    'disséquant',
    'disséquées',
    'dissèquent',
    'disséquera',
    'disséqueur',
    'disséquiez',
    'disséquons',
    'dissertais',
    'dissertait',
    'dissertant',
    'dissertent',
    'dissertera',
    'dissertiez',
    'dissertons',
    'dissidence',
    'dissidente',
    'dissidents',
    'dissimulai',
    'dissimulas',
    'dissimulât',
    'dissimulée',
    'dissimuler',
    'dissimules',
    'dissimulés',
    'dissimulez',
    'dissipâmes',
    'dissipasse',
    'dissipâtes',
    'dissiperai',
    'dissiperas',
    'dissiperez',
    'dissipions',
    'dissociais',
    'dissociait',
    'dissociant',
    'dissociées',
    'dissocient',
    'dissociera',
    'dissociiez',
    'dissocions',
    'dissoluble',
    'dissolutif',
    'dissolvais',
    'dissolvait',
    'dissolvant',
    'dissolvent',
    'dissolviez',
    'dissolvons',
    'dissonâmes',
    'dissonance',
    'dissonasse',
    'dissonâtes',
    'dissonerai',
    'dissoneras',
    'dissonerez',
    'dissonions',
    'dissoudrai',
    'dissoudras',
    'dissoudrez',
    'dissuadais',
    'dissuadait',
    'dissuadant',
    'dissuadées',
    'dissuadent',
    'dissuadera',
    'dissuadiez',
    'dissuadons',
    'dissuasifs',
    'dissuasion',
    'dissuasive',
    'dissyllabe',
    'distançais',
    'distançait',
    'distançant',
    'distancées',
    'distancent',
    'distancera',
    'distanciai',
    'distancias',
    'distanciât',
    'distanciée',
    'distancier',
    'distancies',
    'distanciés',
    'distanciez',
    'distançons',
    'distendais',
    'distendait',
    'distendant',
    'distendent',
    'distendiez',
    'distendons',
    'distendrai',
    'distendras',
    'distendrez',
    'distendues',
    'distension',
    'distillais',
    'distillait',
    'distillant',
    'distillats',
    'distillées',
    'distillent',
    'distillera',
    'distilliez',
    'distillons',
    'distinctes',
    'distinctif',
    'distinguai',
    'distinguas',
    'distinguât',
    'distinguée',
    'distinguer',
    'distingues',
    'distingués',
    'distinguez',
    'distinguos',
    'distordais',
    'distordait',
    'distordant',
    'distordent',
    'distordiez',
    'distordons',
    'distordrai',
    'distordras',
    'distordrez',
    'distordues',
    'distorsion',
    'distraient',
    'distrairai',
    'distrairas',
    'distrairez',
    'distraites',
    'distrayais',
    'distrayait',
    'distrayant',
    'distrayiez',
    'distrayons',
    'distribuai',
    'distribuas',
    'distribuât',
    'distribuée',
    'distribuer',
    'distribues',
    'distribués',
    'distribuez',
    'dithyrambe',
    'diurétique',
    'divagateur',
    'divagation',
    'divaguâmes',
    'divaguasse',
    'divaguâtes',
    'divaguerai',
    'divagueras',
    'divaguerez',
    'divaguions',
    'divergeais',
    'divergeait',
    'divergeant',
    'divergence',
    'divergente',
    'divergents',
    'divergeons',
    'divergerai',
    'divergeras',
    'divergerez',
    'divergions',
    'diversifia',
    'diversifie',
    'diversifié',
    'diversions',
    'diversités',
    'divertîmes',
    'divertirai',
    'divertiras',
    'divertirez',
    'divertisse',
    'divertîtes',
    'dividendes',
    'divinateur',
    'divination',
    'divinement',
    'divinisais',
    'divinisait',
    'divinisant',
    'divinisées',
    'divinisent',
    'divinisera',
    'divinisiez',
    'divinisons',
    'divisaient',
    'divisasses',
    'diviserais',
    'diviserait',
    'divisèrent',
    'diviseriez',
    'diviserons',
    'diviseront',
    'divisibles',
    'divorçâmes',
    'divorçasse',
    'divorçâtes',
    'divorcerai',
    'divorceras',
    'divorcerez',
    'divorcions',
    'divulguais',
    'divulguait',
    'divulguant',
    'divulguées',
    'divulguent',
    'divulguera',
    'divulguiez',
    'divulguons',
    'djaïnismes',
    'docilement',
    'docimasies',
    'doctorales',
    'doctorante',
    'doctorants',
    'doctoresse',
    'doctrinale',
    'doctrinaux',
    'documentai',
    'documentas',
    'documentât',
    'documentée',
    'documenter',
    'documentes',
    'documentés',
    'documentez',
    'dodécagone',
    'dodelinais',
    'dodelinait',
    'dodelinant',
    'dodelinées',
    'dodelinent',
    'dodelinera',
    'dodeliniez',
    'dodelinons',
    'dodinaient',
    'dodinasses',
    'dodinerais',
    'dodinerait',
    'dodinèrent',
    'dodineriez',
    'dodinerons',
    'dodineront',
    'dogmatique',
    'dogmatisai',
    'dogmatisas',
    'dogmatisât',
    'dogmatisée',
    'dogmatiser',
    'dogmatises',
    'dogmatisés',
    'dogmatisez',
    'dogmatisme',
    'dogmatiste',
    'doigtaient',
    'doigtasses',
    'doigterais',
    'doigterait',
    'doigtèrent',
    'doigteriez',
    'doigterons',
    'doigteront',
    'dolassions',
    'dolcissimo',
    'doleraient',
    'dolorismes',
    'domaniales',
    'domestiqua',
    'domestique',
    'domestiqué',
    'domiciliai',
    'domicilias',
    'domiciliât',
    'domiciliée',
    'domicilier',
    'domicilies',
    'domiciliés',
    'domiciliez',
    'dominaient',
    'dominances',
    'dominantes',
    'dominasses',
    'dominateur',
    'domination',
    'dominerais',
    'dominerait',
    'dominèrent',
    'domineriez',
    'dominerons',
    'domineront',
    'dominicain',
    'dominicale',
    'dominicaux',
    'dominotier',
    'domptables',
    'domptaient',
    'domptasses',
    'dompterais',
    'dompterait',
    'domptèrent',
    'dompteriez',
    'dompterons',
    'dompteront',
    'dompteuses',
    'donataires',
    'donatismes',
    'donatistes',
    'donatrices',
    'donnassent',
    'donnassiez',
    'donnerions',
    'dopassions',
    'doperaient',
    'dorassions',
    'dorénavant',
    'doreraient',
    'dorlotâmes',
    'dorlotasse',
    'dorlotâtes',
    'dorloterai',
    'dorloteras',
    'dorloterez',
    'dorlotions',
    'dormirions',
    'dormissent',
    'dormissiez',
    'dormitions',
    'dormitives',
    'dorsalgies',
    'doryphores',
    'dosassions',
    'doseraient',
    'dosimètres',
    'dotassions',
    'doteraient',
    'douairière',
    'douanières',
    'douassions',
    'doublaient',
    'doublasses',
    'doublement',
    'doublerais',
    'doublerait',
    'doublèrent',
    'doubleriez',
    'doublerons',
    'doubleront',
    'doubleuses',
    'douceâtres',
    'doucereuse',
    'douchaient',
    'douchasses',
    'doucherais',
    'doucherait',
    'douchèrent',
    'doucheriez',
    'doucherons',
    'doucheront',
    'doucheuses',
    'doucirions',
    'doucissage',
    'doucissais',
    'doucissait',
    'doucissant',
    'doucissent',
    'doucissiez',
    'doucissons',
    'doueraient',
    'douillette',
    'douloureux',
    'doutassent',
    'doutassiez',
    'douterions',
    'doxologies',
    'doyennetés',
    'draconiens',
    'dragéifiai',
    'dragéifias',
    'dragéifiât',
    'dragéifiée',
    'dragéifier',
    'dragéifies',
    'dragéifiés',
    'dragéifiez',
    'drageonnai',
    'drageonnas',
    'drageonnât',
    'drageonner',
    'drageonnes',
    'drageonnez',
    'dragonnade',
    'dragonnier',
    'draguaient',
    'draguasses',
    'draguerais',
    'draguerait',
    'draguèrent',
    'dragueriez',
    'draguerons',
    'dragueront',
    'dragueuses',
    'draierions',
    'drainaient',
    'drainasses',
    'drainerais',
    'drainerait',
    'drainèrent',
    'draineriez',
    'drainerons',
    'draineront',
    'draineuses',
    'draisienne',
    'dramatique',
    'dramatisai',
    'dramatisas',
    'dramatisât',
    'dramatisée',
    'dramatiser',
    'dramatises',
    'dramatisés',
    'dramatisez',
    'dramaturge',
    'drapassent',
    'drapassiez',
    'drapements',
    'draperions',
    'drastiques',
    'dravidiens',
    'drayassent',
    'drayassiez',
    'drayerions',
    'dressaient',
    'dressantes',
    'dressasses',
    'dresserais',
    'dresserait',
    'dressèrent',
    'dresseriez',
    'dresserons',
    'dresseront',
    'dresseuses',
    'dreyfusard',
    'dribblâmes',
    'dribblasse',
    'dribblâtes',
    'dribblerai',
    'dribbleras',
    'dribblerez',
    'dribbleurs',
    'dribbleuse',
    'dribblions',
    'drillaient',
    'drillasses',
    'drillerais',
    'drillerait',
    'drillèrent',
    'drilleriez',
    'drillerons',
    'drilleront',
    'drivassent',
    'drivassiez',
    'driverions',
    'droguaient',
    'droguasses',
    'droguerais',
    'droguerait',
    'droguèrent',
    'drogueries',
    'drogueriez',
    'droguerons',
    'drogueront',
    'droguistes',
    'droitement',
    'droitières',
    'drolatique',
    'dromadaire',
    'dropassent',
    'dropassiez',
    'droperions',
    'drop-goals',
    'droppaient',
    'droppasses',
    'dropperais',
    'dropperait',
    'droppèrent',
    'dropperiez',
    'dropperons',
    'dropperont',
    'drosophile',
    'drossaient',
    'drossasses',
    'drosserais',
    'drosserait',
    'drossèrent',
    'drosseriez',
    'drosserons',
    'drosseront',
    'drugstores',
    'druidesses',
    'druidiques',
    'druidismes',
    'dubitatifs',
    'dubitative',
    'ductilités',
    'duellistes',
    'duettistes',
    'dulcifiais',
    'dulcifiait',
    'dulcifiant',
    'dulcifiées',
    'dulcifient',
    'dulcifiera',
    'dulcifiiez',
    'dulcifions',
    'duodécimal',
    'duodénales',
    'duodénites',
    'dupassions',
    'duperaient',
    'duplexâmes',
    'duplexasse',
    'duplexâtes',
    'duplexerai',
    'duplexeras',
    'duplexerez',
    'duplexions',
    'duplicités',
    'dupliquais',
    'dupliquait',
    'dupliquant',
    'dupliquées',
    'dupliquent',
    'dupliquera',
    'dupliquiez',
    'dupliquons',
    'durabilité',
    'duralumins',
    'durassions',
    'durcirions',
    'durcissais',
    'durcissait',
    'durcissant',
    'durcissent',
    'durcisseur',
    'durcissiez',
    'durcissons',
    'dureraient',
    'duumvirats',
    'duvetaient',
    'duvetasses',
    'duvetèrent',
    'duveteuses',
    'duvetterai',
    'duvetteras',
    'duvetterez',
    'dynamiques',
    'dynamisais',
    'dynamisait',
    'dynamisant',
    'dynamisées',
    'dynamisent',
    'dynamisera',
    'dynamisiez',
    'dynamismes',
    'dynamisons',
    'dynamistes',
    'dynamitage',
    'dynamitais',
    'dynamitait',
    'dynamitant',
    'dynamitées',
    'dynamitent',
    'dynamitera',
    'dynamiteur',
    'dynamitiez',
    'dynamitons',
    'dynamogène',
    'dynastique',
    'dysarthrie',
    'dyschromie',
    'dyscrasies',
    'dysenterie',
    'dysgraphie',
    'dyshidrose',
    'dysidroses',
    'dyslexique',
    'dyspepsies',
    'dysphasies',
    'dysphonies',
    'dysphories',
    'dysplasies',
    'dyspraxies',
    'dysprosium',
    'dystrophie',
    'eau-de-vie',
    'ébahirions',
    'ébahissais',
    'ébahissait',
    'ébahissant',
    'ébahissent',
    'ébahissiez',
    'ébahissons',
    'ébarbaient',
    'ébarbasses',
    'ébarberais',
    'ébarberait',
    'ébarbèrent',
    'ébarberiez',
    'ébarberons',
    'ébarberont',
    'ébarbeuses',
    'ébattrions',
    'ébaubiriez',
    'ébaubirons',
    'ébaubissez',
    'ébauchages',
    'ébauchâmes',
    'ébauchasse',
    'ébauchâtes',
    'ébaucherai',
    'ébaucheras',
    'ébaucherez',
    'ébaucheurs',
    'ébauchions',
    'ébauchoirs',
    'ébaudiriez',
    'ébaudirons',
    'ébaudissez',
    'ébavurâmes',
    'ébavurasse',
    'ébavurâtes',
    'ébavurerai',
    'ébavureras',
    'ébavurerez',
    'ébavurions',
    'éberluâmes',
    'éberluasse',
    'éberluâtes',
    'éberluerai',
    'éberlueras',
    'éberluerez',
    'éberluions',
    'ébiselâmes',
    'ébiselasse',
    'ébiselâtes',
    'ébiselerai',
    'ébiseleras',
    'ébiselerez',
    'ébiselions',
    'éblouirais',
    'éblouirait',
    'éblouirent',
    'éblouiriez',
    'éblouirons',
    'éblouiront',
    'éblouisses',
    'éblouissez',
    'éborgnages',
    'éborgnâmes',
    'éborgnasse',
    'éborgnâtes',
    'éborgnerai',
    'éborgneras',
    'éborgnerez',
    'éborgnions',
    'ébossaient',
    'ébossasses',
    'ébosserais',
    'ébosserait',
    'ébossèrent',
    'ébosseriez',
    'ébosserons',
    'ébosseront',
    'ébouassent',
    'ébouassiez',
    'ébouerions',
    'éboulaient',
    'éboulasses',
    'éboulement',
    'éboulerais',
    'éboulerait',
    'éboulèrent',
    'ébouleriez',
    'éboulerons',
    'ébouleront',
    'ébouriffai',
    'ébouriffas',
    'ébouriffât',
    'ébouriffée',
    'ébouriffer',
    'ébouriffes',
    'ébouriffés',
    'ébouriffez',
    'ébourrâmes',
    'ébourrasse',
    'ébourrâtes',
    'ébourrerai',
    'ébourreras',
    'ébourrerez',
    'ébourrions',
    'éboutaient',
    'éboutasses',
    'ébouterais',
    'ébouterait',
    'éboutèrent',
    'ébouteriez',
    'ébouterons',
    'ébouteront',
    'ébraisâmes',
    'ébraisasse',
    'ébraisâtes',
    'ébraiserai',
    'ébraiseras',
    'ébraiserez',
    'ébraisions',
    'ébranchage',
    'ébranchais',
    'ébranchait',
    'ébranchant',
    'ébranchées',
    'ébranchent',
    'ébranchera',
    'ébranchiez',
    'ébranchoir',
    'ébranchons',
    'ébranlâmes',
    'ébranlasse',
    'ébranlâtes',
    'ébranlerai',
    'ébranleras',
    'ébranlerez',
    'ébranlions',
    'ébrasaient',
    'ébrasasses',
    'ébrasement',
    'ébraserais',
    'ébraserait',
    'ébrasèrent',
    'ébraseriez',
    'ébraserons',
    'ébraseront',
    'ébréchâmes',
    'ébréchasse',
    'ébréchâtes',
    'ébrécherai',
    'ébrécheras',
    'ébrécherez',
    'ébréchions',
    'ébréchures',
    'ébrouement',
    'ébroueriez',
    'ébrouerons',
    'ébruitâmes',
    'ébruitasse',
    'ébruitâtes',
    'ébruiterai',
    'ébruiteras',
    'ébruiterez',
    'ébruitions',
    'ébullition',
    'éburnéenne',
    'écachaient',
    'écachasses',
    'écacherais',
    'écacherait',
    'écachèrent',
    'écacheriez',
    'écacherons',
    'écacheront',
    'écaffaient',
    'écaffasses',
    'écafferais',
    'écafferait',
    'écaffèrent',
    'écafferiez',
    'écafferons',
    'écafferont',
    'écaillages',
    'écaillâmes',
    'écaillasse',
    'écaillâtes',
    'écaillerai',
    'écailleras',
    'écaillerez',
    'écailleurs',
    'écailleuse',
    'écaillions',
    'écaillures',
    'écalassent',
    'écalassiez',
    'écalerions',
    'écanguâmes',
    'écanguasse',
    'écanguâtes',
    'écanguerai',
    'écangueras',
    'écanguerez',
    'écanguions',
    'écarquilla',
    'écarquille',
    'écarquillé',
    'écartaient',
    'écartasses',
    'écartelais',
    'écartelait',
    'écartelant',
    'écartelées',
    'écartèlent',
    'écartèlera',
    'écarteliez',
    'écartelons',
    'écartelure',
    'écartement',
    'écarterais',
    'écarterait',
    'écartèrent',
    'écarteriez',
    'écarterons',
    'écarteront',
    'écatirions',
    'écatissais',
    'écatissait',
    'écatissant',
    'écatissent',
    'écatissiez',
    'écatissons',
    'ecballiums',
    'ecchymoses',
    'ecclésiale',
    'ecclésiaux',
    'écervelées',
    'échafaudai',
    'échafaudas',
    'échafaudât',
    'échafaudée',
    'échafauder',
    'échafaudes',
    'échafaudés',
    'échafaudez',
    'échalassai',
    'échalassas',
    'échalassât',
    'échalassée',
    'échalasser',
    'échalasses',
    'échalassés',
    'échalassez',
    'échampîmes',
    'échampirai',
    'échampiras',
    'échampirez',
    'échampisse',
    'échampîtes',
    'échancrais',
    'échancrait',
    'échancrant',
    'échancrées',
    'échancrent',
    'échancrera',
    'échancriez',
    'échancrons',
    'échancrure',
    'échangeais',
    'échangeait',
    'échangeant',
    'échangeons',
    'échangerai',
    'échangeras',
    'échangerez',
    'échangeurs',
    'échangeuse',
    'échangions',
    'échangiste',
    'échappâmes',
    'échappasse',
    'échappâtes',
    'échapperai',
    'échapperas',
    'échapperez',
    'échappions',
    'échardonna',
    'échardonne',
    'échardonné',
    'écharnages',
    'écharnâmes',
    'écharnasse',
    'écharnâtes',
    'écharnerai',
    'écharneras',
    'écharnerez',
    'écharneuse',
    'écharnions',
    'écharnoirs',
    'écharpâmes',
    'écharpasse',
    'écharpâtes',
    'écharperai',
    'écharperas',
    'écharperez',
    'écharpions',
    'échassiers',
    'échaudages',
    'échaudâmes',
    'échaudasse',
    'échaudâtes',
    'échauderai',
    'échauderas',
    'échauderez',
    'échaudions',
    'échauffais',
    'échauffait',
    'échauffant',
    'échauffées',
    'échauffent',
    'échauffera',
    'échauffiez',
    'échauffons',
    'échaulâmes',
    'échaulasse',
    'échaulâtes',
    'échaulerai',
    'échauleras',
    'échaulerez',
    'échaulions',
    'échaumâmes',
    'échaumasse',
    'échaumâtes',
    'échaumerai',
    'échaumeras',
    'échaumerez',
    'échaumions',
    'échéancier',
    'échelettes',
    'échelonnai',
    'échelonnas',
    'échelonnât',
    'échelonnée',
    'échelonner',
    'échelonnes',
    'échelonnés',
    'échelonnez',
    'échenillai',
    'échenillas',
    'échenillât',
    'échenillée',
    'écheniller',
    'échenilles',
    'échenillés',
    'échenillez',
    'échevelais',
    'échevelait',
    'échevelant',
    'échevelées',
    'écheveliez',
    'échevelles',
    'échevelons',
    'échevettes',
    'échevinage',
    'échevinale',
    'échevinaux',
    'échinaient',
    'échinasses',
    'échinerais',
    'échinerait',
    'échinèrent',
    'échineriez',
    'échinerons',
    'échineront',
    'échiquetée',
    'échiquetés',
    'échiquiers',
    'écholalies',
    'échoppâmes',
    'échoppasse',
    'échoppâtes',
    'échopperai',
    'échopperas',
    'échopperez',
    'échoppions',
    'échotières',
    'échouaient',
    'échouasses',
    'échouement',
    'échouerais',
    'échouerait',
    'échouèrent',
    'échoueriez',
    'échouerons',
    'échoueront',
    'écimassent',
    'écimassiez',
    'écimerions',
    'éclaboussa',
    'éclabousse',
    'éclaboussé',
    'éclairages',
    'éclairâmes',
    'éclairante',
    'éclairants',
    'éclairasse',
    'éclairâtes',
    'éclaircies',
    'éclaircira',
    'éclairerai',
    'éclaireras',
    'éclairerez',
    'éclaireurs',
    'éclaireuse',
    'éclairions',
    'éclataient',
    'éclatantes',
    'éclatasses',
    'éclatement',
    'éclaterais',
    'éclaterait',
    'éclatèrent',
    'éclateriez',
    'éclaterons',
    'éclateront',
    'éclectique',
    'éclectisme',
    'éclimètres',
    'éclipsâmes',
    'éclipsasse',
    'éclipsâtes',
    'éclipserai',
    'éclipseras',
    'éclipserez',
    'éclipsions',
    'écliptique',
    'éclissâmes',
    'éclissasse',
    'éclissâtes',
    'éclisserai',
    'éclisseras',
    'éclisserez',
    'éclissions',
    'éclopaient',
    'éclopasses',
    'écloperais',
    'écloperait',
    'éclopèrent',
    'écloperiez',
    'écloperons',
    'écloperont',
    'écloraient',
    'éclusaient',
    'éclusasses',
    'écluserais',
    'écluserait',
    'éclusèrent',
    'écluseriez',
    'écluserons',
    'écluseront',
    'éclusières',
    'écobuaient',
    'écobuasses',
    'écobuerais',
    'écobuerait',
    'écobuèrent',
    'écobueriez',
    'écobuerons',
    'écobueront',
    'écoeurâmes',
    'écoeurante',
    'écoeurants',
    'écoeurasse',
    'écoeurâtes',
    'écoeurerai',
    'écoeureras',
    'écoeurerez',
    'écoeurions',
    'écologique',
    'écologiste',
    'éconduirai',
    'éconduiras',
    'éconduirez',
    'éconduises',
    'éconduisez',
    'éconduisis',
    'éconduisit',
    'éconduisît',
    'éconduites',
    'économique',
    'économisai',
    'économisas',
    'économisât',
    'économisée',
    'économiser',
    'économises',
    'économisés',
    'économisez',
    'économisme',
    'économiste',
    'écopassent',
    'écopassiez',
    'écoperches',
    'écoperions',
    'écorassent',
    'écorassiez',
    'écorçaient',
    'écorçasses',
    'écorcerais',
    'écorcerait',
    'écorcèrent',
    'écorceriez',
    'écorcerons',
    'écorceront',
    'écorchâmes',
    'écorchasse',
    'écorchâtes',
    'écorcherai',
    'écorcheras',
    'écorcherez',
    'écorcheurs',
    'écorchions',
    'écorchures',
    'écorerions',
    'écornaient',
    'écornasses',
    'écornerais',
    'écornerait',
    'écornèrent',
    'écorneriez',
    'écornerons',
    'écorneront',
    'écorniflai',
    'écorniflas',
    'écorniflât',
    'écorniflée',
    'écornifler',
    'écornifles',
    'écorniflés',
    'écorniflez',
    'écossaient',
    'écossaises',
    'écossasses',
    'écosserais',
    'écosserait',
    'écossèrent',
    'écosseriez',
    'écosserons',
    'écosseront',
    'écosystème',
    'écoulaient',
    'écoulasses',
    'écoulement',
    'écoulerais',
    'écoulerait',
    'écoulèrent',
    'écouleriez',
    'écoulerons',
    'écouleront',
    'écourtâmes',
    'écourtasse',
    'écourtâtes',
    'écourterai',
    'écourteras',
    'écourterez',
    'écourtions',
    'écoutaient',
    'écoutasses',
    'écouterais',
    'écouterait',
    'écoutèrent',
    'écouteriez',
    'écouterons',
    'écouteront',
    'écoutilles',
    'écouvillon',
    'écrasaient',
    'écrasantes',
    'écrasasses',
    'écrasement',
    'écraserais',
    'écraserait',
    'écrasèrent',
    'écraseriez',
    'écraserons',
    'écraseront',
    'écraseuses',
    'écrémaient',
    'écrémasses',
    'écrémerais',
    'écrémerait',
    'écrémèrent',
    'écrémeriez',
    'écrémerons',
    'écrémeront',
    'écrémeuses',
    'écrêtaient',
    'écrêtasses',
    'écrêtement',
    'écrêterais',
    'écrêterait',
    'écrêtèrent',
    'écrêteriez',
    'écrêterons',
    'écrêteront',
    'écrevisses',
    'écriassiez',
    'écrierions',
    'écriraient',
    'écritoires',
    'écrivaient',
    'écrivailla',
    'écrivaille',
    'écrivaillé',
    'écrivassai',
    'écrivassas',
    'écrivassât',
    'écrivasser',
    'écrivasses',
    'écrivassez',
    'écrivirent',
    'écrivisses',
    'écrouaient',
    'écrouasses',
    'écrouelles',
    'écrouerais',
    'écrouerait',
    'écrouèrent',
    'écroueriez',
    'écrouerons',
    'écroueront',
    'écrouirais',
    'écrouirait',
    'écrouirent',
    'écrouiriez',
    'écrouirons',
    'écrouiront',
    'écrouisses',
    'écrouissez',
    'écroulâmes',
    'écroulâtes',
    'écroulerez',
    'écroulions',
    'écroûtâmes',
    'écroûtasse',
    'écroûtâtes',
    'écroûterai',
    'écroûteras',
    'écroûterez',
    'écroûteuse',
    'écroûtions',
    'ectoblaste',
    'ectodermes',
    'ectopiques',
    'ectoplasme',
    'ectropions',
    'écuissâmes',
    'écuissasse',
    'écuissâtes',
    'écuisserai',
    'écuisseras',
    'écuisserez',
    'écuissions',
    'éculassent',
    'éculassiez',
    'éculerions',
    'écumassent',
    'écumassiez',
    'écumerions',
    'écurassent',
    'écurassiez',
    'écurerions',
    'écussonnai',
    'écussonnas',
    'écussonnât',
    'écussonnée',
    'écussonner',
    'écussonnes',
    'écussonnés',
    'écussonnez',
    'eczémateux',
    'édentaient',
    'édentasses',
    'édenterais',
    'édenterait',
    'édentèrent',
    'édenteriez',
    'édenterons',
    'édenteront',
    'édictaient',
    'édictasses',
    'édicterais',
    'édicterait',
    'édictèrent',
    'édicteriez',
    'édicterons',
    'édicteront',
    'édifiaient',
    'édifiantes',
    'édifiasses',
    'édifierais',
    'édifierait',
    'édifièrent',
    'édifieriez',
    'édifierons',
    'édifieront',
    'édilitaire',
    'éditassent',
    'éditassiez',
    'éditerions',
    'éditionnai',
    'éditionnas',
    'éditionnât',
    'éditionnée',
    'éditionner',
    'éditionnes',
    'éditionnés',
    'éditionnez',
    'éditoriale',
    'éditoriaux',
    'éducateurs',
    'éducations',
    'éducatives',
    'éducatrice',
    'édulcorais',
    'édulcorait',
    'édulcorant',
    'édulcorées',
    'édulcorent',
    'édulcorera',
    'édulcoriez',
    'édulcorons',
    'éduquaient',
    'éduquasses',
    'éduquerais',
    'éduquerait',
    'éduquèrent',
    'éduqueriez',
    'éduquerons',
    'éduqueront',
    'éfaufilais',
    'éfaufilait',
    'éfaufilant',
    'éfaufilées',
    'éfaufilent',
    'éfaufilera',
    'éfaufiliez',
    'éfaufilons',
    'effaçables',
    'effaçaient',
    'effaçasses',
    'effacement',
    'effacerais',
    'effacerait',
    'effacèrent',
    'effaceriez',
    'effacerons',
    'effaceront',
    'effanaient',
    'effanasses',
    'effanerais',
    'effanerait',
    'effanèrent',
    'effaneriez',
    'effanerons',
    'effaneront',
    'effaraient',
    'effarantes',
    'effarasses',
    'effarement',
    'effarerais',
    'effarerait',
    'effarèrent',
    'effareriez',
    'effarerons',
    'effareront',
    'effaroucha',
    'effarouche',
    'effarouché',
    'effarvatte',
    'effecteurs',
    'effectives',
    'effectuais',
    'effectuait',
    'effectuant',
    'effectuées',
    'effectuent',
    'effectuera',
    'effectuiez',
    'effectuons',
    'efféminais',
    'efféminait',
    'efféminant',
    'efféminées',
    'efféminent',
    'efféminera',
    'efféminiez',
    'efféminons',
    'efférentes',
    'effeuillai',
    'effeuillas',
    'effeuillât',
    'effeuillée',
    'effeuiller',
    'effeuilles',
    'effeuillés',
    'effeuillez',
    'efficacité',
    'efficience',
    'efficiente',
    'efficients',
    'effilaient',
    'effilasses',
    'effilement',
    'effilerais',
    'effilerait',
    'effilèrent',
    'effileriez',
    'effilerons',
    'effileront',
    'effilochai',
    'effilochas',
    'effilochât',
    'effilochée',
    'effilocher',
    'effiloches',
    'effilochés',
    'effilochez',
    'efflanquai',
    'efflanquas',
    'efflanquât',
    'efflanquée',
    'efflanquer',
    'efflanques',
    'efflanqués',
    'efflanquez',
    'effleurage',
    'effleurais',
    'effleurait',
    'effleurant',
    'effleurées',
    'effleurent',
    'effleurera',
    'effleuriez',
    'effleurira',
    'effleurons',
    'effluences',
    'effluentes',
    'effluvâmes',
    'effluvasse',
    'effluvâtes',
    'effluverai',
    'effluveras',
    'effluverez',
    'effluvions',
    'effondrais',
    'effondrait',
    'effondrant',
    'effondrées',
    'effondrent',
    'effondrera',
    'effondriez',
    'effondrons',
    'efforçâmes',
    'efforçâtes',
    'efforcerez',
    'efforcions',
    'effraction',
    'effraierai',
    'effraieras',
    'effraierez',
    'effrangeai',
    'effrangeas',
    'effrangeât',
    'effrangées',
    'effrangent',
    'effrangera',
    'effrangiez',
    'effrayâmes',
    'effrayante',
    'effrayants',
    'effrayasse',
    'effrayâtes',
    'effrayerai',
    'effrayeras',
    'effrayerez',
    'effrayions',
    'effritâmes',
    'effritasse',
    'effritâtes',
    'effriterai',
    'effriteras',
    'effriterez',
    'effritions',
    'effrontées',
    'effroyable',
    'éfourceaux',
    'égaiements',
    'égaierions',
    'égaillâmes',
    'égaillâtes',
    'égaillerez',
    'égaillions',
    'égalassent',
    'égalassiez',
    'égalerions',
    'égalisâmes',
    'égalisasse',
    'égalisâtes',
    'égaliserai',
    'égaliseras',
    'égaliserez',
    'égaliseurs',
    'égalisions',
    'égalitaire',
    'égarassent',
    'égarassiez',
    'égarements',
    'égarerions',
    'égayassent',
    'égayassiez',
    'égayements',
    'égayerions',
    'égermaient',
    'égermasses',
    'égermerais',
    'égermerait',
    'égermèrent',
    'égermeriez',
    'égermerons',
    'égermeront',
    'églantiers',
    'églantines',
    'égorgeâmes',
    'égorgeasse',
    'égorgeâtes',
    'égorgement',
    'égorgerais',
    'égorgerait',
    'égorgèrent',
    'égorgeriez',
    'égorgerons',
    'égorgeront',
    'égorgeuses',
    'égosillées',
    'égosilliez',
    'égosillons',
    'égouttages',
    'égouttâmes',
    'égouttasse',
    'égouttâtes',
    'égoutterai',
    'égoutteras',
    'égoutterez',
    'égouttions',
    'égouttoirs',
    'égouttures',
    'égrainages',
    'égrainâmes',
    'égrainasse',
    'égrainâtes',
    'égrainerai',
    'égraineras',
    'égrainerez',
    'égrainions',
    'égrappages',
    'égrappâmes',
    'égrappasse',
    'égrappâtes',
    'égrapperai',
    'égrapperas',
    'égrapperez',
    'égrappions',
    'égrappoirs',
    'égratignai',
    'égratignas',
    'égratignât',
    'égratignée',
    'égratigner',
    'égratignes',
    'égratignés',
    'égratignez',
    'égrenaient',
    'égrenasses',
    'égrènement',
    'égrènerais',
    'égrènerait',
    'égrenèrent',
    'égrèneriez',
    'égrènerons',
    'égrèneront',
    'égreneuses',
    'égrillards',
    'égrisaient',
    'égrisasses',
    'égriserais',
    'égriserait',
    'égrisèrent',
    'égriseriez',
    'égriserons',
    'égriseront',
    'égrotantes',
    'égrugeages',
    'égrugeâmes',
    'égrugeasse',
    'égrugeâtes',
    'égrugeoirs',
    'égrugerais',
    'égrugerait',
    'égrugèrent',
    'égrugeriez',
    'égrugerons',
    'égrugeront',
    'égyptienne',
    'eidétiques',
    'éjaculâmes',
    'éjaculasse',
    'éjaculâtes',
    'éjaculerai',
    'éjaculeras',
    'éjaculerez',
    'éjaculions',
    'éjectables',
    'éjectaient',
    'éjectasses',
    'éjecterais',
    'éjecterait',
    'éjectèrent',
    'éjecteriez',
    'éjecterons',
    'éjecteront',
    'éjointâmes',
    'éjointasse',
    'éjointâtes',
    'éjointerai',
    'éjointeras',
    'éjointerez',
    'éjointions',
    'élaborâmes',
    'élaborasse',
    'élaborâtes',
    'élaborerai',
    'élaboreras',
    'élaborerez',
    'élaborions',
    'élaguaient',
    'élaguasses',
    'élaguerais',
    'élaguerait',
    'élaguèrent',
    'élagueriez',
    'élaguerons',
    'élagueront',
    'élançaient',
    'élançasses',
    'élancement',
    'élancerais',
    'élancerait',
    'élancèrent',
    'élanceriez',
    'élancerons',
    'élanceront',
    'élargirais',
    'élargirait',
    'élargirent',
    'élargiriez',
    'élargirons',
    'élargiront',
    'élargisses',
    'élargissez',
    'élasticité',
    'élastiques',
    'élastomère',
    'éléatiques',
    'électivité',
    'électorale',
    'électorats',
    'électoraux',
    'électrices',
    'électrifia',
    'électrifie',
    'électrifié',
    'électrique',
    'électrisai',
    'électrisas',
    'électrisât',
    'électrisée',
    'électriser',
    'électrises',
    'électrisés',
    'électrisez',
    'électrodes',
    'électuaire',
    'élégamment',
    'élégiaques',
    'élégirions',
    'élégissais',
    'élégissait',
    'élégissant',
    'élégissiez',
    'élégissons',
    'éléphantin',
    'élevassent',
    'élevassiez',
    'élévateurs',
    'élévations',
    'élévatoire',
    'élévatrice',
    'élèverions',
    'élidassent',
    'élidassiez',
    'éliderions',
    'élimassent',
    'élimassiez',
    'élimerions',
    'éliminâmes',
    'éliminasse',
    'éliminâtes',
    'éliminerai',
    'élimineras',
    'éliminerez',
    'éliminions',
    'élinguâmes',
    'élinguasse',
    'élinguâtes',
    'élinguerai',
    'élingueras',
    'élinguerez',
    'élinguions',
    'ellipsoïde',
    'elliptique',
    'élocutions',
    'élogieuses',
    'éloignâmes',
    'éloignasse',
    'éloignâtes',
    'éloignerai',
    'éloigneras',
    'éloignerez',
    'éloignions',
    'élongation',
    'élongeâmes',
    'élongeasse',
    'élongeâtes',
    'élongerais',
    'élongerait',
    'élongèrent',
    'élongeriez',
    'élongerons',
    'élongeront',
    'éloquences',
    'éloquentes',
    'élucidâmes',
    'élucidasse',
    'élucidâtes',
    'éluciderai',
    'élucideras',
    'éluciderez',
    'élucidions',
    'élucubrais',
    'élucubrait',
    'élucubrant',
    'élucubrées',
    'élucubrent',
    'élucubrera',
    'élucubriez',
    'élucubrons',
    'éludassent',
    'éludassiez',
    'éluderions',
    'élyséennes',
    'elzévirien',
    'émaciation',
    'émacieriez',
    'émacierons',
    'émaillages',
    'émaillâmes',
    'émaillasse',
    'émaillâtes',
    'émaillerai',
    'émailleras',
    'émaillerez',
    'émaillerie',
    'émailleurs',
    'émailleuse',
    'émaillions',
    'émaillures',
    'émanassent',
    'émanassiez',
    'émanations',
    'émancipais',
    'émancipait',
    'émancipant',
    'émancipées',
    'émancipent',
    'émancipera',
    'émancipiez',
    'émancipons',
    'émanerions',
    'émargeâmes',
    'émargeasse',
    'émargeâtes',
    'émargement',
    'émargerais',
    'émargerait',
    'émargèrent',
    'émargeriez',
    'émargerons',
    'émargeront',
    'émasculais',
    'émasculait',
    'émasculant',
    'émasculées',
    'émasculent',
    'émasculera',
    'émasculiez',
    'émasculons',
    'emballages',
    'emballâmes',
    'emballasse',
    'emballâtes',
    'emballerai',
    'emballeras',
    'emballerez',
    'emballeurs',
    'emballeuse',
    'emballions',
    'emballotta',
    'emballotte',
    'emballotté',
    'embardâmes',
    'embardasse',
    'embardâtes',
    'embarderai',
    'embarderas',
    'embarderez',
    'embardions',
    'embarquais',
    'embarquait',
    'embarquant',
    'embarquées',
    'embarquent',
    'embarquera',
    'embarquiez',
    'embarquons',
    'embarrâmes',
    'embarrassa',
    'embarrasse',
    'embarrassé',
    'embarrâtes',
    'embarrerai',
    'embarreras',
    'embarrerez',
    'embarrions',
    'embasement',
    'embastilla',
    'embastille',
    'embastillé',
    'embattages',
    'embattîmes',
    'embattions',
    'embattisse',
    'embattîtes',
    'embattrais',
    'embattrait',
    'embattriez',
    'embattrons',
    'embattront',
    'embauchage',
    'embauchais',
    'embauchait',
    'embauchant',
    'embauchées',
    'embauchent',
    'embauchera',
    'embaucheur',
    'embauchiez',
    'embauchoir',
    'embauchons',
    'embaumâmes',
    'embaumasse',
    'embaumâtes',
    'embaumerai',
    'embaumeras',
    'embaumerez',
    'embaumeurs',
    'embaumeuse',
    'embaumions',
    'embecquais',
    'embecquait',
    'embecquant',
    'embecquées',
    'embecquent',
    'embecquera',
    'embecquiez',
    'embecquons',
    'émbéguinée',
    'émbéguiner',
    'émbéguinés',
    'émbéguinez',
    'embellîmes',
    'embellirai',
    'embelliras',
    'embellirez',
    'embellissa',
    'embellisse',
    'embellîtes',
    'embêtaient',
    'embêtantes',
    'embêtasses',
    'embêtement',
    'embêterais',
    'embêterait',
    'embêtèrent',
    'embêteriez',
    'embêterons',
    'embêteront',
    'embidonnai',
    'embidonnas',
    'embidonnât',
    'embidonnée',
    'embidonner',
    'embidonnes',
    'embidonnés',
    'embidonnez',
    'emblavages',
    'emblavâmes',
    'emblavasse',
    'emblavâtes',
    'emblaverai',
    'emblaveras',
    'emblaverez',
    'emblavions',
    'emblavures',
    'embobelina',
    'embobeline',
    'embobeliné',
    'embobinais',
    'embobinait',
    'embobinant',
    'embobinées',
    'embobinent',
    'embobinera',
    'embobiniez',
    'embobinons',
    'emboirions',
    'emboîtable',
    'emboîtages',
    'emboîtâmes',
    'emboîtasse',
    'emboîtâtes',
    'emboîterai',
    'emboîteras',
    'emboîterez',
    'emboîtions',
    'emboîtures',
    'embolismes',
    'embonpoint',
    'embossages',
    'embossâmes',
    'embossasse',
    'embossâtes',
    'embosserai',
    'embosseras',
    'embosserez',
    'embossions',
    'embottelai',
    'embottelas',
    'embottelât',
    'embottelée',
    'embotteler',
    'embottelés',
    'embottelez',
    'embottelle',
    'embouaient',
    'embouasses',
    'embouchais',
    'embouchait',
    'embouchant',
    'embouchées',
    'embouchent',
    'embouchera',
    'embouchiez',
    'embouchoir',
    'embouchons',
    'embouchure',
    'embouerais',
    'embouerait',
    'embouèrent',
    'emboueriez',
    'embouerons',
    'emboueront',
    'embouquais',
    'embouquait',
    'embouquant',
    'embouquent',
    'embouquera',
    'embouquiez',
    'embouquons',
    'embourbais',
    'embourbait',
    'embourbant',
    'embourbées',
    'embourbent',
    'embourbera',
    'embourbiez',
    'embourbons',
    'embourrais',
    'embourrait',
    'embourrant',
    'embourrées',
    'embourrent',
    'embourrera',
    'embourriez',
    'embourrons',
    'embourrure',
    'emboutîmes',
    'emboutirai',
    'emboutiras',
    'emboutirez',
    'emboutisse',
    'emboutîtes',
    'embraierai',
    'embraieras',
    'embraierez',
    'embranchai',
    'embranchas',
    'embranchât',
    'embranchée',
    'embrancher',
    'embranches',
    'embranchés',
    'embranchez',
    'embraquais',
    'embraquait',
    'embraquant',
    'embraquées',
    'embraquent',
    'embraquera',
    'embraquiez',
    'embraquons',
    'embrasâmes',
    'embrasasse',
    'embrasâtes',
    'embraserai',
    'embraseras',
    'embraserez',
    'embrasions',
    'embrassade',
    'embrassais',
    'embrassait',
    'embrassant',
    'embrassées',
    'embrassent',
    'embrassera',
    'embrasseur',
    'embrassiez',
    'embrassons',
    'embrasures',
    'embrayages',
    'embrayâmes',
    'embrayasse',
    'embrayâtes',
    'embrayerai',
    'embrayeras',
    'embrayerez',
    'embrayions',
    'embrelâmes',
    'embrelasse',
    'embrelâtes',
    'embrèlerai',
    'embrèleras',
    'embrèlerez',
    'embrelions',
    'embrevâmes',
    'embrevasse',
    'embrevâtes',
    'embrèverai',
    'embrèveras',
    'embrèverez',
    'embrevions',
    'embrigadai',
    'embrigadas',
    'embrigadât',
    'embrigadée',
    'embrigader',
    'embrigades',
    'embrigadés',
    'embrigadez',
    'embringuai',
    'embringuas',
    'embringuât',
    'embringuée',
    'embringuer',
    'embringues',
    'embringués',
    'embringuez',
    'embrochais',
    'embrochait',
    'embrochant',
    'embrochées',
    'embrochent',
    'embrochera',
    'embrochiez',
    'embrochons',
    'embronchai',
    'embronchas',
    'embronchât',
    'embronchée',
    'embroncher',
    'embronches',
    'embronchés',
    'embronchez',
    'embrouilla',
    'embrouille',
    'embrouillé',
    'embrumâmes',
    'embrumasse',
    'embrumâtes',
    'embrumerai',
    'embrumeras',
    'embrumerez',
    'embrumions',
    'embrunîmes',
    'embrunirai',
    'embruniras',
    'embrunirez',
    'embrunisse',
    'embrunîtes',
    'embuassent',
    'embuassiez',
    'embuerions',
    'embuscades',
    'embusquais',
    'embusquait',
    'embusquant',
    'embusquées',
    'embusquent',
    'embusquera',
    'embusquiez',
    'embusquons',
    'embussions',
    'éméchaient',
    'éméchasses',
    'émécherais',
    'émécherait',
    'éméchèrent',
    'émécheriez',
    'émécherons',
    'émécheront',
    'émergeâmes',
    'émergeasse',
    'émergeâtes',
    'émergences',
    'émergerais',
    'émergerait',
    'émergèrent',
    'émergeriez',
    'émergerons',
    'émergeront',
    'émerillons',
    'émerisâmes',
    'émerisasse',
    'émerisâtes',
    'émeriserai',
    'émeriseras',
    'émeriserez',
    'émerisions',
    'émerveilla',
    'émerveille',
    'émerveillé',
    'émettaient',
    'émettrices',
    'émettrions',
    'émeutières',
    'émiassions',
    'émieraient',
    'émiettâmes',
    'émiettasse',
    'émiettâtes',
    'émietterai',
    'émietteras',
    'émietterez',
    'émiettions',
    'émigraient',
    'émigrantes',
    'émigrasses',
    'émigration',
    'émigrerais',
    'émigrerait',
    'émigrèrent',
    'émigreriez',
    'émigrerons',
    'émigreront',
    'éminçaient',
    'éminçasses',
    'émincerais',
    'émincerait',
    'émincèrent',
    'éminceriez',
    'émincerons',
    'éminceront',
    'éminemment',
    'émissaires',
    'emmagasina',
    'emmagasine',
    'emmagasiné',
    'emmaillota',
    'emmaillote',
    'emmailloté',
    'emmanchais',
    'emmanchait',
    'emmanchant',
    'emmanchées',
    'emmanchent',
    'emmanchera',
    'emmanchiez',
    'emmanchons',
    'emmanchure',
    'emmargeais',
    'emmargeait',
    'emmargeant',
    'emmargeons',
    'emmargerai',
    'emmargeras',
    'emmargerez',
    'emmargions',
    'emmêlaient',
    'emmêlasses',
    'emmêlement',
    'emmêlerais',
    'emmêlerait',
    'emmêlèrent',
    'emmêleriez',
    'emmêlerons',
    'emmêleront',
    'emménageai',
    'emménageas',
    'emménageât',
    'emménagées',
    'emménagent',
    'emménagera',
    'emménagiez',
    'emmenaient',
    'emmenasses',
    'emmènerais',
    'emmènerait',
    'emmenèrent',
    'emmèneriez',
    'emmènerons',
    'emmèneront',
    'emmenthals',
    'emmerdâmes',
    'emmerdante',
    'emmerdants',
    'emmerdasse',
    'emmerdâtes',
    'emmerderai',
    'emmerderas',
    'emmerderez',
    'emmerdeurs',
    'emmerdeuse',
    'emmerdions',
    'emmétrâmes',
    'emmétrasse',
    'emmétrâtes',
    'emmétrerai',
    'emmétreras',
    'emmétrerez',
    'emmétrions',
    'emmétropes',
    'emmétropie',
    'emmiellais',
    'emmiellait',
    'emmiellant',
    'emmiellées',
    'emmiellent',
    'emmiellera',
    'emmielliez',
    'emmiellons',
    'emmitonnai',
    'emmitonnas',
    'emmitonnât',
    'emmitonnée',
    'emmitonner',
    'emmitonnes',
    'emmitonnés',
    'emmitonnez',
    'emmitoufla',
    'emmitoufle',
    'emmitouflé',
    'emmortaisa',
    'emmortaise',
    'emmortaisé',
    'emmuraient',
    'emmurasses',
    'emmurerais',
    'emmurerait',
    'emmurèrent',
    'emmureriez',
    'emmurerons',
    'emmureront',
    'émolliente',
    'émollients',
    'émoluments',
    'émonctoire',
    'émondaient',
    'émondasses',
    'émonderais',
    'émonderait',
    'émondèrent',
    'émonderiez',
    'émonderons',
    'émonderont',
    'émorfilage',
    'émorfilais',
    'émorfilait',
    'émorfilant',
    'émorfilées',
    'émorfilent',
    'émorfilera',
    'émorfiliez',
    'émorfilons',
    'émotionnai',
    'émotionnas',
    'émotionnât',
    'émotionnée',
    'émotionnel',
    'émotionner',
    'émotionnes',
    'émotionnés',
    'émotionnez',
    'émotivités',
    'émottaient',
    'émottasses',
    'émotterais',
    'émotterait',
    'émottèrent',
    'émotteriez',
    'émotterons',
    'émotteront',
    'émotteuses',
    'émouchâmes',
    'émouchasse',
    'émouchâtes',
    'émoucherai',
    'émoucheras',
    'émoucherez',
    'émouchetai',
    'émouchetas',
    'émouchetât',
    'émouchetée',
    'émoucheter',
    'émouchetés',
    'émouchètes',
    'émouchetez',
    'émouchette',
    'émouchions',
    'émouchoirs',
    'émoudrions',
    'émoulaient',
    'émoulurent',
    'émoulusses',
    'émoussâmes',
    'émoussasse',
    'émoussâtes',
    'émousserai',
    'émousseras',
    'émousserez',
    'émoussions',
    'émoustilla',
    'émoustille',
    'émoustillé',
    'émouvaient',
    'émouvantes',
    'émouvrions',
    'empaillage',
    'empaillais',
    'empaillait',
    'empaillant',
    'empaillées',
    'empaillent',
    'empailleur',
    'empailliez',
    'empaillons',
    'empalaient',
    'empalasses',
    'empalement',
    'empalerais',
    'empalerait',
    'empalèrent',
    'empaleriez',
    'empalerons',
    'empaleront',
    'empalmâmes',
    'empalmasse',
    'empalmâtes',
    'empalmerai',
    'empalmeras',
    'empalmerez',
    'empalmions',
    'empanachai',
    'empanachas',
    'empanachât',
    'empanachée',
    'empanacher',
    'empanaches',
    'empanachés',
    'empanachez',
    'empannages',
    'empannâmes',
    'empannasse',
    'empannâtes',
    'empannerai',
    'empanneras',
    'empannerez',
    'empannions',
    'empaquetai',
    'empaquetas',
    'empaquetât',
    'empaquetée',
    'empaqueter',
    'empaquetés',
    'empaquetez',
    'empaquette',
    'empareriez',
    'emparerons',
    'emparquais',
    'emparquait',
    'emparquant',
    'emparquées',
    'emparquent',
    'emparquera',
    'emparquiez',
    'emparquons',
    'empâtaient',
    'empâtasses',
    'empâtement',
    'empâterais',
    'empâterait',
    'empâtèrent',
    'empâteriez',
    'empâterons',
    'empâteront',
    'empâtrerai',
    'empâtreras',
    'empâtrerez',
    'empattâmes',
    'empattasse',
    'empattâtes',
    'empatterai',
    'empatteras',
    'empatterez',
    'empattions',
    'empaumâmes',
    'empaumasse',
    'empaumâtes',
    'empaumerai',
    'empaumeras',
    'empaumerez',
    'empaumions',
    'empaumures',
    'empêchâmes',
    'empêchasse',
    'empêchâtes',
    'empêcherai',
    'empêcheras',
    'empêcherez',
    'empêcheurs',
    'empêcheuse',
    'empêchions',
    'empeignais',
    'empeignait',
    'empeignant',
    'empeignées',
    'empeignent',
    'empeignera',
    'empeigniez',
    'empeignons',
    'empênaient',
    'empênasses',
    'empênerais',
    'empênerait',
    'empênèrent',
    'empêneriez',
    'empênerons',
    'empêneront',
    'empennages',
    'empennâmes',
    'empennasse',
    'empennâtes',
    'empennerai',
    'empenneras',
    'empennerez',
    'empennions',
    'emperchais',
    'emperchait',
    'emperchant',
    'emperchées',
    'emperchent',
    'emperchera',
    'emperchiez',
    'emperchons',
    'emperlâmes',
    'emperlasse',
    'emperlâtes',
    'emperlerai',
    'emperleras',
    'emperlerez',
    'emperlions',
    'empesaient',
    'empesasses',
    'empèserais',
    'empèserait',
    'empesèrent',
    'empèseriez',
    'empèserons',
    'empèseront',
    'empestâmes',
    'empestasse',
    'empestâtes',
    'empesterai',
    'empesteras',
    'empesterez',
    'empestions',
    'empêtrâmes',
    'empêtrasse',
    'empêtrâtes',
    'empêtrerai',
    'empêtreras',
    'empêtrerez',
    'empêtrions',
    'emphatique',
    'emphysèmes',
    'emphytéose',
    'emphytéote',
    'empierrais',
    'empierrait',
    'empierrant',
    'empierrées',
    'empierrent',
    'empierrera',
    'empierriez',
    'empierrons',
    'empiétâmes',
    'empiétasse',
    'empiétâtes',
    'empiéterai',
    'empiéteras',
    'empiéterez',
    'empiétions',
    'empiffrées',
    'empiffriez',
    'empiffrons',
    'empilaient',
    'empilasses',
    'empilement',
    'empilerais',
    'empilerait',
    'empilèrent',
    'empileriez',
    'empilerons',
    'empileront',
    'empileuses',
    'empiraient',
    'empirasses',
    'empirerais',
    'empirerait',
    'empirèrent',
    'empireriez',
    'empirerons',
    'empireront',
    'empiriques',
    'empirismes',
    'empiristes',
    'emplanture',
    'emplâtrais',
    'emplâtrait',
    'emplâtrant',
    'emplâtrées',
    'emplâtrent',
    'emplâtriez',
    'emplâtrons',
    'emplirions',
    'emplissage',
    'emplissais',
    'emplissait',
    'emplissant',
    'emplissent',
    'emplissiez',
    'emplissons',
    'emploierai',
    'emploieras',
    'emploierez',
    'employable',
    'employâmes',
    'employasse',
    'employâtes',
    'employeurs',
    'employeuse',
    'employions',
    'emplumâmes',
    'emplumasse',
    'emplumâtes',
    'emplumerai',
    'emplumeras',
    'emplumerez',
    'emplumions',
    'empochâmes',
    'empochasse',
    'empochâtes',
    'empocherai',
    'empocheras',
    'empocherez',
    'empochions',
    'empoignade',
    'empoignais',
    'empoignait',
    'empoignant',
    'empoignées',
    'empoignent',
    'empoignera',
    'empoigniez',
    'empoignons',
    'empointure',
    'empoisonna',
    'empoisonne',
    'empoisonné',
    'empoissais',
    'empoissait',
    'empoissant',
    'empoissées',
    'empoissent',
    'empoissera',
    'empoissiez',
    'empoissons',
    'emportâmes',
    'emportasse',
    'emportâtes',
    'emporterai',
    'emporteras',
    'emporterez',
    'emportions',
    'empotaient',
    'empotasses',
    'empoterais',
    'empoterait',
    'empotèrent',
    'empoteriez',
    'empoterons',
    'empoteront',
    'empourprai',
    'empourpras',
    'empourprât',
    'empourprée',
    'empourprer',
    'empourpres',
    'empourprés',
    'empourprez',
    'empreignes',
    'empreignez',
    'empreignis',
    'empreignit',
    'empreignît',
    'empreindra',
    'empreindre',
    'empreintes',
    'empressées',
    'empressiez',
    'empressons',
    'emprésurai',
    'emprésuras',
    'emprésurât',
    'emprésurée',
    'emprésurer',
    'emprésures',
    'emprésurés',
    'emprésurez',
    'emprisonna',
    'emprisonne',
    'emprisonné',
    'empruntais',
    'empruntait',
    'empruntant',
    'empruntées',
    'empruntent',
    'empruntera',
    'emprunteur',
    'empruntiez',
    'empruntons',
    'empuanties',
    'empuantira',
    'empyreumes',
    'émulassent',
    'émulassiez',
    'émulations',
    'émulerions',
    'émulsifiai',
    'émulsifias',
    'émulsifiât',
    'émulsifiée',
    'émulsifier',
    'émulsifies',
    'émulsifiés',
    'émulsifiez',
    'émulsionna',
    'émulsionne',
    'émulsionné',
    'enamourées',
    'énamourées',
    'enamouriez',
    'énamouriez',
    'enamourons',
    'énamourons',
    'énanthèmes',
    'énarthrose',
    'encabanage',
    'encabanais',
    'encabanait',
    'encabanant',
    'encabanées',
    'encabanent',
    'encabanera',
    'encabaniez',
    'encabanons',
    'encablures',
    'encadrâmes',
    'encadrasse',
    'encadrâtes',
    'encadrerai',
    'encadreras',
    'encadrerez',
    'encadreurs',
    'encadreuse',
    'encadrions',
    'encageâmes',
    'encageasse',
    'encageâtes',
    'encagement',
    'encagerais',
    'encagerait',
    'encagèrent',
    'encageriez',
    'encagerons',
    'encageront',
    'encaissage',
    'encaissais',
    'encaissait',
    'encaissant',
    'encaissées',
    'encaissent',
    'encaissera',
    'encaisseur',
    'encaissiez',
    'encaissons',
    'encalminée',
    'encalminés',
    'encanailla',
    'encanaille',
    'encanaillé',
    'encaquâmes',
    'encaquasse',
    'encaquâtes',
    'encaquerai',
    'encaqueras',
    'encaquerez',
    'encaquions',
    'encartages',
    'encartâmes',
    'encartasse',
    'encartâtes',
    'encarterai',
    'encarteras',
    'encarterez',
    'encarteuse',
    'encartions',
    'encartonna',
    'encartonne',
    'encartonné',
    'encasernai',
    'encasernas',
    'encasernât',
    'encasernée',
    'encaserner',
    'encasernes',
    'encasernés',
    'encasernez',
    'encastelée',
    'encasteler',
    'encastelés',
    'encastelez',
    'encastrais',
    'encastrait',
    'encastrant',
    'encastrées',
    'encastrent',
    'encastrera',
    'encastriez',
    'encastrons',
    'encavaient',
    'encavasses',
    'encavement',
    'encaverais',
    'encaverait',
    'encavèrent',
    'encaveriez',
    'encaverons',
    'encaveront',
    'encensâmes',
    'encensasse',
    'encensâtes',
    'encenserai',
    'encenseras',
    'encenserez',
    'encenseurs',
    'encenseuse',
    'encensions',
    'encensoirs',
    'encéphales',
    'encerclais',
    'encerclait',
    'encerclant',
    'encerclées',
    'encerclent',
    'encerclera',
    'encercliez',
    'encerclons',
    'enchaînais',
    'enchaînait',
    'enchaînant',
    'enchaînées',
    'enchaînent',
    'enchaînera',
    'enchaîniez',
    'enchaînons',
    'enchantais',
    'enchantait',
    'enchantant',
    'enchantées',
    'enchantent',
    'enchantera',
    'enchanteur',
    'enchantiez',
    'enchantons',
    'encharnais',
    'encharnait',
    'encharnant',
    'encharnées',
    'encharnent',
    'encharnera',
    'encharniez',
    'encharnons',
    'enchâssais',
    'enchâssait',
    'enchâssant',
    'enchâssées',
    'enchâssent',
    'enchâssera',
    'enchâssiez',
    'enchâssons',
    'enchâssure',
    'enchatonna',
    'enchatonne',
    'enchatonné',
    'enchaussai',
    'enchaussas',
    'enchaussât',
    'enchaussée',
    'enchausser',
    'enchausses',
    'enchaussés',
    'enchaussez',
    'enchemisai',
    'enchemisas',
    'enchemisât',
    'enchemisée',
    'enchemiser',
    'enchemises',
    'enchemisés',
    'enchemisez',
    'enchérîmes',
    'enchérirai',
    'enchériras',
    'enchérirez',
    'enchérisse',
    'enchérîtes',
    'enchevêtra',
    'enchevêtre',
    'enchevêtré',
    'enchifrena',
    'enchifrené',
    'enchifrène',
    'enciraient',
    'encirasses',
    'encirerais',
    'encirerait',
    'encirèrent',
    'encireriez',
    'encirerons',
    'encireront',
    'enclavâmes',
    'enclavasse',
    'enclavâtes',
    'enclaverai',
    'enclaveras',
    'enclaverez',
    'enclavions',
    'enclenchai',
    'enclenchas',
    'enclenchât',
    'enclenchée',
    'enclencher',
    'enclenches',
    'enclenchés',
    'enclenchez',
    'encliqueta',
    'encliqueté',
    'enclitique',
    'encloîtrai',
    'encloîtras',
    'encloîtrât',
    'encloîtrée',
    'encloîtrer',
    'encloîtres',
    'encloîtrés',
    'encloîtrez',
    'enclorions',
    'enclouâmes',
    'enclouasse',
    'enclouâtes',
    'enclouerai',
    'encloueras',
    'enclouerez',
    'enclouions',
    'enclouures',
    'encochâmes',
    'encochasse',
    'encochâtes',
    'encocherai',
    'encocheras',
    'encocherez',
    'encochions',
    'encodaient',
    'encodasses',
    'encoderais',
    'encoderait',
    'encodèrent',
    'encoderiez',
    'encoderons',
    'encoderont',
    'encoffrais',
    'encoffrait',
    'encoffrant',
    'encoffrées',
    'encoffrent',
    'encoffrera',
    'encoffriez',
    'encoffrons',
    'encoignure',
    'encollages',
    'encollâmes',
    'encollasse',
    'encollâtes',
    'encollerai',
    'encolleras',
    'encollerez',
    'encolleurs',
    'encolleuse',
    'encollions',
    'encombrais',
    'encombrait',
    'encombrant',
    'encombrées',
    'encombrent',
    'encombrera',
    'encombriez',
    'encombrons',
    'encordâmes',
    'encordasse',
    'encordâtes',
    'encorderai',
    'encorderas',
    'encorderez',
    'encordions',
    'encornâmes',
    'encornasse',
    'encornâtes',
    'encornerai',
    'encorneras',
    'encornerez',
    'encornions',
    'encouragea',
    'encouragée',
    'encourager',
    'encourages',
    'encouragés',
    'encouragez',
    'encourions',
    'encourrais',
    'encourrait',
    'encourriez',
    'encourrons',
    'encourront',
    'encourûmes',
    'encourusse',
    'encourûtes',
    'encrassais',
    'encrassait',
    'encrassant',
    'encrassées',
    'encrassent',
    'encrassera',
    'encrassiez',
    'encrassons',
    'encrêpâmes',
    'encrêpasse',
    'encrêpâtes',
    'encrêperai',
    'encrêperas',
    'encrêperez',
    'encrêpions',
    'encrerions',
    'encroûtais',
    'encroûtait',
    'encroûtant',
    'encroûtées',
    'encroûtent',
    'encroûtera',
    'encroûtiez',
    'encroûtons',
    'encuvaient',
    'encuvasses',
    'encuverais',
    'encuverait',
    'encuvèrent',
    'encuveriez',
    'encuverons',
    'encuveront',
    'encyclique',
    'endaubâmes',
    'endaubasse',
    'endaubâtes',
    'endauberai',
    'endauberas',
    'endauberez',
    'endaubions',
    'endémiques',
    'endentâmes',
    'endentasse',
    'endentâtes',
    'endenterai',
    'endenteras',
    'endenterez',
    'endentions',
    'endettâmes',
    'endettasse',
    'endettâtes',
    'endetterai',
    'endetteras',
    'endetterez',
    'endettions',
    'endeuillai',
    'endeuillas',
    'endeuillât',
    'endeuillée',
    'endeuiller',
    'endeuilles',
    'endeuillés',
    'endeuillez',
    'endiablais',
    'endiablait',
    'endiablant',
    'endiablées',
    'endiablent',
    'endiablera',
    'endiabliez',
    'endiablons',
    'endiguâmes',
    'endiguasse',
    'endiguâtes',
    'endiguerai',
    'endigueras',
    'endiguerez',
    'endiguions',
    'endimancha',
    'endimanche',
    'endimanché',
    'endoblaste',
    'endocardes',
    'endocarpes',
    'endocrines',
    'endoctrina',
    'endoctrine',
    'endoctriné',
    'endodermes',
    'endogamies',
    'endolories',
    'endolorira',
    'endomètres',
    'endommagea',
    'endommagée',
    'endommager',
    'endommages',
    'endommagés',
    'endommagez',
    'endormante',
    'endormants',
    'endormeurs',
    'endormeuse',
    'endormîmes',
    'endormions',
    'endormirai',
    'endormiras',
    'endormirez',
    'endormisse',
    'endormîtes',
    'endorphine',
    'endoscopes',
    'endoscopie',
    'endosmoses',
    'endossable',
    'endossâmes',
    'endossasse',
    'endossâtes',
    'endosserai',
    'endosseras',
    'endosserez',
    'endosseurs',
    'endossions',
    'endotoxine',
    'enduirions',
    'enduisîmes',
    'enduisions',
    'enduisisse',
    'enduisîtes',
    'endurables',
    'enduraient',
    'endurances',
    'endurantes',
    'endurasses',
    'endurcîmes',
    'endurcirai',
    'endurciras',
    'endurcirez',
    'endurcisse',
    'endurcîtes',
    'endurerais',
    'endurerait',
    'endurèrent',
    'endureriez',
    'endurerons',
    'endureront',
    'énergiques',
    'énergisant',
    'énergumène',
    'énervaient',
    'énervantes',
    'énervasses',
    'énervation',
    'énervement',
    'énerverais',
    'énerverait',
    'énervèrent',
    'énerveriez',
    'énerverons',
    'énerveront',
    'enfaîtâmes',
    'enfaîtasse',
    'enfaîtâtes',
    'enfaîteaux',
    'enfaîterai',
    'enfaîteras',
    'enfaîterez',
    'enfaîtions',
    'enfantâmes',
    'enfantasse',
    'enfantâtes',
    'enfanterai',
    'enfanteras',
    'enfanterez',
    'enfantines',
    'enfantions',
    'enfarinais',
    'enfarinait',
    'enfarinant',
    'enfarinées',
    'enfarinent',
    'enfarinera',
    'enfariniez',
    'enfarinons',
    'enfermâmes',
    'enfermasse',
    'enfermâtes',
    'enfermerai',
    'enfermeras',
    'enfermerez',
    'enfermions',
    'enferrâmes',
    'enferrasse',
    'enferrâtes',
    'enferrerai',
    'enferreras',
    'enferrerez',
    'enferrions',
    'enfichable',
    'enfichâmes',
    'enfichasse',
    'enfichâtes',
    'enficherai',
    'enficheras',
    'enficherez',
    'enfichions',
    'enfiellais',
    'enfiellait',
    'enfiellant',
    'enfiellées',
    'enfiellent',
    'enfiellera',
    'enfielliez',
    'enfiellons',
    'enfiévrais',
    'enfiévrait',
    'enfiévrant',
    'enfiévrées',
    'enfièvrent',
    'enfiévrera',
    'enfiévriez',
    'enfiévrons',
    'enfilaient',
    'enfilasses',
    'enfilerais',
    'enfilerait',
    'enfilèrent',
    'enfileriez',
    'enfilerons',
    'enfileront',
    'enfileuses',
    'enflammais',
    'enflammait',
    'enflammant',
    'enflammées',
    'enflamment',
    'enflammera',
    'enflammiez',
    'enflammons',
    'enflassent',
    'enflassiez',
    'enfléchais',
    'enfléchait',
    'enfléchant',
    'enfléchées',
    'enflèchent',
    'enfléchera',
    'enfléchiez',
    'enfléchons',
    'enfléchure',
    'enflerions',
    'enfleurage',
    'enfleurais',
    'enfleurait',
    'enfleurant',
    'enfleurées',
    'enfleurent',
    'enfleurera',
    'enfleuriez',
    'enfleurons',
    'enfonçâmes',
    'enfonçasse',
    'enfonçâtes',
    'enfoncerai',
    'enfonceras',
    'enfoncerez',
    'enfonceurs',
    'enfonceuse',
    'enfoncions',
    'enfonçures',
    'enforcîmes',
    'enforcirai',
    'enforciras',
    'enforcirez',
    'enforcisse',
    'enforcîtes',
    'enfouirais',
    'enfouirait',
    'enfouirent',
    'enfouiriez',
    'enfouirons',
    'enfouiront',
    'enfouisses',
    'enfouissez',
    'enfourchai',
    'enfourchas',
    'enfourchât',
    'enfourchée',
    'enfourcher',
    'enfourches',
    'enfourchés',
    'enfourchez',
    'enfournage',
    'enfournais',
    'enfournait',
    'enfournant',
    'enfournées',
    'enfournent',
    'enfournera',
    'enfourniez',
    'enfournons',
    'enfreignes',
    'enfreignez',
    'enfreignis',
    'enfreignit',
    'enfreignît',
    'enfreindra',
    'enfreindre',
    'enfreintes',
    'enfuirions',
    'enfuissiez',
    'enfumaient',
    'enfumasses',
    'enfumerais',
    'enfumerait',
    'enfumèrent',
    'enfumeriez',
    'enfumerons',
    'enfumeront',
    'enfûtaient',
    'enfutailla',
    'enfutaille',
    'enfutaillé',
    'enfûtasses',
    'enfûterais',
    'enfûterait',
    'enfûtèrent',
    'enfûteriez',
    'enfûterons',
    'enfûteront',
    'engageâmes',
    'engageante',
    'engageants',
    'engageasse',
    'engageâtes',
    'engagement',
    'engagerais',
    'engagerait',
    'engagèrent',
    'engageriez',
    'engagerons',
    'engageront',
    'engainâmes',
    'engainasse',
    'engainâtes',
    'engainerai',
    'engaineras',
    'engainerez',
    'engainions',
    'engamaient',
    'engamasses',
    'engamerais',
    'engamerait',
    'engamèrent',
    'engameriez',
    'engamerons',
    'engameront',
    'engavaient',
    'engavasses',
    'engaverais',
    'engaverait',
    'engavèrent',
    'engaveriez',
    'engaverons',
    'engaveront',
    'engazonnai',
    'engazonnas',
    'engazonnât',
    'engazonnée',
    'engazonner',
    'engazonnes',
    'engazonnés',
    'engazonnez',
    'engendrais',
    'engendrait',
    'engendrant',
    'engendrées',
    'engendrent',
    'engendrera',
    'engendriez',
    'engendrons',
    'engerbâmes',
    'engerbasse',
    'engerbâtes',
    'engerberai',
    'engerberas',
    'engerberez',
    'engerbions',
    'englaçâmes',
    'englaçasse',
    'englaçâtes',
    'englacerai',
    'englaceras',
    'englacerez',
    'englacions',
    'englobâmes',
    'englobante',
    'englobants',
    'englobasse',
    'englobâtes',
    'engloberai',
    'engloberas',
    'engloberez',
    'englobions',
    'englouties',
    'engloutira',
    'engluaient',
    'engluasses',
    'engluement',
    'engluerais',
    'engluerait',
    'engluèrent',
    'englueriez',
    'engluerons',
    'englueront',
    'engobaient',
    'engobasses',
    'engoberais',
    'engoberait',
    'engobèrent',
    'engoberiez',
    'engoberons',
    'engoberont',
    'engommages',
    'engommâmes',
    'engommasse',
    'engommâtes',
    'engommerai',
    'engommeras',
    'engommerez',
    'engommions',
    'engonçâmes',
    'engonçasse',
    'engonçâtes',
    'engoncerai',
    'engonceras',
    'engoncerez',
    'engoncions',
    'engorgeais',
    'engorgeait',
    'engorgeant',
    'engorgeons',
    'engorgerai',
    'engorgeras',
    'engorgerez',
    'engorgions',
    'engouement',
    'engoueriez',
    'engouerons',
    'engouffrai',
    'engouffras',
    'engouffrât',
    'engouffrée',
    'engouffrer',
    'engouffres',
    'engouffrés',
    'engouffrez',
    'engoulâmes',
    'engoulasse',
    'engoulâtes',
    'engoulerai',
    'engouleras',
    'engoulerez',
    'engoulions',
    'engourdies',
    'engourdira',
    'engraissai',
    'engraissas',
    'engraissât',
    'engraissée',
    'engraisser',
    'engraisses',
    'engraissés',
    'engraissez',
    'engrangeai',
    'engrangeas',
    'engrangeât',
    'engrangées',
    'engrangent',
    'engrangera',
    'engrangiez',
    'engravâmes',
    'engravasse',
    'engravâtes',
    'engraverai',
    'engraveras',
    'engraverez',
    'engravions',
    'engrenages',
    'engrenâmes',
    'engrenasse',
    'engrenâtes',
    'engrènerai',
    'engrèneras',
    'engrènerez',
    'engreneurs',
    'engreneuse',
    'engrenions',
    'engrossais',
    'engrossait',
    'engrossant',
    'engrossées',
    'engrossent',
    'engrossera',
    'engrossiez',
    'engrossons',
    'engrumelai',
    'engrumelas',
    'engrumelât',
    'engrumelée',
    'engrumeler',
    'engrumelés',
    'engrumelez',
    'engrumelle',
    'engueulade',
    'engueulais',
    'engueulait',
    'engueulant',
    'engueulées',
    'engueulent',
    'engueulera',
    'engueuliez',
    'engueulons',
    'enguichure',
    'enhardîmes',
    'enhardirai',
    'enhardiras',
    'enhardirez',
    'enhardisse',
    'enhardîtes',
    'enharmonie',
    'enharnacha',
    'enharnache',
    'enharnaché',
    'enherbâmes',
    'enherbasse',
    'enherbâtes',
    'enherberai',
    'enherberas',
    'enherberez',
    'enherbions',
    'éniellâmes',
    'éniellasse',
    'éniellâtes',
    'éniellerai',
    'énielleras',
    'éniellerez',
    'éniellions',
    'enivraient',
    'enivrantes',
    'enivrasses',
    'enivrement',
    'enivrerais',
    'enivrerait',
    'enivrèrent',
    'enivreriez',
    'enivrerons',
    'enivreront',
    'enjambâmes',
    'enjambasse',
    'enjambâtes',
    'enjamberai',
    'enjamberas',
    'enjamberez',
    'enjambions',
    'enjavelais',
    'enjavelait',
    'enjavelant',
    'enjavelées',
    'enjaveliez',
    'enjavelles',
    'enjavelons',
    'enjoignais',
    'enjoignait',
    'enjoignant',
    'enjoignent',
    'enjoigniez',
    'enjoignons',
    'enjoindrai',
    'enjoindras',
    'enjoindrez',
    'enjôlaient',
    'enjôlasses',
    'enjôlement',
    'enjôlerais',
    'enjôlerait',
    'enjôlèrent',
    'enjôleriez',
    'enjôlerons',
    'enjôleront',
    'enjôleuses',
    'enjolivais',
    'enjolivait',
    'enjolivant',
    'enjolivées',
    'enjolivent',
    'enjolivera',
    'enjoliveur',
    'enjoliviez',
    'enjolivons',
    'enjolivure',
    'enjonçâmes',
    'enjonçasse',
    'enjonçâtes',
    'enjoncerai',
    'enjonceras',
    'enjoncerez',
    'enjoncions',
    'enjouaient',
    'enjouasses',
    'enjouement',
    'enjouerais',
    'enjouerait',
    'enjouèrent',
    'enjoueriez',
    'enjouerons',
    'enjoueront',
    'enjuguâmes',
    'enjuguasse',
    'enjuguâtes',
    'enjuguerai',
    'enjugueras',
    'enjuguerez',
    'enjuguions',
    'enjuivâmes',
    'enjuivasse',
    'enjuivâtes',
    'enjuiverai',
    'enjuiveras',
    'enjuiverez',
    'enjuivions',
    'enjuponnai',
    'enjuponnas',
    'enjuponnât',
    'enjuponnée',
    'enjuponner',
    'enjuponnes',
    'enjuponnés',
    'enjuponnez',
    'enkystâmes',
    'enkystâtes',
    'enkysterez',
    'enkystions',
    'enlaçaient',
    'enlaçasses',
    'enlacement',
    'enlacerais',
    'enlacerait',
    'enlacèrent',
    'enlaceriez',
    'enlacerons',
    'enlaceront',
    'enlaidîmes',
    'enlaidirai',
    'enlaidiras',
    'enlaidirez',
    'enlaidisse',
    'enlaidîtes',
    'enlevaient',
    'enlevasses',
    'enlèvement',
    'enlèverais',
    'enlèverait',
    'enlevèrent',
    'enlèveriez',
    'enlèverons',
    'enlèveront',
    'enliassais',
    'enliassait',
    'enliassant',
    'enliassées',
    'enliassent',
    'enliassera',
    'enliassiez',
    'enliassons',
    'enlierions',
    'enlignâmes',
    'enlignasse',
    'enlignâtes',
    'enlignerai',
    'enligneras',
    'enlignerez',
    'enlignions',
    'enlisaient',
    'enlisasses',
    'enlisement',
    'enliserais',
    'enliserait',
    'enlisèrent',
    'enliseriez',
    'enliserons',
    'enliseront',
    'enluminais',
    'enluminait',
    'enluminant',
    'enluminées',
    'enluminent',
    'enluminera',
    'enlumineur',
    'enluminiez',
    'enluminons',
    'enluminure',
    'ennéagonal',
    'ennéagones',
    'enneigeais',
    'enneigeait',
    'enneigeant',
    'enneigeons',
    'enneigerai',
    'enneigeras',
    'enneigerez',
    'enneigions',
    'ennoblîmes',
    'ennoblirai',
    'ennobliras',
    'ennoblirez',
    'ennoblisse',
    'ennoblîtes',
    'ennuageais',
    'ennuageait',
    'ennuageant',
    'ennuageons',
    'ennuagerai',
    'ennuageras',
    'ennuagerez',
    'ennuagions',
    'ennuierais',
    'ennuierait',
    'ennuieriez',
    'ennuierons',
    'ennuieront',
    'ennuyaient',
    'ennuyasses',
    'ennuyèrent',
    'ennuyeuses',
    'énonçaient',
    'énonçasses',
    'énoncerais',
    'énoncerait',
    'énoncèrent',
    'énonceriez',
    'énoncerons',
    'énonceront',
    'énonciatif',
    'énormément',
    'énouassent',
    'énouassiez',
    'énouerions',
    'enquérions',
    'enquerriez',
    'enquerrons',
    'enquêtâmes',
    'enquêtasse',
    'enquêtâtes',
    'enquêterai',
    'enquêteras',
    'enquêterez',
    'enquêteurs',
    'enquêteuse',
    'enquêtions',
    'enquêtrice',
    'enquiquina',
    'enquiquine',
    'enquiquiné',
    'enquissiez',
    'enracinais',
    'enracinait',
    'enracinant',
    'enracinées',
    'enracinent',
    'enracinera',
    'enraciniez',
    'enracinons',
    'enrageâmes',
    'enrageante',
    'enrageants',
    'enrageasse',
    'enrageâtes',
    'enragerais',
    'enragerait',
    'enragèrent',
    'enrageriez',
    'enragerons',
    'enrageront',
    'enraiement',
    'enraierais',
    'enraierait',
    'enraieriez',
    'enraierons',
    'enraieront',
    'enraillais',
    'enraillait',
    'enraillant',
    'enraillées',
    'enraillent',
    'enraillera',
    'enrailliez',
    'enraillons',
    'enraquâmes',
    'enraquasse',
    'enraquâtes',
    'enrayaient',
    'enrayasses',
    'enrayement',
    'enrayerais',
    'enrayerait',
    'enrayèrent',
    'enrayeriez',
    'enrayerons',
    'enrayeront',
    'enregistra',
    'enregistre',
    'enregistré',
    'enrênaient',
    'enrênasses',
    'enrênerais',
    'enrênerait',
    'enrênèrent',
    'enrêneriez',
    'enrênerons',
    'enrêneront',
    'enrhumâmes',
    'enrhumasse',
    'enrhumâtes',
    'enrhumerai',
    'enrhumeras',
    'enrhumerez',
    'enrhumions',
    'enrichîmes',
    'enrichirai',
    'enrichiras',
    'enrichirez',
    'enrichisse',
    'enrichîtes',
    'enrobaient',
    'enrobasses',
    'enrobement',
    'enroberais',
    'enroberait',
    'enrobèrent',
    'enroberiez',
    'enroberons',
    'enroberont',
    'enrobeuses',
    'enrochâmes',
    'enrochasse',
    'enrochâtes',
    'enrocherai',
    'enrocheras',
    'enrocherez',
    'enrochions',
    'enrôlaient',
    'enrôlasses',
    'enrôlement',
    'enrôlerais',
    'enrôlerait',
    'enrôlèrent',
    'enrôleriez',
    'enrôlerons',
    'enrôleront',
    'enrouaient',
    'enrouasses',
    'enrouement',
    'enrouerais',
    'enrouerait',
    'enrouèrent',
    'enroueriez',
    'enrouerons',
    'enroueront',
    'enrouillai',
    'enrouillas',
    'enrouillât',
    'enrouiller',
    'enrouilles',
    'enrouillez',
    'enroulâmes',
    'enroulasse',
    'enroulâtes',
    'enroulerai',
    'enrouleras',
    'enroulerez',
    'enrouleurs',
    'enrouleuse',
    'enroulions',
    'enrubannai',
    'enrubannas',
    'enrubannât',
    'enrubannée',
    'enrubanner',
    'enrubannes',
    'enrubannés',
    'enrubannez',
    'ensablâmes',
    'ensablasse',
    'ensablâtes',
    'ensablerai',
    'ensableras',
    'ensablerez',
    'ensablions',
    'ensabotais',
    'ensabotait',
    'ensabotant',
    'ensabotées',
    'ensabotent',
    'ensabotera',
    'ensabotiez',
    'ensabotons',
    'ensachages',
    'ensachâmes',
    'ensachasse',
    'ensachâtes',
    'ensacherai',
    'ensacheras',
    'ensacherez',
    'ensacheurs',
    'ensacheuse',
    'ensachions',
    'ensaisinai',
    'ensaisinas',
    'ensaisinât',
    'ensaisinée',
    'ensaisiner',
    'ensaisines',
    'ensaisinés',
    'ensaisinez',
    'ensauvâmes',
    'ensauvâtes',
    'ensauverez',
    'ensauvions',
    'enseignais',
    'enseignait',
    'enseignant',
    'enseignées',
    'enseignent',
    'enseignera',
    'enseigniez',
    'enseignons',
    'ensemblier',
    'ensemençai',
    'ensemenças',
    'ensemençât',
    'ensemencée',
    'ensemencer',
    'ensemences',
    'ensemencés',
    'ensemencez',
    'enserrâmes',
    'enserrasse',
    'enserrâtes',
    'enserrerai',
    'enserreras',
    'enserrerez',
    'enserrions',
    'ensevelies',
    'ensevelira',
    'ensiformes',
    'ensilaient',
    'ensilasses',
    'ensilerais',
    'ensilerait',
    'ensilèrent',
    'ensileriez',
    'ensilerons',
    'ensileront',
    'ensoleilla',
    'ensoleille',
    'ensoleillé',
    'ensorcelai',
    'ensorcelas',
    'ensorcelât',
    'ensorcelée',
    'ensorceler',
    'ensorcelés',
    'ensorcelez',
    'ensorcelle',
    'ensoufrais',
    'ensoufrait',
    'ensoufrant',
    'ensoufrées',
    'ensoufrent',
    'ensoufrera',
    'ensoufriez',
    'ensoufrons',
    'enstérâmes',
    'enstérasse',
    'enstérâtes',
    'enstérerai',
    'enstéreras',
    'enstérerez',
    'enstérions',
    'entablâmes',
    'entablasse',
    'entablâtes',
    'entablerai',
    'entableras',
    'entablerez',
    'entablions',
    'entachâmes',
    'entachasse',
    'entachâtes',
    'entacherai',
    'entacheras',
    'entacherez',
    'entachions',
    'entaillage',
    'entaillais',
    'entaillait',
    'entaillant',
    'entaillées',
    'entaillent',
    'entaillera',
    'entailliez',
    'entaillons',
    'entamaient',
    'entamasses',
    'entamerais',
    'entamerait',
    'entamèrent',
    'entameriez',
    'entamerons',
    'entameront',
    'entaquerai',
    'entaqueras',
    'entaquerez',
    'entaquions',
    'entartrage',
    'entartrais',
    'entartrait',
    'entartrant',
    'entartrées',
    'entartrent',
    'entartrera',
    'entartriez',
    'entartrons',
    'entassâmes',
    'entassasse',
    'entassâtes',
    'entasserai',
    'entasseras',
    'entasserez',
    'entassions',
    'entéléchie',
    'entendeurs',
    'entendîmes',
    'entendions',
    'entendisse',
    'entendîtes',
    'entendrais',
    'entendrait',
    'entendriez',
    'entendrons',
    'entendront',
    'enténébrai',
    'enténébras',
    'enténébrât',
    'enténébrée',
    'enténébrer',
    'enténébrés',
    'enténèbres',
    'enténébrez',
    'enteraient',
    'entéralgie',
    'entérinais',
    'entérinait',
    'entérinant',
    'entérinées',
    'entérinent',
    'entérinera',
    'entériniez',
    'entérinons',
    'entériques',
    'enterrages',
    'enterrâmes',
    'enterrasse',
    'enterrâtes',
    'enterrerai',
    'enterreras',
    'enterrerez',
    'enterrions',
    'entêtaient',
    'entêtantes',
    'entêtasses',
    'entêtement',
    'entêterais',
    'entêterait',
    'entêtèrent',
    'entêteriez',
    'entêterons',
    'entêteront',
    'enthalpies',
    'enthymèmes',
    'entichâmes',
    'entichâtes',
    'enticherez',
    'entichions',
    'entièretés',
    'entoilages',
    'entoilâmes',
    'entoilasse',
    'entoilâtes',
    'entoilerai',
    'entoileras',
    'entoilerez',
    'entoilions',
    'entôlaient',
    'entôlasses',
    'entôlerais',
    'entôlerait',
    'entôlèrent',
    'entôleriez',
    'entôlerons',
    'entôleront',
    'entonnages',
    'entonnâmes',
    'entonnasse',
    'entonnâtes',
    'entonnerai',
    'entonneras',
    'entonnerez',
    'entonnions',
    'entonnoirs',
    'entortilla',
    'entortille',
    'entortillé',
    'entourages',
    'entourâmes',
    'entourasse',
    'entourâtes',
    'entourerai',
    'entoureras',
    'entourerez',
    'entourions',
    'entournure',
    'entraccusé',
    'entradmiré',
    'entraidées',
    'entraidiez',
    'entraidons',
    'entrailles',
    'entraînais',
    'entraînait',
    'entraînant',
    'entraînées',
    'entraînent',
    'entraînera',
    'entraîneur',
    'entraîniez',
    'entraînons',
    'entrassent',
    'entrassiez',
    'entravâmes',
    'entravasse',
    'entravâtes',
    'entraverai',
    'entraveras',
    'entraverez',
    'entravions',
    'entrebattu',
    'entrechats',
    'entrecôtes',
    'entrecoupa',
    'entrecoupe',
    'entrecoupé',
    'entre-deux',
    'entrefaite',
    'entrefilet',
    'entregents',
    'entre-haïe',
    'entre-haïs',
    'entrejambe',
    'entrelaçai',
    'entrelaças',
    'entrelaçât',
    'entrelacée',
    'entrelacer',
    'entrelaces',
    'entrelacés',
    'entrelacez',
    'entrelarda',
    'entrelarde',
    'entrelardé',
    'entre-loué',
    'entremêlai',
    'entremêlas',
    'entremêlât',
    'entremêlée',
    'entremêler',
    'entremêles',
    'entremêlés',
    'entremêlez',
    'entremette',
    'entremîmes',
    'entremises',
    'entremisse',
    'entremîtes',
    'entre-nerf',
    'entre-nous',
    'entreponts',
    'entreposai',
    'entreposas',
    'entreposât',
    'entreposée',
    'entreposer',
    'entreposes',
    'entreposés',
    'entreposez',
    'entreprend',
    'entreprise',
    'entre-rail',
    'entrerions',
    'entretenez',
    'entretenir',
    'entretenue',
    'entretenus',
    'entretiens',
    'entretient',
    'entretoisa',
    'entretoise',
    'entretoisé',
    'entre-tuée',
    'entre-tuer',
    'entre-tués',
    'entre-tuez',
    'entreverra',
    'entrevîmes',
    'entrevisse',
    'entrevîtes',
    'entrevoies',
    'entre-vous',
    'entrevoûta',
    'entrevoûte',
    'entrevoûté',
    'entrevoyez',
    'entropions',
    'entrouvert',
    'entrouvres',
    'entrouvrez',
    'entrouvrir',
    'entrouvris',
    'entrouvrit',
    'entrouvrît',
    'entubaient',
    'entubasses',
    'entuberais',
    'entuberait',
    'entubèrent',
    'entuberiez',
    'entuberons',
    'entuberont',
    'enturbanné',
    'énucléâmes',
    'énucléasse',
    'énucléâtes',
    'énucléerai',
    'énucléeras',
    'énucléerez',
    'énucléions',
    'énumérâmes',
    'énumérasse',
    'énumérâtes',
    'énumératif',
    'énumérerai',
    'énuméreras',
    'énumérerez',
    'énumérions',
    'envahirais',
    'envahirait',
    'envahirent',
    'envahiriez',
    'envahirons',
    'envahiront',
    'envahisses',
    'envahissez',
    'envasaient',
    'envasasses',
    'envasement',
    'envaserais',
    'envaserait',
    'envasèrent',
    'envaseriez',
    'envaserons',
    'envaseront',
    'enveloppai',
    'enveloppas',
    'enveloppât',
    'enveloppée',
    'envelopper',
    'enveloppes',
    'enveloppés',
    'enveloppez',
    'envenimais',
    'envenimait',
    'envenimant',
    'envenimées',
    'enveniment',
    'envenimera',
    'envenimiez',
    'envenimons',
    'envergeais',
    'envergeait',
    'envergeant',
    'envergeons',
    'envergerai',
    'envergeras',
    'envergerez',
    'envergions',
    'enverguais',
    'enverguait',
    'enverguant',
    'enverguées',
    'enverguent',
    'enverguera',
    'enverguiez',
    'enverguons',
    'envergures',
    'enverrions',
    'enviassent',
    'enviassiez',
    'envidaient',
    'envidasses',
    'enviderais',
    'enviderait',
    'envidèrent',
    'envideriez',
    'enviderons',
    'envideront',
    'envieillie',
    'envieillir',
    'envieillis',
    'envieillit',
    'envieillît',
    'envierions',
    'environnai',
    'environnas',
    'environnât',
    'environnée',
    'environner',
    'environnes',
    'environnés',
    'environnez',
    'envisageai',
    'envisageas',
    'envisageât',
    'envisagées',
    'envisagent',
    'envisagera',
    'envisagiez',
    'envoilâmes',
    'envoilâtes',
    'envoilerez',
    'envoilions',
    'envoleriez',
    'envolerons',
    'envoûtâmes',
    'envoûtante',
    'envoûtants',
    'envoûtasse',
    'envoûtâtes',
    'envoûterai',
    'envoûteras',
    'envoûterez',
    'envoûteurs',
    'envoûteuse',
    'envoûtions',
    'envoyaient',
    'envoyasses',
    'envoyèrent',
    'envoyeuses',
    'épaisseurs',
    'épaissîmes',
    'épaissirai',
    'épaissiras',
    'épaissirez',
    'épaississe',
    'épaissîtes',
    'épalassent',
    'épalassiez',
    'épalerions',
    'épamprages',
    'épamprâmes',
    'épamprasse',
    'épamprâtes',
    'épamprerai',
    'épampreras',
    'épamprerez',
    'épamprions',
    'épanchâmes',
    'épanchasse',
    'épanchâtes',
    'épancherai',
    'épancheras',
    'épancherez',
    'épanchions',
    'épandaient',
    'épandeuses',
    'épandirent',
    'épandisses',
    'épandrions',
    'épannaient',
    'épannasses',
    'épannelais',
    'épannelait',
    'épannelant',
    'épannelées',
    'épanneliez',
    'épannelles',
    'épannelons',
    'épannerais',
    'épannerait',
    'épannèrent',
    'épanneriez',
    'épannerons',
    'épanneront',
    'épanouîmes',
    'épanouirai',
    'épanouiras',
    'épanouirez',
    'épanouisse',
    'épanouîtes',
    'épargnâmes',
    'épargnante',
    'épargnants',
    'épargnasse',
    'épargnâtes',
    'épargnerai',
    'épargneras',
    'épargnerez',
    'épargnions',
    'éparpillai',
    'éparpillas',
    'éparpillât',
    'éparpillée',
    'éparpiller',
    'éparpilles',
    'éparpillés',
    'éparpillez',
    'épatamment',
    'épatassent',
    'épatassiez',
    'épatements',
    'épaterions',
    'épaufrâmes',
    'épaufrasse',
    'épaufrâtes',
    'épaufrerai',
    'épaufreras',
    'épaufrerez',
    'épaufrions',
    'épaufrures',
    'épaulaient',
    'épaulasses',
    'épaulement',
    'épaulerais',
    'épaulerait',
    'épaulèrent',
    'épauleriez',
    'épaulerons',
    'épauleront',
    'épaulettes',
    'épaulières',
    'épeichette',
    'épelassent',
    'épelassiez',
    'épellation',
    'épellerais',
    'épellerait',
    'épelleriez',
    'épellerons',
    'épelleront',
    'épenthèses',
    'épépinâmes',
    'épépinasse',
    'épépinâtes',
    'épépinerai',
    'épépineras',
    'épépinerez',
    'épépinions',
    'éperdirent',
    'éperdrions',
    'éperdument',
    'éperonnais',
    'éperonnait',
    'éperonnant',
    'éperonnées',
    'éperonnent',
    'éperonnera',
    'éperonniez',
    'éperonnons',
    'épervières',
    'épeulaient',
    'épeulasses',
    'épeulerais',
    'épeulerait',
    'épeulèrent',
    'épeuleriez',
    'épeulerons',
    'épeuleront',
    'épeuraient',
    'épeurasses',
    'épeurerais',
    'épeurerait',
    'épeurèrent',
    'épeureriez',
    'épeurerons',
    'épeureront',
    'éphéméride',
    'épiassions',
    'épiçassent',
    'épiçassiez',
    'épicentres',
    'épicerions',
    'épicondyle',
    'épicrânien',
    'épicuriens',
    'épicurisme',
    'épidémique',
    'épididymes',
    'épieraient',
    'épierrages',
    'épierrâmes',
    'épierrasse',
    'épierrâtes',
    'épierrerai',
    'épierreras',
    'épierrerez',
    'épierrions',
    'épigastres',
    'épiglottes',
    'épigrammes',
    'épigraphes',
    'épigraphie',
    'épilassent',
    'épilassiez',
    'épilations',
    'épilatoire',
    'épilepsies',
    'épilerions',
    'épiloguais',
    'épiloguait',
    'épiloguant',
    'épiloguées',
    'épiloguent',
    'épiloguera',
    'épiloguiez',
    'épiloguons',
    'épinassent',
    'épinassiez',
    'épinçaient',
    'épinçasses',
    'épincelais',
    'épincelait',
    'épincelant',
    'épincelées',
    'épincèlent',
    'épincèlera',
    'épinceliez',
    'épincelons',
    'épincerais',
    'épincerait',
    'épincèrent',
    'épinceriez',
    'épincerons',
    'épinceront',
    'épincetais',
    'épincetait',
    'épincetant',
    'épincetées',
    'épincètent',
    'épincetera',
    'épincetiez',
    'épincetons',
    'épinerions',
    'épinglages',
    'épinglâmes',
    'épinglasse',
    'épinglâtes',
    'épinglerai',
    'épingleras',
    'épinglerez',
    'épinglette',
    'épinglière',
    'épingliers',
    'épinglions',
    'épiphanies',
    'épiphyties',
    'épiscopale',
    'épiscopats',
    'épiscopaux',
    'épisodique',
    'épissaient',
    'épissasses',
    'épisserais',
    'épisserait',
    'épissèrent',
    'épisseriez',
    'épisserons',
    'épisseront',
    'épistasies',
    'épistolier',
    'épithalame',
    'épithélial',
    'épithélium',
    'épizooties',
    'éploierais',
    'éploierait',
    'éploieriez',
    'éploierons',
    'éploieront',
    'éployaient',
    'éployasses',
    'éployèrent',
    'épluchages',
    'épluchâmes',
    'épluchasse',
    'épluchâtes',
    'éplucherai',
    'éplucheras',
    'éplucherez',
    'éplucheurs',
    'éplucheuse',
    'épluchions',
    'épluchures',
    'épointages',
    'épointâmes',
    'épointasse',
    'épointâtes',
    'épointerai',
    'épointeras',
    'épointerez',
    'épointions',
    'épongeages',
    'épongeâmes',
    'épongeasse',
    'épongeâtes',
    'épongement',
    'épongerais',
    'épongerait',
    'épongèrent',
    'épongeriez',
    'épongerons',
    'épongeront',
    'épontillai',
    'épontillas',
    'épontillât',
    'épontillée',
    'épontiller',
    'épontilles',
    'épontillés',
    'épontillez',
    'épouillage',
    'épouillais',
    'épouillait',
    'épouillant',
    'épouillées',
    'épouillent',
    'épouillera',
    'épouilliez',
    'épouillons',
    'époumonais',
    'époumonait',
    'époumonant',
    'époumonées',
    'époumonent',
    'époumonera',
    'époumoniez',
    'époumonons',
    'épousaient',
    'épousasses',
    'épouserais',
    'épouserait',
    'épousèrent',
    'épouseriez',
    'épouserons',
    'épouseront',
    'époussetai',
    'époussetas',
    'époussetât',
    'époussetée',
    'épousseter',
    'époussetés',
    'époussetez',
    'époussette',
    'époutiâmes',
    'époutiasse',
    'époutiâtes',
    'époutierai',
    'époutieras',
    'époutierez',
    'époutiions',
    'époutirais',
    'époutirait',
    'époutirent',
    'époutiriez',
    'époutirons',
    'époutiront',
    'époutisses',
    'époutissez',
    'épouvantai',
    'épouvantas',
    'épouvantât',
    'épouvantée',
    'épouvanter',
    'épouvantes',
    'épouvantés',
    'épouvantez',
    'épreignais',
    'épreignait',
    'épreignant',
    'épreignent',
    'épreigniez',
    'épreignons',
    'épreindrai',
    'épreindras',
    'épreindrez',
    'éprendriez',
    'éprendrons',
    'éprissions',
    'éprouvâmes',
    'éprouvante',
    'éprouvants',
    'éprouvasse',
    'éprouvâtes',
    'éprouverai',
    'éprouveras',
    'éprouverez',
    'éprouvette',
    'éprouvions',
    'épuçassent',
    'épuçassiez',
    'épucerions',
    'épuisables',
    'épuisaient',
    'épuisantes',
    'épuisasses',
    'épuisement',
    'épuiserais',
    'épuiserait',
    'épuisèrent',
    'épuiseriez',
    'épuiserons',
    'épuiseront',
    'épuisettes',
    'épurassent',
    'épurassiez',
    'épurateurs',
    'épurations',
    'épuratives',
    'épuratoire',
    'épurements',
    'épurerions',
    'équanimité',
    'équarrîmes',
    'équarrirai',
    'équarriras',
    'équarrirez',
    'équarrisse',
    'équarrîtes',
    'équatorial',
    'équerrages',
    'équerrâmes',
    'équerrasse',
    'équerrâtes',
    'équerrerai',
    'équerreras',
    'équerrerez',
    'équerrions',
    'équeutages',
    'équeutâmes',
    'équeutasse',
    'équeutâtes',
    'équeuterai',
    'équeuteras',
    'équeuterez',
    'équeutions',
    'équiangles',
    'équilatère',
    'équilibrai',
    'équilibras',
    'équilibrât',
    'équilibrée',
    'équilibrer',
    'équilibres',
    'équilibrés',
    'équilibrez',
    'équinismes',
    'équinoxial',
    'équipaient',
    'équipasses',
    'équipement',
    'équiperais',
    'équiperait',
    'équipèrent',
    'équiperiez',
    'équiperons',
    'équiperont',
    'équipières',
    'équipollés',
    'équipotent',
    'équitables',
    'équitantes',
    'équitation',
    'équivaille',
    'équivalais',
    'équivalait',
    'équivalant',
    'équivalent',
    'équivaliez',
    'équivaloir',
    'équivalons',
    'équivaudra',
    'équivoquai',
    'équivoquas',
    'équivoquât',
    'équivoquer',
    'équivoques',
    'équivoquez',
    'érablières',
    'éradiquais',
    'éradiquait',
    'éradiquant',
    'éradiquées',
    'éradiquent',
    'éradiquera',
    'éradiquiez',
    'éradiquons',
    'éraflaient',
    'éraflasses',
    'éraflement',
    'éraflerais',
    'éraflerait',
    'éraflèrent',
    'érafleriez',
    'éraflerons',
    'érafleront',
    'éraierions',
    'éraillâmes',
    'éraillasse',
    'éraillâtes',
    'éraillerai',
    'érailleras',
    'éraillerez',
    'éraillions',
    'éraillures',
    'érayassent',
    'érayassiez',
    'érayerions',
    'érectilité',
    'érectrices',
    'éreintages',
    'éreintâmes',
    'éreintante',
    'éreintants',
    'éreintasse',
    'éreintâtes',
    'éreinterai',
    'éreinteras',
    'éreinterez',
    'éreinteurs',
    'éreinteuse',
    'éreintions',
    'érémitique',
    'érésipèles',
    'éréthismes',
    'ergastules',
    'ergographe',
    'ergonomies',
    'ergostérol',
    'ergotaient',
    'ergotasses',
    'ergoterais',
    'ergoterait',
    'ergotèrent',
    'ergoteriez',
    'ergoterons',
    'ergoteront',
    'ergoteuses',
    'ergotismes',
    'érigeaient',
    'érigeasses',
    'érigerions',
    'éristiques',
    'érodassent',
    'érodassiez',
    'éroderions',
    'érotisâmes',
    'érotisasse',
    'érotisâtes',
    'érotiserai',
    'érotiseras',
    'érotiserez',
    'érotisions',
    'érotomanes',
    'érotomanie',
    'errassions',
    'erratiques',
    'erreraient',
    'erronément',
    'érubescent',
    'éruciforme',
    'éructaient',
    'éructasses',
    'éructation',
    'éructerais',
    'éructerait',
    'éructèrent',
    'éructeriez',
    'éructerons',
    'éructeront',
    'éruditions',
    'érugineuse',
    'érysipèles',
    'érythrines',
    'esbaudîmes',
    'esbaudirez',
    'esbaudîtes',
    'esbignâmes',
    'esbignâtes',
    'esbignerez',
    'esbignions',
    'esbroufais',
    'esbroufait',
    'esbroufant',
    'esbroufées',
    'esbroufent',
    'esbroufera',
    'esbroufeur',
    'esbroufiez',
    'esbroufons',
    'escabelles',
    'escadrille',
    'escaladais',
    'escaladait',
    'escaladant',
    'escaladées',
    'escaladent',
    'escaladera',
    'escaladiez',
    'escaladons',
    'Escalators',
    'escamotage',
    'escamotais',
    'escamotait',
    'escamotant',
    'escamotées',
    'escamotent',
    'escamotera',
    'escamoteur',
    'escamotiez',
    'escamotons',
    'escampette',
    'escarbille',
    'escarcelle',
    'escarrifia',
    'escarrifie',
    'escarrifié',
    'eschassent',
    'eschassiez',
    'escherions',
    'esclaffées',
    'esclaffiez',
    'esclaffons',
    'esclandres',
    'esclavagea',
    'esclavagée',
    'esclavager',
    'esclavages',
    'esclavagez',
    'esclavonne',
    'escogriffe',
    'escomptais',
    'escomptait',
    'escomptant',
    'escomptées',
    'escomptent',
    'escomptera',
    'escompteur',
    'escomptiez',
    'escomptons',
    'escopettes',
    'escortâmes',
    'escortasse',
    'escortâtes',
    'escorterai',
    'escorteras',
    'escorterez',
    'escorteurs',
    'escortions',
    'escourgeon',
    'escrimâmes',
    'escrimâtes',
    'escrimerez',
    'escrimeurs',
    'escrimeuse',
    'escrimions',
    'escroquais',
    'escroquait',
    'escroquant',
    'escroquées',
    'escroquent',
    'escroquera',
    'escroquiez',
    'escroquons',
    'ésotérique',
    'ésotérisme',
    'espaçaient',
    'espaçasses',
    'espacement',
    'espacerais',
    'espacerait',
    'espacèrent',
    'espaceriez',
    'espacerons',
    'espaceront',
    'espadrille',
    'espagnoles',
    'esparcette',
    'espéraient',
    'espérances',
    'espérasses',
    'espérerais',
    'espérerait',
    'espérèrent',
    'espéreriez',
    'espérerons',
    'espéreront',
    'espingoles',
    'espionites',
    'espionnage',
    'espionnais',
    'espionnait',
    'espionnant',
    'espionnées',
    'espionnent',
    'espionnera',
    'espionniez',
    'espionnite',
    'espionnons',
    'esplanades',
    'espolinais',
    'espolinait',
    'espolinant',
    'espolinées',
    'espolinent',
    'espolinera',
    'espoliniez',
    'espolinons',
    'espoulinai',
    'espoulinas',
    'espoulinât',
    'espoulinée',
    'espouliner',
    'espoulines',
    'espoulinés',
    'espoulinez',
    'espressivo',
    'esquimaude',
    'Esquimaude',
    'esquimauds',
    'esquintais',
    'esquintait',
    'esquintant',
    'esquintées',
    'esquintent',
    'esquintera',
    'esquintiez',
    'esquintons',
    'esquissais',
    'esquissait',
    'esquissant',
    'esquissées',
    'esquissent',
    'esquissera',
    'esquissiez',
    'esquissons',
    'esquivâmes',
    'esquivasse',
    'esquivâtes',
    'esquiverai',
    'esquiveras',
    'esquiverez',
    'esquivions',
    'essaierais',
    'essaierait',
    'essaieriez',
    'essaierons',
    'essaieront',
    'essaimages',
    'essaimâmes',
    'essaimasse',
    'essaimâtes',
    'essaimerai',
    'essaimeras',
    'essaimerez',
    'essaimions',
    'essangeage',
    'essangeais',
    'essangeait',
    'essangeant',
    'essangeons',
    'essangerai',
    'essangeras',
    'essangerez',
    'essangions',
    'essanvages',
    'essartages',
    'essartâmes',
    'essartasse',
    'essartâtes',
    'essarterai',
    'essarteras',
    'essarterez',
    'essartions',
    'essayaient',
    'essayasses',
    'essayerais',
    'essayerait',
    'essayèrent',
    'essayeriez',
    'essayerons',
    'essayeront',
    'essayeuses',
    'essayistes',
    'essentiels',
    'essoraient',
    'essorasses',
    'essorerais',
    'essorerait',
    'essorèrent',
    'essoreriez',
    'essorerons',
    'essoreront',
    'essoreuses',
    'essorillai',
    'essorillas',
    'essorillât',
    'essorillée',
    'essoriller',
    'essorilles',
    'essorillés',
    'essorillez',
    'essouchais',
    'essouchait',
    'essouchant',
    'essouchées',
    'essouchent',
    'essouchera',
    'essouchiez',
    'essouchons',
    'essoufflai',
    'essoufflas',
    'essoufflât',
    'essoufflée',
    'essouffler',
    'essouffles',
    'essoufflés',
    'essoufflez',
    'essuierais',
    'essuierait',
    'essuieriez',
    'essuierons',
    'essuieront',
    'essuyaient',
    'essuyasses',
    'essuyèrent',
    'essuyeuses',
    'estafettes',
    'estafilade',
    'estaminets',
    'estampages',
    'estampâmes',
    'estampasse',
    'estampâtes',
    'estamperai',
    'estamperas',
    'estamperez',
    'estampeurs',
    'estampilla',
    'estampille',
    'estampillé',
    'estampions',
    'estérifiai',
    'estérifias',
    'estérifiât',
    'estérifiée',
    'estérifier',
    'estérifies',
    'estérifiés',
    'estérifiez',
    'esthétique',
    'esthétisai',
    'esthétisas',
    'esthétisât',
    'esthétisée',
    'esthétiser',
    'esthétises',
    'esthétisés',
    'esthétisez',
    'esthétisme',
    'estimables',
    'estimaient',
    'estimasses',
    'estimatifs',
    'estimation',
    'estimative',
    'estimerais',
    'estimerait',
    'estimèrent',
    'estimeriez',
    'estimerons',
    'estimeront',
    'estivaient',
    'estivantes',
    'estivasses',
    'estivation',
    'estiverais',
    'estiverait',
    'estivèrent',
    'estiveriez',
    'estiverons',
    'estiveront',
    'estomaquai',
    'estomaquas',
    'estomaquât',
    'estomaquée',
    'estomaquer',
    'estomaques',
    'estomaqués',
    'estomaquez',
    'estompages',
    'estompâmes',
    'estompasse',
    'estompâtes',
    'estomperai',
    'estomperas',
    'estomperez',
    'estompions',
    'estonienne',
    'estoquâmes',
    'estoquasse',
    'estoquâtes',
    'estoquerai',
    'estoqueras',
    'estoquerez',
    'estoquions',
    'estouffade',
    'estourbies',
    'estourbira',
    'estradiots',
    'estrapadai',
    'estrapadas',
    'estrapadât',
    'estrapader',
    'estrapades',
    'estrapadés',
    'estrapadez',
    'estrapassa',
    'estrapasse',
    'estrapassé',
    'estropiais',
    'estropiait',
    'estropiant',
    'estropiées',
    'estropient',
    'estropiera',
    'estropiiez',
    'estropions',
    'estuariens',
    'esturgeons',
    'établaient',
    'établasses',
    'établerais',
    'établerait',
    'établèrent',
    'étableriez',
    'établerons',
    'étableront',
    'établirais',
    'établirait',
    'établirent',
    'établiriez',
    'établirons',
    'établiront',
    'établisses',
    'établissez',
    'étageaient',
    'étageasses',
    'étagements',
    'étagerions',
    'étaiements',
    'étaient-ce',
    'étaierions',
    'était-elle',
    'étalageais',
    'étalageait',
    'étalageant',
    'étalageons',
    'étalagerai',
    'étalageras',
    'étalagerez',
    'étalagions',
    'étalagiste',
    'étalassent',
    'étalassiez',
    'étalements',
    'étalerions',
    'étalinguai',
    'étalinguas',
    'étalinguât',
    'étalinguée',
    'étalinguer',
    'étalingues',
    'étalingués',
    'étalinguez',
    'étalingure',
    'étalisâmes',
    'étalisasse',
    'étalisâtes',
    'étaliserai',
    'étaliseras',
    'étaliserez',
    'étalisions',
    'étalonnage',
    'étalonnais',
    'étalonnait',
    'étalonnant',
    'étalonnées',
    'étalonnent',
    'étalonnera',
    'étalonniez',
    'étalonnons',
    'étamassent',
    'étamassiez',
    'étamerions',
    'étampaient',
    'étampasses',
    'étamperais',
    'étamperait',
    'étamperche',
    'étampèrent',
    'étamperiez',
    'étamperons',
    'étamperont',
    'étanchâmes',
    'étanchasse',
    'étanchâtes',
    'étanchéité',
    'étancherai',
    'étancheras',
    'étancherez',
    'étanchions',
    'étançonnai',
    'étançonnas',
    'étançonnât',
    'étançonnée',
    'étançonner',
    'étançonnes',
    'étançonnés',
    'étançonnez',
    'étarquâmes',
    'étarquasse',
    'étarquâtes',
    'étarquerai',
    'étarqueras',
    'étarquerez',
    'étarquions',
    'étasuniens',
    'état-major',
    'étayassent',
    'étayassiez',
    'étayements',
    'étayerions',
    'éteignîmes',
    'éteignions',
    'éteignisse',
    'éteignîtes',
    'éteignoirs',
    'éteindrais',
    'éteindrait',
    'éteindriez',
    'éteindrons',
    'éteindront',
    'étendaient',
    'étendirent',
    'étendisses',
    'étendrions',
    'éternelles',
    'éternisais',
    'éternisait',
    'éternisant',
    'éternisées',
    'éternisent',
    'éternisera',
    'éternisiez',
    'éternisons',
    'éternuâmes',
    'éternuasse',
    'éternuâtes',
    'éternuerai',
    'éternueras',
    'éternuerez',
    'éternuions',
    'étêtassent',
    'étêtassiez',
    'étêtements',
    'étêterions',
    'éthérifiai',
    'éthérifias',
    'éthérifiât',
    'éthérifiée',
    'éthérifier',
    'éthérifies',
    'éthérifiés',
    'éthérifiez',
    'éthérisais',
    'éthérisait',
    'éthérisant',
    'éthérisées',
    'éthérisent',
    'éthérisera',
    'éthérisiez',
    'éthérismes',
    'éthérisons',
    'éthéromane',
    'éthiopiens',
    'ethnocides',
    'ethnologie',
    'ethnologue',
    'éthyliques',
    'éthylismes',
    'étiez-vous',
    'étincelage',
    'étincelais',
    'étincelait',
    'étincelant',
    'étinceliez',
    'étincelles',
    'étincelons',
    'étiolaient',
    'étiolasses',
    'étiolement',
    'étiolerais',
    'étiolerait',
    'étiolèrent',
    'étioleriez',
    'étiolerons',
    'étioleront',
    'étiologies',
    'étiquetage',
    'étiquetais',
    'étiquetait',
    'étiquetant',
    'étiquetées',
    'étiqueteur',
    'étiquetiez',
    'étiquetons',
    'étiquettes',
    'étirassent',
    'étirassiez',
    'étirements',
    'étirerions',
    'étoffaient',
    'étoffasses',
    'étofferais',
    'étofferait',
    'étoffèrent',
    'étofferiez',
    'étofferons',
    'étofferont',
    'étoilaient',
    'étoilasses',
    'étoilement',
    'étoilerais',
    'étoilerait',
    'étoilèrent',
    'étoileriez',
    'étoilerons',
    'étoileront',
    'étonnaient',
    'étonnantes',
    'étonnasses',
    'étonnement',
    'étonnerais',
    'étonnerait',
    'étonnèrent',
    'étonneriez',
    'étonnerons',
    'étonneront',
    'étouffades',
    'étouffages',
    'étouffâmes',
    'étouffante',
    'étouffants',
    'étouffasse',
    'étouffâtes',
    'étoufferai',
    'étoufferas',
    'étoufferez',
    'étouffeurs',
    'étouffions',
    'étouffoirs',
    'étoupaient',
    'étoupasses',
    'étouperais',
    'étouperait',
    'étoupèrent',
    'étouperiez',
    'étouperons',
    'étouperont',
    'étoupillai',
    'étoupillas',
    'étoupillât',
    'étoupillée',
    'étoupiller',
    'étoupilles',
    'étoupillés',
    'étoupillez',
    'étourderie',
    'étourdîmes',
    'étourdirai',
    'étourdiras',
    'étourdirez',
    'étourdisse',
    'étourdîtes',
    'étourneaux',
    'étrangères',
    'étrangetés',
    'étranglais',
    'étranglait',
    'étranglant',
    'étranglées',
    'étranglent',
    'étranglera',
    'étrangleur',
    'étrangliez',
    'étrangloir',
    'étranglons',
    'étrécirais',
    'étrécirait',
    'étrécirent',
    'étréciriez',
    'étrécirons',
    'étréciront',
    'étrécisses',
    'étrécissez',
    'étreignais',
    'étreignait',
    'étreignant',
    'étreignent',
    'étreigniez',
    'étreignons',
    'étreindrai',
    'étreindras',
    'étreindrez',
    'étrennâmes',
    'étrennasse',
    'étrennâtes',
    'étrennerai',
    'étrenneras',
    'étrennerez',
    'étrennions',
    'étrésillon',
    'étrillâmes',
    'étrillasse',
    'étrillâtes',
    'étrillerai',
    'étrilleras',
    'étrillerez',
    'étrillions',
    'étripaient',
    'étripasses',
    'étriperais',
    'étriperait',
    'étripèrent',
    'étriperiez',
    'étriperons',
    'étriperont',
    'étriquâmes',
    'étriquasse',
    'étriquâtes',
    'étriquerai',
    'étriqueras',
    'étriquerez',
    'étriquions',
    'étrivières',
    'étroitesse',
    'étronçonna',
    'étronçonne',
    'étronçonné',
    'étudiaient',
    'étudiantes',
    'étudiasses',
    'étudierais',
    'étudierait',
    'étudièrent',
    'étudieriez',
    'étudierons',
    'étudieront',
    'étuvassent',
    'étuvassiez',
    'étuverions',
    'étymologie',
    'eucalyptol',
    'eucalyptus',
    'euclidiens',
    'eudiomètre',
    'eugéniques',
    'eugénismes',
    'eugénistes',
    'eûmes-nous',
    'eupatoires',
    'eupeptique',
    'euphémique',
    'euphémisme',
    'euphonique',
    'euphorique',
    'euphorisai',
    'euphorisas',
    'euphorisât',
    'euphorisée',
    'euphoriser',
    'euphorises',
    'euphorisés',
    'euphorisez',
    'euphuismes',
    'eurasienne',
    'eurent-ils',
    'euristique',
    'eurocrates',
    'eurodevise',
    'eurodollar',
    'euromarché',
    'européenne',
    'européisme',
    'eurovision',
    'eurythmies',
    'euscariens',
    'euskariens',
    'eustatique',
    'eustatisme',
    'eutectique',
    'eûtes-vous',
    'euthanasie',
    'évacuaient',
    'évacuantes',
    'évacuasses',
    'évacuateur',
    'évacuation',
    'évacuerais',
    'évacuerait',
    'évacuèrent',
    'évacueriez',
    'évacuerons',
    'évacueront',
    'évadassiez',
    'évaderions',
    'évaluables',
    'évaluaient',
    'évaluasses',
    'évaluation',
    'évaluerais',
    'évaluerait',
    'évaluèrent',
    'évalueriez',
    'évaluerons',
    'évalueront',
    'évanescent',
    'évangélisa',
    'évangélise',
    'évangélisé',
    'évanouîmes',
    'évanouirez',
    'évanouîtes',
    'évaporable',
    'évaporâmes',
    'évaporasse',
    'évaporâtes',
    'évaporerai',
    'évaporeras',
    'évaporerez',
    'évaporions',
    'évasassent',
    'évasassiez',
    'évasements',
    'évaserions',
    'éveillâmes',
    'éveillasse',
    'éveillâtes',
    'éveillerai',
    'éveilleras',
    'éveillerez',
    'éveilleurs',
    'éveillions',
    'événements',
    'évènements',
    'éventaient',
    'éventaires',
    'éventasses',
    'éventerais',
    'éventerait',
    'éventèrent',
    'éventeriez',
    'éventerons',
    'éventeront',
    'éventrâmes',
    'éventrasse',
    'éventrâtes',
    'éventrerai',
    'éventreras',
    'éventrerez',
    'éventreurs',
    'éventrions',
    'éventuelle',
    'évertuâmes',
    'évertuâtes',
    'évertuerez',
    'évertuions',
    'évidassent',
    'évidassiez',
    'évidements',
    'évidemment',
    'éviderions',
    'évinçaient',
    'évinçasses',
    'évincement',
    'évincerais',
    'évincerait',
    'évincèrent',
    'évinceriez',
    'évincerons',
    'évinceront',
    'éviscérais',
    'éviscérait',
    'éviscérant',
    'éviscérées',
    'éviscèrent',
    'éviscérera',
    'éviscériez',
    'éviscérons',
    'évitassent',
    'évitassiez',
    'évitements',
    'éviterions',
    'évocateurs',
    'évocations',
    'évocatoire',
    'évocatrice',
    'évoluaient',
    'évoluasses',
    'évoluerais',
    'évoluerait',
    'évoluèrent',
    'évolueriez',
    'évoluerons',
    'évolueront',
    'évolutions',
    'évolutives',
    'évoquaient',
    'évoquasses',
    'évoquerais',
    'évoquerait',
    'évoquèrent',
    'évoqueriez',
    'évoquerons',
    'évoqueront',
    'exacerbais',
    'exacerbait',
    'exacerbant',
    'exacerbées',
    'exacerbent',
    'exacerbera',
    'exacerbiez',
    'exacerbons',
    'exactement',
    'exactitude',
    'exagérâmes',
    'exagérasse',
    'exagérâtes',
    'exagérerai',
    'exagéreras',
    'exagérerez',
    'exagérions',
    'exaltaient',
    'exaltantes',
    'exaltasses',
    'exaltation',
    'exalterais',
    'exalterait',
    'exaltèrent',
    'exalteriez',
    'exalterons',
    'exalteront',
    'examinâmes',
    'examinasse',
    'examinâtes',
    'examinerai',
    'examineras',
    'examinerez',
    'examinions',
    'exanthèmes',
    'exaspérais',
    'exaspérait',
    'exaspérant',
    'exaspérées',
    'exaspèrent',
    'exaspérera',
    'exaspériez',
    'exaspérons',
    'exauçaient',
    'exauçasses',
    'exaucement',
    'exaucerais',
    'exaucerait',
    'exaucèrent',
    'exauceriez',
    'exaucerons',
    'exauceront',
    'excavaient',
    'excavasses',
    'excavateur',
    'excavation',
    'excaverais',
    'excaverait',
    'excavèrent',
    'excaveriez',
    'excaverons',
    'excaveront',
    'excédaient',
    'excédantes',
    'excédasses',
    'excéderais',
    'excéderait',
    'excédèrent',
    'excéderiez',
    'excéderons',
    'excéderont',
    'excellâmes',
    'excellasse',
    'excellâtes',
    'excellence',
    'excellente',
    'excellents',
    'excellerai',
    'excelleras',
    'excellerez',
    'excellions',
    'excentrais',
    'excentrait',
    'excentrant',
    'excentrées',
    'excentrent',
    'excentrera',
    'excentriez',
    'excentrons',
    'exceptâmes',
    'exceptasse',
    'exceptâtes',
    'excepterai',
    'excepteras',
    'excepterez',
    'exceptions',
    'excessives',
    'excipaient',
    'excipasses',
    'exciperais',
    'exciperait',
    'excipèrent',
    'exciperiez',
    'exciperons',
    'exciperont',
    'excipients',
    'excisaient',
    'excisasses',
    'exciserais',
    'exciserait',
    'excisèrent',
    'exciseriez',
    'exciserons',
    'exciseront',
    'excitables',
    'excitaient',
    'excitantes',
    'excitasses',
    'excitateur',
    'excitation',
    'exciterais',
    'exciterait',
    'excitèrent',
    'exciteriez',
    'exciterons',
    'exciteront',
    'exclamâmes',
    'exclamâtes',
    'exclamatif',
    'exclamerez',
    'exclamions',
    'excluaient',
    'exclurions',
    'exclusions',
    'exclusives',
    'exclussent',
    'exclussiez',
    'excommunia',
    'excommunie',
    'excommunié',
    'excoriâmes',
    'excoriasse',
    'excoriâtes',
    'excorierai',
    'excorieras',
    'excorierez',
    'excoriions',
    'excréments',
    'excrétâmes',
    'excrétasse',
    'excrétâtes',
    'excréterai',
    'excréteras',
    'excréterez',
    'excréteurs',
    'excrétions',
    'excrétoire',
    'excrétrice',
    'excursions',
    'excusables',
    'excusaient',
    'excusasses',
    'excuserais',
    'excuserait',
    'excusèrent',
    'excuseriez',
    'excuserons',
    'excuseront',
    'exécrables',
    'exécraient',
    'exécrasses',
    'exécration',
    'exécrerais',
    'exécrerait',
    'exécrèrent',
    'exécreriez',
    'exécrerons',
    'exécreront',
    'exécutable',
    'exécutâmes',
    'exécutante',
    'exécutants',
    'exécutasse',
    'exécutâtes',
    'exécuterai',
    'exécuteras',
    'exécuterez',
    'exécuteurs',
    'exécutions',
    'exécutives',
    'exécutoire',
    'exécutrice',
    'exégétique',
    'exemplaire',
    'exemplifia',
    'exemplifie',
    'exemplifié',
    'exemptâmes',
    'exemptasse',
    'exemptâtes',
    'exempterai',
    'exempteras',
    'exempterez',
    'exemptions',
    'exerçaient',
    'exerçantes',
    'exerçasses',
    'exercerais',
    'exercerait',
    'exercèrent',
    'exerceriez',
    'exercerons',
    'exerceront',
    'exerciseur',
    'exfoliâmes',
    'exfoliante',
    'exfoliants',
    'exfoliasse',
    'exfoliâtes',
    'exfolierai',
    'exfolieras',
    'exfolierez',
    'exfoliions',
    'exhalaient',
    'exhalaison',
    'exhalasses',
    'exhalation',
    'exhalerais',
    'exhalerait',
    'exhalèrent',
    'exhaleriez',
    'exhalerons',
    'exhaleront',
    'exhaussais',
    'exhaussait',
    'exhaussant',
    'exhaussées',
    'exhaussent',
    'exhaussera',
    'exhaussiez',
    'exhaussons',
    'exhausteur',
    'exhaustifs',
    'exhaustive',
    'exhérédais',
    'exhérédait',
    'exhérédant',
    'exhérédées',
    'exhérèdent',
    'exhérédera',
    'exhérédiez',
    'exhérédons',
    'exhibaient',
    'exhibasses',
    'exhiberais',
    'exhiberait',
    'exhibèrent',
    'exhiberiez',
    'exhiberons',
    'exhiberont',
    'exhibition',
    'exhortâmes',
    'exhortasse',
    'exhortâtes',
    'exhorterai',
    'exhorteras',
    'exhorterez',
    'exhortions',
    'exhumaient',
    'exhumasses',
    'exhumation',
    'exhumerais',
    'exhumerait',
    'exhumèrent',
    'exhumeriez',
    'exhumerons',
    'exhumeront',
    'exigeaient',
    'exigeantes',
    'exigeasses',
    'exigerions',
    'exilassent',
    'exilassiez',
    'exilerions',
    'existaient',
    'existantes',
    'existasses',
    'existences',
    'existerais',
    'existerait',
    'existèrent',
    'existeriez',
    'existerons',
    'existeront',
    'exonderiez',
    'exonderons',
    'exonérâmes',
    'exonérasse',
    'exonérâtes',
    'exonérerai',
    'exonéreras',
    'exonérerez',
    'exonérions',
    'exorbitant',
    'exorbitées',
    'exorcisais',
    'exorcisait',
    'exorcisant',
    'exorcisées',
    'exorcisent',
    'exorcisera',
    'exorcisiez',
    'exorcismes',
    'exorcisons',
    'exorcistes',
    'exoréiques',
    'exoréismes',
    'exosphères',
    'exotérique',
    'exotoxines',
    'expansible',
    'expansions',
    'expansives',
    'expatriais',
    'expatriait',
    'expatriant',
    'expatriées',
    'expatrient',
    'expatriera',
    'expatriiez',
    'expatrions',
    'expectorai',
    'expectoras',
    'expectorât',
    'expectorée',
    'expectorer',
    'expectores',
    'expectorés',
    'expectorez',
    'expédiâmes',
    'expédiasse',
    'expédiâtes',
    'expédierai',
    'expédieras',
    'expédierez',
    'expédiions',
    'expéditeur',
    'expéditifs',
    'expédition',
    'expéditive',
    'expérience',
    'expertisai',
    'expertisas',
    'expertisât',
    'expertisée',
    'expertiser',
    'expertises',
    'expertisés',
    'expertisez',
    'expiassent',
    'expiassiez',
    'expiations',
    'expiatoire',
    'expierions',
    'expiraient',
    'expirantes',
    'expirasses',
    'expiration',
    'expirerais',
    'expirerait',
    'expirèrent',
    'expireriez',
    'expirerons',
    'expireront',
    'explétives',
    'explicable',
    'explicatif',
    'explicitai',
    'explicitas',
    'explicitât',
    'explicitée',
    'expliciter',
    'explicites',
    'explicités',
    'explicitez',
    'expliquais',
    'expliquait',
    'expliquant',
    'expliquées',
    'expliquent',
    'expliquera',
    'expliquiez',
    'expliquons',
    'exploitais',
    'exploitait',
    'exploitant',
    'exploitées',
    'exploitent',
    'exploitera',
    'exploiteur',
    'exploitiez',
    'exploitons',
    'explorâmes',
    'explorasse',
    'explorâtes',
    'explorerai',
    'exploreras',
    'explorerez',
    'explorions',
    'explosâmes',
    'explosasse',
    'explosâtes',
    'exploserai',
    'exploseras',
    'exploserez',
    'exploseurs',
    'explosible',
    'explosions',
    'explosives',
    'exportable',
    'exportâmes',
    'exportasse',
    'exportâtes',
    'exporterai',
    'exporteras',
    'exporterez',
    'exportions',
    'exposaient',
    'exposantes',
    'exposasses',
    'exposerais',
    'exposerait',
    'exposèrent',
    'exposeriez',
    'exposerons',
    'exposeront',
    'exposition',
    'expressifs',
    'expression',
    'expressive',
    'exprimable',
    'exprimâmes',
    'exprimasse',
    'exprimâtes',
    'exprimerai',
    'exprimeras',
    'exprimerez',
    'exprimions',
    'expropriai',
    'exproprias',
    'expropriât',
    'expropriée',
    'exproprier',
    'expropries',
    'expropriés',
    'expropriez',
    'expulsâmes',
    'expulsasse',
    'expulsâtes',
    'expulserai',
    'expulseras',
    'expulserez',
    'expulsions',
    'expurgeais',
    'expurgeait',
    'expurgeant',
    'expurgeons',
    'expurgerai',
    'expurgeras',
    'expurgerez',
    'expurgions',
    'exstrophie',
    'exsudaient',
    'exsudasses',
    'exsudation',
    'exsuderais',
    'exsuderait',
    'exsudèrent',
    'exsuderiez',
    'exsuderons',
    'exsuderont',
    'extasiâmes',
    'extasiâtes',
    'extasierez',
    'extasiions',
    'extatiques',
    'extenseurs',
    'extensible',
    'extensions',
    'extensives',
    'exténuâmes',
    'exténuante',
    'exténuants',
    'exténuasse',
    'exténuâtes',
    'exténuerai',
    'exténueras',
    'exténuerez',
    'exténuions',
    'extérieure',
    'extérieurs',
    'exterminai',
    'exterminas',
    'exterminât',
    'exterminée',
    'exterminer',
    'extermines',
    'exterminés',
    'exterminez',
    'extincteur',
    'extinction',
    'extirpable',
    'extirpâmes',
    'extirpasse',
    'extirpâtes',
    'extirperai',
    'extirperas',
    'extirperez',
    'extirpions',
    'extorquais',
    'extorquait',
    'extorquant',
    'extorquées',
    'extorquent',
    'extorquera',
    'extorqueur',
    'extorquiez',
    'extorquons',
    'extorsions',
    'extracteur',
    'extractifs',
    'extraction',
    'extractive',
    'extradâmes',
    'extradasse',
    'extradâtes',
    'extraderai',
    'extraderas',
    'extraderez',
    'extradions',
    'extra-fine',
    'extra-fins',
    'extra-fort',
    'extrairais',
    'extrairait',
    'extrairiez',
    'extrairons',
    'extrairont',
    'extranéité',
    'extrapolai',
    'extrapolas',
    'extrapolât',
    'extrapolée',
    'extrapoler',
    'extrapoles',
    'extrapolés',
    'extrapolez',
    'extravagua',
    'extravague',
    'extravagué',
    'extravasai',
    'extravasas',
    'extravasât',
    'extravasée',
    'extravaser',
    'extravases',
    'extravasés',
    'extravasez',
    'extraverti',
    'extrayions',
    'extrémisme',
    'extrémiste',
    'extrémités',
    'extrudâmes',
    'extrudasse',
    'extrudâtes',
    'extruderai',
    'extruderas',
    'extruderez',
    'extrudions',
    'extrusions',
    'exubérance',
    'exubérante',
    'exubérants',
    'exulcérais',
    'exulcérait',
    'exulcérant',
    'exulcérées',
    'exulcèrent',
    'exulcérera',
    'exulcériez',
    'exulcérons',
    'exultaient',
    'exultasses',
    'exultation',
    'exulterais',
    'exulterait',
    'exultèrent',
    'exulteriez',
    'exulterons',
    'exulteront',
    'eye-liners',
    'fabricante',
    'fabricants',
    'fabriciens',
    'fabriquais',
    'fabriquait',
    'fabriquant',
    'fabriquées',
    'fabriquent',
    'fabriquera',
    'fabriquiez',
    'fabriquons',
    'fabulaient',
    'fabulasses',
    'fabulateur',
    'fabulation',
    'fabulerais',
    'fabulerait',
    'fabulèrent',
    'fabuleriez',
    'fabulerons',
    'fabuleront',
    'fabuleuses',
    'fabulistes',
    'facétieuse',
    'facettâmes',
    'facettasse',
    'facettâtes',
    'facetterai',
    'facetteras',
    'facetterez',
    'facettions',
    'fâchassent',
    'fâchassiez',
    'fâcherions',
    'facilement',
    'facilitais',
    'facilitait',
    'facilitant',
    'facilitées',
    'facilitent',
    'facilitera',
    'facilitiez',
    'facilitons',
    'façonnages',
    'façonnâmes',
    'façonnasse',
    'façonnâtes',
    'façonnerai',
    'façonneras',
    'façonnerez',
    'façonnière',
    'façonniers',
    'façonnions',
    'fac-similé',
    'facticités',
    'factieuses',
    'factitives',
    'factorages',
    'factorerie',
    'factoriels',
    'factorings',
    'factorisai',
    'factorisas',
    'factorisât',
    'factorisée',
    'factoriser',
    'factorises',
    'factorisés',
    'factorisez',
    'factuelles',
    'facturâmes',
    'facturasse',
    'facturâtes',
    'facturerai',
    'factureras',
    'facturerez',
    'facturière',
    'facturiers',
    'facturions',
    'facultatif',
    'fadasserie',
    'fadassions',
    'faderaient',
    'fagotaient',
    'fagotasses',
    'fagoterais',
    'fagoterait',
    'fagotèrent',
    'fagoteriez',
    'fagoterons',
    'fagoteront',
    'faiblardes',
    'faiblement',
    'faiblesses',
    'faiblirais',
    'faiblirait',
    'faiblirent',
    'faibliriez',
    'faiblirons',
    'faibliront',
    'faiblisses',
    'faiblissez',
    'faïencerie',
    'faïencière',
    'faïenciers',
    'faignantai',
    'faignantas',
    'faignantât',
    'faignanter',
    'faignantes',
    'faignantez',
    'faillaient',
    'faillasses',
    'faillerais',
    'faillerait',
    'faillèrent',
    'failleriez',
    'faillerons',
    'failleront',
    'faillibles',
    'faillirais',
    'faillirait',
    'faillirent',
    'failliriez',
    'faillirons',
    'failliront',
    'faillisses',
    'fainéantai',
    'fainéantas',
    'fainéantât',
    'fainéanter',
    'fainéantes',
    'fainéantez',
    'faire-part',
    'faisandage',
    'faisandais',
    'faisandait',
    'faisandant',
    'faisandeau',
    'faisandées',
    'faisandent',
    'faisandera',
    'faisandiez',
    'faisandons',
    'faisselles',
    'fakirismes',
    'falariques',
    'fallacieux',
    'falsifiais',
    'falsifiait',
    'falsifiant',
    'falsifiées',
    'falsifient',
    'falsifiera',
    'falsifiiez',
    'falsifions',
    'falunières',
    'faméliques',
    'familiales',
    'familières',
    'fanassions',
    'fanatiques',
    'fanatisais',
    'fanatisait',
    'fanatisant',
    'fanatisées',
    'fanatisent',
    'fanatisera',
    'fanatisiez',
    'fanatismes',
    'fanatisons',
    'faneraient',
    'fanfaronna',
    'fanfaronne',
    'fanfaronné',
    'fantaisies',
    'fantasmais',
    'fantasmait',
    'fantasmant',
    'fantasment',
    'fantasmera',
    'fantasmiez',
    'fantasmons',
    'fantasques',
    'fantassins',
    'faradiques',
    'faramineux',
    'farandoles',
    'farcirions',
    'farcissais',
    'farcissait',
    'farcissant',
    'farcissent',
    'farcissiez',
    'farcissons',
    'fardassent',
    'fardassiez',
    'farderions',
    'farfouilla',
    'farfouille',
    'farfouillé',
    'farigoules',
    'farinacées',
    'farinaient',
    'farinasses',
    'farinerais',
    'farinerait',
    'farinèrent',
    'farineriez',
    'farinerons',
    'farineront',
    'farineuses',
    'farnientes',
    'fartassent',
    'fartassiez',
    'farterions',
    'fasciation',
    'fasciculée',
    'fascicules',
    'fasciculés',
    'fascinâmes',
    'fascinante',
    'fascinants',
    'fascinasse',
    'fascinâtes',
    'fascinerai',
    'fascineras',
    'fascinerez',
    'fascinions',
    'fascisâmes',
    'fascisante',
    'fascisants',
    'fascisasse',
    'fascisâtes',
    'fasciserai',
    'fasciseras',
    'fasciserez',
    'fascisions',
    'faseillais',
    'faseillait',
    'faseillant',
    'faseillent',
    'faseillera',
    'faseilliez',
    'faseillons',
    'fast-foods',
    'fastidieux',
    'fastigiées',
    'fastueuses',
    'fatalement',
    'fatalismes',
    'fatalistes',
    'fatidiques',
    'fatigables',
    'fatigantes',
    'fatiguâmes',
    'fatiguante',
    'fatiguants',
    'fatiguasse',
    'fatiguâtes',
    'fatiguerai',
    'fatigueras',
    'fatiguerez',
    'fatiguions',
    'faubourien',
    'faucardais',
    'faucardait',
    'faucardant',
    'faucardées',
    'faucardent',
    'faucardera',
    'faucardeur',
    'faucardiez',
    'faucardons',
    'fauchaient',
    'fauchaison',
    'fauchasses',
    'faucherais',
    'faucherait',
    'fauchèrent',
    'faucheriez',
    'faucherons',
    'faucheront',
    'fauchettes',
    'faucheuses',
    'faucillons',
    'fauconnais',
    'fauconnait',
    'fauconnant',
    'fauconneau',
    'fauconnent',
    'fauconnera',
    'fauconnier',
    'fauconniez',
    'fauconnons',
    'faudraient',
    'faufilages',
    'faufilâmes',
    'faufilasse',
    'faufilâtes',
    'faufilerai',
    'faufileras',
    'faufilerez',
    'faufilions',
    'faufilures',
    'faunesques',
    'faussaient',
    'faussaires',
    'faussasses',
    'faussement',
    'fausserais',
    'fausserait',
    'faussèrent',
    'fausseriez',
    'fausserons',
    'fausseront',
    'fautassent',
    'fautassiez',
    'fauterions',
    'faux-filet',
    'favorables',
    'favorisais',
    'favorisait',
    'favorisant',
    'favorisées',
    'favorisent',
    'favorisera',
    'favorisiez',
    'favorisons',
    'faxassions',
    'faxeraient',
    'fayotaient',
    'fayotasses',
    'fayoterais',
    'fayoterait',
    'fayotèrent',
    'fayoteriez',
    'fayoterons',
    'fayoteront',
    'fayottâmes',
    'fayottasse',
    'fayottâtes',
    'fayotterai',
    'fayotteras',
    'fayotterez',
    'fayottions',
    'fébricules',
    'fébrifuges',
    'fébrilités',
    'fécondable',
    'fécondâmes',
    'fécondante',
    'fécondants',
    'fécondasse',
    'fécondâtes',
    'féconderai',
    'féconderas',
    'féconderez',
    'fécondions',
    'fécondités',
    'féculaient',
    'féculasses',
    'féculences',
    'féculentes',
    'féculerais',
    'féculerait',
    'féculèrent',
    'féculeries',
    'féculeriez',
    'féculerons',
    'féculeront',
    'fédéraient',
    'fédéralisa',
    'fédéralise',
    'fédéralisé',
    'fédérasses',
    'fédérateur',
    'fédératifs',
    'fédération',
    'fédérative',
    'fédérerais',
    'fédérerait',
    'fédérèrent',
    'fédéreriez',
    'fédérerons',
    'fédéreront',
    'fégaterais',
    'fégaterait',
    'fégateriez',
    'fégaterons',
    'fégateront',
    'feignaient',
    'feignantai',
    'feignantas',
    'feignantât',
    'feignanter',
    'feignantes',
    'feignantez',
    'feignirent',
    'feignisses',
    'feindrions',
    'feintaient',
    'feintasses',
    'feinterais',
    'feinterait',
    'feintèrent',
    'feinteriez',
    'feinterons',
    'feinteront',
    'fêlassions',
    'feldspaths',
    'feldwebels',
    'fêleraient',
    'félibriges',
    'félicitais',
    'félicitait',
    'félicitant',
    'félicitées',
    'félicitent',
    'félicitera',
    'félicitiez',
    'félicitons',
    'fellations',
    'féminisais',
    'féminisait',
    'féminisant',
    'féminisées',
    'féminisent',
    'féminisera',
    'féminisiez',
    'féminismes',
    'féminisons',
    'féministes',
    'femmelette',
    'fendillais',
    'fendillait',
    'fendillant',
    'fendillées',
    'fendillent',
    'fendillera',
    'fendilliez',
    'fendillons',
    'fendissent',
    'fendissiez',
    'fendraient',
    'fenestrage',
    'fenestrais',
    'fenestrait',
    'fenestrant',
    'fenestrées',
    'fenestrent',
    'fenestrera',
    'fenestriez',
    'fenestrons',
    'fenêtrages',
    'fenêtrâmes',
    'fenêtrasse',
    'fenêtrâtes',
    'fenêtrerai',
    'fenêtreras',
    'fenêtrerez',
    'fenêtrions',
    'fenouillet',
    'féodalisme',
    'féodalités',
    'ferlassent',
    'ferlassiez',
    'ferlerions',
    'fermassent',
    'fermassiez',
    'fermentais',
    'fermentait',
    'fermentant',
    'fermentées',
    'fermentent',
    'fermentera',
    'fermentiez',
    'fermentons',
    'fermerions',
    'fermetures',
    'férocement',
    'ferraillai',
    'ferraillas',
    'ferraillât',
    'ferrailler',
    'ferrailles',
    'ferraillez',
    'ferrassent',
    'ferrassiez',
    'ferratiers',
    'ferrements',
    'ferrerions',
    'ferronnier',
    'ferrotypie',
    'ferroutage',
    'ferry-boat',
    'fertilisai',
    'fertilisas',
    'fertilisât',
    'fertilisée',
    'fertiliser',
    'fertilises',
    'fertilisés',
    'fertilisez',
    'fertilités',
    'fervemment',
    'fessassent',
    'fessassiez',
    'fesserions',
    'festivités',
    'festoierai',
    'festoieras',
    'festoierez',
    'festonnais',
    'festonnait',
    'festonnant',
    'festonnées',
    'festonnent',
    'festonnera',
    'festonniez',
    'festonnons',
    'festoyâmes',
    'festoyasse',
    'festoyâtes',
    'festoyions',
    'fêtassions',
    'fêteraient',
    'féticheurs',
    'fétichisai',
    'fétichisas',
    'fétichisât',
    'fétichisée',
    'fétichiser',
    'fétichises',
    'fétichisés',
    'fétichisez',
    'fétichisme',
    'fétichiste',
    'feudataire',
    'feuiletisa',
    'feuillages',
    'feuillâmes',
    'feuillards',
    'feuillasse',
    'feuillâtes',
    'feuillerai',
    'feuilleras',
    'feuilleret',
    'feuillerez',
    'feuilletai',
    'feuilletas',
    'feuilletât',
    'feuilletée',
    'feuilleter',
    'feuilletés',
    'feuilletez',
    'feuilletis',
    'feuilleton',
    'feuillette',
    'feuillions',
    'feuillures',
    'feulassent',
    'feulassiez',
    'feulements',
    'feulerions',
    'feutraient',
    'feutrantes',
    'feutrasses',
    'feutrerais',
    'feutrerait',
    'feutrèrent',
    'feutreriez',
    'feutrerons',
    'feutreront',
    'fiabilités',
    'fiançaient',
    'fiançasses',
    'fiancerais',
    'fiancerait',
    'fiancèrent',
    'fianceriez',
    'fiancerons',
    'fianceront',
    'fibrineuse',
    'fibromyome',
    'ficelaient',
    'ficelasses',
    'ficelèrent',
    'ficellerai',
    'ficelleras',
    'ficellerez',
    'ficellerie',
    'fichassent',
    'fichassiez',
    'ficherions',
    'fidèlement',
    'fiduciaire',
    'fieffaient',
    'fieffasses',
    'fiefferais',
    'fiefferait',
    'fieffèrent',
    'fiefferiez',
    'fiefferons',
    'fiefferont',
    'fielleuses',
    'fientaient',
    'fientasses',
    'fienterais',
    'fienterait',
    'fientèrent',
    'fienteriez',
    'fienterons',
    'fienteront',
    'fiévreuses',
    'figeassent',
    'figeassiez',
    'figeraient',
    'fignolages',
    'fignolâmes',
    'fignolasse',
    'fignolâtes',
    'fignolerai',
    'fignoleras',
    'fignolerez',
    'fignoleurs',
    'fignoleuse',
    'fignolions',
    'figuraient',
    'figurantes',
    'figurasses',
    'figuratifs',
    'figuration',
    'figurative',
    'figurément',
    'figurerais',
    'figurerait',
    'figurèrent',
    'figureriez',
    'figurerons',
    'figureront',
    'figurismes',
    'figuristes',
    'filandière',
    'filandreux',
    'filanzanes',
    'filarioses',
    'filassions',
    'fileraient',
    'filetaient',
    'filetasses',
    'filèterais',
    'filèterait',
    'filetèrent',
    'filèteriez',
    'filèterons',
    'filèteront',
    'filiations',
    'filiformes',
    'filigranai',
    'filigranas',
    'filigranât',
    'filigranée',
    'filigraner',
    'filigranes',
    'filigranés',
    'filigranez',
    'filmassent',
    'filmassiez',
    'filmerions',
    'filmologie',
    'filochâmes',
    'filochasse',
    'filochâtes',
    'filocherai',
    'filocheras',
    'filocherez',
    'filochions',
    'filonienne',
    'filoselles',
    'filoutâmes',
    'filoutasse',
    'filoutâtes',
    'filouterai',
    'filouteras',
    'filouterez',
    'filouterie',
    'filoutions',
    'filtrables',
    'filtraient',
    'filtrantes',
    'filtrasses',
    'filtration',
    'filtrerais',
    'filtrerait',
    'filtrèrent',
    'filtreriez',
    'filtrerons',
    'filtreront',
    'finalement',
    'finalismes',
    'finalistes',
    'finançâmes',
    'finançasse',
    'finançâtes',
    'financerai',
    'financeras',
    'financerez',
    'financière',
    'financiers',
    'financions',
    'finassâmes',
    'finassasse',
    'finassâtes',
    'finasserai',
    'finasseras',
    'finasserez',
    'finasserie',
    'finasseurs',
    'finasseuse',
    'finassière',
    'finassiers',
    'finassions',
    'finauderie',
    'finiraient',
    'finissages',
    'finisseurs',
    'finisseuse',
    'finissions',
    'finlandais',
    'finlandisa',
    'finlandise',
    'finlandisé',
    'fioritures',
    'firmaments',
    'fiscalisai',
    'fiscalisas',
    'fiscalisât',
    'fiscalisée',
    'fiscaliser',
    'fiscalises',
    'fiscalisés',
    'fiscalisez',
    'fiscaliste',
    'fiscalités',
    'fissionnai',
    'fissionnas',
    'fissionnât',
    'fissionnée',
    'fissionner',
    'fissionnes',
    'fissionnés',
    'fissionnez',
    'fissurâmes',
    'fissurasse',
    'fissurâtes',
    'fissurerai',
    'fissureras',
    'fissurerez',
    'fissurions',
    'fistulaire',
    'fistuleuse',
    'fistulines',
    'fixassions',
    'fixatrices',
    'fixeraient',
    'flaccidité',
    'flacheries',
    'flaconnage',
    'flaconnier',
    'flagellais',
    'flagellait',
    'flagellant',
    'flagellées',
    'flagellent',
    'flagellera',
    'flagelliez',
    'flagellons',
    'flagellums',
    'flageolais',
    'flageolait',
    'flageolant',
    'flageolent',
    'flageolera',
    'flageolets',
    'flageoliez',
    'flageolons',
    'flagornais',
    'flagornait',
    'flagornant',
    'flagornées',
    'flagornent',
    'flagornera',
    'flagorneur',
    'flagorniez',
    'flagornons',
    'flagrances',
    'flagrantes',
    'flairaient',
    'flairasses',
    'flairerais',
    'flairerait',
    'flairèrent',
    'flaireriez',
    'flairerons',
    'flaireront',
    'flaireuses',
    'flambaient',
    'flambasses',
    'flambement',
    'flamberais',
    'flamberait',
    'flambèrent',
    'flamberges',
    'flamberiez',
    'flamberons',
    'flamberont',
    'flambeuses',
    'flamboient',
    'flamboiera',
    'flamboyais',
    'flamboyait',
    'flamboyant',
    'flamboyiez',
    'flamboyons',
    'flamingant',
    'flammèches',
    'flammerole',
    'flânassent',
    'flânassiez',
    'flanchâmes',
    'flanchasse',
    'flanchâtes',
    'flancherai',
    'flancheras',
    'flancherez',
    'flanchions',
    'flanconade',
    'flânerions',
    'flanquâmes',
    'flanquasse',
    'flanquâtes',
    'flanquerai',
    'flanqueras',
    'flanquerez',
    'flanquions',
    'flaquaient',
    'flaquasses',
    'flaquerais',
    'flaquerait',
    'flaquèrent',
    'flaqueriez',
    'flaquerons',
    'flaqueront',
    'flashaient',
    'flashasses',
    'flash-back',
    'flasherais',
    'flasherait',
    'flashèrent',
    'flasheriez',
    'flasherons',
    'flasheront',
    'flattaient',
    'flattasses',
    'flatterais',
    'flatterait',
    'flattèrent',
    'flatteries',
    'flatteriez',
    'flatterons',
    'flatteront',
    'flatteuses',
    'flatulence',
    'flatulente',
    'flatulents',
    'flatuosité',
    'flaupaient',
    'flaupasses',
    'flauperais',
    'flauperait',
    'flaupèrent',
    'flauperiez',
    'flauperons',
    'flauperont',
    'flavescent',
    'fléchaient',
    'fléchasses',
    'flécherais',
    'flécherait',
    'fléchèrent',
    'flécheriez',
    'flécherons',
    'flécheront',
    'fléchettes',
    'fléchirais',
    'fléchirait',
    'fléchirent',
    'fléchiriez',
    'fléchirons',
    'fléchiront',
    'fléchisses',
    'fléchissez',
    'flemmardai',
    'flemmardas',
    'flemmardât',
    'flemmarder',
    'flemmardes',
    'flemmardez',
    'flétrirais',
    'flétrirait',
    'flétrirent',
    'flétririez',
    'flétrirons',
    'flétriront',
    'flétrisses',
    'flétrissez',
    'fleuraient',
    'fleuraison',
    'fleurasses',
    'fleurerais',
    'fleurerait',
    'fleurèrent',
    'fleureriez',
    'fleurerons',
    'fleureront',
    'fleurettes',
    'fleurirais',
    'fleurirait',
    'fleurirent',
    'fleuririez',
    'fleurirons',
    'fleuriront',
    'fleurisses',
    'fleurissez',
    'fleuristes',
    'fleuronnée',
    'fleuronnés',
    'flexionnel',
    'flibustais',
    'flibustait',
    'flibustant',
    'flibustées',
    'flibustent',
    'flibustera',
    'flibustier',
    'flibustiez',
    'flibustons',
    'flinguâmes',
    'flinguasse',
    'flinguâtes',
    'flinguerai',
    'flingueras',
    'flinguerez',
    'flinguions',
    'flippaient',
    'flippasses',
    'flipperais',
    'flipperait',
    'flippèrent',
    'flipperiez',
    'flipperons',
    'flipperont',
    'flirtaient',
    'flirtasses',
    'flirterais',
    'flirterait',
    'flirtèrent',
    'flirteriez',
    'flirterons',
    'flirteront',
    'flirteuses',
    'floconnais',
    'floconnait',
    'floconnant',
    'floconnent',
    'floconnera',
    'floconneux',
    'floconniez',
    'floconnons',
    'floculâmes',
    'floculasse',
    'floculâtes',
    'floculerai',
    'floculeras',
    'floculerez',
    'floculions',
    'floraisons',
    'florentine',
    'florentins',
    'floricoles',
    'florifères',
    'florilèges',
    'florissant',
    'flosculeux',
    'flottables',
    'flottaient',
    'flottaison',
    'flottantes',
    'flottasses',
    'flottation',
    'flottement',
    'flotterais',
    'flotterait',
    'flottèrent',
    'flotteriez',
    'flotterons',
    'flotteront',
    'flottilles',
    'flouassent',
    'flouassiez',
    'flouerions',
    'flousaient',
    'flousasses',
    'flouserais',
    'flouserait',
    'flousèrent',
    'flouseriez',
    'flouserons',
    'flouseront',
    'fluassions',
    'fluctuâmes',
    'fluctuante',
    'fluctuants',
    'fluctuasse',
    'fluctuâtes',
    'fluctuerai',
    'fluctueras',
    'fluctuerez',
    'fluctuions',
    'flueraient',
    'fluidifiai',
    'fluidifias',
    'fluidifiât',
    'fluidifiée',
    'fluidifier',
    'fluidifies',
    'fluidifiés',
    'fluidifiez',
    'fluidiques',
    'fluidisais',
    'fluidisait',
    'fluidisant',
    'fluidisées',
    'fluidisent',
    'fluidisera',
    'fluidisiez',
    'fluidisons',
    'fluoration',
    'fluorisais',
    'fluorisait',
    'fluorisant',
    'fluorisées',
    'fluorisent',
    'fluorisera',
    'fluorisiez',
    'fluorisons',
    'flûtassent',
    'flûtassiez',
    'flûterions',
    'fluviatile',
    'fluxassent',
    'fluxassiez',
    'fluxerions',
    'fluxmètres',
    'focalisais',
    'focalisait',
    'focalisant',
    'focalisées',
    'focalisent',
    'focalisera',
    'focalisiez',
    'focalisons',
    'foirassent',
    'foirassiez',
    'foirerions',
    'foisonnais',
    'foisonnait',
    'foisonnant',
    'foisonnent',
    'foisonnera',
    'foisonniez',
    'foisonnons',
    'folâtrâmes',
    'folâtrasse',
    'folâtrâtes',
    'folâtrerai',
    'folâtreras',
    'folâtrerez',
    'folâtrerie',
    'folâtrions',
    'foliations',
    'folichonna',
    'folichonne',
    'folichonné',
    'foliotâmes',
    'foliotasse',
    'foliotâtes',
    'folioterai',
    'folioteras',
    'folioterez',
    'foliotions',
    'follicules',
    'fomentâmes',
    'fomentasse',
    'fomentâtes',
    'fomenterai',
    'fomenteras',
    'fomenterez',
    'fomentions',
    'fonçassent',
    'fonçassiez',
    'foncerions',
    'fonctionna',
    'fonctionne',
    'fonctionné',
    'fondassent',
    'fondassiez',
    'fondateurs',
    'fondations',
    'fondatrice',
    'fondements',
    'fonderions',
    'fondissent',
    'fondissiez',
    'fondraient',
    'fondrières',
    'fongicides',
    'fongiforme',
    'fongosités',
    'fongueuses',
    'fontainier',
    'fontanelle',
    'footballer',
    'foraminées',
    'forassions',
    'forçassent',
    'forçassiez',
    'forcements',
    'forcerions',
    'forcirions',
    'forcissais',
    'forcissait',
    'forcissant',
    'forcissent',
    'forcissiez',
    'forcissons',
    'forclusion',
    'foreraient',
    'foresterie',
    'forestière',
    'forestiers',
    'forfaisais',
    'forfaisait',
    'forfaisant',
    'forfaisiez',
    'forfaisons',
    'forfaiture',
    'forfassent',
    'forfassiez',
    'forferions',
    'forficules',
    'forfissent',
    'forfissiez',
    'forgeables',
    'forgeaient',
    'forgeasses',
    'forgerions',
    'forjetâmes',
    'forjetasse',
    'forjetâtes',
    'forjetions',
    'forjettent',
    'forjettera',
    'forlançais',
    'forlançait',
    'forlançant',
    'forlancées',
    'forlancent',
    'forlancera',
    'forlanciez',
    'forlançons',
    'forlignais',
    'forlignait',
    'forlignant',
    'forlignent',
    'forlignera',
    'forligniez',
    'forlignons',
    'formalisai',
    'formalisas',
    'formalisât',
    'formalisée',
    'formaliser',
    'formalises',
    'formalisés',
    'formalisez',
    'formalisme',
    'formaliste',
    'formalités',
    'formariage',
    'formassent',
    'formassiez',
    'formateurs',
    'formations',
    'formatives',
    'formatrice',
    'formerions',
    'formicante',
    'formicants',
    'formidable',
    'formolâmes',
    'formolasse',
    'formolâtes',
    'formolerai',
    'formoleras',
    'formolerez',
    'formolions',
    'formulable',
    'formulaire',
    'formulâmes',
    'formulasse',
    'formulâtes',
    'formulerai',
    'formuleras',
    'formulerez',
    'formulions',
    'forniquais',
    'forniquait',
    'forniquant',
    'forniquent',
    'forniquera',
    'forniquiez',
    'forniquons',
    'forsythias',
    'forteresse',
    'fortifiais',
    'fortifiait',
    'fortifiant',
    'fortifiées',
    'fortifient',
    'fortifiera',
    'fortifiiez',
    'fortifions',
    'fortissimo',
    'fortraites',
    'fossilisai',
    'fossilisas',
    'fossilisât',
    'fossilisée',
    'fossiliser',
    'fossilises',
    'fossilisés',
    'fossilisez',
    'fossoierai',
    'fossoieras',
    'fossoierez',
    'fossoyâmes',
    'fossoyasse',
    'fossoyâtes',
    'fossoyeurs',
    'fossoyeuse',
    'fossoyions',
    'fouaillais',
    'fouaillait',
    'fouaillant',
    'fouaillées',
    'fouaillent',
    'fouaillera',
    'fouailliez',
    'fouaillons',
    'foudroient',
    'foudroiera',
    'foudroyais',
    'foudroyait',
    'foudroyant',
    'foudroyées',
    'foudroyiez',
    'foudroyons',
    'fouettâmes',
    'fouettards',
    'fouettasse',
    'fouettâtes',
    'fouetterai',
    'fouetteras',
    'fouetterez',
    'fouettions',
    'fougeaient',
    'fougeasses',
    'fougerions',
    'fougeroles',
    'fougueuses',
    'fouillâmes',
    'fouillasse',
    'fouillâtes',
    'fouillerai',
    'fouilleras',
    'fouillerez',
    'fouilleurs',
    'fouilleuse',
    'fouillions',
    'fouinaient',
    'fouinardes',
    'fouinasses',
    'fouinerais',
    'fouinerait',
    'fouinèrent',
    'fouineriez',
    'fouinerons',
    'fouineront',
    'fouineuses',
    'fouiraient',
    'fouisseurs',
    'fouisseuse',
    'fouissions',
    'foulassent',
    'foulassiez',
    'foulerions',
    'foultitude',
    'fouraillai',
    'fouraillas',
    'fouraillât',
    'fouraillée',
    'fourailler',
    'fourailles',
    'fouraillés',
    'fouraillez',
    'fourbaient',
    'fourbasses',
    'fourberais',
    'fourberait',
    'fourbèrent',
    'fourberies',
    'fourberiez',
    'fourberons',
    'fourberont',
    'fourbirais',
    'fourbirait',
    'fourbirent',
    'fourbiriez',
    'fourbirons',
    'fourbiront',
    'fourbisses',
    'fourbissez',
    'fourchâmes',
    'fourchasse',
    'fourchâtes',
    'fourcherai',
    'fourcheras',
    'fourcherât',
    'fourchette',
    'fourchions',
    'fourgonnai',
    'fourgonnas',
    'fourgonnât',
    'fourgonnée',
    'fourgonner',
    'fourgonnes',
    'fourgonnés',
    'fourgonnez',
    'fourguâmes',
    'fourguasse',
    'fourguâtes',
    'fourguerai',
    'fourgueras',
    'fourguerez',
    'fourguions',
    'fourmilier',
    'fourmilion',
    'fourmillai',
    'fourmillas',
    'fourmillât',
    'fourmiller',
    'fourmilles',
    'fourmillez',
    'fournaises',
    'fournières',
    'fourniment',
    'fournirais',
    'fournirait',
    'fournirent',
    'fourniriez',
    'fournirons',
    'fourniront',
    'fournisses',
    'fournissez',
    'fourniture',
    'fourrageai',
    'fourrageas',
    'fourrageât',
    'fourragées',
    'fourragent',
    'fourragera',
    'fourragère',
    'fourrageur',
    'fourragiez',
    'fourraient',
    'fourrasses',
    'fourrerais',
    'fourrerait',
    'fourrèrent',
    'fourreriez',
    'fourrerons',
    'fourreront',
    'fourreuses',
    'fourrières',
    'fourvoient',
    'fourvoiera',
    'fourvoyais',
    'fourvoyait',
    'fourvoyant',
    'fourvoyées',
    'fourvoyiez',
    'fourvoyons',
    'foutraient',
    'foutraques',
    'foutriquet',
    'fracassais',
    'fracassait',
    'fracassant',
    'fracassées',
    'fracassent',
    'fracassera',
    'fracassiez',
    'fracassons',
    'fractionna',
    'fractionne',
    'fractionné',
    'fracturais',
    'fracturait',
    'fracturant',
    'fracturées',
    'fracturent',
    'fracturera',
    'fracturiez',
    'fracturons',
    'fragilisai',
    'fragilisas',
    'fragilisât',
    'fragilisée',
    'fragiliser',
    'fragilises',
    'fragilisés',
    'fragilisez',
    'fragilités',
    'fragmentai',
    'fragmentas',
    'fragmentât',
    'fragmentée',
    'fragmenter',
    'fragmentes',
    'fragmentés',
    'fragmentez',
    'fraîcheurs',
    'fraîchîmes',
    'fraîchirai',
    'fraîchiras',
    'fraîchirez',
    'fraîchisse',
    'fraîchîtes',
    'fraierions',
    'fraisaient',
    'fraisasses',
    'fraiserais',
    'fraiserait',
    'fraisèrent',
    'fraiseriez',
    'fraiserons',
    'fraiseront',
    'fraiseuses',
    'fraisières',
    'framboisai',
    'framboisas',
    'framboisât',
    'framboisée',
    'framboiser',
    'framboises',
    'framboisés',
    'framboisez',
    'françaises',
    'franc-bord',
    'franchîmes',
    'franchirai',
    'franchiras',
    'franchirez',
    'franchises',
    'franchisse',
    'franchîtes',
    'franciques',
    'francisais',
    'francisait',
    'francisant',
    'francisées',
    'francisent',
    'francisera',
    'francisiez',
    'francisons',
    'francisque',
    'francistes',
    'francolins',
    'frangeâmes',
    'frangeante',
    'frangeants',
    'frangeasse',
    'frangeâtes',
    'frangerais',
    'frangerait',
    'frangèrent',
    'frangeriez',
    'frangerons',
    'frangeront',
    'frangipane',
    'franquette',
    'franquiste',
    'frappaient',
    'frappantes',
    'frappasses',
    'frappement',
    'frapperais',
    'frapperait',
    'frappèrent',
    'frapperiez',
    'frapperons',
    'frapperont',
    'frappeuses',
    'fraternels',
    'fraternisa',
    'fraternise',
    'fraternisé',
    'fraternité',
    'fratricide',
    'fraudaient',
    'fraudasses',
    'frauderais',
    'frauderait',
    'fraudèrent',
    'frauderiez',
    'frauderons',
    'frauderont',
    'fraudeuses',
    'frauduleux',
    'fraxinelle',
    'frayassent',
    'frayassiez',
    'frayements',
    'frayerions',
    'fredonnais',
    'fredonnait',
    'fredonnant',
    'fredonnées',
    'fredonnent',
    'fredonnera',
    'fredonniez',
    'fredonnons',
    'free-lance',
    'frégatâmes',
    'frégatasse',
    'frégatâtes',
    'frégations',
    'freinaient',
    'freinantes',
    'freinasses',
    'freinerais',
    'freinerait',
    'freinèrent',
    'freineriez',
    'freinerons',
    'freineront',
    'frelatages',
    'frelatâmes',
    'frelatasse',
    'frelatâtes',
    'frelaterai',
    'frelateras',
    'frelaterez',
    'frelations',
    'freluquets',
    'frémirions',
    'frémissais',
    'frémissait',
    'frémissant',
    'frémissent',
    'frémissiez',
    'frémissons',
    'frénétique',
    'fréquences',
    'fréquentai',
    'fréquentas',
    'fréquentât',
    'fréquentée',
    'fréquenter',
    'fréquentes',
    'fréquentés',
    'fréquentez',
    'fresquiste',
    'frétassent',
    'frétassiez',
    'fréterions',
    'frétillais',
    'frétillait',
    'frétillant',
    'frétillent',
    'frétillera',
    'frétilliez',
    'frétillons',
    'frettaient',
    'frettasses',
    'fretterais',
    'fretterait',
    'frettèrent',
    'fretteriez',
    'fretterons',
    'fretteront',
    'freudienne',
    'freudismes',
    'friabilité',
    'friandises',
    'fricandeau',
    'fricassais',
    'fricassait',
    'fricassant',
    'fricassées',
    'fricassent',
    'fricassera',
    'fricassiez',
    'fricassons',
    'fricatives',
    'fricotages',
    'fricotâmes',
    'fricotasse',
    'fricotâtes',
    'fricoterai',
    'fricoteras',
    'fricoterez',
    'fricoteurs',
    'fricoteuse',
    'fricotions',
    'frictionna',
    'frictionne',
    'frictionné',
    'frigidaire',
    'frigidités',
    'frigorifia',
    'frigorifie',
    'frigorifié',
    'frimassent',
    'frimassiez',
    'frimerions',
    'frimousses',
    'fringantes',
    'fringuâmes',
    'fringuante',
    'fringuants',
    'fringuasse',
    'fringuâtes',
    'fringuerai',
    'fringueras',
    'fringuerez',
    'fringuions',
    'fripassent',
    'fripassiez',
    'friperions',
    'friponnais',
    'friponnait',
    'friponnant',
    'friponnées',
    'friponnent',
    'friponnera',
    'friponniez',
    'friponnons',
    'fripouille',
    'frisassent',
    'frisassiez',
    'friserions',
    'frisottais',
    'frisottait',
    'frisottant',
    'frisottées',
    'frisottent',
    'frisottera',
    'frisottiez',
    'frisottons',
    'frisquette',
    'frissonnai',
    'frissonnas',
    'frissonnât',
    'frissonner',
    'frissonnes',
    'frissonnez',
    'frittaient',
    'frittasses',
    'fritterais',
    'fritterait',
    'frittèrent',
    'fritteriez',
    'fritterons',
    'fritteront',
    'frivolités',
    'froidement',
    'froidirais',
    'froidirait',
    'froidirent',
    'froidiriez',
    'froidirons',
    'froidiront',
    'froidisses',
    'froidissez',
    'froissable',
    'froissâmes',
    'froissante',
    'froissants',
    'froissasse',
    'froissâtes',
    'froisserai',
    'froisseras',
    'froisserez',
    'froissions',
    'froissures',
    'frôlassent',
    'frôlassiez',
    'frôlements',
    'frôlerions',
    'fromagères',
    'fromagerie',
    'fromentaux',
    'fronçaient',
    'fronçasses',
    'froncement',
    'froncerais',
    'froncerait',
    'froncèrent',
    'fronceriez',
    'froncerons',
    'fronceront',
    'frondaient',
    'frondaison',
    'frondasses',
    'fronderais',
    'fronderait',
    'frondèrent',
    'fronderiez',
    'fronderons',
    'fronderont',
    'frondeuses',
    'frontalier',
    'frontalité',
    'frontières',
    'frontignan',
    'frottaient',
    'frottantes',
    'frottasses',
    'frottement',
    'frotterais',
    'frotterait',
    'frottèrent',
    'frotteriez',
    'frotterons',
    'frotteront',
    'frotteuses',
    'frouassent',
    'frouassiez',
    'frouerions',
    'froufrouta',
    'froufroute',
    'froufrouté',
    'froussarde',
    'froussards',
    'fructifère',
    'fructifiai',
    'fructifias',
    'fructifiât',
    'fructifier',
    'fructifies',
    'fructifiez',
    'fructueuse',
    'frugalités',
    'frugivores',
    'fruiteries',
    'fruitières',
    'frumentacé',
    'frusquâmes',
    'frusquasse',
    'frusquâtes',
    'frusquerai',
    'frusqueras',
    'frusquerez',
    'frusquions',
    'frustrâmes',
    'frustrante',
    'frustrants',
    'frustrasse',
    'frustrâtes',
    'frustrerai',
    'frustreras',
    'frustrerez',
    'frustrions',
    'frutescent',
    'fuégiennes',
    'fuguassent',
    'fuguassiez',
    'fuguerions',
    'fulgurâmes',
    'fulgurance',
    'fulgurante',
    'fulgurants',
    'fulgurasse',
    'fulgurâtes',
    'fulgurerai',
    'fulgureras',
    'fulgurerez',
    'fulgurions',
    'fuligineux',
    'fulminâmes',
    'fulminante',
    'fulminants',
    'fulminasse',
    'fulminates',
    'fulminâtes',
    'fulminerai',
    'fulmineras',
    'fulminerez',
    'fulminions',
    'fulminique',
    'fumassions',
    'fumeraient',
    'fumerolles',
    'fûmes-nous',
    'fumeterres',
    'fumigateur',
    'fumigation',
    'fumigeâmes',
    'fumigeasse',
    'fumigeâtes',
    'fumigerais',
    'fumigerait',
    'fumigèrent',
    'fumigeriez',
    'fumigerons',
    'fumigeront',
    'fumisterie',
    'funambules',
    'funéraires',
    'funérarium',
    'furent-ils',
    'furetaient',
    'furetasses',
    'furèterais',
    'furèterait',
    'furetèrent',
    'furèteriez',
    'furèterons',
    'furèteront',
    'fureteuses',
    'furfuracée',
    'furfuracés',
    'furibardes',
    'furibondes',
    'fusainiste',
    'fusassions',
    'fuselaient',
    'fuselasses',
    'fuselèrent',
    'fusellerai',
    'fuselleras',
    'fusellerez',
    'fuseraient',
    'fusibilité',
    'fusiformes',
    'fusillades',
    'fusillâmes',
    'fusillasse',
    'fusillâtes',
    'fusillerai',
    'fusilleras',
    'fusillerez',
    'fusilleurs',
    'fusillions',
    'fusinistes',
    'fusionnais',
    'fusionnait',
    'fusionnant',
    'fusionnées',
    'fusionnent',
    'fusionnera',
    'fusionniez',
    'fusionnons',
    'fussent-ce',
    'fustanelle',
    'fustigeais',
    'fustigeait',
    'fustigeant',
    'fustigeons',
    'fustigerai',
    'fustigeras',
    'fustigerez',
    'fustigions',
    'fûtes-vous',
    'futilement',
    'futuribles',
    'futurismes',
    'futuristes',
    'gabardines',
    'gabariages',
    'gabarriers',
    'gabionnage',
    'gabionnais',
    'gabionnait',
    'gabionnant',
    'gabionnées',
    'gabionnent',
    'gabionnera',
    'gabionniez',
    'gabionnons',
    'gâchassent',
    'gâchassiez',
    'gâcherions',
    'gadgétisai',
    'gadgétisas',
    'gadgétisât',
    'gadgétisée',
    'gadgétiser',
    'gadgétises',
    'gadgétisés',
    'gadgétisez',
    'gadolinium',
    'gaffassent',
    'gaffassiez',
    'gafferions',
    'gageassent',
    'gageassiez',
    'gageraient',
    'gagnassent',
    'gagnassiez',
    'gagne-pain',
    'gagnerions',
    'gaillardes',
    'gaillardie',
    'gailletins',
    'gainassent',
    'gainassiez',
    'gainerions',
    'galactique',
    'galactoses',
    'galalithes',
    'galandages',
    'galanterie',
    'galantines',
    'galbassent',
    'galbassiez',
    'galberions',
    'galéjaient',
    'galéjasses',
    'galéjerais',
    'galéjerait',
    'galéjèrent',
    'galéjeriez',
    'galéjerons',
    'galéjeront',
    'galéniques',
    'galénismes',
    'galetteuse',
    'galhaubans',
    'galiléenne',
    'galimafrée',
    'galimatias',
    'galipettes',
    'galipotais',
    'galipotait',
    'galipotant',
    'galipotées',
    'galipotent',
    'galipotera',
    'galipotiez',
    'galipotons',
    'gallicanes',
    'gallicisme',
    'gallicoles',
    'gallinacés',
    'gallomanie',
    'galonnâmes',
    'galonnasse',
    'galonnâtes',
    'galonnerai',
    'galonneras',
    'galonnerez',
    'galonnière',
    'galonniers',
    'galonnions',
    'galopaient',
    'galopantes',
    'galopasses',
    'galoperais',
    'galoperait',
    'galopèrent',
    'galoperiez',
    'galoperons',
    'galoperont',
    'galopeuses',
    'galvanique',
    'galvanisai',
    'galvanisas',
    'galvanisât',
    'galvanisée',
    'galvaniser',
    'galvanises',
    'galvanisés',
    'galvanisez',
    'galvanisme',
    'galvaudage',
    'galvaudais',
    'galvaudait',
    'galvaudant',
    'galvaudées',
    'galvaudent',
    'galvaudera',
    'galvaudeux',
    'galvaudiez',
    'galvaudons',
    'gambadâmes',
    'gambadante',
    'gambadants',
    'gambadasse',
    'gambadâtes',
    'gambaderai',
    'gambaderas',
    'gambaderez',
    'gambadions',
    'gambergeai',
    'gambergeas',
    'gambergeât',
    'gambergées',
    'gambergent',
    'gambergera',
    'gambergiez',
    'gambillais',
    'gambillait',
    'gambillant',
    'gambillent',
    'gambillera',
    'gambilliez',
    'gambillons',
    'gaminaient',
    'gaminasses',
    'gaminerais',
    'gaminerait',
    'gaminèrent',
    'gamineries',
    'gamineriez',
    'gaminerons',
    'gamineront',
    'gamopétale',
    'gamosépale',
    'ganacherie',
    'ganaderias',
    'gangétique',
    'gangrenais',
    'gangrenait',
    'gangrenant',
    'gangrenées',
    'gangrènent',
    'gangrènera',
    'gangreneux',
    'gangréneux',
    'gangreniez',
    'gangrenons',
    'gansassent',
    'gansassiez',
    'ganserions',
    'gantassent',
    'gantassiez',
    'gantelines',
    'ganterions',
    'garagistes',
    'garançâmes',
    'garançasse',
    'garançâtes',
    'garancerai',
    'garanceras',
    'garancerez',
    'garancerie',
    'garanceurs',
    'garancière',
    'garancions',
    'garantîmes',
    'garantirai',
    'garantiras',
    'garantirez',
    'garantisse',
    'garantîtes',
    'garassions',
    'garçonnets',
    'garçonnier',
    'gardassent',
    'gardassiez',
    'garde-boue',
    'garde-côte',
    'garde-fous',
    'garde-port',
    'garderions',
    'garde-robe',
    'garde-voie',
    'gardiennes',
    'gareraient',
    'gargamelle',
    'gargantuas',
    'gargarisai',
    'gargarisas',
    'gargarisât',
    'gargarisée',
    'gargariser',
    'gargarises',
    'gargarisés',
    'gargarisez',
    'gargarisme',
    'gargotâmes',
    'gargotante',
    'gargotants',
    'gargotasse',
    'gargotâtes',
    'gargoterai',
    'gargoteras',
    'gargoterez',
    'gargotière',
    'gargotiers',
    'gargotions',
    'gargouilla',
    'gargouille',
    'gargouillé',
    'gargousses',
    'garnements',
    'garnirions',
    'garnissage',
    'garnissais',
    'garnissait',
    'garnissant',
    'garnissent',
    'garnisseur',
    'garnissiez',
    'garnissons',
    'garnitures',
    'garrottage',
    'garrottais',
    'garrottait',
    'garrottant',
    'garrottées',
    'garrottent',
    'garrottera',
    'garrottiez',
    'garrottons',
    'gasconnade',
    'gasconnais',
    'gasconnait',
    'gasconnant',
    'gasconnent',
    'gasconnera',
    'gasconniez',
    'gasconnons',
    'gaspillage',
    'gaspillais',
    'gaspillait',
    'gaspillant',
    'gaspillées',
    'gaspillent',
    'gaspillera',
    'gaspilleur',
    'gaspilliez',
    'gaspillons',
    'gastralgie',
    'gastriques',
    'gastronome',
    'gastropode',
    'gâtassions',
    'gâteraient',
    'gâte-sauce',
    'gâtifiâmes',
    'gâtifiasse',
    'gâtifiâtes',
    'gâtifierai',
    'gâtifieras',
    'gâtifierez',
    'gâtifiions',
    'gattiliers',
    'gauchement',
    'gaucheries',
    'gauchirais',
    'gauchirait',
    'gauchirent',
    'gauchiriez',
    'gauchirons',
    'gauchiront',
    'gauchisant',
    'gauchismes',
    'gauchisses',
    'gauchissez',
    'gauchistes',
    'gaudrioles',
    'gaufraient',
    'gaufrasses',
    'gaufrerais',
    'gaufrerait',
    'gaufrèrent',
    'gaufreriez',
    'gaufrerons',
    'gaufreront',
    'gaufrettes',
    'gaufreuses',
    'gaulassent',
    'gaulassiez',
    'gaulerions',
    'gaullienne',
    'gaullismes',
    'gaullistes',
    'gaulthérie',
    'gaussaient',
    'gaussasses',
    'gausserais',
    'gausserait',
    'gaussèrent',
    'gausseriez',
    'gausserons',
    'gausseront',
    'gavassions',
    'gaveraient',
    'gazassions',
    'gazéifiais',
    'gazéifiait',
    'gazéifiant',
    'gazéifiées',
    'gazéifient',
    'gazéifiera',
    'gazéifiiez',
    'gazéifions',
    'gazeraient',
    'gazetières',
    'gazomètres',
    'gazonnages',
    'gazonnâmes',
    'gazonnasse',
    'gazonnâtes',
    'gazonnerai',
    'gazonneras',
    'gazonnerez',
    'gazonnions',
    'gazouillai',
    'gazouillas',
    'gazouillât',
    'gazouiller',
    'gazouilles',
    'gazouillez',
    'gazouillis',
    'geignaient',
    'geignardes',
    'geignement',
    'geignirent',
    'geignisses',
    'geindrions',
    'gelassions',
    'gélatinais',
    'gélatinait',
    'gélatinant',
    'gélatinées',
    'gélatinent',
    'gélatinera',
    'gélatineux',
    'gélatiniez',
    'gélatinisa',
    'gélatinise',
    'gélatinisé',
    'gélatinons',
    'gèleraient',
    'gélifiâmes',
    'gélifiante',
    'gélifiants',
    'gélifiasse',
    'gélifiâtes',
    'gélifierai',
    'gélifieras',
    'gélifierez',
    'gélifiions',
    'gelinottes',
    'gélinottes',
    'gémellaire',
    'gémellités',
    'géminaient',
    'géminasses',
    'gémination',
    'géminerais',
    'géminerait',
    'géminèrent',
    'gémineriez',
    'géminerons',
    'gémineront',
    'gémiraient',
    'gémissante',
    'gémissants',
    'gémissions',
    'gemmassent',
    'gemmassiez',
    'gemmations',
    'gemmerions',
    'gemmifères',
    'gemmologie',
    'gênassions',
    'gendarmais',
    'gendarmait',
    'gendarmant',
    'gendarmées',
    'gendarment',
    'gendarmera',
    'gendarmiez',
    'gendarmons',
    'généalogie',
    'généraient',
    'gêneraient',
    'généralats',
    'généralisa',
    'généralise',
    'généralisé',
    'généralité',
    'générasses',
    'générateur',
    'génératifs',
    'génération',
    'générative',
    'générerais',
    'générerait',
    'générèrent',
    'généreriez',
    'générerons',
    'généreront',
    'généreuses',
    'génériques',
    'générosité',
    'génésiaque',
    'génésiques',
    'généticien',
    'genêtières',
    'génétiques',
    'génétismes',
    'genévriers',
    'génialités',
    'génitrices',
    'génomiques',
    'gentilités',
    'gentillets',
    'géochimies',
    'géodésique',
    'géographes',
    'géographie',
    'géologique',
    'géomancies',
    'géométraux',
    'géométries',
    'géométrisa',
    'géométrise',
    'géométrisé',
    'géorgienne',
    'géothermie',
    'gérassions',
    'gerbassent',
    'gerbassiez',
    'gerberions',
    'gerçassent',
    'gerçassiez',
    'gercerions',
    'géreraient',
    'gériatries',
    'germandrée',
    'germanique',
    'germanisai',
    'germanisas',
    'germanisât',
    'germanisée',
    'germaniser',
    'germanises',
    'germanisés',
    'germanisez',
    'germanisme',
    'germaniste',
    'germaniums',
    'germassent',
    'germassiez',
    'germerions',
    'germicides',
    'germinatif',
    'gérontisme',
    'gestations',
    'gestatoire',
    'gesticulai',
    'gesticulas',
    'gesticulât',
    'gesticuler',
    'gesticules',
    'gesticulez',
    'gestuelles',
    'gibbosités',
    'gibecières',
    'gibelottes',
    'giboierais',
    'giboierait',
    'giboieriez',
    'giboierons',
    'giboieront',
    'giboyaient',
    'giboyasses',
    'giboyèrent',
    'giboyeuses',
    'giclassent',
    'giclassiez',
    'giclements',
    'giclerions',
    'giflassent',
    'giflassiez',
    'giflerions',
    'gigantisme',
    'gigotaient',
    'gigotantes',
    'gigotasses',
    'gigoterais',
    'gigoterait',
    'gigotèrent',
    'gigoteriez',
    'gigoterons',
    'gigoteront',
    'giletières',
    'gimblettes',
    'gingembres',
    'gingivales',
    'gingivites',
    'girandoles',
    'giratoires',
    'giraumonts',
    'girofliers',
    'girondines',
    'gironnâmes',
    'gironnasse',
    'gironnâtes',
    'gironnerai',
    'gironneras',
    'gironnerez',
    'gironnions',
    'girouettai',
    'girouettas',
    'girouettât',
    'girouetter',
    'girouettes',
    'girouettez',
    'gîtassions',
    'gîteraient',
    'givrassent',
    'givrassiez',
    'givrerions',
    'glaçassent',
    'glaçassiez',
    'glacerions',
    'glaciaires',
    'glaciation',
    'gladiateur',
    'glairaient',
    'glairasses',
    'glairerais',
    'glairerait',
    'glairèrent',
    'glaireriez',
    'glairerons',
    'glaireront',
    'glaireuses',
    'glaisaient',
    'glaisasses',
    'glaiserais',
    'glaiserait',
    'glaisèrent',
    'glaiseriez',
    'glaiserons',
    'glaiseront',
    'glaiseuses',
    'glaisières',
    'glanassent',
    'glanassiez',
    'glandaient',
    'glandasses',
    'glanderais',
    'glanderait',
    'glandèrent',
    'glanderiez',
    'glanderons',
    'glanderont',
    'glandeuses',
    'glanduleux',
    'glanerions',
    'glapirions',
    'glapissais',
    'glapissait',
    'glapissant',
    'glapissent',
    'glapissiez',
    'glapissons',
    'glatirions',
    'glatissais',
    'glatissait',
    'glatissant',
    'glatissent',
    'glatissiez',
    'glatissons',
    'glaviotais',
    'glaviotait',
    'glaviotant',
    'glaviotent',
    'glaviotera',
    'glaviotiez',
    'glaviotons',
    'glaviottai',
    'glaviottas',
    'glaviottât',
    'glaviotter',
    'glaviottes',
    'glaviottez',
    'glissaient',
    'glissantes',
    'glissasses',
    'glissement',
    'glisserais',
    'glisserait',
    'glissèrent',
    'glisseriez',
    'glisserons',
    'glisseront',
    'glisseuses',
    'glissières',
    'glissoires',
    'globalisai',
    'globalisas',
    'globalisât',
    'globalisée',
    'globaliser',
    'globalises',
    'globalisés',
    'globalisez',
    'globalités',
    'globulaire',
    'globuleuse',
    'globulines',
    'glomérules',
    'gloriettes',
    'glorieuses',
    'glorifiais',
    'glorifiait',
    'glorifiant',
    'glorifiées',
    'glorifient',
    'glorifiera',
    'glorifiiez',
    'glorifions',
    'glosassent',
    'glosassiez',
    'gloserions',
    'glossaires',
    'glossateur',
    'glottiques',
    'glouglouta',
    'glougloute',
    'glouglouté',
    'gloussâmes',
    'gloussasse',
    'gloussâtes',
    'glousserai',
    'glousseras',
    'glousserez',
    'gloussions',
    'glouterons',
    'gloutonnes',
    'gluciniums',
    'glucomètre',
    'glucoserie',
    'glucosides',
    'glutamates',
    'glutamique',
    'glutineuse',
    'glycérides',
    'glycérinai',
    'glycérinas',
    'glycérinât',
    'glycérinée',
    'glycériner',
    'glycérines',
    'glycérinés',
    'glycérinez',
    'glycérique',
    'glycocolle',
    'glycogènes',
    'glycolyses',
    'glycosurie',
    'glyptiques',
    'glyptodons',
    'gnognottes',
    'gnomonique',
    'gnostiques',
    'gobassions',
    'gobeletier',
    'goberaient',
    'gobergeais',
    'gobergeait',
    'gobergeant',
    'gobergeons',
    'gobergerai',
    'gobergeras',
    'gobergerez',
    'gobergions',
    'gobetaient',
    'gobetasses',
    'gobetèrent',
    'gobetterai',
    'gobetteras',
    'gobetterez',
    'godaillais',
    'godaillait',
    'godaillant',
    'godaillent',
    'godaillera',
    'godailliez',
    'godaillons',
    'godassions',
    'godelureau',
    'godemichés',
    'goderaient',
    'godillâmes',
    'godillante',
    'godillants',
    'godillasse',
    'godillâtes',
    'godillerai',
    'godilleras',
    'godillerez',
    'godilleurs',
    'godillions',
    'godronnage',
    'godronnais',
    'godronnait',
    'godronnant',
    'godronnées',
    'godronnent',
    'godronnera',
    'godronniez',
    'godronnons',
    'goguenarda',
    'goguenarde',
    'goguenardé',
    'goguenards',
    'goinfrâmes',
    'goinfrasse',
    'goinfrâtes',
    'goinfrerai',
    'goinfreras',
    'goinfrerez',
    'goinfrerie',
    'goinfrions',
    'goitreuses',
    'goménolées',
    'gominaient',
    'gominasses',
    'gominerais',
    'gominerait',
    'gominèrent',
    'gomineriez',
    'gominerons',
    'gomineront',
    'gommassent',
    'gommassiez',
    'gommerions',
    'gomorrhéen',
    'gonadiques',
    'gondassent',
    'gondassiez',
    'gonderions',
    'gondolages',
    'gondolâmes',
    'gondolante',
    'gondolants',
    'gondolasse',
    'gondolâtes',
    'gondolerai',
    'gondoleras',
    'gondolerez',
    'gondoliers',
    'gondolions',
    'gonflables',
    'gonflaient',
    'gonflantes',
    'gonflasses',
    'gonflement',
    'gonflerais',
    'gonflerait',
    'gonflèrent',
    'gonfleriez',
    'gonflerons',
    'gonfleront',
    'gongorisme',
    'goniomètre',
    'gonococcie',
    'gonocoques',
    'gonophores',
    'gonozoïdes',
    'gorgeaient',
    'gorgeasses',
    'gorgerette',
    'gorgerions',
    'gorgonzola',
    'gouachâmes',
    'gouachasse',
    'gouachâtes',
    'gouacherai',
    'gouacheras',
    'gouacherez',
    'gouachions',
    'gouaillais',
    'gouaillait',
    'gouaillant',
    'gouaillent',
    'gouaillera',
    'gouailleur',
    'gouailliez',
    'gouaillons',
    'goualantes',
    'goudronnai',
    'goudronnas',
    'goudronnât',
    'goudronnée',
    'goudronner',
    'goudronnes',
    'goudronnés',
    'goudronnez',
    'gougnafier',
    'goujaterie',
    'goujonnais',
    'goujonnait',
    'goujonnant',
    'goujonnées',
    'goujonnent',
    'goujonnera',
    'goujonniez',
    'goujonnons',
    'gouleyante',
    'gouleyants',
    'goupillais',
    'goupillait',
    'goupillant',
    'goupillées',
    'goupillent',
    'goupillera',
    'goupilliez',
    'goupillons',
    'gourassent',
    'gourassiez',
    'gourerions',
    'gourmandai',
    'gourmandas',
    'gourmandât',
    'gourmandée',
    'gourmander',
    'gourmandes',
    'gourmandés',
    'gourmandez',
    'gourmettes',
    'goûtassent',
    'goûtassiez',
    'goûterions',
    'gouttaient',
    'gouttasses',
    'goutterais',
    'goutterait',
    'gouttèrent',
    'goutteriez',
    'goutterons',
    'goutteront',
    'goutteuses',
    'gouttières',
    'gouvernail',
    'gouvernais',
    'gouvernait',
    'gouvernant',
    'gouvernées',
    'gouvernent',
    'gouvernera',
    'gouverneur',
    'gouverniez',
    'gouvernons',
    'grabataire',
    'graciaient',
    'graciasses',
    'gracierais',
    'gracierait',
    'gracièrent',
    'gracieriez',
    'gracierons',
    'gracieront',
    'gracieuses',
    'gracilités',
    'gradations',
    'graduaient',
    'graduasses',
    'graduation',
    'graduelles',
    'graduerais',
    'graduerait',
    'graduèrent',
    'gradueriez',
    'graduerons',
    'gradueront',
    'graillâmes',
    'graillasse',
    'graillâtes',
    'graillerai',
    'grailleras',
    'graillerez',
    'graillions',
    'graillonna',
    'graillonne',
    'graillonné',
    'grainaient',
    'grainasses',
    'grainerais',
    'grainerait',
    'grainèrent',
    'graineriez',
    'grainerons',
    'graineront',
    'grainetier',
    'grainières',
    'graissages',
    'graissâmes',
    'graissante',
    'graissants',
    'graissasse',
    'graissâtes',
    'graisserai',
    'graisseras',
    'graisserez',
    'graisseurs',
    'graisseuse',
    'graissions',
    'grammaires',
    'gramophone',
    'grandelets',
    'grandement',
    'grandesses',
    'grandettes',
    'grandioses',
    'grandirais',
    'grandirait',
    'grandirent',
    'grandiriez',
    'grandirons',
    'grandiront',
    'grandisses',
    'grandissez',
    'grand-mère',
    'grand-papa',
    'grand-père',
    'granitâmes',
    'granitasse',
    'granitâtes',
    'graniterai',
    'graniteras',
    'graniterez',
    'graniteuse',
    'granitions',
    'granitique',
    'granitoïde',
    'granivores',
    'granulaire',
    'granulâmes',
    'granulasse',
    'granulâtes',
    'granulerai',
    'granuleras',
    'granulerez',
    'granuleuse',
    'granulions',
    'granulites',
    'granulomes',
    'grapefruit',
    'graphiques',
    'graphismes',
    'graphistes',
    'graphitage',
    'graphitais',
    'graphitait',
    'graphitant',
    'graphitées',
    'graphitent',
    'graphitera',
    'graphiteux',
    'graphitiez',
    'graphitons',
    'grappillai',
    'grappillas',
    'grappillât',
    'grappillée',
    'grappiller',
    'grappilles',
    'grappillés',
    'grappillez',
    'grappillon',
    'grassement',
    'grasseries',
    'grasseyais',
    'grasseyait',
    'grasseyant',
    'grasseyent',
    'grasseyera',
    'grasseyiez',
    'grasseyons',
    'graticulai',
    'graticulas',
    'graticulât',
    'graticulée',
    'graticuler',
    'graticules',
    'graticulés',
    'graticulez',
    'gratifiais',
    'gratifiait',
    'gratifiant',
    'gratifiées',
    'gratifient',
    'gratifiera',
    'gratifiiez',
    'gratifions',
    'gratinâmes',
    'gratinasse',
    'gratinâtes',
    'gratinerai',
    'gratineras',
    'gratinerez',
    'gratinions',
    'gratitudes',
    'grattaient',
    'grattasses',
    'gratte-cul',
    'gratte-dos',
    'grattelles',
    'grattement',
    'gratterais',
    'gratterait',
    'grattèrent',
    'gratteriez',
    'gratterons',
    'gratteront',
    'gratteuses',
    'gravassent',
    'gravassiez',
    'gravatiers',
    'graveleuse',
    'gravelures',
    'graverions',
    'gravidique',
    'gravidités',
    'gravillons',
    'gravirions',
    'gravissais',
    'gravissait',
    'gravissant',
    'gravissent',
    'gravissiez',
    'gravissons',
    'gravitâmes',
    'gravitasse',
    'gravitâtes',
    'graviterai',
    'graviteras',
    'graviterez',
    'gravitions',
    'gréassions',
    'grécisâmes',
    'grécisasse',
    'grécisâtes',
    'gréciserai',
    'gréciseras',
    'gréciserez',
    'grécisions',
    'grecquâmes',
    'grecquasse',
    'grecquâtes',
    'grecquerai',
    'grecqueras',
    'grecquerez',
    'grecquions',
    'gredinerie',
    'greffaient',
    'greffasses',
    'grefferais',
    'grefferait',
    'greffèrent',
    'grefferiez',
    'grefferons',
    'grefferont',
    'greffeuses',
    'greffières',
    'grégarisme',
    'grégoriens',
    'grelottais',
    'grelottait',
    'grelottant',
    'grelottent',
    'grelottera',
    'grelottiez',
    'grelottons',
    'greluchons',
    'grenadages',
    'grenadeurs',
    'grenadière',
    'grenadiers',
    'grenadille',
    'grenadines',
    'grenaillai',
    'grenaillas',
    'grenaillât',
    'grenaillée',
    'grenailler',
    'grenailles',
    'grenaillés',
    'grenaillez',
    'grenaisons',
    'grenassent',
    'grenassiez',
    'grenelâmes',
    'grenelasse',
    'grenelâtes',
    'grenelions',
    'grenellent',
    'grenellera',
    'grènerions',
    'grenouilla',
    'grenouille',
    'grenouillé',
    'grésassent',
    'grésassiez',
    'gréserions',
    'grevassent',
    'grevassiez',
    'grèverions',
    'gribouilla',
    'gribouille',
    'gribouillé',
    'grièvement',
    'griffaient',
    'griffasses',
    'grifferais',
    'grifferait',
    'griffèrent',
    'grifferiez',
    'grifferons',
    'grifferont',
    'griffeuses',
    'griffonnai',
    'griffonnas',
    'griffonnât',
    'griffonnée',
    'griffonner',
    'griffonnes',
    'griffonnés',
    'griffonnez',
    'grignaient',
    'grignasses',
    'grignerais',
    'grignerait',
    'grignèrent',
    'grigneriez',
    'grignerons',
    'grigneront',
    'grignotage',
    'grignotais',
    'grignotait',
    'grignotant',
    'grignotées',
    'grignotent',
    'grignotera',
    'grignoteur',
    'grignotiez',
    'grignotons',
    'grillageai',
    'grillageas',
    'grillageât',
    'grillagées',
    'grillagent',
    'grillagera',
    'grillageur',
    'grillagiez',
    'grillaient',
    'grillasses',
    'grillerais',
    'grillerait',
    'grillèrent',
    'grilleriez',
    'grillerons',
    'grilleront',
    'grill-room',
    'grimaçâmes',
    'grimaçante',
    'grimaçants',
    'grimaçasse',
    'grimaçâtes',
    'grimacerai',
    'grimaceras',
    'grimacerez',
    'grimacière',
    'grimaciers',
    'grimacions',
    'grimassent',
    'grimassiez',
    'grimerions',
    'grimpaient',
    'grimpantes',
    'grimpasses',
    'grimperais',
    'grimperait',
    'grimpereau',
    'grimpèrent',
    'grimperiez',
    'grimperons',
    'grimperont',
    'grimpettes',
    'grimpeuses',
    'grinçaient',
    'grinçantes',
    'grinçasses',
    'grincement',
    'grincerais',
    'grincerait',
    'grincèrent',
    'grinceriez',
    'grincerons',
    'grinceront',
    'grinchâmes',
    'grinchasse',
    'grinchâtes',
    'grincherai',
    'grincheras',
    'grincherez',
    'grincheuse',
    'grinchions',
    'gringalets',
    'gringuâmes',
    'gringuasse',
    'gringuâtes',
    'gringuerai',
    'gringueras',
    'gringuerez',
    'gringuions',
    'grippaient',
    'grippasses',
    'gripperais',
    'gripperait',
    'grippèrent',
    'gripperiez',
    'gripperons',
    'gripperont',
    'grippe-sou',
    'grisaillai',
    'grisaillas',
    'grisaillât',
    'grisaillée',
    'grisailler',
    'grisailles',
    'grisaillés',
    'grisaillez',
    'grisassent',
    'grisassiez',
    'griserions',
    'grisolâmes',
    'grisolasse',
    'grisolâtes',
    'grisolerai',
    'grisoleras',
    'grisolerez',
    'grisolions',
    'grisollais',
    'grisollait',
    'grisollant',
    'grisollent',
    'grisollera',
    'grisolliez',
    'grisollons',
    'grisonnais',
    'grisonnait',
    'grisonnant',
    'grisonnent',
    'grisonnera',
    'grisonniez',
    'grisonnons',
    'grisouteux',
    'grivelâmes',
    'grivelasse',
    'grivelâtes',
    'grivèlerie',
    'grivelions',
    'grivellent',
    'grivellera',
    'grivelures',
    'grognaient',
    'grognardes',
    'grognassai',
    'grognassas',
    'grognassât',
    'grognasser',
    'grognasses',
    'grognassez',
    'grognement',
    'grognerais',
    'grognerait',
    'grognèrent',
    'grogneries',
    'grogneriez',
    'grognerons',
    'grogneront',
    'grogneuses',
    'grognonnes',
    'grommelais',
    'grommelait',
    'grommelant',
    'grommelées',
    'grommeliez',
    'grommelles',
    'grommelons',
    'grondaient',
    'grondasses',
    'grondement',
    'gronderais',
    'gronderait',
    'grondèrent',
    'gronderies',
    'gronderiez',
    'gronderons',
    'gronderont',
    'groseilles',
    'grosseries',
    'grossesses',
    'grossières',
    'grossirais',
    'grossirait',
    'grossirent',
    'grossiriez',
    'grossirons',
    'grossiront',
    'grossisses',
    'grossissez',
    'grossistes',
    'grossoient',
    'grossoiera',
    'grossoyais',
    'grossoyait',
    'grossoyant',
    'grossoyées',
    'grossoyiez',
    'grossoyons',
    'grotesques',
    'grouillais',
    'grouillait',
    'grouillant',
    'grouillées',
    'grouillent',
    'grouillera',
    'grouilliez',
    'grouillons',
    'grouillots',
    'groullâmes',
    'groullasse',
    'groullâtes',
    'groupaient',
    'groupasses',
    'groupement',
    'grouperais',
    'grouperait',
    'groupèrent',
    'grouperiez',
    'grouperons',
    'grouperont',
    'grugeaient',
    'grugeasses',
    'grugerions',
    'grumelâmes',
    'grumelasse',
    'grumelâtes',
    'grumeleuse',
    'grumelions',
    'grumellent',
    'grumellera',
    'grumelures',
    'guéassions',
    'guéeraient',
    'guérillero',
    'guéririons',
    'guérissais',
    'guérissait',
    'guérissant',
    'guérissent',
    'guérisseur',
    'guérissiez',
    'guérissons',
    'guerrières',
    'guerroient',
    'guerroiera',
    'guerroyais',
    'guerroyait',
    'guerroyant',
    'guerroyiez',
    'guerroyons',
    'guet-apens',
    'guêtraient',
    'guêtrasses',
    'guêtrerais',
    'guêtrerait',
    'guêtrèrent',
    'guêtreriez',
    'guêtrerons',
    'guêtreront',
    'guettaient',
    'guettasses',
    'guetterais',
    'guetterait',
    'guettèrent',
    'guetteriez',
    'guetterons',
    'guetteront',
    'guetteuses',
    'gueulaient',
    'gueulantes',
    'gueulardes',
    'gueulasses',
    'gueulement',
    'gueulerais',
    'gueulerait',
    'gueulèrent',
    'gueuleriez',
    'gueulerons',
    'gueuleront',
    'gueuletons',
    'gueusaient',
    'gueusasses',
    'gueuserais',
    'gueuserait',
    'gueusèrent',
    'gueuseries',
    'gueuseriez',
    'gueuserons',
    'gueuseront',
    'guichetier',
    'guidassent',
    'guidassiez',
    'guide-ânes',
    'guiderions',
    'guideropes',
    'guignaient',
    'guignardes',
    'guignasses',
    'guignerais',
    'guignerait',
    'guignèrent',
    'guigneriez',
    'guignerons',
    'guigneront',
    'guignettes',
    'guignolets',
    'guillaumes',
    'guillemeta',
    'guillemeté',
    'guillemets',
    'guillemots',
    'guillerets',
    'guillochai',
    'guillochas',
    'guillochât',
    'guillochée',
    'guillocher',
    'guilloches',
    'guillochés',
    'guillochez',
    'guillochis',
    'guillotina',
    'guillotine',
    'guillotiné',
    'guimbardes',
    'guinchâmes',
    'guinchasse',
    'guinchâtes',
    'guincherai',
    'guincheras',
    'guincherez',
    'guinchions',
    'guindaient',
    'guindasses',
    'guinderais',
    'guinderait',
    'guindèrent',
    'guinderiez',
    'guinderons',
    'guinderont',
    'guinguette',
    'guipassent',
    'guipassiez',
    'guiperions',
    'guirlandes',
    'guitariste',
    'gummifères',
    'gustations',
    'gustatives',
    'gutturales',
    'gymnocarpe',
    'gypsomètre',
    'gypsophile',
    'gyrocompas',
    'gyromètres',
    'gyrophares',
    'gyropilote',
    'gyroscopes',
    'habilement',
    'habilitais',
    'habilitait',
    'habilitant',
    'habilitées',
    'habilitent',
    'habilitera',
    'habilitiez',
    'habilitons',
    'habillable',
    'habillages',
    'habillâmes',
    'habillasse',
    'habillâtes',
    'habillerai',
    'habilleras',
    'habillerez',
    'habilleurs',
    'habilleuse',
    'habillions',
    'habitables',
    'habitacles',
    'habitaient',
    'habitantes',
    'habitasses',
    'habitation',
    'habiterais',
    'habiterait',
    'habitèrent',
    'habiteriez',
    'habiterons',
    'habiteront',
    'habituâmes',
    'habituasse',
    'habituâtes',
    'habituelle',
    'habituerai',
    'habitueras',
    'habituerez',
    'habituions',
    'hablassent',
    'hablassiez',
    'hablerions',
    'hachassent',
    'hachassiez',
    'hachements',
    'hachereaux',
    'hacherions',
    'hachurâmes',
    'hachurasse',
    'hachurâtes',
    'hachurerai',
    'hachureras',
    'hachurerez',
    'hachurions',
    'haïssables',
    'haïssaient',
    'haïtiennes',
    'halassions',
    'hâlassions',
    'haleraient',
    'hâleraient',
    'haletaient',
    'haletantes',
    'haletasses',
    'halètement',
    'halèterais',
    'halèterait',
    'haletèrent',
    'halèteriez',
    'halèterons',
    'halèteront',
    'half-track',
    'haliotides',
    'halitueuse',
    'hallebarde',
    'halloweens',
    'hallucinai',
    'hallucinas',
    'hallucinât',
    'hallucinée',
    'halluciner',
    'hallucines',
    'hallucinés',
    'hallucinez',
    'halogénure',
    'halophiles',
    'halophytes',
    'hamadryade',
    'hamburgers',
    'hameçonnai',
    'hameçonnas',
    'hameçonnât',
    'hameçonnée',
    'hameçonner',
    'hameçonnes',
    'hameçonnés',
    'hameçonnez',
    'hammerless',
    'hanchaient',
    'hanchasses',
    'hanchement',
    'hancherais',
    'hancherait',
    'hanchèrent',
    'hancheriez',
    'hancherons',
    'hancheront',
    'handicapai',
    'handicapas',
    'handicapât',
    'handicapée',
    'handicaper',
    'handicapes',
    'handicapés',
    'handicapez',
    'handisport',
    'hannetonna',
    'hannetonne',
    'hannetonné',
    'hantassent',
    'hantassiez',
    'hanterions',
    'haplologie',
    'happassent',
    'happassiez',
    'happements',
    'happenings',
    'happerions',
    'haquebutes',
    'haranguais',
    'haranguait',
    'haranguant',
    'haranguées',
    'haranguent',
    'haranguera',
    'harangueur',
    'haranguiez',
    'haranguons',
    'harassâmes',
    'harassante',
    'harassants',
    'harassasse',
    'harassâtes',
    'harasserai',
    'harasseras',
    'harasserez',
    'harassions',
    'harcelâmes',
    'harcelante',
    'harcelants',
    'harcelasse',
    'harcelâtes',
    'harcèlerai',
    'harcèleras',
    'harcèlerez',
    'harcelions',
    'harcellent',
    'harcellera',
    'hardassent',
    'hardassiez',
    'harderions',
    'hardiesses',
    'harengères',
    'harenguets',
    'harengueux',
    'harenguier',
    'hargneuses',
    'haridelles',
    'harmattans',
    'harmonicas',
    'harmonieux',
    'harmonique',
    'harmonisai',
    'harmonisas',
    'harmonisât',
    'harmonisée',
    'harmoniser',
    'harmonises',
    'harmonisés',
    'harmonisez',
    'harmoniums',
    'harnachais',
    'harnachait',
    'harnachant',
    'harnachées',
    'harnachent',
    'harnachera',
    'harnacheur',
    'harnachiez',
    'harnachons',
    'harpaillai',
    'harpaillas',
    'harpaillât',
    'harpaillée',
    'harpailler',
    'harpailles',
    'harpaillés',
    'harpaillez',
    'harpassent',
    'harpassiez',
    'harperions',
    'harponnage',
    'harponnais',
    'harponnait',
    'harponnant',
    'harponnées',
    'harponnent',
    'harponnera',
    'harponneur',
    'harponniez',
    'harponnons',
    'haruspices',
    'hasardâmes',
    'hasardasse',
    'hasardâtes',
    'hasarderai',
    'hasarderas',
    'hasarderez',
    'hasardeuse',
    'hasardions',
    'haschischs',
    'hassidisme',
    'hâtassions',
    'hâtelettes',
    'hâteraient',
    'hâtivement',
    'haubanages',
    'haubanâmes',
    'haubanasse',
    'haubanâtes',
    'haubanerai',
    'haubaneras',
    'haubanerez',
    'haubanions',
    'haussaient',
    'haussasses',
    'hausse-col',
    'haussement',
    'hausserais',
    'hausserait',
    'haussèrent',
    'hausseriez',
    'hausserons',
    'hausseront',
    'haussières',
    'hautboïste',
    'hauturière',
    'hauturiers',
    'havanaises',
    'havassions',
    'haveraient',
    'haviraient',
    'havissions',
    'hawaïennes',
    'hébergeais',
    'hébergeait',
    'hébergeant',
    'hébergeons',
    'hébergerai',
    'hébergeras',
    'hébergerez',
    'hébergions',
    'hébertisme',
    'hébétaient',
    'hébétasses',
    'hébétement',
    'hébéterais',
    'hébéterait',
    'hébétèrent',
    'hébéteriez',
    'hébéterons',
    'hébéteront',
    'hébraïques',
    'hébraïsais',
    'hébraïsait',
    'hébraïsant',
    'hébraïsent',
    'hébraïsera',
    'hébraïsiez',
    'hébraïsmes',
    'hébraïsons',
    'hécatombes',
    'hectolitre',
    'hectomètre',
    'hectowatts',
    'hédonismes',
    'hédonistes',
    'hégélienne',
    'hégémonies',
    'hélassions',
    'héleraient',
    'hélianthes',
    'hélicoïdal',
    'hélicoïdes',
    'héliomarin',
    'héliostats',
    'héliotrope',
    'héliportée',
    'héliportés',
    'hellébores',
    'hellénique',
    'hellénisai',
    'hellénisas',
    'hellénisât',
    'hellénisée',
    'helléniser',
    'hellénises',
    'hellénisés',
    'hellénisez',
    'hellénisme',
    'helléniste',
    'helminthes',
    'helvétique',
    'helvétisme',
    'hématémèse',
    'hématiques',
    'hématuries',
    'héméralope',
    'hémialgies',
    'hémicrânie',
    'hémicycles',
    'hémiédries',
    'hémiplégie',
    'hémisphère',
    'hémistiche',
    'hémitropie',
    'hémogénies',
    'hémogramme',
    'hémolysine',
    'hémopathie',
    'hémophiles',
    'hémophilie',
    'hémoptysie',
    'hémorragie',
    'hémorroïde',
    'hémostases',
    'hennirions',
    'hennissais',
    'hennissait',
    'hennissant',
    'hennissent',
    'hennissiez',
    'hennissons',
    'hépatalgie',
    'hépatiques',
    'hépatismes',
    'hépatocèle',
    'heptaèdres',
    'heptagonal',
    'heptagones',
    'heptamètre',
    'héraldique',
    'héraldiste',
    'herbageais',
    'herbageait',
    'herbageant',
    'herbageons',
    'herbagerai',
    'herbageras',
    'herbagerez',
    'herbagions',
    'herbassent',
    'herbassiez',
    'herberions',
    'herbicides',
    'herbivores',
    'herborisai',
    'herborisas',
    'herborisât',
    'herborisée',
    'herboriser',
    'herborises',
    'herborisés',
    'herborisez',
    'herboriste',
    'herculéens',
    'hercyniens',
    'herd-books',
    'hérétiques',
    'hérissâmes',
    'hérissasse',
    'hérissâtes',
    'hérisserai',
    'hérisseras',
    'hérisserez',
    'hérissions',
    'hérissonna',
    'hérissonne',
    'hérissonné',
    'héritaient',
    'héritasses',
    'hériterais',
    'hériterait',
    'héritèrent',
    'hériteriez',
    'hériterons',
    'hériteront',
    'héritières',
    'hermandads',
    'hermétique',
    'hermétisme',
    'herminette',
    'herniaires',
    'hernieuses',
    'héronneaux',
    'héronnière',
    'herpétique',
    'hersassent',
    'hersassiez',
    'herschages',
    'herscheurs',
    'herscheuse',
    'herserions',
    'hésitaient',
    'hésitantes',
    'hésitasses',
    'hésitation',
    'hésiterais',
    'hésiterait',
    'hésitèrent',
    'hésiteriez',
    'hésiterons',
    'hésiteront',
    'hétérodoxe',
    'hétérodyne',
    'hétérogène',
    'heurtaient',
    'heurtasses',
    'heurterais',
    'heurterait',
    'heurtèrent',
    'heurteriez',
    'heurterons',
    'heurteront',
    'hexacordes',
    'hexagonale',
    'hexagonaux',
    'hexamètres',
    'hibernales',
    'hibernâmes',
    'hibernante',
    'hibernants',
    'hibernasse',
    'hibernâtes',
    'hibernerai',
    'hiberneras',
    'hibernerez',
    'hibernions',
    'hiérarchie',
    'hiératique',
    'hiératisme',
    'hilarantes',
    'himalayens',
    'hindouisme',
    'hindouiste',
    'hinterland',
    'hipparchie',
    'hipparions',
    'hipparques',
    'hippiatres',
    'hippiatrie',
    'hippocampe',
    'hippodrome',
    'hippologie',
    'hippurique',
    'hirondeaux',
    'hirondelle',
    'hirsutisme',
    'hispanique',
    'hispanisme',
    'hissassent',
    'hissassiez',
    'hisserions',
    'histamines',
    'histidines',
    'histologie',
    'histolyses',
    'historiais',
    'historiait',
    'historiant',
    'historiées',
    'historiens',
    'historient',
    'historiera',
    'historiiez',
    'historions',
    'historique',
    'historisme',
    'hitlériens',
    'hitlérisme',
    'hit-parade',
    'hivernages',
    'hivernales',
    'hivernâmes',
    'hivernante',
    'hivernants',
    'hivernasse',
    'hivernâtes',
    'hivernerai',
    'hiverneras',
    'hivernerez',
    'hivernions',
    'hochassent',
    'hochassiez',
    'hochements',
    'hochequeue',
    'hocherions',
    'hockeyeurs',
    'hockeyeuse',
    'hollandais',
    'holocauste',
    'hologramme',
    'holographe',
    'holothurie',
    'homarderie',
    'homéopathe',
    'homéostats',
    'homériques',
    'hominisées',
    'homocerque',
    'homodontes',
    'homofocale',
    'homofocaux',
    'homographe',
    'homogreffe',
    'homologies',
    'homologuai',
    'homologuas',
    'homologuât',
    'homologuée',
    'homologuer',
    'homologues',
    'homologués',
    'homologuez',
    'homonymies',
    'homophones',
    'homophonie',
    'homosexuel',
    'homuncules',
    'hongraient',
    'hongrasses',
    'hongrerais',
    'hongrerait',
    'hongrèrent',
    'hongreriez',
    'hongrerons',
    'hongreront',
    'hongroient',
    'hongroiera',
    'hongroises',
    'hongroyage',
    'hongroyais',
    'hongroyait',
    'hongroyant',
    'hongroyées',
    'hongroyeur',
    'hongroyiez',
    'hongroyons',
    'honnêtetés',
    'honnirions',
    'honnissais',
    'honnissait',
    'honnissant',
    'honnissent',
    'honnissiez',
    'honnissons',
    'honorables',
    'honoraient',
    'honoraires',
    'honorariat',
    'honorasses',
    'honorerais',
    'honorerait',
    'honorèrent',
    'honoreriez',
    'honorerons',
    'honoreront',
    'hoquetâmes',
    'hoquetasse',
    'hoquetâtes',
    'hoquetions',
    'hoquettent',
    'hoquettera',
    'horizontal',
    'horlogères',
    'horlogerie',
    'hormonales',
    'hormonâmes',
    'hormonasse',
    'hormonâtes',
    'hormonerai',
    'hormoneras',
    'hormonerez',
    'hormonions',
    'hornblende',
    'horodatées',
    'horodateur',
    'horoscopes',
    'horrifiais',
    'horrifiait',
    'horrifiant',
    'horrifiées',
    'horrifient',
    'horrifiera',
    'horrifiiez',
    'horrifions',
    'horrifique',
    'horripilai',
    'horripilas',
    'horripilât',
    'horripilée',
    'horripiler',
    'horripiles',
    'horripilés',
    'horripilez',
    'hors-ligne',
    'hors-piste',
    'hors-taxes',
    'hors-texte',
    'hortensias',
    'horticoles',
    'hostilités',
    'hôtel-Dieu',
    'hôtelières',
    'hôtellerie',
    'hottentote',
    'hottentots',
    'hottereaux',
    'houassions',
    'houblonnai',
    'houblonnas',
    'houblonnât',
    'houblonnée',
    'houblonner',
    'houblonnes',
    'houblonnés',
    'houblonnez',
    'houeraient',
    'houillères',
    'houppaient',
    'houppasses',
    'houpperais',
    'houpperait',
    'houppèrent',
    'houpperiez',
    'houpperons',
    'houpperont',
    'houppettes',
    'hourdaient',
    'hourdasses',
    'hourderais',
    'hourderait',
    'hourdèrent',
    'hourderiez',
    'hourderons',
    'hourderont',
    'hourdirais',
    'hourdirait',
    'hourdirent',
    'hourdiriez',
    'hourdirons',
    'hourdiront',
    'hourdisses',
    'hourdissez',
    'houspillai',
    'houspillas',
    'houspillât',
    'houspillée',
    'houspiller',
    'houspilles',
    'houspillés',
    'houspillez',
    'houssaient',
    'houssasses',
    'housserais',
    'housserait',
    'houssèrent',
    'housseriez',
    'housserons',
    'housseront',
    'hovercraft',
    'huchassent',
    'huchassiez',
    'hucherions',
    'huguenotes',
    'huilassent',
    'huilassiez',
    'huilerions',
    'huisseries',
    'huîtrières',
    'hululaient',
    'hululasses',
    'hululement',
    'hululerais',
    'hululerait',
    'hululèrent',
    'hululeriez',
    'hululerons',
    'hululeront',
    'humanisais',
    'humanisait',
    'humanisant',
    'humanisées',
    'humanisent',
    'humanisera',
    'humanisiez',
    'humanismes',
    'humanisons',
    'humanistes',
    'humanoïdes',
    'humassions',
    'humblement',
    'humectages',
    'humectâmes',
    'humectasse',
    'humectâtes',
    'humecterai',
    'humecteras',
    'humecterez',
    'humections',
    'humeraient',
    'humidifiai',
    'humidifias',
    'humidifiât',
    'humidifiée',
    'humidifier',
    'humidifies',
    'humidifiés',
    'humidifiez',
    'humiliâmes',
    'humiliante',
    'humiliants',
    'humiliasse',
    'humiliâtes',
    'humilierai',
    'humilieras',
    'humilierez',
    'humiliions',
    'humorismes',
    'humoristes',
    'hurlassent',
    'hurlassiez',
    'hurlements',
    'hurlerions',
    'hurluberlu',
    'huronienne',
    'hurricanes',
    'hyacinthes',
    'hybridâmes',
    'hybridasse',
    'hybridâtes',
    'hybriderai',
    'hybrideras',
    'hybriderez',
    'hybridions',
    'hybridisme',
    'hybridités',
    'hydracides',
    'hydratable',
    'hydratâmes',
    'hydratante',
    'hydratants',
    'hydratasse',
    'hydratâtes',
    'hydraterai',
    'hydrateras',
    'hydraterez',
    'hydrations',
    'hydravions',
    'hydrazines',
    'hydrocèles',
    'hydrofoils',
    'hydrofugea',
    'hydrofugée',
    'hydrofuger',
    'hydrofuges',
    'hydrofugés',
    'hydrofugez',
    'hydrogénai',
    'hydrogénas',
    'hydrogénât',
    'hydrogénée',
    'hydrogéner',
    'hydrogénés',
    'hydrogènes',
    'hydrogénez',
    'hydrolases',
    'hydrolithe',
    'hydrologie',
    'hydrologue',
    'hydrolysai',
    'hydrolysas',
    'hydrolysât',
    'hydrolysée',
    'hydrolyser',
    'hydrolyses',
    'hydrolysés',
    'hydrolysez',
    'hydromètre',
    'hydrophile',
    'hydrophobe',
    'hydropique',
    'hydropisie',
    'hydroxydes',
    'Hygiaphone',
    'hygiénique',
    'hygiéniste',
    'hygromètre',
    'hygrophile',
    'hygrophobe',
    'hygroscope',
    'hylozoïsme',
    'hyoïdienne',
    'hypallages',
    'hyperactif',
    'hyperalgie',
    'hyperbates',
    'hyperboles',
    'hyperdulie',
    'hypermètre',
    'hypertélie',
    'hypertendu',
    'hypertonie',
    'hypnotique',
    'hypnotisai',
    'hypnotisas',
    'hypnotisât',
    'hypnotisée',
    'hypnotiser',
    'hypnotises',
    'hypnotisés',
    'hypnotisez',
    'hypnotisme',
    'hypocauste',
    'hypocentre',
    'hypochrome',
    'hypocondre',
    'hypocrisie',
    'hypocrites',
    'hypodermes',
    'hypogastre',
    'hypoglosse',
    'hypophyses',
    'hypostases',
    'hypostasia',
    'hypostasie',
    'hypostasié',
    'hypostyles',
    'hypotendue',
    'hypotendus',
    'hypoténuse',
    'hypothénar',
    'hypothéqua',
    'hypothéqué',
    'hypothèque',
    'hypothèses',
    'hypotonies',
    'hystérique',
    'iatrogènes',
    'icariennes',
    'ichneumons',
    'ichtyornis',
    'iconogènes',
    'iconolâtre',
    'iconologie',
    'iconoscope',
    'iconostase',
    'ictériques',
    'idéalement',
    'idéalisais',
    'idéalisait',
    'idéalisant',
    'idéalisées',
    'idéalisent',
    'idéalisera',
    'idéalisiez',
    'idéalismes',
    'idéalisons',
    'idéalistes',
    'idée-force',
    'identifiai',
    'identifias',
    'identifiât',
    'identifiée',
    'identifier',
    'identifies',
    'identifiés',
    'identifiez',
    'identiques',
    'idéogramme',
    'idéologies',
    'idéologisa',
    'idéologise',
    'idéologisé',
    'idéologues',
    'idiolectes',
    'idiopathie',
    'idiotement',
    'idiotifiai',
    'idiotifias',
    'idiotifiât',
    'idiotifiée',
    'idiotifier',
    'idiotifies',
    'idiotifiés',
    'idiotifiez',
    'idiotisais',
    'idiotisait',
    'idiotisant',
    'idiotisées',
    'idiotisent',
    'idiotisera',
    'idiotisiez',
    'idiotismes',
    'idiotisons',
    'idolâtrais',
    'idolâtrait',
    'idolâtrant',
    'idolâtrées',
    'idolâtrent',
    'idolâtrera',
    'idolâtries',
    'idolâtriez',
    'idolâtrons',
    'idylliques',
    'ignifugeai',
    'ignifugeas',
    'ignifugeât',
    'ignifugées',
    'ignifugent',
    'ignifugera',
    'ignifugiez',
    'ignominies',
    'ignoraient',
    'ignorances',
    'ignorantes',
    'ignorantin',
    'ignorasses',
    'ignorerais',
    'ignorerait',
    'ignorèrent',
    'ignoreriez',
    'ignorerons',
    'ignoreront',
    'iguanodons',
    'illégalité',
    'illégitime',
    'illettrées',
    'illimitées',
    'illisibles',
    'illogiques',
    'illogismes',
    'illuminais',
    'illuminait',
    'illuminant',
    'illuminées',
    'illuminent',
    'illuminera',
    'illuminiez',
    'illuminons',
    'illusionna',
    'illusionne',
    'illusionné',
    'illusoires',
    'illustrais',
    'illustrait',
    'illustrant',
    'illustrées',
    'illustrent',
    'illustrera',
    'illustriez',
    'illustrons',
    'imageaient',
    'imageasses',
    'imagerions',
    'imaginable',
    'imaginaire',
    'imaginâmes',
    'imaginasse',
    'imaginâtes',
    'imaginatif',
    'imaginerai',
    'imagineras',
    'imaginerez',
    'imaginions',
    'imbattable',
    'imbibaient',
    'imbibasses',
    'imbiberais',
    'imbiberait',
    'imbibèrent',
    'imbiberiez',
    'imbiberons',
    'imbiberont',
    'imbibition',
    'imbriquais',
    'imbriquait',
    'imbriquant',
    'imbriquées',
    'imbriquent',
    'imbriquera',
    'imbriquiez',
    'imbriquons',
    'imbroglios',
    'imbuvables',
    'imitassent',
    'imitassiez',
    'imitateurs',
    'imitations',
    'imitatives',
    'imitatrice',
    'imiterions',
    'immaculées',
    'immanences',
    'immanentes',
    'immariable',
    'immatériel',
    'immaturité',
    'immédiates',
    'immémorial',
    'immensités',
    'immergeais',
    'immergeait',
    'immergeant',
    'immergeons',
    'immergerai',
    'immergeras',
    'immergerez',
    'immergions',
    'imméritées',
    'immersions',
    'immersives',
    'immettable',
    'immigrâmes',
    'immigrante',
    'immigrants',
    'immigrasse',
    'immigrâtes',
    'immigrerai',
    'immigreras',
    'immigrerez',
    'immigrions',
    'imminences',
    'imminentes',
    'immisçâmes',
    'immisçâtes',
    'immiscerez',
    'immiscions',
    'immixtions',
    'immobilier',
    'immobilisa',
    'immobilise',
    'immobilisé',
    'immobilité',
    'immodérées',
    'immodestes',
    'immodestie',
    'immolaient',
    'immolasses',
    'immolation',
    'immolerais',
    'immolerait',
    'immolèrent',
    'immoleriez',
    'immolerons',
    'immoleront',
    'immondices',
    'immoralité',
    'immortelle',
    'immotivées',
    'immunisais',
    'immunisait',
    'immunisant',
    'immunisées',
    'immunisent',
    'immunisera',
    'immunisiez',
    'immunisons',
    'immunogène',
    'impactâmes',
    'impactasse',
    'impactâtes',
    'impacterai',
    'impacteras',
    'impacterez',
    'impactions',
    'impalpable',
    'impaludées',
    'impanation',
    'imparables',
    'imparfaite',
    'imparfaits',
    'impartiale',
    'impartiaux',
    'impartîmes',
    'impartirai',
    'impartiras',
    'impartirez',
    'impartisse',
    'impartîtes',
    'impassible',
    'impatience',
    'impatienta',
    'impatiente',
    'impatienté',
    'impatients',
    'impayables',
    'impeccable',
    'impédances',
    'impénitent',
    'impensable',
    'impératifs',
    'impérative',
    'imperdable',
    'impériales',
    'impérieuse',
    'impérities',
    'impétrâmes',
    'impétrante',
    'impétrants',
    'impétrasse',
    'impétrâtes',
    'impétrerai',
    'impétreras',
    'impétrerez',
    'impétrions',
    'impétueuse',
    'implacable',
    'implantais',
    'implantait',
    'implantant',
    'implantées',
    'implantent',
    'implantera',
    'implantiez',
    'implantons',
    'implémenta',
    'implémente',
    'implémenté',
    'implicites',
    'impliquais',
    'impliquait',
    'impliquant',
    'impliquées',
    'impliquent',
    'impliquera',
    'impliquiez',
    'impliquons',
    'implorâmes',
    'implorante',
    'implorants',
    'implorasse',
    'implorâtes',
    'implorerai',
    'imploreras',
    'implorerez',
    'implorions',
    'implosâmes',
    'implosasse',
    'implosâtes',
    'imploserai',
    'imploseras',
    'imploserez',
    'implosions',
    'implosives',
    'impluviums',
    'impoliment',
    'importable',
    'importâmes',
    'importance',
    'importante',
    'importants',
    'importasse',
    'importâtes',
    'importerai',
    'importeras',
    'importerez',
    'importions',
    'importunai',
    'importunas',
    'importunât',
    'importunée',
    'importuner',
    'importunes',
    'importunés',
    'importunez',
    'imposables',
    'imposaient',
    'imposantes',
    'imposasses',
    'imposerais',
    'imposerait',
    'imposèrent',
    'imposeriez',
    'imposerons',
    'imposeront',
    'imposition',
    'impossible',
    'imposteurs',
    'impostures',
    'impotences',
    'impotentes',
    'imprécises',
    'imprégnais',
    'imprégnait',
    'imprégnant',
    'imprégnées',
    'imprègnent',
    'imprégnera',
    'imprégniez',
    'imprégnons',
    'imprenable',
    'impresarii',
    'impresario',
    'imprésario',
    'impression',
    'imprimable',
    'imprimâmes',
    'imprimante',
    'imprimants',
    'imprimasse',
    'imprimâtes',
    'imprimatur',
    'imprimerai',
    'imprimeras',
    'imprimerez',
    'imprimerie',
    'imprimeurs',
    'imprimions',
    'improbable',
    'improbités',
    'impromptue',
    'impromptus',
    'improuvais',
    'improuvait',
    'improuvant',
    'improuvées',
    'improuvent',
    'improuvera',
    'improuviez',
    'improuvons',
    'improvisai',
    'improvisas',
    'improvisât',
    'improvisée',
    'improviser',
    'improvises',
    'improvisés',
    'improvisez',
    'improviste',
    'imprudence',
    'imprudente',
    'imprudents',
    'impubertés',
    'impudences',
    'impudentes',
    'impudicité',
    'impudiques',
    'impuissant',
    'impulsâmes',
    'impulsasse',
    'impulsâtes',
    'impulserai',
    'impulseras',
    'impulserez',
    'impulsions',
    'impulsives',
    'impunément',
    'imputables',
    'imputaient',
    'imputasses',
    'imputation',
    'imputerais',
    'imputerait',
    'imputèrent',
    'imputeriez',
    'imputerons',
    'imputeront',
    'inabritées',
    'inaccentué',
    'inaccompli',
    'inachevées',
    'inactivais',
    'inactivait',
    'inactivant',
    'inactivent',
    'inactivera',
    'inactiviez',
    'inactivité',
    'inactivons',
    'inactuelle',
    'inadaptées',
    'inadéquate',
    'inadéquats',
    'inaltérées',
    'inamicales',
    'inamovible',
    'inanitions',
    'inapaisées',
    'inaperçues',
    'inappliqué',
    'inapprécié',
    'inaptitude',
    'inarticulé',
    'inassouvie',
    'inassouvis',
    'inattendue',
    'inattendus',
    'inattentif',
    'inaudibles',
    'inaugurais',
    'inaugurait',
    'inaugurale',
    'inaugurant',
    'inauguraux',
    'inaugurées',
    'inaugurent',
    'inaugurera',
    'inauguriez',
    'inaugurons',
    'inavouable',
    'incapables',
    'incapacité',
    'incarcérai',
    'incarcéras',
    'incarcérât',
    'incarcérée',
    'incarcérer',
    'incarcérés',
    'incarcères',
    'incarcérez',
    'incarnadin',
    'incarnâmes',
    'incarnasse',
    'incarnates',
    'incarnâtes',
    'incarnerai',
    'incarneras',
    'incarnerez',
    'incarnions',
    'incartades',
    'incasiques',
    'incassable',
    'incendiais',
    'incendiait',
    'incendiant',
    'incendiées',
    'incendient',
    'incendiera',
    'incendiiez',
    'incendions',
    'incertaine',
    'incertains',
    'incessante',
    'incessants',
    'incessible',
    'incestueux',
    'inchangées',
    'inchoatifs',
    'inchoative',
    'incidences',
    'incidentes',
    'incinérais',
    'incinérait',
    'incinérant',
    'incinérées',
    'incinèrent',
    'incinérera',
    'incinériez',
    'incinérons',
    'incisaient',
    'incisasses',
    'inciserais',
    'inciserait',
    'incisèrent',
    'inciseriez',
    'inciserons',
    'inciseront',
    'incitaient',
    'incitantes',
    'incitasses',
    'incitateur',
    'incitatifs',
    'incitation',
    'incitative',
    'inciterais',
    'inciterait',
    'incitèrent',
    'inciteriez',
    'inciterons',
    'inciteront',
    'incivilité',
    'inciviques',
    'incivismes',
    'inclémence',
    'inclémente',
    'incléments',
    'inclinable',
    'inclinâmes',
    'inclinasse',
    'inclinâtes',
    'inclinerai',
    'inclineras',
    'inclinerez',
    'inclinions',
    'incluaient',
    'incluaisse',
    'inclurions',
    'inclusions',
    'inclusives',
    'inclussent',
    'inclussiez',
    'incognitos',
    'incohérent',
    'incollable',
    'incombâmes',
    'incombasse',
    'incombâtes',
    'incomberai',
    'incomberas',
    'incomberez',
    'incombions',
    'incommodai',
    'incommodas',
    'incommodât',
    'incommodée',
    'incommoder',
    'incommodes',
    'incommodés',
    'incommodez',
    'incomplète',
    'incomplets',
    'incomprise',
    'inconduite',
    'inconforts',
    'incongrues',
    'inconsolée',
    'inconsolés',
    'inconstant',
    'incontesté',
    'incontrôlé',
    'incorporai',
    'incorporas',
    'incorporât',
    'incorporée',
    'incorporel',
    'incorporer',
    'incorpores',
    'incorporés',
    'incorporez',
    'incorrecte',
    'incorrects',
    'incrédules',
    'incrémenta',
    'incrémente',
    'incrémenté',
    'incréments',
    'increvable',
    'incriminai',
    'incriminas',
    'incriminât',
    'incriminée',
    'incriminer',
    'incrimines',
    'incriminés',
    'incriminez',
    'incroyable',
    'incroyance',
    'incroyante',
    'incroyants',
    'incrustais',
    'incrustait',
    'incrustant',
    'incrustées',
    'incrustent',
    'incrustera',
    'incrusteur',
    'incrustiez',
    'incrustons',
    'incubaient',
    'incubasses',
    'incubateur',
    'incubation',
    'incuberais',
    'incuberait',
    'incubèrent',
    'incuberiez',
    'incuberons',
    'incuberont',
    'inculpable',
    'inculpâmes',
    'inculpasse',
    'inculpâtes',
    'inculperai',
    'inculperas',
    'inculperez',
    'inculpions',
    'inculquais',
    'inculquait',
    'inculquant',
    'inculquées',
    'inculquent',
    'inculquera',
    'inculquiez',
    'inculquons',
    'incultivée',
    'incultivés',
    'incultures',
    'incunables',
    'incurables',
    'incurieuse',
    'incursions',
    'incurvâmes',
    'incurvasse',
    'incurvâtes',
    'incurverai',
    'incurveras',
    'incurverez',
    'incurvions',
    'indatables',
    'indécences',
    'indécentes',
    'indécision',
    'indéfinies',
    'indélébile',
    'indélibéré',
    'indélicate',
    'indélicats',
    'indemnisai',
    'indemnisas',
    'indemnisât',
    'indemnisée',
    'indemniser',
    'indemnises',
    'indemnisés',
    'indemnisez',
    'indemnités',
    'indéniable',
    'indexaient',
    'indexasses',
    'indexation',
    'indexerais',
    'indexerait',
    'indexèrent',
    'indexeriez',
    'indexerons',
    'indexeront',
    'indianisme',
    'indianiste',
    'indiçaient',
    'indiçasses',
    'indicateur',
    'indicatifs',
    'indication',
    'indicative',
    'indicerais',
    'indicerait',
    'indicèrent',
    'indiceriez',
    'indicerons',
    'indiceront',
    'indiciaire',
    'indicibles',
    'indicielle',
    'indictions',
    'indifférai',
    'indifféras',
    'indifférât',
    'indifférée',
    'indifférer',
    'indifférés',
    'indiffères',
    'indifférez',
    'indigences',
    'indigentes',
    'indigestes',
    'indignâmes',
    'indignasse',
    'indignâtes',
    'indignerai',
    'indigneras',
    'indignerez',
    'indignions',
    'indignités',
    'indigotier',
    'indigotine',
    'indiquâmes',
    'indiquasse',
    'indiquâtes',
    'indiquerai',
    'indiqueras',
    'indiquerez',
    'indiquions',
    'indirectes',
    'indiscrète',
    'indiscrets',
    'indiscutée',
    'indiscutés',
    'indisposai',
    'indisposas',
    'indisposât',
    'indisposée',
    'indisposer',
    'indisposes',
    'indisposés',
    'indisposez',
    'indistinct',
    'individuel',
    'indivision',
    'indocilité',
    'indolences',
    'indolentes',
    'indomptées',
    'indonésien',
    'inducteurs',
    'inductions',
    'inductives',
    'inductrice',
    'induirions',
    'induisîmes',
    'induisions',
    'induisisse',
    'induisîtes',
    'indulgence',
    'indulgente',
    'indulgents',
    'induraient',
    'indurasses',
    'induration',
    'indurerais',
    'indurerait',
    'indurèrent',
    'indureriez',
    'indurerons',
    'indureront',
    'industriel',
    'industries',
    'inécoutées',
    'ineffables',
    'inefficace',
    'inégalable',
    'inégalités',
    'inélégance',
    'inélégante',
    'inélégants',
    'inéligible',
    'inemployée',
    'inemployés',
    'inéprouvée',
    'inéprouvés',
    'inépuisées',
    'inertielle',
    'inespérées',
    'inétendues',
    'inévitable',
    'inexécutée',
    'inexécutés',
    'inexercées',
    'inexistant',
    'inexorable',
    'inexpertes',
    'inexpiable',
    'inexpliqué',
    'inexploité',
    'inexplorée',
    'inexplorés',
    'inexprimée',
    'inexprimés',
    'infaisable',
    'infamantes',
    'infanterie',
    'infantiles',
    'infatuâmes',
    'infatuasse',
    'infatuâtes',
    'infatuerai',
    'infatueras',
    'infatuerez',
    'infatuions',
    'infécondes',
    'infectâmes',
    'infectante',
    'infectants',
    'infectasse',
    'infectâtes',
    'infecterai',
    'infecteras',
    'infecterez',
    'infectieux',
    'infections',
    'inféodâmes',
    'inféodasse',
    'inféodâtes',
    'inféoderai',
    'inféoderas',
    'inféoderez',
    'inféodions',
    'inféraient',
    'inférasses',
    'inférences',
    'inférerais',
    'inférerait',
    'inférèrent',
    'inféreriez',
    'inférerons',
    'inféreront',
    'inférieure',
    'inférieurs',
    'infernales',
    'infertiles',
    'infestâmes',
    'infestasse',
    'infestâtes',
    'infesterai',
    'infesteras',
    'infesterez',
    'infestions',
    'infidélité',
    'infiltrais',
    'infiltrait',
    'infiltrant',
    'infiltrées',
    'infiltrent',
    'infiltrera',
    'infiltriez',
    'infiltrons',
    'infiniment',
    'infinitifs',
    'infinitive',
    'infinitude',
    'infirmâmes',
    'infirmasse',
    'infirmâtes',
    'infirmatif',
    'infirmerai',
    'infirmeras',
    'infirmerez',
    'infirmerie',
    'infirmière',
    'infirmiers',
    'infirmions',
    'infirmités',
    'inflations',
    'infléchies',
    'infléchira',
    'inflexible',
    'inflexions',
    'infligeais',
    'infligeait',
    'infligeant',
    'infligeons',
    'infligerai',
    'infligeras',
    'infligerez',
    'infligions',
    'influaient',
    'influasses',
    'influençai',
    'influenças',
    'influençât',
    'influencée',
    'influencer',
    'influences',
    'influencés',
    'influencez',
    'influentes',
    'influenzas',
    'influerais',
    'influerait',
    'influèrent',
    'influeriez',
    'influerons',
    'influeront',
    'informâmes',
    'informasse',
    'informâtes',
    'informatif',
    'informelle',
    'informerai',
    'informeras',
    'informerez',
    'informions',
    'informulée',
    'informulés',
    'infortunée',
    'infortunes',
    'infortunés',
    'infraction',
    'infrarouge',
    'infumables',
    'infusaient',
    'infusasses',
    'infuserais',
    'infuserait',
    'infusèrent',
    'infuseriez',
    'infuserons',
    'infuseront',
    'infusibles',
    'infusoires',
    'ingagnable',
    'ingéniâmes',
    'ingéniâtes',
    'ingénierez',
    'ingénierie',
    'ingénieurs',
    'ingénieuse',
    'ingéniions',
    'ingénuités',
    'ingénument',
    'ingéraient',
    'ingérasses',
    'ingérences',
    'ingérerais',
    'ingérerait',
    'ingérèrent',
    'ingéreriez',
    'ingérerons',
    'ingéreront',
    'ingestions',
    'ingrédient',
    'ingression',
    'inguinales',
    'ingurgitai',
    'ingurgitas',
    'ingurgitât',
    'ingurgitée',
    'ingurgiter',
    'ingurgites',
    'ingurgités',
    'ingurgitez',
    'inhabileté',
    'inhabilité',
    'inhabitées',
    'inhabituel',
    'inhalaient',
    'inhalasses',
    'inhalateur',
    'inhalation',
    'inhalerais',
    'inhalerait',
    'inhalèrent',
    'inhaleriez',
    'inhalerons',
    'inhaleront',
    'inhérences',
    'inhérentes',
    'inhibaient',
    'inhibasses',
    'inhiberais',
    'inhiberait',
    'inhibèrent',
    'inhiberiez',
    'inhiberons',
    'inhiberont',
    'inhibiteur',
    'inhibitifs',
    'inhibition',
    'inhibitive',
    'inhumaient',
    'inhumaines',
    'inhumanité',
    'inhumasses',
    'inhumation',
    'inhumerais',
    'inhumerait',
    'inhumèrent',
    'inhumeriez',
    'inhumerons',
    'inhumeront',
    'inimitable',
    'iniquement',
    'initiaient',
    'initialisa',
    'initialise',
    'initialisé',
    'initiasses',
    'initiateur',
    'initiation',
    'initiative',
    'initierais',
    'initierait',
    'initièrent',
    'initieriez',
    'initierons',
    'initieront',
    'injectable',
    'injectâmes',
    'injectasse',
    'injectâtes',
    'injecterai',
    'injecteras',
    'injecterez',
    'injecteurs',
    'injections',
    'injonctifs',
    'injonction',
    'injonctive',
    'injouables',
    'injuriâmes',
    'injuriasse',
    'injuriâtes',
    'injurierai',
    'injurieras',
    'injurierez',
    'injurieuse',
    'injuriions',
    'injustices',
    'injustifié',
    'inlassable',
    'innervâmes',
    'innervasse',
    'innervâtes',
    'innerverai',
    'innerveras',
    'innerverez',
    'innervions',
    'innocences',
    'innocentai',
    'innocentas',
    'innocentât',
    'innocentée',
    'innocenter',
    'innocentes',
    'innocentés',
    'innocentez',
    'innocuités',
    'innominées',
    'innommable',
    'innovaient',
    'innovasses',
    'innovateur',
    'innovation',
    'innoverais',
    'innoverait',
    'innovèrent',
    'innoveriez',
    'innoverons',
    'innoveront',
    'inobservée',
    'inobservés',
    'inoccupées',
    'inoculable',
    'inoculâmes',
    'inoculasse',
    'inoculâtes',
    'inoculerai',
    'inoculeras',
    'inoculerez',
    'inoculions',
    'inoffensif',
    'inondables',
    'inondaient',
    'inondasses',
    'inondation',
    'inonderais',
    'inonderait',
    'inondèrent',
    'inonderiez',
    'inonderons',
    'inonderont',
    'inopérable',
    'inopérante',
    'inopérants',
    'inopportun',
    'inorganisé',
    'inoxydable',
    'inquiétais',
    'inquiétait',
    'inquiétant',
    'inquiétées',
    'inquiètent',
    'inquiétera',
    'inquiétiez',
    'inquiétons',
    'inquiétude',
    'insalubres',
    'insatiable',
    'inscrirais',
    'inscrirait',
    'inscririez',
    'inscrirons',
    'inscriront',
    'inscrivais',
    'inscrivait',
    'inscrivant',
    'inscrivent',
    'inscriviez',
    'inscrivons',
    'insculpais',
    'insculpait',
    'insculpant',
    'insculpées',
    'insculpent',
    'insculpera',
    'insculpiez',
    'insculpons',
    'insécables',
    'insécurité',
    'inselbergs',
    'inséminais',
    'inséminait',
    'inséminant',
    'inséminées',
    'inséminent',
    'inséminera',
    'inséminiez',
    'inséminons',
    'insensible',
    'insérables',
    'inséraient',
    'insérasses',
    'insérerais',
    'insérerait',
    'insérèrent',
    'inséreriez',
    'insérerons',
    'inséreront',
    'insermenté',
    'insertions',
    'insidieuse',
    'insincères',
    'insinuâmes',
    'insinuante',
    'insinuants',
    'insinuasse',
    'insinuâtes',
    'insinuerai',
    'insinueras',
    'insinuerez',
    'insinuions',
    'insipidité',
    'insistâmes',
    'insistance',
    'insistante',
    'insistants',
    'insistasse',
    'insistâtes',
    'insisterai',
    'insisteras',
    'insisterez',
    'insistions',
    'insociable',
    'insolaient',
    'insolasses',
    'insolation',
    'insolences',
    'insolentes',
    'insolerais',
    'insolerait',
    'insolèrent',
    'insoleriez',
    'insolerons',
    'insoleront',
    'insolubles',
    'insolvable',
    'insomnieux',
    'insondable',
    'insonorisa',
    'insonorise',
    'insonorisé',
    'insonorité',
    'insouciant',
    'insoucieux',
    'insoumises',
    'inspectais',
    'inspectait',
    'inspectant',
    'inspectées',
    'inspectent',
    'inspectera',
    'inspecteur',
    'inspectiez',
    'inspection',
    'inspectons',
    'inspirâmes',
    'inspirante',
    'inspirants',
    'inspirasse',
    'inspirâtes',
    'inspirerai',
    'inspireras',
    'inspirerez',
    'inspirions',
    'installais',
    'installait',
    'installant',
    'installées',
    'installent',
    'installera',
    'installiez',
    'installons',
    'instamment',
    'instantané',
    'instaurais',
    'instaurait',
    'instaurant',
    'instaurées',
    'instaurent',
    'instaurera',
    'instauriez',
    'instaurons',
    'instiguais',
    'instiguait',
    'instiguant',
    'instiguées',
    'instiguent',
    'instiguera',
    'instiguiez',
    'instiguons',
    'instillais',
    'instillait',
    'instillant',
    'instillées',
    'instillent',
    'instillera',
    'instilliez',
    'instillons',
    'instinctif',
    'instituais',
    'instituait',
    'instituant',
    'instituées',
    'instituent',
    'instituera',
    'instituiez',
    'instituons',
    'institutes',
    'instructif',
    'instruirai',
    'instruiras',
    'instruirez',
    'instruises',
    'instruisez',
    'instruisis',
    'instruisit',
    'instruisît',
    'instruites',
    'instrument',
    'insufflais',
    'insufflait',
    'insufflant',
    'insufflées',
    'insufflent',
    'insufflera',
    'insuffliez',
    'insufflons',
    'insulaires',
    'insularité',
    'insulinase',
    'insultâmes',
    'insultante',
    'insultants',
    'insultasse',
    'insultâtes',
    'insulterai',
    'insulteras',
    'insulterez',
    'insulteurs',
    'insultions',
    'insurgeons',
    'insurgerez',
    'insurgions',
    'intactiles',
    'intaillais',
    'intaillait',
    'intaillant',
    'intaillent',
    'intaillera',
    'intailliez',
    'intaillons',
    'intangible',
    'intégrales',
    'intégrâmes',
    'intégrante',
    'intégrants',
    'intégrasse',
    'intégrâtes',
    'intégrerai',
    'intégreras',
    'intégrerez',
    'intégrions',
    'intégrisme',
    'intégriste',
    'intégrités',
    'intellects',
    'intempérie',
    'intemporel',
    'intenables',
    'intendance',
    'intendante',
    'intendants',
    'intensifia',
    'intensifie',
    'intensifié',
    'intensités',
    'intensives',
    'intentâmes',
    'intentasse',
    'intentâtes',
    'intenterai',
    'intenteras',
    'intenterez',
    'intentions',
    'interactif',
    'interallié',
    'interarmes',
    'intercalai',
    'intercalas',
    'intercalât',
    'intercalée',
    'intercaler',
    'intercales',
    'intercalés',
    'intercalez',
    'intercédai',
    'intercédas',
    'intercédât',
    'intercéder',
    'intercèdes',
    'intercédez',
    'intercepta',
    'intercepte',
    'intercepté',
    'interclubs',
    'interdîmes',
    'interdirai',
    'interdiras',
    'interdirez',
    'interdises',
    'interdisse',
    'interdites',
    'interdîtes',
    'intéressai',
    'intéressas',
    'intéressât',
    'intéressée',
    'intéresser',
    'intéresses',
    'intéressés',
    'intéressez',
    'interfaces',
    'interférai',
    'interféras',
    'interférât',
    'interférer',
    'interfères',
    'interférez',
    'interféron',
    'interfluve',
    'interfolia',
    'interfolie',
    'interfolié',
    'intérieure',
    'intérieurs',
    'interjetai',
    'interjetas',
    'interjetât',
    'interjetée',
    'interjeter',
    'interjetés',
    'interjetez',
    'interjette',
    'interligna',
    'interligne',
    'interligné',
    'interlocks',
    'interlopes',
    'interloqua',
    'interloque',
    'interloqué',
    'interludes',
    'intermèdes',
    'internâmes',
    'internasse',
    'internâtes',
    'internerai',
    'interneras',
    'internerez',
    'internions',
    'internonce',
    'interpella',
    'interpelle',
    'interpellé',
    'interphone',
    'interpolai',
    'interpolas',
    'interpolât',
    'interpolée',
    'interpoler',
    'interpoles',
    'interpolés',
    'interpolez',
    'interposai',
    'interposas',
    'interposât',
    'interposée',
    'interposer',
    'interposes',
    'interposés',
    'interposez',
    'interpréta',
    'interprété',
    'interprète',
    'interrègne',
    'interrogea',
    'interrogée',
    'interroger',
    'interroges',
    'interrogés',
    'interrogez',
    'interrompe',
    'interromps',
    'interrompt',
    'interrompu',
    'intersexué',
    'intersigne',
    'interstice',
    'intervalle',
    'intervenez',
    'intervenir',
    'intervenue',
    'intervenus',
    'interverti',
    'interviens',
    'intervient',
    'interviewa',
    'interviewe',
    'interviewé',
    'interviews',
    'interzonal',
    'interzones',
    'intestinal',
    'intestines',
    'intimaient',
    'intimasses',
    'intimation',
    'intimement',
    'intimerais',
    'intimerait',
    'intimèrent',
    'intimeriez',
    'intimerons',
    'intimeront',
    'intimidais',
    'intimidait',
    'intimidant',
    'intimidées',
    'intimident',
    'intimidera',
    'intimidiez',
    'intimidons',
    'intimismes',
    'intimistes',
    'intitulais',
    'intitulait',
    'intitulant',
    'intitulées',
    'intitulent',
    'intitulera',
    'intituliez',
    'intitulons',
    'intolérant',
    'intonation',
    'intoxicant',
    'intoxiquai',
    'intoxiquas',
    'intoxiquât',
    'intoxiquée',
    'intoxiquer',
    'intoxiques',
    'intoxiqués',
    'intoxiquez',
    'intrépides',
    'intrigante',
    'intrigants',
    'intriguais',
    'intriguait',
    'intriguant',
    'intriguées',
    'intriguent',
    'intriguera',
    'intriguiez',
    'intriguons',
    'intriquais',
    'intriquait',
    'intriquant',
    'intriquées',
    'intriquent',
    'intriquera',
    'intriquiez',
    'intriquons',
    'introduira',
    'introduire',
    'introduise',
    'introduite',
    'introduits',
    'intronisai',
    'intronisas',
    'intronisât',
    'intronisée',
    'introniser',
    'intronises',
    'intronisés',
    'intronisez',
    'introverti',
    'intrusions',
    'intubaient',
    'intubasses',
    'intubation',
    'intuberais',
    'intuberait',
    'intubèrent',
    'intuberiez',
    'intuberons',
    'intuberont',
    'intuitions',
    'intuitives',
    'inusuelles',
    'inutilisée',
    'inutilisés',
    'inutilités',
    'invaginais',
    'invaginait',
    'invaginant',
    'invaginées',
    'invaginent',
    'invaginera',
    'invaginiez',
    'invaginons',
    'invaincues',
    'invalidais',
    'invalidait',
    'invalidant',
    'invalidées',
    'invalident',
    'invalidera',
    'invalidiez',
    'invalidité',
    'invalidons',
    'invariable',
    'invariante',
    'invariants',
    'invectivai',
    'invectivas',
    'invectivât',
    'invectivée',
    'invectiver',
    'invectives',
    'invectivés',
    'invectivez',
    'invendable',
    'inventaire',
    'inventâmes',
    'inventasse',
    'inventâtes',
    'inventerai',
    'inventeras',
    'inventerez',
    'inventeurs',
    'inventions',
    'inventives',
    'inventoria',
    'inventorie',
    'inventorié',
    'inventrice',
    'inversable',
    'inversâmes',
    'inversasse',
    'inversâtes',
    'inverserai',
    'inverseras',
    'inverserez',
    'inverseurs',
    'inversible',
    'inversions',
    'inversives',
    'invertases',
    'invertébré',
    'invertîmes',
    'invertines',
    'invertirai',
    'invertiras',
    'invertirez',
    'invertisse',
    'invertîtes',
    'investîmes',
    'investirai',
    'investiras',
    'investirez',
    'investisse',
    'investîtes',
    'invétérées',
    'invétériez',
    'invétérons',
    'invincible',
    'inviolable',
    'invisibles',
    'invitaient',
    'invitantes',
    'invitasses',
    'invitation',
    'inviterais',
    'inviterait',
    'invitèrent',
    'inviteriez',
    'inviterons',
    'inviteront',
    'invivables',
    'invocation',
    'involucrée',
    'involucrés',
    'involutées',
    'invoquâmes',
    'invoquasse',
    'invoquâtes',
    'invoquerai',
    'invoqueras',
    'invoquerez',
    'invoquions',
    'iodassions',
    'ioderaient',
    'iodlassent',
    'iodlassiez',
    'iodlerions',
    'iodoformes',
    'ionisaient',
    'ionisantes',
    'ionisasses',
    'ionisation',
    'ioniserais',
    'ioniserait',
    'ionisèrent',
    'ioniseriez',
    'ioniserons',
    'ioniseront',
    'ionosphère',
    'iotacismes',
    'ioulassent',
    'ioulassiez',
    'ioulerions',
    'irakiennes',
    'iraniennes',
    'iraqiennes',
    'irascibles',
    'iridescent',
    'iridiennes',
    'irisassent',
    'irisassiez',
    'irisations',
    'iriserions',
    'irlandaise',
    'ironisâmes',
    'ironisasse',
    'ironisâtes',
    'ironiserai',
    'ironiseras',
    'ironiserez',
    'ironisions',
    'iroquoises',
    'irradiâmes',
    'irradiante',
    'irradiants',
    'irradiasse',
    'irradiâtes',
    'irradierai',
    'irradieras',
    'irradierez',
    'irradiions',
    'irraisonné',
    'irréalisée',
    'irréalisés',
    'irréalisme',
    'irréaliste',
    'irréalités',
    'irréfléchi',
    'irréfutées',
    'irrégulier',
    'irréligion',
    'irrésolues',
    'irrespects',
    'irrigables',
    'irrigation',
    'irriguâmes',
    'irriguasse',
    'irriguâtes',
    'irriguerai',
    'irrigueras',
    'irriguerez',
    'irriguions',
    'irritables',
    'irritaient',
    'irritantes',
    'irritasses',
    'irritatifs',
    'irritation',
    'irritative',
    'irriterais',
    'irriterait',
    'irritèrent',
    'irriteriez',
    'irriterons',
    'irriteront',
    'irruptions',
    'isallobare',
    'ischémique',
    'islamiques',
    'islamisais',
    'islamisait',
    'islamisant',
    'islamisées',
    'islamisent',
    'islamisera',
    'islamisiez',
    'islamismes',
    'islamisons',
    'islamistes',
    'islandaise',
    'isochrones',
    'isoclinale',
    'isoclinaux',
    'isodynamie',
    'isoglosses',
    'isolassent',
    'isolassiez',
    'isolateurs',
    'isolations',
    'isolements',
    'isolerions',
    'isoséistes',
    'isostasies',
    'isothermes',
    'isotonique',
    'isotopique',
    'israéliens',
    'israélites',
    'isthmiques',
    'italianisa',
    'italianise',
    'italianisé',
    'italiennes',
    'itérassent',
    'itérassiez',
    'itérations',
    'itératives',
    'itérerions',
    'itinéraire',
    'itinérante',
    'itinérants',
    'ivoireries',
    'ivoirienne',
    'ivoirières',
    'ivrognerie',
    'jablassent',
    'jablassiez',
    'jablerions',
    'jaborandis',
    'jabotaient',
    'jabotasses',
    'jaboterais',
    'jaboterait',
    'jabotèrent',
    'jaboteriez',
    'jaboterons',
    'jaboteront',
    'jacarandas',
    'jacassâmes',
    'jacassante',
    'jacassants',
    'jacassasse',
    'jacassâtes',
    'jacasserai',
    'jacasseras',
    'jacasserez',
    'jacasserie',
    'jacasseurs',
    'jacasseuse',
    'jacassions',
    'jacqueries',
    'jactassent',
    'jactassiez',
    'jacterions',
    'jaillirais',
    'jaillirait',
    'jaillirent',
    'jailliriez',
    'jaillirons',
    'jailliront',
    'jaillisses',
    'jaillissez',
    'jalonnâmes',
    'jalonnasse',
    'jalonnâtes',
    'jalonnerai',
    'jalonneras',
    'jalonnerez',
    'jalonneurs',
    'jalonnions',
    'jalousâmes',
    'jalousasse',
    'jalousâtes',
    'jalouserai',
    'jalouseras',
    'jalouserez',
    'jalousions',
    'jamaïquain',
    'jambonneau',
    'jambosiers',
    'jamerosier',
    'janissaire',
    'janotismes',
    'jansénisme',
    'janséniste',
    'japonaises',
    'japoneries',
    'japonisant',
    'japonismes',
    'japonistes',
    'japonnâmes',
    'japonnasse',
    'japonnâtes',
    'japonnerai',
    'japonneras',
    'japonnerez',
    'japonnions',
    'jappassent',
    'jappassiez',
    'jappements',
    'japperions',
    'jaquemarts',
    'jardinages',
    'jardinâmes',
    'jardinasse',
    'jardinâtes',
    'jardinerai',
    'jardineras',
    'jardinerez',
    'jardineuse',
    'jardinière',
    'jardiniers',
    'jardinions',
    'jardiniste',
    'jaretterai',
    'jaretteras',
    'jaretterez',
    'jargonnais',
    'jargonnait',
    'jargonnant',
    'jargonnent',
    'jargonnera',
    'jargonniez',
    'jargonnons',
    'jarnicoton',
    'jarretâmes',
    'jarretasse',
    'jarretâtes',
    'jarretelle',
    'jarretière',
    'jarretions',
    'jarrettent',
    'jasassions',
    'jaseraient',
    'jaspassent',
    'jaspassiez',
    'jasperions',
    'jaspinâmes',
    'jaspinasse',
    'jaspinâtes',
    'jaspinerai',
    'jaspineras',
    'jaspinerez',
    'jaspinions',
    'jaugeaient',
    'jaugeasses',
    'jaugerions',
    'jaunirions',
    'jaunissage',
    'jaunissais',
    'jaunissait',
    'jaunissant',
    'jaunissent',
    'jaunissiez',
    'jaunissons',
    'javanaises',
    'javelaient',
    'javelasses',
    'javelèrent',
    'javeleuses',
    'javellerai',
    'javelleras',
    'javellerez',
    'javellisai',
    'javellisas',
    'javellisât',
    'javellisée',
    'javelliser',
    'javellises',
    'javellisés',
    'javellisez',
    'jeannettes',
    'jennériens',
    'jérémiades',
    'jerkassent',
    'jerkassiez',
    'jerkerions',
    'jerricanes',
    'jésuitique',
    'jésuitisme',
    'jetassions',
    'jetterions',
    'jeûnassent',
    'jeûnassiez',
    'jeûnerions',
    'joaillerie',
    'joaillière',
    'joailliers',
    'jobardâmes',
    'jobardasse',
    'jobardâtes',
    'jobarderai',
    'jobarderas',
    'jobarderez',
    'jobarderie',
    'jobardions',
    'jobardises',
    'jodlassent',
    'jodlassiez',
    'jodlerions',
    'johannique',
    'johannites',
    'joignaient',
    'joignirent',
    'joignisses',
    'joindrions',
    'jointoient',
    'jointoiera',
    'jointoyées',
    'jointoyeur',
    'jonçassent',
    'jonçassiez',
    'joncerions',
    'jonchaient',
    'jonchantes',
    'jonchasses',
    'joncherais',
    'joncherait',
    'jonchèrent',
    'joncheriez',
    'joncherons',
    'joncheront',
    'jonglaient',
    'jonglasses',
    'jonglerais',
    'jonglerait',
    'jonglèrent',
    'jongleries',
    'jongleriez',
    'jonglerons',
    'jongleront',
    'jongleuses',
    'jonquilles',
    'jontoyions',
    'jordaniens',
    'jottereaux',
    'jouaillais',
    'jouaillait',
    'jouaillant',
    'jouaillent',
    'jouaillera',
    'jouailliez',
    'jouaillons',
    'jouassions',
    'joueraient',
    'jouiraient',
    'jouissance',
    'jouissante',
    'jouissants',
    'jouisseurs',
    'jouisseuse',
    'jouissions',
    'jouissives',
    'journalier',
    'joutassent',
    'joutassiez',
    'jouterions',
    'jouvenceau',
    'jouxtaient',
    'jouxtasses',
    'jouxterais',
    'jouxterait',
    'jouxtèrent',
    'jouxteriez',
    'jouxterons',
    'jouxteront',
    'jovialités',
    'joyeusetés',
    'jubilaient',
    'jubilaires',
    'jubilasses',
    'jubilation',
    'jubilerais',
    'jubilerait',
    'jubilèrent',
    'jubileriez',
    'jubilerons',
    'jubileront',
    'juchassent',
    'juchassiez',
    'jucherions',
    'judaïcités',
    'judicature',
    'judiciaire',
    'judicieuse',
    'jugeassent',
    'jugeassiez',
    'jugeraient',
    'jugulaient',
    'jugulaires',
    'jugulantes',
    'jugulasses',
    'jugulerais',
    'jugulerait',
    'jugulèrent',
    'juguleriez',
    'jugulerons',
    'juguleront',
    'juke-boxes',
    'jumelaient',
    'jumelasses',
    'jumelèrent',
    'jumellerai',
    'jumelleras',
    'jumellerez',
    'junonienne',
    'jupitérien',
    'juponnâmes',
    'juponnasse',
    'juponnâtes',
    'juponnerai',
    'juponneras',
    'juponnerez',
    'juponnions',
    'jurassiens',
    'jurassions',
    'jurassique',
    'juratoires',
    'jureraient',
    'juridiques',
    'juridismes',
    'jusquiames',
    'justicière',
    'justiciers',
    'justifiais',
    'justifiait',
    'justifiant',
    'justifiées',
    'justifient',
    'justifiera',
    'justifiiez',
    'justifions',
    'jutassions',
    'juteraient',
    'juvénilité',
    'juxtaposai',
    'juxtaposas',
    'juxtaposât',
    'juxtaposée',
    'juxtaposer',
    'juxtaposes',
    'juxtaposés',
    'juxtaposez',
    'kafkaïenne',
    'kalicyties',
    'kangourous',
    'kantiennes',
    'karpatique',
    'karstiques',
    'keynésiens',
    'khâgneuses',
    'khédivales',
    'khédiviale',
    'khédiviats',
    'khédiviaux',
    'kibboutzim',
    'kichenotte',
    'kidnappage',
    'kidnappais',
    'kidnappait',
    'kidnappant',
    'kidnappées',
    'kidnappent',
    'kidnappera',
    'kidnappeur',
    'kidnappiez',
    'kidnapping',
    'kidnappons',
    'kieselguhr',
    'kiesérites',
    'kilocycles',
    'kilogramme',
    'kilométrai',
    'kilométras',
    'kilométrât',
    'kilométrée',
    'kilométrer',
    'kilométrés',
    'kilomètres',
    'kilométrez',
    'kilotonnes',
    'kinescopes',
    'klaxonnais',
    'klaxonnait',
    'klaxonnant',
    'klaxonnées',
    'klaxonnent',
    'klaxonnera',
    'klaxonniez',
    'klaxonnons',
    'kleptomane',
    'knock-down',
    'kolkhozien',
    'korriganes',
    'kymographe',
    'labialisai',
    'labialisas',
    'labialisât',
    'labialisée',
    'labialiser',
    'labialises',
    'labialisés',
    'labialisez',
    'laborantin',
    'laborieuse',
    'labourable',
    'labourages',
    'labourâmes',
    'labourasse',
    'labourâtes',
    'labourerai',
    'laboureras',
    'labourerez',
    'laboureurs',
    'labourions',
    'labyrinthe',
    'laçassions',
    'laccolites',
    'laccolithe',
    'laceraient',
    'lacéraient',
    'lacérasses',
    'lacération',
    'lacérerais',
    'lacérerait',
    'lacérèrent',
    'lacéreriez',
    'lacérerons',
    'lacéreront',
    'lâchassent',
    'lâchassiez',
    'lâcherions',
    'laconiques',
    'laconismes',
    'lacrymales',
    'lactations',
    'lactescent',
    'lactifères',
    'lactomètre',
    'lactosérum',
    'lacunaires',
    'lacuneuses',
    'là-dessous',
    'lagotriche',
    'lagunaires',
    'laïcisâmes',
    'laïcisasse',
    'laïcisâtes',
    'laïciserai',
    'laïciseras',
    'laïciserez',
    'laïcisions',
    'laieraient',
    'lainassent',
    'lainassiez',
    'lainerions',
    'laissaient',
    'laissasses',
    'laisserais',
    'laisserait',
    'laissèrent',
    'laisseriez',
    'laisserons',
    'laisseront',
    'laitonnais',
    'laitonnait',
    'laitonnant',
    'laitonnées',
    'laitonnent',
    'laitonnera',
    'laitonniez',
    'laitonnons',
    'laïusseurs',
    'laïusseuse',
    'lallations',
    'lamaseries',
    'lamassions',
    'lambinâmes',
    'lambinasse',
    'lambinâtes',
    'lambinerai',
    'lambineras',
    'lambinerez',
    'lambinions',
    'lambourdes',
    'lambrequin',
    'lambrissai',
    'lambrissas',
    'lambrissât',
    'lambrissée',
    'lambrisser',
    'lambrisses',
    'lambrissés',
    'lambrissez',
    'lambruches',
    'lambrusque',
    'lambswools',
    'lamellaire',
    'lamelleuse',
    'lamentable',
    'lamentâmes',
    'lamentasse',
    'lamentâtes',
    'lamenterai',
    'lamenteras',
    'lamenterez',
    'lamentions',
    'lameraient',
    'laminaient',
    'laminaires',
    'laminasses',
    'laminerais',
    'laminerait',
    'laminèrent',
    'lamineriez',
    'laminerons',
    'lamineront',
    'lamineuses',
    'lampadaire',
    'lampassées',
    'lampassent',
    'lampassiez',
    'lamperions',
    'lampourdes',
    'lamprillon',
    'lançassent',
    'lançassiez',
    'lancements',
    'lancéolées',
    'lancerions',
    'lancinâmes',
    'lancinante',
    'lancinants',
    'lancinasse',
    'lancinâtes',
    'lancinerai',
    'lancineras',
    'lancinerez',
    'lancinions',
    'landaulets',
    'landgraves',
    'landsturms',
    'langagière',
    'langagiers',
    'langeaient',
    'langeasses',
    'langerions',
    'langoureux',
    'langoustes',
    'languettes',
    'langueyage',
    'langueyais',
    'langueyait',
    'langueyant',
    'langueyées',
    'langueyent',
    'langueyera',
    'langueyiez',
    'langueyons',
    'languirais',
    'languirait',
    'languirent',
    'languiriez',
    'languirons',
    'languiront',
    'languisses',
    'languissez',
    'lansquenet',
    'lantaniers',
    'lanternais',
    'lanternait',
    'lanternant',
    'lanterneau',
    'lanternées',
    'lanternent',
    'lanternera',
    'lanterniez',
    'lanternons',
    'lanugineux',
    'laotiennes',
    'lapassions',
    'laperaient',
    'lapidaient',
    'lapidaires',
    'lapidasses',
    'lapidation',
    'lapiderais',
    'lapiderait',
    'lapidèrent',
    'lapideriez',
    'lapiderons',
    'lapideront',
    'lapinières',
    'lapinismes',
    'laquassent',
    'laquassiez',
    'laquerions',
    'lardassent',
    'lardassiez',
    'larderions',
    'lardonnais',
    'lardonnait',
    'lardonnant',
    'lardonnées',
    'lardonnent',
    'lardonnera',
    'lardonniez',
    'lardonnons',
    'larghettos',
    'larguaient',
    'larguasses',
    'larguerais',
    'larguerait',
    'larguèrent',
    'largueriez',
    'larguerons',
    'largueront',
    'larmoierai',
    'larmoieras',
    'larmoierez',
    'larmoyâmes',
    'larmoyante',
    'larmoyants',
    'larmoyasse',
    'larmoyâtes',
    'larmoyions',
    'larvicides',
    'laryngiens',
    'laryngites',
    'lascivetés',
    'lascivités',
    'lassassent',
    'lassassiez',
    'lasserions',
    'lassitudes',
    'latéralité',
    'laticifère',
    'laticlaves',
    'latifoliée',
    'latifoliés',
    'latinisais',
    'latinisait',
    'latinisant',
    'latinisées',
    'latinisent',
    'latinisera',
    'latinisiez',
    'latinismes',
    'latinisons',
    'latinistes',
    'lattassent',
    'lattassiez',
    'latterions',
    'laudateurs',
    'laudatives',
    'laudatrice',
    'lavallière',
    'lavandière',
    'lavassions',
    'lavatories',
    'lave-glace',
    'lave-linge',
    'lave-mains',
    'laveraient',
    'lawrencium',
    'layassions',
    'layeraient',
    'lazaristes',
    'leadership',
    'léchassent',
    'léchassiez',
    'lèchefrite',
    'lèchements',
    'lécherions',
    'lécithines',
    'légalement',
    'légalisais',
    'légalisait',
    'légalisant',
    'légalisées',
    'légalisent',
    'légalisera',
    'légalisiez',
    'légalismes',
    'légalisons',
    'légalistes',
    'légataires',
    'légendaire',
    'légèrement',
    'légiférais',
    'légiférait',
    'légiférant',
    'légifèrent',
    'légiférera',
    'légifériez',
    'légiférons',
    'législatif',
    'légitimais',
    'légitimait',
    'légitimant',
    'légitimées',
    'légitiment',
    'légitimera',
    'légitimiez',
    'légitimité',
    'légitimons',
    'léguassent',
    'léguassiez',
    'léguerions',
    'légumières',
    'leishmanie',
    'leitmotive',
    'leitmotivs',
    'lendemains',
    'lénifiâmes',
    'lénifiante',
    'lénifiants',
    'lénifiasse',
    'lénifiâtes',
    'lénifierai',
    'lénifieras',
    'lénifierez',
    'lénifiions',
    'léninismes',
    'lenticelle',
    'lentiforme',
    'lentigines',
    'lentillons',
    'lentisques',
    'léopardées',
    'lépidostée',
    'léprologie',
    'léprologue',
    'léproserie',
    'lésassions',
    'lesbiennes',
    'léseraient',
    'lésinaient',
    'lésinantes',
    'lésinasses',
    'lésinerais',
    'lésinerait',
    'lésinèrent',
    'lésineries',
    'lésineriez',
    'lésinerons',
    'lésineront',
    'lésineuses',
    'lésionnels',
    'lesquelles',
    'lessivable',
    'lessivages',
    'lessivâmes',
    'lessivasse',
    'lessivâtes',
    'lessiverai',
    'lessiveras',
    'lessiverez',
    'lessiveuse',
    'lessiviels',
    'lessivions',
    'lestassent',
    'lestassiez',
    'lesterions',
    'léthargies',
    'lettrismes',
    'leucémique',
    'leucocytes',
    'leucopénie',
    'leucorrhée',
    'leucotomie',
    'leurraient',
    'leurrasses',
    'leurrerais',
    'leurrerait',
    'leurrèrent',
    'leurreriez',
    'leurrerons',
    'leurreront',
    'levantines',
    'levassions',
    'lèveraient',
    'lévigation',
    'lévigeâmes',
    'lévigeasse',
    'lévigeâtes',
    'lévigerais',
    'lévigerait',
    'lévigèrent',
    'lévigeriez',
    'lévigerons',
    'lévigeront',
    'lévitation',
    'levrettais',
    'levrettait',
    'levrettant',
    'levrettées',
    'levrettent',
    'levrettera',
    'levrettiez',
    'levrettons',
    'lexicalisa',
    'lexicalise',
    'lexicalisé',
    'lézardâmes',
    'lézardasse',
    'lézardâtes',
    'lézarderai',
    'lézarderas',
    'lézarderez',
    'lézardions',
    'liaisonnai',
    'liaisonnas',
    'liaisonnât',
    'liaisonnée',
    'liaisonner',
    'liaisonnes',
    'liaisonnés',
    'liaisonnez',
    'liardaient',
    'liardasses',
    'liarderais',
    'liarderait',
    'liardèrent',
    'liarderiez',
    'liarderons',
    'liarderont',
    'libanaises',
    'libellâmes',
    'libellasse',
    'libellâtes',
    'libellerai',
    'libelleras',
    'libellerez',
    'libellions',
    'libelliste',
    'libellules',
    'libérables',
    'libéraient',
    'libéralisa',
    'libéralise',
    'libéralisé',
    'libéralité',
    'libérasses',
    'libérateur',
    'libération',
    'libérerais',
    'libérerait',
    'libérèrent',
    'libéreriez',
    'libérerons',
    'libéreront',
    'libérienne',
    'libertaire',
    'libertines',
    'libidinale',
    'libidinaux',
    'libidineux',
    'librairies',
    'librations',
    'licenciais',
    'licenciait',
    'licenciant',
    'licenciées',
    'licencient',
    'licenciera',
    'licencieux',
    'licenciiez',
    'licencions',
    'lichassent',
    'lichassiez',
    'licherions',
    'licitaient',
    'licitasses',
    'licitation',
    'licitement',
    'liciterais',
    'liciterait',
    'licitèrent',
    'liciteriez',
    'liciterons',
    'liciteront',
    'lie-de-vin',
    'liégeaient',
    'liégeasses',
    'liégeoises',
    'liégerions',
    'lieutenant',
    'lieux-dits',
    'liftassent',
    'liftassiez',
    'lifterions',
    'ligaturais',
    'ligaturait',
    'ligaturant',
    'ligaturées',
    'ligaturent',
    'ligaturera',
    'ligaturiez',
    'ligaturons',
    'lignassent',
    'lignassiez',
    'lignerions',
    'lignerolle',
    'lignicoles',
    'lignifiais',
    'lignifiait',
    'lignifiant',
    'lignifiées',
    'lignifient',
    'lignifiera',
    'lignifiiez',
    'lignifions',
    'lignomètre',
    'ligotaient',
    'ligotasses',
    'ligoterais',
    'ligoterait',
    'ligotèrent',
    'ligoteriez',
    'ligoterons',
    'ligoteront',
    'liguassent',
    'liguassiez',
    'liguerions',
    'limandâmes',
    'limandasse',
    'limandâtes',
    'limanderai',
    'limanderas',
    'limanderez',
    'limandions',
    'limassions',
    'limeraient',
    'limettiers',
    'liminaires',
    'limitables',
    'limitaient',
    'limitasses',
    'limitatifs',
    'limitation',
    'limitative',
    'limiterais',
    'limiterait',
    'limitèrent',
    'limiteriez',
    'limiterons',
    'limiteront',
    'limitrophe',
    'limnologie',
    'limogeages',
    'limogeâmes',
    'limogeasse',
    'limogeâtes',
    'limogerais',
    'limogerait',
    'limogèrent',
    'limogeriez',
    'limogerons',
    'limogeront',
    'limonadier',
    'limonaient',
    'limonaires',
    'limonasses',
    'limonerais',
    'limonerait',
    'limonèrent',
    'limoneriez',
    'limonerons',
    'limoneront',
    'limoneuses',
    'limonières',
    'limoselles',
    'limousinai',
    'limousinas',
    'limousinât',
    'limousinée',
    'limousiner',
    'limousines',
    'limousinés',
    'limousinez',
    'limpidités',
    'linéaments',
    'linéarités',
    'lingeaient',
    'lingeasses',
    'lingerions',
    'lingotière',
    'linguatule',
    'linguettes',
    'linguistes',
    'linnéennes',
    'linotypies',
    'lipochrome',
    'lipophiles',
    'lipothymie',
    'lipotropes',
    'lipovaccin',
    'liquations',
    'liquéfiais',
    'liquéfiait',
    'liquéfiant',
    'liquéfiées',
    'liquéfient',
    'liquéfiera',
    'liquéfiiez',
    'liquéfions',
    'liquidable',
    'liquidâmes',
    'liquidasse',
    'liquidâtes',
    'liquidatif',
    'liquiderai',
    'liquideras',
    'liquiderez',
    'liquidiens',
    'liquidions',
    'liquidités',
    'liquoreuse',
    'liquoriste',
    'liseraient',
    'liséraient',
    'liserasses',
    'lisérasses',
    'lisérerais',
    'lisèrerais',
    'lisérerait',
    'lisèrerait',
    'liserèrent',
    'lisérèrent',
    'liséreriez',
    'lisèreriez',
    'lisérerons',
    'lisèrerons',
    'liséreront',
    'lisèreront',
    'lisibilité',
    'lissassent',
    'lissassiez',
    'lisserions',
    'listassent',
    'listassiez',
    'listerions',
    'listériose',
    'litassions',
    'literaient',
    'lithodomes',
    'lithogènes',
    'lithologie',
    'lithophage',
    'litigieuse',
    'littéraire',
    'littérales',
    'littorales',
    'littorines',
    'lituaniens',
    'liturgique',
    'livraisons',
    'livrassent',
    'livrassiez',
    'livrerions',
    'livresques',
    'lobassions',
    'lobectomie',
    'loberaient',
    'lobotomies',
    'lobulaires',
    'lobuleuses',
    'localement',
    'localisais',
    'localisait',
    'localisant',
    'localisées',
    'localisent',
    'localisera',
    'localisiez',
    'localisons',
    'locataires',
    'lochassent',
    'lochassiez',
    'locherions',
    'lock-outai',
    'lock-outas',
    'lock-outât',
    'lock-outée',
    'lock-outer',
    'lock-outes',
    'lock-outés',
    'lock-outez',
    'locomobile',
    'locomoteur',
    'locomotifs',
    'locomotion',
    'locomotive',
    'loculaires',
    'loculeuses',
    'locutrices',
    'lofassions',
    'loferaient',
    'logarithme',
    'logeassent',
    'logeassiez',
    'logeraient',
    'logicielle',
    'logicienne',
    'logicismes',
    'logistique',
    'logographe',
    'logogriphe',
    'logomachie',
    'logopédies',
    'logorrhées',
    'lointaines',
    'lombalgies',
    'londoniens',
    'longeaient',
    'longeasses',
    'longerions',
    'longévités',
    'longicorne',
    'longiligne',
    'longitudes',
    'longuement',
    'longuettes',
    'longue-vue',
    'lophophore',
    'loquacités',
    'loquassent',
    'loquassiez',
    'loquerions',
    'loqueteaux',
    'loqueteuse',
    'lord-maire',
    'lorgnaient',
    'lorgnasses',
    'lorgnerais',
    'lorgnerait',
    'lorgnèrent',
    'lorgneriez',
    'lorgnerons',
    'lorgneront',
    'lorgnettes',
    'loricaires',
    'lotionnais',
    'lotionnait',
    'lotionnant',
    'lotionnées',
    'lotionnent',
    'lotionnera',
    'lotionniez',
    'lotionnons',
    'lotiraient',
    'lotisseurs',
    'lotisseuse',
    'lotissions',
    'louangeais',
    'louangeait',
    'louangeant',
    'louangeons',
    'louangerai',
    'louangeras',
    'louangerez',
    'louangeurs',
    'louangeuse',
    'louangions',
    'louassions',
    'louchaient',
    'louchasses',
    'loucherais',
    'loucherait',
    'louchèrent',
    'loucheries',
    'loucheriez',
    'loucherons',
    'loucheront',
    'loucheuses',
    'louchirais',
    'louchirait',
    'louchirent',
    'louchiriez',
    'louchirons',
    'louchiront',
    'louchisses',
    'louchissez',
    'loueraient',
    'loufassent',
    'loufassiez',
    'louferions',
    'loupassent',
    'loupassiez',
    'louperions',
    'loup-garou',
    'lourassent',
    'lourassiez',
    'lourdaient',
    'lourdasses',
    'lourdaudes',
    'lourdement',
    'lourderais',
    'lourderait',
    'lourdèrent',
    'lourderiez',
    'lourderons',
    'lourderont',
    'lourerions',
    'louvassent',
    'louvassiez',
    'louverions',
    'louvetâmes',
    'louvetasse',
    'louvetâtes',
    'louveteaux',
    'louveterie',
    'louvetiers',
    'louvetions',
    'louvettent',
    'louvettera',
    'louvoierai',
    'louvoieras',
    'louvoierez',
    'louvoyâmes',
    'louvoyante',
    'louvoyants',
    'louvoyasse',
    'louvoyâtes',
    'louvoyions',
    'lovassions',
    'loveraient',
    'loxodromie',
    'loyalement',
    'loyalismes',
    'loyalistes',
    'lubricités',
    'lubrifiais',
    'lubrifiait',
    'lubrifiant',
    'lubrifiées',
    'lubrifient',
    'lubrifiera',
    'lubrifiiez',
    'lubrifions',
    'lucernaire',
    'lucidement',
    'luciférien',
    'lucimètres',
    'lucratives',
    'ludothèque',
    'lugeassent',
    'lugeassiez',
    'lugeraient',
    'luisissent',
    'luisissiez',
    'lumachelle',
    'luminaires',
    'lumineuses',
    'luministes',
    'luminosité',
    'lunatiques',
    'lunchaient',
    'lunchasses',
    'luncherais',
    'luncherait',
    'lunchèrent',
    'luncheriez',
    'luncherons',
    'luncheront',
    'lunetières',
    'lunetterie',
    'lupercales',
    'lusitanien',
    'lustraient',
    'lustrantes',
    'lustrasses',
    'lustration',
    'lustrerais',
    'lustrerait',
    'lustrèrent',
    'lustreries',
    'lustreriez',
    'lustrerons',
    'lustreront',
    'lutassions',
    'luteraient',
    'luthériens',
    'lutinaient',
    'lutinasses',
    'lutinerais',
    'lutinerait',
    'lutinèrent',
    'lutineriez',
    'lutinerons',
    'lutineront',
    'luttassent',
    'luttassiez',
    'lutterions',
    'luxassions',
    'luxeraient',
    'luxuriance',
    'luxuriante',
    'luxuriants',
    'luxurieuse',
    'luzernière',
    'lycoperdon',
    'lymphocyte',
    'lymphoïdes',
    'lynchaient',
    'lynchasses',
    'lyncherais',
    'lyncherait',
    'lynchèrent',
    'lyncheriez',
    'lyncherons',
    'lyncheront',
    'lyncheuses',
    'lyonnaises',
    'lyophilisa',
    'lyophilise',
    'lyophilisé',
    'lysassions',
    'lyseraient',
    'lysergides',
    'lysergique',
    'lysimaques',
    'macadamisa',
    'macadamise',
    'macadamisé',
    'macchabées',
    'macédoines',
    'macédonien',
    'macéraient',
    'macérasses',
    'macérateur',
    'macération',
    'macérerais',
    'macérerait',
    'macérèrent',
    'macéreriez',
    'macérerons',
    'macéreront',
    'macfarlane',
    'mâchassent',
    'mâchassiez',
    'mâchements',
    'mâcherions',
    'machiavels',
    'machicotai',
    'machicotas',
    'machicotât',
    'machicoter',
    'machicotes',
    'machicotez',
    'machinales',
    'machinâmes',
    'machinasse',
    'machinâtes',
    'machinerai',
    'machineras',
    'machinerez',
    'machinerie',
    'machinisme',
    'machiniste',
    'machmètres',
    'mâchonnais',
    'mâchonnait',
    'mâchonnant',
    'mâchonnées',
    'mâchonnent',
    'mâchonnera',
    'mâchonniez',
    'mâchonnons',
    'mâchouilla',
    'mâchouille',
    'mâchurâmes',
    'mâchurasse',
    'mâchurâtes',
    'mâchurerai',
    'mâchureras',
    'mâchurerez',
    'mâchurions',
    'maclassent',
    'maclassiez',
    'maclerions',
    'maçonnâmes',
    'maçonnasse',
    'maçonnâtes',
    'maçonnerai',
    'maçonneras',
    'maçonnerez',
    'maçonnerie',
    'maçonnions',
    'maçonnique',
    'macquaient',
    'macquasses',
    'macquerais',
    'macquerait',
    'macquèrent',
    'macqueriez',
    'macquerons',
    'macqueront',
    'macrocosme',
    'macrocytes',
    'macropodes',
    'macrospore',
    'maculaient',
    'maculasses',
    'maculature',
    'maculerais',
    'maculerait',
    'maculèrent',
    'maculeriez',
    'maculerons',
    'maculeront',
    'madapolams',
    'madécasses',
    'madéfiâmes',
    'madéfiasse',
    'madéfiâtes',
    'madéfierai',
    'madéfieras',
    'madéfierez',
    'madéfiions',
    'madeleines',
    'madérisais',
    'madérisait',
    'madérisant',
    'madérisées',
    'madérisent',
    'madérisera',
    'madérisiez',
    'madérisons',
    'madrépores',
    'madrilènes',
    'maelströms',
    'magasinage',
    'magasinais',
    'magasinait',
    'magasinant',
    'magasinées',
    'magasinent',
    'magasinera',
    'magasinier',
    'magasiniez',
    'magasinons',
    'maghrébine',
    'maghrébins',
    'magicienne',
    'magistères',
    'magistrale',
    'magistrats',
    'magistraux',
    'magmatique',
    'magnanerie',
    'magnanière',
    'magnaniers',
    'magnanimes',
    'magnassent',
    'magnassiez',
    'magnerions',
    'magnésiens',
    'magnésites',
    'magnésiums',
    'magnétique',
    'magnétisai',
    'magnétisas',
    'magnétisât',
    'magnétisée',
    'magnétiser',
    'magnétises',
    'magnétisés',
    'magnétisez',
    'magnétisme',
    'magnétites',
    'magnétrons',
    'magnifiais',
    'magnifiait',
    'magnifiant',
    'magnificat',
    'magnifiées',
    'magnifient',
    'magnifiera',
    'magnifiiez',
    'magnifions',
    'magnifique',
    'magnitudes',
    'magouillai',
    'magouillée',
    'magouiller',
    'magouilles',
    'magouillés',
    'magouillez',
    'maharadjah',
    'maharajahs',
    'mahométane',
    'mahométans',
    'maïeutique',
    'maigrelets',
    'maigrement',
    'maigrichon',
    'maigriotte',
    'maigrirais',
    'maigrirait',
    'maigrirent',
    'maigririez',
    'maigrirons',
    'maigriront',
    'maigrisses',
    'maigrissez',
    'mail-coach',
    'maillaient',
    'maillasses',
    'maillerais',
    'maillerait',
    'maillèrent',
    'mailleriez',
    'maillerons',
    'mailleront',
    'mailletons',
    'mailloches',
    'maillotins',
    'main-forte',
    'mainlevées',
    'mainmortes',
    'maintenais',
    'maintenait',
    'maintenant',
    'mainteneur',
    'mainteniez',
    'maintenons',
    'maintenues',
    'maintienne',
    'maintînmes',
    'maintinsse',
    'maintîntes',
    'maisonnées',
    'maîtresses',
    'maîtrisais',
    'maîtrisait',
    'maîtrisant',
    'maîtrisées',
    'maîtrisent',
    'maîtrisera',
    'maîtrisiez',
    'maîtrisons',
    'majestueux',
    'majoliques',
    'majoraient',
    'majorantes',
    'majorasses',
    'majoration',
    'majordomes',
    'majorerais',
    'majorerait',
    'majorèrent',
    'majoreriez',
    'majorerons',
    'majoreront',
    'majorettes',
    'majuscules',
    'malachites',
    'maladrerie',
    'maladresse',
    'maladroite',
    'maladroits',
    'malandreux',
    'malandrins',
    'malapprise',
    'malavisées',
    'malaxaient',
    'malaxasses',
    'malaxerais',
    'malaxerait',
    'malaxèrent',
    'malaxeriez',
    'malaxerons',
    'malaxeront',
    'malchances',
    'malcommode',
    'maléfiques',
    'malentendu',
    'malfaisais',
    'malfaisait',
    'malfaisant',
    'malfaisiez',
    'malfaisons',
    'malfaiteur',
    'malfassent',
    'malfassiez',
    'malferions',
    'malfissent',
    'malfissiez',
    'malhabiles',
    'malheureux',
    'malhonnête',
    'malicieuse',
    'malignités',
    'malléables',
    'malmenâmes',
    'malmenasse',
    'malmenâtes',
    'malmènerai',
    'malmèneras',
    'malmènerez',
    'malmenions',
    'malodorant',
    'malpighies',
    'malpropres',
    'malséantes',
    'malsonnant',
    'maltassent',
    'maltassiez',
    'malterions',
    'malthusien',
    'maltraitai',
    'maltraitas',
    'maltraitât',
    'maltraitée',
    'maltraiter',
    'maltraites',
    'maltraités',
    'maltraitez',
    'malvoyante',
    'malvoyants',
    'mamelonnai',
    'mamelonnas',
    'mamelonnât',
    'mamelonnée',
    'mamelonner',
    'mamelonnes',
    'mamelonnés',
    'mamelonnez',
    'mameloukes',
    'mamillaire',
    'mammaliens',
    'mammalogie',
    'mammifères',
    'manageâmes',
    'manageasse',
    'manageâtes',
    'management',
    'managerais',
    'managerait',
    'managèrent',
    'manageriez',
    'managerons',
    'manageront',
    'mancenille',
    'mancherons',
    'manchettes',
    'manchonnai',
    'manchonnas',
    'manchonnât',
    'manchonnée',
    'manchonner',
    'manchonnes',
    'manchonnés',
    'manchonnez',
    'mandarinal',
    'mandarinat',
    'mandarines',
    'mandassent',
    'mandassiez',
    'mandataire',
    'mandatâmes',
    'mandatasse',
    'mandatâtes',
    'mandaterai',
    'mandateras',
    'mandaterez',
    'mandations',
    'mandchoues',
    'mandements',
    'manderions',
    'mandibules',
    'mandolines',
    'mandragore',
    'manégeâmes',
    'manégeasse',
    'manégeâtes',
    'manégerais',
    'manégerait',
    'manégèrent',
    'manégeriez',
    'manégerons',
    'manégeront',
    'manganates',
    'manganèses',
    'manganique',
    'manganites',
    'mangeables',
    'mangeaient',
    'mangeaille',
    'mangeasses',
    'mangeoires',
    'mangeottai',
    'mangeottas',
    'mangeottât',
    'mangeottée',
    'mangeotter',
    'mangeottes',
    'mangeottés',
    'mangeottez',
    'mangerions',
    'mange-tout',
    'mangoustan',
    'mangoustes',
    'maniassent',
    'maniassiez',
    'manichéens',
    'manicordes',
    'maniements',
    'maniérâmes',
    'maniérasse',
    'maniérâtes',
    'maniérerai',
    'maniéreras',
    'maniérerez',
    'manierions',
    'maniérions',
    'maniérisme',
    'maniériste',
    'manifestai',
    'manifestas',
    'manifestât',
    'manifestée',
    'manifester',
    'manifestes',
    'manifestés',
    'manifestez',
    'manigançai',
    'maniganças',
    'manigançât',
    'manigancée',
    'manigancer',
    'manigances',
    'manigancés',
    'manigancez',
    'maniguette',
    'manipulais',
    'manipulait',
    'manipulant',
    'manipulées',
    'manipulent',
    'manipulera',
    'manipuliez',
    'manipulons',
    'manivelles',
    'mannequina',
    'mannequine',
    'mannequiné',
    'mannequins',
    'manoeuvrai',
    'manoeuvras',
    'manoeuvrât',
    'manoeuvrée',
    'manoeuvrer',
    'manoeuvres',
    'manoeuvrés',
    'manoeuvrez',
    'manomètres',
    'manométrie',
    'manoquâmes',
    'manoquasse',
    'manoquâtes',
    'manoquerai',
    'manoqueras',
    'manoquerez',
    'manoquions',
    'manouvrier',
    'manquaient',
    'manquantes',
    'manquasses',
    'manquement',
    'manquerais',
    'manquerait',
    'manquèrent',
    'manqueriez',
    'manquerons',
    'manqueront',
    'mansardais',
    'mansardait',
    'mansardant',
    'mansardées',
    'mansardent',
    'mansardera',
    'mansardiez',
    'mansardons',
    'mansuétude',
    'mantelures',
    'manubriums',
    'manucurais',
    'manucurait',
    'manucurant',
    'manucurées',
    'manucurent',
    'manucurera',
    'manucuriez',
    'manucurons',
    'manuélines',
    'manuscrite',
    'manuscrits',
    'manuterges',
    'mappemonde',
    'maquassent',
    'maquassiez',
    'maquereaux',
    'maquerions',
    'maquignons',
    'maquillage',
    'maquillais',
    'maquillait',
    'maquillant',
    'maquillées',
    'maquillent',
    'maquillera',
    'maquilleur',
    'maquilliez',
    'maquillons',
    'maquisarde',
    'maquisards',
    'maraîchage',
    'maraîchère',
    'maraîchers',
    'maraîchine',
    'maraîchins',
    'marasquins',
    'maraudages',
    'maraudâmes',
    'maraudasse',
    'maraudâtes',
    'marauderai',
    'marauderas',
    'marauderez',
    'maraudeurs',
    'maraudeuse',
    'maraudions',
    'marbraient',
    'marbrasses',
    'marbrerais',
    'marbrerait',
    'marbrèrent',
    'marbreries',
    'marbreriez',
    'marbrerons',
    'marbreront',
    'marbreuses',
    'marbrières',
    'marcassins',
    'marcassite',
    'marcescent',
    'marchaient',
    'marchandai',
    'marchandas',
    'marchandât',
    'marchandée',
    'marchander',
    'marchandes',
    'marchandés',
    'marchandez',
    'marchantes',
    'marchantie',
    'marchasses',
    'marchéages',
    'marchepied',
    'marcherais',
    'marcherait',
    'marchèrent',
    'marcheriez',
    'marcherons',
    'marcheront',
    'marcheuses',
    'marcottage',
    'marcottais',
    'marcottait',
    'marcottant',
    'marcottées',
    'marcottent',
    'marcottera',
    'marcottiez',
    'marcottons',
    'marécageux',
    'maréchalat',
    'maréchales',
    'marégraphe',
    'marémoteur',
    'maréomètre',
    'mareyeuses',
    'margailles',
    'margarines',
    'margeaient',
    'margeasses',
    'margerions',
    'marginales',
    'marginâmes',
    'marginasse',
    'marginâtes',
    'marginerai',
    'margineras',
    'marginerez',
    'marginions',
    'margotâmes',
    'margotasse',
    'margotâtes',
    'margoterai',
    'margoteras',
    'margoterez',
    'margotions',
    'margottais',
    'margottait',
    'margottant',
    'margottent',
    'margottera',
    'margottiez',
    'margottons',
    'margoulins',
    'margraviat',
    'marguerite',
    'mariassent',
    'mariassiez',
    'marierions',
    'marinaient',
    'marinasses',
    'marinerais',
    'marinerait',
    'marinèrent',
    'marineriez',
    'marinerons',
    'marineront',
    'maringouin',
    'marinières',
    'marinismes',
    'maritornes',
    'marivaudai',
    'marivaudas',
    'marivaudât',
    'marivauder',
    'marivaudes',
    'marivaudez',
    'marjolaine',
    'marketings',
    'marmailles',
    'marmelades',
    'marmenteau',
    'marmitages',
    'marmitâmes',
    'marmitasse',
    'marmitâtes',
    'marmiterai',
    'marmiteras',
    'marmiterez',
    'marmitions',
    'marmonnais',
    'marmonnait',
    'marmonnant',
    'marmonnées',
    'marmonnent',
    'marmonnera',
    'marmonniez',
    'marmonnons',
    'marmoréens',
    'marmorisai',
    'marmorisas',
    'marmorisât',
    'marmorisée',
    'marmoriser',
    'marmorises',
    'marmorisés',
    'marmorisez',
    'marmottais',
    'marmottait',
    'marmottant',
    'marmottées',
    'marmottent',
    'marmottera',
    'marmotteur',
    'marmottiez',
    'marmottons',
    'marmousets',
    'marnassent',
    'marnassiez',
    'marnerions',
    'marocaines',
    'maronnâmes',
    'maronnasse',
    'maronnâtes',
    'maronnerai',
    'maronneras',
    'maronnerez',
    'maronnions',
    'maroquinai',
    'maroquinas',
    'maroquinât',
    'maroquinée',
    'maroquiner',
    'maroquines',
    'maroquinés',
    'maroquinez',
    'marotiques',
    'marouettes',
    'marouflage',
    'marouflais',
    'marouflait',
    'marouflant',
    'marouflées',
    'marouflent',
    'marouflera',
    'maroufliez',
    'marouflons',
    'marquaient',
    'marquantes',
    'marquasses',
    'marquerais',
    'marquerait',
    'marquèrent',
    'marqueriez',
    'marquerons',
    'marqueront',
    'marquetais',
    'marquetait',
    'marquetant',
    'marquetées',
    'marqueteur',
    'marquetiez',
    'marquetons',
    'marquettes',
    'marqueuses',
    'marquisats',
    'marrassent',
    'marrassiez',
    'marrerions',
    'marronnais',
    'marronnait',
    'marronnant',
    'marronnent',
    'marronnera',
    'marronnier',
    'marronniez',
    'marronnons',
    'marsouinai',
    'marsouinas',
    'marsouinât',
    'marsouiner',
    'marsouines',
    'marsouinez',
    'marsupiaux',
    'martelages',
    'martelâmes',
    'martelasse',
    'martelâtes',
    'martèlerai',
    'martèleras',
    'martèlerez',
    'marteleurs',
    'martelions',
    'martensite',
    'martiennes',
    'martingale',
    'martyrisai',
    'martyrisas',
    'martyrisât',
    'martyrisée',
    'martyriser',
    'martyrises',
    'martyrisés',
    'martyrisez',
    'martyriums',
    'marxiennes',
    'marxisâmes',
    'marxisasse',
    'marxisâtes',
    'marxiserai',
    'marxiseras',
    'marxiserez',
    'marxisions',
    'mascarades',
    'masculines',
    'maskinongé',
    'masochisme',
    'masochiste',
    'masquaient',
    'masquantes',
    'masquasses',
    'masquerais',
    'masquerait',
    'masquèrent',
    'masqueriez',
    'masquerons',
    'masqueront',
    'massacrais',
    'massacrait',
    'massacrant',
    'massacrées',
    'massacrent',
    'massacrera',
    'massacreur',
    'massacriez',
    'massacrons',
    'massassent',
    'massassiez',
    'masselotte',
    'massepains',
    'masserions',
    'massicotai',
    'massicotas',
    'massicotât',
    'massicotée',
    'massicoter',
    'massicotes',
    'massicotés',
    'massicotez',
    'massifiais',
    'massifiait',
    'massifiant',
    'massifiées',
    'massifient',
    'massifiera',
    'massifiiez',
    'massifions',
    'masticages',
    'mastiquais',
    'mastiquait',
    'mastiquant',
    'mastiquées',
    'mastiquent',
    'mastiquera',
    'mastiquiez',
    'mastiquons',
    'mastodonte',
    'mastoïdien',
    'mastoïdite',
    'mastologie',
    'mastroquet',
    'masturbais',
    'masturbait',
    'masturbant',
    'masturbées',
    'masturbent',
    'masturbera',
    'masturbiez',
    'masturbons',
    'matassions',
    'mâtassions',
    'matchaient',
    'matchasses',
    'matcherais',
    'matcherait',
    'matchèrent',
    'matcheriez',
    'matcherons',
    'matcheront',
    'matelassai',
    'matelassas',
    'matelassât',
    'matelassée',
    'matelasser',
    'matelasses',
    'matelassés',
    'matelassez',
    'materaient',
    'mâteraient',
    'matérielle',
    'maternages',
    'maternâmes',
    'maternasse',
    'maternâtes',
    'maternelle',
    'maternerai',
    'materneras',
    'maternerez',
    'maternions',
    'maternisai',
    'maternisas',
    'maternisât',
    'maternisée',
    'materniser',
    'maternises',
    'maternisés',
    'maternisez',
    'maternités',
    'mâtinaient',
    'mâtinasses',
    'mâtinerais',
    'mâtinerait',
    'mâtinèrent',
    'mâtineriez',
    'mâtinerons',
    'mâtineront',
    'matineuses',
    'matinières',
    'matiraient',
    'matissions',
    'matraquage',
    'matraquais',
    'matraquait',
    'matraquant',
    'matraquées',
    'matraquent',
    'matraquera',
    'matraqueur',
    'matraquiez',
    'matraquons',
    'matriarcal',
    'matriarcat',
    'matricaire',
    'matriçâmes',
    'matriçasse',
    'matriçâtes',
    'matricerai',
    'matriceras',
    'matricerez',
    'matricides',
    'matricions',
    'matriculai',
    'matriculas',
    'matriculât',
    'matriculée',
    'matriculer',
    'matricules',
    'matriculés',
    'matriculez',
    'matrilocal',
    'matthioles',
    'maturaient',
    'maturasses',
    'maturation',
    'maturerais',
    'maturerait',
    'maturèrent',
    'matureriez',
    'maturerons',
    'matureront',
    'matutinale',
    'matutinaux',
    'maudirions',
    'maudissais',
    'maudissait',
    'maudissant',
    'maudissent',
    'maudissiez',
    'maudissons',
    'maugréâmes',
    'maugréasse',
    'maugréâtes',
    'maugréerai',
    'maugréeras',
    'maugréerez',
    'maugréions',
    'maurandies',
    'mauresques',
    'mauvaiseté',
    'mauviettes',
    'maxillaire',
    'maximalisa',
    'maximalise',
    'maximalisé',
    'maximisais',
    'maximisait',
    'maximisant',
    'maximisées',
    'maximisent',
    'maximisera',
    'maximisiez',
    'maximisons',
    'mayonnaise',
    'mazoutâmes',
    'mazoutasse',
    'mazoutâtes',
    'mazouterai',
    'mazouteras',
    'mazouterez',
    'mazoutions',
    'méandrines',
    'mécanicien',
    'mécaniques',
    'mécanisais',
    'mécanisait',
    'mécanisant',
    'mécanisées',
    'mécanisent',
    'mécanisera',
    'mécanisiez',
    'mécanismes',
    'mécanisons',
    'mécanistes',
    'méchamment',
    'méchanceté',
    'méchassent',
    'méchassiez',
    'mécherions',
    'mécomptais',
    'mécomptait',
    'mécomptant',
    'mécomptées',
    'mécomptent',
    'mécomptera',
    'mécomptiez',
    'mécomptons',
    'méconduire',
    'méconduite',
    'méconnûmes',
    'méconnusse',
    'méconnûtes',
    'mécontenta',
    'mécontente',
    'mécontenté',
    'mécontents',
    'mécréantes',
    'médaillais',
    'médaillait',
    'médaillant',
    'médaillées',
    'médaillent',
    'médaillera',
    'médailleur',
    'médaillier',
    'médailliez',
    'médaillons',
    'médianoche',
    'médiastins',
    'médiateurs',
    'médiations',
    'médiatique',
    'médiatisai',
    'médiatisas',
    'médiatisât',
    'médiatisée',
    'médiatiser',
    'médiatises',
    'médiatisés',
    'médiatisez',
    'médiatrice',
    'médicalisa',
    'médicalise',
    'médicalisé',
    'médicament',
    'médicastre',
    'médication',
    'médicinale',
    'médicinaux',
    'médicinier',
    'médiévales',
    'médiévisme',
    'médiéviste',
    'médiocrité',
    'médiraient',
    'médisaient',
    'médisances',
    'médisantes',
    'médisasses',
    'médiserais',
    'médiserait',
    'médisèrent',
    'médiseriez',
    'médiserons',
    'médiseront',
    'médissions',
    'méditaient',
    'méditantes',
    'méditasses',
    'méditatifs',
    'méditation',
    'méditative',
    'méditerais',
    'méditerait',
    'méditèrent',
    'méditeriez',
    'méditerons',
    'méditeront',
    'médiumnité',
    'médullaire',
    'médulleuse',
    'médusaient',
    'médusantes',
    'médusasses',
    'méduserais',
    'méduserait',
    'médusèrent',
    'méduseriez',
    'méduserons',
    'méduseront',
    'méfaisions',
    'méfassions',
    'méferaient',
    'méfiassent',
    'méfiassiez',
    'méfierions',
    'méfissions',
    'mégacôlons',
    'mégacycles',
    'mégalithes',
    'mégalomane',
    'mégalopole',
    'mégaphones',
    'mégaptères',
    'mégatonnes',
    'mégiraient',
    'mégissâmes',
    'mégissasse',
    'mégissâtes',
    'mégisserai',
    'mégisseras',
    'mégisserez',
    'mégisserie',
    'mégissiers',
    'mégissions',
    'mégotaient',
    'mégotasses',
    'mégoterais',
    'mégoterait',
    'mégotèrent',
    'mégoteriez',
    'mégoterons',
    'mégoteront',
    'méharistes',
    'meilleures',
    'méiotiques',
    'méjugeâmes',
    'méjugeasse',
    'méjugeâtes',
    'méjugerais',
    'méjugerait',
    'méjugèrent',
    'méjugeriez',
    'méjugerons',
    'méjugeront',
    'mélampyres',
    'mélancolie',
    'mélangeais',
    'mélangeait',
    'mélangeant',
    'mélangeons',
    'mélangerai',
    'mélangeras',
    'mélangerez',
    'mélangeurs',
    'mélangeuse',
    'mélangions',
    'mélaniques',
    'mélanismes',
    'mêlassions',
    'méléagrine',
    'mêleraient',
    'mélioratif',
    'mellifères',
    'melliflues',
    'mélodieuse',
    'mélodiques',
    'mélodistes',
    'mélodrames',
    'mélongines',
    'melonnière',
    'mélophages',
    'membraneux',
    'membranule',
    'mémorables',
    'mémorandum',
    'mémoration',
    'mémorielle',
    'mémorisais',
    'mémorisait',
    'mémorisant',
    'mémorisées',
    'mémorisent',
    'mémorisera',
    'mémorisiez',
    'mémorisons',
    'menaçaient',
    'menaçantes',
    'menaçasses',
    'menacerais',
    'menacerait',
    'menacèrent',
    'menaceriez',
    'menacerons',
    'menaceront',
    'ménageâmes',
    'ménageante',
    'ménageants',
    'ménageasse',
    'ménageâtes',
    'ménagement',
    'ménagerais',
    'ménagerait',
    'ménagèrent',
    'ménageries',
    'ménageriez',
    'ménagerons',
    'ménageront',
    'menassions',
    'mencheviks',
    'mendéliens',
    'mendélisme',
    'mendiaient',
    'mendiantes',
    'mendiasses',
    'mendicités',
    'mendierais',
    'mendierait',
    'mendièrent',
    'mendieriez',
    'mendierons',
    'mendieront',
    'mendigotai',
    'mendigotas',
    'mendigotât',
    'mendigotée',
    'mendigoter',
    'mendigotes',
    'mendigotés',
    'mendigotez',
    'mèneraient',
    'ménestrels',
    'ménétriers',
    'méningiome',
    'méningites',
    'mennonites',
    'ménopausée',
    'ménopauses',
    'ménorragie',
    'mensongère',
    'mensongers',
    'menstruels',
    'mensualisa',
    'mensualise',
    'mensualisé',
    'mensualité',
    'mensuelles',
    'mensurâmes',
    'mensurasse',
    'mensurâtes',
    'mensurerai',
    'mensureras',
    'mensurerez',
    'mensurions',
    'mentalités',
    'mentholées',
    'mentionnai',
    'mentionnas',
    'mentionnât',
    'mentionnée',
    'mentionner',
    'mentionnes',
    'mentionnés',
    'mentionnez',
    'mentirions',
    'mentissent',
    'mentissiez',
    'mentonnets',
    'mentonnier',
    'menuisâmes',
    'menuisasse',
    'menuisâtes',
    'menuiserai',
    'menuiseras',
    'menuiserez',
    'menuiserie',
    'menuisiers',
    'menuisions',
    'ményanthes',
    'méphitique',
    'méphitisme',
    'méprendrai',
    'méprendras',
    'méprendrez',
    'méprenions',
    'méprennent',
    'méprisable',
    'méprisâmes',
    'méprisante',
    'méprisants',
    'méprisasse',
    'méprisâtes',
    'mépriserai',
    'mépriseras',
    'mépriserez',
    'méprisions',
    'méprissent',
    'méprissiez',
    'mercantile',
    'mercaptans',
    'mercatique',
    'mercenaire',
    'mercerisai',
    'mercerisas',
    'mercerisât',
    'mercerisée',
    'merceriser',
    'mercerises',
    'mercerisés',
    'mercerisez',
    'mercuriale',
    'mercuriels',
    'merdoierai',
    'merdoieras',
    'merdoierez',
    'merdoyâmes',
    'merdoyasse',
    'merdoyâtes',
    'merdoyions',
    'mère-grand',
    'méridienne',
    'méridional',
    'meringuais',
    'meringuait',
    'meringuant',
    'meringuées',
    'meringuent',
    'meringuera',
    'meringuiez',
    'meringuons',
    'méristèmes',
    'méritaient',
    'méritantes',
    'méritasses',
    'mériterais',
    'mériterait',
    'méritèrent',
    'mériteriez',
    'mériterons',
    'mériteront',
    'méritoires',
    'merveilles',
    'mérycismes',
    'mésalliais',
    'mésalliait',
    'mésalliant',
    'mésalliées',
    'mésallient',
    'mésalliera',
    'mésalliiez',
    'mésallions',
    'mésangette',
    'mescalines',
    'mésenchyme',
    'mésentente',
    'mésentères',
    'mésestimai',
    'mésestimas',
    'mésestimât',
    'mésestimée',
    'mésestimer',
    'mésestimes',
    'mésestimés',
    'mésestimez',
    'mesmérisme',
    'mésoblaste',
    'mésocarpes',
    'mésodermes',
    'mésopauses',
    'mésosphère',
    'mésothorax',
    'mésozoïque',
    'messagères',
    'messagerie',
    'messiérait',
    'messiéront',
    'mestrances',
    'mesurables',
    'mesuraient',
    'mesurasses',
    'mesurerais',
    'mesurerait',
    'mesurèrent',
    'mesureriez',
    'mesurerons',
    'mesureront',
    'mésusaient',
    'mésusasses',
    'mésuserais',
    'mésuserait',
    'mésusèrent',
    'mésuseriez',
    'mésuserons',
    'mésuseront',
    'métabolite',
    'métacarpes',
    'métacentre',
    'métalangue',
    'métallique',
    'métallisai',
    'métallisas',
    'métallisât',
    'métallisée',
    'métalliser',
    'métallises',
    'métallisés',
    'métallisez',
    'métalloïde',
    'métaphases',
    'métaphores',
    'métaphyses',
    'métaplasie',
    'métastases',
    'métatarses',
    'métazoaire',
    'météorique',
    'météorisai',
    'météorisas',
    'météorisât',
    'météorisée',
    'météoriser',
    'météorises',
    'météorisés',
    'météorisez',
    'météorites',
    'méthaniers',
    'méthionine',
    'méthodique',
    'méthodisme',
    'méthodiste',
    'méthylènes',
    'méthylique',
    'méticuleux',
    'métissages',
    'métissâmes',
    'métissasse',
    'métissâtes',
    'métisserai',
    'métisseras',
    'métisserez',
    'métissions',
    'métonymies',
    'métrassent',
    'métrassiez',
    'métrerions',
    'métriciens',
    'métromanie',
    'métronomes',
    'métropoles',
    'mettraient',
    'meublaient',
    'meublasses',
    'meublerais',
    'meublerait',
    'meublèrent',
    'meubleriez',
    'meublerons',
    'meubleront',
    'meuglaient',
    'meuglantes',
    'meuglasses',
    'meuglement',
    'meuglerais',
    'meuglerait',
    'meuglèrent',
    'meugleriez',
    'meuglerons',
    'meugleront',
    'meulassent',
    'meulassiez',
    'meulerions',
    'meurtrière',
    'meurtriers',
    'meurtrîmes',
    'meurtrirai',
    'meurtriras',
    'meurtrirez',
    'meurtrisse',
    'meurtrîtes',
    'mévendîmes',
    'mévendions',
    'mévendisse',
    'mévendîtes',
    'mévendrais',
    'mévendrait',
    'mévendriez',
    'mévendrons',
    'mévendront',
    'mexicaines',
    'mezzanines',
    'miaulaient',
    'miaulantes',
    'miaulasses',
    'miaulement',
    'miaulerais',
    'miaulerait',
    'miaulèrent',
    'miauleriez',
    'miaulerons',
    'miauleront',
    'miauleuses',
    'mi-carêmes',
    'micellaire',
    'michelines',
    'microbiens',
    'microcoque',
    'microcosme',
    'microfiche',
    'microfilma',
    'microfilme',
    'microfilmé',
    'microfilms',
    'microgrenu',
    'micromètre',
    'microphone',
    'microscope',
    'midinettes',
    'midshipman',
    'midshipmen',
    'mielleuses',
    'mieux-être',
    'mièvrement',
    'mièvreries',
    'mi-février',
    'migmatites',
    'mignardais',
    'mignardait',
    'mignardant',
    'mignardées',
    'mignardent',
    'mignardera',
    'mignardiez',
    'mignardise',
    'mignardons',
    'mignotâmes',
    'mignotasse',
    'mignotâtes',
    'mignoterai',
    'mignoteras',
    'mignoterez',
    'mignotions',
    'migraineux',
    'migrassent',
    'migrassiez',
    'migrateurs',
    'migrations',
    'migratoire',
    'migratrice',
    'migrerions',
    'mi-janvier',
    'mijotaient',
    'mijotantes',
    'mijotasses',
    'mijoterais',
    'mijoterait',
    'mijotèrent',
    'mijoteriez',
    'mijoterons',
    'mijoteront',
    'mi-juillet',
    'milanaises',
    'mildiousée',
    'mildiousés',
    'milicienne',
    'militaient',
    'militaires',
    'militantes',
    'militarisa',
    'militarise',
    'militarisé',
    'militasses',
    'militerais',
    'militerait',
    'militèrent',
    'militeriez',
    'militerons',
    'militeront',
    'millénaire',
    'milléniums',
    'millépores',
    'millerandé',
    'millésimai',
    'millésimas',
    'millésimât',
    'millésimée',
    'millésimer',
    'millésimes',
    'millésimés',
    'millésimez',
    'milliaires',
    'milliasses',
    'millilitre',
    'millimétré',
    'millimètre',
    'millivolts',
    'mimassions',
    'mimeraient',
    'mimétiques',
    'mimétismes',
    'mimodrames',
    'mimographe',
    'mimolettes',
    'mimologies',
    'minahouets',
    'minassions',
    'minaudâmes',
    'minaudasse',
    'minaudâtes',
    'minauderai',
    'minauderas',
    'minauderez',
    'minauderie',
    'minaudière',
    'minaudiers',
    'minaudions',
    'mincirions',
    'mincissais',
    'mincissait',
    'mincissant',
    'mincissent',
    'mincissiez',
    'mincissons',
    'mineraient',
    'minéralier',
    'minéralisa',
    'minéralise',
    'minéralisé',
    'minerviste',
    'minestrone',
    'miniaturai',
    'miniaturas',
    'miniaturât',
    'miniaturée',
    'miniaturer',
    'miniatures',
    'miniaturés',
    'miniaturez',
    'mini-jupes',
    'minimisais',
    'minimisait',
    'minimisant',
    'minimisées',
    'minimisent',
    'minimisera',
    'minimisiez',
    'minimisons',
    'ministères',
    'minoraient',
    'minorasses',
    'minoratifs',
    'minoration',
    'minorative',
    'minorerais',
    'minorerait',
    'minorèrent',
    'minoreriez',
    'minorerons',
    'minoreront',
    'minoteries',
    'minotières',
    'minuscules',
    'minutaient',
    'minutaires',
    'minutasses',
    'minuterais',
    'minuterait',
    'minutèrent',
    'minuteries',
    'minuteriez',
    'minuterons',
    'minuteront',
    'minutieuse',
    'mi-octobre',
    'mi-parties',
    'mirabelles',
    'miraculées',
    'miraculeux',
    'mirassions',
    'mireraient',
    'mirifiques',
    'mirliflore',
    'mirliflors',
    'mirmillons',
    'mirobolant',
    'miroitâmes',
    'miroitante',
    'miroitants',
    'miroitasse',
    'miroitâtes',
    'miroiterai',
    'miroiteras',
    'miroiterez',
    'miroiterie',
    'miroitière',
    'miroitiers',
    'miroitions',
    'misassions',
    'misérables',
    'miseraient',
    'miséreuses',
    'misogynies',
    'misonéisme',
    'misonéiste',
    'mispickels',
    'missiliers',
    'mistoufles',
    'mitassions',
    'miteraient',
    'mithriaque',
    'mithridata',
    'mithridate',
    'mithridaté',
    'mitigation',
    'mitigeâmes',
    'mitigeante',
    'mitigeants',
    'mitigeasse',
    'mitigeâtes',
    'mitigerais',
    'mitigerait',
    'mitigèrent',
    'mitigeriez',
    'mitigerons',
    'mitigeront',
    'mitonnâmes',
    'mitonnasse',
    'mitonnâtes',
    'mitonnerai',
    'mitonneras',
    'mitonnerez',
    'mitonnions',
    'mitoyennes',
    'mitraillai',
    'mitraillas',
    'mitraillât',
    'mitraillée',
    'mitrailler',
    'mitrailles',
    'mitraillés',
    'mitraillez',
    'mixassions',
    'mixeraient',
    'mixtionnai',
    'mixtionnas',
    'mixtionnât',
    'mixtionnée',
    'mixtionner',
    'mixtionnes',
    'mixtionnés',
    'mixtionnez',
    'mnémonique',
    'mobilières',
    'mobilisais',
    'mobilisait',
    'mobilisant',
    'mobilisées',
    'mobilisent',
    'mobilisera',
    'mobilisiez',
    'mobilisons',
    'mobylettes',
    'modelaient',
    'modelantes',
    'modelasses',
    'modèlerais',
    'modèlerait',
    'modelèrent',
    'modèleriez',
    'modèlerons',
    'modèleront',
    'modeleuses',
    'modélisais',
    'modélisait',
    'modélisant',
    'modélisées',
    'modélisent',
    'modélisera',
    'modélisiez',
    'modélisons',
    'modélisont',
    'modélistes',
    'modénature',
    'modéraient',
    'modérasses',
    'modérateur',
    'modération',
    'modérément',
    'modérerais',
    'modérerait',
    'modérèrent',
    'modéreriez',
    'modérerons',
    'modéreront',
    'modernisai',
    'modernisas',
    'modernisât',
    'modernisée',
    'moderniser',
    'modernises',
    'modernisés',
    'modernisez',
    'modernisme',
    'moderniste',
    'modernités',
    'modifiable',
    'modifiâmes',
    'modifiante',
    'modifiants',
    'modifiasse',
    'modifiâtes',
    'modifierai',
    'modifieras',
    'modifierez',
    'modifiions',
    'modulaient',
    'modulaires',
    'modulantes',
    'modularité',
    'modulasses',
    'modulateur',
    'modulation',
    'modulerais',
    'modulerait',
    'modulèrent',
    'moduleriez',
    'modulerons',
    'moduleront',
    'moelleuses',
    'moinillons',
    'moirassent',
    'moirassiez',
    'moirerions',
    'moisassent',
    'moisassiez',
    'moiserions',
    'moisirions',
    'moisissais',
    'moisissait',
    'moisissant',
    'moisissent',
    'moisissiez',
    'moisissons',
    'moisissure',
    'moissonnai',
    'moissonnas',
    'moissonnât',
    'moissonnée',
    'moissonner',
    'moissonnes',
    'moissonnés',
    'moissonnez',
    'moitassent',
    'moitassiez',
    'moiterions',
    'moitirions',
    'moitissais',
    'moitissait',
    'moitissant',
    'moitissent',
    'moitissiez',
    'moitissons',
    'moleskines',
    'molestâmes',
    'molestasse',
    'molestâtes',
    'molesterai',
    'molesteras',
    'molesterez',
    'molestions',
    'moletaient',
    'moletasses',
    'moletèrent',
    'moletterai',
    'moletteras',
    'moletterez',
    'molinistes',
    'mollardais',
    'mollardait',
    'mollardant',
    'mollardées',
    'mollardent',
    'mollardera',
    'mollardiez',
    'mollardons',
    'mollassons',
    'molletière',
    'molletonna',
    'molletonne',
    'molletonné',
    'mollirions',
    'mollissais',
    'mollissait',
    'mollissant',
    'mollissent',
    'mollissiez',
    'mollissons',
    'molluscums',
    'mollusques',
    'molybdènes',
    'momentanée',
    'momentanés',
    'momifiâmes',
    'momifiasse',
    'momifiâtes',
    'momifierai',
    'momifieras',
    'momifierez',
    'momifiions',
    'momordique',
    'monachisme',
    'monadelphe',
    'monarchies',
    'monastères',
    'monastique',
    'mondanités',
    'mondassent',
    'mondassiez',
    'monderions',
    'mondialisa',
    'mondialise',
    'mondialisé',
    'monétaires',
    'monétiques',
    'monétisais',
    'monétisait',
    'monétisant',
    'monétisées',
    'monétisent',
    'monétisera',
    'monétisiez',
    'monétisons',
    'mongoliens',
    'mongolique',
    'mongolisme',
    'mongoloïde',
    'monitoires',
    'monitorage',
    'monitorats',
    'monitoring',
    'monitrices',
    'monnaierai',
    'monnaieras',
    'monnaierez',
    'monnayable',
    'monnayages',
    'monnayâmes',
    'monnayante',
    'monnayants',
    'monnayasse',
    'monnayâtes',
    'monnayerai',
    'monnayeras',
    'monnayerez',
    'monnayeurs',
    'monnayeuse',
    'monnayions',
    'monoacides',
    'monochrome',
    'monoclinal',
    'monocoques',
    'monocordes',
    'monogamies',
    'monogramme',
    'monolingue',
    'monolithes',
    'monologuai',
    'monologuas',
    'monologuât',
    'monologuée',
    'monologuer',
    'monologues',
    'monologués',
    'monologuez',
    'monomanies',
    'monomoteur',
    'monophasée',
    'monophasés',
    'monoplaces',
    'monopolisa',
    'monopolise',
    'monopolisé',
    'monopsones',
    'monoptères',
    'monosépale',
    'monosperme',
    'monostyles',
    'monotonies',
    'monotraces',
    'monotrèmes',
    'monovalent',
    'monsignore',
    'monsignori',
    'monstrueux',
    'montagnard',
    'montagneux',
    'montaisons',
    'montanisme',
    'montaniste',
    'montassent',
    'montassiez',
    'mont-blanc',
    'monterions',
    'monte-sacs',
    'monticoles',
    'monticules',
    'montrables',
    'montraient',
    'montrasses',
    'montrerais',
    'montrerait',
    'montrèrent',
    'montreriez',
    'montrerons',
    'montreront',
    'montreuses',
    'monts-joie',
    'montueuses',
    'monumental',
    'moquassent',
    'moquassiez',
    'moquerions',
    'moquettais',
    'moquettait',
    'moquettant',
    'moquettées',
    'moquettent',
    'moquettera',
    'moquettiez',
    'moquettons',
    'moraillons',
    'morainique',
    'moralement',
    'moralisais',
    'moralisait',
    'moralisant',
    'moralisées',
    'moralisent',
    'moralisera',
    'moralisiez',
    'moralismes',
    'moralisons',
    'moralistes',
    'moratoires',
    'moratorium',
    'morbidités',
    'morbilleux',
    'morcelable',
    'morcelâmes',
    'morcelasse',
    'morcelâtes',
    'morcelions',
    'morcellent',
    'morcellera',
    'mordacités',
    'mordançais',
    'mordançait',
    'mordançant',
    'mordancées',
    'mordancent',
    'mordancera',
    'mordanciez',
    'mordançons',
    'mordicante',
    'mordicants',
    'mordillais',
    'mordillait',
    'mordillant',
    'mordillées',
    'mordillent',
    'mordillera',
    'mordilliez',
    'mordillons',
    'mordissent',
    'mordissiez',
    'mordorâmes',
    'mordorasse',
    'mordorâtes',
    'mordorerai',
    'mordoreras',
    'mordorerez',
    'mordorions',
    'mordorures',
    'mordraient',
    'morfilâmes',
    'morfilasse',
    'morfilâtes',
    'morfilerai',
    'morfileras',
    'morfilerez',
    'morfilions',
    'morflaient',
    'morflasses',
    'morflerais',
    'morflerait',
    'morflèrent',
    'morfleriez',
    'morflerons',
    'morfleront',
    'morfondais',
    'morfondait',
    'morfondant',
    'morfondent',
    'morfondiez',
    'morfondons',
    'morfondrai',
    'morfondras',
    'morfondrez',
    'morfondues',
    'morgelines',
    'morguaient',
    'morguasses',
    'morguerais',
    'morguerait',
    'morguèrent',
    'morgueriez',
    'morguerons',
    'morgueront',
    'morguienne',
    'moribondes',
    'moricaudes',
    'morigénais',
    'morigénait',
    'morigénant',
    'morigénées',
    'morigènent',
    'morigénera',
    'morigéniez',
    'morigénons',
    'mormonisme',
    'morphogène',
    'mortadelle',
    'mortaisage',
    'mortaisais',
    'mortaisait',
    'mortaisant',
    'mortaisées',
    'mortaisent',
    'mortaisera',
    'mortaisiez',
    'mortaisons',
    'mortalités',
    'mortifiais',
    'mortifiait',
    'mortifiant',
    'mortifiées',
    'mortifient',
    'mortifiera',
    'mortifiiez',
    'mortifions',
    'mortuaires',
    'morutières',
    'mosaïquées',
    'mosellanes',
    'motionnais',
    'motionnait',
    'motionnant',
    'motionnées',
    'motionnent',
    'motionnera',
    'motionniez',
    'motionnons',
    'motivaient',
    'motivantes',
    'motivasses',
    'motivation',
    'motiverais',
    'motiverait',
    'motivèrent',
    'motiveriez',
    'motiverons',
    'motiveront',
    'motocistes',
    'moto-cross',
    'motocycles',
    'motoneiges',
    'motopompes',
    'motorisais',
    'motorisait',
    'motorisant',
    'motorisées',
    'motorisent',
    'motorisera',
    'motorisiez',
    'motorisons',
    'motoristes',
    'motorships',
    'motricités',
    'mottassent',
    'mottassiez',
    'motterions',
    'mot-valise',
    'mouchaient',
    'mouchardai',
    'mouchardas',
    'mouchardât',
    'mouchardée',
    'moucharder',
    'mouchardes',
    'mouchardés',
    'mouchardez',
    'mouchasses',
    'moucherais',
    'moucherait',
    'mouchèrent',
    'moucheriez',
    'moucherons',
    'moucheront',
    'mouchetais',
    'mouchetait',
    'mouchetant',
    'mouchetées',
    'mouchetiez',
    'mouchetons',
    'mouchettes',
    'moucheture',
    'moudraient',
    'mouettâmes',
    'mouettasse',
    'mouettâtes',
    'mouetterai',
    'mouetteras',
    'mouetterez',
    'mouettions',
    'mouffettes',
    'mouflettes',
    'mouftaient',
    'mouftasses',
    'moufterais',
    'moufterait',
    'mouftèrent',
    'moufteriez',
    'moufterons',
    'moufteront',
    'mouillages',
    'mouillâmes',
    'mouillante',
    'mouillants',
    'mouillasse',
    'mouillâtes',
    'mouillerai',
    'mouilleras',
    'mouillères',
    'mouillerez',
    'mouillette',
    'mouilleurs',
    'mouillions',
    'mouilloirs',
    'mouillures',
    'moujingues',
    'moulassent',
    'moulassiez',
    'moulerions',
    'moulinages',
    'moulinâmes',
    'moulinasse',
    'moulinâtes',
    'moulinerai',
    'moulineras',
    'moulinerez',
    'moulinette',
    'moulineurs',
    'moulineuse',
    'moulinière',
    'mouliniers',
    'moulinions',
    'moulurâmes',
    'moulurasse',
    'moulurâtes',
    'moulurerai',
    'moulureras',
    'moulurerez',
    'moulurions',
    'moulussent',
    'moulussiez',
    'mouronnais',
    'mouronnait',
    'mouronnant',
    'mouronnées',
    'mouronnent',
    'mouronnera',
    'mouronniez',
    'mouronnons',
    'mourraient',
    'mourussent',
    'mourussiez',
    'mouscaille',
    'mousqueton',
    'moussaient',
    'moussantes',
    'moussasses',
    'mousseline',
    'mousserais',
    'mousserait',
    'moussèrent',
    'mousseriez',
    'mousserons',
    'mousseront',
    'mousseuses',
    'moustaches',
    'moustachue',
    'moustachus',
    'moustérien',
    'moustiques',
    'moutardier',
    'moutonnais',
    'moutonnait',
    'moutonnant',
    'moutonnées',
    'moutonnent',
    'moutonnera',
    'moutonneux',
    'moutonnier',
    'moutonniez',
    'moutonnons',
    'mouvassent',
    'mouvassiez',
    'mouvementa',
    'mouvemente',
    'mouvementé',
    'mouvements',
    'mouverions',
    'mouvraient',
    'moyenâgeux',
    'moyennâmes',
    'moyennasse',
    'moyennâtes',
    'moyennerai',
    'moyenneras',
    'moyennerez',
    'moyennions',
    'muchassent',
    'muchassiez',
    'mucherions',
    'mugiraient',
    'mugissante',
    'mugissants',
    'mugissions',
    'muguetâmes',
    'muguetasse',
    'muguetâtes',
    'muguetions',
    'muguettent',
    'muguettera',
    'mulassière',
    'mulassiers',
    'mulâtresse',
    'mule-jenny',
    'muletières',
    'mulotaient',
    'mulotasses',
    'muloterais',
    'muloterait',
    'mulotèrent',
    'muloteriez',
    'muloterons',
    'muloteront',
    'multiflore',
    'multiforme',
    'multimédia',
    'multipares',
    'multiplexa',
    'multiplexe',
    'multiplexé',
    'multipliai',
    'multiplias',
    'multipliât',
    'multipliée',
    'multiplier',
    'multiplies',
    'multipliés',
    'multipliez',
    'multitubes',
    'multitudes',
    'municipale',
    'municipaux',
    'munificent',
    'muniraient',
    'munissions',
    'munitionna',
    'munitionne',
    'munitionné',
    'muraillais',
    'muraillait',
    'muraillant',
    'muraillées',
    'muraillent',
    'muraillera',
    'murailliez',
    'muraillons',
    'murassions',
    'mureraient',
    'muriatique',
    'mûriraient',
    'mûrissages',
    'mûrissante',
    'mûrissants',
    'mûrisserie',
    'mûrissions',
    'murmurâmes',
    'murmurante',
    'murmurants',
    'murmurasse',
    'murmurâtes',
    'murmurerai',
    'murmureras',
    'murmurerez',
    'murmurions',
    'musaraigne',
    'musardâmes',
    'musardasse',
    'musardâtes',
    'musarderai',
    'musarderas',
    'musarderez',
    'musardions',
    'musardises',
    'musassions',
    'muscadiers',
    'muscardine',
    'muscardins',
    'muscarines',
    'musclaient',
    'musclasses',
    'musclerais',
    'musclerait',
    'musclèrent',
    'muscleriez',
    'musclerons',
    'muscleront',
    'musculaire',
    'musculeuse',
    'muselaient',
    'muselasses',
    'muselèrent',
    'muselières',
    'musellerai',
    'muselleras',
    'musellerez',
    'muséologie',
    'museraient',
    'muserolles',
    'musicalité',
    'music-hall',
    'musicienne',
    'musiquâmes',
    'musiquasse',
    'musiquâtes',
    'musiquerai',
    'musiqueras',
    'musiquerez',
    'musiquette',
    'musiquions',
    'musquaient',
    'musquasses',
    'musquerais',
    'musquerait',
    'musquèrent',
    'musqueriez',
    'musquerons',
    'musqueront',
    'mussassent',
    'mussassiez',
    'musserions',
    'musulmanes',
    'mutabilité',
    'mutassions',
    'muteraient',
    'mutilaient',
    'mutilantes',
    'mutilasses',
    'mutilateur',
    'mutilation',
    'mutilerais',
    'mutilerait',
    'mutilèrent',
    'mutileriez',
    'mutilerons',
    'mutileront',
    'mutinaient',
    'mutinasses',
    'mutinerais',
    'mutinerait',
    'mutinèrent',
    'mutineries',
    'mutineriez',
    'mutinerons',
    'mutineront',
    'mutualisme',
    'mutualiste',
    'mutualités',
    'myasthénie',
    'mycélienne',
    'mycénienne',
    'mycodermes',
    'mycologies',
    'mycologues',
    'mycoplasme',
    'mycorhizes',
    'myélocytes',
    'myocardite',
    'myographes',
    'myopathies',
    'myopotames',
    'myriamètre',
    'myrobolans',
    'myroxylons',
    'myrtiforme',
    'mystagogie',
    'mystagogue',
    'mystérieux',
    'mysticisme',
    'mysticités',
    'mystifiais',
    'mystifiait',
    'mystifiant',
    'mystifiées',
    'mystifient',
    'mystifiera',
    'mystifiiez',
    'mystifions',
    'mythologie',
    'mythologue',
    'mythomanes',
    'mythomanie',
    'myxoedèmes',
    'myxomatose',
    'nacrassent',
    'nacrassiez',
    'nacrerions',
    'nageassent',
    'nageassiez',
    'nageraient',
    'naissaient',
    'naissances',
    'naissantes',
    'naîtraient',
    'nantirions',
    'nantissais',
    'nantissait',
    'nantissant',
    'nantissent',
    'nantissiez',
    'nantissons',
    'naphtalène',
    'naphtaline',
    'napolitain',
    'nappassent',
    'nappassiez',
    'napperions',
    'naquissent',
    'naquissiez',
    'narcotines',
    'narcotique',
    'narghilehs',
    'narguaient',
    'narguasses',
    'narguerais',
    'narguerait',
    'narguèrent',
    'nargueriez',
    'narguerons',
    'nargueront',
    'narquoises',
    'narrassent',
    'narrassiez',
    'narrateurs',
    'narrations',
    'narratives',
    'narratrice',
    'narrerions',
    'nasalisais',
    'nasalisait',
    'nasalisant',
    'nasalisées',
    'nasalisent',
    'nasalisera',
    'nasalisiez',
    'nasalisons',
    'nasillâmes',
    'nasillante',
    'nasillants',
    'nasillarde',
    'nasillards',
    'nasillasse',
    'nasillâtes',
    'nasillerai',
    'nasilleras',
    'nasillerez',
    'nasilleurs',
    'nasilleuse',
    'nasillions',
    'natalistes',
    'natatoires',
    'nationales',
    'nativement',
    'nativismes',
    'nativistes',
    'nattassent',
    'nattassiez',
    'natterions',
    'naturalisa',
    'naturalise',
    'naturalisé',
    'naturelles',
    'naturismes',
    'naturistes',
    'naufrageai',
    'naufrageas',
    'naufrageât',
    'naufragées',
    'naufragent',
    'naufragera',
    'naufrageur',
    'naufragiez',
    'naumachies',
    'naupathies',
    'nauséabond',
    'nauséeuses',
    'nautonière',
    'nautoniers',
    'navetteurs',
    'navetteuse',
    'navigables',
    'navigantes',
    'navigateur',
    'navigation',
    'naviguâmes',
    'naviguante',
    'naviguants',
    'naviguasse',
    'naviguâtes',
    'naviguerai',
    'navigueras',
    'naviguerez',
    'naviguions',
    'naviplanes',
    'navisphère',
    'navrassent',
    'navrassiez',
    'navrements',
    'navrerions',
    'nazaréenne',
    'néantisais',
    'néantisait',
    'néantisant',
    'néantisées',
    'néantisent',
    'néantisera',
    'néantisiez',
    'néantisons',
    'nébuleuses',
    'nébuliseur',
    'nébulosité',
    'nécessaire',
    'nécessitai',
    'nécessitas',
    'nécessitât',
    'nécessitée',
    'nécessiter',
    'nécessites',
    'nécessités',
    'nécessitez',
    'nécrologes',
    'nécrologie',
    'nécrologue',
    'nécromants',
    'nécrophage',
    'nécrophore',
    'nécropoles',
    'nécropsies',
    'nécrosâmes',
    'nécrosasse',
    'nécrosâtes',
    'nécroserai',
    'nécroseras',
    'nécroserez',
    'nécrosions',
    'nectarines',
    'négatrices',
    'négligeais',
    'négligeait',
    'négligeant',
    'négligence',
    'négligente',
    'négligents',
    'négligeons',
    'négligerai',
    'négligeras',
    'négligerez',
    'négligions',
    'négociable',
    'négociâmes',
    'négociante',
    'négociants',
    'négociasse',
    'négociâtes',
    'négocierai',
    'négocieras',
    'négocierez',
    'négociions',
    'négrillons',
    'négritudes',
    'néoblastes',
    'néocomiens',
    'néoformées',
    'néologique',
    'néologisme',
    'néomycines',
    'néonatales',
    'néoplasmes',
    'néozoïques',
    'népérienne',
    'néphélions',
    'néphridies',
    'népotismes',
    'neptuniums',
    'néritiques',
    'néronienne',
    'nervations',
    'nervosités',
    'nervurâmes',
    'nervurasse',
    'nervurâtes',
    'nervurerai',
    'nervureras',
    'nervurerez',
    'nervurions',
    'nestoriens',
    'nettoierai',
    'nettoieras',
    'nettoierez',
    'nettoyages',
    'nettoyâmes',
    'nettoyante',
    'nettoyants',
    'nettoyasse',
    'nettoyâtes',
    'nettoyeurs',
    'nettoyeuse',
    'nettoyions',
    'neurologie',
    'neurologue',
    'neuronales',
    'neuronique',
    'neurotrope',
    'neutralisa',
    'neutralise',
    'neutralisé',
    'neutralité',
    'névralgies',
    'névrilèmes',
    'névritique',
    'névroglies',
    'névropathe',
    'névrotique',
    'niaisaient',
    'niaisasses',
    'niaisement',
    'niaiserais',
    'niaiserait',
    'niaisèrent',
    'niaiseries',
    'niaiseriez',
    'niaiserons',
    'niaiseront',
    'nichassent',
    'nichassiez',
    'nicherions',
    'nickelages',
    'nickelâmes',
    'nickelasse',
    'nickelâtes',
    'nickelions',
    'nickellent',
    'nickellera',
    'nicotinisa',
    'nicotinise',
    'nicotinisé',
    'nictations',
    'nictitante',
    'nictitants',
    'nidifiâmes',
    'nidifiasse',
    'nidifiâtes',
    'nidifierai',
    'nidifieras',
    'nidifierez',
    'nidifiions',
    'niellaient',
    'niellasses',
    'niellerais',
    'niellerait',
    'niellèrent',
    'nielleriez',
    'niellerons',
    'nielleront',
    'nigauderie',
    'nihilismes',
    'nihilistes',
    'nilotiques',
    'nimbassent',
    'nimbassiez',
    'nimberions',
    'nippassent',
    'nippassiez',
    'nipperions',
    'nitescence',
    'nitrassent',
    'nitrassiez',
    'nitratâmes',
    'nitratasse',
    'nitratâtes',
    'nitraterai',
    'nitrateras',
    'nitraterez',
    'nitrations',
    'nitrerions',
    'nitrifiais',
    'nitrifiait',
    'nitrifiant',
    'nitrifiées',
    'nitrifient',
    'nitrifiera',
    'nitrifiiez',
    'nitrifions',
    'nitrophile',
    'nitrurâmes',
    'nitrurasse',
    'nitrurâtes',
    'nitrurerai',
    'nitrureras',
    'nitrurerez',
    'nitrurions',
    'nivelaient',
    'nivelasses',
    'nivelèrent',
    'nivelettes',
    'niveleuses',
    'nivellerai',
    'nivelleras',
    'nivellerez',
    'nivernaise',
    'nobiliaire',
    'noblaillon',
    'noctambule',
    'noctiluque',
    'noctuelles',
    'nodulaires',
    'noduleuses',
    'noieraient',
    'noircirais',
    'noircirait',
    'noircirent',
    'noirciriez',
    'noircirons',
    'noirciront',
    'noircisses',
    'noircissez',
    'noiseraies',
    'noisetiers',
    'nolisaient',
    'nolisasses',
    'noliserais',
    'noliserait',
    'nolisèrent',
    'noliseriez',
    'noliserons',
    'noliseront',
    'nomadisais',
    'nomadisait',
    'nomadisant',
    'nomadisées',
    'nomadisent',
    'nomadisera',
    'nomadisiez',
    'nomadismes',
    'nomadisons',
    'nombrables',
    'nombraient',
    'nombrasses',
    'nombrèrent',
    'nombreuses',
    'nominalisa',
    'nominalise',
    'nominalisé',
    'nominatifs',
    'nomination',
    'nominative',
    'nommassent',
    'nommassiez',
    'nommerions',
    'nomogramme',
    'non-aligné',
    'nonantième',
    'nonchalant',
    'nonchaloir',
    'nonciature',
    'non-engagé',
    'non-fumeur',
    'non-initié',
    'nonobstant',
    'nonpareils',
    'non-retour',
    'non-tissés',
    'non-usages',
    'non-valeur',
    'non-viable',
    'noologique',
    'nopassions',
    'noperaient',
    'nordirions',
    'nordissais',
    'nordissait',
    'nordissant',
    'nordissent',
    'nordissiez',
    'nordissons',
    'nord-ouest',
    'normaliens',
    'normalisai',
    'normalisas',
    'normalisât',
    'normalisée',
    'normaliser',
    'normalises',
    'normalisés',
    'normalisez',
    'normatives',
    'norvégiens',
    'nosologies',
    'nostalgies',
    'notabilité',
    'notairesse',
    'notariales',
    'notassions',
    'noteraient',
    'notifiâmes',
    'notifiasse',
    'notifiâtes',
    'notifierai',
    'notifieras',
    'notifierez',
    'notifiions',
    'notionnels',
    'notonectes',
    'notoriétés',
    'Notre-Dame',
    'nouassions',
    'noueraient',
    'nougatines',
    'nourricier',
    'nourrirent',
    'nourrirrai',
    'nourrisses',
    'nourrissez',
    'nourrisson',
    'nourriture',
    'nous-mêmes',
    'nouveau-né',
    'nouveautés',
    'novassions',
    'novatoires',
    'novatrices',
    'noveraient',
    'noyassions',
    'noyautages',
    'noyautâmes',
    'noyautasse',
    'noyautâtes',
    'noyauterai',
    'noyauteras',
    'noyauterez',
    'noyautions',
    'nuançaient',
    'nuançasses',
    'nuancerais',
    'nuancerait',
    'nuancèrent',
    'nuanceriez',
    'nuancerons',
    'nuanceront',
    'nucléaient',
    'nucléaires',
    'nucléasses',
    'nucléerais',
    'nucléerait',
    'nucléèrent',
    'nucléeriez',
    'nucléerons',
    'nucléeront',
    'nucléiques',
    'nucléotide',
    'nuisissent',
    'nuisissiez',
    'nuitamment',
    'nullipares',
    'numéraires',
    'numérateur',
    'numération',
    'numériques',
    'numérisais',
    'numérisait',
    'numérisant',
    'numérisées',
    'numérisent',
    'numérisera',
    'numérisiez',
    'numérisons',
    'numérotage',
    'numérotais',
    'numérotait',
    'numérotant',
    'numérotées',
    'numérotent',
    'numérotera',
    'numéroteur',
    'numérotiez',
    'numérotons',
    'numismates',
    'nummulites',
    'nuptialité',
    'nutriments',
    'nutritions',
    'nutritives',
    'nyctalopes',
    'nyctalopie',
    'nycthémère',
    'nymphalidé',
    'nymphettes',
    'nymphomane',
    'obédiences',
    'obéiraient',
    'obéissance',
    'obéissante',
    'obéissants',
    'obéissions',
    'obélisques',
    'obérassent',
    'obérassiez',
    'obérerions',
    'obituaires',
    'objectales',
    'objectâmes',
    'objectasse',
    'objectâtes',
    'objecterai',
    'objecteras',
    'objecterez',
    'objecteurs',
    'objections',
    'objectivai',
    'objectivas',
    'objectivât',
    'objectivée',
    'objectiver',
    'objectives',
    'objectivés',
    'objectivez',
    'objurguais',
    'objurguait',
    'objurguant',
    'objurguent',
    'objurguera',
    'objurguiez',
    'objurguons',
    'obligation',
    'obligeâmes',
    'obligeance',
    'obligeante',
    'obligeants',
    'obligeasse',
    'obligeâtes',
    'obligerais',
    'obligerait',
    'obligèrent',
    'obligeriez',
    'obligerons',
    'obligeront',
    'obliquâmes',
    'obliquasse',
    'obliquâtes',
    'obliquerai',
    'obliqueras',
    'obliquerez',
    'obliquions',
    'obliquités',
    'oblitérais',
    'oblitérait',
    'oblitérant',
    'oblitérées',
    'oblitèrent',
    'oblitérera',
    'oblitériez',
    'oblitérons',
    'obnubilais',
    'obnubilait',
    'obnubilant',
    'obnubilées',
    'obnubilent',
    'obnubilera',
    'obnubiliez',
    'obnubilons',
    'obombrâmes',
    'obombrasse',
    'obombrâtes',
    'obombrerai',
    'obombreras',
    'obombrerez',
    'obombrions',
    'obscénités',
    'obscurcies',
    'obscurcira',
    'obscurités',
    'obsédaient',
    'obsédasses',
    'obséderais',
    'obséderait',
    'obsédèrent',
    'obséderiez',
    'obséderons',
    'obséderont',
    'obséquieux',
    'observable',
    'observâmes',
    'observance',
    'observasse',
    'observâtes',
    'observerai',
    'observeras',
    'observerez',
    'observions',
    'obsessions',
    'obsidienne',
    'obsidional',
    'obstinâmes',
    'obstinâtes',
    'obstinerez',
    'obstinions',
    'obstruâmes',
    'obstruasse',
    'obstruâtes',
    'obstructif',
    'obstruerai',
    'obstrueras',
    'obstruerez',
    'obstruions',
    'obtempérai',
    'obtempéras',
    'obtempérât',
    'obtempérer',
    'obtempères',
    'obtempérez',
    'obtenaient',
    'obtentions',
    'obtiendrai',
    'obtiendras',
    'obtiendrez',
    'obtiennent',
    'obtinssent',
    'obtinssiez',
    'obturaient',
    'obturasses',
    'obturateur',
    'obturation',
    'obturerais',
    'obturerait',
    'obturèrent',
    'obtureriez',
    'obturerons',
    'obtureront',
    'obtusangle',
    'obvenaient',
    'obviassent',
    'obviassiez',
    'obviendrai',
    'obviendras',
    'obviendrez',
    'obviennent',
    'obvierions',
    'obvinssent',
    'obvinssiez',
    'occasionna',
    'occasionne',
    'occasionné',
    'occidental',
    'occipitale',
    'occipitaux',
    'occluaient',
    'occlurions',
    'occlusions',
    'occlusives',
    'occlussent',
    'occlussiez',
    'occultâmes',
    'occultasse',
    'occultâtes',
    'occulterai',
    'occulteras',
    'occulterez',
    'occultions',
    'occultisme',
    'occultiste',
    'occupaient',
    'occupantes',
    'occupasses',
    'occupation',
    'occuperais',
    'occuperait',
    'occupèrent',
    'occuperiez',
    'occuperons',
    'occuperont',
    'occurrence',
    'occurrente',
    'occurrents',
    'océanautes',
    'océanienne',
    'océaniques',
    'ocrassions',
    'ocreraient',
    'octaviâmes',
    'octaviasse',
    'octaviâtes',
    'octavierai',
    'octavieras',
    'octavierez',
    'octaviions',
    'octogonale',
    'octogonaux',
    'octostyles',
    'octroierai',
    'octroieras',
    'octroierez',
    'octroyâmes',
    'octroyasse',
    'octroyâtes',
    'octroyions',
    'octuplâmes',
    'octuplasse',
    'octuplâtes',
    'octuplerai',
    'octupleras',
    'octuplerez',
    'octuplions',
    'oculariste',
    'ocytocines',
    'odalisques',
    'odontalgie',
    'odontoïdes',
    'oedémateux',
    'oedicnèmes',
    'oedipienne',
    'oeilletons',
    'oeillettes',
    'oekoumènes',
    'oenilismes',
    'oenoliques',
    'oenolismes',
    'oenologies',
    'oenologues',
    'oenométrie',
    'oenotheras',
    'oenothères',
    'oesophages',
    'oestrogène',
    'oeuvraient',
    'oeuvrasses',
    'oeuvrerais',
    'oeuvrerait',
    'oeuvrèrent',
    'oeuvreriez',
    'oeuvrerons',
    'oeuvreront',
    'offensâmes',
    'offensante',
    'offensants',
    'offensasse',
    'offensâtes',
    'offenserai',
    'offenseras',
    'offenserez',
    'offenseurs',
    'offensions',
    'offensives',
    'offertoire',
    'officiâmes',
    'officiante',
    'officiants',
    'officiasse',
    'officiâtes',
    'officielle',
    'officierai',
    'officieras',
    'officierez',
    'officieuse',
    'officiions',
    'officinale',
    'officinaux',
    'offririons',
    'offrissent',
    'offrissiez',
    'offusquais',
    'offusquait',
    'offusquant',
    'offusquées',
    'offusquent',
    'offusquera',
    'offusquiez',
    'offusquons',
    'oghamiques',
    'oignonades',
    'oignonière',
    'oiselaient',
    'oiselasses',
    'oiselèrent',
    'oiselières',
    'oisellerai',
    'oiselleras',
    'oisellerez',
    'oisellerie',
    'oisivement',
    'oléagineux',
    'oléfiantes',
    'oléiformes',
    'oléomètres',
    'olfactions',
    'olfactives',
    'oligarchie',
    'oligarques',
    'oligocènes',
    'oligopoles',
    'olivaisons',
    'oliveraies',
    'olivétains',
    'olographes',
    'olympiades',
    'olympienne',
    'olympiques',
    'olympismes',
    'ombellules',
    'ombilicale',
    'ombilicaux',
    'ombiliquée',
    'ombiliqués',
    'ombrageais',
    'ombrageait',
    'ombrageant',
    'ombrageons',
    'ombragerai',
    'ombrageras',
    'ombragerez',
    'ombrageuse',
    'ombragions',
    'ombrassent',
    'ombrassiez',
    'ombrerions',
    'ombriennes',
    'omettaient',
    'omettrions',
    'omnicolore',
    'omnipotent',
    'omniscient',
    'omnisports',
    'oncologies',
    'oncotiques',
    'onctueuses',
    'onctuosité',
    'ondemètres',
    'ondoiement',
    'ondoierais',
    'ondoierait',
    'ondoieriez',
    'ondoierons',
    'ondoieront',
    'ondoyaient',
    'ondoyantes',
    'ondoyasses',
    'ondoyèrent',
    'ondulaient',
    'ondulantes',
    'ondulasses',
    'ondulation',
    'ondulerais',
    'ondulerait',
    'ondulèrent',
    'onduleriez',
    'ondulerons',
    'onduleront',
    'onduleuses',
    'onguiculée',
    'onguiculés',
    'onguiforme',
    'onirologie',
    'onirologue',
    'onkotiques',
    'onomatopée',
    'ontogenèse',
    'ontogénies',
    'ontologies',
    'onusiennes',
    'oolithique',
    'opacifiais',
    'opacifiait',
    'opacifiant',
    'opacifiées',
    'opacifient',
    'opacifiera',
    'opacifiiez',
    'opacifions',
    'opalescent',
    'opalisâmes',
    'opalisasse',
    'opalisâtes',
    'opaliserai',
    'opaliseras',
    'opaliserez',
    'opalisions',
    'opérassent',
    'opérassiez',
    'opérateurs',
    'opérations',
    'opératoire',
    'opératrice',
    'operculées',
    'opérerions',
    'ophicléide',
    'ophidienne',
    'ophiologie',
    'ophtalmies',
    'opiaçaient',
    'opiaçasses',
    'opiacerais',
    'opiacerait',
    'opiacèrent',
    'opiaceriez',
    'opiacerons',
    'opiaceront',
    'opinassent',
    'opinassiez',
    'opinerions',
    'opiniâtrée',
    'opiniâtrer',
    'opiniâtres',
    'opiniâtrés',
    'opiniâtrez',
    'opiomanies',
    'opportunes',
    'opposables',
    'opposaient',
    'opposantes',
    'opposasses',
    'opposerais',
    'opposerait',
    'opposèrent',
    'opposeriez',
    'opposerons',
    'opposeront',
    'opposition',
    'oppressais',
    'oppressait',
    'oppressant',
    'oppressées',
    'oppressent',
    'oppressera',
    'oppresseur',
    'oppressiez',
    'oppressifs',
    'oppression',
    'oppressive',
    'oppressons',
    'opprimâmes',
    'opprimante',
    'opprimasse',
    'opprimâtes',
    'opprimerai',
    'opprimeras',
    'opprimerez',
    'opprimions',
    'optassions',
    'opteraient',
    'opticienne',
    'optimalisa',
    'optimalise',
    'optimalisé',
    'optimisais',
    'optimisait',
    'optimisant',
    'optimisées',
    'optimisent',
    'optimisera',
    'optimisiez',
    'optimismes',
    'optimisons',
    'optimistes',
    'optionnels',
    'optométrie',
    'orangeades',
    'orangeâmes',
    'orangeasse',
    'orangeâtes',
    'orangeraie',
    'orangerais',
    'orangerait',
    'orangèrent',
    'orangeries',
    'orangeriez',
    'orangerons',
    'orangeront',
    'orangistes',
    'oratoriens',
    'orbitaient',
    'orbitaires',
    'orbitasses',
    'orbiterais',
    'orbiterait',
    'orbitèrent',
    'orbiteriez',
    'orbiterons',
    'orbiteront',
    'orcanettes',
    'orchestrai',
    'orchestral',
    'orchestras',
    'orchestrât',
    'orchestrée',
    'orchestrer',
    'orchestres',
    'orchestrés',
    'orchestrez',
    'ordinaires',
    'ordinateur',
    'ordination',
    'ordonnâmes',
    'ordonnança',
    'ordonnance',
    'ordonnancé',
    'ordonnasse',
    'ordonnâtes',
    'ordonnerai',
    'ordonneras',
    'ordonnerez',
    'ordonnions',
    'ordurières',
    'oreillarde',
    'oreillards',
    'oreillette',
    'orfèvrerie',
    'organiques',
    'organisais',
    'organisait',
    'organisant',
    'organisées',
    'organisent',
    'organisera',
    'organisiez',
    'organismes',
    'organisons',
    'organistes',
    'organsinai',
    'organsinas',
    'organsinât',
    'organsinée',
    'organsiner',
    'organsines',
    'organsinés',
    'organsinez',
    'orgastique',
    'orichalque',
    'orientable',
    'orientales',
    'orientâmes',
    'orientasse',
    'orientâtes',
    'orienterai',
    'orienteras',
    'orienterez',
    'orienteurs',
    'orienteuse',
    'orientions',
    'oriflammes',
    'originaire',
    'originales',
    'originelle',
    'oringuâmes',
    'oringuasse',
    'oringuâtes',
    'oringuerai',
    'oringueras',
    'oringuerez',
    'oringuions',
    'orléaniste',
    'ornassions',
    'ornementai',
    'ornemental',
    'ornementas',
    'ornementât',
    'ornementée',
    'ornementer',
    'ornementes',
    'ornementés',
    'ornementez',
    'orneraient',
    'orobanches',
    'orogenèses',
    'orogénique',
    'orographie',
    'oropharynx',
    'orpaillage',
    'orpailleur',
    'orphelinat',
    'orphelines',
    'orthodoxes',
    'orthodoxie',
    'orthogénie',
    'orthogonal',
    'orthopédie',
    'orthoptère',
    'oryctérope',
    'oscabrions',
    'oscillâmes',
    'oscillante',
    'oscillants',
    'oscillasse',
    'oscillâtes',
    'oscillerai',
    'oscilleras',
    'oscillerez',
    'oscillions',
    'osculation',
    'osmomètres',
    'osmotiques',
    'ossianique',
    'ossifiâmes',
    'ossifiasse',
    'ossifiâtes',
    'ossifierai',
    'ossifieras',
    'ossifierez',
    'ossifiions',
    'ostéalgies',
    'ostensible',
    'ostensoirs',
    'ostéologie',
    'ostéopathe',
    'ostéophyte',
    'ostéotomie',
    'ostracisai',
    'ostracisas',
    'ostracisât',
    'ostracisée',
    'ostraciser',
    'ostracises',
    'ostracisés',
    'ostracisez',
    'ostracisme',
    'ostréicole',
    'ostrogotes',
    'ostrogothe',
    'oto-rhinos',
    'otorragies',
    'ouananiche',
    'ouaouarons',
    'ouatassent',
    'ouatassiez',
    'ouaterions',
    'ouatinâmes',
    'ouatinasse',
    'ouatinâtes',
    'ouatinerai',
    'ouatineras',
    'ouatinerez',
    'ouatinions',
    'oubliaient',
    'oubliasses',
    'oublierais',
    'oublierait',
    'oublièrent',
    'oublieriez',
    'oublierons',
    'oublieront',
    'oubliettes',
    'oublieuses',
    'ougriennes',
    'ouillaient',
    'ouillasses',
    'ouillerais',
    'ouillerait',
    'ouillèrent',
    'ouilleriez',
    'ouillerons',
    'ouilleront',
    'ouralienne',
    'ourdirions',
    'ourdissage',
    'ourdissais',
    'ourdissait',
    'ourdissant',
    'ourdissent',
    'ourdisseur',
    'ourdissiez',
    'ourdissoir',
    'ourdissons',
    'ourlassent',
    'ourlassiez',
    'ourlerions',
    'ourliennes',
    'outardeaux',
    'outillages',
    'outillâmes',
    'outillasse',
    'outillâtes',
    'outillerai',
    'outilleras',
    'outillerez',
    'outilleurs',
    'outillions',
    'outrageais',
    'outrageait',
    'outrageant',
    'outrageons',
    'outragerai',
    'outrageras',
    'outragerez',
    'outrageuse',
    'outragions',
    'outrancier',
    'outrassent',
    'outrassiez',
    'outrepassa',
    'outrepasse',
    'outrepassé',
    'outrerions',
    'ouvertures',
    'ouvrageais',
    'ouvrageait',
    'ouvrageant',
    'ouvrageons',
    'ouvragerai',
    'ouvrageras',
    'ouvragerez',
    'ouvragions',
    'ouvraisons',
    'ouvrassent',
    'ouvrassiez',
    'ouvrerions',
    'ouvririons',
    'ouvrissent',
    'ouvrissiez',
    'ovalbumine',
    'ovalisâmes',
    'ovalisasse',
    'ovalisâtes',
    'ovaliserai',
    'ovaliseras',
    'ovaliserez',
    'ovalisions',
    'ovariennes',
    'ovationnai',
    'ovationnas',
    'ovationnât',
    'ovationnée',
    'ovationner',
    'ovationnes',
    'ovationnés',
    'ovationnez',
    'oviparités',
    'oviscaptes',
    'ovogenèses',
    'ovulassent',
    'ovulassiez',
    'ovulations',
    'ovulerions',
    'oxhydrique',
    'oxycarboné',
    'oxycoupage',
    'oxydassent',
    'oxydassiez',
    'oxydations',
    'oxyderions',
    'oxygénâmes',
    'oxygénasse',
    'oxygénâtes',
    'oxygénerai',
    'oxygéneras',
    'oxygénerez',
    'oxygénions',
    'oxysulfure',
    'ozocérites',
    'ozokérites',
    'ozonisâmes',
    'ozonisasse',
    'ozonisâtes',
    'ozoniserai',
    'ozoniseras',
    'ozoniserez',
    'ozoniseurs',
    'ozonisions',
    'pacageâmes',
    'pacageasse',
    'pacageâtes',
    'pacagerais',
    'pacagerait',
    'pacagèrent',
    'pacageriez',
    'pacagerons',
    'pacageront',
    'pacemakers',
    'pachyderme',
    'pacifiâmes',
    'pacifiasse',
    'pacifiâtes',
    'pacifierai',
    'pacifieras',
    'pacifierez',
    'pacifiions',
    'pacifiques',
    'pacifismes',
    'pacifistes',
    'pacotilles',
    'pacquaient',
    'pacquasses',
    'pacquerais',
    'pacquerait',
    'pacquèrent',
    'pacqueriez',
    'pacquerons',
    'pacqueront',
    'pactisâmes',
    'pactisasse',
    'pactisâtes',
    'pactiserai',
    'pactiseras',
    'pactiserez',
    'pactisions',
    'padischahs',
    'pagaierais',
    'pagaierait',
    'pagaieriez',
    'pagaierons',
    'pagaieront',
    'paganisais',
    'paganisait',
    'paganisant',
    'paganisées',
    'paganisent',
    'paganisera',
    'paganisiez',
    'paganismes',
    'paganisons',
    'pagayaient',
    'pagayasses',
    'pagayerais',
    'pagayerait',
    'pagayèrent',
    'pagayeriez',
    'pagayerons',
    'pagayeront',
    'pagayeuses',
    'pageassent',
    'pageassiez',
    'pageotâmes',
    'pageotasse',
    'pageotâtes',
    'pageoterai',
    'pageoteras',
    'pageoterez',
    'pageotions',
    'pageraient',
    'paginaient',
    'paginasses',
    'pagination',
    'paginerais',
    'paginerait',
    'paginèrent',
    'pagineriez',
    'paginerons',
    'pagineront',
    'pagnotâmes',
    'pagnotasse',
    'pagnotâtes',
    'pagnoterai',
    'pagnoteras',
    'pagnoterez',
    'pagnotions',
    'paidologie',
    'paieraient',
    'paillaient',
    'paillardai',
    'paillardas',
    'paillardât',
    'paillardée',
    'paillarder',
    'paillardes',
    'paillardés',
    'paillardez',
    'paillasses',
    'paillasson',
    'paillerais',
    'paillerait',
    'paillèrent',
    'pailleriez',
    'paillerons',
    'pailleront',
    'pailletage',
    'pailletais',
    'pailletait',
    'pailletant',
    'pailletées',
    'pailleteur',
    'pailletiez',
    'pailletons',
    'paillettes',
    'pailleuses',
    'paillonnai',
    'paillonnas',
    'paillonnât',
    'paillonnée',
    'paillonner',
    'paillonnes',
    'paillonnés',
    'paillonnez',
    'paissaient',
    'paissances',
    'paisselais',
    'paisselait',
    'paisselant',
    'paisselées',
    'paisseliez',
    'paisselles',
    'paisselons',
    'paîtraient',
    'pajotaient',
    'pajotasses',
    'pajoterais',
    'pajoterait',
    'pajotèrent',
    'pajoteriez',
    'pajoterons',
    'pajoteront',
    'palabrâmes',
    'palabrasse',
    'palabrâtes',
    'palabrerai',
    'palabreras',
    'palabrerât',
    'palabrions',
    'palancrais',
    'palancrait',
    'palancrant',
    'palancrées',
    'palancrent',
    'palancrera',
    'palancriez',
    'palancrons',
    'palangrais',
    'palangrait',
    'palangrant',
    'palangrées',
    'palangrent',
    'palangrera',
    'palangriez',
    'palangrons',
    'palanguais',
    'palanguait',
    'palanguant',
    'palanguent',
    'palanguera',
    'palanguiez',
    'palanguons',
    'palanquais',
    'palanquait',
    'palanquant',
    'palanquées',
    'palanquent',
    'palanquera',
    'palanquiez',
    'palanquins',
    'palanquons',
    'palatalisé',
    'palatinats',
    'palettâmes',
    'palettasse',
    'palettâtes',
    'paletterai',
    'paletteras',
    'paletterez',
    'palettions',
    'palettisai',
    'palettisas',
    'palettisât',
    'palettisée',
    'palettiser',
    'palettises',
    'palettisés',
    'palettisez',
    'palétuvier',
    'pâlichonne',
    'palilalies',
    'palindrome',
    'palinodies',
    'pâliraient',
    'palissadai',
    'palissadas',
    'palissadât',
    'palissadée',
    'palissader',
    'palissades',
    'palissadés',
    'palissadez',
    'palissages',
    'palissâmes',
    'pâlissante',
    'pâlissants',
    'palissasse',
    'palissâtes',
    'palisserai',
    'palisseras',
    'palisserez',
    'palissions',
    'pâlissions',
    'palissonna',
    'palissonne',
    'palissonné',
    'palliaient',
    'palliasses',
    'palliatifs',
    'palliative',
    'pallierais',
    'pallierait',
    'pallièrent',
    'pallieriez',
    'pallierons',
    'pallieront',
    'palmassent',
    'palmassiez',
    'palmatures',
    'palmeraies',
    'palmerions',
    'palmifides',
    'palmilobée',
    'palmilobés',
    'palmiparti',
    'palmipèdes',
    'palmiséqué',
    'palmitines',
    'palmitique',
    'palonniers',
    'palotaient',
    'palotasses',
    'paloterais',
    'paloterait',
    'palotèrent',
    'paloteriez',
    'paloterons',
    'paloteront',
    'palpassent',
    'palpassiez',
    'palpations',
    'palpébrale',
    'palpébraux',
    'palperions',
    'palpitâmes',
    'palpitante',
    'palpitants',
    'palpitasse',
    'palpitâtes',
    'palpiterai',
    'palpiteras',
    'palpiterez',
    'palpitions',
    'palplanche',
    'palsambleu',
    'paltoquets',
    'paludéenne',
    'paludières',
    'paludismes',
    'pâmassions',
    'pâmeraient',
    'panachages',
    'panachâmes',
    'panachasse',
    'panachâtes',
    'panacherai',
    'panacheras',
    'panacherez',
    'panachions',
    'panachures',
    'panassions',
    'panatellas',
    'pancalisme',
    'paneraient',
    'paneteries',
    'panetières',
    'paniculées',
    'panifiable',
    'panifiâmes',
    'panifiasse',
    'panifiâtes',
    'panifierai',
    'panifieras',
    'panifierez',
    'panifiions',
    'paniquâmes',
    'paniquante',
    'paniquants',
    'paniquarde',
    'paniquards',
    'paniquasse',
    'paniquâtes',
    'paniquerai',
    'paniqueras',
    'paniquerez',
    'paniquions',
    'panlogisme',
    'pannassent',
    'pannassiez',
    'panneautai',
    'panneautas',
    'panneautât',
    'panneautée',
    'panneauter',
    'panneautes',
    'panneautés',
    'panneautez',
    'pannerions',
    'pannicules',
    'panonceaux',
    'panoptique',
    'pansassent',
    'pansassiez',
    'pansements',
    'panserions',
    'panspermie',
    'pantelâmes',
    'pantelante',
    'pantelants',
    'pantelasse',
    'pantelâtes',
    'pantelions',
    'pantellent',
    'pantellera',
    'panthéisme',
    'panthéiste',
    'pantomètre',
    'pantomimes',
    'pantouflai',
    'pantouflas',
    'pantouflât',
    'pantoufler',
    'pantoufles',
    'pantouflez',
    'papavérine',
    'papelardes',
    'paperasses',
    'papeteries',
    'papetières',
    'papillaire',
    'papilleuse',
    'papillomes',
    'papillonna',
    'papillonne',
    'papillonné',
    'papillotai',
    'papillotas',
    'papillotât',
    'papillotée',
    'papilloter',
    'papillotes',
    'papillotés',
    'papillotez',
    'papotaient',
    'papotasses',
    'papoterais',
    'papoterait',
    'papotèrent',
    'papoteriez',
    'papoterons',
    'papoteront',
    'papouillai',
    'papouillas',
    'papouillât',
    'papouillée',
    'papouiller',
    'papouilles',
    'papouillés',
    'papouillez',
    'papuleuses',
    'pâquerette',
    'paquetages',
    'paqueteurs',
    'paqueteuse',
    'parabellum',
    'parabioses',
    'parachevai',
    'parachevas',
    'parachevât',
    'parachevée',
    'parachever',
    'parachevés',
    'parachèves',
    'parachevez',
    'parachutai',
    'parachutas',
    'parachutât',
    'parachutée',
    'parachuter',
    'parachutes',
    'parachutés',
    'parachutez',
    'paradaient',
    'paradasses',
    'paraderais',
    'paraderait',
    'paradèrent',
    'paraderiez',
    'paraderons',
    'paraderont',
    'paradigmes',
    'paradisier',
    'paradoxale',
    'paradoxaux',
    'parafaient',
    'parafasses',
    'paraferais',
    'paraferait',
    'parafèrent',
    'paraferiez',
    'paraferons',
    'paraferont',
    'paraffinai',
    'paraffinas',
    'paraffinât',
    'paraffinée',
    'paraffiner',
    'paraffines',
    'paraffinés',
    'paraffinez',
    'parafiscal',
    'parafoudre',
    'paragraphe',
    'paragrêles',
    'paraisonna',
    'paraisonne',
    'paraisonné',
    'paraissais',
    'paraissait',
    'paraissant',
    'paraissent',
    'paraissiez',
    'paraissons',
    'paraîtrais',
    'paraîtrait',
    'paraîtriez',
    'paraîtrons',
    'paraîtront',
    'parallaxes',
    'parallèles',
    'paralysais',
    'paralysait',
    'paralysant',
    'paralysées',
    'paralysent',
    'paralysera',
    'paralysies',
    'paralysiez',
    'paralysons',
    'paramécies',
    'paramétrai',
    'paramétras',
    'paramétrât',
    'paramétrée',
    'paramétrer',
    'paramétrés',
    'paramètres',
    'paramétrez',
    'parangonna',
    'parangonne',
    'parangonné',
    'paranoïdes',
    'paranormal',
    'paraphâmes',
    'paraphasie',
    'paraphasse',
    'paraphâtes',
    'parapherai',
    'parapheras',
    'parapherez',
    'paraphions',
    'paraphrasa',
    'paraphrase',
    'paraphrasé',
    'paraphyses',
    'paraplégie',
    'parapluies',
    'parascèves',
    'parasitais',
    'parasitait',
    'parasitant',
    'parasitées',
    'parasitent',
    'parasitera',
    'parasitiez',
    'parasitons',
    'parasitose',
    'parassions',
    'parastatal',
    'parcellais',
    'parcellait',
    'parcellant',
    'parcellées',
    'parcellent',
    'parcellera',
    'parcelliez',
    'parcellisa',
    'parcellise',
    'parcellisé',
    'parcellons',
    'parchemina',
    'parchemine',
    'parcheminé',
    'parchemins',
    'parcimonie',
    'parcmètres',
    'parcomètre',
    'parcourais',
    'parcourait',
    'parcourant',
    'parcourent',
    'parcouriez',
    'parcourons',
    'parcourrai',
    'parcourras',
    'parcourrez',
    'parcourues',
    'par-dessus',
    'par-devant',
    'par-devers',
    'pardonnais',
    'pardonnait',
    'pardonnant',
    'pardonnées',
    'pardonnent',
    'pardonnera',
    'pardonniez',
    'pardonnons',
    'pare-brise',
    'pare-chocs',
    'pare-fumée',
    'parementai',
    'parementas',
    'parementât',
    'parementée',
    'parementer',
    'parementes',
    'parementés',
    'parementez',
    'parenchyme',
    'parentales',
    'parentéral',
    'parenthésé',
    'parenthèse',
    'parerasses',
    'parerèrent',
    'paressâmes',
    'paressasse',
    'paressâtes',
    'paresserai',
    'paresseras',
    'paresserez',
    'paresseuse',
    'paressions',
    'parfaisais',
    'parfaisait',
    'parfaisant',
    'parfaisiez',
    'parfaisons',
    'parfassent',
    'parfassiez',
    'parferions',
    'parfilages',
    'parfilâmes',
    'parfilasse',
    'parfilâtes',
    'parfilerai',
    'parfileras',
    'parfilerez',
    'parfilions',
    'parfissent',
    'parfissiez',
    'parfondais',
    'parfondait',
    'parfondant',
    'parfondent',
    'parfondiez',
    'parfondons',
    'parfondrai',
    'parfondras',
    'parfondrez',
    'parfondues',
    'parfumâmes',
    'parfumasse',
    'parfumâtes',
    'parfumerai',
    'parfumeras',
    'parfumerez',
    'parfumerie',
    'parfumeurs',
    'parfumeuse',
    'parfumions',
    'pariassent',
    'pariassiez',
    'parierions',
    'pariétaire',
    'pariétales',
    'paripennée',
    'paripennés',
    'parisettes',
    'parisienne',
    'paritaires',
    'parjurâmes',
    'parjurasse',
    'parjurâtes',
    'parjurerai',
    'parjureras',
    'parjurerez',
    'parjurions',
    'parkérisai',
    'parkérisas',
    'parkérisât',
    'parkérisée',
    'parkériser',
    'parkérises',
    'parkérisés',
    'parkérisez',
    'parkinsons',
    'parlassent',
    'parlassiez',
    'parlementa',
    'parlemente',
    'parlementé',
    'parlements',
    'parlerions',
    'parlotâmes',
    'parlotasse',
    'parlotâtes',
    'parloterai',
    'parloteras',
    'parloterez',
    'parlotions',
    'parmenture',
    'parnassien',
    'parodiâmes',
    'parodiasse',
    'parodiâtes',
    'parodierai',
    'parodieras',
    'parodierez',
    'parodiions',
    'parodiques',
    'parodistes',
    'paroissial',
    'paroissien',
    'parolières',
    'paronomase',
    'paronymies',
    'parotidien',
    'parotidite',
    'paroxysmal',
    'paroxysmes',
    'paroxytons',
    'parpaillot',
    'parquaient',
    'parquasses',
    'parquerais',
    'parquerait',
    'parquèrent',
    'parqueriez',
    'parquerons',
    'parqueront',
    'parquetage',
    'parquetais',
    'parquetait',
    'parquetant',
    'parquetées',
    'parqueteur',
    'parquetiez',
    'parquetons',
    'parquettes',
    'parqueuses',
    'parrainage',
    'parrainais',
    'parrainait',
    'parrainant',
    'parrainées',
    'parrainent',
    'parrainera',
    'parrainiez',
    'parrainons',
    'parricides',
    'parsemâmes',
    'parsemasse',
    'parsemâtes',
    'parsèmerai',
    'parsèmeras',
    'parsèmerez',
    'parsemions',
    'partageais',
    'partageait',
    'partageant',
    'partageons',
    'partagerai',
    'partageras',
    'partagerez',
    'partageurs',
    'partageuse',
    'partagions',
    'partenaire',
    'partiaires',
    'partialité',
    'participai',
    'participas',
    'participât',
    'participer',
    'participes',
    'participez',
    'particules',
    'partielles',
    'partirions',
    'partisanes',
    'partissent',
    'partissiez',
    'partiteurs',
    'partitions',
    'partitives',
    'parureries',
    'parurières',
    'parussions',
    'parvenions',
    'parviendra',
    'parviennes',
    'parvinrent',
    'parvinsses',
    'passagères',
    'passassent',
    'passassiez',
    'passations',
    'passavants',
    'passéismes',
    'passéistes',
    'passementa',
    'passemente',
    'passementé',
    'passements',
    'passe-pied',
    'passe-plat',
    'passepoila',
    'passepoile',
    'passepoilé',
    'passepoils',
    'passeports',
    'passerages',
    'passereaux',
    'passerelle',
    'passerines',
    'passerions',
    'passe-rose',
    'passiflore',
    'passionnai',
    'passionnas',
    'passionnât',
    'passionnée',
    'passionnel',
    'passionner',
    'passionnes',
    'passionnés',
    'passionnez',
    'passivités',
    'pastellais',
    'pastellait',
    'pastellant',
    'pastellées',
    'pastellent',
    'pastellera',
    'pastelliez',
    'pastellons',
    'pastenague',
    'pasteurien',
    'pasteurisa',
    'pasteurise',
    'pasteurisé',
    'pastichais',
    'pastichait',
    'pastichant',
    'pastichées',
    'pastichent',
    'pastichera',
    'pasticheur',
    'pastichiez',
    'pastichons',
    'pastillais',
    'pastillait',
    'pastillant',
    'pastillées',
    'pastillent',
    'pastillera',
    'pastilliez',
    'pastillons',
    'pastorales',
    'pastoureau',
    'patafiolai',
    'patafiolas',
    'patafiolât',
    'patafiolée',
    'patafioler',
    'patafioles',
    'patafiolés',
    'patafiolez',
    'patarasses',
    'pâtassions',
    'pataugeage',
    'pataugeais',
    'pataugeait',
    'pataugeaît',
    'pataugeant',
    'pataugeons',
    'pataugerai',
    'pataugeras',
    'pataugerez',
    'pataugeurs',
    'pataugeuse',
    'pataugions',
    'patchoulis',
    'patchworks',
    'patelinage',
    'patelinais',
    'patelinait',
    'patelinant',
    'patelinées',
    'patelinent',
    'patelinera',
    'pateliniez',
    'patelinons',
    'patenôtres',
    'patentable',
    'patentages',
    'patentâmes',
    'patentasse',
    'patentâtes',
    'patenterai',
    'patenteras',
    'patenterez',
    'patentions',
    'pâteraient',
    'paternelle',
    'paternités',
    'pathétique',
    'pathétisme',
    'pathogènes',
    'pathogénie',
    'pathologie',
    'patiemment',
    'patientais',
    'patientait',
    'patientant',
    'patientent',
    'patientera',
    'patientiez',
    'patientons',
    'patinaient',
    'patinasses',
    'patinerais',
    'patinerait',
    'patinèrent',
    'patineriez',
    'patinerons',
    'patineront',
    'patinettes',
    'patineuses',
    'patinoires',
    'pâtiraient',
    'pâtissâmes',
    'pâtissasse',
    'pâtissâtes',
    'pâtisserai',
    'pâtisseras',
    'pâtisserez',
    'pâtisserie',
    'pâtissière',
    'pâtissiers',
    'pâtissions',
    'patoisâmes',
    'patoisante',
    'patoisants',
    'patoisasse',
    'patoisâtes',
    'patoiserai',
    'patoiseras',
    'patoiserez',
    'patoisions',
    'patouillai',
    'patouillas',
    'patouillât',
    'patouillée',
    'patouiller',
    'patouilles',
    'patouillés',
    'patouillez',
    'patriarcal',
    'patriarcat',
    'patriarche',
    'patriciats',
    'patriciens',
    'patriclans',
    'patrilocal',
    'patrimoine',
    'patriotard',
    'patrologie',
    'patronages',
    'patronales',
    'patronnais',
    'patronnait',
    'patronnant',
    'patronnées',
    'patronnent',
    'patronnera',
    'patronniez',
    'patronnons',
    'patronymes',
    'patrouilla',
    'patrouille',
    'patrouillé',
    'pattassent',
    'pattassiez',
    'patterions',
    'pâturaient',
    'pâturasses',
    'pâturerais',
    'pâturerait',
    'pâturèrent',
    'pâtureriez',
    'pâturerons',
    'pâtureront',
    'pauchouses',
    'pauliennes',
    'pauliniens',
    'paulinisme',
    'paulownias',
    'paumassent',
    'paumassiez',
    'paumerions',
    'paumoierai',
    'paumoieras',
    'paumoierez',
    'paumoyâmes',
    'paumoyasse',
    'paumoyâtes',
    'paumoyions',
    'paupérisai',
    'paupérisas',
    'paupérisât',
    'paupérisée',
    'paupériser',
    'paupérises',
    'paupérisés',
    'paupérisez',
    'paupérisme',
    'paupiettes',
    'pausassent',
    'pausassiez',
    'pause-café',
    'pauserions',
    'pauvrement',
    'pauvresses',
    'pauvrettes',
    'pavanaient',
    'pavanasses',
    'pavanerais',
    'pavanerait',
    'pavanèrent',
    'pavaneriez',
    'pavanerons',
    'pavaneront',
    'pavassions',
    'paveraient',
    'pavoisâmes',
    'pavoisasse',
    'pavoisâtes',
    'pavoiserai',
    'pavoiseras',
    'pavoiserez',
    'pavoisions',
    'payassions',
    'payeraient',
    'paysagères',
    'paysagiste',
    'paysannats',
    'peaufinais',
    'peaufinait',
    'peaufinant',
    'peaufinées',
    'peaufinent',
    'peaufinera',
    'peaufiniez',
    'peaufinons',
    'peau-rouge',
    'peaussâmes',
    'peaussasse',
    'peaussâtes',
    'peausserai',
    'peausseras',
    'peausserez',
    'peausserie',
    'peaussiers',
    'peaussions',
    'peccadille',
    'péchassent',
    'pêchassent',
    'péchassiez',
    'pêchassiez',
    'pechblende',
    'pécheresse',
    'pécherions',
    'pêcherions',
    'péclotâmes',
    'péclotasse',
    'péclotâtes',
    'pécloterai',
    'pécloteras',
    'pécloterez',
    'péclotions',
    'pécoptéris',
    'pectorales',
    'pécuniaire',
    'pédagogies',
    'pédagogues',
    'pédalaient',
    'pédalasses',
    'pédalerais',
    'pédalerait',
    'pédalèrent',
    'pédaleriez',
    'pédalerons',
    'pédaleront',
    'pédaleuses',
    'pédanterie',
    'pédantisme',
    'pédérastes',
    'pédérastie',
    'pédiatries',
    'pédicelles',
    'pédiculées',
    'pédiculose',
    'pédicuries',
    'pédiplaine',
    'pédogenèse',
    'pédologies',
    'pédologues',
    'pédonculée',
    'pédoncules',
    'pédonculés',
    'pédophiles',
    'pédophilie',
    'pedzouille',
    'pegmatites',
    'peignaient',
    'peignasses',
    'peigne-cul',
    'peignerais',
    'peignerait',
    'peignèrent',
    'peigneriez',
    'peignerons',
    'peigneront',
    'peigneuses',
    'peignirent',
    'peignisses',
    'peignoires',
    'peinassent',
    'peinassiez',
    'peindrions',
    'peinerions',
    'peinturais',
    'peinturait',
    'peinturant',
    'peinturées',
    'peinturent',
    'peinturera',
    'peinturiez',
    'peinturons',
    'péjoratifs',
    'péjoration',
    'péjorative',
    'pékinoises',
    'pélagienne',
    'pélagiques',
    'pélasgiens',
    'pélasgique',
    'pelassions',
    'pèleraient',
    'pèlerinage',
    'pellagreux',
    'pelletages',
    'pelletâmes',
    'pelletasse',
    'pelletâtes',
    'pelleterie',
    'pelleteurs',
    'pelleteuse',
    'pelletière',
    'pelletiers',
    'pelletions',
    'pellettent',
    'pellettera',
    'pelliculée',
    'pellicules',
    'pelliculés',
    'pellucides',
    'pelotaient',
    'pelotasses',
    'peloterais',
    'peloterait',
    'pelotèrent',
    'peloteriez',
    'peloterons',
    'peloteront',
    'peloteuses',
    'pelotonnai',
    'pelotonnas',
    'pelotonnât',
    'pelotonnée',
    'pelotonner',
    'pelotonnes',
    'pelotonnés',
    'pelotonnez',
    'peluchâmes',
    'peluchasse',
    'peluchâtes',
    'pelucherai',
    'pelucheras',
    'pelucherez',
    'pelucheuse',
    'peluchions',
    'pelviennes',
    'pénalement',
    'pénalisais',
    'pénalisait',
    'pénalisant',
    'pénalisées',
    'pénalisent',
    'pénalisera',
    'pénalisiez',
    'pénalisons',
    'penchaient',
    'penchantes',
    'penchasses',
    'pencherais',
    'pencherait',
    'penchèrent',
    'pencheriez',
    'pencherons',
    'pencheront',
    'pendaisons',
    'pendeloque',
    'pendentifs',
    'pendillais',
    'pendillait',
    'pendillant',
    'pendillent',
    'pendillera',
    'pendilliez',
    'pendillons',
    'pendissent',
    'pendissiez',
    'pendouilla',
    'pendouille',
    'pendouillé',
    'pendraient',
    'pendulaire',
    'pendulâmes',
    'pendulasse',
    'pendulâtes',
    'pendulerai',
    'penduleras',
    'pendulerez',
    'pendulette',
    'pendulions',
    'pénéplaine',
    'pénétrable',
    'pénétrâmes',
    'pénétrante',
    'pénétrants',
    'pénétrasse',
    'pénétrâtes',
    'pénétrerai',
    'pénétreras',
    'pénétrerez',
    'pénétrions',
    'pénicillée',
    'pénicillés',
    'péninsules',
    'pénitences',
    'pénitentes',
    'penniforme',
    'pensassent',
    'pensassiez',
    'pense-bête',
    'penserions',
    'pensionnai',
    'pensionnas',
    'pensionnat',
    'pensionnât',
    'pensionnée',
    'pensionner',
    'pensionnes',
    'pensionnés',
    'pensionnez',
    'pentacorde',
    'pentacrine',
    'pentaèdres',
    'pentagonal',
    'pentagones',
    'pentamères',
    'pentamètre',
    'pentarchie',
    'pentathlon',
    'pentatomes',
    'pentecôtes',
    'penthotals',
    'pénultième',
    'pépiassent',
    'pépiassiez',
    'pépiements',
    'pépierions',
    'pépinières',
    'peppermint',
    'péquenaude',
    'péquenauds',
    'perborates',
    'percalines',
    'perçassent',
    'perçassiez',
    'percements',
    'percepteur',
    'perceptifs',
    'perception',
    'perceptive',
    'percerette',
    'percerions',
    'percevable',
    'percevions',
    'percevrais',
    'percevrait',
    'percevriez',
    'percevrons',
    'percevront',
    'perchaient',
    'perchasses',
    'percherais',
    'percherait',
    'perchèrent',
    'percheriez',
    'percherons',
    'percheront',
    'percheuses',
    'perchistes',
    'perçoivent',
    'perçussent',
    'perçussiez',
    'percussion',
    'percutâmes',
    'percutante',
    'percutants',
    'percutasse',
    'percutâtes',
    'percuterai',
    'percuteras',
    'percuterez',
    'percuteurs',
    'percutions',
    'perdissent',
    'perdissiez',
    'perditions',
    'perdraient',
    'perdurable',
    'perdurâmes',
    'perdurasse',
    'perdurâtes',
    'perdurerai',
    'perdureras',
    'perdurerez',
    'perdurions',
    'pérégrinai',
    'pérégrinas',
    'pérégrinât',
    'pérégriner',
    'pérégrines',
    'pérégrinez',
    'péremption',
    'pérennisai',
    'pérennisas',
    'pérennisât',
    'pérennisée',
    'pérenniser',
    'pérennises',
    'pérennisés',
    'pérennisez',
    'pérennités',
    'perfectifs',
    'perfection',
    'perfective',
    'perfoliées',
    'perforages',
    'perforâmes',
    'perforante',
    'perforants',
    'perforasse',
    'perforâtes',
    'perforerai',
    'perforeras',
    'perforerez',
    'perforeuse',
    'perforions',
    'performant',
    'perfusâmes',
    'perfusasse',
    'perfusâtes',
    'perfuserai',
    'perfuseras',
    'perfuserez',
    'perfusions',
    'pergélisol',
    'périanthes',
    'péricardes',
    'péricarpes',
    'périclitai',
    'périclitas',
    'périclitât',
    'péricliter',
    'périclites',
    'périclitez',
    'péricycles',
    'péridurale',
    'périduraux',
    'périhélies',
    'périlleuse',
    'périmaient',
    'périmasses',
    'périmerais',
    'périmerait',
    'périmèrent',
    'périmeriez',
    'périmerons',
    'périmeront',
    'périmètres',
    'périnatale',
    'périnataux',
    'périnéales',
    'périodique',
    'périostite',
    'péripéties',
    'périphérie',
    'périphrasa',
    'périphrase',
    'périphrasé',
    'périptères',
    'périraient',
    'périscopes',
    'périsperme',
    'périssable',
    'périssions',
    'périssoire',
    'péristomes',
    'péristyles',
    'périthèces',
    'péritoines',
    'péritonéal',
    'péritonite',
    'perlassent',
    'perlassiez',
    'perlerions',
    'permafrost',
    'permalloys',
    'permanence',
    'permanenta',
    'permanente',
    'permanenté',
    'permanents',
    'perméables',
    'permettais',
    'permettait',
    'permettant',
    'permettent',
    'permettiez',
    'permettons',
    'permettrai',
    'permettras',
    'permettrez',
    'permiennes',
    'permissent',
    'permissiez',
    'permissifs',
    'permission',
    'permissive',
    'permutable',
    'permutâmes',
    'permutante',
    'permutants',
    'permutasse',
    'permutâtes',
    'permuterai',
    'permuteras',
    'permuterez',
    'permutions',
    'pernicieux',
    'péronières',
    'péronnelle',
    'péroraient',
    'péroraison',
    'pérorasses',
    'pérorerais',
    'pérorerait',
    'pérorèrent',
    'péroreriez',
    'pérorerons',
    'péroreront',
    'péroreuses',
    'peroxydais',
    'péroxydais',
    'peroxydait',
    'péroxydait',
    'peroxydant',
    'péroxydant',
    'peroxydase',
    'peroxydées',
    'péroxydées',
    'peroxydent',
    'péroxydent',
    'peroxydera',
    'péroxydera',
    'peroxydiez',
    'péroxydiez',
    'peroxydons',
    'péroxydons',
    'perpétrais',
    'perpétrait',
    'perpétrant',
    'perpétrées',
    'perpètrent',
    'perpétrera',
    'perpétriez',
    'perpétrons',
    'perpétuais',
    'perpétuait',
    'perpétuant',
    'perpétuées',
    'perpétuels',
    'perpétuent',
    'perpétuera',
    'perpétuiez',
    'perpétuité',
    'perpétuons',
    'perplexité',
    'perroquets',
    'perruquier',
    'persécutai',
    'persécutas',
    'persécutât',
    'persécutée',
    'persécuter',
    'persécutes',
    'persécutés',
    'persécutez',
    'persévérai',
    'persévéras',
    'persévérât',
    'persévérer',
    'persévères',
    'persévérez',
    'persicaire',
    'persiennes',
    'persiflage',
    'persiflais',
    'persiflait',
    'persiflant',
    'persiflées',
    'persiflent',
    'persiflera',
    'persifleur',
    'persifliez',
    'persiflons',
    'persillade',
    'persillais',
    'persillait',
    'persillant',
    'persillées',
    'persillent',
    'persillera',
    'persillère',
    'persilliez',
    'persillons',
    'persistais',
    'persistait',
    'persistant',
    'persistent',
    'persistera',
    'persistiez',
    'persistons',
    'personnage',
    'personnels',
    'perspectif',
    'perspicace',
    'persuadais',
    'persuadait',
    'persuadant',
    'persuadées',
    'persuadent',
    'persuadera',
    'persuadiez',
    'persuadons',
    'persuasifs',
    'persuasion',
    'persuasive',
    'persulfate',
    'persulfure',
    'persulfuré',
    'pertinence',
    'pertinente',
    'pertinents',
    'pertuisane',
    'perturbais',
    'perturbait',
    'perturbant',
    'perturbées',
    'perturbent',
    'perturbera',
    'perturbiez',
    'perturbons',
    'péruvienne',
    'pervenches',
    'perversion',
    'perversité',
    'perverties',
    'pervertira',
    'pervibrage',
    'pervibrais',
    'pervibrait',
    'pervibrant',
    'pervibrées',
    'pervibrent',
    'pervibrera',
    'pervibriez',
    'pervibrons',
    'pesanteurs',
    'pesassions',
    'pèse-acide',
    'pèse-bébés',
    'pèse-moûts',
    'pèseraient',
    'pèse-sirop',
    'pessimisme',
    'pessimiste',
    'pestassent',
    'pestassiez',
    'pesterions',
    'pesticides',
    'pestiférai',
    'pestiféras',
    'pestiférât',
    'pestiférée',
    'pestiférer',
    'pestiférés',
    'pestifères',
    'pestiférez',
    'pestilence',
    'pétalismes',
    'pétaloïdes',
    'pétaradais',
    'pétaradait',
    'pétaradant',
    'pétaradent',
    'pétaradera',
    'pétaradiez',
    'pétaradons',
    'pétardâmes',
    'pétardasse',
    'pétardâtes',
    'pétarderai',
    'pétarderas',
    'pétarderez',
    'pétardions',
    'pétassions',
    'pétaudière',
    'pétauriste',
    'pétéchiale',
    'pétéchiaux',
    'péteraient',
    'pétillâmes',
    'pétillante',
    'pétillants',
    'pétillasse',
    'pétillâtes',
    'pétillerai',
    'pétilleras',
    'pétillerez',
    'pétillions',
    'petit-bois',
    'petitement',
    'petitesses',
    'petit-fils',
    'petit-gris',
    'pétitionna',
    'pétitionne',
    'pétitionné',
    'petit-lait',
    'pétitoires',
    'petit-pois',
    'pétouillai',
    'pétouillas',
    'pétouillât',
    'pétouiller',
    'pétouilles',
    'pétouillez',
    'pétrifiais',
    'pétrifiait',
    'pétrifiant',
    'pétrifiées',
    'pétrifient',
    'pétrifiera',
    'pétrifiiez',
    'pétrifions',
    'pétririons',
    'pétrissage',
    'pétrissais',
    'pétrissait',
    'pétrissant',
    'pétrissent',
    'pétrisseur',
    'pétrissiez',
    'pétrissons',
    'pétrogales',
    'pétrolette',
    'pétroleuse',
    'pétrolière',
    'pétroliers',
    'pétulances',
    'pétulantes',
    'pétunaient',
    'pétunasses',
    'pétunerais',
    'pétunerait',
    'pétunèrent',
    'pétuneriez',
    'pétunerons',
    'pétuneront',
    'peuplaient',
    'peuplasses',
    'peuplement',
    'peupleraie',
    'peuplerais',
    'peuplerait',
    'peuplèrent',
    'peupleriez',
    'peuplerons',
    'peupleront',
    'phacochère',
    'phacomètre',
    'phagocytai',
    'phagocytas',
    'phagocytât',
    'phagocytée',
    'phagocyter',
    'phagocytes',
    'phagocytés',
    'phagocytez',
    'phalangers',
    'phalangien',
    'phalangine',
    'phalliques',
    'phalloïdes',
    'phantasmes',
    'pharaonien',
    'pharillons',
    'pharisiens',
    'pharmacien',
    'pharmacies',
    'pharyngale',
    'pharyngaux',
    'pharyngées',
    'pharyngien',
    'pharyngite',
    'phascolome',
    'phasemètre',
    'phellogène',
    'phéniciens',
    'phéniquées',
    'phénoménal',
    'phénomènes',
    'phénotypes',
    'philatélie',
    'philippine',
    'philippins',
    'philistins',
    'philologie',
    'philologue',
    'philosopha',
    'philosophe',
    'philosophé',
    'phlegmasie',
    'phlyctènes',
    'phocéennes',
    'phonateurs',
    'phonations',
    'phonatoire',
    'phonatrice',
    'phonémique',
    'phonétique',
    'phoniatrie',
    'phonogénie',
    'phonolites',
    'phonologie',
    'phonologue',
    'phosphatai',
    'phosphatas',
    'phosphatât',
    'phosphatée',
    'phosphater',
    'phosphates',
    'phosphatés',
    'phosphatez',
    'phosphènes',
    'phosphites',
    'phosphorai',
    'phosphoras',
    'phosphorât',
    'phosphorée',
    'phosphorer',
    'phosphores',
    'phosphorés',
    'phosphorez',
    'photocopia',
    'photocopie',
    'photocopié',
    'photogènes',
    'photogénie',
    'photolyses',
    'photomaton',
    'photomètre',
    'photonique',
    'photophore',
    'photopiles',
    'photostats',
    'photostyle',
    'phototypes',
    'phototypie',
    'phragmites',
    'phrasaient',
    'phrasasses',
    'phraserais',
    'phraserait',
    'phrasèrent',
    'phraseriez',
    'phraserons',
    'phraseront',
    'phraseuses',
    'phrastique',
    'phréatique',
    'phréniques',
    'phrygienne',
    'phtaléines',
    'phtaliques',
    'phtiriases',
    'phtiriasis',
    'phtisiques',
    'phylactère',
    'phylarques',
    'phylétique',
    'phylloxera',
    'phylloxéra',
    'phylloxéré',
    'phylogénie',
    'physiciens',
    'phytotrons',
    'piaculaire',
    'piaffaient',
    'piaffantes',
    'piaffasses',
    'piaffement',
    'piafferais',
    'piafferait',
    'piaffèrent',
    'piafferiez',
    'piafferons',
    'piafferont',
    'piaffeuses',
    'piaillâmes',
    'piaillarde',
    'piaillards',
    'piaillasse',
    'piaillâtes',
    'piaillerai',
    'piailleras',
    'piaillerez',
    'piaillerie',
    'piailleurs',
    'piailleuse',
    'piaillions',
    'pianissimo',
    'pianoforte',
    'pianotages',
    'pianotâmes',
    'pianotasse',
    'pianotâtes',
    'pianoterai',
    'pianoteras',
    'pianoterez',
    'pianotions',
    'piaulaient',
    'piaulasses',
    'piaulement',
    'piaulerais',
    'piaulerait',
    'piaulèrent',
    'piauleriez',
    'piaulerons',
    'piauleront',
    'picaillons',
    'picaresque',
    'pichenette',
    'picholines',
    'pickpocket',
    'picolaient',
    'picolasses',
    'picolerais',
    'picolerait',
    'picolèrent',
    'picoleriez',
    'picolerons',
    'picoleront',
    'picoleuses',
    'picoraient',
    'picorasses',
    'picorerais',
    'picorerait',
    'picorèrent',
    'picoreriez',
    'picorerons',
    'picoreront',
    'picotaient',
    'picotasses',
    'picotement',
    'picoterais',
    'picoterait',
    'picotèrent',
    'picoteriez',
    'picoterons',
    'picoteront',
    'picridiums',
    'picturales',
    'pied-droit',
    'piédestaux',
    'piédouches',
    'pieds-bots',
    'piégeaient',
    'piégeasses',
    'piégerions',
    'piémontais',
    'pierraient',
    'pierraille',
    'pierrasses',
    'pierrerais',
    'pierrerait',
    'pierrèrent',
    'pierreries',
    'pierreriez',
    'pierrerons',
    'pierreront',
    'pierreuses',
    'piétailles',
    'piétassent',
    'piétassiez',
    'piètements',
    'piéterions',
    'piétinâmes',
    'piétinante',
    'piétinants',
    'piétinasse',
    'piétinâtes',
    'piétinerai',
    'piétineras',
    'piétinerez',
    'piétinions',
    'piétonnier',
    'piètrement',
    'pieusement',
    'pieutaient',
    'pieutasses',
    'pieuterais',
    'pieuterait',
    'pieutèrent',
    'pieuteriez',
    'pieuterons',
    'pieuteront',
    'piézomètre',
    'pifassions',
    'piferaient',
    'piffassent',
    'piffassiez',
    'pifferions',
    'pifomètres',
    'pigeassent',
    'pigeassiez',
    'pigeonnais',
    'pigeonnait',
    'pigeonnant',
    'pigeonneau',
    'pigeonnées',
    'pigeonnent',
    'pigeonnera',
    'pigeonnier',
    'pigeonniez',
    'pigeonnons',
    'pigeraient',
    'pigmentais',
    'pigmentait',
    'pigmentant',
    'pigmentées',
    'pigmentent',
    'pigmentera',
    'pigmentiez',
    'pigmentons',
    'pignatelle',
    'pignochais',
    'pignochait',
    'pignochant',
    'pignochées',
    'pignochent',
    'pignochera',
    'pignochiez',
    'pignochons',
    'pignoratif',
    'pilassions',
    'pileraient',
    'pillassent',
    'pillassiez',
    'pillerions',
    'pilocarpes',
    'pilonnages',
    'pilonnâmes',
    'pilonnasse',
    'pilonnâtes',
    'pilonnerai',
    'pilonneras',
    'pilonnerez',
    'pilonnions',
    'piloselles',
    'pilosismes',
    'pilotaient',
    'pilotasses',
    'piloterais',
    'piloterait',
    'pilotèrent',
    'piloteriez',
    'piloterons',
    'piloteront',
    'pilulaires',
    'pimentâmes',
    'pimentasse',
    'pimentâtes',
    'pimenterai',
    'pimenteras',
    'pimenterez',
    'pimentions',
    'pinaillage',
    'pinaillais',
    'pinaillait',
    'pinaillant',
    'pinaillent',
    'pinaillera',
    'pinailleur',
    'pinailliez',
    'pinaillons',
    'pinardiers',
    'pinçassent',
    'pinçassiez',
    'pinceliers',
    'pincements',
    'pincerions',
    'pinchardes',
    'pindarique',
    'pindarisai',
    'pindarisas',
    'pindarisât',
    'pindariser',
    'pindarises',
    'pindarisez',
    'ping-pongs',
    'pingreries',
    'pinnothère',
    'pintadeaux',
    'pintadines',
    'pintassent',
    'pintassiez',
    'pinterions',
    'piochaient',
    'piochasses',
    'piocherais',
    'piocherait',
    'piochèrent',
    'piocheriez',
    'piocherons',
    'piocheront',
    'piocheuses',
    'pionçaient',
    'pionçasses',
    'pioncerais',
    'pioncerait',
    'pioncèrent',
    'pionceriez',
    'pioncerons',
    'pionceront',
    'pionnaient',
    'pionnasses',
    'pionnerais',
    'pionnerait',
    'pionnèrent',
    'pionneriez',
    'pionnerons',
    'pionneront',
    'pionnières',
    'pipassions',
    'pipelettes',
    'pipe-lines',
    'piperaient',
    'pipéronals',
    'piquassent',
    'piquassiez',
    'piquerions',
    'piquetages',
    'piquetâmes',
    'piquetasse',
    'piquetâtes',
    'piquetions',
    'piquettent',
    'piquettera',
    'pirataient',
    'piratasses',
    'piraterais',
    'piraterait',
    'piratèrent',
    'pirateries',
    'pirateriez',
    'piraterons',
    'pirateront',
    'piriformes',
    'piroguière',
    'piroguiers',
    'pirouettai',
    'pirouettas',
    'pirouettât',
    'pirouetter',
    'pirouettes',
    'pirouettez',
    'piscicoles',
    'pisciforme',
    'piscivores',
    'pissassent',
    'pissassiez',
    'pissenlits',
    'pisserions',
    'pissotière',
    'pistachais',
    'pistachait',
    'pistachant',
    'pistachées',
    'pistachent',
    'pistachera',
    'pistachier',
    'pistachiez',
    'pistachons',
    'pistassent',
    'pistassiez',
    'pisterions',
    'pistonnais',
    'pistonnait',
    'pistonnant',
    'pistonnées',
    'pistonnent',
    'pistonnera',
    'pistonniez',
    'pistonnons',
    'pithiviers',
    'pitonnages',
    'pitonnâmes',
    'pitonnasse',
    'pitonnâtes',
    'pitonnerai',
    'pitonneras',
    'pitonnerez',
    'pitonnions',
    'pitoyables',
    'pituitaire',
    'pityriasis',
    'pivotaient',
    'pivotantes',
    'pivotasses',
    'pivotement',
    'pivoterais',
    'pivoterait',
    'pivotèrent',
    'pivoteriez',
    'pivoterons',
    'pivoteront',
    'placardais',
    'placardait',
    'placardant',
    'placardées',
    'placardent',
    'placardera',
    'placardiez',
    'placardons',
    'plaçassent',
    'plaçassiez',
    'placements',
    'placerions',
    'placidités',
    'plafonnage',
    'plafonnais',
    'plafonnait',
    'plafonnant',
    'plafonnées',
    'plafonnent',
    'plafonnera',
    'plafonneur',
    'plafonnier',
    'plafonniez',
    'plafonnons',
    'plagiaient',
    'plagiaires',
    'plagiasses',
    'plagierais',
    'plagierait',
    'plagièrent',
    'plagieriez',
    'plagierons',
    'plagieront',
    'plaidaient',
    'plaidantes',
    'plaidasses',
    'plaiderais',
    'plaiderait',
    'plaidèrent',
    'plaideriez',
    'plaiderons',
    'plaideront',
    'plaideuses',
    'plaidoirie',
    'plaidoyers',
    'plaignante',
    'plaignants',
    'plaignîmes',
    'plaignions',
    'plaignisse',
    'plaignîtes',
    'plainaient',
    'plainasses',
    'plaindrais',
    'plaindrait',
    'plaindriez',
    'plaindrons',
    'plaindront',
    'plainerais',
    'plainerait',
    'plainèrent',
    'plaineriez',
    'plainerons',
    'plaineront',
    'plain-pied',
    'plaintives',
    'plairaient',
    'plaisaient',
    'plaisances',
    'plaisantai',
    'plaisantas',
    'plaisantât',
    'plaisantée',
    'plaisanter',
    'plaisantes',
    'plaisantés',
    'plaisantez',
    'plaisantin',
    'planassent',
    'planassiez',
    'planchâmes',
    'planchasse',
    'planchâtes',
    'planchéiai',
    'planchéias',
    'planchéiât',
    'planchéiée',
    'planchéier',
    'planchéies',
    'planchéiés',
    'plancherai',
    'plancheras',
    'plancherez',
    'planchette',
    'planchions',
    'planerions',
    'planétaire',
    'planifiais',
    'planifiait',
    'planifiant',
    'planifiées',
    'planifient',
    'planifiera',
    'planifiiez',
    'planifions',
    'planimètre',
    'planquâmes',
    'planquasse',
    'planquâtes',
    'planquerai',
    'planqueras',
    'planquerez',
    'planquions',
    'plantaient',
    'plantaires',
    'plantasses',
    'plantation',
    'planterais',
    'planterait',
    'plantèrent',
    'planteriez',
    'planterons',
    'planteront',
    'planteuses',
    'plantureux',
    'plaquaient',
    'plaquasses',
    'plaquemine',
    'plaquerais',
    'plaquerait',
    'plaquèrent',
    'plaqueriez',
    'plaquerons',
    'plaqueront',
    'plaquettes',
    'plaqueuses',
    'plasmagène',
    'plasmifiai',
    'plasmifias',
    'plasmifiât',
    'plasmifiée',
    'plasmifier',
    'plasmifies',
    'plasmifiés',
    'plasmifiez',
    'plasmocyte',
    'plasmodium',
    'plasmolyse',
    'plasticage',
    'plasticien',
    'plasticité',
    'plastifiai',
    'plastifias',
    'plastifiât',
    'plastifiée',
    'plastifier',
    'plastifies',
    'plastifiés',
    'plastifiez',
    'plastigels',
    'plastiquai',
    'plastiquas',
    'plastiquât',
    'plastiquée',
    'plastiquer',
    'plastiques',
    'plastiqués',
    'plastiquez',
    'plastisols',
    'plastronna',
    'plastronne',
    'plastronné',
    'platelages',
    'platinages',
    'platinâmes',
    'platinasse',
    'platinâtes',
    'platinerai',
    'platineras',
    'platinerez',
    'platinions',
    'platinisai',
    'platinisas',
    'platinisât',
    'platinisée',
    'platiniser',
    'platinises',
    'platinisés',
    'platinisez',
    'platitudes',
    'platonique',
    'platonisme',
    'plâtraient',
    'plâtrasses',
    'plâtrerais',
    'plâtrerait',
    'plâtrèrent',
    'plâtreries',
    'plâtreriez',
    'plâtrerons',
    'plâtreront',
    'plâtreuses',
    'plâtrières',
    'plausibles',
    'plébéienne',
    'plébiscita',
    'plébiscite',
    'plébiscité',
    'pleinement',
    'plein-vent',
    'plénitudes',
    'pléonasmes',
    'pleuraient',
    'pleurantes',
    'pleurardes',
    'pleurasses',
    'pleurerais',
    'pleurerait',
    'pleurèrent',
    'pleureriez',
    'pleurerons',
    'pleureront',
    'pleurésies',
    'pleureuses',
    'pleurnicha',
    'pleurniche',
    'pleurniché',
    'pleutrerie',
    'pleuvaient',
    'pleuvassât',
    'pleuvasser',
    'pleuvinait',
    'pleuvinera',
    'pleuvotait',
    'pleuvotera',
    'pliassions',
    'plieraient',
    'plissaient',
    'plissasses',
    'plissement',
    'plisserais',
    'plisserait',
    'plissèrent',
    'plisseriez',
    'plisserons',
    'plisseront',
    'plisseuses',
    'ploiements',
    'ploierions',
    'plombagine',
    'plombaient',
    'plombasses',
    'plomberais',
    'plomberait',
    'plombèrent',
    'plomberiez',
    'plomberons',
    'plomberont',
    'plombières',
    'plombifère',
    'plongeâmes',
    'plongeante',
    'plongeants',
    'plongeasse',
    'plongeâtes',
    'plongeoirs',
    'plongerais',
    'plongerait',
    'plongèrent',
    'plongeriez',
    'plongerons',
    'plongeront',
    'plongeuses',
    'ploquaient',
    'ploquasses',
    'ploquerais',
    'ploquerait',
    'ploquèrent',
    'ploqueriez',
    'ploquerons',
    'ploqueront',
    'ployassent',
    'ployassiez',
    'pluchaient',
    'pluchasses',
    'plucherais',
    'plucherait',
    'pluchèrent',
    'plucheriez',
    'plucherons',
    'plucheront',
    'plucheuses',
    'plumaisons',
    'plumassent',
    'plumassier',
    'plumassiez',
    'plumerions',
    'pluralisme',
    'pluraliste',
    'pluralités',
    'plurielles',
    'plurivoque',
    'plus-value',
    'plutoniens',
    'plutonique',
    'plutonisme',
    'plutoniums',
    'pluvieuses',
    'pluviosité',
    'pneumonies',
    'pochardise',
    'pochassent',
    'pochassiez',
    'pocherions',
    'podologies',
    'podologues',
    'podoscaphe',
    'podzolique',
    'poêlassent',
    'poêlassiez',
    'poêlerions',
    'poétereaux',
    'poétisâmes',
    'poétisasse',
    'poétisâtes',
    'poétiserai',
    'poétiseras',
    'poétiserez',
    'poétisions',
    'poignaient',
    'poignantes',
    'poignardai',
    'poignardas',
    'poignardât',
    'poignardée',
    'poignarder',
    'poignardes',
    'poignardés',
    'poignardez',
    'poignirent',
    'poignisses',
    'poilassent',
    'poilassiez',
    'poilerions',
    'poinçonnai',
    'poinçonnas',
    'poinçonnât',
    'poinçonnée',
    'poinçonner',
    'poinçonnes',
    'poinçonnés',
    'poinçonnez',
    'poindrions',
    'pointaient',
    'pointasses',
    'pointerais',
    'pointerait',
    'pointèrent',
    'pointeriez',
    'pointerons',
    'pointeront',
    'pointeuses',
    'pointillai',
    'pointillas',
    'pointillât',
    'pointillée',
    'pointiller',
    'pointilles',
    'pointillés',
    'pointillez',
    'poireautai',
    'poireautas',
    'poireautât',
    'poireauter',
    'poireautes',
    'poireautez',
    'poirotâmes',
    'poirotasse',
    'poirotâtes',
    'poiroterai',
    'poiroteras',
    'poiroterez',
    'poirotions',
    'poiscaille',
    'poiseuille',
    'poissaient',
    'poissardes',
    'poissasses',
    'poisserais',
    'poisserait',
    'poissèrent',
    'poisseriez',
    'poisserons',
    'poisseront',
    'poisseuses',
    'poitevines',
    'poivraient',
    'poivrasses',
    'poivrerais',
    'poivrerait',
    'poivrèrent',
    'poivreriez',
    'poivrerons',
    'poivreront',
    'poivrières',
    'polarisais',
    'polarisait',
    'polarisant',
    'polarisées',
    'polarisent',
    'polarisera',
    'polariseur',
    'polarisiez',
    'polarisons',
    'polatouche',
    'polémarque',
    'polémiquai',
    'polémiquas',
    'polémiquât',
    'polémiquer',
    'polémiques',
    'polémiquez',
    'polémistes',
    'poliçaient',
    'poliçasses',
    'policerais',
    'policerait',
    'policèrent',
    'policeriez',
    'policerons',
    'policeront',
    'policières',
    'poliraient',
    'polissable',
    'polissages',
    'polisseurs',
    'polisseuse',
    'polissions',
    'polissoire',
    'polissoirs',
    'polissonna',
    'polissonne',
    'polissonné',
    'politesses',
    'politicard',
    'politicien',
    'politiquai',
    'politiquas',
    'politiquât',
    'politiquée',
    'politiquer',
    'politiques',
    'politiqués',
    'politiquez',
    'politisais',
    'politisait',
    'politisant',
    'politisées',
    'politisent',
    'politisera',
    'politisiez',
    'politisons',
    'polluaient',
    'polluantes',
    'polluasses',
    'polluerais',
    'polluerait',
    'polluèrent',
    'pollueriez',
    'polluerons',
    'pollueront',
    'pollueuses',
    'pollutions',
    'polonaises',
    'poltronnes',
    'polyacides',
    'polyakènes',
    'polyalcool',
    'polyamides',
    'polyamines',
    'polyandres',
    'polyandrie',
    'polychrome',
    'polycopiai',
    'polycopias',
    'polycopiât',
    'polycopiée',
    'polycopier',
    'polycopies',
    'polycopiés',
    'polycopiez',
    'polyesters',
    'polygamies',
    'polyglotte',
    'polygonale',
    'polygonaux',
    'polygraphe',
    'polymérisa',
    'polymérise',
    'polymérisé',
    'polymorphe',
    'polynésien',
    'polynomial',
    'polyosides',
    'polypétale',
    'polypeuses',
    'polyphasée',
    'polyphasés',
    'polyphonie',
    'polyploïde',
    'polyptères',
    'polyptyque',
    'polystyles',
    'polythènes',
    'polytonale',
    'polytonaux',
    'polyvalent',
    'pommadâmes',
    'pommadasse',
    'pommadâtes',
    'pommaderai',
    'pommaderas',
    'pommaderez',
    'pommadions',
    'pommassent',
    'pommassiez',
    'pommelâmes',
    'pommelasse',
    'pommelâtes',
    'pommelions',
    'pommellent',
    'pommellera',
    'pommerâmes',
    'pommerasse',
    'pommerâtes',
    'pomoeriums',
    'pomologues',
    'pompassent',
    'pompassiez',
    'pompéienne',
    'pomperions',
    'pomponnais',
    'pomponnait',
    'pomponnant',
    'pomponnées',
    'pomponnent',
    'pomponnera',
    'pomponniez',
    'pomponnons',
    'ponantaise',
    'ponçassent',
    'ponçassiez',
    'poncerions',
    'ponctionna',
    'ponctionne',
    'ponctionné',
    'ponctuâmes',
    'ponctuasse',
    'ponctuâtes',
    'ponctuelle',
    'ponctuerai',
    'ponctueras',
    'ponctuerez',
    'ponctuions',
    'pondaisons',
    'pondérable',
    'pondérales',
    'pondérâmes',
    'pondérasse',
    'pondérâtes',
    'pondérerai',
    'pondéreras',
    'pondérerez',
    'pondéreuse',
    'pondérions',
    'pondissent',
    'pondissiez',
    'pondraient',
    'pontassent',
    'pontassiez',
    'ponterions',
    'pontifiais',
    'pontifiait',
    'pontifiant',
    'pontifical',
    'pontificat',
    'pontifient',
    'pontifiera',
    'pontifiiez',
    'pontifions',
    'pontillais',
    'pontillait',
    'pontillant',
    'pontillées',
    'pontillent',
    'pontillera',
    'pontilliez',
    'pontillons',
    'pont-levis',
    'pontonnier',
    'pontuseaux',
    'populacier',
    'populaires',
    'popularisa',
    'popularise',
    'popularisé',
    'popularité',
    'population',
    'populeuses',
    'populismes',
    'populistes',
    'poquassent',
    'poquassiez',
    'poquerions',
    'porcelaine',
    'porchaison',
    'porcheries',
    'porphyrisa',
    'porphyrise',
    'porphyrisé',
    'porrection',
    'portassent',
    'portassiez',
    'portatives',
    'porte-bébé',
    'porte-clés',
    'porte-épée',
    'porte-faix',
    'porte-fort',
    'porte-jupe',
    'porte-lame',
    'portements',
    'porte-menu',
    'porte-mine',
    'portemines',
    'porte-mors',
    'porterions',
    'porte-vent',
    'porte-voix',
    'portillons',
    'portuaires',
    'portugaise',
    'posassions',
    'posemètres',
    'poseraient',
    'positionna',
    'positionne',
    'positionné',
    'positonium',
    'posologies',
    'possédâmes',
    'possédante',
    'possédants',
    'possédasse',
    'possédâtes',
    'posséderai',
    'posséderas',
    'posséderez',
    'possédions',
    'possesseur',
    'possessifs',
    'possession',
    'possessive',
    'postassent',
    'postassiez',
    'postdatais',
    'postdatait',
    'postdatant',
    'postdatées',
    'postdatent',
    'postdatera',
    'postdatiez',
    'postdatons',
    'postérieur',
    'posterions',
    'posteriori',
    'postérités',
    'postichais',
    'postichait',
    'postichant',
    'postichent',
    'postichera',
    'posticheur',
    'postichiez',
    'postichons',
    'postillons',
    'postnatale',
    'postnataux',
    'postposais',
    'postposait',
    'postposant',
    'postposées',
    'postposent',
    'postposera',
    'postposiez',
    'postposons',
    'postulâmes',
    'postulante',
    'postulants',
    'postulasse',
    'postulâtes',
    'postulerai',
    'postuleras',
    'postulerez',
    'postulions',
    'posturales',
    'potassâmes',
    'potassasse',
    'potassâtes',
    'potasserai',
    'potasseras',
    'potasserez',
    'potasseurs',
    'potassions',
    'potassique',
    'pot-au-feu',
    'pot-de-vin',
    'potentiels',
    'potentille',
    'potestatif',
    'potinaient',
    'potinasses',
    'potinerais',
    'potinerait',
    'potinèrent',
    'potineriez',
    'potinerons',
    'potineront',
    'potinières',
    'potomètres',
    'pot-pourri',
    'pouce-pied',
    'poudingues',
    'poudraient',
    'poudrasses',
    'poudrerais',
    'poudrerait',
    'poudrèrent',
    'poudreries',
    'poudreriez',
    'poudrerons',
    'poudreront',
    'poudrettes',
    'poudreuses',
    'poudrières',
    'poudroient',
    'poudroiera',
    'poudroyais',
    'poudroyait',
    'poudroyant',
    'poudroyiez',
    'poudroyons',
    'pouffaient',
    'pouffasses',
    'poufferais',
    'poufferait',
    'pouffèrent',
    'poufferiez',
    'poufferons',
    'poufferont',
    'pouffiasse',
    'pouillards',
    'pouillerie',
    'pouilleuse',
    'poujadisme',
    'poulailler',
    'poulinâmes',
    'poulinasse',
    'poulinâtes',
    'poulinerai',
    'poulineras',
    'poulinerez',
    'poulinière',
    'poulinions',
    'pouponnais',
    'pouponnait',
    'pouponnant',
    'pouponnent',
    'pouponnera',
    'pouponniez',
    'pouponnons',
    'pourboires',
    'pourchassa',
    'pourchasse',
    'pourchassé',
    'pourfendes',
    'pourfendez',
    'pourfendis',
    'pourfendit',
    'pourfendît',
    'pourfendra',
    'pourfendre',
    'pourfendue',
    'pourfendus',
    'pourléchai',
    'pourléchas',
    'pourléchât',
    'pourléchée',
    'pourlécher',
    'pourléchés',
    'pourlèches',
    'pourléchez',
    'pourpoints',
    'pourprâmes',
    'pourprasse',
    'pourprâtes',
    'pourprerai',
    'pourpreras',
    'pourprerez',
    'pourprinne',
    'pourprions',
    'pourraient',
    'pourridiés',
    'pourrirais',
    'pourrirait',
    'pourrirent',
    'pourririez',
    'pourrirons',
    'pourriront',
    'pourrisses',
    'pourrissez',
    'pourriture',
    'poursuites',
    'poursuives',
    'poursuivez',
    'poursuivie',
    'poursuivis',
    'poursuivit',
    'poursuivît',
    'poursuivra',
    'poursuivre',
    'pourvoient',
    'pourvoirai',
    'pourvoiras',
    'pourvoirez',
    'pourvoyais',
    'pourvoyait',
    'pourvoyant',
    'pourvoyeur',
    'pourvoyiez',
    'pourvoyons',
    'pourvurent',
    'pourvusses',
    'poussaient',
    'poussasses',
    'pousserais',
    'pousserait',
    'poussèrent',
    'pousseriez',
    'pousserons',
    'pousseront',
    'poussettes',
    'poussières',
    'poutargues',
    'poutrelles',
    'poutsaient',
    'poutsasses',
    'poutserais',
    'poutserait',
    'poutsèrent',
    'poutseriez',
    'poutserons',
    'poutseront',
    'pouzzolane',
    'praesidium',
    'pralinages',
    'pralinâmes',
    'pralinasse',
    'pralinâtes',
    'pralinerai',
    'pralineras',
    'pralinerez',
    'pralinions',
    'praséodyme',
    'praticable',
    'praticiens',
    'pratiquais',
    'pratiquait',
    'pratiquant',
    'pratiquées',
    'pratiquent',
    'pratiquera',
    'pratiquiez',
    'pratiquons',
    'préachetai',
    'préachetas',
    'préachetât',
    'préachetée',
    'préacheter',
    'préachetés',
    'préachètes',
    'préachetez',
    'préadamite',
    'préalables',
    'préalpines',
    'préambules',
    'préavisais',
    'préavisait',
    'préavisant',
    'préavisées',
    'préavisent',
    'préavisera',
    'préavisiez',
    'préavisons',
    'prébendier',
    'précarisai',
    'précarisas',
    'précarisât',
    'précarisée',
    'précariser',
    'précarises',
    'précarisés',
    'précarisez',
    'précarités',
    'précaution',
    'précédâmes',
    'précédasse',
    'précédâtes',
    'précédente',
    'précédents',
    'précéderai',
    'précéderas',
    'précéderez',
    'précédions',
    'préceintes',
    'précepteur',
    'précession',
    'prêchaient',
    'préchambre',
    'prêchasses',
    'préchauffa',
    'préchauffe',
    'préchauffé',
    'prêcherais',
    'prêcherait',
    'prêchèrent',
    'prêcheriez',
    'prêcherons',
    'prêcheront',
    'prêcheuses',
    'précieuses',
    'préciosité',
    'précipices',
    'précipitai',
    'précipitas',
    'précipitât',
    'précipitée',
    'précipiter',
    'précipites',
    'précipités',
    'précipitez',
    'précisâmes',
    'précisasse',
    'précisâtes',
    'préciserai',
    'préciseras',
    'préciserez',
    'précisions',
    'précocités',
    'précomptai',
    'précomptas',
    'précomptât',
    'précomptée',
    'précompter',
    'précomptes',
    'précomptés',
    'précomptez',
    'préconçues',
    'préconisai',
    'préconisas',
    'préconisât',
    'préconisée',
    'préconiser',
    'préconises',
    'préconisés',
    'préconisez',
    'précordial',
    'précurseur',
    'prédateurs',
    'prédations',
    'prédatrice',
    'prédécoupé',
    'prédéfinie',
    'prédéfinir',
    'prédéfinis',
    'prédéfinit',
    'prédéfinît',
    'prédestina',
    'prédestine',
    'prédestiné',
    'prédicable',
    'prédicants',
    'prédicatif',
    'prédiction',
    'prédigérée',
    'prédigérés',
    'prédiquais',
    'prédiquait',
    'prédiquant',
    'prédiquées',
    'prédiquent',
    'prédiquera',
    'prédiquiez',
    'prédiquons',
    'prédirions',
    'prédisions',
    'prédisposa',
    'prédispose',
    'prédisposé',
    'prédissent',
    'prédissiez',
    'prédominai',
    'prédominas',
    'prédominât',
    'prédominer',
    'prédomines',
    'prédominez',
    'préembalée',
    'préembalés',
    'préemballé',
    'prééminent',
    'préemptais',
    'préemptait',
    'préemptant',
    'préemptées',
    'préemptent',
    'préemptera',
    'préemptiez',
    'préemption',
    'préemptons',
    'préencollé',
    'préétablie',
    'préétablir',
    'préétablis',
    'préétablit',
    'préétablît',
    'préexistai',
    'préexistas',
    'préexistât',
    'préexister',
    'préexistes',
    'préexistez',
    'préfaçâmes',
    'préfaçasse',
    'préfaçâtes',
    'préfacerai',
    'préfaceras',
    'préfacerez',
    'préfaciers',
    'préfacions',
    'préfecture',
    'préférable',
    'préférâmes',
    'préférasse',
    'préférâtes',
    'préférence',
    'préférerai',
    'préféreras',
    'préférerez',
    'préférions',
    'préfigurai',
    'préfiguras',
    'préfigurât',
    'préfigurée',
    'préfigurer',
    'préfigures',
    'préfigurés',
    'préfigurez',
    'préfixâmes',
    'préfixasse',
    'préfixâtes',
    'préfixerai',
    'préfixeras',
    'préfixerez',
    'préfixions',
    'préformais',
    'préformait',
    'préformant',
    'préformées',
    'préforment',
    'préformera',
    'préformiez',
    'préformons',
    'prégnances',
    'prégnantes',
    'préhenseur',
    'préhensile',
    'préhension',
    'préjudices',
    'préjudicia',
    'préjudicie',
    'préjudicié',
    'préjugeais',
    'préjugeait',
    'préjugeant',
    'préjugeons',
    'préjugerai',
    'préjugeras',
    'préjugerez',
    'préjugions',
    'prélassais',
    'prélassait',
    'prélassant',
    'prélassées',
    'prélassent',
    'prélassera',
    'prélassiez',
    'prélassons',
    'prélatines',
    'prélatures',
    'prélavages',
    'prélevâmes',
    'prélevasse',
    'prélevâtes',
    'prélèverai',
    'prélèveras',
    'prélèverez',
    'prélevions',
    'prélogique',
    'préludâmes',
    'préludasse',
    'préludâtes',
    'préluderai',
    'préluderas',
    'préluderez',
    'préludions',
    'prématurée',
    'prématurés',
    'préméditai',
    'préméditas',
    'préméditât',
    'préméditée',
    'préméditer',
    'prémédites',
    'prémédités',
    'préméditez',
    'premier-né',
    'prémolaire',
    'prémontrés',
    'prémunîmes',
    'prémunirai',
    'prémuniras',
    'prémunirez',
    'prémunisse',
    'prémunîtes',
    'prénatales',
    'prendrions',
    'prénommais',
    'prénommait',
    'prénommant',
    'prénommées',
    'prénomment',
    'prénommera',
    'prénommiez',
    'prénommons',
    'prénuptial',
    'préoccupai',
    'préoccupas',
    'préoccupât',
    'préoccupée',
    'préoccuper',
    'préoccupes',
    'préoccupés',
    'préoccupez',
    'prépaierai',
    'prépaieras',
    'prépaierez',
    'préparâmes',
    'préparasse',
    'préparâtes',
    'préparerai',
    'prépareras',
    'préparerez',
    'préparions',
    'prépayâmes',
    'prépayasse',
    'prépayâtes',
    'prépayerai',
    'prépayeras',
    'prépayerez',
    'prépayions',
    'préposâmes',
    'préposasse',
    'préposâtes',
    'préposerai',
    'préposeras',
    'préposerez',
    'préposions',
    'prépositif',
    'prépotence',
    'préréglais',
    'préréglait',
    'préréglant',
    'préréglées',
    'prérèglent',
    'préréglera',
    'prérégliez',
    'préréglons',
    'présageais',
    'présageait',
    'présageant',
    'présageons',
    'présagerai',
    'présageras',
    'présagerez',
    'présagions',
    'présalaire',
    'presbytère',
    'presbyties',
    'prescience',
    'presciente',
    'prescients',
    'prescrirai',
    'prescriras',
    'prescrirez',
    'prescrites',
    'prescrives',
    'prescrivez',
    'prescrivis',
    'prescrivit',
    'prescrivît',
    'préséances',
    'présentais',
    'présentait',
    'présentant',
    'présentées',
    'présentent',
    'présentera',
    'présentiez',
    'présentoir',
    'présentons',
    'préservais',
    'préservait',
    'préservant',
    'préservées',
    'préservent',
    'préservera',
    'préserviez',
    'préservons',
    'présidâmes',
    'présidasse',
    'présidâtes',
    'présidence',
    'présidente',
    'présidents',
    'présiderai',
    'présideras',
    'présiderez',
    'présidiaux',
    'présidions',
    'présidiums',
    'présomptif',
    'pressaient',
    'prés-salés',
    'pressantes',
    'pressasses',
    'pressentes',
    'pressentez',
    'pressentie',
    'pressentir',
    'pressentis',
    'pressentit',
    'pressentît',
    'presserais',
    'presserait',
    'pressèrent',
    'presseriez',
    'presserons',
    'presseront',
    'presseuses',
    'pressurage',
    'pressurais',
    'pressurait',
    'pressurant',
    'pressurées',
    'pressurent',
    'pressurera',
    'pressureur',
    'pressuriez',
    'pressurisa',
    'pressurise',
    'pressurisé',
    'pressurons',
    'prestances',
    'prestation',
    'prestement',
    'prestesses',
    'présumable',
    'présumâmes',
    'présumasse',
    'présumâtes',
    'présumerai',
    'présumeras',
    'présumerez',
    'présumions',
    'présupposa',
    'présuppose',
    'présupposé',
    'présurâmes',
    'présurasse',
    'présurâtes',
    'présurerai',
    'présureras',
    'présurerez',
    'présurions',
    'prêtassent',
    'prêtassiez',
    'prétendais',
    'prétendait',
    'prétendant',
    'prétendent',
    'prétendiez',
    'prétendons',
    'prétendrai',
    'prétendras',
    'prétendrez',
    'prétendues',
    'prête-noms',
    'prétention',
    'prêterions',
    'prétextais',
    'prétextait',
    'prétextant',
    'prétextées',
    'prétextent',
    'prétextera',
    'prétextiez',
    'prétextons',
    'prétoriale',
    'prétoriaux',
    'prétoriens',
    'prêtresses',
    'prévalence',
    'prévalions',
    'prévalûmes',
    'prévalusse',
    'prévalûtes',
    'prévariqua',
    'prévarique',
    'prévariqué',
    'prévaudrai',
    'prévaudras',
    'prévaudrez',
    'prévenance',
    'prévenante',
    'prévenants',
    'prévenions',
    'préventifs',
    'prévention',
    'préventive',
    'préviendra',
    'préviennes',
    'prévinrent',
    'prévinsses',
    'prévisible',
    'prévisions',
    'prévissent',
    'prévissiez',
    'prévoirais',
    'prévoirait',
    'prévoiriez',
    'prévoirons',
    'prévoiront',
    'prévôtales',
    'prévoyance',
    'prévoyante',
    'prévoyants',
    'prévoyions',
    'priapismes',
    'priassions',
    'prieraient',
    'primarisai',
    'primarisas',
    'primarisât',
    'primarisée',
    'primariser',
    'primarises',
    'primarisés',
    'primarisez',
    'primarités',
    'primassent',
    'primassiez',
    'primatiale',
    'primatiaux',
    'primerions',
    'primeroses',
    'primevères',
    'primipares',
    'primipiles',
    'primitives',
    'primordial',
    'princesses',
    'princières',
    'principale',
    'principats',
    'principaux',
    'printanier',
    'priodontes',
    'prisassent',
    'prisassiez',
    'priserions',
    'prisonnier',
    'privassent',
    'privassiez',
    'privations',
    'privatique',
    'privatisai',
    'privatisas',
    'privatisât',
    'privatisée',
    'privatiser',
    'privatises',
    'privatisés',
    'privatisez',
    'privatives',
    'priverions',
    'privilèges',
    'privilégia',
    'privilégie',
    'privilégié',
    'probations',
    'probatique',
    'probatoire',
    'procédâmes',
    'procédasse',
    'procédâtes',
    'procéderai',
    'procéderas',
    'procéderez',
    'procédions',
    'procédures',
    'processeur',
    'processifs',
    'procession',
    'processive',
    'prochaines',
    'prochinois',
    'proclamais',
    'proclamait',
    'proclamant',
    'proclamées',
    'proclament',
    'proclamera',
    'proclamiez',
    'proclamons',
    'proconsuls',
    'procréâmes',
    'procréasse',
    'procréâtes',
    'procréerai',
    'procréeras',
    'procréerez',
    'procréions',
    'proctalgie',
    'procurâmes',
    'procurasse',
    'procurâtes',
    'procuratie',
    'procurerai',
    'procureras',
    'procurerât',
    'procureurs',
    'procurions',
    'prodigieux',
    'prodiguais',
    'prodiguait',
    'prodiguant',
    'prodiguées',
    'prodiguent',
    'prodiguera',
    'prodiguiez',
    'prodiguons',
    'producteur',
    'productifs',
    'production',
    'productive',
    'produirais',
    'produirait',
    'produiriez',
    'produirons',
    'produiront',
    'produisais',
    'produisait',
    'produisant',
    'produisent',
    'produisiez',
    'produisons',
    'proéminent',
    'profanâmes',
    'profanasse',
    'profanâtes',
    'profanerai',
    'profaneras',
    'profanerez',
    'profanions',
    'proférâmes',
    'proférasse',
    'proférâtes',
    'proférerai',
    'proféreras',
    'proférerez',
    'proférions',
    'professais',
    'professait',
    'professant',
    'professées',
    'professent',
    'professera',
    'professeur',
    'professiez',
    'profession',
    'professons',
    'profilages',
    'profilâmes',
    'profilasse',
    'profilâtes',
    'profilerai',
    'profileras',
    'profilerez',
    'profilions',
    'profitable',
    'profitâmes',
    'profitasse',
    'profitâtes',
    'profiterai',
    'profiteras',
    'profiterez',
    'profiteurs',
    'profiteuse',
    'profitions',
    'profondeur',
    'profusions',
    'progiciels',
    'proglottis',
    'prognathes',
    'programmai',
    'programmas',
    'programmât',
    'programmée',
    'programmer',
    'programmes',
    'programmés',
    'programmez',
    'progressai',
    'progressas',
    'progressât',
    'progresser',
    'progresses',
    'progressez',
    'progressif',
    'prohibâmes',
    'prohibasse',
    'prohibâtes',
    'prohiberai',
    'prohiberas',
    'prohiberez',
    'prohibions',
    'prohibitif',
    'projecteur',
    'projectile',
    'projection',
    'projecture',
    'projetâmes',
    'projetasse',
    'projetâtes',
    'projeteurs',
    'projetions',
    'projettent',
    'projettera',
    'prolactine',
    'prolamines',
    'prolétaire',
    'proliférai',
    'proliféras',
    'proliférât',
    'proliférer',
    'prolifères',
    'proliférez',
    'prolifique',
    'prolixités',
    'prolongeai',
    'prolongeas',
    'prolongeât',
    'prolongées',
    'prolongent',
    'prolongera',
    'prolongiez',
    'promenades',
    'promenâmes',
    'promenasse',
    'promenâtes',
    'promènerai',
    'promèneras',
    'promènerez',
    'promeneurs',
    'promeneuse',
    'promenions',
    'promenoirs',
    'prométhéen',
    'prométhéum',
    'promettais',
    'promettait',
    'promettant',
    'promettent',
    'prometteur',
    'promettiez',
    'promettons',
    'promettrai',
    'promettras',
    'promettrez',
    'promeuvent',
    'promissent',
    'promissiez',
    'promoteurs',
    'promotions',
    'promotrice',
    'promouvais',
    'promouvait',
    'promouvant',
    'promouviez',
    'promouvoir',
    'promouvons',
    'promouvrai',
    'promouvras',
    'promouvrez',
    'prompteurs',
    'promulguai',
    'promulguas',
    'promulguât',
    'promulguée',
    'promulguer',
    'promulgues',
    'promulgués',
    'promulguez',
    'promussent',
    'promussiez',
    'prônassent',
    'prônassiez',
    'pronateurs',
    'pronations',
    'pronatrice',
    'prônerions',
    'pronominal',
    'prononçais',
    'prononçait',
    'prononçant',
    'prononcées',
    'prononcent',
    'prononcera',
    'prononciez',
    'prononçons',
    'pronostics',
    'propagande',
    'propageais',
    'propageait',
    'propageant',
    'propageons',
    'propagerai',
    'propageras',
    'propagerez',
    'propagions',
    'propagules',
    'propaniers',
    'propédeute',
    'propension',
    'propergols',
    'prophéties',
    'prophétisa',
    'prophétise',
    'prophétisé',
    'proportion',
    'proposable',
    'proposâmes',
    'proposasse',
    'proposâtes',
    'proposerai',
    'proposeras',
    'proposerez',
    'proposions',
    'proprement',
    'propréteur',
    'proprettes',
    'propréture',
    'propriétés',
    'propulsais',
    'propulsait',
    'propulsant',
    'propulsées',
    'propulsent',
    'propulsera',
    'propulseur',
    'propulsiez',
    'propulsifs',
    'propulsion',
    'propulsive',
    'propulsons',
    'propylènes',
    'prorogatif',
    'prorogeais',
    'prorogeait',
    'prorogeant',
    'prorogeons',
    'prorogerai',
    'prorogeras',
    'prorogerez',
    'prorogions',
    'prosaïques',
    'prosaïsmes',
    'prosateurs',
    'proscenium',
    'proscrirai',
    'proscriras',
    'proscrirez',
    'proscrites',
    'proscrives',
    'proscrivez',
    'proscrivis',
    'proscrivit',
    'proscrivît',
    'prosecteur',
    'prosélytes',
    'prosociale',
    'prosociaux',
    'prosodiais',
    'prosodiait',
    'prosodiant',
    'prosodiées',
    'prosodient',
    'prosodiera',
    'prosodiiez',
    'prosodions',
    'prosodique',
    'prosopopée',
    'prospectai',
    'prospectas',
    'prospectât',
    'prospectée',
    'prospecter',
    'prospectes',
    'prospectés',
    'prospectez',
    'prospectif',
    'prospectus',
    'prospérais',
    'prospérait',
    'prospérant',
    'prospèrent',
    'prospérera',
    'prospériez',
    'prospérité',
    'prospérons',
    'prosternai',
    'prosternas',
    'prosternât',
    'prosternée',
    'prosterner',
    'prosternes',
    'prosternés',
    'prosternez',
    'prosthèses',
    'prostituai',
    'prostituas',
    'prostituât',
    'prostituée',
    'prostituer',
    'prostitues',
    'prostitués',
    'prostituez',
    'protecteur',
    'protection',
    'protégeais',
    'protégeait',
    'protégeant',
    'protégeons',
    'protégerai',
    'protégeras',
    'protégerez',
    'protégions',
    'protéiques',
    'protéolyse',
    'protestais',
    'protestait',
    'protestant',
    'protestent',
    'protestera',
    'protestiez',
    'protestons',
    'prothalles',
    'protidique',
    'protocoles',
    'protogines',
    'protogynie',
    'protoptère',
    'prototypes',
    'protoxydes',
    'protuteurs',
    'protutrice',
    'prouvables',
    'prouvaient',
    'prouvasses',
    'prouverais',
    'prouverait',
    'prouvèrent',
    'prouveriez',
    'prouverons',
    'prouveront',
    'provenance',
    'provençale',
    'provençaux',
    'provenions',
    'proverbial',
    'providence',
    'proviendra',
    'proviennes',
    'provignage',
    'provignais',
    'provignait',
    'provignant',
    'provignées',
    'provignent',
    'provignera',
    'provigniez',
    'provignons',
    'provincial',
    'provinrent',
    'provinsses',
    'proviseurs',
    'provisions',
    'provisoire',
    'provisorat',
    'provocante',
    'provocants',
    'provoquais',
    'provoquait',
    'provoquant',
    'provoquées',
    'provoquent',
    'provoquera',
    'provoquiez',
    'provoquons',
    'proxémique',
    'proxénètes',
    'proximales',
    'proximités',
    'prudemment',
    'prunelaies',
    'prunellier',
    'prussiates',
    'prussienne',
    'prussiques',
    'psallettes',
    'psalliotes',
    'psalmistes',
    'psalmodiai',
    'psalmodias',
    'psalmodiât',
    'psalmodiée',
    'psalmodier',
    'psalmodies',
    'psalmodiés',
    'psalmodiez',
    'psaltérion',
    'pseudonyme',
    'pseudopode',
    'psittacose',
    'psychiatre',
    'psychiques',
    'psychismes',
    'ptyalismes',
    'pubertaire',
    'pubescence',
    'pubescente',
    'pubescents',
    'publiables',
    'publiaient',
    'publiasses',
    'publicains',
    'publiciste',
    'publicités',
    'publierais',
    'publierait',
    'publièrent',
    'publieriez',
    'publierons',
    'publieront',
    'pudibondes',
    'puérilisme',
    'puérilités',
    'puerpérale',
    'puerpéraux',
    'pugilistes',
    'pugnacités',
    'puisassent',
    'puisassiez',
    'puisatiers',
    'puisements',
    'puiserions',
    'puissances',
    'puissantes',
    'pulicaires',
    'pulloroses',
    'pull-overs',
    'pullulâmes',
    'pullulante',
    'pullulants',
    'pullulasse',
    'pullulâtes',
    'pullulerai',
    'pulluleras',
    'pullulerez',
    'pullulions',
    'pulmonaire',
    'pulsassent',
    'pulsassiez',
    'pulsations',
    'pulserions',
    'pulsionnel',
    'pulvérisai',
    'pulvérisas',
    'pulvérisât',
    'pulvérisée',
    'pulvériser',
    'pulvérises',
    'pulvérisés',
    'pulvérisez',
    'punaisâmes',
    'punaisasse',
    'punaisâtes',
    'punaiserai',
    'punaiseras',
    'punaiserez',
    'punaisions',
    'puniraient',
    'punissable',
    'punissions',
    'puntarelle',
    'pupillaire',
    'pupitreurs',
    'pupitreuse',
    'purgations',
    'purgatives',
    'purgatoire',
    'purgeaient',
    'purgeasses',
    'purgerions',
    'purifiâmes',
    'purifiasse',
    'purifiâtes',
    'purifierai',
    'purifieras',
    'purifierez',
    'purifiions',
    'puritaines',
    'purpurines',
    'purulences',
    'purulentes',
    'puseyismes',
    'pustuleuse',
    'putréfiais',
    'putréfiait',
    'putréfiant',
    'putréfiées',
    'putréfient',
    'putréfiera',
    'putréfiiez',
    'putréfions',
    'putrescent',
    'putridités',
    'putschiste',
    'pycnomètre',
    'pyloriques',
    'pyodermite',
    'pyramidais',
    'pyramidait',
    'pyramidale',
    'pyramidant',
    'pyramidaux',
    'pyramidées',
    'pyramident',
    'pyramidera',
    'pyramidiez',
    'pyramidion',
    'pyramidons',
    'pyrénéenne',
    'pyrénéites',
    'pyridoxine',
    'pyrimidine',
    'pyrogallol',
    'pyrogravai',
    'pyrogravas',
    'pyrogravât',
    'pyrogravée',
    'pyrograver',
    'pyrograves',
    'pyrogravés',
    'pyrogravez',
    'pyromanies',
    'pyromètres',
    'pyrométrie',
    'pyrophores',
    'pyroxylées',
    'pyrrhiques',
    'pyrrhonien',
    'pythiennes',
    'pythonisse',
    'quadrangle',
    'quadrature',
    'quadrettes',
    'quadriceps',
    'quadrifide',
    'quadrillai',
    'quadrillas',
    'quadrillât',
    'quadrillée',
    'quadriller',
    'quadrilles',
    'quadrillés',
    'quadrillez',
    'quadrirème',
    'quadrumane',
    'quadrupède',
    'quadruplai',
    'quadruplas',
    'quadruplât',
    'quadruplée',
    'quadrupler',
    'quadruples',
    'quadruplés',
    'quadruplex',
    'quadruplez',
    'quakeresse',
    'qualifiais',
    'qualifiait',
    'qualifiant',
    'qualifiées',
    'qualifient',
    'qualifiera',
    'qualifiiez',
    'qualifions',
    'qualitatif',
    'quantièmes',
    'quantifiai',
    'quantifias',
    'quantifiât',
    'quantifiée',
    'quantifier',
    'quantifies',
    'quantifiés',
    'quantifiez',
    'quarraient',
    'quarrasses',
    'quarrerais',
    'quarrerait',
    'quarrèrent',
    'quarreriez',
    'quarrerons',
    'quarreront',
    'quartageai',
    'quartageas',
    'quartageât',
    'quartagées',
    'quartagent',
    'quartagera',
    'quartagiez',
    'quartaient',
    'quartanier',
    'quartasses',
    'quartation',
    'quartenier',
    'quarterais',
    'quarterait',
    'quartèrent',
    'quarteriez',
    'quarterons',
    'quarteront',
    'quartettes',
    'quartzeuse',
    'quartzites',
    'Quasimodos',
    'quatrièmes',
    'québécisme',
    'québécoise',
    'quebrachos',
    'quelconque',
    'quémandais',
    'quémandait',
    'quémandant',
    'quémandées',
    'quémandent',
    'quémandera',
    'quémandeur',
    'quémandiez',
    'quémandons',
    'quenouille',
    'quéquettes',
    'quercitron',
    'querellais',
    'querellait',
    'querellant',
    'querellées',
    'querellent',
    'querellera',
    'querelleur',
    'querelliez',
    'querellons',
    'quérulence',
    'quérulente',
    'quérulents',
    'questionna',
    'questionne',
    'questionné',
    'quêtassent',
    'quêtassiez',
    'quêterions',
    'queutaient',
    'queutasses',
    'queuterais',
    'queuterait',
    'queutèrent',
    'queuteriez',
    'queuterons',
    'queuteront',
    'quiétismes',
    'quiétistes',
    'quilleuses',
    'quinconces',
    'quinoléine',
    'quinquinas',
    'quintaines',
    'quintettes',
    'quinteuses',
    'quintuplai',
    'quintuplas',
    'quintuplât',
    'quintuplée',
    'quintupler',
    'quintuples',
    'quintuplés',
    'quintuplez',
    'quinzaines',
    'quinzièmes',
    'quiproquos',
    'quittaient',
    'quittançai',
    'quittanças',
    'quittançât',
    'quittancée',
    'quittancer',
    'quittances',
    'quittancés',
    'quittancez',
    'quittasses',
    'quitterais',
    'quitterait',
    'quittèrent',
    'quitteriez',
    'quitterons',
    'quitteront',
    'quote-part',
    'quotidiens',
    'quottaient',
    'quottasses',
    'quotterais',
    'quotterait',
    'quottèrent',
    'quotteriez',
    'quotterons',
    'quotteront',
    'rabâchages',
    'rabâchâmes',
    'rabâchasse',
    'rabâchâtes',
    'rabâcherai',
    'rabâcheras',
    'rabâcherez',
    'rabâcheurs',
    'rabâcheuse',
    'rabâchions',
    'rabaissais',
    'rabaissait',
    'rabaissant',
    'rabaissées',
    'rabaissent',
    'rabaissera',
    'rabaissiez',
    'rabaissons',
    'rabantâmes',
    'rabantasse',
    'rabantâtes',
    'rabanterai',
    'rabanteras',
    'rabanterez',
    'rabantions',
    'rabat-joie',
    'rabattages',
    'rabattante',
    'rabattants',
    'rabatteurs',
    'rabatteuse',
    'rabattîmes',
    'rabattions',
    'rabattisse',
    'rabattîtes',
    'rabattrais',
    'rabattrait',
    'rabattriez',
    'rabattrons',
    'rabattront',
    'rabbinique',
    'rabbinisme',
    'rabibochai',
    'rabibochas',
    'rabibochât',
    'rabibochée',
    'rabibocher',
    'rabiboches',
    'rabibochés',
    'rabibochez',
    'rabiotâmes',
    'rabiotasse',
    'rabiotâtes',
    'rabioterai',
    'rabioteras',
    'rabioterez',
    'rabiotions',
    'râblassent',
    'râblassiez',
    'râblerions',
    'rabonnîmes',
    'rabonnirai',
    'rabonniras',
    'rabonnirez',
    'rabonnisse',
    'rabonnîtes',
    'rabotaient',
    'rabotasses',
    'rabotement',
    'raboterais',
    'raboterait',
    'rabotèrent',
    'raboteriez',
    'raboterons',
    'raboteront',
    'raboteuses',
    'rabougries',
    'rabougrira',
    'raboutâmes',
    'raboutasse',
    'raboutâtes',
    'rabouterai',
    'rabouteras',
    'rabouterez',
    'raboutions',
    'rabrouâmes',
    'rabrouasse',
    'rabrouâtes',
    'rabrouerai',
    'rabroueras',
    'rabrouerez',
    'rabrouions',
    'raccommoda',
    'raccommode',
    'raccommodé',
    'raccordais',
    'raccordait',
    'raccordant',
    'raccordées',
    'raccordent',
    'raccordera',
    'raccordiez',
    'raccordons',
    'raccourcie',
    'raccourcir',
    'raccourcis',
    'raccourcit',
    'raccourcît',
    'raccoutrai',
    'raccoutras',
    'raccoutrât',
    'raccoutrée',
    'raccoutrer',
    'raccoutres',
    'raccoutrés',
    'raccoutrez',
    'raccoutuma',
    'raccoutume',
    'raccoutumé',
    'raccrochai',
    'raccrochas',
    'raccrochât',
    'raccrochée',
    'raccrocher',
    'raccroches',
    'raccrochés',
    'raccrochez',
    'racémiques',
    'rachetable',
    'rachetâmes',
    'rachetasse',
    'rachetâtes',
    'rachèterai',
    'rachèteras',
    'rachèterez',
    'rachetions',
    'rachialgie',
    'rachidiens',
    'rachitique',
    'rachitisme',
    'racinaient',
    'racinasses',
    'racinerais',
    'racinerait',
    'racinèrent',
    'racineriez',
    'racinerons',
    'racineront',
    'racinienne',
    'raciologie',
    'racketters',
    'racketteur',
    'raclassent',
    'raclassiez',
    'raclements',
    'raclerions',
    'racolaient',
    'racolasses',
    'racolerais',
    'racolerait',
    'racolèrent',
    'racoleriez',
    'racolerons',
    'racoleront',
    'racoleuses',
    'racontable',
    'racontâmes',
    'racontasse',
    'racontâtes',
    'raconterai',
    'raconteras',
    'raconterez',
    'raconteurs',
    'raconteuse',
    'racontions',
    'racornîmes',
    'racornirai',
    'racorniras',
    'racornirez',
    'racornisse',
    'racornîtes',
    'radaristes',
    'radassions',
    'raderaient',
    'radiassent',
    'radiassiez',
    'radiateurs',
    'radiations',
    'radiatives',
    'radicalisa',
    'radicalise',
    'radicalisé',
    'radicantes',
    'radicelles',
    'radierions',
    'radinaient',
    'radinasses',
    'radinerais',
    'radinerait',
    'radinèrent',
    'radineries',
    'radineriez',
    'radinerons',
    'radineront',
    'radioactif',
    'radioguida',
    'radioguide',
    'radioguidé',
    'radiologie',
    'radiologue',
    'radiolyses',
    'radiomètre',
    'radiophare',
    'radioscopa',
    'radioscope',
    'radioscopé',
    'radiosonde',
    'radio-taxi',
    'radotaient',
    'radotasses',
    'radoterais',
    'radoterait',
    'radotèrent',
    'radoteriez',
    'radoterons',
    'radoteront',
    'radoteuses',
    'radoubâmes',
    'radoubasse',
    'radoubâtes',
    'radouberai',
    'radouberas',
    'radouberez',
    'radoubions',
    'radoucîmes',
    'radoucirai',
    'radouciras',
    'radoucirez',
    'radoucisse',
    'radoucîtes',
    'raffermies',
    'raffermira',
    'raffinages',
    'raffinâmes',
    'raffinasse',
    'raffinâtes',
    'raffinerai',
    'raffineras',
    'raffinerez',
    'raffinerie',
    'raffineurs',
    'raffineuse',
    'raffinions',
    'rafflesias',
    'rafflésies',
    'raffolâmes',
    'raffolasse',
    'raffolâtes',
    'raffolerai',
    'raffoleras',
    'raffolerez',
    'raffolions',
    'raffûtâmes',
    'raffûtasse',
    'raffûtâtes',
    'raffûterai',
    'raffûteras',
    'raffûterez',
    'raffûtions',
    'rafistolai',
    'rafistolas',
    'rafistolât',
    'rafistolée',
    'rafistoler',
    'rafistoles',
    'rafistolés',
    'rafistolez',
    'raflassent',
    'raflassiez',
    'raflerions',
    'rafraîchie',
    'rafraîchir',
    'rafraîchis',
    'rafraîchit',
    'rafraîchît',
    'rageassent',
    'rageassiez',
    'rageraient',
    'ragotaient',
    'ragotasses',
    'ragoterais',
    'ragoterait',
    'ragotèrent',
    'ragoteriez',
    'ragoterons',
    'ragoteront',
    'ragoûtâmes',
    'ragoûtante',
    'ragoûtants',
    'ragoûtasse',
    'ragoûtâtes',
    'ragoûterai',
    'ragoûteras',
    'ragoûterez',
    'ragoûtions',
    'ragrafâmes',
    'ragrafasse',
    'ragrafâtes',
    'ragraferai',
    'ragraferas',
    'ragraferez',
    'ragrafions',
    'ragréaient',
    'ragréasses',
    'ragréerais',
    'ragréerait',
    'ragréèrent',
    'ragréeriez',
    'ragréerons',
    'ragréeront',
    'raguassent',
    'raguassiez',
    'raguerions',
    'raidillons',
    'raidirâmes',
    'raidirasse',
    'raidirâtes',
    'raidissais',
    'raidissait',
    'raidissant',
    'raidissent',
    'raidisseur',
    'raidissiez',
    'raidissons',
    'raieraient',
    'raillaient',
    'raillasses',
    'raillerais',
    'raillerait',
    'raillèrent',
    'railleries',
    'railleriez',
    'raillerons',
    'railleront',
    'railleuses',
    'rail-route',
    'rainassent',
    'rainassiez',
    'rainerions',
    'rainetâmes',
    'rainetasse',
    'rainetâtes',
    'rainetions',
    'rainettent',
    'rainettera',
    'rainurages',
    'rainurâmes',
    'rainurasse',
    'rainurâtes',
    'rainurerai',
    'rainureras',
    'rainurerez',
    'rainurions',
    'raisonnais',
    'raisonnait',
    'raisonnant',
    'raisonnées',
    'raisonnent',
    'raisonnera',
    'raisonneur',
    'raisonniez',
    'raisonnons',
    'rajeunîmes',
    'rajeunirai',
    'rajeuniras',
    'rajeunirez',
    'rajeunisse',
    'rajeunîtes',
    'rajoutâmes',
    'rajoutasse',
    'rajoutâtes',
    'rajouterai',
    'rajouteras',
    'rajouterez',
    'rajoutions',
    'rajustâmes',
    'rajustasse',
    'rajustâtes',
    'rajusterai',
    'rajusteras',
    'rajusterez',
    'rajustions',
    'râlassions',
    'ralentîmes',
    'ralentirai',
    'ralentiras',
    'ralentirez',
    'ralentisse',
    'ralentîtes',
    'râleraient',
    'ralinguais',
    'ralinguait',
    'ralinguant',
    'ralinguées',
    'ralinguent',
    'ralinguera',
    'ralinguiez',
    'ralinguons',
    'rallégeais',
    'rallégeait',
    'rallégeant',
    'rallégeons',
    'rallégerai',
    'rallégeras',
    'rallégerez',
    'rallégions',
    'ralliaient',
    'ralliasses',
    'ralliement',
    'rallierais',
    'rallierait',
    'rallièrent',
    'rallieriez',
    'rallierons',
    'rallieront',
    'rallongeai',
    'rallongeas',
    'rallongeât',
    'rallongées',
    'rallongent',
    'rallongera',
    'rallongiez',
    'rallumâmes',
    'rallumasse',
    'rallumâtes',
    'rallumerai',
    'rallumeras',
    'rallumerez',
    'rallumions',
    'ramageâmes',
    'ramageasse',
    'ramageâtes',
    'ramagerais',
    'ramagerait',
    'ramagèrent',
    'ramageriez',
    'ramagerons',
    'ramageront',
    'ramaillais',
    'ramaillait',
    'ramaillant',
    'ramaillent',
    'ramaillera',
    'ramailliez',
    'ramaillons',
    'ramandâmes',
    'ramandasse',
    'ramandâtes',
    'ramanderai',
    'ramanderas',
    'ramanderez',
    'ramandions',
    'ramarrâmes',
    'ramarrasse',
    'ramarrâtes',
    'ramarrerai',
    'ramarreras',
    'ramarrerez',
    'ramarrions',
    'ramassages',
    'ramassâmes',
    'ramassasse',
    'ramassâtes',
    'ramasserai',
    'ramasseras',
    'ramasserez',
    'ramassette',
    'ramasseurs',
    'ramasseuse',
    'ramassions',
    'ramastiqua',
    'ramastique',
    'ramastiqué',
    'ramenaient',
    'ramenasses',
    'ramendâmes',
    'ramendasse',
    'ramendâtes',
    'ramenderai',
    'ramenderas',
    'ramenderez',
    'ramendeurs',
    'ramendeuse',
    'ramendions',
    'ramènerais',
    'ramènerait',
    'ramenèrent',
    'ramèneriez',
    'ramènerons',
    'ramèneront',
    'rameraient',
    'ramescence',
    'rameutâmes',
    'rameutasse',
    'rameutâtes',
    'rameuterai',
    'rameuteras',
    'rameuterez',
    'rameutions',
    'ramifiâmes',
    'ramifiasse',
    'ramifiâtes',
    'ramifierai',
    'ramifieras',
    'ramifierez',
    'ramifiions',
    'ramollîmes',
    'ramollirai',
    'ramolliras',
    'ramollirez',
    'ramollisse',
    'ramollîtes',
    'ramonaient',
    'ramonasses',
    'ramonerais',
    'ramonerait',
    'ramonèrent',
    'ramoneriez',
    'ramonerons',
    'ramoneront',
    'rampassent',
    'rampassiez',
    'rampements',
    'ramperions',
    'ramponeaux',
    'ramponneau',
    'rancardais',
    'rancardait',
    'rancardant',
    'rancardées',
    'rancardent',
    'rancardera',
    'rancardiez',
    'rancardons',
    'rancirions',
    'rancissais',
    'rancissait',
    'rancissant',
    'rancissent',
    'rancissiez',
    'rancissons',
    'rancissure',
    'rançonnais',
    'rançonnait',
    'rançonnant',
    'rançonnées',
    'rançonnent',
    'rançonnera',
    'rançonniez',
    'rançonnons',
    'rancunière',
    'rancuniers',
    'randomisai',
    'randomisas',
    'randomisât',
    'randomisée',
    'randomiser',
    'randomises',
    'randomisés',
    'randomisez',
    'randonnais',
    'randonnait',
    'randonnant',
    'randonnées',
    'randonnent',
    'randonnera',
    'randonneur',
    'randonniez',
    'randonnons',
    'rangeaient',
    'rangeasses',
    'rangements',
    'rangerions',
    'ranimaient',
    'ranimasses',
    'ranimerais',
    'ranimerait',
    'ranimèrent',
    'ranimeriez',
    'ranimerons',
    'ranimeront',
    'râpassions',
    'rapatriais',
    'rapatriait',
    'rapatriant',
    'rapatriées',
    'rapatrient',
    'rapatriera',
    'rapatriiez',
    'rapatrions',
    'râperaient',
    'rapetassai',
    'rapetassas',
    'rapetassât',
    'rapetassée',
    'rapetasser',
    'rapetasses',
    'rapetassés',
    'rapetassez',
    'rapetissai',
    'rapetissas',
    'rapetissât',
    'rapetissée',
    'rapetisser',
    'rapetisses',
    'rapetissés',
    'rapetissez',
    'rapidement',
    'rapiéçages',
    'rapiéçâmes',
    'rapiéçasse',
    'rapiéçâtes',
    'rapiécerai',
    'rapiéceras',
    'rapiécerez',
    'rapiécetai',
    'rapiécetas',
    'rapiécetât',
    'rapiécetée',
    'rapiéceter',
    'rapiécetés',
    'rapiécètes',
    'rapiécetez',
    'rapiécions',
    'rapinaient',
    'rapinasses',
    'rapinerais',
    'rapinerait',
    'rapinèrent',
    'rapineries',
    'rapineriez',
    'rapinerons',
    'rapineront',
    'raplatîmes',
    'raplatirai',
    'raplatiras',
    'raplatirez',
    'raplatisse',
    'raplatîtes',
    'rapointies',
    'rapointira',
    'rappariais',
    'rappariait',
    'rappariant',
    'rappariées',
    'rapparient',
    'rappariera',
    'rappariiez',
    'rapparions',
    'rappelable',
    'rappelâmes',
    'rappelasse',
    'rappelâtes',
    'rappelions',
    'rappellent',
    'rappellera',
    'rappliquai',
    'rappliquas',
    'rappliquât',
    'rappliquer',
    'rappliques',
    'rappliquez',
    'rappointis',
    'rapportage',
    'rapportais',
    'rapportait',
    'rapportant',
    'rapportées',
    'rapportent',
    'rapportera',
    'rapporteur',
    'rapportiez',
    'rapportons',
    'rapprenais',
    'rapprenait',
    'rapprenant',
    'rapprendes',
    'rapprendra',
    'rapprendre',
    'rappreniez',
    'rapprenons',
    'rapprêtais',
    'rapprêtait',
    'rapprêtant',
    'rapprêtées',
    'rapprêtent',
    'rapprêtera',
    'rapprêtiez',
    'rapprêtons',
    'rapprirent',
    'rapprisses',
    'rapprochai',
    'rapprochas',
    'rapprochât',
    'rapprochée',
    'rapprocher',
    'rapproches',
    'rapprochés',
    'rapprochez',
    'rappropria',
    'rapproprie',
    'rapproprié',
    'raquassent',
    'raquassiez',
    'raquerions',
    'raréfiâmes',
    'raréfiasse',
    'raréfiâtes',
    'raréfierai',
    'raréfieras',
    'raréfierez',
    'raréfiions',
    'rarescente',
    'rarescents',
    'rarissimes',
    'rasassions',
    'raseraient',
    'rassasiais',
    'rassasiait',
    'rassasiant',
    'rassasiées',
    'rassasient',
    'rassasiera',
    'rassasiiez',
    'rassasions',
    'rassemblai',
    'rassemblas',
    'rassemblât',
    'rassemblée',
    'rassembler',
    'rassembles',
    'rassemblés',
    'rassemblez',
    'rassérénai',
    'rassérénas',
    'rassérénât',
    'rassérénée',
    'rasséréner',
    'rassérénés',
    'rassérènes',
    'rassérénez',
    'rasseyions',
    'rassiérais',
    'rassiérait',
    'rassiériez',
    'rassiérons',
    'rassiéront',
    'rassissent',
    'rassissiez',
    'rassorties',
    'rassortira',
    'rassoyions',
    'rassurâmes',
    'rassurante',
    'rassurants',
    'rassurasse',
    'rassurâtes',
    'rassurerai',
    'rassureras',
    'rassurerez',
    'rassurions',
    'ratassions',
    'ratatinais',
    'ratatinait',
    'ratatinant',
    'ratatinées',
    'ratatinent',
    'ratatinera',
    'ratatiniez',
    'ratatinons',
    'râtelaient',
    'râtelasses',
    'râtelèrent',
    'râteleuses',
    'râtellerai',
    'râtelleras',
    'râtellerez',
    'rateraient',
    'ratiboisai',
    'ratiboisas',
    'ratiboisât',
    'ratiboisée',
    'ratiboiser',
    'ratiboises',
    'ratiboisés',
    'ratiboisez',
    'ratifierai',
    'ratifieras',
    'ratifierez',
    'ratifiions',
    'ratinaient',
    'ratinasses',
    'ratinerais',
    'ratinerait',
    'ratinèrent',
    'ratineriez',
    'ratinerons',
    'ratineront',
    'ratiocinai',
    'ratiocinas',
    'ratiocinât',
    'ratiociner',
    'ratiocines',
    'ratiocinez',
    'rationnais',
    'rationnait',
    'rationnant',
    'rationnées',
    'rationnels',
    'rationnent',
    'rationnera',
    'rationniez',
    'rationnons',
    'ratissages',
    'ratissâmes',
    'ratissasse',
    'ratissâtes',
    'ratisserai',
    'ratisseras',
    'ratisserez',
    'ratissions',
    'ratissoire',
    'rattachais',
    'rattachait',
    'rattachant',
    'rattachées',
    'rattachent',
    'rattachera',
    'rattachiez',
    'rattachons',
    'rattrapage',
    'rattrapais',
    'rattrapait',
    'rattrapant',
    'rattrapées',
    'rattrapent',
    'rattrapera',
    'rattrapiez',
    'rattrapons',
    'raturaient',
    'raturasses',
    'raturerais',
    'raturerait',
    'raturèrent',
    'ratureriez',
    'raturerons',
    'ratureront',
    'raugmentai',
    'raugmentas',
    'raugmentât',
    'raugmenter',
    'raugmentes',
    'raugmentez',
    'rauquement',
    'rauwolfias',
    'ravageâmes',
    'ravageasse',
    'ravageâtes',
    'ravagerais',
    'ravagerait',
    'ravagèrent',
    'ravageriez',
    'ravagerons',
    'ravageront',
    'ravageuses',
    'ravalaient',
    'ravalasses',
    'ravalement',
    'ravalerais',
    'ravalerait',
    'ravalèrent',
    'ravaleriez',
    'ravalerons',
    'ravaleront',
    'ravaudages',
    'ravaudâmes',
    'ravaudasse',
    'ravaudâtes',
    'ravauderai',
    'ravauderas',
    'ravauderez',
    'ravaudeurs',
    'ravaudeuse',
    'ravaudions',
    'ravenelles',
    'ravigotais',
    'ravigotait',
    'ravigotant',
    'ravigotées',
    'ravigotent',
    'ravigotera',
    'ravigotiez',
    'ravigotons',
    'ravilirais',
    'ravilirait',
    'ravilirent',
    'raviliriez',
    'ravilirons',
    'raviliront',
    'ravilisses',
    'ravilissez',
    'ravinaient',
    'ravinasses',
    'ravinement',
    'ravinerais',
    'ravinerait',
    'ravinèrent',
    'ravineriez',
    'ravinerons',
    'ravineront',
    'raviraient',
    'ravisaient',
    'ravisasses',
    'raviserais',
    'raviserait',
    'ravisèrent',
    'raviseriez',
    'raviserons',
    'raviseront',
    'ravissante',
    'ravissants',
    'ravisseurs',
    'ravisseuse',
    'ravissions',
    'ravitailla',
    'ravitaille',
    'ravitaillé',
    'ravivaient',
    'ravivasses',
    'ravivèrent',
    'rayassions',
    'rayeraient',
    'rayonnages',
    'rayonnâmes',
    'rayonnante',
    'rayonnants',
    'rayonnasse',
    'rayonnâtes',
    'rayonnerai',
    'rayonneras',
    'rayonnerez',
    'rayonnions',
    'razziaient',
    'razziasses',
    'razzièrent',
    'réabonnais',
    'réabonnait',
    'réabonnant',
    'réabonnées',
    'réabonnent',
    'réabonnera',
    'réabonniez',
    'réabonnons',
    'réabsorbai',
    'réabsorbas',
    'réabsorbât',
    'réabsorbée',
    'réabsorber',
    'réabsorbes',
    'réabsorbés',
    'réabsorbez',
    'réactivais',
    'réactivait',
    'réactivant',
    'réactivées',
    'réactivent',
    'réactivera',
    'réactiviez',
    'réactivité',
    'réactivons',
    'réactrices',
    'réadaptais',
    'réadaptait',
    'réadaptant',
    'réadaptées',
    'réadaptent',
    'réadaptera',
    'réadaptiez',
    'réadaptons',
    'réadmettes',
    'réadmettez',
    'réadmettra',
    'réadmettre',
    'réadmirent',
    'réadmisses',
    'réaffectai',
    'réaffectas',
    'réaffectât',
    'réaffectée',
    'réaffecter',
    'réaffectes',
    'réaffectés',
    'réaffectez',
    'réaffirmai',
    'réaffirmas',
    'réaffirmât',
    'réaffirmée',
    'réaffirmer',
    'réaffirmes',
    'réaffirmés',
    'réaffirmez',
    'réaffûtais',
    'réaffûtait',
    'réaffûtant',
    'réaffûtées',
    'réaffûtent',
    'réaffûtera',
    'réaffûtiez',
    'réaffûtons',
    'réagirions',
    'réagissais',
    'réagissait',
    'réagissant',
    'réagissent',
    'réagissiez',
    'réagissons',
    'réajustais',
    'réajustait',
    'réajustant',
    'réajustées',
    'réajustent',
    'réajustera',
    'réajustiez',
    'réajustons',
    'réalésâmes',
    'réalésasse',
    'réalésâtes',
    'réaléserai',
    'réaléseras',
    'réaléserez',
    'réalésions',
    'réalignais',
    'réalignait',
    'réalignant',
    'réalignées',
    'réalignent',
    'réalignera',
    'réaligniez',
    'réalignons',
    'réalisable',
    'réalisâmes',
    'réalisasse',
    'réalisâtes',
    'réaliserai',
    'réaliseras',
    'réaliserez',
    'réalisions',
    'réaménagea',
    'réaménagée',
    'réaménager',
    'réaménages',
    'réaménagés',
    'réaménagez',
    'réamorçais',
    'réamorçait',
    'réamorçant',
    'réamorcées',
    'réamorcent',
    'réamorcera',
    'réamorciez',
    'réamorçons',
    'réanimâmes',
    'réanimasse',
    'réanimâtes',
    'réanimerai',
    'réanimeras',
    'réanimerez',
    'réanimions',
    'réapparais',
    'réapparaît',
    'réapparues',
    'réapprends',
    'réapprenez',
    'réapprenne',
    'réapprîmes',
    'réapprises',
    'réapprisse',
    'réapprîtes',
    'réargentai',
    'réargentas',
    'réargentât',
    'réargentée',
    'réargenter',
    'réargentes',
    'réargentés',
    'réargentez',
    'réarmaient',
    'réarmasses',
    'réarmement',
    'réarmerais',
    'réarmerait',
    'réarmèrent',
    'réarmeriez',
    'réarmerons',
    'réarmeront',
    'réarrangea',
    'réarrangée',
    'réarranger',
    'réarranges',
    'réarrangés',
    'réarrangez',
    'réassignai',
    'réassignas',
    'réassignât',
    'réassignée',
    'réassigner',
    'réassignes',
    'réassignés',
    'réassignez',
    'réassortie',
    'réassortir',
    'réassortis',
    'réassortit',
    'réassortît',
    'réassurais',
    'réassurait',
    'réassurant',
    'réassurées',
    'réassurent',
    'réassurera',
    'réassuriez',
    'réassurons',
    'rebaissais',
    'rebaissait',
    'rebaissant',
    'rebaissent',
    'rebaissera',
    'rebaissiez',
    'rebaissons',
    'rebandâmes',
    'rebandasse',
    'rebandâtes',
    'rebanderai',
    'rebanderas',
    'rebanderez',
    'rebandions',
    'rebaptisai',
    'rebaptisas',
    'rebaptisât',
    'rebaptisée',
    'rebaptiser',
    'rebaptises',
    'rebaptisés',
    'rebaptisez',
    'rébarbatif',
    'rebâtirais',
    'rebâtirait',
    'rebâtirent',
    'rebâtiriez',
    'rebâtirons',
    'rebâtiront',
    'rebâtisses',
    'rebâtissez',
    'rebattîmes',
    'rebattions',
    'rebattisse',
    'rebattîtes',
    'rebattrais',
    'rebattrait',
    'rebattriez',
    'rebattrons',
    'rebattront',
    'rebellâmes',
    'rebellasse',
    'rebellâtes',
    'rebellerai',
    'rebelleras',
    'rebellerez',
    'rebellions',
    'rébellions',
    'rebiffâmes',
    'rebiffasse',
    'rebiffâtes',
    'rebifferai',
    'rebifferas',
    'rebifferez',
    'rebiffions',
    'rebiquâmes',
    'rebiquasse',
    'rebiquâtes',
    'rebiquerai',
    'rebiqueras',
    'rebiquerez',
    'rebiquions',
    'reblanchie',
    'reblanchir',
    'reblanchis',
    'reblanchit',
    'reblanchît',
    'reblochons',
    'reboisâmes',
    'reboisasse',
    'reboisâtes',
    'reboiserai',
    'reboiseras',
    'reboiserez',
    'reboisions',
    'rebondîmes',
    'rebondirai',
    'rebondiras',
    'rebondirez',
    'rebondisse',
    'rebondîtes',
    'rebordâmes',
    'rebordasse',
    'rebordâtes',
    'reborderai',
    'reborderas',
    'reborderez',
    'rebordions',
    'rebouchais',
    'rebouchait',
    'rebouchant',
    'rebouchées',
    'rebouchent',
    'rebouchera',
    'rebouchiez',
    'rebouchons',
    'reboutâmes',
    'reboutasse',
    'reboutâtes',
    'rebouterai',
    'rebouteras',
    'rebouterez',
    'rebouteurs',
    'rebouteuse',
    'reboutions',
    'reboutonna',
    'reboutonne',
    'reboutonné',
    'rebrodâmes',
    'rebrodasse',
    'rebrodâtes',
    'rebroderai',
    'rebroderas',
    'rebroderez',
    'rebrodions',
    'rebroussai',
    'rebroussas',
    'rebroussât',
    'rebroussée',
    'rebrousser',
    'rebrousses',
    'rebroussés',
    'rebroussez',
    'rebrûlâmes',
    'rebrûlasse',
    'rebrûlâtes',
    'rebrûlerai',
    'rebrûleras',
    'rebrûlerez',
    'rebrûlions',
    'rebuffades',
    'rebutaient',
    'rebutantes',
    'rebutasses',
    'rebuterais',
    'rebuterait',
    'rebutèrent',
    'rebuteriez',
    'rebuterons',
    'rebuteront',
    'recachetai',
    'recachetas',
    'recachetât',
    'recachetée',
    'recacheter',
    'recachetés',
    'recachetez',
    'recachette',
    'recalaient',
    'recalasses',
    'recalcifia',
    'recalcifie',
    'recalcifié',
    'recalculai',
    'recalculas',
    'recalculât',
    'recalculée',
    'recalculer',
    'recalcules',
    'recalculés',
    'recalculez',
    'recalerais',
    'recalerait',
    'recalèrent',
    'recaleriez',
    'recalerons',
    'recaleront',
    'récapitula',
    'récapitule',
    'récapitulé',
    'recardâmes',
    'recardasse',
    'recardâtes',
    'recarderai',
    'recarderas',
    'recarderez',
    'recardions',
    'recarrelai',
    'recarrelas',
    'recarrelât',
    'recarrelée',
    'recarreler',
    'recarrelés',
    'recarrelez',
    'recarrelle',
    'recasaient',
    'recasasses',
    'recaserais',
    'recaserait',
    'recasèrent',
    'recaseriez',
    'recaserons',
    'recaseront',
    'recausâmes',
    'recausasse',
    'recausâtes',
    'recauserai',
    'recauseras',
    'recauserez',
    'recausions',
    'recédaient',
    'recédasses',
    'recéderais',
    'recéderait',
    'recédèrent',
    'recéderiez',
    'recéderons',
    'recéderont',
    'recelaient',
    'recélaient',
    'recelasses',
    'recélasses',
    'recélerais',
    'recèlerais',
    'recélerait',
    'recèlerait',
    'recelèrent',
    'recélèrent',
    'recéleriez',
    'recèleriez',
    'recélerons',
    'recèlerons',
    'recéleront',
    'recèleront',
    'receleuses',
    'recensâmes',
    'recensasse',
    'recensâtes',
    'recenserai',
    'recenseras',
    'recenserez',
    'recenseurs',
    'recenseuse',
    'recensions',
    'recentrais',
    'recentrait',
    'recentrant',
    'recentrées',
    'recentrent',
    'recentrera',
    'recentriez',
    'recentrons',
    'recepaient',
    'recépaient',
    'recepasses',
    'recépasses',
    'recéperais',
    'recèperais',
    'recéperait',
    'recèperait',
    'recepèrent',
    'recépèrent',
    'recéperiez',
    'recèperiez',
    'recéperons',
    'recèperons',
    'recéperont',
    'recèperont',
    'récépissés',
    'réceptacle',
    'récepteurs',
    'réceptions',
    'réceptives',
    'réceptrice',
    'recerclais',
    'recerclait',
    'recerclant',
    'recerclées',
    'recerclent',
    'recerclera',
    'recercliez',
    'recerclons',
    'récessions',
    'récessives',
    'recevables',
    'recevaient',
    'receveuses',
    'recevrions',
    'rechampies',
    'réchampies',
    'rechampira',
    'réchampira',
    'rechangeai',
    'rechangeas',
    'rechangeât',
    'rechangées',
    'rechangent',
    'rechangera',
    'rechangiez',
    'rechantais',
    'rechantait',
    'rechantant',
    'rechantées',
    'rechantent',
    'rechantera',
    'rechantiez',
    'rechantons',
    'rechapages',
    'rechapâmes',
    'rechapasse',
    'rechapâtes',
    'rechaperai',
    'rechaperas',
    'rechaperez',
    'rechapions',
    'réchappais',
    'réchappait',
    'réchappant',
    'réchappent',
    'réchappera',
    'réchappiez',
    'réchappons',
    'rechargeai',
    'rechargeas',
    'rechargeât',
    'rechargées',
    'rechargent',
    'rechargera',
    'rechargiez',
    'rechassais',
    'rechassait',
    'rechassant',
    'rechassées',
    'rechassent',
    'rechassera',
    'rechassiez',
    'rechassons',
    'réchauffai',
    'réchauffas',
    'réchauffât',
    'réchauffée',
    'réchauffer',
    'réchauffes',
    'réchauffés',
    'réchauffez',
    'rechaussai',
    'rechaussas',
    'rechaussât',
    'rechaussée',
    'rechausser',
    'rechausses',
    'rechaussés',
    'rechaussez',
    'recherchai',
    'recherchas',
    'recherchât',
    'recherchée',
    'rechercher',
    'recherches',
    'recherchés',
    'recherchez',
    'rechignais',
    'rechignait',
    'rechignant',
    'rechignent',
    'rechignera',
    'rechigniez',
    'rechignons',
    'rechutâmes',
    'rechutasse',
    'rechutâtes',
    'rechuterai',
    'rechuteras',
    'rechuterez',
    'rechutions',
    'récidivais',
    'récidivait',
    'récidivant',
    'récidivent',
    'récidivera',
    'récidiviez',
    'récidivons',
    'récipients',
    'réciproqua',
    'réciproque',
    'réciproqué',
    'récitaient',
    'récitantes',
    'récitasses',
    'récitatifs',
    'récitation',
    'réciterais',
    'réciterait',
    'récitèrent',
    'réciteriez',
    'réciterons',
    'réciteront',
    'réclamâmes',
    'réclamasse',
    'réclamâtes',
    'réclamerai',
    'réclameras',
    'réclamerez',
    'réclamions',
    'reclassais',
    'reclassait',
    'reclassant',
    'reclassées',
    'reclassent',
    'reclassera',
    'reclassiez',
    'reclassons',
    'réclinâmes',
    'réclinasse',
    'réclinâtes',
    'réclinerai',
    'réclineras',
    'réclinerez',
    'réclinions',
    'reclouâmes',
    'reclouasse',
    'reclouâtes',
    'reclouerai',
    'recloueras',
    'reclouerez',
    'reclouions',
    'réclusions',
    'récognitif',
    'recoiffais',
    'recoiffait',
    'recoiffant',
    'recoiffées',
    'recoiffent',
    'recoiffera',
    'recoiffiez',
    'recoiffons',
    'recolaient',
    'recolasses',
    'récolement',
    'recolerais',
    'recolerait',
    'recolèrent',
    'recoleriez',
    'recolerons',
    'recoleront',
    'recollages',
    'recollâmes',
    'recollasse',
    'recollâtes',
    'recollerai',
    'recolleras',
    'recollerez',
    'recollions',
    'recolorais',
    'recolorait',
    'recolorant',
    'recolorées',
    'recolorent',
    'recolorera',
    'recoloriez',
    'recolorons',
    'récoltâmes',
    'récoltante',
    'récoltants',
    'récoltasse',
    'récoltâtes',
    'récolterai',
    'récolteras',
    'récolterez',
    'récolteurs',
    'récoltions',
    'recombinai',
    'recombinas',
    'recombinât',
    'recombinée',
    'recombiner',
    'recombines',
    'recombinés',
    'recombinez',
    'recommanda',
    'recommande',
    'recommandé',
    'recommença',
    'recommence',
    'recommencé',
    'recomparue',
    'recomparus',
    'recomparut',
    'recomparût',
    'récompensa',
    'récompense',
    'récompensé',
    'recompilai',
    'recompilas',
    'recompilât',
    'recompilée',
    'recompiler',
    'recompiles',
    'recompilés',
    'recompilez',
    'recomposai',
    'recomposas',
    'recomposât',
    'recomposée',
    'recomposer',
    'recomposes',
    'recomposés',
    'recomposez',
    'recomptais',
    'recomptait',
    'recomptant',
    'recomptées',
    'recomptent',
    'recomptera',
    'recomptiez',
    'recomptons',
    'réconcilia',
    'réconcilie',
    'réconcilié',
    'recondamna',
    'recondamne',
    'recondamné',
    'reconduira',
    'reconduire',
    'reconduise',
    'reconduite',
    'reconduits',
    'réconforta',
    'réconforte',
    'réconforté',
    'réconforts',
    'recongelai',
    'recongelas',
    'recongelât',
    'recongelée',
    'recongeler',
    'recongelés',
    'recongèles',
    'recongelez',
    'reconnecta',
    'reconnecte',
    'reconnecté',
    'reconnûmes',
    'reconnusse',
    'reconnûtes',
    'reconquéru',
    'reconquête',
    'reconquise',
    'reconverti',
    'recopiâmes',
    'recopiasse',
    'recopiâtes',
    'recopierai',
    'recopieras',
    'recopierez',
    'recopiions',
    'recoquilla',
    'recoquille',
    'recoquillé',
    'recordages',
    'recordâmes',
    'recordasse',
    'recordâtes',
    'recorderai',
    'recorderas',
    'recorderez',
    'recordions',
    'recordmans',
    'recorrigea',
    'recorrigée',
    'recorriger',
    'recorriges',
    'recorrigés',
    'recorrigez',
    'recouchais',
    'recouchait',
    'recouchant',
    'recouchées',
    'recouchent',
    'recouchera',
    'recouchiez',
    'recouchons',
    'recoudrais',
    'recoudrait',
    'recoudriez',
    'recoudrons',
    'recoudront',
    'recoupâmes',
    'recoupasse',
    'recoupâtes',
    'recouperai',
    'recouperas',
    'recouperez',
    'recoupette',
    'recoupions',
    'recourbais',
    'recourbait',
    'recourbant',
    'recourbées',
    'recourbent',
    'recourbera',
    'recourbiez',
    'recourbons',
    'recourbure',
    'recourions',
    'recourrais',
    'recourrait',
    'recourriez',
    'recourrons',
    'recourront',
    'recourûmes',
    'recourusse',
    'recourûtes',
    'recousîmes',
    'recousions',
    'recousisse',
    'recousîtes',
    'recouverte',
    'recouverts',
    'recouvrage',
    'recouvrais',
    'recouvrait',
    'recouvrant',
    'recouvrées',
    'recouvrent',
    'recouvrera',
    'recouvriez',
    'recouvrira',
    'recouvrons',
    'recouvrues',
    'recrachais',
    'recrachait',
    'recrachant',
    'recrachées',
    'recrachent',
    'recrachera',
    'recrachiez',
    'recrachons',
    'recréaient',
    'récréaient',
    'recréasses',
    'récréasses',
    'récréatifs',
    'recréation',
    'récréation',
    'récréative',
    'recréerais',
    'récréerais',
    'recréerait',
    'récréerait',
    'recréèrent',
    'récréèrent',
    'recréeriez',
    'récréeriez',
    'recréerons',
    'récréerons',
    'recréeront',
    'récréeront',
    'recrépîmes',
    'recrépirai',
    'recrépiras',
    'recrépirez',
    'recrépisse',
    'recrépîtes',
    'recreusais',
    'recreusait',
    'recreusant',
    'recreusées',
    'recreusent',
    'recreusera',
    'recreusiez',
    'recreusons',
    'récriaient',
    'récriasses',
    'récrierais',
    'récrierait',
    'récrièrent',
    'récrieriez',
    'récrierons',
    'récrieront',
    'récriminai',
    'récriminas',
    'récriminât',
    'récriminer',
    'récrimines',
    'récriminez',
    'récririons',
    'récrivîmes',
    'récrivions',
    'récrivisse',
    'récrivîtes',
    'recroisais',
    'recroisait',
    'recroisant',
    'recroisées',
    'recroisent',
    'recroisera',
    'recroisiez',
    'recroisons',
    'recroisses',
    'recroissez',
    'recroîtrai',
    'recroîtras',
    'recroîtrez',
    'recrussent',
    'recrussiez',
    'recrutâmes',
    'recrutasse',
    'recrutâtes',
    'recruterai',
    'recruteras',
    'recruterez',
    'recruteurs',
    'recrutions',
    'rectangles',
    'rectifiais',
    'rectifiait',
    'rectifiant',
    'rectifiées',
    'rectifient',
    'rectifiera',
    'rectifieur',
    'rectifiiez',
    'rectifions',
    'rectiligne',
    'rectitudes',
    'rectorales',
    'rectoscope',
    'recueilles',
    'recueillez',
    'recueillie',
    'recueillir',
    'recueillis',
    'recueillit',
    'recueillît',
    'recuirions',
    'recuisîmes',
    'recuisions',
    'recuisisse',
    'recuisîtes',
    'reculaient',
    'reculasses',
    'reculement',
    'reculerais',
    'reculerait',
    'reculèrent',
    'reculeriez',
    'reculerons',
    'reculeront',
    'reculottai',
    'reculottas',
    'reculottât',
    'reculottée',
    'reculotter',
    'reculottes',
    'reculottés',
    'reculottez',
    'récupérais',
    'récupérait',
    'récupérant',
    'récupérées',
    'récupèrent',
    'récupérera',
    'récupériez',
    'récupérons',
    'récuraient',
    'récurasses',
    'récurerais',
    'récurerait',
    'récurèrent',
    'récureriez',
    'récurerons',
    'récureront',
    'récurrence',
    'récurrente',
    'récurrents',
    'récursions',
    'récursives',
    'récursoire',
    'récusables',
    'récusaient',
    'récusasses',
    'récusation',
    'récuserais',
    'récuserait',
    'récusèrent',
    'récuseriez',
    'récuserons',
    'récuseront',
    'reçussions',
    'recyclages',
    'recyclâmes',
    'recyclasse',
    'recyclâtes',
    'recyclerai',
    'recycleras',
    'recyclerez',
    'recyclions',
    'rédacteurs',
    'rédactions',
    'rédactrice',
    'redditions',
    'redéfaites',
    'redéfasses',
    'redéferais',
    'redéferait',
    'redéferiez',
    'redéferons',
    'redéferont',
    'redéfinies',
    'redéfinira',
    'redéfirent',
    'redéfisses',
    'redemandai',
    'redemandas',
    'redemandât',
    'redemandée',
    'redemander',
    'redemandes',
    'redemandés',
    'redemandez',
    'redémarrai',
    'redémarras',
    'redémarrât',
    'redémarrer',
    'redémarres',
    'redémarrez',
    'redémolies',
    'redémolira',
    'redémontra',
    'redémontre',
    'redémontré',
    'rédempteur',
    'rédemption',
    'redéploies',
    'redéployai',
    'redéployas',
    'redéployât',
    'redéployée',
    'redéployer',
    'redéployés',
    'redéployez',
    'redescende',
    'redescends',
    'redescendu',
    'redevables',
    'redevaient',
    'redevances',
    'redevenais',
    'redevenait',
    'redevenant',
    'redeveniez',
    'redevenons',
    'redevenues',
    'redevienne',
    'redevînmes',
    'redevinsse',
    'redevîntes',
    'redevrions',
    'rédigeâmes',
    'rédigeasse',
    'rédigeâtes',
    'rédigerais',
    'rédigerait',
    'rédigèrent',
    'rédigeriez',
    'rédigerons',
    'rédigeront',
    'rédimaient',
    'rédimasses',
    'rédimerais',
    'rédimerait',
    'rédimèrent',
    'rédimeriez',
    'rédimerons',
    'rédimeront',
    'redingotes',
    'rediraient',
    'redisaient',
    'rediscutai',
    'rediscutas',
    'rediscutât',
    'rediscutée',
    'rediscuter',
    'rediscutes',
    'rediscutés',
    'rediscutez',
    'redissions',
    'redondâmes',
    'redondance',
    'redondante',
    'redondants',
    'redondasse',
    'redondâtes',
    'redonderai',
    'redonderas',
    'redonderez',
    'redondions',
    'redonnâmes',
    'redonnasse',
    'redonnâtes',
    'redonnerai',
    'redonneras',
    'redonnerez',
    'redonnions',
    'redoraient',
    'redorasses',
    'redorerais',
    'redorerait',
    'redorèrent',
    'redoreriez',
    'redorerons',
    'redoreront',
    'redoublais',
    'redoublait',
    'redoublant',
    'redoublées',
    'redoublent',
    'redoublera',
    'redoubliez',
    'redoublons',
    'redoutable',
    'redoutâmes',
    'redoutasse',
    'redoutâtes',
    'redouterai',
    'redouteras',
    'redouterez',
    'redoutions',
    'redressais',
    'redressait',
    'redressant',
    'redressées',
    'redressent',
    'redressera',
    'redresseur',
    'redressiez',
    'redressons',
    'réductases',
    'réducteurs',
    'réductible',
    'réductions',
    'réductrice',
    'réduirions',
    'réduisîmes',
    'réduisions',
    'réduisisse',
    'réduisîtes',
    'redussions',
    'réécoutais',
    'réécoutait',
    'réécoutant',
    'réécoutées',
    'réécoutent',
    'réécoutera',
    'réécoutiez',
    'réécoutons',
    'réécriture',
    'réédifiais',
    'réédifiait',
    'réédifiant',
    'réédifiées',
    'réédifient',
    'réédifiera',
    'réédifiiez',
    'réédifions',
    'rééditâmes',
    'rééditasse',
    'rééditâtes',
    'rééditerai',
    'rééditeras',
    'rééditerez',
    'rééditions',
    'rééduquais',
    'rééduquait',
    'rééduquant',
    'rééduquées',
    'rééduquent',
    'rééduquera',
    'rééduquiez',
    'rééduquons',
    'réélection',
    'rééligible',
    'réélirions',
    'réélisions',
    'réellement',
    'réélussent',
    'réélussiez',
    'réembaucha',
    'réembauche',
    'réembauché',
    'réemploies',
    'réemployai',
    'réemployas',
    'réemployât',
    'réemployée',
    'réemployer',
    'réemployés',
    'réemployez',
    'réemprunta',
    'réemprunte',
    'réemprunté',
    'réengageai',
    'réengageas',
    'réengageât',
    'réengagées',
    'réengagent',
    'réengagera',
    'réengagiez',
    'réescompta',
    'réescompte',
    'réescompté',
    'réessaient',
    'réessaiera',
    'réessayage',
    'réessayais',
    'réessayait',
    'réessayant',
    'réessayées',
    'réessayent',
    'réessayera',
    'réessayiez',
    'réessayons',
    'réévaluais',
    'réévaluait',
    'réévaluant',
    'réévaluées',
    'réévaluent',
    'réévaluera',
    'réévaluiez',
    'réévaluons',
    'réexaminai',
    'réexaminas',
    'réexaminât',
    'réexaminée',
    'réexaminer',
    'réexamines',
    'réexaminés',
    'réexaminez',
    'réexpédiai',
    'réexpédias',
    'réexpédiât',
    'réexpédiée',
    'réexpédier',
    'réexpédies',
    'réexpédiés',
    'réexpédiez',
    'réexportai',
    'réexportas',
    'réexportât',
    'réexportée',
    'réexporter',
    'réexportes',
    'réexportés',
    'réexportez',
    'refaçonnai',
    'refaçonnas',
    'refaçonnât',
    'refaçonnée',
    'refaçonner',
    'refaçonnes',
    'refaçonnés',
    'refaçonnez',
    'réfactions',
    'refaisions',
    'refassions',
    'réfections',
    'réfectoire',
    'refendîmes',
    'refendions',
    'refendisse',
    'refendîtes',
    'refendrais',
    'refendrait',
    'refendriez',
    'refendrons',
    'refendront',
    'referaient',
    'référaient',
    'référasses',
    'référençai',
    'référenças',
    'référençât',
    'référencée',
    'référencer',
    'références',
    'référencés',
    'référencez',
    'référendum',
    'référerais',
    'référerait',
    'référèrent',
    'référeriez',
    'référerons',
    'référeront',
    'refermâmes',
    'refermasse',
    'refermâtes',
    'refermerai',
    'refermeras',
    'refermerez',
    'refermions',
    'refilaient',
    'refilasses',
    'refilerais',
    'refilerait',
    'refilèrent',
    'refileriez',
    'refilerons',
    'refileront',
    'refissions',
    'réfléchies',
    'réfléchira',
    'réflecteur',
    'réflectifs',
    'réflection',
    'réflective',
    'reflétâmes',
    'reflétasse',
    'reflétâtes',
    'refléterai',
    'refléteras',
    'refléterez',
    'reflétions',
    'refleuries',
    'refleurira',
    'réflexible',
    'réflexions',
    'réflexives',
    'refluaient',
    'refluasses',
    'refluerais',
    'refluerait',
    'refluèrent',
    'reflueriez',
    'refluerons',
    'reflueront',
    'refondîmes',
    'refondions',
    'refondisse',
    'refondîtes',
    'refondrais',
    'refondrait',
    'refondriez',
    'refondrons',
    'refondront',
    'reforgeais',
    'reforgeait',
    'reforgeant',
    'reforgeons',
    'reforgerai',
    'reforgeras',
    'reforgerez',
    'reforgions',
    'réformable',
    'reformâmes',
    'réformâmes',
    'reformasse',
    'réformasse',
    'reformâtes',
    'réformâtes',
    'reformerai',
    'réformerai',
    'reformeras',
    'réformeras',
    'reformerez',
    'réformerez',
    'réformette',
    'réformions',
    'réformisme',
    'réformiste',
    'reformulai',
    'reformulas',
    'reformulât',
    'reformulée',
    'reformuler',
    'reformules',
    'reformulés',
    'reformulez',
    'refouillai',
    'refouillas',
    'refouillât',
    'refouillée',
    'refouiller',
    'refouilles',
    'refouillés',
    'refouillez',
    'refoulâmes',
    'refoulasse',
    'refoulâtes',
    'refoulerai',
    'refouleras',
    'refoulerez',
    'refoulions',
    'refourrais',
    'refourrait',
    'refourrant',
    'refourrées',
    'refourrent',
    'refourrera',
    'refourriez',
    'refourrons',
    'réfractais',
    'réfractait',
    'réfractant',
    'réfractées',
    'réfractent',
    'réfractera',
    'réfracteur',
    'réfractiez',
    'réfraction',
    'réfractons',
    'refrénâmes',
    'réfrénâmes',
    'refrénasse',
    'réfrénasse',
    'refrénâtes',
    'réfrénâtes',
    'refrénerai',
    'réfrénerai',
    'refréneras',
    'réfréneras',
    'refrénerez',
    'réfrénerez',
    'refrénions',
    'réfrénions',
    'réfrigérai',
    'réfrigéras',
    'réfrigérât',
    'réfrigérée',
    'réfrigérer',
    'réfrigérés',
    'réfrigères',
    'réfrigérez',
    'réfringent',
    'refroidies',
    'refroidira',
    'réfugiâmes',
    'réfugiasse',
    'réfugiâtes',
    'réfugierai',
    'réfugieras',
    'réfugierez',
    'réfugiions',
    'refusables',
    'refusaient',
    'refusasses',
    'refuserais',
    'refuserait',
    'refusèrent',
    'refuseriez',
    'refuserons',
    'refuseront',
    'réfutables',
    'réfutaient',
    'réfutasses',
    'réfutation',
    'réfuterais',
    'réfuterait',
    'réfutèrent',
    'réfuteriez',
    'réfuterons',
    'réfuteront',
    'regagnâmes',
    'regagnasse',
    'regagnâtes',
    'regagnerai',
    'regagneras',
    'regagnerez',
    'regagnions',
    'régalaient',
    'régalasses',
    'régalement',
    'régalerais',
    'régalerait',
    'régalèrent',
    'régaleriez',
    'régalerons',
    'régaleront',
    'régalienne',
    'regardable',
    'regardâmes',
    'regardante',
    'regardants',
    'regardasse',
    'regardâtes',
    'regarderai',
    'regarderas',
    'regarderez',
    'regardions',
    'regarnîmes',
    'regarnirai',
    'regarniras',
    'regarnirez',
    'regarnisse',
    'regarnîtes',
    'régataient',
    'régatasses',
    'régaterais',
    'régaterait',
    'régatèrent',
    'régateriez',
    'régaterons',
    'régateront',
    'régatières',
    'regazonnai',
    'regazonnas',
    'regazonnât',
    'regazonnée',
    'regazonner',
    'regazonnes',
    'regazonnés',
    'regazonnez',
    'régénérais',
    'régénérait',
    'régénérant',
    'régénérées',
    'régénèrent',
    'régénérera',
    'régénériez',
    'régénérons',
    'régentâmes',
    'régentasse',
    'régentâtes',
    'régenterai',
    'régenteras',
    'régenterez',
    'régentions',
    'regimbâmes',
    'regimbasse',
    'regimbâtes',
    'regimberai',
    'regimberas',
    'regimberez',
    'regimbeurs',
    'regimbeuse',
    'regimbions',
    'reginglard',
    'régionales',
    'régiraient',
    'régisseurs',
    'régisseuse',
    'régissions',
    'registrais',
    'registrait',
    'registrant',
    'registrées',
    'registrent',
    'registrera',
    'registriez',
    'registrons',
    'réglassent',
    'réglassiez',
    'réglementa',
    'réglemente',
    'réglementé',
    'règlements',
    'réglerions',
    'régnassent',
    'régnassiez',
    'régnerions',
    'regonflais',
    'regonflait',
    'regonflant',
    'regonflées',
    'regonflent',
    'regonflera',
    'regonfliez',
    'regonflons',
    'regorgeais',
    'regorgeait',
    'regorgeant',
    'regorgeons',
    'regorgerai',
    'regorgeras',
    'regorgerez',
    'regorgions',
    'regrattais',
    'regrattait',
    'regrattant',
    'regrattées',
    'regrattent',
    'regrattera',
    'regrattier',
    'regrattiez',
    'regrattons',
    'regréaient',
    'regréasses',
    'regréerais',
    'regréerait',
    'regréèrent',
    'regréeriez',
    'regréerons',
    'regréeront',
    'regreffais',
    'regreffait',
    'regreffant',
    'regreffées',
    'regreffent',
    'regreffera',
    'regreffiez',
    'regreffons',
    'régressais',
    'régressait',
    'régressant',
    'régressent',
    'régressera',
    'régressiez',
    'régressifs',
    'régression',
    'régressive',
    'régressons',
    'regrettais',
    'regrettait',
    'regrettant',
    'regrettées',
    'regrettent',
    'regrettera',
    'regrettiez',
    'regrettons',
    'regrimpais',
    'regrimpait',
    'regrimpant',
    'regrimpées',
    'regrimpent',
    'regrimpera',
    'regrimpiez',
    'regrimpons',
    'regrossira',
    'regroupais',
    'regroupait',
    'regroupant',
    'regroupées',
    'regroupent',
    'regroupera',
    'regroupiez',
    'regroupons',
    'régularisa',
    'régularise',
    'régularisé',
    'régularité',
    'régulateur',
    'régulation',
    'régulières',
    'régurgitai',
    'régurgitas',
    'régurgitât',
    'régurgitée',
    'régurgiter',
    'régurgites',
    'régurgités',
    'régurgitez',
    'réhabilita',
    'réhabilite',
    'réhabilité',
    'réhabituai',
    'réhabituas',
    'réhabituât',
    'réhabituée',
    'réhabituer',
    'réhabitues',
    'réhabitués',
    'réhabituez',
    'rehaussais',
    'rehaussait',
    'rehaussant',
    'rehaussées',
    'rehaussent',
    'rehaussera',
    'rehaussiez',
    'rehaussons',
    'réhydratai',
    'réhydratas',
    'réhydratât',
    'réhydratée',
    'réhydrater',
    'réhydrates',
    'réhydratés',
    'réhydratez',
    'reichsmark',
    'reichstags',
    'réifiaient',
    'réifiasses',
    'réifierais',
    'réifierait',
    'réifièrent',
    'réifieriez',
    'réifierons',
    'réifieront',
    'réimplanta',
    'réimplante',
    'réimplanté',
    'réimportai',
    'réimportas',
    'réimportât',
    'réimportée',
    'réimporter',
    'réimportes',
    'réimportés',
    'réimportez',
    'réimposais',
    'réimposait',
    'réimposant',
    'réimposées',
    'réimposent',
    'réimposera',
    'réimposiez',
    'réimposons',
    'réimprimai',
    'réimprimas',
    'réimprimât',
    'réimprimée',
    'réimprimer',
    'réimprimes',
    'réimprimés',
    'réimprimez',
    'réincarnai',
    'réincarnas',
    'réincarnât',
    'réincarnée',
    'réincarner',
    'réincarnes',
    'réincarnés',
    'réincarnez',
    'réinfectai',
    'réinfectas',
    'réinfectât',
    'réinfectée',
    'réinfecter',
    'réinfectes',
    'réinfectés',
    'réinfectez',
    'réinjectai',
    'réinjectas',
    'réinjectât',
    'réinjectée',
    'réinjecter',
    'réinjectes',
    'réinjectés',
    'réinjectez',
    'réinscrira',
    'réinscrire',
    'réinscrite',
    'réinscrits',
    'réinscrive',
    'réinsérais',
    'réinsérait',
    'réinsérant',
    'réinsérées',
    'réinsèrent',
    'réinsérera',
    'réinsériez',
    'réinsérons',
    'réinstalla',
    'réinstalle',
    'réinstallé',
    'réintégrai',
    'réintégras',
    'réintégrât',
    'réintégrée',
    'réintégrer',
    'réintégrés',
    'réintègres',
    'réintégrez',
    'réinventai',
    'réinventas',
    'réinventât',
    'réinventée',
    'réinventer',
    'réinventes',
    'réinventés',
    'réinventez',
    'réinvestie',
    'réinvestir',
    'réinvestis',
    'réinvestit',
    'réinvestît',
    'réinvitais',
    'réinvitait',
    'réinvitant',
    'réinvitées',
    'réinvitent',
    'réinvitera',
    'réinvitiez',
    'réinvitons',
    'réitérâmes',
    'réitérasse',
    'réitérâtes',
    'réitérerai',
    'réitéreras',
    'réitérerez',
    'réitérions',
    'rejaillira',
    'rejetables',
    'rejetaient',
    'rejetasses',
    'rejetèrent',
    'rejetterai',
    'rejetteras',
    'rejetterez',
    'rejoignais',
    'rejoignait',
    'rejoignant',
    'rejoignent',
    'rejoigniez',
    'rejoignons',
    'rejoindrai',
    'rejoindras',
    'rejoindrez',
    'rejointoie',
    'rejointoya',
    'rejointoyé',
    'rejouaient',
    'rejouasses',
    'rejouerais',
    'rejouerait',
    'rejouèrent',
    'rejoueriez',
    'rejouerons',
    'rejoueront',
    'réjouirais',
    'réjouirait',
    'réjouirent',
    'réjouiriez',
    'réjouirons',
    'réjouiront',
    'réjouisses',
    'réjouissez',
    'rejugeâmes',
    'rejugeasse',
    'rejugeâtes',
    'rejugerais',
    'rejugerait',
    'rejugèrent',
    'rejugeriez',
    'rejugerons',
    'rejugeront',
    'relâchâmes',
    'relâchasse',
    'relâchâtes',
    'relâcherai',
    'relâcheras',
    'relâcherez',
    'relâchions',
    'relaierais',
    'relaierait',
    'relaieriez',
    'relaierons',
    'relaieront',
    'relaissais',
    'relaissait',
    'relaissant',
    'relaissées',
    'relaissent',
    'relaissera',
    'relaissiez',
    'relaissons',
    'relançâmes',
    'relançasse',
    'relançâtes',
    'relancerai',
    'relanceras',
    'relancerez',
    'relancions',
    'rélargîmes',
    'rélargirai',
    'rélargiras',
    'rélargirez',
    'rélargisse',
    'rélargîtes',
    'relataient',
    'relatasses',
    'relaterais',
    'relaterait',
    'relatèrent',
    'relateriez',
    'relaterons',
    'relateront',
    'relativisa',
    'relativise',
    'relativisé',
    'relativité',
    'relavaient',
    'relavasses',
    'relaverais',
    'relaverait',
    'relavèrent',
    'relaveriez',
    'relaverons',
    'relaveront',
    'relaxaient',
    'relaxasses',
    'relaxation',
    'relaxerais',
    'relaxerait',
    'relaxèrent',
    'relaxeriez',
    'relaxerons',
    'relaxeront',
    'relayaient',
    'relayasses',
    'relayerais',
    'relayerait',
    'relayèrent',
    'relayeriez',
    'relayerons',
    'relayeront',
    'relayeuses',
    'relectures',
    'relégation',
    'reléguâmes',
    'reléguasse',
    'reléguâtes',
    'reléguerai',
    'relégueras',
    'reléguerez',
    'reléguions',
    'relevables',
    'relevaient',
    'relevasses',
    'relèvement',
    'relèverais',
    'relèverait',
    'relevèrent',
    'relèveriez',
    'relèverons',
    'relèveront',
    'releveuses',
    'reliassent',
    'reliassiez',
    'relierions',
    'religieuse',
    'reliquaire',
    'reliraient',
    'relisaient',
    'relogeâmes',
    'relogeasse',
    'relogeâtes',
    'relogement',
    'relogerais',
    'relogerait',
    'relogèrent',
    'relogeriez',
    'relogerons',
    'relogeront',
    'relouaient',
    'relouasses',
    'relouerais',
    'relouerait',
    'relouèrent',
    'reloueriez',
    'relouerons',
    'reloueront',
    'reluirions',
    'reluisante',
    'reluisants',
    'reluisîmes',
    'reluisions',
    'reluisisse',
    'reluisîtes',
    'reluquâmes',
    'reluquasse',
    'reluquâtes',
    'reluquerai',
    'reluqueras',
    'reluquerez',
    'reluquions',
    'relussions',
    'remâchâmes',
    'remâchasse',
    'remâchâtes',
    'remâcherai',
    'remâcheras',
    'remâcherez',
    'remâchions',
    'remaillage',
    'remaillais',
    'remaillait',
    'remaillant',
    'remaillées',
    'remaillent',
    'remaillera',
    'remailliez',
    'remaillons',
    'rémanences',
    'rémanentES',
    'remangeais',
    'remangeait',
    'remangeant',
    'remangeons',
    'remangerai',
    'remangeras',
    'remangerez',
    'remangions',
    'remaniâmes',
    'remaniasse',
    'remaniâtes',
    'remanierai',
    'remanieras',
    'remanierez',
    'remaniions',
    'remaquilla',
    'remaquille',
    'remaquillé',
    'remarchais',
    'remarchait',
    'remarchant',
    'remarchent',
    'remarchera',
    'remarchiez',
    'remarchons',
    'remariages',
    'remariâmes',
    'remariasse',
    'remariâtes',
    'remarierai',
    'remarieras',
    'remarierez',
    'remariions',
    'remarquais',
    'remarquait',
    'remarquant',
    'remarquées',
    'remarquent',
    'remarquera',
    'remarquiez',
    'remarquons',
    'remastiqua',
    'remastique',
    'remastiqué',
    'remballage',
    'remballais',
    'remballait',
    'remballant',
    'remballées',
    'remballent',
    'remballera',
    'remballiez',
    'remballons',
    'rembarquai',
    'rembarquas',
    'rembarquât',
    'rembarquée',
    'rembarquer',
    'rembarques',
    'rembarqués',
    'rembarquez',
    'rembarrais',
    'rembarrait',
    'rembarrant',
    'rembarrées',
    'rembarrent',
    'rembarrera',
    'rembarriez',
    'rembarrons',
    'rembinâmes',
    'rembinasse',
    'rembinâtes',
    'rembinerai',
    'rembineras',
    'rembinerez',
    'rembinions',
    'remblaient',
    'remblaiera',
    'remblavais',
    'remblavait',
    'remblavant',
    'remblavées',
    'remblavent',
    'remblavera',
    'remblaviez',
    'remblavons',
    'remblayage',
    'remblayais',
    'remblayait',
    'remblayant',
    'remblayées',
    'remblayent',
    'remblayera',
    'remblayiez',
    'remblayons',
    'remboîtage',
    'remboîtais',
    'remboîtait',
    'remboîtant',
    'remboîtées',
    'remboîtent',
    'remboîtera',
    'remboîtiez',
    'remboîtons',
    'rembougeai',
    'rembougeas',
    'rembougeât',
    'rembougées',
    'rembougent',
    'rembougera',
    'rembougiez',
    'rembourrai',
    'rembourras',
    'rembourrât',
    'rembourrée',
    'rembourrer',
    'rembourres',
    'rembourrés',
    'rembourrez',
    'remboursai',
    'remboursas',
    'remboursât',
    'remboursée',
    'rembourser',
    'rembourses',
    'remboursés',
    'remboursez',
    'rembrunies',
    'rembrunira',
    'rembuchais',
    'rembuchait',
    'rembuchant',
    'rembuchées',
    'rembuchent',
    'rembuchera',
    'rembuchiez',
    'rembuchons',
    'remédiable',
    'remédiâmes',
    'remédiasse',
    'remédiâtes',
    'remédierai',
    'remédieras',
    'remédierez',
    'remédiions',
    'remembrais',
    'remembrait',
    'remembrant',
    'remembrées',
    'remembrent',
    'remembrera',
    'remembriez',
    'remembrons',
    'remémorais',
    'remémorait',
    'remémorant',
    'remémorées',
    'remémorent',
    'remémorera',
    'remémoriez',
    'remémorons',
    'remerciais',
    'remerciait',
    'remerciant',
    'remerciées',
    'remercient',
    'remerciera',
    'remerciiez',
    'remercions',
    'remettants',
    'remettions',
    'remettrais',
    'remettrait',
    'remettriez',
    'remettrons',
    'remettront',
    'remeublais',
    'remeublait',
    'remeublant',
    'remeublées',
    'remeublent',
    'remeublera',
    'remeubliez',
    'remeublons',
    'remisaient',
    'remisasses',
    'remiserais',
    'remiserait',
    'remisèrent',
    'remiseriez',
    'remiserons',
    'remiseront',
    'rémissible',
    'remissions',
    'rémissions',
    'rémittence',
    'rémittentE',
    'rémittents',
    'remmaillai',
    'remmaillas',
    'remmaillât',
    'remmaillée',
    'remmailler',
    'remmailles',
    'remmaillés',
    'remmaillez',
    'remmanchai',
    'remmanchas',
    'remmanchât',
    'remmanchée',
    'remmancher',
    'remmanches',
    'remmanchés',
    'remmanchez',
    'remmenâmes',
    'remmenasse',
    'remmenâtes',
    'remmènerai',
    'remmèneras',
    'remmènerez',
    'remmenions',
    'remodelage',
    'remodelais',
    'remodelait',
    'remodelant',
    'remodelées',
    'remodèlent',
    'remodèlera',
    'remodeliez',
    'remodelons',
    'remontages',
    'remontâmes',
    'remontante',
    'remontants',
    'remontasse',
    'remontâtes',
    'remonterai',
    'remonteras',
    'remonterez',
    'remonteurs',
    'remonteuse',
    'remontions',
    'remontoirs',
    'remontrais',
    'remontrait',
    'remontrant',
    'remontrées',
    'remontrent',
    'remontrera',
    'remontriez',
    'remontrons',
    'remordîmes',
    'remordions',
    'remordisse',
    'remordîtes',
    'remordrais',
    'remordrait',
    'remordriez',
    'remordrons',
    'remordront',
    'remorquage',
    'remorquais',
    'remorquait',
    'remorquant',
    'remorquées',
    'remorquent',
    'remorquera',
    'remorqueur',
    'remorquiez',
    'remorquons',
    'remouchais',
    'remouchait',
    'remouchant',
    'remouchées',
    'remouchent',
    'remouchera',
    'remouchiez',
    'remouchons',
    'remoudrais',
    'remoudrait',
    'remoudriez',
    'remoudrons',
    'remoudront',
    'remouillai',
    'remouillas',
    'remouillât',
    'remouillée',
    'remouiller',
    'remouilles',
    'remouillés',
    'remouillez',
    'rémoulades',
    'rémouleurs',
    'remoulions',
    'remoulûmes',
    'remoulusse',
    'remoulûtes',
    'rempaillai',
    'rempaillas',
    'rempaillât',
    'rempaillée',
    'rempailler',
    'rempailles',
    'rempaillés',
    'rempaillez',
    'rempaqueta',
    'rempaqueté',
    'remparâmes',
    'remparasse',
    'remparâtes',
    'remparerai',
    'rempareras',
    'remparerez',
    'remparions',
    'rempiétais',
    'rempiétait',
    'rempiétant',
    'rempiétées',
    'rempiètent',
    'rempiétera',
    'rempiétiez',
    'rempiétons',
    'rempilâmes',
    'rempilasse',
    'rempilâtes',
    'rempilerai',
    'rempileras',
    'rempilerez',
    'rempilions',
    'remplaçais',
    'remplaçait',
    'remplaçant',
    'remplacées',
    'remplacent',
    'remplacera',
    'remplaciez',
    'remplaçons',
    'rempliâmes',
    'rempliasse',
    'rempliâtes',
    'remplierai',
    'remplieras',
    'remplierez',
    'rempliions',
    'remplirais',
    'remplirait',
    'remplirent',
    'rempliriez',
    'remplirons',
    'rempliront',
    'remplisses',
    'remplissez',
    'remploient',
    'remploiera',
    'remployais',
    'remployait',
    'remployant',
    'remployées',
    'remployiez',
    'remployons',
    'remplumais',
    'remplumait',
    'remplumant',
    'remplumées',
    'remplument',
    'remplumera',
    'remplumiez',
    'remplumons',
    'rempochais',
    'rempochait',
    'rempochant',
    'rempochées',
    'rempochent',
    'rempochera',
    'rempochiez',
    'rempochons',
    'remportais',
    'remportait',
    'remportant',
    'remportées',
    'remportent',
    'remportera',
    'remportiez',
    'remportons',
    'rempotâmes',
    'rempotasse',
    'rempotâtes',
    'rempoterai',
    'rempoteras',
    'rempoterez',
    'rempotions',
    'rempruntai',
    'rempruntas',
    'rempruntât',
    'rempruntée',
    'remprunter',
    'rempruntes',
    'rempruntés',
    'rempruntez',
    'remuassent',
    'remuassiez',
    'remuements',
    'remuerions',
    'rémunérais',
    'rémunérait',
    'rémunérant',
    'rémunérées',
    'rémunèrent',
    'rémunérera',
    'rémunériez',
    'rémunérons',
    'renaclâmes',
    'renaclasse',
    'renaclâtes',
    'renâclerai',
    'renâcleras',
    'renâclerez',
    'renâclions',
    'renaissais',
    'renaissait',
    'renaissant',
    'renaissent',
    'renaissiez',
    'renaissons',
    'renaîtrais',
    'renaîtrait',
    'renaîtriez',
    'renaîtrons',
    'renaîtront',
    'renaquîmes',
    'renaquisse',
    'renaquîtes',
    'renardâmes',
    'renardasse',
    'renardâtes',
    'renardeaux',
    'renarderai',
    'renarderas',
    'renarderez',
    'renardière',
    'renardions',
    'renaudâmes',
    'renaudasse',
    'renaudâtes',
    'renauderai',
    'renauderas',
    'renauderez',
    'renaudions',
    'rencaissai',
    'rencaissas',
    'rencaissât',
    'rencaissée',
    'rencaisser',
    'rencaisses',
    'rencaissés',
    'rencaissez',
    'rencardées',
    'rencardent',
    'rencardera',
    'renchaînai',
    'renchaînas',
    'renchaînât',
    'renchaînée',
    'renchaîner',
    'renchaînes',
    'renchaînés',
    'renchaînez',
    'renchérira',
    'rencognais',
    'rencognait',
    'rencognant',
    'rencognées',
    'rencognent',
    'rencognera',
    'rencogniez',
    'rencognons',
    'rencontrai',
    'rencontras',
    'rencontrât',
    'rencontrée',
    'rencontrer',
    'rencontres',
    'rencontrés',
    'rencontrez',
    'rendements',
    'rendissent',
    'rendissiez',
    'rendormais',
    'rendormait',
    'rendormant',
    'rendorment',
    'rendormies',
    'rendormiez',
    'rendormira',
    'rendormons',
    'rendossais',
    'rendossait',
    'rendossant',
    'rendossées',
    'rendossent',
    'rendossera',
    'rendossiez',
    'rendossons',
    'rendraient',
    'renégociai',
    'renégocias',
    'renégociât',
    'renégociée',
    'renégocier',
    'renégocies',
    'renégociés',
    'renégociez',
    'reneigeait',
    'renfaîtais',
    'renfaîtait',
    'renfaîtant',
    'renfaîtées',
    'renfaîtent',
    'renfaîtera',
    'renfaîtiez',
    'renfaîtons',
    'renfermais',
    'renfermait',
    'renfermant',
    'renfermées',
    'renferment',
    'renfermera',
    'renfermiez',
    'renfermons',
    'renfilâmes',
    'renfilasse',
    'renfilâtes',
    'renfilerai',
    'renfileras',
    'renfilerez',
    'renfilions',
    'renflaient',
    'renflammai',
    'renflammas',
    'renflammât',
    'renflammée',
    'renflammer',
    'renflammes',
    'renflammés',
    'renflammez',
    'renflasses',
    'renflement',
    'renflerais',
    'renflerait',
    'renflèrent',
    'renfleriez',
    'renflerons',
    'renfleront',
    'renflouage',
    'renflouais',
    'renflouait',
    'renflouant',
    'renflouées',
    'renflouent',
    'renflouera',
    'renflouiez',
    'renflouons',
    'renfonçais',
    'renfonçait',
    'renfonçant',
    'renfoncées',
    'renfoncent',
    'renfoncera',
    'renfonciez',
    'renfonçons',
    'renforçais',
    'renforçait',
    'renforçant',
    'renforcées',
    'renforcent',
    'renforcera',
    'renforciez',
    'renforçons',
    'renformies',
    'renformira',
    'renfrognai',
    'renfrognas',
    'renfrognât',
    'renfrognée',
    'renfrogner',
    'renfrognes',
    'renfrognés',
    'renfrognez',
    'rengageais',
    'rengageait',
    'rengageant',
    'rengageons',
    'rengagerai',
    'rengageras',
    'rengagerez',
    'rengagions',
    'rengainais',
    'rengainait',
    'rengainant',
    'rengainées',
    'rengainent',
    'rengainera',
    'rengainiez',
    'rengainons',
    'rengorgeai',
    'rengorgeas',
    'rengorgeât',
    'rengorgées',
    'rengorgent',
    'rengorgera',
    'rengorgiez',
    'rengraciai',
    'rengracias',
    'rengraciât',
    'rengracier',
    'rengracies',
    'rengraciez',
    'rengraissa',
    'rengraisse',
    'rengraissé',
    'rengrenais',
    'rengrénais',
    'rengrenait',
    'rengrénait',
    'rengrenant',
    'rengrénant',
    'rengrenées',
    'rengrénées',
    'rengrènent',
    'rengrénera',
    'rengrènera',
    'rengreniez',
    'rengréniez',
    'rengrenons',
    'rengrénons',
    'reniassent',
    'reniassiez',
    'reniements',
    'renierions',
    'reniflâmes',
    'reniflards',
    'reniflasse',
    'reniflâtes',
    'reniflerai',
    'renifleras',
    'reniflerez',
    'renifleurs',
    'renifleuse',
    'reniflions',
    'réniformes',
    'rénitences',
    'renommâmes',
    'renommasse',
    'renommâtes',
    'renommerai',
    'renommeras',
    'renommerez',
    'renommions',
    'renonçâmes',
    'renonçasse',
    'renonçâtes',
    'renoncerai',
    'renonceras',
    'renoncerez',
    'renoncions',
    'renoncules',
    'renouaient',
    'renouasses',
    'renouement',
    'renouerais',
    'renouerait',
    'renouèrent',
    'renoueriez',
    'renouerons',
    'renoueront',
    'renouveaux',
    'renouvelai',
    'renouvelas',
    'renouvelât',
    'renouvelée',
    'renouveler',
    'renouvelés',
    'renouvelez',
    'renouvelle',
    'rénovaient',
    'rénovasses',
    'rénovateur',
    'rénovation',
    'rénoverais',
    'rénoverait',
    'rénovèrent',
    'rénoveriez',
    'rénoverons',
    'rénoveront',
    'renquillai',
    'renquillas',
    'renquillât',
    'renquillée',
    'renquiller',
    'renquilles',
    'renquillés',
    'renquillez',
    'renseignai',
    'renseignas',
    'renseignât',
    'renseignée',
    'renseigner',
    'renseignes',
    'renseignés',
    'renseignez',
    'rentamâmes',
    'rentamasse',
    'rentamâtes',
    'rentamerai',
    'rentameras',
    'rentamerez',
    'rentamions',
    'rentassent',
    'rentassiez',
    'renterions',
    'rentoilais',
    'rentoilait',
    'rentoilant',
    'rentoilées',
    'rentoilent',
    'rentoilera',
    'rentoileur',
    'rentoiliez',
    'rentoilons',
    'rentraient',
    'rentrantes',
    'rentrasses',
    'rentrerais',
    'rentrerait',
    'rentrèrent',
    'rentreriez',
    'rentrerons',
    'rentreront',
    'rentrouvre',
    'rentrouvru',
    'renveloppa',
    'renveloppe',
    'renveloppé',
    'renvenimai',
    'renvenimas',
    'renvenimât',
    'renvenimée',
    'renvenimer',
    'renvenimes',
    'renvenimés',
    'renvenimez',
    'renvergeai',
    'renvergeas',
    'renvergeât',
    'renvergées',
    'renvergent',
    'renvergera',
    'renvergiez',
    'renverrais',
    'renverrait',
    'renverriez',
    'renverrons',
    'renverront',
    'renversais',
    'renversait',
    'renversant',
    'renversées',
    'renversent',
    'renversera',
    'renversiez',
    'renversons',
    'renviaient',
    'renviasses',
    'renvidages',
    'renvidâmes',
    'renvidasse',
    'renvidâtes',
    'renviderai',
    'renvideras',
    'renviderez',
    'renvideurs',
    'renvidions',
    'renvierais',
    'renvierait',
    'renvièrent',
    'renvieriez',
    'renvierons',
    'renvieront',
    'renvoyâmes',
    'renvoyasse',
    'renvoyâtes',
    'renvoyions',
    'réoccupais',
    'réoccupait',
    'réoccupant',
    'réoccupées',
    'réoccupent',
    'réoccupera',
    'réoccupiez',
    'réoccupons',
    'réopérâmes',
    'réopérasse',
    'réopérâtes',
    'réopérerai',
    'réopéreras',
    'réopérerez',
    'réopérions',
    'réordonnai',
    'réordonnas',
    'réordonnât',
    'réordonnée',
    'réordonner',
    'réordonnes',
    'réordonnés',
    'réordonnez',
    'réorganisa',
    'réorganise',
    'réorganisé',
    'réorientai',
    'réorientas',
    'réorientât',
    'réorientée',
    'réorienter',
    'réorientes',
    'réorientés',
    'réorientez',
    'repaierais',
    'repaierait',
    'repaieriez',
    'repaierons',
    'repaieront',
    'repairâmes',
    'repairasse',
    'repairâtes',
    'repairerai',
    'repaireras',
    'repairerez',
    'repairions',
    'repaissais',
    'repaissait',
    'repaissant',
    'repaissent',
    'repaissiez',
    'repaissons',
    'repaîtrais',
    'repaîtrait',
    'repaîtriez',
    'repaîtrons',
    'repaîtront',
    'répandîmes',
    'répandions',
    'répandisse',
    'répandîtes',
    'répandrais',
    'répandrait',
    'répandriez',
    'répandrons',
    'répandront',
    'réparables',
    'réparaient',
    'reparaisse',
    'reparaîtra',
    'reparaître',
    'réparasses',
    'réparateur',
    'réparation',
    'réparerais',
    'réparerait',
    'réparèrent',
    'répareriez',
    'réparerons',
    'répareront',
    'reparlâmes',
    'reparlasse',
    'reparlâtes',
    'reparlerai',
    'reparleras',
    'reparlerez',
    'reparlions',
    'repartagea',
    'repartagée',
    'repartager',
    'repartages',
    'repartagés',
    'repartagez',
    'repartîmes',
    'répartîmes',
    'repartions',
    'repartirai',
    'répartirai',
    'repartiras',
    'répartiras',
    'repartirez',
    'répartirez',
    'repartisse',
    'répartisse',
    'repartîtes',
    'répartîtes',
    'reparurent',
    'reparusses',
    'repassages',
    'repassâmes',
    'repassasse',
    'repassâtes',
    'repasserai',
    'repasseras',
    'repasserez',
    'repasseurs',
    'repasseuse',
    'repassions',
    'repatinais',
    'repatinait',
    'repatinant',
    'repatinées',
    'repatinent',
    'repatinera',
    'repatiniez',
    'repatinons',
    'repavaient',
    'repavasses',
    'repaverais',
    'repaverait',
    'repavèrent',
    'repaveriez',
    'repaverons',
    'repaveront',
    'repayaient',
    'repayasses',
    'repayerais',
    'repayerait',
    'repayèrent',
    'repayeriez',
    'repayerons',
    'repayeront',
    'repêchages',
    'repêchâmes',
    'repêchasse',
    'repêchâtes',
    'repêcherai',
    'repêcheras',
    'repêcherez',
    'repêchions',
    'repeignais',
    'repeignait',
    'repeignant',
    'repeignées',
    'repeignent',
    'repeignera',
    'repeigniez',
    'repeignons',
    'repeindrai',
    'repeindras',
    'repeindrez',
    'rependîmes',
    'rependions',
    'rependisse',
    'rependîtes',
    'rependrais',
    'rependrait',
    'rependriez',
    'rependrons',
    'rependront',
    'repensâmes',
    'repensasse',
    'repensâtes',
    'repenserai',
    'repenseras',
    'repenserez',
    'repensions',
    'repentance',
    'repentante',
    'repentants',
    'repentîmes',
    'repentions',
    'repentirai',
    'repentiras',
    'repentirez',
    'repentisse',
    'repentîtes',
    'repérables',
    'repéraient',
    'repérasses',
    'reperçâmes',
    'reperçasse',
    'reperçâtes',
    'repercerai',
    'reperceras',
    'repercerez',
    'repercions',
    'répercutai',
    'répercutas',
    'répercutât',
    'répercutée',
    'répercuter',
    'répercutes',
    'répercutés',
    'répercutez',
    'reperdîmes',
    'reperdions',
    'reperdisse',
    'reperdîtes',
    'reperdrais',
    'reperdrait',
    'reperdriez',
    'reperdrons',
    'reperdront',
    'repérerais',
    'repérerait',
    'repérèrent',
    'repéreriez',
    'repérerons',
    'repéreront',
    'répertoire',
    'répertoria',
    'répertorie',
    'répertorié',
    'répétables',
    'répétaient',
    'répétasses',
    'répéterais',
    'répéterait',
    'répétèrent',
    'répéteriez',
    'répéterons',
    'répéteront',
    'répétiteur',
    'répétitifs',
    'répétition',
    'répétitive',
    'repeuplais',
    'repeuplait',
    'repeuplant',
    'repeuplées',
    'repeuplent',
    'repeuplera',
    'repeupliez',
    'repeuplons',
    'repinçâmes',
    'repinçasse',
    'repinçâtes',
    'repincerai',
    'repinceras',
    'repincerez',
    'repincions',
    'repiquages',
    'repiquâmes',
    'repiquasse',
    'repiquâtes',
    'repiquerai',
    'repiqueras',
    'repiquerez',
    'repiquions',
    'replaçâmes',
    'replaçasse',
    'replaçâtes',
    'replacerai',
    'replaceras',
    'replacerez',
    'replacions',
    'replantais',
    'replantait',
    'replantant',
    'replantées',
    'replantent',
    'replantera',
    'replantiez',
    'replantons',
    'replâtrage',
    'replâtrais',
    'replâtrait',
    'replâtrant',
    'replâtrées',
    'replâtrent',
    'replâtrera',
    'replâtriez',
    'replâtrons',
    'réplétions',
    'réplétives',
    'repleuvait',
    'repleuvent',
    'repleuvoir',
    'repliables',
    'repliaient',
    'repliasses',
    'repliement',
    'replierais',
    'replierait',
    'replièrent',
    'replieriez',
    'replierons',
    'replieront',
    'répliquais',
    'répliquait',
    'répliquant',
    'répliquées',
    'répliquent',
    'répliquera',
    'répliquiez',
    'répliquons',
    'replissais',
    'replissait',
    'replissant',
    'replissées',
    'replissent',
    'replissera',
    'replissiez',
    'replissons',
    'reploierai',
    'reploieras',
    'reploierez',
    'replongeai',
    'replongeas',
    'replongeât',
    'replongées',
    'replongent',
    'replongera',
    'replongiez',
    'reployâmes',
    'reployasse',
    'reployâtes',
    'reployions',
    'replussent',
    'repolirais',
    'repolirait',
    'repolirent',
    'repoliriez',
    'repolirons',
    'repoliront',
    'repolisses',
    'repolissez',
    'répondante',
    'répondants',
    'répondeurs',
    'répondeuse',
    'répondîmes',
    'répondions',
    'répondisse',
    'répondîtes',
    'répondrais',
    'répondrait',
    'répondriez',
    'répondrons',
    'répondront',
    'reportages',
    'reportâmes',
    'reportasse',
    'reportâtes',
    'reporterai',
    'reporteras',
    'reporterez',
    'reporteurs',
    'reportions',
    'reportrice',
    'reposaient',
    'reposantes',
    'reposasses',
    'reposerais',
    'reposerait',
    'reposèrent',
    'reposeriez',
    'reposerons',
    'reposeront',
    'repoussage',
    'repoussais',
    'repoussait',
    'repoussant',
    'repoussées',
    'repoussent',
    'repoussera',
    'repoussiez',
    'repoussoir',
    'repoussons',
    'reprendrai',
    'reprendras',
    'reprendrez',
    'reprenions',
    'reprennent',
    'représenta',
    'représente',
    'représenté',
    'répressifs',
    'répression',
    'répressive',
    'réprimâmes',
    'réprimanda',
    'réprimande',
    'réprimandé',
    'réprimasse',
    'réprimâtes',
    'réprimerai',
    'réprimeras',
    'réprimerez',
    'réprimions',
    'reprisages',
    'reprisâmes',
    'reprisasse',
    'reprisâtes',
    'repriserai',
    'repriseras',
    'repriserez',
    'repriseuse',
    'reprisions',
    'reprissent',
    'reprissiez',
    'reprochais',
    'reprochait',
    'reprochant',
    'reprochées',
    'reprochent',
    'reprochera',
    'reprochiez',
    'reprochons',
    'reproduira',
    'reproduire',
    'reproduise',
    'reproduite',
    'reproduits',
    'reprouvais',
    'réprouvais',
    'reprouvait',
    'réprouvait',
    'reprouvant',
    'réprouvant',
    'reprouvées',
    'réprouvées',
    'reprouvent',
    'réprouvent',
    'reprouvera',
    'réprouvera',
    'reprouviez',
    'réprouviez',
    'reprouvons',
    'réprouvons',
    'reptations',
    'république',
    'répudiâmes',
    'répudiasse',
    'répudiâtes',
    'répudierai',
    'répudieras',
    'répudierez',
    'répudiions',
    'répugnâmes',
    'répugnance',
    'répugnante',
    'répugnants',
    'répugnasse',
    'répugnâtes',
    'répugnerai',
    'répugneras',
    'répugnerez',
    'répugnions',
    'répulsions',
    'repussions',
    'réputaient',
    'réputasses',
    'réputation',
    'réputerais',
    'réputerait',
    'réputèrent',
    'réputeriez',
    'réputerons',
    'réputeront',
    'requérions',
    'requerrais',
    'requerrait',
    'requerriez',
    'requerrons',
    'requerront',
    'requièrent',
    'requinquai',
    'requinquas',
    'requinquât',
    'requinquée',
    'requinquer',
    'requinques',
    'requinqués',
    'requinquez',
    'requissent',
    'requissiez',
    'reroisâmes',
    'reroisasse',
    'reroisâtes',
    'resalaient',
    'resalasses',
    'resalerais',
    'resalerait',
    'resalèrent',
    'resaleriez',
    'resalerons',
    'resaleront',
    'resalirais',
    'resalirait',
    'resaliriez',
    'resalirons',
    'resaliront',
    'resalissez',
    'resaluâmes',
    'resaluasse',
    'resaluâtes',
    'resaluerai',
    'resalueras',
    'resaluerez',
    'resaluions',
    'resarcelée',
    'rescindais',
    'rescindait',
    'rescindant',
    'rescindent',
    'rescindera',
    'rescindiez',
    'rescindons',
    'rescisions',
    'rescisoire',
    'rescousses',
    'résections',
    'reséquâmes',
    'reséquasse',
    'reséquâtes',
    'reséquerai',
    'reséqueras',
    'reséquerez',
    'reséquions',
    'réserpines',
    'réservâmes',
    'réservasse',
    'réservâtes',
    'réserverai',
    'réserveras',
    'réserverez',
    'réservions',
    'réserviste',
    'réservoirs',
    'résidaient',
    'résidantes',
    'résidasses',
    'résidences',
    'résidentes',
    'résiderais',
    'résiderait',
    'résidèrent',
    'résideriez',
    'résiderons',
    'résideront',
    'résiduaire',
    'résiduelle',
    'résignâmes',
    'résignasse',
    'résignâtes',
    'résignerai',
    'résigneras',
    'résignerez',
    'résignions',
    'résiliable',
    'résiliâmes',
    'résiliasse',
    'résiliâtes',
    'résilience',
    'résilierai',
    'résilieras',
    'résilierez',
    'résiliions',
    'résinaient',
    'résinasses',
    'résinerais',
    'résinerait',
    'résinèrent',
    'résineriez',
    'résinerons',
    'résineront',
    'résineuses',
    'résinières',
    'résinifère',
    'résinifiai',
    'résinifias',
    'résinifiât',
    'résinifiée',
    'résinifier',
    'résinifies',
    'résinifiés',
    'résinifiez',
    'résistâmes',
    'résistance',
    'résistante',
    'résistants',
    'résistasse',
    'résistâtes',
    'résisterai',
    'résisteras',
    'résisterez',
    'résistible',
    'résistions',
    'résolument',
    'résolurent',
    'résolusses',
    'résolutifs',
    'résolution',
    'résolutive',
    'résolvante',
    'résolvants',
    'résolvions',
    'résonances',
    'résonantes',
    'résonnâmes',
    'résonnante',
    'résonnants',
    'résonnasse',
    'résonnâtes',
    'résonnerai',
    'résonneras',
    'résonnerez',
    'résonnions',
    'résorbâmes',
    'résorbasse',
    'résorbâtes',
    'résorberai',
    'résorberas',
    'résorberez',
    'résorbions',
    'résorcines',
    'résorption',
    'résoudrais',
    'résoudrait',
    'résoudriez',
    'résoudrons',
    'résoudront',
    'respectais',
    'respectait',
    'respectant',
    'respectées',
    'respectent',
    'respectera',
    'respectiez',
    'respectifs',
    'respective',
    'respectons',
    'respirable',
    'respirâmes',
    'respirasse',
    'respirâtes',
    'respirerai',
    'respireras',
    'respirerez',
    'respirions',
    'resplendir',
    'resplendis',
    'resplendit',
    'resplendît',
    'resquillai',
    'resquillas',
    'resquillât',
    'resquillée',
    'resquiller',
    'resquilles',
    'resquillés',
    'resquillez',
    'ressaierai',
    'ressaieras',
    'ressaierez',
    'ressaignai',
    'ressaignas',
    'ressaignât',
    'ressaignée',
    'ressaigner',
    'ressaignes',
    'ressaignés',
    'ressaignez',
    'ressaisies',
    'ressaisira',
    'ressassais',
    'ressassait',
    'ressassant',
    'ressassées',
    'ressassent',
    'ressassera',
    'ressasseur',
    'ressassiez',
    'ressassons',
    'ressautais',
    'ressautait',
    'ressautant',
    'ressautées',
    'ressautent',
    'ressautera',
    'ressautiez',
    'ressautons',
    'ressayages',
    'ressayâmes',
    'ressayasse',
    'ressayâtes',
    'ressayerai',
    'ressayeras',
    'ressayerez',
    'ressayions',
    'ressemâmes',
    'ressemasse',
    'ressemâtes',
    'ressemblai',
    'ressemblas',
    'ressemblât',
    'ressembler',
    'ressembles',
    'ressemblez',
    'ressemelai',
    'ressemelas',
    'ressemelât',
    'ressemelée',
    'ressemeler',
    'ressemelés',
    'ressemelez',
    'ressemelle',
    'ressèmerai',
    'ressèmeras',
    'ressèmerez',
    'ressemions',
    'ressentais',
    'ressentait',
    'ressentant',
    'ressentent',
    'ressenties',
    'ressentiez',
    'ressentira',
    'ressentons',
    'resserrais',
    'resserrait',
    'resserrant',
    'resserrées',
    'resserrent',
    'resserrera',
    'resserriez',
    'resserrons',
    'resservais',
    'resservait',
    'resservant',
    'resservent',
    'resservies',
    'resserviez',
    'resservira',
    'resservons',
    'ressortais',
    'ressortait',
    'ressortant',
    'ressortent',
    'ressorties',
    'ressortiez',
    'ressortira',
    'ressortons',
    'ressoudais',
    'ressoudait',
    'ressoudant',
    'ressoudées',
    'ressoudent',
    'ressoudera',
    'ressoudiez',
    'ressoudons',
    'ressourçai',
    'ressourças',
    'ressourçât',
    'ressourcée',
    'ressourcer',
    'ressources',
    'ressourcés',
    'ressourcez',
    'ressouvenu',
    'ressouvins',
    'ressouvint',
    'ressouvînt',
    'ressuaient',
    'ressuasses',
    'ressuerais',
    'ressuerait',
    'ressuèrent',
    'ressueriez',
    'ressuerons',
    'ressueront',
    'ressuierai',
    'ressuieras',
    'ressuierez',
    'ressurgira',
    'ressuscita',
    'ressuscite',
    'ressuscité',
    'ressuyages',
    'ressuyâmes',
    'ressuyasse',
    'ressuyâtes',
    'ressuyions',
    'restassent',
    'restassiez',
    'restaurais',
    'restaurait',
    'restaurant',
    'restaurées',
    'restaurent',
    'restaurera',
    'restauriez',
    'restaurons',
    'resterions',
    'restituais',
    'restituait',
    'restituant',
    'restituées',
    'restituent',
    'restituera',
    'restituiez',
    'restituons',
    'restoroute',
    'restreigne',
    'restreinte',
    'restreints',
    'restrictif',
    'résultante',
    'résultants',
    'résumaient',
    'résumasses',
    'résumerais',
    'résumerait',
    'résumèrent',
    'résumeriez',
    'résumerons',
    'résumeront',
    'résurgence',
    'resurgîmes',
    'resurgirai',
    'resurgiras',
    'resurgirez',
    'resurgisse',
    'resurgîtes',
    'rétablîmes',
    'rétablirai',
    'rétabliras',
    'rétablirez',
    'rétablisse',
    'rétablîtes',
    'retaillais',
    'retaillait',
    'retaillant',
    'retaillées',
    'retaillent',
    'retaillera',
    'retailliez',
    'retaillons',
    'rétamaient',
    'rétamasses',
    'rétamerais',
    'rétamerait',
    'rétamèrent',
    'rétameriez',
    'rétamerons',
    'rétameront',
    'retapaient',
    'retapasses',
    'retaperais',
    'retaperait',
    'retapèrent',
    'retaperiez',
    'retaperons',
    'retaperont',
    'retapissai',
    'retapissas',
    'retapissât',
    'retapissée',
    'retapisser',
    'retapisses',
    'retapissés',
    'retapissez',
    'retardâmes',
    'retardasse',
    'retardâtes',
    'retarderai',
    'retarderas',
    'retarderez',
    'retardions',
    'retâtaient',
    'retâtasses',
    'retâterais',
    'retâterait',
    'retâtèrent',
    'retâteriez',
    'retâterons',
    'retâteront',
    'reteignais',
    'reteignait',
    'reteignant',
    'reteignent',
    'reteigniez',
    'reteignons',
    'reteindrai',
    'reteindras',
    'reteindrez',
    'retenaient',
    'retenantes',
    'retendîmes',
    'retendions',
    'retendisse',
    'retendîtes',
    'retendrais',
    'retendrait',
    'retendriez',
    'retendrons',
    'retendront',
    'retentâmes',
    'retentasse',
    'retentâtes',
    'retenterai',
    'retenteras',
    'retenterez',
    'retentîmes',
    'retentions',
    'rétentions',
    'retentirai',
    'retentiras',
    'retentirez',
    'retentisse',
    'retentîtes',
    'reterçâmes',
    'reterçasse',
    'reterçâtes',
    'retercerai',
    'reterceras',
    'retercerez',
    'retercions',
    'retersâmes',
    'retersasse',
    'retersâtes',
    'reterserai',
    'reterseras',
    'reterserez',
    'retersions',
    'réticences',
    'réticentes',
    'réticulais',
    'réticulait',
    'réticulant',
    'réticulées',
    'réticulent',
    'réticulera',
    'réticuliez',
    'réticulons',
    'réticulums',
    'retiendrai',
    'retiendras',
    'retiendrez',
    'retiennent',
    'rétinienne',
    'retinssent',
    'retinssiez',
    'retiraient',
    'retirasses',
    'retiration',
    'retirement',
    'retirerais',
    'retirerait',
    'retirèrent',
    'retireriez',
    'retirerons',
    'retireront',
    'retissâmes',
    'retissasse',
    'retissâtes',
    'retisserai',
    'retisseras',
    'retisserez',
    'retissions',
    'retombâmes',
    'retombante',
    'retombants',
    'retombasse',
    'retombâtes',
    'retomberai',
    'retomberas',
    'retomberez',
    'retombions',
    'retondîmes',
    'retondions',
    'retondisse',
    'retondîtes',
    'retondrais',
    'retondrait',
    'retondriez',
    'retondrons',
    'retondront',
    'retordages',
    'retordeurs',
    'retordeuse',
    'retordîmes',
    'retordions',
    'retordisse',
    'retordîtes',
    'retordrais',
    'retordrait',
    'retordriez',
    'retordrons',
    'retordront',
    'rétorquais',
    'rétorquait',
    'rétorquant',
    'rétorquées',
    'rétorquent',
    'rétorquera',
    'rétorquiez',
    'rétorquons',
    'rétorsions',
    'retouchais',
    'retouchait',
    'retouchant',
    'retouchées',
    'retouchent',
    'retouchera',
    'retoucheur',
    'retouchiez',
    'retouchons',
    'retournage',
    'retournais',
    'retournait',
    'retournant',
    'retournées',
    'retournent',
    'retournera',
    'retourniez',
    'retournons',
    'retraçâmes',
    'retraçasse',
    'retraçâtes',
    'retracerai',
    'retraceras',
    'retracerez',
    'retracions',
    'rétractais',
    'rétractait',
    'rétractant',
    'rétractées',
    'rétractent',
    'rétractera',
    'rétracteur',
    'rétractiez',
    'rétractile',
    'rétraction',
    'rétractons',
    'retraduira',
    'retraduire',
    'retraduise',
    'retraduite',
    'retraduits',
    'retrairais',
    'retrairait',
    'retrairiez',
    'retrairons',
    'retrairont',
    'retraitais',
    'retraitait',
    'retraitant',
    'retraitées',
    'retraitent',
    'retraitera',
    'retraitiez',
    'retraitons',
    'retranchai',
    'retranchas',
    'retranchât',
    'retranchée',
    'retrancher',
    'retranches',
    'retranchés',
    'retranchez',
    'retransmet',
    'retransmis',
    'retransmit',
    'retransmît',
    'retraversa',
    'retraverse',
    'retraversé',
    'retrayante',
    'retrayants',
    'retrayions',
    'rétrécîmes',
    'rétrécirai',
    'rétréciras',
    'rétrécirez',
    'rétrécisse',
    'rétrécîtes',
    'retrempais',
    'retrempait',
    'retrempant',
    'retrempées',
    'retrempent',
    'retrempera',
    'retrempiez',
    'retrempons',
    'rétribuais',
    'rétribuait',
    'rétribuant',
    'rétribuées',
    'rétribuent',
    'rétribuera',
    'rétribuiez',
    'rétribuons',
    'retrievers',
    'rétroactes',
    'rétroactif',
    'rétroagira',
    'rétrocédai',
    'rétrocédas',
    'rétrocédât',
    'rétrocédée',
    'rétrocéder',
    'rétrocédés',
    'rétrocèdes',
    'rétrocédez',
    'rétrofusée',
    'rétrograda',
    'rétrograde',
    'rétrogradé',
    'retroussai',
    'retroussas',
    'retroussât',
    'retroussée',
    'retrousser',
    'retrousses',
    'retroussés',
    'retroussez',
    'retroussis',
    'retrouvais',
    'retrouvait',
    'retrouvant',
    'retrouvées',
    'retrouvent',
    'retrouvera',
    'retrouviez',
    'retrouvons',
    'rétrovirus',
    'réunifiais',
    'réunifiait',
    'réunifiant',
    'réunifiées',
    'réunifient',
    'réunifiera',
    'réunifiiez',
    'réunifions',
    'réunirions',
    'réunissage',
    'réunissais',
    'réunissait',
    'réunissant',
    'réunissent',
    'réunissiez',
    'réunissons',
    'réussirais',
    'réussirait',
    'réussirent',
    'réussiriez',
    'réussirons',
    'réussiront',
    'réussisses',
    'réussissez',
    'réutilisai',
    'réutilisas',
    'réutilisât',
    'réutilisée',
    'réutiliser',
    'réutilises',
    'réutilisés',
    'réutilisez',
    'revaccinai',
    'revaccinas',
    'revaccinât',
    'revaccinée',
    'revacciner',
    'revaccines',
    'revaccinés',
    'revaccinez',
    'revaillent',
    'revalaient',
    'revalorisa',
    'revalorise',
    'revalorisé',
    'revalurent',
    'revalusses',
    'revanchais',
    'revanchait',
    'revanchant',
    'revanchard',
    'revanchées',
    'revanchent',
    'revanchera',
    'revanchiez',
    'revanchons',
    'rêvassâmes',
    'rêvassasse',
    'rêvassâtes',
    'rêvasserai',
    'rêvasseras',
    'rêvasserez',
    'rêvasserie',
    'rêvasseurs',
    'rêvasseuse',
    'rêvassions',
    'revaudrais',
    'revaudrait',
    'revaudriez',
    'revaudrons',
    'revaudront',
    'revécurent',
    'revécusses',
    'réveillais',
    'réveillait',
    'réveillant',
    'réveillées',
    'réveillent',
    'réveillera',
    'réveilleur',
    'réveilliez',
    'réveillons',
    'révélaient',
    'révélasses',
    'révélateur',
    'révélation',
    'révélerais',
    'révélerait',
    'révélèrent',
    'révéleriez',
    'révélerons',
    'révéleront',
    'revenaient',
    'revenantes',
    'revendeurs',
    'revendeuse',
    'revendîmes',
    'revendions',
    'revendiqua',
    'revendique',
    'revendiqué',
    'revendisse',
    'revendîtes',
    'revendrais',
    'revendrait',
    'revendriez',
    'revendrons',
    'revendront',
    'révéraient',
    'rêveraient',
    'révérasses',
    'réverbérai',
    'réverbéras',
    'réverbérât',
    'réverbérée',
    'réverbérer',
    'réverbérés',
    'réverbères',
    'réverbérez',
    'reverchais',
    'reverchait',
    'reverchant',
    'reverchées',
    'reverchent',
    'reverchera',
    'reverchiez',
    'reverchons',
    'reverdîmes',
    'reverdirai',
    'reverdiras',
    'reverdirez',
    'reverdisse',
    'reverdîtes',
    'révérences',
    'révérendes',
    'révérerais',
    'révérerait',
    'révérèrent',
    'révéreriez',
    'révérerons',
    'révéreront',
    'revernîmes',
    'revernirai',
    'reverniras',
    'revernirez',
    'revernisse',
    'revernîtes',
    'reverrions',
    'reversâmes',
    'reversasse',
    'reversâtes',
    'reverserai',
    'reverseras',
    'reverserez',
    'réversible',
    'reversions',
    'réversions',
    'revêtaient',
    'revêtement',
    'revêtirais',
    'revêtirait',
    'revêtirent',
    'revêtiriez',
    'revêtirons',
    'revêtiront',
    'revêtisses',
    'reveuilles',
    'reviendrai',
    'reviendras',
    'reviendrez',
    'reviennent',
    'revigorais',
    'revigorait',
    'revigorant',
    'revigorées',
    'revigorent',
    'revigorera',
    'revigoriez',
    'revigorons',
    'revinssent',
    'revinssiez',
    'reviraient',
    'revirasses',
    'revirement',
    'revirerais',
    'revirerait',
    'revirèrent',
    'revireriez',
    'revirerons',
    'revireront',
    'révisables',
    'révisaient',
    'révisasses',
    'réviserais',
    'réviserait',
    'révisèrent',
    'réviseriez',
    'réviserons',
    'réviseront',
    'revissâmes',
    'revissasse',
    'revissâtes',
    'revisserai',
    'revisseras',
    'revisserez',
    'revissions',
    'revitalisa',
    'revitalise',
    'revitalisé',
    'revivaient',
    'revivifiai',
    'revivifias',
    'revivifiât',
    'revivifiée',
    'revivifier',
    'revivifies',
    'revivifiés',
    'revivifiez',
    'revivrions',
    'révocables',
    'révocation',
    'revolaient',
    'revolasses',
    'revolerais',
    'revolerait',
    'revolèrent',
    'revoleriez',
    'revolerons',
    'revoleront',
    'révoltâmes',
    'révoltante',
    'révoltants',
    'révoltasse',
    'révoltâtes',
    'révolterai',
    'révolteras',
    'révolterez',
    'révoltions',
    'révolution',
    'révoquâmes',
    'révoquante',
    'révoquants',
    'révoquasse',
    'révoquâtes',
    'révoquerai',
    'révoqueras',
    'révoquerez',
    'révoquions',
    'revotaient',
    'revotasses',
    'revoterais',
    'revoterait',
    'revotèrent',
    'revoteriez',
    'revoterons',
    'revoteront',
    'revoudrais',
    'revoudrait',
    'revoudriez',
    'revoudrons',
    'revoudront',
    'revoulions',
    'revoulûmes',
    'revoulusse',
    'revoulûtes',
    'revoyaient',
    'révulsâmes',
    'révulsasse',
    'révulsâtes',
    'révulserai',
    'révulseras',
    'révulserez',
    'révulsions',
    'révulsives',
    'rewritings',
    'rhabillage',
    'rhabillais',
    'rhabillait',
    'rhabillant',
    'rhabillées',
    'rhabillent',
    'rhabillera',
    'rhabilleur',
    'rhabilliez',
    'rhabillons',
    'rhapsodies',
    'rhéologies',
    'rhéologues',
    'rhéomètres',
    'rhéophiles',
    'rhétiennes',
    'rhétorique',
    'rhinanthes',
    'rhingraves',
    'rhinocéros',
    'rhinologie',
    'rhinolophe',
    'rhizocarpé',
    'rhizoctone',
    'rhizophage',
    'rhizophore',
    'rhizostome',
    'rhizotomes',
    'rhodamines',
    'rhodaniens',
    'rhodopsine',
    'rhombiques',
    'rhomboïdal',
    'rhomboïdes',
    'rhotacisme',
    'rhumassent',
    'rhumassiez',
    'rhumatisme',
    'rhumatoïde',
    'rhumerions',
    'rhynchites',
    'rhyolithes',
    'rhytidomes',
    'ribambelle',
    'riblassent',
    'riblassiez',
    'riblerions',
    'riboulâmes',
    'riboulasse',
    'riboulâtes',
    'riboulerai',
    'ribouleras',
    'riboulerez',
    'riboulions',
    'ricanaient',
    'ricanasses',
    'ricanement',
    'ricanerais',
    'ricanerait',
    'ricanèrent',
    'ricaneriez',
    'ricanerons',
    'ricaneront',
    'ricaneuses',
    'richelieus',
    'richissime',
    'rickettsie',
    'ricochâmes',
    'ricochasse',
    'ricochâtes',
    'ricocherai',
    'ricocheras',
    'ricocherez',
    'ricochions',
    'ridassions',
    'rideraient',
    'ridiculisa',
    'ridiculise',
    'ridiculisé',
    'riffaudais',
    'riffaudait',
    'riffaudant',
    'riffaudées',
    'riffaudent',
    'riffaudera',
    'riffaudiez',
    'riffaudons',
    'riflassent',
    'riflassiez',
    'riflerions',
    'rigidement',
    'rigidifiai',
    'rigidifias',
    'rigidifiât',
    'rigidifiée',
    'rigidifier',
    'rigidifies',
    'rigidifiés',
    'rigidifiez',
    'rigolaient',
    'rigolardes',
    'rigolasses',
    'rigolerais',
    'rigolerait',
    'rigolèrent',
    'rigoleriez',
    'rigolerons',
    'rigoleront',
    'rigoleuses',
    'rigorismes',
    'rigoristes',
    'rigoureuse',
    'rimaillais',
    'rimaillait',
    'rimaillant',
    'rimaillent',
    'rimaillera',
    'rimailleur',
    'rimailliez',
    'rimaillons',
    'rimassions',
    'rimeraient',
    'rinçassent',
    'rinçassiez',
    'rincerions',
    'ringardage',
    'ringardais',
    'ringardait',
    'ringardant',
    'ringardées',
    'ringardent',
    'ringardera',
    'ringardiez',
    'ringardons',
    'ripaillais',
    'ripaillait',
    'ripaillant',
    'ripaillent',
    'ripaillera',
    'ripailleur',
    'ripailliez',
    'ripaillons',
    'ripassions',
    'riperaient',
    'ripolinais',
    'ripolinait',
    'ripolinant',
    'ripolinées',
    'ripolinent',
    'ripolinera',
    'ripoliniez',
    'ripolinons',
    'ripostâmes',
    'ripostasse',
    'ripostâtes',
    'riposterai',
    'riposteras',
    'riposterez',
    'ripostions',
    'risquaient',
    'risquasses',
    'risquerais',
    'risquerait',
    'risquèrent',
    'risqueriez',
    'risquerons',
    'risqueront',
    'rissolâmes',
    'rissolasse',
    'rissolâtes',
    'rissolerai',
    'rissoleras',
    'rissolerez',
    'rissolions',
    'ristournai',
    'ristournas',
    'ristournât',
    'ristournée',
    'ristourner',
    'ristournes',
    'ristournés',
    'ristournez',
    'ritualisai',
    'ritualisas',
    'ritualisât',
    'ritualisée',
    'ritualiser',
    'ritualises',
    'ritualisés',
    'ritualisez',
    'ritualisme',
    'ritualiste',
    'rivalisais',
    'rivalisait',
    'rivalisant',
    'rivalisent',
    'rivalisera',
    'rivalisiez',
    'rivalisons',
    'rivassions',
    'rivelaines',
    'riveraient',
    'riveraines',
    'rivetaient',
    'rivetasses',
    'rivetèrent',
    'riveteuses',
    'rivetterai',
    'rivetteras',
    'rivetterez',
    'roast-beef',
    'robassions',
    'roberaient',
    'robinetier',
    'roboratifs',
    'roborative',
    'robotiques',
    'robotisais',
    'robotisait',
    'robotisant',
    'robotisées',
    'robotisent',
    'robotisera',
    'robotisiez',
    'robotisons',
    'robustesse',
    'rocaillage',
    'rocailleur',
    'rocailleux',
    'rochassier',
    'rocouaient',
    'rocouasses',
    'rocouerais',
    'rocouerait',
    'rocouèrent',
    'rocoueriez',
    'rocouerons',
    'rocoueront',
    'rocquaient',
    'rocquasses',
    'rocquerais',
    'rocquerait',
    'rocquèrent',
    'rocqueriez',
    'rocquerons',
    'rocqueront',
    'rôdaillais',
    'rôdaillait',
    'rôdaillant',
    'rôdaillent',
    'rôdaillera',
    'rôdailliez',
    'rôdaillons',
    'rodassions',
    'rôdassions',
    'roderaient',
    'rôderaient',
    'rogatoires',
    'rognassent',
    'rognassiez',
    'rognerions',
    'rognonnais',
    'rognonnait',
    'rognonnant',
    'rognonnées',
    'rognonnent',
    'rognonnera',
    'rognonniez',
    'rognonnons',
    'roidirions',
    'roidissais',
    'roidissait',
    'roidissant',
    'roidissent',
    'roidissiez',
    'roidissons',
    'romançâmes',
    'romançasse',
    'romançâtes',
    'romancerai',
    'romanceras',
    'romancerez',
    'romanceros',
    'romancière',
    'romanciers',
    'romancions',
    'romanesque',
    'romanichel',
    'romanisais',
    'romanisait',
    'romanisant',
    'romanisées',
    'romanisent',
    'romanisera',
    'romanisiez',
    'romanisons',
    'romanistes',
    'romantique',
    'romantisme',
    'rompissent',
    'rompissiez',
    'rompraient',
    'ronceraies',
    'ronchonnai',
    'ronchonnas',
    'ronchonnât',
    'ronchonner',
    'ronchonnes',
    'ronchonnez',
    'rondelette',
    'rondirions',
    'rondissais',
    'rondissait',
    'rondissant',
    'rondissent',
    'rondissiez',
    'rondissons',
    'rond-point',
    'ronéotypai',
    'ronéotypas',
    'ronéotypât',
    'ronéotypée',
    'ronéotyper',
    'ronéotypes',
    'ronéotypés',
    'ronéotypez',
    'ronflaguai',
    'ronflaguas',
    'ronflaguât',
    'ronflaguer',
    'ronflagues',
    'ronflaguez',
    'ronflaient',
    'ronflantes',
    'ronflasses',
    'ronflement',
    'ronflerais',
    'ronflerait',
    'ronflèrent',
    'ronfleriez',
    'ronflerons',
    'ronfleront',
    'ronfleuses',
    'rongeaient',
    'rongeasses',
    'rongements',
    'rongerions',
    'ronronnais',
    'ronronnait',
    'ronronnant',
    'ronronnent',
    'ronronnera',
    'ronronniez',
    'ronronnons',
    'ronsardisa',
    'ronsardise',
    'ronsardisé',
    'roquassent',
    'roquassiez',
    'roqueforts',
    'roquelaure',
    'roquentins',
    'roquerions',
    'rosaniline',
    'rosassions',
    'rose-croix',
    'roselières',
    'roseraient',
    'rosiériste',
    'rosiraient',
    'rosissions',
    'rossassent',
    'rossassiez',
    'rosserions',
    'rossignols',
    'rossinante',
    'rotassions',
    'rotatoires',
    'rotatrices',
    'roteraient',
    'rôtiraient',
    'rôtissages',
    'rôtisserie',
    'rôtisseurs',
    'rôtisseuse',
    'rôtissions',
    'rôtissoire',
    'rotondités',
    'rotrouenge',
    'rotruenges',
    'rotulienne',
    'roturières',
    'rouannette',
    'rouassions',
    'roublardes',
    'roucoulade',
    'roucoulais',
    'roucoulait',
    'roucoulant',
    'roucoulées',
    'roucoulent',
    'roucoulera',
    'roucouliez',
    'roucoulons',
    'roudoudous',
    'rouennerie',
    'roueraient',
    'rougeâtres',
    'rougeaudes',
    'rougeoient',
    'rougeoiera',
    'rougeoyais',
    'rougeoyait',
    'rougeoyant',
    'rougeoyiez',
    'rougeoyons',
    'rougirions',
    'rougissais',
    'rougissait',
    'rougissant',
    'rougissent',
    'rougissiez',
    'rougissons',
    'rouillâmes',
    'rouillasse',
    'rouillâtes',
    'rouillerai',
    'rouilleras',
    'rouillerez',
    'rouillions',
    'rouillures',
    'rouiraient',
    'rouissages',
    'rouissions',
    'rouissoirs',
    'roulassent',
    'roulassiez',
    'rouleautée',
    'rouleautés',
    'roulements',
    'roulerions',
    'roulottais',
    'roulottait',
    'roulottant',
    'roulottées',
    'roulottent',
    'roulottera',
    'roulottiez',
    'roulottons',
    'roupillais',
    'roupillait',
    'roupillant',
    'roupillent',
    'roupillera',
    'roupilliez',
    'roupillons',
    'rouscailla',
    'rouscaille',
    'rouscaillé',
    'rouspétais',
    'rouspétait',
    'rouspétant',
    'rouspètent',
    'rouspétera',
    'rouspéteur',
    'rouspétiez',
    'rouspétons',
    'roussâtres',
    'rousselets',
    'roussettes',
    'roussirais',
    'roussirait',
    'roussirent',
    'roussiriez',
    'roussirons',
    'roussiront',
    'roussisses',
    'roussissez',
    'roustirais',
    'roustirait',
    'roustirent',
    'roustiriez',
    'roustirons',
    'roustiront',
    'roustisses',
    'roustissez',
    'routassent',
    'routassiez',
    'routerions',
    'routinière',
    'routiniers',
    'rouverains',
    'rouvraient',
    'rouvrirais',
    'rouvrirait',
    'rouvrirent',
    'rouvririez',
    'rouvrirons',
    'rouvriront',
    'rouvrisses',
    'royalement',
    'royalismes',
    'royalistes',
    'rubanaient',
    'rubanasses',
    'rubanerais',
    'rubanerait',
    'rubanèrent',
    'rubaneries',
    'rubaneriez',
    'rubanerons',
    'rubaneront',
    'rubanières',
    'rubéfiâmes',
    'rubéfiante',
    'rubéfiants',
    'rubéfiasse',
    'rubéfiâtes',
    'rubéfierai',
    'rubéfieras',
    'rubéfierez',
    'rubéfiions',
    'rubellites',
    'rubéoleuse',
    'rubescente',
    'rubescents',
    'rubicelles',
    'rubicondes',
    'rubigineux',
    'ruchassent',
    'ruchassiez',
    'rucherions',
    'rudbeckies',
    'rudentâmes',
    'rudentasse',
    'rudentâtes',
    'rudenterai',
    'rudenteras',
    'rudenterez',
    'rudentions',
    'rudentures',
    'rudération',
    'rudoiement',
    'rudoierais',
    'rudoierait',
    'rudoieriez',
    'rudoierons',
    'rudoieront',
    'rudoyaient',
    'rudoyasses',
    'rudoyèrent',
    'rugination',
    'rugiraient',
    'rugissante',
    'rugissants',
    'rugissions',
    'ruilassent',
    'ruilassiez',
    'ruilerions',
    'ruinassent',
    'ruinassiez',
    'ruinerions',
    'ruiniforme',
    'ruisselais',
    'ruisselait',
    'ruisselant',
    'ruisselets',
    'ruisseliez',
    'ruisselles',
    'ruisselons',
    'ruminaient',
    'ruminantes',
    'ruminasses',
    'rumination',
    'ruminerais',
    'ruminerait',
    'ruminèrent',
    'rumineriez',
    'ruminerons',
    'rumineront',
    'rupinaient',
    'rupinasses',
    'rupinerais',
    'rupinerait',
    'rupinèrent',
    'rupineriez',
    'rupinerons',
    'rupineront',
    'rusassions',
    'ruseraient',
    'russifiais',
    'russifiait',
    'russifiant',
    'russifiées',
    'russifient',
    'russifiera',
    'russifiiez',
    'russifions',
    'russophile',
    'rusticages',
    'rusticités',
    'rustiquais',
    'rustiquait',
    'rustiquant',
    'rustiquées',
    'rustiquent',
    'rustiquera',
    'rustiquiez',
    'rustiquons',
    'ruthéniums',
    'rutilaient',
    'rutilantes',
    'rutilasses',
    'rutilerais',
    'rutilerait',
    'rutilèrent',
    'rutileriez',
    'rutilerons',
    'rutileront',
    'rythmaient',
    'rythmasses',
    'rythmerais',
    'rythmerait',
    'rythmèrent',
    'rythmeriez',
    'rythmerons',
    'rythmeront',
    'rythmicien',
    'rythmicité',
    'rythmiques',
    'sabbathien',
    'sabbatique',
    'sablassent',
    'sablassiez',
    'sablerions',
    'sablonnais',
    'sablonnait',
    'sablonnant',
    'sablonnées',
    'sablonnent',
    'sablonnera',
    'sablonneux',
    'sablonniez',
    'sablonnons',
    'sabordages',
    'sabordâmes',
    'sabordasse',
    'sabordâtes',
    'saborderai',
    'saborderas',
    'saborderez',
    'sabordions',
    'sabotaient',
    'sabotasses',
    'saboterais',
    'saboterait',
    'sabotèrent',
    'saboteries',
    'saboteriez',
    'saboterons',
    'saboteront',
    'saboteuses',
    'sabotières',
    'saboulâmes',
    'saboulasse',
    'saboulâtes',
    'saboulerai',
    'sabouleras',
    'saboulerez',
    'saboulions',
    'sabrairent',
    'sabraisses',
    'sabrerions',
    'sabretache',
    'saburrales',
    'sacagnâmes',
    'sacagnasse',
    'sacagnâtes',
    'sacagnerai',
    'sacagneras',
    'sacagnerez',
    'sacagnions',
    'saccadâmes',
    'saccadasse',
    'saccadâtes',
    'saccaderai',
    'saccaderas',
    'saccaderez',
    'saccadions',
    'saccageais',
    'saccageait',
    'saccageant',
    'saccageons',
    'saccagerai',
    'saccageras',
    'saccagerez',
    'saccageurs',
    'saccageuse',
    'saccagions',
    'saccharase',
    'saccharate',
    'sacchareux',
    'saccharidé',
    'saccharine',
    'saccharins',
    'saccharolé',
    'saccharose',
    'saccharure',
    'sacciforme',
    'sacculines',
    'sacerdoces',
    'sacerdotal',
    'sacquaient',
    'sacquasses',
    'sacquebute',
    'sacquerais',
    'sacquerait',
    'sacquèrent',
    'sacqueriez',
    'sacquerons',
    'sacqueront',
    'sacralisai',
    'sacralisas',
    'sacralisât',
    'sacralisée',
    'sacraliser',
    'sacralises',
    'sacralisés',
    'sacralisez',
    'sacrassent',
    'sacrassiez',
    'sacrements',
    'sacrerions',
    'sacrifiais',
    'sacrifiait',
    'sacrifiant',
    'sacrifices',
    'sacrifiées',
    'sacrifient',
    'sacrifiera',
    'sacrifiiez',
    'sacrifions',
    'sacrilèges',
    'sacripants',
    'sacristain',
    'sacristies',
    'sacristine',
    'saducéenne',
    'safranâmes',
    'safranasse',
    'safranâtes',
    'safranerai',
    'safraneras',
    'safranerez',
    'safranière',
    'safranions',
    'sage-femme',
    'sagittaire',
    'sagittales',
    'sagoutiers',
    'saharienne',
    'sahélienne',
    'saignaient',
    'saignantes',
    'saignasses',
    'saignement',
    'saignerais',
    'saignerait',
    'saignèrent',
    'saigneriez',
    'saignerons',
    'saigneront',
    'saigneuses',
    'saillaient',
    'saillantes',
    'saillirais',
    'saillirait',
    'saillirent',
    'sailliriez',
    'saillirons',
    'sailliront',
    'saillisses',
    'saintement',
    'saint-père',
    'saisirions',
    'saisissais',
    'saisissait',
    'saisissant',
    'saisissent',
    'saisissiez',
    'saisissons',
    'saisonnais',
    'saisonnait',
    'saisonnant',
    'saisonnent',
    'saisonnera',
    'saisonnier',
    'saisonniez',
    'saisonnons',
    'salamalecs',
    'salamandre',
    'salanganes',
    'salariales',
    'salariâmes',
    'salariasse',
    'salariâtes',
    'salarierai',
    'salarieras',
    'salarierez',
    'salariions',
    'salassions',
    'saleraient',
    'salésienne',
    'salicaires',
    'salicional',
    'salicoques',
    'salicornes',
    'salicoside',
    'salicylate',
    'salifiable',
    'salifiâmes',
    'salifiasse',
    'salifiâtes',
    'salifierai',
    'salifieras',
    'salifierez',
    'salifiions',
    'saligaudes',
    'salinières',
    'saliraient',
    'salissante',
    'salissants',
    'salissions',
    'salissures',
    'salivaient',
    'salivaires',
    'salivasses',
    'salivation',
    'saliverais',
    'saliverait',
    'salivèrent',
    'saliveriez',
    'saliverons',
    'saliveront',
    'salmonelle',
    'salonardes',
    'salonnarde',
    'salonnards',
    'salonnière',
    'salonniers',
    'salopaient',
    'salopasses',
    'saloperais',
    'saloperait',
    'salopèrent',
    'saloperies',
    'saloperiez',
    'saloperons',
    'saloperont',
    'salopettes',
    'salopiauds',
    'salpêtrage',
    'salpêtrais',
    'salpêtrait',
    'salpêtrant',
    'salpêtrées',
    'salpêtrent',
    'salpêtrera',
    'salpêtreux',
    'salpêtriez',
    'salpêtrons',
    'salpingite',
    'saltarelle',
    'saltations',
    'saluassent',
    'saluassiez',
    'saluerions',
    'salutaires',
    'salutation',
    'salutistes',
    'salvateurs',
    'salvatrice',
    'samaritain',
    'sanatorium',
    'san-benito',
    'sanctifiai',
    'sanctifias',
    'sanctifiât',
    'sanctifiée',
    'sanctifier',
    'sanctifies',
    'sanctifiés',
    'sanctifiez',
    'sanctionna',
    'sanctionne',
    'sanctionné',
    'sanctuaire',
    'sandalette',
    'sandaraque',
    'sanderling',
    'sandwichai',
    'sandwichas',
    'sandwichât',
    'sandwichée',
    'sandwicher',
    'sandwiches',
    'sandwichés',
    'sandwichez',
    'sang-froid',
    'sanglaient',
    'sanglantes',
    'sanglasses',
    'sanglerais',
    'sanglerait',
    'sanglèrent',
    'sangleriez',
    'sanglerons',
    'sangleront',
    'sanglotais',
    'sanglotait',
    'sanglotant',
    'sanglotent',
    'sanglotera',
    'sanglotiez',
    'sanglotons',
    'sanhédrins',
    'sanitaires',
    'sans-coeur',
    'sanscrites',
    'sansevière',
    'sans-façon',
    'sans-grade',
    'sans-logis',
    'sansonnets',
    'sans-parti',
    'sans-souci',
    'santonnais',
    'santonnait',
    'santonnant',
    'santonnées',
    'santonnent',
    'santonnera',
    'santonniez',
    'santonnons',
    'saoudienne',
    'saoulaient',
    'saoulasses',
    'saoulerais',
    'saoulerait',
    'saoulèrent',
    'saouleriez',
    'saoulerons',
    'saouleront',
    'sapassions',
    'saperaient',
    'saperlotte',
    'sapiential',
    'sapinettes',
    'sapinières',
    'saponacées',
    'saponaires',
    'saponifiai',
    'saponifias',
    'saponifiât',
    'saponifiée',
    'saponifier',
    'saponifies',
    'saponifiés',
    'saponifiez',
    'saprophage',
    'saprophyte',
    'saquassent',
    'saquassiez',
    'saquerions',
    'sarabandes',
    'sarbacanes',
    'sarclaient',
    'sarclasses',
    'sarclerais',
    'sarclerait',
    'sarclèrent',
    'sarcleriez',
    'sarclerons',
    'sarcleront',
    'sarcleuses',
    'sarcocèles',
    'sarcophage',
    'sardinerie',
    'sardinière',
    'sardiniers',
    'sardonique',
    'sarmentais',
    'sarmentait',
    'sarmentant',
    'sarmentent',
    'sarmentera',
    'sarmenteux',
    'sarmentiez',
    'sarmentons',
    'sarrasines',
    'sarriettes',
    'sassanides',
    'sassassent',
    'sassassiez',
    'sassements',
    'sassenages',
    'sasserions',
    'satanaient',
    'satanasses',
    'satanerais',
    'satanerait',
    'satanèrent',
    'sataneriez',
    'satanerons',
    'sataneront',
    'sataniques',
    'satanismes',
    'satellisai',
    'satellisas',
    'satellisât',
    'satellisée',
    'satelliser',
    'satellises',
    'satellisés',
    'satellisez',
    'satellites',
    'satinaient',
    'satinasses',
    'satinerais',
    'satinerait',
    'satinèrent',
    'satineriez',
    'satinerons',
    'satineront',
    'satinettes',
    'satineuses',
    'satiriques',
    'satirisais',
    'satirisait',
    'satirisant',
    'satirisées',
    'satirisent',
    'satirisera',
    'satirisiez',
    'satirisons',
    'satisfaire',
    'satisfaite',
    'satisfaits',
    'satisfasse',
    'satisfecit',
    'satisferai',
    'satisferas',
    'satisferez',
    'satisfîmes',
    'satisfisse',
    'satisfîtes',
    'satonnâmes',
    'satonnasse',
    'satonnâtes',
    'satonnerai',
    'satonneras',
    'satonnerez',
    'satonnions',
    'saturables',
    'saturaient',
    'saturantes',
    'saturasses',
    'saturateur',
    'saturation',
    'saturerais',
    'saturerait',
    'saturèrent',
    'satureriez',
    'saturerons',
    'satureront',
    'saturnales',
    'saturniens',
    'saturnines',
    'saturnisme',
    'satyriasis',
    'satyriques',
    'satyrismes',
    'sauçassent',
    'sauçassiez',
    'saucerions',
    'sauciflard',
    'saucissons',
    'saugrenues',
    'saumoneaux',
    'saumurages',
    'saumurâmes',
    'saumurasse',
    'saumurâtes',
    'saumurerai',
    'saumureras',
    'saumurerez',
    'saumurions',
    'saunassent',
    'saunassiez',
    'saunerions',
    'saupiquets',
    'saupoudrai',
    'saupoudras',
    'saupoudrât',
    'saupoudrée',
    'saupoudrer',
    'saupoudres',
    'saupoudrés',
    'saupoudrez',
    'saurassent',
    'saurassiez',
    'saurerions',
    'sauririons',
    'saurissage',
    'saurissais',
    'saurissait',
    'saurissant',
    'saurissent',
    'saurisseur',
    'saurissiez',
    'saurissons',
    'sautassent',
    'sautassiez',
    'sautereaux',
    'sauterelle',
    'sauterions',
    'sautillais',
    'sautillait',
    'sautillant',
    'sautillent',
    'sautillera',
    'sautilliez',
    'sautillons',
    'sauvageons',
    'sauvagerie',
    'sauvagines',
    'sauvassent',
    'sauvassiez',
    'sauvegarda',
    'sauvegarde',
    'sauvegardé',
    'sauverions',
    'sauvetages',
    'sauveteurs',
    'savonnages',
    'savonnâmes',
    'savonnasse',
    'savonnâtes',
    'savonnerai',
    'savonneras',
    'savonnerez',
    'savonnerie',
    'savonnette',
    'savonneuse',
    'savonnière',
    'savonniers',
    'savonnions',
    'savourâmes',
    'savourasse',
    'savourâtes',
    'savourerai',
    'savoureras',
    'savourerez',
    'savoureuse',
    'savourions',
    'savoyardes',
    'saxifrages',
    'saxophones',
    'scabieuses',
    'scabreuses',
    'scaferlati',
    'scalpaient',
    'scalpasses',
    'scalperais',
    'scalperait',
    'scalpèrent',
    'scalperiez',
    'scalperons',
    'scalperont',
    'scandaient',
    'scandaleux',
    'scandalisa',
    'scandalise',
    'scandalisé',
    'scandasses',
    'scanderais',
    'scanderait',
    'scandèrent',
    'scanderiez',
    'scanderons',
    'scanderont',
    'scandinave',
    'scaphandre',
    'scaphoïdes',
    'scapulaire',
    'scarieuses',
    'scarifiage',
    'scarlatine',
    'scatologie',
    'scatophile',
    'scélérates',
    'scellaient',
    'scellasses',
    'scellement',
    'scellerais',
    'scellerait',
    'scellèrent',
    'scelleriez',
    'scellerons',
    'scelleront',
    'scénariste',
    'sceptiques',
    'scheidâmes',
    'scheidasse',
    'scheidâtes',
    'scheiderai',
    'scheideras',
    'scheiderez',
    'scheidions',
    'schématisa',
    'schématise',
    'schématisé',
    'schisteuse',
    'schistoïde',
    'schizoïdes',
    'schizoïdie',
    'schlittage',
    'schlittais',
    'schlittait',
    'schlittant',
    'schlittées',
    'schlittent',
    'schlittera',
    'schlitteur',
    'schlittiez',
    'schlittons',
    'schnauzers',
    'schnorchel',
    'schnorkels',
    'sciassions',
    'sciatiques',
    'scientisme',
    'scientiste',
    'scieraient',
    'scindaient',
    'scindasses',
    'scinderais',
    'scinderait',
    'scindèrent',
    'scinderiez',
    'scinderons',
    'scinderont',
    'scintillai',
    'scintillas',
    'scintillât',
    'scintiller',
    'scintilles',
    'scintillez',
    'sciottâmes',
    'sciottasse',
    'sciottâtes',
    'sciotterai',
    'sciotteras',
    'sciotterez',
    'sciottions',
    'scissionna',
    'scissionne',
    'scissionné',
    'scissipare',
    'scléranthe',
    'scléreuses',
    'sclérosais',
    'sclérosait',
    'sclérosant',
    'sclérosées',
    'sclérosent',
    'sclérosera',
    'sclérosiez',
    'sclérosons',
    'scolarisai',
    'scolarisas',
    'scolarisât',
    'scolarisée',
    'scolariser',
    'scolarises',
    'scolarisés',
    'scolarisez',
    'scolarités',
    'scoliastes',
    'scoriacées',
    'scorsonère',
    'scotchâmes',
    'scotchasse',
    'scotchâtes',
    'scotcherai',
    'scotcheras',
    'scotcherez',
    'scotchions',
    'scotomisai',
    'scotomisas',
    'scotomisât',
    'scotomisée',
    'scotomiser',
    'scotomises',
    'scotomisés',
    'scotomisez',
    'scoutismes',
    'scramasaxe',
    'scratchais',
    'scratchait',
    'scratchant',
    'scratchées',
    'scratchent',
    'scratchera',
    'scratchiez',
    'scratchons',
    'scripteurs',
    'scriptural',
    'scrofuleur',
    'scrofuleux',
    'scrupuleux',
    'scrutaient',
    'scrutasses',
    'scrutateur',
    'scrutation',
    'scruterais',
    'scruterait',
    'scrutèrent',
    'scruteriez',
    'scruterons',
    'scruteront',
    'sculptâmes',
    'sculptasse',
    'sculptâtes',
    'sculpterai',
    'sculpteras',
    'sculpterez',
    'sculpteurs',
    'sculptions',
    'sculptural',
    'sculptures',
    'scythiques',
    'séborrhées',
    'sécessions',
    'séchassent',
    'séchassiez',
    'sécheresse',
    'sécherions',
    'secondaire',
    'secondâmes',
    'secondasse',
    'secondâtes',
    'seconderai',
    'seconderas',
    'seconderez',
    'secondions',
    'secouaient',
    'secouasses',
    'secouement',
    'secouerais',
    'secouerait',
    'secouèrent',
    'secoueriez',
    'secouerons',
    'secoueront',
    'secourable',
    'secoureurs',
    'secourions',
    'secourisme',
    'secouriste',
    'secourrais',
    'secourrait',
    'secourriez',
    'secourrons',
    'secourront',
    'secourûmes',
    'secourusse',
    'secourûtes',
    'secrétages',
    'secrétaire',
    'sécrétâmes',
    'sécrétasse',
    'sécrétâtes',
    'sécréterai',
    'sécréteras',
    'sécréterez',
    'sécréteurs',
    'sécréteuse',
    'sécrétines',
    'sécrétions',
    'sécrétoire',
    'sectarisme',
    'sectateurs',
    'sectatrice',
    'sectionnai',
    'sectionnas',
    'sectionnât',
    'sectionnée',
    'sectionner',
    'sectionnes',
    'sectionnés',
    'sectionnez',
    'sectoriels',
    'sectorisai',
    'sectorisas',
    'sectorisât',
    'sectorisée',
    'sectoriser',
    'sectorises',
    'sectorisés',
    'sectorisez',
    'séculaires',
    'sécularisa',
    'sécularise',
    'sécularisé',
    'séculières',
    'sécurisais',
    'sécurisait',
    'sécurisant',
    'sécurisées',
    'sécurisent',
    'sécurisera',
    'sécurisiez',
    'sécurisons',
    'sédentaire',
    'sédimentai',
    'sédimentas',
    'sédimentât',
    'sédimentée',
    'sédimenter',
    'sédimentes',
    'sédimentés',
    'sédimentez',
    'séditieuse',
    'séducteurs',
    'séductions',
    'séductrice',
    'séduirions',
    'séduisante',
    'séduisants',
    'séduisîmes',
    'séduisions',
    'séduisisse',
    'séduisîtes',
    'segmentais',
    'segmentait',
    'segmentale',
    'segmentant',
    'segmentaux',
    'segmentées',
    'segmentent',
    'segmentera',
    'segmentiez',
    'segmentons',
    'ségrairies',
    'ségrégatif',
    'séguedille',
    'seigneurie',
    'séismicité',
    'séismiques',
    'séjournais',
    'séjournait',
    'séjournant',
    'séjournent',
    'séjournera',
    'séjourniez',
    'séjournons',
    'sélecteurs',
    'sélections',
    'sélectives',
    'sélectrice',
    'séléniates',
    'séléniques',
    'séléniteux',
    'séléniures',
    'sellassent',
    'sellassiez',
    'sellerions',
    'semainière',
    'semainiers',
    'sémantèmes',
    'sémantique',
    'sémaphores',
    'semassions',
    'semblables',
    'semblaient',
    'semblantes',
    'semblasses',
    'semblerais',
    'semblerait',
    'semblèrent',
    'sembleriez',
    'semblerons',
    'sembleront',
    'sèmeraient',
    'semestriel',
    'semi-aride',
    'semi-cokes',
    'semi-finis',
    'sémillante',
    'sémillants',
    'séminaires',
    'sémiologie',
    'sémiotique',
    'semi-ouvré',
    'sémitiques',
    'semonçâmes',
    'semonçasse',
    'semonçâtes',
    'semoncerai',
    'semonceras',
    'semoncerez',
    'semoncions',
    'semoulerie',
    'sénatorial',
    'sénégalais',
    'sénescence',
    'sénescente',
    'sénescents',
    'sensations',
    'sensitives',
    'sensoriels',
    'sensualité',
    'sensuelles',
    'sentiments',
    'sentinelle',
    'sentirions',
    'sentissent',
    'sentissiez',
    'sépaloïdes',
    'séparables',
    'séparaient',
    'séparantes',
    'séparasses',
    'séparateur',
    'séparation',
    'séparément',
    'séparerais',
    'séparerait',
    'séparèrent',
    'sépareriez',
    'séparerons',
    'sépareront',
    'septemvirs',
    'septénaire',
    'septennale',
    'septennats',
    'septennaux',
    'septicémie',
    'septicités',
    'septmoncel',
    'septuplais',
    'septuplait',
    'septuplant',
    'septuplées',
    'septuplent',
    'septuplera',
    'septupliez',
    'septuplons',
    'sépulcrale',
    'sépulcraux',
    'sépultures',
    'séquenceur',
    'séquentiel',
    'séquestrai',
    'séquestras',
    'séquestrât',
    'séquestrée',
    'séquestrer',
    'séquestres',
    'séquestrés',
    'séquestrez',
    'sérançâmes',
    'sérançasse',
    'sérançâtes',
    'sérancerai',
    'séranceras',
    'sérancerez',
    'sérancions',
    'séraphique',
    'serez-vous',
    'serfouette',
    'serfouîmes',
    'serfouirai',
    'serfouiras',
    'serfouirez',
    'serfouisse',
    'serfouîtes',
    'sérialisai',
    'sérialisas',
    'sérialisât',
    'sérialisée',
    'sérialiser',
    'sérialises',
    'sérialisés',
    'sérialisez',
    'sériassent',
    'sériassiez',
    'sériations',
    'séricicole',
    'séricigène',
    'sérierions',
    'serinaient',
    'serinasses',
    'serinerais',
    'serinerait',
    'serinèrent',
    'serineriez',
    'serinerons',
    'serineront',
    'seringuais',
    'seringuait',
    'seringuant',
    'seringuées',
    'seringuent',
    'seringuera',
    'seringuiez',
    'seringuons',
    'sermonnais',
    'sermonnait',
    'sermonnant',
    'sermonnées',
    'sermonnent',
    'sermonnera',
    'sermonneur',
    'sermonniez',
    'sermonnons',
    'sérologies',
    'seront-ils',
    'sérotonine',
    'serpentais',
    'serpentait',
    'serpentant',
    'serpenteau',
    'serpentées',
    'serpentent',
    'serpentera',
    'serpentiez',
    'serpentine',
    'serpentins',
    'serpentons',
    'serrassent',
    'serrassiez',
    'serratules',
    'serre-file',
    'serre-fils',
    'serrements',
    'serrerions',
    'serre-tête',
    'serrurerie',
    'serruriers',
    'sertirions',
    'sertissage',
    'sertissais',
    'sertissait',
    'sertissant',
    'sertissent',
    'sertisseur',
    'sertissiez',
    'sertissons',
    'sertissure',
    'serviables',
    'serviettes',
    'servilités',
    'servirions',
    'servissent',
    'servissiez',
    'serviteurs',
    'servitudes',
    'servofrein',
    'sésamoïdes',
    'sévèrement',
    'séviraient',
    'sévissions',
    'sevrassent',
    'sevrassiez',
    'sevrerions',
    'sexagésime',
    'sex-appeal',
    'sexologies',
    'sexologues',
    'sexonomies',
    'sextillion',
    'sextuplais',
    'sextuplait',
    'sextuplant',
    'sextuplées',
    'sextuplent',
    'sextuplera',
    'sextupliez',
    'sextuplons',
    'sexualisai',
    'sexualisas',
    'sexualisât',
    'sexualisée',
    'sexualiser',
    'sexualises',
    'sexualisés',
    'sexualisez',
    'sexualisme',
    'sexualités',
    'sgraffites',
    'shampoings',
    'shampooing',
    'shampouina',
    'shampouine',
    'shampouiné',
    'shantoungs',
    'shintoïsme',
    'shootaient',
    'shootasses',
    'shooterais',
    'shooterait',
    'shootèrent',
    'shooteriez',
    'shooterons',
    'shooteront',
    'shrapnells',
    'shuntaient',
    'shuntasses',
    'shunterais',
    'shunterait',
    'shuntèrent',
    'shunteriez',
    'shunterons',
    'shunteront',
    'sialagogue',
    'sialorrhée',
    'sibérienne',
    'sibilantes',
    'sibyllines',
    'siccatives',
    'sicilienne',
    'sidéraient',
    'sidérantes',
    'sidérasses',
    'sidérerais',
    'sidérerait',
    'sidérèrent',
    'sidéreriez',
    'sidérerons',
    'sidéreront',
    'sidérostat',
    'sidérurgie',
    'siégeaient',
    'siégeasses',
    'siégerions',
    'sifflaient',
    'sifflantes',
    'sifflasses',
    'sifflement',
    'sifflerais',
    'sifflerait',
    'sifflèrent',
    'siffleriez',
    'sifflerons',
    'siffleront',
    'siffleuses',
    'sifflotais',
    'sifflotait',
    'sifflotant',
    'sifflotées',
    'sifflotent',
    'sifflotera',
    'sifflotiez',
    'sifflotons',
    'sigillaire',
    'siglaisons',
    'signalâmes',
    'signalasse',
    'signalâtes',
    'signalerai',
    'signaleras',
    'signalerez',
    'signaleurs',
    'signalions',
    'signalisai',
    'signalisas',
    'signalisât',
    'signalisée',
    'signaliser',
    'signalises',
    'signalisés',
    'signalisez',
    'signassent',
    'signassiez',
    'signataire',
    'signatures',
    'signerions',
    'signifiais',
    'signifiait',
    'signifiant',
    'signifiées',
    'signifient',
    'signifiera',
    'signifiiez',
    'signifions',
    'silencieux',
    'silésienne',
    'silhouetta',
    'silhouette',
    'silhouetté',
    'silicatisa',
    'silicatise',
    'silicatisé',
    'siliceuses',
    'silicicole',
    'siliciques',
    'siliciures',
    'siliconais',
    'siliconait',
    'siliconant',
    'siliconées',
    'siliconent',
    'siliconera',
    'siliconiez',
    'siliconons',
    'sillonnais',
    'sillonnait',
    'sillonnant',
    'sillonnées',
    'sillonnent',
    'sillonnera',
    'sillonniez',
    'sillonnons',
    'silurienne',
    'simiesques',
    'similaires',
    'similarité',
    'similicuir',
    'similisage',
    'similisais',
    'similisait',
    'similisant',
    'similisées',
    'similisent',
    'similisera',
    'similisiez',
    'similisons',
    'similistes',
    'similitude',
    'simoniaque',
    'simplement',
    'simplettes',
    'simplicité',
    'simplifiai',
    'simplifias',
    'simplifiât',
    'simplifiée',
    'simplifier',
    'simplifies',
    'simplifiés',
    'simplifiez',
    'simplismes',
    'simplistes',
    'simulacres',
    'simulaient',
    'simulasses',
    'simulateur',
    'simulation',
    'simulerais',
    'simulerait',
    'simulèrent',
    'simuleriez',
    'simulerons',
    'simuleront',
    'simultanée',
    'simultanés',
    'sinapisées',
    'sinapismes',
    'sincérités',
    'sincipital',
    'singalette',
    'singeaient',
    'singeasses',
    'singerions',
    'singulière',
    'singuliers',
    'sinisaient',
    'sinisantes',
    'sinisasses',
    'sinisation',
    'siniserais',
    'siniserait',
    'sinisèrent',
    'siniseriez',
    'siniserons',
    'siniseront',
    'sinistrées',
    'sinologies',
    'sinologues',
    'sintérisai',
    'sintérisas',
    'sintérisât',
    'sintérisée',
    'sintériser',
    'sintérises',
    'sintérisés',
    'sintérisez',
    'sinuosités',
    'sinusoïdal',
    'sinusoïdes',
    'siphonnais',
    'siphonnait',
    'siphonnant',
    'siphonnées',
    'siphonnent',
    'siphonnera',
    'siphonniez',
    'siphonnons',
    'sirotaient',
    'sirotasses',
    'siroterais',
    'siroterait',
    'sirotèrent',
    'siroteriez',
    'siroterons',
    'siroteront',
    'sirupeuses',
    'sismicités',
    'sismologie',
    'situassent',
    'situassiez',
    'situations',
    'situerions',
    'skateboard',
    'skiascopie',
    'skiassions',
    'skieraient',
    'slalomâmes',
    'slalomasse',
    'slalomâtes',
    'slalomerai',
    'slalomeras',
    'slalomerez',
    'slalomeurs',
    'slalomeuse',
    'slalomions',
    'slavisâmes',
    'slavisasse',
    'slavisâtes',
    'slaviserai',
    'slaviseras',
    'slaviserez',
    'slavisions',
    'slavophile',
    'smaragdine',
    'smaragdins',
    'smaragdite',
    'smashaient',
    'smashasses',
    'smasherais',
    'smasherait',
    'smashèrent',
    'smasheriez',
    'smasherons',
    'smasheront',
    'smectiques',
    'smillaient',
    'smillasses',
    'smillerais',
    'smillerait',
    'smillèrent',
    'smilleriez',
    'smillerons',
    'smilleront',
    'snack-bars',
    'sniffaient',
    'sniffasses',
    'snifferais',
    'snifferait',
    'sniffèrent',
    'snifferiez',
    'snifferons',
    'snifferont',
    'snobassent',
    'snobassiez',
    'snoberions',
    'snobinarde',
    'snobinards',
    'snow-boots',
    'sobriquets',
    'socialisai',
    'socialisas',
    'socialisât',
    'socialisée',
    'socialiser',
    'socialises',
    'socialisés',
    'socialisez',
    'socialisme',
    'socialiste',
    'sociatries',
    'sociétaire',
    'sociodrame',
    'sociologie',
    'sociologue',
    'socquettes',
    'socratique',
    'socratisai',
    'socratisas',
    'socratisât',
    'socratiser',
    'socratises',
    'socratisez',
    'sodomisais',
    'sodomisait',
    'sodomisant',
    'sodomisées',
    'sodomisent',
    'sodomisera',
    'sodomisiez',
    'sodomisons',
    'soeurettes',
    'soi-disant',
    'soiffardes',
    'soignaient',
    'soignantes',
    'soignasses',
    'soignerais',
    'soignerait',
    'soignèrent',
    'soigneriez',
    'soignerons',
    'soigneront',
    'soigneuses',
    'solarisais',
    'solarisait',
    'solarisant',
    'solarisées',
    'solarisent',
    'solarisera',
    'solarisiez',
    'solarisons',
    'soldanelle',
    'soldassent',
    'soldassiez',
    'solderions',
    'solécismes',
    'solennelle',
    'solennisai',
    'solennisas',
    'solennisât',
    'solennisée',
    'solenniser',
    'solennises',
    'solennisés',
    'solennisez',
    'solennités',
    'solénoïdal',
    'solfatares',
    'solfiaient',
    'solfiasses',
    'solfierais',
    'solfierait',
    'solfièrent',
    'solfieriez',
    'solfierons',
    'solfieront',
    'solidaires',
    'solidarisa',
    'solidarise',
    'solidarisé',
    'solidarité',
    'solidement',
    'solidifiai',
    'solidifias',
    'solidifiât',
    'solidifiée',
    'solidifier',
    'solidifies',
    'solidifiés',
    'solidifiez',
    'solifluais',
    'solifluait',
    'solifluant',
    'solifluent',
    'solifluera',
    'solifluiez',
    'solifluons',
    'soliloquai',
    'soliloquas',
    'soliloquât',
    'soliloquer',
    'soliloques',
    'soliloquez',
    'solipsisme',
    'solitaires',
    'sollicitai',
    'sollicitas',
    'sollicitât',
    'sollicitée',
    'solliciter',
    'sollicites',
    'sollicités',
    'sollicitez',
    'solmisâmes',
    'solmisasse',
    'solmisâtes',
    'solmiserai',
    'solmiseras',
    'solmiserez',
    'solmisions',
    'solsticial',
    'solubilisa',
    'solubilise',
    'solubilisé',
    'solubilité',
    'solutionna',
    'solutionne',
    'solutionné',
    'solutréens',
    'somatiques',
    'somatisais',
    'somatisait',
    'somatisant',
    'somatisées',
    'somatisent',
    'somatisera',
    'somatisiez',
    'somatisons',
    'sombraient',
    'sombrasses',
    'sombrement',
    'sombrerais',
    'sombrerait',
    'sombrèrent',
    'sombreriez',
    'sombrerons',
    'sombreront',
    'sommassent',
    'sommassiez',
    'sommations',
    'sommeillai',
    'sommeillas',
    'sommeillât',
    'sommeiller',
    'sommeilles',
    'sommeillez',
    'sommelière',
    'sommeliers',
    'sommerions',
    'sommitales',
    'somnambule',
    'somnifères',
    'somnolâmes',
    'somnolante',
    'somnolants',
    'somnolasse',
    'somnolâtes',
    'somnolence',
    'somnolente',
    'somnolents',
    'somnolerai',
    'somnoleras',
    'somnolerez',
    'somnolions',
    'somptuaire',
    'somptueuse',
    'sonagraphe',
    'sondassent',
    'sondassiez',
    'sonderions',
    'songeaient',
    'songeasses',
    'songerions',
    'sonnaillai',
    'sonnaillas',
    'sonnaillât',
    'sonnaillée',
    'sonnailler',
    'sonnailles',
    'sonnaillés',
    'sonnaillez',
    'sonnassent',
    'sonnassiez',
    'sonnerions',
    'sonomètres',
    'sonorisais',
    'sonorisait',
    'sonorisant',
    'sonorisées',
    'sonorisent',
    'sonorisera',
    'sonorisiez',
    'sonorisons',
    'sonothèque',
    'sont-elles',
    'sopraniste',
    'sorbetière',
    'sorbonnard',
    'sordidités',
    'sorguaient',
    'sorguasses',
    'sorguerais',
    'sorguerait',
    'sorguèrent',
    'sorgueriez',
    'sorguerons',
    'sorgueront',
    'sortilèges',
    'sortirions',
    'sortissent',
    'sortissiez',
    'sottisiers',
    'soubresaut',
    'soubrettes',
    'souchetais',
    'souchetait',
    'souchetant',
    'souchetées',
    'souchetiez',
    'souchetons',
    'souchettes',
    'souchevais',
    'souchevait',
    'souchevant',
    'souchevées',
    'souchèvent',
    'souchèvera',
    'soucheviez',
    'souchevons',
    'souciaient',
    'souciasses',
    'soucierais',
    'soucierait',
    'soucièrent',
    'soucieriez',
    'soucierons',
    'soucieront',
    'soucieuses',
    'soudaineté',
    'soudanaise',
    'soudassent',
    'soudassiez',
    'souderions',
    'soudoierai',
    'soudoieras',
    'soudoierez',
    'soudoyâmes',
    'soudoyasse',
    'soudoyâtes',
    'soudoyions',
    'souffertes',
    'soufflages',
    'soufflâmes',
    'soufflante',
    'soufflants',
    'soufflards',
    'soufflasse',
    'soufflâtes',
    'soufflerai',
    'souffleras',
    'soufflerez',
    'soufflerie',
    'souffletai',
    'souffletas',
    'souffletât',
    'souffletée',
    'souffleter',
    'souffletés',
    'souffletez',
    'soufflette',
    'souffleurs',
    'souffleuse',
    'soufflions',
    'soufflures',
    'souffrance',
    'souffrante',
    'souffrants',
    'souffrîmes',
    'souffrions',
    'souffrirai',
    'souffriras',
    'souffrirez',
    'souffrisse',
    'souffrîtes',
    'soufraient',
    'soufrasses',
    'soufrerais',
    'soufrerait',
    'soufrèrent',
    'soufreriez',
    'soufrerons',
    'soufreront',
    'soufreuses',
    'soufrières',
    'souhaitais',
    'souhaitait',
    'souhaitant',
    'souhaitées',
    'souhaitent',
    'souhaitera',
    'souhaitiez',
    'souhaitons',
    'souillâmes',
    'souillarde',
    'souillards',
    'souillasse',
    'souillâtes',
    'souillerai',
    'souilleras',
    'souillerez',
    'souillions',
    'souillures',
    'souï-manga',
    'soulageais',
    'soulageait',
    'soulageant',
    'soulageons',
    'soulagerai',
    'soulageras',
    'soulagerez',
    'soulagions',
    'soûlassent',
    'soûlassiez',
    'soûlerions',
    'soulevâmes',
    'soulevasse',
    'soulevâtes',
    'soulèverai',
    'soulèveras',
    'soulèverez',
    'soulevions',
    'soulignage',
    'soulignais',
    'soulignait',
    'soulignant',
    'soulignées',
    'soulignent',
    'soulignera',
    'souligniez',
    'soulignons',
    'soumettais',
    'soumettait',
    'soumettant',
    'soumettent',
    'soumettiez',
    'soumettons',
    'soumettrai',
    'soumettras',
    'soumettrez',
    'soumissent',
    'soumissiez',
    'soumission',
    'soupassent',
    'soupassiez',
    'soupçonnai',
    'soupçonnas',
    'soupçonnât',
    'soupçonnée',
    'soupçonner',
    'soupçonnes',
    'soupçonnés',
    'soupçonnez',
    'souperions',
    'soupesâmes',
    'soupesasse',
    'soupesâtes',
    'soupèserai',
    'soupèseras',
    'soupèserez',
    'soupesions',
    'soupirâmes',
    'soupirante',
    'soupirants',
    'soupirasse',
    'soupirâtes',
    'soupirerai',
    'soupireras',
    'soupirerez',
    'soupirions',
    'souplement',
    'souplesses',
    'souquaient',
    'souquasses',
    'souquerais',
    'souquerait',
    'souquèrent',
    'souqueriez',
    'souquerons',
    'souqueront',
    'sourcières',
    'sourcilier',
    'sourcillai',
    'sourcillas',
    'sourcillât',
    'sourciller',
    'sourcilles',
    'sourcillez',
    'sourdement',
    'sourdinais',
    'sourdinait',
    'sourdinant',
    'sourdinées',
    'sourdinent',
    'sourdinera',
    'sourdingue',
    'sourdiniez',
    'sourdinons',
    'sourd-muet',
    'souriaient',
    'souriantes',
    'souriceaux',
    'souricière',
    'souririons',
    'sourissent',
    'sourissiez',
    'sournoises',
    'sous-aides',
    'sous-barbe',
    'sous-chefs',
    'souscrirai',
    'souscriras',
    'souscrirez',
    'souscrites',
    'souscrives',
    'souscrivez',
    'souscrivis',
    'souscrivit',
    'souscrivît',
    'sous-fifre',
    'sous-garde',
    'sous-genre',
    'sous-homme',
    'sous-louai',
    'sous-louas',
    'sous-louât',
    'sous-louée',
    'sous-louer',
    'sous-loues',
    'sous-loués',
    'sous-louez',
    'sous-marin',
    'sous-nappe',
    'sous-ordre',
    'sous-paies',
    'sous-palan',
    'sous-payai',
    'sous-payas',
    'sous-payât',
    'sous-payée',
    'sous-payer',
    'sous-payes',
    'sous-payés',
    'sous-payez',
    'sous-pieds',
    'sous-pulls',
    'sous-seing',
    'soussignée',
    'soussignés',
    'sous-tasse',
    'sous-tende',
    'sous-tends',
    'sous-tendu',
    'sous-titra',
    'sous-titre',
    'sous-titré',
    'soustraies',
    'soustraira',
    'soustraire',
    'soustraite',
    'soustraits',
    'soustrayez',
    'sous-verge',
    'sous-verre',
    'sous-virai',
    'sous-viras',
    'sous-virât',
    'sous-virer',
    'sous-vires',
    'sous-virez',
    'soutachais',
    'soutachait',
    'soutachant',
    'soutachées',
    'soutachent',
    'soutachera',
    'soutachiez',
    'soutachons',
    'soutanelle',
    'soutenable',
    'soutenance',
    'souteneurs',
    'soutenions',
    'souterrain',
    'soutiendra',
    'soutiennes',
    'soutinrent',
    'soutinsses',
    'soutirages',
    'soutirâmes',
    'soutirasse',
    'soutirâtes',
    'soutirerai',
    'soutireras',
    'soutirerez',
    'soutirions',
    'souvenance',
    'souvenions',
    'souveraine',
    'souverains',
    'souviendra',
    'souviennes',
    'souvinrent',
    'souvinsses',
    'soviétique',
    'soviétisai',
    'soviétisas',
    'soviétisât',
    'soviétisée',
    'soviétiser',
    'soviétises',
    'soviétisés',
    'soviétisez',
    'spacieuses',
    'spadassins',
    'spaghettis',
    'spallation',
    'sparadraps',
    'sparganier',
    'spartéines',
    'sparteries',
    'spartiates',
    'spatangues',
    'spathifiai',
    'spathifias',
    'spathifiât',
    'spathifiée',
    'spathifier',
    'spathifies',
    'spathifiés',
    'spathifiez',
    'spathiques',
    'spatialisa',
    'spatialise',
    'spatialisé',
    'spatialité',
    'spécialisa',
    'spécialise',
    'spécialisé',
    'spécialité',
    'spécieuses',
    'spécifiais',
    'spécifiait',
    'spécifiant',
    'spécifiées',
    'spécifient',
    'spécifiera',
    'spécifiiez',
    'spécifions',
    'spécifique',
    'spéciosité',
    'spectacles',
    'spectateur',
    'spectrales',
    'spéculaire',
    'spéculâmes',
    'spéculasse',
    'spéculâtes',
    'spéculatif',
    'spéculerai',
    'spéculeras',
    'spéculerez',
    'spéculions',
    'spermaceti',
    'spermatide',
    'spermaties',
    'spermicide',
    'sphacélais',
    'sphacélait',
    'sphacélant',
    'sphacélées',
    'sphacèlent',
    'sphacélera',
    'sphacéliez',
    'sphacélons',
    'sphénoïdal',
    'sphénoïdes',
    'sphéricité',
    'sphériques',
    'sphéroïdal',
    'sphéroïdes',
    'sphincters',
    'spinnakers',
    'spinozisme',
    'spinoziste',
    'spirillose',
    'spiritains',
    'spiritisme',
    'spiritiste',
    'spirituals',
    'spirituels',
    'spiritueux',
    'spirochète',
    'spiroïdale',
    'spiroïdaux',
    'spiromètre',
    'spittaient',
    'spittasses',
    'spitterais',
    'spitterait',
    'spittèrent',
    'spitteriez',
    'spitterons',
    'spitteront',
    'splendeurs',
    'splendides',
    'splittâmes',
    'splittasse',
    'splittâtes',
    'splitterai',
    'splitteras',
    'splitterez',
    'splittions',
    'spoliaient',
    'spoliasses',
    'spoliateur',
    'spoliation',
    'spolierais',
    'spolierait',
    'spolièrent',
    'spolieriez',
    'spolierons',
    'spolieront',
    'spongieuse',
    'spongilles',
    'sponsorisa',
    'sponsorise',
    'sponsorisé',
    'spontanées',
    'sporadique',
    'sporogones',
    'sporophyte',
    'sportivité',
    'sporulâmes',
    'sporulasse',
    'sporulâtes',
    'sporulerai',
    'sporuleras',
    'sporulerez',
    'sporulions',
    'sprintâmes',
    'sprintasse',
    'sprintâtes',
    'sprinterai',
    'sprinteras',
    'sprinterez',
    'sprintions',
    'spumosités',
    'squameuses',
    'squamifère',
    'squattâmes',
    'squattasse',
    'squattâtes',
    'squatterai',
    'squatteras',
    'squatterez',
    'squattions',
    'squelettes',
    'squirreuse',
    'squirrheux',
    'stabilisai',
    'stabilisas',
    'stabilisât',
    'stabilisée',
    'stabiliser',
    'stabilises',
    'stabilisés',
    'stabilisez',
    'stabilités',
    'stadhouder',
    'staffaient',
    'staffasses',
    'stafferais',
    'stafferait',
    'staffèrent',
    'stafferiez',
    'stafferons',
    'stafferont',
    'stagiaires',
    'stagnaient',
    'stagnantes',
    'stagnasses',
    'stagnation',
    'stagnerais',
    'stagnerait',
    'stagnèrent',
    'stagneriez',
    'stagnerons',
    'stagneront',
    'stalactite',
    'stalagmite',
    'staliniens',
    'stalinisai',
    'stalinisas',
    'stalinisât',
    'stalinisée',
    'staliniser',
    'stalinises',
    'stalinisés',
    'stalinisez',
    'stalinisme',
    'staminales',
    'staphyline',
    'staphylins',
    'staphylome',
    'starifiais',
    'starifiait',
    'starifiant',
    'starifiées',
    'starifient',
    'starifiera',
    'starifiiez',
    'starifions',
    'starisâmes',
    'starisasse',
    'starisâtes',
    'stariserai',
    'stariseras',
    'stariserez',
    'starisions',
    'starlettes',
    'stathouder',
    'stationnai',
    'stationnas',
    'stationnât',
    'stationnée',
    'stationner',
    'stationnes',
    'stationnés',
    'stationnez',
    'statuaient',
    'statuaires',
    'statuasses',
    'statuerais',
    'statuerait',
    'statuèrent',
    'statueriez',
    'statuerons',
    'statueront',
    'statuettes',
    'statufiais',
    'statufiait',
    'statufiant',
    'statufiées',
    'statufient',
    'statufiera',
    'statufiiez',
    'statufions',
    'statutaire',
    'stéarinier',
    'stéariques',
    'stéatopyge',
    'stégomyies',
    'stégosaure',
    'steinbocks',
    'stellaires',
    'sténosages',
    'sténotypai',
    'sténotypas',
    'sténotypât',
    'sténotypée',
    'sténotyper',
    'sténotypes',
    'sténotypés',
    'sténotypez',
    'sténotypie',
    'steppiques',
    'stéradians',
    'stérassent',
    'stérassiez',
    'stercorale',
    'stercoraux',
    'stéréobate',
    'stéréoducs',
    'stéréotypa',
    'stéréotype',
    'stéréotypé',
    'stérerions',
    'stérilisai',
    'stérilisas',
    'stérilisât',
    'stérilisée',
    'stériliser',
    'stérilises',
    'stérilisés',
    'stérilisez',
    'stériliste',
    'stérilités',
    'stertoreux',
    'stigmatisa',
    'stigmatise',
    'stigmatisé',
    'stillation',
    'stimugènes',
    'stimulâmes',
    'stimulante',
    'stimulants',
    'stimulasse',
    'stimulâtes',
    'stimulerai',
    'stimuleras',
    'stimulerez',
    'stimulines',
    'stimulions',
    'stipendiai',
    'stipendias',
    'stipendiât',
    'stipendiée',
    'stipendier',
    'stipendies',
    'stipendiés',
    'stipendiez',
    'stipulaire',
    'stipulâmes',
    'stipulasse',
    'stipulâtes',
    'stipulerai',
    'stipuleras',
    'stipulerez',
    'stipulions',
    'stockaient',
    'stockasses',
    'stock-cars',
    'stockerais',
    'stockerait',
    'stockèrent',
    'stockeriez',
    'stockerons',
    'stockeront',
    'stockfisch',
    'stockistes',
    'stoïcienne',
    'stoïcismes',
    'stomacales',
    'stomatites',
    'stoppaient',
    'stoppasses',
    'stopperais',
    'stopperait',
    'stoppèrent',
    'stopperiez',
    'stopperons',
    'stopperont',
    'stoppeuses',
    'strabiques',
    'strabismes',
    'stramoines',
    'stramonium',
    'strangulai',
    'strangulas',
    'strangulât',
    'strangulée',
    'stranguler',
    'strangules',
    'strangulés',
    'strangulez',
    'strapontin',
    'stratagème',
    'stratégies',
    'stratifiai',
    'stratifias',
    'stratifiât',
    'stratifiée',
    'stratifier',
    'stratifies',
    'stratifiés',
    'stratifiez',
    'stratiomes',
    'stratiomys',
    'stressâmes',
    'stressante',
    'stressants',
    'stressasse',
    'stressâtes',
    'stresserai',
    'stresseras',
    'stresserez',
    'stressions',
    'striassent',
    'striassiez',
    'striations',
    'strictions',
    'stridences',
    'stridentes',
    'stridulais',
    'stridulait',
    'stridulant',
    'stridulées',
    'stridulent',
    'stridulera',
    'striduleux',
    'striduliez',
    'stridulons',
    'strierions',
    'strippâmes',
    'strippasse',
    'strippâtes',
    'stripperai',
    'stripperas',
    'stripperez',
    'strippings',
    'strippions',
    'striquâmes',
    'striquasse',
    'striquâtes',
    'striquerai',
    'striqueras',
    'striquerez',
    'striquions',
    'stroborama',
    'strongyles',
    'strontiane',
    'strontiums',
    'strophante',
    'structurai',
    'structural',
    'structuras',
    'structurât',
    'structurée',
    'structurel',
    'structurer',
    'structures',
    'structurés',
    'structurez',
    'strychnine',
    'stucateurs',
    'studieuses',
    'stupéfaite',
    'stupéfaits',
    'stupéfiais',
    'stupéfiait',
    'stupéfiant',
    'stupéfiées',
    'stupéfient',
    'stupéfiera',
    'stupéfiiez',
    'stupéfions',
    'stupidités',
    'stuquaient',
    'stuquasses',
    'stuquerais',
    'stuquerait',
    'stuquèrent',
    'stuqueriez',
    'stuquerons',
    'stuqueront',
    'stylassent',
    'stylassiez',
    'stylerions',
    'stylisâmes',
    'stylisasse',
    'stylisâtes',
    'styliserai',
    'styliseras',
    'styliserez',
    'stylisions',
    'stylobates',
    'stylomines',
    'styptiques',
    'styrolènes',
    'subalpines',
    'subalterne',
    'subdélégua',
    'subdélégué',
    'subdélègue',
    'subdivisai',
    'subdivisas',
    'subdivisât',
    'subdivisée',
    'subdiviser',
    'subdivises',
    'subdivisés',
    'subdivisez',
    'subduction',
    'subéreuses',
    'subfébrile',
    'subintrant',
    'subiraient',
    'subissions',
    'subitement',
    'subjacente',
    'subjacents',
    'subjectifs',
    'subjectile',
    'subjective',
    'subjonctif',
    'subjuguais',
    'subjuguait',
    'subjuguant',
    'subjuguées',
    'subjuguent',
    'subjuguera',
    'subjuguiez',
    'subjuguons',
    'sublimâmes',
    'sublimasse',
    'sublimâtes',
    'sublimerai',
    'sublimeras',
    'sublimerez',
    'subliminal',
    'sublimions',
    'sublimités',
    'sublingual',
    'sublunaire',
    'submergeai',
    'submergeas',
    'submergeât',
    'submergées',
    'submergent',
    'submergera',
    'submergiez',
    'submersion',
    'subnarcose',
    'subodorais',
    'subodorait',
    'subodorant',
    'subodorées',
    'subodorent',
    'subodorera',
    'subodoriez',
    'subodorons',
    'subordonna',
    'subordonne',
    'subordonné',
    'subornâmes',
    'subornasse',
    'subornâtes',
    'subornerai',
    'suborneras',
    'subornerez',
    'suborneurs',
    'suborneuse',
    'subornions',
    'subreptice',
    'subreption',
    'subrogatif',
    'subrogeais',
    'subrogeait',
    'subrogeant',
    'subrogeons',
    'subrogerai',
    'subrogeras',
    'subrogerez',
    'subrogions',
    'subséquent',
    'subsidence',
    'subsistais',
    'subsistait',
    'subsistant',
    'subsistent',
    'subsistera',
    'subsistiez',
    'subsistons',
    'subsonique',
    'substances',
    'substantif',
    'substituai',
    'substituas',
    'substituât',
    'substituée',
    'substituer',
    'substitues',
    'substitués',
    'substituez',
    'substituts',
    'substratum',
    'subterfuge',
    'subtilisai',
    'subtilisas',
    'subtilisât',
    'subtilisée',
    'subtiliser',
    'subtilises',
    'subtilisés',
    'subtilisez',
    'subtilités',
    'suburbaine',
    'suburbains',
    'subvenions',
    'subvention',
    'subversifs',
    'subversion',
    'subversive',
    'subverties',
    'subvertira',
    'subviendra',
    'subviennes',
    'subvinrent',
    'subvinsses',
    'suçassions',
    'succédâmes',
    'succédanée',
    'succédanés',
    'succédasse',
    'succédâtes',
    'succéderai',
    'succéderas',
    'succéderez',
    'succédions',
    'successeur',
    'successifs',
    'succession',
    'successive',
    'succinctes',
    'succinique',
    'succombais',
    'succombait',
    'succombant',
    'succombent',
    'succombera',
    'succombiez',
    'succombons',
    'succulence',
    'succulente',
    'succulents',
    'succursale',
    'suceraient',
    'suçotaient',
    'suçotasses',
    'suçoterais',
    'suçoterait',
    'suçotèrent',
    'suçoteriez',
    'suçoterons',
    'suçoteront',
    'sucrassent',
    'sucrassiez',
    'sucrerions',
    'sudatoires',
    'sud-coréen',
    'sudorifère',
    'sudoripare',
    'suffirions',
    'suffisance',
    'suffisante',
    'suffisants',
    'suffisions',
    'suffissent',
    'suffissiez',
    'suffixales',
    'suffixâmes',
    'suffixasse',
    'suffixâtes',
    'suffixerai',
    'suffixeras',
    'suffixerez',
    'suffixions',
    'suffocante',
    'suffocants',
    'suffoquais',
    'suffoquait',
    'suffoquant',
    'suffoquées',
    'suffoquent',
    'suffoquera',
    'suffoquiez',
    'suffoquons',
    'suffragant',
    'suffusions',
    'suggérâmes',
    'suggérasse',
    'suggérâtes',
    'suggérerai',
    'suggéreras',
    'suggérerez',
    'suggérions',
    'suggestifs',
    'suggestion',
    'suggestive',
    'suicidaire',
    'suicidâmes',
    'suicidasse',
    'suicidâtes',
    'suiciderai',
    'suicideras',
    'suiciderez',
    'suicidions',
    'suifassent',
    'suifassiez',
    'suiferions',
    'suiffaient',
    'suiffasses',
    'suifferais',
    'suifferait',
    'suiffèrent',
    'suifferiez',
    'suifferons',
    'suifferont',
    'suiffeuses',
    'suintaient',
    'suintantes',
    'suintasses',
    'suintement',
    'suinterais',
    'suinterait',
    'suintèrent',
    'suinteriez',
    'suinterons',
    'suinteront',
    'suissesses',
    'suivissent',
    'suivissiez',
    'suivraient',
    'sulcatures',
    'sulciforme',
    'sulfamides',
    'sulfatages',
    'sulfatâmes',
    'sulfatasse',
    'sulfatâtes',
    'sulfaterai',
    'sulfateras',
    'sulfaterez',
    'sulfateurs',
    'sulfateuse',
    'sulfations',
    'sulfitages',
    'sulfitâmes',
    'sulfitasse',
    'sulfitâtes',
    'sulfiterai',
    'sulfiteras',
    'sulfiterez',
    'sulfitions',
    'sulfonâmes',
    'sulfonasse',
    'sulfonâtes',
    'sulfonerai',
    'sulfoneras',
    'sulfonerez',
    'sulfonions',
    'sulfurages',
    'sulfurâmes',
    'sulfurasse',
    'sulfurâtes',
    'sulfurerai',
    'sulfureras',
    'sulfurerez',
    'sulfureuse',
    'sulfurions',
    'sulfurique',
    'sulfurisée',
    'sulfurisés',
    'sulpiciens',
    'sumérienne',
    'superbombe',
    'supercarré',
    'supérettes',
    'superficie',
    'superfines',
    'superfinie',
    'superfinir',
    'superfinis',
    'superfinit',
    'superfinît',
    'superflues',
    'supergrand',
    'supérieure',
    'supérieurs',
    'superlatif',
    'supernovae',
    'superposai',
    'superposas',
    'superposât',
    'superposée',
    'superposer',
    'superposes',
    'superposés',
    'superposez',
    'superstars',
    'superstrat',
    'supervisai',
    'supervisas',
    'supervisât',
    'supervisée',
    'superviser',
    'supervises',
    'supervisés',
    'supervisez',
    'supinateur',
    'supination',
    'supplantai',
    'supplantas',
    'supplantât',
    'supplantée',
    'supplanter',
    'supplantes',
    'supplantés',
    'supplantez',
    'suppléâmes',
    'suppléance',
    'suppléante',
    'suppléants',
    'suppléasse',
    'suppléâtes',
    'suppléerai',
    'suppléeras',
    'suppléerez',
    'suppléions',
    'supplément',
    'supplétifs',
    'supplétive',
    'suppliâmes',
    'suppliante',
    'suppliants',
    'suppliasse',
    'suppliâtes',
    'suppliciai',
    'supplicias',
    'suppliciât',
    'suppliciée',
    'supplicier',
    'supplicies',
    'suppliciés',
    'suppliciez',
    'supplierai',
    'supplieras',
    'supplierez',
    'suppliions',
    'suppliques',
    'supportais',
    'supportait',
    'supportant',
    'supportées',
    'supportent',
    'supportera',
    'supporters',
    'supportiez',
    'supportons',
    'supposable',
    'supposâmes',
    'supposasse',
    'supposâtes',
    'supposerai',
    'supposeras',
    'supposerez',
    'supposions',
    'supprimais',
    'supprimait',
    'supprimant',
    'supprimées',
    'suppriment',
    'supprimera',
    'supprimiez',
    'supprimons',
    'suppurâmes',
    'suppurante',
    'suppurants',
    'suppurasse',
    'suppurâtes',
    'suppuratif',
    'suppurerai',
    'suppureras',
    'suppurerez',
    'suppurions',
    'supputâmes',
    'supputasse',
    'supputâtes',
    'supputerai',
    'supputeras',
    'supputerez',
    'supputions',
    'suprématie',
    'surabondai',
    'surabondas',
    'surabondât',
    'surabonder',
    'surabondes',
    'surabondez',
    'suractivée',
    'suractivés',
    'surajoutai',
    'surajoutas',
    'surajoutât',
    'surajoutée',
    'surajouter',
    'surajoutes',
    'surajoutés',
    'surajoutez',
    'surarbitre',
    'surbaissai',
    'surbaissas',
    'surbaissât',
    'surbaissée',
    'surbaisser',
    'surbaisses',
    'surbaissés',
    'surbaissez',
    'surchargea',
    'surchargée',
    'surcharger',
    'surcharges',
    'surchargés',
    'surchargez',
    'surchauffa',
    'surchauffe',
    'surchauffé',
    'surclassai',
    'surclassas',
    'surclassât',
    'surclassée',
    'surclasser',
    'surclasses',
    'surclassés',
    'surclassez',
    'surcomposé',
    'surcontrai',
    'surcontras',
    'surcontrât',
    'surcontrée',
    'surcontrer',
    'surcontres',
    'surcontrés',
    'surcontrez',
    'surcoupais',
    'surcoupait',
    'surcoupant',
    'surcoupées',
    'surcoupent',
    'surcoupera',
    'surcoupiez',
    'surcoupons',
    'surdorâmes',
    'surdorasse',
    'surdorâtes',
    'surdorerai',
    'surdoreras',
    'surdorerez',
    'surdorions',
    'surdosages',
    'surédifiai',
    'surédifias',
    'surédifiât',
    'surédifiée',
    'surédifier',
    'surédifies',
    'surédifiés',
    'surédifiez',
    'surélevais',
    'surélevait',
    'surélevant',
    'surélevées',
    'surélèvent',
    'surélèvera',
    'suréleviez',
    'surélevons',
    'suremplois',
    'surenchère',
    'surenchéri',
    'suréquipai',
    'suréquipas',
    'suréquipât',
    'suréquipée',
    'suréquiper',
    'suréquipes',
    'suréquipés',
    'suréquipez',
    'surestimai',
    'surestimas',
    'surestimât',
    'surestimée',
    'surestimer',
    'surestimes',
    'surestimés',
    'surestimez',
    'surévaluai',
    'surévaluas',
    'surévaluât',
    'surévaluée',
    'surévaluer',
    'surévalues',
    'surévalués',
    'surévaluez',
    'surexcitai',
    'surexcitas',
    'surexcitât',
    'surexcitée',
    'surexciter',
    'surexcites',
    'surexcités',
    'surexcitez',
    'surexposai',
    'surexposas',
    'surexposât',
    'surexposée',
    'surexposer',
    'surexposes',
    'surexposés',
    'surexposez',
    'surfaçâmes',
    'surfaçasse',
    'surfaçâtes',
    'surfacerai',
    'surfaceras',
    'surfacerez',
    'surfacions',
    'surfaisais',
    'surfaisait',
    'surfaisant',
    'surfaisiez',
    'surfaisons',
    'surfassent',
    'surfassiez',
    'surferions',
    'surfilages',
    'surfilâmes',
    'surfilasse',
    'surfilâtes',
    'surfilerai',
    'surfileras',
    'surfilerez',
    'surfilions',
    'surfissent',
    'surfissiez',
    'surfondues',
    'surfusions',
    'surgelâmes',
    'surgelasse',
    'surgelâtes',
    'surgèlerai',
    'surgèleras',
    'surgèlerez',
    'surgelions',
    'surgeonnai',
    'surgeonnas',
    'surgeonnât',
    'surgeonner',
    'surgeonnes',
    'surgeonnez',
    'surgirions',
    'surgissais',
    'surgissait',
    'surgissant',
    'surgissent',
    'surgissiez',
    'surgissons',
    'surglaçais',
    'surglaçait',
    'surglaçant',
    'surglacées',
    'surglacent',
    'surglacera',
    'surglaciez',
    'surglaçons',
    'surhaussai',
    'surhaussas',
    'surhaussât',
    'surhaussée',
    'surhausser',
    'surhausses',
    'surhaussés',
    'surhaussez',
    'surhumaine',
    'surhumains',
    'surimposai',
    'surimposas',
    'surimposât',
    'surimposée',
    'surimposer',
    'surimposes',
    'surimposés',
    'surimposez',
    'surinaient',
    'surinasses',
    'surinerais',
    'surinerait',
    'surinèrent',
    'surineriez',
    'surinerons',
    'surineront',
    'suriraient',
    'surissions',
    'surjalâmes',
    'surjalasse',
    'surjalâtes',
    'surjalerai',
    'surjaleras',
    'surjalerez',
    'surjalions',
    'surjetâmes',
    'surjetasse',
    'surjetâtes',
    'surjetions',
    'surjettent',
    'surjettera',
    'surliaient',
    'surliasses',
    'surlierais',
    'surlierait',
    'surlièrent',
    'surlieriez',
    'surlierons',
    'surlieront',
    'surlouâmes',
    'surlouasse',
    'surlouâtes',
    'surlouerai',
    'surloueras',
    'surlouerez',
    'surlouions',
    'surmenages',
    'surmenâmes',
    'surmenasse',
    'surmenâtes',
    'surmènerai',
    'surmèneras',
    'surmènerez',
    'surmenions',
    'surmontais',
    'surmontait',
    'surmontant',
    'surmontées',
    'surmontent',
    'surmontera',
    'surmontiez',
    'surmontoir',
    'surmontons',
    'surmoulage',
    'surmoulais',
    'surmoulait',
    'surmoulant',
    'surmoulées',
    'surmoulent',
    'surmoulera',
    'surmouliez',
    'surmoulons',
    'surnageais',
    'surnageait',
    'surnageant',
    'surnageons',
    'surnagerai',
    'surnageras',
    'surnagerez',
    'surnagions',
    'surnaturel',
    'surnombres',
    'surnommais',
    'surnommait',
    'surnommant',
    'surnommées',
    'surnomment',
    'surnommera',
    'surnommiez',
    'surnommons',
    'suroxydais',
    'suroxydait',
    'suroxydant',
    'suroxydées',
    'suroxydent',
    'suroxydera',
    'suroxydiez',
    'suroxydons',
    'suroxygéné',
    'surpaierai',
    'surpaieras',
    'surpaierez',
    'surpassais',
    'surpassait',
    'surpassant',
    'surpassées',
    'surpassent',
    'surpassera',
    'surpassiez',
    'surpassons',
    'surpayâmes',
    'surpayasse',
    'surpayâtes',
    'surpayerai',
    'surpayeras',
    'surpayerez',
    'surpayions',
    'surpeuplée',
    'surpeuplés',
    'surpiquais',
    'surpiquait',
    'surpiquant',
    'surpiquées',
    'surpiquent',
    'surpiquera',
    'surpiquiez',
    'surpiquons',
    'surpiqûres',
    'surplombai',
    'surplombas',
    'surplombât',
    'surplombée',
    'surplomber',
    'surplombes',
    'surplombés',
    'surplombez',
    'surprenais',
    'surprenait',
    'surprenant',
    'surprendra',
    'surprendre',
    'surpreniez',
    'surprennes',
    'surprenons',
    'surprirent',
    'surprisses',
    'surprotégé',
    'surprotège',
    'surrection',
    'surrénales',
    'sursalaire',
    'sursaturai',
    'sursaturas',
    'sursaturât',
    'sursaturée',
    'sursaturer',
    'sursatures',
    'sursaturés',
    'sursaturez',
    'sursautais',
    'sursautait',
    'sursautant',
    'sursautent',
    'sursautera',
    'sursautiez',
    'sursautons',
    'sursemâmes',
    'sursemasse',
    'sursemâtes',
    'sursèmerai',
    'sursèmeras',
    'sursèmerez',
    'sursemions',
    'surseoirai',
    'surseoiras',
    'surseoirez',
    'sursissent',
    'sursissiez',
    'sursitaire',
    'sursoyions',
    'surtaxâmes',
    'surtaxasse',
    'surtaxâtes',
    'surtaxerai',
    'surtaxeras',
    'surtaxerez',
    'surtaxions',
    'surtension',
    'survécûmes',
    'survécusse',
    'survécûtes',
    'surveillai',
    'surveillas',
    'surveillât',
    'surveillée',
    'surveiller',
    'surveilles',
    'surveillés',
    'surveillez',
    'survenance',
    'survenions',
    'surviendra',
    'surviennes',
    'survinrent',
    'survinsses',
    'survirages',
    'survirâmes',
    'survirasse',
    'survirâtes',
    'survirerai',
    'survireras',
    'survirerez',
    'survireurs',
    'survireuse',
    'survirions',
    'survivance',
    'survivante',
    'survivants',
    'survivions',
    'survivrais',
    'survivrait',
    'survivriez',
    'survivrons',
    'survivront',
    'survolâmes',
    'survolasse',
    'survolâtes',
    'survolerai',
    'survoleras',
    'survolerez',
    'survolions',
    'survoltage',
    'survoltais',
    'survoltait',
    'survoltant',
    'survoltées',
    'survoltent',
    'survoltera',
    'survolteur',
    'survoltiez',
    'survoltons',
    'suscitâmes',
    'suscitasse',
    'suscitâtes',
    'susciterai',
    'susciteras',
    'susciterez',
    'suscitions',
    'susnommées',
    'suspectais',
    'suspectait',
    'suspectant',
    'suspectées',
    'suspectent',
    'suspectera',
    'suspectiez',
    'suspectons',
    'suspendais',
    'suspendait',
    'suspendant',
    'suspendent',
    'suspendiez',
    'suspendons',
    'suspendrai',
    'suspendras',
    'suspendrez',
    'suspendues',
    'suspenseur',
    'suspensifs',
    'suspension',
    'suspensive',
    'suspensoir',
    'suspicieux',
    'suspicions',
    'sustentais',
    'sustentait',
    'sustentant',
    'sustentées',
    'sustentent',
    'sustentera',
    'sustentiez',
    'sustentons',
    'susurrâmes',
    'susurrante',
    'susurrants',
    'susurrasse',
    'susurrâtes',
    'susurrerai',
    'susurreras',
    'susurrerez',
    'susurrions',
    'suturaient',
    'suturasses',
    'suturerais',
    'suturerait',
    'suturèrent',
    'sutureriez',
    'suturerons',
    'sutureront',
    'suzeraines',
    'sveltesses',
    'sweepstake',
    'swinguâmes',
    'swinguasse',
    'swinguâtes',
    'swinguerai',
    'swingueras',
    'swinguerez',
    'swinguions',
    'sycophante',
    'syllabâmes',
    'syllabasse',
    'syllabâtes',
    'syllaberai',
    'syllaberas',
    'syllaberez',
    'syllabions',
    'syllabique',
    'syllabisme',
    'syllogisme',
    'sylvestres',
    'sylvicoles',
    'sylvinites',
    'symbolique',
    'symbolisai',
    'symbolisas',
    'symbolisât',
    'symbolisée',
    'symboliser',
    'symbolises',
    'symbolisés',
    'symbolisez',
    'symbolisme',
    'symboliste',
    'symétrique',
    'symétrisai',
    'symétrisas',
    'symétrisât',
    'symétrisée',
    'symétriser',
    'symétrises',
    'symétrisés',
    'symétrisez',
    'sympathies',
    'sympathisa',
    'sympathise',
    'sympathisé',
    'symphonies',
    'symphorine',
    'symposions',
    'symposiums',
    'synagogues',
    'synalèphes',
    'synanthéré',
    'synaptases',
    'synaptique',
    'synarchies',
    'synchrones',
    'synchronie',
    'synclinale',
    'synclinaux',
    'syncopales',
    'syncopâmes',
    'syncopasse',
    'syncopâtes',
    'syncoperai',
    'syncoperas',
    'syncoperez',
    'syncopions',
    'syndicales',
    'syndiquais',
    'syndiquait',
    'syndiquant',
    'syndiquées',
    'syndiquent',
    'syndiquera',
    'syndiquiez',
    'syndiquons',
    'synecdoque',
    'synergique',
    'syngnathes',
    'synodiques',
    'synonymies',
    'synoptique',
    'synostoses',
    'syntaxique',
    'synthétisa',
    'synthétise',
    'synthétisé',
    'syntonisai',
    'syntonisas',
    'syntonisât',
    'syntonisée',
    'syntoniser',
    'syntonises',
    'syntonisés',
    'syntonisez',
    'syphilides',
    'systémique',
    'tabagiques',
    'tabagismes',
    'tabassâmes',
    'tabassasse',
    'tabassâtes',
    'tabasserai',
    'tabasseras',
    'tabasserez',
    'tabassions',
    'tabatières',
    'tabellaire',
    'tabellions',
    'tabernacle',
    'tabétiques',
    'tablassent',
    'tablassiez',
    'tablatures',
    'tableautin',
    'tablerions',
    'tabletière',
    'tabletiers',
    'tabulaient',
    'tabulaires',
    'tabulasses',
    'tabulateur',
    'tabulerais',
    'tabulerait',
    'tabulèrent',
    'tabuleriez',
    'tabulerons',
    'tabuleront',
    'tachassent',
    'tâchassent',
    'tachassiez',
    'tâchassiez',
    'tacherions',
    'tâcherions',
    'tachetâmes',
    'tachetasse',
    'tachetâtes',
    'tachetions',
    'tachettent',
    'tachettera',
    'tachetures',
    'tachymètre',
    'tacitement',
    'taciturnes',
    'tacticiens',
    'tahitienne',
    'taillables',
    'tailladais',
    'tailladait',
    'tailladant',
    'tailladées',
    'tailladent',
    'tailladera',
    'tailladiez',
    'tailladons',
    'taillaient',
    'taillasses',
    'taillerais',
    'taillerait',
    'taillèrent',
    'tailleries',
    'tailleriez',
    'taillerons',
    'tailleront',
    'tailleuses',
    'talassions',
    'talentueux',
    'taleraient',
    'tallassent',
    'tallassiez',
    'tallerions',
    'talmudique',
    'talmudiste',
    'talochâmes',
    'talochasse',
    'talochâtes',
    'talocherai',
    'talocheras',
    'talocherez',
    'talochions',
    'talonnages',
    'talonnâmes',
    'talonnasse',
    'talonnâtes',
    'talonnerai',
    'talonneras',
    'talonnerez',
    'talonnette',
    'talonneurs',
    'talonnière',
    'talonnions',
    'talquaient',
    'talquasses',
    'talquerais',
    'talquerait',
    'talquèrent',
    'talqueriez',
    'talquerons',
    'talqueront',
    'talqueuses',
    'tamarinier',
    'tambouille',
    'tambourina',
    'tambourine',
    'tambouriné',
    'tambourins',
    'tamisaient',
    'tamisantes',
    'tamisasses',
    'tamiserais',
    'tamiserait',
    'tamisèrent',
    'tamiseries',
    'tamiseriez',
    'tamiserons',
    'tamiseront',
    'tamiseuses',
    'tamisières',
    'tamponnade',
    'tamponnais',
    'tamponnait',
    'tamponnant',
    'tamponnées',
    'tamponnent',
    'tamponnera',
    'tamponneur',
    'tamponniez',
    'tamponnoir',
    'tamponnons',
    'tançassent',
    'tançassiez',
    'tancerions',
    'tanguaient',
    'tanguasses',
    'tanguerais',
    'tanguerait',
    'tanguèrent',
    'tangueriez',
    'tanguerons',
    'tangueront',
    'tanguières',
    'tanisaient',
    'tanisasses',
    'taniserais',
    'taniserait',
    'tanisèrent',
    'taniseriez',
    'taniserons',
    'taniseront',
    'tannassent',
    'tannassiez',
    'tannerions',
    'tannisâmes',
    'tannisasse',
    'tannisâtes',
    'tanniserai',
    'tanniseras',
    'tanniserez',
    'tannisions',
    'tantrismes',
    'tapageâmes',
    'tapageasse',
    'tapageâtes',
    'tapagerais',
    'tapagerait',
    'tapagèrent',
    'tapageriez',
    'tapagerons',
    'tapageront',
    'tapageuses',
    'tapassions',
    'taperaient',
    'tapinaient',
    'tapinasses',
    'tapinerais',
    'tapinerait',
    'tapinèrent',
    'tapineriez',
    'tapinerons',
    'tapineront',
    'tapiraient',
    'tapissâmes',
    'tapissasse',
    'tapissâtes',
    'tapisserai',
    'tapisseras',
    'tapisserez',
    'tapisserie',
    'tapissière',
    'tapissiers',
    'tapissions',
    'taponnâmes',
    'taponnasse',
    'taponnâtes',
    'taponnerai',
    'taponneras',
    'taponnerez',
    'taponnions',
    'tapotaient',
    'tapotantes',
    'tapotasses',
    'tapotement',
    'tapoterais',
    'tapoterait',
    'tapotèrent',
    'tapoteriez',
    'tapoterons',
    'tapoteront',
    'taquassent',
    'taquassiez',
    'taquerions',
    'taquinâmes',
    'taquinante',
    'taquinants',
    'taquinasse',
    'taquinâtes',
    'taquinerai',
    'taquineras',
    'taquinerez',
    'taquinerie',
    'taquinions',
    'tarabiscot',
    'tarabustai',
    'tarabustas',
    'tarabustât',
    'tarabustée',
    'tarabuster',
    'tarabustes',
    'tarabustés',
    'tarabustez',
    'tarassions',
    'taraudages',
    'taraudâmes',
    'taraudasse',
    'taraudâtes',
    'tarauderai',
    'tarauderas',
    'tarauderez',
    'taraudeurs',
    'taraudeuse',
    'taraudions',
    'taravelles',
    'tarbouches',
    'tardassent',
    'tardassiez',
    'tarderions',
    'tardigrade',
    'tardillons',
    'tardivetés',
    'tarentelle',
    'tarentules',
    'tareraient',
    'targuaient',
    'targuasses',
    'targuerais',
    'targuerait',
    'targuèrent',
    'targueriez',
    'targuerons',
    'targueront',
    'taricheute',
    'tarifaient',
    'tarifaires',
    'tarifantes',
    'tarifasses',
    'tariferais',
    'tariferait',
    'tarifèrent',
    'tariferiez',
    'tariferons',
    'tariferont',
    'tarifiâmes',
    'tarifiasse',
    'tarifiâtes',
    'tarifierai',
    'tarifieras',
    'tarifierez',
    'tarifiions',
    'tariraient',
    'tarissable',
    'tarissions',
    'tarlatanes',
    'tarmacadam',
    'tarsiennes',
    'tartelette',
    'Tartempion',
    'tartinâmes',
    'tartinasse',
    'tartinâtes',
    'tartinerai',
    'tartineras',
    'tartinerez',
    'tartinions',
    'tartirions',
    'tartissais',
    'tartissait',
    'tartissant',
    'tartissent',
    'tartissiez',
    'tartissons',
    'tartreuses',
    'tartriques',
    'tartuferie',
    'tassassent',
    'tassassiez',
    'tassements',
    'tasserions',
    'tâtassions',
    'tâteraient',
    'tatillonna',
    'tatillonne',
    'tatillonné',
    'tâtonnâmes',
    'tâtonnante',
    'tâtonnants',
    'tâtonnasse',
    'tâtonnâtes',
    'tâtonnerai',
    'tâtonneras',
    'tâtonnerez',
    'tâtonnions',
    'tatouaient',
    'tatouasses',
    'tatouerais',
    'tatouerait',
    'tatouèrent',
    'tatoueriez',
    'tatouerons',
    'tatoueront',
    'taupinière',
    'taurillons',
    'tauroboles',
    'tautologie',
    'tautologue',
    'tautomères',
    'tautomérie',
    'tavaïolles',
    'tavelaient',
    'tavelasses',
    'tavelèrent',
    'tavellâmes',
    'tavellasse',
    'tavellâtes',
    'tavellerai',
    'tavelleras',
    'tavellerez',
    'tavellions',
    'tavernière',
    'taverniers',
    'taxassions',
    'taxeraient',
    'taxidermie',
    'taximètres',
    'taxinomies',
    'Taxiphones',
    'taxonomies',
    'taylorisai',
    'taylorisas',
    'taylorisât',
    'taylorisée',
    'tayloriser',
    'taylorises',
    'taylorisés',
    'taylorisez',
    'taylorisme',
    'tcharchafs',
    'technicien',
    'technicisa',
    'technicise',
    'technicisé',
    'technicité',
    'techniques',
    'technisais',
    'technisait',
    'technisant',
    'technisées',
    'technisent',
    'technisera',
    'technisiez',
    'technisons',
    'tectonique',
    'teen-agers',
    'tee-shirts',
    'tégénaires',
    'teignaient',
    'teigneuses',
    'teignirent',
    'teignisses',
    'teillaient',
    'teillasses',
    'teillerais',
    'teillerait',
    'teillèrent',
    'teilleriez',
    'teillerons',
    'teilleront',
    'teilleuses',
    'teindrions',
    'teintaient',
    'teintasses',
    'teinterais',
    'teinterait',
    'teintèrent',
    'teinteriez',
    'teinterons',
    'teinteront',
    'teinturier',
    'télébennes',
    'télécabine',
    'télécinéma',
    'télécopiai',
    'télécopias',
    'télécopiât',
    'télécopiée',
    'télécopier',
    'télécopies',
    'télécopiés',
    'télécopiez',
    'télégramme',
    'télégraphe',
    'téléguidai',
    'téléguidas',
    'téléguidât',
    'téléguidée',
    'téléguider',
    'téléguides',
    'téléguidés',
    'téléguidez',
    'télémesure',
    'télémétrai',
    'télémétras',
    'télémétrât',
    'télémétrée',
    'télémétrer',
    'télémétrés',
    'télémètres',
    'télémétrez',
    'télémétrie',
    'téléologie',
    'téléonomie',
    'téléosaure',
    'télépathes',
    'télépathie',
    'téléphonai',
    'téléphonas',
    'téléphonât',
    'téléphonée',
    'téléphoner',
    'téléphones',
    'téléphonés',
    'téléphonez',
    'téléphonie',
    'téléradios',
    'téléscaphe',
    'télescopai',
    'télescopas',
    'télescopât',
    'télescopée',
    'télescoper',
    'télescopes',
    'télescopés',
    'télescopez',
    'télésièges',
    'télétextes',
    'téléthèque',
    'télévisais',
    'télévisait',
    'télévisant',
    'télévisées',
    'télévisent',
    'télévisera',
    'téléviseur',
    'télévisiez',
    'télévision',
    'télévisons',
    'télévisuel',
    'télexaient',
    'télexasses',
    'télexerais',
    'télexerait',
    'télexèrent',
    'télexeriez',
    'télexerons',
    'télexeront',
    'télexistes',
    'tellurates',
    'tellureuse',
    'telluriens',
    'tellurique',
    'tellurisme',
    'tellurures',
    'télophases',
    'téméraires',
    'témoignage',
    'témoignais',
    'témoignait',
    'témoignant',
    'témoignées',
    'témoignent',
    'témoignera',
    'témoigniez',
    'témoignons',
    'tempérâmes',
    'tempérance',
    'tempérante',
    'tempérants',
    'tempérasse',
    'tempérâtes',
    'tempérerai',
    'tempéreras',
    'tempérerez',
    'tempérions',
    'tempêtâmes',
    'tempêtasse',
    'tempêtâtes',
    'tempêterai',
    'tempêteras',
    'tempêterez',
    'tempêtions',
    'tempétueux',
    'temporaire',
    'temporales',
    'temporelle',
    'temporisai',
    'temporisas',
    'temporisât',
    'temporiser',
    'temporises',
    'temporisez',
    'tenacement',
    'tenaillais',
    'tenaillait',
    'tenaillant',
    'tenaillées',
    'tenaillent',
    'tenaillera',
    'tenailliez',
    'tenaillons',
    'tenancière',
    'tenanciers',
    'tendanciel',
    'tendineuse',
    'tendinites',
    'tendissent',
    'tendissiez',
    'tendraient',
    'tendrement',
    'tendresses',
    'ténébreuse',
    'ténébrions',
    'tenonnâmes',
    'tenonnasse',
    'tenonnâtes',
    'tenonnerai',
    'tenonneras',
    'tenonnerez',
    'tenonneuse',
    'tenonnions',
    'ténorisais',
    'ténorisait',
    'ténorisant',
    'ténorisent',
    'ténorisera',
    'ténorisiez',
    'ténorisons',
    'ténotomies',
    'tentacules',
    'tentassent',
    'tentassiez',
    'tentateurs',
    'tentations',
    'tentatives',
    'tentatrice',
    'tente-abri',
    'tenterions',
    'tenthrèdes',
    'tepidarium',
    'tératogène',
    'terçassent',
    'terçassiez',
    'tercerions',
    'térébelles',
    'terebellum',
    'térébinthe',
    'térébiques',
    'térébrante',
    'térébrants',
    'tergiversa',
    'tergiverse',
    'tergiversé',
    'terminales',
    'terminâmes',
    'terminasse',
    'terminâtes',
    'terminerai',
    'termineras',
    'terminerez',
    'terminions',
    'termitière',
    'ternirions',
    'ternissais',
    'ternissait',
    'ternissant',
    'ternissent',
    'ternissiez',
    'ternissons',
    'ternissure',
    'terpénique',
    'terpinéols',
    'terramares',
    'terraplane',
    'terraquées',
    'terrariums',
    'terrassais',
    'terrassait',
    'terrassant',
    'terrassées',
    'terrassent',
    'terrassera',
    'terrassier',
    'terrassiez',
    'terrassons',
    'terreautai',
    'terreautas',
    'terreautât',
    'terreautée',
    'terreauter',
    'terreautes',
    'terreautés',
    'terreautez',
    'terrerions',
    'terrestres',
    'terricoles',
    'terriennes',
    'terrifiais',
    'terrifiait',
    'terrifiant',
    'terrifiées',
    'terrifient',
    'terrifiera',
    'terrifiiez',
    'terrifions',
    'terrigènes',
    'terririons',
    'terrissais',
    'terrissait',
    'terrissant',
    'terrissent',
    'terrissiez',
    'terrissons',
    'territoire',
    'terrorisai',
    'terrorisas',
    'terrorisât',
    'terrorisée',
    'terroriser',
    'terrorises',
    'terrorisés',
    'terrorisez',
    'terrorisme',
    'terroriste',
    'tersassent',
    'tersassiez',
    'terserions',
    'tertiaires',
    'tessitures',
    'testacelle',
    'testaments',
    'testassent',
    'testassiez',
    'testateurs',
    'testatrice',
    'testerions',
    'testicules',
    'testologie',
    'tétaniques',
    'tétanisais',
    'tétanisait',
    'tétanisant',
    'tétanisées',
    'tétanisent',
    'tétanisera',
    'tétanisiez',
    'tétanismes',
    'tétanisons',
    'tétassions',
    'tête-bêche',
    'téteraient',
    'téterelles',
    'tétonnière',
    'tétracorde',
    'tétragones',
    'tétralogie',
    'tétramères',
    'tétramètre',
    'tétrapodes',
    'tétraptère',
    'tétrarchat',
    'tétrarchie',
    'tétrarques',
    'tétrastyle',
    'teutonique',
    'textuelles',
    'texturâmes',
    'texturasse',
    'texturâtes',
    'texturerai',
    'textureras',
    'texturerez',
    'texturions',
    'texturisai',
    'texturisas',
    'texturisât',
    'texturisée',
    'texturiser',
    'texturises',
    'texturisés',
    'texturisez',
    'thalamique',
    'théâtrales',
    'thébaïques',
    'thébaïsmes',
    'thématique',
    'thématisai',
    'thématisas',
    'thématisât',
    'thématisée',
    'thématiser',
    'thématises',
    'thématisés',
    'thématisez',
    'thématisme',
    'théocratie',
    'théodicées',
    'théodolite',
    'théogonies',
    'théologale',
    'théologaux',
    'théologien',
    'théologies',
    'théoricien',
    'théoriques',
    'théorisais',
    'théorisait',
    'théorisant',
    'théorisées',
    'théorisent',
    'théorisera',
    'théorisiez',
    'théorisons',
    'théosophes',
    'théosophie',
    'thérapeute',
    'thériaques',
    'théridions',
    'thermiques',
    'thermistor',
    'thermogène',
    'thermolyse',
    'thermostat',
    'thésaurisa',
    'thésaurise',
    'thésaurisé',
    'théurgique',
    'thioalcool',
    'thoracique',
    'thréonines',
    'thrombines',
    'thromboses',
    'thylacines',
    'thyratrons',
    'thyristors',
    'thyroïdien',
    'thyroïdite',
    'thyroxines',
    'tibétaines',
    'tictaquais',
    'tictaquait',
    'tictaquant',
    'tictaquent',
    'tictaquera',
    'tictaquiez',
    'tictaquons',
    'tiédirions',
    'tiédissais',
    'tiédissait',
    'tiédissant',
    'tiédissent',
    'tiédissiez',
    'tiédissons',
    'tiendrions',
    'tierçaient',
    'tierçasses',
    'tiercelets',
    'tiercerais',
    'tiercerait',
    'tiercèrent',
    'tierceriez',
    'tiercerons',
    'tierceront',
    'tigrassent',
    'tigrassiez',
    'tigrerions',
    'tillandsie',
    'tillassent',
    'tillassiez',
    'tillerions',
    'timbaliers',
    'timbraient',
    'timbrasses',
    'timbrerais',
    'timbrerait',
    'timbrèrent',
    'timbreriez',
    'timbrerons',
    'timbreront',
    'timidement',
    'timoneries',
    'timonières',
    'tinctorial',
    'tintamarre',
    'tintassent',
    'tintassiez',
    'tintements',
    'tinterions',
    'tiquassent',
    'tiquassiez',
    'tiquerions',
    'tiquetures',
    'tiraillais',
    'tiraillait',
    'tiraillant',
    'tiraillées',
    'tiraillent',
    'tiraillera',
    'tirailleur',
    'tirailliez',
    'tiraillons',
    'tirassions',
    'tire-balle',
    'tire-bonde',
    'tire-botte',
    'tire-clous',
    'tire-filet',
    'tire-laine',
    'tire-ligne',
    'tire-pieds',
    'tireraient',
    'tiretaines',
    'tire-veine',
    'tisassions',
    'tiseraient',
    'tisonnâmes',
    'tisonnasse',
    'tisonnâtes',
    'tisonnerai',
    'tisonneras',
    'tisonnerez',
    'tisonniers',
    'tisonnions',
    'tissassent',
    'tissassiez',
    'tisserande',
    'tisserands',
    'tisserions',
    'tissulaire',
    'titanesque',
    'titaniques',
    'titillâmes',
    'titillasse',
    'titillâtes',
    'titillerai',
    'titilleras',
    'titillerez',
    'titillions',
    'titrassent',
    'titrassiez',
    'titrerions',
    'titubaient',
    'titubantes',
    'titubasses',
    'titubation',
    'tituberais',
    'tituberait',
    'titubèrent',
    'tituberiez',
    'tituberons',
    'tituberont',
    'titulaires',
    'titularisa',
    'titularise',
    'titularisé',
    'toastaient',
    'toastasses',
    'toasterais',
    'toasterait',
    'toastèrent',
    'toasteriez',
    'toasterons',
    'toasteront',
    'toilassent',
    'toilassiez',
    'toilerions',
    'toilettage',
    'toilettais',
    'toilettait',
    'toilettant',
    'toilettées',
    'toilettent',
    'toilettera',
    'toilettiez',
    'toilettons',
    'toisassent',
    'toisassiez',
    'toiserions',
    'tokhariens',
    'tolérables',
    'toléraient',
    'tolérances',
    'tolérantes',
    'tolérasses',
    'tolérerais',
    'tolérerait',
    'tolérèrent',
    'toléreriez',
    'tolérerons',
    'toléreront',
    'toluidines',
    'tomassions',
    'tombassent',
    'tombassiez',
    'tombereaux',
    'tomberions',
    'tomenteuse',
    'tomeraient',
    'tondaisons',
    'tondissent',
    'tondissiez',
    'tondraient',
    'tonifiâmes',
    'tonifiante',
    'tonifiants',
    'tonifiasse',
    'tonifiâtes',
    'tonifierai',
    'tonifieras',
    'tonifierez',
    'tonifiions',
    'tonitruais',
    'tonitruait',
    'tonitruant',
    'tonitruent',
    'tonitruera',
    'tonitruiez',
    'tonitruons',
    'tonnelages',
    'tonneliers',
    'tonnerions',
    'tonométrie',
    'tonsurâmes',
    'tonsurasse',
    'tonsurâtes',
    'tonsurerai',
    'tonsureras',
    'tonsurerez',
    'tonsurions',
    'tontinâmes',
    'tontinasse',
    'tontinâtes',
    'tontinerai',
    'tontineras',
    'tontinerez',
    'tontinions',
    'topassions',
    'toperaient',
    'topicalisa',
    'topicalise',
    'topicalisé',
    'topographe',
    'topologies',
    'toponymies',
    'toquassent',
    'toquassiez',
    'toquerions',
    'torchaient',
    'torchasses',
    'torche-cul',
    'torcherais',
    'torcherait',
    'torchèrent',
    'torcheriez',
    'torcherons',
    'torcheront',
    'torchonnai',
    'torchonnas',
    'torchonnât',
    'torchonnée',
    'torchonner',
    'torchonnes',
    'torchonnés',
    'torchonnez',
    'tordissent',
    'tordissiez',
    'tordraient',
    'toréassent',
    'toréassiez',
    'toréerions',
    'toreutique',
    'toronnâmes',
    'toronnasse',
    'toronnâtes',
    'toronnerai',
    'toronneras',
    'toronnerez',
    'toronneuse',
    'toronnions',
    'torpillage',
    'torpillais',
    'torpillait',
    'torpillant',
    'torpillées',
    'torpillent',
    'torpillera',
    'torpilleur',
    'torpilliez',
    'torpillons',
    'torréfiais',
    'torréfiait',
    'torréfiant',
    'torréfiées',
    'torréfient',
    'torréfiera',
    'torréfiiez',
    'torréfions',
    'torrentiel',
    'torsadâmes',
    'torsadante',
    'torsadants',
    'torsadasse',
    'torsadâtes',
    'torsaderai',
    'torsaderas',
    'torsaderez',
    'torsadions',
    'torticolis',
    'tortillage',
    'tortillais',
    'tortillait',
    'tortillant',
    'tortillard',
    'tortillées',
    'tortillent',
    'tortillera',
    'tortilliez',
    'tortillons',
    'tortorâmes',
    'tortorasse',
    'tortorâtes',
    'tortorerai',
    'tortoreras',
    'tortorerez',
    'tortorions',
    'tortueuses',
    'torturâmes',
    'torturante',
    'torturants',
    'torturasse',
    'torturâtes',
    'torturerai',
    'tortureras',
    'torturerez',
    'torturions',
    'totalement',
    'totalisais',
    'totalisait',
    'totalisant',
    'totalisées',
    'totalisent',
    'totalisera',
    'totaliseur',
    'totalisiez',
    'totalisons',
    'totémiques',
    'totémismes',
    'touarègues',
    'touassions',
    'touchaient',
    'touchantes',
    'touchasses',
    'toucherais',
    'toucherait',
    'touchèrent',
    'toucheriez',
    'toucherons',
    'toucheront',
    'touchettes',
    'toueraient',
    'touillages',
    'touillâmes',
    'touillasse',
    'touillâtes',
    'touillerai',
    'touilleras',
    'touillerez',
    'touillions',
    'toupillais',
    'toupillait',
    'toupillant',
    'toupillées',
    'toupillent',
    'toupillera',
    'toupilleur',
    'toupilliez',
    'toupillons',
    'tourailles',
    'touraniens',
    'tourbaient',
    'tourbasses',
    'tourberais',
    'tourberait',
    'tourbèrent',
    'tourberiez',
    'tourberons',
    'tourberont',
    'tourbeuses',
    'tourbières',
    'tourbillon',
    'tourdilles',
    'tourillons',
    'tourmaline',
    'tourmentai',
    'tourmentas',
    'tourmentât',
    'tourmentée',
    'tourmenter',
    'tourmentes',
    'tourmentés',
    'tourmentez',
    'tourmentin',
    'tournaient',
    'tournailla',
    'tournaille',
    'tournaillé',
    'tournantes',
    'tournassai',
    'tournassas',
    'tournassât',
    'tournassée',
    'tournasser',
    'tournasses',
    'tournassés',
    'tournassez',
    'tournemain',
    'tournerais',
    'tournerait',
    'tournèrent',
    'tourneriez',
    'tournerons',
    'tourneront',
    'tournesols',
    'tournettes',
    'tourneuses',
    'tournicota',
    'tournicote',
    'tournicoté',
    'tournillai',
    'tournillas',
    'tournillât',
    'tourniller',
    'tournilles',
    'tournillez',
    'tournioles',
    'tourniquai',
    'tourniquas',
    'tourniquât',
    'tourniquer',
    'tourniques',
    'tourniquet',
    'tourniquez',
    'tournisses',
    'tournoient',
    'tournoiera',
    'tournoyais',
    'tournoyait',
    'tournoyant',
    'tournoyiez',
    'tournoyons',
    'tourtereau',
    'tourtières',
    'toussaient',
    'toussailla',
    'toussaille',
    'toussaillé',
    'toussasses',
    'tousserais',
    'tousserait',
    'toussèrent',
    'tousseries',
    'tousseriez',
    'tousserons',
    'tousseront',
    'tousseuses',
    'toussotais',
    'toussotait',
    'toussotant',
    'toussotent',
    'toussotera',
    'toussotiez',
    'toussotons',
    'tout-petit',
    'toxicomane',
    'toxoplasme',
    'traboulais',
    'traboulait',
    'traboulant',
    'traboulent',
    'traboulera',
    'trabouliez',
    'traboulons',
    'tracanâmes',
    'tracanasse',
    'tracanâtes',
    'tracanerai',
    'tracaneras',
    'tracanerez',
    'tracanions',
    'tracassais',
    'tracassait',
    'tracassant',
    'tracassées',
    'tracassent',
    'traçassent',
    'tracassera',
    'tracassier',
    'tracassiez',
    'traçassiez',
    'tracassins',
    'tracassons',
    'tracements',
    'tracerions',
    'trachéales',
    'trachéenne',
    'trachéides',
    'trachéites',
    'tractables',
    'tractaient',
    'tractasses',
    'tractation',
    'tracterais',
    'tracterait',
    'tractèrent',
    'tracteriez',
    'tracterons',
    'tracteront',
    'tractrices',
    'traditeurs',
    'traditions',
    'traducteur',
    'traduction',
    'traduirais',
    'traduirait',
    'traduiriez',
    'traduirons',
    'traduiront',
    'traduisais',
    'traduisait',
    'traduisant',
    'traduisent',
    'traduisiez',
    'traduisons',
    'traficotai',
    'traficotas',
    'traficotât',
    'traficotée',
    'traficoter',
    'traficotes',
    'traficotés',
    'traficotez',
    'trafiquais',
    'trafiquait',
    'trafiquant',
    'trafiquées',
    'trafiquent',
    'trafiqueur',
    'trafiquiez',
    'trafiquons',
    'trafiquont',
    'tragédiens',
    'trahirions',
    'trahissais',
    'trahissait',
    'trahissant',
    'trahissent',
    'trahissiez',
    'trahissons',
    'traînaient',
    'traînailla',
    'traînaille',
    'traînaillé',
    'traînantes',
    'traînardes',
    'traînassai',
    'traînassas',
    'traînassât',
    'traînassée',
    'traînasser',
    'traînasses',
    'traînassés',
    'traînassez',
    'traînement',
    'traînerais',
    'traînerait',
    'traînèrent',
    'traîneriez',
    'traînerons',
    'traîneront',
    'traîneuses',
    'trainglots',
    'traintrain',
    'trairaient',
    'traitables',
    'traitaient',
    'traitantes',
    'traitasses',
    'traitement',
    'traiterais',
    'traiterait',
    'traitèrent',
    'traiteriez',
    'traiterons',
    'traiteront',
    'traîtresse',
    'traîtrises',
    'tramassent',
    'tramassiez',
    'tramerions',
    'tramontane',
    'trampoline',
    'tranchages',
    'tranchâmes',
    'tranchante',
    'tranchants',
    'tranchasse',
    'tranchâtes',
    'trancherai',
    'trancheras',
    'trancherez',
    'trancheurs',
    'trancheuse',
    'tranchions',
    'tranchoirs',
    'tranquille',
    'transalpin',
    'transandin',
    'transborda',
    'transborde',
    'transbordé',
    'transcenda',
    'transcende',
    'transcendé',
    'transcodai',
    'transcodas',
    'transcodât',
    'transcodée',
    'transcoder',
    'transcodes',
    'transcodés',
    'transcodez',
    'transcrira',
    'transcrire',
    'transcrite',
    'transcrits',
    'transcrive',
    'transférai',
    'transféras',
    'transférât',
    'transférée',
    'transférer',
    'transférés',
    'transfères',
    'transférez',
    'transferts',
    'transfilai',
    'transfilas',
    'transfilât',
    'transfilée',
    'transfiler',
    'transfiles',
    'transfilés',
    'transfilez',
    'transfinie',
    'transfinis',
    'transforma',
    'transforme',
    'transformé',
    'transfuges',
    'transfusai',
    'transfusas',
    'transfusât',
    'transfusée',
    'transfuser',
    'transfuses',
    'transfusés',
    'transfusez',
    'transhumai',
    'transhumas',
    'transhumât',
    'transhumée',
    'transhumer',
    'transhumes',
    'transhumés',
    'transhumez',
    'transigeai',
    'transigeas',
    'transigeât',
    'transigent',
    'transigera',
    'transigiez',
    'transirais',
    'transirait',
    'transirent',
    'transiriez',
    'transirons',
    'transiront',
    'transisses',
    'transissez',
    'transistor',
    'transitais',
    'transitait',
    'transitant',
    'transitées',
    'transitent',
    'transitera',
    'transitiez',
    'transitifs',
    'transition',
    'transitive',
    'transitons',
    'translatai',
    'translatas',
    'translatât',
    'translatée',
    'translater',
    'translates',
    'translatés',
    'translatez',
    'translatif',
    'transmette',
    'transmigra',
    'transmigre',
    'transmigré',
    'transmîmes',
    'transmises',
    'transmisse',
    'transmîtes',
    'transmuais',
    'transmuait',
    'transmuant',
    'transmuées',
    'transmuent',
    'transmuera',
    'transmuiez',
    'transmuons',
    'transmutai',
    'transmutas',
    'transmutât',
    'transmutée',
    'transmuter',
    'transmutes',
    'transmutés',
    'transmutez',
    'transparue',
    'transparus',
    'transparut',
    'transparût',
    'transperça',
    'transperce',
    'transpercé',
    'transpirai',
    'transpiras',
    'transpirât',
    'transpirer',
    'transpires',
    'transpirez',
    'transplant',
    'transporta',
    'transporte',
    'transporté',
    'transports',
    'transposai',
    'transposas',
    'transposât',
    'transposée',
    'transposer',
    'transposes',
    'transposés',
    'transposez',
    'transsudai',
    'transsudas',
    'transsudat',
    'transsudât',
    'transsudée',
    'transsuder',
    'transsudes',
    'transsudés',
    'transsudez',
    'transvasai',
    'transvasas',
    'transvasât',
    'transvasée',
    'transvaser',
    'transvases',
    'transvasés',
    'transvasez',
    'transvidai',
    'transvidas',
    'transvidât',
    'transvidée',
    'transvider',
    'transvides',
    'transvidés',
    'transvidez',
    'trapéziste',
    'trapézoïde',
    'trappillon',
    'trappistes',
    'traquaient',
    'traquasses',
    'traquenard',
    'traquerais',
    'traquerait',
    'traquèrent',
    'traqueriez',
    'traquerons',
    'traqueront',
    'traqueuses',
    'trattorias',
    'traumatisa',
    'traumatise',
    'traumatisé',
    'travaillai',
    'travaillas',
    'travaillât',
    'travaillée',
    'travailler',
    'travailles',
    'travaillés',
    'travaillez',
    'travelages',
    'travelings',
    'travelling',
    'traversais',
    'traversait',
    'traversant',
    'traversées',
    'traversent',
    'traversera',
    'traversier',
    'traversiez',
    'traversins',
    'traversons',
    'travesties',
    'travestira',
    'trébuchais',
    'trébuchait',
    'trébuchant',
    'trébuchées',
    'trébuchent',
    'trébuchera',
    'trébuchets',
    'trébuchiez',
    'trébuchons',
    'tréfilages',
    'tréfilâmes',
    'tréfilasse',
    'tréfilâtes',
    'tréfilerai',
    'tréfileras',
    'tréfilerez',
    'tréfilerie',
    'tréfileurs',
    'tréfileuse',
    'tréfilions',
    'tréflières',
    'tréhaloses',
    'treillagea',
    'treillagée',
    'treillager',
    'treillages',
    'treillagés',
    'treillagez',
    'treillissa',
    'treillisse',
    'treillissé',
    'treizièmes',
    'treizistes',
    'trélingage',
    'trématages',
    'trématâmes',
    'trématasse',
    'trématâtes',
    'trématerai',
    'trémateras',
    'trématerez',
    'trémations',
    'tremblâmes',
    'tremblante',
    'tremblants',
    'tremblasse',
    'tremblâtes',
    'tremblerai',
    'trembleras',
    'tremblerez',
    'trembleurs',
    'trembleuse',
    'tremblions',
    'tremblotai',
    'tremblotas',
    'tremblotât',
    'trembloter',
    'tremblotes',
    'tremblotez',
    'trémoussai',
    'trémoussas',
    'trémoussât',
    'trémousser',
    'trémousses',
    'trémoussez',
    'trempaient',
    'trempasses',
    'tremperais',
    'tremperait',
    'trempèrent',
    'tremperiez',
    'tremperons',
    'tremperont',
    'trempettes',
    'trémulâmes',
    'trémulasse',
    'trémulâtes',
    'trémulerai',
    'trémuleras',
    'trémulerez',
    'trémulions',
    'trentaines',
    'trente-six',
    'trentièmes',
    'trépanâmes',
    'trépanasse',
    'trépanâtes',
    'trépanerai',
    'trépaneras',
    'trépanerez',
    'trépanions',
    'trépassais',
    'trépassait',
    'trépassant',
    'trépassées',
    'trépassent',
    'trépassera',
    'trépassiez',
    'trépassons',
    'trépassont',
    'tréphocyte',
    'trépidâmes',
    'trépidante',
    'trépidants',
    'trépidasse',
    'trépidâtes',
    'trépiderai',
    'trépideras',
    'trépiderez',
    'trépidions',
    'trépignais',
    'trépignait',
    'trépignant',
    'trépignées',
    'trépignent',
    'trépignera',
    'trépigniez',
    'trépignons',
    'trépointes',
    'tréponèmes',
    'trésailles',
    'trescheurs',
    'trésorerie',
    'trésorière',
    'trésoriers',
    'tressaient',
    'tressaille',
    'tressailli',
    'tressasses',
    'tressautai',
    'tressautas',
    'tressautât',
    'tressauter',
    'tressautes',
    'tressautez',
    'tresserais',
    'tresserait',
    'tressèrent',
    'tresseriez',
    'tresserons',
    'tresseront',
    'treuillage',
    'treuillais',
    'treuillait',
    'treuillant',
    'treuillées',
    'treuillent',
    'treuillera',
    'treuilliez',
    'treuillons',
    'trévirâmes',
    'trévirasse',
    'trévirâtes',
    'trévirerai',
    'trévireras',
    'trévirerez',
    'trévirions',
    'trialcools',
    'triandries',
    'triangulai',
    'triangulas',
    'triangulât',
    'triangulée',
    'trianguler',
    'triangules',
    'triangulés',
    'triangulez',
    'triasiques',
    'triassions',
    'triathlons',
    'tribalisme',
    'triballais',
    'triballait',
    'triballant',
    'triballées',
    'triballent',
    'triballera',
    'triballiez',
    'triballons',
    'tribasique',
    'tribologie',
    'tribomètre',
    'tribordais',
    'triboulets',
    'tributaire',
    'tricennale',
    'tricennaux',
    'tricéphale',
    'trichaient',
    'trichasses',
    'tricherais',
    'tricherait',
    'trichèrent',
    'tricheries',
    'tricheriez',
    'tricherons',
    'tricheront',
    'tricheuses',
    'trichiasis',
    'trichinées',
    'trichineux',
    'trichinose',
    'tricholome',
    'trichromes',
    'trichromie',
    'triclinium',
    'tricolores',
    'tricotages',
    'tricotâmes',
    'tricotasse',
    'tricotâtes',
    'tricoterai',
    'tricoteras',
    'tricoterez',
    'tricoteurs',
    'tricoteuse',
    'tricotions',
    'tricourant',
    'tricuspide',
    'tridactyle',
    'tridentées',
    'triennales',
    'trieraient',
    'triérarque',
    'trifoliolé',
    'triforiums',
    'trifouilla',
    'trifouille',
    'trifouillé',
    'trigéminée',
    'trigéminés',
    'trigonelle',
    'trijumeaux',
    'trilatéral',
    'trilingues',
    'trilitères',
    'trillaient',
    'trillasses',
    'trillerais',
    'trillerait',
    'trillèrent',
    'trilleriez',
    'trillerons',
    'trilleront',
    'trimardais',
    'trimardait',
    'trimardant',
    'trimardent',
    'trimardera',
    'trimardeur',
    'trimardiez',
    'trimardons',
    'trimassent',
    'trimassiez',
    'trimbalage',
    'trimbalais',
    'trimbalait',
    'trimbalant',
    'trimbalées',
    'trimbalent',
    'trimbalera',
    'trimbaliez',
    'trimballai',
    'trimballas',
    'trimballât',
    'trimballée',
    'trimballer',
    'trimballes',
    'trimballés',
    'trimballez',
    'trimbalons',
    'trimerions',
    'trimestres',
    'trimoteurs',
    'trinervées',
    'tringlâmes',
    'tringlasse',
    'tringlâtes',
    'tringlerai',
    'tringleras',
    'tringlerez',
    'tringlions',
    'trinitaire',
    'trinquâmes',
    'trinquasse',
    'trinquâtes',
    'trinquerai',
    'trinqueras',
    'trinquerez',
    'trinquette',
    'trinqueurs',
    'trinquions',
    'triomphais',
    'triomphait',
    'triomphale',
    'triomphant',
    'triomphaux',
    'triomphent',
    'triomphera',
    'triomphiez',
    'triomphons',
    'tripailles',
    'triparties',
    'tripartite',
    'triphasées',
    'triplaient',
    'triplasses',
    'triplement',
    'triplerais',
    'triplerait',
    'triplèrent',
    'tripleriez',
    'triplerons',
    'tripleront',
    'triplettes',
    'triplicata',
    'triploïdes',
    'triploïdie',
    'triporteur',
    'tripotages',
    'tripotâmes',
    'tripotasse',
    'tripotâtes',
    'tripoterai',
    'tripoteras',
    'tripoterez',
    'tripoteurs',
    'tripoteuse',
    'tripotions',
    'triptyques',
    'triquaient',
    'triquasses',
    'triquerais',
    'triquerait',
    'triquèrent',
    'triqueriez',
    'triquerons',
    'triqueront',
    'trisaïeule',
    'trisannuel',
    'trisecteur',
    'trisection',
    'triséquais',
    'triséquait',
    'triséquant',
    'triséquées',
    'trisèquent',
    'triséquera',
    'triséquiez',
    'triséquons',
    'trisomique',
    'trissaient',
    'trissasses',
    'trisserais',
    'trisserait',
    'trissèrent',
    'trisseriez',
    'trisserons',
    'trisseront',
    'tristement',
    'tristesses',
    'tristounet',
    'trisyllabe',
    'triticales',
    'triturable',
    'triturâmes',
    'triturasse',
    'triturâtes',
    'triturerai',
    'tritureras',
    'triturerez',
    'triturions',
    'triumviral',
    'triumvirat',
    'trivalente',
    'trivalents',
    'trivialité',
    'trochaïque',
    'trochanter',
    'trochiters',
    'troglobies',
    'troglodyte',
    'trois-deux',
    'trois-huit',
    'troisièmes',
    'trois-mâts',
    'trolleybus',
    'trombidion',
    'trompaient',
    'trompasses',
    'tromperais',
    'tromperait',
    'trompèrent',
    'tromperies',
    'tromperiez',
    'tromperons',
    'tromperont',
    'trompetais',
    'trompetait',
    'trompetant',
    'trompetées',
    'trompetiez',
    'trompetons',
    'trompettes',
    'trompeuses',
    'trompillon',
    'trônassent',
    'trônassiez',
    'troncature',
    'tronçonnai',
    'tronçonnas',
    'tronçonnât',
    'tronçonnée',
    'tronçonner',
    'tronçonnes',
    'tronçonnés',
    'tronçonnez',
    'trônerions',
    'tronquâmes',
    'tronquasse',
    'tronquâtes',
    'tronquerai',
    'tronqueras',
    'tronquerez',
    'tronquions',
    'trophiques',
    'tropicales',
    'tropopause',
    'trop-perçu',
    'trop-plein',
    'troquaient',
    'troquasses',
    'troquerais',
    'troquerait',
    'troquèrent',
    'troqueriez',
    'troquerons',
    'troqueront',
    'troqueuses',
    'trotskisme',
    'trotskiste',
    'trotskyste',
    'trottaient',
    'trottantes',
    'trottasses',
    'trotterais',
    'trotterait',
    'trottèrent',
    'trotteriez',
    'trotterons',
    'trotteront',
    'trotteuses',
    'trottinais',
    'trottinait',
    'trottinant',
    'trottinent',
    'trottinera',
    'trottiniez',
    'trottinons',
    'trouassent',
    'trouassiez',
    'troubadour',
    'troublâmes',
    'troublante',
    'troublants',
    'troublasse',
    'troublâtes',
    'troubleaux',
    'troublerai',
    'troubleras',
    'troublerez',
    'troublions',
    'trouerions',
    'troufignon',
    'trouillard',
    'troupiales',
    'troussages',
    'troussâmes',
    'troussasse',
    'troussâtes',
    'trousseaux',
    'trousserai',
    'trousseras',
    'trousserez',
    'trousseurs',
    'troussions',
    'trou-trous',
    'trouvables',
    'trouvaient',
    'trouvaille',
    'trouvasses',
    'trouverais',
    'trouverait',
    'trouvèrent',
    'trouveriez',
    'trouverons',
    'trouveront',
    'trouveuses',
    'truandâmes',
    'truandasse',
    'truandâtes',
    'truanderai',
    'truanderas',
    'truanderez',
    'truanderie',
    'truandions',
    'truchement',
    'trucidâmes',
    'trucidasse',
    'trucidâtes',
    'truciderai',
    'trucideras',
    'truciderez',
    'trucidions',
    'trucmuches',
    'truculence',
    'truculente',
    'truculents',
    'truffaient',
    'truffasses',
    'trufferais',
    'trufferait',
    'truffèrent',
    'trufferiez',
    'trufferons',
    'trufferont',
    'truffières',
    'truquaient',
    'truquasses',
    'truquerais',
    'truquerait',
    'truquèrent',
    'truqueriez',
    'truquerons',
    'truqueront',
    'truqueuses',
    'truquistes',
    'trusquinai',
    'trusquinas',
    'trusquinât',
    'trusquinée',
    'trusquiner',
    'trusquines',
    'trusquinés',
    'trusquinez',
    'trustaient',
    'trustasses',
    'trusterais',
    'trusterait',
    'trustèrent',
    'trusteriez',
    'trusterons',
    'trusteront',
    'tsarévitch',
    'tubassions',
    'tubéracées',
    'tuberaient',
    'tubercules',
    'tubéreuses',
    'tubérisées',
    'tubérosité',
    'tubulaires',
    'tubuleuses',
    'tue-diable',
    'tuilassent',
    'tuilassiez',
    'tuilerions',
    'tularémies',
    'tuméfiâmes',
    'tuméfiasse',
    'tuméfiâtes',
    'tuméfierai',
    'tuméfieras',
    'tuméfierez',
    'tuméfiions',
    'tumescence',
    'tumescente',
    'tumescents',
    'tumulaires',
    'tumultueux',
    'tungstates',
    'tungstènes',
    'tungstique',
    'tunicelles',
    'tunisienne',
    'tunneliers',
    'tupinambis',
    'turbidités',
    'turbinages',
    'turbinâmes',
    'turbinasse',
    'turbinâtes',
    'turbinelle',
    'turbinerai',
    'turbineras',
    'turbinerez',
    'turbinions',
    'turbopompe',
    'turbotière',
    'turbotrain',
    'turbulence',
    'turbulente',
    'turbulents',
    'turgescent',
    'turlupinai',
    'turlupinas',
    'turlupinât',
    'turlupinée',
    'turlupiner',
    'turlupines',
    'turlupinés',
    'turlupinez',
    'turlurette',
    'turlutaine',
    'turonienne',
    'turpitudes',
    'turqueries',
    'turquettes',
    'turquoises',
    'turriculée',
    'turriculés',
    'turritelle',
    'tussilages',
    'tutélaires',
    'tuteurages',
    'tuteurâmes',
    'tuteurasse',
    'tuteurâtes',
    'tuteurerai',
    'tuteureras',
    'tuteurerez',
    'tuteurions',
    'tutoiement',
    'tutoierais',
    'tutoierait',
    'tutoieriez',
    'tutoierons',
    'tutoieront',
    'tutoyaient',
    'tutoyasses',
    'tutoyèrent',
    'tutoyeuses',
    'tuyautages',
    'tuyautâmes',
    'tuyautasse',
    'tuyautâtes',
    'tuyauterai',
    'tuyauteras',
    'tuyauterez',
    'tuyauterie',
    'tuyauteurs',
    'tuyauteuse',
    'tuyautions',
    'twistaient',
    'twistasses',
    'twisterais',
    'twisterait',
    'twistèrent',
    'twisteriez',
    'twisterons',
    'twisteront',
    'tympanique',
    'tympanisai',
    'tympanisas',
    'tympanisât',
    'tympanisée',
    'tympaniser',
    'tympanises',
    'tympanisés',
    'tympanisez',
    'tympanisme',
    'typassions',
    'typeraient',
    'typisaient',
    'typisasses',
    'typiserais',
    'typiserait',
    'typisèrent',
    'typiseriez',
    'typiserons',
    'typiseront',
    'typographe',
    'typologies',
    'typomètres',
    'typtologie',
    'tyranneaux',
    'tyrannique',
    'tyrannisai',
    'tyrannisas',
    'tyrannisât',
    'tyrannisée',
    'tyranniser',
    'tyrannises',
    'tyrannisés',
    'tyrannisez',
    'tyrolienne',
    'ukrainiens',
    'ulcéraient',
    'ulcérasses',
    'ulcératifs',
    'ulcération',
    'ulcérative',
    'ulcérerais',
    'ulcérerait',
    'ulcérèrent',
    'ulcéreriez',
    'ulcérerons',
    'ulcéreront',
    'ulcéreuses',
    'ulcéroïdes',
    'uliginaire',
    'ultérieure',
    'ultérieurs',
    'ultimatums',
    'ultimement',
    'ultra-chic',
    'ultracourt',
    'ultra-sons',
    'ululassent',
    'ululassiez',
    'ululements',
    'ululerions',
    'unanimisme',
    'unanimiste',
    'unanimités',
    'unciformes',
    'unguifères',
    'unicolores',
    'unièmement',
    'unifiaient',
    'unifiasses',
    'unifierais',
    'unifierait',
    'unifièrent',
    'unifieriez',
    'unifierons',
    'unifieront',
    'unifilaire',
    'unifoliées',
    'uniformisa',
    'uniformise',
    'uniformisé',
    'uniformité',
    'unilatéral',
    'unilingues',
    'uninominal',
    'uniquement',
    'unisexuées',
    'unissaient',
    'unitariens',
    'unitarisme',
    'universaux',
    'universels',
    'université',
    'univocités',
    'uranifères',
    'uraninites',
    'uranoscope',
    'urbanisais',
    'urbanisait',
    'urbanisant',
    'urbanisées',
    'urbanisent',
    'urbanisera',
    'urbanisiez',
    'urbanismes',
    'urbanisons',
    'urbanistes',
    'urédospore',
    'urétérales',
    'urétérites',
    'uréthannes',
    'urinassent',
    'urinassiez',
    'urinerions',
    'urinifères',
    'urobilines',
    'urochromes',
    'urographie',
    'urolagnies',
    'uropygiale',
    'uropygiaux',
    'uropygiens',
    'urticaires',
    'urticantes',
    'urtication',
    'uruguayens',
    'usinassent',
    'usinassiez',
    'usinerions',
    'ustensiles',
    'usucapions',
    'usurpaient',
    'usurpasses',
    'usurpateur',
    'usurpation',
    'usurperais',
    'usurperait',
    'usurpèrent',
    'usurperiez',
    'usurperons',
    'usurperont',
    'utilisable',
    'utilisâmes',
    'utilisasse',
    'utilisâtes',
    'utiliserai',
    'utiliseras',
    'utiliserez',
    'utilisions',
    'utilitaire',
    'utriculeux',
    'uxorilocal',
    'vacancière',
    'vacanciers',
    'vacataires',
    'vaccinable',
    'vaccinales',
    'vaccinâmes',
    'vaccinasse',
    'vaccinâtes',
    'vaccinelle',
    'vaccinerai',
    'vaccineras',
    'vaccinerez',
    'vaccinides',
    'vacciniers',
    'vaccinions',
    'vaccinoïde',
    'vacillâmes',
    'vacillante',
    'vacillants',
    'vacillasse',
    'vacillâtes',
    'vacillerai',
    'vacilleras',
    'vacillerez',
    'vacillions',
    'vacuolaire',
    'vacuolisai',
    'vacuolisas',
    'vacuolisât',
    'vacuolisée',
    'vacuoliser',
    'vacuolises',
    'vacuolisés',
    'vacuolisez',
    'vade-mecum',
    'vadrouilla',
    'vadrouille',
    'vadrouillé',
    'vagabondai',
    'vagabondas',
    'vagabondât',
    'vagabonder',
    'vagabondes',
    'vagabondez',
    'vaginismes',
    'vagiraient',
    'vagissante',
    'vagissants',
    'vagissions',
    'vagotonies',
    'vaguassent',
    'vaguassiez',
    'vaguelette',
    'vaguerions',
    'vaillances',
    'vaillantes',
    'vaillantie',
    'vaincrions',
    'vainqueurs',
    'vainquîmes',
    'vainquions',
    'vainquisse',
    'vainquîtes',
    'vaironnais',
    'vaironnait',
    'vaironnant',
    'vaironnent',
    'vaironnera',
    'vaironniez',
    'vaironnons',
    'vaisselier',
    'vaisselles',
    'valdinguai',
    'valdinguas',
    'valdinguât',
    'valdinguer',
    'valdingues',
    'valdinguez',
    'valérianes',
    'valetaient',
    'valetaille',
    'valetasses',
    'valeterais',
    'valeterait',
    'valetèrent',
    'valeteriez',
    'valeterons',
    'valeteront',
    'valeureuse',
    'validaient',
    'validasses',
    'validation',
    'validement',
    'validerais',
    'validerait',
    'validèrent',
    'valideriez',
    'validerons',
    'valideront',
    'valisaient',
    'valisasses',
    'valiserais',
    'valiserait',
    'valisèrent',
    'valiseriez',
    'valiserons',
    'valiseront',
    'vallonnais',
    'vallonnait',
    'vallonnant',
    'vallonnées',
    'vallonnent',
    'vallonnera',
    'vallonniez',
    'vallonnons',
    'valorisais',
    'valorisait',
    'valorisant',
    'valorisées',
    'valorisent',
    'valorisera',
    'valorisiez',
    'valorisons',
    'valousâmes',
    'valousasse',
    'valousâtes',
    'valouserai',
    'valouseras',
    'valouserez',
    'valousions',
    'valsassent',
    'valsassiez',
    'valserions',
    'valussions',
    'valvulaire',
    'vampassent',
    'vampassiez',
    'vamperions',
    'vampirique',
    'vampirisai',
    'vampirisas',
    'vampirisât',
    'vampirisée',
    'vampiriser',
    'vampirises',
    'vampirisés',
    'vampirisez',
    'vampirisme',
    'vanadinite',
    'vanadiques',
    'vandalisai',
    'vandalisas',
    'vandalisât',
    'vandalisée',
    'vandaliser',
    'vandalises',
    'vandalisés',
    'vandalisez',
    'vandalisme',
    'vanilliers',
    'vanillines',
    'vanillisme',
    'vaniteuses',
    'vannassent',
    'vannassiez',
    'vannerions',
    'vantardise',
    'vantassent',
    'vantassiez',
    'vanterions',
    'vaporeuses',
    'vaporisage',
    'vaporisais',
    'vaporisait',
    'vaporisant',
    'vaporisées',
    'vaporisent',
    'vaporisera',
    'vaporisiez',
    'vaporisons',
    'vaquassent',
    'vaquassiez',
    'vaquerions',
    'varappâmes',
    'varappasse',
    'varappâtes',
    'varapperai',
    'varapperas',
    'varapperez',
    'varappeurs',
    'varappeuse',
    'varappions',
    'variassent',
    'variassiez',
    'variateurs',
    'variations',
    'varicelles',
    'varicocèle',
    'varierions',
    'variétales',
    'varioleuse',
    'variolique',
    'variqueuse',
    'varlopâmes',
    'varlopasse',
    'varlopâtes',
    'varloperai',
    'varloperas',
    'varloperez',
    'varlopions',
    'vasculaire',
    'vasectomie',
    'vaselinais',
    'vaselinait',
    'vaselinant',
    'vaselinées',
    'vaselinent',
    'vaselinera',
    'vaseliniez',
    'vaselinons',
    'vasomoteur',
    'vasouillai',
    'vasouillas',
    'vasouillât',
    'vasouiller',
    'vasouilles',
    'vasouillez',
    'vassalisai',
    'vassalisas',
    'vassalisât',
    'vassalisée',
    'vassaliser',
    'vassalises',
    'vassalisés',
    'vassalisez',
    'vassalités',
    'vassiveaux',
    'vaticinais',
    'vaticinait',
    'vaticinant',
    'vaticinent',
    'vaticinera',
    'vaticiniez',
    'vaticinons',
    'vauchéries',
    'vauclusien',
    'vaudeville',
    'vaudraient',
    'vauriennes',
    'vautraient',
    'vautrasses',
    'vautrerais',
    'vautrerait',
    'vautrèrent',
    'vautreriez',
    'vautrerons',
    'vautreront',
    'vavasseurs',
    'vectoriels',
    'vécussions',
    'végétaient',
    'végétarien',
    'végétasses',
    'végétatifs',
    'végétation',
    'végétative',
    'végéterais',
    'végéterait',
    'végétèrent',
    'végéteriez',
    'végéterons',
    'végéteront',
    'véhémences',
    'véhémentes',
    'véhiculais',
    'véhiculait',
    'véhiculant',
    'véhiculées',
    'véhiculent',
    'véhiculera',
    'véhiculiez',
    'véhiculons',
    'veillaient',
    'veillasses',
    'veillerais',
    'veillerait',
    'veillèrent',
    'veilleriez',
    'veillerons',
    'veilleront',
    'veilleuses',
    'veinassent',
    'veinassiez',
    'veinerions',
    'vélarisais',
    'vélarisait',
    'vélarisant',
    'vélarisées',
    'vélarisent',
    'vélarisera',
    'vélarisiez',
    'vélarisons',
    'vêlassions',
    'vêleraient',
    'vélocement',
    'vélocipède',
    'vélodromes',
    'vélomoteur',
    'veloutâmes',
    'veloutasse',
    'veloutâtes',
    'velouterai',
    'velouteras',
    'velouterez',
    'velouteuse',
    'veloutiers',
    'veloutines',
    'veloutions',
    'vénalement',
    'vendangeai',
    'vendangeas',
    'vendangeât',
    'vendangées',
    'vendangent',
    'vendangera',
    'vendangeur',
    'vendangiez',
    'vendéennes',
    'vendissent',
    'vendissiez',
    'vendraient',
    'vénéneuses',
    'vénérables',
    'vénéraient',
    'vénérasses',
    'vénération',
    'vénérerais',
    'vénérerait',
    'vénérèrent',
    'vénéreriez',
    'vénérerons',
    'vénéreront',
    'vénérienne',
    'vengeaient',
    'vengeances',
    'vengeantes',
    'vengeasses',
    'vengeresse',
    'vengerions',
    'venimeuses',
    'venimosité',
    'vénitienne',
    'ventilâmes',
    'ventilasse',
    'ventilâtes',
    'ventilerai',
    'ventileras',
    'ventilerez',
    'ventileuse',
    'ventilions',
    'ventousais',
    'ventousait',
    'ventousant',
    'ventousées',
    'ventousent',
    'ventousera',
    'ventousiez',
    'ventousons',
    'ventrebleu',
    'ventricule',
    'ventrières',
    'vénusienne',
    'vératrines',
    'verbalisai',
    'verbalisas',
    'verbalisât',
    'verbalisée',
    'verbaliser',
    'verbalises',
    'verbalisés',
    'verbalisez',
    'verbalisme',
    'verbiageai',
    'verbiageas',
    'verbiageât',
    'verbiagent',
    'verbiagera',
    'verbiagiez',
    'verbomanie',
    'verboquets',
    'verbosités',
    'verdelette',
    'verdirions',
    'verdissage',
    'verdissais',
    'verdissait',
    'verdissant',
    'verdissent',
    'verdissiez',
    'verdissons',
    'verdoierai',
    'verdoieras',
    'verdoierez',
    'verdoyâmes',
    'verdoyante',
    'verdoyants',
    'verdoyasse',
    'verdoyâtes',
    'verdoyions',
    'verdunisai',
    'verdunisas',
    'verdunisât',
    'verdunisée',
    'verduniser',
    'verdunises',
    'verdunisés',
    'verdunisez',
    'vérétilles',
    'vergeoises',
    'vergerette',
    'vergetures',
    'verglaçait',
    'verglacera',
    'vergobrets',
    'véridicité',
    'véridiques',
    'vérifiable',
    'vérifiâmes',
    'vérifiasse',
    'vérifiâtes',
    'vérifierai',
    'vérifieras',
    'vérifierez',
    'vérifieurs',
    'vérifieuse',
    'vérifiions',
    'véritables',
    'verjutâmes',
    'verjutasse',
    'verjutâtes',
    'verjuterai',
    'verjuteras',
    'verjuterez',
    'verjutions',
    'vermeilles',
    'vermicelle',
    'vermicides',
    'vermiculai',
    'vermiculas',
    'vermiculât',
    'vermiculée',
    'vermiculer',
    'vermicules',
    'vermiculés',
    'vermiculez',
    'vermiforme',
    'vermifuges',
    'vermillais',
    'vermillait',
    'vermillant',
    'vermillent',
    'vermillera',
    'vermilliez',
    'vermillons',
    'vermineuse',
    'vermisseau',
    'vermoulais',
    'vermoulait',
    'vermoulant',
    'vermoulées',
    'vermoulent',
    'vermoulera',
    'vermouliez',
    'vermoulons',
    'vermoulues',
    'vermoulure',
    'vernations',
    'vernirions',
    'vernissage',
    'vernissais',
    'vernissait',
    'vernissant',
    'vernissées',
    'vernissent',
    'vernissera',
    'vernisseur',
    'vernissiez',
    'vernissons',
    'véroniques',
    'verroterie',
    'verrouilla',
    'verrouille',
    'verrouillé',
    'verruqueux',
    'versassent',
    'versassiez',
    'versatiles',
    'versements',
    'verserions',
    'versifiais',
    'versifiait',
    'versifiant',
    'versifiées',
    'versifient',
    'versifiera',
    'versifiiez',
    'versifions',
    'vertébrale',
    'vertébraux',
    'vertébrées',
    'verticales',
    'verticille',
    'verticillé',
    'vertueuses',
    'vertugadin',
    'vésicantes',
    'vésication',
    'vésiculeux',
    'vespérales',
    'vessassent',
    'vessassiez',
    'vesserions',
    'vestiaires',
    'vestibules',
    'vétillâmes',
    'vétillarde',
    'vétillards',
    'vétillasse',
    'vétillâtes',
    'vétillerai',
    'vétilleras',
    'vétillerez',
    'vétilleuse',
    'vétillions',
    'vêtiraient',
    'vêtissions',
    'veuglaires',
    'veveysanne',
    'vexassions',
    'vexatoires',
    'vexatrices',
    'vexeraient',
    'vexillaire',
    'viabilisai',
    'viabilisas',
    'viabilisât',
    'viabilisée',
    'viabiliser',
    'viabilises',
    'viabilisés',
    'viabilisez',
    'viabilités',
    'viandaient',
    'viandasses',
    'vianderais',
    'vianderait',
    'viandèrent',
    'vianderiez',
    'vianderons',
    'vianderont',
    'vibraphone',
    'vibrassent',
    'vibrassiez',
    'vibrateurs',
    'vibratiles',
    'vibrations',
    'vibratoire',
    'vibrerions',
    'vibrionnai',
    'vibrionnas',
    'vibrionnât',
    'vibrionner',
    'vibrionnes',
    'vibrionnez',
    'vicariales',
    'vicariante',
    'vicariants',
    'vice-légat',
    'vicennales',
    'vice-reine',
    'vicésimale',
    'vicésimaux',
    'vice-versa',
    'vichyssois',
    'viciassent',
    'viciassiez',
    'viciateurs',
    'viciations',
    'viciatrice',
    'vicierions',
    'vicinalité',
    'vicomtales',
    'vicomtesse',
    'victimaire',
    'victoriens',
    'victorieux',
    'vidangeais',
    'vidangeait',
    'vidangeant',
    'vidangeons',
    'vidangerai',
    'vidangeras',
    'vidangerez',
    'vidangeurs',
    'vidangions',
    'vidassions',
    'vidéoclips',
    'vide-pomme',
    'videraient',
    'vidimaient',
    'vidimasses',
    'vidimerais',
    'vidimerait',
    'vidimèrent',
    'vidimeriez',
    'vidimerons',
    'vidimeront',
    'vieillards',
    'vieillerie',
    'vieillesse',
    'vieillîmes',
    'vieillirai',
    'vieilliras',
    'vieillirez',
    'vieillisse',
    'vieillîtes',
    'vieillotte',
    'viellaient',
    'viellasses',
    'viellerais',
    'viellerait',
    'viellèrent',
    'vielleriez',
    'viellerons',
    'vielleront',
    'viendrions',
    'vietnamien',
    'vif-argent',
    'vigilances',
    'vigilantes',
    'vigneronne',
    'vignetures',
    'vigoureuse',
    'vilipendai',
    'vilipendas',
    'vilipendât',
    'vilipendée',
    'vilipender',
    'vilipendes',
    'vilipendés',
    'vilipendez',
    'villageois',
    'villanelle',
    'villosités',
    'vinaigrais',
    'vinaigrait',
    'vinaigrant',
    'vinaigrées',
    'vinaigrent',
    'vinaigrera',
    'vinaigrier',
    'vinaigriez',
    'vinaigrons',
    'vinassions',
    'vindicatif',
    'vineraient',
    'vingtaines',
    'vingt-cinq',
    'vingt-deux',
    'vingt-huit',
    'vingtièmes',
    'vingt-neuf',
    'vingt-sept',
    'vinifiâmes',
    'vinifiasse',
    'vinifiâtes',
    'vinifierai',
    'vinifieras',
    'vinifierez',
    'vinifiions',
    'vinyliques',
    'violaçâmes',
    'violaçasse',
    'violaçâtes',
    'violacerai',
    'violaceras',
    'violacerez',
    'violacions',
    'violassent',
    'violassiez',
    'violateurs',
    'violations',
    'violatrice',
    'violemment',
    'violentais',
    'violentait',
    'violentant',
    'violentées',
    'violentent',
    'violentera',
    'violentiez',
    'violentons',
    'violerions',
    'violonâmes',
    'violonasse',
    'violonâtes',
    'violonerai',
    'violoneras',
    'violonerez',
    'violonions',
    'violoniste',
    'vioquirais',
    'vioquirait',
    'vioquirent',
    'vioquiriez',
    'vioquirons',
    'vioquiront',
    'vioquisses',
    'vioquissez',
    'virassions',
    'vireraient',
    'virescence',
    'virevoltai',
    'virevoltas',
    'virevoltât',
    'virevolter',
    'virevoltes',
    'virevoltez',
    'virginales',
    'virginités',
    'virgulâmes',
    'virgulasse',
    'virgulâtes',
    'virgulerai',
    'virguleras',
    'virgulerez',
    'virgulions',
    'virilement',
    'virilisais',
    'virilisait',
    'virilisant',
    'virilisées',
    'virilisent',
    'virilisera',
    'virilisiez',
    'virilismes',
    'virilisons',
    'virilocale',
    'virilocaux',
    'virolaient',
    'virolasses',
    'virolerais',
    'virolerait',
    'virolèrent',
    'viroleriez',
    'virolerons',
    'viroleront',
    'virologies',
    'virologues',
    'virtualité',
    'virtuelles',
    'virtuosité',
    'virulences',
    'virulentes',
    'visagistes',
    'visassions',
    'viscérales',
    'viscosités',
    'viseraient',
    'visibilité',
    'visionnais',
    'visionnait',
    'visionnant',
    'visionnées',
    'visionnent',
    'visionnera',
    'visionniez',
    'visionnons',
    'visiophone',
    'visitaient',
    'visitantes',
    'visitasses',
    'visitation',
    'visiterais',
    'visiterait',
    'visitèrent',
    'visiteriez',
    'visiterons',
    'visiteront',
    'visiteuses',
    'visonnière',
    'visqueuses',
    'vissassent',
    'vissassiez',
    'visserions',
    'visualisai',
    'visualisas',
    'visualisât',
    'visualisée',
    'visualiser',
    'visualises',
    'visualisés',
    'visualisez',
    'vitalismes',
    'vitalistes',
    'vitaminées',
    'vitellines',
    'vitelottes',
    'vitrassent',
    'vitrassiez',
    'vitrerions',
    'vitrifiais',
    'vitrifiait',
    'vitrifiant',
    'vitrifiées',
    'vitrifient',
    'vitrifiera',
    'vitrifiiez',
    'vitrifions',
    'vitriolage',
    'vitriolais',
    'vitriolait',
    'vitriolant',
    'vitriolées',
    'vitriolent',
    'vitriolera',
    'vitrioleur',
    'vitrioliez',
    'vitriolons',
    'vitulaires',
    'vitupérais',
    'vitupérait',
    'vitupérant',
    'vitupérées',
    'vitupèrent',
    'vitupérera',
    'vitupériez',
    'vitupérons',
    'vivandière',
    'vivandiers',
    'vivifiâmes',
    'vivifiante',
    'vivifiants',
    'vivifiasse',
    'vivifiâtes',
    'vivifierai',
    'vivifieras',
    'vivifierez',
    'vivifiions',
    'viviparité',
    'vivotaient',
    'vivotasses',
    'vivoterais',
    'vivoterait',
    'vivotèrent',
    'vivoteriez',
    'vivoterons',
    'vivoteront',
    'vocalement',
    'vocaliques',
    'vocalisais',
    'vocalisait',
    'vocalisant',
    'vocalisées',
    'vocalisent',
    'vocalisera',
    'vocalisiez',
    'vocalismes',
    'vocalisons',
    'vociférais',
    'vociférait',
    'vociférant',
    'vociférées',
    'vocifèrent',
    'vociférera',
    'vocifériez',
    'vociférons',
    'voguassent',
    'voguassiez',
    'voguerions',
    'voilassent',
    'voilassiez',
    'voilements',
    'voilerions',
    'voisinages',
    'voisinâmes',
    'voisinasse',
    'voisinâtes',
    'voisinerai',
    'voisineras',
    'voisinerez',
    'voisinions',
    'voiturages',
    'voiturâmes',
    'voiturasse',
    'voiturâtes',
    'voiturerai',
    'voitureras',
    'voiturerez',
    'voiturette',
    'voituriers',
    'voiturions',
    'volaillers',
    'volailleur',
    'volassions',
    'volatilisa',
    'volatilise',
    'volatilisé',
    'volatilité',
    'volatisées',
    'volcanique',
    'volcanisai',
    'volcanisas',
    'volcanisât',
    'volcanisée',
    'volcaniser',
    'volcanises',
    'volcanisés',
    'volcanisez',
    'volcanisme',
    'voleraient',
    'voletaient',
    'voletasses',
    'voletèrent',
    'voletterai',
    'voletteras',
    'voletterez',
    'voligeages',
    'voligeâmes',
    'voligeasse',
    'voligeâtes',
    'voligerais',
    'voligerait',
    'voligèrent',
    'voligeriez',
    'voligerons',
    'voligeront',
    'volleyeurs',
    'volleyeuse',
    'volontaire',
    'volontiers',
    'voltaïques',
    'voltairien',
    'voltamètre',
    'voltampère',
    'voltassent',
    'voltassiez',
    'volte-face',
    'volterions',
    'voltigeais',
    'voltigeait',
    'voltigeant',
    'voltigeons',
    'voltigerai',
    'voltigeras',
    'voltigerez',
    'voltigeurs',
    'voltigeuse',
    'voltigions',
    'voltmètres',
    'volubilité',
    'volucelles',
    'volumétrie',
    'volumineux',
    'voluptueux',
    'vomérienne',
    'vomiquiers',
    'vomiraient',
    'vomissions',
    'vomissures',
    'vomitoires',
    'voracement',
    'vorticelle',
    'votassions',
    'voteraient',
    'vouassions',
    'voudraient',
    'voueraient',
    'voulussent',
    'voulussiez',
    'vous-mêmes',
    'vousoierai',
    'vousoieras',
    'vousoierez',
    'vousoyâmes',
    'vousoyasse',
    'vousoyâtes',
    'vousoyions',
    'voussoient',
    'voussoiera',
    'voussoyais',
    'voussoyait',
    'voussoyant',
    'voussoyées',
    'voussoyiez',
    'voussoyons',
    'voûtassent',
    'voûtassiez',
    'voûterions',
    'vouvoierai',
    'vouvoieras',
    'vouvoierez',
    'vouvoyâmes',
    'vouvoyasse',
    'vouvoyâtes',
    'vouvoyions',
    'voyageâmes',
    'voyageasse',
    'voyageâtes',
    'voyagerais',
    'voyagerait',
    'voyagèrent',
    'voyageriez',
    'voyagerons',
    'voyageront',
    'voyageuses',
    'voyeurisme',
    'vrillaient',
    'vrillasses',
    'vrillerais',
    'vrillerait',
    'vrillèrent',
    'vrilleriez',
    'vrillerons',
    'vrilleront',
    'vrillettes',
    'vrombirais',
    'vrombirait',
    'vrombirent',
    'vrombiriez',
    'vrombirons',
    'vrombiront',
    'vrombisses',
    'vrombissez',
    'vulcanales',
    'vulcaniens',
    'vulcanisai',
    'vulcanisas',
    'vulcanisât',
    'vulcanisée',
    'vulcaniser',
    'vulcanises',
    'vulcanisés',
    'vulcanisez',
    'vulgarisai',
    'vulgarisas',
    'vulgarisât',
    'vulgarisée',
    'vulgariser',
    'vulgarises',
    'vulgarisés',
    'vulgarisez',
    'vulgarisme',
    'vulgarités',
    'vulnérable',
    'vulnéraire',
    'vulnérante',
    'vulnérants',
    'wagnériens',
    'wagonniers',
    'wallingant',
    'wallonisme',
    'warrantage',
    'warrantais',
    'warrantait',
    'warrantant',
    'warrantées',
    'warrantent',
    'warrantera',
    'warrantiez',
    'warrantons',
    'wassingues',
    'watergangs',
    'wateringue',
    'water-polo',
    'waterproof',
    'wattheures',
    'wattmètres',
    'winchester',
    'wisigothes',
    'wurmiennes',
    'wyandottes',
    'xénélasies',
    'xénophiles',
    'xénophilie',
    'xénophobes',
    'xénophobie',
    'xéranthème',
    'xérodermie',
    'xérophiles',
    'xiphoïdien',
    'xiphophore',
    'xylographe',
    'xylophages',
    'xylophones',
    'yacht-club',
    'yachtwoman',
    'yachtwomen',
    'yaourtière',
    'yohimbehes',
    'yohimbines',
    'yougoslave',
    'yponomeute',
    'ytterbines',
    'ytterbiums',
    'yttrialite',
    'yttrifères',
    'zambiennes',
    'zébrassent',
    'zébrassiez',
    'zébrerions',
    'zélatrices',
    'zénithales',
    'zéphyriens',
    'zéphyrines',
    'zestassent',
    'zestassiez',
    'zesterions',
    'zététiques',
    'zézaiement',
    'zézaierais',
    'zézaierait',
    'zézaieriez',
    'zézaierons',
    'zézaieront',
    'zézayaient',
    'zézayasses',
    'zézayerais',
    'zézayerait',
    'zézayèrent',
    'zézayeriez',
    'zézayerons',
    'zézayeront',
    'zibassions',
    'ziberaient',
    'zieutaient',
    'zieutasses',
    'zieuterais',
    'zieuterait',
    'zieutèrent',
    'zieuteriez',
    'zieuterons',
    'zieuteront',
    'ziggourats',
    'zigouillai',
    'zigouillas',
    'zigouillât',
    'zigouillée',
    'zigouiller',
    'zigouilles',
    'zigouillés',
    'zigouillez',
    'ziguassent',
    'ziguassiez',
    'ziguerions',
    'zigzaguais',
    'zigzaguait',
    'zigzaguant',
    'zigzaguent',
    'zigzaguera',
    'zigzaguiez',
    'zigzaguons',
    'zincifères',
    'zinguaient',
    'zinguasses',
    'zinguerais',
    'zinguerait',
    'zinguèrent',
    'zingueriez',
    'zinguerons',
    'zingueront',
    'zinzinulai',
    'zinzinulas',
    'zinzinulât',
    'zinzinuler',
    'zinzinules',
    'zinzinulez',
    'zippassent',
    'zippassiez',
    'zipperions',
    'zirconites',
    'zirconiums',
    'zodiacales',
    'zonassions',
    'zoneraient',
    'zoogamètes',
    'zoolâtries',
    'zoologique',
    'zoologiste',
    'zoomorphes',
    'zoophilies',
    'zoophobies',
    'zootechnie',
    'zostériens',
    'zozotaient',
    'zozotasses',
    'zozotement',
    'zozoterais',
    'zozoterait',
    'zozotèrent',
    'zozoteriez',
    'zozoterons',
    'zozoteront',
    'zucchettes',
    'zygomorphe',
    'zygopétale',
    'zymotiques'
];
