import dict from './words.dictionaries';

function removeFoundedLetter(availableLetters, searchedLetter) {
    const result = [];
    for (let i = 0; i < availableLetters.length; i += 1) {
        if (availableLetters[i] === searchedLetter) {
            return result.concat(availableLetters.slice(i + 1));
        } else {
            result.push(availableLetters[i]);
        }
    }
    return result;
}

function allLettersIncluded(wordLetters, availableLetters) {
    let lettersList = [...availableLetters];
    for (let i = 0; i < wordLetters.length; i += 1) {
        const startLettersListLength = lettersList.length;
        lettersList = removeFoundedLetter(lettersList, wordLetters[i]);
        if (lettersList.length === startLettersListLength) {
            return false;
        }
    }
    return true;
}

const wordSearch = (letters, wordLength) => {
    const orderedLetters = letters.toLowerCase().split('').sort();
    const dictionary = dict[wordLength];

    const isSameLength = orderedLetters.length === wordLength;
    const correctWords = [];

    dictionary.forEach((word) => {
        const letters = word.split('').sort();
        if (isSameLength) {
            if (letters.join('') === orderedLetters.join('')) {
                correctWords.push(word);
            }
        } else {
            if (allLettersIncluded(letters, orderedLetters)) {
                correctWords.push(word);
            }
        }
    });

    return correctWords;
};

export default wordSearch;
