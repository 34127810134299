export default [
    'constitutionnalisassent',
    'constitutionnalisassiez',
    'constitutionnaliserions',
    'contre-révolutionnaires',
    'électroencéphalogrammes',
    'hospitalo-universitaire',
    'inconstitutionnellement',
    'institutionnalisassions',
    'institutionnaliseraient',
    'médico-professionnelles',
    'moissonneuses-batteuses'
];
