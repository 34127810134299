import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import {
    SIGN_SUCCESS,
    SIGN_FAIL,
    AUTH_LOADING_FINISHED,
    AUTH_LOADING,
    SIGN_OUT
} from './auth.types';
import EVENT_AUTH from '../common/event.types';

export const signIn = (email, password) => async (dispatch) => {
    dispatch({ type: AUTH_LOADING });
    try {
        const auth = getAuth();
        const response = await signInWithEmailAndPassword(auth, email, password);
        dispatch({ type: SIGN_SUCCESS, payload: { user: { email: response.user.email } } });
        dispatch({ type: AUTH_LOADING_FINISHED });
        dispatch({ type: EVENT_AUTH, payload: { type: 'auth', code: 'connected', value: {} } });
    } catch (error) {
        dispatch({ type: SIGN_FAIL, payload: { error } });
        dispatch({ type: AUTH_LOADING_FINISHED });
    }
};

export const logOut = () => async (dispatch) => {
    dispatch({ type: AUTH_LOADING });
    dispatch({ type: EVENT_AUTH, payload: { type: 'auth', code: 'disconnected', value: {} } });
    try {
        const auth = getAuth();
        await signOut(auth);
        dispatch({ type: SIGN_OUT });
        dispatch({ type: AUTH_LOADING_FINISHED });
    } catch (error) {
        dispatch({ type: AUTH_LOADING_FINISHED });
    }
};
