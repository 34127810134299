import LordsMobileHunt from './Hunt/Hunt.screen';

const routes = [
    {
        path: '/cheats/lords-mobile-hunt',
        element: <LordsMobileHunt />,
        title: 'Lords Mobile - Chasse',
        info: "Résumé du nombre de coups à porter par monstre par level et par type d'attaques."
    }
];

export default routes;
