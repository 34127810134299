import Home from './index';

const routes = [
    {
        path: '/home',
        element: <Home />,
        name: 'Accueil'
    }
];

export default routes;
