import React, { useEffect } from 'react';
import Transport from '../Transport';

export default function Home() {
    useEffect(() => {
        document.title = 'Home - Morgan DENIS';
    }, []);

    return (
        <React.Fragment>
            <Transport />
        </React.Fragment>
    );
}
