import React, { useEffect } from 'react';
import Bongo from './Music/Bongo';

export default function KidGames() {
    useEffect(() => {
        document.title = 'Home - Jeux';
    }, []);
    return (
        <React.Fragment>
            <Bongo />
        </React.Fragment>
    );
}
