import subRoutes from './sub.routes';
import Cheat from './Cheat.screen';

const routes = [
    ...subRoutes,
    {
        path: '/cheats',
        element: <Cheat />,
        name: 'Triches'
    }
];

export default routes;
