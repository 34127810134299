import Auth from './index';

const routes = [
    {
        path: '/sign-in',
        element: <Auth />,
        name: 'Connexion'
    }
];

export default routes;
