export default [
    'ace',
    'âge',
    'âgé',
    'agi',
    'aie',
    'ail',
    'air',
    'ais',
    'aïs',
    'ait',
    'ale',
    'âme',
    'ami',
    'ana',
    'âne',
    'ans',
    'api',
    'ara',
    'arc',
    'are',
    'ars',
    'art',
    'aux',
    'Avé',
    'axa',
    'axe',
    'axé',
    'bac',
    'bah',
    'bai',
    'bal',
    'ban',
    'bar',
    'bas',
    'bat',
    'béa',
    'bec',
    'bée',
    'bel',
    'ben',
    'ber',
    'bey',
    'bic',
    'bis',
    'bit',
    'blé',
    'boa',
    'bob',
    'bof',
    'bol',
    'bon',
    'bop',
    'bot',
    'box',
    'boy',
    'bru',
    'bue',
    'bus',
    'but',
    'bût',
    'bye',
    'cab',
    'cal',
    'cap',
    'car',
    'cas',
    'cep',
    'ces',
    'cet',
    'chu',
    'cil',
    'clé',
    'coi',
    'col',
    'con',
    'coq',
    'cor',
    'cou',
    'cri',
    'cru',
    'crû',
    'cul',
    'dam',
    'dan',
    'dép',
    'der',
    'des',
    'dés',
    'dès',
    'dey',
    'dia',
    'dis',
    'dit',
    'dît',
    'div',
    'dix',
    'dol',
    'dom',
    'don',
    'dos',
    'dot',
    'dru',
    'dry',
    'duc',
    'due',
    'duo',
    'dur',
    'dus',
    'dûs',
    'dut',
    'dût',
    'eau',
    'écu',
    'ego',
    'élu',
    'ème',
    'ému',
    'éon',
    'épi',
    'ère',
    'erg',
    'est',
    'etc',
    'été',
    'eue',
    'euh',
    'eus',
    'eut',
    'eût',
    'eux',
    'far',
    'fat',
    'fax',
    'fée',
    'fer',
    'feu',
    'fez',
    'fia',
    'fic',
    'fie',
    'fié',
    'fig',
    'fil',
    'fin',
    'fis',
    'fit',
    'fît',
    'fla',
    'foc',
    'foi',
    'fol',
    'for',
    'fou',
    'fox',
    'fui',
    'fur',
    'fus',
    'fut',
    'fût',
    'gag',
    'gai',
    'gal',
    'gap',
    'gay',
    'gaz',
    'gel',
    'gin',
    'gis',
    'gît',
    'glu',
    'goï',
    'goy',
    'gré',
    'gué',
    'gui',
    'gus',
    'gym',
    'haï',
    'han',
    'hem',
    'hep',
    'heu',
    'hic',
    'hie',
    'hop',
    'hou',
    'hua',
    'hue',
    'hué',
    'hui',
    'hum',
    'ici',
    'ide',
    'ifs',
    'île',
    'ils',
    'ion',
    'ira',
    'ire',
    'ive',
    'jan',
    'jar',
    'jas',
    'jet',
    'jeu',
    'job',
    'jus',
    'kan',
    'kif',
    'kil',
    'kir',
    'kit',
    'ksi',
    'lac',
    'lad',
    'lai',
    'las',
    'lat',
    'lei',
    'les',
    'lès',
    'let',
    'leu',
    'lev',
    'lia',
    'lie',
    'lié',
    'lin',
    'lis',
    'lit',
    'lob',
    'lof',
    'loi',
    'lot',
    'lue',
    'lui',
    'lus',
    'lut',
    'lût',
    'lux',
    'lys',
    'mac',
    'mai',
    'mal',
    'man',
    'mat',
    'mât',
    'max',
    'mec',
    'men',
    'mer',
    'mes',
    'met',
    'mie',
    'mil',
    'min',
    'mir',
    'mis',
    'mit',
    'mît',
    'moi',
    'mol',
    'mon',
    'mot',
    'mou',
    'mua',
    'mue',
    'mué',
    'mur',
    'mûr',
    'mus',
    'mut',
    'mût',
    'née',
    'nef',
    'néo',
    'nés',
    'net',
    'nez',
    'nia',
    'nib',
    'nid',
    'nie',
    'nié',
    'nif',
    'nom',
    'non',
    'nos',
    'nua',
    'nue',
    'nué',
    'nul',
    'nus',
    'ode',
    'off',
    'ohé',
    'ohm',
    'oie',
    'oïl',
    'ois',
    'olé',
    'ont',
    'ope',
    'ors',
    'osa',
    'ose',
    'osé',
    'ôta',
    'ôte',
    'ôté',
    'ouf',
    'oui',
    'ouï',
    'out',
    'ove',
    'paf',
    'pal',
    'pan',
    'par',
    'pas',
    'pat',
    'pep',
    'peu',
    'phi',
    'pic',
    'pie',
    'pif',
    'pin',
    'pis',
    'pli',
    'plu',
    'pop',
    'pot',
    'pou',
    'pré',
    'pro',
    'psi',
    'pst',
    'pua',
    'pub',
    'pue',
    'pué',
    'pur',
    'pus',
    'put',
    'pût',
    'puy',
    'que',
    'qui',
    'rai',
    'ras',
    'rat',
    'raz',
    'réa',
    'rée',
    'réé',
    'réf',
    'reg',
    'rem',
    'rhé',
    'rhô',
    'ria',
    'rie',
    'ris',
    'rit',
    'rît',
    'riz',
    'rob',
    'roc',
    'roi',
    'rot',
    'rôt',
    'rua',
    'rue',
    'rué',
    'rus',
    'rut',
    'sac',
    'saï',
    'sas',
    'sec',
    'sel',
    'sen',
    'sep',
    'ses',
    'set',
    'sic',
    'sil',
    'sir',
    'sis',
    'six',
    'ski',
    'soc',
    'soi',
    'sol',
    'son',
    'sot',
    'sou',
    'sua',
    'suc',
    'sud',
    'sue',
    'sué',
    'sur',
    'sûr',
    'sus',
    'sut',
    'sût',
    'tac',
    'tan',
    'tas',
    'tee',
    'tek',
    'tel',
    'tes',
    'tés',
    'thé',
    'tic',
    'tif',
    'tir',
    'toc',
    'toi',
    'ton',
    'top',
    'tôt',
    'tri',
    'tua',
    'tub',
    'tue',
    'tué',
    'tuf',
    'tus',
    'tut',
    'tût',
    'une',
    'uni',
    'uns',
    'ure',
    'usa',
    'use',
    'usé',
    'val',
    'van',
    'var',
    'vas',
    'ver',
    'vêt',
    'via',
    'vie',
    'vif',
    'vil',
    'vin',
    'vis',
    'vit',
    'vît',
    'vol',
    'vos',
    'vue',
    'vus',
    'won',
    'yak',
    'yen',
    'yin',
    'zée',
    'zen',
    'zig',
    'zoo',
    'zou',
    'zut'
];
