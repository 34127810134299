import React, { useEffect, useState } from 'react';
import wordSearch from './words.utils';
import { TextField, Button, Typography, Stack, Paper } from '@mui/material';
import PageTitle from '../../common/Components/PageTitle';

export default function Words() {
    const [letters, setLetter] = useState('');
    const [result, setResult] = useState([]);

    useEffect(() => {
        document.title = 'Home - Cheats - words';
    }, []);

    function search() {
        const result = [];
        for (let i = 3; i <= letters.length; i += 1) {
            result.push({
                title: `${i} lettres`,
                words: wordSearch(letters, i).join(', ') || 'N/A'
            });
        }
        setResult(result);
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            search();
        }
    };

    return (
        <React.Fragment>
            <PageTitle title="Trouve-mots" />
            <div className="row mt-m">
                <TextField
                    type="text"
                    value={letters}
                    onChange={(e) => setLetter(e.target.value)}
                    autoFocus
                    autocapitalize="off"
                    autocomplete="off"
                    spellcheck="false"
                    autocorrect="off"
                    variant="outlined"
                    fullWidth
                    label="Lettres disponibles"
                    onKeyPress={handleKeyPress}
                />
            </div>
            <div className="row mt-m justify-center">
                <Button variant="contained" color="primary" onClick={() => search()} size="large">
                    Chercher
                </Button>
            </div>
            <div className="row mt-m">
                <Stack spacing={3} className="full">
                    {result.map((r, i) => (
                        <Paper elevation={6} key={i} className="p-s">
                            <Typography variant="h6">{r.title}</Typography>
                            <Typography>{r.words}</Typography>
                        </Paper>
                    ))}
                </Stack>
            </div>
        </React.Fragment>
    );
}
