export default [
    'angiocardiographies',
    'anticonceptionnelle',
    'anticonjoncturelles',
    'anticonstitutionnel',
    'antigouvernementale',
    'antigouvernementaux',
    'antigravitationnels',
    'anti-inflammatoires',
    'anti-inflationniste',
    'antiparlementarisme',
    'antipoliomyélitique',
    'approvisionnassions',
    'approvisionneraient',
    'arrière-grand-mères',
    'arrière-grand-oncle',
    'arrière-grand-tante',
    'arrière-petit-neveu',
    'arrière-petits-fils',
    'assurance-accidents',
    'autodéterminassions',
    'autosuggestionnâmes',
    'autosuggestionnâtes',
    'autosuggestionnerez',
    'autosuggestionnions',
    'bureaucratisassions',
    'bureaucratiseraient',
    'cardiorespiratoires',
    'céphalo-rachidienne',
    'chirurgien-dentiste',
    'chromolithographies',
    'chronophotographies',
    'cinématographiaient',
    'cinématographiasses',
    'cinématographierais',
    'cinématographierait',
    'cinématographièrent',
    'cinématographieriez',
    'cinématographierons',
    'cinématographieront',
    'circonstanciassions',
    'circonstancieraient',
    'collaborationnistes',
    'commercialisassions',
    'commercialiseraient',
    'commissaire-priseur',
    'compartimentassions',
    'compartimenteraient',
    'concentrationnaires',
    'conceptualisassions',
    'conceptualiseraient',
    'constitutionnalisai',
    'constitutionnalisas',
    'constitutionnalisât',
    'constitutionnalisée',
    'constitutionnaliser',
    'constitutionnalises',
    'constitutionnalisés',
    'constitutionnalisez',
    'constitutionnalités',
    'contractualisassent',
    'contractualisassiez',
    'contractualiserions',
    'contre-attaquassent',
    'contre-attaquassiez',
    'contre-attaquerions',
    'contrebalançassions',
    'contrebalanceraient',
    'contre-dénonciation',
    'contre-indiquassent',
    'contre-indiquassiez',
    'contre-indiquerions',
    'contre-manifestâmes',
    'contre-manifestante',
    'contre-manifestants',
    'contre-manifestasse',
    'contre-manifestâtes',
    'contre-manifesterai',
    'contre-manifesteras',
    'contre-manifesterez',
    'contre-manifestions',
    'contre-performances',
    'contre-plaquassions',
    'contre-plaqueraient',
    'contre-préparations',
    'contre-propositions',
    'contre-scellassions',
    'contre-scelleraient',
    'conventionnellement',
    'correctionnalisâmes',
    'correctionnalisasse',
    'correctionnalisâtes',
    'correctionnaliserai',
    'correctionnaliseras',
    'correctionnaliserez',
    'correctionnalisions',
    'court-circuitassent',
    'court-circuitassiez',
    'court-circuiterions',
    'dactylographiassent',
    'dactylographiassiez',
    'dactylographierions',
    'débroussaillassions',
    'débroussailleraient',
    'débureaucratisaient',
    'débureaucratisasses',
    'débureaucratiserais',
    'débureaucratiserait',
    'débureaucratisèrent',
    'débureaucratiseriez',
    'débureaucratiserons',
    'débureaucratiseront',
    'déchristianisassent',
    'déchristianisassiez',
    'déchristianisations',
    'déchristianiserions',
    'décléricalisassions',
    'décléricaliseraient',
    'déconditionnassions',
    'déconditionneraient',
    'décongestionnassent',
    'décongestionnassiez',
    'décongestionnerions',
    'décriminalisassions',
    'décriminaliseraient',
    'déculpabilisassions',
    'déculpabiliseraient',
    'dédifférenciassions',
    'dédifférencieraient',
    'dématérialisassions',
    'dématérialiseraient',
    'dénationalisassions',
    'dénationaliseraient',
    'départementalierais',
    'départementalierait',
    'départementalieriez',
    'départementalierons',
    'départementalieront',
    'départementalisâmes',
    'départementalisasse',
    'départementalisâtes',
    'départementalisions',
    'dépersonnalisassent',
    'dépersonnalisassiez',
    'dépersonnalisations',
    'dépersonnaliserions',
    'déprolétarisassions',
    'déprolétariseraient',
    'désaffectionnassent',
    'désaffectionnassiez',
    'désaffectionnerions',
    'désapprovisionnâmes',
    'désapprovisionnasse',
    'désapprovisionnâtes',
    'désapprovisionnerai',
    'désapprovisionneras',
    'désapprovisionnerez',
    'désapprovisionnions',
    'désembourgeoisaient',
    'désembourgeoisasses',
    'désembourgeoiserais',
    'désembourgeoiserait',
    'désembourgeoisèrent',
    'désembourgeoiseriez',
    'désembourgeoiserons',
    'désembourgeoiseront',
    'désembouteillassent',
    'désembouteillassiez',
    'désembouteillerions',
    'désemprisonnassions',
    'désemprisonneraient',
    'désensibilisassions',
    'désensibiliseraient',
    'désensorcelleraient',
    'désentortillassions',
    'désentortilleraient',
    'déshypothéquassions',
    'déshypothéqueraient',
    'désillusionnassions',
    'désillusionneraient',
    'désingularisassions',
    'désingulariseraient',
    'désoxyribonucléique',
    'désubjectivisassent',
    'désubjectivisassiez',
    'désubjectiviserions',
    'désynchronisassions',
    'désynchroniseraient',
    'disproportionnaient',
    'disproportionnasses',
    'disproportionnerais',
    'disproportionnerait',
    'disproportionnèrent',
    'disproportionneriez',
    'disproportionnerons',
    'disproportionneront',
    'échantillonnassions',
    'échantillonneraient',
    'électro-acoustiques',
    'électrocardiogramme',
    'électrocardiographe',
    'électrocoagulations',
    'électrodynamomètres',
    'électroluminescence',
    'électrophysiologies',
    'électroradiologiste',
    'emberlificotassions',
    'emberlificoteraient',
    'embroussaillassions',
    'embroussailleraient',
    'entre-déchirassions',
    'entre-détruisissiez',
    'entre-regardassions',
    'environnementaliste',
    'extra-parlementaire',
    'fonctionnarisassent',
    'fonctionnarisassiez',
    'fonctionnarisations',
    'fonctionnariserions',
    'fusils-mitrailleurs',
    'gastro-intestinales',
    'gouvernementalismes',
    'grammaticalisations',
    'grammaticaliserions',
    'grand-guignolesques',
    'hauts-commissariats',
    'hispano-américaines',
    'histocompatibilités',
    'hypersustentatrices',
    'immatérialisassions',
    'immatérialiseraient',
    'imperméabilisassent',
    'imperméabilisassiez',
    'imperméabilisations',
    'imperméabiliserions',
    'imprescriptibilités',
    'impressionnabilités',
    'incommensurablement',
    'incompréhensibilité',
    'inconstitutionnelle',
    'individualisassions',
    'individualiseraient',
    'industrialisassions',
    'industrialiseraient',
    'ingénieurs-conseils',
    'insensibilisassions',
    'insensibiliseraient',
    'institutionnalisais',
    'institutionnalisait',
    'institutionnalisant',
    'institutionnalisées',
    'institutionnalisent',
    'institutionnalisera',
    'institutionnalisiez',
    'institutionnalismes',
    'institutionnalisons',
    'intellectualisaient',
    'intellectualisasses',
    'intellectualiserais',
    'intellectualiserait',
    'intellectualisèrent',
    'intellectualiseriez',
    'intellectualiserons',
    'intellectualiseront',
    'interchangeabilités',
    'intercommunications',
    'interdépartementale',
    'interdépartementaux',
    'interdisciplinaires',
    'interdisciplinarité',
    'intergouvernemental',
    'interministérielles',
    'internationalisâmes',
    'internationalisasse',
    'internationalisâtes',
    'internationaliserai',
    'internationaliseras',
    'internationaliserez',
    'internationalisions',
    'interparlementaires',
    'interprofessionnels',
    'intra-communautaire',
    'intradermo-réaction',
    'irrévérencieusement',
    'judéo-christianisme',
    'laissés-pour-compte',
    'lymphogranulomatose',
    'magnéto-électriques',
    'maîtres-assistantes',
    'manutentionnassions',
    'manutentionneraient',
    'mécanicien-dentiste',
    'médico-pédagogiques',
    'micro-informatiques',
    'miséricordieusement',
    'moissonneuse-lieuse',
    'multidimensionnelle',
    'multidisciplinaires',
    'multiprogrammations',
    'national-socialisme',
    'national-socialiste',
    'non-responsabilités',
    'occidentalisassions',
    'occidentaliseraient',
    'odontostomatologies',
    'omnidirectionnelles',
    'particularisassions',
    'particulariseraient',
    'perpendiculairement',
    'perquisitionnassent',
    'perquisitionnassiez',
    'perquisitionnerions',
    'petites-bourgeoises',
    'pharmacodépendances',
    'photoélasticimétrie',
    'pluridisciplinaires',
    'pluridisciplinarité',
    'pochettes-surprises',
    'portraiturerassions',
    'postsynchronisaient',
    'postsynchronisasses',
    'postsynchronisation',
    'postsynchroniserais',
    'postsynchroniserait',
    'postsynchronisèrent',
    'postsynchroniseriez',
    'postsynchroniserons',
    'postsynchroniseront',
    'précautionneusement',
    'président-directeur',
    'processionnellement',
    'professionnellement',
    'proportionnellement',
    'proverbialisassions',
    'proverbialiseraient',
    'pseudo-fécondations',
    'psycho-analeptiques',
    'psycholinguistiques',
    'psychopharmacologie',
    'psychophysiologique',
    'psychotechniciennes',
    'quatre-vingt-quatre',
    'quatre-vingt-quinze',
    'quatre-vingt-treize',
    'quintessenciassions',
    'quintessencieraient',
    'radicale-socialiste',
    'radiocommunications',
    'radiométallographie',
    'radiotélégraphiâmes',
    'radiotélégraphiasse',
    'radiotélégraphiâtes',
    'radiotélégraphierai',
    'radiotélégraphieras',
    'radiotélégraphierez',
    'radiotélégraphiions',
    'radiotélégraphiques',
    'radiotélégraphistes',
    'rapprovisionnassent',
    'rapprovisionnassiez',
    'rapprovisionnerions',
    'réapprovisionnaient',
    'réapprovisionnasses',
    'réapprovisionnerais',
    'réapprovisionnerait',
    'réapprovisionnèrent',
    'réapprovisionneriez',
    'réapprovisionnerons',
    'réapprovisionneront',
    'rechristianisassent',
    'rechristianisassiez',
    'rechristianiserions',
    'recristallisassions',
    'recristalliseraient',
    'recroquevillassions',
    'recroquevilleraient',
    'rééquilibrerassions',
    'réimperméabilisâmes',
    'réimperméabilisasse',
    'réimperméabilisâtes',
    'réimperméabiliserai',
    'réimperméabiliseras',
    'réimperméabiliserez',
    'réimperméabilisions',
    'reporters-cameramen',
    'républicanisassions',
    'républicaniseraient',
    'réquisitionnassions',
    'réquisitionneraient',
    'responsabilisassent',
    'responsabilisassiez',
    'responsabiliserions',
    'révolutionnairement',
    'socio-professionnel',
    'socioprofessionnels',
    'sous-alimentassions',
    'sous-alimenteraient',
    'sous-bibliothécaire',
    'sous-développements',
    'sous-embranchements',
    'sous-exploitassions',
    'sous-exploiteraient',
    'sténodactylographes',
    'sténodactylographie',
    'strobophotographies',
    'surmédicalisassions',
    'surmédicaliseraient',
    'syncristallisassent',
    'syncristallisassiez',
    'syncristalliserions',
    'technocratisassions',
    'technocratiseraient',
    'thalassothérapeutes',
    'thermodynamiciennes',
    'thermoluminescences',
    'tire-bouchonnassent',
    'tire-bouchonnassiez',
    'tirebouchonnassions',
    'tirebouchonneraient',
    'tire-bouchonnerions',
    'transformationnelle',
    'transistorisassions',
    'transistoriseraient',
    'transsubstantiaient',
    'transsubstantiasses',
    'transsubstantiation',
    'transsubstantierais',
    'transsubstantierait',
    'transsubstantièrent',
    'transsubstantieriez',
    'transsubstantierons',
    'transsubstantieront',
    'trigonométriquement',
    'trompette-des-morts',
    'tuberculinisassions',
    'tuberculiniseraient',
    'ultracentrifugation',
    'ultramicroscopiques'
];
