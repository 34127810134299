import Transport from './index';

const routes = [
    {
        path: '/transport',
        element: <Transport />,
        name: 'Transport'
    }
];

export default routes;
