export default [
    'anarcho-syndicalismes',
    'anarcho-syndicalistes',
    'anticonstitutionnelle',
    'antigravitationnelles',
    'antiségrégationnistes',
    'arrière-grands-oncles',
    'arrière-petits-neveux',
    'autosuggestionnassiez',
    'autosuggestionnerions',
    'chirurgiens-dentistes',
    'cinématographiassions',
    'cinématographieraient',
    'cinématographiquement',
    'commissaires-priseurs',
    'constitutionnalisâmes',
    'constitutionnalisasse',
    'constitutionnalisâtes',
    'constitutionnaliserai',
    'constitutionnaliseras',
    'constitutionnaliserez',
    'constitutionnalisions',
    'constitutionnellement',
    'contre-électromotrice',
    'contre-interrogatoire',
    'contre-manifestassent',
    'contre-manifestassiez',
    'contre-manifestations',
    'contre-manifesterions',
    'correctionnalisassent',
    'correctionnalisassiez',
    'correctionnaliserions',
    'débureaucratisassions',
    'débureaucratiseraient',
    'départementalieraient',
    'départementalisassent',
    'départementalisassiez',
    'départementalisations',
    'désapprovisionnassent',
    'désapprovisionnassiez',
    'désapprovisionnements',
    'désapprovisionnerions',
    'désembourgeoisassions',
    'désembourgeoiseraient',
    'disproportionnassions',
    'disproportionneraient',
    'électrocardiographies',
    'inconstitutionnalités',
    'institutionnalisaient',
    'institutionnalisasses',
    'institutionnalisation',
    'institutionnaliserais',
    'institutionnaliserait',
    'institutionnalisèrent',
    'institutionnaliseriez',
    'institutionnaliserons',
    'institutionnaliseront',
    'intellectualisassions',
    'intellectualiseraient',
    'intergouvernementales',
    'internationalisassent',
    'internationalisassiez',
    'internationalisations',
    'internationaliserions',
    'interprofessionnelles',
    'mandats-contributions',
    'mécaniciens-dentistes',
    'médico-professionnels',
    'moissonneuse-batteuse',
    'moissonneuses-lieuses',
    'nationaux-socialistes',
    'physico-mathématiques',
    'postsynchronisassions',
    'postsynchroniseraient',
    'présidents-directeurs',
    'quatre-vingt-dix-huit',
    'quatre-vingt-dix-neuf',
    'quatre-vingt-dix-sept',
    'quatre-vingt-quatorze',
    'radicales-socialistes',
    'radiocristallographie',
    'radiotélégraphiassent',
    'radiotélégraphiassiez',
    'radiotélégraphierions',
    'réapprovisionnassions',
    'réapprovisionneraient',
    'réimperméabilisassent',
    'réimperméabilisassiez',
    'réimperméabiliserions',
    'socio-professionnelle',
    'socioprofessionnelles',
    'technobureaucratiques',
    'tétrahydronaphtalines',
    'transsubstantiassions',
    'transsubstantieraient'
];
