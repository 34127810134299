import homeRoutes from '../Home/home.routes';
import authRoutes from '../Auth/auth.routes';
import transportRoutes from '../Transport/transport.routes';
import administrationRoutes from '../Administration/administration.routes';
import spendingRoutes from '../Spending/spending.routes';
import cheatRoutes from '../Cheat/cheat.routes';
import kidGamesRoutes from '../KidGames/kidGames.routes';
import Home from '../Home';

const DEFAULT_ROUTES = [
    {
        path: '*',
        element: <Home />
    }
];

export const routes = homeRoutes
    .concat(administrationRoutes)
    .concat(authRoutes)
    .concat(transportRoutes)
    .concat(spendingRoutes)
    .concat(cheatRoutes)
    .concat(kidGamesRoutes)
    .concat(DEFAULT_ROUTES);

export const menuRoutes = () => routes.filter((route) => route.name);
