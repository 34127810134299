export default [
    'anarcho-syndicalisme',
    'anarcho-syndicaliste',
    'anticonceptionnelles',
    'anticonstitutionnels',
    'antigouvernementales',
    'antigravitationnelle',
    'anti-inflationnistes',
    'antiparlementarismes',
    'antipoliomyélitiques',
    'antiségrégationniste',
    'arrière-grands-pères',
    'arrière-grand-tantes',
    'arrière-petite-fille',
    'arrière-petite-nièce',
    'assurance-invalidité',
    'assurance-vieillesse',
    'autosuggestionneriez',
    'autosuggestionnerons',
    'céphalo-rachidiennes',
    'cinématographiassent',
    'cinématographiassiez',
    'cinématographierions',
    'constitutionnalisais',
    'constitutionnalisait',
    'constitutionnalisant',
    'constitutionnalisées',
    'constitutionnalisent',
    'constitutionnalisera',
    'constitutionnalisiez',
    'constitutionnalisons',
    'contractualisassions',
    'contractualiseraient',
    'contre-attaquassions',
    'contre-attaqueraient',
    'contre-dénonciations',
    'contre-indiquassions',
    'contre-indiqueraient',
    'contre-manifestaient',
    'contre-manifestantes',
    'contre-manifestasses',
    'contre-manifestation',
    'contre-manifesterais',
    'contre-manifesterait',
    'contre-manifestèrent',
    'contre-manifesteriez',
    'contre-manifesterons',
    'contre-manifesteront',
    'correctionnalisaient',
    'correctionnalisasses',
    'correctionnaliserais',
    'correctionnaliserait',
    'correctionnalisèrent',
    'correctionnaliseriez',
    'correctionnaliserons',
    'correctionnaliseront',
    'court-circuitassions',
    'court-circuiteraient',
    'dactylographiassions',
    'dactylographieraient',
    'débureaucratisassent',
    'débureaucratisassiez',
    'débureaucratiserions',
    'déchristianisassions',
    'déchristianiseraient',
    'décongestionnassions',
    'décongestionneraient',
    'démocrate-chrétienne',
    'démocrates-chrétiens',
    'départementalierions',
    'départementalisaient',
    'départementalisasses',
    'départementalisation',
    'départementalisèrent',
    'dépersonnalisassions',
    'dépersonnaliseraient',
    'désaffectionnassions',
    'désaffectionneraient',
    'désapprovisionnaient',
    'désapprovisionnasses',
    'désapprovisionnement',
    'désapprovisionnerais',
    'désapprovisionnerait',
    'désapprovisionnèrent',
    'désapprovisionneriez',
    'désapprovisionnerons',
    'désapprovisionneront',
    'désembourgeoisassent',
    'désembourgeoisassiez',
    'désembourgeoiserions',
    'désembouteillassions',
    'désembouteilleraient',
    'désoxyribonucléiques',
    'dessous-de-bouteille',
    'désubjectivisassions',
    'désubjectiviseraient',
    'disproportionnassent',
    'disproportionnassiez',
    'disproportionnerions',
    'électrocardiogrammes',
    'électrocardiographes',
    'électrocardiographie',
    'électroluminescences',
    'électroradiologistes',
    'entre-détruisissions',
    'environnementalistes',
    'extra-parlementaires',
    'fonctionnarisassions',
    'fonctionnariseraient',
    'grammaticaliseraient',
    'imperméabilisassions',
    'imperméabiliseraient',
    'incompréhensibilités',
    'inconditionnellement',
    'inconstitutionnalité',
    'inconstitutionnelles',
    'institutionnalisâmes',
    'institutionnalisasse',
    'institutionnalisâtes',
    'institutionnaliserai',
    'institutionnaliseras',
    'institutionnaliserez',
    'institutionnalisions',
    'intellectualisassent',
    'intellectualisassiez',
    'intellectualiserions',
    'interdépartementales',
    'interdisciplinarités',
    'intergouvernementale',
    'intergouvernementaux',
    'internationalisaient',
    'internationalisasses',
    'internationalisation',
    'internationaliserais',
    'internationaliserait',
    'internationalisèrent',
    'internationaliseriez',
    'internationaliserons',
    'internationaliseront',
    'interprofessionnelle',
    'intra-communautaires',
    'intradermo-réactions',
    'judéo-christianismes',
    'lieutenants-colonels',
    'lymphogranulomatoses',
    'mandat-contributions',
    'marxistes-léninistes',
    'médico-professionnel',
    'multidimensionnelles',
    'perquisitionnassions',
    'perquisitionneraient',
    'photoélasticimétries',
    'physico-mathématique',
    'pistolet-mitrailleur',
    'pluridisciplinarités',
    'postsynchronisassent',
    'postsynchronisassiez',
    'postsynchronisations',
    'postsynchroniserions',
    'psychopharmacologies',
    'psychophysiologiques',
    'radicaux-socialistes',
    'radiométallographies',
    'radiotélégraphiaient',
    'radiotélégraphiasses',
    'radiotélégraphierais',
    'radiotélégraphierait',
    'radiotélégraphièrent',
    'radiotélégraphieriez',
    'radiotélégraphierons',
    'radiotélégraphieront',
    'rapprovisionnassions',
    'rapprovisionneraient',
    'réapprovisionnassent',
    'réapprovisionnassiez',
    'réapprovisionnerions',
    'rechristianisassions',
    'rechristianiseraient',
    'réimperméabilisaient',
    'réimperméabilisasses',
    'réimperméabiliserais',
    'réimperméabiliserait',
    'réimperméabilisèrent',
    'réimperméabiliseriez',
    'réimperméabiliserons',
    'réimperméabiliseront',
    'responsabilisassions',
    'responsabiliseraient',
    'socioprofessionnelle',
    'socio-professionnels',
    'sous-bibliothécaires',
    'sténodactylographies',
    'survolteur-dévolteur',
    'syncristallisassions',
    'syncristalliseraient',
    'technobureaucratique',
    'tétrahydronaphtaline',
    'tire-bouchonnassions',
    'tire-bouchonneraient',
    'transformationnelles',
    'transsubstantiassent',
    'transsubstantiassiez',
    'transsubstantiations',
    'transsubstantierions',
    'trompettes-des-morts',
    'ultracentrifugations'
];
