export default {
    name: 'Griffon',
    boostOneShot: {
        lv1: 1,
        lv2: 1,
        lv3: 6,
        lv4: NaN,
        lv5: NaN
    },
    oneShot: {
        lv1: 2,
        lv2: 4,
        lv3: 14,
        lv4: NaN,
        lv5: NaN
    },
    boostOneByOne: {
        lv1: NaN,
        lv2: NaN,
        lv3: NaN,
        lv4: NaN,
        lv5: NaN
    },
    oneByOne: {
        lv1: NaN,
        lv2: NaN,
        lv3: NaN,
        lv4: NaN,
        lv5: NaN
    }
};
