export const SPENDING_LOADING = 'SPENDING_LOADING';
export const SPENDING_LOADING_FINISHED = 'SPENDING_LOADING_FINISHED';
export const SPENDING_FETCH_SUCCESS = 'SPENDING_FETCH_SUCCESS';
export const SPENDING_FETCH_FAIL = 'SPENDING_FETCH_FAIL';

export const SPENDING_CREATE_SUCCESS = 'SPENDING_CREATE_SUCCESS';
export const SPENDING_CREATE_FAIL = 'SPENDING_CREATE_FAIL';

export const SPENDING_UPDATE_SUCCESS = 'SPENDING_UPDATE_SUCCESS';
export const SPENDING_UPDATE_FAIL = 'SPENDING_UPDATE_FAIL';

export const SPENDING_DELETE_SUCCESS = 'SPENDING_DELETE_SUCCESS';
export const SPENDING_DELETE_FAIL = 'SPENDING_DELETE_FAIL';

export const SPENDING_CONF_FETCH_SUCCESS = 'SPENDING_CONF_FETCH_SUCCESS';
export const SPENDING_CONF_FETCH_FAIL = 'SPENDING_CONF_FETCH_FAIL';

export const DEFAULT_EXPENSES_UPDATE_SUCCESS = 'DEFAULT_EXPENSES_UPDATE_SUCCESS';
export const DEFAULT_EXPENSES_UPDATE_FAIL = 'DEFAULT_EXPENSES_UPDATE_FAIL';
