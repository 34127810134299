import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import moment from 'moment';
import 'moment/locale/fr';
import './index.css';
import './firebase';
import App from './common/App';
import store from './common/main.store';
import initEvents from './common/app.events';

moment.locale('fr');
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <App />
    </Provider>
);

initEvents();
