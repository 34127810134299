import { initializeApp } from 'firebase/app';
import { getFirestore, collection } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import authChangeListener from './Auth/auth.listener';
import isLocalhost from './common/utils';

const config = {
    apiKey: 'AIzaSyB6CGJw-gt3f05xcsO6mNzGJzkBY_HCVMA',
    authDomain: 'morgandenis-cv.firebaseapp.com',
    databaseURL: 'https://morgandenis-cv.firebaseio.com',
    projectId: 'morgandenis-cv',
    storageBucket: 'morgandenis-cv.appspot.com',
    messagingSenderId: '150500914234',
    appId: '1:150500914234:web:115733ddfe3b3907',
    measurementId: 'G-LCG3T37HYY'
};
if (isLocalhost()) {
    console.warn('### Dev table in use ###');
}
export const TABLES = {
    temperatures: isLocalhost() ? 'temperature-dev' : 'temperature',
    pressures: isLocalhost() ? 'pressure-dev' : 'pressure',
    humidities: isLocalhost() ? 'humidity-dev' : 'humidity',
    spendings: isLocalhost() ? 'spendings-dev' : 'spendings',
    admin: 'admin'
};
initializeApp(config);
getAnalytics();

authChangeListener();

export const db = getFirestore();
export const temperatures = collection(db, TABLES.temperatures);
export const pressures = collection(db, TABLES.pressures);
export const humidities = collection(db, TABLES.humidities);
