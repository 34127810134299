export default [
    'abaque',
    'abatée',
    'abatte',
    'abattu',
    'abbaye',
    'aberra',
    'aberre',
    'aberré',
    'abêtie',
    'abêtir',
    'abêtis',
    'abêtit',
    'abêtît',
    'abîmai',
    'abîmas',
    'abîmât',
    'abîmée',
    'abîmer',
    'abîmes',
    'abîmés',
    'abîmez',
    'abject',
    'abjura',
    'abjure',
    'abjuré',
    'aboies',
    'abolie',
    'abolir',
    'abolis',
    'abolit',
    'abolît',
    'abonda',
    'abonde',
    'abondé',
    'abonna',
    'abonne',
    'abonné',
    'abonni',
    'aborda',
    'aborde',
    'abordé',
    'abords',
    'aboula',
    'aboule',
    'aboulé',
    'abouta',
    'aboute',
    'abouté',
    'abouti',
    'abouts',
    'aboyai',
    'aboyas',
    'aboyât',
    'aboyer',
    'aboyez',
    'abrasa',
    'abrase',
    'abrasé',
    'abrégé',
    'abrège',
    'abrita',
    'abrite',
    'abrité',
    'abroge',
    'abrogé',
    'abrupt',
    'abruti',
    'absent',
    'abside',
    'absolu',
    'absous',
    'absout',
    'abusai',
    'abusas',
    'abusât',
    'abusée',
    'abuser',
    'abuses',
    'abusés',
    'abusez',
    'abusif',
    'abysse',
    'acabit',
    'acacia',
    'acajou',
    'acarus',
    'acaule',
    'accéda',
    'accédé',
    'accède',
    'accent',
    'accise',
    'accola',
    'accole',
    'accolé',
    'accora',
    'accord',
    'accore',
    'accoré',
    'accort',
    'accota',
    'accote',
    'accoté',
    'accots',
    'accoua',
    'accoue',
    'accoué',
    'accroc',
    'accrue',
    'accrus',
    'accrut',
    'accrût',
    'accula',
    'accule',
    'acculé',
    'accusa',
    'accuse',
    'accusé',
    'acérai',
    'acéras',
    'acérât',
    'acerbe',
    'acérée',
    'acérer',
    'acérés',
    'acères',
    'acérez',
    'achats',
    'achéen',
    'acheta',
    'acheté',
    'achète',
    'acheva',
    'achevé',
    'achève',
    'acides',
    'aciéra',
    'aciéré',
    'acière',
    'aciers',
    'acinus',
    'aconit',
    'acores',
    'à-côté',
    'à-coup',
    'acquêt',
    'acquis',
    'acquit',
    'acquît',
    'âcreté',
    'actais',
    'actait',
    'actant',
    'actées',
    'actent',
    'actera',
    'acteur',
    'actiez',
    'actifs',
    'action',
    'activa',
    'active',
    'activé',
    'actons',
    'actuel',
    'acuité',
    'adages',
    'adagio',
    'adapta',
    'adapte',
    'adapté',
    'adents',
    'adepte',
    'adhéra',
    'adhéré',
    'adhère',
    'adieux',
    'adjuge',
    'adjugé',
    'adjura',
    'adjure',
    'adjuré',
    'admets',
    'admira',
    'admire',
    'admiré',
    'admise',
    'adnées',
    'adonis',
    'adonné',
    'adopta',
    'adopte',
    'adopté',
    'adorai',
    'adoras',
    'adorât',
    'adorée',
    'adorer',
    'adores',
    'adorés',
    'adorez',
    'adossa',
    'adosse',
    'adossé',
    'adouba',
    'adoube',
    'adoubé',
    'adouci',
    'adrets',
    'adroit',
    'adulai',
    'adulas',
    'adulât',
    'adulée',
    'aduler',
    'adules',
    'adulés',
    'adulez',
    'adulte',
    'advenu',
    'advint',
    'advînt',
    'aérais',
    'aérait',
    'aérant',
    'aérées',
    'aèrent',
    'aérera',
    'aérien',
    'aériez',
    'aérons',
    'affadi',
    'affala',
    'affale',
    'affalé',
    'affama',
    'affame',
    'affamé',
    'affect',
    'afféra',
    'afféré',
    'affère',
    'affété',
    'affidé',
    'affila',
    'affile',
    'affilé',
    'affina',
    'affine',
    'affiné',
    'affins',
    'affixe',
    'afflua',
    'afflue',
    'afflué',
    'afflux',
    'affola',
    'affole',
    'affolé',
    'affres',
    'affura',
    'affure',
    'affuré',
    'affûta',
    'affûte',
    'affûté',
    'affûts',
    'afghan',
    'afocal',
    'agaçai',
    'agaças',
    'agaçât',
    'agacée',
    'agacer',
    'agaces',
    'agacés',
    'agacez',
    'agamis',
    'agapes',
    'agaric',
    'agates',
    'agaves',
    'agavés',
    'agença',
    'agence',
    'agencé',
    'agenda',
    'agents',
    'agiles',
    'agîmes',
    'agiota',
    'agiote',
    'agioté',
    'agirai',
    'agiras',
    'agirez',
    'agisse',
    'agitai',
    'agitas',
    'agitât',
    'agitée',
    'agiter',
    'agites',
    'agités',
    'agîtes',
    'agitez',
    'agnats',
    'agneau',
    'agnela',
    'agnelé',
    'agonie',
    'agonir',
    'agonis',
    'agonit',
    'agonît',
    'agoras',
    'agouti',
    'agrafa',
    'agrafe',
    'agrafé',
    'agréai',
    'agréas',
    'agréât',
    'agréée',
    'agréer',
    'agrées',
    'agréés',
    'agréez',
    'agrégé',
    'agrège',
    'agrile',
    'agrion',
    'agrume',
    'aguets',
    'ahanai',
    'ahanas',
    'ahanât',
    'ahaner',
    'ahanes',
    'ahanez',
    'ahurie',
    'ahurir',
    'ahuris',
    'ahurit',
    'ahurît',
    'aiches',
    'aidais',
    'aidait',
    'aidant',
    'aidées',
    'aident',
    'aidera',
    'aidiez',
    'aidons',
    'aïeule',
    'aïeuls',
    'aigles',
    'aiglon',
    'aigres',
    'aigrie',
    'aigrir',
    'aigris',
    'aigrit',
    'aigrît',
    'aiguës',
    'aïkido',
    'ailées',
    'ailier',
    'aillai',
    'aillas',
    'aillât',
    'aillée',
    'ailler',
    'ailles',
    'aillés',
    'aillez',
    'aimais',
    'aimait',
    'aimant',
    'aimées',
    'aiment',
    'aimera',
    'aimiez',
    'aimons',
    'aînées',
    'aïolis',
    'airain',
    'airais',
    'airait',
    'airant',
    'airbus',
    'airent',
    'airera',
    'airiez',
    'airons',
    'aisées',
    'ajoncs',
    'ajoura',
    'ajoure',
    'ajouré',
    'ajours',
    'ajouta',
    'ajoute',
    'ajouté',
    'ajouts',
    'ajusta',
    'ajuste',
    'ajusté',
    'alaire',
    'alaise',
    'alarma',
    'alarme',
    'alarmé',
    'albédo',
    'albite',
    'albugo',
    'albums',
    'alcade',
    'alcali',
    'alcène',
    'alcool',
    'alcôve',
    'alcyne',
    'alcyon',
    'aldine',
    'aldins',
    'aldols',
    'alênes',
    'alenti',
    'alephs',
    'alerta',
    'alerte',
    'alerté',
    'alésai',
    'alésas',
    'alésât',
    'alésée',
    'aléser',
    'alésés',
    'alèses',
    'alésez',
    'alevin',
    'alezan',
    'algide',
    'algies',
    'algine',
    'algols',
    'algues',
    'alibis',
    'aliéna',
    'aliéné',
    'aliène',
    'aligna',
    'aligne',
    'aligné',
    'alinéa',
    'alises',
    'alisme',
    'alitai',
    'alitas',
    'alitât',
    'alitée',
    'aliter',
    'alites',
    'alités',
    'alitez',
    'alizés',
    'allais',
    'allait',
    'allant',
    'allées',
    'allégé',
    'allège',
    'allégi',
    'allène',
    'allers',
    'alliai',
    'allias',
    'alliât',
    'alliée',
    'allier',
    'allies',
    'alliés',
    'alliez',
    'allons',
    'alloua',
    'alloue',
    'alloué',
    'alluma',
    'allume',
    'allumé',
    'allure',
    'alluré',
    'allyle',
    'almées',
    'alogie',
    'aloses',
    'aloyau',
    'alpaga',
    'alpage',
    'alpine',
    'alpins',
    'altéra',
    'altéré',
    'altère',
    'altier',
    'altise',
    'alunai',
    'alunas',
    'alunât',
    'alunée',
    'aluner',
    'alunes',
    'alunés',
    'alunez',
    'alunir',
    'alunis',
    'alunit',
    'alunît',
    'alvine',
    'alvins',
    'alytes',
    'amadou',
    'amande',
    'amante',
    'amants',
    'amaril',
    'amarra',
    'amarre',
    'amarré',
    'amassa',
    'amasse',
    'amassé',
    'amatie',
    'amatir',
    'amatis',
    'amatit',
    'amatît',
    'ambigu',
    'amblai',
    'amblas',
    'amblât',
    'ambler',
    'ambles',
    'amblez',
    'ambons',
    'ambrai',
    'ambras',
    'ambrât',
    'ambrée',
    'ambrer',
    'ambres',
    'ambrés',
    'ambrez',
    'amenai',
    'amenas',
    'amenât',
    'amenda',
    'amende',
    'amendé',
    'amenée',
    'amener',
    'amenés',
    'amènes',
    'amenez',
    'amères',
    'amerri',
    'ameuta',
    'ameute',
    'ameuté',
    'amibes',
    'amical',
    'amicts',
    'amides',
    'amidon',
    'aminci',
    'aminée',
    'aminés',
    'amiral',
    'amitié',
    'amnios',
    'amocha',
    'amoche',
    'amoché',
    'amodia',
    'amodie',
    'amodié',
    'amolli',
    'amonts',
    'amoral',
    'amorça',
    'amorce',
    'amorcé',
    'amorti',
    'amours',
    'ampère',
    'amphis',
    'amples',
    'amplis',
    'amputa',
    'ampute',
    'amputé',
    'amuïes',
    'amurai',
    'amuras',
    'amurât',
    'amurée',
    'amurer',
    'amures',
    'amurés',
    'amurez',
    'amusai',
    'amusas',
    'amusât',
    'amusée',
    'amuser',
    'amuses',
    'amusés',
    'amusez',
    'amusie',
    'amyles',
    'anales',
    'ananas',
    'anches',
    'ancien',
    'ancrai',
    'ancras',
    'ancrât',
    'ancrée',
    'ancrer',
    'ancres',
    'ancrés',
    'ancrez',
    'andain',
    'andine',
    'andins',
    'anémia',
    'anémie',
    'anémié',
    'ânerie',
    'ânesse',
    'aneths',
    'angine',
    'angles',
    'anglet',
    'angons',
    'angora',
    'angors',
    'anhéla',
    'anhélé',
    'anhèle',
    'ânière',
    'âniers',
    'animai',
    'animal',
    'animas',
    'animât',
    'animée',
    'animer',
    'animes',
    'animés',
    'animez',
    'anions',
    'anisai',
    'anisas',
    'anisât',
    'anisée',
    'aniser',
    'anises',
    'anisés',
    'anisez',
    'annale',
    'annaux',
    'anneau',
    'années',
    'annela',
    'annelé',
    'annexa',
    'annexe',
    'annexé',
    'annone',
    'annota',
    'annote',
    'annoté',
    'annuel',
    'annula',
    'annule',
    'annulé',
    'anobli',
    'anodes',
    'anodin',
    'anomal',
    'anomie',
    'ânonna',
    'ânonne',
    'ânonné',
    'anorak',
    'anordi',
    'anoure',
    'ansées',
    'antres',
    'anurie',
    'aortes',
    'aoûtai',
    'aoûtas',
    'aoûtat',
    'aoûtât',
    'aoûtée',
    'aoûter',
    'aoûtes',
    'aoûtés',
    'aoûtez',
    'apache',
    'apaisa',
    'apaise',
    'apaisé',
    'aparté',
    'aperçu',
    'apeura',
    'apeure',
    'apeuré',
    'aphone',
    'aphtes',
    'apical',
    'apiols',
    'apions',
    'apiqua',
    'apique',
    'apiqué',
    'aplani',
    'aplati',
    'aplats',
    'aplomb',
    'apnées',
    'apodes',
    'apogée',
    'aporie',
    'aposta',
    'aposte',
    'aposté',
    'apôtre',
    'apparu',
    'appâta',
    'appâte',
    'appâté',
    'appâts',
    'appeau',
    'appela',
    'appelé',
    'appels',
    'appert',
    'appéta',
    'appété',
    'appète',
    'apport',
    'apposa',
    'appose',
    'apposé',
    'apprêt',
    'appris',
    'apprit',
    'apprît',
    'appuie',
    'appuis',
    'appuya',
    'appuyé',
    'âpreté',
    'apside',
    'aptère',
    'apurai',
    'apuras',
    'apurât',
    'apurée',
    'apurer',
    'apures',
    'apurés',
    'apurez',
    'aqueux',
    'arabes',
    'arable',
    'araire',
    'aramon',
    'arasai',
    'arasas',
    'arasât',
    'arasée',
    'araser',
    'arases',
    'arasés',
    'arasez',
    'arbora',
    'arbore',
    'arboré',
    'arbres',
    'arcade',
    'arcane',
    'arceau',
    'archal',
    'archer',
    'arches',
    'archet',
    'arçons',
    'arcure',
    'ardent',
    'ardeur',
    'ardues',
    'arènes',
    'aréole',
    'arêtes',
    'argent',
    'argile',
    'argons',
    'argots',
    'arguai',
    'arguas',
    'arguât',
    'arguée',
    'arguer',
    'argues',
    'argués',
    'arguez',
    'arides',
    'ariens',
    'arille',
    'arioso',
    'arisai',
    'arisas',
    'arisât',
    'ariser',
    'arises',
    'arisez',
    'armada',
    'armais',
    'armait',
    'armant',
    'armées',
    'arment',
    'armera',
    'armets',
    'armiez',
    'armons',
    'armure',
    'arnica',
    'arômes',
    'aronde',
    'arpégé',
    'arpège',
    'arpent',
    'arpète',
    'arpion',
    'arquai',
    'arquas',
    'arquât',
    'arquée',
    'arquer',
    'arques',
    'arqués',
    'arquez',
    'arrêta',
    'arrête',
    'arrêté',
    'arrêts',
    'arrhes',
    'arrima',
    'arrime',
    'arrimé',
    'arrisa',
    'arrise',
    'arrisé',
    'arriva',
    'arrive',
    'arrivé',
    'arrobe',
    'arrogé',
    'arrosa',
    'arrose',
    'arrosé',
    'arroyo',
    'arsine',
    'arsins',
    'artels',
    'artère',
    'aryens',
    'aryles',
    'ascèse',
    'ascète',
    'ascite',
    'asexué',
    'ashram',
    'asiles',
    'aspect',
    'aspics',
    'aspira',
    'aspire',
    'aspiré',
    'asques',
    'assagi',
    'assaut',
    'assena',
    'asséna',
    'assené',
    'asséné',
    'assène',
    'asseye',
    'assidu',
    'assied',
    'assise',
    'assoie',
    'assois',
    'assoit',
    'assola',
    'assole',
    'assolé',
    'assona',
    'assone',
    'assoné',
    'assuma',
    'assume',
    'assumé',
    'assura',
    'assure',
    'assuré',
    'astate',
    'asters',
    'asthme',
    'astral',
    'astres',
    'astuce',
    'ataxie',
    'atèles',
    'athées',
    'a-t-il',
    'atolls',
    'atomes',
    'a-t-on',
    'atonal',
    'atones',
    'atonie',
    'atours',
    'atouts',
    'atrium',
    'atroce',
    'attela',
    'attelé',
    'attend',
    'attifa',
    'attife',
    'attifé',
    'attige',
    'attigé',
    'attira',
    'attire',
    'attiré',
    'attisa',
    'attise',
    'attisé',
    'aubade',
    'aubère',
    'aubier',
    'aubins',
    'auburn',
    'aucuba',
    'aucune',
    'audace',
    'audits',
    'augets',
    'augura',
    'augure',
    'auguré',
    'aulnes',
    'aumône',
    'auprès',
    'auquel',
    'aurais',
    'aurait',
    'auriez',
    'aurige',
    'aurons',
    'auront',
    'aurore',
    'autant',
    'autels',
    'auteur',
    'autour',
    'autres',
    'autrui',
    'auvent',
    'auxine',
    'avachi',
    'avalai',
    'avalas',
    'avalât',
    'avalée',
    'avaler',
    'avales',
    'avalés',
    'avalez',
    'avança',
    'avance',
    'avancé',
    'avanie',
    'avants',
    'avares',
    'avaria',
    'avarie',
    'avarié',
    'avatar',
    'avenir',
    'avenue',
    'avenus',
    'avérai',
    'avéras',
    'avérât',
    'avérée',
    'avérer',
    'avérés',
    'avères',
    'avérez',
    'averse',
    'averti',
    'aveuli',
    'avides',
    'avilie',
    'avilir',
    'avilis',
    'avilit',
    'avilît',
    'avinai',
    'avinas',
    'avinât',
    'avinée',
    'aviner',
    'avines',
    'avinés',
    'avinez',
    'avions',
    'aviron',
    'avisai',
    'avisas',
    'avisât',
    'avisée',
    'aviser',
    'avises',
    'avisés',
    'avisez',
    'avisos',
    'avivai',
    'avivas',
    'avivât',
    'avivée',
    'aviver',
    'avives',
    'avivés',
    'avivez',
    'avocat',
    'avoine',
    'avorta',
    'avorte',
    'avorté',
    'avouai',
    'avouas',
    'avouât',
    'avouée',
    'avouer',
    'avoues',
    'avoués',
    'avouez',
    'axâmes',
    'axasse',
    'axâtes',
    'axerai',
    'axeras',
    'axerez',
    'axiles',
    'axiome',
    'axions',
    'axones',
    'axonge',
    'azalée',
    'azimut',
    'azotée',
    'azotes',
    'azotés',
    'azurai',
    'azuras',
    'azurât',
    'azurée',
    'azurer',
    'azures',
    'azurés',
    'azurez',
    'azygos',
    'azymes',
    'babils',
    'babine',
    'bâbord',
    'bâchai',
    'bâchas',
    'bâchât',
    'bâchée',
    'bâcher',
    'bâches',
    'bâchés',
    'bâchez',
    'bachot',
    'bâclai',
    'bâclas',
    'bâclât',
    'bâclée',
    'bâcler',
    'bâcles',
    'bâclés',
    'bâclez',
    'baculs',
    'badaud',
    'badges',
    'badina',
    'badine',
    'badiné',
    'badins',
    'baffai',
    'baffas',
    'baffât',
    'baffée',
    'baffer',
    'baffes',
    'baffés',
    'baffez',
    'baffle',
    'bafoua',
    'bafoue',
    'bafoué',
    'bâfrai',
    'bâfras',
    'bâfrât',
    'bâfrée',
    'bâfrer',
    'bâfres',
    'bâfrés',
    'bâfrez',
    'bagads',
    'bagage',
    'bagnes',
    'bagout',
    'baguai',
    'baguas',
    'baguât',
    'baguée',
    'baguer',
    'bagues',
    'bagués',
    'baguez',
    'bahuts',
    'baient',
    'baiera',
    'baigna',
    'baigne',
    'baigné',
    'bailla',
    'bâilla',
    'baille',
    'baillé',
    'bâille',
    'bâillé',
    'bailli',
    'baïram',
    'baisai',
    'baisas',
    'baisât',
    'baisée',
    'baiser',
    'baises',
    'baisés',
    'baisez',
    'baissa',
    'baisse',
    'baissé',
    'bajoue',
    'balada',
    'balade',
    'baladé',
    'balaie',
    'balais',
    'balane',
    'balata',
    'balaya',
    'balaye',
    'balayé',
    'balcon',
    'balisa',
    'balise',
    'balisé',
    'ballai',
    'ballas',
    'ballât',
    'baller',
    'balles',
    'ballet',
    'ballez',
    'ballon',
    'ballot',
    'baltes',
    'balzan',
    'bambin',
    'bambou',
    'banale',
    'banane',
    'banaux',
    'bancal',
    'banche',
    'bancos',
    'bandai',
    'bandas',
    'bandât',
    'bandée',
    'bander',
    'bandes',
    'bandés',
    'bandez',
    'bandit',
    'banian',
    'banjos',
    'banlon',
    'bannai',
    'bannas',
    'bannât',
    'bannée',
    'banner',
    'bannes',
    'bannés',
    'bannez',
    'bannie',
    'bannir',
    'bannis',
    'bannit',
    'bannît',
    'banqua',
    'banque',
    'banqué',
    'bantou',
    'baobab',
    'baquet',
    'baraka',
    'barbai',
    'barbas',
    'barbât',
    'barbée',
    'barber',
    'barbes',
    'barbés',
    'barbet',
    'barbez',
    'barbon',
    'barbue',
    'barbus',
    'bardai',
    'bardas',
    'bardât',
    'bardée',
    'barder',
    'bardes',
    'bardés',
    'bardez',
    'bardis',
    'bardot',
    'barème',
    'baréta',
    'barété',
    'barète',
    'barges',
    'barils',
    'barman',
    'barmen',
    'barons',
    'baroud',
    'barouf',
    'barque',
    'barrai',
    'barras',
    'barrât',
    'barrée',
    'barrer',
    'barres',
    'barrés',
    'barrez',
    'barrir',
    'barris',
    'barrit',
    'barrît',
    'barrot',
    'baryes',
    'baryon',
    'baryte',
    'baryum',
    'barzoï',
    'basais',
    'basait',
    'basale',
    'basana',
    'basane',
    'basané',
    'basant',
    'basaux',
    'basées',
    'basent',
    'basera',
    'basics',
    'baside',
    'basiez',
    'basins',
    'basket',
    'basons',
    'basque',
    'basses',
    'basset',
    'bassin',
    'basson',
    'bastes',
    'bastos',
    'bâtais',
    'bâtait',
    'bâtant',
    'bâtard',
    'bateau',
    'bâtées',
    'batela',
    'batelé',
    'bâtent',
    'bâtera',
    'bâties',
    'bâtiez',
    'batiks',
    'bâtira',
    'bâtons',
    'battée',
    'battes',
    'battez',
    'battis',
    'battit',
    'battît',
    'battra',
    'battre',
    'battue',
    'battus',
    'baudet',
    'baugea',
    'baugée',
    'bauger',
    'bauges',
    'baugés',
    'baugez',
    'baumes',
    'bavais',
    'bavait',
    'bavant',
    'bavard',
    'bavent',
    'bavera',
    'baveux',
    'baviez',
    'bavoir',
    'bavons',
    'bavure',
    'bayais',
    'bayait',
    'bayant',
    'bayent',
    'bayera',
    'bayiez',
    'bayons',
    'bayous',
    'bazars',
    'beagle',
    'béâmes',
    'béance',
    'béante',
    'béants',
    'béasse',
    'béates',
    'béâtes',
    'beaufs',
    'beauté',
    'be-bop',
    'bécane',
    'bêchai',
    'bêchas',
    'bêchât',
    'bêchée',
    'bêcher',
    'bêches',
    'bêchés',
    'bêchez',
    'bécota',
    'bécote',
    'bécoté',
    'bécots',
    'becqua',
    'becque',
    'becqué',
    'bectai',
    'bectas',
    'bectât',
    'bectée',
    'becter',
    'bectes',
    'bectés',
    'bectez',
    'bédane',
    'bedeau',
    'bedons',
    'béerai',
    'béeras',
    'béerez',
    'bégaie',
    'bégaya',
    'bégaye',
    'bégayé',
    'bègues',
    'béguin',
    'bégums',
    'beiges',
    'beigne',
    'béions',
    'bêlais',
    'bêlait',
    'bêlant',
    'bêlées',
    'bêlent',
    'bêlera',
    'belges',
    'bélier',
    'bêliez',
    'belles',
    'belons',
    'bêlons',
    'belote',
    'béluga',
    'bémols',
    'bénefs',
    'benêts',
    'bénies',
    'bénins',
    'bénira',
    'bénite',
    'bénits',
    'bennes',
    'benoît',
    'benzol',
    'béquai',
    'béquas',
    'béquât',
    'béquée',
    'béquer',
    'béqués',
    'bèques',
    'béquez',
    'berçai',
    'berças',
    'berçât',
    'bercée',
    'bercer',
    'berces',
    'bercés',
    'bercez',
    'bérets',
    'berger',
    'berges',
    'berlue',
    'bermes',
    'bernai',
    'bernas',
    'bernât',
    'bernée',
    'berner',
    'bernes',
    'bernés',
    'bernez',
    'béryls',
    'besace',
    'besant',
    'besoin',
    'bétail',
    'bétels',
    'bêtisa',
    'bêtise',
    'bêtisé',
    'bétons',
    'bettes',
    'bétyle',
    'beugla',
    'beugle',
    'beuglé',
    'beurra',
    'beurre',
    'beurré',
    'bévues',
    'biaisa',
    'biaise',
    'biaisé',
    'bibine',
    'bibles',
    'biceps',
    'bichai',
    'bichas',
    'bichât',
    'bicher',
    'biches',
    'bichez',
    'bichof',
    'bichon',
    'bicots',
    'bident',
    'bidets',
    'bidons',
    'bidule',
    'bielle',
    'bières',
    'biface',
    'biffai',
    'biffas',
    'biffât',
    'biffée',
    'biffer',
    'biffes',
    'biffés',
    'biffez',
    'biffin',
    'bifide',
    'bigame',
    'biglai',
    'biglas',
    'biglât',
    'biglée',
    'bigler',
    'bigles',
    'biglés',
    'biglez',
    'bigote',
    'bigots',
    'bigues',
    'bijoux',
    'bikini',
    'bilais',
    'bilait',
    'bilame',
    'bilans',
    'bilant',
    'bilées',
    'bilent',
    'bilera',
    'bileux',
    'biliez',
    'billes',
    'billet',
    'billon',
    'billot',
    'bilobé',
    'bilons',
    'bimane',
    'binage',
    'binais',
    'binait',
    'binant',
    'binard',
    'binées',
    'binent',
    'binera',
    'bingos',
    'biniez',
    'biniou',
    'binons',
    'bipale',
    'bipède',
    'bipied',
    'biplan',
    'biques',
    'biquet',
    'birbes',
    'birème',
    'bisais',
    'bisait',
    'bisant',
    'biseau',
    'bisées',
    'bisent',
    'bisera',
    'bisets',
    'bisiez',
    'bisons',
    'bisous',
    'bisqua',
    'bisque',
    'bisqué',
    'bissai',
    'bissas',
    'bissât',
    'bissée',
    'bisser',
    'bisses',
    'bissés',
    'bissez',
    'bistra',
    'bistre',
    'bistré',
    'bistro',
    'bitais',
    'bitait',
    'bitant',
    'bitées',
    'bitent',
    'bitera',
    'bitiez',
    'bitons',
    'bitord',
    'bittai',
    'bittas',
    'bittât',
    'bittée',
    'bitter',
    'bittes',
    'bittés',
    'bittez',
    'bituma',
    'bitume',
    'bitumé',
    'bitura',
    'biture',
    'bituré',
    'biveau',
    'bizuta',
    'bizute',
    'bizuté',
    'bizuts',
    'blabla',
    'blacks',
    'blagua',
    'blague',
    'blagué',
    'blaira',
    'blaire',
    'blairé',
    'blairs',
    'blâmai',
    'blâmas',
    'blâmât',
    'blâmée',
    'blâmer',
    'blâmes',
    'blâmés',
    'blâmez',
    'blancs',
    'blasai',
    'blasas',
    'blasât',
    'blasée',
    'blaser',
    'blases',
    'blasés',
    'blasez',
    'blason',
    'blatte',
    'blazer',
    'blèche',
    'blêmes',
    'blêmez',
    'blêmie',
    'blêmir',
    'blêmis',
    'blêmit',
    'blêmît',
    'blende',
    'blésai',
    'blésas',
    'blésât',
    'blésée',
    'bléser',
    'blésés',
    'blèses',
    'blésez',
    'blessa',
    'blesse',
    'blessé',
    'blette',
    'bletti',
    'bleues',
    'bleuet',
    'bleuie',
    'bleuir',
    'bleuis',
    'bleuit',
    'bleuît',
    'bleuta',
    'bleute',
    'bleuté',
    'blinda',
    'blinde',
    'blindé',
    'blocus',
    'blonde',
    'blondi',
    'blonds',
    'bloqua',
    'bloque',
    'bloqué',
    'blotti',
    'blousa',
    'blouse',
    'blousé',
    'bluets',
    'bluffa',
    'bluffe',
    'bluffé',
    'bluffs',
    'blutai',
    'blutas',
    'blutât',
    'blutée',
    'bluter',
    'blutes',
    'blutés',
    'blutez',
    'bobard',
    'bobina',
    'bobine',
    'bobiné',
    'bocage',
    'bocard',
    'bocaux',
    'boches',
    'boësse',
    'boëtes',
    'boetta',
    'boette',
    'boetté',
    'boeufs',
    'boghei',
    'bogies',
    'bogues',
    'bohème',
    'boille',
    'boirai',
    'boiras',
    'boirez',
    'boisai',
    'boisas',
    'boisât',
    'boisée',
    'boiser',
    'boises',
    'boisés',
    'boisez',
    'boitai',
    'boitas',
    'boitât',
    'boiter',
    'boites',
    'boîtes',
    'boitez',
    'boives',
    'boldos',
    'bolduc',
    'bolées',
    'boléro',
    'bolets',
    'bolide',
    'bolier',
    'bombai',
    'bombas',
    'bombât',
    'bombée',
    'bomber',
    'bombes',
    'bombés',
    'bombez',
    'bombyx',
    'bonace',
    'bonbon',
    'bondai',
    'bondas',
    'bondât',
    'bondée',
    'bonder',
    'bondes',
    'bondés',
    'bondez',
    'bondir',
    'bondis',
    'bondit',
    'bondît',
    'bondon',
    'bonite',
    'bonnes',
    'bonnet',
    'bonsaï',
    'bontés',
    'bonzes',
    'borate',
    'bordai',
    'bordas',
    'bordât',
    'bordée',
    'bordel',
    'border',
    'bordes',
    'bordés',
    'bordez',
    'boréal',
    'borgne',
    'bornai',
    'bornas',
    'bornât',
    'bornée',
    'borner',
    'bornes',
    'bornés',
    'bornez',
    'boscos',
    'boscot',
    'bossai',
    'bossas',
    'bossât',
    'bossée',
    'bosser',
    'bosses',
    'bossés',
    'bossez',
    'bossua',
    'bossue',
    'bossué',
    'bossus',
    'boston',
    'bottai',
    'bottas',
    'bottât',
    'bottée',
    'botter',
    'bottes',
    'bottés',
    'bottez',
    'bottin',
    'boubou',
    'boucan',
    'boucau',
    'boucha',
    'bouche',
    'bouché',
    'boucla',
    'boucle',
    'bouclé',
    'boudai',
    'boudas',
    'boudât',
    'boudée',
    'bouder',
    'boudes',
    'boudés',
    'boudez',
    'boudin',
    'bouées',
    'boueur',
    'boueux',
    'bouffa',
    'bouffe',
    'bouffé',
    'bouffi',
    'bougea',
    'bougée',
    'bouger',
    'bouges',
    'bougés',
    'bougez',
    'bougie',
    'bougon',
    'bougre',
    'bouifs',
    'boulai',
    'boulas',
    'boulât',
    'boulée',
    'bouler',
    'boules',
    'boulés',
    'boulet',
    'boulez',
    'boulin',
    'boulon',
    'boulot',
    'boumer',
    'boumes',
    'bourbe',
    'bourda',
    'bourde',
    'bourdé',
    'bourgs',
    'bourra',
    'bourre',
    'bourré',
    'bourru',
    'bourse',
    'bouses',
    'bousin',
    'boutai',
    'boutas',
    'boutât',
    'boutée',
    'bouter',
    'boutes',
    'boutés',
    'boutez',
    'bouton',
    'boutre',
    'bouvet',
    'bovidé',
    'bovins',
    'boxais',
    'boxait',
    'boxant',
    'boxées',
    'boxent',
    'boxera',
    'boxers',
    'boxeur',
    'boxiez',
    'boxons',
    'boyard',
    'boyaux',
    'bradai',
    'bradas',
    'bradât',
    'bradée',
    'bradel',
    'brader',
    'brades',
    'bradés',
    'bradez',
    'braies',
    'braira',
    'braire',
    'braisa',
    'braise',
    'braisé',
    'bramai',
    'bramas',
    'bramât',
    'bramée',
    'bramer',
    'brames',
    'bramés',
    'bramez',
    'brande',
    'brandi',
    'brandy',
    'branla',
    'branle',
    'branlé',
    'brante',
    'braqua',
    'braque',
    'braqué',
    'brasai',
    'brasas',
    'brasât',
    'brasée',
    'braser',
    'brases',
    'brasés',
    'brasez',
    'brassa',
    'brasse',
    'brassé',
    'bravai',
    'bravas',
    'bravât',
    'bravée',
    'braver',
    'braves',
    'bravés',
    'bravez',
    'bravos',
    'brayai',
    'brayas',
    'brayât',
    'brayée',
    'brayer',
    'brayes',
    'brayés',
    'brayez',
    'breaks',
    'brebis',
    'brèche',
    'brélai',
    'brêlai',
    'brelan',
    'brélas',
    'brêlas',
    'brélât',
    'brêlât',
    'brélée',
    'brêlée',
    'bréler',
    'brêler',
    'brélés',
    'brèles',
    'brêles',
    'brêlés',
    'brélez',
    'brêlez',
    'brella',
    'brelle',
    'brellé',
    'brèmes',
    'brésil',
    'breton',
    'bretta',
    'brette',
    'bretté',
    'brèves',
    'brevet',
    'briard',
    'bribes',
    'bricks',
    'bridai',
    'bridas',
    'bridât',
    'bridée',
    'brider',
    'brides',
    'bridés',
    'bridez',
    'bridge',
    'bridgé',
    'bridon',
    'brifai',
    'brifas',
    'brifât',
    'brifée',
    'brifer',
    'brifes',
    'brifés',
    'brifez',
    'briffa',
    'briffe',
    'briffé',
    'brigua',
    'brigue',
    'brigué',
    'brilla',
    'brille',
    'brillé',
    'brimai',
    'brimas',
    'brimât',
    'brimée',
    'brimer',
    'brimes',
    'brimés',
    'brimez',
    'briqua',
    'brique',
    'briqué',
    'brisai',
    'brisas',
    'brisât',
    'brisée',
    'briser',
    'brises',
    'brisés',
    'brisez',
    'brisis',
    'briska',
    'brocha',
    'broche',
    'broché',
    'brodai',
    'brodas',
    'brodât',
    'brodée',
    'broder',
    'brodes',
    'brodés',
    'brodez',
    'broies',
    'bromes',
    'bronza',
    'bronze',
    'bronzé',
    'brooks',
    'brossa',
    'brosse',
    'brossé',
    'brouet',
    'brouta',
    'broute',
    'brouté',
    'brouts',
    'broyai',
    'broyas',
    'broyât',
    'broyée',
    'broyer',
    'broyés',
    'broyez',
    'bruant',
    'bruche',
    'bruies',
    'bruina',
    'bruine',
    'bruiné',
    'bruira',
    'bruire',
    'bruita',
    'bruite',
    'bruité',
    'bruits',
    'brûlai',
    'brûlas',
    'brûlât',
    'brûlée',
    'brûler',
    'brûles',
    'brûlés',
    'brûlez',
    'brûlis',
    'brûlot',
    'brumât',
    'brumer',
    'brumes',
    'brunch',
    'brunes',
    'brunet',
    'brunie',
    'brunir',
    'brunis',
    'brunit',
    'brunît',
    'brutal',
    'brutes',
    'bryone',
    'bubale',
    'bubons',
    'buccal',
    'buccin',
    'bûchai',
    'bûchas',
    'bûchât',
    'bûchée',
    'bûcher',
    'bûches',
    'bûchés',
    'bûchez',
    'budget',
    'buffet',
    'buffle',
    'buggys',
    'bugles',
    'buires',
    'bulbes',
    'bulles',
    'bunker',
    'bureau',
    'burelé',
    'burent',
    'burgau',
    'burina',
    'burine',
    'buriné',
    'burins',
    'burons',
    'busard',
    'bushes',
    'busses',
    'bustes',
    'butais',
    'butait',
    'butane',
    'butant',
    'butées',
    'butent',
    'butera',
    'buteur',
    'butiez',
    'butina',
    'butine',
    'butiné',
    'butins',
    'butoir',
    'butome',
    'butons',
    'butors',
    'buttai',
    'buttas',
    'buttât',
    'buttée',
    'butter',
    'buttes',
    'buttés',
    'buttez',
    'buvais',
    'buvait',
    'buvant',
    'buvard',
    'buvées',
    'buveur',
    'buviez',
    'buvons',
    'buvota',
    'buvote',
    'buvoté',
    'byssus',
    'cabala',
    'cabale',
    'cabalé',
    'cabana',
    'cabane',
    'cabané',
    'cabans',
    'cabiai',
    'cabine',
    'câblai',
    'câblas',
    'câblât',
    'câblée',
    'câbler',
    'câbles',
    'câblés',
    'câblez',
    'câblot',
    'cabota',
    'cabote',
    'caboté',
    'cabots',
    'cabrai',
    'cabras',
    'cabrât',
    'cabrée',
    'cabrer',
    'cabres',
    'cabrés',
    'cabrez',
    'cabris',
    'cacaba',
    'cacabe',
    'cacabé',
    'cacaos',
    'cachai',
    'cachas',
    'cachât',
    'cachée',
    'cacher',
    'caches',
    'cachés',
    'cachet',
    'cachez',
    'cachot',
    'cachou',
    'cactus',
    'caddie',
    'cadeau',
    'cadets',
    'cadmia',
    'cadmie',
    'cadmié',
    'cadrai',
    'cadran',
    'cadras',
    'cadrat',
    'cadrât',
    'cadrée',
    'cadrer',
    'cadres',
    'cadrés',
    'cadrez',
    'caducs',
    'caecal',
    'caecum',
    'cafard',
    'cafres',
    'caftai',
    'caftan',
    'caftas',
    'caftât',
    'caftée',
    'cafter',
    'caftes',
    'caftés',
    'caftez',
    'cageot',
    'cagets',
    'cagibi',
    'cagnai',
    'cagnas',
    'cagnât',
    'cagner',
    'cagnes',
    'cagnez',
    'cagote',
    'cagots',
    'cahier',
    'cahota',
    'cahote',
    'cahoté',
    'cahots',
    'cahute',
    'caïeux',
    'cailla',
    'caille',
    'caillé',
    'caïman',
    'caïque',
    'cairns',
    'caisse',
    'cajola',
    'cajole',
    'cajolé',
    'cajous',
    'cajuns',
    'calage',
    'calais',
    'calait',
    'calame',
    'calant',
    'calaos',
    'calcin',
    'calcul',
    'calées',
    'calent',
    'calera',
    'caleta',
    'caleté',
    'calfat',
    'calice',
    'calier',
    'caliez',
    'calife',
    'câlina',
    'câline',
    'câliné',
    'câlins',
    'calmai',
    'calmar',
    'calmas',
    'calmât',
    'calmée',
    'calmer',
    'calmes',
    'calmés',
    'calmez',
    'calmir',
    'calmis',
    'calmit',
    'calmît',
    'calons',
    'calots',
    'calqua',
    'calque',
    'calqué',
    'caltai',
    'caltas',
    'caltât',
    'caltée',
    'calter',
    'caltes',
    'caltés',
    'caltez',
    'calvas',
    'camail',
    'camard',
    'cambra',
    'cambre',
    'cambré',
    'camées',
    'caméra',
    'camion',
    'campai',
    'campas',
    'campât',
    'campée',
    'camper',
    'campes',
    'campés',
    'campez',
    'campos',
    'campus',
    'canada',
    'canais',
    'canait',
    'canant',
    'canapé',
    'canard',
    'canari',
    'canaux',
    'cancan',
    'cancel',
    'cancer',
    'canche',
    'cancre',
    'candie',
    'candir',
    'candis',
    'candit',
    'candît',
    'canent',
    'canera',
    'cangue',
    'caniez',
    'canifs',
    'canine',
    'canins',
    'cannai',
    'cannas',
    'cannât',
    'cannée',
    'canner',
    'cannes',
    'cannés',
    'cannez',
    'canoës',
    'canons',
    'canope',
    'canota',
    'canote',
    'canoté',
    'canots',
    'cantal',
    'canter',
    'canton',
    'cantre',
    'canula',
    'canule',
    'canulé',
    'canuts',
    'canyon',
    'caouas',
    'capéai',
    'capéas',
    'capéât',
    'capéer',
    'capées',
    'capéez',
    'capela',
    'capelé',
    'capeya',
    'capeye',
    'capeyé',
    'capité',
    'capons',
    'capota',
    'capote',
    'capoté',
    'capots',
    'cappas',
    'câpres',
    'caprin',
    'capron',
    'captai',
    'captal',
    'captas',
    'captât',
    'captée',
    'capter',
    'captes',
    'captés',
    'captez',
    'captif',
    'capuce',
    'caquai',
    'caquas',
    'caquât',
    'caquée',
    'caquer',
    'caques',
    'caqués',
    'caquet',
    'caquez',
    'carabe',
    'caraco',
    'carafe',
    'carats',
    'carcan',
    'carcel',
    'cardai',
    'cardan',
    'cardas',
    'cardât',
    'cardée',
    'carder',
    'cardes',
    'cardés',
    'cardez',
    'cardia',
    'cardon',
    'carême',
    'caréna',
    'caréné',
    'carène',
    'cargos',
    'cargua',
    'cargue',
    'cargué',
    'cariai',
    'carias',
    'cariât',
    'cariée',
    'carier',
    'caries',
    'cariés',
    'cariez',
    'carlin',
    'carmai',
    'carmas',
    'carmât',
    'carmée',
    'carmer',
    'carmes',
    'carmés',
    'carmez',
    'carmin',
    'carnée',
    'carnes',
    'carnés',
    'carnet',
    'carpes',
    'carrai',
    'carras',
    'carrât',
    'carrée',
    'carrer',
    'carres',
    'carrés',
    'carrez',
    'carrys',
    'cartel',
    'carter',
    'cartes',
    'carton',
    'casais',
    'casait',
    'casant',
    'casbah',
    'casées',
    'casent',
    'casera',
    'casher',
    'casier',
    'casiez',
    'casino',
    'casoar',
    'casons',
    'casqua',
    'casque',
    'casqué',
    'cassai',
    'cassas',
    'cassât',
    'cassée',
    'casser',
    'casses',
    'cassés',
    'cassez',
    'cassis',
    'casson',
    'castel',
    'castes',
    'castor',
    'castra',
    'castre',
    'castré',
    'casuel',
    'catcha',
    'catche',
    'catché',
    'catchs',
    'catins',
    'cation',
    'caudal',
    'cauris',
    'causai',
    'causal',
    'causas',
    'causât',
    'causée',
    'causer',
    'causes',
    'causés',
    'causez',
    'causse',
    'cavais',
    'cavait',
    'cavala',
    'cavale',
    'cavalé',
    'cavant',
    'caveau',
    'cavées',
    'cavent',
    'cavera',
    'cavets',
    'caviar',
    'caviez',
    'cavité',
    'cavons',
    'cécité',
    'cédais',
    'cédait',
    'cédant',
    'cédées',
    'cèdent',
    'cédera',
    'cédiez',
    'cédons',
    'cédrat',
    'cèdres',
    'cédule',
    'ceigne',
    'ceinte',
    'ceints',
    'celais',
    'celait',
    'celant',
    'celées',
    'cèlent',
    'cèlera',
    'céleri',
    'celiez',
    'cellas',
    'celles',
    'celons',
    'celtes',
    'cément',
    'cendra',
    'cendre',
    'cendré',
    'censée',
    'censés',
    'centra',
    'centre',
    'centré',
    'cénure',
    'cépage',
    'cérame',
    'cérats',
    'cerces',
    'cercla',
    'cercle',
    'cerclé',
    'cerise',
    'cérite',
    'cérium',
    'cernai',
    'cernas',
    'cernât',
    'cernée',
    'cerner',
    'cernes',
    'cernés',
    'cernez',
    'certes',
    'céruse',
    'césars',
    'césium',
    'cessai',
    'cessas',
    'cessât',
    'cessée',
    'cesser',
    'cesses',
    'cessés',
    'cessez',
    'cestes',
    'césure',
    'cétacé',
    'cétane',
    'cetera',
    'cétone',
    'chabla',
    'chable',
    'chablé',
    'chabot',
    'chacal',
    'chacun',
    'chahut',
    'chaîna',
    'chaîne',
    'chaîné',
    'chaire',
    'chairs',
    'chaise',
    'châles',
    'chalet',
    'châlit',
    'chalut',
    'champi',
    'champs',
    'chance',
    'chanci',
    'change',
    'changé',
    'chanta',
    'chante',
    'chanté',
    'chants',
    'chapée',
    'chapes',
    'chapés',
    'chapon',
    'chaque',
    'charge',
    'chargé',
    'charma',
    'charme',
    'charmé',
    'charnu',
    'charte',
    'chassa',
    'chasse',
    'chassé',
    'châsse',
    'chaste',
    'châtia',
    'châtie',
    'châtié',
    'chaton',
    'châtra',
    'châtre',
    'châtré',
    'chatte',
    'chaude',
    'chauds',
    'chaula',
    'chaule',
    'chaulé',
    'chauma',
    'chaume',
    'chaumé',
    'chauve',
    'chauvi',
    'chébec',
    'chèche',
    'cheffe',
    'cheikh',
    'cheiks',
    'cheire',
    'chelem',
    'chemin',
    'chenal',
    'chênes',
    'chenet',
    'chenil',
    'chenue',
    'chenus',
    'chèque',
    'chérai',
    'chéras',
    'chérât',
    'chérer',
    'chéres',
    'chères',
    'chérez',
    'chérie',
    'chérif',
    'chérir',
    'chéris',
    'chérit',
    'chérît',
    'chérot',
    'cherra',
    'cherre',
    'cherré',
    'cherté',
    'chétif',
    'cheval',
    'chevet',
    'cheveu',
    'chèvre',
    'chiada',
    'chiade',
    'chiadé',
    'chiais',
    'chiait',
    'chiala',
    'chiale',
    'chialé',
    'chiant',
    'chiche',
    'chichi',
    'chicon',
    'chicot',
    'chiées',
    'chiens',
    'chient',
    'chiera',
    'chiffe',
    'chiiez',
    'chiite',
    'chimie',
    'chinai',
    'chinas',
    'chinât',
    'chinée',
    'chiner',
    'chines',
    'chinés',
    'chinez',
    'chintz',
    'chions',
    'chiots',
    'chipai',
    'chipas',
    'chipât',
    'chipée',
    'chiper',
    'chipes',
    'chipés',
    'chipez',
    'chipie',
    'chiqua',
    'chique',
    'chiqué',
    'chiton',
    'chiure',
    'chlora',
    'chlore',
    'chloré',
    'choeur',
    'choies',
    'choisi',
    'chômai',
    'chômas',
    'chômât',
    'chômée',
    'chômer',
    'chômes',
    'chômés',
    'chômez',
    'chopai',
    'chopas',
    'chopât',
    'chopée',
    'choper',
    'chopes',
    'chopés',
    'chopez',
    'choppa',
    'choppe',
    'choppé',
    'choqua',
    'choque',
    'choqué',
    'choral',
    'chorée',
    'chorus',
    'choses',
    'chotts',
    'chouan',
    'choyai',
    'choyas',
    'choyât',
    'choyée',
    'choyer',
    'choyés',
    'choyez',
    'christ',
    'chroma',
    'chrome',
    'chromé',
    'chromo',
    'chrono',
    'chutai',
    'chutas',
    'chutât',
    'chutée',
    'chuter',
    'chutes',
    'chutés',
    'chutez',
    'chyles',
    'chymes',
    'cibles',
    'cicéro',
    'cidres',
    'cierge',
    'cigale',
    'cigare',
    'ci-gît',
    'ciguës',
    'cilice',
    'ciliée',
    'ciliés',
    'cillai',
    'cillas',
    'cillât',
    'cillée',
    'ciller',
    'cilles',
    'cillés',
    'cillez',
    'ciment',
    'cimier',
    'cincle',
    'cinéma',
    'cingla',
    'cingle',
    'cinglé',
    'cintra',
    'cintre',
    'cintré',
    'cipaye',
    'cippes',
    'cirage',
    'cirais',
    'cirait',
    'cirant',
    'cirées',
    'cirent',
    'cirera',
    'cireur',
    'cireux',
    'cirier',
    'ciriez',
    'cirons',
    'cirque',
    'cirres',
    'cirrus',
    'ciseau',
    'cisela',
    'ciselé',
    'cisèle',
    'cistes',
    'cistre',
    'citais',
    'citait',
    'citant',
    'citées',
    'citent',
    'citera',
    'citiez',
    'citons',
    'citrin',
    'citron',
    'citrus',
    'civets',
    'civile',
    'civils',
    'clabot',
    'clades',
    'claies',
    'claire',
    'clairs',
    'clamai',
    'clamas',
    'clamât',
    'clamée',
    'clamer',
    'clames',
    'clamés',
    'clamez',
    'clampa',
    'clampe',
    'clampé',
    'clamps',
    'clamsa',
    'clamse',
    'clamsé',
    'clandé',
    'clapai',
    'clapas',
    'clapât',
    'claper',
    'clapes',
    'clapet',
    'clapez',
    'clapir',
    'clapis',
    'clapit',
    'clapît',
    'clappa',
    'clappe',
    'clappé',
    'clapsa',
    'clapse',
    'clapsé',
    'claqua',
    'claque',
    'claqué',
    'clarté',
    'classa',
    'classe',
    'classé',
    'clause',
    'clavai',
    'clavas',
    'clavât',
    'clavée',
    'claver',
    'claves',
    'clavés',
    'clavez',
    'clayon',
    'clercs',
    'clergé',
    'clicha',
    'cliche',
    'cliché',
    'client',
    'cligna',
    'cligne',
    'cligné',
    'climat',
    'clique',
    'clissa',
    'clisse',
    'clissé',
    'clivai',
    'clivas',
    'clivât',
    'clivée',
    'cliver',
    'clives',
    'clivés',
    'clivez',
    'clocha',
    'cloche',
    'cloché',
    'clodos',
    'clones',
    'clopes',
    'cloqua',
    'cloque',
    'cloqué',
    'clorai',
    'cloras',
    'clorez',
    'closes',
    'clouai',
    'clouas',
    'clouât',
    'clouée',
    'clouer',
    'cloues',
    'cloués',
    'clouez',
    'clouta',
    'cloute',
    'clouté',
    'clowns',
    'cluses',
    'coassa',
    'coasse',
    'coassé',
    'coatis',
    'cobalt',
    'cobaye',
    'cobéas',
    'cobras',
    'coccyx',
    'cochai',
    'côchai',
    'cochas',
    'côchas',
    'cochât',
    'côchât',
    'cochée',
    'côchée',
    'cocher',
    'côcher',
    'coches',
    'cochés',
    'côches',
    'côchés',
    'cochez',
    'côchez',
    'cochon',
    'cocker',
    'cocons',
    'codage',
    'codais',
    'codait',
    'codant',
    'codées',
    'codent',
    'codera',
    'codeur',
    'codiez',
    'codons',
    'coeurs',
    'coffin',
    'coffra',
    'coffre',
    'coffré',
    'cogita',
    'cogite',
    'cogité',
    'cogito',
    'cognac',
    'cognai',
    'cognas',
    'cognat',
    'cognât',
    'cognée',
    'cogner',
    'cognes',
    'cognés',
    'cognez',
    'cohues',
    'coiffa',
    'coiffe',
    'coiffé',
    'coinça',
    'coince',
    'coincé',
    'coings',
    'coïtai',
    'coïtas',
    'coïtât',
    'coïter',
    'coites',
    'coïtes',
    'coïtez',
    'colère',
    'colins',
    'colite',
    'collai',
    'collas',
    'collât',
    'collée',
    'coller',
    'colles',
    'collés',
    'collet',
    'colley',
    'collez',
    'colons',
    'côlons',
    'colora',
    'colore',
    'coloré',
    'combat',
    'combes',
    'combla',
    'comble',
    'comblé',
    'comète',
    'comice',
    'comics',
    'comité',
    'commas',
    'commet',
    'commis',
    'commit',
    'commît',
    'commua',
    'commue',
    'commué',
    'commun',
    'compas',
    'complu',
    'compta',
    'compte',
    'compté',
    'comput',
    'comtal',
    'comtes',
    'comtés',
    'conard',
    'concis',
    'conclu',
    'conçue',
    'conçus',
    'conçut',
    'conçût',
    'condés',
    'condom',
    'condor',
    'confer',
    'confia',
    'confie',
    'confié',
    'confis',
    'confit',
    'confît',
    'confus',
    'congaï',
    'congas',
    'congés',
    'congre',
    'congru',
    'cônies',
    'cônira',
    'connue',
    'connus',
    'connut',
    'connût',
    'conque',
    'consul',
    'contai',
    'contas',
    'contât',
    'contée',
    'conter',
    'contes',
    'contés',
    'contez',
    'contra',
    'contre',
    'contré',
    'convia',
    'convie',
    'convié',
    'convoi',
    'coolie',
    'coopta',
    'coopte',
    'coopté',
    'copain',
    'copals',
    'copeau',
    'copiai',
    'copias',
    'copiât',
    'copiée',
    'copier',
    'copies',
    'copiés',
    'copiez',
    'copina',
    'copine',
    'copiné',
    'coprah',
    'copras',
    'coptes',
    'copula',
    'copule',
    'copulé',
    'coquai',
    'coquas',
    'coquât',
    'coquée',
    'coquer',
    'coques',
    'coqués',
    'coquet',
    'coquez',
    'coquin',
    'corail',
    'corans',
    'coraux',
    'corbin',
    'cordai',
    'cordas',
    'cordât',
    'cordée',
    'corder',
    'cordes',
    'cordés',
    'cordez',
    'cordon',
    'coréen',
    'cornac',
    'cornai',
    'cornas',
    'cornât',
    'cornée',
    'corner',
    'cornes',
    'cornés',
    'cornet',
    'cornez',
    'cornue',
    'cornus',
    'corons',
    'corozo',
    'corpus',
    'corral',
    'corsai',
    'corsas',
    'corsât',
    'corsée',
    'corser',
    'corses',
    'corsés',
    'corset',
    'corsez',
    'cortex',
    'corvée',
    'coryza',
    'cosmos',
    'cossai',
    'cossas',
    'cossât',
    'cosser',
    'cosses',
    'cossez',
    'cossue',
    'cossus',
    'costal',
    'cotais',
    'cotait',
    'cotant',
    'coteau',
    'cotées',
    'côtelé',
    'cotent',
    'cotera',
    'côtier',
    'coties',
    'cotiez',
    'cotira',
    'cotisa',
    'cotise',
    'cotisé',
    'côtoie',
    'cotons',
    'côtoya',
    'côtoyé',
    'cottes',
    'couacs',
    'couard',
    'coucha',
    'couche',
    'couché',
    'coucou',
    'coudai',
    'coudas',
    'coudât',
    'coudée',
    'couder',
    'coudes',
    'coudés',
    'coudez',
    'coudra',
    'coudre',
    'couina',
    'couine',
    'couiné',
    'coulai',
    'coulas',
    'coulât',
    'coulée',
    'couler',
    'coules',
    'coulés',
    'coulez',
    'coulis',
    'coupai',
    'coupas',
    'coupât',
    'coupée',
    'couper',
    'coupes',
    'coupés',
    'coupez',
    'coupla',
    'couple',
    'couplé',
    'coupon',
    'courba',
    'courbe',
    'courbé',
    'coures',
    'courez',
    'courge',
    'courir',
    'courra',
    'coursa',
    'course',
    'coursé',
    'courte',
    'courts',
    'courue',
    'courus',
    'courut',
    'courût',
    'couses',
    'cousez',
    'cousin',
    'cousis',
    'cousit',
    'cousît',
    'cousue',
    'cousus',
    'coûtai',
    'coûtas',
    'coûtât',
    'coûtée',
    'coûter',
    'coûtes',
    'coûtés',
    'coûtez',
    'coutil',
    'coutre',
    'couvai',
    'couvas',
    'couvât',
    'couvée',
    'couver',
    'couves',
    'couvés',
    'couvez',
    'couvre',
    'couvru',
    'coxale',
    'coxaux',
    'coyaus',
    'coyote',
    'crabes',
    'crabot',
    'cracha',
    'crache',
    'craché',
    'cracks',
    'cracra',
    'craies',
    'crains',
    'craint',
    'cramai',
    'cramas',
    'cramât',
    'crambé',
    'cramée',
    'cramer',
    'crames',
    'cramés',
    'cramez',
    'crampe',
    'cramsa',
    'cramse',
    'cramsé',
    'cranai',
    'crânai',
    'cranas',
    'crânas',
    'cranât',
    'crânât',
    'cranée',
    'craner',
    'crâner',
    'cranes',
    'cranés',
    'crânes',
    'cranez',
    'crânez',
    'cranta',
    'crante',
    'cranté',
    'craqua',
    'craque',
    'craqué',
    'crases',
    'crashs',
    'crassa',
    'crasse',
    'crassé',
    'craves',
    'crawla',
    'crawle',
    'crawlé',
    'crayon',
    'créais',
    'créait',
    'créant',
    'crécha',
    'créché',
    'crèche',
    'crédit',
    'créées',
    'créent',
    'créera',
    'créiez',
    'crémai',
    'crémas',
    'crémât',
    'crémer',
    'crèmes',
    'crémez',
    'crénai',
    'crénas',
    'crénât',
    'crénée',
    'créner',
    'crénés',
    'crènes',
    'crénez',
    'créole',
    'créons',
    'crêpai',
    'crêpas',
    'crêpât',
    'crêpée',
    'crêper',
    'crêpes',
    'crêpés',
    'crêpez',
    'crépie',
    'crépir',
    'crépis',
    'crépit',
    'crépît',
    'crépon',
    'crépue',
    'crépus',
    'crêtes',
    'crétin',
    'creusa',
    'creuse',
    'creusé',
    'crevai',
    'crevas',
    'crevât',
    'crevée',
    'crever',
    'crevés',
    'crèves',
    'crevez',
    'criais',
    'criait',
    'criant',
    'criard',
    'cribla',
    'crible',
    'criblé',
    'cricri',
    'criées',
    'crient',
    'criera',
    'crieur',
    'criiez',
    'crimes',
    'crions',
    'crique',
    'crises',
    'crispa',
    'crispe',
    'crispé',
    'crissa',
    'crisse',
    'crissé',
    'croate',
    'crocha',
    'croche',
    'croché',
    'crochu',
    'crocus',
    'croies',
    'croira',
    'croire',
    'croisa',
    'croise',
    'croisé',
    'croqua',
    'croque',
    'croqué',
    'crossa',
    'crosse',
    'crossé',
    'crotta',
    'crotte',
    'crotté',
    'croula',
    'croule',
    'croulé',
    'croupe',
    'croupi',
    'croûta',
    'croûte',
    'croûté',
    'croyez',
    'cruche',
    'cruels',
    'crûmes',
    'crusse',
    'crûsse',
    'crûtes',
    'crypta',
    'crypte',
    'crypté',
    'cubage',
    'cubain',
    'cubais',
    'cubait',
    'cubant',
    'cubées',
    'cubera',
    'cubiez',
    'cubons',
    'cuesta',
    'cuirai',
    'cuiras',
    'cuirez',
    'cuises',
    'cuisez',
    'cuisis',
    'cuisit',
    'cuisît',
    'cuisse',
    'cuitai',
    'cuitas',
    'cuitât',
    'cuiter',
    'cuites',
    'cuitez',
    'cuivra',
    'cuivre',
    'cuivré',
    'culais',
    'culait',
    'culant',
    'culées',
    'culent',
    'culera',
    'culiez',
    'culons',
    'culots',
    'cultes',
    'cumins',
    'cumula',
    'cumule',
    'cumulé',
    'cumuls',
    'cupide',
    'cupule',
    'curage',
    'curais',
    'curait',
    'curant',
    'curare',
    'curées',
    'curent',
    'curera',
    'cureta',
    'cureté',
    'curial',
    'curiez',
    'curium',
    'curons',
    'currys',
    'cursif',
    'cursus',
    'curule',
    'cutané',
    'cutine',
    'cutter',
    'cuvage',
    'cuvais',
    'cuvait',
    'cuvant',
    'cuveau',
    'cuvées',
    'cuvela',
    'cuvelé',
    'cuvent',
    'cuvera',
    'cuvier',
    'cuviez',
    'cuvons',
    'cycles',
    'cygnes',
    'cynips',
    'cyprès',
    'cyprin',
    'cytise',
    'dadais',
    'daguai',
    'daguas',
    'daguât',
    'daguée',
    'daguer',
    'dagues',
    'dagués',
    'daguet',
    'daguez',
    'dahirs',
    'dahlia',
    'daigna',
    'daigne',
    'daigné',
    'daïmio',
    'daines',
    'daleau',
    'dallai',
    'dallas',
    'dallât',
    'dallée',
    'daller',
    'dalles',
    'dallés',
    'dallez',
    'dalots',
    'dalton',
    'damage',
    'damais',
    'damait',
    'damans',
    'damant',
    'damées',
    'dament',
    'damera',
    'damier',
    'damiez',
    'damnai',
    'damnas',
    'damnât',
    'damnée',
    'damner',
    'damnes',
    'damnés',
    'damnez',
    'damons',
    'dandin',
    'dandys',
    'danger',
    'danien',
    'danois',
    'dansai',
    'dansas',
    'dansât',
    'dansée',
    'danser',
    'danses',
    'dansés',
    'dansez',
    'daphné',
    'darces',
    'dardai',
    'dardas',
    'dardât',
    'dardée',
    'darder',
    'dardes',
    'dardés',
    'dardez',
    'darnes',
    'darses',
    'dartre',
    'datage',
    'datais',
    'datait',
    'datant',
    'datcha',
    'datées',
    'datent',
    'datera',
    'dateur',
    'datiez',
    'datifs',
    'dation',
    'dative',
    'datons',
    'dattes',
    'datura',
    'daubai',
    'daubas',
    'daubât',
    'daubée',
    'dauber',
    'daubes',
    'daubés',
    'daubez',
    'davier',
    'dealer',
    'débâta',
    'débâte',
    'débâté',
    'débâti',
    'débats',
    'débets',
    'débile',
    'débina',
    'débine',
    'débiné',
    'débita',
    'débite',
    'débité',
    'débits',
    'déblai',
    'débord',
    'débous',
    'debout',
    'débout',
    'débris',
    'débuta',
    'débute',
    'débuté',
    'débuts',
    'décade',
    'décadi',
    'décala',
    'décale',
    'décalé',
    'décans',
    'décapa',
    'décape',
    'décapé',
    'décati',
    'décavé',
    'décéda',
    'décédé',
    'décède',
    'décela',
    'décelé',
    'décèle',
    'décent',
    'dèches',
    'déchet',
    'déchue',
    'déchus',
    'déchut',
    'déchût',
    'décida',
    'décide',
    'décidé',
    'décile',
    'décima',
    'décime',
    'décimé',
    'déclic',
    'déclin',
    'déclos',
    'déclot',
    'décoda',
    'décode',
    'décodé',
    'déçois',
    'déçoit',
    'décora',
    'décore',
    'décoré',
    'décors',
    'décote',
    'découd',
    'décret',
    'décria',
    'décrie',
    'décrié',
    'décris',
    'décrit',
    'décrua',
    'décrue',
    'décrué',
    'décrus',
    'décrut',
    'décrût',
    'déçues',
    'décuva',
    'décuve',
    'décuvé',
    'dédain',
    'dédale',
    'dedans',
    'dédiai',
    'dédias',
    'dédiât',
    'dédiée',
    'dédier',
    'dédies',
    'dédiés',
    'dédiez',
    'dédira',
    'dédire',
    'dédise',
    'dédite',
    'dédits',
    'dédora',
    'dédore',
    'dédoré',
    'déduis',
    'déduit',
    'déesse',
    'défais',
    'défait',
    'défaut',
    'défend',
    'défera',
    'déféra',
    'déféré',
    'défère',
    'défiai',
    'défias',
    'défiât',
    'défiée',
    'défier',
    'défies',
    'défiés',
    'défiez',
    'défige',
    'défigé',
    'défila',
    'défile',
    'défilé',
    'défini',
    'défont',
    'défunt',
    'dégage',
    'dégagé',
    'dégâts',
    'dégaza',
    'dégaze',
    'dégazé',
    'dégela',
    'dégelé',
    'dégèle',
    'dégels',
    'dégîta',
    'dégîte',
    'dégîté',
    'déglua',
    'déglue',
    'déglué',
    'dégota',
    'dégote',
    'dégoté',
    'dégoût',
    'dégréa',
    'dégrée',
    'dégréé',
    'degrés',
    'déhala',
    'déhale',
    'déhalé',
    'dehors',
    'déifia',
    'déifie',
    'déifié',
    'déisme',
    'déiste',
    'déités',
    'déjeta',
    'déjeté',
    'déjoua',
    'déjoue',
    'déjoué',
    'déjuge',
    'déjugé',
    'délaça',
    'délace',
    'délacé',
    'délaie',
    'délais',
    'délava',
    'délave',
    'délavé',
    'délaya',
    'délaye',
    'délayé',
    'delcos',
    'déliai',
    'délias',
    'déliât',
    'délice',
    'déliée',
    'délier',
    'délies',
    'déliés',
    'déliez',
    'délira',
    'délire',
    'déliré',
    'délita',
    'délite',
    'délité',
    'délits',
    'déloge',
    'délogé',
    'délots',
    'délova',
    'délove',
    'délové',
    'deltas',
    'déluge',
    'délura',
    'délure',
    'déluré',
    'déluta',
    'délute',
    'déluté',
    'demain',
    'démâta',
    'démâte',
    'démâté',
    'démêla',
    'démêle',
    'démêlé',
    'démena',
    'démené',
    'démène',
    'démens',
    'dément',
    'démets',
    'demies',
    'démina',
    'démine',
    'déminé',
    'démise',
    'démoda',
    'démode',
    'démodé',
    'démoli',
    'démons',
    'démord',
    'démuni',
    'démura',
    'démure',
    'démuré',
    'dengue',
    'déniai',
    'dénias',
    'déniât',
    'déniée',
    'denier',
    'dénier',
    'dénies',
    'déniés',
    'déniez',
    'dénoie',
    'dénota',
    'dénote',
    'dénoté',
    'dénoua',
    'dénoue',
    'dénoué',
    'dénoya',
    'dénoyé',
    'denrée',
    'denses',
    'dental',
    'dentée',
    'dentés',
    'dénuai',
    'dénuas',
    'dénuât',
    'dénuda',
    'dénude',
    'dénudé',
    'dénuée',
    'dénuer',
    'dénues',
    'dénués',
    'dénuez',
    'dépara',
    'dépare',
    'déparé',
    'dépars',
    'départ',
    'dépava',
    'dépave',
    'dépavé',
    'dépeça',
    'dépecé',
    'dépèce',
    'dépend',
    'dépens',
    'dépéri',
    'dépila',
    'dépile',
    'dépilé',
    'dépita',
    'dépite',
    'dépité',
    'dépits',
    'déplia',
    'déplie',
    'déplié',
    'déplus',
    'déplut',
    'déplût',
    'dépoli',
    'déport',
    'déposa',
    'dépose',
    'déposé',
    'dépota',
    'dépote',
    'dépoté',
    'dépôts',
    'dépris',
    'déprit',
    'déprît',
    'depuis',
    'dépura',
    'dépure',
    'dépuré',
    'députa',
    'députe',
    'député',
    'dérada',
    'dérade',
    'déradé',
    'dérage',
    'déragé',
    'déraie',
    'dérapa',
    'dérape',
    'dérapé',
    'dérasa',
    'dérase',
    'dérasé',
    'dérata',
    'dérate',
    'dératé',
    'déraya',
    'déraye',
    'dérayé',
    'derbys',
    'dérida',
    'déride',
    'déridé',
    'dériva',
    'dérive',
    'dérivé',
    'dermes',
    'dernys',
    'déroba',
    'dérobe',
    'dérobé',
    'déroda',
    'dérode',
    'dérodé',
    'déroge',
    'dérogé',
    'désaxa',
    'désaxe',
    'désaxé',
    'désert',
    'design',
    'désira',
    'désire',
    'désiré',
    'désirs',
    'desman',
    'désola',
    'désole',
    'désolé',
    'dessin',
    'dessus',
    'destin',
    'désuet',
    'désuni',
    'détail',
    'détala',
    'détale',
    'détalé',
    'détaxa',
    'détaxe',
    'détaxé',
    'détela',
    'dételé',
    'détend',
    'détenu',
    'détins',
    'détint',
    'détînt',
    'détira',
    'détire',
    'détiré',
    'détona',
    'détone',
    'détoné',
    'détord',
    'détors',
    'détour',
    'dettes',
    'deuils',
    'devais',
    'devait',
    'dévala',
    'dévale',
    'dévalé',
    'devant',
    'dévasa',
    'dévase',
    'dévasé',
    'devenu',
    'devers',
    'dévers',
    'dévête',
    'dévêts',
    'dévêtu',
    'déviai',
    'dévias',
    'déviât',
    'dévida',
    'dévide',
    'dévidé',
    'déviée',
    'dévier',
    'dévies',
    'déviés',
    'deviez',
    'déviez',
    'devina',
    'devine',
    'deviné',
    'devins',
    'devint',
    'devînt',
    'devisa',
    'devise',
    'devisé',
    'dévoie',
    'devoir',
    'dévolu',
    'devons',
    'dévora',
    'dévore',
    'dévoré',
    'dévote',
    'dévots',
    'dévoua',
    'dévoue',
    'dévoué',
    'dévoya',
    'dévoyé',
    'devrai',
    'devras',
    'devrez',
    'dextre',
    'diable',
    'diacre',
    'dianes',
    'diapos',
    'diapra',
    'diapre',
    'diapré',
    'diaule',
    'dictai',
    'dictas',
    'dictât',
    'dictée',
    'dicter',
    'dictes',
    'dictés',
    'dictez',
    'dicton',
    'didyme',
    'diesel',
    'dièses',
    'diètes',
    'diffas',
    'diffus',
    'digéra',
    'digéré',
    'digère',
    'digest',
    'digité',
    'digits',
    'dignes',
    'digons',
    'digues',
    'diktat',
    'dilata',
    'dilate',
    'dilaté',
    'diluai',
    'diluas',
    'diluât',
    'diluée',
    'diluer',
    'dilues',
    'dilués',
    'diluez',
    'dînais',
    'dînait',
    'dînant',
    'dinars',
    'dindes',
    'dindon',
    'dînent',
    'dînera',
    'dîners',
    'dîneur',
    'dinghy',
    'dingos',
    'dingua',
    'dingue',
    'dingué',
    'dîniez',
    'dînons',
    'diodes',
    'dionée',
    'dipode',
    'dipôle',
    'dirais',
    'dirait',
    'direct',
    'dirent',
    'dirham',
    'dirhem',
    'diriez',
    'dirige',
    'dirigé',
    'dirons',
    'diront',
    'disais',
    'disait',
    'disant',
    'discal',
    'discos',
    'disent',
    'disert',
    'diseur',
    'disiez',
    'disons',
    'dispos',
    'disque',
    'disses',
    'distal',
    'diurne',
    'divans',
    'divers',
    'divine',
    'divins',
    'divisa',
    'divise',
    'divisé',
    'dizain',
    'djaïns',
    'djinns',
    'docile',
    'docker',
    'doctes',
    'dodina',
    'dodine',
    'dodiné',
    'dodues',
    'dogger',
    'dogmes',
    'dogues',
    'doigta',
    'doigte',
    'doigté',
    'doigts',
    'doives',
    'dolais',
    'dolait',
    'dolant',
    'doleau',
    'dolées',
    'dolent',
    'dolera',
    'dolics',
    'doliez',
    'doline',
    'dollar',
    'dolman',
    'dolmen',
    'dolons',
    'domina',
    'domine',
    'dominé',
    'domino',
    'dompta',
    'dompte',
    'dompté',
    'dondon',
    'donjon',
    'donnai',
    'donnas',
    'donnât',
    'donnée',
    'donner',
    'donnes',
    'donnés',
    'donnez',
    'dopage',
    'dopais',
    'dopait',
    'dopant',
    'dopées',
    'dopent',
    'dopera',
    'dopiez',
    'doping',
    'dopons',
    'dorade',
    'dorage',
    'dorais',
    'dorait',
    'dorant',
    'dorées',
    'dorent',
    'dorera',
    'doreur',
    'dorien',
    'doriez',
    'dormes',
    'dormez',
    'dormir',
    'dormis',
    'dormit',
    'dormît',
    'dorons',
    'dorsal',
    'dorure',
    'dosage',
    'dosais',
    'dosait',
    'dosant',
    'dosées',
    'dosent',
    'dosera',
    'doseur',
    'dosiez',
    'dosons',
    'dosses',
    'dotais',
    'dotait',
    'dotale',
    'dotant',
    'dotaux',
    'dotées',
    'dotent',
    'dotera',
    'dotiez',
    'dotons',
    'douais',
    'douait',
    'douane',
    'douant',
    'douars',
    'doubla',
    'double',
    'doublé',
    'douces',
    'doucet',
    'doucha',
    'douche',
    'douché',
    'doucie',
    'doucin',
    'doucir',
    'doucis',
    'doucit',
    'doucît',
    'douées',
    'douera',
    'douiez',
    'douons',
    'douros',
    'doutai',
    'doutas',
    'doutât',
    'doutée',
    'douter',
    'doutes',
    'doutés',
    'doutez',
    'douves',
    'doyens',
    'dragée',
    'dragon',
    'dragua',
    'drague',
    'dragué',
    'draies',
    'draina',
    'draine',
    'drainé',
    'drains',
    'drames',
    'drapai',
    'drapas',
    'drapât',
    'drapée',
    'draper',
    'drapes',
    'drapés',
    'drapez',
    'draves',
    'drayai',
    'drayas',
    'drayât',
    'drayée',
    'drayer',
    'drayes',
    'drayés',
    'drayez',
    'drèges',
    'drelin',
    'drenne',
    'dressa',
    'dresse',
    'dressé',
    'drilla',
    'drille',
    'drillé',
    'drills',
    'drinks',
    'drisse',
    'drivai',
    'drivas',
    'drivât',
    'drivée',
    'driver',
    'drives',
    'drivés',
    'drivez',
    'drogua',
    'drogue',
    'drogué',
    'droite',
    'droits',
    'drôles',
    'dronte',
    'dropai',
    'dropas',
    'dropât',
    'dropée',
    'droper',
    'dropes',
    'dropés',
    'dropez',
    'droppa',
    'droppe',
    'droppé',
    'drossa',
    'drosse',
    'drossé',
    'druide',
    'drupes',
    'dryade',
    'duales',
    'ducale',
    'ducats',
    'ducaux',
    'duchés',
    'duègne',
    'duetto',
    'dugong',
    'duites',
    'dulies',
    'dûment',
    'dundee',
    'duodis',
    'dupais',
    'dupait',
    'dupant',
    'dupées',
    'dupent',
    'dupera',
    'dupeur',
    'dupiez',
    'duplex',
    'dupons',
    'duquel',
    'durais',
    'durait',
    'durant',
    'durcie',
    'durcir',
    'durcis',
    'durcit',
    'durcît',
    'durées',
    'durent',
    'durera',
    'dureté',
    'duriez',
    'durion',
    'durits',
    'durons',
    'dusses',
    'duveta',
    'duveté',
    'duvets',
    'dyades',
    'dynamo',
    'ébahie',
    'ébahir',
    'ébahis',
    'ébahit',
    'ébahît',
    'ébarba',
    'ébarbe',
    'ébarbé',
    'ébattu',
    'ébaubi',
    'ébaudi',
    'ébènes',
    'ébloui',
    'ébossa',
    'ébosse',
    'ébossé',
    'ébouai',
    'ébouas',
    'ébouât',
    'ébouée',
    'ébouer',
    'éboues',
    'éboués',
    'ébouez',
    'éboula',
    'éboule',
    'éboulé',
    'ébouta',
    'éboute',
    'ébouté',
    'ébrasa',
    'ébrase',
    'ébrasé',
    'ébroué',
    'éburné',
    'écacha',
    'écache',
    'écaché',
    'écaffa',
    'écaffe',
    'écaffé',
    'écalai',
    'écalas',
    'écalât',
    'écalée',
    'écaler',
    'écales',
    'écalés',
    'écalez',
    'écangs',
    'écarta',
    'écarte',
    'écarté',
    'écarts',
    'écatie',
    'écatir',
    'écatis',
    'écatit',
    'écatît',
    'échecs',
    'échina',
    'échine',
    'échiné',
    'échoie',
    'échoir',
    'échoit',
    'échoua',
    'échoue',
    'échoué',
    'échues',
    'écimai',
    'écimas',
    'écimât',
    'écimée',
    'écimer',
    'écimes',
    'écimés',
    'écimez',
    'éclair',
    'éclata',
    'éclate',
    'éclaté',
    'éclats',
    'éclopa',
    'éclope',
    'éclopé',
    'éclora',
    'éclore',
    'éclose',
    'éclusa',
    'écluse',
    'éclusé',
    'écobua',
    'écobue',
    'écobué',
    'écoles',
    'écopai',
    'écopas',
    'écopât',
    'écopée',
    'écoper',
    'écopes',
    'écopés',
    'écopez',
    'écorai',
    'écoras',
    'écorât',
    'écorça',
    'écorce',
    'écorcé',
    'écorée',
    'écorer',
    'écores',
    'écorés',
    'écorez',
    'écorna',
    'écorne',
    'écorné',
    'écossa',
    'écosse',
    'écossé',
    'écotée',
    'écotés',
    'écoula',
    'écoule',
    'écoulé',
    'écouta',
    'écoute',
    'écouté',
    'écrans',
    'écrasa',
    'écrase',
    'écrasé',
    'écréma',
    'écrémé',
    'écrème',
    'écrêta',
    'écrête',
    'écrêté',
    'écriée',
    'écrier',
    'écriés',
    'écriez',
    'écrins',
    'écrira',
    'écrire',
    'écrite',
    'écrits',
    'écrive',
    'écroua',
    'écroue',
    'écroué',
    'écroui',
    'écrous',
    'écrues',
    'écueil',
    'éculai',
    'éculas',
    'éculât',
    'éculée',
    'éculer',
    'écules',
    'éculés',
    'éculez',
    'écumai',
    'écumas',
    'écumât',
    'écumée',
    'écumer',
    'écumes',
    'écumés',
    'écumez',
    'écurai',
    'écuras',
    'écurât',
    'écurée',
    'écurer',
    'écures',
    'écurés',
    'écurez',
    'écurie',
    'écuyer',
    'eczéma',
    'édenta',
    'édente',
    'édenté',
    'édicta',
    'édicte',
    'édicté',
    'édifia',
    'édifie',
    'édifié',
    'édiles',
    'éditai',
    'éditas',
    'éditât',
    'éditée',
    'éditer',
    'édites',
    'édités',
    'éditez',
    'éduqua',
    'éduque',
    'éduqué',
    'éfendi',
    'effaça',
    'efface',
    'effacé',
    'effana',
    'effane',
    'effané',
    'effara',
    'effare',
    'effaré',
    'effets',
    'effila',
    'effile',
    'effilé',
    'effort',
    'effroi',
    'égaies',
    'égalai',
    'égalas',
    'égalât',
    'égalée',
    'égaler',
    'égales',
    'égalés',
    'égalez',
    'égarai',
    'égaras',
    'égarât',
    'égards',
    'égarée',
    'égarer',
    'égares',
    'égarés',
    'égarez',
    'égayai',
    'égayas',
    'égayât',
    'égayée',
    'égayer',
    'égayes',
    'égayés',
    'égayez',
    'égéens',
    'égérie',
    'égerma',
    'égerme',
    'égermé',
    'égides',
    'église',
    'égoïne',
    'égorge',
    'égorgé',
    'égouts',
    'égrena',
    'égrené',
    'égrène',
    'égrisa',
    'égrise',
    'égrisé',
    'égruge',
    'égrugé',
    'éhonté',
    'eiders',
    'éjecta',
    'éjecte',
    'éjecté',
    'elaeis',
    'élagua',
    'élague',
    'élagué',
    'élança',
    'élance',
    'élancé',
    'élargi',
    'élavée',
    'élavés',
    'elbeuf',
    'elbots',
    'élégie',
    'élégir',
    'élégis',
    'élevai',
    'élevas',
    'élevât',
    'élevée',
    'élever',
    'élevés',
    'élèves',
    'élevez',
    'élidai',
    'élidas',
    'élidât',
    'élidée',
    'élider',
    'élides',
    'élidés',
    'élidez',
    'élimai',
    'élimas',
    'élimât',
    'élimée',
    'élimer',
    'élimes',
    'élimés',
    'élimez',
    'élirai',
    'éliras',
    'élirez',
    'élises',
    'élisez',
    'élites',
    'élixir',
    'élodée',
    'éloges',
    'élonge',
    'élongé',
    'éludai',
    'éludas',
    'éludât',
    'éludée',
    'éluder',
    'éludes',
    'éludés',
    'éludez',
    'élûmes',
    'élusif',
    'élusse',
    'élûtes',
    'élytre',
    'émacié',
    'émanai',
    'émanas',
    'émanât',
    'émaner',
    'émanes',
    'émanez',
    'émarge',
    'émargé',
    'embase',
    'embats',
    'embêta',
    'embête',
    'embêté',
    'emboua',
    'emboue',
    'emboué',
    'embout',
    'embuai',
    'embuas',
    'embuât',
    'embuée',
    'embuer',
    'embues',
    'embués',
    'embuez',
    'émécha',
    'éméché',
    'émèche',
    'émerge',
    'émergé',
    'émeris',
    'émette',
    'émeute',
    'émeuve',
    'émiais',
    'émiait',
    'émiant',
    'émiées',
    'émient',
    'émiera',
    'émigra',
    'émigre',
    'émigré',
    'émiiez',
    'émîmes',
    'éminça',
    'émince',
    'émincé',
    'émions',
    'émirat',
    'émises',
    'émisse',
    'émîtes',
    'emmêla',
    'emmêle',
    'emmêlé',
    'emmena',
    'emmené',
    'emmène',
    'emmura',
    'emmure',
    'emmuré',
    'émonda',
    'émonde',
    'émondé',
    'émotif',
    'émotta',
    'émotte',
    'émotté',
    'émouds',
    'émoule',
    'émoulu',
    'empala',
    'empale',
    'empalé',
    'empans',
    'emparé',
    'empâta',
    'empâte',
    'empâté',
    'empêna',
    'empêne',
    'empêné',
    'empesa',
    'empesé',
    'empèse',
    'empila',
    'empile',
    'empilé',
    'empira',
    'empire',
    'empiré',
    'emplie',
    'emplir',
    'emplis',
    'emplit',
    'emplît',
    'emploi',
    'empois',
    'emport',
    'empota',
    'empote',
    'empoté',
    'empuse',
    'émulai',
    'émulas',
    'émulât',
    'émulée',
    'émuler',
    'émules',
    'émulés',
    'émulez',
    'émûmes',
    'émusse',
    'émûtes',
    'en-but',
    'encage',
    'encagé',
    'encans',
    'encart',
    'en-cas',
    'encava',
    'encave',
    'encavé',
    'encens',
    'encira',
    'encire',
    'enciré',
    'enclin',
    'enclos',
    'enclot',
    'encoda',
    'encode',
    'encodé',
    'encore',
    'encrai',
    'encras',
    'encrât',
    'encrée',
    'encrer',
    'encres',
    'encrés',
    'encrez',
    'encuva',
    'encuve',
    'encuvé',
    'endive',
    'endors',
    'endort',
    'enduis',
    'enduit',
    'endura',
    'endure',
    'enduré',
    'enduro',
    'énerva',
    'énerve',
    'énervé',
    'enfant',
    'enfers',
    'enfeus',
    'enfila',
    'enfile',
    'enfilé',
    'enflai',
    'enflas',
    'enflât',
    'enflée',
    'enfler',
    'enfles',
    'enflés',
    'enflez',
    'enfoui',
    'enfuie',
    'enfuir',
    'enfuis',
    'enfuma',
    'enfume',
    'enfumé',
    'enfûta',
    'enfûte',
    'enfûté',
    'engage',
    'engagé',
    'engama',
    'engame',
    'engamé',
    'engava',
    'engave',
    'engavé',
    'engins',
    'englua',
    'englue',
    'englué',
    'engoba',
    'engobe',
    'engobé',
    'engoué',
    'énième',
    'énigme',
    'enivra',
    'enivre',
    'enivré',
    'enjeux',
    'enjôla',
    'enjôle',
    'enjôlé',
    'enjoua',
    'enjoue',
    'enjoué',
    'enlaça',
    'enlace',
    'enlacé',
    'enleva',
    'enlevé',
    'enlève',
    'enliai',
    'enlias',
    'enliât',
    'enliée',
    'enlier',
    'enlies',
    'enliés',
    'enliez',
    'enlisa',
    'enlise',
    'enlisé',
    'ennemi',
    'ennuie',
    'ennuis',
    'ennuya',
    'ennuyé',
    'énonça',
    'énonce',
    'énoncé',
    'énorme',
    'énouai',
    'énouas',
    'énouât',
    'énouée',
    'énouer',
    'énoues',
    'énoués',
    'énouez',
    'enquis',
    'enrage',
    'enragé',
    'enraie',
    'enraya',
    'enraye',
    'enrayé',
    'enrêna',
    'enrêne',
    'enrêné',
    'enroba',
    'enrobe',
    'enrobé',
    'enrôla',
    'enrôle',
    'enrôlé',
    'enroua',
    'enroue',
    'enroué',
    'ensila',
    'ensile',
    'ensilé',
    'entais',
    'entait',
    'entama',
    'entame',
    'entamé',
    'entant',
    'entées',
    'entend',
    'entent',
    'entera',
    'entêta',
    'entête',
    'entêté',
    'entier',
    'entiez',
    'entité',
    'entoir',
    'entôla',
    'entôle',
    'entôlé',
    'entons',
    'entour',
    'entrai',
    'entras',
    'entrât',
    'entrée',
    'entrer',
    'entres',
    'entrés',
    'entrez',
    'entuba',
    'entube',
    'entubé',
    'enture',
    'envahi',
    'envasa',
    'envase',
    'envasé',
    'envers',
    'enviai',
    'envias',
    'enviât',
    'envida',
    'envide',
    'envidé',
    'enviée',
    'envier',
    'envies',
    'enviés',
    'enviez',
    'enviné',
    'envoie',
    'envois',
    'envolé',
    'envols',
    'envoya',
    'envoyé',
    'enzyme',
    'éocène',
    'éolien',
    'éosine',
    'épacte',
    'épairs',
    'épalai',
    'épalas',
    'épalât',
    'épalée',
    'épaler',
    'épales',
    'épalés',
    'épalez',
    'épande',
    'épands',
    'épandu',
    'épanna',
    'épanne',
    'épanné',
    'éparse',
    'épatai',
    'épatas',
    'épatât',
    'épatée',
    'épater',
    'épates',
    'épatés',
    'épatez',
    'épaula',
    'épaule',
    'épaulé',
    'épaves',
    'épeire',
    'épelai',
    'épelas',
    'épelât',
    'épelée',
    'épeler',
    'épelés',
    'épelez',
    'épelle',
    'éperdu',
    'éperon',
    'épeula',
    'épeule',
    'épeulé',
    'épeura',
    'épeure',
    'épeuré',
    'éphèbe',
    'éphods',
    'éphore',
    'épiage',
    'épiais',
    'épiait',
    'épiant',
    'épiçai',
    'épiças',
    'épiçât',
    'épicéa',
    'épicée',
    'épicer',
    'épices',
    'épicés',
    'épicez',
    'épiées',
    'épient',
    'épiera',
    'épieur',
    'épieux',
    'épigée',
    'épigés',
    'épiiez',
    'épilai',
    'épilas',
    'épilât',
    'épilée',
    'épiler',
    'épiles',
    'épilés',
    'épilez',
    'épinai',
    'épinas',
    'épinât',
    'épinça',
    'épince',
    'épincé',
    'épinée',
    'épiner',
    'épines',
    'épinés',
    'épinez',
    'épions',
    'épique',
    'épissa',
    'épisse',
    'épissé',
    'épites',
    'épître',
    'éploie',
    'éploré',
    'éploya',
    'éployé',
    'épodes',
    'éponge',
    'épongé',
    'éponte',
    'épopée',
    'époque',
    'épousa',
    'épouse',
    'épousé',
    'épouti',
    'éprise',
    'épuçai',
    'épuças',
    'épuçât',
    'épucée',
    'épucer',
    'épuces',
    'épucés',
    'épucez',
    'épuisa',
    'épuise',
    'épuisé',
    'épulie',
    'épulis',
    'épulon',
    'épurai',
    'épuras',
    'épurât',
    'épurée',
    'épurer',
    'épures',
    'épurés',
    'épurez',
    'épurge',
    'équidé',
    'équine',
    'équins',
    'équipa',
    'équipe',
    'équipé',
    'équité',
    'érable',
    'érafla',
    'érafle',
    'éraflé',
    'éraies',
    'érayai',
    'érayas',
    'érayât',
    'érayée',
    'érayer',
    'érayes',
    'érayés',
    'érayez',
    'erbine',
    'erbium',
    'ergota',
    'ergote',
    'ergoté',
    'ergots',
    'érigea',
    'érigée',
    'ériger',
    'ériges',
    'érigés',
    'érigez',
    'érigne',
    'érines',
    'ermite',
    'érodai',
    'érodas',
    'érodât',
    'érodée',
    'éroder',
    'érodes',
    'érodés',
    'érodez',
    'érosif',
    'errais',
    'errait',
    'errant',
    'errata',
    'errent',
    'errera',
    'erreur',
    'erriez',
    'erroné',
    'errons',
    'ersatz',
    'erseau',
    'éructa',
    'éructe',
    'éructé',
    'érudit',
    'escale',
    'escape',
    'eschai',
    'eschas',
    'eschât',
    'eschée',
    'escher',
    'esches',
    'eschés',
    'eschez',
    'escots',
    'escroc',
    'escudo',
    'espaça',
    'espace',
    'espacé',
    'espada',
    'espars',
    'espèce',
    'espéra',
    'espéré',
    'espère',
    'espion',
    'espoir',
    'esprit',
    'esquif',
    'essaie',
    'essaim',
    'essais',
    'essart',
    'essaya',
    'essaye',
    'essayé',
    'essieu',
    'essora',
    'essore',
    'essoré',
    'essors',
    'essuie',
    'essuya',
    'essuyé',
    'est-ce',
    'esters',
    'est-il',
    'estima',
    'estime',
    'estimé',
    'estiva',
    'estive',
    'estivé',
    'estocs',
    'est-on',
    'estran',
    'établa',
    'étable',
    'établé',
    'établi',
    'étagea',
    'étagée',
    'étager',
    'étages',
    'étagés',
    'étagez',
    'étaies',
    'étains',
    'étalai',
    'étalas',
    'étalât',
    'étalée',
    'étaler',
    'étales',
    'étalés',
    'étalez',
    'étalon',
    'étamai',
    'étamas',
    'étamât',
    'étamée',
    'étamer',
    'étames',
    'étamés',
    'étamez',
    'étampa',
    'étampe',
    'étampé',
    'étangs',
    'étapes',
    'étayai',
    'étayas',
    'étayât',
    'étayée',
    'étayer',
    'étayes',
    'étayés',
    'étayez',
    'éteins',
    'éteint',
    'étende',
    'étends',
    'étendu',
    'étêtai',
    'étêtas',
    'étêtât',
    'étêtée',
    'étêter',
    'étêtes',
    'étêtés',
    'étêtez',
    'éteufs',
    'éteule',
    'éthane',
    'éthéré',
    'éthers',
    'ethnie',
    'éthuse',
    'éthyle',
    'étiage',
    'étiers',
    'étiola',
    'étiole',
    'étiolé',
    'étions',
    'étique',
    'étirai',
    'étiras',
    'étirât',
    'étirée',
    'étirer',
    'étires',
    'étirés',
    'étirez',
    'étisie',
    'étoffa',
    'étoffe',
    'étoffé',
    'étoila',
    'étoile',
    'étoilé',
    'étoles',
    'étonna',
    'étonne',
    'étonné',
    'étoupa',
    'étoupe',
    'étoupé',
    'étrave',
    'étréci',
    'étrier',
    'étripa',
    'étripe',
    'étripé',
    'étrive',
    'étroit',
    'étrons',
    'études',
    'étudia',
    'étudie',
    'étudié',
    'étuvai',
    'étuvas',
    'étuvât',
    'étuvée',
    'étuver',
    'étuves',
    'étuvés',
    'étuvez',
    'étymon',
    'eurêka',
    'eurent',
    'eus-je',
    'eusses',
    'eus-tu',
    'eut-il',
    'eût-il',
    'eut-on',
    'eût-on',
    'évacua',
    'évacue',
    'évacué',
    'évadée',
    'évader',
    'évadés',
    'évadez',
    'évalua',
    'évalue',
    'évalué',
    'évasai',
    'évasas',
    'évasât',
    'évasée',
    'évaser',
    'évases',
    'évasés',
    'évasez',
    'évasif',
    'évêché',
    'éveils',
    'éventa',
    'évente',
    'éventé',
    'évents',
    'évêque',
    'évidai',
    'évidas',
    'évidât',
    'évidée',
    'évider',
    'évides',
    'évidés',
    'évidez',
    'éviers',
    'évinça',
    'évince',
    'évincé',
    'évitai',
    'évitas',
    'évitât',
    'évitée',
    'éviter',
    'évites',
    'évités',
    'évitez',
    'évolua',
    'évolue',
    'évolué',
    'évoqua',
    'évoque',
    'évoqué',
    'evzone',
    'exacte',
    'exacts',
    'exalta',
    'exalte',
    'exalté',
    'examen',
    'exauça',
    'exauce',
    'exaucé',
    'excava',
    'excave',
    'excavé',
    'excéda',
    'excédé',
    'excède',
    'excipa',
    'excipe',
    'excipé',
    'excisa',
    'excise',
    'excisé',
    'excita',
    'excite',
    'excité',
    'exclue',
    'exclus',
    'exclut',
    'exclût',
    'excusa',
    'excuse',
    'excusé',
    'exécra',
    'exécré',
    'exècre',
    'exèdre',
    'exempt',
    'exerça',
    'exerce',
    'exercé',
    'exhala',
    'exhale',
    'exhalé',
    'exhiba',
    'exhibe',
    'exhibé',
    'exhuma',
    'exhume',
    'exhumé',
    'exigea',
    'exigée',
    'exiger',
    'exiges',
    'exigés',
    'exigez',
    'exiguë',
    'exigus',
    'exilai',
    'exilas',
    'exilât',
    'exilée',
    'exiler',
    'exiles',
    'exilés',
    'exilez',
    'exista',
    'existe',
    'existé',
    'exocet',
    'exodes',
    'exondé',
    'exorde',
    'expert',
    'expiai',
    'expias',
    'expiât',
    'expiée',
    'expier',
    'expies',
    'expiés',
    'expiez',
    'expira',
    'expire',
    'expiré',
    'exposa',
    'expose',
    'exposé',
    'exprès',
    'exquis',
    'exsuda',
    'exsude',
    'exsudé',
    'extase',
    'exulta',
    'exulte',
    'exulté',
    'fables',
    'fabula',
    'fabule',
    'fabulé',
    'façade',
    'fâchai',
    'fâchas',
    'fâchât',
    'fâchée',
    'fâcher',
    'fâches',
    'fâchés',
    'fâcheu',
    'fâchez',
    'fachos',
    'facial',
    'faciès',
    'facile',
    'façons',
    'factum',
    'fadais',
    'fadait',
    'fadant',
    'fadées',
    'fadent',
    'fadera',
    'fadeur',
    'fadiez',
    'fading',
    'fadons',
    'fafiot',
    'fagnes',
    'fagota',
    'fagote',
    'fagoté',
    'fagots',
    'faible',
    'faibli',
    'failla',
    'faille',
    'faillé',
    'failli',
    'faînes',
    'faisan',
    'faites',
    'faîtes',
    'fakirs',
    'fallut',
    'fallût',
    'falote',
    'falots',
    'faluns',
    'falzar',
    'famées',
    'fameux',
    'famine',
    'fanage',
    'fanais',
    'fanait',
    'fanant',
    'fanaux',
    'fanées',
    'fanent',
    'fanera',
    'faneur',
    'faneux',
    'fanges',
    'faniez',
    'fanion',
    'fanons',
    'faquin',
    'farads',
    'faraud',
    'farces',
    'farcie',
    'farcin',
    'farcir',
    'farcis',
    'farcit',
    'farcît',
    'fardai',
    'fardas',
    'fardât',
    'fardée',
    'farder',
    'fardes',
    'fardés',
    'fardez',
    'farina',
    'farine',
    'fariné',
    'fartai',
    'fartas',
    'fartât',
    'fartée',
    'farter',
    'fartes',
    'fartés',
    'fartez',
    'fascée',
    'fascés',
    'fascia',
    'fascié',
    'fasses',
    'fastes',
    'fatale',
    'fatals',
    'fatmas',
    'fatras',
    'fauber',
    'faucha',
    'fauche',
    'fauché',
    'faucon',
    'faucre',
    'faudra',
    'faufil',
    'faunes',
    'faussa',
    'fausse',
    'faussé',
    'fautai',
    'fautas',
    'fautât',
    'fauter',
    'fautes',
    'fautez',
    'fautif',
    'fauves',
    'favela',
    'faveur',
    'favori',
    'faxais',
    'faxait',
    'faxant',
    'faxées',
    'faxent',
    'faxera',
    'faxiez',
    'faxons',
    'fayard',
    'fayota',
    'fayote',
    'fayoté',
    'fayots',
    'féales',
    'fécale',
    'fécaux',
    'fécial',
    'fécond',
    'fécula',
    'fécule',
    'féculé',
    'fédéra',
    'fédéré',
    'fédère',
    'féerie',
    'feigne',
    'feinta',
    'feinte',
    'feinté',
    'feints',
    'fêlais',
    'fêlait',
    'fêlant',
    'fêlées',
    'fêlent',
    'fêlera',
    'félidé',
    'fêliez',
    'féline',
    'félins',
    'fellah',
    'félons',
    'fêlons',
    'fêlure',
    'femmes',
    'fémurs',
    'fendes',
    'fendez',
    'fendis',
    'fendit',
    'fendît',
    'fendra',
    'fendre',
    'fendue',
    'fendus',
    'fenils',
    'fennec',
    'fentes',
    'fenton',
    'féodal',
    'ferais',
    'ferait',
    'férial',
    'fériée',
    'fériés',
    'feriez',
    'ferlai',
    'ferlas',
    'ferlât',
    'ferlée',
    'ferler',
    'ferles',
    'ferlés',
    'ferlez',
    'fermai',
    'fermas',
    'fermât',
    'fermée',
    'fermer',
    'fermes',
    'fermés',
    'fermez',
    'féroce',
    'ferons',
    'feront',
    'ferrai',
    'ferras',
    'ferrât',
    'ferrée',
    'ferrer',
    'ferres',
    'ferrés',
    'ferret',
    'ferrez',
    'férues',
    'férule',
    'fessai',
    'fessas',
    'fessât',
    'fessée',
    'fesser',
    'fesses',
    'fessés',
    'fessez',
    'fessue',
    'fessus',
    'festif',
    'festin',
    'feston',
    'fêtais',
    'fêtait',
    'fêtant',
    'fêtard',
    'fêtées',
    'fêtent',
    'fêtera',
    'fétide',
    'fêtiez',
    'fêtons',
    'feuils',
    'feulai',
    'feulas',
    'feulât',
    'feuler',
    'feules',
    'feulez',
    'feutra',
    'feutre',
    'feutré',
    'févier',
    'fiable',
    'fiacre',
    'fiâmes',
    'fiança',
    'fiance',
    'fiancé',
    'fiasco',
    'fiasse',
    'fiâtes',
    'fibres',
    'fibule',
    'ficela',
    'ficelé',
    'fichai',
    'fichas',
    'fichât',
    'fichée',
    'ficher',
    'fiches',
    'fichés',
    'fichet',
    'fichez',
    'fichue',
    'fichus',
    'fictif',
    'fidèle',
    'fieffa',
    'fieffe',
    'fieffé',
    'fienta',
    'fiente',
    'fienté',
    'fierai',
    'fieras',
    'fières',
    'fierez',
    'fiérot',
    'fierté',
    'fiesta',
    'fièvre',
    'fifres',
    'figaro',
    'figeai',
    'figeas',
    'figeât',
    'figées',
    'figent',
    'figera',
    'figiez',
    'figues',
    'figura',
    'figure',
    'figuré',
    'fiions',
    'filage',
    'filais',
    'filait',
    'filant',
    'filées',
    'filent',
    'filera',
    'fileta',
    'fileté',
    'filète',
    'filets',
    'fileur',
    'filial',
    'filiez',
    'filins',
    'filles',
    'filmai',
    'filmas',
    'filmât',
    'filmée',
    'filmer',
    'filmes',
    'filmés',
    'filmez',
    'filons',
    'filous',
    'filtra',
    'filtre',
    'filtré',
    'finage',
    'finale',
    'finals',
    'finaud',
    'finies',
    'finira',
    'finish',
    'fioles',
    'firent',
    'firman',
    'firmes',
    'fiscal',
    'fisses',
    'fiston',
    'fistot',
    'fixage',
    'fixais',
    'fixait',
    'fixant',
    'fixées',
    'fixent',
    'fixera',
    'fixiez',
    'fixing',
    'fixité',
    'fixons',
    'fjelds',
    'fjords',
    'flache',
    'flacon',
    'flaira',
    'flaire',
    'flairé',
    'flairs',
    'flamba',
    'flambe',
    'flambé',
    'flamme',
    'flammé',
    'flânai',
    'flânas',
    'flânât',
    'flancs',
    'flâner',
    'flânes',
    'flânez',
    'flapie',
    'flapis',
    'flaqua',
    'flaque',
    'flaqué',
    'flasha',
    'flashe',
    'flashé',
    'flatta',
    'flatte',
    'flatté',
    'flaupa',
    'flaupe',
    'flaupé',
    'fléaux',
    'flécha',
    'fléché',
    'flèche',
    'fléchi',
    'flegme',
    'fleins',
    'flemme',
    'fléole',
    'flétan',
    'flétri',
    'flette',
    'fleura',
    'fleure',
    'fleuré',
    'fleuri',
    'fleurs',
    'fleuve',
    'flints',
    'flipot',
    'flippa',
    'flippe',
    'flippé',
    'flirta',
    'flirte',
    'flirté',
    'flirts',
    'floche',
    'flocon',
    'flopée',
    'floral',
    'flores',
    'florès',
    'florin',
    'flotta',
    'flotte',
    'flotté',
    'flouai',
    'flouas',
    'flouât',
    'flouée',
    'flouer',
    'floues',
    'floués',
    'flouez',
    'flousa',
    'flouse',
    'flousé',
    'flouve',
    'flouze',
    'fluage',
    'fluais',
    'fluait',
    'fluant',
    'fluate',
    'fluent',
    'fluera',
    'fluets',
    'fluide',
    'fluiez',
    'fluons',
    'fluors',
    'flûtai',
    'flûtas',
    'flûtât',
    'flûtée',
    'flûter',
    'flûtes',
    'flûtés',
    'flûtez',
    'fluxai',
    'fluxas',
    'fluxât',
    'fluxée',
    'fluxer',
    'fluxes',
    'fluxés',
    'fluxez',
    'focale',
    'focaux',
    'foetal',
    'foetus',
    'foirai',
    'foiras',
    'foirât',
    'foirer',
    'foires',
    'foirez',
    'foison',
    'foliée',
    'folies',
    'foliés',
    'folios',
    'foliot',
    'folles',
    'follet',
    'fonçai',
    'fonças',
    'fonçât',
    'foncée',
    'foncer',
    'fonces',
    'foncés',
    'foncez',
    'fondai',
    'fondas',
    'fondât',
    'fondée',
    'fonder',
    'fondes',
    'fondés',
    'fondez',
    'fondis',
    'fondit',
    'fondît',
    'fondra',
    'fondre',
    'fondue',
    'fondus',
    'fongus',
    'fontes',
    'fontis',
    'forage',
    'forain',
    'forais',
    'forait',
    'forant',
    'forban',
    'forçai',
    'forças',
    'forçat',
    'forçât',
    'forcée',
    'forcer',
    'forces',
    'forcés',
    'forcez',
    'forcir',
    'forcis',
    'forcit',
    'forcît',
    'forées',
    'forent',
    'forera',
    'forets',
    'forêts',
    'foreur',
    'forfis',
    'forfit',
    'forfît',
    'forgea',
    'forgée',
    'forger',
    'forges',
    'forgés',
    'forgez',
    'foriez',
    'forint',
    'formai',
    'formas',
    'format',
    'formât',
    'formée',
    'formel',
    'former',
    'formes',
    'formés',
    'formez',
    'formol',
    'forons',
    'fortes',
    'fortin',
    'forums',
    'forure',
    'fosses',
    'fossés',
    'fouace',
    'fouage',
    'foudre',
    'fouées',
    'fouets',
    'foufou',
    'fougea',
    'fouger',
    'fouges',
    'fougez',
    'fougue',
    'fouies',
    'fouina',
    'fouine',
    'fouiné',
    'fouira',
    'foulai',
    'foulas',
    'foulât',
    'foulée',
    'fouler',
    'foules',
    'foulés',
    'foulez',
    'foulon',
    'fourba',
    'fourbe',
    'fourbé',
    'fourbi',
    'fourbu',
    'fourme',
    'fourmi',
    'fourni',
    'fourra',
    'fourre',
    'fourré',
    'foutes',
    'foutez',
    'foutra',
    'foutre',
    'foutue',
    'foutus',
    'fovéas',
    'foxées',
    'foyers',
    'fracas',
    'fragon',
    'fraies',
    'fraisa',
    'fraise',
    'fraisé',
    'framée',
    'franco',
    'francs',
    'frange',
    'frangé',
    'frapes',
    'frappa',
    'frappe',
    'frappé',
    'frasil',
    'frater',
    'frauda',
    'fraude',
    'fraudé',
    'frayai',
    'frayas',
    'frayât',
    'frayée',
    'frayer',
    'frayes',
    'frayés',
    'frayez',
    'freina',
    'freine',
    'freiné',
    'freins',
    'frêles',
    'frelon',
    'frémir',
    'frémis',
    'frémit',
    'frémît',
    'frênes',
    'fréons',
    'frères',
    'frérot',
    'frétai',
    'frétas',
    'frétât',
    'frétée',
    'fréter',
    'frétés',
    'frètes',
    'frétez',
    'fretin',
    'fretta',
    'frette',
    'fretté',
    'friand',
    'friche',
    'fricot',
    'frigos',
    'frimai',
    'frimas',
    'frimât',
    'frimée',
    'frimer',
    'frimes',
    'frimés',
    'frîmes',
    'frimez',
    'fripai',
    'fripas',
    'fripât',
    'fripée',
    'friper',
    'fripes',
    'fripés',
    'fripez',
    'fripon',
    'friqué',
    'frirai',
    'friras',
    'frirez',
    'frisai',
    'frisas',
    'frisât',
    'frisée',
    'friser',
    'frises',
    'frisés',
    'frisez',
    'frison',
    'frisse',
    'frites',
    'frîtes',
    'fritta',
    'fritte',
    'fritté',
    'froide',
    'froidi',
    'froids',
    'frôlai',
    'frôlas',
    'frôlât',
    'frôlée',
    'frôler',
    'frôles',
    'frôlés',
    'frôlez',
    'fronça',
    'fronce',
    'froncé',
    'fronda',
    'fronde',
    'frondé',
    'fronts',
    'frotta',
    'frotte',
    'frotté',
    'frouai',
    'frouas',
    'frouât',
    'frouer',
    'froues',
    'frouez',
    'frugal',
    'fruité',
    'fruits',
    'fruste',
    'fugace',
    'fuguai',
    'fuguas',
    'fuguât',
    'fuguer',
    'fugues',
    'fuguez',
    'führer',
    'fuient',
    'fuîmes',
    'fuirai',
    'fuiras',
    'fuirez',
    'fuisse',
    'fuites',
    'fuîtes',
    'fumage',
    'fumais',
    'fumait',
    'fumant',
    'fumées',
    'fument',
    'fumera',
    'fumets',
    'fumeur',
    'fumeux',
    'fumier',
    'fumiez',
    'fumige',
    'fumigé',
    'fumoir',
    'fumons',
    'fumure',
    'funins',
    'furent',
    'fureta',
    'fureté',
    'furète',
    'furets',
    'fureur',
    'furias',
    'furies',
    'furole',
    'furtif',
    'fusain',
    'fusais',
    'fusait',
    'fusant',
    'fuseau',
    'fusées',
    'fusela',
    'fuselé',
    'fusels',
    'fusent',
    'fusera',
    'fusiez',
    'fusils',
    'fusion',
    'fus-je',
    'fusons',
    'fusses',
    'fus-tu',
    'futaie',
    'fut-ce',
    'fût-ce',
    'futées',
    'fut-il',
    'fût-il',
    'futile',
    'fut-on',
    'fût-on',
    'future',
    'futurs',
    'fuyais',
    'fuyait',
    'fuyant',
    'fuyard',
    'fuyiez',
    'fuyons',
    'gabare',
    'gabbro',
    'gabier',
    'gabion',
    'gâbles',
    'gâchai',
    'gâchas',
    'gâchât',
    'gâchée',
    'gâcher',
    'gâches',
    'gâchés',
    'gâchez',
    'gâchis',
    'gadget',
    'gadins',
    'gadoue',
    'gaffai',
    'gaffas',
    'gaffât',
    'gaffée',
    'gaffer',
    'gaffes',
    'gaffés',
    'gaffez',
    'gageai',
    'gageas',
    'gageât',
    'gagées',
    'gagent',
    'gagera',
    'gagiez',
    'gagman',
    'gagmen',
    'gagnai',
    'gagnas',
    'gagnât',
    'gagnée',
    'gagner',
    'gagnes',
    'gagnés',
    'gagnez',
    'gaïacs',
    'gaieté',
    'gainai',
    'gainas',
    'gainât',
    'gainée',
    'gainer',
    'gaines',
    'gainés',
    'gainez',
    'galant',
    'galbai',
    'galbas',
    'galbât',
    'galbée',
    'galber',
    'galbes',
    'galbés',
    'galbez',
    'galéja',
    'galéjé',
    'galèje',
    'galène',
    'galère',
    'galets',
    'galeux',
    'galgal',
    'galion',
    'gallec',
    'gallon',
    'gallos',
    'gallup',
    'galons',
    'galopa',
    'galope',
    'galopé',
    'galops',
    'galure',
    'gambas',
    'gambes',
    'gambit',
    'gamète',
    'gamina',
    'gamine',
    'gaminé',
    'gamins',
    'gammée',
    'gammes',
    'gandin',
    'gangas',
    'gangue',
    'gansai',
    'gansas',
    'gansât',
    'gansée',
    'ganser',
    'ganses',
    'gansés',
    'gansez',
    'gantai',
    'gantas',
    'gantât',
    'gantée',
    'ganter',
    'gantes',
    'gantés',
    'gantez',
    'garage',
    'garais',
    'garait',
    'garant',
    'garces',
    'garçon',
    'gardai',
    'gardas',
    'gardât',
    'gardée',
    'garder',
    'gardes',
    'gardés',
    'gardez',
    'gardon',
    'garées',
    'garent',
    'garera',
    'gariez',
    'garnie',
    'garnir',
    'garnis',
    'garnit',
    'garnît',
    'garons',
    'garous',
    'garrot',
    'gascon',
    'gasoil',
    'gâtais',
    'gâtait',
    'gâtant',
    'gâteau',
    'gâtées',
    'gâtent',
    'gâtera',
    'gâteux',
    'gâtiez',
    'gâtine',
    'gâtons',
    'gattes',
    'gauche',
    'gauchi',
    'gaucho',
    'gaudes',
    'gaufra',
    'gaufre',
    'gaufré',
    'gaulai',
    'gaulas',
    'gaulât',
    'gaulée',
    'gauler',
    'gaules',
    'gaulés',
    'gaulez',
    'gaulis',
    'gaupes',
    'gaussa',
    'gausse',
    'gaussé',
    'gavage',
    'gavais',
    'gavait',
    'gavant',
    'gavées',
    'gavent',
    'gavera',
    'gaveur',
    'gavial',
    'gaviez',
    'gavons',
    'gayals',
    'gazage',
    'gazais',
    'gazait',
    'gazant',
    'gazées',
    'gazent',
    'gazera',
    'gazeux',
    'gazier',
    'gaziez',
    'gazole',
    'gazons',
    'géante',
    'géants',
    'geckos',
    'geigne',
    'geinte',
    'geints',
    'geisha',
    'gelais',
    'gelait',
    'gelant',
    'gelées',
    'gèlent',
    'gèlera',
    'geliez',
    'gélifs',
    'gélive',
    'gelons',
    'gélose',
    'gélule',
    'gelure',
    'gémeau',
    'gémies',
    'gémina',
    'gémine',
    'géminé',
    'gémira',
    'gemmai',
    'gemmas',
    'gemmât',
    'gemmée',
    'gemmer',
    'gemmes',
    'gemmés',
    'gemmez',
    'gênais',
    'gênait',
    'gênant',
    'gendre',
    'gênées',
    'gênent',
    'genépi',
    'génépi',
    'généra',
    'gênera',
    'généré',
    'génère',
    'genèse',
    'genets',
    'genêts',
    'gêneur',
    'génial',
    'génies',
    'gêniez',
    'génois',
    'génome',
    'gênons',
    'genoux',
    'genres',
    'gentil',
    'gentry',
    'géodes',
    'géoïde',
    'geôles',
    'gérais',
    'gérait',
    'gérant',
    'gerbai',
    'gerbas',
    'gerbât',
    'gerbée',
    'gerber',
    'gerbes',
    'gerbés',
    'gerbez',
    'gerçai',
    'gerças',
    'gerçât',
    'gercée',
    'gercer',
    'gerces',
    'gercés',
    'gercez',
    'gérées',
    'gèrent',
    'gérera',
    'gériez',
    'germai',
    'germas',
    'germât',
    'germée',
    'germen',
    'germer',
    'germes',
    'germés',
    'germez',
    'germon',
    'géromé',
    'gérons',
    'gésier',
    'gésine',
    'gesses',
    'gestes',
    'geyser',
    'ghetto',
    'ghilde',
    'giaour',
    'gibbon',
    'gibets',
    'gibier',
    'giboie',
    'giboya',
    'giboyé',
    'giclai',
    'giclas',
    'giclât',
    'giclée',
    'gicler',
    'gicles',
    'giclez',
    'giflai',
    'giflas',
    'giflât',
    'giflée',
    'gifler',
    'gifles',
    'giflés',
    'giflez',
    'gigolo',
    'gigota',
    'gigote',
    'gigoté',
    'gigots',
    'gigues',
    'gildes',
    'gilets',
    'gilles',
    'gindre',
    'ginkgo',
    'girafe',
    'giries',
    'girond',
    'girons',
    'gisais',
    'gisait',
    'gisant',
    'gisiez',
    'gisons',
    'gîtais',
    'gîtait',
    'gitane',
    'gitans',
    'gîtant',
    'gîtent',
    'gîtera',
    'gîtiez',
    'gitons',
    'gîtons',
    'givrai',
    'givras',
    'givrât',
    'givrée',
    'givrer',
    'givres',
    'givrés',
    'givrez',
    'glabre',
    'glaçai',
    'glaças',
    'glaçât',
    'glacée',
    'glacer',
    'glaces',
    'glacés',
    'glacez',
    'glacis',
    'glaçon',
    'glaira',
    'glaire',
    'glairé',
    'glaisa',
    'glaise',
    'glaisé',
    'glaive',
    'glanai',
    'glanas',
    'glanât',
    'glanda',
    'glande',
    'glandé',
    'glands',
    'glanée',
    'glaner',
    'glanes',
    'glanés',
    'glanez',
    'glapie',
    'glapir',
    'glapis',
    'glapit',
    'glapît',
    'glatir',
    'glatis',
    'glatit',
    'glatît',
    'glèbes',
    'glènes',
    'gliome',
    'glissa',
    'glisse',
    'glissé',
    'global',
    'globes',
    'gloire',
    'glomes',
    'gloria',
    'glosai',
    'glosas',
    'glosât',
    'glosée',
    'gloser',
    'gloses',
    'glosés',
    'glosez',
    'glotte',
    'gluant',
    'gluaux',
    'glumes',
    'gluten',
    'glycol',
    'glyphe',
    'gneiss',
    'gnôles',
    'gnomes',
    'gnomon',
    'gnoses',
    'gnosie',
    'gobais',
    'gobait',
    'gobant',
    'gobées',
    'gobent',
    'gobera',
    'gobeta',
    'gobeté',
    'gobeur',
    'gobies',
    'gobiez',
    'gobons',
    'godage',
    'godais',
    'godait',
    'godant',
    'godent',
    'godera',
    'godets',
    'godiez',
    'godons',
    'godron',
    'goémon',
    'goglus',
    'goitre',
    'golden',
    'golfes',
    'gombos',
    'gomina',
    'gomine',
    'gominé',
    'gommai',
    'gommas',
    'gommât',
    'gommée',
    'gommer',
    'gommes',
    'gommés',
    'gommez',
    'gonade',
    'gondai',
    'gondas',
    'gondât',
    'gondée',
    'gonder',
    'gondes',
    'gondés',
    'gondez',
    'gonfla',
    'gonfle',
    'gonflé',
    'gonzes',
    'gorets',
    'gorfou',
    'gorgea',
    'gorgée',
    'gorger',
    'gorges',
    'gorgés',
    'gorget',
    'gorgez',
    'gosier',
    'gospel',
    'gosses',
    'gotons',
    'gouape',
    'goudas',
    'gouets',
    'gouges',
    'gouine',
    'goujat',
    'goujon',
    'goulag',
    'goulée',
    'goules',
    'goulet',
    'goulot',
    'goulue',
    'goulus',
    'goupil',
    'gourai',
    'gouras',
    'gourât',
    'gourbi',
    'gourde',
    'gourds',
    'gourée',
    'gourer',
    'goures',
    'gourés',
    'gourez',
    'gourme',
    'gourmé',
    'gourou',
    'gousse',
    'goûtai',
    'goûtas',
    'goûtât',
    'goûtée',
    'goûter',
    'goûtes',
    'goûtés',
    'goûtez',
    'goutta',
    'goutte',
    'goutté',
    'goyave',
    'grabat',
    'graben',
    'grâces',
    'gracia',
    'gracie',
    'gracié',
    'gradée',
    'grader',
    'grades',
    'gradés',
    'gradin',
    'gradua',
    'gradue',
    'gradué',
    'gradus',
    'graina',
    'graine',
    'grainé',
    'grains',
    'gramen',
    'gramme',
    'grande',
    'grandi',
    'grands',
    'grange',
    'granit',
    'graphe',
    'grappe',
    'grasse',
    'gratin',
    'gratis',
    'gratta',
    'gratte',
    'gratté',
    'gravai',
    'gravas',
    'gravât',
    'gravée',
    'graver',
    'graves',
    'gravés',
    'gravez',
    'gravie',
    'gravir',
    'gravis',
    'gravit',
    'gravît',
    'gréais',
    'gréait',
    'gréant',
    'grèbes',
    'gredin',
    'gréées',
    'greens',
    'gréent',
    'greffa',
    'greffe',
    'greffé',
    'grèges',
    'gréiez',
    'grêlât',
    'grêler',
    'grêles',
    'grelin',
    'grêlon',
    'grelot',
    'grémil',
    'grenai',
    'grenas',
    'grenat',
    'grenât',
    'grenée',
    'grener',
    'grenés',
    'grènes',
    'grenez',
    'grenue',
    'grenus',
    'gréons',
    'grésai',
    'grésas',
    'grésât',
    'grésée',
    'gréser',
    'grésés',
    'grèses',
    'grésez',
    'grésil',
    'grevai',
    'grevas',
    'grevât',
    'grevée',
    'grever',
    'grevés',
    'grèves',
    'grevez',
    'griefs',
    'griffa',
    'griffe',
    'griffé',
    'griffu',
    'grigna',
    'grigne',
    'grigné',
    'grigou',
    'grigri',
    'grilla',
    'grille',
    'grillé',
    'grills',
    'grimai',
    'grimas',
    'grimât',
    'grimée',
    'grimer',
    'grimes',
    'grimés',
    'grimez',
    'grimpa',
    'grimpe',
    'grimpé',
    'grinça',
    'grince',
    'grincé',
    'gringe',
    'gringo',
    'griots',
    'grippa',
    'grippe',
    'grippé',
    'grisai',
    'grisas',
    'grisât',
    'grisbi',
    'grisée',
    'griser',
    'grises',
    'grisés',
    'griset',
    'grisez',
    'grison',
    'grisou',
    'grives',
    'groggy',
    'grogna',
    'grogne',
    'grogné',
    'groins',
    'groles',
    'grolle',
    'gronda',
    'gronde',
    'grondé',
    'grooms',
    'grosse',
    'grossi',
    'grotte',
    'groupa',
    'groupe',
    'groupé',
    'groups',
    'grouse',
    'gruaux',
    'grugea',
    'grugée',
    'gruger',
    'gruges',
    'grugés',
    'grugez',
    'grumes',
    'guanos',
    'guéais',
    'guéait',
    'guéant',
    'guèbre',
    'guèdes',
    'guéées',
    'guéent',
    'guéera',
    'guéiez',
    'guelfe',
    'guelte',
    'guenon',
    'guéons',
    'guêpes',
    'guères',
    'guéret',
    'guérie',
    'guérir',
    'guéris',
    'guérit',
    'guérît',
    'guerre',
    'guètes',
    'guêtra',
    'guêtre',
    'guêtré',
    'guetta',
    'guette',
    'guetté',
    'gueula',
    'gueule',
    'gueulé',
    'gueusa',
    'gueuse',
    'gueusé',
    'guibre',
    'guiche',
    'guidai',
    'guidas',
    'guidât',
    'guidée',
    'guider',
    'guides',
    'guidés',
    'guidez',
    'guidon',
    'guigna',
    'guigne',
    'guigné',
    'guilde',
    'guimpe',
    'guinda',
    'guinde',
    'guindé',
    'guinée',
    'guipai',
    'guipas',
    'guipât',
    'guipée',
    'guiper',
    'guipes',
    'guipés',
    'guipez',
    'guipon',
    'guises',
    'guivré',
    'gulden',
    'gunite',
    'guppys',
    'guzlas',
    'gypses',
    'gyrins',
    'habile',
    'habita',
    'habite',
    'habité',
    'habits',
    'hablai',
    'hablas',
    'hablât',
    'hablée',
    'habler',
    'hables',
    'hablés',
    'hablez',
    'hachai',
    'hachas',
    'hachât',
    'hachée',
    'hacher',
    'haches',
    'hachés',
    'hachez',
    'hachis',
    'hadith',
    'hadjis',
    'hagard',
    'haïkaï',
    'haïkus',
    'haïmes',
    'haines',
    'haïrai',
    'haïras',
    'haires',
    'haïrez',
    'haïsse',
    'haïtes',
    'halage',
    'halais',
    'hâlais',
    'halait',
    'hâlait',
    'halant',
    'hâlant',
    'halbis',
    'halées',
    'hâlées',
    'halent',
    'hâlent',
    'halera',
    'hâlera',
    'haleta',
    'haleté',
    'halète',
    'haleur',
    'haliez',
    'hâliez',
    'halles',
    'hâloir',
    'halons',
    'hâlons',
    'haltes',
    'halvas',
    'hamacs',
    'hamada',
    'hameau',
    'hammam',
    'hampes',
    'hanaps',
    'hancha',
    'hanche',
    'hanché',
    'hangar',
    'hanses',
    'hantai',
    'hantas',
    'hantât',
    'hantée',
    'hanter',
    'hantes',
    'hantés',
    'hantez',
    'happai',
    'happas',
    'happât',
    'happée',
    'happer',
    'happes',
    'happés',
    'happez',
    'haquet',
    'hardai',
    'hardas',
    'hardât',
    'hardée',
    'harder',
    'hardes',
    'hardés',
    'hardez',
    'hardie',
    'hardis',
    'harems',
    'hareng',
    'hargne',
    'harkis',
    'harles',
    'harpai',
    'harpas',
    'harpât',
    'harpée',
    'harper',
    'harpes',
    'harpés',
    'harpez',
    'harpie',
    'harpon',
    'hasard',
    'haschs',
    'hastée',
    'hastés',
    'hâtais',
    'hâtait',
    'hâtant',
    'hâtées',
    'hâtent',
    'hâtera',
    'hâtier',
    'hâtiez',
    'hâtifs',
    'hâtive',
    'hâtons',
    'hauban',
    'haussa',
    'hausse',
    'haussé',
    'hautes',
    'hautin',
    'havage',
    'havais',
    'havait',
    'havane',
    'havant',
    'havées',
    'havent',
    'havera',
    'haveur',
    'havies',
    'haviez',
    'havira',
    'havons',
    'havres',
    'hayons',
    'heaume',
    'hébéta',
    'hébété',
    'hébète',
    'hébreu',
    'hectos',
    'hégire',
    'hélais',
    'hélait',
    'hélant',
    'hélées',
    'hèlent',
    'hélera',
    'hélice',
    'héliez',
    'hélion',
    'hélium',
    'hélons',
    'hémine',
    'hennés',
    'hennie',
    'hennin',
    'hennir',
    'hennis',
    'hennit',
    'hennît',
    'henrys',
    'héraut',
    'herbai',
    'herbas',
    'herbât',
    'herbée',
    'herber',
    'herbes',
    'herbés',
    'herbez',
    'herbue',
    'herbus',
    'hérita',
    'hérite',
    'hérité',
    'hernie',
    'hernié',
    'hérons',
    'herpes',
    'herpès',
    'hersai',
    'hersas',
    'hersât',
    'hersée',
    'herser',
    'herses',
    'hersés',
    'hersez',
    'hésita',
    'hésite',
    'hésité',
    'hetman',
    'hêtres',
    'heures',
    'heurta',
    'heurte',
    'heurté',
    'heurts',
    'hévéas',
    'hexose',
    'hiatal',
    'hiatus',
    'hiboux',
    'hideur',
    'hideux',
    'hièble',
    'hiémal',
    'hi-han',
    'hilare',
    'hindou',
    'hippie',
    'hircin',
    'hissai',
    'hissas',
    'hissât',
    'hissée',
    'hisser',
    'hisses',
    'hissés',
    'hissez',
    'hivers',
    'hoccos',
    'hochai',
    'hochas',
    'hochât',
    'hochée',
    'hocher',
    'hoches',
    'hochés',
    'hochet',
    'hochez',
    'hockey',
    'hoirie',
    'homard',
    'hommes',
    'hongra',
    'hongre',
    'hongré',
    'honnie',
    'honnir',
    'honnis',
    'honnit',
    'honnît',
    'honora',
    'honore',
    'honoré',
    'hontes',
    'hoquet',
    'hordes',
    'horion',
    'hormis',
    'horsts',
    'hostie',
    'hostos',
    'hôtels',
    'hottée',
    'hottes',
    'houais',
    'houait',
    'houant',
    'houdan',
    'houées',
    'houent',
    'houera',
    'houiez',
    'houkas',
    'houles',
    'houons',
    'houppa',
    'houppe',
    'houppé',
    'houque',
    'hourda',
    'hourde',
    'hourdé',
    'hourdi',
    'hourds',
    'houris',
    'hourra',
    'houssa',
    'housse',
    'houssé',
    'hoyaux',
    'huâmes',
    'huards',
    'huarts',
    'huasse',
    'huâtes',
    'hublot',
    'huchai',
    'huchas',
    'huchât',
    'huchée',
    'hucher',
    'huches',
    'huchés',
    'huchet',
    'huchez',
    'huerai',
    'hueras',
    'huerez',
    'huerta',
    'huilai',
    'huilas',
    'huilât',
    'huilée',
    'huiler',
    'huiles',
    'huilés',
    'huilez',
    'huions',
    'huître',
    'hulula',
    'hulule',
    'hululé',
    'humage',
    'humain',
    'humais',
    'humait',
    'humant',
    'humble',
    'humées',
    'hument',
    'humera',
    'humeur',
    'humide',
    'humiez',
    'humons',
    'humour',
    'hunier',
    'huppée',
    'huppes',
    'huppés',
    'hurlai',
    'hurlas',
    'hurlât',
    'hurlée',
    'hurler',
    'hurles',
    'hurlés',
    'hurlez',
    'hurons',
    'hurrah',
    'huttes',
    'hyalin',
    'hydnes',
    'hydres',
    'hydrie',
    'hyènes',
    'hymens',
    'hymnes',
    'hyoïde',
    'hypogé',
    'hysope',
    'iambes',
    'ibères',
    'ibidem',
    'icaque',
    'ichors',
    'icônes',
    'ictère',
    'idéale',
    'idéaux',
    'idéels',
    'idiome',
    'idiote',
    'idiots',
    'idoine',
    'idoles',
    'idylle',
    'igloos',
    'igname',
    'ignare',
    'ignées',
    'ignora',
    'ignore',
    'ignoré',
    'iguane',
    'iléale',
    'iléaux',
    'iléite',
    'iléons',
    'îliens',
    'ilions',
    'illico',
    'ilotes',
    'imagea',
    'imagée',
    'imager',
    'images',
    'imagés',
    'imagez',
    'imagos',
    'imamat',
    'imanat',
    'imbiba',
    'imbibe',
    'imbibé',
    'imbues',
    'imides',
    'imitai',
    'imitas',
    'imitât',
    'imitée',
    'imiter',
    'imites',
    'imités',
    'imitez',
    'immola',
    'immole',
    'immolé',
    'immune',
    'immuns',
    'impact',
    'impair',
    'impala',
    'impayé',
    'impers',
    'impies',
    'impoli',
    'imposa',
    'impose',
    'imposé',
    'impôts',
    'impuni',
    'impure',
    'impurs',
    'imputa',
    'impute',
    'imputé',
    'inapte',
    'incisa',
    'incise',
    'incisé',
    'incita',
    'incite',
    'incité',
    'inclue',
    'inclus',
    'inclut',
    'inclût',
    'incréé',
    'incuba',
    'incube',
    'incubé',
    'incuit',
    'incuse',
    'indexa',
    'indexe',
    'indexé',
    'indiça',
    'indice',
    'indicé',
    'indien',
    'indigo',
    'indium',
    'indole',
    'indues',
    'induis',
    'induit',
    'indult',
    'indura',
    'indure',
    'induré',
    'inédit',
    'inégal',
    'inepte',
    'inerme',
    'inerte',
    'infâme',
    'infant',
    'infect',
    'inféra',
    'inféré',
    'infère',
    'infime',
    'infini',
    'infixe',
    'influa',
    'influe',
    'influé',
    'influx',
    'infule',
    'infusa',
    'infuse',
    'infusé',
    'ingénu',
    'ingéra',
    'ingéré',
    'ingère',
    'ingrat',
    'inhala',
    'inhale',
    'inhalé',
    'inhiba',
    'inhibe',
    'inhibé',
    'inhuma',
    'inhume',
    'inhumé',
    'inique',
    'initia',
    'initie',
    'initié',
    'injure',
    'inlays',
    'innées',
    'innomé',
    'innova',
    'innove',
    'innové',
    'inonda',
    'inonde',
    'inondé',
    'inouïe',
    'inouïs',
    'inputs',
    'insane',
    'inséra',
    'inséré',
    'insère',
    'insola',
    'insole',
    'insolé',
    'instar',
    'intact',
    'intima',
    'intime',
    'intimé',
    'intrus',
    'intuba',
    'intube',
    'intubé',
    'inules',
    'invita',
    'invite',
    'invité',
    'iodais',
    'iodait',
    'iodant',
    'iodate',
    'iodées',
    'iodent',
    'iodera',
    'iodiez',
    'iodlai',
    'iodlas',
    'iodlât',
    'iodlée',
    'iodler',
    'iodles',
    'iodlés',
    'iodlez',
    'iodons',
    'iodure',
    'ioduré',
    'ionien',
    'ionisa',
    'ionise',
    'ionisé',
    'ionone',
    'ioulai',
    'ioulas',
    'ioulât',
    'ioulée',
    'iouler',
    'ioules',
    'ioulés',
    'ioulez',
    'iourte',
    'ipécas',
    'ipomée',
    'iridié',
    'irions',
    'irisai',
    'irisas',
    'irisât',
    'irisée',
    'iriser',
    'irises',
    'irisés',
    'irisez',
    'iritis',
    'irones',
    'ironie',
    'irréel',
    'irrita',
    'irrite',
    'irrité',
    'isards',
    'isatis',
    'isolai',
    'isolas',
    'isolat',
    'isolât',
    'isolée',
    'isoler',
    'isoles',
    'isolés',
    'isolez',
    'isopet',
    'ISPELL',
    'issant',
    'issues',
    'isthme',
    'itérai',
    'itéras',
    'itérât',
    'itérée',
    'itérer',
    'itérés',
    'itères',
    'itérez',
    'ivette',
    'ivoire',
    'ivraie',
    'ixodes',
    'jabiru',
    'jablai',
    'jablas',
    'jablât',
    'jablée',
    'jabler',
    'jables',
    'jablés',
    'jablez',
    'jabota',
    'jabote',
    'jaboté',
    'jabots',
    'jacées',
    'jacket',
    'jacots',
    'jacqué',
    'jactai',
    'jactas',
    'jactât',
    'jactes',
    'jactez',
    'jaguar',
    'jailli',
    'jaïnas',
    'jalaps',
    'jalons',
    'jaloux',
    'jamais',
    'jambée',
    'jambes',
    'jambés',
    'jambon',
    'jantes',
    'japons',
    'jappai',
    'jappas',
    'jappât',
    'japper',
    'jappes',
    'jappez',
    'jardes',
    'jardin',
    'jardon',
    'jargon',
    'jarres',
    'jarret',
    'jasais',
    'jasait',
    'jasant',
    'jasent',
    'jasera',
    'jaseur',
    'jasiez',
    'jasmin',
    'jasons',
    'jaspai',
    'jaspas',
    'jaspât',
    'jaspée',
    'jasper',
    'jaspes',
    'jaspés',
    'jaspez',
    'jattée',
    'jattes',
    'jaugea',
    'jaugée',
    'jauger',
    'jauges',
    'jaugés',
    'jaugez',
    'jaunes',
    'jaunet',
    'jaunie',
    'jaunir',
    'jaunis',
    'jaunit',
    'jaunît',
    'javart',
    'javeau',
    'javela',
    'javelé',
    'javels',
    'jennys',
    'jerkai',
    'jerkas',
    'jerkât',
    'jerker',
    'jerkes',
    'jerkez',
    'jersey',
    'jetage',
    'jetais',
    'jetait',
    'jetant',
    'jetées',
    'jeteur',
    'jetiez',
    'jetons',
    'jettes',
    'jeudis',
    'jeûnai',
    'jeûnas',
    'jeûnât',
    'jeûner',
    'jeunes',
    'jeûnes',
    'jeunet',
    'jeûnez',
    'jeunot',
    'jigger',
    'jobard',
    'jockey',
    'jodlai',
    'jodlas',
    'jodlât',
    'jodlée',
    'jodler',
    'jodles',
    'jodlés',
    'jodlez',
    'joigne',
    'jointe',
    'joints',
    'jokers',
    'jolies',
    'jonçai',
    'jonças',
    'jonçât',
    'joncée',
    'joncer',
    'jonces',
    'joncés',
    'joncez',
    'joncha',
    'jonche',
    'jonché',
    'jongla',
    'jongle',
    'jonglé',
    'jonque',
    'joseph',
    'jouais',
    'jouait',
    'jouant',
    'jouées',
    'jouent',
    'jouera',
    'jouets',
    'joueur',
    'jouiez',
    'jouira',
    'joujou',
    'joules',
    'jouons',
    'joutai',
    'joutas',
    'joutât',
    'jouter',
    'joutes',
    'joutez',
    'jouxta',
    'jouxte',
    'jouxté',
    'jovial',
    'jovien',
    'joyaux',
    'joyeux',
    'jubila',
    'jubile',
    'jubilé',
    'juchai',
    'juchas',
    'juchât',
    'juchée',
    'jucher',
    'juches',
    'juchés',
    'juchez',
    'judoka',
    'jugale',
    'jugaux',
    'jugeai',
    'jugeas',
    'jugeât',
    'jugées',
    'jugent',
    'jugera',
    'jugeur',
    'jugiez',
    'jugula',
    'jugule',
    'jugulé',
    'juives',
    'jujube',
    'juleps',
    'julien',
    'jumbos',
    'jumeau',
    'jumela',
    'jumelé',
    'jumels',
    'jument',
    'jungle',
    'junior',
    'junker',
    'juntes',
    'jupons',
    'jurais',
    'jurait',
    'jurant',
    'jurats',
    'jurées',
    'jurent',
    'jurera',
    'jureur',
    'juriez',
    'jurons',
    'jusant',
    'jusées',
    'jusque',
    'justes',
    'jutais',
    'jutait',
    'jutant',
    'jutées',
    'jutent',
    'jutera',
    'juteux',
    'jutiez',
    'jutons',
    'kabuki',
    'kabyle',
    'kachas',
    'kaches',
    'kaiser',
    'kalium',
    'kamala',
    'kanake',
    'kaolin',
    'kapoks',
    'karaté',
    'karbau',
    'karité',
    'karman',
    'karmas',
    'kayacs',
    'kayaks',
    'kéfirs',
    'kelvin',
    'kendos',
    'kentia',
    'képhir',
    'ketchs',
    'ketmie',
    'khâgne',
    'khanat',
    'khmère',
    'khmers',
    'kimono',
    'kinase',
    'kipper',
    'kirsch',
    'kitsch',
    'klaxon',
    'knouts',
    'koalas',
    'kobold',
    'kopeck',
    'koubba',
    'koulak',
    'koumis',
    'koumys',
    'kouros',
    'kraals',
    'krachs',
    'krafts',
    'kraken',
    'kummel',
    'kurdes',
    'kyries',
    'kystes',
    'là-bas',
    'labels',
    'labeur',
    'labial',
    'labiée',
    'labiés',
    'labile',
    'labium',
    'labour',
    'labres',
    'laçage',
    'laçais',
    'laçait',
    'laçant',
    'lacées',
    'lacent',
    'lacera',
    'lacéra',
    'lacéré',
    'lacère',
    'lacets',
    'laceur',
    'lâchai',
    'lâchas',
    'lâchât',
    'lâchée',
    'lâcher',
    'lâches',
    'lâchés',
    'lâchez',
    'laciez',
    'laçons',
    'lactée',
    'lactés',
    'lacune',
    'ladies',
    'ladins',
    'ladite',
    'ladres',
    'lagons',
    'laguis',
    'lagune',
    'laïcat',
    'laîche',
    'laides',
    'laient',
    'laiera',
    'lainai',
    'lainas',
    'lainât',
    'lainée',
    'lainer',
    'laines',
    'lainés',
    'lainez',
    'laïque',
    'lairds',
    'laissa',
    'laisse',
    'laissé',
    'laitée',
    'laités',
    'laiton',
    'laitue',
    'laizes',
    'lamais',
    'lamait',
    'lamant',
    'lambda',
    'lambel',
    'lambic',
    'lambin',
    'lamées',
    'lament',
    'lamera',
    'lamier',
    'lamies',
    'lamiez',
    'lamina',
    'lamine',
    'laminé',
    'lamons',
    'lampai',
    'lampas',
    'lampât',
    'lampée',
    'lamper',
    'lampes',
    'lampés',
    'lampez',
    'lançai',
    'lanças',
    'lançât',
    'lancée',
    'lancer',
    'lances',
    'lancés',
    'lancez',
    'lançon',
    'landau',
    'Länder',
    'landes',
    'langea',
    'langée',
    'langer',
    'langes',
    'langés',
    'langez',
    'langue',
    'langui',
    'lanice',
    'lanier',
    'lapais',
    'lapait',
    'lapant',
    'lapées',
    'lapent',
    'lapera',
    'lapiaz',
    'lapida',
    'lapide',
    'lapidé',
    'lapiez',
    'lapine',
    'lapins',
    'lapone',
    'lapons',
    'lapsus',
    'laptot',
    'laquai',
    'laquas',
    'laquât',
    'laquée',
    'laquer',
    'laques',
    'laqués',
    'laquez',
    'larbin',
    'larcin',
    'lardai',
    'lardas',
    'lardât',
    'lardée',
    'larder',
    'lardes',
    'lardés',
    'lardez',
    'lardon',
    'larges',
    'largos',
    'largua',
    'largue',
    'largué',
    'larmes',
    'larron',
    'larvée',
    'larves',
    'larvés',
    'larynx',
    'lascar',
    'lascif',
    'lasers',
    'lassai',
    'lassas',
    'lassât',
    'lassée',
    'lasser',
    'lasses',
    'lassés',
    'lassez',
    'lassis',
    'lassos',
    'lastex',
    'latent',
    'latine',
    'latins',
    'latrie',
    'lattai',
    'lattas',
    'lattât',
    'lattée',
    'latter',
    'lattes',
    'lattés',
    'lattez',
    'lattis',
    'laudes',
    'laurée',
    'laurés',
    'lavabo',
    'lavage',
    'lavais',
    'lavait',
    'lavant',
    'lavées',
    'lavent',
    'lavera',
    'laveur',
    'laviez',
    'lavoir',
    'lavons',
    'lavure',
    'laxité',
    'layais',
    'layait',
    'layant',
    'layées',
    'layent',
    'layera',
    'layiez',
    'layons',
    'lazzis',
    'leader',
    'lebels',
    'léchai',
    'léchas',
    'léchât',
    'léchée',
    'lécher',
    'léchés',
    'lèches',
    'léchez',
    'leçons',
    'légale',
    'legato',
    'légats',
    'légaux',
    'légère',
    'légers',
    'légion',
    'léguai',
    'léguas',
    'léguât',
    'léguée',
    'léguer',
    'légués',
    'lègues',
    'léguez',
    'légume',
    'lemmes',
    'lémure',
    'lendit',
    'lentes',
    'lentos',
    'léonin',
    'lèpres',
    'lequel',
    'lerche',
    'lérots',
    'lésais',
    'lésait',
    'lésant',
    'lésées',
    'lèsent',
    'lésera',
    'lésiez',
    'lésina',
    'lésine',
    'lésiné',
    'lésion',
    'lésons',
    'lestai',
    'lestas',
    'lestât',
    'lestée',
    'lester',
    'lestes',
    'lestés',
    'lestez',
    'létale',
    'létaux',
    'letchi',
    'lettre',
    'lettré',
    'leudes',
    'leurra',
    'leurre',
    'leurré',
    'levage',
    'levain',
    'levais',
    'levait',
    'levant',
    'levées',
    'lèvent',
    'lèvera',
    'levier',
    'leviez',
    'lévige',
    'lévigé',
    'lévite',
    'levons',
    'lèvres',
    'levron',
    'levure',
    'lexème',
    'lexies',
    'lézard',
    'liages',
    'liâmes',
    'lianes',
    'liante',
    'liants',
    'liarda',
    'liarde',
    'liardé',
    'liards',
    'liasse',
    'liâtes',
    'libage',
    'libéra',
    'libéré',
    'libère',
    'libers',
    'libido',
    'libres',
    'libyen',
    'lichai',
    'lichas',
    'lichât',
    'lichée',
    'lichen',
    'licher',
    'liches',
    'lichés',
    'lichez',
    'licier',
    'licita',
    'licite',
    'licité',
    'licols',
    'licous',
    'liégea',
    'liégée',
    'liéger',
    'liégés',
    'lièges',
    'liégez',
    'lierai',
    'lieras',
    'lierez',
    'lierne',
    'lierre',
    'liesse',
    'lieues',
    'lieurs',
    'lieuse',
    'lièvre',
    'liftai',
    'liftas',
    'liftât',
    'liftée',
    'lifter',
    'liftes',
    'liftés',
    'liftez',
    'ligand',
    'ligies',
    'lignai',
    'lignas',
    'lignât',
    'lignée',
    'ligner',
    'lignes',
    'lignés',
    'lignez',
    'ligota',
    'ligote',
    'ligoté',
    'ligots',
    'liguai',
    'liguas',
    'liguât',
    'liguée',
    'liguer',
    'ligues',
    'ligués',
    'liguez',
    'ligule',
    'ligulé',
    'ligure',
    'liions',
    'lilial',
    'limace',
    'limage',
    'limais',
    'limait',
    'limans',
    'limant',
    'limbes',
    'limées',
    'liment',
    'limera',
    'limeur',
    'limier',
    'limiez',
    'limita',
    'limite',
    'limité',
    'limnée',
    'limoge',
    'limogé',
    'limona',
    'limone',
    'limoné',
    'limons',
    'limule',
    'linéal',
    'liners',
    'lingam',
    'lingas',
    'lingea',
    'lingée',
    'linger',
    'linges',
    'lingés',
    'lingez',
    'lingot',
    'linier',
    'linons',
    'linter',
    'lionne',
    'lipase',
    'lipide',
    'lipome',
    'lippée',
    'lippes',
    'lippue',
    'lippus',
    'lirais',
    'lirait',
    'liriez',
    'lirons',
    'liront',
    'lisage',
    'lisais',
    'lisait',
    'lisant',
    'lisent',
    'lisera',
    'liséra',
    'liseré',
    'liséré',
    'lisère',
    'liseur',
    'lisier',
    'lisiez',
    'lisons',
    'lissai',
    'lissas',
    'lissât',
    'lissée',
    'lisser',
    'lisses',
    'lissés',
    'lissez',
    'listai',
    'listas',
    'listât',
    'listée',
    'listel',
    'lister',
    'listes',
    'listés',
    'listez',
    'litais',
    'litait',
    'litant',
    'litchi',
    'liteau',
    'litées',
    'litent',
    'litera',
    'litham',
    'lithos',
    'litiez',
    'litige',
    'litons',
    'litote',
    'litres',
    'litron',
    'litsam',
    'liures',
    'livets',
    'livide',
    'living',
    'livrai',
    'livras',
    'livrât',
    'livrée',
    'livrer',
    'livres',
    'livrés',
    'livret',
    'livrez',
    'llanos',
    'loader',
    'lobais',
    'lobait',
    'lobant',
    'lobées',
    'lobent',
    'lobera',
    'lobiez',
    'lobons',
    'lobulé',
    'locale',
    'locaux',
    'lochai',
    'lochas',
    'lochât',
    'lochée',
    'locher',
    'loches',
    'lochés',
    'lochez',
    'loculé',
    'lodens',
    'lofais',
    'lofait',
    'lofant',
    'lofent',
    'lofera',
    'lofiez',
    'lofons',
    'logeai',
    'logeas',
    'logeât',
    'logées',
    'logent',
    'logera',
    'logeur',
    'loggia',
    'logiez',
    'loisir',
    'lokoum',
    'lombes',
    'longea',
    'longée',
    'longer',
    'longes',
    'longés',
    'longez',
    'longue',
    'loochs',
    'loofas',
    'lopins',
    'loquai',
    'loquas',
    'loquât',
    'loquée',
    'loquer',
    'loques',
    'loqués',
    'loquet',
    'loquez',
    'lorans',
    'lorgna',
    'lorgne',
    'lorgné',
    'loriot',
    'lorsqu',
    'lotier',
    'loties',
    'lotion',
    'lotira',
    'lottes',
    'louage',
    'louais',
    'louait',
    'louant',
    'loubar',
    'loucha',
    'louche',
    'louché',
    'louchi',
    'louées',
    'louent',
    'louera',
    'loueur',
    'loufai',
    'loufas',
    'loufât',
    'loufer',
    'loufes',
    'loufez',
    'lougre',
    'louiez',
    'loulou',
    'louons',
    'loupai',
    'loupas',
    'loupât',
    'loupée',
    'louper',
    'loupes',
    'loupés',
    'loupez',
    'lourai',
    'louras',
    'lourât',
    'lourda',
    'lourde',
    'lourdé',
    'lourds',
    'lourée',
    'lourer',
    'loures',
    'lourés',
    'lourez',
    'loutre',
    'louvai',
    'louvas',
    'louvât',
    'louvée',
    'louver',
    'louves',
    'louvés',
    'louvet',
    'louvez',
    'lovais',
    'lovait',
    'lovant',
    'lovées',
    'lovent',
    'lovera',
    'loviez',
    'lovons',
    'loyale',
    'loyaux',
    'loyers',
    'lubies',
    'lucane',
    'lucide',
    'lucres',
    'ludion',
    'luette',
    'lueurs',
    'luffas',
    'lugeai',
    'lugeas',
    'lugeât',
    'lugent',
    'lugera',
    'lugeur',
    'lugiez',
    'luirai',
    'luiras',
    'luirez',
    'luises',
    'luisez',
    'luisis',
    'luisit',
    'luisît',
    'luites',
    'lumens',
    'luncha',
    'lunche',
    'lunché',
    'lunchs',
    'lundis',
    'lunées',
    'lunule',
    'lunure',
    'lupins',
    'lurent',
    'lurons',
    'lusins',
    'lusses',
    'lustra',
    'lustre',
    'lustré',
    'lutais',
    'lutait',
    'lutant',
    'lutées',
    'lutent',
    'lutera',
    'lutiez',
    'lutina',
    'lutine',
    'lutiné',
    'lutins',
    'lutons',
    'lutrin',
    'luttai',
    'luttas',
    'luttât',
    'lutter',
    'luttes',
    'luttez',
    'luxais',
    'luxait',
    'luxant',
    'luxées',
    'luxent',
    'luxera',
    'luxiez',
    'luxons',
    'luxure',
    'luzule',
    'lycaon',
    'lycéen',
    'lycées',
    'lycène',
    'lychee',
    'lycope',
    'lycose',
    'lydien',
    'lymphe',
    'lyncha',
    'lynche',
    'lynché',
    'lyrics',
    'lysais',
    'lysait',
    'lysant',
    'lysats',
    'lysées',
    'lysent',
    'lysera',
    'lysiez',
    'lysine',
    'lysons',
    'maboul',
    'macéra',
    'macéré',
    'macère',
    'mâchai',
    'mâchas',
    'mâchât',
    'mâchée',
    'mâcher',
    'mâches',
    'mâchés',
    'mâchez',
    'machin',
    'machos',
    'maclai',
    'maclas',
    'maclât',
    'maclée',
    'macler',
    'macles',
    'maclés',
    'maclez',
    'maçons',
    'mâcons',
    'macqua',
    'macque',
    'macqué',
    'macres',
    'macula',
    'macule',
    'maculé',
    'madame',
    'madère',
    'madone',
    'madras',
    'madrée',
    'madrés',
    'maffia',
    'mafflu',
    'mafias',
    'magies',
    'magmas',
    'magnai',
    'magnas',
    'magnat',
    'magnât',
    'magnée',
    'magner',
    'magnes',
    'magnés',
    'magnez',
    'magnum',
    'magots',
    'magret',
    'mahous',
    'maïeur',
    'maigre',
    'maigri',
    'mailla',
    'maille',
    'maillé',
    'mainte',
    'maints',
    'maires',
    'mairie',
    'maison',
    'maître',
    'majeur',
    'majora',
    'majore',
    'majoré',
    'majors',
    'malade',
    'malaga',
    'malais',
    'malard',
    'malart',
    'malaxa',
    'malaxe',
    'malaxé',
    'malfis',
    'malfit',
    'malfît',
    'malgré',
    'malice',
    'malien',
    'malins',
    'malles',
    'maltai',
    'maltas',
    'maltât',
    'maltée',
    'malter',
    'maltes',
    'maltés',
    'maltez',
    'mamans',
    'mambos',
    'mamelu',
    'mamies',
    'mammys',
    'manade',
    'manage',
    'managé',
    'manant',
    'manche',
    'mancie',
    'mandai',
    'mandas',
    'mandat',
    'mandât',
    'mandée',
    'mander',
    'mandes',
    'mandés',
    'mandez',
    'manégé',
    'manège',
    'mangea',
    'mangée',
    'manger',
    'manges',
    'mangés',
    'mangez',
    'mangue',
    'maniai',
    'manias',
    'maniât',
    'maniée',
    'manier',
    'manies',
    'maniés',
    'maniez',
    'manioc',
    'mannes',
    'manoir',
    'manqua',
    'manque',
    'manqué',
    'manses',
    'mantes',
    'manuel',
    'maorie',
    'maoris',
    'maquai',
    'maquas',
    'maquât',
    'maquée',
    'maquer',
    'maques',
    'maqués',
    'maquez',
    'maquis',
    'marais',
    'maraud',
    'marbra',
    'marbre',
    'marbré',
    'marcha',
    'marche',
    'marché',
    'mardis',
    'marées',
    'marfil',
    'margay',
    'margea',
    'margée',
    'marger',
    'marges',
    'margés',
    'margez',
    'margis',
    'mariai',
    'marial',
    'marias',
    'mariât',
    'mariée',
    'marier',
    'maries',
    'mariés',
    'mariez',
    'marina',
    'marine',
    'mariné',
    'marins',
    'marlis',
    'marlou',
    'marmot',
    'marnai',
    'marnas',
    'marnât',
    'marnée',
    'marner',
    'marnes',
    'marnés',
    'marnez',
    'marqua',
    'marque',
    'marqué',
    'marrai',
    'marras',
    'marrât',
    'marrée',
    'marrer',
    'marres',
    'marrés',
    'marrez',
    'marrie',
    'marris',
    'marron',
    'martel',
    'martes',
    'martre',
    'martyr',
    'masers',
    'masqua',
    'masque',
    'masqué',
    'massai',
    'massas',
    'massât',
    'massée',
    'masser',
    'masses',
    'massés',
    'massez',
    'massif',
    'massue',
    'mastic',
    'mastoc',
    'masure',
    'matafs',
    'matage',
    'matais',
    'mâtais',
    'matait',
    'mâtait',
    'matant',
    'mâtant',
    'matcha',
    'matche',
    'matché',
    'matchs',
    'matées',
    'mâtées',
    'matent',
    'mâtent',
    'matera',
    'mâtera',
    'maties',
    'matiez',
    'mâtiez',
    'mâtina',
    'matine',
    'mâtiné',
    'matins',
    'matira',
    'matité',
    'matoir',
    'matois',
    'matons',
    'mâtons',
    'matous',
    'matras',
    'mattes',
    'matura',
    'mature',
    'maturé',
    'mâture',
    'maudis',
    'maudit',
    'maudît',
    'maures',
    'mauser',
    'mauves',
    'mauvis',
    'maxima',
    'maxime',
    'mayens',
    'mazout',
    'mécano',
    'mécène',
    'méchai',
    'méchas',
    'méchât',
    'méchée',
    'mécher',
    'méchés',
    'mèches',
    'méchez',
    'mechta',
    'médial',
    'médian',
    'médias',
    'médiat',
    'médina',
    'médira',
    'médire',
    'médisa',
    'médise',
    'médisé',
    'médita',
    'médite',
    'médité',
    'médits',
    'médium',
    'médius',
    'médocs',
    'médusa',
    'méduse',
    'médusé',
    'méfais',
    'méfait',
    'méfera',
    'méfiai',
    'méfias',
    'méfiât',
    'méfiée',
    'méfier',
    'méfies',
    'méfiés',
    'méfiez',
    'méfont',
    'mégère',
    'mégies',
    'mégira',
    'mégohm',
    'mégota',
    'mégote',
    'mégoté',
    'mégots',
    'méhara',
    'méhari',
    'meïjis',
    'méiose',
    'méjuge',
    'méjugé',
    'mêlais',
    'mêlait',
    'mêlant',
    'mêlées',
    'méléna',
    'mêlent',
    'mêlera',
    'mélèze',
    'mélias',
    'mêliez',
    'mellâh',
    'melons',
    'mêlons',
    'membre',
    'membré',
    'membru',
    'mémère',
    'menaça',
    'menace',
    'menacé',
    'ménade',
    'ménage',
    'ménagé',
    'menais',
    'menait',
    'menant',
    'mendia',
    'mendie',
    'mendié',
    'meneau',
    'menées',
    'mènent',
    'mènera',
    'meneur',
    'menhir',
    'meniez',
    'menine',
    'menins',
    'menons',
    'menses',
    'mental',
    'mentes',
    'mentez',
    'menthe',
    'mentie',
    'mentir',
    'mentis',
    'mentit',
    'mentît',
    'menton',
    'mentor',
    'menues',
    'menuet',
    'ménure',
    'méplat',
    'mépris',
    'méprit',
    'méprît',
    'mercis',
    'merdes',
    'merise',
    'mérita',
    'mérite',
    'mérité',
    'merlan',
    'merles',
    'merlin',
    'merlon',
    'merlus',
    'mérous',
    'messer',
    'messes',
    'messie',
    'mestre',
    'mesura',
    'mesure',
    'mesuré',
    'mésusa',
    'mésuse',
    'mésusé',
    'métaux',
    'méteil',
    'métier',
    'métope',
    'métrai',
    'métras',
    'métrât',
    'métrée',
    'métrer',
    'métrés',
    'mètres',
    'métrez',
    'métros',
    'mettes',
    'mettez',
    'mettra',
    'mettre',
    'meubla',
    'meuble',
    'meublé',
    'meugla',
    'meugle',
    'meuglé',
    'meulai',
    'meulas',
    'meulât',
    'meulée',
    'meuler',
    'meules',
    'meulés',
    'meulez',
    'meulon',
    'meures',
    'meutes',
    'meuves',
    'mévend',
    'mézail',
    'miaous',
    'miasme',
    'miaula',
    'miaule',
    'miaulé',
    'mi-bas',
    'micacé',
    'miches',
    'micmac',
    'micron',
    'micros',
    'miellé',
    'mienne',
    'miette',
    'mièvre',
    'mi-fin',
    'mignon',
    'migrai',
    'migras',
    'migrât',
    'migrer',
    'migres',
    'migrez',
    'mijota',
    'mijote',
    'mijoté',
    'mikado',
    'milans',
    'milice',
    'milieu',
    'milita',
    'milite',
    'milité',
    'millas',
    'milles',
    'millet',
    'milord',
    'mi-mai',
    'mimais',
    'mimait',
    'mimant',
    'mimées',
    'miment',
    'mimera',
    'mimiez',
    'mimons',
    'mimosa',
    'minage',
    'minais',
    'minait',
    'minant',
    'minbar',
    'minces',
    'mincir',
    'mincis',
    'mincit',
    'mincît',
    'minées',
    'minent',
    'minera',
    'minets',
    'mineur',
    'minier',
    'miniez',
    'minima',
    'minime',
    'minium',
    'minoen',
    'minois',
    'minons',
    'minora',
    'minore',
    'minoré',
    'minots',
    'minous',
    'minuit',
    'minuta',
    'minute',
    'minuté',
    'mioche',
    'mirage',
    'mirais',
    'mirait',
    'mirant',
    'mirées',
    'mirent',
    'mirera',
    'mireur',
    'miriez',
    'miroir',
    'mirons',
    'misais',
    'misait',
    'misant',
    'misées',
    'misent',
    'misera',
    'misère',
    'misiez',
    'misons',
    'missel',
    'misses',
    'miston',
    'mitage',
    'mitais',
    'mitait',
    'mitans',
    'mitant',
    'mitées',
    'mitent',
    'mitera',
    'miteux',
    'mitiez',
    'mitige',
    'mitigé',
    'mitons',
    'mitose',
    'mitral',
    'mitrée',
    'mitres',
    'mitrés',
    'mitron',
    'mixage',
    'mixais',
    'mixait',
    'mixant',
    'mixées',
    'mixent',
    'mixera',
    'mixers',
    'mixeur',
    'mixiez',
    'mixité',
    'mixons',
    'mixtes',
    'mobile',
    'moblot',
    'moches',
    'modale',
    'modaux',
    'modela',
    'modelé',
    'modèle',
    'modems',
    'modéra',
    'modéré',
    'modère',
    'modula',
    'module',
    'modulé',
    'moelle',
    'moeres',
    'moeurs',
    'mohair',
    'moines',
    'moirai',
    'moiras',
    'moirât',
    'moirée',
    'moirer',
    'moires',
    'moirés',
    'moirez',
    'moisai',
    'moisas',
    'moisât',
    'moisée',
    'moiser',
    'moises',
    'moisés',
    'moïses',
    'moisez',
    'moisir',
    'moisis',
    'moisit',
    'moisît',
    'moitai',
    'moitas',
    'moitât',
    'moiter',
    'moites',
    'moitez',
    'moitie',
    'moitié',
    'moitir',
    'moitis',
    'moitit',
    'moitît',
    'molène',
    'moleta',
    'moleté',
    'mollah',
    'molles',
    'mollet',
    'mollie',
    'mollir',
    'mollis',
    'mollit',
    'mollît',
    'moloch',
    'moment',
    'momies',
    'monade',
    'mondai',
    'mondas',
    'mondât',
    'mondée',
    'monder',
    'mondes',
    'mondés',
    'mondez',
    'monels',
    'monème',
    'mongol',
    'monial',
    'montai',
    'montas',
    'montât',
    'montée',
    'monter',
    'montes',
    'montés',
    'montez',
    'montra',
    'montre',
    'montré',
    'moquai',
    'moquas',
    'moquât',
    'moquée',
    'moquer',
    'moques',
    'moqués',
    'moquez',
    'morale',
    'moraux',
    'morbus',
    'mordes',
    'mordez',
    'mordis',
    'mordit',
    'mordît',
    'mordra',
    'mordre',
    'mordue',
    'mordus',
    'moreau',
    'morfal',
    'morfil',
    'morfla',
    'morfle',
    'morflé',
    'morgua',
    'morgue',
    'morgué',
    'morion',
    'morios',
    'mornée',
    'mornes',
    'mornés',
    'morose',
    'morses',
    'mortel',
    'mortes',
    'morues',
    'morula',
    'morves',
    'mosane',
    'mosans',
    'motard',
    'motels',
    'motets',
    'moteur',
    'motifs',
    'motion',
    'motiva',
    'motive',
    'motivé',
    'mottai',
    'mottas',
    'mottât',
    'mottée',
    'motter',
    'mottes',
    'mottés',
    'mottez',
    'moucha',
    'mouche',
    'mouché',
    'moudra',
    'moudre',
    'moufle',
    'moufta',
    'moufte',
    'moufté',
    'mouise',
    'moujik',
    'moulai',
    'moulas',
    'moulât',
    'moulée',
    'mouler',
    'moules',
    'moulés',
    'moulez',
    'moulin',
    'moulue',
    'moulus',
    'moulut',
    'moulût',
    'mourez',
    'mourir',
    'mouron',
    'mourra',
    'mourre',
    'mourus',
    'mourut',
    'mourût',
    'mousmé',
    'moussa',
    'mousse',
    'moussé',
    'moussu',
    'mouton',
    'mouvai',
    'mouvas',
    'mouvât',
    'mouvée',
    'mouver',
    'mouves',
    'mouvés',
    'mouvez',
    'mouvra',
    'moyées',
    'moyens',
    'moyeux',
    'muâmes',
    'muance',
    'muasse',
    'muâtes',
    'muchai',
    'muchas',
    'muchât',
    'muchée',
    'mucher',
    'muches',
    'muchés',
    'muchez',
    'mucors',
    'mucron',
    'muerai',
    'mueras',
    'muerez',
    'muette',
    'muffin',
    'mufles',
    'muftis',
    'mugira',
    'muguet',
    'muions',
    'mulard',
    'muleta',
    'mulets',
    'mulons',
    'mulota',
    'mulote',
    'muloté',
    'mulots',
    'munies',
    'munira',
    'muphti',
    'murage',
    'murais',
    'murait',
    'murale',
    'murant',
    'muraux',
    'murées',
    'murène',
    'murent',
    'murera',
    'murets',
    'mûrier',
    'mûries',
    'muriez',
    'mûrira',
    'murmel',
    'murons',
    'musais',
    'musait',
    'musant',
    'musard',
    'muscat',
    'muscla',
    'muscle',
    'musclé',
    'museau',
    'musées',
    'musela',
    'muselé',
    'musent',
    'musera',
    'muséum',
    'musiez',
    'musoir',
    'musons',
    'musqua',
    'musque',
    'musqué',
    'mussai',
    'mussas',
    'mussât',
    'mussée',
    'musser',
    'musses',
    'mussés',
    'mussez',
    'mussif',
    'mutage',
    'mutais',
    'mutait',
    'mutant',
    'mutées',
    'mutent',
    'mutera',
    'mutiez',
    'mutila',
    'mutile',
    'mutilé',
    'mutina',
    'mutine',
    'mutiné',
    'mutins',
    'mutité',
    'mutons',
    'mutuel',
    'mutule',
    'mycose',
    'mygale',
    'myiase',
    'myomes',
    'myopes',
    'myopie',
    'myrrhe',
    'myrtes',
    'mythes',
    'nababs',
    'nabote',
    'nabots',
    'nacrai',
    'nacras',
    'nacrât',
    'nacrée',
    'nacrer',
    'nacres',
    'nacrés',
    'nacrez',
    'nadirs',
    'naevus',
    'nageai',
    'nageas',
    'nageât',
    'nagées',
    'nagent',
    'nagera',
    'nageur',
    'nagiez',
    'naïade',
    'naines',
    'naisse',
    'naîtra',
    'naître',
    'naïves',
    'nanars',
    'nandou',
    'nankin',
    'nantie',
    'nantir',
    'nantis',
    'nantit',
    'nantît',
    'napalm',
    'napées',
    'napels',
    'naphte',
    'nappai',
    'nappas',
    'nappât',
    'nappée',
    'napper',
    'nappes',
    'nappés',
    'nappez',
    'naquis',
    'naquit',
    'naquît',
    'nargua',
    'nargue',
    'nargué',
    'narine',
    'narrai',
    'narras',
    'narrât',
    'narrée',
    'narrer',
    'narres',
    'narrés',
    'narrez',
    'narval',
    'nasale',
    'nasard',
    'nasaux',
    'naseau',
    'nasses',
    'natale',
    'natals',
    'natice',
    'natifs',
    'nation',
    'native',
    'natron',
    'natrum',
    'nattai',
    'nattas',
    'nattât',
    'nattée',
    'natter',
    'nattes',
    'nattés',
    'nattez',
    'nature',
    'nausée',
    'navaja',
    'navale',
    'navaux',
    'navets',
    'navire',
    'navrai',
    'navras',
    'navrât',
    'navrée',
    'navrer',
    'navres',
    'navrés',
    'navrez',
    'nazies',
    'néants',
    'nebkas',
    'nectar',
    'nèfles',
    'négoce',
    'nègres',
    'neigea',
    'neiger',
    'neiges',
    'némale',
    'nénies',
    'népète',
    'néroli',
    'nervin',
    'nervis',
    'nettes',
    'neumes',
    'neural',
    'neutre',
    'neuves',
    'neveux',
    'niable',
    'niaisa',
    'niaise',
    'niaisé',
    'niâmes',
    'niasse',
    'niâtes',
    'nichai',
    'nichas',
    'nichât',
    'nichée',
    'nicher',
    'niches',
    'nichés',
    'nichet',
    'nichez',
    'nichon',
    'nickel',
    'nicols',
    'nièces',
    'niella',
    'nielle',
    'niellé',
    'nièmes',
    'nierai',
    'nieras',
    'nierez',
    'nigaud',
    'niions',
    'nilles',
    'nimbai',
    'nimbas',
    'nimbât',
    'nimbée',
    'nimber',
    'nimbes',
    'nimbés',
    'nimbez',
    'nimbus',
    'niôles',
    'nippai',
    'nippas',
    'nippât',
    'nippée',
    'nipper',
    'nippes',
    'nippés',
    'nippez',
    'niques',
    'nitrai',
    'nitras',
    'nitrât',
    'nitrée',
    'nitrer',
    'nitres',
    'nitrés',
    'nitrez',
    'nivale',
    'nivaux',
    'nivéal',
    'niveau',
    'nivela',
    'nivelé',
    'nivôse',
    'nizeré',
    'nobles',
    'noceur',
    'nocher',
    'nocifs',
    'nocive',
    'nodule',
    'noeuds',
    'noient',
    'noiera',
    'noirci',
    'noires',
    'noises',
    'nolisa',
    'nolise',
    'nolisé',
    'nomade',
    'nombra',
    'nombre',
    'nombré',
    'nommai',
    'nommas',
    'nommât',
    'nommée',
    'nommer',
    'nommes',
    'nommés',
    'nommez',
    'nonces',
    'nonidi',
    'nonnes',
    'nopais',
    'nopait',
    'nopals',
    'nopant',
    'nopées',
    'nopent',
    'nopera',
    'nopiez',
    'nopons',
    'nordir',
    'nordis',
    'nordit',
    'nordît',
    'norias',
    'normal',
    'normée',
    'normes',
    'normés',
    'norois',
    'noroît',
    'nostoc',
    'notais',
    'notait',
    'notant',
    'notées',
    'notent',
    'notera',
    'notice',
    'notiez',
    'notion',
    'notons',
    'nôtres',
    'notule',
    'nouais',
    'nouait',
    'nouant',
    'noubas',
    'nouées',
    'nouent',
    'nouera',
    'noueux',
    'nougat',
    'nouiez',
    'noulet',
    'nounou',
    'nouons',
    'nourri',
    'nouure',
    'nouvel',
    'novais',
    'novait',
    'novant',
    'novées',
    'novent',
    'novera',
    'novice',
    'noviez',
    'novons',
    'noyade',
    'noyais',
    'noyait',
    'noyant',
    'noyaux',
    'noyées',
    'noyers',
    'noyiez',
    'noyons',
    'nuages',
    'nuâmes',
    'nuança',
    'nuance',
    'nuancé',
    'nuasse',
    'nuâtes',
    'nubien',
    'nubile',
    'nucléa',
    'nuclée',
    'nucléé',
    'nudité',
    'nuerai',
    'nueras',
    'nuerez',
    'nuions',
    'nuirai',
    'nuiras',
    'nuirez',
    'nuises',
    'nuisez',
    'nuisis',
    'nuisit',
    'nuisît',
    'nuitée',
    'nuites',
    'nulles',
    'nûment',
    'numéro',
    'numide',
    'nuques',
    'nurses',
    'nylons',
    'nymphe',
    'oasien',
    'obéira',
    'obèles',
    'obérai',
    'obéras',
    'obérât',
    'obérée',
    'obérer',
    'obérés',
    'obères',
    'obérez',
    'obèses',
    'obiers',
    'objets',
    'oblate',
    'oblats',
    'oblige',
    'obligé',
    'oblong',
    'oboles',
    'obscur',
    'obséda',
    'obsédé',
    'obsède',
    'obtenu',
    'obtins',
    'obtint',
    'obtînt',
    'obtura',
    'obture',
    'obturé',
    'obtuse',
    'obvenu',
    'obvers',
    'obviai',
    'obvias',
    'obviât',
    'obviée',
    'obvier',
    'obvies',
    'obviés',
    'obviez',
    'obvins',
    'obvint',
    'obvînt',
    'occase',
    'occlue',
    'occlus',
    'occlut',
    'occlût',
    'occupa',
    'occupe',
    'occupé',
    'océans',
    'ocelle',
    'ocellé',
    'ocelot',
    'ocrais',
    'ocrait',
    'ocrant',
    'ocrées',
    'ocrent',
    'ocrera',
    'ocreux',
    'ocriez',
    'ocrons',
    'octane',
    'octant',
    'octave',
    'octets',
    'octidi',
    'octroi',
    'octuor',
    'oculus',
    'odéons',
    'odeurs',
    'odieux',
    'odorat',
    'oedème',
    'oedipe',
    'oestre',
    'oeuvée',
    'oeuvés',
    'oeuvra',
    'oeuvre',
    'oeuvré',
    'offert',
    'office',
    'offres',
    'offrez',
    'offrir',
    'offris',
    'offrit',
    'offrît',
    'offset',
    'oflags',
    'ogival',
    'ogives',
    'oïdium',
    'oignit',
    'oignon',
    'oilles',
    'oindre',
    'ointes',
    'oiseau',
    'oisela',
    'oiselé',
    'oiseux',
    'oisifs',
    'oisive',
    'oisons',
    'okapis',
    'okoumé',
    'oléate',
    'oléine',
    'oléums',
    'olives',
    'olympe',
    'ombles',
    'ombrai',
    'ombras',
    'ombrât',
    'ombrée',
    'ombrer',
    'ombres',
    'ombrés',
    'ombrez',
    'omette',
    'omîmes',
    'omises',
    'omisse',
    'omîtes',
    'omnium',
    'onagre',
    'oncial',
    'oncles',
    'ondées',
    'ondine',
    'ondins',
    'on-dit',
    'ondoie',
    'ondoya',
    'ondoyé',
    'ondula',
    'ondule',
    'ondulé',
    'onglée',
    'ongles',
    'onglés',
    'onglet',
    'onglon',
    'ongulé',
    'onques',
    'onyxis',
    'onzain',
    'oocyte',
    'oogone',
    'opales',
    'opalin',
    'opaque',
    'opérai',
    'opéras',
    'opérât',
    'opérée',
    'opérer',
    'opérés',
    'opères',
    'opérez',
    'ophite',
    'ophrys',
    'opiaça',
    'opiace',
    'opiacé',
    'opiats',
    'opimes',
    'opinai',
    'opinas',
    'opinât',
    'opiner',
    'opines',
    'opinez',
    'opiums',
    'oppida',
    'opposa',
    'oppose',
    'opposé',
    'optais',
    'optait',
    'optant',
    'optent',
    'optera',
    'optiez',
    'optima',
    'option',
    'optons',
    'oracle',
    'orages',
    'orales',
    'orange',
    'orangé',
    'orante',
    'orants',
    'orbita',
    'orbite',
    'orbité',
    'orchis',
    'ordres',
    'ordure',
    'oréade',
    'orfroi',
    'organe',
    'orgeat',
    'orgies',
    'orgues',
    'oribus',
    'oriels',
    'orient',
    'origan',
    'ormaie',
    'ormeau',
    'ormoie',
    'ornais',
    'ornait',
    'ornant',
    'ornées',
    'ornent',
    'ornera',
    'orniez',
    'ornons',
    'orobes',
    'oronge',
    'orphie',
    'orpins',
    'orques',
    'orteil',
    'orties',
    'orvale',
    'orvets',
    'osâmes',
    'osasse',
    'osâtes',
    'oscars',
    'oscule',
    'oserai',
    'oseras',
    'oserez',
    'osides',
    'osiers',
    'osions',
    'osmium',
    'osmose',
    'osques',
    'osseux',
    'ossues',
    'ostiak',
    'otages',
    'ôtâmes',
    'otarie',
    'ôtasse',
    'ôtâtes',
    'ôterai',
    'ôteras',
    'ôterez',
    'ôtions',
    'otique',
    'otites',
    'ouatai',
    'ouatas',
    'ouatât',
    'ouatée',
    'ouater',
    'ouates',
    'ouatés',
    'ouatez',
    'oublia',
    'oublie',
    'oublié',
    'oublis',
    'ouches',
    'ouilla',
    'ouille',
    'ouillé',
    'ouîmes',
    'ouïrai',
    'ouïras',
    'ouïrez',
    'ouïsse',
    'ouîtes',
    'oukase',
    'ouléma',
    'ourdie',
    'ourdir',
    'ourdis',
    'ourdit',
    'ourdît',
    'ourdou',
    'ourlai',
    'ourlas',
    'ourlât',
    'ourlée',
    'ourler',
    'ourles',
    'ourlés',
    'ourlet',
    'ourlez',
    'ourses',
    'oursin',
    'ourson',
    'outils',
    'outlaw',
    'output',
    'outrai',
    'outras',
    'outrât',
    'outrée',
    'outrer',
    'outres',
    'outrés',
    'outrez',
    'ouvert',
    'ouvrai',
    'ouvras',
    'ouvrât',
    'ouvrée',
    'ouvrer',
    'ouvres',
    'ouvrés',
    'ouvrez',
    'ouvrir',
    'ouvris',
    'ouvrit',
    'ouvrît',
    'ouzbek',
    'ovaire',
    'ovales',
    'ovates',
    'ovibos',
    'ovidés',
    'ovines',
    'ovinés',
    'ovoïde',
    'ovulai',
    'ovulas',
    'ovulât',
    'ovuler',
    'ovules',
    'ovulez',
    'oxford',
    'oxydai',
    'oxydas',
    'oxydât',
    'oxydée',
    'oxyder',
    'oxydes',
    'oxydés',
    'oxydez',
    'oxymel',
    'oxyton',
    'oxyure',
    'ozènes',
    'ozonée',
    'ozones',
    'ozonés',
    'pacage',
    'pacagé',
    'pachas',
    'pachto',
    'pacqua',
    'pacque',
    'pacqué',
    'pacson',
    'pactes',
    'padoue',
    'padous',
    'paella',
    'pagaie',
    'pagaïe',
    'pagaya',
    'pagaye',
    'pagayé',
    'pageai',
    'pageas',
    'pageât',
    'pagées',
    'pagels',
    'pagent',
    'pageot',
    'pagera',
    'pagiez',
    'pagina',
    'pagine',
    'paginé',
    'pagnes',
    'pagode',
    'pagres',
    'pagure',
    'païens',
    'paient',
    'paiera',
    'pailla',
    'paille',
    'paillé',
    'paires',
    'pairie',
    'pairle',
    'paisse',
    'paîtra',
    'paître',
    'pajota',
    'pajote',
    'pajoté',
    'palace',
    'palais',
    'palans',
    'palées',
    'palets',
    'pâleur',
    'palier',
    'pâlies',
    'pâlira',
    'pallia',
    'pallie',
    'pallié',
    'palmai',
    'palmas',
    'palmât',
    'palmée',
    'palmer',
    'palmes',
    'palmés',
    'palmez',
    'palota',
    'palote',
    'paloté',
    'palots',
    'pâlots',
    'palpai',
    'palpas',
    'palpât',
    'palpée',
    'palper',
    'palpes',
    'palpés',
    'palpez',
    'palude',
    'paluds',
    'pâmais',
    'pâmait',
    'pâmant',
    'pâmées',
    'pâment',
    'pâmera',
    'pâmiez',
    'pâmons',
    'pampas',
    'pampre',
    'panade',
    'panais',
    'panait',
    'panama',
    'panant',
    'panard',
    'pancas',
    'pandas',
    'pandit',
    'panées',
    'panels',
    'panent',
    'panera',
    'panics',
    'panier',
    'paniez',
    'pankas',
    'pannai',
    'pannas',
    'pannât',
    'pannée',
    'panner',
    'pannes',
    'pannés',
    'pannez',
    'panons',
    'pansai',
    'pansas',
    'pansât',
    'pansée',
    'panser',
    'panses',
    'pansés',
    'pansez',
    'pansue',
    'pansus',
    'pantin',
    'panure',
    'panzer',
    'paonne',
    'papale',
    'papaux',
    'papaye',
    'papier',
    'papion',
    'papota',
    'papote',
    'papoté',
    'papule',
    'pâques',
    'paquet',
    'parada',
    'parade',
    'paradé',
    'parafa',
    'parafe',
    'parafé',
    'parage',
    'parais',
    'parait',
    'paraît',
    'parant',
    'par-ci',
    'pardon',
    'parées',
    'pareil',
    'parent',
    'paréos',
    'parera',
    'parère',
    'pareur',
    'parfis',
    'parfit',
    'parfît',
    'parfum',
    'pariai',
    'parian',
    'parias',
    'pariât',
    'pariée',
    'parier',
    'paries',
    'pariés',
    'pariez',
    'parité',
    'parkas',
    'par-là',
    'parlai',
    'parlas',
    'parlât',
    'parlée',
    'parler',
    'parles',
    'parlés',
    'parlez',
    'parois',
    'parole',
    'paroli',
    'parons',
    'parqua',
    'parque',
    'parqué',
    'parsec',
    'parsie',
    'parsis',
    'partes',
    'partez',
    'partie',
    'partir',
    'partis',
    'partit',
    'partît',
    'parton',
    'parues',
    'parure',
    'parvis',
    'pascal',
    'passai',
    'passas',
    'passât',
    'passée',
    'passer',
    'passes',
    'passés',
    'passez',
    'passif',
    'passim',
    'pastel',
    'pastis',
    'pâtais',
    'pâtait',
    'pâtant',
    'patard',
    'patate',
    'pataud',
    'pâtées',
    'patène',
    'patent',
    'pâtent',
    'pâtera',
    'patère',
    'pâteux',
    'pathos',
    'pâtiez',
    'patina',
    'patine',
    'patiné',
    'patios',
    'pâtira',
    'patois',
    'pâtons',
    'pâtres',
    'patrie',
    'patron',
    'pattai',
    'pattas',
    'pattât',
    'pattée',
    'patter',
    'pattes',
    'pattés',
    'pattez',
    'pattue',
    'pattus',
    'pâtura',
    'pâture',
    'pâturé',
    'paumai',
    'paumas',
    'paumât',
    'paumée',
    'paumer',
    'paumes',
    'paumés',
    'paumez',
    'pausai',
    'pausas',
    'pausât',
    'pauser',
    'pauses',
    'pausez',
    'pauvre',
    'pavage',
    'pavais',
    'pavait',
    'pavana',
    'pavane',
    'pavané',
    'pavant',
    'pavées',
    'pavent',
    'pavera',
    'paveur',
    'pavies',
    'paviez',
    'pavois',
    'pavons',
    'pavots',
    'payais',
    'payait',
    'payant',
    'payées',
    'payent',
    'payera',
    'payeur',
    'payiez',
    'payons',
    'paysan',
    'payses',
    'péages',
    'pébroc',
    'pécari',
    'péchai',
    'pêchai',
    'péchas',
    'pêchas',
    'péchât',
    'pêchât',
    'pêchée',
    'pécher',
    'pêcher',
    'péchés',
    'pèches',
    'pêches',
    'pêchés',
    'péchez',
    'pêchez',
    'pecnot',
    'pécore',
    'pecten',
    'pécule',
    'pédala',
    'pédale',
    'pédalé',
    'Pédalo',
    'pédant',
    'pedums',
    'pégase',
    'pègres',
    'pehlvi',
    'peigna',
    'peigne',
    'peigné',
    'peille',
    'peinai',
    'peinas',
    'peinât',
    'peinée',
    'peiner',
    'peines',
    'peinés',
    'peinez',
    'peinte',
    'peints',
    'pékans',
    'pékiné',
    'pékins',
    'pelade',
    'pelage',
    'pelais',
    'pelait',
    'pelant',
    'pelard',
    'peléen',
    'péléen',
    'pelées',
    'pèlent',
    'pèlera',
    'peliez',
    'pelles',
    'pellet',
    'pelons',
    'pelota',
    'pelote',
    'peloté',
    'peltas',
    'peltée',
    'peltés',
    'pelure',
    'pelvis',
    'pénale',
    'pénard',
    'penaud',
    'pénaux',
    'pencha',
    'penche',
    'penché',
    'pendes',
    'pendez',
    'pendis',
    'pendit',
    'pendît',
    'pendra',
    'pendre',
    'pendue',
    'pendus',
    'pénien',
    'pénils',
    'pennée',
    'pennes',
    'pennés',
    'pennon',
    'penons',
    'pensai',
    'pensas',
    'pensât',
    'pensée',
    'penser',
    'penses',
    'pensés',
    'pensez',
    'pensif',
    'pensum',
    'pentes',
    'pentue',
    'pentus',
    'péotte',
    'pépère',
    'pépiai',
    'pépias',
    'pépiât',
    'pépier',
    'pépies',
    'pépiez',
    'pépins',
    'pépite',
    'péplum',
    'pépons',
    'péquin',
    'perçai',
    'perças',
    'perçât',
    'percée',
    'percer',
    'perces',
    'percés',
    'percez',
    'percha',
    'perche',
    'perché',
    'perçue',
    'perçus',
    'perçut',
    'perçût',
    'perdes',
    'perdez',
    'perdis',
    'perdit',
    'perdît',
    'perdra',
    'perdre',
    'perdue',
    'perdus',
    'périls',
    'périma',
    'périme',
    'périmé',
    'périra',
    'perlai',
    'perlas',
    'perlât',
    'perlée',
    'perler',
    'perles',
    'perlés',
    'perlez',
    'perlon',
    'perlot',
    'permet',
    'permis',
    'permit',
    'permît',
    'péroné',
    'pérora',
    'pérore',
    'péroré',
    'pérots',
    'perron',
    'persan',
    'persel',
    'perses',
    'persil',
    'pertes',
    'pesade',
    'pesage',
    'pesais',
    'pesait',
    'pesant',
    'pesées',
    'pèsent',
    'pèsera',
    'peseta',
    'peseur',
    'pesiez',
    'pesons',
    'pesses',
    'pestai',
    'pestas',
    'pestât',
    'pester',
    'pestes',
    'pestez',
    'pétais',
    'pétait',
    'pétale',
    'pétant',
    'pétard',
    'pétase',
    'pétées',
    'pètent',
    'pétera',
    'péteur',
    'péteux',
    'pétiez',
    'petiot',
    'petite',
    'petits',
    'petons',
    'pétons',
    'pétrée',
    'pétrel',
    'pétrés',
    'pétrie',
    'pétrin',
    'pétrir',
    'pétris',
    'pétrit',
    'pétrît',
    'pétuna',
    'pétune',
    'pétuné',
    'pétuns',
    'peupla',
    'peuple',
    'peuplé',
    'peyotl',
    'pézize',
    'phages',
    'phanie',
    'phares',
    'phases',
    'phasme',
    'phénix',
    'phénol',
    'philos',
    'phobie',
    'phones',
    'phonie',
    'phonos',
    'phoque',
    'photon',
    'photos',
    'phrasa',
    'phrase',
    'phrasé',
    'phylum',
    'piaffa',
    'piaffe',
    'piaffé',
    'pianos',
    'piaula',
    'piaule',
    'piaulé',
    'pibale',
    'picage',
    'picard',
    'pichet',
    'picola',
    'picole',
    'picolé',
    'picolo',
    'picora',
    'picore',
    'picoré',
    'picota',
    'picote',
    'picoté',
    'picots',
    'picris',
    'pidgin',
    'pièces',
    'piégea',
    'piégée',
    'piéger',
    'piégés',
    'pièges',
    'piégez',
    'pierra',
    'pierre',
    'pierré',
    'piétai',
    'piétas',
    'piétât',
    'piétée',
    'piéter',
    'piétés',
    'piètes',
    'piétez',
    'piétin',
    'piéton',
    'piètre',
    'pieuse',
    'pieuta',
    'pieute',
    'pieuté',
    'pièzes',
    'pifais',
    'pifait',
    'pifant',
    'pifées',
    'pifent',
    'pifera',
    'piffai',
    'piffas',
    'piffât',
    'piffée',
    'piffer',
    'piffes',
    'piffés',
    'piffez',
    'pifiez',
    'pifons',
    'pigeai',
    'pigeas',
    'pigeât',
    'pigées',
    'pigent',
    'pigeon',
    'pigera',
    'pigiez',
    'pignes',
    'pignon',
    'pilafs',
    'pilage',
    'pilais',
    'pilait',
    'pilant',
    'pilées',
    'pilent',
    'pilera',
    'pilets',
    'pileur',
    'pileux',
    'pilier',
    'piliez',
    'pillai',
    'pillas',
    'pillât',
    'pillée',
    'piller',
    'pilles',
    'pillés',
    'pillez',
    'pilons',
    'pilori',
    'pilota',
    'pilote',
    'piloté',
    'pilous',
    'pilule',
    'pilums',
    'piment',
    'pinard',
    'pinçai',
    'pinças',
    'pinçât',
    'pincée',
    'pincer',
    'pinces',
    'pincés',
    'pincez',
    'pinçon',
    'pinéal',
    'pineau',
    'pinède',
    'pingre',
    'pinnes',
    'pinots',
    'pinson',
    'pintai',
    'pintas',
    'pintât',
    'pintée',
    'pinter',
    'pintes',
    'pintés',
    'pintez',
    'pin-up',
    'pinyin',
    'piocha',
    'pioche',
    'pioché',
    'piolet',
    'pionça',
    'pionce',
    'pioncé',
    'pionna',
    'pionne',
    'pionné',
    'pipais',
    'pipait',
    'pipant',
    'pipeau',
    'pipées',
    'pipent',
    'pipera',
    'pipeur',
    'pipier',
    'pipiez',
    'pipits',
    'pipons',
    'piquai',
    'piquas',
    'piquât',
    'piquée',
    'piquer',
    'piques',
    'piqués',
    'piquet',
    'piquez',
    'piqûre',
    'pirata',
    'pirate',
    'piraté',
    'piraya',
    'pirole',
    'pissai',
    'pissas',
    'pissat',
    'pissât',
    'pissée',
    'pisser',
    'pisses',
    'pissés',
    'pissez',
    'pistai',
    'pistas',
    'pistât',
    'pistée',
    'pister',
    'pistes',
    'pistés',
    'pistez',
    'pistil',
    'piston',
    'pistou',
    'piteux',
    'pitiés',
    'pitons',
    'pitres',
    'pivert',
    'pivota',
    'pivote',
    'pivoté',
    'pivots',
    'pizzas',
    'plaçai',
    'plaças',
    'plaçât',
    'placée',
    'placer',
    'places',
    'placés',
    'placet',
    'placez',
    'plagal',
    'plages',
    'plagia',
    'plagie',
    'plagié',
    'plaida',
    'plaide',
    'plaidé',
    'plaids',
    'plaies',
    'plaina',
    'plaine',
    'plainé',
    'plains',
    'plaint',
    'plaira',
    'plaire',
    'plaise',
    'planai',
    'planas',
    'planât',
    'planée',
    'planer',
    'planes',
    'planés',
    'planez',
    'planta',
    'plante',
    'planté',
    'plants',
    'plaqua',
    'plaque',
    'plaqué',
    'plasma',
    'plaste',
    'platée',
    'plates',
    'plâtra',
    'plâtre',
    'plâtré',
    'playon',
    'pléban',
    'plèbes',
    'pleine',
    'pleins',
    'pleura',
    'pleure',
    'pleuré',
    'pleurs',
    'pleuve',
    'plèvre',
    'plexus',
    'pleyon',
    'pliage',
    'pliais',
    'pliait',
    'pliant',
    'pliées',
    'plient',
    'pliera',
    'plieur',
    'pliiez',
    'plioir',
    'plions',
    'plique',
    'plissa',
    'plisse',
    'plissé',
    'pliure',
    'ploies',
    'plomba',
    'plombe',
    'plombé',
    'plombs',
    'plonge',
    'plongé',
    'ploqua',
    'ploque',
    'ploqué',
    'ploucs',
    'plouks',
    'ployai',
    'ployas',
    'ployât',
    'ployée',
    'ployer',
    'ployés',
    'ployez',
    'plucha',
    'pluche',
    'pluché',
    'pluies',
    'plumai',
    'plumas',
    'plumât',
    'plumée',
    'plumer',
    'plumes',
    'plumés',
    'plûmes',
    'plumet',
    'plumez',
    'plural',
    'plusse',
    'plûtes',
    'pluton',
    'plutôt',
    'pneumo',
    'pochai',
    'pochas',
    'pochât',
    'pochée',
    'pocher',
    'poches',
    'pochés',
    'pochez',
    'pochon',
    'podium',
    'podzol',
    'poêlai',
    'poêlas',
    'poêlât',
    'poêlée',
    'poêler',
    'poêles',
    'poêlés',
    'poëles',
    'poêlez',
    'poêlon',
    'poèmes',
    'poésie',
    'poètes',
    'pognes',
    'pognon',
    'pogrom',
    'poigne',
    'poilai',
    'poilas',
    'poilât',
    'poilée',
    'poiler',
    'poiles',
    'poilés',
    'poilez',
    'poilue',
    'poilus',
    'poings',
    'pointa',
    'pointe',
    'pointé',
    'points',
    'pointu',
    'poires',
    'poirés',
    'poises',
    'poison',
    'poissa',
    'poisse',
    'poissé',
    'poivra',
    'poivre',
    'poivré',
    'pokers',
    'polars',
    'polder',
    'poliça',
    'police',
    'policé',
    'polies',
    'polios',
    'polira',
    'polkas',
    'pollen',
    'pollua',
    'pollue',
    'pollué',
    'polype',
    'pomélo',
    'pommai',
    'pommas',
    'pommât',
    'pommer',
    'pommes',
    'pommez',
    'pompai',
    'pompas',
    'pompât',
    'pompée',
    'pomper',
    'pompes',
    'pompés',
    'pompez',
    'pompon',
    'ponant',
    'ponçai',
    'ponças',
    'ponçât',
    'poncée',
    'poncer',
    'ponces',
    'poncés',
    'poncez',
    'poncho',
    'poncif',
    'pondes',
    'pondez',
    'pondis',
    'pondit',
    'pondît',
    'pondra',
    'pondre',
    'pondue',
    'pondus',
    'poneys',
    'pongée',
    'pongés',
    'pontai',
    'pontas',
    'pontât',
    'pontée',
    'ponter',
    'pontes',
    'pontés',
    'pontet',
    'pontez',
    'pontil',
    'ponton',
    'popote',
    'populo',
    'poquai',
    'poquas',
    'poquât',
    'poquer',
    'poques',
    'poquet',
    'poquez',
    'porche',
    'porcin',
    'poreux',
    'porion',
    'pornos',
    'porque',
    'portai',
    'portas',
    'portât',
    'portée',
    'porter',
    'portes',
    'portés',
    'portez',
    'portor',
    'portos',
    'posada',
    'posais',
    'posait',
    'posant',
    'posées',
    'posent',
    'posera',
    'poseur',
    'posiez',
    'posons',
    'postai',
    'postal',
    'postas',
    'postât',
    'postée',
    'poster',
    'postes',
    'postés',
    'postez',
    'potage',
    'potard',
    'poteau',
    'potées',
    'potelé',
    'potier',
    'potina',
    'potine',
    'potiné',
    'potins',
    'potion',
    'pouces',
    'poudra',
    'poudre',
    'poudré',
    'pouffa',
    'pouffe',
    'pouffé',
    'poules',
    'poulet',
    'poulie',
    'poulot',
    'poulpe',
    'poumon',
    'poupée',
    'poupes',
    'poupin',
    'poupon',
    'pourra',
    'pourri',
    'pourvu',
    'poussa',
    'pousse',
    'poussé',
    'poutre',
    'poutsa',
    'poutse',
    'poutsé',
    'pouvez',
    'praire',
    'pralin',
    'prames',
    'praxis',
    'préaux',
    'prêcha',
    'prêche',
    'prêché',
    'précis',
    'prédis',
    'prédit',
    'prédît',
    'préfet',
    'prélat',
    'prèles',
    'prêles',
    'prends',
    'prenez',
    'prenne',
    'prénom',
    'pressa',
    'presse',
    'pressé',
    'preste',
    'presto',
    'prêtai',
    'prêtas',
    'prêtât',
    'prêtée',
    'prêter',
    'prêtes',
    'prêtés',
    'prêtez',
    'prêtre',
    'preuve',
    'prévis',
    'prévit',
    'prévît',
    'prévôt',
    'prévue',
    'prévus',
    'priais',
    'priait',
    'priant',
    'priées',
    'prient',
    'priera',
    'prière',
    'prieur',
    'priiez',
    'primai',
    'primas',
    'primat',
    'primât',
    'primée',
    'primer',
    'primes',
    'primés',
    'prîmes',
    'primez',
    'prince',
    'prions',
    'priori',
    'prisai',
    'prisas',
    'prisât',
    'prisée',
    'priser',
    'prises',
    'prisés',
    'prisez',
    'prisme',
    'prison',
    'prisse',
    'prîtes',
    'privai',
    'privas',
    'privât',
    'privée',
    'priver',
    'prives',
    'privés',
    'privez',
    'probes',
    'procès',
    'proche',
    'profès',
    'profil',
    'profit',
    'profus',
    'proies',
    'projet',
    'prolan',
    'prolos',
    'promet',
    'promis',
    'promit',
    'promît',
    'promos',
    'prompt',
    'promus',
    'promut',
    'promût',
    'prônai',
    'prônas',
    'prônât',
    'prônée',
    'prôner',
    'prônes',
    'prônés',
    'prônez',
    'pronom',
    'propos',
    'propre',
    'proses',
    'protée',
    'protes',
    'protêt',
    'proton',
    'proues',
    'prouva',
    'prouve',
    'prouvé',
    'provin',
    'provos',
    'proyer',
    'prudes',
    'pruine',
    'prunes',
    'prunus',
    'prurit',
    'psaume',
    'pseudo',
    'psoque',
    'psyché',
    'psychè',
    'ptôses',
    'ptôsis',
    'puâmes',
    'puante',
    'puants',
    'puasse',
    'puâtes',
    'pubère',
    'pubien',
    'publia',
    'public',
    'publie',
    'publié',
    'puceau',
    'puches',
    'pucier',
    'pudeur',
    'puerai',
    'pueras',
    'puerez',
    'puéril',
    'puffin',
    'puînée',
    'puînés',
    'puions',
    'puisai',
    'puisas',
    'puisât',
    'puisée',
    'puiser',
    'puises',
    'puisés',
    'puisez',
    'puisse',
    'pulpes',
    'pulque',
    'pulsai',
    'pulsar',
    'pulsas',
    'pulsât',
    'pulsée',
    'pulser',
    'pulses',
    'pulsés',
    'pulsez',
    'punchs',
    'punies',
    'punira',
    'pureau',
    'purées',
    'purent',
    'pureté',
    'purgea',
    'purgée',
    'purger',
    'purges',
    'purgés',
    'purgez',
    'purine',
    'purins',
    'purots',
    'pusses',
    'putain',
    'putier',
    'putiet',
    'putois',
    'putsch',
    'puzzle',
    'pygmée',
    'pyjama',
    'pylône',
    'pylore',
    'pyrale',
    'pyrène',
    'pyrite',
    'pyrrol',
    'pythie',
    'python',
    'pyurie',
    'pyxide',
    'quaker',
    'quanta',
    'quarks',
    'quarra',
    'quarre',
    'quarré',
    'quarta',
    'quarte',
    'quarté',
    'quarto',
    'quarts',
    'quartz',
    'quasar',
    'quasis',
    'quater',
    'quatre',
    'quelle',
    'querir',
    'quérir',
    'quêtai',
    'quêtas',
    'quêtât',
    'quêtée',
    'quêter',
    'quêtes',
    'quêtés',
    'quêtez',
    'queues',
    'queuta',
    'queute',
    'queuté',
    'quiche',
    'quidam',
    'quiète',
    'quiets',
    'quille',
    'quinée',
    'quines',
    'quinés',
    'quinoa',
    'quinte',
    'quinto',
    'quinze',
    'quipos',
    'quipou',
    'quipus',
    'quitta',
    'quitte',
    'quitté',
    'quitus',
    'quorum',
    'quotas',
    'quotta',
    'quotte',
    'quotté',
    'rabais',
    'rabane',
    'rabans',
    'rabats',
    'rabbin',
    'rabbis',
    'rabiot',
    'râblai',
    'râblas',
    'râblât',
    'râblée',
    'râbler',
    'râbles',
    'râblés',
    'râblez',
    'rabota',
    'rabote',
    'raboté',
    'rabots',
    'racées',
    'racers',
    'rachat',
    'rachis',
    'racial',
    'racina',
    'racine',
    'raciné',
    'racket',
    'raclai',
    'raclas',
    'raclât',
    'raclée',
    'racler',
    'racles',
    'raclés',
    'raclez',
    'racola',
    'racole',
    'racolé',
    'radais',
    'radait',
    'radant',
    'radars',
    'radeau',
    'radées',
    'radent',
    'radera',
    'radiai',
    'radial',
    'radian',
    'radias',
    'radiât',
    'radiée',
    'radier',
    'radies',
    'radiés',
    'radiez',
    'radina',
    'radine',
    'radiné',
    'radins',
    'radios',
    'radium',
    'radius',
    'radjah',
    'radôme',
    'radons',
    'radota',
    'radote',
    'radoté',
    'radoub',
    'rafale',
    'raffut',
    'rafiot',
    'raflai',
    'raflas',
    'raflât',
    'raflée',
    'rafler',
    'rafles',
    'raflés',
    'raflez',
    'rageai',
    'rageas',
    'rageât',
    'ragent',
    'ragera',
    'rageur',
    'ragiez',
    'raglan',
    'ragota',
    'ragote',
    'ragoté',
    'ragots',
    'ragoût',
    'ragréa',
    'ragrée',
    'ragréé',
    'raguai',
    'raguas',
    'raguât',
    'raguée',
    'raguer',
    'ragues',
    'ragués',
    'raguez',
    'raider',
    'raides',
    'raidie',
    'raidir',
    'raidis',
    'raidit',
    'raidît',
    'raient',
    'raiera',
    'railla',
    'raille',
    'raillé',
    'rainai',
    'rainas',
    'rainât',
    'rainée',
    'rainer',
    'raines',
    'rainés',
    'rainez',
    'raisin',
    'raison',
    'rajahs',
    'rajout',
    'râlais',
    'râlait',
    'râlant',
    'râlent',
    'râlera',
    'râleur',
    'râliez',
    'rallia',
    'rallie',
    'rallié',
    'rallye',
    'râlons',
    'ramage',
    'ramagé',
    'ramais',
    'ramait',
    'ramant',
    'ramdam',
    'rameau',
    'ramées',
    'ramena',
    'ramené',
    'ramène',
    'rament',
    'ramera',
    'rameur',
    'rameux',
    'ramier',
    'ramies',
    'ramiez',
    'ramona',
    'ramone',
    'ramoné',
    'ramons',
    'rampai',
    'rampas',
    'rampât',
    'ramper',
    'rampes',
    'rampez',
    'ramure',
    'rances',
    'ranche',
    'ranchs',
    'rancie',
    'rancio',
    'rancir',
    'rancis',
    'rancit',
    'rancît',
    'rançon',
    'rangea',
    'rangée',
    'ranger',
    'ranges',
    'rangés',
    'rangez',
    'ranima',
    'ranime',
    'ranimé',
    'raouts',
    'rapace',
    'râpage',
    'râpais',
    'râpait',
    'râpant',
    'râpées',
    'râpent',
    'râpera',
    'râpeux',
    'raphia',
    'rapiat',
    'rapide',
    'râpiez',
    'rapina',
    'rapine',
    'rapiné',
    'rapins',
    'râpons',
    'rappel',
    'râpure',
    'raquai',
    'raquas',
    'raquât',
    'raquée',
    'raquer',
    'raques',
    'raqués',
    'raquez',
    'rareté',
    'rasade',
    'rasage',
    'rasais',
    'rasait',
    'rasant',
    'rasées',
    'rasent',
    'rasera',
    'raseur',
    'rashes',
    'rasiez',
    'rasoir',
    'rasons',
    'rassis',
    'rassit',
    'rassît',
    'rastas',
    'ratage',
    'ratais',
    'ratait',
    'ratant',
    'râteau',
    'ratées',
    'râtela',
    'râtelé',
    'ratels',
    'ratent',
    'ratera',
    'ratier',
    'ratiez',
    'ratina',
    'ratine',
    'ratiné',
    'ration',
    'ratios',
    'ratons',
    'ratura',
    'rature',
    'raturé',
    'rauque',
    'ravage',
    'ravagé',
    'ravala',
    'ravale',
    'ravalé',
    'ravier',
    'ravies',
    'ravili',
    'ravina',
    'ravine',
    'raviné',
    'ravins',
    'ravira',
    'ravisa',
    'ravise',
    'ravisé',
    'raviva',
    'ravive',
    'ravivé',
    'ravoir',
    'rayage',
    'rayais',
    'rayait',
    'rayant',
    'rayées',
    'rayent',
    'rayera',
    'rayère',
    'rayiez',
    'rayons',
    'rayure',
    'razzia',
    'razzie',
    'razzié',
    'réagir',
    'réagis',
    'réagit',
    'réagît',
    'réales',
    'réâmes',
    'réarma',
    'réarme',
    'réarmé',
    'réasse',
    'réâtes',
    'rebabs',
    'rebâti',
    'rebats',
    'rebecs',
    'rebond',
    'rebord',
    'rebuta',
    'rebute',
    'rebuté',
    'rebuts',
    'recala',
    'recale',
    'recalé',
    'recasa',
    'recase',
    'recasé',
    'recéda',
    'recédé',
    'recède',
    'recela',
    'recéla',
    'recelé',
    'recélé',
    'recèle',
    'recels',
    'récent',
    'recepa',
    'recépa',
    'recepé',
    'recépé',
    'recèpe',
    'rêches',
    'récifs',
    'récita',
    'récite',
    'récité',
    'récits',
    'reclus',
    'recoin',
    'reçois',
    'reçoit',
    'recola',
    'recole',
    'recolé',
    'record',
    'recors',
    'recoud',
    'recréa',
    'récréa',
    'recrée',
    'recréé',
    'récrée',
    'récréé',
    'récria',
    'récrie',
    'récrié',
    'récris',
    'récrit',
    'recrue',
    'recrus',
    'recrut',
    'recrût',
    'rectal',
    'rectos',
    'rectum',
    'reçues',
    'recuis',
    'recuit',
    'recula',
    'recule',
    'reculé',
    'récura',
    'récure',
    'récuré',
    'récusa',
    'récuse',
    'récusé',
    'redans',
    'redent',
    'rédies',
    'rédige',
    'rédigé',
    'rédima',
    'rédime',
    'rédimé',
    'redira',
    'redire',
    'redise',
    'redite',
    'redits',
    'redois',
    'redoit',
    'redora',
    'redore',
    'redoré',
    'redoux',
    'redues',
    'réduis',
    'réduit',
    'réduve',
    'réélis',
    'réélit',
    'réelle',
    'réélue',
    'réélus',
    'réélut',
    'réélût',
    'réerai',
    'réeras',
    'réerez',
    'refais',
    'refait',
    'refend',
    'refera',
    'référa',
    'référé',
    'réfère',
    'refila',
    'refile',
    'refilé',
    'reflet',
    'reflex',
    'reflua',
    'reflue',
    'reflué',
    'reflux',
    'refond',
    'refont',
    'refuge',
    'refusa',
    'refuse',
    'refusé',
    'réfuta',
    'réfute',
    'réfuté',
    'regain',
    'régala',
    'régale',
    'régalé',
    'régals',
    'regard',
    'régata',
    'régate',
    'régaté',
    'régent',
    'reggae',
    'régies',
    'régime',
    'région',
    'régira',
    'réglai',
    'réglas',
    'réglât',
    'réglée',
    'régler',
    'réglés',
    'règles',
    'réglet',
    'réglez',
    'régnai',
    'régnas',
    'régnât',
    'régner',
    'règnes',
    'régnez',
    'regrat',
    'regréa',
    'regrée',
    'regréé',
    'regret',
    'régule',
    'rehaut',
    'réifia',
    'réifie',
    'réifié',
    'reines',
    'réions',
    'reître',
    'rejeta',
    'rejeté',
    'rejets',
    'rejoua',
    'rejoue',
    'rejoué',
    'réjoui',
    'rejuge',
    'rejugé',
    'relaie',
    'relais',
    'relaps',
    'relata',
    'relate',
    'relaté',
    'relava',
    'relave',
    'relavé',
    'relaxa',
    'relaxe',
    'relaxé',
    'relaya',
    'relaye',
    'relayé',
    'relent',
    'releva',
    'relevé',
    'relève',
    'reliai',
    'relias',
    'reliât',
    'reliée',
    'relief',
    'relier',
    'relies',
    'reliés',
    'reliez',
    'relira',
    'relire',
    'relise',
    'reloge',
    'relogé',
    'reloua',
    'reloue',
    'reloué',
    'relues',
    'reluis',
    'reluit',
    'remake',
    'remède',
    'réméré',
    'remets',
    'rémige',
    'remisa',
    'remise',
    'remisé',
    'rémora',
    'remord',
    'remoud',
    'remous',
    'rempli',
    'remuai',
    'remuas',
    'remuât',
    'remuée',
    'remuer',
    'remues',
    'remués',
    'remuez',
    'renais',
    'renaît',
    'rénale',
    'renard',
    'rénaux',
    'rendes',
    'rendez',
    'rendis',
    'rendit',
    'rendît',
    'rendra',
    'rendre',
    'rendue',
    'rendus',
    'renées',
    'renfla',
    'renfle',
    'renflé',
    'reniai',
    'renias',
    'reniât',
    'reniée',
    'renier',
    'renies',
    'reniés',
    'reniez',
    'rennes',
    'renoms',
    'renoua',
    'renoue',
    'renoué',
    'rénova',
    'rénove',
    'rénové',
    'rentai',
    'rentas',
    'rentât',
    'rentée',
    'renter',
    'rentes',
    'rentés',
    'rentez',
    'rentra',
    'rentre',
    'rentré',
    'renvia',
    'renvie',
    'renvié',
    'renvoi',
    'repaie',
    'repais',
    'repaît',
    'répand',
    'répara',
    'répare',
    'réparé',
    'repars',
    'repart',
    'reparu',
    'repava',
    'repave',
    'repavé',
    'repaya',
    'repaye',
    'repayé',
    'repend',
    'repens',
    'repent',
    'repéra',
    'reperd',
    'repéré',
    'repère',
    'répéta',
    'répété',
    'répète',
    'répits',
    'replet',
    'replia',
    'replie',
    'replié',
    'replis',
    'replut',
    'replût',
    'repoli',
    'répond',
    'report',
    'reposa',
    'repose',
    'reposé',
    'repris',
    'reprit',
    'reprît',
    'repues',
    'réputa',
    'répute',
    'réputé',
    'requin',
    'requis',
    'requit',
    'requît',
    'resala',
    'resale',
    'resalé',
    'resali',
    'réseau',
    'réséda',
    'résida',
    'réside',
    'résidé',
    'résidu',
    'résina',
    'résine',
    'résiné',
    'résolu',
    'résous',
    'résout',
    'ressac',
    'ressua',
    'ressue',
    'ressué',
    'ressui',
    'restai',
    'restas',
    'restât',
    'restée',
    'rester',
    'restes',
    'restés',
    'restez',
    'résuma',
    'résume',
    'résumé',
    'rétama',
    'rétame',
    'rétamé',
    'retapa',
    'retape',
    'retapé',
    'retard',
    'retâta',
    'retâte',
    'retâté',
    'retend',
    'retenu',
    'rétifs',
    'rétine',
    'retins',
    'retint',
    'retînt',
    'retira',
    'retire',
    'retiré',
    'rétive',
    'retond',
    'retord',
    'retors',
    'retour',
    'rétros',
    'réunie',
    'réunir',
    'réunis',
    'réunit',
    'réunît',
    'réussi',
    'rêvais',
    'rêvait',
    'revalu',
    'rêvant',
    'revaut',
    'revaux',
    'rêvées',
    'réveil',
    'révéla',
    'révélé',
    'révèle',
    'revend',
    'rêvent',
    'revenu',
    'révéra',
    'rêvera',
    'révéré',
    'révère',
    'revers',
    'revête',
    'revêts',
    'revêtu',
    'rêveur',
    'reveut',
    'reveux',
    'rêviez',
    'revins',
    'revint',
    'revînt',
    'revira',
    'revire',
    'reviré',
    'révisa',
    'révise',
    'révisé',
    'revive',
    'revoie',
    'revoir',
    'revois',
    'revoit',
    'revola',
    'revole',
    'revolé',
    'révolu',
    'rêvons',
    'revota',
    'revote',
    'revoté',
    'revues',
    'rhénan',
    'rhésus',
    'Rhodia',
    'rhodié',
    'rhombe',
    'Rhovyl',
    'rhumai',
    'rhumas',
    'rhumât',
    'rhumbs',
    'rhumée',
    'rhumer',
    'rhumes',
    'rhumés',
    'rhumez',
    'riante',
    'riants',
    'ribaud',
    'riblai',
    'riblas',
    'riblât',
    'riblée',
    'ribler',
    'ribles',
    'riblés',
    'riblez',
    'riblon',
    'ribose',
    'ribote',
    'ricain',
    'ricana',
    'ricane',
    'ricané',
    'riccie',
    'riches',
    'riciné',
    'rictus',
    'ridage',
    'ridais',
    'ridait',
    'ridant',
    'rideau',
    'ridées',
    'rident',
    'ridera',
    'ridiez',
    'ridoir',
    'ridons',
    'ridule',
    'rieurs',
    'rieuse',
    'riffle',
    'rififi',
    'riflai',
    'riflas',
    'riflât',
    'riflée',
    'rifler',
    'rifles',
    'riflés',
    'riflez',
    'rigide',
    'rigola',
    'rigole',
    'rigolé',
    'rigolo',
    'riions',
    'rikiki',
    'Rilsan',
    'rimais',
    'rimait',
    'rimant',
    'rimaye',
    'rimées',
    'riment',
    'rimera',
    'rimeur',
    'rimiez',
    'Rimmel',
    'rimons',
    'rinçai',
    'rinças',
    'rinçât',
    'rincée',
    'rincer',
    'rinces',
    'rincés',
    'rincez',
    'ripage',
    'ripais',
    'ripait',
    'ripant',
    'ripées',
    'ripent',
    'ripera',
    'ripiez',
    'ripons',
    'ripper',
    'rirais',
    'rirait',
    'rirent',
    'ririez',
    'rirons',
    'riront',
    'risban',
    'risées',
    'risqua',
    'risque',
    'risqué',
    'risses',
    'ritals',
    'rituel',
    'rivage',
    'rivais',
    'rivait',
    'rivale',
    'rivant',
    'rivaux',
    'rivées',
    'rivent',
    'rivera',
    'riveta',
    'riveté',
    'rivets',
    'riveur',
    'riviez',
    'rivoir',
    'rivons',
    'rivure',
    'robage',
    'robais',
    'robait',
    'robant',
    'robées',
    'robent',
    'robera',
    'robert',
    'robiez',
    'robins',
    'robons',
    'robots',
    'rocade',
    'rocher',
    'roches',
    'rochet',
    'rocker',
    'rocket',
    'rococo',
    'rocoua',
    'rocoue',
    'rocoué',
    'rocous',
    'rocqua',
    'rocque',
    'rocqué',
    'rodage',
    'rodais',
    'rôdais',
    'rodait',
    'rôdait',
    'rodant',
    'rôdant',
    'rodées',
    'rodent',
    'rôdent',
    'rodéos',
    'rodera',
    'rôdera',
    'rôdeur',
    'rodiez',
    'rôdiez',
    'rodoir',
    'rodons',
    'rôdons',
    'rognai',
    'rognas',
    'rognât',
    'rognée',
    'rogner',
    'rognes',
    'rognés',
    'rognez',
    'rognon',
    'roguée',
    'rogues',
    'rogués',
    'rohart',
    'roides',
    'roidie',
    'roidir',
    'roidis',
    'roidit',
    'roidît',
    'romain',
    'romand',
    'romane',
    'romani',
    'romano',
    'romans',
    'rompes',
    'rompez',
    'rompis',
    'rompit',
    'rompît',
    'rompra',
    'rompre',
    'rompue',
    'rompus',
    'ronces',
    'rondes',
    'rondie',
    'rondin',
    'rondir',
    'rondis',
    'rondit',
    'rondît',
    'rondos',
    'Ronéos',
    'ronfla',
    'ronfle',
    'ronflé',
    'rongea',
    'rongée',
    'ronger',
    'ronges',
    'rongés',
    'rongez',
    'ronron',
    'roquai',
    'roquas',
    'roquât',
    'roquer',
    'roques',
    'roquet',
    'roquez',
    'rosace',
    'rosacé',
    'rosage',
    'rosais',
    'rosait',
    'rosant',
    'rosbif',
    'roseau',
    'rosées',
    'rosent',
    'rosera',
    'rosier',
    'rosies',
    'rosiez',
    'rosira',
    'rosons',
    'rossai',
    'rossas',
    'rossât',
    'rossée',
    'rosser',
    'rosses',
    'rossés',
    'rossez',
    'rostre',
    'rotacé',
    'rotais',
    'rotait',
    'rotang',
    'rotant',
    'rotary',
    'rotent',
    'rotera',
    'rôties',
    'rotiez',
    'rotins',
    'rôtira',
    'rotons',
    'rotors',
    'rotule',
    'roture',
    'rouage',
    'rouais',
    'rouait',
    'rouans',
    'rouant',
    'rouble',
    'rouchi',
    'rouées',
    'rouent',
    'rouera',
    'rouets',
    'rouges',
    'rouget',
    'rougie',
    'rougir',
    'rougis',
    'rougit',
    'rougît',
    'rouies',
    'rouiez',
    'rouira',
    'roulai',
    'roulas',
    'roulât',
    'roulée',
    'rouler',
    'roules',
    'roulés',
    'roulez',
    'roulis',
    'roumis',
    'rounds',
    'rouons',
    'roupie',
    'rousse',
    'roussi',
    'rousti',
    'routai',
    'routas',
    'routât',
    'routée',
    'router',
    'routes',
    'routés',
    'routez',
    'rouvre',
    'rouvru',
    'rowing',
    'royale',
    'royaux',
    'ruades',
    'ruâmes',
    'ruasse',
    'ruâtes',
    'rubana',
    'rubane',
    'rubané',
    'rubans',
    'rubato',
    'ruchai',
    'ruchas',
    'ruchât',
    'ruchée',
    'rucher',
    'ruches',
    'ruchés',
    'ruchez',
    'rudoie',
    'rudoya',
    'rudoyé',
    'ruelle',
    'ruerai',
    'rueras',
    'ruerez',
    'rufian',
    'rugbys',
    'rugira',
    'ruilai',
    'ruilas',
    'ruilât',
    'ruilée',
    'ruiler',
    'ruiles',
    'ruilés',
    'ruilez',
    'ruinai',
    'ruinas',
    'ruinât',
    'ruinée',
    'ruiner',
    'ruines',
    'ruinés',
    'ruinez',
    'ruions',
    'rumbas',
    'rumens',
    'rumeur',
    'rumina',
    'rumine',
    'ruminé',
    'rupina',
    'rupine',
    'rupiné',
    'rupins',
    'rurale',
    'ruraux',
    'rusais',
    'rusait',
    'rusant',
    'rusées',
    'rusent',
    'rusera',
    'rushes',
    'rusiez',
    'rusons',
    'russes',
    'rustre',
    'rutila',
    'rutile',
    'rutilé',
    'rythma',
    'rythme',
    'rythmé',
    'sabbat',
    'sabéen',
    'sabine',
    'sabirs',
    'sablai',
    'sablas',
    'sablât',
    'sablée',
    'sabler',
    'sables',
    'sablés',
    'sablez',
    'sablon',
    'sabord',
    'sabota',
    'sabote',
    'saboté',
    'sabots',
    'sabras',
    'sabrée',
    'sabrer',
    'sabres',
    'sabrés',
    'sabrez',
    'sachem',
    'saches',
    'sachet',
    'sachez',
    'sacome',
    'sacqua',
    'sacque',
    'sacqué',
    'sacrai',
    'sacral',
    'sacras',
    'sacrât',
    'sacrée',
    'sacrer',
    'sacres',
    'sacrés',
    'sacret',
    'sacrez',
    'sacrum',
    'safari',
    'safran',
    'safres',
    'sagace',
    'sagaie',
    'sagous',
    'sagums',
    'sahels',
    'saïgas',
    'saigna',
    'saigne',
    'saigné',
    'saille',
    'sailli',
    'saines',
    'sainte',
    'saints',
    'saisie',
    'saisir',
    'saisis',
    'saisit',
    'saisît',
    'saison',
    'saïtes',
    'sajous',
    'salace',
    'salade',
    'salage',
    'salais',
    'salait',
    'salami',
    'salant',
    'salaud',
    'salées',
    'salent',
    'saleps',
    'salera',
    'saleté',
    'saleur',
    'salien',
    'salies',
    'saliez',
    'saline',
    'salins',
    'salira',
    'saliva',
    'salive',
    'salivé',
    'salles',
    'salmis',
    'saloir',
    'salols',
    'salons',
    'saloon',
    'salopa',
    'salope',
    'salopé',
    'salops',
    'salsas',
    'salses',
    'saluai',
    'saluas',
    'saluât',
    'saluée',
    'saluer',
    'salues',
    'salués',
    'saluez',
    'salure',
    'saluts',
    'salves',
    'samare',
    'sambas',
    'samedi',
    'samits',
    'sampan',
    'sampis',
    'sampot',
    'Sandow',
    'sandre',
    'sangla',
    'sangle',
    'sanglé',
    'sanies',
    'santal',
    'santés',
    'santon',
    'sanves',
    'sanzas',
    'saoula',
    'saoule',
    'saoulé',
    'saouls',
    'sapais',
    'sapait',
    'sapant',
    'sapées',
    'sapent',
    'sapera',
    'sapeur',
    'saphir',
    'sapide',
    'sapiez',
    'sapine',
    'sapins',
    'sapons',
    'saquai',
    'saquas',
    'saquât',
    'saquée',
    'saquer',
    'saques',
    'saqués',
    'saquez',
    'sarcla',
    'sarcle',
    'sarclé',
    'sardes',
    'sarong',
    'sarrau',
    'sassai',
    'sassas',
    'sassât',
    'sassée',
    'sasser',
    'sasses',
    'sassés',
    'sassez',
    'satana',
    'satane',
    'satané',
    'satina',
    'satine',
    'satiné',
    'satins',
    'satire',
    'satura',
    'sature',
    'saturé',
    'satyre',
    'sauçai',
    'sauças',
    'sauçât',
    'saucée',
    'saucer',
    'sauces',
    'saucés',
    'saucez',
    'sauges',
    'saulée',
    'saules',
    'saumon',
    'saunai',
    'saunas',
    'saunât',
    'sauner',
    'saunes',
    'saunez',
    'saurai',
    'sauras',
    'saurât',
    'saurée',
    'saurer',
    'saures',
    'saurés',
    'saurez',
    'saurie',
    'saurin',
    'saurir',
    'sauris',
    'saurit',
    'saurît',
    'sautai',
    'sautas',
    'sautât',
    'sautée',
    'sauter',
    'sautes',
    'sautés',
    'sautez',
    'sauvai',
    'sauvas',
    'sauvât',
    'sauvée',
    'sauver',
    'sauves',
    'sauvés',
    'sauvez',
    'savais',
    'savait',
    'savane',
    'savant',
    'savart',
    'savate',
    'savent',
    'saveur',
    'saviez',
    'savoir',
    'savons',
    'saxons',
    'sayons',
    'sbires',
    'scalde',
    'scalpa',
    'scalpe',
    'scalpé',
    'scalps',
    'scampi',
    'scanda',
    'scande',
    'scandé',
    'scares',
    'sceaux',
    'scella',
    'scelle',
    'scellé',
    'scènes',
    'schahs',
    'schako',
    'scheik',
    'schéma',
    'schéol',
    'schleu',
    'schupo',
    'schuss',
    'sciage',
    'sciais',
    'sciait',
    'sciant',
    'sciées',
    'sciène',
    'scient',
    'sciera',
    'scieur',
    'sciiez',
    'scille',
    'scinda',
    'scinde',
    'scindé',
    'scions',
    'scirpe',
    'sciure',
    'scolex',
    'scolie',
    'sconse',
    'scoops',
    'scopie',
    'scores',
    'scorie',
    'Scotch',
    'scotie',
    'scoute',
    'scouts',
    'scribe',
    'script',
    'scruta',
    'scrute',
    'scruté',
    'sculls',
    'scutum',
    'scythe',
    'séance',
    'séante',
    'séants',
    'sébacé',
    'sébile',
    'sebkas',
    'sebkha',
    'sébums',
    'sécant',
    'séchai',
    'séchas',
    'séchât',
    'séchée',
    'sécher',
    'séchés',
    'sèches',
    'séchez',
    'second',
    'secoua',
    'secoue',
    'secoué',
    'secret',
    'sectes',
    'séduis',
    'séduit',
    'ségala',
    'seguia',
    'seiche',
    'séides',
    'seigle',
    'seille',
    'seimes',
    'seines',
    'seings',
    'séisme',
    'séjour',
    'sélect',
    'sellai',
    'sellas',
    'sellât',
    'sellée',
    'seller',
    'selles',
    'sellés',
    'sellez',
    'selves',
    'semais',
    'semait',
    'semant',
    'sembla',
    'semble',
    'semblé',
    'semées',
    'sèment',
    'sèmera',
    'semeur',
    'semiez',
    'sémite',
    'semoir',
    'semons',
    'semple',
    'sénats',
    'senaux',
    'sénevé',
    'sénile',
    'senior',
    'sennes',
    'sensée',
    'sensés',
    'sentes',
    'sentez',
    'sentie',
    'sentir',
    'sentis',
    'sentit',
    'sentît',
    'sépale',
    'sépara',
    'sépare',
    'séparé',
    'sépias',
    'septum',
    'sequin',
    'séracs',
    'sérail',
    'serais',
    'serait',
    'serbes',
    'serein',
    'séreux',
    'serges',
    'sergés',
    'sériai',
    'sérias',
    'sériât',
    'sériée',
    'sériel',
    'sérier',
    'séries',
    'sériés',
    'seriez',
    'sériez',
    'serina',
    'serine',
    'seriné',
    'sérine',
    'serins',
    'sermon',
    'serons',
    'seront',
    'serpes',
    'serrai',
    'serran',
    'serras',
    'serrât',
    'serrée',
    'serrer',
    'serres',
    'serrés',
    'serrez',
    'sertes',
    'sertie',
    'sertir',
    'sertis',
    'sertit',
    'sertît',
    'sérums',
    'serval',
    'serves',
    'servez',
    'servie',
    'servir',
    'servis',
    'servit',
    'servît',
    'sésame',
    'sétacé',
    'setier',
    'sétons',
    'setter',
    'seuils',
    'seules',
    'seulet',
    'sévère',
    'sévira',
    'sevrai',
    'sevras',
    'sevrât',
    'sevrée',
    'sevrer',
    'sevres',
    'sevrés',
    'sèvres',
    'sevrez',
    'sextes',
    'sexuée',
    'sexuel',
    'sexués',
    'seyait',
    'seyant',
    'shaker',
    'shakos',
    'shérif',
    'sherry',
    'shimmy',
    'shintô',
    'shogun',
    'shoota',
    'shoote',
    'shooté',
    'shoots',
    'shorts',
    'shunta',
    'shunte',
    'shunté',
    'shunts',
    'sialis',
    'sicles',
    'sidéra',
    'sidéré',
    'sidère',
    'siècle',
    'siéent',
    'siégea',
    'siéger',
    'sièges',
    'siégez',
    'sienne',
    'sierra',
    'sieste',
    'sieurs',
    'siffla',
    'siffle',
    'sifflé',
    'sigles',
    'signai',
    'signal',
    'signas',
    'signât',
    'signée',
    'signer',
    'signes',
    'signés',
    'signet',
    'signez',
    'silène',
    'silice',
    'sillet',
    'sillon',
    'silphe',
    'silure',
    'silves',
    'simien',
    'simili',
    'simoun',
    'simple',
    'simula',
    'simule',
    'simulé',
    'singea',
    'singée',
    'singer',
    'singes',
    'singés',
    'singez',
    'single',
    'sinisa',
    'sinise',
    'sinisé',
    'sinité',
    'siphon',
    'sirdar',
    'sirène',
    'sirlis',
    'siroco',
    'sirops',
    'sirota',
    'sirote',
    'siroté',
    'sisals',
    'sismal',
    'sistre',
    'sitars',
    'sit-in',
    'situai',
    'situas',
    'situât',
    'située',
    'situer',
    'situes',
    'situés',
    'situez',
    'sixain',
    'sixtes',
    'sizain',
    'skates',
    'sketch',
    'skiais',
    'skiait',
    'skiant',
    'skient',
    'skiera',
    'skieur',
    'skiffs',
    'skiiez',
    'skions',
    'skunks',
    'slalom',
    'slangs',
    'slaves',
    'slavon',
    'slogan',
    'sloops',
    'smalas',
    'smalts',
    'smasha',
    'smashe',
    'smashé',
    'smashs',
    'smilax',
    'smilla',
    'smille',
    'smillé',
    'smocks',
    'smolts',
    'smurfs',
    'snacks',
    'sniffa',
    'sniffe',
    'sniffé',
    'snobai',
    'snobas',
    'snobât',
    'snobée',
    'snober',
    'snobes',
    'snobés',
    'snobez',
    'sobres',
    'social',
    'socles',
    'socque',
    'sodées',
    'sodium',
    'sodoku',
    'soeurs',
    'soient',
    'soigna',
    'soigne',
    'soigné',
    'soirée',
    'soldai',
    'soldas',
    'soldat',
    'soldât',
    'soldée',
    'solder',
    'soldes',
    'soldés',
    'soldez',
    'soleil',
    'solens',
    'solfia',
    'solfie',
    'solfié',
    'solide',
    'solins',
    'solive',
    'soluté',
    'sombra',
    'sombre',
    'sombré',
    'somite',
    'sommai',
    'sommas',
    'sommât',
    'sommée',
    'sommer',
    'sommes',
    'sommés',
    'sommet',
    'sommez',
    'sonars',
    'sonate',
    'sondai',
    'sondas',
    'sondât',
    'sondée',
    'sonder',
    'sondes',
    'sondés',
    'sondez',
    'songea',
    'songer',
    'songes',
    'songez',
    'sonnai',
    'sonnas',
    'sonnât',
    'sonnée',
    'sonner',
    'sonnes',
    'sonnés',
    'sonnet',
    'sonnez',
    'sonore',
    'sorbes',
    'sorbet',
    'sorgho',
    'sorgua',
    'sorgue',
    'sorgué',
    'sortes',
    'sortez',
    'sortie',
    'sortir',
    'sortis',
    'sortit',
    'sortît',
    'sosies',
    'sotchs',
    'soties',
    'sottes',
    'souche',
    'soucia',
    'soucie',
    'soucié',
    'soucis',
    'soudai',
    'soudan',
    'soudas',
    'soudât',
    'soudée',
    'souder',
    'soudes',
    'soudés',
    'soudez',
    'soufra',
    'soufre',
    'soufré',
    'soûlai',
    'soûlas',
    'soûlât',
    'soûlée',
    'soûler',
    'soûles',
    'soûlés',
    'soûlez',
    'soûlot',
    'soulte',
    'soumet',
    'soumis',
    'soumit',
    'soumît',
    'soupai',
    'soupas',
    'soupât',
    'souper',
    'soupes',
    'soupez',
    'soupir',
    'souple',
    'souqua',
    'souque',
    'souqué',
    'source',
    'sourde',
    'sourds',
    'sourie',
    'souris',
    'sourit',
    'sourît',
    'soutes',
    'soviet',
    'soyers',
    'soyeux',
    'soyons',
    'spahis',
    'spalax',
    'sparte',
    'sparts',
    'spasme',
    'spathe',
    'spaths',
    'speech',
    'speiss',
    'sperme',
    'sphère',
    'sphinx',
    'spider',
    'spinal',
    'spiral',
    'spirée',
    'spires',
    'spitta',
    'spitte',
    'spitté',
    'spleen',
    'spolia',
    'spolie',
    'spolié',
    'spores',
    'sports',
    'sprats',
    'sprays',
    'sprint',
    'sprues',
    'squale',
    'squame',
    'square',
    'squash',
    'squats',
    'squaws',
    'stable',
    'stades',
    'stadia',
    'staffa',
    'staffe',
    'staffé',
    'staffs',
    'stages',
    'stagna',
    'stagne',
    'stagné',
    'stalag',
    'stalle',
    'stance',
    'stands',
    'starie',
    'stases',
    'statif',
    'stator',
    'statua',
    'statue',
    'statué',
    'statut',
    'stawug',
    'stayer',
    'steaks',
    'stèles',
    'stemms',
    'sténos',
    'steppe',
    'stérai',
    'stéras',
    'stérât',
    'stérée',
    'stéréo',
    'stérer',
    'stérés',
    'stères',
    'stérez',
    'sterne',
    'stérol',
    'sthène',
    'stibié',
    'sticks',
    'stipes',
    'stiple',
    'stocka',
    'stocke',
    'stocké',
    'stocks',
    'stoker',
    'stolon',
    'stoppa',
    'stoppe',
    'stoppé',
    'storax',
    'stores',
    'stouts',
    'strass',
    'strate',
    'stress',
    'striai',
    'strias',
    'striât',
    'strict',
    'striée',
    'strier',
    'stries',
    'striés',
    'striez',
    'strige',
    'string',
    'stroma',
    'strume',
    'stryge',
    'studio',
    'stupre',
    'stuqua',
    'stuque',
    'stuqué',
    'stylai',
    'stylas',
    'stylât',
    'stylée',
    'styler',
    'styles',
    'stylés',
    'stylet',
    'stylez',
    'stylos',
    'styrax',
    'suages',
    'suaire',
    'suâmes',
    'suante',
    'suants',
    'suasse',
    'suâtes',
    'suaves',
    'subers',
    'subies',
    'subira',
    'subite',
    'subito',
    'subits',
    'subtil',
    'subulé',
    'suçais',
    'suçait',
    'suçant',
    'succès',
    'succin',
    'sucées',
    'sucent',
    'sucera',
    'suceur',
    'suciez',
    'suçoir',
    'suçons',
    'suçota',
    'suçote',
    'suçoté',
    'sucrai',
    'sucras',
    'sucrât',
    'sucrée',
    'sucrer',
    'sucres',
    'sucrés',
    'sucrez',
    'sucrin',
    'suédée',
    'suédés',
    'suèdes',
    'suerai',
    'sueras',
    'suerez',
    'suette',
    'sueurs',
    'suffis',
    'suffit',
    'suffît',
    'suifai',
    'suifas',
    'suifât',
    'suifée',
    'suifer',
    'suifes',
    'suifés',
    'suifez',
    'suiffa',
    'suiffe',
    'suiffé',
    'suinta',
    'suinte',
    'suinté',
    'suints',
    'suions',
    'suisse',
    'suitée',
    'suites',
    'suives',
    'suivez',
    'suivie',
    'suivis',
    'suivit',
    'suivît',
    'suivra',
    'suivre',
    'sujets',
    'sulkys',
    'sultan',
    'sumacs',
    'summum',
    'sunnas',
    'supère',
    'supins',
    'suppôt',
    'surahs',
    'surate',
    'surdos',
    'sureau',
    'surent',
    'sûreté',
    'surets',
    'surfai',
    'surfas',
    'surfât',
    'surfer',
    'surfes',
    'surfez',
    'surfil',
    'surfin',
    'surfis',
    'surfit',
    'surfît',
    'surgir',
    'surgis',
    'surgit',
    'surgît',
    'surina',
    'surine',
    'suriné',
    'surins',
    'surira',
    'surjet',
    'surlia',
    'surlie',
    'surlié',
    'surmoi',
    'surnom',
    'suroît',
    'sursis',
    'sursit',
    'sursît',
    'survie',
    'survis',
    'survit',
    'survol',
    'susdit',
    'sushis',
    'susses',
    'sutras',
    'sutura',
    'suture',
    'suturé',
    'svelte',
    'swings',
    'sylphe',
    'sylves',
    'syndic',
    'synode',
    'syrien',
    'syrinx',
    'syrphe',
    'tabacs',
    'tabard',
    'tabars',
    'tablai',
    'tablar',
    'tablas',
    'tablât',
    'tablée',
    'tabler',
    'tables',
    'tablés',
    'tablez',
    'tabors',
    'taboue',
    'taboué',
    'tabous',
    'tabula',
    'tabule',
    'tabulé',
    'taccas',
    'tachai',
    'tâchai',
    'tachas',
    'tâchas',
    'tachât',
    'tâchât',
    'tachée',
    'tâchée',
    'tacher',
    'tâcher',
    'taches',
    'tachés',
    'tâches',
    'tâchés',
    'tachez',
    'tâchez',
    'tacite',
    'tacons',
    'tacots',
    'taenia',
    'tafias',
    'tagète',
    'taïaut',
    'taïgas',
    'tailla',
    'taille',
    'taillé',
    'tairai',
    'tairas',
    'tairez',
    'taises',
    'taisez',
    'tajine',
    'talais',
    'talait',
    'talant',
    'talées',
    'talent',
    'talera',
    'taleth',
    'taliez',
    'talion',
    'tallai',
    'tallas',
    'tallât',
    'taller',
    'talles',
    'tallez',
    'talmud',
    'talons',
    'talqua',
    'talque',
    'talqué',
    'talure',
    'talweg',
    'tamias',
    'tamier',
    'tamils',
    'tamisa',
    'tamise',
    'tamisé',
    'tamoul',
    'tampon',
    'tançai',
    'tanças',
    'tançât',
    'tancée',
    'tancer',
    'tances',
    'tancés',
    'tancez',
    'tanche',
    'tandem',
    'tandis',
    'tangon',
    'tangos',
    'tangua',
    'tangue',
    'tangué',
    'tanins',
    'tanisa',
    'tanise',
    'tanisé',
    'tanker',
    'tannai',
    'tannas',
    'tannât',
    'tannée',
    'tanner',
    'tannes',
    'tannés',
    'tannez',
    'tannin',
    'tanrec',
    'tantes',
    'tantôt',
    'tapage',
    'tapagé',
    'tapais',
    'tapait',
    'tapant',
    'tapées',
    'tapent',
    'tapera',
    'tapeur',
    'tapies',
    'tapiez',
    'tapina',
    'tapine',
    'tapiné',
    'tapins',
    'tapira',
    'tapons',
    'tapota',
    'tapote',
    'tapoté',
    'taquai',
    'taquas',
    'taquât',
    'taquée',
    'taquer',
    'taques',
    'taqués',
    'taquet',
    'taquez',
    'taquin',
    'tarage',
    'tarais',
    'tarait',
    'tarama',
    'tarant',
    'tarare',
    'taraud',
    'tardai',
    'tardas',
    'tardât',
    'tarder',
    'tardes',
    'tardez',
    'tardif',
    'tarées',
    'tarent',
    'tarera',
    'tarets',
    'targes',
    'targua',
    'targue',
    'targué',
    'targui',
    'taries',
    'tariez',
    'tarifa',
    'tarife',
    'tarifé',
    'tarifs',
    'tarins',
    'tarira',
    'tarons',
    'taroté',
    'tarots',
    'tarpan',
    'tarpon',
    'tarsal',
    'tarses',
    'tartan',
    'tartes',
    'tartir',
    'tartis',
    'tartit',
    'tartît',
    'tartre',
    'tarzan',
    'tassai',
    'tassas',
    'tassât',
    'tassée',
    'tasser',
    'tasses',
    'tassés',
    'tassez',
    'tâtais',
    'tâtait',
    'tatami',
    'tatane',
    'tâtant',
    'tatare',
    'tâtées',
    'tâtent',
    'tâtera',
    'tâteur',
    'tâtiez',
    'tâtons',
    'tatoua',
    'tatoue',
    'tatoué',
    'tatous',
    'taudis',
    'taules',
    'taupée',
    'taupes',
    'taupés',
    'taupin',
    'taures',
    'taurin',
    'tauzin',
    'tavela',
    'tavelé',
    'taxais',
    'taxait',
    'taxant',
    'taxées',
    'taxent',
    'taxera',
    'taxies',
    'taxiez',
    'taxons',
    'teckel',
    'Téflon',
    'teigne',
    'teilla',
    'teille',
    'teillé',
    'teinta',
    'teinte',
    'teinté',
    'teints',
    'téléga',
    'télexa',
    'télexe',
    'télexé',
    'telles',
    'telson',
    'témoin',
    'tempes',
    'temple',
    'tempos',
    'tenace',
    'tenais',
    'tenait',
    'tenant',
    'tender',
    'tendes',
    'tendez',
    'tendis',
    'tendit',
    'tendît',
    'tendon',
    'tendra',
    'tendre',
    'tendue',
    'tendus',
    'teneur',
    'ténias',
    'teniez',
    'tennis',
    'tenons',
    'ténors',
    'tenrec',
    'tenson',
    'tentai',
    'tentas',
    'tentât',
    'tentée',
    'tenter',
    'tentes',
    'tentés',
    'tentez',
    'tenues',
    'ténues',
    'tenure',
    'tenuto',
    'téorbe',
    'terçai',
    'terças',
    'terçât',
    'tercée',
    'tercer',
    'terces',
    'tercés',
    'tercet',
    'tercez',
    'Tergal',
    'termes',
    'ternes',
    'ternie',
    'ternir',
    'ternis',
    'ternit',
    'ternît',
    'terrai',
    'terras',
    'terrât',
    'terrée',
    'terrer',
    'terres',
    'terrés',
    'terrez',
    'terril',
    'terrir',
    'terris',
    'territ',
    'terrît',
    'tersai',
    'tersas',
    'tersât',
    'tersée',
    'terser',
    'terses',
    'tersés',
    'tersez',
    'tertio',
    'tertre',
    'tesson',
    'testai',
    'testas',
    'testât',
    'testée',
    'tester',
    'testes',
    'testés',
    'testez',
    'teston',
    'tétais',
    'tétait',
    'tétant',
    'têtard',
    'têteau',
    'tétées',
    'tètent',
    'tétera',
    'tétiez',
    'tétine',
    'tétins',
    'tétons',
    'tétras',
    'tettes',
    'têtues',
    'teuton',
    'texane',
    'texans',
    'textes',
    'thaïes',
    'thaler',
    'thalle',
    'théier',
    'théine',
    'thèmes',
    'thénar',
    'thèque',
    'thèses',
    'thoras',
    'thorax',
    'thoron',
    'thrène',
    'thrips',
    'thunes',
    'thuyas',
    'thymie',
    'thymol',
    'thymus',
    'thyrse',
    'tiares',
    'tibial',
    'tibias',
    'ticket',
    'tièdes',
    'tiédie',
    'tiédir',
    'tiédis',
    'tiédit',
    'tiédît',
    'tienne',
    'tierça',
    'tierce',
    'tiercé',
    'tiffes',
    'tiglon',
    'tigrai',
    'tigras',
    'tigrât',
    'tigrée',
    'tigrer',
    'tigres',
    'tigrés',
    'tigrez',
    'tigron',
    'tildes',
    'tillac',
    'tillai',
    'tillas',
    'tillât',
    'tillée',
    'tiller',
    'tilles',
    'tillés',
    'tillez',
    'timbra',
    'timbre',
    'timbré',
    'timide',
    'timing',
    'timons',
    'timoré',
    'tincal',
    'tînmes',
    'tinsse',
    'tintai',
    'tintas',
    'tintât',
    'tintée',
    'tinter',
    'tintes',
    'tintés',
    'tîntes',
    'tintez',
    'tintin',
    'tipule',
    'tiquai',
    'tiquas',
    'tiquât',
    'tiquer',
    'tiques',
    'tiquez',
    'tirade',
    'tirage',
    'tirais',
    'tirait',
    'tirant',
    'tirées',
    'tirent',
    'tirera',
    'tirets',
    'tireur',
    'tiriez',
    'tiroir',
    'tirons',
    'tisais',
    'tisait',
    'tisane',
    'tisant',
    'tisées',
    'tisent',
    'tisera',
    'tisiez',
    'tisons',
    'tissai',
    'tissas',
    'tissât',
    'tissée',
    'tisser',
    'tisses',
    'tissés',
    'tissez',
    'tissus',
    'titane',
    'titans',
    'titrai',
    'titras',
    'titrât',
    'titrée',
    'titrer',
    'titres',
    'titrés',
    'titrez',
    'tituba',
    'titube',
    'titubé',
    'tmèses',
    'toasta',
    'toaste',
    'toasté',
    'toasts',
    'tocade',
    'tocard',
    'tocsin',
    'toilai',
    'toilas',
    'toilât',
    'toilée',
    'toiler',
    'toiles',
    'toilés',
    'toilez',
    'toisai',
    'toisas',
    'toisât',
    'toisée',
    'toiser',
    'toises',
    'toisés',
    'toisez',
    'toison',
    'tokais',
    'tokays',
    'tôlées',
    'toléra',
    'toléré',
    'tolère',
    'tolets',
    'tôlier',
    'tolite',
    'tollés',
    'toluol',
    'tomais',
    'tomait',
    'tomant',
    'tomate',
    'tombac',
    'tombai',
    'tombal',
    'tombas',
    'tombât',
    'tombée',
    'tomber',
    'tombes',
    'tombés',
    'tombez',
    'tomées',
    'toment',
    'tomera',
    'tomiez',
    'tommes',
    'tomons',
    'tonale',
    'tonals',
    'tondes',
    'tondez',
    'tondis',
    'tondit',
    'tondît',
    'tondra',
    'tondre',
    'tondue',
    'tondus',
    'tonies',
    'tonkas',
    'tonner',
    'tonnes',
    'tonnez',
    'tontes',
    'tonton',
    'topais',
    'topait',
    'topant',
    'topaze',
    'topent',
    'topera',
    'tophus',
    'topiez',
    'topons',
    'toquai',
    'toquas',
    'toquât',
    'toquée',
    'toquer',
    'toques',
    'toqués',
    'toquet',
    'toquez',
    'torahs',
    'torcha',
    'torche',
    'torché',
    'torcol',
    'tordes',
    'tordez',
    'tordis',
    'tordit',
    'tordît',
    'tordra',
    'tordre',
    'tordue',
    'tordus',
    'toréai',
    'toréas',
    'toréât',
    'toréer',
    'torées',
    'toréez',
    'torero',
    'tories',
    'torils',
    'torons',
    'torque',
    'torses',
    'tortil',
    'tortis',
    'tortue',
    'torves',
    'toscan',
    'totale',
    'totaux',
    'totems',
    'totons',
    'touage',
    'touais',
    'touait',
    'touant',
    'toubib',
    'toucan',
    'toucha',
    'touche',
    'touché',
    'touées',
    'touent',
    'touera',
    'toueur',
    'touffe',
    'touffu',
    'touiez',
    'touons',
    'toupet',
    'toupie',
    'touque',
    'tourba',
    'tourbe',
    'tourbé',
    'tourds',
    'touret',
    'tourie',
    'tourna',
    'tourne',
    'tourné',
    'touron',
    'tourte',
    'toussa',
    'tousse',
    'toussé',
    'toutes',
    'toutim',
    'toutou',
    'toxine',
    'trabée',
    'trabes',
    'traçai',
    'tracas',
    'traças',
    'traçât',
    'tracée',
    'tracer',
    'traces',
    'tracés',
    'tracez',
    'tracta',
    'tracte',
    'tracté',
    'tracts',
    'trafic',
    'trahie',
    'trahir',
    'trahis',
    'trahit',
    'trahît',
    'traies',
    'traîna',
    'traîne',
    'traîné',
    'trains',
    'traira',
    'traire',
    'traita',
    'traite',
    'traité',
    'traits',
    'trajet',
    'tramai',
    'tramas',
    'tramât',
    'tramée',
    'tramer',
    'trames',
    'tramés',
    'tramez',
    'transe',
    'transi',
    'trappe',
    'trapue',
    'trapus',
    'traqua',
    'traque',
    'traqué',
    'trauma',
    'travée',
    'trayez',
    'trayon',
    'tréflé',
    'trèfle',
    'treize',
    'trémas',
    'trémie',
    'trempa',
    'trempe',
    'trempé',
    'trente',
    'trépan',
    'trépas',
    'trésor',
    'tressa',
    'tresse',
    'tressé',
    'treuil',
    'trêves',
    'triade',
    'triage',
    'triais',
    'triait',
    'trials',
    'triant',
    'tribal',
    'tribun',
    'tribus',
    'tribut',
    'tricha',
    'triche',
    'triché',
    'tricot',
    'tridis',
    'triées',
    'trient',
    'triera',
    'trière',
    'trieur',
    'trigle',
    'triiez',
    'trilla',
    'trille',
    'trillé',
    'trimai',
    'trimas',
    'trimât',
    'trimée',
    'trimer',
    'trimes',
    'trimés',
    'trimez',
    'trines',
    'triode',
    'triols',
    'trions',
    'tripes',
    'tripla',
    'triple',
    'triplé',
    'tripot',
    'triqua',
    'trique',
    'triqué',
    'trisme',
    'trisoc',
    'trissa',
    'trisse',
    'trissé',
    'triste',
    'triton',
    'troche',
    'troène',
    'trogne',
    'troïka',
    'trolls',
    'trombe',
    'trompa',
    'trompe',
    'trompé',
    'trônai',
    'trônas',
    'trônât',
    'troncs',
    'trôner',
    'trônes',
    'trônez',
    'tropes',
    'troqua',
    'troque',
    'troqué',
    'trotta',
    'trotte',
    'trotté',
    'trouai',
    'trouas',
    'trouât',
    'trouée',
    'trouer',
    'troues',
    'troués',
    'trouez',
    'troupe',
    'trouva',
    'trouve',
    'trouvé',
    'troyen',
    'truand',
    'truble',
    'truffa',
    'truffe',
    'truffé',
    'truies',
    'truite',
    'truité',
    'trulli',
    'trullo',
    'truqua',
    'truque',
    'truqué',
    'trusta',
    'truste',
    'trusté',
    'trusts',
    'tuable',
    'tuages',
    'tuâmes',
    'tuante',
    'tuants',
    'tuasse',
    'tuâtes',
    'tubage',
    'tubais',
    'tubait',
    'tubant',
    'tubard',
    'tubées',
    'tubent',
    'tubera',
    'tubiez',
    'tubons',
    'tubulé',
    'tudieu',
    'tuerai',
    'tueras',
    'tuerez',
    'tuerie',
    'tueurs',
    'tueuse',
    'tufeau',
    'tufier',
    'tuilai',
    'tuilas',
    'tuilât',
    'tuilée',
    'tuiler',
    'tuiles',
    'tuilés',
    'tuilez',
    'tuions',
    'tulipe',
    'tulles',
    'tumeur',
    'tuners',
    'tungar',
    'tunnel',
    'tupaja',
    'tuques',
    'turban',
    'turbeh',
    'turbes',
    'turbés',
    'turbin',
    'turbot',
    'turcos',
    'turent',
    'turion',
    'turnep',
    'turnes',
    'turque',
    'tussah',
    'tusses',
    'tussor',
    'tuteur',
    'tuthie',
    'tuties',
    'tutoie',
    'tutoya',
    'tutoyé',
    'tuyaux',
    'tuyère',
    'tweeds',
    'twista',
    'twiste',
    'twisté',
    'twists',
    'tympan',
    'typais',
    'typait',
    'typant',
    'typées',
    'typent',
    'typera',
    'typhas',
    'typhon',
    'typhus',
    'typiez',
    'typisa',
    'typise',
    'typisé',
    'typons',
    'tyrans',
    'ukases',
    'ulcéra',
    'ulcéré',
    'ulcère',
    'ulémas',
    'ultime',
    'ultimo',
    'ultras',
    'ululai',
    'ululas',
    'ululât',
    'ululer',
    'ulules',
    'ululez',
    'unciné',
    'unguis',
    'uniate',
    'uniaxe',
    'unième',
    'unifia',
    'unifie',
    'unifié',
    'unîmes',
    'unions',
    'unique',
    'unirai',
    'uniras',
    'unirez',
    'unisse',
    'unités',
    'unîtes',
    'unitif',
    'uraeus',
    'uranes',
    'uranie',
    'urates',
    'urbain',
    'uréide',
    'urémie',
    'urètre',
    'urgeât',
    'urgent',
    'urgera',
    'urinai',
    'urinal',
    'urinas',
    'urinât',
    'uriner',
    'urines',
    'urinez',
    'urique',
    'urubus',
    'usagée',
    'usager',
    'usages',
    'usagés',
    'usâmes',
    'usante',
    'usants',
    'usasse',
    'usâtes',
    'userai',
    'useras',
    'userez',
    'usinai',
    'usinas',
    'usinât',
    'usinée',
    'usiner',
    'usines',
    'usinés',
    'usinez',
    'usions',
    'usitée',
    'usités',
    'usnées',
    'usuels',
    'usures',
    'usurpa',
    'usurpe',
    'usurpé',
    'utérin',
    'utérus',
    'utiles',
    'utopie',
    'uvales',
    'uvéite',
    'uvulas',
    'uvules',
    'vacant',
    'vaccin',
    'vacher',
    'vaches',
    'vacive',
    'vacuum',
    'vagins',
    'vagira',
    'vaguai',
    'vaguas',
    'vaguât',
    'vaguer',
    'vagues',
    'vaguez',
    'vahiné',
    'vaigre',
    'vaille',
    'vaincs',
    'vaincu',
    'vaines',
    'vairée',
    'vairés',
    'vairon',
    'valais',
    'valait',
    'valant',
    'valent',
    'valeta',
    'valeté',
    'valets',
    'valeur',
    'valgus',
    'valida',
    'valide',
    'validé',
    'valiez',
    'valine',
    'valisa',
    'valise',
    'valisé',
    'vallée',
    'vallon',
    'valoir',
    'valons',
    'valsai',
    'valsas',
    'valsât',
    'valsée',
    'valser',
    'valses',
    'valsés',
    'valsez',
    'values',
    'valvée',
    'valves',
    'valvés',
    'vampai',
    'vampas',
    'vampât',
    'vampée',
    'vamper',
    'vampes',
    'vampés',
    'vampez',
    'vandas',
    'vanité',
    'vannai',
    'vannas',
    'vannât',
    'vannée',
    'vanner',
    'vannes',
    'vannés',
    'vannez',
    'vantai',
    'vantas',
    'vantât',
    'vantée',
    'vanter',
    'vantes',
    'vantés',
    'vantez',
    'vapeur',
    'vaquai',
    'vaquas',
    'vaquât',
    'vaquer',
    'vaques',
    'vaquez',
    'varans',
    'varech',
    'variai',
    'varias',
    'variât',
    'varice',
    'variée',
    'varier',
    'varies',
    'variés',
    'variez',
    'varlet',
    'varron',
    'varves',
    'vasard',
    'vaseux',
    'vasque',
    'vassal',
    'vastes',
    'vaudou',
    'vaudra',
    'vautra',
    'vautre',
    'vautré',
    'vécues',
    'végéta',
    'végété',
    'végète',
    'veilla',
    'veille',
    'veillé',
    'veinai',
    'veinas',
    'veinât',
    'veinée',
    'veiner',
    'veines',
    'veinés',
    'veinez',
    'vêlage',
    'vêlais',
    'vêlait',
    'vêlant',
    'vélars',
    'velche',
    'vêlent',
    'vêlera',
    'vélies',
    'vêliez',
    'vélins',
    'vélite',
    'véloce',
    'vêlons',
    'velots',
    'veltes',
    'velues',
    'vélums',
    'venais',
    'venait',
    'vénale',
    'venant',
    'vénaux',
    'vendes',
    'vendez',
    'vendis',
    'vendit',
    'vendît',
    'vendra',
    'vendre',
    'vendue',
    'vendus',
    'vénéra',
    'vénéré',
    'vénère',
    'venets',
    'veneur',
    'vengea',
    'vengée',
    'venger',
    'venges',
    'vengés',
    'vengez',
    'véniel',
    'veniez',
    'venins',
    'venons',
    'ventât',
    'venter',
    'ventes',
    'ventis',
    'ventre',
    'ventru',
    'venues',
    'vêpres',
    'verbal',
    'verbes',
    'verdet',
    'verdie',
    'verdir',
    'verdis',
    'verdit',
    'verdît',
    'véreux',
    'vergée',
    'verger',
    'verges',
    'vergés',
    'vergne',
    'vergue',
    'vérine',
    'vérins',
    'vérité',
    'verjus',
    'verlan',
    'vermée',
    'vermet',
    'vermis',
    'vernal',
    'vernie',
    'vernir',
    'vernis',
    'vernit',
    'vernît',
    'vérole',
    'vérolé',
    'verrai',
    'verras',
    'verrat',
    'verrée',
    'verres',
    'verrés',
    'verrez',
    'verrou',
    'verrue',
    'versai',
    'versas',
    'versât',
    'versée',
    'verser',
    'verses',
    'versés',
    'verset',
    'versez',
    'versos',
    'verste',
    'versus',
    'vertes',
    'vertex',
    'vertus',
    'verves',
    'vesces',
    'Vespas',
    'vessai',
    'vessas',
    'vessât',
    'vesser',
    'vesses',
    'vessez',
    'vessie',
    'vestes',
    'veston',
    'vêtais',
    'vêtait',
    'vêtant',
    'vêtent',
    'vêtiez',
    'vêtira',
    'vêtons',
    'vêtues',
    'vêture',
    'veules',
    'veuves',
    'vexais',
    'vexait',
    'vexant',
    'vexées',
    'vexent',
    'vexera',
    'vexiez',
    'vexons',
    'viable',
    'viaduc',
    'viager',
    'vianda',
    'viande',
    'viandé',
    'vibord',
    'vibrai',
    'vibras',
    'vibrât',
    'vibrée',
    'vibrer',
    'vibres',
    'vibrés',
    'vibrez',
    'vichys',
    'viciai',
    'vicias',
    'viciât',
    'viciée',
    'vicier',
    'vicies',
    'viciés',
    'viciez',
    'vidage',
    'vidais',
    'vidait',
    'vidame',
    'vidant',
    'vidées',
    'vident',
    'vidéos',
    'videra',
    'videur',
    'vidiez',
    'vidima',
    'vidime',
    'vidimé',
    'vidoir',
    'vidons',
    'vidure',
    'viella',
    'vielle',
    'viellé',
    'vienne',
    'vierge',
    'vigies',
    'vigile',
    'vignes',
    'vignot',
    'viking',
    'vilain',
    'villas',
    'villes',
    'vinage',
    'vinais',
    'vinait',
    'vinant',
    'vinées',
    'vinent',
    'vinera',
    'vineux',
    'vingts',
    'viniez',
    'vînmes',
    'vinons',
    'vinsse',
    'vîntes',
    'vinyle',
    'violai',
    'violas',
    'violat',
    'violât',
    'violée',
    'violer',
    'violes',
    'violés',
    'violet',
    'violez',
    'violon',
    'vioque',
    'vioqui',
    'viorne',
    'vipère',
    'virage',
    'virago',
    'virais',
    'virait',
    'virale',
    'virant',
    'viraux',
    'virées',
    'virent',
    'virera',
    'vireur',
    'vireux',
    'viriez',
    'virile',
    'virils',
    'virion',
    'virola',
    'virole',
    'virolé',
    'virons',
    'virose',
    'virure',
    'visage',
    'visais',
    'visait',
    'visant',
    'visées',
    'visent',
    'visera',
    'viseur',
    'viseux',
    'visiez',
    'vision',
    'visita',
    'visite',
    'visité',
    'visons',
    'vissai',
    'vissas',
    'vissât',
    'vissée',
    'visser',
    'visses',
    'vissés',
    'vissez',
    'visuel',
    'vitale',
    'vitaux',
    'vitrai',
    'vitras',
    'vitrât',
    'vitrée',
    'vitrer',
    'vitres',
    'vitrés',
    'vitrez',
    'vivace',
    'vivais',
    'vivait',
    'vivant',
    'vivats',
    'vivent',
    'viveur',
    'vivier',
    'viviez',
    'vivons',
    'vivota',
    'vivote',
    'vivoté',
    'vivrai',
    'vivras',
    'vivrée',
    'vivres',
    'vivrés',
    'vivrez',
    'vizirs',
    'vocale',
    'vocaux',
    'vodkas',
    'vogoul',
    'voguai',
    'voguas',
    'voguât',
    'voguer',
    'vogues',
    'voguez',
    'voient',
    'voilai',
    'voilas',
    'voilât',
    'voilée',
    'voiler',
    'voiles',
    'voilés',
    'voilez',
    'voirie',
    'voisin',
    'volage',
    'volais',
    'volait',
    'volant',
    'volcan',
    'volées',
    'volent',
    'volera',
    'voleta',
    'voleté',
    'volets',
    'voleur',
    'voliez',
    'volige',
    'voligé',
    'volley',
    'volons',
    'voltai',
    'voltas',
    'voltât',
    'volter',
    'voltes',
    'voltez',
    'volume',
    'volute',
    'volves',
    'volvox',
    'vomers',
    'vomies',
    'vomira',
    'vorace',
    'vortex',
    'votais',
    'votait',
    'votant',
    'votées',
    'votent',
    'votera',
    'votiez',
    'votifs',
    'votive',
    'votons',
    'vôtres',
    'vouais',
    'vouait',
    'vouant',
    'voudra',
    'vouées',
    'vouent',
    'vouera',
    'vouges',
    'vouiez',
    'voulez',
    'voulue',
    'voulus',
    'voulut',
    'voulût',
    'vouons',
    'voûtai',
    'voûtas',
    'voûtât',
    'voûtée',
    'voûter',
    'voûtes',
    'voûtés',
    'voûtez',
    'voyage',
    'voyagé',
    'voyais',
    'voyait',
    'voyant',
    'voyers',
    'voyeur',
    'voyiez',
    'voyons',
    'voyous',
    'vraies',
    'vrilla',
    'vrille',
    'vrillé',
    'vrombi',
    'vulpin',
    'vulves',
    'wading',
    'wagage',
    'wagons',
    'wallon',
    'wapiti',
    'waters',
    'webers',
    'welche',
    'welter',
    'wharfs',
    'whisky',
    'whists',
    'wigwam',
    'wilaya',
    'winchs',
    'wombat',
    'woofer',
    'xénons',
    'xiphos',
    'xylène',
    'xystes',
    'yachts',
    'yankee',
    'yaourt',
    'yèbles',
    'yeoman',
    'yeomen',
    'yeuses',
    'youpin',
    'yourte',
    'youyou',
    'ypréau',
    'ysopet',
    'yttria',
    'yuccas',
    'zabres',
    'zancle',
    'zannis',
    'zanzis',
    'zaouïa',
    'zazous',
    'zébrai',
    'zébras',
    'zébrât',
    'zébrée',
    'zébrer',
    'zébrés',
    'zèbres',
    'zébrez',
    'zélées',
    'zélote',
    'zenana',
    'zénith',
    'zéphyr',
    'zestai',
    'zestas',
    'zestât',
    'zestée',
    'zester',
    'zestes',
    'zestés',
    'zestez',
    'zétète',
    'zeugma',
    'zeugme',
    'zézaie',
    'zézaya',
    'zézaye',
    'zézayé',
    'zibais',
    'zibait',
    'zibant',
    'zibées',
    'zibent',
    'zibera',
    'zibiez',
    'zibons',
    'zieuta',
    'zieute',
    'zieuté',
    'zigoto',
    'ziguai',
    'ziguas',
    'ziguât',
    'ziguée',
    'ziguer',
    'zigues',
    'zigués',
    'ziguez',
    'zigzag',
    'zingua',
    'zingue',
    'zingué',
    'zinnia',
    'zinzin',
    'zippai',
    'zippas',
    'zippât',
    'zippée',
    'zipper',
    'zippes',
    'zippés',
    'zippez',
    'zircon',
    'zlotys',
    'zoïles',
    'zombie',
    'zombis',
    'zonage',
    'zonais',
    'zonait',
    'zonale',
    'zonant',
    'zonard',
    'zonaux',
    'zonées',
    'zonent',
    'zonera',
    'zonier',
    'zoniez',
    'zonons',
    'zonure',
    'zooïde',
    'zouave',
    'zozota',
    'zozote',
    'zozoté',
    'zwanze',
    'zygène',
    'zygoma',
    'zygote',
    'zymase',
    'zython',
    'zythum'
];
