import { getAuth, onAuthStateChanged } from 'firebase/auth';
import store from '../common/main.store';
import { AUTH_LOADING_FINISHED, SIGN_OUT, SIGN_SUCCESS } from './auth.types';

export default function authChangeListener() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
        if (user) {
            store.dispatch({ type: SIGN_SUCCESS, payload: { user: { email: user.email } } });
        } else {
            store.dispatch({ type: SIGN_OUT });
        }
        store.dispatch({ type: AUTH_LOADING_FINISHED });
    });
}
